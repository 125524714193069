export var jsonAssentamentoBrasilPE5 = {
  type: 'FeatureCollection',
  name: 'AssentamentoBrasil_PE_5',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0350000',
        uf: 'PE',
        nome_proje: 'PA OURO II',
        municipio: 'BELO JARDIM',
        area_hecta: '465.7060',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 4.0,
        data_de_cr: '21/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 465.6053,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.404415574301233, -8.374718105497994],
              [-36.403976600669012, -8.374796366082634],
              [-36.40364097833158, -8.374820093299931],
              [-36.403129463320397, -8.374849711538076],
              [-36.402491520003331, -8.375129709848549],
              [-36.40240767425346, -8.375430884375104],
              [-36.402397226720723, -8.375966043149921],
              [-36.402113719761076, -8.376313163886991],
              [-36.401814000561231, -8.376559963614403],
              [-36.401580536177292, -8.378827110169341],
              [-36.401441443820957, -8.380177771223801],
              [-36.39954265420667, -8.383800424048767],
              [-36.400906301627145, -8.384103459270571],
              [-36.398550300034266, -8.38804224986376],
              [-36.398503846864841, -8.388122999716256],
              [-36.396226321741175, -8.391960439501393],
              [-36.390581828649218, -8.395981222236465],
              [-36.389124803232001, -8.397019145684819],
              [-36.389522420530227, -8.397796105225687],
              [-36.390113618024706, -8.398800618050265],
              [-36.388994348774787, -8.400242712558114],
              [-36.389166259008846, -8.401321886630306],
              [-36.39146845778847, -8.402231751347085],
              [-36.391878107379689, -8.402268714533511],
              [-36.392615530469847, -8.401287013020742],
              [-36.394306838029728, -8.402510352724651],
              [-36.393765320325052, -8.403874599623881],
              [-36.393495582571212, -8.404070889510779],
              [-36.393373024871437, -8.404648855935511],
              [-36.39328344693714, -8.404759228774827],
              [-36.395815193391286, -8.404606655752023],
              [-36.39621477996787, -8.401515427178731],
              [-36.399305573268094, -8.399881572564233],
              [-36.400899657480394, -8.399710380063427],
              [-36.405048723980023, -8.401962255012315],
              [-36.407140663138485, -8.399249386241889],
              [-36.407792527550193, -8.398410126492966],
              [-36.409853713842033, -8.395716909336461],
              [-36.410679713290818, -8.394739534304275],
              [-36.410727364950667, -8.394683188884155],
              [-36.411764651861873, -8.393385048453069],
              [-36.413792222833585, -8.389784127967689],
              [-36.416923343686236, -8.384222895598445],
              [-36.416775335231769, -8.384031616844583],
              [-36.416360191850913, -8.383495200182999],
              [-36.416039431324542, -8.38308094298303],
              [-36.415843198974457, -8.383019383627239],
              [-36.415465132709194, -8.38290078692798],
              [-36.415303281236447, -8.382717279017735],
              [-36.414862403043543, -8.382217110243962],
              [-36.414678305794531, -8.3820083349386],
              [-36.414159504913705, -8.382026260115762],
              [-36.413804531490143, -8.382038481474675],
              [-36.413154140860065, -8.381880144011758],
              [-36.412843823605435, -8.38172611479064],
              [-36.412451833818942, -8.381531698687622],
              [-36.412236105310456, -8.381276358007222],
              [-36.412024608869793, -8.381026015125125],
              [-36.411644361061292, -8.380798873124128],
              [-36.41148707573705, -8.380830824230419],
              [-36.411160061435432, -8.381157064080751],
              [-36.410488413510059, -8.38160601562919],
              [-36.409904146239562, -8.38157570253369],
              [-36.409274152924688, -8.381325050180241],
              [-36.408932584148864, -8.381067345622494],
              [-36.408668638052077, -8.380584690952603],
              [-36.408564117177974, -8.379774427252109],
              [-36.408317111591124, -8.379200254222395],
              [-36.408060253507358, -8.378264196487962],
              [-36.408025386287193, -8.377116788303393],
              [-36.408055697164343, -8.376444855124596],
              [-36.408227306621605, -8.375825267240451],
              [-36.407914248635585, -8.375373647330475],
              [-36.4076176956886, -8.375225037995602],
              [-36.407100018544298, -8.374965454172147],
              [-36.406548749443694, -8.374845714232332],
              [-36.405290266163227, -8.374984286274477],
              [-36.404415574301233, -8.374718105497994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0244000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DOS BOIS',
        municipio: 'INGAZEIRA',
        area_hecta: '362.8474',
        capacidade: 18.0,
        num_famili: 17.0,
        fase: 7.0,
        data_de_cr: '05/12/2001',
        forma_obte: 'Compra e Venda',
        data_obten: '25/10/1984',
        area_calc_: 364.5387,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.412624711008206, -7.642083344632303],
              [-37.411825980771681, -7.642353099847092],
              [-37.410741442375119, -7.642559969865974],
              [-37.410551414207951, -7.642809766870475],
              [-37.410511393728775, -7.642888890592291],
              [-37.410427463480978, -7.643131943738823],
              [-37.409583451205506, -7.644901335350369],
              [-37.409566737004859, -7.645094043205606],
              [-37.409475994084971, -7.645352108005246],
              [-37.409231263468371, -7.646160856736821],
              [-37.408869807791255, -7.646783127309037],
              [-37.407959416476118, -7.64829570999568],
              [-37.407748062084885, -7.648949540672303],
              [-37.40737841049809, -7.649866662809792],
              [-37.406419777823807, -7.651361341858703],
              [-37.405902663607179, -7.652086576018434],
              [-37.405083794443193, -7.653590873022891],
              [-37.404447458010154, -7.654763370218463],
              [-37.404048963170247, -7.655590492627167],
              [-37.403568362828359, -7.656672263237506],
              [-37.402257227236781, -7.659628144263923],
              [-37.401346149744789, -7.661888734432481],
              [-37.400874323477765, -7.663479675174184],
              [-37.400106591006704, -7.664230541370659],
              [-37.398773628892705, -7.668359029431347],
              [-37.399119339125889, -7.669431776813786],
              [-37.39909957544257, -7.669617196376531],
              [-37.399209073033283, -7.669664696695412],
              [-37.400028926228622, -7.669706265831536],
              [-37.400484335021687, -7.669884771446812],
              [-37.401224721988143, -7.668604147111408],
              [-37.403466912664136, -7.669988558396652],
              [-37.403631251177998, -7.669688018992898],
              [-37.403810005569596, -7.669447510534419],
              [-37.404080675517342, -7.669100538284544],
              [-37.404421014821494, -7.669407079959658],
              [-37.404773500730677, -7.669705652444038],
              [-37.405563256500812, -7.670367320428873],
              [-37.407041421621052, -7.671137355746402],
              [-37.407875022162074, -7.670084165778182],
              [-37.408855672834882, -7.669199959651679],
              [-37.408274631773949, -7.668394796189979],
              [-37.408734341838851, -7.667209810779729],
              [-37.408914449567312, -7.666853757506046],
              [-37.410078570174839, -7.665774821748258],
              [-37.410193231975668, -7.665710243060173],
              [-37.411650201985793, -7.664388645768738],
              [-37.412779288609109, -7.663514252015958],
              [-37.413874711472857, -7.662247785821098],
              [-37.414216997781239, -7.662082577018461],
              [-37.415381998243227, -7.660866089103326],
              [-37.417150581108849, -7.658977216439508],
              [-37.416510727256295, -7.658603875623466],
              [-37.417127474400623, -7.657487624409916],
              [-37.417328226125967, -7.65713243378446],
              [-37.418857868928882, -7.654312025954112],
              [-37.41865262473204, -7.654162242951866],
              [-37.418295693745939, -7.654206910802491],
              [-37.418053493420956, -7.654167179092837],
              [-37.418005197604622, -7.653904613800885],
              [-37.417072411392638, -7.653460441331224],
              [-37.417387378906341, -7.652297895072405],
              [-37.418405976702502, -7.651879909099106],
              [-37.41910304465074, -7.652465902549144],
              [-37.419414055782269, -7.652375880703818],
              [-37.418691895939872, -7.650404925188832],
              [-37.417693071027266, -7.64933981883321],
              [-37.417241826031287, -7.648889487680051],
              [-37.416926381616705, -7.648243061800244],
              [-37.416751550647838, -7.647819111513951],
              [-37.416668714596085, -7.646580589981203],
              [-37.416545822101966, -7.646293176956004],
              [-37.416238430690001, -7.64561824298421],
              [-37.415909543347226, -7.644955120758947],
              [-37.415803112114098, -7.644728806989887],
              [-37.415295315687025, -7.643886667547777],
              [-37.415029522891466, -7.64356702125444],
              [-37.414520130175177, -7.642942871443682],
              [-37.414165637771013, -7.642760038266854],
              [-37.413401894763879, -7.642376730319484],
              [-37.412841808121541, -7.642141218023677],
              [-37.412624711008206, -7.642083344632303],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0280000',
        uf: 'PE',
        nome_proje: 'PA TENTUGAL',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '1043.5000',
        capacidade: 100.0,
        num_famili: 99.0,
        fase: 4.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/07/2001',
        area_calc_: 1029.7548,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.163932408089764, -8.83834812926208],
              [-35.163287664471582, -8.840824400918843],
              [-35.162747709488286, -8.843428395016547],
              [-35.161978546313975, -8.84680694690311],
              [-35.161763439492098, -8.847891849595825],
              [-35.161659895001641, -8.848054634585877],
              [-35.161499189635556, -8.848655491182674],
              [-35.161344272285092, -8.849477291296241],
              [-35.161268795401547, -8.849707706871328],
              [-35.160957404018617, -8.851567448328144],
              [-35.160244638443835, -8.854938726349037],
              [-35.159405906242327, -8.858379140309147],
              [-35.159001792535719, -8.860057427055446],
              [-35.158659539207072, -8.861483068517806],
              [-35.157890028644125, -8.864702614020253],
              [-35.157509874610447, -8.866289125770932],
              [-35.157203966006065, -8.867570937033131],
              [-35.156719091780516, -8.869562568929002],
              [-35.162634954553518, -8.874734045458542],
              [-35.163070180980093, -8.874858483295258],
              [-35.163674573218721, -8.875080173140732],
              [-35.164164872925596, -8.875323408174287],
              [-35.166903582176303, -8.877010279061707],
              [-35.168845313410216, -8.876504117314601],
              [-35.170112994953833, -8.876179959626544],
              [-35.171217490004835, -8.875896735842881],
              [-35.171916428209812, -8.875724987579936],
              [-35.172088137349448, -8.874908747667147],
              [-35.172239166195965, -8.874184644625407],
              [-35.17240021159288, -8.873420567371856],
              [-35.172578160499761, -8.872565170784162],
              [-35.173510369435476, -8.872120181408452],
              [-35.174602372802084, -8.871605997595505],
              [-35.175554769196651, -8.87115765976629],
              [-35.17667443006787, -8.870638636947106],
              [-35.177753987125861, -8.870885504789424],
              [-35.177867917548589, -8.870909552733155],
              [-35.178945063863246, -8.871163598668906],
              [-35.180004107339613, -8.871413155056695],
              [-35.181062001785548, -8.871663465041321],
              [-35.182108964359713, -8.871915462567864],
              [-35.183075619312454, -8.872580696074856],
              [-35.183848411448302, -8.873115698282506],
              [-35.18485416169252, -8.873816183558352],
              [-35.186083348806939, -8.874697697748914],
              [-35.186354884502762, -8.874776788273673],
              [-35.188067054486069, -8.875371382508831],
              [-35.189189348211507, -8.875781682206199],
              [-35.189826134009344, -8.875130855640329],
              [-35.190557549899722, -8.874351727243724],
              [-35.191046932361175, -8.873806488615285],
              [-35.191294413338646, -8.87365333717606],
              [-35.192932051650004, -8.87255496690501],
              [-35.193940927194255, -8.870504440176346],
              [-35.193999630126214, -8.870392854508115],
              [-35.19200955527684, -8.869563513777925],
              [-35.192125839512556, -8.868582886122976],
              [-35.191845208154781, -8.867959470438826],
              [-35.191732068539963, -8.867914316007537],
              [-35.191791873007396, -8.867843422402494],
              [-35.192512311374536, -8.866990967449929],
              [-35.190975348769946, -8.865612748463366],
              [-35.190530203863993, -8.864913880915108],
              [-35.188972104606492, -8.862081385820225],
              [-35.188911065337834, -8.861990034131299],
              [-35.188640541049068, -8.861492448316087],
              [-35.188511705333013, -8.86125587839479],
              [-35.187016462774238, -8.858081176614657],
              [-35.186382626390312, -8.857055681026013],
              [-35.185546664726985, -8.855167049100132],
              [-35.184382726040802, -8.852853620462824],
              [-35.183422715006706, -8.851106140068138],
              [-35.183380029181322, -8.851026123760239],
              [-35.1832522101341, -8.850888122473741],
              [-35.183048692295962, -8.850385590252898],
              [-35.182292120653052, -8.848872398650258],
              [-35.181596229154962, -8.848406983487759],
              [-35.175952473530522, -8.844753798972778],
              [-35.173341729680402, -8.843715569001175],
              [-35.170391681876389, -8.842028712648954],
              [-35.170097725015076, -8.841862616198231],
              [-35.165621569483491, -8.839326424733219],
              [-35.163932408089764, -8.83834812926208],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0365000',
        uf: 'PE',
        nome_proje: 'PA JUNDIÁ LIMEIRA',
        municipio: 'PANELAS',
        area_hecta: '686.3313',
        capacidade: 70.0,
        num_famili: 68.0,
        fase: 4.0,
        data_de_cr: '06/12/2006',
        forma_obte: 'Desapropriação',
        data_obten: '14/07/2006',
        area_calc_: 736.8852,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.997476026032231, -8.747363085227921],
              [-36.001017041239272, -8.747739041227565],
              [-36.001216925436587, -8.748005208760835],
              [-36.001832817928722, -8.748205058217234],
              [-36.002134115627022, -8.748310324482675],
              [-36.002336161531915, -8.748392479632033],
              [-36.002661937110801, -8.748596659429293],
              [-36.003010050867736, -8.748720992727216],
              [-36.003510736070695, -8.748974347592533],
              [-36.003740936244718, -8.749110869616883],
              [-36.004103275594105, -8.749345419385143],
              [-36.004362867916861, -8.749427210640613],
              [-36.004910672322438, -8.749569002340076],
              [-36.005326963896572, -8.749648818195968],
              [-36.005526303761926, -8.749699363442724],
              [-36.005751713654419, -8.749783417371152],
              [-36.006170989443149, -8.749953063650404],
              [-36.006426478883128, -8.750022349862601],
              [-36.006748188512823, -8.750115361908531],
              [-36.006970920110852, -8.750200171830606],
              [-36.007111884763674, -8.750333819661128],
              [-36.007248278133616, -8.750601226099436],
              [-36.007155579163474, -8.750568539913827],
              [-36.006956862872045, -8.750520242997419],
              [-36.006880704532577, -8.75050953142455],
              [-36.006815192228878, -8.750507167433495],
              [-36.006753260981277, -8.750511111199692],
              [-36.006706682690215, -8.750523987086234],
              [-36.006664593855817, -8.750543449517743],
              [-36.006586022826902, -8.750604203673799],
              [-36.006520405996369, -8.750654971884382],
              [-36.006464298633922, -8.750711674268409],
              [-36.006404077169023, -8.750788908390893],
              [-36.006363260220638, -8.750877676095332],
              [-36.0063285028493, -8.750946048716177],
              [-36.006270385467531, -8.751049341841716],
              [-36.006227900811879, -8.751113337929565],
              [-36.006189764560247, -8.751178815155509],
              [-36.006149276736643, -8.751264430189979],
              [-36.006121616961309, -8.751317283980312],
              [-36.006071285442694, -8.751404230528422],
              [-36.005999280535555, -8.751503480923846],
              [-36.005896371205559, -8.751615729935464],
              [-36.005898701979753, -8.751643852111817],
              [-36.012822327460327, -8.754935676079819],
              [-36.014801468766883, -8.755446206341308],
              [-36.015275106608193, -8.755016889645116],
              [-36.016859896546215, -8.752992488011152],
              [-36.018468048487442, -8.751360196190905],
              [-36.019191825825573, -8.751746769566397],
              [-36.019668588477309, -8.753006234337699],
              [-36.020732251988633, -8.753927154040953],
              [-36.020745746852725, -8.753934111020653],
              [-36.020702244775158, -8.754752771503247],
              [-36.024713306901681, -8.753629158689892],
              [-36.024969221201864, -8.755227281711472],
              [-36.03126012584702, -8.754086507282873],
              [-36.032960606626773, -8.749759325975811],
              [-36.035878000941032, -8.749697833115274],
              [-36.033610451116466, -8.745654183609677],
              [-36.032118075577372, -8.743487806240736],
              [-36.031888872583245, -8.741762091099627],
              [-36.031420012823133, -8.741573641913842],
              [-36.030641878155009, -8.73891560287815],
              [-36.030779863300303, -8.737370485971701],
              [-36.028138307977933, -8.73746611350677],
              [-36.027240334784565, -8.736069262063333],
              [-36.027194874578171, -8.733505341998409],
              [-36.02088945182426, -8.72958152013195],
              [-36.019530182055419, -8.732912032294317],
              [-36.018567484400613, -8.733503507179124],
              [-36.018173665434297, -8.735033482919389],
              [-36.016795641914044, -8.735569350682789],
              [-36.016691857713909, -8.736713274416516],
              [-36.01545849267179, -8.740411826374116],
              [-36.015058784482676, -8.741301688178266],
              [-36.01462966006774, -8.741665506088443],
              [-36.013859426251599, -8.74162096733671],
              [-36.011295698436193, -8.740369660887426],
              [-36.00597115381143, -8.738268018748357],
              [-36.005399974555594, -8.737728410017221],
              [-36.00100891417808, -8.735462202450883],
              [-35.998610787566406, -8.736238073213322],
              [-35.997157383768872, -8.735447512024267],
              [-35.997047358951335, -8.735459819571178],
              [-35.995945996220151, -8.734653184189446],
              [-35.993770644047522, -8.7344558155639],
              [-35.992908281325278, -8.734616630435957],
              [-35.993295658000036, -8.735283751411114],
              [-35.991593085822863, -8.736578264298837],
              [-35.991718322857558, -8.737194660411117],
              [-35.993991224069767, -8.737286001115686],
              [-35.993438574786332, -8.740896324914704],
              [-35.997476026032231, -8.747363085227921],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0291000',
        uf: 'PE',
        nome_proje: 'PA ARASSU',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '185.9981',
        capacidade: 16.0,
        num_famili: 15.0,
        fase: 5.0,
        data_de_cr: '25/02/2003',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 176.4509,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.205669135647547, -8.881160470790954],
              [-35.199405494583637, -8.87860334341801],
              [-35.199262898162864, -8.87889527814546],
              [-35.19864453348881, -8.880638311534119],
              [-35.198446290280025, -8.881182611924162],
              [-35.197538262549791, -8.883235340523504],
              [-35.196317020784264, -8.885868379621233],
              [-35.195087299259733, -8.888674706264835],
              [-35.194943628349407, -8.888991693801506],
              [-35.19600866095643, -8.888756105284926],
              [-35.197095252229119, -8.888590979946098],
              [-35.19787470773138, -8.888593000568013],
              [-35.198826620818394, -8.888487823521125],
              [-35.199963507322749, -8.888382347789568],
              [-35.20077645608437, -8.888436580595805],
              [-35.20186543508953, -8.888596172827867],
              [-35.202242320268724, -8.888817944753781],
              [-35.20310774850973, -8.889112491951877],
              [-35.203680077547297, -8.888818268036029],
              [-35.204066734549919, -8.888950433056769],
              [-35.204408532298444, -8.889292824175975],
              [-35.20494208663419, -8.889840190907488],
              [-35.206088921930373, -8.890558943317796],
              [-35.206476831084345, -8.890896819523723],
              [-35.206681688158298, -8.89157725387259],
              [-35.207358615210339, -8.891139556408008],
              [-35.207907132281335, -8.890563684632335],
              [-35.208759258482061, -8.891344678397623],
              [-35.209703080801155, -8.891898985681644],
              [-35.210076872268587, -8.892413014976155],
              [-35.210402181285993, -8.891735693902918],
              [-35.210209061154885, -8.890908386762167],
              [-35.209936989275334, -8.89104680158392],
              [-35.210133473714606, -8.890434808499839],
              [-35.211095244192876, -8.890893538974529],
              [-35.211569442256021, -8.891341223110263],
              [-35.211651638763854, -8.890513855851781],
              [-35.211554818651926, -8.889457208532887],
              [-35.211951206400542, -8.889069092877723],
              [-35.211601080062337, -8.888540688803131],
              [-35.211475675076528, -8.888275929777082],
              [-35.211707865520687, -8.888159181884886],
              [-35.212164290371696, -8.888302321771885],
              [-35.212867823595957, -8.886427531087003],
              [-35.212898488819476, -8.886341192112923],
              [-35.213805873594843, -8.88390132105533],
              [-35.214375800780473, -8.882280787349609],
              [-35.205740935086538, -8.881231777722542],
              [-35.205669135647547, -8.881160470790954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0246000',
        uf: 'PE',
        nome_proje: 'PA PORÇÃO F',
        municipio: 'MORENO',
        area_hecta: '243.6916',
        capacidade: 33.0,
        num_famili: 29.0,
        fase: 4.0,
        data_de_cr: '14/12/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 215.1513,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.072656611472752, -8.098329973306525],
              [-35.072603088807902, -8.098506521947144],
              [-35.072576526560724, -8.100584010268582],
              [-35.072572004079575, -8.100941106595382],
              [-35.072536403818681, -8.101783799798177],
              [-35.072542594722755, -8.102461755664425],
              [-35.072550465501742, -8.103467848827144],
              [-35.072717141951671, -8.10448579028399],
              [-35.073539013316832, -8.104491554519196],
              [-35.074440858412046, -8.105057378946775],
              [-35.075196159461861, -8.10552541286761],
              [-35.078676149522281, -8.107706159121316],
              [-35.080163823381199, -8.111941843278728],
              [-35.0814220578659, -8.111952590758644],
              [-35.08479852644377, -8.111984136434053],
              [-35.08642960189858, -8.111998383570146],
              [-35.086619749543154, -8.111403497914646],
              [-35.086704552532758, -8.111137294852119],
              [-35.086788457679745, -8.110872904346797],
              [-35.087439211070283, -8.108831107441187],
              [-35.08835154266049, -8.105975297855258],
              [-35.088909919003868, -8.105370389887863],
              [-35.089001608655387, -8.105384383573437],
              [-35.091695152948041, -8.105804477086963],
              [-35.093081416156565, -8.106020641635055],
              [-35.094578432071202, -8.106253407365486],
              [-35.097321910137708, -8.106681339797662],
              [-35.095371020856966, -8.104809308620625],
              [-35.094835710862021, -8.104610474442312],
              [-35.093387010667996, -8.104071917710241],
              [-35.092234598353805, -8.10364392701195],
              [-35.092495251113505, -8.102651835980613],
              [-35.092916045846984, -8.101053262074029],
              [-35.091253279942556, -8.100864725779562],
              [-35.091088095559499, -8.100846589329654],
              [-35.090973762978621, -8.100839039778466],
              [-35.09069973507448, -8.100822365161207],
              [-35.089124518367854, -8.100724672240453],
              [-35.08858190462486, -8.100691292396085],
              [-35.08761347309693, -8.100406074713826],
              [-35.087121781033503, -8.100396839457852],
              [-35.085736187504281, -8.100307202160447],
              [-35.084105600602022, -8.100202540348285],
              [-35.082057361496027, -8.09984598618191],
              [-35.080114445845425, -8.099508771254916],
              [-35.076943727502034, -8.09942729822923],
              [-35.076983671974681, -8.09890278599806],
              [-35.076881135773462, -8.098896076197621],
              [-35.076156912371417, -8.098825636285373],
              [-35.075142976248046, -8.098687062254852],
              [-35.075015986102883, -8.098687708219575],
              [-35.073793085495602, -8.098484202751806],
              [-35.07347057037444, -8.098387307983201],
              [-35.072656611472752, -8.098329973306525],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0316000',
        uf: 'PE',
        nome_proje: 'PA PITOMBEIRAS',
        municipio: 'CANHOTINHO',
        area_hecta: '529.7254',
        capacidade: 36.0,
        num_famili: 34.0,
        fase: 3.0,
        data_de_cr: '22/07/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 526.197,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.287983248963471, -8.80023088539769],
              [-36.287723788263506, -8.800068797497685],
              [-36.286150525263693, -8.802606432697676],
              [-36.287112502663575, -8.80290578249768],
              [-36.286405719763657, -8.804862159197677],
              [-36.286254209263674, -8.805192869997679],
              [-36.284505286863883, -8.808905591497668],
              [-36.286342270563651, -8.809242293797675],
              [-36.285687490563724, -8.811305395497666],
              [-36.284886152563828, -8.811281320997667],
              [-36.283968420563937, -8.81074536309767],
              [-36.28374707896397, -8.810832812997653],
              [-36.282640114664098, -8.812155489497652],
              [-36.282524827764114, -8.812242765697661],
              [-36.278657748864582, -8.817467984797636],
              [-36.281940022164157, -8.818845047097659],
              [-36.284330041963855, -8.821537928797666],
              [-36.285197547463738, -8.824664547597671],
              [-36.283530317163937, -8.826775042297653],
              [-36.280919545564245, -8.829492819897652],
              [-36.280446109664304, -8.830589964097646],
              [-36.280444144964299, -8.831722130497635],
              [-36.280491006764294, -8.832571354697643],
              [-36.280529124564289, -8.833103624197635],
              [-36.280399625764304, -8.833525313097638],
              [-36.279524645964401, -8.835232761097638],
              [-36.279055944864467, -8.83551231439764],
              [-36.278087989064581, -8.836913252997636],
              [-36.278037170164588, -8.836944213397627],
              [-36.277956713464597, -8.837052650297633],
              [-36.278295768964547, -8.838695054697622],
              [-36.278341335764516, -8.842920333597622],
              [-36.278941297464449, -8.843337530697623],
              [-36.279456832364382, -8.843611112197632],
              [-36.279927113764323, -8.843504261597632],
              [-36.279951448764315, -8.844031806397627],
              [-36.279930737464319, -8.844718792197634],
              [-36.280759726564213, -8.845301897997636],
              [-36.281208860664151, -8.845564932397643],
              [-36.281594290064099, -8.845824762697637],
              [-36.282087419264052, -8.846016363697641],
              [-36.282117231964051, -8.843494115397643],
              [-36.282322901464028, -8.842962157697649],
              [-36.283060738263927, -8.842543555397649],
              [-36.283543588663868, -8.842627839397659],
              [-36.284074213863811, -8.841857418197661],
              [-36.284079839463807, -8.84098904169765],
              [-36.284760296863723, -8.840742709997656],
              [-36.285567942363627, -8.840916841997663],
              [-36.286181399563553, -8.840940312497667],
              [-36.28670905866349, -8.841404322497674],
              [-36.287437352163387, -8.841768496497682],
              [-36.288051678363331, -8.83767814339768],
              [-36.289776257163126, -8.835762469397682],
              [-36.289020848563219, -8.835237636597693],
              [-36.285163548963702, -8.834448842097672],
              [-36.285202926763702, -8.833604635797663],
              [-36.285222484563697, -8.831954486797665],
              [-36.284718326463768, -8.830177306097665],
              [-36.284456925963809, -8.829673496197666],
              [-36.284892833163759, -8.828441252597658],
              [-36.284636075763792, -8.828087958897653],
              [-36.284341043463826, -8.827567985097664],
              [-36.285821965863647, -8.826173355697664],
              [-36.285871297563645, -8.826130061797665],
              [-36.286587167863551, -8.82508292429767],
              [-36.286595523363559, -8.824924854497675],
              [-36.287837571463406, -8.824449052997677],
              [-36.288739476263295, -8.82453648629769],
              [-36.288824434563274, -8.826336489397688],
              [-36.292399665962819, -8.830715843697709],
              [-36.293903436262639, -8.82970562299772],
              [-36.294924414662511, -8.828958695397718],
              [-36.296930305062268, -8.827351052997722],
              [-36.296720949262301, -8.826843223297722],
              [-36.299164559062007, -8.825410611297741],
              [-36.297588129662202, -8.823147621897739],
              [-36.296022677662407, -8.821990284497726],
              [-36.295596885662455, -8.820995937297724],
              [-36.298905515162062, -8.820343921497747],
              [-36.297930693462185, -8.817750284497729],
              [-36.297604265062219, -8.817642704897734],
              [-36.297192310062272, -8.817866582197727],
              [-36.296733804962336, -8.815488339497733],
              [-36.297138271062295, -8.814469941597727],
              [-36.297196314562285, -8.814339821297743],
              [-36.298113321862182, -8.813512026497738],
              [-36.297940542062207, -8.813032130797732],
              [-36.29869929006211, -8.812386834297742],
              [-36.298616225262123, -8.812109628997749],
              [-36.298256904562166, -8.812182887497734],
              [-36.297884447362229, -8.809819843497733],
              [-36.297422132862273, -8.810264595897742],
              [-36.297011509562324, -8.811010271297736],
              [-36.296756429162357, -8.811269675497725],
              [-36.296392706462399, -8.811405644497736],
              [-36.295532249762509, -8.811570787797734],
              [-36.295152595962549, -8.812152844497724],
              [-36.295070020662557, -8.812256641897715],
              [-36.2947041046626, -8.812600328997712],
              [-36.294635368262611, -8.812707271197713],
              [-36.29456167166262, -8.812689008597722],
              [-36.294484085162644, -8.80925362359771],
              [-36.29459674006263, -8.808905423597716],
              [-36.294616252362637, -8.807553751397721],
              [-36.294537048062651, -8.807181079897713],
              [-36.294480890062651, -8.806452954397725],
              [-36.294448831862653, -8.805783232997721],
              [-36.294482511562656, -8.80528579729773],
              [-36.294506305462647, -8.805238446997715],
              [-36.294219014162692, -8.805099047997727],
              [-36.292998634262844, -8.803981021597719],
              [-36.29202387826296, -8.803331551697712],
              [-36.291346643563053, -8.802486955097711],
              [-36.291009780563094, -8.802196895197698],
              [-36.288661237563389, -8.800649886497686],
              [-36.288223209763444, -8.800506534197687],
              [-36.287983248963471, -8.80023088539769],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0374000',
        uf: 'PE',
        nome_proje: 'PA QUEIMADA DA ONÇA',
        municipio: 'ARCOVERDE',
        area_hecta: '715.5957',
        capacidade: 28.0,
        num_famili: 26.0,
        fase: 3.0,
        data_de_cr: '27/12/2007',
        forma_obte: 'Desapropriação',
        data_obten: '21/12/2006',
        area_calc_: 715.427,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.033276136748086, -8.306791805624956],
              [-37.026503446717982, -8.319873191073974],
              [-37.02120844503149, -8.330072099735011],
              [-37.015526751501255, -8.335477986172787],
              [-37.014283973216152, -8.336660644083286],
              [-37.012219807826533, -8.340701485071156],
              [-37.010726413759791, -8.343600954976502],
              [-37.009742323605536, -8.345511536790209],
              [-37.013848498074104, -8.348800081397297],
              [-37.017095522586345, -8.344097449963181],
              [-37.017494223770051, -8.343622121328082],
              [-37.02854025283991, -8.34598186097071],
              [-37.034311520678663, -8.347214704369506],
              [-37.038211689569792, -8.324429087717283],
              [-37.039891854916746, -8.31461170481341],
              [-37.033276136748086, -8.306791805624956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0390000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA SANTA ROSA',
        municipio: 'BELO JARDIM',
        area_hecta: '574.3513',
        capacidade: 50.0,
        num_famili: 35.0,
        fase: 3.0,
        data_de_cr: '15/09/2010',
        forma_obte: 'Desapropriação',
        data_obten: '15/07/2008',
        area_calc_: 574.3705,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.295011970656724, -8.156703686487873],
              [-36.287885911047439, -8.167472136759654],
              [-36.286853968946744, -8.166113170877203],
              [-36.275387991687246, -8.17564113994877],
              [-36.283074105042658, -8.182688934245036],
              [-36.284458159255756, -8.182129407992534],
              [-36.285893555587471, -8.183779374398314],
              [-36.286235713106201, -8.183713543381611],
              [-36.28852677486821, -8.184775671637681],
              [-36.293635763512981, -8.18111819245339],
              [-36.295950844073019, -8.182537285950042],
              [-36.302521488465082, -8.177076996025173],
              [-36.302538004471678, -8.17682943355414],
              [-36.304367469947834, -8.175570440059788],
              [-36.309821905150478, -8.171067169473551],
              [-36.295013019177496, -8.156702835749376],
              [-36.295011970656724, -8.156703686487873],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0288000',
        uf: 'PE',
        nome_proje: 'PA NOVA CONQUISTA',
        municipio: 'OROBO',
        area_hecta: '209.6294',
        capacidade: 39.0,
        num_famili: 35.0,
        fase: 5.0,
        data_de_cr: '23/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '11/01/2002',
        area_calc_: 209.6943,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.549583305256526, -7.688705350517426],
              [-35.547308373748614, -7.688548697564097],
              [-35.547374855875681, -7.691309561326066],
              [-35.547394895514643, -7.692147444194023],
              [-35.547394804926789, -7.692147444732162],
              [-35.547400563842984, -7.692383728316597],
              [-35.547407487739051, -7.692693746970933],
              [-35.547407578327025, -7.692693746432751],
              [-35.547436319501067, -7.694002586403034],
              [-35.547438764477981, -7.694169304682002],
              [-35.54745734004382, -7.695413951805206],
              [-35.547475297077611, -7.696615495986279],
              [-35.547502534414683, -7.696746822539478],
              [-35.547647817788601, -7.699334725945852],
              [-35.547699563709799, -7.700320130915572],
              [-35.547657905374265, -7.701765008456666],
              [-35.547601735674093, -7.702044315451136],
              [-35.547909705952534, -7.706005588971327],
              [-35.547897390457528, -7.707006063007199],
              [-35.547986186568778, -7.708825323712921],
              [-35.548004645760969, -7.710519257615299],
              [-35.547997870271246, -7.71099370878189],
              [-35.547991152670164, -7.712404438458814],
              [-35.548197824547145, -7.713472897700637],
              [-35.550695117692158, -7.713365550786444],
              [-35.550934048247363, -7.71335554055309],
              [-35.551172888155413, -7.713345530730175],
              [-35.554717076297251, -7.713196772011838],
              [-35.554735235926373, -7.711950919629897],
              [-35.554745484160186, -7.711243355387993],
              [-35.554745574751585, -7.711243354846974],
              [-35.554756739650252, -7.710477135297762],
              [-35.554774336318786, -7.709272946578658],
              [-35.554774245727806, -7.709272947119549],
              [-35.554801288881606, -7.707423373120316],
              [-35.554790889568885, -7.706731837108477],
              [-35.554788167792005, -7.706549939467075],
              [-35.554785446043034, -7.706368041823489],
              [-35.554771738455401, -7.705457108252737],
              [-35.554732184149252, -7.702819005179379],
              [-35.554718694596311, -7.701913763394814],
              [-35.554718604006901, -7.701913763935179],
              [-35.554690256572783, -7.70001065956809],
              [-35.554688734935205, -7.699907918440284],
              [-35.554688644346221, -7.699907918980505],
              [-35.554655484210045, -7.697684844392324],
              [-35.554551106881142, -7.690692757387612],
              [-35.554551197468186, -7.690692756848065],
              [-35.554526572400725, -7.689045375050184],
              [-35.552650588381113, -7.688916378638512],
              [-35.552650587840773, -7.688916288271965],
              [-35.550456590790326, -7.688765406728982],
              [-35.54958339530382, -7.688705259612319],
              [-35.549583305256526, -7.688705350517426],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0324000',
        uf: 'PE',
        nome_proje: 'PA SÃO JAQUES',
        municipio: 'CANHOTINHO',
        area_hecta: '320.0000',
        capacidade: 92.0,
        num_famili: 64.0,
        fase: 3.0,
        data_de_cr: '09/11/2005',
        forma_obte: 'Compra e Venda',
        data_obten: '07/12/1984',
        area_calc_: 325.5603,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.169526241479723, -8.793041952696974],
              [-36.169092547379783, -8.79296258499698],
              [-36.169037109679792, -8.793299052496975],
              [-36.168633280079852, -8.794323456496979],
              [-36.168673806279841, -8.79478230659697],
              [-36.168723688879837, -8.795159072196967],
              [-36.168306084179903, -8.795035055296964],
              [-36.168144628179924, -8.794961091496967],
              [-36.167999135879938, -8.794859267396966],
              [-36.167895979079965, -8.794812603396961],
              [-36.167555519280015, -8.794752961596958],
              [-36.167417417580026, -8.794714988196958],
              [-36.167248282380065, -8.794615224396967],
              [-36.167077254680088, -8.794468439296967],
              [-36.16675502638013, -8.794378715296961],
              [-36.166606159680157, -8.794276865496958],
              [-36.166505602280161, -8.794140129896949],
              [-36.165413822980334, -8.794977470196951],
              [-36.16455935168046, -8.795527811496939],
              [-36.164471267680476, -8.795599568196939],
              [-36.164160146480519, -8.795911324796947],
              [-36.163840462180559, -8.796227775196938],
              [-36.164439889280473, -8.796650539996943],
              [-36.163953087180538, -8.797236229096937],
              [-36.16343388458062, -8.798042624996933],
              [-36.16263529868074, -8.79830409529694],
              [-36.162349863280781, -8.798406029296926],
              [-36.161618908480889, -8.798651218796927],
              [-36.161416796180916, -8.798744826796927],
              [-36.160797529581018, -8.798950565496911],
              [-36.160444615181063, -8.799048630896907],
              [-36.159312363181243, -8.79945036919691],
              [-36.159461073981213, -8.799457145096916],
              [-36.159547156581198, -8.799907111496918],
              [-36.159527652281199, -8.800197190196908],
              [-36.159625009481189, -8.800389715796911],
              [-36.15950844118121, -8.800598818296905],
              [-36.159486341881205, -8.800649211396916],
              [-36.159500264381201, -8.800902840096903],
              [-36.159458263781211, -8.801064425596907],
              [-36.159233033881229, -8.8046254496969],
              [-36.15970789918115, -8.805797490196907],
              [-36.15991310948111, -8.806182928896918],
              [-36.159759088781136, -8.806755918896906],
              [-36.159414526281175, -8.808852948596913],
              [-36.159188494181208, -8.810133295696911],
              [-36.158944134481246, -8.811513105496907],
              [-36.159820723981106, -8.811649561196914],
              [-36.160679381380973, -8.811776925796915],
              [-36.161238669380886, -8.811697793696924],
              [-36.161877784980796, -8.811174892296933],
              [-36.162613938380687, -8.810546979196918],
              [-36.163486281280555, -8.810194011496939],
              [-36.163653610980525, -8.810275190196942],
              [-36.163799527780505, -8.810343303196937],
              [-36.164000713680473, -8.810402870796933],
              [-36.164087983080464, -8.810366831796937],
              [-36.164138737280453, -8.810303218296935],
              [-36.164122983080453, -8.810184308896945],
              [-36.164001994480479, -8.810040447096931],
              [-36.164750019980367, -8.80897150189694],
              [-36.164669693480377, -8.808882441796936],
              [-36.164969162880332, -8.808637174696951],
              [-36.165255337580291, -8.808668055196952],
              [-36.165609972280244, -8.808696583396943],
              [-36.165836850680201, -8.808989231896941],
              [-36.166263297480135, -8.809207896696959],
              [-36.167136747380006, -8.808965243896962],
              [-36.16829018107984, -8.808659152096972],
              [-36.168505425379799, -8.808614885996965],
              [-36.168871680779752, -8.808539299996964],
              [-36.169760649279617, -8.808380351396982],
              [-36.16995258137959, -8.808294497696986],
              [-36.170095042979568, -8.808203794496974],
              [-36.17043010917952, -8.807938824196983],
              [-36.17064505827949, -8.807785430696985],
              [-36.170836456579458, -8.807621786696988],
              [-36.171026209679432, -8.807452534096985],
              [-36.171322313179388, -8.807132427596985],
              [-36.171414784379373, -8.807105142996983],
              [-36.171513844479357, -8.807099173096994],
              [-36.171860701279307, -8.807206989496992],
              [-36.172327331979233, -8.807396290496982],
              [-36.172773660379164, -8.807592153697003],
              [-36.173111202779111, -8.807741310296997],
              [-36.17362451437905, -8.807932639996999],
              [-36.173843092579006, -8.807967860197012],
              [-36.174061356278969, -8.808044489096998],
              [-36.174193252878958, -8.808123201397009],
              [-36.174258354878944, -8.808180276797012],
              [-36.174302500278934, -8.808293649997013],
              [-36.174403261078922, -8.808508178797016],
              [-36.174982566578834, -8.80940273009702],
              [-36.175572686078738, -8.810237213797008],
              [-36.175806099778697, -8.810615586497017],
              [-36.17612551467866, -8.81107798779701],
              [-36.176511426078591, -8.811674076297013],
              [-36.176864142678546, -8.811455680197017],
              [-36.177174788378494, -8.811146869997026],
              [-36.177531836778442, -8.810636921797029],
              [-36.178561582578297, -8.809830781097039],
              [-36.178720683078282, -8.808436575697035],
              [-36.178825333778263, -8.80821475199704],
              [-36.179025602578243, -8.807751049697048],
              [-36.179193756578215, -8.80723970629704],
              [-36.179276062178204, -8.806772487497042],
              [-36.179362690778191, -8.806625399597044],
              [-36.18085558277798, -8.804574981397057],
              [-36.180963869577965, -8.804242263497049],
              [-36.18099551087797, -8.804075735797054],
              [-36.180872707777979, -8.803948342397055],
              [-36.180457210378044, -8.803587451097043],
              [-36.179504313078191, -8.80271809649704],
              [-36.178652536978319, -8.801899736897038],
              [-36.179109083578254, -8.801118583897049],
              [-36.179247351478239, -8.800912563997043],
              [-36.179350325678222, -8.800761121397036],
              [-36.180078691678119, -8.80026404949705],
              [-36.180175433778103, -8.800192584497044],
              [-36.180248708778095, -8.800174106897041],
              [-36.180357285478081, -8.800099932497055],
              [-36.180450093878058, -8.800027878397051],
              [-36.180664388878029, -8.799811235097055],
              [-36.181707379477892, -8.798551523797055],
              [-36.18141389977793, -8.798304214397056],
              [-36.181225952977954, -8.798161783697045],
              [-36.180993975477996, -8.798039169097052],
              [-36.179972120078155, -8.797474136997053],
              [-36.179548449878212, -8.797258308597042],
              [-36.178831033578312, -8.798390064397037],
              [-36.178282614478384, -8.798600843197041],
              [-36.176928703178596, -8.798796624197031],
              [-36.176116618778714, -8.798909167597024],
              [-36.176018039978729, -8.798703609797021],
              [-36.175968484378743, -8.798116764897024],
              [-36.175493666478808, -8.797821083697022],
              [-36.175059394578874, -8.797223516497006],
              [-36.174806384078913, -8.796759378297017],
              [-36.17413610417902, -8.795829868497012],
              [-36.173455900279123, -8.795318872797008],
              [-36.172634789479247, -8.794694895897004],
              [-36.171930297579358, -8.794123278297],
              [-36.171393258479441, -8.793725278097],
              [-36.170810436179522, -8.793429901496989],
              [-36.170545669879566, -8.79332942039699],
              [-36.170217866479625, -8.79323294219698],
              [-36.169526241479723, -8.793041952696974],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0236000',
        uf: 'PE',
        nome_proje: 'PA BANANEIRAS',
        municipio: 'QUIPAPA',
        area_hecta: '990.3978',
        capacidade: 43.0,
        num_famili: 40.0,
        fase: 4.0,
        data_de_cr: '07/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '30/01/2001',
        area_calc_: 960.3262,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.025318143203542, -8.864934459695849],
              [-36.022441518904074, -8.865379618495819],
              [-36.02187320760418, -8.865467562095827],
              [-36.017911936604932, -8.865298775495777],
              [-36.01745012340502, -8.865654037595784],
              [-36.017230542805052, -8.866102628995773],
              [-36.016425350505195, -8.86774757849577],
              [-36.01640708690519, -8.867783848195771],
              [-36.015672878305317, -8.869763279795761],
              [-36.015318281005378, -8.870471352795764],
              [-36.015391669705373, -8.870921054395762],
              [-36.014540105605512, -8.87312385439575],
              [-36.010437801606308, -8.871144724295716],
              [-36.009835045306417, -8.871383571395716],
              [-36.004248078707505, -8.869536736895661],
              [-36.004276155607492, -8.870836236195652],
              [-36.001431855608047, -8.870755647595631],
              [-36.00168272430799, -8.871729918695632],
              [-36.000163394508256, -8.874823287595625],
              [-35.999611363408363, -8.876051033095605],
              [-35.999696133908337, -8.876733898795614],
              [-36.000030415908263, -8.877552849995622],
              [-36.000173966108235, -8.878297468795624],
              [-36.000009409208261, -8.879291464995609],
              [-36.000569628408144, -8.880360340395619],
              [-36.000922695508059, -8.882181239995612],
              [-35.997352659408747, -8.883682853995593],
              [-35.997101488608791, -8.883788498195591],
              [-35.996460671108935, -8.881678688395576],
              [-35.995728158809079, -8.880567188595583],
              [-35.99475730100928, -8.879894143895559],
              [-35.994572953609321, -8.877809410995566],
              [-35.995256683409195, -8.877174684595577],
              [-35.995077140809236, -8.876193078595565],
              [-35.991275406009954, -8.881117979595544],
              [-35.990353495710153, -8.878982591395527],
              [-35.982996311411618, -8.876398193195465],
              [-35.980830786612046, -8.875806380895432],
              [-35.980212781412156, -8.87893630429544],
              [-35.975702863413062, -8.877635520095392],
              [-35.972335209813764, -8.875328853695361],
              [-35.969592740414321, -8.874921317995323],
              [-35.969827130614256, -8.877325600595338],
              [-35.96896214311441, -8.879405171695327],
              [-35.974017662013367, -8.883033298095375],
              [-35.976186813512925, -8.884520190095389],
              [-35.977222951612703, -8.886309139995408],
              [-35.981863254711762, -8.887839780495449],
              [-35.983845379911365, -8.888420007995471],
              [-35.98722559091069, -8.890697176095495],
              [-35.994729479109196, -8.89315291329557],
              [-36.004138151307359, -8.895654162895642],
              [-36.00817724070658, -8.896463479995687],
              [-36.009642597606295, -8.89731127569569],
              [-36.01090521610606, -8.896490048995705],
              [-36.013285059705602, -8.896665358095731],
              [-36.012817495705697, -8.895492359395721],
              [-36.011652318005929, -8.894437118195718],
              [-36.012241154705826, -8.892947191995724],
              [-36.012417350205808, -8.891322152095732],
              [-36.012410631205825, -8.888350264795729],
              [-36.01347287010563, -8.887377103595735],
              [-36.013047396905719, -8.885546543095739],
              [-36.013166142105703, -8.884271093095741],
              [-36.014162515305522, -8.883250188995747],
              [-36.015688525705229, -8.883326275695758],
              [-36.018757639004654, -8.882332801195782],
              [-36.019964125704433, -8.881586428495797],
              [-36.021153234104226, -8.880247144295804],
              [-36.021431338204174, -8.880149238895813],
              [-36.021615058804144, -8.879917326595816],
              [-36.021491407604167, -8.879849870695812],
              [-36.021588741804152, -8.878964925395815],
              [-36.021529789004163, -8.877383497795821],
              [-36.023085673703896, -8.876048637795833],
              [-36.023086527403898, -8.87504434699582],
              [-36.023514633803828, -8.874321677995823],
              [-36.02416197890372, -8.87229545269583],
              [-36.024894368503581, -8.870745805395838],
              [-36.025068965603559, -8.869236852395851],
              [-36.024983657203578, -8.868950410495849],
              [-36.024588249703655, -8.868532428495847],
              [-36.024768988903624, -8.868550548295836],
              [-36.024790809503621, -8.868406868195837],
              [-36.025318143203542, -8.864934459695849],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0360000',
        uf: 'PE',
        nome_proje: 'PA SANTO ANTÔNIO II',
        municipio: 'ALTINHO',
        area_hecta: '200.4871',
        capacidade: 17.0,
        num_famili: 14.0,
        fase: 4.0,
        data_de_cr: '10/08/2006',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 200.2174,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.002508895387244, -8.495445439088749],
              [-36.002238947362947, -8.49556233064534],
              [-36.002139902158568, -8.495755515959159],
              [-36.002100735985053, -8.49577463620321],
              [-36.001916523852884, -8.495822991577564],
              [-36.001521971679772, -8.496059157777934],
              [-36.000500998275172, -8.496640451409906],
              [-36.000133445357356, -8.496811692654022],
              [-35.99965166911101, -8.497097409708191],
              [-35.999386280526807, -8.497374496397759],
              [-35.9991883040339, -8.49760576115985],
              [-35.998965618141405, -8.498237106164449],
              [-35.99892575915716, -8.498648361494357],
              [-35.998896002743557, -8.498810823920113],
              [-35.998885598657836, -8.4988185124131],
              [-35.998779167491833, -8.499015975060363],
              [-35.998700089646761, -8.499126566472018],
              [-35.998524509848316, -8.499243098113261],
              [-35.998444537732709, -8.499282047520632],
              [-35.998292180614143, -8.499352778072646],
              [-35.998183019652842, -8.49938797917277],
              [-35.998085366463357, -8.49948352191339],
              [-35.9979884601698, -8.499553054140335],
              [-35.997881718027756, -8.499615554702162],
              [-35.997722988257685, -8.499630680283627],
              [-35.997584165044479, -8.499618046181496],
              [-35.997463103859964, -8.499573390172634],
              [-35.997235144360708, -8.499622940546463],
              [-35.996970681830959, -8.499652516171611],
              [-35.996897167770371, -8.499724396626135],
              [-35.996759648615381, -8.499742395536552],
              [-35.996512197679515, -8.499964423287837],
              [-35.996327039170623, -8.500145649111758],
              [-35.996093609985614, -8.500210061525275],
              [-35.995969240834278, -8.500357068225664],
              [-35.995864098354339, -8.500435479176037],
              [-35.995843511015408, -8.500422492763331],
              [-35.995522620034002, -8.500369066162795],
              [-35.995515128510341, -8.500596830527895],
              [-35.995508229278919, -8.500806803674589],
              [-35.995510602448206, -8.501014590088376],
              [-35.995515193257027, -8.501426819356251],
              [-35.99553903618903, -8.501580932577122],
              [-35.995566327629909, -8.501746635185853],
              [-35.99561538053824, -8.501902749830066],
              [-35.995807086542101, -8.501941086877972],
              [-35.996140852345697, -8.501914486878732],
              [-35.996415090944026, -8.501899802645942],
              [-35.996554066030647, -8.501904669878554],
              [-35.99667093433311, -8.50191686408415],
              [-35.996856353088688, -8.501993544073967],
              [-35.996966503765535, -8.502145884796606],
              [-35.997147372473357, -8.502270858301754],
              [-35.997399889252598, -8.502330621695302],
              [-35.997617785086859, -8.50237340587516],
              [-35.99787083945634, -8.502445729603416],
              [-35.998079812489543, -8.502550684914192],
              [-35.998208641193514, -8.502611751503073],
              [-35.998366789264729, -8.502496712051526],
              [-35.998548301009549, -8.502317805871595],
              [-35.998711993506852, -8.50217823808749],
              [-35.998895693821666, -8.501997903178195],
              [-35.998934652560621, -8.501958908402537],
              [-35.999001047496229, -8.501904045821719],
              [-35.999068356086106, -8.501848377264281],
              [-35.999182437417112, -8.504071660297631],
              [-35.999399382500471, -8.50487767058913],
              [-35.99936637627556, -8.505667027473313],
              [-35.999360125235398, -8.505817205067299],
              [-35.999278588606984, -8.507769330776156],
              [-35.999278679342687, -8.507769331476656],
              [-35.99923107879021, -8.510967066277793],
              [-35.999254582871771, -8.513353516285875],
              [-35.999253599679619, -8.517797228562248],
              [-35.998547282415913, -8.518014352005741],
              [-35.998448760428438, -8.521185853613865],
              [-35.999179918969872, -8.521298102172363],
              [-36.001551707633737, -8.52217090727421],
              [-36.002753396826407, -8.522397806627071],
              [-36.005244255034036, -8.523994191020938],
              [-36.005210366288829, -8.522717862230579],
              [-36.005033669960497, -8.517055768372657],
              [-36.004723428679661, -8.510150453708054],
              [-36.005761241892706, -8.510256194279844],
              [-36.005437892781337, -8.505276440333166],
              [-36.005199649256035, -8.505282645055562],
              [-36.004923178170309, -8.503389087689612],
              [-36.004900170048906, -8.503383942076139],
              [-36.004845272582841, -8.503208721644237],
              [-36.00498008821129, -8.502779857138954],
              [-36.005096712705559, -8.502765217696142],
              [-36.005130818264782, -8.501844246964636],
              [-36.005465089950533, -8.500315917847971],
              [-36.005631895597936, -8.499553150459359],
              [-36.005241080535704, -8.499482933259854],
              [-36.004903246487657, -8.499507613684418],
              [-36.004958451431385, -8.498217968712277],
              [-36.004946758441768, -8.498204689841208],
              [-36.003673269677286, -8.496763262521389],
              [-36.002932908622263, -8.495925309342432],
              [-36.002508895387244, -8.495445439088749],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0411000',
        uf: 'PE',
        nome_proje: 'PA JUSSARA',
        municipio: 'IATI',
        area_hecta: '558.2172',
        capacidade: 21.0,
        num_famili: 21.0,
        fase: 3.0,
        data_de_cr: '14/07/2014',
        forma_obte: 'Desapropriação',
        data_obten: '23/12/2011',
        area_calc_: 560.4204,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.928566591231281, -9.153587634130737],
              [-36.926700510271097, -9.149972641253239],
              [-36.926698247172048, -9.149971691346879],
              [-36.926693840535854, -9.149967688805393],
              [-36.926688941116097, -9.149962390811677],
              [-36.926684542192156, -9.149955486669322],
              [-36.926679143825432, -9.149948386982148],
              [-36.926672843766347, -9.149941490042698],
              [-36.926652139959579, -9.149908685159675],
              [-36.926639941437415, -9.149893085699288],
              [-36.926624845383735, -9.149870491251857],
              [-36.926618745428549, -9.149858885933403],
              [-36.926608442059667, -9.149845882580435],
              [-36.9265952389758, -9.149824691028225],
              [-36.926586346667762, -9.149811487841419],
              [-36.926567240517265, -9.149782389207886],
              [-36.926562647248993, -9.149774489620016],
              [-36.926554946894861, -9.14975808426791],
              [-36.926549939654173, -9.149750986817564],
              [-36.926535845126146, -9.149725089681038],
              [-36.926533242574713, -9.149718991281411],
              [-36.926522742156578, -9.149703889661305],
              [-36.926514442863144, -9.149690382527197],
              [-36.926505144075882, -9.149676688887087],
              [-36.926488342744086, -9.149646988759349],
              [-36.926478243122176, -9.149631789998413],
              [-36.926464044516521, -9.14960658829879],
              [-36.926458642299274, -9.149598584649166],
              [-36.926444044175831, -9.149571690297472],
              [-36.926425242293895, -9.149541888329585],
              [-36.92641104582583, -9.149517888880588],
              [-36.926400442253147, -9.149500183324385],
              [-36.926389147115898, -9.149479382339782],
              [-36.926368440423538, -9.149447083640563],
              [-36.926363543089003, -9.149438287412618],
              [-36.926360043914194, -9.149433087822022],
              [-36.926354340680682, -9.149422090411287],
              [-36.926350545255438, -9.14941778402544],
              [-36.926347645829445, -9.149412687299408],
              [-36.926345344073226, -9.149406482147743],
              [-36.926342540040842, -9.149402190473262],
              [-36.926337146405437, -9.149398986788562],
              [-36.926327939734236, -9.149396086706288],
              [-36.926316839351173, -9.149393483128593],
              [-36.926307539538804, -9.149389389313392],
              [-36.926299641567269, -9.149386686545277],
              [-36.926290240202682, -9.149385990959553],
              [-36.926279039766378, -9.149383386808283],
              [-36.926268644596966, -9.149378382786244],
              [-36.926251539445694, -9.149366587948183],
              [-36.926243340910531, -9.149362382920568],
              [-36.926233340839538, -9.149351984642369],
              [-36.926226940800262, -9.1493497876099],
              [-36.926210442528479, -9.149346285363457],
              [-36.926201538902788, -9.149342889845943],
              [-36.926169246714814, -9.149325783342618],
              [-36.926153139510923, -9.149317582848814],
              [-36.926146038640077, -9.149313890305894],
              [-36.926141343658301, -9.149310988916328],
              [-36.926137842585931, -9.149307687585493],
              [-36.926134146014235, -9.149303589669707],
              [-36.926121943381155, -9.149294986664735],
              [-36.926114344021656, -9.149290983929827],
              [-36.926087344041733, -9.149278888405703],
              [-36.926067243846632, -9.149269083168269],
              [-36.926057242685253, -9.14926358422956],
              [-36.926048846071737, -9.149255888860873],
              [-36.926042542928293, -9.149246388553705],
              [-36.926036143467748, -9.149239382566819],
              [-36.92602854325461, -9.149232387787864],
              [-36.926018344693198, -9.14922799052434],
              [-36.926007544481486, -9.149225388656305],
              [-36.925990045521424, -9.149212488750369],
              [-36.92598054295712, -9.149207281923575],
              [-36.925960639944876, -9.149197984015775],
              [-36.925941644961803, -9.149187389631086],
              [-36.925922939719527, -9.149173886220529],
              [-36.925884941066251, -9.149149488414873],
              [-36.925875639940692, -9.149142483900448],
              [-36.925857541313682, -9.149123189710968],
              [-36.925847442864629, -9.149118783977219],
              [-36.925838343628222, -9.149116182799473],
              [-36.925829940057071, -9.149109689626243],
              [-36.925815240195064, -9.149094084876467],
              [-36.925806939035866, -9.149087185516471],
              [-36.925782643477042, -9.149069889724545],
              [-36.925758839322313, -9.149050987732549],
              [-36.925744840820471, -9.149036788099799],
              [-36.925737342051811, -9.149027986019838],
              [-36.92573004091129, -9.149021182775439],
              [-36.925720440555182, -9.149015586690405],
              [-36.925702242567546, -9.149000883936383],
              [-36.925693842511293, -9.148993785142242],
              [-36.925688141258256, -9.148985589948122],
              [-36.925683642085154, -9.148975584716448],
              [-36.925681237881946, -9.148965085268268],
              [-36.925680138289806, -9.148955488185507],
              [-36.925676446415899, -9.148934884372572],
              [-36.925675042175769, -9.148924490080301],
              [-36.925669743748664, -9.148903289513196],
              [-36.925655243937598, -9.148862583526657],
              [-36.925651641341084, -9.148851688526687],
              [-36.925643743416039, -9.148833284332559],
              [-36.925637838853561, -9.148813083668736],
              [-36.925624642952521, -9.148784389450475],
              [-36.925613642103059, -9.148766184443874],
              [-36.925595840739106, -9.148742688634384],
              [-36.925584038305601, -9.148729387427089],
              [-36.925582344079949, -9.14872508402229],
              [-36.925580338239868, -9.148721185606561],
              [-36.925575542992526, -9.148715183128727],
              [-36.925567940894979, -9.148710086604734],
              [-36.925564842002309, -9.148709589782237],
              [-36.925563645213892, -9.148710486871716],
              [-36.925563140282698, -9.14871128848741],
              [-36.925563244422328, -9.148710584011228],
              [-36.92487993816647, -9.148391686450035],
              [-36.924880440216491, -9.148391381985089],
              [-36.924880643782132, -9.148390786551145],
              [-36.924880638773345, -9.148390081450581],
              [-36.92487623798236, -9.148389785075826],
              [-36.92487294307427, -9.14838858205421],
              [-36.92486784111189, -9.148385280590125],
              [-36.924853438769176, -9.148373284233307],
              [-36.924851242325147, -9.148370885262034],
              [-36.924847643088512, -9.148360984611521],
              [-36.924842544187371, -9.148355586027884],
              [-36.924841742962457, -9.148352580365838],
              [-36.924842837298819, -9.148347388985499],
              [-36.924852241207077, -9.148335085984085],
              [-36.924851838824949, -9.14832918096339],
              [-36.924846938878673, -9.148323982385298],
              [-36.924842842474931, -9.148316682231],
              [-36.924837338298495, -9.148310585294814],
              [-36.924825636798666, -9.148304986203785],
              [-36.924819143844772, -9.148295285932498],
              [-36.924815237917194, -9.14829278678082],
              [-36.92480974271102, -9.14828828082714],
              [-36.924804236985224, -9.148280882210681],
              [-36.924801544844776, -9.148272984456746],
              [-36.924801337688343, -9.148264784550555],
              [-36.924802642969276, -9.148254586559892],
              [-36.924806640131436, -9.148244485334176],
              [-36.92481223802551, -9.148235984203643],
              [-36.924817644152697, -9.148229181379103],
              [-36.92482143964429, -9.148222486845945],
              [-36.9248230438062, -9.148214080363859],
              [-36.924825840668731, -9.148205481843215],
              [-36.924827044384813, -9.148198682078782],
              [-36.924824939256887, -9.148193083688787],
              [-36.924821844993858, -9.148187081903385],
              [-36.924817141877256, -9.148180881080657],
              [-36.924809540380132, -9.148175685115614],
              [-36.924800439629131, -9.148171782238645],
              [-36.924780243204317, -9.148169180787386],
              [-36.924769039672533, -9.148163982279305],
              [-36.924760341402745, -9.148154983493836],
              [-36.924753937283846, -9.148144081492923],
              [-36.924751442330439, -9.148131981555009],
              [-36.924755944589698, -9.148124188264012],
              [-36.924760045176257, -9.148119782441913],
              [-36.924760440866102, -9.148114288762525],
              [-36.924760539030643, -9.148095785708163],
              [-36.9247628425138, -9.148076581167683],
              [-36.924765842105906, -9.148059685654799],
              [-36.924766441770728, -9.148050387562916],
              [-36.924767642207136, -9.148042584408547],
              [-36.92476844336813, -9.14801578722518],
              [-36.92476894027574, -9.148005385740335],
              [-36.924772237554755, -9.147985780116485],
              [-36.924770041470452, -9.147980181205858],
              [-36.924766041037373, -9.147973586673038],
              [-36.924765043123934, -9.147965282806831],
              [-36.924765044414862, -9.147958783498021],
              [-36.924766145101806, -9.147947788870351],
              [-36.924765343837187, -9.147940082729185],
              [-36.924765738771171, -9.147936288449323],
              [-36.924767839164751, -9.147928580840645],
              [-36.924769544081116, -9.147918484570893],
              [-36.924770637891775, -9.147913383581363],
              [-36.924775443022455, -9.147901985309447],
              [-36.924778944563151, -9.147895786259312],
              [-36.924782944642388, -9.147889888362704],
              [-36.924787245113009, -9.147882383175503],
              [-36.92479014490732, -9.147873288077841],
              [-36.924792039555051, -9.147864982691717],
              [-36.924793039253515, -9.147857286860345],
              [-36.924795141374794, -9.147849280961498],
              [-36.924799539361857, -9.147843783075233],
              [-36.924801541239866, -9.147842086091767],
              [-36.924800237211613, -9.14784108429464],
              [-36.924797936504049, -9.147840980730171],
              [-36.924797038453569, -9.147838986924919],
              [-36.924799439047398, -9.147835981513461],
              [-36.9248024397317, -9.147834588547394],
              [-36.924802845185489, -9.147833686930158],
              [-36.924801839012432, -9.147833084570953],
              [-36.92480093732545, -9.147833287314983],
              [-36.924798840811924, -9.147832479847565],
              [-36.924798343426019, -9.147831979834178],
              [-36.924797638168322, -9.147829679793585],
              [-36.924798439749374, -9.147829485515869],
              [-36.924797844395712, -9.147825486700462],
              [-36.924800940398519, -9.147810788308226],
              [-36.924802839299581, -9.147804887422106],
              [-36.924809042127357, -9.147790884768684],
              [-36.924810237719242, -9.14778548606181],
              [-36.924810339858332, -9.147781988405267],
              [-36.924795536207604, -9.147708982958086],
              [-36.924797241025843, -9.14770988761634],
              [-36.924799038981369, -9.147711986006257],
              [-36.924799536943496, -9.147712386589699],
              [-36.924800136164237, -9.147712579847012],
              [-36.924800642247696, -9.147711579372547],
              [-36.924800741452543, -9.147708587904662],
              [-36.924801942681668, -9.147703785829311],
              [-36.92480614109391, -9.147693486887647],
              [-36.924819041110837, -9.147672779191559],
              [-36.924824140800062, -9.147665486483985],
              [-36.924834044910838, -9.147648485863014],
              [-36.924843536829158, -9.14763198004802],
              [-36.924850636180942, -9.147612387146484],
              [-36.924852237003776, -9.147602986311977],
              [-36.924849138402934, -9.14758517906812],
              [-36.924850142530829, -9.147578287766528],
              [-36.924856538542066, -9.147565479415302],
              [-36.924861942475673, -9.147557483376371],
              [-36.924868338476642, -9.147549384543524],
              [-36.924874137614424, -9.147540685694601],
              [-36.924883739252714, -9.147524081072289],
              [-36.92488853895528, -9.147515186674974],
              [-36.924891141515701, -9.147505583668231],
              [-36.924891038493726, -9.147496679285624],
              [-36.924891838656734, -9.147488882877793],
              [-36.924894939577477, -9.147481180994191],
              [-36.924902443842178, -9.147465486382337],
              [-36.924909742192312, -9.147446084444189],
              [-36.924915440210377, -9.147426582951498],
              [-36.924919738978694, -9.147408384159942],
              [-36.924926040072265, -9.14738997988426],
              [-36.924928544075847, -9.147381687023302],
              [-36.924932137370732, -9.147373780051476],
              [-36.924936740694413, -9.147367379398675],
              [-36.924941243734878, -9.147362587182071],
              [-36.924946342537808, -9.147358584801898],
              [-36.924952441298522, -9.147354687578517],
              [-36.924958539136114, -9.147349380205764],
              [-36.924964639979656, -9.147341984752387],
              [-36.924972643581292, -9.147324882851898],
              [-36.924978341905323, -9.14731788282705],
              [-36.924984743936015, -9.147311881162201],
              [-36.924988437229764, -9.147303983801114],
              [-36.924990939064351, -9.147285078689682],
              [-36.924990537287073, -9.147268082347567],
              [-36.924991844766744, -9.147248085673766],
              [-36.924993239261021, -9.147238186490211],
              [-36.924996137188295, -9.147230980609002],
              [-36.925005943395952, -9.147219882136195],
              [-36.925010537796965, -9.147211881459571],
              [-36.925014138579044, -9.147202681896987],
              [-36.925017838085026, -9.14719528173689],
              [-36.925028141853197, -9.147183083306002],
              [-36.925032944702721, -9.147176783226234],
              [-36.92503614549323, -9.147170681882766],
              [-36.925041442524886, -9.14715758701405],
              [-36.925043937479735, -9.147149285060076],
              [-36.925046040377524, -9.147123878346411],
              [-36.92504654112728, -9.147114380819115],
              [-36.925045543152677, -9.147106085991533],
              [-36.925043843652155, -9.147097986015181],
              [-36.925038745244102, -9.147083087046834],
              [-36.925037044591235, -9.147075185930404],
              [-36.92503683702418, -9.147065485482663],
              [-36.925038842535805, -9.147056882427604],
              [-36.925042441009793, -9.147048080583875],
              [-36.925044436796696, -9.147039585945681],
              [-36.925045838654491, -9.147029985103213],
              [-36.925046439815162, -9.147012578688949],
              [-36.925049143071455, -9.146996585410443],
              [-36.925048943446932, -9.146987083874579],
              [-36.925047643183454, -9.146977585078675],
              [-36.925047843195898, -9.146968185263303],
              [-36.925050536702585, -9.146958582773884],
              [-36.925054737799584, -9.14694938664586],
              [-36.925062340818577, -9.146935482389797],
              [-36.92506654138613, -9.146931086171797],
              [-36.925071944074254, -9.146929580397597],
              [-36.925077043845128, -9.146926979124022],
              [-36.925081540714963, -9.146921680663162],
              [-36.925083740916058, -9.146915582631234],
              [-36.9250857456529, -9.146892986603042],
              [-36.925086645641265, -9.146877380736026],
              [-36.925087241655589, -9.146870279190551],
              [-36.925088841660028, -9.146862586791705],
              [-36.925093536810522, -9.146848186804462],
              [-36.925096939083318, -9.14684027873561],
              [-36.925100942023448, -9.146833883681719],
              [-36.925105941739403, -9.146831281834725],
              [-36.92511093869782, -9.146827586206349],
              [-36.925115339774123, -9.146819982153168],
              [-36.925119238978759, -9.146811082595564],
              [-36.92512114500861, -9.146802379536588],
              [-36.925118044337829, -9.146777078630802],
              [-36.925119342755423, -9.146761782382558],
              [-36.925126236948834, -9.146741483221577],
              [-36.92512773770239, -9.146732081810391],
              [-36.925127042241876, -9.146723381941625],
              [-36.925125439335346, -9.146715879117773],
              [-36.925126541776557, -9.146709286675806],
              [-36.925131538384754, -9.146704081467337],
              [-36.925138939204061, -9.146698383812424],
              [-36.925147041200113, -9.146690981726239],
              [-36.925153141049428, -9.146682185155758],
              [-36.925155842492956, -9.146672781576246],
              [-36.925156343812688, -9.1466631846179],
              [-36.925154343749057, -9.14665358238377],
              [-36.925150744509956, -9.146643681737368],
              [-36.925142338950657, -9.146624985380969],
              [-36.925134039083908, -9.14660688622881],
              [-36.925129843836906, -9.14659937760865],
              [-36.925127045425015, -9.146590982083318],
              [-36.925125244316234, -9.146581579854306],
              [-36.925122741630254, -9.146572381516124],
              [-36.925118843785711, -9.146563780832297],
              [-36.925113739035666, -9.146554685114639],
              [-36.925107342514288, -9.146547181975352],
              [-36.925103444670263, -9.146538581291543],
              [-36.925101839182268, -9.146528384716248],
              [-36.925104039456421, -9.146511285752092],
              [-36.92510013833121, -9.146501681677755],
              [-36.92509493801527, -9.146493380879045],
              [-36.925092343445236, -9.146487481390645],
              [-36.925092939281377, -9.146481979815015],
              [-36.925093138361888, -9.146475879369365],
              [-36.925090237319303, -9.146460080000242],
              [-36.925086540910627, -9.14644968163163],
              [-36.925081245327569, -9.146438984850679],
              [-36.92506903880205, -9.146420085953704],
              [-36.925057942459901, -9.146407385388923],
              [-36.925048144732919, -9.146393480978409],
              [-36.925036241654901, -9.146370877669844],
              [-36.925032444443083, -9.14636217812863],
              [-36.925030041236859, -9.14635307979311],
              [-36.925025540143729, -9.146344981862317],
              [-36.92501294313162, -9.146330681776801],
              [-36.925008837065775, -9.146323481005838],
              [-36.925006345607969, -9.146315484970163],
              [-36.925005741106382, -9.1463067856224],
              [-36.92500433963697, -9.146297485114081],
              [-36.924999542471369, -9.146288680416763],
              [-36.924988643712275, -9.146273278205951],
              [-36.924981943528735, -9.146266378966903],
              [-36.924966538538186, -9.146253183693922],
              [-36.924953743153729, -9.14623858417248],
              [-36.924935645374994, -9.146223881988833],
              [-36.924903544302808, -9.146208385548103],
              [-36.924895241491669, -9.146201784474741],
              [-36.924889036966967, -9.146195683537252],
              [-36.924883237540648, -9.146191880921815],
              [-36.924877440243016, -9.146189280556561],
              [-36.924864242794214, -9.146181277482418],
              [-36.924856141642948, -9.146175979233902],
              [-36.924850343546296, -9.146170377788284],
              [-36.924845744934281, -9.146164982074231],
              [-36.924840940453869, -9.146160579514136],
              [-36.924836540842513, -9.146160084285102],
              [-36.924834242274045, -9.146161182973508],
              [-36.924772538568057, -9.146229583359187],
              [-36.924773043496018, -9.146228781744089],
              [-36.924773038434118, -9.146228085682576],
              [-36.924775942115289, -9.14621988550271],
              [-36.924775443532873, -9.146214883869874],
              [-36.92477244178972, -9.146210184288149],
              [-36.924770238218663, -9.146205877968372],
              [-36.924770143306212, -9.146200282046747],
              [-36.924766537158511, -9.146196284077485],
              [-36.924759544633986, -9.146195882478997],
              [-36.924753338252046, -9.146196380279022],
              [-36.924746438590695, -9.146194080941262],
              [-36.92472573717037, -9.146193881096433],
              [-36.924712238406883, -9.146190784677865],
              [-36.92469943758536, -9.146189680918047],
              [-36.924686641596196, -9.14619088222814],
              [-36.924676939014951, -9.14619038376151],
              [-36.924656040415364, -9.146194386097182],
              [-36.924646939357686, -9.146193683167034],
              [-36.924642237681219, -9.146195084477002],
              [-36.924639641080738, -9.146197383695931],
              [-36.924639436940495, -9.146198078559918],
              [-36.924635637700376, -9.146197577725562],
              [-36.924629439165543, -9.14619358299508],
              [-36.924592737740383, -9.146183980993051],
              [-36.924576340092074, -9.146181979820057],
              [-36.924560738080331, -9.146182378637123],
              [-36.92453384425157, -9.146189682179273],
              [-36.924522136523493, -9.146189877302252],
              [-36.92449413740507, -9.146193278540458],
              [-36.924481340442142, -9.146193078733347],
              [-36.924470142379299, -9.146190085870304],
              [-36.924458144740889, -9.146190080465193],
              [-36.9244334441576, -9.146187778652534],
              [-36.924422339630411, -9.14618748005933],
              [-36.924393736326977, -9.146194683410437],
              [-36.924379738854363, -9.146192885796022],
              [-36.924352740953978, -9.146180482876819],
              [-36.9243398427869, -9.146180481351809],
              [-36.924325538008588, -9.146181484184357],
              [-36.924312935568622, -9.14618067984649],
              [-36.924300741641765, -9.146176876772437],
              [-36.924288038699345, -9.146174580360546],
              [-36.92424974241306, -9.146171784888582],
              [-36.92421253847651, -9.146167278184357],
              [-36.924185140795267, -9.146165783497597],
              [-36.924162043426222, -9.146163084073882],
              [-36.924149538159412, -9.146162777452789],
              [-36.924138844275539, -9.146160682365563],
              [-36.924116338721859, -9.14615317737732],
              [-36.924091540132061, -9.146147385780573],
              [-36.9240665414898, -9.14614158399751],
              [-36.924043042147048, -9.146134480087316],
              [-36.924032242447673, -9.146133378723137],
              [-36.92402124186949, -9.146138685128548],
              [-36.924009935187073, -9.146146584082709],
              [-36.923994037542293, -9.146152477125476],
              [-36.923967435318318, -9.146154982382894],
              [-36.923955037024307, -9.146153483169654],
              [-36.923942438228451, -9.146150482271047],
              [-36.923931741249653, -9.146145783819151],
              [-36.923920540331835, -9.146138578573465],
              [-36.923907341347196, -9.146133983306466],
              [-36.923879438402103, -9.146131780632684],
              [-36.923866736620262, -9.146129285347214],
              [-36.923847441002415, -9.146120379541969],
              [-36.923837240832214, -9.146116280531558],
              [-36.923827535162111, -9.146113178684129],
              [-36.923816640601174, -9.14611118187217],
              [-36.92379393610868, -9.146103476860464],
              [-36.923769638393352, -9.146092879162509],
              [-36.923756237001335, -9.146088680464318],
              [-36.923731940111367, -9.146085784319014],
              [-36.92368963622544, -9.146080778311912],
              [-36.923674237759343, -9.146075881181837],
              [-36.923659936074728, -9.146070077354974],
              [-36.923649636251028, -9.146067478306007],
              [-36.923636436910954, -9.146066082968003],
              [-36.923622835416602, -9.146061883119453],
              [-36.923603540085395, -9.14606547876364],
              [-36.923584237517673, -9.146067185135418],
              [-36.923572542355231, -9.146066783701496],
              [-36.923560942997398, -9.146063978341521],
              [-36.923549037033567, -9.146062283068655],
              [-36.923536242442161, -9.146061676470863],
              [-36.923524938608509, -9.146064377782823],
              [-36.923515340734696, -9.146067784896553],
              [-36.923490041315212, -9.146082482660418],
              [-36.923468442593979, -9.146094281898185],
              [-36.923458936122778, -9.14609918103071],
              [-36.923438738136824, -9.146101578297889],
              [-36.923428537820037, -9.146103779721482],
              [-36.923407535321388, -9.146106877450439],
              [-36.923398139123321, -9.14610687787086],
              [-36.923377439245364, -9.146103279153618],
              [-36.923365542195207, -9.14610318389524],
              [-36.92335423429612, -9.146101880734472],
              [-36.923342540681318, -9.146102780971862],
              [-36.923324437308679, -9.146112584438816],
              [-36.923308334916101, -9.146123981256315],
              [-36.923294842864614, -9.146136983954301],
              [-36.923286343132908, -9.146142386013519],
              [-36.923277835904223, -9.146149080660164],
              [-36.923272834487477, -9.146156681246005],
              [-36.923267942612341, -9.146165782995396],
              [-36.923259740548239, -9.146173184460649],
              [-36.923250439814673, -9.14617868136378],
              [-36.923234140010813, -9.146191179849788],
              [-36.923223641078742, -9.146194681226383],
              [-36.9232028355365, -9.146195185769477],
              [-36.923192842295748, -9.146196186134743],
              [-36.92318233835703, -9.146198982410066],
              [-36.923149636649825, -9.146211784672312],
              [-36.923138641541357, -9.146214578132678],
              [-36.923125736385636, -9.146211078286136],
              [-36.923114538527294, -9.14620648541541],
              [-36.923080142560003, -9.146200882838755],
              [-36.923072438403111, -9.146199283934163],
              [-36.923067740975654, -9.14619367973158],
              [-36.923064036135784, -9.146187882348693],
              [-36.923059839181228, -9.146185381518345],
              [-36.923056437852097, -9.146185280679774],
              [-36.92305393794544, -9.14618188563335],
              [-36.923048337313361, -9.146179882966965],
              [-36.923026740468899, -9.146185083441603],
              [-36.923014936758065, -9.146189282408455],
              [-36.92299173670601, -9.14619488046857],
              [-36.922981236434687, -9.146200180665273],
              [-36.922969834088519, -9.146204183063457],
              [-36.922944539378335, -9.146202383952373],
              [-36.922934533766941, -9.146202380869038],
              [-36.922924439712055, -9.146205080051391],
              [-36.922899739409736, -9.14621527959085],
              [-36.922875035778489, -9.146224484778189],
              [-36.922863942367364, -9.146231679841188],
              [-36.922852234040334, -9.146236683852633],
              [-36.922829140043824, -9.146239679169291],
              [-36.922818134426798, -9.146239579959655],
              [-36.922791939950649, -9.146236085319993],
              [-36.9227787402763, -9.146233180372903],
              [-36.922775135699716, -9.146230484067262],
              [-36.922777938627021, -9.146228683211879],
              [-36.922779537405404, -9.146227481095668],
              [-36.922781634903927, -9.146224980167053],
              [-36.922779438877605, -9.14622408172707],
              [-36.922772741278507, -9.1462245857175],
              [-36.92274843690366, -9.146222982093274],
              [-36.922721839353272, -9.146224682778694],
              [-36.922708035867828, -9.146227080481321],
              [-36.922694340191143, -9.146231277636144],
              [-36.922679138813976, -9.146237879675812],
              [-36.922664937609916, -9.146238277396664],
              [-36.922644439169737, -9.14622818048316],
              [-36.92263363812355, -9.146224177431487],
              [-36.922622139352406, -9.146221282224589],
              [-36.922611736041951, -9.146223979625672],
              [-36.922602233988606, -9.146229683261724],
              [-36.922586933151521, -9.146240885767472],
              [-36.922566635703035, -9.146247883453146],
              [-36.922537234257732, -9.146249983869717],
              [-36.922524734770789, -9.146248682894415],
              [-36.922500333994606, -9.146244882133864],
              [-36.922488840672102, -9.14624418352116],
              [-36.922475339060426, -9.146241584172005],
              [-36.922451240602499, -9.146234277864782],
              [-36.922424841789827, -9.14622837755587],
              [-36.92241273957962, -9.146227585058167],
              [-36.922399836234092, -9.146228478331665],
              [-36.922373437048343, -9.14622577795433],
              [-36.922360635622027, -9.14622478258204],
              [-36.922334138880046, -9.146220083942106],
              [-36.92232094056893, -9.146220080624866],
              [-36.922296237106103, -9.146222985329459],
              [-36.922273441139986, -9.146220079609153],
              [-36.922243838829637, -9.14622387825243],
              [-36.922226241306468, -9.146224880209362],
              [-36.92220103968701, -9.146224283816437],
              [-36.92218924081611, -9.14622137783731],
              [-36.922178033103243, -9.146213783812973],
              [-36.92216213591044, -9.146196084016792],
              [-36.922143540522789, -9.146184081638401],
              [-36.922130633654824, -9.146180880048091],
              [-36.922114636645425, -9.146180381597841],
              [-36.922101135041913, -9.146177782235853],
              [-36.92208963473805, -9.146173585335346],
              [-36.92207343493785, -9.146176683389941],
              [-36.922054440149957, -9.146181781204406],
              [-36.922007940279073, -9.146170983838278],
              [-36.921984332803156, -9.146155879342123],
              [-36.921974133810089, -9.14614688095269],
              [-36.921964735482462, -9.146136278144496],
              [-36.921952438402009, -9.146125179635678],
              [-36.921943340234201, -9.146114578547525],
              [-36.921936634869724, -9.146103882701086],
              [-36.921914332559744, -9.146084880675396],
              [-36.921905139922131, -9.146074884682928],
              [-36.921899435390941, -9.146067276996918],
              [-36.921891231887166, -9.146062384877002],
              [-36.92188063365225, -9.146054785299738],
              [-36.921870936135619, -9.146045581877338],
              [-36.921861437958455, -9.146038078998496],
              [-36.921852635680338, -9.146034485114923],
              [-36.921843236797692, -9.146033382677132],
              [-36.921819539156203, -9.146021278726778],
              [-36.921791935206393, -9.14600988457382],
              [-36.92178173276028, -9.146001482759324],
              [-36.921760132442884, -9.145993178322286],
              [-36.921734039022134, -9.145986384822386],
              [-36.921709739097672, -9.145976184720485],
              [-36.921696234235618, -9.145972581957245],
              [-36.921671432318249, -9.145962677274836],
              [-36.921634033525592, -9.145952582871685],
              [-36.921624332122484, -9.145947184995636],
              [-36.921615337156219, -9.145939178791981],
              [-36.921605237851089, -9.14593338090255],
              [-36.921587233716586, -9.145916681421168],
              [-36.921574733474678, -9.14590767887195],
              [-36.921544736162467, -9.145891778419202],
              [-36.921504735568575, -9.145875477124875],
              [-36.921493533516966, -9.145868479715308],
              [-36.921486839054019, -9.145862177058564],
              [-36.921479737537084, -9.145850782905359],
              [-36.92147513166978, -9.14583567883996],
              [-36.92146993587837, -9.14582348206854],
              [-36.921470231408932, -9.145810177816426],
              [-36.921469433618277, -9.145797183674572],
              [-36.921466135471768, -9.145785576300931],
              [-36.92146613969588, -9.145773879385994],
              [-36.921459837295565, -9.14574547772774],
              [-36.921459734484962, -9.145730281964735],
              [-36.921463535608595, -9.145700681753459],
              [-36.921461132495359, -9.14568688294492],
              [-36.921461838099852, -9.145671881642775],
              [-36.921472135294692, -9.145634183240597],
              [-36.921463732327723, -9.145624481028273],
              [-36.921455839687603, -9.1456208833132],
              [-36.921458638374816, -9.145611977502677],
              [-36.921465131557532, -9.145599675990407],
              [-36.921473439839836, -9.14558808091399],
              [-36.920551011645451, -9.14546741196733],
              [-36.921653252775805, -9.146676719182116],
              [-36.920879005153161, -9.147603397627286],
              [-36.920243966009316, -9.148275356570807],
              [-36.919608924445861, -9.148947313846914],
              [-36.918897095082613, -9.149674738659824],
              [-36.918185262805416, -9.150402161401846],
              [-36.91747342761402, -9.151129582072626],
              [-36.916761589508184, -9.151857000671866],
              [-36.916049748487659, -9.152584417199165],
              [-36.916271979175846, -9.151678654061421],
              [-36.916494208251279, -9.15077289049424],
              [-36.916716435714129, -9.149867126497616],
              [-36.916938661564586, -9.148961362071617],
              [-36.917160885802787, -9.148055597216281],
              [-36.917383108428886, -9.147149831931694],
              [-36.917605329443127, -9.146244066217845],
              [-36.917827548845636, -9.145338300074785],
              [-36.918058539059786, -9.144315059318819],
              [-36.918289527326131, -9.14329181806745],
              [-36.918353664718595, -9.143172460389493],
              [-36.918611202497836, -9.141966298340884],
              [-36.918537677700321, -9.141783598567018],
              [-36.918930138972392, -9.14075150416951],
              [-36.919015233649191, -9.140534921433114],
              [-36.919252871267531, -9.139332915656061],
              [-36.919490506415229, -9.138130909293507],
              [-36.91972528223247, -9.137180389899513],
              [-36.919960056268714, -9.136229870029391],
              [-36.92019482852411, -9.135279349683247],
              [-36.920429598998879, -9.134328828861079],
              [-36.920664367693213, -9.133378307563001],
              [-36.920899134607346, -9.132427785789023],
              [-36.92113389974142, -9.131477263539217],
              [-36.921368663095699, -9.130526740813639],
              [-36.921603424670323, -9.129576217612289],
              [-36.921838184465557, -9.128625693935312],
              [-36.920851821606689, -9.128500460424387],
              [-36.919865460032035, -9.128375224385538],
              [-36.918879099741851, -9.128249985818977],
              [-36.917892740736434, -9.128124744724698],
              [-36.916906383016105, -9.127999501102874],
              [-36.915920026581098, -9.127874254953682],
              [-36.914933671431719, -9.127749006277101],
              [-36.913947317568258, -9.127623755073357],
              [-36.912960964990994, -9.127498501342483],
              [-36.91197461370021, -9.127373245084696],
              [-36.911902757524459, -9.128354634825198],
              [-36.911830900349628, -9.129336024413929],
              [-36.911759042175618, -9.130317413850921],
              [-36.911687183002371, -9.131298803136101],
              [-36.911615322829824, -9.132280192269498],
              [-36.911543461657914, -9.133261581251125],
              [-36.911471599486539, -9.134242970080924],
              [-36.911399736315687, -9.135224358758895],
              [-36.91130261743659, -9.136483692682344],
              [-36.91125957036985, -9.136514502879205],
              [-36.911189131750255, -9.137467951356035],
              [-36.911118692183798, -9.13842139968852],
              [-36.911048251670394, -9.139374847876667],
              [-36.910977810210007, -9.140328295920513],
              [-36.910907367802515, -9.141281743820006],
              [-36.910836924447892, -9.142235191575134],
              [-36.910766480146073, -9.143188639185947],
              [-36.910696034897008, -9.144142086652376],
              [-36.91063249374109, -9.145065451280646],
              [-36.910568951720386, -9.145988815780619],
              [-36.910505408834887, -9.146912180152247],
              [-36.910441865084501, -9.147835544395555],
              [-36.910378320469199, -9.148758908510532],
              [-36.910314774988905, -9.149682272497152],
              [-36.910251228643567, -9.150605636355406],
              [-36.91018768143315, -9.151529000085306],
              [-36.910124133357584, -9.152452363686857],
              [-36.910060584416826, -9.153375727159988],
              [-36.909997034610804, -9.15429909050477],
              [-36.909933483939476, -9.155222453721112],
              [-36.909869932402785, -9.156145816809092],
              [-36.909806380000688, -9.157069179768648],
              [-36.909742826733101, -9.157992542599739],
              [-36.909679272600016, -9.158915905302443],
              [-36.909539686580658, -9.160318251156713],
              [-36.909476427103556, -9.161221181837201],
              [-36.90941316679308, -9.162124112393267],
              [-36.909349905649215, -9.163027042824801],
              [-36.909286643671877, -9.163929973131864],
              [-36.909223380861015, -9.164832903314416],
              [-36.909160117216601, -9.165735833372478],
              [-36.909096852738564, -9.166638763306013],
              [-36.909033587426855, -9.16754169311503],
              [-36.908970321281416, -9.168444622799521],
              [-36.908907054302205, -9.16934755235947],
              [-36.908843786489179, -9.170250481794865],
              [-36.908780517842267, -9.171153411105726],
              [-36.908717248361441, -9.172056340291991],
              [-36.908653978046594, -9.172959269353729],
              [-36.908590706897762, -9.17386219829087],
              [-36.909554411966134, -9.173514858075366],
              [-36.910518115661546, -9.173167514864137],
              [-36.91148181798382, -9.17282016865742],
              [-36.912445518932778, -9.172472819455551],
              [-36.912164257977139, -9.171440231321839],
              [-36.911882998025092, -9.170407643276681],
              [-36.91214672270597, -9.169875169575977],
              [-36.912412817862517, -9.169099032888012],
              [-36.913209453341658, -9.168047342594889],
              [-36.914006083822784, -9.166995649443459],
              [-36.914819969352578, -9.166529607573453],
              [-36.915633853032901, -9.166063563386794],
              [-36.916447734863766, -9.165597516883821],
              [-36.91726161484516, -9.165131468064807],
              [-36.917957798358593, -9.164466735284762],
              [-36.91865397930281, -9.163802000565317],
              [-36.919350157678011, -9.163137263906755],
              [-36.92004633348435, -9.162472525309367],
              [-36.92079642266502, -9.161874387592388],
              [-36.921546509457663, -9.161276247743475],
              [-36.922296593862448, -9.160678105762941],
              [-36.923046675879448, -9.160079961651075],
              [-36.923796755508796, -9.159481815408206],
              [-36.924546832750629, -9.158883667034642],
              [-36.925296907605052, -9.158285516530659],
              [-36.926046980072194, -9.157687363896628],
              [-36.926797050152167, -9.157089209132794],
              [-36.927547117845108, -9.156491052239481],
              [-36.928297183151123, -9.155892893217038],
              [-36.929047246070354, -9.155294732065732],
              [-36.929802850411178, -9.154641212922206],
              [-36.930558452074628, -9.153987691567936],
              [-36.930557753410675, -9.153987389276283],
              [-36.93055624531226, -9.153985491424313],
              [-36.930557347959507, -9.153988290901342],
              [-36.930552444501728, -9.153988389442535],
              [-36.93053104457131, -9.153986197171259],
              [-36.930505746605014, -9.153981695660011],
              [-36.93049384422126, -9.153980895474598],
              [-36.930469947760223, -9.153980993988135],
              [-36.930459049436394, -9.153979593896864],
              [-36.930434045419403, -9.153974596892517],
              [-36.930421351846967, -9.153972192209194],
              [-36.930409952043185, -9.153970997158162],
              [-36.930387646439264, -9.153969694606563],
              [-36.930366644902662, -9.153969393838825],
              [-36.930342850385173, -9.153967594650316],
              [-36.930330047502991, -9.153966789315149],
              [-36.930300047444128, -9.153966889105252],
              [-36.93028794651736, -9.153967389454039],
              [-36.930280649151783, -9.153966190718586],
              [-36.93027645303934, -9.153965091055694],
              [-36.930268745044998, -9.153965688816692],
              [-36.930259146871386, -9.153964395581188],
              [-36.930249048057071, -9.153961589903684],
              [-36.930238547657623, -9.153960589813574],
              [-36.930206951930636, -9.153964495110534],
              [-36.930180952062116, -9.153963397424981],
              [-36.930163944803503, -9.15396449345689],
              [-36.930154551811242, -9.153963897425507],
              [-36.930136151667575, -9.153965193404227],
              [-36.930127648406383, -9.153964891716814],
              [-36.930117848811676, -9.153965396169299],
              [-36.930107643817159, -9.153966793206713],
              [-36.930086949821472, -9.15396679248051],
              [-36.930075947942569, -9.153965997426864],
              [-36.930064443790336, -9.153968688714919],
              [-36.930054152691824, -9.153972390308084],
              [-36.930032047619385, -9.153972589422494],
              [-36.93001064825809, -9.153976589119566],
              [-36.929999445531813, -9.153977492324769],
              [-36.929977449127236, -9.153982491982619],
              [-36.929967545449962, -9.153983691870627],
              [-36.929956051814322, -9.153982993436841],
              [-36.929942248650384, -9.153978991461168],
              [-36.929929952216831, -9.153978695212693],
              [-36.929906845638797, -9.153982197019998],
              [-36.929897043885767, -9.153987790640803],
              [-36.929889352971095, -9.153993296889624],
              [-36.929879945382467, -9.15399679561952],
              [-36.929869145070306, -9.153998894321861],
              [-36.929857648396414, -9.154000293010139],
              [-36.929835151712389, -9.154005289799859],
              [-36.929792746576361, -9.154011293707626],
              [-36.929771046633306, -9.154013791130096],
              [-36.929740652037765, -9.154019194750349],
              [-36.929720544928493, -9.154023093340912],
              [-36.92969914729084, -9.154026794727548],
              [-36.929678943990638, -9.154031596706538],
              [-36.929608746183931, -9.154039792004838],
              [-36.929576450066115, -9.154043693240906],
              [-36.929554046967212, -9.154045092862829],
              [-36.929532244613938, -9.154047996458157],
              [-36.929521350627077, -9.1540489918064],
              [-36.929484443190603, -9.154048093872417],
              [-36.929460651810693, -9.154048897992448],
              [-36.929423848348044, -9.154048895547728],
              [-36.929402149308544, -9.154048093569223],
              [-36.929368547730157, -9.154048091340869],
              [-36.929345449012274, -9.154047091531277],
              [-36.929298547272531, -9.154044192980981],
              [-36.929274549371641, -9.154041389185769],
              [-36.929251845399847, -9.154038195051397],
              [-36.929220048825471, -9.154032797563042],
              [-36.929199344098826, -9.154026794559398],
              [-36.929186542542205, -9.154024190349972],
              [-36.929140848276553, -9.154010695461372],
              [-36.929118750998853, -9.154001692450676],
              [-36.929098347925233, -9.153992292350503],
              [-36.929057642988482, -9.153973293859114],
              [-36.929047743263972, -9.153969097219981],
              [-36.929029148205771, -9.153958496172239],
              [-36.929018149598164, -9.153953994950271],
              [-36.92900794255705, -9.153947889239365],
              [-36.928998542457606, -9.153939094399091],
              [-36.928988948299882, -9.1539323955908],
              [-36.928978147502406, -9.153928293232468],
              [-36.92895754284411, -9.153922290789177],
              [-36.928938147870994, -9.153911594770914],
              [-36.928919847230368, -9.153901989736347],
              [-36.9289021498019, -9.153891890982385],
              [-36.928883448889252, -9.153882292697441],
              [-36.928874544135105, -9.153877496102778],
              [-36.92886714941416, -9.153872690062009],
              [-36.92884844850375, -9.153863091775722],
              [-36.928833645257932, -9.153854293160569],
              [-36.928814948840589, -9.153845490366001],
              [-36.928787649323574, -9.153828493883733],
              [-36.928772544929956, -9.153818292437682],
              [-36.928732249945853, -9.153788195875743],
              [-36.928723646733388, -9.153781593121936],
              [-36.928716050460281, -9.153773893317984],
              [-36.928677643984649, -9.153728693669761],
              [-36.928662248378991, -9.153709089578031],
              [-36.928647348394371, -9.153690292826433],
              [-36.928636650803227, -9.153677793446397],
              [-36.92862934911075, -9.153669489685864],
              [-36.928625548333464, -9.153666096258043],
              [-36.928623043723597, -9.153663496669033],
              [-36.928622451094277, -9.153660591632802],
              [-36.928622344004118, -9.153657092772177],
              [-36.92862294179244, -9.15365439340906],
              [-36.928624346817315, -9.153652096394989],
              [-36.928624347727144, -9.153648797018722],
              [-36.928622147941311, -9.153645394665839],
              [-36.928617049322348, -9.153641496650437],
              [-36.928607647157946, -9.153631490512105],
              [-36.928603346679893, -9.15362799479367],
              [-36.928593647302485, -9.153622190277355],
              [-36.928590242309426, -9.153617994605554],
              [-36.928588346772337, -9.153613888918882],
              [-36.928588945544902, -9.153612590668645],
              [-36.928588649984114, -9.153611793511459],
              [-36.928586447728904, -9.153610388851993],
              [-36.928582442644853, -9.15360929026367],
              [-36.928578550909194, -9.153605896315518],
              [-36.9285765428818, -9.153600795651503],
              [-36.92857424925883, -9.153597889929019],
              [-36.928571949198755, -9.153596096016059],
              [-36.928569444422465, -9.15359509639987],
              [-36.928567344525547, -9.153593294591957],
              [-36.928565445315115, -9.153591394498086],
              [-36.92856554668915, -9.153589596236886],
              [-36.928566047185733, -9.153587990085182],
              [-36.928566591231281, -9.153587634130737],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0079000',
        uf: 'PE',
        nome_proje: 'PA BOM CONSELHO',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1001.3930',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 5.0,
        data_de_cr: '17/03/2003',
        forma_obte: 'Desapropriação',
        data_obten: '09/10/2001',
        area_calc_: 1014.4268,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.295424088816759, -8.94858277030081],
              [-40.284558985690694, -8.950869083775544],
              [-40.284212315160232, -8.9520273029786],
              [-40.283519422932997, -8.95436168074125],
              [-40.292167623969974, -8.958811891911379],
              [-40.29245508495179, -8.957775212775594],
              [-40.29270066405055, -8.95690155024902],
              [-40.293024138678611, -8.955912260588324],
              [-40.293628721363412, -8.954256391354511],
              [-40.29423392362694, -8.952708677617968],
              [-40.294736334444494, -8.951032919069155],
              [-40.295077772132004, -8.949714877646006],
              [-40.295424088816759, -8.94858277030081],
            ],
          ],
          [
            [
              [-40.295630094723222, -8.94859476405585],
              [-40.295209226234498, -8.949751312965521],
              [-40.294867800297993, -8.951069223740124],
              [-40.294363085726197, -8.952752736930627],
              [-40.293756457230899, -8.954304158352283],
              [-40.293153159036372, -8.955956386240354],
              [-40.292831296323612, -8.956940775592022],
              [-40.292586900610985, -8.957810200520136],
              [-40.292300251286498, -8.958886014574635],
              [-40.295345761290577, -8.960459182274603],
              [-40.296315413131005, -8.960908083845542],
              [-40.319097685528291, -8.972600601919964],
              [-40.319156610062315, -8.972594965829874],
              [-40.323499178537851, -8.969963312817585],
              [-40.32789251658108, -8.96732897246741],
              [-40.330767178853066, -8.965595600460988],
              [-40.335245212105924, -8.962912935701517],
              [-40.335310674675668, -8.962855304962904],
              [-40.335296053533717, -8.962791434514575],
              [-40.332874038631786, -8.958897449905002],
              [-40.330130990292155, -8.954346327357532],
              [-40.327648601132744, -8.950182253941918],
              [-40.327682158996119, -8.950004017823614],
              [-40.327791750583096, -8.945113591867518],
              [-40.326019632552949, -8.945076180055926],
              [-40.323568273681857, -8.945123566635148],
              [-40.318543005155192, -8.945404023980474],
              [-40.313485129883475, -8.945858315087369],
              [-40.308312488435675, -8.946468011068468],
              [-40.298873343478235, -8.947977196611607],
              [-40.298142196099278, -8.948150245361402],
              [-40.296554892545394, -8.948416113785115],
              [-40.295630103817622, -8.948594764024037],
              [-40.295630094723222, -8.94859476405585],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0045000',
        uf: 'PE',
        nome_proje: 'PA VOLTA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '632.2852',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 4.0,
        data_de_cr: '02/06/2000',
        forma_obte: 'Confisco',
        data_obten: '02/06/2000',
        area_calc_: 637.7432,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.850805301161529, -8.522220972407842],
              [-38.847853543824392, -8.52184589364232],
              [-38.847664101230102, -8.521822934719522],
              [-38.84731531788433, -8.521253937316972],
              [-38.847430830980151, -8.520961637809139],
              [-38.848400693697869, -8.520547470258968],
              [-38.848845262516328, -8.519692588850072],
              [-38.831845395593049, -8.497445865094271],
              [-38.83140415610881, -8.49689409019058],
              [-38.831518029150715, -8.497455040315986],
              [-38.831800988003764, -8.498020676573823],
              [-38.831800776818504, -8.498505325193568],
              [-38.831881464956645, -8.49890932440454],
              [-38.831840856866172, -8.499313271234373],
              [-38.831517006539535, -8.499797779926604],
              [-38.831233678442416, -8.500282396340749],
              [-38.83127381045162, -8.50096904453237],
              [-38.831354321936168, -8.501777008025732],
              [-38.831677804157813, -8.502140679709758],
              [-38.831839536724708, -8.50234264130126],
              [-38.832041596353179, -8.502787125438557],
              [-38.832526792681058, -8.503191208328907],
              [-38.832971665775638, -8.503554931166386],
              [-38.833578252731265, -8.503959155295192],
              [-38.833901543664403, -8.504565149386522],
              [-38.83426535920885, -8.505171160470228],
              [-38.834821902067631, -8.505750207546283],
              [-38.834899364631724, -8.506058776506746],
              [-38.835077867358429, -8.507846751359562],
              [-38.836020995205551, -8.510426153232265],
              [-38.896121221542828, -8.591679224866878],
              [-38.899310687309004, -8.590290711897721],
              [-38.899595929087077, -8.59041298844646],
              [-38.899922645355687, -8.590410269374706],
              [-38.900109819552938, -8.590562098231615],
              [-38.900842047449622, -8.590550347506108],
              [-38.90110150078651, -8.590240883655346],
              [-38.87802462045687, -8.560780931440066],
              [-38.877780611759853, -8.559113892807925],
              [-38.877741689664532, -8.557208936436426],
              [-38.850805301161529, -8.522220972407842],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0140000',
        uf: 'PE',
        nome_proje: 'PA SÃO BENEDITO',
        municipio: 'MIRANDIBA',
        area_hecta: '751.2014',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 3.0,
        data_de_cr: '06/07/2005',
        forma_obte: 'Desapropriação',
        data_obten: '06/09/2004',
        area_calc_: 696.3359,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.80537251787289, -8.11265972097898],
              [-38.809631821953943, -8.11338928641165],
              [-38.810184133752507, -8.113422259985924],
              [-38.811008387303687, -8.1132913533789],
              [-38.811542764856277, -8.113083217095602],
              [-38.814921002246436, -8.111367299928469],
              [-38.816850985238041, -8.110459017690124],
              [-38.82278836069618, -8.090144643915437],
              [-38.811557229013047, -8.090834191891453],
              [-38.805372508795955, -8.112659720974628],
              [-38.80537251787289, -8.11265972097898],
            ],
          ],
          [
            [
              [-38.81103827857207, -8.147916781972061],
              [-38.815865367988749, -8.124338927205134],
              [-38.813054100807861, -8.123717796442859],
              [-38.815144087623622, -8.116352792514197],
              [-38.815419250498806, -8.115373909344967],
              [-38.813603679126231, -8.115740308636115],
              [-38.812830390542494, -8.115674209843432],
              [-38.808319553423487, -8.114532908825655],
              [-38.807263104658496, -8.113896929382037],
              [-38.806019552359082, -8.113164078453524],
              [-38.805266578297406, -8.113023559922972],
              [-38.802459101280697, -8.122845060683247],
              [-38.8070053946877, -8.123291039219682],
              [-38.802112315026733, -8.147002835857203],
              [-38.801568845780899, -8.146699946300309],
              [-38.801230489034573, -8.148344774716156],
              [-38.801826429274271, -8.148203077525102],
              [-38.805569103643258, -8.147728772537342],
              [-38.807341353239394, -8.147589395276894],
              [-38.808207160349617, -8.147593699736181],
              [-38.809323115888631, -8.147540236366616],
              [-38.809724441156646, -8.147567916412305],
              [-38.810361121428279, -8.147712968515942],
              [-38.81103827857207, -8.147916781972061],
            ],
          ],
          [
            [
              [-38.815472401702792, -8.115183101401948],
              [-38.8154723926258, -8.115183101397818],
              [-38.816733210121498, -8.110885331576695],
              [-38.815126668159387, -8.111770468918975],
              [-38.811728874537188, -8.113496319520081],
              [-38.811128081529901, -8.113730324145873],
              [-38.810206542615596, -8.11387668143181],
              [-38.809579046065473, -8.113839219254567],
              [-38.806408104174452, -8.113231597639043],
              [-38.807333114127246, -8.113780557551197],
              [-38.808374189085306, -8.114407281966344],
              [-38.812852920472615, -8.115539955600161],
              [-38.813594887569707, -8.115603377113107],
              [-38.815472401702792, -8.115183101401948],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0297000',
        uf: 'PE',
        nome_proje: 'PA XIQUE XIQUE',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '431.8287',
        capacidade: 3.0,
        num_famili: 3.0,
        fase: 3.0,
        data_de_cr: '07/12/2011',
        forma_obte: 'Confisco',
        data_obten: '07/12/2011',
        area_calc_: 432.1122,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.877765763380253, -8.557171414043887],
              [-38.879157572447042, -8.557416346278542],
              [-38.878953901483492, -8.559222479926646],
              [-38.87988560058816, -8.559381795206034],
              [-38.88249711511039, -8.560498482260266],
              [-38.885681293227812, -8.558695428924926],
              [-38.885813804811846, -8.558902045380679],
              [-38.886446709534944, -8.55920433230405],
              [-38.886810564664763, -8.558883512310439],
              [-38.885524779268742, -8.557946384843552],
              [-38.886944183086015, -8.557367612253115],
              [-38.88473547282068, -8.55469431214145],
              [-38.853184184285688, -8.519397528826392],
              [-38.850542171692851, -8.522206786719538],
              [-38.877765763380253, -8.557171414043887],
            ],
          ],
          [
            [
              [-38.848537540035444, -8.519698899281124],
              [-38.849517538496613, -8.517797382579973],
              [-38.850567003359366, -8.51649819104237],
              [-38.847847608630936, -8.51340677260168],
              [-38.847656349544494, -8.513185566562184],
              [-38.831439184689849, -8.494990939926542],
              [-38.831416551773074, -8.494803604550299],
              [-38.830381570418936, -8.494523035828237],
              [-38.829510490962221, -8.494572678101363],
              [-38.829637804309456, -8.494906925086529],
              [-38.829468785645354, -8.496190966494524],
              [-38.829816534744957, -8.496552761292081],
              [-38.830900552788641, -8.496950135441271],
              [-38.831341784522003, -8.497501899645291],
              [-38.848537540035444, -8.519698899281124],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0320000',
        uf: 'PE',
        nome_proje: 'PA SERROTE BRANCO',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '408.7054',
        capacidade: 4.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '23/11/2015',
        forma_obte: 'Confisco',
        data_obten: '18/10/2011',
        area_calc_: 409.0306,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.947334113945971, -8.487225496754293],
              [-38.969034562886883, -8.494276607960801],
              [-38.967808092604727, -8.491848098818835],
              [-38.966334403232096, -8.489571074228078],
              [-38.963064912022197, -8.485863804942831],
              [-38.962310899260061, -8.483061476958939],
              [-38.963057514488419, -8.480557518031873],
              [-38.963165596596525, -8.479970574010222],
              [-38.964174900190677, -8.478984722281647],
              [-38.964445838656921, -8.477877412403489],
              [-38.963292871779707, -8.477224501049157],
              [-38.963086919361515, -8.477084278330635],
              [-38.963411506404462, -8.474558930684656],
              [-38.963539100579197, -8.473210186640728],
              [-38.963586136817305, -8.472516320218565],
              [-38.963740075222475, -8.471185488263695],
              [-38.96387536034316, -8.470149624191738],
              [-38.962207158654877, -8.468008968182694],
              [-38.961667028128765, -8.46726203867874],
              [-38.961225800778095, -8.466875486125364],
              [-38.960675203560072, -8.466347904625067],
              [-38.960377715879659, -8.466742705369505],
              [-38.95993617011063, -8.466817917911873],
              [-38.959043251101789, -8.467110262455272],
              [-38.957895623621354, -8.467027827571489],
              [-38.957833082584919, -8.466516485585949],
              [-38.95713886464479, -8.466720112002642],
              [-38.956939420317177, -8.466131507302578],
              [-38.956449644843367, -8.466097351966196],
              [-38.955771600407985, -8.466284062903027],
              [-38.954880684660978, -8.466521492717103],
              [-38.954060441241822, -8.464535846124779],
              [-38.953548695347209, -8.463633418631927],
              [-38.953546799895157, -8.470349701905933],
              [-38.95323949221941, -8.47072468654374],
              [-38.952918789905411, -8.471418247450638],
              [-38.9520586430246, -8.473257429431026],
              [-38.951301741102021, -8.473304643102399],
              [-38.9495103817409, -8.473392246229677],
              [-38.947334113945971, -8.487225496754293],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0047000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA',
        municipio: 'FLORESTA',
        area_hecta: '121.0000',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 4.0,
        data_de_cr: '29/05/2000',
        forma_obte: 'Adjudicação',
        data_obten: '02/12/1999',
        area_calc_: 121.0912,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.57852154734509, -8.391888466173453],
              [-38.573903377507982, -8.370710727744708],
              [-38.57390192516818, -8.370709912112352],
              [-38.573874659700593, -8.370725983142908],
              [-38.573836851073182, -8.370748736238976],
              [-38.573795059448983, -8.370759183585985],
              [-38.573749167840234, -8.37078138524533],
              [-38.573756508758514, -8.370796136812118],
              [-38.573720060745678, -8.3708205194967],
              [-38.573628082948623, -8.370793284727636],
              [-38.573538056603098, -8.370809016664923],
              [-38.573483918556946, -8.370813933093601],
              [-38.573460302665559, -8.370814812124928],
              [-38.573370327263355, -8.370867177070435],
              [-38.573319389997586, -8.370852016624699],
              [-38.573275435057234, -8.370847265672285],
              [-38.573170140368831, -8.370871664413967],
              [-38.573042754529823, -8.370831275752641],
              [-38.57298770269383, -8.370841256428109],
              [-38.572934016506203, -8.37084825366116],
              [-38.572665894809283, -8.37093280764959],
              [-38.57265360531116, -8.370958392227013],
              [-38.572439065115205, -8.370965305875893],
              [-38.572358391959632, -8.370982766243344],
              [-38.57232067957964, -8.371000453990726],
              [-38.57221490845761, -8.371045203616314],
              [-38.577869150699591, -8.396975137841997],
              [-38.595090034794275, -8.399460559280641],
              [-38.5951546170445, -8.397883324628607],
              [-38.57852154734509, -8.391888466173453],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0140000',
        uf: 'PE',
        nome_proje: 'PA JUNDIÁ DE CIMA',
        municipio: 'TAMANDARE',
        area_hecta: '468.0153',
        capacidade: 40.0,
        num_famili: 37.0,
        fase: 6.0,
        data_de_cr: '09/06/1998',
        forma_obte: 'Desapropriação',
        data_obten: '25/11/1997',
        area_calc_: 467.6676,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.279685848752642, -8.73956971984801],
              [-35.278149172438745, -8.738150986083225],
              [-35.278039534106853, -8.738033604425659],
              [-35.27622797775426, -8.740926744803371],
              [-35.275802118887334, -8.741351485869529],
              [-35.27496752855928, -8.741791706415524],
              [-35.272753163980703, -8.741157753806343],
              [-35.272082631616279, -8.741924806530772],
              [-35.270979663776941, -8.744163552690971],
              [-35.270519858012186, -8.745779492127777],
              [-35.27019748342228, -8.747541743634971],
              [-35.269881594555756, -8.749131603380132],
              [-35.267106255685604, -8.749950047408536],
              [-35.264570534010865, -8.750898720751781],
              [-35.264529504914918, -8.753668440158155],
              [-35.26469049155137, -8.754403936298518],
              [-35.264738537463266, -8.755542671021594],
              [-35.264940256296406, -8.757888931389218],
              [-35.264947299155601, -8.757986784359144],
              [-35.264978163601285, -8.758417822612564],
              [-35.265005865533759, -8.758806670352849],
              [-35.265141519332133, -8.758928508894446],
              [-35.265184731311095, -8.758952924843586],
              [-35.265307001915659, -8.75901012840276],
              [-35.267891726454593, -8.760756856701915],
              [-35.268021154576388, -8.760855592833837],
              [-35.268367684917706, -8.76112809971775],
              [-35.27057663636451, -8.763004499279633],
              [-35.271452984074671, -8.763537657405232],
              [-35.272290028128552, -8.764212400435598],
              [-35.27273954472836, -8.764626546251975],
              [-35.272811099877018, -8.764680165227963],
              [-35.27304079584431, -8.764802699288259],
              [-35.27385036622276, -8.765439289084297],
              [-35.276379827490771, -8.763901805724485],
              [-35.280951055087527, -8.760689291482528],
              [-35.284762600256606, -8.756152430762899],
              [-35.286325118537448, -8.754036692630903],
              [-35.289244342414811, -8.749551033053018],
              [-35.283691734687245, -8.743906236189318],
              [-35.282651327631001, -8.742486343861801],
              [-35.282547255035873, -8.74238827266961],
              [-35.281851799743229, -8.741684039626611],
              [-35.281434858062674, -8.741215744833253],
              [-35.280131475385858, -8.740066315714246],
              [-35.279685848752642, -8.73956971984801],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0142000',
        uf: 'PE',
        nome_proje: 'PA SERRA D ÁGUA',
        municipio: 'RIO FORMOSO',
        area_hecta: '417.7982',
        capacidade: 37.0,
        num_famili: 33.0,
        fase: 5.0,
        data_de_cr: '29/06/1998',
        forma_obte: 'Desapropriação',
        data_obten: '05/12/1997',
        area_calc_: 417.5484,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.163193587050607, -8.644267837306762],
              [-35.162769751376246, -8.646287218273894],
              [-35.162128157821506, -8.648126053373911],
              [-35.161771891866621, -8.649412162215892],
              [-35.161427759882834, -8.650739692228109],
              [-35.161094594435625, -8.652047274423959],
              [-35.160718637618992, -8.653369470721394],
              [-35.16023620401841, -8.655245914692648],
              [-35.159539409983431, -8.657586282499656],
              [-35.1589466142254, -8.659575797369897],
              [-35.158825177045969, -8.659911925180918],
              [-35.15869568703831, -8.661304681901777],
              [-35.157320996826627, -8.660638486555358],
              [-35.157144376600101, -8.661126240851404],
              [-35.157891149081472, -8.661654068303989],
              [-35.158238518544017, -8.662006802070014],
              [-35.158270225090739, -8.66223061258896],
              [-35.158053103612723, -8.662975035990971],
              [-35.157533400727672, -8.663925633383544],
              [-35.157480410696891, -8.666404462354958],
              [-35.15933841624561, -8.666822869066262],
              [-35.160050682988818, -8.668113513988802],
              [-35.163238986878603, -8.669718978664012],
              [-35.16460065072441, -8.670339658843035],
              [-35.164833966528313, -8.671045463704647],
              [-35.164795234037712, -8.671959173431892],
              [-35.164562573490478, -8.672668614956907],
              [-35.161388117464213, -8.672489461399502],
              [-35.158667203257195, -8.67289688589822],
              [-35.159299491900846, -8.673605682150356],
              [-35.164708574063489, -8.673307121536453],
              [-35.167764552026426, -8.673180674707297],
              [-35.170476866118506, -8.673051367639971],
              [-35.170525851041219, -8.673071246159779],
              [-35.171919398133987, -8.672645262741426],
              [-35.174510924736985, -8.671906932887577],
              [-35.175535524213956, -8.671615009574875],
              [-35.176297851343918, -8.671397885646897],
              [-35.177273475225171, -8.671119886423952],
              [-35.177674588731961, -8.671005605135685],
              [-35.178259889145828, -8.670838840615735],
              [-35.178658209083153, -8.670712823655119],
              [-35.181093546337578, -8.669942173435976],
              [-35.178228058194101, -8.668561914802385],
              [-35.176701276043353, -8.667866752495685],
              [-35.175366248450963, -8.667202307907822],
              [-35.174525694649276, -8.666704434096713],
              [-35.173480093453094, -8.663797916845455],
              [-35.17343850901667, -8.663674589729224],
              [-35.171156038350908, -8.656904841184698],
              [-35.171028329843672, -8.656446111072988],
              [-35.170907502852543, -8.656126091308932],
              [-35.170419027442392, -8.647061509495444],
              [-35.167479076081378, -8.645837735024456],
              [-35.165561106118581, -8.645047544760489],
              [-35.165462671211159, -8.644988533887537],
              [-35.163193587050607, -8.644267837306762],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0131000',
        uf: 'PE',
        nome_proje: 'PA AÇUDE GRANDE',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '300.0000',
        capacidade: 47.0,
        num_famili: 47.0,
        fase: 3.0,
        data_de_cr: '30/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '09/07/1997',
        area_calc_: 279.6822,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.24076476022946, -8.045800493671953],
              [-35.239010388281962, -8.046948293603199],
              [-35.236926719261618, -8.048413228187558],
              [-35.230672486635271, -8.05271873938978],
              [-35.231615372644654, -8.059734592838995],
              [-35.235887016303906, -8.062793769347005],
              [-35.244956903417012, -8.064674282964699],
              [-35.246019710040855, -8.063964110677643],
              [-35.246000604243378, -8.062999353051497],
              [-35.247537029045688, -8.05655366688608],
              [-35.248693969251093, -8.053946621036056],
              [-35.248017419347804, -8.052968204236924],
              [-35.248193439905037, -8.051754664325285],
              [-35.24076476022946, -8.045800493671953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0182000',
        uf: 'PE',
        nome_proje: 'PA LARANJEIRAS',
        municipio: 'RIO FORMOSO',
        area_hecta: '439.2970',
        capacidade: 38.0,
        num_famili: 34.0,
        fase: 5.0,
        data_de_cr: '25/01/1999',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/1997',
        area_calc_: 439.0113,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.212388811194231, -8.717689816583707],
              [-35.21217328085654, -8.718438941887923],
              [-35.211587857839312, -8.719116826843464],
              [-35.210622161524675, -8.71987420742108],
              [-35.209848193473668, -8.720701063553328],
              [-35.208669855414769, -8.721635212485769],
              [-35.207941821872069, -8.721220679384528],
              [-35.206734133593628, -8.720824570074969],
              [-35.206509011899847, -8.720870620774575],
              [-35.206219027984979, -8.721365916437051],
              [-35.205867698979205, -8.721362173267494],
              [-35.204961980221313, -8.721831850450455],
              [-35.204263709868187, -8.72192213639048],
              [-35.203904438814021, -8.722252784323771],
              [-35.203689350052365, -8.72266213679317],
              [-35.202587603258671, -8.723353676464003],
              [-35.201017481178482, -8.722889601215755],
              [-35.199703278663343, -8.722518310834957],
              [-35.197700559381005, -8.723051183903063],
              [-35.196911932229547, -8.723506428099018],
              [-35.196029344110869, -8.723880322520825],
              [-35.195701326110211, -8.723295867851627],
              [-35.195164763850613, -8.723247359554742],
              [-35.194858998662106, -8.723579228262578],
              [-35.195151866840078, -8.725393447418931],
              [-35.195612338770076, -8.728245534721077],
              [-35.195806429794956, -8.729447848295997],
              [-35.196397101884976, -8.733106078435434],
              [-35.196496177778634, -8.733719696226943],
              [-35.196526898678563, -8.73383060603927],
              [-35.196778183778299, -8.735644608276431],
              [-35.196798036061061, -8.735761185102033],
              [-35.197122971410749, -8.738003382706044],
              [-35.197217649844539, -8.738656524948622],
              [-35.197222077378129, -8.738776445108197],
              [-35.197320005414568, -8.740154576224894],
              [-35.201593142928274, -8.738604822607901],
              [-35.203294262282661, -8.737987885875963],
              [-35.20377610980546, -8.737843716382544],
              [-35.207225551386664, -8.736746393065168],
              [-35.209021335250021, -8.736179319993795],
              [-35.210862298658554, -8.735124694771544],
              [-35.213017320869518, -8.733900921879169],
              [-35.214912186434056, -8.733394169112358],
              [-35.218505739385023, -8.734022862212338],
              [-35.220079105286288, -8.734365800366469],
              [-35.221355761396723, -8.7336352225296],
              [-35.223230752459777, -8.732767902577688],
              [-35.22121882167783, -8.728471834051154],
              [-35.22065682349028, -8.727271797247896],
              [-35.219057022901261, -8.72501722292137],
              [-35.219005080023685, -8.724944041634982],
              [-35.214452994977627, -8.719953027414855],
              [-35.212880762921969, -8.718233345143815],
              [-35.212388811194231, -8.717689816583707],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0237000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DAS GRAÇAS',
        municipio: 'GRAVATA',
        area_hecta: '384.1825',
        capacidade: 45.0,
        num_famili: 28.0,
        fase: 4.0,
        data_de_cr: '07/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 382.7872,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.589515446857284, -8.126975792493139],
              [-35.589415507067542, -8.129962800767181],
              [-35.591585333896212, -8.130804037575306],
              [-35.593585691114491, -8.131579978358413],
              [-35.595439695262549, -8.132300688297317],
              [-35.597167868988485, -8.132971143531396],
              [-35.598811250591048, -8.133600016995532],
              [-35.600333988768107, -8.134188815779254],
              [-35.602714436324661, -8.135115412497802],
              [-35.602762015982783, -8.135740452287509],
              [-35.602747718867384, -8.135906451728204],
              [-35.602707758766172, -8.136064958397847],
              [-35.602642086214175, -8.136208315331436],
              [-35.602530375082786, -8.136362177096009],
              [-35.602431421244368, -8.136508299796512],
              [-35.602350050868637, -8.13660070879191],
              [-35.602317082701994, -8.136651968679598],
              [-35.602279568465967, -8.136792592700736],
              [-35.602260379189723, -8.136994357546902],
              [-35.602282164381151, -8.137591486137797],
              [-35.602466872348998, -8.138031872063131],
              [-35.602653417019532, -8.138359939184296],
              [-35.603258646859764, -8.139264721770591],
              [-35.603846037614986, -8.13978675328778],
              [-35.604640979990911, -8.140715839125418],
              [-35.604737549617688, -8.140993433390991],
              [-35.604766962385504, -8.141184676318238],
              [-35.604751606874984, -8.141582952895282],
              [-35.603713043486614, -8.144177777698127],
              [-35.603696479440828, -8.144785361140993],
              [-35.602759872652804, -8.14690733420619],
              [-35.602549504033973, -8.147847977533774],
              [-35.601200218345845, -8.148938866393326],
              [-35.607891855113976, -8.151448290413146],
              [-35.612416213538111, -8.153129263823764],
              [-35.612505863132121, -8.151747833726906],
              [-35.612596250954105, -8.150479981071335],
              [-35.612685333910058, -8.149208308308939],
              [-35.613069956127916, -8.143545868249577],
              [-35.617458388647869, -8.14186553607416],
              [-35.617897732272517, -8.141676376540014],
              [-35.617682646479459, -8.143856235585982],
              [-35.621067671785823, -8.145041657731465],
              [-35.621210477141389, -8.143959797455521],
              [-35.621243460992766, -8.143714554825086],
              [-35.621662654154143, -8.142397365088183],
              [-35.622276045303302, -8.141794865767325],
              [-35.622293326865041, -8.141695210919927],
              [-35.622583635128862, -8.140253787658173],
              [-35.618635648148953, -8.139147295141505],
              [-35.618654217449432, -8.137865872491627],
              [-35.618706396610065, -8.134463191707658],
              [-35.618705700455251, -8.134355995547985],
              [-35.589515446857284, -8.126975792493139],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0307000',
        uf: 'PE',
        nome_proje: 'PA DOIS IRMÃOS',
        municipio: 'BUIQUE',
        area_hecta: '2024.6870',
        capacidade: 70.0,
        num_famili: 55.0,
        fase: 4.0,
        data_de_cr: '28/04/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 2437.5603,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.15271389561569, -8.452702495768845],
              [-37.142085088561394, -8.453360072314874],
              [-37.139802559581604, -8.453501294079704],
              [-37.139712003127855, -8.453506920891771],
              [-37.136378217161536, -8.453752244855883],
              [-37.13287692671269, -8.453709878024538],
              [-37.13334074099248, -8.456653039147254],
              [-37.133801649031675, -8.459577924682145],
              [-37.13322042651189, -8.462988475423634],
              [-37.133115856081389, -8.463603016306449],
              [-37.132374684039171, -8.465398759085625],
              [-37.131471704125964, -8.467586724772824],
              [-37.129492785117328, -8.472381754758128],
              [-37.127674692457049, -8.472696697677176],
              [-37.124697341956768, -8.472980480008911],
              [-37.124270099354668, -8.473149477224002],
              [-37.124184669135822, -8.473183240526373],
              [-37.122071105090157, -8.47401937651126],
              [-37.116853854329221, -8.473032501497336],
              [-37.116368057999139, -8.472451647864597],
              [-37.115169024119865, -8.474697701351721],
              [-37.114549854985725, -8.476684371798356],
              [-37.11400798209413, -8.478423157916028],
              [-37.113444551294279, -8.480130918937069],
              [-37.112952005438231, -8.481584165998541],
              [-37.112719735904456, -8.482269315110791],
              [-37.112258344413235, -8.483868354984295],
              [-37.112154767880476, -8.484288784300761],
              [-37.113394880546451, -8.486809794917768],
              [-37.114358573160558, -8.488328490129684],
              [-37.11714422524043, -8.489334618863197],
              [-37.117303065315184, -8.489368836609719],
              [-37.11738969659762, -8.489387607611791],
              [-37.117477541029963, -8.489418318092635],
              [-37.118649474629422, -8.490248935905933],
              [-37.118727670113962, -8.490955568158682],
              [-37.119851148074424, -8.492992333395062],
              [-37.121946777205629, -8.495511856701166],
              [-37.123322830348343, -8.496801360010682],
              [-37.124736505359159, -8.497452137680819],
              [-37.130428504785741, -8.500071930489092],
              [-37.130924415581063, -8.500300058350476],
              [-37.131565755924171, -8.500595333015481],
              [-37.140277982494446, -8.502758379488274],
              [-37.143121510695153, -8.503536988227156],
              [-37.145631933231677, -8.504224306517999],
              [-37.146323461398374, -8.504096510880478],
              [-37.154620877663596, -8.50256240323831],
              [-37.161991778744358, -8.501199574457589],
              [-37.171344251309748, -8.499505158042806],
              [-37.170749319372376, -8.496135263303655],
              [-37.168334898659758, -8.494979880925866],
              [-37.167959767746282, -8.494727412872313],
              [-37.167707719453098, -8.493492689670564],
              [-37.167851068289814, -8.491492526755623],
              [-37.168183250474229, -8.491130645223725],
              [-37.168702554721214, -8.490413522308193],
              [-37.169070527760589, -8.488976123066323],
              [-37.169230626094993, -8.487846131241],
              [-37.169595184046813, -8.487128278896057],
              [-37.169607361722512, -8.486800513370595],
              [-37.169590649167183, -8.486436219780547],
              [-37.169582598356747, -8.486322813725462],
              [-37.168943909148403, -8.4773261614445],
              [-37.166855956867671, -8.477571944794141],
              [-37.164943811522512, -8.477798291630442],
              [-37.164846660821247, -8.477776046259967],
              [-37.164749024062658, -8.477741412588095],
              [-37.16456877991596, -8.477681618377197],
              [-37.16447607552707, -8.477640950463167],
              [-37.164364961702866, -8.477614209282097],
              [-37.164179785398616, -8.477560449096963],
              [-37.164077912462055, -8.477538271749586],
              [-37.163980692997804, -8.477511415020919],
              [-37.163888173612172, -8.477489191227393],
              [-37.163795469800604, -8.477448432769618],
              [-37.163693455417956, -8.477398680024889],
              [-37.163591557439545, -8.477362669922471],
              [-37.163419953019364, -8.477281127425069],
              [-37.163317800917604, -8.477222152271008],
              [-37.162983133607675, -8.476962369997],
              [-37.16289487592109, -8.476903279347688],
              [-37.162746011738285, -8.476775554456623],
              [-37.162615535637904, -8.476638423194101],
              [-37.162443583647189, -8.476515471596606],
              [-37.162350832841518, -8.476465581383774],
              [-37.16225029738591, -8.476429577421726],
              [-37.162167023253033, -8.476430179791402],
              [-37.162073464814839, -8.476435615776296],
              [-37.161871235939202, -8.476511781905229],
              [-37.16165476033926, -8.476280039636219],
              [-37.161796948141365, -8.476111826123665],
              [-37.161416816623067, -8.474967000214733],
              [-37.161568973845633, -8.473980907310986],
              [-37.161870133711837, -8.471295382026558],
              [-37.161927647360521, -8.470829505982987],
              [-37.162120249668099, -8.469643437720737],
              [-37.162028862761076, -8.467623007963596],
              [-37.161749446197817, -8.466323244869862],
              [-37.161724394708564, -8.466206138268454],
              [-37.161555944278859, -8.465422859261938],
              [-37.161966340501536, -8.461987091870155],
              [-37.157418953963756, -8.457014015377521],
              [-37.155330831814119, -8.454724165439369],
              [-37.154730198708826, -8.454260160318624],
              [-37.15271389561569, -8.452702495768845],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0264000',
        uf: 'PE',
        nome_proje: 'PA RIQUEZA',
        municipio: 'PALMARES',
        area_hecta: '261.7576',
        capacidade: 21.0,
        num_famili: 20.0,
        fase: 4.0,
        data_de_cr: '29/10/2002',
        forma_obte: 'Desapropriação',
        data_obten: '06/09/2001',
        area_calc_: 226.6162,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.670091788219956, -8.609265936755312],
              [-35.669086354664721, -8.610246989114181],
              [-35.669538569979537, -8.61194078599779],
              [-35.669881231516584, -8.614323876279125],
              [-35.670019652052389, -8.615288849084498],
              [-35.670013931144638, -8.615379247606796],
              [-35.669995054156985, -8.615664912482282],
              [-35.670044464451799, -8.615978113791032],
              [-35.670446748659174, -8.618574936606826],
              [-35.670113605620315, -8.620652793661982],
              [-35.670734954860137, -8.620957497301953],
              [-35.671208712909198, -8.621190036395536],
              [-35.671650581044595, -8.62140653217833],
              [-35.672107940807933, -8.621631051710915],
              [-35.672553453985834, -8.62184932793212],
              [-35.672964347099423, -8.622050676500644],
              [-35.673003608653111, -8.622082028516498],
              [-35.673325008921758, -8.622339118580818],
              [-35.673946808453636, -8.622836275729284],
              [-35.674659913524145, -8.62340598523201],
              [-35.675072620042378, -8.623735626913419],
              [-35.675879385932255, -8.623809516576603],
              [-35.680084009856181, -8.624194009333086],
              [-35.680429242801473, -8.624225030005544],
              [-35.680775389974734, -8.624256947554491],
              [-35.682261725301075, -8.62439293887625],
              [-35.685817418777205, -8.625069238355451],
              [-35.688465518538834, -8.625022678742248],
              [-35.687999406606529, -8.619453601344746],
              [-35.684641646493688, -8.615168843827073],
              [-35.678966926600765, -8.613451907981551],
              [-35.677407437621582, -8.613235981727522],
              [-35.677041058947069, -8.613037036259582],
              [-35.676628763732758, -8.612813424275075],
              [-35.676125116785691, -8.612540122315089],
              [-35.670091788219956, -8.609265936755312],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0173000',
        uf: 'PE',
        nome_proje: 'PA CARNAUBA DO AJUDANTE',
        municipio: 'SERRA TALHADA',
        area_hecta: '347.0108',
        capacidade: 11.0,
        num_famili: 10.0,
        fase: 3.0,
        data_de_cr: '12/07/2006',
        forma_obte: 'Desapropriação',
        data_obten: '12/07/2006',
        area_calc_: 348.1728,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.360238814739645, -7.900597017096139],
              [-38.336909322076316, -7.907761245762333],
              [-38.336813929574888, -7.907790581161229],
              [-38.334664195670598, -7.908449078498313],
              [-38.334798083039317, -7.909307123384745],
              [-38.334798124276702, -7.909307099070498],
              [-38.334798127850519, -7.90930712216248],
              [-38.334798131364472, -7.909307145263621],
              [-38.334798134818584, -7.909307168373712],
              [-38.334798138212804, -7.909307191492617],
              [-38.334798141547097, -7.909307214620206],
              [-38.334798144821463, -7.909307237756311],
              [-38.33479814803588, -7.909307260900742],
              [-38.33479815119032, -7.909307284053386],
              [-38.334798154284776, -7.909307307214069],
              [-38.334798157319199, -7.90930733038266],
              [-38.334798160293595, -7.909307353559007],
              [-38.334798163207928, -7.909307376742905],
              [-38.334798166062193, -7.90930739993424],
              [-38.334798168856345, -7.909307423132859],
              [-38.334798171590386, -7.909307446338577],
              [-38.334798174264307, -7.909307469551289],
              [-38.334798176878067, -7.909307492770798],
              [-38.334798179431658, -7.909307515996931],
              [-38.334798181925066, -7.909307539229591],
              [-38.334798184358277, -7.909307562468573],
              [-38.33479818673127, -7.909307585713779],
              [-38.334798189044022, -7.909307608965003],
              [-38.334798191296528, -7.909307632222097],
              [-38.334798193488773, -7.909307655484941],
              [-38.334798195620735, -7.90930767875335],
              [-38.334798197692415, -7.909307702027149],
              [-38.334798199703769, -7.909307725306233],
              [-38.33479820165482, -7.909307748590408],
              [-38.334798203545525, -7.909307771879526],
              [-38.334798205375876, -7.909307795173431],
              [-38.334798207145894, -7.909307818471993],
              [-38.334798208855531, -7.909307841775006],
              [-38.334798210504786, -7.909307865082354],
              [-38.334798212093638, -7.909307888393887],
              [-38.334798213622108, -7.909307911709432],
              [-38.334798215090146, -7.909307935028807],
              [-38.334798216497781, -7.909307958351912],
              [-38.334798217844963, -7.909307981678542],
              [-38.334798219131706, -7.909308005008597],
              [-38.33479822035801, -7.909308028341872],
              [-38.334798221523855, -7.909308051678222],
              [-38.334798222629232, -7.90930807501749],
              [-38.334798223674127, -7.909308098359541],
              [-38.334798224658556, -7.90930812170421],
              [-38.334798225582489, -7.909308145051344],
              [-38.334798226445926, -7.909308168400774],
              [-38.334798227248875, -7.909308191752345],
              [-38.334798227991314, -7.909308215105894],
              [-38.334798228673243, -7.909308238461286],
              [-38.334798229294655, -7.909308261818365],
              [-38.334798229855551, -7.909308285176945],
              [-38.334798230355922, -7.909308308536917],
              [-38.334798230795776, -7.909308331898106],
              [-38.3347982311751, -7.909308355260309],
              [-38.334798231493906, -7.909308378623444],
              [-38.33479823175216, -7.909308401987321],
              [-38.33479823194989, -7.909308425351781],
              [-38.334798232087088, -7.909308448716665],
              [-38.334798232163735, -7.909308472081825],
              [-38.334798232179843, -7.909308495447103],
              [-38.334798232135419, -7.909308518812357],
              [-38.334798232030472, -7.909308542177411],
              [-38.33479823186498, -7.909308565542132],
              [-38.334798231638956, -7.90930858890632],
              [-38.334798231352394, -7.909308612269853],
              [-38.334798231005301, -7.909308635632586],
              [-38.334798230597684, -7.909308658994328],
              [-38.334798230129536, -7.909308682354943],
              [-38.334798229600871, -7.909308705714283],
              [-38.334798229011675, -7.909308729072181],
              [-38.334798228361961, -7.909308752428485],
              [-38.334798227651739, -7.909308775783037],
              [-38.33479822688102, -7.909308799135678],
              [-38.334798226049784, -7.909308822486262],
              [-38.334798225158075, -7.909308845834632],
              [-38.334798224205848, -7.90930886918063],
              [-38.334798223193161, -7.90930889252409],
              [-38.334798222119979, -7.909308915864876],
              [-38.334798220986343, -7.909308939202814],
              [-38.334798219792233, -7.909308962537764],
              [-38.334798218537678, -7.909308985869531],
              [-38.334798217222669, -7.909309009198019],
              [-38.334798215847222, -7.909309032523008],
              [-38.33479821441135, -7.90930905584441],
              [-38.334798212915061, -7.909309079162032],
              [-38.334798211358361, -7.909309102475714],
              [-38.334798209741265, -7.909309125785303],
              [-38.334798208063781, -7.909309149090657],
              [-38.334798206325907, -7.909309172391597],
              [-38.334798204527694, -7.909309195688014],
              [-38.33479820266912, -7.909309218979697],
              [-38.3347982007502, -7.909309242266537],
              [-38.334798198770955, -7.909309265548341],
              [-38.334798196731391, -7.909309288824948],
              [-38.334798194631539, -7.909309312096253],
              [-38.334798192471382, -7.909309335362069],
              [-38.334798190250972, -7.909309358622246],
              [-38.334798187970286, -7.909309381876634],
              [-38.334798185629417, -7.909309405124572],
              [-38.334798170135095, -7.909309384783519],
              [-38.334699642990373, -7.910275418980577],
              [-38.334699652814592, -7.910275408868465],
              [-38.334699647855544, -7.910275456246425],
              [-38.334699642646314, -7.91027550359769],
              [-38.334699637187043, -7.910275550920924],
              [-38.334699631477889, -7.910275598214849],
              [-38.334699625518986, -7.910275645478116],
              [-38.334699619310527, -7.910275692709453],
              [-38.334699612852681, -7.910275739907537],
              [-38.334699606145598, -7.910275787071052],
              [-38.334699599189491, -7.910275834198699],
              [-38.334699591984567, -7.910275881289161],
              [-38.334699584530995, -7.910275928341139],
              [-38.334699576828989, -7.91027597535335],
              [-38.334699568878754, -7.910276022324445],
              [-38.334699560680548, -7.910276069253142],
              [-38.334699552234554, -7.910276116138143],
              [-38.334699543541028, -7.910276162978154],
              [-38.334699534600226, -7.910276209771866],
              [-38.334699525412368, -7.91027625651799],
              [-38.334699515977718, -7.910276303215204],
              [-38.334699506296538, -7.910276349862217],
              [-38.334699496369105, -7.910276396457779],
              [-38.334699486195689, -7.910276443000548],
              [-38.334699475776546, -7.910276489489253],
              [-38.334699465112017, -7.910276535922626],
              [-38.334699454202358, -7.910276582299354],
              [-38.334699443047889, -7.910276628618154],
              [-38.334699431648914, -7.91027667487772],
              [-38.334699420005741, -7.910276721076801],
              [-38.334699408118709, -7.910276767214114],
              [-38.334699395988139, -7.910276813288351],
              [-38.334699383614371, -7.910276859298263],
              [-38.334699370997726, -7.910276905242555],
              [-38.334699358138586, -7.91027695111998],
              [-38.334699345037301, -7.910276996929242],
              [-38.334699331694225, -7.910277042669073],
              [-38.334699318109728, -7.910277088338202],
              [-38.334699304284179, -7.910277133935391],
              [-38.334699290218005, -7.910277179459335],
              [-38.334699275911539, -7.910277224908796],
              [-38.334699261365209, -7.910277270282504],
              [-38.334699246579419, -7.910277315579203],
              [-38.334699231554559, -7.910277360797634],
              [-38.334699216291064, -7.910277405936537],
              [-38.334699200789359, -7.910277450994695],
              [-38.334699185049864, -7.910277495970799],
              [-38.334699169073012, -7.910277540863653],
              [-38.334699152859265, -7.910277585672007],
              [-38.33469913640905, -7.91027763039458],
              [-38.334699119722821, -7.910277675030152],
              [-38.334699102801082, -7.910277719577503],
              [-38.334699085644253, -7.910277764035388],
              [-38.334699068252839, -7.910277808402565],
              [-38.334699050627314, -7.910277852677817],
              [-38.334699032768171, -7.910277896859901],
              [-38.334699014675905, -7.910277940947601],
              [-38.334698996351008, -7.910277984939677],
              [-38.334698977793984, -7.910278028834929],
              [-38.334698959005372, -7.910278072632112],
              [-38.334698939985671, -7.910278116330051],
              [-38.33469892073542, -7.910278159927513],
              [-38.334698901255152, -7.910278203423269],
              [-38.334698881545393, -7.910278246816158],
              [-38.334698861606711, -7.910278290104969],
              [-38.334698841439646, -7.910278333288455],
              [-38.334698821044775, -7.910278376365454],
              [-38.334698800422636, -7.910278419334792],
              [-38.334698779573813, -7.910278462195216],
              [-38.334698758498902, -7.910278504945583],
              [-38.334698737198458, -7.910278547584719],
              [-38.334698715673085, -7.91027859011141],
              [-38.334698693923379, -7.910278632524477],
              [-38.334698671949958, -7.910278674822741],
              [-38.334698649753399, -7.910278717005055],
              [-38.334698627334348, -7.910278759070232],
              [-38.334698604693401, -7.910278801017119],
              [-38.334698581831226, -7.910278842844526],
              [-38.334698558748414, -7.910278884551318],
              [-38.334698535445618, -7.910278926136311],
              [-38.334698511923499, -7.910278967598386],
              [-38.334698488182688, -7.910279008936364],
              [-38.33469846422387, -7.910279050149123],
              [-38.334698440047696, -7.910279091235497],
              [-38.334698415654813, -7.91027913219436],
              [-38.334698391045933, -7.910279173024567],
              [-38.33469836622173, -7.91027921372501],
              [-38.334698341182865, -7.910279254294523],
              [-38.334698315930076, -7.910279294732014],
              [-38.33469829046404, -7.910279335036339],
              [-38.334698264785473, -7.910279375206385],
              [-38.334698238895058, -7.910279415241046],
              [-38.334698212793541, -7.910279455139203],
              [-38.334698186481646, -7.910279494899756],
              [-38.334698159960105, -7.910279534521587],
              [-38.334698133229629, -7.910279574003603],
              [-38.334698106290986, -7.910279613344727],
              [-38.3346980791449, -7.910279652543864],
              [-38.334698051792152, -7.910279691599905],
              [-38.334698024233475, -7.910279730511775],
              [-38.334697996469806, -7.91027976927752],
              [-38.334698003085073, -7.91027975798671],
              [-38.334455605316997, -7.9106162994133],
              [-38.334455628351868, -7.910616310103072],
              [-38.334455621383626, -7.91061631979229],
              [-38.334455614428208, -7.910616329490696],
              [-38.334455607485602, -7.910616339198201],
              [-38.334455600555849, -7.910616348914826],
              [-38.334455593638936, -7.910616358640564],
              [-38.334455586734904, -7.910616368375377],
              [-38.334455579843727, -7.910616378119237],
              [-38.334455572965439, -7.910616387872179],
              [-38.334455566100075, -7.910616397634125],
              [-38.334455559247608, -7.910616407405103],
              [-38.334455552408059, -7.910616417185068],
              [-38.334455545581456, -7.910616426974014],
              [-38.334455538767799, -7.910616436771907],
              [-38.334455531967095, -7.910616446578784],
              [-38.334455525179365, -7.910616456394582],
              [-38.334455518404617, -7.910616466219278],
              [-38.334455511642872, -7.910616476052873],
              [-38.334455504894123, -7.910616485895332],
              [-38.334455498158398, -7.910616495746658],
              [-38.334455491435705, -7.910616505606848],
              [-38.334455484726043, -7.910616515475858],
              [-38.334455478029447, -7.910616525353662],
              [-38.334455471345905, -7.91061653524025],
              [-38.33445546467545, -7.910616545135632],
              [-38.334455458018077, -7.910616555039752],
              [-38.334455451373799, -7.910616564952649],
              [-38.334455444742638, -7.910616574874246],
              [-38.334455438124593, -7.910616584804573],
              [-38.334455431519707, -7.910616594743563],
              [-38.334455424927931, -7.91061660469125],
              [-38.334455418349336, -7.910616614647549],
              [-38.334455411783907, -7.910616624612499],
              [-38.334455405231651, -7.910616634586079],
              [-38.334455398692604, -7.910616644568251],
              [-38.334455392166738, -7.910616654559008],
              [-38.334455385654088, -7.910616664558359],
              [-38.334455379154676, -7.910616674566239],
              [-38.334455372668494, -7.910616684582646],
              [-38.334455366195563, -7.910616694607586],
              [-38.334455359735891, -7.910616704641003],
              [-38.334455353289485, -7.910616714682914],
              [-38.334455346856366, -7.91061672473329],
              [-38.334455340436534, -7.910616734792111],
              [-38.334455334030004, -7.910616744859356],
              [-38.334455327636803, -7.910616754935017],
              [-38.334455321256918, -7.91061676501905],
              [-38.33445531489037, -7.910616775111481],
              [-38.33445530853718, -7.910616785212278],
              [-38.334455302197334, -7.910616795321414],
              [-38.33445529587086, -7.910616805438859],
              [-38.334455289557781, -7.910616815564632],
              [-38.334455283258087, -7.910616825698664],
              [-38.334455276971795, -7.910616835840978],
              [-38.334455270698911, -7.910616845991546],
              [-38.33445526443947, -7.910616856150342],
              [-38.334455258193451, -7.910616866317365],
              [-38.334455251960883, -7.910616876492597],
              [-38.334455245741772, -7.910616886675987],
              [-38.334455239536126, -7.910616896867572],
              [-38.334455233343967, -7.91061690706728],
              [-38.334455227165293, -7.910616917275115],
              [-38.334455221000127, -7.910616927491071],
              [-38.334455214848461, -7.910616937715121],
              [-38.334455208710324, -7.910616947947227],
              [-38.334455202585723, -7.910616958187413],
              [-38.334455196474657, -7.910616968435636],
              [-38.334455190377156, -7.910616978691886],
              [-38.334455184293212, -7.91061698895614],
              [-38.334455178222839, -7.910616999228389],
              [-38.334455172166052, -7.910617009508591],
              [-38.334455166122879, -7.910617019796748],
              [-38.334455160093292, -7.910617030092851],
              [-38.33445515407734, -7.910617040396842],
              [-38.334455148074987, -7.910617050708753],
              [-38.334455142086306, -7.910617061028523],
              [-38.334455136111259, -7.910617071356178],
              [-38.334455130149877, -7.910617081691661],
              [-38.334455124202158, -7.910617092034982],
              [-38.334455118268117, -7.910617102386107],
              [-38.334455112347769, -7.910617112745003],
              [-38.334455106441126, -7.910617123111687],
              [-38.334455100548183, -7.910617133486114],
              [-38.334455094668968, -7.910617143868293],
              [-38.334455088803487, -7.910617154258177],
              [-38.334455082951735, -7.910617164655779],
              [-38.334455077113745, -7.910617175061035],
              [-38.334455071289511, -7.910617185473982],
              [-38.334455065479055, -7.910617195894548],
              [-38.334455059682384, -7.910617206322768],
              [-38.334455053899497, -7.910617216758593],
              [-38.334455048130401, -7.910617227201968],
              [-38.334455042375133, -7.910617237652963],
              [-38.334455036633692, -7.910617248111479],
              [-38.334455030906071, -7.910617258577567],
              [-38.334455025192291, -7.91061726905114],
              [-38.334455019492374, -7.910617279532245],
              [-38.334455013806298, -7.91061729002082],
              [-38.334455008134121, -7.910617300516847],
              [-38.334455002475806, -7.910617311020327],
              [-38.334454968686352, -7.910617293352877],
              [-38.334244324521251, -7.911009059967681],
              [-38.334244247791716, -7.91100905200168],
              [-38.334065163360428, -7.91134199345649],
              [-38.334065202249903, -7.911342038490851],
              [-38.334065187676728, -7.911342065475753],
              [-38.334065173011936, -7.911342092411239],
              [-38.334065158255683, -7.911342119297071],
              [-38.334065143408154, -7.911342146132885],
              [-38.334065128469504, -7.911342172918381],
              [-38.334065113439905, -7.911342199653275],
              [-38.334065098319542, -7.911342226337225],
              [-38.334065083108584, -7.911342252969937],
              [-38.334065067807195, -7.911342279551117],
              [-38.334065052415554, -7.911342306080429],
              [-38.334065036933865, -7.91134233255761],
              [-38.334065021362264, -7.911342358982317],
              [-38.33406500570095, -7.91134238535428],
              [-38.334064989950107, -7.911342411673168],
              [-38.334064974109907, -7.911342437938688],
              [-38.334064958180541, -7.911342464150581],
              [-38.334064942162179, -7.911342490308482],
              [-38.334064926055014, -7.911342516412128],
              [-38.334064909859229, -7.911342542461215],
              [-38.334064893575011, -7.911342568455456],
              [-38.334064877202543, -7.911342594394516],
              [-38.334064860742018, -7.911342620278123],
              [-38.33406484419362, -7.911342646105965],
              [-38.334064827557548, -7.911342671877742],
              [-38.334064810833986, -7.91134269759318],
              [-38.334064794023121, -7.911342723251972],
              [-38.33406477712515, -7.911342748853811],
              [-38.334064760140265, -7.911342774398436],
              [-38.33406474306868, -7.911342799885522],
              [-38.334064725910565, -7.911342825314778],
              [-38.334064708666133, -7.911342850685931],
              [-38.334064691335577, -7.911342875998685],
              [-38.334064673919094, -7.911342901252741],
              [-38.334064656416885, -7.911342926447808],
              [-38.334064638829155, -7.911342951583625],
              [-38.334064621156102, -7.91134297665988],
              [-38.334064603397927, -7.911343001676276],
              [-38.334064585554842, -7.911343026632525],
              [-38.334064567627031, -7.911343051528343],
              [-38.33406454961473, -7.911343076363488],
              [-38.334064531518123, -7.911343101137634],
              [-38.334064513337424, -7.911343125850485],
              [-38.334064495072845, -7.911343150501777],
              [-38.334064476724606, -7.91134317509122],
              [-38.3340644582929, -7.911343199618537],
              [-38.334064439777947, -7.911343224083434],
              [-38.334064421179946, -7.911343248485657],
              [-38.334064402499131, -7.911343272824904],
              [-38.334064383735701, -7.911343297100897],
              [-38.334064364889869, -7.911343321313358],
              [-38.334064345961878, -7.911343345462021],
              [-38.334064326951918, -7.911343369546598],
              [-38.334064307860217, -7.911343393566805],
              [-38.334064288686989, -7.911343417522395],
              [-38.33406426943246, -7.911343441413071],
              [-38.334064250096866, -7.911343465238568],
              [-38.334064230680397, -7.911343488998601],
              [-38.334064211183296, -7.911343512692905],
              [-38.334064191605783, -7.911343536321187],
              [-38.334064171948079, -7.911343559883215],
              [-38.334064152210416, -7.9113435833787],
              [-38.334064132393017, -7.911343606807377],
              [-38.334064112496108, -7.911343630168954],
              [-38.334064092519924, -7.911343653463172],
              [-38.334064072464685, -7.911343676689786],
              [-38.334064052330632, -7.911343699848516],
              [-38.334064032117972, -7.911343722939087],
              [-38.334064011826975, -7.911343745961229],
              [-38.334063991457853, -7.911343768914698],
              [-38.334063971010828, -7.91134379179922],
              [-38.334063950486161, -7.911343814614505],
              [-38.334063929884074, -7.911343837360362],
              [-38.334063909204794, -7.911343860036479],
              [-38.334063888448583, -7.911343882642587],
              [-38.334063867615654, -7.911343905178454],
              [-38.334063846706258, -7.911343927643827],
              [-38.334063825720634, -7.911343950038402],
              [-38.334063804659031, -7.911343972361963],
              [-38.334063783521664, -7.911343994614239],
              [-38.334063762308809, -7.911344016794993],
              [-38.334063741020707, -7.911344038903955],
              [-38.334063719657571, -7.911344060940861],
              [-38.33406369821968, -7.911344082905486],
              [-38.334063676707252, -7.911344104797584],
              [-38.334063655120552, -7.911344126616828],
              [-38.33406363345982, -7.911344148363045],
              [-38.33406361172532, -7.911344170035952],
              [-38.334063589917278, -7.911344191635306],
              [-38.33406356803598, -7.911344213160882],
              [-38.334063546081623, -7.911344234612401],
              [-38.334063524054507, -7.911344255989608],
              [-38.334063501954866, -7.911344277292296],
              [-38.334063479782941, -7.911344298520217],
              [-38.334063457539017, -7.911344319673089],
              [-38.334063435223342, -7.911344340750705],
              [-38.334063412836144, -7.911344361752796],
              [-38.3340633903777, -7.911344382679141],
              [-38.334063367848273, -7.911344403529497],
              [-38.334063345247905, -7.911344424304628],
              [-38.3340633450523, -7.911344432716521],
              [-38.33343118716617, -7.911923572595223],
              [-38.33343116992085, -7.911923547910785],
              [-38.333431154630034, -7.911923561886274],
              [-38.333431139307201, -7.911923575826855],
              [-38.33343112395243, -7.911923589732474],
              [-38.333431108565826, -7.911923603603072],
              [-38.333431093147446, -7.911923617438536],
              [-38.333431077697377, -7.911923631238829],
              [-38.333431062215688, -7.911923645003851],
              [-38.333431046702486, -7.911923658733534],
              [-38.333431031157829, -7.911923672427815],
              [-38.333431015581809, -7.911923686086655],
              [-38.33343099997451, -7.911923699709929],
              [-38.333430984336005, -7.911923713297602],
              [-38.333430968666377, -7.91192372684956],
              [-38.333430952965713, -7.911923740365767],
              [-38.333430937234091, -7.91192375384616],
              [-38.333430921471589, -7.911923767290634],
              [-38.333430905678313, -7.911923780699174],
              [-38.333430889854327, -7.911923794071626],
              [-38.333430873999703, -7.911923807407992],
              [-38.333430858114532, -7.911923820708173],
              [-38.333430842198908, -7.911923833972135],
              [-38.333430826252908, -7.911923847199755],
              [-38.33343081027661, -7.911923860390988],
              [-38.3334307942701, -7.911923873545779],
              [-38.333430778233463, -7.911923886664034],
              [-38.333430762166792, -7.911923899745696],
              [-38.33343074607015, -7.911923912790685],
              [-38.33343072994365, -7.911923925798948],
              [-38.333430713787344, -7.911923938770402],
              [-38.333430697601337, -7.911923951705014],
              [-38.333430681385714, -7.911923964602648],
              [-38.333430665140554, -7.911923977463323],
              [-38.333430648865935, -7.911923990286919],
              [-38.333430632561964, -7.911924003073369],
              [-38.333430616228689, -7.911924015822624],
              [-38.333430599866226, -7.911924028534617],
              [-38.333430583474659, -7.911924041209261],
              [-38.33343056705408, -7.911924053846509],
              [-38.33343055060454, -7.911924066446307],
              [-38.333430534126151, -7.911924079008559],
              [-38.333430517619007, -7.911924091533208],
              [-38.333430501083171, -7.911924104020191],
              [-38.333430484518765, -7.911924116469459],
              [-38.333430467925815, -7.911924128880944],
              [-38.333430451304466, -7.911924141254542],
              [-38.33343043465478, -7.911924153590223],
              [-38.333430417976842, -7.911924165887914],
              [-38.333430401270753, -7.911924178147556],
              [-38.333430384536591, -7.911924190369073],
              [-38.33343036777444, -7.911924202552408],
              [-38.333430350984386, -7.911924214697486],
              [-38.333430334166529, -7.911924226804273],
              [-38.333430317320961, -7.911924238872686],
              [-38.333430300447752, -7.911924250902667],
              [-38.333430283547003, -7.911924262894154],
              [-38.333430266618798, -7.911924274847066],
              [-38.333430249663216, -7.911924286761353],
              [-38.33343023268035, -7.911924298636948],
              [-38.333430215670319, -7.9119243104738],
              [-38.33343019863316, -7.911924322271859],
              [-38.333430181569, -7.911924334031044],
              [-38.333430164477917, -7.911924345751311],
              [-38.333430147359991, -7.911924357432558],
              [-38.333430130215341, -7.91192436907474],
              [-38.333430113044017, -7.911924380677826],
              [-38.33343009584614, -7.911924392241733],
              [-38.333430078621781, -7.911924403766414],
              [-38.333430061371033, -7.91192441525176],
              [-38.333430044093994, -7.911924426697777],
              [-38.333430026790751, -7.911924438104378],
              [-38.333430009461395, -7.911924449471497],
              [-38.333429992106026, -7.911924460799082],
              [-38.333429974724709, -7.91192447208707],
              [-38.333429957317549, -7.911924483335392],
              [-38.333429939884653, -7.911924494543996],
              [-38.333429922426092, -7.911924505712848],
              [-38.333429904941951, -7.911924516841866],
              [-38.333429887432338, -7.911924527931014],
              [-38.333429869897344, -7.91192453898021],
              [-38.333429852337062, -7.91192454998937],
              [-38.33342983475157, -7.911924560958477],
              [-38.33342981714096, -7.911924571887479],
              [-38.333429799505339, -7.911924582776311],
              [-38.333429781844792, -7.911924593624905],
              [-38.333429764159419, -7.911924604433208],
              [-38.333429746449298, -7.911924615201159],
              [-38.333429728714528, -7.911924625928684],
              [-38.333429710955208, -7.911924636615788],
              [-38.333429693171418, -7.911924647262367],
              [-38.333429675363263, -7.911924657868374],
              [-38.333429657530829, -7.911924668433752],
              [-38.333429639674215, -7.911924678958458],
              [-38.333429621793506, -7.911924689442419],
              [-38.333429603888803, -7.911924699885606],
              [-38.333429585960197, -7.911924710287935],
              [-38.33342956800778, -7.911924720649333],
              [-38.333429550031646, -7.911924730969824],
              [-38.333429532031893, -7.911924741249302],
              [-38.333429514008763, -7.911924751486844],
              [-38.33342955232483, -7.911924745839761],
              [-38.333090768209836, -7.912116683150327],
              [-38.333090760338493, -7.912116674241415],
              [-38.333090726929278, -7.912116693076484],
              [-38.333090693440447, -7.912116711770411],
              [-38.333090659872582, -7.912116730322963],
              [-38.333090626226294, -7.912116748733749],
              [-38.333090592502195, -7.912116767002423],
              [-38.333090558700867, -7.912116785128691],
              [-38.333090524822943, -7.912116803112224],
              [-38.333090490869004, -7.912116820952701],
              [-38.33309045683967, -7.912116838649799],
              [-38.333090422735552, -7.912116856203204],
              [-38.333090388557245, -7.912116873612596],
              [-38.333090354305376, -7.912116890877686],
              [-38.333090319980556, -7.912116907998108],
              [-38.333090285583395, -7.912116924973592],
              [-38.333090251114506, -7.91211694180384],
              [-38.333090216574512, -7.912116958488557],
              [-38.333090181964032, -7.912116975027415],
              [-38.333090147283691, -7.91211699142016],
              [-38.333090112534094, -7.912117007666454],
              [-38.333090077715866, -7.912117023766032],
              [-38.333090042829646, -7.91211703971861],
              [-38.333090007876038, -7.912117055523877],
              [-38.333089972855682, -7.912117071181595],
              [-38.333089937769188, -7.912117086691407],
              [-38.333089902617225, -7.912117102053119],
              [-38.333089867400375, -7.912117117266412],
              [-38.333089832119278, -7.912117132331026],
              [-38.333089796774587, -7.912117147246692],
              [-38.333089761366928, -7.912117162013137],
              [-38.333089725896919, -7.912117176630074],
              [-38.333089690365192, -7.912117191097271],
              [-38.333089654772408, -7.912117205414467],
              [-38.333089619119171, -7.912117219581402],
              [-38.333089583406178, -7.912117233597825],
              [-38.33308954763401, -7.9121172474635],
              [-38.333089511803323, -7.912117261178127],
              [-38.333089475914761, -7.912117274741523],
              [-38.333089439968958, -7.912117288153401],
              [-38.333089403966589, -7.912117301413561],
              [-38.333089367908272, -7.912117314521729],
              [-38.333089331794646, -7.912117327477697],
              [-38.333089295626372, -7.912117340281204],
              [-38.33308925940409, -7.912117352932038],
              [-38.333089223128461, -7.912117365429964],
              [-38.333089186800123, -7.912117377774761],
              [-38.333089150419717, -7.912117389966207],
              [-38.333089113987917, -7.912117402004109],
              [-38.333089077505363, -7.912117413888227],
              [-38.333089040972702, -7.912117425618348],
              [-38.333089004390601, -7.912117437194267],
              [-38.333088967759693, -7.912117448615767],
              [-38.333088931080667, -7.912117459882667],
              [-38.333088894354141, -7.912117470994739],
              [-38.333088857580819, -7.912117481951795],
              [-38.333088820761304, -7.912117492753641],
              [-38.333088783896308, -7.912117503400079],
              [-38.333088746986448, -7.91211751389094],
              [-38.333088710032413, -7.912117524225994],
              [-38.333088673034844, -7.912117534405107],
              [-38.333088635994429, -7.91211754442807],
              [-38.3330885989118, -7.912117554294709],
              [-38.333088561787655, -7.912117564004828],
              [-38.333088524622625, -7.912117573558273],
              [-38.333088487417399, -7.912117582954848],
              [-38.333088450172632, -7.912117592194428],
              [-38.333088412888991, -7.912117601276804],
              [-38.333088375567137, -7.91211761020183],
              [-38.333088338207766, -7.912117618969372],
              [-38.333088300811511, -7.912117627579265],
              [-38.333088263379068, -7.912117636031303],
              [-38.333088225911077, -7.912117644325392],
              [-38.333088188408233, -7.912117652461371],
              [-38.333088150871205, -7.912117660439055],
              [-38.333088113300661, -7.912117668258379],
              [-38.333088075697283, -7.912117675919148],
              [-38.333088038061724, -7.912117683421229],
              [-38.333088000394675, -7.912117690764489],
              [-38.333087962696808, -7.912117697948806],
              [-38.333087924968773, -7.912117704974039],
              [-38.333087887211285, -7.912117711840079],
              [-38.333087849425006, -7.912117718546781],
              [-38.333087811610582, -7.912117725094058],
              [-38.333087773768717, -7.91211773148176],
              [-38.333087735900108, -7.912117737709789],
              [-38.3330876980054, -7.912117743778008],
              [-38.333087660085276, -7.912117749686338],
              [-38.333087622140432, -7.912117755434678],
              [-38.33308758417153, -7.912117761022895],
              [-38.333087546179257, -7.912117766450916],
              [-38.333087508164297, -7.912117771718634],
              [-38.333087470127317, -7.912117776825942],
              [-38.333087432069007, -7.912117781772776],
              [-38.333087393990056, -7.912117786559035],
              [-38.333087355891116, -7.912117791184623],
              [-38.333087317772907, -7.912117795649485],
              [-38.333087279636096, -7.912117799953491],
              [-38.333087241481351, -7.912117804096611],
              [-38.333087203309368, -7.91211780807877],
              [-38.333087165120872, -7.912117811899586],
              [-38.333087137534925, -7.912117853204992],
              [-38.331628767444613, -7.912260603244518],
              [-38.331628733470488, -7.912260631754535],
              [-38.331628713070764, -7.912260633728996],
              [-38.331628692666612, -7.912260635657491],
              [-38.331628672258148, -7.912260637540036],
              [-38.33162865184547, -7.912260639376596],
              [-38.331628631428686, -7.91226064116717],
              [-38.331628611007886, -7.912260642911797],
              [-38.331628590583193, -7.912260644610376],
              [-38.331628570154699, -7.912260646262951],
              [-38.33162854972251, -7.912260647869509],
              [-38.331628529286746, -7.912260649430053],
              [-38.331628508847487, -7.912260650944527],
              [-38.331628488404853, -7.91226065241299],
              [-38.331628467958943, -7.91226065383538],
              [-38.331628447509864, -7.912260655211724],
              [-38.331628427057709, -7.912260656541984],
              [-38.331628406602597, -7.912260657826193],
              [-38.331628386144629, -7.912260659064321],
              [-38.331628365683905, -7.912260660256351],
              [-38.331628345220537, -7.912260661402298],
              [-38.331628324754618, -7.912260662502161],
              [-38.331628304286262, -7.912260663555891],
              [-38.331628283815569, -7.912260664563505],
              [-38.331628263342651, -7.912260665525057],
              [-38.331628242867602, -7.912260666440457],
              [-38.331628222390535, -7.912260667309764],
              [-38.331628201911549, -7.912260668132932],
              [-38.331628181430744, -7.912260668909955],
              [-38.331628160948227, -7.912260669640843],
              [-38.331628140464119, -7.912260670325602],
              [-38.331628119978504, -7.912260670964217],
              [-38.331628099491503, -7.912260671556679],
              [-38.331628079003195, -7.912260672102994],
              [-38.331628058513729, -7.912260672603165],
              [-38.331628038023148, -7.912260673057204],
              [-38.331628017531614, -7.91226067346506],
              [-38.331627997039199, -7.912260673826769],
              [-38.331627976546017, -7.912260674142315],
              [-38.331627956052181, -7.912260674411697],
              [-38.331627935557776, -7.912260674634901],
              [-38.331627915062924, -7.912260674811941],
              [-38.331627894567724, -7.912260674942814],
              [-38.331627874072261, -7.912260675027531],
              [-38.331627853576684, -7.912260675066084],
              [-38.331627833081065, -7.912260675058452],
              [-38.331627812585509, -7.912260675004681],
              [-38.331627792090131, -7.912260674904726],
              [-38.331627771595045, -7.91226067475861],
              [-38.331627751100321, -7.91226067456632],
              [-38.33162773060608, -7.912260674327863],
              [-38.331627710112457, -7.912260674043246],
              [-38.331627689619502, -7.912260673712468],
              [-38.331627669127371, -7.912260673335526],
              [-38.33162764863615, -7.912260672912444],
              [-38.331627628145931, -7.912260672443217],
              [-38.331627607656827, -7.912260671927829],
              [-38.331627587168938, -7.912260671366297],
              [-38.331627566682386, -7.912260670758608],
              [-38.331627546197254, -7.91226067010475],
              [-38.331627525713664, -7.912260669404772],
              [-38.331627505231694, -7.912260668658665],
              [-38.331627484751479, -7.912260667866416],
              [-38.331627464273112, -7.912260667028012],
              [-38.331627443796698, -7.912260666143495],
              [-38.331627423322345, -7.91226066521287],
              [-38.331627402850138, -7.912260664236139],
              [-38.331627382380212, -7.912260663213293],
              [-38.331627361912652, -7.912260662144345],
              [-38.331627341447557, -7.912260661029287],
              [-38.331627320985064, -7.912260659868107],
              [-38.331627300525227, -7.912260658660849],
              [-38.33162728006819, -7.912260657407537],
              [-38.331627259614045, -7.912260656108131],
              [-38.331627239162884, -7.912260654762655],
              [-38.33162721871485, -7.912260653371105],
              [-38.331627198270006, -7.912260651933511],
              [-38.331627177828473, -7.912260650449883],
              [-38.331627157390351, -7.912260648920218],
              [-38.331627136955753, -7.912260647344493],
              [-38.331627116524771, -7.912260645722745],
              [-38.331627096097527, -7.912260644054996],
              [-38.331627075674113, -7.912260642341226],
              [-38.331627055254621, -7.912260640581463],
              [-38.33162703483918, -7.9122606387757],
              [-38.33162701442788, -7.912260636923962],
              [-38.331626994020823, -7.912260635026259],
              [-38.331626973618114, -7.912260633082585],
              [-38.331626953219875, -7.912260631092939],
              [-38.331626932826197, -7.91226062905739],
              [-38.33162691243718, -7.912260626975887],
              [-38.331626892052924, -7.91226062484847],
              [-38.331626871673556, -7.912260622675149],
              [-38.331626851299148, -7.912260620455907],
              [-38.331626830929835, -7.912260618190799],
              [-38.331626810565687, -7.912260615879823],
              [-38.331626790206847, -7.912260613522961],
              [-38.331626769853393, -7.912260611120235],
              [-38.331626749505432, -7.912260608671692],
              [-38.331626729163084, -7.912260606177311],
              [-38.331626708826434, -7.912260603636122],
              [-38.331626680892981, -7.912260599903896],
              [-38.330071333294242, -7.912064543199778],
              [-38.330071303335529, -7.912064539044976],
              [-38.330071266779754, -7.912064534512089],
              [-38.330071230205817, -7.912064530127198],
              [-38.330071193614323, -7.912064525890472],
              [-38.330071157005868, -7.912064521801874],
              [-38.330071120381085, -7.912064517861525],
              [-38.330071083740556, -7.912064514069479],
              [-38.330071047084871, -7.912064510425836],
              [-38.330071010414649, -7.912064506930598],
              [-38.3300709737305, -7.912064503583846],
              [-38.330070937033021, -7.91206450038564],
              [-38.330070900322823, -7.912064497336038],
              [-38.330070863600504, -7.912064494435097],
              [-38.330070826866674, -7.912064491682807],
              [-38.330070790121944, -7.912064489079292],
              [-38.330070753366904, -7.912064486624558],
              [-38.330070716602165, -7.912064484318646],
              [-38.33007067982836, -7.91206448216158],
              [-38.330070643046049, -7.912064480153455],
              [-38.330070606255873, -7.912064478294193],
              [-38.330070569458435, -7.912064476583924],
              [-38.330070532654332, -7.912064475022657],
              [-38.330070495844168, -7.912064473610403],
              [-38.330070459028555, -7.912064472347173],
              [-38.330070422208102, -7.912064471233016],
              [-38.330070385383422, -7.912064470267921],
              [-38.330070348555118, -7.912064469451924],
              [-38.330070311723766, -7.912064468785004],
              [-38.330070274890026, -7.912064468267202],
              [-38.330070238054475, -7.912064467898585],
              [-38.33007020121773, -7.912064467679047],
              [-38.330070164380395, -7.912064467608655],
              [-38.330070127543081, -7.912064467687419],
              [-38.330070090706393, -7.912064467915302],
              [-38.330070053870912, -7.912064468292309],
              [-38.330070017037279, -7.912064468818467],
              [-38.330069980206119, -7.912064469493728],
              [-38.330069943377985, -7.912064470318101],
              [-38.330069906553518, -7.912064471291562],
              [-38.330069869733322, -7.912064472414098],
              [-38.330069832918014, -7.91206447368569],
              [-38.33006979610817, -7.912064475106309],
              [-38.330069759304415, -7.91206447667594],
              [-38.330069722507375, -7.912064478394597],
              [-38.330069685717618, -7.912064480262199],
              [-38.330069648935776, -7.912064482278696],
              [-38.330069612162454, -7.912064484444125],
              [-38.330069575398241, -7.912064486758415],
              [-38.330069538643777, -7.912064489221483],
              [-38.330069501899636, -7.912064491833336],
              [-38.330069465166424, -7.912064494593953],
              [-38.330069428444787, -7.91206449750325],
              [-38.330069391735279, -7.912064500561213],
              [-38.330069355038532, -7.912064503767743],
              [-38.33006931835515, -7.912064507122809],
              [-38.330069281685731, -7.91206451062639],
              [-38.330069245030892, -7.912064514278389],
              [-38.330069208391222, -7.912064518078735],
              [-38.330069171767335, -7.912064522027384],
              [-38.33006913515981, -7.912064526124287],
              [-38.330069098569297, -7.912064530369356],
              [-38.330069061996355, -7.912064534762528],
              [-38.330069025441631, -7.912064539303748],
              [-38.330068988905687, -7.912064543992896],
              [-38.330068952389155, -7.912064548829922],
              [-38.330068915892603, -7.912064553814766],
              [-38.330068879416658, -7.912064558947319],
              [-38.330068842961929, -7.912064564227488],
              [-38.330068806528999, -7.912064569655213],
              [-38.330068770118473, -7.912064575230422],
              [-38.330068733730954, -7.912064580952944],
              [-38.330068697367032, -7.912064586822789],
              [-38.330068661027333, -7.912064592839739],
              [-38.330068624712432, -7.912064599003808],
              [-38.33006858842294, -7.912064605314845],
              [-38.330068552159432, -7.912064611772731],
              [-38.330068515922541, -7.912064618377404],
              [-38.330068479712843, -7.91206462512868],
              [-38.330068443530941, -7.912064632026521],
              [-38.330068407377425, -7.912064639070782],
              [-38.330068371252892, -7.912064646261343],
              [-38.330068335157947, -7.912064653598121],
              [-38.330068299093192, -7.912064661080948],
              [-38.330068263059189, -7.912064668709712],
              [-38.330068227056564, -7.912064676484337],
              [-38.330068191085886, -7.912064684404615],
              [-38.330068155147778, -7.912064692470508],
              [-38.33006811924281, -7.912064700681798],
              [-38.330068083371572, -7.912064709038383],
              [-38.330068047534681, -7.912064717540112],
              [-38.330068011732692, -7.912064726186862],
              [-38.330067975966209, -7.912064734978503],
              [-38.33006794023585, -7.912064743914857],
              [-38.330067904542169, -7.912064752995771],
              [-38.330067868885756, -7.912064762221147],
              [-38.330067833267222, -7.912064771590797],
              [-38.330067797687143, -7.912064781104544],
              [-38.330067762146101, -7.912064790762277],
              [-38.330067726644671, -7.912064800563834],
              [-38.330067691183459, -7.91206481050902],
              [-38.330067704073642, -7.912064808726807],
              [-38.329388991452575, -7.912256649503748],
              [-38.329389003518983, -7.912256633058313],
              [-38.329388967944553, -7.912256643191141],
              [-38.329388932411845, -7.912256653468516],
              [-38.329388896921493, -7.912256663890297],
              [-38.329388861474058, -7.91225667445629],
              [-38.329388826070151, -7.912256685166335],
              [-38.32938879071034, -7.912256696020263],
              [-38.329388755395215, -7.912256707017872],
              [-38.329388720125372, -7.912256718158973],
              [-38.329388684901375, -7.91225672944341],
              [-38.329388649723846, -7.912256740870971],
              [-38.329388614593356, -7.912256752441459],
              [-38.329388579510479, -7.912256764154702],
              [-38.329388544475812, -7.912256776010506],
              [-38.329388509489924, -7.912256788008668],
              [-38.329388474553397, -7.912256800148998],
              [-38.329388439666836, -7.91225681243127],
              [-38.329388404830794, -7.912256824855292],
              [-38.329388370045855, -7.912256837420851],
              [-38.329388335312622, -7.912256850127757],
              [-38.329388300631628, -7.912256862975813],
              [-38.329388266003498, -7.912256875964718],
              [-38.32938823142878, -7.912256889094333],
              [-38.329388196908056, -7.912256902364443],
              [-38.329388162441909, -7.912256915774796],
              [-38.329388128030885, -7.912256929325192],
              [-38.329388093675568, -7.912256943015378],
              [-38.329388059376541, -7.912256956845152],
              [-38.329388025134378, -7.91225697081426],
              [-38.32938799094962, -7.912256984922509],
              [-38.329387956822885, -7.912256999169621],
              [-38.329387922754684, -7.91225701355538],
              [-38.329387888745615, -7.912257028079558],
              [-38.329387854796252, -7.912257042741894],
              [-38.329387820907122, -7.912257057542144],
              [-38.329387787078829, -7.912257072480066],
              [-38.329387753311913, -7.912257087555412],
              [-38.329387719606949, -7.912257102767947],
              [-38.329387685964491, -7.912257118117387],
              [-38.329387652385087, -7.91225713360351],
              [-38.329387618869312, -7.912257149226035],
              [-38.32938758541772, -7.91225716498472],
              [-38.329387552030859, -7.912257180879268],
              [-38.329387518709289, -7.912257196909446],
              [-38.329387485453573, -7.91225721307498],
              [-38.329387452264257, -7.912257229375622],
              [-38.329387419141888, -7.912257245811047],
              [-38.329387386087028, -7.912257262381045],
              [-38.329387353100223, -7.912257279085291],
              [-38.329387320182008, -7.912257295923538],
              [-38.329387287332949, -7.912257312895481],
              [-38.329387254553573, -7.91225733000085],
              [-38.329387221844449, -7.912257347239376],
              [-38.329387189206102, -7.912257364610743],
              [-38.329387156639086, -7.91225738211468],
              [-38.329387124143935, -7.912257399750884],
              [-38.329387091721209, -7.912257417519108],
              [-38.329387059371413, -7.912257435418993],
              [-38.329387027095123, -7.912257453450254],
              [-38.329386994892829, -7.912257471612623],
              [-38.329386962765106, -7.91225748990575],
              [-38.329386930712481, -7.912257508329401],
              [-38.329386898735478, -7.912257526883219],
              [-38.329386866834625, -7.912257545566901],
              [-38.329386835010489, -7.912257564380111],
              [-38.329386803263539, -7.912257583322601],
              [-38.329386771594351, -7.912257602394017],
              [-38.329386740003443, -7.912257621594057],
              [-38.329386708491327, -7.912257640922345],
              [-38.32938667705853, -7.912257660378653],
              [-38.329386645705576, -7.912257679962577],
              [-38.32938661443297, -7.912257699673808],
              [-38.329386583241288, -7.912257719512049],
              [-38.329386552130998, -7.912257739476982],
              [-38.32938652110262, -7.912257759568222],
              [-38.329386490156686, -7.912257779785456],
              [-38.329386459293701, -7.91225780012836],
              [-38.329386428514205, -7.912257820596575],
              [-38.329386397818659, -7.912257841189782],
              [-38.329386367207618, -7.912257861907645],
              [-38.329386336681573, -7.912257882749777],
              [-38.329386306241027, -7.912257903715878],
              [-38.329386275886506, -7.912257924805574],
              [-38.329386245618501, -7.912257946018515],
              [-38.329386215437509, -7.912257967354367],
              [-38.329386185344042, -7.912257988812739],
              [-38.329386155338604, -7.912258010393313],
              [-38.329386125421678, -7.912258032095703],
              [-38.32938609559379, -7.912258053919554],
              [-38.329386065855388, -7.912258075864518],
              [-38.329386036207019, -7.9122580979302],
              [-38.329386006649159, -7.912258120116275],
              [-38.32938597718227, -7.912258142422345],
              [-38.329385947806884, -7.912258164848031],
              [-38.329385918523464, -7.912258187393012],
              [-38.329385889332528, -7.912258210056849],
              [-38.329385860234517, -7.912258232839169],
              [-38.329385831229935, -7.912258255739641],
              [-38.329385802319266, -7.912258278757867],
              [-38.329385773502999, -7.912258301893446],
              [-38.32938581363581, -7.912258272504219],
              [-38.328609237015968, -7.912884296964937],
              [-38.328609268685788, -7.912884335042274],
              [-38.328609222933295, -7.912884372174634],
              [-38.328609177423978, -7.912884409602843],
              [-38.328609132159741, -7.912884447325339],
              [-38.328609087142496, -7.912884485340519],
              [-38.328609042374147, -7.912884523646771],
              [-38.328608997856584, -7.912884562242446],
              [-38.32860895359169, -7.912884601125948],
              [-38.328608909581341, -7.912884640295629],
              [-38.32860886582742, -7.912884679749824],
              [-38.328608822331731, -7.912884719486868],
              [-38.32860877909615, -7.912884759505082],
              [-38.328608736122483, -7.91288479980276],
              [-38.328608693412569, -7.912884840378206],
              [-38.328608650968192, -7.912884881229707],
              [-38.328608608791157, -7.912884922355532],
              [-38.328608566883254, -7.912884963753965],
              [-38.328608525246239, -7.9128850054232],
              [-38.328608483881894, -7.912885047361525],
              [-38.328608442791946, -7.912885089567159],
              [-38.328608401978165, -7.912885132038284],
              [-38.328608361442235, -7.912885174773149],
              [-38.328608321185897, -7.912885217769925],
              [-38.328608281210833, -7.912885261026822],
              [-38.328608241518772, -7.912885304541943],
              [-38.328608202111354, -7.912885348313504],
              [-38.328608162990264, -7.912885392339657],
              [-38.328608124157149, -7.912885436618517],
              [-38.328608085613652, -7.912885481148213],
              [-38.328608047361406, -7.912885525926878],
              [-38.328608009402025, -7.912885570952596],
              [-38.328607971737121, -7.912885616223488],
              [-38.328607934368286, -7.912885661737604],
              [-38.328607897297104, -7.912885707493042],
              [-38.328607860525111, -7.912885753487894],
              [-38.328607824053897, -7.912885799720156],
              [-38.328607787884998, -7.91288584618791],
              [-38.328607752019934, -7.912885892889173],
              [-38.328607716460226, -7.912885939822011],
              [-38.328607681207387, -7.912885986984399],
              [-38.328607646262896, -7.912886034374322],
              [-38.328607611628229, -7.912886081989829],
              [-38.328607577304858, -7.912886129828883],
              [-38.328607543294233, -7.912886177889479],
              [-38.328607509597795, -7.912886226169551],
              [-38.328607476216966, -7.912886274667074],
              [-38.328607443153146, -7.912886323380019],
              [-38.328607410407741, -7.912886372306292],
              [-38.328607377982159, -7.91288642144384],
              [-38.328607345877742, -7.912886470790585],
              [-38.328607314095848, -7.912886520344433],
              [-38.328607282637847, -7.912886570103312],
              [-38.328607251505041, -7.912886620065075],
              [-38.32860722069875, -7.912886670227628],
              [-38.32860719022031, -7.912886720588883],
              [-38.328607160070973, -7.912886771146664],
              [-38.328607130251996, -7.912886821898854],
              [-38.3286071007647, -7.912886872843284],
              [-38.328607071610307, -7.912886923977839],
              [-38.328607042790019, -7.912886975300343],
              [-38.328607014305092, -7.912887026808591],
              [-38.328606986156714, -7.912887078500451],
              [-38.328606958346093, -7.91288713037371],
              [-38.328606930874372, -7.912887182426198],
              [-38.328606903742745, -7.912887234655698],
              [-38.328606876952342, -7.91288728706],
              [-38.328606850504308, -7.912887339636867],
              [-38.328606824399749, -7.912887392384129],
              [-38.328606798639768, -7.912887445299511],
              [-38.328606773225474, -7.912887498380806],
              [-38.328606748157917, -7.912887551625765],
              [-38.32860672343817, -7.912887605032103],
              [-38.328606699067272, -7.912887658597596],
              [-38.328606675046274, -7.912887712319943],
              [-38.328606651376155, -7.912887766196927],
              [-38.328606628057948, -7.912887820226223],
              [-38.328606605092624, -7.912887874405561],
              [-38.328606582481164, -7.912887928732661],
              [-38.328606560224515, -7.912887983205191],
              [-38.328606538323619, -7.912888037820873],
              [-38.328606516779409, -7.912888092577422],
              [-38.328606495592787, -7.912888147472472],
              [-38.328606474764662, -7.912888202503745],
              [-38.328606454295901, -7.912888257668889],
              [-38.328606434187378, -7.912888312965571],
              [-38.328606414439932, -7.912888368391463],
              [-38.328606395054408, -7.912888423944217],
              [-38.32860637603163, -7.912888479621495],
              [-38.328606357372394, -7.912888535420916],
              [-38.328606339077488, -7.91288859134012],
              [-38.328606321147703, -7.912888647376767],
              [-38.328606303583776, -7.91288870352848],
              [-38.328606286386439, -7.912888759792904],
              [-38.32860626955646, -7.912888816167595],
              [-38.32860625309452, -7.912888872650234],
              [-38.328606237001324, -7.912888929238407],
              [-38.328606221277553, -7.912888985929746],
              [-38.328606205923869, -7.912889042721821],
              [-38.328606190940931, -7.91288909961224],
              [-38.328606176329352, -7.912889156598596],
              [-38.328606144642869, -7.912889176306121],
              [-38.328510366361961, -7.913268008891167],
              [-38.328510354061756, -7.913268000086042],
              [-38.328510328070898, -7.913268105507806],
              [-38.328510303350939, -7.913268211232984],
              [-38.328510279905451, -7.91326831724637],
              [-38.328510257737825, -7.913268423532701],
              [-38.32851023685123, -7.913268530076605],
              [-38.328510217248713, -7.913268636862775],
              [-38.328510198933067, -7.913268743875826],
              [-38.328510181906928, -7.913268851100335],
              [-38.328510166172762, -7.91326895852087],
              [-38.328510151732836, -7.913269066121955],
              [-38.328510138589223, -7.913269173888059],
              [-38.328510126743822, -7.913269281803683],
              [-38.328510116198338, -7.9132693898533],
              [-38.328510106954283, -7.91326949802131],
              [-38.328510099013009, -7.913269606292165],
              [-38.328510092375645, -7.913269714650233],
              [-38.328510087043128, -7.913269823079911],
              [-38.328510083016262, -7.913269931565617],
              [-38.328510080295608, -7.913270040091673],
              [-38.328510078881557, -7.913270148642488],
              [-38.328510078774322, -7.913270257202378],
              [-38.328510079973917, -7.91327036575575],
              [-38.328510082480157, -7.913270474286946],
              [-38.328510086292688, -7.913270582780343],
              [-38.328510091410976, -7.913270691220298],
              [-38.328510097834247, -7.913270799591191],
              [-38.328510105561634, -7.913270907877431],
              [-38.328510114591957, -7.91327101606338],
              [-38.328510124923966, -7.913271124133477],
              [-38.328510136556126, -7.913271232072182],
              [-38.328510149486824, -7.91327133986391],
              [-38.328510163714135, -7.913271447493105],
              [-38.328510179236041, -7.913271554944333],
              [-38.328510196050317, -7.91327166220207],
              [-38.328510214154512, -7.913271769250883],
              [-38.328510233546034, -7.91327187607537],
              [-38.328510254222088, -7.913271982660111],
              [-38.328510276179699, -7.913272088989717],
              [-38.328510299415683, -7.913272195048939],
              [-38.328510323926729, -7.913272300822471],
              [-38.328510349709276, -7.913272406295074],
              [-38.328510376759631, -7.91327251145157],
              [-38.328510405073878, -7.913272616276801],
              [-38.328510434647946, -7.913272720755671],
              [-38.328510465477578, -7.913272824873121],
              [-38.328510497558334, -7.913272928614157],
              [-38.32851053088558, -7.913273031963837],
              [-38.328510565454536, -7.913273134907262],
              [-38.328510601260213, -7.913273237429619],
              [-38.328510638297438, -7.913273339516137],
              [-38.328510676560903, -7.913273441152102],
              [-38.328510716045074, -7.913273542322865],
              [-38.328510756744258, -7.913273643013822],
              [-38.32851079865263, -7.91327374321055],
              [-38.328510841764121, -7.913273842898557],
              [-38.328510886072522, -7.913273942063506],
              [-38.328510931571465, -7.91327404069111],
              [-38.328510978254364, -7.913274138767139],
              [-38.328511026114533, -7.913274236277519],
              [-38.328511075145052, -7.91327433320817],
              [-38.328511125338892, -7.913274429545092],
              [-38.328511176688771, -7.913274525274469],
              [-38.328511229187328, -7.913274620382462],
              [-38.328511282827002, -7.9132747148554],
              [-38.328511337600041, -7.913274808679675],
              [-38.328511393498566, -7.913274901841751],
              [-38.328511450514554, -7.913274994328247],
              [-38.328511508639735, -7.913275086125808],
              [-38.328511567865782, -7.913275177221179],
              [-38.328511628184152, -7.913275267601293],
              [-38.328511689586144, -7.913275357253125],
              [-38.32851175206293, -7.913275446163741],
              [-38.328511815605502, -7.913275534320336],
              [-38.328511880204694, -7.913275621710213],
              [-38.328511945851218, -7.913275708320789],
              [-38.328512012535604, -7.913275794139564],
              [-38.328512080248267, -7.913275879154186],
              [-38.328512148979428, -7.913275963352446],
              [-38.328512218719197, -7.913276046722178],
              [-38.328512289457535, -7.913276129251376],
              [-38.328512361184238, -7.913276210928151],
              [-38.328512433888982, -7.913276291740742],
              [-38.3285125075613, -7.913276371677513],
              [-38.328512582190577, -7.91327645072693],
              [-38.328512657766041, -7.913276528877632],
              [-38.328512734276828, -7.913276606118315],
              [-38.32851281171191, -7.913276682437894],
              [-38.328512890060125, -7.9132767578254],
              [-38.328512969310204, -7.913276832269914],
              [-38.328513049450713, -7.913276905760754],
              [-38.328513130470121, -7.9132769782873],
              [-38.328513212356739, -7.913277049839124],
              [-38.328513295098794, -7.913277120405913],
              [-38.328513378684356, -7.913277189977528],
              [-38.328513463101388, -7.913277258543902],
              [-38.328513548337725, -7.913277326095173],
              [-38.328513634381082, -7.913277392621644],
              [-38.328513721219075, -7.913277458113703],
              [-38.328513808839197, -7.91327752256191],
              [-38.328513798344936, -7.913277511690791],
              [-38.329043434868453, -7.913662234366495],
              [-38.329043423489729, -7.913662203223256],
              [-38.329043435496835, -7.913662211959493],
              [-38.32904344748956, -7.913662220715336],
              [-38.32904345946789, -7.913662229490765],
              [-38.329043471431774, -7.913662238285749],
              [-38.329043483381191, -7.913662247100255],
              [-38.329043495316107, -7.913662255934274],
              [-38.329043507236491, -7.913662264787802],
              [-38.329043519142317, -7.91366227366075],
              [-38.329043531033527, -7.913662282553181],
              [-38.329043542910121, -7.91366229146498],
              [-38.329043554772056, -7.913662300396179],
              [-38.329043566619291, -7.913662309346751],
              [-38.32904357845181, -7.913662318316686],
              [-38.329043590269556, -7.913662327305907],
              [-38.329043602072524, -7.913662336314427],
              [-38.329043613860684, -7.913662345342216],
              [-38.329043625633972, -7.913662354389269],
              [-38.329043637392381, -7.91366236345554],
              [-38.329043649135883, -7.913662372540975],
              [-38.329043660864436, -7.91366238164561],
              [-38.329043672578003, -7.913662390769395],
              [-38.32904368427657, -7.913662399912293],
              [-38.329043695960081, -7.913662409074287],
              [-38.329043707628529, -7.913662418255385],
              [-38.32904371928187, -7.913662427455525],
              [-38.329043730920084, -7.913662436674644],
              [-38.329043742543135, -7.913662445912796],
              [-38.32904375415098, -7.913662455169927],
              [-38.329043765743599, -7.913662464446002],
              [-38.329043777320955, -7.913662473740992],
              [-38.329043788883027, -7.913662483054893],
              [-38.329043800429766, -7.913662492387689],
              [-38.329043811961164, -7.913662501739315],
              [-38.329043823477171, -7.913662511109735],
              [-38.329043834977767, -7.913662520499027],
              [-38.329043846462923, -7.913662529907035],
              [-38.329043857932582, -7.91366253933382],
              [-38.329043869386759, -7.913662548779326],
              [-38.329043880825388, -7.913662558243529],
              [-38.329043892248443, -7.913662567726409],
              [-38.329043903655901, -7.913662577227911],
              [-38.329043915047741, -7.913662586748074],
              [-38.329043926423907, -7.913662596286794],
              [-38.329043937784384, -7.913662605844121],
              [-38.329043949129144, -7.913662615419971],
              [-38.329043960458158, -7.913662625014332],
              [-38.329043971771377, -7.913662634627229],
              [-38.329043983068786, -7.91366264425859],
              [-38.329043994350364, -7.913662653908385],
              [-38.329044005616048, -7.913662663576551],
              [-38.329044016865836, -7.913662673263143],
              [-38.329044028099702, -7.913662682968087],
              [-38.329044039317587, -7.913662692691402],
              [-38.329044050519485, -7.913662702433008],
              [-38.329044061705353, -7.913662712192886],
              [-38.329044072875185, -7.913662721971067],
              [-38.329044084028915, -7.913662731767451],
              [-38.329044095166545, -7.913662741582036],
              [-38.329044106288016, -7.913662751414826],
              [-38.329044117393323, -7.913662761265751],
              [-38.329044128482423, -7.913662771134795],
              [-38.329044139555293, -7.913662781021994],
              [-38.329044150611907, -7.913662790927209],
              [-38.329044161652213, -7.913662800850488],
              [-38.329044172676205, -7.913662810791841],
              [-38.329044183683841, -7.913662820751123],
              [-38.329044194675099, -7.913662830728426],
              [-38.329044205649943, -7.913662840723608],
              [-38.329044216608352, -7.91366285073676],
              [-38.32904422755027, -7.913662860767779],
              [-38.329044238475703, -7.913662870816659],
              [-38.329044249384616, -7.913662880883376],
              [-38.329044260276959, -7.913662890967886],
              [-38.329044271152711, -7.913662901070178],
              [-38.329044282011864, -7.913662911190272],
              [-38.329044292854363, -7.913662921328045],
              [-38.329044303680178, -7.913662931483512],
              [-38.329044314489302, -7.913662941656677],
              [-38.329044325281679, -7.91366295184744],
              [-38.329044336057315, -7.913662962055866],
              [-38.329044346816147, -7.913662972281867],
              [-38.329044357558161, -7.913662982525449],
              [-38.329044368283327, -7.913662992786539],
              [-38.329044378991611, -7.913663003065143],
              [-38.329044389683006, -7.913663013361226],
              [-38.329044400357454, -7.913663023674753],
              [-38.329044411014934, -7.913663034005734],
              [-38.329044421655432, -7.913663044354063],
              [-38.329044432278906, -7.913663054719821],
              [-38.329044442885326, -7.913663065102849],
              [-38.329044453474673, -7.913663075503241],
              [-38.329044464046909, -7.913663085920886],
              [-38.329044474602021, -7.913663096355846],
              [-38.32904448513996, -7.913663106807988],
              [-38.32904449566071, -7.913663117277328],
              [-38.329044506164244, -7.913663127763841],
              [-38.329044516650526, -7.913663138267526],
              [-38.329044527119535, -7.913663148788298],
              [-38.329044537571122, -7.913663159327595],
              [-38.329044522044519, -7.913663140616968],
              [-38.32973866867988, -7.914364160227331],
              [-38.335404162906912, -7.912829593945538],
              [-38.335537034819872, -7.912793534858145],
              [-38.34899882462171, -7.909146791292565],
              [-38.349246707024179, -7.909991444026351],
              [-38.334770197745527, -7.913913084234323],
              [-38.334646582927313, -7.913946625187536],
              [-38.329986513164677, -7.915208821212068],
              [-38.330030431108497, -7.916045647349938],
              [-38.334209392059577, -7.914913746971772],
              [-38.334322025256114, -7.914883173521697],
              [-38.34929066430162, -7.910828271500946],
              [-38.349178311888373, -7.911259545975923],
              [-38.334125975418978, -7.915337102491074],
              [-38.334013069925277, -7.915367675441787],
              [-38.329922685449318, -7.91647601769566],
              [-38.3299226649847, -7.916475982737412],
              [-38.329922578493317, -7.916476006630694],
              [-38.329922492243263, -7.916476031375891],
              [-38.329922406242957, -7.916476056970608],
              [-38.329922320500813, -7.916476083412316],
              [-38.329922235025251, -7.916476110698451],
              [-38.329922149824611, -7.916476138826306],
              [-38.329922064907237, -7.916476167793162],
              [-38.329921980281433, -7.916476197596192],
              [-38.329921895955501, -7.916476228232451],
              [-38.329921811937673, -7.916476259698949],
              [-38.329921728236187, -7.916476291992598],
              [-38.32992164485924, -7.91647632511026],
              [-38.32992156181497, -7.916476359048684],
              [-38.329921479111512, -7.916476393804547],
              [-38.329921396756973, -7.916476429374423],
              [-38.32992131475941, -7.91647646575487],
              [-38.329921233126839, -7.916476502942297],
              [-38.329921151867254, -7.916476540933099],
              [-38.329921070988611, -7.916476579723538],
              [-38.32992099049882, -7.916476619309811],
              [-38.329920910405754, -7.916476659688058],
              [-38.329920830717271, -7.916476700854322],
              [-38.329920751441144, -7.916476742804547],
              [-38.329920672585168, -7.916476785534665],
              [-38.329920594157016, -7.916476829040477],
              [-38.329920516164414, -7.916476873317732],
              [-38.329920438614955, -7.91647691836208],
              [-38.32992036151623, -7.916476964169137],
              [-38.329920284875804, -7.916477010734378],
              [-38.329920208701175, -7.916477058053287],
              [-38.329920132999796, -7.91647710612123],
              [-38.329920057779077, -7.916477154933479],
              [-38.329919983046366, -7.916477204485277],
              [-38.329919908808996, -7.91647725477179],
              [-38.329919835074222, -7.916477305788034],
              [-38.329919761849261, -7.916477357529095],
              [-38.329919689141292, -7.916477409989868],
              [-38.329919616957412, -7.916477463165219],
              [-38.329919545304698, -7.91647751704993],
              [-38.32991947419017, -7.916477571638756],
              [-38.329919403620771, -7.91647762692634],
              [-38.329919333603414, -7.916477682907265],
              [-38.329919264144948, -7.916477739576058],
              [-38.329919195252188, -7.916477796927187],
              [-38.329919126931856, -7.916477854955023],
              [-38.32991905919063, -7.916477913653903],
              [-38.329918992035182, -7.916477973018042],
              [-38.329918925472057, -7.916478033041653],
              [-38.329918859507764, -7.916478093718909],
              [-38.329918794148774, -7.916478155043789],
              [-38.329918729401463, -7.916478217010336],
              [-38.329918665272196, -7.916478279612462],
              [-38.329918601767226, -7.916478342844067],
              [-38.329918538892784, -7.916478406698957],
              [-38.329918476655017, -7.916478471170881],
              [-38.329918415060014, -7.916478536253519],
              [-38.329918354113801, -7.916478601940514],
              [-38.329918293822345, -7.916478668225438],
              [-38.32991823419156, -7.916478735101779],
              [-38.32991817522727, -7.916478802563025],
              [-38.329918116935254, -7.91647887060257],
              [-38.329918059321194, -7.916478939213733],
              [-38.32991800239077, -7.916479008389793],
              [-38.329917946149507, -7.916479078124012],
              [-38.329917890602935, -7.916479148409542],
              [-38.329917835756504, -7.916479219239513],
              [-38.329917781615549, -7.916479290606994],
              [-38.329917728185393, -7.916479362504985],
              [-38.329917675471243, -7.916479434926473],
              [-38.329917623478295, -7.916479507864344],
              [-38.329917572211592, -7.916479581311461],
              [-38.329917521676201, -7.91647965526065],
              [-38.329917471877017, -7.916479729704672],
              [-38.329917422818951, -7.916479804636272],
              [-38.329917374506792, -7.916479880048048],
              [-38.329917326945264, -7.916479955932664],
              [-38.329917280139021, -7.916480032282691],
              [-38.329917234092655, -7.916480109090648],
              [-38.329917188810683, -7.916480186349039],
              [-38.329917144297511, -7.916480264050257],
              [-38.329917100557502, -7.916480342186747],
              [-38.32991705759494, -7.91648042075081],
              [-38.329917015414033, -7.916480499734811],
              [-38.329916974018907, -7.916480579130971],
              [-38.329916933413614, -7.916480658931544],
              [-38.329916893602125, -7.916480739128723],
              [-38.329916854588326, -7.916480819714658],
              [-38.329916816376077, -7.916480900681459],
              [-38.329916778969057, -7.916480982021231],
              [-38.329916742370969, -7.91648106372594],
              [-38.329916706585408, -7.916481145787636],
              [-38.329916671615827, -7.916481228198295],
              [-38.329916637465665, -7.916481310949817],
              [-38.329916604138297, -7.916481394034173],
              [-38.329916571636964, -7.916481477443144],
              [-38.329916539964827, -7.916481561168647],
              [-38.32991650912502, -7.9164816452024],
              [-38.329916479120541, -7.91648172953622],
              [-38.329916449954318, -7.916481814161904],
              [-38.329916416380748, -7.916481796446046],
              [-38.32962271424374, -7.917347838319986],
              [-38.329622757105753, -7.917347876404476],
              [-38.329622753394951, -7.917347887370232],
              [-38.329622749698224, -7.917347898340707],
              [-38.329622746015581, -7.917347909315892],
              [-38.329622742347027, -7.91734792029575],
              [-38.329622738692564, -7.917347931280299],
              [-38.329622735052219, -7.917347942269485],
              [-38.32962273142595, -7.917347953263289],
              [-38.329622727813813, -7.917347964261755],
              [-38.329622724215788, -7.91734797526482],
              [-38.329622720631882, -7.917347986272462],
              [-38.329622717062108, -7.917347997284638],
              [-38.329622713506467, -7.917348008301389],
              [-38.329622709964973, -7.917348019322656],
              [-38.329622706437625, -7.917348030348416],
              [-38.329622702924432, -7.917348041378664],
              [-38.329622699425386, -7.91734805241343],
              [-38.329622695940515, -7.917348063452577],
              [-38.329622692469812, -7.917348074496237],
              [-38.329622689013277, -7.917348085544261],
              [-38.329622685570939, -7.91734809659671],
              [-38.329622682142769, -7.917348107653542],
              [-38.329622678728796, -7.917348118714726],
              [-38.32962267532902, -7.917348129780279],
              [-38.329622671943454, -7.917348140850138],
              [-38.3296226685721, -7.91734815192433],
              [-38.329622665214956, -7.917348163002808],
              [-38.329622661872023, -7.917348174085537],
              [-38.329622658543329, -7.91734818517252],
              [-38.32962265522886, -7.917348196263751],
              [-38.329622651928624, -7.917348207359217],
              [-38.329622648642641, -7.917348218458852],
              [-38.32962264537089, -7.917348229562686],
              [-38.329622642113399, -7.917348240670676],
              [-38.329622638870163, -7.917348251782844],
              [-38.329622635641201, -7.917348262899099],
              [-38.329622632426492, -7.917348274019506],
              [-38.329622629226073, -7.917348285143975],
              [-38.329622626039914, -7.917348296272513],
              [-38.329622622868058, -7.9173483074051],
              [-38.329622619710484, -7.917348318541755],
              [-38.3296226165672, -7.917348329682437],
              [-38.329622613438218, -7.917348340827089],
              [-38.32962261032354, -7.91734835197574],
              [-38.329622607223172, -7.917348363128356],
              [-38.329622604137128, -7.9173483742849],
              [-38.329622601065395, -7.917348385445363],
              [-38.329622598007973, -7.917348396609759],
              [-38.32962259496491, -7.91734840777805],
              [-38.329622591936172, -7.917348418950239],
              [-38.329622588921765, -7.917348430126241],
              [-38.329622585921712, -7.917348441306093],
              [-38.329622582936004, -7.917348452489755],
              [-38.329622579964656, -7.917348463677235],
              [-38.329622577007662, -7.917348474868445],
              [-38.329622574065034, -7.917348486063483],
              [-38.329622571136774, -7.917348497262216],
              [-38.329622568222902, -7.917348508464698],
              [-38.32962256532339, -7.917348519670892],
              [-38.32962256243826, -7.917348530880763],
              [-38.329622559567532, -7.917348542094299],
              [-38.329622556711186, -7.917348553311514],
              [-38.32962255386925, -7.917348564532339],
              [-38.329622551041695, -7.917348575756777],
              [-38.329622548228564, -7.917348586984859],
              [-38.329622545429835, -7.917348598216486],
              [-38.329622542645538, -7.917348609451674],
              [-38.329622539875658, -7.917348620690405],
              [-38.329622537120187, -7.917348631932668],
              [-38.329622534379169, -7.91734864317842],
              [-38.329622531652575, -7.917348654427684],
              [-38.329622528940412, -7.917348665680391],
              [-38.329622526242687, -7.917348676936559],
              [-38.329622523559429, -7.917348688196171],
              [-38.329622520890609, -7.917348699459174],
              [-38.329622518236249, -7.917348710725587],
              [-38.329622515596348, -7.917348721995377],
              [-38.329622512970914, -7.917348733268507],
              [-38.329622510359961, -7.917348744544999],
              [-38.329622507763467, -7.917348755824814],
              [-38.329622505181455, -7.917348767107939],
              [-38.329622502613915, -7.917348778394339],
              [-38.329622500060871, -7.917348789683998],
              [-38.329622497522323, -7.917348800976913],
              [-38.329622494998269, -7.917348812273052],
              [-38.329622492488696, -7.9173488235724],
              [-38.329622489993646, -7.917348834874953],
              [-38.329622487513092, -7.917348846180633],
              [-38.32962248504704, -7.917348857489516],
              [-38.329622482595532, -7.917348868801543],
              [-38.329622480158527, -7.917348880116677],
              [-38.329622477736038, -7.917348891434895],
              [-38.32962247532808, -7.91734890275621],
              [-38.329622472934645, -7.917348914080591],
              [-38.329622470555755, -7.917348925407998],
              [-38.329622468191403, -7.917348936738427],
              [-38.329622465841609, -7.91734894807186],
              [-38.329622463506347, -7.917348959408317],
              [-38.329622461185629, -7.91734897074771],
              [-38.329622458879463, -7.91734898209006],
              [-38.32962244018087, -7.917349013733161],
              [-38.329475844897928, -7.918072380318402],
              [-38.329475854450095, -7.918072400492251],
              [-38.329475842790409, -7.918072459020477],
              [-38.329475831518266, -7.918072517624143],
              [-38.329475820634151, -7.918072576300657],
              [-38.329475810138518, -7.918072635047479],
              [-38.329475800031844, -7.918072693862083],
              [-38.329475790314547, -7.918072752741853],
              [-38.32947578098706, -7.918072811684286],
              [-38.329475772049811, -7.918072870686763],
              [-38.329475763503162, -7.918072929746748],
              [-38.329475755347488, -7.918072988861658],
              [-38.329475747583146, -7.91807304802894],
              [-38.329475740210484, -7.918073107245993],
              [-38.329475733229806, -7.918073166510228],
              [-38.329475726641434, -7.91807322581912],
              [-38.32947572044565, -7.91807328517003],
              [-38.32947571464269, -7.918073344560428],
              [-38.32947570923286, -7.918073403987677],
              [-38.329475704216378, -7.918073463449236],
              [-38.329475699593438, -7.918073522942482],
              [-38.329475695364266, -7.918073582464867],
              [-38.329475691529034, -7.918073642013757],
              [-38.329475688087918, -7.918073701586552],
              [-38.329475685041075, -7.91807376118067],
              [-38.329475682388626, -7.918073820793583],
              [-38.329475680130663, -7.918073880422627],
              [-38.329475678267322, -7.918073940065212],
              [-38.329475676798666, -7.918073999718776],
              [-38.329475675724773, -7.918074059380708],
              [-38.329475675045671, -7.918074119048398],
              [-38.32947567476139, -7.918074178719261],
              [-38.329475674871958, -7.918074238390697],
              [-38.329475675377374, -7.918074298060088],
              [-38.329475676277603, -7.918074357724872],
              [-38.329475677572589, -7.918074417382408],
              [-38.329475679262302, -7.918074477030165],
              [-38.329475681346658, -7.918074536665483],
              [-38.329475683825585, -7.918074596285821],
              [-38.329475686698956, -7.918074655888526],
              [-38.329475689966628, -7.918074715471059],
              [-38.329475693628503, -7.91807477503079],
              [-38.329475697684394, -7.918074834565156],
              [-38.329475702134118, -7.918074894071554],
              [-38.329475706977497, -7.918074953547345],
              [-38.329475712214318, -7.918075012989991],
              [-38.329475717844353, -7.918075072396928],
              [-38.329475723867347, -7.918075131765528],
              [-38.32947573028305, -7.918075191093227],
              [-38.329475737091173, -7.91807525037742],
              [-38.329475744291429, -7.918075309615544],
              [-38.329475751883493, -7.918075368805048],
              [-38.329475759867066, -7.918075427943308],
              [-38.329475768241757, -7.918075487027759],
              [-38.329475777007204, -7.918075546055866],
              [-38.329475786163073, -7.918075605025025],
              [-38.32947579570893, -7.918075663932695],
              [-38.329475805644357, -7.918075722776276],
              [-38.329475815968934, -7.91807578155323],
              [-38.329475826682199, -7.918075840260991],
              [-38.329475837783697, -7.918075898896998],
              [-38.329475849272946, -7.918075957458742],
              [-38.32947586114944, -7.918076015943609],
              [-38.329475873412669, -7.918076074349103],
              [-38.329475886062077, -7.918076132672652],
              [-38.329475899097154, -7.918076190911738],
              [-38.329475912517282, -7.918076249063817],
              [-38.329475926321926, -7.918076307126365],
              [-38.329475940510456, -7.918076365096835],
              [-38.329475955082252, -7.918076422972749],
              [-38.329475970036704, -7.918076480751544],
              [-38.329475985373136, -7.918076538430724],
              [-38.329476001090889, -7.918076596007767],
              [-38.329476017189286, -7.918076653480177],
              [-38.329476033667625, -7.918076710845451],
              [-38.32947605052518, -7.918076768101104],
              [-38.329476067761227, -7.918076825244622],
              [-38.329476085374999, -7.918076882273524],
              [-38.329476103365757, -7.91807693918532],
              [-38.329476121732696, -7.918076995977548],
              [-38.329476140475016, -7.918077052647741],
              [-38.32947615959192, -7.918077109193424],
              [-38.329476179082562, -7.91807716561213],
              [-38.329476198946089, -7.918077221901419],
              [-38.329476219181643, -7.91807727805883],
              [-38.329476239788356, -7.918077334081908],
              [-38.329476260765318, -7.91807738996822],
              [-38.3294762821116, -7.918077445715358],
              [-38.329476303826304, -7.918077501320897],
              [-38.32947632590848, -7.91807755678237],
              [-38.32947634835714, -7.918077612097387],
              [-38.329476371171324, -7.918077667263573],
              [-38.32947639435006, -7.918077722278479],
              [-38.329476417892295, -7.918077777139729],
              [-38.329476441797055, -7.918077831844946],
              [-38.329476466063248, -7.918077886391741],
              [-38.329476490689856, -7.918077940777742],
              [-38.329476515675793, -7.918077995000565],
              [-38.329476541019972, -7.918078049057881],
              [-38.329476566721297, -7.918078102947327],
              [-38.329476592778626, -7.918078156666543],
              [-38.329476561304553, -7.918078170287147],
              [-38.329611561860141, -7.918354079681371],
              [-38.333062633404275, -7.917428425297068],
              [-38.333190429654991, -7.917390459530259],
              [-38.37199644254806, -7.905851599375905],
              [-38.36757759181215, -7.902284330620505],
              [-38.360238814739645, -7.900597017096139],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0363000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DO CAROÁ',
        municipio: 'AGUAS BELAS',
        area_hecta: '267.3818',
        capacidade: 9.0,
        num_famili: 8.0,
        fase: 4.0,
        data_de_cr: '08/11/2006',
        forma_obte: 'Desapropriação',
        data_obten: '18/11/2005',
        area_calc_: 267.1723,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.965990878069988, -8.926859171337446],
              [-36.963364324688143, -8.928802313053644],
              [-36.958832469674554, -8.932154721939082],
              [-36.961282853570665, -8.934290440621554],
              [-36.960851719942525, -8.934690516560744],
              [-36.959845850623694, -8.936186472805234],
              [-36.959389042468999, -8.937115315487356],
              [-36.961809467455218, -8.940867347513763],
              [-36.961882134426972, -8.940976494400402],
              [-36.963047429820598, -8.9427914694348],
              [-36.964057876690745, -8.944433024653819],
              [-36.964053758087829, -8.945157623005478],
              [-36.964052350788968, -8.945188978525994],
              [-36.963979877481727, -8.946803704643425],
              [-36.965227305010416, -8.948338086288471],
              [-36.965553299799765, -8.947850558976523],
              [-36.966107929093305, -8.947021049697682],
              [-36.967076227417699, -8.946826498109134],
              [-36.967484365927291, -8.947000853956812],
              [-36.967891718130453, -8.946923810993425],
              [-36.96822152737338, -8.946993237372592],
              [-36.968699949223151, -8.946887746679154],
              [-36.970125524485162, -8.946105129716139],
              [-36.970265983678694, -8.945974009966582],
              [-36.970326266039685, -8.945464138704374],
              [-36.971013663360118, -8.945150700659093],
              [-36.971775724148017, -8.945029673601599],
              [-36.978376229657329, -8.940254159242626],
              [-36.978327070525225, -8.940200375721187],
              [-36.976293302144875, -8.937975713235238],
              [-36.974251217949288, -8.935763202905976],
              [-36.972242751336481, -8.933591368206775],
              [-36.969828314739324, -8.930991445030449],
              [-36.969751499921145, -8.930908763249317],
              [-36.965990878069988, -8.926859171337446],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0256000',
        uf: 'PE',
        nome_proje: 'PA CLARA GOMES',
        municipio: 'OURICURI',
        area_hecta: '566.7986',
        capacidade: 41.0,
        num_famili: 39.0,
        fase: 3.0,
        data_de_cr: '20/12/2007',
        forma_obte: 'Doação',
        data_obten: '20/12/2007',
        area_calc_: 567.4364,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.021296820124299, -8.164540708944239],
              [-40.003604965284779, -8.160451760122342],
              [-40.001855646611496, -8.165095756330185],
              [-39.999603900512575, -8.164820952434367],
              [-39.979994409130157, -8.170865222978351],
              [-39.993021216906968, -8.182382902997608],
              [-39.985342327732965, -8.184820447033948],
              [-39.990485910556096, -8.187114361092158],
              [-39.990486508796181, -8.187114379393167],
              [-39.990487104525378, -8.187114436934094],
              [-39.990487695153767, -8.187114533464861],
              [-39.99048827811356, -8.187114668565728],
              [-39.990488850870378, -8.187114841649414],
              [-39.990489410934124, -8.18711505196338],
              [-39.990489955869926, -8.187115298593305],
              [-39.990490483308676, -8.187115580466967],
              [-39.990490483308676, -8.187115580466967],
              [-39.991843413832804, -8.187896897817007],
              [-39.991843966911539, -8.187897192273475],
              [-39.991844539074762, -8.187897447981838],
              [-39.991845127591908, -8.187897663721746],
              [-39.991845729654358, -8.187897838463622],
              [-39.99184634238884, -8.187897971373543],
              [-39.991846962871158, -8.187898061817204],
              [-39.991847588140153, -8.187898109362962],
              [-39.991848215211796, -8.187898113783945],
              [-39.991848841093493, -8.187898075059026],
              [-39.991849462798292, -8.187897993373012],
              [-39.991850077359175, -8.187897869115753],
              [-39.99185068184326, -8.187897702880237],
              [-39.991851273365711, -8.187897495459834],
              [-39.991851849103561, -8.187897247844369],
              [-39.991852406309185, -8.18789696121563],
              [-39.991852942323391, -8.187896636941451],
              [-39.99185345458811, -8.187896276569431],
              [-39.991853940658643, -8.187895881819379],
              [-39.991854398215288, -8.187895454575173],
              [-39.991854398215288, -8.187895454575173],
              [-39.992518528736362, -8.187230925086544],
              [-39.992518975864897, -8.187230506937953],
              [-39.992519450309281, -8.187230119822384],
              [-39.992519949904199, -8.187229765506606],
              [-39.992520472369563, -8.187229445607688],
              [-39.992521015320889, -8.187229161585579],
              [-39.992521576280225, -8.18722891473657],
              [-39.992522152687393, -8.187228706187229],
              [-39.992522741911749, -8.187228536889325],
              [-39.992523341264132, -8.187228407615562],
              [-39.992523948009165, -8.187228318955885],
              [-39.992524559377728, -8.187228271314975],
              [-39.992525172579612, -8.187228264910233],
              [-39.992525784816223, -8.187228299770892],
              [-39.992526393293382, -8.187228375737853],
              [-39.992526995234073, -8.187228492464415],
              [-39.99252758789109, -8.187228649417831],
              [-39.992528168559637, -8.187228845881835],
              [-39.992528734589591, -8.187229080959721],
              [-39.992529283397644, -8.187229353578655],
              [-39.992529812479113, -8.187229662494422],
              [-39.99253031941933, -8.187230006297183],
              [-39.992530801904671, -8.187230383417857],
              [-39.99253125773312, -8.187230792135283],
              [-39.992531684824336, -8.187231230584123],
              [-39.992532081229122, -8.18723169676338],
              [-39.992532445138316, -8.187232188545446],
              [-39.992532774891096, -8.187232703685876],
              [-39.992533068982496, -8.187233239833626],
              [-39.992533068982496, -8.187233239833626],
              [-39.993209014673937, -8.188571023086441],
              [-39.993209023148459, -8.188571039869705],
              [-39.993209268703694, -8.188571567829593],
              [-39.993209479675102, -8.188572110429574],
              [-39.993209655189368, -8.188572665423543],
              [-39.993209794519942, -8.188573230514061],
              [-39.993209897090068, -8.188573803361953],
              [-39.993209962475149, -8.188574381595924],
              [-39.993209990404523, -8.188574962822315],
              [-39.993209980762572, -8.18857554463516],
              [-39.99320993358922, -8.188576124626026],
              [-39.99320984907974, -8.188576700394037],
              [-39.993209849079726, -8.188576700394037],
              [-39.993209845756901, -8.188576718884665],
              [-39.992988590684732, -8.189805446847965],
              [-39.99298858742555, -8.18980546485175],
              [-39.99298850024411, -8.189806070934194],
              [-39.992988454420306, -8.189806681504825],
              [-39.992988450164134, -8.189807293765384],
              [-39.992988487495133, -8.189807904909843],
              [-39.992988566242182, -8.18980851213732],
              [-39.992988686044406, -8.18980911266488],
              [-39.992988846352723, -8.189809703740281],
              [-39.992989046432456, -8.189810282654602],
              [-39.992989285366633, -8.189810846754686],
              [-39.992989562060203, -8.189811393455219],
              [-39.992989875245065, -8.189811920250669],
              [-39.992990223485911, -8.189812424726707],
              [-39.992990605186719, -8.189812904571342],
              [-39.992991018598147, -8.189813357585406],
              [-39.992991461825532, -8.18981378169271],
              [-39.992991932837541, -8.189814174949586],
              [-39.992992429475514, -8.18981453555369],
              [-39.992992949463357, -8.189814861852408],
              [-39.992993490417923, -8.189815152350272],
              [-39.992994049860023, -8.189815405715944],
              [-39.992994625225712, -8.189815620788211],
              [-39.992995213878082, -8.189815796581414],
              [-39.992995813119308, -8.189815932289907],
              [-39.99299642020307, -8.189816027291698],
              [-39.99299642020307, -8.189816027291684],
              [-39.992996428450823, -8.189816028289789],
              [-39.99299643117017, -8.189816028618873],
              [-39.994795024666558, -8.190035352077555],
              [-39.99479974648127, -8.190035973534085],
              [-39.994802914811082, -8.190032438491667],
              [-39.995701337989509, -8.189020982416444],
              [-39.995701377555065, -8.189020937396382],
              [-39.99570174104187, -8.189020496473127],
              [-39.995702075899608, -8.189020033590698],
              [-39.995702380795471, -8.189019550591439],
              [-39.995702654515895, -8.189019049397825],
              [-39.995702895971441, -8.18901853200467],
              [-39.995703104201048, -8.18901800047135],
              [-39.99570327837592, -8.18901745691347],
              [-39.995703417802801, -8.189016903494489],
              [-39.995703521926764, -8.189016342417142],
              [-39.995703590333349, -8.189015775914637],
              [-39.995703590333349, -8.189015775914637],
              [-39.995703594297623, -8.189015731222359],
              [-39.995813163517305, -8.187665887217333],
              [-39.995813252268384, -8.18766507302148],
              [-39.995813159472775, -8.187664259273397],
              [-39.995697445718569, -8.186431007709377],
              [-39.99569744011945, -8.186430946231743],
              [-39.995697405699076, -8.186430350099547],
              [-39.99569741083468, -8.186429753003612],
              [-39.995697455503887, -8.186429157546717],
              [-39.99569753951198, -8.186428566324381],
              [-39.99569766249278, -8.186427981913754],
              [-39.995697823910199, -8.186427406862263],
              [-39.995698023060626, -8.186426843676527],
              [-39.995698259075994, -8.186426294811476],
              [-39.995698530927491, -8.186425762659582],
              [-39.995698837430147, -8.186425249540473],
              [-39.995699177247907, -8.186424757690858],
              [-39.995699548899516, -8.186424289254642],
              [-39.995699950764973, -8.186423846273755],
              [-39.995700381092533, -8.186423430679152],
              [-39.995700838006442, -8.186423044282339],
              [-39.995701319515007, -8.186422688767685],
              [-39.995701823519333, -8.186422365684807],
              [-39.995702347822515, -8.186422076442046],
              [-39.995702347822515, -8.186422076442046],
              [-39.995702415997997, -8.186422041707065],
              [-39.996374665346167, -8.186085296463967],
              [-39.996375662724894, -8.186084841793861],
              [-39.996376477883345, -8.186084116247654],
              [-39.997844640861608, -8.184840985876386],
              [-39.997844622406888, -8.184840945539706],
              [-39.997845345900025, -8.184840261214861],
              [-39.997845365242142, -8.184840260552093],
              [-39.998841613028787, -8.183788311236736],
              [-39.998843785934056, -8.183786044818383],
              [-39.998844050591963, -8.183782969142062],
              [-39.99900728329154, -8.182115903639646],
              [-39.999007341330902, -8.18211549156984],
              [-39.999007370909872, -8.182114637237497],
              [-39.998946117575301, -8.180700368941006],
              [-39.998946114644511, -8.18070031471977],
              [-39.998946061960126, -8.180699713495157],
              [-39.998945969149524, -8.180699117109949],
              [-39.998945836625992, -8.180698528219772],
              [-39.99894566497963, -8.180697949446808],
              [-39.998945454974745, -8.180697383368273],
              [-39.998945207546448, -8.180696832504754],
              [-39.998944923796486, -8.18069629930916],
              [-39.998944604988367, -8.180695786155724],
              [-39.998944252541655, -8.180695295329407],
              [-39.998943868025741, -8.180694829015774],
              [-39.998943453152819, -8.180694389291212],
              [-39.998943453152819, -8.180694389291212],
              [-39.99894337933614, -8.180694316107292],
              [-39.998098023225012, -8.179857466295147],
              [-39.99809793238321, -8.179857376231388],
              [-39.998097495459859, -8.179856970659484],
              [-39.998097032717837, -8.179856594588879],
              [-39.998096546159836, -8.17985624964717],
              [-39.998096546159822, -8.179856249647187],
              [-39.998096476959475, -8.179856203927885],
              [-39.997061690317395, -8.179204772895773],
              [-39.997061542441713, -8.179204681317175],
              [-39.997061163046297, -8.179204433045019],
              [-39.997060796581373, -8.179204166186661],
              [-39.997060443963271, -8.179203881409368],
              [-39.997060443963271, -8.179203881409368],
              [-39.997060326274394, -8.17920378139997],
              [-39.995558495650869, -8.177810065215697],
              [-39.995558467119018, -8.177810038668916],
              [-39.995557994208525, -8.177809628196639],
              [-39.995557493978843, -8.177809251241881],
              [-39.995556968818157, -8.177808909604298],
              [-39.995556421233623, -8.177808604914889],
              [-39.995555853839505, -8.177808338628282],
              [-39.995555269344628, -8.177808112015788],
              [-39.995554670539448, -8.177807926159305],
              [-39.99555406028275, -8.177807781946086],
              [-39.995553441487978, -8.177807680064651],
              [-39.995552817109363, -8.177807621001422],
              [-39.995552190127754, -8.177807605038327],
              [-39.995551563536466, -8.177807632251636],
              [-39.995550940326929, -8.177807702511371],
              [-39.99555032347444, -8.177807815482144],
              [-39.995549715923929, -8.177807970624556],
              [-39.995549120575951, -8.17780816719802],
              [-39.995548540272758, -8.177808404264015],
              [-39.995547977784817, -8.177808680690736],
              [-39.995547435797512, -8.177808995158543],
              [-39.99554691689837, -8.177809346166068],
              [-39.995546423564697, -8.177809732037572],
              [-39.995546423564697, -8.177809732037572],
              [-39.995546422195062, -8.177809733182031],
              [-39.994337064459671, -8.178832804623637],
              [-39.994332354209, -8.178836795657405],
              [-39.994326900530311, -8.178833824488922],
              [-39.992453388458785, -8.177814715896734],
              [-39.992448671373971, -8.177812200174953],
              [-39.992443343507041, -8.175661253690247],
              [-39.992443325117698, -8.175660700178685],
              [-39.992443272761548, -8.175660148826291],
              [-39.992443186634915, -8.175659601700449],
              [-39.992443067060748, -8.175659060852672],
              [-39.992443067060741, -8.175659060852672],
              [-39.992443065749931, -8.175659055682612],
              [-39.99206357989577, -8.174164708710128],
              [-39.992063523192272, -8.174164485068475],
              [-39.992063382531164, -8.174163990016401],
              [-39.992063213843679, -8.174163503735276],
              [-39.992063213843679, -8.174163503735292],
              [-39.992063122468224, -8.174163262759549],
              [-39.991639206350349, -8.173201275430811],
              [-39.991639204231625, -8.173201270532315],
              [-39.991638985226167, -8.173200715122457],
              [-39.991638803456901, -8.173200146531611],
              [-39.991638659715179, -8.173199567235089],
              [-39.991638554626753, -8.173198979754845],
              [-39.99163848864913, -8.173198386648448],
              [-39.991638462069538, -8.173197790497937],
              [-39.991638475003711, -8.173197193898652],
              [-39.99163852739531, -8.173196599447866],
              [-39.991638619016271, -8.173196009733466],
              [-39.991638749467704, -8.173195427322783],
              [-39.991638918181721, -8.173194854751282],
              [-39.991638918181728, -8.173194854751282],
              [-39.991638918277118, -8.173194854452417],
              [-39.991638920245109, -8.173194848514283],
              [-39.992015696177717, -8.172014476046561],
              [-39.992015756673645, -8.172014328016415],
              [-39.992015884815892, -8.172013995885917],
              [-39.992015999747252, -8.172013658990235],
              [-39.992015999747252, -8.172013658990235],
              [-39.992016056799862, -8.172013480299615],
              [-39.99201662158444, -8.172012554985725],
              [-39.992759847826612, -8.170794889682256],
              [-39.992760134228199, -8.17079444971235],
              [-39.992760445741212, -8.17079402702168],
              [-39.992760781318275, -8.170793623031392],
              [-39.992761139831167, -8.170793239099741],
              [-39.992761520074524, -8.170792876517565],
              [-39.992761520074524, -8.170792876517565],
              [-39.993697329406466, -8.169950943372955],
              [-39.993697774152615, -8.169950568343038],
              [-39.993698242130108, -8.169950222515652],
              [-39.993698731410795, -8.16994990731571],
              [-39.99369923997871, -8.169949624041839],
              [-39.993699765738462, -8.169949373861265],
              [-39.99370030652382, -8.169949157804732],
              [-39.993700860106635, -8.169948976762456],
              [-39.993701424206016, -8.16994883148034],
              [-39.993701424206016, -8.16994883148034],
              [-39.995390139499101, -8.169571070672543],
              [-39.995390625740846, -8.169570975711745],
              [-39.995391116458698, -8.169570907329629],
              [-39.995391610190389, -8.169570865729931],
              [-39.995392105464681, -8.169570851036672],
              [-39.995392105464681, -8.169570851036655],
              [-39.996801573579667, -8.169567381354701],
              [-39.996801613273043, -8.169567381256917],
              [-39.996802299733396, -8.16956737956586],
              [-39.99811741706884, -8.169657635532587],
              [-39.998118028548561, -8.169657656901283],
              [-39.99811864008386, -8.169657637175019],
              [-39.998119248896046, -8.169657576443479],
              [-39.998119852218757, -8.169657474982603],
              [-39.998120447310619, -8.169657333253415],
              [-39.998120447310619, -8.169657333253415],
              [-39.999152417594601, -8.169374600356642],
              [-39.99915294105957, -8.169374473459289],
              [-39.999153471158102, -8.169374377728198],
              [-39.999153471158102, -8.169374377728198],
              [-40.001440536417263, -8.169030859648657],
              [-40.001441120068833, -8.169030752430267],
              [-40.001441695440874, -8.169030607437774],
              [-40.001442260073567, -8.169030425291011],
              [-40.001442811553126, -8.169030206768614],
              [-40.001443347521942, -8.169029952804863],
              [-40.001443347521942, -8.169029952804863],
              [-40.003521099094264, -8.167961080355918],
              [-40.003521654498272, -8.16796081797126],
              [-40.00352222645104, -8.167960593641766],
              [-40.003522812329777, -8.167960408396159],
              [-40.003523409447808, -8.16796026308389],
              [-40.003524015066915, -8.167960158371356],
              [-40.003524626409934, -8.167960094738694],
              [-40.003525240673419, -8.167960072477726],
              [-40.003525240673419, -8.167960072477726],
              [-40.00690639665126, -8.167951674498834],
              [-40.006907027591602, -8.167951694801481],
              [-40.006907655589849, -8.1679517587501],
              [-40.006908277608559, -8.167951866035372],
              [-40.006908890639153, -8.167952016138416],
              [-40.006909491716584, -8.167952208333217],
              [-40.006910077933611, -8.167952441690167],
              [-40.006910646454813, -8.167952715080563],
              [-40.006911194530424, -8.167953027182099],
              [-40.006911194530417, -8.167953027182115],
              [-40.007939058224771, -8.168586577123822],
              [-40.007939508318536, -8.168586837083639],
              [-40.007939972620555, -8.168587070922808],
              [-40.00794044960557, -8.168587277873129],
              [-40.00794044960557, -8.168587277873129],
              [-40.010768365238555, -8.169719294762825],
              [-40.01076891142101, -8.169719534465951],
              [-40.010769440613572, -8.169719809413833],
              [-40.010769950528463, -8.169720118417791],
              [-40.010770438961231, -8.169720460141985],
              [-40.010770903800292, -8.169720833109091],
              [-40.010770903800292, -8.169720833109091],
              [-40.012182550775236, -8.170931817028496],
              [-40.012182973832481, -8.17093220382136],
              [-40.012183371499702, -8.170932616491378],
              [-40.012183742185684, -8.1709330533873],
              [-40.012184084407167, -8.170933512760929],
              [-40.012184396794794, -8.170933992774152],
              [-40.012184396794794, -8.170933992774152],
              [-40.0127487375185, -8.171864350898339],
              [-40.012749058705907, -8.171864843515182],
              [-40.012749411311908, -8.171865314317236],
              [-40.012749793848904, -8.171865761318283],
              [-40.012750204703003, -8.171866182632499],
              [-40.012750642140887, -8.171866576482333],
              [-40.012751104317033, -8.171866941206245],
              [-40.012751589281606, -8.171867275265503],
              [-40.012752094988571, -8.171867577250737],
              [-40.012752619304429, -8.171867845887904],
              [-40.012753160017155, -8.171868080043655],
              [-40.012753160017155, -8.171868080043655],
              [-40.013688525088767, -8.172237636789102],
              [-40.013689102602321, -8.172237842745586],
              [-40.013689692757666, -8.172238009368742],
              [-40.013690292859799, -8.172238135897652],
              [-40.013690900168292, -8.172238221754482],
              [-40.013691511909769, -8.1722382665472],
              [-40.013692125290675, -8.172238270071237],
              [-40.013692737509892, -8.1722382323105],
              [-40.013693345771692, -8.172238153437409],
              [-40.013693947298329, -8.172238033812151],
              [-40.013694539342886, -8.172237873981047],
              [-40.013695119201728, -8.172237674673962],
              [-40.013695119201728, -8.172237674673962],
              [-40.015659588457837, -8.171487527674191],
              [-40.01566015969405, -8.171487286929969],
              [-40.015660713096402, -8.171487007878678],
              [-40.015661246081002, -8.171486691823215],
              [-40.01566175615929, -8.171486340239289],
              [-40.015662240949688, -8.171485954768448],
              [-40.015662698188656, -8.171485537210543],
              [-40.015663125741312, -8.171485089515141],
              [-40.015663521611366, -8.171484613772583],
              [-40.015663883950474, -8.171484112204167],
              [-40.015664211066863, -8.17148358715172],
              [-40.015664501433157, -8.171483041066793],
              [-40.015664753693642, -8.171482476499097],
              [-40.015664966670499, -8.171481896084622],
              [-40.01566513936929, -8.171481302533383],
              [-40.01566527098371, -8.171480698616749],
              [-40.015665360899199, -8.171480087154402],
              [-40.015665408695966, -8.171479471001376],
              [-40.015665408695966, -8.171479471001376],
              [-40.015753766377934, -8.169431780546239],
              [-40.015753813752866, -8.169431168164726],
              [-40.015753902732861, -8.169430560399176],
              [-40.015754032907559, -8.169429960052586],
              [-40.015754203676579, -8.169429369893688],
              [-40.015754414252363, -8.16942879264432],
              [-40.015754663663742, -8.169428230966686],
              [-40.015754950760439, -8.169427687451199],
              [-40.015755274218371, -8.169427164604572],
              [-40.01575563254579, -8.169426664838134],
              [-40.015756024090081, -8.169426190456759],
              [-40.015756447045476, -8.169425743648279],
              [-40.015756899461323, -8.169425326473371],
              [-40.015757379251099, -8.169424940856016],
              [-40.015757884202046, -8.169424588574669],
              [-40.015758411985345, -8.169424271253986],
              [-40.015758411985345, -8.169424271253986],
              [-40.017444268364798, -8.168486960787835],
              [-40.017444993595824, -8.16848659719896],
              [-40.018290097651956, -8.168110406118146],
              [-40.019605266863621, -8.16754572551479],
              [-40.019605256068004, -8.167545717094775],
              [-40.01960562947712, -8.167545543718653],
              [-40.020355665283013, -8.167263588305735],
              [-40.020356177752852, -8.167263412880937],
              [-40.020356699815281, -8.167263268238409],
              [-40.020357229609729, -8.167263154893641],
              [-40.020357765248072, -8.167263073250599],
              [-40.020358304821336, -8.167263023600235],
              [-40.020358846406545, -8.16726300611953],
              [-40.020358846406545, -8.16726300611953],
              [-40.021108772681679, -8.167261114878581],
              [-40.02110936839712, -8.167261093874629],
              [-40.021109961444402, -8.167261033956843],
              [-40.021110549265899, -8.167260935383611],
              [-40.021110549265906, -8.167260935383627],
              [-40.02251538178588, -8.16697779648311],
              [-40.022515966759748, -8.16697769828858],
              [-40.022516556921602, -8.166977638376654],
              [-40.022517149750719, -8.166977617003257],
              [-40.022517742714918, -8.166977634259693],
              [-40.022518333281482, -8.166977690072205],
              [-40.022518918927929, -8.16697778420246],
              [-40.022519497152764, -8.166977916248351],
              [-40.022520065486233, -8.166978085645901],
              [-40.022520621500803, -8.166978291671539],
              [-40.022521162821576, -8.16697853344529],
              [-40.022521687136404, -8.166978809934445],
              [-40.022522192205763, -8.16697911995805],
              [-40.022522675872374, -8.166979462191911],
              [-40.02252313607034, -8.16697983517421],
              [-40.02252313607034, -8.16697983517421],
              [-40.023269394825846, -8.167626163136926],
              [-40.023269869005183, -8.167626546689981],
              [-40.023270367998173, -8.167626897601439],
              [-40.023270889545564, -8.16762721428255],
              [-40.023271431285984, -8.167627495299477],
              [-40.023271990766659, -8.167627739379922],
              [-40.023272565454469, -8.167627945418724],
              [-40.023273152747457, -8.167628112483069],
              [-40.023273749986572, -8.167628239816553],
              [-40.023274354467759, -8.167628326842628],
              [-40.023274963454149, -8.167628373167297],
              [-40.02327557418851, -8.16762837858081],
              [-40.023276183905665, -8.167628343058665],
              [-40.023276789845028, -8.167628266761673],
              [-40.023277389263164, -8.16762815003532],
              [-40.023277979446135, -8.167627993408045],
              [-40.023278557721824, -8.167627797589041],
              [-40.023279121472015, -8.167627563464864],
              [-40.023279121472015, -8.167627563464864],
              [-40.024306615300716, -8.167159818323803],
              [-40.024307061163306, -8.167159629517547],
              [-40.02430751650089, -8.167159464683056],
              [-40.024307980016339, -8.167159324289898],
              [-40.024307980016346, -8.167159324289898],
              [-40.025338496621288, -8.166876892406702],
              [-40.025339060344436, -8.166876718074608],
              [-40.025339611504187, -8.16687650760465],
              [-40.025340147769853, -8.166876261886815],
              [-40.025340666873738, -8.166875981960185],
              [-40.025341166620734, -8.166875669008469],
              [-40.025341644897551, -8.166875324355029],
              [-40.025342099681737, -8.166874949457325],
              [-40.025342529050135, -8.166874545900628],
              [-40.025342931187105, -8.166874115391479],
              [-40.025342931187105, -8.166874115391479],
              [-40.025996388602032, -8.166127265279366],
              [-40.025996788697405, -8.166126836815293],
              [-40.025997215766672, -8.16612643503167],
              [-40.025997668021539, -8.166126061610889],
              [-40.025998143568238, -8.166125718116604],
              [-40.02599864041548, -8.166125405987152],
              [-40.025999156482776, -8.16612512652955],
              [-40.025999156482783, -8.16612512652955],
              [-40.026936485623033, -8.165656033936861],
              [-40.026936982108133, -8.165655765879805],
              [-40.026937460903937, -8.165655467580386],
              [-40.026937460903937, -8.165655467580386],
              [-40.027780783761109, -8.165092964058934],
              [-40.027781260651984, -8.165092666865004],
              [-40.027781755101863, -8.165092399673995],
              [-40.027782265211961, -8.165092163511993],
              [-40.027782789023306, -8.165091959285913],
              [-40.027783324524364, -8.165091787780053],
              [-40.027783324524357, -8.165091787780039],
              [-40.029977854704555, -8.164462984666487],
              [-40.029978458844212, -8.164462833715476],
              [-40.02997907195104, -8.164462724402329],
              [-40.029979691138116, -8.164462657241781],
              [-40.029980313489887, -8.164462632550061],
              [-40.029980936075887, -8.164462650443454],
              [-40.029981555964561, -8.164462710837688],
              [-40.029982170237034, -8.164462813448388],
              [-40.029982776000892, -8.164462957792393],
              [-40.02998337040377, -8.164463143190044],
              [-40.029983950646837, -8.164463368768363],
              [-40.029984513997881, -8.164463633465127],
              [-40.029985057804268, -8.16446393603403],
              [-40.029985579505386, -8.164464275050307],
              [-40.029986076644697, -8.164464648917686],
              [-40.029986546881325, -8.1644650558757],
              [-40.029986988001077, -8.16446549400812],
              [-40.029986988001077, -8.16446549400812],
              [-40.030371832506297, -8.164874905761282],
              [-40.030372277910224, -8.164875347862258],
              [-40.030372752959629, -8.164875758181813],
              [-40.030373255375437, -8.164876134751369],
              [-40.030373782747326, -8.164876475764368],
              [-40.030374332545236, -8.164876779584823],
              [-40.030374902131484, -8.164877044755134],
              [-40.030375488773501, -8.164877270003128],
              [-40.030376089656869, -8.164877454248218],
              [-40.030376701898831, -8.164877596606452],
              [-40.030376701898831, -8.164877596606452],
              [-40.030838376718947, -8.164968271105788],
              [-40.030838998394472, -8.164968371021255],
              [-40.030839625526539, -8.164968427747912],
              [-40.030840255096727, -8.164968441012677],
              [-40.030840884074863, -8.164968410751749],
              [-40.030841509433628, -8.164968337110746],
              [-40.030842128163144, -8.164968220444131],
              [-40.030842737285404, -8.164968061313415],
              [-40.030843333868695, -8.164967860484502],
              [-40.030843915041594, -8.164967618923988],
              [-40.030844478006884, -8.164967337794526],
              [-40.03084502005499, -8.164967018449234],
              [-40.030845538576983, -8.16496666242511],
              [-40.030846031077203, -8.164966271435731],
              [-40.030846495185195, -8.164965847362945],
              [-40.030846928667188, -8.164965392247874],
              [-40.030847329436789, -8.164964908280995],
              [-40.030847695565086, -8.164964397791671],
              [-40.030847695565093, -8.164964397791671],
              [-40.031216995892215, -8.164409775526536],
              [-40.031217348234648, -8.164409282958715],
              [-40.031217732833035, -8.164408814959684],
              [-40.031218147967046, -8.164408373622852],
              [-40.031218591779762, -8.164407960922336],
              [-40.031219062285956, -8.164407578704163],
              [-40.031219557380993, -8.164407228678078],
              [-40.031220074850296, -8.164406912409749],
              [-40.03122061237918, -8.164406631313895],
              [-40.03122116756321, -8.164406386647851],
              [-40.031221737919005, -8.164406179506049],
              [-40.031222320895317, -8.16440601081505],
              [-40.031222913884442, -8.164405881329442],
              [-40.031222913884442, -8.164405881329442],
              [-40.032251902915277, -8.164216878406288],
              [-40.032252423596724, -8.16421679826084],
              [-40.032252948067892, -8.164216748351901],
              [-40.032253474561728, -8.164216728847638],
              [-40.032254001304359, -8.164216739813734],
              [-40.032254526521093, -8.164216781213243],
              [-40.032254526521093, -8.164216781213243],
              [-40.033092822244392, -8.164307316758919],
              [-40.033093422351627, -8.164307401854073],
              [-40.03309401542063, -8.164307526668804],
              [-40.033094598806478, -8.164307690646506],
              [-40.033095169907419, -8.164307893055859],
              [-40.033095726176526, -8.164308132994199],
              [-40.033096265132976, -8.164308409391452],
              [-40.033096784373186, -8.164308721014972],
              [-40.03309728158149, -8.164309066474987],
              [-40.033097754540492, -8.164309444230886],
              [-40.033098201140916, -8.164309852597947],
              [-40.033098619391062, -8.164310289754981],
              [-40.033099007425648, -8.164310753752416],
              [-40.033099363514147, -8.164311242520915],
              [-40.033099686068503, -8.164311753880739],
              [-40.033099973650238, -8.164312285551338],
              [-40.033100224976799, -8.164312835161644],
              [-40.033100438927335, -8.164313400260562],
              [-40.033100614547699, -8.164313978327856],
              [-40.033100751054668, -8.164314566785563],
              [-40.033100847839464, -8.164315163009297],
              [-40.033100847839464, -8.164315163009297],
              [-40.033195318225246, -8.165052589158032],
              [-40.033195415576493, -8.165053188250965],
              [-40.033195553032698, -8.165053779489574],
              [-40.033195729974928, -8.165054360211505],
              [-40.033195945606387, -8.165054927801831],
              [-40.033196198956126, -8.165055479704682],
              [-40.033196488883306, -8.165056013434853],
              [-40.033196814082388, -8.165056526589007],
              [-40.033197173089022, -8.165057016856405],
              [-40.033197564286603, -8.165057482029395],
              [-40.033197985913603, -8.165057920013325],
              [-40.03319843607143, -8.165058328835965],
              [-40.033198912733049, -8.165058706656403],
              [-40.033199413752065, -8.165059051773321],
              [-40.033199936872428, -8.165059362632695],
              [-40.033199936872414, -8.165059362632695],
              [-40.034040084291924, -8.165521259432269],
              [-40.03404062507186, -8.165521581628667],
              [-40.034041142115541, -8.165521940430505],
              [-40.034041632931483, -8.165522334108777],
              [-40.034042095154547, -8.165522760766459],
              [-40.0340425265574, -8.165523218347573],
              [-40.034042925061222, -8.165523704647184],
              [-40.034043288745721, -8.165524217321916],
              [-40.034043615858373, -8.165524753901297],
              [-40.03404390482293, -8.165525311799723],
              [-40.034044154246921, -8.165525888328775],
              [-40.034044362928448, -8.165526480710337],
              [-40.034044529861923, -8.165527086089861],
              [-40.034044529861923, -8.165527086089861],
              [-40.034351219638168, -8.166812356664002],
              [-40.034351378900169, -8.166812937456356],
              [-40.034351576592158, -8.166813506403388],
              [-40.034351811837979, -8.166814060983638],
              [-40.034352083595095, -8.166814598739306],
              [-40.034352390659102, -8.166815117287154],
              [-40.034352731669145, -8.166815614329005],
              [-40.034353105113929, -8.166816087662122],
              [-40.034353509338402, -8.166816535188749],
              [-40.034353942551114, -8.166816954925508],
              [-40.034354402832143, -8.166817345012204],
              [-40.0343548881416, -8.166817703720021],
              [-40.034355396328664, -8.166818029459282],
              [-40.034355925141128, -8.166818320786279],
              [-40.034356472235409, -8.166818576410002],
              [-40.034357035186858, -8.166818795197457],
              [-40.034357035186858, -8.166818795197475],
              [-40.034859261729025, -8.166995026644353],
              [-40.03854431637118, -8.162073003728487],
              [-40.028993287234776, -8.160740783738152],
              [-40.030804477504013, -8.152035747183071],
              [-40.024783115400645, -8.150603949618468],
              [-40.021296820124299, -8.164540708944239],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0154000',
        uf: 'PE',
        nome_proje: 'PA DIVINA GRAÇA',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '201.9594',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 5.0,
        data_de_cr: '16/11/1998',
        forma_obte: 'Desapropriação',
        data_obten: '08/01/1998',
        area_calc_: 201.7599,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.354967760832608, -8.169479162824214],
              [-35.354707067805634, -8.169742695265583],
              [-35.354726833057128, -8.169759680268976],
              [-35.354738840473026, -8.169768549036784],
              [-35.354754580884105, -8.169775154619577],
              [-35.35477150466852, -8.169779376290949],
              [-35.354791998230517, -8.169781344765171],
              [-35.354812922255881, -8.169780924673464],
              [-35.354824577821574, -8.169779293214825],
              [-35.35483517293337, -8.169776257987124],
              [-35.35484770241829, -8.169770518142018],
              [-35.35487214629142, -8.169757785736742],
              [-35.354882649171472, -8.169751397910217],
              [-35.354888093266361, -8.169748573437149],
              [-35.354890447423131, -8.169747845719291],
              [-35.35489328629496, -8.169747793036429],
              [-35.354895974253871, -8.169748301594451],
              [-35.354899046886715, -8.169749440579462],
              [-35.354901859282933, -8.16975106009906],
              [-35.354904902630736, -8.169753392283669],
              [-35.354906243211595, -8.169754613659045],
              [-35.354908004430584, -8.169756411023402],
              [-35.354909684756713, -8.169758335392176],
              [-35.354911074188969, -8.169760152997611],
              [-35.354912529394518, -8.169762358858323],
              [-35.354913885725125, -8.169764718942448],
              [-35.354914904642875, -8.169766773695937],
              [-35.354915834074298, -8.169769036846777],
              [-35.354916774749661, -8.1697716704942],
              [-35.354917709429017, -8.169774828386281],
              [-35.354920792192189, -8.169790066746751],
              [-35.354923238344227, -8.169798837535133],
              [-35.354924496642333, -8.169803041962229],
              [-35.354928051505205, -8.169816804363284],
              [-35.354930257588329, -8.16982330798659],
              [-35.354932685876626, -8.169829033039436],
              [-35.354934831287274, -8.169832925005361],
              [-35.354937210624094, -8.16983649023754],
              [-35.354939803254496, -8.16983930406543],
              [-35.354942316800226, -8.169841006667635],
              [-35.354946664506905, -8.16984302396088],
              [-35.354951723592634, -8.169844151377932],
              [-35.354956624079023, -8.169844529556242],
              [-35.354962109168625, -8.169844054748987],
              [-35.354968387166139, -8.169842671514532],
              [-35.354983605298401, -8.169837557713773],
              [-35.355000048278953, -8.169830945493691],
              [-35.355014737778788, -8.169823855426747],
              [-35.35503693644808, -8.169810322657607],
              [-35.355047815088099, -8.16980459242016],
              [-35.355058560897767, -8.169800959794832],
              [-35.355085295445647, -8.169796782155606],
              [-35.355174805674004, -8.169787322212004],
              [-35.355212901221726, -8.169782328240435],
              [-35.355231870622745, -8.169779614795507],
              [-35.355238151480073, -8.169778719597979],
              [-35.355240238343669, -8.169778815901704],
              [-35.355242002643564, -8.169779591941243],
              [-35.355243400034311, -8.169781219698704],
              [-35.35524452732308, -8.169783192476874],
              [-35.355245856651123, -8.169787134383574],
              [-35.355247594193081, -8.169795719494795],
              [-35.355248479064073, -8.169799663989901],
              [-35.355249395657857, -8.169802831023894],
              [-35.355250983207611, -8.169805948959851],
              [-35.355252142590395, -8.169807207541396],
              [-35.355253135681238, -8.169807951919854],
              [-35.355254454357741, -8.169808531716399],
              [-35.355256761671662, -8.169809096717165],
              [-35.355259857805734, -8.169809602895365],
              [-35.355263433247721, -8.169809862253491],
              [-35.355268404653792, -8.169809959834023],
              [-35.355274751444007, -8.169809480004288],
              [-35.355282518228861, -8.169808295971102],
              [-35.35528897678612, -8.169806767070998],
              [-35.355292932983737, -8.169805442544442],
              [-35.355295021495458, -8.169804273504687],
              [-35.355298552123706, -8.169801532475367],
              [-35.355304911289167, -8.16979543087699],
              [-35.355307255212246, -8.16979295886375],
              [-35.355310342231462, -8.169790365027511],
              [-35.355312031108497, -8.169789107934195],
              [-35.355314173937344, -8.169787920501843],
              [-35.355316057272347, -8.169787340133256],
              [-35.355317626106995, -8.169787267729706],
              [-35.355318697227588, -8.169787397062969],
              [-35.355320060514558, -8.169787850066125],
              [-35.355321388738737, -8.169788511149715],
              [-35.355322745234957, -8.169789352830577],
              [-35.355324403604328, -8.169790626581946],
              [-35.355327049228173, -8.169793196069444],
              [-35.355330247303151, -8.169797163244391],
              [-35.355334583434583, -8.169803392353453],
              [-35.355338498609349, -8.169808991247356],
              [-35.355342829280104, -8.169814289464115],
              [-35.355347116511176, -8.169818367790771],
              [-35.355363920613001, -8.169828473932787],
              [-35.355371116364175, -8.169832002071811],
              [-35.35537703486807, -8.169834281354165],
              [-35.355382356310159, -8.169835289742249],
              [-35.355450486449286, -8.169839737353858],
              [-35.355485482922013, -8.169841467673125],
              [-35.355496405296563, -8.169843193599215],
              [-35.355505093342003, -8.169845971919774],
              [-35.355513188881652, -8.169849802110241],
              [-35.355521442067349, -8.169854218858497],
              [-35.355565105265406, -8.169881377080685],
              [-35.355598739202279, -8.169904427152954],
              [-35.355616176488113, -8.169920413393184],
              [-35.355633636523606, -8.169940276842356],
              [-35.355648409552487, -8.169961339932259],
              [-35.355663243829703, -8.169992841663447],
              [-35.355679938721153, -8.17003534999219],
              [-35.355691135886573, -8.170057626936874],
              [-35.355713189000454, -8.170095042717525],
              [-35.355739707225133, -8.170129748171458],
              [-35.355760419319438, -8.170144224038877],
              [-35.355773608700417, -8.170148919319644],
              [-35.355796024121346, -8.170147595712709],
              [-35.35582855375916, -8.170135484967576],
              [-35.355867013372468, -8.170116787057392],
              [-35.355893269412029, -8.170106809693056],
              [-35.355916570882634, -8.170101901833652],
              [-35.355934508943527, -8.170101200817655],
              [-35.355950689537075, -8.17010707169468],
              [-35.3559624171723, -8.170117722549662],
              [-35.355971172579309, -8.170131978842566],
              [-35.355973040200332, -8.17014417842495],
              [-35.355973255259258, -8.170180826636752],
              [-35.35597604329898, -8.170198389483641],
              [-35.355989193868119, -8.170247480091563],
              [-35.356003168598839, -8.170285530385941],
              [-35.356018570444611, -8.170310177912086],
              [-35.356046810903393, -8.170333846828189],
              [-35.356080411646317, -8.170351230151521],
              [-35.356099366244237, -8.17037226885081],
              [-35.356109061603732, -8.170393668791537],
              [-35.35611100619365, -8.170418982193906],
              [-35.356108154897505, -8.170441648263841],
              [-35.356101088124362, -8.170461645543622],
              [-35.355918054193779, -8.170762749489022],
              [-35.355888950045426, -8.170797489757623],
              [-35.355830094336838, -8.170857122542969],
              [-35.355801595780292, -8.17089304327115],
              [-35.355734551511034, -8.170984013636279],
              [-35.355714957287496, -8.171008557756059],
              [-35.355661626728647, -8.171066820705416],
              [-35.355610204588672, -8.171121050582183],
              [-35.355567443732269, -8.171171949171036],
              [-35.355557394508089, -8.17119227112147],
              [-35.35555514179206, -8.171214924671849],
              [-35.355555848850244, -8.171233394418259],
              [-35.3555625893746, -8.171261364205883],
              [-35.355575389886113, -8.171301807412434],
              [-35.355603294066405, -8.171370180719892],
              [-35.355626297668373, -8.171416520585108],
              [-35.355645600969659, -8.17144441717927],
              [-35.355673878587226, -8.171472858001328],
              [-35.355697919241052, -8.171493424211489],
              [-35.355726578367538, -8.17151113458816],
              [-35.355734992475796, -8.171518234697741],
              [-35.355740042721408, -8.171525580358061],
              [-35.355742037504307, -8.171533169772495],
              [-35.355741492627232, -8.171542337573742],
              [-35.355738562237029, -8.171553073825654],
              [-35.355732805905518, -8.17156606798936],
              [-35.355718015840004, -8.171593123830593],
              [-35.355691412875082, -8.171646016100981],
              [-35.355679109214186, -8.171663965133693],
              [-35.355668112994763, -8.171675941407072],
              [-35.355652630703361, -8.171687058083604],
              [-35.355633679733415, -8.171694471273909],
              [-35.355598884502903, -8.171700783744164],
              [-35.355587089438572, -8.171703383128524],
              [-35.355560232034428, -8.171712921140312],
              [-35.355535174159954, -8.171723054219205],
              [-35.35550595621671, -8.171738417475208],
              [-35.355483629534646, -8.171756424897129],
              [-35.355474355446759, -8.171768174220487],
              [-35.355469844486109, -8.171776985528485],
              [-35.355467570835529, -8.171783705040633],
              [-35.355422048009501, -8.171963710841993],
              [-35.35540611211686, -8.17202428661386],
              [-35.355404351564715, -8.172030334318064],
              [-35.355400293282628, -8.172038998379692],
              [-35.355394884806266, -8.172047896259611],
              [-35.355388134304526, -8.172056874262566],
              [-35.355373272925029, -8.17207178331727],
              [-35.355364418786287, -8.172079354594249],
              [-35.355360620057233, -8.172081238572108],
              [-35.355353293660002, -8.172081733158077],
              [-35.355345361973512, -8.172081101508166],
              [-35.355340583991989, -8.172081508942963],
              [-35.355336256091675, -8.172082871793855],
              [-35.355327762497446, -8.172088506817399],
              [-35.355318535189639, -8.172095863353327],
              [-35.355313414958651, -8.172101325077248],
              [-35.355309262363626, -8.172107829583201],
              [-35.355307223481098, -8.172117457994856],
              [-35.355306342075373, -8.172132656168692],
              [-35.355306713955216, -8.172145018117407],
              [-35.355308917666179, -8.172163479147365],
              [-35.355313547891427, -8.172187357936835],
              [-35.355317536763174, -8.172205510309675],
              [-35.355324553037505, -8.172229456541197],
              [-35.355327306035782, -8.172239499903567],
              [-35.355329010768173, -8.172250218191859],
              [-35.355329569161675, -8.172255719139073],
              [-35.355329482302388, -8.172279562141449],
              [-35.355327452516818, -8.17231547328244],
              [-35.355326233876852, -8.172362858079365],
              [-35.355327569302077, -8.172386385500491],
              [-35.355331703628487, -8.172403045739157],
              [-35.35533519384316, -8.172411964082263],
              [-35.355343032963965, -8.17242309853683],
              [-35.355362748745058, -8.172443988208379],
              [-35.355369077585635, -8.172452808670348],
              [-35.355379154494308, -8.17246811472455],
              [-35.354965668402969, -8.17297259885043],
              [-35.352907761057018, -8.175483356923509],
              [-35.352288536601485, -8.176468681421545],
              [-35.352925128651485, -8.178445866591872],
              [-35.353445876267983, -8.180125285651124],
              [-35.353446152105057, -8.180125916711278],
              [-35.354024552176057, -8.181953493698019],
              [-35.355350527586879, -8.182793544178626],
              [-35.355652269373181, -8.18298474719678],
              [-35.355710470402933, -8.183549740129473],
              [-35.355656351506994, -8.184234962290164],
              [-35.355833885893588, -8.184684393709867],
              [-35.35651028265103, -8.185355952332477],
              [-35.357429461375389, -8.185738770797744],
              [-35.359087758139204, -8.186445719845461],
              [-35.359102678247368, -8.186206566734246],
              [-35.360388912951905, -8.186379184509169],
              [-35.36067542680096, -8.186417637828757],
              [-35.360647757113853, -8.186469560615008],
              [-35.360622197353052, -8.186496227767133],
              [-35.360572740422356, -8.186526902973323],
              [-35.360502881336828, -8.186548171384391],
              [-35.360479014036727, -8.186557249610161],
              [-35.360446214218037, -8.186572932667522],
              [-35.360409286840593, -8.186599368037401],
              [-35.360375419568733, -8.186637110196637],
              [-35.360342823337675, -8.186687353594998],
              [-35.360319176906799, -8.186735465899726],
              [-35.360305725543768, -8.186786492103924],
              [-35.360299595905886, -8.186811554336522],
              [-35.360290161282883, -8.186834557143605],
              [-35.360276821130832, -8.186855187705079],
              [-35.360233771102905, -8.186907878285496],
              [-35.3602183815334, -8.186934784206848],
              [-35.360207758329082, -8.186959285244708],
              [-35.360201598816609, -8.186979277301299],
              [-35.36020052694672, -8.187000586276211],
              [-35.360200869554781, -8.187034106435835],
              [-35.360199445689211, -8.187046478828254],
              [-35.36019769779913, -8.187053122985796],
              [-35.360181826967832, -8.187093805725294],
              [-35.36016259804002, -8.187141892198147],
              [-35.360152629359817, -8.187163551458335],
              [-35.360148358417277, -8.187174602867978],
              [-35.360145721377229, -8.187184298051598],
              [-35.360144791625579, -8.187191235680775],
              [-35.360144917274802, -8.187200245891717],
              [-35.360148066620852, -8.187214381076037],
              [-35.360155793545843, -8.187231020147028],
              [-35.360173772061096, -8.187263244146502],
              [-35.36020874677029, -8.187336482879207],
              [-35.360220597154886, -8.187367847945207],
              [-35.360225547805541, -8.187396722705905],
              [-35.360225702268856, -8.187422941212688],
              [-35.360223189877495, -8.18745379376459],
              [-35.360216941404161, -8.187483321495838],
              [-35.36020901486765, -8.187508249630909],
              [-35.36020000974721, -8.187528710234627],
              [-35.360188910433514, -8.187547845453215],
              [-35.36017660060557, -8.187564755350591],
              [-35.360148987488508, -8.187598583613704],
              [-35.360127322290225, -8.187627315777576],
              [-35.360113690991973, -8.187647803436398],
              [-35.360108895690217, -8.187659156169714],
              [-35.360107141762718, -8.187666315532573],
              [-35.360106892897363, -8.187674885068876],
              [-35.360108065931293, -8.187683066690999],
              [-35.360111416607268, -8.187692880504539],
              [-35.36011572044238, -8.187701269768235],
              [-35.360121535802037, -8.187710626302421],
              [-35.360132010951737, -8.187724122129914],
              [-35.360146296458296, -8.187739231561245],
              [-35.360159532971373, -8.187751816470058],
              [-35.360215981882426, -8.187803925147398],
              [-35.360297430564906, -8.187872870105492],
              [-35.360465668463377, -8.188004176117657],
              [-35.360569604123192, -8.188082235392402],
              [-35.360596675595914, -8.188108892946017],
              [-35.36062014619489, -8.188134080282063],
              [-35.360643578467133, -8.18815122396985],
              [-35.360662455368981, -8.188158859155187],
              [-35.360687919809884, -8.188165262778652],
              [-35.360889947101121, -8.18819477409342],
              [-35.36101507095858, -8.18821548933072],
              [-35.361055193466747, -8.188224789723005],
              [-35.361080979823306, -8.188236560039014],
              [-35.36111402408617, -8.188260796551791],
              [-35.361145569609548, -8.188286234853262],
              [-35.361218538914962, -8.188337053643039],
              [-35.361249790383347, -8.188363388420619],
              [-35.361272676695506, -8.18838947388895],
              [-35.361290486522599, -8.188417676851362],
              [-35.36132440321429, -8.188489882214029],
              [-35.361341999752902, -8.188532682873769],
              [-35.361356573330987, -8.188568948634421],
              [-35.361366243000951, -8.188587365825292],
              [-35.361373187568773, -8.1885974568414],
              [-35.361382057214243, -8.188606343568784],
              [-35.361398130331139, -8.188618459914988],
              [-35.361423956253908, -8.188636936201531],
              [-35.361444425009793, -8.188659158476367],
              [-35.361466416567836, -8.188687336951284],
              [-35.361478211804339, -8.188709320765785],
              [-35.361486115555266, -8.188731318316135],
              [-35.361504701530336, -8.188789613427073],
              [-35.36152020623711, -8.188833022823315],
              [-35.361529603183513, -8.188854415120979],
              [-35.361541718818927, -8.188879967085926],
              [-35.361558003000496, -8.188903262254277],
              [-35.361588722049348, -8.18893928889233],
              [-35.361609790088558, -8.188963143531202],
              [-35.36162400169944, -8.188976472818139],
              [-35.361641340828193, -8.188987912922691],
              [-35.361716834415397, -8.189022195238623],
              [-35.362282172130413, -8.189163494543701],
              [-35.363126445779159, -8.18931924705436],
              [-35.363832520655706, -8.190388206052152],
              [-35.363907548593616, -8.191065799810303],
              [-35.364304528198986, -8.191192084493885],
              [-35.364252602769817, -8.191385441208121],
              [-35.364732735437315, -8.191552541756829],
              [-35.364713820261848, -8.192343308499369],
              [-35.365399017136284, -8.19291826652197],
              [-35.365203734027986, -8.187669104454182],
              [-35.365202449694529, -8.187635942418918],
              [-35.365090847987787, -8.184634786802771],
              [-35.36296453546548, -8.184184038519202],
              [-35.362929128860735, -8.184179094065072],
              [-35.362924822670969, -8.183990287423352],
              [-35.362938259285919, -8.183835269601499],
              [-35.362946571388889, -8.183621896010671],
              [-35.362937489381125, -8.183501571458587],
              [-35.362926075990728, -8.183391997744174],
              [-35.36290869896429, -8.183284835932987],
              [-35.362845383387338, -8.183095705783318],
              [-35.362769123938961, -8.182943598875546],
              [-35.362628389071993, -8.182607140573216],
              [-35.362588930318012, -8.182407151604513],
              [-35.362590116914902, -8.182202152732248],
              [-35.3626429910559, -8.181837149182275],
              [-35.362641804114297, -8.181635743265616],
              [-35.362624504651791, -8.181541695143665],
              [-35.362590608037408, -8.181472770464881],
              [-35.362542218947581, -8.181381281214458],
              [-35.362533639458832, -8.181346173400646],
              [-35.362535226544338, -8.181310707857682],
              [-35.362546879848701, -8.181259393960657],
              [-35.362560321545679, -8.181154437831433],
              [-35.362573640913709, -8.181081061354865],
              [-35.362596377477118, -8.180980217394334],
              [-35.362618097269326, -8.180911563867559],
              [-35.362638730613611, -8.180860197442087],
              [-35.362677223075281, -8.180796208849609],
              [-35.36269682059757, -8.180772260878424],
              [-35.362733071390586, -8.180732715237241],
              [-35.362757917913704, -8.18068668372641],
              [-35.362769643479901, -8.180646097572351],
              [-35.362775877388366, -8.180486358833912],
              [-35.362777849814442, -8.18046548748557],
              [-35.362781926086726, -8.180447586385162],
              [-35.362790195083917, -8.180428467735828],
              [-35.362814793222903, -8.180391087090722],
              [-35.362847108744781, -8.180344117122933],
              [-35.362926627224049, -8.180238177949624],
              [-35.362939106795501, -8.180223951364436],
              [-35.362951595722912, -8.180212851887918],
              [-35.36296158112885, -8.180206385498067],
              [-35.362971869100939, -8.180202005126338],
              [-35.362985303483917, -8.180198953015921],
              [-35.363079711090627, -8.180182900455309],
              [-35.36313616825506, -8.180173486918781],
              [-35.363167670630979, -8.180165557007612],
              [-35.363199454762146, -8.180154642891557],
              [-35.363326566707293, -8.180106820013268],
              [-35.363380257249325, -8.180083567315801],
              [-35.363408864271051, -8.180066119195692],
              [-35.363436212481297, -8.180038248539486],
              [-35.363460218845162, -8.180002055323911],
              [-35.363471823478108, -8.179967127695397],
              [-35.363483478092554, -8.17988989270167],
              [-35.36348832873265, -8.179798688555431],
              [-35.363492336470081, -8.179769164937548],
              [-35.36349998016069, -8.179747076429543],
              [-35.363510642598719, -8.179729281404187],
              [-35.363525950776314, -8.179713257765895],
              [-35.363550366613275, -8.179695752825664],
              [-35.363562886576922, -8.179688385868682],
              [-35.363586134053683, -8.179675804457895],
              [-35.363595894903241, -8.179669718970434],
              [-35.363609886642223, -8.179659659102425],
              [-35.363624610581496, -8.179646088189848],
              [-35.363644048521344, -8.179621246370647],
              [-35.363659406040057, -8.179588972044044],
              [-35.363676606687328, -8.17953852047096],
              [-35.363685062281697, -8.179501821738997],
              [-35.363693396738256, -8.179444571211986],
              [-35.363696074303391, -8.179417143167598],
              [-35.363694176966838, -8.179373798789401],
              [-35.363692672652228, -8.179347886491483],
              [-35.363694183902247, -8.179324188944383],
              [-35.363698399420549, -8.179303756382398],
              [-35.3637247110901, -8.179227773264072],
              [-35.363727343192011, -8.179217255659774],
              [-35.363729123064488, -8.179202196907903],
              [-35.363729066984114, -8.179180370368565],
              [-35.363705964752782, -8.178875923579799],
              [-35.363705417680102, -8.178870829325847],
              [-35.363704260206958, -8.178865286741349],
              [-35.363702208744591, -8.178858890773745],
              [-35.363698264305917, -8.178849902926283],
              [-35.363692601988994, -8.178840328619362],
              [-35.363675258200026, -8.17881570201803],
              [-35.363663270646292, -8.178797967215864],
              [-35.363628696853027, -8.178741853468228],
              [-35.363608913948831, -8.178708157900843],
              [-35.363596417526495, -8.178681035547143],
              [-35.363591077952066, -8.178663117236907],
              [-35.363586474703432, -8.178630073975125],
              [-35.363588655997987, -8.178593854819127],
              [-35.36359666776471, -8.178558803611093],
              [-35.363611106516295, -8.17852147336737],
              [-35.363619681536889, -8.178505037229451],
              [-35.363638330850968, -8.178472599045991],
              [-35.363645643573129, -8.178458999200201],
              [-35.363653324390413, -8.178441673527873],
              [-35.363675415742776, -8.178385309587702],
              [-35.36368437052424, -8.178356353541304],
              [-35.36369063715253, -8.178329943873965],
              [-35.363727662870659, -8.178041757193403],
              [-35.36374289068533, -8.177884351965124],
              [-35.363739767456636, -8.177862326456015],
              [-35.363733818207862, -8.177842540838226],
              [-35.363724908460412, -8.177826803506482],
              [-35.363707813015615, -8.177805826809502],
              [-35.363698997778677, -8.177793812596642],
              [-35.363683734893506, -8.17776671546101],
              [-35.363674629673113, -8.177743974788687],
              [-35.363664394551435, -8.177708054223675],
              [-35.363653979487822, -8.177664687364413],
              [-35.36364082174812, -8.17758998358296],
              [-35.363632894511049, -8.177513152375726],
              [-35.363622882522172, -8.177336526268281],
              [-35.363625614255206, -8.177292115427315],
              [-35.363630819391425, -8.177262593848408],
              [-35.363646952296442, -8.177207972969413],
              [-35.36368536976191, -8.177080519725676],
              [-35.363706737613754, -8.177001438399261],
              [-35.363711354573589, -8.176973700756488],
              [-35.363716806652583, -8.176933747834807],
              [-35.363723287172064, -8.176866665735368],
              [-35.363728039055054, -8.176835655532818],
              [-35.363738196424634, -8.176784495030187],
              [-35.363756255802286, -8.17670274876264],
              [-35.36377497002281, -8.176630533798765],
              [-35.363851225571672, -8.176402012766756],
              [-35.363893896523876, -8.176259043456048],
              [-35.363985761347337, -8.175939399987872],
              [-35.36399764330433, -8.175900747089283],
              [-35.364000247425992, -8.175887021149377],
              [-35.364000613195365, -8.175872124320007],
              [-35.363998556199675, -8.175852469578023],
              [-35.363988625325177, -8.175817369694981],
              [-35.363945676790408, -8.175683839854246],
              [-35.363899257469754, -8.175570891844153],
              [-35.363872048267808, -8.175520700071351],
              [-35.363809292737486, -8.175426312308685],
              [-35.363613441734245, -8.175124448365638],
              [-35.363587207797096, -8.175087356012741],
              [-35.363558049025869, -8.17506190368454],
              [-35.363515496400346, -8.175046354032721],
              [-35.363209899925224, -8.174948333893111],
              [-35.363138647591519, -8.174934443280895],
              [-35.363011149612134, -8.174914934469825],
              [-35.362916874004995, -8.174902371443929],
              [-35.362879168649421, -8.174896635807844],
              [-35.362835427219672, -8.174884075577621],
              [-35.362766210599474, -8.174860050377896],
              [-35.362723901242823, -8.174834973102783],
              [-35.362537146807867, -8.174706748381933],
              [-35.362254295010096, -8.174560023941529],
              [-35.362043623122844, -8.174433737267563],
              [-35.361883935867979, -8.17433157334769],
              [-35.361811247838723, -8.174277173249299],
              [-35.361739046306148, -8.174203709032211],
              [-35.361580041031694, -8.174013944122505],
              [-35.361505285691472, -8.173911889309849],
              [-35.361390849652977, -8.173776688736789],
              [-35.361163256450304, -8.173517017170068],
              [-35.36107532930459, -8.173413240582422],
              [-35.361029448888836, -8.173340815443755],
              [-35.360986151974089, -8.173249594117987],
              [-35.360953409922693, -8.173174706179466],
              [-35.360924925064324, -8.173111109023377],
              [-35.360906359846098, -8.173056094316903],
              [-35.360897597235976, -8.173040654255662],
              [-35.360884971659743, -8.17302850853174],
              [-35.360854659680747, -8.17301051001302],
              [-35.360818272046245, -8.172975862711544],
              [-35.360796913694593, -8.172953347096779],
              [-35.36077729137552, -8.172922171917726],
              [-35.360745421076203, -8.172842805011859],
              [-35.360721497766313, -8.172740453093422],
              [-35.360702834150629, -8.172617807122267],
              [-35.360691873246687, -8.172381290816853],
              [-35.360696837095283, -8.172335375591942],
              [-35.360715406189577, -8.172213705233187],
              [-35.360719724976342, -8.172159903547492],
              [-35.360723855685499, -8.172098800206156],
              [-35.360725635565423, -8.17204515795431],
              [-35.36072074782544, -8.171977545638036],
              [-35.360717173120925, -8.171929601515409],
              [-35.360718619800302, -8.171894877872417],
              [-35.360720414622548, -8.171869994646205],
              [-35.360735230870169, -8.17174566189963],
              [-35.360738594998978, -8.171706905123886],
              [-35.36074038892248, -8.171655648838508],
              [-35.360740188831826, -8.171570728376663],
              [-35.360737862825637, -8.171506833821008],
              [-35.360735306440574, -8.171477800415344],
              [-35.360730037357435, -8.171447137920422],
              [-35.360727598384663, -8.17143806890803],
              [-35.36072516171577, -8.171430934027446],
              [-35.360721001163178, -8.171423727869142],
              [-35.360717350280829, -8.171418353461684],
              [-35.360713282099908, -8.171412972452476],
              [-35.360704925405734, -8.171403305325784],
              [-35.360695100008165, -8.171392227797909],
              [-35.36068817020886, -8.171383031357133],
              [-35.360671884892781, -8.171359365370551],
              [-35.360651601094133, -8.171329829910151],
              [-35.360636973763071, -8.171308910850316],
              [-35.360573951184172, -8.171219747731065],
              [-35.360542898216885, -8.171175976914776],
              [-35.360521336749805, -8.171145120305889],
              [-35.360484850345216, -8.171093635580309],
              [-35.360455143949281, -8.171052098330659],
              [-35.360449043939951, -8.171043638159743],
              [-35.36044317129587, -8.171036821589082],
              [-35.360436185153389, -8.171030382078966],
              [-35.360424395307355, -8.17102151225679],
              [-35.360400467796993, -8.171007868884448],
              [-35.360380809732327, -8.170996812588605],
              [-35.360370759074833, -8.170990616917132],
              [-35.360357842729627, -8.17098145541188],
              [-35.360325968927029, -8.170950803590191],
              [-35.360189534803688, -8.170810515753843],
              [-35.36009079993498, -8.170699661713027],
              [-35.36004802544651, -8.170646279473738],
              [-35.360021376942925, -8.170591013575443],
              [-35.360014012580351, -8.170558808732473],
              [-35.360011408320894, -8.170535505718632],
              [-35.360010669524279, -8.17017990728421],
              [-35.36000885404907, -8.170151908909206],
              [-35.360006673262433, -8.17013583387649],
              [-35.360001780692357, -8.170118283388094],
              [-35.359995411924857, -8.170102675660665],
              [-35.359977558677926, -8.170066871606517],
              [-35.359947926943413, -8.170011776744936],
              [-35.359933835306869, -8.169980126322457],
              [-35.359917397326143, -8.169930467683837],
              [-35.359896518299074, -8.169838220468373],
              [-35.359887144785226, -8.169769748534621],
              [-35.35988618538773, -8.169708376613107],
              [-35.359890120299916, -8.169664853430632],
              [-35.359896384717523, -8.169611781493407],
              [-35.359895771929587, -8.169558451429824],
              [-35.359892836650531, -8.169515863104877],
              [-35.359886034199832, -8.169428007585573],
              [-35.359887373077513, -8.169350227196876],
              [-35.359899855012245, -8.169234557473816],
              [-35.35993206851272, -8.1690174558778],
              [-35.359958840390291, -8.168891562066568],
              [-35.359987539272154, -8.168789499436521],
              [-35.360013365975632, -8.168706514851372],
              [-35.360015856144706, -8.168696522294972],
              [-35.360015331708958, -8.16868445953474],
              [-35.360012563354751, -8.168671813354948],
              [-35.360004977614203, -8.168652859598165],
              [-35.359993630768408, -8.168629833541486],
              [-35.35995753436714, -8.168567473608903],
              [-35.359931892637988, -8.168530675595241],
              [-35.359922824177616, -8.168512552950167],
              [-35.359914859621178, -8.168481616921191],
              [-35.359905323428407, -8.168436382799021],
              [-35.359871773751095, -8.168324253423348],
              [-35.359831925830967, -8.168108168762373],
              [-35.359823574659565, -8.168009892330028],
              [-35.359825965678397, -8.167907386678053],
              [-35.359836448693386, -8.167859053195121],
              [-35.359873507701359, -8.167753362734478],
              [-35.359917510318638, -8.167608298092734],
              [-35.359934506431969, -8.167549207466331],
              [-35.359940564343475, -8.16751192624506],
              [-35.359940035701243, -8.167472902949282],
              [-35.359917086215788, -8.167384843357693],
              [-35.359861117944646, -8.167222484612758],
              [-35.359801622306975, -8.167072664148465],
              [-35.359768694256601, -8.166964399385929],
              [-35.359739678167969, -8.166912129754996],
              [-35.359657755255149, -8.166812501928682],
              [-35.35943982140293, -8.166567060754705],
              [-35.359329239659857, -8.166475942094941],
              [-35.359148418952117, -8.166339337628143],
              [-35.359079401829277, -8.166298029353046],
              [-35.358749861654616, -8.166142118796971],
              [-35.354967760832608, -8.169479162824214],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0161000',
        uf: 'PE',
        nome_proje: 'PA MOURA',
        municipio: 'TACARATU',
        area_hecta: '675.7761',
        capacidade: 16.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '22/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '22/12/2005',
        area_calc_: 674.8718,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.236911117603441, -8.890594123218518],
              [-38.248316522069842, -8.875334819645801],
              [-38.218022635205607, -8.870037086533447],
              [-38.214537192572223, -8.869427419885461],
              [-38.213579225958703, -8.892094027315183],
              [-38.21661547915852, -8.891906962256268],
              [-38.217916899199089, -8.891826758375581],
              [-38.217916285041269, -8.891773304851391],
              [-38.236911117603441, -8.890594123218518],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0378000',
        uf: 'PE',
        nome_proje: 'PA MILHO BRANCO',
        municipio: 'SAO BENTO DO UNA',
        area_hecta: '655.2300',
        capacidade: 65.0,
        num_famili: 61.0,
        fase: 4.0,
        data_de_cr: '07/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 657.5181,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.579144510510048, -8.497176324113205],
              [-36.576690252535904, -8.497264353324713],
              [-36.573926652444435, -8.496726214558906],
              [-36.570457420084104, -8.496637249476652],
              [-36.566562660221976, -8.49493013855809],
              [-36.561158138607937, -8.506024047441214],
              [-36.556075603354437, -8.503685907766831],
              [-36.555615526419061, -8.506320051467895],
              [-36.555064893158921, -8.50964643281138],
              [-36.553269982204128, -8.515914610889791],
              [-36.552726508489407, -8.517278186910097],
              [-36.550776867120184, -8.517060205642407],
              [-36.550585272456566, -8.51760883295243],
              [-36.548526536179324, -8.526846098860984],
              [-36.550825759118837, -8.527760075031084],
              [-36.564664725278249, -8.532720805695286],
              [-36.565904376385689, -8.53316514468685],
              [-36.566384755302799, -8.527685832389849],
              [-36.567441344845115, -8.508658971651178],
              [-36.569609644872187, -8.509321916547979],
              [-36.571217772512519, -8.50893305026316],
              [-36.572184800401978, -8.509104477723353],
              [-36.572913907710614, -8.509143011656812],
              [-36.573490996319265, -8.509347790144172],
              [-36.573790191869968, -8.509409486230979],
              [-36.574897167598344, -8.509775365598991],
              [-36.575815061580165, -8.510095236856865],
              [-36.575814082188231, -8.510048974842293],
              [-36.579144510510048, -8.497176324113205],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0064000',
        uf: 'PE',
        nome_proje: 'PA SERRA DOS QUILOMBOS',
        municipio: 'BONITO',
        area_hecta: '893.3000',
        capacidade: 70.0,
        num_famili: 67.0,
        fase: 6.0,
        data_de_cr: '02/08/1995',
        forma_obte: 'Desapropriação',
        data_obten: '21/02/1994',
        area_calc_: 823.9785,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.720408218216711, -8.579655352278003],
              [-35.719492718548118, -8.575358997888861],
              [-35.719057176883737, -8.571468473007645],
              [-35.712712897880458, -8.574387608551429],
              [-35.701777787020546, -8.579906699503567],
              [-35.697376358719659, -8.582395133491309],
              [-35.692994864470784, -8.584874901236486],
              [-35.693407382990628, -8.586689191826936],
              [-35.694364010449007, -8.591516699206585],
              [-35.69490827825463, -8.597363871452043],
              [-35.694985406568875, -8.599374136816088],
              [-35.694884068777881, -8.607263557160271],
              [-35.70341019635444, -8.610877759954093],
              [-35.705922263971317, -8.612825144049491],
              [-35.708279111282891, -8.612860128701779],
              [-35.713414580002812, -8.612953368588],
              [-35.71643052315968, -8.613202848781155],
              [-35.720105950539022, -8.612513271577781],
              [-35.720126198048874, -8.608207161265691],
              [-35.72006166308627, -8.604014955525063],
              [-35.715569631937193, -8.602760312936626],
              [-35.716017065729815, -8.599569591311404],
              [-35.715933825213533, -8.59917595776448],
              [-35.715108429366332, -8.598796236158364],
              [-35.714954761800279, -8.598583900573407],
              [-35.714848492335371, -8.598219794437702],
              [-35.715557513047671, -8.596817171360373],
              [-35.716114792857788, -8.59644151598277],
              [-35.712811565327748, -8.594291125367878],
              [-35.710782889473364, -8.592895954335177],
              [-35.707339329246139, -8.590804584674054],
              [-35.705843131948413, -8.587758166123098],
              [-35.707162048028877, -8.586571649018968],
              [-35.710562343811603, -8.582998740644523],
              [-35.711846826068765, -8.582771496152199],
              [-35.713178128701799, -8.582324263347076],
              [-35.714166634285327, -8.580368963922128],
              [-35.71584113503954, -8.580070925929766],
              [-35.720408218216711, -8.579655352278003],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0178000',
        uf: 'PE',
        nome_proje: 'PA POÇO DA VOLTA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '538.5240',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '04/07/2006',
        forma_obte: 'Confisco',
        data_obten: '04/07/2006',
        area_calc_: 538.963,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.041621781802995, -8.54812477697671],
              [-39.065522691847548, -8.541405035167946],
              [-39.065911183638292, -8.539443577590339],
              [-39.066849048547454, -8.535328057709902],
              [-39.035405408348915, -8.544126358937925],
              [-39.032227069628519, -8.545326953389575],
              [-39.028787231027998, -8.54712290572779],
              [-39.011544047157464, -8.550765096561866],
              [-39.008364450732671, -8.562755227225956],
              [-39.035403224357502, -8.55394046355195],
              [-39.038717428318392, -8.551352636539999],
              [-39.039883454242698, -8.550135829529529],
              [-39.041621781802995, -8.54812477697671],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0141000',
        uf: 'PE',
        nome_proje: 'PA BARRA DO JUÁ',
        municipio: 'FLORESTA',
        area_hecta: '1481.1375',
        capacidade: 100.0,
        num_famili: 99.0,
        fase: 3.0,
        data_de_cr: '06/07/2005',
        forma_obte: 'Doação',
        data_obten: '06/07/2005',
        area_calc_: 1482.2356,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.098051292043834, -8.395376573381354],
              [-38.096386655268184, -8.39416633655544],
              [-38.091498984267332, -8.402562696702004],
              [-38.088871478189645, -8.400662747065113],
              [-38.094330903699372, -8.390385340474218],
              [-38.091385875833382, -8.381787725532707],
              [-38.090360733817732, -8.382046403423031],
              [-38.090477746575274, -8.382340231731531],
              [-38.090661650976969, -8.383086178924582],
              [-38.090722056797283, -8.383668229744226],
              [-38.090723777608538, -8.384256566791786],
              [-38.090796016560752, -8.385569878189267],
              [-38.090976877350528, -8.386805477833457],
              [-38.091067657974541, -8.3871749666325],
              [-38.091193504401275, -8.387501756583815],
              [-38.091352994014102, -8.387772730118103],
              [-38.091623937751955, -8.387958943701562],
              [-38.09204157631401, -8.388059664246381],
              [-38.092315076135357, -8.388162223304281],
              [-38.092471296576356, -8.388276903056148],
              [-38.092527861725294, -8.388440825910989],
              [-38.092617493390954, -8.388797378489375],
              [-38.092662550984407, -8.389106345804702],
              [-38.09279020204761, -8.389439018381532],
              [-38.092870416763702, -8.389705106031771],
              [-38.092895004550869, -8.390068292187264],
              [-38.092776179616109, -8.390315652381252],
              [-38.092484023400367, -8.390541721221659],
              [-38.092290918010164, -8.39070525026405],
              [-38.091785227655457, -8.392186359380919],
              [-38.091577643981218, -8.392717234836034],
              [-38.091458408384014, -8.393101434025466],
              [-38.09082475865646, -8.393832924433077],
              [-38.090064194066059, -8.394734153651795],
              [-38.08937571101567, -8.395491923893006],
              [-38.088934012717324, -8.396046856757089],
              [-38.088582496774087, -8.396331210949636],
              [-38.088063248147456, -8.396646017681148],
              [-38.087338717362186, -8.397007830776646],
              [-38.086732436040123, -8.397370640067487],
              [-38.086433484088936, -8.39778028705356],
              [-38.086166502556537, -8.398500137072235],
              [-38.086210405018825, -8.398796078084388],
              [-38.086285492235312, -8.398884253338634],
              [-38.086519933538696, -8.398990796330855],
              [-38.086967832354759, -8.399127770689283],
              [-38.087247395752094, -8.39916432450212],
              [-38.087427027322711, -8.399250390294851],
              [-38.087414457758157, -8.399382317599771],
              [-38.087362824279758, -8.399518224328521],
              [-38.08727106696886, -8.399645265091053],
              [-38.087073685470529, -8.399770161712274],
              [-38.086903800126173, -8.399905161145007],
              [-38.086878148474533, -8.400038414724106],
              [-38.086937285581051, -8.400228301336252],
              [-38.08716797093885, -8.400426725470501],
              [-38.08728932217803, -8.400578227504941],
              [-38.087343370497308, -8.400847059072099],
              [-38.08723077778312, -8.401028407769553],
              [-38.08701168222877, -8.401194767303339],
              [-38.086876591426922, -8.401286977314479],
              [-38.086842035943008, -8.40146027649115],
              [-38.086910076854167, -8.401610117495936],
              [-38.087042743107837, -8.401622634907561],
              [-38.087236459270692, -8.401589710495349],
              [-38.087527262200481, -8.401481310128704],
              [-38.087822424335016, -8.401411900587149],
              [-38.088239744871814, -8.401381935518952],
              [-38.088626175845008, -8.401433659059249],
              [-38.089136844976423, -8.401645753898766],
              [-38.089919274356475, -8.401970264001768],
              [-38.090457206214161, -8.402215522462892],
              [-38.090942082919021, -8.40258375087136],
              [-38.091401797072301, -8.402836967470343],
              [-38.092217725086563, -8.402975056938606],
              [-38.092611820226153, -8.402973794754107],
              [-38.092876731289302, -8.402868224903964],
              [-38.093000799539659, -8.402672520359094],
              [-38.093180346131732, -8.402249026703792],
              [-38.093422606498308, -8.401923627287855],
              [-38.093715316829467, -8.401579983818651],
              [-38.094073254741289, -8.401229616448381],
              [-38.094261471543057, -8.400983048962177],
              [-38.094407190321952, -8.400615370602347],
              [-38.094468390993839, -8.400191151706141],
              [-38.094510484441471, -8.399585640615863],
              [-38.094589672446872, -8.399081149406902],
              [-38.094730227384488, -8.398628894487693],
              [-38.095007459755671, -8.398260703935943],
              [-38.095417175305428, -8.397905117639301],
              [-38.095781679848798, -8.397619249273802],
              [-38.096264525505447, -8.397334465979444],
              [-38.09669122750276, -8.397016270165562],
              [-38.096971121793672, -8.396673950779276],
              [-38.09747261673273, -8.396060627947026],
              [-38.097774403334853, -8.395676754042647],
              [-38.097925962551294, -8.395491330422221],
              [-38.098051292043834, -8.395376573381354],
            ],
          ],
          [
            [
              [-38.098676655439789, -8.395831221775877],
              [-38.098636624416436, -8.396136822802225],
              [-38.098487378009203, -8.397484810669914],
              [-38.098364924285789, -8.39820283138396],
              [-38.098268418152763, -8.398539421441185],
              [-38.097695260250546, -8.399349204612289],
              [-38.09730732173584, -8.400421244574527],
              [-38.09727177209777, -8.400712300052826],
              [-38.097233557230396, -8.400977573070735],
              [-38.097172914480389, -8.401161667028729],
              [-38.09679941393351, -8.401487491690677],
              [-38.096686915720561, -8.401668934185084],
              [-38.096587317887057, -8.401848959139615],
              [-38.096443175446026, -8.402360265972689],
              [-38.096297790946124, -8.402858636463854],
              [-38.096157069136119, -8.403147460533985],
              [-38.095905392182317, -8.403382306455534],
              [-38.095805415942017, -8.40356848038595],
              [-38.095755722717236, -8.403847926316352],
              [-38.095772651194913, -8.404264184998045],
              [-38.095720868159596, -8.404779140293934],
              [-38.095632443337685, -8.405193167164233],
              [-38.095547591362994, -8.405515492761937],
              [-38.095186670538631, -8.405975833225765],
              [-38.094797416638322, -8.406277108934097],
              [-38.094692910254736, -8.406536169227207],
              [-38.094438017851488, -8.407372453711265],
              [-38.094258426709054, -8.408049188554141],
              [-38.094253287106497, -8.40807214929325],
              [-38.093905292044788, -8.409613494695257],
              [-38.093836521545661, -8.410831696213091],
              [-38.093889374912862, -8.410874326252488],
              [-38.094020712397864, -8.410873905222783],
              [-38.094097419520423, -8.410852918183412],
              [-38.094293381516302, -8.410715262700803],
              [-38.094416815318482, -8.410637313530335],
              [-38.094496005484586, -8.410642198872171],
              [-38.094644513329477, -8.410679170185546],
              [-38.094792748517399, -8.410716231261409],
              [-38.094973386803439, -8.410684630009722],
              [-38.095142457105034, -8.410667201644602],
              [-38.095358405433714, -8.410723320894398],
              [-38.095535561063294, -8.410783511225132],
              [-38.0957854868834, -8.410914595355358],
              [-38.096178681961042, -8.411030994989783],
              [-38.09650673877529, -8.411153937440913],
              [-38.096725109838125, -8.411105418731369],
              [-38.096982546140843, -8.411052829189707],
              [-38.097211970284754, -8.410859355049226],
              [-38.097419681313305, -8.410707344382239],
              [-38.097512952695425, -8.410593238108474],
              [-38.097556498941408, -8.410379711040754],
              [-38.09754583887851, -8.410276309943878],
              [-38.097608948234949, -8.410126047576695],
              [-38.097688410677939, -8.410131023336909],
              [-38.09783940209347, -8.41019386592578],
              [-38.097960322472446, -8.410220552646363],
              [-38.098107740705991, -8.410375367751964],
              [-38.098179542461949, -8.410433144725742],
              [-38.098440975750918, -8.410419363490352],
              [-38.098978653080934, -8.410467897222702],
              [-38.099602604372372, -8.410403754470243],
              [-38.100055092957405, -8.410331078591117],
              [-38.100394049377918, -8.41017864288081],
              [-38.100604333295337, -8.41005250303718],
              [-38.100946436057079, -8.409677583378324],
              [-38.101112414831142, -8.409503768764615],
              [-38.101369242131412, -8.409320665028629],
              [-38.101669649269731, -8.409302719737108],
              [-38.101944073276002, -8.409287609092358],
              [-38.102545557596223, -8.409513190728005],
              [-38.102903596267481, -8.409672373674614],
              [-38.103313356414155, -8.409695466459832],
              [-38.103553529229345, -8.409605479951951],
              [-38.103712394313469, -8.409325286461161],
              [-38.103851200074757, -8.40876232108053],
              [-38.10400181819093, -8.408315692729872],
              [-38.104058352098434, -8.408100747004971],
              [-38.104193260347984, -8.408008530534168],
              [-38.104454692214226, -8.407994745107121],
              [-38.104651167517517, -8.407987594965983],
              [-38.104999785759631, -8.40805631108112],
              [-38.105388924237452, -8.408080530404014],
              [-38.10572473350954, -8.408150392540897],
              [-38.105938107922931, -8.408180633320411],
              [-38.106304893910163, -8.408169256680642],
              [-38.106677826258476, -8.408091598576977],
              [-38.106986615778197, -8.407903091705675],
              [-38.107169672042417, -8.40776666608626],
              [-38.107526181796352, -8.407403349264236],
              [-38.107664750841778, -8.407219158742517],
              [-38.108033683006461, -8.407102961218982],
              [-38.108457246416052, -8.407007056002065],
              [-38.108718768788734, -8.406993177417759],
              [-38.109045879514511, -8.40685499683384],
              [-38.10927778848496, -8.40676317892402],
              [-38.111408571079664, -8.406531033541357],
              [-38.111750525283099, -8.406534970995432],
              [-38.11211222646557, -8.406602263237161],
              [-38.112264490549499, -8.406625398518447],
              [-38.112908273774494, -8.406624498629244],
              [-38.113235903730583, -8.406616916171258],
              [-38.113663985787447, -8.406690416310555],
              [-38.113989379670052, -8.406787652398453],
              [-38.114513484862911, -8.406772913903357],
              [-38.114907155935896, -8.40664093769678],
              [-38.115316302339551, -8.406533596540733],
              [-38.115729380864146, -8.406334282522302],
              [-38.116130188167517, -8.406018720292563],
              [-38.116327292807142, -8.405893807884102],
              [-38.11707941822646, -8.405617923977504],
              [-38.117640390608912, -8.405325149298955],
              [-38.118337418219532, -8.40495299753289],
              [-38.11888431478841, -8.404779122932252],
              [-38.119079024093836, -8.404628336699238],
              [-38.119319031363005, -8.404407829084645],
              [-38.119504385279846, -8.404297269165649],
              [-38.119684526448815, -8.404234548745512],
              [-38.119433908211988, -8.403168553647717],
              [-38.096354317768295, -8.409081894625221],
              [-38.099709957998371, -8.396582449064928],
              [-38.098676655439789, -8.395831221775877],
            ],
          ],
          [
            [
              [-38.120112595014483, -8.406055047833119],
              [-38.120112593573367, -8.406055047920983],
              [-38.120111685104433, -8.406055136333817],
              [-38.119901614487944, -8.406090030172505],
              [-38.11928885871766, -8.406388209319488],
              [-38.11901129991687, -8.40662581702923],
              [-38.11855834678741, -8.406946873079651],
              [-38.118232480871058, -8.407097998153994],
              [-38.117567325784556, -8.40727097320239],
              [-38.117115273067562, -8.407474271664571],
              [-38.116811729652738, -8.407714532608491],
              [-38.116618533108046, -8.407878073412641],
              [-38.116118672123541, -8.407935106239201],
              [-38.115888736764333, -8.407997900275754],
              [-38.115593662905489, -8.408067421491294],
              [-38.114838287039134, -8.408132108643485],
              [-38.114233565781291, -8.408129029407558],
              [-38.113851642323162, -8.408246650670767],
              [-38.113474238402986, -8.408533682665164],
              [-38.113081242592351, -8.408926950357857],
              [-38.112858152956896, -8.409054425135004],
              [-38.112414659326923, -8.4090868890296],
              [-38.111766963866998, -8.409049069004537],
              [-38.111354966207223, -8.409130713668695],
              [-38.110944730745878, -8.409355714939108],
              [-38.110414249114314, -8.409815325792563],
              [-38.110222381990141, -8.409991799495035],
              [-38.109787832686209, -8.410363082056431],
              [-38.109430671648532, -8.410653140074267],
              [-38.108890042481988, -8.411139949005921],
              [-38.108643788905241, -8.41142654922003],
              [-38.108515881787923, -8.411714050598594],
              [-38.108380924078538, -8.412185226690333],
              [-38.108192377538543, -8.412537167087287],
              [-38.107930465162646, -8.412799311440375],
              [-38.107411479202362, -8.413113872061402],
              [-38.106773872259836, -8.413427710269165],
              [-38.106275172827807, -8.413688049210194],
              [-38.106056076578049, -8.413854419350169],
              [-38.105722695149367, -8.414189116012663],
              [-38.105369676294018, -8.414492022006181],
              [-38.104924791379823, -8.414890498074506],
              [-38.104715838769728, -8.415029486069608],
              [-38.104301168545014, -8.415085249624656],
              [-38.103951973489487, -8.415264888510308],
              [-38.103412536443493, -8.415385666830783],
              [-38.103030176950419, -8.415372856155484],
              [-38.102535798523242, -8.415292951782131],
              [-38.102161009842717, -8.415226886425259],
              [-38.10188942811719, -8.415268050962156],
              [-38.101647737124104, -8.415345099164895],
              [-38.101511918432102, -8.415555069538412],
              [-38.101450386365308, -8.415848688831602],
              [-38.101668375706971, -8.416557991372537],
              [-38.101711976505733, -8.416872019796646],
              [-38.101701594583233, -8.417277996159177],
              [-38.101596942109303, -8.417915925048469],
              [-38.101504034707069, -8.418187760585818],
              [-38.106482995244953, -8.416420003970609],
              [-38.120575102878135, -8.408022330095765],
              [-38.120112595014483, -8.406055047833119],
            ],
          ],
          [
            [
              [-38.101016042844506, -8.418361015401],
              [-38.100817747574361, -8.41825728509477],
              [-38.100610271939374, -8.418030430802032],
              [-38.100453096394126, -8.417654640998387],
              [-38.10028717048263, -8.417449678502498],
              [-38.099903959794027, -8.417175481557422],
              [-38.099591310408158, -8.416946396232904],
              [-38.099419486849669, -8.416621130379571],
              [-38.099301102480631, -8.415731892225812],
              [-38.099191291078704, -8.415181383757838],
              [-38.09901703785826, -8.414768110945005],
              [-38.098778161030786, -8.414492163738815],
              [-38.098496736866132, -8.414312079173634],
              [-38.098147024855066, -8.414361201906445],
              [-38.097927834588816, -8.414527476455852],
              [-38.097553472195962, -8.414592007871988],
              [-38.097049103102023, -8.414669987752362],
              [-38.096676163374077, -8.4147476358366],
              [-38.096417391905796, -8.414787378907441],
              [-38.096087091777775, -8.414768892831185],
              [-38.095608372404342, -8.414713706956718],
              [-38.095297644471763, -8.414715434075804],
              [-38.094750726446563, -8.414889273239059],
              [-38.094413610705423, -8.415185150487671],
              [-38.09436630597714, -8.415490648955748],
              [-38.094454187528129, -8.415703573692019],
              [-38.094543068690172, -8.415799015054603],
              [-38.094951708108169, -8.416033118493212],
              [-38.095128198817918, -8.416341574210952],
              [-38.09524239657857, -8.416682536490031],
              [-38.095314045504665, -8.417119451475196],
              [-38.095360884917987, -8.417444702481237],
              [-38.09527345520771, -8.417741064402346],
              [-38.095092327954738, -8.418020930802729],
              [-38.094388699561215, -8.418870018386235],
              [-38.093869937015299, -8.419315249907477],
              [-38.093448263592272, -8.419685184605729],
              [-38.093237153206609, -8.419929074805953],
              [-38.093056354977278, -8.420339631024527],
              [-38.09284380392976, -8.42096790077759],
              [-38.092623425119164, -8.421251925852463],
              [-38.092419553695436, -8.421312051825929],
              [-38.092078076527841, -8.421059740660549],
              [-38.09170335089658, -8.420614889529979],
              [-38.091367873967791, -8.42016596850082],
              [-38.090932609916244, -8.419897250315893],
              [-38.090473117308882, -8.419774631589501],
              [-38.090124401699413, -8.419705992030059],
              [-38.089867231598198, -8.419758576984409],
              [-38.089631617108488, -8.420148564175927],
              [-38.089427165731955, -8.420456953346553],
              [-38.089314897580117, -8.420768992878722],
              [-38.089311163255587, -8.421239649648159],
              [-38.089092386590622, -8.421536609517135],
              [-38.08847298341437, -8.421900743458139],
              [-38.087911169482425, -8.422263110705122],
              [-38.087767257343479, -8.422395456381578],
              [-38.08745104404543, -8.42314657425892],
              [-38.087439337318507, -8.423180915385021],
              [-38.101016042844506, -8.418361015401],
            ],
          ],
          [
            [
              [-38.08917136661114, -8.382346534572738],
              [-38.087607060545309, -8.382741232744896],
              [-38.087199900064363, -8.390335794306202],
              [-38.073565178216256, -8.375840834232651],
              [-38.074124576776711, -8.365864670109817],
              [-38.070309184349803, -8.365639378666323],
              [-38.067507283902977, -8.377676673345274],
              [-38.078034193723333, -8.393416659308365],
              [-38.078860570195793, -8.401014706564199],
              [-38.072329178013149, -8.405221420083519],
              [-38.079972962384737, -8.412084165154319],
              [-38.074865024361124, -8.412998859243512],
              [-38.072029236882067, -8.419854510888733],
              [-38.075653051584929, -8.425285994482277],
              [-38.070469880280747, -8.429711051140318],
              [-38.067129418740166, -8.419154641997316],
              [-38.063700486213648, -8.420388532681338],
              [-38.063348200917233, -8.431433960108576],
              [-38.052426172804928, -8.420466418380457],
              [-38.056997873788148, -8.394865973578685],
              [-38.053247714390558, -8.393814635219448],
              [-38.043323746429515, -8.41278691799867],
              [-38.043098202327215, -8.420379869874319],
              [-38.025636990525413, -8.419850112007106],
              [-38.024507143009068, -8.42374686994439],
              [-38.045821811425284, -8.432412825333756],
              [-38.051507080545761, -8.441852475116704],
              [-38.047907142616381, -8.443195491593901],
              [-38.04573735300054, -8.444247931371748],
              [-38.043999340674816, -8.452804369681173],
              [-38.038469391844934, -8.451353256443163],
              [-38.033603888568997, -8.454924659090695],
              [-38.022071891960913, -8.44641208695697],
              [-38.005837643051038, -8.452756976333125],
              [-38.00277357279186, -8.459586225440365],
              [-38.005504135310225, -8.461138447373054],
              [-38.01185465481565, -8.4558938694021],
              [-38.020256589365083, -8.455439546398747],
              [-38.021403245472079, -8.459458725741957],
              [-38.017094508892193, -8.46954945872354],
              [-38.020905236000985, -8.47097922294688],
              [-38.023915725287502, -8.466989981073858],
              [-38.042263138708641, -8.463713813312008],
              [-38.053576370490561, -8.455487991711442],
              [-38.047684700990182, -8.464361957877486],
              [-38.05205234967066, -8.465911351167167],
              [-38.063044443863816, -8.455357927455271],
              [-38.066253846568031, -8.453984182540497],
              [-38.073353000076395, -8.45178855949905],
              [-38.08279482972484, -8.436823796743358],
              [-38.09202799828681, -8.430615928374824],
              [-38.092963381844669, -8.425277880378337],
              [-38.087420116855625, -8.423715227412211],
              [-38.088431100170752, -8.424970914049624],
              [-38.09004392121107, -8.425345742022586],
              [-38.09122317051461, -8.425348472754196],
              [-38.092072009173343, -8.425766202907925],
              [-38.091786790163063, -8.426082003573665],
              [-38.091627642863479, -8.426320495523781],
              [-38.091479484803401, -8.426792988950423],
              [-38.091328331037346, -8.427108918452658],
              [-38.090836971857442, -8.42771510583434],
              [-38.090416492171428, -8.428346238040731],
              [-38.090173299824265, -8.428789297387802],
              [-38.089826079624487, -8.429243058917647],
              [-38.089553906976604, -8.429532387911825],
              [-38.088964562130876, -8.429932677669022],
              [-38.088518964473849, -8.430349399112442],
              [-38.088301422722964, -8.430659294019028],
              [-38.088079884340722, -8.430930289015029],
              [-38.08791930964194, -8.431155842305415],
              [-38.087843109722506, -8.431307337991738],
              [-38.087901247920698, -8.431614888637151],
              [-38.087981373735978, -8.431881067520868],
              [-38.087155941266914, -8.432440165576942],
              [-38.086723184886893, -8.432066533834279],
              [-38.086346961299185, -8.431987696256421],
              [-38.085711644191257, -8.432196586624562],
              [-38.084779166976681, -8.432579970687309],
              [-38.083944622205728, -8.433149079228203],
              [-38.083324372934136, -8.433630956962189],
              [-38.082236382375328, -8.434361904314891],
              [-38.081293987650213, -8.4349028072217],
              [-38.080557952441126, -8.43540964221895],
              [-38.079935213749273, -8.435865550825438],
              [-38.078970733586928, -8.436754328602932],
              [-38.078256456129026, -8.437467871141676],
              [-38.077642852570484, -8.438014329995523],
              [-38.077251599917936, -8.438334480879616],
              [-38.07667140855559, -8.438694704724726],
              [-38.075935035608062, -8.439070748758917],
              [-38.075480861809496, -8.439378720430224],
              [-38.075306277588155, -8.439723166292175],
              [-38.075678751673443, -8.440425426933265],
              [-38.075742367212058, -8.440717616051645],
              [-38.075767836737761, -8.441084420807785],
              [-38.075785222725081, -8.441450935173339],
              [-38.075858638169365, -8.443160563602225],
              [-38.075030032896827, -8.444997034670026],
              [-38.074863475709527, -8.446317464400604],
              [-38.073382572672941, -8.449331307162668],
              [-38.073122608698377, -8.449860413618946],
              [-38.07253914919999, -8.449602446891706],
              [-38.071569033483378, -8.449559268143009],
              [-38.070382186408224, -8.450008122002801],
              [-38.069206939014997, -8.450884068481162],
              [-38.068894876712022, -8.451203673419457],
              [-38.068736559305357, -8.451502299373724],
              [-38.068389110169235, -8.452032188558977],
              [-38.06814386209733, -8.452292711961645],
              [-38.067722983100211, -8.452571357636227],
              [-38.067348633833099, -8.452727473849581],
              [-38.066841452634954, -8.452744353651777],
              [-38.066375027267064, -8.452698110822366],
              [-38.066002580135802, -8.452441089798734],
              [-38.065667670643656, -8.451722269272855],
              [-38.06529235880879, -8.451184327134708],
              [-38.065250454763962, -8.451007593311971],
              [-38.06506996093249, -8.450178891939128],
              [-38.064968562282459, -8.450001021185344],
              [-38.064865301456017, -8.449917477266462],
              [-38.064731671584902, -8.449884056258794],
              [-38.064493798274498, -8.449873358526277],
              [-38.064279719494451, -8.449977579187349],
              [-38.064181513033859, -8.450058290333619],
              [-38.064047786930381, -8.450366197952413],
              [-38.063973907880118, -8.450679765545212],
              [-38.063962965689718, -8.451375149878544],
              [-38.06396251713408, -8.451598812495162],
              [-38.063885168202212, -8.451995035938133],
              [-38.063780344660785, -8.452257972212458],
              [-38.063566615845154, -8.452480039383348],
              [-38.063096454499366, -8.452740111601694],
              [-38.062792337811842, -8.452888975063008],
              [-38.062611374395587, -8.453084077832003],
              [-38.062427292529293, -8.453479502511369],
              [-38.062331655779509, -8.453924701269711],
              [-38.062228254874107, -8.45419966915547],
              [-38.061855757909683, -8.454602691304057],
              [-38.061492233764653, -8.454863468983342],
              [-38.060957343902423, -8.454993417529108],
              [-38.06044438482504, -8.455069604877171],
              [-38.060078111908389, -8.455189646521758],
              [-38.059583441709478, -8.455440429920408],
              [-38.059350618107914, -8.455593712411998],
              [-38.05881305301665, -8.456002032202024],
              [-38.058314741307541, -8.456217984824388],
              [-38.057835929551295, -8.456446102940458],
              [-38.057618464790657, -8.456633246570078],
              [-38.057463685390154, -8.456966695436362],
              [-38.057389617636304, -8.457280260524872],
              [-38.05737485427921, -8.457823148646046],
              [-38.057348981270479, -8.458265167085653],
              [-38.057312303779412, -8.458480783009994],
              [-38.057142162468864, -8.458780640591804],
              [-38.05691661454334, -8.459003850525541],
              [-38.056535417208117, -8.45920778253843],
              [-38.056164515379699, -8.459398896209297],
              [-38.055443226658554, -8.459861213084318],
              [-38.054901942286882, -8.460263367298289],
              [-38.054509189134897, -8.460586109907821],
              [-38.054384336451875, -8.460751949966628],
              [-38.05429508699158, -8.461031652061006],
              [-38.054214980508249, -8.461287047340178],
              [-38.05406783777336, -8.461466942292654],
              [-38.053833946894272, -8.461608551237617],
              [-38.053513307204696, -8.461712145341709],
              [-38.053160001714389, -8.461763746402852],
              [-38.052987111418737, -8.461922686443701],
              [-38.052897330345559, -8.462084812271936],
              [-38.05292181785461, -8.462435334048521],
              [-38.053072402823261, -8.462631595957664],
              [-38.053303958817423, -8.462925640720812],
              [-38.053373830197181, -8.463142056552172],
              [-38.053486658869204, -8.463659928902491],
              [-38.053594793512119, -8.464016984113584],
              [-38.05249216503028, -8.465142304857332],
              [-38.052048731967453, -8.464975992449464],
              [-38.052047869540424, -8.464622995645231],
              [-38.051969568449294, -8.464325071055146],
              [-38.051770250250271, -8.463886758473604],
              [-38.051688840379597, -8.463671490203639],
              [-38.051686362647452, -8.463421864575478],
              [-38.051712657016033, -8.463219067185362],
              [-38.051722711349946, -8.46297091901401],
              [-38.051685608343107, -8.462727721218602],
              [-38.051519673972713, -8.462270948968277],
              [-38.051393427587001, -8.461966215330611],
              [-38.05139388406316, -8.461742553641585],
              [-38.05166922629104, -8.460958095312559],
              [-38.051852155367193, -8.460668672523216],
              [-38.051985182005424, -8.460466676434049],
              [-38.052286912599733, -8.460294482717069],
              [-38.052621228588698, -8.460095415969361],
              [-38.053050737176186, -8.459898478647453],
              [-38.053292876324505, -8.459838287795376],
              [-38.053378213938444, -8.459747147962011],
              [-38.053424310067562, -8.459506955249521],
              [-38.053353377302898, -8.459278960317077],
              [-38.053227049001343, -8.458970247531852],
              [-38.053231143314463, -8.45878150509032],
              [-38.053256102007502, -8.458567127626093],
              [-38.053584213960683, -8.458192225635434],
              [-38.053896874452199, -8.457783551073657],
              [-38.054017413834607, -8.457672598916051],
              [-38.054074593681861, -8.457654920096333],
              [-38.054301643580963, -8.457561047545392],
              [-38.054590755895696, -8.457495994931422],
              [-38.054796941376914, -8.457427757179552],
              [-38.054872771327084, -8.457361103726896],
              [-38.054943900169071, -8.457247951713059],
              [-38.055003321988188, -8.456794709472652],
              [-38.055216258082119, -8.455297232616983],
              [-38.055395673780573, -8.454172657383156],
              [-38.055528933538461, -8.45361187660983],
              [-38.055678027490842, -8.45333774474901],
              [-38.055802967763753, -8.453171904111169],
              [-38.056047330205331, -8.453017385343228],
              [-38.056242413489258, -8.452844297419505],
              [-38.05628272983607, -8.45266369138921],
              [-38.056256633573625, -8.452525071250191],
              [-38.056160302858466, -8.45239387891821],
              [-38.05588452121038, -8.452245702949631],
              [-38.055630938007674, -8.452083471397781],
              [-38.055384283919089, -8.451873503026675],
              [-38.054765415514915, -8.451725221944395],
              [-38.054196797355374, -8.451607088074077],
              [-38.053747237907501, -8.451723847718165],
              [-38.053622311988015, -8.451771932228112],
              [-38.053471624821789, -8.451916908225243],
              [-38.053300059038882, -8.452205184206353],
              [-38.053135694894749, -8.452445633693396],
              [-38.052657161644014, -8.452895600497316],
              [-38.052114970202865, -8.453374896515182],
              [-38.051802767767889, -8.453559908119701],
              [-38.051580344580756, -8.453582703277595],
              [-38.051363516576053, -8.453546181961915],
              [-38.051136667174056, -8.453298816116469],
              [-38.050907422562616, -8.453028110345526],
              [-38.050863829768439, -8.452950315043603],
              [-38.050806427722449, -8.45216776282842],
              [-38.050733540818456, -8.452034093831925],
              [-38.05060790316891, -8.451964510670107],
              [-38.050420552245555, -8.451983772634469],
              [-38.050124514031253, -8.452096649514671],
              [-38.049919922098972, -8.452294130377224],
              [-38.049785031956667, -8.452590361961171],
              [-38.049738130572869, -8.452936459670774],
              [-38.049715036931538, -8.453056419996349],
              [-38.049682526728994, -8.453201048092728],
              [-38.049321921499235, -8.453602910156667],
              [-38.048586429963088, -8.454339671334008],
              [-38.04844292628303, -8.454554574076663],
              [-38.048412993110595, -8.454722270928638],
              [-38.048487560774916, -8.45498527623791],
              [-38.048805489049066, -8.455541000301958],
              [-38.049036324311061, -8.455940862112049],
              [-38.049058065520271, -8.456150468730931],
              [-38.049021020490756, -8.456366082532133],
              [-38.048906826792866, -8.456519013490055],
              [-38.048772391515733, -8.456591673277265],
              [-38.048570922197555, -8.45658874152616],
              [-38.048111426542228, -8.456494830672085],
              [-38.047937752061735, -8.456418437008724],
              [-38.047808565475727, -8.456314114567988],
              [-38.047647945605114, -8.456248334249855],
              [-38.047464140774942, -8.456302332842505],
              [-38.047368325037894, -8.456406288561119],
              [-38.047364665971287, -8.456712606074058],
              [-38.047339415793815, -8.456933493943689],
              [-38.047255667709422, -8.457153877599637],
              [-38.047052496250217, -8.457363026917212],
              [-38.04653933004527, -8.457780522190353],
              [-38.046301085774083, -8.457993293669448],
              [-38.0459221896351, -8.458220463995232],
              [-38.045458504645246, -8.45831511073931],
              [-38.044948923175717, -8.458308621437528],
              [-38.044511972216604, -8.458318136173302],
              [-38.044075599736736, -8.45838779557692],
              [-38.043760994823728, -8.458549460031358],
              [-38.043435014212122, -8.458830027227064],
              [-38.043350456732462, -8.459156495875222],
              [-38.043271405295563, -8.45942346731508],
              [-38.043187835974351, -8.459643850200177],
              [-38.043152728249304, -8.459993140576216],
              [-38.043087538179414, -8.460164458538062],
              [-38.04291554232519, -8.460217488743943],
              [-38.042709190238774, -8.460168054802629],
              [-38.042220308631002, -8.46001817186386],
              [-38.041858127041252, -8.459949453579169],
              [-38.041583844219424, -8.459930512384847],
              [-38.041294728935704, -8.459995554933624],
              [-38.04107504181674, -8.460159348861724],
              [-38.040911818992065, -8.460411371256612],
              [-38.040890127720495, -8.460660756084044],
              [-38.040937610782045, -8.460891227357431],
              [-38.040967612390894, -8.461064677533589],
              [-38.040877019574111, -8.461332795681164],
              [-38.040800887373337, -8.461740681567894],
              [-38.040686505063896, -8.462041300261658],
              [-38.040449153571885, -8.462147981674239],
              [-38.040172474325374, -8.46210578995646],
              [-38.039987259690342, -8.462030720978165],
              [-38.03963350479399, -8.462043418597556],
              [-38.039278941958869, -8.462162201886047],
              [-38.038998161705592, -8.462308660400435],
              [-38.038768964451521, -8.462496939057123],
              [-38.038540938276654, -8.462579222922463],
              [-38.038253780899517, -8.462549846773303],
              [-38.03805499868168, -8.462524667263851],
              [-38.037113682086087, -8.462691837857353],
              [-38.036017595632401, -8.462851389240596],
              [-38.03549308498723, -8.462928870312485],
              [-38.034482431157365, -8.46299719166281],
              [-38.03435465063005, -8.463022109275199],
              [-38.033141318340419, -8.463607613194805],
              [-38.032617400460275, -8.463920239347223],
              [-38.032284224357142, -8.464130957230882],
              [-38.032005925693774, -8.464300750281641],
              [-38.031730831215214, -8.464387797179967],
              [-38.031559174477877, -8.464558396628879],
              [-38.031384222478763, -8.464811651230642],
              [-38.030479123362689, -8.464986943916363],
              [-38.030095300345991, -8.464708698660894],
              [-38.029731192839144, -8.464392968866477],
              [-38.02937846554434, -8.46395851759307],
              [-38.029109530363812, -8.463762496587343],
              [-38.028595786215085, -8.463530414270922],
              [-38.028162308675263, -8.463457164888172],
              [-38.027764062733603, -8.463498049107914],
              [-38.027347435390531, -8.46358790635337],
              [-38.02698460260833, -8.463742743398798],
              [-38.026721007490629, -8.464169964860146],
              [-38.026615228217231, -8.464404306104203],
              [-38.026310279698961, -8.464659133649633],
              [-38.026131074966159, -8.464759891002464],
              [-38.025728561267236, -8.464871849142511],
              [-38.025090110907669, -8.46503151410873],
              [-38.024891123510187, -8.465051909713477],
              [-38.024537929689444, -8.464841024082673],
              [-38.024155081800899, -8.464574352382536],
              [-38.023855706486977, -8.464310963792734],
              [-38.02368054328722, -8.464105405811205],
              [-38.023502174350114, -8.463982502991399],
              [-38.023309938763141, -8.463955252213012],
              [-38.023197697094496, -8.464013758551422],
              [-38.023140830804735, -8.464266670830199],
              [-38.023096048793867, -8.464518437551796],
              [-38.023041327732237, -8.464677025031689],
              [-38.022842679693952, -8.464815084383977],
              [-38.022559837841428, -8.465055855127785],
              [-38.022378420059262, -8.46513327128271],
              [-38.021779666604324, -8.465112328559016],
              [-38.02142954634671, -8.465159928686004],
              [-38.021140764001707, -8.465342620214113],
              [-38.020798592577741, -8.465695478333009],
              [-38.020540999188142, -8.465973301046198],
              [-38.020345804326645, -8.466146187415205],
              [-38.020000808549021, -8.466358130399884],
              [-38.019647479834028, -8.46648856474145],
              [-38.019403005517447, -8.466643057857175],
              [-38.019111737043062, -8.466802317174794],
              [-38.019019391057327, -8.466823610366861],
              [-38.0189944593711, -8.466696659009575],
              [-38.019290588363504, -8.465442532612785],
              [-38.019613176423064, -8.464428864406997],
              [-38.01979784697069, -8.463927379526744],
              [-38.019936661928774, -8.463203204055205],
              [-38.020038742925188, -8.46281619119846],
              [-38.020136622442664, -8.462617827866501],
              [-38.020219301130673, -8.462503536623547],
              [-38.020837686141768, -8.461943175002396],
              [-38.021442887536715, -8.461386939491966],
              [-38.021825726671956, -8.460971054047674],
              [-38.022130765623892, -8.46071613974485],
              [-38.022358311674154, -8.460500554246112],
              [-38.022463753079414, -8.460148459042607],
              [-38.022484213495957, -8.459887496989028],
              [-38.022493807899366, -8.459180451955772],
              [-38.022560242784621, -8.457978021742662],
              [-38.022548994357741, -8.457755689239514],
              [-38.022468232799724, -8.457434421303883],
              [-38.022295400329739, -8.457134448301995],
              [-38.02201688112838, -8.456852028013801],
              [-38.021699513385975, -8.456414042094947],
              [-38.02158765607944, -8.456248978638275],
              [-38.021511864819828, -8.455974300225796],
              [-38.021385778702019, -8.455328325547741],
              [-38.021158215478849, -8.454039693573479],
              [-38.021203560452662, -8.453564448260527],
              [-38.021254777942787, -8.453029707318841],
              [-38.021373649200868, -8.452682348681824],
              [-38.021404497149028, -8.452408479505914],
              [-38.0213045432507, -8.452242270097639],
              [-38.021149440284489, -8.452116892632755],
              [-38.020894970749005, -8.452060725428101],
              [-38.020537588822165, -8.452111021160768],
              [-38.020167493139056, -8.45219601413238],
              [-38.019902253251239, -8.452376232444903],
              [-38.019655824414386, -8.452625052025761],
              [-38.019429357658872, -8.452954233125308],
              [-38.01925530796526, -8.453101577871742],
              [-38.018994607070788, -8.453210629671057],
              [-38.018551882253703, -8.453279611737996],
              [-38.018109339005306, -8.453348593741637],
              [-38.017655797484828, -8.453312726431564],
              [-38.017120057745139, -8.453094503941266],
              [-38.016642828332834, -8.452943354137107],
              [-38.016329846326926, -8.452893096536929],
              [-38.015970500481195, -8.452965269736714],
              [-38.015433130585727, -8.453032201495589],
              [-38.014797116439958, -8.453062165195197],
              [-38.014388402841355, -8.453115853119252],
              [-38.013930749418357, -8.453268721507222],
              [-38.01336706643815, -8.453538352669534],
              [-38.012755412103338, -8.453918832961996],
              [-38.0123733495845, -8.454172008102697],
              [-38.012053951264832, -8.454287147277874],
              [-38.011702416644837, -8.454323250114307],
              [-38.011263261965901, -8.454309479239486],
              [-38.010953915316435, -8.454294136661812],
              [-38.010665953129859, -8.454370735828352],
              [-38.010428327663242, -8.454477488412385],
              [-38.010006442721156, -8.454744216879844],
              [-38.009640313455961, -8.454981963967223],
              [-38.009392816474417, -8.455219196996856],
              [-38.008902634333104, -8.455516501085192],
              [-38.008377096457117, -8.455857126468644],
              [-38.007863317373356, -8.45603932857577],
              [-38.007544976946413, -8.456166042837426],
              [-38.007084443524839, -8.45651922149337],
              [-38.006754897495, -8.456764926645858],
              [-38.006288000693118, -8.456942180779022],
              [-38.005740055254115, -8.457021914126026],
              [-38.005389941879898, -8.457069590962899],
              [-38.005315981523161, -8.457041908967007],
              [-38.005332149993144, -8.45651070124514],
              [-38.005402107696376, -8.456044657107638],
              [-38.005529609671783, -8.455560851899266],
              [-38.00556341753304, -8.455306166251551],
              [-38.005596113752922, -8.455059888757011],
              [-38.00576250669382, -8.454606027642505],
              [-38.005887132357543, -8.454322631579052],
              [-38.006175486473801, -8.454022286587003],
              [-38.006758928552237, -8.453700260924609],
              [-38.007118122954779, -8.453510342167823],
              [-38.007470290769717, -8.453368429286726],
              [-38.007890390640796, -8.453195847922819],
              [-38.008092671678391, -8.453092715377661],
              [-38.008347619303052, -8.452925412712668],
              [-38.009023779578897, -8.452255963524431],
              [-38.009199164945414, -8.452120293966427],
              [-38.009571930873676, -8.451914851160602],
              [-38.009977732663934, -8.451720253957051],
              [-38.010318359312151, -8.451579394304899],
              [-38.010541687147949, -8.451450628307104],
              [-38.010651624238761, -8.451368785798454],
              [-38.010912507667122, -8.451259559251147],
              [-38.011027140169745, -8.451224305546814],
              [-38.01150608529985, -8.451163472560969],
              [-38.011909013383651, -8.451026657645791],
              [-38.012207451484436, -8.450937241061148],
              [-38.012505889701792, -8.450847733792461],
              [-38.012798544739461, -8.4508177218441],
              [-38.01304425251864, -8.450674901906627],
              [-38.013263853013221, -8.450511124141729],
              [-38.013383116700076, -8.450404794768179],
              [-38.013558745860841, -8.450386787296015],
              [-38.01404051143308, -8.450466774761704],
              [-38.014339285856003, -8.450495021021769],
              [-38.014633273997092, -8.4504764970177],
              [-38.015197379784979, -8.45032452821558],
              [-38.015618624987759, -8.450163607543919],
              [-38.016178222373064, -8.450034055276301],
              [-38.016678214352581, -8.449947311996402],
              [-38.016923894935339, -8.449922153147606],
              [-38.017397575916711, -8.450038383069787],
              [-38.017710894460151, -8.45020612338871],
              [-38.01795016146017, -8.45034645500589],
              [-38.018244149628842, -8.45032792825754],
              [-38.01844163922037, -8.450178381573837],
              [-38.018466888526632, -8.449963918574099],
              [-38.018455733226091, -8.449741495944664],
              [-38.018426489338914, -8.449344386206155],
              [-38.018410596553394, -8.449163464165485],
              [-38.01832085056823, -8.448984437153518],
              [-38.018296010424031, -8.448857486022384],
              [-38.018355426805144, -8.448745487986592],
              [-38.018439345182948, -8.448642685897404],
              [-38.018607792505648, -8.448554837505473],
              [-38.018781027075043, -8.448513578200368],
              [-38.018968557824159, -8.448494331816631],
              [-38.019267240704615, -8.448522573958726],
              [-38.019563621353115, -8.448527295419133],
              [-38.019835408445111, -8.448523091875961],
              [-38.020403147592965, -8.44836949616298],
              [-38.02081160773605, -8.448198136697149],
              [-38.021198747262112, -8.448052589661254],
              [-38.021653095792132, -8.447982366721904],
              [-38.02188951333072, -8.448064082938515],
              [-38.022180724359941, -8.448245963794259],
              [-38.02253521222611, -8.448586093629073],
              [-38.022821884806383, -8.448839139993044],
              [-38.023300695245553, -8.449119436018792],
              [-38.023639760530394, -8.449308127939018],
              [-38.023925875193193, -8.449530693534399],
              [-38.024097736445611, -8.449818996351283],
              [-38.024252277716947, -8.450167942038446],
              [-38.024383536266534, -8.450636935661723],
              [-38.024496000445836, -8.451030363994134],
              [-38.024587059590431, -8.451338814648263],
              [-38.024657724376382, -8.451449238579794],
              [-38.024751399650953, -8.451439613831335],
              [-38.024868176160979, -8.451309940602286],
              [-38.024978490811016, -8.451004433417951],
              [-38.025039868567468, -8.450798108842932],
              [-38.025156826442313, -8.450668435943054],
              [-38.025288868881056, -8.450572535144552],
              [-38.025404834031619, -8.450548766418674],
              [-38.025554968191621, -8.450627552664129],
              [-38.02574672703502, -8.450878461756156],
              [-38.025826877381519, -8.451082154075891],
              [-38.025892443178435, -8.451487132406781],
              [-38.025886623152424, -8.451887863264172],
              [-38.025936581647642, -8.452141584930024],
              [-38.02595706784345, -8.45233952143303],
              [-38.025903038964202, -8.452513215146372],
              [-38.025775784098606, -8.452655705259509],
              [-38.025624616841803, -8.45302423898508],
              [-38.025599121487545, -8.453121038125385],
              [-38.025677780332153, -8.453195305320705],
              [-38.025757432003189, -8.4531635777992],
              [-38.025979514296345, -8.45302313250437],
              [-38.026108352423151, -8.453009886869188],
              [-38.026250153857831, -8.453007074185759],
              [-38.026286808507685, -8.453132788315514],
              [-38.026331888048091, -8.453340011002281],
              [-38.026317302763452, -8.453541568355849],
              [-38.026256341134555, -8.45394406131437],
              [-38.026240604173012, -8.454133948861317],
              [-38.026280425285492, -8.454518152063489],
              [-38.026237337467784, -8.454675503135219],
              [-38.026166292003673, -8.454788739729031],
              [-38.02609951253482, -8.454830900076709],
              [-38.026101186494074, -8.454960144827167],
              [-38.026140235420392, -8.45510910833999],
              [-38.026238699805972, -8.455146072507349],
              [-38.026344278620911, -8.455135210913841],
              [-38.026527994089484, -8.455081131840499],
              [-38.026660939867597, -8.454879145341136],
              [-38.026775322718258, -8.454726222243179],
              [-38.02689875965379, -8.454548812020096],
              [-38.027058964842361, -8.454379451981078],
              [-38.027314967625628, -8.454223715257315],
              [-38.027636415892964, -8.454014237906366],
              [-38.027875189384545, -8.453919053648175],
              [-38.028064935259756, -8.453923142516219],
              [-38.028192963210437, -8.454015891453471],
              [-38.028223928793423, -8.454201011307509],
              [-38.028196199315104, -8.454392135438653],
              [-38.028035456519945, -8.454667491899251],
              [-38.027883858673746, -8.454918270940365],
              [-38.027771150212907, -8.455200620090285],
              [-38.027779825532761, -8.455399793425583],
              [-38.027841347141944, -8.455534613383909],
              [-38.028007742915776, -8.455541086275737],
              [-38.028146878499854, -8.455515022963677],
              [-38.028226189184025, -8.455365720398349],
              [-38.028328509516292, -8.455096368848681],
              [-38.028546955613002, -8.454920912302809],
              [-38.028776152029671, -8.454732730059632],
              [-38.028966276683661, -8.454513067570005],
              [-38.028998953838347, -8.454486287559357],
              [-38.02910491047809, -8.454251764653645],
              [-38.029176227308902, -8.454138618531779],
              [-38.029262447984934, -8.454059063100853],
              [-38.029368026282746, -8.45404829108864],
              [-38.029460710389458, -8.454144660095114],
              [-38.029560849861788, -8.454310958747588],
              [-38.029649428828918, -8.454595978240176],
              [-38.029744402407303, -8.454939257819143],
              [-38.029848680917127, -8.455375715067639],
              [-38.030185987097369, -8.456235291995718],
              [-38.030320139467563, -8.456603901750693],
              [-38.030428454682387, -8.45673404398047],
              [-38.030579493322186, -8.456706743149006],
              [-38.030704695662337, -8.456658576736961],
              [-38.030752298287645, -8.456547813511564],
              [-38.030777450635604, -8.45633343927674],
              [-38.030827845830039, -8.456231998413994],
              [-38.030914339004717, -8.456152533757539],
              [-38.031135612072973, -8.456117990416317],
              [-38.03125273026437, -8.456105980344844],
              [-38.031495301763748, -8.456163468434671],
              [-38.031655666594311, -8.456111680613125],
              [-38.03174909169924, -8.455984389393542],
              [-38.031862230277788, -8.455819885050246],
              [-38.03195911072261, -8.455727512701602],
              [-38.032106169242375, -8.455547717129983],
              [-38.032191419423206, -8.45545658212383],
              [-38.032266406522091, -8.455037119308242],
              [-38.032311614108309, -8.454903016000088],
              [-38.032375816772252, -8.454837604698211],
              [-38.032509102071657, -8.454753281020082],
              [-38.032602869008493, -8.454743654686659],
              [-38.032700091269724, -8.454769037710465],
              [-38.032815248248603, -8.454851262264167],
              [-38.032811953538683, -8.45493400804869],
              [-38.032777909468749, -8.455290534543213],
              [-38.032788821160231, -8.455395292707284],
              [-38.03284074528576, -8.455554778865974],
              [-38.032994611863622, -8.455668210918343],
              [-38.033278107205113, -8.45585250750819],
              [-38.033448049923024, -8.455893897277173],
              [-38.033924605849002, -8.455809787751383],
              [-38.034320267707642, -8.455745556018956],
              [-38.034623586199409, -8.455702802039346],
              [-38.035038890703696, -8.455483601272713],
              [-38.035444839360203, -8.455264105627609],
              [-38.035761929799918, -8.455125697519678],
              [-38.035970420149333, -8.455080719059291],
              [-38.036149688876144, -8.455097711408872],
              [-38.036346620368164, -8.455171544456643],
              [-38.036528374852296, -8.455211786236509],
              [-38.036641946036561, -8.455164855333024],
              [-38.036658112799181, -8.455092541890773],
              [-38.03665972944075, -8.454880460440029],
              [-38.036642875091857, -8.454717533724484],
              [-38.036654923900997, -8.454584252538098],
              [-38.036721702157401, -8.454542180695489],
              [-38.036884460704201, -8.454513639947052],
              [-38.037296975237012, -8.454612512615023],
              [-38.037463371007043, -8.454619071900348],
              [-38.037655168762413, -8.454528740207257],
              [-38.037879480872249, -8.454293872249265],
              [-38.037914117475339, -8.454172675227383],
              [-38.037991928004203, -8.453894125513463],
              [-38.038060004076364, -8.453731497963361],
              [-38.0381792629234, -8.453625160393498],
              [-38.038303311386777, -8.453565411969297],
              [-38.038541002334391, -8.453576306221368],
              [-38.039799701958472, -8.453729529041897],
              [-38.040171112530651, -8.453773671084774],
              [-38.040584779542321, -8.453884210094584],
              [-38.041090000379597, -8.453961780908758],
              [-38.041305220567132, -8.454060256443965],
              [-38.041549471930438, -8.454247074039834],
              [-38.041852673758179, -8.454545279096124],
              [-38.04228338043405, -8.454818834092618],
              [-38.042472321297083, -8.454928821294397],
              [-38.042547782272422, -8.455085831816216],
              [-38.042625802331642, -8.455383756755676],
              [-38.042712044376024, -8.455625989714333],
              [-38.042785019498268, -8.455759750612179],
              [-38.042849657347922, -8.455811912693607],
              [-38.043734978358046, -8.456132793740819],
              [-38.044015291892975, -8.45621008279781],
              [-38.044211970767229, -8.456166246792787],
              [-38.044306544010084, -8.456050712354591],
              [-38.044369156122691, -8.455855963314347],
              [-38.044387280000642, -8.455689233002214],
              [-38.044315546821849, -8.455567323245043],
              [-38.044022911471465, -8.455256031579577],
              [-38.043792518950525, -8.454973742733284],
              [-38.043672132256376, -8.454727266196787],
              [-38.043590016427686, -8.454579735966258],
              [-38.043605835854521, -8.45438984699615],
              [-38.043710977597591, -8.454261405321228],
              [-38.043870006537659, -8.45419803241658],
              [-38.044059753595242, -8.454202113563348],
              [-38.044621258573919, -8.454368088552178],
              [-38.044849806920567, -8.454403376001915],
              [-38.045084042330267, -8.454379347400124],
              [-38.045350789660311, -8.454210691120464],
              [-38.045450867544069, -8.454035749484937],
              [-38.045480893166165, -8.45386787232888],
              [-38.045443976267777, -8.453624584361426],
              [-38.045309910145264, -8.453473581399503],
              [-38.04511689348459, -8.453211010339507],
              [-38.04499908335449, -8.452987783977441],
              [-38.045023295056907, -8.452746273075238],
              [-38.045126040500151, -8.45259458152837],
              [-38.045316683457393, -8.452492666401957],
              [-38.045581017282913, -8.452418334695549],
              [-38.04592207238273, -8.45239519895577],
              [-38.046127892948952, -8.452326874296428],
              [-38.046210652896889, -8.452212576613951],
              [-38.046205606632192, -8.452048231562246],
              [-38.046116396055652, -8.451645638630024],
              [-38.04606981560822, -8.451305554400648],
              [-38.046148425366944, -8.450920916030782],
              [-38.046285803695447, -8.450647935519983],
              [-38.046428063320739, -8.450421544359214],
              [-38.046416801309732, -8.450199030062835],
              [-38.046304768845687, -8.449916397874851],
              [-38.045938522051827, -8.449583132092759],
              [-38.045742306065478, -8.4494031270278],
              [-38.045660992435636, -8.449187948715817],
              [-38.045682753097424, -8.449056318476487],
              [-38.045942822223722, -8.449053063122509],
              [-38.046389344423829, -8.449136633587239],
              [-38.04661194690047, -8.449113751126317],
              [-38.046815369665737, -8.4490223573875],
              [-38.04685018480253, -8.448901069180033],
              [-38.04677357070922, -8.448732389578097],
              [-38.046539540858568, -8.448415183062652],
              [-38.046197720683146, -8.448085594764416],
              [-38.045871516587411, -8.447907172537542],
              [-38.04577908533755, -8.44781750041451],
              [-38.045778647587078, -8.447699924991745],
              [-38.045841694945715, -8.447622750828124],
              [-38.046199442797764, -8.447384493943829],
              [-38.04648216716916, -8.447143612137696],
              [-38.046611552479952, -8.446906698127727],
              [-38.04658297316471, -8.446745009007373],
              [-38.046547117551043, -8.446513209752661],
              [-38.04649030663856, -8.446307225858737],
              [-38.046451250174819, -8.446158263532805],
              [-38.046437613693826, -8.446014880030095],
              [-38.046603568770514, -8.44590367869675],
              [-38.04688468151187, -8.44587478821119],
              [-38.047586129047609, -8.445791026512477],
              [-38.047990043302313, -8.445690713318074],
              [-38.048425298864885, -8.4455518571204],
              [-38.048645412632894, -8.445505814093529],
              [-38.048901840646302, -8.445467727539018],
              [-38.049015574058082, -8.445538367418152],
              [-38.049005444458096, -8.445668850497839],
              [-38.048930312002092, -8.445970653625446],
              [-38.048921134819317, -8.446454043296422],
              [-38.049072587046339, -8.446738217645249],
              [-38.049285224679295, -8.446963481894006],
              [-38.049590384555572, -8.447167445372099],
              [-38.049922367450336, -8.447286367858462],
              [-38.050574340915745, -8.447525358728434],
              [-38.050785479348406, -8.447621286687479],
              [-38.05117250349295, -8.44781685546328],
              [-38.051380184999744, -8.44787786416507],
              [-38.051566381128239, -8.44784711259317],
              [-38.051702056409361, -8.447785940561587],
              [-38.051866509693731, -8.447545582161478],
              [-38.051993405459072, -8.447285416423737],
              [-38.052106351494139, -8.447120904477694],
              [-38.052272578447507, -8.447009610839057],
              [-38.052915515890007, -8.446931757747146],
              [-38.053778839428873, -8.446584376104695],
              [-38.054269544559155, -8.446354210334151],
              [-38.05459256669026, -8.446273953938752],
              [-38.054888598912036, -8.446161253896982],
              [-38.055023120281369, -8.446088591665008],
              [-38.055063436351212, -8.445907895242447],
              [-38.055006012544901, -8.445466580393731],
              [-38.054974593559066, -8.445163885493763],
              [-38.055029302412002, -8.444966311107606],
              [-38.055199696828907, -8.444784119498024],
              [-38.055502565776557, -8.444623591471734],
              [-38.055760236339793, -8.444597170312605],
              [-38.055920853134339, -8.444663037402098],
              [-38.056249806666784, -8.444982186003669],
              [-38.056631328161124, -8.445237156626414],
              [-38.057025463806085, -8.445384983392577],
              [-38.057180136458747, -8.445392681437948],
              [-38.057254720110109, -8.445314357155484],
              [-38.057287044905941, -8.445169727552027],
              [-38.057253669114957, -8.444961449500928],
              [-38.057187527716849, -8.444780044359105],
              [-38.05694540970304, -8.44449891027905],
              [-38.056676211572054, -8.444185242177452],
              [-38.056593558006881, -8.443958485728395],
              [-38.056736261581406, -8.443508337128659],
              [-38.056981600937007, -8.443247911644614],
              [-38.05735221341174, -8.443056885090785],
              [-38.057877671105196, -8.442951698232692],
              [-38.058423197755609, -8.442848639024835],
              [-38.058572986505894, -8.442809746971307],
              [-38.058894501479863, -8.442600241126479],
              [-38.059091622996412, -8.442332824704517],
              [-38.059226150782791, -8.441919012915028],
              [-38.05924862960363, -8.441563629107725],
              [-38.059205928954022, -8.441379657672316],
              [-38.059101336935136, -8.441284623224],
              [-38.058943026166169, -8.441242006694258],
              [-38.058615125624137, -8.441275768255606],
              [-38.058288468666056, -8.44132110912004],
              [-38.058075377150821, -8.441319330931243],
              [-38.057920355247653, -8.441194057779301],
              [-38.05778344980348, -8.440902233690998],
              [-38.057646015029121, -8.440716406492028],
              [-38.057462327352951, -8.440575779564142],
              [-38.057214617291741, -8.440354052889122],
              [-38.056999490847844, -8.440105543151253],
              [-38.05684092118171, -8.439945350459698],
              [-38.056600477380101, -8.439911217909613],
              [-38.05630205442565, -8.44000067046855],
              [-38.055612326469472, -8.440424449195334],
              [-38.054996027058415, -8.440758504135632],
              [-38.054782042211045, -8.440862810585882],
              [-38.054584037991546, -8.44089489223868],
              [-38.054424575412966, -8.440840694591081],
              [-38.05412694705597, -8.440752519080942],
              [-38.053798606771565, -8.440668610448402],
              [-38.053449752627031, -8.440728002653753],
              [-38.05321810303515, -8.440775287072654],
              [-38.053127538574934, -8.44070217205228],
              [-38.053082444871634, -8.440495041324175],
              [-38.053089737161407, -8.4402235519639],
              [-38.05299580604246, -8.440115608614681],
              [-38.052831552605284, -8.440014821159306],
              [-38.052620675881059, -8.440036469917922],
              [-38.052337702389714, -8.440159691553134],
              [-38.051947910966575, -8.440282021562815],
              [-38.051650501210617, -8.440179193201629],
              [-38.05143786533727, -8.439953930418742],
              [-38.051285697200456, -8.439628242382891],
              [-38.051224093031685, -8.439375669719533],
              [-38.051276220872552, -8.439193826579109],
              [-38.051362706181941, -8.439114356446925],
              [-38.051758000576058, -8.43893261981828],
              [-38.051964888839329, -8.438758386150596],
              [-38.052091871848326, -8.438498220161097],
              [-38.052135826850659, -8.438352533890605],
              [-38.052087989541505, -8.438004306934614],
              [-38.051972764821265, -8.43757732295944],
              [-38.051877955165601, -8.437234047188351],
              [-38.051770803403912, -8.436998005792368],
              [-38.051546525108641, -8.436891555861797],
              [-38.051307599668668, -8.436869091003679],
              [-38.051088825061726, -8.436926806367781],
              [-38.050928910019692, -8.437096088580677],
              [-38.050754257433475, -8.437125783055489],
              [-38.05066387549639, -8.437052848920425],
              [-38.050599072666323, -8.436882932444455],
              [-38.050487917883181, -8.436501088623221],
              [-38.050253284963311, -8.435948553117186],
              [-38.050050689816118, -8.435592806415496],
              [-38.049959702447971, -8.435284540683721],
              [-38.049894837265228, -8.434545472644578],
              [-38.049838571737915, -8.434115917867665],
              [-38.049683207353937, -8.433872885731093],
              [-38.049556074179797, -8.433674238510502],
              [-38.049358711011926, -8.433482656454123],
              [-38.049048223642096, -8.43345567583828],
              [-38.048726364097035, -8.433547598573607],
              [-38.048326283463688, -8.433682743424507],
              [-38.048140093720683, -8.433713675085274],
              [-38.047845421516087, -8.433496894269362],
              [-38.047644775501226, -8.433311001324347],
              [-38.046990832289467, -8.432366194738412],
              [-38.046476018387757, -8.431395316411225],
              [-38.046361046339577, -8.431313006230043],
              [-38.046034855368269, -8.431134585546081],
              [-38.045877355316108, -8.430986058837949],
              [-38.045840695174995, -8.430860436364483],
              [-38.045930288677425, -8.430698310983932],
              [-38.046184238898732, -8.430407162686896],
              [-38.046363954861157, -8.430200396999421],
              [-38.04641990334288, -8.430053474394693],
              [-38.046413431089626, -8.429877639599784],
              [-38.046242345226389, -8.429824586713238],
              [-38.045935408856906, -8.429832523170369],
              [-38.045675352611312, -8.429835779953665],
              [-38.045506405705723, -8.429688401072111],
              [-38.045316340664399, -8.429374467260269],
              [-38.0452293440432, -8.429218605179502],
              [-38.045008710567643, -8.429147071580593],
              [-38.044727882564295, -8.42917596273943],
              [-38.044323179985057, -8.429382360810655],
              [-38.04398071643098, -8.429617399195925],
              [-38.043716472597474, -8.429809396617694],
              [-38.043376861936117, -8.429844293567577],
              [-38.043052263386642, -8.429795115922145],
              [-38.04285285670904, -8.42969794739605],
              [-38.0426753010113, -8.429469058348728],
              [-38.042431703966777, -8.429058762748797],
              [-38.042322934364023, -8.42885581730007],
              [-38.04211989048666, -8.428723638617432],
              [-38.041769111079304, -8.428536201075882],
              [-38.041321280147891, -8.428440867323127],
              [-38.040681410286155, -8.428318473959877],
              [-38.040060816482878, -8.428040838238028],
              [-38.039525052822107, -8.427692050638731],
              [-38.039235016651212, -8.427404098006862],
              [-38.038846339869984, -8.427079274838746],
              [-38.038682993453236, -8.426872486909138],
              [-38.038472676663233, -8.426670377317992],
              [-38.038171085164585, -8.426501417054105],
              [-38.037811330232742, -8.426455947874349],
              [-38.037411970644619, -8.426485176566986],
              [-38.036895937394348, -8.426644083377507],
              [-38.036546166024074, -8.426748589820813],
              [-38.036143077570877, -8.42674298798871],
              [-38.035670840348878, -8.426638453637043],
              [-38.035034179137249, -8.426433124477205],
              [-38.03446321159376, -8.426291717242917],
              [-38.03410106273224, -8.426223085791655],
              [-38.033711119978932, -8.426227733763131],
              [-38.033316549679093, -8.426303637927546],
              [-38.033159744432126, -8.426390256662961],
              [-38.030629152810661, -8.425328383890035],
              [-38.03043176571974, -8.424677893371907],
              [-38.030265056740042, -8.424218852814302],
              [-38.030037985033367, -8.423853903217621],
              [-38.029909804651119, -8.423643491008438],
              [-38.029640894751708, -8.423447473096513],
              [-38.029355818821649, -8.423323858724103],
              [-38.028992522647037, -8.423243462725525],
              [-38.028447920890372, -8.423240578560987],
              [-38.027983934566834, -8.423217543812159],
              [-38.027599684324251, -8.423162780087635],
              [-38.027205584769384, -8.42301492735637],
              [-38.026890069421334, -8.422823857054182],
              [-38.026545366696233, -8.422500127061454],
              [-38.026382029040626, -8.422293334922729],
              [-38.026196745088434, -8.422218261490515],
              [-38.026140289213664, -8.422129850708975],
              [-38.026223047350548, -8.42201546656878],
              [-38.026327283807014, -8.421992933690579],
              [-38.026819063720602, -8.421942510184406],
              [-38.027039074166346, -8.421896477788934],
              [-38.027481329343878, -8.42170981637304],
              [-38.027764385429059, -8.421586612152332],
              [-38.02811446589979, -8.42153891126766],
              [-38.028593373656435, -8.421478052834118],
              [-38.028921256872238, -8.421444405197423],
              [-38.029149350369885, -8.421362034053056],
              [-38.02941928237793, -8.42111073093626],
              [-38.029788814443783, -8.420655908795291],
              [-38.030020382942247, -8.42049088209053],
              [-38.0302544159387, -8.420466859667437],
              [-38.030521899875374, -8.420651296958024],
              [-38.030839183210851, -8.420971609703143],
              [-38.031220409740385, -8.42154775820968],
              [-38.03164535479398, -8.422221871997182],
              [-38.031798127491854, -8.422441478917824],
              [-38.032061006445744, -8.422579236170323],
              [-38.032194714192421, -8.422612576785902],
              [-38.032429991102646, -8.422600223224148],
              [-38.032969641548355, -8.422438847636707],
              [-38.033378948429551, -8.422279140269891],
              [-38.033559908709925, -8.422084141168506],
              [-38.033711669803075, -8.421833267663027],
              [-38.033898661767942, -8.421696437161275],
              [-38.034102282512976, -8.42155666286437],
              [-38.034493028314458, -8.42144601835462],
              [-38.034994133026125, -8.421371007200502],
              [-38.035636129570292, -8.421399173422692],
              [-38.036903958463625, -8.421527906929839],
              [-38.037470579792071, -8.421588082664288],
              [-38.038129893456492, -8.421555691332264],
              [-38.038706822581922, -8.421414114999662],
              [-38.039248864513333, -8.42127607013216],
              [-38.039656210007202, -8.421210681771054],
              [-38.039937650454895, -8.421299459898016],
              [-38.040123283233164, -8.421492192711348],
              [-38.040331458918892, -8.421788536372919],
              [-38.040562369646068, -8.422036820444903],
              [-38.040746323143843, -8.422100307596434],
              [-38.041240589583118, -8.422073025013098],
              [-38.041732369793628, -8.422022582628182],
              [-38.042286824927999, -8.422118627305501],
              [-38.042676577394431, -8.422411794893454],
              [-38.043027789220908, -8.422717077915625],
              [-38.043309578753245, -8.422923429093137],
              [-38.043565095656938, -8.422991340071592],
              [-38.043780753374932, -8.423016284416725],
              [-38.044031042006019, -8.422920030368983],
              [-38.044182871974733, -8.422786726738623],
              [-38.044319892354935, -8.422396079834645],
              [-38.044430362989758, -8.422090654972525],
              [-38.044611062288013, -8.421777803729434],
              [-38.045219994466088, -8.420993072518524],
              [-38.045473938542486, -8.420590499440355],
              [-38.045600479767472, -8.420212759561135],
              [-38.045611247592134, -8.419858615106994],
              [-38.045555425777209, -8.419546635740085],
              [-38.045471447238015, -8.419197597229022],
              [-38.045279431205046, -8.418829032133866],
              [-38.04518764570183, -8.418626671436543],
              [-38.044992796532838, -8.418117190848594],
              [-38.044873585880936, -8.417764720314986],
              [-38.04463354046581, -8.417277921156597],
              [-38.044423058977678, -8.416958239994802],
              [-38.04440859653117, -8.416818562517836],
              [-38.044555980642635, -8.416756334970941],
              [-38.044856873073719, -8.4166901391779],
              [-38.045020328646366, -8.416555777830894],
              [-38.045085599695646, -8.416384277599366],
              [-38.045072746510691, -8.416032517687501],
              [-38.045075807112752, -8.415487612444549],
              [-38.045018652147391, -8.415163962800872],
              [-38.044947034598977, -8.414700635669453],
              [-38.044869731175503, -8.414296715075832],
              [-38.044858120809721, -8.413956715620523],
              [-38.044849888054209, -8.413646841532167],
              [-38.044907095399992, -8.413170351436593],
              [-38.044923367437093, -8.412756709491465],
              [-38.044922581194385, -8.412521468074678],
              [-38.044749126039108, -8.412103928552323],
              [-38.044692409910176, -8.411897944928468],
              [-38.044709666196191, -8.411712852177125],
              [-38.044805288345131, -8.411608985728042],
              [-38.044946637039565, -8.411488408079048],
              [-38.04528579272808, -8.411335932319602],
              [-38.045621582222815, -8.411148537468632],
              [-38.045995548063537, -8.410874772349416],
              [-38.046237580860584, -8.410696917228849],
              [-38.046468060319512, -8.4106379651542],
              [-38.046778529267804, -8.410664945796634],
              [-38.047056326006306, -8.410718798769464],
              [-38.047357728810653, -8.410887841412924],
              [-38.047831634573534, -8.411121696188083],
              [-38.048139709208904, -8.411125155082011],
              [-38.048463398886035, -8.411019849729589],
              [-38.048726201752196, -8.410816357664846],
              [-38.048875280662948, -8.410542136073598],
              [-38.048907345895365, -8.410279931630836],
              [-38.04882195777914, -8.409912168652072],
              [-38.048756628032763, -8.409624766713559],
              [-38.048735442320236, -8.40941082001882],
              [-38.048838536974408, -8.409035372951365],
              [-38.04898611374599, -8.408631815421625],
              [-38.048989508478044, -8.408207921644721],
              [-38.048783741861023, -8.407593593957039],
              [-38.048643777829923, -8.407259072166324],
              [-38.048550309294008, -8.406927556907023],
              [-38.048497593938102, -8.406532830723792],
              [-38.048479945797489, -8.406134571636544],
              [-38.048542195923325, -8.405822244780042],
              [-38.048624776499857, -8.405590279600842],
              [-38.048768077349415, -8.405375373806921],
              [-38.04906337993328, -8.405027433113013],
              [-38.049458634472764, -8.404698274136756],
              [-38.049874768046145, -8.404373234974825],
              [-38.050230879535242, -8.40404253351689],
              [-38.050433047949063, -8.403821618894304],
              [-38.050552817281186, -8.40360927831588],
              [-38.0506434765908, -8.40334115513552],
              [-38.050659999593584, -8.403045268310841],
              [-38.050600794478413, -8.402816035619617],
              [-38.050455113126056, -8.402666183461262],
              [-38.050328428756657, -8.402585022656824],
              [-38.050257866387902, -8.402469629185118],
              [-38.050267021924014, -8.402327566684262],
              [-38.050482241404076, -8.401893511282088],
              [-38.050606724029493, -8.401610094442209],
              [-38.050708311529306, -8.401105491301891],
              [-38.050852998219526, -8.400543380318139],
              [-38.050976236872394, -8.400248293230177],
              [-38.05117697765295, -8.400015797887425],
              [-38.051610326928454, -8.399630020446937],
              [-38.052090551755853, -8.399239561804407],
              [-38.052541302887199, -8.398793138291062],
              [-38.053018912542314, -8.398244307675766],
              [-38.053368360766441, -8.39796124941831],
              [-38.053596436191249, -8.397878952322106],
              [-38.053898907221068, -8.397941993552399],
              [-38.054092617843146, -8.398098562035768],
              [-38.054216546173848, -8.398380044533948],
              [-38.054261265592686, -8.39892859368134],
              [-38.05433836674856, -8.399307098706595],
              [-38.054417724022137, -8.399616601708702],
              [-38.054608940240357, -8.400091158998197],
              [-38.054746092380093, -8.400500648938678],
              [-38.054765961445412, -8.400922247953901],
              [-38.054671137272358, -8.401261450091271],
              [-38.05429690482179, -8.401876553602159],
              [-38.053984556246888, -8.402402810153932],
              [-38.053860685797865, -8.402809231177697],
              [-38.053955661248004, -8.403270172061506],
              [-38.054067238735577, -8.403776464379311],
              [-38.054010317130121, -8.40425313896773],
              [-38.053776848366923, -8.404647900748662],
              [-38.053266783195703, -8.405206240186349],
              [-38.052928336171007, -8.405593965616099],
              [-38.052726609215419, -8.405932457445944],
              [-38.052656372757738, -8.406280942970527],
              [-38.052699770505271, -8.406811671652365],
              [-38.0526925592578, -8.407200646065684],
              [-38.052638483087676, -8.407476907149396],
              [-38.052492063126998, -8.407892045913028],
              [-38.052454315998993, -8.408213657777818],
              [-38.052505442211739, -8.408479047745198],
              [-38.052605673692966, -8.408645250461538],
              [-38.052999940362213, -8.408910742949818],
              [-38.053416708211103, -8.409148794812783],
              [-38.053771376700631, -8.409488806163628],
              [-38.054067889154823, -8.409952673669745],
              [-38.054177705179711, -8.41021196414947],
              [-38.054145734279231, -8.410474169635116],
              [-38.053990623985925, -8.410690134358351],
              [-38.053743802593999, -8.410821405395099],
              [-38.053320035641171, -8.410959033607579],
              [-38.052935338660376, -8.411127957945297],
              [-38.052774192518207, -8.411285753243781],
              [-38.052677497243863, -8.411495707929223],
              [-38.052686368806278, -8.411694792465902],
              [-38.052730567197436, -8.412008009635151],
              [-38.05273251058766, -8.412254921379096],
              [-38.052669821152328, -8.412449673206728],
              [-38.052513990992608, -8.412811699473767],
              [-38.052455833519943, -8.412935465081613],
              [-38.052383017605166, -8.413260609987764],
              [-38.052349452006638, -8.413393479038028],
              [-38.0521790715569, -8.413575761510458],
              [-38.051909611261934, -8.41360342031666],
              [-38.051564071134791, -8.413580066977641],
              [-38.051272505888868, -8.413621690552755],
              [-38.050984578859911, -8.413698504660839],
              [-38.050525481525021, -8.413722087100608],
              [-38.050215267906758, -8.413812774887051],
              [-38.049865635054289, -8.413978072313698],
              [-38.049699604789033, -8.414089186160675],
              [-38.049666219631362, -8.414222145747406],
              [-38.049741495959182, -8.414379154211563],
              [-38.049930306085123, -8.414834443622064],
              [-38.049993150652412, -8.415098595864844],
              [-38.050059375664041, -8.415280092222764],
              [-38.050266753720365, -8.415682336909729],
              [-38.050309539395293, -8.415866308996922],
              [-38.050301870335289, -8.416143495412731],
              [-38.050216361313261, -8.416458208102755],
              [-38.050111581957772, -8.416704409548343],
              [-38.050143800577402, -8.41690110844371],
              [-38.050277857743986, -8.417052108838565],
              [-38.050537270263533, -8.417272419313576],
              [-38.050694511602664, -8.417414069620675],
              [-38.050841881588163, -8.417693077551496],
              [-38.050996438768671, -8.418041924084061],
              [-38.051093109436685, -8.418290873208823],
              [-38.051090169275689, -8.418491195313745],
              [-38.050965421607167, -8.418657036186749],
              [-38.050690621653466, -8.418861766431444],
              [-38.050332994001145, -8.419063221272483],
              [-38.050150359663469, -8.41912898346799],
              [-38.049755888880057, -8.419204905558651],
              [-38.049583821754716, -8.419257850255418],
              [-38.049454100488205, -8.41937710087015],
              [-38.04946536555105, -8.419599525076627],
              [-38.049542994336001, -8.419797780898728],
              [-38.049844481086836, -8.420308060457643],
              [-38.050118708470571, -8.420785805263844],
              [-38.050235361483352, -8.420997450323878],
              [-38.050272462276254, -8.421240738667548],
              [-38.050170077238853, -8.421510098885134],
              [-38.049808884048694, -8.421794389209232],
              [-38.049506428093032, -8.422090397837112],
              [-38.049222027040344, -8.422543276326948],
              [-38.049057932835126, -8.422901391247331],
              [-38.048884604743655, -8.423283993483306],
              [-38.048884149561914, -8.423507565005943],
              [-38.048937321080125, -8.423678629137157],
              [-38.049054596253896, -8.42378427802144],
              [-38.04922097986443, -8.423790740434448],
              [-38.049506518909674, -8.423690769570763],
              [-38.049610406306783, -8.423550654946645],
              [-38.049709062786832, -8.423246375361662],
              [-38.049775303961134, -8.423086543672982],
              [-38.049909817627153, -8.423013882333517],
              [-38.050071318772048, -8.422973845338232],
              [-38.050204938277091, -8.423007089348431],
              [-38.050285992412874, -8.423104691400523],
              [-38.05031821128965, -8.423301390286957],
              [-38.050427678349031, -8.423443106286561],
              [-38.050550017379265, -8.423595435097164],
              [-38.050665293011107, -8.423776326492538],
              [-38.050753534578526, -8.423943676663367],
              [-38.050828736068709, -8.423983110253905],
              [-38.050972923677911, -8.424003446371792],
              [-38.051118262994457, -8.424035723571926],
              [-38.051293077290218, -8.424123603342084],
              [-38.051399527290478, -8.424124493553014],
              [-38.051595127349039, -8.424069072728431],
              [-38.051821626411773, -8.423857442495343],
              [-38.051942918100622, -8.423656772992997],
              [-38.052072199820387, -8.423419945888249],
              [-38.052262826169546, -8.423318025445104],
              [-38.052495890277818, -8.423282320437712],
              [-38.052861240004127, -8.423268278622022],
              [-38.053259612786668, -8.423344939054253],
              [-38.05356938087305, -8.423478002380284],
              [-38.05381672900134, -8.423582064085577],
              [-38.0540504936532, -8.423781509381053],
              [-38.054329713024089, -8.423964603538336],
              [-38.054520237101109, -8.424204010354531],
              [-38.054654648917193, -8.424472494851994],
              [-38.054700092284179, -8.424797472404771],
              [-38.054741155847204, -8.425280351388865],
              [-38.054764921740251, -8.42585453528983],
              [-38.054846057510979, -8.426293374958464],
              [-38.054959304870607, -8.426674861153138],
              [-38.055128877112359, -8.426939993406767],
              [-38.055620913060842, -8.427348347766403],
              [-38.055847311057811, -8.427478043675428],
              [-38.056125822911945, -8.427767042970572],
              [-38.056392965371614, -8.428175036674071],
              [-38.056761296474825, -8.428506940202814],
              [-38.057321971283535, -8.428778890019178],
              [-38.057756043845927, -8.428969589470524],
              [-38.058106390611371, -8.429039528129044],
              [-38.058425047338602, -8.429030343978187],
              [-38.058738469825158, -8.428856903938739],
              [-38.05893708709602, -8.428718913091934],
              [-38.059073997469106, -8.428669588499213],
              [-38.059313191608624, -8.428691958341963],
              [-38.059439704173045, -8.428773207295503],
              [-38.059460207958558, -8.428971053604791],
              [-38.059414212250637, -8.429211157981543],
              [-38.059365547738672, -8.429428012293579],
              [-38.059230503320457, -8.430057980560564],
              [-38.059099880706391, -8.430624559316199],
              [-38.059052810880665, -8.430970568852691],
              [-38.059102031910093, -8.431312558463858],
              [-38.059383998903868, -8.431977894334874],
              [-38.059634805032665, -8.432458020162201],
              [-38.059932721110222, -8.432913929798318],
              [-38.060166754638459, -8.433231219181806],
              [-38.06034876378169, -8.433389024632254],
              [-38.060450957709044, -8.433460899632129],
              [-38.060562475673443, -8.433508287086472],
              [-38.060753014879744, -8.433406272211746],
              [-38.06096459782475, -8.43327871244071],
              [-38.06127713142012, -8.433211266865333],
              [-38.061520768741772, -8.4331627395586],
              [-38.061623582111331, -8.433128526962491],
              [-38.06183295015456, -8.43297762726846],
              [-38.062002626085317, -8.43290142832811],
              [-38.062160843417409, -8.43294395264609],
              [-38.062334863819544, -8.433137915071311],
              [-38.062429057470318, -8.433363612813764],
              [-38.06266104991424, -8.43377504650476],
              [-38.062825746823577, -8.433993586800408],
              [-38.063058419973061, -8.434160465957335],
              [-38.063243717984953, -8.434235704253759],
              [-38.06357525259169, -8.434236857539389],
              [-38.063719353642291, -8.43425746056394],
              [-38.06377821683445, -8.434369025989037],
              [-38.063756725090357, -8.434618414624993],
              [-38.063750684979091, -8.434901303791886],
              [-38.063844925958051, -8.435107827648634],
              [-38.064038657938376, -8.435264393410543],
              [-38.064294369491456, -8.435332292578819],
              [-38.064520422292219, -8.435344408376755],
              [-38.065023496515607, -8.435174943121142],
              [-38.065439208111187, -8.435073372141867],
              [-38.065627703619434, -8.435065771813978],
              [-38.06582702904582, -8.435162929452726],
              [-38.065983648498076, -8.435417535929897],
              [-38.066174984947253, -8.435550851346575],
              [-38.066354247833843, -8.435567738597877],
              [-38.066469054008884, -8.435532467995399],
              [-38.066528273189988, -8.435420460371265],
              [-38.066553494421498, -8.435205991045656],
              [-38.0665927413745, -8.435013713473824],
              [-38.066649579062478, -8.434758529840732],
              [-38.06659524570442, -8.434575797369749],
              [-38.066437735636278, -8.434427277923954],
              [-38.066204584278452, -8.434345324863559],
              [-38.065861329210158, -8.434345321632486],
              [-38.065633942195298, -8.434321626863305],
              [-38.065542405551206, -8.434236754775249],
              [-38.06554276081205, -8.434013272527274],
              [-38.065567538207588, -8.433681227158239],
              [-38.065544820181472, -8.433231674458469],
              [-38.065509046286444, -8.432658727259257],
              [-38.065448769709135, -8.432076677458236],
              [-38.065375168257908, -8.431596705358389],
              [-38.065184371307701, -8.431239727059516],
              [-38.064888184108888, -8.430893622006407],
              [-38.064607976136188, -8.430475110532594],
              [-38.06447223144081, -8.430077292811223],
              [-38.064460344644928, -8.429732226869863],
              [-38.064517613352898, -8.429373397622973],
              [-38.064701326254365, -8.428860213194422],
              [-38.064837976341359, -8.428351981279176],
              [-38.064899328490924, -8.428145737915857],
              [-38.064997715084466, -8.427382457649589],
              [-38.06504497426107, -8.427030749305967],
              [-38.065232942648286, -8.426446667671096],
              [-38.065427928290759, -8.42581475853868],
              [-38.065506963278104, -8.425317513395536],
              [-38.065535562683003, -8.424679148479166],
              [-38.065495439242376, -8.4241773689513],
              [-38.065506361335878, -8.423940888027607],
              [-38.065596926413768, -8.423672670402338],
              [-38.065988033422435, -8.423220664955416],
              [-38.066344829763572, -8.422758719237438],
              [-38.066540873023847, -8.422479627545934],
              [-38.066737178818407, -8.422318292418902],
              [-38.066905514877604, -8.422230330016639],
              [-38.066978321548341, -8.422246420815279],
              [-38.067115401173901, -8.422314759093382],
              [-38.067175689287708, -8.42243799444738],
              [-38.067221497649477, -8.422880457303926],
              [-38.067194236026445, -8.423189163125244],
              [-38.067060786876766, -8.423614739712924],
              [-38.066973691130599, -8.423800120742298],
              [-38.066947490949531, -8.424120586544433],
              [-38.066974925234554, -8.424270695740754],
              [-38.067103308614669, -8.424481188966602],
              [-38.06728780499094, -8.424662150306437],
              [-38.067411659012222, -8.424943539588043],
              [-38.067449923444656, -8.425198587738555],
              [-38.067377932432606, -8.425638057545358],
              [-38.067290826612762, -8.42628243399747],
              [-38.067269338116212, -8.426531823221572],
              [-38.067309644998936, -8.427033784100031],
              [-38.06744894128397, -8.427807850105635],
              [-38.067536323829806, -8.428299341027699],
              [-38.067533300559056, -8.428840903197678],
              [-38.067507989673864, -8.429396611868059],
              [-38.067472028298937, -8.430075658691266],
              [-38.067458609429657, -8.430747794972845],
              [-38.067593501047213, -8.43135136650614],
              [-38.06772348446296, -8.431691105780141],
              [-38.067865896463104, -8.431923609823864],
              [-38.06815250059406, -8.432176534503318],
              [-38.068462545654107, -8.432426982272048],
              [-38.06892139467395, -8.432744619945463],
              [-38.069496384271289, -8.433038655799473],
              [-38.069839902164553, -8.433156322015742],
              [-38.070074124724997, -8.433132276871236],
              [-38.070270526370493, -8.432970759820082],
              [-38.070338712813346, -8.432716597096491],
              [-38.070377957117941, -8.432524499775063],
              [-38.070459643967418, -8.432398345128869],
              [-38.070588475294272, -8.432385083928306],
              [-38.0707571698142, -8.432414877280371],
              [-38.070985973522774, -8.432567814698336],
              [-38.071165494658317, -8.432666369831248],
              [-38.071419780852509, -8.432722593853129],
              [-38.071685522792173, -8.432660093783113],
              [-38.071958009930512, -8.432549584491685],
              [-38.07232124118061, -8.432171127398165],
              [-38.072691126040674, -8.431744660612049],
              [-38.073012450844878, -8.431417565501535],
              [-38.073131338260929, -8.431193548745505],
              [-38.073208845604995, -8.430914897345717],
              [-38.073216569356212, -8.430761072768131],
              [-38.073184247452019, -8.430564464727052],
              [-38.073112153097014, -8.430324802536253],
              [-38.072948695099875, -8.430117936088219],
              [-38.072751247145611, -8.429766642954407],
              [-38.072458512975281, -8.429455371538157],
              [-38.072273929070576, -8.429156746200936],
              [-38.072199166168595, -8.428893743426071],
              [-38.072252261877082, -8.428605899809611],
              [-38.072433208193388, -8.428410790583467],
              [-38.072533804455865, -8.428353235462794],
              [-38.072855655885668, -8.428261382529184],
              [-38.073192777211936, -8.428203300449344],
              [-38.073561773910363, -8.428224159234567],
              [-38.07397312164008, -8.428311412008398],
              [-38.074298970238935, -8.428371963080325],
              [-38.074647993824605, -8.428312460722024],
              [-38.074955727579059, -8.428198595173113],
              [-38.075210808572606, -8.428148819604507],
              [-38.075470865391566, -8.428145633541426],
              [-38.075688836095352, -8.428193900525468],
              [-38.075860017745192, -8.428246850712934],
              [-38.075985297346655, -8.428198668347163],
              [-38.076093971870662, -8.428105044645783],
              [-38.076220935335336, -8.427844868563849],
              [-38.07636911612699, -8.427676812969075],
              [-38.07662682236095, -8.427400845476647],
              [-38.076747125796224, -8.427305982702839],
              [-38.076981078838607, -8.427164266446587],
              [-38.077101290860782, -8.427069584216705],
              [-38.077133787637578, -8.426925042745413],
              [-38.077059469294497, -8.42677970754535],
              [-38.076974766122149, -8.426647100342151],
              [-38.076974409079611, -8.426529524012576],
              [-38.077025726592616, -8.426453672787767],
              [-38.077132359932001, -8.426454466082834],
              [-38.077326890040432, -8.426505118941403],
              [-38.077603933256455, -8.426539414540098],
              [-38.077788965333497, -8.426496888881532],
              [-38.077936083035127, -8.42631698206657],
              [-38.077958717680346, -8.426079351774193],
              [-38.077927728716929, -8.42589423322036],
              [-38.077892211355106, -8.425780282445585],
              [-38.077804132782788, -8.425730513003332],
              [-38.077659942484914, -8.425710005632533],
              [-38.077314387658298, -8.425686673612132],
              [-38.077062592823793, -8.425653703413991],
              [-38.076839556170285, -8.425558937869049],
              [-38.076663133736567, -8.425341641536081],
              [-38.076616517764684, -8.425005356477882],
              [-38.076608703409406, -8.424700184489685],
              [-38.076683294861759, -8.424500300638986],
              [-38.076798893550894, -8.424358939136166],
              [-38.076966075396427, -8.424259392903846],
              [-38.077077236438491, -8.424189108830808],
              [-38.077254098053231, -8.42418265074658],
              [-38.077402724742669, -8.424132261545742],
              [-38.077470017785686, -8.423984003128467],
              [-38.077453146037158, -8.42382107618139],
              [-38.077359211404186, -8.423712957386391],
              [-38.076951151409666, -8.423543051674978],
              [-38.076739926561245, -8.42344713819633],
              [-38.076536780599305, -8.423315066532746],
              [-38.076459094087404, -8.423134902935972],
              [-38.07641753927328, -8.42296260235589],
              [-38.076531983044511, -8.422580298837607],
              [-38.076731030892631, -8.422218634151054],
              [-38.076895548351516, -8.421978171849561],
              [-38.077034494986101, -8.421834423079281],
              [-38.077064594040849, -8.421666541680811],
              [-38.076950679482621, -8.421595909628932],
              [-38.076758457862553, -8.421568686920233],
              [-38.076409622640256, -8.421628011405053],
              [-38.076222286637048, -8.421647197020986],
              [-38.076103934908524, -8.421647551815195],
              [-38.075978391228688, -8.421578248792787],
              [-38.075843965329547, -8.421309498731265],
              [-38.075809335931311, -8.421089641560142],
              [-38.07585230822059, -8.420932281929611],
              [-38.075959198044529, -8.420709411224976],
              [-38.076012334458547, -8.420555693173737],
              [-38.076012068340873, -8.420438117052859],
              [-38.075972023928585, -8.420395062607144],
              [-38.075865573726809, -8.420394360045551],
              [-38.07561156043564, -8.420455715779132],
              [-38.075316349444961, -8.420462437458303],
              [-38.074936883986084, -8.420454399294085],
              [-38.074445105250177, -8.42050497438648],
              [-38.074229626731686, -8.420480047239023],
              [-38.074092182666675, -8.420411800957856],
              [-38.074008634765839, -8.420290863148898],
              [-38.073995405471095, -8.42016285563628],
              [-38.073984482693575, -8.420058006874966],
              [-38.074069805758292, -8.419967041755527],
              [-38.074213546594741, -8.41986988326339],
              [-38.074380727358864, -8.419770157087889],
              [-38.074464715771548, -8.419667431175998],
              [-38.074544000860499, -8.419518116196153],
              [-38.074548878836374, -8.419223465505292],
              [-38.074622125994182, -8.41901580075821],
              [-38.074780870431638, -8.418834836979649],
              [-38.074916531893123, -8.418773836208745],
              [-38.075233846559009, -8.418752967207121],
              [-38.075418434788183, -8.418704562467333],
              [-38.075506154869373, -8.41863666548665],
              [-38.075572209721699, -8.418359252260366],
              [-38.075665429290787, -8.418232037762518],
              [-38.075799846501518, -8.418159366614203],
              [-38.075983539842781, -8.418105171133226],
              [-38.076295263244994, -8.418143799365565],
              [-38.076556648219103, -8.418152282106346],
              [-38.076909031126156, -8.418127695486938],
              [-38.07721107502806, -8.418073144259674],
              [-38.077642650415761, -8.417899331489107],
              [-38.078017143222418, -8.41774320152825],
              [-38.07831998241101, -8.417582652352829],
              [-38.078473043835231, -8.417461003952754],
              [-38.078496921275359, -8.417234953023614],
              [-38.078409821693732, -8.417079187269232],
              [-38.07830283334377, -8.416960727727611],
              [-38.07813724647616, -8.41684828085077],
              [-38.077914486787989, -8.416753517028692],
              [-38.077599213470329, -8.416680061392327],
              [-38.07725321833211, -8.416539153562461],
              [-38.076911751773494, -8.416327077659467],
              [-38.076566196074637, -8.415962595904682],
              [-38.076156543401481, -8.415663353374802],
              [-38.075981368765312, -8.415457908069682],
              [-38.07551585606533, -8.414853018237508],
              [-38.075380544918431, -8.414690355411699],
              [-38.075191345435108, -8.414462718393231],
              [-38.075123955271991, -8.414269555174377],
              [-38.075142152607164, -8.414103002531688],
              [-38.075276659429612, -8.414030241175231],
              [-38.075478108447349, -8.414033156823125],
              [-38.075775712017041, -8.414049593462703],
              [-38.076120103034896, -8.414061346348069],
              [-38.076521982205129, -8.413922194591741],
              [-38.076798095685028, -8.413846690161666],
              [-38.076985518938969, -8.413827413626734],
              [-38.077346424286958, -8.413884425559992],
              [-38.077594661166742, -8.41388247554503],
              [-38.077903626654305, -8.413780185872906],
              [-38.077986459806532, -8.413665698674437],
              [-38.078020812545333, -8.413426829424386],
              [-38.07820086200649, -8.413337713171956],
              [-38.078627380970595, -8.413340973800173],
              [-38.079547167869869, -8.41335153895262],
              [-38.079904431665362, -8.413373719523884],
              [-38.08010162634239, -8.413447439544447],
              [-38.080203995999462, -8.41363706627074],
              [-38.08029971375349, -8.413874340789139],
              [-38.080398533275222, -8.414028867363129],
              [-38.080486518931323, -8.414078635791514],
              [-38.080837835412147, -8.41404255649568],
              [-38.0811939465626, -8.414053156590064],
              [-38.08154641672165, -8.414028565589653],
              [-38.081922234835439, -8.414001767709937],
              [-38.082137983675288, -8.414026510065966],
              [-38.082413569761584, -8.414174846466471],
              [-38.082715175259231, -8.414343773928836],
              [-38.082965106768896, -8.414471157830038],
              [-38.083173390461191, -8.414426150771867],
              [-38.083225949155285, -8.414361968423909],
              [-38.083281699932364, -8.4142149478179],
              [-38.083267223395112, -8.414075270238513],
              [-38.083209419440749, -8.413975286516804],
              [-38.08311912654036, -8.413902269460605],
              [-38.083110689566951, -8.413820760742437],
              [-38.083151437855015, -8.413757726614197],
              [-38.083301213651119, -8.413718823584256],
              [-38.083884343273859, -8.413635256649547],
              [-38.08418753093973, -8.413556101769455],
              [-38.084331357732133, -8.413459029673447],
              [-38.084324867744343, -8.413283193518133],
              [-38.084245844377612, -8.413091180067465],
              [-38.084149330338661, -8.412959903518148],
              [-38.084065871045766, -8.412839058291732],
              [-38.084092501652826, -8.412754013525857],
              [-38.084256034739454, -8.412619543947537],
              [-38.084560996905083, -8.412364481311196],
              [-38.084619325832378, -8.412240891139394],
              [-38.084578830493335, -8.412080260676614],
              [-38.084501410720371, -8.412017674849444],
              [-38.084407563285396, -8.412027314670112],
              [-38.084260636287311, -8.412207134918432],
              [-38.084065586520026, -8.412380150249838],
              [-38.083929837442206, -8.412441244822501],
              [-38.083734068779286, -8.412378925825392],
              [-38.083461454980856, -8.411932316632994],
              [-38.08328752218236, -8.411738363459985],
              [-38.083092547951608, -8.411570137403171],
              [-38.082990445330474, -8.411498268596974],
              [-38.082958478280723, -8.411419237417222],
              [-38.082969929407604, -8.4113004221448],
              [-38.083087737606206, -8.411182307024193],
              [-38.083140386857465, -8.411118034401117],
              [-38.08314925836202, -8.410976059718342],
              [-38.083074940261206, -8.41083063518305],
              [-38.082957658045025, -8.410725086243206],
              [-38.082817908750933, -8.410633503540343],
              [-38.082566031831163, -8.41060053762825],
              [-38.082221552011696, -8.410588880925125],
              [-38.082005987119018, -8.410563958353563],
              [-38.081854518317392, -8.410473704631251],
              [-38.081702781854517, -8.410265693611665],
              [-38.081449691416175, -8.410015389974097],
              [-38.081268745190201, -8.409869173541708],
              [-38.081160604152991, -8.409739135474116],
              [-38.081109725970308, -8.409591323447914],
              [-38.081114689090896, -8.409296581749755],
              [-38.081084852117037, -8.409123223536371],
              [-38.080953454278159, -8.408995482631228],
              [-38.080713116385155, -8.40896136685396],
              [-38.080493207013014, -8.409007521485021],
              [-38.080147933969037, -8.409101860274985],
              [-38.079754538677889, -8.409189393419302],
              [-38.079590647423906, -8.409206284623894],
              [-38.079336374883184, -8.409149886471505],
              [-38.07908280614059, -8.408987762158837],
              [-38.078894760113506, -8.408771706301982],
              [-38.078697829063081, -8.40835665539224],
              [-38.078554312811946, -8.408130302757202],
              [-38.07853379958344, -8.407932275416801],
              [-38.078600207615388, -8.407772528037279],
              [-38.078804488260545, -8.407575026996533],
              [-38.079037184037404, -8.407421637981848],
              [-38.079170261517987, -8.407337475808045],
              [-38.079403406240814, -8.407301663275577],
              [-38.079546345954775, -8.407310499266291],
              [-38.079710594671432, -8.407411274963046],
              [-38.079864100523203, -8.407407292669916],
              [-38.080007835174456, -8.407310131342188],
              [-38.080077611573124, -8.407185302447349],
              [-38.080144109666605, -8.407025554931703],
              [-38.080086216799195, -8.406925480259339],
              [-38.079891604855014, -8.406874829518769],
              [-38.079664413390631, -8.406851235350187],
              [-38.079304308910658, -8.40668822905155],
              [-38.078998274373248, -8.406590286939588],
              [-38.078707868308371, -8.406643601580791],
              [-38.078464430558576, -8.406692142071252],
              [-38.078262984924194, -8.406689318801369],
              [-38.078145614677361, -8.406583678024106],
              [-38.078115779123891, -8.406410319726479],
              [-38.078131328906252, -8.406133329840831],
              [-38.07808284940085, -8.40600885742902],
              [-38.077991315491488, -8.40592407899053],
              [-38.077714668351447, -8.405882008020432],
              [-38.077209678223632, -8.405804400207163],
              [-38.076929747734219, -8.405844985702849],
              [-38.076693592888475, -8.405963364027942],
              [-38.076555010003638, -8.406224689824565],
              [-38.076528826359961, -8.406427491509612],
              [-38.07655306487198, -8.40677801409405],
              [-38.076511877953308, -8.407064711444637],
              [-38.076461368933117, -8.407375806447581],
              [-38.076389012478614, -8.407477384730127],
              [-38.076270574222654, -8.407477739826456],
              [-38.07616057737637, -8.407442028047363],
              [-38.076063976854314, -8.407310659264828],
              [-38.075992240565846, -8.407188664450045],
              [-38.075823465073213, -8.407158964676841],
              [-38.075624416594891, -8.407179389445787],
              [-38.07547917077666, -8.407147302843525],
              [-38.075326461301714, -8.407045286421223],
              [-38.075248505535868, -8.406865031766593],
              [-38.07514374243172, -8.406652245165388],
              [-38.075049454799668, -8.406426640298529],
              [-38.074870819171004, -8.406303670536191],
              [-38.074573046271325, -8.40616965790495],
              [-38.074225460891626, -8.405899411625303],
              [-38.074020905807693, -8.405637912777548],
              [-38.073862635777999, -8.405391717131971],
              [-38.073618303694694, -8.405205011084451],
              [-38.073406732865756, -8.404991430106895],
              [-38.073408416857234, -8.404779436394998],
              [-38.073472429328703, -8.404596350166676],
              [-38.073569752808275, -8.404422477028863],
              [-38.074801228016241, -8.403629296353635],
              [-38.075116691969164, -8.403540951491422],
              [-38.075535378765935, -8.403356708111989],
              [-38.075970312220342, -8.403100238605036],
              [-38.076191286903338, -8.402947999322505],
              [-38.076387936136875, -8.402904234166328],
              [-38.076529720939504, -8.402901401249622],
              [-38.076880049995509, -8.402971321077915],
              [-38.07736897151532, -8.403121064629973],
              [-38.077764933916313, -8.403174630051536],
              [-38.078423067465444, -8.403130407061838],
              [-38.078841048185907, -8.40305224774424],
              [-38.079067878936236, -8.402958265616157],
              [-38.079325014222761, -8.402761882462327],
              [-38.079490941427473, -8.402650754385391],
              [-38.07964195709566, -8.40262352234461],
              [-38.079769983910673, -8.402716163748215],
              [-38.080003660737553, -8.402915865020457],
              [-38.080241597558377, -8.402926640972829],
              [-38.080502250791888, -8.402817452137226],
              [-38.080601326712127, -8.402630738070409],
              [-38.080620674112943, -8.402475764148324],
              [-38.080561541780384, -8.402246534252869],
              [-38.080449852071226, -8.402081576950296],
              [-38.080434132684822, -8.401930138859845],
              [-38.080459160102478, -8.40171584767773],
              [-38.080575185916189, -8.401350729346772],
              [-38.080614329617433, -8.401158629601683],
              [-38.080556351076211, -8.40094088865229],
              [-38.08034256265892, -8.400703881317106],
              [-38.080149991932913, -8.400559084741902],
              [-38.079964704873021, -8.400483946115722],
              [-38.079705460979135, -8.400381139965734],
              [-38.079611530176898, -8.400273203007734],
              [-38.079463355767075, -8.399750458716065],
              [-38.079410249998631, -8.399238247087894],
              [-38.079456318694831, -8.398998228939943],
              [-38.07959773032384, -8.398536486752244],
              [-38.079718908741008, -8.398335715351168],
              [-38.080029899729404, -8.398139095373475],
              [-38.080313016320822, -8.398015849672671],
              [-38.080499096788976, -8.397984990801952],
              [-38.080842321230428, -8.397984977779961],
              [-38.081075369404779, -8.39794916341007],
              [-38.081168581719872, -8.397821946758254],
              [-38.081127026039454, -8.397649736965711],
              [-38.080994822871993, -8.397286662066877],
              [-38.080858001906265, -8.396994845385306],
              [-38.0807120243119, -8.396734662328017],
              [-38.080559407635555, -8.396632738828808],
              [-38.080187683720439, -8.396470792410918],
              [-38.080006834155284, -8.396324757123045],
              [-38.079826076190592, -8.396178450650556],
              [-38.079672215940185, -8.396064856815462],
              [-38.079350034179349, -8.396039141941381],
              [-38.079084141288625, -8.395984164433246],
              [-38.078837414322884, -8.395774212482971],
              [-38.078780231889169, -8.395450564665177],
              [-38.078984067791829, -8.395130421142655],
              [-38.079407357041291, -8.394992670821802],
              [-38.079906476986402, -8.395012015494535],
              [-38.080144317970579, -8.395022881220822],
              [-38.080580225453794, -8.395119225298762],
              [-38.080828632239431, -8.395117272377009],
              [-38.080915010643267, -8.39503779362804],
              [-38.080946257998548, -8.394881581130738],
              [-38.080904793413538, -8.394709371545495],
              [-38.080798872060022, -8.394602582743484],
              [-38.080595035185283, -8.394576421471825],
              [-38.080313959721344, -8.394605340477222],
              [-38.080135958236738, -8.394600131571856],
              [-38.079695075302027, -8.394457106919953],
              [-38.079239104604952, -8.394280401805567],
              [-38.078932988439625, -8.39418255086329],
              [-38.078706599412754, -8.39405286929828],
              [-38.078630682995701, -8.393778197715418],
              [-38.078660419506249, -8.393492648633824],
              [-38.078781414455719, -8.393292057813557],
              [-38.078926387990272, -8.393206475877319],
              [-38.079151446321447, -8.393206911282443],
              [-38.079493331363345, -8.393195319173074],
              [-38.079749099327465, -8.3931523229164],
              [-38.079958438462576, -8.393001319729937],
              [-38.080117169400864, -8.392820080824668],
              [-38.080253973980867, -8.392770746894653],
              [-38.080453015045236, -8.392750318951277],
              [-38.080870190297922, -8.392778063018506],
              [-38.081122055621798, -8.392811028792766],
              [-38.081421681722063, -8.392733222662212],
              [-38.081551294015455, -8.392613868658323],
              [-38.081567089244118, -8.392423975265929],
              [-38.081525624733985, -8.392251675280942],
              [-38.081391920809523, -8.392218442504964],
              [-38.080936488444166, -8.392276982860832],
              [-38.080575960644133, -8.392337733783249],
              [-38.080035303659741, -8.392487331183309],
              [-38.079756717810781, -8.392539590028143],
              [-38.079538765804266, -8.392491327308713],
              [-38.079285116419996, -8.39232911349449],
              [-38.079174764520879, -8.392175826363248],
              [-38.079144657078835, -8.392002196124276],
              [-38.079243371467953, -8.391697905468465],
              [-38.079319003398183, -8.391283582296879],
              [-38.079238201347316, -8.390962321302549],
              [-38.079170185016039, -8.390534005768099],
              [-38.079142388257942, -8.390266229865563],
              [-38.079347802776709, -8.389621308960621],
              [-38.079456986075336, -8.389190512941518],
              [-38.079474552500315, -8.388788625635749],
              [-38.079458565288562, -8.388519611087407],
              [-38.079301951723323, -8.388265100909228],
              [-38.079007200558017, -8.388048254157402],
              [-38.078730479553087, -8.387888518803768],
              [-38.078401907517566, -8.387686877508237],
              [-38.077874297065705, -8.387505843881929],
              [-38.077457127956102, -8.387478097267953],
              [-38.077018037776284, -8.387464408008993],
              [-38.076556481562839, -8.387464774751166],
              [-38.076225962369975, -8.387357640006073],
              [-38.075768843156261, -8.387286837723057],
              [-38.075531006460366, -8.387199455242609],
              [-38.075340932138069, -8.387077816326075],
              [-38.075237326199002, -8.386876699935433],
              [-38.075246290509725, -8.386734454394754],
              [-38.075354146063333, -8.386405676238082],
              [-38.075353430807112, -8.386170342329589],
              [-38.075243605067115, -8.385911056641874],
              [-38.075104934103308, -8.385713475758683],
              [-38.075092857676971, -8.385597047534096],
              [-38.075269343614103, -8.385473102369202],
              [-38.075475214690456, -8.385404848317101],
              [-38.075577042728952, -8.385358961084195],
              [-38.075515426666577, -8.385106571639071],
              [-38.07537408632448, -8.384885650352853],
              [-38.0751124564446, -8.384759683369571],
              [-38.074946727463256, -8.384674730799366],
              [-38.074720078494416, -8.384427380891808],
              [-38.074423117590818, -8.3841871918208],
              [-38.074241560910416, -8.383805820781852],
              [-38.07395772900086, -8.383352578989735],
              [-38.073556278719082, -8.383017179240959],
              [-38.072911929237542, -8.382624591430941],
              [-38.072448784958176, -8.382377951034172],
              [-38.0721506783038, -8.382047676854869],
              [-38.071655298448839, -8.381263096792191],
              [-38.0713239818988, -8.380920715139018],
              [-38.070818214299898, -8.380266618748616],
              [-38.070671719160401, -8.38011478231199],
              [-38.070496562000059, -8.379909155362432],
              [-38.070124410699087, -8.379629805787363],
              [-38.069951650941782, -8.379447428071435],
              [-38.069808009365346, -8.379203346524275],
              [-38.069744357587965, -8.379045102676066],
              [-38.069687177322095, -8.378609486249923],
              [-38.069585875711297, -8.378090377668006],
              [-38.069388254719662, -8.377439990592048],
              [-38.069184778688481, -8.376731344557028],
              [-38.069168613414213, -8.376349005092086],
              [-38.069210331432444, -8.375838644707056],
              [-38.069269539071257, -8.37526790976605],
              [-38.069250358670509, -8.374740221708704],
              [-38.069234825068172, -8.374015467528258],
              [-38.06925506200681, -8.373754407045405],
              [-38.069417512545314, -8.37326712251298],
              [-38.069687378077077, -8.372674453669937],
              [-38.070046643102074, -8.372143315143864],
              [-38.07048651006469, -8.371592106600772],
              [-38.070796815002751, -8.371140622510058],
              [-38.071185899509693, -8.370441690883839],
              [-38.071443336306707, -8.369956346662267],
              [-38.071678664533053, -8.369602634998378],
              [-38.071917814782786, -8.369283843044297],
              [-38.072000456633951, -8.369169536473823],
              [-38.072177646631317, -8.368821838010756],
              [-38.072381550353882, -8.368506671094156],
              [-38.07252411819762, -8.368397841124814],
              [-38.072676273807716, -8.368382189711642],
              [-38.07274960445406, -8.368633521498886],
              [-38.072826319548199, -8.369143437298929],
              [-38.07283777549376, -8.369483529247104],
              [-38.072680930563401, -8.370252739359204],
              [-38.072517598826565, -8.370846113239391],
              [-38.072342986682415, -8.371217061610601],
              [-38.072255816700938, -8.371402536071001],
              [-38.072107744967617, -8.371802488287008],
              [-38.07204189012544, -8.372421143324976],
              [-38.07202591054984, -8.372611035685845],
              [-38.071754447659167, -8.373074369560918],
              [-38.071687073049418, -8.373222446998069],
              [-38.071662401130929, -8.373554494958613],
              [-38.071771610189742, -8.374148598947317],
              [-38.071758475077615, -8.374479407810471],
              [-38.071704328365058, -8.374755673728588],
              [-38.071661718237614, -8.375030790985365],
              [-38.071572690719165, -8.375769499258352],
              [-38.071551753955589, -8.376824374587411],
              [-38.071612570060374, -8.377182942280134],
              [-38.071762340661138, -8.377485282791216],
              [-38.071978509663197, -8.377745361657599],
              [-38.072281272282339, -8.378025812635929],
              [-38.072508454957806, -8.378390830788641],
              [-38.07282929594917, -8.378746120092384],
              [-38.073257819808461, -8.379113962194578],
              [-38.073481363219656, -8.379443970021597],
              [-38.073966057982787, -8.379870459820005],
              [-38.074174600570309, -8.380284272659269],
              [-38.07437435023671, -8.380499181031874],
              [-38.07488011383191, -8.38081194224335],
              [-38.076068836941708, -8.381397630717291],
              [-38.076283499330877, -8.381528551765527],
              [-38.076281460323266, -8.381622878906294],
              [-38.076236727477415, -8.381874657598971],
              [-38.076211427557524, -8.382089038417547],
              [-38.076241170537635, -8.382262487007967],
              [-38.076356676457088, -8.382462364756382],
              [-38.076638759660327, -8.383003421005895],
              [-38.076929337190307, -8.383408923120111],
              [-38.077362755309863, -8.38382335116672],
              [-38.077811945067808, -8.384135796101747],
              [-38.078169990374732, -8.384393128821204],
              [-38.079045795249975, -8.385079736490379],
              [-38.079225486239828, -8.385214192314553],
              [-38.079545620585321, -8.385334324005671],
              [-38.079864870324514, -8.38556036207588],
              [-38.08016152110487, -8.385664068369373],
              [-38.08084032099886, -8.385817956431719],
              [-38.081148196991485, -8.385821477373785],
              [-38.081693821068853, -8.38571837663596],
              [-38.082102279851824, -8.385664609200109],
              [-38.082308782432804, -8.385714019275929],
              [-38.082641790776059, -8.385844489043857],
              [-38.082926949070661, -8.386085820895266],
              [-38.083362187053744, -8.386386651075512],
              [-38.083682593471707, -8.386624356204837],
              [-38.083794822727882, -8.386906980029769],
              [-38.083759233393629, -8.387134180692179],
              [-38.083581773590254, -8.38736430769741],
              [-38.083208738902847, -8.387532026588049],
              [-38.082728989744687, -8.387699135152642],
              [-38.0824951546752, -8.387840767650568],
              [-38.0824371018427, -8.387964449054532],
              [-38.082443141265081, -8.388022617948359],
              [-38.08254657305315, -8.388106156561944],
              [-38.082697670431052, -8.38819649880104],
              [-38.082911007608708, -8.388197989809511],
              [-38.083267889116236, -8.388102497706532],
              [-38.08349231187416, -8.387985443367628],
              [-38.083698365208001, -8.387917094999056],
              [-38.083891815320982, -8.387956073114202],
              [-38.083973935075257, -8.388065247700359],
              [-38.083955834494333, -8.388231892159235],
              [-38.083903730849329, -8.38841374250268],
              [-38.083935215587069, -8.388581858777309],
              [-38.084102216212472, -8.388823548655919],
              [-38.084326657830054, -8.389047645028526],
              [-38.084528455271631, -8.389168041009913],
              [-38.084744190455183, -8.38919296139761],
              [-38.084971371965416, -8.389216732470373],
              [-38.085332617448977, -8.389391402081847],
              [-38.085473513975863, -8.389494562624044],
              [-38.085631376454337, -8.389760740860698],
              [-38.085675691955011, -8.390073957497297],
              [-38.085613653715299, -8.390503960821782],
              [-38.085511302674057, -8.390773243748692],
              [-38.085430696096537, -8.390793316023448],
              [-38.085356740414397, -8.390765649779567],
              [-38.085213536721561, -8.390639149498185],
              [-38.085136822840731, -8.390470566553295],
              [-38.085053818724425, -8.390467298887597],
              [-38.084997440085459, -8.39049665248881],
              [-38.084907603377054, -8.390541122860879],
              [-38.08490787292169, -8.390658789880954],
              [-38.084833575646911, -8.390854697940462],
              [-38.084737746048006, -8.390976121373683],
              [-38.084625442891856, -8.391034739066155],
              [-38.084465110468301, -8.391086552410206],
              [-38.08439977373142, -8.391140395168474],
              [-38.084407417828999, -8.391327901310644],
              [-38.084424652772434, -8.391608495596602],
              [-38.084410714935139, -8.391703971059195],
              [-38.084324428791867, -8.391783450954355],
              [-38.084191175829012, -8.391867796088537],
              [-38.084165791127973, -8.391964601587254],
              [-38.084206896706483, -8.392019234218575],
              [-38.084307841729512, -8.392079341985694],
              [-38.084494370225492, -8.392166148301161],
              [-38.084718317220464, -8.392253222303815],
              [-38.084889845371478, -8.392423924753775],
              [-38.084925632351712, -8.392655541989818],
              [-38.084922801994892, -8.39285586673164],
              [-38.084850720335645, -8.393075023731523],
              [-38.084692442519518, -8.393373841742893],
              [-38.084557151651701, -8.393552514280975],
              [-38.08448355590442, -8.393642424585396],
              [-38.084458440841885, -8.393856716192019],
              [-38.084488187874669, -8.39403016454961],
              [-38.084575467527628, -8.394185929268273],
              [-38.08468919438323, -8.394256648517604],
              [-38.084818012988478, -8.394243381217031],
              [-38.084982781829282, -8.394120489950479],
              [-38.085134677863323, -8.393987258217267],
              [-38.085266687004363, -8.393891333232324],
              [-38.085466612444584, -8.39376490560071],
              [-38.085709411356049, -8.393481025522737],
              [-38.085910038457413, -8.393248599997287],
              [-38.08600990180782, -8.392955886519211],
              [-38.086043002067512, -8.392705436000256],
              [-38.086099813069673, -8.392452417593368],
              [-38.08620510544096, -8.392284799361441],
              [-38.086449326429246, -8.392130165098164],
              [-38.086712640999629, -8.392044311635122],
              [-38.087020433735781, -8.391930252308031],
              [-38.087235537025883, -8.391837594024402],
              [-38.087537198474678, -8.391665365151209],
              [-38.087746534190444, -8.391514357407972],
              [-38.087902410711905, -8.391192378827188],
              [-38.088009992976033, -8.390860249260323],
              [-38.088096981315822, -8.390557194694226],
              [-38.088127682321506, -8.390283313492318],
              [-38.088163812861929, -8.390173689543152],
              [-38.088280549721219, -8.39004399271478],
              [-38.088400385519371, -8.389831637988653],
              [-38.088448665258056, -8.389497200463934],
              [-38.088680727637744, -8.388868795259485],
              [-38.088809251326353, -8.388396617260979],
              [-38.088852372707947, -8.387898013378644],
              [-38.088816199598533, -8.387207396375487],
              [-38.088712009936017, -8.386201066082128],
              [-38.088683935514972, -8.385815713400641],
              [-38.088768863276691, -8.385265653418161],
              [-38.088878472694745, -8.384724785133846],
              [-38.088962874460712, -8.384049279139386],
              [-38.088955657836202, -8.383638107835457],
              [-38.088966199947436, -8.383284047164324],
              [-38.089092051426015, -8.382671042520931],
              [-38.08917136661114, -8.382346534572738],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0021000',
        uf: 'PE',
        nome_proje: 'PA PITANGA - II',
        municipio: 'IGARASSU',
        area_hecta: '1459.7700',
        capacidade: 184.0,
        num_famili: 180.0,
        fase: 3.0,
        data_de_cr: '04/01/1989',
        forma_obte: 'Desapropriação',
        data_obten: '18/05/1988',
        area_calc_: 1466.6597,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999139423787405, -7.881638052506386],
              [-34.999510094411704, -7.881559372854853],
              [-34.999901348093488, -7.881543467354065],
              [-35.000159671157455, -7.881403638075425],
              [-35.000336641543129, -7.880661378492763],
              [-34.999494376839493, -7.880707964170794],
              [-34.998991337103966, -7.880489234406433],
              [-34.998557136058061, -7.880616901775864],
              [-34.998336161008275, -7.88030580056526],
              [-34.998011520703727, -7.880180649387812],
              [-34.997521193019011, -7.880083462788991],
              [-34.997471340891508, -7.879661583179264],
              [-34.997120429634997, -7.879778265653857],
              [-34.996777642923334, -7.879665876742863],
              [-34.996444405424441, -7.879814307402232],
              [-34.996455038510788, -7.88014078348039],
              [-34.996465069318639, -7.880405924269698],
              [-34.996718875013528, -7.880580363023915],
              [-34.997246327983063, -7.880858091173989],
              [-34.99757219537986, -7.881134387837794],
              [-34.99779863715726, -7.881692426322762],
              [-34.997607152150152, -7.882111103669861],
              [-34.997599517096596, -7.882376323584003],
              [-34.997361593367685, -7.88238210410965],
              [-34.997282617619632, -7.882018438533474],
              [-34.997083208451571, -7.881809351763811],
              [-34.99680792753739, -7.881766523622269],
              [-34.996655272313127, -7.881827544469952],
              [-34.996478223935242, -7.881895632781172],
              [-34.996585062080932, -7.882339466099844],
              [-34.996350574446211, -7.882940809612604],
              [-34.996475908566055, -7.883203512355804],
              [-34.996178464840675, -7.883269115659107],
              [-34.995941149171941, -7.882926055829921],
              [-34.995592851524442, -7.882710917264915],
              [-34.995305100065508, -7.883166857351693],
              [-34.995190738571452, -7.883658056970237],
              [-34.99499813571574, -7.883533837713326],
              [-34.994800148167975, -7.883241693113201],
              [-34.994687669157273, -7.8827666733897],
              [-34.994645722913866, -7.882327589944017],
              [-34.994892319068363, -7.881728246068831],
              [-34.994805641285176, -7.881275461438383],
              [-34.994810787699073, -7.88095547980831],
              [-34.994564460734665, -7.880618906870501],
              [-34.994310176836656, -7.880423643007572],
              [-34.993917905114955, -7.880715441103527],
              [-34.993550421560791, -7.880649164779345],
              [-34.993212383517744, -7.880623797572014],
              [-34.99304169838706, -7.880828530239268],
              [-34.993077144479273, -7.881407562267194],
              [-34.992901174222219, -7.881570008369177],
              [-34.992843949903566, -7.881813834705976],
              [-34.992995170383864, -7.882098567656525],
              [-34.993308988727968, -7.882365689293699],
              [-34.993403164026844, -7.882752122231139],
              [-34.993082590461377, -7.883036284031148],
              [-34.993030351310445, -7.883363487369239],
              [-34.992729862359901, -7.883780716841327],
              [-34.992196493079781, -7.883887378471046],
              [-34.991858348588757, -7.883524534642421],
              [-34.99152743732909, -7.883023297420537],
              [-34.991536055216962, -7.882548040932552],
              [-34.99135713400711, -7.882192041056722],
              [-34.991144397879921, -7.882462027253676],
              [-34.990862205234194, -7.88285384896308],
              [-34.990693521067357, -7.883300363603081],
              [-34.990376607747464, -7.88289127525939],
              [-34.990284067186039, -7.88263024565648],
              [-34.990316756072183, -7.882112642350455],
              [-34.990066356038213, -7.881866793019644],
              [-34.99029289580637, -7.881326092446939],
              [-34.990285912864259, -7.880861228987989],
              [-34.989615254389278, -7.881194671517259],
              [-34.989304004144792, -7.881195680819122],
              [-34.98895957245081, -7.881385385562828],
              [-34.988527351047217, -7.881478821497628],
              [-34.988480722678375, -7.88187678784508],
              [-34.988218314392931, -7.882132402845823],
              [-34.98787889696348, -7.882155027005712],
              [-34.987498076761788, -7.882543500046234],
              [-34.987357443661828, -7.882599440372818],
              [-34.987170994204277, -7.882592251430007],
              [-34.987028497137658, -7.882438593306075],
              [-34.986694766833409, -7.882390599691383],
              [-34.986468243215214, -7.882267393988049],
              [-34.986326398434393, -7.882017527661923],
              [-34.986304509430205, -7.881811745800182],
              [-34.986551630044247, -7.88138763114588],
              [-34.986668812443561, -7.881103435414266],
              [-34.986382437662243, -7.881094961083178],
              [-34.98596636561907, -7.881021765523568],
              [-34.986072021585812, -7.880735037306557],
              [-34.986231344312813, -7.880285691318218],
              [-34.98596959173873, -7.880035405747389],
              [-34.985613793772828, -7.879992444048637],
              [-34.985397889986892, -7.880158875340243],
              [-34.985046621351437, -7.880498539258829],
              [-34.984915303174056, -7.880856767122424],
              [-34.985048611703874, -7.881363973119728],
              [-34.985273424331687, -7.881805762723727],
              [-34.984631514773127, -7.881644972221252],
              [-34.984389332181514, -7.882029211530651],
              [-34.98387763267128, -7.882441620998734],
              [-34.98358548927019, -7.882578451889805],
              [-34.983380539207673, -7.88269729977441],
              [-34.983209069410563, -7.882895210469025],
              [-34.983016621965064, -7.883188289707695],
              [-34.982875220142482, -7.883180463837686],
              [-34.982754863449657, -7.883421999357815],
              [-34.982154660327566, -7.883087261881682],
              [-34.980804791824028, -7.882391504847542],
              [-34.979789895163094, -7.881884542629814],
              [-34.979053199105032, -7.881691978165716],
              [-34.978864969602114, -7.882576031569823],
              [-34.979444025470499, -7.882752694669627],
              [-34.979737297594227, -7.88264685845761],
              [-34.979744584707028, -7.883194560357976],
              [-34.979247120845407, -7.883409322442189],
              [-34.979066810575389, -7.883843385005989],
              [-34.978612881821455, -7.884521455136823],
              [-34.978415385186018, -7.884723641319387],
              [-34.978261799011122, -7.885429354179958],
              [-34.978186541199051, -7.885830933653545],
              [-34.978614702912672, -7.885588079729465],
              [-34.978712063572864, -7.885372032701645],
              [-34.978783624990911, -7.884850749400609],
              [-34.979106203469385, -7.884651025477179],
              [-34.979384489606851, -7.884232242163496],
              [-34.979760913127166, -7.884442716556799],
              [-34.979971348534797, -7.884379203082831],
              [-34.980149651771228, -7.884449561927307],
              [-34.980588029947825, -7.885031409707704],
              [-34.980869200583982, -7.885145948522272],
              [-34.981306576896479, -7.885347862756268],
              [-34.981364420720951, -7.885875291628245],
              [-34.981345632358305, -7.886105055861202],
              [-34.981119266405564, -7.886430171132342],
              [-34.98126981785488, -7.886786689807336],
              [-34.981512479800728, -7.886754506842752],
              [-34.981790264587175, -7.886820664257424],
              [-34.981739252524264, -7.887030654173678],
              [-34.981498061643634, -7.887187835515415],
              [-34.98096427562831, -7.887579552520696],
              [-34.980600741304279, -7.887703915659291],
              [-34.980121285311753, -7.887858878234908],
              [-34.979946216467738, -7.888131301641297],
              [-34.979599534692078, -7.888334428280944],
              [-34.979028662643806, -7.888768161426288],
              [-34.979076725537439, -7.889054369827102],
              [-34.979407867516251, -7.889265475444674],
              [-34.979964651259486, -7.889392632784293],
              [-34.980269974506307, -7.889431601275903],
              [-34.980777320001081, -7.88895394935601],
              [-34.981369613220203, -7.889050297974952],
              [-34.981831200026889, -7.888667190903671],
              [-34.982040297637667, -7.888385003082851],
              [-34.982376957120159, -7.888366163719336],
              [-34.98246530730475, -7.88806443072796],
              [-34.982591144180361, -7.887651017677323],
              [-34.982568494661891, -7.887274444415992],
              [-34.98249512860783, -7.886750399402095],
              [-34.982656604359136, -7.886600284937384],
              [-34.982851605666909, -7.886644226241059],
              [-34.983033481967503, -7.886553305097283],
              [-34.983313811413332, -7.886252768142096],
              [-34.983656998547026, -7.886064547544206],
              [-34.983540782680635, -7.887123074833029],
              [-34.983625358047767, -7.887413004074652],
              [-34.983713859573875, -7.887536835945058],
              [-34.984161741392249, -7.887232166457353],
              [-34.984600496216402, -7.887093053907416],
              [-34.984698754350312, -7.886863582686662],
              [-34.984957685740234, -7.886756683994538],
              [-34.984987087039251, -7.886562713551597],
              [-34.985237604173406, -7.886636146998834],
              [-34.985198072667259, -7.8868898460868],
              [-34.985081268602066, -7.887204656756522],
              [-34.985048317785193, -7.887390719314169],
              [-34.984872914604878, -7.887647646133723],
              [-34.984800076382228, -7.887831825498838],
              [-34.985001915203732, -7.888132040127333],
              [-34.984725605762286, -7.88848271543588],
              [-34.984779662415754, -7.888748512861582],
              [-34.984721133097622, -7.889103275322767],
              [-34.984614115064758, -7.889300760115154],
              [-34.984793303324032, -7.889484508619576],
              [-34.984986269560849, -7.889361682592171],
              [-34.98522041196388, -7.888870879282951],
              [-34.985540059490376, -7.888535135704717],
              [-34.985714024724601, -7.888106786884354],
              [-34.985995782242206, -7.887925579351112],
              [-34.986001756081002, -7.887412851739823],
              [-34.986429962521129, -7.887449230006983],
              [-34.98666477640527, -7.887924340957368],
              [-34.986889592995432, -7.887760703811337],
              [-34.987023846656577, -7.888275138207027],
              [-34.98672459720553, -7.888441331459129],
              [-34.986599382372503, -7.888749598669852],
              [-34.986449217901445, -7.889025359106927],
              [-34.986259439484819, -7.889359636863278],
              [-34.986715925102708, -7.889684008124405],
              [-34.986922901938598, -7.889116398024984],
              [-34.987573708813976, -7.888641183889424],
              [-34.987691443024907, -7.888421014245745],
              [-34.988073228990572, -7.888657794019871],
              [-34.988678198340672, -7.888090462655963],
              [-34.988760819682788, -7.887858779077786],
              [-34.988901846163706, -7.887354986238516],
              [-34.989379879820589, -7.887380127266209],
              [-34.989635640962803, -7.886922919980774],
              [-34.989707951720398, -7.887242041851197],
              [-34.990026042426607, -7.88730599873212],
              [-34.990373081309336, -7.886990747083948],
              [-34.990853669349626, -7.887048494784922],
              [-34.990924720028737, -7.887223799947222],
              [-34.990661957013266, -7.887425973317219],
              [-34.990557786376392, -7.887823038479355],
              [-34.990128238752128, -7.888203636576306],
              [-34.989997425855115, -7.888640195051698],
              [-34.989771317092618, -7.888978384877119],
              [-34.989744375041603, -7.889503386572946],
              [-34.989986208237404, -7.889580393141649],
              [-34.990292711668069, -7.889627212320846],
              [-34.990444494008699, -7.890050927453608],
              [-34.991301764310776, -7.889895416706258],
              [-34.991373937697823, -7.88955951088154],
              [-34.991327964113268, -7.889226718985893],
              [-34.991722233384714, -7.888863573950876],
              [-34.992035019577678, -7.888956263166612],
              [-34.992142914156823, -7.889378418539206],
              [-34.992550459768218, -7.889514398639581],
              [-34.992862847650166, -7.889397533261724],
              [-34.993179767444495, -7.889057472548429],
              [-34.993095615644755, -7.889715696618141],
              [-34.992756417898924, -7.890461192285977],
              [-34.993236200944175, -7.890558455917038],
              [-34.993296495373052, -7.890724652556002],
              [-34.99266680502739, -7.891057362955552],
              [-34.991865725699519, -7.891152589029156],
              [-34.99123949248645, -7.89147314547408],
              [-34.990924748109379, -7.891775439020562],
              [-34.990745353656507, -7.892033690604549],
              [-34.990393210900415, -7.892041996896591],
              [-34.989990112066359, -7.892319072492056],
              [-34.989266749274663, -7.892576990613617],
              [-34.989474219118918, -7.892768060355592],
              [-34.989774873921448, -7.893051028903971],
              [-34.990002714530448, -7.893481996844262],
              [-34.990389867433656, -7.893557523695859],
              [-34.990649472557635, -7.893801277215597],
              [-34.991607754661409, -7.893016537548909],
              [-34.991972111746726, -7.892642035794639],
              [-34.992545977930924, -7.892703122314679],
              [-34.992796476129854, -7.892973050011025],
              [-34.99314565036871, -7.892777884053372],
              [-34.993791824273565, -7.892479305724752],
              [-34.994298635348827, -7.891796554297597],
              [-34.994699743065979, -7.891172721080927],
              [-34.995039561867863, -7.89104181693707],
              [-34.995262921220309, -7.890759227105939],
              [-34.99559079120538, -7.890966023937944],
              [-34.995280623532039, -7.891442119492577],
              [-34.995268098390838, -7.891803511162236],
              [-34.995100050604648, -7.892183812628062],
              [-34.995155576485033, -7.89237153899611],
              [-34.99504744311141, -7.892529987360523],
              [-34.994964110426046, -7.89279999797014],
              [-34.995153471871632, -7.892973974093021],
              [-34.995168205488326, -7.893328044705205],
              [-34.994883399762216, -7.893474227211626],
              [-34.99471799523694, -7.893653554358956],
              [-34.994849082024729, -7.893848363140572],
              [-34.995089427739067, -7.894190759348255],
              [-34.995678318177056, -7.894067343929871],
              [-34.995829519304813, -7.894304655883302],
              [-34.995661259074751, -7.89476588278044],
              [-34.995325319297116, -7.895220789675665],
              [-34.99555753392405, -7.895289000416466],
              [-34.99590182321429, -7.895326611504056],
              [-34.996351482129363, -7.89542137373079],
              [-34.996904571888926, -7.895658445668237],
              [-34.997352820464826, -7.895987151043588],
              [-34.997038386523187, -7.896200848608563],
              [-34.996556737559054, -7.896170284710853],
              [-34.996228585895238, -7.896306404468473],
              [-34.99648467355555, -7.896720349760561],
              [-34.996198494684414, -7.897116444398685],
              [-34.995397722241492, -7.897263754468825],
              [-34.995106982701429, -7.897546581414901],
              [-34.995367771042993, -7.897753953327858],
              [-34.99564113082922, -7.897985913475138],
              [-34.996352558933431, -7.897792148056999],
              [-34.996975739511214, -7.89825612114463],
              [-34.997366468090497, -7.898396319742806],
              [-34.997643668926528, -7.898305542088428],
              [-34.997924512284868, -7.898258992760361],
              [-34.99804337000586, -7.898009834660777],
              [-34.998656130190184, -7.898194419688242],
              [-34.999134274455677, -7.898280574425324],
              [-34.999341092199984, -7.898194920547692],
              [-34.999648445575886, -7.898132269301783],
              [-34.999946524688525, -7.898014327863155],
              [-35.000181457987864, -7.898289095249308],
              [-35.000632655476259, -7.898231497773015],
              [-35.000899058118812, -7.898305456931786],
              [-35.002078436800311, -7.898867894113793],
              [-35.002342300843445, -7.899168272851776],
              [-35.002935480672704, -7.899584020023452],
              [-35.003972702423901, -7.899813484908326],
              [-35.004587795944154, -7.899723289113314],
              [-35.005453969584671, -7.899575033993783],
              [-35.005857601589078, -7.899494053058381],
              [-35.005871237366733, -7.899322376207282],
              [-35.005881938886674, -7.899045320998956],
              [-35.005505810569922, -7.898930967174525],
              [-35.00521414757803, -7.898491527694389],
              [-35.00487615433412, -7.898193152334972],
              [-35.00438321377765, -7.897968893771496],
              [-35.003627817258128, -7.898187716666255],
              [-35.003408309486034, -7.897967046129255],
              [-35.003513312243449, -7.897576375533835],
              [-35.003596865670239, -7.897309747112135],
              [-35.00299506514834, -7.896803951634152],
              [-35.002425907958447, -7.896341331647204],
              [-35.001278888983727, -7.895665285524754],
              [-35.001024663837953, -7.894965311877567],
              [-35.000901771899656, -7.894751082458043],
              [-35.000577093346443, -7.894517786321548],
              [-35.000209869773165, -7.894358026788699],
              [-34.999858468302264, -7.89394883283396],
              [-35.000298104026541, -7.893997741981899],
              [-35.000326466438246, -7.893593278383525],
              [-35.000602170585047, -7.893393507170988],
              [-35.000692804787839, -7.893632368228988],
              [-35.000855810087941, -7.893843233791111],
              [-35.001537926714512, -7.893671672859449],
              [-35.001876245669543, -7.892576897051335],
              [-35.002013531975415, -7.892263991546832],
              [-35.002261557777047, -7.892904410604868],
              [-35.00256266471952, -7.892694227261157],
              [-35.002928049802321, -7.891940845351235],
              [-35.003409569652405, -7.891680779896221],
              [-35.003497317938233, -7.891326379031454],
              [-35.00364408621752, -7.891302605047958],
              [-35.003801990227572, -7.891593613777026],
              [-35.004190716888658, -7.891716864327392],
              [-35.00462715288019, -7.891673046959029],
              [-35.004694243016196, -7.891936804315051],
              [-35.004321255006062, -7.892118585530962],
              [-35.00368596107738, -7.892173259553993],
              [-35.003414799960353, -7.892424009069371],
              [-35.003516123183807, -7.892954993568659],
              [-35.00301728303387, -7.893532148776788],
              [-35.002794858951013, -7.894201401348098],
              [-35.00307501885959, -7.894763976064794],
              [-35.003569074651786, -7.894905318950406],
              [-35.003788280022142, -7.894508944525951],
              [-35.004201688078084, -7.893717703814303],
              [-35.004603758803668, -7.893207338133607],
              [-35.004834741786567, -7.893003927664519],
              [-35.005214525276621, -7.892442063652479],
              [-35.005608069548948, -7.89184329654845],
              [-35.005663929238843, -7.891606581234447],
              [-35.006121814002192, -7.891184474057239],
              [-35.006854136030221, -7.891165061379906],
              [-35.007204468812304, -7.89092364903632],
              [-35.007862642950236, -7.890854095163307],
              [-35.008405785982191, -7.891218413616081],
              [-35.008776283593548, -7.891383967625648],
              [-35.009076640667523, -7.891293701820384],
              [-35.009278035178767, -7.890973703205902],
              [-35.009382238166964, -7.89012120092126],
              [-35.009767052109652, -7.890478550860633],
              [-35.009703332114611, -7.890869285443013],
              [-35.009903195059358, -7.891153196837337],
              [-35.00964124537002, -7.891620309237839],
              [-35.010166400250959, -7.891645686478478],
              [-35.010712713701949, -7.892043287787264],
              [-35.011108540352723, -7.891413663197336],
              [-35.011988983127146, -7.891251170594858],
              [-35.012240629629005, -7.890645911742727],
              [-35.012510828532299, -7.890215492777436],
              [-35.012804486141853, -7.890261116743623],
              [-35.012721020196189, -7.890829646009978],
              [-35.012375249013317, -7.891343471850163],
              [-35.01248344701439, -7.891826367108099],
              [-35.013226800864757, -7.891953726606035],
              [-35.013043277911599, -7.892191394240885],
              [-35.012843573382156, -7.892315424267569],
              [-35.013017886392184, -7.892588215468681],
              [-35.013364269775622, -7.892832420015457],
              [-35.013850843474891, -7.892909513099798],
              [-35.014078245244498, -7.893155418193109],
              [-35.013953735444439, -7.893326137594509],
              [-35.01350616600763, -7.893119713895055],
              [-35.013185474875165, -7.893156189319088],
              [-35.012799197814822, -7.892909405765883],
              [-35.012399913803847, -7.892314323168499],
              [-35.011899971073525, -7.892269874828363],
              [-35.011634817822902, -7.892047989506675],
              [-35.011403735786331, -7.892240321024317],
              [-35.011265930842349, -7.892474140712805],
              [-35.01141059875598, -7.892757781094774],
              [-35.011789262561848, -7.893186465435483],
              [-35.011342614903214, -7.893154456644925],
              [-35.01104492693765, -7.893184392620721],
              [-35.010860342132418, -7.892896581440533],
              [-35.010499138517822, -7.892867338840935],
              [-35.009801767906971, -7.892404468922991],
              [-35.009251406642662, -7.89237327943632],
              [-35.008975704559795, -7.892252817974004],
              [-35.00823437290299, -7.892071089128343],
              [-35.007660381873158, -7.891792921764902],
              [-35.007259633279872, -7.892094700207979],
              [-35.006659144862716, -7.892169821325264],
              [-35.006507942864857, -7.892987734401983],
              [-35.006292903209975, -7.893315442776384],
              [-35.0062936819239, -7.893892202163473],
              [-35.006817026289482, -7.894149755554726],
              [-35.006544211280115, -7.89454604362837],
              [-35.006839054644203, -7.894963947050292],
              [-35.007352243980939, -7.895335961754023],
              [-35.007707100250549, -7.895118844018568],
              [-35.008217474308779, -7.895172217885733],
              [-35.008873093276264, -7.89485531578774],
              [-35.008898057676092, -7.894313480675597],
              [-35.009282844572219, -7.894714603179829],
              [-35.009433774584245, -7.894782222628639],
              [-35.009533205160814, -7.895082503013794],
              [-35.009709932181465, -7.895467138372896],
              [-35.00910872449721, -7.895884520824502],
              [-35.008676881917161, -7.896058480882155],
              [-35.008108018752317, -7.896495416057552],
              [-35.007756733627389, -7.896608974622395],
              [-35.007815255204619, -7.896884462552758],
              [-35.008537141842744, -7.896598577136532],
              [-35.008991824637867, -7.896537616980342],
              [-35.009103949127962, -7.896802954393955],
              [-35.009065610326061, -7.897492288284876],
              [-35.009575875242327, -7.897207612175239],
              [-35.010075273455008, -7.897006583409371],
              [-35.010193547507896, -7.897258712349705],
              [-35.009959000908282, -7.897591579106104],
              [-35.009637267847083, -7.898245594667072],
              [-35.009990378817818, -7.898470267350066],
              [-35.01025844252689, -7.898351348716327],
              [-35.010749328785678, -7.89781954057423],
              [-35.01091703124046, -7.897915936262211],
              [-35.01090505165503, -7.898774481561629],
              [-35.011214814038439, -7.898892903655159],
              [-35.01153310379884, -7.898607680076985],
              [-35.011866429801351, -7.898456250149009],
              [-35.012116690009222, -7.898571197558853],
              [-35.012366354454684, -7.89881817804843],
              [-35.011934725323442, -7.898896612549184],
              [-35.011532666658653, -7.899652027835924],
              [-35.011052053480732, -7.899828800614237],
              [-35.010747405026429, -7.900000369361975],
              [-35.011499271632658, -7.900151318815908],
              [-35.012084302303094, -7.89978898458357],
              [-35.012732814691603, -7.9002457083693],
              [-35.012943855285918, -7.899963160625244],
              [-35.012962684623723, -7.899189647540728],
              [-35.012850412810586, -7.89887675570094],
              [-35.013078433488211, -7.898486299472117],
              [-35.013512282590483, -7.898321898746808],
              [-35.014183212549185, -7.898175525501228],
              [-35.014377368463585, -7.897867967059204],
              [-35.014619808268264, -7.897955798546915],
              [-35.014374381116198, -7.898483954029437],
              [-35.014214457550111, -7.899137361100968],
              [-35.014438255551994, -7.899752554190411],
              [-35.01462837929963, -7.899858830784651],
              [-35.01482740566815, -7.899517236539994],
              [-35.015119332466732, -7.899308829031691],
              [-35.015464193778335, -7.899117087975577],
              [-35.015421264301729, -7.89941031390812],
              [-35.01540646456283, -7.899777044062722],
              [-35.015534257942946, -7.899958756331882],
              [-35.015777840453431, -7.89958884775103],
              [-35.015988337404806, -7.899174288172195],
              [-35.016209955470472, -7.899276900158467],
              [-35.016418082798815, -7.899686474875097],
              [-35.016427417660068, -7.900123372522893],
              [-35.016548564101754, -7.900573367907197],
              [-35.016763628997509, -7.900687098648032],
              [-35.016999351401154, -7.899601403725771],
              [-35.017291373800788, -7.899246269268148],
              [-35.017896084120402, -7.898834427825077],
              [-35.018386611897469, -7.89922736578027],
              [-35.018548894912634, -7.899441621484889],
              [-35.018362111856533, -7.899655606360268],
              [-35.018345474448736, -7.900034383208546],
              [-35.018803374243852, -7.900081360777016],
              [-35.019096326018357, -7.900374674669399],
              [-35.019287560681178, -7.900655190440994],
              [-35.019367222899881, -7.900519944242832],
              [-35.019202921773619, -7.899960817365127],
              [-35.019204763234484, -7.899671541568134],
              [-35.019441666627891, -7.899090618985747],
              [-35.01973460365955, -7.898907398096724],
              [-35.019834696468095, -7.899156163611718],
              [-35.02006627576845, -7.899436675431193],
              [-35.020635262687293, -7.898956878563236],
              [-35.020923854469089, -7.89887186721139],
              [-35.021288720718097, -7.898549484853975],
              [-35.02160250781035, -7.898482206387462],
              [-35.021949781175273, -7.898322072376801],
              [-35.022169776171587, -7.898479242517364],
              [-35.022257088904645, -7.89943294825426],
              [-35.022092012606578, -7.899775705112662],
              [-35.021952397377582, -7.900222809626514],
              [-35.021409621920547, -7.900689915599814],
              [-35.021440682658742, -7.900920171227707],
              [-35.021225588225683, -7.901484003667981],
              [-35.021472856997285, -7.901733494664666],
              [-35.021767318725168, -7.901241791671973],
              [-35.022142061038807, -7.90122192055729],
              [-35.022561843834907, -7.900933939655752],
              [-35.022598211849022, -7.900519228445686],
              [-35.022921665478719, -7.899902566970888],
              [-35.023394355440118, -7.89966187535617],
              [-35.023784934799522, -7.900038254457985],
              [-35.024078950419927, -7.900746485062535],
              [-35.024065237133406, -7.901588633043475],
              [-35.024428309135303, -7.901304333391916],
              [-35.024483410297407, -7.900912849403611],
              [-35.025022228830856, -7.900788477242737],
              [-35.025218217156819, -7.900554403751216],
              [-35.025658265683219, -7.900294951272442],
              [-35.025868759155976, -7.900431340429028],
              [-35.025797652898753, -7.900914325450205],
              [-35.025885535415931, -7.90123337620295],
              [-35.026035816616051, -7.902102773356383],
              [-35.025553425388473, -7.902828723462648],
              [-35.025348710134253, -7.9033939309523],
              [-35.025961194852712, -7.903468785093338],
              [-35.02602443132723, -7.903070194857182],
              [-35.026343630079467, -7.902361178090947],
              [-35.026751059236851, -7.901787046615647],
              [-35.026905936750396, -7.902025223942313],
              [-35.02718584786934, -7.902703952681344],
              [-35.027561298023564, -7.902377416762892],
              [-35.027945930440346, -7.902572584068833],
              [-35.028463443838554, -7.902340197558759],
              [-35.028254105084528, -7.902970050859785],
              [-35.027809865685533, -7.903408215765553],
              [-35.027801841372543, -7.903740701179134],
              [-35.028081436389023, -7.903458625484111],
              [-35.028739582536495, -7.902815997828966],
              [-35.02906475283131, -7.903279823167503],
              [-35.029495157802444, -7.902989078339473],
              [-35.030022259210355, -7.903007103114259],
              [-35.03043352607564, -7.903111853918811],
              [-35.030796300960588, -7.903516482618035],
              [-35.03098500489623, -7.903258733328296],
              [-35.031089973983562, -7.902832224227786],
              [-35.031391292672431, -7.90249476114023],
              [-35.031426204891943, -7.902173098168079],
              [-35.031342803097083, -7.90177553530954],
              [-35.031792065237596, -7.901261775616377],
              [-35.032107506932405, -7.900731505355171],
              [-35.032333013547628, -7.900460299868428],
              [-35.032387265973817, -7.900145256379396],
              [-35.03216809274516, -7.899696744593546],
              [-35.031757677621194, -7.8992931362869],
              [-35.031342312632376, -7.899150887128187],
              [-35.031074793560258, -7.898970982357826],
              [-35.030832493802819, -7.898501566894383],
              [-35.030919088795343, -7.898110328486308],
              [-35.03017620858239, -7.897937368926279],
              [-35.030167919886239, -7.897510942226852],
              [-35.029713796133038, -7.897506446044347],
              [-35.029369132919605, -7.896868160614749],
              [-35.029511105538504, -7.896358899846565],
              [-35.029573900856015, -7.895790194735949],
              [-35.028677183396198, -7.895289060136164],
              [-35.028464023004268, -7.895779131590862],
              [-35.028067918384927, -7.89585019283831],
              [-35.027526044350381, -7.895553211000558],
              [-35.027328173450883, -7.895092990725936],
              [-35.027546019643474, -7.89447347997965],
              [-35.026499999385948, -7.893538014663715],
              [-35.026124431965314, -7.893610386925709],
              [-35.025747785680764, -7.893156460002239],
              [-35.025727369961139, -7.892624844557139],
              [-35.024997109804474, -7.892506029769248],
              [-35.024592703065338, -7.892137865893923],
              [-35.024784045606715, -7.89136708044926],
              [-35.024671884052417, -7.890930940381574],
              [-35.024284591596903, -7.890953539474745],
              [-35.023171313149035, -7.89100835206346],
              [-35.022872066236481, -7.890690933136701],
              [-35.023193682167431, -7.890039185920919],
              [-35.022859557094293, -7.889869454508009],
              [-35.022004901091229, -7.889427478643208],
              [-35.021284372186365, -7.88914983476587],
              [-35.020900123477254, -7.889599640117265],
              [-35.020441945249708, -7.890756428337887],
              [-35.021012911240959, -7.89143274230829],
              [-35.021022600204802, -7.891888167669275],
              [-35.020602330801694, -7.891576037170066],
              [-35.019745101077064, -7.890824702328274],
              [-35.019803264880636, -7.890291869192858],
              [-35.019775954461622, -7.889614774294609],
              [-35.0194652074385, -7.889808120840097],
              [-35.019241183763633, -7.890488357589582],
              [-35.019239439605073, -7.890869843909011],
              [-35.018941194779337, -7.890785590576717],
              [-35.018543656410316, -7.889929280373201],
              [-35.018685884203258, -7.889205463514932],
              [-35.018439906330293, -7.888505065754452],
              [-35.018168029792818, -7.888239223811783],
              [-35.01754159854336, -7.888468473170682],
              [-35.017488864056318, -7.888817275551729],
              [-35.017146812743874, -7.889585716997997],
              [-35.017464912508522, -7.890205342875288],
              [-35.017278214528154, -7.890849673407685],
              [-35.016915266028029, -7.891117160529224],
              [-35.016623555790225, -7.891509212934226],
              [-35.016482488830555, -7.89121373190932],
              [-35.016871370814727, -7.890650946180864],
              [-35.016303010161529, -7.889721690823719],
              [-35.016411974781683, -7.889253377566899],
              [-35.016326498089178, -7.888761697661949],
              [-35.016501470674257, -7.888069262793236],
              [-35.016735558879446, -7.887017083751415],
              [-35.015893307680074, -7.886778501763356],
              [-35.015455667579687, -7.886259938850386],
              [-35.014953600762901, -7.885536910782659],
              [-35.014535737292363, -7.885397804660649],
              [-35.014520254812474, -7.88596051885518],
              [-35.014823510312134, -7.886833640930095],
              [-35.014573494034977, -7.887311496191463],
              [-35.013797388213312, -7.888117374655313],
              [-35.013281964223118, -7.888285090225831],
              [-35.013149968213263, -7.887596035921697],
              [-35.012463328563918, -7.887220476863042],
              [-35.012361922520249, -7.886732276328424],
              [-35.012165881568208, -7.886107644380479],
              [-35.012311287205648, -7.885043694847166],
              [-35.012639294603382, -7.884746553652588],
              [-35.012911125312534, -7.883767057040959],
              [-35.013000928929962, -7.883627616962891],
              [-35.012758868828897, -7.883398927223112],
              [-35.01235975522458, -7.883463105847605],
              [-35.011924488164162, -7.88295426893284],
              [-35.011222501496441, -7.882679240138279],
              [-35.011051371667413, -7.882018386046595],
              [-35.010650443851446, -7.881375317501165],
              [-35.010229120084709, -7.881363338733524],
              [-35.009243128829489, -7.881337584127347],
              [-35.008910712787504, -7.881878764436489],
              [-35.008923194603526, -7.882297860170211],
              [-35.008480253497567, -7.882663001837089],
              [-35.008141856013836, -7.882348814994788],
              [-35.008006814071358, -7.881802879742855],
              [-35.007968396447474, -7.880655695135051],
              [-35.008310058492924, -7.880052506755799],
              [-35.00741706561957, -7.879963160043759],
              [-35.006957047374726, -7.880303792311297],
              [-35.006770229113322, -7.880843908422305],
              [-35.007149272208345, -7.881304066132462],
              [-35.007318331168129, -7.881976899157669],
              [-35.007183933001407, -7.882508857038111],
              [-35.006261192346805, -7.882807412646387],
              [-35.005967725900369, -7.883316876644455],
              [-35.005979271362918, -7.883900755543798],
              [-35.006214296958014, -7.884215309372735],
              [-35.006048465108094, -7.884686037613593],
              [-35.005089546366882, -7.8841929866465],
              [-35.004573568204165, -7.882874386659091],
              [-35.00446898292693, -7.882419628602256],
              [-35.004190334966722, -7.882344080717474],
              [-35.004073938087323, -7.882771760559245],
              [-35.003862489359769, -7.883529372753823],
              [-35.004026506803541, -7.883851578762101],
              [-35.004153504803625, -7.884369697159469],
              [-35.003975098083906, -7.884613560172244],
              [-35.003987559698324, -7.884884750136267],
              [-35.003551486864986, -7.884978388719831],
              [-35.002987757153399, -7.88480710903753],
              [-35.002883872268363, -7.884356085748695],
              [-35.002370797832974, -7.883657289472476],
              [-35.002213469690631, -7.882883463416317],
              [-35.001652334126327, -7.883565692889292],
              [-35.001892430839575, -7.883958761684195],
              [-35.00196491024105, -7.884616542652574],
              [-35.001623011318401, -7.885446546183112],
              [-35.00223946675564, -7.8858697062963],
              [-35.002676579520674, -7.886108427748715],
              [-35.003138079926245, -7.886461962793433],
              [-35.003505711138885, -7.886662131811411],
              [-35.003733612765011, -7.887033709356662],
              [-35.004134414359278, -7.887452079551923],
              [-35.004555040298968, -7.887615480421103],
              [-35.004699335678616, -7.887838412756969],
              [-35.005132848157579, -7.887935258191698],
              [-35.005540558425785, -7.887920094127219],
              [-35.00553555600036, -7.888121175175559],
              [-35.004944954169282, -7.888158274557128],
              [-35.004899741785167, -7.888454910616603],
              [-35.004624387188834, -7.888225177662696],
              [-35.004093522120954, -7.888216485240229],
              [-35.003619019874414, -7.88785593987058],
              [-35.003430664783032, -7.888050966604453],
              [-35.002892996531308, -7.887604136919257],
              [-35.002662253928399, -7.887604723837794],
              [-35.002261706232666, -7.887696090607813],
              [-35.002271023601423, -7.887313533922999],
              [-35.001949575740419, -7.887180195493617],
              [-35.001340110374279, -7.886796661281991],
              [-35.00106254080471, -7.887137883354942],
              [-35.000897308493698, -7.886963342066177],
              [-35.000618257428521, -7.886952854613421],
              [-35.000440692112655, -7.88716985613075],
              [-35.000473013872593, -7.887611468696898],
              [-35.000135391537448, -7.887543029509644],
              [-34.999965420222303, -7.886886540391661],
              [-34.999573574983806, -7.886773220169836],
              [-34.999197161515234, -7.886925405923521],
              [-34.999080386311796, -7.887311324560954],
              [-34.998864066074198, -7.887478549813236],
              [-34.99893353769999, -7.886790080043665],
              [-34.999175572980548, -7.886351234908704],
              [-34.999539488924384, -7.886130531148039],
              [-34.999724179771064, -7.886018396890678],
              [-34.999982751918154, -7.885842894230993],
              [-35.000085337027897, -7.885236732929674],
              [-35.000038019137094, -7.884574955192514],
              [-34.999614848170651, -7.883872965982324],
              [-34.999582108616217, -7.882915807474109],
              [-34.99925840989151, -7.88264803941562],
              [-34.998894264713677, -7.882632671095545],
              [-34.998677753927538, -7.883102548209405],
              [-34.998538914684268, -7.882826453038176],
              [-34.998527085328497, -7.882533789100448],
              [-34.998463921018676, -7.882177709245045],
              [-34.998722768951403, -7.881821050093381],
              [-34.999139423787405, -7.881638052506386],
            ],
          ],
          [
            [
              [-35.010235652100206, -7.906906793577164],
              [-35.010153975217648, -7.90740250726461],
              [-35.010005701015452, -7.9073864066037],
              [-35.009815853569101, -7.907251690585317],
              [-35.009690232748412, -7.907601011302792],
              [-35.009717662608075, -7.908187347492905],
              [-35.009970996834603, -7.908653621186558],
              [-35.009982134149219, -7.908946219310587],
              [-35.009698309046271, -7.908712631793225],
              [-35.009375313387167, -7.90834840852589],
              [-35.009344580718711, -7.907289623155476],
              [-35.009055476837318, -7.907142872139464],
              [-35.008676827279487, -7.906779148985851],
              [-35.008526544492121, -7.907431027455543],
              [-35.008159858115071, -7.907322384602362],
              [-35.008081344630696, -7.906989755768413],
              [-35.007847933727334, -7.906693192988549],
              [-35.007812619540807, -7.906394314542932],
              [-35.007888136239416, -7.905866471866868],
              [-35.00805368006619, -7.905238341339823],
              [-35.00781078083174, -7.905057641313992],
              [-35.007406135945722, -7.905417163304858],
              [-35.006937387737146, -7.90574720262183],
              [-35.00688363592122, -7.906111211228613],
              [-35.006720055397345, -7.905973675055607],
              [-35.006550865482104, -7.905488696671967],
              [-35.006670566783207, -7.904878386900547],
              [-35.006627199830589, -7.904683335415935],
              [-35.006425717685822, -7.904532326590255],
              [-35.006363790497048, -7.905039438629504],
              [-35.006410646913302, -7.905342316779859],
              [-35.006249509778492, -7.906590038601368],
              [-35.005710312627691, -7.907203980090856],
              [-35.005312918225478, -7.907409613176978],
              [-35.005271177625829, -7.907783281524793],
              [-35.005454129559681, -7.908062894590156],
              [-35.005312486327497, -7.908410952133786],
              [-35.005483769391887, -7.908741844265585],
              [-35.005930558433214, -7.909012868769219],
              [-35.00571077000675, -7.909138471569785],
              [-35.005356910026585, -7.90910622005118],
              [-35.004927708057423, -7.908706305756429],
              [-35.00486933662205, -7.908040780303877],
              [-35.004559341768406, -7.907642234337734],
              [-35.004508058408057, -7.907316255449314],
              [-35.004815967888661, -7.906721786858158],
              [-35.005087010509627, -7.906341792355962],
              [-35.004975346763871, -7.905342597695952],
              [-35.004645019899144, -7.904725151802666],
              [-35.004625245538151, -7.904219469614262],
              [-35.004829953975658, -7.90363166515632],
              [-35.004123478210865, -7.903794372329893],
              [-35.004040985027466, -7.904272075116085],
              [-35.003726665393728, -7.904473171653264],
              [-35.003138368785059, -7.904729072331462],
              [-35.002928052128574, -7.905034476893324],
              [-35.003146264764204, -7.905487379712278],
              [-35.002944261776648, -7.905678323929112],
              [-35.00265698257256, -7.905544326552842],
              [-35.002406531003267, -7.905094724604785],
              [-35.002238660729063, -7.904883516634811],
              [-35.002478550946876, -7.904561680083051],
              [-35.002724677978343, -7.903786581207639],
              [-35.002757080719498, -7.903439675371106],
              [-35.00258426533496, -7.903337663384918],
              [-35.001808785795717, -7.903474611221739],
              [-35.001538284520777, -7.903818801243927],
              [-35.00119622467701, -7.903848269903649],
              [-35.000961925923683, -7.903616060563706],
              [-35.00072062554672, -7.90353885714894],
              [-35.000330305042333, -7.903182505205952],
              [-35.000127439060634, -7.903630470546704],
              [-34.999982245880204, -7.903940607951371],
              [-35.000075434000088, -7.904505989227173],
              [-34.999677517323505, -7.904501077626444],
              [-34.999407113012701, -7.904683200204835],
              [-34.999304212607633, -7.904452789570257],
              [-34.999345498037563, -7.903969988753576],
              [-34.999225754772986, -7.903631534729345],
              [-34.999269723924535, -7.903064873257033],
              [-34.998906931359727, -7.90264159529598],
              [-34.998588748607489, -7.902521325978704],
              [-34.99845760692196, -7.902648152994947],
              [-34.99846854630993, -7.903222190113643],
              [-34.99833583258399, -7.903751940088023],
              [-34.998055470241141, -7.903534277035471],
              [-34.997922534330826, -7.903199976162166],
              [-34.997596550480509, -7.90318741493636],
              [-34.997053764180521, -7.9024773289835],
              [-34.996883791846088, -7.902480612520936],
              [-34.996748595666112, -7.902864054876368],
              [-34.996664493696279, -7.903338268064409],
              [-34.996423565533036, -7.903486084324753],
              [-34.996083642425937, -7.903572867350043],
              [-34.995693589312353, -7.90352101796219],
              [-34.995594959674243, -7.902752723439043],
              [-34.995360795003663, -7.90236578670165],
              [-34.99491874038749, -7.902308140977634],
              [-34.994788323013104, -7.902565035870328],
              [-34.994861900924064, -7.902822842699392],
              [-34.994744245699692, -7.903350471871287],
              [-34.994297648559083, -7.903264276162513],
              [-34.994234317598838, -7.903473737883596],
              [-34.994609550275982, -7.903931822474369],
              [-34.993872553472166, -7.90417847012828],
              [-34.993298536353947, -7.904151308889211],
              [-34.993188778529678, -7.904371508083825],
              [-34.993309122542819, -7.904612708844069],
              [-34.993078820765426, -7.904660747090087],
              [-34.992731185275552, -7.904411884764331],
              [-34.992668740462591, -7.903753986077759],
              [-34.993168896216226, -7.903019648441497],
              [-34.993278785964044, -7.902451821518232],
              [-34.993306563232586, -7.901897173609164],
              [-34.993176522872844, -7.901807315556844],
              [-34.992914066054979, -7.901940896093694],
              [-34.992435094996999, -7.901885541293959],
              [-34.991884019286786, -7.902566285301749],
              [-34.99166120680654, -7.902858221449153],
              [-34.991680068626273, -7.903246042317216],
              [-34.991466112232864, -7.903260290675767],
              [-34.991052933829806, -7.903065863846837],
              [-34.991047653489396, -7.902401700997987],
              [-34.990852372838589, -7.902172342315458],
              [-34.99097890942955, -7.901631802064649],
              [-34.991207823633893, -7.90121639689947],
              [-34.991531453903931, -7.900599914445566],
              [-34.991671351882808, -7.900079767850544],
              [-34.991104868796825, -7.899598545541104],
              [-34.990801103749838, -7.899446247278454],
              [-34.990621141584825, -7.89874760327141],
              [-34.99033427110718, -7.898707433317286],
              [-34.989907404848339, -7.898856937187122],
              [-34.989805102892639, -7.899607517361421],
              [-34.989773023944863, -7.89997306288916],
              [-34.990098699700852, -7.900272097750877],
              [-34.990291717493641, -7.900595197874693],
              [-34.990549418282868, -7.900861704986225],
              [-34.990585306949079, -7.901239612932728],
              [-34.990438201179153, -7.901444927706436],
              [-34.990316747101865, -7.902129265329316],
              [-34.990096269936551, -7.902400153316339],
              [-34.989978660789767, -7.902780150572023],
              [-34.989702396177378, -7.902820834280265],
              [-34.989483399912757, -7.90217330858938],
              [-34.989321554334111, -7.901945844167541],
              [-34.98892544527191, -7.902246712340771],
              [-34.988628466298252, -7.902198805278848],
              [-34.988340566712751, -7.902561557541584],
              [-34.988070844255255, -7.902682109086139],
              [-34.988088427710061, -7.902279201035359],
              [-34.98768207119609, -7.901583520123457],
              [-34.987356797880977, -7.902068862332317],
              [-34.987095003594739, -7.902645138274589],
              [-34.987113048755113, -7.903055984315194],
              [-34.986721864126537, -7.903040040632379],
              [-34.986446720618396, -7.903199864017343],
              [-34.986199373933303, -7.903088333105174],
              [-34.986235336550266, -7.902317221146386],
              [-34.986213139427548, -7.901535554543526],
              [-34.985950072231923, -7.900827923230288],
              [-34.985634735127832, -7.900851918161434],
              [-34.985362513583894, -7.900353275499328],
              [-34.984989192999286, -7.900147069086144],
              [-34.984405452987183, -7.900112511912632],
              [-34.983968107930167, -7.899771785461102],
              [-34.983619324859717, -7.899649682631671],
              [-34.983256627791185, -7.899711363526258],
              [-34.983327659012737, -7.89947023005592],
              [-34.98366734537332, -7.899201861933694],
              [-34.984265745830598, -7.898919628692221],
              [-34.984714088840299, -7.898742715709263],
              [-34.985102782044777, -7.898241426165083],
              [-34.98492694326896, -7.897934901339926],
              [-34.985208482099857, -7.897637084822712],
              [-34.98547405912246, -7.897450848593489],
              [-34.986315034673339, -7.897502697794566],
              [-34.986527304781866, -7.897293604396297],
              [-34.986809930986688, -7.896698957610385],
              [-34.986148468900716, -7.896716528584695],
              [-34.985747371900658, -7.896474819932333],
              [-34.985460285040674, -7.89642712369331],
              [-34.985036861745819, -7.895948586038149],
              [-34.984613868255877, -7.89535474300436],
              [-34.984328348039277, -7.895140829763467],
              [-34.984006521288244, -7.895534150368122],
              [-34.983588792151579, -7.895654918812636],
              [-34.983133007958926, -7.895386914345976],
              [-34.983085102089262, -7.895529177841053],
              [-34.983644070114849, -7.895997702423884],
              [-34.983708261388884, -7.89643010682943],
              [-34.983960167558486, -7.896774141328915],
              [-34.984096815361994, -7.897172585030831],
              [-34.983936133899348, -7.897268234518949],
              [-34.983632261389793, -7.896979972079304],
              [-34.983246415693813, -7.897136902371714],
              [-34.982965779016645, -7.896733905065501],
              [-34.982833680863379, -7.897023286636965],
              [-34.982800919056402, -7.897260615773122],
              [-34.982553265066024, -7.897306905253018],
              [-34.982252956343544, -7.897122654282155],
              [-34.982236057780973, -7.896828876826623],
              [-34.982096124005032, -7.896656861175127],
              [-34.981950230803086, -7.897187660338671],
              [-34.981655505210604, -7.897414885874054],
              [-34.981414005412304, -7.897343223228241],
              [-34.981152391669291, -7.897476063215501],
              [-34.980728257526962, -7.897564040062558],
              [-34.980508762503675, -7.897456157265964],
              [-34.980275029111098, -7.897044568728496],
              [-34.980270496424403, -7.896578474639276],
              [-34.980108772219602, -7.896556615688874],
              [-34.979840121340736, -7.896398802798313],
              [-34.979420178381567, -7.896649262970223],
              [-34.979252335548267, -7.896526628101443],
              [-34.979269531838767, -7.896229154538798],
              [-34.97910240715742, -7.896044050610961],
              [-34.979101686271321, -7.89584019377503],
              [-34.979076868365752, -7.895582828904405],
              [-34.978782270966626, -7.895271083629013],
              [-34.978993842332876, -7.894653214165007],
              [-34.978820644036361, -7.894432495049952],
              [-34.978501104155093, -7.894799858558305],
              [-34.977974171124544, -7.89497239107599],
              [-34.978048311126713, -7.895663101414613],
              [-34.978251206023714, -7.895894391210815],
              [-34.978562489176717, -7.89602574362867],
              [-34.978862645516543, -7.896831751039422],
              [-34.978715219986171, -7.897273916276415],
              [-34.978750623904062, -7.897535065204752],
              [-34.978597421574257, -7.897572236583565],
              [-34.978193588471576, -7.897115160707561],
              [-34.97795723899285, -7.897181814894681],
              [-34.977726478459211, -7.89761605509639],
              [-34.97764340763235, -7.897850459547788],
              [-34.977515232080847, -7.897862571845367],
              [-34.977343075101103, -7.897730608444487],
              [-34.977056538012825, -7.89765155769546],
              [-34.976758929723601, -7.897114726969058],
              [-34.976618887793173, -7.896964957781926],
              [-34.976292061390737, -7.897166272414452],
              [-34.976193490373312, -7.897664647675288],
              [-34.976213005503446, -7.897949657261888],
              [-34.975977817646097, -7.898087523493007],
              [-34.975883923093562, -7.898373162394546],
              [-34.97587417674012, -7.898602127530228],
              [-34.975748822791481, -7.898665479225584],
              [-34.975638320058444, -7.898180809672239],
              [-34.975705333483305, -7.898012802957643],
              [-34.975682793370218, -7.897655694841056],
              [-34.975405145361265, -7.897377919813227],
              [-34.975191497143491, -7.897108525325279],
              [-34.9751427945831, -7.896724022268155],
              [-34.974983363501956, -7.896745924249557],
              [-34.974929362807416, -7.896388434880103],
              [-34.974916630218139, -7.89601094486424],
              [-34.97485201317582, -7.895554687563004],
              [-34.974475348255559, -7.895287557010633],
              [-34.973875928797291, -7.895516107017666],
              [-34.973980140372817, -7.896144004744612],
              [-34.97388654446025, -7.896256874260529],
              [-34.973579176860298, -7.896065444271998],
              [-34.973513180969405, -7.895786600343345],
              [-34.972925247812846, -7.896646303721331],
              [-34.972316830805333, -7.89741109754738],
              [-34.971701705448567, -7.89821989731657],
              [-34.971597642629682, -7.898400607877171],
              [-34.971635544291658, -7.89883289406789],
              [-34.97164344006233, -7.898922953725213],
              [-34.971825793336009, -7.900125753354893],
              [-34.971960684568849, -7.90106970127745],
              [-34.972084957323226, -7.901833038834021],
              [-34.972165766820922, -7.90242295651545],
              [-34.972278260781287, -7.903020756976721],
              [-34.972291756519581, -7.903110152992838],
              [-34.972393312227169, -7.903782857937534],
              [-34.972441945034362, -7.904152695671796],
              [-34.97268476361679, -7.904096590546021],
              [-34.972879339902732, -7.903974595379507],
              [-34.973193542972801, -7.904160048174579],
              [-34.972726359887602, -7.904497107634899],
              [-34.972405924735412, -7.904727792102452],
              [-34.972303529779317, -7.905245016075349],
              [-34.972885029925024, -7.905305376290876],
              [-34.973364310541101, -7.905226757864568],
              [-34.973689516206299, -7.905125042659316],
              [-34.974011387730954, -7.905039094231458],
              [-34.974590516410359, -7.905148595776406],
              [-34.974768376836344, -7.905463635269664],
              [-34.975191141636202, -7.905539217621411],
              [-34.975786977673458, -7.90559270008352],
              [-34.975829218328812, -7.904979314520438],
              [-34.976213824511952, -7.905369049208617],
              [-34.97645560364623, -7.905517047253767],
              [-34.976590331882463, -7.905877783278553],
              [-34.976861909528154, -7.905831310622863],
              [-34.977155587836755, -7.904947494312737],
              [-34.977340150675289, -7.904681368378409],
              [-34.977259849794635, -7.904144801863255],
              [-34.977432450197064, -7.903870812392671],
              [-34.977389198925351, -7.903216631372859],
              [-34.977182162344569, -7.902804269224388],
              [-34.977009799452546, -7.90253141265386],
              [-34.977186451709585, -7.902389790653088],
              [-34.977351532024521, -7.902651408154279],
              [-34.977566934451154, -7.902813147024325],
              [-34.977815293328817, -7.90316937817906],
              [-34.97789083965354, -7.903793783221733],
              [-34.978023481640243, -7.904076644481287],
              [-34.978268828609224, -7.904680528327156],
              [-34.978530287640993, -7.904407212766312],
              [-34.978863819742763, -7.904401806302858],
              [-34.979243367960734, -7.903994742413775],
              [-34.979555095319178, -7.903849053253716],
              [-34.979968902946645, -7.90356979107437],
              [-34.980412786206969, -7.903562849870838],
              [-34.98011646291409, -7.903805344535204],
              [-34.979767999619042, -7.903953722665554],
              [-34.979457566765859, -7.904110716480137],
              [-34.979225840520748, -7.90432089844207],
              [-34.979028041603677, -7.904893303643263],
              [-34.979403613043935, -7.90512586906144],
              [-34.979160097677607, -7.905598341210043],
              [-34.97918890599933, -7.905873478116562],
              [-34.97871135964121, -7.90539089775986],
              [-34.978601366642053, -7.905663958772863],
              [-34.978222948995871, -7.905877935740795],
              [-34.977912266487259, -7.905872606847387],
              [-34.977739788721216, -7.906069294001647],
              [-34.977930693870263, -7.906195130743863],
              [-34.978193613572039, -7.90622541142073],
              [-34.97864989593581, -7.906407639952874],
              [-34.979026770502628, -7.906674488435906],
              [-34.979394659134684, -7.907046252999876],
              [-34.979835125373306, -7.907239415959353],
              [-34.980573882113916, -7.907770700343929],
              [-34.980896787173776, -7.908031909948789],
              [-34.981725633646263, -7.908442699619468],
              [-34.981876862237094, -7.908628918249614],
              [-34.982100472201637, -7.909056592343624],
              [-34.982268883267601, -7.909209081034687],
              [-34.982424405044021, -7.909114348393191],
              [-34.982336617022284, -7.908907843629859],
              [-34.982168424711801, -7.908741087495649],
              [-34.982725966411934, -7.908567105581328],
              [-34.982999980132135, -7.908735916014826],
              [-34.983856803753824, -7.90810890370184],
              [-34.984172675421895, -7.90763154848309],
              [-34.983919854848715, -7.90751158478162],
              [-34.983673542101279, -7.907547212193246],
              [-34.982851178267154, -7.907204418130579],
              [-34.982775913892546, -7.907157896093412],
              [-34.982871895646198, -7.906840220282704],
              [-34.983108636683575, -7.907109324329999],
              [-34.983466566957766, -7.907176095378078],
              [-34.983676675218874, -7.907277400411532],
              [-34.983891018562865, -7.907185108395525],
              [-34.984306568756573, -7.907206187355333],
              [-34.984418315918006, -7.907361460111335],
              [-34.984671095249638, -7.907059824935476],
              [-34.984962007394877, -7.906415120987946],
              [-34.985094621699567, -7.905937099287615],
              [-34.985200792690833, -7.905597291955498],
              [-34.985346020691956, -7.905469676346379],
              [-34.985490810634921, -7.905937434643936],
              [-34.985554596513282, -7.906210184621978],
              [-34.985321151253537, -7.906352707986018],
              [-34.985333232630659, -7.906673952894622],
              [-34.985186468657943, -7.906885023405617],
              [-34.985399096149941, -7.907536179640472],
              [-34.985835536862382, -7.907736062770132],
              [-34.98590523382304, -7.907927237345292],
              [-34.985270780159532, -7.908003516511616],
              [-34.985268809945481, -7.90868373826661],
              [-34.984698642775122, -7.909206092785195],
              [-34.985045825572612, -7.909378563961708],
              [-34.984973944392827, -7.909609431661561],
              [-34.985293042717451, -7.909773759616364],
              [-34.985652476561739, -7.909784571154143],
              [-34.985845265283935, -7.909847995902045],
              [-34.986272643634969, -7.910135491201712],
              [-34.986744311615773, -7.910786544862343],
              [-34.986886801436555, -7.910845159533832],
              [-34.987192180810737, -7.910907549617793],
              [-34.987640393238436, -7.911360375853461],
              [-34.988134285118356, -7.911042899027488],
              [-34.988189269830151, -7.910408651920037],
              [-34.988121371829898, -7.910199570626185],
              [-34.987668967862113, -7.910027305082936],
              [-34.987138688833738, -7.909432749917028],
              [-34.987228727437191, -7.909422091661628],
              [-34.987290854080086, -7.909414737460478],
              [-34.987483465657618, -7.909521407680539],
              [-34.987672423882053, -7.909527247746504],
              [-34.987979392719716, -7.909712779475886],
              [-34.988258395736892, -7.909270349295529],
              [-34.988490668519972, -7.909227596616661],
              [-34.988695752005299, -7.909299876302175],
              [-34.988448057859557, -7.909420450663984],
              [-34.988365524623759, -7.909597932445672],
              [-34.988412826553052, -7.909759519447469],
              [-34.988974760399998, -7.910113383759564],
              [-34.989088833586194, -7.91021005046767],
              [-34.989190550590934, -7.909851994373844],
              [-34.989288209080648, -7.909598772750042],
              [-34.989249279790897, -7.909272825584227],
              [-34.989407211092363, -7.908790734013163],
              [-34.989442744515301, -7.908622109169119],
              [-34.98938538686825, -7.908283374727607],
              [-34.98949883138831, -7.908013094408833],
              [-34.989773767543142, -7.907830072181667],
              [-34.989783255391991, -7.907557131386644],
              [-34.989961906297381, -7.907252762388014],
              [-34.990095435667648, -7.906910530583234],
              [-34.990176492549878, -7.906984569894393],
              [-34.990177084390467, -7.907443631174558],
              [-34.990512181222392, -7.907291252794433],
              [-34.990699896195487, -7.907307160597665],
              [-34.991109987100501, -7.907114940204552],
              [-34.991549760133843, -7.907066298557846],
              [-34.992132035149183, -7.906884321351695],
              [-34.992484718171234, -7.90688023280213],
              [-34.992699050724958, -7.906677418165158],
              [-34.993303828619965, -7.906757732848738],
              [-34.993487464823723, -7.90668170316647],
              [-34.993761722861009, -7.906713363845581],
              [-34.994171863142952, -7.906615956289137],
              [-34.994198237399559, -7.906782858482633],
              [-34.994734970757527, -7.906836228032933],
              [-34.995320922223286, -7.906647588525323],
              [-34.995378198334322, -7.907024328461118],
              [-34.995822191631056, -7.907311680414464],
              [-34.996629060921641, -7.907558385196633],
              [-34.997287031306598, -7.907687981973737],
              [-34.997535247812948, -7.907849660944819],
              [-34.997310819777489, -7.908115534760885],
              [-34.996927425955114, -7.907944590411885],
              [-34.996665489735889, -7.907807640214272],
              [-34.995888384175771, -7.907706782265143],
              [-34.995731065533171, -7.907878909774752],
              [-34.995624943847005, -7.907554935434987],
              [-34.994950219682742, -7.907145051070849],
              [-34.994682394742377, -7.907259106866722],
              [-34.994472450803798, -7.907189252701649],
              [-34.99418667646151, -7.907186071530662],
              [-34.99393249016461, -7.90711827338943],
              [-34.99341539775876, -7.907187371626279],
              [-34.993010238251564, -7.907376074651345],
              [-34.992864316747479, -7.907347086198557],
              [-34.992705024964984, -7.907475032859722],
              [-34.992305079266721, -7.907504478081559],
              [-34.992075337504112, -7.907622821303795],
              [-34.991561972758568, -7.907780361539228],
              [-34.991249205527708, -7.907834449847083],
              [-34.99108313205069, -7.908041425114599],
              [-34.990786465664904, -7.908074246789162],
              [-34.990560383227013, -7.908165975532156],
              [-34.990480012723403, -7.908343647817135],
              [-34.990555653904565, -7.908582434849856],
              [-34.990553523995381, -7.908849112391653],
              [-34.990416258532221, -7.909119068687385],
              [-34.9903826263646, -7.909400124177292],
              [-34.99043559148857, -7.909677190886812],
              [-34.990387248288641, -7.909933600183914],
              [-34.990128387176739, -7.910363014869641],
              [-34.990149675482222, -7.911047119580273],
              [-34.990081669212302, -7.911359784875196],
              [-34.990122045850455, -7.911809998872374],
              [-34.990216217800238, -7.911922274254246],
              [-34.990582003725066, -7.911666569307194],
              [-34.99087396251204, -7.911568442738857],
              [-34.990978864248433, -7.911473302553052],
              [-34.991224316368822, -7.911413011400534],
              [-34.991270660806741, -7.911562898186156],
              [-34.991161496758501, -7.911846576321289],
              [-34.991150179459353, -7.912002836094381],
              [-34.991287704867659, -7.912011917989506],
              [-34.991539840057584, -7.911537713900943],
              [-34.992289642847425, -7.910811879163901],
              [-34.992930624916774, -7.910506487563149],
              [-34.993162673860716, -7.910384344454629],
              [-34.993233398176159, -7.910486161309052],
              [-34.992914744798767, -7.910934033117252],
              [-34.992747205514632, -7.91104317346945],
              [-34.992516264976587, -7.911434449837603],
              [-34.992471399993264, -7.911716687516259],
              [-34.992800653066105, -7.911788855295881],
              [-34.992984837384306, -7.911746772338576],
              [-34.993089319573635, -7.9119123597507],
              [-34.992615123163297, -7.912058503725609],
              [-34.9922796604178, -7.912309095594038],
              [-34.992575864581831, -7.912414331762045],
              [-34.992675854058376, -7.912688784598696],
              [-34.992683439064251, -7.91317879494713],
              [-34.993034602694806, -7.913347274682933],
              [-34.99336999919074, -7.91375300747019],
              [-34.993881133427578, -7.913899667281193],
              [-34.994604929766261, -7.913643403340786],
              [-34.994903400479359, -7.913468722843527],
              [-34.995443793749459, -7.913246961661781],
              [-34.995448872680313, -7.913023365071434],
              [-34.995712090829976, -7.912893700024608],
              [-34.995700322409725, -7.913093595808435],
              [-34.995864775675734, -7.912986888087181],
              [-34.996037891049433, -7.91288753166048],
              [-34.996406886007357, -7.912820293917362],
              [-34.996941732261355, -7.91297949239712],
              [-34.99710305793306, -7.912897713713149],
              [-34.997197963253086, -7.913098967061864],
              [-34.997347791346158, -7.913346643738132],
              [-34.997683213648394, -7.913522652017083],
              [-34.997941909277252, -7.913369532479739],
              [-34.998279645247123, -7.912969155259908],
              [-34.998530352080373, -7.912698179820106],
              [-34.998804283596691, -7.9128940852571],
              [-34.999024043072772, -7.912847445981828],
              [-34.999335122970052, -7.912913584784989],
              [-34.999578378088877, -7.912764701346315],
              [-35.000141494960133, -7.912917348310439],
              [-35.000292778355977, -7.912888369947122],
              [-35.000459194953699, -7.912765325226446],
              [-35.000579328865342, -7.912865041848966],
              [-35.00045713943662, -7.913413393873948],
              [-35.000680123547198, -7.913592270382647],
              [-35.000931184002269, -7.913438784102761],
              [-35.001478622262105, -7.913467539030735],
              [-35.001637235102578, -7.913408049954029],
              [-35.001863340628859, -7.91314272769196],
              [-35.001929884151522, -7.913294276997925],
              [-35.002430105125065, -7.912923566335394],
              [-35.002794374079613, -7.912757618871829],
              [-35.002912425698781, -7.912481724805152],
              [-35.003119187096218, -7.912286189464971],
              [-35.003342692700585, -7.912344493543162],
              [-35.003583769910435, -7.912166572038686],
              [-35.003872922319147, -7.912235936716933],
              [-35.004070632380078, -7.912044046832819],
              [-35.00431876047282, -7.912197105990711],
              [-35.004310433647262, -7.91233290636799],
              [-35.003593679771292, -7.912547581887037],
              [-35.00334784463351, -7.912793663705265],
              [-35.003433101707444, -7.913037359839938],
              [-35.003885014856479, -7.913069791989413],
              [-35.004148714591288, -7.913185610452455],
              [-35.004280440272709, -7.913065462997936],
              [-35.004899169888226, -7.913009433137788],
              [-35.005140172649249, -7.913009745085589],
              [-35.005303554089252, -7.913234665639762],
              [-35.005534332829122, -7.913312804547711],
              [-35.005762791493026, -7.913540031004142],
              [-35.005965071531314, -7.913548728025572],
              [-35.006474491176675, -7.913861295001771],
              [-35.006628084575688, -7.91386032382714],
              [-35.006856880298628, -7.9136221630836],
              [-35.006957372298459, -7.913746805055528],
              [-35.006826275659073, -7.91414538189509],
              [-35.007172407484639, -7.914381745905257],
              [-35.007468559976047, -7.914494936216117],
              [-35.007660720339267, -7.914432796602313],
              [-35.007800844268182, -7.914144078642387],
              [-35.008191337931336, -7.913979198304347],
              [-35.008273727881026, -7.913846752502091],
              [-35.008407471158058, -7.913783200812896],
              [-35.008674637166749, -7.914064822730383],
              [-35.009120243411012, -7.914193327682247],
              [-35.009366369018387, -7.914049390791995],
              [-35.009971186289668, -7.91391505259799],
              [-35.01018153977779, -7.91380248913675],
              [-35.010306426532537, -7.913487375205298],
              [-35.010802635074192, -7.913342009722593],
              [-35.011433811485915, -7.913740586736118],
              [-35.01179121988342, -7.914006594513995],
              [-35.011255512660405, -7.914229932430945],
              [-35.010631985565723, -7.914325136542808],
              [-35.0108334467307, -7.914695435886578],
              [-35.0113321275253, -7.914516296878134],
              [-35.011521350342029, -7.914644953366096],
              [-35.01136882686221, -7.914815184184346],
              [-35.011076728500676, -7.914870441344928],
              [-35.010603785529675, -7.915066747362967],
              [-35.010182230225269, -7.914643778060094],
              [-35.00995667927333, -7.914329257127769],
              [-35.009543159017042, -7.914405994567169],
              [-35.00922332475097, -7.914621755600225],
              [-35.009661073658648, -7.915323452544594],
              [-35.010112519315776, -7.916082849726672],
              [-35.010343965469701, -7.916461280056642],
              [-35.011278190737919, -7.916333460812276],
              [-35.012549222823139, -7.916157853936467],
              [-35.013860540721559, -7.916028485840473],
              [-35.014482709781298, -7.915961271106154],
              [-35.014644967647222, -7.915943741766907],
              [-35.014899152062213, -7.915187468785565],
              [-35.015188635123266, -7.91433039241717],
              [-35.015346853914423, -7.91398781558113],
              [-35.015894743662891, -7.913152021062836],
              [-35.016588112412634, -7.912585919303168],
              [-35.016864784142584, -7.911981143544862],
              [-35.016839094101272, -7.91124003001452],
              [-35.016819687763906, -7.910866181533834],
              [-35.016726377388672, -7.910085036133699],
              [-35.016874331311186, -7.909373065599715],
              [-35.017379406516952, -7.909208041737395],
              [-35.017811836232681, -7.908942379661073],
              [-35.018176978183327, -7.908760457800664],
              [-35.018617111506842, -7.908470023328676],
              [-35.018213293704569, -7.907467095669729],
              [-35.017871485479716, -7.907557062013441],
              [-35.017834172297711, -7.907042217264606],
              [-35.017878200584121, -7.90676214045921],
              [-35.017706204396838, -7.906138752813862],
              [-35.017644126626692, -7.905683708015543],
              [-35.017774440460798, -7.905261552970805],
              [-35.017703006856621, -7.904804306199251],
              [-35.017253125467739, -7.905297189074044],
              [-35.016795237316025, -7.905527827316385],
              [-35.016545218218184, -7.905375775449827],
              [-35.016839584220307, -7.906170357232023],
              [-35.01674530567162, -7.906417410094162],
              [-35.016530480095867, -7.906711773883449],
              [-35.016100932052929, -7.907038237548756],
              [-35.015780108915457, -7.907101060196112],
              [-35.015539005948348, -7.907472905856996],
              [-35.015507213116692, -7.907807063434523],
              [-35.015623435084819, -7.908070839714405],
              [-35.016120063555597, -7.908283462603106],
              [-35.016251403359867, -7.908454175571161],
              [-35.016152756421015, -7.908519973597875],
              [-35.015908636853872, -7.908365312594587],
              [-35.015474496669796, -7.908255816891721],
              [-35.015305725017711, -7.908176039224856],
              [-35.015194209980571, -7.907688644377101],
              [-35.015152765871889, -7.907158696809387],
              [-35.01508743769994, -7.906833290677901],
              [-35.01515703874, -7.906508766389343],
              [-35.015161459174287, -7.906190767260781],
              [-35.015495735069571, -7.90624074348781],
              [-35.01533638536084, -7.905672209306454],
              [-35.015189543658259, -7.905143087844004],
              [-35.014943397623789, -7.904989941726319],
              [-35.014771514494171, -7.904607781952124],
              [-35.014573939808223, -7.904838275175083],
              [-35.014395925169978, -7.905353018008826],
              [-35.014395895570495, -7.905616897783977],
              [-35.014429332058306, -7.905951259486972],
              [-35.01423962719965, -7.906340584463283],
              [-35.014014377001992, -7.906590648263058],
              [-35.013949268308217, -7.906768949742096],
              [-35.013741157364848, -7.906887592272723],
              [-35.013845726098175, -7.907260101994508],
              [-35.013832857037741, -7.907468442642507],
              [-35.013735021865138, -7.907432488316165],
              [-35.013662916819861, -7.907144727858954],
              [-35.013503682346425, -7.90697696857747],
              [-35.013515469012034, -7.906696882156576],
              [-35.013740699537948, -7.906560556954148],
              [-35.013747104000174, -7.906075870043257],
              [-35.013611368636383, -7.905915647500275],
              [-35.013497373330132, -7.905626766087036],
              [-35.013402897213155, -7.905439380005766],
              [-35.013343128408252, -7.905238294504721],
              [-35.013363781106548, -7.904852561094927],
              [-35.013323419040603, -7.90461126936688],
              [-35.01296255210039, -7.904676017243502],
              [-35.012667500839434, -7.904375744354446],
              [-35.012618267268081, -7.904012014392133],
              [-35.012643102744526, -7.903718259035065],
              [-35.012209598334472, -7.903509640512762],
              [-35.012105513997277, -7.903648091629377],
              [-35.012053492856026, -7.903946001063748],
              [-35.011739129543031, -7.904018613929328],
              [-35.011717884088064, -7.904477361691606],
              [-35.011853493087109, -7.904714539833523],
              [-35.0119010301188, -7.904983129444807],
              [-35.011968854658768, -7.905380514138928],
              [-35.011939634598555, -7.905775215439999],
              [-35.012098257288713, -7.906185982809755],
              [-35.011978994200163, -7.906246116754621],
              [-35.011716086900307, -7.905978987398367],
              [-35.011598166843626, -7.905494859833357],
              [-35.011381608804498, -7.905230003776126],
              [-35.011332973246191, -7.904999001826869],
              [-35.011077710291204, -7.904763193712582],
              [-35.010820939956162, -7.904560078996096],
              [-35.010550185876951, -7.90463990085573],
              [-35.010576668927044, -7.905075919503203],
              [-35.010583663170614, -7.905409606971009],
              [-35.010466271410635, -7.905634450863607],
              [-35.0105405472654, -7.905895252760773],
              [-35.01056159901794, -7.906238043987181],
              [-35.010712546591584, -7.906634703588002],
              [-35.010619021473687, -7.90664669965506],
              [-35.010279356675227, -7.906285137001329],
              [-35.010150654483446, -7.905956949091675],
              [-35.009816508916401, -7.905561485407297],
              [-35.009537125608105, -7.905380106630643],
              [-35.009408786376788, -7.905624134875372],
              [-35.009436428897558, -7.90603870282804],
              [-35.009494252791939, -7.906455825325153],
              [-35.009659667951233, -7.906740465795274],
              [-35.009988516353481, -7.906942470394299],
              [-35.010235652100206, -7.906906793577164],
            ],
          ],
          [
            [
              [-34.994951840453908, -7.855556322858098],
              [-34.994067124090037, -7.857727305231464],
              [-34.993960489242987, -7.857647418144721],
              [-34.991929171983621, -7.856176445646111],
              [-34.991405322058768, -7.857467104592885],
              [-34.991304808516638, -7.857722797913221],
              [-34.991315335476102, -7.857876744638155],
              [-34.991481012651292, -7.858084902677069],
              [-34.9914810447953, -7.858312436914038],
              [-34.99146241178731, -7.858657662747209],
              [-34.991580710027939, -7.859180249759964],
              [-34.992022789660389, -7.859542921877519],
              [-34.992577029808032, -7.85955447698043],
              [-34.992573854502574, -7.85962447833517],
              [-34.99222289258789, -7.859886125747837],
              [-34.991827050081589, -7.860108040843603],
              [-34.991238262089055, -7.860233390129411],
              [-34.990854634384263, -7.860113027316004],
              [-34.990367226773408, -7.86075545646905],
              [-34.990105443476892, -7.860952284443913],
              [-34.9898539904556, -7.861225530114313],
              [-34.989637920769454, -7.861490697609872],
              [-34.989379838137424, -7.861827855179309],
              [-34.989192073290674, -7.862192674133867],
              [-34.989174680532585, -7.862591453956519],
              [-34.989225225048841, -7.863126498892988],
              [-34.989486285366063, -7.863358867829716],
              [-34.989489878973743, -7.863841723885301],
              [-34.989594005162232, -7.864068229988054],
              [-34.989789780187841, -7.864055725208037],
              [-34.990195874569885, -7.863866322356472],
              [-34.990555417957275, -7.86389722511876],
              [-34.991365310487353, -7.864235159409466],
              [-34.991723812189292, -7.864492451384296],
              [-34.992228349251398, -7.864583000152471],
              [-34.992515747564724, -7.864486876921221],
              [-34.992869784404888, -7.864609744411704],
              [-34.993055673563134, -7.864454872174741],
              [-34.993443768852572, -7.864116187598783],
              [-34.993642534989256, -7.863807227217762],
              [-34.993878023603557, -7.863814932771289],
              [-34.994359075035639, -7.863712201316928],
              [-34.99428642675344, -7.864501682734698],
              [-34.994089473466964, -7.864886518926161],
              [-34.994364516313915, -7.864814708411306],
              [-34.995031657890877, -7.864734851434728],
              [-34.99534443971335, -7.864757381731562],
              [-34.995801369729989, -7.864793784601281],
              [-34.995972442542588, -7.865156512296409],
              [-34.995604030153714, -7.865323230982613],
              [-34.995375073628772, -7.865514248173961],
              [-34.994815244475006, -7.865713820030605],
              [-34.994575579584037, -7.865749750461213],
              [-34.994395214476896, -7.865926423417151],
              [-34.994493875313417, -7.866185317067104],
              [-34.994510407259682, -7.866560027112193],
              [-34.99458460145739, -7.866880225286256],
              [-34.994805804060661, -7.86723440636719],
              [-34.994974648859824, -7.867477695144736],
              [-34.995212682580807, -7.867629492972605],
              [-34.995417720145106, -7.867861537071509],
              [-34.995507212016449, -7.868032735261594],
              [-34.995543612392872, -7.868224701822841],
              [-34.995635197654543, -7.868619634996001],
              [-34.99588359637626, -7.868642948520653],
              [-34.996393039341427, -7.868572880338474],
              [-34.996638388185907, -7.868627055552812],
              [-34.997029760664262, -7.868638276372875],
              [-34.997518785475037, -7.868768846060677],
              [-34.997834794043968, -7.868762604314052],
              [-34.997729319251384, -7.869233429255073],
              [-34.997864314728751, -7.869491684465958],
              [-34.998009695496094, -7.869580704514733],
              [-34.998282481098414, -7.869823452129317],
              [-34.998308375633542, -7.870075458145402],
              [-34.998501487964006, -7.870146880309517],
              [-34.99871875978701, -7.870412876221438],
              [-34.998252291374222, -7.870167594647323],
              [-34.998087854704721, -7.869861246149233],
              [-34.997839671912892, -7.869784502485202],
              [-34.997700883806019, -7.869567837156827],
              [-34.997549660839915, -7.869463958184385],
              [-34.997417854161711, -7.869573730615363],
              [-34.997217299130725, -7.869483960592718],
              [-34.99754495625811, -7.869261214122695],
              [-34.997304271155684, -7.86904465282245],
              [-34.996815179959135, -7.868977948246487],
              [-34.996476472940827, -7.869056077742689],
              [-34.996197463918705, -7.869050247382775],
              [-34.995982302221194, -7.869157351697452],
              [-34.995622261819271, -7.869126298040481],
              [-34.995265750400705, -7.868968261047527],
              [-34.995015938906015, -7.868689958819515],
              [-34.994857093169543, -7.868410304202872],
              [-34.994707439870851, -7.868307464428007],
              [-34.994434329829552, -7.868227125789075],
              [-34.994136397366717, -7.867976378066595],
              [-34.993715928447095, -7.86763076954466],
              [-34.993575967772202, -7.867311059416763],
              [-34.993384218707376, -7.866893310722432],
              [-34.993106546125006, -7.866445857789865],
              [-34.992894402098955, -7.866233491520839],
              [-34.992645680877338, -7.866371635597317],
              [-34.99244701750915, -7.866482358885971],
              [-34.992148977576228, -7.866648158635789],
              [-34.991900221456063, -7.866664945649262],
              [-34.991801716624991, -7.866899067704932],
              [-34.991497258485992, -7.86705605496488],
              [-34.991229034814943, -7.867048774732726],
              [-34.991162716038616, -7.866771993776221],
              [-34.990802326354078, -7.86658914134461],
              [-34.990472853263235, -7.866754696414755],
              [-34.989995084293263, -7.867008527473518],
              [-34.989567946517511, -7.867494862585011],
              [-34.989444250214042, -7.867817943208729],
              [-34.989047894055389, -7.868751523022472],
              [-34.989016279620849, -7.868824895863026],
              [-34.988703132814969, -7.86963758585646],
              [-34.988371616008429, -7.870478834429782],
              [-34.988196194954739, -7.87088975022124],
              [-34.988075347056238, -7.871174191215611],
              [-34.988612278536472, -7.870900167171757],
              [-34.988956493261576, -7.870722505363431],
              [-34.989013546655968, -7.871060534989015],
              [-34.989076464904585, -7.871465165405959],
              [-34.988962941187545, -7.871796356612116],
              [-34.988813233797849, -7.872245785302442],
              [-34.988719616460827, -7.872528321340345],
              [-34.988876944463648, -7.872517132669643],
              [-34.989114097483757, -7.872507761150418],
              [-34.989232115796582, -7.872194792507551],
              [-34.989388516481384, -7.871812769922474],
              [-34.989574929258616, -7.871547551712903],
              [-34.990079126746004, -7.871098975590182],
              [-34.990325904042017, -7.871130855623312],
              [-34.990553134513149, -7.871157603635372],
              [-34.990841315405277, -7.871251132661039],
              [-34.990975368423697, -7.871506383551062],
              [-34.990891849698265, -7.871835297060663],
              [-34.990861142436088, -7.872047518962518],
              [-34.990898732495474, -7.872305413429922],
              [-34.991092159741122, -7.872113391060092],
              [-34.9912593780574, -7.871938766900503],
              [-34.991542560065596, -7.871944300921829],
              [-34.991736359702784, -7.872186857876129],
              [-34.991677212533553, -7.873053210768812],
              [-34.991791415113148, -7.873236329767086],
              [-34.992080427704074, -7.873399461783605],
              [-34.992399855654966, -7.873435208964223],
              [-34.992517225077258, -7.873781069306351],
              [-34.99226729189553, -7.874036283291185],
              [-34.992145827949315, -7.874236019698311],
              [-34.991601454918502, -7.874541811144677],
              [-34.991733400054272, -7.87464227074077],
              [-34.991981898069099, -7.874580436204702],
              [-34.992226882042772, -7.874350363246267],
              [-34.992544774063006, -7.874387517558586],
              [-34.993104361552213, -7.874305959903364],
              [-34.993691417506426, -7.874359356311757],
              [-34.993863781025276, -7.874183929620695],
              [-34.994125236820523, -7.874086668718349],
              [-34.99435946740099, -7.873823110858138],
              [-34.994561025785167, -7.873889364575315],
              [-34.99435013018276, -7.874159800264379],
              [-34.994506555866238, -7.874441934482383],
              [-34.994749775066403, -7.874252199694013],
              [-34.995099282846468, -7.874247399494041],
              [-34.995373267858305, -7.874244317929078],
              [-34.995411419828038, -7.873938534193273],
              [-34.995663187393916, -7.873701679155392],
              [-34.995686672826615, -7.87343286986611],
              [-34.996028010283588, -7.873048435453581],
              [-34.996340282006251, -7.872983922801757],
              [-34.996744024214514, -7.873226596101853],
              [-34.997072859768828, -7.873149674265338],
              [-34.997512814371426, -7.873170380067529],
              [-34.998049675405163, -7.873016315019737],
              [-34.998356112440625, -7.873066345836582],
              [-34.998711612198157, -7.873013068938723],
              [-34.99901699245757, -7.873122880984575],
              [-34.999312788382014, -7.873618743152541],
              [-34.99941320486154, -7.873748991425147],
              [-34.99941899058495, -7.873927972082092],
              [-34.999245364034124, -7.874156627814263],
              [-34.999171271410432, -7.874510646640119],
              [-34.99878063791202, -7.874716506117207],
              [-34.998551043594944, -7.874884029996801],
              [-34.99841699269561, -7.87512228926198],
              [-34.998384004458401, -7.875352864701237],
              [-34.998525919411087, -7.875539373691794],
              [-34.998621141797997, -7.875966185361261],
              [-34.998828296617333, -7.87608459512431],
              [-34.999195651197077, -7.875980236873598],
              [-34.99901454039702, -7.875657213394384],
              [-34.999159505052951, -7.875389921061624],
              [-34.999341938279827, -7.875295972929051],
              [-34.999489242867725, -7.874851470995381],
              [-34.999820456176792, -7.874727426643575],
              [-35.000160618038798, -7.874971006800021],
              [-35.000361276026865, -7.875080849743965],
              [-35.000627211469933, -7.874950881886894],
              [-35.000691874472409, -7.874689207308522],
              [-35.000906540320599, -7.874569212028189],
              [-35.001426473612376, -7.874634572226165],
              [-35.001653828453222, -7.874807886417341],
              [-35.001721791350995, -7.875485123034625],
              [-35.002103477048863, -7.875620847174532],
              [-35.002152319202203, -7.875675379146178],
              [-35.002335695651063, -7.875852857654369],
              [-35.002589290620996, -7.876107319854675],
              [-35.002498727737077, -7.876743977320788],
              [-35.002696006906071, -7.876808323639702],
              [-35.003131556077825, -7.876630437538449],
              [-35.00355972771947, -7.876638721660772],
              [-35.003845582070348, -7.876953600891873],
              [-35.00437280797312, -7.877013148579625],
              [-35.004642533177396, -7.877103234837178],
              [-35.005473262332778, -7.876821027922492],
              [-35.005884630761678, -7.876634752873031],
              [-35.005880085742639, -7.876326157861158],
              [-35.005716376765584, -7.876026007315158],
              [-35.005330183131946, -7.875300302130254],
              [-35.005275062091236, -7.874356451044489],
              [-35.005442503897306, -7.873819219856745],
              [-35.005558457960909, -7.873449801279442],
              [-35.005584015830792, -7.873376670125618],
              [-35.006197642673122, -7.873152930147536],
              [-35.006134238068583, -7.873528538309187],
              [-35.00611745667711, -7.873640483496883],
              [-35.006531558955778, -7.873849060176898],
              [-35.006765253779719, -7.874367830969718],
              [-35.006914811464021, -7.87448796804091],
              [-35.007166609624093, -7.874536263186921],
              [-35.007599919069754, -7.874193119557231],
              [-35.007891143986903, -7.874206616530163],
              [-35.008148049473363, -7.874361422817288],
              [-35.008353562490079, -7.874402092813694],
              [-35.008515269706322, -7.874274025059016],
              [-35.009020930538732, -7.87390743039006],
              [-35.009166776490055, -7.873446216346423],
              [-35.009387197143326, -7.873727016753112],
              [-35.00991035927602, -7.873919882495083],
              [-35.010187991323981, -7.874094819314188],
              [-35.010442356853758, -7.87384470237375],
              [-35.010722305483327, -7.873268214212967],
              [-35.010881897163372, -7.873060024936702],
              [-35.01103281592961, -7.872990294069846],
              [-35.011302876162908, -7.873030011659646],
              [-35.011543284380636, -7.873188873292326],
              [-35.011684986006365, -7.873116100747674],
              [-35.012222018473338, -7.872882445655111],
              [-35.012573613898383, -7.873055434473366],
              [-35.012820222244038, -7.87331720121517],
              [-35.012922056058542, -7.873100661179833],
              [-35.013197491966295, -7.873121053149073],
              [-35.013610756739304, -7.872923618000805],
              [-35.013713903474859, -7.872505878119084],
              [-35.013729627446061, -7.872071011977243],
              [-35.013886194051814, -7.871535473005955],
              [-35.014271324924692, -7.870985111364066],
              [-35.014664641017944, -7.87062153179588],
              [-35.014774987095308, -7.870510893060064],
              [-35.01476906934424, -7.870087984014705],
              [-35.014808462192256, -7.86988833656928],
              [-35.014993382571305, -7.869674558778967],
              [-35.015326973448161, -7.869830332972518],
              [-35.015560566247174, -7.870092023581669],
              [-35.015833982243898, -7.870382083324507],
              [-35.016312959749214, -7.870445513125646],
              [-35.016583077479432, -7.87035424745943],
              [-35.016731448285448, -7.870782232631448],
              [-35.016731577256458, -7.870965288191204],
              [-35.016638507037797, -7.871082941894125],
              [-35.01645827657368, -7.871237993997626],
              [-35.016282578581986, -7.871289667299297],
              [-35.016126732563265, -7.87141942733965],
              [-35.016047630844461, -7.871603107509068],
              [-35.015777314330087, -7.871996581056192],
              [-35.015710357643499, -7.872132931195686],
              [-35.015800342730188, -7.872415596932415],
              [-35.01686945092964, -7.872206722155951],
              [-35.017270643321488, -7.872263725106436],
              [-35.017575783582402, -7.872409591935261],
              [-35.017817557913716, -7.872484284606319],
              [-35.01786262385086, -7.872674664241342],
              [-35.018043819079168, -7.872947321399724],
              [-35.017930024457762, -7.87316899684459],
              [-35.017760957388248, -7.873304150187483],
              [-35.017672073405684, -7.873338536466597],
              [-35.017099044091474, -7.873330529490173],
              [-35.016761928859466, -7.873548924698603],
              [-35.016391966453668, -7.873790192504832],
              [-35.01607231238534, -7.873936133157332],
              [-35.01616028389472, -7.874096816631142],
              [-35.01628438304251, -7.874085889221123],
              [-35.016766604652943, -7.873935291187565],
              [-35.01735212091635, -7.87380994603669],
              [-35.018030711484435, -7.874056818208323],
              [-35.018523257871848, -7.874118597774279],
              [-35.018823783524134, -7.87415481633085],
              [-35.019093345450528, -7.874450146259681],
              [-35.019162608253247, -7.8747504596239],
              [-35.019657079680599, -7.87522251058662],
              [-35.019743088624807, -7.875308626349766],
              [-35.019880420255099, -7.875523991416782],
              [-35.020016395704538, -7.875672961350853],
              [-35.020125882176472, -7.875969406916819],
              [-35.02026328652947, -7.876098674460597],
              [-35.020418650601833, -7.876423246064856],
              [-35.020623034465913, -7.876492550112807],
              [-35.020747208250768, -7.876859908292049],
              [-35.020720211027609, -7.877183929977761],
              [-35.02112781729668, -7.87739583906011],
              [-35.02154793130066, -7.877612806897225],
              [-35.02129398628994, -7.877844643213938],
              [-35.020799465988866, -7.877658192768223],
              [-35.02037086895713, -7.877555263780486],
              [-35.020209033147232, -7.877137087885521],
              [-35.019932602605259, -7.876877514466928],
              [-35.019205476241062, -7.876374063389987],
              [-35.018606796072241, -7.87632848779757],
              [-35.017839392443619, -7.876128956803379],
              [-35.01685445070985, -7.876427374015887],
              [-35.016332699422861, -7.876879939701989],
              [-35.01610782942592, -7.876995342229427],
              [-35.015668149063316, -7.877125935078261],
              [-35.015781242535859, -7.877289856553021],
              [-35.016052949224026, -7.877596484773175],
              [-35.016476420504027, -7.878080844851523],
              [-35.016215147166349, -7.878379558064074],
              [-35.015902355313244, -7.878555850500528],
              [-35.015582530216435, -7.878720156372435],
              [-35.015039219026171, -7.878794186554355],
              [-35.015377338070245, -7.879136772183565],
              [-35.015715591398674, -7.879382748145319],
              [-35.016116587308346, -7.879002751177786],
              [-35.016441216757492, -7.878904976519477],
              [-35.016805405267419, -7.878691122845318],
              [-35.017020120899303, -7.878749357442285],
              [-35.017080073114258, -7.878864296955943],
              [-35.017417317295667, -7.879053300319693],
              [-35.017657062960225, -7.879271980257339],
              [-35.017757123172736, -7.879291110640072],
              [-35.017976793344765, -7.87927183108543],
              [-35.018273126530602, -7.879511478213834],
              [-35.018678408809087, -7.879851303987567],
              [-35.019039521491344, -7.879919780448152],
              [-35.019482552878557, -7.87975753109957],
              [-35.019509991241101, -7.880001351191311],
              [-35.019563998614025, -7.880559677654566],
              [-35.019578376275241, -7.88070671518214],
              [-35.019589780299995, -7.880839264922831],
              [-35.019364590656103, -7.881241127469486],
              [-35.019394985516747, -7.881827429227721],
              [-35.019513161748385, -7.881975227652979],
              [-35.019702705941988, -7.881871117352078],
              [-35.020275984633372, -7.881555528118513],
              [-35.02079240715986, -7.881515466330749],
              [-35.02127534333259, -7.881661954741052],
              [-35.021584548394642, -7.881895423013797],
              [-35.021619110772278, -7.881921983312281],
              [-35.021769618771906, -7.882237966465087],
              [-35.021777507595743, -7.8826440393254],
              [-35.021593583034502, -7.882947429293417],
              [-35.021240589436999, -7.883308937670371],
              [-35.021098141533905, -7.883592197591391],
              [-35.020867009440579, -7.883797701841374],
              [-35.02064409910917, -7.883984287038522],
              [-35.02037481804188, -7.884071220861899],
              [-35.020092032032238, -7.884052563948346],
              [-35.019838254147565, -7.884079963376585],
              [-35.020019553958925, -7.884260559788747],
              [-35.020206625319453, -7.884444344895535],
              [-35.020396558351528, -7.884323521214875],
              [-35.020737337935813, -7.884115764527759],
              [-35.021161769937549, -7.883992153993741],
              [-35.021457630603365, -7.883903647288754],
              [-35.021575530976129, -7.883515349567455],
              [-35.021697589995242, -7.883107247580747],
              [-35.021820858600371, -7.882921355567402],
              [-35.022751005823793, -7.883044587062909],
              [-35.024375434786336, -7.874271215409949],
              [-35.024286466394656, -7.87408729197829],
              [-35.02419626226952, -7.873939884315035],
              [-35.02410276982944, -7.873837982701543],
              [-35.023950569510035, -7.873751236257982],
              [-35.023827554235865, -7.873712463532428],
              [-35.023583133980026, -7.873605166567326],
              [-35.023393219877022, -7.873520788923498],
              [-35.023078319531443, -7.873353032263745],
              [-35.022881393454213, -7.873270000378164],
              [-35.022502765622285, -7.873167724166815],
              [-35.022352477405406, -7.873113335848561],
              [-35.022099104228261, -7.872968901464226],
              [-35.022036373626662, -7.872878223423433],
              [-35.021971981766903, -7.872806799263652],
              [-35.021817713568424, -7.872655325652378],
              [-35.021615994179243, -7.872488334071365],
              [-35.021442561046989, -7.872364946664473],
              [-35.021272794175026, -7.872273909586008],
              [-35.021061721533414, -7.87216819966553],
              [-35.020888415861599, -7.872071055749918],
              [-35.020610315698129, -7.871893059003112],
              [-35.020501240589624, -7.87183409719569],
              [-35.020404608972797, -7.871808318326575],
              [-35.020235151870303, -7.871781140631425],
              [-35.020136752921239, -7.871752745775425],
              [-35.019984659878091, -7.871687867321716],
              [-35.019878149015057, -7.87161489594408],
              [-35.019745191682034, -7.871515807465301],
              [-35.019518048411889, -7.871352436215375],
              [-35.019333143398043, -7.871215105869677],
              [-35.019109628275125, -7.871076211661601],
              [-35.01875442326903, -7.870918705230766],
              [-35.018367457415678, -7.870724609054162],
              [-35.018084319440362, -7.870592999568538],
              [-35.017773048371033, -7.870449277770381],
              [-35.017428405783022, -7.870298717667446],
              [-35.0169785452143, -7.870160910588693],
              [-35.016363547232089, -7.869981905626619],
              [-35.015653698454898, -7.869782359817584],
              [-35.015263157632255, -7.869574280249555],
              [-35.015048890784584, -7.869533318345844],
              [-35.014929669409582, -7.869553136980482],
              [-35.014649538174638, -7.86968045605346],
              [-35.013882617891909, -7.870016569160099],
              [-35.013262645653846, -7.87026099485406],
              [-35.012831750258286, -7.87041703072562],
              [-35.012526613658004, -7.870453487609137],
              [-35.012084430116168, -7.870452109716457],
              [-35.011698208959835, -7.87041197094734],
              [-35.011399634915186, -7.870353915159296],
              [-35.011220173262807, -7.870253296721637],
              [-35.01095260900734, -7.870076116523978],
              [-35.010681468218671, -7.869884956142375],
              [-35.010420855430809, -7.869693745057047],
              [-35.010174532855551, -7.869554954425076],
              [-35.009984476537859, -7.869440388967989],
              [-35.009829141926168, -7.869248672934534],
              [-35.009705525610791, -7.869084799085657],
              [-35.00943079242213, -7.868876158395572],
              [-35.009237025070938, -7.868719618890962],
              [-35.008800293897501, -7.868392777938848],
              [-35.008596016087907, -7.868239787624048],
              [-35.008328588798157, -7.868090598730973],
              [-35.007927759741904, -7.867931550195769],
              [-35.006855174937208, -7.86746602805412],
              [-35.006572251464803, -7.867378149350251],
              [-35.006287388354494, -7.867251787566491],
              [-35.00604468678489, -7.867135721833045],
              [-35.005763492981302, -7.867042585288362],
              [-35.005531504370836, -7.866964960153025],
              [-35.005347050381815, -7.866920350929659],
              [-35.004966103932311, -7.866881929417597],
              [-35.00465451970738, -7.866854548890107],
              [-35.004336664002722, -7.86680007857592],
              [-35.004017137736092, -7.866763112424811],
              [-35.003899561182365, -7.866760174609882],
              [-35.00378178296647, -7.866715246241895],
              [-35.003644451873136, -7.866617921830031],
              [-35.003341388282308, -7.866355172219708],
              [-35.002971657852292, -7.866094490118861],
              [-35.002867774090532, -7.866019751116064],
              [-35.002753446596017, -7.865962558386087],
              [-35.002626861979522, -7.86591067304173],
              [-35.002468736187872, -7.865867686377507],
              [-35.002275274547117, -7.865774128465194],
              [-35.002120431411875, -7.865683885522574],
              [-35.00203597459398, -7.865635298287326],
              [-35.002000638766468, -7.865584727071193],
              [-35.002005533306935, -7.865507719248526],
              [-35.001994703428153, -7.865444783613296],
              [-35.001929144624647, -7.86531212312695],
              [-35.001904151920641, -7.865223010322484],
              [-35.001868874902158, -7.86518468633248],
              [-35.001726364748876, -7.865104882224284],
              [-35.001476394984465, -7.864936358449758],
              [-35.001256203569866, -7.864757194607551],
              [-35.001021732139129, -7.8645273588816],
              [-35.000739383104566, -7.864192772244061],
              [-35.000603195877559, -7.863967716631524],
              [-35.000401157377866, -7.863548763696486],
              [-35.000328773852907, -7.863456377199172],
              [-35.000224849248156, -7.863372889213238],
              [-35.000010233195169, -7.863258434833345],
              [-34.999612576031318, -7.863027625623862],
              [-34.999331226355963, -7.862901241099739],
              [-34.999109525240627, -7.862772822676504],
              [-34.99901427352814, -7.862668297177234],
              [-34.998920634064419, -7.862534019902554],
              [-34.998767229026164, -7.862377281652933],
              [-34.998636407877179, -7.862173195249908],
              [-34.998498560058287, -7.86196739256054],
              [-34.998314899977949, -7.8617215658251],
              [-34.998230293604664, -7.861641484502109],
              [-34.998013606094162, -7.861460551828795],
              [-34.99786544887467, -7.86130028879602],
              [-34.997803568844844, -7.861202602685994],
              [-34.997662036817246, -7.860960074368853],
              [-34.997555764468011, -7.860752371292693],
              [-34.997447812965653, -7.860560423010349],
              [-34.997309631637442, -7.860284635124112],
              [-34.997095358386666, -7.859874485730116],
              [-34.997061343258451, -7.859732925601509],
              [-34.996929946727725, -7.859408114911623],
              [-34.996812938564602, -7.859156721159914],
              [-34.996629490044306, -7.858954633434451],
              [-34.996419756189759, -7.858759669135976],
              [-34.996295914164349, -7.858546799965368],
              [-34.996235103949708, -7.858305636741822],
              [-34.996183526684973, -7.858160660574389],
              [-34.996167842804965, -7.857816053312544],
              [-34.996149678181219, -7.857686665241853],
              [-34.996080521699284, -7.857534773938659],
              [-34.995816780335012, -7.8570531223929],
              [-34.995581710628237, -7.856696434638795],
              [-34.995259383994366, -7.856071588983296],
              [-34.995129693519701, -7.855822305585449],
              [-34.994951840453908, -7.855556322858098],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0403000',
        uf: 'PE',
        nome_proje: 'PA UMBURANA',
        municipio: 'CUSTODIA',
        area_hecta: '258.3414',
        capacidade: 15.0,
        num_famili: 14.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '17/02/2009',
        area_calc_: 256.2958,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.658214119182297, -8.128975094027183],
              [-37.656613653979164, -8.134838685695858],
              [-37.65839688340894, -8.135036653763269],
              [-37.659788247625386, -8.135181685571897],
              [-37.661133520710472, -8.135324119228088],
              [-37.665024029194292, -8.13575986952014],
              [-37.666963715065187, -8.135961751782808],
              [-37.66833752170939, -8.136122975293258],
              [-37.670147795317924, -8.136216714796245],
              [-37.669869137957193, -8.135846661487244],
              [-37.669769341239373, -8.135700016718053],
              [-37.669538456484617, -8.135208760803216],
              [-37.669465291075333, -8.134554431032234],
              [-37.669472694210825, -8.134400993227322],
              [-37.669422200785476, -8.134219241659807],
              [-37.669324411118637, -8.134042037627397],
              [-37.668992278953787, -8.133651914005897],
              [-37.66877144827864, -8.133139529937246],
              [-37.667721031297582, -8.129998662809887],
              [-37.667604530103112, -8.129992041206373],
              [-37.665934067858139, -8.129807960707431],
              [-37.662704878220154, -8.129462936044316],
              [-37.661948214852487, -8.129378425207783],
              [-37.660513526190094, -8.129222499778798],
              [-37.65911993262015, -8.129071497845551],
              [-37.658214119182297, -8.128975094027183],
            ],
          ],
          [
            [
              [-37.667998038957499, -8.130014491761795],
              [-37.668638225813304, -8.13195688433566],
              [-37.669025689898135, -8.133042244738567],
              [-37.669225804994745, -8.133507084397019],
              [-37.669549441113375, -8.133886780417919],
              [-37.669676098524484, -8.134116348269227],
              [-37.669747399493247, -8.134372954707944],
              [-37.669741288935128, -8.134574777559328],
              [-37.669804227153314, -8.13513828075097],
              [-37.670003033480853, -8.135560161089959],
              [-37.670226325216085, -8.135849820408234],
              [-37.670555614993908, -8.136359755769169],
              [-37.671516064298899, -8.13648986387952],
              [-37.671599238873029, -8.136503429327],
              [-37.678037939244753, -8.137514210275652],
              [-37.682507765337995, -8.138039222476698],
              [-37.686690712278946, -8.138489647940627],
              [-37.69044488582221, -8.138878692697739],
              [-37.69778459690459, -8.139757876938349],
              [-37.697822902479068, -8.138659608437729],
              [-37.697331980512558, -8.137463603564054],
              [-37.697182015755786, -8.136689745171054],
              [-37.697188295820382, -8.136571751108967],
              [-37.69731483295606, -8.136113484086202],
              [-37.697109204266042, -8.135686978696489],
              [-37.696935532952978, -8.134835272476895],
              [-37.694248333887991, -8.134535814574514],
              [-37.693011734315434, -8.134399807369926],
              [-37.68945716377484, -8.134009175441662],
              [-37.686341299222249, -8.133673829490037],
              [-37.683031888884777, -8.133305187279724],
              [-37.681078346682995, -8.133092214433916],
              [-37.677502330135468, -8.132362383476233],
              [-37.667998038957499, -8.130014491761795],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0404000',
        uf: 'PE',
        nome_proje: 'PA SAMAMBAIA',
        municipio: 'CUSTODIA',
        area_hecta: '737.6567',
        capacidade: 28.0,
        num_famili: 27.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '19/06/2008',
        area_calc_: 737.542,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.654640402728553, -8.306876335322633],
              [-37.654607759295388, -8.306997582218004],
              [-37.654468341808631, -8.307173630579408],
              [-37.652678143191523, -8.307387772576242],
              [-37.652113173454595, -8.30743641028686],
              [-37.651528806458764, -8.30806065886968],
              [-37.650533300391942, -8.309971506422428],
              [-37.649355956248911, -8.313651988346443],
              [-37.659372551062702, -8.31500407615394],
              [-37.66093561339175, -8.315194543300951],
              [-37.665571934003943, -8.315804616949674],
              [-37.672528504704594, -8.316785832362729],
              [-37.676030223445863, -8.317212132864217],
              [-37.676106642953258, -8.317220526099275],
              [-37.693848332428793, -8.319564135995996],
              [-37.698048330617617, -8.320129547472686],
              [-37.702246195374421, -8.320714352189285],
              [-37.706314676182473, -8.321388311215486],
              [-37.710664657331435, -8.32194666762982],
              [-37.715083989388269, -8.322404732337677],
              [-37.719883543456433, -8.322793799017388],
              [-37.721489017754841, -8.318866786830171],
              [-37.723026054034335, -8.315006101243601],
              [-37.723029407744988, -8.313548973606355],
              [-37.719262694690642, -8.313255438220619],
              [-37.715542781209855, -8.312980557684011],
              [-37.712733066092383, -8.312626386582643],
              [-37.709945604008389, -8.312299759865429],
              [-37.707198074688961, -8.312008060345828],
              [-37.704272173317499, -8.311707622706875],
              [-37.701448683928909, -8.311410120974111],
              [-37.698252225534866, -8.311168080419035],
              [-37.695130047947551, -8.310814665850172],
              [-37.690600055714043, -8.310352963162764],
              [-37.690499863050093, -8.310340425223725],
              [-37.686465452151296, -8.310008629453664],
              [-37.683262463776956, -8.309748906290615],
              [-37.680049043970115, -8.309488761643548],
              [-37.676891349554403, -8.309210057744572],
              [-37.673719231907569, -8.308903066979596],
              [-37.671439452672494, -8.308665859637058],
              [-37.670868479084447, -8.308608971410049],
              [-37.665352406521215, -8.308069542194263],
              [-37.66156902193417, -8.307684626582549],
              [-37.659253889130461, -8.307438732265334],
              [-37.654640402728553, -8.306876335322633],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0176000',
        uf: 'PE',
        nome_proje: 'PA ANGICO III',
        municipio: 'ITACURUBA',
        area_hecta: '1481.0000',
        capacidade: 79.0,
        num_famili: 34.0,
        fase: 3.0,
        data_de_cr: '03/08/2006',
        forma_obte: 'Dação',
        data_obten: '03/08/2006',
        area_calc_: 1482.4888,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.666546397149155, -8.637907956674335],
              [-38.666224107315351, -8.638725900749133],
              [-38.666509922642391, -8.639594574649324],
              [-38.664707956252123, -8.641301541064237],
              [-38.665236347163109, -8.642802141221129],
              [-38.66423238156662, -8.644418263969573],
              [-38.664140681054874, -8.64543404416786],
              [-38.66028740859781, -8.648257796201976],
              [-38.652847396395849, -8.65031169565105],
              [-38.649795032621775, -8.654522861414497],
              [-38.649366926087062, -8.656677200147355],
              [-38.648452477260314, -8.657314856494686],
              [-38.648176453057552, -8.659833127926492],
              [-38.646960266994647, -8.661103389880974],
              [-38.646559461873714, -8.662125299538561],
              [-38.643831917543018, -8.663406361406128],
              [-38.65216164812999, -8.679835817475855],
              [-38.652161714694223, -8.679835848471127],
              [-38.652167905405136, -8.679836546463038],
              [-38.652174085664385, -8.679837330819607],
              [-38.65218025425515, -8.679838201386398],
              [-38.652186409963015, -8.679839157992054],
              [-38.652192551575979, -8.679840200448222],
              [-38.652198677884904, -8.679841328549644],
              [-38.652204787683594, -8.679842542074244],
              [-38.652210879769171, -8.67984384078307],
              [-38.652216952942183, -8.679845224420477],
              [-38.652223006006942, -8.679846692713989],
              [-38.652229037771711, -8.679848245374561],
              [-38.652235047048933, -8.679849882096507],
              [-38.65224103265551, -8.679851602557573],
              [-38.652246993412966, -8.679853406419028],
              [-38.652252928147746, -8.679855293325748],
              [-38.652258835691377, -8.679857262906197],
              [-38.652264714880801, -8.679859314772635],
              [-38.652270564558485, -8.679861448521073],
              [-38.652276383572755, -8.679863663731389],
              [-38.65228217077793, -8.679865959967502],
              [-38.652287925034599, -8.679868336777266],
              [-38.652293645209902, -8.679870793692796],
              [-38.652299330177591, -8.679873330230301],
              [-38.652304978818407, -8.679875945890403],
              [-38.652310590020257, -8.679878640158156],
              [-38.652316162678346, -8.679881412503072],
              [-38.652321695695555, -8.679884262379323],
              [-38.652327187982543, -8.679887189225839],
              [-38.652332638457921, -8.679890192466367],
              [-38.652338046048627, -8.679893271509643],
              [-38.652343409689983, -8.67989642574944],
              [-38.652348728325983, -8.679899654564744],
              [-38.652354000909519, -8.67990295731985],
              [-38.65235922640246, -8.679906333364519],
              [-38.652364403776019, -8.679909782034075],
              [-38.652369532010852, -8.679913302649503],
              [-38.652374610097311, -8.679916894517696],
              [-38.652379637035608, -8.679920556931435],
              [-38.652384611836041, -8.679924289169682],
              [-38.652389533519113, -8.679928090497629],
              [-38.652394401115863, -8.67993196016689],
              [-38.652399213667941, -8.679935897415517],
              [-38.652403970227844, -8.679939901468439],
              [-38.652408669859092, -8.679943971537226],
              [-38.652413311636387, -8.679948106820605],
              [-38.652417894645865, -8.679952306504436],
              [-38.652422417985186, -8.679956569761798],
              [-38.652426880763798, -8.679960895753405],
              [-38.652431282103059, -8.679965283627514],
              [-38.652435621136419, -8.679969732520245],
              [-38.652439897009586, -8.679974241555666],
              [-38.652444108880722, -8.679978809846064],
              [-38.652448255920589, -8.679983436491979],
              [-38.652452337312695, -8.679988120582538],
              [-38.652456352253488, -8.679992861195496],
              [-38.652460299952473, -8.67999765739752],
              [-38.652464179632446, -8.680002508244371],
              [-38.652467990529566, -8.680007412780931],
              [-38.652471731893485, -8.680012370041622],
              [-38.652475402987648, -8.680017379050437],
              [-38.652479003089248, -8.680022438821197],
              [-38.652482531489483, -8.680027548357724],
              [-38.652485987493691, -8.680032706654018],
              [-38.652489370421421, -8.680037912694514],
              [-38.652492679606631, -8.680043165454244],
              [-38.652495914397811, -8.680048463899023],
              [-38.652499074158072, -8.680053806985683],
              [-38.652502158265328, -8.680059193662252],
              [-38.652505166112334, -8.680064622868217],
              [-38.652508097106931, -8.680070093534633],
              [-38.652510950672038, -8.680075604584403],
              [-38.652513726245829, -8.680081154932534],
              [-38.652516423281845, -8.680086743486255],
              [-38.652519041249093, -8.680092369145274],
              [-38.652521579632115, -8.680098030801975],
              [-38.652524037931151, -8.680103727341722],
              [-38.652526415662223, -8.68010945764294],
              [-38.652528712357181, -8.680115220577418],
              [-38.652530927563824, -8.68012101501057],
              [-38.652533060846025, -8.680126839801531],
              [-38.65253511178377, -8.680132693803522],
              [-38.652537079973271, -8.680138575864003],
              [-38.65253896502702, -8.680144484824876],
              [-38.652540766573864, -8.680150419522768],
              [-38.652542484259136, -8.680156378789302],
              [-38.652544117744618, -8.680162361451108],
              [-38.652545666708711, -8.680168366330353],
              [-38.652547130846457, -8.680174392244773],
              [-38.652548509869582, -8.680180438007964],
              [-38.652549803506574, -8.680186502429665],
              [-38.652551011502723, -8.680192584315831],
              [-38.652552133620219, -8.680198682469069],
              [-38.652553169638111, -8.680204795688772],
              [-38.65255411935243, -8.680210922771334],
              [-38.652554982576163, -8.680217062510433],
              [-38.652555759139403, -8.680223213697293],
              [-38.652556448889207, -8.680229375120849],
              [-38.652557051689797, -8.680235545567967],
              [-38.652557567423095, -8.680241723823094],
              [-38.652558187088232, -8.680250358785132],
              [-38.652558727411304, -8.680258999022211],
              [-38.652559188347155, -8.680267643812586],
              [-38.652559569857274, -8.680276292434003],
              [-38.652559871909787, -8.680284944163953],
              [-38.652560094479433, -8.680293598279626],
              [-38.652560237547647, -8.680302254058031],
              [-38.652560301102426, -8.68031091077607],
              [-38.652560285138506, -8.680319567710514],
              [-38.652560189657187, -8.680328224138163],
              [-38.652560014666442, -8.680336879335812],
              [-38.652559760180885, -8.680345532580429],
              [-38.652559426221778, -8.680354183149044],
              [-38.652559012817008, -8.680362830319012],
              [-38.652558520001115, -8.680371473367881],
              [-38.652557947815239, -8.68038011157361],
              [-38.652557296307222, -8.680388744214572],
              [-38.652556565531441, -8.680397370569519],
              [-38.652555755548974, -8.680405989917816],
              [-38.652554866427472, -8.680414601539391],
              [-38.652553898241209, -8.680423204714769],
              [-38.652552851071057, -8.680431798725222],
              [-38.6525517250045, -8.680440382852831],
              [-38.652550520135613, -8.680448956380415],
              [-38.652549236565044, -8.680457518591718],
              [-38.652547874400028, -8.680466068771443],
              [-38.652546433754338, -8.680474606205284],
              [-38.652544914748347, -8.680483130180029],
              [-38.652543317508936, -8.680491639983499],
              [-38.652541642169531, -8.680500134904809],
              [-38.65253988887013, -8.680508614234268],
              [-38.652538057757155, -8.680517077263458],
              [-38.652536148983607, -8.680525523285398],
              [-38.652534162708925, -8.680533951594448],
              [-38.652532099099041, -8.680542361486498],
              [-38.652529958326383, -8.680550752258959],
              [-38.652527740569752, -8.680559123210847],
              [-38.652525446014444, -8.68056747364284],
              [-38.652523074852141, -8.680575802857334],
              [-38.652520627280929, -8.680584110158422],
              [-38.652518103505287, -8.680592394852145],
              [-38.652515503736048, -8.680600656246376],
              [-38.652512828190396, -8.680608893650886],
              [-38.652510077091854, -8.680617106377547],
              [-38.652507250670247, -8.680625293740247],
              [-38.652504349161696, -8.680633455054958],
              [-38.652501372808615, -8.680641589639899],
              [-38.652498321859625, -8.680649696815436],
              [-38.652495196569618, -8.68065777590434],
              [-38.652491997199682, -8.680665826231595],
              [-38.652488724017104, -8.680673847124716],
              [-38.652485377295321, -8.680681837913538],
              [-38.652481957313931, -8.680689797930562],
              [-38.652478464358644, -8.68069772651077],
              [-38.652474898721252, -8.680705622991749],
              [-38.652471260699663, -8.680713486713829],
              [-38.652467550597763, -8.680721317020055],
              [-38.652463768725546, -8.680729113256266],
              [-38.652459915398914, -8.680736874771128],
              [-38.652455990939799, -8.680744600916242],
              [-38.65245199567606, -8.680752291046112],
              [-38.652447929941466, -8.680759944518316],
              [-38.65244379407568, -8.68076756069345],
              [-38.652439588424215, -8.680775138935214],
              [-38.652435313338415, -8.680782678610532],
              [-38.652430969175427, -8.680790179089524],
              [-38.652426556298188, -8.680797639745533],
              [-38.652422075075336, -8.680805059955308],
              [-38.652417525881269, -8.680812439098956],
              [-38.652412909096014, -8.680819776559977],
              [-38.652408225105269, -8.680827071725359],
              [-38.65240347430035, -8.6808343239857],
              [-38.652398657078137, -8.680841532735078],
              [-38.652393773841084, -8.68084869737126],
              [-38.652388824997161, -8.680855817295729],
              [-38.652383810959762, -8.680862891913625],
              [-38.652378732147831, -8.680869920633953],
              [-38.652373588985597, -8.680876902869446],
              [-38.652368381902768, -8.680883838036838],
              [-38.652363111334367, -8.680890725556772],
              [-38.652357777720674, -8.680897564853787],
              [-38.652352381507306, -8.680904355356528],
              [-38.65234692314506, -8.680911096497697],
              [-38.652341403089942, -8.680917787714149],
              [-38.652335821803092, -8.680924428446808],
              [-38.65233017975082, -8.680931018140955],
              [-38.652324477404449, -8.680937556246064],
              [-38.652318715240384, -8.680944042215868],
              [-38.652312893739982, -8.680950475508549],
              [-38.652307013389617, -8.680956855586663],
              [-38.652301074680544, -8.680963181917182],
              [-38.652295078108892, -8.680969453971574],
              [-38.652289024175616, -8.68097567122587],
              [-38.6522829133865, -8.680981833160635],
              [-38.652276746252042, -8.680987939261117],
              [-38.652270523287449, -8.680993989017205],
              [-38.652264245012624, -8.680999981923454],
              [-38.652257911952077, -8.681005917479199],
              [-38.652251524635069, -8.681011795187976],
              [-38.652236525371165, -8.681025400090183],
              [-38.652221454793512, -8.681038926735877],
              [-38.652206313314466, -8.681052374754927],
              [-38.652191101348322, -8.681065743779397],
              [-38.652175819311303, -8.681079033443433],
              [-38.652160467621535, -8.681092243383478],
              [-38.652145046699069, -8.681105373238054],
              [-38.652129556965832, -8.681118422647907],
              [-38.652113998845664, -8.681131391255949],
              [-38.65209837276425, -8.68114427870738],
              [-38.652082679149132, -8.681157084649536],
              [-38.652066918429718, -8.681169808732079],
              [-38.652051091037237, -8.681182450606785],
              [-38.652035197404764, -8.68119500992781],
              [-38.652019237967174, -8.681207486351454],
              [-38.652003213161116, -8.681219879536387],
              [-38.65198712342508, -8.681232189143483],
              [-38.65197096919929, -8.681244414835936],
              [-38.651954750925746, -8.681256556279207],
              [-38.65193846904824, -8.681268613141121],
              [-38.651922124012209, -8.681280585091773],
              [-38.651905716264928, -8.681292471803561],
              [-38.651889246255323, -8.681304272951278],
              [-38.65187271443402, -8.681315988211987],
              [-38.651856121253388, -8.681327617265161],
              [-38.651839467167413, -8.681339159792598],
              [-38.651822752631794, -8.681350615478491],
              [-38.651805978103873, -8.681361984009358],
              [-38.65178914404261, -8.68137326507418],
              [-38.651772250908628, -8.681384458364249],
              [-38.65175529916413, -8.681395563573275],
              [-38.65173828927297, -8.681406580397478],
              [-38.651721221700548, -8.681417508535363],
              [-38.651704096913861, -8.681428347687911],
              [-38.651686915381489, -8.681439097558565],
              [-38.651669677573523, -8.6814497578532],
              [-38.65165238396164, -8.681460328280066],
              [-38.651635035019012, -8.681470808549985],
              [-38.651617631220319, -8.681481198376227],
              [-38.651600173041793, -8.681491497474441],
              [-38.651582660961076, -8.681501705562869],
              [-38.651565095457372, -8.681511822362195],
              [-38.651547477011285, -8.68152184759559],
              [-38.651529806104854, -8.681531780988735],
              [-38.65151208322164, -8.681541622269867],
              [-38.651494308846551, -8.681551371169675],
              [-38.651476483465913, -8.681561027421461],
              [-38.65145860756747, -8.681570590760931],
              [-38.651440681640331, -8.681580060926498],
              [-38.651422706174998, -8.681589437658985],
              [-38.651404681663294, -8.681598720701832],
              [-38.651386608598386, -8.681607909801061],
              [-38.651368487474826, -8.681617004705261],
              [-38.651350318788403, -8.681626005165514],
              [-38.651332103036268, -8.681634910935582],
              [-38.651313840716846, -8.681643721771811],
              [-38.651295532329783, -8.681652437433087],
              [-38.65127717837607, -8.681661057680957],
              [-38.651258779357875, -8.681669582279518],
              [-38.651240335778667, -8.68167801099556],
              [-38.651221848143074, -8.681686343598443],
              [-38.651203316956938, -8.681694579860167],
              [-38.65118474272731, -8.681702719555394],
              [-38.65116612596244, -8.681710762461382],
              [-38.65114746717169, -8.681718708358074],
              [-38.651128766865611, -8.68172655702805],
              [-38.651110025555887, -8.681734308256567],
              [-38.651091243755289, -8.681741961831516],
              [-38.651072421977744, -8.681749517543469],
              [-38.651053560738241, -8.68175697518574],
              [-38.651034660552874, -8.68176433455422],
              [-38.651015721938769, -8.681771595447549],
              [-38.650996745414119, -8.6817787576671],
              [-38.650977731498187, -8.681785821016868],
              [-38.650958680711199, -8.681792785303605],
              [-38.650939593574428, -8.681799650336716],
              [-38.650920470610131, -8.681806415928412],
              [-38.650901312341574, -8.681813081893544],
              [-38.65088211929293, -8.681819648049704],
              [-38.650862891989384, -8.681826114217259],
              [-38.650843630957013, -8.681832480219249],
              [-38.650824336722835, -8.681838745881528],
              [-38.650805009814796, -8.681844911032652],
              [-38.650785650761705, -8.681850975503911],
              [-38.650766260093263, -8.681856939129368],
              [-38.650746838340048, -8.681862801745867],
              [-38.650727386033466, -8.68186856319296],
              [-38.650707903705751, -8.681874223313049],
              [-38.650688391890007, -8.681879781951229],
              [-38.650668851120109, -8.681885238955415],
              [-38.65064928193074, -8.681890594176293],
              [-38.650629684857314, -8.681895847467345],
              [-38.650610060436087, -8.681900998684837],
              [-38.650590409203993, -8.681906047687777],
              [-38.65057073169875, -8.68191099433805],
              [-38.650551028458764, -8.681915838500286],
              [-38.650531300023133, -8.68192058004197],
              [-38.650511546931696, -8.68192521883334],
              [-38.650491769725015, -8.68192975474742],
              [-38.650480020754259, -8.681932436307385],
              [-38.650468280212209, -8.681935154173662],
              [-38.650456548212169, -8.681937908320025],
              [-38.650444824867378, -8.681940698719909],
              [-38.650433110290955, -8.681943525346375],
              [-38.650421404595981, -8.681946388172113],
              [-38.650409707895435, -8.681949287169536],
              [-38.650398020302184, -8.681952222310631],
              [-38.650386341929035, -8.681955193567108],
              [-38.650374672888688, -8.681958200910243],
              [-38.650363013293791, -8.681961244311049],
              [-38.650351363256846, -8.681964323740143],
              [-38.650339722890308, -8.681967439167789],
              [-38.650328092306488, -8.681970590563958],
              [-38.650316471617685, -8.681973777898202],
              [-38.650304860936011, -8.681977001139778],
              [-38.650293260373545, -8.681980260257557],
              [-38.650281670042247, -8.681983555220082],
              [-38.650270090053944, -8.681986885995579],
              [-38.650258520520438, -8.681990252551897],
              [-38.650246961553364, -8.681993654856528],
              [-38.650235413264298, -8.681997092876644],
              [-38.650223875764681, -8.682000566579065],
              [-38.650212349165855, -8.682004075930266],
              [-38.650200833579106, -8.682007620896389],
              [-38.650189329115527, -8.682011201443183],
              [-38.650177835886147, -8.682014817536114],
              [-38.650166354001939, -8.682018469140315],
              [-38.650154883573684, -8.682022156220501],
              [-38.650143424712077, -8.68202587874109],
              [-38.650131977527728, -8.682029636666153],
              [-38.65012054213112, -8.682033429959469],
              [-38.650109118632592, -8.682037258584385],
              [-38.650097707142415, -8.682041122503929],
              [-38.650086307770728, -8.682045021680871],
              [-38.650074920627524, -8.682048956077532],
              [-38.650063545822718, -8.682052925655945],
              [-38.650052183466094, -8.682056930377824],
              [-38.650040833667319, -8.682060970204498],
              [-38.650029496535907, -8.682065045096987],
              [-38.650018172181305, -8.682069155015943],
              [-38.650006860712786, -8.682073299921759],
              [-38.649995562239525, -8.682077479774362],
              [-38.649984276870562, -8.682081694533444],
              [-38.649973004714809, -8.68208594415829],
              [-38.649961745881079, -8.682090228607938],
              [-38.649950500478013, -8.682094547841023],
              [-38.649939268614162, -8.682098901815875],
              [-38.649928050397904, -8.682103290490447],
              [-38.649916845937518, -8.682107713822361],
              [-38.649905655341122, -8.682112171768981],
              [-38.64989447871676, -8.682116664287236],
              [-38.649883316172279, -8.682121191333813],
              [-38.649872167815403, -8.682125752864975],
              [-38.649861033753737, -8.682130348836715],
              [-38.64984991409473, -8.682134979204667],
              [-38.649838808945709, -8.68213964392417],
              [-38.649827718413853, -8.68214434295021],
              [-38.649816642606183, -8.682149076237401],
              [-38.649805581629614, -8.68215384374006],
              [-38.649794535590885, -8.682158645412205],
              [-38.64978350459662, -8.68216348120745],
              [-38.649772488753257, -8.682168351079181],
              [-38.64976148816713, -8.68217325498035],
              [-38.649750502944414, -8.682178192863653],
              [-38.649739533191109, -8.682183164681437],
              [-38.649728579013079, -8.682188170385697],
              [-38.649717640516094, -8.68219320992813],
              [-38.649706717805678, -8.682198283260117],
              [-38.649695810987261, -8.682203390332655],
              [-38.649684920166123, -8.682208531096512],
              [-38.649674045447355, -8.682213705502038],
              [-38.649663186935918, -8.682218913499257],
              [-38.649652344736602, -8.682224155037956],
              [-38.649641518954063, -8.682229430067524],
              [-38.649630709692786, -8.682234738537078],
              [-38.649619917057059, -8.682240080395378],
              [-38.649609141151075, -8.682245455590847],
              [-38.649598382078842, -8.682250864071646],
              [-38.64958763994418, -8.682256305785518],
              [-38.649576914850762, -8.682261780679998],
              [-38.649566206902101, -8.682267288702233],
              [-38.649555516201552, -8.682272829799045],
              [-38.649544842852293, -8.68227840391696],
              [-38.649534186957311, -8.682284011002197],
              [-38.649523548619477, -8.682289651000643],
              [-38.64951292794148, -8.682295323857856],
              [-38.649502325025779, -8.682301029519058],
              [-38.649491739974728, -8.682306767929207],
              [-38.649481172890468, -8.68231253903293],
              [-38.649470623875025, -8.682318342774526],
              [-38.649460093030143, -8.682324179097984],
              [-38.649449580457528, -8.682330047946996],
              [-38.64943908625861, -8.682335949264875],
              [-38.649428610534677, -8.682341882994672],
              [-38.649418153386812, -8.682347849079164],
              [-38.649407714915959, -8.682353847460694],
              [-38.649397295222869, -8.682359878081446],
              [-38.649386894408138, -8.682365940882299],
              [-38.649362074660601, -8.682380473091728],
              [-38.649337266172594, -8.68239502432618],
              [-38.64931246895879, -8.682409594577079],
              [-38.649287683033968, -8.682424183835728],
              [-38.649262908412801, -8.682438792093501],
              [-38.649238145110012, -8.68245341934173],
              [-38.649213393140307, -8.682468065571701],
              [-38.64918865251839, -8.682482730774741],
              [-38.649163923258925, -8.682497414942125],
              [-38.649139205376606, -8.682512118065155],
              [-38.649114498886135, -8.682526840135083],
              [-38.64908980380212, -8.682541581143196],
              [-38.649065120139269, -8.682556341080705],
              [-38.649040447912235, -8.682571119938883],
              [-38.649015787135632, -8.682585917708934],
              [-38.648991137824133, -8.682600734382097],
              [-38.648966499992355, -8.682615569949558],
              [-38.648941873654948, -8.6826304244025],
              [-38.648917258826536, -8.682645297732138],
              [-38.648892655521685, -8.682660189929596],
              [-38.648868063755039, -8.682675100986089],
              [-38.648843483541206, -8.682690030892708],
              [-38.648818914894747, -8.682704979640619],
              [-38.648794357830276, -8.682719947220939],
              [-38.648769812362367, -8.682734933624797],
              [-38.648745278505572, -8.682749938843285],
              [-38.648720756274479, -8.682764962867475],
              [-38.648696245683645, -8.682780005688516],
              [-38.648671746747617, -8.682795067297379],
              [-38.648647259480938, -8.682810147685199],
              [-38.648622783898148, -8.682825246843027],
              [-38.648598320013789, -8.682840364761837],
              [-38.648573867842366, -8.682855501432707],
              [-38.648549427398414, -8.682870656846612],
              [-38.648524998696431, -8.682885830994586],
              [-38.64850058175093, -8.682901023867613],
              [-38.648476176576388, -8.682916235456661],
              [-38.648451783187312, -8.682931465752699],
              [-38.648427401598191, -8.682946714746711],
              [-38.648403031823484, -8.682961982429653],
              [-38.648378673877673, -8.682977268792399],
              [-38.648354327775202, -8.682992573825945],
              [-38.648329993530545, -8.683007897521135],
              [-38.648305671158134, -8.683023239868938],
              [-38.648281360672421, -8.683038600860204],
              [-38.648257062087822, -8.683053980485823],
              [-38.648232775418784, -8.683069378736668],
              [-38.648208500679722, -8.683084795603598],
              [-38.648184237885033, -8.683100231077479],
              [-38.648159987049148, -8.683115685149108],
              [-38.648135748186434, -8.683131157809317],
              [-38.648111521311307, -8.683146649048959],
              [-38.648087306438136, -8.683162158858821],
              [-38.648063103581293, -8.683177687229673],
              [-38.648038912755169, -8.683193234152299],
              [-38.648014733974108, -8.6832087996175],
              [-38.647990567252464, -8.683224383616013],
              [-38.647966412604603, -8.683239986138576],
              [-38.647942270044851, -8.683255607175962],
              [-38.647918139587553, -8.683271246718858],
              [-38.647894021247019, -8.683286904758015],
              [-38.647869915037582, -8.683302581284099],
              [-38.647845820973558, -8.683318276287833],
              [-38.647821739069236, -8.683333989759893],
              [-38.647797669338914, -8.683349721690934],
              [-38.6477736117969, -8.683365472071632],
              [-38.647749566457449, -8.683381240892659],
              [-38.647725533334871, -8.683397028144581],
              [-38.647701512443405, -8.683412833818098],
              [-38.647677503797347, -8.683428657903809],
              [-38.647653507410908, -8.683444500392309],
              [-38.647629523298377, -8.683460361274213],
              [-38.647605551473958, -8.683476240540051],
              [-38.647581591951905, -8.683492138180489],
              [-38.647557644746435, -8.683508054185987],
              [-38.647533709871766, -8.683523988547178],
              [-38.64750978734213, -8.683539941254539],
              [-38.647485877171675, -8.683555912298637],
              [-38.647461979374654, -8.683571901669985],
              [-38.647438093965235, -8.683587909359094],
              [-38.647414220957586, -8.683603935356428],
              [-38.647390360365911, -8.683619979652537],
              [-38.647366512204343, -8.683636042237826],
              [-38.647342676487057, -8.68365212310281],
              [-38.647318853228221, -8.683668222237923],
              [-38.647295042441939, -8.683684339633604],
              [-38.647271244142388, -8.683700475280306],
              [-38.647247458343656, -8.683716629168384],
              [-38.647223685059913, -8.68373280128834],
              [-38.647199924305248, -8.68374899163053],
              [-38.647176176093758, -8.683765200185302],
              [-38.647152440439569, -8.683781426943121],
              [-38.647128717356743, -8.683797671894272],
              [-38.647105006859398, -8.683813935029177],
              [-38.647081308961589, -8.68383021633816],
              [-38.647057623677384, -8.683846515811508],
              [-38.647033951020852, -8.683862833439596],
              [-38.647010291006055, -8.683879169212744],
              [-38.646986643647161, -8.683895523119849],
              [-38.646979867522489, -8.683900226689344],
              [-38.646973104188469, -8.683904948451097],
              [-38.646966353694452, -8.683909688370736],
              [-38.646959616089681, -8.683914446413654],
              [-38.646952891423275, -8.683919222545166],
              [-38.646946179744297, -8.683924016730417],
              [-38.646939481101711, -8.683928828934455],
              [-38.646932795544345, -8.68393365912217],
              [-38.646926123120991, -8.683938507258356],
              [-38.646919463880295, -8.683943373307613],
              [-38.646912817870842, -8.683948257234492],
              [-38.646906185141077, -8.683953159003332],
              [-38.646899565739425, -8.683958078578451],
              [-38.64689295971413, -8.683963015923885],
              [-38.646886367113382, -8.68396797100368],
              [-38.646879787985263, -8.683972943781638],
              [-38.646873222377764, -8.68397793422155],
              [-38.646866670338781, -8.683982942286969],
              [-38.646860131916085, -8.683987967941428],
              [-38.646853607157375, -8.683993011148196],
              [-38.646847096110228, -8.683998071870541],
              [-38.646840598822159, -8.684003150071552],
              [-38.646834115340539, -8.684008245714185],
              [-38.646827645712662, -8.684013358761243],
              [-38.646821189985708, -8.684018489175457],
              [-38.646814748206765, -8.684023636919418],
              [-38.646808320422821, -8.684028801955581],
              [-38.646801906680764, -8.684033984246254],
              [-38.646795507027363, -8.684039183753658],
              [-38.646789121509308, -8.68404440043984],
              [-38.646782750173166, -8.684049634266774],
              [-38.646776393065394, -8.684054885196305],
              [-38.646770050232384, -8.684060153190101],
              [-38.64676372172039, -8.684065438209789],
              [-38.646757407575549, -8.68407074021675],
              [-38.64675110784394, -8.684076059172348],
              [-38.646744822571513, -8.684081395037815],
              [-38.646738551804091, -8.684086747774183],
              [-38.646732295587427, -8.684092117342461],
              [-38.646726053967136, -8.684097503703429],
              [-38.646719826988765, -8.684102906817863],
              [-38.646713614697703, -8.684108326646296],
              [-38.646707417139297, -8.684113763149263],
              [-38.646701234358723, -8.684119216287046],
              [-38.64669506640108, -8.68412468601989],
              [-38.646688913311358, -8.684130172307915],
              [-38.646682775134458, -8.684135675111129],
              [-38.646676651915108, -8.684141194389339],
              [-38.646670543697986, -8.68414673010234],
              [-38.646664450527666, -8.684152282209727],
              [-38.646658372448549, -8.684157850671033],
              [-38.646652309505008, -8.684163435445649],
              [-38.646646261741246, -8.684169036492793],
              [-38.646640229201381, -8.684174653771636],
              [-38.646634211929381, -8.684180287241194],
              [-38.646628209969172, -8.68418593686042],
              [-38.64662222336451, -8.684191602588076],
              [-38.646616252159085, -8.684197284382854],
              [-38.646610296396425, -8.684202982203287],
              [-38.64660435611998, -8.684208696007854],
              [-38.646598431373057, -8.68421442575486],
              [-38.646592522198901, -8.684220171402533],
              [-38.646586628640584, -8.684225932908907],
              [-38.646580750741109, -8.684231710232023],
              [-38.646574888543341, -8.684237503329726],
              [-38.646569042090057, -8.684243312159776],
              [-38.646563211423867, -8.684249136679757],
              [-38.646557396587312, -8.684254976847241],
              [-38.64655159762281, -8.684260832619607],
              [-38.646545814572647, -8.684266703954131],
              [-38.646540047479029, -8.684272590808018],
              [-38.64653429638399, -8.684278493138336],
              [-38.646528561329475, -8.684284410902004],
              [-38.646522842357335, -8.684290344055892],
              [-38.646517139509264, -8.68429629255669],
              [-38.646511452826886, -8.684302256361036],
              [-38.646505782351632, -8.684308235425419],
              [-38.646500128124906, -8.684314229706265],
              [-38.646494490187933, -8.684320239159815],
              [-38.646488868581827, -8.684326263742244],
              [-38.646483263347584, -8.684332303409633],
              [-38.646477674526103, -8.684338358117914],
              [-38.646472102158164, -8.684344427822916],
              [-38.646466546284373, -8.684350512480382],
              [-38.646461006945273, -8.684356612045946],
              [-38.646455484181267, -8.684362726475092],
              [-38.646449978032621, -8.684368855723253],
              [-38.64644448853953, -8.684374999745678],
              [-38.646439015742011, -8.684381158497619],
              [-38.646433559679963, -8.684387331934134],
              [-38.64642812039321, -8.684393520010145],
              [-38.646422697921416, -8.684399722680558],
              [-38.646417292304122, -8.684405939900158],
              [-38.646411903580763, -8.684412171623574],
              [-38.646406531790667, -8.684418417805341],
              [-38.646401176972972, -8.684424678399935],
              [-38.64639583916675, -8.684430953361653],
              [-38.646390518410946, -8.684437242644778],
              [-38.646385214744562, -8.684443546202722],
              [-38.646373448700075, -8.684457652025227],
              [-38.646361765242339, -8.684471825652581],
              [-38.646350164765991, -8.684486066606043],
              [-38.64633864766288, -8.684500374404603],
              [-38.646327214322035, -8.68451474856499],
              [-38.646315865129644, -8.684529188601651],
              [-38.646304600469044, -8.684543694026868],
              [-38.646293420720767, -8.684558264350651],
              [-38.646282326262423, -8.684572899080919],
              [-38.646271317468781, -8.684587597723326],
              [-38.646260394711646, -8.684602359781367],
              [-38.646249558360026, -8.684617184756444],
              [-38.64623880877992, -8.684632072147823],
              [-38.64622814633443, -8.684647021452577],
              [-38.646217571383723, -8.684662032165859],
              [-38.646207084284988, -8.684677103780585],
              [-38.646196685392489, -8.684692235787715],
              [-38.646186375057461, -8.68470742767612],
              [-38.646176153628161, -8.684722678932642],
              [-38.646166021449858, -8.684737989042153],
              [-38.646155978864819, -8.684753357487509],
              [-38.646146026212229, -8.684768783749639],
              [-38.646136163828288, -8.684784267307441],
              [-38.646126392046142, -8.684799807637949],
              [-38.646116711195859, -8.684815404216238],
              [-38.64610712160443, -8.68483105651552],
              [-38.646097623595807, -8.684846764007069],
              [-38.646088217490778, -8.684862526160384],
              [-38.646078903607084, -8.684878342443035],
              [-38.646069682259352, -8.684894212320755],
              [-38.646060553759035, -8.684910135257546],
              [-38.646051518414495, -8.684926110715546],
              [-38.646042576530931, -8.684942138155174],
              [-38.646033728410394, -8.684958217035069],
              [-38.646024974351747, -8.684974346812096],
              [-38.646016314650701, -8.684990526941469],
              [-38.646007749599775, -8.685006756876641],
              [-38.645999279488258, -8.685023036069429],
              [-38.645990904602286, -8.685039363969954],
              [-38.645982625224747, -8.685055740026732],
              [-38.645974441635296, -8.685072163686577],
              [-38.645966354110378, -8.685088634394763],
              [-38.645958362923182, -8.685105151594989],
              [-38.645950468343628, -8.685121714729284],
              [-38.645942670638384, -8.685138323238217],
              [-38.645934970070861, -8.685154976560833],
              [-38.645927366901184, -8.685171674134562],
              [-38.645919861386162, -8.685188415395444],
              [-38.645912453779317, -8.68520519977799],
              [-38.645905144330904, -8.685222026715287],
              [-38.645897933287806, -8.685238895638928],
              [-38.645890820893605, -8.685255805979187],
              [-38.64588380738855, -8.685272757164833],
              [-38.645876893009572, -8.685289748623301],
              [-38.64587007799021, -8.685306779780667],
              [-38.645863362560696, -8.685323850061659],
              [-38.645856746947842, -8.685340958889704],
              [-38.645850231375142, -8.685358105686904],
              [-38.645843816062673, -8.685375289874072],
              [-38.64583750122717, -8.685392510870793],
              [-38.645831287081911, -8.685409768095374],
              [-38.645825173836826, -8.685427060964891],
              [-38.645819161698398, -8.685444388895274],
              [-38.645813250869757, -8.685461751301236],
              [-38.645807441550517, -8.685479147596283],
              [-38.645801733936956, -8.685496577192813],
              [-38.64579612822186, -8.685514039502133],
              [-38.645790624594582, -8.68553153393438],
              [-38.645785223241042, -8.685549059898644],
              [-38.645779924343699, -8.685566616802952],
              [-38.645774728081562, -8.685584204054269],
              [-38.645769634630156, -8.685601821058551],
              [-38.645764644161517, -8.685619467220734],
              [-38.645759756844235, -8.685637141944788],
              [-38.645754972843427, -8.685654844633687],
              [-38.645750292320685, -8.685672574689498],
              [-38.64574571543411, -8.68569033151331],
              [-38.645741242338318, -8.685708114505388],
              [-38.645736873184418, -8.685725923065059],
              [-38.645732608119985, -8.685743756590757],
              [-38.645728447289137, -8.685761614480137],
              [-38.645724390832399, -8.685779496129998],
              [-38.645720438886791, -8.685797400936346],
              [-38.645716591585852, -8.68581532829441],
              [-38.645712849059535, -8.685833277598665],
              [-38.645709211434252, -8.685851248242749],
              [-38.64570567883289, -8.685869239619754],
              [-38.645702251374814, -8.685887251121926],
              [-38.645698929175779, -8.685905282140872],
              [-38.645695712348044, -8.685923332067599],
              [-38.645692601000249, -8.685941400292387],
              [-38.645689595237528, -8.685959486204919],
              [-38.645686695161402, -8.685977589194325],
              [-38.645683900869869, -8.685995708649122],
              [-38.645681212457312, -8.6860138439573],
              [-38.645678630014544, -8.686031994506282],
              [-38.645676153628834, -8.686050159682969],
              [-38.64567378338382, -8.686068338873797],
              [-38.645671519359595, -8.686086531464726],
              [-38.645669999392524, -8.68609884004829],
              [-38.645668430802573, -8.686111142590283],
              [-38.645666813613971, -8.686123438900603],
              [-38.645665147851737, -8.686135728789131],
              [-38.645663433541593, -8.686148012065905],
              [-38.645661670710048, -8.68616028854102],
              [-38.645659859384352, -8.686172558024742],
              [-38.64565799959248, -8.686184820327385],
              [-38.645656091363193, -8.686197075259408],
              [-38.645654134725973, -8.686209322631338],
              [-38.645652129711067, -8.686221562253918],
              [-38.645650076349476, -8.686233793937893],
              [-38.645647974672933, -8.686246017494174],
              [-38.645645824713881, -8.686258232733859],
              [-38.64564362650561, -8.686270439468064],
              [-38.645641380082054, -8.686282637508139],
              [-38.645639085477967, -8.686294826665476],
              [-38.645636742728769, -8.686307006751727],
              [-38.645634351870726, -8.686319177578552],
              [-38.645631912940743, -8.686331338957832],
              [-38.645629425976551, -8.686343490701557],
              [-38.645626891016562, -8.686355632621929],
              [-38.645624308099976, -8.686367764531198],
              [-38.645621677266703, -8.686379886241864],
              [-38.645618998557417, -8.686391997566522],
              [-38.645616272013527, -8.686404098317974],
              [-38.645613497677168, -8.686416188309133],
              [-38.645610675591215, -8.686428267353163],
              [-38.645607805799301, -8.686440335263311],
              [-38.645604888345765, -8.686452391853013],
              [-38.645601923275706, -8.686464436935914],
              [-38.64559891063498, -8.686476470325813],
              [-38.645595850470109, -8.686488491836704],
              [-38.645592742828441, -8.686500501282756],
              [-38.645589587757975, -8.68651249847829],
              [-38.645586385307496, -8.6865244832379],
              [-38.6455831355265, -8.686536455376306],
              [-38.645579838465203, -8.686548414708458],
              [-38.645576494174591, -8.686560361049422],
              [-38.645573102706336, -8.686572294214603],
              [-38.645569664112884, -8.686584214019472],
              [-38.645566178447353, -8.686596120279836],
              [-38.645562645763647, -8.686608012811583],
              [-38.645559066116355, -8.686619891430917],
              [-38.645555439560823, -8.686631755954188],
              [-38.645551766153098, -8.686643606198029],
              [-38.645548045949958, -8.686655441979214],
              [-38.645544279008909, -8.686667263114789],
              [-38.64554046538818, -8.686679069422034],
              [-38.645536605146702, -8.686690860718459],
              [-38.645532698344176, -8.686702636821776],
              [-38.645528745040963, -8.686714397549947],
              [-38.645524745298175, -8.686726142721188],
              [-38.645520699177645, -8.686737872153891],
              [-38.6455166067419, -8.686749585666773],
              [-38.645512468054207, -8.68676128307877],
              [-38.645508283178557, -8.686772964209052],
              [-38.645504052179596, -8.686784628877039],
              [-38.645499775122758, -8.686796276902442],
              [-38.645495452074137, -8.686807908105175],
              [-38.645491083100559, -8.68681952230545],
              [-38.645486668269569, -8.686831119323736],
              [-38.64548220764938, -8.686842698980747],
              [-38.64547770130897, -8.686854261097505],
              [-38.645473149317979, -8.686865805495266],
              [-38.645468551746802, -8.686877331995561],
              [-38.645463908666457, -8.686888840420256],
              [-38.645459220148723, -8.686900330591397],
              [-38.64545448626609, -8.686911802331394],
              [-38.645449707091736, -8.686923255462906],
              [-38.645444882699515, -8.686934689808897],
              [-38.645440013163999, -8.686946105192598],
              [-38.64543509856049, -8.68695750143756],
              [-38.645430138964926, -8.686968878367599],
              [-38.645425134453973, -8.686980235806875],
              [-38.645420085105002, -8.686991573579778],
              [-38.645414990996038, -8.687002891511122],
              [-38.645409852205852, -8.687014189425875],
              [-38.64540466881386, -8.687025467149434],
              [-38.645399440900192, -8.68703672450744],
              [-38.645394168545657, -8.687047961325868],
              [-38.645388851831747, -8.687059177431037],
              [-38.645383490840658, -8.68707037264956],
              [-38.645378085655231, -8.687081546808381],
              [-38.645372636359049, -8.687092699734769],
              [-38.645367143036324, -8.687103831256284],
              [-38.645361605771996, -8.687114941200896],
              [-38.645356024651626, -8.687126029396826],
              [-38.645350399761483, -8.687137095672703],
              [-38.645344731188558, -8.687148139857486],
              [-38.645339019020419, -8.687159161780404],
              [-38.645333263345421, -8.687170161271062],
              [-38.645327464252475, -8.687181138159454],
              [-38.645321621831258, -8.687192092275925],
              [-38.645315736172059, -8.687203023451108],
              [-38.645309807365884, -8.68721393151602],
              [-38.645303835504343, -8.687224816302034],
              [-38.645297820679779, -8.687235677640908],
              [-38.645291762985096, -8.687246515364992],
              [-38.645285624457998, -8.687257399607516],
              [-38.645279445404071, -8.687268261121529],
              [-38.645273225908348, -8.68727909975763],
              [-38.64526696605634, -8.687289915366758],
              [-38.64526066593416, -8.687300707800098],
              [-38.645254325628471, -8.687311476909205],
              [-38.64524794522648, -8.687322222545976],
              [-38.645241524815923, -8.687332944562593],
              [-38.645235064485163, -8.687343642811538],
              [-38.645228564323013, -8.687354317145688],
              [-38.645222024418906, -8.687364967418183],
              [-38.645215444862799, -8.687375593482544],
              [-38.645208825745179, -8.687386195192614],
              [-38.645202167157116, -8.687396772402519],
              [-38.645195469190149, -8.687407324966834],
              [-38.645188731936464, -8.687417852740367],
              [-38.645181955488702, -8.687428355578303],
              [-38.645175139940079, -8.687438833336142],
              [-38.645168285384344, -8.687449285869805],
              [-38.645161391915764, -8.687459713035517],
              [-38.64515445962919, -8.687470114689814],
              [-38.645147488619948, -8.687480490689669],
              [-38.645140478983926, -8.687490840892291],
              [-38.645133430817559, -8.687501165155343],
              [-38.645126344217772, -8.687511463336818],
              [-38.645119219282059, -8.687521735295045],
              [-38.645112056108417, -8.68753198088873],
              [-38.645104854795363, -8.687542199976946],
              [-38.645097615441976, -8.687552392419134],
              [-38.64509033814781, -8.687562558075063],
              [-38.64508302301298, -8.68757269680496],
              [-38.645075670138091, -8.687582808469303],
              [-38.645068279624297, -8.687592892929031],
              [-38.645060851573234, -8.687602950045434],
              [-38.645053386087092, -8.68761297968013],
              [-38.645045883268558, -8.687622981695222],
              [-38.645038343220833, -8.687632955953079],
              [-38.645030766047618, -8.687642902316508],
              [-38.645023151853145, -8.687652820648712],
              [-38.645015500742161, -8.687662710813239],
              [-38.645007812819877, -8.687672572674048],
              [-38.645000088192077, -8.687682406095508],
              [-38.644992326964989, -8.687692210942341],
              [-38.64498452924537, -8.687701987079697],
              [-38.644976695140478, -8.687711734373059],
              [-38.644968824758095, -8.687721452688413],
              [-38.644960918206444, -8.687731141892009],
              [-38.644952975594308, -8.687740801850605],
              [-38.644944997030912, -8.68775043243131],
              [-38.644936982626035, -8.687760033501682],
              [-38.644928932489897, -8.687769604929601],
              [-38.644920846733214, -8.687779146583463],
              [-38.644912725467215, -8.687788658332016],
              [-38.644904568803632, -8.68779814004438],
              [-38.644896376854646, -8.687807591590158],
              [-38.644888149732928, -8.687817012839336],
              [-38.644879887551639, -8.687826403662338],
              [-38.644871590424451, -8.687835763929934],
              [-38.644863258465477, -8.687845093513424],
              [-38.644854891789322, -8.687854392284459],
              [-38.644846490511071, -8.687863660115125],
              [-38.644838054746288, -8.687872896877955],
              [-38.64482958461101, -8.687882102445871],
              [-38.644821080221746, -8.68789127669228],
              [-38.644812541695472, -8.687900419490953],
              [-38.644803969149621, -8.687909530716158],
              [-38.644795362702148, -8.687918610242539],
              [-38.644786722471409, -8.687927657945208],
              [-38.644778048576242, -8.687936673699721],
              [-38.644769341135977, -8.687945657382032],
              [-38.644760600270381, -8.687954608868626],
              [-38.644751826099686, -8.687963528036317],
              [-38.644743018744592, -8.68797241476245],
              [-38.644734178326217, -8.687981268924762],
              [-38.644725304966194, -8.687990090401467],
              [-38.644716398786557, -8.687998879071241],
              [-38.644707459909824, -8.688007634813172],
              [-38.64469848845895, -8.68801635750682],
              [-38.644689484557325, -8.688025047032225],
              [-38.644680448328835, -8.688033703269813],
              [-38.64467137989773, -8.688042326100536],
              [-38.644662279388768, -8.688050915405817],
              [-38.644653146927148, -8.688059471067481],
              [-38.644643982638456, -8.688067992967818],
              [-38.644634786648766, -8.688076480989647],
              [-38.644625559084552, -8.688084935016185],
              [-38.644616300072784, -8.688093354931111],
              [-38.644607009740774, -8.688101740618706],
              [-38.644597688216351, -8.688110091963516],
              [-38.644588335627702, -8.688118408850697],
              [-38.644578952103494, -8.688126691165888],
              [-38.644569537772796, -8.688134938795105],
              [-38.644560092765097, -8.688143151624939],
              [-38.644550617210328, -8.688151329542418],
              [-38.644541111238823, -8.688159472435045],
              [-38.644531574981336, -8.688167580190777],
              [-38.644522008569041, -8.688175652698135],
              [-38.64451241213353, -8.688183689846056],
              [-38.644502785806793, -8.688191691523949],
              [-38.644498735924088, -8.688195050553915],
              [-38.644494691308822, -8.68819841586188],
              [-38.644490651970848, -8.68820178743969],
              [-38.644486617919959, -8.688205165279088],
              [-38.644482589165975, -8.688208549371884],
              [-38.644478565718678, -8.688211939709884],
              [-38.644474547587869, -8.688215336284809],
              [-38.644470534783274, -8.688218739088434],
              [-38.644466527314663, -8.688222148112484],
              [-38.644462525191777, -8.688225563348643],
              [-38.644458528424352, -8.688228984788653],
              [-38.644454537022085, -8.688232412424179],
              [-38.644450550994684, -8.688235846246913],
              [-38.644446570351825, -8.688239286248448],
              [-38.6444425951032, -8.688242732420493],
              [-38.644438625258459, -8.688246184754654],
              [-38.644434660827251, -8.688249643242541],
              [-38.644430701819196, -8.688253107875729],
              [-38.644426748243944, -8.688256578645815],
              [-38.64442280011108, -8.688260055544374],
              [-38.644418857430217, -8.688263538562936],
              [-38.644414920210927, -8.688267027693033],
              [-38.644410988462774, -8.68827052292624],
              [-38.64440706219532, -8.688274024253985],
              [-38.644403141418103, -8.68827753166781],
              [-38.644399226140656, -8.688281045159158],
              [-38.644395316372481, -8.688284564719499],
              [-38.644391412123099, -8.68828809034032],
              [-38.644387513401981, -8.688291622013013],
              [-38.644383620218626, -8.688295159729005],
              [-38.644379732582451, -8.688298703479685],
              [-38.644375850502968, -8.68830225325646],
              [-38.64437197398955, -8.688305809050691],
              [-38.644368103051647, -8.688309370853787],
              [-38.64436423769866, -8.688312938657004],
              [-38.644360377939989, -8.688316512451742],
              [-38.644356523785, -8.688320092229288],
              [-38.644352675243084, -8.688323677980936],
              [-38.644348832323566, -8.688327269697986],
              [-38.644344995035823, -8.688330867371709],
              [-38.644341163389143, -8.688334470993359],
              [-38.644337337392834, -8.688338080554157],
              [-38.644333517056239, -8.688341696045347],
              [-38.644329702388603, -8.688345317458165],
              [-38.644325893399191, -8.688348944783774],
              [-38.644322090097297, -8.688352578013419],
              [-38.644318292492152, -8.68835621713818],
              [-38.644314500592948, -8.688359862149289],
              [-38.644310714408945, -8.688363513037842],
              [-38.644306933949331, -8.688367169794988],
              [-38.644303159223298, -8.688370832411838],
              [-38.644299390239993, -8.688374500879489],
              [-38.644295627008603, -8.688378175189053],
              [-38.644291869538257, -8.688381855331523],
              [-38.644288117838109, -8.688385541298034],
              [-38.644284371917252, -8.688389233079596],
              [-38.64428063178481, -8.688392930667234],
              [-38.644276897449863, -8.688396634051985],
              [-38.644273168921465, -8.688400343224854],
              [-38.644269446208725, -8.688404058176792],
              [-38.644265729320644, -8.688407778898766],
              [-38.644262018266282, -8.688411505381763],
              [-38.644258313054642, -8.688415237616727],
              [-38.644254613694748, -8.688418975594582],
              [-38.644250920195567, -8.688422719306228],
              [-38.644247232566087, -8.688426468742584],
              [-38.644243550815283, -8.688430223894553],
              [-38.644239874952085, -8.68843398475299],
              [-38.644236204985418, -8.688437751308737],
              [-38.644232540924229, -8.688441523552674],
              [-38.644228882777398, -8.688445301475593],
              [-38.644225230553822, -8.688449085068354],
              [-38.644221584262382, -8.68845287432177],
              [-38.644217943911933, -8.688456669226573],
              [-38.644214309511341, -8.688460469773602],
              [-38.64421068106941, -8.688464275953589],
              [-38.644207058594951, -8.688468087757283],
              [-38.64420344209681, -8.688471905175421],
              [-38.644199831583762, -8.688475728198748],
              [-38.644196227064569, -8.688479556817978],
              [-38.64419262854797, -8.688483391023771],
              [-38.644189036042768, -8.688487230806819],
              [-38.644185449557632, -8.688491076157801],
              [-38.644181869101324, -8.688494927067374],
              [-38.644178294682511, -8.688498783526171],
              [-38.644174726309906, -8.688502645524816],
              [-38.644171163992162, -8.688506513053941],
              [-38.64416760773797, -8.688510386104142],
              [-38.644164057555933, -8.688514264665992],
              [-38.644160513454693, -8.688518148730063],
              [-38.64415697544289, -8.688522038286932],
              [-38.644153443529056, -8.688525933327167],
              [-38.644149917721833, -8.688529833841262],
              [-38.644146398029783, -8.688533739819734],
              [-38.644142884461424, -8.688537651253132],
              [-38.64413937702534, -8.688541568131923],
              [-38.644135875730029, -8.688545490446591],
              [-38.644132380584011, -8.688549418187632],
              [-38.644128891595763, -8.688553351345437],
              [-38.64412883328162, -8.688553324370089],
              [-38.644128878137124, -8.688553364788604],
              [-38.64412887094641, -8.688553373116429],
              [-38.646103929052288, -8.69110950236078],
              [-38.649352152080439, -8.687901489684801],
              [-38.652221847960213, -8.690961367784265],
              [-38.654513508374514, -8.689977534396574],
              [-38.654595370622225, -8.689923337884881],
              [-38.65252594204172, -8.686493357407009],
              [-38.658299931708086, -8.684608144917153],
              [-38.659656784725307, -8.686665224696085],
              [-38.660598148756954, -8.686383041877935],
              [-38.66299186810496, -8.683029422547804],
              [-38.663273702353024, -8.682984445811817],
              [-38.663528499863794, -8.682677136254382],
              [-38.668631033180588, -8.679289677466363],
              [-38.669785413208693, -8.679353994146474],
              [-38.669063527586324, -8.683658853114858],
              [-38.668989854931318, -8.68474420524702],
              [-38.668853430937453, -8.684825493008972],
              [-38.66956074668353, -8.686788900549617],
              [-38.671879288451443, -8.686175833761107],
              [-38.672187829361604, -8.686782123235924],
              [-38.673197509992399, -8.68601415342134],
              [-38.674652987795753, -8.684803349582007],
              [-38.674544147324745, -8.684522857416667],
              [-38.674371972243719, -8.683898595882996],
              [-38.674272885504337, -8.682849275162878],
              [-38.6742459193729, -8.682496491706617],
              [-38.674300740919783, -8.682170913502638],
              [-38.674719361443969, -8.68161951716308],
              [-38.674719384812121, -8.681592381762806],
              [-38.674664968845853, -8.681447613119119],
              [-38.675502788631817, -8.679666433863181],
              [-38.67642945544975, -8.680273245339217],
              [-38.677075306777553, -8.679740129084658],
              [-38.677912108990839, -8.679134811729478],
              [-38.678539760854946, -8.678619767748787],
              [-38.680077110179553, -8.677282378009549],
              [-38.68246022653539, -8.675457248017562],
              [-38.686651103304712, -8.66894078266369],
              [-38.666546397149155, -8.637907956674335],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0308000',
        uf: 'PE',
        nome_proje: 'PA JOSIAS E SAMUEL',
        municipio: 'PETROLINA',
        area_hecta: '160.9202',
        capacidade: 25.0,
        num_famili: 23.0,
        fase: 3.0,
        data_de_cr: '26/12/2012',
        forma_obte: 'Desapropriação',
        data_obten: '22/09/2010',
        area_calc_: 164.466,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.301883434087564, -9.058214115341137],
              [-40.300308571011534, -9.058936719281018],
              [-40.298390444301248, -9.059046707251616],
              [-40.296662010730472, -9.059186939885709],
              [-40.296539368592519, -9.059234668427068],
              [-40.298237447555472, -9.060699195884881],
              [-40.299120439655987, -9.06034673761094],
              [-40.302605739439635, -9.058967706752542],
              [-40.302685741037799, -9.058977911709455],
              [-40.301883434087564, -9.058214115341137],
            ],
          ],
          [
            [
              [-40.285757109771907, -9.052693101709028],
              [-40.28433072470559, -9.054142255665171],
              [-40.284297723025993, -9.054173840658935],
              [-40.284259050981724, -9.054196945219415],
              [-40.282580204390484, -9.055183802181444],
              [-40.282496955038653, -9.055232382828214],
              [-40.280242506371955, -9.056396859261767],
              [-40.280166830426197, -9.056709104011082],
              [-40.279608352781487, -9.059050274744548],
              [-40.278997896617931, -9.061512349766888],
              [-40.279512298996039, -9.061882305158756],
              [-40.282307288912506, -9.066368737217028],
              [-40.285368462558949, -9.065409755148336],
              [-40.288020134671839, -9.064576621602864],
              [-40.288108456610175, -9.064547102129104],
              [-40.290609712458732, -9.063759062374608],
              [-40.290728898847185, -9.063710985995336],
              [-40.290812572253337, -9.06367913118182],
              [-40.291238554910542, -9.063512595687838],
              [-40.291871278151881, -9.063257704469828],
              [-40.292074052296378, -9.063153990234168],
              [-40.293005656421691, -9.062781207889444],
              [-40.294079644943658, -9.062361168318013],
              [-40.295297314635917, -9.061875237528609],
              [-40.296680303461194, -9.061319991725],
              [-40.297971639528079, -9.060804854839438],
              [-40.296144538695216, -9.059232449696468],
              [-40.296028938587803, -9.059214411639308],
              [-40.294333601876474, -9.059325307889214],
              [-40.293006626470138, -9.059414639273671],
              [-40.291782621702829, -9.059475840932199],
              [-40.291310438757293, -9.059517567048555],
              [-40.291024201270424, -9.058895794747309],
              [-40.290983817072394, -9.058821514408264],
              [-40.290213108364149, -9.057397176920384],
              [-40.290036642906308, -9.057071532098981],
              [-40.289375876503406, -9.056143348419646],
              [-40.288964398929586, -9.055655396956938],
              [-40.288298513044616, -9.054923006820042],
              [-40.287600362191078, -9.054249416804089],
              [-40.287063926889608, -9.053796264796041],
              [-40.285757109771907, -9.052693101709028],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0156000',
        uf: 'PE',
        nome_proje: 'PA SERRA DA SANTA',
        municipio: 'PETROLINA',
        area_hecta: '280.9962',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 3.0,
        data_de_cr: '06/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '23/08/2005',
        area_calc_: 281.051,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.411727690963929, -9.199111709798784],
              [-40.403245065216169, -9.197982952523576],
              [-40.403368027494906, -9.196208528851354],
              [-40.40336029867921, -9.196210186556003],
              [-40.403163572966136, -9.19626276495389],
              [-40.403130653138447, -9.196268770581172],
              [-40.402746732046182, -9.196298295193476],
              [-40.402418621656295, -9.196357352074532],
              [-40.402062391632242, -9.196555319447292],
              [-40.401782999433863, -9.196714828835255],
              [-40.401395611636254, -9.196950533294508],
              [-40.401369184213316, -9.196964470902525],
              [-40.401135690506855, -9.197086094564805],
              [-40.400861319335007, -9.197111032253328],
              [-40.400728387079447, -9.197140574729028],
              [-40.400544913286488, -9.197184600465594],
              [-40.400426115260622, -9.197244561006954],
              [-40.400068579463593, -9.197388729188054],
              [-40.400028551242649, -9.197392230259343],
              [-40.399632399026949, -9.19734349161698],
              [-40.399381274485229, -9.19733252991157],
              [-40.399194964473395, -9.197303322139613],
              [-40.398930178518455, -9.197266461265388],
              [-40.398721880408104, -9.197199812328689],
              [-40.398992283732646, -9.192711807591486],
              [-40.397784203707637, -9.192732403490252],
              [-40.39694960529944, -9.192359468658921],
              [-40.396173846863221, -9.192254865252623],
              [-40.39550246934823, -9.192266413935259],
              [-40.394702557328714, -9.191966131164413],
              [-40.394206066250362, -9.192623267029118],
              [-40.394183442299962, -9.192911538036363],
              [-40.392507493908916, -9.194022547604551],
              [-40.392336566718207, -9.194016967880808],
              [-40.390314548859784, -9.196371741626473],
              [-40.403721760754586, -9.212819095002333],
              [-40.405736110180754, -9.210487064143395],
              [-40.405928810637612, -9.210312335891611],
              [-40.414572851316706, -9.205247760793107],
              [-40.411727690963929, -9.199111709798784],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0294000',
        uf: 'PE',
        nome_proje: 'PA IVAN SOUTO DE OLIVEIRA',
        municipio: 'SERRA TALHADA',
        area_hecta: '974.7703',
        capacidade: 57.0,
        num_famili: 52.0,
        fase: 3.0,
        data_de_cr: '18/08/2011',
        forma_obte: 'Doação',
        data_obten: '18/08/2011',
        area_calc_: 975.3988,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.283740774778778, -7.905802058690317],
              [-38.283697407847519, -7.905330923472845],
              [-38.283035055139294, -7.904241408995293],
              [-38.282108971196266, -7.903268663275933],
              [-38.282000192036456, -7.903011690408567],
              [-38.281946253548831, -7.902622212843445],
              [-38.281764823409866, -7.902200316211537],
              [-38.281609914997624, -7.901130123428124],
              [-38.281418052785988, -7.899449242811055],
              [-38.281344137572326, -7.898808914512316],
              [-38.281464180013131, -7.896852246616512],
              [-38.270482906228402, -7.903820893613217],
              [-38.270069683707824, -7.90408310793037],
              [-38.266214702120621, -7.906529337140481],
              [-38.26485714393079, -7.907385041049301],
              [-38.264451001462682, -7.907641111048921],
              [-38.259379449492691, -7.910837661581505],
              [-38.254450776136551, -7.913944039758296],
              [-38.252545332891067, -7.915144957896351],
              [-38.253343340782841, -7.917640513128797],
              [-38.254170473765022, -7.922407380132488],
              [-38.254945220378325, -7.924127202790291],
              [-38.260169454492789, -7.932878124334261],
              [-38.261865640923986, -7.935456305992163],
              [-38.263576391142585, -7.937101708091864],
              [-38.270396365707171, -7.940563266693305],
              [-38.2766784394491, -7.946471432772986],
              [-38.280618369410369, -7.948685321259553],
              [-38.282204089784365, -7.948688063041962],
              [-38.282478247967823, -7.948542099095496],
              [-38.282639891241317, -7.948456089527467],
              [-38.282717295869745, -7.948395441211988],
              [-38.282901086723889, -7.948251220419838],
              [-38.271773414004613, -7.920536622172762],
              [-38.274653035956014, -7.91884318475743],
              [-38.275174850994773, -7.918536293011847],
              [-38.27581290645491, -7.918161041804881],
              [-38.278109388588433, -7.916810362748534],
              [-38.286639612387923, -7.911793329227091],
              [-38.28572553945417, -7.910996263089872],
              [-38.284708570325613, -7.909581882203968],
              [-38.284165000302274, -7.908205395248141],
              [-38.28392411216138, -7.907066404387466],
              [-38.283775325146678, -7.906177845040784],
              [-38.283751014160586, -7.905914052694629],
              [-38.283740774778778, -7.905802058690317],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0014000',
        uf: 'PE',
        nome_proje: 'PA SÍTIO JARDIM',
        municipio: 'TUPARETAMA',
        area_hecta: '225.9338',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 5.0,
        data_de_cr: '28/07/1987',
        forma_obte: 'Desapropriação',
        data_obten: '05/03/1987',
        area_calc_: 218.9011,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.31294942580567, -7.673989467299885],
              [-37.312361427005683, -7.674083005899889],
              [-37.311887183905689, -7.674226027899885],
              [-37.311676843105701, -7.674305445299885],
              [-37.311328318705705, -7.674297389599892],
              [-37.311073362205704, -7.674358799599891],
              [-37.310805211105716, -7.674366662099889],
              [-37.310675111005715, -7.675768735499886],
              [-37.310407746405723, -7.677182886399892],
              [-37.310188512505725, -7.677899068299886],
              [-37.310082438005722, -7.678664525999896],
              [-37.309985526405725, -7.679376411099899],
              [-37.309751964505736, -7.682502746899885],
              [-37.309420279505737, -7.686143588399881],
              [-37.308997327405734, -7.690540805299882],
              [-37.309007233405744, -7.691424632899884],
              [-37.309038312805733, -7.692321917899895],
              [-37.309042384705741, -7.692986168399891],
              [-37.308988128105732, -7.693709256799887],
              [-37.308868817105733, -7.694496728899886],
              [-37.308743937405744, -7.69499775569989],
              [-37.30855998080574, -7.696309895899892],
              [-37.308006109005753, -7.700747825099895],
              [-37.307918569005764, -7.70141059879989],
              [-37.307844403005753, -7.703488822899891],
              [-37.307653392205758, -7.705167598899894],
              [-37.307624099305755, -7.706354410799896],
              [-37.307521913605768, -7.707328067599891],
              [-37.307287057305764, -7.709917203899893],
              [-37.306696681505777, -7.709875446299882],
              [-37.306693489105776, -7.710555685099878],
              [-37.306561440905774, -7.711311660299883],
              [-37.306607205605779, -7.711462534699884],
              [-37.306244852805783, -7.711833895899876],
              [-37.305549925605796, -7.712392844899878],
              [-37.305388513605791, -7.712805679399891],
              [-37.305403120805799, -7.713346262199892],
              [-37.305273175605798, -7.714274112899889],
              [-37.30467282660581, -7.717733878399893],
              [-37.304266386905816, -7.720053735899895],
              [-37.303690753905826, -7.723472915099883],
              [-37.303524871005827, -7.724444085599891],
              [-37.303366133405824, -7.725444260699876],
              [-37.302817285005844, -7.728725345399889],
              [-37.302539264505839, -7.730355842099881],
              [-37.30219317240585, -7.73252324739988],
              [-37.307322231705761, -7.733079130099878],
              [-37.30774243820575, -7.728428379299894],
              [-37.308039418905743, -7.726239592599883],
              [-37.308448805405746, -7.721632815999887],
              [-37.308542549005743, -7.720536532999882],
              [-37.308822101505733, -7.717246561699881],
              [-37.309136846605732, -7.713954498999883],
              [-37.309138809405731, -7.713741639899885],
              [-37.309170462505726, -7.713367297499893],
              [-37.309350589005717, -7.711617150799892],
              [-37.30947661590573, -7.70998492549989],
              [-37.309625772505726, -7.708717506999895],
              [-37.309605956805719, -7.708361628799893],
              [-37.309821520605723, -7.705843738799897],
              [-37.310007649305717, -7.703985508399879],
              [-37.310165316605719, -7.701978381099885],
              [-37.310249714005714, -7.700980132899889],
              [-37.310485420105714, -7.699295956699896],
              [-37.310661031805708, -7.69755136059988],
              [-37.310852603505708, -7.695725469199894],
              [-37.311074395105699, -7.694167173499896],
              [-37.311164717905697, -7.693645948499882],
              [-37.311258344505703, -7.692571950099895],
              [-37.311320344805701, -7.691584757199893],
              [-37.311424802105705, -7.690031570799889],
              [-37.311511916105701, -7.688908508799885],
              [-37.311660555105696, -7.687482547499887],
              [-37.311711931205693, -7.686921046999893],
              [-37.311799218205699, -7.685753405499887],
              [-37.311990472905684, -7.684285260399893],
              [-37.31206961100569, -7.683200058699892],
              [-37.312076061705682, -7.682132402999887],
              [-37.312384790105675, -7.679770897599895],
              [-37.312422789905675, -7.678382022499884],
              [-37.312438966605683, -7.676799879999891],
              [-37.31294942580567, -7.673989467299885],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0087000',
        uf: 'PE',
        nome_proje: 'PA DONA LIDIA',
        municipio: 'CABROBO',
        area_hecta: '94.3729',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 4.0,
        data_de_cr: '17/06/2004',
        forma_obte: 'Confisco',
        data_obten: '17/06/2004',
        area_calc_: 94.4343,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.286645205886252, -8.542141616368582],
              [-39.271728794392779, -8.525638989398091],
              [-39.269314929354685, -8.526860243312278],
              [-39.28574705411625, -8.544934634752785],
              [-39.290352926437983, -8.549026891008594],
              [-39.290575826095349, -8.5484219596764],
              [-39.291298594005298, -8.547452853373008],
              [-39.292038687890383, -8.546807280627679],
              [-39.287961635723207, -8.543384360275867],
              [-39.287266964777878, -8.542801277302358],
              [-39.286645205886252, -8.542141616368582],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0371000',
        uf: 'PE',
        nome_proje: 'PA SÃO BOA VENTURA',
        municipio: 'BETANIA',
        area_hecta: '3579.2926',
        capacidade: 121.0,
        num_famili: 120.0,
        fase: 4.0,
        data_de_cr: '03/09/2007',
        forma_obte: 'Compra e Venda',
        data_obten: '21/12/2006',
        area_calc_: 3578.6302,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.790961628888937, -8.187833301538104],
              [-37.794090020888454, -8.191826238627408],
              [-37.794207942872312, -8.19197108244586],
              [-37.794881310902426, -8.192831969252625],
              [-37.797019722706963, -8.195558326003757],
              [-37.797179867900461, -8.195758904223101],
              [-37.798338782256671, -8.197245945741528],
              [-37.798508041300337, -8.197452154197403],
              [-37.799919045917093, -8.199253666234315],
              [-37.800379415262078, -8.199842090484392],
              [-37.800439932296136, -8.199885706850523],
              [-37.80053581959114, -8.199993743155463],
              [-37.800546385978436, -8.20006785225848],
              [-37.800729317683803, -8.20027198587651],
              [-37.800850104693808, -8.200312954728473],
              [-37.801019063884311, -8.200288005870286],
              [-37.80120508828999, -8.200315718729815],
              [-37.801458461047631, -8.200475237356276],
              [-37.80153889857521, -8.200637191669905],
              [-37.801574847312715, -8.200911156616691],
              [-37.803701059352989, -8.201556809990752],
              [-37.806189771759669, -8.202278842963695],
              [-37.809348278272637, -8.203194954179665],
              [-37.811397131047009, -8.203789617122302],
              [-37.811770201130457, -8.203902175834468],
              [-37.813521820688408, -8.204401072871297],
              [-37.816866475020561, -8.205372668562934],
              [-37.816892443991748, -8.205379350858024],
              [-37.817548607310343, -8.205563007742876],
              [-37.81909720463409, -8.206017563200847],
              [-37.823547499254239, -8.207313849257435],
              [-37.82727326379613, -8.208398728073739],
              [-37.827337199420725, -8.208421375448941],
              [-37.830672388510287, -8.209390627244153],
              [-37.842527317156105, -8.213048072424261],
              [-37.852437500563084, -8.21707035273548],
              [-37.874410680010328, -8.234703401708753],
              [-37.879205578181306, -8.224513334452773],
              [-37.862990149632111, -8.207597937957052],
              [-37.85888137225524, -8.204413828474717],
              [-37.857595911164871, -8.203088828270479],
              [-37.857358833655084, -8.202945183134904],
              [-37.856982849935036, -8.200734905647716],
              [-37.85672315597359, -8.199026116431533],
              [-37.857152072238819, -8.196631098400912],
              [-37.857530417216495, -8.19462654288856],
              [-37.85795144047615, -8.192425190656051],
              [-37.858290481808055, -8.190637041045877],
              [-37.858666617387094, -8.188741945395288],
              [-37.859148069173727, -8.186327988289506],
              [-37.859134986215572, -8.186054052243243],
              [-37.859019913525103, -8.181791406559421],
              [-37.858943524929039, -8.181550049414819],
              [-37.85852275894657, -8.178547986675753],
              [-37.858396396230269, -8.17790895010301],
              [-37.858356819928098, -8.177846096141677],
              [-37.855498070296733, -8.173546189673218],
              [-37.85494795255763, -8.173391532585681],
              [-37.854426536345613, -8.173393729535015],
              [-37.853025355470201, -8.172531380174663],
              [-37.852330240894027, -8.171765471355851],
              [-37.852282990406636, -8.171511025560227],
              [-37.852571252498819, -8.170894797435228],
              [-37.84677625834798, -8.17042050291211],
              [-37.846093271718189, -8.170271092660956],
              [-37.840979327611407, -8.16786531365932],
              [-37.834747629873988, -8.162396655956888],
              [-37.831696012263869, -8.159687297131953],
              [-37.823745350698999, -8.16025249525747],
              [-37.823691027795768, -8.160256483921202],
              [-37.814013318539587, -8.160987529156985],
              [-37.81306329650608, -8.162730117266703],
              [-37.812223466303763, -8.164281280820953],
              [-37.811396309032304, -8.165807996998208],
              [-37.810695470716119, -8.167092416364396],
              [-37.810200616762842, -8.168148835695412],
              [-37.80491862769604, -8.175686607090764],
              [-37.804876673400479, -8.175746648501782],
              [-37.804677265921605, -8.17608085868256],
              [-37.804510699674495, -8.176299398142419],
              [-37.803275009416431, -8.177021454876662],
              [-37.802317612756035, -8.17764030515265],
              [-37.801440797929111, -8.178206212532526],
              [-37.798043167645943, -8.178602114106956],
              [-37.794903789582982, -8.178968614297212],
              [-37.790995050444948, -8.179430304013666],
              [-37.787912275406939, -8.179804823770722],
              [-37.784992250244279, -8.180170409459659],
              [-37.785000879216078, -8.180247605999552],
              [-37.786019428750038, -8.181570326762513],
              [-37.787008462915544, -8.182820291632698],
              [-37.787052347932182, -8.182874076873668],
              [-37.787819796232043, -8.18385360166919],
              [-37.790479758760839, -8.187160682957456],
              [-37.790823090755701, -8.187176399967711],
              [-37.790961628888937, -8.187833301538104],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0301000',
        uf: 'PE',
        nome_proje: 'PA JULIA SIQUEIRA',
        municipio: 'SAO JOSE DO BELMONTE',
        area_hecta: '445.3293',
        capacidade: 14.0,
        num_famili: 14.0,
        fase: 3.0,
        data_de_cr: '12/06/2012',
        forma_obte: 'Desapropriação',
        data_obten: '04/05/2009',
        area_calc_: 450.2115,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.733182758245434, -7.798731183708143],
              [-38.757050245392939, -7.808535058836505],
              [-38.757074386474805, -7.80882453174969],
              [-38.757110068508261, -7.809233503597266],
              [-38.757138967848412, -7.809607917368171],
              [-38.757219736969667, -7.810003618066773],
              [-38.75728520541611, -7.810193703598432],
              [-38.771064132379529, -7.816075150806344],
              [-38.769904377818719, -7.81393993995504],
              [-38.771641028433564, -7.807193404466806],
              [-38.771533301447711, -7.805138637040453],
              [-38.748801289875601, -7.795794497405952],
              [-38.740712636429578, -7.792491148536599],
              [-38.737385841052486, -7.791112903858479],
              [-38.730255366555674, -7.798370581080189],
              [-38.730327078142764, -7.79856257399142],
              [-38.732895038133883, -7.799618916697882],
              [-38.733182758245434, -7.798731183708143],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0028000',
        uf: 'PE',
        nome_proje: 'PA ARARAS',
        municipio: 'FLORES',
        area_hecta: '542.8997',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '20/10/1989',
        forma_obte: 'Desapropriação',
        data_obten: '30/03/1988',
        area_calc_: 543.4037,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.934811670000542, -7.92267361],
              [-37.93457861000055, -7.92267361],
              [-37.932587500000544, -7.92314056],
              [-37.932637500000546, -7.924705],
              [-37.93260750000055, -7.9263875],
              [-37.93116417000055, -7.92637444],
              [-37.931117500000546, -7.92638389],
              [-37.931719170000548, -7.928175829999989],
              [-37.931924720000545, -7.92892556],
              [-37.932063890000549, -7.92947472],
              [-37.932178610000548, -7.92972722],
              [-37.932412500000545, -7.93054444],
              [-37.932699170000546, -7.93168306],
              [-37.932696670000546, -7.931945],
              [-37.932660560000549, -7.932135],
              [-37.932395560000558, -7.932794719999985],
              [-37.932102780000548, -7.933497499999989],
              [-37.931812220000545, -7.93415222],
              [-37.931411110000546, -7.93475111],
              [-37.930973890000551, -7.93529028],
              [-37.930543060000552, -7.93581278],
              [-37.930003060000551, -7.93647333],
              [-37.929593060000556, -7.936969719999987],
              [-37.929171670000557, -7.937534169999985],
              [-37.928975280000557, -7.93790806],
              [-37.928852500000552, -7.938190559999988],
              [-37.928564170000556, -7.93885472],
              [-37.928311670000554, -7.93949139],
              [-37.927994440000553, -7.94014889],
              [-37.927657220000562, -7.94078056],
              [-37.92674528000056, -7.941246389999985],
              [-37.927119170000559, -7.94194111],
              [-37.927342500000563, -7.942225559999987],
              [-37.929790280000553, -7.94303917],
              [-37.931025000000545, -7.94356167],
              [-37.935262220000538, -7.945184169999989],
              [-37.936130000000531, -7.94545167],
              [-37.938162780000525, -7.95342167],
              [-37.938919170000524, -7.95353278],
              [-37.94025556000053, -7.95362333],
              [-37.942115000000527, -7.95402778],
              [-37.946067780000512, -7.95469667],
              [-37.946632780000513, -7.95477167],
              [-37.947851110000506, -7.95413583],
              [-37.948268610000504, -7.95333389],
              [-37.948871670000507, -7.951658889999987],
              [-37.949175280000503, -7.950847499999983],
              [-37.949397500000508, -7.950385559999989],
              [-37.9497147200005, -7.94960833],
              [-37.949905560000502, -7.94908667],
              [-37.949920000000503, -7.94897111],
              [-37.949936670000504, -7.94826111],
              [-37.949945280000506, -7.94807556],
              [-37.949962500000503, -7.947566109999988],
              [-37.949969440000501, -7.94730889],
              [-37.949989170000507, -7.94685083],
              [-37.950015830000503, -7.946085],
              [-37.950347780000506, -7.94444083],
              [-37.950498330000499, -7.94344944],
              [-37.950558330000504, -7.94332722],
              [-37.950596940000501, -7.94334611],
              [-37.950868890000507, -7.94290528],
              [-37.951431110000499, -7.941934169999986],
              [-37.951861390000495, -7.94108806],
              [-37.9519925000005, -7.940836669999988],
              [-37.951636670000497, -7.93989417],
              [-37.951497220000498, -7.93946028],
              [-37.951960280000499, -7.93883028],
              [-37.952758060000498, -7.937880829999987],
              [-37.953160000000487, -7.93744389],
              [-37.9534580600005, -7.93716222],
              [-37.9541625000005, -7.93648167],
              [-37.954386110000492, -7.93627389],
              [-37.954237220000493, -7.93609917],
              [-37.953622780000494, -7.935411939999987],
              [-37.952787500000497, -7.93446917],
              [-37.9515366700005, -7.93305944],
              [-37.9502522200005, -7.93293],
              [-37.947640830000509, -7.93271333],
              [-37.946557220000514, -7.932602219999987],
              [-37.94624639000051, -7.932436669999986],
              [-37.945925560000511, -7.932205],
              [-37.942076940000518, -7.93049139],
              [-37.941968330000527, -7.93044278],
              [-37.94094167000052, -7.93007194],
              [-37.939650280000528, -7.92980722],
              [-37.938851110000535, -7.92968083],
              [-37.938031940000542, -7.92948972],
              [-37.93745222000053, -7.92939861],
              [-37.936302780000538, -7.92916306],
              [-37.936764720000532, -7.92745861],
              [-37.936718060000537, -7.92633806],
              [-37.936651390000542, -7.92602472],
              [-37.936347220000535, -7.92518694],
              [-37.936326940000534, -7.925181389999989],
              [-37.935749720000537, -7.92458167],
              [-37.935533890000542, -7.924061669999989],
              [-37.935218060000537, -7.923048059999989],
              [-37.935133610000541, -7.92278861],
              [-37.934811670000542, -7.92267361],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0051000',
        uf: 'PE',
        nome_proje: 'PA MANDACARU',
        municipio: 'PETROLINA',
        area_hecta: '481.6786',
        capacidade: 70.0,
        num_famili: 64.0,
        fase: 5.0,
        data_de_cr: '15/01/2001',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2000',
        area_calc_: 482.773,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.579056261419396, -9.257137891770299],
              [-40.573079807447385, -9.256748973356149],
              [-40.569454297226464, -9.256070367944796],
              [-40.568030333866282, -9.255788528644397],
              [-40.567288671148823, -9.25562449914686],
              [-40.566183319330634, -9.255295507544362],
              [-40.565816567678631, -9.257379339810324],
              [-40.565638074624196, -9.258239449774665],
              [-40.565580956545261, -9.258618278386434],
              [-40.566762913716026, -9.263005785209941],
              [-40.568710036413208, -9.263323309643047],
              [-40.57044894063317, -9.264178363838312],
              [-40.572658758541401, -9.266668438464693],
              [-40.573087870469458, -9.267153270463645],
              [-40.575190635744846, -9.269528782663777],
              [-40.57362188576122, -9.271307410207443],
              [-40.576775145874059, -9.27254879480896],
              [-40.57684498683323, -9.272678054948726],
              [-40.579591147976636, -9.27379751401063],
              [-40.580859838395313, -9.274306655367235],
              [-40.581240968371624, -9.274458137670207],
              [-40.581078317030638, -9.275351634188025],
              [-40.584550491162062, -9.277227251144456],
              [-40.585572828269527, -9.277780140553727],
              [-40.586965228869374, -9.278531386536288],
              [-40.588040709817456, -9.279606278740966],
              [-40.589405015781381, -9.280988022794151],
              [-40.590518572411121, -9.282106587743264],
              [-40.59144718524503, -9.283043510807031],
              [-40.593130053445051, -9.280165381609976],
              [-40.593166086060968, -9.280102562438667],
              [-40.593202118665317, -9.280039743265693],
              [-40.594943793205225, -9.277048508108427],
              [-40.595018121623525, -9.276920418252219],
              [-40.59677212998912, -9.273925507104043],
              [-40.596845102218651, -9.273799683524906],
              [-40.597350979247878, -9.272936300210192],
              [-40.599550670497877, -9.269161179306705],
              [-40.595136994200175, -9.267712057435405],
              [-40.593506831095219, -9.267147883751624],
              [-40.591870710852298, -9.26659385491141],
              [-40.590227020737011, -9.266055674266591],
              [-40.589888272120326, -9.265936019986642],
              [-40.588584628983654, -9.265522000313302],
              [-40.587084535108616, -9.265037416700881],
              [-40.58692170929303, -9.264956130143394],
              [-40.58452578669435, -9.262852157347128],
              [-40.584416838101582, -9.262790161126782],
              [-40.582978789360524, -9.261910070252267],
              [-40.581489730807924, -9.260998732407488],
              [-40.581932435404582, -9.258906154766178],
              [-40.579205750326224, -9.257229729973732],
              [-40.579056261419396, -9.257137891770299],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0028000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOSÉ DO VALE',
        municipio: 'PETROLINA',
        area_hecta: '446.9144',
        capacidade: 36.0,
        num_famili: 36.0,
        fase: 5.0,
        data_de_cr: '19/12/1997',
        forma_obte: 'Compra e Venda',
        data_obten: '09/07/1997',
        area_calc_: 446.8664,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.275005933260431, -9.05869349630856],
              [-40.274426364330537, -9.059035528475553],
              [-40.271708540916833, -9.060668458505519],
              [-40.271970301430265, -9.060883403487606],
              [-40.272466559239994, -9.061372980444888],
              [-40.273889734954899, -9.063044495944892],
              [-40.275315386211958, -9.064691850779033],
              [-40.276253141093875, -9.06579561347319],
              [-40.277270069322952, -9.066981929453696],
              [-40.277736984665793, -9.067530559240963],
              [-40.277793373141698, -9.067577385321194],
              [-40.277889342682542, -9.067600380665924],
              [-40.277993017045496, -9.067589709451745],
              [-40.278077899492025, -9.067564635239915],
              [-40.282307288912506, -9.066368737217028],
              [-40.279512298996039, -9.061882305158756],
              [-40.278997896617931, -9.061512349766888],
              [-40.275005933260431, -9.05869349630856],
            ],
          ],
          [
            [
              [-40.262609618430524, -9.052834215615018],
              [-40.262294910283792, -9.052850311678567],
              [-40.261807006012553, -9.05287080223485],
              [-40.261060608103875, -9.052905656266478],
              [-40.260499756734617, -9.052929923011124],
              [-40.259990492545171, -9.052955910466725],
              [-40.258504910012782, -9.053033814033302],
              [-40.260535023077558, -9.05451412991763],
              [-40.256881846497805, -9.057976764615677],
              [-40.256793323396792, -9.058079887231118],
              [-40.257360883349818, -9.058517336496394],
              [-40.257919055228768, -9.058949300737696],
              [-40.258568719276376, -9.059346766905788],
              [-40.259173752153274, -9.059780647589045],
              [-40.259838760672295, -9.060194787801212],
              [-40.260421043814894, -9.060678299785165],
              [-40.260981676663526, -9.061187928965715],
              [-40.261505050114572, -9.061708627501368],
              [-40.262059000301782, -9.062283658231827],
              [-40.262533978231609, -9.06280434092154],
              [-40.263076687499172, -9.063233635966041],
              [-40.26364129276957, -9.063680307296785],
              [-40.264159138855923, -9.064153182849116],
              [-40.264600795856097, -9.064587436182768],
              [-40.265109265899326, -9.065057991274333],
              [-40.265667038135845, -9.065500341738646],
              [-40.26613806637765, -9.06600755808245],
              [-40.266841880679614, -9.066300915636006],
              [-40.267406728132976, -9.066762138115557],
              [-40.267894679467496, -9.067268659960577],
              [-40.268445449764769, -9.067710034777132],
              [-40.268927141171524, -9.068220736317929],
              [-40.269381408016912, -9.068771230713251],
              [-40.269855680067451, -9.069292717482655],
              [-40.270289198801123, -9.069840840822797],
              [-40.270707089063478, -9.070652709554688],
              [-40.271268713824952, -9.071125510538007],
              [-40.271668523483747, -9.071736325876708],
              [-40.272148350844972, -9.072257337152385],
              [-40.272599624031393, -9.072833156691363],
              [-40.273009065234838, -9.073439324526401],
              [-40.273640434666078, -9.073874349747012],
              [-40.274008284849131, -9.074475596963646],
              [-40.274537399197015, -9.075013885060079],
              [-40.274973594937599, -9.075568864079711],
              [-40.275277885814148, -9.076230104865491],
              [-40.275393542157012, -9.076159528166551],
              [-40.281424089243501, -9.072084137424667],
              [-40.28097984667712, -9.071467432018844],
              [-40.280488687044873, -9.070909126434929],
              [-40.280016756416593, -9.070359705110826],
              [-40.279530466998601, -9.069788539835583],
              [-40.279043280248523, -9.069246133251294],
              [-40.27858279897081, -9.068691605362659],
              [-40.278099809283709, -9.068151804865371],
              [-40.277646302213107, -9.067613437808443],
              [-40.277182966707024, -9.067048066022297],
              [-40.276743624162975, -9.066526829507135],
              [-40.276171621460236, -9.065871225367543],
              [-40.275707116572761, -9.065334069018357],
              [-40.275225122237536, -9.064764418354329],
              [-40.27475171765952, -9.064205136256497],
              [-40.274271270607443, -9.063660617820473],
              [-40.273805923750949, -9.063115232107149],
              [-40.273331319318288, -9.062549802526934],
              [-40.272849685765834, -9.062002934561939],
              [-40.272387402318806, -9.06144785983496],
              [-40.27186955622075, -9.060976353230934],
              [-40.271347716048972, -9.060455213888467],
              [-40.27079987316344, -9.059938142764784],
              [-40.270522286868754, -9.059672068683932],
              [-40.270376982016735, -9.059640561079082],
              [-40.270057965554933, -9.059830032578363],
              [-40.269801944776262, -9.059720597110106],
              [-40.26927544878022, -9.059141685913632],
              [-40.268669850321437, -9.058574984568818],
              [-40.268064290520698, -9.058018771630183],
              [-40.267444914957785, -9.0574395457473],
              [-40.266841905489514, -9.056883140558261],
              [-40.266242906639029, -9.056328438401183],
              [-40.265682888414766, -9.055811851943599],
              [-40.265123305131176, -9.055289113690291],
              [-40.264562922720586, -9.054771531595605],
              [-40.26396528399475, -9.05429169571123],
              [-40.263477471220043, -9.053765631940237],
              [-40.26293826699154, -9.053267506425126],
              [-40.263001459407036, -9.053205796373522],
              [-40.262609618430524, -9.052834215615018],
            ],
          ],
          [
            [
              [-40.292507005993613, -9.063080118093394],
              [-40.292002703829375, -9.063299741808351],
              [-40.293247665570306, -9.067108533878411],
              [-40.294893721423342, -9.072197539325558],
              [-40.295053389489702, -9.072503345205753],
              [-40.29511285162387, -9.072670788843347],
              [-40.295392040260005, -9.073705657104268],
              [-40.295600316866611, -9.07363836345611],
              [-40.293674496163227, -9.067000318573159],
              [-40.292507005993613, -9.063080118093394],
            ],
          ],
          [
            [
              [-40.291905808404721, -9.063348372848969],
              [-40.289068619867365, -9.064348484320536],
              [-40.288274491378679, -9.064599960804582],
              [-40.282334679454195, -9.066473267426209],
              [-40.278118349517712, -9.067683588914788],
              [-40.278012759423021, -9.067718501847095],
              [-40.277979848477152, -9.067776220167694],
              [-40.277994792517305, -9.067860176524761],
              [-40.278169190142485, -9.068090884469521],
              [-40.280232896297996, -9.070456882831895],
              [-40.281079141070357, -9.071401523069595],
              [-40.281691739815379, -9.072314154403287],
              [-40.281641876468285, -9.072388752238943],
              [-40.283148627153253, -9.072950997119163],
              [-40.289012543775598, -9.075224356485155],
              [-40.28914879554177, -9.075266377252509],
              [-40.289556193017489, -9.07536884214568],
              [-40.289736465020709, -9.075356359716302],
              [-40.290085173046627, -9.075277993081961],
              [-40.292373255212247, -9.074569267105133],
              [-40.293145877871069, -9.0743286163463],
              [-40.295281148012201, -9.073733269121284],
              [-40.295008042332199, -9.07269485247396],
              [-40.294952557021155, -9.072545480413273],
              [-40.29482161578413, -9.072309745196234],
              [-40.294670885311604, -9.071883095091694],
              [-40.293141312057934, -9.067132873523855],
              [-40.291905808404721, -9.063348372848969],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0076000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DE FÁTIMA',
        municipio: 'PETROLINA',
        area_hecta: '3786.3277',
        capacidade: 80.0,
        num_famili: 74.0,
        fase: 4.0,
        data_de_cr: '11/03/2003',
        forma_obte: 'Desapropriação',
        data_obten: '16/07/2002',
        area_calc_: 3785.7297,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.721272921851984, -9.077546587974588],
              [-40.718559277486484, -9.080750443486563],
              [-40.715875426210154, -9.083859576309241],
              [-40.715004093278431, -9.084852956562168],
              [-40.71138343554783, -9.086543205174742],
              [-40.706441744903437, -9.092148210233155],
              [-40.705081808850579, -9.093740761760992],
              [-40.704463511008868, -9.094469752665695],
              [-40.702899705482096, -9.095254786024668],
              [-40.702678046967918, -9.095356269330468],
              [-40.702538794508236, -9.095417405471666],
              [-40.699833433956833, -9.096565970752133],
              [-40.697457931901432, -9.098917909693153],
              [-40.696517624394176, -9.102530560474799],
              [-40.696347894745287, -9.102727003514929],
              [-40.695663089298023, -9.103943347001037],
              [-40.695590924308185, -9.104072701618772],
              [-40.695374677896361, -9.104436352897405],
              [-40.69496426123186, -9.105678994426675],
              [-40.694411127240272, -9.107793786706321],
              [-40.694302249105448, -9.108143918761757],
              [-40.693885819218565, -9.109463077274894],
              [-40.692707788708965, -9.113989730177272],
              [-40.691545276371244, -9.116773698148611],
              [-40.690696954603567, -9.118781729592119],
              [-40.690652674894039, -9.118922075091945],
              [-40.690471717054415, -9.120310750908756],
              [-40.690116331347298, -9.123895728781283],
              [-40.689930806276216, -9.125802400238022],
              [-40.689570317419154, -9.127434398026937],
              [-40.689179868369656, -9.129195825366949],
              [-40.688806615709154, -9.131248030496142],
              [-40.688451341689976, -9.13381505252358],
              [-40.692425822152622, -9.136264809866873],
              [-40.694127001055612, -9.137306110448646],
              [-40.697756343298472, -9.140630427222879],
              [-40.698325671083396, -9.141453859223013],
              [-40.698859733804447, -9.141401360759472],
              [-40.700417927088658, -9.142268255642742],
              [-40.718259807514443, -9.139392552423891],
              [-40.756716443207566, -9.132596758594792],
              [-40.756823427861185, -9.130126612078362],
              [-40.75668941990255, -9.129215027123147],
              [-40.756330544512217, -9.126094284396483],
              [-40.756266972400525, -9.125614335544007],
              [-40.756335714989383, -9.122949088014281],
              [-40.756402301316641, -9.122223047854026],
              [-40.756463705856419, -9.121720887086342],
              [-40.756810001776984, -9.119818712321575],
              [-40.757329913711885, -9.116847960877944],
              [-40.75740017999415, -9.116465278970836],
              [-40.756684501921733, -9.112942486834621],
              [-40.756344331284645, -9.111221010076468],
              [-40.756391286161104, -9.110944943413166],
              [-40.756591369257279, -9.110470321288636],
              [-40.756666950535738, -9.110207135477614],
              [-40.756951621800305, -9.107477558183403],
              [-40.757025290661566, -9.10684162241451],
              [-40.757379286165545, -9.105473281031914],
              [-40.754214421583974, -9.101913915400116],
              [-40.752476212086201, -9.099400566908029],
              [-40.75207338104731, -9.098943763777523],
              [-40.750608288367779, -9.097387072656847],
              [-40.75036494589316, -9.096972626308236],
              [-40.749537807864002, -9.095305818695012],
              [-40.749528973096723, -9.094835366878298],
              [-40.749437903159638, -9.094157999244121],
              [-40.749370750850815, -9.09336696163594],
              [-40.749379061577287, -9.092755658899137],
              [-40.749053580397238, -9.091703218722499],
              [-40.748813416813071, -9.091286404276941],
              [-40.74785196774878, -9.090898000024236],
              [-40.746228393875093, -9.090244696907806],
              [-40.742669097297629, -9.088866866918877],
              [-40.738483006341838, -9.087206919354157],
              [-40.732055167538988, -9.084140913797224],
              [-40.728971558044314, -9.082791953602168],
              [-40.726919296877497, -9.081787344698533],
              [-40.726756086520432, -9.081705932884162],
              [-40.724607365117798, -9.080671386633444],
              [-40.721905888794169, -9.077846030824283],
              [-40.721741088399938, -9.077678642419118],
              [-40.721272921851984, -9.077546587974588],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0145000',
        uf: 'PE',
        nome_proje: 'PA TERRAS DA LIBERDADE',
        municipio: 'PETROLINA',
        area_hecta: '1656.1725',
        capacidade: 140.0,
        num_famili: 134.0,
        fase: 3.0,
        data_de_cr: '13/07/2005',
        forma_obte: 'Desapropriação',
        data_obten: '24/02/2005',
        area_calc_: 1692.3534,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.63003917, -9.25945833],
              [-40.62999694, -9.25957083],
              [-40.62026111, -9.26354028],
              [-40.61903167, -9.2640325],
              [-40.61249639, -9.26559028],
              [-40.61010833, -9.26578111],
              [-40.61001972, -9.26584222],
              [-40.60778444, -9.26936],
              [-40.60741917, -9.26984417],
              [-40.60631083, -9.26947806],
              [-40.60122722, -9.26776722],
              [-40.59837083, -9.27265361],
              [-40.59330556, -9.28131444],
              [-40.59804333, -9.28110778],
              [-40.59988417, -9.28434139],
              [-40.60092889, -9.28575556],
              [-40.60380528, -9.28774806],
              [-40.60698056, -9.28923278],
              [-40.60714139, -9.28918444],
              [-40.60978056, -9.28610111],
              [-40.60846167, -9.27805889],
              [-40.61964444, -9.27169],
              [-40.62336556, -9.273875],
              [-40.62628028, -9.275595],
              [-40.626724546286084, -9.2758569269243],
              [-40.629838459927633, -9.277692635822909],
              [-40.630765426261128, -9.27823831176058],
              [-40.631065321698664, -9.279360525674544],
              [-40.631439423064727, -9.280763770353396],
              [-40.631888912530052, -9.282447316492711],
              [-40.632461962429403, -9.284592766820827],
              [-40.63255263841728, -9.28493194931427],
              [-40.634727450049958, -9.285098677683871],
              [-40.63477944, -9.28510278],
              [-40.63650278, -9.28523833],
              [-40.6383425, -9.28538194],
              [-40.64038333, -9.28554056],
              [-40.64094444, -9.2855875],
              [-40.64094417, -9.28650472],
              [-40.64093361, -9.28863444],
              [-40.64092861, -9.28968056],
              [-40.64091611, -9.29244806],
              [-40.64088167, -9.30000722],
              [-40.64086361, -9.30441472],
              [-40.64079472, -9.31748694],
              [-40.65150028, -9.31080417],
              [-40.65400028, -9.3091875],
              [-40.65341278, -9.30740194],
              [-40.656089335074107, -9.306000099617583],
              [-40.658659520533021, -9.304630328329806],
              [-40.659691599334352, -9.303450607626006],
              [-40.661570568603146, -9.302896759246947],
              [-40.66215889, -9.30287417],
              [-40.6595, -9.29171806],
              [-40.65838861, -9.28689583],
              [-40.65784778, -9.28455556],
              [-40.65734889, -9.28238917],
              [-40.65714167, -9.28149167],
              [-40.65688639, -9.28004306],
              [-40.65647472, -9.27770222],
              [-40.65641389, -9.277355],
              [-40.65588639, -9.27612306],
              [-40.65518722, -9.27449306],
              [-40.65442917, -9.27278639],
              [-40.65376806, -9.27130056],
              [-40.65051944, -9.27089111],
              [-40.64556583, -9.27025833],
              [-40.64281778, -9.26894889],
              [-40.64267667, -9.26888],
              [-40.64113417, -9.26813194],
              [-40.63958528, -9.26738],
              [-40.63803583, -9.26662806],
              [-40.63648472, -9.26587528],
              [-40.63583806, -9.26556111],
              [-40.63574361, -9.26463028],
              [-40.63557639, -9.26298889],
              [-40.63509194, -9.26197611],
              [-40.63467333, -9.26108944],
              [-40.63440361, -9.26061778],
              [-40.63249667, -9.26010389],
              [-40.63242278, -9.26008333],
              [-40.63028583, -9.25954167],
              [-40.63003917, -9.25945833],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0042000',
        uf: 'PE',
        nome_proje: 'PA SENADOR MANSUETO DE LAVOR',
        municipio: 'PETROLINA',
        area_hecta: '714.8603',
        capacidade: 100.0,
        num_famili: 100.0,
        fase: 5.0,
        data_de_cr: '24/05/2000',
        forma_obte: 'Desapropriação',
        data_obten: '29/11/1999',
        area_calc_: 710.0512,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.249031728993444, -9.029989195733922],
              [-40.248923444536565, -9.030005841520234],
              [-40.247738396066588, -9.030264431723312],
              [-40.246817532510384, -9.030475368793727],
              [-40.246518954034521, -9.030547822555866],
              [-40.245101581538172, -9.031233753214867],
              [-40.243064223000566, -9.032413810722142],
              [-40.241522950560231, -9.035548461934777],
              [-40.241506085596185, -9.03620224041142],
              [-40.242107812952753, -9.037122412972192],
              [-40.244112815621975, -9.040051168118842],
              [-40.244261175550925, -9.040259739684206],
              [-40.244446501917224, -9.040530763493917],
              [-40.2443480917808, -9.040854295267332],
              [-40.244190375652252, -9.041365218954544],
              [-40.244027601840806, -9.041886920982506],
              [-40.24386403221235, -9.04241540795773],
              [-40.243698831271004, -9.042945799477966],
              [-40.243533200545833, -9.04348360771545],
              [-40.243364839715774, -9.044021244326187],
              [-40.24318942287092, -9.04457057034891],
              [-40.24301351536981, -9.045109588949739],
              [-40.249427469429385, -9.047470526217612],
              [-40.250891193029624, -9.048021768224881],
              [-40.250828977020291, -9.048155907488589],
              [-40.249170201961597, -9.051451774166116],
              [-40.248770972560052, -9.051966868706705],
              [-40.249142237729558, -9.052205875548065],
              [-40.248916558394079, -9.05252911878952],
              [-40.249775993001187, -9.053271240562616],
              [-40.25125307323804, -9.054257762241397],
              [-40.252816979146438, -9.055515540912769],
              [-40.25414026164021, -9.056797014492719],
              [-40.254442381572105, -9.056664856103538],
              [-40.254552475011444, -9.056564372990502],
              [-40.258504910012782, -9.053033814033302],
              [-40.259990492545171, -9.052955910466725],
              [-40.260499756734617, -9.052929923011124],
              [-40.261060608103875, -9.052905656266478],
              [-40.261807006012553, -9.05287080223485],
              [-40.262294910283792, -9.052850311678567],
              [-40.262609618430524, -9.052834215615018],
              [-40.263001459407036, -9.053205796373522],
              [-40.263235622225551, -9.053414242911584],
              [-40.263764424089629, -9.053903179979748],
              [-40.264295327929972, -9.0543944599927],
              [-40.264808256459688, -9.054869975947],
              [-40.265316352171133, -9.055341890509641],
              [-40.265817054846671, -9.055806504946524],
              [-40.266303884843737, -9.056257963789859],
              [-40.266785323359677, -9.056702206109646],
              [-40.270065570846931, -9.059741927999696],
              [-40.270371741004304, -9.059417580515913],
              [-40.271708540916833, -9.060668458505519],
              [-40.274426364330537, -9.059035528475553],
              [-40.274016200071102, -9.058916414215286],
              [-40.273618031532365, -9.05876777778791],
              [-40.273220179407403, -9.058605575393926],
              [-40.272839599550387, -9.058413832518097],
              [-40.272466361433118, -9.05818842397813],
              [-40.272118516451229, -9.057940319426427],
              [-40.271790229700308, -9.057665922031923],
              [-40.271484324535678, -9.057366126329697],
              [-40.271203089900403, -9.057045084174641],
              [-40.270951173939849, -9.056705221080996],
              [-40.270716643752117, -9.056342147491923],
              [-40.270524327497604, -9.055979650636125],
              [-40.270345880642736, -9.055577045295847],
              [-40.270202688660177, -9.05516219997938],
              [-40.270099951240233, -9.054765119258636],
              [-40.270022970939472, -9.054345432229333],
              [-40.26997490323842, -9.053920942561883],
              [-40.269964602075945, -9.053577889210047],
              [-40.26996799593114, -9.0535080659582],
              [-40.269988665837957, -9.053072848092016],
              [-40.270680642438791, -9.051893057700717],
              [-40.272839705314524, -9.048253197443932],
              [-40.276603420280367, -9.045976933547964],
              [-40.278878444106766, -9.044366056140651],
              [-40.278803825626099, -9.044307899224663],
              [-40.278196022422939, -9.04383390944621],
              [-40.276139148316112, -9.045316879729972],
              [-40.274667153756425, -9.04360809548478],
              [-40.274189576961497, -9.043068807587199],
              [-40.272246727233458, -9.042779940769979],
              [-40.270372143839161, -9.04250176867809],
              [-40.268827931059384, -9.042266392292204],
              [-40.268156055979254, -9.041571866877055],
              [-40.267641248607447, -9.041048341774772],
              [-40.267466972971754, -9.040976419662689],
              [-40.266574561846987, -9.040611092863811],
              [-40.265733511262269, -9.040260778565601],
              [-40.264963529727368, -9.039942500380988],
              [-40.264942339956349, -9.039918157466579],
              [-40.264876344902568, -9.039827774592652],
              [-40.264588031711746, -9.039244864663702],
              [-40.264530145110399, -9.039131846333305],
              [-40.264785446986885, -9.038440355060807],
              [-40.265219101561534, -9.037217336376168],
              [-40.2650937981712, -9.03707552268312],
              [-40.264518931078648, -9.036438528646967],
              [-40.263881803442011, -9.035737723671568],
              [-40.263255989051338, -9.035045740399166],
              [-40.263215745453323, -9.035009345337654],
              [-40.261927691776684, -9.035207025351212],
              [-40.260915599739022, -9.03534280319945],
              [-40.260235849729206, -9.035436834134568],
              [-40.257139023153258, -9.034282267519435],
              [-40.254437143330918, -9.033191703339657],
              [-40.253617916370452, -9.032910280778983],
              [-40.253409383941928, -9.032821286354752],
              [-40.252867363921368, -9.03231076019256],
              [-40.252157034543515, -9.031688673865284],
              [-40.25165319931908, -9.031276403122652],
              [-40.251506210752709, -9.030885070616698],
              [-40.251176044136855, -9.030053786771392],
              [-40.250935966976876, -9.030049359861096],
              [-40.249941391118547, -9.03001322911779],
              [-40.249031728993444, -9.029989195733922],
            ],
          ],
          [
            [
              [-40.290348706524533, -9.035120395549498],
              [-40.281515841558871, -9.041461669751214],
              [-40.278505480528125, -9.043621949641523],
              [-40.279105935778688, -9.044204841202188],
              [-40.28361286804919, -9.041003275645403],
              [-40.28997816601877, -9.041323213535481],
              [-40.29414351841902, -9.041526560941259],
              [-40.291453956064039, -9.03698521732521],
              [-40.290348706524533, -9.035120395549498],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0139000',
        uf: 'PE',
        nome_proje: 'PA PEDRO PAULO',
        municipio: 'PETROLINA',
        area_hecta: '590.5621',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 3.0,
        data_de_cr: '19/05/2005',
        forma_obte: 'Desapropriação',
        data_obten: '05/05/2004',
        area_calc_: 590.4825,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.59180422924463, -8.647218849031459],
              [-40.591578753652136, -8.648548404067068],
              [-40.59040430992043, -8.648466392731724],
              [-40.589810761396464, -8.650339720427993],
              [-40.590996017757867, -8.650896478153177],
              [-40.591141822789091, -8.652058570616767],
              [-40.591342628391985, -8.652578277984281],
              [-40.592312279714285, -8.654043100490529],
              [-40.592328222494409, -8.654312482740254],
              [-40.602630076072941, -8.656751541840084],
              [-40.607059580479294, -8.657776110355757],
              [-40.611797096871726, -8.658837300468507],
              [-40.612843183260942, -8.659048536332042],
              [-40.61506584770234, -8.659345941996733],
              [-40.617966054208097, -8.659681788220768],
              [-40.61805531737685, -8.65969036180541],
              [-40.621107728493975, -8.660028432336116],
              [-40.622296309861852, -8.66015937848176],
              [-40.625844762642934, -8.660545576469204],
              [-40.629339713578524, -8.660935132826513],
              [-40.631535666770951, -8.66114710777584],
              [-40.632855599488678, -8.661290750402614],
              [-40.634136104458783, -8.661414755488471],
              [-40.636576463051043, -8.66160268950094],
              [-40.640523384631535, -8.661892463424573],
              [-40.642990350467208, -8.662119029886851],
              [-40.644825489998659, -8.66220489868998],
              [-40.646086674501987, -8.662232109314566],
              [-40.649248196541443, -8.66237824563685],
              [-40.649455516346897, -8.662351491807403],
              [-40.649585939589208, -8.662255810735751],
              [-40.649620687124894, -8.662097883961078],
              [-40.649832998207337, -8.655626852636752],
              [-40.649807807709259, -8.655413126219221],
              [-40.649739303138546, -8.655330509746822],
              [-40.645184032576303, -8.654280229290473],
              [-40.640950082168025, -8.653256721859213],
              [-40.640236984753123, -8.653123431061788],
              [-40.637050300270985, -8.652353073428564],
              [-40.635273201671005, -8.651958225941922],
              [-40.633359677699069, -8.651489086386807],
              [-40.632083322888626, -8.651181598087154],
              [-40.631264967425921, -8.651016278337639],
              [-40.630601061105459, -8.650890353526769],
              [-40.630119418528231, -8.650848824326248],
              [-40.62986733961786, -8.650798450527928],
              [-40.628888408162489, -8.65066880077225],
              [-40.628299693214956, -8.650596802145259],
              [-40.62753666303972, -8.650535037782873],
              [-40.626828651086733, -8.650484521003674],
              [-40.626342235777201, -8.650452953094765],
              [-40.622799584446021, -8.650380467474934],
              [-40.621120140549586, -8.650339302172315],
              [-40.620514881079075, -8.650329207060631],
              [-40.618335824628119, -8.650315552086493],
              [-40.615490263648198, -8.650269978189501],
              [-40.613464817684104, -8.650270837140495],
              [-40.60912704749726, -8.650394618427168],
              [-40.599993802621007, -8.650834822553952],
              [-40.599447163729096, -8.650636106850143],
              [-40.59180422924463, -8.647218849031459],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0300000',
        uf: 'PE',
        nome_proje: 'PA EDIMILSON DE ARAUJO',
        municipio: 'LAGOA GRANDE',
        area_hecta: '457.4590',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '22/03/2012',
        forma_obte: 'Compra e Venda',
        data_obten: '31/12/2012',
        area_calc_: 457.4076,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.200503420062866, -8.98741958225877],
              [-40.199967695769551, -8.98775673769495],
              [-40.197924825332954, -8.989017128018741],
              [-40.194629812738235, -8.991058289088162],
              [-40.196224681838068, -8.994602667108332],
              [-40.197689867612674, -8.997895692559771],
              [-40.198706841318717, -9.00022679575078],
              [-40.199228870591789, -9.001414999883647],
              [-40.199387885813877, -9.001778289696492],
              [-40.199716373000761, -9.002530969729262],
              [-40.200180786888808, -9.003595557997627],
              [-40.200633456351873, -9.004628532420725],
              [-40.20106945459294, -9.005625477988289],
              [-40.201199798745428, -9.005927004633978],
              [-40.201473842211875, -9.006607514180208],
              [-40.201859230647031, -9.007564923755144],
              [-40.202232139040589, -9.008488642328528],
              [-40.202593769411919, -9.00938463453793],
              [-40.202889108778592, -9.010121502066111],
              [-40.202943781653147, -9.01026013609512],
              [-40.203278356664079, -9.011115611703719],
              [-40.203602760842308, -9.011948240815066],
              [-40.203922187627164, -9.01276062899818],
              [-40.204230906820989, -9.013552975903899],
              [-40.204535297426879, -9.014328787521407],
              [-40.209884832950159, -9.010859004218466],
              [-40.209977336905119, -9.010802542023054],
              [-40.215043141600411, -9.007550573915514],
              [-40.214514542991353, -9.006864858977865],
              [-40.213976448158242, -9.00616768952468],
              [-40.213427407261086, -9.005460698044816],
              [-40.212870932015306, -9.004733111559293],
              [-40.212309589656385, -9.003990980472025],
              [-40.21226052624565, -9.003925850470477],
              [-40.211727863669367, -9.003245659748833],
              [-40.211139697389513, -9.002477660634296],
              [-40.210542932296917, -9.001694315127796],
              [-40.209930833880328, -9.000894560113295],
              [-40.209527212958854, -9.000366318326888],
              [-40.209306666669633, -9.000075310096252],
              [-40.20866660394546, -8.999234588049294],
              [-40.208014208659066, -8.998376994190471],
              [-40.207343732872197, -8.997497211794824],
              [-40.20677667347622, -8.996752103595753],
              [-40.206653896228964, -8.996593074559897],
              [-40.205939889278724, -8.995667944050536],
              [-40.203303980639852, -8.991927979864778],
              [-40.200503420062866, -8.98741958225877],
            ],
          ],
          [
            [
              [-40.208581074118385, -9.012151758487471],
              [-40.208500927615745, -9.012204291245197],
              [-40.2046730541892, -9.01469657418949],
              [-40.206028925567665, -9.017944052278093],
              [-40.206854422824854, -9.019774572855585],
              [-40.207065133358185, -9.020222692959036],
              [-40.208792932957273, -9.023828036061335],
              [-40.210424389967201, -9.026928623836216],
              [-40.212496544405795, -9.030687532515762],
              [-40.214619208199835, -9.034384581947643],
              [-40.216750363624236, -9.038077063100161],
              [-40.218962856366247, -9.042028792492669],
              [-40.220386535945821, -9.044238352029787],
              [-40.22065495443708, -9.044658867064523],
              [-40.220936734063095, -9.043996063947812],
              [-40.220976809760444, -9.043470526156131],
              [-40.221080626010398, -9.043097966267181],
              [-40.221511036864236, -9.042700896859287],
              [-40.221391732145555, -9.042229786902679],
              [-40.222319131158457, -9.041163047187974],
              [-40.222155303798992, -9.040815614662874],
              [-40.221124582147944, -9.038459340219905],
              [-40.220602057608893, -9.037272814898099],
              [-40.21887063011048, -9.033511300224752],
              [-40.208581074118385, -9.012151758487471],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0338000',
        uf: 'PE',
        nome_proje: 'PA UMBURANA',
        municipio: 'ITAIBA',
        area_hecta: '1248.3249',
        capacidade: 28.0,
        num_famili: 24.0,
        fase: 3.0,
        data_de_cr: '01/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 1247.4176,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.141221867627507, -8.970312597561696],
              [-37.138042743994141, -8.97058679201692],
              [-37.137094225714492, -8.970972201178586],
              [-37.134775222403043, -8.970864399268224],
              [-37.132107023042053, -8.970640093295248],
              [-37.120697398793006, -8.971780790953291],
              [-37.11821415929608, -8.972042507604193],
              [-37.116022430139871, -8.972273516539985],
              [-37.11396714628453, -8.97249012635606],
              [-37.107057089592253, -8.973218301143747],
              [-37.107278537941212, -8.976997966733805],
              [-37.103693939370643, -8.979093202237971],
              [-37.103588139723854, -8.979155051598486],
              [-37.103471234667602, -8.979275474188441],
              [-37.102188029349385, -8.980581772264538],
              [-37.101421190512646, -8.980982454151151],
              [-37.101242353330598, -8.980708896104908],
              [-37.100886551192801, -8.980164642648941],
              [-37.099417915712863, -8.977918124365431],
              [-37.098800032471999, -8.978256126239176],
              [-37.098689633425145, -8.978363409226063],
              [-37.098624027421025, -8.978371876382415],
              [-37.098531751140712, -8.978383785602087],
              [-37.096578601546568, -8.978635854249374],
              [-37.093480759711099, -8.97903563152472],
              [-37.090384654783165, -8.979435157053683],
              [-37.088160526948613, -8.979722144459904],
              [-37.088064222789647, -8.979734570618616],
              [-37.083586723258534, -8.980312324941686],
              [-37.082378155659228, -8.980600127737263],
              [-37.080766243007673, -8.980955529274791],
              [-37.08009883677007, -8.98109020627018],
              [-37.079176955025787, -8.981330929467244],
              [-37.078807971068471, -8.981417693661342],
              [-37.078107039926103, -8.981637338264454],
              [-37.077614359556023, -8.98176531327945],
              [-37.076343057796691, -8.98218891368394],
              [-37.073894161814692, -8.983885738484505],
              [-37.073818222792134, -8.983922949400132],
              [-37.072714740851076, -8.984333579452827],
              [-37.072865663750839, -8.984887268661144],
              [-37.072647143134724, -8.984942990045349],
              [-37.071277696721893, -8.989391669004158],
              [-37.074717277340085, -8.991822838443627],
              [-37.075904351072609, -8.9917298748944],
              [-37.078740476667122, -8.993585355851645],
              [-37.081105364161147, -8.993747249726576],
              [-37.083164585888881, -8.993888206232574],
              [-37.083257247026005, -8.993894548743073],
              [-37.087948404046919, -8.994215620813078],
              [-37.089489747671692, -8.994321061776155],
              [-37.091443435951113, -8.994454774302497],
              [-37.092790866069365, -8.994540693886107],
              [-37.093922194425105, -8.994612829840039],
              [-37.096445933362453, -8.994459471736791],
              [-37.097556746895897, -8.994391966191547],
              [-37.098356588156371, -8.994410684646992],
              [-37.099839727792286, -8.994402005665446],
              [-37.101637851534555, -8.99439147545],
              [-37.102963795879376, -8.994276184108557],
              [-37.105127591843619, -8.994088030638434],
              [-37.106132445197623, -8.994000680036597],
              [-37.106211882927383, -8.994116736664573],
              [-37.107987639227417, -8.993338237267443],
              [-37.110475539324575, -8.992197696762455],
              [-37.110558391423027, -8.992159714013177],
              [-37.115605400450164, -8.989845906875892],
              [-37.116422208874972, -8.989555509288179],
              [-37.120671673284299, -8.98804467004426],
              [-37.12654483235832, -8.985956440065248],
              [-37.131214535750303, -8.984296009638303],
              [-37.136264717601023, -8.982500282951653],
              [-37.142264348430324, -8.980366764254587],
              [-37.145968008596476, -8.979049597764275],
              [-37.145710823462004, -8.978662995478944],
              [-37.145110082951028, -8.977332998297898],
              [-37.144565853580822, -8.975905556043502],
              [-37.143716766241475, -8.974384367450387],
              [-37.141221867627507, -8.970312597561696],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0355000',
        uf: 'PE',
        nome_proje: 'PA GARROTE',
        municipio: 'BREJO DA MADRE DE DEUS',
        area_hecta: '632.4109',
        capacidade: 30.0,
        num_famili: 21.0,
        fase: 5.0,
        data_de_cr: '17/04/2006',
        forma_obte: 'Desapropriação',
        data_obten: '18/11/2005',
        area_calc_: 631.0016,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.170433438651223, -7.97031499969675],
              [-36.169374647118232, -7.970388195594091],
              [-36.168907669719843, -7.970508340205308],
              [-36.167852870848954, -7.970464570201321],
              [-36.166595714856882, -7.970608425989919],
              [-36.16632025480412, -7.970679231378005],
              [-36.166051757891474, -7.970817780868606],
              [-36.165265988382089, -7.971292740926411],
              [-36.165184683428379, -7.971336147939525],
              [-36.164416486103256, -7.971787364957207],
              [-36.163648726992371, -7.972273300948962],
              [-36.162543956822034, -7.973088898062942],
              [-36.161207316767857, -7.973734564559917],
              [-36.160396413513602, -7.973998060255416],
              [-36.159706134680164, -7.974143873096597],
              [-36.159355107558902, -7.974173806805726],
              [-36.15866127109593, -7.974079913932776],
              [-36.158401141786143, -7.974125115653035],
              [-36.158149825689115, -7.974247097374612],
              [-36.158538431159869, -7.974872334110623],
              [-36.158800564824503, -7.975340183401591],
              [-36.159168392303918, -7.97591677287635],
              [-36.159334003278261, -7.976289305982244],
              [-36.159809575970598, -7.976839245166114],
              [-36.160257629389392, -7.977427385940842],
              [-36.160755359752322, -7.977779896267039],
              [-36.161400954391766, -7.977814641326699],
              [-36.161544171204774, -7.977923679394814],
              [-36.162341297254741, -7.978795301824226],
              [-36.162761785527437, -7.979351334918673],
              [-36.162874752835734, -7.979462724762985],
              [-36.162990692991102, -7.979648643382903],
              [-36.163274799769788, -7.980152371268178],
              [-36.163951676898179, -7.979648604018991],
              [-36.164548785048673, -7.980189728233608],
              [-36.163533893759507, -7.98146017866476],
              [-36.163665314834276, -7.981589745053299],
              [-36.163562967563244, -7.981677051488891],
              [-36.164367241686016, -7.982381763971345],
              [-36.164475179755648, -7.982617789441345],
              [-36.165326243432446, -7.984216991967984],
              [-36.165500977359166, -7.984419161115153],
              [-36.165824308921636, -7.985058361857392],
              [-36.165985301758596, -7.985707418071225],
              [-36.16629459728842, -7.987029440358199],
              [-36.166396808189099, -7.987403488442652],
              [-36.166316795165173, -7.988194338910208],
              [-36.16623000816584, -7.98838933031774],
              [-36.166048501511348, -7.98847358242681],
              [-36.165629499224764, -7.988569089217583],
              [-36.164409207964489, -7.988616769609028],
              [-36.16421887335445, -7.98866819984539],
              [-36.163461962050263, -7.988946761852527],
              [-36.16274812409668, -7.989248304328981],
              [-36.161916089096977, -7.9899352628993],
              [-36.16220212256858, -7.990511029191855],
              [-36.162427191193068, -7.991243438177952],
              [-36.163057121839351, -7.9924199975604],
              [-36.163684624229937, -7.993776496291281],
              [-36.163966286474185, -7.994586587601408],
              [-36.162143504984215, -7.995569665734021],
              [-36.161831988848213, -7.995717701222813],
              [-36.161516545215839, -7.99595390726642],
              [-36.161272317572902, -7.996539319326562],
              [-36.160961802779447, -7.997315162595568],
              [-36.160590579663257, -7.997924500711175],
              [-36.160408512404516, -7.998407071150226],
              [-36.15954132263802, -7.999049197375455],
              [-36.158165179153151, -7.999247803980196],
              [-36.157420725350555, -7.999629763245912],
              [-36.157105757463007, -8.000065897908765],
              [-36.157010501475881, -8.000426217257658],
              [-36.156102093884826, -8.001794354264621],
              [-36.156046807777955, -8.002059668291452],
              [-36.156029749549205, -8.002337987099105],
              [-36.156117968313822, -8.002954584118601],
              [-36.156305347392035, -8.003901556336759],
              [-36.156468309975054, -8.004354068404163],
              [-36.156600743170891, -8.004948771643482],
              [-36.156738617051886, -8.005813434898393],
              [-36.158718389474686, -8.004610546000391],
              [-36.160477542454863, -8.005721293625653],
              [-36.161482749165764, -8.006778194766067],
              [-36.163463990544784, -8.004902150154928],
              [-36.164064172782375, -8.006415609592846],
              [-36.163212794612754, -8.008390792100871],
              [-36.1631152003661, -8.008465850393828],
              [-36.16294857954918, -8.00860573729874],
              [-36.163114428949264, -8.008712016923365],
              [-36.162502997669733, -8.009551678786572],
              [-36.162973609520428, -8.010033917430693],
              [-36.162655496499255, -8.010669107029287],
              [-36.163190408342508, -8.010939524473208],
              [-36.164125860377865, -8.009237159987606],
              [-36.164984156015429, -8.00979294236781],
              [-36.165452815713017, -8.010020916379732],
              [-36.165912598769921, -8.010113215982454],
              [-36.168744920673809, -8.011187737605118],
              [-36.169462276282836, -8.011376145593934],
              [-36.170432181059454, -8.012052851075246],
              [-36.171211829451224, -8.011475786062666],
              [-36.17154864510556, -8.011248469710972],
              [-36.171134820538114, -8.01051992929388],
              [-36.172118197516504, -8.009340622641881],
              [-36.172152847556859, -8.009259465021474],
              [-36.17207098657331, -8.009036898070292],
              [-36.171809160207935, -8.00858070102867],
              [-36.171867001741958, -8.008171481879589],
              [-36.171961859994482, -8.00750037161133],
              [-36.171728676716782, -8.0070401610747],
              [-36.17208321871248, -8.00690272378003],
              [-36.172168301533354, -8.006198910955872],
              [-36.171942168009664, -8.005884743841737],
              [-36.17193785636055, -8.005711000420451],
              [-36.172211674777841, -8.005711333206261],
              [-36.172374461095174, -8.004953680937767],
              [-36.17216874642056, -8.004507175466895],
              [-36.172163440207854, -8.004300194854803],
              [-36.172511459107746, -8.003888683196742],
              [-36.172653240158759, -8.00365030259618],
              [-36.172837298492226, -8.00358090224687],
              [-36.174045462750541, -8.003075465446416],
              [-36.174787306177947, -8.002751685088272],
              [-36.174705260383945, -8.001234007658303],
              [-36.17459643713763, -7.998752302451572],
              [-36.174876969429, -7.998516322673445],
              [-36.175247480681328, -7.998204653010786],
              [-36.175436825502146, -7.99796736365288],
              [-36.176102025723125, -7.996936341012807],
              [-36.176935272695992, -7.996336003934588],
              [-36.177108834331257, -7.996026452261698],
              [-36.175703017866859, -7.995436045847153],
              [-36.17573628709026, -7.994887510249302],
              [-36.176643516521224, -7.994753402006981],
              [-36.178290821726122, -7.994688298729226],
              [-36.178294334699231, -7.993858472275617],
              [-36.177653138136179, -7.993478174012417],
              [-36.176796647314212, -7.992669277114572],
              [-36.176847248846791, -7.99135034212593],
              [-36.17669387177294, -7.989560946567968],
              [-36.178556097246975, -7.989924755914712],
              [-36.178651087928813, -7.98990965666148],
              [-36.178923754570746, -7.990638181349343],
              [-36.179083972408748, -7.990808319274757],
              [-36.179443927283863, -7.990814662003001],
              [-36.181160193600853, -7.991417515729627],
              [-36.181517687523666, -7.991412835453159],
              [-36.182392780333004, -7.99123915370558],
              [-36.182688227144297, -7.991277679056115],
              [-36.183162504897027, -7.991205913930948],
              [-36.183291677065121, -7.990179987114692],
              [-36.183351776370976, -7.989189358664665],
              [-36.183297708968752, -7.988940058281884],
              [-36.183142765982069, -7.988726074692248],
              [-36.182997155961885, -7.988527017261257],
              [-36.18294999822011, -7.987084125725602],
              [-36.182879844471195, -7.98695090564748],
              [-36.182130069671231, -7.986282401659428],
              [-36.181912320014206, -7.985137956985396],
              [-36.181649733469094, -7.9846699805002],
              [-36.181315691361839, -7.984809981944354],
              [-36.180295397834406, -7.985409825472418],
              [-36.179673693051683, -7.985773158775451],
              [-36.179420935871157, -7.985958299006052],
              [-36.179345787887684, -7.986010107651238],
              [-36.179036914292361, -7.986224258326175],
              [-36.178180313143841, -7.986487837058303],
              [-36.177755774833948, -7.986685404559398],
              [-36.177723433670316, -7.986597391210324],
              [-36.177672561217179, -7.986168534681199],
              [-36.17792940111643, -7.985203124722022],
              [-36.177918581506653, -7.984233014197166],
              [-36.176151350294248, -7.984017978335092],
              [-36.176616503499496, -7.981656974729688],
              [-36.176744886147915, -7.980796767570802],
              [-36.176197834370384, -7.980493859584704],
              [-36.174930342036042, -7.980126834748648],
              [-36.174332951826166, -7.979867942716882],
              [-36.17425440662678, -7.979773325603927],
              [-36.173687039559297, -7.979159195514116],
              [-36.173356058290572, -7.97887013004532],
              [-36.172865381171832, -7.978552172869351],
              [-36.172427689825291, -7.978199829227326],
              [-36.171862224688972, -7.977950314647349],
              [-36.171656361900524, -7.97806614435921],
              [-36.171695103832093, -7.978432964043455],
              [-36.170406622900948, -7.978289019382601],
              [-36.170502136803513, -7.976934285545346],
              [-36.170627823047951, -7.976465755218206],
              [-36.167880813551996, -7.975172633122776],
              [-36.16981256976171, -7.971601345762863],
              [-36.170051827066189, -7.97103073822344],
              [-36.170433438651223, -7.97031499969675],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0369000',
        uf: 'PE',
        nome_proje: 'PA MOLEQUE',
        municipio: 'BREJO DA MADRE DE DEUS',
        area_hecta: '700.0080',
        capacidade: 33.0,
        num_famili: 26.0,
        fase: 3.0,
        data_de_cr: '07/08/2007',
        forma_obte: 'Desapropriação',
        data_obten: '13/10/2006',
        area_calc_: 596.0646,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.271116033634947, -8.025439277533529],
              [-36.270994448851418, -8.025465841048385],
              [-36.270692409719892, -8.03791180532888],
              [-36.270691773772576, -8.037938016336337],
              [-36.270964557518212, -8.04200107634701],
              [-36.270575455164568, -8.043832036674567],
              [-36.270691221851905, -8.047134964801405],
              [-36.270580080257162, -8.049120972040097],
              [-36.276607500222191, -8.049039740541524],
              [-36.276579537271054, -8.052568636901766],
              [-36.277750050870488, -8.052481267453706],
              [-36.278080505944999, -8.05907121320228],
              [-36.277990600542388, -8.059745155577614],
              [-36.278044552246598, -8.063074450920313],
              [-36.278136062905268, -8.063406987985832],
              [-36.277956735537863, -8.065296471320289],
              [-36.277867345587694, -8.068732958839968],
              [-36.27778648124864, -8.072431897501646],
              [-36.287158525423543, -8.069921489087481],
              [-36.286775242910522, -8.064545525260952],
              [-36.287032343089962, -8.050473104206226],
              [-36.287049779998263, -8.050363470901841],
              [-36.287037883721382, -8.044284598195201],
              [-36.287002535482358, -8.039673806573466],
              [-36.288989277317611, -8.040412301533198],
              [-36.288921683244446, -8.036328308990125],
              [-36.28889268544151, -8.036289452699585],
              [-36.288861105759942, -8.036271768990442],
              [-36.288816707649644, -8.036248547787993],
              [-36.288774802032201, -8.036228534079811],
              [-36.28872997429005, -8.036209466628208],
              [-36.288683032765192, -8.036187409990754],
              [-36.288635213325421, -8.036162752694878],
              [-36.288585986227424, -8.036138840668366],
              [-36.288539675567442, -8.036118436292153],
              [-36.288486026201483, -8.036089757981713],
              [-36.288432466182392, -8.036060796051693],
              [-36.288390985093628, -8.036030501152613],
              [-36.288342577116111, -8.036007339116498],
              [-36.288292765532418, -8.035983682406147],
              [-36.288243622243499, -8.035960839751581],
              [-36.288194232595906, -8.035937978571178],
              [-36.288140520572711, -8.035917675428582],
              [-36.288086194980217, -8.035893360273736],
              [-36.288036806847053, -8.035870433668659],
              [-36.287986170101753, -8.035846316914956],
              [-36.287935756433605, -8.035823216335015],
              [-36.287884997465262, -8.035802510517353],
              [-36.287836065277524, -8.035780741391195],
              [-36.287794846356398, -8.035760567416906],
              [-36.287740122948847, -8.035742370430674],
              [-36.287686706740104, -8.035720970475781],
              [-36.287631225414067, -8.035699798449269],
              [-36.287573665829868, -8.035680177083201],
              [-36.287517551598306, -8.035660257555158],
              [-36.287461268568201, -8.035638794264411],
              [-36.28740276474425, -8.03561894195796],
              [-36.287343144188675, -8.03559822903034],
              [-36.287282980658702, -8.035578656482908],
              [-36.287220690326464, -8.035555517175608],
              [-36.287157535636283, -8.03553762566003],
              [-36.287094946534815, -8.03551589463595],
              [-36.287030083419985, -8.035496435851099],
              [-36.286966871801653, -8.035479620348532],
              [-36.286902927514724, -8.035462171861798],
              [-36.286838490078637, -8.035449600520653],
              [-36.286773903045365, -8.035434836967648],
              [-36.286709704892964, -8.035411683647641],
              [-36.286647324432344, -8.035385158439238],
              [-36.286585453664934, -8.035358212758593],
              [-36.286524435635521, -8.035330799597268],
              [-36.286461889315412, -8.035306972616485],
              [-36.286401772762815, -8.035284395418511],
              [-36.286339085326681, -8.035265136044854],
              [-36.286277404259906, -8.035246744814266],
              [-36.286218929425829, -8.035227503215964],
              [-36.286158015054802, -8.035211594395516],
              [-36.286097819533992, -8.035195612603719],
              [-36.286034922187689, -8.035181824498872],
              [-36.28597377488768, -8.035167880898362],
              [-36.285909287451531, -8.035157022905183],
              [-36.285840493077366, -8.035148987934766],
              [-36.285774495450688, -8.035137999270885],
              [-36.285707633652912, -8.035127750474382],
              [-36.285642127186449, -8.035115249196187],
              [-36.285578943614155, -8.035102036963533],
              [-36.285531542543801, -8.03508899093424],
              [-36.285476368947002, -8.035069463869625],
              [-36.28541438416179, -8.035048887909905],
              [-36.285354603537876, -8.035028119614703],
              [-36.28529696553553, -8.035004695095321],
              [-36.285237070811384, -8.034985602527374],
              [-36.285180185426654, -8.03496206280793],
              [-36.285117405466849, -8.03493997377204],
              [-36.285053476094824, -8.034921733598258],
              [-36.285002659409351, -8.034906387793104],
              [-36.284944150298529, -8.03488915570631],
              [-36.2848881255835, -8.034873372036516],
              [-36.284831637281215, -8.034854631439345],
              [-36.284770299611075, -8.034834351923474],
              [-36.284708458154803, -8.03481204812797],
              [-36.284647881549553, -8.034794658015926],
              [-36.284584464207242, -8.034780420100262],
              [-36.284521595547368, -8.034764789185402],
              [-36.284461138256923, -8.034743718272267],
              [-36.284400893792153, -8.034720893708693],
              [-36.284342946894959, -8.034700831017513],
              [-36.284283498778862, -8.034682022889768],
              [-36.284226500430286, -8.034665312671747],
              [-36.284167331240475, -8.034649232853358],
              [-36.284100865555949, -8.034628651950595],
              [-36.284041742607691, -8.034606033619331],
              [-36.283983694839208, -8.034578499515181],
              [-36.283924380430378, -8.034552933579356],
              [-36.283862894635206, -8.03453134283138],
              [-36.283804318329324, -8.034510116026944],
              [-36.283745436698126, -8.034490489172615],
              [-36.283740595708515, -8.03454510651401],
              [-36.283539123155933, -8.036512884553522],
              [-36.282721237208662, -8.03608643154446],
              [-36.281409348818578, -8.035998519531104],
              [-36.280153665076796, -8.035925389885429],
              [-36.278549439725133, -8.035874329027344],
              [-36.278300238230933, -8.035889061089149],
              [-36.278304301202176, -8.035415810855692],
              [-36.27830318510491, -8.035215967918461],
              [-36.278294062553876, -8.034814630124874],
              [-36.278294825197555, -8.034500102797715],
              [-36.278526079020118, -8.028711856562813],
              [-36.277723261886742, -8.028232161425157],
              [-36.277446423303772, -8.03194343955869],
              [-36.275224618413269, -8.031270560879067],
              [-36.275551750691832, -8.026818214252549],
              [-36.275538783841583, -8.026799915203595],
              [-36.275497257946768, -8.026756253167891],
              [-36.275444163044725, -8.026736571602054],
              [-36.27535779425218, -8.026717448472196],
              [-36.275268911691462, -8.026699580474368],
              [-36.275185368387248, -8.026680130207362],
              [-36.275102610209707, -8.026650906570953],
              [-36.275016405602564, -8.026620859158406],
              [-36.27493394374072, -8.026590425382636],
              [-36.274850876347102, -8.026561433707501],
              [-36.274763973312155, -8.02654086383424],
              [-36.274678386398975, -8.026520477833511],
              [-36.274590056110526, -8.026493836965551],
              [-36.274492842845909, -8.02645976947607],
              [-36.274398693095549, -8.026430276603568],
              [-36.274301029350703, -8.026400648162998],
              [-36.274201742355693, -8.026368371590248],
              [-36.274109019223921, -8.026341983979568],
              [-36.274006730943341, -8.02630832915953],
              [-36.273912712985513, -8.026268425772807],
              [-36.27382202966033, -8.026226960651693],
              [-36.273731363971905, -8.026182145554367],
              [-36.273636608066901, -8.02613337943894],
              [-36.273539268073002, -8.026082541330918],
              [-36.273444135103752, -8.02603340938488],
              [-36.273355472206667, -8.025988510500309],
              [-36.273259825167628, -8.025945674099905],
              [-36.273161072079503, -8.025913349580501],
              [-36.273050990312733, -8.02588630803292],
              [-36.272951761262362, -8.025866349603213],
              [-36.27284740467686, -8.025845147865802],
              [-36.272735499769887, -8.025822205278931],
              [-36.27251758865895, -8.025769860898999],
              [-36.272429413005057, -8.025746705291732],
              [-36.272345057977546, -8.025724064607557],
              [-36.272249772007754, -8.025705312343041],
              [-36.272135753877556, -8.025684446339238],
              [-36.272026487018287, -8.025659315670762],
              [-36.271919289828766, -8.02562973941121],
              [-36.27181127407286, -8.02559766815582],
              [-36.271689907318468, -8.025560735271251],
              [-36.271583065168706, -8.025533419066985],
              [-36.271467839250612, -8.025507866120659],
              [-36.27135217855529, -8.025479054034944],
              [-36.271229506890059, -8.025459266443812],
              [-36.271116033634947, -8.025439277533529],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0260000',
        uf: 'PE',
        nome_proje: 'PA PASSAGEM VELHA',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '580.0000',
        capacidade: 60.0,
        num_famili: 57.0,
        fase: 4.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/07/2001',
        area_calc_: 571.0742,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.190710553975855, -8.823572002124283],
              [-35.188366287586945, -8.824740492249727],
              [-35.185440651008449, -8.826764932556339],
              [-35.180763096721215, -8.825471886578232],
              [-35.177721560802212, -8.824636309347577],
              [-35.177384978391451, -8.824530445732778],
              [-35.176039824304688, -8.82415075657301],
              [-35.175188904428289, -8.823919275141446],
              [-35.17371796260214, -8.823522064864864],
              [-35.172053059793022, -8.823073363629355],
              [-35.171796055670342, -8.822999781990827],
              [-35.171168365349722, -8.824051255931836],
              [-35.170363488931642, -8.825280710378646],
              [-35.169172065117714, -8.826456330202012],
              [-35.167918232439042, -8.827574865771602],
              [-35.165308559366146, -8.826836064481039],
              [-35.163390609849586, -8.826802384268229],
              [-35.161240004838874, -8.827206818182239],
              [-35.159965166024548, -8.828209845032838],
              [-35.15832032876078, -8.829758696590517],
              [-35.157051176105533, -8.830848658332595],
              [-35.157181187871977, -8.831091531061785],
              [-35.157253718731077, -8.831332592096148],
              [-35.15719469730751, -8.832253748595065],
              [-35.158223311333934, -8.833013446178226],
              [-35.160526608041941, -8.834045527880342],
              [-35.160675864021265, -8.835034387172557],
              [-35.160794498302224, -8.835292201520172],
              [-35.161081134636468, -8.835967301112913],
              [-35.161180731921384, -8.836049117624196],
              [-35.161664126670452, -8.837345662626955],
              [-35.163932432736651, -8.838348051547198],
              [-35.165621594130002, -8.839326347018313],
              [-35.170097749651582, -8.841862538492325],
              [-35.170391706521869, -8.842028634933945],
              [-35.173341754325236, -8.843715491286099],
              [-35.175952498174787, -8.844753721257636],
              [-35.180445486603205, -8.844247576730897],
              [-35.181670303947918, -8.844103902007026],
              [-35.183576393187657, -8.843889771030724],
              [-35.183664389192622, -8.843877154904751],
              [-35.184013989212374, -8.843829289225642],
              [-35.185759304098063, -8.843643572482991],
              [-35.186973881643986, -8.843487792841652],
              [-35.187203524020916, -8.843486632645325],
              [-35.188626476920739, -8.843457558059415],
              [-35.188495772558014, -8.842992305031045],
              [-35.188347781397837, -8.842201961571778],
              [-35.188291777934381, -8.841811827765564],
              [-35.187447809094571, -8.837275600118536],
              [-35.187283281830936, -8.834184089940443],
              [-35.187148103367718, -8.831494294044367],
              [-35.187110718358518, -8.831048931526137],
              [-35.187161657381424, -8.830957025444356],
              [-35.190352478088158, -8.824978658677642],
              [-35.190710553975855, -8.823572002124283],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0333000',
        uf: 'PE',
        nome_proje: 'PA CACIMBA DE BAIXO',
        municipio: 'SANTA CRUZ DO CAPIBARIBE',
        area_hecta: '166.6778',
        capacidade: 10.0,
        num_famili: 9.0,
        fase: 3.0,
        data_de_cr: '30/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/2005',
        area_calc_: 157.6008,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.254970584583198, -7.891078839821025],
              [-36.254903011916703, -7.892035083795522],
              [-36.254848316948994, -7.892907381253985],
              [-36.254846906313766, -7.892928876677443],
              [-36.254844876203649, -7.892961843222563],
              [-36.254760263384178, -7.894508269500032],
              [-36.254579446565579, -7.896213451199134],
              [-36.25458328113271, -7.898403429468718],
              [-36.254581186357171, -7.898500999946114],
              [-36.254518406067362, -7.899868935262819],
              [-36.254527587667205, -7.900125063846774],
              [-36.254166377351538, -7.902617863685916],
              [-36.254136627728087, -7.902840937109969],
              [-36.254207833302083, -7.90315972851211],
              [-36.254190212255274, -7.903302826495002],
              [-36.254147689955495, -7.903525093074506],
              [-36.253842556212007, -7.903964383919229],
              [-36.253713322547696, -7.904102139659858],
              [-36.253680806633376, -7.904291220987468],
              [-36.253463287153487, -7.904842676699022],
              [-36.253509522583919, -7.904881110924585],
              [-36.253756451294656, -7.906500192370086],
              [-36.257305539675798, -7.909997711184787],
              [-36.258117731360869, -7.910516722659607],
              [-36.258177737286431, -7.908746139298668],
              [-36.25977625477136, -7.908514212619667],
              [-36.260449268329154, -7.908330418990157],
              [-36.261715677575083, -7.90860184718117],
              [-36.262079940510134, -7.901808720104323],
              [-36.262115181241697, -7.901219466474616],
              [-36.263551015998971, -7.902071900211084],
              [-36.26391853215241, -7.901983589943995],
              [-36.264153000762086, -7.901635807667947],
              [-36.264057253348163, -7.900900492155738],
              [-36.263323410940451, -7.898875044903498],
              [-36.263394344220117, -7.8964435662906],
              [-36.262911448029215, -7.896081418393854],
              [-36.262470754279789, -7.895751080968574],
              [-36.260525293751172, -7.894292274714442],
              [-36.25996061625434, -7.893868418059312],
              [-36.259458128737805, -7.893482376082433],
              [-36.2589482373102, -7.893106134090122],
              [-36.258391374128252, -7.892803314379653],
              [-36.257834603747504, -7.892500314090958],
              [-36.257277743146311, -7.892197403092301],
              [-36.256720792324735, -7.891894581383331],
              [-36.256163933708606, -7.891591669448751],
              [-36.255871463981038, -7.891432531285658],
              [-36.255582950557546, -7.891319319736868],
              [-36.255058982612496, -7.891113484130193],
              [-36.254970584583198, -7.891078839821025],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0320000',
        uf: 'PE',
        nome_proje: 'PA MACAMBIRA',
        municipio: 'POCAO',
        area_hecta: '695.5448',
        capacidade: 32.0,
        num_famili: 32.0,
        fase: 4.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '06/11/2004',
        area_calc_: 696.2992,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.780533718335676, -8.20609053916461],
              [-36.780189858335291, -8.206282139759061],
              [-36.778960468541584, -8.206208726219705],
              [-36.779270571597685, -8.206511630746661],
              [-36.779730707181621, -8.207306184008843],
              [-36.779958748547742, -8.208029152848447],
              [-36.780498083804808, -8.209200540971795],
              [-36.780429226868122, -8.209712649322094],
              [-36.780172611247835, -8.209828080191699],
              [-36.779986268877586, -8.209692598800435],
              [-36.779440965418537, -8.208970157671452],
              [-36.778780296279173, -8.20928591137721],
              [-36.778635884316692, -8.209211510763598],
              [-36.777173823728901, -8.209080556505597],
              [-36.777032761678569, -8.209438015820041],
              [-36.776700731002691, -8.209609529859177],
              [-36.776649828543114, -8.20976374133998],
              [-36.776043132403217, -8.209834526719353],
              [-36.7750393023993, -8.209302528018032],
              [-36.774554376635329, -8.208925809836428],
              [-36.774315463988671, -8.208498369477219],
              [-36.774143816332511, -8.208018649563634],
              [-36.773115639890214, -8.207812924781571],
              [-36.772739787274176, -8.207545338204186],
              [-36.772519385621344, -8.207568722719456],
              [-36.772505542757905, -8.208055090555483],
              [-36.772134751603403, -8.208715343257026],
              [-36.772200504700308, -8.20892934057466],
              [-36.772274734843009, -8.209775743433712],
              [-36.772728419728018, -8.209793150081378],
              [-36.772846302764592, -8.209844636905538],
              [-36.773395285661145, -8.210436773105338],
              [-36.773401391029452, -8.210781955975767],
              [-36.773341146495582, -8.210836136082865],
              [-36.773709210147338, -8.212019967955564],
              [-36.773657017203249, -8.212260546930896],
              [-36.773327487433882, -8.212978804812645],
              [-36.773597115693235, -8.213299648982796],
              [-36.773974587743986, -8.214039363379268],
              [-36.774455569532456, -8.215156996142674],
              [-36.775706099273243, -8.216978431214535],
              [-36.7759895407209, -8.217322020834057],
              [-36.776370349458595, -8.218040539205251],
              [-36.776278170328247, -8.218831907679403],
              [-36.776311650752284, -8.219013946062526],
              [-36.779448906795082, -8.220381713490035],
              [-36.781975096535028, -8.22268089477075],
              [-36.782285524856213, -8.223297958969088],
              [-36.782883988479334, -8.223641531054257],
              [-36.783181770379173, -8.224172203646047],
              [-36.784069701149065, -8.224871516361562],
              [-36.784049533494702, -8.225111262009451],
              [-36.784589704880482, -8.225643153513664],
              [-36.78478853334925, -8.225624644387143],
              [-36.786535570567686, -8.22630364249723],
              [-36.786831830246825, -8.22678937314379],
              [-36.787353152162666, -8.227213739800924],
              [-36.787808852022536, -8.227387546676477],
              [-36.788313861999292, -8.227957566695435],
              [-36.788491978502094, -8.227994603785447],
              [-36.788468482004738, -8.228456943158827],
              [-36.788639085161627, -8.228526885842971],
              [-36.789189290475186, -8.228705975535899],
              [-36.789257739247972, -8.228815325926492],
              [-36.790261694370599, -8.22914272826582],
              [-36.790886275352925, -8.229280655080428],
              [-36.793293779438933, -8.230249401106162],
              [-36.79697112461362, -8.231200473820206],
              [-36.79848692427867, -8.231347355860569],
              [-36.798725683991009, -8.231296717770876],
              [-36.799034557329847, -8.231154817360117],
              [-36.800905368557132, -8.230579646980946],
              [-36.802277783269453, -8.23027809838174],
              [-36.803523995571211, -8.230126797128468],
              [-36.803638084822659, -8.230042318630806],
              [-36.803963714550257, -8.230002501865055],
              [-36.804358738486997, -8.229861790833558],
              [-36.804532402655866, -8.229866166110664],
              [-36.804449239168378, -8.229559541032332],
              [-36.804529307870119, -8.229550062941613],
              [-36.80483099010393, -8.229652470320675],
              [-36.804965023057818, -8.229632033512878],
              [-36.80507072256291, -8.22946786560823],
              [-36.805408351369813, -8.229537612104776],
              [-36.80542284625912, -8.229534706698461],
              [-36.80575672928903, -8.229467781851316],
              [-36.805949983149034, -8.229363252563436],
              [-36.80610031387463, -8.229330846280192],
              [-36.808049334591615, -8.225710804485059],
              [-36.808738669775728, -8.223194996714835],
              [-36.808750257495049, -8.223103900885567],
              [-36.808972887677328, -8.221069497894382],
              [-36.807928793998578, -8.215369619121478],
              [-36.807296133785364, -8.213561784131512],
              [-36.807065116889348, -8.213574101760742],
              [-36.805819260600842, -8.213587502792551],
              [-36.805052788281181, -8.213387617684264],
              [-36.804717339517374, -8.213245510606688],
              [-36.804595914849898, -8.213284032378857],
              [-36.804204543654812, -8.21310359254359],
              [-36.804014073468608, -8.212875058394454],
              [-36.803782437351146, -8.212721287440781],
              [-36.803012575978393, -8.212252433044357],
              [-36.801933995917132, -8.212258673444612],
              [-36.799842703088551, -8.211747938437323],
              [-36.797708164787572, -8.211173702284409],
              [-36.795484367093792, -8.210682017773827],
              [-36.793069811372696, -8.210115554551216],
              [-36.792590676603325, -8.210110152264527],
              [-36.792416400732577, -8.209947371658894],
              [-36.789203797431846, -8.209170060319918],
              [-36.7881333450201, -8.20876838241248],
              [-36.787594037852251, -8.208718181395735],
              [-36.786219010438344, -8.208402114330488],
              [-36.785843095100745, -8.208620091034511],
              [-36.785270665738345, -8.208605797331074],
              [-36.784694207199678, -8.208549169403632],
              [-36.783980844482514, -8.208281333746045],
              [-36.783819697645505, -8.208075118418494],
              [-36.783326886988981, -8.2081171300213],
              [-36.783193523421879, -8.20733143741019],
              [-36.78222470275395, -8.206974723335239],
              [-36.781727206822083, -8.206815040214412],
              [-36.78148553974259, -8.20668460598991],
              [-36.781018989250363, -8.206139564948838],
              [-36.780533718335676, -8.20609053916461],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0384000',
        uf: 'PE',
        nome_proje: 'PA LAGE GRANDE',
        municipio: 'PESQUEIRA',
        area_hecta: '325.0312',
        capacidade: 27.0,
        num_famili: 23.0,
        fase: 3.0,
        data_de_cr: '21/07/2009',
        forma_obte: 'Desapropriação',
        data_obten: '29/11/2006',
        area_calc_: 327.4011,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.734594742984108, -8.429938527118994],
              [-36.733058133706528, -8.427115946365523],
              [-36.73259707734934, -8.427726458843546],
              [-36.732325773913324, -8.427660898164678],
              [-36.731770945710032, -8.426652152239669],
              [-36.730010976499884, -8.424607580784789],
              [-36.727772715863082, -8.421323947819696],
              [-36.724009546513813, -8.424312612984085],
              [-36.724009546513813, -8.424312612984085],
              [-36.722177010250881, -8.422315774036456],
              [-36.721190103669059, -8.424043179039886],
              [-36.722346533196664, -8.425443041368759],
              [-36.719739705534003, -8.4271952441698],
              [-36.718882171918509, -8.427768723221009],
              [-36.718785422574157, -8.427828176297924],
              [-36.718718869180613, -8.427877501023907],
              [-36.718681699063268, -8.427915969616031],
              [-36.718659602695475, -8.427968987399884],
              [-36.718629202810384, -8.428029368456704],
              [-36.718593563705106, -8.428085380579391],
              [-36.718551655111021, -8.42814244086189],
              [-36.718503270438944, -8.428189340390198],
              [-36.718460384418663, -8.428227323789907],
              [-36.718427455509598, -8.428270065085389],
              [-36.718397309714661, -8.428318064874396],
              [-36.71836888164767, -8.428367249648861],
              [-36.718344286049529, -8.428413022083904],
              [-36.718323562274207, -8.428464149730035],
              [-36.718311550685904, -8.428500143397299],
              [-36.718282469423528, -8.428583309023981],
              [-36.718222160470354, -8.428713202728236],
              [-36.718145892920923, -8.428840111292196],
              [-36.718081233046384, -8.428937983453194],
              [-36.718023854243825, -8.429017097918658],
              [-36.717941835182081, -8.429072207489282],
              [-36.717855190110917, -8.429142203607269],
              [-36.717767726149944, -8.429243468043941],
              [-36.717686214783434, -8.429351274774319],
              [-36.717618387287366, -8.429447501453222],
              [-36.717580594489419, -8.429530164365266],
              [-36.717560904861152, -8.429637065300202],
              [-36.717390943144203, -8.429905874775711],
              [-36.717312389723432, -8.430004569719356],
              [-36.717240324839359, -8.430064888905035],
              [-36.717172765555063, -8.430130838138803],
              [-36.717100153470732, -8.430222517581297],
              [-36.717026839645555, -8.430325490999895],
              [-36.716967891350251, -8.430424480687316],
              [-36.716926056688074, -8.430530710343954],
              [-36.716876950292637, -8.430638614976438],
              [-36.716824700328971, -8.430740807066124],
              [-36.716802093828065, -8.43077240050088],
              [-36.716752402293359, -8.43084080360552],
              [-36.716642417575514, -8.430958928836363],
              [-36.716491396141464, -8.431109172828886],
              [-36.716315725393223, -8.431267859765534],
              [-36.716135799448494, -8.431440079499543],
              [-36.715963483201151, -8.431614874187947],
              [-36.71580086490652, -8.431792165618988],
              [-36.715635294038066, -8.431962118596248],
              [-36.715470708252028, -8.432118881056546],
              [-36.715305731143054, -8.432264975755661],
              [-36.715134955130523, -8.432440140390668],
              [-36.714939155428389, -8.432625643719875],
              [-36.71475321348565, -8.432801894736368],
              [-36.714600357132909, -8.432955199990969],
              [-36.71446149005191, -8.433091323242627],
              [-36.714342488996273, -8.433214275683607],
              [-36.71426744171815, -8.433318503513954],
              [-36.714240895742705, -8.433417952486881],
              [-36.714249175976043, -8.433522665976778],
              [-36.714285087990781, -8.433667490464815],
              [-36.714315631693964, -8.433829908639519],
              [-36.71433914778688, -8.433998793538009],
              [-36.714363663424109, -8.434167503496386],
              [-36.714387019763961, -8.434332681705268],
              [-36.714410827834818, -8.434498224087793],
              [-36.714428341883092, -8.434668972085529],
              [-36.714451243740825, -8.434849693784553],
              [-36.714473417302742, -8.435030772779037],
              [-36.714493083180237, -8.435221508298701],
              [-36.714519807953458, -8.435415900348499],
              [-36.714544016107958, -8.43560602966067],
              [-36.714610192784484, -8.435991634127603],
              [-36.714633901915867, -8.436189443223194],
              [-36.714660998609368, -8.436413212419517],
              [-36.714687629218865, -8.436639057746239],
              [-36.714714154703564, -8.436867342845529],
              [-36.71473538715032, -8.437115843218411],
              [-36.714753400584485, -8.437371465202991],
              [-36.714775336849442, -8.437623765834227],
              [-36.714800449697307, -8.437860719624609],
              [-36.714826178806334, -8.438100930853834],
              [-36.71484752415936, -8.438361091502145],
              [-36.714872749955745, -8.438609705563071],
              [-36.714916233808502, -8.438840801105595],
              [-36.714964891810396, -8.439056471072888],
              [-36.71503044368248, -8.439255066490944],
              [-36.715087682411294, -8.4394317403932],
              [-36.715133474007771, -8.439579153360397],
              [-36.715176803611079, -8.439728450050575],
              [-36.715215306885057, -8.439880339754833],
              [-36.715255463451946, -8.439998073754792],
              [-36.715289585058649, -8.44010781873247],
              [-36.715328738135945, -8.440257271851056],
              [-36.71538853608844, -8.440446433761196],
              [-36.715449340547707, -8.44063424577622],
              [-36.715505941865921, -8.44082673328529],
              [-36.715560745304657, -8.441016227627406],
              [-36.715622083875289, -8.441205850460706],
              [-36.715678785633315, -8.441396711647958],
              [-36.715731918287354, -8.441561611668552],
              [-36.715778020770784, -8.44170251880154],
              [-36.715823884027635, -8.441822364943718],
              [-36.715878801319818, -8.441930785036455],
              [-36.715909485392935, -8.441976788893502],
              [-36.7159717353574, -8.442057774831545],
              [-36.716041843371279, -8.44213003927581],
              [-36.716102585963363, -8.442189504893316],
              [-36.716137932451531, -8.442229932082219],
              [-36.716162551793552, -8.44227300827129],
              [-36.716176592580581, -8.442324338170822],
              [-36.725575516867146, -8.430747352533322],
              [-36.730026556022551, -8.434817339943097],
              [-36.733579151561877, -8.443249629002432],
              [-36.736700924765387, -8.441656009306101],
              [-36.737262941764115, -8.441700109937837],
              [-36.737616397962569, -8.441923506077508],
              [-36.737747157650631, -8.44213476981882],
              [-36.741296854349585, -8.440225689071349],
              [-36.7400764156382, -8.438561244130627],
              [-36.737457510275121, -8.435130994949926],
              [-36.738739938875263, -8.433938067637808],
              [-36.736767436660045, -8.427799341286777],
              [-36.734594742984108, -8.429938527118994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0067000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO CIPÓ',
        municipio: 'TAMANDARE',
        area_hecta: '444.0000',
        capacidade: 45.0,
        num_famili: 32.0,
        fase: 3.0,
        data_de_cr: '03/10/1995',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/1994',
        area_calc_: 392.4589,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.29897468054687, -8.726955134294219],
              [-35.297947933740957, -8.72687960384164],
              [-35.296991438159928, -8.726990970026401],
              [-35.295115825170598, -8.72709760936228],
              [-35.293048975016212, -8.727255204804912],
              [-35.289398559341052, -8.727583688658084],
              [-35.287828879190002, -8.727711185534462],
              [-35.28759131558369, -8.72766064345808],
              [-35.287199769010876, -8.727701985773926],
              [-35.285132168599617, -8.727918015968626],
              [-35.284620355920104, -8.727779792109281],
              [-35.284407914432329, -8.727833587981927],
              [-35.283969527112816, -8.727868170901679],
              [-35.283483011052915, -8.727862979008917],
              [-35.283340942783823, -8.727856254558763],
              [-35.282785760995786, -8.727924023589752],
              [-35.282203181781199, -8.72787122299337],
              [-35.281782176637648, -8.727900826189833],
              [-35.281544495585713, -8.727965329842212],
              [-35.281119545001118, -8.728151693447474],
              [-35.280796390369716, -8.728165006343],
              [-35.28037301372138, -8.727983473451545],
              [-35.279946152081301, -8.727944892527667],
              [-35.279485184762642, -8.728025084358421],
              [-35.279119674998043, -8.728053268361229],
              [-35.2786039735591, -8.728081271493735],
              [-35.27854040503707, -8.72890459366441],
              [-35.278610726606296, -8.729462904278478],
              [-35.278590238887965, -8.729673635105245],
              [-35.278496250203133, -8.730236722791823],
              [-35.278180934649136, -8.730735088537841],
              [-35.278052253067926, -8.731230708741172],
              [-35.278015904066102, -8.731966159456585],
              [-35.278107963432028, -8.733146958886701],
              [-35.278723099683404, -8.734795638621588],
              [-35.277608761679076, -8.7374176090861],
              [-35.277711567215519, -8.73767903265921],
              [-35.279647976821337, -8.73950384115658],
              [-35.281352339692539, -8.741288885757116],
              [-35.283201375477006, -8.743236550800827],
              [-35.284924340560345, -8.745032286417242],
              [-35.286615310985617, -8.746754572133382],
              [-35.288081534461618, -8.748252439622936],
              [-35.288945918089453, -8.749176261624308],
              [-35.291360629291439, -8.745725904407339],
              [-35.293218645692683, -8.743083437864444],
              [-35.293690254946867, -8.742606313119982],
              [-35.29666293807928, -8.73840001612402],
              [-35.297524810710385, -8.737583771819667],
              [-35.29834217882776, -8.736432127957329],
              [-35.298654323801536, -8.736042591802844],
              [-35.299291014591695, -8.735333826009331],
              [-35.299634432920506, -8.734719960067803],
              [-35.299522550899241, -8.732446757397256],
              [-35.299442827867388, -8.730892068227995],
              [-35.299107421078212, -8.728060411327823],
              [-35.29897468054687, -8.726955134294219],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0349000',
        uf: 'PE',
        nome_proje: 'PA VARAME II',
        municipio: 'PASSIRA',
        area_hecta: '359.6045',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '21/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/12/2004',
        area_calc_: 356.1341,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.527048471335391, -7.992628472004253],
              [-35.525461695359546, -7.992900042760272],
              [-35.523867296695578, -7.993172911941973],
              [-35.522077119805886, -7.993479279699572],
              [-35.519890898868994, -7.993853415159693],
              [-35.519535555844001, -7.993977989174383],
              [-35.519095235282435, -7.994262209470604],
              [-35.518741544187357, -7.99465606689084],
              [-35.518523298880467, -7.995110301403161],
              [-35.518439147610103, -7.99540458989737],
              [-35.518431062591496, -7.995750639303855],
              [-35.518352376548513, -7.99725346385662],
              [-35.518190456319324, -7.999382289232882],
              [-35.518189795374077, -7.999409483498598],
              [-35.517889771108408, -8.000928469176353],
              [-35.517795068780217, -8.001504357084931],
              [-35.517499750724014, -8.002388603897947],
              [-35.517360317146505, -8.002640400156828],
              [-35.517153505606082, -8.003013870525027],
              [-35.516641677493681, -8.00365350667928],
              [-35.516621196031402, -8.0036855470965],
              [-35.516604671634269, -8.003711397227997],
              [-35.516271009446079, -8.004233364858459],
              [-35.51592605594864, -8.005102268955897],
              [-35.515736572053179, -8.0062418058217],
              [-35.520385246943157, -8.007714007244964],
              [-35.524525192825401, -8.009011499769976],
              [-35.527800473638194, -8.01003803226652],
              [-35.530273711306435, -8.010817914534055],
              [-35.530304270185319, -8.01082755049481],
              [-35.530741231643091, -8.010965334823336],
              [-35.530825568081319, -8.010991928033606],
              [-35.535029053416395, -8.012317354593213],
              [-35.534731692872739, -8.011890766799235],
              [-35.53531034633636, -8.011506614880416],
              [-35.535358531489898, -8.01147462604014],
              [-35.535587772381398, -8.011322438991401],
              [-35.534372291149033, -8.005405519411768],
              [-35.539578107349911, -8.005275575579059],
              [-35.539667575537244, -8.000886826868793],
              [-35.53980870981303, -8.000873717852192],
              [-35.538452673929982, -7.997044679038106],
              [-35.535332366821812, -7.997085876808965],
              [-35.535296124788552, -7.997086355186025],
              [-35.532784386726505, -7.997119500635949],
              [-35.530537987801914, -7.997149131709143],
              [-35.528512403753687, -7.997175839674171],
              [-35.52718095895731, -7.997193389792135],
              [-35.527048471335391, -7.992628472004253],
            ],
          ],
          [
            [
              [-35.526970727830523, -7.990970351450567],
              [-35.526398838910616, -7.990985367240748],
              [-35.525755260446878, -7.99100226441038],
              [-35.525787273630804, -7.99221388541351],
              [-35.524389949451724, -7.992626365456949],
              [-35.520681975318134, -7.992697970057897],
              [-35.519998619099859, -7.993605752776813],
              [-35.527004206321152, -7.992406820321424],
              [-35.526970727830523, -7.990970351450567],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0335000',
        uf: 'PE',
        nome_proje: 'PA RECREIO II',
        municipio: 'PASSIRA',
        area_hecta: '496.4245',
        capacidade: 40.0,
        num_famili: 40.0,
        fase: 3.0,
        data_de_cr: '30/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '09/06/2005',
        area_calc_: 497.5828,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.533108254857439, -8.016710839686066],
              [-35.525902047079647, -8.016351799926849],
              [-35.525868786932037, -8.016956718420303],
              [-35.521717796368108, -8.017105284769581],
              [-35.521388869376899, -8.025317446343562],
              [-35.52138428140681, -8.025424392418806],
              [-35.521096717131179, -8.032385505955414],
              [-35.523715866542851, -8.032322676348695],
              [-35.523689916551113, -8.03405749998012],
              [-35.527139156988937, -8.033253286856894],
              [-35.527032766601216, -8.036154345768503],
              [-35.528153108497349, -8.036320914517306],
              [-35.538869318566519, -8.036700229999818],
              [-35.539209347812964, -8.034517966926334],
              [-35.543167060597185, -8.034554030251789],
              [-35.543299306328855, -8.034179993819565],
              [-35.54331655001787, -8.033687442619936],
              [-35.54212385785663, -8.024997507194625],
              [-35.545525077064873, -8.025073269575051],
              [-35.545855439781469, -8.024919243832914],
              [-35.54773446970286, -8.024623700845176],
              [-35.549384091507122, -8.02422233725742],
              [-35.549717713270901, -8.02418757183597],
              [-35.550057859659759, -8.024229353498189],
              [-35.549819389414367, -8.023495522629229],
              [-35.548942034800525, -8.02351672232785],
              [-35.548615172566954, -8.022588814406069],
              [-35.549106632934212, -8.022577478311556],
              [-35.549098868953429, -8.022337123702332],
              [-35.549754209346354, -8.022258515503653],
              [-35.549713882258601, -8.021924777832739],
              [-35.548634267106713, -8.022038657771612],
              [-35.548543683725228, -8.021677327578429],
              [-35.548109047547996, -8.021712642664541],
              [-35.547843176874871, -8.02086798849793],
              [-35.54743623566857, -8.020822611322629],
              [-35.547053635625957, -8.020999936405001],
              [-35.546743434525041, -8.021025158182805],
              [-35.546735516086564, -8.020900458409374],
              [-35.545683705352118, -8.020717240830917],
              [-35.545677505224845, -8.020680622533058],
              [-35.542379278976867, -8.020955443516215],
              [-35.541976296961025, -8.019878593982909],
              [-35.540000699763027, -8.019721726264359],
              [-35.539687499392592, -8.017288707317835],
              [-35.533095828290307, -8.016968828122108],
              [-35.533108254857439, -8.016710839686066],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0345000',
        uf: 'PE',
        nome_proje: 'PA INDEPENDÊNCIA',
        municipio: 'PASSIRA',
        area_hecta: '365.5213',
        capacidade: 29.0,
        num_famili: 28.0,
        fase: 3.0,
        data_de_cr: '16/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/07/2005',
        area_calc_: 362.3814,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.605599325944816, -7.965456941418414],
              [-35.60487519410043, -7.965458763014882],
              [-35.604693800618165, -7.965404075308113],
              [-35.604238023516771, -7.965210799818497],
              [-35.604093865620875, -7.965200054780628],
              [-35.60376965128463, -7.965311385087804],
              [-35.603465785545581, -7.965477484686809],
              [-35.603280860680286, -7.965514847503836],
              [-35.602995454752815, -7.965496901914278],
              [-35.601946559587446, -7.96960651679449],
              [-35.598852919366429, -7.967996791483212],
              [-35.597403728467405, -7.967542171011493],
              [-35.597334776359091, -7.968657546441523],
              [-35.594048611774447, -7.96725554565039],
              [-35.593233728385123, -7.966823698512842],
              [-35.592592740447969, -7.987160671221478],
              [-35.592942388122374, -7.987482229078811],
              [-35.593071791981565, -7.98748216127437],
              [-35.593369538678708, -7.9874033439178],
              [-35.593533223281348, -7.98735404549966],
              [-35.595382438160769, -7.987210571805365],
              [-35.595645534187867, -7.987123689498632],
              [-35.596255261654335, -7.986873644044764],
              [-35.596458828597918, -7.986821824770902],
              [-35.597069525715767, -7.986827137370498],
              [-35.597335372238796, -7.986938005941268],
              [-35.597654691309927, -7.987107785856637],
              [-35.598271232315042, -7.987318091560979],
              [-35.598406896286754, -7.987335838525381],
              [-35.599178841829165, -7.987271201953388],
              [-35.59951975055931, -7.987181214934668],
              [-35.599759601827557, -7.987077813172483],
              [-35.600115398166146, -7.986898232788734],
              [-35.600944007303909, -7.986904623459011],
              [-35.601288313392246, -7.986888853743055],
              [-35.601430404794002, -7.986855835991197],
              [-35.601513233170394, -7.986928639912552],
              [-35.602474162021821, -7.986895599578975],
              [-35.603905086336397, -7.986654767174892],
              [-35.604249401315947, -7.986617966049555],
              [-35.604862600729838, -7.986379313400291],
              [-35.606232047158798, -7.986703219023321],
              [-35.606779003050129, -7.987347953524656],
              [-35.606923585712579, -7.987734838659659],
              [-35.607310326011728, -7.987712937655385],
              [-35.607403268173542, -7.985364758696595],
              [-35.608559773565375, -7.985430004527848],
              [-35.60977297458988, -7.967545100536896],
              [-35.60907321311953, -7.966977014169355],
              [-35.606819287690897, -7.972380726555564],
              [-35.606252624145235, -7.972425698682636],
              [-35.605542960021253, -7.971980608041961],
              [-35.605331244586523, -7.971901891288123],
              [-35.604723644619391, -7.97190128586344],
              [-35.603779214505764, -7.971241176516125],
              [-35.603991824435482, -7.970511043460958],
              [-35.604938073688992, -7.970126850437382],
              [-35.60403929647434, -7.969708843217323],
              [-35.605599325944816, -7.965456941418414],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0346000',
        uf: 'PE',
        nome_proje: 'PA VARAME I',
        municipio: 'PASSIRA',
        area_hecta: '205.7780',
        capacidade: 22.0,
        num_famili: 22.0,
        fase: 3.0,
        data_de_cr: '16/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '16/09/2004',
        area_calc_: 203.9114,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.520747930015368, -7.991835562682303],
              [-35.518709885252555, -7.99213198241743],
              [-35.518811245695105, -7.992544802072044],
              [-35.517330795144545, -7.992626900169887],
              [-35.516394033347261, -7.992653358911154],
              [-35.514762337486019, -7.992712134782527],
              [-35.510193532927254, -7.992876675220741],
              [-35.506679462977907, -7.993003196435153],
              [-35.497407010675381, -7.993336901032018],
              [-35.497884301037217, -7.997553640875499],
              [-35.502402908141228, -7.997587135350857],
              [-35.5048969701581, -7.997605601490324],
              [-35.506766474935723, -7.997670138277445],
              [-35.508748776773544, -7.997738559520941],
              [-35.508801337625812, -7.998433226592441],
              [-35.508858954618077, -7.999194709578512],
              [-35.508911815641262, -7.999893328598156],
              [-35.508960873521396, -8.000541678134283],
              [-35.509011941065054, -8.00121658062633],
              [-35.509066934511424, -8.001943359869554],
              [-35.509131825562761, -8.002800932891326],
              [-35.509199134222577, -8.003690444505645],
              [-35.509224921095459, -8.004031225446063],
              [-35.509261173087282, -8.004573895915909],
              [-35.50932304828158, -8.005500119164777],
              [-35.509389000173954, -8.006487351831442],
              [-35.51331448433664, -8.006124835161954],
              [-35.514099044258202, -8.006116171491923],
              [-35.515434704474586, -8.006125049843384],
              [-35.515513605369854, -8.005682969599313],
              [-35.51558945066175, -8.005274974650595],
              [-35.515602455506233, -8.005205017604043],
              [-35.515803960248306, -8.004565130624728],
              [-35.515828972049896, -8.004507036002037],
              [-35.516011355646668, -8.004083415274868],
              [-35.516105119401118, -8.00391932817106],
              [-35.516140776480242, -8.003856928051423],
              [-35.516582838295513, -8.003283292529982],
              [-35.51665742617719, -8.00318650442574],
              [-35.517000270105896, -8.002721774911089],
              [-35.51706932308597, -8.002628172554292],
              [-35.517262618206118, -8.002317984338861],
              [-35.517325010612979, -8.002147085832171],
              [-35.517343054268764, -8.00209766258765],
              [-35.517398268504969, -8.001804374746348],
              [-35.517446599756902, -8.001493981995246],
              [-35.517528482287993, -8.000992824449735],
              [-35.517643655943864, -8.000440478770535],
              [-35.517744649416073, -7.999956137617563],
              [-35.51775592465399, -7.999902064152418],
              [-35.517855914027116, -7.999440838504797],
              [-35.517860356714316, -7.999420345501035],
              [-35.517881145508902, -7.99935964466626],
              [-35.517905669250283, -7.998911664867418],
              [-35.517929734008234, -7.998456006671834],
              [-35.517953426931584, -7.998007390816985],
              [-35.51798108467942, -7.997553715403996],
              [-35.518009631597167, -7.997110912420582],
              [-35.518038581748961, -7.996662774472828],
              [-35.518068793964979, -7.99617307603048],
              [-35.518094437447182, -7.995757431794186],
              [-35.518126424188338, -7.995294262416647],
              [-35.518156713310745, -7.994854270168293],
              [-35.518177204645006, -7.994556605336522],
              [-35.518231297598952, -7.994408953051136],
              [-35.518246746486184, -7.994391881953725],
              [-35.518310279789482, -7.99432167733635],
              [-35.518464931574819, -7.994222316082535],
              [-35.518871777082374, -7.994005736489639],
              [-35.519655608316121, -7.993607366443237],
              [-35.519820075611364, -7.993483042165101],
              [-35.519990469024449, -7.993338338473427],
              [-35.520080399387957, -7.993232564935474],
              [-35.520290177061888, -7.992902792899273],
              [-35.520449138026024, -7.99267063482289],
              [-35.520633599523087, -7.992425459309835],
              [-35.52074872337699, -7.992290142994517],
              [-35.520747930015368, -7.991835562682303],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0330000',
        uf: 'PE',
        nome_proje: 'PA ISMAEL FELIPE',
        municipio: 'TRACUNHAEM',
        area_hecta: '580.4902',
        capacidade: 53.0,
        num_famili: 49.0,
        fase: 3.0,
        data_de_cr: '23/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/11/2003',
        area_calc_: 580.1762,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.110222490337748, -7.732973478982677],
              [-35.10832018584604, -7.732821017167671],
              [-35.105534186911044, -7.732802682358729],
              [-35.104231104824621, -7.732772209989019],
              [-35.103043804320244, -7.732441052064731],
              [-35.102931486525677, -7.73479302733944],
              [-35.102999459672581, -7.735038176157696],
              [-35.103179279434869, -7.736978243394992],
              [-35.103232621905683, -7.737222658354612],
              [-35.103305464945407, -7.73746682445751],
              [-35.103148164666997, -7.737555285862489],
              [-35.102887403584184, -7.741095374021917],
              [-35.103192050116171, -7.745433621203896],
              [-35.103216906159439, -7.745580792209576],
              [-35.103059219231476, -7.746854689183851],
              [-35.103032707947875, -7.748100700807298],
              [-35.103032971109506, -7.748190238248809],
              [-35.103016147353323, -7.749237339574371],
              [-35.103027508028887, -7.751063239033426],
              [-35.103045004458295, -7.751220167800967],
              [-35.103074834239749, -7.751604047114326],
              [-35.10307796345942, -7.752471910360952],
              [-35.102969483369428, -7.753438237100746],
              [-35.102873991408181, -7.753605391548083],
              [-35.102860856749416, -7.754190707980458],
              [-35.10291684489124, -7.754576645601466],
              [-35.102878717540342, -7.756536290203104],
              [-35.102437071138446, -7.757396779341319],
              [-35.101845138878062, -7.757732403626516],
              [-35.10120329049829, -7.758290001104489],
              [-35.100653416726587, -7.761582078179872],
              [-35.100512341268463, -7.76423980486067],
              [-35.100238244621814, -7.770293224753148],
              [-35.100213576661226, -7.771032202428917],
              [-35.100239086117917, -7.771284705612755],
              [-35.100201772373154, -7.771731513295483],
              [-35.100059619236106, -7.773299195395117],
              [-35.100021024975788, -7.773834775092126],
              [-35.099988676628712, -7.774064373247514],
              [-35.099603308108684, -7.778288534753209],
              [-35.099872990817076, -7.778243040875365],
              [-35.100178090121084, -7.777664803296187],
              [-35.100798381434075, -7.776739180564333],
              [-35.101145873653252, -7.776280121020315],
              [-35.101798404461228, -7.775511828759795],
              [-35.102092149234345, -7.775196984045294],
              [-35.102262941664613, -7.774759473198226],
              [-35.102492317723744, -7.774517852754256],
              [-35.102482295988615, -7.774425552356504],
              [-35.102642970849729, -7.774198780963693],
              [-35.102709397782021, -7.774122246883496],
              [-35.102668171731935, -7.773946573826163],
              [-35.102635131886821, -7.773308875017288],
              [-35.102665015720341, -7.773097929204726],
              [-35.102840549866997, -7.772615386098463],
              [-35.103295811362955, -7.771531815243155],
              [-35.103751335657357, -7.771120166992494],
              [-35.104217663031008, -7.770515683522969],
              [-35.104570444779228, -7.769602585508241],
              [-35.104641866256372, -7.7689513526134],
              [-35.104410443021301, -7.768151461023799],
              [-35.104192980806417, -7.767732434471058],
              [-35.104011635022836, -7.767134576463387],
              [-35.10402702252555, -7.766839171980767],
              [-35.104195033068194, -7.766852125561433],
              [-35.104190078031557, -7.766184493094269],
              [-35.103779649700996, -7.766002611795199],
              [-35.103461632407658, -7.76581705442897],
              [-35.103333504707408, -7.765431339041413],
              [-35.103487099718919, -7.764601887476545],
              [-35.103839068274937, -7.764089987523126],
              [-35.103985279601105, -7.763070033007363],
              [-35.104030701872546, -7.762664945316551],
              [-35.10447946209564, -7.761609585409663],
              [-35.104945760199627, -7.761115587739904],
              [-35.105483518544069, -7.76024041010973],
              [-35.105697447470675, -7.759622813953865],
              [-35.105824517125484, -7.759299131858477],
              [-35.106006979677709, -7.758933213051502],
              [-35.10652560004025, -7.75863487379643],
              [-35.106697677043996, -7.758445886698368],
              [-35.107097282531811, -7.757791835701407],
              [-35.107550353929831, -7.757273043283623],
              [-35.107885620278857, -7.756994423152958],
              [-35.1081262368781, -7.756724498048493],
              [-35.108142149310268, -7.756638460500322],
              [-35.108728975319806, -7.756216516111972],
              [-35.10892240208188, -7.755887216804099],
              [-35.115230893307981, -7.75577220235385],
              [-35.117679922629137, -7.755768643875592],
              [-35.118509431454903, -7.755722353430283],
              [-35.11868998367278, -7.755736080566162],
              [-35.120813454354241, -7.756140240343045],
              [-35.121298357990256, -7.756221285951877],
              [-35.123294885880014, -7.756555591964564],
              [-35.123183996952044, -7.754482850806843],
              [-35.123126518778555, -7.751984979822927],
              [-35.123051421038468, -7.749073632797804],
              [-35.123096655968169, -7.748997998608085],
              [-35.121627699106021, -7.746994079826385],
              [-35.119974853576082, -7.743881259368784],
              [-35.11917031723226, -7.740583415988864],
              [-35.117822046409167, -7.73797602419051],
              [-35.117537602524393, -7.737049984310544],
              [-35.117170220406528, -7.736249717799716],
              [-35.116902502464185, -7.73513506220071],
              [-35.116492939894577, -7.734355333824106],
              [-35.116143816588085, -7.733260769093835],
              [-35.114821415158424, -7.733253115150879],
              [-35.11304028058111, -7.733136902362634],
              [-35.110222490337748, -7.732973478982677],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0332000',
        uf: 'PE',
        nome_proje: 'PA CHICO MENDES I',
        municipio: 'TRACUNHAEM',
        area_hecta: '700.6673',
        capacidade: 59.0,
        num_famili: 58.0,
        fase: 3.0,
        data_de_cr: '23/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/11/2003',
        area_calc_: 700.2887,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.13450802992292, -7.757085144939169],
              [-35.131607704229197, -7.756851280022537],
              [-35.13151912021975, -7.756867217731852],
              [-35.131472869463359, -7.756852470871006],
              [-35.131460985013398, -7.75676375262515],
              [-35.130616103830597, -7.756819356878602],
              [-35.130514273832489, -7.75684336076641],
              [-35.123294885880014, -7.756555591964564],
              [-35.121298357990256, -7.756221285951877],
              [-35.11868998367278, -7.755736080566162],
              [-35.118509431454903, -7.755722353430283],
              [-35.117679922629137, -7.755768643875592],
              [-35.115230893307981, -7.75577220235385],
              [-35.114890690498733, -7.761166931131124],
              [-35.119150440632851, -7.764209332679922],
              [-35.119239933572004, -7.764273249315752],
              [-35.120316509933808, -7.765039196920764],
              [-35.120821043090153, -7.765395987197989],
              [-35.12077210669581, -7.766087212858213],
              [-35.120349962536793, -7.771978820923914],
              [-35.120857163589278, -7.772166418626448],
              [-35.121352890411004, -7.774344113321349],
              [-35.119737321939823, -7.775100106449868],
              [-35.1196475228273, -7.775367812108649],
              [-35.118010607614309, -7.779710128593669],
              [-35.117716783100818, -7.78053567782604],
              [-35.117665251965363, -7.780924902521749],
              [-35.119379420645977, -7.782593330477509],
              [-35.120877626222402, -7.784257024778787],
              [-35.122129330205198, -7.7856871674699],
              [-35.122929005849436, -7.786593860244328],
              [-35.123955833482569, -7.787175061582282],
              [-35.124837627752434, -7.787652606483949],
              [-35.128877587440456, -7.789849579428992],
              [-35.129858675524218, -7.788625090786979],
              [-35.130437041993183, -7.787892896838946],
              [-35.131135101011616, -7.787018775965798],
              [-35.131750362146981, -7.786247218316197],
              [-35.133498571938723, -7.783921870598357],
              [-35.134023522923911, -7.783230766436401],
              [-35.13709894616666, -7.779058938331123],
              [-35.136938824308018, -7.778723193506822],
              [-35.136987820958169, -7.778540465346141],
              [-35.137233309389863, -7.776564955469393],
              [-35.137268526796269, -7.776085492841155],
              [-35.137280686872366, -7.775359996830601],
              [-35.137227160525306, -7.77423490733405],
              [-35.13727544837743, -7.773007816695719],
              [-35.136835180521594, -7.773141467944374],
              [-35.135659273359828, -7.77212145840798],
              [-35.1363839285168, -7.771345564611038],
              [-35.136907435294866, -7.771027838007774],
              [-35.137370877676553, -7.771507096666309],
              [-35.137515380307072, -7.771260954603603],
              [-35.137780151187549, -7.770958803722644],
              [-35.138267533498691, -7.770475604475623],
              [-35.138742073166398, -7.769997477317985],
              [-35.139084005614109, -7.76972715441122],
              [-35.139408657203923, -7.769477185030145],
              [-35.140036273227018, -7.768983393603903],
              [-35.140653427772435, -7.768497834907894],
              [-35.141120295979007, -7.767866357889377],
              [-35.141647679069052, -7.766925604677795],
              [-35.142039611389222, -7.766566332680004],
              [-35.140778485864665, -7.765111086803732],
              [-35.139210862185259, -7.763002164384053],
              [-35.139015035624958, -7.762738716798012],
              [-35.139059869907022, -7.762400212741623],
              [-35.139138400171774, -7.762175817743485],
              [-35.139144068313747, -7.761988399517845],
              [-35.139083328057687, -7.761805835195311],
              [-35.138696400451991, -7.761281861094236],
              [-35.13838254091565, -7.76077939461677],
              [-35.138374819907384, -7.760482962676878],
              [-35.139051841505136, -7.759241581989155],
              [-35.139503054593725, -7.75874058991077],
              [-35.13957894116556, -7.758351713349342],
              [-35.139771833223698, -7.758007572603774],
              [-35.139658226647143, -7.757442981055778],
              [-35.139393396223589, -7.757432770847463],
              [-35.139308359034544, -7.757429492309302],
              [-35.138841693524725, -7.757384833353054],
              [-35.138414418714071, -7.757163135166564],
              [-35.137104607333768, -7.757007276615493],
              [-35.13450802992292, -7.757085144939169],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0353001',
        uf: 'PE',
        nome_proje: 'PA AJUDANTE',
        municipio: 'NAZARE DA MATA',
        area_hecta: '399.1370',
        capacidade: 45.0,
        num_famili: 42.0,
        fase: 3.0,
        data_de_cr: '13/04/2006',
        forma_obte: 'Desapropriação',
        data_obten: '24/05/2004',
        area_calc_: 398.8714,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.205344190951607, -7.674079017029971],
              [-35.204699526648689, -7.674362622183425],
              [-35.203017498040616, -7.675214529102419],
              [-35.202263789824933, -7.675226219970336],
              [-35.201491493867415, -7.675411170659237],
              [-35.200532552715053, -7.675363913400573],
              [-35.20015937506804, -7.675448623163855],
              [-35.19980288973354, -7.67552070189872],
              [-35.199062600936195, -7.67544534158376],
              [-35.198223449467264, -7.677582558169282],
              [-35.19781170826792, -7.678749667761948],
              [-35.197043718532434, -7.680469984127078],
              [-35.196985768214162, -7.680590719324482],
              [-35.196696289271749, -7.680702749731144],
              [-35.196450713933388, -7.681147271142551],
              [-35.19600831693883, -7.681769683377512],
              [-35.196005534282122, -7.68218465316941],
              [-35.195965526545699, -7.682421501905448],
              [-35.195888152058359, -7.682405346722242],
              [-35.195002373319923, -7.682419800418068],
              [-35.194424434478982, -7.684609131859172],
              [-35.194461992434377, -7.684714843722733],
              [-35.194559643967892, -7.685208556431754],
              [-35.194646214420779, -7.68523319776259],
              [-35.194434110946055, -7.685708155305618],
              [-35.194438198991421, -7.686524856807149],
              [-35.194351127302127, -7.687417535032026],
              [-35.194285543810466, -7.68816833236005],
              [-35.194339226122757, -7.689442022450916],
              [-35.195591766200238, -7.690000196400775],
              [-35.196671008033519, -7.690372828038132],
              [-35.197190015247692, -7.690452689243543],
              [-35.197258169691189, -7.690451788766727],
              [-35.197380204367285, -7.690754361079959],
              [-35.197852616737919, -7.691214287877414],
              [-35.198368836836593, -7.691776065325167],
              [-35.198662886181211, -7.692125780595982],
              [-35.198860705929391, -7.692433398886033],
              [-35.199194556952214, -7.693135977530144],
              [-35.199339365127493, -7.693305444176531],
              [-35.200242796588299, -7.692928846172856],
              [-35.201149301823996, -7.692581138308952],
              [-35.201796544676654, -7.69278350293263],
              [-35.202539802892595, -7.692778936053984],
              [-35.204120293327712, -7.69286636020437],
              [-35.204211867874477, -7.692849004331009],
              [-35.20501054099315, -7.692890028070992],
              [-35.205580843988159, -7.693093381659292],
              [-35.205705362581178, -7.693136852422152],
              [-35.20578970446433, -7.693459377655827],
              [-35.206094774990575, -7.693765357607774],
              [-35.206368467339253, -7.693902431088835],
              [-35.2066664865077, -7.694013558625381],
              [-35.207050653109462, -7.694097745307563],
              [-35.207600286376035, -7.694084986142059],
              [-35.208341765887738, -7.694008934857112],
              [-35.20870290560056, -7.694067010935072],
              [-35.208815054795359, -7.694178146338679],
              [-35.209050257114008, -7.694874471337863],
              [-35.209413286016385, -7.695619518053531],
              [-35.209986568778682, -7.695996778621903],
              [-35.210465116471795, -7.696365222085374],
              [-35.211051457363325, -7.696638841174186],
              [-35.211381964485753, -7.696958503514619],
              [-35.211960506308387, -7.697280584347141],
              [-35.212611708501093, -7.697287754301777],
              [-35.213024168150113, -7.697414492833024],
              [-35.213475461843444, -7.697362923306699],
              [-35.214510335382855, -7.697003493964077],
              [-35.214932906806681, -7.696915887617094],
              [-35.214961414007924, -7.695778166486848],
              [-35.215101156486405, -7.69509377182903],
              [-35.215083769174875, -7.694612180207641],
              [-35.215175374477859, -7.694273118753287],
              [-35.215261611112822, -7.694128267924762],
              [-35.215441735547479, -7.693907912742866],
              [-35.215828031183207, -7.69369181602169],
              [-35.216164987407289, -7.693593108382707],
              [-35.216738725007993, -7.693374110707617],
              [-35.216916087585645, -7.693177416480637],
              [-35.216982490145881, -7.693233576696023],
              [-35.217719505905052, -7.694253860691521],
              [-35.217742850598043, -7.692561465063413],
              [-35.21785191416339, -7.691400650077382],
              [-35.217870121437166, -7.690121187525309],
              [-35.218056584989419, -7.68790303857504],
              [-35.217351830128408, -7.685676225234364],
              [-35.217169141217227, -7.685491279521164],
              [-35.217003380477024, -7.685542402207426],
              [-35.216704595683048, -7.685331932773579],
              [-35.216626315617738, -7.684914295847551],
              [-35.216455211068457, -7.684656705697238],
              [-35.216573567568958, -7.684459538957033],
              [-35.21637570596576, -7.684310230893563],
              [-35.216441382016676, -7.684511382851086],
              [-35.216254421188395, -7.684573488683507],
              [-35.215851385912238, -7.684412901095617],
              [-35.215733445412802, -7.684447505969972],
              [-35.215540529671301, -7.684227983100646],
              [-35.215137746788272, -7.684164925556526],
              [-35.215084044051395, -7.684299912764757],
              [-35.214751401978653, -7.684465689140506],
              [-35.214621509172339, -7.684404043461756],
              [-35.214594992989376, -7.6842268144171],
              [-35.213858940723036, -7.683995240589499],
              [-35.213983525346968, -7.683905101673632],
              [-35.213526002297449, -7.683598457852259],
              [-35.213830058051613, -7.683411913312303],
              [-35.213398932982678, -7.682857343158076],
              [-35.213253093040798, -7.682832369505738],
              [-35.213285157081771, -7.682635856052592],
              [-35.213126454445053, -7.682287953027815],
              [-35.212504791429801, -7.682196218314383],
              [-35.212657001178783, -7.681998950216592],
              [-35.212237733976323, -7.681735531111748],
              [-35.212235308322313, -7.681536492574395],
              [-35.212011596187132, -7.681451565088127],
              [-35.211872446575491, -7.681096220677249],
              [-35.211584749363304, -7.681055751528612],
              [-35.211449169828505, -7.680731835661865],
              [-35.211267718466772, -7.680618831366741],
              [-35.210864072433019, -7.680628303285887],
              [-35.210457070111723, -7.680060596742138],
              [-35.210355763026563, -7.680108673067665],
              [-35.210411583618651, -7.680295351749201],
              [-35.210247186503544, -7.680419130267301],
              [-35.209990126370492, -7.680463072598668],
              [-35.209813760610402, -7.680245295585642],
              [-35.209681259776318, -7.680444784552628],
              [-35.209358642259666, -7.680234376314289],
              [-35.208795309345071, -7.680360468296703],
              [-35.208182437895971, -7.679867097828024],
              [-35.207604161776018, -7.679643774402598],
              [-35.207252578528767, -7.679702066891726],
              [-35.207020546392542, -7.679398855745698],
              [-35.20646450032045, -7.678342030856189],
              [-35.20592483723582, -7.678082141389005],
              [-35.205602040672503, -7.677651951430749],
              [-35.204763964084869, -7.676441114042705],
              [-35.204676122883733, -7.676413913802462],
              [-35.204744268045481, -7.676049622032733],
              [-35.204747309482762, -7.675735795123356],
              [-35.204708837862533, -7.675380677709076],
              [-35.20489754406983, -7.674988895398624],
              [-35.205208160956076, -7.674507723083609],
              [-35.205344190951607, -7.674079017029971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0198000',
        uf: 'PE',
        nome_proje: 'PA RIO PONTAL',
        municipio: 'PETROLINA',
        area_hecta: '1044.6081',
        capacidade: 35.0,
        num_famili: 34.0,
        fase: 3.0,
        data_de_cr: '27/11/2006',
        forma_obte: 'Desapropriação',
        data_obten: '27/11/2006',
        area_calc_: 1044.8778,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.335351898165754, -8.962930424357562],
              [-40.31365300689027, -8.975943955024478],
              [-40.313624682026713, -8.976110082714055],
              [-40.313651053449874, -8.976209912527104],
              [-40.315876615593247, -8.981045973967044],
              [-40.315839412463347, -8.981070251009168],
              [-40.331071542396742, -9.01385073161708],
              [-40.331228254199146, -9.013797445029489],
              [-40.333947685975907, -9.008065957780605],
              [-40.337262510072392, -9.003885665691069],
              [-40.339874626574847, -8.991769723017152],
              [-40.343930817911435, -8.986089496141958],
              [-40.342141565305518, -8.966482632487523],
              [-40.342141534628112, -8.966482622282948],
              [-40.342111019992231, -8.966147879432825],
              [-40.336071312030654, -8.964104925850357],
              [-40.335351898165754, -8.962930424357562],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0057000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DO NAVIO',
        municipio: 'FLORESTA',
        area_hecta: '214.9185',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '24/10/2001',
        forma_obte: 'Confisco',
        data_obten: '17/03/1999',
        area_calc_: 225.0857,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.433706980733888, -8.627320480372386],
              [-38.417847067607546, -8.698545310190577],
              [-38.416861221315848, -8.702960025919204],
              [-38.418503648512058, -8.70326147041513],
              [-38.434757496477324, -8.630454904688401],
              [-38.435360547464796, -8.627686973170935],
              [-38.439792093431137, -8.607576626780396],
              [-38.440670489491112, -8.60358973661199],
              [-38.442392636291316, -8.595773818317056],
              [-38.440758062355044, -8.595619041811293],
              [-38.438197339393966, -8.607132729024814],
              [-38.43731411393447, -8.611103775053481],
              [-38.433706980733888, -8.627320480372386],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0231000',
        uf: 'PE',
        nome_proje: 'PA ABEL MOREIRA',
        municipio: 'PARNAMIRIM',
        area_hecta: '513.6525',
        capacidade: 30.0,
        num_famili: 26.0,
        fase: 3.0,
        data_de_cr: '14/05/2007',
        forma_obte: 'Doação',
        data_obten: '22/11/2004',
        area_calc_: 494.2443,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.884551008621841, -8.199944602894803],
              [-39.886152118006976, -8.203976090553171],
              [-39.886239573684243, -8.204196402064399],
              [-39.872555219128223, -8.207482460256891],
              [-39.872466844182583, -8.207503634880995],
              [-39.869041811176672, -8.208326037780095],
              [-39.868934572550828, -8.208606738900571],
              [-39.874966079188937, -8.215019150572388],
              [-39.885363694768294, -8.218116386234481],
              [-39.888378183485912, -8.227383821922531],
              [-39.889926915078938, -8.227026577031763],
              [-39.889761974167428, -8.226952506509198],
              [-39.889204064896951, -8.226571432842436],
              [-39.888930373138273, -8.226338782168773],
              [-39.888962855126941, -8.226085737357034],
              [-39.889110954337596, -8.22605773380063],
              [-39.889198966595096, -8.22583188035235],
              [-39.889225211743053, -8.225550449793001],
              [-39.889116597816276, -8.225290391083988],
              [-39.889238492389232, -8.224951135870189],
              [-39.889441504216094, -8.224714174668414],
              [-39.889316630764249, -8.224327801147766],
              [-39.889277243527083, -8.224170876802534],
              [-39.889251259817293, -8.223877080428993],
              [-39.889169970400353, -8.223575266363108],
              [-39.889168204378606, -8.223393838798804],
              [-39.889521312070102, -8.2234144930786],
              [-39.88987714986979, -8.223560225586267],
              [-39.890294313908676, -8.223726714607945],
              [-39.890875682294883, -8.223800405205189],
              [-39.89130190669534, -8.223674014228171],
              [-39.891985916491272, -8.223569119425463],
              [-39.892623397221747, -8.223483862769875],
              [-39.893318424596906, -8.223393955063466],
              [-39.893752097897313, -8.223270258391114],
              [-39.894106987136276, -8.223113724312793],
              [-39.894343184657266, -8.222900564510944],
              [-39.894625302629237, -8.222720676368455],
              [-39.895007461766149, -8.222539480065787],
              [-39.895319072653415, -8.222394979997512],
              [-39.895565707511047, -8.222342877562753],
              [-39.895947153835984, -8.222289389309481],
              [-39.896295124753024, -8.222165790590507],
              [-39.89660970413744, -8.221888239517726],
              [-39.896748958632074, -8.221520815886851],
              [-39.896757521455264, -8.221250911208836],
              [-39.896758029582841, -8.22123463011418],
              [-39.896995495704061, -8.220900361351804],
              [-39.897013217930635, -8.220868575902255],
              [-39.897324967516766, -8.220584790072401],
              [-39.897687701828474, -8.220325671971283],
              [-39.898077703144466, -8.220121301897835],
              [-39.898391786246876, -8.219947218974148],
              [-39.898778004605091, -8.219716175707735],
              [-39.899194923200533, -8.219572794344691],
              [-39.899483691710635, -8.219405369671396],
              [-39.899812433397543, -8.219170112721816],
              [-39.900091366183297, -8.218989143043011],
              [-39.900297926598782, -8.218760037987328],
              [-39.90036269462243, -8.218455458004671],
              [-39.900383234401566, -8.218183717787047],
              [-39.90039288347468, -8.217953696697885],
              [-39.900225589559582, -8.217881806172292],
              [-39.900056517427494, -8.21766538976833],
              [-39.899939936677974, -8.217416929075414],
              [-39.899931746268912, -8.217408083865786],
              [-39.899668774158449, -8.217178039302278],
              [-39.899369304679965, -8.217067643326455],
              [-39.899048526900287, -8.216968600244597],
              [-39.89867337764543, -8.216872843873123],
              [-39.898300307026894, -8.216732493494337],
              [-39.898276373254198, -8.216706770322736],
              [-39.897803839951884, -8.216703212253762],
              [-39.897248994036808, -8.216671528131142],
              [-39.897207283820535, -8.216653351397621],
              [-39.896737126256461, -8.216575803372423],
              [-39.896147903644383, -8.216476541998439],
              [-39.895583177977407, -8.2164112322604],
              [-39.895574534171395, -8.216402568846878],
              [-39.895136337143057, -8.21636754701564],
              [-39.894604504600188, -8.216153291373868],
              [-39.894196065101482, -8.21606900061847],
              [-39.893832169646323, -8.216011653347456],
              [-39.893585092180743, -8.216026673155511],
              [-39.893402309014554, -8.215807118366026],
              [-39.893029883908945, -8.215629588733457],
              [-39.892683540897266, -8.215504820369748],
              [-39.892285867794179, -8.215403681027162],
              [-39.891926296156811, -8.215371556331286],
              [-39.89164992640054, -8.21535950652102],
              [-39.891377698304559, -8.215087962403446],
              [-39.891051039558789, -8.214874422980163],
              [-39.890748848822753, -8.214844883462447],
              [-39.890425393897807, -8.214928084492026],
              [-39.890188287016279, -8.215058215838988],
              [-39.889916921146082, -8.215254718576016],
              [-39.889670275832557, -8.215219176556827],
              [-39.889402353032544, -8.214923563213647],
              [-39.889182945396826, -8.214644484245945],
              [-39.889056978333592, -8.214295013850176],
              [-39.888912147931251, -8.21399496771299],
              [-39.888740409011184, -8.213719039132961],
              [-39.88849629554354, -8.213556054446006],
              [-39.888224701753359, -8.213527530704884],
              [-39.887832367861549, -8.213621825360475],
              [-39.887635051950021, -8.213723920107642],
              [-39.88757642085708, -8.213812142318094],
              [-39.887366547044891, -8.213696474315523],
              [-39.887151436071697, -8.21371612269316],
              [-39.8868369012351, -8.213687240430723],
              [-39.886600120316196, -8.213760027181822],
              [-39.886369112234149, -8.213816068856104],
              [-39.886186863002528, -8.213998806269949],
              [-39.886022662017837, -8.214173725660322],
              [-39.885891675839048, -8.214384387923635],
              [-39.885805340397013, -8.214588620774741],
              [-39.8857106717468, -8.21480137373789],
              [-39.885679318005913, -8.214990924007818],
              [-39.885655613487444, -8.21523201082092],
              [-39.885563043816973, -8.21540885267239],
              [-39.885426783400312, -8.215574846849682],
              [-39.885264783778169, -8.215473634030779],
              [-39.884941102051862, -8.215168552921591],
              [-39.884790254673867, -8.21489691784026],
              [-39.884790923641859, -8.214871049230394],
              [-39.884646573969469, -8.21458185354288],
              [-39.884605878672723, -8.214283747307711],
              [-39.884587665874896, -8.214053334619274],
              [-39.884586822216001, -8.213795298167058],
              [-39.884572279011813, -8.213582152314743],
              [-39.88435146413245, -8.213444799983177],
              [-39.884036978038154, -8.213396831577692],
              [-39.883858811788336, -8.213250702604251],
              [-39.88385915571007, -8.213242019170224],
              [-39.883581607186045, -8.212942714242363],
              [-39.88358229484254, -8.21292525692915],
              [-39.88363327444214, -8.212742899206475],
              [-39.883642768546736, -8.212725693897383],
              [-39.883844509251041, -8.212530523865837],
              [-39.883854781169497, -8.212495589708675],
              [-39.883957351359108, -8.212366209660619],
              [-39.883813296601787, -8.212209604585825],
              [-39.883568552151971, -8.212129555660729],
              [-39.883251657608973, -8.21210040428169],
              [-39.882874349012319, -8.212051396876504],
              [-39.882611979841847, -8.21200647849483],
              [-39.88260317340621, -8.212006226466414],
              [-39.882165711109501, -8.211972997160615],
              [-39.881868991995816, -8.21195365892671],
              [-39.881434032923927, -8.211902877071161],
              [-39.881190879704739, -8.211885501282397],
              [-39.880978316651778, -8.211948915924047],
              [-39.880636993652971, -8.212122050147133],
              [-39.880327460178357, -8.212426078465858],
              [-39.8801693524128, -8.212689346790226],
              [-39.880019396364098, -8.212985428663755],
              [-39.87994054043471, -8.213248252341911],
              [-39.879836006192662, -8.213517101485703],
              [-39.879644113014606, -8.213856688368892],
              [-39.879542909309521, -8.214071534719585],
              [-39.879363488114954, -8.214097160799374],
              [-39.879143644675196, -8.214070688511523],
              [-39.87887618623985, -8.213941303571593],
              [-39.878859969683063, -8.213915290909286],
              [-39.878799889550912, -8.213634591325505],
              [-39.878714945818174, -8.213317496784095],
              [-39.878715199467685, -8.213308994429333],
              [-39.878484662021854, -8.213001623841427],
              [-39.878485078350778, -8.212984528884791],
              [-39.878406903626292, -8.212690482856484],
              [-39.878225081422116, -8.212408239830181],
              [-39.878190944402085, -8.212406957624955],
              [-39.878053549154309, -8.212470749492716],
              [-39.877813166708393, -8.212599072079836],
              [-39.877682027955323, -8.212824836682607],
              [-39.877551670933393, -8.213117257310326],
              [-39.877300955745405, -8.213377380133675],
              [-39.877045760848382, -8.213375222873577],
              [-39.876691819019698, -8.213301557841149],
              [-39.87643398664764, -8.213173054621766],
              [-39.876351028464107, -8.212891138055248],
              [-39.876140996240437, -8.212577571026372],
              [-39.875947916001302, -8.212211328115577],
              [-39.875819767633828, -8.21189658779028],
              [-39.875814097137088, -8.211670307319157],
              [-39.875886921644799, -8.211388142121894],
              [-39.876025987695989, -8.211299655937545],
              [-39.876271529901324, -8.211288810943515],
              [-39.876518038874863, -8.211346249484503],
              [-39.876703562048355, -8.211454107940028],
              [-39.876979780126128, -8.211480730279343],
              [-39.877216863225335, -8.211421062967258],
              [-39.877463569507619, -8.211361555440917],
              [-39.877671083634034, -8.211189800852448],
              [-39.87786785197239, -8.2110855413642],
              [-39.877989946455131, -8.211123442828942],
              [-39.878182277044942, -8.211189319382697],
              [-39.878352374139681, -8.211134952874891],
              [-39.878398973886405, -8.210941389985791],
              [-39.878540819690606, -8.210672369304056],
              [-39.87871413825404, -8.210473012780248],
              [-39.878966930538013, -8.210250419339745],
              [-39.879062097606834, -8.210056569509426],
              [-39.878914225828403, -8.209855562361579],
              [-39.878787003162159, -8.209590837010772],
              [-39.878741577266254, -8.209244532896793],
              [-39.878691496593731, -8.2090107521405],
              [-39.87868408606154, -8.208984720230893],
              [-39.878577548019614, -8.208672285267079],
              [-39.878578327683101, -8.208655279959821],
              [-39.878545773532345, -8.208423993383061],
              [-39.878677346148699, -8.208148573554828],
              [-39.878770576421317, -8.207982313673604],
              [-39.879006332103629, -8.207898047474394],
              [-39.879339513824362, -8.207986950971213],
              [-39.879412843372741, -8.20810084164901],
              [-39.879564017675818, -8.208235183720523],
              [-39.879707021982568, -8.208369634012362],
              [-39.880015666435192, -8.20852886599986],
              [-39.880267585858661, -8.208694100605804],
              [-39.880554731099139, -8.208819191031038],
              [-39.880790306883632, -8.208776890495734],
              [-39.880798931888002, -8.208777233412777],
              [-39.881035048399241, -8.208691970344352],
              [-39.881287851568857, -8.208598982841394],
              [-39.881552441226503, -8.208458033732118],
              [-39.881834738992282, -8.208237454414753],
              [-39.882070110063303, -8.207896867215252],
              [-39.882284944254899, -8.207752951528306],
              [-39.882525192725211, -8.207771599504232],
              [-39.882637239319493, -8.207946273910201],
              [-39.88265714617291, -8.208245782880033],
              [-39.882657868711519, -8.208408581615771],
              [-39.882690411425315, -8.20863362696026],
              [-39.882616736978051, -8.208898519817755],
              [-39.882589032036577, -8.208932135483979],
              [-39.882573106683637, -8.209079142890339],
              [-39.882563956156872, -8.209087483847297],
              [-39.882512167565245, -8.209314703773783],
              [-39.88270956927073, -8.20962169241542],
              [-39.882879173274759, -8.209714659475122],
              [-39.88325140342473, -8.209808176992308],
              [-39.883532128190417, -8.20982067566904],
              [-39.883903560097892, -8.209922605654347],
              [-39.884306327407756, -8.209949487915397],
              [-39.884647377652328, -8.209982926912726],
              [-39.885107965416324, -8.210047396719499],
              [-39.885495953265874, -8.210123331196705],
              [-39.885704755518887, -8.210248137702338],
              [-39.886178157749605, -8.210400761466021],
              [-39.886307705363407, -8.210401652135694],
              [-39.886364472194899, -8.210414370312392],
              [-39.886542563948822, -8.21048624344553],
              [-39.886577276040562, -8.21046039033463],
              [-39.886671923689462, -8.210443359262017],
              [-39.886757263271953, -8.210404551536913],
              [-39.886839864870041, -8.210400118740608],
              [-39.886907527018849, -8.210331684116282],
              [-39.887014920590836, -8.210290204877467],
              [-39.887110331005246, -8.210248752010394],
              [-39.887358491554373, -8.210232196579017],
              [-39.887487372909106, -8.210137488506323],
              [-39.887537212439739, -8.210096769085467],
              [-39.887613047130714, -8.210070282594417],
              [-39.887876291417541, -8.209978353349481],
              [-39.88802731718053, -8.209921763875194],
              [-39.888057538281657, -8.209917627231205],
              [-39.888160948125915, -8.209921921404188],
              [-39.888255916925189, -8.209926957724548],
              [-39.888348441264974, -8.209934984082425],
              [-39.888481466975563, -8.209989590414969],
              [-39.888502244414688, -8.209984389238869],
              [-39.888605888008655, -8.209971317440443],
              [-39.888713416483832, -8.209949825706925],
              [-39.888802609154439, -8.209929278858818],
              [-39.888899696357996, -8.209907177010614],
              [-39.888998702022064, -8.209890497572985],
              [-39.889196062401766, -8.209809385389139],
              [-39.889248673018685, -8.209790185441172],
              [-39.889320835243858, -8.209704465634818],
              [-39.889404656057359, -8.209636085406862],
              [-39.889362331995628, -8.209545553528562],
              [-39.889322873914331, -8.209437469089542],
              [-39.889331596648169, -8.209318515363295],
              [-39.889283614241947, -8.209214519739822],
              [-39.889240624415457, -8.209110332195456],
              [-39.88921131679065, -8.208994537544633],
              [-39.889179648076954, -8.208878386324015],
              [-39.889148606805307, -8.208758615934507],
              [-39.889127274283908, -8.208636653440687],
              [-39.889151645186331, -8.208532859836756],
              [-39.889243872364467, -8.208489061998407],
              [-39.88935408740636, -8.208451193758361],
              [-39.889548833111057, -8.208337708134602],
              [-39.889749144303742, -8.208236781913019],
              [-39.889753942401086, -8.2082308019802],
              [-39.889826795888503, -8.208170314593],
              [-39.889871387350311, -8.208096775187126],
              [-39.890105787642682, -8.208057456610485],
              [-39.89012066400479, -8.208052177958718],
              [-39.890206769371225, -8.207990756782705],
              [-39.890373668326383, -8.207928614344697],
              [-39.890496984572401, -8.207822250418706],
              [-39.890668717898421, -8.207648217416077],
              [-39.890662880980386, -8.207554620330637],
              [-39.890626455642803, -8.207463171096233],
              [-39.89058737029081, -8.207359427293511],
              [-39.890599466654919, -8.207247249446544],
              [-39.890662986564536, -8.207194470348965],
              [-39.890732897274653, -8.207117166670024],
              [-39.890963629773587, -8.20706175666224],
              [-39.891069140366341, -8.207031405300508],
              [-39.891159289892194, -8.206992495673108],
              [-39.891342778297819, -8.206920095971839],
              [-39.891399803136594, -8.206926572239706],
              [-39.891501887425562, -8.206866019916907],
              [-39.891582237417623, -8.206829302554437],
              [-39.891663525072694, -8.206765268888461],
              [-39.891746188525204, -8.206707563272282],
              [-39.891794492395668, -8.20662994548632],
              [-39.89183634034255, -8.206588338863959],
              [-39.891913107649685, -8.206532726493061],
              [-39.891998041936645, -8.206475648916919],
              [-39.89206746284249, -8.206382427912503],
              [-39.892090135501682, -8.206290938413458],
              [-39.89216132819476, -8.206218787021225],
              [-39.89219482163422, -8.20613532310942],
              [-39.892162831831982, -8.205997556672033],
              [-39.892173084936701, -8.205954572874415],
              [-39.892163066803661, -8.205858723985788],
              [-39.892091343388685, -8.205815017259058],
              [-39.892008719307675, -8.205768169096675],
              [-39.891950094701329, -8.205695581573465],
              [-39.891888803792504, -8.205648595710901],
              [-39.891796032182874, -8.205569933525402],
              [-39.891723189816382, -8.205553633858694],
              [-39.891637035283161, -8.205511225199281],
              [-39.891541172334925, -8.205471099111694],
              [-39.891472494851214, -8.20540911574575],
              [-39.891386005874942, -8.20537955087511],
              [-39.891319009031434, -8.205338456424007],
              [-39.891237250202693, -8.205272431948737],
              [-39.891138286917155, -8.205226071946072],
              [-39.890922916174475, -8.205045387989577],
              [-39.890903150224759, -8.205015494581925],
              [-39.890853230937111, -8.204938455838978],
              [-39.890787325804041, -8.204776072870457],
              [-39.890783097353491, -8.204752476217648],
              [-39.890714003066442, -8.20470704495024],
              [-39.890598221560239, -8.204690659080416],
              [-39.890466887261077, -8.204620312206337],
              [-39.89027233293654, -8.204574163186116],
              [-39.890179096904646, -8.204531317597231],
              [-39.88992024435241, -8.204391971740138],
              [-39.889849279046729, -8.204362372220876],
              [-39.889765326792862, -8.204330359362169],
              [-39.889582720469512, -8.20422756536205],
              [-39.889566901036304, -8.204216927834649],
              [-39.889481808436742, -8.204243615895084],
              [-39.889390056716273, -8.204255847518619],
              [-39.88929840099587, -8.204270430466531],
              [-39.889203862328877, -8.204254087741777],
              [-39.889110772440226, -8.204195232888372],
              [-39.889038452753915, -8.204168892205843],
              [-39.88902116924676, -8.204071340730072],
              [-39.888944999449777, -8.20402818584556],
              [-39.888858619175807, -8.204006488810663],
              [-39.888702916856957, -8.203795825187118],
              [-39.888703551310932, -8.203795371565526],
              [-39.889097045501082, -8.203328895404923],
              [-39.889242661011259, -8.203085730691438],
              [-39.889244877026471, -8.202775772534673],
              [-39.889461667845019, -8.202697783253459],
              [-39.889522600296011, -8.202951345561543],
              [-39.88973423683283, -8.203127335600163],
              [-39.890013056032601, -8.203183247784029],
              [-39.890262054903829, -8.203137566059693],
              [-39.890502858906643, -8.20287691581221],
              [-39.89077859276474, -8.202607234254787],
              [-39.890853931211247, -8.202358616832587],
              [-39.89063003455292, -8.201976531547505],
              [-39.890432590708841, -8.201773558145131],
              [-39.890000109358994, -8.201362448201419],
              [-39.890030219578222, -8.200696529463437],
              [-39.889990864559017, -8.200185333680269],
              [-39.890170666790631, -8.199926175077978],
              [-39.890206945202763, -8.199870381180133],
              [-39.89054326448889, -8.199699060206106],
              [-39.890995502122614, -8.19981030268054],
              [-39.891270893532308, -8.199958565590597],
              [-39.891602982462373, -8.200007485353867],
              [-39.891875652076124, -8.199830425177662],
              [-39.891910315315791, -8.199498055623231],
              [-39.891591851722751, -8.199085971474373],
              [-39.891106564350757, -8.19868619434996],
              [-39.890632968232765, -8.198277346263684],
              [-39.890372996785487, -8.197755512990968],
              [-39.890123507541681, -8.197375021076272],
              [-39.889591928394864, -8.197384605278419],
              [-39.889354230049456, -8.197489412025831],
              [-39.889191040826482, -8.197748624051389],
              [-39.888867200200451, -8.197979338348924],
              [-39.888580562029006, -8.198079911222196],
              [-39.888310338941025, -8.198050659843028],
              [-39.888020673962579, -8.197441611132412],
              [-39.887759548837558, -8.197216526851248],
              [-39.887758956034844, -8.197194821464027],
              [-39.887747270747859, -8.196755829331318],
              [-39.887680470980612, -8.196311441362541],
              [-39.887327629741435, -8.196195363327776],
              [-39.887050985601249, -8.195971578809829],
              [-39.886819293031792, -8.195836964046752],
              [-39.886401588259254, -8.195986120808666],
              [-39.886119381846243, -8.196202089420215],
              [-39.885733413662109, -8.196581538232463],
              [-39.885665819083286, -8.19659760491048],
              [-39.8853626669476, -8.196660677231566],
              [-39.885312643921779, -8.196658887694301],
              [-39.885169417610641, -8.196340565662053],
              [-39.885431824397386, -8.195996661991904],
              [-39.885484853965899, -8.195921295671354],
              [-39.885731445586067, -8.195691838996469],
              [-39.885732131598836, -8.195673658124621],
              [-39.885817222231566, -8.195236352512062],
              [-39.885818686230508, -8.195200533233924],
              [-39.885525995560414, -8.194698303768281],
              [-39.884956966297842, -8.194560991180188],
              [-39.884627273369851, -8.194526079036933],
              [-39.884175933945301, -8.194448381577192],
              [-39.88416545971338, -8.194062567663572],
              [-39.884836163705089, -8.193831910625745],
              [-39.884103978126078, -8.193557840345871],
              [-39.88346992171612, -8.193488320518297],
              [-39.882957875031117, -8.193461765260093],
              [-39.882615002521604, -8.193254040398017],
              [-39.88256512016369, -8.192862704883145],
              [-39.882695193899231, -8.192528136803759],
              [-39.883084086203588, -8.192281095548068],
              [-39.883155581454005, -8.192264025936705],
              [-39.883191260133323, -8.192265394969807],
              [-39.883620220276264, -8.19228136910896],
              [-39.883638104804469, -8.192281963061573],
              [-39.884848291043475, -8.192582391377977],
              [-39.885328631556831, -8.192639403298031],
              [-39.885720914894016, -8.192654098947198],
              [-39.885931207912215, -8.192759547965039],
              [-39.885930434122393, -8.192779085700232],
              [-39.885879132837786, -8.19318420930337],
              [-39.885895378041248, -8.193223426599593],
              [-39.886173618198391, -8.193349166749462],
              [-39.886597170272907, -8.193382153049415],
              [-39.887005827697529, -8.193370944238019],
              [-39.887384905990309, -8.193571169039259],
              [-39.88779306213597, -8.193579496541073],
              [-39.888152793863512, -8.193528960369632],
              [-39.888506260489102, -8.19380521383531],
              [-39.88888667318588, -8.19415629564903],
              [-39.889345892802133, -8.194185220807348],
              [-39.889807793559079, -8.194195146174618],
              [-39.890310352099682, -8.193996054491192],
              [-39.890755160820468, -8.193994530058442],
              [-39.89082597433439, -8.193997539273383],
              [-39.891031719966946, -8.19389434001959],
              [-39.8909743611745, -8.193573660081396],
              [-39.891372766192035, -8.193361140061654],
              [-39.891411033583715, -8.193342785739022],
              [-39.891817224012755, -8.193120841902351],
              [-39.891876055917869, -8.193083629639554],
              [-39.892251333180731, -8.192840047069508],
              [-39.892428422238453, -8.192668896326467],
              [-39.892456631786871, -8.19249590413356],
              [-39.892193526889344, -8.191951919778516],
              [-39.891821075197043, -8.191710984913568],
              [-39.89143300286942, -8.1914259472466],
              [-39.891315097303149, -8.191187524635811],
              [-39.891801992155344, -8.190927235215687],
              [-39.892140127556551, -8.190800498809375],
              [-39.892050216820614, -8.190251880438185],
              [-39.891447261959904, -8.190245886480023],
              [-39.891101475729144, -8.190238600422127],
              [-39.89061804268308, -8.190218412893586],
              [-39.890241918017914, -8.190120929398798],
              [-39.88982477610169, -8.189907043394332],
              [-39.889358119603848, -8.189674453534197],
              [-39.889342649446704, -8.189657303152851],
              [-39.888917958213042, -8.189395406676695],
              [-39.889011739525067, -8.18915380364497],
              [-39.889047218164784, -8.189106061234376],
              [-39.889344935855128, -8.188805943588463],
              [-39.889357173621221, -8.188511881398489],
              [-39.889112603685369, -8.188340394520038],
              [-39.888794444406891, -8.188346340818574],
              [-39.888591088967289, -8.18842122431003],
              [-39.888208507240286, -8.188440335955352],
              [-39.887878053714878, -8.188428581448907],
              [-39.887431470338093, -8.188445933768572],
              [-39.887398515917518, -8.188444830422208],
              [-39.886839089608635, -8.18845890275804],
              [-39.886216542805563, -8.188500246265971],
              [-39.885902820665784, -8.188213953954412],
              [-39.885946188048202, -8.187956724704669],
              [-39.885962438202078, -8.187957231715711],
              [-39.886281404551298, -8.187741544817998],
              [-39.886330936770229, -8.187727145841151],
              [-39.886972019219968, -8.187653111060914],
              [-39.887969341439735, -8.187470934299663],
              [-39.888653387862959, -8.187409374234292],
              [-39.889208123697621, -8.187327747786629],
              [-39.88972296694859, -8.187063872292445],
              [-39.889935938362584, -8.186823001390193],
              [-39.88995076632596, -8.186510030587803],
              [-39.889950506264157, -8.18614752942047],
              [-39.889581590758532, -8.186106195055721],
              [-39.889042316603671, -8.186159478648872],
              [-39.888421048428043, -8.186243421747502],
              [-39.887920981759706, -8.186252390216092],
              [-39.887887937548484, -8.186251648866628],
              [-39.887393424530771, -8.186268020931555],
              [-39.886754222005827, -8.186246994720799],
              [-39.88679743881243, -8.186004056015387],
              [-39.886814560631286, -8.18598828105444],
              [-39.88712151890082, -8.185720885731277],
              [-39.887187154568139, -8.185722912224692],
              [-39.888790960894866, -8.185260289266399],
              [-39.889537116469292, -8.185208811662189],
              [-39.889732211790296, -8.184971326754836],
              [-39.88921339411349, -8.18491548920373],
              [-39.888851852635881, -8.184719387134528],
              [-39.889217660607038, -8.1843424230103],
              [-39.889434980788288, -8.183893520380044],
              [-39.889470277625634, -8.183845868819075],
              [-39.889730881610838, -8.183594492145467],
              [-39.889966391415655, -8.183161557942816],
              [-39.890035935539146, -8.182879760381342],
              [-39.885445888670688, -8.182394390390622],
              [-39.876331417724813, -8.192789734877554],
              [-39.884551008621841, -8.199944602894803],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0160000',
        uf: 'PE',
        nome_proje: 'PA ANGICOS',
        municipio: 'ITACURUBA',
        area_hecta: '1413.0000',
        capacidade: 51.0,
        num_famili: 48.0,
        fase: 3.0,
        data_de_cr: '20/12/2005',
        forma_obte: 'Doação',
        data_obten: '20/12/2005',
        area_calc_: 1414.0975,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.686651103304712, -8.66894078266369],
              [-38.68246022653539, -8.675457248017562],
              [-38.680077110179553, -8.677282378009549],
              [-38.678539760854946, -8.678619767748787],
              [-38.677912108990839, -8.679134811729478],
              [-38.677075306777553, -8.679740129084658],
              [-38.67642945544975, -8.680273245339217],
              [-38.675502788631817, -8.679666433863181],
              [-38.674664968845853, -8.681447613119119],
              [-38.674719384812121, -8.681592381762806],
              [-38.674719361443969, -8.68161951716308],
              [-38.674300740919783, -8.682170913502638],
              [-38.6742459193729, -8.682496491706617],
              [-38.674272885504337, -8.682849275162878],
              [-38.674371972243719, -8.683898595882996],
              [-38.674544147324745, -8.684522857416667],
              [-38.674652987795753, -8.684803349582007],
              [-38.673197509992399, -8.68601415342134],
              [-38.672187829361604, -8.686782123235924],
              [-38.671879288451443, -8.686175833761107],
              [-38.66956074668353, -8.686788900549617],
              [-38.668853430937453, -8.684825493008972],
              [-38.668989854931318, -8.68474420524702],
              [-38.669063527586324, -8.683658853114858],
              [-38.669785413208693, -8.679353994146474],
              [-38.668631033180588, -8.679289677466363],
              [-38.663528499863794, -8.682677136254382],
              [-38.663273702353024, -8.682984445811817],
              [-38.66299186810496, -8.683029422547804],
              [-38.660598148756954, -8.686383041877935],
              [-38.659656784725307, -8.686665224696085],
              [-38.658299931708086, -8.684608144917153],
              [-38.65252594204172, -8.686493357407009],
              [-38.654595370622225, -8.689923337884881],
              [-38.654513508374514, -8.689977534396574],
              [-38.652221847960213, -8.690961367784265],
              [-38.649352152080439, -8.687901489684801],
              [-38.646103929052288, -8.69110950236078],
              [-38.644128870946297, -8.688553373117577],
              [-38.644121117969128, -8.688562381914872],
              [-38.644113394663627, -8.688571415911182],
              [-38.644105701112508, -8.68858047500972],
              [-38.644098037398201, -8.688589559113471],
              [-38.644090403602739, -8.688598668125136],
              [-38.644082799807947, -8.688607801947144],
              [-38.644075226095246, -8.688616960481689],
              [-38.644067682545739, -8.688626143630639],
              [-38.644060169240248, -8.68863535129568],
              [-38.644052686259222, -8.688644583378148],
              [-38.644045233682832, -8.688653839779219],
              [-38.644037811590906, -8.688663120399687],
              [-38.644030420062933, -8.688672425140179],
              [-38.644023059178068, -8.688681753901063],
              [-38.644015729015166, -8.688691106582372],
              [-38.644008429652743, -8.688700483083972],
              [-38.644001161168973, -8.688709883305402],
              [-38.643993923641723, -8.688719307146011],
              [-38.643986717148501, -8.688728754504867],
              [-38.643979541766484, -8.688738225280755],
              [-38.643972397572547, -8.688747719372259],
              [-38.643965284643215, -8.688757236677677],
              [-38.643958203054652, -8.688766777095081],
              [-38.643951152882735, -8.688776340522313],
              [-38.643944134202954, -8.688785926856861],
              [-38.64393714709049, -8.688795535996107],
              [-38.643930191620186, -8.688805167837137],
              [-38.643923267866555, -8.688814822276775],
              [-38.643916375903729, -8.688824499211597],
              [-38.643909515805547, -8.688834198538006],
              [-38.643902687645479, -8.688843920152079],
              [-38.643895891496683, -8.688853663949711],
              [-38.643889127431933, -8.688863429826501],
              [-38.643882395523654, -8.68887321767791],
              [-38.643875695843995, -8.688883027399065],
              [-38.643869028464692, -8.68889285888493],
              [-38.643862393457177, -8.688902712030165],
              [-38.643855790892516, -8.688912586729231],
              [-38.643849220841396, -8.688922482876398],
              [-38.643842683374231, -8.688932400365623],
              [-38.643836178561003, -8.688942339090747],
              [-38.643829706471415, -8.688952298945294],
              [-38.643823267174781, -8.688962279822581],
              [-38.643816860740095, -8.688972281615722],
              [-38.643810487235946, -8.688982304217557],
              [-38.643804146730588, -8.688992347520768],
              [-38.643797839291977, -8.689002411417787],
              [-38.643791564987652, -8.689012495800805],
              [-38.643785323884799, -8.689022600561817],
              [-38.64377911605029, -8.689032725592611],
              [-38.643772941550623, -8.689042870784702],
              [-38.643766800451921, -8.689053036029479],
              [-38.643760692819953, -8.689063221218026],
              [-38.643754618720159, -8.689073426241281],
              [-38.643748578217583, -8.689083650989925],
              [-38.643742571376933, -8.689093895354448],
              [-38.643736598262535, -8.689104159225128],
              [-38.643730658938402, -8.689114442492007],
              [-38.643724753468113, -8.689124745045007],
              [-38.643718881914936, -8.689135066773728],
              [-38.643713044341759, -8.689145407567638],
              [-38.643707240811118, -8.689155767315961],
              [-38.643701471385164, -8.689166145907766],
              [-38.643695736125714, -8.689176543231879],
              [-38.643690035094174, -8.68918695917692],
              [-38.643684368351614, -8.689197393631369],
              [-38.643678735958751, -8.689207846483395],
              [-38.643673137975895, -8.689218317621114],
              [-38.643667574463002, -8.689228806932357],
              [-38.64366204547968, -8.689239314304738],
              [-38.643656551085158, -8.689249839625758],
              [-38.643651091338263, -8.689260382782674],
              [-38.643645666297488, -8.689270943662526],
              [-38.643640276020925, -8.68928152215226],
              [-38.643634920566342, -8.689292118138543],
              [-38.64362959999108, -8.689302731507862],
              [-38.643624314352138, -8.689313362146594],
              [-38.643619063706105, -8.689324009940822],
              [-38.643613848109283, -8.689334674776541],
              [-38.643608667617464, -8.689345356539528],
              [-38.643603522286185, -8.689356055115333],
              [-38.643598412170554, -8.6893667703894],
              [-38.643593337325285, -8.689377502246964],
              [-38.643588297804762, -8.689388250573066],
              [-38.643583293662935, -8.689399015252564],
              [-38.643578324953438, -8.689409796170198],
              [-38.643573391729461, -8.689420593210476],
              [-38.643568494043869, -8.689431406257745],
              [-38.643563631949121, -8.689442235196234],
              [-38.643558805497271, -8.689453079909919],
              [-38.643554014740047, -8.689463940282671],
              [-38.643549259728744, -8.689474816198148],
              [-38.643544540514299, -8.689485707539861],
              [-38.643539857147275, -8.689496614191144],
              [-38.643535209677822, -8.68950753603524],
              [-38.643530598155714, -8.689518472955086],
              [-38.643526022630361, -8.689529424833598],
              [-38.643521483150778, -8.689540391553443],
              [-38.643516979765565, -8.6895513729978],
              [-38.643492775182587, -8.6895687195709],
              [-38.643468629770958, -8.689586147600716],
              [-38.643444543807568, -8.689603656887389],
              [-38.643420517568607, -8.689621247230138],
              [-38.643396551329587, -8.689638918427303],
              [-38.643372645365325, -8.689656670276225],
              [-38.643348799949962, -8.689674502573325],
              [-38.643325015356936, -8.689692415114195],
              [-38.643301291858947, -8.689710407693362],
              [-38.643277629728097, -8.689728480104515],
              [-38.643254029235671, -8.689746632140483],
              [-38.643230490652314, -8.689764863593075],
              [-38.643207014247956, -8.689783174253238],
              [-38.643183600291785, -8.689801563911006],
              [-38.643160249052301, -8.689820032355509],
              [-38.643136960797264, -8.68983857937498],
              [-38.64311373579374, -8.689857204756755],
              [-38.643090574308047, -8.689875908287235],
              [-38.643067476605751, -8.689894689751979],
              [-38.643044442951748, -8.689913548935621],
              [-38.643021473610176, -8.689932485621872],
              [-38.642998568844405, -8.689951499593652],
              [-38.642975728917101, -8.689970590632893],
              [-38.642952954090148, -8.689989758520674],
              [-38.642930244624729, -8.690009003037188],
              [-38.642907600781264, -8.690028323961815],
              [-38.642885022819385, -8.690047721072977],
              [-38.642862510998043, -8.69006719414827],
              [-38.642840065575328, -8.69008674296437],
              [-38.642817686808677, -8.690106367297126],
              [-38.64279537495468, -8.690126066921527],
              [-38.642773130269205, -8.690145841611649],
              [-38.642750953007315, -8.690165691140788],
              [-38.642728843423363, -8.690185615281289],
              [-38.642706801770842, -8.690205613804695],
              [-38.642684828302521, -8.690225686481705],
              [-38.642662923270379, -8.690245833082137],
              [-38.642641086925615, -8.690266053374971],
              [-38.642619319518616, -8.690286347128341],
              [-38.642597621299004, -8.690306714109578],
              [-38.642575992515589, -8.690327154085105],
              [-38.642554433416414, -8.690347666820543],
              [-38.642532944248671, -8.690368252080681],
              [-38.642511525258804, -8.690388909629466],
              [-38.642490176692426, -8.69040963923004],
              [-38.642468898794355, -8.690430440644692],
              [-38.64244769180857, -8.69045131363489],
              [-38.642426555978282, -8.690472257961302],
              [-38.642405491545837, -8.690493273383769],
              [-38.642384498752811, -8.69051435966125],
              [-38.642363577839923, -8.69053551655203],
              [-38.64234272904708, -8.690556743813467],
              [-38.642321952613379, -8.690578041202167],
              [-38.642301248777045, -8.690599408473904],
              [-38.642280617775498, -8.69062084538367],
              [-38.642260059845356, -8.690642351685629],
              [-38.642239575222305, -8.690663927133222],
              [-38.642219164141309, -8.690685571479019],
              [-38.642198826836392, -8.690707284474819],
              [-38.642178563540796, -8.690729065871672],
              [-38.642158374486861, -8.690750915419788],
              [-38.642138259906126, -8.690772832868614],
              [-38.642118220029246, -8.690794817966841],
              [-38.642098255086012, -8.690816870462392],
              [-38.642078365305387, -8.690838990102348],
              [-38.642058550915458, -8.690861176633126],
              [-38.64203881214344, -8.690883429800261],
              [-38.642019149215699, -8.690905749348609],
              [-38.64199956235769, -8.690928135022212],
              [-38.64198005179405, -8.6909505865644],
              [-38.641960617748495, -8.690973103717701],
              [-38.641941260443907, -8.690995686223964],
              [-38.641921980102261, -8.691018333824179],
              [-38.641902776944661, -8.691041046258668],
              [-38.641883651191279, -8.69106382326707],
              [-38.641864603061485, -8.691086664588072],
              [-38.641845632773702, -8.69110956995984],
              [-38.641826740545461, -8.691132539119682],
              [-38.641807926593437, -8.691155571804249],
              [-38.641789191133341, -8.691178667749414],
              [-38.641770534380051, -8.691201826690321],
              [-38.641751956547516, -8.691225048361428],
              [-38.641733457848758, -8.69124833249645],
              [-38.641715038495946, -8.691271678828391],
              [-38.641696698700279, -8.691295087089548],
              [-38.641678438672059, -8.691318557011495],
              [-38.641660258620718, -8.691342088325113],
              [-38.641642158754728, -8.691365680760553],
              [-38.641624139281639, -8.691389334047315],
              [-38.641606200408106, -8.691413047914116],
              [-38.641588342339858, -8.691436822089111],
              [-38.64157056528164, -8.691460656299629],
              [-38.641552869437369, -8.691484550272357],
              [-38.641535255009941, -8.691508503733345],
              [-38.641517722201378, -8.691532516407895],
              [-38.641500271212692, -8.691556588020656],
              [-38.641482902244064, -8.691580718295631],
              [-38.641465615494653, -8.691604906956078],
              [-38.641448411162706, -8.691629153724669],
              [-38.641448409432492, -8.691629152670291],
              [-38.641447493936887, -8.69165316448052],
              [-38.641446580695735, -8.691677176375743],
              [-38.641445669709043, -8.69170118835574],
              [-38.641444760976825, -8.691725200420223],
              [-38.64144385449908, -8.691749212569073],
              [-38.64144295027581, -8.691773224802043],
              [-38.641442048307034, -8.691797237118944],
              [-38.641441148592769, -8.691821249519572],
              [-38.641440251132998, -8.691845262003664],
              [-38.641439355927751, -8.691869274571056],
              [-38.641438462977035, -8.691893287221525],
              [-38.641437572280843, -8.691917299954881],
              [-38.64143668383921, -8.691941312770885],
              [-38.641435797652107, -8.691965325669354],
              [-38.641434913719564, -8.691989338650059],
              [-38.641434032041602, -8.692013351712788],
              [-38.641433152618212, -8.692037364857359],
              [-38.64143227544939, -8.692061378083537],
              [-38.641431400535176, -8.692085391391105],
              [-38.641430527875542, -8.692109404779888],
              [-38.641429657470532, -8.692133418249657],
              [-38.64142878932013, -8.692157431800169],
              [-38.641427923424352, -8.692181445431267],
              [-38.641427059783204, -8.692205459142722],
              [-38.641426198396694, -8.692229472934317],
              [-38.641425339264835, -8.692253486805868],
              [-38.641424482387627, -8.692277500757138],
              [-38.641423627765086, -8.692301514787912],
              [-38.641422775397203, -8.692325528898017],
              [-38.641421925284014, -8.692349543087234],
              [-38.641421077425505, -8.692373557355316],
              [-38.641420231821677, -8.692397571702053],
              [-38.641419388472578, -8.692421586127267],
              [-38.641418547378173, -8.692445600630784],
              [-38.641417708538484, -8.692469615212318],
              [-38.641416871953524, -8.69249362987172],
              [-38.641416037623308, -8.692517644608737],
              [-38.641415205547816, -8.692541659423165],
              [-38.641414375727066, -8.692565674314821],
              [-38.64141354816109, -8.692589689283469],
              [-38.641412722849864, -8.692613704328908],
              [-38.641411899793425, -8.692637719450932],
              [-38.641411078991752, -8.692661734649338],
              [-38.641410260444879, -8.692685749923911],
              [-38.641409444152785, -8.692709765274415],
              [-38.6414086301155, -8.692733780700681],
              [-38.641407818333015, -8.692757796202487],
              [-38.641407008805359, -8.692781811779621],
              [-38.641406201532511, -8.692805827431872],
              [-38.641405396514514, -8.692829843159029],
              [-38.641404593751339, -8.692853858960868],
              [-38.641403793243015, -8.692877874837206],
              [-38.641402994989562, -8.692901890787818],
              [-38.641402198990953, -8.692925906812478],
              [-38.641401405247215, -8.692949922911028],
              [-38.641400613758357, -8.69297393908322],
              [-38.641399824524385, -8.692997955328847],
              [-38.641399037545298, -8.69302197164771],
              [-38.641398252821119, -8.693045988039584],
              [-38.641397470351819, -8.693070004504294],
              [-38.641396690137448, -8.693094021041579],
              [-38.641395912178012, -8.693118037651256],
              [-38.641395136473491, -8.693142054333142],
              [-38.641394363023899, -8.693166071086967],
              [-38.641393591829242, -8.693190087912575],
              [-38.641392822889543, -8.693214104809737],
              [-38.641392056204801, -8.693238121778226],
              [-38.641391291775022, -8.693262138817845],
              [-38.641390529600216, -8.693286155928396],
              [-38.64138976968038, -8.693310173109667],
              [-38.64138901201553, -8.693334190361446],
              [-38.64138825660568, -8.693358207683509],
              [-38.641387503450801, -8.693382225075659],
              [-38.641386752550964, -8.69340624253771],
              [-38.641386003906121, -8.693430260069405],
              [-38.641385257516291, -8.693454277670547],
              [-38.641384513381496, -8.693478295340963],
              [-38.641383771501737, -8.693502313080387],
              [-38.641383031876998, -8.693526330888666],
              [-38.641382294507316, -8.693550348765546],
              [-38.641381559392698, -8.693574366710846],
              [-38.641380826533144, -8.693598384724327],
              [-38.641380095928646, -8.693622402805818],
              [-38.641379367579219, -8.693646420955087],
              [-38.641378641484891, -8.693670439171919],
              [-38.641377917645613, -8.693694457456122],
              [-38.641377196061455, -8.693718475807456],
              [-38.641376476732404, -8.693742494225765],
              [-38.641375759658452, -8.693766512710766],
              [-38.64137504483962, -8.693790531262305],
              [-38.641374332275909, -8.693814549880171],
              [-38.641373621967311, -8.693838568564109],
              [-38.64137291391387, -8.693862587313991],
              [-38.641372208115556, -8.693886606129528],
              [-38.641371504572383, -8.693910625010513],
              [-38.641370803284381, -8.693934643956789],
              [-38.641370104251529, -8.693958662968107],
              [-38.641369407473853, -8.693982682044274],
              [-38.641368712951341, -8.694006701185087],
              [-38.641368717630712, -8.694006702188307],
              [-38.641388861550226, -8.694011017875281],
              [-38.641409005875573, -8.694015331687599],
              [-38.641429150606605, -8.694019643625188],
              [-38.641449295743122, -8.694023953688017],
              [-38.641469441284983, -8.69402826187607],
              [-38.641489587231973, -8.694032568189275],
              [-38.641509733583931, -8.69403687262764],
              [-38.641529880340691, -8.69404117519109],
              [-38.641550027502078, -8.694045475879578],
              [-38.641570175067876, -8.694049774693116],
              [-38.641590323037946, -8.69405407163163],
              [-38.641610471412108, -8.694058366695092],
              [-38.641630620190192, -8.694062659883455],
              [-38.641650769371992, -8.694066951196699],
              [-38.641670918957338, -8.694071240634781],
              [-38.641691068946095, -8.694075528197645],
              [-38.641711219338049, -8.694079813885271],
              [-38.641731370133016, -8.694084097697598],
              [-38.64175152133086, -8.694088379634639],
              [-38.641771672931363, -8.69409265969631],
              [-38.64179182493438, -8.694096937882593],
              [-38.6418119773397, -8.694101214193452],
              [-38.641832130147179, -8.694105488628841],
              [-38.641852283356641, -8.694109761188724],
              [-38.641872436967873, -8.694114031873065],
              [-38.641892590980731, -8.694118300681838],
              [-38.641912745395032, -8.694122567614999],
              [-38.641932900210598, -8.694126832672486],
              [-38.641953055427258, -8.69413109585428],
              [-38.641973211044828, -8.694135357160382],
              [-38.641993367063137, -8.694139616590688],
              [-38.642013523481992, -8.694143874145199],
              [-38.642033680301239, -8.694148129823869],
              [-38.642053837520699, -8.694152383626676],
              [-38.64207399514018, -8.694156635553554],
              [-38.642094153159519, -8.694160885604466],
              [-38.642114311578531, -8.694165133779396],
              [-38.642134470397039, -8.694169380078296],
              [-38.642154629614886, -8.694173624501149],
              [-38.642174789231873, -8.694177867047902],
              [-38.642194949247838, -8.69418210771852],
              [-38.642215109662594, -8.694186346512971],
              [-38.642235270475965, -8.694190583431205],
              [-38.642255431687779, -8.694194818473186],
              [-38.642275593297867, -8.694199051638861],
              [-38.642295755306037, -8.694203282928239],
              [-38.642315917712125, -8.694207512341228],
              [-38.642336080515946, -8.694211739877824],
              [-38.642356243717323, -8.694215965537987],
              [-38.642376407316092, -8.694220189321696],
              [-38.642396571312055, -8.694224411228893],
              [-38.642416735705069, -8.694228631259552],
              [-38.642436900494914, -8.694232849413618],
              [-38.642457065681462, -8.69423706569108],
              [-38.642477231264493, -8.69424128009188],
              [-38.642497397243851, -8.69424549261597],
              [-38.642517563619364, -8.694249703263335],
              [-38.642537730390842, -8.69425391203394],
              [-38.64255789755812, -8.694258118927747],
              [-38.642578065121015, -8.69426232394469],
              [-38.642598233079347, -8.694266527084773],
              [-38.642618401432955, -8.694270728347959],
              [-38.642638570181646, -8.694274927734181],
              [-38.642658739325249, -8.694279125243426],
              [-38.642678908863594, -8.694283320875634],
              [-38.642699078796483, -8.694287514630792],
              [-38.642719249123772, -8.694291706508837],
              [-38.642739419845256, -8.694295896509743],
              [-38.642759590960779, -8.694300084633486],
              [-38.642779762470163, -8.694304270880025],
              [-38.642799934373208, -8.694308455249315],
              [-38.642820106669753, -8.694312637741312],
              [-38.642840279359625, -8.694316818355995],
              [-38.642860452442648, -8.694320997093335],
              [-38.642880625918657, -8.69432517395329],
              [-38.642900799787434, -8.694329348935806],
              [-38.642920974048849, -8.69433352204088],
              [-38.642941148702683, -8.694337693268452],
              [-38.642961323748807, -8.694341862618462],
              [-38.642981499186995, -8.694346030090916],
              [-38.643001675017103, -8.694350195685741],
              [-38.643021851238949, -8.694354359402942],
              [-38.643042027852346, -8.694358521242435],
              [-38.643062204857124, -8.694362681204218],
              [-38.643082382253127, -8.694366839288236],
              [-38.643102560040141, -8.694370995494475],
              [-38.643122738218011, -8.694375149822889],
              [-38.643142916786552, -8.694379302273438],
              [-38.643163095745592, -8.694383452846088],
              [-38.64318327509497, -8.694387601540811],
              [-38.643203454834463, -8.694391748357534],
              [-38.643223634963952, -8.694395893296274],
              [-38.643243815483231, -8.694400036356944],
              [-38.643263996392115, -8.694404177539555],
              [-38.64328417769044, -8.694408316844026],
              [-38.643304359378021, -8.694412454270347],
              [-38.643324541454689, -8.694416589818465],
              [-38.643344723920286, -8.694420723488385],
              [-38.643364906774579, -8.694424855280131],
              [-38.643364904863994, -8.694425029758694],
              [-38.643364905852948, -8.694425204244823],
              [-38.64336490974118, -8.694425378690823],
              [-38.643364916527617, -8.694425553049015],
              [-38.643364926210396, -8.694425727271733],
              [-38.643364938786895, -8.694425901311329],
              [-38.643364954253641, -8.694426075120219],
              [-38.643364972606449, -8.694426248650915],
              [-38.643364993840258, -8.694426421855944],
              [-38.643365017949279, -8.694426594687975],
              [-38.643365044926931, -8.694426767099744],
              [-38.643365074765825, -8.694426939044124],
              [-38.643365107457811, -8.694427110474082],
              [-38.643365142993957, -8.69442728134276],
              [-38.643365181364537, -8.694427451603495],
              [-38.643365222559055, -8.694427621209663],
              [-38.643365266566256, -8.69442779011495],
              [-38.643365313374119, -8.694427958273149],
              [-38.643365362969838, -8.694428125638293],
              [-38.643365415339844, -8.694428292164622],
              [-38.643365470469838, -8.694428457806602],
              [-38.643365528344738, -8.694428622518965],
              [-38.643365588948718, -8.694428786256678],
              [-38.643365652265217, -8.694428948974984],
              [-38.643365718276918, -8.694429110629363],
              [-38.643365786965795, -8.694429271175641],
              [-38.643365858313025, -8.694429430569926],
              [-38.643365932299147, -8.694429588768648],
              [-38.643366008903897, -8.694429745728549],
              [-38.643366088106369, -8.694429901406711],
              [-38.643366169884885, -8.694430055760574],
              [-38.643366254217085, -8.694430208747967],
              [-38.643366341079926, -8.694430360327015],
              [-38.643366430449646, -8.694430510456316],
              [-38.643366522301847, -8.694430659094811],
              [-38.643366616611374, -8.694430806201856],
              [-38.643366713352449, -8.694430951737278],
              [-38.643366812498648, -8.694431095661237],
              [-38.643366914022856, -8.694431237934372],
              [-38.643367017897305, -8.694431378517855],
              [-38.643367124093608, -8.694431517373182],
              [-38.643367232582733, -8.694431654462434],
              [-38.643367343335008, -8.694431789748092],
              [-38.643367456320163, -8.694431923193219],
              [-38.643367571507312, -8.694432054761316],
              [-38.643367688864956, -8.694432184416387],
              [-38.643367808361013, -8.694432312123016],
              [-38.643367929962814, -8.694432437846277],
              [-38.643368053637111, -8.694432561551807],
              [-38.643368179350105, -8.694432683205767],
              [-38.643368307067391, -8.694432802774926],
              [-38.643368436754095, -8.694432920226554],
              [-38.643368568374754, -8.694433035528579],
              [-38.643368701893358, -8.694433148649452],
              [-38.643368837273421, -8.694433259558254],
              [-38.64336897447793, -8.694433368224665],
              [-38.643369113469397, -8.694433474618991],
              [-38.643369254209766, -8.694433578712117],
              [-38.64336939666061, -8.694433680475608],
              [-38.643369540782956, -8.694433779881637],
              [-38.643369686537433, -8.694433876903032],
              [-38.643369833884151, -8.694433971513236],
              [-38.643369982782865, -8.694434063686433],
              [-38.643370133192846, -8.694434153397378],
              [-38.643370285072962, -8.694434240621552],
              [-38.643370438381744, -8.694434325335138],
              [-38.643370593077194, -8.694434407514956],
              [-38.643370749117096, -8.694434487138523],
              [-38.643370906458735, -8.694434564184107],
              [-38.643371065059128, -8.694434638630618],
              [-38.643371224874919, -8.69443471045772],
              [-38.643371385862395, -8.694434779645755],
              [-38.643371547977537, -8.694434846175813],
              [-38.643371711176044, -8.694434910029729],
              [-38.643371875413294, -8.694434971190018],
              [-38.64337204064438, -8.694435029639985],
              [-38.643372206824147, -8.694435085363605],
              [-38.64337237390712, -8.694435138345698],
              [-38.643372541847661, -8.694435188571724],
              [-38.643372710599841, -8.694435236027999],
              [-38.643372880117525, -8.694435280701521],
              [-38.643373050354377, -8.694435322580089],
              [-38.643373221263829, -8.694435361652262],
              [-38.643373392799191, -8.694435397907336],
              [-38.643373564913546, -8.694435431335407],
              [-38.643373737559827, -8.694435461927341],
              [-38.643373910690862, -8.694435489674762],
              [-38.643374084259307, -8.694435514570118],
              [-38.643374258217683, -8.694435536606557],
              [-38.643374432518485, -8.694435555778085],
              [-38.643374607114019, -8.694435572079438],
              [-38.643374781956574, -8.6944355855062],
              [-38.643374956998315, -8.694435596054666],
              [-38.64337513219143, -8.694435603721951],
              [-38.643375307488, -8.694435608505986],
              [-38.643375482840092, -8.69443561040544],
              [-38.643375658199787, -8.694435609419786],
              [-38.64337583351913, -8.694435605549327],
              [-38.643376008750508, -8.69443559879406],
              [-38.643414526752046, -8.694433658776331],
              [-38.643453029925588, -8.694431446451135],
              [-38.643491516326733, -8.694428961930209],
              [-38.64352998401187, -8.69442620533902],
              [-38.643568431038354, -8.694423176816723],
              [-38.643606855464583, -8.694419876516331],
              [-38.643645255350116, -8.694416304604458],
              [-38.64368362875571, -8.69441246126145],
              [-38.643721973743503, -8.694408346681497],
              [-38.643760288377024, -8.694403961072304],
              [-38.64379857072138, -8.694399304655391],
              [-38.643836818843276, -8.694394377665864],
              [-38.643875030811159, -8.694389180352575],
              [-38.643913204695302, -8.69438371297797],
              [-38.643951338567902, -8.69437797581813],
              [-38.643989430503161, -8.694371969162814],
              [-38.644027478577442, -8.694365693315355],
              [-38.644065480869259, -8.694359148592669],
              [-38.644103435459499, -8.694352335325288],
              [-38.644141340431396, -8.694345253857293],
              [-38.644179193870762, -8.694337904546241],
              [-38.644216993865975, -8.694330287763293],
              [-38.644254738508081, -8.694322403893125],
              [-38.644292425890988, -8.694314253333859],
              [-38.64433005411145, -8.694305836497099],
              [-38.644367621269204, -8.694297153807904],
              [-38.644405125467124, -8.694288205704733],
              [-38.644442564811193, -8.694278992639505],
              [-38.644479937410715, -8.694269515077453],
              [-38.64451724137836, -8.694259773497212],
              [-38.644554474830251, -8.69424976839073],
              [-38.644591635886101, -8.69423950026324],
              [-38.644628722669211, -8.694228969633343],
              [-38.644665733306731, -8.694218177032781],
              [-38.644702665929593, -8.694207123006596],
              [-38.644739518672665, -8.694195808113045],
              [-38.644776289674866, -8.694184232923533],
              [-38.644812977079276, -8.694172398022575],
              [-38.644849579033128, -8.694160304007887],
              [-38.644886093688044, -8.694147951490166],
              [-38.644922519200001, -8.694135341093238],
              [-38.644958853729491, -8.694122473453964],
              [-38.644995095441622, -8.694109349222137],
              [-38.645031242506171, -8.69409596906053],
              [-38.645067293097689, -8.69408233364484],
              [-38.645103245395624, -8.694068443663673],
              [-38.645139097584355, -8.694054299818495],
              [-38.645174847853362, -8.694039902823549],
              [-38.645210494397247, -8.694025253405879],
              [-38.645246035415816, -8.694010352305318],
              [-38.645281469114273, -8.693995200274358],
              [-38.645316793703188, -8.693979798078182],
              [-38.645352007398685, -8.693964146494595],
              [-38.645387108422455, -8.693948246314006],
              [-38.645422095001877, -8.693932098339419],
              [-38.645456965370144, -8.693915703386285],
              [-38.645491717766276, -8.693899062282533],
              [-38.645526350435311, -8.693882175868572],
              [-38.645560861628248, -8.693865044997155],
              [-38.64559524960233, -8.69384767053343],
              [-38.645629512620921, -8.693830053354764],
              [-38.645663648953736, -8.693812194350853],
              [-38.645697656876898, -8.693794094423582],
              [-38.645731534673018, -8.693775754487033],
              [-38.645765280631274, -8.693757175467329],
              [-38.645798893047477, -8.693738358302737],
              [-38.64583237022422, -8.693719303943498],
              [-38.6458657104709, -8.693700013351931],
              [-38.645898912103846, -8.693680487502156],
              [-38.645931973446366, -8.693660727380252],
              [-38.645964892828893, -8.69364073398412],
              [-38.645997668588997, -8.693620508323411],
              [-38.646030299071512, -8.693600051419546],
              [-38.646062782628597, -8.693579364305583],
              [-38.646095117619844, -8.693558448026261],
              [-38.646127302412367, -8.693537303637806],
              [-38.646159335380823, -8.693515932208051],
              [-38.646191214907581, -8.693494334816247],
              [-38.646222939382717, -8.693472512553113],
              [-38.646254507204169, -8.693450466520584],
              [-38.646285916777771, -8.693428197832057],
              [-38.64631716651737, -8.693405707612076],
              [-38.646348254844867, -8.693382996996428],
              [-38.646379180190316, -8.693360067131978],
              [-38.646409940991994, -8.693336919176707],
              [-38.646440535696506, -8.693313554299596],
              [-38.646470962758855, -8.693289973680546],
              [-38.646501220642499, -8.693266178510413],
              [-38.646531307819416, -8.693242169990826],
              [-38.646561222770217, -8.693217949334244],
              [-38.646590963984259, -8.693193517763783],
              [-38.646620529959606, -8.693168876513287],
              [-38.64664991920322, -8.693144026827113],
              [-38.64667913023095, -8.693118969960166],
              [-38.646708161567702, -8.693093707177809],
              [-38.646737011747383, -8.693068239755853],
              [-38.646765679313113, -8.693042568980413],
              [-38.646794162817194, -8.693016696147831],
              [-38.646822460821262, -8.692990622564675],
              [-38.64684199351467, -8.692972585591978],
              [-38.646861614976338, -8.692954644264855],
              [-38.646881324733243, -8.692936799015786],
              [-38.646901122310204, -8.69291905027505],
              [-38.646921007229921, -8.692901398470493],
              [-38.646940979013017, -8.692883844027657],
              [-38.646961037177974, -8.692866387369785],
              [-38.64698118124123, -8.692849028917674],
              [-38.647001410717138, -8.692831769089834],
              [-38.647021725118002, -8.692814608302397],
              [-38.647042123954066, -8.69279754696899],
              [-38.647062606733549, -8.692780585500998],
              [-38.647083172962638, -8.692763724307307],
              [-38.647103822145517, -8.692746963794395],
              [-38.647124553784352, -8.692730304366378],
              [-38.647145367379359, -8.692713746424809],
              [-38.64716626242874, -8.692697290368903],
              [-38.647187238428742, -8.692680936595385],
              [-38.647208294873671, -8.692664685498482],
              [-38.647229431255894, -8.692648537470008],
              [-38.647250647065846, -8.692632492899275],
              [-38.647271941792042, -8.692616552173039],
              [-38.647293314921107, -8.692600715675635],
              [-38.647314765937764, -8.692584983788844],
              [-38.64733629432488, -8.692569356891923],
              [-38.64735789956341, -8.692553835361592],
              [-38.647379581132519, -8.69253841957206],
              [-38.647401338509482, -8.692523109894967],
              [-38.64742317116977, -8.692507906699403],
              [-38.647445078587026, -8.692492810351883],
              [-38.647467060233119, -8.692477821216336],
              [-38.647489115578097, -8.692462939654153],
              [-38.647511244090239, -8.692448166024075],
              [-38.647533445236071, -8.692433500682275],
              [-38.647555718480362, -8.692418943982293],
              [-38.647578063286133, -8.692404496275058],
              [-38.647600479114701, -8.692390157908912],
              [-38.647622965425668, -8.692375929229483],
              [-38.647645521676893, -8.692361810579795],
              [-38.647668147324623, -8.692347802300221],
              [-38.64769084182339, -8.692333904728507],
              [-38.647713604626048, -8.692320118199666],
              [-38.64773643518383, -8.692306443046071],
              [-38.647759332946372, -8.692292879597415],
              [-38.647782297361609, -8.692279428180672],
              [-38.647805327875922, -8.692266089120114],
              [-38.647828423934087, -8.692252862737321],
              [-38.6478515849793, -8.692239749351168],
              [-38.647874810453196, -8.692226749277827],
              [-38.647898099795839, -8.692213862830631],
              [-38.647921452445793, -8.692201090320275],
              [-38.647944867840032, -8.692188432054714],
              [-38.6479683454141, -8.692175888339051],
              [-38.647991884601971, -8.69216345947574],
              [-38.648015484836144, -8.692151145764372],
              [-38.648039145547685, -8.692138947501864],
              [-38.648062866166185, -8.692126864982217],
              [-38.648086646119765, -8.692114898496811],
              [-38.648110484835144, -8.692103048334049],
              [-38.648134381737634, -8.692091314779644],
              [-38.6481583362511, -8.692079698116476],
              [-38.64818234779807, -8.692068198624575],
              [-38.648206415799656, -8.692056816581209],
              [-38.648230539675616, -8.69204555226071],
              [-38.648254718844392, -8.692034405934718],
              [-38.648278952723068, -8.692023377871902],
              [-38.648303240727415, -8.692012468338104],
              [-38.648327582271882, -8.692001677596398],
              [-38.648351976769668, -8.691991005906861],
              [-38.648376423632641, -8.691980453526785],
              [-38.648400922271463, -8.69197002071059],
              [-38.648425472095489, -8.691959707709758],
              [-38.648450072512922, -8.691949514772936],
              [-38.648474722930644, -8.691939442145864],
              [-38.648499422754405, -8.691929490071313],
              [-38.64852417138875, -8.691919658789288],
              [-38.648548968237037, -8.69190994853674],
              [-38.648573812701457, -8.691900359547788],
              [-38.648598704183073, -8.691890892053625],
              [-38.648623642081773, -8.691881546282449],
              [-38.648648625796405, -8.691872322459579],
              [-38.648673654724604, -8.691863220807409],
              [-38.648698728263028, -8.691854241545325],
              [-38.648723845807176, -8.691845384889817],
              [-38.64874900675153, -8.691836651054407],
              [-38.648774210489506, -8.691828040249645],
              [-38.648799456413499, -8.691819552683116],
              [-38.648824743914879, -8.691811188559415],
              [-38.648850072384008, -8.691802948080237],
              [-38.648875441210294, -8.6917948314442],
              [-38.648900849782116, -8.69178683884698],
              [-38.648926297486959, -8.691778970481282],
              [-38.648951783711311, -8.69177122653681],
              [-38.648977307840759, -8.691763607200222],
              [-38.649002869259988, -8.691756112655211],
              [-38.649028467352743, -8.691748743082451],
              [-38.64905410150191, -8.691741498659644],
              [-38.649079771089511, -8.691734379561385],
              [-38.649105475496683, -8.691727385959268],
              [-38.649122597577993, -8.691722835055284],
              [-38.649139750655607, -8.691718401223964],
              [-38.649156933921063, -8.691714084674265],
              [-38.649174146564434, -8.691709885609667],
              [-38.649191387774408, -8.69170580422807],
              [-38.649208656738359, -8.691701840721864],
              [-38.649225952642297, -8.691697995277833],
              [-38.649243274671022, -8.691694268077281],
              [-38.649260622008043, -8.691690659295844],
              [-38.649277993835746, -8.691687169103641],
              [-38.6492953893353, -8.69168379766518],
              [-38.649312807686783, -8.691680545139393],
              [-38.649330248069205, -8.691677411679517],
              [-38.649347709660532, -8.691674397433326],
              [-38.649365191637706, -8.691671502542823],
              [-38.649382693176776, -8.691668727144489],
              [-38.649400213452772, -8.691666071369164],
              [-38.649417751639909, -8.691663535342006],
              [-38.649435306911535, -8.691661119182529],
              [-38.649452878440215, -8.691658823004651],
              [-38.649470465397719, -8.691656646916581],
              [-38.649488066955101, -8.691654591020885],
              [-38.649505682282729, -8.691652655414481],
              [-38.649523310550322, -8.691650840188588],
              [-38.649540950926983, -8.691649145428753],
              [-38.649558602581259, -8.691647571214901],
              [-38.649576264681144, -8.6916461176212],
              [-38.649593936394147, -8.691644784716141],
              [-38.649611616887348, -8.691643572562635],
              [-38.649629305327366, -8.691642481217745],
              [-38.649647000880506, -8.691641510732937],
              [-38.649664702712663, -8.691640661153937],
              [-38.649682409989516, -8.691639932520806],
              [-38.649700121876428, -8.691639324867888],
              [-38.64971783753856, -8.691638838223826],
              [-38.649735556140911, -8.69163847261153],
              [-38.64975327684833, -8.691638228048239],
              [-38.649770998825566, -8.691638104545502],
              [-38.649788721237329, -8.69163810210916],
              [-38.649806443248245, -8.69163822073928],
              [-38.649824164023038, -8.691638460430319],
              [-38.649841882726449, -8.69163882117094],
              [-38.6498595985233, -8.691639302944195],
              [-38.64987731057861, -8.691639905727303],
              [-38.649895018057514, -8.691640629491888],
              [-38.649912720125386, -8.691641474203834],
              [-38.649930415947871, -8.691642439823328],
              [-38.649948104690878, -8.691643526304864],
              [-38.649965785520678, -8.691644733597236],
              [-38.649983457603888, -8.691646061643505],
              [-38.650001120107547, -8.691647510381136],
              [-38.65001877219914, -8.691649079741781],
              [-38.650036413046671, -8.691650769651527],
              [-38.650054041818656, -8.691652580030697],
              [-38.650071657684173, -8.691654510793942],
              [-38.650089259812916, -8.691656561850278],
              [-38.650106847375206, -8.691658733103017],
              [-38.650124419542081, -8.691661024449838],
              [-38.650141975485319, -8.69166343578277],
              [-38.650159514377386, -8.691665966988085],
              [-38.650177035391636, -8.69166861794651],
              [-38.650194537702227, -8.6916713885331],
              [-38.650212020484183, -8.691674278617274],
              [-38.650229482913495, -8.691677288062806],
              [-38.65024692416705, -8.691680416727859],
              [-38.650264343422812, -8.691683664464904],
              [-38.650281739859722, -8.69168703112094],
              [-38.650299112657798, -8.691690516537234],
              [-38.650316460998205, -8.691694120549537],
              [-38.650333784063243, -8.69169784298796],
              [-38.650351081036391, -8.691701683677067],
              [-38.650368351102387, -8.691705642435826],
              [-38.650385593447204, -8.691709719077613],
              [-38.650402807258146, -8.691713913410327],
              [-38.650419991723865, -8.691718225236244],
              [-38.650437146034363, -8.691722654352137],
              [-38.650454269381093, -8.691727200549245],
              [-38.650471360956978, -8.691731863613267],
              [-38.650488419956396, -8.691736643324457],
              [-38.650505445575298, -8.69174153945751],
              [-38.650522437011183, -8.691746551781627],
              [-38.650539393463184, -8.691751680060579],
              [-38.650556314132054, -8.691756924052662],
              [-38.650573198220286, -8.691762283510672],
              [-38.650590044932009, -8.691767758182023],
              [-38.650606853473214, -8.691773347808642],
              [-38.650623623051622, -8.691779052127103],
              [-38.650640352876813, -8.691784870868522],
              [-38.650657042160226, -8.691790803758641],
              [-38.650673690115241, -8.691796850517809],
              [-38.650690295957169, -8.691803010861026],
              [-38.650706858903277, -8.691809284497914],
              [-38.65072337817292, -8.691815671132799],
              [-38.650739852987464, -8.691822170464624],
              [-38.650756282570363, -8.691828782187066],
              [-38.650772666147226, -8.691835505988511],
              [-38.650789002945828, -8.691842341551991],
              [-38.650805292196139, -8.69184928855535],
              [-38.65082153313007, -8.691856346672264],
              [-38.650834967625698, -8.691862194575634],
              [-38.650848435290598, -8.691867966448079],
              [-38.650861935690152, -8.691873662103268],
              [-38.650875468388719, -8.691879281357441],
              [-38.650889032949607, -8.69188482402925],
              [-38.650902628935071, -8.69189028993984],
              [-38.650916255906388, -8.691895678912831],
              [-38.650929913423816, -8.691900990774315],
              [-38.65094360104662, -8.691906225352881],
              [-38.650957318333099, -8.691911382479597],
              [-38.650971064840626, -8.691916461988068],
              [-38.650984840125567, -8.691921463714355],
              [-38.650998643743428, -8.691926387497087],
              [-38.651012475248756, -8.691931233177316],
              [-38.651026334195208, -8.691936000598739],
              [-38.651040220135542, -8.691940689607438],
              [-38.651054132621695, -8.691945300052168],
              [-38.651068071204698, -8.691949831784115],
              [-38.651082035434754, -8.691954284657045],
              [-38.651096024861239, -8.691958658527239],
              [-38.651110039032716, -8.691962953253622],
              [-38.651124077496945, -8.691967168697513],
              [-38.651138139800914, -8.69197130472295],
              [-38.65115222549084, -8.691975361196423],
              [-38.651166334112162, -8.691979337987068],
              [-38.651180465209606, -8.691983234966484],
              [-38.651194618327153, -8.69198705200901],
              [-38.651208793008117, -8.691990788991419],
              [-38.651222988795041, -8.691994445793098],
              [-38.651237205229862, -8.69199802229606],
              [-38.651251441853788, -8.692001518384934],
              [-38.651265698207425, -8.692004933946816],
              [-38.651279973830718, -8.692008268871557],
              [-38.651294268262987, -8.692011523051496],
              [-38.651308581042962, -8.692014696381667],
              [-38.651322911708753, -8.692017788759651],
              [-38.65133725979792, -8.692020800085615],
              [-38.651351624847464, -8.692023730262433],
              [-38.651366006393829, -8.692026579195515],
              [-38.651380403972901, -8.69202934679293],
              [-38.651394817120064, -8.692032032965402],
              [-38.65140924537026, -8.692034637626204],
              [-38.651423688257815, -8.692037160691294],
              [-38.651438145316725, -8.692039602079277],
              [-38.651452616080434, -8.692041961711341],
              [-38.651467100081952, -8.692044239511363],
              [-38.651481596853912, -8.69204643540585],
              [-38.651496105928494, -8.692048549323879],
              [-38.651510626837499, -8.692050581197282],
              [-38.651525159112317, -8.692052530960478],
              [-38.651539702284012, -8.692054398550551],
              [-38.651554255883255, -8.692056183907233],
              [-38.651568819440435, -8.692057886972915],
              [-38.651583392485563, -8.692059507692624],
              [-38.651597974548366, -8.692061046014089],
              [-38.651612565158295, -8.692062501887632],
              [-38.651627163844502, -8.692063875266291],
              [-38.651641770135882, -8.692065166105726],
              [-38.651656383561104, -8.692066374364328],
              [-38.651671003648595, -8.692067500003072],
              [-38.651685629926561, -8.692068542985616],
              [-38.651700261923004, -8.692069503278338],
              [-38.651714899165768, -8.692070380850232],
              [-38.651729541182483, -8.692071175672977],
              [-38.651744187500682, -8.692071887720941],
              [-38.651758837647712, -8.692072516971111],
              [-38.651773491150806, -8.692073063403223],
              [-38.651788147537111, -8.692073526999613],
              [-38.651802806333649, -8.692073907745341],
              [-38.65181746706741, -8.692074205628094],
              [-38.651832129265259, -8.692074420638278],
              [-38.651846792454094, -8.69207455276897],
              [-38.651861456160674, -8.692074602015866],
              [-38.651876119911833, -8.692074568377402],
              [-38.651890783234386, -8.692074451854662],
              [-38.651905445655125, -8.692074252451402],
              [-38.651920106700906, -8.692073970174061],
              [-38.651934765898609, -8.692073605031762],
              [-38.651949422775182, -8.692073157036248],
              [-38.651964076857666, -8.692072626201988],
              [-38.651978727673153, -8.692072012546145],
              [-38.651993374748869, -8.692071316088468],
              [-38.652008017612154, -8.692070536851469],
              [-38.652022655790496, -8.692069674860283],
              [-38.652037288811499, -8.692068730142726],
              [-38.652051916202957, -8.69206770272932],
              [-38.652066537492864, -8.692066592653141],
              [-38.652081152209369, -8.692065399950073],
              [-38.652095759880872, -8.692064124658591],
              [-38.652110360035991, -8.692062766819836],
              [-38.652124952203543, -8.692061326477624],
              [-38.652139535912688, -8.692059803678438],
              [-38.652154110692777, -8.692058198471425],
              [-38.652168676073494, -8.69205651090838],
              [-38.652183231584807, -8.692054741043759],
              [-38.652197776757028, -8.692052888934677],
              [-38.652212311120749, -8.692050954640919],
              [-38.652226834206999, -8.692048938224847],
              [-38.652241345546933, -8.692046839752315],
              [-38.652262724554454, -8.692043639394045],
              [-38.652284089449488, -8.692040347051211],
              [-38.65230543983234, -8.692036962785377],
              [-38.652326775303528, -8.692033486659904],
              [-38.652348095463864, -8.692029918739797],
              [-38.652369399914441, -8.692026259091834],
              [-38.652390688256659, -8.692022507784475],
              [-38.652411960092195, -8.692018664887916],
              [-38.652433215023073, -8.69201473047406],
              [-38.652454452651575, -8.692010704616504],
              [-38.652475672580373, -8.692006587390605],
              [-38.652496874412442, -8.69200237887336],
              [-38.652518057751074, -8.691998079143508],
              [-38.652539222199934, -8.691993688281528],
              [-38.652560367363016, -8.691989206369541],
              [-38.652581492844732, -8.691984633491423],
              [-38.652602598249779, -8.691979969732738],
              [-38.6526236831833, -8.691975215180744],
              [-38.652644747250768, -8.69197036992437],
              [-38.652665790058101, -8.69196543405431],
              [-38.652686811211566, -8.691960407662888],
              [-38.652707810317878, -8.691955290844161],
              [-38.652728786984099, -8.69195008369384],
              [-38.652749740817789, -8.691944786309405],
              [-38.652770671426893, -8.691939398789922],
              [-38.652791578419787, -8.691933921236206],
              [-38.652812461405311, -8.691928353750745],
              [-38.652833319992737, -8.691922696437693],
              [-38.652854153791807, -8.691916949402907],
              [-38.652874962412689, -8.691911112753909],
              [-38.652895745466083, -8.691905186599909],
              [-38.65291650256313, -8.691899171051805],
              [-38.652937233315448, -8.691893066222107],
              [-38.652957937335195, -8.691886872225066],
              [-38.652978614234954, -8.691880589176586],
              [-38.652999263627898, -8.691874217194194],
              [-38.653019885127634, -8.691867756397114],
              [-38.653040478348387, -8.691861206906221],
              [-38.65306104290481, -8.691854568844056],
              [-38.653081578412149, -8.691847842334841],
              [-38.653102084486193, -8.691841027504424],
              [-38.65312256074327, -8.6918341244803],
              [-38.653143006800271, -8.691827133391632],
              [-38.653163422274631, -8.691820054369186],
              [-38.653183806784398, -8.691812887545481],
              [-38.653204159948181, -8.691805633054569],
              [-38.653224481385124, -8.691798291032171],
              [-38.653244770715069, -8.691790861615656],
              [-38.653265027558376, -8.691783344944065],
              [-38.653285251536026, -8.691775741158011],
              [-38.653305442269662, -8.691768050399766],
              [-38.653325599381482, -8.69176027281323],
              [-38.653345722494365, -8.691752408543891],
              [-38.653365811231815, -8.69174445773892],
              [-38.653385865217935, -8.691736420547084],
              [-38.65340588407755, -8.691728297118713],
              [-38.653425867436091, -8.691720087605859],
              [-38.653445814919671, -8.691711792162055],
              [-38.653465726155083, -8.691703410942548],
              [-38.653485600769763, -8.691694944104183],
              [-38.653505438391889, -8.691686391805327],
              [-38.653525238650289, -8.69167775420598],
              [-38.653545001174464, -8.691669031467821],
              [-38.653564725594713, -8.69166022375398],
              [-38.653584411541956, -8.691651331229277],
              [-38.653604058647886, -8.6916423540601],
              [-38.653623666544888, -8.691633292414412],
              [-38.653643234866138, -8.691624146461741],
              [-38.653662763245457, -8.691614916373235],
              [-38.653682251317498, -8.691605602321568],
              [-38.653701698717668, -8.691596204480996],
              [-38.65372110508207, -8.691586723027363],
              [-38.653740470047616, -8.691577158138104],
              [-38.653759793252007, -8.691567509992112],
              [-38.653779074333691, -8.69155777876996],
              [-38.653798312931926, -8.691547964653699],
              [-38.653817508686735, -8.691538067826928],
              [-38.653836661239026, -8.691528088474845],
              [-38.6538557702304, -8.691518026784134],
              [-38.653874835303341, -8.691507882943128],
              [-38.653893856101178, -8.691497657141541],
              [-38.65391283226797, -8.691487349570712],
              [-38.653931763448739, -8.691476960423499],
              [-38.65395064928925, -8.691466489894283],
              [-38.65396948943615, -8.691455938178978],
              [-38.653988283536961, -8.691445305475034],
              [-38.654007031240027, -8.691434591981325],
              [-38.654025732194597, -8.691423797898347],
              [-38.654044386050778, -8.691412923428068],
              [-38.654062992459536, -8.691401968773901],
              [-38.654081551072764, -8.691390934140816],
              [-38.654100061543232, -8.691379819735291],
              [-38.65411852352463, -8.691368625765287],
              [-38.654136936671513, -8.691357352440237],
              [-38.654155300639381, -8.691345999971029],
              [-38.654173615084645, -8.691334568570118],
              [-38.654191879664644, -8.691323058451355],
              [-38.654210094037666, -8.69131146983011],
              [-38.65422825786289, -8.691299802923192],
              [-38.654255956905878, -8.691282055600237],
              [-38.654283772906396, -8.691264490324141],
              [-38.654311704656017, -8.691247107858032],
              [-38.654339750941254, -8.691229908957034],
              [-38.654367910543655, -8.691212894368361],
              [-38.654396182239871, -8.691196064831166],
              [-38.654424564801644, -8.691179421076622],
              [-38.654453056995962, -8.691162963827777],
              [-38.654481657584945, -8.691146693799579],
              [-38.654510365326118, -8.691130611698886],
              [-38.654539178972279, -8.691114718224355],
              [-38.654568097271635, -8.691099014066463],
              [-38.654597118967892, -8.691083499907414],
              [-38.654626242800205, -8.691068176421261],
              [-38.654655467503346, -8.69105304427368],
              [-38.654684791807668, -8.691038104122091],
              [-38.654714214439203, -8.691023356615526],
              [-38.654743734119705, -8.69100880239467],
              [-38.654773349566732, -8.690994442091814],
              [-38.654803059493702, -8.690980276330853],
              [-38.654832862609865, -8.690966305727146],
              [-38.654862757620471, -8.69095253088768],
              [-38.654892743226775, -8.690938952410852],
              [-38.654922818126082, -8.690925570886566],
              [-38.654952981011824, -8.690912386896178],
              [-38.654983230573606, -8.690899401012446],
              [-38.655013565497285, -8.690886613799515],
              [-38.655043984464974, -8.690874025812908],
              [-38.655074486155186, -8.690861637599509],
              [-38.655105069242801, -8.690849449697508],
              [-38.655135732399181, -8.690837462636344],
              [-38.655166474292194, -8.690825676936857],
              [-38.655197293586305, -8.690814093111003],
              [-38.655228188942623, -8.690802711662041],
              [-38.655259159018932, -8.69079153308444],
              [-38.655290202469757, -8.690780557863823],
              [-38.655321317946481, -8.690769786476995],
              [-38.65535250409733, -8.690759219391904],
              [-38.655383759567457, -8.690748857067605],
              [-38.655415082999014, -8.690738699954327],
              [-38.655446473031212, -8.690728748493282],
              [-38.655477928300343, -8.690719003116815],
              [-38.655509447439876, -8.690709464248307],
              [-38.65554102908051, -8.690700132302165],
              [-38.655572671850216, -8.690691007683762],
              [-38.655604374374334, -8.690682090789561],
              [-38.655636135275586, -8.69067338200693],
              [-38.655667953174174, -8.690664881714204],
              [-38.655699826687801, -8.690656590280659],
              [-38.655731754431784, -8.6906485080665],
              [-38.65576373501905, -8.6906406354229],
              [-38.655795767060276, -8.690632972691798],
              [-38.655827849163856, -8.690625520206138],
              [-38.655859979936061, -8.690618278289698],
              [-38.655892157980979, -8.690611247257065],
              [-38.655924381900697, -8.690604427413703],
              [-38.655956650295302, -8.690597819055879],
              [-38.655988961762958, -8.690591422470709],
              [-38.65602131489991, -8.690585237936046],
              [-38.656053708300654, -8.690579265720622],
              [-38.656086140557875, -8.690573506083856],
              [-38.656118610262652, -8.69056795927599],
              [-38.656151116004338, -8.69056262553798],
              [-38.656183656370814, -8.690557505101555],
              [-38.656216229948384, -8.690552598189173],
              [-38.656248835321954, -8.690547905013981],
              [-38.65628147107504, -8.690543425779873],
              [-38.656314135789827, -8.690539160681471],
              [-38.656346828047248, -8.690535109904062],
              [-38.656379546427026, -8.69053127362363],
              [-38.656412289507784, -8.690527652006811],
              [-38.656445055867053, -8.690524245210963],
              [-38.656477844081337, -8.690521053384117],
              [-38.656510652726205, -8.69051807666489],
              [-38.656543480376328, -8.690515315182632],
              [-38.656576325605606, -8.690512769057305],
              [-38.656609186987076, -8.690510438399516],
              [-38.656642063093166, -8.690508323310537],
              [-38.656674952495592, -8.690506423882235],
              [-38.656707853765553, -8.690504740197138],
              [-38.656740765473707, -8.69050327232841],
              [-38.656773686190256, -8.690502020339814],
              [-38.656806614484985, -8.690500984285734],
              [-38.656839548927408, -8.690500164211192],
              [-38.656872488086734, -8.690499560151808],
              [-38.656905430531978, -8.690499172133821],
              [-38.656938374831995, -8.690499000174112],
              [-38.656971319555574, -8.690499044280136],
              [-38.657004263271503, -8.690499304449968],
              [-38.657037204548573, -8.690499780672342],
              [-38.657070141955714, -8.690500472926535],
              [-38.657103074062015, -8.690501381182527],
              [-38.657135999436782, -8.690502505400792],
              [-38.657168916649645, -8.690503845532533],
              [-38.657201824270558, -8.690505401519534],
              [-38.657234720869901, -8.690507173294215],
              [-38.657267605018525, -8.690509160779554],
              [-38.657300475287833, -8.690511363889255],
              [-38.657333330250374, -8.690513782526322],
              [-38.657379328406321, -8.69050629479233],
              [-38.657425391374829, -8.690499212521676],
              [-38.657471515546113, -8.690492536269328],
              [-38.657517697305657, -8.690486266558519],
              [-38.65756393303441, -8.690480403880567],
              [-38.657610219109053, -8.690474948694924],
              [-38.65765655190237, -8.690469901429083],
              [-38.657702927783497, -8.690465262478563],
              [-38.65774934311812, -8.690461032206944],
              [-38.657795794268914, -8.690457210945706],
              [-38.657842277595712, -8.690453798994325],
              [-38.657888789455811, -8.690450796620182],
              [-38.657935326204296, -8.690448204058576],
              [-38.657981884194285, -8.690446021512656],
              [-38.658028459777249, -8.690444249153492],
              [-38.658075049303278, -8.690442887119966],
              [-38.658121649121362, -8.690441935518811],
              [-38.658168255579682, -8.69044139442463],
              [-38.658214865025897, -8.690441263879793],
              [-38.658261473807443, -8.690441543894584],
              [-38.658308078271794, -8.690442234447058],
              [-38.658354674766784, -8.690443335483057],
              [-38.658401259640847, -8.690444846916357],
              [-38.65844782924335, -8.690446768628515],
              [-38.658494379924811, -8.690449100468916],
              [-38.658540908037317, -8.690451842254838],
              [-38.658587409934633, -8.690454993771418],
              [-38.658633881972627, -8.690458554771704],
              [-38.658680320509497, -8.690462524976667],
              [-38.658726721906056, -8.690466904075132],
              [-38.658773082526032, -8.690471691723996],
              [-38.658819398736348, -8.690476887547982],
              [-38.658865666907445, -8.690482491140024],
              [-38.65891188341346, -8.690488502060942],
              [-38.658958044632612, -8.690494919839704],
              [-38.659004146947453, -8.690501743973403],
              [-38.659050186745148, -8.690508973927241],
              [-38.659096160417782, -8.69051660913467],
              [-38.65914206436257, -8.690524648997327],
              [-38.659187894982225, -8.690533092885202],
              [-38.659233648685216, -8.690541940136555],
              [-38.659279321885997, -8.690551190058111],
              [-38.659324911005399, -8.690560841924958],
              [-38.659370412470757, -8.690570894980761],
              [-38.659415822716333, -8.690581348437691],
              [-38.659461138183524, -8.690592201476582],
              [-38.659506355321128, -8.690603453246911],
              [-38.65955147058569, -8.690615102866976],
              [-38.659596480441699, -8.690627149423813],
              [-38.659641381361936, -8.690639591973396],
              [-38.659686169827687, -8.690652429540629],
              [-38.659730842329068, -8.690665661119558],
              [-38.659775395365266, -8.69067928567323],
              [-38.65981982544487, -8.690693302134022],
              [-38.659864129086046, -8.690707709403441],
              [-38.659908302816902, -8.690722506352554],
              [-38.659952343175718, -8.690737691821697],
              [-38.659996246711231, -8.690753264620923],
              [-38.660040009982872, -8.690769223529855],
              [-38.6600836295611, -8.690785567297819],
              [-38.660127102027616, -8.690802294644069],
              [-38.660170423975629, -8.690819404257766],
              [-38.66021359201018, -8.690836894798096],
              [-38.660256602748341, -8.690854764894384],
              [-38.660299452819523, -8.690873013146254],
              [-38.660342138865737, -8.690891638123631],
              [-38.660384657541798, -8.690910638367003],
              [-38.660427005515693, -8.69093001238741],
              [-38.660469179468748, -8.690949758666566],
              [-38.660511176095966, -8.690969875657052],
              [-38.660552992106169, -8.690990361782411],
              [-38.660594624222419, -8.691011215437191],
              [-38.660636069182132, -8.691032434987196],
              [-38.660677323737374, -8.691054018769533],
              [-38.660718384655219, -8.691075965092818],
              [-38.66075924871781, -8.691098272237159],
              [-38.660799912722787, -8.691120938454489],
              [-38.660840373483424, -8.691143961968491],
              [-38.660880627828924, -8.691167340974975],
              [-38.660920672604718, -8.691191073641749],
              [-38.660960504672573, -8.691215158109038],
              [-38.661000120910977, -8.691239592489463],
              [-38.66103951821534, -8.691264374868139],
              [-38.661078693498176, -8.691289503303018],
              [-38.661117643689451, -8.691314975824866],
              [-38.661156365736716, -8.691340790437511],
              [-38.661194856605476, -8.691366945117966],
              [-38.661233113279245, -8.691393437816604],
              [-38.661271132759971, -8.69142026645727],
              [-38.661308912068158, -8.691447428937524],
              [-38.661346448243137, -8.69147492312876],
              [-38.661383738343282, -8.69150274687636],
              [-38.66142077944626, -8.691530897999906],
              [-38.661457568649247, -8.691559374293249],
              [-38.661494103069117, -8.691588173524851],
              [-38.66153037984278, -8.691617293437833],
              [-38.661566396127284, -8.691646731750195],
              [-38.661602149100105, -8.691676486154897],
              [-38.661637635959423, -8.691706554320239],
              [-38.661645174947012, -8.691717266156996],
              [-38.66165257714934, -8.691728072030514],
              [-38.661659841381812, -8.691738970211594],
              [-38.661666966481924, -8.691749958956166],
              [-38.66167395130946, -8.691761036505754],
              [-38.66168079474663, -8.691772201087584],
              [-38.661687495698239, -8.691783450915052],
              [-38.661694053091971, -8.691794784187817],
              [-38.661700465878411, -8.691806199092248],
              [-38.661706733031323, -8.691817693801616],
              [-38.661712853547762, -8.691829266476432],
              [-38.661718826448265, -8.691840915264732],
              [-38.661724650776975, -8.6918526383024],
              [-38.661730325601809, -8.691864433713372],
              [-38.661735850014608, -8.691876299610037],
              [-38.661741223131315, -8.691888234093522],
              [-38.661746444092039, -8.691900235253989],
              [-38.661751512061286, -8.691912301170849],
              [-38.661756426227996, -8.691924429913222],
              [-38.661761185805716, -8.691936619540185],
              [-38.661765790032817, -8.691948868100988],
              [-38.661770238172444, -8.691961173635555],
              [-38.661774529512734, -8.691973534174592],
              [-38.661778663366967, -8.691985947740083],
              [-38.66178263907355, -8.69199841234548],
              [-38.661786455996292, -8.69201092599609],
              [-38.66179011352429, -8.692023486689342],
              [-38.661793611072248, -8.692036092415194],
              [-38.661796948080472, -8.692048741156331],
              [-38.661800124014867, -8.692061430888582],
              [-38.661803138367219, -8.692074159581251],
              [-38.661805990655104, -8.692086925197321],
              [-38.661808680422055, -8.692099725693952],
              [-38.661811207237626, -8.692112559022677],
              [-38.661813570697419, -8.692125423129815],
              [-38.66181577042321, -8.692138315956672],
              [-38.661817806062963, -8.692151235440061],
              [-38.661819677290865, -8.692164179512474],
              [-38.661821383807492, -8.69217714610244],
              [-38.66182292533972, -8.692190133134988],
              [-38.661824301640827, -8.692203138531772],
              [-38.661825512490545, -8.692216160211508],
              [-38.661826557695115, -8.692229196090397],
              [-38.661827437087219, -8.692242244082324],
              [-38.661828150526134, -8.692255302099175],
              [-38.661828697897668, -8.692268368051296],
              [-38.661829079114206, -8.692281439847754],
              [-38.66182929411471, -8.692294515396654],
              [-38.661829342864785, -8.692307592605555],
              [-38.661829225356598, -8.692320669381683],
              [-38.661828941608945, -8.692333743632409],
              [-38.661828491667194, -8.692346813265422],
              [-38.661827875603343, -8.692359876189192],
              [-38.661827093515967, -8.69237293031329],
              [-38.66182614553022, -8.692385973548655],
              [-38.661825031797761, -8.69239900380796],
              [-38.661823752496815, -8.692412019006007],
              [-38.661822307832118, -8.692425017059966],
              [-38.661820698034809, -8.692437995889762],
              [-38.661818923362496, -8.692450953418371],
              [-38.661816984099161, -8.692463887572249],
              [-38.661814880555134, -8.692476796281474],
              [-38.661812613067013, -8.692489677480323],
              [-38.661810181997673, -8.692502529107395],
              [-38.661807587736135, -8.692515349106053],
              [-38.661804830697513, -8.692528135424714],
              [-38.661801911323018, -8.692540886017211],
              [-38.661798830079839, -8.692553598843013],
              [-38.661795587461022, -8.692566271867706],
              [-38.661792183985497, -8.69257890306325],
              [-38.661788620197889, -8.69259149040824],
              [-38.661784896668479, -8.692604031888335],
              [-38.661781013993171, -8.692616525496554],
              [-38.661776972793248, -8.692628969233503],
              [-38.661772773715448, -8.692641361107809],
              [-38.661768417431695, -8.692653699136409],
              [-38.661763904639145, -8.692665981344858],
              [-38.66175923605995, -8.692678205767606],
              [-38.661754412441212, -8.692690370448414],
              [-38.661749434554842, -8.692702473440544],
              [-38.66174430319743, -8.692714512807145],
              [-38.661739019190136, -8.692726486621574],
              [-38.661733583378556, -8.692738392967634],
              [-38.661727996632557, -8.692750229939959],
              [-38.661722259846194, -8.692761995644288],
              [-38.661716373937487, -8.692773688197759],
              [-38.661710339848376, -8.692785305729174],
              [-38.661704158544445, -8.692796846379423],
              [-38.661697831014926, -8.692808308301595],
              [-38.661691358272371, -8.692819689661489],
              [-38.661684741352587, -8.692830988637693],
              [-38.661677981314504, -8.69284220342209],
              [-38.661671079239895, -8.692853332219924],
              [-38.661664036233311, -8.692864373250272],
              [-38.661656853421825, -8.692875324746231],
              [-38.661649531954886, -8.692886184955212],
              [-38.661642073004153, -8.692896952139273],
              [-38.661634477763272, -8.692907624575291],
              [-38.66162674744762, -8.692918200555511],
              [-38.661593788289444, -8.692927258914445],
              [-38.661560781393661, -8.692936143514547],
              [-38.661527727685502, -8.692944854106846],
              [-38.661494628091525, -8.692953390447107],
              [-38.661461483539568, -8.692961752296043],
              [-38.661428294958753, -8.692969939419273],
              [-38.661395063279372, -8.692977951587325],
              [-38.661361789433002, -8.692985788575561],
              [-38.661328474352345, -8.692993450164312],
              [-38.661295118971296, -8.693000936138814],
              [-38.661261724224843, -8.693008246289224],
              [-38.661228291049113, -8.693015380410658],
              [-38.661194820381304, -8.693022338303082],
              [-38.661161313159646, -8.693029119771479],
              [-38.661127770323411, -8.693035724625766],
              [-38.661094192812854, -8.693042152680777],
              [-38.661060581569231, -8.693048403756356],
              [-38.6610269375347, -8.693054477677256],
              [-38.660993261652386, -8.693060374273218],
              [-38.660959554866281, -8.693066093378944],
              [-38.660925818121228, -8.6930716348341],
              [-38.660892052362954, -8.693076998483404],
              [-38.660858258537942, -8.693082184176458],
              [-38.660824437593526, -8.693087191767946],
              [-38.660790590477738, -8.693092021117431],
              [-38.660756718139396, -8.693096672089595],
              [-38.660722821528005, -8.693101144554047],
              [-38.660688901593737, -8.693105438385425],
              [-38.66065495928742, -8.693109553463351],
              [-38.660620995560528, -8.693113489672488],
              [-38.660587011365138, -8.693117246902501],
              [-38.660553007653874, -8.693120825048045],
              [-38.660518985379916, -8.693124224008853],
              [-38.660484945496982, -8.693127443689651],
              [-38.660450888959282, -8.693130484000129],
              [-38.660416816721451, -8.693133344855124],
              [-38.660382729738629, -8.693136026174434],
              [-38.660348628966304, -8.693138527882864],
              [-38.660314515360419, -8.693140849910334],
              [-38.660280389877208, -8.693142992191724],
              [-38.660246253473296, -8.693144954667007],
              [-38.660212107105565, -8.693146737281154],
              [-38.660177951731221, -8.693148339984189],
              [-38.660143788307693, -8.693149762731208],
              [-38.660109617792635, -8.693151005482344],
              [-38.660075441143924, -8.693152068202714],
              [-38.660041259319598, -8.693152950862567],
              [-38.660007073277804, -8.693153653437159],
              [-38.659972883976877, -8.6931541759068],
              [-38.659938692375192, -8.693154518256824],
              [-38.659904499431192, -8.693154680477658],
              [-38.659870306103379, -8.693154662564737],
              [-38.659836113350245, -8.693154464518603],
              [-38.659801922130285, -8.693154086344752],
              [-38.659767733401942, -8.693153528053823],
              [-38.659733548123583, -8.693152789661465],
              [-38.659699367253474, -8.693151871188364],
              [-38.659665191749788, -8.693150772660305],
              [-38.659631022570508, -8.693149494108058],
              [-38.659596860673467, -8.693148035567464],
              [-38.659562707016306, -8.693146397079396],
              [-38.659528562556375, -8.693144578689797],
              [-38.659494428250817, -8.693142580449683],
              [-38.659460305056491, -8.693140402415002],
              [-38.659426193929924, -8.69313804464687],
              [-38.659392095827315, -8.693135507211341],
              [-38.659358011704498, -8.693132790179552],
              [-38.659323942516906, -8.693129893627685],
              [-38.659289889219551, -8.693126817636912],
              [-38.659255852767032, -8.693123562293485],
              [-38.659221834113438, -8.693120127688646],
              [-38.659187834212375, -8.693116513918664],
              [-38.659153854016914, -8.693112721084859],
              [-38.659119894479588, -8.693108749293565],
              [-38.659085956552353, -8.693104598656106],
              [-38.65905204118652, -8.693100269288818],
              [-38.659018149332837, -8.693095761313071],
              [-38.658984281941329, -8.693091074855239],
              [-38.658950439961345, -8.693086210046715],
              [-38.658916624341565, -8.693081167023836],
              [-38.658882836029882, -8.693075945927996],
              [-38.65884907597345, -8.693070546905506],
              [-38.658815345118619, -8.693064970107766],
              [-38.658781644410915, -8.693059215691058],
              [-38.658747974795062, -8.69305328381675],
              [-38.658714337214832, -8.693047174651104],
              [-38.658680732613163, -8.693040888365321],
              [-38.658647161932073, -8.693034425135679],
              [-38.658613626112576, -8.693027785143336],
              [-38.658580126094733, -8.6930209685744],
              [-38.658546662817642, -8.693013975620008],
              [-38.658513237219317, -8.693006806476122],
              [-38.658479850236738, -8.69299946134376],
              [-38.658446502805795, -8.692991940428799],
              [-38.658413195861257, -8.692984243942075],
              [-38.6583799303368, -8.692976372099317],
              [-38.65834670716491, -8.692968325121202],
              [-38.658313527276889, -8.692960103233291],
              [-38.658280391602695, -8.692951706666189],
              [-38.658266107541664, -8.692957907003251],
              [-38.658251821583143, -8.692964103011033],
              [-38.658237533728446, -8.692970294688976],
              [-38.65822324397891, -8.692976482036501],
              [-38.658208952335848, -8.692982665053037],
              [-38.658194658800618, -8.692988843737993],
              [-38.658180363374512, -8.692995018090819],
              [-38.658166066058875, -8.693001188110928],
              [-38.658151766855021, -8.693007353797753],
              [-38.658137465764284, -8.693013515150703],
              [-38.658123162787994, -8.693019672169264],
              [-38.658108857927466, -8.693025824852803],
              [-38.658094551184028, -8.693031973200775],
              [-38.658080242559024, -8.693038117212579],
              [-38.658065932053781, -8.693044256887696],
              [-38.6580516196696, -8.69305039222553],
              [-38.658037305407838, -8.693056523225518],
              [-38.658022989269817, -8.693062649887057],
              [-38.65800867125683, -8.69306877220963],
              [-38.657994351370256, -8.693074890192651],
              [-38.657980029611409, -8.693081003835516],
              [-38.657965705981589, -8.693087113137704],
              [-38.65795138048216, -8.693093218098644],
              [-38.657937053114438, -8.693099318717749],
              [-38.657922723879764, -8.693105414994495],
              [-38.657908392779426, -8.693111506928245],
              [-38.657894059814787, -8.693117594518478],
              [-38.657879724987197, -8.693123677764616],
              [-38.657865388297957, -8.693129756666146],
              [-38.657851049748388, -8.693135831222461],
              [-38.65783670933984, -8.693141901432965],
              [-38.657822367073621, -8.693147967297129],
              [-38.657808022951109, -8.693154028814394],
              [-38.657793676973576, -8.693160085984207],
              [-38.657779329142393, -8.693166138805976],
              [-38.657764979458875, -8.693172187279165],
              [-38.657750627924365, -8.693178231403206],
              [-38.65773627454017, -8.693184271177529],
              [-38.657721919307647, -8.693190306601615],
              [-38.657707562228119, -8.693196337674831],
              [-38.657693203302919, -8.693202364396642],
              [-38.657678842533386, -8.693208386766528],
              [-38.657664479920832, -8.693214404783911],
              [-38.657650115466602, -8.693220418448204],
              [-38.657635749172037, -8.693226427758892],
              [-38.657621381038453, -8.693232432715385],
              [-38.657607011067185, -8.693238433317141],
              [-38.65759263925959, -8.69324442956362],
              [-38.657578265616976, -8.693250421454222],
              [-38.657563890140679, -8.693256408988425],
              [-38.657549512832048, -8.693262392165655],
              [-38.657535133692399, -8.693268370985388],
              [-38.657520752723073, -8.693274345447044],
              [-38.657506369925414, -8.693280315550037],
              [-38.657491985300744, -8.693286281293863],
              [-38.657477598850406, -8.693292242677959],
              [-38.657463210575706, -8.693298199701745],
              [-38.657448820478024, -8.693304152364732],
              [-38.657434428558659, -8.693310100666288],
              [-38.657420034818969, -8.693316044605892],
              [-38.65740563926029, -8.69332198418301],
              [-38.657391241883929, -8.693327919397117],
              [-38.657376842691257, -8.693333850247576],
              [-38.657362441683595, -8.693339776733898],
              [-38.657348038862281, -8.693345698855525],
              [-38.657333634228642, -8.693351616611897],
              [-38.657319227784008, -8.693357530002498],
              [-38.657304819529742, -8.693363439026744],
              [-38.657290409467166, -8.693369343684076],
              [-38.657275997597608, -8.693375243973975],
              [-38.65726158392242, -8.693381139895886],
              [-38.657247168442943, -8.693387031449294],
              [-38.657232751160493, -8.693392918633585],
              [-38.657218332076432, -8.693398801448238],
              [-38.657203911192084, -8.693404679892719],
              [-38.657189488508791, -8.693410553966489],
              [-38.657175064027882, -8.693416423669008],
              [-38.657160637750707, -8.693422288999709],
              [-38.657146209678594, -8.693428149958047],
              [-38.657131779812893, -8.693434006543471],
              [-38.657117348154934, -8.693439858755493],
              [-38.657102914706073, -8.693445706593508],
              [-38.657088479467603, -8.693451550056993],
              [-38.657074042440925, -8.693457389145394],
              [-38.657059603627346, -8.693463223858206],
              [-38.657045163028194, -8.693469054194843],
              [-38.657030720644826, -8.693474880154815],
              [-38.657016276478593, -8.693480701737549],
              [-38.657001830530803, -8.693486518942493],
              [-38.656987382802811, -8.693492331769125],
              [-38.656972933295975, -8.693498140216938],
              [-38.656958482011611, -8.693503944285334],
              [-38.656944028951074, -8.693509743973797],
              [-38.656929574115708, -8.693515539281798],
              [-38.656915117506841, -8.693521330208794],
              [-38.65690065912581, -8.693527116754252],
              [-38.656886198973986, -8.693532898917645],
              [-38.656871737052661, -8.693538676698402],
              [-38.656857273363187, -8.693544450096089],
              [-38.656832898074178, -8.693554168343656],
              [-38.656808517775289, -8.693563874142404],
              [-38.656784132472943, -8.693573567489793],
              [-38.656759742173563, -8.693583248383259],
              [-38.656735346883579, -8.693592916820252],
              [-38.656710946609415, -8.693602572798245],
              [-38.656686541357509, -8.693612216314653],
              [-38.656662131134269, -8.693621847366977],
              [-38.65663771594614, -8.693631465952645],
              [-38.656613295799538, -8.693641072069148],
              [-38.656588870700915, -8.693650665713955],
              [-38.656564440656695, -8.693660246884514],
              [-38.656540005673307, -8.693669815578346],
              [-38.656515565757189, -8.693679371792873],
              [-38.656491120914808, -8.693688915525611],
              [-38.656466671152565, -8.693698446774089],
              [-38.656442216476911, -8.693707965535712],
              [-38.656417756894299, -8.693717471808011],
              [-38.656393292411167, -8.693726965588498],
              [-38.65636882303393, -8.69373644687467],
              [-38.656344348769089, -8.693745915664016],
              [-38.656319869623047, -8.693755371954017],
              [-38.656295385602263, -8.693764815742215],
              [-38.656270896713195, -8.693774247026095],
              [-38.656246402962303, -8.693783665803215],
              [-38.656221904356002, -8.693793072071097],
              [-38.656197400900773, -8.693802465827217],
              [-38.656172892603067, -8.693811847069107],
              [-38.656148379469336, -8.693821215794314],
              [-38.656123861506046, -8.693830572000371],
              [-38.656099338719649, -8.693839915684803],
              [-38.656074811116589, -8.693849246845179],
              [-38.656050278703361, -8.693858565478996],
              [-38.656025741486388, -8.693867871583832],
              [-38.656001199472179, -8.69387716515724],
              [-38.655976652667157, -8.69388644619673],
              [-38.655952101077794, -8.69389571469989],
              [-38.655927544710572, -8.693904970664271],
              [-38.65590298357197, -8.693914214087428],
              [-38.655878417668433, -8.693923444966934],
              [-38.655853847006419, -8.693932663300348],
              [-38.655829271592452, -8.693941869085277],
              [-38.655804691432969, -8.693951062319243],
              [-38.655780106534444, -8.693960242999886],
              [-38.655755516903383, -8.693969411124714],
              [-38.655730922546212, -8.693978566691346],
              [-38.655706323469452, -8.693987709697415],
              [-38.655681719679571, -8.693996840140434],
              [-38.655657111183025, -8.694005958018062],
              [-38.655632497986339, -8.694015063327864],
              [-38.655607880095985, -8.694024156067458],
              [-38.655583257518423, -8.694033236234448],
              [-38.65555863026016, -8.694042303826416],
              [-38.655533998327677, -8.694051358841008],
              [-38.655509361727461, -8.69406040127582],
              [-38.655484720466021, -8.694069431128451],
              [-38.655460074549815, -8.694078448396573],
              [-38.655435423985345, -8.694087453077756],
              [-38.65541076877912, -8.694096445169659],
              [-38.65538610893762, -8.694105424669933],
              [-38.655361444467346, -8.694114391576166],
              [-38.655336775374806, -8.694123345886034],
              [-38.655312101666475, -8.694132287597153],
              [-38.655287423348867, -8.694141216707193],
              [-38.655262740428469, -8.69415013321378],
              [-38.655238052911812, -8.694159037114565],
              [-38.655213360805384, -8.694167928407225],
              [-38.65518866411567, -8.694176807089356],
              [-38.655163962849194, -8.69418567315871],
              [-38.655139257012465, -8.694194526612893],
              [-38.655114546611983, -8.694203367449553],
              [-38.655089831654266, -8.694212195666426],
              [-38.655065112145799, -8.694221011261169],
              [-38.655040388093127, -8.694229814231411],
              [-38.655015659502745, -8.694238604574874],
              [-38.654990926381181, -8.694247382289246],
              [-38.654966188734932, -8.694256147372228],
              [-38.654941446570511, -8.694264899821466],
              [-38.65491669989445, -8.694273639634691],
              [-38.654891948713271, -8.694282366809563],
              [-38.654867193033482, -8.694291081343838],
              [-38.654842432861606, -8.694299783235152],
              [-38.654817668204181, -8.694308472481278],
              [-38.654792899067708, -8.694317149079907],
              [-38.654768125458709, -8.694325813028719],
              [-38.654743347383722, -8.694334464325468],
              [-38.654718564849283, -8.694343102967897],
              [-38.654693777861908, -8.69435172895367],
              [-38.654668986428135, -8.694360342280532],
              [-38.654644190554478, -8.694368942946269],
              [-38.654619390247476, -8.69437753094854],
              [-38.654594585513678, -8.694386106285112],
              [-38.654569776359594, -8.694394668953732],
              [-38.654544962791782, -8.69440321895218],
              [-38.654520144816743, -8.694411756278136],
              [-38.654495322441051, -8.694420280929384],
              [-38.654470495671241, -8.69442879290367],
              [-38.654445664513823, -8.694437292198762],
              [-38.654420828975418, -8.694445778811557],
              [-38.654411216486658, -8.694449030516838],
              [-38.654401585638432, -8.694452227984934],
              [-38.654391936739835, -8.694455371113266],
              [-38.654382270100541, -8.694458459800959],
              [-38.654372586030789, -8.694461493948841],
              [-38.654362884841383, -8.694464473459547],
              [-38.654353166843691, -8.69446739823749],
              [-38.654343432349599, -8.694470268188773],
              [-38.654333681671538, -8.694473083221276],
              [-38.654323915122433, -8.69447584324466],
              [-38.654314133015717, -8.694478548170334],
              [-38.654304335665394, -8.694481197911502],
              [-38.654294523385872, -8.69448379238314],
              [-38.65428469649207, -8.694486331501913],
              [-38.65427485529937, -8.694488815186409],
              [-38.654265000123651, -8.694491243356875],
              [-38.654255131281168, -8.694493615935386],
              [-38.654245249088675, -8.694495932845792],
              [-38.654235353863342, -8.694498194013732],
              [-38.654225445922741, -8.69450039936665],
              [-38.654215525584867, -8.694502548833745],
              [-38.654205593168101, -8.694504642346054],
              [-38.654195648991227, -8.694506679836346],
              [-38.654185693373385, -8.694508661239302],
              [-38.654175726634094, -8.69451058649126],
              [-38.654165749093252, -8.694512455530473],
              [-38.654155761071046, -8.694514268296945],
              [-38.654145762888078, -8.694516024732485],
              [-38.654135754865202, -8.694517724780729],
              [-38.654125737323625, -8.694519368387114],
              [-38.654115710584868, -8.6945209554989],
              [-38.654105674970729, -8.694522486065139],
              [-38.65409563080329, -8.69452396003671],
              [-38.654085578404896, -8.694525377366295],
              [-38.654075518098217, -8.694526738008443],
              [-38.654065450206076, -8.694528041919458],
              [-38.654055375051648, -8.694529289057497],
              [-38.654045292958266, -8.694530479382518],
              [-38.65403520424951, -8.69453161285632],
              [-38.654025109249169, -8.694532689442569],
              [-38.654015008281227, -8.694533709106633],
              [-38.654004901669893, -8.69453467181587],
              [-38.653994789739514, -8.69453557753935],
              [-38.653984672814644, -8.694536426247991],
              [-38.65397455121996, -8.694537217914542],
              [-38.653964425280328, -8.694537952513633],
              [-38.653954295320702, -8.694538630021674],
              [-38.653944161666246, -8.694539250416939],
              [-38.65393402464214, -8.694539813679439],
              [-38.653923884573771, -8.694540319791205],
              [-38.653913741786553, -8.694540768735937],
              [-38.653903596606021, -8.694541160499243],
              [-38.653893449357788, -8.694541495068512],
              [-38.65388330036749, -8.694541772433052],
              [-38.653873149960901, -8.694541992583895],
              [-38.653862998463751, -8.694542155514059],
              [-38.653852846201879, -8.694542261218281],
              [-38.653842693501097, -8.694542309693189],
              [-38.653832540687262, -8.694542300937199],
              [-38.653822388086191, -8.694542234950578],
              [-38.653812236023761, -8.694542111735476],
              [-38.653802084825799, -8.69454193129585],
              [-38.653791934818074, -8.694541693637479],
              [-38.653781786326363, -8.69454139876798],
              [-38.653771639676371, -8.694541046696809],
              [-38.653761495193748, -8.694540637435303],
              [-38.653751353204065, -8.694540170996572],
              [-38.653741214032863, -8.694539647395612],
              [-38.653731078005507, -8.694539066649188],
              [-38.653720945447333, -8.694538428775948],
              [-38.653710816683514, -8.694537733796391],
              [-38.653700692039173, -8.694536981732828],
              [-38.653690571839192, -8.694536172609343],
              [-38.653680456408416, -8.6945353064519],
              [-38.653670346071507, -8.694534383288381],
              [-38.653660241152906, -8.694533403148368],
              [-38.653650141976961, -8.694532366063308],
              [-38.653640048867771, -8.694531272066502],
              [-38.653629962149303, -8.694530121193045],
              [-38.653619882145243, -8.694528913479907],
              [-38.653609809179116, -8.694527648965799],
              [-38.653599743574226, -8.694526327691356],
              [-38.653589685653614, -8.694524949698945],
              [-38.653579635740087, -8.694523515032806],
              [-38.653569594156181, -8.694522023738982],
              [-38.653559561224171, -8.694520475865325],
              [-38.653549537266059, -8.694518871461531],
              [-38.653539522603566, -8.694517210579106],
              [-38.653529517558127, -8.694515493271313],
              [-38.6535195224508, -8.694513719593305],
              [-38.65350953760241, -8.694511889601998],
              [-38.653499563333398, -8.694510003356111],
              [-38.653489599963883, -8.694508060916199],
              [-38.65347964781364, -8.694506062344608],
              [-38.653469707202071, -8.694504007705449],
              [-38.653459778448223, -8.694501897064702],
              [-38.653449861870747, -8.694499730490064],
              [-38.653439957787903, -8.694497508051127],
              [-38.653430066517018, -8.694495229819776],
              [-38.653412563341355, -8.69449123828905],
              [-38.653395020850688, -8.694487421460162],
              [-38.653377440806167, -8.694483779716357],
              [-38.653359824972732, -8.694480313423224],
              [-38.653342175118944, -8.694477022928789],
              [-38.653324493016747, -8.694473908563351],
              [-38.653306780441341, -8.694470970639573],
              [-38.653289039170964, -8.694468209452486],
              [-38.653271270986764, -8.694465625279198],
              [-38.653253477672557, -8.694463218379218],
              [-38.653235661014733, -8.694460988994152],
              [-38.653217822801963, -8.694458937347809],
              [-38.653199964825127, -8.694457063646198],
              [-38.65318208887706, -8.694455368077417],
              [-38.653164196752421, -8.694453850811655],
              [-38.653146290247491, -8.694452512001288],
              [-38.653128371159987, -8.694451351780675],
              [-38.653110441288909, -8.694450370266333],
              [-38.653092502434276, -8.694449567556781],
              [-38.653074556397122, -8.694448943732615],
              [-38.653056604979078, -8.694448498856483],
              [-38.653038649982392, -8.69444823297302],
              [-38.65302069320969, -8.694448146108899],
              [-38.653002736463677, -8.694448238272845],
              [-38.652984781547154, -8.694448509455658],
              [-38.652966830262692, -8.694448959630074],
              [-38.652948884412517, -8.694449588750878],
              [-38.652930945798282, -8.694450396754949],
              [-38.65291301622095, -8.694451383561157],
              [-38.652895097480538, -8.694452549070393],
              [-38.652877191376014, -8.694453893165701],
              [-38.652859299705028, -8.694455415712099],
              [-38.652841424263848, -8.694457116556743],
              [-38.65282356684704, -8.694458995528894],
              [-38.652805729247433, -8.69446105243987],
              [-38.6527879132558, -8.694463287083193],
              [-38.652770120660783, -8.694465699234515],
              [-38.652752353248658, -8.694468288651668],
              [-38.652734612803208, -8.694471055074699],
              [-38.652716901105471, -8.694473998225845],
              [-38.652699219933602, -8.694477117809631],
              [-38.652681571062693, -8.694480413512872],
              [-38.652663956264611, -8.694483885004699],
              [-38.652646377307804, -8.69448753193659],
              [-38.652628835957088, -8.694491353942423],
              [-38.652611333973525, -8.694495350638459],
              [-38.652593873114242, -8.694499521623484],
              [-38.652576455132191, -8.69450386647871],
              [-38.65255908177609, -8.694508384768],
              [-38.652541754790114, -8.694513076037653],
              [-38.652524475913793, -8.694517939816768],
              [-38.652507246881846, -8.694522975617016],
              [-38.652490069423997, -8.694528182932816],
              [-38.652472945264762, -8.694533561241391],
              [-38.652455876123312, -8.694539110002793],
              [-38.652438863713314, -8.694544828659936],
              [-38.652421909742735, -8.694550716638672],
              [-38.652405015913665, -8.69455677334793],
              [-38.652388183922156, -8.694562998179638],
              [-38.652371415458049, -8.69456939050878],
              [-38.652354712204847, -8.694575949693718],
              [-38.652338075839431, -8.694582675075852],
              [-38.652321508032038, -8.694589565980026],
              [-38.652305010445986, -8.69459662171441],
              [-38.652288584737555, -8.69460384157065],
              [-38.652272232555802, -8.694611224823916],
              [-38.652255955542422, -8.694618770732959],
              [-38.652239755331522, -8.694626478540195],
              [-38.652223633549539, -8.694634347471816],
              [-38.652207591815035, -8.694642376737793],
              [-38.652191631738503, -8.694650565532065],
              [-38.652175754922283, -8.694658913032535],
              [-38.652159962960305, -8.694667418401071],
              [-38.652144257438032, -8.694676080783847],
              [-38.652128639932187, -8.694684899311191],
              [-38.652113112010746, -8.694693873097735],
              [-38.652097675232604, -8.694703001242553],
              [-38.65208233114754, -8.694712282829295],
              [-38.65206708129606, -8.694721716926049],
              [-38.652051927209158, -8.694731302585742],
              [-38.652036870408239, -8.694741038845983],
              [-38.652021912404933, -8.694750924729325],
              [-38.652007054700967, -8.694760959243261],
              [-38.651992298787995, -8.694771141380363],
              [-38.651977646147415, -8.694781470118405],
              [-38.651963098250306, -8.69479194442045],
              [-38.651948656557202, -8.694802563234871],
              [-38.651934322518024, -8.694813325495673],
              [-38.651920097571804, -8.694824230122311],
              [-38.651905983146676, -8.694835276020076],
              [-38.651891980659677, -8.694846462079957],
              [-38.651878091516593, -8.694857787178938],
              [-38.651864317111837, -8.694869250180053],
              [-38.651850658828302, -8.694880849932447],
              [-38.651837118037243, -8.694892585271589],
              [-38.651823696098049, -8.69490445501928],
              [-38.651810394358257, -8.694916457983869],
              [-38.651797214153333, -8.694928592960302],
              [-38.651784156806883, -8.694940858729121],
              [-38.651764731948575, -8.694959395664117],
              [-38.651745413796711, -8.694978042675235],
              [-38.651726202981287, -8.69499679915427],
              [-38.651707100128867, -8.695015664489565],
              [-38.651688105862455, -8.695034638065813],
              [-38.651669220801558, -8.69505371926428],
              [-38.651650445562069, -8.695072907462663],
              [-38.651631780756318, -8.69509220203512],
              [-38.651613226993035, -8.695111602352425],
              [-38.651594784877361, -8.695131107781915],
              [-38.651576455010733, -8.695150717687422],
              [-38.651558237990983, -8.695170431429419],
              [-38.651540134412208, -8.695190248364957],
              [-38.651522144864892, -8.695210167847749],
              [-38.6515042699357, -8.695230189228145],
              [-38.651486510207633, -8.695250311853206],
              [-38.651468866259904, -8.69527053506669],
              [-38.651451338667947, -8.695290858209026],
              [-38.651433928003406, -8.695311280617396],
              [-38.651416634834121, -8.695331801625803],
              [-38.651399459724111, -8.695352420565003],
              [-38.651382403233505, -8.695373136762472],
              [-38.651365465918587, -8.695393949542662],
              [-38.651348648331783, -8.695414858226764],
              [-38.651331951021554, -8.695435862132889],
              [-38.651315374532487, -8.695456960576037],
              [-38.651298919405214, -8.695478152868116],
              [-38.651282586176386, -8.695499438317981],
              [-38.651266375378732, -8.695520816231454],
              [-38.651250287540954, -8.69554228591133],
              [-38.651234323187737, -8.695563846657393],
              [-38.651218482839752, -8.695585497766485],
              [-38.651202767013629, -8.695607238532521],
              [-38.651187176221917, -8.695629068246456],
              [-38.651171710973124, -8.695650986196297],
              [-38.65115637177162, -8.695672991667301],
              [-38.651141159117692, -8.69569508394177],
              [-38.65112607350752, -8.695717262299191],
              [-38.651111115433096, -8.695739526016279],
              [-38.65109628538228, -8.695761874366948],
              [-38.651081583838724, -8.695784306622308],
              [-38.651067011281945, -8.695806822050793],
              [-38.651052568187218, -8.695829419918091],
              [-38.651038255025604, -8.695852099487238],
              [-38.651024072263901, -8.695874860018561],
              [-38.651010020364701, -8.695897700769736],
              [-38.650996099786305, -8.695920620995903],
              [-38.65098231098272, -8.695943619949547],
              [-38.650968654403677, -8.695966696880582],
              [-38.650955130494594, -8.695989851036389],
              [-38.650941739696528, -8.696013081661825],
              [-38.650928482446247, -8.696036387999298],
              [-38.650915359176125, -8.696059769288681],
              [-38.650902370314171, -8.696083224767477],
              [-38.650889516284039, -8.696106753670666],
              [-38.650876797504949, -8.696130355230924],
              [-38.650864214391738, -8.696154028678487],
              [-38.650851767354801, -8.696177773241356],
              [-38.650839456800114, -8.696201588145069],
              [-38.650827283129189, -8.696225472612991],
              [-38.650815246739064, -8.696249425866139],
              [-38.650803348022301, -8.696273447123341],
              [-38.650791587367017, -8.696297535601181],
              [-38.650779965156765, -8.696321690514031],
              [-38.650768481770641, -8.696345911074129],
              [-38.650757137583149, -8.696370196491573],
              [-38.650745932964298, -8.696394545974314],
              [-38.650734868279528, -8.696418958728236],
              [-38.650723943889751, -8.696443433957164],
              [-38.650713160151255, -8.696467970862882],
              [-38.650702517415752, -8.696492568645152],
              [-38.650692016030384, -8.696517226501747],
              [-38.650681656337653, -8.696541943628485],
              [-38.650671438675452, -8.696566719219293],
              [-38.650661363377054, -8.696591552466094],
              [-38.650651430771063, -8.696616442559048],
              [-38.650641641181458, -8.696641388686354],
              [-38.650631994927501, -8.696666390034482],
              [-38.650622492323876, -8.696691445788023],
              [-38.650613133680473, -8.696716555129814],
              [-38.650603919302569, -8.696741717240975],
              [-38.650594849490695, -8.696766931300854],
              [-38.650585924540671, -8.696792196487158],
              [-38.650577144743622, -8.696817511975897],
              [-38.650568510385881, -8.696842876941446],
              [-38.650560021749108, -8.696868290556537],
              [-38.650551679110158, -8.696893751992365],
              [-38.650543482741142, -8.696919260418547],
              [-38.650535432909408, -8.696944815003157],
              [-38.650527529877508, -8.696970414912798],
              [-38.650519773903234, -8.696996059312498],
              [-38.650512165239562, -8.697021747365952],
              [-38.650504704134661, -8.697047478235396],
              [-38.650497390831895, -8.697073251081616],
              [-38.650490225569811, -8.69709906506406],
              [-38.650483208582123, -8.69712491934089],
              [-38.650476340097704, -8.697150813068871],
              [-38.650469620340594, -8.69717674540351],
              [-38.650463049529982, -8.697202715499087],
              [-38.650457128857028, -8.697226143703496],
              [-38.650451086965901, -8.69724954124859],
              [-38.650444924016945, -8.69727290751347],
              [-38.650438640173689, -8.697296241877975],
              [-38.650432235602871, -8.697319543722896],
              [-38.650425710474451, -8.697342812429804],
              [-38.65041906496154, -8.697366047381204],
              [-38.650412299240514, -8.697389247960459],
              [-38.650405413490901, -8.697412413551854],
              [-38.650398407895395, -8.697435543540594],
              [-38.650391282639916, -8.697458637312891],
              [-38.650384037913518, -8.697481694255835],
              [-38.650376673908454, -8.697504713757514],
              [-38.650369190820143, -8.697527695207061],
              [-38.650361588847126, -8.697550637994546],
              [-38.650353868191154, -8.697573541511121],
              [-38.650346029057104, -8.697596405148927],
              [-38.650338071652968, -8.697619228301219],
              [-38.65032999618991, -8.697642010362307],
              [-38.650321802882246, -8.697664750727579],
              [-38.650313491947372, -8.697687448793516],
              [-38.650305063605835, -8.697710103957775],
              [-38.650296518081291, -8.697732715619107],
              [-38.650287855600503, -8.697755283177415],
              [-38.650279076393353, -8.697777806033784],
              [-38.650270180692786, -8.697800283590496],
              [-38.650261168734893, -8.697822715251034],
              [-38.650252040758787, -8.697845100420103],
              [-38.650242797006712, -8.697867438503597],
              [-38.650233437723969, -8.697889728908722],
              [-38.650223963158901, -8.697911971043888],
              [-38.650214373562939, -8.697934164318852],
              [-38.650204669190579, -8.697956308144624],
              [-38.6501948502993, -8.697978401933527],
              [-38.650184917149716, -8.698000445099222],
              [-38.65017487000538, -8.698022437056695],
              [-38.650164709132916, -8.698044377222345],
              [-38.650154434801983, -8.698066265013859],
              [-38.65014404728521, -8.698088099850407],
              [-38.65013354685825, -8.698109881152492],
              [-38.650122933799764, -8.698131608342086],
              [-38.650112208391391, -8.698153280842565],
              [-38.650101370917731, -8.698174898078779],
              [-38.650090421666391, -8.698196459477018],
              [-38.65007936092794, -8.698217964465098],
              [-38.650068188995888, -8.698239412472271],
              [-38.650056906166697, -8.698260802929338],
              [-38.650045512739808, -8.698282135268617],
              [-38.650034009017538, -8.698303408923994],
              [-38.650022395305193, -8.698324623330898],
              [-38.650010671910955, -8.69834577792631],
              [-38.64999883914593, -8.698366872148807],
              [-38.649986897324126, -8.69838790543856],
              [-38.64997484676244, -8.698408877237418],
              [-38.6499626877807, -8.698429786988774],
              [-38.649950420701515, -8.698450634137712],
              [-38.649938045850476, -8.698471418130991],
              [-38.649925563555946, -8.698492138417036],
              [-38.649912974149174, -8.698512794445943],
              [-38.649900277964278, -8.698533385669531],
              [-38.649887475338154, -8.698553911541318],
              [-38.649874566610563, -8.698574371516584],
              [-38.649861552124065, -8.698594765052308],
              [-38.649848432224047, -8.698615091607341],
              [-38.649835207258668, -8.698635350642192],
              [-38.649821877578887, -8.698655541619225],
              [-38.649808443538461, -8.698675664002591],
              [-38.64979490549387, -8.698695717258246],
              [-38.6497812638044, -8.698715700854013],
              [-38.649767518832064, -8.698735614259565],
              [-38.649753670941621, -8.698755456946412],
              [-38.649739720500563, -8.698775228387943],
              [-38.64972566787911, -8.698794928059447],
              [-38.649711513450185, -8.698814555438151],
              [-38.649697257589416, -8.698834110003116],
              [-38.64968290067511, -8.698853591235416],
              [-38.649668443088288, -8.698872998618048],
              [-38.649653885212601, -8.698892331635943],
              [-38.649639227434434, -8.69891158977601],
              [-38.649624470142705, -8.698930772527186],
              [-38.649609613729083, -8.698949879380384],
              [-38.649594658587816, -8.698968909828528],
              [-38.649579605115797, -8.698987863366533],
              [-38.649564453712486, -8.699006739491448],
              [-38.649549204780001, -8.699025537702289],
              [-38.649533858723011, -8.699044257500203],
              [-38.649518415948755, -8.699062898388327],
              [-38.649502876867061, -8.699081459872005],
              [-38.649487241890313, -8.699099941458599],
              [-38.649471511433433, -8.699118342657648],
              [-38.649455685913878, -8.6991366629808],
              [-38.649439765751616, -8.699154901941879],
              [-38.649423751369149, -8.699173059056827],
              [-38.649407643191466, -8.699191133843771],
              [-38.64939144164606, -8.699209125823],
              [-38.649375147162864, -8.699227034517044],
              [-38.64935876017433, -8.69924485945063],
              [-38.649342281115331, -8.699262600150666],
              [-38.649325710423597, -8.699280256144869],
              [-38.649318688471581, -8.699287584206829],
              [-38.649311599216745, -8.699294847830769],
              [-38.649304443256327, -8.699302046404767],
              [-38.649297221193187, -8.699309179322402],
              [-38.649289933635778, -8.699316245982661],
              [-38.649282581198044, -8.699323245790239],
              [-38.649275164499407, -8.699330178155439],
              [-38.649267684164712, -8.69933704249418],
              [-38.649260140824126, -8.69934383822824],
              [-38.649252535113177, -8.699350564784984],
              [-38.649244867672621, -8.699357221597802],
              [-38.649237139148404, -8.69936380810587],
              [-38.649229350191661, -8.699370323754248],
              [-38.649221501458562, -8.699376767994048],
              [-38.649213593610348, -8.699383140282324],
              [-38.649205627313229, -8.699389440082271],
              [-38.649197603238356, -8.699395666863115],
              [-38.649189522061725, -8.69940182010027],
              [-38.649181384464143, -8.699407899275334],
              [-38.649173191131197, -8.699413903876199],
              [-38.649164942753131, -8.699419833396922],
              [-38.64915664002487, -8.699425687338001],
              [-38.649148283645879, -8.699431465206233],
              [-38.649139874320184, -8.699437166514894],
              [-38.649131412756212, -8.699442790783591],
              [-38.649122899666857, -8.69944833753855],
              [-38.649114335769312, -8.699453806312459],
              [-38.649105721785055, -8.699459196644559],
              [-38.649097058439807, -8.699464508080759],
              [-38.649088346463415, -8.699469740173582],
              [-38.649079586589849, -8.699474892482236],
              [-38.649070779557107, -8.699479964572641],
              [-38.649061926107144, -8.699484956017489],
              [-38.649053026985861, -8.699489866396283],
              [-38.649044082942964, -8.699494695295297],
              [-38.649035094731993, -8.699499442307747],
              [-38.649026063110149, -8.69950410703367],
              [-38.649016988838341, -8.699508689080101],
              [-38.649007872681047, -8.699513188061022],
              [-38.648998715406293, -8.699517603597396],
              [-38.64898951778553, -8.699521935317236],
              [-38.648980280593648, -8.699526182855569],
              [-38.648971004608875, -8.699530345854573],
              [-38.648961690612659, -8.699534423963522],
              [-38.648952339389695, -8.699538416838868],
              [-38.64894295172779, -8.699542324144179],
              [-38.648933528417857, -8.699546145550322],
              [-38.648924070253749, -8.699549880735303],
              [-38.648914578032318, -8.699553529384502],
              [-38.648905052553253, -8.699557091190492],
              [-38.648895494619062, -8.69956056585319],
              [-38.648885905034966, -8.699563953079871],
              [-38.648876284608853, -8.699567252585179],
              [-38.648866634151226, -8.699570464091158],
              [-38.648856954475121, -8.69957358732721],
              [-38.648847246396031, -8.699576622030225],
              [-38.648837510731838, -8.699579567944561],
              [-38.648827748302729, -8.699582424821966],
              [-38.648817959931179, -8.69958519242183],
              [-38.64880814644183, -8.699587870510928],
              [-38.648798308661441, -8.699590458863703],
              [-38.648788447418831, -8.699592957262029],
              [-38.648778563544781, -8.69959536549546],
              [-38.64876865787199, -8.699597683361096],
              [-38.648758731234963, -8.699599910663657],
              [-38.64874878447003, -8.699602047215512],
              [-38.648738818415154, -8.699604092836653],
              [-38.648728833909963, -8.699606047354747],
              [-38.648718831795634, -8.699607910605135],
              [-38.648708812914798, -8.699609682430836],
              [-38.648698778111559, -8.699611362682555],
              [-38.648688728231285, -8.699612951218809],
              [-38.648678664120681, -8.699614447905697],
              [-38.648668586627601, -8.699615852617134],
              [-38.64865849660108, -8.699617165234814],
              [-38.648648394891147, -8.69961838564809],
              [-38.648638282348884, -8.699619513754223],
              [-38.64862815982621, -8.69962054945813],
              [-38.648618028175953, -8.699621492672554],
              [-38.648607888251654, -8.699622343318017],
              [-38.648597740907611, -8.699623101322903],
              [-38.64858758699868, -8.699623766623304],
              [-38.648577427380317, -8.699624339163224],
              [-38.64856726290845, -8.699624818894339],
              [-38.648557094439411, -8.699625205776325],
              [-38.648546922829865, -8.699625499776554],
              [-38.648536748936749, -8.69962570087025],
              [-38.64852657361719, -8.699625809040464],
              [-38.648516397728429, -8.69962582427808],
              [-38.648506222127772, -8.699625746581896],
              [-38.648496047672474, -8.699625575958336],
              [-38.648485875219713, -8.699625312421839],
              [-38.64847570562651, -8.699624955994627],
              [-38.648465539749601, -8.699624506706703],
              [-38.648455378445462, -8.69962396459589],
              [-38.648445222570139, -8.699623329707901],
              [-38.648435072979254, -8.699622602096204],
              [-38.648424930527881, -8.699621781822129],
              [-38.648414796070412, -8.699620868954923],
              [-38.648412806886675, -8.699620642166609],
              [-38.648410818107997, -8.699620411888105],
              [-38.648408829740561, -8.699620178120119],
              [-38.648406841790568, -8.699619940863377],
              [-38.648404854264179, -8.699619700118637],
              [-38.648402867167576, -8.699619455886644],
              [-38.648400880506955, -8.69961920816815],
              [-38.648398894288484, -8.699618956963894],
              [-38.64839690851835, -8.699618702274719],
              [-38.648394923202723, -8.699618444101393],
              [-38.648392938347783, -8.699618182444688],
              [-38.648390953959698, -8.699617917305448],
              [-38.648388970044628, -8.699617648684489],
              [-38.64838698660877, -8.699617376582667],
              [-38.648385003658284, -8.699617101000795],
              [-38.648383021199315, -8.699616821939722],
              [-38.648381039238046, -8.699616539400408],
              [-38.648379057780652, -8.699616253383592],
              [-38.648377076833285, -8.699615963890267],
              [-38.648375096402098, -8.699615670921329],
              [-38.648373116493261, -8.699615374477624],
              [-38.648371137112925, -8.69961507456012],
              [-38.648369158267258, -8.699614771169731],
              [-38.648367179962413, -8.699614464307412],
              [-38.648365202204523, -8.699614153974139],
              [-38.648363224999763, -8.699613840170842],
              [-38.64836124835427, -8.699613522898511],
              [-38.648359272274199, -8.699613202158146],
              [-38.648357296765681, -8.699612877950726],
              [-38.64835532183487, -8.699612550277248],
              [-38.64835334748792, -8.69961221913875],
              [-38.648351373730954, -8.699611884536264],
              [-38.648349400570126, -8.699611546470823],
              [-38.648347428011562, -8.699611204943514],
              [-38.648345456061392, -8.6996108599553],
              [-38.648343484725764, -8.699610511507348],
              [-38.648341514010802, -8.699610159600695],
              [-38.648339543922624, -8.699609804236474],
              [-38.648337574467384, -8.699609445415783],
              [-38.648335605651184, -8.699609083139709],
              [-38.648333637480171, -8.699608717409406],
              [-38.648331669960449, -8.699608348225984],
              [-38.648329703098113, -8.699607975590618],
              [-38.648327736899319, -8.699607599504454],
              [-38.648325771370182, -8.699607219968666],
              [-38.648323806516785, -8.699606836984415],
              [-38.648321842345268, -8.699606450552928],
              [-38.64831987886172, -8.699606060675386],
              [-38.648317916072266, -8.699605667353003],
              [-38.648315953982994, -8.699605270587005],
              [-38.648313992600023, -8.699604870378607],
              [-38.648312031929443, -8.699604466729099],
              [-38.648310071977363, -8.699604059639675],
              [-38.648308112749852, -8.69960364911166],
              [-38.648306154253028, -8.699603235146292],
              [-38.648304196492965, -8.699602817744889],
              [-38.648302239475775, -8.699602396908732],
              [-38.648300283207526, -8.69960197263911],
              [-38.648298327694306, -8.699601544937382],
              [-38.648296372942212, -8.69960111380486],
              [-38.648294418957292, -8.699600679242868],
              [-38.648292465745648, -8.699600241252792],
              [-38.648290513313363, -8.699599799835967],
              [-38.648288561666483, -8.699599354993783],
              [-38.648286610811105, -8.69959890672761],
              [-38.648284660753269, -8.699598455038846],
              [-38.648282711499043, -8.699597999928891],
              [-38.648280763054515, -8.699597541399172],
              [-38.648278815425726, -8.699597079451127],
              [-38.64827686861873, -8.699596614086163],
              [-38.648274922639587, -8.699596145305755],
              [-38.648272977494365, -8.699595673111311],
              [-38.648271033189104, -8.699595197504372],
              [-38.648269089729837, -8.699594718486344],
              [-38.648267147122624, -8.69959423605877],
              [-38.648265205373498, -8.699593750223119],
              [-38.648263264488506, -8.699593260980935],
              [-38.648261324473673, -8.699592768333703],
              [-38.648259385335052, -8.699592272282997],
              [-38.648257447078642, -8.699591772830319],
              [-38.648255509710516, -8.699591269977246],
              [-38.648253573236659, -8.699590763725345],
              [-38.648251637663122, -8.699590254076174],
              [-38.648249702995898, -8.699589741031339],
              [-38.648247769241038, -8.699589224592401],
              [-38.648245836404534, -8.699588704761009],
              [-38.648243904492396, -8.699588181538735],
              [-38.648241973510643, -8.69958765492726],
              [-38.648240043465265, -8.699587124928165],
              [-38.648238114362279, -8.699586591543143],
              [-38.648236186207697, -8.699586054773805],
              [-38.648234259007488, -8.699585514621855],
              [-38.648232332767648, -8.69958497108898],
              [-38.648230407494189, -8.699584424176875],
              [-38.648228483193087, -8.699583873887194],
              [-38.648226559870331, -8.699583320221695],
              [-38.648224637531904, -8.699582763182088],
              [-38.648222716183767, -8.699582202770074],
              [-38.648220795832216, -8.699581638987384],
              [-38.648208133629367, -8.699578072009153],
              [-38.648195429330649, -8.699574656434921],
              [-38.648182684764564, -8.699571392756344],
              [-38.648169901765421, -8.699568281443137],
              [-38.648157082173078, -8.699565322943089],
              [-38.64814422783261, -8.699562517682075],
              [-38.648131340594148, -8.699559866063737],
              [-38.648118422312521, -8.699557368469804],
              [-38.648105474847029, -8.699555025259754],
              [-38.64809250006121, -8.69955283677078],
              [-38.648079499822465, -8.699550803317941],
              [-38.648066476001944, -8.69954892519385],
              [-38.648053430474107, -8.69954720266883],
              [-38.648040365116593, -8.699545635990825],
              [-38.648027281809881, -8.699544225385308],
              [-38.648014182437031, -8.699542971055335],
              [-38.64800106888341, -8.699541873181397],
              [-38.647987943036426, -8.699540931921524],
              [-38.647974806785278, -8.699540147411174],
              [-38.647961662020627, -8.699539519763288],
              [-38.6479485106344, -8.699539049068177],
              [-38.647935354519454, -8.699538735393588],
              [-38.647922195569294, -8.699538578784693],
              [-38.647909035677898, -8.699538579264015],
              [-38.647895876739334, -8.699538736831483],
              [-38.647882720647573, -8.699539051464411],
              [-38.647869569296113, -8.699539523117553],
              [-38.647856424577832, -8.699540151722978],
              [-38.647843288384607, -8.69954093719023],
              [-38.64783016260715, -8.699541879406272],
              [-38.647817049134595, -8.699542978235449],
              [-38.647803949854364, -8.699544233519665],
              [-38.647790866651796, -8.699545645078208],
              [-38.647777801409958, -8.699547212707918],
              [-38.647764756009295, -8.699548936183172],
              [-38.647751732327407, -8.699550815255915],
              [-38.647738732238807, -8.699552849655701],
              [-38.647725757614531, -8.699555039089695],
              [-38.647712810322027, -8.699557383242796],
              [-38.647699892224757, -8.699559881777613],
              [-38.647687005182028, -8.699562534334516],
              [-38.647674151048633, -8.699565340531743],
              [-38.647661331674648, -8.699568299965414],
              [-38.647648548905167, -8.699571412209558],
              [-38.647635804579963, -8.699574676816244],
              [-38.647623100533345, -8.699578093315591],
              [-38.647610438593738, -8.699581661215891],
              [-38.64759782058362, -8.69958538000361],
              [-38.647585248319025, -8.69958924914352],
              [-38.647572723609507, -8.699593268078708],
              [-38.64756024825769, -8.699597436230741],
              [-38.64754782405916, -8.699601752999724],
              [-38.647535452802096, -8.699606217764321],
              [-38.647523136267097, -8.699610829881959],
              [-38.647510876226839, -8.699615588688815],
              [-38.647498674445927, -8.699620493499946],
              [-38.647486532680503, -8.699625543609399],
              [-38.647474452678154, -8.699630738290359],
              [-38.647462436177527, -8.699636076795151],
              [-38.647450484908134, -8.699641558355376],
              [-38.647438600590114, -8.69964718218211],
              [-38.647426784933948, -8.699652947465905],
              [-38.647415039640286, -8.699658853376988],
              [-38.647403366399566, -8.699664899065313],
              [-38.647391766891928, -8.699671083660792],
              [-38.64738024278688, -8.699677406273205],
              [-38.647368795743056, -8.699683865992561],
              [-38.647357427408046, -8.699690461889164],
              [-38.647346139418048, -8.699697193013645],
              [-38.647334933397751, -8.699704058397195],
              [-38.647323810960017, -8.699711057051704],
              [-38.647312773705693, -8.699718187969877],
              [-38.647301823223358, -8.699725450125339],
              [-38.647290961089098, -8.699732842472867],
              [-38.647280188866304, -8.699740363948496],
              [-38.647269508105381, -8.699748013469698],
              [-38.647258920343646, -8.699755789935443],
              [-38.647248427104948, -8.699763692226501],
              [-38.64723802989959, -8.699771719205508],
              [-38.647227730224039, -8.699779869717119],
              [-38.647217529560713, -8.699788142588233],
              [-38.647207429377779, -8.699796536628236],
              [-38.647197431128944, -8.699805050628898],
              [-38.647187536253277, -8.699813683364823],
              [-38.647177746174911, -8.699822433593519],
              [-38.647168062302953, -8.699831300055589],
              [-38.647158486031167, -8.699840281474877],
              [-38.647149018737906, -8.699849376558738],
              [-38.647139661785729, -8.699858583998092],
              [-38.647130416521435, -8.6998679024677],
              [-38.64712128427567, -8.699877330626421],
              [-38.647112266362832, -8.699886867117213],
              [-38.64710336408087, -8.699896510567545],
              [-38.647094578711091, -8.699906259589437],
              [-38.647085911517962, -8.699916112779702],
              [-38.647077363748956, -8.699926068720229],
              [-38.647068936634348, -8.699936125978031],
              [-38.64706063138707, -8.699946283105572],
              [-38.647052449202207, -8.699956538641446],
              [-38.64704471867293, -8.699966478563981],
              [-38.647037116508073, -8.699976516024638],
              [-38.647029643950525, -8.6999866493823],
              [-38.64702230222202, -8.699996876980244],
              [-38.647015092522913, -8.700007197146295],
              [-38.647008016031954, -8.700017608193155],
              [-38.647001073906132, -8.700028108418694],
              [-38.646994267280455, -8.700038696106146],
              [-38.646987597267781, -8.700049369524548],
              [-38.646981064958659, -8.700060126928729],
              [-38.64697467142107, -8.700070966559997],
              [-38.64696841770035, -8.700081886646132],
              [-38.646962304818963, -8.700092885401718],
              [-38.64695633377633, -8.700103961028507],
              [-38.646950505548723, -8.7001151117157],
              [-38.646944821089022, -8.700126335640249],
              [-38.646939281326631, -8.700137630967061],
              [-38.646933887167286, -8.700148995849416],
              [-38.646928639492899, -8.700160428429182],
              [-38.646923539161506, -8.700171926837255],
              [-38.646918587006944, -8.700183489193654],
              [-38.646913783838883, -8.700195113608006],
              [-38.646909130442673, -8.70020679817976],
              [-38.64690462757909, -8.700218540998558],
              [-38.646900275984365, -8.700230340144488],
              [-38.64689607636997, -8.700242193688476],
              [-38.646892029422546, -8.700254099692513],
              [-38.646888135803771, -8.700266056210019],
              [-38.64688439615022, -8.700278061286168],
              [-38.646880811073338, -8.700290112958168],
              [-38.646877381159271, -8.700302209255664],
              [-38.646874106968838, -8.700314348200937],
              [-38.646870989037332, -8.700326527809329],
              [-38.646868027874568, -8.700338746089546],
              [-38.646865223964674, -8.700351001043929],
              [-38.646862577766093, -8.700363290668898],
              [-38.646860089711495, -8.700375612955114],
              [-38.646857760207681, -8.700387965887996],
              [-38.646855589635514, -8.700400347447827],
              [-38.646853578349891, -8.700412755610314],
              [-38.646851726679678, -8.700425188346793],
              [-38.646850034927617, -8.700437643624538],
              [-38.646848503370336, -8.700450119407193],
              [-38.646847132258216, -8.700462613654986],
              [-38.646845921815476, -8.700475124325182],
              [-38.646844872240024, -8.700487649372386],
              [-38.646843983703484, -8.700500186748764],
              [-38.646843256351126, -8.700512734404521],
              [-38.646842690301895, -8.700525290288205],
              [-38.646842285648368, -8.700537852346901],
              [-38.646842042456697, -8.700550418526841],
              [-38.646841960766679, -8.700562986773477],
              [-38.64684204059165, -8.700575555031996],
              [-38.646842281918623, -8.700588121247504],
              [-38.64684268470814, -8.700600683365499],
              [-38.646843248894335, -8.700613239332116],
              [-38.646843974385028, -8.70062578709453],
              [-38.646844861061588, -8.700638324601201],
              [-38.646845908779078, -8.700650849802315],
              [-38.646847117366221, -8.700663360650084],
              [-38.646848486625409, -8.700675855098991],
              [-38.646850016332827, -8.700688331106308],
              [-38.646851706238351, -8.70070078663219],
              [-38.646853556065729, -8.700713219640271],
              [-38.646855565512539, -8.70072562809778],
              [-38.646857734250247, -8.700738009975991],
              [-38.646860061924308, -8.700750363250521],
              [-38.646862548154154, -8.700762685901642],
              [-38.646865192533312, -8.700774975914673],
              [-38.646867994629488, -8.700787231280218],
              [-38.646870953984511, -8.700799449994634],
              [-38.646874070114592, -8.700811630060148],
              [-38.646877342510258, -8.700823769485403],
              [-38.646880770636507, -8.700835866285642],
              [-38.646884353932876, -8.700847918483101],
              [-38.646888091813501, -8.700859924107263],
              [-38.646891983667302, -8.70087188119531],
              [-38.646896028857952, -8.700883787792247],
              [-38.646900226724128, -8.700895641951432],
              [-38.646904576579495, -8.70090744173477],
              [-38.646909077712884, -8.700919185212987],
              [-38.64691372938838, -8.700930870466125],
              [-38.646918530845511, -8.700942495583671],
              [-38.646923481299211, -8.700954058664967],
              [-38.646928579940159, -8.700965557819458],
              [-38.646933825934724, -8.700976991167119],
              [-38.646939218425267, -8.70098835683865],
              [-38.646944756530111, -8.70099965297578],
              [-38.646950439343833, -8.701010877731632],
              [-38.646956265937305, -8.701022029271041],
              [-38.646962235357925, -8.7010331057707],
              [-38.646968346629741, -8.701044105419744],
              [-38.64697459875358, -8.701055026419676],
              [-38.64698099070727, -8.701065866985019],
              [-38.646987521445752, -8.701076625343321],
              [-38.646994189901264, -8.701087299735693],
              [-38.647000994983614, -8.701097888416896],
              [-38.647007935580142, -8.701108389655682],
              [-38.647015010556132, -8.701118801735165],
              [-38.64702298676761, -8.701130560991846],
              [-38.647030791580129, -8.701142433565046],
              [-38.647038423367874, -8.701154416981613],
              [-38.64704588054105, -8.701166508745239],
              [-38.647053161546268, -8.701178706337179],
              [-38.647060264866788, -8.701191007216549],
              [-38.647067189022948, -8.701203408821002],
              [-38.647073932572347, -8.701215908567159],
              [-38.647080494110234, -8.701228503851187],
              [-38.647086872269767, -8.701241192049457],
              [-38.647093065722302, -8.701253970518833],
              [-38.647099073177685, -8.701266836597528],
              [-38.647104893384494, -8.701279787605364],
              [-38.647110525130287, -8.701292820844586],
              [-38.647115967241916, -8.70130593360021],
              [-38.647121218585738, -8.701319123140799],
              [-38.647126278067809, -8.701332386718835],
              [-38.647131144634194, -8.701345721571366],
              [-38.647135817271113, -8.701359124920717],
              [-38.647140295005208, -8.701372593974748],
              [-38.647144576903706, -8.701386125927819],
              [-38.647148662074613, -8.701399717961078],
              [-38.647152549666934, -8.701413367243193],
              [-38.647156238870863, -8.701427070930919],
              [-38.647159728917835, -8.701440826169621],
              [-38.647163019080843, -8.701454630093973],
              [-38.647166108674504, -8.701468479828534],
              [-38.647168997055218, -8.701482372488263],
              [-38.647171683621252, -8.701496305179161],
              [-38.647174167812963, -8.70151027499899],
              [-38.647176449112848, -8.701524279037669],
              [-38.647178527045682, -8.701538314378071],
              [-38.647180401178588, -8.701552378096476],
              [-38.647182071121115, -8.7015664672633],
              [-38.647183536525418, -8.701580578943663],
              [-38.647184797086183, -8.701594710197918],
              [-38.647185852540829, -8.701608858082466],
              [-38.647186702669465, -8.701623019650146],
              [-38.647187347294953, -8.701637191950955],
              [-38.647187786283027, -8.701651372032739],
              [-38.647188019542192, -8.701665556941609],
              [-38.647188047023846, -8.701679743722714],
              [-38.647187868722234, -8.701693929420871],
              [-38.647187484674483, -8.701708111081011],
              [-38.647186894960541, -8.701722285748989],
              [-38.647186099703283, -8.701736450472092],
              [-38.647185099068317, -8.701750602299688],
              [-38.647183893264021, -8.701764738283838],
              [-38.647182482541616, -8.701778855479825],
              [-38.647180867194926, -8.701792950946979],
              [-38.647179047560407, -8.701807021749049],
              [-38.647177024017076, -8.701821064954927],
              [-38.647174796986462, -8.701835077639334],
              [-38.647172366932431, -8.701849056883297],
              [-38.647169734361164, -8.70186299977477],
              [-38.647166899821052, -8.701876903409351],
              [-38.647163863902506, -8.701890764890749],
              [-38.64716062723793, -8.701904581331531],
              [-38.647157190501524, -8.701918349853564],
              [-38.647153554409165, -8.701932067588782],
              [-38.647149719718271, -8.701945731679611],
              [-38.647145687227628, -8.701959339279684],
              [-38.647141457777224, -8.701972887554426],
              [-38.647137032248061, -8.701986373681606],
              [-38.647132411561998, -8.701999794851936],
              [-38.647127596681536, -8.702013148269657],
              [-38.647122588609676, -8.702026431153108],
              [-38.647117388389603, -8.702039640735359],
              [-38.64711199710456, -8.702052774264709],
              [-38.647106415877573, -8.702065829005308],
              [-38.647100645871269, -8.702078802237791],
              [-38.647094688287545, -8.702091691259632],
              [-38.647088544367449, -8.702104493385956],
              [-38.64708221539076, -8.702117205949957],
              [-38.64707570267587, -8.702129826303475],
              [-38.647069007579411, -8.702142351817605],
              [-38.647062131496014, -8.702154779883088],
              [-38.647055075858034, -8.702167107911114],
              [-38.647047842135208, -8.702179333333575],
              [-38.64704043183437, -8.70219145360382],
              [-38.647032846499137, -8.702203466197062],
              [-38.647025087709622, -8.702215368610977],
              [-38.647017157082004, -8.702227158366156],
              [-38.647009056268331, -8.70223883300671],
              [-38.647000786956049, -8.702250390100671],
              [-38.646992350867713, -8.702261827240575],
              [-38.646983749760643, -8.702273142043952],
              [-38.646974985426525, -8.702284332153837],
              [-38.646966059691039, -8.702295395239219],
              [-38.646956974413484, -8.702306328995526],
              [-38.646947731486414, -8.702317131145149],
              [-38.646938332835205, -8.702327799437896],
              [-38.646928780417689, -8.702338331651456],
              [-38.646919076223696, -8.702348725591856],
              [-38.646909222274722, -8.702358979093963],
              [-38.646899220623411, -8.702369090021831],
              [-38.646889073353201, -8.702379056269281],
              [-38.646878782577879, -8.702388875760214],
              [-38.646868350440997, -8.702398546449249],
              [-38.646855708357116, -8.70240996848805],
              [-38.646842938821379, -8.702421249292378],
              [-38.646830043427421, -8.702432387454415],
              [-38.646817023784628, -8.702443381584116],
              [-38.646803881517833, -8.702454230309352],
              [-38.646790618267225, -8.702464932276225],
              [-38.646777235688063, -8.702475486149066],
              [-38.646763735450527, -8.702485890610776],
              [-38.646750119239414, -8.702496144362826],
              [-38.6467363887541, -8.702506246125552],
              [-38.646722545708137, -8.702516194638241],
              [-38.646708591829153, -8.70252598865928],
              [-38.64669452885861, -8.702535626966398],
              [-38.646680358551606, -8.702545108356679],
              [-38.646666082676582, -8.702554431646808],
              [-38.646651703015195, -8.702563595673269],
              [-38.646637221362063, -8.70257259929237],
              [-38.646622639524516, -8.702581441380422],
              [-38.646607959322367, -8.702590120833914],
              [-38.64659318258775, -8.702598636569688],
              [-38.646578311164802, -8.702606987524897],
              [-38.64656334690951, -8.702615172657351],
              [-38.646548291689463, -8.70262319094558],
              [-38.646533147383529, -8.702631041388793],
              [-38.646517915881766, -8.702638723007301],
              [-38.646502599085089, -8.702646234842405],
              [-38.646487198905042, -8.702653575956639],
              [-38.646471717263637, -8.702660745433766],
              [-38.646456156092988, -8.702667742379084],
              [-38.646440517335122, -8.70267456591932],
              [-38.646424802941823, -8.702681215202917],
              [-38.646409014874266, -8.702687689400003],
              [-38.646393155102828, -8.702693987702578],
              [-38.646377225606827, -8.702700109324642],
              [-38.646361228374317, -8.702706053502164],
              [-38.646345165401748, -8.702711819493352],
              [-38.64632903869385, -8.702717406578536],
              [-38.646312850263243, -8.702722814060452],
              [-38.646296602130256, -8.702728041264287],
              [-38.646280296322708, -8.702733087537624],
              [-38.646263934875599, -8.702737952250713],
              [-38.646247519830872, -8.702742634796406],
              [-38.646231053237145, -8.702747134590325],
              [-38.646214537149461, -8.702751451070874],
              [-38.646197973629114, -8.702755583699364],
              [-38.646181364743228, -8.702759531960002],
              [-38.646164712564627, -8.702763295360109],
              [-38.646148019171534, -8.702766873429946],
              [-38.646131286647318, -8.702770265722972],
              [-38.64611451708025, -8.702773471815837],
              [-38.646097712563183, -8.702776491308409],
              [-38.646080875193377, -8.702779323823835],
              [-38.646064007072169, -8.702781969008631],
              [-38.646047110304686, -8.702784426532659],
              [-38.646030186999752, -8.70278669608925],
              [-38.646013239269379, -8.702788777395128],
              [-38.645996269228711, -8.702790670190549],
              [-38.645979278995611, -8.702792374239282],
              [-38.645962270690532, -8.702793889328682],
              [-38.645945246436114, -8.702795215269635],
              [-38.645928208357027, -8.702796351896692],
              [-38.6459111585797, -8.702797299067958],
              [-38.64589409923191, -8.702798056665287],
              [-38.645877032442741, -8.702798624594074],
              [-38.645859960342172, -8.702799002783461],
              [-38.645842885060816, -8.702799191186275],
              [-38.645825808729711, -8.70279918977894],
              [-38.64580873348001, -8.702798998561708],
              [-38.645791661442765, -8.702798617558392],
              [-38.64577459474858, -8.702798046816572],
              [-38.645757535527409, -8.702797286407424],
              [-38.645740485908291, -8.702796336425962],
              [-38.645723448019048, -8.702795196990627],
              [-38.645706423986063, -8.70279386824369],
              [-38.645689415933937, -8.702792350350993],
              [-38.645672425985339, -8.702790643501928],
              [-38.645655456260648, -8.702788747909526],
              [-38.645638508877688, -8.702786663810384],
              [-38.645621585951552, -8.702784391464581],
              [-38.645604689594272, -8.702781931155705],
              [-38.645587821914503, -8.70277928319083],
              [-38.645570985017407, -8.702776447900437],
              [-38.645554181004243, -8.702773425638354],
              [-38.645537411972185, -8.702770216781758],
              [-38.645520680014045, -8.702766821731148],
              [-38.645503987218007, -8.702763240910215],
              [-38.645487335667369, -8.702759474765884],
              [-38.645470727440262, -8.702755523768131],
              [-38.645454164609433, -8.702751388410084],
              [-38.645437649241956, -8.702747069207843],
              [-38.645421183398973, -8.702742566700437],
              [-38.645404769135475, -8.702737881449805],
              [-38.645388408499947, -8.702733014040666],
              [-38.645372103534299, -8.702727965080481],
              [-38.645355856273348, -8.702722735199401],
              [-38.645339668744818, -8.702717325050058],
              [-38.645323542968946, -8.702711735307737],
              [-38.64530748095823, -8.702705966670013],
              [-38.645291484717234, -8.702700019856934],
              [-38.645274503487066, -8.702693713467237],
              [-38.645257448296697, -8.702687607869006],
              [-38.6452403215442, -8.702681703920684],
              [-38.645223125637706, -8.702676002452437],
              [-38.64520586299507, -8.702670504265903],
              [-38.645188536043484, -8.70266521013418],
              [-38.645171147219251, -8.7026601208016],
              [-38.645153698967327, -8.702655236983807],
              [-38.645136193741031, -8.702650559367463],
              [-38.645118634001705, -8.702646088610273],
              [-38.645101022218356, -8.70264182534085],
              [-38.645083360867297, -8.702637770158615],
              [-38.645065652431818, -8.702633923633758],
              [-38.645047899401824, -8.702630286307141],
              [-38.645030104273488, -8.702626858690177],
              [-38.645012269548914, -8.702623641264809],
              [-38.644994397735758, -8.702620634483404],
              [-38.64497649134691, -8.70261783876877],
              [-38.644958552900086, -8.702615254513978],
              [-38.644940584917556, -8.702612882082393],
              [-38.644922589925706, -8.702610721807558],
              [-38.644904570454742, -8.702608773993255],
              [-38.644886529038295, -8.702607038913346],
              [-38.64486846821309, -8.702605516811801],
              [-38.644850390518592, -8.702604207902652],
              [-38.644832298496617, -8.702603112369879],
              [-38.644814194690987, -8.702602230367592],
              [-38.644796081647229, -8.702601562019725],
              [-38.644777961912112, -8.702601107420316],
              [-38.644759838033394, -8.702600866633251],
              [-38.64474171255938, -8.702600839692387],
              [-38.644723588038609, -8.70260102660151],
              [-38.644705467019506, -8.702601427334331],
              [-38.644687352049971, -8.702602041834522],
              [-38.644669245677072, -8.702602870015685],
              [-38.644651150446663, -8.702603911761368],
              [-38.644633068903055, -8.702605166925071],
              [-38.644615003588584, -8.702606635330339],
              [-38.644596957043355, -8.702608316770693],
              [-38.644578931804794, -8.702610211009691],
              [-38.64456093040738, -8.702612317781044],
              [-38.644542955382171, -8.702614636788466],
              [-38.644525009256597, -8.702617167705926],
              [-38.644507094553951, -8.702619910177569],
              [-38.644489213793165, -8.702622863817744],
              [-38.64447136948835, -8.70262602821122],
              [-38.644453564148549, -8.702629402913001],
              [-38.644435800277257, -8.702632987448602],
              [-38.644418080372184, -8.702636781314043],
              [-38.644400406924859, -8.70264078397584],
              [-38.644382782420251, -8.702644994871198],
              [-38.644365209336513, -8.702649413408089],
              [-38.644347690144443, -8.702654038965209],
              [-38.644330227307407, -8.702658870892174],
              [-38.644312823280757, -8.702663908509583],
              [-38.644295480511587, -8.702669151109122],
              [-38.644278201438397, -8.702674597953653],
              [-38.644260988490736, -8.702680248277323],
              [-38.644243844088834, -8.702686101285648],
              [-38.644226770643279, -8.702692156155681],
              [-38.644209770554731, -8.702698412036048],
              [-38.644192846213478, -8.702704868047169],
              [-38.644175999999177, -8.702711523281279],
              [-38.644159234280529, -8.702718376802604],
              [-38.644142551414859, -8.702725427647492],
              [-38.64412595374791, -8.702732674824565],
              [-38.644109443613395, -8.702740117314827],
              [-38.644093023332751, -8.702747754071826],
              [-38.644076695214743, -8.702755584021789],
              [-38.64406046155522, -8.70276360606376],
              [-38.644044324636731, -8.702771819069785],
              [-38.644028286728236, -8.702780221885114],
              [-38.644012350084743, -8.702788813328214],
              [-38.643996516947048, -8.702797592191121],
              [-38.643980789541388, -8.702806557239436],
              [-38.643965170079156, -8.702815707212634],
              [-38.643949660756498, -8.702825040824191],
              [-38.643934263754169, -8.702834556761712],
              [-38.643918981237064, -8.702844253687243],
              [-38.643903815354008, -8.702854130237309],
              [-38.643888768237417, -8.702864185023209],
              [-38.643873842002989, -8.702874416631195],
              [-38.64385903874949, -8.702884823622641],
              [-38.64384436055834, -8.702895404534228],
              [-38.643829809493369, -8.702906157878253],
              [-38.643815387600583, -8.702917082142731],
              [-38.643801096907751, -8.702928175791634],
              [-38.643786939424288, -8.702939437265149],
              [-38.643772917140822, -8.702950864979805],
              [-38.643759032028946, -8.702962457328843],
              [-38.643745286041032, -8.702974212682284],
              [-38.64373168110987, -8.702986129387249],
              [-38.643718219148376, -8.702998205768194],
              [-38.643704902049386, -8.703010440127093],
              [-38.643691731685429, -8.703022830743739],
              [-38.643678709908315, -8.70303537587592],
              [-38.643665838548998, -8.703048073759716],
              [-38.643653119417294, -8.703060922609749],
              [-38.643640554301385, -8.70307392061974],
              [-38.643627694945252, -8.703087541147152],
              [-38.643614997261032, -8.703101311019264],
              [-38.643602463000569, -8.703115228336369],
              [-38.643590093893124, -8.703129291178374],
              [-38.643577891645201, -8.703143497605165],
              [-38.643565857940274, -8.703157845656779],
              [-38.643553994438527, -8.703172333353717],
              [-38.643542302776709, -8.70318695869722],
              [-38.643530784567886, -8.703201719669533],
              [-38.643519441401111, -8.703216614234242],
              [-38.643508274841352, -8.703231640336389],
              [-38.643497286429209, -8.703246795902972],
              [-38.643486477680682, -8.703262078843089],
              [-38.643475850086979, -8.703277487048254],
              [-38.643465405114362, -8.703293018392728],
              [-38.643455144203834, -8.703308670733753],
              [-38.643445068771086, -8.70332444191189],
              [-38.643435180206112, -8.703340329751315],
              [-38.643425479873244, -8.703356332060107],
              [-38.643415969110755, -8.703372446630507],
              [-38.643406649230798, -8.703388671239361],
              [-38.643397521519219, -8.70340500364826],
              [-38.6433885872353, -8.703421441603901],
              [-38.643379847611676, -8.703437982838498],
              [-38.643371303854103, -8.703454625069991],
              [-38.643362957141349, -8.703471366002326],
              [-38.643354808624942, -8.703488203325891],
              [-38.64334685942913, -8.703505134717794],
              [-38.643339110650636, -8.703522157842087],
              [-38.64333156335848, -8.703539270350255],
              [-38.643324218593989, -8.703556469881381],
              [-38.643317077370483, -8.703573754062576],
              [-38.643310140673194, -8.703591120509241],
              [-38.643303409459179, -8.703608566825503],
              [-38.643296884657111, -8.703626090604402],
              [-38.643290567167192, -8.703643689428265],
              [-38.643284457861057, -8.703661360869134],
              [-38.643278557581539, -8.703679102489009],
              [-38.643272867142748, -8.703696911840222],
              [-38.643267387329765, -8.703714786465708],
              [-38.643262118898612, -8.703732723899437],
              [-38.643257062576183, -8.703750721666715],
              [-38.643252219060095, -8.703768777284502],
              [-38.6432475890186, -8.703786888261819],
              [-38.643243173090497, -8.703805052099975],
              [-38.643238971885054, -8.703823266293044],
              [-38.643234985981934, -8.703841528328187],
              [-38.643231215931053, -8.703859835685835],
              [-38.643227662252563, -8.703878185840328],
              [-38.643224325436819, -8.703896576259959],
              [-38.643221205944158, -8.703915004407554],
              [-38.643218304205, -8.703933467740711],
              [-38.643215620619721, -8.703951963712129],
              [-38.643213155558584, -8.7039704897701],
              [-38.643210909361713, -8.703989043358701],
              [-38.643208882339025, -8.704007621918185],
              [-38.643207074770217, -8.704026222885402],
              [-38.643205486904691, -8.704044843694101],
              [-38.643204118961549, -8.704063481775307],
              [-38.643202971129561, -8.704082134557606],
              [-38.643202043567086, -8.704100799467644],
              [-38.643201336402171, -8.704119473930346],
              [-38.643200849732381, -8.704138155369284],
              [-38.643200583624917, -8.704156841207134],
              [-38.643200538116467, -8.704175528865926],
              [-38.64320071321341, -8.704194215767419],
              [-38.643201108891589, -8.704212899333511],
              [-38.643201725096453, -8.704231576986595],
              [-38.643202561743024, -8.704250246149797],
              [-38.643203618715901, -8.704268904247433],
              [-38.643204895869303, -8.704287548705409],
              [-38.64320639302705, -8.704306176951453],
              [-38.643208109982638, -8.704324786415528],
              [-38.643210046499213, -8.704343374530238],
              [-38.64321220230962, -8.704361938731058],
              [-38.643214577116503, -8.704380476456805],
              [-38.643217170592237, -8.704398985149993],
              [-38.64321998237903, -8.704417462257034],
              [-38.643223012089052, -8.704435905228795],
              [-38.643226259304278, -8.704454311520793],
              [-38.643229723576766, -8.704472678593628],
              [-38.643233404428599, -8.704491003913335],
              [-38.643237301352002, -8.704509284951646],
              [-38.64324141380937, -8.704527519186458],
              [-38.643245741233351, -8.704545704102113],
              [-38.643250283026937, -8.704563837189744],
              [-38.643255038563602, -8.7045819159476],
              [-38.643260007187209, -8.704599937881513],
              [-38.643265188212382, -8.704617900505113],
              [-38.643270580924288, -8.704635801340167],
              [-38.643276184579001, -8.704653637916996],
              [-38.643281998403431, -8.704671407774857],
              [-38.643288021595524, -8.704689108462071],
              [-38.643294253324292, -8.704706737536645],
              [-38.643300692730044, -8.704724292566354],
              [-38.64330733892438, -8.704741771129283],
              [-38.643314190990424, -8.704759170813951],
              [-38.643321247982811, -8.704776489219878],
              [-38.643328508928029, -8.704793723957721],
              [-38.643328482205526, -8.704793735319306],
              [-38.643334328567747, -8.704813503437153],
              [-38.643340201856681, -8.704833263651423],
              [-38.643346102061535, -8.704853015925829],
              [-38.643352029171481, -8.704872760224054],
              [-38.643357983175626, -8.704892496509842],
              [-38.643363964063035, -8.704912224746922],
              [-38.643369971822729, -8.704931944899023],
              [-38.643376006443646, -8.704951656929941],
              [-38.643382067914708, -8.704971360803427],
              [-38.643388156224816, -8.704991056483257],
              [-38.643394271362759, -8.705010743933265],
              [-38.643400413317302, -8.705030423117286],
              [-38.643406582077155, -8.70505009399915],
              [-38.643412777631035, -8.705069756542677],
              [-38.643418999967508, -8.705089410711786],
              [-38.64342524907515, -8.705109056470318],
              [-38.643431524942514, -8.705128693782218],
              [-38.643437827558046, -8.705148322611343],
              [-38.643444156910192, -8.705167942921682],
              [-38.643450512987293, -8.705187554677169],
              [-38.643456895777689, -8.705207157841702],
              [-38.643463305269663, -8.70522675237935],
              [-38.643469741451433, -8.705246338254046],
              [-38.643476204311177, -8.705265915429845],
              [-38.643482693837043, -8.70528548387073],
              [-38.64348921001708, -8.705305043540768],
              [-38.643495752839328, -8.705324594403987],
              [-38.643502322291766, -8.705344136424502],
              [-38.643508918362329, -8.705363669566385],
              [-38.643515541038909, -8.705383193793729],
              [-38.643522190309348, -8.705402709070686],
              [-38.643528866161397, -8.705422215361391],
              [-38.643535568582813, -8.705441712629957],
              [-38.643542297561297, -8.705461200840592],
              [-38.64354905308447, -8.705480679957496],
              [-38.643555835139935, -8.705500149944859],
              [-38.643562643715221, -8.705519610766915],
              [-38.643569478797829, -8.705539062387864],
              [-38.643576340375191, -8.705558504771991],
              [-38.643583228434728, -8.705577937883588],
              [-38.643590142963774, -8.705597361686902],
              [-38.643597083949601, -8.705616776146311],
              [-38.643604051379505, -8.705636181226048],
              [-38.643611045240661, -8.705655576890495],
              [-38.643618065520215, -8.705674963104048],
              [-38.643625112205292, -8.705694339831036],
              [-38.643632185282925, -8.705713707035885],
              [-38.643639284740139, -8.705733064682949],
              [-38.643646410563889, -8.705752412736746],
              [-38.643653562741065, -8.705771751161633],
              [-38.643660741258564, -8.705791079922143],
              [-38.643667946103164, -8.70581039898272],
              [-38.643675177261656, -8.705829708307869],
              [-38.643682434720745, -8.705849007862142],
              [-38.643689718467094, -8.705868297610014],
              [-38.643697028487331, -8.705887577516068],
              [-38.643704364768027, -8.705906847544895],
              [-38.643711727295702, -8.705926107661051],
              [-38.643719116056836, -8.705945357829179],
              [-38.643726531037828, -8.705964598013882],
              [-38.643733972225107, -8.705983828179797],
              [-38.643741439604952, -8.706003048291613],
              [-38.6437489331637, -8.706022258313991],
              [-38.64375645288753, -8.706041458211621],
              [-38.643763998762658, -8.706060647949272],
              [-38.643771570775229, -8.706079827491633],
              [-38.643779168911301, -8.706098996803469],
              [-38.643786793156941, -8.70611815584958],
              [-38.643794443498145, -8.706137304594694],
              [-38.643802119920849, -8.706156443003726],
              [-38.643809822410944, -8.706175571041429],
              [-38.643817550954296, -8.706194688672671],
              [-38.643825305536687, -8.706213795862332],
              [-38.643833086143914, -8.706232892575295],
              [-38.643840892761631, -8.706251978776491],
              [-38.643848725375527, -8.706271054430823],
              [-38.643856583971193, -8.706290119503223],
              [-38.643864468534211, -8.706309173958662],
              [-38.643872379050094, -8.706328217762161],
              [-38.643880315504312, -8.706347250878709],
              [-38.643888277882283, -8.706366273273316],
              [-38.643896266169364, -8.706385284911056],
              [-38.643904280350888, -8.706404285756944],
              [-38.643912320412142, -8.706423275776109],
              [-38.643920386338365, -8.70644225493365],
              [-38.643928478114702, -8.706461223194705],
              [-38.643936595726309, -8.706480180524366],
              [-38.643944739158293, -8.706499126887833],
              [-38.643952908395654, -8.706518062250275],
              [-38.643961103423415, -8.706536986576923],
              [-38.643969324226504, -8.706555899832956],
              [-38.643977570789829, -8.706574801983669],
              [-38.643985843098235, -8.706593692994305],
              [-38.643994141136524, -8.706612572830158],
              [-38.644002464889461, -8.706631441456523],
              [-38.644010814341748, -8.706650298838724],
              [-38.644019189478044, -8.706669144942131],
              [-38.644027590282953, -8.706687979732068],
              [-38.64403601674109, -8.706706803173393],
              [-38.644041060196152, -8.706718020846736],
              [-38.644046112761856, -8.706729234460585],
              [-38.644051174434942, -8.706740444007627],
              [-38.644056245212077, -8.70675164948052],
              [-38.644061325089964, -8.70676285087195],
              [-38.644066414065279, -8.706774048174577],
              [-38.644071512134715, -8.70678524138112],
              [-38.644076619294886, -8.706796430484264],
              [-38.644081735542521, -8.706807615476704],
              [-38.64408686087426, -8.706818796351097],
              [-38.64409199528675, -8.706829973100179],
              [-38.64409713877663, -8.706841145716613],
              [-38.644102291340538, -8.706852314193121],
              [-38.644107452975135, -8.706863478522402],
              [-38.644112623677039, -8.706874638697196],
              [-38.644117803442853, -8.706885794710143],
              [-38.644122992269224, -8.706896946554023],
              [-38.644128190152735, -8.70690809422152],
              [-38.644133397090016, -8.706919237705376],
              [-38.644138613077658, -8.706930376998253],
              [-38.644143838112257, -8.706941512092946],
              [-38.64414907219038, -8.706952642982127],
              [-38.644154315308633, -8.706963769658547],
              [-38.644159567463568, -8.706974892114937],
              [-38.644164828651796, -8.706986010344023],
              [-38.644170098869843, -8.706997124338567],
              [-38.644175378114284, -8.707008234091292],
              [-38.644180666381658, -8.707019339594927],
              [-38.644185963668541, -8.70703044084223],
              [-38.644191269971429, -8.707041537825935],
              [-38.644196585286885, -8.707052630538826],
              [-38.644201909611432, -8.707063718973636],
              [-38.644207242941597, -8.707074803123138],
              [-38.644212585273891, -8.707085882980063],
              [-38.644217936604825, -8.7070969585372],
              [-38.64422329693091, -8.707108029787285],
              [-38.644228666248644, -8.707119096723115],
              [-38.644234044554509, -8.707130159337444],
              [-38.644239431845001, -8.707141217623045],
              [-38.644244828116612, -8.707152271572683],
              [-38.644250233365788, -8.707163321179173],
              [-38.644255647589034, -8.707174366435256],
              [-38.64426107078279, -8.707185407333737],
              [-38.64426650294353, -8.70719644386741],
              [-38.644271944067697, -8.707207476029081],
              [-38.644277394151736, -8.70721850381147],
              [-38.644282853192088, -8.707229527207463],
              [-38.644288321185179, -8.707240546209825],
              [-38.644293798127471, -8.707251560811347],
              [-38.644299284015361, -8.70726257100481],
              [-38.64430477884526, -8.707273576783086],
              [-38.644310282613596, -8.707284578138925],
              [-38.64431579531675, -8.707295575065181],
              [-38.644321316951149, -8.707306567554642],
              [-38.644326847513192, -8.707317555600156],
              [-38.644332386999238, -8.707328539194528],
              [-38.644337935405687, -8.707339518330574],
              [-38.644343492728908, -8.707350493001137],
              [-38.644349058965268, -8.70736146319903],
              [-38.644354634111153, -8.70737242891709],
              [-38.644360218162909, -8.707383390148186],
              [-38.64436581111687, -8.707394346885122],
              [-38.64437141296942, -8.707405299120754],
              [-38.644377023716864, -8.707416246847943],
              [-38.644382643355556, -8.707427190059516],
              [-38.644388271881837, -8.707438128748322],
              [-38.644393909292006, -8.707449062907216],
              [-38.644399555582403, -8.707459992529095],
              [-38.644405210749341, -8.707470917606736],
              [-38.644410874789095, -8.707481838133104],
              [-38.644416547698, -8.70749275410099],
              [-38.644422229472333, -8.707503665503275],
              [-38.644427920108377, -8.707514572332844],
              [-38.644433619602431, -8.707525474582591],
              [-38.644439327950785, -8.707536372245361],
              [-38.644445045149688, -8.707547265314044],
              [-38.644450771195402, -8.707558153781561],
              [-38.644456506084211, -8.707569037640747],
              [-38.644462249812335, -8.707579916884486],
              [-38.644468002376058, -8.707590791505719],
              [-38.644473763771607, -8.707601661497311],
              [-38.644479533995224, -8.707612526852184],
              [-38.644485313043127, -8.707623387563213],
              [-38.644491100911559, -8.707634243623309],
              [-38.644496897596724, -8.707645095025397],
              [-38.644502703094851, -8.707655941762377],
              [-38.644508517402137, -8.707666783827156],
              [-38.64451434051481, -8.707677621212673],
              [-38.644520172429019, -8.707688453911821],
              [-38.64452601314099, -8.707699281917565],
              [-38.6445318626469, -8.707710105222766],
              [-38.644537720942942, -8.707720923820434],
              [-38.64454358802525, -8.707731737703435],
              [-38.644549463890058, -8.707742546864743],
              [-38.644555348533451, -8.707753351297272],
              [-38.644561241951642, -8.707764150994],
              [-38.644567144140758, -8.707774945947829],
              [-38.644573055096963, -8.707785736151738],
              [-38.644578974816376, -8.707796521598636],
              [-38.644586639070688, -8.707810465022066],
              [-38.644594317993757, -8.707824400453468],
              [-38.644602011577163, -8.707838327877601],
              [-38.644609719812493, -8.707852247279156],
              [-38.644617442691256, -8.707866158642917],
              [-38.644625180205026, -8.707880061953608],
              [-38.64463293234531, -8.707893957196021],
              [-38.644640699103618, -8.707907844354871],
              [-38.64464848047141, -8.70792172341495],
              [-38.644656276440202, -8.707935594361087],
              [-38.644664087001416, -8.70794945717801],
              [-38.644671912146499, -8.707963311850579],
              [-38.644679751866875, -8.707977158363539],
              [-38.644687606153951, -8.707990996701783],
              [-38.644695474999125, -8.708004826850127],
              [-38.644703358393755, -8.708018648793361],
              [-38.644711256329217, -8.708032462516369],
              [-38.644719168796847, -8.708046268003992],
              [-38.644727095787978, -8.708060065241106],
              [-38.644735037293906, -8.708073854212595],
              [-38.644742993305961, -8.708087634903336],
              [-38.644750963815383, -8.708101407298189],
              [-38.644758948813454, -8.708115171382101],
              [-38.644766948291412, -8.708128927139965],
              [-38.644774962240511, -8.708142674556701],
              [-38.644782990651947, -8.708156413617242],
              [-38.644791033516931, -8.708170144306502],
              [-38.644799090826638, -8.708183866609476],
              [-38.644807162572256, -8.708197580511083],
              [-38.644815248744912, -8.708211285996271],
              [-38.644823349335752, -8.708224983050087],
              [-38.644831464335908, -8.708238671657423],
              [-38.644839593736457, -8.708252351803358],
              [-38.644847737528522, -8.708266023472845],
              [-38.644855895703166, -8.708279686650886],
              [-38.644864068251444, -8.708293341322559],
              [-38.644872255164394, -8.708306987472852],
              [-38.644880456433043, -8.708320625086811],
              [-38.644888672048417, -8.7083342541495],
              [-38.644896902001484, -8.70834787464595],
              [-38.64490514628325, -8.708361486561229],
              [-38.644913404884669, -8.708375089880462],
              [-38.644921677796681, -8.708388684588696],
              [-38.644929965010242, -8.70840227067103],
              [-38.644938266516235, -8.708415848112578],
              [-38.644946582305572, -8.708429416898436],
              [-38.644954912369158, -8.708442977013791],
              [-38.644963256697842, -8.708456528443696],
              [-38.644971615282479, -8.708470071173341],
              [-38.644979988113917, -8.708483605187872],
              [-38.644988375182976, -8.708497130472418],
              [-38.644996776480447, -8.708510647012215],
              [-38.64500519199715, -8.708524154792402],
              [-38.645013621723848, -8.708537653798205],
              [-38.645022065651283, -8.708551144014777],
              [-38.645030523770224, -8.708564625427359],
              [-38.645038996071378, -8.708578098021178],
              [-38.645047482545493, -8.708591561781438],
              [-38.645055983183234, -8.708605016693413],
              [-38.645064497975305, -8.708618462742324],
              [-38.645073026912371, -8.708631899913449],
              [-38.645081569985059, -8.708645328192045],
              [-38.645090127184019, -8.708658747563398],
              [-38.645098698499901, -8.708672158012801],
              [-38.645107283923252, -8.708685559525515],
              [-38.64511588344471, -8.708698952086891],
              [-38.645124497054837, -8.708712335682243],
              [-38.645133124744184, -8.708725710296893],
              [-38.645141766503279, -8.708739075916142],
              [-38.645150422322679, -8.70875243252541],
              [-38.645159092192891, -8.708765780109983],
              [-38.645167776104408, -8.708779118655265],
              [-38.645176474047702, -8.708792448146653],
              [-38.645185186013244, -8.708805768569482],
              [-38.645193911991498, -8.708819079909187],
              [-38.64520265197288, -8.708832382151151],
              [-38.645211405947812, -8.708845675280804],
              [-38.645220173906729, -8.708858959283585],
              [-38.64522895583999, -8.708872234144925],
              [-38.645237751737959, -8.708885499850272],
              [-38.645246561591023, -8.708898756385045],
              [-38.645255385389511, -8.708912003734781],
              [-38.645264223123768, -8.708925241884911],
              [-38.645273074784079, -8.708938470820904],
              [-38.645281940360768, -8.708951690528307],
              [-38.645290819844092, -8.708964900992612],
              [-38.64529971322434, -8.708978102199296],
              [-38.645308620491747, -8.708991294133945],
              [-38.645317541636558, -8.709004476782049],
              [-38.645326476648997, -8.709017650129201],
              [-38.645335425519285, -8.709030814160908],
              [-38.645344388237575, -8.709043968862789],
              [-38.645353364794069, -8.709057114220391],
              [-38.645362355178918, -8.709070250219323],
              [-38.645371359382274, -8.709083376845166],
              [-38.645380377394275, -8.709096494083552],
              [-38.645389409205002, -8.709109601920071],
              [-38.645398454804599, -8.709122700340387],
              [-38.64540751418312, -8.709135789330093],
              [-38.645412440069862, -8.709143816192155],
              [-38.645417335084325, -8.709151861729522],
              [-38.645422199155043, -8.709159925824661],
              [-38.645427032210939, -8.70916800835975],
              [-38.645431834181402, -8.709176109216701],
              [-38.645436604996242, -8.709184228277145],
              [-38.645441344585784, -8.709192365422478],
              [-38.64544605288075, -8.709200520533788],
              [-38.645450729812381, -8.70920869349197],
              [-38.645455375312316, -8.709216884177563],
              [-38.645459989312691, -8.709225092470898],
              [-38.645464571746089, -8.709233318252116],
              [-38.64546912254557, -8.709241561400958],
              [-38.645473641644628, -8.709249821797004],
              [-38.645478128977231, -8.709258099319614],
              [-38.645482584477826, -8.709266393847795],
              [-38.645487008081311, -8.709274705260368],
              [-38.645491399723056, -8.709283033435906],
              [-38.645495759338871, -8.709291378252757],
              [-38.645500086865113, -8.709299739588987],
              [-38.645504382238485, -8.709308117322406],
              [-38.645508645396269, -8.709316511330663],
              [-38.645512876276172, -8.709324921491048],
              [-38.645517074816375, -8.709333347680751],
              [-38.645521240955546, -8.709341789776627],
              [-38.645525374632776, -8.70935024765533],
              [-38.645529475787683, -8.70935872119329],
              [-38.645533544360362, -8.709367210266739],
              [-38.645537580291368, -8.709375714751609],
              [-38.645541583521705, -8.709384234523659],
              [-38.645545553992918, -8.709392769458416],
              [-38.64554949164696, -8.709401319431178],
              [-38.645553396426322, -8.70940988431702],
              [-38.64555726827394, -8.709418463990803],
              [-38.645561107133226, -8.709427058327156],
              [-38.645564912948132, -8.709435667200575],
              [-38.645568685663022, -8.709444290485227],
              [-38.645572425222774, -8.709452928055148],
              [-38.645576131572746, -8.709461579784097],
              [-38.645579804658794, -8.709470245545718],
              [-38.645583444427253, -8.709478925213384],
              [-38.645587050824936, -8.709487618660249],
              [-38.645590623799151, -8.709496325759366],
              [-38.64559416329768, -8.709505046383459],
              [-38.645597669268838, -8.709513780405141],
              [-38.645601141661366, -8.70952252769681],
              [-38.645604580424546, -8.709531288130634],
              [-38.645607985508136, -8.709540061578656],
              [-38.645611356862361, -8.70954884791265],
              [-38.645614694437995, -8.709557647004281],
              [-38.645617998186239, -8.709566458724968],
              [-38.645621268058846, -8.709575282945989],
              [-38.645624504008026, -8.709584119538407],
              [-38.645627705986499, -8.709592968373117],
              [-38.645630873947503, -8.709601829320828],
              [-38.645634007844698, -8.709610702252098],
              [-38.645637107632361, -8.709619587037238],
              [-38.645640173265164, -8.709628483546501],
              [-38.645643204698288, -8.709637391649872],
              [-38.645646201887473, -8.70964631121719],
              [-38.645649164788928, -8.709655242118153],
              [-38.645652093359367, -8.709664184222266],
              [-38.645654987555965, -8.709673137398905],
              [-38.645657847336466, -8.709682101517206],
              [-38.645660672659076, -8.70969107644625],
              [-38.645663463482506, -8.709700062054884],
              [-38.645666219765999, -8.709709058211839],
              [-38.645668941469253, -8.709718064785676],
              [-38.645671628552513, -8.709727081644781],
              [-38.645674280976515, -8.709736108657451],
              [-38.645676898702511, -8.709745145691778],
              [-38.645679481692255, -8.709754192615712],
              [-38.645682029907981, -8.70976324929709],
              [-38.645684543312484, -8.709772315603628],
              [-38.64568702186903, -8.709781391402753],
              [-38.645689465541395, -8.709790476561983],
              [-38.645691874293888, -8.709799570948501],
              [-38.645694248091296, -8.709808674429446],
              [-38.645696586898943, -8.709817786871863],
              [-38.645698890682674, -8.709826908142553],
              [-38.645701159408773, -8.709836038108291],
              [-38.645703393044151, -8.709845176635653],
              [-38.645705591556151, -8.709854323591152],
              [-38.645707754912628, -8.709863478841099],
              [-38.645709883081985, -8.709872642251783],
              [-38.645711976033134, -8.709881813689297],
              [-38.645714033735494, -8.709890993019645],
              [-38.645716056158989, -8.709900180108704],
              [-38.645718043274059, -8.709909374822262],
              [-38.645719995051699, -8.709918577025975],
              [-38.645721911463362, -8.709927786585393],
              [-38.64572379248105, -8.709937003365955],
              [-38.645725638077288, -8.709946227232994],
              [-38.645727448225095, -8.709955458051756],
              [-38.645729222898048, -8.709964695687345],
              [-38.645730962070182, -8.709973940004858],
              [-38.645732665716082, -8.709983190869165],
              [-38.6457343338109, -8.709992448145131],
              [-38.645735966330498, -8.71000171169594],
              [-38.645738829569147, -8.710018909884759],
              [-38.645741569666555, -8.710036127921384],
              [-38.64574418648381, -8.710053364932994],
              [-38.645746679888269, -8.710070620045729],
              [-38.645749049753448, -8.710087892384852],
              [-38.645751295959215, -8.710105181074743],
              [-38.645753418391706, -8.710122485238999],
              [-38.645755416943295, -8.710139804000374],
              [-38.645757291512631, -8.710157136480868],
              [-38.645759042004684, -8.710174481801843],
              [-38.645760668330688, -8.710191839083981],
              [-38.645762170408183, -8.710209207447397],
              [-38.645763548161028, -8.710226586011546],
              [-38.645764801519306, -8.710243973895455],
              [-38.645765930419522, -8.710261370217665],
              [-38.645766934804399, -8.710278774096279],
              [-38.645767814622992, -8.71029618464898],
              [-38.645768569830707, -8.710313600993192],
              [-38.645769200389225, -8.71033102224594],
              [-38.64576970626657, -8.710348447524106],
              [-38.645770087437057, -8.710365875944298],
              [-38.645770343881388, -8.710383306623006],
              [-38.645770475586502, -8.710400738676572],
              [-38.64577048254575, -8.710418171221308],
              [-38.645770364758732, -8.710435603373456],
              [-38.645770122231404, -8.710453034249301],
              [-38.645769754976044, -8.710470462965167],
              [-38.64576926301126, -8.710487888637559],
              [-38.645768646361965, -8.710505310383066],
              [-38.645767905059415, -8.710522727318493],
              [-38.645767039141155, -8.710540138560873],
              [-38.64576604865109, -8.710557543227598],
              [-38.645764933639398, -8.710574940436301],
              [-38.645763694162568, -8.710592329305053],
              [-38.645762330283461, -8.710609708952319],
              [-38.645760842071184, -8.710627078497057],
              [-38.645759229601147, -8.710644437058704],
              [-38.645757492955092, -8.710661783757274],
              [-38.645755632221046, -8.710679117713381],
              [-38.645753647493315, -8.710696438048277],
              [-38.645751538872496, -8.710713743883934],
              [-38.645749306465454, -8.710731034343024],
              [-38.645746950385387, -8.710748308549002],
              [-38.645744470751673, -8.710765565626167],
              [-38.64574186769002, -8.710782804699678],
              [-38.645739141332378, -8.710800024895592],
              [-38.645736291816917, -8.710817225340943],
              [-38.645733319288119, -8.710834405163709],
              [-38.645730223896621, -8.710851563493044],
              [-38.645727005799344, -8.710868699459047],
              [-38.645723665159394, -8.71088581219305],
              [-38.645720202146144, -8.710902900827497],
              [-38.645716616935097, -8.710919964496101],
              [-38.64571290970801, -8.710937002333811],
              [-38.645709080652807, -8.710954013476881],
              [-38.645705129963559, -8.710970997062949],
              [-38.645701057840569, -8.71098795223106],
              [-38.645696864490219, -8.711004878121644],
              [-38.645692550125091, -8.71102177387662],
              [-38.645688114963896, -8.711038638639515],
              [-38.645683559231436, -8.711055471555301],
              [-38.645678883158659, -8.711072271770709],
              [-38.645674086982602, -8.711089038434023],
              [-38.645669170946391, -8.71110577069523],
              [-38.645664135299249, -8.711122467706124],
              [-38.645658980296389, -8.71113912862023],
              [-38.645653706199177, -8.711155752592969],
              [-38.645648313274961, -8.711172338781513],
              [-38.645642801797095, -8.71118888634507],
              [-38.645637172044992, -8.711205394444772],
              [-38.645631424304028, -8.711221862243701],
              [-38.645625558865568, -8.711238288907071],
              [-38.645619576026952, -8.711254673602102],
              [-38.645613476091469, -8.711271015498188],
              [-38.645607259368305, -8.711287313766848],
              [-38.645600926172655, -8.711303567581862],
              [-38.645594476825529, -8.71131977611924],
              [-38.645587911653877, -8.711335938557305],
              [-38.645581230990508, -8.711352054076642],
              [-38.645574435174062, -8.71136812186033],
              [-38.645567524549065, -8.711384141093792],
              [-38.64556049946583, -8.711400110964949],
              [-38.645553360280481, -8.711416030664191],
              [-38.64554610735491, -8.711431899384483],
              [-38.645538741056818, -8.711447716321347],
              [-38.645531261759601, -8.711463480672954],
              [-38.645523669842433, -8.711479191640084],
              [-38.645515965690137, -8.711494848426332],
              [-38.645508149693306, -8.711510450237938],
              [-38.645500222248124, -8.711525996283951],
              [-38.645492183756474, -8.71154148577631],
              [-38.645484034625852, -8.711556917929746],
              [-38.645475775269382, -8.711572291961902],
              [-38.645467406105745, -8.711587607093426],
              [-38.645458927559204, -8.711602862547924],
              [-38.64545034005959, -8.711618057551973],
              [-38.645441644042201, -8.711633191335292],
              [-38.64543283994788, -8.711648263130645],
              [-38.645423928223444, -8.711663272173382],
              [-38.645407193057196, -8.711691572446243],
              [-38.645390842530638, -8.711720094435949],
              [-38.645374879610507, -8.71174883296746],
              [-38.645359307193196, -8.711777782826395],
              [-38.645344128104277, -8.711806938760091],
              [-38.645329345097934, -8.711836295478474],
              [-38.645314960856496, -8.711865847655053],
              [-38.645300977989962, -8.711895589927829],
              [-38.645287399035468, -8.711925516900367],
              [-38.645274226456891, -8.711955623142661],
              [-38.645261462644378, -8.711985903192231],
              [-38.645249109913898, -8.712016351555027],
              [-38.645237170506846, -8.712046962706451],
              [-38.64522564658963, -8.712077731092386],
              [-38.645214540253228, -8.712108651130194],
              [-38.645203853512932, -8.71213971720972],
              [-38.645193588307812, -8.712170923694268],
              [-38.64518374650055, -8.712202264921713],
              [-38.645174329876923, -8.712233735205448],
              [-38.645165340145631, -8.712265328835489],
              [-38.645156778937853, -8.712297040079447],
              [-38.645148647807105, -8.712328863183544],
              [-38.645140948228764, -8.712360792373763],
              [-38.645133681599994, -8.712392821856874],
              [-38.645126849239368, -8.712424945821345],
              [-38.645120452386656, -8.712457158438541],
              [-38.645114492202651, -8.712489453863782],
              [-38.645108969768884, -8.712521826237341],
              [-38.645103886087441, -8.712554269685519],
              [-38.64509924208086, -8.712586778321707],
              [-38.645095038591869, -8.712619346247491],
              [-38.645091276383226, -8.71265196755367],
              [-38.645087956137715, -8.712684636321354],
              [-38.645085078457861, -8.712717346623112],
              [-38.645082643865933, -8.712750092523892],
              [-38.645080652803749, -8.712782868082215],
              [-38.645079105632718, -8.712815667351194],
              [-38.64507800263366, -8.712848484379689],
              [-38.64507734400685, -8.712881313213268],
              [-38.645077129871865, -8.712914147895457],
              [-38.645077360267685, -8.712946982468603],
              [-38.645078035152643, -8.712979810975137],
              [-38.645079154404392, -8.713012627458582],
              [-38.645080717819965, -8.713045425964639],
              [-38.645082725115813, -8.713078200542267],
              [-38.645085175927846, -8.713110945244766],
              [-38.645088069811486, -8.713143654130835],
              [-38.645091406241789, -8.713176321265733],
              [-38.645095184613503, -8.713208940722181],
              [-38.645099404241158, -8.713241506581705],
              [-38.645104064359273, -8.713274012935379],
              [-38.645109164122424, -8.713306453885194],
              [-38.645114702605383, -8.713338823544987],
              [-38.645120678803359, -8.713371116041504],
              [-38.645127091632141, -8.713403325515493],
              [-38.64513393992825, -8.713435446122773],
              [-38.645141222449254, -8.713467472035287],
              [-38.645148937873877, -8.713499397442177],
              [-38.645157084802335, -8.71353121655074],
              [-38.645165661756522, -8.713562923587695],
              [-38.645174667180321, -8.713594512799943],
              [-38.645184099439859, -8.713625978455893],
              [-38.64519395682386, -8.713657314846257],
              [-38.645204237543844, -8.713688516285286],
              [-38.645214939734544, -8.713719577111691],
              [-38.645226061454224, -8.713750491689666],
              [-38.645237600685057, -8.713781254409978],
              [-38.645249555333386, -8.713811859690937],
              [-38.645261923230237, -8.713842301979403],
              [-38.645274702131651, -8.713872575751806],
              [-38.645287889719057, -8.713902675515198],
              [-38.645301483599752, -8.713932595808098],
              [-38.645315481307314, -8.713962331201728],
              [-38.645329880302043, -8.713991876300749],
              [-38.64534467797143, -8.714021225744393],
              [-38.64535987163061, -8.714050374207405],
              [-38.645375458522892, -8.71407931640092],
              [-38.645391435820223, -8.714108047073593],
              [-38.645407800623701, -8.71413656101238],
              [-38.645424549964119, -8.714164853043666],
              [-38.645441680802499, -8.714192918033964],
              [-38.645459190030635, -8.714220750891045],
              [-38.645477074471671, -8.714248346564847],
              [-38.645495330880657, -8.714275700048237],
              [-38.645513955945162, -8.714302806378109],
              [-38.645532946285833, -8.714329660636192],
              [-38.645552298457105, -8.714356257949879],
              [-38.645572008947674, -8.714382593493296],
              [-38.645592074181266, -8.714408662487969],
              [-38.645612490517244, -8.714434460203854],
              [-38.645633254251237, -8.714459981960061],
              [-38.645654361615861, -8.714485223125816],
              [-38.645675808781363, -8.714510179121261],
              [-38.645697591856347, -8.714534845418241],
              [-38.64571970688845, -8.714559217541195],
              [-38.645742149865093, -8.714583291067923],
              [-38.645764916714171, -8.714607061630431],
              [-38.645788003304816, -8.714630524915673],
              [-38.645811405448661, -8.714653676666062],
              [-38.645830232429354, -8.714671621816267],
              [-38.645849482071434, -8.714689117433478],
              [-38.645869143535357, -8.714706153665789],
              [-38.645889205749626, -8.714722720919953],
              [-38.645909657417143, -8.714738809866832],
              [-38.645930487021452, -8.714754411446636],
              [-38.645951682833292, -8.71476951687397],
              [-38.645973232917157, -8.714784117642807],
              [-38.645995125138086, -8.714798205531386],
              [-38.646017347168403, -8.714811772606676],
              [-38.646039886494776, -8.714824811228935],
              [-38.646062730425115, -8.714837314056021],
              [-38.646085866095859, -8.714849274047465],
              [-38.646109280479173, -8.714860684468501],
              [-38.646132960390219, -8.714871538893844],
              [-38.646156892494666, -8.714881831211231],
              [-38.646181063316142, -8.714891555624998],
              [-38.646205459243916, -8.714900706659224],
              [-38.646230066540411, -8.714909279160896],
              [-38.646254871349072, -8.714917268302708],
              [-38.646279859702091, -8.714924669585985],
              [-38.646305017528341, -8.714931478842932],
              [-38.646330330661236, -8.714937692239207],
              [-38.646355784846712, -8.714943306276011],
              [-38.646381365751331, -8.714948317791979],
              [-38.646407058970226, -8.714952723965117],
              [-38.64643285003536, -8.714956522314251],
              [-38.646458724423503, -8.714959710700482],
              [-38.646484667564607, -8.714962287328422],
              [-38.646510664849828, -8.7149642507471],
              [-38.646536701639882, -8.714965599850947],
              [-38.646562763273202, -8.714966333880211],
              [-38.646588835074247, -8.714966452421613],
              [-38.646614902361755, -8.714965955408314],
              [-38.646640950456998, -8.714964843120262],
              [-38.646666964692045, -8.714963116183739],
              [-38.646692930418077, -8.71496077557121],
              [-38.64671883301353, -8.71495782260069],
              [-38.64674465789242, -8.714954258935023],
              [-38.64677039051255, -8.714950086580924],
              [-38.646796016383639, -8.714945307887861],
              [-38.646821521075509, -8.714939925546801],
              [-38.646846890226264, -8.714933942588557],
              [-38.646872109550323, -8.7149273623822],
              [-38.646897164846443, -8.714920188633069],
              [-38.646922042005784, -8.714912425380763],
              [-38.646946727019802, -8.714904076996865],
              [-38.646971205988152, -8.714895148182382],
              [-38.646995465126551, -8.714885643965237],
              [-38.647019490774447, -8.714875569697298],
              [-38.647043269402836, -8.714864931051476],
              [-38.647066787621768, -8.714853734018536],
              [-38.647090032187947, -8.714841984903538],
              [-38.647112990012175, -8.714829690322528],
              [-38.647135648166753, -8.714816857198697],
              [-38.647157993892705, -8.71480349275847],
              [-38.647180014606981, -8.714789604527514],
              [-38.64720169790958, -8.714775200326347],
              [-38.647223031590478, -8.71476028826614],
              [-38.647244003636565, -8.71474487674395],
              [-38.647264602238337, -8.714728974438149],
              [-38.647284815796581, -8.714712590303458],
              [-38.647304632928957, -8.714695733565897],
              [-38.64732404247632, -8.714678413717618],
              [-38.647343033509046, -8.714660640511601],
              [-38.647361595333223, -8.714642423956011],
              [-38.647379717496555, -8.7146237743088],
              [-38.647397389794413, -8.714604702071632],
              [-38.64741460227544, -8.71458521798432],
              [-38.647431345247242, -8.714565333018429],
              [-38.647447609281791, -8.714545058371359],
              [-38.647463385220767, -8.714524405459931],
              [-38.647478664180689, -8.714503385913908],
              [-38.647493437557962, -8.714482011569594],
              [-38.647507697033681, -8.71446029446297],
              [-38.647521434578302, -8.714438246823107],
              [-38.647534642456236, -8.714415881065195],
              [-38.647547313230092, -8.714393209783569],
              [-38.647559439765018, -8.714370245744512],
              [-38.647571015232543, -8.714347001879265],
              [-38.647582033114546, -8.714323491276591],
              [-38.647592487206907, -8.714299727175465],
              [-38.647602371622973, -8.714275722957586],
              [-38.647611680796849, -8.714251492139883],
              [-38.647620409486613, -8.714227048366885],
              [-38.647628552777185, -8.714202405403009],
              [-38.647636106083155, -8.714177577124872],
              [-38.647643065151335, -8.714152577513405],
              [-38.647649426063118, -8.714127420646028],
              [-38.647655185236815, -8.714102120688706],
              [-38.647660339429457, -8.714076691887986],
              [-38.647664885738877, -8.714051148562985],
              [-38.647668821605109, -8.714025505097272],
              [-38.64767214481197, -8.713999775930791],
              [-38.647674853488304, -8.713973975551799],
              [-38.647676946108938, -8.713948118488576],
              [-38.647678421495613, -8.713922219301388],
              [-38.647679278817719, -8.713896292574146],
              [-38.647679517592586, -8.713870352906344],
              [-38.647679843284777, -8.713840614512483],
              [-38.64768098191896, -8.713810895938961],
              [-38.647682932652209, -8.713781219181412],
              [-38.647685694040533, -8.713751606204591],
              [-38.647689264039947, -8.713722078926098],
              [-38.647693640007965, -8.713692659199948],
              [-38.647698818705635, -8.713663368800642],
              [-38.647704796299827, -8.713634229406992],
              [-38.647711568366191, -8.713605262585926],
              [-38.647719129892266, -8.713576489776791],
              [-38.647727475281371, -8.713547932275233],
              [-38.64773659835668, -8.713519611217535],
              [-38.647746492365705, -8.713491547564994],
              [-38.647757149985424, -8.71346376208842],
              [-38.647768563327652, -8.713436275352718],
              [-38.647780723944841, -8.713409107701636],
              [-38.647793622836375, -8.713382279242813],
              [-38.647807250455287, -8.713355809832805],
              [-38.647821596715161, -8.713329719062406],
              [-38.647836650997782, -8.713304026242223],
              [-38.647852402160879, -8.713278750388287],
              [-38.647868838546394, -8.713253910207944],
              [-38.64788594798916, -8.713229524086263],
              [-38.64790371782582, -8.713205610072087],
              [-38.647922134904299, -8.713182185864873],
              [-38.647941185593439, -8.713159268801576],
              [-38.64796085579318, -8.713136875843789],
              [-38.647981130944913, -8.71311502356523],
              [-38.648001996042325, -8.713093728139359],
              [-38.648023435642436, -8.71307300532759],
              [-38.648045433877122, -8.713052870467441],
              [-38.648067974464759, -8.713033338461313],
              [-38.648091040722335, -8.713014423765424],
              [-38.648114615577796, -8.712996140379035],
              [-38.648138681582651, -8.712978501834252],
              [-38.648163220924907, -8.712961521185859],
              [-38.648188215442239, -8.712945211001687],
              [-38.648213646635476, -8.712929583353416],
              [-38.648239495682198, -8.712914649807447],
              [-38.648265743450786, -8.71290042141657],
              [-38.648292370514483, -8.712886908711628],
              [-38.648319357165875, -8.712874121693712],
              [-38.648346683431285, -8.712862069826867],
              [-38.648374329085811, -8.71285076203103],
              [-38.648402273668118, -8.71284020667542],
              [-38.648430496495621, -8.712830411572375],
              [-38.648458976679827, -8.712821383971484],
              [-38.648487693141753, -8.712813130554325],
              [-38.648516624627533, -8.712805657429504],
              [-38.648545749724207, -8.712798970128114],
              [-38.648575046875479, -8.712793073599594],
              [-38.648604494397723, -8.712787972208146],
              [-38.648634070496023, -8.712783669729427],
              [-38.648663753280317, -8.712780169347846],
              [-38.64869352078157, -8.712777473654128],
              [-38.648723350968019, -8.712775584643452],
              [-38.648753221761574, -8.712774503713939],
              [-38.648783111054023, -8.712774231665612],
              [-38.648812996723514, -8.712774768699804],
              [-38.648842856650823, -8.712776114419094],
              [-38.648872668735841, -8.712778267827421],
              [-38.648902410913827, -8.712781227331083],
              [-38.648932061171784, -8.712784990739635],
              [-38.648961597564721, -8.712789555267673],
              [-38.648990998231994, -8.712794917536877],
              [-38.649020241413311, -8.712801073578522],
              [-38.649049305465027, -8.712808018836311],
              [-38.649078168876002, -8.712815748169938],
              [-38.649106810283641, -8.712824255858633],
              [-38.649135208489618, -8.712833535605688],
              [-38.649163342475639, -8.712843580542904],
              [-38.649191191418936, -8.712854383235756],
              [-38.649218734707702, -8.712865935688846],
              [-38.64924595195636, -8.712878229351952],
              [-38.649272823020624, -8.712891255126133],
              [-38.64929932801244, -8.712905003370654],
              [-38.649325447314681, -8.712919463910147],
              [-38.649351161595675, -8.712934626041884],
              [-38.6493764518235, -8.712950478543975],
              [-38.649401299280079, -8.712967009683519],
              [-38.649425685575061, -8.712984207225402],
              [-38.649449592659373, -8.713002058441202],
              [-38.649473002838626, -8.713020550118744],
              [-38.649495898786228, -8.713039668571781],
              [-38.649518263556118, -8.713059399650238],
              [-38.649540080595393, -8.713079728750557],
              [-38.649561333756544, -8.71310064082655],
              [-38.649582007309384, -8.713122120400635],
              [-38.649602085952729, -8.713144151575168],
              [-38.64962155482565, -8.713166718044171],
              [-38.649640399518553, -8.713189803105593],
              [-38.649658606083818, -8.713213389673463],
              [-38.649676161046067, -8.713237460290642],
              [-38.649693051412243, -8.713261997141792],
              [-38.649709264681142, -8.713286982066421],
              [-38.64972478885268, -8.713312396572437],
              [-38.649739612436804, -8.713338221849781],
              [-38.649753724462002, -8.713364438784422],
              [-38.649767114484369, -8.713391027972223],
              [-38.649782152035812, -8.713422605751608],
              [-38.649796653613379, -8.713454430732881],
              [-38.64981061511363, -8.713486493910947],
              [-38.649824032585897, -8.713518786213337],
              [-38.649836902233517, -8.713551298502745],
              [-38.649849220414794, -8.713584021579642],
              [-38.649860983644054, -8.713616946184777],
              [-38.649872188592688, -8.713650063001971],
              [-38.649882832090007, -8.71368336266058],
              [-38.64989291112424, -8.713716835738243],
              [-38.64990242284329, -8.713750472763538],
              [-38.649911364555592, -8.713784264218624],
              [-38.649919733730897, -8.713818200542038],
              [-38.649927528000923, -8.713852272131206],
              [-38.649934745160053, -8.713886469345354],
              [-38.649941383165988, -8.713920782508136],
              [-38.649947440140309, -8.7139552019104],
              [-38.649952914368981, -8.713989717812922],
              [-38.649957804302879, -8.7140243204492],
              [-38.649962108558192, -8.714059000028159],
              [-38.64996582591683, -8.714093746736912],
              [-38.649968955326784, -8.714128550743673],
              [-38.649971495902392, -8.714163402200329],
              [-38.649973446924612, -8.714198291245435],
              [-38.649974807841268, -8.714233208006863],
              [-38.649975578267039, -8.714268142604645],
              [-38.649975757983817, -8.714303085153727],
              [-38.649975346940565, -8.714338025766878],
              [-38.649974345253419, -8.714372954557343],
              [-38.64997275320566, -8.714407861641757],
              [-38.649970571247614, -8.714442737142853],
              [-38.649967799996517, -8.714477571192319],
              [-38.649964440236346, -8.714512353933605],
              [-38.649960492917621, -8.714547075524619],
              [-38.649955959157104, -8.714581726140603],
              [-38.649950840237501, -8.714616295976832],
              [-38.649945137607077, -8.714650775251478],
              [-38.649938852879302, -8.714685154208373],
              [-38.649931987832346, -8.71471942311967],
              [-38.649924544408556, -8.714753572288657],
              [-38.64991652471398, -8.71478759205252],
              [-38.649907931017687, -8.714821472785081],
              [-38.64989876575121, -8.71485520489947],
              [-38.649889031507769, -8.714888778850856],
              [-38.649878731041625, -8.714922185139237],
              [-38.649867867267254, -8.714955414311925],
              [-38.649856443258486, -8.714988456966458],
              [-38.649844462247735, -8.715021303753087],
              [-38.649831927624987, -8.715053945377489],
              [-38.649818842936895, -8.715086372603441],
              [-38.649805211885756, -8.715118576255311],
              [-38.64979103832848, -8.715150547220812],
              [-38.649776326275465, -8.715182276453367],
              [-38.649761079889508, -8.715213754974913],
              [-38.6497453034846, -8.715244973878301],
              [-38.649729001524719, -8.715275924329831],
              [-38.649712178622487, -8.715306597571766],
              [-38.649694839538057, -8.715336984924786],
              [-38.64967698917755, -8.715367077790466],
              [-38.649658632591787, -8.715396867653784],
              [-38.649639774974851, -8.715426346085298],
              [-38.649620421662576, -8.715455504743836],
              [-38.649600578131093, -8.715484335378651],
              [-38.649580249995211, -8.715512829831813],
              [-38.6495594430069, -8.715540980040524],
              [-38.649538163053592, -8.715568778039355],
              [-38.649516416156608, -8.715596215962607],
              [-38.649494208469349, -8.715623286046354],
              [-38.649471546275663, -8.715649980630888],
              [-38.649448435987921, -8.71567629216268],
              [-38.64942488414539, -8.715702213196584],
              [-38.649400897412214, -8.715727736397945],
              [-38.649376482575605, -8.715752854544727],
              [-38.649351646543984, -8.715777560529476],
              [-38.649326396344847, -8.71580184736133],
              [-38.649300739122992, -8.715825708168079],
              [-38.649274682138312, -8.715849136198031],
              [-38.649248232763902, -8.715872124821958],
              [-38.64922139848381, -8.715894667535007],
              [-38.649194186891059, -8.715916757958412],
              [-38.649166605685402, -8.71593838984148],
              [-38.64913866267122, -8.715959557063199],
              [-38.649110365755249, -8.715980253634097],
              [-38.649081722944373, -8.716000473697779],
              [-38.649052742343365, -8.716020211532806],
              [-38.649023432152589, -8.716039461554121],
              [-38.648993800665636, -8.716058218314682],
              [-38.648963856267059, -8.716076476507086],
              [-38.648933607429946, -8.716094230964931],
              [-38.648903062713529, -8.716111476664397],
              [-38.648872230760723, -8.71612820872563],
              [-38.648841120295813, -8.716144422414072],
              [-38.648809740121834, -8.716160113141891],
              [-38.648778099118132, -8.716175276469196],
              [-38.648746206237917, -8.716189908105372],
              [-38.648714070505612, -8.716204003910216],
              [-38.648681701014397, -8.716217559895121],
              [-38.648649106923607, -8.716230572224299],
              [-38.64861629745549, -8.716243037216451],
              [-38.648596739284116, -8.716250391976379],
              [-38.648577256119019, -8.716257941241699],
              [-38.648557849914127, -8.71626568425531],
              [-38.648538522615681, -8.716273620240663],
              [-38.64851927616197, -8.716281748401887],
              [-38.648500112483191, -8.716290067923813],
              [-38.648481033501241, -8.716298577972079],
              [-38.648462041129548, -8.716307277693218],
              [-38.648443137272793, -8.71631616621479],
              [-38.648424323826816, -8.716325242645311],
              [-38.648405602678416, -8.716334506074562],
              [-38.648386975705115, -8.716343955573539],
              [-38.648368444774974, -8.71635359019456],
              [-38.648350011746416, -8.716363408971372],
              [-38.648331678468082, -8.716373410919296],
              [-38.648313446778594, -8.716383595035216],
              [-38.648295318506385, -8.716393960297774],
              [-38.648277295469519, -8.716404505667512],
              [-38.648259379475512, -8.716415230086781],
              [-38.648241572321105, -8.716426132480109],
              [-38.648223875792191, -8.716437211754069],
              [-38.648206291663527, -8.716448466797537],
              [-38.648188821698575, -8.716459896481794],
              [-38.648171467649433, -8.716471499660527],
              [-38.648154231256477, -8.716483275170154],
              [-38.64813711424835, -8.716495221829641],
              [-38.64812011834168, -8.716507338440909],
              [-38.648103245241003, -8.716519623788798],
              [-38.648086496638484, -8.716532076641238],
              [-38.648069874213803, -8.71654469574934],
              [-38.64805337963405, -8.716557479847584],
              [-38.648037014553445, -8.716570427653814],
              [-38.648020780613216, -8.716583537869568],
              [-38.648004679441456, -8.716596809179984],
              [-38.647988712652932, -8.716610240254159],
              [-38.647972881848986, -8.716623829745075],
              [-38.64795718861722, -8.716637576289898],
              [-38.647941634531513, -8.716651478509945],
              [-38.647926221151806, -8.716665535011028],
              [-38.647910950023885, -8.716679744383436],
              [-38.64789582267926, -8.716694105202128],
              [-38.647880840635096, -8.71670861602686],
              [-38.647866005393901, -8.716723275402385],
              [-38.647851318443486, -8.716738081858542],
              [-38.647836781256821, -8.716753033910376],
              [-38.647822395291833, -8.716768130058409],
              [-38.647808161991293, -8.716783368788638],
              [-38.647794082782646, -8.716798748572762],
              [-38.647780159077925, -8.716814267868422],
              [-38.647766392273489, -8.716829925119162],
              [-38.64775278375005, -8.716845718754815],
              [-38.647739334872384, -8.716861647191365],
              [-38.647726046989305, -8.716877708831422],
              [-38.647712921433417, -8.716893902064159],
              [-38.647699959521148, -8.716910225265591],
              [-38.64768716255238, -8.716926676798673],
              [-38.64767453181058, -8.716943255013495],
              [-38.647662068562468, -8.716959958247504],
              [-38.647649774057982, -8.716976784825484],
              [-38.647637649530168, -8.716993733059917],
              [-38.647625696194979, -8.717010801251151],
              [-38.64761391525122, -8.71702798768739],
              [-38.647602307880454, -8.71704529064502],
              [-38.647590875246749, -8.717062708388776],
              [-38.647579618496707, -8.717080239171839],
              [-38.647568538759288, -8.717097881236077],
              [-38.647557637145667, -8.717115632812158],
              [-38.647546914749206, -8.717133492119823],
              [-38.647536372645263, -8.717151457367967],
              [-38.647526011891095, -8.717169526754864],
              [-38.647515833525823, -8.717187698468393],
              [-38.647505838570233, -8.717205970686095],
              [-38.647496028026751, -8.717224341575479],
              [-38.647486402879281, -8.717242809294165],
              [-38.647476964093144, -8.717261371990007],
              [-38.647467712614969, -8.717280027801419],
              [-38.647458649372631, -8.717298774857388],
              [-38.647449775275078, -8.717317611277812],
              [-38.647441091212308, -8.71733653517359],
              [-38.647432598055282, -8.717355544646878],
              [-38.647424296655792, -8.717374637791217],
              [-38.647416187846403, -8.717393812691792],
              [-38.64740827244038, -8.717413067425557],
              [-38.64740055123157, -8.717432400061483],
              [-38.647393024994358, -8.717451808660664],
              [-38.647385694483575, -8.71747129127672],
              [-38.647378560434419, -8.717490845955675],
              [-38.647371623562393, -8.717510470736446],
              [-38.647364884563224, -8.71753016365089],
              [-38.647358344112781, -8.71754992272399],
              [-38.647352002867045, -8.717569745974131],
              [-38.647345861461972, -8.717589631413251],
              [-38.647339920513552, -8.717609577047083],
              [-38.647334180617619, -8.717629580875263],
              [-38.647328642349841, -8.717649640891626],
              [-38.64732330626569, -8.717669755084374],
              [-38.647318172900341, -8.717689921436287],
              [-38.647313242768654, -8.717710137924872],
              [-38.647308516365058, -8.717730402522534],
              [-38.647307546209667, -8.717734757620642],
              [-38.647306619007985, -8.717739121971404],
              [-38.647305734849155, -8.717743495155526],
              [-38.647304893818145, -8.71774787675276],
              [-38.647304095995729, -8.717752266342059],
              [-38.64730334145861, -8.717756663501621],
              [-38.647302630279256, -8.717761067808953],
              [-38.647301962526058, -8.717765478840782],
              [-38.647301338263134, -8.717769896173351],
              [-38.647300757550482, -8.717774319382102],
              [-38.647300220443924, -8.717778748042029],
              [-38.64729972699508, -8.717783181727615],
              [-38.647299277251328, -8.717787620012801],
              [-38.647298871255913, -8.717792062471082],
              [-38.647298509047836, -8.717796508675617],
              [-38.647298190661914, -8.717800958199151],
              [-38.647297916128736, -8.717805410614117],
              [-38.64729768547469, -8.717809865492702],
              [-38.64729749872194, -8.717814322406817],
              [-38.647297355888426, -8.717818780928203],
              [-38.647297256987876, -8.717823240628423],
              [-38.647297202029804, -8.717827701078974],
              [-38.64729719101949, -8.717832161851211],
              [-38.647297223957978, -8.717836622516538],
              [-38.647297300842105, -8.717841082646286],
              [-38.647297421664511, -8.717845541811887],
              [-38.647297586413572, -8.717849999584857],
              [-38.647297795073463, -8.71785445553687],
              [-38.647298047624112, -8.717858909239711],
              [-38.647298344041289, -8.717863360265449],
              [-38.647298684296501, -8.717867808186359],
              [-38.647299068357029, -8.71787225257505],
              [-38.647299496186029, -8.717876693004483],
              [-38.64729996774232, -8.717881129047932],
              [-38.647300482980633, -8.717885560279141],
              [-38.647301041851449, -8.717889986272338],
              [-38.647301644301074, -8.717894406602205],
              [-38.647302290271597, -8.71789882084397],
              [-38.647302979700981, -8.717903228573519],
              [-38.647303712522969, -8.717907629367277],
              [-38.647304488667132, -8.717912022802357],
              [-38.647305308058911, -8.717916408456576],
              [-38.647306170619551, -8.717920785908582],
              [-38.647307076266173, -8.717925154737696],
              [-38.647308024911773, -8.717929514524105],
              [-38.647309016465179, -8.717933864848876],
              [-38.647310050831109, -8.717938205294011],
              [-38.6473111279102, -8.717942535442418],
              [-38.647312247598926, -8.717946854877997],
              [-38.647313409789703, -8.717951163185703],
              [-38.647314614370885, -8.717955459951556],
              [-38.647315861226666, -8.717959744762654],
              [-38.647317150237278, -8.717964017207299],
              [-38.64731848127888, -8.717968276874897],
              [-38.647319854223525, -8.717972523356185],
              [-38.647321268939301, -8.717976756243065],
              [-38.64732272529028, -8.717980975128848],
              [-38.647324223136522, -8.717985179608107],
              [-38.647325762334084, -8.717989369276838],
              [-38.647327342735075, -8.717993543732463],
              [-38.647328964187643, -8.717997702573829],
              [-38.647330626535961, -8.718001845401316],
              [-38.647332329620319, -8.718005971816842],
              [-38.647334073277044, -8.718010081423898],
              [-38.647335857338597, -8.718014173827589],
              [-38.647337681633545, -8.71801824863471],
              [-38.647339545986625, -8.718022305453646],
              [-38.647341450218633, -8.71802634389457],
              [-38.647343394146617, -8.71803036356946],
              [-38.647345377583783, -8.718034364092077],
              [-38.647347400339562, -8.718038345077984],
              [-38.647349462219566, -8.718042306144634],
              [-38.647351563025673, -8.71804624691141],
              [-38.647353702556018, -8.718050166999671],
              [-38.647355880605012, -8.718054066032707],
              [-38.647358096963352, -8.71805794363584],
              [-38.647360351418087, -8.718061799436498],
              [-38.647362643752587, -8.71806563306415],
              [-38.647364973746562, -8.718069444150435],
              [-38.647367341176128, -8.718073232329143],
              [-38.647369745813812, -8.718076997236251],
              [-38.647372187428537, -8.718080738510029],
              [-38.647374665785691, -8.718084455790908],
              [-38.647377180647126, -8.718088148721733],
              [-38.647379731771203, -8.718091816947632],
              [-38.647382318912754, -8.71809546011613],
              [-38.647384941823191, -8.718099077877165],
              [-38.647387600250497, -8.718102669883084],
              [-38.64739029393921, -8.718106235788712],
              [-38.647393022630467, -8.718109775251421],
              [-38.647395786062106, -8.718113287931088],
              [-38.647398583968574, -8.718116773490188],
              [-38.647401416081003, -8.718120231593808],
              [-38.647404282127262, -8.718123661909628],
              [-38.647407181831959, -8.71812706410803],
              [-38.647410114916461, -8.718130437862097],
              [-38.647413081098911, -8.718133782847618],
              [-38.647416080094295, -8.718137098743227],
              [-38.647419111614525, -8.718140385228695],
              [-38.647425486661206, -8.718147208313253],
              [-38.647431875166241, -8.718154018925334],
              [-38.647438277104975, -8.718160817038681],
              [-38.647444692452694, -8.718167602627041],
              [-38.647451121184687, -8.718174375664256],
              [-38.647457563276106, -8.718181136124173],
              [-38.647464018702109, -8.718187883980724],
              [-38.647470487437786, -8.718194619207868],
              [-38.647476969458189, -8.718201341779588],
              [-38.647483464738286, -8.718208051670013],
              [-38.647489973253037, -8.718214748853178],
              [-38.647496494977339, -8.718221433303299],
              [-38.64750302988601, -8.71822810499455],
              [-38.647509577953826, -8.71823476390119],
              [-38.647516139155542, -8.718241409997528],
              [-38.647522713465833, -8.718248043257942],
              [-38.647529300859325, -8.718254663656822],
              [-38.647535901310626, -8.718261271168602],
              [-38.647542514794253, -8.718267865767828],
              [-38.647549141284678, -8.71827444742903],
              [-38.647555780756349, -8.718281016126806],
              [-38.647562433183658, -8.71828757183582],
              [-38.647569098540892, -8.718294114530798],
              [-38.647575776802391, -8.718300644186487],
              [-38.64758246794235, -8.718307160777652],
              [-38.647589171934975, -8.718313664279167],
              [-38.647595888754381, -8.718320154665975],
              [-38.647602618374655, -8.718326631912994],
              [-38.647609360769835, -8.718333095995236],
              [-38.647616115913912, -8.718339546887769],
              [-38.647622883780805, -8.718345984565705],
              [-38.647629664344421, -8.718352409004202],
              [-38.647636457578592, -8.718358820178484],
              [-38.647643263457091, -8.718365218063779],
              [-38.647650081953678, -8.718371602635429],
              [-38.647656913042042, -8.718377973868796],
              [-38.64766375669582, -8.718384331739253],
              [-38.647670612888604, -8.718390676222329],
              [-38.647677481593938, -8.718397007293511],
              [-38.647684362785334, -8.718403324928421],
              [-38.647691256436232, -8.718409629102588],
              [-38.64769816252003, -8.718415919791747],
              [-38.647705081010095, -8.718422196971641],
              [-38.647712011879705, -8.718428460618004],
              [-38.64771895510215, -8.718434710706696],
              [-38.647725910650628, -8.718440947213606],
              [-38.647732878498296, -8.71844717011466],
              [-38.647739858618273, -8.71845337938583],
              [-38.647746850983616, -8.718459575003186],
              [-38.647753855567366, -8.7184657569428],
              [-38.647760872342502, -8.718471925180852],
              [-38.64776790128191, -8.718478079693526],
              [-38.64777494235851, -8.718484220457031],
              [-38.647781995545117, -8.718490347447734],
              [-38.64778906081451, -8.718496460641965],
              [-38.647796138139448, -8.718502560016146],
              [-38.647803227492602, -8.718508645546743],
              [-38.647810328846646, -8.718514717210269],
              [-38.647817442174144, -8.718520774983276],
              [-38.647824567447678, -8.718526818842436],
              [-38.647831704639756, -8.718532848764397],
              [-38.647838853722817, -8.718538864725904],
              [-38.647846014669298, -8.718544866703706],
              [-38.647853187451553, -8.718550854674712],
              [-38.647860372041919, -8.718556828615757],
              [-38.647867568412657, -8.718562788503828],
              [-38.647874776536028, -8.718568734315928],
              [-38.647881996384207, -8.718574666029085],
              [-38.647889227929333, -8.71858058362044],
              [-38.647896471143511, -8.718586487067146],
              [-38.647903725998773, -8.718592376346427],
              [-38.64791099246716, -8.718598251435568],
              [-38.647918270520606, -8.718604112311908],
              [-38.647925560131036, -8.718609958952813],
              [-38.647932861270327, -8.718615791335713],
              [-38.647940173910314, -8.718621609438111],
              [-38.647947498022788, -8.718627413237591],
              [-38.647954833579462, -8.718633202711725],
              [-38.647962180552049, -8.718638977838172],
              [-38.6479695389122, -8.718644738594666],
              [-38.647976908631527, -8.71865048495895],
              [-38.647984289681595, -8.718656216908899],
              [-38.647991682033904, -8.718661934422363],
              [-38.647999085659961, -8.718667637477299],
              [-38.648006500531174, -8.718673326051704],
              [-38.648013926618951, -8.71867900012359],
              [-38.648021363894642, -8.7186846596711],
              [-38.648028812329528, -8.718690304672364],
              [-38.648036271894881, -8.71869593510565],
              [-38.648043742561939, -8.718701550949188],
              [-38.648051224301838, -8.718707152181345],
              [-38.648058717085746, -8.718712738780461],
              [-38.648066220884729, -8.718718310725029],
              [-38.648073735669847, -8.718723867993527],
              [-38.648081261412095, -8.718729410564507],
              [-38.648088798082448, -8.718734938416601],
              [-38.648096345651801, -8.718740451528488],
              [-38.648103904091066, -8.718745949878837],
              [-38.648111473371074, -8.718751433446462],
              [-38.648111459292281, -8.718751452991814],
              [-38.648115153694512, -8.718754118590352],
              [-38.648118850672873, -8.71875678065096],
              [-38.648122550223896, -8.718759439171105],
              [-38.648126252344177, -8.718762094148376],
              [-38.648129957030314, -8.718764745580277],
              [-38.648133664278845, -8.718767393464377],
              [-38.648137374086332, -8.71877003779823],
              [-38.648141086449364, -8.718772678579391],
              [-38.648144801364516, -8.718775315805393],
              [-38.648148518828314, -8.718777949473795],
              [-38.648152238837326, -8.718780579582203],
              [-38.648155961388113, -8.718783206128128],
              [-38.648159686477229, -8.718785829109152],
              [-38.648163414101241, -8.718788448522851],
              [-38.648167144256661, -8.718791064366805],
              [-38.648170876940071, -8.718793676638612],
              [-38.648174612148004, -8.718796285335813],
              [-38.648178349876986, -8.718798890456029],
              [-38.648182090123576, -8.718801491996793],
              [-38.648185832884323, -8.718804089955752],
              [-38.648189578155709, -8.718806684330497],
              [-38.648193325934329, -8.718809275118609],
              [-38.648197076216668, -8.718811862317686],
              [-38.648200828999293, -8.718814445925327],
              [-38.648204584278695, -8.718817025939167],
              [-38.648208342051419, -8.718819602356829],
              [-38.648212102313977, -8.718822175175857],
              [-38.648215865062895, -8.718824744393929],
              [-38.648219630294683, -8.718827310008642],
              [-38.64822339800584, -8.71882987201764],
              [-38.648227168192903, -8.718832430418555],
              [-38.648230940852379, -8.718834985208979],
              [-38.64823471598077, -8.718837536386605],
              [-38.648238493574581, -8.718840083949027],
              [-38.648242273630309, -8.718842627893872],
              [-38.648246056144458, -8.718845168218827],
              [-38.648249841113525, -8.718847704921517],
              [-38.648253628534022, -8.718850237999607],
              [-38.64825741840243, -8.718852767450743],
              [-38.648261210715226, -8.718855293272584],
              [-38.648265005468907, -8.718857815462783],
              [-38.648268802659999, -8.71886033401902],
              [-38.648272602284912, -8.718862848938972],
              [-38.648276404340201, -8.718865360220301],
              [-38.648280208822307, -8.718867867860654],
              [-38.648284015727718, -8.718870371857749],
              [-38.648287825052897, -8.718872872209261],
              [-38.64829163679434, -8.71887536891286],
              [-38.648295450948495, -8.718877861966215],
              [-38.648299267511845, -8.718880351367083],
              [-38.648303086480858, -8.718882837113089],
              [-38.648306907852003, -8.718885319202],
              [-38.648310731621727, -8.718887797631462],
              [-38.648314557786485, -8.718890272399189],
              [-38.648318386342751, -8.718892743502924],
              [-38.648322217286982, -8.718895210940339],
              [-38.648326050615616, -8.71889767470917],
              [-38.648329886325122, -8.718900134807109],
              [-38.648333724411941, -8.718902591231945],
              [-38.648337564872506, -8.718905043981328],
              [-38.648341407703292, -8.718907493053038],
              [-38.648345252900718, -8.718909938444776],
              [-38.648349100461225, -8.718912380154302],
              [-38.648352950381259, -8.718914818179329],
              [-38.648356802657254, -8.718917252517642],
              [-38.64836065728565, -8.71891968316697],
              [-38.648364514262852, -8.718922110125025],
              [-38.648368373585321, -8.71892453338959],
              [-38.648372235249475, -8.718926952958427],
              [-38.648376099251728, -8.718929368829286],
              [-38.648379965588518, -8.718931780999931],
              [-38.648383834256251, -8.718934189468145],
              [-38.648387705251345, -8.718936594231669],
              [-38.648391578570234, -8.718938995288307],
              [-38.648395454209322, -8.718941392635802],
              [-38.64839933216502, -8.718943786271993],
              [-38.648403212433728, -8.718946176194583],
              [-38.648407095011876, -8.718948562401415],
              [-38.64841097989585, -8.718950944890265],
              [-38.648414867082074, -8.718953323658946],
              [-38.648418756566933, -8.718955698705221],
              [-38.648422648346816, -8.718958070026927],
              [-38.648426542418143, -8.718960437621872],
              [-38.648430438777304, -8.718962801487816],
              [-38.648434337420689, -8.718965161622576],
              [-38.648438238344653, -8.718967518024028],
              [-38.648442141545658, -8.718969870689909],
              [-38.648446047020045, -8.718972219618099],
              [-38.648449954764203, -8.718974564806411],
              [-38.648453864774503, -8.718976906252644],
              [-38.648457777047355, -8.718979243954703],
              [-38.648461691579108, -8.718981577910325],
              [-38.648465608366159, -8.718983908117451],
              [-38.648469527404863, -8.718986234573837],
              [-38.648473448691597, -8.718988557277378],
              [-38.648477372222757, -8.718990876225908],
              [-38.648481297994671, -8.718993191417299],
              [-38.648485226003721, -8.718995502849369],
              [-38.648489156246235, -8.718997810520081],
              [-38.648493800777111, -8.71900048960627],
              [-38.648498483890499, -8.719003101357268],
              [-38.648503204600331, -8.719005645223131],
              [-38.648507961912614, -8.719008120668237],
              [-38.648512754825667, -8.719010527171331],
              [-38.6485175823303, -8.719012864225746],
              [-38.648522443410045, -8.719015131339344],
              [-38.648527337041337, -8.719017328034806],
              [-38.648532262193804, -8.719019453849578],
              [-38.648537217830395, -8.719021508336075],
              [-38.648542202907656, -8.719023491061684],
              [-38.64854721637596, -8.719025401608912],
              [-38.648552257179645, -8.719027239575478],
              [-38.648557324257347, -8.7190290045744],
              [-38.648562416542148, -8.719030696234064],
              [-38.648567532961813, -8.719032314198239],
              [-38.648572672439023, -8.719033858126274],
              [-38.648577833891643, -8.719035327693051],
              [-38.648583016232884, -8.719036722589154],
              [-38.648588218371522, -8.71903804252087],
              [-38.648593439212242, -8.719039287210292],
              [-38.648598677655706, -8.719040456395319],
              [-38.648603932598967, -8.719041549829779],
              [-38.648609202935511, -8.719042567283406],
              [-38.648614487555626, -8.719043508542057],
              [-38.648619785346611, -8.719044373407451],
              [-38.648625095192948, -8.719045161697522],
              [-38.648630415976598, -8.71904587324628],
              [-38.648635746577234, -8.719046507903908],
              [-38.648641085872455, -8.719047065536774],
              [-38.648646432738012, -8.719047546027449],
              [-38.648651786048063, -8.719047949274792],
              [-38.648657144675482, -8.719048275193837],
              [-38.648662507491906, -8.719048523716001],
              [-38.64866787336814, -8.719048694788945],
              [-38.648673241174386, -8.719048788376684],
              [-38.648678609780369, -8.719048804459439],
              [-38.648683978055715, -8.719048743033909],
              [-38.648689344870071, -8.719048604112951],
              [-38.648694709093398, -8.719048387725852],
              [-38.648700069596231, -8.719048093918161],
              [-38.648705425249858, -8.719047722751776],
              [-38.648710774926577, -8.71904727430482],
              [-38.648716117500008, -8.719046748671715],
              [-38.648721451845198, -8.719046145963148],
              [-38.648726776838956, -8.719045466306046],
              [-38.648732091360067, -8.719044709843462],
              [-38.648737394289476, -8.719043876734714],
              [-38.648742684510658, -8.719042967155209],
              [-38.648747960909667, -8.719041981296471],
              [-38.648753222375539, -8.71904091936608],
              [-38.648758467800391, -8.719039781587629],
              [-38.64876369607979, -8.71903856820072],
              [-38.648768906112878, -8.7190372794608],
              [-38.64877409680259, -8.719035915639243],
              [-38.648779267056014, -8.719034477023193],
              [-38.648784415784498, -8.719032963915584],
              [-38.648789541903938, -8.719031376635032],
              [-38.648794644334991, -8.71902971551569],
              [-38.648799722003275, -8.719027980907409],
              [-38.648804773839657, -8.719026173175347],
              [-38.648809798780405, -8.71902429270019],
              [-38.648814795767507, -8.719022339877847],
              [-38.64881976374879, -8.719020315119542],
              [-38.648824701678201, -8.719018218851584],
              [-38.648829608516003, -8.719016051515366],
              [-38.648834483229031, -8.719013813567235],
              [-38.648839324790877, -8.719011505478436],
              [-38.648844132182091, -8.719009127734918],
              [-38.648848904390448, -8.719006680837353],
              [-38.648853640411133, -8.719004165300966],
              [-38.648858339246885, -8.719001581655425],
              [-38.648862999908367, -8.718998930444723],
              [-38.64886762141424, -8.718996212227099],
              [-38.648872202791374, -8.718993427574903],
              [-38.648876743075149, -8.718990577074463],
              [-38.648881241309553, -8.718987661325999],
              [-38.648885696547431, -8.718984680943425],
              [-38.648890107850725, -8.718981636554293],
              [-38.648894474290593, -8.718978528799623],
              [-38.648898794947627, -8.718975358333791],
              [-38.64890306891207, -8.718972125824335],
              [-38.648907295284019, -8.718968831951901],
              [-38.648911473173577, -8.718965477410066],
              [-38.648915601701034, -8.718962062905142],
              [-38.648919679997121, -8.718958589156106],
              [-38.64892370720311, -8.718955056894362],
              [-38.648927682471005, -8.718951466863633],
              [-38.648931604963835, -8.718947819819883],
              [-38.648935473855651, -8.71894411653099],
              [-38.648939288331839, -8.718940357776743],
              [-38.648943047589221, -8.718936544348569],
              [-38.648946750836245, -8.718932677049441],
              [-38.648950397293198, -8.718928756693611],
              [-38.648953986192247, -8.718924784106557],
              [-38.648957516777742, -8.718920760124746],
              [-38.648960988306278, -8.71891668559547],
              [-38.648964400046914, -8.718912561376619],
              [-38.648967751281262, -8.718908388336637],
              [-38.648973258505798, -8.718901594917266],
              [-38.648978926057765, -8.718894933313592],
              [-38.648984750751161, -8.718888407270439],
              [-38.648990729311627, -8.718882020456423],
              [-38.648996858378347, -8.718875776461873],
              [-38.649003134505847, -8.718869678796802],
              [-38.649009554165985, -8.718863730889051],
              [-38.649016113750015, -8.718857936082191],
              [-38.649022809570425, -8.718852297633788],
              [-38.649029637863201, -8.718846818713441],
              [-38.649036594789813, -8.718841502401162],
              [-38.649043676439426, -8.718836351685464],
              [-38.649050878831119, -8.718831369461832],
              [-38.649058197916069, -8.718826558531021],
              [-38.649065629579873, -8.718821921597469],
              [-38.649073169644829, -8.718817461267831],
              [-38.649080813872295, -8.71881318004945],
              [-38.649088557965115, -8.718809080349017],
              [-38.649096397569934, -8.718805164471158],
              [-38.649104328279726, -8.718801434617216],
              [-38.649112345636283, -8.718797892883863],
              [-38.64912044513266, -8.718794541262124],
              [-38.649128622215734, -8.718791381636066],
              [-38.649136872288771, -8.71878841578188],
              [-38.649145190714037, -8.718785645366792],
              [-38.649153572815322, -8.718783071948247],
              [-38.649162013880662, -8.71878069697285],
              [-38.649170509164925, -8.718778521775677],
              [-38.649179053892503, -8.718776547579544],
              [-38.649187643260021, -8.718774775494198],
              [-38.649196272438957, -8.718773206515838],
              [-38.649204936578464, -8.718771841526475],
              [-38.649213630807999, -8.718770681293405],
              [-38.649222350240123, -8.718769726468855],
              [-38.649231089973249, -8.718768977589599],
              [-38.649239845094343, -8.718768435076589],
              [-38.649248610681745, -8.718768099234842],
              [-38.649257381807885, -8.718767970253102],
              [-38.649266153542115, -8.718768048203913],
              [-38.64927492095341, -8.718768333043416],
              [-38.649283679113204, -8.718768824611541],
              [-38.649292423098125, -8.718769522631909],
              [-38.649301147992752, -8.718770426712142],
              [-38.649309848892393, -8.71877153634404],
              [-38.649318520905886, -8.718772850903797],
              [-38.649327159158283, -8.718774369652458],
              [-38.649335758793576, -8.718776091736245],
              [-38.649344314977533, -8.718778016187093],
              [-38.649352822900305, -8.718780141923222],
              [-38.649361277779185, -8.718782467749589],
              [-38.649369674861276, -8.71878499235876],
              [-38.649378009426194, -8.718787714331555],
              [-38.649386276788654, -8.718790632137788],
              [-38.649394472301196, -8.718793744137264],
              [-38.649402591356733, -8.718797048580557],
              [-38.649410629391149, -8.718800543610076],
              [-38.649418581885868, -8.718804227261117],
              [-38.649426444370427, -8.718808097462897],
              [-38.64943421242495, -8.718812152039806],
              [-38.649441881682613, -8.7188163887126],
              [-38.649449447832176, -8.718820805099583],
              [-38.649456906620351, -8.718825398718147],
              [-38.64946425385417, -8.718830166985985],
              [-38.649471485403417, -8.71883510722262],
              [-38.649478597202865, -8.718840216650918],
              [-38.649485585254652, -8.718845492398602],
              [-38.649492445630422, -8.718850931499965],
              [-38.649499174473668, -8.718856530897396],
              [-38.649505768001738, -8.71886228744321],
              [-38.649512222508115, -8.718868197901358],
              [-38.649518534364383, -8.718874258949313],
              [-38.649524700022361, -8.718880467179886],
              [-38.649530716015981, -8.718886819103103],
              [-38.649536578963421, -8.718893311148248],
              [-38.649542285568792, -8.718899939665851],
              [-38.649547832624123, -8.718906700929693],
              [-38.649553217011153, -8.718913591138914],
              [-38.649558435703042, -8.718920606420223],
              [-38.649563485766116, -8.718927742829994],
              [-38.649568364361457, -8.718934996356484],
              [-38.649573068746584, -8.718942362922171],
              [-38.649577596276899, -8.718949838385941],
              [-38.649581944407281, -8.718957418545429],
              [-38.649586110693384, -8.718965099139528],
              [-38.649590092793154, -8.718972875850563],
              [-38.649593888468061, -8.718980744306869],
              [-38.649597495584324, -8.718988700085239],
              [-38.649600912114202, -8.718996738713319],
              [-38.649604136137121, -8.7190048556722],
              [-38.649607165840649, -8.71901304639896],
              [-38.649609999521651, -8.719021306289179],
              [-38.649612635587133, -8.71902963069957],
              [-38.649615072555271, -8.719038014950604],
              [-38.649617309056069, -8.719046454329044],
              [-38.649619343832292, -8.719054944090759],
              [-38.649621175740073, -8.719063479463191],
              [-38.649622803749594, -8.719072055648189],
              [-38.649624226945647, -8.719080667824699],
              [-38.649625444527693, -8.719089311151548],
              [-38.649626510319706, -8.719098137044675],
              [-38.649627437220573, -8.719106978445545],
              [-38.649628225003489, -8.719115833190616],
              [-38.649628873475649, -8.719124699113104],
              [-38.649629382478373, -8.719133574043385],
              [-38.64962975188709, -8.719142455809751],
              [-38.64962998161139, -8.719151342238792],
              [-38.649630071595041, -8.71916023115585],
              [-38.649630021816044, -8.719169120385814],
              [-38.649629832286521, -8.719178007753397],
              [-38.649629503052893, -8.719186891083782],
              [-38.649629034195677, -8.719195768203175],
              [-38.649628425829619, -8.719204636939272],
              [-38.649627678103563, -8.719213495121858],
              [-38.649626791200483, -8.71922234058321],
              [-38.649625765337397, -8.719231171158803],
              [-38.649624600765343, -8.719239984687761],
              [-38.649623297769288, -8.71924877901329],
              [-38.649621856668055, -8.719257551983425],
              [-38.649620277814314, -8.719266301451285],
              [-38.649618561594394, -8.719275025275849],
              [-38.649616708428255, -8.719283721322332],
              [-38.649614718769378, -8.719292387462747],
              [-38.649612593104635, -8.719301021576392],
              [-38.649610331954179, -8.719309621550474],
              [-38.649607935871337, -8.719318185280468],
              [-38.649605405442429, -8.719326710670794],
              [-38.64960274128665, -8.719335195635262],
              [-38.64959994405595, -8.719343638097461],
              [-38.649597014434818, -8.719352035991511],
              [-38.649593953140148, -8.719360387262343],
              [-38.649590760921043, -8.71936868986637],
              [-38.649587438558669, -8.719376941771865],
              [-38.649583986866006, -8.719385140959519],
              [-38.649580406687704, -8.719393285422942],
              [-38.649576698899885, -8.719401373169143],
              [-38.649572864409841, -8.719409402218917],
              [-38.649568904155885, -8.719417370607543],
              [-38.64956481910712, -8.719425276385097],
              [-38.649560610263201, -8.719433117616973],
              [-38.649556278654032, -8.719440892384322],
              [-38.649551825339586, -8.719448598784673],
              [-38.649547251409651, -8.71945623493213],
              [-38.649542557983466, -8.719463798958095],
              [-38.649537746209525, -8.719471289011619],
              [-38.649532817265339, -8.719478703259803],
              [-38.649527772357018, -8.719486039888308],
              [-38.649522612719124, -8.719493297101835],
              [-38.649517339614214, -8.719500473124452],
              [-38.649511954332688, -8.719507566200148],
              [-38.649506458192349, -8.719514574593198],
              [-38.649500852538118, -8.719521496588591],
              [-38.649495138741777, -8.719528330492457],
              [-38.649489318201489, -8.71953507463247],
              [-38.649483392341622, -8.719541727358289],
              [-38.649477362612245, -8.719548287041974],
              [-38.649471230488892, -8.719554752078313],
              [-38.649464997472151, -8.719561120885214],
              [-38.649458665087252, -8.719567391904194],
              [-38.649452234883803, -8.719573563600731],
              [-38.649445708435294, -8.719579634464486],
              [-38.649439087338827, -8.719585603009909],
              [-38.64943237321463, -8.719591467776446],
              [-38.649425567705691, -8.719597227328972],
              [-38.649418672477353, -8.719602880258044],
              [-38.649411689216969, -8.719608425180322],
              [-38.649404619633366, -8.719613860738958],
              [-38.649397465456531, -8.719619185603833],
              [-38.649390228437142, -8.719624398471888],
              [-38.649382910346162, -8.719629498067468],
              [-38.649375512974402, -8.719634483142714],
              [-38.64936803813201, -8.719639352477696],
              [-38.649360487648188, -8.719644104880874],
              [-38.649352863370574, -8.719648739189298],
              [-38.649345167164874, -8.719653254268881],
              [-38.649337400914455, -8.719657649014787],
              [-38.649329566519739, -8.719661922351552],
              [-38.649321665897865, -8.719666073233478],
              [-38.649313700982212, -8.719670100644779],
              [-38.649305673721841, -8.719674003599968],
              [-38.64929758608109, -8.719677781143938],
              [-38.649289440039063, -8.719681432352278],
              [-38.64928123758915, -8.7196849563315],
              [-38.649272980738573, -8.71968835221927],
              [-38.649264671507872, -8.71969161918461],
              [-38.649256311930351, -8.719694756428048],
              [-38.649247904051677, -8.71969776318185],
              [-38.64923944992934, -8.719700638710236],
              [-38.649230951632113, -8.719703382309619],
              [-38.649222411239613, -8.719705993308553],
              [-38.649213830841731, -8.719708471068119],
              [-38.649205212538163, -8.719710814981976],
              [-38.649196558437907, -8.719713024476572],
              [-38.64918787065865, -8.719715099011196],
              [-38.649179151326379, -8.719717038078214],
              [-38.649170402574811, -8.719718841203129],
              [-38.649161626544817, -8.719720507944672],
              [-38.64915282538395, -8.719722037895018],
              [-38.649144001246391, -8.719723430679196],
              [-38.649134746384348, -8.719724895057217],
              [-38.649125516916193, -8.719726510316926],
              [-38.649116315326602, -8.719728276023471],
              [-38.649107144092724, -8.719730191701492],
              [-38.649098005683598, -8.719732256835275],
              [-38.649088902559363, -8.719734470868886],
              [-38.649079837170682, -8.719736833206241],
              [-38.649070811958069, -8.719739343211403],
              [-38.64906182935119, -8.719742000208633],
              [-38.649052891768285, -8.719744803482625],
              [-38.64904400161543, -8.719747752278765],
              [-38.64903516128598, -8.719750845803135],
              [-38.649026373159856, -8.719754083222952],
              [-38.649017639602889, -8.719757463666681],
              [-38.649008962966263, -8.719760986224227],
              [-38.64900034558584, -8.719764649947333],
              [-38.648991789781491, -8.719768453849625],
              [-38.648983297856567, -8.719772396907116],
              [-38.64897487209717, -8.719776478058254],
              [-38.648966514771622, -8.719780696204319],
              [-38.6489582281298, -8.71978505020976],
              [-38.648950014402566, -8.71978953890245],
              [-38.648941875801171, -8.719794161073954],
              [-38.648933814516589, -8.719798915479979],
              [-38.648925832719023, -8.719803800840511],
              [-38.648917932557261, -8.719808815840414],
              [-38.648910116158149, -8.719813959129576],
              [-38.648902385625902, -8.719819229323337],
              [-38.648894743041701, -8.719824625002964],
              [-38.648887190463029, -8.719830144715823],
              [-38.648879729923088, -8.719835786975962],
              [-38.648872363430407, -8.719841550264412],
              [-38.648865092968073, -8.71984743302967],
              [-38.648857920493427, -8.719853433687975],
              [-38.648850847937339, -8.719859550623882],
              [-38.648843877203852, -8.719865782190714],
              [-38.648837010169586, -8.719872126710785],
              [-38.648830248683197, -8.719878582476124],
              [-38.648823594565002, -8.719885147748737],
              [-38.648817049606329, -8.719891820761173],
              [-38.648810615569161, -8.719898599717022],
              [-38.648804294185659, -8.719905482791276],
              [-38.648798087157601, -8.719912468130918],
              [-38.648791996155971, -8.719919553855471],
              [-38.648786022820566, -8.719926738057328],
              [-38.648780168759501, -8.719934018802428],
              [-38.648774435548724, -8.719941394130709],
              [-38.648768824731704, -8.719948862056665],
              [-38.648763337818963, -8.719956420569828],
              [-38.648757976287619, -8.719964067635367],
              [-38.648752741581077, -8.719971801194596],
              [-38.648747635108599, -8.719979619165573],
              [-38.648742658244906, -8.719987519443546],
              [-38.648737812329834, -8.719995499901762],
              [-38.648733098667982, -8.720003558391678],
              [-38.648728518528287, -8.720011692743958],
              [-38.64872407314386, -8.720019900768699],
              [-38.648719763711391, -8.720028180256186],
              [-38.648715591391074, -8.720036528977497],
              [-38.648711557306136, -8.720044944685089],
              [-38.648707662542627, -8.720053425113294],
              [-38.648703908149052, -8.72006196797912],
              [-38.648700295136159, -8.720070570982751],
              [-38.648696824476644, -8.720079231808096],
              [-38.648693497104802, -8.720087948123611],
              [-38.648690313916468, -8.720096717582718],
              [-38.648687275768594, -8.720105537824598],
              [-38.648684383479065, -8.720114406474757],
              [-38.648681637826556, -8.720123321145589],
              [-38.648679039550238, -8.720132279437204],
              [-38.648676589349591, -8.720141278937929],
              [-38.648674287884276, -8.720150317224975],
              [-38.648672135773857, -8.720159391865122],
              [-38.648670133597754, -8.720168500415381],
              [-38.648668281894949, -8.720177640423591],
              [-38.648666581163965, -8.720186809429205],
              [-38.648665031862691, -8.720196004963771],
              [-38.648663634408202, -8.720205224551757],
              [-38.648662389176749, -8.720214465711134],
              [-38.648661296503541, -8.720223725954069],
              [-38.648660356682775, -8.720233002787591],
              [-38.648659569967464, -8.7202422937143],
              [-38.648658936569419, -8.72025159623292],
              [-38.648658456659149, -8.720260907839105],
              [-38.648658130365902, -8.720270226026065],
              [-38.648657957777488, -8.72027954828523],
              [-38.648657938940417, -8.720288872106932],
              [-38.648658073859757, -8.720298194981106],
              [-38.648658362499177, -8.720307514397918],
              [-38.648658804781014, -8.720316827848407],
              [-38.648659400586205, -8.720326132825342],
              [-38.648660149754356, -8.720335426823675],
              [-38.648661052083803, -8.720344707341377],
              [-38.648662107331631, -8.720353971879995],
              [-38.64866331521376, -8.720363217945382],
              [-38.648664675405037, -8.720372443048435],
              [-38.648666187539284, -8.720381644705562],
              [-38.648667851209431, -8.720390820439647],
              [-38.648669665967951, -8.720399967779892],
              [-38.64867197434949, -8.7204107747873],
              [-38.648674419784037, -8.720421551913583],
              [-38.648677001881211, -8.720432297438185],
              [-38.648679720228806, -8.720443009645727],
              [-38.648682574392858, -8.720453686825996],
              [-38.648685563917745, -8.720464327274518],
              [-38.648688688326203, -8.720474929292534],
              [-38.648691947119474, -8.720485491187585],
              [-38.648695339777298, -8.720496011273458],
              [-38.648698865758085, -8.720506487870724],
              [-38.648702524498958, -8.720516919306869],
              [-38.648706315415801, -8.720527303916555],
              [-38.648710237903451, -8.720537640041965],
              [-38.648714291335722, -8.720547926032976],
              [-38.6487184750655, -8.720558160247535],
              [-38.648722788424898, -8.720568341051766],
              [-38.648727230725335, -8.720578466820415],
              [-38.648731801257625, -8.720588535936907],
              [-38.648736499292141, -8.720598546793825],
              [-38.648741324078841, -8.720608497793004],
              [-38.648746274847539, -8.720618387345741],
              [-38.648751350807842, -8.720628213873326],
              [-38.64875655114944, -8.720637975806989],
              [-38.648761875042126, -8.720647671588258],
              [-38.648767321635987, -8.720657299669305],
              [-38.648772890061515, -8.720666858513059],
              [-38.648778579429781, -8.720676346593482],
              [-38.648784388832482, -8.720685762395892],
              [-38.648790317342197, -8.720695104417096],
              [-38.648796364012519, -8.720704371165695],
              [-38.648802527878097, -8.72071356116232],
              [-38.648808807954943, -8.720722672939841],
              [-38.648815203240488, -8.720731705043608],
              [-38.648821712713776, -8.72074065603168],
              [-38.648828335335594, -8.720749524475144],
              [-38.648835070048705, -8.720758308958159],
              [-38.648841915777957, -8.720767008078351],
              [-38.648848871430488, -8.720775620446991],
              [-38.648855935895867, -8.720784144689089],
              [-38.648863108046299, -8.720792579443849],
              [-38.648870386736817, -8.720800923364703],
              [-38.648877770805399, -8.720809175119582],
              [-38.648885259073253, -8.720817333391166],
              [-38.648892850344922, -8.72082539687702],
              [-38.648900543408502, -8.720833364289886],
              [-38.648908337035863, -8.720841234357753],
              [-38.648916229982795, -8.720849005824258],
              [-38.648924220989244, -8.720856677448719],
              [-38.6489323087795, -8.720864248006404],
              [-38.648940492062408, -8.720871716288686],
              [-38.648948769531543, -8.720879081103371],
              [-38.648957139865459, -8.720886341274669],
              [-38.648965601727909, -8.72089349564353],
              [-38.648974153768002, -8.720900543067822],
              [-38.648982794620458, -8.720907482422419],
              [-38.648991522905817, -8.720914312599506],
              [-38.64900033723066, -8.720921032508736],
              [-38.649009236187837, -8.720927641077273],
              [-38.649018218356701, -8.720934137250108],
              [-38.649027282303287, -8.720940519990153],
              [-38.649036426580608, -8.720946788278454],
              [-38.649045649728812, -8.720952941114295],
              [-38.649054950275506, -8.720958977515414],
              [-38.649064326735903, -8.720964896518145],
              [-38.649073777613104, -8.720970697177544],
              [-38.649083301398349, -8.720976378567565],
              [-38.649092896571219, -8.720981939781241],
              [-38.649102561599882, -8.720987379930717],
              [-38.649112294941389, -8.720992698147496],
              [-38.649122095041882, -8.720997893582576],
              [-38.649131960336817, -8.721002965406562],
              [-38.649141889251268, -8.721007912809714],
              [-38.649151880200137, -8.721012735002235],
              [-38.649161931588431, -8.721017431214301],
              [-38.649172041811497, -8.721022000696127],
              [-38.649182209255294, -8.7210264427183],
              [-38.649192432296672, -8.721030756571626],
              [-38.649202709303566, -8.721034941567453],
              [-38.64921303863531, -8.721038997037651],
              [-38.64922341864289, -8.721042922334782],
              [-38.649233847669201, -8.721046716832175],
              [-38.649244324049285, -8.721050379924094],
              [-38.649254846110679, -8.721053911025741],
              [-38.649265412173598, -8.721057309573398],
              [-38.64927602055122, -8.721060575024513],
              [-38.649286669549944, -8.721063706857739],
              [-38.649297357469763, -8.72106670457312],
              [-38.649308082604399, -8.72106956769208],
              [-38.649318843241645, -8.721072295757539],
              [-38.649329637663612, -8.721074888333961],
              [-38.649340464147045, -8.721077345007521],
              [-38.649351320963554, -8.721079665385936],
              [-38.649362206379905, -8.721081849098841],
              [-38.649373118658325, -8.72108389579757],
              [-38.649384056056682, -8.721085805155393],
              [-38.649395016828933, -8.721087576867506],
              [-38.649405999225223, -8.721089210651037],
              [-38.649417001492282, -8.721090706245162],
              [-38.649428021873646, -8.721092063411161],
              [-38.649429446131435, -8.721092243903877],
              [-38.649430870125499, -8.721092426444807],
              [-38.649432293852875, -8.721092611033537],
              [-38.649433717310593, -8.721092797669717],
              [-38.649435140495662, -8.721092986352941],
              [-38.649436563405104, -8.721093177082794],
              [-38.649437986035963, -8.721093369858909],
              [-38.649439408385255, -8.721093564680853],
              [-38.649440830450018, -8.72109376154825],
              [-38.649442252227281, -8.721093960460673],
              [-38.649443673714067, -8.721094161417717],
              [-38.649445094907385, -8.721094364418958],
              [-38.649446515804307, -8.721094569463956],
              [-38.649447936401842, -8.721094776552306],
              [-38.649449356697019, -8.721094985683559],
              [-38.649450776686876, -8.721095196857286],
              [-38.649452196368443, -8.721095410073055],
              [-38.649453615738757, -8.721095625330394],
              [-38.649455034794833, -8.721095842628877],
              [-38.649456453533752, -8.721096061968032],
              [-38.649457871952499, -8.721096283347419],
              [-38.649459290048135, -8.721096506766557],
              [-38.649460707817703, -8.72109673222498],
              [-38.649462125258218, -8.721096959722246],
              [-38.649463542366732, -8.721097189257824],
              [-38.649464959140303, -8.721097420831278],
              [-38.649466375575926, -8.721097654442115],
              [-38.649467791670673, -8.721097890089846],
              [-38.649469207421596, -8.721098127773992],
              [-38.649470622825703, -8.721098367494035],
              [-38.649472037880052, -8.721098609249474],
              [-38.649473452581688, -8.721098853039861],
              [-38.64947486692764, -8.721099098864595],
              [-38.649476280914982, -8.721099346723211],
              [-38.649477694540757, -8.721099596615177],
              [-38.649479107801973, -8.721099848540005],
              [-38.649480520695718, -8.721100102497132],
              [-38.649481933219015, -8.721100358486041],
              [-38.649483345368921, -8.721100616506197],
              [-38.649484757142488, -8.721100876557065],
              [-38.649486168536761, -8.721101138638087],
              [-38.649487579548783, -8.721101402748713],
              [-38.649488990175627, -8.721101668888393],
              [-38.649490400414329, -8.7211019370566],
              [-38.649491810261964, -8.721102207252741],
              [-38.649493219715545, -8.721102479476286],
              [-38.649494628772182, -8.721102753726637],
              [-38.649496037428875, -8.721103030003222],
              [-38.649497445682712, -8.721103308305461],
              [-38.649498853530751, -8.721103588632802],
              [-38.649500260970044, -8.721103870984612],
              [-38.649501667997647, -8.721104155360322],
              [-38.649503074610607, -8.721104441759362],
              [-38.64950448080603, -8.721104730181105],
              [-38.649505886580933, -8.721105020624977],
              [-38.649507291932387, -8.721105313090352],
              [-38.649508696857474, -8.721105607576584],
              [-38.649510101353243, -8.721105904083133],
              [-38.649511505416754, -8.721106202609318],
              [-38.649512909045086, -8.721106503154557],
              [-38.649514312235304, -8.721106805718181],
              [-38.649515714984489, -8.721107110299585],
              [-38.649517117289669, -8.721107416898111],
              [-38.649518519147954, -8.721107725513173],
              [-38.649519920556394, -8.721108036144058],
              [-38.649521321512083, -8.72110834879015],
              [-38.649522722012073, -8.721108663450769],
              [-38.649524122053421, -8.721108980125296],
              [-38.649525521633237, -8.721109298813047],
              [-38.649526920748592, -8.721109619513371],
              [-38.649528319396552, -8.721109942225583],
              [-38.649529717574197, -8.721110266949012],
              [-38.649531115278599, -8.721110593682996],
              [-38.649532512506845, -8.721110922426824],
              [-38.649533909256007, -8.721111253179821],
              [-38.649535305523173, -8.721111585941292],
              [-38.649536701305436, -8.721111920710548],
              [-38.649538096599855, -8.72111225748689],
              [-38.64953949140353, -8.721112596269625],
              [-38.649540885713563, -8.721112937058027],
              [-38.649542279526997, -8.721113279851391],
              [-38.649543672840956, -8.721113624648995],
              [-38.649545065652532, -8.721113971450123],
              [-38.649546457958778, -8.72111432025406],
              [-38.649547849756821, -8.721114671060063],
              [-38.649549241043729, -8.721115023867402],
              [-38.649550631816616, -8.721115378675336],
              [-38.649552022072541, -8.72111573548314],
              [-38.649553411808661, -8.721116094290068],
              [-38.649554801021999, -8.721116455095354],
              [-38.649556189709699, -8.721116817898242],
              [-38.649557577868862, -8.72111718269799],
              [-38.64955896549656, -8.721117549493835],
              [-38.649560352589901, -8.721117918284998],
              [-38.649561739146002, -8.721118289070702],
              [-38.649563125161933, -8.721118661850188],
              [-38.649564510634853, -8.72111903662268],
              [-38.649565895561814, -8.721119413387392],
              [-38.649567279940058, -8.721119792143487],
              [-38.64956727251348, -8.721119818981542],
              [-38.649575838305459, -8.72112230361955],
              [-38.649584378303899, -8.721124874650071],
              [-38.649592891628608, -8.721127531808094],
              [-38.649601377402185, -8.721130274819766],
              [-38.649609834749995, -8.721133103402408],
              [-38.649618262800409, -8.72113601726446],
              [-38.649626660684767, -8.721139016105601],
              [-38.649635027537528, -8.721142099616777],
              [-38.649643362496377, -8.721145267480182],
              [-38.649651664702233, -8.721148519369308],
              [-38.649659933299461, -8.721151854948978],
              [-38.64966816743582, -8.721155273875418],
              [-38.649676366262661, -8.721158775796289],
              [-38.649684528934962, -8.721162360350625],
              [-38.649692654611428, -8.721166027168973],
              [-38.649700742454606, -8.721169775873463],
              [-38.649708791630871, -8.721173606077677],
              [-38.649716801310653, -8.721177517386892],
              [-38.649724770668428, -8.721181509397951],
              [-38.649732698882829, -8.721185581699459],
              [-38.649740585136698, -8.72118973387164],
              [-38.649748428617272, -8.721193965486611],
              [-38.649756228516132, -8.721198276108177],
              [-38.649763984029384, -8.721202665292118],
              [-38.649771694357675, -8.721207132586013],
              [-38.649779358706361, -8.721211677529459],
              [-38.649786976285483, -8.721216299654037],
              [-38.649794546309955, -8.721220998483345],
              [-38.649802067999545, -8.721225773533117],
              [-38.649809540579028, -8.721230624311181],
              [-38.649816963278226, -8.721235550317594],
              [-38.649824335332134, -8.721240551044691],
              [-38.64983165598089, -8.721245625977023],
              [-38.64983892447006, -8.721250774591567],
              [-38.649846140050457, -8.721255996357623],
              [-38.649853301978403, -8.721261290737051],
              [-38.64986040951576, -8.721266657184207],
              [-38.649867461929958, -8.721272095145942],
              [-38.649874458494168, -8.721277604061802],
              [-38.649881398487246, -8.72128318336401],
              [-38.649888281193945, -8.721288832477558],
              [-38.649895105904861, -8.721294550820174],
              [-38.649901871916612, -8.721300337802505],
              [-38.649908578531843, -8.721306192828136],
              [-38.649915225059331, -8.721312115293561],
              [-38.64992181081405, -8.721318104588409],
              [-38.64992833511721, -8.721324160095383],
              [-38.649934797296389, -8.721330281190395],
              [-38.649941196685567, -8.721336467242534],
              [-38.649947532625163, -8.721342717614224],
              [-38.64995380446215, -8.721349031661287],
              [-38.649960011550142, -8.721355408732958],
              [-38.649966153249345, -8.721361848171975],
              [-38.649972228926821, -8.721368349314631],
              [-38.649978237956319, -8.721374911490926],
              [-38.649984179718516, -8.721381534024463],
              [-38.649990053601044, -8.721388216232743],
              [-38.64999585899848, -8.721394957427025],
              [-38.650001595312467, -8.721401756912492],
              [-38.650007261951814, -8.72140861398843],
              [-38.65001285833246, -8.721415527948041],
              [-38.650018383877629, -8.721422498078768],
              [-38.650023838017795, -8.72142952366219],
              [-38.65002922019081, -8.721436603974258],
              [-38.650034529841975, -8.721443738285165],
              [-38.65003976642403, -8.721450925859676],
              [-38.650044929397268, -8.721458165956959],
              [-38.65005001822955, -8.721465457830828],
              [-38.650055032396374, -8.721472800729728],
              [-38.650059971380955, -8.721480193896841],
              [-38.650064834674247, -8.721487636570187],
              [-38.650069621775025, -8.721495127982694],
              [-38.650074332189867, -8.721502667362254],
              [-38.650078965433295, -8.72151025393179],
              [-38.650083521027774, -8.721517886909417],
              [-38.650087998503771, -8.721525565508406],
              [-38.650092397399803, -8.721533288937342],
              [-38.650096717262507, -8.721541056400238],
              [-38.650100957646622, -8.721548867096523],
              [-38.650105118115121, -8.721556720221178],
              [-38.650109198239186, -8.721564614964809],
              [-38.65011319759828, -8.72157255051372],
              [-38.650117115780219, -8.721580526050037],
              [-38.650120952381151, -8.721588540751773],
              [-38.650124707005659, -8.721596593792839],
              [-38.650128379266754, -8.721604684343303],
              [-38.650131968785949, -8.721612811569223],
              [-38.650135475193288, -8.721620974632998],
              [-38.650138898127359, -8.721629172693303],
              [-38.650142237235364, -8.721637404905209],
              [-38.650145492173181, -8.721645670420198],
              [-38.650148662605304, -8.721653968386448],
              [-38.650151748204962, -8.721662297948649],
              [-38.650154748654131, -8.721670658248357],
              [-38.650157663643569, -8.721679048423894],
              [-38.650160492872828, -8.721687467610497],
              [-38.650163236050297, -8.72169591494044],
              [-38.650165892893249, -8.721704389543099],
              [-38.650168463127621, -8.721712890545566],
              [-38.650172159781505, -8.721725550684019],
              [-38.650175744401928, -8.72173824267465],
              [-38.650179216711685, -8.721750965536083],
              [-38.650182576442276, -8.721763718284482],
              [-38.650185823333899, -8.721776499933787],
              [-38.650188957135462, -8.721789309495675],
              [-38.650191977604671, -8.721802145979602],
              [-38.650194884507933, -8.72181500839304],
              [-38.650197677620447, -8.721827895741381],
              [-38.650200356726252, -8.721840807028153],
              [-38.650202921618174, -8.72185374125492],
              [-38.650205372097837, -8.721866697421618],
              [-38.650207707975795, -8.721879674526432],
              [-38.650209929071387, -8.721892671565843],
              [-38.650212035212874, -8.721905687534916],
              [-38.650214026237364, -8.721918721427166],
              [-38.650215901990897, -8.721931772234758],
              [-38.65021766232843, -8.721944838948543],
              [-38.650219307113836, -8.721957920558175],
              [-38.650220836219887, -8.721971016052088],
              [-38.650222249528376, -8.721984124417686],
              [-38.65022354693, -8.721997244641379],
              [-38.650224728324396, -8.72201037570864],
              [-38.65022579362023, -8.722023516604143],
              [-38.6502267427351, -8.722036666311716],
              [-38.650227575595608, -8.722049823814602],
              [-38.650228292137356, -8.722062988095416],
              [-38.650228892304902, -8.722076158136192],
              [-38.650229376051847, -8.722089332918593],
              [-38.650229743340745, -8.722102511423836],
              [-38.650229994143203, -8.722115692632981],
              [-38.650230128439823, -8.722128875526737],
              [-38.65023014622021, -8.722142059085749],
              [-38.65023004748295, -8.722155242290562],
              [-38.650229832235667, -8.722168424121865],
              [-38.650229500495016, -8.722181603560342],
              [-38.650229052286633, -8.722194779586886],
              [-38.650228487645158, -8.722207951182673],
              [-38.650227806614247, -8.722221117329234],
              [-38.650227009246528, -8.72223427700847],
              [-38.650226095603657, -8.722247429202802],
              [-38.650225065756281, -8.722260572895275],
              [-38.650223919784011, -8.72227370706954],
              [-38.650222657775416, -8.722286830709976],
              [-38.650221279828131, -8.722299942801849],
              [-38.650219786048645, -8.722313042331242],
              [-38.650218176552464, -8.7223261282852],
              [-38.65021645146404, -8.722339199651897],
              [-38.650214610916741, -8.722352255420606],
              [-38.65021265505289, -8.722365294581765],
              [-38.650210584023696, -8.722378316127129],
              [-38.650208397989303, -8.722391319049819],
              [-38.650206097118712, -8.722404302344348],
              [-38.650203681589851, -8.722417265006831],
              [-38.650201151589485, -8.72243020603492],
              [-38.650198507313206, -8.722443124427945],
              [-38.650195748965508, -8.722456019186991],
              [-38.650192876759647, -8.722468889314934],
              [-38.650189890917723, -8.722481733816675],
              [-38.650186791670571, -8.722494551698949],
              [-38.650183579257863, -8.722507341970646],
              [-38.650180253927964, -8.722520103642752],
              [-38.650176815938018, -8.722532835728465],
              [-38.650173265553832, -8.722545537243285],
              [-38.650169603049939, -8.722558207205054],
              [-38.650165828709511, -8.72257084463409],
              [-38.650161942824411, -8.722583448553186],
              [-38.650157945695121, -8.722596017987742],
              [-38.650153837630675, -8.722608551965825],
              [-38.650149618948724, -8.722621049518262],
              [-38.650145289975455, -8.722633509678666],
              [-38.650140851045663, -8.722645931483553],
              [-38.650136302502467, -8.722658313972447],
              [-38.650131644697666, -8.72267065618782],
              [-38.650126877991369, -8.722682957175332],
              [-38.650122002752163, -8.722695215983805],
              [-38.650117019357019, -8.722707431665318],
              [-38.650111928191265, -8.722719603275321],
              [-38.650106729648563, -8.722731729872626],
              [-38.650101424130881, -8.722743810519511],
              [-38.650096012048458, -8.722755844281879],
              [-38.650090493819775, -8.722767830229222],
              [-38.650084869871513, -8.722779767434673],
              [-38.65007914063856, -8.722791654975259],
              [-38.650073306563883, -8.722803491931732],
              [-38.650067368098632, -8.722815277388804],
              [-38.650061325701948, -8.722827010435156],
              [-38.650055179841083, -8.722838690163567],
              [-38.650048930991233, -8.722850315670843],
              [-38.65004257963561, -8.722861886058109],
              [-38.650036126265306, -8.722873400430624],
              [-38.650029571379321, -8.722884857898068],
              [-38.650022915484499, -8.722896257574462],
              [-38.650016159095514, -8.722907598578358],
              [-38.650009302734787, -8.72291888003279],
              [-38.650002346932482, -8.722930101065421],
              [-38.649995292226436, -8.722941260808582],
              [-38.649988139162168, -8.722952358399342],
              [-38.64998088829315, -8.722963392978864],
              [-38.649974487799511, -8.722973122409375],
              [-38.649968163342656, -8.722982900937907],
              [-38.649961915303031, -8.722992727976209],
              [-38.649955744056477, -8.723002602933169],
              [-38.649949649974253, -8.72301252521472],
              [-38.649943633422943, -8.723022494223994],
              [-38.649937694764503, -8.723032509361278],
              [-38.64993183435616, -8.723042570024134],
              [-38.649926052550455, -8.723052675607397],
              [-38.649920349695186, -8.723062825503099],
              [-38.649914726133474, -8.72307301910072],
              [-38.649909182203558, -8.723083255787005],
              [-38.649903718238939, -8.723093534946218],
              [-38.649898334568327, -8.723103855960014],
              [-38.649893031515603, -8.723114218207485],
              [-38.649887809399736, -8.723124621065322],
              [-38.6498826685349, -8.723135063907716],
              [-38.649877609230352, -8.723145546106508],
              [-38.649872631790437, -8.723156067031111],
              [-38.649867736514565, -8.723166626048622],
              [-38.649862923697249, -8.723177222523912],
              [-38.649858193627999, -8.723187855819488],
              [-38.649853546591345, -8.723198525295748],
              [-38.649848982866871, -8.723209230310829],
              [-38.649844502729074, -8.7232199702208],
              [-38.64984010644752, -8.723230744379581],
              [-38.649835794286631, -8.723241552139054],
              [-38.649831566505817, -8.72325239284905],
              [-38.649827423359426, -8.723263265857481],
              [-38.64982336509668, -8.72327417051028],
              [-38.649819391961749, -8.723285106151437],
              [-38.64981550419359, -8.723296072123141],
              [-38.64981170202612, -8.723307067765717],
              [-38.649807985688049, -8.723318092417731],
              [-38.649804355402964, -8.723329145415981],
              [-38.649800811389234, -8.723340226095605],
              [-38.649797353860073, -8.723351333789935],
              [-38.649793983023443, -8.723362467830919],
              [-38.64979069908216, -8.723373627548694],
              [-38.649787502233785, -8.723384812271998],
              [-38.649784392670604, -8.723396021327968],
              [-38.64978137057971, -8.72340725404232],
              [-38.64977843614286, -8.723418509739377],
              [-38.649775589536631, -8.723429787742022],
              [-38.649772830932257, -8.723441087371848],
              [-38.649770160495677, -8.723452407949086],
              [-38.649767578387561, -8.72346374879278],
              [-38.649765084763231, -8.723475109220688],
              [-38.649762679772692, -8.723486488549453],
              [-38.649760363560652, -8.723497886094481],
              [-38.649758136266421, -8.723509301170221],
              [-38.649755998024013, -8.723520733089918],
              [-38.649753948962058, -8.723532181165961],
              [-38.649751989203814, -8.723543644709618],
              [-38.649750118867196, -8.723555123031348],
              [-38.649748338064725, -8.723566615440625],
              [-38.649746646903516, -8.723578121246161],
              [-38.649745045485325, -8.723589639755781],
              [-38.649743533906481, -8.7236011702766],
              [-38.64974211225794, -8.723612712114983],
              [-38.649740780625201, -8.723624264576644],
              [-38.649739539088401, -8.723635826966596],
              [-38.649738387722245, -8.723647398589357],
              [-38.649737326595975, -8.723658978748789],
              [-38.649736355773442, -8.723670566748298],
              [-38.649735475313051, -8.723682161890816],
              [-38.649734685267802, -8.723693763478773],
              [-38.649733985685174, -8.723705370814351],
              [-38.649733376607315, -8.723716983199248],
              [-38.649732858070834, -8.723728599934926],
              [-38.649732430106951, -8.723740220322602],
              [-38.649732092741424, -8.723751843663193],
              [-38.649731845994545, -8.723763469257527],
              [-38.649731689881165, -8.723775096406259],
              [-38.64973162441067, -8.723786724409926],
              [-38.649731649587046, -8.723798352569068],
              [-38.64973176540876, -8.723809980184196],
              [-38.649731971868846, -8.723821606555791],
              [-38.649732268954914, -8.723833230984525],
              [-38.649732656649071, -8.723844852771087],
              [-38.649733134928049, -8.723856471216326],
              [-38.649733703763054, -8.723868085621394],
              [-38.649734363119876, -8.723879695287618],
              [-38.649735112958858, -8.723891299516561],
              [-38.649735953234917, -8.723902897610206],
              [-38.649736883897504, -8.723914488870843],
              [-38.649737904890642, -8.723926072601181],
              [-38.649739016152928, -8.7239376481044],
              [-38.64974021761752, -8.723949214684215],
              [-38.649741509212127, -8.723960771644771],
              [-38.649742890859102, -8.723972318290862],
              [-38.649744362475303, -8.723983853927932],
              [-38.649745923972247, -8.723995377861989],
              [-38.649747575255972, -8.724006889399844],
              [-38.649749316227179, -8.724018387848982],
              [-38.649751146781142, -8.724029872517743],
              [-38.64975306680774, -8.724041342715209],
              [-38.649755076191489, -8.724052797751447],
              [-38.649757174811349, -8.724064236937842],
              [-38.649758946828868, -8.724073434206442],
              [-38.64976081072296, -8.724082613480258],
              [-38.649762766309117, -8.724091773850775],
              [-38.64976481339383, -8.724100914411387],
              [-38.649766951774467, -8.724110034257352],
              [-38.6497691812394, -8.724119132486072],
              [-38.649771501567969, -8.724128208197035],
              [-38.649773912530549, -8.724137260491993],
              [-38.649776413888496, -8.724146288474994],
              [-38.649779005394279, -8.724155291252497],
              [-38.649781686791364, -8.724164267933448],
              [-38.649784457814405, -8.724173217629412],
              [-38.649787318189141, -8.72418213945458],
              [-38.649790267632454, -8.724191032525901],
              [-38.649793305852455, -8.724199895963185],
              [-38.649796432548428, -8.724208728889232],
              [-38.64979964741093, -8.724217530429724],
              [-38.649802950121767, -8.724226299713603],
              [-38.649806340354054, -8.724235035872889],
              [-38.649809817772272, -8.724243738042949],
              [-38.649813382032242, -8.724252405362469],
              [-38.649817032781179, -8.724261036973616],
              [-38.649820769657786, -8.724269632022077],
              [-38.649824592292205, -8.724278189657175],
              [-38.649828500306079, -8.724286709031901],
              [-38.649832493312637, -8.724295189303048],
              [-38.64983657091669, -8.72430362963132],
              [-38.649840732714637, -8.724312029181288],
              [-38.649844978294588, -8.724320387121665],
              [-38.649849307236337, -8.724328702625177],
              [-38.649853719111434, -8.724336974868798],
              [-38.649858213483228, -8.724345203033858],
              [-38.649862789906898, -8.724353386305875],
              [-38.649867447929488, -8.724361523874991],
              [-38.649872187089962, -8.724369614935728],
              [-38.649877006919311, -8.724377658687324],
              [-38.649881906940458, -8.724385654333625],
              [-38.649886886668462, -8.724393601083305],
              [-38.649891945610442, -8.724401498149788],
              [-38.649897083265678, -8.724409344751484],
              [-38.649902299125721, -8.724417140111784],
              [-38.64990759267431, -8.724424883459127],
              [-38.649912963387528, -8.724432574027155],
              [-38.649918410733804, -8.724440211054636],
              [-38.649923934174019, -8.724447793785716],
              [-38.649929533161448, -8.724455321469923],
              [-38.649935207141993, -8.7244627933622],
              [-38.649940955554051, -8.724470208723016],
              [-38.649946777828674, -8.724477566818404],
              [-38.649952673389613, -8.724484866920129],
              [-38.649958641653377, -8.724492108305686],
              [-38.649964682029228, -8.72449929025832],
              [-38.649970793919351, -8.724506412067187],
              [-38.649976976718818, -8.724513473027475],
              [-38.649983229815696, -8.724520472440245],
              [-38.649989552591087, -8.724527409612785],
              [-38.649995944419196, -8.724534283858455],
              [-38.650002404667411, -8.724541094496916],
              [-38.650008932696309, -8.724547840854045],
              [-38.650015527859786, -8.724554522262171],
              [-38.650022189505123, -8.724561138059954],
              [-38.65002891697295, -8.724567687592616],
              [-38.650035709597454, -8.724574170211909],
              [-38.650042566706304, -8.72458058527625],
              [-38.650049487620848, -8.724586932150679],
              [-38.650056471656072, -8.724593210206999],
              [-38.650063518120753, -8.724599418823898],
              [-38.650070626317458, -8.724605557386802],
              [-38.650077795542678, -8.724611625288182],
              [-38.650085025086817, -8.724617621927498],
              [-38.650092314234342, -8.724623546711189],
              [-38.65009966226382, -8.724629399052889],
              [-38.65010706844798, -8.724635178373337],
              [-38.650114532053792, -8.724640884100513],
              [-38.650122052342574, -8.724646515669722],
              [-38.650129628569964, -8.724652072523545],
              [-38.650137259986138, -8.724657554112012],
              [-38.650144945835763, -8.724662959892587],
              [-38.650152685358158, -8.72466828933025],
              [-38.650160477787281, -8.724673541897491],
              [-38.650168322351902, -8.724678717074443],
              [-38.650176218275597, -8.724683814348886],
              [-38.650184164776867, -8.724688833216373],
              [-38.650192161069207, -8.724693773180062],
              [-38.650200206361198, -8.724698633751084],
              [-38.650208299856537, -8.724703414448344],
              [-38.6502164407542, -8.724708114798704],
              [-38.650224628248431, -8.724712734336912],
              [-38.650232861528856, -8.724717272605782],
              [-38.650241139780626, -8.724721729156096],
              [-38.65024946218437, -8.724726103546779],
              [-38.650257827916391, -8.72473039534491],
              [-38.650266236148703, -8.724734604125679],
              [-38.65027468604908, -8.724738729472502],
              [-38.650283176781208, -8.724742770977155],
              [-38.650291707504714, -8.724746728239525],
              [-38.65030027737528, -8.724750600868006],
              [-38.650308885544703, -8.72475438847926],
              [-38.650317531160972, -8.724758090698439],
              [-38.650325019259249, -8.724761291355536],
              [-38.650332479368856, -8.72476455607014],
              [-38.650339910936232, -8.724767884599997],
              [-38.65034731340991, -8.724771276698094],
              [-38.650354686240625, -8.72477473211277],
              [-38.650362028881254, -8.724778250587599],
              [-38.650369340786973, -8.72478183186155],
              [-38.650376621415226, -8.72478547566881],
              [-38.650383870225753, -8.724789181739029],
              [-38.650391086680671, -8.724792949797196],
              [-38.65039827024448, -8.724796779563722],
              [-38.650405420384175, -8.724800670754417],
              [-38.650412536569156, -8.72480462308055],
              [-38.650419618271414, -8.724808636248843],
              [-38.650426664965416, -8.724812709961508],
              [-38.650433676128287, -8.724816843916262],
              [-38.650440651239812, -8.72482103780635],
              [-38.650447589782395, -8.724825291320599],
              [-38.650454491241163, -8.724829604143338],
              [-38.650461355104007, -8.724833975954589],
              [-38.65046818086163, -8.7248384064299],
              [-38.650474968007487, -8.724842895240544],
              [-38.650481716038009, -8.724847442053452],
              [-38.650488424452426, -8.724852046531231],
              [-38.650495092752976, -8.724856708332169],
              [-38.650501720444836, -8.724861427110419],
              [-38.650508307036212, -8.72486620251577],
              [-38.650514852038341, -8.724871034193908],
              [-38.650521354965605, -8.724875921786285],
              [-38.650527815335415, -8.724880864930254],
              [-38.65053423266842, -8.724885863258999],
              [-38.650540606488427, -8.72489091640162],
              [-38.650546936322463, -8.724896023983144],
              [-38.650553221700847, -8.724901185624629],
              [-38.650559462157183, -8.724906400943039],
              [-38.650565657228405, -8.724911669551373],
              [-38.650571806454806, -8.724916991058672],
              [-38.650577909380104, -8.724922365070086],
              [-38.650583965551434, -8.724927791186861],
              [-38.650589974519413, -8.724933269006318],
              [-38.650595935838147, -8.724938798122],
              [-38.650601849065289, -8.724944378123629],
              [-38.650607713762064, -8.724950008597176],
              [-38.650613529493278, -8.72495568912484],
              [-38.65061929582739, -8.724961419285064],
              [-38.650625012336526, -8.724967198652676],
              [-38.650630678596464, -8.724973026798851],
              [-38.650636294186782, -8.724978903291095],
              [-38.650641858690783, -8.724984827693373],
              [-38.650647371695541, -8.724990799566038],
              [-38.650652832791984, -8.724996818466009],
              [-38.650658241574881, -8.725002883946633],
              [-38.650663597642868, -8.725008995557847],
              [-38.650668900598518, -8.725015152846145],
              [-38.650674150048324, -8.725021355354615],
              [-38.650679345602747, -8.725027602623035],
              [-38.650684486876273, -8.725033894187835],
              [-38.650689573487398, -8.725040229582159],
              [-38.650694605058675, -8.7250466083359],
              [-38.650699581216756, -8.725053029975715],
              [-38.650704501592351, -8.725059494025135],
              [-38.65070936582039, -8.725066000004453],
              [-38.650714173539903, -8.72507254743096],
              [-38.650718924394148, -8.725079135818792],
              [-38.650723618030597, -8.725085764679056],
              [-38.650728254100969, -8.725092433519892],
              [-38.650732832261227, -8.725099141846446],
              [-38.650737352171681, -8.725105889160929],
              [-38.650741813496921, -8.725112674962675],
              [-38.65074621590589, -8.725119498748153],
              [-38.650750559071916, -8.725126360011034],
              [-38.650754842672733, -8.725133258242147],
              [-38.650759066390449, -8.725140192929651],
              [-38.650763229911703, -8.72514716355899],
              [-38.650767332927487, -8.725154169612857],
              [-38.650771375133367, -8.725161210571414],
              [-38.650775356229403, -8.725168285912217],
              [-38.650779275920144, -8.725175395110222],
              [-38.650783133914771, -8.725182537637929],
              [-38.650786929926994, -8.725189712965332],
              [-38.650790663675124, -8.725196920559984],
              [-38.650794334882114, -8.725204159887079],
              [-38.650797943275521, -8.725211430409404],
              [-38.650801488587611, -8.72521873158747],
              [-38.650804970555285, -8.725226062879543],
              [-38.650808388920197, -8.725233423741548],
              [-38.650811743428655, -8.725240813627359],
              [-38.650815033831748, -8.72524823198855],
              [-38.650818259885327, -8.725255678274724],
              [-38.650821421350003, -8.725263151933291],
              [-38.650824517991161, -8.725270652409714],
              [-38.650827549579034, -8.725278179147416],
              [-38.650830515888664, -8.725285731587876],
              [-38.650833416699925, -8.725293309170693],
              [-38.650836251797564, -8.725300911333589],
              [-38.650839020971226, -8.725308537512429],
              [-38.650841724015393, -8.725316187141368],
              [-38.650844360729494, -8.725323859652748],
              [-38.650846930918213, -8.725331554476375],
              [-38.650850024490289, -8.725341042234621],
              [-38.650853063884909, -8.725350547310507],
              [-38.650856049004169, -8.725360069397816],
              [-38.650858979751852, -8.725369608189808],
              [-38.650861856033558, -8.725379163379088],
              [-38.650864677756601, -8.72538873465785],
              [-38.650867444830084, -8.72539832171771],
              [-38.650870157164817, -8.725407924249778],
              [-38.650872814673441, -8.725417541944687],
              [-38.650875417270321, -8.725427174492577],
              [-38.65087796487159, -8.725436821583095],
              [-38.650880457395182, -8.72544648290541],
              [-38.650882894760798, -8.725456158148273],
              [-38.650885276889852, -8.725465846999946],
              [-38.650887603705655, -8.725475549148276],
              [-38.65088987513316, -8.725485264280682],
              [-38.650892091099273, -8.725494992084137],
              [-38.650894251532534, -8.725504732245268],
              [-38.650896356363347, -8.725514484450176],
              [-38.650898405523897, -8.725524248384746],
              [-38.650900398948153, -8.725534023734365],
              [-38.650902336571896, -8.725543810184107],
              [-38.65090421833267, -8.725553607418645],
              [-38.650906044169865, -8.7255634151223],
              [-38.650907814024663, -8.725573232979155],
              [-38.650909527839993, -8.725583060672841],
              [-38.650911185560687, -8.725592897886745],
              [-38.650912787133294, -8.72560274430392],
              [-38.650914332506225, -8.72561259960713],
              [-38.650915821629674, -8.725622463478839],
              [-38.650917254455692, -8.725632335601265],
              [-38.650918630938058, -8.725642215656354],
              [-38.650919951032456, -8.725652103325753],
              [-38.650921214696346, -8.725661998290926],
              [-38.650922421888993, -8.725671900233037],
              [-38.650923572571521, -8.725681808833075],
              [-38.650924666706842, -8.725691723771845],
              [-38.650925704259706, -8.725701644729842],
              [-38.650926685196673, -8.725711571387455],
              [-38.650927609486132, -8.725721503424865],
              [-38.650928477098297, -8.725731440522058],
              [-38.65092928800523, -8.725741382358883],
              [-38.650930042180775, -8.725751328615031],
              [-38.650930739600661, -8.725761278970042],
              [-38.650931380242369, -8.725771233103339],
              [-38.650931964085288, -8.725781190694203],
              [-38.650932491110595, -8.725791151421841],
              [-38.650932961301301, -8.725801114965307],
              [-38.650933374642257, -8.725811081003592],
              [-38.650933731120134, -8.725821049215623],
              [-38.650934030723462, -8.725831019280211],
              [-38.650934273442566, -8.725840990876154],
              [-38.650934459269621, -8.725850963682188],
              [-38.650934588198623, -8.725860937376979],
              [-38.650934660225445, -8.725870911639236],
              [-38.650934675347749, -8.725880886147563],
              [-38.650934633565043, -8.725890860580595],
              [-38.650934534878665, -8.725900834616978],
              [-38.65093437929179, -8.725910807935373],
              [-38.650934166809421, -8.725920780214436],
              [-38.650933897438421, -8.725930751132886],
              [-38.650933571187437, -8.725940720369454],
              [-38.650933188066993, -8.725950687602989],
              [-38.650932748089424, -8.725960652512333],
              [-38.650932251268898, -8.725970614776413],
              [-38.650931697621424, -8.725980574074326],
              [-38.650931087164828, -8.725990530085094],
              [-38.65093041991878, -8.726000482488026],
              [-38.650929695904765, -8.72601043096242],
              [-38.65092891514611, -8.726020375187787],
              [-38.65092807766797, -8.726030314843735],
              [-38.650927183497309, -8.726040249610007],
              [-38.650926232662954, -8.726050179166553],
              [-38.650925225195515, -8.726060103193435],
              [-38.650924161127449, -8.72607002137088],
              [-38.650923040493048, -8.726079933379403],
              [-38.650921863328406, -8.726089838899602],
              [-38.650920629671432, -8.726099737612365],
              [-38.650919339561867, -8.726109629198751],
              [-38.650917993041318, -8.726119513340084],
              [-38.650916590153116, -8.726129389717856],
              [-38.650915130942465, -8.726139258013928],
              [-38.650913615456375, -8.726149117910348],
              [-38.650912043743666, -8.726158969089441],
              [-38.650910415855002, -8.726168811233789],
              [-38.650908731842783, -8.726178644026303],
              [-38.650906991761296, -8.726188467150211],
              [-38.650905195666581, -8.726198280288987],
              [-38.650903343616505, -8.726208083126458],
              [-38.650901435670733, -8.726217875346851],
              [-38.650899471890739, -8.726227656634604],
              [-38.650897452339777, -8.726237426674629],
              [-38.650895377082918, -8.726247185152131],
              [-38.650893246187032, -8.726256931752694],
              [-38.650891059720735, -8.726266666162315],
              [-38.650888817754492, -8.726276388067342],
              [-38.650886520360544, -8.726286097154583],
              [-38.65088416761288, -8.726295793111172],
              [-38.6508817595873, -8.726305475624919],
              [-38.650881709412175, -8.726305431796042],
              [-38.650876508626354, -8.726325688031379],
              [-38.650876544041644, -8.726325684770488],
              [-38.65087633454992, -8.726326476204504],
              [-38.650876124687748, -8.726327267541372],
              [-38.650875914455163, -8.72632805878087],
              [-38.650875703852222, -8.726328849922883],
              [-38.650875492878967, -8.726329640967197],
              [-38.650875281535434, -8.7263304319137],
              [-38.650875069821694, -8.726331222762166],
              [-38.650874857737762, -8.726332013512456],
              [-38.650874645283693, -8.726332804164368],
              [-38.650874432459538, -8.72633359471777],
              [-38.650874219265354, -8.726334385172452],
              [-38.650874005701162, -8.726335175528288],
              [-38.65087379176704, -8.726335965785053],
              [-38.650873577463017, -8.726336755942603],
              [-38.65087336278912, -8.72633754600078],
              [-38.650873147745415, -8.726338335959381],
              [-38.650872932331971, -8.726339125818241],
              [-38.650872716548797, -8.726339915577226],
              [-38.65087250039597, -8.726340705236113],
              [-38.650872283873504, -8.726341494794772],
              [-38.650872066981471, -8.726342284253015],
              [-38.650871849719927, -8.726343073610654],
              [-38.650871632088872, -8.72634386286756],
              [-38.650871414088392, -8.726344652023528],
              [-38.650871195718537, -8.726345441078392],
              [-38.650870976979341, -8.726346230031975],
              [-38.650870757870855, -8.726347018884086],
              [-38.650870538393129, -8.726347807634601],
              [-38.650870318546211, -8.726348596283353],
              [-38.650870098330124, -8.726349384830124],
              [-38.650869877744945, -8.726350173274776],
              [-38.650869656790725, -8.726350961617145],
              [-38.650869435467492, -8.726351749857026],
              [-38.650869213775309, -8.7263525379943],
              [-38.650868991714226, -8.726353326028702],
              [-38.650868769284266, -8.726354113960173],
              [-38.650868546485498, -8.726354901788486],
              [-38.650868323317972, -8.726355689513472],
              [-38.650868099781725, -8.726356477134939],
              [-38.65086787587682, -8.726357264652723],
              [-38.650867651603292, -8.726358052066692],
              [-38.650867426961199, -8.726358839376658],
              [-38.650867201950582, -8.726359626582449],
              [-38.650866976571486, -8.726360413683869],
              [-38.650866750823965, -8.726361200680795],
              [-38.650866524708078, -8.726361987573027],
              [-38.650866298223875, -8.72636277436038],
              [-38.650866071371389, -8.726363561042719],
              [-38.650865844150673, -8.726364347619842],
              [-38.650865616561781, -8.726365134091616],
              [-38.650865388604764, -8.726365920457836],
              [-38.650865160279672, -8.726366706718355],
              [-38.650864931586547, -8.726367492872962],
              [-38.650864702525432, -8.726368278921527],
              [-38.650864473096412, -8.726369064863849],
              [-38.650864243299488, -8.726369850699777],
              [-38.650864013134743, -8.726370636429159],
              [-38.650863782602215, -8.726371422051775],
              [-38.65086355170196, -8.726372207567509],
              [-38.65086332043402, -8.726372992976154],
              [-38.650863088798459, -8.726373778277566],
              [-38.650862856795314, -8.726374563471534],
              [-38.65086262442464, -8.726375348557914],
              [-38.650862391686488, -8.726376133536553],
              [-38.650862158580907, -8.726376918407224],
              [-38.650861925107939, -8.726377703169838],
              [-38.650861691267657, -8.726378487824153],
              [-38.650861457060088, -8.726379272370069],
              [-38.650861222485304, -8.726380056807347],
              [-38.650860987543346, -8.726380841135855],
              [-38.650860752234259, -8.726381625355396],
              [-38.650860516558097, -8.726382409465806],
              [-38.65086028051492, -8.726383193466928],
              [-38.650860044104753, -8.726383977358589],
              [-38.650859807327691, -8.726384761140622],
              [-38.650859570183748, -8.726385544812837],
              [-38.650859332672987, -8.726386328375103],
              [-38.650859094795472, -8.726387111827242],
              [-38.650858856551238, -8.726387895169056],
              [-38.650858617940344, -8.726388678400397],
              [-38.650858378962845, -8.726389461521082],
              [-38.650858139618769, -8.726390244530929],
              [-38.65085789990821, -8.726391027429813],
              [-38.650857659831189, -8.726391810217541],
              [-38.650857419387755, -8.726392592893935],
              [-38.650857178577979, -8.726393375458812],
              [-38.650856937401912, -8.726394157912042],
              [-38.650856695859588, -8.726394940253398],
              [-38.650856453951072, -8.726395722482787],
              [-38.650856211676427, -8.726396504599954],
              [-38.650855969035675, -8.726397286604797],
              [-38.650855726028894, -8.726398068497128],
              [-38.650855482656155, -8.72639885027677],
              [-38.650855238917451, -8.726399631943542],
              [-38.650854994812882, -8.72640041349727],
              [-38.650854750342489, -8.726401194937816],
              [-38.650854505506338, -8.726401976264992],
              [-38.650854260304463, -8.726402757478647],
              [-38.650854014737078, -8.726403538577655],
              [-38.650851495281422, -8.726411613997625],
              [-38.650849014909376, -8.726419701385565],
              [-38.650846573678372, -8.726427800554212],
              [-38.65084417164497, -8.726435911316086],
              [-38.650841808864747, -8.726444033483373],
              [-38.650839485392432, -8.726452166868054],
              [-38.65083720128181, -8.726460311281784],
              [-38.650834956585769, -8.726468466536025],
              [-38.650832751356283, -8.726476632441917],
              [-38.650830585644421, -8.726484808810403],
              [-38.65082845950031, -8.72649299545226],
              [-38.650826372973192, -8.726501192177842],
              [-38.650824326111369, -8.72650939879744],
              [-38.650822318962256, -8.726517615120988],
              [-38.650820351572293, -8.72652584095834],
              [-38.650818423987062, -8.726534076118964],
              [-38.65081653625117, -8.726542320412262],
              [-38.650814688408346, -8.726550573647302],
              [-38.65081288050137, -8.726558835633051],
              [-38.650811112572086, -8.726567106178164],
              [-38.650809384661443, -8.726575385091202],
              [-38.650807696809458, -8.726583672180453],
              [-38.650806049055213, -8.726591967254084],
              [-38.650804441436833, -8.726600270120011],
              [-38.650802873991566, -8.726608580586019],
              [-38.650801346755699, -8.726616898459692],
              [-38.650799859764589, -8.726625223548423],
              [-38.650798413052669, -8.726633555659527],
              [-38.650797006653434, -8.726641894600025],
              [-38.650795640599469, -8.726650240176889],
              [-38.650794314922386, -8.726658592196889],
              [-38.650793029652874, -8.726666950466608],
              [-38.650791784820711, -8.726675314792596],
              [-38.650790580454689, -8.726683684981149],
              [-38.650789416582761, -8.726692060838506],
              [-38.650788293231805, -8.726700442170721],
              [-38.650787210427872, -8.726708828783774],
              [-38.650786168196021, -8.726717220483467],
              [-38.650785166560382, -8.72672561707553],
              [-38.650784205544156, -8.726734018365518],
              [-38.650783285169602, -8.726742424158957],
              [-38.650782405458017, -8.726750834261239],
              [-38.650781566429792, -8.726759248477594],
              [-38.650780768104326, -8.726767666613263],
              [-38.650780010500128, -8.726776088473319],
              [-38.650779293634734, -8.726784513862761],
              [-38.65077861752475, -8.726792942586544],
              [-38.650777982185822, -8.72680137444951],
              [-38.650777387632665, -8.726809809256427],
              [-38.650776833879064, -8.726818246812],
              [-38.650776320937808, -8.726826686920912],
              [-38.650775848820814, -8.726835129387734],
              [-38.650775417538995, -8.726843574016971],
              [-38.650775027102341, -8.726852020613164],
              [-38.650774677519898, -8.726860468980702],
              [-38.650774368799759, -8.726868918923998],
              [-38.650774100949072, -8.726877370247461],
              [-38.650773873974039, -8.726885822755319],
              [-38.650773687879934, -8.726894276251944],
              [-38.650773542671054, -8.726902730541598],
              [-38.650773438350768, -8.726911185428529],
              [-38.650773374921506, -8.726919640716998],
              [-38.650773352384697, -8.726928096211234],
              [-38.650773370740929, -8.726936551715495],
              [-38.650773429989741, -8.726945007033978],
              [-38.650773530129754, -8.726953461970909],
              [-38.650773671158682, -8.7269619163306],
              [-38.650773853073233, -8.726970369917261],
              [-38.650774075869215, -8.72697882253515],
              [-38.650774339541485, -8.726987273988584],
              [-38.650774644083917, -8.726995724081892],
              [-38.650774989489477, -8.727004172619457],
              [-38.650775375750165, -8.727012619405615],
              [-38.650775802857041, -8.72702106424482],
              [-38.650776270800222, -8.727029506941603],
              [-38.650776779568865, -8.727037947300419],
              [-38.650777329151218, -8.727046385125915],
              [-38.650777919534534, -8.727054820222655],
              [-38.650778550705169, -8.727063252395405],
              [-38.650779222648481, -8.727071681448923],
              [-38.650779935348943, -8.727080107188034],
              [-38.650780688790064, -8.727088529417701],
              [-38.650781482954372, -8.727096947942872],
              [-38.650782317823491, -8.727105362568661],
              [-38.650783193378118, -8.727113773100243],
              [-38.650784109597957, -8.727122179342921],
              [-38.650785066461808, -8.727130581102021],
              [-38.650786063947521, -8.727138978183012],
              [-38.650787102032005, -8.727147370391553],
              [-38.650788180691222, -8.727155757533231],
              [-38.650789299900211, -8.727164139413969],
              [-38.650790459633043, -8.727172515839625],
              [-38.650791659862897, -8.727180886616306],
              [-38.650792900561967, -8.727189251550188],
              [-38.650794181701535, -8.727197610447565],
              [-38.650795503251935, -8.727205963114956],
              [-38.650796865182578, -8.727214309358997],
              [-38.650798267461951, -8.727222648986359],
              [-38.650799710057512, -8.727230981804492],
              [-38.650803811890157, -8.727253925953878],
              [-38.650807953678488, -8.727276862996975],
              [-38.650812135410078, -8.72729979286485],
              [-38.65081635707238, -8.727322715488663],
              [-38.650820618652737, -8.72734563079959],
              [-38.650824920138312, -8.727368538728813],
              [-38.650829261516265, -8.727391439207528],
              [-38.650833642773513, -8.727414332167001],
              [-38.65083806389692, -8.72743721753848],
              [-38.650842524873241, -8.727460095253235],
              [-38.65084702568906, -8.727482965242572],
              [-38.650851566330878, -8.727505827437797],
              [-38.650856146785074, -8.727528681770302],
              [-38.650860767037898, -8.727551528171441],
              [-38.650865427075487, -8.727574366572581],
              [-38.650870126883838, -8.727597196905181],
              [-38.650874866448852, -8.727620019100661],
              [-38.650879645756326, -8.727642833090519],
              [-38.650884464791879, -8.727665638806206],
              [-38.65088932354108, -8.727688436179269],
              [-38.650894221989347, -8.727711225141249],
              [-38.650899160121938, -8.7277340056237],
              [-38.650904137924066, -8.727756777558232],
              [-38.650909155380802, -8.72777954087646],
              [-38.650914212477048, -8.727802295510031],
              [-38.650919309197661, -8.727825041390629],
              [-38.650924445527316, -8.727847778449927],
              [-38.650929621450615, -8.72787050661967],
              [-38.650934836952018, -8.727893225831579],
              [-38.650940092015873, -8.727915936017485],
              [-38.650945386626397, -8.727938637109146],
              [-38.650950720767717, -8.72796132903839],
              [-38.650956094423805, -8.727984011737123],
              [-38.650961507578536, -8.728006685137192],
              [-38.650966960215648, -8.728029349170521],
              [-38.650972452318825, -8.728052003769079],
              [-38.650977983871535, -8.728074648864794],
              [-38.650983554857191, -8.728097284389715],
              [-38.65098916525907, -8.728119910275833],
              [-38.650994815060315, -8.728142526455226],
              [-38.651000504244003, -8.728165132859955],
              [-38.65100623279303, -8.728187729422165],
              [-38.651012000690223, -8.728210316074007],
              [-38.651017807918244, -8.728232892747632],
              [-38.651023654459671, -8.728255459375266],
              [-38.651029540296967, -8.728278015889128],
              [-38.651035465412455, -8.728300562221484],
              [-38.651041429788343, -8.72832309830466],
              [-38.651047433406745, -8.728345624070961],
              [-38.651053476249615, -8.728368139452728],
              [-38.65105955829884, -8.728390644382392],
              [-38.65106567953616, -8.728413138792332],
              [-38.651071839943185, -8.728435622615036],
              [-38.651078039501428, -8.728458095782969],
              [-38.651084278192307, -8.728480558228666],
              [-38.651090555997058, -8.728503009884641],
              [-38.651096872896858, -8.728525450683522],
              [-38.651103228872735, -8.72854788055786],
              [-38.651109623905626, -8.728570299440355],
              [-38.651116057976303, -8.728592707263672],
              [-38.651122531065489, -8.728615103960525],
              [-38.651129043153723, -8.728637489463637],
              [-38.651135594221479, -8.728659863705827],
              [-38.651142184249082, -8.728682226619874],
              [-38.651148813216764, -8.728704578138657],
              [-38.651155481104603, -8.728726918195033],
              [-38.651162187892595, -8.728749246721923],
              [-38.651168933560612, -8.728771563652289],
              [-38.651175718088403, -8.72879386891911],
              [-38.65118254145559, -8.728816162455395],
              [-38.651189403641709, -8.728838444194183],
              [-38.651196304626154, -8.72886071406862],
              [-38.651203244388206, -8.728882972011801],
              [-38.651210222907018, -8.728905217956887],
              [-38.651217240161664, -8.728927451837089],
              [-38.651224296131097, -8.728949673585637],
              [-38.651231390794081, -8.72897188313579],
              [-38.651238524129361, -8.728994080420874],
              [-38.651245696115502, -8.729016265374206],
              [-38.651252906731003, -8.729038437929217],
              [-38.651260155954191, -8.729060598019293],
              [-38.651267443763302, -8.729082745577861],
              [-38.651274770136475, -8.729104880538475],
              [-38.651282135051716, -8.729127002834614],
              [-38.651289538486907, -8.729149112399885],
              [-38.651296980419836, -8.729171209167873],
              [-38.651304460828143, -8.729193293072255],
              [-38.651311979689389, -8.729215364046672],
              [-38.651319536980999, -8.729237422024877],
              [-38.651327132680272, -8.729259466940627],
              [-38.651334766764435, -8.72928149872771],
              [-38.651342439210559, -8.729303517320014],
              [-38.651350149995594, -8.729325522651379],
              [-38.651357899096411, -8.729347514655727],
              [-38.65136568648974, -8.729369493267026],
              [-38.651373512152205, -8.729391458419274],
              [-38.651381376060314, -8.729413410046536],
              [-38.651389278190457, -8.729435348082857],
              [-38.651397218518923, -8.72945727246238],
              [-38.651397858639172, -8.729459043990854],
              [-38.651398498478116, -8.729460815619918],
              [-38.651399138035693, -8.729462587349541],
              [-38.65139977731193, -8.729464359179685],
              [-38.651400416306814, -8.729466131110271],
              [-38.651401055020301, -8.729467903141281],
              [-38.651401693452399, -8.729469675272677],
              [-38.651402331603066, -8.729471447504411],
              [-38.651402969472315, -8.729473219836418],
              [-38.651403607060097, -8.729474992268694],
              [-38.651404244366418, -8.729476764801175],
              [-38.651404881391258, -8.729478537433776],
              [-38.651405518134609, -8.729480310166544],
              [-38.651406154596465, -8.729482082999345],
              [-38.651406790776768, -8.729483855932154],
              [-38.651407426675533, -8.729485628964959],
              [-38.651408062292738, -8.729487402097702],
              [-38.651408697628369, -8.729489175330343],
              [-38.651409332682405, -8.729490948662832],
              [-38.651409967454839, -8.729492722095104],
              [-38.651410601945649, -8.729494495627144],
              [-38.651411236154814, -8.729496269258892],
              [-38.651411870082327, -8.729498042990327],
              [-38.651412503728174, -8.729499816821374],
              [-38.651413137092327, -8.729501590751983],
              [-38.65141377017477, -8.729503364782158],
              [-38.651414402975512, -8.729505138911827],
              [-38.651415035494509, -8.729506913140925],
              [-38.651415667731769, -8.729508687469416],
              [-38.651416299687249, -8.729510461897288],
              [-38.651416931360956, -8.729512236424465],
              [-38.651417562752869, -8.729514011050922],
              [-38.651418193862966, -8.729515785776602],
              [-38.651418824691227, -8.729517560601462],
              [-38.651419455237637, -8.729519335525477],
              [-38.651420085502203, -8.729521110548585],
              [-38.651420715484889, -8.729522885670713],
              [-38.651421345185696, -8.729524660891855],
              [-38.651421974604581, -8.72952643621198],
              [-38.651422603741544, -8.729528211630999],
              [-38.651423232596557, -8.729529987148888],
              [-38.651423861169626, -8.729531762765614],
              [-38.651424489460723, -8.729533538481114],
              [-38.651425117469834, -8.729535314295351],
              [-38.651425745196953, -8.729537090208305],
              [-38.65142637264205, -8.729538866219903],
              [-38.651426999805111, -8.729540642330097],
              [-38.651427626686129, -8.729542418538859],
              [-38.651428253285083, -8.729544194846172],
              [-38.651428879601951, -8.729545971251923],
              [-38.651429505636727, -8.72954774775611],
              [-38.651430131389397, -8.72954952435869],
              [-38.651430756859931, -8.729551301059592],
              [-38.65143138204833, -8.729553077858791],
              [-38.651432006954558, -8.729554854756252],
              [-38.651432631578622, -8.729556631751899],
              [-38.651433255920502, -8.729558408845726],
              [-38.651433879980168, -8.729560186037666],
              [-38.651434503757599, -8.729561963327685],
              [-38.651435127252832, -8.729563740715736],
              [-38.65143575046578, -8.729565518201781],
              [-38.651436373396471, -8.729567295785756],
              [-38.651436996044879, -8.729569073467607],
              [-38.651437618411002, -8.729570851247335],
              [-38.651438240494791, -8.729572629124876],
              [-38.651438862296281, -8.729574407100174],
              [-38.651439483815395, -8.729576185173167],
              [-38.651440105052167, -8.729577963343868],
              [-38.651440726006555, -8.729579741612179],
              [-38.65144134667856, -8.729581519978062],
              [-38.651441967068145, -8.729583298441538],
              [-38.651442587175325, -8.729585077002469],
              [-38.651443207000057, -8.729586855660841],
              [-38.651443826542319, -8.729588634416668],
              [-38.651444445802142, -8.729590413269833],
              [-38.651445064779459, -8.72959219222032],
              [-38.651445683474286, -8.729593971268081],
              [-38.651446301886608, -8.729595750413042],
              [-38.651446920016383, -8.729597529655226],
              [-38.651447537863618, -8.729599308994546],
              [-38.651448155428298, -8.72960108843095],
              [-38.651448772710388, -8.72960286796441],
              [-38.651449389709889, -8.729604647594883],
              [-38.651450006426792, -8.729606427322311],
              [-38.651450622861063, -8.729608207146688],
              [-38.651451239012708, -8.729609987067912],
              [-38.651451854881678, -8.729611767086007],
              [-38.651452470467994, -8.729613547200849],
              [-38.651453085771621, -8.729615327412441],
              [-38.651453700792551, -8.729617107720729],
              [-38.651454315530763, -8.729618888125668],
              [-38.651454929986258, -8.729620668627234],
              [-38.651455544158992, -8.729622449225348],
              [-38.651456158048973, -8.72962422991999],
              [-38.651456771656164, -8.729626010711096],
              [-38.651457384980574, -8.729627791598647],
              [-38.651457998022167, -8.729629572582558],
              [-38.651458610780949, -8.729631353662832],
              [-38.651459223256879, -8.729633134839736],
              [-38.651459213824509, -8.729633138116094],
              [-38.651467160652089, -8.729656709006004],
              [-38.651475062235264, -8.72968029494772],
              [-38.651482918545213, -8.729703895855337],
              [-38.651490729553302, -8.729727511642812],
              [-38.651498495231039, -8.729751142224075],
              [-38.651506215550121, -8.729774787512982],
              [-38.651513890482406, -8.729798447423365],
              [-38.651521519999903, -8.729822121868972],
              [-38.65152910407479, -8.72984581076352],
              [-38.651536642679417, -8.729869514020661],
              [-38.65154413578631, -8.72989323155401],
              [-38.651551583368125, -8.729916963277113],
              [-38.651558985397727, -8.729940709103454],
              [-38.651566341848117, -8.729964468946477],
              [-38.651573652692477, -8.729988242719617],
              [-38.65158091790417, -8.730012030336212],
              [-38.651588137456663, -8.730035831709555],
              [-38.651595311323668, -8.730059646752881],
              [-38.651602439479028, -8.730083475379377],
              [-38.651609521896717, -8.730107317502201],
              [-38.651616558550955, -8.730131173034456],
              [-38.651623549416065, -8.7301550418892],
              [-38.651630494466559, -8.73017892397942],
              [-38.65163739367712, -8.730202819218048],
              [-38.651644247022574, -8.730226727518035],
              [-38.651651054477952, -8.730250648792182],
              [-38.651657816018421, -8.730274582953342],
              [-38.651664531619339, -8.730298529914261],
              [-38.651671201256192, -8.730322489587666],
              [-38.651677824904681, -8.730346461886212],
              [-38.651684402540674, -8.730370446722507],
              [-38.651690934140163, -8.730394444009155],
              [-38.651697419679323, -8.730418453658665],
              [-38.651703859134528, -8.730442475583585],
              [-38.651710252482282, -8.730466509696278],
              [-38.651716599699299, -8.730490555909183],
              [-38.651722900762387, -8.730514614134631],
              [-38.651729155648596, -8.73053868428495],
              [-38.651735364335153, -8.730562766272401],
              [-38.651741526799356, -8.730586860009227],
              [-38.65174764301878, -8.730610965407553],
              [-38.651753712971114, -8.730635082379594],
              [-38.651759736634205, -8.730659210837363],
              [-38.651765713986094, -8.730683350693003],
              [-38.651771645004999, -8.730707501858427],
              [-38.651777529669296, -8.730731664245678],
              [-38.651783367957506, -8.730755837766653],
              [-38.651789159848356, -8.730780022333272],
              [-38.651794905320727, -8.730804217857356],
              [-38.651800604353653, -8.730828424250703],
              [-38.651806256926363, -8.730852641425118],
              [-38.651811863018239, -8.730876869292299],
              [-38.651817422608843, -8.730901107763962],
              [-38.651822935677906, -8.730925356751762],
              [-38.651828402205318, -8.730949616167299],
              [-38.651833822171135, -8.730973885922131],
              [-38.651839195555624, -8.730998165927838],
              [-38.651844522339154, -8.731022456095907],
              [-38.651849802502326, -8.731046756337811],
              [-38.651855036025864, -8.731071066564928],
              [-38.651860222890683, -8.731095386688679],
              [-38.651865363077903, -8.73111971662042],
              [-38.651870456568744, -8.731144056271489],
              [-38.651875503344634, -8.731168405553133],
              [-38.65188050338719, -8.731192764376637],
              [-38.651885456678158, -8.731217132653166],
              [-38.651890363199477, -8.73124151029397],
              [-38.651895222933256, -8.731265897210116],
              [-38.6519000358618, -8.731290293312776],
              [-38.651904801967511, -8.731314698512991],
              [-38.65190952123303, -8.731339112721804],
              [-38.65191419364114, -8.731363535850257],
              [-38.651918819174796, -8.731387967809331],
              [-38.651923397817157, -8.731412408509948],
              [-38.651927929551498, -8.731436857863031],
              [-38.651932414361291, -8.731461315779443],
              [-38.6519368522302, -8.731485782170054],
              [-38.651941243142026, -8.731510256945699],
              [-38.651945587080768, -8.731534740017157],
              [-38.65194988403055, -8.73155923129519],
              [-38.651954133975757, -8.731583730690529],
              [-38.651958336900826, -8.731608238113846],
              [-38.651962492790481, -8.731632753475877],
              [-38.651966601629525, -8.731657276687207],
              [-38.651970663402992, -8.731681807658445],
              [-38.65197467809606, -8.731706346300227],
              [-38.651978645694101, -8.731730892523073],
              [-38.651982566182632, -8.731755446237523],
              [-38.651986439547343, -8.731780007354081],
              [-38.651990265774145, -8.731804575783245],
              [-38.651994044849033, -8.731829151435429],
              [-38.65199777675825, -8.73185373422106],
              [-38.652001461488176, -8.731878324050564],
              [-38.652005099025388, -8.731902920834296],
              [-38.652008689356606, -8.731927524482584],
              [-38.652012232468728, -8.731952134905754],
              [-38.652015728348836, -8.731976752014111],
              [-38.652019176984176, -8.732001375717916],
              [-38.652022578362178, -8.732026005926837],
              [-38.652023611136023, -8.732033572668728],
              [-38.652024634521773, -8.732041140673129],
              [-38.652025648517906, -8.732048709928481],
              [-38.652026653122839, -8.732056280423297],
              [-38.652027648335071, -8.73206385214603],
              [-38.652028634153062, -8.73207142508511],
              [-38.652029610575326, -8.732078999229039],
              [-38.652030577600364, -8.73208657456626],
              [-38.652031535226705, -8.732094151085237],
              [-38.652032483452885, -8.732101728774436],
              [-38.652033422277476, -8.732109307622288],
              [-38.652034351699044, -8.732116887617282],
              [-38.652035271716151, -8.732124468747843],
              [-38.652036182327429, -8.732132051002424],
              [-38.652037083531432, -8.73213963436949],
              [-38.652037975326856, -8.732147218837463],
              [-38.652038857712292, -8.732154804394787],
              [-38.652039730686418, -8.732162391029915],
              [-38.6520405942479, -8.732169978731287],
              [-38.652041448395423, -8.732177567487389],
              [-38.652042293127671, -8.732185157286585],
              [-38.652043128443381, -8.732192748117349],
              [-38.652043954341259, -8.732200339968145],
              [-38.652044770820055, -8.732207932827345],
              [-38.652045577878525, -8.732215526683426],
              [-38.652046375515454, -8.732223121524806],
              [-38.652047163729577, -8.732230717339897],
              [-38.652047942519744, -8.732238314117177],
              [-38.652048711884746, -8.732245911845016],
              [-38.652049471823418, -8.732253510511889],
              [-38.652050222334587, -8.732261110106151],
              [-38.652050963417125, -8.732268710616324],
              [-38.652051695069886, -8.732276312030729],
              [-38.652052417291785, -8.73228391433782],
              [-38.65205313008169, -8.732291517526052],
              [-38.652053833438529, -8.732299121583802],
              [-38.652054527361223, -8.732306726499486],
              [-38.652055211848719, -8.732314332261543],
              [-38.65205588689998, -8.732321938858332],
              [-38.652056552513962, -8.732329546278322],
              [-38.652057208689655, -8.732337154509914],
              [-38.652057855426072, -8.732344763541505],
              [-38.652058492722205, -8.732352373361497],
              [-38.6520591205771, -8.732359983958316],
              [-38.652059738989784, -8.732367595320342],
              [-38.652060347959342, -8.732375207435979],
              [-38.65206094748482, -8.732382820293665],
              [-38.652061537565309, -8.732390433881765],
              [-38.652062118199922, -8.732398048188697],
              [-38.652062689387748, -8.732405663202872],
              [-38.652063251127942, -8.732413278912663],
              [-38.652063803419651, -8.732420895306481],
              [-38.652064346262009, -8.732428512372731],
              [-38.652064879654198, -8.732436130099785],
              [-38.652065403595408, -8.732443748476042],
              [-38.652065918084837, -8.732451367489885],
              [-38.652066423121703, -8.732458987129796],
              [-38.652066918705223, -8.732466607384026],
              [-38.652067404834682, -8.732474228241085],
              [-38.652067881509289, -8.732481849689268],
              [-38.652068348728349, -8.732489471717049],
              [-38.652068806491144, -8.732497094312764],
              [-38.652069254796956, -8.732504717464806],
              [-38.652069693645139, -8.73251234116157],
              [-38.652070123034981, -8.732519965391443],
              [-38.652070542965838, -8.732527590142801],
              [-38.652070953437111, -8.732535215404019],
              [-38.652071354448125, -8.732542841163507],
              [-38.652071745998285, -8.732550467409657],
              [-38.652072128086992, -8.732558094130802],
              [-38.652072500713672, -8.732565721315373],
              [-38.652072863877763, -8.732573348951679],
              [-38.652073217578682, -8.73258097702819],
              [-38.652073561815918, -8.732588605533204],
              [-38.652073896588924, -8.732596234455153],
              [-38.652074221897216, -8.732603863782369],
              [-38.652074537740269, -8.73261149350329],
              [-38.652074844117621, -8.732619123606259],
              [-38.652075141028789, -8.732626754079648],
              [-38.652075428473339, -8.732634384911854],
              [-38.652075706450816, -8.732642016091214],
              [-38.652075974960816, -8.732649647606118],
              [-38.652076234002891, -8.732657279444961],
              [-38.652076483576685, -8.732664911596046],
              [-38.652076723681802, -8.732672544047823],
              [-38.652076954317877, -8.732680176788641],
              [-38.652077175484564, -8.732687809806864],
              [-38.6520773871815, -8.732695443090861],
              [-38.652077589408385, -8.732703076629026],
              [-38.652077782164923, -8.732710710409705],
              [-38.652077965450793, -8.73271834442127],
              [-38.652078139265726, -8.732725978652093],
              [-38.65207830360945, -8.732733613090547],
              [-38.652078458481718, -8.732741247725011],
              [-38.652078603882309, -8.732748882543849],
              [-38.652078739810996, -8.732756517535424],
              [-38.652078866267523, -8.732764152688082],
              [-38.652078983251776, -8.732771787990231],
              [-38.652079090763515, -8.732779423429506],
              [-38.652079064616224, -8.732779423886546],
              [-38.652079096912971, -8.732781962234839],
              [-38.652079128195709, -8.732784500595679],
              [-38.652079158464403, -8.732787038968732],
              [-38.652079187719082, -8.73278957735354],
              [-38.652079215959702, -8.732792115749721],
              [-38.652079243186293, -8.732794654156878],
              [-38.652079269398833, -8.732797192574594],
              [-38.652079294597328, -8.732799731002469],
              [-38.652079318781773, -8.732802269440107],
              [-38.65207934195216, -8.732804807887094],
              [-38.652079364108481, -8.732807346343042],
              [-38.652079385250744, -8.73280988480756],
              [-38.652079405378927, -8.732812423280246],
              [-38.652079424493046, -8.732814961760708],
              [-38.652079442593084, -8.732817500248517],
              [-38.652079459679058, -8.732820038743279],
              [-38.652079475750931, -8.732822577244589],
              [-38.652079490808738, -8.732825115752044],
              [-38.652079504852459, -8.732827654265261],
              [-38.652079517882086, -8.732830192783826],
              [-38.652079529897627, -8.732832731307345],
              [-38.652079540899074, -8.732835269835411],
              [-38.652079550886434, -8.732837808367645],
              [-38.652079559859693, -8.732840346903616],
              [-38.652079567818859, -8.732842885442956],
              [-38.652079574763931, -8.732845423985223],
              [-38.652079580694902, -8.732847962530029],
              [-38.652079585611766, -8.732850501076971],
              [-38.652079589514543, -8.732853039625663],
              [-38.652079592403204, -8.732855578175695],
              [-38.65207959427778, -8.732858116726669],
              [-38.65207959513824, -8.732860655278198],
              [-38.652079594984606, -8.732863193829852],
              [-38.652079593816865, -8.732865732381253],
              [-38.652079591635029, -8.732868270931991],
              [-38.652079588439086, -8.732870809481664],
              [-38.652079584229043, -8.732873348029871],
              [-38.652079579004905, -8.732875886576197],
              [-38.652079572766667, -8.732878425120285],
              [-38.652079565514335, -8.732880963661705],
              [-38.652079557247895, -8.732883502200021],
              [-38.652079547967368, -8.732886040734895],
              [-38.652079537672726, -8.732888579265893],
              [-38.65207952636402, -8.732891117792622],
              [-38.652079514041212, -8.732893656314682],
              [-38.652079500704296, -8.732896194831664],
              [-38.652079486353315, -8.732898733343168],
              [-38.652079470988241, -8.732901271848805],
              [-38.65207945460908, -8.732903810348173],
              [-38.652079437215846, -8.732906348840826],
              [-38.652079418808533, -8.73290888732642],
              [-38.652079399387155, -8.732911425804547],
              [-38.65207937895169, -8.732913964274786],
              [-38.652079357502174, -8.732916502736746],
              [-38.652079335038572, -8.732919041190026],
              [-38.652079311560918, -8.732921579634223],
              [-38.652079287069213, -8.732924118068931],
              [-38.652079261563436, -8.73292665649376],
              [-38.652079235043622, -8.73292919490831],
              [-38.652079207509765, -8.732931733312157],
              [-38.652079178961863, -8.732934271704895],
              [-38.652079149399931, -8.732936810086159],
              [-38.652079118823956, -8.73293934845554],
              [-38.652079087233965, -8.732941886812638],
              [-38.652079054629951, -8.732944425157045],
              [-38.652079021011907, -8.732946963488338],
              [-38.65207898637987, -8.73294950180615],
              [-38.652078950733809, -8.732952040110073],
              [-38.652078914073762, -8.732954578399676],
              [-38.652078876399713, -8.732957116674582],
              [-38.652078837711663, -8.73295965493439],
              [-38.652078798009647, -8.732962193178691],
              [-38.652078757293651, -8.732964731407137],
              [-38.65207871556369, -8.732967269619254],
              [-38.652078672819762, -8.732969807814667],
              [-38.652078629061862, -8.732972345992982],
              [-38.65207858429001, -8.732974884153785],
              [-38.652078538504242, -8.732977422296708],
              [-38.652078491704522, -8.732979960421297],
              [-38.652078443890879, -8.73298249852718],
              [-38.652078395063313, -8.732985036613973],
              [-38.652078345221831, -8.732987574681239],
              [-38.65207829436644, -8.732990112728618],
              [-38.652078242497169, -8.732992650755673],
              [-38.652078189613981, -8.73299518876202],
              [-38.652078135716934, -8.732997726747252],
              [-38.652078080806007, -8.733000264710983],
              [-38.652078024881206, -8.733002802652791],
              [-38.652077967942567, -8.733005340572273],
              [-38.652077909990069, -8.733007878469039],
              [-38.652077851023748, -8.733010416342688],
              [-38.652077791043595, -8.733012954192811],
              [-38.652077730049612, -8.733015492019044],
              [-38.652077668041834, -8.733018029820959],
              [-38.652077605020253, -8.733020567598155],
              [-38.652077540984891, -8.733023105350211],
              [-38.652077475935734, -8.73302564307674],
              [-38.652077409872817, -8.733028180777353],
              [-38.652077342796169, -8.733030718451813],
              [-38.652076660639636, -8.733055328293386],
              [-38.652075961701073, -8.733079937668771],
              [-38.652075245980825, -8.733104546566706],
              [-38.652074513479192, -8.733129154975817],
              [-38.652073764196537, -8.733153762884786],
              [-38.652072998133171, -8.733178370282278],
              [-38.652072215289458, -8.733202977156992],
              [-38.652071415665752, -8.733227583497579],
              [-38.652070599262423, -8.733252189292708],
              [-38.65206976607984, -8.733276794531042],
              [-38.652068916118388, -8.733301399201286],
              [-38.652068049378443, -8.73332600329209],
              [-38.652067165860402, -8.733350606792147],
              [-38.652066265564699, -8.733375209690154],
              [-38.652065348491696, -8.733399811974738],
              [-38.652064414641842, -8.733424413634578],
              [-38.652063464015562, -8.733449014658373],
              [-38.65206249661329, -8.733473615034788],
              [-38.652061512435459, -8.733498214752501],
              [-38.652060511482517, -8.73352281380018],
              [-38.652059493754933, -8.733547412166528],
              [-38.652058459253176, -8.733572009840216],
              [-38.652057407977708, -8.733596606809911],
              [-38.652056339928983, -8.733621203064271],
              [-38.652055255107562, -8.733645798591992],
              [-38.65205415351388, -8.733670393381754],
              [-38.652053035148448, -8.73369498742222],
              [-38.652051900011806, -8.733719580702116],
              [-38.65205074810445, -8.733744173210075],
              [-38.652049579426901, -8.733768764934799],
              [-38.652048393979719, -8.733793355864941],
              [-38.652047191763423, -8.73381794598922],
              [-38.652045972778581, -8.733842535296276],
              [-38.652044737025733, -8.733867123774841],
              [-38.652043484505455, -8.733891711413522],
              [-38.652042215218309, -8.733916298201079],
              [-38.652040929164905, -8.73394088412617],
              [-38.652039626345811, -8.733965469177461],
              [-38.652038306761618, -8.73399005334365],
              [-38.652036970412929, -8.734014636613411],
              [-38.652035617300371, -8.734039218975425],
              [-38.652034247424567, -8.734063800418369],
              [-38.652032860786115, -8.734088380930961],
              [-38.652031457385675, -8.73411296050187],
              [-38.652030037223909, -8.734137539119782],
              [-38.652028600301399, -8.734162116773373],
              [-38.65202714661887, -8.734186693451321],
              [-38.652025676176955, -8.734211269142341],
              [-38.652024188976355, -8.734235843835105],
              [-38.652022685017712, -8.734260417518314],
              [-38.652021164301736, -8.734284990180649],
              [-38.65201962682913, -8.734309561810786],
              [-38.652018072600598, -8.734334132397434],
              [-38.652016501616849, -8.734358701929256],
              [-38.652014913878588, -8.734383270394972],
              [-38.652013309386547, -8.734407837783232],
              [-38.652011688141492, -8.734432404082792],
              [-38.652010050144135, -8.734456969282292],
              [-38.652008395395228, -8.734481533370428],
              [-38.652006723895532, -8.73450609633591],
              [-38.652005035645828, -8.734530658167436],
              [-38.652003330646878, -8.734555218853687],
              [-38.652001608899468, -8.734579778383356],
              [-38.65199987040436, -8.734604336745159],
              [-38.651998115162399, -8.734628893927766],
              [-38.651996343174353, -8.734653449919863],
              [-38.65199455444106, -8.734678004710181],
              [-38.651992748963323, -8.73470255828739],
              [-38.651990926741973, -8.734727110640195],
              [-38.651989087777842, -8.734751661757301],
              [-38.651987232071782, -8.734776211627391],
              [-38.651985359624625, -8.734800760239192],
              [-38.651983470437258, -8.734825307581406],
              [-38.651981564510535, -8.734849853642684],
              [-38.651979641845337, -8.734874398411764],
              [-38.651977702442515, -8.734898941877354],
              [-38.651975746302988, -8.734923484028117],
              [-38.651973773427649, -8.7349480248528],
              [-38.651971783817402, -8.734972564340117],
              [-38.651969777473163, -8.734997102478708],
              [-38.65196775439582, -8.735021639257308],
              [-38.651965714586353, -8.735046174664642],
              [-38.651963658045666, -8.735070708689424],
              [-38.651961584774718, -8.73509524132032],
              [-38.651959494774424, -8.735119772546067],
              [-38.651957388045794, -8.735144302355353],
              [-38.651955264589759, -8.735168830736868],
              [-38.651953124407314, -8.735193357679377],
              [-38.651950967499417, -8.735217883171558],
              [-38.651948793867085, -8.735242407202124],
              [-38.651946603511291, -8.735266929759772],
              [-38.651944396433066, -8.735291450833248],
              [-38.65194217263339, -8.735315970411248],
              [-38.651939932113301, -8.735340488482478],
              [-38.651937674873849, -8.735365005035661],
              [-38.651935400916038, -8.735389520059504],
              [-38.651933110240911, -8.735414033542718],
              [-38.651930802849527, -8.735438545474018],
              [-38.65192847874296, -8.735463055842301],
              [-38.651927020716528, -8.735477593549938],
              [-38.651925415083568, -8.735492115854198],
              [-38.651923662008045, -8.735506621271664],
              [-38.65192176166903, -8.73552110832053],
              [-38.651919714260629, -8.73553557552107],
              [-38.65191751999199, -8.735550021395383],
              [-38.651915179087169, -8.735564444467931],
              [-38.651912691785348, -8.735578843265356],
              [-38.651910058340526, -8.735593216316804],
              [-38.651907279021735, -8.735607562154147],
              [-38.65190435411283, -8.735621879311935],
              [-38.651901283912593, -8.735636166327662],
              [-38.65189806873461, -8.735650421741967],
              [-38.6518947089073, -8.735664644098645],
              [-38.651891204773861, -8.735678831944925],
              [-38.651887556692209, -8.735692983831504],
              [-38.651883765034967, -8.735707098312773],
              [-38.651879830189451, -8.735721173946954],
              [-38.651875752557572, -8.735735209296239],
              [-38.651871532555852, -8.735749202926925],
              [-38.651867170615347, -8.735763153409589],
              [-38.651862667181597, -8.735777059319176],
              [-38.651858022714627, -8.735790919235239],
              [-38.651853237688833, -8.735804731741947],
              [-38.651848312592982, -8.735818495428429],
              [-38.651843247930174, -8.735832208888676],
              [-38.651838044217754, -8.735845870721914],
              [-38.651832701987225, -8.735859479532589],
              [-38.651827221784295, -8.735873033930526],
              [-38.651821604168759, -8.735886532531227],
              [-38.651815849714431, -8.735899973955762],
              [-38.6518099590091, -8.735913356831118],
              [-38.651803932654502, -8.735926679790229],
              [-38.651797771266189, -8.735939941472175],
              [-38.651791475473551, -8.735953140522291],
              [-38.651785045919659, -8.735966275592279],
              [-38.65177848326131, -8.735979345340432],
              [-38.651771788168844, -8.735992348431664],
              [-38.651764961326137, -8.736005283537734],
              [-38.651758003430537, -8.73601814933728],
              [-38.651750915192792, -8.736030944516141],
              [-38.651743697336933, -8.736043667767252],
              [-38.651736350600245, -8.736056317790949],
              [-38.651728875733184, -8.736068893295045],
              [-38.651721273499319, -8.736081392994953],
              [-38.651713544675147, -8.736093815613822],
              [-38.651705690050193, -8.73610615988272],
              [-38.651697710426781, -8.73611842454069],
              [-38.651689606619996, -8.736130608334856],
              [-38.651681379457656, -8.736142710020671],
              [-38.651673029780135, -8.736154728361983],
              [-38.651664558440324, -8.736166662131104],
              [-38.651655966303579, -8.736178510108973],
              [-38.651647254247571, -8.736190271085363],
              [-38.651638423162204, -8.736201943858921],
              [-38.651629473949569, -8.736213527237199],
              [-38.651620407523815, -8.736225020037038],
              [-38.651611224811042, -8.736236421084415],
              [-38.651601926749294, -8.736247729214734],
              [-38.651592514288311, -8.73625894327286],
              [-38.651582988389585, -8.736270062113318],
              [-38.651573350026162, -8.736281084600265],
              [-38.651563600182591, -8.736292009607812],
              [-38.651553739854812, -8.736302836019917],
              [-38.65154377005004, -8.736313562730718],
              [-38.651533691786689, -8.736324188644442],
              [-38.651523506094222, -8.73633471267568],
              [-38.6515132140131, -8.736345133749422],
              [-38.651502816594657, -8.736355450801154],
              [-38.651492314900977, -8.736365662776993],
              [-38.651481710004802, -8.73637576863376],
              [-38.651471002989375, -8.736385767339154],
              [-38.651460194948449, -8.736395657871849],
              [-38.651449286986043, -8.736405439221473],
              [-38.651438280216375, -8.736415110388922],
              [-38.651427175763814, -8.736424670386237],
              [-38.651415974762628, -8.736434118236872],
              [-38.651404678357018, -8.736443452975804],
              [-38.651393287700884, -8.736452673649403],
              [-38.65138180395779, -8.736461779315837],
              [-38.651370228300792, -8.736470769044963],
              [-38.651358561912318, -8.736479641918434],
              [-38.651346805984083, -8.736488397029936],
              [-38.651334961716955, -8.736497033485099],
              [-38.651323030320825, -8.736505550401747],
              [-38.651311013014457, -8.736513946909859],
              [-38.651298911025414, -8.736522222151727],
              [-38.651286725589927, -8.736530375282079],
              [-38.651274457952709, -8.736538405468032],
              [-38.651262109366911, -8.736546311889313],
              [-38.65124968109393, -8.736554093738281],
              [-38.651237174403285, -8.73656175022003],
              [-38.651224590572554, -8.736569280552455],
              [-38.651211930887158, -8.736576683966339],
              [-38.651199196640285, -8.736583959705403],
              [-38.651186389132711, -8.736591107026427],
              [-38.651173509672759, -8.736598125199361],
              [-38.65116055957602, -8.736605013507255],
              [-38.651147540165354, -8.73661177124584],
              [-38.651117333011463, -8.736627707868696],
              [-38.651087511142741, -8.736644347256158],
              [-38.651058091073828, -8.736661680193775],
              [-38.651029089096838, -8.736679697082996],
              [-38.651000521272408, -8.736698387946502],
              [-38.650972403420695, -8.736717742433797],
              [-38.650944751112768, -8.736737749826831],
              [-38.650917579661808, -8.736758399045996],
              [-38.650890904114775, -8.736779678656291],
              [-38.650864739243971, -8.73680157687355],
              [-38.65083909953897, -8.736824081571141],
              [-38.650813999198469, -8.736847180286484],
              [-38.650789452122531, -8.736870860228107],
              [-38.650765471904791, -8.736895108282601],
              [-38.650742071825022, -8.736919911021999],
              [-38.650719264841726, -8.736945254711156],
              [-38.650697063584971, -8.736971125315348],
              [-38.650675480349406, -8.736997508508058],
              [-38.650654527087426, -8.737024389678869],
              [-38.650634215402548, -8.737051753941705],
              [-38.650614556543061, -8.737079586142819],
              [-38.650595561395711, -8.737107870869428],
              [-38.650577240479706, -8.737136592458127],
              [-38.650559603940877, -8.737165735003558],
              [-38.650542661546105, -8.737195282367272],
              [-38.650526422677821, -8.737225218186614],
              [-38.650510896328925, -8.737255525883834],
              [-38.650496091097722, -8.737286188675204],
              [-38.650482015183165, -8.737317189580391],
              [-38.65046867638037, -8.737348511431778],
              [-38.650456082076218, -8.737380136884063],
              [-38.65044423924536, -8.737412048423751],
              [-38.650433154446276, -8.737444228378966],
              [-38.650422833817679, -8.737476658929156],
              [-38.650413283075075, -8.737509322115011],
              [-38.650404507507687, -8.737542199848329],
              [-38.650396511975408, -8.73757527392223],
              [-38.65038930090622, -8.737608526020939],
              [-38.650382878293648, -8.737641937730169],
              [-38.650377247694607, -8.737675490547211],
              [-38.650372412227419, -8.737709165891298],
              [-38.650368374570078, -8.737742945113663],
              [-38.650365136958754, -8.737776809508109],
              [-38.650362701186609, -8.73781074032126],
              [-38.650361068602734, -8.73784471876291],
              [-38.650360240111411, -8.737878726016469],
              [-38.650360216171691, -8.73791274324946],
              [-38.650360996797048, -8.73794675162384],
              [-38.650362581555378, -8.73798073230639],
              [-38.650364969569345, -8.73801466647935],
              [-38.65036815951666, -8.738048535350595],
              [-38.650372149631082, -8.738082320164239],
              [-38.650376937703129, -8.738116002210925],
              [-38.650382521081511, -8.738149562838169],
              [-38.650388896674443, -8.738182983460733],
              [-38.650396060951486, -8.738216245570937],
              [-38.650404009945376, -8.73824933074879],
              [-38.65041273925435, -8.73828222067243],
              [-38.650422244044464, -8.738314897127974],
              [-38.650432519052359, -8.738347342019816],
              [-38.650443558588123, -8.738379537380583],
              [-38.650455356538458, -8.738411465381063],
              [-38.650467906370039, -8.738443108340068],
              [-38.650481201133246, -8.738474448734337],
              [-38.650495233465819, -8.738505469208109],
              [-38.650509995597098, -8.738536152582759],
              [-38.65052547935224, -8.738566481866407],
              [-38.650541676156806, -8.738596440263237],
              [-38.650558577041451, -8.738626011182763],
              [-38.650576172646907, -8.738655178249111],
              [-38.650594453229225, -8.73868392531009],
              [-38.650613408665052, -8.738712236446011],
              [-38.650633028457349, -8.738740095978676],
              [-38.650653301741144, -8.738767488479935],
              [-38.650674217289591, -8.738794398780229],
              [-38.650695763520133, -8.738820811977112],
              [-38.650717928500946, -8.738846713443357],
              [-38.650740699957531, -8.738872088835178],
              [-38.650764065279581, -8.738896924100029],
              [-38.650788011527837, -8.738921205484591],
              [-38.650812525441395, -8.738944919542183],
              [-38.650837593444905, -8.738968053140319],
              [-38.650863201656229, -8.738990593467975],
              [-38.650889335894014, -8.739012528042679],
              [-38.650915981685614, -8.739033844717369],
              [-38.650943124275102, -8.739054531687206],
              [-38.65097074863138, -8.739074577496067],
              [-38.650998839456584, -8.739093971042847],
              [-38.651027381194524, -8.739112701587699],
              [-38.651056358039256, -8.739130758757923],
              [-38.651085753943903, -8.739148132553698],
              [-38.651115552629491, -8.739164813353709],
              [-38.651145737594035, -8.739180791920354],
              [-38.651176292121541, -8.739196059404875],
              [-38.651207199291441, -8.739210607352431],
              [-38.651238441987793, -8.739224427706549],
              [-38.651270002908902, -8.739237512813663],
              [-38.651301864576808, -8.739249855427456],
              [-38.65133400934733, -8.739261448711675],
              [-38.651349972685374, -8.739266934298723],
              [-38.651365973553908, -8.739272310566212],
              [-38.651382011196624, -8.739277577260021],
              [-38.65139808485543, -8.739282734131196],
              [-38.65141419377057, -8.739287780935975],
              [-38.651430337180571, -8.739292717435804],
              [-38.651446514322409, -8.739297543397354],
              [-38.65146272443139, -8.739302258592483],
              [-38.651478966741315, -8.739306862798344],
              [-38.651495240484444, -8.739311355797298],
              [-38.65151154489152, -8.739315737376941],
              [-38.651527879191882, -8.739320007330225],
              [-38.65154424261344, -8.73932416545524],
              [-38.651560634382733, -8.739328211555486],
              [-38.651577053724928, -8.739332145439699],
              [-38.651593499863935, -8.739335966921947],
              [-38.651609972022349, -8.73933967582157],
              [-38.651626469421608, -8.73934327196325],
              [-38.651642991281868, -8.739346755177037],
              [-38.65165953682218, -8.739350125298255],
              [-38.651676105260485, -8.739353382167604],
              [-38.651692695813608, -8.739356525631154],
              [-38.651709307697352, -8.7393595555403],
              [-38.651725940126497, -8.739362471751845],
              [-38.651742592314854, -8.739365274127969],
              [-38.651759263475299, -8.739367962536134],
              [-38.651775952819861, -8.739370536849297],
              [-38.651792659559604, -8.739372996945804],
              [-38.651809382904872, -8.739375342709328],
              [-38.651826122065174, -8.739377574029019],
              [-38.651842876249255, -8.739379690799389],
              [-38.651859644665208, -8.739381692920389],
              [-38.651876426520403, -8.7393835802974],
              [-38.651893221021595, -8.739385352841152],
              [-38.651910027374953, -8.739387010467908],
              [-38.65192684478604, -8.73938855309928],
              [-38.651943672459957, -8.7393899806624],
              [-38.651960509601267, -8.739391293089751],
              [-38.651977355414125, -8.739392490319288],
              [-38.651994209102256, -8.739393572294466],
              [-38.652011069868998, -8.739394538964072],
              [-38.652027936917406, -8.739395390282493],
              [-38.652044809450175, -8.739396126209435],
              [-38.652061686669782, -8.739396746710115],
              [-38.652078567778474, -8.739397251755211],
              [-38.652095451978283, -8.73939764132086],
              [-38.65211233847117, -8.739397915388649],
              [-38.652129226458896, -8.739398073945583],
              [-38.652146115143239, -8.739398116984221],
              [-38.652163003725875, -8.739398044502469],
              [-38.652179891408501, -8.739397856503819],
              [-38.652196777392895, -8.73939755299711],
              [-38.652213660880868, -8.739397133996706],
              [-38.65223054107436, -8.739396599522404],
              [-38.652247417175474, -8.73939594959946],
              [-38.65226428838654, -8.739395184258626],
              [-38.652281153910046, -8.739394303536017],
              [-38.652298012948805, -8.739393307473328],
              [-38.652314864705936, -8.739392196117635],
              [-38.652331708384864, -8.739390969521383],
              [-38.652348543189404, -8.739389627742693],
              [-38.652365368323835, -8.739388170844871],
              [-38.652382182992866, -8.739386598896823],
              [-38.652398986401671, -8.739384911972857],
              [-38.652415777755998, -8.739383110152696],
              [-38.652432556262141, -8.739381193521487],
              [-38.652449321127037, -8.739379162169882],
              [-38.652466071558187, -8.739377016193865],
              [-38.652482806763878, -8.739374755694877],
              [-38.652499525953047, -8.739372380779779],
              [-38.652516228335408, -8.739369891560782],
              [-38.652532913121476, -8.739367288155599],
              [-38.652549579522578, -8.739364570687282],
              [-38.652566226750935, -8.739361739284238],
              [-38.65258285401967, -8.739358794080365],
              [-38.652599460542802, -8.739355735214815],
              [-38.652616045535417, -8.739352562832217],
              [-38.652632608213551, -8.739349277082505],
              [-38.652649147794321, -8.739345878120993],
              [-38.652665663495938, -8.739342366108312],
              [-38.652682154537715, -8.739338741210535],
              [-38.652698620140164, -8.739335003598955],
              [-38.652715059524986, -8.739331153450275],
              [-38.652731471915118, -8.739327190946449],
              [-38.652747856534766, -8.7393231162748],
              [-38.65276421260949, -8.739318929627922],
              [-38.652780539366141, -8.739314631203712],
              [-38.652796836032991, -8.739310221205335],
              [-38.652813101839712, -8.739305699841241],
              [-38.652829336017483, -8.739301067325163],
              [-38.65284553779891, -8.739296323876081],
              [-38.6528617064182, -8.739291469718184],
              [-38.652877841111064, -8.739286505080928],
              [-38.652893941114847, -8.739281430198984],
              [-38.652910005668545, -8.739276245312244],
              [-38.652926034012815, -8.739270950665755],
              [-38.652942025390018, -8.739265546509829],
              [-38.652957979044281, -8.739260033099875],
              [-38.652973894221482, -8.739254410697294],
              [-38.653017014814473, -8.739238951743387],
              [-38.65306009722228, -8.739223387769075],
              [-38.65310314118647, -8.739207718867766],
              [-38.653146146448883, -8.739191945133427],
              [-38.653189112751548, -8.739176066660654],
              [-38.653232039836759, -8.739160083544714],
              [-38.653274927447036, -8.739143995881484],
              [-38.653317775325121, -8.739127803767403],
              [-38.653360583214003, -8.739111507299668],
              [-38.653403350856941, -8.739095106575983],
              [-38.653446077997373, -8.739078601694727],
              [-38.653488764379027, -8.739061992754905],
              [-38.653531409745888, -8.739045279856157],
              [-38.653574013842125, -8.739028463098702],
              [-38.653616576412226, -8.739011542583398],
              [-38.653659097200887, -8.73899451841177],
              [-38.65370157595305, -8.738977390685916],
              [-38.653744012413945, -8.738960159508601],
              [-38.653786406329026, -8.738942824983111],
              [-38.653828757443989, -8.738925387213481],
              [-38.653871065504831, -8.738907846304285],
              [-38.653913330257765, -8.738890202360729],
              [-38.653955551449293, -8.738872455488654],
              [-38.65399772882617, -8.738854605794517],
              [-38.654039862135413, -8.738836653385375],
              [-38.65408195112429, -8.738818598368917],
              [-38.654123995540353, -8.738800440853414],
              [-38.654165995131436, -8.738782180947782],
              [-38.654207949645581, -8.738763818761589],
              [-38.654249858831172, -8.738745354404921],
              [-38.654291722436824, -8.73872678798854],
              [-38.654333540211439, -8.738708119623841],
              [-38.654375311904182, -8.738689349422769],
              [-38.654417037264523, -8.738670477497939],
              [-38.654458716042178, -8.738651503962538],
              [-38.654500347987131, -8.738632428930343],
              [-38.65454193284971, -8.738613252515808],
              [-38.654583470380466, -8.738593974833927],
              [-38.65462496033026, -8.738574596000344],
              [-38.654666402450225, -8.738555116131302],
              [-38.654707796491806, -8.738535535343605],
              [-38.654749142206704, -8.738515853754754],
              [-38.654790439346925, -8.738496071482798],
              [-38.654831687664753, -8.738476188646356],
              [-38.65487288691282, -8.738456205364702],
              [-38.654914036843984, -8.738436121757719],
              [-38.65495513721141, -8.738415937945874],
              [-38.654996187768617, -8.738395654050157],
              [-38.655037188269354, -8.738375270192345],
              [-38.655078138467694, -8.7383547864946],
              [-38.655119038118038, -8.738334203079887],
              [-38.655159886975063, -8.738313520071596],
              [-38.655200684793741, -8.73829273759381],
              [-38.655241431329387, -8.73827185577116],
              [-38.655282126337589, -8.738250874728942],
              [-38.655322769574255, -8.738229794592987],
              [-38.655363360795626, -8.738208615489697],
              [-38.655403899758213, -8.738187337546169],
              [-38.655444386218889, -8.73816596088999],
              [-38.655484819934777, -8.738144485649432],
              [-38.655525200663384, -8.738122911953228],
              [-38.655565528162512, -8.738101239930835],
              [-38.655605802190252, -8.738079469712229],
              [-38.65564602250506, -8.738057601427997],
              [-38.655686188865701, -8.738035635209297],
              [-38.655726301031237, -8.738013571187908],
              [-38.655766358761078, -8.737991409496127],
              [-38.655806361814982, -8.737969150266913],
              [-38.655846309952992, -8.737946793633776],
              [-38.655886202935505, -8.737924339730819],
              [-38.655926040523255, -8.737901788692707],
              [-38.65596582247727, -8.737879140654755],
              [-38.656005548558973, -8.737856395752763],
              [-38.656045218530053, -8.737833554123148],
              [-38.656084832152594, -8.737810615902941],
              [-38.65612438918901, -8.73778758122973],
              [-38.656163889402009, -8.737764450241663],
              [-38.656203332554689, -8.737741223077496],
              [-38.656242718410461, -8.737717899876566],
              [-38.656282046733111, -8.737694480778716],
              [-38.656321317286725, -8.737670965924442],
              [-38.65636052983578, -8.737647355454801],
              [-38.656399684145086, -8.73762364951142],
              [-38.656438779979773, -8.737599848236464],
              [-38.656477817105376, -8.737575951772692],
              [-38.656516795287729, -8.73755196026347],
              [-38.65655571429307, -8.737527873852692],
              [-38.656594573887958, -8.737503692684802],
              [-38.656633373839284, -8.737479416904861],
              [-38.656672113914389, -8.737455046658496],
              [-38.656710793880883, -8.737430582091879],
              [-38.656749413506745, -8.737406023351719],
              [-38.656787972560387, -8.737381370585338],
              [-38.656826470810508, -8.737356623940602],
              [-38.656864908026193, -8.737331783565972],
              [-38.656903283976916, -8.737306849610425],
              [-38.656941598432496, -8.737281822223512],
              [-38.656979851163136, -8.737256701555358],
              [-38.657018041939601, -8.737231487756492],
              [-38.657028129780542, -8.737224704834818],
              [-38.657038118027572, -8.737217777559163],
              [-38.657048004594003, -8.737210707376708],
              [-38.657057787414338, -8.737203495764566],
              [-38.657067464444793, -8.737196144229369],
              [-38.657077033663633, -8.737188654306978],
              [-38.657086493071709, -8.737181027562183],
              [-38.657095840692783, -8.737173265588355],
              [-38.657105074573963, -8.737165370007071],
              [-38.65711419278616, -8.737157342467903],
              [-38.65712319342439, -8.737149184647944],
              [-38.657132074608263, -8.737140898251477],
              [-38.657140834482369, -8.737132485009719],
              [-38.65714947121662, -8.73712394668031],
              [-38.657157983006648, -8.737115285047068],
              [-38.657166368074201, -8.737106501919605],
              [-38.657174624667483, -8.737097599132804],
              [-38.657182751061548, -8.737088578546684],
              [-38.657190745558694, -8.737079442045793],
              [-38.657198606488691, -8.737070191538853],
              [-38.657206332209299, -8.737060828958535],
              [-38.657213921106454, -8.737051356260782],
              [-38.657221371594744, -8.737041775424661],
              [-38.657228682117598, -8.737032088451748],
              [-38.65723585114776, -8.737022297365852],
              [-38.657242877187485, -8.737012404212484],
              [-38.657249758768941, -8.737002411058491],
              [-38.65725649445443, -8.736992319991638],
              [-38.657263082836749, -8.736982133120119],
              [-38.657269522539536, -8.736971852572131],
              [-38.657275812217385, -8.73696148049553],
              [-38.657281950556296, -8.736951019057157],
              [-38.657287936273875, -8.736940470442606],
              [-38.657293768119594, -8.736929836855708],
              [-38.657299444875136, -8.736919120517957],
              [-38.657304965354506, -8.736908323668226],
              [-38.657310328404392, -8.736897448562136],
              [-38.65731553290437, -8.736886497471692],
              [-38.657320577767173, -8.736875472684785],
              [-38.657325461938818, -8.736864376504672],
              [-38.657330184398944, -8.736853211249553],
              [-38.65733474416097, -8.736841979252031],
              [-38.657339140272278, -8.736830682858676],
              [-38.657343371814463, -8.736819324429488],
              [-38.65734743790351, -8.736807906337441],
              [-38.657351337689938, -8.736796430968031],
              [-38.657355070359046, -8.736784900718586],
              [-38.657358635131004, -8.736773317998008],
              [-38.65736203126113, -8.736761685226137],
              [-38.657365258039874, -8.736750004833249],
              [-38.657368314793167, -8.736738279259574],
              [-38.65737120088238, -8.736726510954798],
              [-38.657373915704603, -8.736714702377501],
              [-38.657376458692667, -8.736702855994764],
              [-38.657378829315313, -8.736690974281414],
              [-38.657381027077307, -8.736679059719814],
              [-38.657383051519489, -8.736667114799104],
              [-38.657384902218944, -8.736655142014769],
              [-38.657386578789072, -8.736643143868125],
              [-38.657388080879585, -8.736631122865809],
              [-38.657389408176712, -8.736619081519201],
              [-38.657390560403179, -8.736607022343957],
              [-38.657391537318261, -8.736594947859427],
              [-38.657392338717891, -8.736582860588205],
              [-38.657392964434656, -8.736570763055525],
              [-38.657393414337861, -8.736558657788745],
              [-38.657393688333528, -8.736546547316863],
              [-38.65739378636443, -8.736534434170009],
              [-38.65739370841009, -8.736522320878761],
              [-38.657393454486837, -8.73651020997384],
              [-38.65739302464771, -8.736498103985356],
              [-38.657392418982553, -8.736486005442538],
              [-38.657391637617884, -8.736473916872905],
              [-38.657390680716993, -8.736461840802018],
              [-38.657389548479792, -8.736449779752764],
              [-38.657388241142861, -8.736437736244858],
              [-38.657386758979307, -8.736425712794434],
              [-38.657385102298818, -8.736413711913384],
              [-38.657383271447529, -8.736401736108888],
              [-38.657381266807931, -8.73638978788288],
              [-38.657379088798841, -8.736377869731564],
              [-38.657376737875317, -8.736365984144816],
              [-38.65737421452851, -8.736354133605786],
              [-38.65737151928564, -8.736342320590193],
              [-38.657368652709742, -8.736330547566014],
              [-38.657365615399755, -8.736318816992805],
              [-38.65736240799022, -8.736307131321297],
              [-38.657359031151259, -8.73629549299279],
              [-38.657355485588326, -8.736283904438746],
              [-38.65735177204219, -8.736272368080179],
              [-38.657347891288694, -8.73626088632729],
              [-38.657343844138573, -8.736249461578778],
              [-38.657339631437388, -8.736238096221436],
              [-38.657335254065252, -8.736226792629704],
              [-38.657330712936698, -8.736215553165056],
              [-38.657326009000428, -8.736204380175607],
              [-38.657321143239201, -8.7361932759956],
              [-38.657316116669577, -8.736182242944887],
              [-38.657310930341701, -8.736171283328408],
              [-38.65730570797438, -8.736160246090252],
              [-38.657300647636724, -8.736149134393843],
              [-38.657295750400905, -8.736137950593555],
              [-38.657291017304487, -8.736126697058953],
              [-38.657286449350345, -8.736115376174368],
              [-38.657282047506264, -8.73610399033848],
              [-38.657277812704884, -8.736092541963655],
              [-38.657273745843462, -8.736081033475561],
              [-38.657269847783645, -8.736069467312573],
              [-38.657266119351334, -8.736057845925282],
              [-38.657262561336459, -8.736046171776014],
              [-38.6572591744929, -8.736034447338231],
              [-38.657255959538197, -8.736022675096086],
              [-38.657252917153549, -8.736010857543834],
              [-38.65725004798351, -8.735998997185364],
              [-38.657247352636013, -8.73598709653357],
              [-38.657244831682071, -8.735975158109959],
              [-38.657242485655864, -8.735963184443973],
              [-38.657240315054402, -8.735951178072598],
              [-38.657238320337569, -8.735939141539646],
              [-38.657236501927997, -8.735927077395456],
              [-38.657234860210934, -8.735914988196059],
              [-38.657233395534206, -8.735902876502902],
              [-38.657232108208142, -8.735890744882195],
              [-38.657230998505455, -8.735878595904301],
              [-38.657230066661278, -8.735866432143377],
              [-38.657229312873, -8.735854256176536],
              [-38.657228737300315, -8.735842070583674],
              [-38.657228340065188, -8.73582987794658],
              [-38.65722812125172, -8.735817680848625],
              [-38.657228080906293, -8.735805481874044],
              [-38.657228219037435, -8.735793283607538],
              [-38.657228535615843, -8.735781088633598],
              [-38.657229030574435, -8.735768899536106],
              [-38.657229703808312, -8.735756718897601],
              [-38.657230555174827, -8.73574454929892],
              [-38.65723158449358, -8.735732393318477],
              [-38.657232791546456, -8.735720253531825],
              [-38.657234176077708, -8.735708132511133],
              [-38.657235737793947, -8.735696032824526],
              [-38.657237476364266, -8.735683957035688],
              [-38.657239391420319, -8.73567190770315],
              [-38.657241482556294, -8.735659887379875],
              [-38.657243749329133, -8.735647898612696],
              [-38.657246191258551, -8.735635943941777],
              [-38.657248807827123, -8.735624025899957],
              [-38.65725159848045, -8.735612147012477],
              [-38.657254562627273, -8.735600309796139],
              [-38.657257699639509, -8.735588516758948],
              [-38.657261008852494, -8.735576770399609],
              [-38.657264489565058, -8.735565073206871],
              [-38.657268141039737, -8.735553427659081],
              [-38.657271962502811, -8.735541836223671],
              [-38.657275953144627, -8.73553030135659],
              [-38.657280112119622, -8.735518825501794],
              [-38.657284438546611, -8.735507411090728],
              [-38.657288931508909, -8.73549606054185],
              [-38.65729359005455, -8.735484776260073],
              [-38.657298413196479, -8.735473560636235],
              [-38.657303399912784, -8.735462416046657],
              [-38.65730854914689, -8.735451344852668],
              [-38.657313859807758, -8.73544034939995],
              [-38.6573193307702, -8.735429432018151],
              [-38.65732496087503, -8.735418595020445],
              [-38.65733074892934, -8.735407840702875],
              [-38.657336693706782, -8.735397171344102],
              [-38.657342793947791, -8.735386589204611],
              [-38.657349048359841, -8.735376096526547],
              [-38.657355455617783, -8.735365695533089],
              [-38.657362014364047, -8.735355388427907],
              [-38.65736872320899, -8.735345177394809],
              [-38.657375580731149, -8.735335064597336],
              [-38.657382585477606, -8.735325052178094],
              [-38.657389735964159, -8.735315142258486],
              [-38.657397030675817, -8.735305336938156],
              [-38.657404468066993, -8.735295638294666],
              [-38.657412046561873, -8.735286048382868],
              [-38.657419764554739, -8.735276569234673],
              [-38.657427620410338, -8.73526720285845],
              [-38.657435612464191, -8.735257951238713],
              [-38.65744373902298, -8.735248816335691],
              [-38.657451998364856, -8.735239800084772],
              [-38.657460388739871, -8.735230904396364],
              [-38.657468908370284, -8.735222131155197],
              [-38.657477555451024, -8.735213482220113],
              [-38.657486328149929, -8.735204959423619],
              [-38.657495224608311, -8.735196564571499],
              [-38.657504242941201, -8.73518829944237],
              [-38.657513381237827, -8.735180165787456],
              [-38.657522637561996, -8.735172165330077],
              [-38.65753200995254, -8.735164299765319],
              [-38.657541496423647, -8.735156570759667],
              [-38.65755109496537, -8.735148979950779],
              [-38.657560803544015, -8.735141528946885],
              [-38.657570620102554, -8.735134219326762],
              [-38.657580542561092, -8.735127052639061],
              [-38.657590568817319, -8.735120030402257],
              [-38.657600696746883, -8.735113154104186],
              [-38.657610924203802, -8.735106425201948],
              [-38.657619895861821, -8.735100766504161],
              [-38.657629001199851, -8.735095323263495],
              [-38.657638234991595, -8.735090098604314],
              [-38.657647591936986, -8.735085095525463],
              [-38.65765706666528, -8.735080316898655],
              [-38.657666653738133, -8.735075765466737],
              [-38.657676347652718, -8.735071443842152],
              [-38.657686142844867, -8.735067354505466],
              [-38.657696033692297, -8.735063499803859],
              [-38.657706014517807, -8.735059881949871],
              [-38.657716079592589, -8.735056503020138],
              [-38.657726223139413, -8.7350533649541],
              [-38.657736439336063, -8.735050469552924],
              [-38.657746722318585, -8.735047818478561],
              [-38.65775706618475, -8.735045413252649],
              [-38.657767464997313, -8.735043255255789],
              [-38.657777912787509, -8.735041345726613],
              [-38.657788403558499, -8.735039685761182],
              [-38.657798931288745, -8.735038276312247],
              [-38.657809489935481, -8.735037118188849],
              [-38.657820073438231, -8.735036212055739],
              [-38.657830675722217, -8.735035558433008],
              [-38.65784129070191, -8.735035157695814],
              [-38.657851912284464, -8.735035010074183],
              [-38.657862534373301, -8.735035115652861],
              [-38.65787315087146, -8.735035474371248],
              [-38.65788375568529, -8.735036086023458],
              [-38.657894342727758, -8.735036950258364],
              [-38.657904905922081, -8.735038066579934],
              [-38.657915439205176, -8.735039434347433],
              [-38.657925936531079, -8.735041052775795],
              [-38.6579363918745, -8.73504292093603],
              [-38.657946799234239, -8.73504503775589],
              [-38.657957152636655, -8.735047402020285],
              [-38.657967446139018, -8.735050012372254],
              [-38.657977673833066, -8.735052867313415],
              [-38.657987829848231, -8.735055965205126],
              [-38.657997908355128, -8.735059304269246],
              [-38.658007903568851, -8.735062882589203],
              [-38.658017809752323, -8.73506669811108],
              [-38.658027621219517, -8.735070748644834],
              [-38.658037332338807, -8.735075031865536],
              [-38.658046937536184, -8.735079545314678],
              [-38.658056431298398, -8.7350842864016],
              [-38.658065808176161, -8.735089252405027],
              [-38.658075062787326, -8.73509444047451],
              [-38.658084189819839, -8.735099847632215],
              [-38.658093184034946, -8.735105470774522],
              [-38.658102040270101, -8.735111306673794],
              [-38.658110753441946, -8.735117351980341],
              [-38.658119318549282, -8.735123603224249],
              [-38.658127730675801, -8.735130056817411],
              [-38.65813598499313, -8.735136709055551],
              [-38.658144076763371, -8.7351435561204],
              [-38.658152001341968, -8.735150594081841],
              [-38.658159754180332, -8.735157818900184],
              [-38.658167330828455, -8.735165226428506],
              [-38.658174726937403, -8.735172812415023],
              [-38.658181938261976, -8.735180572505461],
              [-38.658188960662919, -8.73518850224565],
              [-38.658195790109488, -8.735196597084052],
              [-38.658202422681676, -8.735204852374359],
              [-38.658208854572464, -8.735213263378144],
              [-38.658215082090045, -8.735221825267603],
              [-38.658221101659876, -8.735230533128368],
              [-38.658226909826837, -8.735239381962268],
              [-38.658232503257068, -8.73524836669017],
              [-38.658237878740053, -8.735257482155022],
              [-38.658243033190288, -8.735266723124628],
              [-38.65824796364921, -8.735276084294856],
              [-38.65825266728676, -8.7352855602925],
              [-38.658257141403155, -8.735295145678528],
              [-38.658261383430236, -8.735304834950997],
              [-38.658265390933195, -8.735314622548461],
              [-38.658269161611678, -8.735324502852974],
              [-38.65827269330142, -8.735334470193344],
              [-38.658275983975244, -8.735344518848494],
              [-38.658279031744335, -8.735354643050693],
              [-38.658281834859267, -8.735364836988706],
              [-38.658284391711106, -8.735375094811406],
              [-38.658286700832235, -8.735385410630968],
              [-38.658288760897214, -8.735395778526209],
              [-38.65829057072358, -8.735406192546133],
              [-38.658292129272517, -8.735416646713254],
              [-38.658293435649377, -8.735427135027004],
              [-38.65829448910435, -8.735437651467262],
              [-38.658295289032694, -8.735448189997724],
              [-38.658295834975277, -8.735458744569438],
              [-38.658296126618708, -8.735469309124216],
              [-38.658296163795541, -8.735479877598129],
              [-38.658295946484458, -8.735490443925062],
              [-38.658295474810117, -8.735501002040056],
              [-38.658294749043279, -8.735511545882913],
              [-38.658293769600476, -8.735522069401588],
              [-38.658292537043891, -8.735532566555785],
              [-38.658291052080969, -8.735543031320232],
              [-38.658289315564026, -8.735553457688342],
              [-38.658287328489791, -8.73556383967545],
              [-38.658285091998863, -8.735574171322753],
              [-38.658282405418433, -8.735586740008539],
              [-38.658280087930152, -8.735599381213435],
              [-38.658278141500453, -8.735612084211168],
              [-38.658276567780945, -8.735624838223005],
              [-38.65827536810702, -8.73563763242691],
              [-38.658274543496631, -8.73565045596683],
              [-38.658274094649528, -8.735663297961697],
              [-38.658274021946625, -8.735676147514885],
              [-38.658274325449618, -8.735688993723318],
              [-38.658275004901022, -8.735701825686698],
              [-38.658276059724358, -8.735714632516931],
              [-38.658277489024627, -8.735727403347155],
              [-38.658279291589089, -8.735740127341113],
              [-38.658281465888258, -8.73575279370222],
              [-38.658284010077239, -8.735765391682829],
              [-38.658286921997288, -8.735777910593326],
              [-38.6582901991776, -8.735790339811208],
              [-38.658293838837494, -8.735802668790029],
              [-38.658297837888647, -8.735814887068377],
              [-38.65830219293786, -8.735826984278864],
              [-38.658306900289801, -8.73583895015671],
              [-38.658311955950225, -8.735850774548716],
              [-38.658317355629364, -8.735862447421612],
              [-38.658323094745477, -8.735873958870723],
              [-38.658329168428878, -8.73588529912837],
              [-38.658335571525932, -8.735896458572165],
              [-38.658342298603536, -8.735907427733041],
              [-38.658349343953645, -8.735918197303478],
              [-38.658356701598173, -8.735928758145274],
              [-38.658364365294055, -8.735939101297326],
              [-38.658372328538476, -8.735949217983265],
              [-38.658380584574509, -8.735959099618908],
              [-38.658389126396784, -8.73596873781945],
              [-38.658397946757397, -8.73597812440674],
              [-38.658407038172108, -8.735987251415995],
              [-38.658416392926682, -8.735996111102791],
              [-38.658426003083456, -8.736004695949514],
              [-38.658435860488005, -8.736012998671741],
              [-38.658445956776191, -8.736021012224441],
              [-38.658456283381057, -8.73602872980794],
              [-38.658466831540316, -8.736036144873719],
              [-38.658477592303633, -8.736043251129916],
              [-38.658488556540306, -8.736050042546747],
              [-38.658499714946942, -8.736056513361545],
              [-38.65851105805541, -8.736062658083682],
              [-38.658522576240884, -8.736068471499255],
              [-38.658534259729905, -8.736073948675401],
              [-38.658546098608838, -8.736079084964707],
              [-38.65855808283213, -8.736083876008866],
              [-38.658570202230926, -8.736088317742563],
              [-38.658582446521656, -8.736092406396899],
              [-38.658594805314806, -8.736096138502605],
              [-38.658607268123639, -8.736099510892885],
              [-38.658619824373254, -8.736102520706194],
              [-38.658632463409369, -8.736105165388613],
              [-38.658645174507512, -8.736107442696097],
              [-38.65865794688203, -8.736109350696294],
              [-38.658670769695313, -8.736110887770236],
              [-38.658683632066932, -8.7361120526137],
              [-38.658696523082867, -8.736112844238244],
              [-38.65870943180483, -8.736113261972172],
              [-38.658722347279486, -8.736113305460995],
              [-38.6587352585478, -8.736112974667865],
              [-38.658748154654269, -8.736112269873454],
              [-38.658761024656279, -8.736111191675786],
              [-38.658773857633335, -8.736109740989729],
              [-38.658786642696413, -8.736107919046235],
              [-38.658799368997094, -8.736105727391227],
              [-38.65881202573685, -8.736103167884396],
              [-38.658824602176175, -8.736100242697555],
              [-38.658837087643661, -8.73609695431273],
              [-38.658849471545167, -8.736093305520232],
              [-38.65886174337269, -8.736089299416131],
              [-38.658873892713281, -8.736084939399662],
              [-38.658885909258018, -8.736080229170406],
              [-38.658897782810584, -8.736075172725121],
              [-38.65890950329603, -8.736069774354235],
              [-38.658921060769302, -8.73606403863846],
              [-38.658932445423623, -8.736057970444602],
              [-38.658943647598889, -8.736051574921714],
              [-38.658954657789828, -8.736044857496472],
              [-38.658965466654088, -8.736037823868795],
              [-38.658976065020092, -8.73603048000683],
              [-38.658986443894953, -8.736022832142041],
              [-38.658996594471979, -8.736014886763698],
              [-38.659006508138212, -8.73600665061374],
              [-38.659016176481714, -8.735998130680661],
              [-38.659025591298715, -8.735989334193764],
              [-38.659034744600554, -8.735980268617102],
              [-38.659043628620495, -8.735970941642989],
              [-38.659052235820283, -8.735961361185524],
              [-38.659060558896542, -8.735951535373943],
              [-38.659068590787015, -8.735941472545646],
              [-38.659076324676491, -8.735931181239144],
              [-38.65908375400263, -8.735920670186806],
              [-38.659090872461512, -8.735909948307521],
              [-38.65909767401304, -8.735899024698959],
              [-38.659104152885959, -8.735887908630071],
              [-38.659110303582949, -8.735876609532156],
              [-38.659112910353755, -8.735871630270253],
              [-38.659115501140008, -8.735866642757275],
              [-38.65911807591538, -8.735861647044088],
              [-38.659120634653604, -8.735856643181512],
              [-38.659123177328631, -8.735851631220537],
              [-38.65912570391459, -8.735846611212187],
              [-38.659128214385724, -8.735841583207595],
              [-38.659130708716489, -8.735836547257986],
              [-38.659133186881462, -8.735831503414623],
              [-38.659135648855433, -8.735826451728832],
              [-38.659138094613326, -8.735821392252131],
              [-38.659140524130187, -8.735816325036005],
              [-38.659142937381347, -8.735811250132082],
              [-38.659145334342192, -8.73580616759201],
              [-38.659147714988293, -8.735801077467583],
              [-38.659150079295458, -8.735795979810614],
              [-38.659152427239569, -8.735790874673039],
              [-38.659154758796724, -8.735785762106815],
              [-38.659157073943192, -8.73578064216403],
              [-38.659159372655374, -8.735775514896844],
              [-38.65916165490988, -8.735770380357446],
              [-38.659163920683483, -8.735765238598118],
              [-38.65916616995306, -8.735760089671251],
              [-38.659168402695755, -8.735754933629263],
              [-38.659170618888808, -8.735749770524633],
              [-38.659172818509646, -8.735744600410007],
              [-38.659175001535885, -8.735739423338019],
              [-38.659177167945266, -8.735734239361337],
              [-38.659179317715768, -8.735729048532805],
              [-38.659181450825471, -8.735723850905302],
              [-38.659183567252654, -8.73571864653171],
              [-38.659185666975766, -8.735713435465044],
              [-38.659187749973434, -8.735708217758377],
              [-38.659189816224433, -8.735702993464857],
              [-38.659191865707733, -8.735697762637677],
              [-38.659193898402457, -8.735692525330105],
              [-38.659195914287892, -8.735687281595473],
              [-38.659197913343526, -8.735682031487212],
              [-38.659199895549008, -8.735676775058758],
              [-38.659201860884139, -8.735671512363639],
              [-38.659203809328893, -8.73566624345548],
              [-38.65920574086347, -8.735660968387899],
              [-38.659207655468158, -8.735655687214653],
              [-38.659209553123475, -8.735650399989506],
              [-38.659211433810107, -8.735645106766309],
              [-38.659213297508877, -8.735639807598947],
              [-38.659215144200829, -8.735634502541405],
              [-38.659216973867139, -8.735629191647726],
              [-38.659218786489184, -8.735623874971965],
              [-38.659220582048519, -8.735618552568294],
              [-38.65922236052684, -8.735613224490859],
              [-38.659224121906043, -8.735607890794],
              [-38.659225866168178, -8.735602551531976],
              [-38.659227593295505, -8.735597206759195],
              [-38.659229303270429, -8.735591856530043],
              [-38.659230996075522, -8.735586500899043],
              [-38.659232671693552, -8.735581139920711],
              [-38.659234330107473, -8.735575773649687],
              [-38.659235971300383, -8.735570402140594],
              [-38.659237595255561, -8.735565025448107],
              [-38.659239201956474, -8.735559643627031],
              [-38.659240791386772, -8.735554256732136],
              [-38.659242363530254, -8.735548864818313],
              [-38.659243918370933, -8.73554346794044],
              [-38.659245455892936, -8.735538066153499],
              [-38.659246976080645, -8.735532659512499],
              [-38.65924847891857, -8.735527248072506],
              [-38.6592499643914, -8.735521831888613],
              [-38.659251432483991, -8.735516411015963],
              [-38.659252883181423, -8.735510985509809],
              [-38.659254316468918, -8.735505555425378],
              [-38.659255732331872, -8.735500120817969],
              [-38.659257130755854, -8.735494681742919],
              [-38.659258511726662, -8.735489238255619],
              [-38.659259875230177, -8.735483790411505],
              [-38.659261221252571, -8.735478338266084],
              [-38.659262549780109, -8.73547288187485],
              [-38.659263860799264, -8.73546742129337],
              [-38.659265154296669, -8.735461956577277],
              [-38.659266430259194, -8.735456487782228],
              [-38.659267688673808, -8.735451014963848],
              [-38.65926892952772, -8.735445538177958],
              [-38.659270152808283, -8.735440057480281],
              [-38.659271358503027, -8.735434572926648],
              [-38.659272546599695, -8.735429084572896],
              [-38.659273717086187, -8.735423592474953],
              [-38.659274869950579, -8.735418096688749],
              [-38.65927600518112, -8.73541259727021],
              [-38.659277122766277, -8.735407094275375],
              [-38.659278222694631, -8.735401587760247],
              [-38.659279304955007, -8.735396077780944],
              [-38.65928036953639, -8.735390564393581],
              [-38.659281416427945, -8.735385047654253],
              [-38.65928244561897, -8.735379527619219],
              [-38.659283457099015, -8.735374004344617],
              [-38.659284450857776, -8.735368477886759],
              [-38.659285426885134, -8.735362948301892],
              [-38.659286385171143, -8.735357415646314],
              [-38.65928732570606, -8.735351879976406],
              [-38.659287335009594, -8.735351881491356],
              [-38.659289464060144, -8.735338525934061],
              [-38.659291491098109, -8.735325154674685],
              [-38.659293416006612, -8.735311768484515],
              [-38.659295238674602, -8.735298368135634],
              [-38.659296958996975, -8.73528495440101],
              [-38.659298576874505, -8.735271528054369],
              [-38.659300092213883, -8.735258089870122],
              [-38.659301504927711, -8.73524464062346],
              [-38.659302814934499, -8.73523118109008],
              [-38.659304022158736, -8.735217712046378],
              [-38.659305126530754, -8.735204234269249],
              [-38.659306127986902, -8.735190748536082],
              [-38.659307026469413, -8.735177255624796],
              [-38.659307821926447, -8.735163756313627],
              [-38.659308514312151, -8.73515025138127],
              [-38.659309103586615, -8.735136741606679],
              [-38.659309589715853, -8.735123227769078],
              [-38.659309972671814, -8.735109710648016],
              [-38.659310252432448, -8.73509619102313],
              [-38.659310428981605, -8.735082669674254],
              [-38.659310502309133, -8.735069147381315],
              [-38.659310472410795, -8.735055624924291],
              [-38.659310339288346, -8.73504210308317],
              [-38.659310102949462, -8.735028582637886],
              [-38.659309763407784, -8.735015064368291],
              [-38.659309320682937, -8.735001549054195],
              [-38.659308774800422, -8.734988037475132],
              [-38.659308125791767, -8.734974530410458],
              [-38.659307373694432, -8.734961028639283],
              [-38.659306518551773, -8.734947532940399],
              [-38.659305560413152, -8.734934044092229],
              [-38.659304499333821, -8.734920562872869],
              [-38.659303335375036, -8.734907090059846],
              [-38.659302068603907, -8.734893626430361],
              [-38.659300699093535, -8.734880172760953],
              [-38.659299226922926, -8.734866729827665],
              [-38.659297652176988, -8.734853298405875],
              [-38.659295974946581, -8.734839879270371],
              [-38.659294195328449, -8.734826473195112],
              [-38.659292313425283, -8.734813080953408],
              [-38.659290329345602, -8.734799703317716],
              [-38.659288243203882, -8.734786341059676],
              [-38.659286055120461, -8.734772994950024],
              [-38.659283765221552, -8.734759665758551],
              [-38.65928137363926, -8.734746354254163],
              [-38.659278880511543, -8.734733061204626],
              [-38.659276285982223, -8.734719787376703],
              [-38.659273590200939, -8.734706533536016],
              [-38.65927079332323, -8.734693300447077],
              [-38.659267895510403, -8.734680088873189],
              [-38.659264896929621, -8.73466689957638],
              [-38.659261797753892, -8.734653733317444],
              [-38.659258598161934, -8.734640590855783],
              [-38.659255298338351, -8.734627472949503],
              [-38.659251898473478, -8.734614380355209],
              [-38.659248398763424, -8.73460131382814],
              [-38.659244799410082, -8.734588274121949],
              [-38.659241100621045, -8.734575261988763],
              [-38.659237302609704, -8.73456227817916],
              [-38.659233405595131, -8.734549323442039],
              [-38.659229409802116, -8.734536398524639],
              [-38.659225315461157, -8.734523504172495],
              [-38.65922112280839, -8.734510641129312],
              [-38.659216832085711, -8.734497810137102],
              [-38.659212443540611, -8.73448501193589],
              [-38.65920795742619, -8.734472247263952],
              [-38.659203374001294, -8.734459516857525],
              [-38.65919869353025, -8.734446821450932],
              [-38.659193916283051, -8.734434161776401],
              [-38.659189042535274, -8.734421538564186],
              [-38.659184072568024, -8.734408952542431],
              [-38.659179006668026, -8.734396404437014],
              [-38.659173845127441, -8.734383894971796],
              [-38.659168588244036, -8.734371424868289],
              [-38.659163236321028, -8.734358994845794],
              [-38.659157789667127, -8.734346605621266],
              [-38.659152248596499, -8.734334257909344],
              [-38.659146613428796, -8.734321952422258],
              [-38.659140884489048, -8.734309689869741],
              [-38.659135062107701, -8.734297470959159],
              [-38.659129146620607, -8.734285296395251],
              [-38.65912313836899, -8.734273166880309],
              [-38.659117037699417, -8.734261083113925],
              [-38.659110844963791, -8.734249045793097],
              [-38.659104560519317, -8.734237055612191],
              [-38.659098184728478, -8.734225113262768],
              [-38.659091717959058, -8.73421321943364],
              [-38.659085160584077, -8.734201374810896],
              [-38.659078512981765, -8.734189580077736],
              [-38.659071775535558, -8.734177835914448],
              [-38.659064948634111, -8.73416614299849],
              [-38.659058032671183, -8.734154502004282],
              [-38.65905102804571, -8.734142913603298],
              [-38.659043935161748, -8.734131378463939],
              [-38.659036754428378, -8.734119897251567],
              [-38.659029486259833, -8.734108470628414],
              [-38.659022131075339, -8.734097099253615],
              [-38.659014689299184, -8.73408578378301],
              [-38.659007161360577, -8.734074524869323],
              [-38.659000878644015, -8.73406523695944],
              [-38.658994655462067, -8.734055909460128],
              [-38.658988492066705, -8.734046542749086],
              [-38.658982388707528, -8.734037137205666],
              [-38.658976345631707, -8.73402769321072],
              [-38.658970363083952, -8.734018211146729],
              [-38.65896444130653, -8.734008691397673],
              [-38.658958580539249, -8.733999134349055],
              [-38.658952781019444, -8.733989540387938],
              [-38.658947042981978, -8.733979909902787],
              [-38.658941366659192, -8.733970243283643],
              [-38.658935752280989, -8.733960540921938],
              [-38.658930200074693, -8.733950803210625],
              [-38.65892471026514, -8.73394103054402],
              [-38.658919283074681, -8.733931223317885],
              [-38.658913918723059, -8.733921381929344],
              [-38.658908617427521, -8.733911506777007],
              [-38.65890337940273, -8.733901598260733],
              [-38.658898204860833, -8.733891656781786],
              [-38.65889309401134, -8.733881682742762],
              [-38.658888047061232, -8.733871676547579],
              [-38.658883064214884, -8.733861638601459],
              [-38.65887814567408, -8.733851569310875],
              [-38.65887329163801, -8.733841469083636],
              [-38.658868502303214, -8.733831338328732],
              [-38.658863777863651, -8.733821177456434],
              [-38.658859118510655, -8.733810986878202],
              [-38.658854524432876, -8.733800767006768],
              [-38.658849995816361, -8.733790518255962],
              [-38.658845532844516, -8.733780241040813],
              [-38.65884113569804, -8.733769935777531],
              [-38.65883680455503, -8.733759602883426],
              [-38.658832539590833, -8.733749242776971],
              [-38.658828340978204, -8.733738855877666],
              [-38.658824208887147, -8.733728442606171],
              [-38.658820143484974, -8.733718003384176],
              [-38.658816144936338, -8.733707538634468],
              [-38.658812213403145, -8.73369704878079],
              [-38.658808349044605, -8.733686534247939],
              [-38.658804552017223, -8.733675995461743],
              [-38.658800822474724, -8.733665432848934],
              [-38.658797160568156, -8.733654846837348],
              [-38.658793566445816, -8.733644237855582],
              [-38.658790040253223, -8.733633606333324],
              [-38.658786582133182, -8.733622952701088],
              [-38.658783192225727, -8.733612277390309],
              [-38.658779870668127, -8.733601580833279],
              [-38.658776617594896, -8.73359086346319],
              [-38.658773433137753, -8.733580125714063],
              [-38.65877031742567, -8.733569368020737],
              [-38.658767270584804, -8.733558590818831],
              [-38.658764292738525, -8.733547794544826],
              [-38.658761384007427, -8.733536979635861],
              [-38.658758544509304, -8.73352614652998],
              [-38.658755774359129, -8.733515295665837],
              [-38.658753073669075, -8.733504427482853],
              [-38.658750442548516, -8.733493542421146],
              [-38.658747881103992, -8.733482640921542],
              [-38.658745389439218, -8.733471723425501],
              [-38.658742967655094, -8.733460790375135],
              [-38.658740615849688, -8.733449842213185],
              [-38.658738334118233, -8.733438879383007],
              [-38.658736122553137, -8.733427902328584],
              [-38.658733981243934, -8.733416911494428],
              [-38.658731910277339, -8.73340590732562],
              [-38.658729909737218, -8.733394890267805],
              [-38.65872797970458, -8.733383860767102],
              [-38.658726120257569, -8.733372819270215],
              [-38.658724331471475, -8.733361766224247],
              [-38.658722613418732, -8.733350702076802],
              [-38.658720966168936, -8.733339627275948],
              [-38.65871938978875, -8.733328542270154],
              [-38.658717884342032, -8.733317447508332],
              [-38.658716449889724, -8.73330634343978],
              [-38.658715086489927, -8.733295230514177],
              [-38.658713794197823, -8.73328410918155],
              [-38.65871257306577, -8.733272979892263],
              [-38.658711423143181, -8.733261843096992],
              [-38.658710344476638, -8.733250699246767],
              [-38.658709337109805, -8.733239548792877],
              [-38.658708401083473, -8.733228392186831],
              [-38.658707536435543, -8.733217229880477],
              [-38.658706743201016, -8.733206062325797],
              [-38.658706021412023, -8.733194889975067],
              [-38.658705371097781, -8.733183713280727],
              [-38.658704792284624, -8.733172532695379],
              [-38.65870428499597, -8.733161348671789],
              [-38.658703849252369, -8.733150161662843],
              [-38.658703485071449, -8.73313897212163],
              [-38.658703192467968, -8.733127780501242],
              [-38.658702971453764, -8.733116587254932],
              [-38.658702822037768, -8.733105392835931],
              [-38.658702744226034, -8.733094197697602],
              [-38.658702738021709, -8.733083002293311],
              [-38.658702803425037, -8.733071807076422],
              [-38.658702940433358, -8.733060612500269],
              [-38.658703149041102, -8.733049419018226],
              [-38.658703429239843, -8.733038227083563],
              [-38.658703781017735, -8.733027037151128],
              [-38.658704524093089, -8.73300633487611],
              [-38.658705319055834, -8.73298563450904],
              [-38.658706165901037, -8.732964936178671],
              [-38.6587070646234, -8.732944240013675],
              [-38.65870801521735, -8.732923546142796],
              [-38.658709017676941, -8.732902854694677],
              [-38.658710071995941, -8.732882165798049],
              [-38.658711178167792, -8.732861479581535],
              [-38.658712336185609, -8.732840796173766],
              [-38.658713546042179, -8.732820115703376],
              [-38.65871480772995, -8.732799438298979],
              [-38.658716121241085, -8.732778764089163],
              [-38.658717486567411, -8.732758093202468],
              [-38.658718903700425, -8.732737425767505],
              [-38.658720372631286, -8.732716761912727],
              [-38.658721893350879, -8.732696101766669],
              [-38.658723465849747, -8.732675445457772],
              [-38.658725090118075, -8.732654793114561],
              [-38.658726766145747, -8.732634144865386],
              [-38.658728493922361, -8.732613500838703],
              [-38.658730273437143, -8.732592861162894],
              [-38.658732104679032, -8.73257222596626],
              [-38.658733987636616, -8.732551595377192],
              [-38.658735922298192, -8.732530969523951],
              [-38.658737908651716, -8.732510348534792],
              [-38.658739946684825, -8.732489732537962],
              [-38.658742036384844, -8.73246912166166],
              [-38.658744177738761, -8.732448516034086],
              [-38.658746370733247, -8.732427915783301],
              [-38.658748615354668, -8.732407321037487],
              [-38.658750911589046, -8.732386731924706],
              [-38.658753259422099, -8.732366148572959],
              [-38.658755658839233, -8.732345571110281],
              [-38.658758109825484, -8.732324999664623],
              [-38.658760612365626, -8.732304434363943],
              [-38.658763166444089, -8.732283875336053],
              [-38.658765772044958, -8.732263322708892],
              [-38.658768429152076, -8.732242776610187],
              [-38.658771137748843, -8.732222237167758],
              [-38.658773897818442, -8.732201704509329],
              [-38.658776709343691, -8.732181178762582],
              [-38.658779572307125, -8.73216066005514],
              [-38.658782486690889, -8.732140148514601],
              [-38.658785452476884, -8.732119644268545],
              [-38.65878846964663, -8.732099147444481],
              [-38.658791538181397, -8.732078658169838],
              [-38.658794658062043, -8.732058176572073],
              [-38.658797829269218, -8.732037702778532],
              [-38.658801051783136, -8.732017236916535],
              [-38.658804325583773, -8.731996779113357],
              [-38.658807650650779, -8.731976329496201],
              [-38.658811026963441, -8.73195588819228],
              [-38.658814454500764, -8.731935455328623],
              [-38.65881793324143, -8.73191503103239],
              [-38.658821463163797, -8.731894615430543],
              [-38.658825044245894, -8.73187420865006],
              [-38.658828676465461, -8.731853810817814],
              [-38.658832359799895, -8.731833422060665],
              [-38.658836094226267, -8.731813042505422],
              [-38.658839879721377, -8.731792672278806],
              [-38.658843716261636, -8.731772311507484],
              [-38.658847603823219, -8.731751960318103],
              [-38.658851542381932, -8.731731618837165],
              [-38.658855531913247, -8.731711287191182],
              [-38.658859572392373, -8.731690965506614],
              [-38.658863663794165, -8.731670653909807],
              [-38.658867806093177, -8.731650352527115],
              [-38.658871999263653, -8.731630061484751],
              [-38.658876243279479, -8.73160978090889],
              [-38.658880538114268, -8.73158951092562],
              [-38.658884883741315, -8.731569251661105],
              [-38.658889280133586, -8.731549003241209],
              [-38.65889372726371, -8.731528765791911],
              [-38.65889822510406, -8.731508539439044],
              [-38.658902773626636, -8.731488324308403],
              [-38.658907372803164, -8.731468120525673],
              [-38.658912022604987, -8.73144792821653],
              [-38.65891672300323, -8.731427747506494],
              [-38.658921473968647, -8.731407578521075],
              [-38.658926275471671, -8.731387421385707],
              [-38.65893112748244, -8.73136727622572],
              [-38.658936029970768, -8.731347143166436],
              [-38.658940982906181, -8.731327022333003],
              [-38.658945986257855, -8.731306913850608],
              [-38.658951039994676, -8.731286817844238],
              [-38.658956144085188, -8.731266734438849],
              [-38.658961298497658, -8.731246663759402],
              [-38.658966503200034, -8.731226605930651],
              [-38.658971758159936, -8.731206561077364],
              [-38.658977063344665, -8.731186529324143],
              [-38.658982418721237, -8.731166510795614],
              [-38.658987824256336, -8.731146505616215],
              [-38.658993279916338, -8.731126513910368],
              [-38.658998785667308, -8.731106535802414],
              [-38.659004341475004, -8.73108657141657],
              [-38.65900994730486, -8.731066620876984],
              [-38.659015603122015, -8.73104668430776],
              [-38.659021308891298, -8.731026761832824],
              [-38.659027064577295, -8.731006853575899],
              [-38.659032396237976, -8.730988418368019],
              [-38.659037685094468, -8.730969970959459],
              [-38.659042931118577, -8.73095151144854],
              [-38.659048134282344, -8.730933039933769],
              [-38.659053294558049, -8.730914556513603],
              [-38.659058411918117, -8.730896061286646],
              [-38.659063486335327, -8.730877554351549],
              [-38.659068517782586, -8.730859035806933],
              [-38.659073506233085, -8.730840505751639],
              [-38.659078451660207, -8.730821964284422],
              [-38.659083354037598, -8.730803411504192],
              [-38.659088213339132, -8.730784847509877],
              [-38.659093029538909, -8.730766272400514],
              [-38.659097802611207, -8.730747686275102],
              [-38.659102532530603, -8.730729089232801],
              [-38.659107219271903, -8.730710481372752],
              [-38.659111862810086, -8.730691862794224],
              [-38.659116463120398, -8.730673233596509],
              [-38.65912102017834, -8.730654593878908],
              [-38.659125533959589, -8.730635943740866],
              [-38.65913000444008, -8.730617283281818],
              [-38.659134431596001, -8.730598612601286],
              [-38.659138815403729, -8.730579931798841],
              [-38.659143155839878, -8.730561240974133],
              [-38.659147452881356, -8.730542540226768],
              [-38.659151706505213, -8.730523829656542],
              [-38.659155916688796, -8.730505109363206],
              [-38.659160083409631, -8.730486379446614],
              [-38.659164206645528, -8.730467640006625],
              [-38.659168286374495, -8.730448891143149],
              [-38.659172322574783, -8.730430132956229],
              [-38.659176315224883, -8.730411365545891],
              [-38.659180264303494, -8.730392589012181],
              [-38.659184169789569, -8.730373803455249],
              [-38.659188031662275, -8.730355008975291],
              [-38.659191849901056, -8.730336205672524],
              [-38.659195624485534, -8.730317393647214],
              [-38.659199355395579, -8.730298572999718],
              [-38.659203042611317, -8.730279743830382],
              [-38.659206686113073, -8.730260906239604],
              [-38.659210285881457, -8.730242060327846],
              [-38.659213841897255, -8.730223206195626],
              [-38.659217354141482, -8.73020434394347],
              [-38.659220822595479, -8.730185473672002],
              [-38.659224247240708, -8.7301665954818],
              [-38.659227628058929, -8.730147709473599],
              [-38.659230965032094, -8.730128815748062],
              [-38.659234258142455, -8.730109914405999],
              [-38.659237507372445, -8.730091005548159],
              [-38.659240712704708, -8.730072089275412],
              [-38.659243874122204, -8.730053165688647],
              [-38.65924699160805, -8.730034234888727],
              [-38.659250065145649, -8.730015296976653],
              [-38.659253094718594, -8.729996352053403],
              [-38.659256080310762, -8.729977400220024],
              [-38.659259021906195, -8.72995844157755],
              [-38.659261919489275, -8.729939476227091],
              [-38.659264773044505, -8.729920504269804],
              [-38.659267582556694, -8.729901525806852],
              [-38.659270348010857, -8.729882540939435],
              [-38.65927306939227, -8.729863549768821],
              [-38.659275746686404, -8.729844552396271],
              [-38.659278379879005, -8.72982554892312],
              [-38.659280968956047, -8.729806539450667],
              [-38.659283513903695, -8.729787524080331],
              [-38.659286014708407, -8.729768502913478],
              [-38.659288471356859, -8.72974947605158],
              [-38.659290883835936, -8.729730443596081],
              [-38.659293252132798, -8.729711405648478],
              [-38.659295576234811, -8.729692362310303],
              [-38.659297856129598, -8.72967331368312],
              [-38.659300091805001, -8.729654259868511],
              [-38.659302283249097, -8.729635200968058],
              [-38.65930443045022, -8.729616137083429],
              [-38.659306533396908, -8.729597068316258],
              [-38.659308592077977, -8.729577994768277],
              [-38.65931060648245, -8.729558916541173],
              [-38.659312576599561, -8.729539833736712],
              [-38.659314502418852, -8.729520746456613],
              [-38.659316383930026, -8.729501654802712],
              [-38.65931822112308, -8.729482558876795],
              [-38.659320013988214, -8.729463458780698],
              [-38.659321762515873, -8.729444354616295],
              [-38.659323466696762, -8.729425246485455],
              [-38.659325126521729, -8.729406134490082],
              [-38.659326741982021, -8.729387018732098],
              [-38.659328313068968, -8.72936789931342],
              [-38.659329839774223, -8.729348776336032],
              [-38.659331322089649, -8.729329649901951],
              [-38.659332760007331, -8.729310520113097],
              [-38.659334153519623, -8.729291387071532],
              [-38.659335502619108, -8.729272250879278],
              [-38.65933680729858, -8.729253111638393],
              [-38.659338067551118, -8.729233969450901],
              [-38.65933928336996, -8.729214824418966],
              [-38.659340454748666, -8.729195676644586],
              [-38.659341581680984, -8.729176526229947],
              [-38.659342664160917, -8.729157373277157],
              [-38.659343702182952, -8.729138217887565],
              [-38.659344215080523, -8.729128105726106],
              [-38.6593446814842, -8.729117991336331],
              [-38.659345101384233, -8.729107874929904],
              [-38.659345474771804, -8.729097756718433],
              [-38.659345801639141, -8.729087636913597],
              [-38.659346081979407, -8.729077515727134],
              [-38.659346315786721, -8.72906739337078],
              [-38.659346503056213, -8.729057270056311],
              [-38.659346643783977, -8.729047145995514],
              [-38.659346737967056, -8.729037021400178],
              [-38.659346785603475, -8.729026896482186],
              [-38.659346786692254, -8.729016771453303],
              [-38.659346741233357, -8.729006646525383],
              [-38.659346649227778, -8.728996521910213],
              [-38.659346510677416, -8.728986397819675],
              [-38.659346325585176, -8.728976274465515],
              [-38.659346093954937, -8.728966152059579],
              [-38.659345815791546, -8.728956030813595],
              [-38.659345491100829, -8.728945910939332],
              [-38.659345119889601, -8.728935792648501],
              [-38.659344702165598, -8.728925676152819],
              [-38.659344237937589, -8.728915561663904],
              [-38.659343727215273, -8.728905449393357],
              [-38.659343170009343, -8.728895339552752],
              [-38.659342566331475, -8.728885232353598],
              [-38.659341916194272, -8.72887512800736],
              [-38.65934121961137, -8.728865026725407],
              [-38.659340476597329, -8.728854928719104],
              [-38.659339687167702, -8.728844834199668],
              [-38.659338851339008, -8.728834743378339],
              [-38.659337969128735, -8.728824656466179],
              [-38.65933704055535, -8.728814573674272],
              [-38.659336065638293, -8.728804495213522],
              [-38.65933504439792, -8.728794421294765],
              [-38.659333976855663, -8.728784352128816],
              [-38.659332863033818, -8.728774287926273],
              [-38.659331702955697, -8.728764228897759],
              [-38.659330496645573, -8.728754175253668],
              [-38.659329244128699, -8.728744127204337],
              [-38.659327945431279, -8.72873408496001],
              [-38.659326600580485, -8.728724048730742],
              [-38.659325209604454, -8.728714018726526],
              [-38.659323772532296, -8.728703995157215],
              [-38.659322289394069, -8.728693978232499],
              [-38.659320760220815, -8.728683968161928],
              [-38.659319185044524, -8.728673965154947],
              [-38.659317563898171, -8.728663969420838],
              [-38.659315896815649, -8.728653981168691],
              [-38.659314183831853, -8.728644000607499],
              [-38.65931242498263, -8.728634027946052],
              [-38.659310620304765, -8.728624063393001],
              [-38.659308769836031, -8.728614107156778],
              [-38.659306873615144, -8.728604159445741],
              [-38.659304931681795, -8.72859422046796],
              [-38.659302944076565, -8.728584290431376],
              [-38.659300910841083, -8.728574369543757],
              [-38.659298832017889, -8.728564458012636],
              [-38.659296707650455, -8.728554556045399],
              [-38.659294537783239, -8.728544663849156],
              [-38.659292322461653, -8.728534781630927],
              [-38.65929006173203, -8.728524909597425],
              [-38.659287755641699, -8.72851504795519],
              [-38.659285404238872, -8.728505196910534],
              [-38.659283007572753, -8.728495356669551],
              [-38.659280565693514, -8.728485527438144],
              [-38.659278078652221, -8.728475709421872],
              [-38.659275546500929, -8.728465902826212],
              [-38.659272969292594, -8.728456107856276],
              [-38.659270347081147, -8.728446324717043],
              [-38.659267679921456, -8.728436553613129],
              [-38.659264967869319, -8.728426794749012],
              [-38.659262210981488, -8.728417048328801],
              [-38.659259409315617, -8.72840731455644],
              [-38.659256562930359, -8.728397593635552],
              [-38.659253671885246, -8.728387885769527],
              [-38.659250736240772, -8.728378191161482],
              [-38.659247756058342, -8.72836851001418],
              [-38.659244731400321, -8.728358842530179],
              [-38.659241662330011, -8.728349188911752],
              [-38.659238548911588, -8.728339549360873],
              [-38.65923539121021, -8.728329924079189],
              [-38.659232189291934, -8.728320313268053],
              [-38.659228943223773, -8.728310717128586],
              [-38.659225653073612, -8.728301135861502],
              [-38.659222318910317, -8.728291569667302],
              [-38.659218940803612, -8.728282018746079],
              [-38.659215518824212, -8.728272483297644],
              [-38.659212053043667, -8.728262963521537],
              [-38.65920854353454, -8.728253459616846],
              [-38.65920499037022, -8.728243971782472],
              [-38.659201393625061, -8.728234500216848],
              [-38.659197753374301, -8.728225045118201],
              [-38.659194069694124, -8.728215606684323],
              [-38.659190342661567, -8.728206185112601],
              [-38.659186572354635, -8.728196780600246],
              [-38.659182758852189, -8.728187393343958],
              [-38.659178902234046, -8.728178023540126],
              [-38.659175002580859, -8.728168671384802],
              [-38.659171059974241, -8.728159337073622],
              [-38.65916610041419, -8.728147575519635],
              [-38.659161209051121, -8.728135785729336],
              [-38.659156386047407, -8.728123968094129],
              [-38.659151631563148, -8.728112123006341],
              [-38.659146945756191, -8.728100250859244],
              [-38.659142328782131, -8.728088352047028],
              [-38.659137780794204, -8.728076426964662],
              [-38.659133301943399, -8.728064476008107],
              [-38.659128892378426, -8.72805249957413],
              [-38.659124552245679, -8.728040498060331],
              [-38.659120281689226, -8.728028471865137],
              [-38.659116080850858, -8.728016421387881],
              [-38.659111949870017, -8.728004347028593],
              [-38.65910788888386, -8.727992249188143],
              [-38.659103898027183, -8.727980128268173],
              [-38.659099977432504, -8.727967984671125],
              [-38.659096127229951, -8.727955818800112],
              [-38.659092347547379, -8.727943631059102],
              [-38.659088638510234, -8.727931421852693],
              [-38.659085000241646, -8.727919191586217],
              [-38.659081432862422, -8.727906940665758],
              [-38.659077936490966, -8.727894669497994],
              [-38.659074511243396, -8.727882378490371],
              [-38.659071157233356, -8.727870068050919],
              [-38.659067874572244, -8.727857738588344],
              [-38.659064663369023, -8.727845390511996],
              [-38.659061523730287, -8.72783302423181],
              [-38.659058455760267, -8.727820640158397],
              [-38.659055459560818, -8.727808238702824],
              [-38.659052535231389, -8.727795820276885],
              [-38.659049682869103, -8.72778338529284],
              [-38.659046902568619, -8.727770934163532],
              [-38.659044194422201, -8.727758467302319],
              [-38.659041558519824, -8.727745985123153],
              [-38.659038994948943, -8.727733488040364],
              [-38.659036503794681, -8.7277209764689],
              [-38.659034085139723, -8.727708450824144],
              [-38.659031739064361, -8.727695911521925],
              [-38.659029465646505, -8.727683358978553],
              [-38.659027264961594, -8.727670793610763],
              [-38.659025137082686, -8.727658215835758],
              [-38.659023082080438, -8.727645626071062],
              [-38.659021100023054, -8.727633024734706],
              [-38.659019190976316, -8.727620412244997],
              [-38.659017355003627, -8.727607789020729],
              [-38.659015592165908, -8.727595155480927],
              [-38.659013902521707, -8.727582512045066],
              [-38.659012286127059, -8.727569859132863],
              [-38.65901074303568, -8.727557197164446],
              [-38.659009273298771, -8.727544526560159],
              [-38.659007876965106, -8.727531847740657],
              [-38.659006554081053, -8.727519161126867],
              [-38.659005304690517, -8.727506467140028],
              [-38.659004128834958, -8.727493766201535],
              [-38.659003026553421, -8.727481058733058],
              [-38.659001997882498, -8.727468345156485],
              [-38.659001042856325, -8.727455625893922],
              [-38.65900016150659, -8.727442901367613],
              [-38.658999353862576, -8.727430172000039],
              [-38.658998619951063, -8.727417438213784],
              [-38.658997959796416, -8.727404700431647],
              [-38.658997373420554, -8.727391959076472],
              [-38.658996860842919, -8.727379214571277],
              [-38.658996422080527, -8.727366467339206],
              [-38.658996057147959, -8.727353717803442],
              [-38.658995766057288, -8.72734096638729],
              [-38.658995548818197, -8.727328213514042],
              [-38.658995405437892, -8.727315459607143],
              [-38.658995335921091, -8.727302705089977],
              [-38.658995340270131, -8.727289950386018],
              [-38.658995418484842, -8.727277195918695],
              [-38.658995570562631, -8.727264442111482],
              [-38.658995796498445, -8.727251689387796],
              [-38.658996096284753, -8.727238938170991],
              [-38.658996469911614, -8.727226188884419],
              [-38.658996917366601, -8.727213441951355],
              [-38.65899743863487, -8.727200697795004],
              [-38.658998033699099, -8.727187956838469],
              [-38.658998702539513, -8.727175219504753],
              [-38.658999445133922, -8.727162486216665],
              [-38.659000261457656, -8.727149757397026],
              [-38.659001151483594, -8.727137033468399],
              [-38.659002115182183, -8.727124314853183],
              [-38.659003152521436, -8.727111601973656],
              [-38.659004263466898, -8.727098895251888],
              [-38.65900544798167, -8.727086195109743],
              [-38.659006706026425, -8.727073501968858],
              [-38.659008037559389, -8.727060816250635],
              [-38.659009442536373, -8.727048138376231],
              [-38.659010920910674, -8.727035468766523],
              [-38.659012472633243, -8.727022807842188],
              [-38.659014097652545, -8.727010156023571],
              [-38.659015795914605, -8.726997513730627],
              [-38.659017567363065, -8.726984881383155],
              [-38.659019411939099, -8.726972259400474],
              [-38.659021329581449, -8.726959648201703],
              [-38.659023320226439, -8.726947048205497],
              [-38.659025383807986, -8.726934459830145],
              [-38.659027520257183, -8.72692188349497],
              [-38.659029751513678, -8.726909155712882],
              [-38.659032043236607, -8.72689643857036],
              [-38.659034395374746, -8.726883732351746],
              [-38.659036807875495, -8.726871037341168],
              [-38.659039280684887, -8.726858353822491],
              [-38.659041813747621, -8.726845682079311],
              [-38.659044407007073, -8.72683302239496],
              [-38.659047060405243, -8.726820375052471],
              [-38.659049773882799, -8.726807740334721],
              [-38.659052547379055, -8.726795118524132],
              [-38.659055380831987, -8.726782509902987],
              [-38.659058274178243, -8.726769914753207],
              [-38.659061227353121, -8.726757333356378],
              [-38.659064240290569, -8.726744765993894],
              [-38.659067312923227, -8.726732212946699],
              [-38.659070445182394, -8.726719674495476],
              [-38.659073636997988, -8.726707150920582],
              [-38.659076888298685, -8.72669464250211],
              [-38.659080199011726, -8.72668214951968],
              [-38.659083569063135, -8.726669672252646],
              [-38.659086998377511, -8.726657210980003],
              [-38.659090486878178, -8.726644765980392],
              [-38.65909403448714, -8.726632337532072],
              [-38.659097641125072, -8.726619925912953],
              [-38.659101306711293, -8.726607531400521],
              [-38.659105031163861, -8.72659515427196],
              [-38.659108814399481, -8.726582794803996],
              [-38.659112656333562, -8.726570453273006],
              [-38.659116556880193, -8.726558129954942],
              [-38.659120515952146, -8.726545825125321],
              [-38.65912453346089, -8.726533539059309],
              [-38.659128609316589, -8.726521272031595],
              [-38.659132743428117, -8.726509024316499],
              [-38.65913693570301, -8.726496796187851],
              [-38.659141186047542, -8.726484587919099],
              [-38.659145494366641, -8.726472399783189],
              [-38.659149860564, -8.726460232052684],
              [-38.659154284541984, -8.726448084999605],
              [-38.659158766201642, -8.726435958895584],
              [-38.659163305442775, -8.72642385401174],
              [-38.659167902163901, -8.726411770618782],
              [-38.659172556262192, -8.726399708986861],
              [-38.659177267633588, -8.726387669385669],
              [-38.659182036172773, -8.7263756520844],
              [-38.659186861773087, -8.726363657351802],
              [-38.659191744326627, -8.726351685456018],
              [-38.659196683724225, -8.726339736664785],
              [-38.659201679855407, -8.726327811245252],
              [-38.659206732608496, -8.726315909464082],
              [-38.659211841870466, -8.726304031587382],
              [-38.65921700752709, -8.726292177880765],
              [-38.659222229462877, -8.726280348609242],
              [-38.659227507561027, -8.72626854403736],
              [-38.659232841703542, -8.726256764429008],
              [-38.659238231771113, -8.726245010047636],
              [-38.659243677643261, -8.726233281156034],
              [-38.659249179198191, -8.726221578016464],
              [-38.659254736312874, -8.72620990089059],
              [-38.659260348863036, -8.726198250039541],
              [-38.659266016723215, -8.726186625723804],
              [-38.659271739766666, -8.726175028203325],
              [-38.659277517865398, -8.726163457737389],
              [-38.65928335089022, -8.726151914584726],
              [-38.659289238710691, -8.726140399003446],
              [-38.659295181195191, -8.726128911251024],
              [-38.659301178210796, -8.726117451584342],
              [-38.659307229623437, -8.726106020259591],
              [-38.659313335297782, -8.726094617532416],
              [-38.659319495097321, -8.726083243657735],
              [-38.65932570888431, -8.726071898889955],
              [-38.659331976519816, -8.726060583482642],
              [-38.659338297863684, -8.726049297688869],
              [-38.659344672774559, -8.726038041760944],
              [-38.659351101109891, -8.72602681595059],
              [-38.659357582725953, -8.726015620508752],
              [-38.659364117477814, -8.726004455685819],
              [-38.659370705219352, -8.725993321731393],
              [-38.659377345803264, -8.72598221889448],
              [-38.65938403908104, -8.725971147423268],
              [-38.659390784903039, -8.725960107565378],
              [-38.659397583118412, -8.725949099567613],
              [-38.659404433575148, -8.725938123676144],
              [-38.65941133612008, -8.725927180136328],
              [-38.659418290598857, -8.72591626919291],
              [-38.659425296855972, -8.725905391089849],
              [-38.659432354734754, -8.725894546070373],
              [-38.659439464077423, -8.725883734376978],
              [-38.65944662472495, -8.725872956251402],
              [-38.659453836517272, -8.725862211934686],
              [-38.659461099293111, -8.725851501667005],
              [-38.659468412890078, -8.725840825687852],
              [-38.659475777144628, -8.725830184235971],
              [-38.659483191892093, -8.725819577549265],
              [-38.659490656966689, -8.725809005864924],
              [-38.659498172201495, -8.725798469419308],
              [-38.659505737428432, -8.725787968448049],
              [-38.659513352478378, -8.725777503185911],
              [-38.659521017181063, -8.725767073866912],
              [-38.659528731364858, -8.725756680725114],
              [-38.659531530465486, -8.725752990675758],
              [-38.659534388978628, -8.725749345990829],
              [-38.65953730616171, -8.725745747617177],
              [-38.659540281256902, -8.725742196489573],
              [-38.659543313491319, -8.725738693530547],
              [-38.65954640207724, -8.725735239650097],
              [-38.659549546212311, -8.725731835745504],
              [-38.659552745079758, -8.725728482700982],
              [-38.65955599784855, -8.725725181387649],
              [-38.659559303673703, -8.725721932663093],
              [-38.659562661696398, -8.725718737371269],
              [-38.659566071044289, -8.725715596342244],
              [-38.659569530831718, -8.72571251039202],
              [-38.659573040159842, -8.725709480322294],
              [-38.659576598117049, -8.725706506920151],
              [-38.659580203779036, -8.725703590958087],
              [-38.659583856209125, -8.725700733193584],
              [-38.659587554458476, -8.725697934369053],
              [-38.659591297566337, -8.725695195211548],
              [-38.659595084560344, -8.72569251643267],
              [-38.659598914456687, -8.725689898728318],
              [-38.659602786260436, -8.725687342778523],
              [-38.659606698965767, -8.725684849247259],
              [-38.659610651556257, -8.725682418782302],
              [-38.659614643005071, -8.725680052015061],
              [-38.659618672275293, -8.725677749560306],
              [-38.65962273832023, -8.725675512016272],
              [-38.659626840083568, -8.725673339964169],
              [-38.659630976499777, -8.725671233968299],
              [-38.659635146494281, -8.725669194575701],
              [-38.659639348983774, -8.72566722231621],
              [-38.659643582876548, -8.725665317702148],
              [-38.659647847072726, -8.725663481228377],
              [-38.659652140464523, -8.725661713371899],
              [-38.659656461936621, -8.725660014592002],
              [-38.659660810366368, -8.725658385329993],
              [-38.659665184624131, -8.725656826009089],
              [-38.659669583573553, -8.725655337034439],
              [-38.659674006071896, -8.72565391879281],
              [-38.659678450970254, -8.725652571652613],
              [-38.659682917113926, -8.725651295963859],
              [-38.659687403342701, -8.725650092057903],
              [-38.659691908491141, -8.725648960247565],
              [-38.659696431388888, -8.725647900826775],
              [-38.659700970860982, -8.725646914070804],
              [-38.659705525728164, -8.725646000236008],
              [-38.659710094807146, -8.725645159559759],
              [-38.65971467691098, -8.725644392260433],
              [-38.659719270849315, -8.725643698537382],
              [-38.65972387542876, -8.725643078570821],
              [-38.659728489453094, -8.725642532521801],
              [-38.65973311172371, -8.725642060532182],
              [-38.659737741039827, -8.725641662724557],
              [-38.659742376198814, -8.725641339202312],
              [-38.659747015996551, -8.725641090049457],
              [-38.659751659227723, -8.725640915330727],
              [-38.659756304686113, -8.725640815091497],
              [-38.659760951164891, -8.725640789357822],
              [-38.659765597457017, -8.725640838136425],
              [-38.659770242355435, -8.725640961414561],
              [-38.659774884653537, -8.725641159160228],
              [-38.659779523145303, -8.72564143132209],
              [-38.659784156625769, -8.725641777829406],
              [-38.659788783891216, -8.725642198592181],
              [-38.659793403739592, -8.725642693501088],
              [-38.659798014970747, -8.725643262427592],
              [-38.659802616386763, -8.72564390522389],
              [-38.659807206792273, -8.72564462172293],
              [-38.659811784994794, -8.725645411738682],
              [-38.65981634980497, -8.725646275065865],
              [-38.659820900036969, -8.725647211480197],
              [-38.659825434508733, -8.725648220738409],
              [-38.659829952042273, -8.725649302578345],
              [-38.659834451464043, -8.72565045671894],
              [-38.659838931605144, -8.725651682860374],
              [-38.659843391301756, -8.725652980684117],
              [-38.659847829395318, -8.725654349853047],
              [-38.659852244732882, -8.725655790011423],
              [-38.659856636167454, -8.72565730078521],
              [-38.659861002558216, -8.725658881781822],
              [-38.65986534277085, -8.725660532590647],
              [-38.659869655677859, -8.725662252782771],
              [-38.659873940158832, -8.725664041911351],
              [-38.659878195100731, -8.725665899511625],
              [-38.659882419398208, -8.725667825100993],
              [-38.659886611953887, -8.725669818179206],
              [-38.659890771678597, -8.725671878228534],
              [-38.659894897491732, -8.725674004713818],
              [-38.659898988321466, -8.725676197082592],
              [-38.659903043105096, -8.725678454765369],
              [-38.659907060789259, -8.725680777175615],
              [-38.65991104033025, -8.725683163710046],
              [-38.659914980694246, -8.725685613748659],
              [-38.659918880857596, -8.725688126654955],
              [-38.659922739807143, -8.725690701776191],
              [-38.659926556540398, -8.725693338443358],
              [-38.659930330065819, -8.725696035971513],
              [-38.659934059403128, -8.725698793659873],
              [-38.65993774358342, -8.725701610792235],
              [-38.659948035960412, -8.72570965322282],
              [-38.659958288881725, -8.725717745387559],
              [-38.659968502104597, -8.725725887094908],
              [-38.659978675387165, -8.725734078152048],
              [-38.659988808488578, -8.72574231836504],
              [-38.659998901168869, -8.725750607538792],
              [-38.66000895318907, -8.725758945476992],
              [-38.660018964311163, -8.725767331982235],
              [-38.660028934298083, -8.725775766855943],
              [-38.660038862913787, -8.725784249898359],
              [-38.660048749923149, -8.725792780908657],
              [-38.66005859509206, -8.725801359684809],
              [-38.66006839818742, -8.725809986023718],
              [-38.660078158977072, -8.725818659721078],
              [-38.660087877229927, -8.725827380571548],
              [-38.660097552715854, -8.725836148368629],
              [-38.660107185205746, -8.725844962904684],
              [-38.660116774471518, -8.725853823971013],
              [-38.660126320286125, -8.725862731357836],
              [-38.66013582242352, -8.72587168485421],
              [-38.660145280658689, -8.725880684248125],
              [-38.660154694767705, -8.725889729326502],
              [-38.660164064527656, -8.725898819875166],
              [-38.660173389716633, -8.725907955678858],
              [-38.660182670113869, -8.725917136521268],
              [-38.660191905499588, -8.725926362185019],
              [-38.660201095655125, -8.725935632451604],
              [-38.660210240362886, -8.725944947101597],
              [-38.660219339406289, -8.725954305914437],
              [-38.660228392569906, -8.725963708668447],
              [-38.660237399639385, -8.725973155141059],
              [-38.660246360401423, -8.725982645108539],
              [-38.660255274643845, -8.725992178346209],
              [-38.660264142155583, -8.726001754628363],
              [-38.660272962726658, -8.726011373728207],
              [-38.660281736148207, -8.726021035417999],
              [-38.660290462212487, -8.726030739468962],
              [-38.660299140712873, -8.726040485651316],
              [-38.660307771443868, -8.726050273734263],
              [-38.660316354201093, -8.726060103486095],
              [-38.660324888781368, -8.726069974674006],
              [-38.660333374982535, -8.726079887064264],
              [-38.660341812603697, -8.726089840422205],
              [-38.660350201445056, -8.726099834512102],
              [-38.660358541307957, -8.726109869097357],
              [-38.660366831994921, -8.726119943940294],
              [-38.660375073309645, -8.726130058802429],
              [-38.660383265056979, -8.726140213444218],
              [-38.660391407042951, -8.726150407625221],
              [-38.660399499074764, -8.726160641104066],
              [-38.660407540960811, -8.726170913638427],
              [-38.660415532510669, -8.726181224985108],
              [-38.660423473535118, -8.726191574899904],
              [-38.660431363846101, -8.726201963137768],
              [-38.660439203256786, -8.726212389452678],
              [-38.660446991581551, -8.726222853597816],
              [-38.660454728635976, -8.726233355325359],
              [-38.660462414236854, -8.726243894386695],
              [-38.6604700482022, -8.726254470532234],
              [-38.660477630351238, -8.726265083511569],
              [-38.660485160504429, -8.726275733073368],
              [-38.660492638483497, -8.726286418965516],
              [-38.660500064111339, -8.72629714093495],
              [-38.660507437212139, -8.726307898727811],
              [-38.660514757611274, -8.726318692089357],
              [-38.660522025135457, -8.726329520764038],
              [-38.660529239612579, -8.726340384495423],
              [-38.660536400871784, -8.726351283026288],
              [-38.660543508743523, -8.72636221609857],
              [-38.660550563059473, -8.726373183453369],
              [-38.660557563652617, -8.726384184831039],
              [-38.660564510357148, -8.726395219971044],
              [-38.660571403008618, -8.726406288612123],
              [-38.660578241443794, -8.726417390492179],
              [-38.660585025500737, -8.7264285253483],
              [-38.660591755018828, -8.726439692916861],
              [-38.660598429838686, -8.726450892933427],
              [-38.6606050498023, -8.726462125132819],
              [-38.66061161475286, -8.726473389249023],
              [-38.660618124534977, -8.726484685015379],
              [-38.660624578994451, -8.726496012164356],
              [-38.660630977978464, -8.7265073704278],
              [-38.660637321335493, -8.72651875953674],
              [-38.660643608915322, -8.726530179221513],
              [-38.6606498405691, -8.726541629211701],
              [-38.660656016149204, -8.726553109236194],
              [-38.660662135509448, -8.726564619023177],
              [-38.660668198504915, -8.726576158300082],
              [-38.660674204992041, -8.72658772679369],
              [-38.660680154828604, -8.726599324230087],
              [-38.660686047873689, -8.726610950334688],
              [-38.660691883987774, -8.726622604832123],
              [-38.660697663032657, -8.726634287446508],
              [-38.660703384871482, -8.726645997901167],
              [-38.660709049368783, -8.72665773591882],
              [-38.660714656390404, -8.726669501221542],
              [-38.660720205803592, -8.726681293530751],
              [-38.660725697476927, -8.726693112567242],
              [-38.660731131280542, -8.726704958050334],
              [-38.660739778303764, -8.726724006095838],
              [-38.660748367945608, -8.726743079824043],
              [-38.660756900129066, -8.726762179063815],
              [-38.660765374777554, -8.726781303643808],
              [-38.660773791815032, -8.726800453392489],
              [-38.660782151166011, -8.726819628138044],
              [-38.66079045275545, -8.726838827708455],
              [-38.660798696508891, -8.726858051931435],
              [-38.660806882352368, -8.726877300634595],
              [-38.660815010212445, -8.726896573645236],
              [-38.660823080016172, -8.726915870790437],
              [-38.660831091691172, -8.726935191897081],
              [-38.660839045165531, -8.726954536791874],
              [-38.660846940367911, -8.726973905301229],
              [-38.66085477722747, -8.726993297251408],
              [-38.660862555673894, -8.72701271246844],
              [-38.660870275637393, -8.72703215077815],
              [-38.66087793704871, -8.727051612006163],
              [-38.660885539839072, -8.727071095977847],
              [-38.660893083940316, -8.727090602518482],
              [-38.660900569284713, -8.727110131453037],
              [-38.660907995805097, -8.727129682606298],
              [-38.66091536343486, -8.727149255802907],
              [-38.660922672107908, -8.727168850867214],
              [-38.660929921758637, -8.727188467623495],
              [-38.660937112321989, -8.72720810589573],
              [-38.660944243733489, -8.727227765507754],
              [-38.660951315929104, -8.727247446283181],
              [-38.660958328845425, -8.72726714804549],
              [-38.660965282419504, -8.727286870617888],
              [-38.660972176588942, -8.727306613823465],
              [-38.660979011291907, -8.727326377485101],
              [-38.660985786467059, -8.727346161425523],
              [-38.660992502053617, -8.7273659654672],
              [-38.660999157991306, -8.727385789432473],
              [-38.661005754220412, -8.72740563314354],
              [-38.661012290681768, -8.727425496422343],
              [-38.661018767316719, -8.727445379090687],
              [-38.661025184067121, -8.727465280970211],
              [-38.661031540875449, -8.727485201882399],
              [-38.661037837684603, -8.727505141648487],
              [-38.661044074438152, -8.727525100089613],
              [-38.661050251080084, -8.727545077026724],
              [-38.661056367555013, -8.727565072280608],
              [-38.661062423808048, -8.727585085671899],
              [-38.661068419784826, -8.727605117021051],
              [-38.661074355431566, -8.727625166148352],
              [-38.661080230695021, -8.72764523287394],
              [-38.661086045522445, -8.727665317017808],
              [-38.661091799861673, -8.727685418399737],
              [-38.661097493661089, -8.727705536839414],
              [-38.661103126869577, -8.727725672156375],
              [-38.66110869943661, -8.727745824169984],
              [-38.661114211312189, -8.727765992699437],
              [-38.661119662446822, -8.727786177563802],
              [-38.661125052791633, -8.727806378581972],
              [-38.661130382298239, -8.727826595572759],
              [-38.661135650918837, -8.727846828354796],
              [-38.661140858606124, -8.727867076746533],
              [-38.66114600531337, -8.72788734056636],
              [-38.661151090994416, -8.727907619632466],
              [-38.661156115603639, -8.727927913762926],
              [-38.661161079095905, -8.727948222775689],
              [-38.661165981426713, -8.727968546488569],
              [-38.661170822552052, -8.727988884719167],
              [-38.6611756024285, -8.728009237285091],
              [-38.661180321013155, -8.728029604003742],
              [-38.66118497826367, -8.72804998469242],
              [-38.661189574138277, -8.72807037916828],
              [-38.661194108595716, -8.728090787248341],
              [-38.661198581595293, -8.728111208749525],
              [-38.661202993096893, -8.728131643488632],
              [-38.661207343060909, -8.72815209128234],
              [-38.661211631448325, -8.728172551947205],
              [-38.661215858220636, -8.728193025299664],
              [-38.661220023339922, -8.728213511156074],
              [-38.661224126768815, -8.728234009332628],
              [-38.66122816847048, -8.728254519645452],
              [-38.661232148408665, -8.72827504191052],
              [-38.66123606654763, -8.728295575943742],
              [-38.661239922852232, -8.728316121560905],
              [-38.661243717287867, -8.728336678577666],
              [-38.661247449820486, -8.728357246809587],
              [-38.661251120416573, -8.728377826072213],
              [-38.661254729043208, -8.728398416180911],
              [-38.661258275667997, -8.728419016950925],
              [-38.661261760259109, -8.728439628197476],
              [-38.6612651827853, -8.728460249735619],
              [-38.661268543215819, -8.728480881380372],
              [-38.661271841520531, -8.728501522946656],
              [-38.661275077669828, -8.728522174249273],
              [-38.661278251634677, -8.728542835102967],
              [-38.661281363386578, -8.728563505322395],
              [-38.661284412897622, -8.728584184722088],
              [-38.661287400140409, -8.728604873116579],
              [-38.661290325088174, -8.728625570320217],
              [-38.661293187714634, -8.728646276147352],
              [-38.661295987994116, -8.728666990412215],
              [-38.661298725901311, -8.728687712930082],
              [-38.661302344105259, -8.728715838463218],
              [-38.661305854577435, -8.728743977507726],
              [-38.661309257266907, -8.728772129655134],
              [-38.661312552124222, -8.728800294496738],
              [-38.661315739101539, -8.728828471623709],
              [-38.661318818152559, -8.728856660627025],
              [-38.661321789232581, -8.728884861097468],
              [-38.661324652298426, -8.728913072625664],
              [-38.661327407308541, -8.7289412948021],
              [-38.66133005422288, -8.728969527217078],
              [-38.661332593002989, -8.728997769460788],
              [-38.661335023612018, -8.729026021123202],
              [-38.661337346014633, -8.729054281794271],
              [-38.661339560177126, -8.729082551063696],
              [-38.661341666067301, -8.729110828521165],
              [-38.661343663654577, -8.72913911375614],
              [-38.661345552909935, -8.729167406358025],
              [-38.661347333805921, -8.729195705916155],
              [-38.661349006316648, -8.729224012019653],
              [-38.661350570417824, -8.729252324257688],
              [-38.661352026086732, -8.729280642219203],
              [-38.661353373302191, -8.729308965493166],
              [-38.661354612044626, -8.729337293668399],
              [-38.661355742296031, -8.729365626333683],
              [-38.661356764040001, -8.729393963077726],
              [-38.661357677261641, -8.729422303489187],
              [-38.661358481947687, -8.729450647156662],
              [-38.661359178086421, -8.729478993668678],
              [-38.66135976566774, -8.72950734261377],
              [-38.661360244683067, -8.729535693580411],
              [-38.661360615125417, -8.729564046157018],
              [-38.661360876989413, -8.729592399932075],
              [-38.661361030271209, -8.729620754493919],
              [-38.661361074968561, -8.729649109430948],
              [-38.661361011080814, -8.729677464331573],
              [-38.661360838608836, -8.729705818784177],
              [-38.661360557555128, -8.729734172377125],
              [-38.661360167923732, -8.729762524698838],
              [-38.661359669720305, -8.729790875337759],
              [-38.661359062952044, -8.729819223882338],
              [-38.661358347627704, -8.729847569921024],
              [-38.661357523757687, -8.729875913042314],
              [-38.661356591353893, -8.729904252834819],
              [-38.661355550429874, -8.729932588887108],
              [-38.661354401000665, -8.729960920787832],
              [-38.661353143082962, -8.729989248125747],
              [-38.661351776694993, -8.730017570489615],
              [-38.661350301856565, -8.730045887468306],
              [-38.661348718589075, -8.730074198650739],
              [-38.661347026915458, -8.730102503625918],
              [-38.66134522686027, -8.730130801982979],
              [-38.66134331844961, -8.730159093311135],
              [-38.661341301711154, -8.730187377199696],
              [-38.661339176674154, -8.73021565323802],
              [-38.661336943369434, -8.730243921015704],
              [-38.661334601829395, -8.730272180122382],
              [-38.661332152088001, -8.730300430147802],
              [-38.661329594180778, -8.730328670681889],
              [-38.661326928144859, -8.730356901314673],
              [-38.661324154018892, -8.730385121636377],
              [-38.661321271843164, -8.730413331237287],
              [-38.661318281659426, -8.730441529707941],
              [-38.661315183511128, -8.730469716638952],
              [-38.661311977443177, -8.730497891621178],
              [-38.661308663502112, -8.730526054245612],
              [-38.661305241736009, -8.730554204103447],
              [-38.661301712194515, -8.730582340785961],
              [-38.661298074928837, -8.730610463884759],
              [-38.661294329991755, -8.730638572991614],
              [-38.661290477437625, -8.730666667698443],
              [-38.661286517322338, -8.730694747597436],
              [-38.661282449703343, -8.730722812280931],
              [-38.661278274639685, -8.730750861341523],
              [-38.661273992191937, -8.730778894372058],
              [-38.661269602422244, -8.730806910965605],
              [-38.6612651053943, -8.730834910715421],
              [-38.661260501173395, -8.730862893215038],
              [-38.661255789826299, -8.730890858058288],
              [-38.66125097142141, -8.73091880483919],
              [-38.66124604602863, -8.730946733152056],
              [-38.661241013719447, -8.730974642591459],
              [-38.661235874566884, -8.731002532752248],
              [-38.661230628645548, -8.731030403229546],
              [-38.66122527603153, -8.731058253618761],
              [-38.661219816802536, -8.731086083515637],
              [-38.661214251037777, -8.731113892516095],
              [-38.66120857881802, -8.731141680216545],
              [-38.66120280022561, -8.731169446213528],
              [-38.661196915344384, -8.731197190103982],
              [-38.66119092425977, -8.731224911485208],
              [-38.66118482705869, -8.731252609954735],
              [-38.661178623829642, -8.731280285110513],
              [-38.661172314662664, -8.731307936550721],
              [-38.661165899649319, -8.731335563874019],
              [-38.661159378882687, -8.731363166679342],
              [-38.661152752457433, -8.731390744565971],
              [-38.661146020469701, -8.731418297133539],
              [-38.66113918301722, -8.731445823982121],
              [-38.661132240199514, -8.731473324711317],
              [-38.661122489603123, -8.731512074811199],
              [-38.661112948198806, -8.731550876422915],
              [-38.661103616261556, -8.731589728428601],
              [-38.661094494060251, -8.731628629708956],
              [-38.661085581857797, -8.73166757914324],
              [-38.661076879910979, -8.731706575609316],
              [-38.661068388470603, -8.731745617983693],
              [-38.66106010778136, -8.731784705141552],
              [-38.66105203808187, -8.731823835956817],
              [-38.661044179604694, -8.731863009302121],
              [-38.661036532576304, -8.731902224048882],
              [-38.661029097217067, -8.731941479067318],
              [-38.661021873741277, -8.731980773226523],
              [-38.661014862357085, -8.732020105394421],
              [-38.661008063266578, -8.732059474437831],
              [-38.661001476665689, -8.732098879222537],
              [-38.660995102744252, -8.732138318613304],
              [-38.660988941685964, -8.732177791473873],
              [-38.660982993668391, -8.732217296667022],
              [-38.660977258862964, -8.732256833054599],
              [-38.660971737434963, -8.732296399497576],
              [-38.660966429543549, -8.732335994856031],
              [-38.660961335341682, -8.732375617989248],
              [-38.660956454976215, -8.732415267755686],
              [-38.660951788587802, -8.732454943012945],
              [-38.660947336310969, -8.732494642618054],
              [-38.660943098274025, -8.732534365427265],
              [-38.66093907459917, -8.732574110296124],
              [-38.660935265402372, -8.732613876079588],
              [-38.660931670793445, -8.732653661632026],
              [-38.660928290876036, -8.732693465807175],
              [-38.660925125747568, -8.7327332874583],
              [-38.660922175499302, -8.732773125438126],
              [-38.660919440216297, -8.732812978598929],
              [-38.660916919977446, -8.732852845792502],
              [-38.660914614855407, -8.732892725870267],
              [-38.660912524916675, -8.732932617683286],
              [-38.660910650221517, -8.732972520082271],
              [-38.660908990824012, -8.733012431917601],
              [-38.660907546772037, -8.733052352039428],
              [-38.660906318107251, -8.733092279297654],
              [-38.660905304865167, -8.733132212541964],
              [-38.660904507075003, -8.733172150621828],
              [-38.660903924759822, -8.73321209238668],
              [-38.660903557936486, -8.733252036685757],
              [-38.660903406615589, -8.733291982368261],
              [-38.660903470801607, -8.733331928283304],
              [-38.660903750492736, -8.733371873280102],
              [-38.660904245680996, -8.733411816207795],
              [-38.660904956352184, -8.733451755915615],
              [-38.660905882485899, -8.733491691252869],
              [-38.660907024055518, -8.733531621069041],
              [-38.660908381028221, -8.733571544213751],
              [-38.660909953365, -8.733611459536759],
              [-38.660911741020591, -8.733651365888083],
              [-38.660913743943595, -8.733691262118041],
              [-38.660915962076359, -8.733731147077208],
              [-38.660918395355047, -8.733771019616455],
              [-38.660921043709628, -8.733810878587059],
              [-38.660923907063868, -8.733850722840675],
              [-38.660926985335337, -8.733890551229356],
              [-38.660930278435423, -8.733930362605648],
              [-38.660933786269318, -8.733970155822545],
              [-38.660937508736026, -8.734009929733594],
              [-38.660941445728369, -8.734049683192898],
              [-38.660945597133001, -8.734089415055147],
              [-38.660949962830372, -8.73412912417565],
              [-38.660954542694768, -8.734168809410358],
              [-38.660959336594338, -8.734208469615931],
              [-38.660964344391004, -8.734248103649721],
              [-38.660969565940555, -8.734287710369864],
              [-38.660975001092659, -8.734327288635278],
              [-38.660980649690771, -8.734366837305707],
              [-38.660986511572204, -8.73440635524171],
              [-38.660992586568177, -8.734445841304719],
              [-38.660998874503711, -8.734485294357169],
              [-38.661005375197732, -8.734524713262386],
              [-38.661012088462989, -8.734564096884693],
              [-38.661019014106159, -8.7346034440894],
              [-38.661026151927764, -8.734642753742927],
              [-38.661033501722251, -8.734682024712738],
              [-38.661041063277906, -8.73472125586739],
              [-38.661048836376963, -8.734760446076614],
              [-38.661056820795537, -8.73479959421133],
              [-38.661065016303631, -8.734838699143635],
              [-38.661073422665247, -8.734877759746906],
              [-38.661082039638202, -8.734916774895771],
              [-38.661090866974355, -8.734955743466195],
              [-38.661099904419416, -8.734994664335479],
              [-38.66110915171307, -8.735033536382257],
              [-38.661118608589, -8.735072358486615],
              [-38.661128274774775, -8.735111129530059],
              [-38.661138149991977, -8.735149848395556],
              [-38.661148233956183, -8.735188513967604],
              [-38.661158526376923, -8.735227125132223],
              [-38.661169026957708, -8.735265680776978],
              [-38.661179735396097, -8.735304179791072],
              [-38.661190651383613, -8.735342621065323],
              [-38.661201774606177, -8.735381003491632],
              [-38.661204679051217, -8.735390713441099],
              [-38.661207697603778, -8.735400388880448],
              [-38.661210829849715, -8.735410028482372],
              [-38.661214075359425, -8.735419630924516],
              [-38.661217433687654, -8.73542919488964],
              [-38.661220904373749, -8.735438719065742],
              [-38.66122448694157, -8.7354482021463],
              [-38.661228180899691, -8.73545764283042],
              [-38.661231985741402, -8.735467039823076],
              [-38.661235900944746, -8.735476391835116],
              [-38.661239925972637, -8.735485697583702],
              [-38.661244060272956, -8.735494955792237],
              [-38.661248303278533, -8.735504165190678],
              [-38.661252654407349, -8.735513324515715],
              [-38.661257113062533, -8.735522432510846],
              [-38.661261678632428, -8.735531487926634],
              [-38.661266350490735, -8.73554048952086],
              [-38.661271127996613, -8.735549436058713],
              [-38.661276010494646, -8.735558326312873],
              [-38.66128099731511, -8.735567159063793],
              [-38.661286087773881, -8.735575933099787],
              [-38.661291281172673, -8.735584647217257],
              [-38.661296576799074, -8.735593300220776],
              [-38.661301973926619, -8.735601890923348],
              [-38.661307471814943, -8.735610418146493],
              [-38.661313069709855, -8.735618880720455],
              [-38.661318766843443, -8.735627277484287],
              [-38.661324562434189, -8.735635607286167],
              [-38.66133045568705, -8.735643868983439],
              [-38.661336445793587, -8.735652061442716],
              [-38.661342531932121, -8.73566018354018],
              [-38.661348713267721, -8.735668234161606],
              [-38.661354988952446, -8.735676212202639],
              [-38.661361358125433, -8.735684116568885],
              [-38.661367819912932, -8.735691946175962],
              [-38.661374373428536, -8.735699699949839],
              [-38.661381017773223, -8.735707376826836],
              [-38.661387752035559, -8.735714975753838],
              [-38.661394575291702, -8.735722495688439],
              [-38.661401486605662, -8.735729935599048],
              [-38.661408485029376, -8.735737294465048],
              [-38.661415569602752, -8.735744571276976],
              [-38.661422739353988, -8.735751765036559],
              [-38.661429993299507, -8.735758874756964],
              [-38.661437330444222, -8.735765899462921],
              [-38.66144474978163, -8.735772838190726],
              [-38.661452250293948, -8.735779689988574],
              [-38.661459830952268, -8.735786453916482],
              [-38.66146749071666, -8.735793129046632],
              [-38.661475228536403, -8.735799714463266],
              [-38.661483043349968, -8.735806209263037],
              [-38.661490934085364, -8.735812612554996],
              [-38.661498899660124, -8.735818923460679],
              [-38.661506938981546, -8.735825141114393],
              [-38.661515050946775, -8.735831264663204],
              [-38.661523234443031, -8.735837293267101],
              [-38.661531488347713, -8.735843226099032],
              [-38.661539811528527, -8.735849062345157],
              [-38.661548202843726, -8.735854801204852],
              [-38.661556661142171, -8.735860441890836],
              [-38.661565185263569, -8.735865983629379],
              [-38.66157377403858, -8.735871425660228],
              [-38.661582426288966, -8.735876767236803],
              [-38.661591140827852, -8.735882007626392],
              [-38.661599916459757, -8.735887146110127],
              [-38.661608751980872, -8.735892181983109],
              [-38.661617646179096, -8.73589711455446],
              [-38.661626597834342, -8.735901943147576],
              [-38.661635605718629, -8.735906667100076],
              [-38.661644668596246, -8.73591128576391],
              [-38.661653785223933, -8.73591579850549],
              [-38.661662954351108, -8.735920204705764],
              [-38.661672174719918, -8.735924503760247],
              [-38.661681445065526, -8.735928695079254],
              [-38.661690764116209, -8.735932778087786],
              [-38.661700130593601, -8.735936752225733],
              [-38.661709543212801, -8.735940616947909],
              [-38.661719000682567, -8.735944371724189],
              [-38.661728501705547, -8.735948016039456],
              [-38.661738044978371, -8.735951549393782],
              [-38.661747629191893, -8.735954971302519],
              [-38.661757253031347, -8.735958281296176],
              [-38.66176691517655, -8.735961478920711],
              [-38.661776614302035, -8.735964563737465],
              [-38.661786349077254, -8.73596753532329],
              [-38.661796118166819, -8.735970393270504],
              [-38.661805920230591, -8.735973137187079],
              [-38.661815753923918, -8.735975766696614],
              [-38.661825617897811, -8.735978281438333],
              [-38.661835510799136, -8.735980681067339],
              [-38.66184543127077, -8.735982965254388],
              [-38.661855377951824, -8.73598513368618],
              [-38.661865349477793, -8.735987186065211],
              [-38.661875344480833, -8.735989122109977],
              [-38.661885361589775, -8.735990941554849],
              [-38.661895399430477, -8.735992644150244],
              [-38.661905456625931, -8.735994229662632],
              [-38.661915531796531, -8.735995697874445],
              [-38.661925623560421, -8.735997048583505],
              [-38.661937253999177, -8.735998477759363],
              [-38.661948892498593, -8.73599984043603],
              [-38.661960538674556, -8.736001136568522],
              [-38.661972192142684, -8.736002366114086],
              [-38.661983852518368, -8.736003529032113],
              [-38.661995519416742, -8.736004625284265],
              [-38.662007192452769, -8.736005654834301],
              [-38.662018871241173, -8.736006617648258],
              [-38.662030555396498, -8.736007513694361],
              [-38.662042244533133, -8.73600834294303],
              [-38.662053938265252, -8.736009105366914],
              [-38.662065636206925, -8.73600980094087],
              [-38.662077337972086, -8.736010429641894],
              [-38.66208904317449, -8.736010991449254],
              [-38.662100751427829, -8.736011486344406],
              [-38.662112462345675, -8.736011914311026],
              [-38.662124175541514, -8.736012275334978],
              [-38.662135890628747, -8.736012569404366],
              [-38.662147607220746, -8.736012796509421],
              [-38.662159324930784, -8.736012956642748],
              [-38.662171043372147, -8.736013049798984],
              [-38.662182762158054, -8.736013075975071],
              [-38.662194480901732, -8.736013035170165],
              [-38.662206199216428, -8.736012927385595],
              [-38.662217916715356, -8.736012752624921],
              [-38.662229633011819, -8.736012510893913],
              [-38.66224134771911, -8.736012202200532],
              [-38.662253060450574, -8.736011826554986],
              [-38.66226477081964, -8.736011383969688],
              [-38.662276478439836, -8.736010874459206],
              [-38.66228818292474, -8.736010298040391],
              [-38.66229988388806, -8.736009654732236],
              [-38.662311580943594, -8.736008944556001],
              [-38.662323273705319, -8.736008167535125],
              [-38.662334961787295, -8.736007323695233],
              [-38.662346644803748, -8.736006413064178],
              [-38.662358322369109, -8.736005435672032],
              [-38.662369994097965, -8.736004391551052],
              [-38.662381659605067, -8.736003280735666],
              [-38.662393318505444, -8.736002103262571],
              [-38.662404970414265, -8.736000859170602],
              [-38.662416614946991, -8.735999548500834],
              [-38.662428251719277, -8.735998171296528],
              [-38.662439880347058, -8.735996727603117],
              [-38.662451500446551, -8.735995217468277],
              [-38.662463111634224, -8.735993640941835],
              [-38.662474713526862, -8.735991998075836],
              [-38.662486305741538, -8.735990288924471],
              [-38.66249788789569, -8.735988513544163],
              [-38.662509459607016, -8.735986671993558],
              [-38.662521020493621, -8.735984764333354],
              [-38.662532570173923, -8.735982790626576],
              [-38.662544108266751, -8.735980750938339],
              [-38.662555634391275, -8.735978645335951],
              [-38.662567148167099, -8.735976473888924],
              [-38.662578649214211, -8.735974236668923],
              [-38.662590137153025, -8.735971933749767],
              [-38.662601611604359, -8.735969565207467],
              [-38.662613072189544, -8.735967131120219],
              [-38.662624518530329, -8.735964631568368],
              [-38.662635950248898, -8.73596206663437],
              [-38.66264736696796, -8.735959436402901],
              [-38.662658768310735, -8.735956740960784],
              [-38.662670153900926, -8.735953980396944],
              [-38.662681523362721, -8.735951154802516],
              [-38.662692876320911, -8.735948264270757],
              [-38.66270421240079, -8.735945308897042],
              [-38.662715531228208, -8.73594228877896],
              [-38.662726832429605, -8.735939204016137],
              [-38.662738115631974, -8.735936054710432],
              [-38.662749380462927, -8.735932840965747],
              [-38.662760626550686, -8.735929562888174],
              [-38.662771853524042, -8.735926220585872],
              [-38.66278306101249, -8.735922814169207],
              [-38.662794248646129, -8.735919343750563],
              [-38.662805416055704, -8.735915809444471],
              [-38.662816562872635, -8.735912211367603],
              [-38.662827688729053, -8.735908549638712],
              [-38.662838793257727, -8.735904824378661],
              [-38.662849876092167, -8.735901035710365],
              [-38.662860936866601, -8.735897183758915],
              [-38.662871975215964, -8.73589326865139],
              [-38.662882990775934, -8.735889290517015],
              [-38.662893983182954, -8.735885249487124],
              [-38.662904952074243, -8.73588114569508],
              [-38.662915897087743, -8.735876979276311],
              [-38.66292681786225, -8.735872750368316],
              [-38.662937714037312, -8.735868459110698],
              [-38.662948585253318, -8.735864105645076],
              [-38.662959431151457, -8.735859690115126],
              [-38.662970251373793, -8.73585521266657],
              [-38.662981045563164, -8.735850673447214],
              [-38.662991813363348, -8.735846072606863],
              [-38.663002554418952, -8.735841410297363],
              [-38.663013268375487, -8.735836686672592],
              [-38.663023954879321, -8.73583190188843],
              [-38.663034613577764, -8.735827056102814],
              [-38.663045244119033, -8.735822149475659],
              [-38.663055846152226, -8.735817182168816],
              [-38.663058818784847, -8.735815759445876],
              [-38.663061789152621, -8.735814332048321],
              [-38.663064757248115, -8.735812899979708],
              [-38.663067723063897, -8.735811463243598],
              [-38.663070686592562, -8.735810021843626],
              [-38.663073647826685, -8.735808575783375],
              [-38.663076606758885, -8.735807125066488],
              [-38.663079563381757, -8.735805669696539],
              [-38.66308251768789, -8.735804209677205],
              [-38.663085469669916, -8.735802745012137],
              [-38.663088419320466, -8.735801275704986],
              [-38.663091366632138, -8.735799801759415],
              [-38.663094311597575, -8.735798323179123],
              [-38.663097254209411, -8.735796839967801],
              [-38.663100194460299, -8.735795352129173],
              [-38.663103132342876, -8.735793859666932],
              [-38.66310606784981, -8.735792362584821],
              [-38.663109000973741, -8.735790860886608],
              [-38.663111931707363, -8.735789354576019],
              [-38.663114860043315, -8.735787843656825],
              [-38.663117785974315, -8.735786328132816],
              [-38.663120709493008, -8.735784808007731],
              [-38.663123630592104, -8.735783283285448],
              [-38.663126549264305, -8.735781753969702],
              [-38.663129465502308, -8.735780220064362],
              [-38.663132379298823, -8.735778681573283],
              [-38.663135290646544, -8.735777138500266],
              [-38.663138199538231, -8.735775590849208],
              [-38.663141105966581, -8.735774038623962],
              [-38.663144009924345, -8.735772481828384],
              [-38.663146911404255, -8.735770920466392],
              [-38.663149810399034, -8.735769354541901],
              [-38.663152706901492, -8.735767784058817],
              [-38.663155600904325, -8.735766209021023],
              [-38.663158492400349, -8.735764629432508],
              [-38.663161381382288, -8.735763045297224],
              [-38.663164267842944, -8.735761456619125],
              [-38.663167151775092, -8.735759863402164],
              [-38.663170033171546, -8.735758265650322],
              [-38.663172912025061, -8.735756663367594],
              [-38.663175788328473, -8.735755056558025],
              [-38.663178662074586, -8.735753445225557],
              [-38.663181533256186, -8.73575182937433],
              [-38.663184401866118, -8.735750209008277],
              [-38.663187267897221, -8.735748584131498],
              [-38.663190131342319, -8.735746954748089],
              [-38.663192992194226, -8.735745320862032],
              [-38.663195850445845, -8.735743682477501],
              [-38.663198706089972, -8.735742039598545],
              [-38.663201559119493, -8.735740392229276],
              [-38.663204409527289, -8.735738740373831],
              [-38.663207257306212, -8.735737084036327],
              [-38.663210102449149, -8.735735423220882],
              [-38.663212944949002, -8.735733757931696],
              [-38.663215784798631, -8.735732088172917],
              [-38.663218621990957, -8.735730413948721],
              [-38.663221456518876, -8.735728735263256],
              [-38.663224288375318, -8.735727052120767],
              [-38.663227117553191, -8.735725364525411],
              [-38.663229944045426, -8.735723672481491],
              [-38.663232767844953, -8.735721975993162],
              [-38.663235588944715, -8.735720275064697],
              [-38.663238407337644, -8.735718569700342],
              [-38.663241223016726, -8.735716859904345],
              [-38.663244035974891, -8.735715145681013],
              [-38.663246846205105, -8.735713427034609],
              [-38.663249653700355, -8.735711703969454],
              [-38.663252458453627, -8.735709976489831],
              [-38.663255260457895, -8.735708244600058],
              [-38.663258059706159, -8.735706508304466],
              [-38.663260856191435, -8.735704767607404],
              [-38.66326364990671, -8.735703022513238],
              [-38.663266440844993, -8.735701273026297],
              [-38.663269228999347, -8.735699519151025],
              [-38.66327201436274, -8.735697760891723],
              [-38.663274796928263, -8.735695998252801],
              [-38.663277576688934, -8.735694231238716],
              [-38.663280353637802, -8.735692459853844],
              [-38.663283127767926, -8.735690684102629],
              [-38.663285899072385, -8.735688903989512],
              [-38.663288667544208, -8.73568711951893],
              [-38.663291433176525, -8.735685330695381],
              [-38.663294195962408, -8.735683537523316],
              [-38.663296955894928, -8.735681740007189],
              [-38.663299712967188, -8.735679938151534],
              [-38.663302467172322, -8.735678131960828],
              [-38.663305218503424, -8.735676321439593],
              [-38.663307966953617, -8.735674506592348],
              [-38.663310712516015, -8.735672687423659],
              [-38.663313455183776, -8.735670863938056],
              [-38.663316194950042, -8.735669036140139],
              [-38.663318931807943, -8.735667204034389],
              [-38.663321665750651, -8.735665367625458],
              [-38.66332439677133, -8.735663526917937],
              [-38.663327124863152, -8.735661681916387],
              [-38.663329850019295, -8.735659832625448],
              [-38.663332572232939, -8.735657979049735],
              [-38.663335291497312, -8.735656121193884],
              [-38.663338007805429, -8.735654259062789],
              [-38.663350027034944, -8.735645853865243],
              [-38.663361939699442, -8.735637299817508],
              [-38.663373743936688, -8.735628598256765],
              [-38.663385437901404, -8.735619750543222],
              [-38.663397019765569, -8.735610758060016],
              [-38.663408487718655, -8.73560162221289],
              [-38.663419839968, -8.735592344429952],
              [-38.663431074738959, -8.735582926161552],
              [-38.66344219027529, -8.735573368879974],
              [-38.663453184839405, -8.735563674079259],
              [-38.663464056712591, -8.735553843274927],
              [-38.663474804195324, -8.735543878003748],
              [-38.663485425607554, -8.735533779823555],
              [-38.663495919288906, -8.735523550312898],
              [-38.663506283598991, -8.735513191070869],
              [-38.66351651691761, -8.735502703716893],
              [-38.663526617645111, -8.735492089890371],
              [-38.663536584202518, -8.735481351250503],
              [-38.663546415031803, -8.735470489475961],
              [-38.663556108596225, -8.735459506264691],
              [-38.663565663380467, -8.735448403333645],
              [-38.663575077890904, -8.735437182418458],
              [-38.663584350655825, -8.735425845273223],
              [-38.663593480225735, -8.735414393670155],
              [-38.663602465173462, -8.735402829399472],
              [-38.663611304094459, -8.735391154268887],
              [-38.663619995607007, -8.735379370103491],
              [-38.663628538352455, -8.735367478745436],
              [-38.663636930995381, -8.735355482053626],
              [-38.663645172223838, -8.735343381903363],
              [-38.663653260749541, -8.735331180186249],
              [-38.663661195308102, -8.735318878809641],
              [-38.66366897465916, -8.735306479696565],
              [-38.663676597586644, -8.735293984785226],
              [-38.663684062898945, -8.735281396028915],
              [-38.663691369429067, -8.73526871539554],
              [-38.663698516034856, -8.735255944867349],
              [-38.663705501599132, -8.735243086440693],
              [-38.663712325029941, -8.735230142125626],
              [-38.663718985260601, -8.735217113945637],
              [-38.663725481250026, -8.735204003937316],
              [-38.663731811982721, -8.735190814150069],
              [-38.6637379764691, -8.735177546645753],
              [-38.663743973745518, -8.735164203498426],
              [-38.663749802874463, -8.735150786793819],
              [-38.663755462944749, -8.735137298629374],
              [-38.663760953071602, -8.735123741113545],
              [-38.663766272396778, -8.735110116365691],
              [-38.663771420088814, -8.735096426515682],
              [-38.663776395342978, -8.735082673703516],
              [-38.663781197381574, -8.735068860079098],
              [-38.663785825453942, -8.735054987801799],
              [-38.663790278836629, -8.735041059040153],
              [-38.663794556833501, -8.735027075971558],
              [-38.663798658775818, -8.735013040781896],
              [-38.663802584022378, -8.73499895566518],
              [-38.663806331959584, -8.734984822823201],
              [-38.663809902001596, -8.734970644465241],
              [-38.663813293590337, -8.734956422807747],
              [-38.663816506195644, -8.734942160073844],
              [-38.663819539315341, -8.734927858493112],
              [-38.663822392475304, -8.734913520301196],
              [-38.663825065229538, -8.734899147739528],
              [-38.663827557160253, -8.73488474305482],
              [-38.663829867877922, -8.734870308498875],
              [-38.663831997021354, -8.734855846328115],
              [-38.663833944257746, -8.734841358803283],
              [-38.663835709282715, -8.734826848189149],
              [-38.663837291820343, -8.73481231675405],
              [-38.663838691623319, -8.734797766769518],
              [-38.663839908472795, -8.734783200510067],
              [-38.663840942178595, -8.734768620252739],
              [-38.663841792579142, -8.734754028276726],
              [-38.663842459541527, -8.734739426863099],
              [-38.663842942961509, -8.734724818294398],
              [-38.663843242763534, -8.734710204854203],
              [-38.663843358900756, -8.734695588826984],
              [-38.66384329135505, -8.734680972497548],
              [-38.663843040136996, -8.734666358150697],
              [-38.663842605285886, -8.734651748071041],
              [-38.663841986869713, -8.734637144542415],
              [-38.663841184985166, -8.734622549847678],
              [-38.663840199757651, -8.734607966268312],
              [-38.663839031341141, -8.734593396084055],
              [-38.663837679918373, -8.734578841572512],
              [-38.663836145700586, -8.734564305008922],
              [-38.663834428927643, -8.734549788665641],
              [-38.663832529867939, -8.734535294811906],
              [-38.663830448818345, -8.734520825713444],
              [-38.663828186104205, -8.734506383632031],
              [-38.663825742079275, -8.734491970825365],
              [-38.663823117125588, -8.734477589546389],
              [-38.663820311653531, -8.734463242043272],
              [-38.663817326101665, -8.734448930558845],
              [-38.663814160936745, -8.734434657330265],
              [-38.663810816653537, -8.734420424588787],
              [-38.663807293774873, -8.734406234559293],
              [-38.663803592851473, -8.734392089459968],
              [-38.663799714461895, -8.734377991502003],
              [-38.663797352682941, -8.734369489249998],
              [-38.663795055452184, -8.734360969510188],
              [-38.663792822900369, -8.734352432767611],
              [-38.663790655154649, -8.73434387950835],
              [-38.663788552338396, -8.734335310219324],
              [-38.663786514571349, -8.734326725388453],
              [-38.663784541969498, -8.734318125504492],
              [-38.66378263464518, -8.734309511057047],
              [-38.663780792706952, -8.734300882536576],
              [-38.663779016259689, -8.734292240434321],
              [-38.663777305404516, -8.734283585242332],
              [-38.663775660238855, -8.734274917453389],
              [-38.663774080856349, -8.734266237560918],
              [-38.663772567346925, -8.734257546059183],
              [-38.663771119796735, -8.734248843442943],
              [-38.663769738288202, -8.7342401302077],
              [-38.66376842289997, -8.734231406849549],
              [-38.663767173706894, -8.734222673865149],
              [-38.663765990780142, -8.734213931751636],
              [-38.663764874187031, -8.734205181006809],
              [-38.663763823991125, -8.734196422128813],
              [-38.663762840252232, -8.73418765561637],
              [-38.663761923026328, -8.734178881968564],
              [-38.663761072365652, -8.734170101684894],
              [-38.663760288318606, -8.734161315265297],
              [-38.663759570929848, -8.734152523209984],
              [-38.663758920240205, -8.73414372601952],
              [-38.663758336286712, -8.734134924194789],
              [-38.663757819102621, -8.734126118236876],
              [-38.66375736871737, -8.734117308647145],
              [-38.663756985156603, -8.734108495927156],
              [-38.66375666844214, -8.734099680578627],
              [-38.663756418592016, -8.734090863103493],
              [-38.663756235620447, -8.734082044003753],
              [-38.663756119537851, -8.734073223781444],
              [-38.663756070350836, -8.734064402938838],
              [-38.663756088062172, -8.734055581978065],
              [-38.66375617267088, -8.734046761401345],
              [-38.66375632417212, -8.734037941710877],
              [-38.663756542557259, -8.734029123408773],
              [-38.66375682781387, -8.73402030699712],
              [-38.663757179925703, -8.734011492977833],
              [-38.663757598872692, -8.734002681852763],
              [-38.663758084630999, -8.73399387412354],
              [-38.663758637172975, -8.733985070291622],
              [-38.663759256467124, -8.733976270858225],
              [-38.663759942478201, -8.733967476324365],
              [-38.663760695167142, -8.73395868719072],
              [-38.663761514491092, -8.733949903957672],
              [-38.663762400403392, -8.733941127125311],
              [-38.663763352853607, -8.733932357193316],
              [-38.663764371787508, -8.733923594661],
              [-38.663765457147072, -8.733914840027243],
              [-38.663766608870496, -8.733906093790452],
              [-38.663767826892219, -8.733897356448587],
              [-38.663769111142877, -8.733888628499134],
              [-38.663770461549355, -8.73387991043897],
              [-38.663771878034765, -8.733871202764444],
              [-38.663773360518462, -8.733862505971331],
              [-38.663774908916025, -8.733853820554756],
              [-38.663776523139305, -8.733845147009186],
              [-38.663778203096392, -8.733836485828478],
              [-38.663779948691634, -8.733827837505714],
              [-38.663781759825646, -8.733819202533306],
              [-38.663783636395316, -8.733810581402867],
              [-38.663785578293769, -8.733801974605173],
              [-38.663787585410489, -8.733793382630298],
              [-38.663789657631156, -8.733784805967396],
              [-38.66379179483782, -8.73377624510473],
              [-38.663793996908787, -8.733767700529761],
              [-38.663796263718673, -8.733759172728909],
              [-38.663798595138431, -8.733750662187706],
              [-38.663800991035302, -8.73374216939067],
              [-38.663803451272891, -8.733733694821384],
              [-38.663805975711128, -8.733725238962247],
              [-38.663808564206263, -8.733716802294772],
              [-38.663811216610938, -8.73370838529919],
              [-38.66381393277414, -8.733699988454788],
              [-38.663816712541212, -8.733691612239616],
              [-38.663819555753875, -8.733683257130508],
              [-38.663822462250295, -8.7336749236032],
              [-38.663825431864957, -8.733666612132113],
              [-38.663828464428803, -8.733658323190468],
              [-38.663831559769157, -8.733650057250184],
              [-38.663834717709818, -8.733641814781826],
              [-38.663837938070948, -8.733633596254714],
              [-38.663841220669227, -8.733625402136768],
              [-38.663844565317767, -8.733617232894447],
              [-38.663847971826115, -8.733609088992905],
              [-38.66385144000035, -8.733600970895784],
              [-38.663854969642991, -8.733592879065263],
              [-38.663858560553102, -8.733584813962027],
              [-38.663862212526205, -8.733576776045286],
              [-38.663865925354408, -8.733568765772635],
              [-38.663869698826325, -8.733560783600112],
              [-38.663873532727095, -8.73355282998221],
              [-38.663877426838454, -8.733544905371765],
              [-38.663881380938683, -8.733537010219871],
              [-38.663885394803039, -8.733529144975124],
              [-38.663892641826983, -8.733515111853922],
              [-38.663899918043455, -8.733501093693583],
              [-38.663907223421255, -8.733487090554194],
              [-38.663914557929033, -8.7334731024958],
              [-38.66392192153539, -8.733459129578423],
              [-38.663929314208687, -8.733445171861922],
              [-38.663936735917254, -8.733431229406198],
              [-38.663944186629244, -8.733417302271031],
              [-38.663951666312734, -8.733403390516127],
              [-38.663959174935606, -8.733389494201171],
              [-38.663966712465687, -8.733375613385707],
              [-38.663974278870633, -8.733361748129322],
              [-38.663981874118022, -8.733347898491399],
              [-38.663989498175248, -8.733334064531366],
              [-38.663997151009653, -8.733320246308566],
              [-38.664004832588361, -8.733306443882206],
              [-38.664012542878496, -8.733292657311519],
              [-38.664020281846945, -8.73327888665559],
              [-38.664028049460526, -8.7332651319735],
              [-38.664035845685945, -8.733251393324171],
              [-38.664043670489747, -8.733237670766579],
              [-38.664051523838403, -8.733223964359553],
              [-38.664059405698204, -8.733210274161861],
              [-38.664067316035357, -8.733196600232217],
              [-38.664075254815948, -8.733182942629243],
              [-38.664083222005914, -8.733169301411531],
              [-38.664091217571098, -8.733155676637541],
              [-38.664099241477224, -8.733142068365726],
              [-38.664107293689845, -8.733128476654432],
              [-38.664115374174479, -8.733114901561933],
              [-38.664123482896443, -8.733101343146474],
              [-38.664131619820935, -8.73308780146613],
              [-38.664139784913118, -8.733074276579064],
              [-38.66414797813794, -8.733060768543156],
              [-38.664156199460272, -8.733047277416443],
              [-38.664164448844858, -8.733033803256705],
              [-38.664172726256311, -8.733020346121748],
              [-38.664181031659147, -8.733006906069276],
              [-38.664189365017734, -8.732993483156914],
              [-38.664197726296358, -8.732980077442255],
              [-38.66420611545913, -8.732966688982744],
              [-38.664214532470105, -8.732953317835809],
              [-38.664222977293164, -8.732939964058779],
              [-38.664231449892085, -8.732926627708927],
              [-38.664239950230559, -8.732913308843447],
              [-38.664248478272128, -8.732900007519419],
              [-38.664257033980199, -8.732886723793923],
              [-38.664265617318115, -8.732873457723903],
              [-38.664274228249035, -8.732860209366258],
              [-38.664282866736023, -8.732846978777767],
              [-38.664291532742084, -8.732833766015206],
              [-38.664300226229997, -8.732820571135189],
              [-38.664308947162525, -8.732807394194312],
              [-38.664317695502234, -8.732794235249111],
              [-38.664326471211638, -8.732781094355989],
              [-38.664335274253055, -8.732767971571288],
              [-38.664344104588793, -8.732754866951293],
              [-38.664352962180949, -8.732741780552221],
              [-38.664361846991554, -8.732728712430147],
              [-38.6643707589825, -8.732715662641111],
              [-38.664379698115546, -8.732702631241086],
              [-38.66438866435238, -8.732689618285987],
              [-38.664397657654547, -8.732676623831541],
              [-38.664406677983486, -8.732663647933549],
              [-38.664415725300501, -8.732650690647603],
              [-38.664424799566795, -8.732637752029284],
              [-38.664433900743475, -8.73262483213405],
              [-38.664443028791489, -8.732611931017329],
              [-38.664452183671685, -8.732599048734462],
              [-38.664461365344835, -8.732586185340642],
              [-38.664470573771524, -8.732573340891035],
              [-38.66447980891229, -8.732560515440735],
              [-38.66448907072752, -8.732547709044766],
              [-38.6644983591775, -8.732534921757994],
              [-38.664507674222399, -8.732522153635323],
              [-38.664517015822263, -8.73250940473142],
              [-38.664526383937023, -8.732496675101022],
              [-38.664535778526528, -8.732483964798661],
              [-38.664545199550467, -8.732471273878863],
              [-38.664554646968462, -8.732458602396067],
              [-38.664564120739982, -8.732445950404601],
              [-38.664573620824406, -8.732433317958675],
              [-38.664583147180977, -8.732420705112535],
              [-38.66459269976886, -8.732408111920211],
              [-38.664602278547108, -8.732395538435719],
              [-38.664611883474599, -8.732382984712965],
              [-38.664621514510188, -8.732370450805826],
              [-38.664631171612534, -8.732357936767983],
              [-38.664640854740242, -8.732345442653138],
              [-38.664650563851794, -8.732332968514866],
              [-38.664660298905545, -8.732320514406666],
              [-38.664670059859738, -8.732308080381911],
              [-38.664679846672534, -8.732295666493947],
              [-38.664689659301949, -8.732283272795993],
              [-38.664699497705918, -8.7322708993412],
              [-38.66470936184222, -8.732258546182615],
              [-38.664719251668586, -8.732246213373234],
              [-38.66472916714261, -8.732233900965921],
              [-38.664739108221788, -8.732221609013319],
              [-38.664744535193648, -8.732214939122402],
              [-38.664749987868028, -8.732208290013752],
              [-38.664755466164515, -8.732201661785467],
              [-38.664760970002312, -8.732195054535195],
              [-38.664766499300299, -8.732188468360468],
              [-38.664772053976918, -8.732181903358306],
              [-38.664777633950287, -8.732175359625543],
              [-38.664783239138096, -8.732168837258655],
              [-38.664788869457723, -8.732162336353852],
              [-38.664794524826156, -8.732155857006939],
              [-38.664800205159978, -8.732149399313483],
              [-38.664805910375449, -8.732142963368661],
              [-38.664811640388457, -8.732136549267441],
              [-38.664817395114483, -8.732130157104352],
              [-38.664823174468687, -8.732123786973649],
              [-38.664828978365861, -8.732117438969244],
              [-38.664834806720414, -8.732111113184759],
              [-38.664840659446419, -8.732104809713464],
              [-38.66484653645756, -8.732098528648315],
              [-38.664852437667193, -8.732092270081885],
              [-38.664858362988291, -8.732086034106519],
              [-38.664864312333513, -8.732079820814127],
              [-38.664870285615095, -8.732073630296306],
              [-38.664876282744991, -8.73206746264438],
              [-38.664882303634784, -8.73206131794926],
              [-38.66488834819566, -8.732055196301518],
              [-38.664894416338505, -8.732049097791483],
              [-38.664900507973847, -8.732043022509039],
              [-38.664906623011881, -8.732036970543785],
              [-38.664912761362416, -8.73203094198495],
              [-38.664918922934945, -8.732024936921396],
              [-38.664925107638631, -8.732018955441694],
              [-38.664931315382255, -8.732012997634044],
              [-38.66493754607432, -8.732007063586273],
              [-38.664943799622925, -8.732001153385895],
              [-38.66495007593587, -8.731995267120022],
              [-38.6649563749206, -8.731989404875517],
              [-38.664962696484267, -8.731983566738755],
              [-38.664969040533634, -8.731977752795824],
              [-38.664975406975152, -8.731971963132477],
              [-38.664981795714972, -8.731966197834064],
              [-38.664988206658876, -8.731960456985609],
              [-38.66499463971234, -8.731954740671737],
              [-38.665001094780514, -8.731949048976714],
              [-38.665007571768193, -8.731943381984522],
              [-38.6650140705799, -8.731937739778692],
              [-38.665020591119806, -8.731932122442446],
              [-38.665027133291758, -8.731926530058525],
              [-38.665033696999309, -8.731920962709481],
              [-38.665040282145647, -8.731915420477364],
              [-38.665046888633704, -8.731909903443887],
              [-38.665053516366051, -8.731904411690422],
              [-38.665060165244959, -8.731898945297919],
              [-38.665066835172411, -8.731893504346992],
              [-38.665073526050023, -8.731888088917866],
              [-38.665080237779179, -8.731882699090397],
              [-38.665086970260901, -8.731877334944034],
              [-38.66509372339592, -8.731871996557883],
              [-38.665100497084651, -8.731866684010699],
              [-38.665107291227216, -8.73186139738076],
              [-38.665114105723433, -8.731856136746014],
              [-38.665120940472846, -8.731850902184078],
              [-38.665127795374659, -8.731845693772067],
              [-38.665134670327788, -8.731840511586826],
              [-38.665141565230897, -8.731835355704769],
              [-38.665148479982285, -8.731830226201906],
              [-38.665155414480004, -8.731825123153843],
              [-38.665162368621814, -8.73182004663586],
              [-38.665169342305177, -8.731814996722814],
              [-38.665176335427255, -8.731809973489138],
              [-38.665183347884934, -8.731804977008943],
              [-38.665190379574831, -8.731800007355831],
              [-38.665197430393256, -8.731795064603142],
              [-38.665204500236236, -8.731790148823723],
              [-38.665211588999547, -8.731785260090076],
              [-38.665218696578656, -8.731780398474267],
              [-38.665225822868756, -8.731775564047972],
              [-38.665232967764787, -8.731770756882501],
              [-38.665240131161376, -8.731765977048703],
              [-38.665247312952914, -8.731761224617093],
              [-38.66525451303351, -8.731756499657719],
              [-38.66526173129698, -8.731751802240282],
              [-38.665268967636898, -8.731747132433979],
              [-38.665276221946577, -8.731742490307731],
              [-38.665283494119052, -8.731737875929941],
              [-38.665290784047095, -8.731733289368698],
              [-38.665298091623207, -8.731728730691579],
              [-38.665305416739635, -8.731724199965836],
              [-38.665312759288376, -8.731719697258269],
              [-38.665320119161187, -8.731715222635225],
              [-38.665327496249496, -8.73171077616275],
              [-38.665334890444591, -8.731706357906338],
              [-38.665342301637402, -8.731701967931171],
              [-38.66534972971867, -8.731697606301971],
              [-38.665357174578872, -8.731693273083092],
              [-38.665364636108222, -8.731688968338368],
              [-38.665372114196693, -8.731684692131303],
              [-38.665379608734042, -8.731680444524947],
              [-38.665387119609839, -8.731676225581735],
              [-38.665405614365959, -8.731665928102883],
              [-38.665424147243662, -8.731655698693691],
              [-38.665442717989833, -8.731645537493831],
              [-38.665461326350894, -8.731635444642075],
              [-38.665479972072667, -8.731625420276254],
              [-38.665498654900581, -8.731615464533267],
              [-38.665517374579437, -8.731605577549079],
              [-38.665536130853624, -8.731595759458678],
              [-38.665554923466999, -8.73158601039621],
              [-38.665573752162892, -8.731576330494715],
              [-38.665592616684215, -8.731566719886484],
              [-38.665611516773311, -8.731557178702678],
              [-38.665630452172088, -8.731547707073672],
              [-38.665649422621946, -8.731538305128772],
              [-38.665668427863828, -8.731528972996376],
              [-38.665687467638186, -8.731519710803928],
              [-38.665706541685005, -8.731510518677922],
              [-38.665725649743791, -8.731501396743889],
              [-38.665744791553614, -8.731492345126387],
              [-38.665763966853056, -8.731483363949049],
              [-38.665783175380248, -8.731474453334492],
              [-38.665802416872879, -8.731465613404454],
              [-38.665821691068174, -8.731456844279618],
              [-38.665840997702915, -8.731448146079741],
              [-38.66586033651344, -8.731439518923612],
              [-38.665879707235682, -8.731430962929061],
              [-38.665899109605057, -8.731422478212892],
              [-38.665918543356632, -8.731414064891037],
              [-38.665938008225012, -8.731405723078339],
              [-38.665957503944362, -8.73139745288873],
              [-38.665977030248456, -8.731389254435163],
              [-38.665996586870627, -8.731381127829589],
              [-38.666016173543802, -8.731373073182979],
              [-38.666035790000485, -8.731365090605355],
              [-38.666055435972815, -8.731357180205721],
              [-38.666075111192477, -8.731349342092095],
              [-38.6660948153908, -8.731341576371532],
              [-38.666114548298687, -8.731333883150056],
              [-38.666134309646637, -8.731326262532754],
              [-38.666154099164814, -8.73131871462367],
              [-38.666173916582942, -8.731311239525914],
              [-38.666193761630396, -8.731303837341553],
              [-38.666213634036176, -8.731296508171667],
              [-38.666233533528896, -8.731289252116367],
              [-38.666253459836774, -8.731282069274704],
              [-38.666273412687723, -8.731274959744807],
              [-38.666293391809234, -8.73126792362377],
              [-38.666313396928487, -8.731260961007662],
              [-38.666333427772273, -8.731254071991529],
              [-38.666353484067052, -8.731247256669528],
              [-38.666373565538912, -8.731240515134667],
              [-38.666393671913632, -8.731233847479045],
              [-38.666413802916637, -8.731227253793712],
              [-38.666433958272982, -8.731220734168712],
              [-38.666454137707454, -8.73121428869309],
              [-38.666474340944461, -8.731207917454849],
              [-38.666494567708085, -8.731201620541016],
              [-38.666514817722117, -8.73119539803756],
              [-38.666535090710035, -8.731189250029459],
              [-38.666555386394961, -8.731183176600677],
              [-38.666575704499735, -8.731177177834178],
              [-38.666596044746889, -8.731171253811858],
              [-38.666616406858651, -8.7311654046146],
              [-38.66663679055695, -8.731159630322351],
              [-38.666657195563417, -8.731153931013907],
              [-38.666677621599419, -8.731148306767121],
              [-38.666698068385955, -8.731142757658791],
              [-38.666718535643859, -8.731137283764699],
              [-38.666739023093584, -8.731131885159614],
              [-38.666759530455366, -8.731126561917234],
              [-38.666780057449138, -8.731121314110293],
              [-38.666800603794599, -8.731116141810416],
              [-38.666821169211147, -8.731111045088266],
              [-38.666841753417927, -8.731106024013394],
              [-38.666862356133869, -8.73110107865446],
              [-38.666882977077556, -8.731096209078919],
              [-38.666903615967442, -8.73109141535331],
              [-38.666924272521641, -8.731086697543109],
              [-38.666944946458081, -8.731082055712712],
              [-38.666965637494442, -8.731077489925545],
              [-38.666986345348114, -8.731073000243917],
              [-38.667007069736343, -8.73106858672916],
              [-38.667027810376105, -8.731064249441536],
              [-38.667048566984164, -8.7310599884403],
              [-38.667069339277056, -8.731055803783654],
              [-38.667090126971118, -8.731051695528722],
              [-38.667110929782446, -8.731047663731594],
              [-38.667131747426978, -8.731043708447345],
              [-38.667152579620399, -8.731039829729994],
              [-38.667173426078229, -8.731036027632495],
              [-38.667194286515802, -8.73103230220679],
              [-38.667215160648205, -8.731028653503726],
              [-38.667236048190397, -8.73102508157317],
              [-38.667256948857137, -8.731021586463884],
              [-38.667277862362994, -8.73101816822358],
              [-38.66729878842235, -8.73101482689898],
              [-38.667319726749461, -8.73101156253567],
              [-38.667340677058377, -8.73100837517827],
              [-38.667361639062968, -8.731005264870324],
              [-38.667367907447833, -8.731004393067309],
              [-38.667374187969578, -8.731003612503784],
              [-38.667380479286621, -8.731002923346527],
              [-38.667386780055132, -8.731002325742704],
              [-38.667393088929209, -8.731001819819971],
              [-38.66739940456123, -8.731001405686404],
              [-38.667405725602144, -8.731001083430481],
              [-38.667412050701742, -8.73100085312101],
              [-38.667418378508934, -8.731000714807191],
              [-38.667424707672048, -8.73100066851859],
              [-38.667431036839147, -8.731000714265059],
              [-38.667437364658305, -8.731000852036868],
              [-38.667443689777826, -8.731001081804548],
              [-38.667450010846622, -8.731001403519063],
              [-38.667456326514497, -8.731001817111652],
              [-38.667462635432365, -8.73100232249401],
              [-38.667468936252618, -8.73100291955814],
              [-38.667475227629346, -8.731003608176538],
              [-38.667481508218678, -8.731004388202074],
              [-38.667487776679032, -8.731005259468196],
              [-38.667494031671424, -8.731006221788746],
              [-38.667500271859758, -8.731007274958165],
              [-38.667506495911098, -8.731008418751493],
              [-38.66751270249592, -8.731009652924438],
              [-38.667518890288484, -8.73101097721335],
              [-38.667525057967012, -8.731012391335339],
              [-38.667531204214072, -8.731013894988358],
              [-38.667537327716772, -8.731015487851195],
              [-38.667543427167104, -8.731017169583646],
              [-38.667549501262172, -8.731018939826457],
              [-38.667555548704513, -8.731020798201468],
              [-38.667561568202366, -8.731022744311748],
              [-38.667567558469919, -8.731024777741613],
              [-38.667573518227599, -8.731026898056671],
              [-38.667579446202367, -8.731029104804071],
              [-38.66758534112801, -8.731031397512341],
              [-38.667591201745282, -8.731033775691818],
              [-38.667597026802348, -8.731036238834491],
              [-38.66760281505492, -8.731038786414212],
              [-38.66760856526659, -8.731041417886805],
              [-38.667614276209079, -8.731044132690158],
              [-38.667619946662505, -8.731046930244377],
              [-38.667625575415585, -8.731049809951923],
              [-38.667631161266009, -8.73105277119763],
              [-38.667636703020619, -8.731055813348961],
              [-38.667642199495624, -8.731058935756083],
              [-38.667647649516958, -8.731062137752071],
              [-38.667653051920446, -8.731065418652934],
              [-38.667658405552118, -8.731068777757807],
              [-38.667663709268382, -8.731072214349261],
              [-38.667668961936343, -8.731075727693117],
              [-38.667674162433997, -8.73107931703896],
              [-38.667679309650474, -8.731082981620071],
              [-38.66768440248628, -8.731086720653682],
              [-38.667689439853568, -8.731090533341106],
              [-38.667694420676334, -8.731094418867906],
              [-38.667699343890618, -8.731098376404105],
              [-38.667704208444796, -8.731102405104393],
              [-38.667709013299763, -8.73110650410819],
              [-38.667713757429176, -8.731110672539929],
              [-38.667718439819616, -8.731114909509195],
              [-38.667723059470966, -8.73111921411093],
              [-38.667727615396373, -8.731123585425667],
              [-38.667732106622687, -8.731128022519623],
              [-38.667736532190538, -8.731132524445069],
              [-38.667740891154601, -8.731137090240313],
              [-38.667745182583772, -8.731141718930091],
              [-38.667749405561359, -8.731146409525678],
              [-38.667753559185321, -8.73115116102516],
              [-38.667757642568382, -8.731155972413561],
              [-38.667761654838337, -8.731160842663112],
              [-38.667765595138121, -8.731165770733522],
              [-38.667769462626055, -8.731170755572121],
              [-38.667773256476018, -8.731175796114146],
              [-38.667776975877608, -8.731180891282857],
              [-38.667780620036339, -8.731186039989906],
              [-38.667784188173805, -8.731191241135491],
              [-38.667787679527791, -8.731196493608635],
              [-38.667791093352534, -8.731201796287317],
              [-38.667794428918832, -8.731207148038912],
              [-38.667797685514167, -8.731212547720222],
              [-38.667800862442903, -8.731217994177856],
              [-38.667803959026422, -8.731223486248384],
              [-38.667806974603266, -8.731229022758699],
              [-38.667809908529293, -8.73123460252612],
              [-38.667812760177796, -8.731240224358825],
              [-38.667815528939634, -8.731245887055898],
              [-38.667818214223374, -8.731251589407798],
              [-38.667820815455414, -8.731257330196426],
              [-38.667823332080125, -8.731263108195529],
              [-38.667825763559911, -8.731268922170894],
              [-38.667828109375414, -8.731274770880589],
              [-38.667830369025523, -8.731280653075313],
              [-38.667832542027583, -8.731286567498579],
              [-38.667834627917394, -8.731292512887025],
              [-38.66783662624939, -8.731298487970669],
              [-38.667838536596754, -8.731304491473217],
              [-38.667840358551388, -8.731310522112251],
              [-38.667842091724125, -8.731316578599541],
              [-38.667847966769671, -8.731338235007147],
              [-38.66785356086146, -8.731359964931892],
              [-38.667858873070621, -8.73138176476597],
              [-38.667863902515165, -8.731403630890005],
              [-38.667868648359992, -8.731425559673623],
              [-38.667873109817116, -8.731447547475984],
              [-38.667877286145753, -8.731469590646512],
              [-38.667881176652465, -8.731491685525411],
              [-38.667884780691274, -8.731513828444308],
              [-38.667888097663734, -8.731536015726867],
              [-38.667891127019111, -8.731558243689348],
              [-38.667893868254396, -8.731580508641267],
              [-38.667896320914373, -8.731602806886015],
              [-38.667898484591817, -8.731625134721449],
              [-38.66790035892744, -8.731647488440535],
              [-38.667901943609969, -8.731669864331868],
              [-38.66790323837629, -8.731692258680468],
              [-38.667904243011343, -8.731714667768214],
              [-38.667904957348298, -8.731737087874512],
              [-38.667905381268518, -8.731759515277002],
              [-38.667905514701545, -8.731781946252083],
              [-38.667905357625187, -8.731804377075614],
              [-38.667904910065459, -8.731826804023388],
              [-38.667904172096605, -8.731849223371894],
              [-38.66790314384113, -8.731871631398889],
              [-38.667901825469691, -8.731894024383989],
              [-38.667900217201101, -8.731916398609288],
              [-38.667898319302353, -8.731938750360079],
              [-38.667896132088508, -8.731961075925248],
              [-38.66789365592264, -8.731983371598126],
              [-38.667890891215791, -8.732005633676993],
              [-38.667887838426978, -8.73202785846571],
              [-38.667884498062968, -8.732050042274327],
              [-38.667880870678339, -8.732072181419634],
              [-38.667876956875247, -8.732094272225902],
              [-38.667872757303478, -8.732116311025466],
              [-38.667868272660243, -8.732138294159173],
              [-38.667863503690057, -8.732160217977237],
              [-38.667858451184649, -8.732182078839642],
              [-38.667853115982851, -8.732203873116832],
              [-38.667847498970431, -8.732225597190324],
              [-38.667841601079893, -8.732247247453284],
              [-38.667835423290427, -8.732268820311148],
              [-38.667828966627681, -8.732290312182153],
              [-38.667822232163608, -8.732311719498009],
              [-38.667815221016269, -8.732333038704539],
              [-38.667807934349682, -8.732354266262087],
              [-38.667800373373609, -8.732375398646271],
              [-38.667792539343345, -8.73239643234845],
              [-38.667784433559518, -8.732417363876433],
              [-38.667776057367888, -8.732438189754975],
              [-38.667767412159115, -8.732458906526363],
              [-38.66775849936851, -8.732479510750965],
              [-38.667749320475821, -8.732499999007942],
              [-38.667739877004962, -8.732520367895544],
              [-38.667730170523804, -8.732540614032008],
              [-38.667720202643864, -8.732560734055811],
              [-38.667709975020045, -8.732580724626498],
              [-38.667699489350426, -8.732600582424949],
              [-38.667688747375898, -8.732620304154258],
              [-38.667677750879889, -8.732639886540007],
              [-38.667666501688132, -8.732659326330944],
              [-38.667655001668287, -8.732678620299465],
              [-38.667643252729654, -8.732697765242172],
              [-38.667631256822872, -8.732716757980478],
              [-38.667619015939586, -8.732735595361014],
              [-38.66760653211211, -8.732754274256207],
              [-38.667593807413112, -8.732772791564777],
              [-38.667580843955243, -8.732791144212278],
              [-38.66756764389077, -8.73280932915169],
              [-38.667554209411286, -8.732827343363709],
              [-38.667540542747268, -8.73284518385748],
              [-38.667526646167808, -8.732862847670869],
              [-38.667512521980065, -8.73288033187122],
              [-38.6674981725291, -8.732897633555627],
              [-38.667483600197308, -8.732914749851451],
              [-38.667468807404092, -8.732931677916897],
              [-38.667453796605493, -8.732948414941363],
              [-38.667438570293726, -8.732964958146066],
              [-38.667423130996781, -8.732981304784264],
              [-38.667407481278033, -8.732997452141936],
              [-38.667391623735796, -8.733013397538155],
              [-38.667375561002871, -8.733029138325476],
              [-38.66735929574611, -8.733044671890479],
              [-38.667342830666065, -8.733059995654079],
              [-38.667326168496373, -8.73307510707212],
              [-38.667309312003475, -8.733090003635635],
              [-38.667292263985999, -8.733104682871312],
              [-38.667275027274449, -8.733119142341979],
              [-38.667257604730608, -8.733133379646889],
              [-38.66723999924713, -8.733147392422282],
              [-38.66722221374706, -8.733161178341545],
              [-38.667204251183293, -8.733174735115842],
              [-38.667186114538168, -8.733188060494285],
              [-38.667167806822874, -8.733201152264515],
              [-38.667149331077084, -8.733214008252874],
              [-38.667130690368275, -8.733226626324834],
              [-38.667111887791371, -8.733239004385496],
              [-38.667092926468115, -8.733251140379638],
              [-38.667076156581366, -8.733261856145459],
              [-38.667059510461961, -8.733272761258846],
              [-38.667042990269607, -8.733283854304867],
              [-38.667026598147721, -8.733295133844328],
              [-38.667010336223065, -8.733306598413755],
              [-38.666994206605516, -8.733318246525689],
              [-38.666978211387793, -8.733330076668896],
              [-38.666962352645172, -8.733342087308502],
              [-38.666946632435227, -8.733354276886176],
              [-38.666931052797558, -8.733366643820407],
              [-38.666915615753545, -8.733379186506721],
              [-38.666900323306024, -8.733391903317749],
              [-38.666885177439113, -8.733404792603579],
              [-38.666870180117876, -8.733417852691911],
              [-38.666855333288154, -8.733431081888293],
              [-38.666840638876231, -8.733444478476329],
              [-38.666826098788597, -8.733458040717929],
              [-38.66681171491178, -8.73347176685345],
              [-38.666797489111971, -8.733485655102005],
              [-38.666783423234904, -8.733499703661735],
              [-38.666769519105557, -8.733513910709883],
              [-38.666755778527886, -8.733528274403236],
              [-38.666742203284691, -8.733542792878179],
              [-38.666728795137246, -8.733557464251032],
              [-38.66671555582522, -8.733572286618283],
              [-38.666702487066317, -8.733587258056819],
              [-38.666689590556153, -8.733602376624214],
              [-38.666676867967986, -8.733617640358931],
              [-38.666664320952499, -8.733633047280582],
              [-38.666651951137609, -8.733648595390264],
              [-38.666639760128234, -8.733664282670686],
              [-38.666627749506091, -8.733680107086506],
              [-38.666615920829514, -8.733696066584667],
              [-38.666604275633226, -8.733712159094518],
              [-38.666592815428103, -8.733728382528119],
              [-38.666581541701092, -8.733744734780629],
              [-38.666570455914872, -8.733761213730419],
              [-38.666559559507803, -8.733777817239442],
              [-38.666548853893623, -8.733794543153561],
              [-38.666538340461372, -8.733811389302666],
              [-38.666528020575093, -8.733828353501075],
              [-38.666517895573762, -8.733845433547806],
              [-38.666507966771064, -8.733862627226808],
              [-38.666498235455222, -8.733879932307335],
              [-38.666488702888827, -8.733897346544159],
              [-38.666479370308693, -8.733914867677871],
              [-38.666470238925712, -8.733932493435237],
              [-38.666461309924635, -8.733950221529444],
              [-38.666452584463954, -8.733968049660371],
              [-38.666444063675812, -8.733985975514953],
              [-38.666435748665705, -8.734003996767386],
              [-38.666427640512531, -8.734022111079554],
              [-38.66641974026826, -8.734040316101195],
              [-38.666412048957952, -8.734058609470372],
              [-38.666404567579541, -8.73407698881361],
              [-38.6663972971037, -8.734095451746333],
              [-38.666390238473774, -8.734113995873042],
              [-38.666383392605596, -8.7341326187878],
              [-38.666376760387401, -8.734151318074392],
              [-38.666370342679741, -8.734170091306682],
              [-38.666364140315267, -8.734188936048959],
              [-38.666358154098759, -8.734207849856238],
              [-38.666352384806906, -8.734226830274581],
              [-38.666346833188278, -8.734245874841376],
              [-38.666341499963202, -8.734264981085749],
              [-38.666336385823641, -8.734284146528713],
              [-38.666331491433191, -8.734303368683696],
              [-38.666326817426871, -8.734322645056761],
              [-38.666322364411158, -8.734341973146895],
              [-38.666318132963831, -8.734361350446406],
              [-38.666314123633931, -8.734380774441188],
              [-38.666310336941677, -8.734400242611114],
              [-38.666306773378423, -8.734419752430268],
              [-38.666303433406533, -8.734439301367424],
              [-38.666300317459374, -8.734458886886197],
              [-38.666297425941288, -8.734478506445466],
              [-38.666294759227426, -8.734498157499708],
              [-38.66629231766386, -8.734517837499338],
              [-38.66629010156737, -8.73453754389099],
              [-38.666288111225526, -8.734557274117826],
              [-38.666286346896626, -8.734577025620023],
              [-38.666284808809571, -8.734596795834905],
              [-38.666283497163988, -8.734616582197413],
              [-38.666282412130066, -8.734636382140394],
              [-38.666281553848648, -8.734656193094885],
              [-38.666280922431092, -8.73467601249053],
              [-38.666280517959386, -8.734695837755893],
              [-38.666280340486026, -8.73471566631876],
              [-38.666280390034103, -8.734735495606484],
              [-38.666280666597189, -8.734755323046306],
              [-38.666281170139435, -8.734775146065743],
              [-38.666281900595564, -8.734794962092861],
              [-38.666282857870854, -8.734814768556644],
              [-38.666284041841116, -8.734834562887345],
              [-38.666285452352774, -8.734854342516691],
              [-38.666287089222863, -8.734874104878434],
              [-38.66628895223905, -8.734893847408511],
              [-38.666291041159653, -8.734913567545432],
              [-38.666293355714068, -8.734933262729967],
              [-38.666294448681583, -8.734941684836187],
              [-38.66629564847964, -8.734950092536437],
              [-38.666296954916838, -8.734958484489404],
              [-38.666298367784783, -8.734966859356364],
              [-38.666299886858056, -8.73497521580126],
              [-38.66630151189436, -8.734983552490966],
              [-38.666303242634442, -8.734991868095559],
              [-38.666305078802196, -8.735000161288442],
              [-38.666307020104739, -8.735008430746605],
              [-38.666309066232358, -8.73501667515081],
              [-38.666311216858631, -8.73502489318585],
              [-38.666313471640485, -8.735033083540676],
              [-38.666315830218245, -8.735041244908702],
              [-38.666318292215628, -8.735049375987925],
              [-38.666320857239874, -8.735057475481208],
              [-38.666323524881804, -8.735065542096455],
              [-38.666326294715866, -8.735073574546766],
              [-38.666329166300166, -8.735081571550738],
              [-38.666332139176646, -8.735089531832612],
              [-38.666335212871005, -8.735097454122457],
              [-38.666338386892924, -8.73510533715648],
              [-38.666341660736073, -8.735113179677045],
              [-38.666345033878152, -8.73512098043307],
              [-38.666348505781066, -8.735128738180109],
              [-38.666352075890948, -8.735136451680521],
              [-38.66635574363827, -8.735144119703799],
              [-38.666359508437893, -8.7351517410267],
              [-38.666363369689265, -8.735159314433314],
              [-38.666367326776381, -8.735166838715516],
              [-38.666371379067975, -8.735174312672948],
              [-38.666375525917594, -8.735181735113247],
              [-38.666379766663702, -8.73518910485234],
              [-38.666384100629756, -8.735196420714567],
              [-38.666388527124383, -8.735203681532763],
              [-38.666393045441424, -8.735210886148639],
              [-38.666397654860063, -8.735218033412849],
              [-38.666402354644994, -8.735225122185167],
              [-38.666407144046431, -8.735232151334726],
              [-38.666412022300364, -8.735239119740186],
              [-38.666416988628519, -8.73524602628985],
              [-38.666422042238658, -8.735252869881894],
              [-38.666427182324576, -8.73525964942462],
              [-38.666432408066257, -8.735266363836427],
              [-38.666437718630078, -8.735273012046196],
              [-38.666443113168825, -8.735279592993336],
              [-38.666448590821901, -8.735286105627956],
              [-38.666454150715481, -8.735292548911117],
              [-38.666459791962595, -8.735298921814916],
              [-38.666465513663269, -8.735305223322694],
              [-38.666471314904761, -8.735311452429153],
              [-38.666477194761576, -8.735317608140544],
              [-38.666483152295704, -8.7353236894749],
              [-38.666489186556753, -8.735329695462024],
              [-38.666495296582077, -8.73533562514376],
              [-38.666501481396949, -8.735341477574174],
              [-38.666507740014673, -8.735347251819636],
              [-38.666514071436858, -8.735352946958933],
              [-38.666520474653431, -8.735358562083574],
              [-38.666526948642883, -8.735364096297753],
              [-38.66653349237243, -8.735369548718577],
              [-38.666540104798145, -8.73537491847623],
              [-38.666546784865155, -8.735380204714062],
              [-38.66655353150778, -8.735385406588783],
              [-38.666560343649735, -8.735390523270523],
              [-38.666567220204264, -8.735395553943007],
              [-38.666574160074376, -8.735400497803687],
              [-38.666581162152937, -8.735405354063895],
              [-38.666588225322897, -8.73541012194887],
              [-38.666595348457477, -8.735414800698003],
              [-38.66660253042032, -8.735419389564905],
              [-38.666609770065705, -8.735423887817495],
              [-38.666617066238658, -8.735428294738172],
              [-38.666624417775239, -8.73543260962391],
              [-38.666631823502655, -8.735436831786354],
              [-38.666639282239444, -8.735440960551902],
              [-38.666646792795753, -8.735444995261913],
              [-38.666654353973399, -8.735448935272728],
              [-38.666661964566131, -8.735452779955823],
              [-38.666669623359851, -8.735456528697799],
              [-38.666677329132732, -8.735460180900638],
              [-38.666685080655483, -8.735463735981714],
              [-38.666692876691499, -8.735467193373895],
              [-38.666700715997081, -8.735470552525609],
              [-38.666708597321588, -8.73547381290093],
              [-38.666716519407757, -8.735476973979782],
              [-38.666724480991739, -8.735480035257829],
              [-38.666732480803432, -8.73548299624675],
              [-38.66674051756663, -8.735485856474176],
              [-38.666748589999187, -8.735488615483751],
              [-38.666756696813358, -8.735491272835391],
              [-38.666764836715828, -8.73549382810517],
              [-38.666773008408043, -8.735496280885366],
              [-38.666781210586365, -8.735498630784791],
              [-38.666789441942292, -8.735500877428494],
              [-38.666797701162679, -8.735503020458074],
              [-38.666805986929923, -8.735505059531677],
              [-38.66681429792218, -8.735506994323963],
              [-38.666822632813627, -8.735508824526333],
              [-38.666830990274626, -8.735510549846483],
              [-38.666846266800086, -8.735513554051989],
              [-38.666861561944003, -8.735516462976245],
              [-38.666876875105984, -8.735519276505114],
              [-38.666892205684967, -8.735521994528133],
              [-38.666907553079163, -8.73552461693863],
              [-38.666922916686147, -8.735527143633638],
              [-38.666938295902867, -8.735529574513995],
              [-38.666953690125666, -8.735531909484257],
              [-38.666969098750243, -8.735534148452805],
              [-38.666984521171784, -8.735536291331734],
              [-38.666999956784927, -8.735538338036935],
              [-38.667015404983765, -8.735540288488094],
              [-38.667030865161919, -8.735542142608621],
              [-38.667046336712552, -8.735543900325716],
              [-38.667061819028355, -8.735545561570413],
              [-38.667077311501608, -8.73554712627751],
              [-38.667092813524171, -8.735548594385577],
              [-38.667108324487572, -8.735549965836981],
              [-38.667123843782939, -8.73555124057788],
              [-38.667139370801124, -8.735552418558257],
              [-38.667154904932644, -8.735553499731886],
              [-38.667170445567727, -8.735554484056275],
              [-38.667185992096385, -8.735555371492833],
              [-38.667201543908334, -8.73555616200669],
              [-38.667217100393181, -8.735556855566838],
              [-38.667232660940229, -8.735557452146059],
              [-38.667248224938746, -8.735557951720924],
              [-38.667263791777756, -8.735558354271816],
              [-38.667279360846244, -8.735558659782942],
              [-38.667294931533064, -8.735558868242338],
              [-38.667310503227057, -8.735558979641755],
              [-38.667326075316943, -8.735558993976891],
              [-38.667341647191513, -8.735558911247132],
              [-38.66735721823953, -8.735558731455765],
              [-38.667372787849757, -8.735558454609816],
              [-38.667388355411077, -8.735558080720121],
              [-38.667403920312402, -8.735557609801425],
              [-38.667419481942773, -8.735557041872182],
              [-38.667435039691355, -8.735556376954682],
              [-38.667450592947453, -8.735555615075027],
              [-38.667466141100583, -8.735554756263122],
              [-38.667481683540416, -8.735553800552672],
              [-38.66749721965688, -8.735552747981185],
              [-38.667512748840139, -8.735551598590005],
              [-38.667528270480624, -8.735550352424225],
              [-38.667543783969094, -8.735549009532773],
              [-38.667559288696573, -8.735547569968363],
              [-38.667574784054459, -8.735546033787447],
              [-38.667590269434541, -8.735544401050419],
              [-38.66760574422895, -8.735542671821309],
              [-38.667621207830273, -8.735540846167988],
              [-38.667636659631526, -8.735538924162094],
              [-38.667652099026185, -8.735536905879153],
              [-38.667667525408184, -8.735534791398329],
              [-38.667682938172028, -8.735532580802634],
              [-38.667698336712718, -8.735530274178855],
              [-38.667713720425816, -8.735527871617505],
              [-38.667729088707468, -8.735525373212917],
              [-38.667744440954415, -8.735522779063112],
              [-38.667759776564075, -8.735520089269976],
              [-38.667775094934449, -8.735517303939059],
              [-38.667790395464266, -8.735514423179685],
              [-38.667805677552948, -8.735511447104953],
              [-38.667820940600613, -8.735508375831673],
              [-38.667836184008145, -8.735505209480401],
              [-38.667851407177224, -8.73550194817542],
              [-38.667866609510284, -8.735498592044733],
              [-38.66788179041059, -8.73549514122012],
              [-38.667896949282245, -8.735491595836963],
              [-38.667912085530247, -8.73548795603452],
              [-38.667927198560449, -8.735484221955589],
              [-38.66794228777961, -8.735480393746775],
              [-38.667957352595444, -8.735476471558323],
              [-38.667972392416623, -8.735472455544221],
              [-38.667987406652799, -8.735468345862081],
              [-38.66800239471462, -8.735464142673198],
              [-38.668017356013763, -8.735459846142604],
              [-38.668032289962952, -8.73545545643891],
              [-38.66804719597598, -8.735450973734476],
              [-38.66806207346778, -8.735446398205212],
              [-38.66807692185435, -8.73544173003075],
              [-38.668091740552853, -8.735436969394287],
              [-38.668106528981617, -8.735432116482746],
              [-38.668121286560172, -8.735427171486585],
              [-38.668136012709226, -8.735422134599894],
              [-38.668150706850724, -8.735417006020404],
              [-38.668165368407941, -8.735411785949413],
              [-38.668179996805314, -8.735406474591818],
              [-38.668194591468669, -8.735401072156145],
              [-38.668209151825124, -8.735395578854428],
              [-38.668223677303139, -8.735389994902265],
              [-38.668238167332561, -8.735384320518872],
              [-38.668252621344628, -8.735378555926982],
              [-38.668267038771972, -8.735372701352848],
              [-38.668281419048654, -8.735366757026281],
              [-38.668295761610239, -8.735360723180644],
              [-38.668310065893721, -8.735354600052746],
              [-38.668324331337658, -8.735348387882967],
              [-38.668338557382278, -8.735342086914972],
              [-38.668346384193676, -8.735338471999762],
              [-38.668354116607176, -8.735334661297747],
              [-38.668361749675896, -8.735330657246882],
              [-38.668369278516536, -8.735326462408768],
              [-38.668376698312443, -8.735322079467077],
              [-38.668384004316756, -8.735317511225896],
              [-38.668391191855413, -8.735312760607716],
              [-38.668398256330129, -8.735307830651823],
              [-38.668405193221346, -8.735302724512195],
              [-38.668411998091152, -8.735297445455497],
              [-38.668418666586071, -8.73529199685909],
              [-38.668425194439891, -8.735286382208738],
              [-38.668431577476362, -8.735280605096431],
              [-38.668437811611916, -8.735274669218175],
              [-38.668443892858228, -8.735268578371425],
              [-38.668449817324735, -8.735262336452893],
              [-38.668455581221274, -8.735255947455899],
              [-38.668461180860326, -8.735249415467829],
              [-38.668466612659479, -8.735242744667543],
              [-38.668471873143751, -8.735235939322797],
              [-38.668476958947664, -8.735229003787355],
              [-38.668481866817586, -8.735221942498235],
              [-38.668486593613665, -8.735214759972971],
              [-38.668491136311907, -8.735207460806643],
              [-38.668495492006087, -8.735200049668965],
              [-38.668499657909649, -8.735192531301221],
              [-38.66850363135741, -8.735184910513386],
              [-38.668507409807376, -8.735177192180885],
              [-38.668510990842243, -8.735169381241562],
              [-38.668514372171039, -8.735161482692593],
              [-38.668517551630529, -8.735153501587053],
              [-38.668520527186686, -8.73514544303095],
              [-38.668523296935852, -8.735137312179802],
              [-38.668525859106069, -8.735129114235361],
              [-38.668528212058206, -8.735120854442322],
              [-38.668530354286951, -8.735112538084993],
              [-38.668532284421815, -8.735104170483744],
              [-38.668534001228004, -8.735095756991869],
              [-38.668535503607167, -8.735087302991946],
              [-38.668536790598182, -8.735078813892429],
              [-38.668537861377693, -8.735070295124325],
              [-38.668538715260695, -8.735061752137591],
              [-38.668539351700908, -8.735053190397581],
              [-38.668539770291169, -8.735044615381794],
              [-38.66853997076371, -8.735036032576112],
              [-38.668539952990308, -8.7350274474715],
              [-38.66853971698233, -8.735018865560287],
              [-38.668539262890782, -8.735010292332804],
              [-38.66853859100619, -8.735001733273853],
              [-38.668537701758396, -8.734993193859134],
              [-38.668536595716347, -8.734984679551779],
              [-38.668535273587629, -8.73497619579893],
              [-38.668533736218116, -8.734967748028019],
              [-38.668531984591326, -8.734959341643659],
              [-38.668530019827926, -8.734950982023825],
              [-38.668527843184904, -8.734942674516653],
              [-38.668525456054773, -8.73493442443695],
              [-38.668522859964739, -8.734926237062727],
              [-38.668520056575673, -8.73491811763186],
              [-38.668517047681071, -8.734910071338884],
              [-38.6685138352059, -8.734902103331425],
              [-38.668510421205418, -8.734894218707026],
              [-38.668506807863686, -8.734886422510005],
              [-38.668502997492446, -8.734878719727952],
              [-38.668498992529379, -8.734871115288799],
              [-38.668494795536702, -8.734863614057526],
              [-38.668490409199485, -8.734856220833093],
              [-38.66848583632391, -8.734848940345355],
              [-38.668481079835516, -8.734841777252026],
              [-38.668476142777301, -8.734834736135754],
              [-38.668471028307806, -8.734827821501128],
              [-38.668465739699016, -8.734821037771836],
              [-38.668460280334386, -8.734814389287783],
              [-38.668454653706561, -8.73480788030238],
              [-38.668448863415229, -8.734801514979802],
              [-38.668442913164775, -8.734795297392278],
              [-38.668436806761903, -8.734789231517514],
              [-38.668430548113221, -8.73478332123627],
              [-38.668424141222744, -8.73477757032961],
              [-38.668417590189314, -8.734771982476694],
              [-38.668410899203998, -8.734766561252423],
              [-38.668404072547403, -8.734761310125068],
              [-38.668397114586924, -8.73475623245402],
              [-38.668390029773946, -8.734751331487779],
              [-38.668382822641014, -8.734746610361702],
              [-38.668375497798955, -8.734742072096246],
              [-38.668368059933854, -8.734737719594735],
              [-38.668360513804153, -8.7347335556417],
              [-38.668352864237498, -8.734729582901037],
              [-38.668345116127767, -8.73472580391438],
              [-38.668337274431849, -8.734722221099293],
              [-38.668329344166501, -8.734718836747959],
              [-38.668321330405156, -8.734715653025489],
              [-38.668313238274656, -8.734712671968703],
              [-38.668305072952009, -8.734709895484732],
              [-38.668296839660982, -8.734707325349836],
              [-38.668288543668879, -8.734704963208294],
              [-38.66828019028312, -8.734702810571308],
              [-38.668271784847789, -8.734700868816025],
              [-38.668264475477848, -8.73469919293203],
              [-38.66825720490823, -8.734697357571399],
              [-38.668249976653669, -8.734695363621386],
              [-38.668242794208432, -8.734693212045892],
              [-38.66823566104464, -8.734690903885076],
              [-38.668228580610602, -8.73468844025469],
              [-38.668221556329129, -8.734685822345689],
              [-38.668214591595863, -8.73468305142366],
              [-38.668207689777702, -8.734680128828128],
              [-38.668200854211122, -8.734677055971879],
              [-38.668194088200522, -8.734673834340432],
              [-38.668187395016737, -8.734670465491147],
              [-38.668180777895387, -8.734666951052599],
              [-38.668174240035249, -8.73466329272377],
              [-38.668167784596896, -8.734659492273096],
              [-38.66816141470099, -8.734655551537831],
              [-38.668155133426843, -8.73465147242295],
              [-38.668148943810927, -8.734647256900422],
              [-38.668142848845442, -8.734642907008109],
              [-38.668136851476753, -8.734638424848807],
              [-38.668130954604131, -8.734633812589262],
              [-38.668125161078216, -8.734629072459121],
              [-38.668119473699683, -8.734624206749878],
              [-38.668113895217921, -8.734619217813693],
              [-38.668108428329681, -8.734614108062301],
              [-38.66810307567772, -8.734608879965867],
              [-38.668097839849629, -8.734603536051711],
              [-38.668092723376461, -8.734598078903201],
              [-38.668087728731642, -8.734592511158439],
              [-38.66808285832964, -8.734586835508916],
              [-38.66807811452491, -8.734581054698388],
              [-38.668073499610671, -8.734575171521415],
              [-38.668069015817849, -8.73456918882197],
              [-38.668064665313999, -8.734563109492239],
              [-38.668060450202212, -8.73455693647108],
              [-38.66805637252012, -8.73455067274265],
              [-38.668052434238973, -8.734544321334903],
              [-38.668048637262594, -8.734537885318238],
              [-38.668044983426476, -8.73453136780398],
              [-38.668041474496974, -8.734524771942745],
              [-38.668038112170336, -8.734518100923131],
              [-38.668034898071966, -8.734511357970014],
              [-38.668031833755613, -8.73450454634307],
              [-38.668028920702604, -8.734497669335125],
              [-38.668026160321148, -8.734490730270698],
              [-38.668023553945673, -8.734483732504206],
              [-38.668021102836128, -8.734476679418533],
              [-38.668018808177415, -8.734469574423199],
              [-38.668016671078782, -8.734462420952925],
              [-38.668014692573351, -8.734455222465824],
              [-38.668012873617563, -8.734447982441756],
              [-38.668011215090715, -8.734440704380704],
              [-38.668009717794547, -8.734433391800993],
              [-38.668008382452889, -8.734426048237605],
              [-38.668007209711234, -8.734418677240631],
              [-38.668006200136517, -8.734411282373259],
              [-38.668005354216746, -8.734403867210359],
              [-38.668004672360901, -8.7343964353365],
              [-38.668004154898526, -8.734388990344401],
              [-38.668003802079809, -8.734381535833064],
              [-38.668003614075275, -8.734374075406173],
              [-38.6680035909758, -8.734366612670186],
              [-38.668003732792542, -8.734359151232743],
              [-38.668004039456946, -8.734351694700841],
              [-38.668004510820737, -8.734344246679074],
              [-38.668005146656064, -8.734336810767932],
              [-38.668005946655512, -8.734329390562065],
              [-38.668006910432375, -8.734321989648537],
              [-38.668008037520721, -8.734314611605056],
              [-38.668009327375664, -8.734307259998319],
              [-38.668010779373688, -8.734299938382215],
              [-38.668012392812848, -8.734292650296078],
              [-38.668014166913188, -8.734285399263179],
              [-38.668016100817056, -8.73427818878873],
              [-38.668018193589546, -8.734271022358417],
              [-38.668020444219003, -8.734263903436583],
              [-38.668022851617401, -8.734256835464674],
              [-38.668025414620963, -8.734249821859445],
              [-38.668028131990688, -8.734242866011353],
              [-38.668031002412924, -8.734235971283045],
              [-38.668034024500081, -8.734229141007505],
              [-38.668037196791218, -8.734222378486585],
              [-38.668040517752772, -8.734215686989431],
              [-38.668043985779349, -8.734209069750781],
              [-38.668047599194416, -8.734202529969568],
              [-38.668051356251212, -8.734196070807194],
              [-38.668055255133481, -8.734189695386135],
              [-38.66805929395646, -8.734183406788373],
              [-38.668063470767677, -8.734177208053936],
              [-38.668067783547997, -8.734171102179351],
              [-38.668072230212573, -8.734165092116315],
              [-38.668076808611772, -8.734159180770233],
              [-38.668081516532332, -8.734153370998673],
              [-38.668086351698371, -8.734147665610239],
              [-38.668091311772471, -8.734142067362979],
              [-38.668096394356851, -8.73413657896319],
              [-38.668101596994511, -8.734131203064017],
              [-38.668106917170391, -8.734125942264267],
              [-38.668112352313599, -8.734120799106627],
              [-38.668137298540337, -8.734097783643238],
              [-38.668162320998441, -8.734074850240233],
              [-38.668187419415183, -8.734051999147562],
              [-38.66821259351704, -8.734029230614256],
              [-38.668237843029644, -8.734006544888448],
              [-38.668263167677807, -8.733983942217369],
              [-38.668288567185527, -8.733961422847315],
              [-38.668314041275991, -8.733938987023755],
              [-38.668339589671518, -8.733916634991182],
              [-38.668365212093747, -8.733894366993189],
              [-38.668390908263376, -8.733872183272466],
              [-38.668416677900353, -8.733850084070779],
              [-38.668442520723822, -8.733828069628904],
              [-38.668468436452152, -8.733806140186877],
              [-38.668494424802894, -8.733784295983599],
              [-38.668520485492806, -8.733762537257162],
              [-38.668546618237862, -8.733740864244686],
              [-38.668572822753248, -8.733719277182368],
              [-38.668599098753376, -8.733697776305487],
              [-38.668625445951889, -8.733676361848339],
              [-38.668651864061609, -8.733655034044331],
              [-38.668678352794636, -8.73363379312589],
              [-38.668704911862278, -8.733612639324468],
              [-38.668731540975102, -8.733591572870628],
              [-38.668758239842859, -8.73357059399396],
              [-38.668785008174581, -8.733549702923089],
              [-38.668811845678547, -8.733528899885712],
              [-38.668838752062236, -8.733508185108494],
              [-38.668865727032426, -8.733487558817224],
              [-38.66889277029513, -8.733467021236688],
              [-38.668919881555624, -8.733446572590683],
              [-38.668947060518434, -8.733426213102096],
              [-38.668974306887343, -8.733405942992775],
              [-38.669001620365407, -8.733385762483664],
              [-38.669029000654945, -8.733365671794665],
              [-38.669056447457585, -8.733345671144733],
              [-38.669083960474154, -8.733325760751853],
              [-38.669111539404824, -8.733305940832985],
              [-38.669139183949049, -8.733286211604145],
              [-38.66916689380551, -8.733266573280359],
              [-38.669194668672233, -8.733247026075643],
              [-38.669222508246513, -8.733227570203024],
              [-38.66925041222494, -8.733208205874524],
              [-38.669278380303389, -8.733188933301186],
              [-38.669306412177079, -8.733169752693048],
              [-38.669334507540498, -8.733150664259117],
              [-38.669362666087451, -8.733131668207415],
              [-38.669390887511057, -8.733112764745028],
              [-38.669419171503741, -8.733093954077921],
              [-38.669447517757249, -8.733075236411119],
              [-38.669475925962672, -8.733056611948571],
              [-38.669504395810385, -8.733038080893277],
              [-38.669532926990108, -8.733019643447195],
              [-38.66956151919095, -8.733001299811251],
              [-38.669590172101231, -8.732983050185338],
              [-38.669618885408724, -8.732964894768386],
              [-38.669647658800514, -8.732946833758222],
              [-38.669676491962974, -8.732928867351687],
              [-38.669705384581903, -8.732910995744575],
              [-38.669734336342401, -8.732893219131677],
              [-38.669763346928946, -8.732875537706692],
              [-38.669792416025395, -8.732857951662332],
              [-38.669821543314903, -8.73284046119022],
              [-38.669850728480071, -8.732823066481004],
              [-38.669879971202811, -8.732805767724246],
              [-38.669909271164414, -8.732788565108493],
              [-38.669938628045585, -8.732771458821155],
              [-38.669968041526374, -8.732754449048695],
              [-38.669997511286226, -8.732737535976536],
              [-38.670027037003969, -8.732720719788921],
              [-38.670056618357819, -8.73270400066918],
              [-38.670086255025389, -8.732687378799465],
              [-38.670115946683701, -8.732670854360936],
              [-38.670145693009154, -8.732654427533719],
              [-38.670175493677576, -8.732638098496798],
              [-38.670205348364199, -8.732621867428112],
              [-38.670235256743638, -8.732605734504608],
              [-38.670265218489959, -8.732589699902041],
              [-38.670295233276626, -8.732573763795211],
              [-38.670325300776518, -8.732557926357744],
              [-38.670355420661984, -8.732542187762258],
              [-38.670385592604745, -8.732526548180305],
              [-38.670415816275984, -8.732511007782307],
              [-38.670446091346328, -8.732495566737569],
              [-38.670476417485816, -8.732480225214438],
              [-38.670506794363952, -8.732464983380106],
              [-38.670537221649695, -8.732449841400625],
              [-38.670567699011421, -8.732434799441055],
              [-38.670598226117001, -8.732419857665331],
              [-38.67062880263375, -8.732405016236275],
              [-38.670659428228404, -8.732390275315639],
              [-38.670690102567228, -8.732375635064054],
              [-38.670720825315925, -8.732361095641114],
              [-38.670751596139652, -8.732346657205206],
              [-38.670782414703112, -8.732332319913706],
              [-38.67081328067038, -8.7323180839229],
              [-38.670844193705108, -8.732303949387894],
              [-38.670875153470377, -8.732289916462715],
              [-38.670906159628807, -8.732275985300319],
              [-38.670928993488118, -8.732265737876858],
              [-38.670951802242826, -8.732255435261605],
              [-38.670974585758159, -8.732245077515369],
              [-38.670997343899501, -8.732234664699394],
              [-38.671020076532358, -8.732224196875206],
              [-38.671042783522395, -8.732213674104633],
              [-38.671065464735499, -8.732203096449872],
              [-38.671088120037602, -8.732192463973409],
              [-38.67111074929484, -8.732181776738068],
              [-38.671133352373516, -8.732171034807026],
              [-38.671155929140077, -8.732160238243711],
              [-38.671178479461119, -8.732149387111981],
              [-38.671201003203365, -8.732138481475893],
              [-38.671223500233779, -8.732127521399921],
              [-38.671245970419399, -8.732116506948815],
              [-38.671268413627473, -8.732105438187649],
              [-38.671290829725372, -8.732094315181866],
              [-38.67131321858065, -8.73208313799713],
              [-38.671335580061012, -8.732071906699508],
              [-38.671357914034346, -8.732060621355407],
              [-38.671380220368668, -8.732049282031429],
              [-38.671402498932153, -8.732037888794647],
              [-38.671424749593228, -8.732026441712348],
              [-38.671446972220366, -8.732014940852173],
              [-38.671469166682279, -8.732003386282095],
              [-38.671491332847808, -8.731991778070373],
              [-38.671513470585992, -8.731980116285635],
              [-38.671535579766029, -8.731968400996703],
              [-38.671557660257271, -8.731956632272869],
              [-38.671579711929262, -8.731944810183668],
              [-38.671601734651674, -8.731932934798939],
              [-38.671623728294428, -8.731921006188832],
              [-38.671645692727537, -8.731909024423897],
              [-38.671667627821208, -8.731896989574853],
              [-38.671689533445864, -8.731884901712862],
              [-38.671711409472046, -8.731872760909367],
              [-38.671733255770505, -8.731860567236032],
              [-38.671755072212164, -8.731848320764994],
              [-38.671776858668096, -8.731836021568522],
              [-38.671798615009592, -8.731823669719383],
              [-38.671820341108088, -8.731811265290494],
              [-38.671842036835208, -8.731798808355185],
              [-38.671863702062751, -8.731786298987052],
              [-38.671885336662719, -8.731773737260001],
              [-38.671906940507284, -8.731761123248274],
              [-38.671928513468764, -8.731748457026409],
              [-38.671950055419721, -8.731735738669231],
              [-38.671971566232862, -8.731722968251898],
              [-38.671993045781079, -8.731710145849885],
              [-38.672014493937453, -8.731697271538932],
              [-38.672035910575261, -8.731684345395108],
              [-38.67205729556796, -8.731671367494791],
              [-38.672078648789196, -8.731658337914695],
              [-38.672099970112782, -8.731645256731788],
              [-38.672121259412741, -8.731632124023346],
              [-38.672142516563277, -8.731618939867008],
              [-38.672163741438816, -8.731605704340636],
              [-38.672184933913918, -8.731592417522492],
              [-38.672206093863387, -8.731579079491029],
              [-38.67222722116216, -8.731565690325075],
              [-38.67224831568543, -8.731552250103752],
              [-38.672269377308545, -8.731538758906469],
              [-38.672290405907063, -8.731525216812978],
              [-38.672311401356737, -8.731511623903257],
              [-38.672332363533506, -8.73149798025765],
              [-38.672353292313502, -8.731484285956762],
              [-38.672374187573077, -8.731470541081464],
              [-38.672395049188758, -8.731456745713066],
              [-38.672415877037288, -8.73144289993299],
              [-38.672436670995602, -8.731429003823102],
              [-38.672457430940838, -8.731415057465517],
              [-38.672478156750316, -8.731401060942591],
              [-38.672498848301593, -8.731387014337095],
              [-38.672519505472401, -8.731372917731949],
              [-38.672540128140682, -8.731358771210527],
              [-38.672560716184591, -8.73134457485637],
              [-38.672581269482464, -8.731330328753371],
              [-38.672601787912882, -8.731316032985699],
              [-38.672622271354584, -8.731301687637865],
              [-38.672642719686564, -8.731287292794564],
              [-38.672663132787974, -8.731272848540899],
              [-38.672683510538235, -8.731258354962238],
              [-38.672703852816888, -8.731243812144188],
              [-38.672724159503808, -8.731229220172697],
              [-38.672744430478964, -8.731214579133949],
              [-38.672764665622587, -8.731199889114505],
              [-38.672784864815121, -8.731185150201132],
              [-38.672805027937216, -8.731170362480917],
              [-38.672825154869727, -8.731155526041256],
              [-38.672845245493747, -8.731140640969812],
              [-38.672865299690571, -8.731125707354552],
              [-38.672885317341667, -8.731110725283674],
              [-38.672905298328821, -8.731095694845722],
              [-38.672925242533914, -8.731080616129525],
              [-38.672945149839137, -8.731065489224155],
              [-38.672965020126846, -8.731050314219013],
              [-38.672984853279658, -8.731035091203776],
              [-38.673004649180371, -8.731019820268358],
              [-38.673024407711615, -8.731004501504302],
              [-38.673032629630086, -8.730998073575424],
              [-38.673040814720551, -8.730991599289259],
              [-38.673048962718951, -8.730985078854637],
              [-38.673057073362443, -8.730978512481926],
              [-38.673065146389384, -8.730971900382954],
              [-38.673073181539344, -8.730965242771022],
              [-38.673081178553112, -8.730958539860913],
              [-38.673089137172688, -8.730951791868815],
              [-38.673097057141383, -8.730944999012475],
              [-38.67310493820365, -8.730938161510998],
              [-38.673112780105278, -8.730931279584969],
              [-38.673120582593285, -8.730924353456382],
              [-38.673128345415982, -8.730917383348677],
              [-38.67313606832294, -8.730910369486695],
              [-38.673143751065005, -8.730903312096718],
              [-38.673151393394349, -8.73089621140641],
              [-38.673158995064448, -8.730889067644869],
              [-38.673166555830058, -8.7308818810425],
              [-38.673174075447271, -8.730874651831174],
              [-38.673181553673544, -8.730867380244064],
              [-38.673188990267597, -8.730860066515783],
              [-38.673196384989552, -8.730852710882232],
              [-38.673203737600858, -8.730845313580749],
              [-38.673211047864328, -8.730837874849925],
              [-38.673218315544119, -8.730830394929765],
              [-38.673225540405795, -8.730822874061545],
              [-38.673232722216284, -8.730815312487882],
              [-38.673239860743912, -8.73080771045273],
              [-38.673246955758408, -8.730800068201297],
              [-38.673254007030863, -8.730792385980166],
              [-38.673261014333825, -8.730784664037101],
              [-38.673267977441242, -8.730776902621262],
              [-38.673274896128483, -8.730769101982963],
              [-38.673281770172366, -8.730761262373925],
              [-38.673288599351146, -8.730753384047011],
              [-38.673295383444504, -8.730745467256403],
              [-38.673302122233601, -8.73073751225745],
              [-38.673308815501038, -8.730729519306797],
              [-38.673315463030924, -8.730721488662283],
              [-38.673322064608769, -8.730713420583006],
              [-38.673328620021671, -8.730705315329217],
              [-38.673335129058096, -8.730697173162367],
              [-38.67334159150812, -8.730688994345156],
              [-38.673348007163227, -8.730680779141389],
              [-38.673354375816494, -8.730672527816125],
              [-38.67336069726246, -8.730664240635491],
              [-38.673366971297177, -8.730655917866889],
              [-38.673373197718291, -8.730647559778783],
              [-38.673379376324895, -8.730639166640797],
              [-38.673385506917725, -8.730630738723683],
              [-38.673391589298987, -8.730622276299346],
              [-38.673397623272479, -8.73061377964075],
              [-38.673403608643532, -8.730605249022018],
              [-38.673409545219087, -8.730596684718327],
              [-38.673415432807616, -8.73058808700597],
              [-38.673421271219198, -8.730579456162282],
              [-38.67342706026551, -8.730570792465718],
              [-38.673432799759787, -8.730562096195774],
              [-38.673438489516876, -8.730553367632965],
              [-38.673444129353243, -8.730544607058874],
              [-38.673449719086932, -8.73053581475609],
              [-38.673455258537643, -8.730526991008277],
              [-38.67346074752669, -8.730518136100093],
              [-38.67346618587699, -8.73050925031716],
              [-38.673471573413089, -8.73050033394615],
              [-38.673476909961224, -8.730491387274705],
              [-38.673482195349244, -8.730482410591415],
              [-38.673487429406613, -8.73047340418589],
              [-38.673492611964505, -8.73046436834867],
              [-38.673497742855758, -8.730455303371206],
              [-38.673502821914845, -8.730446209545978],
              [-38.673507848977906, -8.730437087166314],
              [-38.673512823882795, -8.730427936526544],
              [-38.673517746468974, -8.730418757921816],
              [-38.673522616577742, -8.730409551648245],
              [-38.673527434051927, -8.730400318002825],
              [-38.673532198736119, -8.730391057283423],
              [-38.673536910476656, -8.73038176978876],
              [-38.673541569121518, -8.730372455818497],
              [-38.673546174520439, -8.730363115673081],
              [-38.673550726524851, -8.730353749653785],
              [-38.673555224987886, -8.730344358062798],
              [-38.673559669764465, -8.730334941203033],
              [-38.673564060711193, -8.730325499378345],
              [-38.673568397686417, -8.730316032893249],
              [-38.673572680550244, -8.730306542053178],
              [-38.673576909164503, -8.730297027164255],
              [-38.673581083392811, -8.730287488533495],
              [-38.67358520310048, -8.730277926468577],
              [-38.673589268154629, -8.730268341277931],
              [-38.673593278424129, -8.730258733270805],
              [-38.673597233779631, -8.730249102757151],
              [-38.673601134093495, -8.730239450047621],
              [-38.673604979239961, -8.730229775453644],
              [-38.67360876909494, -8.730220079287264],
              [-38.673612503536212, -8.73021036186131],
              [-38.673616182443304, -8.730200623489258],
              [-38.67361980569752, -8.730190864485239],
              [-38.673623373182011, -8.730181085164139],
              [-38.673627649911161, -8.730169382048391],
              [-38.673632006509536, -8.730157708130244],
              [-38.673636442775745, -8.730146063949201],
              [-38.67364095850477, -8.730134450043492],
              [-38.673645553487887, -8.730122866949849],
              [-38.673650227512724, -8.730111315203651],
              [-38.673654980363231, -8.730099795338759],
              [-38.673659811819753, -8.730088307887646],
              [-38.673664721658945, -8.730076853381206],
              [-38.673669709653936, -8.730065432348889],
              [-38.673674775574121, -8.730054045318496],
              [-38.673679919185396, -8.730042692816388],
              [-38.673685140250008, -8.730031375367179],
              [-38.673690438526648, -8.730020093494025],
              [-38.673695813770422, -8.730008847718288],
              [-38.673701265732902, -8.729997638559768],
              [-38.673706794162094, -8.729986466536513],
              [-38.673712398802493, -8.729975332164855],
              [-38.673718079395016, -8.729964235959432],
              [-38.673723835677158, -8.729953178433066],
              [-38.673729667382837, -8.72994216009687],
              [-38.673735574242521, -8.729931181460055],
              [-38.673741555983206, -8.729920243030032],
              [-38.673747612328413, -8.729909345312336],
              [-38.67375374299823, -8.72989848881069],
              [-38.673759947709293, -8.729887674026807],
              [-38.673766226174834, -8.729876901460564],
              [-38.673772578104661, -8.729866171609865],
              [-38.67377900320519, -8.729855484970562],
              [-38.673785501179481, -8.729844842036655],
              [-38.673792071727178, -8.729834243299964],
              [-38.67379871454461, -8.729823689250376],
              [-38.67380542932473, -8.729813180375654],
              [-38.673812215757238, -8.729802717161565],
              [-38.673819073528421, -8.729792300091647],
              [-38.673826002321327, -8.729781929647372],
              [-38.673833001815744, -8.729771606308045],
              [-38.673840071688126, -8.729761330550762],
              [-38.673847211611744, -8.729751102850507],
              [-38.673854421256578, -8.729740923679898],
              [-38.6738617002894, -8.729730793509493],
              [-38.673869048373817, -8.72972071280746],
              [-38.673876465170174, -8.729710682039665],
              [-38.673883950335686, -8.729700701669755],
              [-38.673891503524388, -8.729690772159],
              [-38.673899124387212, -8.729680893966318],
              [-38.673906812571893, -8.729671067548246],
              [-38.673914567723124, -8.729661293358976],
              [-38.673922389482456, -8.729651571850203],
              [-38.673930277488367, -8.729641903471288],
              [-38.673938231376283, -8.729632288669061],
              [-38.67394625077862, -8.729622727887886],
              [-38.673954335324702, -8.729613221569677],
              [-38.673962484640882, -8.729603770153783],
              [-38.673970698350502, -8.729594374077033],
              [-38.67397897607394, -8.729585033773677],
              [-38.673987317428626, -8.72957574967543],
              [-38.673995722028998, -8.729566522211371],
              [-38.674004189486631, -8.729557351808003],
              [-38.674012719410179, -8.729548238889125],
              [-38.67402131140539, -8.729539183875948],
              [-38.67402996507515, -8.729530187186954],
              [-38.674038680019507, -8.729521249237941],
              [-38.674047455835662, -8.729512370442073],
              [-38.674056292118017, -8.729503551209655],
              [-38.674065188458179, -8.729494791948277],
              [-38.674074144444951, -8.729486093062826],
              [-38.67408315966442, -8.729477454955308],
              [-38.674092233699909, -8.729468878024969],
              [-38.674101366132014, -8.729460362668236],
              [-38.674110556538679, -8.729451909278634],
              [-38.674119804495128, -8.729443518246933],
              [-38.674129109573919, -8.729435189960888],
              [-38.674138471345024, -8.72942692480547],
              [-38.674147889375703, -8.729418723162588],
              [-38.674157363230705, -8.729410585411401],
              [-38.674166892472158, -8.729402511927971],
              [-38.674176476659639, -8.729394503085416],
              [-38.674186115350174, -8.729386559253951],
              [-38.674195808098268, -8.72937868080068],
              [-38.674205554455988, -8.729370868089738],
              [-38.674215353972812, -8.729363121482228],
              [-38.674225206195842, -8.729355441336162],
              [-38.674235110669777, -8.729347828006524],
              [-38.67424506693677, -8.729340281845159],
              [-38.674255074536696, -8.729332803200871],
              [-38.674265133007026, -8.729325392419277],
              [-38.674275241882867, -8.729318049842894],
              [-38.674285400697009, -8.729310775811108],
              [-38.674295608979925, -8.72930357066009],
              [-38.674305866259814, -8.729296434722817],
              [-38.674316172062589, -8.72928936832915],
              [-38.674326525911951, -8.729282371805652],
              [-38.67433692732935, -8.729275445475725],
              [-38.674347375834046, -8.729268589659432],
              [-38.674357870943155, -8.729261804673698],
              [-38.674368412171589, -8.72925509083205],
              [-38.67437899903215, -8.729248448444832],
              [-38.674389631035389, -8.729241877819401],
              [-38.674399962745326, -8.72923557504345],
              [-38.674410324699657, -8.729229321608319],
              [-38.674420716660343, -8.729223117657625],
              [-38.674431138388648, -8.729216963333965],
              [-38.674441589645163, -8.729210858778664],
              [-38.67445207018978, -8.729204804131998],
              [-38.674462579781732, -8.72919879953302],
              [-38.674473118179584, -8.729192845119668],
              [-38.674483685141254, -8.729186941028791],
              [-38.674494280423978, -8.729181087395977],
              [-38.674504903784339, -8.729175284355703],
              [-38.674515554978306, -8.729169532041301],
              [-38.674526233761199, -8.729163830584861],
              [-38.674536939887666, -8.729158180117448],
              [-38.674547673111789, -8.729152580768814],
              [-38.674558433186981, -8.7291470326676],
              [-38.674569219866044, -8.729141535941221],
              [-38.67458003290119, -8.729136090716034],
              [-38.674590872044021, -8.729130697117096],
              [-38.674601737045499, -8.72912535526828],
              [-38.67461262765606, -8.729120065292339],
              [-38.674623543625508, -8.7291148273108],
              [-38.674634484703049, -8.729109641443978],
              [-38.674645450637357, -8.729104507811016],
              [-38.674656441176509, -8.729099426529839],
              [-38.674667456068029, -8.729094397717175],
              [-38.674678495058878, -8.729089421488545],
              [-38.674689557895441, -8.729084497958288],
              [-38.674700644323579, -8.729079627239518],
              [-38.674711754088619, -8.729074809444091],
              [-38.674722886935321, -8.729070044682709],
              [-38.674734042607945, -8.729065333064847],
              [-38.674745220850205, -8.729060674698701],
              [-38.67475642140532, -8.729056069691335],
              [-38.674767644015972, -8.729051518148497],
              [-38.674778888424335, -8.729047020174757],
              [-38.674790154372118, -8.729042575873475],
              [-38.674801441600501, -8.729038185346692],
              [-38.674812749850183, -8.729033848695341],
              [-38.674824078861391, -8.729029566018999],
              [-38.674835428373854, -8.729025337416084],
              [-38.674846798126843, -8.729021162983727],
              [-38.674858187859179, -8.729017042817794],
              [-38.674869597309197, -8.72901297701298],
              [-38.67488102621477, -8.729008965662667],
              [-38.674892474313381, -8.729005008858971],
              [-38.674903941342045, -8.729001106692861],
              [-38.67491542703727, -8.728997259253946],
              [-38.674926931135268, -8.728993466630596],
              [-38.674938453371716, -8.728989728909976],
              [-38.674949993481917, -8.72898604617793],
              [-38.674961551200788, -8.72898241851906],
              [-38.674973126262806, -8.728978846016702],
              [-38.674984718402051, -8.728975328752911],
              [-38.67499632735224, -8.72897186680853],
              [-38.675007952846656, -8.728968460263051],
              [-38.675019594618249, -8.728965109194755],
              [-38.67503125239957, -8.72896181368063],
              [-38.675042925922817, -8.728958573796355],
              [-38.675054614919802, -8.728955389616374],
              [-38.675066319122003, -8.728952261213836],
              [-38.675078038260551, -8.728949188660591],
              [-38.675089772066222, -8.728946172027252],
              [-38.67510152026945, -8.728943211383132],
              [-38.675113282600364, -8.728940306796201],
              [-38.67512505878873, -8.728937458333217],
              [-38.67513684856403, -8.728934666059612],
              [-38.675148651655419, -8.728931930039533],
              [-38.675160467791763, -8.728929250335822],
              [-38.675172296701575, -8.728926627010049],
              [-38.675184138113146, -8.728924060122456],
              [-38.675195991754443, -8.728921549732053],
              [-38.675207857353143, -8.72891909589649],
              [-38.67521973463667, -8.728916698672148],
              [-38.675231623332181, -8.728914358114057],
              [-38.675243523166529, -8.728912074276014],
              [-38.675255433866369, -8.728909847210504],
              [-38.675267355158084, -8.728907676968664],
              [-38.675279286767797, -8.728905563600369],
              [-38.675291228421401, -8.728903507154152],
              [-38.675303179844583, -8.728901507677259],
              [-38.67531514076277, -8.728899565215631],
              [-38.675327110901193, -8.728897679813885],
              [-38.675339089984881, -8.728895851515315],
              [-38.675351077738618, -8.728894080361957],
              [-38.67536307388702, -8.728892366394486],
              [-38.675375078154509, -8.728890709652283],
              [-38.675387090265332, -8.7288891101734],
              [-38.675399109943498, -8.728887567994594],
              [-38.675411136912913, -8.728886083151238],
              [-38.675423170897275, -8.728884655677522],
              [-38.675435211620119, -8.728883285606196],
              [-38.675447258804887, -8.728881972968741],
              [-38.675459312174752, -8.728880717795311],
              [-38.675471371452872, -8.72887952011477],
              [-38.675483436362178, -8.728878379954562],
              [-38.675495506625538, -8.728877297340956],
              [-38.675507581965633, -8.728876272298782],
              [-38.675519662105216, -8.728875304850938],
              [-38.675526347965139, -8.728874815900699],
              [-38.675533038021626, -8.728874387522648],
              [-38.675539731720285, -8.728874019752293],
              [-38.675546428506465, -8.728873712620128],
              [-38.675553127825246, -8.728873466151578],
              [-38.675559829121482, -8.728873280367081],
              [-38.67556653183987, -8.728873155282029],
              [-38.675573235424999, -8.728873090906783],
              [-38.675579939321374, -8.728873087246685],
              [-38.675586642973485, -8.72887314430203],
              [-38.675593345825838, -8.728873262068113],
              [-38.675600047323002, -8.728873440535144],
              [-38.675606746909658, -8.728873679688338],
              [-38.675613444030638, -8.728873979507899],
              [-38.675620138131016, -8.728874339968977],
              [-38.675626828656078, -8.728874761041698],
              [-38.675633515051395, -8.728875242691162],
              [-38.675640196762934, -8.728875784877459],
              [-38.675646873236992, -8.728876387555696],
              [-38.67565354392034, -8.728877050675898],
              [-38.675660208260211, -8.728877774183113],
              [-38.675666865704365, -8.728878558017421],
              [-38.675673515701156, -8.728879402113813],
              [-38.675680157699496, -8.728880306402392],
              [-38.675686791149033, -8.728881270808197],
              [-38.675693415500085, -8.72888229525133],
              [-38.675700030203721, -8.728883379646904],
              [-38.675706634711815, -8.728884523905048],
              [-38.675713228477065, -8.728885727930946],
              [-38.67571981095314, -8.728886991624828],
              [-38.675726381594536, -8.728888314881978],
              [-38.6757329398568, -8.728889697592772],
              [-38.675739485196473, -8.72889113964259],
              [-38.675746017071177, -8.728892640911967],
              [-38.675752534939676, -8.728894201276482],
              [-38.675759038261845, -8.728895820606871],
              [-38.675765526498807, -8.728897498768902],
              [-38.675771999112904, -8.728899235623537],
              [-38.675778455567787, -8.728901031026838],
              [-38.675784895328455, -8.728902884830049],
              [-38.675791317861247, -8.728904796879554],
              [-38.675797722634002, -8.728906767016895],
              [-38.675804109115973, -8.72890879507886],
              [-38.675810476777954, -8.728910880897343],
              [-38.675816825092276, -8.728913024299519],
              [-38.67582315353291, -8.728915225107816],
              [-38.675829461575439, -8.728917483139799],
              [-38.675835748697132, -8.728919798208375],
              [-38.675842014377054, -8.728922170121756],
              [-38.675848258095968, -8.72892459868334],
              [-38.675854479336493, -8.728927083691902],
              [-38.675860677583117, -8.728929624941536],
              [-38.67586685232223, -8.728932222221651],
              [-38.675873003042156, -8.728934875317014],
              [-38.675879129233209, -8.728937584007831],
              [-38.675885230387756, -8.728940348069584],
              [-38.675891306000217, -8.72894316727325],
              [-38.675897355567152, -8.72894604138521],
              [-38.675903378587257, -8.728948970167314],
              [-38.675909374561435, -8.728951953376882],
              [-38.675915342992845, -8.728954990766685],
              [-38.675921283386892, -8.728958082085025],
              [-38.675927195251333, -8.728961227075812],
              [-38.675933078096307, -8.72896442547837],
              [-38.67593893143431, -8.728967677027649],
              [-38.675944754780303, -8.728970981454266],
              [-38.675950547651752, -8.728974338484369],
              [-38.675956309568626, -8.728977747839798],
              [-38.675962040053449, -8.728981209238015],
              [-38.675967738631414, -8.728984722392198],
              [-38.675973404830266, -8.728988287011246],
              [-38.675979038180479, -8.72899190279978],
              [-38.675984638215255, -8.728995569458176],
              [-38.675990204470551, -8.728999286682559],
              [-38.675995736485106, -8.729003054164943],
              [-38.676001233800548, -8.729006871593137],
              [-38.676006695961298, -8.729010738650793],
              [-38.676012122514763, -8.729014655017467],
              [-38.676017513011267, -8.729018620368659],
              [-38.676022867004114, -8.729022634375781],
              [-38.67602818404967, -8.729026696706196],
              [-38.676033463707306, -8.729030807023248],
              [-38.676038705539554, -8.729034964986429],
              [-38.676043909112039, -8.729039170251067],
              [-38.676049073993575, -8.729043422468822],
              [-38.676054199756166, -8.729047721287241],
              [-38.676059285975057, -8.729052066350144],
              [-38.676064332228798, -8.729056457297469],
              [-38.676069338099211, -8.729060893765384],
              [-38.676074303171518, -8.729065375386224],
              [-38.676079227034244, -8.729069901788638],
              [-38.676084109279415, -8.729074472597549],
              [-38.676088949502436, -8.729079087434227],
              [-38.676093747302225, -8.729083745916206],
              [-38.676098502281228, -8.729088447657505],
              [-38.676103214045412, -8.7290931922685],
              [-38.676107882204334, -8.72909797935603],
              [-38.676112506371183, -8.729102808523463],
              [-38.676117086162733, -8.729107679370541],
              [-38.676117284304866, -8.729107892090861],
              [-38.676117482393039, -8.729108104860918],
              [-38.676117680427218, -8.72910831768071],
              [-38.676117878407403, -8.729108530550247],
              [-38.676118076333587, -8.729108743469467],
              [-38.676118274205741, -8.729108956438417],
              [-38.676118472023873, -8.729109169457013],
              [-38.676118669787961, -8.729109382525284],
              [-38.676118867497976, -8.729109595643209],
              [-38.676119065153934, -8.729109808810772],
              [-38.676119262755812, -8.729110022027974],
              [-38.676119460303589, -8.729110235294799],
              [-38.676119657797258, -8.729110448611188],
              [-38.676119855236813, -8.729110661977206],
              [-38.676120052622224, -8.729110875392774],
              [-38.676120249953492, -8.729111088857909],
              [-38.676120447230602, -8.729111302372566],
              [-38.67612064445354, -8.729111515936797],
              [-38.676120841622293, -8.729111729550526],
              [-38.676121038736866, -8.729111943213745],
              [-38.676121235797211, -8.729112156926464],
              [-38.676121432803328, -8.729112370688672],
              [-38.676121629755237, -8.729112584500328],
              [-38.676121826652889, -8.729112798361438],
              [-38.676122023496283, -8.729113012271986],
              [-38.6761222202854, -8.729113226231968],
              [-38.676122417020238, -8.729113440241335],
              [-38.676122613700777, -8.729113654300104],
              [-38.676122810327016, -8.729113868408295],
              [-38.676123006898919, -8.729114082565815],
              [-38.676123203416495, -8.7291142967727],
              [-38.676123399879728, -8.729114511028929],
              [-38.676123596288598, -8.729114725334485],
              [-38.676123792643089, -8.729114939689362],
              [-38.676123988943196, -8.729115154093519],
              [-38.676124185188925, -8.729115368546999],
              [-38.676124381380227, -8.729115583049708],
              [-38.676124577517122, -8.729115797601702],
              [-38.676124773599575, -8.729116012202912],
              [-38.676124969627587, -8.729116226853375],
              [-38.676125165601135, -8.729116441553066],
              [-38.676125361520214, -8.729116656301962],
              [-38.676125557384793, -8.729116871100036],
              [-38.676125753194889, -8.729117085947278],
              [-38.676125948950478, -8.729117300843699],
              [-38.676126144651548, -8.729117515789291],
              [-38.67612634029809, -8.729117730783999],
              [-38.676126535890063, -8.729117945827818],
              [-38.67612673142748, -8.729118160920752],
              [-38.676126926910349, -8.729118376062768],
              [-38.67612712233862, -8.729118591253901],
              [-38.676127317712293, -8.729118806494066],
              [-38.676127513031375, -8.729119021783307],
              [-38.676127708295809, -8.729119237121582],
              [-38.676127903505623, -8.729119452508874],
              [-38.67612809866079, -8.729119667945209],
              [-38.676128293761309, -8.729119883430526],
              [-38.67612848880713, -8.729120098964842],
              [-38.676128683798275, -8.729120314548101],
              [-38.676128878734751, -8.729120530180344],
              [-38.676129073616508, -8.729120745861533],
              [-38.676129268443546, -8.729120961591649],
              [-38.67612946321583, -8.729121177370663],
              [-38.67612965793338, -8.729121393198588],
              [-38.676129852596176, -8.72912160907541],
              [-38.676130047204204, -8.729121825001096],
              [-38.676130241757427, -8.729122040975675],
              [-38.676130436255882, -8.729122256999068],
              [-38.676130630699539, -8.729122473071319],
              [-38.67613082508835, -8.729122689192373],
              [-38.676131019422328, -8.729122905362216],
              [-38.676131213701481, -8.729123121580885],
              [-38.676131407925759, -8.72912333784832],
              [-38.676131602095197, -8.729123554164516],
              [-38.676131796209717, -8.729123770529471],
              [-38.676131990269369, -8.729123986943154],
              [-38.676132184274095, -8.729124203405579],
              [-38.676132378223919, -8.729124419916719],
              [-38.67613257211881, -8.729124636476534],
              [-38.676132765958748, -8.72912485308504],
              [-38.676132959743732, -8.729125069742208],
              [-38.676133153473749, -8.729125286448038],
              [-38.676133347148806, -8.729125503202511],
              [-38.676133540768845, -8.729125720005605],
              [-38.676133734333881, -8.729125936857313],
              [-38.6761339278439, -8.729126153757614],
              [-38.676134121298901, -8.729126370706501],
              [-38.676134314698857, -8.729126587703968],
              [-38.676134508043752, -8.729126804749997],
              [-38.676134701333574, -8.729127021844549],
              [-38.676134894568328, -8.729127238987658],
              [-38.676135087747987, -8.729127456179274],
              [-38.676135280872543, -8.729127673419406],
              [-38.676135473941997, -8.729127890708002],
              [-38.676135666956306, -8.729128108045096],
              [-38.676135859915469, -8.729128325430644],
              [-38.676136052819494, -8.729128542864636],
              [-38.676136245668353, -8.729128760347049],
              [-38.676136438462017, -8.729128977879471],
              [-38.676788221699347, -8.72882220457978],
              [-38.676817250005477, -8.728790571384062],
              [-38.677148116169015, -8.728431852207219],
              [-38.677262872585707, -8.728296996624048],
              [-38.677476386379162, -8.728131109728373],
              [-38.67754197784533, -8.728080241436157],
              [-38.677725238958935, -8.7278894546029],
              [-38.677873552459644, -8.727741964141879],
              [-38.678083048019509, -8.727490416171227],
              [-38.678274945217616, -8.727299636371324],
              [-38.678388505770236, -8.727074056829457],
              [-38.678493353492854, -8.726831103232231],
              [-38.67878747799206, -8.726590299743936],
              [-38.679040504313512, -8.726356064202921],
              [-38.679284661894108, -8.726286894478205],
              [-38.67942413549337, -8.72626964565678],
              [-38.679572351428369, -8.726235037527095],
              [-38.679720574739292, -8.726191746026444],
              [-38.679851419063191, -8.726165806446655],
              [-38.680034545063059, -8.726131227574522],
              [-38.680394231725423, -8.725978215735548],
              [-38.68058319283665, -8.72560291167961],
              [-38.680812487604463, -8.725049723820639],
              [-38.680943688234599, -8.724496453362251],
              [-38.681270743753949, -8.724334006258614],
              [-38.681826567842897, -8.724236785094243],
              [-38.681854023079502, -8.724236808100875],
              [-38.679205407338657, -8.719871120871685],
              [-38.677521884184635, -8.720877774488917],
              [-38.677385215706394, -8.721230418252331],
              [-38.675347716852606, -8.722504044233206],
              [-38.675120337978079, -8.722621436350977],
              [-38.66668799046176, -8.720869688033755],
              [-38.666458207497563, -8.720723498173328],
              [-38.667004839313925, -8.719403390989703],
              [-38.667141396572674, -8.719186427892643],
              [-38.667432756580837, -8.71868015656953],
              [-38.66746935246772, -8.718417880231264],
              [-38.667433532385495, -8.717802780959937],
              [-38.667260964002793, -8.717621727544016],
              [-38.667015700133291, -8.717404429888539],
              [-38.66700661718145, -8.717395376812199],
              [-38.666943020512974, -8.717350095490628],
              [-38.666697548622658, -8.717367970478843],
              [-38.666406437418097, -8.717593842905282],
              [-38.666015140483104, -8.718027664692096],
              [-38.665914963019361, -8.718226569150572],
              [-38.665678099083479, -8.718787157907535],
              [-38.665395996684737, -8.719103488549786],
              [-38.665050264822128, -8.719410717748152],
              [-38.664941067983293, -8.719528207928226],
              [-38.664495172783305, -8.720016250483971],
              [-38.664055121026962, -8.720474810563083],
              [-38.663761959680791, -8.721057237276026],
              [-38.663403310795573, -8.721572671854084],
              [-38.662848254592276, -8.721624822064685],
              [-38.662175532621447, -8.721606134050576],
              [-38.658284405104752, -8.721774509971375],
              [-38.65650694521873, -8.716969955192093],
              [-38.655589166224047, -8.716525914227521],
              [-38.655590888513593, -8.714644533526187],
              [-38.650321754636373, -8.706092188971063],
              [-38.648744108301848, -8.703513784985859],
              [-38.648586505453714, -8.69987651144235],
              [-38.654860820486832, -8.697856145655564],
              [-38.654624716701193, -8.697584578147382],
              [-38.654788568760672, -8.697340508617323],
              [-38.654734125377814, -8.697231917881263],
              [-38.656498531919638, -8.696292821323754],
              [-38.65691637942038, -8.696636912412002],
              [-38.658289865116274, -8.695724590247877],
              [-38.659742358352233, -8.697914809543629],
              [-38.66326268582376, -8.69533103371066],
              [-38.663416851650659, -8.695747245655022],
              [-38.663625471706219, -8.696263001995455],
              [-38.664225578073989, -8.696109764424044],
              [-38.664587296787886, -8.698244732372231],
              [-38.666787300644025, -8.698129077286005],
              [-38.669196448468021, -8.697941230165675],
              [-38.670465447943762, -8.702139269702048],
              [-38.670738094630863, -8.702220911803668],
              [-38.671974762229645, -8.701823993990416],
              [-38.67300698451637, -8.700898118220616],
              [-38.676760097045708, -8.697531649621528],
              [-38.676833022533835, -8.697296538020886],
              [-38.671493240306319, -8.691068918826602],
              [-38.673766823556292, -8.689913094666055],
              [-38.674813151460874, -8.688819527328073],
              [-38.68015085750352, -8.697525467550705],
              [-38.680319991227393, -8.697783731858625],
              [-38.69277346748364, -8.689723112453546],
              [-38.697947086552318, -8.68637420820032],
              [-38.686651103304712, -8.66894078266369],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0049000',
        uf: 'PE',
        nome_proje: 'PA BARRA AZUL',
        municipio: 'BONITO',
        area_hecta: '859.7262',
        capacidade: 68.0,
        num_famili: 68.0,
        fase: 5.0,
        data_de_cr: '10/01/1995',
        forma_obte: 'Compra e Venda',
        data_obten: '20/07/1993',
        area_calc_: 863.9697,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.691740559938033, -8.526633889997841],
              [-35.68897416993844, -8.545068889997856],
              [-35.6876274999386, -8.546178329997867],
              [-35.687554999938619, -8.546232499997872],
              [-35.678018059939788, -8.553380559997906],
              [-35.679330559939636, -8.556041389997898],
              [-35.683098609939208, -8.562253609997889],
              [-35.685191109938948, -8.562179719997861],
              [-35.685955829938862, -8.562101669997871],
              [-35.686160559938834, -8.562081109997859],
              [-35.689132499938481, -8.562554439997848],
              [-35.69654388993758, -8.56387582999781],
              [-35.697274719937482, -8.562635279997817],
              [-35.699914439937153, -8.561787779997799],
              [-35.701370559936976, -8.561432779997793],
              [-35.701816939936919, -8.56131971999778],
              [-35.709145279935996, -8.559203889997745],
              [-35.710467499935831, -8.558710829997739],
              [-35.713284169935463, -8.554299439997726],
              [-35.710491669935784, -8.547840559997745],
              [-35.710389439935796, -8.547779719997743],
              [-35.707641669936123, -8.542365559997755],
              [-35.699698889937075, -8.531567779997808],
              [-35.691740559938033, -8.526633889997841],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0093000',
        uf: 'PE',
        nome_proje: 'PA MUNDO NOVO',
        municipio: 'BEZERROS',
        area_hecta: '901.7292',
        capacidade: 50.0,
        num_famili: 23.0,
        fase: 4.0,
        data_de_cr: '30/12/1996',
        forma_obte: 'Compra e Venda',
        data_obten: '21/06/1996',
        area_calc_: 889.1558,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.65706805994089, -8.131801669998088],
              [-35.656838329940918, -8.13180555999808],
              [-35.656042219941021, -8.131830559998093],
              [-35.656332779940996, -8.137360279998077],
              [-35.65600582994103, -8.137381389998074],
              [-35.655636669941082, -8.139333329998086],
              [-35.656023329941057, -8.144435279998081],
              [-35.654618329941222, -8.144872779998089],
              [-35.652619439941461, -8.145516669998091],
              [-35.653533329941354, -8.148071939998086],
              [-35.653038059941416, -8.148900279998102],
              [-35.652119439941536, -8.150552779998097],
              [-35.651960279941548, -8.150836109998096],
              [-35.649981669941816, -8.162184999998106],
              [-35.650031389941816, -8.163313609998111],
              [-35.650046939941817, -8.163589999998104],
              [-35.650096109941813, -8.165298609998118],
              [-35.650199999941805, -8.167718889998106],
              [-35.650221389941812, -8.168090559998099],
              [-35.6503436099418, -8.171119719998098],
              [-35.650606939941802, -8.177751669998097],
              [-35.651974999941636, -8.178582219998102],
              [-35.654694719941325, -8.180843329998076],
              [-35.65500971994129, -8.181236939998076],
              [-35.654480279941353, -8.181712499998088],
              [-35.655673329941216, -8.182679719998077],
              [-35.659585559940759, -8.183489719998057],
              [-35.660991939940601, -8.184887499998052],
              [-35.665969999940017, -8.187339439998034],
              [-35.666043329939995, -8.18479249999803],
              [-35.666108329939981, -8.18117027999803],
              [-35.666125559939978, -8.181109719998036],
              [-35.666168889939961, -8.179702779998037],
              [-35.666243059939951, -8.178346389998035],
              [-35.666322219939929, -8.174670279998033],
              [-35.666280829939922, -8.172736389998034],
              [-35.666292499939921, -8.172503059998025],
              [-35.66628693993993, -8.172409169998025],
              [-35.666328609939903, -8.168436939998038],
              [-35.666557219939875, -8.16400471999804],
              [-35.666504719939859, -8.158074439998039],
              [-35.66646888993985, -8.156926389998025],
              [-35.66648721993986, -8.156894719998032],
              [-35.666516669939838, -8.155112499998037],
              [-35.66650638993984, -8.151501389998044],
              [-35.666704439939799, -8.147201389998031],
              [-35.666779439939788, -8.14605193999803],
              [-35.666883329939765, -8.144966939998039],
              [-35.666949999939753, -8.143858609998034],
              [-35.667254439939704, -8.136847779998034],
              [-35.666834719939729, -8.133756939998046],
              [-35.662072779940303, -8.132187499998064],
              [-35.65706805994089, -8.131801669998088],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0278000',
        uf: 'PE',
        nome_proje: 'PA JOSIAS BARROS I',
        municipio: 'IBIMIRIM',
        area_hecta: '700.5238',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '13/11/2008',
        forma_obte: 'Desapropriação',
        data_obten: '13/03/2008',
        area_calc_: 700.5513,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.667343448260858, -8.636701857433078],
              [-37.769822831495382, -8.649457339347906],
              [-37.769822740915146, -8.649457248623914],
              [-37.773530937407671, -8.643680437479475],
              [-37.702947240215671, -8.635858268369009],
              [-37.670448132865666, -8.631876462304083],
              [-37.670338396031788, -8.631997528872358],
              [-37.670003169896972, -8.632365861949003],
              [-37.669653080931262, -8.63274870223719],
              [-37.669285943507703, -8.633147398542441],
              [-37.668915615157886, -8.633548886614516],
              [-37.668544182936671, -8.633953987553765],
              [-37.66818110772271, -8.634359659611375],
              [-37.667854811135179, -8.634719792076943],
              [-37.667605034348739, -8.634999436535916],
              [-37.667428785123391, -8.635274543572935],
              [-37.667314556735015, -8.635587394321503],
              [-37.667269086754509, -8.635934214236265],
              [-37.667299347331905, -8.636322081104634],
              [-37.667299437881383, -8.636322171848867],
              [-37.667340271959404, -8.636674807972277],
              [-37.667343448260858, -8.636701857433078],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0180000',
        uf: 'PE',
        nome_proje: 'PA NOVA CONQUISTA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '1233.0190',
        capacidade: 16.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '04/07/2006',
        forma_obte: 'Arrecadação',
        data_obten: '04/07/2006',
        area_calc_: 1234.0136,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.959888949625103, -8.416431111118994],
              [-38.973470640741724, -8.391365444231733],
              [-38.968929134836777, -8.389013304645239],
              [-38.963279531714264, -8.386579602345234],
              [-38.961844423887754, -8.386072922860741],
              [-38.959301216014161, -8.385059582106997],
              [-38.948629336905327, -8.378011957502006],
              [-38.93314053652167, -8.375781662359044],
              [-38.933157431023119, -8.383230202714667],
              [-38.933083065151642, -8.384143686385672],
              [-38.933071460857235, -8.389323177636813],
              [-38.932992407628717, -8.392668156747851],
              [-38.93277618393347, -8.3965888520639],
              [-38.937567853749371, -8.402643640574492],
              [-38.942377892393004, -8.405879282105452],
              [-38.945475973393556, -8.407919552606591],
              [-38.945854822734127, -8.408168986703176],
              [-38.948712973846099, -8.410685529554309],
              [-38.951911407503857, -8.413501586562784],
              [-38.954074786145057, -8.415406354569011],
              [-38.959888949625103, -8.416431111118994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0022000',
        uf: 'PE',
        nome_proje: 'PA QUEBRA UNHAS',
        municipio: 'FLORESTA',
        area_hecta: '119.0000',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 5.0,
        data_de_cr: '12/08/1996',
        forma_obte: 'Adjudicação',
        data_obten: '19/01/1993',
        area_calc_: 112.1338,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.245922662068125, -8.398589533055137],
              [-38.24403920355978, -8.401102597819204],
              [-38.242800508194073, -8.400621937001654],
              [-38.237558053702365, -8.425210061503343],
              [-38.240861304608671, -8.426012026747836],
              [-38.246878269993438, -8.398821547737974],
              [-38.245922662068125, -8.398589533055137],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0226000',
        uf: 'PE',
        nome_proje: 'PA BATALHA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.0793',
        capacidade: 20.0,
        num_famili: 18.0,
        fase: 3.0,
        data_de_cr: '09/04/2007',
        forma_obte: 'Desapropriação',
        data_obten: '01/11/2006',
        area_calc_: 1320.235,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.024052326749221, -8.616019342021554],
              [-40.023719241410468, -8.616647696904145],
              [-40.021305748908453, -8.642177879963187],
              [-40.031200721229602, -8.682069892670492],
              [-40.039495734402621, -8.690946703549461],
              [-40.091238566256955, -8.753220287537602],
              [-40.126402788871296, -8.797191482589128],
              [-40.129494784578213, -8.793629467043797],
              [-40.095360936402777, -8.749665252426526],
              [-40.043780506773189, -8.686550991815999],
              [-40.035266644237623, -8.677902138742068],
              [-40.026143293836824, -8.640953251845962],
              [-40.024052326749221, -8.616019342021554],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0236000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOAQUIM',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.6399',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '19/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '17/08/2006',
        area_calc_: 1320.7356,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.069487054339923, -8.660175939024324],
              [-40.059660306475124, -8.652894909477444],
              [-40.055167765230365, -8.633604276219256],
              [-40.032314326111241, -8.596913784082837],
              [-40.031749518808624, -8.599779503754782],
              [-40.031416319576515, -8.600491067003967],
              [-40.031148992248959, -8.601065619268613],
              [-40.050330448494741, -8.634829249039077],
              [-40.055594919181921, -8.657062864713481],
              [-40.065202877885859, -8.664571873924002],
              [-40.115971593244829, -8.731889538919537],
              [-40.144930147531554, -8.775783189951747],
              [-40.146666043066411, -8.773772434825473],
              [-40.148015762244938, -8.772210139237435],
              [-40.120093486030299, -8.728334289019262],
              [-40.069487054339923, -8.660175939024324],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0212000',
        uf: 'PE',
        nome_proje: 'PA ASA BRANCA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.0595',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '03/01/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 1320.1651,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.031148992248959, -8.601065619268613],
              [-40.030940477734035, -8.601513580034737],
              [-40.030944483280457, -8.602625156230433],
              [-40.029809280617251, -8.605091214582929],
              [-40.045493093655836, -8.636054164474775],
              [-40.051529442718468, -8.66123078655788],
              [-40.060918602247938, -8.668967771816341],
              [-40.111849620968748, -8.735444753161394],
              [-40.141844837459317, -8.77935585949737],
              [-40.144930147531554, -8.775783189951747],
              [-40.115971593244829, -8.731889538919537],
              [-40.065202877885859, -8.664571873924002],
              [-40.055594919181921, -8.657062864713481],
              [-40.050330448494741, -8.634829249039077],
              [-40.031148992248959, -8.601065619268613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0007000',
        uf: 'PE',
        nome_proje: 'PA POÇO DO ICÓ',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '874.0000',
        capacidade: 26.0,
        num_famili: 24.0,
        fase: 5.0,
        data_de_cr: '27/12/1989',
        forma_obte: 'Desapropriação',
        data_obten: '22/04/1988',
        area_calc_: 868.938,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.878264729155759, -8.744669504696454],
              [-39.878172039954947, -8.744258116681388],
              [-39.873204428766783, -8.722304380627087],
              [-39.864057036386278, -8.681124889987521],
              [-39.862960665851276, -8.68254578836877],
              [-39.859204743099014, -8.683004221671949],
              [-39.869458265275505, -8.731852860178735],
              [-39.87485201408343, -8.758196995086474],
              [-39.870603593514886, -8.77687383141107],
              [-39.885333531426042, -8.779451985170425],
              [-39.887252231492084, -8.770806112407977],
              [-39.882705896250769, -8.756273855014335],
              [-39.878264729155759, -8.744669504696454],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0262000',
        uf: 'PE',
        nome_proje: 'PA CAMPINAS',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '347.3414',
        capacidade: 38.0,
        num_famili: 38.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 343.3631,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.246129365161771, -8.849796811959692],
              [-35.241781250003996, -8.850191681788422],
              [-35.239974991571508, -8.850358484598353],
              [-35.23881233896401, -8.850457671248387],
              [-35.237494613629821, -8.851738872256352],
              [-35.235659261329232, -8.853529244417166],
              [-35.234203707675952, -8.854947673314262],
              [-35.230709494005822, -8.858358627379326],
              [-35.230624886324719, -8.858391960408561],
              [-35.229753153079997, -8.858336347765865],
              [-35.225341289695734, -8.857804203682395],
              [-35.224169546673771, -8.857662940699679],
              [-35.222813643224022, -8.857498689484498],
              [-35.226999331691815, -8.862916661662691],
              [-35.228085262256826, -8.868811481042936],
              [-35.228169599917855, -8.869739481032493],
              [-35.228227191200716, -8.870379633322488],
              [-35.228265334336839, -8.870837556142252],
              [-35.228278706815075, -8.870925530743781],
              [-35.228343301566689, -8.871635735721357],
              [-35.229277534327522, -8.871679835380609],
              [-35.23016072939884, -8.87172433691947],
              [-35.230515031801538, -8.871439257406418],
              [-35.231848130819998, -8.870378274748051],
              [-35.232940718336039, -8.869509351431319],
              [-35.234056587190722, -8.868621360033785],
              [-35.235028578108221, -8.867748761520028],
              [-35.235389142374622, -8.867424459360778],
              [-35.236992198691325, -8.86597876717601],
              [-35.237408360062105, -8.865471280415175],
              [-35.239702030758728, -8.864213740466928],
              [-35.23996149838149, -8.864067468686263],
              [-35.240570515321991, -8.863938669639042],
              [-35.242153867065966, -8.864251903681321],
              [-35.243684231473694, -8.864544804455765],
              [-35.244312703919469, -8.864655494410359],
              [-35.244774383352976, -8.864725662214981],
              [-35.244874784719848, -8.864758076832192],
              [-35.245478820649645, -8.864954181014676],
              [-35.246538074337103, -8.863729564494845],
              [-35.246649138250035, -8.863602411601551],
              [-35.247614853431983, -8.862484269514106],
              [-35.247660378678553, -8.862434057892838],
              [-35.247733333655866, -8.862345366989057],
              [-35.248049169344803, -8.861982586355301],
              [-35.248386594982321, -8.861591023608582],
              [-35.249040610583336, -8.860832616864062],
              [-35.249095641593101, -8.860771980789934],
              [-35.249772383508237, -8.859989728697956],
              [-35.249873256628689, -8.859869858469064],
              [-35.250437005814177, -8.85922046502494],
              [-35.250559655920178, -8.859078671790112],
              [-35.247536393321489, -8.858594099734711],
              [-35.247484123637086, -8.858511438896869],
              [-35.246415647823845, -8.856581199905191],
              [-35.246295551190393, -8.856350498573628],
              [-35.246204422538433, -8.856065238322834],
              [-35.245950921718311, -8.855158541234584],
              [-35.245913021398998, -8.855075487696894],
              [-35.245636213080587, -8.854144932617677],
              [-35.246535521764002, -8.85235791115351],
              [-35.246588320386955, -8.851501310351955],
              [-35.246129365161771, -8.849796811959692],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0152000',
        uf: 'PE',
        nome_proje: 'PA RONDA',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '1148.0000',
        capacidade: 90.0,
        num_famili: 89.0,
        fase: 4.0,
        data_de_cr: '16/11/1998',
        forma_obte: 'Desapropriação',
        data_obten: '08/01/1998',
        area_calc_: 1190.6385,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.376986898540551, -8.183772199425427],
              [-35.374396838766394, -8.183851428706483],
              [-35.373504226252173, -8.183878729877675],
              [-35.371215503770671, -8.184018348809483],
              [-35.371175333623405, -8.18402084423559],
              [-35.365083934843888, -8.18406281038915],
              [-35.365078798687577, -8.184652752692687],
              [-35.365101155737491, -8.185658915920547],
              [-35.365144530982505, -8.187613054136472],
              [-35.365147867999447, -8.187655874833341],
              [-35.365406916925025, -8.192919575893004],
              [-35.365425210882492, -8.193359710772119],
              [-35.36536166997773, -8.196054336594734],
              [-35.365360930067382, -8.196082629950515],
              [-35.365246176407787, -8.199918787469651],
              [-35.365237073507814, -8.202735820845236],
              [-35.365236429235274, -8.202764927059389],
              [-35.365111947115388, -8.20841887735687],
              [-35.365083361451255, -8.208478515478181],
              [-35.364978658333058, -8.212829148111636],
              [-35.364867824888705, -8.216049702687815],
              [-35.364859283796463, -8.216323062618629],
              [-35.364832272644065, -8.217184463358846],
              [-35.364832990469601, -8.219341843294609],
              [-35.364833836354684, -8.221811033892807],
              [-35.364834164882566, -8.222585680436444],
              [-35.364874780529895, -8.224325359080751],
              [-35.364903752300179, -8.225569822785163],
              [-35.365444973173204, -8.22562782385037],
              [-35.367272351357997, -8.225823668936112],
              [-35.369006590138142, -8.226009479998462],
              [-35.370425101361974, -8.22616144622506],
              [-35.372316113898805, -8.226364130851568],
              [-35.372357231930877, -8.226367774350996],
              [-35.374242586567242, -8.226534502645071],
              [-35.376780381271942, -8.226758910095109],
              [-35.379302199465506, -8.22698194923551],
              [-35.380518500035038, -8.227089524299824],
              [-35.381710192847429, -8.227194891544258],
              [-35.383137890790614, -8.227321088107924],
              [-35.383178736554839, -8.227324732056642],
              [-35.38428901883637, -8.227422901094164],
              [-35.385967688952377, -8.22757127645836],
              [-35.388193597874107, -8.227768087486675],
              [-35.388386984244711, -8.225734221860419],
              [-35.388583616363789, -8.223666535608885],
              [-35.388671195926399, -8.22318782150117],
              [-35.388677696157387, -8.223152264048311],
              [-35.388954664337554, -8.221638212281006],
              [-35.38950500856744, -8.21863010468414],
              [-35.389511509845299, -8.218594728003827],
              [-35.389989810932832, -8.215980115315293],
              [-35.390446717082163, -8.214610686047948],
              [-35.390797393851564, -8.21355966840728],
              [-35.391263860139404, -8.210998080817145],
              [-35.391270534633684, -8.21096134746856],
              [-35.391357963400772, -8.208881570176253],
              [-35.390653925449598, -8.204679794162026],
              [-35.389951871065605, -8.200489641036064],
              [-35.386498865200124, -8.19412074383488],
              [-35.385028213758758, -8.191879802921838],
              [-35.385010819783304, -8.191853153585118],
              [-35.383992714220483, -8.190519664003066],
              [-35.383643788409167, -8.190008453860576],
              [-35.378473268283798, -8.185130715238676],
              [-35.377278693497672, -8.184074608526624],
              [-35.376986898540551, -8.183772199425427],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0171000',
        uf: 'PE',
        nome_proje: 'PA CURRALINHO DOS ANGICOS',
        municipio: 'FLORESTA',
        area_hecta: '3489.0861',
        capacidade: 50.0,
        num_famili: 48.0,
        fase: 3.0,
        data_de_cr: '15/05/2006',
        forma_obte: 'Desapropriação',
        data_obten: '15/05/2006',
        area_calc_: 3359.3543,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.28316802195809, -8.754046625883936],
              [-38.238887670337846, -8.85356744934203],
              [-38.241584399261356, -8.856397119276302],
              [-38.264329366568489, -8.821926075614776],
              [-38.276931817410478, -8.796084568776848],
              [-38.280627857671433, -8.788589887939658],
              [-38.291543783673831, -8.788509091125524],
              [-38.302173641159399, -8.77495641644388],
              [-38.307318817954396, -8.757619768397143],
              [-38.307468941910678, -8.757114949079005],
              [-38.307927533836896, -8.755558873366246],
              [-38.302620353546835, -8.752575120729704],
              [-38.301162629644743, -8.751517926011894],
              [-38.299689199518987, -8.750745510463574],
              [-38.299411589158417, -8.755003628218089],
              [-38.29553468454143, -8.75596844763764],
              [-38.294425264786021, -8.755776625476589],
              [-38.293906992577732, -8.754991767441938],
              [-38.292809768996442, -8.755420600427765],
              [-38.292271431068599, -8.755345609388986],
              [-38.29066610235791, -8.7555759522433],
              [-38.290116848344702, -8.756080059718139],
              [-38.289416911821007, -8.755693629119291],
              [-38.289274611272461, -8.754794696247833],
              [-38.288109106326765, -8.754363703550158],
              [-38.287244570430595, -8.752714975241544],
              [-38.286382493588, -8.752069922322574],
              [-38.284499725159399, -8.751103776068264],
              [-38.283389851674713, -8.75355596423697],
              [-38.28316802195809, -8.754046625883936],
            ],
          ],
          [
            [
              [-38.302950799391631, -8.717969464762518],
              [-38.291964965700991, -8.745135133963988],
              [-38.291918263390144, -8.745251404801561],
              [-38.288729390659626, -8.753241881827309],
              [-38.287230967270922, -8.752395685534111],
              [-38.288205681242644, -8.754254717647393],
              [-38.289396896287613, -8.754695255086965],
              [-38.289541263573838, -8.75560712551184],
              [-38.290096889729625, -8.755913874030167],
              [-38.290604706138403, -8.755447675949659],
              [-38.292271144567671, -8.755208583975479],
              [-38.2927932131471, -8.755281283452359],
              [-38.293960218015265, -8.754825266163103],
              [-38.294506774250173, -8.755653048035304],
              [-38.295529672271769, -8.755829875764954],
              [-38.299281966223901, -8.754896029254212],
              [-38.299550966586629, -8.750770217689258],
              [-38.299490319713549, -8.750487947335507],
              [-38.301234938646665, -8.751402469972696],
              [-38.302694296419034, -8.75246093364936],
              [-38.307966894134616, -8.75542892959467],
              [-38.315982512193415, -8.728445920140311],
              [-38.316116155182016, -8.727996372910841],
              [-38.316997492699336, -8.725024844621368],
              [-38.311092180058537, -8.724092032098913],
              [-38.306164627795035, -8.718220501406496],
              [-38.30324297003456, -8.717275575613806],
              [-38.303131980365912, -8.717533196978176],
              [-38.302950799391631, -8.717969464762518],
            ],
          ],
          [
            [
              [-38.313445526603445, -8.678077948396632],
              [-38.303477739678947, -8.70334311632058],
              [-38.308604018801638, -8.704041229390315],
              [-38.304138010867078, -8.715011745937671],
              [-38.304983318143059, -8.71593556949372],
              [-38.30976727524628, -8.713812341928859],
              [-38.312105898313952, -8.7144582509528],
              [-38.312121764119887, -8.713899666942142],
              [-38.316279636708352, -8.713703376004876],
              [-38.316578330330181, -8.715778882416487],
              [-38.31816808757651, -8.717139298314283],
              [-38.319390063292978, -8.716566297000714],
              [-38.3196113490567, -8.715929599678852],
              [-38.320192930558669, -8.712179439943261],
              [-38.320898912984717, -8.711974307490982],
              [-38.323263492271373, -8.704062238952613],
              [-38.326485920294594, -8.703377299317394],
              [-38.327927314884967, -8.698224911182667],
              [-38.319551336190052, -8.698039416509911],
              [-38.32827376164667, -8.676498478996187],
              [-38.316143810818986, -8.677786048274774],
              [-38.313445526603445, -8.678077948396632],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0262000',
        uf: 'PE',
        nome_proje: 'PA GILDA GOMES',
        municipio: 'OROCO',
        area_hecta: '1012.4141',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '25/08/2008',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/2006',
        area_calc_: 1020.6428,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.610283809712065, -8.423529265138821],
              [-39.610561389778979, -8.42267385841499],
              [-39.611099635735776, -8.421040024582727],
              [-39.611446028661391, -8.419987386465481],
              [-39.611769141298765, -8.419011484852161],
              [-39.612022351286647, -8.418235547330642],
              [-39.612291878777505, -8.417410452537791],
              [-39.612497556777889, -8.416781260427692],
              [-39.612710392169134, -8.416140669590922],
              [-39.613085837722181, -8.415014649303615],
              [-39.613295967771109, -8.414376124676803],
              [-39.613571143769953, -8.413541704198543],
              [-39.613978275419527, -8.412293790398699],
              [-39.614122820953114, -8.411856959859035],
              [-39.614253643514644, -8.411461901824904],
              [-39.614432607713624, -8.410917157387129],
              [-39.61462558686069, -8.410331987562628],
              [-39.614741457680509, -8.409977437599627],
              [-39.615102307785804, -8.408882064597437],
              [-39.61555595359895, -8.407500891160872],
              [-39.574966733107971, -8.405672079902532],
              [-39.573891694655018, -8.403392163322779],
              [-39.563743983343386, -8.40505370225765],
              [-39.563511410396956, -8.405119068473754],
              [-39.564063344658912, -8.406501639714364],
              [-39.564147036510015, -8.408661806329755],
              [-39.563871284532382, -8.410490276149558],
              [-39.564018709063831, -8.411577936833512],
              [-39.56463041364259, -8.413115975374989],
              [-39.564112986940259, -8.414630335415213],
              [-39.564304658372983, -8.416049311779096],
              [-39.563697521088372, -8.41720138730237],
              [-39.561332790999181, -8.417916337494722],
              [-39.561315372834933, -8.418549798196125],
              [-39.561553402179229, -8.419705782357216],
              [-39.56141719034715, -8.420724609908396],
              [-39.5617170647851, -8.420754111046611],
              [-39.57882268019474, -8.422139378247968],
              [-39.609763287223025, -8.425092466354059],
              [-39.610283809712065, -8.423529265138821],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0299000',
        uf: 'PE',
        nome_proje: 'PA JUNCO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1776.2574',
        capacidade: 14.0,
        num_famili: 14.0,
        fase: 3.0,
        data_de_cr: '21/03/2012',
        forma_obte: 'Confisco',
        data_obten: '21/03/2012',
        area_calc_: 1777.2688,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.74587397196121, -8.59257057607717],
              [-39.727842154337374, -8.577562314024529],
              [-39.721684488501388, -8.582375675468521],
              [-39.763593337386276, -8.623453911678647],
              [-39.766681914453834, -8.6217378886045],
              [-39.77446599329533, -8.63061754707627],
              [-39.789882756392828, -8.625006938385066],
              [-39.790261287856104, -8.624882340415187],
              [-39.797330545305869, -8.619172151346277],
              [-39.798164793082449, -8.618511800856295],
              [-39.801735880624165, -8.615622800164628],
              [-39.806324841855371, -8.611923877074027],
              [-39.809392149877127, -8.609882431437223],
              [-39.813646188207564, -8.606443350911832],
              [-39.816690153628677, -8.603981246811848],
              [-39.810372665172657, -8.596097261935524],
              [-39.76917370308874, -8.604794583938235],
              [-39.809277710182151, -8.609741850263783],
              [-39.806088163125423, -8.611864589562421],
              [-39.761398183712011, -8.606042368845953],
              [-39.759366818226063, -8.603671374808503],
              [-39.74587397196121, -8.59257057607717],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0135000',
        uf: 'PE',
        nome_proje: 'PA MARTIRES DA RESISTENCIA II',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1196.9620',
        capacidade: 39.0,
        num_famili: 36.0,
        fase: 4.0,
        data_de_cr: '27/12/2004',
        forma_obte: 'Doação',
        data_obten: '23/12/2004',
        area_calc_: 1195.6524,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.860769206226763, -8.488646345762309],
              [-39.85438624031962, -8.480516804687435],
              [-39.852240820876368, -8.477784204935711],
              [-39.85190475350268, -8.477356142896726],
              [-39.845539659671353, -8.46924862675362],
              [-39.845367761937467, -8.46902967123499],
              [-39.84519668434897, -8.468811799177576],
              [-39.841531679257336, -8.46414328100162],
              [-39.839725278859184, -8.46556008841268],
              [-39.839935104190644, -8.467143591255178],
              [-39.822786726445436, -8.46980559161914],
              [-39.827319612645233, -8.477710292207382],
              [-39.827457666689419, -8.477951034646088],
              [-39.82759572091517, -8.478191777016438],
              [-39.832630082262384, -8.486970380337864],
              [-39.846909967839039, -8.511868294555066],
              [-39.86430549598694, -8.500468052006843],
              [-39.864107609714132, -8.500040664931671],
              [-39.864694558479499, -8.499662935029148],
              [-39.865857980674292, -8.498896733374846],
              [-39.86632274417704, -8.498604470955307],
              [-39.8671187755332, -8.498084547365258],
              [-39.86729983310989, -8.498505559134971],
              [-39.868099256751094, -8.497981608792871],
              [-39.86165192720366, -8.489770602726646],
              [-39.860769206226763, -8.488646345762309],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0284000',
        uf: 'PE',
        nome_proje: 'PA SITIO FORTALEZA',
        municipio: 'DORMENTES',
        area_hecta: '59.1185',
        capacidade: 2.0,
        num_famili: 2.0,
        fase: 3.0,
        data_de_cr: '11/09/2009',
        forma_obte: 'Confisco',
        data_obten: '11/09/2009',
        area_calc_: 59.1265,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.502222420272609, -8.46873724631268],
              [-40.500396933745201, -8.467855032111116],
              [-40.498136671138006, -8.466779701633676],
              [-40.497739573810932, -8.466608694701248],
              [-40.497230361044338, -8.466262332470885],
              [-40.496767683312179, -8.466065714335956],
              [-40.496356715481618, -8.465783356387243],
              [-40.496031517447577, -8.465580333253859],
              [-40.495899947165306, -8.46558807078355],
              [-40.495598240474102, -8.467494009775736],
              [-40.495617022182799, -8.467677408441514],
              [-40.495598807828841, -8.467852629692688],
              [-40.495573852573166, -8.467975611601121],
              [-40.495543934994366, -8.468107112371937],
              [-40.495518691116082, -8.468272956422464],
              [-40.495503204849115, -8.468449071475371],
              [-40.495470434279383, -8.46866494887999],
              [-40.495427662890506, -8.468901662115472],
              [-40.49539571172869, -8.46914158920459],
              [-40.495364589813086, -8.469384587541334],
              [-40.495302796848407, -8.469681596061559],
              [-40.495251418498057, -8.470017899216838],
              [-40.495199860597729, -8.470331235333017],
              [-40.495157063120658, -8.470608277842251],
              [-40.495108957127499, -8.470874761049595],
              [-40.49507263971941, -8.471136497080275],
              [-40.49503735065921, -8.471405824803533],
              [-40.495012859001577, -8.471578357375138],
              [-40.494994618689809, -8.471723377081831],
              [-40.49497048653113, -8.471847893063535],
              [-40.494940717141503, -8.471970802989338],
              [-40.494898892909596, -8.472100088747863],
              [-40.494871528499836, -8.47223429248537],
              [-40.494835386850703, -8.472376939264727],
              [-40.494798438612456, -8.472522392279977],
              [-40.494770981245672, -8.472656053829757],
              [-40.494741537311377, -8.472792706980337],
              [-40.494705567812247, -8.472932911650155],
              [-40.494664341452975, -8.473075939589515],
              [-40.494644056166592, -8.473208850296055],
              [-40.494636710569964, -8.47332932336789],
              [-40.494616347451235, -8.473418559512979],
              [-40.494546326429855, -8.473678254386586],
              [-40.494534585898641, -8.473907162829606],
              [-40.496781156711265, -8.474203376882096],
              [-40.497177313847338, -8.474833560327955],
              [-40.497365920971106, -8.475106640965917],
              [-40.497903663087705, -8.475827789319567],
              [-40.499484608742037, -8.476220579900064],
              [-40.501237354093178, -8.471264130553463],
              [-40.502222420272609, -8.46873724631268],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0073000',
        uf: 'PE',
        nome_proje: 'PA MARIA CAROLINA',
        municipio: 'OROCO',
        area_hecta: '122.1906',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 4.0,
        data_de_cr: '11/03/2003',
        forma_obte: 'Doação',
        data_obten: '22/05/2002',
        area_calc_: 124.1837,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.550610058851056, -8.554391758921604],
              [-39.548590885308428, -8.553253064592376],
              [-39.548515770012109, -8.553212197471312],
              [-39.547961289532722, -8.552954388002991],
              [-39.547215046981499, -8.555284788029539],
              [-39.545839038885156, -8.559631651163604],
              [-39.544437112417569, -8.563920297420641],
              [-39.543059375860118, -8.568241919609822],
              [-39.54192403892656, -8.571715415278844],
              [-39.5416529795041, -8.572558874651261],
              [-39.541511309297427, -8.573008335639852],
              [-39.541148774682391, -8.574069544313009],
              [-39.540403107478916, -8.576410244135671],
              [-39.540251085671535, -8.576867226543301],
              [-39.539647364361855, -8.578764705192114],
              [-39.538887590734738, -8.58116692797334],
              [-39.537468209561752, -8.585552720787566],
              [-39.537430470234348, -8.585663483485193],
              [-39.537430470361592, -8.585663573934449],
              [-39.537264670058015, -8.586149880202768],
              [-39.53721978108436, -8.586281727562062],
              [-39.537905353824158, -8.586350056019192],
              [-39.538200866745434, -8.586339151516743],
              [-39.538668735893054, -8.586367713329061],
              [-39.53888160744205, -8.586331869265022],
              [-39.539366753495393, -8.586303513411204],
              [-39.539607617325437, -8.586205219713632],
              [-39.539937283865534, -8.586119102650203],
              [-39.540178164680754, -8.586033019254446],
              [-39.540482511320604, -8.585971448992691],
              [-39.54129377193825, -8.585724107823895],
              [-39.541282488911129, -8.58558564567435],
              [-39.541267137019176, -8.585397532528438],
              [-39.541165353342812, -8.58372734671112],
              [-39.541218406400972, -8.583522133070629],
              [-39.541888919088755, -8.581496753655806],
              [-39.542044448703876, -8.581014530412723],
              [-39.542714230698394, -8.578928187977823],
              [-39.543044209747514, -8.577915414310914],
              [-39.543384726841438, -8.576837502025416],
              [-39.54393381896179, -8.575136551682141],
              [-39.544052315057506, -8.574751522595665],
              [-39.544329356214597, -8.573899822534795],
              [-39.544725254110276, -8.572662730753557],
              [-39.545376196773972, -8.570638640329546],
              [-39.545706376153177, -8.56958425782355],
              [-39.54650460494333, -8.567109881655782],
              [-39.54660259180077, -8.56681107920398],
              [-39.5471502135196, -8.565116910949856],
              [-39.547385870860943, -8.564374801903298],
              [-39.547731744767631, -8.563309180051949],
              [-39.548105422465156, -8.562116075357011],
              [-39.548505301055528, -8.560882230984966],
              [-39.548914262399734, -8.559647107097421],
              [-39.549326059256366, -8.558363226617926],
              [-39.549757792658859, -8.557043047252625],
              [-39.55018170179946, -8.555718084808436],
              [-39.550610058851056, -8.554391758921604],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0069000',
        uf: 'PE',
        nome_proje: 'PA ANTÔNIO DE LINDÔ',
        municipio: 'PARNAMIRIM',
        area_hecta: '799.7130',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 4.0,
        data_de_cr: '05/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '15/01/2002',
        area_calc_: 814.3269,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.624335517747639, -8.112834647705382],
              [-39.625153980000384, -8.112680260445302],
              [-39.626442194757395, -8.112437412909594],
              [-39.630089855106625, -8.111738126167781],
              [-39.633670182358763, -8.111057454662623],
              [-39.632765984167563, -8.110011176288353],
              [-39.629858490508326, -8.110218469298573],
              [-39.627074056192974, -8.110476114104669],
              [-39.626338956113614, -8.110535765829649],
              [-39.625565648689772, -8.110598550203008],
              [-39.622559175564369, -8.110842575997012],
              [-39.62146141250161, -8.110924752321306],
              [-39.61810392317954, -8.111154627817415],
              [-39.618015709815907, -8.111161183585859],
              [-39.61496584528598, -8.111350135510156],
              [-39.614102457401437, -8.111388703149004],
              [-39.610890469192505, -8.111569909484333],
              [-39.606357016780542, -8.111804797019749],
              [-39.605635123423596, -8.111839880038989],
              [-39.601958924888791, -8.112004520602616],
              [-39.595128719325615, -8.112357000329935],
              [-39.594779656074031, -8.112432765025691],
              [-39.591187910368447, -8.112885361907733],
              [-39.586174635488561, -8.113125154776606],
              [-39.585518348202115, -8.113152691066349],
              [-39.584913431066305, -8.113178072185335],
              [-39.582575899476723, -8.113276304501811],
              [-39.578961871914501, -8.113464348335828],
              [-39.575342667645685, -8.113654176664754],
              [-39.571722010707646, -8.113847683597923],
              [-39.571352422475712, -8.113855800017772],
              [-39.568312739902353, -8.114039235607381],
              [-39.56480196713332, -8.114216427858388],
              [-39.561345014208094, -8.114393968144615],
              [-39.561164674366196, -8.11440335219714],
              [-39.561103865124309, -8.11440642085492],
              [-39.559367083103304, -8.114496366325428],
              [-39.559321612318264, -8.114498690045512],
              [-39.557669871183947, -8.114584260340415],
              [-39.555578742988224, -8.114680734970426],
              [-39.554929530915743, -8.114710745268962],
              [-39.553929709274442, -8.114756789116187],
              [-39.550709999323274, -8.114930208537171],
              [-39.548590463731088, -8.117575606096381],
              [-39.547916029424655, -8.11875681439273],
              [-39.54444792552961, -8.119187855074722],
              [-39.543189233876625, -8.119311555452962],
              [-39.542461281667805, -8.119383259138235],
              [-39.541593474062836, -8.119468534441705],
              [-39.54026359000548, -8.119503316319571],
              [-39.539897349546031, -8.11950922929014],
              [-39.537274315687299, -8.119560005719473],
              [-39.537657094915851, -8.121615373168238],
              [-39.537736996462094, -8.121975536022854],
              [-39.538198571646149, -8.124442081273207],
              [-39.539051899234764, -8.128869003912568],
              [-39.539085400309745, -8.129078173756184],
              [-39.539274965311598, -8.13026129893098],
              [-39.539428412171965, -8.130227085620662],
              [-39.542660045492461, -8.129506771725028],
              [-39.546004183529568, -8.128759236853044],
              [-39.54978940241142, -8.127911582741419],
              [-39.550416158972205, -8.127772796705679],
              [-39.550971138064149, -8.127649935955953],
              [-39.552802856665188, -8.127244399417609],
              [-39.555567744781641, -8.126622397797794],
              [-39.559914968902397, -8.125650508629514],
              [-39.560281312404626, -8.125585060281345],
              [-39.560709333942896, -8.125487416436643],
              [-39.561775893482363, -8.125244259475204],
              [-39.565566271551212, -8.124439962597394],
              [-39.568663540925229, -8.123775985731859],
              [-39.571812717612012, -8.123111732112406],
              [-39.576876416038964, -8.122040972351632],
              [-39.579729233075959, -8.121459570416585],
              [-39.580485107007107, -8.121305631042421],
              [-39.581237259712502, -8.121152328773196],
              [-39.584718179697731, -8.120450147970658],
              [-39.58913874544465, -8.1195871147227],
              [-39.593604861303689, -8.118729033579232],
              [-39.59811732330779, -8.117861972499574],
              [-39.600618952145133, -8.117380258675174],
              [-39.601287255784925, -8.117251556151837],
              [-39.601370467434514, -8.117237322980138],
              [-39.601647234306029, -8.117189789398271],
              [-39.605688164017145, -8.116408999777565],
              [-39.606400562661015, -8.116271268903244],
              [-39.609220298687973, -8.115726438128803],
              [-39.609841489534688, -8.115598516021883],
              [-39.613796408546271, -8.114805747035085],
              [-39.614651874441911, -8.114626179336829],
              [-39.618490065894825, -8.113905178871352],
              [-39.618579714239395, -8.113886952688171],
              [-39.622568743528838, -8.113167766094822],
              [-39.624335517747639, -8.112834647705382],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0222000',
        uf: 'PE',
        nome_proje: 'PA OLHO D AGUA',
        municipio: 'OROCO',
        area_hecta: '126.3962',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '27/02/2007',
        forma_obte: 'Confisco',
        data_obten: '27/02/2007',
        area_calc_: 126.4871,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.51427252145983, -8.544959981624695],
              [-39.509901451826309, -8.531517920739931],
              [-39.500905301159698, -8.526528557925502],
              [-39.505780893590213, -8.541389338985807],
              [-39.506680784007685, -8.541632378669782],
              [-39.507636043806272, -8.542517538961128],
              [-39.508726588153777, -8.542633693811659],
              [-39.509290759535979, -8.543238967824113],
              [-39.511135396592806, -8.543281763819902],
              [-39.511902212917143, -8.543689223593157],
              [-39.512499350187944, -8.54400356187247],
              [-39.513272497260438, -8.544590463085891],
              [-39.51427252145983, -8.544959981624695],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0250000',
        uf: 'PE',
        nome_proje: 'PA SÃO FRANCISCO II',
        municipio: 'CABROBO',
        area_hecta: '397.6215',
        capacidade: 19.0,
        num_famili: 19.0,
        fase: 3.0,
        data_de_cr: '30/11/2007',
        forma_obte: 'Confisco',
        data_obten: '30/11/2007',
        area_calc_: 216.2569,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.241048056607113, -8.239819110194574],
              [-39.234085082819014, -8.266930503270912],
              [-39.234050565290325, -8.267834430212337],
              [-39.233899083894514, -8.268726304698522],
              [-39.224964484149005, -8.298249456465449],
              [-39.222816236403872, -8.305648526189836],
              [-39.22530844162398, -8.306468173750194],
              [-39.227728359149012, -8.298760127276953],
              [-39.236431639630801, -8.269045203586437],
              [-39.236512354131676, -8.268417133943698],
              [-39.236571747312475, -8.267514549067529],
              [-39.243405546373801, -8.241016022595076],
              [-39.243438607349958, -8.240884391893676],
              [-39.24283342914908, -8.240426880142049],
              [-39.241354646919866, -8.24185839375037],
              [-39.241081753976893, -8.239687660186116],
              [-39.241048056607113, -8.239819110194574],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0005000',
        uf: 'PE',
        nome_proje: 'PA SANTANA',
        municipio: 'CABROBO',
        area_hecta: '641.0000',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 5.0,
        data_de_cr: '12/09/1989',
        forma_obte: 'Adjudicação',
        data_obten: '11/09/1989',
        area_calc_: 161.2959,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.506000696192586, -8.279420763508451],
              [-39.507102233810258, -8.287851863297343],
              [-39.508436602277904, -8.296236526776314],
              [-39.509972485031952, -8.296971560835168],
              [-39.514380069978344, -8.296210902659158],
              [-39.516399438553087, -8.298206717325121],
              [-39.517207457357877, -8.299041527518638],
              [-39.515112355858484, -8.288682527815556],
              [-39.512603721416909, -8.279552453433588],
              [-39.506399664845723, -8.279470820169157],
              [-39.506000696192586, -8.279420763508451],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0179000',
        uf: 'PE',
        nome_proje: 'PA SANTA RITA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '159.9827',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 3.0,
        data_de_cr: '04/07/2006',
        forma_obte: 'Confisco',
        data_obten: '04/07/2006',
        area_calc_: 160.11,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.226625400655756, -8.598624357320491],
              [-39.226406901151158, -8.600275877334013],
              [-39.227189485905249, -8.601491007434202],
              [-39.226231150876764, -8.601988156086096],
              [-39.227276811389515, -8.603146688739388],
              [-39.228400663286166, -8.602527328434423],
              [-39.229390170595799, -8.604297618490744],
              [-39.230345208258449, -8.606161906344303],
              [-39.223171986794483, -8.608731366905332],
              [-39.22378117734965, -8.609337587279358],
              [-39.229688487439752, -8.61521278896976],
              [-39.230426015753125, -8.615945916543184],
              [-39.231852604090712, -8.61541609288442],
              [-39.232626407059762, -8.615119031881655],
              [-39.233305985552754, -8.614863272846881],
              [-39.235463209503507, -8.614062539160454],
              [-39.235769461435794, -8.615037428114439],
              [-39.24013818288077, -8.618675249285939],
              [-39.239658180666225, -8.611265058893293],
              [-39.226625400655756, -8.598624357320491],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0322000',
        uf: 'PE',
        nome_proje: 'PA BAIXA DO ICO II',
        municipio: 'TACARATU',
        area_hecta: '81.1439',
        capacidade: 5.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '30/10/2018',
        forma_obte: 'Desapropriação',
        data_obten: '07/04/2010',
        area_calc_: 81.1914,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.131964535178135, -9.117802788602365],
              [-38.130164281200599, -9.121184136498453],
              [-38.130768560858279, -9.122599531415108],
              [-38.130675932982413, -9.122782723716625],
              [-38.13133044084227, -9.123374232769949],
              [-38.133897163218613, -9.124837176116959],
              [-38.136163995063256, -9.125152329651664],
              [-38.138861650363566, -9.11912369993837],
              [-38.137681219733111, -9.11808716412582],
              [-38.140383177106983, -9.114446708217599],
              [-38.139688003468351, -9.113165506900966],
              [-38.13921823429088, -9.112722411313342],
              [-38.138186764214524, -9.112872273087225],
              [-38.1378427466611, -9.112358025303612],
              [-38.137245473664905, -9.112187847418424],
              [-38.136930572933913, -9.112016800711674],
              [-38.136131303927748, -9.112216949720059],
              [-38.136254958732714, -9.112752562730657],
              [-38.136025760617386, -9.112854307398161],
              [-38.136716756891396, -9.114239692489729],
              [-38.136410849032814, -9.11547619785166],
              [-38.135497215299033, -9.114793641892762],
              [-38.135173297566219, -9.114852382324564],
              [-38.13474979046994, -9.116551391905725],
              [-38.134687778744066, -9.117361955969798],
              [-38.132285939168206, -9.11758034754596],
              [-38.131964535178135, -9.117802788602365],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0215000',
        uf: 'PE',
        nome_proje: 'PA TERRA LIVRE',
        municipio: 'SAO JOSE DO BELMONTE',
        area_hecta: '1938.0000',
        capacidade: 102.0,
        num_famili: 93.0,
        fase: 4.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '04/11/1999',
        area_calc_: 2022.5141,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899341389600643, -7.892404815109754],
              [-38.905125295590416, -7.870252059780714],
              [-38.884577029623941, -7.851433349766438],
              [-38.884577270953535, -7.851446349912343],
              [-38.884577298166647, -7.851446375558628],
              [-38.884776001581272, -7.862148773789349],
              [-38.88481096030911, -7.86397857553075],
              [-38.884952169178064, -7.871366682254321],
              [-38.885003084369565, -7.873257323124823],
              [-38.885003117651905, -7.873260581453829],
              [-38.885003173615274, -7.873262660070124],
              [-38.885010697872644, -7.874002681923636],
              [-38.885017482641622, -7.874666907325489],
              [-38.885287275746492, -7.885874059080566],
              [-38.885287347856554, -7.885878484459821],
              [-38.885287454744287, -7.885882923840328],
              [-38.885471500731448, -7.897179597776485],
              [-38.885471501782092, -7.897179797915718],
              [-38.885471589909095, -7.897185206084276],
              [-38.885472652610773, -7.897399019624429],
              [-38.885474776317622, -7.897803565420419],
              [-38.885469715147998, -7.89809964302695],
              [-38.885463730361138, -7.898451105423709],
              [-38.885479144010198, -7.898643982136342],
              [-38.885495630602605, -7.89883495248344],
              [-38.88549656027476, -7.89886191812166],
              [-38.88549671132764, -7.898863808299763],
              [-38.885538292818083, -7.900071047783805],
              [-38.885614136479802, -7.904719220762288],
              [-38.885631704499879, -7.905794234554238],
              [-38.885671390141432, -7.908857576381791],
              [-38.885680209294136, -7.909537956057887],
              [-38.885701786840279, -7.910533908992983],
              [-38.885755731318014, -7.913022889345368],
              [-38.885758031936881, -7.913632985006874],
              [-38.885765624756452, -7.91564331511745],
              [-38.885831450294013, -7.920699717114987],
              [-38.885867355884272, -7.923456125744848],
              [-38.885867355435842, -7.92345775395401],
              [-38.885867199846906, -7.924504728387906],
              [-38.885866291497649, -7.930611923107742],
              [-38.885866290266456, -7.930612114558297],
              [-38.885866288879427, -7.930621420991279],
              [-38.885798036788451, -7.941225018407271],
              [-38.895496237660254, -7.951130690687702],
              [-38.902301629424329, -7.950142938894947],
              [-38.905070702784293, -7.947941248137835],
              [-38.910878500352645, -7.938527415675602],
              [-38.911358398321077, -7.936321203939482],
              [-38.911487278912631, -7.935698169844652],
              [-38.911424921079664, -7.934970618259084],
              [-38.896901770216161, -7.892163336264264],
              [-38.899341389600643, -7.892404815109754],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0254000',
        uf: 'PE',
        nome_proje: 'PA MARIA VIEIRA DE MATOS',
        municipio: 'OURICURI',
        area_hecta: '948.8588',
        capacidade: 66.0,
        num_famili: 65.0,
        fase: 3.0,
        data_de_cr: '20/12/2007',
        forma_obte: 'Doação',
        data_obten: '20/12/2007',
        area_calc_: 1083.2606,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.945019143859966, -8.155700253985161],
              [-39.94501911629969, -8.15570023334887],
              [-39.945870424449552, -8.155698247556984],
              [-39.94587049129678, -8.155698268102242],
              [-39.945870555609119, -8.155698267725112],
              [-39.945870619917144, -8.15569826689395],
              [-39.945870684217667, -8.155698265608784],
              [-39.945870748507446, -8.155698263869699],
              [-39.945870812783234, -8.155698261676738],
              [-39.945870877041813, -8.155698259030046],
              [-39.945870941279956, -8.155698255929764],
              [-39.945871005494453, -8.155698252376022],
              [-39.945871069682049, -8.155698248369005],
              [-39.94587113383956, -8.15569824390891],
              [-39.945871197963733, -8.15569823899596],
              [-39.945871262051362, -8.155698233630403],
              [-39.945871326099223, -8.155698227812531],
              [-39.945871390104109, -8.155698221542622],
              [-39.945871454062797, -8.155698214820983],
              [-39.945871517972101, -8.155698207647971],
              [-39.945871581828783, -8.15569820002391],
              [-39.945871645629659, -8.155698191949226],
              [-39.945871709371509, -8.155698183424276],
              [-39.945871773051138, -8.155698174449526],
              [-39.945871836665333, -8.155698165025433],
              [-39.945871900210939, -8.155698155152445],
              [-39.945871963684738, -8.155698144831074],
              [-39.94587202708356, -8.155698134061836],
              [-39.945872090404194, -8.155698122845239],
              [-39.945872153643492, -8.155698111181881],
              [-39.94587221679825, -8.155698099072328],
              [-39.945872279865327, -8.155698086517228],
              [-39.94587234284154, -8.155698073517177],
              [-39.945872405723733, -8.155698060072819],
              [-39.945872468508739, -8.155698046184856],
              [-39.945872531193423, -8.155698031853968],
              [-39.945872593774617, -8.155698017080869],
              [-39.9458726562492, -8.155698001866313],
              [-39.945872718614019, -8.155697986211074],
              [-39.945872780865955, -8.15569797011592],
              [-39.945872843001872, -8.155697953581651],
              [-39.945872905018661, -8.155697936609119],
              [-39.945872966913193, -8.155697919199191],
              [-39.945873028682392, -8.155697901352692],
              [-39.945873090323119, -8.155697883070545],
              [-39.945873151832295, -8.155697864353687],
              [-39.945873213206852, -8.155697845202988],
              [-39.945873274443663, -8.155697825619498],
              [-39.945873335539694, -8.155697805604163],
              [-39.945873396491862, -8.155697785157979],
              [-39.945873457297118, -8.155697764281985],
              [-39.945873517952393, -8.155697742977196],
              [-39.945873578454659, -8.155697721244719],
              [-39.945873638800862, -8.155697699085655],
              [-39.945873698987974, -8.155697676501065],
              [-39.945873759012997, -8.155697653492147],
              [-39.945873818872876, -8.155697630060011],
              [-39.94587387856464, -8.155697606205862],
              [-39.945873938085285, -8.155697581930855],
              [-39.945873997431811, -8.155697557236259],
              [-39.945874056601255, -8.155697532123275],
              [-39.945874115590634, -8.15569750659318],
              [-39.945874174396991, -8.155697480647254],
              [-39.945874233017371, -8.155697454286802],
              [-39.945874291448838, -8.155697427513163],
              [-39.945874349688452, -8.155697400327645],
              [-39.945874407733299, -8.155697372731646],
              [-39.945874465580438, -8.155697344726525],
              [-39.945874523226998, -8.155697316313686],
              [-39.945874580670065, -8.155697287494586],
              [-39.945874637906755, -8.155697258270644],
              [-39.945874694934218, -8.155697228643335],
              [-39.945874751749564, -8.155697198614163],
              [-39.945874808349934, -8.155697168184588],
              [-39.945874864732517, -8.155697137356192],
              [-39.945874920894482, -8.155697106130516],
              [-39.945874976832961, -8.155697074509087],
              [-39.945875032545203, -8.155697042493548],
              [-39.945875088028387, -8.155697010085467],
              [-39.945875143279714, -8.155696977286487],
              [-39.945875198296442, -8.155696944098263],
              [-39.945875253075783, -8.155696910522439],
              [-39.94587530761499, -8.155696876560686],
              [-39.945875361911334, -8.155696842214748],
              [-39.945875415962085, -8.155696807486331],
              [-39.945875469764523, -8.155696772377192],
              [-39.945875523315962, -8.155696736889077],
              [-39.945875576613709, -8.155696701023777],
              [-39.945875629655077, -8.155696664783079],
              [-39.945875682437418, -8.155696628168833],
              [-39.945875734958058, -8.155696591182837],
              [-39.945875787214391, -8.155696553826964],
              [-39.945875839203772, -8.155696516103111],
              [-39.945875890923602, -8.155696478013152],
              [-39.945875942371266, -8.155696439558978],
              [-39.945875993544213, -8.155696400742563],
              [-39.945876044439856, -8.155696361565843],
              [-39.945876095055631, -8.155696322030767],
              [-39.945876145389008, -8.155696282139328],
              [-39.945876195437457, -8.155696241893549],
              [-39.94587624519847, -8.155696201295394],
              [-39.945876294669546, -8.155696160346972],
              [-39.945876343848198, -8.155696119050276],
              [-39.945876319232667, -8.155696063160873],
              [-39.946797505435555, -8.154916914023577],
              [-39.946797568152697, -8.154916961023323],
              [-39.946797583867991, -8.154916947700825],
              [-39.946797599552859, -8.154916934342781],
              [-39.94679761520721, -8.154916920949248],
              [-39.94679763083095, -8.154916907520313],
              [-39.946797646424031, -8.154916894055999],
              [-39.946797661986345, -8.154916880556399],
              [-39.946797677517807, -8.15491686702158],
              [-39.946797693018368, -8.154916853451638],
              [-39.946797708487942, -8.15491683984663],
              [-39.94679772392643, -8.154916826206598],
              [-39.946797739333753, -8.154916812531669],
              [-39.946797754709841, -8.154916798821825],
              [-39.946797770054609, -8.154916785077214],
              [-39.946797785367991, -8.15491677129789],
              [-39.946797800649897, -8.154916757483887],
              [-39.946797815900233, -8.154916743635317],
              [-39.94679783111895, -8.154916729752239],
              [-39.946797846305948, -8.154916715834716],
              [-39.946797861461171, -8.154916701882849],
              [-39.946797876584512, -8.154916687896693],
              [-39.946797891675914, -8.154916673876309],
              [-39.946797906735284, -8.154916659821755],
              [-39.946797921762553, -8.154916645733142],
              [-39.94679793675764, -8.154916631610524],
              [-39.946797951720484, -8.15491661745399],
              [-39.946797966650969, -8.154916603263599],
              [-39.946797981549054, -8.154916589039455],
              [-39.946797996414659, -8.154916574781549],
              [-39.946798011247687, -8.154916560490038],
              [-39.946798026048079, -8.154916546164962],
              [-39.94679804081575, -8.154916531806407],
              [-39.94679805555063, -8.154916517414419],
              [-39.946798070252648, -8.154916502989115],
              [-39.946798084921681, -8.154916488530505],
              [-39.946798099557725, -8.154916474038732],
              [-39.946798114160657, -8.154916459513867],
              [-39.946798128730428, -8.154916444955971],
              [-39.946798143266925, -8.154916430365059],
              [-39.946798157770125, -8.154916415741281],
              [-39.946798172239909, -8.154916401084702],
              [-39.946798186676212, -8.154916386395369],
              [-39.946798201078977, -8.154916371673361],
              [-39.946798215448112, -8.154916356918786],
              [-39.946798229783553, -8.15491634213171],
              [-39.946798244085223, -8.154916327312197],
              [-39.946798258353056, -8.154916312460333],
              [-39.94679827258696, -8.154916297576172],
              [-39.946798286786873, -8.154916282659832],
              [-39.946798300952722, -8.154916267711359],
              [-39.946798315084429, -8.154916252730821],
              [-39.946798329181924, -8.154916237718325],
              [-39.946798343245135, -8.154916222673915],
              [-39.946798357273984, -8.154916207597712],
              [-39.946798371268422, -8.154916192489784],
              [-39.946798385228327, -8.154916177350181],
              [-39.946798399153678, -8.154916162178987],
              [-39.94679841304437, -8.154916146976305],
              [-39.946798426900337, -8.154916131742185],
              [-39.94679844072153, -8.154916116476729],
              [-39.946798454507856, -8.154916101180007],
              [-39.946798468259253, -8.154916085852125],
              [-39.946798481975634, -8.154916070493078],
              [-39.946798495656957, -8.154916055103032],
              [-39.946798509303122, -8.154916039682037],
              [-39.946798522914072, -8.154916024230165],
              [-39.946798536489737, -8.154916008747508],
              [-39.946798550030046, -8.154915993234138],
              [-39.946798563534919, -8.154915977690147],
              [-39.946798577004309, -8.154915962115622],
              [-39.946798590438142, -8.154915946510588],
              [-39.946798603836321, -8.154915930875182],
              [-39.946798617198802, -8.154915915209465],
              [-39.9467986305255, -8.154915899513526],
              [-39.946798643816365, -8.154915883787465],
              [-39.946798657071326, -8.154915868031289],
              [-39.946798670290306, -8.154915852245153],
              [-39.946798683473226, -8.154915836429129],
              [-39.946798696620029, -8.154915820583275],
              [-39.946798709730651, -8.154915804707688],
              [-39.946798722805028, -8.154915788802453],
              [-39.946798735843082, -8.154915772867634],
              [-39.946798748844749, -8.154915756903319],
              [-39.946798761809966, -8.154915740909606],
              [-39.946798774738653, -8.154915724886552],
              [-39.946798787630755, -8.154915708834269],
              [-39.946798800486206, -8.154915692752823],
              [-39.946798813304945, -8.154915676642288],
              [-39.946798826086884, -8.154915660502763],
              [-39.946798838831967, -8.154915644334331],
              [-39.946798851540137, -8.154915628137079],
              [-39.946798864211331, -8.154915611911068],
              [-39.946798876845463, -8.154915595656419],
              [-39.946798889442483, -8.15491557937319],
              [-39.946798902002328, -8.154915563061474],
              [-39.946798914524926, -8.154915546721339],
              [-39.946798927010207, -8.154915530352895],
              [-39.94679893945812, -8.154915513956215],
              [-39.946798951868594, -8.154915497531352],
              [-39.946798964241552, -8.154915481078429],
              [-39.946798954307425, -8.154915463546688],
              [-39.94743668424325, -8.154065344174048],
              [-39.947436731631967, -8.15406539896513],
              [-39.947436751214148, -8.154065372961513],
              [-39.947436770890015, -8.154065347028196],
              [-39.947436790659317, -8.154065321165515],
              [-39.947436810521786, -8.154065295373831],
              [-39.947436830477159, -8.154065269653422],
              [-39.947436850525193, -8.154065244004645],
              [-39.947436870665641, -8.154065218427856],
              [-39.947436890898189, -8.154065192923374],
              [-39.947436911222646, -8.154065167491522],
              [-39.947436931638684, -8.154065142132618],
              [-39.947436952146099, -8.154065116847022],
              [-39.947436972744583, -8.154065091635031],
              [-39.94743699343389, -8.154065066497001],
              [-39.947437014213747, -8.154065041433222],
              [-39.947437035083873, -8.154065016444031],
              [-39.947437056044031, -8.154064991529744],
              [-39.947437077093909, -8.154064966690701],
              [-39.947437098233287, -8.154064941927206],
              [-39.947437119461846, -8.154064917239602],
              [-39.947437140779314, -8.154064892628165],
              [-39.947437162185437, -8.154064868093279],
              [-39.947437183679938, -8.154064843635187],
              [-39.947437205262531, -8.154064819254264],
              [-39.947437226932927, -8.154064794950784],
              [-39.947437248690861, -8.154064770725078],
              [-39.947437270536035, -8.154064746577452],
              [-39.947437292468202, -8.154064722508267],
              [-39.94743731448704, -8.15406469851777],
              [-39.947437336592287, -8.154064674606277],
              [-39.947437358783638, -8.154064650774131],
              [-39.947437381060823, -8.154064627021606],
              [-39.947437403423564, -8.154064603349013],
              [-39.947437425871556, -8.154064579756703],
              [-39.9474374484045, -8.154064556244919],
              [-39.947437471022106, -8.154064532814017],
              [-39.947437493724109, -8.154064509464252],
              [-39.947437516510199, -8.154064486195962],
              [-39.947437539380076, -8.154064463009448],
              [-39.947437562333448, -8.154064439904987],
              [-39.947437585370011, -8.154064416882894],
              [-39.947437608489494, -8.154064393943456],
              [-39.947437631691578, -8.154064371086962],
              [-39.947437654975943, -8.15406434831373],
              [-39.947437678342325, -8.154064325624036],
              [-39.94743770179042, -8.154064303018199],
              [-39.947437725319908, -8.154064280496481],
              [-39.947437748930469, -8.154064258059179],
              [-39.947437772621839, -8.154064235706608],
              [-39.947437796393679, -8.15406421343901],
              [-39.947437820245703, -8.154064191256705],
              [-39.947437844177585, -8.154064169159975],
              [-39.947437868189034, -8.154064147149107],
              [-39.947437892279737, -8.154064125224396],
              [-39.947437916449367, -8.154064103386089],
              [-39.947437940697618, -8.154064081634507],
              [-39.947437965024179, -8.154064059969924],
              [-39.947437989428742, -8.15406403839259],
              [-39.947438013910997, -8.154064016902808],
              [-39.947438038470601, -8.15406399550087],
              [-39.947438063107249, -8.154063974187023],
              [-39.947438087820643, -8.154063952961561],
              [-39.947438112610435, -8.15406393182475],
              [-39.947438137476325, -8.154063910776861],
              [-39.947438162417981, -8.154063889818181],
              [-39.947438187435068, -8.154063868948951],
              [-39.947438212527288, -8.154063848169466],
              [-39.947438237694314, -8.154063827479986],
              [-39.947438262935812, -8.154063806880782],
              [-39.947438288251455, -8.154063786372134],
              [-39.947438313640902, -8.154063765954284],
              [-39.947438339103854, -8.154063745627507],
              [-39.947438364639964, -8.154063725392049],
              [-39.947438390248934, -8.154063705248182],
              [-39.947438415930392, -8.154063685196192],
              [-39.947438441684007, -8.154063665236306],
              [-39.947438467509471, -8.154063645368799],
              [-39.947438493406423, -8.154063625593901],
              [-39.94743851937455, -8.154063605911878],
              [-39.947438545413533, -8.154063586323023],
              [-39.947438571522987, -8.15406356682754],
              [-39.947438597702607, -8.154063547425702],
              [-39.94743862395206, -8.154063528117767],
              [-39.947438650270989, -8.15406350890396],
              [-39.947438676659068, -8.154063489784564],
              [-39.947438703115935, -8.154063470759786],
              [-39.94743872964127, -8.154063451829895],
              [-39.947438756234703, -8.154063432995144],
              [-39.947438782895937, -8.154063414255754],
              [-39.947438809624593, -8.154063395611963],
              [-39.947438836420332, -8.154063377064052],
              [-39.947438863282798, -8.154063358612209],
              [-39.947438890211671, -8.154063340256696],
              [-39.947438917206568, -8.154063321997789],
              [-39.947438944267162, -8.154063303835665],
              [-39.947438971393112, -8.15406328577059],
              [-39.947438998584047, -8.154063267802769],
              [-39.947439025839621, -8.154063249932474],
              [-39.947439053159499, -8.154063232159917],
              [-39.947439080543546, -8.154063214484083],
              [-39.947439039077238, -8.154063168031824],
              [-39.948431802881416, -8.153424940085813],
              [-39.948431839970823, -8.153424966696823],
              [-39.948431879156267, -8.153424941361775],
              [-39.948431918210339, -8.153424915826106],
              [-39.948431957132016, -8.153424890090516],
              [-39.948431995920259, -8.153424864155694],
              [-39.94843203457404, -8.153424838022298],
              [-39.948432073092341, -8.153424811691057],
              [-39.948432111474141, -8.153424785162628],
              [-39.9484321497184, -8.153424758437721],
              [-39.948432187824146, -8.153424731517083],
              [-39.948432225790341, -8.153424704401385],
              [-39.948432263615985, -8.153424677091342],
              [-39.94843230130008, -8.15342464958773],
              [-39.948432338841634, -8.153424621891226],
              [-39.948432376239637, -8.153424594002567],
              [-39.948432413493116, -8.153424565922522],
              [-39.948432450601068, -8.153424537651798],
              [-39.948432487562521, -8.153424509191167],
              [-39.948432524376493, -8.153424480541382],
              [-39.948432561042011, -8.153424451703195],
              [-39.94843259755811, -8.15342442267737],
              [-39.948432633923794, -8.153424393464704],
              [-39.948432670138146, -8.153424364065918],
              [-39.948432706200158, -8.153424334481823],
              [-39.948432742108906, -8.153424304713178],
              [-39.948432777863431, -8.153424274760807],
              [-39.948432813462794, -8.153424244625473],
              [-39.948432848906037, -8.153424214308012],
              [-39.948432884192236, -8.153424183809191],
              [-39.948432919320439, -8.15342415312983],
              [-39.948432954289729, -8.153424122270719],
              [-39.948432989099182, -8.153424091232726],
              [-39.948433023747853, -8.153424060016651],
              [-39.948433058234855, -8.153424028623295],
              [-39.948433092559277, -8.153423997053519],
              [-39.948433126720161, -8.153423965308145],
              [-39.948433160716668, -8.153423933388007],
              [-39.948433194547867, -8.153423901293962],
              [-39.948433228212842, -8.153423869026874],
              [-39.948433261710726, -8.153423836587571],
              [-39.94843329504063, -8.153423803976914],
              [-39.948433328201659, -8.153423771195776],
              [-39.948433361192947, -8.153423738245024],
              [-39.94843339401362, -8.153423705125554],
              [-39.948433426662788, -8.153423671838206],
              [-39.948433459139629, -8.153423638383876],
              [-39.948433491443247, -8.153423604763459],
              [-39.948433523572795, -8.15342357097783],
              [-39.948433555527416, -8.153423537027889],
              [-39.948433587306283, -8.153423502914551],
              [-39.948433618908538, -8.153423468638699],
              [-39.948433650333357, -8.153423434201255],
              [-39.948433681579871, -8.153423399603126],
              [-39.948433712647308, -8.153423364845207],
              [-39.948433743534807, -8.153423329928428],
              [-39.948433774241572, -8.153423294853733],
              [-39.948433804766765, -8.15342325962205],
              [-39.948433835109597, -8.153423224234292],
              [-39.948433865269273, -8.153423188691404],
              [-39.948433895244968, -8.153423152994351],
              [-39.9484339250359, -8.153423117144037],
              [-39.948433954641274, -8.153423081141444],
              [-39.948433984060323, -8.153423044987491],
              [-39.94843401329225, -8.153423008683182],
              [-39.948434042336295, -8.153422972229428],
              [-39.948434071191663, -8.153422935627264],
              [-39.948434099857629, -8.153422898877599],
              [-39.94843412833341, -8.15342286198141],
              [-39.948434156618248, -8.1534228249397],
              [-39.948434184711402, -8.153422787753454],
              [-39.948434212612135, -8.153422750423623],
              [-39.948434240319685, -8.153422712951217],
              [-39.948434267833335, -8.15342267533722],
              [-39.94843429515236, -8.153422637582642],
              [-39.948434322276029, -8.15342259968846],
              [-39.94843434920363, -8.153422561655702],
              [-39.948434375934454, -8.153422523485366],
              [-39.948434402467761, -8.153422485178465],
              [-39.948434428802884, -8.153422446736013],
              [-39.948434454939111, -8.153422408159017],
              [-39.948434480875754, -8.15342236944851],
              [-39.948434506612124, -8.153422330605519],
              [-39.948434532147537, -8.153422291631042],
              [-39.948434557481328, -8.153422252526175],
              [-39.948434582612805, -8.153422213291915],
              [-39.948434607541316, -8.153422173929272],
              [-39.948434632266192, -8.15342213443934],
              [-39.948434656786802, -8.153422094823153],
              [-39.948434681102476, -8.153422055081739],
              [-39.94843470521257, -8.153422015216162],
              [-39.948434729116443, -8.153421975227461],
              [-39.94843475281349, -8.153421935116727],
              [-39.948434776303046, -8.153421894884994],
              [-39.948434799584497, -8.153421854533351],
              [-39.948434822657248, -8.153421814062854],
              [-39.948434845520673, -8.153421773474587],
              [-39.948434868174168, -8.15342173276963],
              [-39.948434890617129, -8.153421691949028],
              [-39.94843491284896, -8.153421651013872],
              [-39.948434934869091, -8.15342160996528],
              [-39.948434881070789, -8.153421586484205],
              [-39.948999228183133, -8.152363165204022],
              [-39.948999220105833, -8.152363158135355],
              [-39.948999261596555, -8.152363081208168],
              [-39.948999303830803, -8.152363004683753],
              [-39.948999346804676, -8.152362928569262],
              [-39.948999390514118, -8.152362852871818],
              [-39.948999434955077, -8.152362777598434],
              [-39.948999480123419, -8.152362702756145],
              [-39.948999526014916, -8.152362628351936],
              [-39.94899957262529, -8.152362554392777],
              [-39.948999619950179, -8.152362480885515],
              [-39.948999667985213, -8.152362407836996],
              [-39.948999716725886, -8.152362335254073],
              [-39.948999766167638, -8.152362263143504],
              [-39.948999816305914, -8.152362191511992],
              [-39.948999867135981, -8.152362120366227],
              [-39.948999918653129, -8.152362049712833],
              [-39.94899997085254, -8.152361979558417],
              [-39.949000023729354, -8.152361909909491],
              [-39.949000077278633, -8.152361840772597],
              [-39.949000131495424, -8.152361772154157],
              [-39.949000186374612, -8.152361704060539],
              [-39.949000241911115, -8.152361636498117],
              [-39.949000298099747, -8.152361569473197],
              [-39.949000354935279, -8.152361502992031],
              [-39.949000412412389, -8.152361437060829],
              [-39.949000470525739, -8.152361371685695],
              [-39.949000529269902, -8.152361306872745],
              [-39.949000588639393, -8.152361242628022],
              [-39.94900064862869, -8.152361178957543],
              [-39.949000709232195, -8.152361115867205],
              [-39.94900077044425, -8.152361053362924],
              [-39.949000832259159, -8.152360991450493],
              [-39.949000894671158, -8.152360930135719],
              [-39.949000957674421, -8.152360869424275],
              [-39.949001021263065, -8.152360809321886],
              [-39.949001085431185, -8.152360749834093],
              [-39.949001150172776, -8.152360690966482],
              [-39.949001215481815, -8.152360632724534],
              [-39.949001281352203, -8.152360575113663],
              [-39.949001347777802, -8.152360518139258],
              [-39.949001414752423, -8.152360461806609],
              [-39.949001482269821, -8.152360406121002],
              [-39.949001550323686, -8.152360351087607],
              [-39.949001618907687, -8.152360296711581],
              [-39.949001688015436, -8.15236024299795],
              [-39.949001757640474, -8.152360189951759],
              [-39.949001827776307, -8.152360137577936],
              [-39.949001898416405, -8.152360085881385],
              [-39.949001969554182, -8.152360034866884],
              [-39.949002041183014, -8.15235998453924],
              [-39.949002113296181, -8.152359934903116],
              [-39.949002185887011, -8.152359885963156],
              [-39.94900225894871, -8.152359837723912],
              [-39.949002332474471, -8.152359790189877],
              [-39.949002406457431, -8.152359743365485],
              [-39.949002480890698, -8.152359697255099],
              [-39.949002555767329, -8.152359651863046],
              [-39.949002631080354, -8.152359607193517],
              [-39.949002706822732, -8.152359563250696],
              [-39.949002782987414, -8.152359520038688],
              [-39.949002859567294, -8.152359477561507],
              [-39.949002936555239, -8.152359435823156],
              [-39.949003013944051, -8.152359394827464],
              [-39.949003091726532, -8.152359354578286],
              [-39.949003169895434, -8.152359315079362],
              [-39.949003248443432, -8.152359276334399],
              [-39.949003327363258, -8.152359238347008],
              [-39.949003406647513, -8.152359201120701],
              [-39.949003486288809, -8.152359164658971],
              [-39.949003566279735, -8.152359128965205],
              [-39.949003646612837, -8.152359094042751],
              [-39.949003727280605, -8.152359059894867],
              [-39.949003808275513, -8.152359026524701],
              [-39.949003889590031, -8.152358993935405],
              [-39.949003971216577, -8.152358962129979],
              [-39.949004053147512, -8.15235893111142],
              [-39.949004135375212, -8.152358900882597],
              [-39.949004217892032, -8.152358871446337],
              [-39.949004300690248, -8.152358842805407],
              [-39.949004383762151, -8.152358814962453],
              [-39.949004467100004, -8.152358787920065],
              [-39.949004550696024, -8.152358761680816],
              [-39.949004634542419, -8.152358736247091],
              [-39.949004718631365, -8.152358711621284],
              [-39.949004802955031, -8.152358687805691],
              [-39.949004887505552, -8.152358664802534],
              [-39.949004972275048, -8.152358642613958],
              [-39.949005057255626, -8.152358621242003],
              [-39.949005142439326, -8.152358600688702],
              [-39.94900522781824, -8.152358580955948],
              [-39.949005313384404, -8.152358562045599],
              [-39.949005399129817, -8.152358543959403],
              [-39.949005485046506, -8.152358526699089],
              [-39.949005571126456, -8.152358510266202],
              [-39.949005657361617, -8.152358494662311],
              [-39.949005743743989, -8.15235847988885],
              [-39.949005830265484, -8.152358465947218],
              [-39.949005916918061, -8.152358452838719],
              [-39.949006003693619, -8.152358440564566],
              [-39.949006090584405, -8.152358429125144],
              [-39.949006024593963, -8.152358446248172],
              [-39.949565708849519, -8.152287494635246],
              [-39.949651938494313, -8.15259570411021],
              [-39.949780404688838, -8.152608698357231],
              [-39.964995263955501, -8.154142227094326],
              [-39.966078711185077, -8.151742912077003],
              [-39.94586044477952, -8.146890808668909],
              [-39.945213427585188, -8.146836241597731],
              [-39.94521340832501, -8.146836216151625],
              [-39.945213375527075, -8.146836254617719],
              [-39.945213342945088, -8.146836293265588],
              [-39.94521331058008, -8.146836332094045],
              [-39.945213278433023, -8.146836371101882],
              [-39.945213246504956, -8.146836410287884],
              [-39.945213214796851, -8.146836449650827],
              [-39.945213183309697, -8.146836489189489],
              [-39.945213152044481, -8.146836528902632],
              [-39.945213121002176, -8.146836568789025],
              [-39.945213090183742, -8.146836608847405],
              [-39.945213059590152, -8.146836649076553],
              [-39.945213029222337, -8.146836689475204],
              [-39.945212999081278, -8.146836730042111],
              [-39.945212969167876, -8.146836770776007],
              [-39.945212939483064, -8.146836811675595],
              [-39.945212910027799, -8.146836852739622],
              [-39.945212880802998, -8.146836893966809],
              [-39.945212851809544, -8.146836935355873],
              [-39.94521282304833, -8.146836976905531],
              [-39.945212794520288, -8.146837018614479],
              [-39.945212766226291, -8.146837060481408],
              [-39.945212738167214, -8.146837102505071],
              [-39.945212710343938, -8.146837144684092],
              [-39.945212682757337, -8.146837187017184],
              [-39.945212655408262, -8.146837229503017],
              [-39.945212628297554, -8.146837272140269],
              [-39.945212601426071, -8.14683731492762],
              [-39.945212574794645, -8.146837357863769],
              [-39.945212548404101, -8.146837400947319],
              [-39.945212522255282, -8.146837444176954],
              [-39.945212496348979, -8.146837487551323],
              [-39.945212470686016, -8.146837531069114],
              [-39.945212445267174, -8.146837574728918],
              [-39.945212420093249, -8.146837618529377],
              [-39.945212395165044, -8.146837662469171],
              [-39.945212370483318, -8.146837706546904],
              [-39.945212346048834, -8.146837750761206],
              [-39.945212321862378, -8.146837795110692],
              [-39.94521229792467, -8.146837839593967],
              [-39.945212274236496, -8.146837884209695],
              [-39.945212250798541, -8.146837928956442],
              [-39.945212227611584, -8.146837973832843],
              [-39.945212204676338, -8.146838018837489],
              [-39.945212181993504, -8.146838063968984],
              [-39.945212159563781, -8.146838109225879],
              [-39.945212137387884, -8.146838154606817],
              [-39.945212115466489, -8.146838200110373],
              [-39.945212093800301, -8.14683824573512],
              [-39.945212072389971, -8.146838291479652],
              [-39.94521205123619, -8.14683833734254],
              [-39.945212030339597, -8.146838383322331],
              [-39.945212009700846, -8.146838429417627],
              [-39.945211989320597, -8.146838475626975],
              [-39.945211969199455, -8.146838521948927],
              [-39.945211949338074, -8.14683856838205],
              [-39.945211929737056, -8.146838614924894],
              [-39.945211910397013, -8.146838661576023],
              [-39.945211891318557, -8.146838708333991],
              [-39.945211872502277, -8.146838755197324],
              [-39.945211853948756, -8.146838802164545],
              [-39.945211835658576, -8.146838849234218],
              [-39.945211817632298, -8.146838896404859],
              [-39.945211799870506, -8.146838943675037],
              [-39.945211782373718, -8.14683899104323],
              [-39.945211765142517, -8.146839038507977],
              [-39.945211748177414, -8.146839086067821],
              [-39.945211731478942, -8.146839133721251],
              [-39.945211715047627, -8.146839181466817],
              [-39.945211698883973, -8.146839229303028],
              [-39.945211682988493, -8.146839277228359],
              [-39.945211667361683, -8.146839325241336],
              [-39.945211652004019, -8.146839373340461],
              [-39.945211636915985, -8.146839421524254],
              [-39.945211622098036, -8.146839469791209],
              [-39.945211607550661, -8.146839518139791],
              [-39.945211593274294, -8.146839566568541],
              [-39.945211579269383, -8.146839615075901],
              [-39.945211565536376, -8.146839663660408],
              [-39.945211552075683, -8.14683971232053],
              [-39.945211538887747, -8.146839761054725],
              [-39.945211525972951, -8.14683980986152],
              [-39.945211513331706, -8.146839858739371],
              [-39.945211500964419, -8.146839907686751],
              [-39.945211488871458, -8.146839956702141],
              [-39.945211477053199, -8.146840005784021],
              [-39.945211465510035, -8.146840054930864],
              [-39.945211454242298, -8.146840104141129],
              [-39.945211443250358, -8.146840153413285],
              [-39.945211432534542, -8.146840202745778],
              [-39.945211422095191, -8.146840252137149],
              [-39.945211411932632, -8.14684030158576],
              [-39.945211402047185, -8.146840351090125],
              [-39.945211392439134, -8.146840400648699],
              [-39.945211383108813, -8.146840450259925],
              [-39.945211374056491, -8.146840499922272],
              [-39.945211365282454, -8.14684054963419],
              [-39.945211356786984, -8.146840599394109],
              [-39.945211348570332, -8.146840649200517],
              [-39.945211340632753, -8.146840699051825],
              [-39.945211350376901, -8.146840678161855],
              [-39.945001650325629, -8.148181808707774],
              [-39.945001643877021, -8.148181851264235],
              [-39.945001632293582, -8.148181923429814],
              [-39.945001620125332, -8.148181995499771],
              [-39.945001607373015, -8.148182067469421],
              [-39.9450015940375, -8.148182139334022],
              [-39.945001580119673, -8.1481822110889],
              [-39.945001565620387, -8.148182282729381],
              [-39.945001550540617, -8.148182354250798],
              [-39.945001534881371, -8.148182425648447],
              [-39.945001518643643, -8.148182496917673],
              [-39.9450015018285, -8.14818256805383],
              [-39.945001484437043, -8.148182639052282],
              [-39.945001466470401, -8.14818270990839],
              [-39.945001447929748, -8.148182780617518],
              [-39.945001428816305, -8.148182851175051],
              [-39.945001409131308, -8.148182921576387],
              [-39.945001388876044, -8.148182991816938],
              [-39.945001368051862, -8.148183061892103],
              [-39.945001346660078, -8.148183131797278],
              [-39.945001324702112, -8.148183201527967],
              [-39.945001302179392, -8.148183271079581],
              [-39.945001279093397, -8.148183340447549],
              [-39.945001255445639, -8.148183409627391],
              [-39.945001231237626, -8.148183478614552],
              [-39.945001206470984, -8.148183547404566],
              [-39.945001181147305, -8.148183615992911],
              [-39.945001155268258, -8.148183684375123],
              [-39.945001128835514, -8.14818375254673],
              [-39.945001101850806, -8.148183820503283],
              [-39.945001074315911, -8.148183888240331],
              [-39.945001046232598, -8.148183955753485],
              [-39.945001017602721, -8.148184023038302],
              [-39.945000988428156, -8.148184090090403],
              [-39.945000958710793, -8.148184156905401],
              [-39.945000928452572, -8.14818422347895],
              [-39.945000897655476, -8.148184289806698],
              [-39.945000866321507, -8.148184355884323],
              [-39.945000834452728, -8.148184421707501],
              [-39.945000802051204, -8.14818448727191],
              [-39.945000769119048, -8.148184552573309],
              [-39.945000735658418, -8.148184617607399],
              [-39.945000701671518, -8.14818468236995],
              [-39.945000667160528, -8.148184746856762],
              [-39.945000632127737, -8.148184811063578],
              [-39.945000596575397, -8.148184874986221],
              [-39.945000560505861, -8.148184938620535],
              [-39.945000523921472, -8.148185001962332],
              [-39.945000486824618, -8.1481850650075],
              [-39.94500044921773, -8.148185127751917],
              [-39.945000411103237, -8.148185190191468],
              [-39.945000372483655, -8.148185252322106],
              [-39.945000333361506, -8.148185314139782],
              [-39.945000293739326, -8.148185375640431],
              [-39.945000253619725, -8.148185436820034],
              [-39.945000213005294, -8.14818549767463],
              [-39.945000171898698, -8.148185558200183],
              [-39.945000130302638, -8.148185618392818],
              [-39.945000088219814, -8.148185678248542],
              [-39.945000045652975, -8.148185737763484],
              [-39.945000002604885, -8.148185796933724],
              [-39.944999959078388, -8.14818585575545],
              [-39.944999915076295, -8.148185914224785],
              [-39.944999870601507, -8.148185972337922],
              [-39.944999825656893, -8.148186030091056],
              [-39.944999780245432, -8.148186087480438],
              [-39.94499973437005, -8.148186144502301],
              [-39.944999688033754, -8.148186201152937],
              [-39.944999641239576, -8.148186257428621],
              [-39.944999593990566, -8.148186313325692],
              [-39.944999546289836, -8.148186368840504],
              [-39.944999498140454, -8.148186423969436],
              [-39.944999449545598, -8.14818647870888],
              [-39.944999400508429, -8.148186533055261],
              [-39.944999351032145, -8.148186587005046],
              [-39.944999301119971, -8.148186640554687],
              [-39.944999250775183, -8.148186693700698],
              [-39.944999200001064, -8.148186746439626],
              [-39.944999148800925, -8.148186798768011],
              [-39.944999097178098, -8.148186850682437],
              [-39.94499904513598, -8.148186902179525],
              [-39.944998992677959, -8.148186953255891],
              [-39.944998939807448, -8.148187003908232],
              [-39.944998886527912, -8.148187054133224],
              [-39.94499883284282, -8.148187103927576],
              [-39.944998778755689, -8.148187153288029],
              [-39.944998724270043, -8.148187202211405],
              [-39.944998669389435, -8.148187250694445],
              [-39.944998614117466, -8.148187298734058],
              [-39.944998558457719, -8.148187346327036],
              [-39.944998502413853, -8.148187393470316],
              [-39.944998445989519, -8.148187440160818],
              [-39.944998389188399, -8.148187486395473],
              [-39.944998332014194, -8.148187532171276],
              [-39.944998274470656, -8.148187577485224],
              [-39.944998216561515, -8.148187622334394],
              [-39.944998158290588, -8.148187666715804],
              [-39.944998099661646, -8.148187710626592],
              [-39.944998040678541, -8.148187754063876],
              [-39.944997981345111, -8.148187797024839],
              [-39.944997921665099, -8.148187839506964],
              [-39.944997852166793, -8.148187786817692],
              [-39.943791928663543, -8.149038953214545],
              [-39.943791936615298, -8.149038978529092],
              [-39.943791930026123, -8.14903898318385],
              [-39.943791923441104, -8.149038987844445],
              [-39.943791916860235, -8.149038992510878],
              [-39.943791910283551, -8.149038997183148],
              [-39.94379190371103, -8.149039001861247],
              [-39.943791897142695, -8.149039006545188],
              [-39.943791890578545, -8.149039011234947],
              [-39.943791884018587, -8.149039015930525],
              [-39.943791877462829, -8.149039020631919],
              [-39.943791870911276, -8.149039025339123],
              [-39.943791864363916, -8.14903903005214],
              [-39.943791857820777, -8.149039034770944],
              [-39.943791851281851, -8.149039039495547],
              [-39.94379184474716, -8.149039044225937],
              [-39.943791838216676, -8.149039048962148],
              [-39.943791831690433, -8.149039053704142],
              [-39.943791825168425, -8.149039058451923],
              [-39.943791818650666, -8.149039063205473],
              [-39.943791812137142, -8.149039067964804],
              [-39.943791805627882, -8.149039072729924],
              [-39.943791799122877, -8.149039077500797],
              [-39.943791792622129, -8.149039082277437],
              [-39.943791786125665, -8.149039087059823],
              [-39.943791779633472, -8.149039091848],
              [-39.943791773145549, -8.149039096641928],
              [-39.943791766661896, -8.149039101441589],
              [-39.943791760182549, -8.149039106247002],
              [-39.943791753707501, -8.149039111058167],
              [-39.943791747236745, -8.149039115875086],
              [-39.943791740770287, -8.14903912069772],
              [-39.943791734308149, -8.14903912552607],
              [-39.943791727850325, -8.149039130360158],
              [-39.943791721396806, -8.149039135199978],
              [-39.943791714947636, -8.149039140045517],
              [-39.943791708502786, -8.14903914489677],
              [-39.943791702062271, -8.149039149753744],
              [-39.943791695626089, -8.149039154616437],
              [-39.943791689194263, -8.149039159484813],
              [-39.943791682766786, -8.149039164358891],
              [-39.943791676343658, -8.149039169238666],
              [-39.943791669924885, -8.149039174124129],
              [-39.943791663510488, -8.149039179015288],
              [-39.943791657100455, -8.149039183912137],
              [-39.943791650694806, -8.149039188814664],
              [-39.943791644293526, -8.149039193722896],
              [-39.943791637896645, -8.149039198636775],
              [-39.943791631504141, -8.149039203556322],
              [-39.943791625116049, -8.149039208481536],
              [-39.943791618732348, -8.149039213412419],
              [-39.943791612353053, -8.149039218348967],
              [-39.943791605978177, -8.149039223291165],
              [-39.943791599607714, -8.149039228239012],
              [-39.943791593241663, -8.149039233192529],
              [-39.943791586880046, -8.14903923815166],
              [-39.943791580522849, -8.149039243116427],
              [-39.943791574170106, -8.149039248086826],
              [-39.943791567821791, -8.149039253062854],
              [-39.94379156147793, -8.149039258044537],
              [-39.943791555138517, -8.149039263031833],
              [-39.943791548803574, -8.149039268024747],
              [-39.943791542473079, -8.149039273023277],
              [-39.943791536147046, -8.149039278027406],
              [-39.943791529825496, -8.149039283037153],
              [-39.943791523508402, -8.149039288052499],
              [-39.943791517195812, -8.149039293073441],
              [-39.943791510887692, -8.149039298099988],
              [-39.943791504584077, -8.149039303132115],
              [-39.943791498284959, -8.14903930816984],
              [-39.943791491990325, -8.149039313213168],
              [-39.943791485700224, -8.149039318262057],
              [-39.943791479414614, -8.149039323316513],
              [-39.943791473133537, -8.149039328376535],
              [-39.943791466856958, -8.149039333442122],
              [-39.943791460584926, -8.149039338513278],
              [-39.943791454317406, -8.149039343590013],
              [-39.943791448054441, -8.149039348672298],
              [-39.943791384915201, -8.149039316253242],
              [-39.942659680986615, -8.149958501444779],
              [-39.942659711431055, -8.1499585626264],
              [-39.942659611882846, -8.149958644662416],
              [-39.942659513516453, -8.149958728101883],
              [-39.942659416351752, -8.149958812927821],
              [-39.942659320408453, -8.149958899123105],
              [-39.942659225705995, -8.149958986670253],
              [-39.942659132263543, -8.149959075551532],
              [-39.942659040100033, -8.149959165748941],
              [-39.942658949234129, -8.149959257244205],
              [-39.942658859684244, -8.149959350018809],
              [-39.942658771468516, -8.149959444053934],
              [-39.942658684604815, -8.149959539330572],
              [-39.942658599110729, -8.149959635829365],
              [-39.942658515003572, -8.149959733530814],
              [-39.942658432300391, -8.14995983241511],
              [-39.942658351017933, -8.149959932462227],
              [-39.942658271172675, -8.149960033651906],
              [-39.942658192780776, -8.149960135963648],
              [-39.942658115858137, -8.149960239376732],
              [-39.942658040420298, -8.14996034387018],
              [-39.942657966482571, -8.149960449422874],
              [-39.94265789405992, -8.149960556013387],
              [-39.942657823167018, -8.149960663620147],
              [-39.942657753818246, -8.149960772221387],
              [-39.942657686027616, -8.149960881795062],
              [-39.94265761980887, -8.14996099231899],
              [-39.942657555175444, -8.149961103770819],
              [-39.942657492140398, -8.149961216127927],
              [-39.942657430716523, -8.14996132936756],
              [-39.942657370916258, -8.149961443466809],
              [-39.942657312751713, -8.149961558402534],
              [-39.942657256234668, -8.149961674151479],
              [-39.942657201376548, -8.149961790690162],
              [-39.942657148188509, -8.14996190799503],
              [-39.942657096681309, -8.149962026042296],
              [-39.942657046865357, -8.149962144808018],
              [-39.942656998750778, -8.149962264268174],
              [-39.942656952347299, -8.149962384398551],
              [-39.942656907664329, -8.149962505174832],
              [-39.942656864710905, -8.149962626572545],
              [-39.94265682349576, -8.149962748567113],
              [-39.942656784027193, -8.149962871133779],
              [-39.942656746313219, -8.149962994247748],
              [-39.942656710361504, -8.149963117884088],
              [-39.94265667617929, -8.149963242017742],
              [-39.942656643773525, -8.149963366623567],
              [-39.942656613150781, -8.149963491676342],
              [-39.942656584317234, -8.149963617150696],
              [-39.942656557278738, -8.149963743021249],
              [-39.942656532040779, -8.149963869262484],
              [-39.942656508608458, -8.149963995848863],
              [-39.942656486986522, -8.149964122754717],
              [-39.942656467179354, -8.149964249954349],
              [-39.942656449190977, -8.149964377421993],
              [-39.942656433025014, -8.149964505131829],
              [-39.942656418684734, -8.149964633057957],
              [-39.942656406173079, -8.149964761174507],
              [-39.942656395492534, -8.14996488945552],
              [-39.942656386645297, -8.149965017874992],
              [-39.942656379633156, -8.149965146406947],
              [-39.942656374457535, -8.149965275025297],
              [-39.942656371119469, -8.149965403704018],
              [-39.942656369619648, -8.149965532417058],
              [-39.942656369958343, -8.149965661138326],
              [-39.942656372135531, -8.149965789841746],
              [-39.942656376150737, -8.149965918501252],
              [-39.942656382003179, -8.149966047090787],
              [-39.942656389691635, -8.149966175584311],
              [-39.942656399214577, -8.149966303955798],
              [-39.942656410570059, -8.149966432179255],
              [-39.942656423755793, -8.149966560228666],
              [-39.942656438769092, -8.149966688078109],
              [-39.942656455606937, -8.149966815701701],
              [-39.942656474265874, -8.149966943073585],
              [-39.942656494742188, -8.149967070167964],
              [-39.942656517031693, -8.149967196959073],
              [-39.942656541129864, -8.149967323421281],
              [-39.942656567031854, -8.149967449528942],
              [-39.942656594732384, -8.149967575256449],
              [-39.942656624225855, -8.14996770057844],
              [-39.942656655506305, -8.149967825469476],
              [-39.942656688567361, -8.149967949904269],
              [-39.942656723402386, -8.149968073857618],
              [-39.942656760004269, -8.149968197304394],
              [-39.942656798365611, -8.14996832021961],
              [-39.942656838478669, -8.14996844257838],
              [-39.942656880335285, -8.149968564355873],
              [-39.942656923926982, -8.149968685527483],
              [-39.942656969244943, -8.149968806068616],
              [-39.942657016279995, -8.149968925954918],
              [-39.942657065022594, -8.149969045161997],
              [-39.942657115462872, -8.149969163665785],
              [-39.942657167590617, -8.14996928144228],
              [-39.942657221395265, -8.149969398467599],
              [-39.942657276865916, -8.149969514718061],
              [-39.942657333991328, -8.149969630170098],
              [-39.942657392759948, -8.149969744800346],
              [-39.942657453159867, -8.149969858585546],
              [-39.942657515178844, -8.149969971502664],
              [-39.942657578804315, -8.149970083528874],
              [-39.942657529695417, -8.149970083202366],
              [-39.943225856348874, -8.15095423323114],
              [-39.94322587158274, -8.150954275065127],
              [-39.94322587562673, -8.150954282075906],
              [-39.943225879664432, -8.150954289090288],
              [-39.943225883695824, -8.150954296108235],
              [-39.943225887720942, -8.15095430312979],
              [-39.943225891739743, -8.150954310154932],
              [-39.943225895752235, -8.150954317183624],
              [-39.94322589975841, -8.150954324215917],
              [-39.943225903758304, -8.150954331251768],
              [-39.943225907751867, -8.150954338291164],
              [-39.943225911739113, -8.150954345334139],
              [-39.943225915720028, -8.150954352380637],
              [-39.94322591969464, -8.150954359430697],
              [-39.943225923662908, -8.150954366484273],
              [-39.943225927624844, -8.150954373541383],
              [-39.943225931580443, -8.150954380602034],
              [-39.943225935529732, -8.150954387666181],
              [-39.943225939472661, -8.150954394733866],
              [-39.943225943409246, -8.150954401805068],
              [-39.943225947339485, -8.150954408879775],
              [-39.943225951263372, -8.150954415957948],
              [-39.943225955180921, -8.150954423039639],
              [-39.943225959092103, -8.15095443012482],
              [-39.94322596299692, -8.15095443721348],
              [-39.943225966895376, -8.150954444305611],
              [-39.943225970787466, -8.150954451401226],
              [-39.943225974673204, -8.150954458500294],
              [-39.943225978552562, -8.150954465602833],
              [-39.943225982425552, -8.150954472708801],
              [-39.943225986292148, -8.150954479818241],
              [-39.943225990152378, -8.150954486931116],
              [-39.943225994006227, -8.150954494047438],
              [-39.943225997853681, -8.150954501167167],
              [-39.943226001694732, -8.150954508290324],
              [-39.943226005529411, -8.150954515416888],
              [-39.943226009357687, -8.150954522546902],
              [-39.943226013179562, -8.1509545296803],
              [-39.943226016995041, -8.150954536817114],
              [-39.943226020804111, -8.150954543957313],
              [-39.943226024606766, -8.150954551100913],
              [-39.943226028403004, -8.150954558247896],
              [-39.94322603219284, -8.15095456539825],
              [-39.943226035976267, -8.150954572551987],
              [-39.943226039753263, -8.15095457970909],
              [-39.943226043523843, -8.150954586869544],
              [-39.943226047287979, -8.150954594033347],
              [-39.943226051045706, -8.150954601200517],
              [-39.943226054796995, -8.150954608371045],
              [-39.943226058541846, -8.150954615544897],
              [-39.943226062280253, -8.150954622722072],
              [-39.943226066012222, -8.150954629902579],
              [-39.943226069737754, -8.150954637086421],
              [-39.943226073456827, -8.150954644273574],
              [-39.943226077169456, -8.150954651464033],
              [-39.943226080875633, -8.15095465865781],
              [-39.943226084575358, -8.150954665854881],
              [-39.943226088268617, -8.150954673055237],
              [-39.94322609195541, -8.150954680258881],
              [-39.943226095635744, -8.150954687465786],
              [-39.943226099309605, -8.150954694675994],
              [-39.943226102977007, -8.150954701889482],
              [-39.943226106637923, -8.150954709106221],
              [-39.943226110292358, -8.150954716326213],
              [-39.943226113940334, -8.15095472354947],
              [-39.943226117581801, -8.150954730775974],
              [-39.943226121216796, -8.150954738005714],
              [-39.94322612484531, -8.150954745238684],
              [-39.94322612846733, -8.150954752474906],
              [-39.943226132082842, -8.150954759714322],
              [-39.943226135691866, -8.150954766956977],
              [-39.943226139294396, -8.150954774202845],
              [-39.94322614289041, -8.150954781451894],
              [-39.94322614647993, -8.150954788704164],
              [-39.943226150062927, -8.150954795959608],
              [-39.943226153639415, -8.150954803218259],
              [-39.943226157209402, -8.150954810480068],
              [-39.943226160772859, -8.150954817745081],
              [-39.943226164329808, -8.150954825013278],
              [-39.943226167880219, -8.150954832284638],
              [-39.943226171424122, -8.150954839559144],
              [-39.943226174961481, -8.150954846836786],
              [-39.943226178492317, -8.150954854117593],
              [-39.943226182016609, -8.150954861401548],
              [-39.943226185534378, -8.150954868688649],
              [-39.943226189045582, -8.150954875978886],
              [-39.943226192550291, -8.15095488327222],
              [-39.943226196048407, -8.150954890568682],
              [-39.94322619954, -8.150954897868262],
              [-39.943226203025027, -8.150954905170957],
              [-39.943226206503518, -8.150954912476749],
              [-39.943226209975457, -8.150954919785624],
              [-39.943226213440802, -8.150954927097596],
              [-39.943226216899603, -8.150954934412651],
              [-39.943226220351839, -8.150954941730784],
              [-39.94322622379751, -8.150954949051998],
              [-39.9432262272366, -8.150954956376266],
              [-39.943226230669119, -8.150954963703594],
              [-39.943226234095064, -8.150954971034389],
              [-39.94322622110964, -8.150954955929972],
              [-39.943655160751632, -8.151873767308233],
              [-39.94365516720088, -8.151873816356678],
              [-39.943655180386038, -8.151873844729522],
              [-39.943655193472686, -8.151873873147622],
              [-39.943655206460662, -8.151873901610628],
              [-39.943655219349814, -8.151873930118205],
              [-39.943655232139974, -8.151873958670013],
              [-39.943655244831, -8.15187398726572],
              [-39.943655257422733, -8.151874015904967],
              [-39.943655269915027, -8.151874044587407],
              [-39.943655282307745, -8.151874073312715],
              [-39.943655294600731, -8.151874102080537],
              [-39.943655306793815, -8.151874130890542],
              [-39.943655318886876, -8.151874159742389],
              [-39.943655330879771, -8.151874188635711],
              [-39.943655342772352, -8.151874217570183],
              [-39.943655354564463, -8.151874246545425],
              [-39.943655366255989, -8.151874275561143],
              [-39.943655377846767, -8.151874304616939],
              [-39.943655389336662, -8.15187433371249],
              [-39.943655400725547, -8.151874362847463],
              [-39.943655412013285, -8.151874392021481],
              [-39.94365542319975, -8.151874421234215],
              [-39.943655434284793, -8.151874450485325],
              [-39.943655445268249, -8.151874479774424],
              [-39.943655456150061, -8.151874509101193],
              [-39.943655466930032, -8.151874538465259],
              [-39.943655477608075, -8.151874567866287],
              [-39.943655488184056, -8.151874597303909],
              [-39.943655498657819, -8.151874626777818],
              [-39.943655509029284, -8.151874656287612],
              [-39.943655519298289, -8.151874685832956],
              [-39.943655529464735, -8.151874715413509],
              [-39.943655539528493, -8.151874745028909],
              [-39.943655549489421, -8.15187477467879],
              [-39.943655559347434, -8.15187480436281],
              [-39.943655569102404, -8.151874834080608],
              [-39.94365557875421, -8.151874863831853],
              [-39.94365558830274, -8.151874893616137],
              [-39.943655597747856, -8.151874923433137],
              [-39.943655607089482, -8.151874953282492],
              [-39.943655616327497, -8.151874983163875],
              [-39.943655625461773, -8.151875013076888],
              [-39.943655634492224, -8.151875043021169],
              [-39.94365564341873, -8.151875072996406],
              [-39.943655652241169, -8.151875103002187],
              [-39.943655660959465, -8.151875133038203],
              [-39.943655669573488, -8.151875163104075],
              [-39.943655678083161, -8.151875193199416],
              [-39.94365568648837, -8.151875223323879],
              [-39.943655694789001, -8.15187525347717],
              [-39.943655702984955, -8.151875283658837],
              [-39.943655711076168, -8.15187531386858],
              [-39.943655719062512, -8.151875344106008],
              [-39.943655726943902, -8.151875374370755],
              [-39.943655734720252, -8.151875404662482],
              [-39.943655742391456, -8.151875434980797],
              [-39.943655749957408, -8.15187546532537],
              [-39.943655757418043, -8.151875495695831],
              [-39.943655764773276, -8.151875526091786],
              [-39.943655772023, -8.151875556512922],
              [-39.943655779167138, -8.151875586958848],
              [-39.943655786205596, -8.151875617429194],
              [-39.943655793138298, -8.151875647923623],
              [-39.943655799965157, -8.15187567844173],
              [-39.943655806686102, -8.151875708983182],
              [-39.943655813301035, -8.151875739547588],
              [-39.943655819809884, -8.151875770134634],
              [-39.943655826212584, -8.151875800743893],
              [-39.943655832509059, -8.151875831375012],
              [-39.943655838699193, -8.151875862027655],
              [-39.94365584478296, -8.151875892701435],
              [-39.943655850760273, -8.151875923395982],
              [-39.943655856631032, -8.151875954110924],
              [-39.943655862395211, -8.151875984845926],
              [-39.94365586805273, -8.151876015600619],
              [-39.943655873603497, -8.151876046374595],
              [-39.943655879047462, -8.151876077167502],
              [-39.943655884384576, -8.151876107979003],
              [-39.943655889614732, -8.151876138808678],
              [-39.943655894737908, -8.151876169656203],
              [-39.943655899754027, -8.151876200521199],
              [-39.943655904663046, -8.151876231403273],
              [-39.943655909464873, -8.151876262302087],
              [-39.943655914159464, -8.15187629321724],
              [-39.943655918746792, -8.151876324148381],
              [-39.94365592322675, -8.151876355095146],
              [-39.943655927599337, -8.151876386057156],
              [-39.943655931864463, -8.151876417034041],
              [-39.94365593602209, -8.151876448025444],
              [-39.943655940072176, -8.15187647903096],
              [-39.943655944014658, -8.151876510050256],
              [-39.943655947849493, -8.151876541082942],
              [-39.943655951576645, -8.151876572128662],
              [-39.943655955196071, -8.15187660318702],
              [-39.943655958707708, -8.151876634257672],
              [-39.943655962111528, -8.151876665340232],
              [-39.943655965407487, -8.151876696434348],
              [-39.943655968595543, -8.15187672753961],
              [-39.943655971675675, -8.151876758655689],
              [-39.943655974647719, -8.15187678978284],
              [-39.94365598464708, -8.151876750022639],
              [-39.943728787046062, -8.152654212792219],
              [-39.943728850033722, -8.152654241944896],
              [-39.943728851191302, -8.152654254398412],
              [-39.943728852331631, -8.152654266853544],
              [-39.943728853454743, -8.1526542793102],
              [-39.943728854560604, -8.152654291768382],
              [-39.943728855649212, -8.152654304228086],
              [-39.943728856720575, -8.15265431668929],
              [-39.943728857774666, -8.152654329151922],
              [-39.943728858811525, -8.152654341616007],
              [-39.943728859831118, -8.152654354081497],
              [-39.943728860833446, -8.152654366548408],
              [-39.943728861818542, -8.152654379016642],
              [-39.943728862786358, -8.152654391486223],
              [-39.943728863736901, -8.15265440395715],
              [-39.943728864670206, -8.152654416429359],
              [-39.943728865586216, -8.15265442890284],
              [-39.943728866484989, -8.152654441377571],
              [-39.943728867366467, -8.152654453853525],
              [-39.943728868230679, -8.152654466330665],
              [-39.943728869077617, -8.152654478808989],
              [-39.943728869907282, -8.15265449128848],
              [-39.943728870719674, -8.152654503769091],
              [-39.943728871514779, -8.152654516250808],
              [-39.943728872292617, -8.152654528733621],
              [-39.943728873053146, -8.152654541217455],
              [-39.943728873796424, -8.152654553702353],
              [-39.943728874522392, -8.152654566188236],
              [-39.943728875231095, -8.152654578675103],
              [-39.943728875922503, -8.152654591162955],
              [-39.943728876596623, -8.152654603651721],
              [-39.943728877253456, -8.152654616141438],
              [-39.943728877892994, -8.152654628632003],
              [-39.943728878515245, -8.152654641123453],
              [-39.943728879120194, -8.15265465361575],
              [-39.943728879707848, -8.152654666108859],
              [-39.943728880278215, -8.152654678602756],
              [-39.943728880831294, -8.152654691097448],
              [-39.943728881367058, -8.152654703592869],
              [-39.943728881885534, -8.152654716089044],
              [-39.943728882386708, -8.152654728585892],
              [-39.94372888287058, -8.152654741083444],
              [-39.943728883337151, -8.152654753581619],
              [-39.943728883786406, -8.152654766080447],
              [-39.943728884218388, -8.152654778579883],
              [-39.94372888463306, -8.152654791079902],
              [-39.943728885030403, -8.15265480358045],
              [-39.943728885410458, -8.15265481608156],
              [-39.94372888577319, -8.152654828583161],
              [-39.943728886118649, -8.15265484108526],
              [-39.94372888644677, -8.15265485358784],
              [-39.94372888675759, -8.152654866090812],
              [-39.943728887051108, -8.152654878594246],
              [-39.943728887327318, -8.152654891098038],
              [-39.943728887586218, -8.152654903602208],
              [-39.943728887827795, -8.152654916106727],
              [-39.943728888052064, -8.152654928611563],
              [-39.943728888259031, -8.152654941116714],
              [-39.943728888448682, -8.152654953622108],
              [-39.94372888862101, -8.152654966127759],
              [-39.943728888776036, -8.152654978633638],
              [-39.943728888913753, -8.152654991139725],
              [-39.943728889034155, -8.15265500364597],
              [-39.94372888913724, -8.152655016152393],
              [-39.94372888922301, -8.15265502865892],
              [-39.943728889291485, -8.152655041165554],
              [-39.943728889342616, -8.15265505367228],
              [-39.943728889376452, -8.152655066179067],
              [-39.943728889392979, -8.152655078685868],
              [-39.94372888939219, -8.152655091192702],
              [-39.943728889374079, -8.152655103699541],
              [-39.943728889338651, -8.152655116206301],
              [-39.943728889285921, -8.152655128713048],
              [-39.943728889215862, -8.152655141219686],
              [-39.943728889128508, -8.152655153726197],
              [-39.943728889023831, -8.152655166232609],
              [-39.943728888901845, -8.152655178738863],
              [-39.943728888762536, -8.152655191244888],
              [-39.943728888605925, -8.152655203750767],
              [-39.943728888432005, -8.152655216256402],
              [-39.943728888240763, -8.152655228761787],
              [-39.943728888032204, -8.152655241266881],
              [-39.943728887806344, -8.152655253771705],
              [-39.943728887563168, -8.152655266276184],
              [-39.94372888730269, -8.152655278780301],
              [-39.943728887024903, -8.152655291284054],
              [-39.943728886729794, -8.152655303787441],
              [-39.943728886417382, -8.152655316290399],
              [-39.943728886087669, -8.152655328792889],
              [-39.94372888574064, -8.152655341294968],
              [-39.943728885376302, -8.152655353796529],
              [-39.943728884994663, -8.152655366297573],
              [-39.943728884595721, -8.152655378798091],
              [-39.943728884179464, -8.152655391298035],
              [-39.943728883745919, -8.152655403797384],
              [-39.943728883295066, -8.152655416296147],
              [-39.943728882826903, -8.152655428794278],
              [-39.943728882341446, -8.152655441291758],
              [-39.943728881838673, -8.152655453788539],
              [-39.94372888131862, -8.152655466284633],
              [-39.943728880781201, -8.152655478781426],
              [-39.94372888005045, -8.152655492756375],
              [-39.943661117060955, -8.154143610968259],
              [-39.943661156546696, -8.154143597175121],
              [-39.943661156180667, -8.1541436052987],
              [-39.943661155821964, -8.154143613422596],
              [-39.943661155470586, -8.154143621546831],
              [-39.943661155126563, -8.154143629671362],
              [-39.943661154789851, -8.154143637796203],
              [-39.943661154460472, -8.154143645921311],
              [-39.943661154138432, -8.154143654046742],
              [-39.943661153823719, -8.154143662172453],
              [-39.943661153516345, -8.154143670298453],
              [-39.943661153216297, -8.15414367842474],
              [-39.943661152923575, -8.154143686551246],
              [-39.943661152638214, -8.154143694678019],
              [-39.94366115236015, -8.154143702805063],
              [-39.943661152089433, -8.15414371093236],
              [-39.943661151826049, -8.154143719059892],
              [-39.943661151569998, -8.154143727187641],
              [-39.943661151321287, -8.15414373531563],
              [-39.943661151079908, -8.154143743443834],
              [-39.943661150845863, -8.154143751572258],
              [-39.943661150619143, -8.154143759700881],
              [-39.943661150399784, -8.15414376782971],
              [-39.943661150187737, -8.154143775958726],
              [-39.943661149983029, -8.154143784087939],
              [-39.943661149785655, -8.154143792217321],
              [-39.943661149595613, -8.154143800346873],
              [-39.943661149412911, -8.154143808476592],
              [-39.943661149237556, -8.154143816606464],
              [-39.943661149069513, -8.154143824736503],
              [-39.943661148908831, -8.154143832866692],
              [-39.943661148755467, -8.154143840997019],
              [-39.943661148609451, -8.154143849127458],
              [-39.943661148470767, -8.154143857258036],
              [-39.943661148339423, -8.154143865388747],
              [-39.943661148215412, -8.154143873519578],
              [-39.943661148098741, -8.154143881650489],
              [-39.943661147989403, -8.154143889781542],
              [-39.943661147887404, -8.154143897912656],
              [-39.943661147792739, -8.154143906043839],
              [-39.943661147705413, -8.154143914175139],
              [-39.94366114762542, -8.154143922306504],
              [-39.943661147552774, -8.154143930437938],
              [-39.943661147487461, -8.154143938569442],
              [-39.943661147429481, -8.154143946700994],
              [-39.943661147378847, -8.154143954832612],
              [-39.94366114733554, -8.154143962964262],
              [-39.943661147299572, -8.15414397109595],
              [-39.943661147270937, -8.154143979227653],
              [-39.943661147249649, -8.154143987359369],
              [-39.943661147235687, -8.154143995491124],
              [-39.943661147229079, -8.154144003622894],
              [-39.943661147229811, -8.154144011754658],
              [-39.943661147237854, -8.154144019886413],
              [-39.943661147253266, -8.15414402801815],
              [-39.94366111790913, -8.154143972739732],
              [-39.943662937774086, -8.154920153538075],
              [-39.943662966947365, -8.154920136069098],
              [-39.94366296735376, -8.154920203091194],
              [-39.943662968258586, -8.154920270108507],
              [-39.943662969661801, -8.154920337117249],
              [-39.94366297156332, -8.154920404113794],
              [-39.943662973963058, -8.154920471094455],
              [-39.943662976860871, -8.154920538055581],
              [-39.94366298025659, -8.154920604993446],
              [-39.943662984150038, -8.154920671904387],
              [-39.943662988541014, -8.154920738784764],
              [-39.943662993429257, -8.154920805630857],
              [-39.94366299881451, -8.154920872439069],
              [-39.943663004696475, -8.154920939205649],
              [-39.943663011074818, -8.154921005926949],
              [-39.943663017949191, -8.154921072599334],
              [-39.943663025319232, -8.154921139219136],
              [-39.943663033184528, -8.154921205782674],
              [-39.94366304154466, -8.154921272286305],
              [-39.943663050399138, -8.154921338726419],
              [-39.943663059747507, -8.154921405099296],
              [-39.943663069589228, -8.154921471401314],
              [-39.94366307992378, -8.154921537628855],
              [-39.943663090750583, -8.154921603778259],
              [-39.94366310206906, -8.154921669845908],
              [-39.943663113878557, -8.15492173582815],
              [-39.943663126178464, -8.154921801721413],
              [-39.94366313896807, -8.15492186752201],
              [-39.943663152246707, -8.154921933226388],
              [-39.943663166013636, -8.154921998830909],
              [-39.943663180268068, -8.154922064331958],
              [-39.94366319500925, -8.154922129725959],
              [-39.943663210236373, -8.154922195009339],
              [-39.943663225948598, -8.15492226017847],
              [-39.943663242145071, -8.154922325229778],
              [-39.94366325882487, -8.154922390159713],
              [-39.943663275987106, -8.154922454964707],
              [-39.943663293630827, -8.15492251964117],
              [-39.943663311755081, -8.154922584185618],
              [-39.943663330358852, -8.154922648594464],
              [-39.943663349441117, -8.154922712864138],
              [-39.943663369000873, -8.154922776991159],
              [-39.943663389036978, -8.154922840971995],
              [-39.943663409548378, -8.154922904803119],
              [-39.943663430533917, -8.154922968481049],
              [-39.943663451992471, -8.154923032002257],
              [-39.943663473922832, -8.154923095363284],
              [-39.943663496323836, -8.154923158560612],
              [-39.943663519194203, -8.154923221590813],
              [-39.943663542532725, -8.154923284450399],
              [-39.943663566338095, -8.154923347135938],
              [-39.943663590609006, -8.15492340964394],
              [-39.943663615344121, -8.154923471971065],
              [-39.943663640542105, -8.154923534113809],
              [-39.943663666201545, -8.15492359606878],
              [-39.943663692321039, -8.154923657832596],
              [-39.943663718899181, -8.154923719401841],
              [-39.943663745934472, -8.154923780773155],
              [-39.943663773425449, -8.154923841943143],
              [-39.94366380137059, -8.154923902908466],
              [-39.943663829768383, -8.154923963665762],
              [-39.943663858617242, -8.154924024211667],
              [-39.943663887915598, -8.154924084542928],
              [-39.943663917661823, -8.154924144656166],
              [-39.943663947854326, -8.154924204548152],
              [-39.943663978491408, -8.154924264215516],
              [-39.9436640095714, -8.154924323655015],
              [-39.943664041092589, -8.154924382863383],
              [-39.94366407305327, -8.154924441837387],
              [-39.943664105451646, -8.154924500573768],
              [-39.943664138285975, -8.154924559069308],
              [-39.943664171554445, -8.154924617320813],
              [-39.943664205255189, -8.154924675325034],
              [-39.943664239386429, -8.154924733078843],
              [-39.943664273946226, -8.154924790579015],
              [-39.943664308932718, -8.154924847822427],
              [-39.943664344343965, -8.154924904805924],
              [-39.943664380178049, -8.154924961526358],
              [-39.943664416432966, -8.154925017980682],
              [-39.943664453106749, -8.154925074165725],
              [-39.943664490197378, -8.154925130078432],
              [-39.943664527702815, -8.154925185715717],
              [-39.943664565621013, -8.154925241074531],
              [-39.943664603949863, -8.154925296151863],
              [-39.943664642687281, -8.154925350944636],
              [-39.943664681831137, -8.154925405449873],
              [-39.943664721379271, -8.154925459664598],
              [-39.943664761329522, -8.154925513585798],
              [-39.943664801679688, -8.154925567210515],
              [-39.943664842427573, -8.154925620535808],
              [-39.943664883570911, -8.154925673558767],
              [-39.943664925107456, -8.154925726276462],
              [-39.943664967034927, -8.154925778686005],
              [-39.943665009351029, -8.154925830784521],
              [-39.943665052053426, -8.154925882569112],
              [-39.943665095139778, -8.154925934037006],
              [-39.943665138607713, -8.154925985185336],
              [-39.943665182454858, -8.15492603601127],
              [-39.943665226678789, -8.154926086512052],
              [-39.943665271277069, -8.154926136684885],
              [-39.943665316247284, -8.154926186527044],
              [-39.943665311933579, -8.154926207716889],
              [-39.944303605118982, -8.155628370547102],
              [-39.94430364547064, -8.155628417752107],
              [-39.944303691056263, -8.155628467527155],
              [-39.944303737012795, -8.155628516962407],
              [-39.944303783337695, -8.155628566055125],
              [-39.944303830028389, -8.155628614802568],
              [-39.944303877082277, -8.155628663202009],
              [-39.944303924496765, -8.155628711250804],
              [-39.944303972269203, -8.155628758946266],
              [-39.944304020396956, -8.155628806285749],
              [-39.944304068877337, -8.155628853266608],
              [-39.944304117707645, -8.15562889988626],
              [-39.944304166885189, -8.155628946142134],
              [-39.944304216407254, -8.15562899203162],
              [-39.944304266271054, -8.155629037552186],
              [-39.944304316473847, -8.155629082701312],
              [-39.944304367012847, -8.155629127476471],
              [-39.944304417885213, -8.15562917187518],
              [-39.944304469088181, -8.15562921589502],
              [-39.944304520618857, -8.155629259533493],
              [-39.944304572474394, -8.155629302788203],
              [-39.944304624651927, -8.155629345656754],
              [-39.944304677148558, -8.155629388136742],
              [-39.944304729961345, -8.155629430225835],
              [-39.944304783087397, -8.155629471921678],
              [-39.944304836523735, -8.155629513221969],
              [-39.944304890267397, -8.155629554124392],
              [-39.944304944315419, -8.155629594626696],
              [-39.944304998664776, -8.155629634726631],
              [-39.944305053312476, -8.155629674421983],
              [-39.944305108255463, -8.155629713710532],
              [-39.944305163490682, -8.155629752590096],
              [-39.944305219015099, -8.155629791058544],
              [-39.944305274825595, -8.155629829113705],
              [-39.9443053309191, -8.155629866753451],
              [-39.944305387292502, -8.15562990397575],
              [-39.944305443942653, -8.155629940778489],
              [-39.944305500866406, -8.155629977159684],
              [-39.94430555806062, -8.155630013117237],
              [-39.944305615522104, -8.155630048649222],
              [-39.944305673247683, -8.15563008375362],
              [-39.944305731234138, -8.155630118428499],
              [-39.94430578947825, -8.155630152671936],
              [-39.9443058479768, -8.155630186482018],
              [-39.944305906726541, -8.155630219856876],
              [-39.94430596572419, -8.155630252794618],
              [-39.944306024966494, -8.155630285293519],
              [-39.944306084450155, -8.155630317351678],
              [-39.944306144171868, -8.155630348967371],
              [-39.944306204128317, -8.155630380138799],
              [-39.944306264316189, -8.155630410864257],
              [-39.944306324732132, -8.15563044114203],
              [-39.944306385372798, -8.155630470970442],
              [-39.944306446234812, -8.15563050034784],
              [-39.944306507314778, -8.155630529272599],
              [-39.944306568609349, -8.155630557743093],
              [-39.944306630115086, -8.155630585757788],
              [-39.944306691828615, -8.155630613315074],
              [-39.944306753746453, -8.155630640413445],
              [-39.944306815865211, -8.155630667051398],
              [-39.944306878181429, -8.155630693227433],
              [-39.944306940691632, -8.155630718940129],
              [-39.944307003392353, -8.155630744188045],
              [-39.944307066280132, -8.155630768969747],
              [-39.944307129351465, -8.155630793283917],
              [-39.944307192602835, -8.15563081712919],
              [-39.944307256030754, -8.155630840504227],
              [-39.944307319631697, -8.155630863407737],
              [-39.944307383402133, -8.155630885838445],
              [-39.944307447338517, -8.155630907795102],
              [-39.944307511437309, -8.155630929276477],
              [-39.944307575694928, -8.155630950281425],
              [-39.94430764010783, -8.15563097080874],
              [-39.944307704672433, -8.155630990857295],
              [-39.944307769385155, -8.15563101042598],
              [-39.944307834242402, -8.155631029513682],
              [-39.944307899240556, -8.155631048119371],
              [-39.944307964376037, -8.155631066242007],
              [-39.944308029645214, -8.155631083880571],
              [-39.944308095044477, -8.155631101034119],
              [-39.944308160570181, -8.155631117701652],
              [-39.944308226218695, -8.155631133882299],
              [-39.944308291986367, -8.155631149575134],
              [-39.944308357869566, -8.155631164779257],
              [-39.944308423864612, -8.155631179493851],
              [-39.94430848996786, -8.155631193718101],
              [-39.944308556175613, -8.155631207451204],
              [-39.944308622484229, -8.155631220692417],
              [-39.944308688890011, -8.155631233441015],
              [-39.944308755389279, -8.155631245696254],
              [-39.944308821978318, -8.155631257457467],
              [-39.944308888653467, -8.155631268724024],
              [-39.944308955411003, -8.155631279495259],
              [-39.944309022247225, -8.155631289770607],
              [-39.94430908915843, -8.155631299549487],
              [-39.944309156140889, -8.155631308831369],
              [-39.944309223190892, -8.155631317615692],
              [-39.94430929030473, -8.15563132590202],
              [-39.944309357478637, -8.155631333689866],
              [-39.944309424708941, -8.155631340978807],
              [-39.944309491991859, -8.155631347768438],
              [-39.945018208624823, -8.155700235465131],
              [-39.945018241723737, -8.155700211316759],
              [-39.945018250807607, -8.155700212194768],
              [-39.945018259892379, -8.15570021306366],
              [-39.945018268977996, -8.155700213923453],
              [-39.945018278064495, -8.155700214774162],
              [-39.945018287151832, -8.155700215615742],
              [-39.945018296240043, -8.155700216448221],
              [-39.945018305329064, -8.155700217271603],
              [-39.945018314418917, -8.155700218085883],
              [-39.945018323509586, -8.155700218891047],
              [-39.945018332601066, -8.155700219687096],
              [-39.945018341693356, -8.155700220474031],
              [-39.945018350786405, -8.155700221251884],
              [-39.945018359880258, -8.155700222020606],
              [-39.945018368974885, -8.155700222780226],
              [-39.945018378070259, -8.155700223530745],
              [-39.945018387166385, -8.155700224272168],
              [-39.945018396263272, -8.155700225004459],
              [-39.945018405360877, -8.155700225727637],
              [-39.945018414459213, -8.155700226441695],
              [-39.945018423558267, -8.155700227146642],
              [-39.945018432658017, -8.155700227842486],
              [-39.945018441758485, -8.155700228529197],
              [-39.945018450859628, -8.155700229206779],
              [-39.945018459961453, -8.155700229875263],
              [-39.945018469063953, -8.15570023053461],
              [-39.9450184781671, -8.155700231184845],
              [-39.945018487270907, -8.155700231825946],
              [-39.945018496375347, -8.155700232457933],
              [-39.945018505480434, -8.155700233080823],
              [-39.945018514586145, -8.155700233694565],
              [-39.945018523692461, -8.155700234299189],
              [-39.945018532799395, -8.155700234894681],
              [-39.945018541906919, -8.155700235481055],
              [-39.945018551015025, -8.155700236058317],
              [-39.9450185601237, -8.155700236626432],
              [-39.945018569232957, -8.155700237185432],
              [-39.945018578342783, -8.155700237735294],
              [-39.945018587453148, -8.155700238276044],
              [-39.945018596564047, -8.155700238807647],
              [-39.945018605675486, -8.155700239330132],
              [-39.94501861478745, -8.155700239843487],
              [-39.945018623899919, -8.155700240347709],
              [-39.945018633012893, -8.155700240842817],
              [-39.945018642126371, -8.155700241328775],
              [-39.945018651240339, -8.155700241805604],
              [-39.945018660354769, -8.155700242273301],
              [-39.945018669469675, -8.155700242731863],
              [-39.945018678585043, -8.155700243181295],
              [-39.945018687700852, -8.155700243621611],
              [-39.945018696817101, -8.155700244052779],
              [-39.945018705933784, -8.155700244474817],
              [-39.945018715050885, -8.15570024488772],
              [-39.945018724168385, -8.155700245291511],
              [-39.945018733286311, -8.15570024568612],
              [-39.945018742404628, -8.15570024607163],
              [-39.945018751523307, -8.15570024644799],
              [-39.945018760642384, -8.15570024681522],
              [-39.945018769761823, -8.155700247173302],
              [-39.945018778881611, -8.155700247522253],
              [-39.945018788001754, -8.155700247862068],
              [-39.945018797122231, -8.155700248192741],
              [-39.945018806243041, -8.155700248514297],
              [-39.945018815364158, -8.155700248826685],
              [-39.945018824485594, -8.155700249129943],
              [-39.94501883360735, -8.155700249424074],
              [-39.945018842729368, -8.155700249709051],
              [-39.945018851851685, -8.1557002499849],
              [-39.94501886097428, -8.155700250251599],
              [-39.945018870097137, -8.155700250509184],
              [-39.945018879220243, -8.155700250757604],
              [-39.945018888343597, -8.155700250996892],
              [-39.945018897467172, -8.155700251227035],
              [-39.94501890659101, -8.155700251448028],
              [-39.945018915715032, -8.155700251659891],
              [-39.945018924839275, -8.155700251862603],
              [-39.945018933963738, -8.155700252056185],
              [-39.945018943088364, -8.155700252240635],
              [-39.945018952213182, -8.155700252415906],
              [-39.945018961338185, -8.155700252582077],
              [-39.945018970463337, -8.155700252739086],
              [-39.945018979588639, -8.155700252886946],
              [-39.945018988714089, -8.155700253025675],
              [-39.945018997839689, -8.155700253155256],
              [-39.945019006965389, -8.155700253275688],
              [-39.94501901609123, -8.155700253386971],
              [-39.945019025217157, -8.155700253489124],
              [-39.945019034343197, -8.155700253582133],
              [-39.945019043469323, -8.155700253666007],
              [-39.945019052595534, -8.155700253740736],
              [-39.945019061721815, -8.155700253806316],
              [-39.94501907084814, -8.155700253862749],
              [-39.945019079974536, -8.15570025391003],
              [-39.945019089100974, -8.155700253948204],
              [-39.945019098227441, -8.155700253977225],
              [-39.945019107353929, -8.155700253997098],
              [-39.945019116480445, -8.155700254007826],
              [-39.945019125606954, -8.15570025400941],
              [-39.945019134733464, -8.155700254001859],
              [-39.945019143859966, -8.155700253985161],
            ],
          ],
          [
            [
              [-39.938717146373769, -8.176672202884641],
              [-39.93871714918577, -8.17667216216911],
              [-39.942447783668619, -8.175650698884191],
              [-39.942448690964532, -8.175650515882911],
              [-39.943842587518894, -8.175416091603598],
              [-39.942032602512704, -8.173915244933434],
              [-39.953664639883868, -8.160590501347841],
              [-39.948494529457108, -8.157047769013626],
              [-39.948016602140576, -8.157110931410573],
              [-39.948016605437971, -8.157110944554109],
              [-39.948016573024979, -8.157110948895994],
              [-39.948016540627876, -8.157110953354236],
              [-39.948016508247107, -8.157110957928744],
              [-39.948016475883101, -8.157110962619486],
              [-39.948016443536261, -8.157110967426375],
              [-39.948016411207021, -8.157110972349397],
              [-39.94801637889578, -8.157110977388433],
              [-39.948016346602969, -8.157110982543461],
              [-39.94801631432901, -8.157110987814383],
              [-39.948016282074327, -8.157110993201124],
              [-39.948016249839341, -8.157110998703644],
              [-39.948016217624442, -8.157111004321848],
              [-39.948016185430077, -8.157111010055671],
              [-39.94801615325666, -8.157111015905055],
              [-39.948016121104594, -8.157111021869913],
              [-39.948016088974306, -8.157111027950144],
              [-39.948016056866223, -8.157111034145693],
              [-39.948016024780749, -8.157111040456483],
              [-39.948015992718297, -8.157111046882429],
              [-39.948015960679292, -8.157111053423428],
              [-39.948015928664134, -8.157111060079421],
              [-39.948015896673276, -8.15711106685033],
              [-39.948015864707088, -8.157111073736026],
              [-39.948015832766032, -8.157111080736447],
              [-39.948015800850484, -8.157111087851487],
              [-39.948015768960865, -8.157111095081088],
              [-39.948015737097606, -8.157111102425096],
              [-39.948015705261099, -8.157111109883481],
              [-39.948015673451785, -8.157111117456084],
              [-39.948015641670054, -8.157111125142892],
              [-39.948015609916311, -8.157111132943735],
              [-39.94801557819099, -8.157111140858508],
              [-39.948015546494496, -8.157111148887148],
              [-39.94801551482724, -8.157111157029533],
              [-39.948015483189636, -8.157111165285546],
              [-39.948015451582087, -8.157111173655082],
              [-39.948015420005014, -8.157111182138054],
              [-39.948015388458813, -8.157111190734334],
              [-39.948015356943912, -8.157111199443809],
              [-39.948015325460702, -8.157111208266393],
              [-39.9480152940096, -8.157111217201933],
              [-39.948015262591021, -8.157111226250336],
              [-39.948015231205346, -8.157111235411481],
              [-39.948015199853003, -8.157111244685252],
              [-39.948015168534411, -8.157111254071513],
              [-39.948015137249961, -8.157111263570137],
              [-39.948015106000064, -8.157111273181064],
              [-39.948015074785125, -8.157111282904092],
              [-39.948015043605537, -8.157111292739131],
              [-39.948015012461724, -8.157111302686044],
              [-39.948014981354085, -8.157111312744718],
              [-39.948014950282996, -8.157111322914997],
              [-39.948014919248912, -8.157111333196761],
              [-39.948014888252196, -8.157111343589877],
              [-39.948014857293259, -8.157111354094223],
              [-39.948014826372521, -8.157111364709634],
              [-39.948014795490366, -8.157111375435987],
              [-39.948014764647191, -8.157111386273151],
              [-39.948014733843415, -8.157111397220968],
              [-39.948014703079409, -8.157111408279308],
              [-39.948014672355605, -8.157111419448025],
              [-39.948014641672387, -8.157111430726962],
              [-39.948014611030153, -8.15711144211599],
              [-39.94801458042928, -8.157111453614984],
              [-39.948014549870209, -8.157111465223734],
              [-39.948014519353308, -8.157111476942152],
              [-39.948014488878997, -8.157111488770026],
              [-39.948014458447624, -8.157111500707247],
              [-39.948014428059629, -8.157111512753634],
              [-39.948014397715383, -8.157111524909029],
              [-39.94801436741529, -8.157111537173304],
              [-39.948014337159748, -8.157111549546295],
              [-39.948014306949148, -8.157111562027811],
              [-39.94801427678388, -8.157111574617714],
              [-39.948014246664314, -8.157111587315827],
              [-39.948014216590884, -8.157111600122002],
              [-39.948014186563924, -8.15711161303607],
              [-39.948014156583881, -8.15711162605786],
              [-39.948014126651117, -8.157111639187194],
              [-39.94801409676603, -8.157111652423906],
              [-39.948014066928984, -8.15711166576782],
              [-39.948014037140396, -8.157111679218762],
              [-39.948014007400644, -8.157111692776565],
              [-39.948013977710104, -8.157111706441055],
              [-39.948013948069168, -8.15711172021205],
              [-39.948013918478225, -8.157111734089366],
              [-39.948013888937652, -8.157111748072849],
              [-39.94801385944784, -8.157111762162288],
              [-39.948013830009153, -8.157111776357507],
              [-39.948013800621993, -8.15711179065833],
              [-39.948013771286746, -8.157111805064561],
              [-39.948013742003781, -8.157111819576023],
              [-39.948013712773466, -8.157111834192518],
              [-39.948013683596216, -8.157111848913877],
              [-39.948013654472369, -8.157111863739875],
              [-39.948013625402339, -8.15711187867033],
              [-39.948013596386474, -8.157111893705071],
              [-39.948013567425171, -8.157111908843865],
              [-39.948013538518801, -8.157111924086534],
              [-39.94801351838202, -8.157111933500438],
              [-39.94680662496372, -8.157751114005443],
              [-39.946806638814493, -8.157751110704437],
              [-39.946806606341362, -8.157751127986716],
              [-39.946806573938836, -8.157751145400054],
              [-39.946806541607472, -8.157751162944244],
              [-39.946806509347773, -8.157751180618929],
              [-39.946806477160308, -8.157751198423846],
              [-39.94680644504556, -8.157751216358703],
              [-39.946806413004083, -8.157751234423202],
              [-39.946806381036403, -8.157751252617031],
              [-39.946806349143046, -8.157751270939892],
              [-39.946806317324537, -8.157751289391522],
              [-39.946806285581388, -8.15775130797158],
              [-39.946806253914147, -8.157751326679763],
              [-39.946806222323303, -8.157751345515804],
              [-39.94680619080939, -8.157751364479337],
              [-39.94680615937294, -8.157751383570064],
              [-39.946806128014451, -8.157751402787698],
              [-39.946806096734448, -8.157751422131904],
              [-39.946806065533423, -8.157751441602366],
              [-39.946806034411928, -8.157751461198762],
              [-39.946806003370462, -8.15775148092076],
              [-39.946805972409514, -8.157751500768056],
              [-39.946805941529618, -8.157751520740312],
              [-39.946805910731278, -8.1577515408372],
              [-39.946805880014978, -8.157751561058385],
              [-39.946805849381242, -8.157751581403533],
              [-39.94680581883059, -8.157751601872322],
              [-39.946805788363491, -8.157751622464421],
              [-39.946805757980478, -8.15775164317947],
              [-39.946805727682012, -8.157751664017162],
              [-39.946805697468648, -8.15775168497713],
              [-39.946805667340826, -8.157751706058999],
              [-39.946805637299072, -8.157751727262466],
              [-39.946805607343869, -8.157751748587174],
              [-39.946805577475729, -8.157751770032755],
              [-39.946805547695106, -8.157751791598885],
              [-39.946805518002527, -8.157751813285175],
              [-39.946805488398475, -8.157751835091306],
              [-39.946805458883418, -8.157751857016875],
              [-39.946805429457861, -8.15775187906157],
              [-39.946805400122265, -8.157751901224989],
              [-39.946805370877122, -8.157751923506783],
              [-39.946805341722929, -8.157751945906584],
              [-39.946805312660146, -8.157751968424034],
              [-39.946805283689272, -8.157751991058772],
              [-39.946805254810755, -8.157752013810374],
              [-39.946805226025106, -8.157752036678506],
              [-39.946805197332758, -8.157752059662775],
              [-39.946805168734222, -8.157752082762828],
              [-39.946805140229934, -8.157752105978259],
              [-39.946805111820382, -8.157752129308703],
              [-39.946805083506028, -8.157752152753742],
              [-39.946805055287342, -8.157752176313052],
              [-39.9468050271648, -8.157752199986183],
              [-39.946804999138834, -8.157752223772803],
              [-39.946804971209929, -8.15775224767247],
              [-39.946804943378531, -8.15775227168481],
              [-39.94680491564511, -8.157752295809425],
              [-39.946804888010114, -8.157752320045937],
              [-39.946804860473996, -8.157752344393939],
              [-39.94680483303722, -8.157752368853036],
              [-39.946804805700232, -8.157752393422793],
              [-39.946804778463466, -8.157752418102849],
              [-39.946804751327392, -8.157752442892757],
              [-39.946804724292448, -8.157752467792134],
              [-39.946804697359084, -8.157752492800553],
              [-39.946804670527733, -8.157752517917599],
              [-39.946804643798849, -8.157752543142909],
              [-39.946804617172859, -8.157752568476035],
              [-39.946804590650189, -8.157752593916547],
              [-39.946804564231329, -8.157752619464043],
              [-39.946804537916648, -8.157752645118084],
              [-39.946804511706617, -8.157752670878267],
              [-39.94680448560166, -8.157752696744151],
              [-39.946804459602198, -8.15775272271533],
              [-39.946804433708685, -8.157752748791379],
              [-39.946804407921505, -8.157752774971847],
              [-39.946804382241112, -8.157752801256329],
              [-39.946804356667926, -8.157752827644369],
              [-39.946804331202351, -8.15775285413554],
              [-39.946804305844829, -8.157752880729428],
              [-39.946804280595757, -8.157752907425566],
              [-39.946804255455575, -8.157752934223524],
              [-39.946804230424668, -8.157752961122879],
              [-39.946804205503454, -8.157752988123153],
              [-39.946804180692368, -8.157753015223914],
              [-39.946804155991785, -8.157753042424737],
              [-39.946804131402125, -8.157753069725162],
              [-39.9468041069238, -8.157753097124722],
              [-39.946804082557207, -8.157753124623008],
              [-39.946804058302739, -8.157753152219517],
              [-39.946804034160813, -8.157753179913845],
              [-39.946804010131807, -8.157753207705479],
              [-39.946803986216125, -8.157753235594022],
              [-39.946803962414158, -8.157753263578975],
              [-39.946803938726305, -8.15775329165989],
              [-39.946803915152948, -8.157753319836326],
              [-39.946803891694479, -8.157753348107772],
              [-39.946803868351275, -8.157753376473815],
              [-39.946803845123739, -8.157753404933962],
              [-39.946803816479935, -8.157753381649673],
              [-39.945881464154787, -8.15888833808587],
              [-39.94588119248828, -8.158888610050015],
              [-39.945100359733544, -8.15995305075565],
              [-39.945100376357395, -8.159953017565948],
              [-39.945100367206891, -8.159953030062228],
              [-39.945100358077845, -8.159953042574088],
              [-39.945100348970307, -8.159953055101486],
              [-39.94510033988427, -8.159953067644375],
              [-39.945100330819791, -8.159953080202751],
              [-39.94510032177687, -8.159953092776558],
              [-39.945100312755535, -8.159953105365744],
              [-39.945100303755837, -8.1599531179703],
              [-39.945100294777795, -8.159953130590162],
              [-39.945100285821418, -8.159953143225305],
              [-39.945100276886734, -8.1599531558757],
              [-39.945100267973785, -8.1599531685413],
              [-39.945100259082594, -8.159953181222063],
              [-39.945100250213173, -8.159953193917953],
              [-39.945100241365566, -8.159953206628956],
              [-39.945100232539801, -8.159953219355019],
              [-39.945100223735878, -8.159953232096107],
              [-39.945100214953833, -8.159953244852174],
              [-39.945100206193707, -8.159953257623197],
              [-39.945100197455517, -8.159953270409128],
              [-39.945100188739282, -8.159953283209918],
              [-39.945100180045031, -8.159953296025551],
              [-39.9451001713728, -8.159953308855968],
              [-39.945100162722603, -8.159953321701181],
              [-39.945100154094447, -8.159953334561084],
              [-39.945100145488404, -8.159953347435689],
              [-39.945100136904458, -8.159953360324934],
              [-39.945100128342666, -8.159953373228815],
              [-39.945100119803016, -8.159953386147237],
              [-39.945100111285569, -8.159953399080209],
              [-39.945100102790327, -8.159953412027653],
              [-39.945100094317318, -8.159953424989581],
              [-39.945100085866578, -8.159953437965932],
              [-39.945100077438127, -8.159953450956664],
              [-39.94510006903198, -8.159953463961736],
              [-39.945100060648166, -8.159953476981103],
              [-39.945100052286719, -8.159953490014741],
              [-39.94510004394764, -8.159953503062615],
              [-39.945100035630993, -8.159953516124682],
              [-39.945100027336764, -8.159953529200903],
              [-39.945100019065002, -8.159953542291268],
              [-39.945100010815722, -8.159953555395688],
              [-39.945100002588944, -8.159953568514151],
              [-39.945099994384698, -8.159953581646613],
              [-39.945099986202997, -8.159953594793025],
              [-39.945099978043885, -8.15995360795336],
              [-39.945099969907375, -8.159953621127578],
              [-39.945099961793488, -8.159953634315658],
              [-39.945099953702247, -8.159953647517568],
              [-39.945099945633686, -8.159953660733217],
              [-39.94509993758782, -8.159953673962596],
              [-39.945099929564662, -8.15995368720567],
              [-39.94509992156425, -8.159953700462404],
              [-39.945099913586617, -8.159953713732747],
              [-39.945099905631771, -8.159953727016697],
              [-39.945099897699741, -8.159953740314137],
              [-39.945099889790534, -8.159953753625125],
              [-39.945099881904198, -8.159953766949551],
              [-39.94509987404075, -8.159953780287379],
              [-39.945099866200195, -8.159953793638602],
              [-39.945099858382576, -8.159953807003177],
              [-39.945099850587908, -8.159953820381062],
              [-39.945099842816212, -8.1599538337722],
              [-39.945099835067523, -8.15995384717656],
              [-39.945099827341856, -8.15995386059409],
              [-39.945099819639218, -8.159953874024792],
              [-39.945099811959651, -8.159953887468594],
              [-39.945099804303162, -8.159953900925469],
              [-39.945099796669794, -8.159953914395359],
              [-39.945099789059554, -8.159953927878238],
              [-39.945099781472464, -8.159953941374065],
              [-39.945099773908566, -8.159953954882813],
              [-39.945099766367854, -8.159953968404427],
              [-39.945099758850382, -8.159953981938873],
              [-39.945099751356118, -8.159953995486102],
              [-39.945099743885152, -8.159954009046098],
              [-39.945099736437477, -8.159954022618786],
              [-39.94509972901308, -8.159954036204146],
              [-39.945099721612024, -8.159954049802151],
              [-39.945099714234331, -8.159954063412755],
              [-39.945099706880008, -8.159954077035902],
              [-39.945099699549061, -8.159954090671581],
              [-39.945099692241556, -8.159954104319707],
              [-39.945099684957491, -8.159954117980291],
              [-39.945099677696859, -8.159954131653274],
              [-39.945099670459726, -8.159954145338599],
              [-39.945099663246083, -8.159954159036236],
              [-39.945099656055973, -8.159954172746145],
              [-39.945099648889403, -8.159954186468283],
              [-39.945099641746388, -8.159954200202614],
              [-39.945099634626978, -8.159954213949101],
              [-39.945099627531171, -8.159954227707701],
              [-39.945099620458976, -8.159954241478388],
              [-39.945099613410449, -8.159954255261081],
              [-39.945099606385575, -8.159954269055785],
              [-39.945099599384392, -8.15995428286244],
              [-39.945099592406919, -8.159954296681002],
              [-39.945099585453185, -8.159954310511424],
              [-39.945099578523191, -8.159954324353677],
              [-39.94509954579285, -8.159954318860937],
              [-39.944747384743572, -8.160659422432779],
              [-39.944747346334218, -8.160659395466851],
              [-39.944747308759133, -8.160659471574116],
              [-39.944747271901981, -8.160659548029114],
              [-39.944747235766044, -8.160659624825211],
              [-39.94474720035447, -8.160659701955558],
              [-39.944747165670378, -8.160659779413423],
              [-39.944747131716831, -8.160659857191987],
              [-39.944747098496812, -8.160659935284372],
              [-39.944747066013228, -8.160660013683783],
              [-39.944747034268957, -8.160660092383271],
              [-39.944747003266762, -8.160660171375904],
              [-39.944746973009408, -8.160660250654786],
              [-39.944746943499531, -8.160660330212892],
              [-39.944746914739724, -8.160660410043251],
              [-39.944746886732531, -8.160660490138833],
              [-39.944746859480396, -8.160660570492613],
              [-39.944746832985729, -8.160660651097508],
              [-39.944746807250873, -8.160660731946448],
              [-39.94474678227806, -8.160660813032289],
              [-39.944746758069513, -8.160660894347938],
              [-39.944746734627351, -8.16066097588617],
              [-39.944746711953627, -8.160661057639933],
              [-39.944746690050351, -8.160661139601936],
              [-39.944746668919443, -8.160661221765016],
              [-39.944746648562756, -8.160661304121929],
              [-39.944746628982081, -8.160661386665442],
              [-39.944746610179152, -8.160661469388302],
              [-39.944746592155603, -8.160661552283216],
              [-39.944746574913061, -8.160661635342903],
              [-39.944746558452977, -8.160661718560046],
              [-39.944746542776855, -8.160661801927333],
              [-39.944746527886039, -8.160661885437445],
              [-39.944746513781872, -8.160661969083037],
              [-39.944746500465548, -8.160662052856729],
              [-39.944746487938282, -8.160662136751171],
              [-39.944746476201146, -8.160662220758981],
              [-39.944746465255207, -8.160662304872796],
              [-39.944746455101381, -8.160662389085168],
              [-39.944746445740606, -8.160662473388713],
              [-39.944746437173677, -8.160662557776057],
              [-39.944746429401356, -8.160662642239714],
              [-39.944746422424316, -8.160662726772301],
              [-39.944746416243191, -8.160662811366361],
              [-39.944746410858514, -8.16066289601444],
              [-39.944746406270752, -8.160662980709125],
              [-39.944746402480312, -8.160663065442959],
              [-39.944746399487542, -8.160663150208469],
              [-39.94474639729269, -8.160663234998236],
              [-39.944746395895947, -8.160663319804785],
              [-39.944746395297443, -8.160663404620644],
              [-39.944746395497248, -8.160663489438377],
              [-39.944746396495304, -8.160663574250513],
              [-39.944746398291542, -8.160663659049598],
              [-39.944746400885819, -8.16066374382814],
              [-39.944746404277886, -8.160663828578741],
              [-39.944746408467459, -8.160663913293931],
              [-39.944746413454162, -8.160663997966216],
              [-39.944746419237553, -8.160664082588196],
              [-39.944746425817129, -8.160664167152405],
              [-39.944746433192329, -8.160664251651403],
              [-39.944746441362469, -8.160664336077788],
              [-39.944746450326846, -8.160664420424107],
              [-39.94474646008468, -8.160664504682961],
              [-39.94474647063511, -8.16066458884694],
              [-39.944746481977198, -8.160664672908601],
              [-39.944746494109964, -8.160664756860585],
              [-39.944746507032313, -8.160664840695517],
              [-39.944746520743131, -8.160664924405999],
              [-39.944746535241201, -8.160665007984699],
              [-39.944746550525274, -8.160665091424262],
              [-39.944746566593956, -8.160665174717302],
              [-39.944746583445884, -8.160665257856545],
              [-39.944746601079544, -8.160665340834653],
              [-39.944746619493401, -8.160665423644378],
              [-39.944746638685842, -8.160665506278352],
              [-39.944746658655149, -8.160665588729394],
              [-39.944746679399586, -8.160665670990189],
              [-39.944746700917335, -8.160665753053527],
              [-39.944746723206499, -8.160665834912185],
              [-39.944746746265103, -8.160665916558978],
              [-39.94474677009115, -8.160665997986694],
              [-39.944746794682501, -8.160666079188218],
              [-39.944746820037025, -8.160666160156355],
              [-39.944746846152491, -8.160666240884021],
              [-39.944746873026581, -8.160666321364101],
              [-39.944746900656952, -8.160666401589516],
              [-39.944746929041173, -8.160666481553219],
              [-39.944746958176729, -8.160666561248192],
              [-39.944746988061098, -8.160666640667426],
              [-39.944747018691608, -8.160666719803888],
              [-39.94474705006558, -8.160666798650649],
              [-39.944747082180257, -8.160666877200805],
              [-39.944747115032811, -8.160666955447402],
              [-39.944747148620365, -8.160667033383579],
              [-39.944747182939956, -8.160667111002464],
              [-39.944747217988564, -8.160667188297245],
              [-39.944747253763104, -8.160667265261136],
              [-39.944747290260437, -8.160667341887345],
              [-39.944747327477344, -8.160667418169151],
              [-39.944747365410571, -8.160667494099846],
              [-39.944747403652642, -8.160667471844206],
              [-39.94503046733535, -8.161227650532451],
              [-39.945030490998889, -8.16122762450656],
              [-39.945030514265383, -8.161227670213659],
              [-39.945030537791439, -8.161227715788648],
              [-39.945030561576296, -8.161227761230084],
              [-39.945030585619214, -8.161227806536504],
              [-39.945030609919378, -8.161227851706419],
              [-39.945030634476055, -8.161227896738442],
              [-39.94503065928842, -8.161227941631054],
              [-39.945030684355693, -8.161227986382874],
              [-39.94503070967707, -8.161228030992428],
              [-39.945030735251734, -8.161228075458295],
              [-39.945030761078868, -8.161228119779048],
              [-39.945030787157613, -8.161228163953263],
              [-39.945030813487179, -8.161228207979534],
              [-39.945030840066693, -8.161228251856432],
              [-39.945030866895308, -8.161228295582534],
              [-39.945030893972159, -8.161228339156473],
              [-39.945030921296386, -8.161228382576835],
              [-39.945030948867107, -8.161228425842229],
              [-39.945030976683427, -8.161228468951252],
              [-39.945031004744457, -8.161228511902495],
              [-39.945031033049304, -8.161228554694661],
              [-39.945031061597057, -8.161228597326293],
              [-39.945031090386792, -8.161228639796068],
              [-39.945031119417592, -8.16122868210258],
              [-39.945031148688514, -8.16122872424452],
              [-39.94503117819864, -8.161228766220519],
              [-39.945031207946997, -8.161228808029199],
              [-39.945031237932646, -8.161228849669268],
              [-39.945031268154608, -8.161228891139363],
              [-39.945031298611944, -8.161228932438133],
              [-39.945031329303653, -8.161228973564278],
              [-39.945031360228747, -8.161229014516476],
              [-39.945031391386237, -8.161229055293418],
              [-39.945031422775124, -8.161229095893759],
              [-39.945031454394403, -8.161229136316225],
              [-39.94503148624306, -8.161229176559514],
              [-39.94503151832005, -8.161229216622333],
              [-39.945031550624385, -8.161229256503395],
              [-39.945031583154986, -8.161229296201428],
              [-39.945031615910835, -8.161229335715145],
              [-39.945031648890861, -8.16122937504328],
              [-39.94503168209404, -8.161229414184575],
              [-39.945031715519256, -8.16122945313777],
              [-39.945031749165466, -8.161229491901617],
              [-39.945031783031595, -8.16122953047484],
              [-39.945031817116543, -8.161229568856266],
              [-39.945031851419216, -8.161229607044609],
              [-39.945031885938519, -8.161229645038665],
              [-39.945031920673323, -8.161229682837192],
              [-39.945031955622532, -8.161229720439023],
              [-39.945031990785047, -8.1612297578429],
              [-39.945032026159687, -8.161229795047632],
              [-39.945032061745344, -8.161229832052022],
              [-39.945032097540867, -8.161229868854932],
              [-39.945032133545126, -8.161229905455128],
              [-39.945032169756949, -8.161229941851442],
              [-39.945032206175171, -8.161229978042723],
              [-39.945032242798632, -8.161230014027783],
              [-39.945032279626147, -8.161230049805477],
              [-39.945032316656544, -8.161230085374642],
              [-39.945032353888614, -8.161230120734212],
              [-39.945032391321192, -8.161230155882951],
              [-39.945032428953049, -8.161230190819788],
              [-39.945032466782983, -8.161230225543598],
              [-39.945032504809788, -8.161230260053236],
              [-39.945032543032241, -8.161230294347622],
              [-39.945032581449105, -8.16123032842564],
              [-39.945032620059159, -8.161230362286226],
              [-39.945032658861152, -8.161230395928236],
              [-39.945032697853854, -8.161230429350647],
              [-39.945032737035994, -8.161230462552355],
              [-39.945032776406322, -8.161230495532276],
              [-39.945032815963572, -8.161230528289408],
              [-39.945032855706479, -8.161230560822659],
              [-39.945032895633759, -8.161230593130982],
              [-39.945032935744145, -8.161230625213344],
              [-39.945032976036323, -8.161230657068728],
              [-39.945033016509029, -8.161230688696101],
              [-39.945033057160948, -8.16123072009445],
              [-39.945033097990773, -8.161230751262767],
              [-39.945033138997189, -8.161230782200029],
              [-39.945033180178896, -8.161230812905298],
              [-39.945033221534551, -8.161230843377547],
              [-39.945033263062854, -8.161230873615802],
              [-39.94503330476244, -8.161230903619071],
              [-39.945033346631988, -8.161230933386433],
              [-39.945033388670154, -8.1612309629169],
              [-39.945033430875576, -8.161230992209537],
              [-39.945033473246909, -8.161231021263418],
              [-39.945033515782796, -8.161231050077594],
              [-39.945033558481867, -8.161231078651131],
              [-39.94503360134275, -8.161231106983145],
              [-39.945033644364052, -8.161231135072674],
              [-39.945033687544424, -8.161231162918856],
              [-39.945033730882471, -8.161231190520773],
              [-39.945033774376782, -8.161231217877557],
              [-39.945033818025962, -8.161231244988333],
              [-39.94503386182862, -8.161231271852222],
              [-39.945033905783362, -8.161231298468349],
              [-39.945033925048861, -8.161231260197487],
              [-39.946216234163941, -8.161942640086655],
              [-39.945896530824207, -8.163788791591875],
              [-39.947509223178542, -8.165823702030464],
              [-39.947509224266469, -8.165823673358281],
              [-39.947509349541882, -8.165823835151551],
              [-39.947509471116753, -8.165823999723635],
              [-39.947509588929037, -8.165824166990516],
              [-39.947509702918566, -8.165824336866821],
              [-39.94750981302716, -8.165824509265818],
              [-39.947509919198616, -8.165824684099503],
              [-39.947510021378719, -8.16582486127861],
              [-39.947510119515329, -8.165825040712704],
              [-39.947510213558338, -8.165825222310183],
              [-39.947510303459708, -8.165825405978328],
              [-39.947510389173594, -8.165825591623387],
              [-39.947510470656212, -8.165825779150596],
              [-39.947510547865974, -8.165825968464246],
              [-39.94751062076346, -8.165826159467635],
              [-39.947510689311457, -8.165826352063306],
              [-39.947510753474972, -8.165826546152891],
              [-39.947510813221243, -8.165826741637362],
              [-39.947510868519792, -8.165826938416897],
              [-39.947510919342363, -8.165827136391048],
              [-39.947510965663028, -8.165827335458753],
              [-39.947511007458125, -8.165827535518396],
              [-39.947511044706317, -8.165827736467824],
              [-39.94751107738864, -8.165827938204467],
              [-39.947511105488324, -8.165828140625337],
              [-39.947511128991096, -8.165828343627108],
              [-39.947511147884939, -8.16582854710613],
              [-39.947511162160197, -8.165828750958545],
              [-39.94751117180958, -8.165828955080274],
              [-39.947511176828158, -8.165829159367137],
              [-39.947511177213386, -8.165829363714831],
              [-39.947511172965072, -8.165829568019028],
              [-39.947511164085348, -8.165829772175451],
              [-39.947511150578798, -8.165829976079852],
              [-39.947511132452277, -8.165830179628189],
              [-39.947511109715052, -8.1658303827165],
              [-39.947511082378746, -8.165830585241116],
              [-39.947511050457273, -8.165830787098688],
              [-39.947511013966974, -8.165830988186109],
              [-39.947510972926473, -8.165831188400782],
              [-39.947510927356696, -8.165831387640475],
              [-39.947510877280891, -8.165831585803476],
              [-39.947510822724659, -8.165831782788626],
              [-39.94751076371584, -8.165831978495355],
              [-39.947510700284575, -8.165832172823782],
              [-39.947510632463199, -8.165832365674664],
              [-39.947510560286368, -8.165832556949567],
              [-39.947510483790929, -8.165832746550889],
              [-39.947510403015919, -8.165832934381775],
              [-39.947510318002578, -8.165833120346413],
              [-39.947510228794314, -8.165833304349771],
              [-39.947510135436666, -8.165833486297972],
              [-39.947510037977267, -8.165833666098166],
              [-39.947509936465899, -8.165833843658495],
              [-39.947509830954367, -8.165834018888335],
              [-39.947509721496537, -8.165834191698259],
              [-39.947509608148302, -8.165834362000037],
              [-39.947509490967505, -8.165834529706718],
              [-39.947509370013975, -8.1658346947327],
              [-39.947509245349458, -8.16583485699376],
              [-39.94750911703759, -8.165835016407039],
              [-39.947508985143898, -8.165835172891141],
              [-39.947508849735662, -8.165835326366215],
              [-39.947508710882062, -8.165835476753914],
              [-39.947508568653944, -8.16583562397744],
              [-39.947508423123928, -8.165835767961635],
              [-39.947508274366314, -8.165835908633035],
              [-39.947508122457045, -8.16583604591977],
              [-39.947507967473641, -8.165836179751793],
              [-39.947507809495249, -8.165836310060783],
              [-39.947507648602496, -8.165836436780234],
              [-39.947507484877519, -8.165836559845394],
              [-39.947507318403922, -8.165836679193513],
              [-39.947507149266677, -8.165836794763639],
              [-39.947506977552123, -8.165836906496716],
              [-39.947506803347927, -8.165837014335789],
              [-39.947506626742985, -8.165837118225779],
              [-39.947506447827521, -8.165837218113612],
              [-39.947506266692798, -8.165837313948368],
              [-39.947506083431328, -8.16583740568106],
              [-39.947505898136654, -8.165837493264871],
              [-39.947505710903364, -8.165837576655122],
              [-39.947505521827047, -8.165837655809222],
              [-39.947505331004223, -8.165837730686748],
              [-39.947505138532307, -8.165837801249504],
              [-39.947504944509554, -8.165837867461439],
              [-39.947504749035005, -8.165837929288768],
              [-39.947504552208443, -8.165837986699959],
              [-39.947504354130366, -8.165838039665662],
              [-39.947504154901893, -8.165838088158839],
              [-39.947503954624729, -8.165838132154741],
              [-39.947503753401094, -8.165838171630906],
              [-39.947503551333718, -8.165838206567187],
              [-39.947503348525757, -8.165838236945783],
              [-39.947503145080766, -8.165838262751144],
              [-39.94750294110257, -8.165838283970082],
              [-39.947502736695299, -8.165838300591812],
              [-39.94750253196333, -8.1658383126078],
              [-39.947502327011179, -8.165838320011956],
              [-39.947502121941874, -8.165838322801346],
              [-39.947502086699863, -8.165838280093453],
              [-39.94548554623853, -8.165842994863041],
              [-39.945485587344308, -8.165843037557538],
              [-39.945485528413343, -8.165843037885804],
              [-39.94548546948576, -8.165843038595266],
              [-39.945485410564061, -8.165843039685916],
              [-39.94548535165071, -8.165843041157713],
              [-39.945485292748181, -8.1658430430106],
              [-39.945485233858975, -8.165843045244483],
              [-39.945485174985564, -8.16584304785929],
              [-39.945485116130428, -8.165843050854884],
              [-39.945485057296054, -8.165843054231138],
              [-39.945484998484908, -8.165843057987932],
              [-39.94548493969949, -8.165843062125084],
              [-39.945484880942267, -8.165843066642465],
              [-39.945484822215718, -8.165843071539841],
              [-39.945484763522309, -8.165843076817005],
              [-39.945484704864533, -8.165843082473737],
              [-39.945484646244864, -8.165843088509826],
              [-39.945484587665739, -8.165843094924995],
              [-39.945484529129658, -8.165843101718989],
              [-39.94548447063908, -8.165843108891478],
              [-39.945484412196457, -8.165843116442225],
              [-39.945484353804282, -8.165843124370852],
              [-39.945484295464993, -8.165843132677065],
              [-39.945484237181063, -8.165843141360508],
              [-39.945484178954935, -8.165843150420816],
              [-39.945484120789061, -8.165843159857586],
              [-39.945484062685907, -8.165843169670437],
              [-39.945484004647923, -8.165843179858944],
              [-39.945483946677548, -8.165843190422693],
              [-39.94548388877724, -8.165843201361213],
              [-39.945483830949399, -8.165843212674053],
              [-39.9454837731965, -8.165843224360776],
              [-39.945483715520972, -8.165843236420855],
              [-39.945483657925237, -8.165843248853754],
              [-39.945483600411741, -8.165843261659003],
              [-39.945483542982871, -8.165843274836018],
              [-39.945483485641091, -8.165843288384272],
              [-39.945483428388783, -8.165843302303193],
              [-39.945483371228384, -8.165843316592179],
              [-39.945483314162296, -8.165843331250635],
              [-39.945483257192933, -8.165843346277969],
              [-39.94548320032267, -8.165843361673502],
              [-39.945483143553929, -8.165843377436603],
              [-39.945483086889091, -8.165843393566623],
              [-39.94548303033055, -8.165843410062857],
              [-39.945482973880686, -8.165843426924637],
              [-39.945482917541888, -8.165843444151214],
              [-39.945482861316513, -8.165843461741881],
              [-39.945482805206943, -8.165843479695901],
              [-39.94548274921555, -8.165843498012533],
              [-39.945482693344694, -8.165843516690996],
              [-39.945482637596697, -8.165843535730477],
              [-39.945482581973955, -8.165843555130188],
              [-39.945482526478784, -8.165843574889326],
              [-39.945482471113522, -8.165843595007052],
              [-39.945482415880534, -8.165843615482483],
              [-39.945482360782101, -8.165843636314815],
              [-39.945482305820583, -8.165843657503137],
              [-39.945482250998275, -8.165843679046544],
              [-39.945482196317514, -8.165843700944151],
              [-39.94548214178058, -8.165843723195026],
              [-39.945482087389763, -8.165843745798234],
              [-39.9454820331474, -8.165843768752833],
              [-39.945481979055728, -8.165843792057851],
              [-39.945481925117058, -8.165843815712275],
              [-39.945481871333641, -8.165843839715142],
              [-39.945481817707773, -8.165843864065414],
              [-39.945481764241698, -8.165843888762078],
              [-39.945481710937663, -8.1658439138041],
              [-39.945481657797927, -8.165843939190427],
              [-39.945481604824721, -8.165843964919981],
              [-39.945481552020283, -8.165843990991663],
              [-39.945481499386837, -8.165844017404417],
              [-39.945481446926607, -8.165844044157097],
              [-39.945481394641789, -8.16584407124855],
              [-39.945481342534613, -8.165844098677676],
              [-39.945481290607255, -8.165844126443327],
              [-39.945481238861895, -8.165844154544281],
              [-39.945481187300757, -8.16584418297939],
              [-39.945481135925952, -8.165844211747455],
              [-39.94548108473969, -8.165844240847267],
              [-39.945481033744137, -8.165844270277564],
              [-39.945480982941412, -8.165844300037145],
              [-39.945480932333652, -8.165844330124754],
              [-39.945480881923018, -8.165844360539113],
              [-39.94548083171162, -8.165844391278929],
              [-39.945480781701576, -8.16584442234292],
              [-39.945480731895003, -8.165844453729767],
              [-39.945480682293997, -8.165844485438164],
              [-39.945480632900626, -8.165844517466752],
              [-39.945480583716993, -8.165844549814183],
              [-39.94548053474518, -8.165844582479115],
              [-39.94548048598724, -8.16584461546017],
              [-39.945480437445219, -8.165844648755916],
              [-39.945480389121172, -8.165844682365003],
              [-39.945480341017145, -8.165844716285989],
              [-39.945480293135155, -8.165844750517438],
              [-39.945480245477221, -8.165844785057914],
              [-39.945480198045352, -8.165844819905963],
              [-39.94548015084154, -8.165844855060101],
              [-39.945480195077756, -8.165844816226517],
              [-39.94412665278351, -8.16685967409871],
              [-39.94412663905424, -8.166859693447222],
              [-39.944126628664861, -8.166859701248587],
              [-39.944126618286695, -8.166859709064804],
              [-39.944126607919799, -8.16685971689582],
              [-39.944126597564164, -8.166859724741615],
              [-39.944126587219834, -8.166859732602209],
              [-39.944126576886816, -8.166859740477571],
              [-39.944126566565131, -8.166859748367678],
              [-39.944126556254815, -8.166859756272498],
              [-39.944126545955875, -8.166859764192051],
              [-39.944126535668346, -8.166859772126283],
              [-39.944126525392235, -8.166859780075212],
              [-39.944126515127572, -8.166859788038803],
              [-39.944126504874347, -8.166859796017061],
              [-39.944126494632641, -8.166859804009944],
              [-39.944126484402432, -8.166859812017442],
              [-39.944126474183747, -8.166859820039521],
              [-39.944126463976623, -8.166859828076207],
              [-39.944126453781053, -8.166859836127459],
              [-39.944126443597092, -8.16685984419327],
              [-39.944126433424728, -8.166859852273614],
              [-39.944126423264017, -8.166859860368483],
              [-39.944126413114937, -8.166859868477847],
              [-39.944126402977552, -8.166859876601706],
              [-39.944126392851842, -8.166859884739992],
              [-39.944126382737863, -8.166859892892752],
              [-39.944126372635623, -8.166859901059961],
              [-39.94412636254512, -8.166859909241559],
              [-39.944126352466419, -8.166859917437566],
              [-39.944126342399514, -8.166859925647966],
              [-39.944126332344425, -8.166859933872731],
              [-39.944126322301166, -8.166859942111845],
              [-39.944126312269788, -8.166859950365295],
              [-39.944126302250275, -8.166859958633061],
              [-39.944126292242679, -8.166859966915126],
              [-39.944126282247005, -8.166859975211461],
              [-39.944126272263254, -8.166859983522071],
              [-39.944126262291498, -8.166859991846959],
              [-39.944126252331714, -8.166860000186034],
              [-39.944126242383931, -8.166860008539368],
              [-39.944126232448177, -8.166860016906861],
              [-39.94412622252446, -8.166860025288559],
              [-39.944126212612808, -8.166860033684411],
              [-39.944126202713264, -8.166860042094422],
              [-39.94412619282582, -8.166860050518538],
              [-39.944126182950491, -8.166860058956777],
              [-39.944126173087312, -8.166860067409104],
              [-39.944126163236305, -8.166860075875521],
              [-39.944126153397484, -8.166860084355976],
              [-39.944126143570891, -8.166860092850486],
              [-39.944126133756498, -8.166860101359017],
              [-39.944126123954362, -8.166860109881553],
              [-39.944126114164504, -8.166860118418079],
              [-39.944126104386932, -8.166860126968588],
              [-39.944126094621659, -8.166860135533049],
              [-39.944126084868728, -8.166860144111435],
              [-39.944126075128139, -8.166860152703759],
              [-39.944126065399921, -8.166860161309964],
              [-39.94412605568408, -8.166860169930073],
              [-39.94412604598066, -8.166860178564038],
              [-39.944126036289681, -8.166860187211853],
              [-39.944126026611151, -8.166860195873484],
              [-39.94412601694507, -8.166860204548938],
              [-39.94412600729148, -8.166860213238175],
              [-39.944125997650403, -8.166860221941194],
              [-39.94412598802186, -8.166860230657971],
              [-39.944125978405857, -8.166860239388493],
              [-39.944125968802418, -8.166860248132732],
              [-39.944125959211576, -8.166860256890693],
              [-39.944125949633339, -8.166860265662319],
              [-39.944125940067721, -8.166860274447643],
              [-39.944125930514772, -8.166860283246601],
              [-39.944125920974471, -8.16686029205921],
              [-39.944125911446861, -8.166860300885418],
              [-39.944125901931955, -8.166860309725223],
              [-39.944125892429767, -8.166860318578609],
              [-39.944125882940341, -8.166860327445544],
              [-39.944125873463669, -8.166860336326033],
              [-39.94412586399978, -8.16686034522003],
              [-39.944125854548687, -8.166860354127563],
              [-39.944125845110413, -8.166860363048542],
              [-39.944125835685, -8.166860371983017],
              [-39.94412582627244, -8.166860380930924],
              [-39.944125816872756, -8.166860389892271],
              [-39.94412580748596, -8.166860398867074],
              [-39.944125798112104, -8.166860407855236],
              [-39.944125788751187, -8.166860416856778],
              [-39.944125779403215, -8.166860425871684],
              [-39.944125770068219, -8.166860434899917],
              [-39.944125760746232, -8.166860443941481],
              [-39.944125751437248, -8.166860452996351],
              [-39.944125742141296, -8.166860462064486],
              [-39.944125732858396, -8.166860471145903],
              [-39.944125723588591, -8.166860480240578],
              [-39.944125714331854, -8.166860489348462],
              [-39.94412570508824, -8.166860498469575],
              [-39.944125695857743, -8.166860507603868],
              [-39.944125686640412, -8.166860516751331],
              [-39.94412567743624, -8.166860525911943],
              [-39.944125668245249, -8.166860535085696],
              [-39.944125656225559, -8.166860490412956],
              [-39.94310968496471, -8.167876005076501],
              [-39.943109141616688, -8.16787654900263],
              [-39.941075486108595, -8.169570403332255],
              [-39.941075509984358, -8.169570408668172],
              [-39.941075502479009, -8.169570414912636],
              [-39.941075494966924, -8.169570421149043],
              [-39.941075487448103, -8.169570427377385],
              [-39.941075479922539, -8.169570433597634],
              [-39.941075472390274, -8.169570439809817],
              [-39.941075464851281, -8.169570446013909],
              [-39.941075457305594, -8.1695704522099],
              [-39.941075449753214, -8.1695704583978],
              [-39.941075442194141, -8.16957046457758],
              [-39.941075434628402, -8.169570470749257],
              [-39.941075427055985, -8.169570476912778],
              [-39.94107541947691, -8.169570483068195],
              [-39.941075411891191, -8.169570489215456],
              [-39.941075404298822, -8.169570495354579],
              [-39.94107539669983, -8.169570501485563],
              [-39.941075389094202, -8.169570507608361],
              [-39.941075381481959, -8.16957051372302],
              [-39.941075373863129, -8.169570519829492],
              [-39.941075366237676, -8.169570525927783],
              [-39.941075358605659, -8.169570532017911],
              [-39.941075350967054, -8.169570538099832],
              [-39.941075343321877, -8.169570544173514],
              [-39.941075335670135, -8.169570550239023],
              [-39.941075328011841, -8.169570556296323],
              [-39.941075320346997, -8.16957056234537],
              [-39.941075312675643, -8.169570568386193],
              [-39.941075304997739, -8.169570574418792],
              [-39.941075297313319, -8.169570580443139],
              [-39.941075289622397, -8.16957058645926],
              [-39.941075281924981, -8.169570592467092],
              [-39.941075274221056, -8.169570598466651],
              [-39.941075266510666, -8.169570604457968],
              [-39.941075258793781, -8.169570610440982],
              [-39.941075251070444, -8.169570616415719],
              [-39.941075243340656, -8.169570622382169],
              [-39.94107523560443, -8.16957062834031],
              [-39.941075227861759, -8.169570634290158],
              [-39.941075220112658, -8.169570640231687],
              [-39.941075212357148, -8.169570646164887],
              [-39.941075204595215, -8.169570652089766],
              [-39.941075196826894, -8.169570658006307],
              [-39.941075189052171, -8.169570663914502],
              [-39.94107518127106, -8.169570669814375],
              [-39.941075173483583, -8.169570675705875],
              [-39.941075165689746, -8.169570681589011],
              [-39.941075157889543, -8.169570687463763],
              [-39.941075150083009, -8.169570693330154],
              [-39.94107514227013, -8.169570699188169],
              [-39.941075134450912, -8.169570705037797],
              [-39.941075126625392, -8.169570710879009],
              [-39.941075118793542, -8.169570716711837],
              [-39.941075110955396, -8.169570722536236],
              [-39.941075103110975, -8.169570728352225],
              [-39.941075095260253, -8.169570734159825],
              [-39.941075087403263, -8.169570739958948],
              [-39.941075079540006, -8.169570745749661],
              [-39.941075071670504, -8.169570751531918],
              [-39.941075063794727, -8.169570757305733],
              [-39.941075055912734, -8.169570763071084],
              [-39.941075048024508, -8.169570768827981],
              [-39.941075040130059, -8.1695707745764],
              [-39.941075032229413, -8.169570780316342],
              [-39.941075024322558, -8.169570786047823],
              [-39.941075016409513, -8.169570791770795],
              [-39.941075008490287, -8.169570797485276],
              [-39.941075000564886, -8.169570803191249],
              [-39.941074992633318, -8.169570808888709],
              [-39.941074984695597, -8.169570814577673],
              [-39.941074976751722, -8.169570820258102],
              [-39.941074968801715, -8.169570825929981],
              [-39.94107496084559, -8.169570831593337],
              [-39.941074952883348, -8.169570837248159],
              [-39.941074944914988, -8.169570842894426],
              [-39.941074936940531, -8.169570848532148],
              [-39.941074928959985, -8.169570854161305],
              [-39.94107492097335, -8.169570859781878],
              [-39.941074912980646, -8.169570865393887],
              [-39.941074904981882, -8.16957087099733],
              [-39.941074896977064, -8.169570876592164],
              [-39.941074888966206, -8.169570882178389],
              [-39.941074880949309, -8.169570887756016],
              [-39.941074872926386, -8.16957089332505],
              [-39.941074864897452, -8.169570898885457],
              [-39.941074856862514, -8.169570904437251],
              [-39.941074848821565, -8.169570909980417],
              [-39.94107484077464, -8.169570915514958],
              [-39.941074832721725, -8.169570921040849],
              [-39.941074824662856, -8.169570926558098],
              [-39.941074816598018, -8.169570932066716],
              [-39.941074808527226, -8.169570937566657],
              [-39.941074800450494, -8.16957094305792],
              [-39.941074792367843, -8.169570948540509],
              [-39.941074784279252, -8.169570954014443],
              [-39.941074776184756, -8.169570959479687],
              [-39.941074768084356, -8.169570964936232],
              [-39.941074759978065, -8.169570970384067],
              [-39.941074751865891, -8.169570975823222],
              [-39.941074743747819, -8.169570981253646],
              [-39.941074735623943, -8.169570986675359],
              [-39.941074761214026, -8.169570947678414],
              [-39.939040933184643, -8.170926716454872],
              [-39.939040909205914, -8.170926725481788],
              [-39.939040891737015, -8.170926737097268],
              [-39.939040874241186, -8.170926748672461],
              [-39.939040856718535, -8.170926760207267],
              [-39.939040839169145, -8.170926771701687],
              [-39.939040821593125, -8.170926783155585],
              [-39.93904080399053, -8.170926794568963],
              [-39.939040786361481, -8.170926805941722],
              [-39.939040768706086, -8.170926817273804],
              [-39.939040751024422, -8.170926828565161],
              [-39.939040733316574, -8.170926839815712],
              [-39.939040715582657, -8.17092685102542],
              [-39.939040697822762, -8.170926862194218],
              [-39.939040680036968, -8.170926873322037],
              [-39.939040662225409, -8.170926884408816],
              [-39.939040644388122, -8.170926895454544],
              [-39.939040626525248, -8.170926906459089],
              [-39.939040608636873, -8.170926917422427],
              [-39.939040590723074, -8.170926928344512],
              [-39.939040572783973, -8.170926939225266],
              [-39.939040554819648, -8.170926950064619],
              [-39.939040536830205, -8.170926960862541],
              [-39.939040518815744, -8.170926971618941],
              [-39.939040500776343, -8.170926982333841],
              [-39.939040482712109, -8.170926993007086],
              [-39.93904046462314, -8.17092700363866],
              [-39.93904044650953, -8.170927014228495],
              [-39.939040428371392, -8.170927024776558],
              [-39.939040410208797, -8.170927035282796],
              [-39.939040392021859, -8.170927045747094],
              [-39.93904037381067, -8.17092705616947],
              [-39.939040355575329, -8.170927066549851],
              [-39.93904033731593, -8.170927076888136],
              [-39.939040319032593, -8.170927087184316],
              [-39.939040300725367, -8.170927097438309],
              [-39.93904028239438, -8.170927107650066],
              [-39.939040264039761, -8.17092711781957],
              [-39.939040245661545, -8.170927127946713],
              [-39.939040227259881, -8.170927138031439],
              [-39.939040208834847, -8.17092714807375],
              [-39.939040190386535, -8.170927158073548],
              [-39.939040171915053, -8.170927168030772],
              [-39.939040153420507, -8.170927177945416],
              [-39.939040134902982, -8.170927187817393],
              [-39.939040116362584, -8.170927197646646],
              [-39.939040097799406, -8.170927207433108],
              [-39.939040079213576, -8.170927217176789],
              [-39.939040060605144, -8.170927226877561],
              [-39.939040041974252, -8.170927236535466],
              [-39.93904002332097, -8.17092724615034],
              [-39.939040004645413, -8.170927255722225],
              [-39.939039985947694, -8.170927265251002],
              [-39.939039967227885, -8.170927274736671],
              [-39.939039948486112, -8.170927284179147],
              [-39.939039929722448, -8.170927293578412],
              [-39.939039910937026, -8.170927302934381],
              [-39.939039892129919, -8.170927312247018],
              [-39.93903987330124, -8.170927321516265],
              [-39.939039854451089, -8.170927330742096],
              [-39.939039835579557, -8.170927339924424],
              [-39.939039816686751, -8.170927349063243],
              [-39.939039797772793, -8.170927358158456],
              [-39.939039778837746, -8.170927367210078],
              [-39.939039759881751, -8.170927376218026],
              [-39.939039740904867, -8.170927385182241],
              [-39.939039721907236, -8.170927394102685],
              [-39.939039702888948, -8.170927402979292],
              [-39.939039683850083, -8.170927411812047],
              [-39.939039664790755, -8.170927420600878],
              [-39.939039645711077, -8.170927429345758],
              [-39.939039626611162, -8.170927438046629],
              [-39.939039607491061, -8.170927446703445],
              [-39.939039588350923, -8.170927455316166],
              [-39.939039569190861, -8.17092746388475],
              [-39.939039550010918, -8.170927472409122],
              [-39.939039530811257, -8.170927480889267],
              [-39.93903951159195, -8.170927489325138],
              [-39.939039492353082, -8.170927497716661],
              [-39.939039473094809, -8.170927506063808],
              [-39.939039453817188, -8.170927514366543],
              [-39.939039434520353, -8.170927522624797],
              [-39.939039415204377, -8.170927530838568],
              [-39.939039395869401, -8.170927539007792],
              [-39.939039376515495, -8.170927547132385],
              [-39.939039357142775, -8.170927555212346],
              [-39.939039337751339, -8.170927563247671],
              [-39.939039318341308, -8.170927571238245],
              [-39.939039298912768, -8.170927579184054],
              [-39.939039279465831, -8.170927587085055],
              [-39.939039260000591, -8.17092759494119],
              [-39.939039240517175, -8.170927602752439],
              [-39.939039221015669, -8.170927610518747],
              [-39.939039201496179, -8.170927618240087],
              [-39.939039181958805, -8.17092762591637],
              [-39.939039162403674, -8.170927633547647],
              [-39.939039142830872, -8.170927641133799],
              [-39.939039123240491, -8.170927648674793],
              [-39.939039103632659, -8.170927656170628],
              [-39.939039084007483, -8.170927663621223],
              [-39.939039064365168, -8.170927671024973],
              [-39.939039029317854, -8.170927715751422],
              [-39.936350641169092, -8.171937688443027],
              [-39.936350605685405, -8.171937686436442],
              [-39.936350391782469, -8.171937769891088],
              [-39.936350180067492, -8.171937858712282],
              [-39.936349970676126, -8.171937952843116],
              [-39.936349763742442, -8.171938052223295],
              [-39.936349559398991, -8.171938156789196],
              [-39.936349357776614, -8.17193826647383],
              [-39.936349159004457, -8.171938381206946],
              [-39.936348963209845, -8.17193850091509],
              [-39.936348770518151, -8.171938625521552],
              [-39.936348581052791, -8.17193875494657],
              [-39.936348394935088, -8.171938889107246],
              [-39.93634821228428, -8.171939027917642],
              [-39.936348033217321, -8.17193917128886],
              [-39.936347857848894, -8.171939319129084],
              [-39.936347686291313, -8.171939471343604],
              [-39.936347518654472, -8.171939627834995],
              [-39.936347355045704, -8.171939788502954],
              [-39.936347195569816, -8.17193995324465],
              [-39.936347040328933, -8.171940121954536],
              [-39.936346889422481, -8.171940294524568],
              [-39.936346742947109, -8.171940470844239],
              [-39.936346600996643, -8.17194065080059],
              [-39.936346463661963, -8.171940834278407],
              [-39.936346331031046, -8.171941021160146],
              [-39.936346203188833, -8.171941211326144],
              [-39.936346080217191, -8.171941404654602],
              [-39.936345962194899, -8.171941601021702],
              [-39.936345849197522, -8.17194180030166],
              [-39.936345741297444, -8.171942002366883],
              [-39.936345638563751, -8.171942207087936],
              [-39.936345541062259, -8.171942414333733],
              [-39.936345448855398, -8.171942623971519],
              [-39.936345362002243, -8.171942835867023],
              [-39.936345280558392, -8.171943049884558],
              [-39.936345204576028, -8.171943265887052],
              [-39.93634513410381, -8.171943483736158],
              [-39.936345069186842, -8.171943703292365],
              [-39.936345009866749, -8.171943924415046],
              [-39.936344956181493, -8.171944146962602],
              [-39.936344908165452, -8.171944370792454],
              [-39.936344865849399, -8.171944595761298],
              [-39.93634482926042, -8.17194482172504],
              [-39.936344798421949, -8.171945048538946],
              [-39.936344773353738, -8.171945276057791],
              [-39.936344754071854, -8.171945504135829],
              [-39.936344740588645, -8.171945732626995],
              [-39.93634473291273, -8.17194596138496],
              [-39.936344731049033, -8.171946190263194],
              [-39.93634473499872, -8.17194641911512],
              [-39.936344744759317, -8.17194664779419],
              [-39.936344760324538, -8.17194687615393],
              [-39.936344781684426, -8.171947104048087],
              [-39.936344808825297, -8.171947331330701],
              [-39.936344841729756, -8.171947557856239],
              [-39.936344880376751, -8.171947783479583],
              [-39.936344924741512, -8.171948008056257],
              [-39.936344974795638, -8.171948231442418],
              [-39.93634503050707, -8.171948453494986],
              [-39.936345091840131, -8.171948674071789],
              [-39.936345158755522, -8.17194889303153],
              [-39.93634523121041, -8.171949110233957],
              [-39.936345309158369, -8.171949325540011],
              [-39.936345392549498, -8.171949538811768],
              [-39.936345481330378, -8.171949749912663],
              [-39.936345575444165, -8.17194995870747],
              [-39.936345674830555, -8.171950165062459],
              [-39.936345779425913, -8.171950368845486],
              [-39.936345889163249, -8.171950569926056],
              [-39.936346003972275, -8.171950768175359],
              [-39.936346123779487, -8.171950963466411],
              [-39.936346248508116, -8.171951155674195],
              [-39.936346378078326, -8.171951344675559],
              [-39.936346512407098, -8.171951530349453],
              [-39.936346651408392, -8.171951712577004],
              [-39.936346794993213, -8.171951891241525],
              [-39.936346943069594, -8.171952066228505],
              [-39.9363470955427, -8.171952237425927],
              [-39.936347252314874, -8.17195240472412],
              [-39.936347413285709, -8.17195256801598],
              [-39.936347578352127, -8.171952727196921],
              [-39.936347747408391, -8.171952882164971],
              [-39.93634792034625, -8.171953032820877],
              [-39.936348097054911, -8.171953179068192],
              [-39.936348277421239, -8.171953320813163],
              [-39.936348461329708, -8.171953457965115],
              [-39.936348648662531, -8.171953590436148],
              [-39.936348839299718, -8.171953718141438],
              [-39.936349033119193, -8.171953840999208],
              [-39.936349229996807, -8.171953958930727],
              [-39.936349429806498, -8.17195407186054],
              [-39.936349632420274, -8.171954179716225],
              [-39.936349837708377, -8.171954282428819],
              [-39.936350045539321, -8.171954379932464],
              [-39.936350255780006, -8.171954472164719],
              [-39.936350468295807, -8.171954559066526],
              [-39.936350682950582, -8.171954640582221],
              [-39.936350899606872, -8.171954716659616],
              [-39.936351118125927, -8.171954787249989],
              [-39.936351338367786, -8.171954852308115],
              [-39.936351316633825, -8.171954871140999],
              [-39.938692320940568, -8.17261438573879],
              [-39.938692344557111, -8.172614371869129],
              [-39.938692510118535, -8.172614420208955],
              [-39.938692674728003, -8.17261447167526],
              [-39.938692838326041, -8.172614526249363],
              [-39.938693000853561, -8.172614583911663],
              [-39.938693162251838, -8.172614644641246],
              [-39.938693322462591, -8.172614708416205],
              [-39.938693481427919, -8.172614775213503],
              [-39.938693639090424, -8.172614845009004],
              [-39.938693795393121, -8.1726149177775],
              [-39.938693950279585, -8.172614993492688],
              [-39.938694103693834, -8.172615072127263],
              [-39.938694255580472, -8.172615153652758],
              [-39.938694405884611, -8.172615238039786],
              [-39.938694554551972, -8.1726153252578],
              [-39.938694701528831, -8.172615415275333],
              [-39.938694846762104, -8.172615508059826],
              [-39.938694990199345, -8.172615603577833],
              [-39.938695131788712, -8.172615701794799],
              [-39.938695271479062, -8.17261580267523],
              [-39.93869540921996, -8.172615906182722],
              [-39.938695544961625, -8.172616012279871],
              [-39.938695678655009, -8.17261612092835],
              [-39.938695810251843, -8.17261623208892],
              [-39.93869593970458, -8.172616345721405],
              [-39.938696066966443, -8.172616461784779],
              [-39.938696191991482, -8.172616580237085],
              [-39.938696314734521, -8.172616701035551],
              [-39.938696435151215, -8.172616824136565],
              [-39.938696553198092, -8.172616949495586],
              [-39.938696668832463, -8.172617077067374],
              [-39.938696782012606, -8.17261720680591],
              [-39.938696892697592, -8.172617338664228],
              [-39.938697000847476, -8.172617472594721],
              [-39.938697106423156, -8.172617608549041],
              [-39.938697209386525, -8.172617746478046],
              [-39.938697309700359, -8.172617886331905],
              [-39.938697407328448, -8.172618028060114],
              [-39.938697502235506, -8.1726181716115],
              [-39.938697594387257, -8.17261831693417],
              [-39.938697683750398, -8.172618463975603],
              [-39.93869777029267, -8.172618612682765],
              [-39.938697853982809, -8.172618763001879],
              [-39.938697934790561, -8.172618914878626],
              [-39.938698012686736, -8.172619068258186],
              [-39.938698087643218, -8.17261922308515],
              [-39.938698159632928, -8.172619379303562],
              [-39.938698228629839, -8.172619536857001],
              [-39.938698294609047, -8.172619695688548],
              [-39.938698357546713, -8.172619855740834],
              [-39.938698417420078, -8.172620016956056],
              [-39.938698474207541, -8.172620179275929],
              [-39.938698527888583, -8.172620342641858],
              [-39.938698578443812, -8.172620506994823],
              [-39.938698625854961, -8.172620672275464],
              [-39.938698670104905, -8.172620838424042],
              [-39.938698711177651, -8.172621005380563],
              [-39.938698749058382, -8.1726211730847],
              [-39.938698783733393, -8.172621341475862],
              [-39.938698815190179, -8.172621510493252],
              [-39.938698843417356, -8.172621680075807],
              [-39.938698868404742, -8.172621850162272],
              [-39.938698890143293, -8.172622020691186],
              [-39.938698908625177, -8.172622191600951],
              [-39.93869892384371, -8.172622362829832],
              [-39.93869893579339, -8.172622534315961],
              [-39.938698944469898, -8.172622705997419],
              [-39.938698949870108, -8.172622877812158],
              [-39.938698951992073, -8.172623049698148],
              [-39.938698950835004, -8.172623221593248],
              [-39.938698946399342, -8.172623393435414],
              [-39.938698938686692, -8.172623565162535],
              [-39.938698927699811, -8.172623736712611],
              [-39.938698913442714, -8.172623908023628],
              [-39.938698895920496, -8.172624079033715],
              [-39.938698875139529, -8.1726242496811],
              [-39.938698851107283, -8.172624419904137],
              [-39.938698823832503, -8.172624589641359],
              [-39.938698793324988, -8.172624758831432],
              [-39.938698759595752, -8.172624927413215],
              [-39.938698722657008, -8.17262509532585],
              [-39.938698682522087, -8.172625262508637],
              [-39.938698639205505, -8.172625428901203],
              [-39.938698592722879, -8.172625594443469],
              [-39.938698543091029, -8.17262575907561],
              [-39.938698490327866, -8.172625922738149],
              [-39.938698434452448, -8.172626085371965],
              [-39.938698375484968, -8.172626246918332],
              [-39.938698313446729, -8.172626407318841],
              [-39.938698248360147, -8.1726265665156],
              [-39.938698180248714, -8.172626724451087],
              [-39.938698109137036, -8.172626881068235],
              [-39.938698035050841, -8.17262703631048],
              [-39.93869795801686, -8.172627190121748],
              [-39.938697878062897, -8.17262734244647],
              [-39.938697795217898, -8.172627493229607],
              [-39.938697709511715, -8.172627642416719],
              [-39.938697620975375, -8.172627789953888],
              [-39.938697529640812, -8.17262793578783],
              [-39.938697435542217, -8.172628079864985],
              [-39.938697436277998, -8.172628121267682],
              [-39.936678831051651, -8.17565542127565],
              [-39.936678844762127, -8.175655433644211],
              [-39.936678759797879, -8.175655563466822],
              [-39.936678677078802, -8.175655694721005],
              [-39.936678596629172, -8.175655827368242],
              [-39.936678518472611, -8.175655961369552],
              [-39.9366784426321, -8.175656096685573],
              [-39.936678369129865, -8.175656233276586],
              [-39.93667829798752, -8.175656371102495],
              [-39.936678229225954, -8.175656510122815],
              [-39.936678162865363, -8.175656650296727],
              [-39.936678098925221, -8.17565679158303],
              [-39.936678037424301, -8.175656933940303],
              [-39.936677978380672, -8.175657077326685],
              [-39.936677921811672, -8.175657221700106],
              [-39.936677867733913, -8.175657367018157],
              [-39.936677816163268, -8.175657513238166],
              [-39.936677767114901, -8.175657660317192],
              [-39.936677720603178, -8.175657808212037],
              [-39.9366776766418, -8.175657956879306],
              [-39.93667763524364, -8.175658106275288],
              [-39.936677596420864, -8.17565825635616],
              [-39.936677560184897, -8.175658407077851],
              [-39.936677526546354, -8.175658558396069],
              [-39.936677495515113, -8.175658710266401],
              [-39.936677467100296, -8.175658862644232],
              [-39.936677441310259, -8.175659015484849],
              [-39.936677418152534, -8.175659168743353],
              [-39.936677397633979, -8.175659322374724],
              [-39.936677379760553, -8.175659476333877],
              [-39.936677364537566, -8.175659630575586],
              [-39.93667735196945, -8.175659785054554],
              [-39.936677342059909, -8.17565993972541],
              [-39.936677334811854, -8.175660094542748],
              [-39.936677330227418, -8.175660249461123],
              [-39.93667732830793, -8.175660404435014],
              [-39.936677329053964, -8.175660559418905],
              [-39.936677332465308, -8.175660714367327],
              [-39.936677338540953, -8.175660869234759],
              [-39.936677347279122, -8.17566102397571],
              [-39.936677358677223, -8.175661178544745],
              [-39.93667737273195, -8.175661332896508],
              [-39.936677389439133, -8.175661486985625],
              [-39.936677408793891, -8.175661640766888],
              [-39.936677430790546, -8.175661794195083],
              [-39.936677455422625, -8.175661947225191],
              [-39.936677482682896, -8.175662099812282],
              [-39.93667751256335, -8.175662251911552],
              [-39.936677545055232, -8.175662403478322],
              [-39.936677580148988, -8.175662554468092],
              [-39.936677617834299, -8.175662704836514],
              [-39.936677658100116, -8.175662854539459],
              [-39.936677700934624, -8.175663003532927],
              [-39.936677746325223, -8.175663151773188],
              [-39.936677794258593, -8.17566329921673],
              [-39.936677844720656, -8.175663445820211],
              [-39.93667789769659, -8.175663591540614],
              [-39.93667795317085, -8.175663736335132],
              [-39.936678011127157, -8.175663880161265],
              [-39.936678071548464, -8.175664022976742],
              [-39.936678134417029, -8.175664164739663],
              [-39.936678199714407, -8.175664305408381],
              [-39.936678267421421, -8.175664444941619],
              [-39.936678337518195, -8.175664583298362],
              [-39.936678409984125, -8.175664720437979],
              [-39.936678484797945, -8.175664856320255],
              [-39.936678561937661, -8.175664990905211],
              [-39.936678641380674, -8.175665124153385],
              [-39.936678723103618, -8.175665256025608],
              [-39.936678807082494, -8.175665386483207],
              [-39.936678893292644, -8.175665515487808],
              [-39.936678981708766, -8.17566564300159],
              [-39.936679072304891, -8.175665768987074],
              [-39.9366791650544, -8.175665893407281],
              [-39.936679259930074, -8.175666016225609],
              [-39.936679356904051, -8.175666137406081],
              [-39.936679455947846, -8.175666256913093],
              [-39.936679557032377, -8.17566637471152],
              [-39.936679660127965, -8.175666490766799],
              [-39.936679765204332, -8.175666605044816],
              [-39.936679872230634, -8.175666717512067],
              [-39.93667998117543, -8.175666828135492],
              [-39.936680092006732, -8.1756669368826],
              [-39.936680204691989, -8.175667043721486],
              [-39.936680319198118, -8.175667148620729],
              [-39.936680435491489, -8.175667251549559],
              [-39.936680553537961, -8.175667352477772],
              [-39.936680673302845, -8.17566745137569],
              [-39.936680794751005, -8.175667548214268],
              [-39.936680917846772, -8.175667642965106],
              [-39.936681042553978, -8.175667735600355],
              [-39.93668116883601, -8.175667826092821],
              [-39.936681296655799, -8.175667914415927],
              [-39.936681425975785, -8.175668000543732],
              [-39.936681556758018, -8.175668084450953],
              [-39.9366816889641, -8.175668166112965],
              [-39.936681822555187, -8.175668245505749],
              [-39.936681957492056, -8.175668322606031],
              [-39.936682093735087, -8.175668397391162],
              [-39.936682231244248, -8.175668469839149],
              [-39.936682369979202, -8.175668539928756],
              [-39.936682401768799, -8.175668527305294],
              [-39.938710702865556, -8.176671544032468],
              [-39.93871070554794, -8.17667158327872],
              [-39.938710765520234, -8.176671612662068],
              [-39.938710825708036, -8.176671641604672],
              [-39.938710886108069, -8.176671670104987],
              [-39.938710946717045, -8.17667169816144],
              [-39.938711007531715, -8.176671725772517],
              [-39.938711068548741, -8.176671752936734],
              [-39.938711129764854, -8.176671779652612],
              [-39.938711191176701, -8.176671805918707],
              [-39.938711252780983, -8.176671831733584],
              [-39.938711314574356, -8.176671857095865],
              [-39.938711376553428, -8.176671882004149],
              [-39.938711438714897, -8.176671906457118],
              [-39.938711501055352, -8.176671930453423],
              [-39.938711563571445, -8.176671953991761],
              [-39.938711626259767, -8.176671977070871],
              [-39.938711689116936, -8.1766719996895],
              [-39.938711752139533, -8.176672021846422],
              [-39.93871181532414, -8.176672043540419],
              [-39.938711878667334, -8.176672064770303],
              [-39.938711942165682, -8.176672085534987],
              [-39.938712005815759, -8.176672105833282],
              [-39.938712069614091, -8.176672125664114],
              [-39.938712133557217, -8.176672145026417],
              [-39.938712197641692, -8.176672163919124],
              [-39.938712261864033, -8.176672182341212],
              [-39.938712326220745, -8.176672200291677],
              [-39.938712390708382, -8.176672217769566],
              [-39.938712455323397, -8.176672234773932],
              [-39.938712520062317, -8.176672251303836],
              [-39.938712584921618, -8.176672267358393],
              [-39.938712649897788, -8.176672282936721],
              [-39.938712714987318, -8.176672298037984],
              [-39.93871278018667, -8.176672312661347],
              [-39.938712845492304, -8.176672326806047],
              [-39.938712910900698, -8.17667234047131],
              [-39.938712976408283, -8.176672353656377],
              [-39.938713042011528, -8.176672366360561],
              [-39.938713107706867, -8.176672378583145],
              [-39.938713173490733, -8.176672390323489],
              [-39.938713239359572, -8.176672401580934],
              [-39.938713305309825, -8.176672412354886],
              [-39.938713371337876, -8.176672422644764],
              [-39.938713437440185, -8.176672432450001],
              [-39.938713503613144, -8.176672441770053],
              [-39.938713569853192, -8.176672450604437],
              [-39.938713636156741, -8.176672458952677],
              [-39.938713702520147, -8.176672466814287],
              [-39.938713768939856, -8.176672474188875],
              [-39.938713835412258, -8.176672481076022],
              [-39.938713901933738, -8.176672487475377],
              [-39.938713968500721, -8.176672493386601],
              [-39.938714035109562, -8.176672498809323],
              [-39.938714101756688, -8.176672503743291],
              [-39.938714168438452, -8.176672508188213],
              [-39.938714235151252, -8.176672512143853],
              [-39.938714301891466, -8.176672515609999],
              [-39.938714368655504, -8.176672518586493],
              [-39.938714435439714, -8.176672521073131],
              [-39.938714502240487, -8.17667252306979],
              [-39.938714569054213, -8.176672524576357],
              [-39.938714635877233, -8.176672525592775],
              [-39.93871470270598, -8.176672526118987],
              [-39.938714769536809, -8.176672526154915],
              [-39.938714836366081, -8.176672525700635],
              [-39.938714903190174, -8.176672524756105],
              [-39.938714970005499, -8.176672523321418],
              [-39.938715036808397, -8.176672521396636],
              [-39.938715103595271, -8.176672518981844],
              [-39.938715170362485, -8.176672516077206],
              [-39.938715237106415, -8.176672512682851],
              [-39.938715303823464, -8.176672508798969],
              [-39.93871537051001, -8.17667250442579],
              [-39.938715437162429, -8.176672499563557],
              [-39.938715503777125, -8.176672494212497],
              [-39.93871557035046, -8.176672488372924],
              [-39.938715636878854, -8.176672482045145],
              [-39.938715703358675, -8.176672475229513],
              [-39.938715769786349, -8.176672467926378],
              [-39.938715836158245, -8.176672460136144],
              [-39.938715902470783, -8.176672451859259],
              [-39.938715968720352, -8.176672443096132],
              [-39.938716034903386, -8.176672433847278],
              [-39.938716101016283, -8.176672424113182],
              [-39.938716167055453, -8.176672413894361],
              [-39.938716233017324, -8.176672403191377],
              [-39.938716298898349, -8.176672392004784],
              [-39.938716364694898, -8.176672380335233],
              [-39.938716430403439, -8.176672368183327],
              [-39.938716496020419, -8.176672355549742],
              [-39.93871656154225, -8.176672342435147],
              [-39.938716626965409, -8.17667232884029],
              [-39.938716692286341, -8.176672314765838],
              [-39.938716757501503, -8.176672300212603],
              [-39.938716822607354, -8.176672285181374],
              [-39.938716887600386, -8.176672269672963],
              [-39.938716952477058, -8.176672253688205],
              [-39.938717017233827, -8.176672237227958],
              [-39.938717081867253, -8.176672220293147],
              [-39.938717146373769, -8.176672202884641],
            ],
          ],
          [
            [
              [-39.945863626981328, -8.184770672036782],
              [-39.94586363225519, -8.184770703547708],
              [-39.944683835993729, -8.186080821279001],
              [-39.948021627778701, -8.184661092016581],
              [-39.948406125177314, -8.17919977554045],
              [-39.944642673851057, -8.176079433440291],
              [-39.945172930512996, -8.178684768321194],
              [-39.945172900097454, -8.178684800821728],
              [-39.945172903682035, -8.178684818526719],
              [-39.945172907231104, -8.178684836238837],
              [-39.945172910744688, -8.178684853957961],
              [-39.945172914222745, -8.178684871684057],
              [-39.945172917665239, -8.178684889417026],
              [-39.945172921072199, -8.178684907156818],
              [-39.945172924443604, -8.178684924903346],
              [-39.945172927779424, -8.178684942656561],
              [-39.945172931079654, -8.178684960416344],
              [-39.945172934344292, -8.178684978182684],
              [-39.945172937573304, -8.178684995955471],
              [-39.945172940766696, -8.178685013734643],
              [-39.945172943924433, -8.178685031520132],
              [-39.945172947046537, -8.178685049311902],
              [-39.945172950132964, -8.178685067109821],
              [-39.945172953183715, -8.178685084913869],
              [-39.945172956198761, -8.178685102723938],
              [-39.945172959178116, -8.178685120539946],
              [-39.945172962121759, -8.178685138361866],
              [-39.94517296502967, -8.178685156189598],
              [-39.94517296790184, -8.178685174023096],
              [-39.94517297073827, -8.178685191862266],
              [-39.945172973538945, -8.178685209707059],
              [-39.945172976303823, -8.178685227557381],
              [-39.94517297903294, -8.17868524541316],
              [-39.945172981726252, -8.178685263274346],
              [-39.945172984383767, -8.178685281140856],
              [-39.945172987005463, -8.178685299012622],
              [-39.945172989591335, -8.178685316889542],
              [-39.945172992141373, -8.178685334771584],
              [-39.94517299465555, -8.178685352658661],
              [-39.945172997133888, -8.178685370550721],
              [-39.945172999576343, -8.17868538844767],
              [-39.94517300198293, -8.178685406349455],
              [-39.945173004353641, -8.178685424255985],
              [-39.945173006688449, -8.178685442167184],
              [-39.945173008987354, -8.178685460082983],
              [-39.945173011250347, -8.178685478003345],
              [-39.945173013477408, -8.178685495928155],
              [-39.945173015668558, -8.178685513857381],
              [-39.945173017823748, -8.178685531790915],
              [-39.945173019942999, -8.178685549728714],
              [-39.945173022026296, -8.178685567670705],
              [-39.945173024073618, -8.178685585616776],
              [-39.94517302608498, -8.178685603566887],
              [-39.945173028060339, -8.178685621520993],
              [-39.945173029999729, -8.178685639478973],
              [-39.94517303190311, -8.178685657440795],
              [-39.945173033770502, -8.178685675406353],
              [-39.945173035601861, -8.178685693375584],
              [-39.945173037397218, -8.178685711348439],
              [-39.945173039156536, -8.178685729324833],
              [-39.945173040879823, -8.178685747304662],
              [-39.945173042567077, -8.178685765287913],
              [-39.945173044218272, -8.17868578327446],
              [-39.945173045833421, -8.178685801264264],
              [-39.945173047412517, -8.178685819257268],
              [-39.945173048955532, -8.178685837253369],
              [-39.945173050462472, -8.178685855252489],
              [-39.945173051933345, -8.178685873254592],
              [-39.94517305336813, -8.178685891259571],
              [-39.945173054766826, -8.178685909267383],
              [-39.945173056129427, -8.178685927277924],
              [-39.945173057455918, -8.178685945291143],
              [-39.945173058746313, -8.178685963306989],
              [-39.945173060000585, -8.178685981325351],
              [-39.945173061218753, -8.178685999346149],
              [-39.945173062400784, -8.17868601736936],
              [-39.945173063546704, -8.178686035394877],
              [-39.94517306465648, -8.17868605342264],
              [-39.945173065730124, -8.178686071452589],
              [-39.945173066767644, -8.178686089484627],
              [-39.945173067769005, -8.178686107518686],
              [-39.945173068734213, -8.178686125554723],
              [-39.945173069663277, -8.178686143592623],
              [-39.94517307055618, -8.178686161632344],
              [-39.945173071412938, -8.178686179673804],
              [-39.94517307223353, -8.178686197716928],
              [-39.945173073017948, -8.178686215761671],
              [-39.945173073766199, -8.17868623380793],
              [-39.945173074478276, -8.178686251855657],
              [-39.945173075154187, -8.178686269904729],
              [-39.945173075793917, -8.17868628795512],
              [-39.945173076397474, -8.178686306006755],
              [-39.945173076964835, -8.178686324059552],
              [-39.945173077496023, -8.178686342113462],
              [-39.945173077991022, -8.178686360168367],
              [-39.945173078449834, -8.178686378224233],
              [-39.945173078872443, -8.178686396280973],
              [-39.945173079258865, -8.178686414338509],
              [-39.945173079609106, -8.178686432396768],
              [-39.945173079923137, -8.178686450455716],
              [-39.945173080200973, -8.178686468515243],
              [-39.945173080442629, -8.178686486575282],
              [-39.945173080648068, -8.178686504635786],
              [-39.945173080817305, -8.178686522696623],
              [-39.945173080950354, -8.178686540757765],
              [-39.945173081047209, -8.178686558819159],
              [-39.945173081107775, -8.178686576881566],
              [-39.945173116319019, -8.17868657675128],
              [-39.945181073318963, -8.182063979433689],
              [-39.945181038054841, -8.182063957232163],
              [-39.945181038135523, -8.182063979646204],
              [-39.945181038271976, -8.182064002059981],
              [-39.945181038464163, -8.182064024473334],
              [-39.945181038712107, -8.182064046886145],
              [-39.9451810390158, -8.182064069298296],
              [-39.945181039375235, -8.182064091709632],
              [-39.94518103979042, -8.182064114120026],
              [-39.945181040261346, -8.182064136529293],
              [-39.945181040787993, -8.182064158937354],
              [-39.945181041370383, -8.182064181344021],
              [-39.945181042008514, -8.182064203749178],
              [-39.945181042702359, -8.182064226152704],
              [-39.945181043451946, -8.182064248554429],
              [-39.945181044257232, -8.18206427095426],
              [-39.945181045118233, -8.182064293352017],
              [-39.945181046034939, -8.182064315747594],
              [-39.945181047007353, -8.182064338140814],
              [-39.945181048035465, -8.18206436053158],
              [-39.94518104911927, -8.182064382919755],
              [-39.945181050258746, -8.182064405305175],
              [-39.945181051453915, -8.182064427687715],
              [-39.94518105270474, -8.182064450067228],
              [-39.945181054011229, -8.18206447244358],
              [-39.945181055373382, -8.182064494816649],
              [-39.945181056791171, -8.182064517186271],
              [-39.945181058264602, -8.182064539552345],
              [-39.945181059793647, -8.182064561914681],
              [-39.945181061378314, -8.182064584273181],
              [-39.945181063018602, -8.18206460662771],
              [-39.945181064714475, -8.182064628978116],
              [-39.945181066465942, -8.182064651324284],
              [-39.945181068272987, -8.182064673666041],
              [-39.94518107013559, -8.182064696003287],
              [-39.945181072053749, -8.182064718335837],
              [-39.945181074027445, -8.182064740663593],
              [-39.945181076056691, -8.182064762986419],
              [-39.945181078141445, -8.18206478530414],
              [-39.945181080281699, -8.182064807616667],
              [-39.945181082477454, -8.182064829923823],
              [-39.945181084728674, -8.182064852225492],
              [-39.945181087035365, -8.18206487452154],
              [-39.945181089397508, -8.182064896811811],
              [-39.94518109181508, -8.182064919096193],
              [-39.945181094288081, -8.182064941374517],
              [-39.945181096816491, -8.182064963646679],
              [-39.945181099400294, -8.182064985912545],
              [-39.945181102039456, -8.182065008171948],
              [-39.945181104733983, -8.182065030424768],
              [-39.945181107483862, -8.18206505267089],
              [-39.945181110289056, -8.182065074910128],
              [-39.945181113149559, -8.182065097142395],
              [-39.945181116065363, -8.182065119367527],
              [-39.94518111903642, -8.182065141585365],
              [-39.945181122062742, -8.182065163795802],
              [-39.945181125144302, -8.182065185998697],
              [-39.945181128281071, -8.182065208193931],
              [-39.945181131473028, -8.182065230381335],
              [-39.945181134720187, -8.182065252560813],
              [-39.945181138022477, -8.182065274732174],
              [-39.945181141379926, -8.18206529689534],
              [-39.945181144792492, -8.182065319050123],
              [-39.945181148260147, -8.182065341196417],
              [-39.945181151782876, -8.182065363334095],
              [-39.945181155360643, -8.18206538546298],
              [-39.945181158993471, -8.182065407582968],
              [-39.945181162681287, -8.182065429693942],
              [-39.945181166424092, -8.182065451795728],
              [-39.945181170221858, -8.182065473888203],
              [-39.945181174074563, -8.182065495971251],
              [-39.945181177982199, -8.182065518044702],
              [-39.945181181944719, -8.182065540108455],
              [-39.945181185962106, -8.182065562162345],
              [-39.945181190034326, -8.182065584206232],
              [-39.945181194161378, -8.18206560624002],
              [-39.945181198343214, -8.182065628263537],
              [-39.945181202579818, -8.182065650276648],
              [-39.945181206871162, -8.182065672279238],
              [-39.945181211217225, -8.18206569427119],
              [-39.945181215617978, -8.182065716252314],
              [-39.94518122007338, -8.182065738222532],
              [-39.945181224583422, -8.182065760181652],
              [-39.945181229148076, -8.182065782129579],
              [-39.9451812337673, -8.182065804066173],
              [-39.945181238441073, -8.182065825991286],
              [-39.945181243169365, -8.182065847904783],
              [-39.945181247952156, -8.182065869806543],
              [-39.945181252789403, -8.182065891696434],
              [-39.945181257681092, -8.182065913574307],
              [-39.94518126262718, -8.182065935440058],
              [-39.945181267627632, -8.182065957293501],
              [-39.945181272682433, -8.182065979134533],
              [-39.945181277791541, -8.182066000963008],
              [-39.94518128295492, -8.182066022778823],
              [-39.945181288172563, -8.182066044581811],
              [-39.945181293444413, -8.182066066371846],
              [-39.945181298770436, -8.182066088148821],
              [-39.945181304150616, -8.18206610991256],
              [-39.945181309584918, -8.182066131662951],
              [-39.945181315073306, -8.18206615339987],
              [-39.945181350754268, -8.182066149421923],
              [-39.945865700429707, -8.184762377931339],
              [-39.945865670785054, -8.184762414693814],
              [-39.945865692361913, -8.184762501506347],
              [-39.945865713076152, -8.184762588527237],
              [-39.945865732925711, -8.184762675747939],
              [-39.945865751908691, -8.184762763159926],
              [-39.945865770023183, -8.184762850754723],
              [-39.945865787267458, -8.18476293852366],
              [-39.945865803639805, -8.184763026458217],
              [-39.945865819138646, -8.184763114549771],
              [-39.945865833762433, -8.184763202789686],
              [-39.945865847509772, -8.184763291169419],
              [-39.945865860379293, -8.184763379680234],
              [-39.945865872369744, -8.184763468313561],
              [-39.945865883479961, -8.184763557060641],
              [-39.945865893708856, -8.184763645912881],
              [-39.945865903055413, -8.184763734861527],
              [-39.94586591151873, -8.184763823897939],
              [-39.945865919097983, -8.184763913013358],
              [-39.94586592579244, -8.184764002199096],
              [-39.945865931601411, -8.184764091446439],
              [-39.94586593652437, -8.184764180746633],
              [-39.945865940560807, -8.18476427009097],
              [-39.945865943710338, -8.184764359470705],
              [-39.945865945972656, -8.184764448877093],
              [-39.945865947347514, -8.184764538301417],
              [-39.945865947834832, -8.184764627734909],
              [-39.945865947434505, -8.184764717168845],
              [-39.945865946146618, -8.184764806594432],
              [-39.94586594397127, -8.184764896002982],
              [-39.945865940908682, -8.184764985385723],
              [-39.945865936959144, -8.184765074733901],
              [-39.945865932123063, -8.184765164038822],
              [-39.945865926400892, -8.184765253291726],
              [-39.945865919793199, -8.184765342483903],
              [-39.94586591230064, -8.184765431606603],
              [-39.945865903923909, -8.184765520651133],
              [-39.945865894663875, -8.184765609608769],
              [-39.94586588452141, -8.184765698470848],
              [-39.945865873497517, -8.184765787228624],
              [-39.945865861593283, -8.184765875873451],
              [-39.945865848809866, -8.184765964396684],
              [-39.945865835148496, -8.184766052789612],
              [-39.945865820610543, -8.184766141043641],
              [-39.945865805197393, -8.184766229150126],
              [-39.945865788910588, -8.184766317100449],
              [-39.945865771751684, -8.184766404885991],
              [-39.945865753722401, -8.184766492498216],
              [-39.94586573482448, -8.184766579928494],
              [-39.945865715059746, -8.184766667168338],
              [-39.945865694430175, -8.184766754209162],
              [-39.945865672937749, -8.184766841042489],
              [-39.945865650584594, -8.184766927659824],
              [-39.945865627372882, -8.184767014052705],
              [-39.945865603304895, -8.184767100212651],
              [-39.945865578382964, -8.184767186131291],
              [-39.945865552609554, -8.184767271800167],
              [-39.945865525987159, -8.184767357210946],
              [-39.945865498518394, -8.184767442355231],
              [-39.945865470205952, -8.184767527224732],
              [-39.945865441052597, -8.184767611811138],
              [-39.94586541106117, -8.184767696106178],
              [-39.945865380234622, -8.18476778010162],
              [-39.945865348575943, -8.184767863789245],
              [-39.945865316088252, -8.184767947160848],
              [-39.945865282774704, -8.184768030208318],
              [-39.94586524863859, -8.184768112923516],
              [-39.945865213683213, -8.184768195298329],
              [-39.945865177912012, -8.184768277324734],
              [-39.945865141328476, -8.184768358994686],
              [-39.945865103936164, -8.184768440300232],
              [-39.945865065738772, -8.184768521233405],
              [-39.945865026740002, -8.184768601786262],
              [-39.945864986943697, -8.184768681950958],
              [-39.945864946353709, -8.184768761719655],
              [-39.945864904974044, -8.184768841084546],
              [-39.94586486280874, -8.184768920037868],
              [-39.94586481986191, -8.184768998571895],
              [-39.945864776137753, -8.18476907667899],
              [-39.945864731640548, -8.184769154351471],
              [-39.945864686374655, -8.184769231581743],
              [-39.945864640344475, -8.184769308362249],
              [-39.945864593554546, -8.184769384685509],
              [-39.945864546009425, -8.184769460544064],
              [-39.945864497713742, -8.184769535930474],
              [-39.945864448672253, -8.184769610837366],
              [-39.945864398889718, -8.18476968525742],
              [-39.945864348371046, -8.184769759183389],
              [-39.945864297121148, -8.184769832607994],
              [-39.945864245145039, -8.184769905524091],
              [-39.9458641924478, -8.184769977924526],
              [-39.945864139034605, -8.18477004980225],
              [-39.945864084910653, -8.184770121150201],
              [-39.945864030081246, -8.184770191961436],
              [-39.945863974551742, -8.184770262228994],
              [-39.945863918327568, -8.184770331946023],
              [-39.945863861414232, -8.184770401105693],
              [-39.945863803817282, -8.184770469701261],
              [-39.945863745542383, -8.184770537726017],
              [-39.945863686595196, -8.184770605173304],
              [-39.945863626981506, -8.184770672036542],
              [-39.945863626981328, -8.184770672036782],
            ],
          ],
          [
            [
              [-39.988794967920114, -8.187118505850556],
              [-39.988794987442773, -8.187118505802728],
              [-39.990485914055576, -8.187114361603141],
              [-39.985342287416593, -8.184820489699028],
              [-39.969619730618547, -8.189810812796587],
              [-39.972269424621231, -8.205011615246811],
              [-39.972196238572323, -8.203957262797557],
              [-39.972196236249573, -8.20395723311634],
              [-39.972196231473106, -8.203957155651743],
              [-39.97219622736386, -8.203957078149182],
              [-39.972196223922197, -8.203957000614334],
              [-39.972196221148337, -8.203956923052942],
              [-39.972196219042488, -8.203956845470683],
              [-39.972196217604804, -8.203956767873271],
              [-39.9721962168354, -8.203956690266439],
              [-39.972196216734304, -8.20395661265588],
              [-39.972196217301558, -8.203956535047308],
              [-39.9721962185371, -8.203956457446441],
              [-39.972196220440864, -8.20395637985899],
              [-39.972196223012673, -8.203956302290702],
              [-39.972196226252358, -8.203956224747213],
              [-39.972196230159696, -8.20395614723429],
              [-39.972196234734355, -8.203956069757613],
              [-39.972196239976043, -8.203955992322889],
              [-39.972196245884355, -8.203955914935797],
              [-39.972196252458865, -8.203955837602098],
              [-39.972196259699068, -8.203955760327409],
              [-39.972196267604446, -8.203955683117472],
              [-39.972196276174422, -8.203955605977933],
              [-39.972196285408344, -8.203955528914502],
              [-39.972196295305579, -8.203955451932828],
              [-39.972196305865346, -8.203955375038584],
              [-39.972196317086883, -8.203955298237402],
              [-39.972196328969382, -8.203955221535011],
              [-39.97219634151196, -8.203955144937026],
              [-39.972196354713674, -8.203955068449055],
              [-39.972196368573577, -8.203954992076712],
              [-39.97219638309064, -8.203954915825708],
              [-39.97219639826379, -8.203954839701577],
              [-39.972196414091911, -8.203954763709943],
              [-39.972196430573852, -8.203954687856353],
              [-39.972196447708377, -8.2039546121465],
              [-39.972196465494235, -8.203954536585865],
              [-39.972196483930126, -8.20395446118005],
              [-39.972196503014686, -8.203954385934594],
              [-39.972196522746508, -8.203954310855041],
              [-39.972196543124149, -8.203954235946908],
              [-39.972196564146088, -8.203954161215721],
              [-39.972196585810799, -8.203954086666977],
              [-39.972196608116683, -8.203954012306191],
              [-39.972196631062069, -8.203953938138769],
              [-39.972196654645316, -8.203953864170211],
              [-39.972196678864663, -8.203953790405963],
              [-39.972196703718332, -8.20395371685145],
              [-39.972196729204484, -8.203953643512087],
              [-39.972196755321256, -8.203953570393287],
              [-39.972196782066717, -8.203953497500406],
              [-39.972196809438898, -8.203953424838815],
              [-39.972196837435796, -8.203953352413885],
              [-39.972196866055327, -8.203953280230948],
              [-39.972196895295397, -8.203953208295243],
              [-39.972196925153867, -8.203953136612192],
              [-39.972196955628512, -8.203953065186965],
              [-39.972196986717115, -8.203952994024858],
              [-39.97219701841739, -8.203952923131121],
              [-39.972197050726962, -8.203952852510939],
              [-39.972197083643493, -8.203952782169535],
              [-39.972197117164562, -8.203952712112091],
              [-39.972197151287659, -8.203952642343777],
              [-39.972197186010327, -8.203952572869683],
              [-39.972197221329971, -8.203952503694966],
              [-39.972197257244005, -8.203952434824696],
              [-39.97219729374978, -8.203952366263907],
              [-39.972197330844608, -8.203952298017732],
              [-39.972197368525784, -8.203952230091131],
              [-39.972197406790499, -8.203952162489127],
              [-39.972197445635963, -8.20395209521668],
              [-39.972197485059297, -8.203952028278771],
              [-39.972197525057602, -8.203951961680287],
              [-39.972197565627951, -8.203951895426123],
              [-39.972197606767338, -8.203951829521239],
              [-39.972197648472736, -8.203951763970409],
              [-39.972197690741083, -8.203951698778473],
              [-39.972197733569267, -8.203951633950245],
              [-39.97219777695414, -8.203951569490483],
              [-39.97219782089249, -8.203951505403944],
              [-39.972197865381119, -8.203951441695345],
              [-39.97219791041671, -8.203951378369352],
              [-39.972197955995973, -8.20395131543064],
              [-39.972198002115533, -8.203951252883861],
              [-39.972198048772022, -8.203951190733596],
              [-39.972198095961986, -8.203951128984444],
              [-39.972198143681958, -8.203951067640899],
              [-39.972198191928435, -8.203951006707554],
              [-39.972198240697843, -8.203950946188792],
              [-39.972198289986629, -8.203950886089173],
              [-39.972198339791106, -8.203950826413068],
              [-39.972198390107657, -8.203950767164866],
              [-39.972198440932573, -8.203950708348939],
              [-39.972198492262088, -8.203950649969645],
              [-39.972198544092457, -8.203950592031237],
              [-39.972198596419837, -8.203950534537997],
              [-39.972198649240376, -8.203950477494157],
              [-39.972198702550223, -8.203950420903924],
              [-39.972198756345406, -8.203950364771435],
              [-39.972198810621997, -8.203950309100847],
              [-39.972198865375788, -8.203950253896556],
              [-39.97219885187684, -8.203950262738985],
              [-39.972758319792952, -8.203391063824368],
              [-39.972758294466537, -8.203391084925054],
              [-39.972758345213293, -8.203391034601202],
              [-39.972758396356838, -8.203390984677702],
              [-39.972758447893995, -8.203390935157623],
              [-39.972758499821566, -8.203390886044028],
              [-39.972758552136305, -8.203390837340004],
              [-39.972758604834986, -8.20339078904856],
              [-39.972758657914312, -8.203390741172734],
              [-39.972758711370972, -8.203390693715486],
              [-39.972758765201661, -8.203390646679749],
              [-39.972758819403026, -8.203390600068468],
              [-39.972758873971664, -8.203390553884542],
              [-39.972758928904227, -8.203390508130855],
              [-39.972758984197284, -8.203390462810216],
              [-39.972759039847368, -8.203390417925503],
              [-39.972759095851067, -8.203390373479433],
              [-39.972759152204844, -8.203390329474846],
              [-39.972759208905238, -8.203390285914418],
              [-39.972759265948689, -8.203390242800895],
              [-39.972759323331672, -8.203390200136937],
              [-39.972759381050601, -8.203390157925229],
              [-39.9727594391019, -8.20339011616837],
              [-39.972759497481945, -8.203390074868969],
              [-39.972759556187107, -8.203390034029574],
              [-39.972759615213732, -8.203389993652754],
              [-39.972759674558176, -8.203389953741018],
              [-39.972759734216709, -8.203389914296832],
              [-39.972759794185635, -8.203389875322655],
              [-39.972759854461231, -8.203389836820916],
              [-39.972759915039717, -8.203389798793992],
              [-39.972759975917363, -8.203389761244276],
              [-39.972760037090389, -8.203389724174096],
              [-39.97276009855495, -8.203389687585769],
              [-39.972760160307239, -8.203389651481528],
              [-39.97276022234341, -8.20338961586366],
              [-39.972760284659621, -8.203389580734376],
              [-39.972760347251992, -8.203389546095845],
              [-39.972760410116614, -8.203389511950217],
              [-39.972760473249579, -8.203389478299641],
              [-39.972760536646973, -8.203389445146192],
              [-39.972760600304852, -8.203389412491941],
              [-39.972760664219237, -8.203389380338917],
              [-39.972760728386177, -8.203389348689132],
              [-39.972760792801651, -8.203389317544543],
              [-39.97276085746168, -8.203389286907065],
              [-39.972760922362234, -8.203389256778625],
              [-39.972760987499264, -8.203389227161109],
              [-39.972761052868741, -8.203389198056355],
              [-39.972761118466572, -8.203389169466135],
              [-39.9727611842887, -8.203389141392279],
              [-39.972761250331011, -8.203389113836504],
              [-39.972761316589413, -8.203389086800531],
              [-39.972761383059783, -8.20338906028606],
              [-39.972761449737959, -8.203389034294716],
              [-39.972761516619819, -8.203389008828143],
              [-39.972761583701207, -8.203388983887884],
              [-39.972761650977937, -8.203388959475514],
              [-39.972761718445838, -8.20338893559255],
              [-39.972761786100691, -8.203388912240456],
              [-39.972761853938309, -8.203388889420735],
              [-39.972761921954458, -8.203388867134771],
              [-39.97276199014491, -8.203388845383961],
              [-39.972762058505424, -8.20338882416964],
              [-39.972762127031757, -8.203388803493157],
              [-39.972762195719632, -8.203388783355763],
              [-39.972762264564786, -8.203388763758751],
              [-39.972762333562933, -8.20338874470333],
              [-39.972762402709783, -8.203388726190669],
              [-39.97276247200103, -8.203388708221919],
              [-39.972762541432374, -8.203388690798215],
              [-39.972762610999474, -8.203388673920601],
              [-39.972762680698025, -8.203388657590171],
              [-39.972762750523685, -8.20338864180791],
              [-39.972762820472106, -8.20338862657484],
              [-39.972762890538952, -8.203388611891882],
              [-39.972762960719827, -8.203388597759938],
              [-39.972763031010409, -8.203388584179915],
              [-39.972763101406301, -8.203388571152624],
              [-39.972763171903146, -8.203388558678929],
              [-39.972763242496534, -8.203388546759541],
              [-39.972763313182071, -8.203388535395243],
              [-39.972763383955389, -8.203388524586744],
              [-39.972763454812046, -8.203388514334687],
              [-39.972763525747681, -8.203388504639729],
              [-39.972763596757851, -8.203388495502486],
              [-39.97276366783813, -8.203388486923489],
              [-39.972763738984128, -8.203388478903307],
              [-39.972763810191395, -8.2033884714424],
              [-39.972763881455521, -8.203388464541289],
              [-39.972763952772041, -8.20338845820036],
              [-39.972764024136559, -8.203388452420016],
              [-39.972764095544612, -8.203388447200615],
              [-39.972764166991752, -8.203388442542476],
              [-39.972764238473538, -8.203388438445913],
              [-39.972764309985543, -8.203388434911158],
              [-39.972764381523312, -8.203388431938446],
              [-39.972764453082384, -8.203388429527957],
              [-39.972764524658309, -8.203388427679839],
              [-39.972764596246627, -8.203388426394215],
              [-39.972764667842618, -8.203388425671992],
              [-39.97276470182809, -8.203388425589955],
              [-39.97332426795397, -8.203387074576959],
              [-39.973324311292075, -8.203387074472255],
              [-39.973324368442796, -8.20338707478836],
              [-39.973324425590391, -8.203387075462912],
              [-39.973324482732593, -8.203387076495844],
              [-39.973324539867136, -8.203387077887143],
              [-39.973324596991759, -8.203387079636753],
              [-39.973324654104189, -8.203387081744593],
              [-39.973324711202174, -8.203387084210577],
              [-39.973324768283433, -8.203387087034621],
              [-39.973324825345742, -8.203387090216623],
              [-39.973324882386805, -8.203387093756419],
              [-39.973324939404378, -8.203387097653883],
              [-39.97332499639618, -8.2033871019089],
              [-39.973325053359972, -8.203387106521228],
              [-39.973325110293509, -8.203387111490747],
              [-39.973325167194503, -8.203387116817247],
              [-39.973325224060716, -8.203387122500498],
              [-39.973325280889895, -8.203387128540287],
              [-39.973325337679796, -8.203387134936364],
              [-39.973325394428144, -8.203387141688498],
              [-39.973325451132709, -8.2033871487964],
              [-39.973325507791245, -8.203387156259812],
              [-39.973325564401499, -8.203387164078409],
              [-39.973325620961241, -8.203387172251903],
              [-39.97332567746821, -8.203387180779954],
              [-39.97332573392017, -8.203387189662225],
              [-39.973325790314902, -8.203387198898389],
              [-39.973325846650148, -8.203387208488058],
              [-39.97332590292369, -8.203387218430858],
              [-39.973325959133291, -8.203387228726385],
              [-39.973326015276733, -8.203387239374234],
              [-39.973326071351792, -8.203387250374002],
              [-39.973326127356238, -8.203387261725226],
              [-39.973326183287853, -8.203387273427474],
              [-39.973326239144413, -8.203387285480279],
              [-39.973326294923723, -8.203387297883152],
              [-39.973326350623559, -8.203387310635602],
              [-39.973326406241704, -8.203387323737154],
              [-39.973326461775983, -8.203387337187266],
              [-39.973326517224173, -8.203387350985405],
              [-39.973326572584078, -8.203387365131029],
              [-39.973326627853517, -8.203387379623548],
              [-39.97332668303028, -8.203387394462428],
              [-39.973326738112206, -8.203387409647085],
              [-39.973326793097094, -8.203387425176921],
              [-39.973326847982761, -8.203387441051259],
              [-39.973326902767056, -8.203387457269503],
              [-39.973326957447789, -8.20338747383102],
              [-39.973327012022786, -8.20338749073516],
              [-39.973327066489901, -8.203387507981247],
              [-39.973327120846974, -8.203387525568584],
              [-39.973327175091853, -8.2033875434965],
              [-39.97332722922237, -8.203387561764275],
              [-39.973327283236401, -8.203387580371164],
              [-39.973327337131792, -8.203387599316452],
              [-39.973327390906405, -8.203387618599381],
              [-39.973327444558123, -8.203387638219191],
              [-39.973327498084821, -8.203387658175092],
              [-39.973327551484353, -8.203387678466324],
              [-39.97332760475463, -8.203387699092026],
              [-39.973327657893542, -8.203387720051451],
              [-39.973327710898957, -8.203387741343704],
              [-39.973327763768793, -8.203387762967987],
              [-39.973327816500941, -8.203387784923427],
              [-39.973327869093339, -8.203387807209134],
              [-39.973327921543863, -8.20338782982426],
              [-39.973327973850481, -8.203387852767895],
              [-39.973328026011096, -8.203387876039118],
              [-39.973328078023627, -8.203387899637008],
              [-39.973328129886035, -8.203387923560642],
              [-39.973328181596258, -8.203387947809089],
              [-39.973328233152252, -8.203387972381332],
              [-39.973328284551947, -8.203387997276462],
              [-39.973328335793354, -8.20338802249344],
              [-39.9733283868744, -8.203388048031291],
              [-39.973328437793086, -8.203388073889021],
              [-39.973328488547381, -8.203388100065572],
              [-39.973328539135274, -8.203388126559927],
              [-39.973328589554761, -8.203388153371039],
              [-39.973328639803867, -8.203388180497841],
              [-39.973328689880553, -8.203388207939255],
              [-39.973328739782886, -8.203388235694161],
              [-39.973328789508855, -8.203388263761498],
              [-39.973328839056492, -8.203388292140179],
              [-39.97332888842385, -8.203388320829026],
              [-39.973328937608947, -8.203388349826939],
              [-39.973328986609864, -8.20338837913272],
              [-39.973329035424655, -8.203388408745262],
              [-39.973329084051365, -8.203388438663358],
              [-39.973329132488082, -8.203388468885853],
              [-39.973329180732875, -8.203388499411508],
              [-39.973329228783854, -8.203388530239154],
              [-39.973329276639078, -8.203388561367541],
              [-39.973329324296699, -8.203388592795456],
              [-39.973329371754794, -8.203388624521644],
              [-39.973329419011492, -8.20338865654484],
              [-39.973329466064918, -8.203388688863797],
              [-39.97332951291321, -8.203388721477191],
              [-39.973329559554507, -8.203388754383774],
              [-39.97332960598721, -8.20338878758132],
              [-39.9733295805691, -8.203388780152137],
              [-39.974563966062696, -8.20427719709968],
              [-39.974563950178187, -8.2042772313822],
              [-39.974564099991994, -8.204277336933879],
              [-39.974564251916526, -8.204277439447191],
              [-39.974564405889708, -8.204277538880236],
              [-39.974564561848638, -8.204277635192394],
              [-39.974564719729585, -8.204277728344325],
              [-39.974564879468041, -8.204277818297932],
              [-39.974565040998762, -8.204277905016474],
              [-39.97456520425574, -8.204277988464542],
              [-39.974565369172282, -8.204278068608055],
              [-39.974565535680995, -8.204278145414232],
              [-39.974565703713864, -8.204278218851725],
              [-39.974565873202231, -8.204278288890494],
              [-39.974566044076845, -8.20427835550195],
              [-39.97456621626791, -8.204278418658889],
              [-39.974566389705053, -8.204278478335485],
              [-39.974566564317421, -8.204278534507365],
              [-39.97456674003368, -8.204278587151556],
              [-39.974566916782038, -8.204278636246581],
              [-39.974567094490276, -8.204278681772365],
              [-39.974567273085796, -8.204278723710324],
              [-39.97456745249562, -8.204278762043311],
              [-39.974567632646448, -8.204278796755656],
              [-39.974567813464695, -8.204278827833194],
              [-39.974567994876473, -8.204278855263222],
              [-39.974568176807651, -8.204278879034527],
              [-39.974568359183934, -8.204278899137423],
              [-39.974568541930786, -8.204278915563643],
              [-39.974568724973516, -8.204278928306517],
              [-39.97456890823738, -8.204278937360815],
              [-39.97456909164751, -8.204278942722876],
              [-39.974569275128935, -8.204278944390477],
              [-39.974569458606709, -8.204278942362953],
              [-39.974569642005889, -8.20427893664111],
              [-39.974569825251507, -8.204278927227307],
              [-39.974570008268707, -8.204278914125386],
              [-39.974570190982732, -8.204278897340691],
              [-39.974570373318919, -8.204278876880108],
              [-39.974570555202781, -8.204278852751969],
              [-39.974570736559997, -8.204278824966122],
              [-39.97457091731647, -8.20427879353395],
              [-39.974571097398353, -8.204278758468263],
              [-39.974571276732085, -8.204278719783415],
              [-39.974571455244352, -8.204278677495211],
              [-39.974571632862279, -8.204278631620907],
              [-39.974571809513279, -8.20427858217926],
              [-39.974571985125152, -8.204278529190463],
              [-39.974572159626177, -8.204278472676181],
              [-39.974572332945037, -8.204278412659482],
              [-39.974572505010947, -8.2042783491649],
              [-39.974572675753613, -8.204278282218354],
              [-39.974572845103218, -8.204278211847228],
              [-39.974573012990646, -8.204278138080269],
              [-39.974573179347239, -8.204278060947587],
              [-39.974573344105082, -8.204277980480725],
              [-39.974573507196823, -8.204277896712547],
              [-39.974573668555841, -8.204277809677277],
              [-39.97457382811622, -8.204277719410483],
              [-39.974573985812768, -8.204277625949031],
              [-39.974574141581058, -8.204277529331131],
              [-39.974574295357421, -8.204277429596244],
              [-39.974574447079064, -8.204277326785135],
              [-39.974574596683986, -8.204277220939748],
              [-39.974574744111059, -8.204277112103389],
              [-39.974574889300072, -8.204277000320495],
              [-39.974575032191666, -8.204276885636745],
              [-39.974575172727512, -8.204276768098978],
              [-39.974575310850128, -8.204276647755258],
              [-39.974575446503145, -8.204276524654727],
              [-39.974575579631093, -8.204276398847654],
              [-39.974575710179607, -8.204276270385469],
              [-39.974575838095348, -8.204276139320676],
              [-39.974575963326053, -8.20427600570676],
              [-39.974576085820544, -8.204275869598373],
              [-39.974576205528791, -8.204275731051119],
              [-39.974576322401873, -8.204275590121565],
              [-39.974576436392056, -8.204275446867298],
              [-39.974576547452735, -8.204275301346883],
              [-39.97457665553857, -8.20427515361974],
              [-39.974576760605402, -8.204275003746242],
              [-39.97457686261027, -8.20427485178762],
              [-39.974576961511538, -8.204274697805959],
              [-39.974577057268768, -8.204274541864152],
              [-39.974577149842851, -8.204274384025926],
              [-39.974577239195959, -8.204274224355775],
              [-39.974577325291591, -8.204274062918911],
              [-39.974577408094568, -8.20427389978131],
              [-39.974577487571054, -8.204273735009609],
              [-39.974577563688598, -8.204273568671157],
              [-39.974577636416086, -8.204273400833895],
              [-39.974577705723817, -8.204273231566399],
              [-39.974577771583448, -8.204273060937799],
              [-39.974577833968077, -8.204272889017826],
              [-39.974577892852245, -8.204272715876735],
              [-39.974577948211859, -8.204272541585276],
              [-39.974578000024344, -8.204272366214619],
              [-39.974578048268484, -8.204272189836418],
              [-39.974578092924595, -8.204272012522758],
              [-39.974578133974433, -8.204271834346095],
              [-39.974578171401205, -8.204271655379202],
              [-39.97457820471773, -8.204271645679899],
              [-39.974686086484766, -8.203728097461864],
              [-39.97468609931569, -8.203728113098849],
              [-39.97468612049552, -8.203728002843961],
              [-39.974686140300172, -8.20372789233568],
              [-39.974686158726627, -8.203727781590997],
              [-39.974686175772021, -8.203727670626986],
              [-39.974686191433761, -8.203727559460743],
              [-39.974686205709403, -8.203727448109412],
              [-39.974686218596759, -8.203727336590115],
              [-39.974686230093837, -8.203727224920053],
              [-39.974686240198864, -8.20372711311642],
              [-39.974686248910295, -8.203727001196429],
              [-39.974686256226811, -8.203726889177334],
              [-39.974686262147216, -8.203726777076414],
              [-39.974686266670659, -8.203726664910912],
              [-39.974686269796429, -8.203726552698118],
              [-39.974686271524028, -8.203726440455299],
              [-39.974686271853216, -8.203726328199778],
              [-39.974686270783913, -8.203726215948832],
              [-39.97468626831629, -8.203726103719733],
              [-39.974686264450753, -8.203725991529829],
              [-39.97468625918787, -8.203725879396336],
              [-39.974686252528464, -8.203725767336573],
              [-39.974686244473553, -8.203725655367782],
              [-39.974686235024393, -8.203725543507231],
              [-39.974686224182427, -8.203725431772153],
              [-39.974686211949319, -8.203725320179737],
              [-39.974686198326957, -8.203725208747198],
              [-39.97468618331748, -8.203725097491683],
              [-39.974686166923128, -8.20372498643035],
              [-39.974686149146478, -8.20372487558029],
              [-39.974686129990239, -8.203724764958599],
              [-39.974686109457409, -8.203724654582301],
              [-39.974686087551099, -8.203724544468418],
              [-39.974686064274707, -8.203724434633921],
              [-39.97468603963182, -8.203724325095704],
              [-39.974686013626233, -8.203724215870649],
              [-39.974685986261946, -8.20372410697559],
              [-39.974685957543173, -8.203723998427307],
              [-39.974685927474361, -8.203723890242491],
              [-39.974685896060123, -8.20372378243786],
              [-39.97468586330529, -8.203723675029963],
              [-39.974685829214941, -8.2037235680354],
              [-39.974685793794272, -8.203723461470631],
              [-39.974685757048789, -8.203723355352038],
              [-39.974685718984155, -8.203723249696061],
              [-39.974685679606189, -8.203723144518897],
              [-39.974685638921002, -8.203723039836765],
              [-39.974685596934847, -8.203722935665827],
              [-39.97468555365419, -8.203722832022084],
              [-39.974685509085681, -8.203722728921512],
              [-39.974685463236199, -8.203722626380028],
              [-39.974685416112827, -8.203722524413399],
              [-39.974685367722827, -8.203722423037345],
              [-39.974685318073618, -8.203722322267472],
              [-39.974685267172866, -8.203722222119325],
              [-39.974685215028416, -8.203722122608323],
              [-39.974685161648289, -8.203722023749759],
              [-39.974685107040735, -8.203721925558916],
              [-39.974685051214159, -8.203721828050911],
              [-39.974684994177125, -8.203721731240757],
              [-39.97468493593847, -8.203721635143369],
              [-39.974684876507126, -8.203721539773538],
              [-39.974684815892289, -8.203721445145979],
              [-39.974684754103244, -8.203721351275258],
              [-39.974684691149555, -8.203721258175829],
              [-39.974684627040915, -8.203721165862046],
              [-39.974684561787178, -8.203721074348124],
              [-39.974684495398421, -8.203720983648157],
              [-39.974684427884839, -8.203720893776135],
              [-39.974684359256884, -8.203720804745863],
              [-39.974684289525072, -8.203720716571116],
              [-39.974684218700176, -8.20372062926546],
              [-39.974684146793109, -8.203720542842326],
              [-39.974684073814942, -8.203720457315001],
              [-39.974683999776929, -8.203720372696726],
              [-39.974683924690446, -8.20372028900052],
              [-39.974683848567089, -8.203720206239256],
              [-39.974683771418576, -8.203720124425686],
              [-39.9746836932568, -8.203720043572426],
              [-39.974683614093784, -8.203719963691929],
              [-39.974683533941736, -8.203719884796483],
              [-39.974683452812997, -8.203719806898254],
              [-39.974683370720086, -8.203719730009249],
              [-39.974683287675624, -8.203719654141317],
              [-39.974683203692408, -8.203719579306131],
              [-39.974683118783403, -8.203719505515201],
              [-39.97468303296165, -8.203719432779959],
              [-39.974682946240392, -8.203719361111563],
              [-39.974682858633003, -8.203719290521052],
              [-39.974682770152945, -8.20371922101933],
              [-39.974682680813878, -8.203719152617092],
              [-39.974682590629548, -8.203719085324854],
              [-39.974682499613856, -8.203719019153011],
              [-39.974682407780826, -8.203718954111748],
              [-39.974682315144598, -8.203718890211057],
              [-39.974682221719455, -8.203718827460827],
              [-39.974682127519763, -8.203718765870711],
              [-39.974682032560061, -8.203718705450191],
              [-39.974681936854978, -8.203718646208548],
              [-39.974681840419237, -8.203718588154956],
              [-39.974681796801107, -8.203718611388073],
              [-39.97298751908361, -8.20271291597264],
              [-39.972987544957, -8.202712875567263],
              [-39.97298751016293, -8.202712854808761],
              [-39.97298747546229, -8.202712833895584],
              [-39.972987440855761, -8.202712812828157],
              [-39.972987406344068, -8.202712791606913],
              [-39.972987371927864, -8.20271277023229],
              [-39.972987337607847, -8.202712748704643],
              [-39.972987303384713, -8.202712727024473],
              [-39.972987269259129, -8.202712705192178],
              [-39.972987235231784, -8.202712683208237],
              [-39.972987201303376, -8.202712661073031],
              [-39.972987167474543, -8.202712638787029],
              [-39.972987133745981, -8.202712616350702],
              [-39.972987100118374, -8.202712593764454],
              [-39.972987066592374, -8.202712571028755],
              [-39.972987033168671, -8.202712548144056],
              [-39.972986999847912, -8.202712525110851],
              [-39.972986966630756, -8.202712501929533],
              [-39.972986933517895, -8.20271247860059],
              [-39.972986900509987, -8.202712455124498],
              [-39.972986867607645, -8.202712431501727],
              [-39.972986834811572, -8.202712407732744],
              [-39.972986802122421, -8.202712383818017],
              [-39.972986769540839, -8.202712359758019],
              [-39.972986737067451, -8.202712335553256],
              [-39.972986704702919, -8.202712311204197],
              [-39.972986672447902, -8.202712286711305],
              [-39.972986640303027, -8.202712262075075],
              [-39.972986608268975, -8.202712237296042],
              [-39.972986576346329, -8.202712212374664],
              [-39.972986544535772, -8.202712187311437],
              [-39.972986512837899, -8.202712162106884],
              [-39.972986481253365, -8.202712136761491],
              [-39.972986449782795, -8.202712111275755],
              [-39.972986418426835, -8.2027120856502],
              [-39.972986387186083, -8.20271205988532],
              [-39.972986356061185, -8.202712033981669],
              [-39.972986325052737, -8.202712007939716],
              [-39.972986294161394, -8.202711981760032],
              [-39.972986263387746, -8.202711955443085],
              [-39.972986232732403, -8.202711928989453],
              [-39.972986202196005, -8.202711902399599],
              [-39.972986171779141, -8.202711875674103],
              [-39.972986141482409, -8.202711848813495],
              [-39.97298611130644, -8.202711821818315],
              [-39.972986081251804, -8.202711794689083],
              [-39.972986051319126, -8.202711767426354],
              [-39.972986021509008, -8.202711740030635],
              [-39.972985991822021, -8.202711712502538],
              [-39.972985962258768, -8.202711684842562],
              [-39.972985932819839, -8.202711657051275],
              [-39.972985903505837, -8.202711629129208],
              [-39.972985874317331, -8.202711601076992],
              [-39.97298584525489, -8.20271157289509],
              [-39.972985816319117, -8.20271154458413],
              [-39.972985787510595, -8.202711516144676],
              [-39.972985758829878, -8.202711487577258],
              [-39.97298573027755, -8.202711458882474],
              [-39.972985701854164, -8.202711430060901],
              [-39.972985673560316, -8.202711401113088],
              [-39.972985645396555, -8.202711372039607],
              [-39.972985617363449, -8.202711342841088],
              [-39.97298558946153, -8.202711313518092],
              [-39.972985561691395, -8.202711284071178],
              [-39.972985534053585, -8.202711254500935],
              [-39.972985506548639, -8.202711224807997],
              [-39.972985479177112, -8.20271119499291],
              [-39.972985451939557, -8.202711165056307],
              [-39.972985424836509, -8.202711134998756],
              [-39.97298539786852, -8.202711104820857],
              [-39.972985371036124, -8.20271107452324],
              [-39.97298534433984, -8.202711044106479],
              [-39.972985317780228, -8.202711013571191],
              [-39.972985291357801, -8.202710982918024],
              [-39.972985265073078, -8.202710952147529],
              [-39.972985238926626, -8.202710921260374],
              [-39.972985212918907, -8.202710890257121],
              [-39.972985187050483, -8.202710859138419],
              [-39.972985161321859, -8.202710827904896],
              [-39.972985135733545, -8.20271079655717],
              [-39.972985110286054, -8.202710765095848],
              [-39.972985084979889, -8.202710733521583],
              [-39.972985059815549, -8.202710701834983],
              [-39.972985034793567, -8.202710670036687],
              [-39.972985009914424, -8.202710638127348],
              [-39.972984985178584, -8.202710606107594],
              [-39.972984960586615, -8.202710573978058],
              [-39.972984936138936, -8.20271054173938],
              [-39.972984911836065, -8.202710509392222],
              [-39.9729848876785, -8.202710476937177],
              [-39.972984863666689, -8.202710444374967],
              [-39.972984839801136, -8.20271041170618],
              [-39.972984816082317, -8.202710378931503],
              [-39.972984792510701, -8.202710346051585],
              [-39.97298476908675, -8.202710313067071],
              [-39.97298474581094, -8.202710279978625],
              [-39.972984722683755, -8.202710246786875],
              [-39.972984699705606, -8.202710213492541],
              [-39.972984676877012, -8.202710180096263],
              [-39.972984654198555, -8.202710146598056],
              [-39.972984698166435, -8.202710119067534],
              [-39.972313152261158, -8.201713526489399],
              [-39.972313139448175, -8.201713484313901],
              [-39.972313030896139, -8.201713319340541],
              [-39.972312925981257, -8.20171315204769],
              [-39.972312824753473, -8.201712982514893],
              [-39.972312727260928, -8.201712810822842],
              [-39.972312633550025, -8.201712637053214],
              [-39.972312543665289, -8.201712461288652],
              [-39.972312457649565, -8.201712283612775],
              [-39.972312375543687, -8.201712104110104],
              [-39.972312297386765, -8.201711922866039],
              [-39.972312223215958, -8.201711739966795],
              [-39.972312153066568, -8.201711555499395],
              [-39.972312086971961, -8.201711369551564],
              [-39.972312024963571, -8.201711182211813],
              [-39.972311967070908, -8.201710993569213],
              [-39.972311913321491, -8.201710803713516],
              [-39.97231186374092, -8.201710612735074],
              [-39.972311818352757, -8.201710420724702],
              [-39.972311777178618, -8.20171022777375],
              [-39.972311740238055, -8.201710033974022],
              [-39.972311707548677, -8.201709839417697],
              [-39.972311679126022, -8.201709644197317],
              [-39.972311654983599, -8.201709448405806],
              [-39.972311635132918, -8.20170925213626],
              [-39.972311619583401, -8.201709055482048],
              [-39.972311608342444, -8.201708858536737],
              [-39.972311601415413, -8.20170866139401],
              [-39.972311598805582, -8.201708464147679],
              [-39.972311600514217, -8.201708266891535],
              [-39.972311606540494, -8.201708069719434],
              [-39.972311616881534, -8.201707872725194],
              [-39.972311631532421, -8.201707676002513],
              [-39.972311650486205, -8.201707479644956],
              [-39.972311673733849, -8.201707283745964],
              [-39.972311701264303, -8.201707088398722],
              [-39.972311733064473, -8.201706893696167],
              [-39.972311769119216, -8.201706699730936],
              [-39.972311809411394, -8.201706506595261],
              [-39.972311853921845, -8.20170631438102],
              [-39.972311902629372, -8.201706123179706],
              [-39.972311955510825, -8.201705933082225],
              [-39.972312012541032, -8.20170574417905],
              [-39.972312073692869, -8.201705556560018],
              [-39.972312138937262, -8.201705370314388],
              [-39.972312208243125, -8.201705185530772],
              [-39.972312281577544, -8.201705002297055],
              [-39.97231235890559, -8.201704820700417],
              [-39.972312440190514, -8.201704640827263],
              [-39.972312525393612, -8.20170446276312],
              [-39.972312614474355, -8.201704286592729],
              [-39.972312707390401, -8.20170411239992],
              [-39.972312804097513, -8.201703940267517],
              [-39.972312904549689, -8.201703770277417],
              [-39.972313008699153, -8.201703602510495],
              [-39.972313116496366, -8.201703437046554],
              [-39.97231322789002, -8.201703273964311],
              [-39.972313342827142, -8.201703113341372],
              [-39.972313461253059, -8.201702955254111],
              [-39.972313583111422, -8.20170279977774],
              [-39.972313708344245, -8.20170264698625],
              [-39.972313836892006, -8.201702496952283],
              [-39.972313968693499, -8.201702349747261],
              [-39.972314103686031, -8.201702205441167],
              [-39.9723142418054, -8.201702064102681],
              [-39.972314382985907, -8.201701925799041],
              [-39.972314527160364, -8.201701790596026],
              [-39.97231467426019, -8.201701658557965],
              [-39.97231482421541, -8.201701529747663],
              [-39.972314976954721, -8.201701404226403],
              [-39.972315132405399, -8.201701282053905],
              [-39.97231529049354, -8.201701163288242],
              [-39.972315451143928, -8.20170104798598],
              [-39.972315614280127, -8.201700936201936],
              [-39.972315779824527, -8.201700827989297],
              [-39.972315947698412, -8.201700723399545],
              [-39.972316117821876, -8.201700622482418],
              [-39.972316290114009, -8.201700525285947],
              [-39.972316464492842, -8.201700431856354],
              [-39.97231664087542, -8.201700342238075],
              [-39.972316819177848, -8.201700256473751],
              [-39.972316999315282, -8.201700174604198],
              [-39.972317181202044, -8.201700096668379],
              [-39.972317364751596, -8.201700022703365],
              [-39.972317549876642, -8.201699952744288],
              [-39.972317736489082, -8.201699886824464],
              [-39.972317924500167, -8.20169982497525],
              [-39.972318113820421, -8.201699767226078],
              [-39.972318304359817, -8.201699713604407],
              [-39.972318496027697, -8.201699664135756],
              [-39.972318688732891, -8.201699618843646],
              [-39.972318882383718, -8.201699577749618],
              [-39.972319076888049, -8.201699540873275],
              [-39.972319272153356, -8.201699508232084],
              [-39.972319468086766, -8.201699479841608],
              [-39.972319664595041, -8.201699455715371],
              [-39.972319861584701, -8.201699435864827],
              [-39.972320058962069, -8.201699420299411],
              [-39.972320256633211, -8.20169940902656],
              [-39.9723204545041, -8.20169940205162],
              [-39.972320652480775, -8.201699399377688],
              [-39.973546234727564, -8.201696441269728],
              [-39.973546277339011, -8.20169644116681],
              [-39.973546343182477, -8.201696441245716],
              [-39.973546409023633, -8.201696441800413],
              [-39.973546474859035, -8.201696442830924],
              [-39.973546540685184, -8.201696444337179],
              [-39.973546606498658, -8.201696446319046],
              [-39.973546672295953, -8.201696448776485],
              [-39.97354673807363, -8.201696451709317],
              [-39.973546803828235, -8.201696455117403],
              [-39.973546869556301, -8.201696459000628],
              [-39.973546935254355, -8.201696463358688],
              [-39.973547000918948, -8.201696468191402],
              [-39.973547066546644, -8.201696473498538],
              [-39.973547132133973, -8.201696479279756],
              [-39.97354719767749, -8.201696485534828],
              [-39.973547263173749, -8.201696492263356],
              [-39.97354732861929, -8.201696499464997],
              [-39.973547394010687, -8.201696507139435],
              [-39.973547459344495, -8.201696515286178],
              [-39.973547524617281, -8.201696523904843],
              [-39.973547589825593, -8.201696532994953],
              [-39.973547654966012, -8.201696542556077],
              [-39.973547720035128, -8.201696552587697],
              [-39.973547785029481, -8.201696563089243],
              [-39.973547849945675, -8.201696574060218],
              [-39.973547914780298, -8.201696585500006],
              [-39.973547979529926, -8.201696597407995],
              [-39.973548044191162, -8.201696609783619],
              [-39.973548108760603, -8.201696622626184],
              [-39.973548173234846, -8.201696635935008],
              [-39.973548237610515, -8.20169664970938],
              [-39.973548301884193, -8.201696663948645],
              [-39.973548366052526, -8.201696678651992],
              [-39.973548430112139, -8.201696693818652],
              [-39.973548494059628, -8.201696709447839],
              [-39.973548557891668, -8.201696725538707],
              [-39.973548621604877, -8.201696742090483],
              [-39.973548685195915, -8.201696759102202],
              [-39.973548748661436, -8.20169677657303],
              [-39.9735488119981, -8.201696794502011],
              [-39.973548875202574, -8.20169681288823],
              [-39.97354893827152, -8.201696831730704],
              [-39.97354900120164, -8.201696851028455],
              [-39.973549063989601, -8.201696870780456],
              [-39.973549126632136, -8.201696890985682],
              [-39.973549189125904, -8.201696911643028],
              [-39.973549251467652, -8.20169693275148],
              [-39.97354931365409, -8.201696954309895],
              [-39.973549375681934, -8.2016969763171],
              [-39.973549437547945, -8.201696998771984],
              [-39.97354949924884, -8.201697021673349],
              [-39.973549560781393, -8.20169704501996],
              [-39.973549622142357, -8.201697068810647],
              [-39.973549683328514, -8.201697093044135],
              [-39.97354974433663, -8.201697117719139],
              [-39.973549805163515, -8.201697142834378],
              [-39.97354986580595, -8.201697168388502],
              [-39.973549926260759, -8.201697194380186],
              [-39.973549986524745, -8.201697220808075],
              [-39.973550046594745, -8.201697247670756],
              [-39.973550106467613, -8.201697274966811],
              [-39.973550166140186, -8.201697302694829],
              [-39.973550225609323, -8.201697330853355],
              [-39.973550284871891, -8.201697359440859],
              [-39.973550343924785, -8.201697388455901],
              [-39.973550402764893, -8.201697417896909],
              [-39.973550461389117, -8.201697447762355],
              [-39.973550519794365, -8.20169747805063],
              [-39.973550577977598, -8.201697508760187],
              [-39.973550635935709, -8.201697539889404],
              [-39.973550693665679, -8.20169757143662],
              [-39.973550751164453, -8.20169760340019],
              [-39.973550808429039, -8.201697635778459],
              [-39.973550865456374, -8.20169766856967],
              [-39.973550922243504, -8.201697701772108],
              [-39.973550978787415, -8.201697735384087],
              [-39.973551035085144, -8.201697769403802],
              [-39.9735510911337, -8.201697803829418],
              [-39.973551146930184, -8.201697838659175],
              [-39.973551202471633, -8.201697873891217],
              [-39.973551257755119, -8.201697909523697],
              [-39.973551312777751, -8.201697945554786],
              [-39.973551367536608, -8.2016979819825],
              [-39.973551422028848, -8.201698018804953],
              [-39.973551476251565, -8.20169805602027],
              [-39.973551530201938, -8.201698093626431],
              [-39.973551583877097, -8.20169813162145],
              [-39.973551637274234, -8.201698170003382],
              [-39.973551690390558, -8.201698208770157],
              [-39.973551743223247, -8.201698247919788],
              [-39.973551795769538, -8.201698287450141],
              [-39.973551848026659, -8.201698327359201],
              [-39.973551899991847, -8.201698367644857],
              [-39.973551951662408, -8.20169840830494],
              [-39.973552003035579, -8.201698449337366],
              [-39.97355205410868, -8.20169849073997],
              [-39.97355210487904, -8.201698532510518],
              [-39.973552155343945, -8.201698574646889],
              [-39.973552205500781, -8.201698617146842],
              [-39.973552255347485, -8.201698660006382],
              [-39.973552231519442, -8.201698687846674],
              [-39.974457742408177, -8.202482986810999],
              [-39.9755919332056, -8.203493378325575],
              [-39.975591890478377, -8.203493350734124],
              [-39.975591911423017, -8.203493369334765],
              [-39.975591932425267, -8.203493387870829],
              [-39.975591953484901, -8.203493406342135],
              [-39.975591974601741, -8.203493424748494],
              [-39.975591995775588, -8.203493443089753],
              [-39.97559201700625, -8.203493461365719],
              [-39.975592038293492, -8.203493479576203],
              [-39.975592059637123, -8.203493497721068],
              [-39.97559208103695, -8.203493515800092],
              [-39.975592102492762, -8.20349353381317],
              [-39.975592124004351, -8.20349355176007],
              [-39.975592145571518, -8.203493569640651],
              [-39.97559216719403, -8.203493587454719],
              [-39.97559218887173, -8.203493605202135],
              [-39.975592210604376, -8.203493622882688],
              [-39.975592232391762, -8.203493640496243],
              [-39.975592254233696, -8.203493658042639],
              [-39.975592276129944, -8.203493675521669],
              [-39.975592298080329, -8.203493692933217],
              [-39.975592320084615, -8.203493710277066],
              [-39.975592342142605, -8.203493727553077],
              [-39.975592364254084, -8.203493744761106],
              [-39.975592386418846, -8.203493761900928],
              [-39.975592408636679, -8.203493778972415],
              [-39.975592430907341, -8.203493795975426],
              [-39.975592453230668, -8.203493812909764],
              [-39.975592475606405, -8.203493829775276],
              [-39.975592498034366, -8.203493846571812],
              [-39.97559252051434, -8.203493863299189],
              [-39.975592543046091, -8.203493879957284],
              [-39.97559256562942, -8.203493896545899],
              [-39.975592588264085, -8.203493913064884],
              [-39.975592610949903, -8.203493929514085],
              [-39.975592633686638, -8.203493945893387],
              [-39.97559265647407, -8.203493962202552],
              [-39.975592679311994, -8.203493978441497],
              [-39.975592702200188, -8.203493994610028],
              [-39.975592725138434, -8.203494010708015],
              [-39.975592748126502, -8.203494026735282],
              [-39.975592771164202, -8.203494042691673],
              [-39.975592794251277, -8.203494058577055],
              [-39.975592817387529, -8.203494074391266],
              [-39.97559284057273, -8.203494090134145],
              [-39.975592863806646, -8.203494105805547],
              [-39.975592887089093, -8.203494121405337],
              [-39.975592910419799, -8.203494136933369],
              [-39.975592933798588, -8.203494152389458],
              [-39.975592957225189, -8.20349416777351],
              [-39.975592980699417, -8.20349418308534],
              [-39.975593004221039, -8.203494198324815],
              [-39.975593027789813, -8.203494213491776],
              [-39.975593051405554, -8.203494228586083],
              [-39.975593075067977, -8.203494243607604],
              [-39.975593098776905, -8.203494258556169],
              [-39.975593122532089, -8.203494273431676],
              [-39.975593146333303, -8.203494288233962],
              [-39.975593170180332, -8.20349430296287],
              [-39.975593194072957, -8.20349431761829],
              [-39.975593218010893, -8.20349433220005],
              [-39.975593241993977, -8.203494346708048],
              [-39.975593266021953, -8.203494361142116],
              [-39.975593290094587, -8.203494375502103],
              [-39.975593314211672, -8.203494389787915],
              [-39.975593338372931, -8.203494403999379],
              [-39.975593362578188, -8.203494418136374],
              [-39.975593386827171, -8.203494432198774],
              [-39.975593411119668, -8.20349444618642],
              [-39.975593435455444, -8.203494460099215],
              [-39.975593459834265, -8.203494473936974],
              [-39.975593484255889, -8.203494487699615],
              [-39.97559350872011, -8.203494501386986],
              [-39.975593533226665, -8.203494514998965],
              [-39.975593557775333, -8.203494528535408],
              [-39.975593582365889, -8.203494541996196],
              [-39.975593606998068, -8.203494555381178],
              [-39.975593631671664, -8.203494568690246],
              [-39.975593656386415, -8.203494581923295],
              [-39.975593681142122, -8.203494595080162],
              [-39.975593705938515, -8.203494608160739],
              [-39.975593730775373, -8.203494621164889],
              [-39.975593755652454, -8.20349463409249],
              [-39.975593780569518, -8.203494646943433],
              [-39.975593805526337, -8.203494659717579],
              [-39.975593830522669, -8.203494672414809],
              [-39.975593855558259, -8.203494685035009],
              [-39.975593880632886, -8.203494697578058],
              [-39.975593905746308, -8.203494710043843],
              [-39.975593930898278, -8.203494722432225],
              [-39.975593956088566, -8.203494734743078],
              [-39.975593981316919, -8.203494746976311],
              [-39.975594006583101, -8.203494759131791],
              [-39.975594031886885, -8.203494771209431],
              [-39.975594057228008, -8.203494783209054],
              [-39.975594082606243, -8.203494795130641],
              [-39.975594108021319, -8.203494806973989],
              [-39.975594133473038, -8.203494818739017],
              [-39.975594158961123, -8.203494830425617],
              [-39.975594184485352, -8.203494842033683],
              [-39.975594210045458, -8.203494853563088],
              [-39.975594206171664, -8.20349481990084],
              [-39.976838503598813, -8.204053814369411],
              [-39.976838531228367, -8.20405381989621],
              [-39.976838566874036, -8.204053835816802],
              [-39.976838602588153, -8.204053851584435],
              [-39.976838638370026, -8.204053867198789],
              [-39.976838674219024, -8.204053882659593],
              [-39.976838710134466, -8.204053897966554],
              [-39.976838746115682, -8.204053913119413],
              [-39.976838782162019, -8.204053928117858],
              [-39.97683881827281, -8.204053942961629],
              [-39.976838854447365, -8.204053957650446],
              [-39.97683889068503, -8.204053972184012],
              [-39.976838926985138, -8.204053986562103],
              [-39.976838963347006, -8.204054000784437],
              [-39.976838999769974, -8.204054014850726],
              [-39.976839036253359, -8.20405402876076],
              [-39.97683907279648, -8.204054042514233],
              [-39.976839109398654, -8.204054056110911],
              [-39.976839146059241, -8.204054069550537],
              [-39.976839182777532, -8.204054082832872],
              [-39.976839219552843, -8.204054095957671],
              [-39.976839256384508, -8.204054108924655],
              [-39.97683929327183, -8.204054121733623],
              [-39.976839330214133, -8.204054134384338],
              [-39.976839367210751, -8.204054146876539],
              [-39.976839404260971, -8.204054159210031],
              [-39.976839441364127, -8.204054171384538],
              [-39.976839478519508, -8.204054183399874],
              [-39.976839515726454, -8.204054195255809],
              [-39.976839552984245, -8.204054206952101],
              [-39.976839590292236, -8.204054218488551],
              [-39.976839627649696, -8.204054229864949],
              [-39.976839665055948, -8.204054241081069],
              [-39.976839702510304, -8.204054252136714],
              [-39.976839740012053, -8.204054263031686],
              [-39.976839777560514, -8.204054273765781],
              [-39.976839815155003, -8.204054284338802],
              [-39.976839852794797, -8.204054294750526],
              [-39.976839890479212, -8.204054305000826],
              [-39.976839928207568, -8.204054315089429],
              [-39.976839965979124, -8.204054325016205],
              [-39.976840003793221, -8.204054334780956],
              [-39.976840041649147, -8.204054344383474],
              [-39.976840079546186, -8.204054353823633],
              [-39.976840117483661, -8.204054363101198],
              [-39.976840155460842, -8.204054372216047],
              [-39.976840193477052, -8.204054381167985],
              [-39.976840231531575, -8.204054389956863],
              [-39.976840269623693, -8.204054398582509],
              [-39.976840307752724, -8.204054407044747],
              [-39.976840345917957, -8.20405441534345],
              [-39.976840384118667, -8.204054423478443],
              [-39.976840422354165, -8.204054431449592],
              [-39.976840460623741, -8.204054439256726],
              [-39.976840498926677, -8.204054446899734],
              [-39.976840537262277, -8.204054454378467],
              [-39.976840575629822, -8.204054461692765],
              [-39.976840614028596, -8.204054468842486],
              [-39.976840652457902, -8.204054475827547],
              [-39.976840690917022, -8.204054482647733],
              [-39.976840729405247, -8.204054489303022],
              [-39.976840767921871, -8.204054495793203],
              [-39.976840806466143, -8.204054502118208],
              [-39.976840845037394, -8.204054508277903],
              [-39.976840883634893, -8.204054514272174],
              [-39.976840922257914, -8.204054520100904],
              [-39.976840960905761, -8.204054525763993],
              [-39.976840999577711, -8.204054531261358],
              [-39.976841038273051, -8.204054536592851],
              [-39.97684107699105, -8.204054541758401],
              [-39.976841115731013, -8.204054546757899],
              [-39.976841154492199, -8.204054551591293],
              [-39.976841193273899, -8.204054556258454],
              [-39.976841232075422, -8.204054560759309],
              [-39.97684127089601, -8.204054565093763],
              [-39.976841309734951, -8.204054569261729],
              [-39.976841348591549, -8.204054573263168],
              [-39.976841387465058, -8.204054577097954],
              [-39.976841426354774, -8.204054580766044],
              [-39.976841465259973, -8.204054584267368],
              [-39.976841504179959, -8.204054587601865],
              [-39.976841543113956, -8.204054590769466],
              [-39.976841582061283, -8.204054593770124],
              [-39.976841621021208, -8.204054596603772],
              [-39.976841659993028, -8.204054599270362],
              [-39.976841698975981, -8.204054601769846],
              [-39.97684173796938, -8.20405460410219],
              [-39.976841776972492, -8.204054606267331],
              [-39.976841815984592, -8.204054608265235],
              [-39.976841855004963, -8.204054610095838],
              [-39.97684189403288, -8.204054611759158],
              [-39.976841933067611, -8.204054613255142],
              [-39.976841972108453, -8.204054614583745],
              [-39.976842011154659, -8.204054615744971],
              [-39.976842050205519, -8.204054616738768],
              [-39.976842089260323, -8.20405461756514],
              [-39.976842128318324, -8.20405461822407],
              [-39.976842167378827, -8.204054618715542],
              [-39.976842206441084, -8.204054619039526],
              [-39.976842245504372, -8.204054619196055],
              [-39.976842284567965, -8.204054619185122],
              [-39.976842318244707, -8.204054619103518],
              [-39.977739926778085, -8.204052442291511],
              [-39.97774004122244, -8.204052441253658],
              [-39.977740155644568, -8.204052438778389],
              [-39.977740270026288, -8.204052434866068],
              [-39.97774038434941, -8.204052429517361],
              [-39.97774049859575, -8.204052422733092],
              [-39.977740612747169, -8.204052414514333],
              [-39.977740726785505, -8.2040524048624],
              [-39.977740840692647, -8.204052393778843],
              [-39.977740954450496, -8.204052381265422],
              [-39.977741068040949, -8.204052367324117],
              [-39.97774118144595, -8.204052351957138],
              [-39.97774129464748, -8.20405233516694],
              [-39.977741407627562, -8.204052316956171],
              [-39.977741520368212, -8.204052297327745],
              [-39.977741632851526, -8.204052276284767],
              [-39.977741745059618, -8.204052253830598],
              [-39.977741856974653, -8.204052229968791],
              [-39.97774196857884, -8.204052204703157],
              [-39.97774207985443, -8.204052178037683],
              [-39.977742190783765, -8.204052149976622],
              [-39.977742301349195, -8.204052120524469],
              [-39.977742411533136, -8.204052089685845],
              [-39.977742521318085, -8.204052057465715],
              [-39.977742630686599, -8.204052023869149],
              [-39.977742739621263, -8.204051988901526],
              [-39.977742848104803, -8.204051952568371],
              [-39.977742956119954, -8.204051914875457],
              [-39.977743063649534, -8.204051875828814],
              [-39.977743170676476, -8.20405183543463],
              [-39.977743277183762, -8.204051793699305],
              [-39.977743383154454, -8.204051750629485],
              [-39.97774348857169, -8.204051706232033],
              [-39.977743593418779, -8.204051660513986],
              [-39.977743697678974, -8.204051613482621],
              [-39.977743801335762, -8.204051565145427],
              [-39.97774390437263, -8.204051515510045],
              [-39.977744006773221, -8.204051464584408],
              [-39.97774410852125, -8.204051412376582],
              [-39.977744209600559, -8.204051358894876],
              [-39.977744309995046, -8.20405130414778],
              [-39.977744409688789, -8.204051248144035],
              [-39.977744508665928, -8.204051190892482],
              [-39.977744606910719, -8.204051132402276],
              [-39.977744704407577, -8.204051072682685],
              [-39.977744801140972, -8.204051011743214],
              [-39.97774489709554, -8.204050949593556],
              [-39.977744992256035, -8.204050886243555],
              [-39.977745086607328, -8.204050821703328],
              [-39.977745180134413, -8.204050755983111],
              [-39.977745272822425, -8.204050689093336],
              [-39.977745364656641, -8.204050621044662],
              [-39.977745455622454, -8.20405055184788],
              [-39.977745545705439, -8.204050481513997],
              [-39.977745634891235, -8.204050410054212],
              [-39.977745723165661, -8.204050337479858],
              [-39.977745810514712, -8.204050263802493],
              [-39.977745896924496, -8.204050189033806],
              [-39.977745982381279, -8.204050113185708],
              [-39.977746066871475, -8.204050036270212],
              [-39.977746150381662, -8.204049958299612],
              [-39.977746232898554, -8.204049879286218],
              [-39.977746314409032, -8.204049799242634],
              [-39.977746394900159, -8.204049718181595],
              [-39.977746474359122, -8.204049636115981],
              [-39.977746552773297, -8.204049553058827],
              [-39.977746630130213, -8.204049469023325],
              [-39.977746706417598, -8.204049384022841],
              [-39.97774678162331, -8.204049298070887],
              [-39.977746855735383, -8.204049211181118],
              [-39.977746928742057, -8.204049123367394],
              [-39.977747000631716, -8.204049034643607],
              [-39.977747071392933, -8.204048945023883],
              [-39.977747141014476, -8.204048854522471],
              [-39.977747209485251, -8.204048763153784],
              [-39.977747276794403, -8.204048670932282],
              [-39.97774734293121, -8.204048577872666],
              [-39.977747407885154, -8.204048483989721],
              [-39.977747471645962, -8.204048389298377],
              [-39.977747534203431, -8.204048293813653],
              [-39.977747595547676, -8.204048197550771],
              [-39.9777476556689, -8.204048100525009],
              [-39.977747714557573, -8.204048002751803],
              [-39.977747772204332, -8.204047904246675],
              [-39.977747828600002, -8.204047805025288],
              [-39.977747883735624, -8.204047705103431],
              [-39.97774793760243, -8.204047604496946],
              [-39.977747990191887, -8.204047503221885],
              [-39.977748041495595, -8.204047401294277],
              [-39.977748091505426, -8.204047298730382],
              [-39.977748140213421, -8.204047195546472],
              [-39.977748187611823, -8.204047091758977],
              [-39.977748233693141, -8.204046987384356],
              [-39.977748278450001, -8.204046882439203],
              [-39.97774832187531, -8.204046776940213],
              [-39.977748363962185, -8.20404667090415],
              [-39.97774840470391, -8.204046564347889],
              [-39.977748444094011, -8.204046457288326],
              [-39.977748482126231, -8.204046349742534],
              [-39.977748518794527, -8.204046241727561],
              [-39.977748535613131, -8.20404627132846],
              [-39.977969138097102, -8.203382703655778],
              [-39.977969098350385, -8.203382720246271],
              [-39.977969136560716, -8.203382602618623],
              [-39.977969173153568, -8.203382484481562],
              [-39.977969208122111, -8.203382365857163],
              [-39.97796924145981, -8.203382246767642],
              [-39.977969273160419, -8.203382127235166],
              [-39.977969303218039, -8.203382007282153],
              [-39.97796933162703, -8.203381886930975],
              [-39.977969358382097, -8.203381766204149],
              [-39.977969383478253, -8.203381645124212],
              [-39.977969406910766, -8.203381523713782],
              [-39.977969428675294, -8.203381401995612],
              [-39.977969448767766, -8.203381279992348],
              [-39.977969467184415, -8.20338115772687],
              [-39.977969483921811, -8.203381035221987],
              [-39.977969498976812, -8.203380912500606],
              [-39.977969512346625, -8.203380789585657],
              [-39.977969524028737, -8.203380666500092],
              [-39.977969534020964, -8.203380543266947],
              [-39.977969542321446, -8.203380419909221],
              [-39.97796954892862, -8.203380296449961],
              [-39.977969553841277, -8.203380172912247],
              [-39.977969557058458, -8.203380049319188],
              [-39.977969558579609, -8.203379925693866],
              [-39.977969558404403, -8.203379802059359],
              [-39.977969556532905, -8.203379678438798],
              [-39.97796955296544, -8.203379554855282],
              [-39.977969547702706, -8.203379431331884],
              [-39.977969540745647, -8.203379307891709],
              [-39.977969532095592, -8.203379184557818],
              [-39.977969521754162, -8.20337906135326],
              [-39.977969509723259, -8.203378938301068],
              [-39.977969496005144, -8.203378815424211],
              [-39.977969480602397, -8.203378692745661],
              [-39.977969463517873, -8.203378570288336],
              [-39.977969444754784, -8.203378448075163],
              [-39.977969424316619, -8.203378326128934],
              [-39.977969402207222, -8.203378204472441],
              [-39.9779693784307, -8.203378083128444],
              [-39.977969352991508, -8.203377962119593],
              [-39.977969325894392, -8.203377841468518],
              [-39.977969297144412, -8.203377721197779],
              [-39.977969266746967, -8.203377601329768],
              [-39.977969234707707, -8.203377481886978],
              [-39.977969201032636, -8.203377362891683],
              [-39.977969165728034, -8.203377244366136],
              [-39.977969128800531, -8.203377126332477],
              [-39.97796909025697, -8.203377008812774],
              [-39.977969050104598, -8.203376891828993],
              [-39.977969008350911, -8.20337677540296],
              [-39.977968965003726, -8.203376659556479],
              [-39.977968920071099, -8.203376544311174],
              [-39.977968873561458, -8.203376429688589],
              [-39.977968825483487, -8.203376315710132],
              [-39.977968775846186, -8.203376202397111],
              [-39.977968724658801, -8.203376089770703],
              [-39.977968671930931, -8.203375977851918],
              [-39.977968617672396, -8.203375866661736],
              [-39.977968561893363, -8.203375756220868],
              [-39.977968504604235, -8.203375646550013],
              [-39.977968445815726, -8.203375537669629],
              [-39.97796838553883, -8.203375429600055],
              [-39.977968323784793, -8.203375322361511],
              [-39.97796826056517, -8.203375215974019],
              [-39.977968195891762, -8.203375110457479],
              [-39.977968129776656, -8.203375005831589],
              [-39.977968062232236, -8.203374902115891],
              [-39.977967993271086, -8.203374799329799],
              [-39.977967922906117, -8.203374697492519],
              [-39.977967851150453, -8.203374596623048],
              [-39.977967778017529, -8.203374496740251],
              [-39.977967703520989, -8.203374397862818],
              [-39.977967627674794, -8.20337430000918],
              [-39.977967550493062, -8.203374203197654],
              [-39.977967471990262, -8.20337410744634],
              [-39.977967392181043, -8.203374012773098],
              [-39.977967311080313, -8.203373919195661],
              [-39.977967228703243, -8.203373826731484],
              [-39.977967145065215, -8.203373735397838],
              [-39.977967060181868, -8.20337364521183],
              [-39.977966974069048, -8.203373556190293],
              [-39.977966886742877, -8.203373468349834],
              [-39.977966798219633, -8.203373381706905],
              [-39.977966708515901, -8.203373296277688],
              [-39.977966617648391, -8.203373212078134],
              [-39.977966525634152, -8.203373129123998],
              [-39.977966432490319, -8.203373047430786],
              [-39.977966338234332, -8.203372967013729],
              [-39.977966242883788, -8.203372887887864],
              [-39.977966146456517, -8.203372810067982],
              [-39.977966048970522, -8.20337273356866],
              [-39.977965950444037, -8.203372658404172],
              [-39.977965850895472, -8.203372584588537],
              [-39.977965750343415, -8.203372512135582],
              [-39.977965648806666, -8.203372441058834],
              [-39.977965546304212, -8.20337237137157],
              [-39.977965442855179, -8.2033723030868],
              [-39.977965338478917, -8.203372236217318],
              [-39.977965233194936, -8.203372170775609],
              [-39.977965127022884, -8.20337210677387],
              [-39.977965118023391, -8.203372131663615],
              [-39.975702742899209, -8.202029300703316],
              [-39.975702560903812, -8.20202912025918],
              [-39.974929159182516, -8.201591984350555],
              [-39.974929158823855, -8.201592017476518],
              [-39.97492896837543, -8.201591906763642],
              [-39.974928780682177, -8.20159179147487],
              [-39.974928595854799, -8.20159167167825],
              [-39.97492841400237, -8.201591547444441],
              [-39.97492823523217, -8.201591418846713],
              [-39.974928059649685, -8.201591285960994],
              [-39.974927887358504, -8.201591148865633],
              [-39.974927718460258, -8.201591007641571],
              [-39.974927553054634, -8.201590862372104],
              [-39.974927391239191, -8.201590713142927],
              [-39.974927233109419, -8.201590560042099],
              [-39.974927078758604, -8.201590403159964],
              [-39.974926928277789, -8.201590242589052],
              [-39.974926781755812, -8.201590078424115],
              [-39.97492663927909, -8.201589910762019],
              [-39.974926500931666, -8.201589739701658],
              [-39.974926366795195, -8.201589565343976],
              [-39.974926236948811, -8.201589387791847],
              [-39.974926111469109, -8.201589207150038],
              [-39.974925990430144, -8.201589023525083],
              [-39.97492587390331, -8.201588837025358],
              [-39.974925761957365, -8.201588647760905],
              [-39.974925654658342, -8.201588455843345],
              [-39.974925552069578, -8.201588261385959],
              [-39.97492545425159, -8.201588064503436],
              [-39.974925361262052, -8.20158786531198],
              [-39.97492527315589, -8.201587663929056],
              [-39.974925189985051, -8.201587460473544],
              [-39.974925111798605, -8.201587255065418],
              [-39.974925038642688, -8.201587047825891],
              [-39.974924970560465, -8.201586838877288],
              [-39.97492490759209, -8.201586628342801],
              [-39.974924849774737, -8.201586416346698],
              [-39.974924797142506, -8.201586203014086],
              [-39.974924749726441, -8.201585988470759],
              [-39.974924707554536, -8.20158577284335],
              [-39.974924670651646, -8.201585556259021],
              [-39.974924639039585, -8.201585338845634],
              [-39.974924612736949, -8.201585120731393],
              [-39.974924591759311, -8.201584902045015],
              [-39.974924576119022, -8.201584682915543],
              [-39.974924565825305, -8.201584463472233],
              [-39.97492456088424, -8.20158424384455],
              [-39.974924561298749, -8.201584024162102],
              [-39.97492456706857, -8.20158380455449],
              [-39.974924578190318, -8.201583585151301],
              [-39.974924594657409, -8.201583366081953],
              [-39.97492461646015, -8.201583147475709],
              [-39.97492464358568, -8.201582929461534],
              [-39.974924676017963, -8.201582712168074],
              [-39.974924713737892, -8.201582495723519],
              [-39.974924756723205, -8.20158228025559],
              [-39.974924804948557, -8.201582065891392],
              [-39.97492485838545, -8.201581852757418],
              [-39.974924917002397, -8.201581640979391],
              [-39.974924980764804, -8.201581430682296],
              [-39.97492504963504, -8.201581221990176],
              [-39.97492512357249, -8.201581015026175],
              [-39.97492520253352, -8.201580809912381],
              [-39.974925286471553, -8.201580606769825],
              [-39.974925375337037, -8.201580405718378],
              [-39.974925469077583, -8.201580206876635],
              [-39.974925567637847, -8.201580010361898],
              [-39.974925670959699, -8.201579816290149],
              [-39.974925778982183, -8.201579624775896],
              [-39.974925891641554, -8.201579435932093],
              [-39.974926008871336, -8.201579249870177],
              [-39.974926130602377, -8.201579066699916],
              [-39.974926256762863, -8.201578886529393],
              [-39.974926387278344, -8.201578709464904],
              [-39.974926522071833, -8.201578535610897],
              [-39.97492666106379, -8.20157836506999],
              [-39.974926804172192, -8.201578197942771],
              [-39.974926951312639, -8.201578034327852],
              [-39.974927102398304, -8.201577874321755],
              [-39.974927257340049, -8.201577718018898],
              [-39.974927416046441, -8.201577565511487],
              [-39.974927578423866, -8.201577416889499],
              [-39.974927744376522, -8.201577272240634],
              [-39.974927913806482, -8.20157713165022],
              [-39.974928086613787, -8.201576995201208],
              [-39.974928262696494, -8.201576862974138],
              [-39.974928441950702, -8.20157673504697],
              [-39.974928624270646, -8.201576611495232],
              [-39.974928809548764, -8.201576492391766],
              [-39.97492899767574, -8.201576377806875],
              [-39.9749291885406, -8.201576267808155],
              [-39.974929382030695, -8.201576162460524],
              [-39.974929578031904, -8.20157606182611],
              [-39.974929776428567, -8.20157596596432],
              [-39.974929977103649, -8.201575874931653],
              [-39.974930179938703, -8.201575788781911],
              [-39.974930384814115, -8.201575707565851],
              [-39.974930591608967, -8.201575631331387],
              [-39.974930800201278, -8.201575560123517],
              [-39.974931010467962, -8.201575493984224],
              [-39.974931222284972, -8.201575432952621],
              [-39.974931435527338, -8.201575377064604],
              [-39.974931650069067, -8.201575326353323],
              [-39.974931660393537, -8.201575336905046],
              [-39.975926947729015, -8.201352791847507],
              [-39.975926924847286, -8.201352756545186],
              [-39.975926938111186, -8.201352753568603],
              [-39.975926951370603, -8.201352750572241],
              [-39.975926964625494, -8.201352747556108],
              [-39.975926977875829, -8.201352744520193],
              [-39.975926991121597, -8.201352741464531],
              [-39.975927004362752, -8.201352738389069],
              [-39.975927017599268, -8.201352735293856],
              [-39.975927030831116, -8.201352732178911],
              [-39.975927044058267, -8.201352729044215],
              [-39.975927057280686, -8.201352725889791],
              [-39.975927070498358, -8.201352722715633],
              [-39.975927083711234, -8.201352719521743],
              [-39.9759270969193, -8.201352716308152],
              [-39.97592711012252, -8.201352713074867],
              [-39.975927123320858, -8.201352709821881],
              [-39.975927136514301, -8.201352706549182],
              [-39.975927149702798, -8.201352703256816],
              [-39.975927162886343, -8.201352699944771],
              [-39.975927176064886, -8.201352696613061],
              [-39.975927189238412, -8.20135269326169],
              [-39.975927202406872, -8.201352689890651],
              [-39.975927215570266, -8.201352686499964],
              [-39.975927228728537, -8.201352683089649],
              [-39.975927241881671, -8.201352679659706],
              [-39.975927255029646, -8.201352676210146],
              [-39.975927268172398, -8.20135267274096],
              [-39.975927281309922, -8.201352669252158],
              [-39.975927294442208, -8.201352665743759],
              [-39.975927307569179, -8.201352662215783],
              [-39.975927320690865, -8.20135265866821],
              [-39.975927333807171, -8.201352655101056],
              [-39.975927346918098, -8.201352651514361],
              [-39.975927360023633, -8.201352647908067],
              [-39.975927373123739, -8.201352644282228],
              [-39.975927386218366, -8.201352640636845],
              [-39.975927399307494, -8.201352636971931],
              [-39.975927412391123, -8.201352633287526],
              [-39.975927425469166, -8.201352629583557],
              [-39.975927438541653, -8.201352625860094],
              [-39.97592745160852, -8.201352622117122],
              [-39.975927464669738, -8.201352618354672],
              [-39.975927477725278, -8.201352614572707],
              [-39.975927490775142, -8.20135261077127],
              [-39.975927503819271, -8.201352606950373],
              [-39.975927516857631, -8.201352603110013],
              [-39.9759275298902, -8.201352599250193],
              [-39.975927542916963, -8.201352595370929],
              [-39.975927555937879, -8.20135259147226],
              [-39.975927568952919, -8.201352587554142],
              [-39.975927581962047, -8.2013525836166],
              [-39.975927594965249, -8.201352579659666],
              [-39.975927607962483, -8.201352575683327],
              [-39.975927620953726, -8.201352571687588],
              [-39.975927633938959, -8.201352567672496],
              [-39.975927646918116, -8.201352563638013],
              [-39.975927659891205, -8.201352559584166],
              [-39.975927672858198, -8.201352555510963],
              [-39.975927685819038, -8.201352551418422],
              [-39.975927698773717, -8.201352547306536],
              [-39.9759277117222, -8.201352543175346],
              [-39.975927724664473, -8.201352539024827],
              [-39.975927737600458, -8.201352534854971],
              [-39.975927750530175, -8.201352530665853],
              [-39.975927763453591, -8.201352526457438],
              [-39.975927776370661, -8.201352522229721],
              [-39.97592778928135, -8.201352517982754],
              [-39.975927802185652, -8.201352513716527],
              [-39.975927815083523, -8.201352509431048],
              [-39.975927827974935, -8.201352505126325],
              [-39.975927840859868, -8.201352500802365],
              [-39.975927853738277, -8.201352496459181],
              [-39.975927866610142, -8.201352492096792],
              [-39.975927879475435, -8.201352487715198],
              [-39.975927892334134, -8.201352483314418],
              [-39.975927905186211, -8.201352478894446],
              [-39.975927918031608, -8.201352474455303],
              [-39.975927930870334, -8.201352469997005],
              [-39.975927943702331, -8.201352465519538],
              [-39.975927956527592, -8.20135246102293],
              [-39.975927969346067, -8.201352456507204],
              [-39.975927982157749, -8.201352451972355],
              [-39.975927994962611, -8.201352447418387],
              [-39.975928007760594, -8.201352442845312],
              [-39.975928020551692, -8.201352438253155],
              [-39.975928033335855, -8.201352433641908],
              [-39.975928046113104, -8.201352429011592],
              [-39.975928058883362, -8.201352424362222],
              [-39.975928071646621, -8.201352419693784],
              [-39.975928084402831, -8.20135241500631],
              [-39.975928097152, -8.201352410299817],
              [-39.975928109894085, -8.201352405574289],
              [-39.975928122629035, -8.201352400829753],
              [-39.975928135356853, -8.201352396066222],
              [-39.975928148077479, -8.201352391283718],
              [-39.9759281607909, -8.201352386482217],
              [-39.975928173497088, -8.201352381661758],
              [-39.975928186196036, -8.201352376822356],
              [-39.975928198887679, -8.201352371963997],
              [-39.975928211572011, -8.201352367086688],
              [-39.975928217503892, -8.201352336561692],
              [-39.977396251095499, -8.200786590435635],
              [-39.977396266001989, -8.20078661802569],
              [-39.977396313827214, -8.20078659943993],
              [-39.977396361546212, -8.200786580585072],
              [-39.9773964091574, -8.200786561461616],
              [-39.977396456659278, -8.200786542070231],
              [-39.977396504050361, -8.200786522411519],
              [-39.977396551329072, -8.200786502486114],
              [-39.977396598493947, -8.200786482294651],
              [-39.977396645543458, -8.200786461837785],
              [-39.97739669247612, -8.200786441116147],
              [-39.977396739290398, -8.200786420130427],
              [-39.977396785984809, -8.200786398881293],
              [-39.977396832557886, -8.200786377369415],
              [-39.977396879008111, -8.200786355595474],
              [-39.977396925333991, -8.200786333560179],
              [-39.97739697153407, -8.200786311264226],
              [-39.977397017606862, -8.200786288708343],
              [-39.977397063550903, -8.200786265893226],
              [-39.977397109364688, -8.200786242819632],
              [-39.977397155046781, -8.200786219488277],
              [-39.977397200595725, -8.200786195899934],
              [-39.97739724601005, -8.200786172055306],
              [-39.977397291288305, -8.200786147955212],
              [-39.97739733642905, -8.200786123600418],
              [-39.977397381430826, -8.200786098991669],
              [-39.977397426292228, -8.200786074129757],
              [-39.977397471011777, -8.200786049015484],
              [-39.977397515588066, -8.200786023649677],
              [-39.977397560019668, -8.20078599803311],
              [-39.977397604305168, -8.200785972166612],
              [-39.977397648443137, -8.200785946051035],
              [-39.97739769243217, -8.200785919687201],
              [-39.977397736270866, -8.200785893075945],
              [-39.977397779957812, -8.200785866218105],
              [-39.977397823491614, -8.200785839114568],
              [-39.977397866870888, -8.200785811766192],
              [-39.977397910094219, -8.20078578417386],
              [-39.977397953160278, -8.200785756338398],
              [-39.977397996067637, -8.200785728260804],
              [-39.97739803881494, -8.200785699941873],
              [-39.977398081400828, -8.200785671382567],
              [-39.977398123823939, -8.200785642583767],
              [-39.977398166082907, -8.200785613546405],
              [-39.977398208176389, -8.200785584271443],
              [-39.977398250103029, -8.200785554759772],
              [-39.977398291861483, -8.200785525012364],
              [-39.977398333450445, -8.200785495030125],
              [-39.97739837486855, -8.200785464814089],
              [-39.97739841611449, -8.200785434365169],
              [-39.977398457186958, -8.200785403684343],
              [-39.977398498084604, -8.200785372772613],
              [-39.977398538806156, -8.200785341630938],
              [-39.9773985793503, -8.200785310260347],
              [-39.977398619715721, -8.20078527866181],
              [-39.977398659901162, -8.20078524683637],
              [-39.977398699905322, -8.200785214785007],
              [-39.977398739726908, -8.200785182508808],
              [-39.977398779364663, -8.200785150008709],
              [-39.977398818817321, -8.200785117285866],
              [-39.977398858083617, -8.200785084341224],
              [-39.977398897162288, -8.200785051175899],
              [-39.977398936052083, -8.200785017790921],
              [-39.977398974751779, -8.20078498418737],
              [-39.977399013260111, -8.200784950366328],
              [-39.977399051575865, -8.200784916328839],
              [-39.977399089697805, -8.200784882076041],
              [-39.977399127624736, -8.200784847609011],
              [-39.977399165355415, -8.200784812928816],
              [-39.977399202888655, -8.200784778036633],
              [-39.977399240223235, -8.2007847429335],
              [-39.977399277357989, -8.200784707620626],
              [-39.977399314291709, -8.200784672099065],
              [-39.977399351023209, -8.200784636369972],
              [-39.977399387551337, -8.200784600434524],
              [-39.977399423874914, -8.200784564293844],
              [-39.977399459992789, -8.200784527949084],
              [-39.977399495903775, -8.200784491401411],
              [-39.97739953160675, -8.200784454652],
              [-39.977399567100569, -8.200784417702007],
              [-39.977399602384089, -8.200784380552644],
              [-39.977399637456202, -8.200784343205093],
              [-39.977399672315755, -8.20078430566052],
              [-39.977399706961656, -8.200784267920158],
              [-39.97739974139278, -8.200784229985205],
              [-39.977399775608049, -8.20078419185684],
              [-39.977399809606354, -8.200784153536318],
              [-39.977399843386607, -8.200784115024852],
              [-39.977399876947729, -8.200784076323689],
              [-39.977399910288653, -8.200784037434063],
              [-39.977399943408315, -8.200783998357226],
              [-39.977399976305634, -8.200783959094371],
              [-39.977400008979586, -8.200783919646856],
              [-39.977400041429107, -8.200783880015834],
              [-39.977400073653158, -8.200783840202652],
              [-39.97740010565073, -8.200783800208551],
              [-39.97740013742078, -8.200783760034797],
              [-39.977400168962298, -8.200783719682672],
              [-39.977400200274282, -8.200783679153499],
              [-39.977400231355716, -8.200783638448558],
              [-39.977400262205613, -8.200783597569146],
              [-39.977400237985805, -8.200783596173356],
              [-39.978074333372767, -8.199885135502306],
              [-39.978074328931143, -8.199885107138265],
              [-39.978074363941225, -8.199885060155633],
              [-39.978074398645489, -8.19988501294829],
              [-39.978074433042458, -8.19988496551821],
              [-39.978074467130725, -8.19988491786742],
              [-39.978074500908811, -8.199884869997897],
              [-39.978074534375352, -8.199884821911628],
              [-39.978074567528914, -8.199884773610655],
              [-39.978074600368132, -8.199884725096988],
              [-39.978074632891619, -8.199884676372637],
              [-39.978074665098028, -8.199884627439657],
              [-39.978074696985992, -8.199884578300122],
              [-39.978074728554169, -8.199884528956042],
              [-39.97807475980126, -8.199884479409512],
              [-39.978074790725955, -8.199884429662621],
              [-39.978074821326949, -8.199884379717409],
              [-39.978074851602976, -8.199884329575987],
              [-39.978074881552764, -8.199884279240448],
              [-39.978074911175035, -8.19988422871292],
              [-39.978074940468574, -8.199884177995502],
              [-39.97807496943215, -8.199884127090311],
              [-39.97807499806455, -8.199884075999492],
              [-39.978075026364571, -8.199884024725161],
              [-39.978075054331029, -8.199883973269483],
              [-39.978075081962771, -8.199883921634633],
              [-39.978075109258612, -8.199883869822735],
              [-39.978075136217434, -8.199883817835987],
              [-39.978075162838081, -8.199883765676526],
              [-39.978075189119458, -8.199883713346578],
              [-39.978075215060478, -8.19988366084829],
              [-39.978075240660033, -8.199883608183912],
              [-39.978075265917049, -8.1998835553556],
              [-39.978075290830482, -8.199883502365591],
              [-39.978075315399288, -8.199883449216106],
              [-39.978075339622428, -8.199883395909342],
              [-39.978075363498895, -8.199883342447562],
              [-39.978075387027694, -8.199883288833007],
              [-39.978075410207829, -8.199883235067881],
              [-39.978075433038349, -8.19988318115448],
              [-39.97807545551828, -8.199883127095015],
              [-39.978075477646684, -8.199883072891801],
              [-39.978075499422637, -8.199883018547073],
              [-39.978075520845231, -8.199882964063105],
              [-39.978075541913562, -8.1998829094422],
              [-39.978075562626749, -8.199882854686631],
              [-39.978075582983941, -8.199882799798685],
              [-39.978075602984262, -8.199882744780663],
              [-39.978075622626882, -8.199882689634871],
              [-39.978075641910991, -8.19988263436362],
              [-39.978075660835771, -8.199882578969202],
              [-39.978075679400433, -8.199882523453976],
              [-39.978075697604204, -8.199882467820244],
              [-39.978075715446302, -8.199882412070318],
              [-39.978075732926008, -8.199882356206569],
              [-39.978075750042578, -8.1998823002313],
              [-39.978075766795286, -8.199882244146867],
              [-39.978075783183442, -8.199882187955632],
              [-39.978075799206351, -8.199882131659932],
              [-39.978075814863352, -8.199882075262131],
              [-39.978075830153792, -8.199882018764571],
              [-39.978075845077036, -8.199881962169632],
              [-39.978075859632426, -8.199881905479685],
              [-39.978075873819385, -8.199881848697085],
              [-39.978075887637317, -8.199881791824257],
              [-39.978075901085631, -8.199881734863517],
              [-39.97807591416376, -8.199881677817286],
              [-39.978075926871178, -8.199881620687956],
              [-39.978075939207329, -8.199881563477897],
              [-39.978075951171718, -8.199881506189525],
              [-39.978075962763832, -8.199881448825224],
              [-39.978075973983188, -8.199881391387434],
              [-39.978075984829324, -8.199881333878498],
              [-39.978075995301772, -8.199881276300859],
              [-39.978076005400098, -8.199881218656911],
              [-39.978076015123875, -8.19988116094907],
              [-39.978076024472706, -8.199881103179786],
              [-39.978076033446214, -8.199881045351425],
              [-39.978076042043966, -8.199880987466429],
              [-39.978076050265685, -8.199880929527225],
              [-39.978076058110965, -8.199880871536239],
              [-39.978076065579494, -8.1998808134959],
              [-39.978076072670959, -8.19988075540863],
              [-39.978076079385076, -8.199880697276875],
              [-39.97807608572154, -8.199880639103048],
              [-39.978076091680116, -8.199880580889586],
              [-39.978076097260526, -8.19988052263894],
              [-39.978076102462559, -8.199880464353528],
              [-39.978076107285972, -8.199880406035815],
              [-39.978076111730587, -8.199880347688222],
              [-39.978076115796213, -8.199880289313223],
              [-39.978076119482658, -8.199880230913228],
              [-39.978076122789808, -8.199880172490689],
              [-39.978076125717472, -8.199880114048044],
              [-39.978076128265585, -8.199880055587757],
              [-39.978076130434005, -8.199879997112269],
              [-39.97807613222264, -8.199879938624022],
              [-39.978076133631404, -8.199879880125478],
              [-39.978076134660306, -8.19987982161908],
              [-39.978076135309223, -8.199879763107269],
              [-39.978076135578178, -8.199879704592485],
              [-39.978074777033555, -8.199323759083741],
              [-39.978074777023771, -8.199323754980288],
              [-39.978074776436586, -8.199323671803425],
              [-39.978074775081645, -8.199323588635453],
              [-39.978074772959033, -8.199323505483408],
              [-39.978074770068964, -8.19932342235435],
              [-39.978074766411652, -8.199323339255281],
              [-39.978074761987429, -8.199323256193257],
              [-39.978074756796651, -8.199323173175273],
              [-39.978074750839752, -8.199323090208352],
              [-39.978074744117258, -8.199323007299537],
              [-39.978074736629743, -8.199322924455801],
              [-39.978074728377798, -8.199322841684184],
              [-39.978074719362176, -8.199322758991679],
              [-39.978074709583588, -8.199322676385306],
              [-39.978074699042892, -8.199322593871985],
              [-39.978074687740971, -8.199322511458771],
              [-39.978074675678783, -8.199322429152609],
              [-39.97807466285736, -8.199322346960418],
              [-39.978074649277758, -8.199322264889197],
              [-39.978074634941137, -8.199322182945878],
              [-39.978074619848734, -8.199322101137389],
              [-39.978074604001797, -8.199322019470657],
              [-39.978074587401672, -8.199321937952561],
              [-39.978074570049763, -8.199321856590032],
              [-39.978074551947543, -8.199321775389933],
              [-39.978074533096546, -8.199321694359149],
              [-39.97807451349837, -8.1993216135045],
              [-39.978074493154644, -8.199321532832895],
              [-39.978074472067114, -8.199321452351061],
              [-39.978074450237564, -8.199321372065869],
              [-39.978074427667835, -8.199321291984102],
              [-39.978074404359838, -8.199321212112515],
              [-39.97807438031554, -8.199321132457889],
              [-39.978074355536982, -8.199321053026939],
              [-39.978074330026239, -8.199320973826385],
              [-39.978074303785505, -8.199320894862961],
              [-39.978074276816969, -8.199320816143295],
              [-39.978074249122905, -8.199320737674091],
              [-39.978074220705707, -8.199320659461939],
              [-39.978074191567728, -8.199320581513483],
              [-39.978074161711454, -8.199320503835308],
              [-39.978074131139387, -8.199320426434003],
              [-39.978074099854162, -8.199320349316094],
              [-39.978074067858365, -8.19932027248813],
              [-39.978074035154755, -8.199320195956572],
              [-39.978074001746059, -8.199320119727917],
              [-39.978073967635133, -8.199320043808608],
              [-39.978073932824834, -8.199319968205057],
              [-39.978073897318133, -8.199319892923675],
              [-39.97807386111802, -8.199319817970828],
              [-39.97807382422755, -8.199319743352852],
              [-39.978073786649865, -8.199319669076043],
              [-39.97807374838812, -8.199319595146694],
              [-39.978073709445553, -8.199319521571061],
              [-39.97807366982547, -8.199319448355361],
              [-39.978073629531231, -8.19931937550581],
              [-39.978073588566218, -8.199319303028549],
              [-39.978073546933906, -8.199319230929706],
              [-39.978073504637827, -8.199319159215388],
              [-39.978073461681568, -8.199319087891663],
              [-39.978073418068703, -8.199319016964553],
              [-39.978073373803007, -8.199318946440084],
              [-39.978073328888158, -8.199318876324195],
              [-39.978073283328001, -8.1993188066228],
              [-39.978073237126331, -8.199318737341841],
              [-39.978073190287098, -8.199318668487157],
              [-39.978073142814281, -8.199318600064558],
              [-39.978073094711867, -8.199318532079852],
              [-39.978073045983919, -8.199318464538774],
              [-39.978072996634552, -8.199318397447021],
              [-39.978072946667986, -8.199318330810335],
              [-39.978072896088399, -8.199318264634275],
              [-39.978072844900105, -8.199318198924479],
              [-39.978072793107415, -8.199318133686488],
              [-39.978072740714701, -8.199318068925859],
              [-39.97807268772641, -8.199318004648015],
              [-39.978072634147026, -8.199317940858428],
              [-39.978072579981074, -8.199317877562454],
              [-39.978072525233131, -8.199317814765497],
              [-39.978072469907822, -8.199317752472844],
              [-39.978072414009858, -8.199317690689764],
              [-39.978072357543937, -8.199317629421492],
              [-39.978072300514839, -8.1993175686732],
              [-39.978072242927404, -8.19931750845004],
              [-39.978072184786491, -8.199317448757089],
              [-39.978072126097011, -8.19931738959939],
              [-39.978072066863952, -8.199317330982002],
              [-39.978072007092287, -8.199317272909795],
              [-39.978071946787097, -8.19931721538773],
              [-39.97807188595349, -8.199317158420717],
              [-39.978071824596597, -8.199317102013481],
              [-39.978071762721598, -8.199317046170846],
              [-39.978071700333764, -8.199316990897552],
              [-39.978071637438319, -8.199316936198221],
              [-39.978071574040634, -8.199316882077515],
              [-39.978071510146044, -8.199316828540011],
              [-39.978071445759959, -8.19931677559021],
              [-39.978071380887819, -8.199316723232641],
              [-39.978071315535118, -8.199316671471696],
              [-39.978071249707384, -8.199316620311759],
              [-39.978071219291408, -8.199316622772148],
              [-39.977058695941281, -8.198537113216267],
              [-39.977058657045212, -8.198537087034461],
              [-39.977058566091962, -8.198537016089492],
              [-39.977058476052179, -8.198536943997194],
              [-39.977058386940399, -8.198536870769223],
              [-39.977058298771098, -8.198536796417471],
              [-39.977058211558528, -8.198536720953932],
              [-39.977058125316802, -8.198536644390856],
              [-39.977058040059902, -8.198536566740634],
              [-39.977057955801627, -8.198536488015822],
              [-39.977057872555619, -8.198536408229202],
              [-39.977057790335358, -8.198536327393668],
              [-39.977057709154153, -8.198536245522309],
              [-39.977057629025161, -8.198536162628383],
              [-39.977057549961344, -8.198536078725317],
              [-39.97705747197552, -8.198535993826699],
              [-39.977057395080301, -8.19853590794629],
              [-39.977057319288136, -8.198535821097957],
              [-39.977057244611295, -8.19853573329579],
              [-39.977057171061887, -8.198535644554012],
              [-39.977057098651819, -8.198535554886977],
              [-39.977057027392817, -8.198535464309217],
              [-39.977056957296405, -8.198535372835366],
              [-39.97705688837393, -8.198535280480291],
              [-39.97705682063657, -8.198535187258889],
              [-39.977056754095294, -8.198535093186287],
              [-39.977056688760854, -8.198534998277704],
              [-39.977056624643843, -8.198534902548543],
              [-39.977056561754651, -8.198534806014262],
              [-39.977056500103451, -8.1985347086905],
              [-39.977056439700227, -8.198534610593043],
              [-39.977056380554764, -8.198534511737726],
              [-39.977056322676638, -8.198534412140603],
              [-39.977056266075216, -8.19853431181777],
              [-39.977056210759649, -8.198534210785484],
              [-39.977056156738932, -8.198534109060107],
              [-39.977056104021784, -8.198534006658116],
              [-39.977056052616753, -8.198533903596083],
              [-39.977056002532152, -8.198533799890678],
              [-39.977055953776087, -8.19853369555873],
              [-39.977055906356462, -8.198533590617101],
              [-39.977055860280963, -8.198533485082804],
              [-39.977055815557044, -8.198533378972915],
              [-39.977055772191925, -8.198533272304619],
              [-39.97705573019266, -8.198533165095185],
              [-39.97705568956605, -8.198533057361985],
              [-39.97705565031864, -8.198532949122431],
              [-39.977055612456823, -8.198532840394087],
              [-39.977055575986704, -8.198532731194563],
              [-39.977055540914193, -8.19853262154151],
              [-39.977055507244984, -8.198532511452699],
              [-39.97705547498451, -8.198532400945963],
              [-39.97705544413801, -8.198532290039175],
              [-39.977055414710463, -8.198532178750314],
              [-39.977055386706631, -8.19853206709738],
              [-39.977055360131068, -8.198531955098463],
              [-39.977055334988073, -8.198531842771692],
              [-39.977055311281703, -8.198531730135276],
              [-39.977055289015809, -8.19853161720742],
              [-39.977055268194007, -8.198531504006423],
              [-39.977055248819646, -8.198531390550613],
              [-39.977055230895864, -8.198531276858386],
              [-39.977055214425583, -8.198531162948118],
              [-39.977055199411453, -8.198531048838269],
              [-39.977055185855896, -8.198530934547332],
              [-39.977055173761151, -8.198530820093769],
              [-39.977055163129123, -8.198530705496136],
              [-39.977055153961565, -8.198530590773004],
              [-39.977055146259957, -8.198530475942952],
              [-39.977055140025527, -8.198530361024543],
              [-39.977055135259327, -8.198530246036405],
              [-39.977055131962075, -8.198530130997147],
              [-39.977055130134353, -8.198530015925408],
              [-39.977055129776417, -8.19852990083983],
              [-39.977055130888338, -8.198529785759009],
              [-39.977055133469968, -8.198529670701602],
              [-39.977055137520857, -8.198529555686234],
              [-39.977055143040339, -8.198529440731528],
              [-39.977055150027553, -8.198529325856132],
              [-39.977055158481356, -8.198529211078593],
              [-39.977055168400348, -8.198529096417518],
              [-39.977055179782965, -8.198528981891489],
              [-39.97705519262734, -8.198528867519025],
              [-39.977055206931411, -8.198528753318675],
              [-39.977055222692833, -8.198528639308897],
              [-39.977055239909085, -8.198528525508177],
              [-39.977055258577373, -8.198528411934934],
              [-39.977055278694664, -8.198528298607568],
              [-39.977055300257703, -8.198528185544383],
              [-39.977055323262995, -8.198528072763734],
              [-39.97705534770683, -8.198527960283874],
              [-39.97705537358523, -8.198527848122993],
              [-39.977055400894024, -8.198527736299322],
              [-39.97705542962877, -8.198527624830884],
              [-39.977055459784829, -8.198527513735749],
              [-39.977055491357319, -8.198527403031951],
              [-39.977055524341118, -8.198527292737367],
              [-39.97705555873091, -8.198527182869876],
              [-39.977055594521083, -8.198527073447272],
              [-39.977055631705873, -8.198526964487231],
              [-39.977055670278972, -8.198526856007851],
              [-39.977055675376896, -8.198526900551803],
              [-39.97750246717429, -8.19729525954269],
              [-39.977502477508338, -8.197295249555431],
              [-39.977502496541852, -8.197295196575212],
              [-39.977502515244126, -8.19729514347801],
              [-39.977502533614469, -8.197295090265893],
              [-39.977502551652158, -8.197295036940938],
              [-39.977502569356474, -8.197294983505218],
              [-39.977502586726757, -8.197294929960764],
              [-39.977502603762332, -8.197294876309689],
              [-39.977502620462538, -8.197294822554035],
              [-39.977502636826728, -8.197294768695901],
              [-39.977502652854248, -8.197294714737367],
              [-39.97750266854451, -8.197294660680532],
              [-39.977502683896901, -8.19729460652748],
              [-39.977502698910811, -8.197294552280317],
              [-39.977502713585672, -8.197294497941146],
              [-39.9775027279209, -8.197294443512019],
              [-39.977502741915956, -8.197294388995097],
              [-39.977502755570285, -8.197294334392476],
              [-39.977502768883369, -8.19729427970629],
              [-39.977502781854682, -8.197294224938615],
              [-39.977502794483755, -8.197294170091599],
              [-39.977502806770055, -8.19729411516737],
              [-39.97750281871312, -8.197294060168046],
              [-39.977502830312496, -8.197294005095712],
              [-39.977502841567734, -8.19729394995257],
              [-39.977502852478402, -8.197293894740707],
              [-39.977502863044059, -8.197293839462297],
              [-39.977502873264321, -8.197293784119459],
              [-39.977502883138762, -8.197293728714341],
              [-39.977502892667019, -8.197293673249074],
              [-39.97750290184873, -8.197293617725807],
              [-39.977502910683526, -8.197293562146726],
              [-39.977502919171066, -8.197293506513923],
              [-39.977502927311022, -8.197293450829584],
              [-39.977502935103089, -8.197293395095906],
              [-39.977502942546955, -8.19729333931498],
              [-39.977502949642343, -8.197293283489007],
              [-39.97750295638896, -8.197293227620092],
              [-39.977502962786559, -8.197293171710452],
              [-39.977502968834877, -8.197293115762221],
              [-39.977502974533706, -8.197293059777607],
              [-39.977502979882793, -8.19729300375875],
              [-39.977502984881973, -8.197292947707806],
              [-39.977502989531018, -8.197292891626935],
              [-39.977502993829752, -8.197292835518374],
              [-39.977502997778011, -8.197292779384213],
              [-39.977503001375652, -8.197292723226694],
              [-39.977503004622541, -8.197292667047943],
              [-39.977503007518521, -8.197292610850161],
              [-39.977503010063501, -8.19729255463549],
              [-39.977503012257387, -8.197292498406144],
              [-39.977503014100094, -8.197292442164317],
              [-39.97750301559153, -8.197292385912126],
              [-39.977503016731667, -8.197292329651805],
              [-39.977503017520441, -8.197292273385484],
              [-39.977503017957822, -8.197292217115363],
              [-39.977503018043805, -8.197292160843613],
              [-39.977503017778375, -8.197292104572416],
              [-39.977503017161546, -8.197292048303989],
              [-39.977503016193339, -8.197291992040439],
              [-39.97750301487379, -8.197291935783978],
              [-39.977503013202963, -8.197291879536822],
              [-39.977503011180922, -8.197291823301081],
              [-39.977503008807737, -8.197291767078978],
              [-39.977503006083488, -8.197291710872664],
              [-39.977503003008295, -8.197291654684339],
              [-39.977502999582278, -8.197291598516149],
              [-39.977502995805565, -8.197291542370273],
              [-39.977502991678314, -8.197291486248931],
              [-39.977502987200658, -8.197291430154232],
              [-39.977502982372791, -8.197291374088394],
              [-39.977502977194902, -8.197291318053544],
              [-39.977502971667178, -8.197291262051904],
              [-39.977502965789853, -8.197291206085595],
              [-39.977502959563147, -8.197291150156786],
              [-39.977502952987273, -8.197291094267678],
              [-39.977502946062522, -8.197291038420412],
              [-39.977502938789144, -8.19729098261717],
              [-39.977502931167429, -8.19729092686009],
              [-39.977502923197662, -8.197290871151342],
              [-39.977502914880169, -8.197290815493064],
              [-39.977502906215257, -8.19729075988743],
              [-39.977502897203259, -8.197290704336563],
              [-39.97750288784453, -8.197290648842653],
              [-39.97750287813944, -8.197290593407798],
              [-39.977502868088344, -8.197290538034229],
              [-39.977502857691654, -8.197290482723998],
              [-39.977502846949768, -8.197290427479306],
              [-39.977502835863092, -8.197290372302247],
              [-39.977502824432051, -8.197290317195007],
              [-39.977502812657107, -8.197290262159692],
              [-39.977502800538694, -8.197290207198455],
              [-39.977502788077295, -8.197290152313384],
              [-39.9775027752734, -8.197290097506604],
              [-39.977502762127486, -8.197290042780264],
              [-39.977502748640056, -8.197289988136511],
              [-39.977502734811665, -8.197289933577391],
              [-39.977502720642832, -8.197289879105048],
              [-39.977502706134104, -8.197289824721564],
              [-39.977502691286027, -8.197289770429112],
              [-39.977502726031886, -8.197289741933584],
              [-39.977161392529432, -8.196056754756308],
              [-39.977161355407219, -8.196056794780951],
              [-39.977161339866527, -8.196056739333615],
              [-39.977161323971913, -8.19605668398594],
              [-39.977161307724025, -8.196056628740216],
              [-39.977161291123508, -8.196056573598609],
              [-39.977161274171053, -8.196056518563431],
              [-39.977161256867326, -8.196056463636872],
              [-39.977161239213046, -8.196056408821146],
              [-39.977161221208924, -8.196056354118497],
              [-39.977161202855676, -8.196056299531129],
              [-39.97716118415407, -8.196056245061268],
              [-39.977161165104846, -8.196056190711133],
              [-39.977161145708784, -8.196056136482872],
              [-39.977161125966653, -8.196056082378716],
              [-39.977161105879269, -8.196056028400868],
              [-39.977161085447449, -8.196055974551493],
              [-39.977161064672003, -8.196055920832803],
              [-39.977161043553799, -8.196055867246953],
              [-39.977161022093668, -8.196055813796105],
              [-39.977161000292483, -8.196055760482436],
              [-39.977160978151147, -8.196055707308121],
              [-39.977160955670541, -8.196055654275273],
              [-39.977160932851575, -8.196055601386101],
              [-39.977160909695179, -8.196055548642692],
              [-39.977160886202292, -8.196055496047199],
              [-39.977160862373871, -8.196055443601754],
              [-39.977160838210864, -8.196055391308485],
              [-39.977160813714256, -8.196055339169527],
              [-39.97716078888503, -8.196055287187002],
              [-39.977160763724243, -8.196055235362968],
              [-39.97716073823284, -8.196055183699542],
              [-39.977160712411916, -8.196055132198826],
              [-39.977160686262472, -8.196055080862934],
              [-39.977160659785596, -8.196055029693902],
              [-39.977160632982347, -8.196054978693789],
              [-39.977160605853825, -8.19605492786471],
              [-39.977160578401104, -8.196054877208701],
              [-39.977160550625328, -8.196054826727831],
              [-39.977160522527605, -8.196054776424106],
              [-39.977160494109064, -8.196054726299606],
              [-39.977160465370893, -8.196054676356354],
              [-39.977160436314193, -8.196054626596354],
              [-39.977160406940207, -8.196054577021615],
              [-39.977160377250073, -8.196054527634201],
              [-39.977160347245039, -8.196054478436041],
              [-39.977160316926302, -8.196054429429203],
              [-39.977160286295081, -8.196054380615589],
              [-39.977160255352615, -8.196054331997265],
              [-39.977160224100167, -8.196054283576107],
              [-39.977160192539024, -8.196054235354158],
              [-39.977160160670422, -8.196054187333324],
              [-39.977160128495683, -8.196054139515601],
              [-39.977160096016107, -8.196054091902877],
              [-39.977160063232994, -8.196054044497103],
              [-39.977160030147687, -8.196053997300178],
              [-39.977159996761522, -8.196053950314035],
              [-39.977159963075856, -8.196053903540575],
              [-39.977159929092046, -8.196053856981703],
              [-39.977159894811471, -8.196053810639294],
              [-39.977159860235531, -8.196053764515206],
              [-39.977159825365604, -8.196053718611298],
              [-39.977159790203125, -8.196053672929507],
              [-39.977159754749515, -8.196053627471601],
              [-39.977159719006195, -8.196053582239479],
              [-39.977159682974623, -8.196053537234937],
              [-39.977159646656268, -8.196053492459791],
              [-39.977159610052588, -8.196053447915912],
              [-39.977159573165068, -8.196053403605044],
              [-39.97715953599522, -8.19605335952904],
              [-39.977159498544523, -8.196053315689628],
              [-39.977159460814505, -8.196053272088626],
              [-39.977159422806707, -8.196053228727781],
              [-39.97715938452265, -8.196053185608843],
              [-39.977159345963898, -8.19605314273354],
              [-39.97715930713202, -8.196053100103692],
              [-39.977159268028558, -8.196053057720942],
              [-39.977159228655118, -8.196053015587076],
              [-39.977159189013314, -8.196052973703718],
              [-39.977159149104715, -8.196052932072625],
              [-39.977159108930948, -8.196052890695453],
              [-39.977159068493663, -8.196052849573904],
              [-39.977159027794485, -8.196052808709602],
              [-39.977158986835057, -8.196052768104256],
              [-39.977158945617042, -8.196052727759486],
              [-39.977158904142101, -8.196052687676925],
              [-39.977158862411933, -8.19605264785822],
              [-39.977158820428215, -8.196052608304949],
              [-39.977158778192667, -8.196052569018725],
              [-39.977158735706972, -8.196052530001152],
              [-39.977158692972871, -8.196052491253848],
              [-39.977158649992084, -8.196052452778284],
              [-39.977158606766359, -8.196052414576117],
              [-39.977158563297465, -8.196052376648812],
              [-39.977158519587114, -8.196052338997982],
              [-39.977158475637118, -8.196052301625121],
              [-39.977158431449261, -8.196052264531707],
              [-39.97715838702532, -8.196052227719315],
              [-39.977158342367076, -8.196052191189363],
              [-39.977158297476372, -8.196052154943379],
              [-39.977158252355011, -8.196052118982802],
              [-39.977158294897336, -8.196052149701178],
              [-39.976594574861032, -8.195605825270501],
              [-39.976594583319056, -8.195605812015788],
              [-39.976594554204077, -8.195605788865553],
              [-39.976594525184701, -8.195605765596472],
              [-39.976594496261406, -8.195605742208928],
              [-39.976594467434658, -8.195605718703352],
              [-39.976594438704986, -8.19560569508012],
              [-39.976594410072849, -8.19560567133964],
              [-39.976594381538732, -8.195605647482296],
              [-39.97659435310311, -8.195605623508477],
              [-39.976594324766467, -8.195605599418602],
              [-39.976594296529292, -8.195605575213051],
              [-39.976594268392041, -8.195605550892322],
              [-39.976594240355197, -8.195605526456728],
              [-39.976594212419229, -8.195605501906739],
              [-39.976594184584613, -8.195605477242706],
              [-39.976594156851817, -8.195605452465088],
              [-39.976594129221304, -8.195605427574305],
              [-39.97659410169355, -8.195605402570759],
              [-39.976594074268995, -8.195605377454884],
              [-39.976594046948115, -8.195605352227101],
              [-39.976594019731387, -8.195605326887828],
              [-39.976593992619229, -8.195605301437476],
              [-39.976593965612132, -8.195605275876506],
              [-39.976593938710536, -8.195605250205332],
              [-39.976593911914904, -8.19560522442438],
              [-39.976593885225675, -8.195605198534127],
              [-39.976593858643312, -8.19560517253494],
              [-39.976593832168241, -8.195605146427308],
              [-39.976593805800938, -8.195605120211633],
              [-39.97659377954183, -8.195605093888405],
              [-39.976593753391356, -8.195605067458027],
              [-39.976593727349957, -8.195605040920944],
              [-39.976593701418068, -8.195605014277637],
              [-39.976593675596142, -8.19560498752851],
              [-39.976593649884606, -8.195604960674075],
              [-39.976593624283879, -8.195604933714725],
              [-39.976593598794416, -8.19560490665093],
              [-39.976593573416636, -8.195604879483177],
              [-39.976593548150959, -8.195604852211865],
              [-39.976593522997817, -8.195604824837472],
              [-39.976593497957637, -8.195604797360499],
              [-39.976593473030832, -8.195604769781381],
              [-39.976593448217827, -8.195604742100578],
              [-39.976593423519027, -8.195604714318568],
              [-39.976593398934867, -8.195604686435813],
              [-39.976593374465764, -8.195604658452782],
              [-39.976593350112125, -8.195604630369944],
              [-39.976593325874347, -8.195604602187785],
              [-39.976593301752835, -8.195604573906744],
              [-39.97659327774803, -8.195604545527326],
              [-39.976593253860287, -8.195604517049984],
              [-39.976593230090039, -8.19560448847526],
              [-39.976593206437691, -8.195604459803583],
              [-39.976593182903642, -8.195604431035463],
              [-39.97659315948826, -8.195604402171353],
              [-39.976593136191958, -8.195604373211744],
              [-39.976593113015142, -8.195604344157132],
              [-39.976593089958158, -8.19560431500803],
              [-39.976593067021454, -8.195604285764899],
              [-39.976593044205366, -8.195604256428236],
              [-39.976593021510304, -8.195604226998544],
              [-39.976592998936653, -8.195604197476307],
              [-39.976592976484774, -8.195604167862031],
              [-39.976592954155059, -8.195604138156202],
              [-39.976592931947891, -8.195604108359316],
              [-39.976592909863605, -8.195604078471897],
              [-39.976592887902633, -8.195604048494426],
              [-39.976592866065296, -8.195604018427439],
              [-39.976592844351991, -8.195603988271404],
              [-39.97659282276306, -8.195603958026883],
              [-39.976592801298878, -8.195603927694338],
              [-39.976592779959809, -8.195603897274331],
              [-39.976592758746207, -8.195603866767293],
              [-39.976592737658443, -8.195603836173786],
              [-39.97659271669685, -8.195603805494324],
              [-39.976592695861804, -8.195603774729452],
              [-39.976592675153633, -8.195603743879603],
              [-39.976592654572698, -8.195603712945386],
              [-39.976592634119349, -8.195603681927283],
              [-39.976592613793926, -8.195603650825788],
              [-39.97659259359677, -8.195603619641467],
              [-39.976592573528222, -8.195603588374864],
              [-39.976592553588603, -8.19560355702648],
              [-39.976592533778287, -8.195603525596786],
              [-39.976592514097582, -8.195603494086392],
              [-39.976592494546821, -8.195603462495813],
              [-39.976592475126338, -8.195603430825557],
              [-39.976592455836467, -8.195603399076177],
              [-39.976592436677521, -8.195603367248196],
              [-39.976592417649812, -8.195603335342117],
              [-39.976592398753674, -8.195603303358535],
              [-39.976592379989441, -8.195603271297966],
              [-39.976592361357397, -8.195603239160981],
              [-39.976592342857877, -8.195603206948052],
              [-39.976592324491193, -8.195603174659773],
              [-39.976592306257643, -8.195603142296678],
              [-39.976592288157534, -8.195603109859318],
              [-39.976592270191183, -8.195603077348204],
              [-39.976592252358891, -8.19560304476394],
              [-39.976592234660949, -8.195603012106897],
              [-39.976592207855902, -8.195603027287927],
              [-39.975685432516137, -8.193921546173902],
              [-39.975685452542223, -8.193921530905792],
              [-39.975685406177902, -8.193921443794592],
              [-39.975685360769525, -8.193921356184966],
              [-39.975685316322505, -8.193921268087319],
              [-39.975685272842099, -8.193921179512108],
              [-39.975685230333475, -8.193921090469876],
              [-39.97568518880172, -8.193921000971232],
              [-39.975685148251728, -8.193920911026771],
              [-39.975685108688339, -8.193920820647213],
              [-39.975685070116242, -8.193920729843297],
              [-39.975685032540035, -8.193920638625775],
              [-39.975684995964194, -8.193920547005551],
              [-39.975684960393039, -8.193920454993501],
              [-39.975684925830826, -8.193920362600528],
              [-39.975684892281635, -8.193920269837641],
              [-39.975684859749478, -8.193920176715849],
              [-39.975684828238201, -8.193920083246221],
              [-39.975684797751569, -8.193919989439889],
              [-39.975684768293185, -8.193919895307994],
              [-39.97568473986658, -8.193919800861686],
              [-39.975684712475093, -8.193919706112217],
              [-39.975684686122015, -8.193919611070905],
              [-39.975684660810458, -8.193919515748963],
              [-39.975684636543448, -8.193919420157753],
              [-39.975684613323843, -8.193919324308638],
              [-39.975684591154412, -8.193919228213034],
              [-39.975684570037821, -8.193919131882309],
              [-39.975684549976528, -8.193919035327941],
              [-39.975684530972956, -8.193918938561433],
              [-39.97568451302935, -8.193918841594249],
              [-39.975684496147849, -8.19391874443791],
              [-39.975684480330457, -8.193918647104002],
              [-39.975684465579043, -8.193918549604055],
              [-39.975684451895376, -8.193918451949674],
              [-39.975684439281054, -8.19391835415245],
              [-39.975684427737619, -8.193918256224045],
              [-39.975684417266407, -8.193918158176052],
              [-39.975684407868705, -8.193918060020158],
              [-39.975684399545585, -8.193917961768054],
              [-39.975684392298042, -8.193917863431356],
              [-39.975684386126964, -8.193917765021757],
              [-39.975684381033076, -8.193917666550997],
              [-39.975684377016975, -8.193917568030779],
              [-39.975684374079123, -8.19391746947276],
              [-39.975684372219902, -8.193917370888705],
              [-39.975684371439506, -8.193917272290328],
              [-39.975684371738041, -8.193917173689309],
              [-39.97568437311547, -8.193917075097392],
              [-39.975684375571639, -8.1939169765263],
              [-39.975684379106234, -8.193916877987734],
              [-39.975684383718864, -8.193916779493433],
              [-39.97568438940894, -8.193916681055097],
              [-39.975684396175843, -8.193916582684372],
              [-39.9756844040187, -8.19391648439302],
              [-39.975684412936637, -8.193916386192715],
              [-39.975684422928559, -8.193916288095101],
              [-39.975684433993294, -8.193916190111855],
              [-39.975684446129527, -8.193916092254607],
              [-39.975684459335795, -8.193915994535013],
              [-39.975684473610563, -8.193915896964651],
              [-39.975684488952112, -8.193915799555153],
              [-39.975684505358622, -8.193915702318074],
              [-39.975684522828139, -8.193915605265017],
              [-39.975684541358603, -8.193915508407434],
              [-39.975684560947798, -8.193915411756924],
              [-39.975684581593384, -8.193915315324881],
              [-39.97568460329294, -8.19391521912285],
              [-39.975684626043858, -8.193915123162244],
              [-39.975684649843444, -8.193915027454473],
              [-39.975684674688857, -8.193914932010857],
              [-39.97568470057719, -8.193914836842822],
              [-39.975684727505303, -8.193914741961624],
              [-39.975684755470034, -8.19391464737855],
              [-39.975684784468037, -8.193914553104884],
              [-39.975684814495892, -8.193914459151754],
              [-39.975684845550013, -8.193914365530384],
              [-39.975684877626712, -8.193914272251886],
              [-39.975684910722173, -8.193914179327326],
              [-39.975684944832473, -8.193914086767791],
              [-39.975684979953542, -8.193913994584261],
              [-39.975685016081208, -8.193913902787706],
              [-39.975685053211194, -8.193913811388997],
              [-39.975685091339081, -8.193913720399037],
              [-39.975685130460306, -8.193913629828621],
              [-39.975685170570273, -8.193913539688523],
              [-39.975685211664178, -8.193913449989452],
              [-39.975685253737147, -8.193913360742053],
              [-39.975685296784178, -8.19391327195698],
              [-39.975685340800148, -8.193913183644762],
              [-39.975685385779826, -8.193913095815883],
              [-39.975685431717892, -8.193913008480791],
              [-39.975685478608845, -8.193912921649856],
              [-39.975685526447151, -8.193912835333421],
              [-39.975685575227089, -8.193912749541724],
              [-39.975685624942898, -8.193912664284969],
              [-39.975685675588622, -8.193912579573309],
              [-39.975685727158286, -8.193912495416791],
              [-39.975685779645744, -8.193912411825396],
              [-39.97568583304475, -8.193912328809116],
              [-39.975685887349009, -8.193912246377733],
              [-39.975685863699205, -8.193912229565539],
              [-39.976130220923686, -8.193245679770659],
              [-39.97613022116699, -8.193245715379836],
              [-39.976130290977878, -8.193245612205647],
              [-39.976130362206796, -8.193245509998096],
              [-39.976130434840314, -8.193245408776569],
              [-39.976130508864657, -8.193245308560225],
              [-39.976130584265796, -8.193245209368008],
              [-39.976130661029472, -8.193245111218712],
              [-39.976130739141169, -8.193245014130929],
              [-39.9761308185861, -8.193244918123023],
              [-39.976130899349208, -8.193244823213133],
              [-39.976130981415231, -8.193244729419263],
              [-39.976131064768609, -8.19324463675917],
              [-39.976131149393581, -8.193244545250364],
              [-39.976131235274138, -8.193244454910207],
              [-39.976131322393996, -8.193244365755765],
              [-39.976131410736684, -8.193244277803924],
              [-39.976131500285483, -8.193244191071321],
              [-39.976131591023446, -8.193244105574385],
              [-39.976131682933378, -8.19324402132931],
              [-39.976131775997899, -8.193243938352014],
              [-39.976131870199389, -8.193243856658205],
              [-39.976131965520011, -8.193243776263369],
              [-39.976132061941726, -8.193243697182698],
              [-39.976132159446301, -8.193243619431184],
              [-39.976132258015255, -8.193243543023533],
              [-39.97613235762995, -8.193243467974188],
              [-39.976132458271508, -8.193243394297388],
              [-39.976132559920899, -8.193243322007064],
              [-39.976132662558889, -8.193243251116908],
              [-39.976132766166025, -8.193243181640309],
              [-39.976132870722715, -8.193243113590448],
              [-39.976132976209172, -8.193243046980186],
              [-39.976133082605408, -8.193242981822168],
              [-39.976133189891307, -8.193242918128687],
              [-39.976133298046548, -8.193242855911826],
              [-39.976133407050661, -8.193242795183341],
              [-39.976133516882996, -8.193242735954735],
              [-39.976133627522827, -8.193242678237191],
              [-39.976133738949137, -8.193242622041682],
              [-39.976133851140879, -8.193242567378833],
              [-39.976133964076809, -8.193242514258962],
              [-39.976134077735544, -8.193242462692155],
              [-39.976134192095557, -8.193242412688148],
              [-39.97613430713524, -8.193242364256404],
              [-39.976134422832772, -8.193242317406126],
              [-39.976134539166296, -8.19324227214616],
              [-39.976134656113771, -8.193242228485087],
              [-39.976134773653058, -8.193242186431119],
              [-39.976134891761916, -8.193242145992295],
              [-39.976135010417998, -8.1932421071762],
              [-39.976135129598816, -8.193242069990214],
              [-39.976135249281853, -8.193242034441367],
              [-39.976135369444428, -8.193242000536399],
              [-39.97613549006379, -8.193241968281706],
              [-39.97613561111713, -8.193241937683439],
              [-39.976135732581511, -8.193241908747327],
              [-39.976135854433963, -8.193241881478899],
              [-39.976135976651413, -8.19324185588331],
              [-39.976136099210741, -8.19324183196537],
              [-39.976136222088726, -8.193241809729608],
              [-39.976136345262113, -8.19324178918027],
              [-39.976136468707608, -8.193241770321222],
              [-39.976136592401808, -8.193241753156055],
              [-39.976136716321342, -8.19324173768797],
              [-39.976136840442749, -8.193241723919954],
              [-39.976136964742501, -8.193241711854588],
              [-39.976137089197096, -8.193241701494129],
              [-39.976137213782977, -8.193241692840576],
              [-39.976137338476562, -8.193241685895549],
              [-39.976137463254233, -8.193241680660368],
              [-39.976137588092392, -8.193241677136026],
              [-39.9761377129674, -8.193241675323172],
              [-39.976137837855624, -8.193241675222177],
              [-39.976137962733418, -8.193241676833042],
              [-39.976138087577155, -8.193241680155452],
              [-39.976138212363189, -8.19324168518882],
              [-39.976138337067916, -8.193241691932146],
              [-39.976138461667752, -8.193241700384192],
              [-39.976138586139051, -8.193241710543333],
              [-39.976138710458301, -8.193241722407636],
              [-39.976138834601969, -8.193241735974903],
              [-39.976138958546542, -8.193241751242509],
              [-39.976139082268567, -8.193241768207617],
              [-39.976139205744616, -8.193241786866961],
              [-39.976139328951334, -8.193241807217067],
              [-39.976139451865372, -8.19324182925404],
              [-39.97613957446351, -8.193241852973731],
              [-39.976139696722512, -8.193241878371632],
              [-39.976139818619238, -8.193241905442957],
              [-39.976139940130608, -8.193241934182545],
              [-39.976140061233636, -8.19324196458501],
              [-39.9761401819054, -8.193241996644542],
              [-39.976140302123049, -8.193242030355117],
              [-39.976140421863853, -8.193242065710361],
              [-39.97614054110511, -8.193242102703509],
              [-39.976140659824267, -8.193242141327636],
              [-39.976140777998864, -8.193242181575375],
              [-39.976140895606527, -8.193242223439176],
              [-39.976141012624979, -8.193242266911053],
              [-39.976141129031816, -8.193242311983221],
              [-39.976141105785672, -8.193242307070609],
              [-39.97783334390261, -8.193910919769767],
              [-39.977833356714996, -8.193910907785037],
              [-39.97783338892939, -8.193910920442013],
              [-39.977833421192031, -8.193910932976463],
              [-39.97783345350242, -8.193910945388176],
              [-39.977833485860096, -8.193910957677014],
              [-39.977833518264589, -8.193910969842781],
              [-39.977833550715431, -8.193910981885292],
              [-39.977833583212146, -8.193910993804376],
              [-39.977833615754264, -8.193911005599883],
              [-39.977833648341296, -8.193911017271599],
              [-39.977833680972779, -8.193911028819391],
              [-39.977833713648245, -8.193911040243067],
              [-39.97783374636721, -8.19391105154247],
              [-39.977833779129206, -8.193911062717408],
              [-39.977833811933742, -8.193911073767781],
              [-39.977833844780356, -8.193911084693374],
              [-39.977833877668566, -8.193911095494057],
              [-39.977833910597887, -8.19391110616964],
              [-39.977833943567845, -8.19391111672001],
              [-39.977833976577955, -8.193911127144981],
              [-39.977834009627735, -8.193911137444399],
              [-39.977834042716729, -8.193911147618142],
              [-39.977834075844413, -8.193911157666058],
              [-39.977834109010345, -8.193911167588],
              [-39.977834142214007, -8.193911177383786],
              [-39.977834175454944, -8.19391118705332],
              [-39.977834208732673, -8.193911196596439],
              [-39.977834242046683, -8.19391120601302],
              [-39.977834275396511, -8.193911215302901],
              [-39.977834308781659, -8.19391122446598],
              [-39.977834342201668, -8.193911233502089],
              [-39.977834375656002, -8.193911242411128],
              [-39.977834409144215, -8.193911251192926],
              [-39.977834442665824, -8.193911259847377],
              [-39.977834476220309, -8.19391126837437],
              [-39.977834509807209, -8.193911276773765],
              [-39.977834543426006, -8.193911285045436],
              [-39.977834577076244, -8.193911293189286],
              [-39.977834610757419, -8.193911301205166],
              [-39.977834644469041, -8.193911309092975],
              [-39.977834678210613, -8.193911316852597],
              [-39.977834711981657, -8.193911324483917],
              [-39.977834745781671, -8.193911331986831],
              [-39.977834779610163, -8.193911339361225],
              [-39.977834813466643, -8.193911346606997],
              [-39.977834847350614, -8.193911353724031],
              [-39.977834881261607, -8.19391136071223],
              [-39.977834915199097, -8.193911367571491],
              [-39.977834949162606, -8.193911374301711],
              [-39.977834983151645, -8.193911380902803],
              [-39.977835017165688, -8.193911387374667],
              [-39.977835051204281, -8.193911393717197],
              [-39.977835085266911, -8.193911399930293],
              [-39.977835119353074, -8.193911406013902],
              [-39.97783515346228, -8.193911411967923],
              [-39.977835187594039, -8.193911417792243],
              [-39.977835221747867, -8.193911423486792],
              [-39.977835255923225, -8.193911429051491],
              [-39.97783529011965, -8.193911434486258],
              [-39.977835324336631, -8.193911439791023],
              [-39.977835358573685, -8.193911444965686],
              [-39.977835392830301, -8.193911450010198],
              [-39.97783542710598, -8.193911454924464],
              [-39.977835461400218, -8.193911459708442],
              [-39.977835495712526, -8.193911464362024],
              [-39.977835530042405, -8.193911468885158],
              [-39.977835564389345, -8.193911473277794],
              [-39.977835598752861, -8.193911477539848],
              [-39.97783563313245, -8.193911481671236],
              [-39.977835667527593, -8.193911485671967],
              [-39.977835701937828, -8.193911489541915],
              [-39.977835736362607, -8.193911493281052],
              [-39.977835770801455, -8.193911496889328],
              [-39.977835805253882, -8.193911500366694],
              [-39.977835839719361, -8.193911503713084],
              [-39.97783587419741, -8.193911506928464],
              [-39.977835908687517, -8.193911510012764],
              [-39.97783594318917, -8.193911512965947],
              [-39.977835977701893, -8.193911515787971],
              [-39.977836012225168, -8.193911518478819],
              [-39.977836046758483, -8.19391152103843],
              [-39.977836081301362, -8.193911523466776],
              [-39.977836115853279, -8.193911525763806],
              [-39.977836150413758, -8.19391152792949],
              [-39.977836184982245, -8.19391152996382],
              [-39.977836219558299, -8.193911531866751],
              [-39.977836254141366, -8.193911533638216],
              [-39.977836288730977, -8.193911535278241],
              [-39.977836323326599, -8.193911536786809],
              [-39.977836357927764, -8.193911538163862],
              [-39.977836392533945, -8.193911539409379],
              [-39.977836427144624, -8.19391154052339],
              [-39.977836461759345, -8.19391154150582],
              [-39.977836496377563, -8.193911542356695],
              [-39.977836530998793, -8.193911543075972],
              [-39.977836565622518, -8.193911543663662],
              [-39.977836600248253, -8.193911544119755],
              [-39.977836634875466, -8.19391154444425],
              [-39.977836669503688, -8.193911544637139],
              [-39.977836704132372, -8.193911544698423],
              [-39.979074696720886, -8.193908542471585],
              [-39.979074807563798, -8.19390854316552],
              [-39.979074918389919, -8.193908545207911],
              [-39.979075029182773, -8.19390854859841],
              [-39.979075139925804, -8.193908553336534],
              [-39.97907525060252, -8.193908559421565],
              [-39.979075361196401, -8.193908566852617],
              [-39.979075471690962, -8.193908575628567],
              [-39.979075582069719, -8.193908585748089],
              [-39.979075692316243, -8.193908597209711],
              [-39.979075802414073, -8.193908610011704],
              [-39.979075912346801, -8.193908624152149],
              [-39.979076022098042, -8.193908639628949],
              [-39.979076131651418, -8.193908656439785],
              [-39.979076240990594, -8.193908674582186],
              [-39.97907635009927, -8.193908694053412],
              [-39.979076458961195, -8.193908714850572],
              [-39.979076567560121, -8.193908736970556],
              [-39.979076675879853, -8.1939087604101],
              [-39.97907678390424, -8.193908785165654],
              [-39.97907689161719, -8.193908811233555],
              [-39.979076999002615, -8.193908838609945],
              [-39.979077106044542, -8.193908867290707],
              [-39.979077212726978, -8.193908897271562],
              [-39.979077319034019, -8.193908928548057],
              [-39.979077424949843, -8.193908961115522],
              [-39.979077530458632, -8.193908994969105],
              [-39.979077635544655, -8.193909030103725],
              [-39.979077740192245, -8.193909066514195],
              [-39.979077844385799, -8.193909104195052],
              [-39.979077948109797, -8.193909143140678],
              [-39.97907805134875, -8.193909183345291],
              [-39.979078154087283, -8.193909224802875],
              [-39.979078256310061, -8.193909267507262],
              [-39.979078358001864, -8.193909311452087],
              [-39.979078459147509, -8.193909356630749],
              [-39.979078559731924, -8.193909403036583],
              [-39.979078659740118, -8.193909450662627],
              [-39.979078759157161, -8.193909499501787],
              [-39.97907885796824, -8.193909549546794],
              [-39.979078956158631, -8.193909600790176],
              [-39.979079053713662, -8.193909653224312],
              [-39.979079150618844, -8.193909706841364],
              [-39.979079246859662, -8.193909761633329],
              [-39.979079342421812, -8.193909817592045],
              [-39.979079437291041, -8.193909874709153],
              [-39.979079531453174, -8.193909932976172],
              [-39.979079624894197, -8.193909992384384],
              [-39.979079717600172, -8.193910052924934],
              [-39.979079809557277, -8.19391011458884],
              [-39.979079900751799, -8.193910177366854],
              [-39.979079991170138, -8.193910241249634],
              [-39.979080080798816, -8.193910306227663],
              [-39.979080169624474, -8.193910372291235],
              [-39.979080257633868, -8.193910439430507],
              [-39.979080344813859, -8.193910507635456],
              [-39.979080431151459, -8.193910576895956],
              [-39.9790805166338, -8.193910647201628],
              [-39.979080601248135, -8.193910718542016],
              [-39.979080684981824, -8.193910790906468],
              [-39.979080767822431, -8.193910864284224],
              [-39.979080849757558, -8.193910938664315],
              [-39.979080930775012, -8.193911014035665],
              [-39.979081010862693, -8.193911090387022],
              [-39.979081090008684, -8.193911167707009],
              [-39.979081168201169, -8.193911245984117],
              [-39.979081245428503, -8.193911325206644],
              [-39.979081321679153, -8.193911405362794],
              [-39.979081396941758, -8.193911486440602],
              [-39.979081471205106, -8.193911568428014],
              [-39.979081544458097, -8.193911651312748],
              [-39.97908161668984, -8.193911735082493],
              [-39.979081687889547, -8.193911819724741],
              [-39.979081758046611, -8.193911905226871],
              [-39.979081827150559, -8.193911991576121],
              [-39.979081895191101, -8.193912078759658],
              [-39.979081962158077, -8.193912166764457],
              [-39.97908202804151, -8.193912255577402],
              [-39.979082092831582, -8.193912345185218],
              [-39.979082156518629, -8.193912435574578],
              [-39.979082219093158, -8.193912526732003],
              [-39.979082280545825, -8.193912618643882],
              [-39.979082340867485, -8.193912711296546],
              [-39.97908240004913, -8.193912804676144],
              [-39.97908245808194, -8.193912898768758],
              [-39.97908251495727, -8.193912993560401],
              [-39.979082570666627, -8.19391308903689],
              [-39.97908262520172, -8.193913185184007],
              [-39.979082678554406, -8.19391328198742],
              [-39.979082730716726, -8.193913379432679],
              [-39.979082781680916, -8.193913477505262],
              [-39.979082831439371, -8.193913576190536],
              [-39.979082879984666, -8.193913675473812],
              [-39.97908292730957, -8.193913775340274],
              [-39.979082973407017, -8.193913875775023],
              [-39.979083018270153, -8.193913976763113],
              [-39.979083061892268, -8.193914078289451],
              [-39.979083104266863, -8.193914180338924],
              [-39.979083145387627, -8.1939142828963],
              [-39.979083185248129, -8.193914385946789],
              [-39.97908315318665, -8.193914400700026],
              [-39.97976427410142, -8.195707845548469],
              [-39.979764308403063, -8.195707819157882],
              [-39.979764322334923, -8.195707855603398],
              [-39.979764336424388, -8.195707891988731],
              [-39.979764350671182, -8.195707928313182],
              [-39.97976436507507, -8.1957079645761],
              [-39.979764379635775, -8.195708000776781],
              [-39.979764394353012, -8.195708036914585],
              [-39.979764409226512, -8.195708072988817],
              [-39.979764424256018, -8.195708108998822],
              [-39.979764439441219, -8.195708144943934],
              [-39.979764454781858, -8.195708180823468],
              [-39.979764470277637, -8.19570821663674],
              [-39.979764485928271, -8.195708252383128],
              [-39.979764501733477, -8.195708288061944],
              [-39.979764517692949, -8.195708323672513],
              [-39.979764533806389, -8.195708359214191],
              [-39.979764550073511, -8.195708394686326],
              [-39.979764566494012, -8.195708430088228],
              [-39.979764583067556, -8.195708465419244],
              [-39.979764599793867, -8.195708500678741],
              [-39.979764616672632, -8.195708535866039],
              [-39.979764633703532, -8.195708570980514],
              [-39.979764650886246, -8.195708606021507],
              [-39.979764668220454, -8.195708640988329],
              [-39.979764685705852, -8.195708675880359],
              [-39.979764703342084, -8.195708710696941],
              [-39.979764721128824, -8.195708745437431],
              [-39.979764739065786, -8.195708780101175],
              [-39.97976475715258, -8.195708814687551],
              [-39.979764775388908, -8.19570884919589],
              [-39.979764793774414, -8.19570888362558],
              [-39.979764812308773, -8.195708917975935],
              [-39.979764830991613, -8.195708952246365],
              [-39.979764849822601, -8.195708986436198],
              [-39.979764868801404, -8.195709020544825],
              [-39.979764887927644, -8.195709054571575],
              [-39.979764907200959, -8.195709088515848],
              [-39.979764926621023, -8.195709122377012],
              [-39.979764946187451, -8.195709156154434],
              [-39.979764965899882, -8.195709189847454],
              [-39.97976498575796, -8.195709223455506],
              [-39.979765005761294, -8.19570925697791],
              [-39.979765025909543, -8.195709290414065],
              [-39.97976504620231, -8.195709323763348],
              [-39.979765066639224, -8.195709357025169],
              [-39.979765087219903, -8.195709390198855],
              [-39.979765107943983, -8.195709423283798],
              [-39.979765128811039, -8.195709456279399],
              [-39.979765149820707, -8.195709489185067],
              [-39.979765170972591, -8.195709522000163],
              [-39.979765192266328, -8.195709554724075],
              [-39.979765213701477, -8.195709587356205],
              [-39.979765235277661, -8.195709619895931],
              [-39.979765256994483, -8.195709652342675],
              [-39.979765278851524, -8.195709684695785],
              [-39.979765300848378, -8.195709716954727],
              [-39.979765322984655, -8.195709749118855],
              [-39.979765345259921, -8.195709781187581],
              [-39.979765367673785, -8.195709813160317],
              [-39.979765390225829, -8.195709845036445],
              [-39.979765412915604, -8.195709876815389],
              [-39.979765435742713, -8.195709908496562],
              [-39.979765458706723, -8.195709940079359],
              [-39.979765481807213, -8.195709971563202],
              [-39.979765505043737, -8.19571000294753],
              [-39.979765528415882, -8.19571003423173],
              [-39.979765551923229, -8.195710065415234],
              [-39.979765575565295, -8.195710096497436],
              [-39.979765599341675, -8.195710127477792],
              [-39.979765623251915, -8.195710158355721],
              [-39.979765647295572, -8.195710189130617],
              [-39.979765671472201, -8.195710219801956],
              [-39.979765695781346, -8.195710250369117],
              [-39.979765720222559, -8.195710280831562],
              [-39.979765744795394, -8.195710311188719],
              [-39.979765769499373, -8.19571034144003],
              [-39.979765794334057, -8.195710371584932],
              [-39.979765819298976, -8.195710401622863],
              [-39.979765844393668, -8.19571043155325],
              [-39.979765869617665, -8.195710461375546],
              [-39.979765894970498, -8.195710491089189],
              [-39.97976592045169, -8.19571052069363],
              [-39.979765946060787, -8.195710550188341],
              [-39.979765971797285, -8.195710579572756],
              [-39.979765997660721, -8.195710608846321],
              [-39.979766023650626, -8.195710638008492],
              [-39.979766049766489, -8.195710667058751],
              [-39.979766076007841, -8.19571069599653],
              [-39.979766102374192, -8.1957107248213],
              [-39.979766128865052, -8.195710753532534],
              [-39.979766155479915, -8.195710782129684],
              [-39.979766182218306, -8.195710810612217],
              [-39.979766209079742, -8.195710838979629],
              [-39.979766236063689, -8.195710867231368],
              [-39.979766263169644, -8.195710895366902],
              [-39.979766290397123, -8.195710923385738],
              [-39.979766317745622, -8.195710951287333],
              [-39.979766345214621, -8.195710979071183],
              [-39.979766372803617, -8.195711006736754],
              [-39.979766400512091, -8.195711034283537],
              [-39.979766369685599, -8.195711005938005],
              [-39.980665872481609, -8.196601394683851],
              [-39.980665897762854, -8.196601431567911],
              [-39.980666008303231, -8.196601542900805],
              [-39.98066611689223, -8.196601656123907],
              [-39.980666223497288, -8.196601771203309],
              [-39.980666328086457, -8.196601888104478],
              [-39.980666430628361, -8.196602006792389],
              [-39.980666531092268, -8.196602127231452],
              [-39.980666629448059, -8.196602249385556],
              [-39.980666725666239, -8.196602373218084],
              [-39.980666819717968, -8.196602498691885],
              [-39.980666911575021, -8.19660262576935],
              [-39.980667001209902, -8.196602754412387],
              [-39.980667088595709, -8.196602884582415],
              [-39.98066717370623, -8.19660301624042],
              [-39.980667256515979, -8.196603149346902],
              [-39.980667337000106, -8.196603283862],
              [-39.980667415134484, -8.196603419745358],
              [-39.980667490895698, -8.196603556956235],
              [-39.980667564261012, -8.196603695453476],
              [-39.980667635208455, -8.19660383519556],
              [-39.980667703716747, -8.196603976140628],
              [-39.980667769765326, -8.196604118246372],
              [-39.980667833334422, -8.196604261470227],
              [-39.980667894404959, -8.196604405769243],
              [-39.980667952958626, -8.196604551100142],
              [-39.980668008977865, -8.196604697419348],
              [-39.980668062445915, -8.196604844683016],
              [-39.980668113346681, -8.196604992846968],
              [-39.980668161664958, -8.196605141866797],
              [-39.980668207386223, -8.196605291697818],
              [-39.980668250496798, -8.196605442295112],
              [-39.980668290983736, -8.196605593613533],
              [-39.980668328834909, -8.196605745607693],
              [-39.980668364038969, -8.196605898232029],
              [-39.980668396585351, -8.19660605144079],
              [-39.980668426464305, -8.196606205188049],
              [-39.980668453666873, -8.19660635942768],
              [-39.980668478184896, -8.196606514113485],
              [-39.980668500011014, -8.196606669199056],
              [-39.980668519138717, -8.196606824637881],
              [-39.980668535562216, -8.196606980383399],
              [-39.98066854927665, -8.196607136388879],
              [-39.980668560277863, -8.196607292607561],
              [-39.980668568562557, -8.196607448992586],
              [-39.980668574128266, -8.196607605497103],
              [-39.980668576973322, -8.196607762074175],
              [-39.98066857709685, -8.196607918676847],
              [-39.980668574498836, -8.196608075258172],
              [-39.980668569180054, -8.196608231771199],
              [-39.980668561142082, -8.196608388169022],
              [-39.980668550387364, -8.196608544404738],
              [-39.98066853691909, -8.196608700431488],
              [-39.980668520741318, -8.19660885620252],
              [-39.980668501858872, -8.19660901167113],
              [-39.980668480277444, -8.196609166790685],
              [-39.980668456003507, -8.196609321514689],
              [-39.980668429044321, -8.196609475796741],
              [-39.980668399407968, -8.196609629590615],
              [-39.980668367103341, -8.196609782850151],
              [-39.980668332140127, -8.196609935529453],
              [-39.980668294528797, -8.196610087582709],
              [-39.980668254280644, -8.196610238964332],
              [-39.980668211407711, -8.196610389628971],
              [-39.980668165922886, -8.196610539531417],
              [-39.980668117839791, -8.196610688626738],
              [-39.980668067172829, -8.196610836870232],
              [-39.980668013937198, -8.196610984217426],
              [-39.980667958148864, -8.19661113062419],
              [-39.98066789982456, -8.196611276046623],
              [-39.980667838981752, -8.196611420441094],
              [-39.980667775638707, -8.196611563764305],
              [-39.980667709814384, -8.19661170597335],
              [-39.980667641528555, -8.196611847025526],
              [-39.980667570801671, -8.196611986878551],
              [-39.980667497654942, -8.196612125490494],
              [-39.980667422110301, -8.196612262819839],
              [-39.980667344190394, -8.196612398825385],
              [-39.980667263918598, -8.196612533466368],
              [-39.98066718131895, -8.196612666702386],
              [-39.980667096416234, -8.19661279849352],
              [-39.980667009235908, -8.196612928800254],
              [-39.980666919804115, -8.196613057583511],
              [-39.980666828147633, -8.196613184804677],
              [-39.980666734293983, -8.196613310425635],
              [-39.980666638271281, -8.196613434408645],
              [-39.980666540108345, -8.196613556716628],
              [-39.980666439834572, -8.196613677312889],
              [-39.980666337480031, -8.196613796161255],
              [-39.980666233075432, -8.196613913226086],
              [-39.980666126652061, -8.196614028472286],
              [-39.980666018241827, -8.196614141865341],
              [-39.980665907877231, -8.196614253371196],
              [-39.980665795591371, -8.196614362956446],
              [-39.980665681417896, -8.19661447058823],
              [-39.980665565391057, -8.196614576234303],
              [-39.980665447545633, -8.196614679862995],
              [-39.980665327916967, -8.196614781443172],
              [-39.980665206540905, -8.196614880944441],
              [-39.980665083453836, -8.19661497833695],
              [-39.98066495869233, -8.196615073591797],
              [-39.980664998173772, -8.196615053588326],
              [-39.979772851562899, -8.197284052545626],
              [-39.979772849950223, -8.197284058348149],
              [-39.979772776614645, -8.197284113924814],
              [-39.979772703849029, -8.19728417024027],
              [-39.979772631660879, -8.197284227288669],
              [-39.979772560057633, -8.197284285064146],
              [-39.979772489046674, -8.197284343560746],
              [-39.979772418635307, -8.197284402772439],
              [-39.979772348830807, -8.19728446269316],
              [-39.979772279640343, -8.197284523316693],
              [-39.979772211071051, -8.197284584636799],
              [-39.979772143129992, -8.197284646647168],
              [-39.979772075824172, -8.197284709341428],
              [-39.97977200916052, -8.197284772713097],
              [-39.979771943145899, -8.19728483675563],
              [-39.979771877787137, -8.197284901462464],
              [-39.979771813090949, -8.197284966826913],
              [-39.979771749063978, -8.197285032842268],
              [-39.979771685712855, -8.197285099501691],
              [-39.979771623044094, -8.197285166798332],
              [-39.979771561064148, -8.197285234725268],
              [-39.979771499779424, -8.197285303275484],
              [-39.979771439196206, -8.197285372441929],
              [-39.979771379320745, -8.197285442217474],
              [-39.979771320159223, -8.197285512594902],
              [-39.979771261717723, -8.197285583567018],
              [-39.979771204002269, -8.197285655126461],
              [-39.979771147018802, -8.197285727265887],
              [-39.979771090773198, -8.197285799977859],
              [-39.979771035271249, -8.197285873254859],
              [-39.979770980518673, -8.1972859470894],
              [-39.979770926521134, -8.197286021473815],
              [-39.979770873284153, -8.197286096400461],
              [-39.979770820813251, -8.197286171861627],
              [-39.979770769113806, -8.197286247849513],
              [-39.979770718191176, -8.197286324356297],
              [-39.979770668050563, -8.197286401374113],
              [-39.979770618697188, -8.197286478895009],
              [-39.97977057013609, -8.197286556911028],
              [-39.979770522372291, -8.197286635414098],
              [-39.979770475410724, -8.197286714396162],
              [-39.979770429256199, -8.197286793849065],
              [-39.979770383913483, -8.197286873764613],
              [-39.979770339387251, -8.197286954134572],
              [-39.979770295682087, -8.197287034950682],
              [-39.97977025280251, -8.197287116204615],
              [-39.979770210752918, -8.197287197887977],
              [-39.979770169537652, -8.197287279992356],
              [-39.979770129160954, -8.197287362509327],
              [-39.979770089626989, -8.197287445430348],
              [-39.979770050939806, -8.197287528746912],
              [-39.979770013103426, -8.197287612450415],
              [-39.97976997612173, -8.197287696532234],
              [-39.979769939998533, -8.197287780983725],
              [-39.979769904737566, -8.197287865796152],
              [-39.979769870342416, -8.197287950960787],
              [-39.979769836816693, -8.197288036468855],
              [-39.979769804163809, -8.197288122311571],
              [-39.979769772387144, -8.197288208480089],
              [-39.979769741489953, -8.197288294965485],
              [-39.979769711475441, -8.19728838175889],
              [-39.979769682346685, -8.197288468851347],
              [-39.97976965410669, -8.197288556233879],
              [-39.979769626758369, -8.197288643897531],
              [-39.97976960030455, -8.197288731833211],
              [-39.979769574747941, -8.197288820031867],
              [-39.979769550091184, -8.197288908484449],
              [-39.97976952633681, -8.197288997181824],
              [-39.979769503487262, -8.19728908611483],
              [-39.979769481544913, -8.197289175274355],
              [-39.979769460512031, -8.197289264651157],
              [-39.979769440390754, -8.197289354236069],
              [-39.979769421183171, -8.197289444019818],
              [-39.979769402891243, -8.197289533993203],
              [-39.979769385516896, -8.197289624146938],
              [-39.979769369061877, -8.197289714471719],
              [-39.9797693535279, -8.197289804958258],
              [-39.979769338916569, -8.197289895597217],
              [-39.97976932522937, -8.197289986379284],
              [-39.979769312467745, -8.197290077295079],
              [-39.979769300632981, -8.197290168335243],
              [-39.979769289726306, -8.1972902594904],
              [-39.979769279748837, -8.197290350751167],
              [-39.979769270701631, -8.197290442108118],
              [-39.979769262585584, -8.197290533551891],
              [-39.97976925540155, -8.197290625072981],
              [-39.979769249150266, -8.197290716662021],
              [-39.979769243832379, -8.197290808309591],
              [-39.979769239448437, -8.197290900006186],
              [-39.979769235998873, -8.197290991742404],
              [-39.97976923348407, -8.197291083508771],
              [-39.979769231904271, -8.197291175295838],
              [-39.979769231259652, -8.197291267094149],
              [-39.979769231550264, -8.197291358894278],
              [-39.979769232776071, -8.197291450686729],
              [-39.979769234936974, -8.197291542462064],
              [-39.979769238032738, -8.19729163421079],
              [-39.979769242063043, -8.197291725923501],
              [-39.97976924702747, -8.197291817590731],
              [-39.9797692529255, -8.197291909203049],
              [-39.979769259756544, -8.19729200075097],
              [-39.979769240002398, -8.197292020238473],
              [-39.979885555352134, -8.198750842594057],
              [-39.979885587163345, -8.198750804249187],
              [-39.97988558838891, -8.198750819414768],
              [-39.979885589639935, -8.1987508345783],
              [-39.979885590916439, -8.198750849739721],
              [-39.979885592218402, -8.198750864898965],
              [-39.979885593545823, -8.198750880056048],
              [-39.979885594898697, -8.198750895210893],
              [-39.979885596277022, -8.198750910363453],
              [-39.979885597680791, -8.198750925513682],
              [-39.979885599110006, -8.198750940661567],
              [-39.979885600564657, -8.19875095580705],
              [-39.979885602044739, -8.198750970950064],
              [-39.979885603550244, -8.198750986090609],
              [-39.979885605081193, -8.198751001228613],
              [-39.979885606637552, -8.198751016364064],
              [-39.979885608219334, -8.198751031496888],
              [-39.979885609826525, -8.198751046627061],
              [-39.97988561145911, -8.198751061754527],
              [-39.979885613117119, -8.198751076879251],
              [-39.979885614800509, -8.198751092001183],
              [-39.979885616509314, -8.198751107120275],
              [-39.979885618243493, -8.198751122236528],
              [-39.979885620003067, -8.198751137349852],
              [-39.979885621788014, -8.198751152460234],
              [-39.979885623598328, -8.198751167567609],
              [-39.979885625434022, -8.198751182671957],
              [-39.979885627295083, -8.198751197773209],
              [-39.979885629181489, -8.19875121287135],
              [-39.979885631093268, -8.198751227966316],
              [-39.979885633030385, -8.198751243058066],
              [-39.97988563499284, -8.198751258146574],
              [-39.97988563698064, -8.198751273231768],
              [-39.979885638993771, -8.198751288313652],
              [-39.979885641032233, -8.19875130339217],
              [-39.979885643096011, -8.198751318467238],
              [-39.979885645185092, -8.19875133353886],
              [-39.979885647299511, -8.198751348606985],
              [-39.979885649439204, -8.198751363671541],
              [-39.979885651604214, -8.19875137873254],
              [-39.97988565379449, -8.198751393789902],
              [-39.979885656010076, -8.198751408843576],
              [-39.979885658250936, -8.198751423893551],
              [-39.979885660517063, -8.198751438939761],
              [-39.979885662808464, -8.198751453982155],
              [-39.979885665125103, -8.198751469020745],
              [-39.979885667467009, -8.198751484055432],
              [-39.979885669834175, -8.1987514990862],
              [-39.979885672226565, -8.198751514113001],
              [-39.979885674644187, -8.198751529135796],
              [-39.979885677087054, -8.19875154415452],
              [-39.979885679555125, -8.198751559169175],
              [-39.979885682048412, -8.198751574179656],
              [-39.979885684566909, -8.198751589186003],
              [-39.979885687110603, -8.198751604188121],
              [-39.979885689679485, -8.198751619185956],
              [-39.979885692273548, -8.198751634179501],
              [-39.979885694892793, -8.198751649168669],
              [-39.979885697537206, -8.198751664153482],
              [-39.979885700206786, -8.198751679133871],
              [-39.979885702901534, -8.198751694109784],
              [-39.979885705621406, -8.198751709081156],
              [-39.979885708366432, -8.198751724048002],
              [-39.979885711136568, -8.198751739010238],
              [-39.97988571393185, -8.198751753967846],
              [-39.979885716752243, -8.198751768920765],
              [-39.979885719597746, -8.198751783868971],
              [-39.979885722468346, -8.198751798812401],
              [-39.979885725364049, -8.198751813751032],
              [-39.979885728284827, -8.198751828684804],
              [-39.979885731230695, -8.198751843613712],
              [-39.979885734201609, -8.198751858537678],
              [-39.979885737197598, -8.198751873456644],
              [-39.979885740218627, -8.198751888370611],
              [-39.979885743264695, -8.198751903279533],
              [-39.979885746335817, -8.198751918183341],
              [-39.979885749431944, -8.198751933082015],
              [-39.979885752553123, -8.198751947975493],
              [-39.979885755699279, -8.198751962863769],
              [-39.979885758870452, -8.198751977746783],
              [-39.979885762066601, -8.198751992624459],
              [-39.97988576528774, -8.198752007496804],
              [-39.97988576853384, -8.198752022363747],
              [-39.979885771804923, -8.198752037225274],
              [-39.979885775100954, -8.198752052081314],
              [-39.979885778421924, -8.198752066931856],
              [-39.979885781767834, -8.198752081776822],
              [-39.97988578513867, -8.198752096616207],
              [-39.979885788534418, -8.198752111449938],
              [-39.979885791955077, -8.198752126278],
              [-39.979885795400641, -8.198752141100302],
              [-39.979885798871088, -8.198752155916887],
              [-39.979885802366418, -8.19875217072765],
              [-39.979885805886617, -8.198752185532559],
              [-39.979885809431664, -8.198752200331576],
              [-39.979885813001566, -8.198752215124687],
              [-39.979885816596308, -8.198752229911793],
              [-39.979885820215877, -8.198752244692889],
              [-39.979885823860272, -8.198752259467952],
              [-39.979885827529472, -8.198752274236899],
              [-39.97988583122347, -8.198752288999723],
              [-39.97988583602676, -8.198752306619918],
              [-39.980227451033031, -8.200097510601118],
              [-39.980227489541427, -8.200097492772576],
              [-39.980227514375358, -8.200097588353517],
              [-39.980227540255086, -8.200097683658667],
              [-39.980227567177501, -8.200097778676671],
              [-39.980227595139404, -8.200097873396194],
              [-39.98022762413747, -8.200097967805972],
              [-39.980227654168246, -8.200098061894733],
              [-39.980227685228137, -8.200098155651261],
              [-39.980227717313447, -8.200098249064403],
              [-39.980227750420347, -8.200098342123006],
              [-39.980227784544915, -8.200098434816002],
              [-39.980227819683066, -8.200098527132313],
              [-39.98022785583062, -8.200098619060968],
              [-39.980227892983265, -8.200098710590991],
              [-39.980227931136547, -8.200098801711478],
              [-39.980227970285988, -8.200098892411594],
              [-39.980228010426856, -8.20009898268051],
              [-39.980228051554384, -8.200099072507461],
              [-39.98022809366369, -8.200099161881752],
              [-39.980228136749744, -8.200099250792716],
              [-39.980228180807408, -8.200099339229803],
              [-39.980228225831439, -8.200099427182449],
              [-39.980228271816465, -8.200099514640135],
              [-39.980228318757007, -8.200099601592468],
              [-39.980228366647495, -8.200099688029123],
              [-39.980228415482166, -8.200099773939753],
              [-39.980228465255252, -8.200099859314131],
              [-39.980228515960803, -8.200099944142098],
              [-39.980228567592768, -8.200100028413521],
              [-39.980228620144999, -8.200100112118362],
              [-39.980228673611244, -8.200100195246646],
              [-39.980228727985121, -8.20010027778847],
              [-39.980228783260173, -8.200100359734023],
              [-39.980228839429763, -8.2001004410735],
              [-39.980228896487247, -8.200100521797202],
              [-39.980228954425812, -8.200100601895556],
              [-39.980229013238521, -8.20010068135899],
              [-39.980229072918412, -8.200100760178044],
              [-39.980229133458344, -8.2001008383433],
              [-39.98022919485112, -8.200100915845454],
              [-39.980229257089405, -8.200100992675278],
              [-39.98022932016579, -8.200101068823631],
              [-39.980229384072764, -8.200101144281412],
              [-39.980229448802703, -8.200101219039611],
              [-39.980229514347904, -8.200101293089386],
              [-39.980229580700545, -8.200101366421862],
              [-39.980229647852724, -8.200101439028296],
              [-39.980229715796419, -8.200101510900051],
              [-39.980229784523573, -8.200101582028573],
              [-39.980229854025978, -8.200101652405337],
              [-39.98022992429533, -8.200101722021994],
              [-39.98022999532327, -8.200101790870255],
              [-39.980230067101346, -8.200101858941927],
              [-39.980230139620993, -8.200101926228838],
              [-39.980230212873565, -8.20010199272304],
              [-39.980230286850329, -8.200102058416572],
              [-39.980230361542496, -8.20010212330159],
              [-39.980230436941127, -8.200102187370394],
              [-39.98023051303727, -8.200102250615345],
              [-39.980230589821822, -8.200102313028886],
              [-39.98023066728566, -8.20010237460359],
              [-39.980230745419561, -8.200102435332118],
              [-39.980230824214182, -8.200102495207231],
              [-39.98023090366015, -8.200102554221797],
              [-39.980230983748001, -8.200102612368811],
              [-39.980231064468171, -8.200102669641288],
              [-39.980231145811075, -8.200102726032446],
              [-39.980231227766993, -8.200102781535538],
              [-39.980231310326154, -8.200102836143941],
              [-39.980231393478739, -8.200102889851209],
              [-39.980231477214836, -8.20010294265087],
              [-39.980231561524448, -8.200102994536664],
              [-39.980231646397549, -8.200103045502425],
              [-39.980231731824006, -8.200103095542035],
              [-39.980231817793651, -8.200103144649542],
              [-39.98023190429624, -8.200103192819148],
              [-39.980231991321453, -8.200103240045042],
              [-39.980232078858926, -8.200103286321641],
              [-39.980232166898226, -8.200103331643412],
              [-39.980232255428881, -8.200103376004938],
              [-39.980232344440289, -8.200103419400973],
              [-39.980232433921891, -8.200103461826323],
              [-39.980232523863009, -8.20010350327594],
              [-39.980232614252927, -8.200103543744865],
              [-39.980232705080859, -8.200103583228289],
              [-39.980232796335997, -8.200103621721501],
              [-39.980232888007457, -8.200103659219918],
              [-39.980232980084324, -8.200103695719083],
              [-39.980233072555606, -8.200103731214622],
              [-39.980233165410326, -8.200103765702323],
              [-39.980233258637355, -8.200103799178063],
              [-39.980233352225639, -8.200103831637881],
              [-39.980233446164, -8.200103863077878],
              [-39.98023354044124, -8.200103893494303],
              [-39.980233635046126, -8.200103922883551],
              [-39.980233729967395, -8.200103951242125],
              [-39.980233825193721, -8.20010397856662],
              [-39.980233920713765, -8.200104004853788],
              [-39.980234016516143, -8.200104030100515],
              [-39.980234112589429, -8.200104054303772],
              [-39.980234093894758, -8.200104096733922],
              [-39.981583311571676, -8.200436173826978],
              [-39.981583275362979, -8.200436144250968],
              [-39.981583375667753, -8.200436169545469],
              [-39.981583475676644, -8.200436195977364],
              [-39.981583575376661, -8.200436223543235],
              [-39.981583674754852, -8.200436252239482],
              [-39.981583773798299, -8.200436282062391],
              [-39.981583872494127, -8.200436313008076],
              [-39.981583970829533, -8.200436345072532],
              [-39.981584068791719, -8.20043637825156],
              [-39.981584166367988, -8.200436412540874],
              [-39.981584263545628, -8.200436447936029],
              [-39.981584360312048, -8.200436484432403],
              [-39.981584456654659, -8.200436522025282],
              [-39.981584552560946, -8.200436560709749],
              [-39.981584648018433, -8.20043660048081],
              [-39.98158474301475, -8.200436641333262],
              [-39.981584837537547, -8.200436683261838],
              [-39.981584931574531, -8.200436726261071],
              [-39.981585025113496, -8.200436770325361],
              [-39.981585118142277, -8.20043681544902],
              [-39.981585210648802, -8.200436861626171],
              [-39.981585302621049, -8.200436908850801],
              [-39.981585394047066, -8.200436957116789],
              [-39.981585484914987, -8.200437006417845],
              [-39.981585575212982, -8.200437056747582],
              [-39.981585664929348, -8.200437108099459],
              [-39.981585754052404, -8.200437160466796],
              [-39.981585842570595, -8.200437213842784],
              [-39.98158593047242, -8.200437268220513],
              [-39.98158601774643, -8.200437323592894],
              [-39.981586104381321, -8.200437379952753],
              [-39.981586190365832, -8.20043743729277],
              [-39.981586275688763, -8.200437495605472],
              [-39.981586360339051, -8.200437554883292],
              [-39.981586444305712, -8.200437615118556],
              [-39.981586527577818, -8.200437676303395],
              [-39.981586610144554, -8.200437738429894],
              [-39.98158669199519, -8.200437801489961],
              [-39.981586773119098, -8.200437865475401],
              [-39.981586853505732, -8.200437930377937],
              [-39.981586933144683, -8.200437996189086],
              [-39.981587012025557, -8.20043806290035],
              [-39.981587090138113, -8.20043813050302],
              [-39.981587167472235, -8.200438198988325],
              [-39.981587244017845, -8.200438268347364],
              [-39.981587319765026, -8.200438338571157],
              [-39.981587394703901, -8.200438409650559],
              [-39.981587468824777, -8.200438481576329],
              [-39.981587542117971, -8.200438554339128],
              [-39.981587614574011, -8.200438627929493],
              [-39.981587686183474, -8.200438702337891],
              [-39.981587756937046, -8.200438777554647],
              [-39.981587826825532, -8.200438853569942],
              [-39.981587895839866, -8.200438930373972],
              [-39.981587963971059, -8.200439007956694],
              [-39.981588031210272, -8.200439086308087],
              [-39.98158809754878, -8.200439165417947],
              [-39.981588162977957, -8.200439245275977],
              [-39.981588227489297, -8.200439325871832],
              [-39.981588291074416, -8.200439407195013],
              [-39.981588353725087, -8.200439489234983],
              [-39.981588415433116, -8.200439571981075],
              [-39.981588476190545, -8.200439655422533],
              [-39.981588535989424, -8.200439739548521],
              [-39.981588594822028, -8.200439824348116],
              [-39.981588652680685, -8.200439909810298],
              [-39.981588709557911, -8.200439995923947],
              [-39.981588765446276, -8.200440082677897],
              [-39.981588820338537, -8.200440170060851],
              [-39.981588874227569, -8.2004402580615],
              [-39.981588927106372, -8.200440346668387],
              [-39.981588978968077, -8.200440435869996],
              [-39.981589029805917, -8.200440525654727],
              [-39.981589079613336, -8.200440616010935],
              [-39.981589128383817, -8.20044070692688],
              [-39.981589176111044, -8.20044079839076],
              [-39.981589222788855, -8.200440890390645],
              [-39.981589268411113, -8.200440982914637],
              [-39.981589312971941, -8.200441075950692],
              [-39.981589356465527, -8.200441169486743],
              [-39.981589398886229, -8.200441263510609],
              [-39.981589440228532, -8.200441358010087],
              [-39.981589480487081, -8.200441452972898],
              [-39.98158951965663, -8.200441548386729],
              [-39.9815895577321, -8.200441644239138],
              [-39.981589594708517, -8.200441740517716],
              [-39.981589630581112, -8.200441837209931],
              [-39.981589665345204, -8.200441934303232],
              [-39.981589698996288, -8.200442031785002],
              [-39.981589731529986, -8.200442129642571],
              [-39.981589762942072, -8.200442227863224],
              [-39.981589793228466, -8.200442326434203],
              [-39.981589822385239, -8.200442425342713],
              [-39.981589850408589, -8.2004425245759],
              [-39.981589877294887, -8.200442624120877],
              [-39.98158990304065, -8.200442723964684],
              [-39.981589927642496, -8.200442824094379],
              [-39.98158995109727, -8.200442924496938],
              [-39.98158997340191, -8.200443025159322],
              [-39.981589994553588, -8.200443126068377],
              [-39.981589955346685, -8.200443121700703],
              [-39.981701502476852, -8.200990839375596],
              [-39.981701531886017, -8.200990822409052],
              [-39.981701561051167, -8.200990972085584],
              [-39.981701587679588, -8.2009911222307],
              [-39.981701611763739, -8.200991272801717],
              [-39.981701633296751, -8.200991423755863],
              [-39.98170165227252, -8.200991575050191],
              [-39.981701668685659, -8.200991726641746],
              [-39.981701682531465, -8.200991878487425],
              [-39.981701693806038, -8.200992030544077],
              [-39.981701702506157, -8.20099218276849],
              [-39.981701708629359, -8.200992335117402],
              [-39.981701712173923, -8.200992487547492],
              [-39.981701713138797, -8.200992640015464],
              [-39.981701711523741, -8.200992792477981],
              [-39.981701707329186, -8.200992944891713],
              [-39.981701700556357, -8.200993097213324],
              [-39.981701691207157, -8.200993249399509],
              [-39.981701679284249, -8.200993401407088],
              [-39.981701664791018, -8.200993553192788],
              [-39.98170164773159, -8.200993704713518],
              [-39.981701628110812, -8.200993855926169],
              [-39.981701605934248, -8.200994006787809],
              [-39.981701581208227, -8.200994157255527],
              [-39.98170155393975, -8.200994307286569],
              [-39.981701524136568, -8.200994456838316],
              [-39.981701491807172, -8.200994605868223],
              [-39.981701456960735, -8.200994754333966],
              [-39.981701419607177, -8.200994902193345],
              [-39.981701379757084, -8.200995049404344],
              [-39.981701337421811, -8.200995195925099],
              [-39.981701292613359, -8.200995341714016],
              [-39.981701245344503, -8.200995486729594],
              [-39.981701195628659, -8.200995630930688],
              [-39.981701143479924, -8.200995774276267],
              [-39.981701088913169, -8.200995916725628],
              [-39.981701031943885, -8.200996058238248],
              [-39.981700972588271, -8.200996198773927],
              [-39.981700910863175, -8.200996338292713],
              [-39.981700846786133, -8.200996476754968],
              [-39.981700780375384, -8.200996614121337],
              [-39.981700711649786, -8.200996750352795],
              [-39.981700640628894, -8.200996885410564],
              [-39.981700567332851, -8.200997019256327],
              [-39.98170049178254, -8.200997151852018],
              [-39.981700413999377, -8.200997283159955],
              [-39.981700334005524, -8.200997413142824],
              [-39.981700251823668, -8.200997541763655],
              [-39.9817001674772, -8.200997668985922],
              [-39.981700080990073, -8.200997794773466],
              [-39.981699992386872, -8.200997919090506],
              [-39.981699901692778, -8.200998041901753],
              [-39.981699808933577, -8.200998163172288],
              [-39.981699714135608, -8.200998282867634],
              [-39.981699617325845, -8.200998400953781],
              [-39.981699518531791, -8.200998517397162],
              [-39.981699417781499, -8.200998632164705],
              [-39.981699315103633, -8.200998745223778],
              [-39.981699210527353, -8.20099885654222],
              [-39.981699104082402, -8.200998966088434],
              [-39.981698995799, -8.200999073831271],
              [-39.981698885707985, -8.20099917974013],
              [-39.98169877384057, -8.200999283784896],
              [-39.981698660228595, -8.200999385935958],
              [-39.981698544904333, -8.200999486164353],
              [-39.981698427900568, -8.200999584441544],
              [-39.981698309250561, -8.200999680739617],
              [-39.981698188988013, -8.200999775031226],
              [-39.9816980671471, -8.200999867289541],
              [-39.981697943762477, -8.200999957488357],
              [-39.981697818869193, -8.201000045602017],
              [-39.981697692502742, -8.201000131605515],
              [-39.981697564699047, -8.201000215474373],
              [-39.981697435494418, -8.201000297184779],
              [-39.981697304925596, -8.201000376713486],
              [-39.98169717302968, -8.201000454037926],
              [-39.981697039844143, -8.201000529136113],
              [-39.981696905406864, -8.201000601986674],
              [-39.98169676975602, -8.201000672568931],
              [-39.981696632930181, -8.201000740862844],
              [-39.98169649496824, -8.201000806848949],
              [-39.981696355909385, -8.201000870508528],
              [-39.98169621579315, -8.201000931823483],
              [-39.981696074659382, -8.201000990776379],
              [-39.981695932548156, -8.201001047350479],
              [-39.981695789499867, -8.201001101529691],
              [-39.981695645555185, -8.201001153298623],
              [-39.981695500755002, -8.201001202642569],
              [-39.981695355140488, -8.201001249547485],
              [-39.981695208753024, -8.201001294000053],
              [-39.981695061634206, -8.201001335987613],
              [-39.981694913825855, -8.201001375498283],
              [-39.98169476536998, -8.201001412520814],
              [-39.981694616308751, -8.201001447044639],
              [-39.981694466684573, -8.201001479060015],
              [-39.981694316539922, -8.201001508557777],
              [-39.981694165917531, -8.201001535529622],
              [-39.981694014860139, -8.201001559967795],
              [-39.981693863410726, -8.20100158186545],
              [-39.981693711612316, -8.201001601216252],
              [-39.981693559507235, -8.201001618015596],
              [-39.981693540526322, -8.201001621385894],
              [-39.980578166016215, -8.201115308581079],
              [-39.980578162109808, -8.201115328089712],
              [-39.980578040956964, -8.201115341259905],
              [-39.980577919992513, -8.201115356049359],
              [-39.980577799238262, -8.201115372455478],
              [-39.980577678715939, -8.201115390475275],
              [-39.980577558447308, -8.201115410105485],
              [-39.980577438454013, -8.201115431342577],
              [-39.980577318757717, -8.201115454182736],
              [-39.980577199379965, -8.201115478621839],
              [-39.9805770803423, -8.201115504655476],
              [-39.980576961666138, -8.201115532278964],
              [-39.980576843372901, -8.201115561487306],
              [-39.980576725483907, -8.201115592275272],
              [-39.980576608020407, -8.20111562463728],
              [-39.980576491003553, -8.201115658567499],
              [-39.980576374454458, -8.201115694059851],
              [-39.980576258394116, -8.201115731107896],
              [-39.980576142843454, -8.201115769704966],
              [-39.980576027823282, -8.201115809844101],
              [-39.980575913354357, -8.201115851518109],
              [-39.980575799457299, -8.20111589471944],
              [-39.980575686152633, -8.201115939440285],
              [-39.98057557346079, -8.201115985672617],
              [-39.980575461402069, -8.201116033408107],
              [-39.98057534999667, -8.201116082638128],
              [-39.980575239264681, -8.201116133353819],
              [-39.980575129226068, -8.201116185546052],
              [-39.980575019900648, -8.201116239205394],
              [-39.980574911308132, -8.201116294322205],
              [-39.98057480346808, -8.201116350886496],
              [-39.980574696399955, -8.201116408888126],
              [-39.980574590123055, -8.201116468316638],
              [-39.980574484656493, -8.201116529161283],
              [-39.98057438001932, -8.201116591411115],
              [-39.980574276230392, -8.201116655054937],
              [-39.980574173308398, -8.20111672008125],
              [-39.980574071271896, -8.201116786478332],
              [-39.980573970139275, -8.201116854234247],
              [-39.98057386992879, -8.20111692333672],
              [-39.980573770658467, -8.201116993773375],
              [-39.980573672346225, -8.201117065531449],
              [-39.980573575009764, -8.201117138598047],
              [-39.980573478666628, -8.201117212959982],
              [-39.980573383334189, -8.201117288603843],
              [-39.980573289029635, -8.201117365516023],
              [-39.980573195769971, -8.201117443682651],
              [-39.980573103571984, -8.201117523089636],
              [-39.980573012452311, -8.201117603722649],
              [-39.980572922427363, -8.201117685567169],
              [-39.980572833513364, -8.20111776860846],
              [-39.980572745726349, -8.201117852831516],
              [-39.980572659082142, -8.201117938221193],
              [-39.980572573596341, -8.201118024762112],
              [-39.980572489284377, -8.2011181124386],
              [-39.980572406161457, -8.201118201234928],
              [-39.980572324242516, -8.20111829113506],
              [-39.980572243542369, -8.201118382122814],
              [-39.980572164075525, -8.201118474181778],
              [-39.980572085856338, -8.201118567295351],
              [-39.980572008898875, -8.201118661446746],
              [-39.980571933217036, -8.201118756618994],
              [-39.98057185882444, -8.201118852794984],
              [-39.980571785734504, -8.201118949957342],
              [-39.980571713960408, -8.201119048088552],
              [-39.980571643515084, -8.201119147170932],
              [-39.980571574411222, -8.201119247186643],
              [-39.980571506661285, -8.201119348117626],
              [-39.980571440277487, -8.201119449945709],
              [-39.980571375271779, -8.201119552652541],
              [-39.980571311655908, -8.201119656219603],
              [-39.980571249441304, -8.201119760628206],
              [-39.980571188639203, -8.201119865859576],
              [-39.980571129260554, -8.20111997189473],
              [-39.980571071316056, -8.201120078714528],
              [-39.980571014816178, -8.201120186299752],
              [-39.980570959771065, -8.201120294630968],
              [-39.980570906190671, -8.2011204036887],
              [-39.980570854084647, -8.201120513453278],
              [-39.980570803462371, -8.20112062390492],
              [-39.980570754332973, -8.201120735023691],
              [-39.980570706705308, -8.201120846789593],
              [-39.980570660587979, -8.201120959182456],
              [-39.980570615989279, -8.201121072182044],
              [-39.980570572917223, -8.201121185767969],
              [-39.980570531379627, -8.201121299919761],
              [-39.980570491383943, -8.201121414616852],
              [-39.9805704529374, -8.201121529838588],
              [-39.980570416046902, -8.201121645564156],
              [-39.980570380719122, -8.201121761772747],
              [-39.980570346960405, -8.201121878443384],
              [-39.980570314776863, -8.20112199555504],
              [-39.980570284174277, -8.201122113086637],
              [-39.980570255158177, -8.201122231016958],
              [-39.980570227733779, -8.201122349324754],
              [-39.980570201906019, -8.20112246798872],
              [-39.980570177679589, -8.201122586987429],
              [-39.980570155058814, -8.201122706299463],
              [-39.980570134047795, -8.201122825903314],
              [-39.980570114650298, -8.201122945777412],
              [-39.980570096869855, -8.201123065900163],
              [-39.980570105940259, -8.201123106218045],
              [-39.980349622967005, -8.202685398865894],
              [-39.980349590745561, -8.202685367124968],
              [-39.980349572246013, -8.202685505985237],
              [-39.980349555907296, -8.20268564511432],
              [-39.980349541733339, -8.202685784478817],
              [-39.980349529727512, -8.202685924045298],
              [-39.980349519892712, -8.2026860637803],
              [-39.980349512231292, -8.202686203650309],
              [-39.980349506745092, -8.20268634362175],
              [-39.980349503435441, -8.202686483661072],
              [-39.980349502303106, -8.202686623734687],
              [-39.980349503348378, -8.202686763808936],
              [-39.980349506571002, -8.20268690385026],
              [-39.980349511970211, -8.202687043825078],
              [-39.980349519544703, -8.202687183699789],
              [-39.980349529292674, -8.202687323440843],
              [-39.980349541211751, -8.202687463014691],
              [-39.980349555299107, -8.202687602387922],
              [-39.980349571551344, -8.202687741527065],
              [-39.98034958996459, -8.20268788039872],
              [-39.98034961053439, -8.202688018969587],
              [-39.980349633255841, -8.202688157206451],
              [-39.980349658123494, -8.202688295076102],
              [-39.980349685131344, -8.202688432545525],
              [-39.980349714272968, -8.202688569581699],
              [-39.980349745541318, -8.20268870615177],
              [-39.980349778928947, -8.202688842222999],
              [-39.980349814427804, -8.202688977762682],
              [-39.980349852029391, -8.202689112738366],
              [-39.980349891724707, -8.202689247117656],
              [-39.980349933504193, -8.202689380868318],
              [-39.980349977357854, -8.202689513958235],
              [-39.98035002327515, -8.202689646355523],
              [-39.980350071245063, -8.202689778028391],
              [-39.980350121256123, -8.202689908945274],
              [-39.980350173296308, -8.202690039074758],
              [-39.980350227353128, -8.202690168385612],
              [-39.980350283413621, -8.202690296846853],
              [-39.980350341464352, -8.202690424427637],
              [-39.980350401491378, -8.202690551097376],
              [-39.980350463480313, -8.202690676825684],
              [-39.980350527416263, -8.202690801582392],
              [-39.980350593283916, -8.202690925337553],
              [-39.980350661067469, -8.202691048061494],
              [-39.980350730750658, -8.202691169724801],
              [-39.980350802316757, -8.202691290298285],
              [-39.980350875748606, -8.202691409752983],
              [-39.980350951028591, -8.202691528060294],
              [-39.980351028138664, -8.202691645191772],
              [-39.980351107060308, -8.202691761119382],
              [-39.980351187774602, -8.202691875815283],
              [-39.98035127026219, -8.202691989251965],
              [-39.980351354503256, -8.202692101402224],
              [-39.980351440477627, -8.202692212239155],
              [-39.980351528164668, -8.20269232173616],
              [-39.980351617543327, -8.202692429866957],
              [-39.980351708592188, -8.202692536605658],
              [-39.980351801289402, -8.202692641926607],
              [-39.980351895612706, -8.202692745804571],
              [-39.980351991539528, -8.202692848214623],
              [-39.980352089046804, -8.202692949132192],
              [-39.980352188111176, -8.202693048533076],
              [-39.980352288708872, -8.202693146393425],
              [-39.980352390815753, -8.202693242689771],
              [-39.980352494407327, -8.202693337399014],
              [-39.980352599458747, -8.202693430498432],
              [-39.980352705944817, -8.202693521965697],
              [-39.980352813839986, -8.202693611778836],
              [-39.980352923118367, -8.202693699916326],
              [-39.980353033753772, -8.202693786357047],
              [-39.980353145719626, -8.202693871080255],
              [-39.980353258989084, -8.202693954065579],
              [-39.980353373534996, -8.202694035293177],
              [-39.980353489329858, -8.202694114743519],
              [-39.980353606345894, -8.202694192397573],
              [-39.980353724555023, -8.202694268236691],
              [-39.980353843928945, -8.202694342242696],
              [-39.980353964438955, -8.202694414397795],
              [-39.980354086056195, -8.20269448468474],
              [-39.98035420875145, -8.202694553086621],
              [-39.980354332495317, -8.202694619587076],
              [-39.980354457258109, -8.20269468417014],
              [-39.980354583009884, -8.202694746820285],
              [-39.98035470972048, -8.202694807522507],
              [-39.980354837359506, -8.202694866262258],
              [-39.980354965896339, -8.202694923025424],
              [-39.980355095300155, -8.202694977798394],
              [-39.980355225539896, -8.202695030568025],
              [-39.98035535658434, -8.202695081321661],
              [-39.98035548840204, -8.202695130047152],
              [-39.980355620961362, -8.202695176732778],
              [-39.98035575423053, -8.202695221367366],
              [-39.980355888177563, -8.20269526394018],
              [-39.98035602277033, -8.202695304441018],
              [-39.980356157976559, -8.202695342860167],
              [-39.980356293763784, -8.202695379188418],
              [-39.980356430099462, -8.202695413417068],
              [-39.980356566950874, -8.202695445537856],
              [-39.980356704285207, -8.202695475543132],
              [-39.98035684206949, -8.202695503425664],
              [-39.980356980270685, -8.202695529178776],
              [-39.980357000739069, -8.20269551044994],
              [-39.981594569993256, -8.202916252341227],
              [-39.981595296432651, -8.202916341014152],
              [-39.982722933822821, -8.203025831822522],
              [-39.982722950491727, -8.203025788947187],
              [-39.982723042349264, -8.203025798329755],
              [-39.982723134106259, -8.203025808642741],
              [-39.982723225753197, -8.203025819885074],
              [-39.982723317280637, -8.203025832055614],
              [-39.982723408679085, -8.20302584515307],
              [-39.98272349993907, -8.203025859176121],
              [-39.982723591051169, -8.203025874123323],
              [-39.982723682005954, -8.203025889993079],
              [-39.982723772793996, -8.203025906783811],
              [-39.982723863405937, -8.203025924493712],
              [-39.982723953832348, -8.203025943120998],
              [-39.982724044063922, -8.203025962663737],
              [-39.982724134091306, -8.203025983119865],
              [-39.982724223905166, -8.203026004487329],
              [-39.982724313496256, -8.203026026763863],
              [-39.98272440285524, -8.203026049947184],
              [-39.982724491972938, -8.203026074034922],
              [-39.98272458084007, -8.20302609902455],
              [-39.982724669447492, -8.203026124913487],
              [-39.982724757786016, -8.203026151699051],
              [-39.982724845846491, -8.203026179378494],
              [-39.982724933619799, -8.203026207948916],
              [-39.98272502109689, -8.2030262374074],
              [-39.982725108268696, -8.203026267750857],
              [-39.982725195126193, -8.203026298976186],
              [-39.982725281660393, -8.203026331080137],
              [-39.982725367862351, -8.203026364059362],
              [-39.982725453723148, -8.203026397910509],
              [-39.982725539233883, -8.203026432630036],
              [-39.982725624385722, -8.203026468214357],
              [-39.98272570916987, -8.203026504659807],
              [-39.982725793577522, -8.203026541962558],
              [-39.982725877599961, -8.203026580118815],
              [-39.982725961228503, -8.2030266191246],
              [-39.982726044454466, -8.203026658975885],
              [-39.982726127269274, -8.203026699668555],
              [-39.982726209664321, -8.203026741198386],
              [-39.982726291631124, -8.203026783561054],
              [-39.982726373161157, -8.203026826752236],
              [-39.98272645424602, -8.203026870767433],
              [-39.982726534877315, -8.203026915602067],
              [-39.982726615046687, -8.203026961251531],
              [-39.982726694745836, -8.203027007711091],
              [-39.98272677396654, -8.203027054975946],
              [-39.98272685270058, -8.203027103041205],
              [-39.982726930939819, -8.203027151901891],
              [-39.982727008676171, -8.203027201552928],
              [-39.982727085901587, -8.203027251989214],
              [-39.98272716260805, -8.203027303205522],
              [-39.982727238787653, -8.203027355196539],
              [-39.982727314432509, -8.203027407956888],
              [-39.982727389534801, -8.203027461481133],
              [-39.982727464086722, -8.203027515763702],
              [-39.982727538080603, -8.20302757079898],
              [-39.982727611508743, -8.203027626581333],
              [-39.982727684363574, -8.203027683104882],
              [-39.982727756637551, -8.203027740363877],
              [-39.982727828323206, -8.203027798352332],
              [-39.982727899413113, -8.203027857064264],
              [-39.982727969899912, -8.203027916493639],
              [-39.982728039776305, -8.203027976634266],
              [-39.982728109035072, -8.203028037479905],
              [-39.982728177669038, -8.203028099024294],
              [-39.98272824567114, -8.203028161261066],
              [-39.982728313034272, -8.203028224183772],
              [-39.982728379751514, -8.203028287785884],
              [-39.982728445815951, -8.203028352060841],
              [-39.982728511220749, -8.203028417001983],
              [-39.982728575959136, -8.20302848260261],
              [-39.982728640024419, -8.203028548855929],
              [-39.982728703409961, -8.203028615755045],
              [-39.982728766109211, -8.203028683293107],
              [-39.982728828115675, -8.203028751463076],
              [-39.982728889422951, -8.203028820257899],
              [-39.982728950024672, -8.203028889670472],
              [-39.982729009914571, -8.203028959693597],
              [-39.982729069086488, -8.203029030320069],
              [-39.982729127534263, -8.203029101542528],
              [-39.982729185251863, -8.203029173353643],
              [-39.982729242233312, -8.203029245745968],
              [-39.982729298472705, -8.203029318712032],
              [-39.982729353964238, -8.203029392244243],
              [-39.982729408702163, -8.203029466335041],
              [-39.982729462680815, -8.203029540976704],
              [-39.982729515894611, -8.203029616161533],
              [-39.982729568338051, -8.203029691881785],
              [-39.982729620005692, -8.203029768129566],
              [-39.982729670892219, -8.203029844897031],
              [-39.982729720992317, -8.203029922176242],
              [-39.982729770300843, -8.203029999959199],
              [-39.982729818812679, -8.203030078237836],
              [-39.982729866522824, -8.20303015700402],
              [-39.982729913426326, -8.203030236249672],
              [-39.982729959518323, -8.203030315966537],
              [-39.982730004794057, -8.203030396146417],
              [-39.98273004924885, -8.203030476780937],
              [-39.982730092878079, -8.203030557861837],
              [-39.982730135677251, -8.203030639380671],
              [-39.982730177642118, -8.203030721328718],
              [-39.982730207994209, -8.20303069796252],
              [-39.983067178653314, -8.203697158367595],
              [-39.983067182908421, -8.203697173128761],
              [-39.98306722384045, -8.203697253084732],
              [-39.983067265566895, -8.203697332632037],
              [-39.983067308083641, -8.203697411762914],
              [-39.983067351386488, -8.203697490469487],
              [-39.983067395471195, -8.203697568744044],
              [-39.9830674403334, -8.203697646578858],
              [-39.983067485968697, -8.203697723966286],
              [-39.983067532372594, -8.203697800898718],
              [-39.983067579540503, -8.203697877368525],
              [-39.98306762746779, -8.203697953368209],
              [-39.983067676149744, -8.203698028890283],
              [-39.983067725581563, -8.203698103927298],
              [-39.983067775758379, -8.203698178471891],
              [-39.983067826675224, -8.203698252516686],
              [-39.983067878327127, -8.203698326054393],
              [-39.983067930708984, -8.203698399077791],
              [-39.983067983815623, -8.20369847157969],
              [-39.983068037641829, -8.203698543552933],
              [-39.9830680921823, -8.203698614990417],
              [-39.983068147431652, -8.203698685885154],
              [-39.983068203384441, -8.203698756230125],
              [-39.983068260035196, -8.203698826018428],
              [-39.983068317378283, -8.203698895243159],
              [-39.983068375408095, -8.20369896389751],
              [-39.983068434118906, -8.203699031974736],
              [-39.983068493504916, -8.203699099468135],
              [-39.983068553560301, -8.203699166371022],
              [-39.983068614279119, -8.203699232676852],
              [-39.983068675655431, -8.20369929837905],
              [-39.983068737683155, -8.203699363471173],
              [-39.983068800356193, -8.203699427946811],
              [-39.983068863668372, -8.2036994917996],
              [-39.983068927613445, -8.203699555023267],
              [-39.98306899218516, -8.203699617611587],
              [-39.983069057377108, -8.203699679558373],
              [-39.983069123182887, -8.203699740857552],
              [-39.983069189596009, -8.203699801503079],
              [-39.983069256609951, -8.203699861488952],
              [-39.983069324218093, -8.203699920809299],
              [-39.983069392413782, -8.203699979458248],
              [-39.983069461190311, -8.203700037430064],
              [-39.983069530540902, -8.203700094719007],
              [-39.983069600458698, -8.203700151319426],
              [-39.983069670936864, -8.203700207225753],
              [-39.983069741968414, -8.203700262432507],
              [-39.983069813546379, -8.203700316934254],
              [-39.983069885663689, -8.203700370725613],
              [-39.983069958313258, -8.20370042380125],
              [-39.983070031487948, -8.203700476155973],
              [-39.983070105180509, -8.203700527784612],
              [-39.983070179383709, -8.203700578682099],
              [-39.983070254090222, -8.203700628843427],
              [-39.983070329292723, -8.203700678263637],
              [-39.983070404983771, -8.203700726937869],
              [-39.983070481155934, -8.203700774861307],
              [-39.983070557801696, -8.203700822029276],
              [-39.983070634913517, -8.203700868437108],
              [-39.983070712483794, -8.203700914080203],
              [-39.983070790504904, -8.2037009589541],
              [-39.983070868969136, -8.203701003054375],
              [-39.983070947868796, -8.203701046376676],
              [-39.98307102719609, -8.203701088916743],
              [-39.983071106943214, -8.203701130670412],
              [-39.983071187102311, -8.203701171633512],
              [-39.983071267665487, -8.203701211802057],
              [-39.983071348624797, -8.203701251172076],
              [-39.983071429972284, -8.203701289739678],
              [-39.983071511699919, -8.203701327501072],
              [-39.983071593799679, -8.203701364452561],
              [-39.983071676263464, -8.203701400590491],
              [-39.983071759083138, -8.203701435911272],
              [-39.98307184225056, -8.203701470411492],
              [-39.983071925757542, -8.203701504087684],
              [-39.983072009595851, -8.203701536936581],
              [-39.983072093757237, -8.203701568954916],
              [-39.983072178233392, -8.203701600139548],
              [-39.983072263016034, -8.203701630487425],
              [-39.983072348096776, -8.203701659995522],
              [-39.98307243346725, -8.203701688660946],
              [-39.983072519119055, -8.20370171648087],
              [-39.983072605043759, -8.203701743452598],
              [-39.98307269123287, -8.20370176957341],
              [-39.983072777677947, -8.203701794840761],
              [-39.983072864370435, -8.203701819252169],
              [-39.983072951301828, -8.203701842805206],
              [-39.983073038463523, -8.203701865497571],
              [-39.983073125846964, -8.203701887327041],
              [-39.983073213443546, -8.203701908291468],
              [-39.983073301244644, -8.20370192838875],
              [-39.983073389241603, -8.203701947616951],
              [-39.983073477425755, -8.203701965974139],
              [-39.983073565788409, -8.203701983458517],
              [-39.983073654320876, -8.203702000068372],
              [-39.98307374301443, -8.203702015802074],
              [-39.983073831860338, -8.203702030658057],
              [-39.983073920849847, -8.203702044634865],
              [-39.983074009974189, -8.203702057731116],
              [-39.983074099224602, -8.203702069945551],
              [-39.983074188592283, -8.203702081276937],
              [-39.98307418073162, -8.203702115605191],
              [-39.983974331194617, -8.203811705261705],
              [-39.983974332797018, -8.203811694597738],
              [-39.983974363644265, -8.203811698407478],
              [-39.98397439447826, -8.203811702322422],
              [-39.983974425298605, -8.203811706342535],
              [-39.983974456104953, -8.203811710467802],
              [-39.983974486896948, -8.203811714698102],
              [-39.98397451767422, -8.203811719033446],
              [-39.983974548436414, -8.203811723473743],
              [-39.983974579183162, -8.203811728018971],
              [-39.983974609914114, -8.203811732669063],
              [-39.983974640628901, -8.203811737423958],
              [-39.983974671327175, -8.203811742283605],
              [-39.98397470200856, -8.203811747247945],
              [-39.983974732672714, -8.203811752316939],
              [-39.983974763319246, -8.203811757490492],
              [-39.983974793947844, -8.203811762768597],
              [-39.983974824558103, -8.20381176815113],
              [-39.98397485514969, -8.203811773638082],
              [-39.98397488572224, -8.203811779229362],
              [-39.983974916275386, -8.203811784924881],
              [-39.983974946808793, -8.203811790724625],
              [-39.983974977322077, -8.203811796628493],
              [-39.983975007814898, -8.203811802636404],
              [-39.983975038286886, -8.203811808748322],
              [-39.983975068737692, -8.203811814964142],
              [-39.983975099166962, -8.203811821283793],
              [-39.983975129574326, -8.203811827707232],
              [-39.983975159959442, -8.203811834234383],
              [-39.983975190321949, -8.203811840865139],
              [-39.983975220661485, -8.203811847599441],
              [-39.983975250977693, -8.203811854437195],
              [-39.983975281270226, -8.203811861378311],
              [-39.983975311538742, -8.203811868422751],
              [-39.983975341782852, -8.203811875570398],
              [-39.98397537200222, -8.203811882821192],
              [-39.983975402196499, -8.203811890175039],
              [-39.983975432365312, -8.203811897631828],
              [-39.983975462508326, -8.203811905191511],
              [-39.983975492625206, -8.203811912853999],
              [-39.983975522715554, -8.203811920619165],
              [-39.983975552779036, -8.203811928486935],
              [-39.983975582815312, -8.203811936457216],
              [-39.983975612824011, -8.203811944529933],
              [-39.983975642804786, -8.20381195270496],
              [-39.983975672757296, -8.203811960982227],
              [-39.983975702681178, -8.203811969361634],
              [-39.983975732576106, -8.203811977843067],
              [-39.983975762441695, -8.203811986426421],
              [-39.983975792277604, -8.203811995111634],
              [-39.983975822083494, -8.203812003898568],
              [-39.983975851859007, -8.203812012787122],
              [-39.983975881603797, -8.203812021777235],
              [-39.983975911317522, -8.20381203086875],
              [-39.983975940999827, -8.203812040061573],
              [-39.983975970650349, -8.2038120493556],
              [-39.983976000268754, -8.20381205875073],
              [-39.983976029854716, -8.203812068246849],
              [-39.983976059407858, -8.203812077843835],
              [-39.983976088927847, -8.203812087541579],
              [-39.983976118414319, -8.203812097339991],
              [-39.983976147866954, -8.203812107238919],
              [-39.983976177285406, -8.203812117238288],
              [-39.983976206669297, -8.203812127337939],
              [-39.983976236018322, -8.203812137537788],
              [-39.983976265332124, -8.203812147837677],
              [-39.983976294610343, -8.203812158237509],
              [-39.983976323852652, -8.20381216873718],
              [-39.983976353058701, -8.203812179336552],
              [-39.983976382228157, -8.203812190035471],
              [-39.983976411360665, -8.203812200833854],
              [-39.983976440455884, -8.203812211731568],
              [-39.983976469513493, -8.203812222728466],
              [-39.983976498533131, -8.203812233824422],
              [-39.983976527514471, -8.203812245019312],
              [-39.983976556457151, -8.203812256312997],
              [-39.983976585360864, -8.203812267705359],
              [-39.983976614225234, -8.203812279196251],
              [-39.983976643049957, -8.203812290785551],
              [-39.983976671834668, -8.203812302473111],
              [-39.983976700579063, -8.203812314258816],
              [-39.983976729282773, -8.203812326142513],
              [-39.98397675794547, -8.203812338124049],
              [-39.983976786566821, -8.203812350203309],
              [-39.983976815146498, -8.203812362380143],
              [-39.983976843684133, -8.203812374654397],
              [-39.983976872179447, -8.203812387025927],
              [-39.983976900632065, -8.203812399494609],
              [-39.983976929041674, -8.203812412060262],
              [-39.983976957407904, -8.203812424722788],
              [-39.983976985730472, -8.203812437481979],
              [-39.983977014008993, -8.203812450337718],
              [-39.98397704224319, -8.203812463289859],
              [-39.983977070432708, -8.203812476338255],
              [-39.983977098577206, -8.203812489482727],
              [-39.983977126676372, -8.203812502723153],
              [-39.983977154729878, -8.203812516059337],
              [-39.983977182737362, -8.20381252949117],
              [-39.983977210698534, -8.203812543018449],
              [-39.983977238613051, -8.203812556641054],
              [-39.983977266480601, -8.203812570358533],
              [-39.983977238293704, -8.203812602584827],
              [-39.984654902574995, -8.204147572918357],
              [-39.984654908306709, -8.204147576813989],
              [-39.984654937290877, -8.204147591078863],
              [-39.984654966325849, -8.204147605240788],
              [-39.984654995411248, -8.204147619299626],
              [-39.984655024546726, -8.204147633255172],
              [-39.984655053731892, -8.204147647107266],
              [-39.984655082966391, -8.204147660855698],
              [-39.984655112249854, -8.20414767450035],
              [-39.984655141581889, -8.204147688041003],
              [-39.984655170962142, -8.204147701477478],
              [-39.984655200390243, -8.204147714809647],
              [-39.984655229865808, -8.20414772803732],
              [-39.984655259388468, -8.204147741160334],
              [-39.984655288957846, -8.2041477541785],
              [-39.984655318573559, -8.204147767091671],
              [-39.98465534823525, -8.204147779899708],
              [-39.984655377942545, -8.204147792602413],
              [-39.984655407695051, -8.204147805199634],
              [-39.984655437492385, -8.204147817691222],
              [-39.984655467334186, -8.204147830076996],
              [-39.984655497220068, -8.204147842356816],
              [-39.98465552714967, -8.204147854530515],
              [-39.984655557122586, -8.204147866597946],
              [-39.984655587138441, -8.204147878558958],
              [-39.984655617196857, -8.204147890413397],
              [-39.984655647297465, -8.204147902161113],
              [-39.984655677439868, -8.204147913801958],
              [-39.984655707623681, -8.2041479253358],
              [-39.984655737848541, -8.204147936762444],
              [-39.984655768114045, -8.2041479480818],
              [-39.984655798419823, -8.204147959293696],
              [-39.984655828765483, -8.204147970398001],
              [-39.984655859150635, -8.204147981394566],
              [-39.984655889574917, -8.204147992283268],
              [-39.984655920037909, -8.204148003063942],
              [-39.984655950539249, -8.204148013736472],
              [-39.984655981078554, -8.204148024300691],
              [-39.984656011655417, -8.204148034756516],
              [-39.984656042269457, -8.204148045103791],
              [-39.984656072920302, -8.204148055342355],
              [-39.984656103607534, -8.204148065472104],
              [-39.984656134330784, -8.204148075492926],
              [-39.984656165089653, -8.204148085404681],
              [-39.984656195883758, -8.204148095207222],
              [-39.984656226712723, -8.204148104900444],
              [-39.984656257576127, -8.204148114484219],
              [-39.984656288473587, -8.204148123958463],
              [-39.984656319404728, -8.20414813332299],
              [-39.984656350369129, -8.204148142577733],
              [-39.984656381366428, -8.204148151722562],
              [-39.984656412396205, -8.204148160757319],
              [-39.984656443458093, -8.204148169681948],
              [-39.984656474551663, -8.20414817849632],
              [-39.984656505676568, -8.20414818720031],
              [-39.984656536832382, -8.204148195793801],
              [-39.9846565680187, -8.204148204276722],
              [-39.984656599235144, -8.204148212648906],
              [-39.984656630481339, -8.204148220910287],
              [-39.984656661756844, -8.204148229060754],
              [-39.984656693061297, -8.204148237100181],
              [-39.984656724394277, -8.204148245028541],
              [-39.984656755755395, -8.204148252845648],
              [-39.984656787144274, -8.204148260551435],
              [-39.984656818560481, -8.204148268145802],
              [-39.984656850003631, -8.204148275628667],
              [-39.984656881473342, -8.204148282999931],
              [-39.984656912969193, -8.204148290259489],
              [-39.984656944490787, -8.204148297407245],
              [-39.984656976037748, -8.2041483044431],
              [-39.984657007609648, -8.204148311367014],
              [-39.984657039206091, -8.204148318178849],
              [-39.984657070826692, -8.204148324878542],
              [-39.984657102471033, -8.204148331465982],
              [-39.984657134138736, -8.20414833794114],
              [-39.984657165829354, -8.204148344303874],
              [-39.984657197542546, -8.204148350554144],
              [-39.98465722927785, -8.204148356691841],
              [-39.984657261034904, -8.204148362716907],
              [-39.984657292813296, -8.204148368629284],
              [-39.984657324612613, -8.204148374428845],
              [-39.984657356432457, -8.20414838011555],
              [-39.984657388272431, -8.204148385689342],
              [-39.98465742013213, -8.204148391150111],
              [-39.984657452011142, -8.204148396497802],
              [-39.984657483909075, -8.204148401732338],
              [-39.984657515825496, -8.204148406853689],
              [-39.984657547760037, -8.204148411861761],
              [-39.98465757971227, -8.20414841675651],
              [-39.984657611681804, -8.204148421537846],
              [-39.984657643668214, -8.204148426205752],
              [-39.984657675671116, -8.204148430760103],
              [-39.98465770769009, -8.204148435200894],
              [-39.984657739724724, -8.204148439528057],
              [-39.984657771774636, -8.204148443741532],
              [-39.984657803839397, -8.204148447841263],
              [-39.984657835918618, -8.204148451827214],
              [-39.98465786801188, -8.204148455699283],
              [-39.984657900118776, -8.204148459457473],
              [-39.98465793223891, -8.204148463101752],
              [-39.984657964371848, -8.204148466632036],
              [-39.984657991232318, -8.204148469792528],
              [-39.985660718716218, -8.204256809991829],
              [-39.985660748341076, -8.204256835705021],
              [-39.985660937849623, -8.204256854183289],
              [-39.985661127705519, -8.204256868697307],
              [-39.985661317825198, -8.204256879240722],
              [-39.985661508124998, -8.204256885808825],
              [-39.98566169852122, -8.204256888398813],
              [-39.985661888930082, -8.204256887009468],
              [-39.985662079267804, -8.204256881641442],
              [-39.98566226945065, -8.204256872297092],
              [-39.985662459394916, -8.204256858980512],
              [-39.985662649017065, -8.204256841697585],
              [-39.985662838233651, -8.204256820455898],
              [-39.985663026961404, -8.204256795264813],
              [-39.985663215117313, -8.204256766135403],
              [-39.985663402618563, -8.204256733080483],
              [-39.985663589382689, -8.204256696114596],
              [-39.985663775327488, -8.20425665525403],
              [-39.985663960371163, -8.204256610516719],
              [-39.985664144432285, -8.204256561922392],
              [-39.985664327429895, -8.204256509492406],
              [-39.985664509283453, -8.204256453249835],
              [-39.985664689912952, -8.204256393219401],
              [-39.985664869238917, -8.204256329427562],
              [-39.98566504718243, -8.204256261902355],
              [-39.985665223665237, -8.204256190673489],
              [-39.985665398609662, -8.204256115772303],
              [-39.985665571938732, -8.204256037231779],
              [-39.985665743576185, -8.204255955086442],
              [-39.985665913446518, -8.204255869372444],
              [-39.985666081474974, -8.204255780127529],
              [-39.985666247587623, -8.204255687390924],
              [-39.985666411711385, -8.204255591203456],
              [-39.985666573774033, -8.204255491607437],
              [-39.985666733704292, -8.204255388646674],
              [-39.985666891431769, -8.204255282366473],
              [-39.985667046887066, -8.204255172813637],
              [-39.985667200001814, -8.204255060036321],
              [-39.985667350708589, -8.204254944084152],
              [-39.985667498941133, -8.204254825008151],
              [-39.985667644634212, -8.204254702860737],
              [-39.985667787723727, -8.204254577695599],
              [-39.985667928146682, -8.204254449567863],
              [-39.985668065841345, -8.204254318533872],
              [-39.985668200747106, -8.204254184651264],
              [-39.985668332804615, -8.204254047979006],
              [-39.985668461955761, -8.204253908577192],
              [-39.985668588143717, -8.204253766507147],
              [-39.985668711312982, -8.204253621831375],
              [-39.985668831409349, -8.204253474613582],
              [-39.985668948379967, -8.204253324918504],
              [-39.985669062173386, -8.204253172811965],
              [-39.985669172739541, -8.20425301836098],
              [-39.985669280029782, -8.204252861633483],
              [-39.985669383996886, -8.204252702698364],
              [-39.985669484595121, -8.204252541625632],
              [-39.985669581780229, -8.2042523784861],
              [-39.985669675509449, -8.204252213351566],
              [-39.985669765741527, -8.204252046294686],
              [-39.985669852436786, -8.204251877388959],
              [-39.98566993555707, -8.204251706708737],
              [-39.985670015065793, -8.204251534329069],
              [-39.985670090927982, -8.204251360325838],
              [-39.985670163110278, -8.204251184775583],
              [-39.985670231580904, -8.204251007755541],
              [-39.985670296309735, -8.204250829343618],
              [-39.98567035726829, -8.204250649618261],
              [-39.985670414429748, -8.204250468658614],
              [-39.985670467768948, -8.204250286544269],
              [-39.985670517262442, -8.204250103355356],
              [-39.985670562888458, -8.204249919172479],
              [-39.985670604626897, -8.204249734076654],
              [-39.985670642459397, -8.204249548149312],
              [-39.985670676369338, -8.204249361472344],
              [-39.985670706341772, -8.204249174127762],
              [-39.985670732363531, -8.20424898619809],
              [-39.985670754423154, -8.204248797765993],
              [-39.985670772510936, -8.204248608914337],
              [-39.985670786618918, -8.20424841972625],
              [-39.985670796740912, -8.204248230284959],
              [-39.985670802872427, -8.20424804067385],
              [-39.985670805010805, -8.2042478509763],
              [-39.98567080315506, -8.204247661275804],
              [-39.985670797306064, -8.204247471655812],
              [-39.985670787466347, -8.204247282199772],
              [-39.985670773640265, -8.20424709299103],
              [-39.985670755833873, -8.204246904112843],
              [-39.985670734055034, -8.204246715648324],
              [-39.985670708313322, -8.204246527680347],
              [-39.985670678620053, -8.204246340291673],
              [-39.985670644988289, -8.204246153564711],
              [-39.985670607432851, -8.204245967581651],
              [-39.985670565970246, -8.204245782424289],
              [-39.985670520618704, -8.204245598174117],
              [-39.985670471398208, -8.204245414912203],
              [-39.9856704183304, -8.204245232719144],
              [-39.985670361438622, -8.204245051675146],
              [-39.98567030074792, -8.204244871859864],
              [-39.985670236285003, -8.204244693352383],
              [-39.985670168078194, -8.204244516231283],
              [-39.985670096157548, -8.204244340574466],
              [-39.985670128808451, -8.204244305968114],
              [-39.985106831345881, -8.202908949195949],
              [-39.985106870415187, -8.202908950214166],
              [-39.985106860430129, -8.202908926633281],
              [-39.985106850378116, -8.202908903080626],
              [-39.985106840259277, -8.202908879556418],
              [-39.985106830073633, -8.202908856060878],
              [-39.985106819821297, -8.202908832594089],
              [-39.985106809502369, -8.202908809156305],
              [-39.985106799116899, -8.20290878574774],
              [-39.985106788664986, -8.20290876236853],
              [-39.985106778146701, -8.202908739018884],
              [-39.985106767562158, -8.202908715698964],
              [-39.985106756911421, -8.202908692409022],
              [-39.985106746194546, -8.202908669149192],
              [-39.985106735411676, -8.202908645919665],
              [-39.985106724562861, -8.202908622720603],
              [-39.985106713648186, -8.202908599552261],
              [-39.985106702667764, -8.202908576414757],
              [-39.98510669162166, -8.202908553308307],
              [-39.985106680509972, -8.202908530233081],
              [-39.985106669332801, -8.202908507189305],
              [-39.985106658090196, -8.202908484177117],
              [-39.985106646782299, -8.202908461196694],
              [-39.985106635409167, -8.202908438248267],
              [-39.985106623970893, -8.202908415331994],
              [-39.985106612467582, -8.202908392448053],
              [-39.985106600899307, -8.202908369596667],
              [-39.985106589266202, -8.202908346777953],
              [-39.985106577568303, -8.202908323992128],
              [-39.985106565805751, -8.202908301239415],
              [-39.985106553978603, -8.202908278519924],
              [-39.985106542086989, -8.202908255833865],
              [-39.985106530130977, -8.202908233181436],
              [-39.985106518110662, -8.202908210562789],
              [-39.985106506026177, -8.202908187978158],
              [-39.985106493877574, -8.202908165427683],
              [-39.985106481664978, -8.202908142911523],
              [-39.985106469388469, -8.202908120429891],
              [-39.985106457048161, -8.202908097982961],
              [-39.985106444644146, -8.202908075570891],
              [-39.985106432176529, -8.202908053193916],
              [-39.98510641964539, -8.20290803085217],
              [-39.985106407050864, -8.202908008545856],
              [-39.985106394393021, -8.20290798627514],
              [-39.985106381671969, -8.202907964040174],
              [-39.985106368887813, -8.202907941841193],
              [-39.985106356040646, -8.202907919678347],
              [-39.985106343130596, -8.202907897551762],
              [-39.985106330157748, -8.202907875461714],
              [-39.985106317122209, -8.202907853408307],
              [-39.985106304024093, -8.202907831391764],
              [-39.985106290863477, -8.202907809412215],
              [-39.985106277640497, -8.202907787469826],
              [-39.985106264355238, -8.202907765564838],
              [-39.985106251007814, -8.202907743697386],
              [-39.985106237598337, -8.202907721867682],
              [-39.985106224126916, -8.202907700075835],
              [-39.985106210593656, -8.202907678322076],
              [-39.98510619699865, -8.202907656606538],
              [-39.985106183342026, -8.202907634929442],
              [-39.985106169623897, -8.20290761329094],
              [-39.985106155844342, -8.202907591691199],
              [-39.985106142003502, -8.202907570130387],
              [-39.985106128101478, -8.202907548608655],
              [-39.985106114138375, -8.202907527126227],
              [-39.985106100114329, -8.20290750568326],
              [-39.985106086029418, -8.202907484279919],
              [-39.98510607188377, -8.202907462916359],
              [-39.985106057677498, -8.202907441592775],
              [-39.985106043410738, -8.202907420309328],
              [-39.98510602908356, -8.202907399066211],
              [-39.985106014696122, -8.20290737786352],
              [-39.985106000248493, -8.202907356701523],
              [-39.98510598574083, -8.202907335580338],
              [-39.985105971173247, -8.202907314500155],
              [-39.985105956545816, -8.202907293461084],
              [-39.985105941858713, -8.202907272463365],
              [-39.985105927112023, -8.202907251507135],
              [-39.985105912305876, -8.202907230592574],
              [-39.985105897440384, -8.202907209719834],
              [-39.985105882515647, -8.202907188889087],
              [-39.985105867531814, -8.202907168100479],
              [-39.985105852488999, -8.202907147354253],
              [-39.985105837387323, -8.202907126650452],
              [-39.985105822226885, -8.202907105989347],
              [-39.985105807007812, -8.202907085371056],
              [-39.985105791730284, -8.202907064795765],
              [-39.985105776394342, -8.202907044263622],
              [-39.985105761000156, -8.202907023774818],
              [-39.985105745547841, -8.202907003329486],
              [-39.985105730037489, -8.202906982927786],
              [-39.98510571446927, -8.202906962569932],
              [-39.985105698843306, -8.202906942256034],
              [-39.985105683159681, -8.202906921986264],
              [-39.985105667418566, -8.202906901760784],
              [-39.985105651620053, -8.20290688157977],
              [-39.985105635764292, -8.202906861443402],
              [-39.985105619851403, -8.202906841351783],
              [-39.985105603881507, -8.202906821305129],
              [-39.985105587854726, -8.202906801303593],
              [-39.985105571771214, -8.202906781347295],
              [-39.985105555121834, -8.202906781704915],
              [-39.98465230266001, -8.202345995116611],
              [-39.984652283796436, -8.202345972482989],
              [-39.984652276304246, -8.202345963115361],
              [-39.98465226882437, -8.202345953737968],
              [-39.984652261356793, -8.202345944350837],
              [-39.984652253901551, -8.202345934954005],
              [-39.984652246458658, -8.202345925547444],
              [-39.984652239028101, -8.202345916131216],
              [-39.984652231609928, -8.202345906705286],
              [-39.984652224204126, -8.202345897269723],
              [-39.984652216810709, -8.202345887824514],
              [-39.984652209429697, -8.202345878369689],
              [-39.984652202061092, -8.202345868905228],
              [-39.984652194704921, -8.202345859431203],
              [-39.984652187361206, -8.202345849947596],
              [-39.98465218002994, -8.202345840454429],
              [-39.984652172711137, -8.202345830951723],
              [-39.984652165404817, -8.202345821439488],
              [-39.984652158110968, -8.202345811917739],
              [-39.984652150829653, -8.202345802386473],
              [-39.984652143560837, -8.202345792845756],
              [-39.984652136304547, -8.202345783295566],
              [-39.984652129060812, -8.202345773735944],
              [-39.984652121829619, -8.20234576416687],
              [-39.984652114611002, -8.2023457545884],
              [-39.984652107404948, -8.202345745000516],
              [-39.984652100211505, -8.202345735403268],
              [-39.984652093030647, -8.202345725796663],
              [-39.9846520858624, -8.202345716180687],
              [-39.984652078706802, -8.202345706555398],
              [-39.984652071563836, -8.202345696920801],
              [-39.984652064433511, -8.202345687276873],
              [-39.984652057315856, -8.202345677623695],
              [-39.984652050210883, -8.202345667961223],
              [-39.984652043118587, -8.202345658289541],
              [-39.984652036039009, -8.202345648608608],
              [-39.984652028972128, -8.20234563891845],
              [-39.984652021917967, -8.202345629219112],
              [-39.984652014876559, -8.202345619510577],
              [-39.984652007847899, -8.202345609792886],
              [-39.984652000831993, -8.202345600066046],
              [-39.984651993828855, -8.202345590330063],
              [-39.984651986838514, -8.202345580584982],
              [-39.984651979860971, -8.202345570830792],
              [-39.984651972896224, -8.202345561067506],
              [-39.984651965944302, -8.202345551295181],
              [-39.98465195900522, -8.202345541513791],
              [-39.98465195207897, -8.202345531723374],
              [-39.984651945165581, -8.202345521923929],
              [-39.984651938265067, -8.202345512115492],
              [-39.984651931377428, -8.202345502298098],
              [-39.984651924502678, -8.202345492471704],
              [-39.984651917640839, -8.202345482636369],
              [-39.984651910791904, -8.202345472792109],
              [-39.984651903955893, -8.202345462938942],
              [-39.984651897132842, -8.202345453076843],
              [-39.984651890322731, -8.202345443205905],
              [-39.984651883525565, -8.202345433326075],
              [-39.984651876741403, -8.202345423437418],
              [-39.9846518699702, -8.202345413539902],
              [-39.984651863212008, -8.202345403633595],
              [-39.984651856466805, -8.202345393718495],
              [-39.984651849734647, -8.202345383794606],
              [-39.984651843015513, -8.202345373861958],
              [-39.984651836309418, -8.202345363920568],
              [-39.984651829616375, -8.202345353970435],
              [-39.984651822936392, -8.202345344011617],
              [-39.984651816269491, -8.202345334044066],
              [-39.984651809615691, -8.202345324067865],
              [-39.98465180297498, -8.202345314082987],
              [-39.984651796347372, -8.202345304089489],
              [-39.984651789732901, -8.202345294087326],
              [-39.984651783131561, -8.202345284076594],
              [-39.984651776543366, -8.202345274057238],
              [-39.984651769968323, -8.202345264029345],
              [-39.984651763406447, -8.20234525399286],
              [-39.984651756857758, -8.202345243947853],
              [-39.98465175032225, -8.202345233894302],
              [-39.984651743799958, -8.202345223832268],
              [-39.984651737290861, -8.202345213761726],
              [-39.984651730794987, -8.20234520368273],
              [-39.984651724312364, -8.202345193595262],
              [-39.984651717842972, -8.202345183499371],
              [-39.984651711386839, -8.202345173395042],
              [-39.984651704943971, -8.20234516328234],
              [-39.984651698514398, -8.20234515316122],
              [-39.984651692098097, -8.202345143031758],
              [-39.984651685695106, -8.202345132893926],
              [-39.984651679305429, -8.202345122747772],
              [-39.984651672929061, -8.202345112593317],
              [-39.984651666566045, -8.202345102430542],
              [-39.984651660216365, -8.202345092259494],
              [-39.98465165388005, -8.202345082080193],
              [-39.984651647557087, -8.202345071892619],
              [-39.98465164124751, -8.20234506169683],
              [-39.984651634951305, -8.202345051492818],
              [-39.984651628668509, -8.202345041280619],
              [-39.984651622399127, -8.202345031060238],
              [-39.984651616143154, -8.2023450208317],
              [-39.984651609900624, -8.202345010595032],
              [-39.984651603671409, -8.202345000351821],
              [-39.984651645909217, -8.202344979178052],
              [-39.983969866158596, -8.201221091757514],
              [-39.983969897451743, -8.201221112344813],
              [-39.983969889460283, -8.20122109919499],
              [-39.983969881446399, -8.201221086058711],
              [-39.983969873410132, -8.20122107293602],
              [-39.983969865351469, -8.201221059826976],
              [-39.983969857270459, -8.201221046731597],
              [-39.983969849167138, -8.201221033649935],
              [-39.983969841041493, -8.201221020581976],
              [-39.983969832893557, -8.201221007527829],
              [-39.983969824723381, -8.201220994487487],
              [-39.983969816530958, -8.201220981460994],
              [-39.983969808316331, -8.201220968448403],
              [-39.983969800079521, -8.201220955449715],
              [-39.983969791820542, -8.201220942464994],
              [-39.983969783539415, -8.201220929494294],
              [-39.983969775236176, -8.201220916537647],
              [-39.98396976691086, -8.201220903595088],
              [-39.983969758563461, -8.201220890666631],
              [-39.983969750194021, -8.201220877752329],
              [-39.983969741802554, -8.20122086485221],
              [-39.983969733389102, -8.201220851966333],
              [-39.983969724953681, -8.201220839094711],
              [-39.983969716496311, -8.20122082623738],
              [-39.983969708017014, -8.201220813394414],
              [-39.983969699515818, -8.201220800565828],
              [-39.983969690992758, -8.201220787751661],
              [-39.983969682447842, -8.201220774951938],
              [-39.983969673881091, -8.201220762166686],
              [-39.983969665292555, -8.201220749395938],
              [-39.983969656682241, -8.201220736639803],
              [-39.983969648050184, -8.201220723898224],
              [-39.983969639396399, -8.201220711171322],
              [-39.983969630720914, -8.201220698459061],
              [-39.98396962202375, -8.201220685761525],
              [-39.983969613304943, -8.201220673078746],
              [-39.983969604564507, -8.201220660410762],
              [-39.983969595802478, -8.201220647757575],
              [-39.983969587018869, -8.201220635119242],
              [-39.98396957821371, -8.201220622495807],
              [-39.983969569387028, -8.201220609887297],
              [-39.98396956053886, -8.201220597293769],
              [-39.983969551669205, -8.201220584715216],
              [-39.983969542778119, -8.201220572151708],
              [-39.983969533865604, -8.20122055960328],
              [-39.983969524931688, -8.201220547069966],
              [-39.983969515976412, -8.201220534551798],
              [-39.983969506999799, -8.201220522048789],
              [-39.983969498001855, -8.201220509561031],
              [-39.98396948898263, -8.201220497088519],
              [-39.983969479942132, -8.201220484631303],
              [-39.98396947088041, -8.201220472189425],
              [-39.983969461797471, -8.201220459762888],
              [-39.98396945269333, -8.201220447351751],
              [-39.983969443568029, -8.201220434956047],
              [-39.983969434421603, -8.201220422575807],
              [-39.983969425254074, -8.201220410211089],
              [-39.983969416065463, -8.201220397861915],
              [-39.983969406855792, -8.201220385528311],
              [-39.983969397625096, -8.201220373210324],
              [-39.983969388373403, -8.201220360907966],
              [-39.983969379100728, -8.201220348621332],
              [-39.983969369807106, -8.201220336350394],
              [-39.983969360492573, -8.201220324095212],
              [-39.983969351157121, -8.201220311855829],
              [-39.983969341800844, -8.201220299632283],
              [-39.983969332423705, -8.201220287424588],
              [-39.98396932302574, -8.201220275232803],
              [-39.983969313606998, -8.201220263056946],
              [-39.983969304167516, -8.201220250897062],
              [-39.983969294707286, -8.201220238753182],
              [-39.983969285226344, -8.201220226625326],
              [-39.983969275724732, -8.201220214513535],
              [-39.983969266202479, -8.201220202417852],
              [-39.983969256659599, -8.201220190338327],
              [-39.983969247096127, -8.20122017827495],
              [-39.983969237512085, -8.201220166227799],
              [-39.983969227907508, -8.201220154196905],
              [-39.983969218282425, -8.201220142182285],
              [-39.983969208636843, -8.201220130183986],
              [-39.983969198970819, -8.201220118202029],
              [-39.983969189284366, -8.201220106236462],
              [-39.983969179577521, -8.201220094287333],
              [-39.983969169850283, -8.20122008235465],
              [-39.983969160102717, -8.201220070438456],
              [-39.983969150334843, -8.201220058538787],
              [-39.98396914054667, -8.201220046655678],
              [-39.983969130738238, -8.201220034789158],
              [-39.983969120909592, -8.201220022939266],
              [-39.98396911106073, -8.201220011106033],
              [-39.98396910119169, -8.201219999289492],
              [-39.983969091302512, -8.201219987489681],
              [-39.983969081393226, -8.201219975706628],
              [-39.983969071463839, -8.201219963940387],
              [-39.983969061514401, -8.201219952190975],
              [-39.983969051544932, -8.201219940458422],
              [-39.983969041555461, -8.201219928742765],
              [-39.983969031546017, -8.201219917044069],
              [-39.983969021516629, -8.20121990536232],
              [-39.98396901146733, -8.201219893697564],
              [-39.983969001398158, -8.201219882049857],
              [-39.983969046276819, -8.201219918010942],
              [-39.983291286200789, -8.200437257391897],
              [-39.983291280486185, -8.200437259658365],
              [-39.983291219064661, -8.200437187977686],
              [-39.983291158396206, -8.200437115663226],
              [-39.98329109848742, -8.20043704272276],
              [-39.983291039344799, -8.200436969164301],
              [-39.983290980974758, -8.200436894995763],
              [-39.983290923383663, -8.200436820225246],
              [-39.983290866577761, -8.200436744860879],
              [-39.983290810563197, -8.200436668910797],
              [-39.983290755346061, -8.200436592383273],
              [-39.983290700932372, -8.200436515286631],
              [-39.983290647328012, -8.20043643762923],
              [-39.983290594538829, -8.200436359419514],
              [-39.983290542570529, -8.200436280665944],
              [-39.983290491428789, -8.200436201377073],
              [-39.983290441119124, -8.200436121561584],
              [-39.98329039164701, -8.200436041228077],
              [-39.983290343017835, -8.200435960385276],
              [-39.983290295236877, -8.200435879041979],
              [-39.983290248309316, -8.200435797207041],
              [-39.983290202240248, -8.200435714889302],
              [-39.983290157034673, -8.200435632097733],
              [-39.98329011269751, -8.200435548841332],
              [-39.983290069233568, -8.200435465129127],
              [-39.98329002664758, -8.200435380970237],
              [-39.983289984944136, -8.20043529637371],
              [-39.983289944127804, -8.200435211348811],
              [-39.983289904203005, -8.200435125904781],
              [-39.983289865174058, -8.200435040050857],
              [-39.983289827045212, -8.200434953796378],
              [-39.983289789820617, -8.200434867150735],
              [-39.983289753504295, -8.200434780123281],
              [-39.983289718100217, -8.200434692723526],
              [-39.983289683612213, -8.200434604960916],
              [-39.983289650044036, -8.200434516845036],
              [-39.983289617399301, -8.200434428385403],
              [-39.98328958568159, -8.200434339591641],
              [-39.983289554894327, -8.20043425047341],
              [-39.983289525040867, -8.200434161040389],
              [-39.983289496124463, -8.200434071302244],
              [-39.983289468148207, -8.200433981268761],
              [-39.983289441115197, -8.200433890949682],
              [-39.983289415028324, -8.200433800354865],
              [-39.983289389890437, -8.20043370949414],
              [-39.983289365704273, -8.200433618377343],
              [-39.98328934247246, -8.200433527014358],
              [-39.983289320197507, -8.200433435415158],
              [-39.98328929888185, -8.200433343589671],
              [-39.983289278527764, -8.200433251547839],
              [-39.983289259137514, -8.200433159299685],
              [-39.983289240713177, -8.20043306685521],
              [-39.983289223256747, -8.200432974224482],
              [-39.983289206770131, -8.200432881417536],
              [-39.983289191255125, -8.200432788444477],
              [-39.983289176713406, -8.200432695315374],
              [-39.983289163146559, -8.200432602040365],
              [-39.98328915055604, -8.20043250862955],
              [-39.983289138943249, -8.200432415093074],
              [-39.983289128309409, -8.200432321441138],
              [-39.983289118655705, -8.200432227683866],
              [-39.983289109983161, -8.200432133831447],
              [-39.983289102292723, -8.200432039894098],
              [-39.983289095585249, -8.200431945882022],
              [-39.983289089861465, -8.20043185180541],
              [-39.983289085121953, -8.200431757674453],
              [-39.983289081367261, -8.200431663499394],
              [-39.983289078597799, -8.200431569290481],
              [-39.983289076813861, -8.200431475057957],
              [-39.98328907601563, -8.200431380811995],
              [-39.983289076203221, -8.200431286562866],
              [-39.983289077376568, -8.200431192320803],
              [-39.983289079535588, -8.200431098096063],
              [-39.983289082680017, -8.200431003898819],
              [-39.983289086809535, -8.200430909739353],
              [-39.983289091923666, -8.200430815627854],
              [-39.983289098021892, -8.200430721574602],
              [-39.983289105103523, -8.200430627589736],
              [-39.9832891131678, -8.200430533683507],
              [-39.983289122213833, -8.200430439866118],
              [-39.983289132240664, -8.200430346147721],
              [-39.983289143247191, -8.200430252538535],
              [-39.983289155232228, -8.200430159048677],
              [-39.983289168194446, -8.200430065688343],
              [-39.983289182132459, -8.200429972467637],
              [-39.983289197044769, -8.200429879396744],
              [-39.983289212929726, -8.200429786485708],
              [-39.983289229785612, -8.200429693744638],
              [-39.983289247610607, -8.200429601183632],
              [-39.983289266402771, -8.200429508812698],
              [-39.983289286160073, -8.200429416641883],
              [-39.983289306880323, -8.200429324681201],
              [-39.983289328561348, -8.200429232940646],
              [-39.983289351200725, -8.20042914143016],
              [-39.983289374796023, -8.200429050159705],
              [-39.98328939934467, -8.200428959139186],
              [-39.983289424844024, -8.200428868378483],
              [-39.983289451291292, -8.200428777887431],
              [-39.983289478683602, -8.200428687675895],
              [-39.983289507017993, -8.200428597753655],
              [-39.983289536291302, -8.200428508130612],
              [-39.983289539890393, -8.200428488761792],
              [-39.983511392853586, -8.199761027167504],
              [-39.983511408039163, -8.199761065003145],
              [-39.983511435145644, -8.19976098472179],
              [-39.983511463005577, -8.199760904696886],
              [-39.98351149161654, -8.19976082493544],
              [-39.983511520975973, -8.199760745444468],
              [-39.983511551081342, -8.199760666230919],
              [-39.983511581929996, -8.199760587301755],
              [-39.983511613519219, -8.199760508663884],
              [-39.983511645846256, -8.199760430324213],
              [-39.983511678908251, -8.199760352289601],
              [-39.983511712702324, -8.19976027456693],
              [-39.983511747225506, -8.199760197162956],
              [-39.983511782474757, -8.199760120084534],
              [-39.983511818446992, -8.199760043338387],
              [-39.983511855139071, -8.199759966931248],
              [-39.983511892547746, -8.199759890869826],
              [-39.983511930669756, -8.199759815160789],
              [-39.983511969501755, -8.199759739810782],
              [-39.983512009040339, -8.199759664826425],
              [-39.983512049282048, -8.199759590214255],
              [-39.983512090223329, -8.199759515980844],
              [-39.983512131860593, -8.199759442132711],
              [-39.983512174190217, -8.199759368676309],
              [-39.983512217208457, -8.199759295618104],
              [-39.98351226091156, -8.199759222964509],
              [-39.983512305295683, -8.199759150721858],
              [-39.983512350356932, -8.199759078896506],
              [-39.983512396091363, -8.199759007494773],
              [-39.983512442494956, -8.199758936522887],
              [-39.983512489563658, -8.199758865987123],
              [-39.983512537293315, -8.19975879589361],
              [-39.983512585679748, -8.19975872624854],
              [-39.983512634718721, -8.199758657057979],
              [-39.983512684405937, -8.199758588328045],
              [-39.983512734737026, -8.199758520064746],
              [-39.983512785707568, -8.199758452274054],
              [-39.983512837313114, -8.199758384961928],
              [-39.983512889549118, -8.199758318134251],
              [-39.983512942411018, -8.199758251796947],
              [-39.983512995894159, -8.199758185955753],
              [-39.98351304999386, -8.199758120616504],
              [-39.983513104705374, -8.199758055784882],
              [-39.983513160023897, -8.199757991466615],
              [-39.983513215944569, -8.199757927667338],
              [-39.983513272462517, -8.199757864392661],
              [-39.983513329572759, -8.199757801648095],
              [-39.9835133872703, -8.199757739439152],
              [-39.983513445550052, -8.199757677771302],
              [-39.983513504406929, -8.199757616649929],
              [-39.983513563835757, -8.199757556080435],
              [-39.983513623831335, -8.199757496068107],
              [-39.983513684388399, -8.199757436618201],
              [-39.983513745501618, -8.199757377735983],
              [-39.983513807165657, -8.199757319426549],
              [-39.983513869375095, -8.199757261695058],
              [-39.983513932124474, -8.199757204546536],
              [-39.983513995408295, -8.199757147986027],
              [-39.983514059220987, -8.199757092018483],
              [-39.983514123557001, -8.1997570366488],
              [-39.983514188410645, -8.199756981881851],
              [-39.983514253776264, -8.199756927722422],
              [-39.983514319648087, -8.199756874175307],
              [-39.983514386020381, -8.199756821245174],
              [-39.983514452887299, -8.199756768936652],
              [-39.983514520242984, -8.199756717254335],
              [-39.983514588081526, -8.199756666202756],
              [-39.983514656396977, -8.199756615786406],
              [-39.983514725183319, -8.199756566009698],
              [-39.983514794434569, -8.199756516876997],
              [-39.983514864144624, -8.199756468392614],
              [-39.983514934307358, -8.199756420560817],
              [-39.983515004916626, -8.199756373385773],
              [-39.983515075966267, -8.199756326871624],
              [-39.983515147449992, -8.199756281022456],
              [-39.983515219361571, -8.199756235842317],
              [-39.983515291694694, -8.199756191335146],
              [-39.983515364443008, -8.199756147504827],
              [-39.983515437600126, -8.199756104355226],
              [-39.983515511159638, -8.199756061890128],
              [-39.9835155851151, -8.19975602011327],
              [-39.983515659460018, -8.199755979028291],
              [-39.983515734187868, -8.199755938638798],
              [-39.983515809292101, -8.199755898948339],
              [-39.983515884766121, -8.199755859960424],
              [-39.98351596060332, -8.199755821678416],
              [-39.983516036797042, -8.199755784105701],
              [-39.983516113340606, -8.1997557472456],
              [-39.983516190227292, -8.199755711101311],
              [-39.983516267450369, -8.199755675676016],
              [-39.983516345003039, -8.199755640972821],
              [-39.983516422878523, -8.199755606994769],
              [-39.983516501069985, -8.199755573744842],
              [-39.983516579570562, -8.199755541225954],
              [-39.983516658373368, -8.199755509440953],
              [-39.983516737471504, -8.199755478392641],
              [-39.983516816858021, -8.199755448083737],
              [-39.983516896525948, -8.199755418516894],
              [-39.983516976468316, -8.199755389694701],
              [-39.98351705667811, -8.199755361619687],
              [-39.98351713714861, -8.199755334293588],
              [-39.983517097765969, -8.199755315395672],
              [-39.984191197803007, -8.199529916627856],
              [-39.984191157701503, -8.199529959926656],
              [-39.984191171315459, -8.199529955386458],
              [-39.984191184936584, -8.199529950867662],
              [-39.984191198564879, -8.199529946370385],
              [-39.984191212200301, -8.199529941894582],
              [-39.9841912258428, -8.199529937440259],
              [-39.984191239492368, -8.199529933007449],
              [-39.984191253148943, -8.19952992859613],
              [-39.984191266812495, -8.199529924206345],
              [-39.984191280483024, -8.199529919838106],
              [-39.984191294160446, -8.199529915491395],
              [-39.984191307844767, -8.199529911166245],
              [-39.984191321535917, -8.199529906862676],
              [-39.984191335233902, -8.19952990258067],
              [-39.984191348938658, -8.199529898320245],
              [-39.984191362650158, -8.199529894081435],
              [-39.984191376368372, -8.199529889864237],
              [-39.984191390093272, -8.199529885668655],
              [-39.984191403824795, -8.199529881494719],
              [-39.98419141756294, -8.199529877342396],
              [-39.98419143130765, -8.199529873211759],
              [-39.984191445058904, -8.199529869102752],
              [-39.98419145881666, -8.199529865015423],
              [-39.984191472580896, -8.199529860949781],
              [-39.984191486351563, -8.199529856905817],
              [-39.984191500128638, -8.199529852883574],
              [-39.984191513912073, -8.19952984888304],
              [-39.984191527701846, -8.199529844904244],
              [-39.984191541497907, -8.199529840947177],
              [-39.984191555300242, -8.199529837011847],
              [-39.984191569108802, -8.199529833098264],
              [-39.984191582923557, -8.199529829206433],
              [-39.984191596744481, -8.199529825336384],
              [-39.984191610571521, -8.199529821488124],
              [-39.984191624404666, -8.199529817661661],
              [-39.984191638243857, -8.199529813856998],
              [-39.984191652089066, -8.199529810074138],
              [-39.984191665940266, -8.199529806313091],
              [-39.984191679797412, -8.199529802573885],
              [-39.984191693660492, -8.199529798856542],
              [-39.984191707529448, -8.199529795161025],
              [-39.984191721404251, -8.199529791487354],
              [-39.984191735284867, -8.199529787835553],
              [-39.984191749171273, -8.199529784205653],
              [-39.984191763063407, -8.199529780597624],
              [-39.984191776961268, -8.199529777011481],
              [-39.984191790864791, -8.199529773447241],
              [-39.98419180477395, -8.199529769904935],
              [-39.984191818688728, -8.199529766384538],
              [-39.984191832609078, -8.199529762886055],
              [-39.984191846534955, -8.199529759409529],
              [-39.984191860466339, -8.199529755954938],
              [-39.98419187440318, -8.199529752522324],
              [-39.984191888345457, -8.199529749111639],
              [-39.984191902293134, -8.199529745722947],
              [-39.984191916246175, -8.199529742356241],
              [-39.984191930204545, -8.199529739011505],
              [-39.984191944168195, -8.199529735688774],
              [-39.984191958137103, -8.19952973238807],
              [-39.98419197211124, -8.199529729109363],
              [-39.984191986090565, -8.199529725852667],
              [-39.984192000075033, -8.199529722618026],
              [-39.984192014064625, -8.199529719405424],
              [-39.984192028059304, -8.199529716214858],
              [-39.984192042059021, -8.199529713046351],
              [-39.984192056063748, -8.199529709899901],
              [-39.984192070073455, -8.199529706775522],
              [-39.984192084088114, -8.199529703673216],
              [-39.98419209810767, -8.199529700592999],
              [-39.984192112132092, -8.199529697534876],
              [-39.984192126161361, -8.199529694498858],
              [-39.984192140195432, -8.199529691484948],
              [-39.984192154234265, -8.199529688493163],
              [-39.984192168277836, -8.199529685523498],
              [-39.98419218232609, -8.199529682575962],
              [-39.98419219637902, -8.199529679650563],
              [-39.984192210436568, -8.199529676747307],
              [-39.984192224498706, -8.199529673866229],
              [-39.984192238565406, -8.199529671007289],
              [-39.984192252636625, -8.199529668170509],
              [-39.984192266712341, -8.199529665355939],
              [-39.984192280792477, -8.199529662563524],
              [-39.98419229487704, -8.19952965979332],
              [-39.984192308966001, -8.199529657045291],
              [-39.984192323059276, -8.199529654319472],
              [-39.984192337156891, -8.199529651615867],
              [-39.984192351258756, -8.199529648934485],
              [-39.984192365364876, -8.199529646275311],
              [-39.984192379475182, -8.19952964363838],
              [-39.98419239358968, -8.199529641023659],
              [-39.984192407708306, -8.199529638431201],
              [-39.984192421831018, -8.199529635861003],
              [-39.984192435957787, -8.19952963331305],
              [-39.984192450088592, -8.199529630787371],
              [-39.984192464223391, -8.199529628283937],
              [-39.984192478362132, -8.199529625802796],
              [-39.984192492504818, -8.199529623343937],
              [-39.984192506651375, -8.199529620907366],
              [-39.984192520801777, -8.199529618493081],
              [-39.984192534956037, -8.199529616100579],
              [-39.984192558781025, -8.199529641979392],
              [-39.984865844581591, -8.199416392386945],
              [-39.984865809852707, -8.199416381127032],
              [-39.984865907746162, -8.199416364113146],
              [-39.984866005446811, -8.199416346032631],
              [-39.984866102942959, -8.199416326887622],
              [-39.984866200222889, -8.199416306680458],
              [-39.984866297274991, -8.199416285413504],
              [-39.984866394087618, -8.199416263089368],
              [-39.984866490649146, -8.199416239710695],
              [-39.984866586948023, -8.199416215280269],
              [-39.984866682972722, -8.199416189801072],
              [-39.984866778711712, -8.199416163276123],
              [-39.984866874153546, -8.199416135708606],
              [-39.984866969286763, -8.199416107101817],
              [-39.984867064099966, -8.199416077459166],
              [-39.984867158581807, -8.199416046784242],
              [-39.984867252720939, -8.199416015080722],
              [-39.984867346506114, -8.199415982352344],
              [-39.98486743992607, -8.199415948603106],
              [-39.984867532969623, -8.199415913837029],
              [-39.984867625625618, -8.199415878058277],
              [-39.984867717882949, -8.199415841271108],
              [-39.984867809730567, -8.19941580347999],
              [-39.984867901157465, -8.199415764689393],
              [-39.98486799215268, -8.199415724903993],
              [-39.984868082705319, -8.199415684128551],
              [-39.984868172804518, -8.199415642367963],
              [-39.984868262439491, -8.199415599627192],
              [-39.984868351599495, -8.199415555911433],
              [-39.984868440273836, -8.199415511225869],
              [-39.984868528451905, -8.199415465575882],
              [-39.984868616123109, -8.199415418966922],
              [-39.984868703276966, -8.199415371404593],
              [-39.984868789903018, -8.199415322894579],
              [-39.984868875990898, -8.199415273442701],
              [-39.984868961530282, -8.199415223054897],
              [-39.984869046510916, -8.199415171737172],
              [-39.984869130922633, -8.199415119495686],
              [-39.984869214755285, -8.199415066336741],
              [-39.984869297998856, -8.199415012266645],
              [-39.984869380643353, -8.199414957291919],
              [-39.984869462678894, -8.199414901419143],
              [-39.984869544095623, -8.199414844655026],
              [-39.9848696248838, -8.199414787006321],
              [-39.984869705033731, -8.19941472848001],
              [-39.984869784535817, -8.199414669083023],
              [-39.984869863380567, -8.199414608822531],
              [-39.984869941558458, -8.199414547705761],
              [-39.984870019060175, -8.199414485740002],
              [-39.984870095876417, -8.199414422932705],
              [-39.98487017199799, -8.199414359291382],
              [-39.984870247415756, -8.19941429482366],
              [-39.984870322120678, -8.199414229537261],
              [-39.984870396103823, -8.199414163440036],
              [-39.984870469356295, -8.199414096539886],
              [-39.984870541869334, -8.199414028844831],
              [-39.984870613634264, -8.199413960362984],
              [-39.984870684642459, -8.199413891102539],
              [-39.984870754885428, -8.199413821071808],
              [-39.984870824354736, -8.199413750279181],
              [-39.984870893042086, -8.199413678733153],
              [-39.984870960939205, -8.199413606442283],
              [-39.984871028038008, -8.199413533415202],
              [-39.984871094330408, -8.199413459660725],
              [-39.984871159808492, -8.199413385187661],
              [-39.984871224464392, -8.199413310004925],
              [-39.984871288290378, -8.199413234121565],
              [-39.984871351278777, -8.199413157546598],
              [-39.984871413422084, -8.199413080289272],
              [-39.984871474712811, -8.19941300235884],
              [-39.984871535143625, -8.199412923764577],
              [-39.9848715947073, -8.199412844515965],
              [-39.984871653396681, -8.199412764622471],
              [-39.984871711204732, -8.199412684093701],
              [-39.984871768124542, -8.199412602939272],
              [-39.98487182414928, -8.199412521168931],
              [-39.984871879272234, -8.199412438792466],
              [-39.984871933486801, -8.199412355819746],
              [-39.98487198678648, -8.199412272260725],
              [-39.984872039164877, -8.199412188125374],
              [-39.984872090615738, -8.199412103423821],
              [-39.984872141132868, -8.199412018166214],
              [-39.984872190710234, -8.19941193236275],
              [-39.984872239341897, -8.19941184602372],
              [-39.984872287022007, -8.199411759159476],
              [-39.984872333744867, -8.199411671780432],
              [-39.984872379504893, -8.199411583897039],
              [-39.984872424296576, -8.199411495519829],
              [-39.98487246811456, -8.199411406659408],
              [-39.984872510953579, -8.199411317326405],
              [-39.984872552808525, -8.199411227531559],
              [-39.984872593674368, -8.199411137285601],
              [-39.98487263354621, -8.199411046599336],
              [-39.984872672419264, -8.199410955483662],
              [-39.984872710288897, -8.199410863949508],
              [-39.984872747150568, -8.199410772007813],
              [-39.984872782999815, -8.199410679669592],
              [-39.984872817832397, -8.199410586945907],
              [-39.984872851644113, -8.199410493847903],
              [-39.984872884430921, -8.199410400386705],
              [-39.984872916188877, -8.199410306573537],
              [-39.98487291022235, -8.199410315506361],
              [-39.985095030848854, -8.198742219630454],
              [-39.985094989352518, -8.198742247657336],
              [-39.985095010711944, -8.198742182577703],
              [-39.985095031576236, -8.198742117338835],
              [-39.985095051944192, -8.198742051944462],
              [-39.985095071814669, -8.198741986398339],
              [-39.985095091186501, -8.198741920704231],
              [-39.985095110058623, -8.198741854865862],
              [-39.985095128429897, -8.198741788887039],
              [-39.985095146299294, -8.19874172277154],
              [-39.985095163665804, -8.198741656523142],
              [-39.985095180528425, -8.198741590145652],
              [-39.985095196886171, -8.198741523642898],
              [-39.985095212738109, -8.198741457018643],
              [-39.985095228083367, -8.198741390276755],
              [-39.98509524292102, -8.19874132342102],
              [-39.985095257250251, -8.198741256455287],
              [-39.985095271070193, -8.198741189383391],
              [-39.985095284380115, -8.198741122209185],
              [-39.985095297179207, -8.198741054936502],
              [-39.985095309466764, -8.198740987569213],
              [-39.985095321242063, -8.198740920111177],
              [-39.985095332504436, -8.198740852566241],
              [-39.985095343253256, -8.198740784938295],
              [-39.98509535348785, -8.198740717231214],
              [-39.985095363207684, -8.198740649448878],
              [-39.985095372412204, -8.198740581595175],
              [-39.985095381100869, -8.198740513673984],
              [-39.985095389273148, -8.198740445689198],
              [-39.985095396928614, -8.198740377644741],
              [-39.985095404066819, -8.198740309544498],
              [-39.985095410687357, -8.198740241392327],
              [-39.985095416789832, -8.198740173192199],
              [-39.985095422373909, -8.198740104947973],
              [-39.985095427439262, -8.198740036663608],
              [-39.985095431985606, -8.198739968342974],
              [-39.985095436012678, -8.198739899990002],
              [-39.985095439520244, -8.198739831608615],
              [-39.98509544250809, -8.198739763202742],
              [-39.985095444976075, -8.198739694776311],
              [-39.985095446924049, -8.198739626333204],
              [-39.985095448351885, -8.198739557877369],
              [-39.985095449259504, -8.198739489412711],
              [-39.98509544964687, -8.198739420943181],
              [-39.985095449513935, -8.198739352472714],
              [-39.985095448860726, -8.198739284005184],
              [-39.985095447687293, -8.19873921554457],
              [-39.985095445993672, -8.198739147094727],
              [-39.985095443779983, -8.198739078659647],
              [-39.985095441046326, -8.198739010243226],
              [-39.985095437792893, -8.198738941849385],
              [-39.985095434019861, -8.198738873482025],
              [-39.98509542972743, -8.198738805145103],
              [-39.985095424915848, -8.198738736842515],
              [-39.985095419585413, -8.198738668578137],
              [-39.985095413736403, -8.198738600355982],
              [-39.98509540736918, -8.198738532179869],
              [-39.985095400484084, -8.198738464053736],
              [-39.985095393081508, -8.198738395981481],
              [-39.985095385161898, -8.198738327967042],
              [-39.98509537672571, -8.198738260014254],
              [-39.985095367773411, -8.198738192127085],
              [-39.9850953583055, -8.198738124309365],
              [-39.985095348322567, -8.19873805656499],
              [-39.985095337825136, -8.198737988897877],
              [-39.985095326813841, -8.198737921311857],
              [-39.985095315289293, -8.198737853810846],
              [-39.985095303252159, -8.198737786398675],
              [-39.985095290703129, -8.19873771907926],
              [-39.985095277642927, -8.198737651856391],
              [-39.985095264072292, -8.198737584733978],
              [-39.985095249992021, -8.198737517715875],
              [-39.985095235402902, -8.198737450805865],
              [-39.98509522030578, -8.198737384007798],
              [-39.985095204701501, -8.198737317325556],
              [-39.985095188590989, -8.198737250762909],
              [-39.985095171975154, -8.198737184323679],
              [-39.985095154854939, -8.198737118011655],
              [-39.985095137231355, -8.198737051830721],
              [-39.985095119105374, -8.198736985784562],
              [-39.985095100478048, -8.198736919877042],
              [-39.985095081350465, -8.198736854111916],
              [-39.98509506172369, -8.198736788492937],
              [-39.985095041598882, -8.198736723023925],
              [-39.985095020977155, -8.198736657708515],
              [-39.985094999859726, -8.198736592550548],
              [-39.985094978247787, -8.198736527553743],
              [-39.985094956142589, -8.198736462721822],
              [-39.985094933545383, -8.198736398058465],
              [-39.985094910457448, -8.198736333567412],
              [-39.985094886880169, -8.198736269252354],
              [-39.98509486281484, -8.198736205116969],
              [-39.985094838262867, -8.198736141164932],
              [-39.985094813225651, -8.198736077399941],
              [-39.985094787704611, -8.198736013825616],
              [-39.985094761701248, -8.198735950445613],
              [-39.985094735217011, -8.198735887263563],
              [-39.985094708253442, -8.1987358242831],
              [-39.985094680812075, -8.198735761507796],
              [-39.98509465289451, -8.198735698941281],
              [-39.985094624502288, -8.198735636587131],
              [-39.985094651495771, -8.198735618282594],
              [-39.984532552257498, -8.197513580304159],
              [-39.9845325878319, -8.197513619543994],
              [-39.984532513346451, -8.197513453186547],
              [-39.984532442238923, -8.197513285368226],
              [-39.984532374538126, -8.197513116157134],
              [-39.984532310271561, -8.19751294562189],
              [-39.984532249465282, -8.197512773831692],
              [-39.984532192143952, -8.197512600856214],
              [-39.984532138330835, -8.197512426765604],
              [-39.984532088047743, -8.197512251630526],
              [-39.984532041315077, -8.19751207552201],
              [-39.984531998151809, -8.197511898511511],
              [-39.984531958575452, -8.19751172067077],
              [-39.984531922602038, -8.197511542072005],
              [-39.984531890246167, -8.197511362787621],
              [-39.984531861520978, -8.19751118289035],
              [-39.984531836438101, -8.197511002453171],
              [-39.984531815007742, -8.19751082154928],
              [-39.984531797238553, -8.197510640252069],
              [-39.984531783137776, -8.197510458635081],
              [-39.984531772711122, -8.197510276772013],
              [-39.984531765962814, -8.197510094736606],
              [-39.9845317628956, -8.197509912602692],
              [-39.984531763510709, -8.197509730444176],
              [-39.984531767807916, -8.197509548334974],
              [-39.984531775785449, -8.197509366348909],
              [-39.984531787440091, -8.197509184559847],
              [-39.984531802767101, -8.197509003041496],
              [-39.984531821760271, -8.197508821867524],
              [-39.98453184441189, -8.197508641111401],
              [-39.984531870712793, -8.197508460846477],
              [-39.984531900652271, -8.197508281145842],
              [-39.984531934218218, -8.197508102082422],
              [-39.984531971396983, -8.19750792372885],
              [-39.98453201217351, -8.19750774615745],
              [-39.984532056531243, -8.197507569440301],
              [-39.9845321044522, -8.19750739364906],
              [-39.984532155916931, -8.197507218855046],
              [-39.984532210904561, -8.197507045129166],
              [-39.984532269392794, -8.197506872541872],
              [-39.984532331357883, -8.197506701163187],
              [-39.984532396774711, -8.197506531062649],
              [-39.984532465616738, -8.197506362309218],
              [-39.984532537856026, -8.197506194971409],
              [-39.984532613463287, -8.197506029117047],
              [-39.984532692407839, -8.19750586481346],
              [-39.984532774657659, -8.197505702127264],
              [-39.984532860179392, -8.19750554112446],
              [-39.984532948938323, -8.197505381870384],
              [-39.984533040898498, -8.197505224429621],
              [-39.984533136022542, -8.197505068866009],
              [-39.984533234271915, -8.197504915242714],
              [-39.984533335606748, -8.197504763621994],
              [-39.984533439985917, -8.197504614065396],
              [-39.984533547367114, -8.197504466633553],
              [-39.984533657706777, -8.197504321386305],
              [-39.984533770960113, -8.197504178382561],
              [-39.984533887081184, -8.197504037680321],
              [-39.984534006022926, -8.197503899336672],
              [-39.984534127737064, -8.197503763407731],
              [-39.984534252174214, -8.197503629948653],
              [-39.984534379283907, -8.197503499013569],
              [-39.98453450901458, -8.197503370655548],
              [-39.984534641313608, -8.197503244926729],
              [-39.984534776127333, -8.197503121878086],
              [-39.984534913401042, -8.197503001559525],
              [-39.984535053079071, -8.197502884019871],
              [-39.984535195104748, -8.197502769306805],
              [-39.984535339420461, -8.197502657466824],
              [-39.984535485967683, -8.197502548545321],
              [-39.984535634686949, -8.197502442586496],
              [-39.984535785517927, -8.197502339633312],
              [-39.984535938399446, -8.197502239727553],
              [-39.984536093269476, -8.19750214290973],
              [-39.984536250065204, -8.197502049219109],
              [-39.984536408723017, -8.197501958693707],
              [-39.984536569178559, -8.197501871370251],
              [-39.984536731366731, -8.197501787284137],
              [-39.984536895221744, -8.197501706469509],
              [-39.984537060677162, -8.197501628959143],
              [-39.984537227665811, -8.197501554784468],
              [-39.984537396120004, -8.197501483975584],
              [-39.984537565971358, -8.197501416561217],
              [-39.984537737151015, -8.197501352568716],
              [-39.984537909589498, -8.197501292024048],
              [-39.984538083216897, -8.197501234951728],
              [-39.98453825796274, -8.197501181374948],
              [-39.98453843375615, -8.197501131315423],
              [-39.984538610525838, -8.197501084793485],
              [-39.984538788200076, -8.197501041827971],
              [-39.984538966706793, -8.197501002436359],
              [-39.984539145973585, -8.197500966634568],
              [-39.98453932592772, -8.197500934437167],
              [-39.984539506496205, -8.197500905857218],
              [-39.984539687605789, -8.197500880906277],
              [-39.984539869182996, -8.197500859594502],
              [-39.984540051154191, -8.197500841930509],
              [-39.984540233445529, -8.197500827921489],
              [-39.984540415983084, -8.197500817573097],
              [-39.984540598692803, -8.197500810889572],
              [-39.984540781500975, -8.197500807873352],
              [-39.984540818053503, -8.197500807784101],
              [-39.985539225227789, -8.197498369216179],
              [-39.985539344485993, -8.197498370122441],
              [-39.985539463721942, -8.197498372589578],
              [-39.985539582915081, -8.197498376617226],
              [-39.985539702044811, -8.197498382204653],
              [-39.98553982109059, -8.197498389350907],
              [-39.985539940031856, -8.197498398054748],
              [-39.985540058848109, -8.197498408314715],
              [-39.9855401775188, -8.197498420128939],
              [-39.98554029602348, -8.197498433495484],
              [-39.985540414341664, -8.197498448412],
              [-39.985540532452958, -8.197498464875883],
              [-39.985540650336958, -8.197498482884329],
              [-39.985540767973333, -8.197498502434245],
              [-39.985540885341763, -8.197498523522221],
              [-39.985541002422003, -8.197498546144605],
              [-39.985541119193833, -8.197498570297533],
              [-39.9855412356371, -8.197498595976796],
              [-39.985541351731726, -8.19749862317803],
              [-39.985541467457629, -8.197498651896481],
              [-39.98554158279488, -8.197498682127193],
              [-39.985541697723541, -8.19749871386496],
              [-39.985541812223801, -8.197498747104309],
              [-39.985541926275864, -8.197498781839487],
              [-39.985542039860078, -8.197498818064533],
              [-39.985542152956818, -8.197498855773155],
              [-39.98554226554657, -8.197498894958891],
              [-39.985542377609903, -8.19749893561494],
              [-39.985542489127468, -8.197498977734258],
              [-39.985542600080016, -8.197499021309616],
              [-39.985542710448414, -8.197499066333517],
              [-39.985542820213581, -8.197499112798136],
              [-39.985542929356605, -8.197499160695486],
              [-39.985543037858626, -8.197499210017289],
              [-39.985543145700923, -8.197499260755034],
              [-39.985543252864879, -8.197499312899973],
              [-39.985543359331992, -8.197499366443108],
              [-39.9855434650839, -8.197499421375161],
              [-39.985543570102351, -8.197499477686648],
              [-39.985543674369197, -8.19749953536793],
              [-39.985543777866461, -8.197499594408981],
              [-39.98554388057628, -8.197499654799611],
              [-39.98554398248092, -8.197499716529418],
              [-39.985544083562772, -8.197499779587734],
              [-39.985544183804421, -8.197499843963712],
              [-39.985544283188553, -8.197499909646176],
              [-39.98554438169802, -8.197499976623853],
              [-39.985544479315806, -8.197500044885137],
              [-39.985544576025077, -8.197500114418267],
              [-39.985544671809123, -8.197500185211252],
              [-39.985544766651429, -8.197500257251832],
              [-39.985544860535605, -8.197500330527619],
              [-39.985544953445469, -8.197500405025933],
              [-39.985545045364965, -8.197500480733925],
              [-39.985545136278255, -8.197500557638545],
              [-39.985545226169613, -8.197500635726486],
              [-39.985545315023536, -8.197500714984281],
              [-39.985545402824698, -8.197500795398286],
              [-39.98554548955795, -8.197500876954569],
              [-39.985545575208292, -8.197500959639108],
              [-39.985545659760966, -8.19750104343758],
              [-39.985545743201371, -8.197501128335549],
              [-39.985545825515089, -8.197501214318351],
              [-39.985545906687946, -8.197501301371153],
              [-39.985545986705901, -8.197501389478933],
              [-39.985546065555162, -8.197501478626465],
              [-39.985546143222109, -8.197501568798369],
              [-39.985546219693347, -8.197501659979103],
              [-39.985546294955661, -8.197501752152904],
              [-39.985546368996069, -8.197501845303853],
              [-39.985546441801787, -8.197501939415906],
              [-39.985546513360276, -8.197502034472768],
              [-39.985546583659136, -8.197502130458084],
              [-39.985546652686267, -8.197502227355271],
              [-39.985546720429745, -8.197502325147607],
              [-39.985546786877897, -8.197502423818198],
              [-39.985546852019219, -8.197502523350009],
              [-39.985546915842498, -8.19750262372591],
              [-39.985546978336686, -8.197502724928526],
              [-39.985547039491024, -8.197502826940404],
              [-39.985547099294955, -8.19750292974393],
              [-39.985547157738154, -8.197503033321368],
              [-39.985547214810502, -8.19750313765484],
              [-39.985547270502202, -8.197503242726329],
              [-39.985547324803605, -8.197503348517712],
              [-39.985547377705352, -8.197503455010738],
              [-39.985547429198313, -8.197503562186993],
              [-39.985547479273578, -8.197503670028027],
              [-39.985547527922527, -8.197503778515172],
              [-39.985547575136763, -8.197503887629736],
              [-39.985547620908136, -8.197503997352854],
              [-39.985547665228729, -8.197504107665639],
              [-39.985547708090913, -8.197504218548994],
              [-39.985547749487282, -8.197504329983836],
              [-39.985547789410703, -8.197504441950874],
              [-39.985547827854248, -8.197504554430822],
              [-39.985547864811323, -8.197504667404267],
              [-39.98554790027552, -8.197504780851686],
              [-39.985547934240735, -8.197504894753536],
              [-39.985547966700921, -8.197505009090392],
              [-39.985547956185513, -8.197505040596541],
              [-39.985888767777297, -8.198736390775785],
              [-39.985888812371904, -8.198736433348245],
              [-39.985888815087364, -8.198736443124261],
              [-39.985888817813674, -8.198736452897286],
              [-39.985888820550855, -8.19873646266727],
              [-39.985888823298886, -8.198736472434225],
              [-39.985888826057774, -8.198736482198161],
              [-39.98588882882752, -8.198736491959032],
              [-39.985888831608101, -8.198736501716869],
              [-39.985888834399539, -8.198736511471589],
              [-39.985888837201806, -8.198736521223237],
              [-39.985888840014923, -8.198736530971827],
              [-39.985888842838861, -8.19873654071727],
              [-39.985888845673642, -8.198736550459591],
              [-39.985888848519252, -8.198736560198801],
              [-39.985888851375677, -8.198736569934836],
              [-39.985888854242923, -8.198736579667758],
              [-39.98588885712099, -8.198736589397489],
              [-39.985888860009865, -8.198736599124009],
              [-39.985888862909547, -8.198736608847385],
              [-39.985888865820051, -8.198736618567521],
              [-39.985888868741341, -8.198736628284459],
              [-39.985888871673431, -8.198736637998138],
              [-39.985888874616336, -8.198736647708607],
              [-39.985888877570019, -8.198736657415814],
              [-39.985888880534482, -8.198736667119743],
              [-39.985888883509737, -8.198736676820378],
              [-39.985888886495779, -8.198736686517755],
              [-39.985888889492585, -8.198736696211833],
              [-39.985888892500171, -8.198736705902588],
              [-39.985888895518535, -8.19873671559003],
              [-39.985888898547657, -8.19873672527412],
              [-39.985888901587558, -8.198736734954892],
              [-39.98588890463818, -8.198736744632262],
              [-39.985888907699589, -8.198736754306276],
              [-39.985888910771727, -8.198736763976923],
              [-39.98588891385463, -8.198736773644143],
              [-39.985888916948269, -8.198736783307947],
              [-39.985888920052652, -8.198736792968376],
              [-39.985888923167764, -8.198736802625342],
              [-39.985888926293619, -8.198736812278877],
              [-39.985888929430196, -8.198736821928986],
              [-39.985888932577488, -8.19873683157558],
              [-39.985888935735517, -8.198736841218743],
              [-39.985888938904253, -8.19873685085836],
              [-39.985888942083704, -8.198736860494515],
              [-39.985888945273864, -8.198736870127156],
              [-39.985888948474738, -8.198736879756234],
              [-39.985888951686306, -8.198736889381799],
              [-39.98588895490856, -8.198736899003851],
              [-39.985888958141516, -8.198736908622305],
              [-39.985888961385172, -8.198736918237193],
              [-39.9858889646395, -8.198736927848502],
              [-39.985888967904515, -8.19873693745618],
              [-39.985888971180202, -8.198736947060294],
              [-39.985888974466569, -8.198736956660779],
              [-39.985888977763615, -8.198736966257593],
              [-39.985888981071298, -8.1987369758508],
              [-39.985888984389661, -8.198736985440373],
              [-39.985888987718688, -8.198736995026229],
              [-39.985888991058346, -8.19873700460842],
              [-39.985888994408668, -8.198737014186912],
              [-39.985888997769635, -8.198737023761726],
              [-39.985889001141238, -8.198737033332826],
              [-39.985889004523486, -8.198737042900172],
              [-39.985889007916356, -8.198737052463789],
              [-39.985889011319856, -8.198737062023657],
              [-39.985889014733978, -8.198737071579774],
              [-39.985889018158723, -8.198737081132125],
              [-39.985889021594083, -8.198737090680696],
              [-39.985889025040059, -8.198737100225449],
              [-39.985889028496644, -8.198737109766403],
              [-39.985889031963829, -8.198737119303541],
              [-39.985889035441602, -8.198737128836827],
              [-39.985889038929983, -8.198737138366301],
              [-39.985889042428958, -8.198737147891903],
              [-39.985889045938507, -8.198737157413621],
              [-39.985889049458635, -8.198737166931481],
              [-39.98588905298935, -8.198737176445448],
              [-39.985889056530638, -8.198737185955519],
              [-39.985889060082478, -8.198737195461673],
              [-39.985889063644905, -8.198737204963891],
              [-39.985889067217883, -8.198737214462209],
              [-39.985889070801406, -8.198737223956559],
              [-39.985889074395502, -8.198737233446925],
              [-39.985889078000135, -8.19873724293334],
              [-39.985889081615305, -8.198737252415768],
              [-39.98588908524102, -8.198737261894216],
              [-39.985889088877258, -8.198737271368627],
              [-39.985889092524026, -8.198737280839053],
              [-39.985889096181324, -8.198737290305408],
              [-39.98588909984916, -8.198737299767748],
              [-39.985889103527484, -8.198737309226052],
              [-39.985889107216323, -8.198737318680253],
              [-39.985889110915679, -8.198737328130402],
              [-39.985889114625529, -8.198737337576466],
              [-39.985889118345888, -8.198737347018429],
              [-39.985889122076735, -8.198737356456304],
              [-39.985889125818048, -8.198737365890025],
              [-39.98588912956987, -8.198737375319631],
              [-39.985889133332179, -8.198737384745085],
              [-39.98588914534276, -8.198737413604951],
              [-39.986115089140306, -8.199295221702238],
              [-39.986115076128876, -8.199295197506949],
              [-39.986115110613653, -8.199295281428869],
              [-39.98611514594127, -8.199295365002108],
              [-39.986115182108151, -8.199295448218207],
              [-39.98611521911068, -8.199295531068842],
              [-39.986115256945105, -8.199295613545683],
              [-39.986115295607647, -8.199295695640435],
              [-39.986115335094418, -8.199295777344833],
              [-39.986115375401454, -8.199295858650688],
              [-39.98611541652469, -8.199295939549787],
              [-39.986115458459984, -8.199296020034035],
              [-39.986115501203145, -8.199296100095301],
              [-39.98611554474985, -8.19929617972557],
              [-39.986115589095739, -8.199296258916814],
              [-39.986115634236356, -8.199296337661107],
              [-39.986115680167146, -8.199296415950492],
              [-39.986115726883504, -8.199296493777117],
              [-39.986115774380728, -8.199296571133136],
              [-39.986115822654043, -8.199296648010804],
              [-39.986115871698608, -8.199296724402375],
              [-39.98611592150948, -8.199296800300184],
              [-39.986115972081642, -8.199296875696596],
              [-39.986116023410041, -8.199296950584024],
              [-39.986116075489463, -8.199297024954932],
              [-39.986116128314741, -8.199297098801836],
              [-39.986116181880512, -8.199297172117358],
              [-39.986116236181424, -8.199297244894078],
              [-39.986116291211999, -8.199297317124715],
              [-39.986116346966696, -8.199297388801993],
              [-39.986116403439937, -8.199297459918713],
              [-39.986116460626015, -8.199297530467694],
              [-39.986116518519196, -8.199297600441872],
              [-39.986116577113677, -8.199297669834225],
              [-39.986116636403537, -8.199297738637743],
              [-39.986116696382837, -8.199297806845509],
              [-39.986116757045522, -8.199297874450709],
              [-39.986116818385526, -8.199297941446495],
              [-39.986116880396658, -8.199298007826179],
              [-39.986116943072687, -8.199298073583053],
              [-39.986117006407326, -8.199298138710516],
              [-39.986117070394201, -8.199298203202023],
              [-39.986117135026866, -8.199298267051098],
              [-39.986117200298835, -8.19929833025131],
              [-39.986117266203557, -8.199298392796315],
              [-39.986117332734381, -8.19929845467979],
              [-39.986117399884641, -8.199298515895574],
              [-39.986117467647567, -8.199298576437464],
              [-39.986117536016366, -8.199298636299384],
              [-39.986117604984145, -8.199298695475326],
              [-39.986117674543983, -8.199298753959354],
              [-39.98611774468889, -8.199298811745566],
              [-39.986117815411816, -8.19929886882815],
              [-39.986117886705635, -8.199298925201383],
              [-39.986117958563185, -8.199298980859586],
              [-39.986118030977245, -8.19929903579717],
              [-39.986118103940548, -8.199299090008603],
              [-39.986118177445725, -8.199299143488455],
              [-39.986118251485422, -8.199299196231312],
              [-39.986118326052178, -8.199299248231924],
              [-39.986118401138491, -8.199299299485027],
              [-39.986118476736841, -8.199299349985466],
              [-39.986118552839592, -8.199299399728195],
              [-39.986118629439105, -8.199299448708183],
              [-39.986118706527698, -8.199299496920526],
              [-39.986118784097584, -8.199299544360347],
              [-39.986118862141012, -8.199299591022907],
              [-39.986118940650087, -8.199299636903481],
              [-39.98611901961695, -8.1992996819975],
              [-39.986119099033658, -8.199299726300408],
              [-39.986119178892217, -8.199299769807759],
              [-39.986119259184605, -8.199299812515168],
              [-39.986119339902743, -8.199299854418356],
              [-39.986119421038516, -8.199299895513102],
              [-39.986119502583783, -8.199299935795249],
              [-39.986119584530329, -8.1992999752608],
              [-39.986119666869925, -8.199300013905761],
              [-39.986119749594273, -8.19930005172624],
              [-39.986119832695088, -8.199300088718438],
              [-39.986119916164, -8.199300124878635],
              [-39.986119999992624, -8.199300160203212],
              [-39.986120084172519, -8.199300194688584],
              [-39.986120168695223, -8.199300228331317],
              [-39.986120253552265, -8.199300261128034],
              [-39.986120338735077, -8.19930029307538],
              [-39.986120424235118, -8.199300324170196],
              [-39.986120510043797, -8.199300354409353],
              [-39.986120596152467, -8.199300383789804],
              [-39.986120682552475, -8.199300412308592],
              [-39.98612076923515, -8.199300439962837],
              [-39.986120856191768, -8.199300466749765],
              [-39.986120943413589, -8.199300492666698],
              [-39.986121030891852, -8.199300517711043],
              [-39.986121118617739, -8.199300541880245],
              [-39.98612120658246, -8.199300565171891],
              [-39.986121294777149, -8.19930058758364],
              [-39.986121383192952, -8.199300609113246],
              [-39.986121471820965, -8.199300629758538],
              [-39.9861215606523, -8.199300649517429],
              [-39.986121649678012, -8.199300668387973],
              [-39.986121738889153, -8.199300686368215],
              [-39.986121729398533, -8.199300722429866],
              [-39.987247452647729, -8.199521721371839],
              [-39.987247482176421, -8.199521728169584],
              [-39.987247535015463, -8.199521738706967],
              [-39.98724758779079, -8.199521749556684],
              [-39.987247640500541, -8.199521760718325],
              [-39.987247693142848, -8.199521772191522],
              [-39.987247745715877, -8.199521783975843],
              [-39.987247798217773, -8.199521796070899],
              [-39.987247850646675, -8.199521808476275],
              [-39.987247903000728, -8.199521821191492],
              [-39.987247955278107, -8.199521834216124],
              [-39.987248007476971, -8.199521847549724],
              [-39.987248059595458, -8.199521861191819],
              [-39.987248111631757, -8.19952187514191],
              [-39.987248163584013, -8.199521889399529],
              [-39.987248215450407, -8.199521903964161],
              [-39.987248267229099, -8.19952191883525],
              [-39.98724831891829, -8.19952193401233],
              [-39.987248370516134, -8.199521949494837],
              [-39.987248422020826, -8.199521965282253],
              [-39.987248473430533, -8.199521981373984],
              [-39.987248524743471, -8.199521997769503],
              [-39.987248575957828, -8.19952201446821],
              [-39.987248627071764, -8.199522031469547],
              [-39.987248678083503, -8.199522048772865],
              [-39.987248728991261, -8.199522066377577],
              [-39.987248779793227, -8.199522084283085],
              [-39.987248830487609, -8.199522102488729],
              [-39.987248881072631, -8.199522120993866],
              [-39.987248931546496, -8.19952213979785],
              [-39.987248981907435, -8.199522158900036],
              [-39.987249032153677, -8.199522178299736],
              [-39.987249082283448, -8.199522197996275],
              [-39.987249132294984, -8.199522217988907],
              [-39.987249182186503, -8.199522238277016],
              [-39.987249231956277, -8.199522258859831],
              [-39.987249281602523, -8.199522279736616],
              [-39.987249331123515, -8.199522300906658],
              [-39.987249380517504, -8.199522322369244],
              [-39.98724942978275, -8.199522344123556],
              [-39.987249478917505, -8.199522366168878],
              [-39.987249527920035, -8.199522388504391],
              [-39.987249576788628, -8.199522411129337],
              [-39.987249625521564, -8.19952243404291],
              [-39.987249674117109, -8.199522457244296],
              [-39.987249722573573, -8.199522480732693],
              [-39.987249770889221, -8.199522504507259],
              [-39.987249819062356, -8.199522528567162],
              [-39.987249867091315, -8.199522552911541],
              [-39.987249914974363, -8.199522577539565],
              [-39.987249962709832, -8.199522602450338],
              [-39.987250010296037, -8.199522627642994],
              [-39.987250057731302, -8.199522653116675],
              [-39.987250105013949, -8.199522678870439],
              [-39.987250152142323, -8.199522704903385],
              [-39.987250199114747, -8.199522731214605],
              [-39.98725024592958, -8.199522757803178],
              [-39.987250292585173, -8.199522784668165],
              [-39.987250339079878, -8.199522811808606],
              [-39.987250385412054, -8.199522839223548],
              [-39.987250431580094, -8.199522866912037],
              [-39.987250477582315, -8.199522894873079],
              [-39.987250523417138, -8.199522923105699],
              [-39.987250569082931, -8.199522951608914],
              [-39.987250614578095, -8.199522980381696],
              [-39.987250659901022, -8.199523009423075],
              [-39.987250705050137, -8.19952303873197],
              [-39.987250750023811, -8.199523068307395],
              [-39.987250794820483, -8.199523098148278],
              [-39.987250839438566, -8.199523128253594],
              [-39.987250883876492, -8.199523158622251],
              [-39.987250928132674, -8.199523189253172],
              [-39.987250972205594, -8.199523220145323],
              [-39.98725101609368, -8.199523251297585],
              [-39.987251059795362, -8.19952328270888],
              [-39.987251103309134, -8.199523314378077],
              [-39.98725114663344, -8.199523346304082],
              [-39.987251189766766, -8.199523378485742],
              [-39.987251232707571, -8.199523410921923],
              [-39.987251275454383, -8.199523443611513],
              [-39.987251318005669, -8.19952347655336],
              [-39.98725136035992, -8.199523509746273],
              [-39.987251402515668, -8.199523543189095],
              [-39.987251444471397, -8.199523576880658],
              [-39.987251486225666, -8.199523610819771],
              [-39.987251527776969, -8.199523645005238],
              [-39.987251569123863, -8.19952367943586],
              [-39.987251610264877, -8.199523714110407],
              [-39.987251651198576, -8.199523749027643],
              [-39.987251691923518, -8.199523784186391],
              [-39.987251732438239, -8.199523819585375],
              [-39.987251772741352, -8.199523855223351],
              [-39.987251812831403, -8.199523891099055],
              [-39.987251852707004, -8.199523927211231],
              [-39.987251892366743, -8.199523963558619],
              [-39.987251931809205, -8.199524000139926],
              [-39.987251971033011, -8.199524036953838],
              [-39.987252010036798, -8.199524073999116],
              [-39.987252048819173, -8.19952411127441],
              [-39.987252087378764, -8.199524148778416],
              [-39.987252125714363, -8.199524186509246],
              [-39.987252088260533, -8.199524151956961],
              [-39.987587329041268, -8.199856067266682],
              [-39.987587336844221, -8.199856079572088],
              [-39.987587400513235, -8.199856143240362],
              [-39.987587463544081, -8.199856207535868],
              [-39.987587525930522, -8.199856272452243],
              [-39.987587587666418, -8.199856337983052],
              [-39.987587648745659, -8.199856404121853],
              [-39.987587709162206, -8.199856470862123],
              [-39.987587768910103, -8.199856538197238],
              [-39.987587827983454, -8.199856606120585],
              [-39.987587886376424, -8.199856674625419],
              [-39.987587944083231, -8.199856743704995],
              [-39.987588001098189, -8.199856813352488],
              [-39.987588057415685, -8.199856883561036],
              [-39.987588113030121, -8.199856954323685],
              [-39.987588167936018, -8.199857025633461],
              [-39.987588222127975, -8.199857097483305],
              [-39.987588275600622, -8.199857169866133],
              [-39.987588328348664, -8.199857242774803],
              [-39.987588380366923, -8.199857316202113],
              [-39.987588431650252, -8.199857390140817],
              [-39.987588482193573, -8.199857464583584],
              [-39.987588531991918, -8.199857539523093],
              [-39.987588581040356, -8.199857614951918],
              [-39.987588629334034, -8.199857690862654],
              [-39.987588676868192, -8.199857767247764],
              [-39.987588723638147, -8.199857844099716],
              [-39.987588769639267, -8.199857921410921],
              [-39.987588814867003, -8.199857999173744],
              [-39.987588859316915, -8.199858077380515],
              [-39.987588902984577, -8.199858156023508],
              [-39.987588945865703, -8.199858235094958],
              [-39.987588987956052, -8.199858314587033],
              [-39.987589029251474, -8.199858394491914],
              [-39.987589069747884, -8.199858474801696],
              [-39.987589109441274, -8.199858555508435],
              [-39.987589148327743, -8.199858636604201],
              [-39.98758918640344, -8.199858718080931],
              [-39.9875892236646, -8.199858799930636],
              [-39.987589260107555, -8.19985888214519],
              [-39.987589295728704, -8.199858964716507],
              [-39.987589330524514, -8.199859047636416],
              [-39.987589364491569, -8.199859130896728],
              [-39.987589397626508, -8.199859214489209],
              [-39.987589429926075, -8.199859298405634],
              [-39.987589461387046, -8.199859382637685],
              [-39.987589492006329, -8.199859467177079],
              [-39.987589521780897, -8.19985955201544],
              [-39.987589550707845, -8.199859637144399],
              [-39.987589578784267, -8.199859722555527],
              [-39.987589606007404, -8.199859808240419],
              [-39.987589632374579, -8.199859894190613],
              [-39.987589657883191, -8.199859980397605],
              [-39.987589682530711, -8.199860066852894],
              [-39.987589706314708, -8.199860153547965],
              [-39.98758972923283, -8.199860240474193],
              [-39.987589751282819, -8.19986032762303],
              [-39.987589772462499, -8.19986041498589],
              [-39.987589792769754, -8.199860502554118],
              [-39.987589812202629, -8.199860590319091],
              [-39.987589830759156, -8.19986067827211],
              [-39.987589848437516, -8.19986076640452],
              [-39.987589865235996, -8.199860854707595],
              [-39.987589881152893, -8.199860943172613],
              [-39.987589896186655, -8.199861031790869],
              [-39.987589910335792, -8.199861120553592],
              [-39.987589923598911, -8.199861209452008],
              [-39.987589935974704, -8.19986129847736],
              [-39.987589947461949, -8.199861387620823],
              [-39.987589958059502, -8.199861476873636],
              [-39.987589967766311, -8.199861566226968],
              [-39.987589976581447, -8.199861655671985],
              [-39.987589984504012, -8.199861745199845],
              [-39.987589991533241, -8.199861834801728],
              [-39.987589997668429, -8.199861924468788],
              [-39.987590002908966, -8.199862014192147],
              [-39.987590007254354, -8.199862103962984],
              [-39.987590010704153, -8.199862193772402],
              [-39.987590013258014, -8.199862283611521],
              [-39.987590014915703, -8.199862373471504],
              [-39.987590015677036, -8.19986246334347],
              [-39.987590015541926, -8.199862553218523],
              [-39.987590014510431, -8.199862643087803],
              [-39.987590012582643, -8.199862732942435],
              [-39.987590009758712, -8.199862822773554],
              [-39.987590006038957, -8.199862912572268],
              [-39.987590001423733, -8.199863002329723],
              [-39.987589995913481, -8.199863092037052],
              [-39.987589989508777, -8.199863181685402],
              [-39.987589982210224, -8.199863271265915],
              [-39.987589974018547, -8.199863360769722],
              [-39.987589964934571, -8.199863450188035],
              [-39.987589954959184, -8.199863539511981],
              [-39.987589944093379, -8.199863628732757],
              [-39.98758993233821, -8.199863717841545],
              [-39.987589919694848, -8.199863806829567],
              [-39.987589906164537, -8.199863895688004],
              [-39.987589891748641, -8.199863984408104],
              [-39.987589876448538, -8.199864072981109],
              [-39.987589860265771, -8.199864161398246],
              [-39.987589843202137, -8.199864249650259],
              [-39.987589800311227, -8.199864291439757],
              [-39.987480412005922, -8.200415261007402],
              [-39.987480444484, -8.200415294266197],
              [-39.987480419686356, -8.200415424153089],
              [-39.987480396797153, -8.200415554387273],
              [-39.987480375821264, -8.200415684940941],
              [-39.987480356763186, -8.20041581578613],
              [-39.987480339626991, -8.200415946894939],
              [-39.987480324416339, -8.200416078239268],
              [-39.987480311134469, -8.200416209791136],
              [-39.987480299784238, -8.200416341522388],
              [-39.987480290368069, -8.200416473404857],
              [-39.987480282887958, -8.200416605410378],
              [-39.987480277345519, -8.200416737510766],
              [-39.98748027374193, -8.200416869677749],
              [-39.987480272077974, -8.200417001883084],
              [-39.987480272353977, -8.200417134098533],
              [-39.987480274569911, -8.200417266295833],
              [-39.987480278725286, -8.200417398446739],
              [-39.987480284819213, -8.200417530523003],
              [-39.9874802928504, -8.200417662496395],
              [-39.987480302817119, -8.2004177943387],
              [-39.98748031471726, -8.200417926021771],
              [-39.987480328548259, -8.200418057517455],
              [-39.987480344307166, -8.200418188797618],
              [-39.98748036199062, -8.200418319834258],
              [-39.987480381594828, -8.200418450599333],
              [-39.987480403115619, -8.200418581064911],
              [-39.987480426548373, -8.200418711203097],
              [-39.987480451888096, -8.200418840986115],
              [-39.987480479129381, -8.200418970386179],
              [-39.98748050826638, -8.200419099375695],
              [-39.987480539292875, -8.20041922792703],
              [-39.987480572202259, -8.200419356012743],
              [-39.987480606987475, -8.200419483605462],
              [-39.987480643641078, -8.200419610677912],
              [-39.987480682155272, -8.200419737202937],
              [-39.987480722521795, -8.200419863153527],
              [-39.987480764732034, -8.200419988502707],
              [-39.987480808776965, -8.200420113223737],
              [-39.987480854647167, -8.200420237289935],
              [-39.987480902332855, -8.200420360674789],
              [-39.987480951823827, -8.200420483351955],
              [-39.987481003109494, -8.200420605295182],
              [-39.987481056178929, -8.20042072647845],
              [-39.987481111020756, -8.200420846875817],
              [-39.987481167623272, -8.200420966461589],
              [-39.987481225974385, -8.200421085210191],
              [-39.987481286061609, -8.20042120309625],
              [-39.987481347872119, -8.200421320094565],
              [-39.987481411392679, -8.200421436180127],
              [-39.987481476609744, -8.200421551328146],
              [-39.987481543509361, -8.200421665514009],
              [-39.987481612077239, -8.200421778713299],
              [-39.987481682298714, -8.20042189090182],
              [-39.987481754158793, -8.200422002055634],
              [-39.987481827642121, -8.200422112150944],
              [-39.987481902732974, -8.200422221164251],
              [-39.987481979415307, -8.200422329072211],
              [-39.987482057672757, -8.20042243585184],
              [-39.98748213748857, -8.200422541480245],
              [-39.987482218845706, -8.200422645934898],
              [-39.987482301726772, -8.200422749193436],
              [-39.987482386114067, -8.200422851233871],
              [-39.987482471989523, -8.200422952034293],
              [-39.987482559334829, -8.200423051573196],
              [-39.987482648131291, -8.200423149829343],
              [-39.987482738359937, -8.200423246781698],
              [-39.98748283000149, -8.200423342409557],
              [-39.98748292303636, -8.200423436692482],
              [-39.987483017444681, -8.200423529610344],
              [-39.987483113206252, -8.200423621143241],
              [-39.987483210300624, -8.200423711271634],
              [-39.987483308707034, -8.200423799976251],
              [-39.987483408404458, -8.200423887238157],
              [-39.987483509371607, -8.20042397303869],
              [-39.987483611586867, -8.200424057359506],
              [-39.987483715028411, -8.200424140182617],
              [-39.987483819674154, -8.200424221490252],
              [-39.987483925501699, -8.200424301265118],
              [-39.987484032488446, -8.200424379490098],
              [-39.987484140611521, -8.20042445614853],
              [-39.987484249847824, -8.200424531224003],
              [-39.987484360174022, -8.200424604700487],
              [-39.987484471566525, -8.200424676562243],
              [-39.987484584001507, -8.20042474679396],
              [-39.98748469745496, -8.200424815380588],
              [-39.987484811902647, -8.200424882307534],
              [-39.987484927320097, -8.200424947560419],
              [-39.987485043682661, -8.20042501112532],
              [-39.98748516096542, -8.200425072988667],
              [-39.987485279143371, -8.200425133137246],
              [-39.987485398191211, -8.200425191558173],
              [-39.987485518083531, -8.200425248238989],
              [-39.987485638794702, -8.200425303167574],
              [-39.987485760298931, -8.200425356332165],
              [-39.987485882570212, -8.200425407721443],
              [-39.987486005582461, -8.2004254573244],
              [-39.987486129309367, -8.200425505130438],
              [-39.987486253724505, -8.200425551129349],
              [-39.98748637880125, -8.200425595311302],
              [-39.987486504512908, -8.200425637666818],
              [-39.987486519693043, -8.200425646882945],
              [-39.988828658682017, -8.200866970176824],
              [-39.988828632631304, -8.200866967495202],
              [-39.988828788178729, -8.200867017101835],
              [-39.988828944596754, -8.200867063912325],
              [-39.988829101834739, -8.200867107911519],
              [-39.9888292598418, -8.200867149085209],
              [-39.98882941856678, -8.200867187420021],
              [-39.988829577958306, -8.200867222903589],
              [-39.988829737964778, -8.20086725552439],
              [-39.988829898534391, -8.200867285271892],
              [-39.988830059615196, -8.200867312136435],
              [-39.988830221155041, -8.200867336109336],
              [-39.988830383101636, -8.200867357182876],
              [-39.988830545402536, -8.200867375350189],
              [-39.988830708005239, -8.200867390605387],
              [-39.988830870857086, -8.200867402943567],
              [-39.988831033905377, -8.200867412360697],
              [-39.988831197097333, -8.200867418853772],
              [-39.988831360380139, -8.200867422420643],
              [-39.98883152370091, -8.200867423060203],
              [-39.988831687006794, -8.200867420772203],
              [-39.988831850244928, -8.200867415557397],
              [-39.988832013362497, -8.200867407417503],
              [-39.988832176306651, -8.200867396355086],
              [-39.98883233902469, -8.200867382373801],
              [-39.988832501463925, -8.200867365478121],
              [-39.98883266357177, -8.200867345673572],
              [-39.988832825295766, -8.200867322966525],
              [-39.988832986583546, -8.20086729736434],
              [-39.988833147382906, -8.200867268875291],
              [-39.988833307641812, -8.200867237508614],
              [-39.988833467308361, -8.200867203274452],
              [-39.988833626330894, -8.200867166183885],
              [-39.98883378465792, -8.200867126248928],
              [-39.988833942238202, -8.200867083482498],
              [-39.988834099020721, -8.200867037898446],
              [-39.988834254954725, -8.200866989511537],
              [-39.988834409989742, -8.200866938337434],
              [-39.988834564075596, -8.200866884392674],
              [-39.988834717162391, -8.200866827694751],
              [-39.988834869200595, -8.200866768261999],
              [-39.988835020140975, -8.200866706113676],
              [-39.988835169934688, -8.200866641269879],
              [-39.988835318533241, -8.2008665737516],
              [-39.988835465888521, -8.200866503580688],
              [-39.988835611952837, -8.200866430779888],
              [-39.988835756678924, -8.200866355372746],
              [-39.988835900019922, -8.20086627738365],
              [-39.988836041929417, -8.20086619683787],
              [-39.988836182361482, -8.200866113761474],
              [-39.988836321270675, -8.200866028181347],
              [-39.988836458611999, -8.200865940125203],
              [-39.988836594341045, -8.200865849621524],
              [-39.988836728413851, -8.200865756699644],
              [-39.988836860787004, -8.200865661389606],
              [-39.988836991417671, -8.200865563722282],
              [-39.988837120263561, -8.200865463729283],
              [-39.988837247282987, -8.200865361442995],
              [-39.988837372434809, -8.200865256896471],
              [-39.988837495678517, -8.200865150123581],
              [-39.988837616974216, -8.200865041158915],
              [-39.988837736282662, -8.200864930037703],
              [-39.988837853565222, -8.200864816795942],
              [-39.988837968783947, -8.200864701470314],
              [-39.988838081901513, -8.20086458409809],
              [-39.988838192881332, -8.200864464717297],
              [-39.988838301687451, -8.200864343366566],
              [-39.988838408284678, -8.200864220085171],
              [-39.988838512638488, -8.200864094913031],
              [-39.988838614715107, -8.200863967890683],
              [-39.988838714481503, -8.20086383905921],
              [-39.988838811905367, -8.200863708460332],
              [-39.988838906955174, -8.200863576136292],
              [-39.988838999600155, -8.200863442129975],
              [-39.988839089810305, -8.200863306484749],
              [-39.98883917755645, -8.200863169244508],
              [-39.988839262810174, -8.200863030453661],
              [-39.98883934554388, -8.200862890157156],
              [-39.988839425730774, -8.200862748400386],
              [-39.98883950334492, -8.200862605229261],
              [-39.988839578361194, -8.200862460690123],
              [-39.98883965075531, -8.200862314829742],
              [-39.988839720503819, -8.200862167695353],
              [-39.988839787584169, -8.200862019334558],
              [-39.988839851974625, -8.200861869795389],
              [-39.98883991365436, -8.200861719126289],
              [-39.98883997260338, -8.200861567375965],
              [-39.988840028802642, -8.200861414593588],
              [-39.988840082233914, -8.200861260828599],
              [-39.988840132879957, -8.200861106130771],
              [-39.988840180724317, -8.200860950550172],
              [-39.988840225751545, -8.200860794137148],
              [-39.98884026794704, -8.200860636942368],
              [-39.988840307297174, -8.200860479016709],
              [-39.988840343789178, -8.200860320411268],
              [-39.988840377411272, -8.20086016117741],
              [-39.988840408152527, -8.200860001366635],
              [-39.988840436003052, -8.200859841030733],
              [-39.988840460953753, -8.200859680221566],
              [-39.988840482996636, -8.200859518991198],
              [-39.988840502124525, -8.20085935739181],
              [-39.988840531633649, -8.200859343925401],
              [-39.988949626768679, -8.199859872990254],
              [-39.988949664420936, -8.199859890792871],
              [-39.98894967250105, -8.199859819447299],
              [-39.988949681149407, -8.199859748167871],
              [-39.988949690365445, -8.199859676959081],
              [-39.988949700148574, -8.199859605825436],
              [-39.988949710498183, -8.199859534771411],
              [-39.988949721413611, -8.199859463801479],
              [-39.988949732894199, -8.199859392920136],
              [-39.988949744939198, -8.199859322131822],
              [-39.98894975754785, -8.199859251441001],
              [-39.988949770719358, -8.199859180852132],
              [-39.98894978445292, -8.199859110369667],
              [-39.988949798747655, -8.199859039998056],
              [-39.98894981360263, -8.19985896974171],
              [-39.988949829016946, -8.199858899605081],
              [-39.988949844989634, -8.199858829592584],
              [-39.988949861519664, -8.199858759708608],
              [-39.988949878606007, -8.199858689957576],
              [-39.988949896247583, -8.19985862034388],
              [-39.988949914443289, -8.199858550871914],
              [-39.988949933191968, -8.199858481546045],
              [-39.988949952492447, -8.199858412370654],
              [-39.988949972343491, -8.199858343350092],
              [-39.988949992743869, -8.199858274488712],
              [-39.988950013692282, -8.199858205790875],
              [-39.988950035187429, -8.199858137260867],
              [-39.988950057227932, -8.199858068903053],
              [-39.988950079812419, -8.199858000721711],
              [-39.988950102939455, -8.19985793272113],
              [-39.988950126607584, -8.199857864905606],
              [-39.98895015081532, -8.199857797279458],
              [-39.988950175561143, -8.19985772984688],
              [-39.98895020084349, -8.199857662612139],
              [-39.988950226660748, -8.199857595579484],
              [-39.988950253011318, -8.199857528753139],
              [-39.98895027989353, -8.199857462137308],
              [-39.988950307305672, -8.19985739573621],
              [-39.988950335246031, -8.19985732955401],
              [-39.98895036371286, -8.199857263594897],
              [-39.988950392704339, -8.199857197862993],
              [-39.988950422218664, -8.199857132362451],
              [-39.988950452253967, -8.199857067097421],
              [-39.988950482808349, -8.199857002072031],
              [-39.988950513879871, -8.199856937290335],
              [-39.988950545466615, -8.199856872756438],
              [-39.988950577566555, -8.199856808474399],
              [-39.988950610177667, -8.199856744448276],
              [-39.988950643297926, -8.199856680682105],
              [-39.988950676925228, -8.199856617179902],
              [-39.988950711057441, -8.199856553945652],
              [-39.988950745692428, -8.199856490983363],
              [-39.988950780828027, -8.199856428296998],
              [-39.98895081646198, -8.199856365890508],
              [-39.988950852592076, -8.199856303767833],
              [-39.988950889215999, -8.199856241932856],
              [-39.988950926331484, -8.199856180389475],
              [-39.988950963936169, -8.199856119141641],
              [-39.98895100202769, -8.199856058193145],
              [-39.988951040603645, -8.199855997547834],
              [-39.988951079661604, -8.199855937209573],
              [-39.988951119199108, -8.19985587718211],
              [-39.988951159213663, -8.199855817469238],
              [-39.98895119970274, -8.199855758074754],
              [-39.988951240663795, -8.199855699002379],
              [-39.988951282094249, -8.199855640255826],
              [-39.988951323991486, -8.199855581838818],
              [-39.988951366352858, -8.199855523755033],
              [-39.988951409175733, -8.199855466008151],
              [-39.98895145245735, -8.199855408601747],
              [-39.988951496195028, -8.199855351539483],
              [-39.98895154038599, -8.199855294824959],
              [-39.988951585027465, -8.19985523846173],
              [-39.988951630116645, -8.199855182453396],
              [-39.988951675650661, -8.199855126803429],
              [-39.988951721626655, -8.199855071515328],
              [-39.988951768041744, -8.199855016592636],
              [-39.988951814892985, -8.199854962038797],
              [-39.988951862177444, -8.199854907857206],
              [-39.988951909892116, -8.199854854051354],
              [-39.988951958034022, -8.199854800624536],
              [-39.988952006600087, -8.199854747580209],
              [-39.988952055587305, -8.199854694921621],
              [-39.988952104992542, -8.199854642652198],
              [-39.988952154812701, -8.199854590775159],
              [-39.988952205044647, -8.199854539293822],
              [-39.98895225568522, -8.199854488211372],
              [-39.988952306731221, -8.199854437531071],
              [-39.988952358179425, -8.199854387256112],
              [-39.988952410026606, -8.199854337389651],
              [-39.988952462269467, -8.199854287934862],
              [-39.988952514904753, -8.199854238894822],
              [-39.988952567929118, -8.199854190272605],
              [-39.98895262133923, -8.199854142071317],
              [-39.988952675131735, -8.199854094293967],
              [-39.988952729303229, -8.199854046943592],
              [-39.988952783850301, -8.199854000023187],
              [-39.988952838769514, -8.199853953535651],
              [-39.988952894057412, -8.199853907484018],
              [-39.988952949710487, -8.199853861871082],
              [-39.988953005725108, -8.199853816700061],
              [-39.988952970517985, -8.199853805173756],
              [-39.989512773675514, -8.199406100412139],
              [-39.989512739559565, -8.199406088550811],
              [-39.989512787856704, -8.199406050186635],
              [-39.989512836414747, -8.199406012150792],
              [-39.989512885231441, -8.199405974445019],
              [-39.989512934304514, -8.199405937071008],
              [-39.989512983631684, -8.199405900030593],
              [-39.989513033210699, -8.199405863325408],
              [-39.989513083039249, -8.19940582695722],
              [-39.989513133115004, -8.199405790927642],
              [-39.989513183435697, -8.199405755238407],
              [-39.989513233998956, -8.199405719891143],
              [-39.989513284802456, -8.199405684887468],
              [-39.989513335843831, -8.199405650229011],
              [-39.989513387120731, -8.199405615917378],
              [-39.989513438630773, -8.199405581954155],
              [-39.989513490371593, -8.199405548340945],
              [-39.989513542340774, -8.199405515079278],
              [-39.989513594535907, -8.199405482170656],
              [-39.989513646954599, -8.199405449616677],
              [-39.989513699594397, -8.199405417418811],
              [-39.989513752452872, -8.199405385578554],
              [-39.989513805527594, -8.199405354097395],
              [-39.989513858816082, -8.199405322976776],
              [-39.989513912315871, -8.199405292218113],
              [-39.989513966024504, -8.199405261822864],
              [-39.98951401993947, -8.199405231792433],
              [-39.989514074058278, -8.199405202128204],
              [-39.989514128378445, -8.199405172831554],
              [-39.98951418289743, -8.19940514390383],
              [-39.98951423761271, -8.199405115346377],
              [-39.989514292521775, -8.199405087160487],
              [-39.98951434762207, -8.199405059347525],
              [-39.989514402911027, -8.199405031908755],
              [-39.989514458386104, -8.199405004845453],
              [-39.989514514044714, -8.199404978158844],
              [-39.989514569884314, -8.199404951850193],
              [-39.989514625902288, -8.199404925920673],
              [-39.989514682096065, -8.199404900371531],
              [-39.989514738463008, -8.199404875203932],
              [-39.989514795000566, -8.199404850419077],
              [-39.989514851706062, -8.199404826018069],
              [-39.989514908576901, -8.199404802002023],
              [-39.989514965610439, -8.199404778372115],
              [-39.989515022804042, -8.19940475512939],
              [-39.989515080155059, -8.199404732274935],
              [-39.989515137660831, -8.199404709809816],
              [-39.98951519531871, -8.199404687735068],
              [-39.989515253126008, -8.199404666051709],
              [-39.989515311080041, -8.199404644760724],
              [-39.989515369178164, -8.199404623863142],
              [-39.989515427417658, -8.1994046033599],
              [-39.989515485795827, -8.199404583251969],
              [-39.989515544309981, -8.199404563540254],
              [-39.989515602957404, -8.199404544225681],
              [-39.989515661735368, -8.199404525309149],
              [-39.98951572064118, -8.199404506791517],
              [-39.989515779672089, -8.199404488673673],
              [-39.989515838825369, -8.199404470956436],
              [-39.989515898098283, -8.199404453640645],
              [-39.989515957488074, -8.199404436727056],
              [-39.98951601699202, -8.199404420216498],
              [-39.989516076607345, -8.199404404109718],
              [-39.989516136331275, -8.199404388407466],
              [-39.989516196161077, -8.199404373110458],
              [-39.989516256093964, -8.199404358219436],
              [-39.989516316127165, -8.199404343735022],
              [-39.989516376257917, -8.199404329657938],
              [-39.989516436483378, -8.199404315988838],
              [-39.989516496800817, -8.199404302728313],
              [-39.98951655720743, -8.199404289877034],
              [-39.98951661770041, -8.199404277435574],
              [-39.989516678276956, -8.199404265404478],
              [-39.989516738934277, -8.199404253784351],
              [-39.989516799669552, -8.199404242575698],
              [-39.989516860479959, -8.199404231779054],
              [-39.989516921362707, -8.199404221394904],
              [-39.989516982314967, -8.199404211423753],
              [-39.989517043333905, -8.199404201866027],
              [-39.98951710441672, -8.199404192722184],
              [-39.989517165560557, -8.199404183992691],
              [-39.989517226762601, -8.19940417567787],
              [-39.989517288020004, -8.199404167778187],
              [-39.989517349329958, -8.199404160293939],
              [-39.989517410689601, -8.199404153225522],
              [-39.98951747209609, -8.199404146573249],
              [-39.989517533546604, -8.199404140337416],
              [-39.989517595038272, -8.199404134518321],
              [-39.98951765656826, -8.199404129116243],
              [-39.989517718133719, -8.199404124131402],
              [-39.989517779731806, -8.199404119564079],
              [-39.989517841359635, -8.199404115414433],
              [-39.989517903014409, -8.199404111682686],
              [-39.989517964693228, -8.199404108369004],
              [-39.989518026393242, -8.199404105473521],
              [-39.989518088111616, -8.199404102996397],
              [-39.989518149845473, -8.199404100937745],
              [-39.989518211591971, -8.199404099297652],
              [-39.989518273348224, -8.199404098076213],
              [-39.989518335111406, -8.199404097273444],
              [-39.989518396879042, -8.199404096888184],
              [-39.990188960396992, -8.199402450687817],
              [-39.99018896534038, -8.199402450675658],
              [-39.990189070968526, -8.199402451028568],
              [-39.99018917658541, -8.199402452605993],
              [-39.990189282176715, -8.199402455407757],
              [-39.990189387728158, -8.199402459433443],
              [-39.990189493225429, -8.199402464682523],
              [-39.990189598654268, -8.19940247115426],
              [-39.990189704000372, -8.199402478847809],
              [-39.990189809249493, -8.199402487762109],
              [-39.990189914387379, -8.199402497895983],
              [-39.990190019399797, -8.199402509248037],
              [-39.990190124272523, -8.199402521816717],
              [-39.990190228991352, -8.199402535600379],
              [-39.990190333542131, -8.19940255059708],
              [-39.99019043791067, -8.199402566804842],
              [-39.990190542082843, -8.199402584221451],
              [-39.990190646044582, -8.199402602844552],
              [-39.990190749781775, -8.199402622671638],
              [-39.990190853280367, -8.199402643699996],
              [-39.990190956526376, -8.199402665926797],
              [-39.990191059505804, -8.199402689349009],
              [-39.990191162204724, -8.199402713963501],
              [-39.990191264609201, -8.199402739766917],
              [-39.990191366705403, -8.199402766755757],
              [-39.990191468479466, -8.199402794926399],
              [-39.990191569917648, -8.199402824274999],
              [-39.990191671006215, -8.199402854797587],
              [-39.990191771731446, -8.199402886490013],
              [-39.990191872079727, -8.199402919348019],
              [-39.990191972037465, -8.199402953367136],
              [-39.990192071591132, -8.19940298854277],
              [-39.990192170727234, -8.199403024870165],
              [-39.990192269432356, -8.199403062344388],
              [-39.99019236769314, -8.19940310096035],
              [-39.990192465496271, -8.199403140712841],
              [-39.990192562828526, -8.199403181596477],
              [-39.990192659676694, -8.199403223605723],
              [-39.990192756027675, -8.199403266734883],
              [-39.990192851868436, -8.199403310978118],
              [-39.990192947185989, -8.199403356329457],
              [-39.990193041967444, -8.199403402782748],
              [-39.990193136199935, -8.199403450331694],
              [-39.990193229870734, -8.19940349896987],
              [-39.990193322967137, -8.199403548690684],
              [-39.990193415476554, -8.199403599487386],
              [-39.990193507386451, -8.199403651353109],
              [-39.990193598684378, -8.199403704280847],
              [-39.990193689357987, -8.199403758263418],
              [-39.990193779394986, -8.199403813293525],
              [-39.990193868783216, -8.199403869363699],
              [-39.990193957510535, -8.199403926466376],
              [-39.990194045564941, -8.199403984593797],
              [-39.990194132934519, -8.199404043738099],
              [-39.990194219607439, -8.199404103891286],
              [-39.990194305571961, -8.199404165045184],
              [-39.990194390816441, -8.199404227191549],
              [-39.990194475329339, -8.199404290321938],
              [-39.990194559099223, -8.199404354427825],
              [-39.990194642114737, -8.1994044195005],
              [-39.990194724364656, -8.199404485531192],
              [-39.990194805837824, -8.19940455251094],
              [-39.99019488652322, -8.199404620430682],
              [-39.990194966409916, -8.199404689281211],
              [-39.990195045487106, -8.199404759053188],
              [-39.990195123744073, -8.199404829737199],
              [-39.990195201170224, -8.199404901323657],
              [-39.990195277755056, -8.199404973802885],
              [-39.99019535348824, -8.199405047165069],
              [-39.990195428359492, -8.199405121400224],
              [-39.990195502358674, -8.199405196498354],
              [-39.990195575475795, -8.199405272449294],
              [-39.990195647700908, -8.199405349242722],
              [-39.990195719024285, -8.199405426868255],
              [-39.990195789436221, -8.199405505315417],
              [-39.990195858927237, -8.199405584573537],
              [-39.990195927487861, -8.199405664631907],
              [-39.990195995108849, -8.199405745479678],
              [-39.990196061781049, -8.199405827105917],
              [-39.990196127495402, -8.199405909499568],
              [-39.990196192243047, -8.199405992649456],
              [-39.990196256015189, -8.199406076544316],
              [-39.990196318803221, -8.199406161172844],
              [-39.990196380598597, -8.199406246523562],
              [-39.990196441393003, -8.199406332584861],
              [-39.990196501178175, -8.199406419345134],
              [-39.990196559946028, -8.199406506792636],
              [-39.990196617688603, -8.199406594915519],
              [-39.990196674398078, -8.199406683701829],
              [-39.990196730066756, -8.199406773139554],
              [-39.990196784687157, -8.199406863216634],
              [-39.990196838251833, -8.199406953920809],
              [-39.990196890753545, -8.199407045239814],
              [-39.990196942185193, -8.199407137161298],
              [-39.990196992539794, -8.19940722967282],
              [-39.990197041810553, -8.199407322761823],
              [-39.990197089990801, -8.199407416415726],
              [-39.990197137073991, -8.199407510621857],
              [-39.990197183053752, -8.199407605367432],
              [-39.990197227923872, -8.199407700639645],
              [-39.990197271678966, -8.199407796424389],
              [-39.990197234228425, -8.199407766445905],
              [-39.990648335124106, -8.200410654654979],
              [-39.99064833914138, -8.200410693429998],
              [-39.99064837812606, -8.200410778915753],
              [-39.990648417999402, -8.200410863993691],
              [-39.990648458757136, -8.200410948654605],
              [-39.990648500394869, -8.200411032889393],
              [-39.990648542908097, -8.200411116688933],
              [-39.990648586292238, -8.200411200044242],
              [-39.990648630542637, -8.2004112829463],
              [-39.990648675654484, -8.200411365386195],
              [-39.990648721622961, -8.200411447355037],
              [-39.990648768443094, -8.200411528843967],
              [-39.990648816109825, -8.200411609844256],
              [-39.990648864618052, -8.200411690347131],
              [-39.990648913962509, -8.200411770343935],
              [-39.990648964137883, -8.200411849826002],
              [-39.990649015138786, -8.200411928784826],
              [-39.990649066959719, -8.200412007211842],
              [-39.990649119595055, -8.200412085098659],
              [-39.990649173039188, -8.200412162436818],
              [-39.990649227286298, -8.200412239218041],
              [-39.990649282330573, -8.200412315434008],
              [-39.990649338166058, -8.200412391076494],
              [-39.990649394786757, -8.200412466137395],
              [-39.990649452186553, -8.200412540608591],
              [-39.990649510359269, -8.200412614482074],
              [-39.990649569298625, -8.200412687749841],
              [-39.990649628998263, -8.200412760404031],
              [-39.990649689451772, -8.200412832436815],
              [-39.990649750652615, -8.200412903840403],
              [-39.990649812594192, -8.200412974607154],
              [-39.990649875269845, -8.200413044729347],
              [-39.990649938672817, -8.200413114199504],
              [-39.990650002796258, -8.200413183010104],
              [-39.990650067633254, -8.200413251153735],
              [-39.99065013317685, -8.200413318623069],
              [-39.990650199419946, -8.200413385410791],
              [-39.990650266355416, -8.200413451509748],
              [-39.990650333976049, -8.200413516912793],
              [-39.990650402274561, -8.200413581612889],
              [-39.990650471243555, -8.200413645603041],
              [-39.990650540875642, -8.200413708876351],
              [-39.990650611163304, -8.200413771426044],
              [-39.990650682098966, -8.200413833245332],
              [-39.990650753674949, -8.20041389432755],
              [-39.990650825883577, -8.200413954666175],
              [-39.990650898717078, -8.20041401425466],
              [-39.990650972167558, -8.200414073086559],
              [-39.990651046227121, -8.200414131155545],
              [-39.990651120887783, -8.200414188455371],
              [-39.990651196141506, -8.200414244979891],
              [-39.99065127198017, -8.200414300722976],
              [-39.990651348395609, -8.200414355678598],
              [-39.990651425379561, -8.200414409840858],
              [-39.990651502923768, -8.200414463203929],
              [-39.990651581019847, -8.200414515762057],
              [-39.99065165965937, -8.200414567509545],
              [-39.99065173883389, -8.200414618440862],
              [-39.99065181853485, -8.200414668550494],
              [-39.990651898753676, -8.200414717833006],
              [-39.990651979481719, -8.200414766283172],
              [-39.990652060710261, -8.200414813895696],
              [-39.990652142430569, -8.200414860665456],
              [-39.990652224633813, -8.20041490658741],
              [-39.990652307311159, -8.200414951656645],
              [-39.990652390453675, -8.200414995868289],
              [-39.990652474052396, -8.200415039217555],
              [-39.990652558098333, -8.200415081699784],
              [-39.990652642582404, -8.200415123310417],
              [-39.990652727495529, -8.200415164044925],
              [-39.990652812828529, -8.200415203898951],
              [-39.990652898572222, -8.200415242868178],
              [-39.990652984717364, -8.200415280948425],
              [-39.990653071254663, -8.200415318135576],
              [-39.990653158174801, -8.20041535442563],
              [-39.990653245468401, -8.200415389814671],
              [-39.990653333126076, -8.200415424298864],
              [-39.990653421138347, -8.200415457874541],
              [-39.990653509495729, -8.200415490538042],
              [-39.990653598188715, -8.200415522285898],
              [-39.990653687207761, -8.200415553114622],
              [-39.99065377654324, -8.200415583020899],
              [-39.990653866185525, -8.200415612001562],
              [-39.990653956124987, -8.200415640053444],
              [-39.990654046351885, -8.200415667173536],
              [-39.990654136856527, -8.200415693358869],
              [-39.990654227629165, -8.200415718606678],
              [-39.990654318659978, -8.200415742914215],
              [-39.990654409939182, -8.200415766278876],
              [-39.990654501456923, -8.200415788698137],
              [-39.990654593203345, -8.200415810169604],
              [-39.990654685168593, -8.200415830690917],
              [-39.990654777342684, -8.200415850259844],
              [-39.990654869715733, -8.200415868874364],
              [-39.99065496227778, -8.200415886532435],
              [-39.990655055018841, -8.200415903232081],
              [-39.990655147928898, -8.200415918971608],
              [-39.990655240997974, -8.200415933749257],
              [-39.990655334216015, -8.200415947563467],
              [-39.990655427572975, -8.200415960412748],
              [-39.990655521058798, -8.200415972295675],
              [-39.990655519666539, -8.200415973076893],
              [-39.991553937704921, -8.200525460248754],
              [-39.991553934603871, -8.200525449128632],
              [-39.991553993124434, -8.200525456067636],
              [-39.991554051689022, -8.200525462627949],
              [-39.991554110295098, -8.20052546880931],
              [-39.991554168940219, -8.200525474611483],
              [-39.991554227621926, -8.200525480034234],
              [-39.991554286337717, -8.200525485077275],
              [-39.991554345085135, -8.200525489740418],
              [-39.991554403861706, -8.2005254940235],
              [-39.991554462664936, -8.20052549792632],
              [-39.991554521492375, -8.200525501448691],
              [-39.991554580341521, -8.200525504590473],
              [-39.991554639209888, -8.200525507351557],
              [-39.991554698095023, -8.200525509731831],
              [-39.991554756994418, -8.200525511731161],
              [-39.991554815905594, -8.200525513349453],
              [-39.991554874826093, -8.200525514586682],
              [-39.991554933753392, -8.200525515442802],
              [-39.991554992685039, -8.200525515917702],
              [-39.991555051618526, -8.200525516011458],
              [-39.991555110551403, -8.200525515724022],
              [-39.991555169481153, -8.200525515055384],
              [-39.991555228405296, -8.200525514005617],
              [-39.991555287321376, -8.200525512574712],
              [-39.991555346226875, -8.200525510762775],
              [-39.991555405119321, -8.20052550856988],
              [-39.991555463996256, -8.200525505996065],
              [-39.991555522855158, -8.200525503041506],
              [-39.991555581693582, -8.200525499706263],
              [-39.991555640509027, -8.200525495990574],
              [-39.991555699299028, -8.200525491894474],
              [-39.991555758061097, -8.200525487418249],
              [-39.991555816792747, -8.200525482562018],
              [-39.991555875491542, -8.200525477325989],
              [-39.991555934154952, -8.200525471710403],
              [-39.991555992780548, -8.20052546571552],
              [-39.991556051365848, -8.200525459341517],
              [-39.991556109908359, -8.20052545258873],
              [-39.991556168405666, -8.200525445457442],
              [-39.991556226855238, -8.200525437947892],
              [-39.99155628525466, -8.200525430060493],
              [-39.991556343601466, -8.200525421795462],
              [-39.99155640189317, -8.200525413153285],
              [-39.991556460127342, -8.200525404134201],
              [-39.991556518301515, -8.200525394738655],
              [-39.991556576413245, -8.200525384967021],
              [-39.991556634460061, -8.200525374819696],
              [-39.991556692439552, -8.200525364297139],
              [-39.991556750349226, -8.200525353399811],
              [-39.991556808186701, -8.200525342128104],
              [-39.991556865949484, -8.200525330482547],
              [-39.99155692363518, -8.200525318463638],
              [-39.991556981241338, -8.200525306071849],
              [-39.991557038765549, -8.200525293307731],
              [-39.991557096205355, -8.200525280171792],
              [-39.991557153558368, -8.200525266664574],
              [-39.991557210822151, -8.200525252786713],
              [-39.991557267994303, -8.200525238538731],
              [-39.991557325072392, -8.200525223921263],
              [-39.991557382054033, -8.200525208934883],
              [-39.99155743893683, -8.200525193580269],
              [-39.991557495718375, -8.200525177858092],
              [-39.991557552396266, -8.200525161768923],
              [-39.99155760896813, -8.200525145313515],
              [-39.991557665431571, -8.200525128492526],
              [-39.991557721784218, -8.20052511130667],
              [-39.99155777802369, -8.20052509375668],
              [-39.991557834147613, -8.200525075843302],
              [-39.991557890153636, -8.200525057567301],
              [-39.991557946039379, -8.200525038929383],
              [-39.99155800180251, -8.200525019930408],
              [-39.991558057440656, -8.200525000571165],
              [-39.991558112951473, -8.200524980852437],
              [-39.991558168332659, -8.200524960775057],
              [-39.991558223581812, -8.200524940339919],
              [-39.991558278696665, -8.200524919547824],
              [-39.991558333674867, -8.200524898399721],
              [-39.991558388514115, -8.200524876896408],
              [-39.991558443212078, -8.200524855038866],
              [-39.991558497766469, -8.200524832827959],
              [-39.991558552174972, -8.200524810264676],
              [-39.991558606435298, -8.200524787349941],
              [-39.991558660545181, -8.200524764084738],
              [-39.991558714502304, -8.200524740470003],
              [-39.991558768304422, -8.200524716506751],
              [-39.991558821949262, -8.200524692196012],
              [-39.991558875434563, -8.200524667538847],
              [-39.991558928758053, -8.200524642536204],
              [-39.9915589819175, -8.200524617189213],
              [-39.991559034910658, -8.20052459149891],
              [-39.991559087735318, -8.200524565466401],
              [-39.991559140389207, -8.200524539092722],
              [-39.991559192870156, -8.200524512379047],
              [-39.9915592451759, -8.200524485326456],
              [-39.991559297304292, -8.200524457936135],
              [-39.99155934925308, -8.200524430209196],
              [-39.991559401020119, -8.200524402146845],
              [-39.991559452603205, -8.200524373750232],
              [-39.991559504000151, -8.200524345020597],
              [-39.991559555208831, -8.200524315959099],
              [-39.991559563150794, -8.200524361109585],
              [-39.992341949189907, -8.200076919163413],
              [-39.992341947870884, -8.20007694053249],
              [-39.992342059661119, -8.200076875550307],
              [-39.992342170510085, -8.200076808986797],
              [-39.992342280395263, -8.200076740855453],
              [-39.992342389294308, -8.200076671170146],
              [-39.992342497185064, -8.200076599945065],
              [-39.992342604045646, -8.200076527194652],
              [-39.992342709854292, -8.200076452933716],
              [-39.992342814589499, -8.200076377177368],
              [-39.99234291822998, -8.20007629994096],
              [-39.992343020754674, -8.200076221240243],
              [-39.992343122142728, -8.200076141091184],
              [-39.992343222373549, -8.200076059510081],
              [-39.992343321426745, -8.200075976513544],
              [-39.992343419282186, -8.200075892118392],
              [-39.992343515919984, -8.200075806341829],
              [-39.992343611320479, -8.200075719201287],
              [-39.992343705464307, -8.200075630714439],
              [-39.992343798332314, -8.20007554089932],
              [-39.992343889905605, -8.20007544977417],
              [-39.992343980165593, -8.2000753573575],
              [-39.99234406909391, -8.200075263668136],
              [-39.992344156672495, -8.200075168725075],
              [-39.992344242883526, -8.200075072547651],
              [-39.992344327709489, -8.200074975155379],
              [-39.992344411133153, -8.200074876568099],
              [-39.992344493137537, -8.200074776805813],
              [-39.992344573705985, -8.200074675888844],
              [-39.992344652822119, -8.200074573837682],
              [-39.992344730469839, -8.20007447067306],
              [-39.992344806633376, -8.200074366415983],
              [-39.99234488129725, -8.200074261087604],
              [-39.992344954446281, -8.200074154709363],
              [-39.992345026065593, -8.20007404730287],
              [-39.992345096140625, -8.200073938889966],
              [-39.992345164657145, -8.200073829492711],
              [-39.992345231601234, -8.20007371913329],
              [-39.992345296959243, -8.200073607834172],
              [-39.99234536071792, -8.200073495617984],
              [-39.992345422864304, -8.20007338250752],
              [-39.992345483385726, -8.2000732685258],
              [-39.992345542269931, -8.20007315369595],
              [-39.99234559950493, -8.200073038041371],
              [-39.99234565507907, -8.200072921585518],
              [-39.992345708981084, -8.200072804352077],
              [-39.992345761199992, -8.200072686364905],
              [-39.992345811725194, -8.20007256764799],
              [-39.992345860546422, -8.200072448225423],
              [-39.992345907653721, -8.20007232812153],
              [-39.99234595303755, -8.20007220736067],
              [-39.992345996688677, -8.200072085967427],
              [-39.992346038598207, -8.200071963966487],
              [-39.992346078757635, -8.200071841382641],
              [-39.992346117158803, -8.200071718240778],
              [-39.992346153793889, -8.20007159456596],
              [-39.992346188655475, -8.200071470383339],
              [-39.992346221736433, -8.200071345718126],
              [-39.992346253030078, -8.200071220595687],
              [-39.992346282530036, -8.20007109504145],
              [-39.992346310230282, -8.200070969080929],
              [-39.992346336125237, -8.200070842739738],
              [-39.992346360209581, -8.200070716043564],
              [-39.992346382478466, -8.200070589018136],
              [-39.992346402927339, -8.200070461689309],
              [-39.99234642155205, -8.200070334082953],
              [-39.992346438348797, -8.200070206225016],
              [-39.992346453314191, -8.200070078141474],
              [-39.992346466445177, -8.200069949858387],
              [-39.992346477739105, -8.200069821401796],
              [-39.992346487193643, -8.200069692797832],
              [-39.992346494806888, -8.20006956407266],
              [-39.992346500577305, -8.200069435252427],
              [-39.992346504503701, -8.200069306363307],
              [-39.992346506585292, -8.200069177431523],
              [-39.99234650682164, -8.200069048483273],
              [-39.992346505212716, -8.200068919544789],
              [-39.992346501758817, -8.200068790642263],
              [-39.992346496460677, -8.20006866180189],
              [-39.99234648931936, -8.200068533049846],
              [-39.992346480336323, -8.200068404412356],
              [-39.992346469513379, -8.200068275915546],
              [-39.992346456852751, -8.200068147585519],
              [-39.992346442356975, -8.200068019448386],
              [-39.992346426029037, -8.200067891530175],
              [-39.99234640787224, -8.200067763856893],
              [-39.992346387890265, -8.200067636454508],
              [-39.992346366087197, -8.2000675093489],
              [-39.992346342467449, -8.200067382565939],
              [-39.992346317035825, -8.200067256131364],
              [-39.99234628979751, -8.200067130070904],
              [-39.992346260758012, -8.200067004410139],
              [-39.992346229923257, -8.200066879174674],
              [-39.992346197299504, -8.200066754389912],
              [-39.992346162893398, -8.200066630081247],
              [-39.992346126711929, -8.200066506273963],
              [-39.992346088762432, -8.200066382993187],
              [-39.992346049052628, -8.200066260264009],
              [-39.992346007590612, -8.200066138111367],
              [-39.992345964384796, -8.200066016560093],
              [-39.992345919443956, -8.200065895634911],
              [-39.992345915985368, -8.200065875485388],
              [-39.992007926800099, -8.199175944652305],
              [-39.992007909466878, -8.199175923809127],
              [-39.992007884934473, -8.199175859946171],
              [-39.992007859918132, -8.199175796269831],
              [-39.992007834419276, -8.199175732783777],
              [-39.992007808439375, -8.199175669491622],
              [-39.992007781979929, -8.199175606396938],
              [-39.992007755042422, -8.199175543503411],
              [-39.992007727628419, -8.199175480814551],
              [-39.992007699739482, -8.199175418334022],
              [-39.992007671377195, -8.19917535606535],
              [-39.99200764254315, -8.199175294012104],
              [-39.992007613239068, -8.199175232177812],
              [-39.992007583466581, -8.199175170566054],
              [-39.992007553227374, -8.199175109180318],
              [-39.992007522523217, -8.199175048024127],
              [-39.992007491355828, -8.199174987100958],
              [-39.992007459727027, -8.199174926414319],
              [-39.992007427638598, -8.199174865967665],
              [-39.992007395092351, -8.199174805764454],
              [-39.992007362090199, -8.199174745808151],
              [-39.992007328633981, -8.199174686102133],
              [-39.99200729472566, -8.19917462664985],
              [-39.99200726036711, -8.199174567454699],
              [-39.992007225560357, -8.199174508520048],
              [-39.992007190307362, -8.199174449849281],
              [-39.992007154610135, -8.199174391445736],
              [-39.992007118470724, -8.199174333312753],
              [-39.992007081891217, -8.19917427545367],
              [-39.992007044873652, -8.199174217871787],
              [-39.992007007420177, -8.199174160570408],
              [-39.992006969532952, -8.199174103552769],
              [-39.992006931214121, -8.199174046822188],
              [-39.992006892465866, -8.199173990381844],
              [-39.992006853290427, -8.199173934235013],
              [-39.992006813690018, -8.199173878384878],
              [-39.992006773666908, -8.19917382283465],
              [-39.992006733223413, -8.199173767587503],
              [-39.992006692361784, -8.199173712646585],
              [-39.992006651084424, -8.199173658015024],
              [-39.99200660939367, -8.199173603695984],
              [-39.992006567291888, -8.199173549692532],
              [-39.992006524781495, -8.19917349600777],
              [-39.992006481864934, -8.199173442644776],
              [-39.992006438544628, -8.199173389606589],
              [-39.992006394823093, -8.199173336896239],
              [-39.992006350702788, -8.199173284516727],
              [-39.992006306186262, -8.199173232471054],
              [-39.992006261276039, -8.199173180762211],
              [-39.992006215974726, -8.19917312939315],
              [-39.992006170284867, -8.199173078366787],
              [-39.992006124209105, -8.199173027686063],
              [-39.992006077750048, -8.199172977353863],
              [-39.992006030910368, -8.199172927373084],
              [-39.992005983692742, -8.199172877746562],
              [-39.992005936099872, -8.199172828477122],
              [-39.992005888134472, -8.199172779567609],
              [-39.99200583979929, -8.19917273102082],
              [-39.992005791097085, -8.199172682839517],
              [-39.992005742030628, -8.199172635026443],
              [-39.992005692602746, -8.199172587584346],
              [-39.992005642816252, -8.199172540515937],
              [-39.992005592673998, -8.199172493823916],
              [-39.992005542178845, -8.199172447510906],
              [-39.992005491333693, -8.199172401579615],
              [-39.992005440141433, -8.199172356032625],
              [-39.992005388604973, -8.199172310872578],
              [-39.992005336727303, -8.199172266102046],
              [-39.992005284511379, -8.199172221723559],
              [-39.99200523196015, -8.199172177739673],
              [-39.992005179076671, -8.199172134152908],
              [-39.992005125863912, -8.199172090965751],
              [-39.992005072324972, -8.199172048180667],
              [-39.992005018462848, -8.199172005800099],
              [-39.992004964280682, -8.199171963826473],
              [-39.992004909781514, -8.199171922262193],
              [-39.992004854968499, -8.199171881109647],
              [-39.992004799844779, -8.199171840371163],
              [-39.992004744413457, -8.199171800049063],
              [-39.992004688677731, -8.199171760145703],
              [-39.992004632640793, -8.199171720663289],
              [-39.992004576305831, -8.199171681604151],
              [-39.99200451967608, -8.199171642970468],
              [-39.992004462754771, -8.19917160476446],
              [-39.99200440554516, -8.199171566988353],
              [-39.992004348050507, -8.199171529644257],
              [-39.99200429027411, -8.199171492734333],
              [-39.992004232219273, -8.199171456260684],
              [-39.992004173889306, -8.199171420225374],
              [-39.99200411528755, -8.1991713846305],
              [-39.992004056417329, -8.199171349478069],
              [-39.992003997282048, -8.199171314770103],
              [-39.992003937885073, -8.199171280508596],
              [-39.992003878229802, -8.199171246695476],
              [-39.992003818319631, -8.199171213332704],
              [-39.992003758157999, -8.199171180422161],
              [-39.992003697748331, -8.199171147965753],
              [-39.992003637094093, -8.199171115965335],
              [-39.992003576198755, -8.199171084422709],
              [-39.99200351506579, -8.199171053339704],
              [-39.992003453698693, -8.199171022718103],
              [-39.992003466444984, -8.199170981319352],
              [-39.991102768016667, -8.198725870295197],
              [-39.991102774009256, -8.198725825413396],
              [-39.991102709613443, -8.198725793265249],
              [-39.991102645475664, -8.198725760609037],
              [-39.991102581600003, -8.198725727446842],
              [-39.991102517990441, -8.198725693780702],
              [-39.991102454650992, -8.198725659612796],
              [-39.991102391585656, -8.198725624945252],
              [-39.991102328798398, -8.198725589780217],
              [-39.991102266293147, -8.198725554119957],
              [-39.991102204073883, -8.198725517966702],
              [-39.991102142144491, -8.198725481322716],
              [-39.991102080508874, -8.198725444190281],
              [-39.991102019170917, -8.198725406571793],
              [-39.991101958134479, -8.198725368469592],
              [-39.991101897403418, -8.19872532988607],
              [-39.991101836981549, -8.198725290823647],
              [-39.991101776872654, -8.198725251284824],
              [-39.991101717080554, -8.198725211272066],
              [-39.991101657608972, -8.19872517078789],
              [-39.99110159846169, -8.198725129834852],
              [-39.991101539642422, -8.1987250884155],
              [-39.991101481154864, -8.198725046532486],
              [-39.991101423002696, -8.198725004188416],
              [-39.991101365189586, -8.198724961385953],
              [-39.991101307719163, -8.198724918127812],
              [-39.991101250595065, -8.198724874416712],
              [-39.991101193820867, -8.198724830255424],
              [-39.991101137400165, -8.198724785646688],
              [-39.991101081336495, -8.19872474059332],
              [-39.991101025633398, -8.198724695098189],
              [-39.991100970294362, -8.198724649164129],
              [-39.991100915322896, -8.198724602794066],
              [-39.991100860722433, -8.198724555990873],
              [-39.991100806496434, -8.198724508757504],
              [-39.9911007526483, -8.198724461096967],
              [-39.991100699181445, -8.198724413012236],
              [-39.991100646099198, -8.198724364506351],
              [-39.99110059340493, -8.198724315582352],
              [-39.991100541101936, -8.198724266243334],
              [-39.991100489193528, -8.198724216492387],
              [-39.991100437682981, -8.198724166332667],
              [-39.991100386573493, -8.198724115767307],
              [-39.991100335868332, -8.198724064799508],
              [-39.991100285570674, -8.19872401343245],
              [-39.991100235683668, -8.198723961669428],
              [-39.991100186210474, -8.198723909513648],
              [-39.991100137154213, -8.198723856968391],
              [-39.991100088517953, -8.198723804037],
              [-39.991100040304772, -8.198723750722772],
              [-39.991099992517697, -8.1987236970291],
              [-39.991099945159732, -8.198723642959333],
              [-39.991099898233877, -8.198723588516906],
              [-39.991099851743073, -8.198723533705211],
              [-39.991099805690247, -8.198723478527722],
              [-39.991099760078299, -8.198723422987914],
              [-39.991099714910099, -8.198723367089274],
              [-39.991099670188497, -8.198723310835316],
              [-39.991099625916313, -8.198723254229604],
              [-39.991099582096318, -8.198723197275683],
              [-39.991099538731277, -8.198723139977137],
              [-39.991099495823931, -8.19872308233756],
              [-39.991099453376968, -8.198723024360625],
              [-39.991099411393073, -8.198722966049958],
              [-39.99109936987486, -8.198722907409207],
              [-39.991099328824987, -8.198722848442115],
              [-39.991099288245998, -8.198722789152335],
              [-39.991099248140486, -8.198722729543645],
              [-39.991099208510938, -8.198722669619791],
              [-39.991099169359877, -8.198722609384559],
              [-39.991099130689747, -8.19872254884171],
              [-39.991099092503006, -8.198722487995052],
              [-39.991099054802042, -8.198722426848439],
              [-39.991099017589228, -8.198722365405681],
              [-39.991098980866916, -8.198722303670703],
              [-39.991098944637415, -8.198722241647372],
              [-39.991098908902998, -8.198722179339596],
              [-39.991098873665919, -8.198722116751251],
              [-39.991098838928409, -8.198722053886346],
              [-39.991098804692633, -8.198721990748785],
              [-39.991098770960761, -8.198721927342593],
              [-39.991098737734916, -8.198721863671711],
              [-39.99109870501718, -8.19872179974019],
              [-39.991098672809628, -8.198721735552041],
              [-39.99109864111427, -8.198721671111308],
              [-39.991098609933118, -8.198721606422012],
              [-39.991098579268119, -8.198721541488258],
              [-39.991098549121226, -8.19872147631415],
              [-39.991098519494322, -8.198721410903781],
              [-39.991098490389284, -8.19872134526125],
              [-39.991098461807923, -8.198721279390702],
              [-39.99109843375205, -8.198721213296274],
              [-39.991098406223422, -8.198721146982129],
              [-39.991098379223793, -8.198721080452465],
              [-39.991098352754854, -8.198721013711442],
              [-39.99109832681826, -8.19872094676327],
              [-39.991098301415654, -8.198720879612177],
              [-39.991098276548641, -8.198720812262376],
              [-39.991098252218777, -8.198720744718132],
              [-39.991098228427596, -8.198720676983655],
              [-39.991098205177138, -8.198720609061899],
              [-39.991098216229503, -8.198720635849096],
              [-39.990757578466194, -8.197712502302894],
              [-39.990757576655433, -8.197712494211732],
              [-39.990757565674933, -8.197712461915517],
              [-39.990757554572312, -8.197712429660768],
              [-39.990757543347698, -8.197712397447964],
              [-39.990757532001275, -8.19771236527756],
              [-39.990757520533194, -8.197712333150006],
              [-39.990757508943616, -8.197712301065772],
              [-39.990757497232707, -8.197712269025288],
              [-39.990757485400636, -8.197712237029016],
              [-39.990757473447566, -8.197712205077432],
              [-39.990757461373676, -8.197712173170956],
              [-39.990757449179121, -8.197712141310072],
              [-39.990757436864087, -8.197712109495225],
              [-39.990757424428757, -8.197712077726864],
              [-39.990757411873297, -8.197712046005428],
              [-39.990757399197875, -8.197712014331387],
              [-39.990757386402684, -8.197711982705195],
              [-39.990757373487916, -8.197711951127278],
              [-39.990757360453742, -8.197711919598104],
              [-39.990757347300345, -8.197711888118107],
              [-39.990757334027919, -8.197711856687746],
              [-39.990757320636632, -8.19771182530746],
              [-39.990757307126707, -8.197711793977701],
              [-39.990757293498319, -8.197711762698933],
              [-39.990757279751655, -8.197711731471573],
              [-39.990757265886934, -8.197711700296063],
              [-39.99075725190432, -8.197711669172831],
              [-39.99075723780404, -8.197711638102387],
              [-39.990757223586286, -8.197711607085104],
              [-39.99075720925125, -8.197711576121463],
              [-39.990757194799158, -8.1977115452119],
              [-39.990757180230183, -8.197711514356817],
              [-39.990757165544551, -8.197711483556684],
              [-39.990757150742496, -8.197711452811946],
              [-39.990757135824175, -8.197711422123012],
              [-39.990757120789837, -8.197711391490348],
              [-39.990757105639688, -8.197711360914383],
              [-39.99075709037394, -8.19771133039554],
              [-39.9907570749928, -8.197711299934243],
              [-39.990757059496524, -8.197711269530942],
              [-39.990757043885282, -8.197711239186058],
              [-39.990757028159329, -8.197711208900081],
              [-39.99075701231888, -8.197711178673345],
              [-39.990756996364162, -8.197711148506343],
              [-39.990756980295394, -8.197711118399472],
              [-39.990756964112826, -8.19771108835319],
              [-39.990756947816656, -8.197711058367904],
              [-39.990756931407134, -8.19771102844406],
              [-39.990756914884493, -8.197710998582052],
              [-39.990756898248968, -8.19771096878233],
              [-39.990756881500801, -8.197710939045303],
              [-39.990756864640211, -8.197710909371398],
              [-39.990756847667463, -8.197710879761049],
              [-39.990756830582768, -8.197710850214635],
              [-39.990756813386398, -8.197710820732626],
              [-39.990756796078571, -8.197710791315448],
              [-39.990756778659552, -8.19771076196348],
              [-39.990756761129589, -8.197710732677152],
              [-39.99075674348893, -8.197710703456876],
              [-39.99075672573781, -8.197710674303048],
              [-39.990756707876493, -8.197710645216143],
              [-39.990756689905233, -8.197710616196527],
              [-39.990756671824293, -8.197710587244615],
              [-39.990756653633923, -8.197710558360827],
              [-39.990756635334378, -8.197710529545585],
              [-39.990756616925928, -8.197710500799291],
              [-39.990756598408801, -8.197710472122333],
              [-39.990756579783309, -8.197710443515151],
              [-39.990756561049686, -8.19771041497809],
              [-39.990756542208217, -8.197710386511647],
              [-39.990756523259151, -8.197710358116165],
              [-39.990756504202764, -8.197710329792061],
              [-39.990756485039341, -8.197710301539745],
              [-39.990756465769138, -8.197710273359657],
              [-39.990756446392425, -8.197710245252141],
              [-39.990756426909485, -8.197710217217587],
              [-39.990756407320589, -8.197710189256405],
              [-39.99075638762605, -8.197710161369033],
              [-39.990756367826101, -8.197710133555807],
              [-39.990756347921049, -8.197710105817198],
              [-39.990756327911164, -8.197710078153529],
              [-39.990756307796737, -8.197710050565213],
              [-39.990756287578051, -8.197710023052657],
              [-39.990756267255406, -8.197709995616266],
              [-39.990756246829072, -8.197709968256371],
              [-39.990756226299361, -8.197709940973422],
              [-39.990756205666536, -8.197709913767804],
              [-39.990756184930916, -8.197709886639853],
              [-39.990756164092794, -8.197709859589999],
              [-39.990756143152439, -8.197709832618623],
              [-39.990756122110184, -8.197709805726088],
              [-39.990756100966301, -8.197709778912779],
              [-39.990756079721109, -8.197709752179096],
              [-39.990756058374899, -8.197709725525394],
              [-39.990756036927984, -8.197709698952076],
              [-39.990756015380661, -8.197709672459546],
              [-39.990755993733238, -8.197709646048072],
              [-39.990755971986019, -8.197709619718161],
              [-39.990755950139324, -8.197709593470091],
              [-39.990755928193451, -8.197709567304294],
              [-39.990755937339969, -8.197709612191503],
              [-39.990191465775325, -8.197039104986079],
              [-39.990191469669476, -8.197039141077079],
              [-39.990191422378878, -8.197039085366239],
              [-39.990191374638691, -8.197039030037359],
              [-39.99019132645207, -8.197038975093967],
              [-39.99019127782212, -8.197038920539697],
              [-39.99019122875201, -8.197038866378056],
              [-39.990191179244952, -8.197038812612597],
              [-39.990191129304151, -8.197038759246803],
              [-39.99019107893286, -8.197038706284147],
              [-39.990191028134355, -8.197038653728088],
              [-39.990190976911954, -8.197038601582042],
              [-39.990190925268983, -8.197038549849406],
              [-39.99019087320881, -8.197038498533571],
              [-39.990190820734803, -8.197038447637812],
              [-39.990190767850386, -8.197038397165477],
              [-39.990190714559013, -8.19703834711988],
              [-39.990190660864158, -8.197038297504218],
              [-39.990190606769296, -8.19703824832178],
              [-39.990190552277944, -8.197038199575745],
              [-39.990190497393662, -8.19703815126927],
              [-39.990190442120017, -8.197038103405506],
              [-39.990190386460611, -8.197038055987571],
              [-39.990190330419061, -8.197038009018543],
              [-39.990190273999033, -8.197037962501488],
              [-39.990190217204187, -8.197037916439454],
              [-39.990190160038196, -8.197037870835414],
              [-39.990190102504798, -8.197037825692341],
              [-39.990190044607758, -8.197037781013174],
              [-39.990189986350821, -8.197037736800823],
              [-39.990189927737774, -8.197037693058137],
              [-39.990189868772454, -8.197037649788006],
              [-39.990189809458677, -8.197037606993215],
              [-39.990189749800308, -8.197037564676558],
              [-39.990189689801234, -8.197037522840809],
              [-39.990189629465384, -8.197037481488666],
              [-39.990189568796644, -8.197037440622843],
              [-39.990189507798981, -8.197037400245975],
              [-39.990189446476357, -8.197037360360683],
              [-39.990189384832775, -8.197037320969603],
              [-39.99018932287224, -8.197037282075261],
              [-39.99018926059879, -8.19703724368023],
              [-39.990189198016459, -8.197037205786957],
              [-39.990189135129363, -8.197037168397967],
              [-39.99018907194155, -8.197037131515634],
              [-39.990189008457158, -8.197037095142409],
              [-39.990188944680291, -8.197037059280628],
              [-39.99018888061515, -8.19703702393263],
              [-39.990188816265857, -8.197036989100724],
              [-39.990188751636623, -8.197036954787146],
              [-39.990188686731635, -8.197036920994167],
              [-39.990188621555141, -8.197036887723984],
              [-39.99018855611137, -8.197036854978736],
              [-39.990188490404584, -8.197036822760575],
              [-39.990188424439062, -8.197036791071611],
              [-39.990188358219093, -8.197036759913876],
              [-39.990188291748993, -8.197036729289408],
              [-39.990188225033073, -8.197036699200202],
              [-39.990188158075711, -8.197036669648224],
              [-39.990188090881205, -8.197036640635393],
              [-39.990188023453982, -8.197036612163574],
              [-39.990187955798397, -8.197036584234658],
              [-39.990187887918907, -8.197036556850456],
              [-39.990187819819852, -8.197036530012719],
              [-39.990187751505715, -8.19703650372321],
              [-39.990187682980924, -8.197036477983634],
              [-39.990187614249948, -8.197036452795704],
              [-39.990187545317262, -8.197036428161008],
              [-39.990187476187351, -8.197036404081192],
              [-39.990187406864699, -8.19703638055781],
              [-39.990187337353838, -8.197036357592372],
              [-39.99018726765928, -8.197036335186374],
              [-39.990187197785559, -8.197036313341318],
              [-39.990187127737244, -8.197036292058591],
              [-39.99018705751886, -8.197036271339581],
              [-39.990186987135004, -8.197036251185626],
              [-39.99018691659024, -8.197036231598069],
              [-39.990186845889177, -8.197036212578174],
              [-39.990186775036406, -8.197036194127156],
              [-39.990186704036532, -8.197036176246236],
              [-39.990186632894194, -8.197036158936561],
              [-39.990186561613996, -8.197036142199263],
              [-39.990186490200607, -8.197036126035465],
              [-39.990186418658652, -8.197036110446163],
              [-39.9901863469928, -8.197036095432425],
              [-39.990186275207712, -8.197036080995197],
              [-39.990186203308063, -8.197036067135434],
              [-39.990186131298515, -8.197036053854029],
              [-39.990186059183777, -8.197036041151831],
              [-39.990185986968541, -8.197036029029672],
              [-39.990185914657481, -8.197036017488358],
              [-39.990185842255329, -8.197036006528654],
              [-39.990185769766796, -8.197035996151236],
              [-39.990185697196587, -8.197035986356793],
              [-39.990185624549433, -8.197035977145967],
              [-39.990185551830066, -8.197035968519373],
              [-39.990185479043205, -8.197035960477537],
              [-39.990185406193611, -8.197035953021004],
              [-39.990185333286, -8.197035946150249],
              [-39.990185260325134, -8.197035939865732],
              [-39.990185187315753, -8.197035934167882],
              [-39.990185194428705, -8.197035954913092],
              [-39.988717256734226, -8.196927317591683],
              [-39.988717249462773, -8.196927302972597],
              [-39.988717229510705, -8.196927301518064],
              [-39.988717209555446, -8.196927300107358],
              [-39.988717189597132, -8.196927298740475],
              [-39.988717169635841, -8.196927297417401],
              [-39.988717149671672, -8.196927296138165],
              [-39.988717129704717, -8.196927294902752],
              [-39.988717109735077, -8.196927293711196],
              [-39.988717089762858, -8.196927292563513],
              [-39.988717069788137, -8.196927291459692],
              [-39.988717049811029, -8.196927290399708],
              [-39.988717029831626, -8.196927289383595],
              [-39.988717009850028, -8.196927288411352],
              [-39.988716989866319, -8.196927287482971],
              [-39.988716969880613, -8.196927286598491],
              [-39.988716949892996, -8.196927285757882],
              [-39.988716929903568, -8.196927284961181],
              [-39.988716909912426, -8.196927284208368],
              [-39.988716889919665, -8.196927283499443],
              [-39.988716869925391, -8.19692728283442],
              [-39.988716849929688, -8.196927282213302],
              [-39.988716829932663, -8.196927281636091],
              [-39.988716809934417, -8.196927281102765],
              [-39.988716789935033, -8.196927280613378],
              [-39.988716769934612, -8.196927280167911],
              [-39.988716749933239, -8.196927279766332],
              [-39.988716729931049, -8.196927279408683],
              [-39.988716709928127, -8.196927279094959],
              [-39.988716689924537, -8.196927278825168],
              [-39.9887166699204, -8.196927278599295],
              [-39.988716649915816, -8.196927278417331],
              [-39.988716629910897, -8.196927278279315],
              [-39.988716609905687, -8.196927278185216],
              [-39.988716589900335, -8.196927278135036],
              [-39.988716569894926, -8.19692727812879],
              [-39.988716549889538, -8.196927278166465],
              [-39.988716529884286, -8.196927278248054],
              [-39.988716509879268, -8.196927278373577],
              [-39.98871648987457, -8.196927278543038],
              [-39.988716469870305, -8.19692727875641],
              [-39.988716449866551, -8.196927279013718],
              [-39.98871642986343, -8.196927279314943],
              [-39.988716409861013, -8.196927279660086],
              [-39.988716389859398, -8.196927280049135],
              [-39.988716369858707, -8.196927280482093],
              [-39.988716349859018, -8.196927280958979],
              [-39.988716329860431, -8.19692728147978],
              [-39.988716309863044, -8.196927282044497],
              [-39.988716289866957, -8.19692728265311],
              [-39.98871626987227, -8.196927283305639],
              [-39.988716249879062, -8.196927284002046],
              [-39.988716229887451, -8.196927284742369],
              [-39.988716209897532, -8.19692728552657],
              [-39.98871618990939, -8.196927286354706],
              [-39.988716169923123, -8.1969272872267],
              [-39.988716149938838, -8.196927288142573],
              [-39.988716129956629, -8.196927289102327],
              [-39.988716109976586, -8.19692729010594],
              [-39.988716089998817, -8.196927291153418],
              [-39.988716070023408, -8.196927292244771],
              [-39.988716050050471, -8.196927293379987],
              [-39.988716030080091, -8.196927294559048],
              [-39.988716010112356, -8.196927295781967],
              [-39.988715990147377, -8.196927297048713],
              [-39.988715970185268, -8.196927298359292],
              [-39.988715950226094, -8.196927299713709],
              [-39.988715930269962, -8.19692730111195],
              [-39.988715910316969, -8.196927302554004],
              [-39.988715890367217, -8.196927304039844],
              [-39.988715870420798, -8.196927305569512],
              [-39.988715850477831, -8.196927307142955],
              [-39.988715830538361, -8.196927308760193],
              [-39.988715810602542, -8.196927310421202],
              [-39.988715790670426, -8.196927312125982],
              [-39.988715770742139, -8.196927313874522],
              [-39.988715750817775, -8.196927315666819],
              [-39.988715730897418, -8.196927317502853],
              [-39.988715710981161, -8.196927319382628],
              [-39.98871569106911, -8.196927321306125],
              [-39.988715671161387, -8.196927323273362],
              [-39.988715651258033, -8.196927325284264],
              [-39.988715631359199, -8.196927327338873],
              [-39.988715611464947, -8.196927329437177],
              [-39.988715591575385, -8.196927331579161],
              [-39.988715571690612, -8.196927333764801],
              [-39.988715551810721, -8.196927335994125],
              [-39.98871553193581, -8.19692733826707],
              [-39.988715512065973, -8.196927340583665],
              [-39.988715492201308, -8.196927342943866],
              [-39.988715472341916, -8.196927345347682],
              [-39.988715452487888, -8.196927347795103],
              [-39.988715432639324, -8.196927350286105],
              [-39.988715412796303, -8.196927352820675],
              [-39.988715392958945, -8.196927355398826],
              [-39.988715373127356, -8.196927358020513],
              [-39.988715353301586, -8.196927360685748],
              [-39.988715333481778, -8.196927363394504],
              [-39.98871531366801, -8.196927366146788],
              [-39.988715293860366, -8.196927368942537],
              [-39.988715274058968, -8.196927371781802],
              [-39.988715259893652, -8.196927412928762],
              [-39.987924247574057, -8.197041680226857],
              [-39.98792424578312, -8.197041680278533],
              [-39.987924233034995, -8.197041682112289],
              [-39.987924220284242, -8.197041683927996],
              [-39.987924207530924, -8.197041685725663],
              [-39.98792419477504, -8.197041687505262],
              [-39.987924182016648, -8.197041689266813],
              [-39.987924169255756, -8.197041691010304],
              [-39.987924156492376, -8.197041692735711],
              [-39.987924143726566, -8.197041694443067],
              [-39.987924130958334, -8.197041696132334],
              [-39.987924118187699, -8.197041697803515],
              [-39.987924105414692, -8.197041699456626],
              [-39.987924092639332, -8.197041701091653],
              [-39.987924079861671, -8.197041702708606],
              [-39.9879240670817, -8.197041704307455],
              [-39.987924054299476, -8.197041705888218],
              [-39.987924041515008, -8.197041707450895],
              [-39.987924028728322, -8.197041708995451],
              [-39.987924015939441, -8.197041710521901],
              [-39.987924003148393, -8.197041712030245],
              [-39.987923990355213, -8.19704171352047],
              [-39.98792397755993, -8.197041714992592],
              [-39.987923964762551, -8.19704171644659],
              [-39.987923951963097, -8.197041717882483],
              [-39.987923939161632, -8.197041719300278],
              [-39.98792392635815, -8.197041720699929],
              [-39.987923913552684, -8.197041722081444],
              [-39.987923900745258, -8.197041723444833],
              [-39.987923887935914, -8.197041724790108],
              [-39.987923875124636, -8.197041726117238],
              [-39.987923862311504, -8.197041727426216],
              [-39.987923849496511, -8.197041728717052],
              [-39.987923836679691, -8.197041729989751],
              [-39.987923823861067, -8.197041731244312],
              [-39.987923811040659, -8.19704173248072],
              [-39.987923798218517, -8.197041733698965],
              [-39.987923785394642, -8.197041734899058],
              [-39.987923772569069, -8.19704173608101],
              [-39.987923759741811, -8.197041737244794],
              [-39.987923746912927, -8.197041738390419],
              [-39.987923734082408, -8.197041739517886],
              [-39.987923721250297, -8.197041740627185],
              [-39.987923708416623, -8.197041741718323],
              [-39.987923695581401, -8.19704174279129],
              [-39.987923682744658, -8.19704174384607],
              [-39.987923669906422, -8.197041744882688],
              [-39.98792365706673, -8.197041745901117],
              [-39.987923644225603, -8.197041746901375],
              [-39.987923631383048, -8.197041747883475],
              [-39.987923618539114, -8.197041748847367],
              [-39.987923605693808, -8.197041749793069],
              [-39.987923592847189, -8.197041750720615],
              [-39.98792357999924, -8.197041751629953],
              [-39.987923567150013, -8.1970417525211],
              [-39.987923554299527, -8.197041753394059],
              [-39.987923541447806, -8.197041754248827],
              [-39.987923528594877, -8.197041755085397],
              [-39.987923515740782, -8.197041755903783],
              [-39.987923502885529, -8.19704175670396],
              [-39.987923490029132, -8.197041757485962],
              [-39.987923477171655, -8.197041758249759],
              [-39.98792346431307, -8.197041758995363],
              [-39.987923451453462, -8.19704175972274],
              [-39.987923438592823, -8.197041760431912],
              [-39.987923425731196, -8.197041761122854],
              [-39.987923412868582, -8.197041761795592],
              [-39.987923400005023, -8.197041762450123],
              [-39.98792338714054, -8.197041763086441],
              [-39.987923374275177, -8.197041763704553],
              [-39.987923361408932, -8.197041764304457],
              [-39.987923348541862, -8.197041764886135],
              [-39.987923335673948, -8.197041765449605],
              [-39.987923322805266, -8.197041765994868],
              [-39.987923309935809, -8.197041766521904],
              [-39.987923297065613, -8.197041767030711],
              [-39.987923284194707, -8.197041767521313],
              [-39.987923271323112, -8.197041767993671],
              [-39.98792325845087, -8.197041768447837],
              [-39.987923245577974, -8.197041768883757],
              [-39.987923232704482, -8.19704176930148],
              [-39.987923219830407, -8.197041769700963],
              [-39.987923206955763, -8.197041770082228],
              [-39.987923194080594, -8.197041770445264],
              [-39.98792318120492, -8.19704177079007],
              [-39.987923168328784, -8.197041771116655],
              [-39.987923155452172, -8.197041771424995],
              [-39.987923142575141, -8.197041771715112],
              [-39.987923129697712, -8.197041771986997],
              [-39.9879231168199, -8.197041772240658],
              [-39.987923103941753, -8.197041772476092],
              [-39.98792309106328, -8.197041772693282],
              [-39.987923078184494, -8.19704177289225],
              [-39.987923065305452, -8.197041773072986],
              [-39.987923052426162, -8.197041773235478],
              [-39.987923039546665, -8.197041773379745],
              [-39.987923026666969, -8.197041773505783],
              [-39.987923013787082, -8.197041773613549],
              [-39.987923000907088, -8.197041773703132],
              [-39.987922988026959, -8.197041773774476],
              [-39.987922975146596, -8.197041773829225],
              [-39.987266031477233, -8.197043382465154],
              [-39.987266003832374, -8.197043382532824],
              [-39.987265787727274, -8.197043380498714],
              [-39.987265571732003, -8.197043373339516],
              [-39.987265355969001, -8.197043361059391],
              [-39.987265140560602, -8.197043343665234],
              [-39.987264925628835, -8.197043321166914],
              [-39.987264711295545, -8.197043293577176],
              [-39.987264497682233, -8.197043260911682],
              [-39.987264284909948, -8.197043223188912],
              [-39.987264073099304, -8.197043180430274],
              [-39.987263862370355, -8.197043132659992],
              [-39.987263652842522, -8.197043079905152],
              [-39.987263444634593, -8.197043022195629],
              [-39.987263237864575, -8.197042959564168],
              [-39.987263032649643, -8.197042892046252],
              [-39.987262829106136, -8.197042819680156],
              [-39.987262627349402, -8.197042742506886],
              [-39.987262427493803, -8.197042660570173],
              [-39.987262229652629, -8.197042573916486],
              [-39.987262033937995, -8.197042482594913],
              [-39.987261840460853, -8.197042386657248],
              [-39.987261649330847, -8.197042286157846],
              [-39.987261460656313, -8.197042181153668],
              [-39.987261274544196, -8.197042071704246],
              [-39.987261091099974, -8.197041957871594],
              [-39.987260910427651, -8.19704183972023],
              [-39.987260732629579, -8.197041717317148],
              [-39.987260557806593, -8.197041590731716],
              [-39.987260386057741, -8.197041460035676],
              [-39.987260217480383, -8.197041325303115],
              [-39.987260052170065, -8.197041186610381],
              [-39.987259890220493, -8.197041044036105],
              [-39.987259731723455, -8.197040897661084],
              [-39.987259576768778, -8.197040747568295],
              [-39.987259425444293, -8.197040593842788],
              [-39.987259277835769, -8.197040436571724],
              [-39.987259134026878, -8.197040275844211],
              [-39.987258994099136, -8.19704011175137],
              [-39.987258858131838, -8.197039944386191],
              [-39.98725872620205, -8.197039773843576],
              [-39.987258598384564, -8.197039600220142],
              [-39.987258474751812, -8.197039423614356],
              [-39.987258355373882, -8.197039244126238],
              [-39.987258240318418, -8.197039061857572],
              [-39.987258129650641, -8.197038876911668],
              [-39.987258023433284, -8.197038689393301],
              [-39.987257921726552, -8.197038499408828],
              [-39.987257824588092, -8.197038307065895],
              [-39.987257732072948, -8.197038112473521],
              [-39.98725764423358, -8.19703791574204],
              [-39.987257561119726, -8.197037716982873],
              [-39.987257482778546, -8.19703751630875],
              [-39.987257409254454, -8.197037313833381],
              [-39.987257340589075, -8.197037109671564],
              [-39.987257276821332, -8.197036903938956],
              [-39.987257217987398, -8.197036696752216],
              [-39.987257164120614, -8.197036488228754],
              [-39.987257115251502, -8.197036278486765],
              [-39.987257071407761, -8.197036067645142],
              [-39.987257032614245, -8.197035855823348],
              [-39.987256998892946, -8.197035643141497],
              [-39.987256970262969, -8.197035429720122],
              [-39.987256946740551, -8.197035215680152],
              [-39.987256928339008, -8.197035001142973],
              [-39.987256915068777, -8.197034786230095],
              [-39.987256906937397, -8.197034571063405],
              [-39.987256903949451, -8.197034355764851],
              [-39.987256906106644, -8.197034140456399],
              [-39.987256913407755, -8.197033925260151],
              [-39.987256925848641, -8.197033710298063],
              [-39.987256943422267, -8.197033495691969],
              [-39.987256966118643, -8.197033281563508],
              [-39.987256993924923, -8.197033068034033],
              [-39.987257026825354, -8.197032855224588],
              [-39.98725706480127, -8.197032643255787],
              [-39.987257107831155, -8.197032432247797],
              [-39.987257155890617, -8.197032222320161],
              [-39.987257208952421, -8.197032013591922],
              [-39.987257266986489, -8.197031806181371],
              [-39.987257329959931, -8.197031600206047],
              [-39.987257397837055, -8.197031395782709],
              [-39.987257470579372, -8.197031193027247],
              [-39.987257548145671, -8.19703099205454],
              [-39.987257630491982, -8.197030792978557],
              [-39.98725771757163, -8.197030595912047],
              [-39.987257809335247, -8.197030400966748],
              [-39.987257905730836, -8.19703020825318],
              [-39.987258006703783, -8.197030017880566],
              [-39.987258112196805, -8.197029829956779],
              [-39.987258222150146, -8.197029644588369],
              [-39.987258336501483, -8.197029461880362],
              [-39.98725845518598, -8.197029281936333],
              [-39.987258578136384, -8.197029104858293],
              [-39.987258705282997, -8.197028930746598],
              [-39.987258836553778, -8.197028759699926],
              [-39.987258971874283, -8.197028591815219],
              [-39.987259111167852, -8.197028427187647],
              [-39.987259254355507, -8.197028265910543],
              [-39.987259401356098, -8.197028108075262],
              [-39.987259552086265, -8.197027953771354],
              [-39.987259548265421, -8.197027932741234],
              [-39.98780639733495, -8.196481318625757],
              [-39.987806372009835, -8.196481339727248],
              [-39.987806422753692, -8.196481289401813],
              [-39.987806473894338, -8.196481239476691],
              [-39.987806525428631, -8.196481189955014],
              [-39.987806577353325, -8.196481140839788],
              [-39.987806629665215, -8.196481092134155],
              [-39.987806682361011, -8.196481043841066],
              [-39.987806735437488, -8.196480995963562],
              [-39.987806788891298, -8.19648094850462],
              [-39.987806842719145, -8.196480901467194],
              [-39.987806896917661, -8.196480854854189],
              [-39.987806951483492, -8.196480808668529],
              [-39.987807006413234, -8.196480762913085],
              [-39.987807061703478, -8.196480717590713],
              [-39.987807117350769, -8.196480672704203],
              [-39.987807173351655, -8.196480628256355],
              [-39.987807229702661, -8.196480584249979],
              [-39.987807286400262, -8.196480540687743],
              [-39.987807343440942, -8.19648049757237],
              [-39.987807400821168, -8.196480454906576],
              [-39.987807458537333, -8.196480412693017],
              [-39.987807516585889, -8.196480370934283],
              [-39.987807574963192, -8.196480329632973],
              [-39.987807633665625, -8.196480288791692],
              [-39.987807692689543, -8.19648024841297],
              [-39.987807752031259, -8.196480208499285],
              [-39.987807811687098, -8.196480169053153],
              [-39.987807871653345, -8.19648013007702],
              [-39.987807931926248, -8.196480091573342],
              [-39.987807992502091, -8.196480053544461],
              [-39.98780805337708, -8.196480015992746],
              [-39.987808114547441, -8.196479978920571],
              [-39.987808176009366, -8.196479942330209],
              [-39.987808237759033, -8.196479906223972],
              [-39.98780829979259, -8.19647987060406],
              [-39.987808362106207, -8.196479835472719],
              [-39.987808424695977, -8.196479800832142],
              [-39.987808487558013, -8.196479766684432],
              [-39.98780855068842, -8.196479733031778],
              [-39.987808614083256, -8.196479699876248],
              [-39.98780867773857, -8.196479667219883],
              [-39.98780874165044, -8.196479635064733],
              [-39.987808805814851, -8.196479603412797],
              [-39.98780887022783, -8.196479572266059],
              [-39.987808934885365, -8.196479541626463],
              [-39.987808999783439, -8.196479511495868],
              [-39.987809064917997, -8.196479481876159],
              [-39.987809130285015, -8.196479452769216],
              [-39.987809195880402, -8.196479424176813],
              [-39.9878092617001, -8.196479396100772],
              [-39.987809327739974, -8.196479368542791],
              [-39.987809393995974, -8.196479341504608],
              [-39.98780946046395, -8.196479314987899],
              [-39.987809527139774, -8.196479288994308],
              [-39.987809594019282, -8.196479263525461],
              [-39.987809661098318, -8.19647923858297],
              [-39.987809728372717, -8.196479214168328],
              [-39.987809795838288, -8.196479190283078],
              [-39.987809863490845, -8.196479166928725],
              [-39.987809931326169, -8.196479144106702],
              [-39.987809999340058, -8.196479121818449],
              [-39.987810067528258, -8.196479100065336],
              [-39.987810135886527, -8.196479078848689],
              [-39.987810204410629, -8.196479058169889],
              [-39.987810273096294, -8.196479038030173],
              [-39.987810341939259, -8.196479018430811],
              [-39.987810410935211, -8.19647899937303],
              [-39.987810480079901, -8.196478980858004],
              [-39.987810549369009, -8.196478962886886],
              [-39.987810618798214, -8.196478945460797],
              [-39.987810688363204, -8.196478928580817],
              [-39.987810758059666, -8.19647891224801],
              [-39.98781082788323, -8.196478896463361],
              [-39.987810897829597, -8.196478881227888],
              [-39.987810967894383, -8.196478866542535],
              [-39.987811038073247, -8.196478852408173],
              [-39.987811108361811, -8.196478838825739],
              [-39.987811178755699, -8.196478825796037],
              [-39.987811249250569, -8.196478813319901],
              [-39.987811319841988, -8.196478801398067],
              [-39.987811390525593, -8.196478790031325],
              [-39.987811461296978, -8.196478779220371],
              [-39.987811532151738, -8.196478768965857],
              [-39.987811603085461, -8.196478759268434],
              [-39.987811674093763, -8.19647875012874],
              [-39.987811745172216, -8.196478741547267],
              [-39.987811816316373, -8.196478733524625],
              [-39.987811887521815, -8.196478726061235],
              [-39.987811958784128, -8.196478719157639],
              [-39.98781203009888, -8.196478712814212],
              [-39.987812101461643, -8.196478707031392],
              [-39.987812172867933, -8.196478701809504],
              [-39.987812244313361, -8.196478697148871],
              [-39.987812315793462, -8.196478693049812],
              [-39.987812387303784, -8.196478689512581],
              [-39.987812458839876, -8.196478686537361],
              [-39.987812530397314, -8.196478684124374],
              [-39.987812601971598, -8.196478682273717],
              [-39.987812673558317, -8.196478680985589],
              [-39.987812745153057, -8.196478680260082],
              [-39.988821896854489, -8.196476207546258],
              [-39.988822011298829, -8.196476206495072],
              [-39.988822125720766, -8.196476204006386],
              [-39.988822240102124, -8.196476200080619],
              [-39.98882235442472, -8.196476194718388],
              [-39.988822468670364, -8.196476187920549],
              [-39.988822582820916, -8.196476179688165],
              [-39.988822696858215, -8.196476170022576],
              [-39.988822810764148, -8.196476158925291],
              [-39.988822924520584, -8.19647614639811],
              [-39.988823038109473, -8.196476132442992],
              [-39.988823151512733, -8.19647611706216],
              [-39.988823264712344, -8.196476100258076],
              [-39.988823377690323, -8.196476082033371],
              [-39.9888234904287, -8.196476062390982],
              [-39.988823602909569, -8.196476041334037],
              [-39.988823715115032, -8.196476018865864],
              [-39.988823827027254, -8.196475994990012],
              [-39.988823938628457, -8.196475969710328],
              [-39.988824049900899, -8.196475943030803],
              [-39.98882416082688, -8.196475914955654],
              [-39.988824271388779, -8.19647588548937],
              [-39.98882438156901, -8.196475854636633],
              [-39.988824491350087, -8.196475822402348],
              [-39.98882460071453, -8.196475788791654],
              [-39.988824709644952, -8.196475753809855],
              [-39.988824818124058, -8.196475717462549],
              [-39.988824926134598, -8.196475679755491],
              [-39.988825033659403, -8.1964756406947],
              [-39.988825140681385, -8.196475600286357],
              [-39.988825247183513, -8.196475558536878],
              [-39.988825353148854, -8.196475515452939],
              [-39.988825458560619, -8.196475471041351],
              [-39.988825563401974, -8.196475425309186],
              [-39.988825667656315, -8.196475378263722],
              [-39.988825771307035, -8.196475329912435],
              [-39.988825874337657, -8.196475280263012],
              [-39.988825976731846, -8.196475229323337],
              [-39.988826078473274, -8.196475177101492],
              [-39.988826179545789, -8.196475123605826],
              [-39.988826279933349, -8.196475068844823],
              [-39.988826379619937, -8.196475012827152],
              [-39.988826478589772, -8.196474955561754],
              [-39.988826576827087, -8.196474897057724],
              [-39.98882667431625, -8.196474837324351],
              [-39.988826771041801, -8.196474776371133],
              [-39.988826866988354, -8.196474714207788],
              [-39.988826962140649, -8.196474650844156],
              [-39.988827056483572, -8.196474586290343],
              [-39.988827150002109, -8.196474520556594],
              [-39.98882724268141, -8.196474453653364],
              [-39.988827334506723, -8.196474385591257],
              [-39.988827425463469, -8.196474316381137],
              [-39.988827515537203, -8.196474246033997],
              [-39.988827604713578, -8.196474174560997],
              [-39.988827692978425, -8.196474101973502],
              [-39.988827780317727, -8.196474028283074],
              [-39.9888278667176, -8.196473953501419],
              [-39.988827952164307, -8.196473877640399],
              [-39.98882803664425, -8.196473800712109],
              [-39.988828120144007, -8.196473722728756],
              [-39.988828202650311, -8.196473643702751],
              [-39.988828284150053, -8.196473563646606],
              [-39.988828364630251, -8.196473482573106],
              [-39.988828444078166, -8.19647340049511],
              [-39.988828522481107, -8.196473317425657],
              [-39.988828599826647, -8.196473233378001],
              [-39.988828676102464, -8.196473148365463],
              [-39.988828751296481, -8.196473062401568],
              [-39.988828825396695, -8.196472975499958],
              [-39.988828898391375, -8.196472887674473],
              [-39.988828970268855, -8.196472798939041],
              [-39.988829041017766, -8.196472709307804],
              [-39.988829110626838, -8.196472618795013],
              [-39.988829179085009, -8.196472527415027],
              [-39.988829246381393, -8.196472435182395],
              [-39.988829312505302, -8.196472342111747],
              [-39.988829377446216, -8.196472248217905],
              [-39.988829441193822, -8.196472153515783],
              [-39.988829503737989, -8.196472058020445],
              [-39.988829565068741, -8.196471961747049],
              [-39.988829625176365, -8.196471864710917],
              [-39.988829684051318, -8.196471766927482],
              [-39.988829741684178, -8.196471668412247],
              [-39.988829798065858, -8.196471569180941],
              [-39.988829853187347, -8.196471469249282],
              [-39.9888299070399, -8.196471368633157],
              [-39.988829959614961, -8.196471267348601],
              [-39.988830010904145, -8.196471165411664],
              [-39.988830060899353, -8.196471062838596],
              [-39.988830109592598, -8.19647095964566],
              [-39.98883015697615, -8.196470855849281],
              [-39.988830203042454, -8.196470751465956],
              [-39.988830247784229, -8.196470646512292],
              [-39.988830291194319, -8.196470541004926],
              [-39.988830333265867, -8.196470434960688],
              [-39.988830373992158, -8.196470328396408],
              [-39.988830413366728, -8.196470221329001],
              [-39.988830451383315, -8.19647011377551],
              [-39.988830488035873, -8.196470005753049],
              [-39.988830505341447, -8.196470036384506],
              [-39.989051805769385, -8.195803840484409],
              [-39.989051820973607, -8.195803879996365],
              [-39.989051848077388, -8.195803799687265],
              [-39.989051875935111, -8.19580371963468],
              [-39.989051904544311, -8.195803639845602],
              [-39.989051933902481, -8.195803560327104],
              [-39.989051964007054, -8.195803481086067],
              [-39.989051994855387, -8.195803402129519],
              [-39.989052026444767, -8.195803323464345],
              [-39.98905205877243, -8.195803245097503],
              [-39.989052091835525, -8.195803167035784],
              [-39.989052125631169, -8.195803089286109],
              [-39.989052160156376, -8.195803011855286],
              [-39.989052195408135, -8.195802934750125],
              [-39.989052231383319, -8.195802857977348],
              [-39.989052268078808, -8.195802781543716],
              [-39.98905230549137, -8.195802705455948],
              [-39.989052343617722, -8.195802629720717],
              [-39.98905238245451, -8.19580255434469],
              [-39.989052421998331, -8.195802479334425],
              [-39.989052462245724, -8.195802404696554],
              [-39.989052503193129, -8.195802330437601],
              [-39.989052544836987, -8.195802256564081],
              [-39.98905258717361, -8.195802183082538],
              [-39.989052630199311, -8.195802109999351],
              [-39.989052673910308, -8.195802037320961],
              [-39.989052718302752, -8.195801965053731],
              [-39.98905276337274, -8.195801893204017],
              [-39.989052809116345, -8.195801821778153],
              [-39.989052855529536, -8.195801750782325],
              [-39.989052902608229, -8.19580168022285],
              [-39.989052950348309, -8.195801610105876],
              [-39.989052998745606, -8.195801540437591],
              [-39.989053047795821, -8.195801471224028],
              [-39.989053097494683, -8.195801402471355],
              [-39.989053147837829, -8.195801334185575],
              [-39.989053198820834, -8.195801266372676],
              [-39.989053250439213, -8.195801199038621],
              [-39.989053302688461, -8.195801132189308],
              [-39.989053355563968, -8.195801065830588],
              [-39.989053409061114, -8.195800999968315],
              [-39.989053463175189, -8.195800934608224],
              [-39.989053517901453, -8.195800869756122],
              [-39.989053573235097, -8.195800805417642],
              [-39.989053629171273, -8.195800741598438],
              [-39.989053685705059, -8.195800678304131],
              [-39.989053742831501, -8.195800615540264],
              [-39.989053800545584, -8.195800553312363],
              [-39.989053858842247, -8.19580049162585],
              [-39.989053917716369, -8.195800430486191],
              [-39.989053977162776, -8.195800369898707],
              [-39.98905403717626, -8.195800309868737],
              [-39.989054097751577, -8.19580025040155],
              [-39.989054158883363, -8.19580019150234],
              [-39.989054220566274, -8.195800133176313],
              [-39.989054282794918, -8.195800075428558],
              [-39.989054345563801, -8.195800018264171],
              [-39.989054408867439, -8.195799961688152],
              [-39.989054472700261, -8.195799905705451],
              [-39.98905453705666, -8.195799850321036],
              [-39.989054601931009, -8.195799795539719],
              [-39.989054667317596, -8.195799741366299],
              [-39.989054733210686, -8.195799687805577],
              [-39.989054799604503, -8.195799634862235],
              [-39.989054866493213, -8.195799582540877],
              [-39.98905493387096, -8.195799530846143],
              [-39.989055001731806, -8.195799479782574],
              [-39.989055070069824, -8.195799429354613],
              [-39.989055138878989, -8.195799379566731],
              [-39.989055208153275, -8.195799330423247],
              [-39.989055277886607, -8.195799281928529],
              [-39.989055348072853, -8.195799234086795],
              [-39.98905541870586, -8.195799186902244],
              [-39.989055489779432, -8.195799140379036],
              [-39.98905556128733, -8.195799094521229],
              [-39.989055633223266, -8.195799049332873],
              [-39.989055705580931, -8.195799004817902],
              [-39.989055778354015, -8.195798960980271],
              [-39.989055851536072, -8.195798917823801],
              [-39.989055925120709, -8.195798875352267],
              [-39.98905599910146, -8.195798833569402],
              [-39.989056073471829, -8.195798792478904],
              [-39.989056148225309, -8.195798752084331],
              [-39.989056223355327, -8.195798712389266],
              [-39.989056298855274, -8.195798673397174],
              [-39.989056374718551, -8.195798635111487],
              [-39.989056450938477, -8.195798597535545],
              [-39.989056527508382, -8.195798560672682],
              [-39.989056604421535, -8.195798524526095],
              [-39.989056681671194, -8.195798489098971],
              [-39.989056759250552, -8.195798454394444],
              [-39.989056837152837, -8.195798420415546],
              [-39.989056915371187, -8.195798387165253],
              [-39.989056993898757, -8.195798354646454],
              [-39.989057072728649, -8.195798322862041],
              [-39.989057151853928, -8.195798291814777],
              [-39.989057231267665, -8.195798261507433],
              [-39.989057310962885, -8.195798231942605],
              [-39.989057390932601, -8.195798203122951],
              [-39.989057471169794, -8.195798175050962],
              [-39.989057551667628, -8.195798147728706],
              [-39.989057510543681, -8.195798128649029],
              [-39.989730931809142, -8.1955730954486],
              [-39.989730965422332, -8.195573085060916],
              [-39.989730992876623, -8.195573076028428],
              [-39.989731020359741, -8.195573067083387],
              [-39.989731047871416, -8.195573058225905],
              [-39.989731075411363, -8.195573049456042],
              [-39.98973110297927, -8.195573040773876],
              [-39.989731130574896, -8.19557303217953],
              [-39.989731158197948, -8.195573023673068],
              [-39.989731185848129, -8.195573015254592],
              [-39.989731213525161, -8.195573006924192],
              [-39.989731241228782, -8.195572998681914],
              [-39.989731268958693, -8.19557299052788],
              [-39.989731296714595, -8.195572982462188],
              [-39.98973132449624, -8.195572974484895],
              [-39.989731352303323, -8.195572966596062],
              [-39.989731380135552, -8.195572958795816],
              [-39.989731407992672, -8.1955729510842],
              [-39.989731435874376, -8.195572943461304],
              [-39.989731463780373, -8.19557293592721],
              [-39.989731491710401, -8.195572928481953],
              [-39.989731519664161, -8.195572921125725],
              [-39.989731547641355, -8.195572913858465],
              [-39.989731575641734, -8.195572906680315],
              [-39.989731603664985, -8.195572899591379],
              [-39.989731631710825, -8.195572892591654],
              [-39.989731659778975, -8.19557288568126],
              [-39.989731687869138, -8.195572878860247],
              [-39.98973171598103, -8.195572872128682],
              [-39.989731744114366, -8.195572865486636],
              [-39.989731772268868, -8.195572858934225],
              [-39.989731800444225, -8.19557285247144],
              [-39.989731828640181, -8.195572846098408],
              [-39.989731856856416, -8.195572839815165],
              [-39.989731885092681, -8.195572833621764],
              [-39.989731913348649, -8.19557282751831],
              [-39.989731941624044, -8.195572821504827],
              [-39.98973196991858, -8.195572815581377],
              [-39.989731998231967, -8.19557280974804],
              [-39.989732026563928, -8.195572804004851],
              [-39.989732054914143, -8.195572798351931],
              [-39.989732083282355, -8.195572792789275],
              [-39.989732111668268, -8.195572787316941],
              [-39.989732140071581, -8.195572781935009],
              [-39.989732168492004, -8.195572776643516],
              [-39.989732196929253, -8.195572771442514],
              [-39.989732225383051, -8.195572766332102],
              [-39.989732253853063, -8.195572761312281],
              [-39.989732282339048, -8.19557275638312],
              [-39.989732310840694, -8.195572751544672],
              [-39.989732339357708, -8.195572746796968],
              [-39.989732367889808, -8.195572742140079],
              [-39.9897323964367, -8.195572737574039],
              [-39.989732424998074, -8.195572733098931],
              [-39.989732453573673, -8.19557272871474],
              [-39.98973248216317, -8.195572724421551],
              [-39.989732510766295, -8.195572720219397],
              [-39.98973253938275, -8.1955727161083],
              [-39.989732568012251, -8.195572712088358],
              [-39.989732596654484, -8.195572708159538],
              [-39.989732625309188, -8.195572704321959],
              [-39.989732653976027, -8.195572700575626],
              [-39.989732682654761, -8.195572696920548],
              [-39.989732711345049, -8.195572693356802],
              [-39.989732740046641, -8.195572689884379],
              [-39.989732768759211, -8.195572686503356],
              [-39.989732797482475, -8.195572683213763],
              [-39.989732826216141, -8.195572680015619],
              [-39.989732854959918, -8.195572676908961],
              [-39.989732883713529, -8.195572673893816],
              [-39.989732912476647, -8.195572670970259],
              [-39.989732941248981, -8.195572668138256],
              [-39.989732970030268, -8.195572665397867],
              [-39.989732998820195, -8.195572662749125],
              [-39.98973302761847, -8.195572660192015],
              [-39.989733056424782, -8.195572657726649],
              [-39.989733085238875, -8.195572655352958],
              [-39.989733114060428, -8.195572653071007],
              [-39.98973314288915, -8.195572650880852],
              [-39.989733171724744, -8.195572648782456],
              [-39.989733200566924, -8.195572646775855],
              [-39.9897332294154, -8.195572644861096],
              [-39.989733258269865, -8.195572643038192],
              [-39.989733287130022, -8.195572641307164],
              [-39.989733315995586, -8.195572639668001],
              [-39.989733344866259, -8.195572638120741],
              [-39.989733373741743, -8.195572636665432],
              [-39.989733402621766, -8.195572635302019],
              [-39.989733431505996, -8.195572634030546],
              [-39.989733460394156, -8.195572632851039],
              [-39.989733489285975, -8.195572631763538],
              [-39.989733518181097, -8.195572630767977],
              [-39.989733547079283, -8.195572629864438],
              [-39.989733575980217, -8.195572629052885],
              [-39.989733604883611, -8.195572628333343],
              [-39.989733633789164, -8.195572627705809],
              [-39.989733662696572, -8.195572627170302],
              [-39.989733691605551, -8.195572626726799],
              [-39.989733720515822, -8.195572626375373],
              [-39.989733749427067, -8.195572626115929],
              [-39.989733778338874, -8.195572625949236],
              [-39.990972497818625, -8.195569584745943],
              [-39.990972602347632, -8.195569583526041],
              [-39.990972706855594, -8.1955695811069],
              [-39.990972811328675, -8.195569577488856],
              [-39.990972915753012, -8.195569572672392],
              [-39.990973020114744, -8.195569566658168],
              [-39.990973124400035, -8.195569559446954],
              [-39.990973228595081, -8.195569551039718],
              [-39.990973332686032, -8.195569541437564],
              [-39.990973436659104, -8.195569530641782],
              [-39.990973540500505, -8.195569518653814],
              [-39.990973644196465, -8.195569505475232],
              [-39.990973747733207, -8.195569491107758],
              [-39.990973851097039, -8.195569475553334],
              [-39.990973954274239, -8.195569458814015],
              [-39.990974057251101, -8.195569440891999],
              [-39.990974160014005, -8.195569421789706],
              [-39.990974262549301, -8.195569401509621],
              [-39.990974364843403, -8.195569380054474],
              [-39.990974466882726, -8.195569357427081],
              [-39.990974568653776, -8.195569333630459],
              [-39.990974670143011, -8.195569308667752],
              [-39.990974771336987, -8.195569282542294],
              [-39.990974872222289, -8.195569255257523],
              [-39.990974972785551, -8.195569226817087],
              [-39.990975073013423, -8.195569197224723],
              [-39.990975172892604, -8.19556916648437],
              [-39.990975272409869, -8.195569134600113],
              [-39.990975371552004, -8.195569101576179],
              [-39.990975470305891, -8.195569067416917],
              [-39.990975568658385, -8.195569032126922],
              [-39.990975666596462, -8.195568995710795],
              [-39.990975764107148, -8.195568958173396],
              [-39.99097586117751, -8.195568919519749],
              [-39.99097595779466, -8.195568879754921],
              [-39.990976053945801, -8.195568838884212],
              [-39.990976149618163, -8.195568796913017],
              [-39.990976244799086, -8.195568753846942],
              [-39.990976339475914, -8.195568709691655],
              [-39.990976433636114, -8.195568664453059],
              [-39.9909765272672, -8.195568618137122],
              [-39.99097662035674, -8.195568570749998],
              [-39.990976712892383, -8.195568522297942],
              [-39.990976804861894, -8.195568472787427],
              [-39.990976896253052, -8.195568422225],
              [-39.99097698705372, -8.195568370617355],
              [-39.990977077251905, -8.195568317971356],
              [-39.990977166835584, -8.195568264293966],
              [-39.990977255792934, -8.195568209592306],
              [-39.99097734411211, -8.195568153873616],
              [-39.990977431781438, -8.195568097145323],
              [-39.990977518789272, -8.195568039414935],
              [-39.990977605124058, -8.195567980690088],
              [-39.990977690774393, -8.195567920978599],
              [-39.990977775728872, -8.195567860288362],
              [-39.990977859976255, -8.195567798627442],
              [-39.990977943505364, -8.195567736004],
              [-39.990978026305122, -8.195567672426352],
              [-39.990978108364523, -8.195567607902928],
              [-39.990978189672731, -8.195567542442308],
              [-39.990978270218925, -8.19556747605313],
              [-39.990978349992446, -8.195567408744218],
              [-39.990978428982721, -8.195567340524512],
              [-39.990978507179229, -8.195567271403036],
              [-39.99097858457165, -8.19556720138897],
              [-39.990978661149697, -8.195567130491597],
              [-39.990978736903223, -8.195567058720322],
              [-39.990978811822174, -8.19556698608465],
              [-39.990978885896624, -8.195566912594233],
              [-39.990978959116731, -8.19556683825879],
              [-39.990979031472811, -8.195566763088188],
              [-39.990979102955258, -8.195566687092438],
              [-39.990979173554585, -8.195566610281556],
              [-39.990979243261421, -8.195566532665758],
              [-39.99097931206655, -8.195566454255349],
              [-39.990979379960841, -8.195566375060723],
              [-39.99097944693527, -8.195566295092343],
              [-39.990979512980964, -8.195566214360859],
              [-39.990979578089167, -8.195566132876962],
              [-39.990979642251247, -8.195566050651449],
              [-39.990979705458699, -8.195565967695256],
              [-39.990979767703116, -8.195565884019356],
              [-39.990979828976286, -8.19556579963484],
              [-39.990979889270051, -8.195565714552925],
              [-39.990979948576417, -8.195565628784889],
              [-39.990980006887526, -8.195565542342068],
              [-39.990980064195639, -8.195565455235972],
              [-39.99098012049317, -8.195565367478142],
              [-39.990980175772656, -8.195565279080187],
              [-39.990980230026729, -8.19556519005385],
              [-39.990980283248227, -8.195565100410956],
              [-39.990980335430095, -8.195565010163349],
              [-39.99098038656539, -8.195564919323058],
              [-39.990980436647362, -8.195564827902075],
              [-39.990980485669326, -8.19556473591255],
              [-39.990980533624814, -8.195564643366689],
              [-39.990980580507447, -8.195564550276762],
              [-39.990980626311035, -8.195564456655113],
              [-39.990980671029476, -8.195564362514117],
              [-39.99098071465685, -8.195564267866311],
              [-39.990980745161622, -8.195564228464722],
              [-39.991539328400229, -8.194333749540055],
              [-39.99153932003928, -8.194333760014411],
              [-39.991539360688868, -8.194333669142503],
              [-39.991539400332741, -8.194333577830607],
              [-39.991539438966029, -8.194333486089748],
              [-39.991539476584123, -8.194333393931023],
              [-39.991539513182424, -8.194333301365601],
              [-39.991539548756542, -8.194333208404659],
              [-39.991539583302142, -8.19433311505944],
              [-39.991539616815061, -8.194333021341274],
              [-39.991539649291234, -8.194332927261499],
              [-39.991539680726746, -8.194332832831453],
              [-39.991539711117774, -8.194332738062613],
              [-39.991539740460645, -8.194332642966447],
              [-39.991539768751814, -8.194332547554442],
              [-39.991539795987862, -8.194332451838131],
              [-39.991539822165485, -8.194332355829125],
              [-39.991539847281516, -8.194332259539026],
              [-39.991539871332911, -8.194332162979478],
              [-39.991539894316773, -8.194332066162197],
              [-39.991539916230309, -8.194331969098849],
              [-39.99153993707089, -8.194331871801234],
              [-39.991539956835958, -8.194331774281096],
              [-39.991539975523146, -8.194331676550217],
              [-39.991539993130175, -8.194331578620465],
              [-39.99154000965494, -8.194331480503669],
              [-39.991540025095432, -8.194331382211693],
              [-39.99154003944976, -8.194331283756455],
              [-39.991540052716211, -8.194331185149833],
              [-39.991540064893172, -8.194331086403773],
              [-39.991540075979181, -8.194330987530245],
              [-39.991540085972879, -8.194330888541224],
              [-39.991540094873059, -8.194330789448642],
              [-39.991540102678663, -8.194330690264517],
              [-39.991540109388716, -8.194330591000856],
              [-39.991540115002429, -8.194330491669643],
              [-39.991540119519122, -8.194330392282923],
              [-39.99154012293824, -8.194330292852705],
              [-39.991540125259377, -8.194330193391044],
              [-39.991540126482242, -8.194330093909945],
              [-39.991540126606679, -8.194329994421471],
              [-39.99154012563271, -8.194329894937649],
              [-39.991540123560419, -8.194329795470528],
              [-39.991540120390063, -8.194329696032115],
              [-39.991540116122032, -8.194329596634475],
              [-39.99154011075683, -8.194329497289644],
              [-39.99154010429514, -8.194329398009621],
              [-39.991540096737701, -8.194329298806432],
              [-39.991540088085458, -8.194329199692067],
              [-39.991540078339433, -8.194329100678518],
              [-39.991540067500829, -8.194329001777778],
              [-39.991540055570944, -8.194328903001818],
              [-39.991540042551222, -8.194328804362549],
              [-39.991540028443254, -8.194328705871976],
              [-39.991540013248716, -8.194328607541957],
              [-39.991539996969472, -8.194328509384407],
              [-39.991539979607502, -8.194328411411224],
              [-39.991539961164875, -8.194328313634259],
              [-39.991539941643836, -8.19432821606533],
              [-39.991539921046751, -8.194328118716271],
              [-39.991539899376107, -8.19432802159883],
              [-39.991539876634533, -8.194327924724782],
              [-39.991539852824779, -8.194327828105797],
              [-39.991539827949723, -8.194327731753626],
              [-39.991539802012383, -8.194327635679905],
              [-39.991539775015887, -8.194327539896282],
              [-39.991539746963497, -8.194327444414286],
              [-39.991539717858629, -8.194327349245539],
              [-39.991539687704801, -8.194327254401514],
              [-39.99153965650563, -8.19432715989373],
              [-39.991539624264917, -8.19432706573358],
              [-39.991539590986584, -8.194326971932474],
              [-39.991539556674624, -8.194326878501764],
              [-39.991539521333188, -8.194326785452787],
              [-39.991539484966559, -8.194326692796754],
              [-39.991539447579164, -8.194326600544871],
              [-39.991539409175495, -8.194326508708349],
              [-39.991539369760218, -8.194326417298264],
              [-39.991539329338089, -8.194326326325706],
              [-39.991539287914001, -8.194326235801672],
              [-39.99153924549298, -8.19432614573711],
              [-39.991539202080141, -8.194326056142915],
              [-39.991539157680755, -8.194325967029906],
              [-39.991539112300181, -8.194325878408897],
              [-39.991539065943911, -8.194325790290625],
              [-39.991539018617559, -8.194325702685749],
              [-39.991538970326857, -8.194325615604832],
              [-39.991538921077634, -8.194325529058446],
              [-39.991538870875871, -8.194325443057057],
              [-39.991538819727616, -8.194325357611064],
              [-39.991538767639078, -8.194325272730795],
              [-39.99153871461656, -8.194325188426548],
              [-39.991538660666471, -8.194325104708522],
              [-39.991538605795334, -8.194325021586849],
              [-39.9915385500098, -8.194324939071572],
              [-39.991538493316604, -8.194324857172651],
              [-39.991538435722632, -8.194324775900059],
              [-39.991538377234825, -8.194324695263575],
              [-39.991538317860282, -8.194324615272995],
              [-39.991538257606187, -8.194324535937968],
              [-39.991538196479816, -8.194324457268097],
              [-39.991538216037313, -8.194324436751188],
              [-39.990746885857511, -8.193317500376898],
              [-39.990746857047064, -8.193317488011422],
              [-39.990746842963631, -8.193317470136702],
              [-39.990746828835185, -8.193317452297313],
              [-39.990746814661762, -8.193317434493311],
              [-39.990746800443524, -8.193317416724867],
              [-39.990746786180516, -8.193317398992075],
              [-39.9907467718728, -8.193317381295023],
              [-39.990746757520533, -8.193317363633859],
              [-39.990746743123758, -8.193317346008673],
              [-39.990746728682581, -8.193317328419594],
              [-39.990746714197087, -8.193317310866711],
              [-39.99074669966739, -8.193317293350152],
              [-39.990746685093548, -8.193317275870006],
              [-39.99074667047568, -8.19331725842644],
              [-39.990746655813851, -8.193317241019484],
              [-39.990746641108196, -8.193317223649281],
              [-39.990746626358771, -8.193317206315973],
              [-39.990746611565669, -8.193317189019652],
              [-39.990746596729011, -8.193317171760381],
              [-39.990746581848875, -8.193317154538329],
              [-39.990746566925345, -8.19331713735356],
              [-39.99074655195853, -8.193317120206245],
              [-39.990746536948507, -8.193317103096419],
              [-39.990746521895403, -8.193317086024232],
              [-39.990746506799297, -8.193317068989767],
              [-39.990746491660246, -8.193317051993178],
              [-39.990746476478407, -8.193317035034513],
              [-39.990746461253835, -8.193317018113925],
              [-39.990746445986666, -8.193317001231492],
              [-39.99074643067695, -8.193316984387343],
              [-39.990746415324807, -8.193316967581566],
              [-39.990746399930323, -8.193316950814275],
              [-39.990746384493598, -8.193316934085559],
              [-39.990746369014751, -8.193316917395558],
              [-39.990746353493861, -8.193316900744341],
              [-39.990746337931029, -8.193316884132058],
              [-39.990746322326338, -8.193316867558771],
              [-39.990746306679902, -8.193316851024607],
              [-39.990746290991844, -8.193316834529659],
              [-39.990746275262211, -8.193316818074013],
              [-39.990746259491146, -8.193316801657822],
              [-39.990746243678721, -8.193316785281153],
              [-39.990746227825049, -8.193316768944158],
              [-39.990746211930237, -8.193316752646881],
              [-39.990746195994362, -8.19331673638945],
              [-39.990746180017553, -8.193316720171961],
              [-39.99074616399988, -8.19331670399451],
              [-39.990746147941458, -8.193316687857221],
              [-39.990746131842414, -8.19331667176016],
              [-39.990746115702798, -8.193316655703491],
              [-39.990746099522767, -8.193316639687254],
              [-39.990746083302383, -8.19331662371158],
              [-39.990746067041762, -8.193316607776554],
              [-39.990746050741016, -8.193316591882292],
              [-39.990746034400232, -8.19331657602886],
              [-39.990746018019522, -8.193316560216413],
              [-39.990746001598986, -8.193316544445016],
              [-39.990745985138723, -8.193316528714773],
              [-39.990745968638841, -8.193316513025762],
              [-39.990745952099452, -8.193316497378159],
              [-39.990745935520657, -8.193316481771939],
              [-39.990745918902547, -8.193316466207325],
              [-39.990745902245251, -8.19331645068435],
              [-39.990745885548847, -8.193316435203114],
              [-39.990745868813462, -8.193316419763701],
              [-39.990745852039197, -8.193316404366264],
              [-39.990745835226157, -8.193316389010851],
              [-39.990745818374435, -8.193316373697579],
              [-39.990745801484159, -8.193316358426571],
              [-39.990745784555415, -8.193316343197871],
              [-39.990745767588329, -8.193316328011583],
              [-39.990745750582995, -8.193316312867827],
              [-39.990745733539526, -8.193316297766696],
              [-39.990745716458029, -8.193316282708301],
              [-39.990745699338611, -8.193316267692682],
              [-39.99074568218137, -8.193316252719981],
              [-39.990745664986434, -8.193316237790263],
              [-39.990745647753897, -8.193316222903647],
              [-39.990745630483879, -8.193316208060194],
              [-39.990745613176486, -8.193316193260063],
              [-39.990745595831811, -8.193316178503302],
              [-39.990745578449982, -8.193316163789977],
              [-39.99074556103109, -8.193316149120218],
              [-39.990745543575279, -8.193316134494134],
              [-39.990745526082634, -8.193316119911771],
              [-39.99074550855326, -8.193316105373235],
              [-39.990745490987287, -8.193316090878648],
              [-39.990745473384813, -8.193316076428088],
              [-39.990745455745952, -8.193316062021621],
              [-39.99074543807081, -8.193316047659364],
              [-39.990745420359509, -8.193316033341381],
              [-39.99074540261217, -8.193316019067808],
              [-39.99074538482887, -8.193316004838698],
              [-39.990745367009751, -8.193315990654131],
              [-39.990745349154913, -8.193315976514244],
              [-39.990745331264478, -8.193315962419105],
              [-39.990745313338564, -8.193315948368774],
              [-39.990745295377252, -8.193315934363378],
              [-39.990745277380682, -8.193315920402981],
              [-39.990745259348977, -8.19331590648768],
              [-39.990745247885073, -8.193315876442876],
              [-39.989727263688167, -8.192532343094397],
              [-39.989727269571141, -8.192532344489594],
              [-39.989727221734427, -8.192532307921324],
              [-39.989727173654984, -8.192532271670416],
              [-39.989727125334944, -8.192532235738494],
              [-39.98972707677644, -8.192532200127113],
              [-39.989727027981594, -8.192532164837871],
              [-39.989726978952582, -8.19253212987231],
              [-39.989726929691543, -8.192532095231959],
              [-39.989726880200671, -8.192532060918376],
              [-39.989726830482141, -8.192532026933064],
              [-39.989726780538149, -8.192531993277489],
              [-39.989726730370883, -8.1925319599532],
              [-39.989726679982589, -8.192531926961633],
              [-39.989726629375454, -8.192531894304212],
              [-39.989726578551739, -8.192531861982427],
              [-39.98972652751366, -8.192531829997685],
              [-39.989726476263492, -8.192531798351398],
              [-39.989726424803486, -8.192531767044953],
              [-39.989726373135909, -8.192531736079745],
              [-39.989726321263056, -8.192531705457135],
              [-39.989726269187187, -8.192531675178481],
              [-39.989726216910611, -8.19253164524511],
              [-39.989726164435659, -8.192531615658343],
              [-39.989726111764618, -8.192531586419506],
              [-39.989726058899812, -8.192531557529859],
              [-39.989726005843593, -8.192531528990694],
              [-39.989725952598285, -8.192531500803245],
              [-39.989725899166238, -8.192531472968781],
              [-39.98972584554982, -8.19253144548852],
              [-39.989725791751383, -8.192531418363661],
              [-39.989725737773306, -8.192531391595441],
              [-39.989725683617976, -8.192531365185015],
              [-39.989725629287776, -8.192531339133531],
              [-39.989725574785098, -8.192531313442153],
              [-39.989725520112366, -8.19253128811205],
              [-39.989725465271967, -8.192531263144279],
              [-39.989725410266338, -8.192531238539999],
              [-39.989725355097889, -8.192531214300264],
              [-39.98972529976907, -8.192531190426125],
              [-39.989725244282319, -8.192531166918675],
              [-39.98972518864008, -8.192531143778899],
              [-39.989725132844796, -8.192531121007894],
              [-39.989725076898971, -8.192531098606604],
              [-39.989725020805018, -8.192531076576023],
              [-39.98972496456544, -8.192531054917152],
              [-39.989724908182716, -8.192531033630932],
              [-39.989724851659332, -8.192531012718286],
              [-39.989724794997784, -8.192530992180155],
              [-39.989724738200565, -8.192530972017455],
              [-39.989724681270189, -8.192530952231053],
              [-39.989724624209167, -8.192530932821835],
              [-39.989724567020026, -8.192530913790655],
              [-39.989724509705248, -8.192530895138326],
              [-39.98972445226741, -8.192530876865712],
              [-39.989724394709015, -8.192530858973608],
              [-39.989724337032619, -8.192530841462785],
              [-39.989724279240754, -8.192530824334026],
              [-39.98972422133599, -8.192530807588101],
              [-39.989724163320858, -8.192530791225712],
              [-39.989724105197922, -8.192530775247628],
              [-39.989724046969762, -8.192530759654515],
              [-39.989723988638922, -8.192530744447057],
              [-39.989723930207994, -8.192530729625995],
              [-39.989723871679558, -8.19253071519192],
              [-39.989723813056187, -8.19253070114547],
              [-39.989723754340467, -8.192530687487279],
              [-39.989723695534991, -8.192530674217933],
              [-39.98972363664236, -8.192530661338044],
              [-39.989723577665153, -8.192530648848162],
              [-39.989723518605992, -8.192530636748858],
              [-39.98972345946747, -8.192530625040643],
              [-39.989723400252196, -8.192530613724019],
              [-39.989723340962804, -8.192530602799533],
              [-39.989723281601869, -8.192530592267637],
              [-39.989723222172046, -8.192530582128793],
              [-39.989723162675944, -8.192530572383466],
              [-39.989723103116184, -8.192530563032056],
              [-39.98972304349541, -8.192530554074995],
              [-39.989722983816229, -8.192530545512712],
              [-39.989722924081278, -8.192530537345522],
              [-39.989722864293221, -8.192530529573826],
              [-39.989722804454644, -8.192530522197947],
              [-39.989722744568226, -8.19253051521822],
              [-39.989722684636604, -8.192530508634926],
              [-39.98972262466242, -8.192530502448408],
              [-39.989722564648311, -8.192530496658918],
              [-39.989722504596926, -8.192530491266696],
              [-39.989722444510932, -8.192530486271979],
              [-39.989722384392962, -8.192530481675023],
              [-39.989722324245669, -8.192530477475977],
              [-39.989722264071709, -8.19253047367507],
              [-39.989722203873733, -8.192530470272445],
              [-39.989722143654419, -8.192530467268263],
              [-39.989722083416389, -8.192530464662658],
              [-39.989722023162336, -8.19253046245575],
              [-39.989721962894883, -8.192530460647607],
              [-39.989721902616701, -8.192530459238339],
              [-39.989721842330475, -8.192530458228021],
              [-39.989721782038828, -8.192530457616648],
              [-39.989721721744438, -8.192530457404265],
              [-39.988595144414944, -8.192533218900945],
              [-39.988595117876478, -8.192533218966149],
              [-39.988595059210638, -8.192533219470805],
              [-39.988595000549303, -8.192533220353173],
              [-39.98859494189491, -8.192533221613262],
              [-39.988594883249917, -8.192533223251001],
              [-39.988594824616769, -8.192533225266317],
              [-39.988594765997924, -8.192533227659116],
              [-39.988594707395819, -8.19253323042933],
              [-39.988594648812892, -8.192533233576814],
              [-39.988594590251644, -8.192533237101445],
              [-39.988594531714455, -8.192533241003101],
              [-39.98859447320379, -8.192533245281572],
              [-39.988594414722122, -8.192533249936698],
              [-39.988594356271847, -8.192533254968312],
              [-39.98859429785545, -8.192533260376162],
              [-39.98859423947534, -8.192533266160051],
              [-39.988594181133976, -8.192533272319711],
              [-39.988594122833767, -8.192533278854924],
              [-39.988594064577185, -8.192533285765393],
              [-39.988594006366647, -8.192533293050825],
              [-39.988593948204567, -8.192533300710931],
              [-39.988593890093398, -8.192533308745384],
              [-39.988593832035562, -8.19253331715384],
              [-39.988593774033468, -8.192533325935978],
              [-39.988593716089561, -8.192533335091396],
              [-39.988593658206234, -8.192533344619724],
              [-39.988593600385947, -8.192533354520565],
              [-39.988593542631065, -8.192533364793492],
              [-39.988593484944033, -8.192533375438082],
              [-39.988593427327253, -8.192533386453904],
              [-39.988593369783132, -8.192533397840505],
              [-39.988593312314073, -8.192533409597392],
              [-39.988593254922478, -8.192533421724065],
              [-39.988593197610747, -8.192533434220026],
              [-39.988593140381248, -8.192533447084772],
              [-39.988593083236417, -8.19253346031771],
              [-39.988593026178599, -8.192533473918358],
              [-39.988592969210188, -8.192533487886115],
              [-39.988592912333573, -8.1925335022204],
              [-39.988592855551133, -8.192533516920623],
              [-39.988592798865227, -8.192533531986152],
              [-39.988592742278222, -8.192533547416375],
              [-39.988592685792504, -8.192533563210629],
              [-39.988592629410398, -8.19253357936825],
              [-39.988592573134284, -8.192533595888616],
              [-39.988592516966499, -8.192533612770969],
              [-39.988592460909402, -8.192533630014657],
              [-39.988592404965338, -8.192533647618907],
              [-39.98859234913661, -8.19253366558303],
              [-39.988592293425583, -8.192533683906257],
              [-39.988592237834574, -8.192533702587827],
              [-39.988592182365906, -8.192533721626955],
              [-39.988592127021889, -8.192533741022833],
              [-39.988592071804831, -8.192533760774678],
              [-39.988592016717071, -8.192533780881657],
              [-39.988591961760861, -8.192533801342924],
              [-39.988591906938538, -8.192533822157632],
              [-39.98859185225237, -8.192533843324879],
              [-39.988591797704643, -8.192533864843837],
              [-39.98859174329764, -8.192533886713575],
              [-39.988591689033633, -8.192533908933173],
              [-39.988591634914897, -8.192533931501721],
              [-39.988591580943663, -8.192533954418266],
              [-39.98859152712221, -8.192533977681842],
              [-39.988591473452786, -8.19253400129149],
              [-39.988591419937634, -8.192534025246234],
              [-39.988591366578973, -8.192534049545047],
              [-39.988591313379032, -8.19253407418695],
              [-39.988591260340051, -8.192534099170887],
              [-39.988591207464246, -8.192534124495824],
              [-39.988591154753806, -8.1925341501607],
              [-39.988591102210954, -8.192534176164449],
              [-39.988591049837865, -8.192534202505959],
              [-39.988590997636727, -8.192534229184178],
              [-39.988590945609729, -8.192534256197909],
              [-39.988590893759046, -8.192534283546095],
              [-39.988590842086843, -8.192534311227597],
              [-39.988590790595275, -8.192534339241233],
              [-39.988590739286501, -8.192534367585832],
              [-39.988590688162645, -8.19253439626023],
              [-39.988590637225855, -8.19253442526319],
              [-39.98859058647826, -8.192534454593549],
              [-39.988590535921965, -8.192534484250059],
              [-39.988590485559108, -8.192534514231481],
              [-39.988590435391785, -8.192534544536556],
              [-39.988590385422071, -8.192534575164027],
              [-39.988590335652077, -8.192534606112616],
              [-39.988590286083863, -8.192534637381019],
              [-39.988590236719489, -8.192534668967935],
              [-39.988590187561066, -8.192534700872047],
              [-39.988590138610611, -8.192534733092035],
              [-39.988590089870165, -8.192534765626537],
              [-39.988590041341766, -8.192534798474203],
              [-39.98858999302746, -8.192534831633688],
              [-39.988589944929238, -8.192534865103507],
              [-39.988589897049138, -8.192534898882371],
              [-39.988589849389136, -8.19253493296883],
              [-39.988589801951242, -8.19253496736146],
              [-39.988589754737419, -8.192535002058806],
              [-39.988589707749647, -8.192535037059452],
              [-39.988589675862229, -8.192535041139022],
              [-39.987700543077693, -8.193201877184798],
              [-39.987700523659306, -8.193201861265885],
              [-39.987700367578299, -8.193201975735111],
              [-39.987700209080053, -8.193202086858273],
              [-39.987700048236825, -8.193202194584746],
              [-39.987699885121906, -8.193202298865433],
              [-39.987699719809683, -8.193202399652797],
              [-39.987699552375489, -8.19320249690089],
              [-39.987699382895634, -8.193202590565338],
              [-39.987699211447399, -8.193202680603553],
              [-39.987699038108936, -8.193202766974384],
              [-39.987698862959242, -8.193202849638544],
              [-39.987698686078161, -8.193202928558298],
              [-39.987698507546327, -8.193203003697676],
              [-39.987698327445123, -8.193203075022444],
              [-39.987698145856626, -8.193203142500071],
              [-39.987697962863635, -8.193203206099822],
              [-39.987697778549553, -8.193203265792695],
              [-39.987697592998401, -8.193203321551495],
              [-39.98769740629475, -8.193203373350766],
              [-39.987697218523707, -8.193203421166938],
              [-39.987697029770878, -8.193203464978199],
              [-39.98769684012229, -8.193203504764602],
              [-39.987696649664393, -8.193203540507968],
              [-39.987696458484002, -8.193203572192001],
              [-39.987696266668266, -8.193203599802287],
              [-39.98769607430463, -8.193203623326232],
              [-39.987695881480775, -8.193203642753121],
              [-39.987695688284589, -8.193203658074101],
              [-39.98769549480415, -8.193203669282148],
              [-39.98769530112763, -8.193203676372196],
              [-39.987695107343342, -8.193203679340996],
              [-39.987694913539592, -8.193203678187208],
              [-39.987694719804743, -8.193203672911348],
              [-39.987694526227109, -8.193203663515829],
              [-39.987694332894911, -8.193203650004905],
              [-39.98769413989627, -8.193203632384778],
              [-39.987693947319187, -8.193203610663451],
              [-39.987693755251435, -8.193203584850851],
              [-39.987693563780567, -8.193203554958689],
              [-39.98769337299386, -8.193203521000651],
              [-39.987693182978269, -8.193203482992192],
              [-39.987692993820438, -8.193203440950635],
              [-39.987692805606564, -8.193203394895141],
              [-39.987692618422457, -8.193203344846731],
              [-39.987692432353427, -8.193203290828169],
              [-39.987692247484304, -8.193203232864134],
              [-39.98769206389936, -8.193203170981011],
              [-39.987691881682267, -8.193203105207006],
              [-39.987691700916088, -8.19320303557212],
              [-39.987691521683232, -8.193202962108103],
              [-39.987691344065396, -8.19320288484842],
              [-39.987691168143527, -8.193202803828294],
              [-39.98769099399783, -8.193202719084661],
              [-39.987690821707695, -8.193202630656138],
              [-39.987690651351649, -8.193202538583067],
              [-39.987690483007341, -8.193202442907372],
              [-39.987690316751532, -8.1932023436727],
              [-39.987690152659965, -8.193202240924265],
              [-39.987689990807468, -8.193202134708915],
              [-39.987689831267822, -8.193202025075054],
              [-39.987689674113732, -8.193201912072697],
              [-39.987689519416847, -8.193201795753303],
              [-39.987689367247683, -8.193201676169917],
              [-39.987689217675587, -8.193201553377088],
              [-39.987689070768766, -8.193201427430704],
              [-39.987688926594167, -8.193201298388228],
              [-39.987688785217529, -8.193201166308455],
              [-39.987688646703248, -8.193201031251638],
              [-39.98768851111452, -8.193200893279295],
              [-39.987688378513127, -8.193200752454338],
              [-39.987688248959515, -8.193200608840986],
              [-39.987688122512729, -8.193200462504644],
              [-39.987687999230417, -8.193200313512063],
              [-39.987687879168782, -8.193200161931152],
              [-39.987687762382542, -8.193200007830976],
              [-39.987687648924918, -8.193199851281824],
              [-39.987687538847666, -8.193199692355012],
              [-39.987687432200943, -8.193199531123026],
              [-39.987687329033342, -8.19319936765933],
              [-39.987687229391938, -8.19319920203845],
              [-39.98768713332209, -8.193199034335885],
              [-39.987687040867641, -8.193198864628053],
              [-39.987686952070717, -8.193198692992354],
              [-39.987686866971778, -8.193198519507],
              [-39.987686785609647, -8.193198344251069],
              [-39.987686708021386, -8.193198167304443],
              [-39.987686634242365, -8.193197988747812],
              [-39.987686564306223, -8.193197808662514],
              [-39.987686498244841, -8.193197627130704],
              [-39.987686436088346, -8.1931974442351],
              [-39.987686377865025, -8.193197260059062],
              [-39.987686323601466, -8.193197074686543],
              [-39.987686273322375, -8.193196888202067],
              [-39.987686227050688, -8.193196700690613],
              [-39.987686184807487, -8.193196512237662],
              [-39.987686146612027, -8.193196322929126],
              [-39.987686112481732, -8.193196132851304],
              [-39.987686082432148, -8.193195942090844],
              [-39.987686056476967, -8.193195750734656],
              [-39.98768603462738, -8.19319555887035],
              [-39.987686003495973, -8.193195581829448],
              [-39.987572448943283, -8.192093189933521],
              [-39.987572450686244, -8.19209319284432],
              [-39.987572435967039, -8.192093059712247],
              [-39.98757241927072, -8.192092926811915],
              [-39.987572400600925, -8.192092794172524],
              [-39.987572379961755, -8.192092661823141],
              [-39.987572357357735, -8.192092529792776],
              [-39.987572332793818, -8.192092398110402],
              [-39.98757230627541, -8.192092266804885],
              [-39.987572277808312, -8.192092135904989],
              [-39.987572247398774, -8.192092005439493],
              [-39.98757221505344, -8.19209187543696],
              [-39.987572180779438, -8.19209174592592],
              [-39.98757214458427, -8.192091616934768],
              [-39.987572106475859, -8.192091488491752],
              [-39.98757206646259, -8.192091360625119],
              [-39.987572024553216, -8.192091233362845],
              [-39.98757198075694, -8.192091106732875],
              [-39.987571935083366, -8.192090980762964],
              [-39.987571887542515, -8.19209085548075],
              [-39.987571838144774, -8.192090730913678],
              [-39.987571786901036, -8.192090607089105],
              [-39.987571733822499, -8.192090484034132],
              [-39.987571678920816, -8.19209036177581],
              [-39.98757162220803, -8.192090240340931],
              [-39.987571563696562, -8.192090119756086],
              [-39.98757150339928, -8.192090000047807],
              [-39.987571441329365, -8.192089881242229],
              [-39.987571377500437, -8.192089763365489],
              [-39.987571311926523, -8.192089646443442],
              [-39.987571244621961, -8.19208953050167],
              [-39.987571175601566, -8.192089415565651],
              [-39.987571104880423, -8.192089301660564],
              [-39.987571032474058, -8.1920891888114],
              [-39.987570958398379, -8.192089077042908],
              [-39.98757088266958, -8.192088966379568],
              [-39.987570805304308, -8.192088856845722],
              [-39.987570726319504, -8.192088748465332],
              [-39.987570645732504, -8.19208864126219],
              [-39.987570563560986, -8.192088535259742],
              [-39.987570479822963, -8.192088430481354],
              [-39.987570394536789, -8.192088326949916],
              [-39.987570307721178, -8.192088224688149],
              [-39.987570219395174, -8.192088123718483],
              [-39.987570129578131, -8.192088024063077],
              [-39.987570038289782, -8.192087925743778],
              [-39.987569945550085, -8.192087828782139],
              [-39.987569851379433, -8.192087733199397],
              [-39.987569755798461, -8.192087639016581],
              [-39.987569658828143, -8.192087546254291],
              [-39.987569560489696, -8.192087454932892],
              [-39.987569460804757, -8.19208736507243],
              [-39.987569359795124, -8.192087276692559],
              [-39.987569257482988, -8.192087189812717],
              [-39.987569153890767, -8.192087104451939],
              [-39.987569049041191, -8.192087020628895],
              [-39.987568942957239, -8.192086938362017],
              [-39.987568835662195, -8.192086857669354],
              [-39.987568727179585, -8.192086778568571],
              [-39.987568617533192, -8.192086701077057],
              [-39.98756850674706, -8.192086625211795],
              [-39.987568394845489, -8.192086550989355],
              [-39.987568281853022, -8.192086478426116],
              [-39.987568167794436, -8.192086407537918],
              [-39.987568052694748, -8.192086338340324],
              [-39.987567936579183, -8.192086270848526],
              [-39.98756781947322, -8.192086205077318],
              [-39.987567701402547, -8.192086141041123],
              [-39.987567582393048, -8.19208607875396],
              [-39.987567462470821, -8.192086018229551],
              [-39.987567341662171, -8.192085959481098],
              [-39.987567219993586, -8.192085902521516],
              [-39.987567097491763, -8.19208584736332],
              [-39.987566974183537, -8.19208579401859],
              [-39.98756685009598, -8.192085742498982],
              [-39.987566725256286, -8.192085692815875],
              [-39.987566599691846, -8.192085644980095],
              [-39.987566473430185, -8.192085599002157],
              [-39.987566346498994, -8.192085554892131],
              [-39.987566218926119, -8.192085512659743],
              [-39.987566090739541, -8.192085472314167],
              [-39.987565961967348, -8.192085433864351],
              [-39.987565832637799, -8.192085397318648],
              [-39.987565702779257, -8.192085362685122],
              [-39.987565572420195, -8.19208532997132],
              [-39.98756544158919, -8.192085299184468],
              [-39.987565310314956, -8.192085270331285],
              [-39.987565178626262, -8.192085243418099],
              [-39.987565046552007, -8.192085218450831],
              [-39.987564914121137, -8.192085195434915],
              [-39.987564781362707, -8.192085174375476],
              [-39.987564648305835, -8.192085155277068],
              [-39.987564514979681, -8.192085138143888],
              [-39.987564381413499, -8.19208512297971],
              [-39.987564247636584, -8.192085109787813],
              [-39.987564113678253, -8.192085098571194],
              [-39.987563979567909, -8.192085089332204],
              [-39.98756384533494, -8.192085082072914],
              [-39.987563711008811, -8.192085076794914],
              [-39.987563576618946, -8.192085073499385],
              [-39.987563442194848, -8.192085072187023],
              [-39.986328907843017, -8.192088091381857],
              [-39.986328844360457, -8.19208809186188],
              [-39.986328780882822, -8.192088092784223],
              [-39.986328717413208, -8.192088094148836],
              [-39.986328653954729, -8.19208809595567],
              [-39.986328590510489, -8.192088098204623],
              [-39.986328527083572, -8.192088100895592],
              [-39.986328463677125, -8.192088104028457],
              [-39.986328400294191, -8.192088107603031],
              [-39.986328336937902, -8.192088111619162],
              [-39.986328273611349, -8.192088116076652],
              [-39.986328210317652, -8.192088120975283],
              [-39.986328147059879, -8.19208812631482],
              [-39.986328083841116, -8.192088132094991],
              [-39.98632802066448, -8.192088138315532],
              [-39.986327957533071, -8.192088144976099],
              [-39.986327894449936, -8.192088152076401],
              [-39.986327831418194, -8.192088159616075],
              [-39.986327768440908, -8.192088167594775],
              [-39.986327705521177, -8.192088176012074],
              [-39.986327642662054, -8.192088184867615],
              [-39.986327579866646, -8.192088194160911],
              [-39.986327517137994, -8.192088203891538],
              [-39.986327454479181, -8.192088214059012],
              [-39.986327391893262, -8.192088224662827],
              [-39.986327329383307, -8.192088235702467],
              [-39.986327266952365, -8.192088247177383],
              [-39.986327204603498, -8.192088259087033],
              [-39.986327142339761, -8.192088271430849],
              [-39.986327080164187, -8.192088284208168],
              [-39.986327018079834, -8.192088297418428],
              [-39.986326956089691, -8.192088311060967],
              [-39.986326894196843, -8.192088325135099],
              [-39.986326832404302, -8.192088339640145],
              [-39.986326770715081, -8.192088354575391],
              [-39.986326709132207, -8.192088369940105],
              [-39.986326647658679, -8.192088385733568],
              [-39.986326586297515, -8.192088401954948],
              [-39.986326525051723, -8.192088418603504],
              [-39.986326463924293, -8.192088435678411],
              [-39.986326402918188, -8.192088453178808],
              [-39.986326342036435, -8.192088471103878],
              [-39.986326281281983, -8.192088489452697],
              [-39.986326220657809, -8.192088508224414],
              [-39.986326160166882, -8.192088527418083],
              [-39.98632609981216, -8.192088547032773],
              [-39.986326039596591, -8.192088567067534],
              [-39.986325979523123, -8.192088587521365],
              [-39.986325919594698, -8.192088608393297],
              [-39.986325859814251, -8.19208862968228],
              [-39.986325800184687, -8.192088651387246],
              [-39.986325740708949, -8.192088673507229],
              [-39.986325681389907, -8.192088696041049],
              [-39.986325622230495, -8.192088718987655],
              [-39.986325563233606, -8.192088742345916],
              [-39.986325504402124, -8.192088766114681],
              [-39.986325445738899, -8.192088790292802],
              [-39.986325387246829, -8.192088814879053],
              [-39.986325328928764, -8.192088839872303],
              [-39.98632527078756, -8.19208886527128],
              [-39.986325212826053, -8.192088891074748],
              [-39.986325155047105, -8.192088917281451],
              [-39.986325097453495, -8.192088943890104],
              [-39.986325040048079, -8.192088970899421],
              [-39.986324982833644, -8.192088998308078],
              [-39.986324925813008, -8.192089026114703],
              [-39.986324868988945, -8.192089054317965],
              [-39.986324812364231, -8.1920890829165],
              [-39.986324755941645, -8.192089111908858],
              [-39.986324699723951, -8.192089141293653],
              [-39.986324643713871, -8.192089171069458],
              [-39.98632458791419, -8.192089201234788],
              [-39.986324532327579, -8.192089231788202],
              [-39.98632447695681, -8.192089262728157],
              [-39.986324421804568, -8.19208929405319],
              [-39.986324366873561, -8.192089325761739],
              [-39.986324312166452, -8.192089357852263],
              [-39.986324257685943, -8.192089390323208],
              [-39.98632420343467, -8.192089423172954],
              [-39.986324149415324, -8.192089456399916],
              [-39.986324095630522, -8.192089490002466],
              [-39.986324042082884, -8.19208952397894],
              [-39.986323988775062, -8.192089558327718],
              [-39.986323935709642, -8.192089593047088],
              [-39.986323882889224, -8.192089628135362],
              [-39.98632383031638, -8.192089663590831],
              [-39.986323777993711, -8.192089699411737],
              [-39.986323725923747, -8.192089735596342],
              [-39.986323674109038, -8.192089772142879],
              [-39.986323622552142, -8.192089809049547],
              [-39.986323571255539, -8.192089846314571],
              [-39.986323520221781, -8.192089883936086],
              [-39.986323469453339, -8.192089921912306],
              [-39.986323418952708, -8.192089960241319],
              [-39.986323368722346, -8.192089998921285],
              [-39.986323318764718, -8.192090037950308],
              [-39.986323269082277, -8.192090077326462],
              [-39.986323219677423, -8.192090117047822],
              [-39.986323170552602, -8.192090157112476],
              [-39.9863231217102, -8.192090197518436],
              [-39.986323103389928, -8.192090185737259],
              [-39.985664427747857, -8.192639060384481],
              [-39.985664388599687, -8.192639038608222],
              [-39.985664220964559, -8.192639174928045],
              [-39.985664050115808, -8.192639307228086],
              [-39.985663876150525, -8.192639435433229],
              [-39.985663699167574, -8.192639559470587],
              [-39.985663519267483, -8.192639679269657],
              [-39.985663336552513, -8.192639794762412],
              [-39.98566315112646, -8.192639905883206],
              [-39.985662963094704, -8.192640012568894],
              [-39.985662772564076, -8.192640114758872],
              [-39.985662579642842, -8.192640212395055],
              [-39.985662384440616, -8.192640305421991],
              [-39.985662187068357, -8.192640393786805],
              [-39.98566198763816, -8.192640477439298],
              [-39.985661786263364, -8.192640556331897],
              [-39.985661583058395, -8.192640630419824],
              [-39.98566137813873, -8.19264069966094],
              [-39.985661171620798, -8.192640764015914],
              [-39.985660963621946, -8.192640823448203],
              [-39.985660754260351, -8.192640877924012],
              [-39.985660543655015, -8.1926409274124],
              [-39.985660331925565, -8.192640971885224],
              [-39.985660119192325, -8.192641011317217],
              [-39.985659905576192, -8.192641045685997],
              [-39.985659691198499, -8.19264107497202],
              [-39.985659476181105, -8.192641099158656],
              [-39.985659260646173, -8.192641118232151],
              [-39.985659044716172, -8.192641132181647],
              [-39.985658828513806, -8.192641140999269],
              [-39.985658612161913, -8.192641144679961],
              [-39.985658395783432, -8.19264114322165],
              [-39.985658179501307, -8.192641136625177],
              [-39.985657963438456, -8.192641124894255],
              [-39.985657747717617, -8.192641108035595],
              [-39.985657532461396, -8.192641086058746],
              [-39.985657317792082, -8.1926410589762],
              [-39.985657103831677, -8.19264102680336],
              [-39.98565689070174, -8.192640989558473],
              [-39.985656678523391, -8.192640947262722],
              [-39.985656467417158, -8.192640899940148],
              [-39.985656257503045, -8.192640847617648],
              [-39.985656048900296, -8.192640790324928],
              [-39.98565584172745, -8.192640728094547],
              [-39.98565563610223, -8.192640660961892],
              [-39.985655432141478, -8.192640588965094],
              [-39.985655229961083, -8.192640512145038],
              [-39.985655029675925, -8.192640430545413],
              [-39.98565483139982, -8.192640344212537],
              [-39.985654635245425, -8.19264025319551],
              [-39.985654441324201, -8.192640157546062],
              [-39.985654249746325, -8.192640057318492],
              [-39.985654060620682, -8.192639952569772],
              [-39.985653874054712, -8.192639843359434],
              [-39.985653690154436, -8.192639729749525],
              [-39.98565350902436, -8.192639611804619],
              [-39.985653330767377, -8.192639489591693],
              [-39.985653155484805, -8.192639363180227],
              [-39.985652983276218, -8.19263923264203],
              [-39.985652814239486, -8.192639098051275],
              [-39.985652648470655, -8.192638959484452],
              [-39.985652486063913, -8.192638817020313],
              [-39.985652327111538, -8.192638670739761],
              [-39.985652171703869, -8.192638520725948],
              [-39.985652019929191, -8.192638367064117],
              [-39.985651871873763, -8.192638209841572],
              [-39.985651727621693, -8.192638049147648],
              [-39.985651587254971, -8.192637885073683],
              [-39.985651450853318, -8.192637717712865],
              [-39.985651318494284, -8.192637547160311],
              [-39.985651190253058, -8.192637373512911],
              [-39.9856510662025, -8.192637196869359],
              [-39.985650946413131, -8.192637017330011],
              [-39.985650830952977, -8.192636834996899],
              [-39.985650719887659, -8.192636649973627],
              [-39.985650613280306, -8.192636462365334],
              [-39.985650511191452, -8.192636272278593],
              [-39.985650413679153, -8.192636079821439],
              [-39.98565032079879, -8.192635885103231],
              [-39.985650232603142, -8.192635688234613],
              [-39.985650149142323, -8.192635489327444],
              [-39.985650070463755, -8.192635288494735],
              [-39.985649996612175, -8.192635085850657],
              [-39.985649927629503, -8.192634881510278],
              [-39.985649863554961, -8.192634675589739],
              [-39.985649804424945, -8.192634468206055],
              [-39.985649750273069, -8.192634259477074],
              [-39.985649701130086, -8.192634049521372],
              [-39.98564965702392, -8.192633838458258],
              [-39.985649617979661, -8.192633626407709],
              [-39.985649584019455, -8.192633413490153],
              [-39.985649555162631, -8.192633199826586],
              [-39.985649531425572, -8.192632985538429],
              [-39.985649512821759, -8.192632770747444],
              [-39.985649499361777, -8.192632555575706],
              [-39.985649491053273, -8.192632340145414],
              [-39.98564948790095, -8.192632124579044],
              [-39.985649489906621, -8.192631908999015],
              [-39.985649497069133, -8.192631693527884],
              [-39.985649509384423, -8.19263147828808],
              [-39.985649526846231, -8.192631263401536],
              [-39.985649521328696, -8.192631228329239],
              [-39.985741856387293, -8.191641927651753],
              [-39.985741843776097, -8.191641913173642],
              [-39.985741851738879, -8.191641822936786],
              [-39.985741858794135, -8.191641732624994],
              [-39.98574186494119, -8.191641642247323],
              [-39.985741870179403, -8.191641551812829],
              [-39.98574187450825, -8.191641461330629],
              [-39.98574187792731, -8.191641370809743],
              [-39.985741880436215, -8.191641280259295],
              [-39.985741882034759, -8.191641189688346],
              [-39.985741882722728, -8.19164109910599],
              [-39.985741882500086, -8.191641008521309],
              [-39.985741881366842, -8.19164091794339],
              [-39.985741879323115, -8.191640827381315],
              [-39.985741876369119, -8.191640736844194],
              [-39.985741872505137, -8.191640646341098],
              [-39.985741867731548, -8.191640555881095],
              [-39.985741862048847, -8.19164046547326],
              [-39.985741855457618, -8.191640375126674],
              [-39.985741847958487, -8.191640284850381],
              [-39.98574183955224, -8.191640194653441],
              [-39.985741830239689, -8.191640104544916],
              [-39.985741820021786, -8.191640014533853],
              [-39.985741808899547, -8.191639924629271],
              [-39.985741796874109, -8.191639834840188],
              [-39.985741783946672, -8.191639745175612],
              [-39.985741770118501, -8.191639655644519],
              [-39.985741755391018, -8.191639566255928],
              [-39.985741739765693, -8.191639477018775],
              [-39.985741723244075, -8.191639387942011],
              [-39.985741705827827, -8.191639299034614],
              [-39.985741687518733, -8.191639210305436],
              [-39.985741668318589, -8.191639121763442],
              [-39.985741648229336, -8.191639033417466],
              [-39.985741627252985, -8.1916389452764],
              [-39.985741605391645, -8.191638857349083],
              [-39.985741582647506, -8.191638769644335],
              [-39.985741559022848, -8.191638682170931],
              [-39.985741534520052, -8.19163859493767],
              [-39.985741509141562, -8.191638507953298],
              [-39.985741482889921, -8.191638421226525],
              [-39.985741455767773, -8.191638334766077],
              [-39.985741427777839, -8.191638248580608],
              [-39.985741398922926, -8.19163816267878],
              [-39.985741369205925, -8.1916380770692],
              [-39.985741338629815, -8.191637991760482],
              [-39.985741307197671, -8.19163790676113],
              [-39.985741274912634, -8.191637822079706],
              [-39.985741241777966, -8.191637737724729],
              [-39.985741207796963, -8.191637653704598],
              [-39.985741172973057, -8.191637570027794],
              [-39.985741137309724, -8.191637486702698],
              [-39.98574110081055, -8.191637403737658],
              [-39.985741063479203, -8.191637321141],
              [-39.985741025319406, -8.191637238921007],
              [-39.98574098633501, -8.191637157085941],
              [-39.985740946529916, -8.191637075644026],
              [-39.985740905908116, -8.191636994603396],
              [-39.985740864473691, -8.191636913972188],
              [-39.985740822230788, -8.191636833758496],
              [-39.985740779183665, -8.191636753970384],
              [-39.985740735336606, -8.19163667461582],
              [-39.985740690694058, -8.191636595702764],
              [-39.985740645260435, -8.191636517239175],
              [-39.985740599040348, -8.191636439232907],
              [-39.985740552038415, -8.191636361691749],
              [-39.985740504259354, -8.191636284623556],
              [-39.985740455707969, -8.191636208035984],
              [-39.985740406389091, -8.191636131936747],
              [-39.985740356307716, -8.191636056333488],
              [-39.985740305468838, -8.191635981233787],
              [-39.985740253877559, -8.191635906645166],
              [-39.985740201539066, -8.191635832575106],
              [-39.985740148458603, -8.191635759031062],
              [-39.9857400946415, -8.191635686020376],
              [-39.985740040093141, -8.191635613550382],
              [-39.985739984819034, -8.191635541628397],
              [-39.985739928824685, -8.191635470261581],
              [-39.985739872115715, -8.19163539945713],
              [-39.98573981469783, -8.191635329222095],
              [-39.985739756576784, -8.191635259563563],
              [-39.985739697758412, -8.191635190488517],
              [-39.985739638248596, -8.191635122003875],
              [-39.98573957805332, -8.191635054116521],
              [-39.985739517178637, -8.191634986833243],
              [-39.985739455630629, -8.191634920160812],
              [-39.985739393415471, -8.191634854105923],
              [-39.985739330539424, -8.191634788675179],
              [-39.985739267008775, -8.191634723875168],
              [-39.985739202829919, -8.191634659712358],
              [-39.985739138009272, -8.191634596193225],
              [-39.985739072553343, -8.191634533324113],
              [-39.985739006468712, -8.191634471111346],
              [-39.985738939761994, -8.191634409561123],
              [-39.985738872439875, -8.191634348679676],
              [-39.98573880450914, -8.191634288473089],
              [-39.985738735976554, -8.191634228947388],
              [-39.985738666849031, -8.191634170108561],
              [-39.985738597133498, -8.191634111962502],
              [-39.985738526836926, -8.191634054515033],
              [-39.985738455966427, -8.191633997771955],
              [-39.985738478147319, -8.191633976995464],
              [-39.984608796178215, -8.19073876170496],
              [-39.984608760828031, -8.190738734493378],
              [-39.984608733427855, -8.190738712443299],
              [-39.98460870611359, -8.190738690287674],
              [-39.984608678885614, -8.190738668026885],
              [-39.984608651744338, -8.190738645661222],
              [-39.984608624690168, -8.190738623191038],
              [-39.984608597723522, -8.190738600616676],
              [-39.984608570844784, -8.190738577938451],
              [-39.984608544054382, -8.19073855515671],
              [-39.984608517352697, -8.190738532271807],
              [-39.98460849074015, -8.190738509284088],
              [-39.984608464217104, -8.190738486193883],
              [-39.98460843778399, -8.190738463001539],
              [-39.984608411441208, -8.19073843970741],
              [-39.984608385189119, -8.190738416311847],
              [-39.984608359028137, -8.190738392815188],
              [-39.984608332958665, -8.190738369217801],
              [-39.984608306981073, -8.190738345520039],
              [-39.98460828109576, -8.19073832172224],
              [-39.984608255303108, -8.19073829782479],
              [-39.984608229603516, -8.190738273828009],
              [-39.984608203997354, -8.190738249732279],
              [-39.984608178485047, -8.190738225537956],
              [-39.984608153066901, -8.190738201245425],
              [-39.984608127743364, -8.190738176855026],
              [-39.984608102514777, -8.190738152367109],
              [-39.984608077381544, -8.190738127782076],
              [-39.984608052344022, -8.190738103100282],
              [-39.984608027402594, -8.190738078322095],
              [-39.984608002557636, -8.190738053447886],
              [-39.984607977809517, -8.190738028478012],
              [-39.984607953158637, -8.190738003412868],
              [-39.984607928605314, -8.190737978252846],
              [-39.984607904149918, -8.190737952998296],
              [-39.984607879792868, -8.19073792764959],
              [-39.984607855534492, -8.190737902207131],
              [-39.984607831375165, -8.190737876671303],
              [-39.984607807315243, -8.190737851042446],
              [-39.984607783355074, -8.19073782532101],
              [-39.984607759495049, -8.190737799507318],
              [-39.984607735735501, -8.190737773601807],
              [-39.984607712076802, -8.190737747604825],
              [-39.984607688519297, -8.1907377215168],
              [-39.984607665063329, -8.190737695338093],
              [-39.98460764170926, -8.190737669069096],
              [-39.984607618457446, -8.190737642710214],
              [-39.984607595308219, -8.190737616261845],
              [-39.984607572261957, -8.190737589724364],
              [-39.984607549318966, -8.190737563098205],
              [-39.984607526479621, -8.19073753638374],
              [-39.984607503744257, -8.190737509581368],
              [-39.984607481113201, -8.190737482691532],
              [-39.984607458586808, -8.190737455714581],
              [-39.984607436165398, -8.190737428650939],
              [-39.984607413849325, -8.190737401501018],
              [-39.984607391638917, -8.190737374265231],
              [-39.984607369534515, -8.190737346943962],
              [-39.984607347536425, -8.190737319537627],
              [-39.984607325645001, -8.190737292046638],
              [-39.984607303860571, -8.190737264471439],
              [-39.984607282183447, -8.19073723681241],
              [-39.984607260613963, -8.190737209069962],
              [-39.984607239152439, -8.190737181244534],
              [-39.984607217799194, -8.190737153336517],
              [-39.984607196554556, -8.190737125346365],
              [-39.984607175418851, -8.190737097274463],
              [-39.984607154392364, -8.190737069121246],
              [-39.98460713347545, -8.190737040887138],
              [-39.984607112668392, -8.190737012572557],
              [-39.984607091971519, -8.190736984177923],
              [-39.984607071385128, -8.190736955703674],
              [-39.984607050909531, -8.19073692715023],
              [-39.98460703054505, -8.190736898518015],
              [-39.984607010291974, -8.190736869807465],
              [-39.98460699015061, -8.19073684101901],
              [-39.98460697012127, -8.190736812153082],
              [-39.984606950204231, -8.190736783210092],
              [-39.984606930399821, -8.190736754190498],
              [-39.984606910708315, -8.190736725094737],
              [-39.984606891130007, -8.190736695923231],
              [-39.984606871665214, -8.190736666676425],
              [-39.984606852314208, -8.190736637354757],
              [-39.984606833077265, -8.19073660795865],
              [-39.984606813954713, -8.190736578488597],
              [-39.984606794946806, -8.190736548944997],
              [-39.984606776053859, -8.190736519328278],
              [-39.984606757276111, -8.190736489638921],
              [-39.984606738613884, -8.190736459877352],
              [-39.984606720067447, -8.190736430044019],
              [-39.984606701637048, -8.190736400139384],
              [-39.984606683323022, -8.190736370163879],
              [-39.98460666512559, -8.19073634011794],
              [-39.984606647045048, -8.190736310002043],
              [-39.984606629081668, -8.190736279816607],
              [-39.984606611235705, -8.190736249562132],
              [-39.984606593507451, -8.190736219239026],
              [-39.98460657589716, -8.190736188847794],
              [-39.984606558405083, -8.190736158388853],
              [-39.984606541031489, -8.190736127862646],
              [-39.984606523776414, -8.190736097270969],
              [-39.984606485024131, -8.190736117222274],
              [-39.984041571278333, -8.189729335879752],
              [-39.984041536450533, -8.189729306406573],
              [-39.984041484107784, -8.189729211811246],
              [-39.984041432902714, -8.189729116599826],
              [-39.984041382842612, -8.189729020785881],
              [-39.984041333934591, -8.189728924383083],
              [-39.984041286185658, -8.189728827405185],
              [-39.984041239602618, -8.18972872986601],
              [-39.984041194192102, -8.189728631779476],
              [-39.984041149960611, -8.189728533159601],
              [-39.984041106914425, -8.189728434020422],
              [-39.984041065059728, -8.189728334376069],
              [-39.984041024402437, -8.189728234240798],
              [-39.984040984948393, -8.189728133628865],
              [-39.984040946703203, -8.189728032554626],
              [-39.984040909672316, -8.189727931032504],
              [-39.984040873861055, -8.189727829076958],
              [-39.984040839274499, -8.189727726702529],
              [-39.984040805917559, -8.189727623923867],
              [-39.984040773795023, -8.189727520755588],
              [-39.984040742911482, -8.189727417212467],
              [-39.984040713271312, -8.18972731330919],
              [-39.984040684878757, -8.189727209060658],
              [-39.984040657737879, -8.189727104481689],
              [-39.98404063185253, -8.189726999587203],
              [-39.984040607226405, -8.189726894392205],
              [-39.984040583863013, -8.189726788911624],
              [-39.98404056176571, -8.189726683160572],
              [-39.984040540937613, -8.189726577154143],
              [-39.98404052138175, -8.189726470907434],
              [-39.984040503100829, -8.189726364435586],
              [-39.98404048609752, -8.189726257753801],
              [-39.984040470374218, -8.189726150877288],
              [-39.984040455933197, -8.189726043821294],
              [-39.984040442776482, -8.189725936601116],
              [-39.984040430905978, -8.189725829231996],
              [-39.984040420323353, -8.1897257217293],
              [-39.984040411030136, -8.189725614108367],
              [-39.984040403027649, -8.189725506384514],
              [-39.98404039631702, -8.189725398573067],
              [-39.984040390899239, -8.189725290689491],
              [-39.984040386775028, -8.189725182749141],
              [-39.984040383945015, -8.189725074767425],
              [-39.98404038240961, -8.189724966759748],
              [-39.984040382168992, -8.189724858741483],
              [-39.984040383223217, -8.189724750728082],
              [-39.984040385572143, -8.189724642734884],
              [-39.98404038921543, -8.189724534777383],
              [-39.984040394152558, -8.189724426870924],
              [-39.984040400382817, -8.189724319030901],
              [-39.984040407905304, -8.189724211272713],
              [-39.984040416718983, -8.189724103611733],
              [-39.984040426822574, -8.189723996063291],
              [-39.984040438214635, -8.189723888642757],
              [-39.984040450893552, -8.189723781365439],
              [-39.984040464857507, -8.189723674246677],
              [-39.984040480104518, -8.189723567301657],
              [-39.984040496632375, -8.189723460545727],
              [-39.984040514438782, -8.189723353994092],
              [-39.984040533521139, -8.189723247661929],
              [-39.984040553876753, -8.189723141564416],
              [-39.984040575502739, -8.189723035716698],
              [-39.984040598395971, -8.189722930133875],
              [-39.984040622553209, -8.189722824830955],
              [-39.984040647970993, -8.189722719823054],
              [-39.984040674645719, -8.189722615125088],
              [-39.984040702573566, -8.189722510752009],
              [-39.98404073175054, -8.189722406718706],
              [-39.984040762172505, -8.189722303040009],
              [-39.984040793835099, -8.189722199730689],
              [-39.984040826733811, -8.189722096805523],
              [-39.984040860863956, -8.189721994279175],
              [-39.984040896220677, -8.189721892166288],
              [-39.984040932798891, -8.18972179048138],
              [-39.984040970593419, -8.189721689239017],
              [-39.984041009598847, -8.189721588453571],
              [-39.984041049809633, -8.189721488139487],
              [-39.984041091220007, -8.189721388311009],
              [-39.984041133824093, -8.189721288982451],
              [-39.984041177615794, -8.189721190167903],
              [-39.984041222588893, -8.189721091881527],
              [-39.984041268736931, -8.189720994137316],
              [-39.98404131605335, -8.189720896949201],
              [-39.984041364531421, -8.18972080033106],
              [-39.984041414164182, -8.189720704296667],
              [-39.984041464944582, -8.189720608859721],
              [-39.984041516865382, -8.189720514033867],
              [-39.984041569919164, -8.189720419832581],
              [-39.984041624098353, -8.189720326269343],
              [-39.984041679395247, -8.189720233357489],
              [-39.984041735801917, -8.189720141110245],
              [-39.984041793310361, -8.189720049540798],
              [-39.984041851912352, -8.18971995866219],
              [-39.984041911599533, -8.189719868487412],
              [-39.984041972363407, -8.189719779029302],
              [-39.984042034195276, -8.189719690300636],
              [-39.984042097086338, -8.189719602314051],
              [-39.984042161027617, -8.189719515082109],
              [-39.98404222600999, -8.189719428617257],
              [-39.984042292024206, -8.189719342931836],
              [-39.984042359060773, -8.189719258038084],
              [-39.984042363592771, -8.189719294865785],
              [-39.984940902351269, -8.188595168425469],
              [-39.984940934670092, -8.188595195286458],
              [-39.984940953986978, -8.188595171206451],
              [-39.984940973386131, -8.188595147192181],
              [-39.984940992867344, -8.188595123243946],
              [-39.98494101243034, -8.188595099362002],
              [-39.984941032074914, -8.188595075546608],
              [-39.984941051800867, -8.188595051798112],
              [-39.984941071607942, -8.188595028116683],
              [-39.984941091495919, -8.188595004502695],
              [-39.984941111464536, -8.188594980956379],
              [-39.984941131513622, -8.188594957477997],
              [-39.984941151642886, -8.188594934067847],
              [-39.984941171852128, -8.188594910726218],
              [-39.984941192141093, -8.188594887453313],
              [-39.984941212509568, -8.188594864249449],
              [-39.984941232957297, -8.188594841114897],
              [-39.984941253484052, -8.188594818049916],
              [-39.984941274089586, -8.188594795054771],
              [-39.984941294773677, -8.188594772129763],
              [-39.984941315536048, -8.188594749275119],
              [-39.984941336376508, -8.188594726491088],
              [-39.98494135729478, -8.188594703777976],
              [-39.984941378290621, -8.188594681136015],
              [-39.984941399363812, -8.188594658565496],
              [-39.984941420514083, -8.188594636066707],
              [-39.984941441741199, -8.188594613639827],
              [-39.984941463044926, -8.188594591285202],
              [-39.984941484424986, -8.188594569003032],
              [-39.984941505881146, -8.188594546793601],
              [-39.984941527413163, -8.188594524657164],
              [-39.984941549020796, -8.188594502593959],
              [-39.984941570703768, -8.188594480604269],
              [-39.98494159246183, -8.188594458688355],
              [-39.984941614294755, -8.188594436846447],
              [-39.984941636202251, -8.188594415078812],
              [-39.984941658184106, -8.188594393385701],
              [-39.984941680240041, -8.188594371767367],
              [-39.984941702369795, -8.18859435022404],
              [-39.984941724573126, -8.188594328756],
              [-39.984941746849771, -8.188594307363509],
              [-39.984941769199452, -8.188594286046746],
              [-39.984941791621949, -8.188594264806053],
              [-39.984941814116951, -8.18859424364163],
              [-39.98494183668425, -8.188594222553675],
              [-39.984941859323541, -8.188594201542504],
              [-39.98494188203459, -8.188594180608327],
              [-39.984941904817127, -8.188594159751402],
              [-39.98494192767086, -8.188594138971979],
              [-39.984941950595562, -8.188594118270247],
              [-39.984941973590949, -8.18859409764652],
              [-39.984941996656744, -8.188594077101031],
              [-39.984942019792697, -8.188594056633953],
              [-39.984942042998526, -8.188594036245561],
              [-39.98494206627398, -8.188594015936127],
              [-39.98494208961877, -8.188593995705819],
              [-39.984942113032623, -8.188593975554941],
              [-39.984942136515293, -8.18859395548364],
              [-39.984942160066467, -8.188593935492227],
              [-39.984942183685902, -8.188593915580924],
              [-39.984942207373315, -8.188593895749904],
              [-39.984942231128436, -8.188593875999482],
              [-39.984942254950973, -8.188593856329828],
              [-39.98494227884067, -8.188593836741202],
              [-39.984942302797236, -8.188593817233816],
              [-39.984942326820395, -8.188593797807872],
              [-39.984942350909876, -8.188593778463636],
              [-39.984942375065387, -8.188593759201312],
              [-39.984942399286659, -8.188593740021139],
              [-39.984942423573393, -8.188593720923338],
              [-39.984942447925334, -8.188593701908118],
              [-39.984942472342162, -8.1885936829757],
              [-39.984942496823628, -8.188593664126321],
              [-39.984942521369426, -8.188593645360191],
              [-39.984942545979287, -8.188593626677513],
              [-39.984942570652912, -8.188593608078522],
              [-39.984942595390024, -8.188593589563437],
              [-39.984942620190317, -8.188593571132447],
              [-39.984942645053543, -8.1885935527858],
              [-39.984942669979368, -8.188593534523708],
              [-39.984942694967529, -8.18859351634633],
              [-39.984942720017727, -8.188593498253926],
              [-39.984942745129672, -8.188593480246697],
              [-39.984942770303071, -8.18859346232486],
              [-39.984942795537634, -8.188593444488609],
              [-39.984942820833055, -8.188593426738151],
              [-39.984942846189071, -8.1885934090737],
              [-39.984942871605362, -8.188593391495459],
              [-39.984942897081638, -8.18859337400365],
              [-39.984942922617606, -8.18859335659843],
              [-39.984942948212968, -8.188593339280052],
              [-39.984942973867419, -8.188593322048661],
              [-39.984942999580682, -8.188593304904504],
              [-39.984943025352436, -8.188593287847773],
              [-39.984943051182384, -8.188593270878687],
              [-39.984943077070241, -8.188593253997375],
              [-39.984943103015695, -8.188593237204097],
              [-39.984943129018447, -8.188593220499021],
              [-39.984943155078192, -8.188593203882363],
              [-39.98494318119463, -8.188593187354282],
              [-39.984943207367401, -8.188593170915279],
              [-39.984943166807568, -8.188593173173365],
              [-39.985841630344773, -8.188030962492464],
              [-39.985841655413402, -8.188030978902301],
              [-39.985841744720808, -8.188030923734763],
              [-39.985841834663184, -8.188030869600951],
              [-39.985841925228456, -8.188030816508107],
              [-39.985842016404519, -8.188030764463329],
              [-39.985842108179149, -8.1880307134736],
              [-39.985842200540027, -8.188030663545778],
              [-39.985842293474789, -8.188030614686532],
              [-39.985842386970994, -8.188030566902397],
              [-39.985842481016093, -8.188030520199826],
              [-39.985842575597488, -8.188030474585011],
              [-39.985842670702532, -8.188030430064112],
              [-39.985842766318434, -8.188030386643078],
              [-39.985842862432413, -8.18803034432772],
              [-39.985842959031594, -8.188030303123725],
              [-39.985843056103015, -8.188030263036604],
              [-39.98584315363366, -8.188030224071721],
              [-39.98584325161049, -8.188030186234325],
              [-39.985843350020353, -8.18803014952946],
              [-39.985843448850076, -8.188030113962045],
              [-39.985843548086386, -8.188030079536862],
              [-39.985843647716031, -8.188030046258529],
              [-39.985843747725617, -8.188030014131471],
              [-39.98584384810178, -8.188029983160021],
              [-39.985843948831047, -8.188029953348341],
              [-39.985844049899917, -8.188029924700398],
              [-39.98584415129487, -8.188029897220039],
              [-39.985844253002284, -8.188029870910947],
              [-39.985844355008552, -8.188029845776661],
              [-39.985844457300011, -8.188029821820525],
              [-39.98584455986294, -8.188029799045761],
              [-39.985844662683604, -8.188029777455421],
              [-39.985844765748212, -8.188029757052391],
              [-39.985844869042957, -8.188029737839413],
              [-39.985844972554013, -8.188029719819083],
              [-39.985845076267495, -8.188029702993758],
              [-39.985845180169513, -8.188029687365725],
              [-39.985845284246132, -8.188029672937104],
              [-39.985845388483426, -8.18802965970978],
              [-39.985845492867405, -8.188029647685573],
              [-39.985845597384085, -8.188029636866059],
              [-39.985845702019482, -8.188029627252719],
              [-39.985845806759549, -8.188029618846839],
              [-39.985845911590253, -8.18802961164951],
              [-39.985846016497561, -8.188029605661713],
              [-39.985846121467397, -8.188029600884256],
              [-39.985846226485727, -8.188029597317781],
              [-39.98584633153844, -8.188029594962742],
              [-39.985846436611482, -8.188029593819474],
              [-39.985846541690762, -8.188029593888139],
              [-39.985846646762219, -8.188029595168727],
              [-39.985846751811728, -8.188029597661076],
              [-39.985846856825269, -8.188029601364812],
              [-39.985846961788745, -8.188029606279466],
              [-39.985847066688066, -8.188029612404401],
              [-39.985847171509221, -8.188029619738741],
              [-39.985847276238118, -8.188029628281519],
              [-39.985847380860761, -8.188029638031635],
              [-39.985847485363102, -8.188029648987717],
              [-39.985847589731172, -8.188029661148358],
              [-39.985847693950952, -8.188029674511863],
              [-39.985847798008479, -8.188029689076522],
              [-39.985847901889834, -8.18802970484033],
              [-39.985848005581069, -8.188029721801195],
              [-39.985848109068307, -8.188029739956812],
              [-39.985848212337679, -8.188029759304772],
              [-39.985848315375328, -8.188029779842497],
              [-39.985848418167492, -8.188029801567207],
              [-39.985848520700351, -8.188029824476006],
              [-39.985848622960155, -8.188029848565842],
              [-39.985848724933255, -8.188029873833452],
              [-39.985848826605945, -8.188029900275442],
              [-39.985848927964618, -8.188029927888314],
              [-39.985849028995688, -8.188029956668323],
              [-39.985849129685612, -8.188029986611648],
              [-39.985849230020904, -8.188030017714281],
              [-39.985849329988106, -8.188030049971999],
              [-39.98584942957384, -8.188030083380534],
              [-39.985849528764767, -8.188030117935398],
              [-39.985849627547566, -8.188030153631942],
              [-39.985849725909013, -8.188030190465412],
              [-39.985849823835927, -8.188030228430835],
              [-39.9858499213152, -8.188030267523146],
              [-39.985850018333757, -8.188030307737117],
              [-39.985850114878595, -8.188030349067336],
              [-39.985850210936789, -8.188030391508278],
              [-39.985850306495436, -8.188030435054246],
              [-39.985850401541761, -8.188030479699412],
              [-39.98585049606303, -8.188030525437789],
              [-39.98585059004656, -8.18803057226326],
              [-39.985850683479761, -8.188030620169572],
              [-39.985850776350127, -8.188030669150237],
              [-39.985850868645187, -8.18803071919873],
              [-39.985850960352593, -8.188030770308348],
              [-39.985851051460052, -8.188030822472239],
              [-39.985851141955344, -8.188030875683436],
              [-39.985851231826366, -8.188030929934767],
              [-39.985851321061055, -8.188030985218987],
              [-39.985851409647459, -8.188031041528678],
              [-39.985851497573776, -8.188031098856239],
              [-39.985851525122591, -8.188031119216541],
              [-39.986525529964297, -8.188476166580967],
              [-39.986525561874458, -8.188476158355853],
              [-39.986525597260432, -8.188476181838812],
              [-39.986525632535617, -8.188476205486664],
              [-39.98652566769924, -8.188476229298871],
              [-39.986525702750548, -8.188476253274926],
              [-39.986525737688758, -8.18847627741426],
              [-39.986525772513097, -8.188476301716387],
              [-39.986525807222819, -8.188476326180771],
              [-39.986525841817155, -8.18847635080685],
              [-39.986525876295339, -8.188476375594099],
              [-39.986525910656617, -8.188476400542006],
              [-39.986525944900251, -8.188476425649952],
              [-39.986525979025465, -8.188476450917454],
              [-39.986526013031522, -8.188476476343881],
              [-39.986526046917682, -8.188476501928776],
              [-39.986526080683198, -8.188476527671504],
              [-39.986526114327326, -8.188476553571503],
              [-39.986526147849325, -8.188476579628251],
              [-39.986526181248472, -8.18847660584114],
              [-39.986526214524019, -8.188476632209586],
              [-39.986526247675243, -8.18847665873305],
              [-39.986526280701412, -8.188476685410929],
              [-39.986526313601814, -8.188476712242615],
              [-39.986526346375719, -8.188476739227573],
              [-39.986526379022408, -8.188476766365165],
              [-39.98652641154117, -8.1884767936548],
              [-39.986526443931282, -8.188476821095897],
              [-39.986526476192033, -8.188476848687859],
              [-39.986526508322726, -8.188476876430073],
              [-39.986526540322643, -8.188476904321876],
              [-39.986526572191096, -8.188476932362738],
              [-39.986526603927381, -8.188476960552029],
              [-39.986526635530815, -8.188476988889102],
              [-39.986526667000653, -8.188477017373332],
              [-39.986526698336277, -8.188477046004113],
              [-39.986526729536955, -8.188477074780824],
              [-39.986526760602004, -8.188477103702812],
              [-39.98652679153075, -8.188477132769467],
              [-39.986526822322517, -8.188477161980126],
              [-39.986526852976624, -8.188477191334156],
              [-39.986526883492395, -8.188477220830912],
              [-39.986526913869191, -8.188477250469761],
              [-39.986526944106309, -8.188477280250043],
              [-39.986526974203095, -8.188477310171095],
              [-39.986527004158894, -8.18847734023228],
              [-39.986527033973054, -8.188477370432926],
              [-39.9865270636449, -8.188477400772362],
              [-39.986527093173805, -8.188477431249947],
              [-39.98652712255911, -8.188477461864995],
              [-39.986527151800168, -8.188477492616832],
              [-39.986527180896346, -8.188477523504785],
              [-39.98652720984699, -8.188477554528195],
              [-39.98652723865149, -8.188477585686352],
              [-39.986527267309199, -8.188477616978581],
              [-39.986527295819478, -8.188477648404232],
              [-39.986527324181715, -8.188477679962583],
              [-39.986527352395292, -8.188477711652929],
              [-39.986527380459584, -8.188477743474589],
              [-39.986527408373973, -8.188477775426886],
              [-39.986527436137841, -8.188477807509095],
              [-39.986527463750605, -8.188477839720514],
              [-39.986527491211618, -8.188477872060435],
              [-39.986527518520305, -8.188477904528149],
              [-39.986527545676068, -8.18847793712294],
              [-39.986527572678298, -8.188477969844095],
              [-39.986527599526411, -8.188478002690914],
              [-39.986527626219832, -8.188478035662653],
              [-39.986527652757943, -8.188478068758624],
              [-39.986527679140188, -8.188478101978053],
              [-39.986527705365965, -8.188478135320231],
              [-39.986527731434741, -8.188478168784444],
              [-39.986527757345897, -8.188478202369943],
              [-39.986527783098879, -8.188478236075978],
              [-39.986527808693133, -8.188478269901822],
              [-39.98652783412809, -8.18847830384675],
              [-39.986527859403203, -8.188478337909993],
              [-39.986527884517891, -8.188478372090827],
              [-39.986527909471633, -8.188478406388461],
              [-39.986527934263862, -8.188478440802186],
              [-39.986527958894044, -8.18847847533123],
              [-39.98652798336164, -8.188478509974841],
              [-39.986528007666095, -8.188478544732225],
              [-39.986528031806905, -8.188478579602675],
              [-39.986528055783509, -8.188478614585403],
              [-39.986528079595402, -8.188478649679613],
              [-39.986528103242058, -8.188478684884569],
              [-39.986528126722952, -8.188478720199498],
              [-39.986528150037564, -8.18847875562359],
              [-39.986528173185413, -8.188478791156127],
              [-39.98652819616597, -8.188478826796294],
              [-39.986528218978719, -8.18847886254331],
              [-39.986528241623169, -8.188478898396406],
              [-39.986528264098837, -8.188478934354768],
              [-39.986528286405211, -8.188478970417638],
              [-39.986528308541807, -8.188479006584167],
              [-39.986528330508143, -8.188479042853643],
              [-39.986528352303743, -8.188479079225216],
              [-39.986528373928103, -8.188479115698097],
              [-39.986528395380788, -8.188479152271489],
              [-39.986528416661031, -8.188479188945916],
              [-39.986528442071766, -8.188479144055311],
              [-39.987436522265078, -8.190052696783374],
              [-39.987436558587135, -8.190052693028909],
              [-39.987436574316824, -8.190052720174048],
              [-39.987436590140838, -8.190052747264708],
              [-39.987436606058992, -8.190052774300554],
              [-39.987436622071087, -8.19005280128129],
              [-39.987436638176938, -8.190052828206557],
              [-39.98743665437636, -8.190052855076052],
              [-39.98743667066914, -8.190052881889478],
              [-39.987436687055101, -8.190052908646459],
              [-39.987436703534016, -8.190052935346696],
              [-39.987436720105727, -8.190052961989895],
              [-39.987436736770007, -8.190052988575678],
              [-39.987436753526659, -8.190053015103791],
              [-39.987436770375496, -8.190053041573877],
              [-39.987436787316305, -8.190053067985605],
              [-39.987436804348874, -8.190053094338687],
              [-39.987436821473018, -8.190053120632816],
              [-39.98743683868851, -8.19005314686763],
              [-39.987436855995178, -8.190053173042866],
              [-39.987436873392774, -8.190053199158166],
              [-39.987436890881099, -8.190053225213266],
              [-39.987436908459962, -8.190053251207775],
              [-39.987436926129149, -8.190053277141461],
              [-39.987436943888426, -8.190053303013967],
              [-39.987436961737586, -8.190053328825014],
              [-39.987436979676431, -8.190053354574253],
              [-39.987436997704741, -8.190053380261414],
              [-39.987437015822273, -8.190053405886129],
              [-39.987437034028851, -8.190053431448142],
              [-39.987437052324239, -8.190053456947151],
              [-39.987437070708182, -8.190053482382826],
              [-39.98743708918051, -8.190053507754872],
              [-39.987437107740995, -8.190053533063008],
              [-39.987437126389388, -8.190053558306898],
              [-39.987437145125476, -8.190053583486204],
              [-39.987437163949046, -8.190053608600689],
              [-39.987437182859843, -8.190053633650004],
              [-39.987437201857681, -8.190053658633898],
              [-39.987437220942297, -8.19005368355201],
              [-39.987437240113472, -8.190053708404097],
              [-39.987437259370985, -8.190053733189817],
              [-39.987437278714609, -8.190053757908899],
              [-39.987437298144087, -8.190053782561046],
              [-39.987437317659207, -8.190053807145954],
              [-39.987437337259713, -8.190053831663333],
              [-39.987437356945399, -8.190053856112904],
              [-39.987437376716002, -8.190053880494323],
              [-39.987437396571302, -8.190053904807344],
              [-39.98743741651105, -8.190053929051631],
              [-39.987437436535011, -8.190053953226942],
              [-39.987437456642958, -8.19005397733298],
              [-39.987437476834607, -8.190054001369408],
              [-39.987437497109774, -8.190054025335971],
              [-39.987437517468166, -8.190054049232387],
              [-39.987437537909564, -8.190054073058404],
              [-39.987437558433712, -8.190054096813649],
              [-39.987437579040375, -8.190054120497889],
              [-39.987437599729283, -8.190054144110817],
              [-39.987437620500216, -8.190054167652203],
              [-39.987437641352912, -8.190054191121709],
              [-39.987437662287121, -8.190054214519067],
              [-39.987437683302588, -8.19005423784396],
              [-39.987437704399071, -8.190054261096193],
              [-39.987437725576292, -8.190054284275456],
              [-39.987437746834026, -8.190054307381427],
              [-39.987437768171993, -8.190054330413822],
              [-39.987437789589961, -8.190054353372467],
              [-39.987437811087659, -8.190054376256983],
              [-39.987437832664831, -8.190054399067128],
              [-39.987437854321207, -8.190054421802657],
              [-39.987437876056539, -8.190054444463229],
              [-39.987437897870585, -8.19005446704864],
              [-39.987437919763039, -8.19005448955858],
              [-39.987437941733667, -8.190054511992821],
              [-39.987437963782185, -8.190054534351011],
              [-39.987437985908372, -8.190054556632957],
              [-39.987438008111916, -8.190054578838344],
              [-39.987438030392561, -8.190054600966983],
              [-39.987438052750058, -8.19005462301851],
              [-39.98743807518413, -8.190054644992715],
              [-39.987438097694479, -8.190054666889324],
              [-39.987438120280878, -8.190054688708086],
              [-39.987438142943027, -8.190054710448699],
              [-39.987438165680672, -8.190054732110962],
              [-39.987438188493527, -8.190054753694589],
              [-39.987438211381317, -8.190054775199261],
              [-39.987438234343777, -8.190054796624807],
              [-39.987438257380624, -8.19005481797093],
              [-39.987438280491588, -8.190054839237375],
              [-39.987438303676399, -8.190054860423919],
              [-39.987438326934743, -8.190054881530251],
              [-39.987438350266373, -8.190054902556133],
              [-39.987438373671012, -8.190054923501346],
              [-39.987438397148352, -8.190054944365604],
              [-39.987438420698133, -8.190054965148672],
              [-39.987438444320048, -8.190054985850328],
              [-39.987438468013863, -8.19005500647024],
              [-39.987438491779237, -8.190055027008261],
              [-39.987438515615921, -8.190055047464066],
              [-39.987438539523616, -8.190055067837466],
              [-39.98743852509017, -8.190055043378489],
              [-39.988228284718666, -8.190725728351946],
              [-39.988228263234923, -8.190725686446843],
              [-39.988228331514549, -8.190725743852905],
              [-39.988228400357947, -8.190725800586643],
              [-39.988228469758454, -8.190725856642564],
              [-39.988228539709318, -8.190725912015237],
              [-39.98822861020377, -8.190725966699292],
              [-39.988228681234986, -8.190726020689443],
              [-39.988228752796083, -8.190726073980434],
              [-39.988228824880139, -8.190726126567133],
              [-39.98822889748017, -8.19072617844445],
              [-39.988228970589141, -8.190726229607307],
              [-39.988229044199969, -8.190726280050837],
              [-39.988229118305497, -8.190726329770071],
              [-39.988229192898601, -8.19072637876021],
              [-39.988229267972017, -8.19072642701655],
              [-39.988229343518491, -8.190726474534381],
              [-39.988229419530683, -8.190726521309116],
              [-39.988229496001239, -8.190726567336204],
              [-39.988229572922776, -8.190726612611201],
              [-39.988229650287813, -8.190726657129716],
              [-39.988229728088854, -8.190726700887458],
              [-39.988229806318387, -8.190726743880129],
              [-39.988229884968817, -8.190726786103639],
              [-39.988229964032534, -8.190726827553865],
              [-39.988230043501872, -8.190726868226811],
              [-39.988230123369135, -8.190726908118473],
              [-39.988230203626586, -8.190726947225073],
              [-39.988230284266457, -8.190726985542767],
              [-39.98823036528092, -8.190727023067847],
              [-39.988230446662172, -8.190727059796689],
              [-39.988230528402255, -8.190727095725729],
              [-39.988230610493318, -8.190727130851508],
              [-39.988230692927381, -8.190727165170605],
              [-39.988230775696451, -8.190727198679712],
              [-39.988230858792534, -8.190727231375529],
              [-39.988230942207565, -8.190727263254965],
              [-39.988231025933466, -8.190727294314886],
              [-39.988231109962115, -8.190727324552297],
              [-39.988231194285405, -8.190727353964272],
              [-39.988231278895142, -8.190727382547928],
              [-39.988231363783136, -8.190727410300545],
              [-39.988231448941171, -8.190727437219392],
              [-39.98823153436097, -8.190727463301897],
              [-39.988231620034306, -8.190727488545525],
              [-39.988231705952835, -8.190727512947815],
              [-39.988231792108273, -8.190727536506424],
              [-39.988231878492229, -8.190727559219058],
              [-39.988231965096368, -8.190727581083522],
              [-39.988232051912306, -8.190727602097684],
              [-39.988232138931608, -8.190727622259528],
              [-39.988232226145861, -8.190727641567083],
              [-39.988232313546625, -8.190727660018503],
              [-39.988232401125416, -8.190727677611955],
              [-39.988232488873763, -8.190727694345789],
              [-39.988232576783155, -8.190727710218363],
              [-39.988232664845064, -8.190727725228124],
              [-39.988232753051001, -8.190727739373637],
              [-39.988232841392382, -8.190727752653531],
              [-39.988232929860679, -8.190727765066505],
              [-39.988233018447303, -8.190727776611363],
              [-39.988233107143671, -8.190727787286995],
              [-39.988233195941206, -8.190727797092368],
              [-39.98823328483131, -8.190727806026503],
              [-39.988233373805343, -8.190727814088561],
              [-39.988233462854716, -8.190727821277759],
              [-39.988233551970794, -8.190727827593429],
              [-39.98823364114493, -8.190727833034909],
              [-39.988233730368513, -8.190727837601688],
              [-39.988233819632889, -8.190727841293333],
              [-39.988233908929402, -8.190727844109476],
              [-39.988233998249399, -8.190727846049867],
              [-39.988234087584246, -8.19072784711428],
              [-39.988234176925289, -8.190727847302625],
              [-39.988234266263852, -8.190727846614896],
              [-39.988234355591302, -8.190727845051153],
              [-39.988234444898964, -8.190727842611558],
              [-39.988234534178211, -8.190727839296311],
              [-39.988234623420396, -8.190727835105793],
              [-39.988234712616858, -8.190727830040371],
              [-39.988234801758963, -8.190727824100556],
              [-39.988234890838072, -8.190727817286879],
              [-39.988234979845565, -8.190727809600048],
              [-39.988235068772809, -8.190727801040767],
              [-39.988235157611186, -8.190727791609886],
              [-39.988235246352119, -8.190727781308352],
              [-39.988235334986989, -8.19072777013711],
              [-39.988235423507213, -8.190727758097283],
              [-39.988235511904207, -8.19072774518998],
              [-39.988235600169439, -8.190727731416507],
              [-39.988235688294317, -8.190727716778195],
              [-39.988235776270344, -8.190727701276446],
              [-39.988235864088971, -8.190727684912748],
              [-39.988235951741714, -8.190727667688725],
              [-39.988236039220062, -8.190727649605995],
              [-39.988236126515538, -8.190727630666348],
              [-39.988236213619714, -8.19072761087161],
              [-39.988236300524129, -8.190727590223682],
              [-39.988236387220368, -8.190727568724581],
              [-39.988236473700027, -8.190727546376406],
              [-39.98823655995475, -8.190727523181295],
              [-39.988236549636021, -8.190727516957455],
              [-39.989473970426396, -8.190388221838001],
              [-39.98947393831466, -8.190388254774536],
              [-39.989473998739562, -8.190388237982939],
              [-39.989474059046557, -8.190388220775732],
              [-39.98947411923276, -8.190388203153685],
              [-39.989474179295307, -8.190388185117662],
              [-39.98947423923132, -8.190388166668512],
              [-39.989474299037923, -8.190388147807123],
              [-39.989474358712286, -8.190388128534405],
              [-39.989474418251547, -8.190388108851243],
              [-39.989474477652855, -8.190388088758638],
              [-39.989474536913406, -8.190388068257507],
              [-39.989474596030313, -8.19038804734884],
              [-39.989474655000805, -8.190388026033624],
              [-39.989474713822048, -8.190388004312902],
              [-39.989474772491214, -8.190387982187696],
              [-39.989474831005523, -8.190387959659047],
              [-39.989474889362164, -8.190387936728067],
              [-39.989474947558392, -8.190387913395799],
              [-39.989475005591373, -8.190387889663416],
              [-39.989475063458372, -8.190387865532038],
              [-39.989475121156602, -8.190387841002783],
              [-39.989475178683321, -8.190387816076852],
              [-39.989475236035787, -8.190387790755414],
              [-39.989475293211243, -8.19038776503969],
              [-39.989475350206973, -8.190387738930918],
              [-39.989475407020251, -8.190387712430361],
              [-39.989475463648361, -8.190387685539232],
              [-39.989475520088597, -8.190387658258842],
              [-39.989475576338265, -8.190387630590504],
              [-39.989475632394694, -8.19038760253553],
              [-39.989475688255183, -8.190387574095247],
              [-39.989475743917076, -8.190387545271038],
              [-39.989475799377715, -8.190387516064261],
              [-39.989475854634456, -8.190387486476318],
              [-39.98947590968465, -8.190387456508651],
              [-39.989475964525653, -8.190387426162642],
              [-39.989476019154907, -8.190387395439767],
              [-39.989476073569733, -8.190387364341468],
              [-39.98947612776756, -8.190387332869257],
              [-39.989476181745815, -8.190387301024632],
              [-39.98947623550189, -8.190387268809117],
              [-39.989476289033242, -8.190387236224225],
              [-39.989476342337298, -8.190387203271548],
              [-39.989476395411529, -8.190387169952634],
              [-39.989476448253384, -8.190387136269081],
              [-39.989476500860341, -8.190387102222516],
              [-39.989476553229899, -8.190387067814527],
              [-39.989476605359556, -8.190387033046806],
              [-39.989476657246819, -8.190386997920941],
              [-39.9894767088892, -8.190386962438676],
              [-39.989476760284248, -8.190386926601699],
              [-39.989476811429498, -8.190386890411702],
              [-39.989476862322519, -8.190386853870406],
              [-39.989476912960853, -8.190386816979592],
              [-39.989476963342113, -8.190386779740983],
              [-39.989477013463883, -8.190386742156361],
              [-39.989477063323775, -8.190386704227549],
              [-39.989477112919388, -8.190386665956327],
              [-39.98947716224837, -8.19038662734456],
              [-39.989477211308369, -8.19038658839407],
              [-39.989477260097011, -8.190386549106703],
              [-39.989477308612003, -8.19038650948433],
              [-39.989477356851026, -8.190386469528898],
              [-39.989477404811751, -8.190386429242279],
              [-39.989477452491897, -8.190386388626367],
              [-39.989477499889198, -8.190386347683171],
              [-39.989477547001378, -8.190386306414588],
              [-39.989477593826201, -8.190386264822605],
              [-39.989477640361407, -8.190386222909204],
              [-39.989477686604808, -8.190386180676388],
              [-39.989477732554164, -8.19038613812619],
              [-39.989477778207295, -8.190386095260656],
              [-39.989477823562019, -8.190386052081806],
              [-39.989477868616163, -8.190386008591686],
              [-39.989477913367594, -8.190385964792393],
              [-39.989477957814145, -8.190385920686035],
              [-39.989478001953735, -8.190385876274705],
              [-39.98947804578421, -8.190385831560501],
              [-39.989478089303518, -8.190385786545601],
              [-39.989478132509547, -8.190385741232111],
              [-39.989478175400244, -8.190385695622245],
              [-39.98947821797357, -8.190385649718138],
              [-39.989478260227479, -8.190385603521975],
              [-39.989478302159981, -8.190385557036004],
              [-39.989478343769044, -8.190385510262406],
              [-39.989478385052678, -8.190385463203457],
              [-39.989478426008937, -8.190385415861371],
              [-39.989478466635838, -8.190385368238426],
              [-39.989478506931462, -8.190385320336883],
              [-39.989478546893878, -8.190385272159022],
              [-39.989478586521173, -8.190385223707175],
              [-39.989478625811458, -8.190385174983618],
              [-39.989478664762856, -8.19038512599073],
              [-39.989478703373514, -8.190385076730776],
              [-39.989478741641562, -8.190385027206217],
              [-39.989478779565211, -8.190384977419317],
              [-39.989478817142619, -8.190384927372488],
              [-39.98947885437201, -8.190384877068123],
              [-39.989478891251586, -8.190384826508627],
              [-39.989478927779601, -8.190384775696394],
              [-39.989478954516571, -8.190384772822538],
              [-39.99003493076416, -8.189605698536345],
              [-39.990034905122968, -8.189605710449875],
              [-39.990034998845445, -8.189605576578984],
              [-39.990035090127897, -8.189605441044757],
              [-39.990035178940595, -8.189605303891325],
              [-39.990035265254612, -8.189605165163339],
              [-39.99003534904184, -8.189605024905994],
              [-39.990035430274993, -8.189604883165],
              [-39.990035508927591, -8.189604739986473],
              [-39.990035584974038, -8.189604595417061],
              [-39.990035658389566, -8.189604449503884],
              [-39.99003572915025, -8.18960430229443],
              [-39.990035797233062, -8.189604153836646],
              [-39.990035862615812, -8.18960400417892],
              [-39.990035925277205, -8.189603853369933],
              [-39.990035985196855, -8.189603701458834],
              [-39.990036042355229, -8.189603548495137],
              [-39.990036096733697, -8.189603394528607],
              [-39.990036148314573, -8.189603239609404],
              [-39.990036197081047, -8.189603083788006],
              [-39.990036243017222, -8.189602927115139],
              [-39.990036286108158, -8.189602769641869],
              [-39.990036326339819, -8.189602611419446],
              [-39.99003636369909, -8.189602452499413],
              [-39.990036398173807, -8.189602292933516],
              [-39.990036429752735, -8.18960213277375],
              [-39.990036458425621, -8.189601972072275],
              [-39.990036484183086, -8.189601810881408],
              [-39.990036507016768, -8.189601649253687],
              [-39.990036526919198, -8.189601487241701],
              [-39.990036543883932, -8.189601324898277],
              [-39.99003655790542, -8.189601162276231],
              [-39.990036568979107, -8.189600999428587],
              [-39.990036577101385, -8.189600836408347],
              [-39.990036582269603, -8.189600673268588],
              [-39.990036584482084, -8.189600510062508],
              [-39.990036583738089, -8.189600346843216],
              [-39.990036580037895, -8.189600183663856],
              [-39.990036573382682, -8.189600020577604],
              [-39.99003656377463, -8.189599857637596],
              [-39.990036551216832, -8.189599694896879],
              [-39.990036535713436, -8.189599532408431],
              [-39.990036517269452, -8.189599370225205],
              [-39.990036495890905, -8.189599208400031],
              [-39.990036471584752, -8.189599046985579],
              [-39.9900364443589, -8.189598886034469],
              [-39.990036414222224, -8.189598725599096],
              [-39.990036381184552, -8.189598565731691],
              [-39.990036345256627, -8.189598406484373],
              [-39.990036306450151, -8.189598247908965],
              [-39.990036264777771, -8.189598090057128],
              [-39.990036220253053, -8.189597932980304],
              [-39.990036172890505, -8.18959777672961],
              [-39.990036122705547, -8.189597621355924],
              [-39.990036069714535, -8.189597466909945],
              [-39.99003601393472, -8.189597313441846],
              [-39.990035955384272, -8.189597161001711],
              [-39.990035894082261, -8.189597009639122],
              [-39.99003583004864, -8.189596859403425],
              [-39.990035763304284, -8.189596710343507],
              [-39.990035693870922, -8.189596562507992],
              [-39.990035621771149, -8.18959641594496],
              [-39.990035547028491, -8.189596270702175],
              [-39.990035469667248, -8.189596126826919],
              [-39.990035389712645, -8.189595984366072],
              [-39.990035307190723, -8.189595843366025],
              [-39.990035222128348, -8.189595703872724],
              [-39.990035134553224, -8.189595565931564],
              [-39.990035044493872, -8.189595429587495],
              [-39.990034951979631, -8.189595294884935],
              [-39.990034857040634, -8.189595161867716],
              [-39.990034759707804, -8.1895950305792],
              [-39.990034660012824, -8.189594901062133],
              [-39.990034557988189, -8.189594773358682],
              [-39.990034453667107, -8.18959464751048],
              [-39.990034347083572, -8.189594523558478],
              [-39.990034238272294, -8.18959440154307],
              [-39.990034127268693, -8.189594281503979],
              [-39.990034014108957, -8.189594163480288],
              [-39.990033898829914, -8.189594047510477],
              [-39.99003378146913, -8.189593933632326],
              [-39.990033662064796, -8.189593821882852],
              [-39.990033540655844, -8.189593712298517],
              [-39.990033417281794, -8.189593604915007],
              [-39.990033291982833, -8.189593499767259],
              [-39.990033164799755, -8.189593396889544],
              [-39.990033035774005, -8.189593296315365],
              [-39.99003290494759, -8.189593198077503],
              [-39.990032772363151, -8.189593102207908],
              [-39.990032638063838, -8.189593008737816],
              [-39.990032502093399, -8.18959291769769],
              [-39.990032364496123, -8.189592829117188],
              [-39.990032225316817, -8.189592743025159],
              [-39.99003208460082, -8.189592659449575],
              [-39.990031942393976, -8.189592578417733],
              [-39.990031798742557, -8.189592499956051],
              [-39.9900316536934, -8.189592424089966],
              [-39.990031507293729, -8.189592350844286],
              [-39.990031359591214, -8.189592280242842],
              [-39.99003121063398, -8.189592212308613],
              [-39.990031060470521, -8.189592147063751],
              [-39.990031084742093, -8.18959214166471],
              [-39.988459587573068, -8.188926088335707],
              [-39.988459553891566, -8.188926085479736],
              [-39.988459455749023, -8.188926043201279],
              [-39.98845935811314, -8.188925999773993],
              [-39.988459260997416, -8.18892595520386],
              [-39.988459164415353, -8.188925909497108],
              [-39.988459068380344, -8.188925862660064],
              [-39.988458972905697, -8.188925814699228],
              [-39.988458878004693, -8.188925765621239],
              [-39.988458783690454, -8.188925715432966],
              [-39.988458689976106, -8.188925664141289],
              [-39.988458596874622, -8.188925611753387],
              [-39.988458504398956, -8.188925558276502],
              [-39.988458412561897, -8.188925503718053],
              [-39.988458321376214, -8.188925448085604],
              [-39.98845823085454, -8.18892539138691],
              [-39.988458141009453, -8.188925333629786],
              [-39.988458051853414, -8.188925274822271],
              [-39.98845796339878, -8.188925214972542],
              [-39.988457875657843, -8.188925154088887],
              [-39.988457788642748, -8.188925092179741],
              [-39.988457702365601, -8.188925029253705],
              [-39.988457616838367, -8.188924965319515],
              [-39.988457532072893, -8.188924900386041],
              [-39.988457448080943, -8.188924834462259],
              [-39.988457364874201, -8.188924767557364],
              [-39.988457282464182, -8.188924699680616],
              [-39.988457200862328, -8.188924630841449],
              [-39.98845712007995, -8.188924561049397],
              [-39.988457040128267, -8.188924490314152],
              [-39.988456961018379, -8.188924418645513],
              [-39.988456882761255, -8.18892434605344],
              [-39.988456805367747, -8.188924272547993],
              [-39.988456728848597, -8.188924198139379],
              [-39.988456653214435, -8.188924122837912],
              [-39.988456578475734, -8.188924046654044],
              [-39.988456504642869, -8.188923969598365],
              [-39.988456431726085, -8.188923891681528],
              [-39.988456359735515, -8.188923812914373],
              [-39.988456288681135, -8.188923733307844],
              [-39.988456218572786, -8.188923652872937],
              [-39.98845614942023, -8.188923571620839],
              [-39.988456081233032, -8.188923489562809],
              [-39.98845601402067, -8.18892340671027],
              [-39.98845594779246, -8.188923323074707],
              [-39.988455882557581, -8.188923238667673],
              [-39.988455818325129, -8.188923153500935],
              [-39.988455755103963, -8.188923067586281],
              [-39.988455692902875, -8.188922980935637],
              [-39.98845563173051, -8.188922893561037],
              [-39.988455571595331, -8.188922805474594],
              [-39.988455512505702, -8.188922716688538],
              [-39.988455454469793, -8.188922627215169],
              [-39.988455397495684, -8.188922537066929],
              [-39.988455341591298, -8.188922446256292],
              [-39.988455286764335, -8.188922354795906],
              [-39.988455233022428, -8.18892226269841],
              [-39.988455180373059, -8.188922169976616],
              [-39.98845512882351, -8.188922076643362],
              [-39.988455078380909, -8.188921982711637],
              [-39.988455029052282, -8.188921888194409],
              [-39.988454980844494, -8.188921793104855],
              [-39.988454933764203, -8.188921697456133],
              [-39.988454887817944, -8.18892160126153],
              [-39.9884548430121, -8.188921504534395],
              [-39.98845479935288, -8.188921407288134],
              [-39.988454756846359, -8.188921309536251],
              [-39.988454715498399, -8.188921211292319],
              [-39.98845467531477, -8.188921112569952],
              [-39.98845463630105, -8.188921013382854],
              [-39.988454598462624, -8.188920913744754],
              [-39.988454561804765, -8.188920813669522],
              [-39.988454526332553, -8.188920713171026],
              [-39.988454492050899, -8.188920612263223],
              [-39.988454458964561, -8.188920510960079],
              [-39.988454427078146, -8.188920409275665],
              [-39.988454396396079, -8.188920307224093],
              [-39.988454366922589, -8.188920204819532],
              [-39.988454338661789, -8.188920102076192],
              [-39.988454311617602, -8.188919999008318],
              [-39.988454285793757, -8.1889198956302],
              [-39.988454261193851, -8.188919791956197],
              [-39.988454237821323, -8.18891968800069],
              [-39.988454215679369, -8.188919583778089],
              [-39.988454194771094, -8.188919479302879],
              [-39.988454175099378, -8.188919374589531],
              [-39.988454156666968, -8.18891926965259],
              [-39.988454139476403, -8.188919164506627],
              [-39.988454123530083, -8.188919059166198],
              [-39.988454108830233, -8.188918953645949],
              [-39.98845409537887, -8.188918847960501],
              [-39.988454083177849, -8.188918742124519],
              [-39.988454072228883, -8.18891863615271],
              [-39.988454062533521, -8.188918530059741],
              [-39.988454054093033, -8.188918423860347],
              [-39.988454046908664, -8.188918317569282],
              [-39.98845404098136, -8.188918211201251],
              [-39.988454036311971, -8.18891810477105],
              [-39.988454032901146, -8.188917998293425],
              [-39.988454030749338, -8.18891789178317],
              [-39.988454029857003, -8.188917785254681],
              [-39.988454029847169, -8.188917781272766],
              [-39.988451268436265, -8.187798115142805],
              [-39.98845126835171, -8.187798080857144],
              [-39.988451268346871, -8.187798038476069],
              [-39.98845126854134, -8.187797996095435],
              [-39.98845126893513, -8.187797953716167],
              [-39.988451269528241, -8.187797911339189],
              [-39.988451270320645, -8.187797868965481],
              [-39.988451271312343, -8.187797826595899],
              [-39.988451272503312, -8.187797784231439],
              [-39.988451273893496, -8.187797741873014],
              [-39.988451275482888, -8.187797699521532],
              [-39.988451277271459, -8.187797657177946],
              [-39.988451279259152, -8.187797614843154],
              [-39.988451281445933, -8.1877975725181],
              [-39.988451283831743, -8.187797530203751],
              [-39.98845128641657, -8.18779748790099],
              [-39.988451289200313, -8.18779744561078],
              [-39.988451292182901, -8.187797403334015],
              [-39.988451295364321, -8.18779736107164],
              [-39.988451298744465, -8.187797318824581],
              [-39.988451302323263, -8.187797276593761],
              [-39.988451306100643, -8.187797234380126],
              [-39.988451310076542, -8.187797192184606],
              [-39.988451314250817, -8.187797150008073],
              [-39.988451318623426, -8.187797107851519],
              [-39.988451323194269, -8.187797065715825],
              [-39.988451327963212, -8.187797023601956],
              [-39.98845133293019, -8.187796981510793],
              [-39.988451338095061, -8.187796939443288],
              [-39.98845134345774, -8.187796897400382],
              [-39.988451349018092, -8.187796855382956],
              [-39.988451354775997, -8.187796813391961],
              [-39.988451360731332, -8.187796771428264],
              [-39.988451366883965, -8.187796729492858],
              [-39.988451373233751, -8.187796687586651],
              [-39.988451379780578, -8.187796645710568],
              [-39.988451386524289, -8.187796603865491],
              [-39.9884513934647, -8.187796562052366],
              [-39.988451400601718, -8.187796520272093],
              [-39.988451407935145, -8.187796478525586],
              [-39.988451415464844, -8.187796436813807],
              [-39.988451423190654, -8.187796395137616],
              [-39.98845143111236, -8.187796353497957],
              [-39.988451439229834, -8.187796311895774],
              [-39.988451447542872, -8.18779627033191],
              [-39.988451456051301, -8.187796228807336],
              [-39.988451464754945, -8.18779618732292],
              [-39.988451473653598, -8.187796145879622],
              [-39.988451482747074, -8.187796104478311],
              [-39.988451492035161, -8.187796063119906],
              [-39.988451501517666, -8.187796021805328],
              [-39.98845151119437, -8.187795980535471],
              [-39.98845152106508, -8.18779593931125],
              [-39.98845153112957, -8.187795898133572],
              [-39.988451541387604, -8.187795857003346],
              [-39.98845155183897, -8.187795815921451],
              [-39.988451562483441, -8.187795774888794],
              [-39.988451573320773, -8.18779573390629],
              [-39.988451584350742, -8.187795692974824],
              [-39.98845159557311, -8.187795652095335],
              [-39.988451606987603, -8.187795611268676],
              [-39.988451618593992, -8.187795570495721],
              [-39.988451630392021, -8.187795529777453],
              [-39.988451642381413, -8.187795489114711],
              [-39.988451654561921, -8.187795448508359],
              [-39.988451666933287, -8.187795407959355],
              [-39.988451679495221, -8.187795367468558],
              [-39.988451692247452, -8.187795327036815],
              [-39.988451705189696, -8.187795286665065],
              [-39.988451718321684, -8.18779524635422],
              [-39.988451731643117, -8.187795206105111],
              [-39.988451745153696, -8.18779516591864],
              [-39.988451758853145, -8.187795125795693],
              [-39.988451772741129, -8.187795085737118],
              [-39.988451786817372, -8.187795045743837],
              [-39.988451801081574, -8.187795005816739],
              [-39.988451815533381, -8.187794965956666],
              [-39.988451830172515, -8.187794926164479],
              [-39.988451844998615, -8.187794886441118],
              [-39.988451860011402, -8.187794846787371],
              [-39.988451875210515, -8.187794807204183],
              [-39.988451890595634, -8.187794767692395],
              [-39.988451906166418, -8.187794728252875],
              [-39.988451921922511, -8.187794688886466],
              [-39.98845193786358, -8.187794649594032],
              [-39.988451953989276, -8.187794610376496],
              [-39.988451970299252, -8.18779457123466],
              [-39.98845198679313, -8.187794532169404],
              [-39.988452003470563, -8.187794493181578],
              [-39.988452020331195, -8.187794454272025],
              [-39.988452037374621, -8.187794415441662],
              [-39.988452054600501, -8.187794376691304],
              [-39.988452072008421, -8.187794338021769],
              [-39.988452089598034, -8.187794299433929],
              [-39.988452107368943, -8.187794260928662],
              [-39.988452125320755, -8.18779422250676],
              [-39.98845214345306, -8.187794184169102],
              [-39.988452161765487, -8.187794145916532],
              [-39.988452180257624, -8.187794107749877],
              [-39.988452198929053, -8.187794069669973],
              [-39.988452217779304, -8.18779403167798],
              [-39.988452256908815, -8.187794042829401],
              [-39.988786830106235, -8.187123500091916],
              [-39.988786862312111, -8.187123523051662],
              [-39.988786908008223, -8.187123432762357],
              [-39.988786954714655, -8.187123342987718],
              [-39.988787002425553, -8.187123253738978],
              [-39.988787051134977, -8.187123165027293],
              [-39.988787100836824, -8.187123076863754],
              [-39.988787151524889, -8.187122989259365],
              [-39.988787203192828, -8.187122902225106],
              [-39.988787255834183, -8.187122815771843],
              [-39.988787309442387, -8.187122729910394],
              [-39.988787364010705, -8.187122644651447],
              [-39.988787419532343, -8.187122560005724],
              [-39.988787476000361, -8.187122475983751],
              [-39.988787533407702, -8.187122392596077],
              [-39.988787591747183, -8.187122309853089],
              [-39.988787651011506, -8.187122227765171],
              [-39.988787711193261, -8.187122146342553],
              [-39.988787772284944, -8.187122065595403],
              [-39.988787834278902, -8.187121985533837],
              [-39.988787897167384, -8.187121906167864],
              [-39.988787960942524, -8.187121827507408],
              [-39.988788025596385, -8.187121749562269],
              [-39.988788091120846, -8.187121672342213],
              [-39.98878815750772, -8.187121595856892],
              [-39.988788224748731, -8.187121520115877],
              [-39.988788292835459, -8.187121445128625],
              [-39.988788361759397, -8.187121370904508],
              [-39.988788431511921, -8.187121297452791],
              [-39.988788502084326, -8.187121224782693],
              [-39.988788573467765, -8.187121152903291],
              [-39.988788645653351, -8.187121081823554],
              [-39.98878871863203, -8.187121011552362],
              [-39.988788792394686, -8.187120942098522],
              [-39.988788866932111, -8.18712087347069],
              [-39.988788942234969, -8.187120805677454],
              [-39.988789018293865, -8.187120738727318],
              [-39.988789095099271, -8.187120672628609],
              [-39.98878917264161, -8.187120607389602],
              [-39.988789250911168, -8.187120543018457],
              [-39.988789329898161, -8.187120479523243],
              [-39.988789409592755, -8.187120416911855],
              [-39.988789489984931, -8.187120355192143],
              [-39.988789571064665, -8.187120294371807],
              [-39.988789652821829, -8.187120234458458],
              [-39.988789735246193, -8.187120175459595],
              [-39.988789818327469, -8.187120117382596],
              [-39.988789902055224, -8.187120060234708],
              [-39.988789986419057, -8.18712000402307],
              [-39.988790071408374, -8.187119948754724],
              [-39.988790157012581, -8.187119894436577],
              [-39.988790243220954, -8.187119841075409],
              [-39.988790330022731, -8.187119788677883],
              [-39.988790417407046, -8.187119737250592],
              [-39.988790505363006, -8.187119686799905],
              [-39.988790593879578, -8.187119637332177],
              [-39.988790682945726, -8.187119588853568],
              [-39.98879077255031, -8.187119541370137],
              [-39.988790862682123, -8.187119494887822],
              [-39.988790953329882, -8.18711944941245],
              [-39.988791044482291, -8.187119404949671],
              [-39.988791136127936, -8.187119361505074],
              [-39.988791228255366, -8.187119319084083],
              [-39.988791320853053, -8.187119277692005],
              [-39.988791413909432, -8.187119237333997],
              [-39.98879150741287, -8.187119198015107],
              [-39.988791601351672, -8.187119159740279],
              [-39.988791695714113, -8.187119122514247],
              [-39.988791790488378, -8.187119086341701],
              [-39.988791885662629, -8.187119051227159],
              [-39.988791981224949, -8.187119017175018],
              [-39.988792077163424, -8.187118984189514],
              [-39.988792173466052, -8.18711895227478],
              [-39.98879227012079, -8.18711892143479],
              [-39.988792367115536, -8.187118891673414],
              [-39.988792464438198, -8.187118862994383],
              [-39.988792562076597, -8.18711883540127],
              [-39.988792660018525, -8.187118808897527],
              [-39.988792758251741, -8.187118783486483],
              [-39.988792856763972, -8.187118759171264],
              [-39.988792955542891, -8.187118735954952],
              [-39.988793054576156, -8.18711871384046],
              [-39.988793153851383, -8.187118692830529],
              [-39.988793253356164, -8.187118672927781],
              [-39.988793353078073, -8.187118654134709],
              [-39.988793453004632, -8.187118636453672],
              [-39.988793553123344, -8.187118619886864],
              [-39.988793653421702, -8.187118604436364],
              [-39.98879375388718, -8.187118590104101],
              [-39.988793854507193, -8.187118576891889],
              [-39.98879395526918, -8.18711856480135],
              [-39.98879405616055, -8.187118553834006],
              [-39.988794157168677, -8.187118543991206],
              [-39.988794258280933, -8.187118535274228],
              [-39.988794359484707, -8.187118527684131],
              [-39.988794460767302, -8.187118521221869],
              [-39.988794562116098, -8.187118515888269],
              [-39.988794663518405, -8.187118511683964],
              [-39.988794764961554, -8.18711850860948],
              [-39.988794866432862, -8.187118506665213],
              [-39.988794967919645, -8.187118505851396],
              [-39.988794967920114, -8.187118505850556],
            ],
          ],
          [
            [
              [-39.963828923230459, -8.208930968805463],
              [-39.963828931197483, -8.208930965591078],
              [-39.965411572478644, -8.208927175071976],
              [-39.966996028582308, -8.208923373976972],
              [-39.966996030460471, -8.208923377167251],
              [-39.966996057511615, -8.208923377062156],
              [-39.966996084562339, -8.208923376876752],
              [-39.966996111612396, -8.208923376611033],
              [-39.966996138661528, -8.208923376265009],
              [-39.966996165709517, -8.20892337583868],
              [-39.966996192756099, -8.208923375332066],
              [-39.96699621980104, -8.208923374745133],
              [-39.966996246844111, -8.208923374077918],
              [-39.966996273885073, -8.208923373330434],
              [-39.966996300923682, -8.20892337250265],
              [-39.966996327959684, -8.208923371594617],
              [-39.966996354992858, -8.208923370606303],
              [-39.966996382022941, -8.208923369537738],
              [-39.966996409049727, -8.208923368388948],
              [-39.966996436072961, -8.208923367159926],
              [-39.966996463092372, -8.208923365850673],
              [-39.966996490107761, -8.208923364461212],
              [-39.966996517118879, -8.208923362991516],
              [-39.966996544125486, -8.208923361441666],
              [-39.966996571127325, -8.208923359811656],
              [-39.966996598124169, -8.208923358101469],
              [-39.966996625115783, -8.208923356311157],
              [-39.966996652101926, -8.208923354440687],
              [-39.966996679082364, -8.208923352490146],
              [-39.966996706056818, -8.208923350459498],
              [-39.966996733025091, -8.208923348348778],
              [-39.966996759986941, -8.208923346157986],
              [-39.966996786942111, -8.208923343887191],
              [-39.966996813890376, -8.208923341536376],
              [-39.96699684083147, -8.208923339105544],
              [-39.966996867765182, -8.20892333659476],
              [-39.966996894691277, -8.208923334004027],
              [-39.966996921609507, -8.20892333133334],
              [-39.966996948519615, -8.208923328582761],
              [-39.966996975421381, -8.208923325752311],
              [-39.966997002314564, -8.208923322841983],
              [-39.966997029198922, -8.208923319851843],
              [-39.96699705607422, -8.208923316781888],
              [-39.966997082940196, -8.208923313632173],
              [-39.966997109796665, -8.2089233104027],
              [-39.966997136643329, -8.20892330709353],
              [-39.966997163479988, -8.208923303704637],
              [-39.966997190306394, -8.208923300236087],
              [-39.966997217122298, -8.208923296687928],
              [-39.966997243927473, -8.208923293060183],
              [-39.966997270721677, -8.208923289352876],
              [-39.966997297504676, -8.208923285566021],
              [-39.966997324276228, -8.208923281699679],
              [-39.966997351036106, -8.208923277753868],
              [-39.966997377784047, -8.208923273728656],
              [-39.966997404519844, -8.208923269624007],
              [-39.966997431243243, -8.208923265440012],
              [-39.966997457954001, -8.208923261176697],
              [-39.966997484651898, -8.208923256834108],
              [-39.966997511336672, -8.208923252412287],
              [-39.966997538008115, -8.208923247911233],
              [-39.966997564665981, -8.208923243331055],
              [-39.96699759131004, -8.208923238671733],
              [-39.966997617940009, -8.208923233933312],
              [-39.966997644555704, -8.208923229115879],
              [-39.966997671156889, -8.208923224219447],
              [-39.966997697743288, -8.208923219244042],
              [-39.966997724314709, -8.208923214189735],
              [-39.96699775087086, -8.20892320905657],
              [-39.966997777411557, -8.208923203844588],
              [-39.966997803936565, -8.208923198553817],
              [-39.966997830445614, -8.208923193184368],
              [-39.966997856938484, -8.208923187736216],
              [-39.96699788341494, -8.208923182209416],
              [-39.966997909874749, -8.208923176604047],
              [-39.966997936317682, -8.208923170920153],
              [-39.966997962743498, -8.208923165157779],
              [-39.966997989151949, -8.208923159316976],
              [-39.966998015542828, -8.208923153397778],
              [-39.966998041915879, -8.208923147400279],
              [-39.966998068270875, -8.208923141324497],
              [-39.966998094607582, -8.208923135170494],
              [-39.966998120925751, -8.208923128938338],
              [-39.966998147225183, -8.208923122628079],
              [-39.966998173505623, -8.208923116239761],
              [-39.966998199766827, -8.20892310977346],
              [-39.96699822600857, -8.208923103229226],
              [-39.966998252230631, -8.208923096607089],
              [-39.966998278432776, -8.208923089907156],
              [-39.966998304614755, -8.208923083129459],
              [-39.966998330776342, -8.208923076274051],
              [-39.966998356917323, -8.20892306934101],
              [-39.966998383037435, -8.208923062330379],
              [-39.966998409136465, -8.208923055242256],
              [-39.966998435214201, -8.208923048076654],
              [-39.966998461270364, -8.208923040833668],
              [-39.966998487304757, -8.208923033513372],
              [-39.966998513317129, -8.208923026115807],
              [-39.966998539307276, -8.208923018641054],
              [-39.966998565274935, -8.208923011089166],
              [-39.966998591219898, -8.208923003460212],
              [-39.966998617141932, -8.208922995754293],
              [-39.966998643040803, -8.208922987971423],
              [-39.966998668916268, -8.208922980111709],
              [-39.966998660296667, -8.208923005888156],
              [-39.969566815057156, -8.208138755004851],
              [-39.962759875673548, -8.208086979277914],
              [-39.963823298100344, -8.208928989333801],
              [-39.963823253228739, -8.208929005022034],
              [-39.963823301738536, -8.208929043164888],
              [-39.963823350507859, -8.208929080977823],
              [-39.963823399534419, -8.208929118459135],
              [-39.96382344881593, -8.208929155607077],
              [-39.963823498350131, -8.20892919241993],
              [-39.963823548134719, -8.20892922889602],
              [-39.96382359816738, -8.208929265033563],
              [-39.963823648445796, -8.208929300830967],
              [-39.963823698967651, -8.20892933628655],
              [-39.963823749730608, -8.208929371398638],
              [-39.963823800732285, -8.208929406165668],
              [-39.963823851970332, -8.208929440585957],
              [-39.963823903442389, -8.208929474657984],
              [-39.963823955146054, -8.208929508380082],
              [-39.963824007078927, -8.208929541750759],
              [-39.963824059238632, -8.208929574768437],
              [-39.963824111622714, -8.208929607431607],
              [-39.963824164228768, -8.208929639738757],
              [-39.963824217054345, -8.208929671688377],
              [-39.963824270097021, -8.20892970327894],
              [-39.963824323354302, -8.208929734509072],
              [-39.963824376823752, -8.208929765377244],
              [-39.963824430502861, -8.20892979588208],
              [-39.963824484389157, -8.208929826022171],
              [-39.963824538480168, -8.208929855796097],
              [-39.963824592773335, -8.208929885202441],
              [-39.963824647266193, -8.208929914239912],
              [-39.963824701956185, -8.208929942907123],
              [-39.963824756840793, -8.208929971202728],
              [-39.963824811917462, -8.208929999125491],
              [-39.963824867183639, -8.208930026674032],
              [-39.963824922636775, -8.208930053847153],
              [-39.963824978274303, -8.208930080643515],
              [-39.963825034093624, -8.208930107061922],
              [-39.963825090092165, -8.208930133101163],
              [-39.963825146267332, -8.208930158759969],
              [-39.963825202616505, -8.208930184037243],
              [-39.963825259137103, -8.208930208931713],
              [-39.963825315826476, -8.208930233442317],
              [-39.963825372682017, -8.208930257567852],
              [-39.963825429701082, -8.208930281307238],
              [-39.963825486881035, -8.208930304659347],
              [-39.963825544219226, -8.208930327623126],
              [-39.963825601712998, -8.208930350197505],
              [-39.963825659359664, -8.208930372381392],
              [-39.963825717156588, -8.208930394173827],
              [-39.963825775101071, -8.208930415573755],
              [-39.963825833190434, -8.208930436580216],
              [-39.963825891421976, -8.20893045719224],
              [-39.963825949793019, -8.208930477408831],
              [-39.963826008300835, -8.20893049722906],
              [-39.96382606694273, -8.208930516652025],
              [-39.963826125715975, -8.208930535676812],
              [-39.963826184617844, -8.208930554302583],
              [-39.96382624364562, -8.208930572528407],
              [-39.963826302796548, -8.20893059035347],
              [-39.963826362067927, -8.208930607776985],
              [-39.963826421456979, -8.208930624798063],
              [-39.963826480960947, -8.208930641415996],
              [-39.963826540577067, -8.208930657629972],
              [-39.96382660030261, -8.208930673439259],
              [-39.963826660134785, -8.208930688843092],
              [-39.963826720070841, -8.208930703840817],
              [-39.963826780107972, -8.208930718431647],
              [-39.963826840243392, -8.208930732614991],
              [-39.963826900474352, -8.20893074639014],
              [-39.963826960798038, -8.208930759756495],
              [-39.96382702121165, -8.208930772713405],
              [-39.963827081712381, -8.208930785260279],
              [-39.963827142297454, -8.20893079739654],
              [-39.963827202964048, -8.208930809121613],
              [-39.963827263709355, -8.20893082043499],
              [-39.963827324530534, -8.208930831336083],
              [-39.963827385424807, -8.208930841824484],
              [-39.963827446389331, -8.208930851899588],
              [-39.963827507421279, -8.208930861561017],
              [-39.963827568517836, -8.208930870808302],
              [-39.96382762967616, -8.208930879640985],
              [-39.963827690893417, -8.208930888058694],
              [-39.963827752166779, -8.208930896061005],
              [-39.963827813493381, -8.20893090364758],
              [-39.963827874870418, -8.208930910818067],
              [-39.963827936295012, -8.208930917572104],
              [-39.963827997764334, -8.208930923909394],
              [-39.963828059275542, -8.20893092982964],
              [-39.963828120825774, -8.208930935332582],
              [-39.963828182412172, -8.208930940417936],
              [-39.963828244031895, -8.208930945085509],
              [-39.963828305682092, -8.208930949335041],
              [-39.963828367359888, -8.208930953166352],
              [-39.963828429062431, -8.208930956579293],
              [-39.96382849078686, -8.208930959573657],
              [-39.96382855253033, -8.208930962149346],
              [-39.963828614289959, -8.208930964306184],
              [-39.963828676062903, -8.208930966044127],
              [-39.963828737846285, -8.208930967363095],
              [-39.963828799637248, -8.208930968262983],
              [-39.963828861432937, -8.208930968743775],
              [-39.963828923230459, -8.208930968805463],
            ],
          ],
          [
            [
              [-39.930715185096822, -8.218232415760832],
              [-39.930715179991033, -8.218232451572044],
              [-39.930819827513169, -8.214755021583521],
              [-39.932682224251295, -8.214526139154316],
              [-39.932682234022899, -8.214526141029157],
              [-39.93268234361102, -8.214526126886955],
              [-39.932682453018245, -8.214526111416836],
              [-39.932682562228351, -8.214526094621101],
              [-39.932682671225166, -8.214526076502212],
              [-39.93268277999254, -8.214526057062898],
              [-39.932682888514343, -8.214526036305978],
              [-39.932682996774496, -8.214526014234572],
              [-39.932683104756968, -8.214525990851937],
              [-39.932683212445767, -8.214525966161546],
              [-39.932683319824918, -8.214525940167041],
              [-39.932683426878512, -8.214525912872297],
              [-39.932683533590691, -8.214525884281315],
              [-39.932683639945658, -8.214525854398389],
              [-39.932683745927626, -8.214525823227905],
              [-39.932683851520927, -8.214525790774495],
              [-39.93268395670988, -8.214525757042964],
              [-39.932684061478923, -8.214525722038303],
              [-39.932684165812518, -8.214525685765718],
              [-39.932684269695216, -8.214525648230586],
              [-39.93268437311162, -8.214525609438434],
              [-39.932684476046418, -8.214525569395045],
              [-39.932684578484341, -8.214525528106345],
              [-39.932684680410212, -8.214525485578436],
              [-39.932684781808923, -8.214525441817655],
              [-39.932684882665498, -8.214525396830437],
              [-39.932684982964915, -8.214525350623457],
              [-39.93268508269238, -8.214525303203601],
              [-39.932685181833087, -8.214525254577865],
              [-39.932685280372347, -8.214525204753476],
              [-39.932685378295567, -8.214525153737791],
              [-39.932685475588222, -8.21452510153836],
              [-39.932685572235918, -8.21452504816294],
              [-39.932685668224309, -8.214524993619438],
              [-39.932685763539197, -8.214524937915922],
              [-39.932685858166451, -8.214524881060671],
              [-39.932685952092051, -8.214524823062101],
              [-39.93268604530207, -8.214524763928804],
              [-39.93268613778271, -8.214524703669518],
              [-39.932686229520264, -8.214524642293176],
              [-39.932686320501126, -8.214524579808906],
              [-39.932686410711838, -8.214524516225925],
              [-39.93268650013902, -8.2145244515537],
              [-39.932686588769421, -8.2145243858018],
              [-39.932686676589917, -8.214524318979963],
              [-39.932686763587476, -8.214524251098059],
              [-39.932686849749246, -8.214524182166176],
              [-39.932686935062421, -8.214524112194528],
              [-39.93268701951439, -8.214524041193462],
              [-39.932687103092611, -8.214523969173516],
              [-39.932687185784722, -8.214523896145352],
              [-39.932687267578459, -8.214523822119823],
              [-39.932687348461712, -8.214523747107847],
              [-39.93268742842249, -8.21452367112059],
              [-39.932687507448946, -8.214523594169274],
              [-39.932687585529372, -8.214523516265297],
              [-39.932687662652206, -8.214523437420231],
              [-39.93268773880601, -8.214523357645726],
              [-39.932687813979491, -8.21452327695363],
              [-39.932687888161531, -8.214523195355904],
              [-39.932687961341117, -8.214523112864608],
              [-39.932688033507446, -8.214523029491971],
              [-39.932688104649777, -8.214522945250383],
              [-39.932688174757594, -8.214522860152284],
              [-39.932688243820515, -8.214522774210279],
              [-39.932688311828286, -8.214522687437128],
              [-39.932688378770855, -8.214522599845674],
              [-39.932688444638281, -8.214522511448918],
              [-39.9326885094208, -8.214522422259931],
              [-39.932688573108848, -8.214522332291947],
              [-39.932688635692962, -8.21452224155828],
              [-39.932688697163876, -8.214522150072391],
              [-39.93268875751248, -8.214522057847811],
              [-39.932688816729808, -8.214521964898232],
              [-39.932688874807141, -8.214521871237412],
              [-39.932688931735825, -8.214521776879202],
              [-39.93268898750744, -8.214521681837647],
              [-39.932689042113743, -8.214521586126789],
              [-39.932689095546614, -8.214521489760799],
              [-39.932689147798143, -8.214521392753989],
              [-39.932689198860579, -8.214521295120703],
              [-39.93268924872639, -8.214521196875436],
              [-39.932689297388158, -8.214521098032723],
              [-39.932689344838678, -8.214520998607224],
              [-39.932689391070916, -8.214520898613657],
              [-39.932689436078036, -8.214520798066834],
              [-39.932689479853359, -8.214520696981644],
              [-39.93268952239039, -8.214520595373095],
              [-39.932689563682857, -8.214520493256247],
              [-39.932689603724604, -8.214520390646221],
              [-39.932689642509729, -8.214520287558194],
              [-39.93268968003246, -8.21452018400746],
              [-39.932689716287264, -8.214520080009382],
              [-39.932689751268747, -8.214519975579314],
              [-39.932689784971728, -8.214519870732769],
              [-39.932689817391214, -8.214519765485294],
              [-39.932689848522415, -8.214519659852476],
              [-39.932689878360705, -8.214519553849964],
              [-39.932689906901672, -8.214519447493437],
              [-39.932689934141074, -8.214519340798683],
              [-39.932689924810447, -8.21451933805591],
              [-39.93291252002463, -8.213624608449177],
              [-39.932912556589365, -8.213624582779493],
              [-39.93291256197444, -8.213624561020529],
              [-39.932912567305365, -8.213624539248336],
              [-39.932912572582154, -8.213624517463019],
              [-39.932912577804721, -8.213624495664776],
              [-39.93291258297306, -8.213624473853704],
              [-39.932912588087142, -8.21362445202994],
              [-39.932912593146931, -8.213624430193594],
              [-39.932912598152384, -8.213624408344844],
              [-39.932912603103489, -8.213624386483804],
              [-39.932912608000215, -8.21362436461061],
              [-39.932912612842514, -8.213624342725376],
              [-39.932912617630365, -8.213624320828277],
              [-39.932912622363737, -8.213624298919425],
              [-39.932912627042604, -8.213624276998958],
              [-39.932912631666966, -8.213624255066978],
              [-39.932912636236729, -8.21362423312365],
              [-39.932912640751937, -8.213624211169144],
              [-39.93291264521249, -8.21362418920355],
              [-39.932912649618416, -8.213624167227012],
              [-39.93291265396968, -8.213624145239649],
              [-39.932912658266225, -8.213624123241614],
              [-39.932912662508059, -8.213624101233043],
              [-39.932912666695152, -8.213624079214055],
              [-39.932912670827442, -8.213624057184814],
              [-39.932912674904948, -8.213624035145427],
              [-39.932912678927622, -8.213624013096071],
              [-39.932912682895441, -8.213623991036823],
              [-39.932912686808386, -8.213623968967859],
              [-39.932912690666427, -8.213623946889305],
              [-39.932912694469557, -8.213623924801261],
              [-39.932912698217706, -8.21362390270396],
              [-39.932912701910915, -8.213623880597437],
              [-39.932912705549136, -8.213623858481876],
              [-39.932912709132324, -8.213623836357412],
              [-39.932912712660489, -8.213623814224164],
              [-39.932912716133586, -8.213623792082252],
              [-39.932912719551588, -8.213623769931859],
              [-39.932912722914509, -8.2136237477731],
              [-39.932912726222305, -8.213623725606082],
              [-39.932912729474957, -8.213623703430988],
              [-39.932912732672449, -8.213623681247933],
              [-39.932912735814746, -8.21362365905704],
              [-39.932912738901862, -8.213623636858458],
              [-39.932912741933748, -8.213623614652352],
              [-39.932912744910389, -8.213623592438797],
              [-39.932912747831772, -8.213623570217971],
              [-39.932912750697902, -8.21362354799002],
              [-39.932912753508717, -8.213623525755033],
              [-39.93291275626423, -8.21362350351318],
              [-39.932912758964413, -8.213623481264586],
              [-39.932912761609252, -8.213623459009398],
              [-39.932912764198733, -8.213623436747747],
              [-39.932912766732848, -8.21362341447978],
              [-39.932912769211562, -8.213623392205616],
              [-39.932912771634868, -8.213623369925438],
              [-39.932912774002766, -8.213623347639318],
              [-39.932912776315213, -8.213623325347418],
              [-39.932912778572216, -8.213623303049884],
              [-39.932912780773741, -8.213623280746836],
              [-39.932912782919807, -8.213623258438439],
              [-39.93291278501038, -8.213623236124816],
              [-39.93291278704546, -8.213623213806079],
              [-39.932912789025011, -8.213623191482418],
              [-39.932912790949025, -8.213623169153918],
              [-39.932912792817511, -8.213623146820726],
              [-39.932912794630447, -8.213623124482998],
              [-39.932912796387818, -8.213623102140851],
              [-39.932912798089617, -8.213623079794452],
              [-39.932912799735831, -8.213623057443902],
              [-39.932912801326445, -8.213623035089373],
              [-39.932912802861466, -8.213623012730976],
              [-39.932912804340873, -8.21362299036887],
              [-39.932912805764651, -8.213622968003184],
              [-39.932912807132809, -8.213622945634024],
              [-39.932912808445316, -8.213622923261591],
              [-39.932912809702174, -8.213622900886],
              [-39.932912810903389, -8.213622878507339],
              [-39.93291281204894, -8.213622856125788],
              [-39.93291281313882, -8.213622833741505],
              [-39.932912814173015, -8.213622811354536],
              [-39.932912815151539, -8.213622788965143],
              [-39.932912816074378, -8.213622766573376],
              [-39.93291281694151, -8.2136227441794],
              [-39.932912817752943, -8.213622721783347],
              [-39.93291281850869, -8.213622699385382],
              [-39.932912819208703, -8.213622676985615],
              [-39.932912819853009, -8.213622654584169],
              [-39.932912820441608, -8.213622632181217],
              [-39.932912820974487, -8.213622609776898],
              [-39.932912821451637, -8.213622587371292],
              [-39.932912821873053, -8.213622564964604],
              [-39.932912822238748, -8.21362254255693],
              [-39.932912822548715, -8.213622520148425],
              [-39.932912822802933, -8.213622497739211],
              [-39.932912823001431, -8.213622475329466],
              [-39.932912823144193, -8.213622452919296],
              [-39.932912823231199, -8.213622430508829],
              [-39.932912823262484, -8.213622408098225],
              [-39.93291282323802, -8.213622385687602],
              [-39.932912787087858, -8.213622346745629],
              [-39.932910180034938, -8.212505738728455],
              [-39.932910216042927, -8.212505716832991],
              [-39.932910216850594, -8.212505573333967],
              [-39.932910219943402, -8.212505429865766],
              [-39.932910225320605, -8.212505286464545],
              [-39.932910232980802, -8.212505143166331],
              [-39.932910242922112, -8.212505000007278],
              [-39.932910255141991, -8.212504857023363],
              [-39.932910269637397, -8.212504714250587],
              [-39.932910286404663, -8.212504571724935],
              [-39.932910305439563, -8.212504429482252],
              [-39.932910326737314, -8.212504287558364],
              [-39.932910350292545, -8.212504145988982],
              [-39.93291037609935, -8.21250400480975],
              [-39.932910404151208, -8.212503864056224],
              [-39.932910434441077, -8.212503723763819],
              [-39.932910466961296, -8.212503583967896],
              [-39.932910501703716, -8.212503444703598],
              [-39.93291053865957, -8.212503306006001],
              [-39.93291057781957, -8.21250316791002],
              [-39.932910619173839, -8.212503030450408],
              [-39.932910662711983, -8.212502893661796],
              [-39.932910708423037, -8.212502757578608],
              [-39.932910756295492, -8.212502622235089],
              [-39.932910806317302, -8.212502487665324],
              [-39.932910858475843, -8.212502353903192],
              [-39.932910912758032, -8.212502220982376],
              [-39.932910969150178, -8.212502088936308],
              [-39.932911027638085, -8.212501957798246],
              [-39.932911088207035, -8.212501827601216],
              [-39.932911150841782, -8.212501698377986],
              [-39.932911215526545, -8.21250157016109],
              [-39.93291128224503, -8.212501442982788],
              [-39.932911350980483, -8.212501316875096],
              [-39.932911421715566, -8.21250119186978],
              [-39.932911494432481, -8.212501067998309],
              [-39.932911569112932, -8.212500945291843],
              [-39.932911645738102, -8.212500823781324],
              [-39.932911724288715, -8.212500703497321],
              [-39.932911804744982, -8.212500584470083],
              [-39.932911887086647, -8.212500466729598],
              [-39.932911971293009, -8.212500350305506],
              [-39.93291205734284, -8.212500235227107],
              [-39.932912145214502, -8.212500121523375],
              [-39.932912234885848, -8.212500009222916],
              [-39.932912326334318, -8.21249989835405],
              [-39.932912419536898, -8.21249978894466],
              [-39.932912514470082, -8.212499681022264],
              [-39.932912611110027, -8.212499574614066],
              [-39.932912709432387, -8.212499469746845],
              [-39.9329128094124, -8.212499366446986],
              [-39.932912911024886, -8.212499264740529],
              [-39.932913014244292, -8.212499164653021],
              [-39.932913119044613, -8.212499066209743],
              [-39.932913225399489, -8.21249896943541],
              [-39.93291333328213, -8.212498874354386],
              [-39.932913442665345, -8.212498780990636],
              [-39.932913553521651, -8.212498689367658],
              [-39.932913665823115, -8.212498599508489],
              [-39.932913779541479, -8.212498511435792],
              [-39.932913894648102, -8.212498425171718],
              [-39.932914011114001, -8.212498340737961],
              [-39.932914128909886, -8.21249825815582],
              [-39.932914248006057, -8.212498177446069],
              [-39.932914368372572, -8.212498098629036],
              [-39.932914489979112, -8.21249802172451],
              [-39.932914612795066, -8.212497946751943],
              [-39.932914736789513, -8.212497873730133],
              [-39.932914861931245, -8.212497802677456],
              [-39.932914988188749, -8.212497733611865],
              [-39.932915115530243, -8.212497666550711],
              [-39.932915243923667, -8.212497601510872],
              [-39.932915373336733, -8.212497538508709],
              [-39.932915503736815, -8.212497477560101],
              [-39.932915635091085, -8.212497418680378],
              [-39.932915767366524, -8.212497361884404],
              [-39.932915900529764, -8.212497307186441],
              [-39.932916034547354, -8.212497254600251],
              [-39.932916169385507, -8.21249720413911],
              [-39.932916305010288, -8.212497155815679],
              [-39.932916441387562, -8.212497109642152],
              [-39.932916578482995, -8.212497065630165],
              [-39.932916716262078, -8.212497023790744],
              [-39.932916854690106, -8.21249698413447],
              [-39.932916993732249, -8.212496946671306],
              [-39.932917133353506, -8.212496911410671],
              [-39.932917273518711, -8.21249687836146],
              [-39.932917414192602, -8.212496847532021],
              [-39.932917555339735, -8.212496818930067],
              [-39.932917696924626, -8.212496792562808],
              [-39.932917838911571, -8.212496768436914],
              [-39.932917981264858, -8.212496746558413],
              [-39.932918123948646, -8.21249672693285],
              [-39.932918266927025, -8.212496709565125],
              [-39.932918410163978, -8.212496694459627],
              [-39.932918553623459, -8.212496681620191],
              [-39.932918697269379, -8.212496671050024],
              [-39.932918841065529, -8.212496662751795],
              [-39.932918984975728, -8.212496656727586],
              [-39.932919128963754, -8.212496652978921],
              [-39.932919272993722, -8.212496651506296],
              [-39.932919236985725, -8.212496673401757],
              [-39.934154489184564, -8.212493809041254],
              [-39.934154504416838, -8.212493787192484],
              [-39.93415453925617, -8.21249378704505],
              [-39.934154574094656, -8.212493786764423],
              [-39.934154608931806, -8.212493786350583],
              [-39.934154643767123, -8.212493785803543],
              [-39.934154678600052, -8.212493785123321],
              [-39.934154713430111, -8.21249378430992],
              [-39.934154748256788, -8.212493783363357],
              [-39.93415478307957, -8.21249378228363],
              [-39.93415481789792, -8.212493781070778],
              [-39.934154852711352, -8.212493779724801],
              [-39.934154887519334, -8.212493778245735],
              [-39.934154922321369, -8.212493776633579],
              [-39.934154957116938, -8.212493774888367],
              [-39.934154991905537, -8.212493773010141],
              [-39.934155026686625, -8.212493770998892],
              [-39.93415506145972, -8.212493768854698],
              [-39.934155096224288, -8.212493766577548],
              [-39.934155130979839, -8.212493764167526],
              [-39.934155165725848, -8.212493761624605],
              [-39.934155200461817, -8.212493758948877],
              [-39.934155235187198, -8.212493756140336],
              [-39.934155269901524, -8.212493753199027],
              [-39.934155304604261, -8.212493750125025],
              [-39.934155339294882, -8.212493746918371],
              [-39.934155373972921, -8.212493743579083],
              [-39.934155408637828, -8.212493740107259],
              [-39.934155443289121, -8.212493736502887],
              [-39.934155477926261, -8.21249373276607],
              [-39.934155512548763, -8.212493728896851],
              [-39.934155547156088, -8.212493724895266],
              [-39.934155581747746, -8.212493720761382],
              [-39.934155616323245, -8.212493716495294],
              [-39.934155650882026, -8.212493712097],
              [-39.934155685423633, -8.21249370756664],
              [-39.934155719947526, -8.212493702904228],
              [-39.934155754453208, -8.212493698109849],
              [-39.934155788940167, -8.212493693183569],
              [-39.934155823407878, -8.21249368812547],
              [-39.934155857855878, -8.212493682935623],
              [-39.934155892283627, -8.212493677614104],
              [-39.934155926690615, -8.212493672160992],
              [-39.934155961076343, -8.212493666576362],
              [-39.934155995440314, -8.212493660860314],
              [-39.934156029782017, -8.212493655012899],
              [-39.934156064100925, -8.212493649034219],
              [-39.934156098396549, -8.212493642924379],
              [-39.934156132668399, -8.212493636683444],
              [-39.934156166915962, -8.212493630311517],
              [-39.934156201138705, -8.212493623808687],
              [-39.934156235336161, -8.212493617175069],
              [-39.934156269507803, -8.212493610410748],
              [-39.93415630365314, -8.212493603515814],
              [-39.934156337771668, -8.212493596490383],
              [-39.934156371862883, -8.212493589334541],
              [-39.934156405926274, -8.21249358204842],
              [-39.934156439961363, -8.212493574632097],
              [-39.934156473967633, -8.212493567085716],
              [-39.934156507944572, -8.212493559409349],
              [-39.934156541891682, -8.212493551603117],
              [-39.934156575808487, -8.212493543667174],
              [-39.934156609694455, -8.212493535601599],
              [-39.934156643549123, -8.212493527406515],
              [-39.934156677371988, -8.212493519082043],
              [-39.934156711162501, -8.212493510628333],
              [-39.934156744920216, -8.212493502045469],
              [-39.934156778644628, -8.212493493333591],
              [-39.934156812335218, -8.212493484492832],
              [-39.934156845991517, -8.212493475523329],
              [-39.934156879613006, -8.21249346642522],
              [-39.934156913199203, -8.212493457198621],
              [-39.93415694674961, -8.212493447843649],
              [-39.93415698026373, -8.212493438360495],
              [-39.9341570137411, -8.212493428749257],
              [-39.934157047181181, -8.212493419010094],
              [-39.934157080583482, -8.212493409143155],
              [-39.934157113947542, -8.212493399148556],
              [-39.934157147272863, -8.212493389026456],
              [-39.934157180558955, -8.212493378777053],
              [-39.934157213805314, -8.212493368400422],
              [-39.934157247011434, -8.212493357896756],
              [-39.934157280176876, -8.212493347266198],
              [-39.934157313301114, -8.212493336508937],
              [-39.934157346383664, -8.212493325625086],
              [-39.934157379424057, -8.212493314614832],
              [-39.93415741242179, -8.212493303478333],
              [-39.934157445376378, -8.212493292215736],
              [-39.934157478287332, -8.212493280827234],
              [-39.934157511154176, -8.212493269312969],
              [-39.934157543976418, -8.212493257673138],
              [-39.934157576753606, -8.212493245907886],
              [-39.934157609485226, -8.212493234017407],
              [-39.934157642170781, -8.212493222001864],
              [-39.934157674809825, -8.212493209861432],
              [-39.934157707401852, -8.212493197596295],
              [-39.934157739946414, -8.212493185206636],
              [-39.934157772442994, -8.212493172692632],
              [-39.934157804891129, -8.21249316005448],
              [-39.934157837290343, -8.212493147292351],
              [-39.934157869640167, -8.212493134406447],
              [-39.934157846587681, -8.212493168147306],
              [-39.935283747569194, -8.212042136005978],
              [-39.935283760395656, -8.212042156870581],
              [-39.935283838879286, -8.212042125008885],
              [-39.93528391706095, -8.212042092418635],
              [-39.935283994933819, -8.212042059102673],
              [-39.935284072491108, -8.212042025063861],
              [-39.935284149726073, -8.212041990305195],
              [-39.935284226631978, -8.21204195482969],
              [-39.935284303202103, -8.212041918640447],
              [-39.935284379429795, -8.212041881740612],
              [-39.935284455308384, -8.212041844133424],
              [-39.935284530831296, -8.212041805822142],
              [-39.935284605991896, -8.212041766810104],
              [-39.935284680783681, -8.212041727100708],
              [-39.935284755200122, -8.212041686697429],
              [-39.935284829234718, -8.212041645603776],
              [-39.93528490288103, -8.21204160382335],
              [-39.935284976132635, -8.212041561359774],
              [-39.935285048983147, -8.212041518216742],
              [-39.935285121426219, -8.212041474398024],
              [-39.935285193455542, -8.212041429907426],
              [-39.935285265064834, -8.212041384748854],
              [-39.935285336247858, -8.212041338926213],
              [-39.935285406998396, -8.212041292443512],
              [-39.935285477310281, -8.212041245304839],
              [-39.935285547177415, -8.212041197514232],
              [-39.935285616593681, -8.212041149075912],
              [-39.935285685553033, -8.212041099994055],
              [-39.935285754049481, -8.212041050272985],
              [-39.93528582207702, -8.212040999916981],
              [-39.935285889629775, -8.212040948930486],
              [-39.935285956701797, -8.212040897317884],
              [-39.935286023287276, -8.212040845083736],
              [-39.935286089380398, -8.21204079223256],
              [-39.935286154975408, -8.212040738768962],
              [-39.93528622006658, -8.212040684697625],
              [-39.935286284648257, -8.212040630023244],
              [-39.935286348714797, -8.212040574750588],
              [-39.935286412260616, -8.212040518884459],
              [-39.935286475280201, -8.212040462429769],
              [-39.935286537768029, -8.212040405391393],
              [-39.935286599718658, -8.212040347774314],
              [-39.935286661126689, -8.21204028958358],
              [-39.935286721986785, -8.212040230824206],
              [-39.935286782293623, -8.212040171501366],
              [-39.935286842041975, -8.212040111620185],
              [-39.935286901226611, -8.212040051185921],
              [-39.935286959842372, -8.212039990203836],
              [-39.93528701788415, -8.21203992867922],
              [-39.935287075346899, -8.212039866617474],
              [-39.935287132225604, -8.212039804023965],
              [-39.935287188515296, -8.212039740904167],
              [-39.935287244211096, -8.212039677263579],
              [-39.935287299308122, -8.212039613107772],
              [-39.935287353801591, -8.212039548442316],
              [-39.935287407686765, -8.212039483272834],
              [-39.935287460958897, -8.212039417605014],
              [-39.935287513613389, -8.212039351444561],
              [-39.935287565645638, -8.212039284797314],
              [-39.935287617051124, -8.212039217669009],
              [-39.935287667825342, -8.212039150065504],
              [-39.935287717963881, -8.21203908199271],
              [-39.93528776746237, -8.212039013456556],
              [-39.935287816316503, -8.212038944463012],
              [-39.935287864521996, -8.212038875018107],
              [-39.935287912074678, -8.212038805127873],
              [-39.935287958970378, -8.212038734798412],
              [-39.935288005205031, -8.212038664035839],
              [-39.935288050774609, -8.212038592846339],
              [-39.935288095675119, -8.212038521236112],
              [-39.935288139902667, -8.212038449211388],
              [-39.935288183453387, -8.212038376778452],
              [-39.935288226323486, -8.212038303943629],
              [-39.935288268509218, -8.21203823071326],
              [-39.935288310006939, -8.212038157093691],
              [-39.935288350812996, -8.212038083091374],
              [-39.935288390923844, -8.212038008712781],
              [-39.935288430336001, -8.212037933964348],
              [-39.935288469046014, -8.212037858852606],
              [-39.935288507050522, -8.212037783384098],
              [-39.935288544346207, -8.212037707565436],
              [-39.935288580929807, -8.212037631403174],
              [-39.935288616798161, -8.212037554903988],
              [-39.93528865194812, -8.212037478074503],
              [-39.935288686376623, -8.212037400921487],
              [-39.935288720080692, -8.212037323451584],
              [-39.93528875305735, -8.212037245671601],
              [-39.935288785303761, -8.212037167588306],
              [-39.935288816817099, -8.212037089208486],
              [-39.935288847594599, -8.212037010538971],
              [-39.93528887763361, -8.212036931586667],
              [-39.935288906931504, -8.212036852358379],
              [-39.93528893548573, -8.212036772861074],
              [-39.935288963293786, -8.212036693101643],
              [-39.935288990353257, -8.212036613087061],
              [-39.935289016661791, -8.212036532824293],
              [-39.935289042217079, -8.212036452320314],
              [-39.935289067016896, -8.212036371582176],
              [-39.93528909105909, -8.212036290616908],
              [-39.935289114341565, -8.21203620943154],
              [-39.935289136862288, -8.212036128033184],
              [-39.935289180428114, -8.212036154125061],
              [-39.935624719662556, -8.21080073264598],
              [-39.935624899105754, -8.210799827795727],
              [-39.935959405283448, -8.208774928777689],
              [-39.935959386738027, -8.208774956090608],
              [-39.935959398358257, -8.208774887394101],
              [-39.93595941050927, -8.208774818788779],
              [-39.935959423190326, -8.208774750278755],
              [-39.935959436400672, -8.20877468186807],
              [-39.935959450139535, -8.208774613560809],
              [-39.935959464406103, -8.208774545360995],
              [-39.935959479199518, -8.208774477272678],
              [-39.935959494518883, -8.208774409299917],
              [-39.935959510363325, -8.208774341446739],
              [-39.935959526731885, -8.208774273717141],
              [-39.935959543623611, -8.208774206115185],
              [-39.935959561037485, -8.208774138644849],
              [-39.935959578972472, -8.208774071310165],
              [-39.935959597427498, -8.208774004115105],
              [-39.935959616401483, -8.208773937063663],
              [-39.935959635893326, -8.208773870159822],
              [-39.935959655901819, -8.208773803407546],
              [-39.935959676425796, -8.208773736810794],
              [-39.93595969746405, -8.208773670373505],
              [-39.935959719015329, -8.208773604099635],
              [-39.935959741078356, -8.208773537993117],
              [-39.935959763651809, -8.20877347205789],
              [-39.935959786734351, -8.208773406297825],
              [-39.93595981032464, -8.208773340716853],
              [-39.935959834421233, -8.208773275318869],
              [-39.935959859022731, -8.208773210107731],
              [-39.935959884127669, -8.208773145087322],
              [-39.935959909734549, -8.208773080261498],
              [-39.935959935841844, -8.20877301563411],
              [-39.935959962448038, -8.20877295120898],
              [-39.935959989551534, -8.208772886989937],
              [-39.935960017150713, -8.208772822980794],
              [-39.935960045243945, -8.208772759185358],
              [-39.93596007382957, -8.208772695607378],
              [-39.935960102905881, -8.20877263225066],
              [-39.935960132471173, -8.208772569118972],
              [-39.935960162523656, -8.208772506216032],
              [-39.935960193061582, -8.208772443545575],
              [-39.935960224083125, -8.208772381111322],
              [-39.935960255586437, -8.208772318917005],
              [-39.935960287569657, -8.208772256966284],
              [-39.935960320030901, -8.208772195262831],
              [-39.935960352968223, -8.208772133810328],
              [-39.935960386379669, -8.208772072612383],
              [-39.935960420263278, -8.208772011672671],
              [-39.935960454616996, -8.208771950994771],
              [-39.935960489438841, -8.208771890582328],
              [-39.935960524726703, -8.208771830438895],
              [-39.935960560478527, -8.208771770568038],
              [-39.935960596692141, -8.20877171097333],
              [-39.935960633365447, -8.20877165165828],
              [-39.935960670496229, -8.208771592626416],
              [-39.935960708082312, -8.208771533881261],
              [-39.935960746121452, -8.208771475426275],
              [-39.935960784611403, -8.208771417264954],
              [-39.93596082354987, -8.208771359400702],
              [-39.93596086293455, -8.208771301837011],
              [-39.935960902763092, -8.208771244577239],
              [-39.935960943033159, -8.20877118762483],
              [-39.935960983742348, -8.208771130983129],
              [-39.935961024888222, -8.208771074655537],
              [-39.935961066468373, -8.208771018645347],
              [-39.935961108480335, -8.208770962955903],
              [-39.935961150921585, -8.208770907590548],
              [-39.935961193789623, -8.20877085255248],
              [-39.935961237081905, -8.208770797845043],
              [-39.935961280795865, -8.208770743471419],
              [-39.935961324928904, -8.208770689434912],
              [-39.935961369478413, -8.20877063573867],
              [-39.935961414441742, -8.208770582385906],
              [-39.935961459816212, -8.208770529379763],
              [-39.93596150559916, -8.208770476723412],
              [-39.935961551787827, -8.208770424419958],
              [-39.935961598379521, -8.208770372472486],
              [-39.935961645371435, -8.208770320884108],
              [-39.935961692760806, -8.208770269657878],
              [-39.935961740544819, -8.208770218796838],
              [-39.935961788720633, -8.208770168304019],
              [-39.935961837285397, -8.208770118182386],
              [-39.935961886236214, -8.208770068434919],
              [-39.935961935570212, -8.208770019064575],
              [-39.935961985284422, -8.208769970074309],
              [-39.935962035375923, -8.208769921466997],
              [-39.935962085841737, -8.208769873245505],
              [-39.93596213667886, -8.208769825412734],
              [-39.935962187884293, -8.208769777971511],
              [-39.93596223945498, -8.208769730924638],
              [-39.935962291387888, -8.208769684274918],
              [-39.935962343679911, -8.208769638025116],
              [-39.935962396327952, -8.208769592177992],
              [-39.935962449328876, -8.20876954673623],
              [-39.935962502679565, -8.208769501702545],
              [-39.935962556376836, -8.208769457079597],
              [-39.935962610417505, -8.208769412870067],
              [-39.935962664798375, -8.20876936907656],
              [-39.935962719516205, -8.208769325701658],
              [-39.935962774567756, -8.208769282747944],
              [-39.935962829949759, -8.208769240217975],
              [-39.935962885658846, -8.208769198114485],
              [-39.935962841573478, -8.20876922286249],
              [-39.937133845578742, -8.207891280082482],
              [-39.927532496919575, -8.20781754273672],
              [-39.937973313635013, -8.200507886686033],
              [-39.940153240753204, -8.188007887801509],
              [-39.940760829332966, -8.187749432702992],
              [-39.939421275904401, -8.187486195944848],
              [-39.939421281644627, -8.187486167259838],
              [-39.939421228821423, -8.187486156715536],
              [-39.939421176061948, -8.187486145859074],
              [-39.939421123368078, -8.187486134690836],
              [-39.93942107074168, -8.18748612321119],
              [-39.939421018184554, -8.187486111420585],
              [-39.939420965698602, -8.18748609931942],
              [-39.939420913285645, -8.187486086908113],
              [-39.939420860947564, -8.187486074187095],
              [-39.939420808686144, -8.187486061156807],
              [-39.939420756503274, -8.187486047817762],
              [-39.939420704400781, -8.187486034170373],
              [-39.939420652380505, -8.187486020215166],
              [-39.939420600444251, -8.187486005952588],
              [-39.939420548593887, -8.187485991383154],
              [-39.939420496831204, -8.187485976507386],
              [-39.939420445158042, -8.187485961325818],
              [-39.939420393576228, -8.187485945838963],
              [-39.939420342087551, -8.187485930047366],
              [-39.93942029069386, -8.187485913951608],
              [-39.939420239396952, -8.187485897552236],
              [-39.939420188198625, -8.187485880849826],
              [-39.939420137100683, -8.187485863844987],
              [-39.939420086104938, -8.187485846538298],
              [-39.939420035213182, -8.187485828930388],
              [-39.939419984427197, -8.187485811021849],
              [-39.939419933748773, -8.187485792813291],
              [-39.93941988317971, -8.18748577430541],
              [-39.939419832721775, -8.187485755498853],
              [-39.93941978237676, -8.187485736394276],
              [-39.939419732146426, -8.187485716992329],
              [-39.939419682032543, -8.18748569729372],
              [-39.939419632036874, -8.187485677299129],
              [-39.939419582161186, -8.187485657009265],
              [-39.939419532407229, -8.187485636424816],
              [-39.939419482776763, -8.187485615546562],
              [-39.939419433271546, -8.187485594375195],
              [-39.939419383893295, -8.187485572911482],
              [-39.939419334643766, -8.187485551156167],
              [-39.939419285524679, -8.187485529110033],
              [-39.939419236537788, -8.187485506773831],
              [-39.939419187684805, -8.187485484148358],
              [-39.939419138967452, -8.187485461234409],
              [-39.939419090387439, -8.187485438032812],
              [-39.9394190419465, -8.187485414544351],
              [-39.939418993646306, -8.187485390769902],
              [-39.939418945488583, -8.187485366710231],
              [-39.939418897475015, -8.187485342366246],
              [-39.939418849607293, -8.187485317738769],
              [-39.939418801887122, -8.187485292828724],
              [-39.939418754316165, -8.187485267636914],
              [-39.939418706896085, -8.187485242164247],
              [-39.939418659628593, -8.187485216411639],
              [-39.939418612515318, -8.187485190380002],
              [-39.939418565557929, -8.187485164070226],
              [-39.939418518758089, -8.187485137483261],
              [-39.939418472117424, -8.187485110620068],
              [-39.939418425637612, -8.187485083481501],
              [-39.939418379320266, -8.187485056068569],
              [-39.939418333167019, -8.187485028382277],
              [-39.939418287179478, -8.187485000423569],
              [-39.939418241359313, -8.187484972193426],
              [-39.9394181957081, -8.187484943692846],
              [-39.939418150227453, -8.187484914922829],
              [-39.939418104918964, -8.187484885884375],
              [-39.939418059784273, -8.187484856578532],
              [-39.939418014824909, -8.187484827006315],
              [-39.939417970042491, -8.187484797168796],
              [-39.939417925438612, -8.187484767066977],
              [-39.939417881014784, -8.187484736701936],
              [-39.939417836772627, -8.18748470607477],
              [-39.939417792713691, -8.187484675186527],
              [-39.939417748839503, -8.187484644038305],
              [-39.939417705151627, -8.187484612631209],
              [-39.939417661651596, -8.18748458096635],
              [-39.939417618340947, -8.1874845490448],
              [-39.939417575221199, -8.187484516867752],
              [-39.939417532293866, -8.187484484436267],
              [-39.939417489560483, -8.187484451751551],
              [-39.939417447022542, -8.18748441881471],
              [-39.939417404681521, -8.187484385626925],
              [-39.939417362538933, -8.187484352189355],
              [-39.939417320596284, -8.187484318503202],
              [-39.939417278854989, -8.187484284569615],
              [-39.939417237316576, -8.187484250389803],
              [-39.939417195982493, -8.187484215964997],
              [-39.939417154854176, -8.187484181296375],
              [-39.939417113933096, -8.187484146385161],
              [-39.939417073220689, -8.187484111232607],
              [-39.939417032718389, -8.187484075839931],
              [-39.939416992427617, -8.18748404020838],
              [-39.939416952349767, -8.187484004339224],
              [-39.939416912486323, -8.187483968233723],
              [-39.939416872838635, -8.18748393189313],
              [-39.939416833408089, -8.187483895318731],
              [-39.939416794196099, -8.187483858511836],
              [-39.939416755204043, -8.187483821473728],
              [-39.9394167164333, -8.187483784205734],
              [-39.939416677885212, -8.187483746709102],
              [-39.93941663956096, -8.187483708985921],
              [-39.939416640453672, -8.187483674316047],
              [-39.938397867609694, -8.186474886435073],
              [-39.938397877511477, -8.186474927695691],
              [-39.938397861560695, -8.186474911940161],
              [-39.93839784557084, -8.186474896224004],
              [-39.938397829542019, -8.186474880547289],
              [-39.938397813474317, -8.186474864910153],
              [-39.93839779736782, -8.18647484931266],
              [-39.938397781222633, -8.18647483375497],
              [-39.938397765038879, -8.186474818237109],
              [-39.938397748816627, -8.186474802759177],
              [-39.938397732555977, -8.186474787321293],
              [-39.93839771625705, -8.186474771923539],
              [-39.938397699919925, -8.186474756566041],
              [-39.938397683544707, -8.186474741248841],
              [-39.938397667131518, -8.186474725972058],
              [-39.938397650680436, -8.186474710735784],
              [-39.93839763419156, -8.186474695540106],
              [-39.938397617664982, -8.186474680385134],
              [-39.938397601100846, -8.186474665270968],
              [-39.938397584499214, -8.186474650197656],
              [-39.9383975678602, -8.186474635165315],
              [-39.938397551183904, -8.186474620174053],
              [-39.93839753447044, -8.186474605223962],
              [-39.938397517719892, -8.186474590315095],
              [-39.938397500932361, -8.18647457544759],
              [-39.938397484107973, -8.186474560621527],
              [-39.938397467246801, -8.186474545836958],
              [-39.938397450348987, -8.186474531094017],
              [-39.9383974334146, -8.186474516392771],
              [-39.938397416443749, -8.186474501733338],
              [-39.938397399436553, -8.186474487115767],
              [-39.938397382393106, -8.186474472540185],
              [-39.93839736531352, -8.186474458006661],
              [-39.938397348197881, -8.186474443515285],
              [-39.938397331046311, -8.186474429066163],
              [-39.938397313858921, -8.186474414659338],
              [-39.938397296635785, -8.18647440029495],
              [-39.938397279377043, -8.18647438597306],
              [-39.938397262082788, -8.186474371693741],
              [-39.938397244753119, -8.186474357457127],
              [-39.938397227388158, -8.186474343263265],
              [-39.938397209987983, -8.186474329112276],
              [-39.938397192552735, -8.18647431500421],
              [-39.938397175082486, -8.186474300939196],
              [-39.93839715757737, -8.186474286917257],
              [-39.938397140037488, -8.18647427293851],
              [-39.938397122462945, -8.186474259003084],
              [-39.938397104853848, -8.186474245111006],
              [-39.938397087210298, -8.186474231262396],
              [-39.938397069532414, -8.186474217457302],
              [-39.93839705182031, -8.186474203695852],
              [-39.938397034074072, -8.186474189978108],
              [-39.938397016293827, -8.186474176304145],
              [-39.938396998479668, -8.186474162674065],
              [-39.93839698063173, -8.186474149087946],
              [-39.938396962750097, -8.186474135545859],
              [-39.938396944834906, -8.186474122047914],
              [-39.93839692688622, -8.186474108594172],
              [-39.93839690890421, -8.186474095184726],
              [-39.938396890888932, -8.186474081819636],
              [-39.938396872840514, -8.186474068499008],
              [-39.938396854759084, -8.186474055222915],
              [-39.938396836644728, -8.186474041991421],
              [-39.938396818497573, -8.186474028804646],
              [-39.938396800317726, -8.186474015662661],
              [-39.938396782105293, -8.186474002565491],
              [-39.938396763860403, -8.186473989513313],
              [-39.938396745583155, -8.186473976506122],
              [-39.938396727273656, -8.186473963544035],
              [-39.938396708932011, -8.18647395062712],
              [-39.938396690558349, -8.186473937755464],
              [-39.938396672152784, -8.186473924929142],
              [-39.938396653715422, -8.186473912148248],
              [-39.938396635246377, -8.18647389941283],
              [-39.938396616745756, -8.186473886723],
              [-39.938396598213686, -8.186473874078818],
              [-39.938396579650259, -8.18647386148036],
              [-39.938396561055605, -8.186473848927694],
              [-39.938396542429835, -8.186473836420905],
              [-39.938396523773072, -8.186473823960096],
              [-39.938396505085414, -8.186473811545298],
              [-39.938396486366976, -8.186473799176614],
              [-39.938396467617885, -8.186473786854123],
              [-39.938396448838255, -8.186473774577896],
              [-39.938396430028185, -8.186473762347985],
              [-39.938396411187796, -8.18647375016449],
              [-39.938396392317216, -8.186473738027493],
              [-39.938396373416559, -8.186473725937027],
              [-39.938396354485931, -8.186473713893228],
              [-39.938396335525447, -8.186473701896078],
              [-39.938396316535233, -8.186473689945764],
              [-39.938396297515382, -8.18647367804228],
              [-39.938396278466051, -8.186473666185734],
              [-39.938396259387325, -8.186473654376192],
              [-39.938396240279332, -8.186473642613734],
              [-39.938396221142177, -8.186473630898378],
              [-39.938396201975991, -8.186473619230258],
              [-39.938396182780899, -8.186473607609413],
              [-39.938396163556988, -8.186473596035915],
              [-39.938396144304399, -8.186473584509862],
              [-39.938396125023253, -8.186473573031316],
              [-39.938396139716374, -8.186473533799754],
              [-39.936701468409737, -8.185467486641643],
              [-39.936701464066132, -8.185467525666926],
              [-39.936701368121, -8.185467469499148],
              [-39.936701271492012, -8.185467414507483],
              [-39.936701174193686, -8.185467360700187],
              [-39.936701076240631, -8.185467308085341],
              [-39.936700977647583, -8.185467256670849],
              [-39.936700878429349, -8.185467206464446],
              [-39.936700778600851, -8.185467157473651],
              [-39.936700678177075, -8.185467109705856],
              [-39.936700577173127, -8.18546706316822],
              [-39.936700475604184, -8.185467017867765],
              [-39.936700373485507, -8.185466973811266],
              [-39.936700270832453, -8.185466931005369],
              [-39.936700167660426, -8.185466889456483],
              [-39.936700063984958, -8.185466849170856],
              [-39.936699959821603, -8.185466810154553],
              [-39.936699855186028, -8.185466772413472],
              [-39.936699750093972, -8.185466735953209],
              [-39.936699644561209, -8.18546670077928],
              [-39.936699538603605, -8.185466666897],
              [-39.936699432237077, -8.185466634311451],
              [-39.936699325477619, -8.1854666030275],
              [-39.936699218341296, -8.185466573049851],
              [-39.936699110844167, -8.185466544383024],
              [-39.93669900300241, -8.185466517031315],
              [-39.936698894832219, -8.185466490998865],
              [-39.936698786349872, -8.185466466289537],
              [-39.936698677571663, -8.185466442907106],
              [-39.936698568513918, -8.185466420855038],
              [-39.936698459193074, -8.185466400136663],
              [-39.936698349625509, -8.185466380755098],
              [-39.936698239827713, -8.185466362713234],
              [-39.936698129816179, -8.185466346013806],
              [-39.936698019607469, -8.185466330659338],
              [-39.936697909218111, -8.185466316652109],
              [-39.936697798664696, -8.185466303994209],
              [-39.936697687963864, -8.185466292687595],
              [-39.936697577132236, -8.185466282733907],
              [-39.936697466186452, -8.185466274134679],
              [-39.936697355143217, -8.185466266891197],
              [-39.9366972440192, -8.185466261004541],
              [-39.936697132831107, -8.185466256475609],
              [-39.936697021595641, -8.185466253305055],
              [-39.936696910329538, -8.185466251493384],
              [-39.936696799049493, -8.185466251040852],
              [-39.93669668777224, -8.185466251947529],
              [-39.936696576514514, -8.185466254213306],
              [-39.936696465293018, -8.185466257837808],
              [-39.936696354124479, -8.185466262820485],
              [-39.936696243025594, -8.185466269160617],
              [-39.936696132013061, -8.185466276857232],
              [-39.936696021103579, -8.185466285909165],
              [-39.936695910313794, -8.185466296315081],
              [-39.93669579966037, -8.185466308073407],
              [-39.936695689159926, -8.185466321182368],
              [-39.936695578829081, -8.185466335640008],
              [-39.936695468684405, -8.185466351444148],
              [-39.936695358742462, -8.18546636859241],
              [-39.936695249019763, -8.18546638708222],
              [-39.936695139532802, -8.185466406910805],
              [-39.93669503029804, -8.185466428075156],
              [-39.936694921331878, -8.185466450572118],
              [-39.9366948126507, -8.185466474398302],
              [-39.936694704270856, -8.185466499550154],
              [-39.936694596208596, -8.185466526023854],
              [-39.936694488480192, -8.185466553815445],
              [-39.936694381101823, -8.185466582920737],
              [-39.936694274089618, -8.185466613335391],
              [-39.936694167459677, -8.185466645054781],
              [-39.936694061228025, -8.18546667807419],
              [-39.936693955410604, -8.185466712388608],
              [-39.936693850023332, -8.185466747992932],
              [-39.936693745082046, -8.185466784881763],
              [-39.93669364060252, -8.185466823049589],
              [-39.936693536600437, -8.185466862490651],
              [-39.936693433091456, -8.185466903199046],
              [-39.93669333009111, -8.18546694516866],
              [-39.936693227614896, -8.18546698839315],
              [-39.93669312567819, -8.185467032866054],
              [-39.936693024296339, -8.185467078580675],
              [-39.936692923484564, -8.185467125530122],
              [-39.936692823258021, -8.185467173707377],
              [-39.936692723631765, -8.185467223105169],
              [-39.936692624620768, -8.1854672737161],
              [-39.936692526239923, -8.185467325532544],
              [-39.93669242850401, -8.185467378546733],
              [-39.936692331427707, -8.185467432750661],
              [-39.93669223502561, -8.185467488136227],
              [-39.936692139312193, -8.185467544695094],
              [-39.936692044301864, -8.185467602418752],
              [-39.9366919500089, -8.185467661298546],
              [-39.936691856447453, -8.185467721325594],
              [-39.9366917636316, -8.185467782490926],
              [-39.936691671575268, -8.185467844785284],
              [-39.936691580292319, -8.185467908199346],
              [-39.936691489796459, -8.185467972723556],
              [-39.936691400101274, -8.185468038348279],
              [-39.936691311220272, -8.185468105063588],
              [-39.936691223166811, -8.185468172859485],
              [-39.936691135954085, -8.185468241725772],
              [-39.936691121815009, -8.185468234185311],
              [-39.935004038739166, -8.186817308882301],
              [-39.935004037576306, -8.186817284806153],
              [-39.935003973678135, -8.186817336376155],
              [-39.93500391025146, -8.18681738852078],
              [-39.935003847301481, -8.1868174412357],
              [-39.935003784833363, -8.186817494516626],
              [-39.935003722852244, -8.186817548359194],
              [-39.935003661363197, -8.186817602758943],
              [-39.935003600371267, -8.186817657711448],
              [-39.935003539881471, -8.186817713212196],
              [-39.935003479898768, -8.186817769256619],
              [-39.935003420428082, -8.186817825840132],
              [-39.93500336147428, -8.186817882958064],
              [-39.935003303042222, -8.18681794060573],
              [-39.935003245136684, -8.186817998778459],
              [-39.935003187762426, -8.18681805747142],
              [-39.935003130924159, -8.186818116679797],
              [-39.935003074626536, -8.186818176398747],
              [-39.935003018874198, -8.186818236623381],
              [-39.935002963671678, -8.186818297348722],
              [-39.935002909023567, -8.186818358569802],
              [-39.935002854934297, -8.186818420281616],
              [-39.935002801408338, -8.186818482479085],
              [-39.935002748450067, -8.186818545157115],
              [-39.93500269606384, -8.186818608310517],
              [-39.935002644253942, -8.186818671934184],
              [-39.935002593024642, -8.186818736022843],
              [-39.935002542380118, -8.186818800571256],
              [-39.93500249232455, -8.18681886557413],
              [-39.93500244286205, -8.186818931026103],
              [-39.935002393996662, -8.186818996921819],
              [-39.9350023457324, -8.186819063255871],
              [-39.935002298073215, -8.186819130022801],
              [-39.935002251023029, -8.186819197217162],
              [-39.935002204585714, -8.186819264833405],
              [-39.935002158765052, -8.186819332866001],
              [-39.935002113564828, -8.186819401309382],
              [-39.935002068988723, -8.186819470157902],
              [-39.935002025040426, -8.18681953940591],
              [-39.935001981723545, -8.186819609047754],
              [-39.935001939041605, -8.186819679077685],
              [-39.935001896998145, -8.186819749489997],
              [-39.935001855596575, -8.18681982027887],
              [-39.935001814840312, -8.186819891438518],
              [-39.935001774732704, -8.18681996296309],
              [-39.935001735277048, -8.186820034846736],
              [-39.935001696476576, -8.18682010708353],
              [-39.935001658334457, -8.186820179667544],
              [-39.935001620853832, -8.186820252592875],
              [-39.935001584037792, -8.186820325853471],
              [-39.935001547889328, -8.186820399443368],
              [-39.935001512411432, -8.186820473356496],
              [-39.93500147760701, -8.186820547586809],
              [-39.935001443478903, -8.186820622128211],
              [-39.935001410029926, -8.186820696974573],
              [-39.935001377262822, -8.186820772119759],
              [-39.935001345180275, -8.186820847557586],
              [-39.935001313784937, -8.186820923281926],
              [-39.935001283079352, -8.186820999286491],
              [-39.935001253066076, -8.186821075565074],
              [-39.935001223747534, -8.186821152111408],
              [-39.935001195126155, -8.186821228919232],
              [-39.935001167204291, -8.186821305982225],
              [-39.935001139984209, -8.186821383294077],
              [-39.935001113468168, -8.186821460848424],
              [-39.935001087658328, -8.186821538638922],
              [-39.935001062556829, -8.186821616659161],
              [-39.935001038165694, -8.186821694902759],
              [-39.935001014486971, -8.186821773363283],
              [-39.935000991522578, -8.186821852034317],
              [-39.93500096927437, -8.186821930909391],
              [-39.935000947744228, -8.186822009982009],
              [-39.935000926933895, -8.186822089245714],
              [-39.935000906845069, -8.186822168694],
              [-39.935000887479404, -8.186822248320363],
              [-39.9350008688385, -8.186822328118231],
              [-39.935000850923878, -8.18682240808106],
              [-39.935000833737007, -8.186822488202292],
              [-39.935000817279295, -8.186822568475375],
              [-39.9350008015521, -8.186822648893701],
              [-39.935000786556714, -8.186822729450661],
              [-39.935000772294366, -8.186822810139667],
              [-39.935000758766215, -8.186822890954081],
              [-39.935000745973383, -8.186822971887276],
              [-39.935000733916915, -8.186823052932629],
              [-39.935000722597799, -8.186823134083467],
              [-39.935000712016965, -8.186823215333153],
              [-39.935000702175287, -8.186823296674996],
              [-39.935000693073569, -8.186823378102304],
              [-39.935000684712563, -8.186823459608457],
              [-39.935000677092937, -8.186823541186712],
              [-39.935000670215352, -8.186823622830385],
              [-39.93500066408032, -8.186823704532797],
              [-39.935000658688395, -8.18682378628723],
              [-39.935000654039989, -8.18682386808694],
              [-39.9350006501355, -8.186823949925273],
              [-39.935000646975233, -8.186824031795496],
              [-39.935000644559459, -8.186824113690847],
              [-39.935000642888362, -8.186824195604672],
              [-39.93500064196212, -8.186824277530214],
              [-39.935000641780746, -8.186824359460754],
              [-39.935000605751874, -8.186824371420258],
              [-39.935005298580727, -8.188836094111128],
              [-39.935005334581611, -8.188836070072666],
              [-39.935005333442412, -8.188836235912461],
              [-39.935005329251396, -8.188836401703567],
              [-39.935005322009964, -8.188836567390227],
              [-39.935005311720559, -8.188836732916753],
              [-39.935005298386628, -8.188836898227466],
              [-39.935005282012675, -8.188837063266785],
              [-39.935005262604193, -8.188837227979203],
              [-39.935005240167705, -8.188837392309384],
              [-39.93500521471077, -8.188837556202037],
              [-39.935005186241945, -8.188837719602072],
              [-39.935005154770778, -8.188837882454528],
              [-39.935005120307856, -8.188838044704683],
              [-39.935005082864798, -8.188838206297961],
              [-39.935005042454165, -8.188838367180017],
              [-39.935004999089564, -8.188838527296785],
              [-39.935004952785555, -8.188838686594384],
              [-39.935004903557733, -8.188838845019307],
              [-39.935004851422605, -8.188839002518268],
              [-39.935004796397749, -8.188839159038272],
              [-39.935004738501647, -8.188839314526742],
              [-39.93500467775376, -8.188839468931338],
              [-39.935004614174524, -8.18883962220022],
              [-39.935004547785319, -8.188839774281796],
              [-39.935004478608455, -8.188839925124956],
              [-39.935004406667197, -8.188840074678964],
              [-39.935004331985738, -8.188840222893537],
              [-39.935004254589188, -8.188840369718845],
              [-39.935004174503568, -8.18884051510552],
              [-39.9350040917558, -8.188840659004699],
              [-39.935004006373724, -8.188840801367968],
              [-39.93500391838603, -8.188840942147447],
              [-39.935003827822314, -8.188841081295834],
              [-39.935003734713028, -8.188841218766312],
              [-39.935003639089473, -8.188841354512727],
              [-39.935003540983793, -8.188841488489341],
              [-39.935003440429, -8.188841620651155],
              [-39.935003337458873, -8.188841750953729],
              [-39.935003232108052, -8.188841879353244],
              [-39.935003124411978, -8.188842005806512],
              [-39.935003014406817, -8.188842130271073],
              [-39.935002902129604, -8.188842252705022],
              [-39.935002787618053, -8.188842373067192],
              [-39.935002670910698, -8.188842491317148],
              [-39.935002552046768, -8.188842607415095],
              [-39.935002431066209, -8.188842721322025],
              [-39.935002308009736, -8.188842832999624],
              [-39.935002182918694, -8.188842942410332],
              [-39.93500205583517, -8.188843049517395],
              [-39.935001926801853, -8.188843154284767],
              [-39.935001795862171, -8.188843256677233],
              [-39.935001663060142, -8.188843356660367],
              [-39.935001528440417, -8.188843454200532],
              [-39.935001392048235, -8.18884354926495],
              [-39.935001253929492, -8.188843641821666],
              [-39.935001114130614, -8.188843731839535],
              [-39.935000972698603, -8.188843819288298],
              [-39.935000829681009, -8.188843904138547],
              [-39.935000685125942, -8.1888439863618],
              [-39.935000539081983, -8.18884406593032],
              [-39.935000391598251, -8.188844142817425],
              [-39.935000242724328, -8.188844216997227],
              [-39.935000092510286, -8.188844288444823],
              [-39.934999941006609, -8.188844357136132],
              [-39.93499978826425, -8.188844423048099],
              [-39.934999634334574, -8.188844486158546],
              [-39.934999479269329, -8.188844546446269],
              [-39.934999323120657, -8.18884460389099],
              [-39.934999165941065, -8.188844658473375],
              [-39.934999007783389, -8.188844710175099],
              [-39.934998848700815, -8.188844758978783],
              [-39.934998688746838, -8.188844804867985],
              [-39.934998527975232, -8.188844847827291],
              [-39.93499836644007, -8.188844887842244],
              [-39.934998204195644, -8.18884492489943],
              [-39.934998041296524, -8.188844958986353],
              [-39.934997877797464, -8.18884499009155],
              [-39.934997713753461, -8.188845018204583],
              [-39.934997549219666, -8.188845043315993],
              [-39.93499738425141, -8.188845065417313],
              [-39.934997218904115, -8.18884508450115],
              [-39.934997053233431, -8.18884510056105],
              [-39.934996887295043, -8.188845113591633],
              [-39.934996721144742, -8.188845123588516],
              [-39.934996554838392, -8.18884513054835],
              [-39.934996388431927, -8.188845134468762],
              [-39.934996221981287, -8.188845135348464],
              [-39.934996055542435, -8.188845133187137],
              [-39.934995889171354, -8.18884512798553],
              [-39.934995722923951, -8.188845119745384],
              [-39.934995556856151, -8.188845108469465],
              [-39.93499539102379, -8.188845094161573],
              [-39.934995225482616, -8.188845076826512],
              [-39.934995060288308, -8.188845056470113],
              [-39.934994895496381, -8.188845033099225],
              [-39.934994731162277, -8.18884500672169],
              [-39.934994567341242, -8.188844977346392],
              [-39.934994404088329, -8.188844944983209],
              [-39.934994241458462, -8.188844909643016],
              [-39.934994079506687, -8.188844871337524],
              [-39.934994062918662, -8.188844893146493],
              [-39.933648902616106, -8.188513727162757],
              [-39.933648923510603, -8.188513700133285],
              [-39.933648860470605, -8.188513684850957],
              [-39.93364879732259, -8.188513670017956],
              [-39.933648734069784, -8.188513655635008],
              [-39.933648670715414, -8.188513641702833],
              [-39.933648607262732, -8.188513628222168],
              [-39.933648543714966, -8.18851361519371],
              [-39.933648480075362, -8.188513602618075],
              [-39.933648416347175, -8.188513590495962],
              [-39.933648352533645, -8.188513578827953],
              [-39.933648288638061, -8.188513567614688],
              [-39.933648224663656, -8.188513556856686],
              [-39.933648160613728, -8.188513546554518],
              [-39.933648096491538, -8.188513536708719],
              [-39.933648032300354, -8.188513527319802],
              [-39.933647968043445, -8.188513518388227],
              [-39.933647903724115, -8.188513509914445],
              [-39.933647839345639, -8.188513501898916],
              [-39.933647774911321, -8.188513494342027],
              [-39.933647710424431, -8.188513487244171],
              [-39.933647645888271, -8.188513480605691],
              [-39.933647581306147, -8.188513474426971],
              [-39.933647516681326, -8.188513468708273],
              [-39.933647452017155, -8.188513463449924],
              [-39.933647387316924, -8.188513458652173],
              [-39.933647322583916, -8.188513454315308],
              [-39.933647257821448, -8.188513450439478],
              [-39.933647193032833, -8.188513447024947],
              [-39.933647128221381, -8.188513444071853],
              [-39.933647063390389, -8.188513441580364],
              [-39.933646998543203, -8.188513439550603],
              [-39.933646933683093, -8.188513437982662],
              [-39.933646868813405, -8.188513436876635],
              [-39.933646803937435, -8.188513436232583],
              [-39.933646739058503, -8.188513436050519],
              [-39.933646674179911, -8.188513436330458],
              [-39.933646609305001, -8.188513437072409],
              [-39.93364654443706, -8.188513438276315],
              [-39.933646479579423, -8.188513439942108],
              [-39.933646414735385, -8.188513442069713],
              [-39.933646349908251, -8.188513444659019],
              [-39.933646285101368, -8.188513447709884],
              [-39.933646220318018, -8.188513451222184],
              [-39.933646155561526, -8.188513455195695],
              [-39.933646090835175, -8.188513459630247],
              [-39.933646026142299, -8.188513464525576],
              [-39.933645961486192, -8.188513469881482],
              [-39.933645896870161, -8.18851347569767],
              [-39.933645832297486, -8.188513481973834],
              [-39.933645767771502, -8.188513488709679],
              [-39.933645703295468, -8.188513495904818],
              [-39.933645638872697, -8.188513503558916],
              [-39.933645574506485, -8.18851351167158],
              [-39.933645510200108, -8.188513520242374],
              [-39.933645445956856, -8.188513529270905],
              [-39.933645381780011, -8.188513538756677],
              [-39.933645317672848, -8.188513548699213],
              [-39.933645253638659, -8.188513559097997],
              [-39.933645189680682, -8.188513569952484],
              [-39.933645125802208, -8.188513581262145],
              [-39.933645062006491, -8.188513593026395],
              [-39.933644998296792, -8.18851360524466],
              [-39.933644934676373, -8.188513617916289],
              [-39.933644871148473, -8.188513631040614],
              [-39.933644807716355, -8.188513644616995],
              [-39.933644744383244, -8.188513658644732],
              [-39.933644681152373, -8.188513673123104],
              [-39.933644618026975, -8.188513688051371],
              [-39.933644555010275, -8.188513703428793],
              [-39.933644492105493, -8.188513719254543],
              [-39.933644429315834, -8.188513735527829],
              [-39.933644366644529, -8.188513752247848],
              [-39.933644304094763, -8.188513769413712],
              [-39.933644241669739, -8.188513787024565],
              [-39.933644179372621, -8.188513805079483],
              [-39.933644117206626, -8.188513823577559],
              [-39.933644055174895, -8.18851384251786],
              [-39.93364399328064, -8.188513861899418],
              [-39.93364393152698, -8.188513881721219],
              [-39.933643869917084, -8.188513901982244],
              [-39.9336438084541, -8.188513922681487],
              [-39.933643747141197, -8.188513943817886],
              [-39.933643685981458, -8.188513965390364],
              [-39.933643624978032, -8.188513987397794],
              [-39.933643564134037, -8.188514009839086],
              [-39.933643503452579, -8.188514032713035],
              [-39.933643442936749, -8.18851405601856],
              [-39.933643382589651, -8.18851407975442],
              [-39.933643322414355, -8.188514103919381],
              [-39.933643262413945, -8.18851412851224],
              [-39.933643202591483, -8.188514153531724],
              [-39.933643142950032, -8.188514178976599],
              [-39.933643083492619, -8.188514204845527],
              [-39.933643024222306, -8.188514231137182],
              [-39.933642965142084, -8.188514257850231],
              [-39.933642906255024, -8.188514284983301],
              [-39.933642847564087, -8.188514312535021],
              [-39.933642789072294, -8.188514340503971],
              [-39.933642730782623, -8.188514368888741],
              [-39.933642672698056, -8.188514397687843],
              [-39.933642640602152, -8.188514374748197],
              [-39.932296097712872, -8.1891880345872],
              [-39.932296097681125, -8.189188036961852],
              [-39.932295973075064, -8.189188100500335],
              [-39.932295849467145, -8.189188165945607],
              [-39.932295726886764, -8.18918823328212],
              [-39.932295605363095, -8.189188302493836],
              [-39.932295484925007, -8.189188373564331],
              [-39.932295365601163, -8.18918844647669],
              [-39.932295247419894, -8.18918852121355],
              [-39.932295130409337, -8.189188597757171],
              [-39.932295014597308, -8.189188676089366],
              [-39.932294900011335, -8.189188756191484],
              [-39.932294786678675, -8.189188838044481],
              [-39.93229467462627, -8.189188921628888],
              [-39.93229456388076, -8.189189006924844],
              [-39.932294454468483, -8.189189093912063],
              [-39.932294346415475, -8.189189182569859],
              [-39.932294239747371, -8.189189272877169],
              [-39.932294134489602, -8.189189364812501],
              [-39.932294030667144, -8.189189458353969],
              [-39.932293928304723, -8.189189553479396],
              [-39.932293827426662, -8.189189650166099],
              [-39.932293728056933, -8.189189748391115],
              [-39.932293630219185, -8.189189848131086],
              [-39.932293533936686, -8.189189949362316],
              [-39.932293439232303, -8.189190052060697],
              [-39.932293346128588, -8.189190156201848],
              [-39.932293254647647, -8.189190261760979],
              [-39.932293164811263, -8.189190368712993],
              [-39.932293076640775, -8.18919047703247],
              [-39.93229299015713, -8.189190586693668],
              [-39.932292905380947, -8.189190697670465],
              [-39.932292822332343, -8.189190809936498],
              [-39.932292741031077, -8.189190923465087],
              [-39.93229266149649, -8.189191038229222],
              [-39.932292583747468, -8.189191154201609],
              [-39.932292507802522, -8.189191271354684],
              [-39.932292433679692, -8.189191389660596],
              [-39.93229236139662, -8.189191509091216],
              [-39.932292290970494, -8.189191629618099],
              [-39.932292222418049, -8.18919175121264],
              [-39.932292155755576, -8.189191873845909],
              [-39.932292090998956, -8.189191997488757],
              [-39.932292028163552, -8.18919212211174],
              [-39.932291967264327, -8.189192247685286],
              [-39.932291908315769, -8.189192374179489],
              [-39.932291851331861, -8.189192501564309],
              [-39.93229179632619, -8.189192629809426],
              [-39.932291743311808, -8.189192758884387],
              [-39.932291692301341, -8.189192888758441],
              [-39.932291643306918, -8.189193019400749],
              [-39.932291596340164, -8.18919315078025],
              [-39.93229155141227, -8.189193282865658],
              [-39.932291508533908, -8.189193415625629],
              [-39.932291467715281, -8.189193549028547],
              [-39.932291428966103, -8.189193683042705],
              [-39.932291392295554, -8.189193817636268],
              [-39.93229135771238, -8.189193952777176],
              [-39.932291325224796, -8.189194088433329],
              [-39.932291294840546, -8.189194224572475],
              [-39.932291266566835, -8.189194361162238],
              [-39.932291240410386, -8.189194498170126],
              [-39.932291216377429, -8.189194635563574],
              [-39.932291194473663, -8.189194773309904],
              [-39.932291174704311, -8.189194911376369],
              [-39.932291157074054, -8.189195049730127],
              [-39.932291141587108, -8.189195188338322],
              [-39.932291128247144, -8.189195327167953],
              [-39.932291117057353, -8.189195466186034],
              [-39.932291108020365, -8.189195605359487],
              [-39.932291101138347, -8.189195744655253],
              [-39.932291096412925, -8.189195884040176],
              [-39.932291093845237, -8.189196023481099],
              [-39.932291093435879, -8.189196162944899],
              [-39.932291095184944, -8.189196302398429],
              [-39.932291099092033, -8.189196441808496],
              [-39.932291105156224, -8.189196581141966],
              [-39.932291113376039, -8.189196720365713],
              [-39.932291123749565, -8.189196859446591],
              [-39.932291136274308, -8.189196998351596],
              [-39.9322911509473, -8.189197137047667],
              [-39.932291167765044, -8.189197275501808],
              [-39.932291186723553, -8.189197413681143],
              [-39.932291207818309, -8.189197551552775],
              [-39.932291231044303, -8.189197689083914],
              [-39.932291256396013, -8.189197826241879],
              [-39.9322912838674, -8.189197962994053],
              [-39.932291313451948, -8.18919809930795],
              [-39.932291345142609, -8.189198235151103],
              [-39.932291378931851, -8.189198370491237],
              [-39.932291414811651, -8.189198505296151],
              [-39.932291452773462, -8.189198639533839],
              [-39.932291492808261, -8.18919877317237],
              [-39.932291534906525, -8.189198906179902],
              [-39.932291579058266, -8.189199038524878],
              [-39.932291625252944, -8.189199170175831],
              [-39.932291673479625, -8.189199301101427],
              [-39.932291723726799, -8.189199431270531],
              [-39.932291775982549, -8.189199560652204],
              [-39.932291830234426, -8.189199689215682],
              [-39.93229188646955, -8.189199816930383],
              [-39.932291858622136, -8.189199802032729],
              [-39.933654112085094, -8.192229856326591],
              [-39.933654477086399, -8.1922306694733],
              [-39.933994421530286, -8.193237061093329],
              [-39.933994383594175, -8.193237100287066],
              [-39.933994420789489, -8.193237208158001],
              [-39.933994459347211, -8.193237315553002],
              [-39.933994499261189, -8.193237422454979],
              [-39.933994540525106, -8.193237528846986],
              [-39.933994583132382, -8.193237634712062],
              [-39.933994627076224, -8.19323774003335],
              [-39.933994672349669, -8.193237844794165],
              [-39.933994718945513, -8.193237948977776],
              [-39.933994766856337, -8.193238052567695],
              [-39.933994816074538, -8.193238155547347],
              [-39.933994866592265, -8.193238257900418],
              [-39.933994918401481, -8.193238359610628],
              [-39.933994971493988, -8.193238460661805],
              [-39.933995025861293, -8.193238561037827],
              [-39.933995081494785, -8.193238660722798],
              [-39.933995138385583, -8.193238759700829],
              [-39.933995196524677, -8.19323885795616],
              [-39.933995255902786, -8.193238955473188],
              [-39.933995316510476, -8.193239052236386],
              [-39.933995378338132, -8.19323914823037],
              [-39.933995441375899, -8.193239243439898],
              [-39.933995505613744, -8.193239337849805],
              [-39.933995571041464, -8.193239431445116],
              [-39.933995637648636, -8.19323952421089],
              [-39.933995705424714, -8.193239616132386],
              [-39.933995774358863, -8.193239707195003],
              [-39.933995844440155, -8.193239797384262],
              [-39.93399591565742, -8.193239886685816],
              [-39.933995987999346, -8.193239975085426],
              [-39.933996061454422, -8.193240062569069],
              [-39.933996136010975, -8.193240149122838],
              [-39.933996211657139, -8.193240234732949],
              [-39.933996288380882, -8.193240319385765],
              [-39.933996366170021, -8.193240403067854],
              [-39.933996445012149, -8.193240485765887],
              [-39.933996524894752, -8.193240567466749],
              [-39.93399660580512, -8.193240648157404],
              [-39.933996687730385, -8.193240727825073],
              [-39.933996770657515, -8.193240806457],
              [-39.933996854573323, -8.193240884040783],
              [-39.933996939464471, -8.193240960564006],
              [-39.933997025317446, -8.193241036014507],
              [-39.933997112118597, -8.193241110380329],
              [-39.933997199854119, -8.193241183649619],
              [-39.933997288510056, -8.193241255810698],
              [-39.933997378072334, -8.193241326852126],
              [-39.933997468526663, -8.193241396762584],
              [-39.933997559858682, -8.193241465530965],
              [-39.933997652053868, -8.193241533146344],
              [-39.933997745097557, -8.193241599597945],
              [-39.93399783897496, -8.193241664875201],
              [-39.933997933671115, -8.193241728967742],
              [-39.933998029170986, -8.193241791865368],
              [-39.933998125459397, -8.193241853558053],
              [-39.933998222520998, -8.193241914036044],
              [-39.933998320340386, -8.193241973289643],
              [-39.933998418901972, -8.193242031309502],
              [-39.933998518190123, -8.193242088086356],
              [-39.933998618189001, -8.193242143611192],
              [-39.933998718882741, -8.193242197875161],
              [-39.933998820255297, -8.193242250869636],
              [-39.93399892229057, -8.193242302586212],
              [-39.933999024972337, -8.193242353016641],
              [-39.933999128284249, -8.193242402152888],
              [-39.933999232209857, -8.193242449987183],
              [-39.933999336732683, -8.193242496511873],
              [-39.933999441836058, -8.193242541719572],
              [-39.933999547503298, -8.193242585603105],
              [-39.933999653717592, -8.193242628155474],
              [-39.933999760462029, -8.193242669369941],
              [-39.933999867719642, -8.193242709239911],
              [-39.933999975473377, -8.193242747759069],
              [-39.934000083706096, -8.193242784921296],
              [-39.934000192400582, -8.193242820720663],
              [-39.934000301539548, -8.193242855151478],
              [-39.934000411105643, -8.193242888208284],
              [-39.934000521081437, -8.193242919885803],
              [-39.934000631449436, -8.193242950178995],
              [-39.934000742192097, -8.193242979083063],
              [-39.934000853291785, -8.193243006593365],
              [-39.934000964730878, -8.193243032705595],
              [-39.934001076491583, -8.193243057415552],
              [-39.934001188556195, -8.193243080719297],
              [-39.934001300906829, -8.19324310261317],
              [-39.934001413525678, -8.193243123093682],
              [-39.934001526394773, -8.193243142157534],
              [-39.934001639496202, -8.19324315980171],
              [-39.934001752811973, -8.193243176023394],
              [-39.934001866324053, -8.193243190820025],
              [-39.934001980014386, -8.193243204189297],
              [-39.934002093864898, -8.193243216128984],
              [-39.934002207857489, -8.193243226637264],
              [-39.934002321974013, -8.193243235712428],
              [-39.934002436196351, -8.193243243353065],
              [-39.934002550506293, -8.19324324955792],
              [-39.934002664885689, -8.193243254326031],
              [-39.934002779316344, -8.193243257656617],
              [-39.934002893780054, -8.193243259549199],
              [-39.934003008258628, -8.19324326000342],
              [-39.93400296905061, -8.193243281906177],
              [-39.936365420456504, -8.193237806585374],
              [-39.936365456462667, -8.193237784689837],
              [-39.936365600490518, -8.193237785494052],
              [-39.936365744487439, -8.193237788574951],
              [-39.936365888417171, -8.19323779393169],
              [-39.936366032243477, -8.193237801562972],
              [-39.936366175930154, -8.193237811466849],
              [-39.936366319441042, -8.193237823640837],
              [-39.93636646273999, -8.193237838081853],
              [-39.936366605790944, -8.193237854786311],
              [-39.936366748557873, -8.193237873749966],
              [-39.936366891004859, -8.193237894968016],
              [-39.936367033096019, -8.193237918435186],
              [-39.936367174795599, -8.193237944145539],
              [-39.936367316067908, -8.193237972092595],
              [-39.93636745687742, -8.193238002269327],
              [-39.936367597188635, -8.193238034668129],
              [-39.936367736966268, -8.193238069280874],
              [-39.936367876175112, -8.193238106098805],
              [-39.936368014780136, -8.193238145112693],
              [-39.936368152746439, -8.193238186312689],
              [-39.936368290039276, -8.193238229688422],
              [-39.936368426624114, -8.193238275228984],
              [-39.936368562466548, -8.1932383229229],
              [-39.936368697532387, -8.193238372758199],
              [-39.936368831787618, -8.193238424722278],
              [-39.93636896519844, -8.19323847880208],
              [-39.936369097731308, -8.193238534984014],
              [-39.936369229352813, -8.193238593253891],
              [-39.936369360029843, -8.193238653597097],
              [-39.936369489729493, -8.193238715998396],
              [-39.936369618419107, -8.193238780442108],
              [-39.936369746066291, -8.193238846911992],
              [-39.93636987263892, -8.193238915391309],
              [-39.936369998105107, -8.193238985862822],
              [-39.936370122433296, -8.19323905830881],
              [-39.936370245592173, -8.193239132711025],
              [-39.936370367550737, -8.193239209050727],
              [-39.936370488278271, -8.193239287308712],
              [-39.936370607744401, -8.193239367465276],
              [-39.936370725919048, -8.193239449500236],
              [-39.936370842772462, -8.193239533392939],
              [-39.936370958275226, -8.193239619122265],
              [-39.936371072398252, -8.193239706666633],
              [-39.936371185112826, -8.193239796004006],
              [-39.936371296390561, -8.193239887111909],
              [-39.936371406203435, -8.193239979967363],
              [-39.936371514523834, -8.193240074547031],
              [-39.936371621324462, -8.193240170827105],
              [-39.93637172657845, -8.193240268783306],
              [-39.936371830259283, -8.193240368391015],
              [-39.936371932340869, -8.193240469625138],
              [-39.936372032797522, -8.193240572460217],
              [-39.936372131603925, -8.193240676870317],
              [-39.93637222873523, -8.19324078282918],
              [-39.936372324166982, -8.193240890310122],
              [-39.936372417875127, -8.193240999286106],
              [-39.936372509836104, -8.193241109729678],
              [-39.936372600026758, -8.193241221613061],
              [-39.936372688424385, -8.193241334908043],
              [-39.93637277500671, -8.193241449586127],
              [-39.936372859751962, -8.193241565618456],
              [-39.936372942638798, -8.193241682975815],
              [-39.936373023646368, -8.193241801628638],
              [-39.936373102754239, -8.193241921547088],
              [-39.936373179942535, -8.193242042700948],
              [-39.936373255191796, -8.193242165059715],
              [-39.9363733284831, -8.193242288592614],
              [-39.936373399797979, -8.193242413268525],
              [-39.936373469118486, -8.193242539056088],
              [-39.93637353642719, -8.193242665923581],
              [-39.936373601707118, -8.193242793839131],
              [-39.936373664941847, -8.193242922770491],
              [-39.936373726115448, -8.19324305268522],
              [-39.936373785212545, -8.193243183550591],
              [-39.936373842218231, -8.193243315333717],
              [-39.936373897118187, -8.193243448001375],
              [-39.936373949898559, -8.193243581520163],
              [-39.936374000546095, -8.193243715856498],
              [-39.936374049048013, -8.193243850976517],
              [-39.936374095392125, -8.193243986846234],
              [-39.936374139566745, -8.193244123431448],
              [-39.936374181560765, -8.193244260697799],
              [-39.936374221363614, -8.193244398610693],
              [-39.936374258965266, -8.193244537135378],
              [-39.93637429435627, -8.193244676237065],
              [-39.93637432752768, -8.193244815880675],
              [-39.936374358471184, -8.193244956031085],
              [-39.936374387178972, -8.19324509665301],
              [-39.936374413643826, -8.193245237711004],
              [-39.936374437859079, -8.193245379169614],
              [-39.93637445981863, -8.193245520993214],
              [-39.936374479516964, -8.193245663146083],
              [-39.936374496949114, -8.193245805592436],
              [-39.936374512110689, -8.193245948296434],
              [-39.936374524997881, -8.193246091222136],
              [-39.936374535607442, -8.193246234333577],
              [-39.93637454393668, -8.193246377594685],
              [-39.93637454998354, -8.193246520969447],
              [-39.936374553746461, -8.193246664421729],
              [-39.936374555225711, -8.193246807914198],
              [-39.936374519219548, -8.193246829809723],
              [-39.936378437242233, -8.194922646178874],
              [-39.936378473340028, -8.194922663415701],
              [-39.936378473102138, -8.194922753269305],
              [-39.93637847196829, -8.19492284311611],
              [-39.93637846993861, -8.194922932947275],
              [-39.936378467013277, -8.194923022753898],
              [-39.936378463192597, -8.194923112527158],
              [-39.936378458476938, -8.194923202258167],
              [-39.936378452866776, -8.194923291938075],
              [-39.936378446362646, -8.194923381557993],
              [-39.936378438965221, -8.194923471109146],
              [-39.936378430675198, -8.194923560582637],
              [-39.936378421493409, -8.194923649969637],
              [-39.936378411420769, -8.194923739261386],
              [-39.93637840045826, -8.194923828448996],
              [-39.936378388606968, -8.194923917523695],
              [-39.936378375868053, -8.194924006476723],
              [-39.936378362242799, -8.194924095299239],
              [-39.936378347732521, -8.194924183982506],
              [-39.93637833233867, -8.19492427251777],
              [-39.936378316062765, -8.194924360896309],
              [-39.936378298906398, -8.194924449109386],
              [-39.936378280871274, -8.194924537148273],
              [-39.93637826195917, -8.194924625004308],
              [-39.936378242171948, -8.19492471266881],
              [-39.936378221511568, -8.194924800133133],
              [-39.936378199980076, -8.194924887388678],
              [-39.936378177579577, -8.194924974426733],
              [-39.936378154312308, -8.19492506123877],
              [-39.936378130180536, -8.194925147816239],
              [-39.936378105186662, -8.194925234150581],
              [-39.936378079333139, -8.194925320233244],
              [-39.936378052622544, -8.194925406055784],
              [-39.936378025057493, -8.194925491609689],
              [-39.936377996640708, -8.194925576886513],
              [-39.936377967375002, -8.194925661877875],
              [-39.936377937263252, -8.194925746575361],
              [-39.936377906308444, -8.19492583097059],
              [-39.936377874513617, -8.194925915055276],
              [-39.936377841881935, -8.194925998821095],
              [-39.936377808416594, -8.194926082259792],
              [-39.936377774120892, -8.194926165363121],
              [-39.936377738998232, -8.194926248122885],
              [-39.936377703052095, -8.194926330530903],
              [-39.936377666285992, -8.194926412579065],
              [-39.936377628703582, -8.194926494259247],
              [-39.936377590308552, -8.194926575563434],
              [-39.936377551104698, -8.194926656483517],
              [-39.936377511095898, -8.194926737011581],
              [-39.936377470286097, -8.194926817139638],
              [-39.936377428679322, -8.194926896859808],
              [-39.936377386279695, -8.194926976164217],
              [-39.936377343091358, -8.194927055045049],
              [-39.936377299118611, -8.194927133494451],
              [-39.936377254365787, -8.194927211504764],
              [-39.9363772088373, -8.194927289068238],
              [-39.936377162537646, -8.194927366177238],
              [-39.936377115471394, -8.194927442824191],
              [-39.936377067643186, -8.194927519001444],
              [-39.936377019057737, -8.194927594701554],
              [-39.936376969719866, -8.194927669916988],
              [-39.936376919634426, -8.194927744640358],
              [-39.936376868806356, -8.194927818864322],
              [-39.936376817240685, -8.194927892581461],
              [-39.936376764942487, -8.194927965784583],
              [-39.936376711916942, -8.194928038466463],
              [-39.936376658169273, -8.194928110619868],
              [-39.936376603704794, -8.194928182237673],
              [-39.936376548528848, -8.194928253312879],
              [-39.936376492646936, -8.194928323838443],
              [-39.936376436064528, -8.194928393807395],
              [-39.93637637878723, -8.194928463212817],
              [-39.936376320820699, -8.194928532047838],
              [-39.93637626217064, -8.194928600305724],
              [-39.936376202842844, -8.194928667979724],
              [-39.936376142843173, -8.194928735063133],
              [-39.936376082177553, -8.194928801549358],
              [-39.936376020851966, -8.194928867431802],
              [-39.936375958872475, -8.194928932703991],
              [-39.936375896245181, -8.194928997359478],
              [-39.936375832976275, -8.194929061391861],
              [-39.936375769071994, -8.19492912479487],
              [-39.936375704538648, -8.194929187562163],
              [-39.936375639382618, -8.194929249687625],
              [-39.936375573610327, -8.194929311165055],
              [-39.936375507228256, -8.194929371988481],
              [-39.93637544024299, -8.194929432151799],
              [-39.936375372661097, -8.194929491649122],
              [-39.936375304489275, -8.194929550474582],
              [-39.936375235734246, -8.194929608622335],
              [-39.936375166402783, -8.194929666086693],
              [-39.936375096501763, -8.194929722861945],
              [-39.936375026038064, -8.19492977894248],
              [-39.936374955018628, -8.194929834322821],
              [-39.936374883450483, -8.194929888997411],
              [-39.936374811340691, -8.194929942960949],
              [-39.936374738696365, -8.194929996208074],
              [-39.936374665524681, -8.194930048733514],
              [-39.936374591832845, -8.19493010053208],
              [-39.936374517628153, -8.194930151598667],
              [-39.936374442917284, -8.194930201929532],
              [-39.93637446062511, -8.194930162209426],
              [-39.935364775806079, -8.195603142325423],
              [-39.935364786489089, -8.195603111490806],
              [-39.935364712534678, -8.195603160260026],
              [-39.935364638105582, -8.195603208307068],
              [-39.935364563208886, -8.195603255627349],
              [-39.935364487851785, -8.195603302216368],
              [-39.935364412041444, -8.195603348069648],
              [-39.935364335785074, -8.195603393182825],
              [-39.935364259089994, -8.19560343755162],
              [-39.935364181963507, -8.195603481171753],
              [-39.935364104412983, -8.195603524039068],
              [-39.935364026445818, -8.195603566149511],
              [-39.935363948069451, -8.195603607499033],
              [-39.935363869291365, -8.195603648083699],
              [-39.935363790119077, -8.19560368789964],
              [-39.935363710560139, -8.195603726943029],
              [-39.93536363062217, -8.195603765210143],
              [-39.935363550312786, -8.195603802697354],
              [-39.935363469639611, -8.195603839401077],
              [-39.935363388610426, -8.195603875317792],
              [-39.93536330723289, -8.195603910444095],
              [-39.935363225514827, -8.19560394477663],
              [-39.935363143463988, -8.195603978312079],
              [-39.935363061088246, -8.195604011047285],
              [-39.935362978395425, -8.195604042979138],
              [-39.935362895393475, -8.195604074104532],
              [-39.93536281209024, -8.195604104420536],
              [-39.935362728493722, -8.19560413392426],
              [-39.935362644611899, -8.19560416261287],
              [-39.935362560452766, -8.195604190483627],
              [-39.935362476024387, -8.195604217533882],
              [-39.935362391334749, -8.195604243761059],
              [-39.935362306391994, -8.195604269162626],
              [-39.935362221204215, -8.195604293736178],
              [-39.935362135779535, -8.195604317479384],
              [-39.935362050126123, -8.195604340389952],
              [-39.935361964252152, -8.195604362465708],
              [-39.935361878165793, -8.195604383704536],
              [-39.935361791875295, -8.195604404104424],
              [-39.935361705388871, -8.195604423663422],
              [-39.935361618714794, -8.195604442379668],
              [-39.935361531861332, -8.195604460251349],
              [-39.935361444836765, -8.195604477276765],
              [-39.93536135764942, -8.195604493454315],
              [-39.935361270307581, -8.195604508782422],
              [-39.935361182819605, -8.195604523259657],
              [-39.935361095193869, -8.195604536884639],
              [-39.935361007438701, -8.19560454965603],
              [-39.935360919562498, -8.195604561572631],
              [-39.935360831573625, -8.195604572633316],
              [-39.935360743480508, -8.195604582837028],
              [-39.935360655291539, -8.195604592182766],
              [-39.935360567015131, -8.195604600669675],
              [-39.935360478659717, -8.195604608296915],
              [-39.93536039023374, -8.195604615063777],
              [-39.935360301745604, -8.195604620969595],
              [-39.935360213203801, -8.195604626013829],
              [-39.935360124616764, -8.195604630195978],
              [-39.935360035992943, -8.195604633515662],
              [-39.935359947340793, -8.195604635972559],
              [-39.935359858668782, -8.195604637566413],
              [-39.935359769985382, -8.195604638297107],
              [-39.935359681299055, -8.195604638164532],
              [-39.935359592618234, -8.195604637168723],
              [-39.935359503951425, -8.195604635309794],
              [-39.93535941530709, -8.195604632587893],
              [-39.935359326693657, -8.195604629003268],
              [-39.935359238119595, -8.195604624556292],
              [-39.935359149593396, -8.195604619247398],
              [-39.935359061123457, -8.195604613077034],
              [-39.935358972718248, -8.195604606045842],
              [-39.935358884386204, -8.195604598154478],
              [-39.935358796135759, -8.195604589403729],
              [-39.935358707975332, -8.195604579794361],
              [-39.93535861991333, -8.195604569327328],
              [-39.935358531958165, -8.195604558003641],
              [-39.935358444118251, -8.195604545824365],
              [-39.935358356401949, -8.195604532790696],
              [-39.935358268817623, -8.195604518903828],
              [-39.935358181373665, -8.195604504165114],
              [-39.935358094078389, -8.195604488575942],
              [-39.935358006940142, -8.195604472137822],
              [-39.935357919967245, -8.195604454852319],
              [-39.935357833167984, -8.195604436721048],
              [-39.935357746550679, -8.195604417745791],
              [-39.93535766012355, -8.195604397928333],
              [-39.935357573894876, -8.195604377270559],
              [-39.935357487872885, -8.195604355774471],
              [-39.935357402065762, -8.195604333442068],
              [-39.935357316481742, -8.195604310275542],
              [-39.935357231128961, -8.195604286277081],
              [-39.935357146015569, -8.19560426144894],
              [-39.935357061149695, -8.195604235793525],
              [-39.935356976539445, -8.195604209313275],
              [-39.935356892192893, -8.195604182010731],
              [-39.935356808118073, -8.195604153888512],
              [-39.935356724323029, -8.195604124949252],
              [-39.935356640815755, -8.195604095195746],
              [-39.935356557604209, -8.195604064630828],
              [-39.935356474696341, -8.195604033257441],
              [-39.935356392099983, -8.195604001078593],
              [-39.935356426245377, -8.195603975676439],
              [-39.933667502067614, -8.194936526583501],
              [-39.933667470182762, -8.194936509669315],
              [-39.93366735378293, -8.19493646458651],
              [-39.933667236771633, -8.194936421103415],
              [-39.933667119170991, -8.194936379228242],
              [-39.933667001003272, -8.194936338968928],
              [-39.933666882290844, -8.194936300333096],
              [-39.933666763056173, -8.194936263328049],
              [-39.933666643321821, -8.194936227960815],
              [-39.93366652311046, -8.194936194238064],
              [-39.933666402444842, -8.194936162166174],
              [-39.93366628134779, -8.194936131751227],
              [-39.93366615984224, -8.194936102998966],
              [-39.933666037951177, -8.194936075914862],
              [-39.933665915697667, -8.194936050504019],
              [-39.933665793104872, -8.194936026771227],
              [-39.933665670195964, -8.194936004721026],
              [-39.933665546994234, -8.194935984357564],
              [-39.933665423522982, -8.194935965684703],
              [-39.93366529980559, -8.194935948705956],
              [-39.933665175865464, -8.194935933424553],
              [-39.933665051726067, -8.194935919843392],
              [-39.933664927410874, -8.194935907965029],
              [-39.933664802943433, -8.194935897791728],
              [-39.933664678347299, -8.1949358893254],
              [-39.933664553646068, -8.194935882567629],
              [-39.933664428863324, -8.194935877519736],
              [-39.933664304022678, -8.194935874182651],
              [-39.933664179147783, -8.194935872557032],
              [-39.933664054262259, -8.194935872643159],
              [-39.933663929389716, -8.194935874441033],
              [-39.933663804553852, -8.19493587795027],
              [-39.933663679778242, -8.194935883170265],
              [-39.933663555086504, -8.194935890099991],
              [-39.933663430502257, -8.194935898738153],
              [-39.933663306049077, -8.194935909083119],
              [-39.933663181750525, -8.194935921132952],
              [-39.933663057630092, -8.194935934885343],
              [-39.933662933711325, -8.194935950337674],
              [-39.933662810017637, -8.194935967487039],
              [-39.93366268657244, -8.194935986330185],
              [-39.933662563399125, -8.194936006863566],
              [-39.933662440520983, -8.194936029083289],
              [-39.933662317961279, -8.194936052985126],
              [-39.933662195743189, -8.194936078564567],
              [-39.933662073889892, -8.194936105816787],
              [-39.933661952424394, -8.194936134736599],
              [-39.933661831369726, -8.194936165318531],
              [-39.93366171074878, -8.194936197556817],
              [-39.933661590584386, -8.194936231445331],
              [-39.933661470899288, -8.19493626697769],
              [-39.933661351716154, -8.194936304147145],
              [-39.933661233057528, -8.194936342946685],
              [-39.933661114945842, -8.194936383368944],
              [-39.933660997403514, -8.194936425406256],
              [-39.933660880452742, -8.19493646905071],
              [-39.933660764115665, -8.194936514294021],
              [-39.93366064841431, -8.194936561127609],
              [-39.933660533370592, -8.194936609542653],
              [-39.933660419006266, -8.194936659529946],
              [-39.933660305342961, -8.194936711080052],
              [-39.933660192402215, -8.194936764183215],
              [-39.933660080205406, -8.194936818829349],
              [-39.933659968773753, -8.194936875008175],
              [-39.933659858128344, -8.194936932709004],
              [-39.933659748290147, -8.194936991920947],
              [-39.933659639279931, -8.194937052632774],
              [-39.933659531118337, -8.194937114832999],
              [-39.933659423825816, -8.194937178509852],
              [-39.933659317422695, -8.194937243651303],
              [-39.933659211929104, -8.194937310245027],
              [-39.933659107365017, -8.194937378278361],
              [-39.933659003750215, -8.194937447738488],
              [-39.933658901104309, -8.194937518612237],
              [-39.933658799446725, -8.194937590886196],
              [-39.933658698796734, -8.194937664546678],
              [-39.933658599173334, -8.194937739579769],
              [-39.933658500595421, -8.194937815971221],
              [-39.933658403081637, -8.194937893706634],
              [-39.933658306650443, -8.194937972771262],
              [-39.933658211320079, -8.194938053150155],
              [-39.933658117108621, -8.194938134828053],
              [-39.933658024033853, -8.19493821778954],
              [-39.933657932113405, -8.194938302018926],
              [-39.933657841364685, -8.194938387500244],
              [-39.93365775180488, -8.194938474217317],
              [-39.933657663450923, -8.194938562153759],
              [-39.933657576319533, -8.194938651292885],
              [-39.933657490427208, -8.194938741617843],
              [-39.933657405790214, -8.194938833111541],
              [-39.933657322424558, -8.194938925756656],
              [-39.933657240346001, -8.194939019535667],
              [-39.933657159570132, -8.194939114430804],
              [-39.93365708011217, -8.194939210424129],
              [-39.933657001987207, -8.194939307497458],
              [-39.933656925210016, -8.194939405632423],
              [-39.933656849795113, -8.194939504810458],
              [-39.933656775756795, -8.194939605012769],
              [-39.933656703109044, -8.194939706220397],
              [-39.933656631865631, -8.194939808414198],
              [-39.933656562040049, -8.19493991157484],
              [-39.933656525894285, -8.19493989839361],
              [-39.932985033319831, -8.195946911857808],
              [-39.932985054505366, -8.195946870621313],
              [-39.932985024763227, -8.195946915510024],
              [-39.932984995289544, -8.19594696057416],
              [-39.932984966085392, -8.195947005812153],
              [-39.932984937151758, -8.195947051222397],
              [-39.932984908489708, -8.19594709680324],
              [-39.932984880100229, -8.195947142553129],
              [-39.932984851984344, -8.195947188470409],
              [-39.932984824143034, -8.195947234553477],
              [-39.932984796577287, -8.19594728080072],
              [-39.932984769288083, -8.195947327210442],
              [-39.932984742276389, -8.195947373781063],
              [-39.932984715543142, -8.195947420510896],
              [-39.932984689089295, -8.195947467398305],
              [-39.932984662915793, -8.195947514441615],
              [-39.932984637023559, -8.195947561639208],
              [-39.932984611413502, -8.195947608989353],
              [-39.932984586086548, -8.195947656490413],
              [-39.932984561043568, -8.19594770414071],
              [-39.932984536285446, -8.19594775193853],
              [-39.932984511813089, -8.1959477998822],
              [-39.932984487627337, -8.195947847970052],
              [-39.932984463729042, -8.195947896200334],
              [-39.932984440119057, -8.195947944571389],
              [-39.93298441679822, -8.195947993081468],
              [-39.932984393767363, -8.195948041728878],
              [-39.932984371027281, -8.195948090511887],
              [-39.932984348578799, -8.195948139428758],
              [-39.932984326422691, -8.195948188477802],
              [-39.932984304559746, -8.19594823765723],
              [-39.932984282990752, -8.195948286965342],
              [-39.932984261716456, -8.195948336400384],
              [-39.932984240737618, -8.195948385960618],
              [-39.932984220054976, -8.195948435644272],
              [-39.932984199669256, -8.195948485449598],
              [-39.93298417958119, -8.195948535374837],
              [-39.932984159791495, -8.195948585418188],
              [-39.932984140300846, -8.195948635577944],
              [-39.932984121109968, -8.195948685852287],
              [-39.9329841022195, -8.195948736239464],
              [-39.932984083630153, -8.195948786737665],
              [-39.932984065342524, -8.19594883734511],
              [-39.932984047357316, -8.195948888060045],
              [-39.932984029675147, -8.195948938880626],
              [-39.932984012296629, -8.195948989805087],
              [-39.932983995222386, -8.195949040831641],
              [-39.932983978453045, -8.195949091958434],
              [-39.932983961989166, -8.195949143183697],
              [-39.932983945831339, -8.19594919450557],
              [-39.932983929980146, -8.195949245922302],
              [-39.932983914436136, -8.19594929743206],
              [-39.932983899199876, -8.195949349032984],
              [-39.932983884271877, -8.195949400723274],
              [-39.932983869652702, -8.195949452501123],
              [-39.932983855342833, -8.195949504364663],
              [-39.932983841342804, -8.195949556312103],
              [-39.932983827653082, -8.195949608341543],
              [-39.932983814274181, -8.195949660451191],
              [-39.932983801206561, -8.195949712639182],
              [-39.932983788450684, -8.195949764903693],
              [-39.932983776006999, -8.19594981724283],
              [-39.932983763875939, -8.195949869654804],
              [-39.932983752057957, -8.195949922137711],
              [-39.932983740553439, -8.195949974689706],
              [-39.932983729362824, -8.195950027308969],
              [-39.93298371848649, -8.195950079993587],
              [-39.932983707924826, -8.195950132741752],
              [-39.932983697678196, -8.195950185551537],
              [-39.932983687746976, -8.195950238421098],
              [-39.932983678131521, -8.195950291348559],
              [-39.932983668832151, -8.195950344332076],
              [-39.932983659849214, -8.195950397369767],
              [-39.932983651183001, -8.195950450459721],
              [-39.93298364283384, -8.195950503600088],
              [-39.932983634802042, -8.195950556788993],
              [-39.932983627087864, -8.195950610024557],
              [-39.932983619691583, -8.195950663304883],
              [-39.932983612613469, -8.195950716628076],
              [-39.932983605853771, -8.195950769992276],
              [-39.932983599412722, -8.195950823395581],
              [-39.932983593290551, -8.195950876836104],
              [-39.93298358748747, -8.195950930311952],
              [-39.9329835820037, -8.195950983821248],
              [-39.932983576839426, -8.195951037362097],
              [-39.932983571994839, -8.195951090932597],
              [-39.932983567470075, -8.195951144530842],
              [-39.932983563265346, -8.195951198154937],
              [-39.932983559380766, -8.195951251803018],
              [-39.932983555816485, -8.195951305473169],
              [-39.932983552572622, -8.195951359163482],
              [-39.932983549649293, -8.195951412872077],
              [-39.932983547046618, -8.195951466597046],
              [-39.932983544764674, -8.195951520336498],
              [-39.932983542803527, -8.195951574088488],
              [-39.932983541163267, -8.195951627851173],
              [-39.932983539843939, -8.195951681622615],
              [-39.932983538845619, -8.195951735400897],
              [-39.932983538168308, -8.1959517891842],
              [-39.932983537812042, -8.195951842970532],
              [-39.932983537776835, -8.195951896758004],
              [-39.932983501702964, -8.195951889790647],
              [-39.932989795986543, -8.198652788104923],
              [-39.932992146011472, -8.199660955760629],
              [-39.932992182050867, -8.199660947803775],
              [-39.932992181718504, -8.199661049004405],
              [-39.932992180249578, -8.199661150194984],
              [-39.932992177644316, -8.199661251362855],
              [-39.932992173903031, -8.199661352495349],
              [-39.932992169026186, -8.199661453579814],
              [-39.93299216301439, -8.199661554603578],
              [-39.932992155868412, -8.19966165555401],
              [-39.932992147589133, -8.199661756418429],
              [-39.932992138177582, -8.199661857184282],
              [-39.932992127634947, -8.199661957838897],
              [-39.93299211596257, -8.199662058369666],
              [-39.932992103161872, -8.199662158764028],
              [-39.932992089234482, -8.199662259009397],
              [-39.932992074182145, -8.199662359093235],
              [-39.932992058006725, -8.199662459003003],
              [-39.932992040710253, -8.199662558726201],
              [-39.932992022294918, -8.19966265825032],
              [-39.932992002763001, -8.199662757562885],
              [-39.93299198211696, -8.199662856651548],
              [-39.932991960359359, -8.199662955503802],
              [-39.932991937492957, -8.199663054107328],
              [-39.932991913520596, -8.199663152449764],
              [-39.932991888445265, -8.199663250518794],
              [-39.932991862270136, -8.199663348302161],
              [-39.932991834998454, -8.199663445787596],
              [-39.932991806633659, -8.199663542962908],
              [-39.932991777179282, -8.199663639815927],
              [-39.932991746639011, -8.199663736334518],
              [-39.932991715016705, -8.199663832506607],
              [-39.932991682316292, -8.199663928320154],
              [-39.932991648541851, -8.199664023763161],
              [-39.932991613697652, -8.199664118823652],
              [-39.93299157778803, -8.199664213489742],
              [-39.932991540817483, -8.199664307749613],
              [-39.932991502790649, -8.199664401591409],
              [-39.932991463712291, -8.199664495003432],
              [-39.932991423587289, -8.19966458797394],
              [-39.932991382420674, -8.199664680491315],
              [-39.932991340217598, -8.199664772543978],
              [-39.932991296983353, -8.199664864120388],
              [-39.93299125272334, -8.199664955209109],
              [-39.932991207443102, -8.199665045798691],
              [-39.93299116114833, -8.199665135877822],
              [-39.932991113844793, -8.199665225435229],
              [-39.93299106553841, -8.199665314459681],
              [-39.932991016235249, -8.199665402940015],
              [-39.932990965941499, -8.199665490865231],
              [-39.932990914663399, -8.199665578224208],
              [-39.932990862407451, -8.1996656650061],
              [-39.932990809180104, -8.199665751199998],
              [-39.93299075498809, -8.199665836795136],
              [-39.932990699838179, -8.199665921780767],
              [-39.932990643737256, -8.199666006146257],
              [-39.932990586692377, -8.199666089881052],
              [-39.932990528710668, -8.199666172974672],
              [-39.932990469799378, -8.199666255416718],
              [-39.932990409965889, -8.199666337196845],
              [-39.932990349217711, -8.19966641830484],
              [-39.932990287562426, -8.199666498730528],
              [-39.932990225007764, -8.199666578463845],
              [-39.932990161561555, -8.199666657494809],
              [-39.932990097231738, -8.199666735813521],
              [-39.932990032026396, -8.199666813410182],
              [-39.932989965953645, -8.199666890275076],
              [-39.932989899021791, -8.199666966398581],
              [-39.932989831239198, -8.199667041771155],
              [-39.932989762614376, -8.199667116383385],
              [-39.932989693155882, -8.199667190225895],
              [-39.932989622872448, -8.199667263289452],
              [-39.932989551772849, -8.199667335564929],
              [-39.932989479866009, -8.199667407043261],
              [-39.932989407160896, -8.199667477715494],
              [-39.932989333666654, -8.199667547572808],
              [-39.932989259392464, -8.199667616606405],
              [-39.932989184347612, -8.199667684807675],
              [-39.932989108541527, -8.199667752168052],
              [-39.932989031983666, -8.199667818679144],
              [-39.932988954683637, -8.1996678843326],
              [-39.932988876651109, -8.199667949120204],
              [-39.932988797895852, -8.199668013033836],
              [-39.932988718427737, -8.199668076065512],
              [-39.932988638256703, -8.199668138207329],
              [-39.932988557392783, -8.199668199451505],
              [-39.93298847584613, -8.19966825979037],
              [-39.932988393626914, -8.199668319216375],
              [-39.932988310745472, -8.199668377722061],
              [-39.932988227212135, -8.199668435300152],
              [-39.932988143037385, -8.199668491943388],
              [-39.932988058231778, -8.199668547644704],
              [-39.93298797280589, -8.19966860239709],
              [-39.932987886770455, -8.199668656193737],
              [-39.932987800136232, -8.1996687090279],
              [-39.932987712914056, -8.199668760892951],
              [-39.932987625114876, -8.199668811782386],
              [-39.93298753674965, -8.199668861689862],
              [-39.932987447829476, -8.199668910609116],
              [-39.932987358365466, -8.199668958534032],
              [-39.932987268368819, -8.19966900545856],
              [-39.932987177851295, -8.199669051376109],
              [-39.932987171999784, -8.199669016736854],
              [-39.931634880097484, -8.200345585743451],
              [-39.929600720227562, -8.201363158144382],
              [-39.929600764656861, -8.201363178792027],
              [-39.929600726669776, -8.201363197684804],
              [-39.929600688594384, -8.201363216400367],
              [-39.929600650431574, -8.201363234938293],
              [-39.929600612182149, -8.201363253298172],
              [-39.929600573846962, -8.201363271479675],
              [-39.929600535426836, -8.201363289482297],
              [-39.929600496922632, -8.201363307305702],
              [-39.929600458335173, -8.201363324949511],
              [-39.929600419665306, -8.201363342413289],
              [-39.929600380913882, -8.201363359696733],
              [-39.929600342081756, -8.201363376799362],
              [-39.929600303169764, -8.201363393720865],
              [-39.929600264178752, -8.201363410460871],
              [-39.92960022510961, -8.201363427019032],
              [-39.929600185963146, -8.201363443394948],
              [-39.929600146740249, -8.201363459588286],
              [-39.929600107441743, -8.201363475598683],
              [-39.929600068068531, -8.201363491425738],
              [-39.929600028621444, -8.201363507069196],
              [-39.929599989101348, -8.201363522528654],
              [-39.929599949509118, -8.201363537803775],
              [-39.929599909845606, -8.201363552894257],
              [-39.929599870111709, -8.201363567799746],
              [-39.929599830308263, -8.201363582519948],
              [-39.929599790436143, -8.201363597054522],
              [-39.929599750496237, -8.201363611403107],
              [-39.929599710489398, -8.201363625565442],
              [-39.929599670416529, -8.201363639541201],
              [-39.929599630278489, -8.2013636533301],
              [-39.929599590076165, -8.20136366693178],
              [-39.929599549810426, -8.201363680346006],
              [-39.929599509482145, -8.201363693572461],
              [-39.929599469092224, -8.201363706610831],
              [-39.929599428641538, -8.201363719460845],
              [-39.929599388130974, -8.201363732122232],
              [-39.929599347561414, -8.201363744594742],
              [-39.929599306933753, -8.201363756878033],
              [-39.929599266248857, -8.201363768971882],
              [-39.929599225507651, -8.201363780876056],
              [-39.929599184711016, -8.201363792590209],
              [-39.929599143859818, -8.201363804114161],
              [-39.929599102954974, -8.201363815447639],
              [-39.929599061997379, -8.201363826590367],
              [-39.929599020987929, -8.201363837542143],
              [-39.929598979927498, -8.201363848302661],
              [-39.929598938817023, -8.201363858871769],
              [-39.929598897657364, -8.201363869249212],
              [-39.929598856449466, -8.201363879434721],
              [-39.929598815194176, -8.2013638894281],
              [-39.929598773892451, -8.20136389922917],
              [-39.929598732545145, -8.201363908837639],
              [-39.929598691153203, -8.201363918253339],
              [-39.929598649717505, -8.201363927476059],
              [-39.929598608238962, -8.201363936505622],
              [-39.929598566718496, -8.201363945341781],
              [-39.929598525156997, -8.201363953984389],
              [-39.929598483555388, -8.201363962433236],
              [-39.929598441914557, -8.201363970688121],
              [-39.929598400235449, -8.201363978748907],
              [-39.929598358518938, -8.20136398661537],
              [-39.929598316765983, -8.20136399428738],
              [-39.929598274977451, -8.201364001764757],
              [-39.929598233154273, -8.201364009047323],
              [-39.929598191297373, -8.201364016134882],
              [-39.929598149407646, -8.201364023027372],
              [-39.929598107486036, -8.201364029724566],
              [-39.92959806553344, -8.201364036226346],
              [-39.929598023550788, -8.201364042532596],
              [-39.929597981538983, -8.201364048643104],
              [-39.929597939498969, -8.201364054557807],
              [-39.929597897431627, -8.201364060276578],
              [-39.929597855337903, -8.201364065799224],
              [-39.929597813218727, -8.201364071125679],
              [-39.929597771075002, -8.2013640762558],
              [-39.929597728907645, -8.201364081189478],
              [-39.929597686717607, -8.201364085926633],
              [-39.929597644505776, -8.20136409046712],
              [-39.929597602273105, -8.201364094810906],
              [-39.92959756002049, -8.201364098957804],
              [-39.929597517748881, -8.20136410290781],
              [-39.92959747545919, -8.201364106660776],
              [-39.92959743315236, -8.201364110216643],
              [-39.929597390829272, -8.201364113575364],
              [-39.929597348490901, -8.201364116736793],
              [-39.929597306138149, -8.201364119700923],
              [-39.929597263771939, -8.201364122467659],
              [-39.929597221393202, -8.201364125036969],
              [-39.929597179002869, -8.201364127408747],
              [-39.92959713660187, -8.201364129583011],
              [-39.929597094191124, -8.201364131559622],
              [-39.929597051771566, -8.201364133338629],
              [-39.929597009344121, -8.201364134919945],
              [-39.92959696690972, -8.201364136303535],
              [-39.929596924469273, -8.201364137489362],
              [-39.929596882023731, -8.201364138477444],
              [-39.929596839574032, -8.201364139267739],
              [-39.929596797121071, -8.201364139860216],
              [-39.929596754665795, -8.201364140254837],
              [-39.929596712208678, -8.201364140453659],
              [-39.929596728297248, -8.201364162228604],
              [-39.927225237323078, -8.201369623947262],
              [-39.927225268838804, -8.201369602063952],
              [-39.927225183885021, -8.201369602655426],
              [-39.927225098940518, -8.201369604038975],
              [-39.927225014012706, -8.201369606214469],
              [-39.927224929109073, -8.201369609181718],
              [-39.927224844237024, -8.201369612940491],
              [-39.927224759403984, -8.201369617490426],
              [-39.92722467461742, -8.201369622831146],
              [-39.927224589884723, -8.201369628962187],
              [-39.927224505213331, -8.201369635882997],
              [-39.927224420610656, -8.201369643592965],
              [-39.927224336084109, -8.201369652091442],
              [-39.927224251641071, -8.201369661377646],
              [-39.927224167288969, -8.2013696714508],
              [-39.927224083035185, -8.201369682310004],
              [-39.927223998887094, -8.201369693954305],
              [-39.927223914852057, -8.201369706382698],
              [-39.927223830937436, -8.201369719594069],
              [-39.927223747150613, -8.201369733587287],
              [-39.927223663498886, -8.201369748361106],
              [-39.927223579989594, -8.201369763914231],
              [-39.927223496630049, -8.201369780245313],
              [-39.927223413427555, -8.201369797352925],
              [-39.92722333038941, -8.201369815235557],
              [-39.927223247522853, -8.201369833891651],
              [-39.927223164835191, -8.201369853319573],
              [-39.927223082333619, -8.201369873517601],
              [-39.927223000025393, -8.201369894483987],
              [-39.927222917917703, -8.201369916216905],
              [-39.927222836017762, -8.201369938714427],
              [-39.927222754332718, -8.201369961974589],
              [-39.927222672869753, -8.201369985995365],
              [-39.927222591635989, -8.201370010774655],
              [-39.927222510638522, -8.201370036310262],
              [-39.927222429884466, -8.201370062599974],
              [-39.927222349380898, -8.201370089641484],
              [-39.927222269134859, -8.201370117432422],
              [-39.927222189153376, -8.201370145970337],
              [-39.927222109443449, -8.201370175252764],
              [-39.927222030012082, -8.201370205277101],
              [-39.927221950866205, -8.201370236040725],
              [-39.927221872012765, -8.201370267540959],
              [-39.927221793458671, -8.201370299775053],
              [-39.927221715210791, -8.201370332740179],
              [-39.927221637275991, -8.201370366433419],
              [-39.927221559661085, -8.201370400851841],
              [-39.927221482372886, -8.201370435992441],
              [-39.92722140541813, -8.201370471852155],
              [-39.927221328803597, -8.201370508427777],
              [-39.927221252535979, -8.201370545716165],
              [-39.927221176621948, -8.201370583714032],
              [-39.92722110106817, -8.201370622418027],
              [-39.927221025881252, -8.201370661824802],
              [-39.927220951067781, -8.201370701930902],
              [-39.927220876634316, -8.201370742732795],
              [-39.927220802587357, -8.201370784226906],
              [-39.927220728933406, -8.201370826409619],
              [-39.927220655678916, -8.201370869277227],
              [-39.927220582830294, -8.201370912825961],
              [-39.927220510393923, -8.201370957052065],
              [-39.927220438376146, -8.201371001951602],
              [-39.927220366783267, -8.201371047520661],
              [-39.927220295621559, -8.201371093755256],
              [-39.927220224897269, -8.201371140651309],
              [-39.92722015461657, -8.201371188204748],
              [-39.927220084785624, -8.201371236411401],
              [-39.927220015410562, -8.201371285267054],
              [-39.927219946497438, -8.201371334767398],
              [-39.927219878052298, -8.201371384908127],
              [-39.927219810081127, -8.20137143568482],
              [-39.927219742589898, -8.201371487093068],
              [-39.92721967558451, -8.201371539128344],
              [-39.927219609070818, -8.201371591786103],
              [-39.927219543054669, -8.201371645061711],
              [-39.927219477541861, -8.201371698950528],
              [-39.927219412538086, -8.201371753447791],
              [-39.927219348049064, -8.201371808548776],
              [-39.927219284080444, -8.20137186424863],
              [-39.927219220637809, -8.20137192054249],
              [-39.927219157726732, -8.201371977425422],
              [-39.927219095352733, -8.201372034892461],
              [-39.927219033521261, -8.201372092938531],
              [-39.927218972237732, -8.201372151558573],
              [-39.927218911507516, -8.201372210747456],
              [-39.927218851335923, -8.201372270499997],
              [-39.927218791728237, -8.201372330810948],
              [-39.927218732689667, -8.201372391675031],
              [-39.927218674225394, -8.201372453086941],
              [-39.927218616340525, -8.20137251504126],
              [-39.927218559040142, -8.201372577532599],
              [-39.927218502329268, -8.201372640555466],
              [-39.927218446212862, -8.20137270410434],
              [-39.927218390695828, -8.201372768173654],
              [-39.927218335783039, -8.201372832757777],
              [-39.927218281479313, -8.201372897851085],
              [-39.927218227789389, -8.201372963447881],
              [-39.927218174717986, -8.201373029542411],
              [-39.927218122269743, -8.201373096128854],
              [-39.927218070449243, -8.201373163201424],
              [-39.927218019261041, -8.201373230754221],
              [-39.927217983443612, -8.20137325837522],
              [-39.926205112064103, -8.20272310603958],
              [-39.926205095414943, -8.202723064787993],
              [-39.926205085543643, -8.202723077967505],
              [-39.926205075696423, -8.202723091164867],
              [-39.926205065873319, -8.202723104380066],
              [-39.92620505607433, -8.202723117613029],
              [-39.926205046299508, -8.202723130863713],
              [-39.926205036548893, -8.202723144132079],
              [-39.926205026822501, -8.202723157418117],
              [-39.926205017120374, -8.202723170721731],
              [-39.92620500744254, -8.202723184042895],
              [-39.926204997789043, -8.202723197381575],
              [-39.926204988159888, -8.202723210737721],
              [-39.926204978555134, -8.202723224111276],
              [-39.926204968974801, -8.202723237502234],
              [-39.926204959418911, -8.202723250910513],
              [-39.92620494988752, -8.202723264336077],
              [-39.926204940380643, -8.202723277778887],
              [-39.926204930898308, -8.202723291238884],
              [-39.926204921440565, -8.202723304716047],
              [-39.926204912007407, -8.202723318210301],
              [-39.926204902598919, -8.202723331721652],
              [-39.9262048932151, -8.202723345250005],
              [-39.926204883855974, -8.202723358795339],
              [-39.926204874521588, -8.20272337235761],
              [-39.926204865211972, -8.202723385936775],
              [-39.926204855927139, -8.202723399532761],
              [-39.926204846667147, -8.202723413145552],
              [-39.926204837432017, -8.202723426775083],
              [-39.926204828221756, -8.202723440421334],
              [-39.926204819036421, -8.202723454084245],
              [-39.926204809876047, -8.20272346776377],
              [-39.926204800740649, -8.202723481459852],
              [-39.926204791630248, -8.202723495172487],
              [-39.9262047825449, -8.202723508901608],
              [-39.926204773484628, -8.202723522647142],
              [-39.926204764449452, -8.202723536409078],
              [-39.926204755439386, -8.202723550187352],
              [-39.926204746454502, -8.202723563981943],
              [-39.926204737494793, -8.202723577792764],
              [-39.926204728560315, -8.202723591619787],
              [-39.926204719651089, -8.202723605462991],
              [-39.926204710767124, -8.20272361932232],
              [-39.926204701908468, -8.202723633197705],
              [-39.926204693075164, -8.202723647089112],
              [-39.926204684267212, -8.202723660996519],
              [-39.926204675484655, -8.202723674919822],
              [-39.926204666727536, -8.202723688859038],
              [-39.926204657995868, -8.202723702814094],
              [-39.926204649289666, -8.20272371678495],
              [-39.926204640608987, -8.202723730771527],
              [-39.926204631953844, -8.202723744773815],
              [-39.926204623324274, -8.202723758791777],
              [-39.926204614720291, -8.202723772825335],
              [-39.926204606141937, -8.202723786874467],
              [-39.926204597589241, -8.202723800939102],
              [-39.926204589062216, -8.202723815019196],
              [-39.926204580560906, -8.202723829114747],
              [-39.926204572085332, -8.202723843225652],
              [-39.92620456363553, -8.202723857351879],
              [-39.926204555211513, -8.202723871493394],
              [-39.926204546813324, -8.202723885650164],
              [-39.926204538440984, -8.202723899822102],
              [-39.926204530094516, -8.202723914009191],
              [-39.926204521773961, -8.202723928211384],
              [-39.926204513479334, -8.202723942428614],
              [-39.926204505210656, -8.20272395666086],
              [-39.926204496967969, -8.202723970908039],
              [-39.926204488751324, -8.202723985170136],
              [-39.926204480560699, -8.202723999447098],
              [-39.926204472396137, -8.202724013738873],
              [-39.926204464257658, -8.20272402804542],
              [-39.926204456145328, -8.202724042366656],
              [-39.926204448059131, -8.202724056702595],
              [-39.926204439999118, -8.202724071053145],
              [-39.92620443196531, -8.202724085418277],
              [-39.926204423957728, -8.202724099797949],
              [-39.926204415976386, -8.202724114192089],
              [-39.926204408021341, -8.202724128600682],
              [-39.926204400092615, -8.202724143023643],
              [-39.926204392190208, -8.202724157460946],
              [-39.926204384314175, -8.202724171912552],
              [-39.926204376464511, -8.202724186378406],
              [-39.926204368641258, -8.20272420085843],
              [-39.926204360844466, -8.202724215352598],
              [-39.92620435307412, -8.202724229860891],
              [-39.926204345330262, -8.202724244383219],
              [-39.926204337612937, -8.202724258919556],
              [-39.926204329922136, -8.202724273469864],
              [-39.926204322257917, -8.202724288034059],
              [-39.926204314620279, -8.202724302612109],
              [-39.926204307009243, -8.202724317203966],
              [-39.926204299424874, -8.202724331809621],
              [-39.926204291867165, -8.202724346428964],
              [-39.926204284336144, -8.202724361061989],
              [-39.926204276831847, -8.202724375708616],
              [-39.926204269354287, -8.202724390368848],
              [-39.926204261903493, -8.202724405042559],
              [-39.926204254479487, -8.202724419729753],
              [-39.926204247082303, -8.202724434430365],
              [-39.926204239711964, -8.202724449144375],
              [-39.926204207418692, -8.202724464772432],
              [-39.925531381269444, -8.204070906854257],
              [-39.925531341650782, -8.204070864754625],
              [-39.925531287669116, -8.204070971008258],
              [-39.925531232287895, -8.204071076544537],
              [-39.92553117551671, -8.204071181345158],
              [-39.925531117365423, -8.204071285391958],
              [-39.925531057844104, -8.20407138866687],
              [-39.925530996963062, -8.20407149115205],
              [-39.925530934732869, -8.204071592829662],
              [-39.925530871164298, -8.204071693682099],
              [-39.925530806268384, -8.204071793691858],
              [-39.925530740056388, -8.204071892841659],
              [-39.925530672539779, -8.204071991114263],
              [-39.925530603730238, -8.20407208849265],
              [-39.925530533639723, -8.204072184959927],
              [-39.925530462280392, -8.204072280499377],
              [-39.925530389664594, -8.204072375094405],
              [-39.925530315804949, -8.204072468728661],
              [-39.925530240714224, -8.204072561385876],
              [-39.925530164405465, -8.20407265305],
              [-39.925530086891904, -8.204072743705135],
              [-39.925530008186975, -8.204072833335573],
              [-39.925529928304307, -8.204072921925746],
              [-39.925529847257778, -8.204073009460318],
              [-39.925529765061405, -8.204073095924114],
              [-39.925529681729479, -8.204073181302142],
              [-39.925529597276409, -8.204073265579579],
              [-39.925529511716874, -8.204073348741851],
              [-39.925529425065683, -8.204073430774503],
              [-39.925529337337871, -8.204073511663333],
              [-39.925529248548649, -8.204073591394273],
              [-39.925529158713395, -8.20407366995356],
              [-39.925529067847691, -8.204073747327529],
              [-39.92552897596731, -8.204073823502819],
              [-39.925528883088163, -8.204073898466183],
              [-39.925528789226362, -8.204073972204609],
              [-39.925528694398174, -8.204074044705347],
              [-39.925528598620041, -8.204074115955811],
              [-39.925528501908566, -8.204074185943638],
              [-39.92552840428052, -8.204074254656735],
              [-39.925528305752849, -8.204074322083136],
              [-39.925528206342598, -8.204074388211176],
              [-39.925528106067041, -8.204074453029396],
              [-39.925528004943537, -8.204074516526564],
              [-39.925527902989622, -8.204074578691626],
              [-39.92552780022298, -8.204074639513864],
              [-39.925527696661419, -8.204074698982685],
              [-39.925527592322915, -8.20407475708781],
              [-39.925527487225537, -8.204074813819139],
              [-39.925527381387511, -8.20407486916684],
              [-39.925527274827182, -8.204074923121354],
              [-39.925527167563047, -8.204074975673276],
              [-39.925527059613678, -8.204075026813527],
              [-39.925526950997799, -8.204075076533242],
              [-39.925526841734268, -8.204075124823779],
              [-39.925526731841977, -8.204075171676783],
              [-39.925526621340019, -8.204075217084114],
              [-39.925526510247543, -8.204075261037898],
              [-39.925526398583806, -8.204075303530548],
              [-39.925526286368168, -8.204075344554655],
              [-39.925526173620078, -8.20407538410314],
              [-39.925526060359104, -8.204075422169117],
              [-39.925525946604871, -8.204075458746011],
              [-39.925525832377097, -8.204075493827462],
              [-39.925525717695606, -8.204075527407417],
              [-39.925525602580258, -8.204075559479987],
              [-39.925525487051033, -8.204075590039695],
              [-39.925525371127932, -8.204075619081188],
              [-39.925525254831058, -8.204075646599449],
              [-39.92552513818061, -8.204075672589733],
              [-39.925525021196776, -8.204075697047475],
              [-39.925524903899856, -8.204075719968486],
              [-39.925524786310184, -8.204075741348742],
              [-39.925524668448141, -8.204075761184576],
              [-39.925524550334174, -8.204075779472557],
              [-39.925524431988762, -8.204075796209484],
              [-39.92552431343239, -8.204075811392448],
              [-39.925524194685657, -8.204075825018876],
              [-39.925524075769133, -8.204075837086341],
              [-39.925523956703437, -8.204075847592783],
              [-39.925523837509203, -8.204075856536388],
              [-39.92552371820711, -8.204075863915554],
              [-39.925523598817826, -8.204075869729051],
              [-39.925523479362077, -8.204075873975853],
              [-39.925523359860541, -8.204075876655221],
              [-39.925523240333959, -8.204075877766707],
              [-39.925523120803057, -8.204075877310105],
              [-39.925523001288539, -8.204075875285513],
              [-39.925522881811148, -8.204075871693245],
              [-39.92552276239158, -8.204075866533964],
              [-39.92552264305057, -8.204075859808528],
              [-39.925522523808766, -8.204075851518144],
              [-39.925522404686873, -8.20407584166419],
              [-39.925522285705526, -8.204075830248444],
              [-39.925522166885379, -8.204075817272855],
              [-39.925522048247004, -8.204075802739677],
              [-39.925521929810991, -8.20407578665138],
              [-39.925521811597868, -8.204075769010817],
              [-39.92552169362812, -8.204075749821028],
              [-39.925521575922218, -8.204075729085316],
              [-39.925521458501223, -8.204075706806462],
              [-39.925521497456174, -8.204075723065259],
              [-39.923829054311611, -8.203743065619856],
              [-39.923828600208196, -8.203742976217164],
              [-39.922474946065691, -8.203409626479806],
              [-39.922474922546449, -8.203409610364435],
              [-39.922474818141914, -8.203409585303692],
              [-39.92247471344713, -8.203409561475203],
              [-39.922474608476733, -8.203409538882321],
              [-39.922474503245425, -8.203409517528202],
              [-39.922474397767921, -8.203409497415848],
              [-39.922474292058986, -8.203409478548071],
              [-39.922474186133421, -8.20340946092751],
              [-39.922474080006062, -8.203409444556637],
              [-39.92247397369173, -8.203409429437745],
              [-39.922473867205319, -8.203409415572919],
              [-39.922473760561736, -8.203409402964096],
              [-39.922473653775917, -8.2034093916131],
              [-39.922473546862769, -8.203409381521475],
              [-39.922473439837283, -8.203409372690638],
              [-39.922473332714432, -8.203409365121837],
              [-39.922473225509208, -8.203409358816092],
              [-39.922473118236617, -8.203409353774335],
              [-39.922473010911666, -8.203409349997257],
              [-39.92247290354937, -8.203409347485389],
              [-39.922472796164769, -8.203409346239079],
              [-39.922472688772878, -8.203409346258486],
              [-39.922472581388739, -8.203409347543623],
              [-39.92247247402738, -8.203409350094317],
              [-39.922472366703794, -8.203409353910198],
              [-39.92247225943305, -8.20340935899074],
              [-39.922472152230135, -8.203409365335224],
              [-39.922472045110041, -8.203409372942764],
              [-39.922471938087781, -8.203409381812289],
              [-39.922471831178335, -8.203409391942582],
              [-39.922471724396644, -8.203409403332202],
              [-39.922471617757651, -8.203409415979548],
              [-39.922471511276299, -8.203409429882885],
              [-39.922471404967489, -8.203409445040243],
              [-39.922471298846077, -8.203409461449498],
              [-39.922471192926942, -8.203409479108362],
              [-39.922471087224906, -8.203409498014368],
              [-39.922470981754728, -8.203409518164859],
              [-39.9224708765312, -8.20340953955704],
              [-39.922470771569046, -8.203409562187874],
              [-39.922470666882944, -8.203409586054212],
              [-39.922470562487554, -8.203409611152738],
              [-39.922470458397477, -8.203409637479879],
              [-39.922470354627279, -8.203409665032014],
              [-39.922470251191491, -8.203409693805249],
              [-39.922470148104601, -8.203409723795597],
              [-39.922470045380997, -8.203409754998845],
              [-39.922469943035104, -8.203409787410594],
              [-39.92246984108121, -8.203409821026332],
              [-39.922469739533597, -8.203409855841381],
              [-39.922469638406469, -8.203409891850814],
              [-39.922469537713994, -8.203409929049627],
              [-39.922469437470227, -8.203409967432615],
              [-39.922469337689243, -8.20341000699438],
              [-39.922469238384984, -8.203410047729436],
              [-39.922469139571348, -8.203410089632015],
              [-39.922469041262175, -8.203410132696284],
              [-39.922468943471202, -8.203410176916252],
              [-39.922468846212141, -8.20341022228566],
              [-39.922468749498577, -8.203410268798196],
              [-39.922468653344055, -8.203410316447361],
              [-39.922468557762038, -8.203410365226482],
              [-39.922468462765906, -8.203410415128738],
              [-39.922468368368939, -8.203410466147114],
              [-39.922468274584361, -8.203410518274501],
              [-39.922468181425295, -8.20341057150357],
              [-39.922468088904765, -8.203410625826917],
              [-39.922467997035739, -8.203410681236919],
              [-39.922467905831049, -8.203410737725806],
              [-39.922467815303492, -8.203410795285683],
              [-39.922467725465715, -8.20341085390851],
              [-39.922467636330289, -8.20341091358604],
              [-39.922467547909704, -8.203410974309985],
              [-39.922467460216311, -8.20341103607179],
              [-39.922467373262393, -8.203411098862848],
              [-39.92246728706013, -8.203411162674355],
              [-39.922467201621593, -8.203411227497391],
              [-39.922467116958714, -8.203411293322864],
              [-39.922467033083358, -8.203411360141583],
              [-39.922466950007248, -8.203411427944172],
              [-39.922466867742017, -8.203411496721166],
              [-39.922466786299196, -8.203411566462945],
              [-39.922466705690155, -8.203411637159723],
              [-39.922466625926191, -8.203411708801637],
              [-39.922466547018473, -8.203411781378614],
              [-39.922466468978023, -8.203411854880533],
              [-39.922466391815789, -8.203411929297086],
              [-39.922466315542536, -8.203412004617887],
              [-39.92246624016898, -8.203412080832388],
              [-39.922466165705643, -8.203412157929913],
              [-39.922466092162935, -8.203412235899657],
              [-39.922466019551187, -8.203412314730747],
              [-39.922465947880525, -8.20341239441211],
              [-39.922465877161002, -8.203412474932639],
              [-39.922465807402482, -8.203412556281007],
              [-39.922465738614768, -8.203412638445887],
              [-39.922465670807469, -8.203412721415733],
              [-39.922465603990076, -8.203412805178965],
              [-39.922465538171949, -8.203412889723841],
              [-39.922465473362266, -8.203412975038548],
              [-39.922465512780143, -8.203412994487916],
              [-39.921455696539326, -8.204758664980279],
              [-39.921455732727154, -8.204758648502823],
              [-39.921455681538056, -8.204758716060326],
              [-39.921455629716583, -8.204758783137569],
              [-39.921455577267281, -8.204758849728679],
              [-39.921455524194748, -8.204758915827831],
              [-39.921455470503616, -8.204758981429213],
              [-39.921455416198597, -8.204759046527073],
              [-39.921455361284444, -8.204759111115768],
              [-39.921455305765967, -8.204759175189585],
              [-39.921455249648034, -8.20475923874292],
              [-39.921455192935561, -8.204759301770205],
              [-39.921455135633522, -8.204759364265938],
              [-39.92145507774692, -8.204759426224632],
              [-39.921455019280828, -8.20475948764085],
              [-39.921454960240375, -8.204759548509221],
              [-39.921454900630735, -8.204759608824446],
              [-39.921454840457123, -8.204759668581209],
              [-39.921454779724812, -8.204759727774251],
              [-39.921454718439101, -8.204759786398432],
              [-39.921454656605405, -8.204759844448606],
              [-39.921454594229111, -8.204759901919687],
              [-39.921454531315661, -8.204759958806626],
              [-39.921454467870596, -8.204760015104455],
              [-39.921454403899467, -8.204760070808254],
              [-39.921454339407887, -8.204760125913108],
              [-39.921454274401484, -8.204760180414215],
              [-39.921454208885962, -8.204760234306821],
              [-39.921454142867056, -8.204760287586154],
              [-39.921454076350557, -8.204760340247569],
              [-39.921454009342284, -8.204760392286461],
              [-39.921453941848114, -8.204760443698277],
              [-39.921453873873958, -8.204760494478506],
              [-39.921453805425749, -8.204760544622692],
              [-39.921453736509513, -8.204760594126467],
              [-39.921453667131274, -8.204760642985462],
              [-39.921453597297095, -8.204760691195446],
              [-39.921453527013099, -8.204760738752132],
              [-39.921453456285469, -8.20476078565139],
              [-39.921453385120373, -8.204760831889129],
              [-39.921453313524061, -8.204760877461261],
              [-39.921453241502775, -8.204760922363823],
              [-39.92145316906285, -8.20476096659287],
              [-39.921453096210627, -8.204761010144521],
              [-39.921453022952484, -8.204761053014995],
              [-39.921452949294832, -8.204761095200492],
              [-39.921452875244135, -8.204761136697352],
              [-39.921452800806875, -8.204761177501917],
              [-39.921452725989568, -8.204761217610617],
              [-39.921452650798756, -8.204761257019936],
              [-39.921452575241062, -8.204761295726438],
              [-39.921452499323053, -8.20476133372672],
              [-39.921452423051434, -8.204761371017451],
              [-39.921452346432837, -8.204761407595377],
              [-39.921452269473995, -8.204761443457315],
              [-39.921452192181675, -8.204761478600073],
              [-39.921452114562591, -8.204761513020586],
              [-39.921452036623577, -8.204761546715861],
              [-39.921451958371456, -8.204761579682931],
              [-39.921451879813077, -8.204761611918924],
              [-39.921451800955332, -8.204761643420985],
              [-39.92145172180512, -8.204761674186399],
              [-39.921451642369369, -8.204761704212437],
              [-39.921451562655044, -8.20476173349649],
              [-39.921451482669127, -8.204761762035963],
              [-39.921451402418619, -8.204761789828366],
              [-39.921451321910553, -8.204761816871287],
              [-39.921451241151978, -8.204761843162357],
              [-39.921451160149978, -8.204761868699229],
              [-39.921451078911623, -8.204761893479706],
              [-39.921450997444055, -8.204761917501637],
              [-39.921450915754406, -8.204761940762873],
              [-39.921450833849825, -8.204761963261392],
              [-39.921450751737481, -8.204761984995207],
              [-39.921450669424573, -8.204762005962449],
              [-39.921450586918318, -8.204762026161262],
              [-39.92145050422593, -8.204762045589881],
              [-39.921450421354663, -8.204762064246601],
              [-39.921450338311772, -8.204762082129795],
              [-39.92145025510451, -8.204762099237872],
              [-39.921450171740204, -8.204762115569372],
              [-39.921450088226131, -8.204762131122827],
              [-39.9214500045696, -8.204762145896904],
              [-39.921449920777945, -8.204762159890274],
              [-39.921449836858535, -8.204762173101759],
              [-39.921449752818681, -8.204762185530184],
              [-39.92144966866578, -8.204762197174462],
              [-39.921449584407142, -8.204762208033555],
              [-39.921449500050223, -8.204762218106515],
              [-39.92144941560236, -8.204762227392472],
              [-39.921449331070967, -8.204762235890607],
              [-39.921449246463453, -8.204762243600138],
              [-39.92144916178723, -8.204762250520476],
              [-39.921449077049687, -8.204762256650939],
              [-39.921448992258284, -8.204762261991007],
              [-39.921448907420427, -8.204762266540266],
              [-39.921448822543546, -8.204762270298232],
              [-39.921448737635089, -8.204762273264627],
              [-39.92144865270248, -8.204762275439199],
              [-39.92144856775316, -8.204762276821739],
              [-39.921448482794453, -8.204762277412335],
              [-39.921448442537816, -8.204762299316473],
              [-39.920434711079707, -8.204764616160578],
              [-39.920434736868096, -8.204764594289928],
              [-39.920434718794127, -8.20476459434914],
              [-39.920434700720293, -8.204764594444198],
              [-39.920434682646686, -8.204764594575124],
              [-39.920434664573392, -8.204764594741881],
              [-39.920434646500446, -8.204764594944503],
              [-39.920434628427962, -8.20476459518297],
              [-39.920434610355983, -8.204764595457291],
              [-39.920434592284593, -8.204764595767456],
              [-39.920434574213857, -8.204764596113472],
              [-39.920434556143846, -8.204764596495314],
              [-39.920434538074637, -8.204764596912989],
              [-39.920434520006282, -8.204764597366529],
              [-39.920434501938892, -8.204764597855897],
              [-39.920434483872498, -8.204764598381129],
              [-39.920434465807197, -8.204764598942175],
              [-39.920434447743055, -8.204764599539054],
              [-39.920434429680135, -8.204764600171755],
              [-39.920434411618523, -8.204764600840287],
              [-39.920434393558274, -8.20476460154465],
              [-39.920434375499489, -8.204764602284827],
              [-39.920434357442197, -8.204764603060829],
              [-39.920434339386496, -8.204764603872661],
              [-39.920434321332451, -8.204764604720305],
              [-39.920434303280153, -8.204764605603746],
              [-39.920434285229632, -8.204764606523026],
              [-39.920434267180994, -8.20476460747809],
              [-39.920434249134317, -8.204764608468928],
              [-39.920434231089637, -8.204764609495591],
              [-39.920434213047031, -8.204764610558021],
              [-39.920434195006614, -8.204764611656257],
              [-39.920434176968406, -8.204764612790303],
              [-39.920434158932508, -8.204764613960077],
              [-39.920434140898998, -8.204764615165658],
              [-39.920434122867917, -8.204764616407001],
              [-39.920434104839345, -8.204764617684122],
              [-39.920434086813366, -8.204764618996983],
              [-39.920434068790058, -8.204764620345586],
              [-39.920434050769458, -8.204764621729963],
              [-39.920434032751679, -8.204764623150082],
              [-39.92043401473677, -8.204764624605925],
              [-39.92043399672481, -8.204764626097493],
              [-39.920433978715863, -8.204764627624787],
              [-39.92043396071, -8.204764629187805],
              [-39.920433942707305, -8.20476463078656],
              [-39.920433924707822, -8.204764632421009],
              [-39.920433906711658, -8.204764634091148],
              [-39.920433888718868, -8.204764635796975],
              [-39.920433870729518, -8.204764637538512],
              [-39.920433852743692, -8.204764639315714],
              [-39.920433834761418, -8.204764641128612],
              [-39.920433816782847, -8.204764642977146],
              [-39.92043379880797, -8.204764644861321],
              [-39.92043378083693, -8.204764646781198],
              [-39.920433762869742, -8.204764648736699],
              [-39.920433744906504, -8.204764650727819],
              [-39.92043372694728, -8.2047646527546],
              [-39.920433708992128, -8.204764654816991],
              [-39.920433691041147, -8.204764656914973],
              [-39.920433673094394, -8.204764659048612],
              [-39.920433655151939, -8.204764661217787],
              [-39.92043363721384, -8.204764663422596],
              [-39.920433619280196, -8.204764665662962],
              [-39.920433601351071, -8.204764667938893],
              [-39.920433583426515, -8.204764670250379],
              [-39.92043356550662, -8.204764672597415],
              [-39.92043354759145, -8.204764674980021],
              [-39.920433529681084, -8.20476467739813],
              [-39.92043351177557, -8.204764679851769],
              [-39.920433493875009, -8.204764682340929],
              [-39.920433475979458, -8.204764684865593],
              [-39.920433458088986, -8.204764687425733],
              [-39.920433440203659, -8.204764690021358],
              [-39.920433422323555, -8.204764692652455],
              [-39.920433404448751, -8.204764695319014],
              [-39.920433386579312, -8.204764698021027],
              [-39.920433368715308, -8.204764700758515],
              [-39.920433350856804, -8.204764703531392],
              [-39.920433333003878, -8.204764706339699],
              [-39.920433315156593, -8.204764709183403],
              [-39.920433297315036, -8.204764712062524],
              [-39.920433279479276, -8.204764714977024],
              [-39.92043326164935, -8.20476471792689],
              [-39.920433243825379, -8.204764720912131],
              [-39.920433226007404, -8.204764723932675],
              [-39.920433208195504, -8.204764726988628],
              [-39.920433190389723, -8.204764730079866],
              [-39.920433172590172, -8.204764733206428],
              [-39.920433154796903, -8.204764736368308],
              [-39.920433137009979, -8.204764739565446],
              [-39.920433119229493, -8.204764742797879],
              [-39.920433101455501, -8.204764746065575],
              [-39.920433083688067, -8.204764749368499],
              [-39.920433065927277, -8.204764752706682],
              [-39.92043304817318, -8.204764756080095],
              [-39.920433030425862, -8.2047647594887],
              [-39.920433012685393, -8.204764762932518],
              [-39.920432994951838, -8.204764766411492],
              [-39.920432977225296, -8.20476476992566],
              [-39.920432959505781, -8.20476477347499],
              [-39.92043298669067, -8.204764800986888],
              [-39.918740999520082, -8.205105384433542],
              [-39.918741026313235, -8.205105420230019],
              [-39.91874093191025, -8.205105439756943],
              [-39.918740837720762, -8.205105460281677],
              [-39.918740743755372, -8.20510548180193],
              [-39.918740650024702, -8.205105504315233],
              [-39.918740556539305, -8.205105527819082],
              [-39.918740463309739, -8.205105552310826],
              [-39.918740370346526, -8.205105577787673],
              [-39.918740277660142, -8.205105604246802],
              [-39.918740185261051, -8.205105631685177],
              [-39.918740093159663, -8.20510566009971],
              [-39.91874000136638, -8.205105689487194],
              [-39.918739909891542, -8.205105719844356],
              [-39.918739818745486, -8.20510575116772],
              [-39.918739727938473, -8.205105783453769],
              [-39.918739637480769, -8.205105816698865],
              [-39.918739547382572, -8.205105850899272],
              [-39.918739457654027, -8.205105886051113],
              [-39.918739368305268, -8.205105922150427],
              [-39.918739279346383, -8.205105959193137],
              [-39.918739190787399, -8.205105997175087],
              [-39.918739102638305, -8.205106036091967],
              [-39.918739014909036, -8.20510607593938],
              [-39.918738927609503, -8.205106116712868],
              [-39.918738840749548, -8.205106158407798],
              [-39.918738754338975, -8.205106201019488],
              [-39.918738668387526, -8.205106244543126],
              [-39.918738582904879, -8.205106288973786],
              [-39.918738497900719, -8.205106334306469],
              [-39.918738413384588, -8.205106380536053],
              [-39.91873832936605, -8.205106427657348],
              [-39.918738245854584, -8.205106475665024],
              [-39.918738162859583, -8.205106524553678],
              [-39.91873808039044, -8.205106574317773],
              [-39.918737998456429, -8.205106624951688],
              [-39.918737917066807, -8.205106676449727],
              [-39.918737836230768, -8.205106728806097],
              [-39.918737755957409, -8.205106782014852],
              [-39.918737676255802, -8.205106836070016],
              [-39.918737597134928, -8.205106890965501],
              [-39.918737518603713, -8.205106946695105],
              [-39.918737440671016, -8.205107003252543],
              [-39.918737363345628, -8.205107060631407],
              [-39.918737286636265, -8.205107118825273],
              [-39.918737210551605, -8.205107177827541],
              [-39.918737135100201, -8.205107237631552],
              [-39.918737060290589, -8.205107298230576],
              [-39.918736986131179, -8.205107359617795],
              [-39.918736912630365, -8.205107421786249],
              [-39.918736839796409, -8.205107484728956],
              [-39.918736767637562, -8.205107548438788],
              [-39.918736696161936, -8.205107612908575],
              [-39.918736625377605, -8.20510767813103],
              [-39.918736555292554, -8.205107744098807],
              [-39.918736485914671, -8.205107810804481],
              [-39.918736417251807, -8.205107878240501],
              [-39.918736349311715, -8.205107946399266],
              [-39.918736282102024, -8.205108015273098],
              [-39.918736215630339, -8.205108084854242],
              [-39.918736149904142, -8.205108155134782],
              [-39.918736084930877, -8.205108226106857],
              [-39.918736020717844, -8.205108297762452],
              [-39.918735957272297, -8.205108370093441],
              [-39.918735894601383, -8.205108443091721],
              [-39.918735832712208, -8.20510851674903],
              [-39.918735771611701, -8.205108591057057],
              [-39.91873571130678, -8.205108666007423],
              [-39.918735651804255, -8.205108741591674],
              [-39.918735593110839, -8.205108817801291],
              [-39.918735535233139, -8.20510889462766],
              [-39.91873547817768, -8.205108972062115],
              [-39.918735421950913, -8.205109050095947],
              [-39.918735366559162, -8.205109128720325],
              [-39.918735312008707, -8.205109207926416],
              [-39.918735258305681, -8.205109287705236],
              [-39.91873520545613, -8.205109368047827],
              [-39.918735153466031, -8.205109448945098],
              [-39.918735102341259, -8.20510953038794],
              [-39.918735052087548, -8.205109612367171],
              [-39.918735002710598, -8.205109694873531],
              [-39.918734954215978, -8.205109777897711],
              [-39.918734906609146, -8.20510986143036],
              [-39.918734859895459, -8.205109945462056],
              [-39.918734814080217, -8.20511002998329],
              [-39.918734769168566, -8.205110114984546],
              [-39.918734725165585, -8.205110200456257],
              [-39.918734682076241, -8.20511028638875],
              [-39.918734639905374, -8.205110372772317],
              [-39.91873459865775, -8.205110459597279],
              [-39.918734558338031, -8.205110546853804],
              [-39.918734518950764, -8.205110634532041],
              [-39.918734480500376, -8.205110722622107],
              [-39.918734442991216, -8.205110811114073],
              [-39.918734406427525, -8.205110899997933],
              [-39.918734370813411, -8.205110989263661],
              [-39.918734336152895, -8.205111078901218],
              [-39.918734302449899, -8.205111168900464],
              [-39.918734269708196, -8.205111259251288],
              [-39.918734237931517, -8.205111349943458],
              [-39.918734207123428, -8.20511144096673],
              [-39.918734204988588, -8.205111459658308],
              [-39.917724259358671, -8.208148602495969],
              [-39.917724223587157, -8.208148617271798],
              [-39.917724212355118, -8.208148650828104],
              [-39.917724200991429, -8.208148684340426],
              [-39.917724189496283, -8.208148717808202],
              [-39.917724177869829, -8.20814875123091],
              [-39.917724166112244, -8.208148784608095],
              [-39.917724154223755, -8.208148817939184],
              [-39.917724142204491, -8.208148851223701],
              [-39.917724130054665, -8.208148884461128],
              [-39.917724117774455, -8.208148917650957],
              [-39.917724105364037, -8.208148950792669],
              [-39.917724092823626, -8.208148983885803],
              [-39.917724080153391, -8.20814901692979],
              [-39.917724067353539, -8.208149049924188],
              [-39.917724054424255, -8.208149082868445],
              [-39.917724041365759, -8.208149115762051],
              [-39.917724028178228, -8.208149148604537],
              [-39.917724014861854, -8.208149181395383],
              [-39.917724001416879, -8.208149214134092],
              [-39.917723987843495, -8.208149246820161],
              [-39.917723974141879, -8.208149279453087],
              [-39.917723960312266, -8.208149312032379],
              [-39.917723946354883, -8.20814934455753],
              [-39.917723932269894, -8.208149377028056],
              [-39.91772391805759, -8.208149409443449],
              [-39.917723903718112, -8.208149441803196],
              [-39.917723889251704, -8.208149474106829],
              [-39.91772387465862, -8.208149506353852],
              [-39.917723859939038, -8.208149538543761],
              [-39.917723845093221, -8.208149570676092],
              [-39.917723830121361, -8.208149602750304],
              [-39.917723815023727, -8.20814963476594],
              [-39.91772379980052, -8.208149666722514],
              [-39.917723784451972, -8.208149698619502],
              [-39.917723768978327, -8.208149730456462],
              [-39.917723753379818, -8.208149762232862],
              [-39.917723737656686, -8.208149793948257],
              [-39.917723721809182, -8.208149825602137],
              [-39.917723705837524, -8.208149857194023],
              [-39.917723689741983, -8.208149888723424],
              [-39.917723673522786, -8.208149920189898],
              [-39.91772365718019, -8.208149951592903],
              [-39.917723640714449, -8.208149982932007],
              [-39.917723624125806, -8.208150014206701],
              [-39.917723607414509, -8.208150045416529],
              [-39.917723590580835, -8.208150076560987],
              [-39.917723573625011, -8.208150107639593],
              [-39.917723556547337, -8.208150138651941],
              [-39.917723539348025, -8.208150169597468],
              [-39.91772352202738, -8.208150200475764],
              [-39.917723504585638, -8.208150231286313],
              [-39.917723487023096, -8.208150262028671],
              [-39.917723469340004, -8.208150292702371],
              [-39.917723451536631, -8.208150323306935],
              [-39.917723433613254, -8.208150353841893],
              [-39.917723415570137, -8.208150384306775],
              [-39.917723397407578, -8.208150414701123],
              [-39.917723379125832, -8.208150445024451],
              [-39.917723360725191, -8.208150475276314],
              [-39.917723342205925, -8.208150505456265],
              [-39.917723323568325, -8.208150535563808],
              [-39.917723304812689, -8.208150565598489],
              [-39.917723285939282, -8.208150595559871],
              [-39.917723266948414, -8.208150625447482],
              [-39.91772324784035, -8.208150655260862],
              [-39.917723228615408, -8.208150684999561],
              [-39.917723209273859, -8.208150714663136],
              [-39.91772318981603, -8.208150744251101],
              [-39.917723170242191, -8.20815077376305],
              [-39.917723150552625, -8.20815080319846],
              [-39.917723130747689, -8.208150832556944],
              [-39.91772311082763, -8.208150861838044],
              [-39.917723090792798, -8.208150891041287],
              [-39.917723070643454, -8.208150920166256],
              [-39.917723050379955, -8.208150949212474],
              [-39.917723030002584, -8.208150978179523],
              [-39.917723009511633, -8.208151007066924],
              [-39.917722988907471, -8.208151035874279],
              [-39.917722968190347, -8.208151064601129],
              [-39.917722947360623, -8.208151093247036],
              [-39.91772292641862, -8.208151121811561],
              [-39.917722905364634, -8.208151150294251],
              [-39.917722884198994, -8.208151178694697],
              [-39.917722862922027, -8.208151207012458],
              [-39.917722841534058, -8.208151235247069],
              [-39.917722820035415, -8.208151263398138],
              [-39.917722798426432, -8.208151291465244],
              [-39.917722776707414, -8.208151319447929],
              [-39.917722754878731, -8.208151347345739],
              [-39.917722732940696, -8.208151375158304],
              [-39.917722710893635, -8.208151402885168],
              [-39.917722688737904, -8.208151430525907],
              [-39.917722666473864, -8.208151458080117],
              [-39.917722644101794, -8.20815148554736],
              [-39.917722621622083, -8.208151512927213],
              [-39.917722599035045, -8.208151540219252],
              [-39.917722576341063, -8.208151567423092],
              [-39.917722553540443, -8.208151594538297],
              [-39.917722530633569, -8.208151621564436],
              [-39.91772250762093, -8.208151648500236],
              [-39.917722541595957, -8.20815168149822],
              [-39.915690579730068, -8.210520598049571],
              [-39.915182736772323, -8.212536569750409],
              [-39.915182791453503, -8.212536574758458],
              [-39.91518278744234, -8.212536590621376],
              [-39.915182783402393, -8.212536606477084],
              [-39.915182779333662, -8.212536622325459],
              [-39.915182775236161, -8.21253663816649],
              [-39.915182771109919, -8.21253665400009],
              [-39.915182766954921, -8.212536669826255],
              [-39.915182762771217, -8.212536685644904],
              [-39.915182758558792, -8.212536701455988],
              [-39.915182754317669, -8.212536717259439],
              [-39.915182750047855, -8.21253673305524],
              [-39.915182745749391, -8.212536748843325],
              [-39.915182741422257, -8.212536764623604],
              [-39.915182737066473, -8.212536780396102],
              [-39.915182732682084, -8.212536796160693],
              [-39.915182728269066, -8.212536811917365],
              [-39.915182723827456, -8.212536827666083],
              [-39.915182719357261, -8.212536843406761],
              [-39.915182714858496, -8.212536859139336],
              [-39.91518271033118, -8.212536874863808],
              [-39.915182705775315, -8.212536890580086],
              [-39.915182701190943, -8.212536906288125],
              [-39.915182696578036, -8.212536921987855],
              [-39.915182691936664, -8.212536937679277],
              [-39.915182687266793, -8.212536953362308],
              [-39.915182682568464, -8.212536969036895],
              [-39.915182677841678, -8.212536984702973],
              [-39.915182673086477, -8.212537000360523],
              [-39.915182668302833, -8.212537016009479],
              [-39.915182663490803, -8.212537031649807],
              [-39.915182658650373, -8.212537047281408],
              [-39.915182653781585, -8.212537062904259],
              [-39.915182648884432, -8.212537078518331],
              [-39.915182643958957, -8.212537094123542],
              [-39.915182639005145, -8.212537109719838],
              [-39.915182634023026, -8.2125371253072],
              [-39.915182629012634, -8.212537140885559],
              [-39.915182623973948, -8.212537156454857],
              [-39.915182618907018, -8.212537172015038],
              [-39.915182613811844, -8.212537187566081],
              [-39.915182608688447, -8.212537203107928],
              [-39.915182603536842, -8.212537218640488],
              [-39.915182598357042, -8.212537234163758],
              [-39.915182593149062, -8.212537249677665],
              [-39.915182587912938, -8.212537265182162],
              [-39.915182582648676, -8.212537280677189],
              [-39.915182577356283, -8.212537296162733],
              [-39.915182572035789, -8.212537311638698],
              [-39.915182566687214, -8.212537327105045],
              [-39.915182561310552, -8.212537342561733],
              [-39.915182555905858, -8.212537358008715],
              [-39.915182550473133, -8.212537373445905],
              [-39.915182545012371, -8.212537388873304],
              [-39.915182539523613, -8.212537404290824],
              [-39.915182534006888, -8.212537419698441],
              [-39.915182528462182, -8.212537435096094],
              [-39.915182522889545, -8.212537450483735],
              [-39.91518251728899, -8.212537465861285],
              [-39.915182511660497, -8.212537481228738],
              [-39.915182506004143, -8.212537496586011],
              [-39.915182500319901, -8.212537511933071],
              [-39.915182494607819, -8.21253752726987],
              [-39.915182488867885, -8.212537542596351],
              [-39.915182483100146, -8.212537557912436],
              [-39.915182477304619, -8.212537573218141],
              [-39.915182471481309, -8.212537588513362],
              [-39.915182465630231, -8.212537603798049],
              [-39.915182459751421, -8.212537619072185],
              [-39.915182453844892, -8.212537634335703],
              [-39.915182447910659, -8.212537649588555],
              [-39.915182441948758, -8.212537664830686],
              [-39.915182435959181, -8.21253768006207],
              [-39.915182429941957, -8.212537695282597],
              [-39.915182423897122, -8.212537710492283],
              [-39.915182417824674, -8.212537725691051],
              [-39.915182411724651, -8.212537740878831],
              [-39.915182405597072, -8.212537756055609],
              [-39.915182399441946, -8.212537771221324],
              [-39.915182393259286, -8.212537786375902],
              [-39.915182387049128, -8.212537801519343],
              [-39.915182380811494, -8.212537816651537],
              [-39.915182374546397, -8.212537831772492],
              [-39.915182368253852, -8.212537846882105],
              [-39.915182361933894, -8.212537861980376],
              [-39.915182355586531, -8.212537877067234],
              [-39.915182349211797, -8.212537892142615],
              [-39.9151823428097, -8.212537907206507],
              [-39.915182336380262, -8.212537922258832],
              [-39.915182329923525, -8.212537937299542],
              [-39.915182323439481, -8.212537952328594],
              [-39.915182316928153, -8.212537967345927],
              [-39.915182310389568, -8.212537982351519],
              [-39.915182303823769, -8.212537997345292],
              [-39.91518229723075, -8.212538012327215],
              [-39.915182290610545, -8.212538027297244],
              [-39.91518228396319, -8.212538042255288],
              [-39.915182277288672, -8.212538057201348],
              [-39.915182270587032, -8.212538072135358],
              [-39.915182263858277, -8.212538087057247],
              [-39.915182257102309, -8.212538101969246],
              [-39.915182195606953, -8.212538108525603],
              [-39.914621449585965, -8.213772765452374],
              [-39.914621484519152, -8.2137727181437],
              [-39.914621441431272, -8.21377281449929],
              [-39.914621399474278, -8.213772911348963],
              [-39.914621358653946, -8.213773008679574],
              [-39.914621318975755, -8.213773106477909],
              [-39.914621280445161, -8.213773204730595],
              [-39.914621243067394, -8.213773303424329],
              [-39.914621206847507, -8.213773402545637],
              [-39.914621171790451, -8.213773502081068],
              [-39.914621137901001, -8.213773602017081],
              [-39.914621105183762, -8.21377370234007],
              [-39.914621073643154, -8.21377380303638],
              [-39.914621043283518, -8.213773904092344],
              [-39.914621014108945, -8.213774005494194],
              [-39.91462098612341, -8.213774107228133],
              [-39.914620959330747, -8.213774209280338],
              [-39.914620933734575, -8.213774311636932],
              [-39.914620909338382, -8.213774414283979],
              [-39.914620886145485, -8.213774517207488],
              [-39.914620864159041, -8.213774620393501],
              [-39.914620843382039, -8.213774723827964],
              [-39.914620823817309, -8.213774827496799],
              [-39.9146208054675, -8.21377493138594],
              [-39.914620788335135, -8.213775035481227],
              [-39.914620772422502, -8.213775139768499],
              [-39.914620757731797, -8.21377524423354],
              [-39.914620744265036, -8.21377534886221],
              [-39.914620732023991, -8.213775453640237],
              [-39.914620721010401, -8.213775558553374],
              [-39.914620711225702, -8.213775663587358],
              [-39.914620702671257, -8.213775768727862],
              [-39.914620695348219, -8.213775873960614],
              [-39.914620689257589, -8.213775979271331],
              [-39.914620684400191, -8.213776084645614],
              [-39.914620680776693, -8.213776190069202],
              [-39.914620678387578, -8.213776295527708],
              [-39.914620677233174, -8.213776401006804],
              [-39.91462067731365, -8.21377650649214],
              [-39.914620678628978, -8.213776611969367],
              [-39.914620681178995, -8.213776717424128],
              [-39.914620684963332, -8.213776822842087],
              [-39.914620689981497, -8.213776928208926],
              [-39.914620696232795, -8.213777033510262],
              [-39.91462070371638, -8.213777138731826],
              [-39.914620712431223, -8.213777243859266],
              [-39.914620722376149, -8.213777348878304],
              [-39.914620733549818, -8.21377745377462],
              [-39.9146207459507, -8.213777558533996],
              [-39.914620759577097, -8.213777663142139],
              [-39.914620774427149, -8.213777767584833],
              [-39.914620790498866, -8.213777871847881],
              [-39.914620807790044, -8.213777975917102],
              [-39.914620826298318, -8.213778079778319],
              [-39.914620846021208, -8.213778183417414],
              [-39.914620866956, -8.21377828682032],
              [-39.914620889099851, -8.213778389972919],
              [-39.914620912449749, -8.213778492861188],
              [-39.914620937002532, -8.213778595471162],
              [-39.914620962754825, -8.213778697788857],
              [-39.914620989703181, -8.213778799800368],
              [-39.914621017843885, -8.213778901491825],
              [-39.914621047173128, -8.213779002849352],
              [-39.914621077686924, -8.213779103859224],
              [-39.914621109381116, -8.213779204507668],
              [-39.914621142251413, -8.213779304780982],
              [-39.914621176293281, -8.213779404665564],
              [-39.914621211502158, -8.213779504147798],
              [-39.914621247873228, -8.213779603214151],
              [-39.914621285401552, -8.213779701851148],
              [-39.914621324082006, -8.213779800045371],
              [-39.91462136390934, -8.213779897783478],
              [-39.914621404878147, -8.213779995052164],
              [-39.914621446982828, -8.21378009183821],
              [-39.914621490217698, -8.213780188128428],
              [-39.914621534576838, -8.213780283909738],
              [-39.914621580054224, -8.2137803791691],
              [-39.914621626643651, -8.213780473893554],
              [-39.914621674338825, -8.213780568070208],
              [-39.914621723133237, -8.213780661686277],
              [-39.914621773020237, -8.213780754729024],
              [-39.914621823993052, -8.213780847185779],
              [-39.914621876044748, -8.213780939043955],
              [-39.914621929168234, -8.213781030291084],
              [-39.914621983356298, -8.213781120914719],
              [-39.91462203860155, -8.21378121090256],
              [-39.914622094896508, -8.21378130024236],
              [-39.914622152233491, -8.213781388921971],
              [-39.914622210604698, -8.213781476929324],
              [-39.914622270002184, -8.213781564252464],
              [-39.914622330417885, -8.213781650879474],
              [-39.914622391843587, -8.213781736798605],
              [-39.914622454270912, -8.213781821998147],
              [-39.914622517691392, -8.213781906466522],
              [-39.914622582096378, -8.213781990192238],
              [-39.914622647477124, -8.21378207316392],
              [-39.91462271382472, -8.213782155370243],
              [-39.914622781130163, -8.213782236800061],
              [-39.91462284938428, -8.213782317442297],
              [-39.914622918577798, -8.213782397285962],
              [-39.914622988701311, -8.213782476320219],
              [-39.914622924449496, -8.213782530035239],
              [-39.915527423349985, -8.214789917169744],
              [-39.915527605742348, -8.214790278530048],
              [-39.916544829046515, -8.2160211642794],
              [-39.916544892181719, -8.216021164689726],
              [-39.916544975901481, -8.216021264526818],
              [-39.916545061053966, -8.21602136315385],
              [-39.916545147621555, -8.216021460550486],
              [-39.916545235586348, -8.216021556696532],
              [-39.916545324930134, -8.216021651572113],
              [-39.916545415634424, -8.216021745157583],
              [-39.916545507680475, -8.216021837433592],
              [-39.916545601049229, -8.216021928381046],
              [-39.916545695721361, -8.216022017981105],
              [-39.916545791677301, -8.216022106215283],
              [-39.916545888897176, -8.21602219306526],
              [-39.916545987360884, -8.216022278513124],
              [-39.916546087048047, -8.216022362541171],
              [-39.916546187938053, -8.216022445132021],
              [-39.916546290010004, -8.216022526268576],
              [-39.916546393242818, -8.216022605934072],
              [-39.916546497615087, -8.216022684111996],
              [-39.916546603105267, -8.216022760786171],
              [-39.916546709691495, -8.216022835940766],
              [-39.916546817351744, -8.216022909560211],
              [-39.916546926063724, -8.216022981629299],
              [-39.916547035804953, -8.216023052133101],
              [-39.916547146552709, -8.216023121056994],
              [-39.916547258284098, -8.216023188386764],
              [-39.916547370975991, -8.21602325410848],
              [-39.916547484605083, -8.216023318208533],
              [-39.916547599147862, -8.216023380673668],
              [-39.916547714580602, -8.216023441490929],
              [-39.916547830879459, -8.216023500647777],
              [-39.916547948020352, -8.216023558131951],
              [-39.916548065979057, -8.21602361393156],
              [-39.916548184731141, -8.21602366803508],
              [-39.916548304252082, -8.216023720431272],
              [-39.916548424517103, -8.216023771109327],
              [-39.916548545501357, -8.216023820058751],
              [-39.916548667179782, -8.216023867269424],
              [-39.916548789527226, -8.21602391273157],
              [-39.916548912518358, -8.216023956435775],
              [-39.916549036127762, -8.216023998373021],
              [-39.916549160329829, -8.21602403853462],
              [-39.916549285098881, -8.216024076912259],
              [-39.916549410409097, -8.216024113497992],
              [-39.916549536234541, -8.216024148284268],
              [-39.916549662549208, -8.216024181263881],
              [-39.916549789326943, -8.216024212429989],
              [-39.91654991654152, -8.216024241776179],
              [-39.916550044166627, -8.216024269296344],
              [-39.916550172175839, -8.216024294984805],
              [-39.916550300542688, -8.216024318836258],
              [-39.916550429240608, -8.216024340845779],
              [-39.916550558242967, -8.216024361008765],
              [-39.916550687523092, -8.216024379321066],
              [-39.91655081705423, -8.216024395778931],
              [-39.916550946809565, -8.216024410378903],
              [-39.916551076762275, -8.216024423117995],
              [-39.916551206885458, -8.216024433993535],
              [-39.916551337152178, -8.21602444300335],
              [-39.91655146753552, -8.216024450145474],
              [-39.916551598008468, -8.216024455418486],
              [-39.916551728544079, -8.216024458821305],
              [-39.916551859115287, -8.216024460353202],
              [-39.916551989695115, -8.21602446001385],
              [-39.916552120256526, -8.216024457803359],
              [-39.916552250772519, -8.21602445372214],
              [-39.9165523812161, -8.21602444777109],
              [-39.916552511560262, -8.216024439951395],
              [-39.916552641778047, -8.216024430264714],
              [-39.916552771842497, -8.216024418712999],
              [-39.916552901726725, -8.216024405298686],
              [-39.916553031403858, -8.216024390024527],
              [-39.916553160847045, -8.21602437289369],
              [-39.916553290029519, -8.216024353909718],
              [-39.916553418924543, -8.216024333076533],
              [-39.916553547505472, -8.216024310398437],
              [-39.916553675745675, -8.216024285880145],
              [-39.916553803618648, -8.216024259526716],
              [-39.916553931097901, -8.216024231343606],
              [-39.916554058157075, -8.216024201336666],
              [-39.916554184769886, -8.216024169512071],
              [-39.916554310910136, -8.216024135876426],
              [-39.916554436551735, -8.216024100436668],
              [-39.916554561668661, -8.216024063200139],
              [-39.916554686235052, -8.216024024174583],
              [-39.916554810225144, -8.216023983368025],
              [-39.916554933613256, -8.216023940788912],
              [-39.91655505637388, -8.216023896446078],
              [-39.916555178481602, -8.216023850348646],
              [-39.916555299911167, -8.216023802506227],
              [-39.916555420637458, -8.216023752928681],
              [-39.916555540635485, -8.216023701626257],
              [-39.916555659880423, -8.216023648609605],
              [-39.916555778347586, -8.216023593889666],
              [-39.916555896012511, -8.216023537477753],
              [-39.916556012850805, -8.216023479385544],
              [-39.916556128838316, -8.216023419625053],
              [-39.916556243951042, -8.216023358208664],
              [-39.916556358165167, -8.216023295149068],
              [-39.916556471457056, -8.216023230459331],
              [-39.916556583803278, -8.216023164152823],
              [-39.916556544682543, -8.216023217802759],
              [-39.917680786965676, -8.21534874902048],
              [-39.917681330824763, -8.21534838600537],
              [-39.918808121424526, -8.214560039996535],
              [-39.918808182989011, -8.214560004724747],
              [-39.918808254847931, -8.214559953929637],
              [-39.918808326208634, -8.21455990244206],
              [-39.91880839706441, -8.214559850266928],
              [-39.918808467408546, -8.214559797409114],
              [-39.918808537234455, -8.214559743873592],
              [-39.918808606535549, -8.214559689665432],
              [-39.918808675305279, -8.214559634789744],
              [-39.918808743537213, -8.214559579251677],
              [-39.918808811224864, -8.214559523056462],
              [-39.918808878361894, -8.214559466209401],
              [-39.918808944941979, -8.214559408715868],
              [-39.918809010958846, -8.214559350581268],
              [-39.918809076406248, -8.214559291811058],
              [-39.918809141278061, -8.214559232410773],
              [-39.918809205568145, -8.214559172386041],
              [-39.918809269270461, -8.214559111742489],
              [-39.918809332379013, -8.21455905048583],
              [-39.918809394887823, -8.214558988621851],
              [-39.918809456791024, -8.214558926156355],
              [-39.91880951808281, -8.214558863095254],
              [-39.918809578757354, -8.214558799444454],
              [-39.918809638808995, -8.21455873520997],
              [-39.918809698232039, -8.214558670397858],
              [-39.918809757020895, -8.214558605014231],
              [-39.918809815170043, -8.214558539065218],
              [-39.918809872673982, -8.214558472557064],
              [-39.918809929527306, -8.214558405496],
              [-39.918809985724664, -8.214558337888365],
              [-39.918810041260755, -8.214558269740547],
              [-39.918810096130365, -8.214558201058937],
              [-39.918810150328284, -8.214558131850007],
              [-39.918810203849446, -8.214558062120277],
              [-39.918810256688808, -8.214557991876337],
              [-39.918810308841373, -8.214557921124767],
              [-39.918810360302224, -8.214557849872248],
              [-39.918810411066552, -8.214557778125492],
              [-39.918810461129524, -8.214557705891275],
              [-39.918810510486452, -8.214557633176389],
              [-39.918810559132694, -8.214557559987652],
              [-39.918810607063676, -8.214557486332019],
              [-39.918810654274836, -8.214557412216386],
              [-39.918810700761767, -8.214557337647756],
              [-39.91881074652008, -8.214557262633132],
              [-39.918810791545461, -8.214557187179578],
              [-39.918810835833668, -8.214557111294233],
              [-39.918810879380537, -8.214557034984233],
              [-39.918810922181947, -8.214556958256745],
              [-39.918810964233906, -8.214556881119009],
              [-39.918811005532405, -8.214556803578292],
              [-39.918811046073586, -8.214556725641895],
              [-39.918811085853612, -8.214556647317178],
              [-39.91881112486876, -8.214556568611487],
              [-39.918811163115315, -8.214556489532232],
              [-39.918811200589715, -8.21455641008693],
              [-39.918811237288416, -8.21455633028299],
              [-39.918811273207943, -8.214556250127997],
              [-39.918811308344942, -8.214556169629441],
              [-39.918811342696074, -8.214556088794907],
              [-39.918811376258127, -8.214556007632039],
              [-39.918811409027917, -8.214555926148485],
              [-39.918811441002397, -8.214555844351889],
              [-39.918811472178497, -8.21455576224999],
              [-39.918811502553339, -8.214555679850493],
              [-39.918811532124018, -8.214555597161203],
              [-39.918811560887768, -8.214555514189842],
              [-39.918811588841876, -8.214555430944289],
              [-39.918811615983707, -8.214555347432357],
              [-39.9188116423107, -8.214555263661911],
              [-39.918811667820393, -8.214555179640831],
              [-39.918811692510367, -8.214555095377053],
              [-39.918811716378308, -8.214555010878508],
              [-39.918811739421947, -8.214554926153188],
              [-39.918811761639134, -8.214554841209035],
              [-39.91881178302777, -8.214554756054035],
              [-39.918811803585847, -8.214554670696232],
              [-39.918811823311401, -8.214554585143667],
              [-39.918811842202615, -8.214554499404411],
              [-39.918811860257669, -8.214554413486534],
              [-39.91881187747488, -8.214554327398133],
              [-39.918811893852649, -8.214554241147312],
              [-39.918811909389397, -8.214554154742162],
              [-39.918811924083677, -8.21455406819087],
              [-39.918811937934102, -8.214553981501563],
              [-39.918811950939379, -8.214553894682414],
              [-39.918811963098264, -8.214553807741625],
              [-39.918811974409614, -8.214553720687341],
              [-39.91881198487237, -8.214553633527768],
              [-39.918811994485552, -8.214553546271162],
              [-39.918812003248242, -8.214553458925694],
              [-39.918812011159623, -8.214553371499624],
              [-39.918812018218951, -8.214553284001157],
              [-39.918812024425549, -8.21455319643856],
              [-39.918812029778856, -8.214553108820066],
              [-39.918812034278339, -8.214553021153939],
              [-39.918812037923594, -8.214552933448443],
              [-39.918812040714272, -8.214552845711861],
              [-39.91881204265011, -8.214552757952394],
              [-39.91881204373091, -8.214552670178319],
              [-39.918812043956606, -8.214552582397964],
              [-39.91881200797156, -8.214552614738503],
              [-39.91880995479216, -8.213659849366188],
              [-39.91880999086051, -8.213659853262806],
              [-39.918809991007286, -8.213659777090827],
              [-39.918809991798007, -8.213659700922825],
              [-39.918809993232571, -8.213659624764132],
              [-39.918809995310873, -8.213659548620194],
              [-39.918809998032792, -8.213659472496385],
              [-39.918810001398136, -8.213659396398127],
              [-39.918810005406648, -8.213659320330818],
              [-39.918810010058039, -8.213659244299805],
              [-39.918810015351994, -8.213659168310539],
              [-39.918810021288152, -8.213659092368392],
              [-39.918810027866051, -8.213659016478736],
              [-39.918810035085258, -8.213658940646951],
              [-39.918810042945253, -8.213658864878449],
              [-39.918810051445462, -8.213658789178577],
              [-39.918810060585315, -8.213658713552718],
              [-39.918810070364138, -8.213658638006212],
              [-39.918810080781249, -8.213658562544452],
              [-39.918810091835887, -8.213658487172783],
              [-39.918810103527299, -8.213658411896542],
              [-39.91881011585464, -8.213658336721053],
              [-39.918810128817029, -8.213658261651668],
              [-39.918810142413562, -8.213658186693703],
              [-39.918810156643275, -8.213658111852489],
              [-39.918810171505136, -8.213658037133332],
              [-39.918810186998115, -8.213657962541507],
              [-39.918810203121105, -8.213657888082341],
              [-39.918810219872952, -8.213657813761076],
              [-39.918810237252487, -8.213657739583038],
              [-39.918810255258464, -8.213657665553439],
              [-39.918810273889612, -8.213657591677547],
              [-39.918810293144595, -8.213657517960611],
              [-39.918810313022071, -8.213657444407835],
              [-39.918810333520618, -8.213657371024468],
              [-39.918810354638772, -8.213657297815679],
              [-39.918810376375042, -8.213657224786676],
              [-39.918810398727899, -8.213657151942675],
              [-39.918810421695753, -8.213657079288794],
              [-39.918810445276961, -8.213657006830193],
              [-39.918810469469861, -8.213656934572032],
              [-39.918810494272741, -8.213656862519425],
              [-39.918810519683817, -8.21365679067749],
              [-39.918810545701326, -8.213656719051318],
              [-39.918810572323395, -8.213656647645989],
              [-39.918810599548138, -8.213656576466574],
              [-39.918810627373631, -8.213656505518117],
              [-39.918810655797905, -8.213656434805641],
              [-39.918810684818929, -8.213656364334172],
              [-39.918810714434663, -8.213656294108704],
              [-39.91881074464299, -8.21365622413424],
              [-39.918810775441749, -8.213656154415707],
              [-39.918810806828802, -8.213656084958078],
              [-39.918810838801889, -8.213656015766245],
              [-39.918810871358772, -8.213655946845167],
              [-39.918810904497114, -8.213655878199679],
              [-39.918810938214556, -8.213655809834695],
              [-39.918810972508751, -8.213655741755034],
              [-39.918811007377215, -8.213655673965556],
              [-39.918811042817509, -8.213655606471049],
              [-39.918811078827105, -8.213655539276324],
              [-39.91881111540345, -8.213655472386098],
              [-39.91881115254396, -8.213655405805149],
              [-39.918811190245997, -8.213655339538199],
              [-39.918811228506868, -8.213655273589939],
              [-39.918811267323875, -8.213655207965067],
              [-39.918811306694273, -8.213655142668181],
              [-39.91881134661525, -8.213655077703985],
              [-39.918811387083991, -8.213655013077046],
              [-39.918811428097612, -8.213654948791961],
              [-39.918811469653221, -8.213654884853263],
              [-39.918811511747855, -8.213654821265523],
              [-39.918811554378536, -8.213654758033236],
              [-39.918811597542216, -8.213654695160898],
              [-39.918811641235884, -8.213654632652952],
              [-39.918811685456383, -8.213654570513851],
              [-39.91881173020063, -8.213654508747961],
              [-39.918811775465407, -8.213654447359731],
              [-39.918811821247537, -8.213654386353456],
              [-39.918811867543738, -8.21365432573349],
              [-39.918811914350769, -8.213654265504138],
              [-39.918811961665277, -8.21365420566965],
              [-39.918812009483915, -8.213654146234287],
              [-39.918812057803294, -8.21365408720226],
              [-39.918812106619988, -8.213654028577755],
              [-39.918812155930532, -8.213653970364948],
              [-39.918812205731413, -8.213653912567947],
              [-39.918812256019123, -8.213653855190852],
              [-39.918812306790088, -8.213653798237747],
              [-39.918812358040682, -8.213653741712646],
              [-39.918812409767327, -8.213653685619594],
              [-39.91881246196629, -8.213653629962527],
              [-39.918812514633906, -8.213653574745445],
              [-39.918812567766437, -8.213653519972215],
              [-39.91881262136009, -8.213653465646738],
              [-39.918812675411097, -8.213653411772878],
              [-39.918812729915608, -8.213653358354453],
              [-39.918812784869743, -8.21365330539523],
              [-39.918812840269631, -8.213653252899022],
              [-39.918812896111326, -8.213653200869508],
              [-39.918812952391221, -8.213653149309556],
              [-39.918812935183382, -8.213653149727072],
              [-39.920053143164523, -8.212526553919581],
              [-39.920053686601044, -8.212526010015363],
              [-39.921520928182744, -8.211398077538886],
              [-39.921520969488434, -8.211398027693686],
              [-39.921521017155001, -8.21139799129732],
              [-39.921521065062208, -8.211397955215913],
              [-39.921521113207938, -8.211397919451032],
              [-39.921521161590107, -8.211397884004256],
              [-39.921521210206585, -8.211397848877144],
              [-39.921521259055254, -8.211397814071232],
              [-39.921521308133954, -8.211397779588024],
              [-39.921521357440561, -8.211397745429029],
              [-39.921521406972929, -8.211397711595744],
              [-39.92152145672884, -8.211397678089678],
              [-39.921521506706164, -8.211397644912303],
              [-39.92152155690269, -8.211397612065015],
              [-39.921521607316222, -8.21139757954929],
              [-39.921521657944567, -8.211397547366531],
              [-39.921521708785498, -8.211397515518177],
              [-39.921521759836793, -8.211397484005611],
              [-39.92152181109622, -8.211397452830198],
              [-39.921521862561534, -8.211397421993286],
              [-39.921521914230496, -8.211397391496284],
              [-39.921521966100819, -8.21139736134049],
              [-39.921522018170229, -8.211397331527209],
              [-39.921522070436488, -8.211397302057755],
              [-39.921522122897272, -8.211397272933425],
              [-39.921522175550294, -8.211397244155503],
              [-39.921522228393272, -8.211397215725222],
              [-39.921522281423833, -8.211397187643863],
              [-39.921522334639739, -8.211397159912632],
              [-39.921522388038596, -8.211397132532719],
              [-39.921522441618116, -8.211397105505373],
              [-39.921522495375889, -8.211397078831748],
              [-39.921522549309636, -8.211397052513009],
              [-39.921522603416939, -8.211397026550342],
              [-39.921522657695469, -8.211397000944832],
              [-39.921522712142838, -8.21139697569763],
              [-39.921522766756638, -8.211396950809817],
              [-39.921522821534516, -8.211396926282511],
              [-39.921522876474057, -8.211396902116766],
              [-39.921522931572852, -8.211396878313657],
              [-39.921522986828521, -8.211396854874225],
              [-39.921523042238597, -8.211396831799469],
              [-39.921523097800694, -8.211396809090415],
              [-39.921523153512354, -8.211396786748072],
              [-39.921523209371152, -8.211396764773397],
              [-39.921523265374653, -8.211396743167365],
              [-39.921523321520382, -8.211396721930917],
              [-39.921523377805897, -8.211396701064988],
              [-39.921523434228739, -8.211396680570461],
              [-39.921523490786434, -8.211396660448251],
              [-39.921523547476504, -8.211396640699254],
              [-39.921523604296453, -8.21139662132434],
              [-39.921523661243839, -8.211396602324335],
              [-39.921523718316131, -8.211396583700068],
              [-39.921523775510828, -8.21139656545237],
              [-39.92152383282545, -8.211396547582032],
              [-39.921523890257482, -8.211396530089832],
              [-39.921523947804396, -8.211396512976547],
              [-39.921524005463702, -8.211396496242923],
              [-39.921524063232845, -8.211396479889675],
              [-39.921524121109321, -8.211396463917531],
              [-39.921524179090575, -8.211396448327195],
              [-39.921524237174104, -8.211396433119354],
              [-39.921524295357315, -8.211396418294678],
              [-39.921524353637714, -8.211396403853788],
              [-39.921524412012715, -8.211396389797319],
              [-39.921524470479767, -8.211396376125897],
              [-39.921524529036333, -8.211396362840125],
              [-39.921524587679848, -8.211396349940593],
              [-39.921524646407711, -8.211396337427843],
              [-39.921524705217379, -8.211396325302424],
              [-39.921524764106287, -8.211396313564881],
              [-39.921524823071849, -8.211396302215718],
              [-39.921524882111463, -8.211396291255443],
              [-39.921524941222557, -8.211396280684509],
              [-39.921525000402575, -8.211396270503426],
              [-39.921525059648886, -8.21139626071259],
              [-39.921525118958904, -8.211396251312458],
              [-39.921525178330057, -8.211396242303431],
              [-39.921525237759731, -8.21139623368591],
              [-39.921525297245324, -8.211396225460298],
              [-39.921525356784237, -8.211396217626897],
              [-39.921525416373854, -8.211396210186082],
              [-39.921525476011581, -8.211396203138188],
              [-39.921525535694784, -8.211396196483484],
              [-39.921525595420903, -8.211396190222299],
              [-39.921525655187274, -8.211396184354903],
              [-39.92152571499129, -8.211396178881529],
              [-39.921525774830343, -8.211396173802447],
              [-39.921525834701818, -8.211396169117865],
              [-39.921525894603086, -8.211396164827997],
              [-39.921525954531532, -8.211396160933026],
              [-39.92152601448452, -8.211396157433098],
              [-39.921526074459443, -8.211396154328407],
              [-39.921526134453671, -8.211396151619054],
              [-39.921526194464569, -8.211396149305179],
              [-39.921526254489542, -8.211396147386859],
              [-39.921526314525913, -8.211396145864219],
              [-39.921526374571087, -8.211396144737282],
              [-39.921526434622443, -8.211396144006136],
              [-39.921526494677686, -8.211396143669623],
              [-39.921526461426168, -8.211396165557762],
              [-39.922656136306038, -8.211393578474354],
              [-39.924240349469507, -8.211389945108237],
              [-39.924240422693323, -8.211389923128097],
              [-39.924240454626023, -8.21138992311074],
              [-39.924240486558602, -8.211389923205292],
              [-39.924240518490642, -8.211389923411758],
              [-39.924240550421757, -8.211389923730136],
              [-39.924240582351544, -8.211389924160413],
              [-39.924240614279626, -8.211389924702603],
              [-39.924240646205583, -8.211389925356638],
              [-39.924240678129038, -8.211389926122557],
              [-39.924240710049588, -8.211389927000376],
              [-39.924240741966855, -8.211389927990048],
              [-39.924240773880413, -8.211389929091551],
              [-39.924240805789893, -8.211389930304909],
              [-39.924240837694889, -8.211389931630052],
              [-39.924240869595018, -8.211389933067034],
              [-39.924240901489874, -8.211389934615784],
              [-39.92424093337906, -8.211389936276312],
              [-39.924240965262179, -8.211389938048553],
              [-39.924240997138845, -8.211389939932538],
              [-39.924241029008662, -8.211389941928216],
              [-39.924241060871239, -8.211389944035581],
              [-39.924241092726177, -8.211389946254588],
              [-39.924241124573093, -8.211389948585222],
              [-39.92424115641159, -8.211389951027435],
              [-39.92424118824124, -8.21138995358122],
              [-39.924241220061703, -8.211389956246519],
              [-39.924241251872537, -8.211389959023325],
              [-39.924241283673375, -8.211389961911594],
              [-39.924241315463831, -8.211389964911294],
              [-39.924241347243481, -8.211389968022392],
              [-39.92424137901196, -8.211389971244833],
              [-39.924241410768879, -8.211389974578587],
              [-39.924241442513818, -8.211389978023622],
              [-39.924241474246386, -8.211389981579874],
              [-39.924241505966222, -8.211389985247305],
              [-39.924241537672891, -8.211389989025889],
              [-39.924241569366046, -8.211389992915567],
              [-39.924241601045253, -8.211389996916278],
              [-39.924241632710157, -8.211390001027972],
              [-39.924241664360338, -8.211390005250621],
              [-39.924241695995413, -8.21139000958417],
              [-39.924241727614998, -8.211390014028531],
              [-39.924241759218688, -8.211390018583696],
              [-39.9242417908061, -8.21139002324958],
              [-39.92424182237685, -8.21139002802615],
              [-39.924241853930532, -8.211390032913338],
              [-39.92424188546677, -8.211390037911041],
              [-39.92424191698516, -8.211390043019248],
              [-39.924241948485324, -8.211390048237861],
              [-39.924241979966872, -8.211390053566825],
              [-39.924242011429413, -8.211390059006124],
              [-39.92424204287255, -8.211390064555614],
              [-39.924242074295897, -8.211390070215286],
              [-39.924242105699058, -8.211390075985042],
              [-39.92424213708167, -8.211390081864806],
              [-39.924242168443314, -8.211390087854507],
              [-39.924242199783635, -8.211390093954078],
              [-39.92424223110222, -8.211390100163454],
              [-39.924242262398678, -8.211390106482533],
              [-39.924242293672634, -8.211390112911248],
              [-39.924242324923704, -8.211390119449534],
              [-39.924242356151495, -8.211390126097292],
              [-39.924242387355619, -8.211390132854469],
              [-39.924242418535691, -8.211390139720949],
              [-39.924242449691327, -8.211390146696637],
              [-39.924242480822144, -8.21139015378148],
              [-39.924242511927758, -8.211390160975373],
              [-39.924242543007786, -8.211390168278237],
              [-39.924242574061815, -8.211390175689953],
              [-39.924242605089511, -8.211390183210474],
              [-39.92424263609044, -8.211390190839683],
              [-39.924242667064256, -8.211390198577478],
              [-39.924242698010545, -8.211390206423792],
              [-39.924242728928952, -8.211390214378479],
              [-39.92424275981908, -8.211390222441461],
              [-39.924242790680552, -8.211390230612652],
              [-39.924242821512976, -8.211390238891962],
              [-39.924242852315984, -8.211390247279255],
              [-39.924242883089192, -8.211390255774454],
              [-39.924242913832202, -8.211390264377467],
              [-39.924242944544659, -8.211390273088169],
              [-39.924242975226157, -8.211390281906434],
              [-39.924243005876342, -8.211390290832153],
              [-39.924243036494822, -8.211390299865252],
              [-39.924243067081221, -8.211390309005589],
              [-39.924243097635156, -8.211390318253024],
              [-39.924243128156256, -8.211390327607544],
              [-39.924243158644131, -8.211390337068908],
              [-39.924243189098419, -8.21139034663709],
              [-39.924243219518743, -8.211390356311924],
              [-39.924243249904706, -8.211390366093299],
              [-39.924243280255965, -8.211390375981111],
              [-39.924243310572116, -8.211390385975218],
              [-39.92424334085279, -8.211390396075519],
              [-39.92424337109761, -8.211390406281867],
              [-39.924243401306214, -8.211390416594144],
              [-39.924243431478224, -8.211390427012235],
              [-39.924243461613266, -8.211390437535986],
              [-39.924243491710953, -8.2113904481653],
              [-39.924243521770954, -8.211390458899817],
              [-39.924243528046567, -8.211390480473593],
              [-39.925487809947526, -8.211837307819421],
              [-39.925487851095674, -8.211837280317456],
              [-39.925487876489413, -8.211837289478487],
              [-39.925487901855689, -8.21183729871475],
              [-39.925487927194268, -8.211837308026146],
              [-39.925487952504923, -8.211837317412588],
              [-39.925487977787462, -8.211837326874061],
              [-39.925488003041615, -8.211837336410376],
              [-39.925488028267189, -8.211837346021511],
              [-39.925488053463944, -8.211837355707356],
              [-39.925488078631673, -8.211837365467861],
              [-39.925488103770128, -8.211837375302895],
              [-39.925488128879117, -8.211837385212426],
              [-39.925488153958398, -8.211837395196318],
              [-39.925488179007765, -8.211837405254517],
              [-39.925488204026983, -8.211837415386915],
              [-39.925488229015826, -8.211837425593382],
              [-39.925488253974088, -8.211837435873898],
              [-39.92548827890154, -8.211837446228353],
              [-39.92548830379797, -8.211837456656648],
              [-39.925488328663143, -8.211837467158672],
              [-39.925488353496846, -8.211837477734349],
              [-39.925488378298851, -8.211837488383603],
              [-39.925488403068954, -8.211837499106307],
              [-39.925488427806926, -8.211837509902372],
              [-39.925488452512568, -8.211837520771722],
              [-39.925488477185624, -8.211837531714263],
              [-39.925488501825903, -8.211837542729858],
              [-39.925488526433163, -8.211837553818439],
              [-39.925488551007213, -8.211837564979888],
              [-39.925488575547831, -8.21183757621414],
              [-39.925488600054791, -8.211837587521082],
              [-39.92548862452788, -8.211837598900635],
              [-39.925488648966883, -8.21183761035266],
              [-39.925488673371568, -8.211837621877031],
              [-39.925488697741741, -8.211837633473703],
              [-39.925488722077176, -8.211837645142579],
              [-39.92548874637766, -8.211837656883496],
              [-39.925488770642971, -8.211837668696418],
              [-39.925488794872912, -8.211837680581199],
              [-39.92548881906724, -8.211837692537742],
              [-39.925488843225779, -8.21183770456595],
              [-39.925488867348285, -8.211837716665737],
              [-39.92548889143454, -8.211837728836931],
              [-39.925488915484365, -8.211837741079457],
              [-39.925488939497498, -8.211837753393221],
              [-39.925488963473789, -8.211837765778116],
              [-39.925488987412976, -8.211837778234003],
              [-39.925489011314852, -8.211837790760795],
              [-39.925489035179233, -8.211837803358392],
              [-39.925489059005876, -8.21183781602665],
              [-39.925489082794599, -8.211837828765477],
              [-39.925489106545172, -8.211837841574727],
              [-39.925489130257375, -8.211837854454348],
              [-39.925489153931039, -8.211837867404171],
              [-39.925489177565922, -8.211837880424131],
              [-39.925489201161803, -8.211837893514078],
              [-39.925489224718504, -8.211837906673891],
              [-39.925489248235806, -8.211837919903436],
              [-39.925489271713502, -8.211837933202684],
              [-39.92548929515138, -8.211837946571428],
              [-39.925489318549225, -8.211837960009589],
              [-39.925489341906854, -8.211837973517046],
              [-39.925489365224024, -8.211837987093665],
              [-39.925489388500566, -8.211838000739348],
              [-39.925489411736251, -8.211838014453946],
              [-39.925489434930881, -8.211838028237368],
              [-39.92548945808425, -8.211838042089461],
              [-39.925489481196166, -8.211838056010119],
              [-39.925489504266395, -8.211838069999235],
              [-39.925489527294751, -8.211838084056643],
              [-39.925489550281029, -8.211838098182252],
              [-39.925489573225029, -8.211838112375936],
              [-39.925489596126539, -8.211838126637563],
              [-39.925489618985367, -8.211838140966984],
              [-39.925489641801299, -8.211838155364115],
              [-39.925489664574144, -8.211838169828813],
              [-39.925489687303688, -8.211838184360928],
              [-39.925489709989733, -8.211838198960361],
              [-39.925489732632094, -8.21183821362696],
              [-39.925489755230565, -8.211838228360605],
              [-39.925489777784925, -8.211838243161145],
              [-39.925489800294997, -8.211838258028505],
              [-39.925489822760568, -8.211838272962506],
              [-39.925489845181446, -8.211838287963037],
              [-39.925489867557438, -8.211838303029928],
              [-39.92548988988834, -8.211838318163096],
              [-39.925489912173937, -8.211838333362369],
              [-39.92548993441406, -8.211838348627635],
              [-39.925489956608516, -8.211838363958757],
              [-39.925489978757078, -8.211838379355582],
              [-39.925490000859568, -8.211838394817981],
              [-39.925490022915781, -8.211838410345848],
              [-39.925490044925539, -8.211838425939003],
              [-39.925490066888635, -8.211838441597342],
              [-39.925490088804885, -8.21183845732066],
              [-39.925490110674069, -8.211838473108898],
              [-39.925490132496016, -8.211838488961883],
              [-39.925490154270534, -8.211838504879484],
              [-39.925490175997403, -8.211838520861544],
              [-39.925490197676446, -8.211838536908054],
              [-39.925490173178119, -8.211838568597202],
              [-39.926393366490657, -8.212509210577],
              [-39.926393398543858, -8.212509144554625],
              [-39.926393464002757, -8.21250919270325],
              [-39.926393529892174, -8.212509240265437],
              [-39.926393596206836, -8.212509287237298],
              [-39.926393662941415, -8.212509333615147],
              [-39.926393730090524, -8.212509379395241],
              [-39.926393797648814, -8.212509424573875],
              [-39.926393865610819, -8.212509469147417],
              [-39.926393933971092, -8.212509513112328],
              [-39.926394002724138, -8.212509556465031],
              [-39.926394071864451, -8.212509599202084],
              [-39.926394141386453, -8.212509641320016],
              [-39.92639421128456, -8.212509682815471],
              [-39.926394281553179, -8.212509723685102],
              [-39.926394352186655, -8.212509763925631],
              [-39.92639442317931, -8.212509803533809],
              [-39.926394494525418, -8.212509842506474],
              [-39.926394566219294, -8.212509880840516],
              [-39.92639463825514, -8.212509918532769],
              [-39.9263947106272, -8.212509955580314],
              [-39.926394783329634, -8.212509991980095],
              [-39.926394856356616, -8.212510027729232],
              [-39.926394929702262, -8.212510062824839],
              [-39.92639500336071, -8.212510097264085],
              [-39.926395077326013, -8.212510131044217],
              [-39.92639515159226, -8.212510164162557],
              [-39.926395226153446, -8.212510196616348],
              [-39.926395301003623, -8.212510228403072],
              [-39.926395376136739, -8.212510259520139],
              [-39.926395451546782, -8.212510289965058],
              [-39.92639552722769, -8.212510319735397],
              [-39.926395603173368, -8.212510348828713],
              [-39.926395679377755, -8.212510377242744],
              [-39.926395755834683, -8.212510404975143],
              [-39.926395832538034, -8.212510432023707],
              [-39.926395909481641, -8.212510458386259],
              [-39.92639598665933, -8.212510484060703],
              [-39.926396064064875, -8.212510509044913],
              [-39.926396141692095, -8.212510533336955],
              [-39.926396219534737, -8.21251055693487],
              [-39.926396297586535, -8.212510579836701],
              [-39.926396375841236, -8.212510602040659],
              [-39.926396454292551, -8.212510623544953],
              [-39.926396532934177, -8.212510644347887],
              [-39.926396611759777, -8.2125106644477],
              [-39.926396690763049, -8.212510683842858],
              [-39.926396769937625, -8.212510702531779],
              [-39.926396849277161, -8.212510720512974],
              [-39.926396928775269, -8.212510737784962],
              [-39.926397008425567, -8.212510754346393],
              [-39.926397088221663, -8.212510770195911],
              [-39.926397168157138, -8.212510785332256],
              [-39.926397248225577, -8.212510799754211],
              [-39.926397328420535, -8.21251081346063],
              [-39.926397408735582, -8.21251082645038],
              [-39.926397489164273, -8.212510838722469],
              [-39.926397569700129, -8.212510850275848],
              [-39.926397650336689, -8.212510861109605],
              [-39.926397731067468, -8.212510871222911],
              [-39.926397811885991, -8.212510880614907],
              [-39.926397892785765, -8.212510889284864],
              [-39.926397973760281, -8.212510897232082],
              [-39.926398054803045, -8.212510904455909],
              [-39.92639813590754, -8.212510910955787],
              [-39.926398217067259, -8.21251091673118],
              [-39.926398298275664, -8.212510921781631],
              [-39.92639837952624, -8.212510926106683],
              [-39.926398460812479, -8.212510929706079],
              [-39.926398542127821, -8.212510932579466],
              [-39.926398623465751, -8.212510934726639],
              [-39.926398704819739, -8.212510936147423],
              [-39.926398786183228, -8.212510936841706],
              [-39.926398867549693, -8.212510936809402],
              [-39.926398948912599, -8.212510936050569],
              [-39.926399030265422, -8.212510934565227],
              [-39.926399111601611, -8.212510932353492],
              [-39.92639919291463, -8.212510929415563],
              [-39.926399274197955, -8.212510925751678],
              [-39.926399355445056, -8.212510921362099],
              [-39.926399436649405, -8.212510916247199],
              [-39.926399517804477, -8.212510910407412],
              [-39.92639959890375, -8.212510903843189],
              [-39.926399679940708, -8.21251089655504],
              [-39.926399760908843, -8.212510888543564],
              [-39.926399841801654, -8.212510879809416],
              [-39.926399922612646, -8.21251087035329],
              [-39.926400003335324, -8.212510860175939],
              [-39.926400083963188, -8.212510849278168],
              [-39.926400164489792, -8.212510837660886],
              [-39.926400244908628, -8.212510825325003],
              [-39.926400325213272, -8.212510812271493],
              [-39.926400405397253, -8.212510798501446],
              [-39.926400485454131, -8.212510784015956],
              [-39.926400565377477, -8.212510768816166],
              [-39.926400645160875, -8.212510752903313],
              [-39.926400724797901, -8.212510736278677],
              [-39.926400804282174, -8.212510718943582],
              [-39.926400883607307, -8.212510700899438],
              [-39.926400962766913, -8.21251068214767],
              [-39.926401041754659, -8.212510662689809],
              [-39.92640099543523, -8.212510640118747],
              [-39.927754300491287, -8.212170892949105],
              [-39.927754343384933, -8.212170868853898],
              [-39.927754365138256, -8.212170863420365],
              [-39.927754386904994, -8.212170858040421],
              [-39.92775440868499, -8.212170852714037],
              [-39.927754430478132, -8.212170847441278],
              [-39.92775445228429, -8.212170842222179],
              [-39.927754474103303, -8.212170837056773],
              [-39.927754495935062, -8.212170831945077],
              [-39.927754517779441, -8.212170826887123],
              [-39.927754539636304, -8.21217082188295],
              [-39.927754561505488, -8.21217081693259],
              [-39.927754583386886, -8.212170812036044],
              [-39.927754605280363, -8.212170807193411],
              [-39.927754627185777, -8.212170802404657],
              [-39.927754649103001, -8.212170797669819],
              [-39.927754671031906, -8.212170792988964],
              [-39.927754692972343, -8.212170788362089],
              [-39.927754714924184, -8.212170783789215],
              [-39.927754736887294, -8.212170779270396],
              [-39.927754758861553, -8.212170774805639],
              [-39.927754780846811, -8.212170770394987],
              [-39.927754802842934, -8.212170766038456],
              [-39.927754824849799, -8.21217076173606],
              [-39.927754846867266, -8.212170757487849],
              [-39.9277548688952, -8.21217075329386],
              [-39.927754890933464, -8.212170749154096],
              [-39.927754912981918, -8.212170745068564],
              [-39.927754935040447, -8.212170741037308],
              [-39.927754957108888, -8.212170737060363],
              [-39.927754979187142, -8.21217073313775],
              [-39.927755001275045, -8.212170729269484],
              [-39.927755023372463, -8.212170725455591],
              [-39.927755045479294, -8.212170721696104],
              [-39.927755067595378, -8.212170717991032],
              [-39.92775508972057, -8.212170714340417],
              [-39.927755111854736, -8.212170710744264],
              [-39.927755133997771, -8.212170707202585],
              [-39.927755156149509, -8.212170703715424],
              [-39.927755178309837, -8.212170700282787],
              [-39.927755200478607, -8.212170696904693],
              [-39.927755222655676, -8.212170693581198],
              [-39.927755244840924, -8.21217069031227],
              [-39.927755267034215, -8.212170687097974],
              [-39.9277552892354, -8.212170683938304],
              [-39.927755311444358, -8.212170680833283],
              [-39.927755333660933, -8.212170677782947],
              [-39.927755355885019, -8.212170674787275],
              [-39.927755378116466, -8.212170671846321],
              [-39.927755400355139, -8.2121706689601],
              [-39.92775542260091, -8.212170666128612],
              [-39.927755444853609, -8.212170663351896],
              [-39.927755467113137, -8.212170660629949],
              [-39.927755489379351, -8.212170657962799],
              [-39.927755511652101, -8.212170655350457],
              [-39.927755533931268, -8.212170652792951],
              [-39.927755556216688, -8.212170650290298],
              [-39.927755578508275, -8.212170647842466],
              [-39.927755600805853, -8.212170645449525],
              [-39.927755623109299, -8.21217064311147],
              [-39.927755645418472, -8.212170640828287],
              [-39.927755667733244, -8.212170638600046],
              [-39.927755690053459, -8.212170636426727],
              [-39.927755712379003, -8.212170634308348],
              [-39.927755734709727, -8.212170632244911],
              [-39.927755757045503, -8.212170630236463],
              [-39.927755779386196, -8.212170628283006],
              [-39.927755801731664, -8.212170626384532],
              [-39.927755824081764, -8.212170624541029],
              [-39.927755846436384, -8.212170622752568],
              [-39.927755868795344, -8.212170621019135],
              [-39.92775589115854, -8.21217061934073],
              [-39.92775591352585, -8.212170617717353],
              [-39.927755935897103, -8.212170616149054],
              [-39.927755958272186, -8.212170614635816],
              [-39.927755980650929, -8.212170613177642],
              [-39.927756003033238, -8.212170611774562],
              [-39.927756025418944, -8.212170610426565],
              [-39.927756047807954, -8.212170609133679],
              [-39.927756070200076, -8.212170607895892],
              [-39.927756092595203, -8.212170606713219],
              [-39.927756114993194, -8.212170605585674],
              [-39.927756137393921, -8.212170604513267],
              [-39.927756159797234, -8.21217060349599],
              [-39.927756182203005, -8.212170602533844],
              [-39.927756204611086, -8.212170601626845],
              [-39.927756227021348, -8.212170600774998],
              [-39.927756249433664, -8.212170599978352],
              [-39.927756271847869, -8.212170599236822],
              [-39.927756294263865, -8.212170598550481],
              [-39.927756316681474, -8.212170597919322],
              [-39.927756339100597, -8.212170597343329],
              [-39.92775636152107, -8.212170596822506],
              [-39.927756383942764, -8.212170596356872],
              [-39.927756406365553, -8.212170595946422],
              [-39.927756428789273, -8.212170595591155],
              [-39.927756451213831, -8.212170595291095],
              [-39.927756473639057, -8.212170595046226],
              [-39.927756496064816, -8.212170594856554],
              [-39.927756518490973, -8.212170594722075],
              [-39.927756540917301, -8.212170594643709],
              [-39.927756478598596, -8.212170616599352],
              [-39.929672949807767, -8.21216619572691],
              [-39.929672941637527, -8.21216617393374],
              [-39.929673075508333, -8.212166174608036],
              [-39.929673209354597, -8.212166177249063],
              [-39.929673343147222, -8.212166181856164],
              [-39.929673476857104, -8.212166188428398],
              [-39.929673610455168, -8.212166196964329],
              [-39.929673743912367, -8.212166207462078],
              [-39.929673877199662, -8.212166219919357],
              [-39.929674010288082, -8.212166234333461],
              [-39.929674143148681, -8.21216625070131],
              [-39.929674275752582, -8.212166269019296],
              [-39.929674408070909, -8.212166289283422],
              [-39.92967454007492, -8.212166311489307],
              [-39.929674671735889, -8.212166335632137],
              [-39.929674803025208, -8.212166361706618],
              [-39.92967493391432, -8.212166389707106],
              [-39.929675064374727, -8.212166419627527],
              [-39.929675194378099, -8.212166451461339],
              [-39.929675323896149, -8.212166485201626],
              [-39.929675452900717, -8.212166520841077],
              [-39.929675581363732, -8.212166558371914],
              [-39.929675709257268, -8.212166597786013],
              [-39.929675836553528, -8.212166639074747],
              [-39.929675963224803, -8.212166682229176],
              [-39.929676089243557, -8.212166727239893],
              [-39.929676214582386, -8.212166774097145],
              [-39.929676339214048, -8.212166822790692],
              [-39.929676463111399, -8.212166873310009],
              [-39.929676586247538, -8.212166925644034],
              [-39.929676708595657, -8.212166979781465],
              [-39.929676830129175, -8.212167035710463],
              [-39.929676950821644, -8.212167093418893],
              [-39.929677070646818, -8.212167152894216],
              [-39.929677189578648, -8.212167214123452],
              [-39.929677307591263, -8.212167277093352],
              [-39.929677424659012, -8.21216734179017],
              [-39.929677540756416, -8.21216740819985],
              [-39.929677655858242, -8.212167476307972],
              [-39.929677769939445, -8.212167546099696],
              [-39.929677882975248, -8.212167617559871],
              [-39.929677994941024, -8.212167690672953],
              [-39.929678105812464, -8.212167765423025],
              [-39.929678215565445, -8.212167841793807],
              [-39.929678324176088, -8.212167919768744],
              [-39.929678431620772, -8.212167999330887],
              [-39.929678537876157, -8.212168080462872],
              [-39.929678642919107, -8.212168163147101],
              [-39.929678746726786, -8.212168247365534],
              [-39.929678849276634, -8.212168333099939],
              [-39.929678950546354, -8.212168420331638],
              [-39.929679050513897, -8.212168509041648],
              [-39.929679149157522, -8.212168599210678],
              [-39.929679246455805, -8.212168690819162],
              [-39.929679342387573, -8.212168783847114],
              [-39.929679436931956, -8.212168878274317],
              [-39.929679530068405, -8.212168974080285],
              [-39.929679621776671, -8.212169071244162],
              [-39.929679712036794, -8.212169169744783],
              [-39.929679800829142, -8.212169269560741],
              [-39.929679888134444, -8.212169370670361],
              [-39.929679973933659, -8.212169473051665],
              [-39.929680058208163, -8.212169576682314],
              [-39.929680140939624, -8.21216968153982],
              [-39.929680222110065, -8.212169787601384],
              [-39.929680301701801, -8.212169894843921],
              [-39.929680379697537, -8.212170003244113],
              [-39.929680456080312, -8.212170112778423],
              [-39.929680530833537, -8.21217022342298],
              [-39.929680603940923, -8.212170335153731],
              [-39.929680675386585, -8.212170447946423],
              [-39.92968074515499, -8.212170561776508],
              [-39.929680813230959, -8.212170676619197],
              [-39.929680879599687, -8.212170792449536],
              [-39.929680944246755, -8.212170909242355],
              [-39.929681007158088, -8.212171026972198],
              [-39.929681068320008, -8.2121711456135],
              [-39.929681127719221, -8.212171265140499],
              [-39.929681185342808, -8.212171385527165],
              [-39.929681241178223, -8.212171506747303],
              [-39.929681295213349, -8.212171628774577],
              [-39.929681347436421, -8.212171751582433],
              [-39.929681397836063, -8.212171875144167],
              [-39.929681446401361, -8.212171999432906],
              [-39.929681493121727, -8.212172124421645],
              [-39.929681537986987, -8.212172250083199],
              [-39.929681580987399, -8.212172376390217],
              [-39.929681622113613, -8.21217250331522],
              [-39.929681661356696, -8.21217263083064],
              [-39.929681698708094, -8.212172758908753],
              [-39.929681734159693, -8.212172887521673],
              [-39.929681767703777, -8.212173016641458],
              [-39.929681799333061, -8.212173146239993],
              [-39.929681829040668, -8.212173276289139],
              [-39.929681856820118, -8.212173406760579],
              [-39.929681882665392, -8.21217353762597],
              [-39.92968190657087, -8.212173668856838],
              [-39.92968192853133, -8.212173800424633],
              [-39.929681948542026, -8.212173932300772],
              [-39.929681966598586, -8.212174064456521],
              [-39.929681982697467, -8.212174196862779],
              [-39.929681955820492, -8.212174224399442],
              [-39.929796220384176, -8.213176164573877],
              [-39.929796285360403, -8.213176137906659],
              [-39.929796293355466, -8.213176210607083],
              [-39.929796300760202, -8.213176283369519],
              [-39.929796307574122, -8.213176356189214],
              [-39.929796313796771, -8.213176429061456],
              [-39.929796319427759, -8.213176501981422],
              [-39.9297963244667, -8.213176574944367],
              [-39.929796328913284, -8.213176647945529],
              [-39.929796332767197, -8.213176720980126],
              [-39.929796336028218, -8.213176794043372],
              [-39.929796338696107, -8.213176867130514],
              [-39.929796340770686, -8.213176940236762],
              [-39.929796342251841, -8.213177013357321],
              [-39.929796343139465, -8.213177086487438],
              [-39.929796343433502, -8.213177159622305],
              [-39.929796343133937, -8.21317723275714],
              [-39.92979634224077, -8.21317730588717],
              [-39.929796340754073, -8.213177379007643],
              [-39.92979633867396, -8.213177452113719],
              [-39.929796336000543, -8.213177525200663],
              [-39.929796332734, -8.213177598263689],
              [-39.929796328874559, -8.213177671297982],
              [-39.929796324422448, -8.213177744298804],
              [-39.929796319377978, -8.213177817261371],
              [-39.92979631374147, -8.213177890180917],
              [-39.929796307513307, -8.213177963052674],
              [-39.929796300693873, -8.213178035871868],
              [-39.929796293283637, -8.213178108633736],
              [-39.929796285283061, -8.213178181333557],
              [-39.929796276692691, -8.213178253966531],
              [-39.929796267513069, -8.213178326527938],
              [-39.929796257744812, -8.213178399012991],
              [-39.929796247388545, -8.21317847141699],
              [-39.92979623644495, -8.213178543735184],
              [-39.929796224914732, -8.21317861596285],
              [-39.929796212798671, -8.213178688095295],
              [-39.929796200097542, -8.213178760127755],
              [-39.929796186812183, -8.213178832055554],
              [-39.929796172943448, -8.213178903873931],
              [-39.929796158492252, -8.213178975578247],
              [-39.929796143459548, -8.213179047163804],
              [-39.929796127846309, -8.213179118625904],
              [-39.929796111653566, -8.213179189959879],
              [-39.929796094882363, -8.213179261161075],
              [-39.929796077533808, -8.213179332224842],
              [-39.929796059609032, -8.213179403146547],
              [-39.929796041109213, -8.213179473921498],
              [-39.929796022035539, -8.213179544545099],
              [-39.929796002389303, -8.213179615012724],
              [-39.929795982171726, -8.213179685319794],
              [-39.929795961384187, -8.213179755461676],
              [-39.929795940028008, -8.213179825433777],
              [-39.929795918104602, -8.213179895231574],
              [-39.929795895615399, -8.213179964850465],
              [-39.929795872561883, -8.213180034285903],
              [-39.929795848945531, -8.21318010353334],
              [-39.929795824767908, -8.213180172588254],
              [-39.929795800030597, -8.213180241446114],
              [-39.929795774735219, -8.21318031010245],
              [-39.929795748883421, -8.213180378552774],
              [-39.929795722476875, -8.213180446792572],
              [-39.929795695517342, -8.21318051481741],
              [-39.929795668006548, -8.213180582622835],
              [-39.929795639946327, -8.213180650204425],
              [-39.929795611338498, -8.213180717557734],
              [-39.929795582184937, -8.213180784678356],
              [-39.929795552487533, -8.21318085156194],
              [-39.929795522248241, -8.213180918204092],
              [-39.929795491469051, -8.213180984600456],
              [-39.929795460151936, -8.213181050746684],
              [-39.929795428299002, -8.213181116638449],
              [-39.929795395912279, -8.213181182271427],
              [-39.929795362993914, -8.213181247641367],
              [-39.929795329546053, -8.213181312743961],
              [-39.929795295570877, -8.213181377574985],
              [-39.929795261070609, -8.213181442130148],
              [-39.92979522604751, -8.213181506405272],
              [-39.92979519050386, -8.213181570396131],
              [-39.929795154442004, -8.213181634098552],
              [-39.92979511786428, -8.213181697508396],
              [-39.92979508077309, -8.213181760621483],
              [-39.929795043170856, -8.213181823433683],
              [-39.929795005060043, -8.2131818859409],
              [-39.929794966443119, -8.213181948139066],
              [-39.929794927322646, -8.21318201002406],
              [-39.929794887701163, -8.213182071591897],
              [-39.929794847581249, -8.213182132838517],
              [-39.929794806965532, -8.213182193759899],
              [-39.929794765856698, -8.2131822543521],
              [-39.929794724257398, -8.213182314611148],
              [-39.929794682170368, -8.213182374533082],
              [-39.92979463959837, -8.213182434114005],
              [-39.929794596544163, -8.213182493350018],
              [-39.929794553010581, -8.213182552237242],
              [-39.929794509000459, -8.213182610771826],
              [-39.929794464516689, -8.213182668949935],
              [-39.929794419562164, -8.213182726767771],
              [-39.929794374139824, -8.213182784221567],
              [-39.929794328252669, -8.213182841307541],
              [-39.929794281903483, -8.213182898022511],
              [-39.929794238989238, -8.213182952410131],
              [-39.92821863111579, -8.215095036832645],
              [-39.928218682703616, -8.215095008917473],
              [-39.928218621580072, -8.215095083908214],
              [-39.928218561263968, -8.215095159545108],
              [-39.928218501762203, -8.215095235819499],
              [-39.928218443081541, -8.215095312722696],
              [-39.928218385228696, -8.215095390245937],
              [-39.928218328210242, -8.215095468380367],
              [-39.928218272032709, -8.215095547117059],
              [-39.92821821670249, -8.215095626447042],
              [-39.928218162225896, -8.215095706361257],
              [-39.928218108609151, -8.215095786850599],
              [-39.92821805585838, -8.21509586790588],
              [-39.92821800397958, -8.215095949517879],
              [-39.928217952978692, -8.215096031677245],
              [-39.928217902861505, -8.215096114374631],
              [-39.928217853633761, -8.215096197600577],
              [-39.928217805301067, -8.215096281345616],
              [-39.928217757868936, -8.215096365600202],
              [-39.928217711342789, -8.215096450354686],
              [-39.928217665727914, -8.215096535599434],
              [-39.928217621029539, -8.215096621324689],
              [-39.928217577252759, -8.215096707520686],
              [-39.928217534402535, -8.21509679417761],
              [-39.928217492483796, -8.215096881285545],
              [-39.928217451501304, -8.215096968834569],
              [-39.928217411459748, -8.215097056814693],
              [-39.92821737236369, -8.215097145215884],
              [-39.928217334217564, -8.215097234028041],
              [-39.928217297025761, -8.215097323241045],
              [-39.928217260792508, -8.215097412844726],
              [-39.928217225521941, -8.215097502828856],
              [-39.928217191218067, -8.215097593183158],
              [-39.928217157884845, -8.215097683897319],
              [-39.928217125526004, -8.215097774961018],
              [-39.928217094145303, -8.215097866363852],
              [-39.928217063746303, -8.2150979580954],
              [-39.928217034332462, -8.215098050145182],
              [-39.928217005907136, -8.215098142502718],
              [-39.928216978473564, -8.215098235157447],
              [-39.928216952034894, -8.215098328098829],
              [-39.928216926594139, -8.215098421316215],
              [-39.928216902154183, -8.215098514799008],
              [-39.928216878717819, -8.215098608536548],
              [-39.92821685628774, -8.215098702518114],
              [-39.928216834866483, -8.215098796733013],
              [-39.928216814456498, -8.215098891170449],
              [-39.928216795060131, -8.215098985819708],
              [-39.928216776679562, -8.215099080669964],
              [-39.928216759316911, -8.215099175710392],
              [-39.928216742974165, -8.215099270930137],
              [-39.928216727653172, -8.215099366318382],
              [-39.928216713355674, -8.215099461864188],
              [-39.928216700083318, -8.215099557556705],
              [-39.928216687837619, -8.215099653384975],
              [-39.928216676619954, -8.215099749338096],
              [-39.928216666431624, -8.215099845405121],
              [-39.928216657273786, -8.215099941575073],
              [-39.92821664914748, -8.215100037836987],
              [-39.928216642053641, -8.215100134179869],
              [-39.928216635993067, -8.215100230592762],
              [-39.92821663096646, -8.215100327064627],
              [-39.92821662697439, -8.215100423584474],
              [-39.92821662401731, -8.215100520141284],
              [-39.928216622095562, -8.215100616724081],
              [-39.928216621209344, -8.215100713321785],
              [-39.928216621358793, -8.215100809923438],
              [-39.928216622543857, -8.215100906517984],
              [-39.928216624764424, -8.215101003094425],
              [-39.928216628020245, -8.215101099641709],
              [-39.928216632310935, -8.215101196148851],
              [-39.928216637636005, -8.215101292604826],
              [-39.928216643994851, -8.215101388998626],
              [-39.928216651386741, -8.21510148531927],
              [-39.928216659810836, -8.215101581555777],
              [-39.928216669266185, -8.215101677697136],
              [-39.928216679751692, -8.215101773732394],
              [-39.928216691266172, -8.21510186965061],
              [-39.928216703808324, -8.215101965440825],
              [-39.928216717376685, -8.21510206109212],
              [-39.928216731969719, -8.215102156593565],
              [-39.928216747585772, -8.215102251934292],
              [-39.928216764223066, -8.215102347103393],
              [-39.928216781879691, -8.215102442090055],
              [-39.928216800553635, -8.215102536883396],
              [-39.928216820242753, -8.215102631472616],
              [-39.92821684094482, -8.215102725846933],
              [-39.928216862657472, -8.215102819995584],
              [-39.928216885378227, -8.215102913907829],
              [-39.928216909104492, -8.215103007572944],
              [-39.928216933833561, -8.21510310098024],
              [-39.928216959562633, -8.215103194119054],
              [-39.928216986288739, -8.215103286978801],
              [-39.928217014008844, -8.215103379548863],
              [-39.92821704271978, -8.215103471818654],
              [-39.928217072418285, -8.215103563777664],
              [-39.92821710310097, -8.215103655415431],
              [-39.928217134764324, -8.215103746721459],
              [-39.928217167404753, -8.215103837685355],
              [-39.928217201018491, -8.21510392829674],
              [-39.928217235601743, -8.215104018545293],
              [-39.928217199629458, -8.215104084483588],
              [-39.928784615812141, -8.216561357386391],
              [-39.928784651705406, -8.216561337098591],
              [-39.92878466090697, -8.216561360633728],
              [-39.928784670174487, -8.216561384143168],
              [-39.928784679507856, -8.216561407626752],
              [-39.928784688907008, -8.216561431084239],
              [-39.928784698371885, -8.216561454515533],
              [-39.928784707902409, -8.21656147792036],
              [-39.928784717498502, -8.216561501298603],
              [-39.928784727160078, -8.216561524650043],
              [-39.928784736887081, -8.216561547974514],
              [-39.92878474667944, -8.216561571271846],
              [-39.928784756537048, -8.216561594541837],
              [-39.928784766459849, -8.216561617784304],
              [-39.928784776447756, -8.216561640999091],
              [-39.928784786500707, -8.216561664185985],
              [-39.928784796618608, -8.216561687344813],
              [-39.928784806801374, -8.216561710475409],
              [-39.928784817048964, -8.216561733577585],
              [-39.928784827361262, -8.21656175665116],
              [-39.928784837738199, -8.216561779695963],
              [-39.928784848179681, -8.216561802711794],
              [-39.928784858685646, -8.216561825698484],
              [-39.928784869256006, -8.216561848655866],
              [-39.928784879890678, -8.216561871583714],
              [-39.928784890589561, -8.216561894481918],
              [-39.928784901352607, -8.216561917350258],
              [-39.928784912179708, -8.21656194018855],
              [-39.928784923070772, -8.216561962996654],
              [-39.928784934025735, -8.216561985774371],
              [-39.928784945044505, -8.216562008521501],
              [-39.928784956126982, -8.216562031237876],
              [-39.92878496727311, -8.21656205392336],
              [-39.928784978482788, -8.216562076577729],
              [-39.928784989755918, -8.216562099200807],
              [-39.928785001092407, -8.216562121792451],
              [-39.928785012492192, -8.216562144352464],
              [-39.92878502395515, -8.216562166880678],
              [-39.928785035481248, -8.216562189376889],
              [-39.928785047070328, -8.216562211840982],
              [-39.928785058722355, -8.216562234272685],
              [-39.928785070437186, -8.216562256671933],
              [-39.92878508221478, -8.216562279038484],
              [-39.928785094055016, -8.216562301372198],
              [-39.928785105957814, -8.216562323672864],
              [-39.928785117923077, -8.216562345940334],
              [-39.928785129950704, -8.216562368174403],
              [-39.928785142040617, -8.216562390374957],
              [-39.928785154192717, -8.216562412541776],
              [-39.92878516640689, -8.21656243467471],
              [-39.928785178683064, -8.216562456773554],
              [-39.928785191021134, -8.216562478838155],
              [-39.928785203421, -8.216562500868356],
              [-39.92878521588257, -8.216562522863954],
              [-39.928785228405751, -8.216562544824814],
              [-39.92878524099045, -8.216562566750724],
              [-39.928785253636548, -8.216562588641558],
              [-39.928785266343965, -8.216562610497139],
              [-39.928785279112596, -8.216562632317252],
              [-39.92878529194234, -8.21656265410178],
              [-39.928785304833092, -8.216562675850502],
              [-39.928785317784772, -8.216562697563287],
              [-39.928785330797247, -8.216562719239947],
              [-39.928785343870445, -8.216562740880329],
              [-39.928785357004266, -8.216562762484235],
              [-39.928785370198568, -8.216562784051549],
              [-39.928785383453274, -8.216562805582047],
              [-39.928785396768284, -8.216562827075578],
              [-39.928785410143497, -8.21656284853198],
              [-39.928785423578795, -8.216562869951108],
              [-39.92878543707409, -8.216562891332751],
              [-39.928785450629256, -8.216562912676773],
              [-39.928785464244214, -8.216562933982994],
              [-39.928785477918815, -8.216562955251266],
              [-39.928785491653009, -8.216562976481381],
              [-39.928785505446648, -8.216562997673204],
              [-39.92878551929963, -8.216563018826564],
              [-39.928785533211851, -8.216563039941308],
              [-39.928785547183224, -8.216563061017272],
              [-39.928785561213616, -8.216563082054304],
              [-39.928785575302904, -8.216563103052197],
              [-39.928785589451017, -8.216563124010804],
              [-39.928785603657801, -8.216563144929957],
              [-39.928785617923182, -8.216563165809506],
              [-39.928785632247035, -8.216563186649278],
              [-39.928785646629244, -8.216563207449143],
              [-39.928785661069696, -8.216563228208896],
              [-39.928785675568292, -8.216563248928404],
              [-39.928785690124904, -8.21656326960748],
              [-39.928785704739433, -8.216563290245976],
              [-39.928785719411749, -8.216563310843735],
              [-39.928785734141748, -8.216563331400598],
              [-39.928785748929315, -8.21656335191639],
              [-39.928785763774329, -8.216563372390961],
              [-39.928785778676676, -8.21656339282414],
              [-39.928785793636244, -8.216563413215802],
              [-39.928785808652918, -8.216563433565732],
              [-39.928785823726564, -8.216563453873839],
              [-39.928785838857081, -8.216563474139917],
              [-39.928785854044342, -8.216563494363816],
              [-39.928785869288255, -8.216563514545369],
              [-39.928785801021846, -8.216563525294156],
              [-39.929465760295692, -8.21746105365825],
              [-39.929465801254345, -8.217461070250595],
              [-39.929465821221228, -8.217461096508574],
              [-39.929465841283978, -8.217461122693912],
              [-39.929465861442338, -8.217461148806283],
              [-39.929465881696018, -8.217461174845321],
              [-39.929465902044768, -8.217461200810689],
              [-39.929465922488312, -8.217461226702072],
              [-39.929465943026372, -8.217461252519076],
              [-39.929465963658672, -8.2174612782614],
              [-39.929465984384962, -8.217461303928664],
              [-39.929466005204958, -8.217461329520537],
              [-39.929466026118362, -8.217461355036709],
              [-39.929466047124919, -8.217461380476804],
              [-39.929466068224336, -8.21746140584051],
              [-39.929466089416366, -8.2174614311275],
              [-39.929466110700695, -8.217461456337389],
              [-39.929466132077032, -8.217461481469911],
              [-39.929466153545121, -8.217461506524694],
              [-39.929466175104665, -8.217461531501401],
              [-39.929466196755385, -8.217461556399734],
              [-39.929466218496977, -8.217461581219313],
              [-39.929466240329177, -8.21746160595983],
              [-39.92946626225168, -8.217461630620978],
              [-39.929466284264187, -8.217461655202413],
              [-39.929466306366422, -8.217461679703796],
              [-39.929466328558092, -8.217461704124817],
              [-39.929466350838894, -8.217461728465135],
              [-39.929466373208527, -8.217461752724462],
              [-39.929466395666715, -8.217461776902461],
              [-39.929466418213131, -8.217461800998789],
              [-39.929466440847506, -8.217461825013162],
              [-39.929466463569526, -8.217461848945234],
              [-39.929466486378892, -8.217461872794683],
              [-39.929466509275294, -8.217461896561211],
              [-39.929466532258417, -8.217461920244505],
              [-39.929466555327991, -8.217461943844226],
              [-39.929466578483691, -8.217461967360087],
              [-39.92946660172521, -8.217461990791755],
              [-39.929466625052243, -8.217462014138944],
              [-39.929466648464469, -8.217462037401333],
              [-39.929466671961578, -8.217462060578621],
              [-39.92946669554329, -8.217462083670473],
              [-39.929466719209245, -8.217462106676614],
              [-39.929466742959164, -8.217462129596733],
              [-39.929466766792721, -8.217462152430519],
              [-39.929466790709597, -8.217462175177641],
              [-39.929466814709464, -8.21746219783785],
              [-39.929466838792031, -8.217462220410807],
              [-39.929466862956964, -8.217462242896239],
              [-39.92946688720393, -8.217462265293831],
              [-39.929466911532629, -8.217462287603277],
              [-39.929466935942735, -8.217462309824313],
              [-39.929466960433913, -8.217462331956636],
              [-39.929466985005838, -8.217462353999904],
              [-39.92946700965819, -8.217462375953872],
              [-39.92946703439064, -8.217462397818249],
              [-39.929467059202864, -8.217462419592723],
              [-39.92946708409454, -8.217462441277034],
              [-39.929467109065314, -8.217462462870845],
              [-39.929467134114859, -8.217462484373929],
              [-39.929467159242868, -8.217462505785948],
              [-39.929467184448988, -8.217462527106662],
              [-39.929467209732891, -8.217462548335771],
              [-39.929467235094229, -8.217462569472975],
              [-39.929467260532689, -8.217462590518014],
              [-39.92946728604791, -8.217462611470614],
              [-39.929467311639577, -8.217462632330497],
              [-39.929467337307315, -8.217462653097348],
              [-39.929467363050811, -8.217462673770951],
              [-39.929467388869725, -8.217462694350999],
              [-39.929467414763707, -8.217462714837206],
              [-39.92946744073241, -8.217462735229326],
              [-39.929467466775499, -8.217462755527066],
              [-39.929467492892613, -8.217462775730178],
              [-39.929467519083424, -8.217462795838411],
              [-39.929467545347578, -8.217462815851446],
              [-39.929467571684704, -8.217462835769023],
              [-39.929467598094497, -8.217462855590936],
              [-39.929467624576567, -8.217462875316842],
              [-39.929467651130594, -8.217462894946557],
              [-39.9294676777562, -8.217462914479762],
              [-39.929467704453039, -8.217462933916222],
              [-39.929467731220761, -8.217462953255691],
              [-39.929467758059012, -8.217462972497904],
              [-39.929467784967443, -8.217462991642602],
              [-39.929467811945671, -8.21746301068953],
              [-39.929467838993361, -8.217463029638408],
              [-39.929467866110151, -8.217463048489044],
              [-39.929467893295673, -8.217463067241155],
              [-39.92946792054957, -8.217463085894492],
              [-39.929467947871487, -8.217463104448798],
              [-39.929467975261069, -8.217463122903842],
              [-39.929468002717904, -8.217463141259357],
              [-39.929468030241694, -8.21746315951513],
              [-39.929468057832032, -8.217463177670929],
              [-39.929468085488573, -8.217463195726467],
              [-39.92946811321093, -8.217463213681549],
              [-39.92946814099875, -8.217463231535895],
              [-39.929468168851649, -8.217463249289295],
              [-39.929468196769285, -8.217463266941504],
              [-39.929468125886977, -8.217463309279843],
              [-39.930701216634851, -8.218239809791678],
              [-39.93070126033367, -8.218239789686709],
              [-39.930701424542463, -8.218239890679392],
              [-39.930701590868921, -8.218239988171865],
              [-39.93070175923787, -8.218240082120044],
              [-39.930701929573239, -8.218240172481545],
              [-39.930702101798062, -8.218240259215458],
              [-39.930702275834491, -8.218240342282627],
              [-39.930702451603921, -8.218240421645518],
              [-39.930702629026889, -8.218240497268255],
              [-39.930702808023263, -8.218240569116688],
              [-39.930702988512117, -8.21824063715834],
              [-39.93070317041191, -8.218240701362449],
              [-39.930703353640467, -8.218240761700036],
              [-39.930703538114962, -8.218240818143807],
              [-39.930703723752053, -8.218240870668282],
              [-39.930703910467855, -8.218240919249691],
              [-39.930704098177998, -8.218240963866101],
              [-39.930704286797642, -8.218241004497365],
              [-39.930704476241573, -8.218241041125092],
              [-39.930704666424191, -8.218241073732752],
              [-39.930704857259556, -8.218241102305624],
              [-39.930705048661409, -8.218241126830764],
              [-39.930705240543304, -8.218241147297118],
              [-39.930705432818492, -8.218241163695435],
              [-39.930705625400115, -8.218241176018273],
              [-39.930705818201147, -8.218241184260098],
              [-39.930706011134454, -8.21824118841716],
              [-39.930706204112873, -8.218241188487616],
              [-39.9307063970492, -8.218241184471385],
              [-39.930706589856236, -8.218241176370336],
              [-39.930706782446876, -8.21824116418809],
              [-39.930706974734086, -8.218241147930156],
              [-39.930707166630967, -8.218241127603909],
              [-39.930707358050817, -8.218241103218498],
              [-39.930707548907151, -8.218241074784984],
              [-39.930707739113693, -8.218241042316174],
              [-39.930707928584518, -8.218241005826751],
              [-39.930708117234012, -8.218240965333221],
              [-39.930708304976903, -8.218240920853857],
              [-39.930708491728389, -8.218240872408787],
              [-39.930708677404063, -8.218240820019867],
              [-39.930708861920046, -8.21824076371081],
              [-39.93070904519292, -8.218240703507012],
              [-39.9307092271399, -8.21824063943572],
              [-39.930709407678769, -8.218240571525861],
              [-39.930709586727929, -8.218240499808131],
              [-39.930709764206483, -8.218240424314937],
              [-39.930709940034227, -8.218240345080416],
              [-39.93071011413172, -8.21824026214032],
              [-39.930710286420272, -8.21824017553215],
              [-39.930710456822077, -8.218240085295058],
              [-39.93071062526009, -8.218239991469792],
              [-39.930710791658214, -8.218239894098799],
              [-39.930710955941244, -8.218239793226031],
              [-39.930711118034971, -8.218239688897086],
              [-39.930711277866124, -8.218239581159093],
              [-39.930711435362518, -8.218239470060755],
              [-39.930711590452923, -8.218239355652276],
              [-39.930711743067299, -8.218239237985324],
              [-39.930711893136696, -8.218239117113098],
              [-39.930712040593278, -8.218238993090202],
              [-39.930712185370425, -8.218238865972703],
              [-39.930712327402702, -8.21823873581801],
              [-39.930712466625941, -8.218238602684968],
              [-39.930712602977223, -8.218238466633705],
              [-39.930712736394952, -8.218238327725713],
              [-39.930712866818816, -8.21823818602374],
              [-39.930712994189889, -8.218238041591874],
              [-39.930713118450626, -8.218237894495321],
              [-39.930713239544865, -8.218237744800577],
              [-39.930713357417893, -8.218237592575267],
              [-39.930713472016443, -8.218237437888197],
              [-39.930713583288728, -8.218237280809264],
              [-39.930713691184479, -8.21823712140946],
              [-39.930713795654938, -8.218236959760773],
              [-39.930713896652875, -8.218236795936285],
              [-39.930713994132702, -8.218236630010017],
              [-39.930714088050308, -8.218236462056916],
              [-39.930714178363289, -8.218236292152913],
              [-39.930714265030844, -8.218236120374746],
              [-39.930714348013787, -8.218235946800091],
              [-39.930714427274644, -8.218235771507331],
              [-39.930714502777562, -8.218235594575706],
              [-39.930714574488476, -8.218235416085138],
              [-39.930714642374937, -8.218235236116289],
              [-39.930714706406299, -8.218235054750499],
              [-39.930714766553599, -8.218234872069727],
              [-39.930714822789675, -8.218234688156496],
              [-39.930714875089123, -8.218234503093919],
              [-39.930714923428297, -8.218234316965622],
              [-39.930714967785356, -8.218234129855738],
              [-39.930715008140254, -8.21823394184878],
              [-39.930715044474766, -8.218233753029697],
              [-39.930715076772472, -8.218233563483853],
              [-39.930715105018756, -8.218233373296874],
              [-39.930715129200891, -8.218233182554698],
              [-39.930715149307929, -8.218232991343509],
              [-39.930715165330781, -8.218232799749742],
              [-39.930715177262201, -8.218232607859948],
              [-39.930715185096822, -8.218232415760832],
            ],
          ],
          [
            [
              [-39.951839742497029, -8.220781917028072],
              [-39.951839849235235, -8.220782039662414],
              [-39.95183995816533, -8.220782160368593],
              [-39.95184006925227, -8.220782279107766],
              [-39.951840182460245, -8.220782395841653],
              [-39.95184029775281, -8.220782510532665],
              [-39.951840415092825, -8.220782623143867],
              [-39.951840534442475, -8.220782733639002],
              [-39.951840655763348, -8.220782841982476],
              [-39.951840779016358, -8.220782948139382],
              [-39.951840904161799, -8.220783052075523],
              [-39.951841031159361, -8.220783153757395],
              [-39.95184115996814, -8.220783253152321],
              [-39.95184129054666, -8.220783350228212],
              [-39.95184142285283, -8.220783444953843],
              [-39.951841556844066, -8.22078353729872],
              [-39.951841692477203, -8.220783627233022],
              [-39.951841829708549, -8.220783714727864],
              [-39.951841968493902, -8.220783799754996],
              [-39.951842108788561, -8.220783882287082],
              [-39.951842250547344, -8.220783962297528],
              [-39.951842393724583, -8.220784039760533],
              [-39.951842538274157, -8.220784114651192],
              [-39.951842684149511, -8.220784186945354],
              [-39.951842831303672, -8.220784256619748],
              [-39.951842979689211, -8.220784323651904],
              [-39.951843129258371, -8.22078438802027],
              [-39.951843279962965, -8.220784449704077],
              [-39.951843431754433, -8.220784508683479],
              [-39.951843584583891, -8.220784564939471],
              [-39.951843738402118, -8.220784618453944],
              [-39.951843893159577, -8.220784669209642],
              [-39.9518440488064, -8.22078471719025],
              [-39.951844205292474, -8.220784762380243],
              [-39.951844362567392, -8.220784804765158],
              [-39.951844520580487, -8.2207848443313],
              [-39.951844679280867, -8.220784881065899],
              [-39.951844838617411, -8.220784914957152],
              [-39.951844998538817, -8.220784945994144],
              [-39.951845158993557, -8.220784974166861],
              [-39.951845319929951, -8.220784999466238],
              [-39.951845481296161, -8.220785021884144],
              [-39.951845643040201, -8.220785041413325],
              [-39.951845805110011, -8.220785058047518],
              [-39.951845967453337, -8.220785071781323],
              [-39.951846130017934, -8.220785082610412],
              [-39.951846292751412, -8.220785090531194],
              [-39.951846455601391, -8.220785095541157],
              [-39.951846618515354, -8.220785097638695],
              [-39.951846781440885, -8.220785096823104],
              [-39.951846944325474, -8.220785093094692],
              [-39.951847107116684, -8.220785086454635],
              [-39.951847269762055, -8.220785076905036],
              [-39.951847432209206, -8.220785064449059],
              [-39.95184759440582, -8.220785049090628],
              [-39.951847756299649, -8.22078503083474],
              [-39.951847917838549, -8.220785009687244],
              [-39.951848078970485, -8.220784985654989],
              [-39.951848239643567, -8.220784958745686],
              [-39.951848399806039, -8.220784928968026],
              [-39.951848559406301, -8.220784896331594],
              [-39.951848718392952, -8.220784860846871],
              [-39.951848876714799, -8.220784822525316],
              [-39.951849034320816, -8.22078478137928],
              [-39.951849191160257, -8.22078473742198],
              [-39.951849347182623, -8.220784690667605],
              [-39.951849502337623, -8.22078464113121],
              [-39.951849656575298, -8.220784588828746],
              [-39.951849809845982, -8.220784533777026],
              [-39.951849962100304, -8.220784475993874],
              [-39.95185011328919, -8.220784415497812],
              [-39.95185026336398, -8.220784352308369],
              [-39.951850412276315, -8.22078428644587],
              [-39.951850559978233, -8.220784217931557],
              [-39.951850706422185, -8.220784146787478],
              [-39.951850851560977, -8.220784073036551],
              [-39.951850995347854, -8.220783996702556],
              [-39.951851137736512, -8.220783917810051],
              [-39.951851278681083, -8.220783836384454],
              [-39.951851418136187, -8.220783752451977],
              [-39.951851556056894, -8.22078366603971],
              [-39.951851692398776, -8.220783577175441],
              [-39.951851827117942, -8.22078348588779],
              [-39.951851960170949, -8.220783392206149],
              [-39.951852091514979, -8.220783296160773],
              [-39.951852221107728, -8.220783197782493],
              [-39.951852348907444, -8.220783097103054],
              [-39.95185247487295, -8.220782994154872],
              [-39.951852598963704, -8.220782888971089],
              [-39.951852721139709, -8.220782781585637],
              [-39.951852841361635, -8.22078267203306],
              [-39.951852959590738, -8.220782560348644],
              [-39.951853075788939, -8.220782446568393],
              [-39.951853189918836, -8.220782330728904],
              [-39.951853301943636, -8.22078221286756],
              [-39.951853411827273, -8.220782093022265],
              [-39.951853519534367, -8.220781971231663],
              [-39.951853625030175, -8.220781847534941],
              [-39.951853728280781, -8.22078172197193],
              [-39.951853829252883, -8.22078159458313],
              [-39.951853820538119, -8.220781598951669],
              [-39.952635059533165, -8.219777551913745],
              [-39.952635055660849, -8.219777583652011],
              [-39.952635102704249, -8.219777522659049],
              [-39.952635149225252, -8.219777461269722],
              [-39.952635195220509, -8.219777399488429],
              [-39.952635240686703, -8.219777337319647],
              [-39.952635285620538, -8.219777274767894],
              [-39.952635330018751, -8.219777211837664],
              [-39.952635373878152, -8.219777148533531],
              [-39.952635417195552, -8.219777084860061],
              [-39.95263545996783, -8.219777020821882],
              [-39.952635502191896, -8.219776956423601],
              [-39.952635543864666, -8.219776891669888],
              [-39.952635584983149, -8.219776826565436],
              [-39.952635625544389, -8.219776761114918],
              [-39.952635665545408, -8.219776695323107],
              [-39.952635704983351, -8.21977662919473],
              [-39.952635743855332, -8.219776562734605],
              [-39.95263578215858, -8.219776495947519],
              [-39.952635819890283, -8.2197764288383],
              [-39.952635857047746, -8.21977636141181],
              [-39.952635893628248, -8.219776293672933],
              [-39.952635929629174, -8.219776225626561],
              [-39.952635965047911, -8.219776157277616],
              [-39.952635999881878, -8.219776088631034],
              [-39.952636034128581, -8.219776019691793],
              [-39.952636067785534, -8.219775950464884],
              [-39.952636100850292, -8.219775880955284],
              [-39.952636133320475, -8.219775811168049],
              [-39.952636165193738, -8.21977574110821],
              [-39.952636196467758, -8.219775670780837],
              [-39.952636227140289, -8.21977560019103],
              [-39.9526362572091, -8.219775529343908],
              [-39.952636286672039, -8.219775458244552],
              [-39.952636315526938, -8.219775386898171],
              [-39.952636343771744, -8.219775315309843],
              [-39.952636371404402, -8.219775243484804],
              [-39.952636398422904, -8.219775171428223],
              [-39.952636424825307, -8.219775099145323],
              [-39.95263645060971, -8.219775026641333],
              [-39.952636475774227, -8.219774953921476],
              [-39.952636500317041, -8.21977488099105],
              [-39.952636524236397, -8.219774807855309],
              [-39.952636547530531, -8.219774734519554],
              [-39.952636570197797, -8.21977466098906],
              [-39.952636592236523, -8.219774587269187],
              [-39.952636613645126, -8.219774513365243],
              [-39.95263663442207, -8.219774439282574],
              [-39.952636654565843, -8.219774365026522],
              [-39.952636674074967, -8.219774290602521],
              [-39.952636692948069, -8.219774216015892],
              [-39.952636711183757, -8.219774141272033],
              [-39.952636728780718, -8.219774066376383],
              [-39.952636745737685, -8.219773991334341],
              [-39.95263676205343, -8.219773916151331],
              [-39.952636777726767, -8.219773840832797],
              [-39.952636792756572, -8.219773765384186],
              [-39.952636807141744, -8.219773689810973],
              [-39.952636820881239, -8.219773614118591],
              [-39.952636833974097, -8.219773538312525],
              [-39.952636846419345, -8.219773462398287],
              [-39.952636858216081, -8.219773386381346],
              [-39.952636869363467, -8.219773310267191],
              [-39.95263687986067, -8.219773234061327],
              [-39.952636889706945, -8.219773157769289],
              [-39.952636898901595, -8.219773081396571],
              [-39.952636907443939, -8.219773004948728],
              [-39.952636915333365, -8.219772928431253],
              [-39.952636922569283, -8.219772851849715],
              [-39.952636929151204, -8.219772775209631],
              [-39.952636935078623, -8.219772698516536],
              [-39.952636940351134, -8.219772621775984],
              [-39.952636944968333, -8.219772544993571],
              [-39.9526369489299, -8.219772468174806],
              [-39.952636952235544, -8.219772391325236],
              [-39.95263695488503, -8.219772314450452],
              [-39.952636956878159, -8.219772237555995],
              [-39.952636958214796, -8.219772160647421],
              [-39.952636958894836, -8.219772083730318],
              [-39.952636958918241, -8.219772006810219],
              [-39.952636958284998, -8.219771929892721],
              [-39.95263695699515, -8.219771852983348],
              [-39.95263695504881, -8.219771776087708],
              [-39.952636952446092, -8.219771699211332],
              [-39.952636949187209, -8.219771622359769],
              [-39.952636945272367, -8.219771545538618],
              [-39.952636940701886, -8.219771468753406],
              [-39.952636935476072, -8.219771392009703],
              [-39.952636929595336, -8.219771315313054],
              [-39.952636923060034, -8.219771238669018],
              [-39.952636915870698, -8.219771162083116],
              [-39.952636908027849, -8.219771085560906],
              [-39.952636899532003, -8.2197710091079],
              [-39.952636890383829, -8.219770932729634],
              [-39.95263688058396, -8.219770856431667],
              [-39.952636870133112, -8.219770780219493],
              [-39.952636859032062, -8.219770704098627],
              [-39.952636847281582, -8.219770628074551],
              [-39.952636834882526, -8.219770552152804],
              [-39.952636821835796, -8.219770476338855],
              [-39.952636808142351, -8.219770400638181],
              [-39.95263676733839, -8.219770402882897],
              [-39.952408414507836, -8.218537755079849],
              [-39.952408398467149, -8.218537746910053],
              [-39.952408390435323, -8.218537704141196],
              [-39.952408382197063, -8.218537661411355],
              [-39.952408373752561, -8.218537618721486],
              [-39.952408365102002, -8.218537576072594],
              [-39.952408356245606, -8.218537533465661],
              [-39.952408347183571, -8.218537490901646],
              [-39.95240833791609, -8.218537448381568],
              [-39.952408328443397, -8.218537405906398],
              [-39.952408318765684, -8.218537363477116],
              [-39.952408308883228, -8.218537321094729],
              [-39.952408298796215, -8.218537278760198],
              [-39.952408288504891, -8.218537236474502],
              [-39.952408278009486, -8.218537194238595],
              [-39.952408267310247, -8.218537152053504],
              [-39.952408256407416, -8.218537109920163],
              [-39.952408245301264, -8.218537067839593],
              [-39.952408233992045, -8.218537025812708],
              [-39.95240822248001, -8.218536983840526],
              [-39.952408210765427, -8.218536941924009],
              [-39.952408198848559, -8.218536900064144],
              [-39.952408186729684, -8.21853685826186],
              [-39.952408174409115, -8.218536816518126],
              [-39.952408161887092, -8.218536774833961],
              [-39.952408149163922, -8.218536733210268],
              [-39.95240813623991, -8.218536691648048],
              [-39.952408123115354, -8.218536650148263],
              [-39.952408109790539, -8.218536608711853],
              [-39.952408096265799, -8.218536567339783],
              [-39.952408082541425, -8.218536526033009],
              [-39.952408068617743, -8.21853648479251],
              [-39.952408054495066, -8.218536443619227],
              [-39.952408040173744, -8.218536402514086],
              [-39.952408025654087, -8.21853636147806],
              [-39.952408010936438, -8.218536320512131],
              [-39.952407996021144, -8.218536279617204],
              [-39.952407980908546, -8.218536238794206],
              [-39.952407965598979, -8.218536198044164],
              [-39.95240795009282, -8.218536157367971],
              [-39.952407934390422, -8.218536116766547],
              [-39.952407918492142, -8.21853607624084],
              [-39.952407902398342, -8.218536035791809],
              [-39.952407886109413, -8.218535995420396],
              [-39.952407869625716, -8.218535955127514],
              [-39.952407852947637, -8.218535914914092],
              [-39.952407836075587, -8.218535874781086],
              [-39.952407819009899, -8.218535834729419],
              [-39.952407801751029, -8.218535794759998],
              [-39.952407784299325, -8.218535754873747],
              [-39.952407766655256, -8.21853571507161],
              [-39.952407748819155, -8.218535675354513],
              [-39.952407730791464, -8.218535635723326],
              [-39.952407712572629, -8.218535596179036],
              [-39.952407694163057, -8.218535556722525],
              [-39.95240767556313, -8.218535517354688],
              [-39.952407656773339, -8.218535478076451],
              [-39.952407637794089, -8.218535438888741],
              [-39.952407618625834, -8.218535399792431],
              [-39.952407599268987, -8.218535360788444],
              [-39.952407579724024, -8.218535321877695],
              [-39.952407559991407, -8.218535283061088],
              [-39.952407540071562, -8.218535244339479],
              [-39.952407519964964, -8.218535205713835],
              [-39.952407499672077, -8.218535167184958],
              [-39.952407479193376, -8.218535128753823],
              [-39.952407458529329, -8.218535090421257],
              [-39.952407437680421, -8.218535052188216],
              [-39.952407416647134, -8.218535014055513],
              [-39.952407395429944, -8.218534976024083],
              [-39.952407374029342, -8.218534938094773],
              [-39.952407352445846, -8.218534900268461],
              [-39.952407330679925, -8.218534862546063],
              [-39.952407308732091, -8.218534824928422],
              [-39.952407286602885, -8.218534787416381],
              [-39.952407264292781, -8.218534750010853],
              [-39.952407241802312, -8.218534712712675],
              [-39.952407219131963, -8.21853467552272],
              [-39.952407196282323, -8.218534638441881],
              [-39.952407173253896, -8.218534601470967],
              [-39.952407150047172, -8.218534564610851],
              [-39.952407126662735, -8.218534527862412],
              [-39.952407103101123, -8.218534491226462],
              [-39.952407079362857, -8.218534454703875],
              [-39.952407055448518, -8.21853441829548],
              [-39.952407031358632, -8.218534382002122],
              [-39.952407007093775, -8.218534345824645],
              [-39.95240698265448, -8.218534309763886],
              [-39.952406958041351, -8.218534273820673],
              [-39.952406933254935, -8.218534237995842],
              [-39.952406908295806, -8.218534202290236],
              [-39.952406883164556, -8.218534166704664],
              [-39.952406857861746, -8.218534131239943],
              [-39.952406832387993, -8.218534095896914],
              [-39.952406806743838, -8.218534060676381],
              [-39.952406780929927, -8.218534025579197],
              [-39.952406754946821, -8.218533990606131],
              [-39.95240672879514, -8.21853395575801],
              [-39.952406702475479, -8.218533921035617],
              [-39.952406675988449, -8.218533886439772],
              [-39.952406649334662, -8.218533851971291],
              [-39.952406680382836, -8.218533870141659],
              [-39.951274152456968, -8.217076529879215],
              [-39.951274117447724, -8.217076526135854],
              [-39.951274072253099, -8.217076467486052],
              [-39.951274027541274, -8.217076408470135],
              [-39.951273983315218, -8.217076349092006],
              [-39.951273939577902, -8.217076289355662],
              [-39.951273896332225, -8.217076229265075],
              [-39.9512738535811, -8.217076168824267],
              [-39.951273811327376, -8.217076108037274],
              [-39.951273769573859, -8.217076046908176],
              [-39.951273728323379, -8.217075985441035],
              [-39.951273687578663, -8.217075923639968],
              [-39.951273647342447, -8.217075861509111],
              [-39.951273607617416, -8.217075799052637],
              [-39.951273568406222, -8.217075736274671],
              [-39.951273529711486, -8.21707567317948],
              [-39.951273491535794, -8.217075609771204],
              [-39.951273453881704, -8.217075546054115],
              [-39.951273416751732, -8.217075482032472],
              [-39.951273380148351, -8.217075417710584],
              [-39.951273344074018, -8.217075353092682],
              [-39.951273308531135, -8.217075288183127],
              [-39.951273273522091, -8.217075222986264],
              [-39.951273239049208, -8.217075157506429],
              [-39.951273205114802, -8.217075091748013],
              [-39.951273171721134, -8.217075025715413],
              [-39.951273138870448, -8.217074959413031],
              [-39.951273106564933, -8.2170748928453],
              [-39.951273074806743, -8.217074826016686],
              [-39.951273043598, -8.217074758931624],
              [-39.951273012940788, -8.217074691594632],
              [-39.951272982837168, -8.217074624010195],
              [-39.951272953289134, -8.217074556182833],
              [-39.951272924298699, -8.217074488117028],
              [-39.95127289586776, -8.217074419817418],
              [-39.951272867998227, -8.217074351288554],
              [-39.951272840691978, -8.217074282534943],
              [-39.951272813950816, -8.217074213561247],
              [-39.951272787776546, -8.217074144372043],
              [-39.951272762170916, -8.217074074971965],
              [-39.951272737135625, -8.217074005365651],
              [-39.951272712672349, -8.217073935557787],
              [-39.951272688782744, -8.21707386555298],
              [-39.95127266546838, -8.217073795355946],
              [-39.951272642730828, -8.217073724971344],
              [-39.951272620571608, -8.217073654403942],
              [-39.951272598992198, -8.217073583658387],
              [-39.951272577994033, -8.217073512739455],
              [-39.951272557578534, -8.217073441651845],
              [-39.951272537747045, -8.21707337040036],
              [-39.951272518500922, -8.217073298989739],
              [-39.951272499841409, -8.217073227424716],
              [-39.951272481769784, -8.217073155710127],
              [-39.951272464287243, -8.217073083850714],
              [-39.95127244739497, -8.217073011851346],
              [-39.951272431094068, -8.217072939716786],
              [-39.951272415385652, -8.217072867451851],
              [-39.951272400270753, -8.217072795061405],
              [-39.951272385750393, -8.217072722550302],
              [-39.951272371825532, -8.21707264992332],
              [-39.951272358497114, -8.217072577185357],
              [-39.951272345766014, -8.217072504341287],
              [-39.951272333633113, -8.217072431395954],
              [-39.951272322099172, -8.217072358354223],
              [-39.951272311164999, -8.217072285221015],
              [-39.951272300831334, -8.217072212001163],
              [-39.951272291098817, -8.217072138699619],
              [-39.951272281968166, -8.217072065321243],
              [-39.951272273439933, -8.217071991870963],
              [-39.951272265514724, -8.217071918353671],
              [-39.951272258193043, -8.217071844774274],
              [-39.951272251475409, -8.21707177113772],
              [-39.951272245362247, -8.217071697448896],
              [-39.951272239853971, -8.217071623712737],
              [-39.951272234950949, -8.217071549934214],
              [-39.951272230653515, -8.217071476118186],
              [-39.951272226961954, -8.217071402269664],
              [-39.951272223876508, -8.21707132839351],
              [-39.951272221397396, -8.217071254494712],
              [-39.95127221952476, -8.217071180578161],
              [-39.951272218258737, -8.217071106648866],
              [-39.951272217599424, -8.217071032711708],
              [-39.951272217546858, -8.217070958771647],
              [-39.951272218101032, -8.217070884833637],
              [-39.951272219261917, -8.217070810902635],
              [-39.951272221029441, -8.217070736983519],
              [-39.951272223403478, -8.217070663081303],
              [-39.951272226383857, -8.217070589200866],
              [-39.951272229970392, -8.217070515347199],
              [-39.951272234162865, -8.217070441525202],
              [-39.951272238960961, -8.217070367739808],
              [-39.951272244364375, -8.21707029399596],
              [-39.951272250372753, -8.217070220298607],
              [-39.951272256985675, -8.217070146652656],
              [-39.951272264202714, -8.217070073062992],
              [-39.951272272023367, -8.217069999534587],
              [-39.951272280447135, -8.217069926072359],
              [-39.951272289473444, -8.217069852681176],
              [-39.951272299101696, -8.21706977936593],
              [-39.951272309331237, -8.217069706131575],
              [-39.951272320161351, -8.217069632983298],
              [-39.951272320460092, -8.217069660549265],
              [-39.951494258735075, -8.215611919665411],
              [-39.951494263028664, -8.21561189446953],
              [-39.95149427430011, -8.215611822398872],
              [-39.951494286154578, -8.215611750421106],
              [-39.951494298591328, -8.215611678540936],
              [-39.951494311609544, -8.21561160676303],
              [-39.951494325208373, -8.215611535092053],
              [-39.951494339386926, -8.215611463532664],
              [-39.951494354144273, -8.215611392089523],
              [-39.951494369479491, -8.21561132076725],
              [-39.951494385391548, -8.215611249570538],
              [-39.951494401879415, -8.215611178503979],
              [-39.951494418942026, -8.215611107572213],
              [-39.951494436578272, -8.215611036779841],
              [-39.95149445478701, -8.215610966131498],
              [-39.951494473567024, -8.215610895631736],
              [-39.951494492917135, -8.215610825285157],
              [-39.951494512836071, -8.21561075509633],
              [-39.951494533322524, -8.215610685069823],
              [-39.951494554375174, -8.215610615210203],
              [-39.951494575992648, -8.21561054552202],
              [-39.951494598173525, -8.215610476009781],
              [-39.951494620916392, -8.21561040667803],
              [-39.95149464421975, -8.215610337531242],
              [-39.951494668082091, -8.21561026857395],
              [-39.951494692501861, -8.215610199810639],
              [-39.951494717477473, -8.215610131245761],
              [-39.951494743007274, -8.215610062883798],
              [-39.951494769089663, -8.215609994729135],
              [-39.951494795722894, -8.215609926786307],
              [-39.951494822905254, -8.215609859059651],
              [-39.951494850634965, -8.2156097915536],
              [-39.951494878910239, -8.215609724272568],
              [-39.951494907729234, -8.215609657220876],
              [-39.951494937090054, -8.215609590402925],
              [-39.951494966990815, -8.215609523823035],
              [-39.95149499742957, -8.215609457485584],
              [-39.95149502840431, -8.215609391394842],
              [-39.951495059913064, -8.215609325555096],
              [-39.951495091953753, -8.215609259970677],
              [-39.9514951245243, -8.215609194645836],
              [-39.951495157622588, -8.2156091295848],
              [-39.951495191246451, -8.215609064791813],
              [-39.951495225393735, -8.215609000271089],
              [-39.951495260062181, -8.215608936026804],
              [-39.951495295249565, -8.2156088720632],
              [-39.951495330953584, -8.21560880838439],
              [-39.951495367171916, -8.215608744994503],
              [-39.9514954039022, -8.215608681897697],
              [-39.951495441142058, -8.215608619098013],
              [-39.951495478889065, -8.215608556599621],
              [-39.951495517140756, -8.215608494406558],
              [-39.951495555894667, -8.215608432522814],
              [-39.951495595148238, -8.215608370952475],
              [-39.951495634898954, -8.215608309699554],
              [-39.951495675144209, -8.215608248767989],
              [-39.951495715881393, -8.215608188161774],
              [-39.951495757107857, -8.215608127884837],
              [-39.951495798820915, -8.215608067941103],
              [-39.951495841017859, -8.215608008334458],
              [-39.951495883695934, -8.215607949068776],
              [-39.951495926852374, -8.21560789014795],
              [-39.951495970484395, -8.215607831575792],
              [-39.951496014589111, -8.215607773356071],
              [-39.951496059163681, -8.215607715492629],
              [-39.951496104205205, -8.215607657989217],
              [-39.951496149710742, -8.215607600849584],
              [-39.951496195677343, -8.215607544077404],
              [-39.951496242102017, -8.215607487676406],
              [-39.951496288981737, -8.215607431650261],
              [-39.951496336313468, -8.215607376002572],
              [-39.951496384094114, -8.215607320737023],
              [-39.951496432320589, -8.215607265857143],
              [-39.951496480989739, -8.215607211366544],
              [-39.951496530098403, -8.215607157268735],
              [-39.95149657964339, -8.215607103567303],
              [-39.95149662962146, -8.215607050265646],
              [-39.951496680029408, -8.215606997367317],
              [-39.951496730863894, -8.215606944875695],
              [-39.951496782121644, -8.215606892794201],
              [-39.951496833799332, -8.215606841126286],
              [-39.95149688589359, -8.215606789875226],
              [-39.951496938401021, -8.215606739044423],
              [-39.951496991318223, -8.215606688637163],
              [-39.951497044641734, -8.215606638656684],
              [-39.951497098368108, -8.21560658910628],
              [-39.951497152493843, -8.215606539989176],
              [-39.951497207015429, -8.215606491308534],
              [-39.951497261929283, -8.215606443067509],
              [-39.951497317231869, -8.215606395269313],
              [-39.951497372919576, -8.215606347917001],
              [-39.951497428988795, -8.215606301013667],
              [-39.951497485435858, -8.215606254562333],
              [-39.951497542257108, -8.215606208566061],
              [-39.951497599448849, -8.215606163027832],
              [-39.951497657007359, -8.215606117950578],
              [-39.951497714928884, -8.215606073337254],
              [-39.951497773209681, -8.21560602919075],
              [-39.951497831845941, -8.215605985513985],
              [-39.951497890833863, -8.215605942309718],
              [-39.951497950168793, -8.215605899582746],
              [-39.95149796653206, -8.215605941666825],
              [-39.952282162844675, -8.215045963712633],
              [-39.952282185522428, -8.215045936773027],
              [-39.952282263569309, -8.215045880418307],
              [-39.952282341011205, -8.215045823241171],
              [-39.952282417839378, -8.215045765248057],
              [-39.952282494045157, -8.215045706445521],
              [-39.95228256961996, -8.215045646840176],
              [-39.952282644555268, -8.215045586438773],
              [-39.952282718842632, -8.215045525248108],
              [-39.952282792473682, -8.215045463275056],
              [-39.952282865440083, -8.215045400526645],
              [-39.952282937733663, -8.215045337009901],
              [-39.952283009346232, -8.215045272732009],
              [-39.95228308026973, -8.215045207700209],
              [-39.952283150496157, -8.215045141921832],
              [-39.952283220017605, -8.215045075404328],
              [-39.952283288826223, -8.215045008155142],
              [-39.952283356914265, -8.215044940181894],
              [-39.95228342427405, -8.21504487149223],
              [-39.952283490897997, -8.215044802093901],
              [-39.952283556778561, -8.215044731994709],
              [-39.95228362190835, -8.215044661202604],
              [-39.952283686280005, -8.215044589725524],
              [-39.952283749886256, -8.215044517571558],
              [-39.952283812719962, -8.215044444748813],
              [-39.952283874774032, -8.215044371265527],
              [-39.952283936041439, -8.215044297129943],
              [-39.952283996515312, -8.215044222350471],
              [-39.95228405618883, -8.215044146935497],
              [-39.952284115055242, -8.215044070893528],
              [-39.952284173107955, -8.215043994233159],
              [-39.952284230340354, -8.21504391696303],
              [-39.95228428674605, -8.215043839091821],
              [-39.952284342318649, -8.215043760628374],
              [-39.952284397051919, -8.215043681581486],
              [-39.952284450939644, -8.215043601960089],
              [-39.952284503975804, -8.215043521773122],
              [-39.952284556154353, -8.215043441029664],
              [-39.952284607469466, -8.215043359738829],
              [-39.952284657915328, -8.215043277909736],
              [-39.952284707486271, -8.215043195551623],
              [-39.952284756176695, -8.215043112673799],
              [-39.952284803981115, -8.215043029285622],
              [-39.952284850894124, -8.215042945396428],
              [-39.952284896910449, -8.215042861015728],
              [-39.952284942024924, -8.215042776153018],
              [-39.952284986232421, -8.215042690817841],
              [-39.952285029527971, -8.215042605019853],
              [-39.952285071906701, -8.215042518768731],
              [-39.952285113363828, -8.21504243207419],
              [-39.952285153894692, -8.215042344945976],
              [-39.952285193494703, -8.215042257393941],
              [-39.952285232159404, -8.215042169427951],
              [-39.952285269884428, -8.215042081057922],
              [-39.952285306665537, -8.215041992293816],
              [-39.95228534249857, -8.215041903145652],
              [-39.952285377379511, -8.215041813623433],
              [-39.952285411304395, -8.215041723737322],
              [-39.952285444269421, -8.215041633497401],
              [-39.952285476270866, -8.215041542913843],
              [-39.95228550730512, -8.21504145199688],
              [-39.952285537368681, -8.215041360756757],
              [-39.952285566458187, -8.215041269203766],
              [-39.952285594570306, -8.215041177348235],
              [-39.952285621701918, -8.215041085200507],
              [-39.952285647849934, -8.215040992770973],
              [-39.952285673011417, -8.215040900070058],
              [-39.952285697183541, -8.21504080710821],
              [-39.952285720363555, -8.215040713895883],
              [-39.952285742548881, -8.215040620443625],
              [-39.952285763736981, -8.215040526761975],
              [-39.95228578392549, -8.21504043286143],
              [-39.952285803112126, -8.215040338752662],
              [-39.952285821294723, -8.21504024444622],
              [-39.952285838471226, -8.215040149952753],
              [-39.952285854639719, -8.215040055282921],
              [-39.952285869798366, -8.215039960447386],
              [-39.95228588394545, -8.215039865456884],
              [-39.952285897079385, -8.215039770322051],
              [-39.952285909198686, -8.215039675053664],
              [-39.952285920302003, -8.21503957966245],
              [-39.952285930388051, -8.215039484159156],
              [-39.95228593945572, -8.215039388554548],
              [-39.952285947503995, -8.215039292859414],
              [-39.952285954531945, -8.21503919708455],
              [-39.952285960538795, -8.215039101240723],
              [-39.952285965523842, -8.21503900533877],
              [-39.952285969486546, -8.215038909389477],
              [-39.952285972426466, -8.2150388134037],
              [-39.952285974343248, -8.215038717392213],
              [-39.952285975236705, -8.215038621365878],
              [-39.952285975106719, -8.215038525335501],
              [-39.952285973953295, -8.215038429311905],
              [-39.952285971776575, -8.215038333305925],
              [-39.952285968576817, -8.215038237328363],
              [-39.952285964354346, -8.215038141390078],
              [-39.952285959109687, -8.215038045501869],
              [-39.952285952843376, -8.215037949674555],
              [-39.952285945556163, -8.21503785391889],
              [-39.952285937248853, -8.215037758245725],
              [-39.952285927922375, -8.215037662665827],
              [-39.952285955799837, -8.215037633991859],
              [-39.952170813689868, -8.213919851311539],
              [-39.952170834477457, -8.213919808540597],
              [-39.952170827972203, -8.213919743008148],
              [-39.952170821946062, -8.213919677430209],
              [-39.952170816399345, -8.213919611810349],
              [-39.952170811332323, -8.213919546151974],
              [-39.952170806745301, -8.213919480458571],
              [-39.952170802638491, -8.213919414733656],
              [-39.952170799012144, -8.213919348980685],
              [-39.952170795866415, -8.213919283203159],
              [-39.952170793201503, -8.21391921740455],
              [-39.952170791017522, -8.213919151588378],
              [-39.952170789314607, -8.213919085758066],
              [-39.952170788092843, -8.213919019917181],
              [-39.952170787352287, -8.213918954069156],
              [-39.952170787092989, -8.213918888217503],
              [-39.952170787314962, -8.21391882236572],
              [-39.952170788018201, -8.213918756517282],
              [-39.952170789202647, -8.213918690675708],
              [-39.952170790868259, -8.213918624844448],
              [-39.952170793014936, -8.213918559027029],
              [-39.952170795642566, -8.213918493226933],
              [-39.952170798750998, -8.213918427447652],
              [-39.952170802340092, -8.213918361692654],
              [-39.952170806409647, -8.21391829596543],
              [-39.952170810959451, -8.213918230269462],
              [-39.952170815989255, -8.213918164608241],
              [-39.95217082149879, -8.213918098985241],
              [-39.952170827487777, -8.213918033403962],
              [-39.952170833955869, -8.213917967867845],
              [-39.952170840902767, -8.213917902380404],
              [-39.952170848328066, -8.21391783694509],
              [-39.952170856231405, -8.213917771565393],
              [-39.952170864612327, -8.213917706244727],
              [-39.952170873470429, -8.213917640986594],
              [-39.952170882805206, -8.213917575794467],
              [-39.952170892616181, -8.213917510671747],
              [-39.952170902902829, -8.213917445621943],
              [-39.952170913664602, -8.213917380648489],
              [-39.952170924900926, -8.213917315754786],
              [-39.952170936611225, -8.213917250944318],
              [-39.952170948794866, -8.213917186220533],
              [-39.952170961451202, -8.213917121586833],
              [-39.952170974579559, -8.213917057046675],
              [-39.952170988179255, -8.213916992603446],
              [-39.952171002249543, -8.213916928260572],
              [-39.952171016789698, -8.213916864021487],
              [-39.952171031798947, -8.21391679988956],
              [-39.952171047276494, -8.213916735868223],
              [-39.952171063221527, -8.213916671960845],
              [-39.952171079633167, -8.213916608170816],
              [-39.952171096510575, -8.213916544501533],
              [-39.952171113852842, -8.213916480956369],
              [-39.952171131659064, -8.213916417538698],
              [-39.95217114992829, -8.213916354251868],
              [-39.952171168659532, -8.213916291099221],
              [-39.952171187851832, -8.213916228084145],
              [-39.952171207504136, -8.213916165209975],
              [-39.95217122761543, -8.213916102479985],
              [-39.952171248184641, -8.213916039897567],
              [-39.952171269210659, -8.213915977466014],
              [-39.952171290692405, -8.213915915188661],
              [-39.952171312628721, -8.213915853068771],
              [-39.952171335018427, -8.213915791109635],
              [-39.952171357860379, -8.213915729314554],
              [-39.952171381153327, -8.213915667686798],
              [-39.952171404896049, -8.213915606229678],
              [-39.952171429087286, -8.213915544946376],
              [-39.952171453725754, -8.213915483840218],
              [-39.952171478810151, -8.213915422914368],
              [-39.952171504339148, -8.213915362172123],
              [-39.952171530311382, -8.213915301616643],
              [-39.952171556725489, -8.213915241251192],
              [-39.952171583580068, -8.213915181078953],
              [-39.952171610873677, -8.213915121103092],
              [-39.952171638604888, -8.213915061326825],
              [-39.95217166677223, -8.213915001753289],
              [-39.952171695374204, -8.213914942385671],
              [-39.952171724409283, -8.213914883227094],
              [-39.952171753875966, -8.213914824280709],
              [-39.952171783772634, -8.213914765549642],
              [-39.952171814097746, -8.213914707036986],
              [-39.95217184484968, -8.213914648745856],
              [-39.95217187602681, -8.21391459067933],
              [-39.952171907627452, -8.213914532840517],
              [-39.952171939649986, -8.213914475232482],
              [-39.952171972092678, -8.213914417858236],
              [-39.952172004953809, -8.213914360720873],
              [-39.952172038231659, -8.213914303823367],
              [-39.952172071924437, -8.213914247168786],
              [-39.952172106030375, -8.213914190760091],
              [-39.95217214054766, -8.213914134600317],
              [-39.952172175474452, -8.213914078692408],
              [-39.952172210808918, -8.213914023039319],
              [-39.952172246549175, -8.21391396764407],
              [-39.952172282693304, -8.213913912509502],
              [-39.95217231923943, -8.213913857638603],
              [-39.952172356185606, -8.213913803034224],
              [-39.952172393529864, -8.213913748699339],
              [-39.95217243127022, -8.213913694636778],
              [-39.952172469405149, -8.213913640848165],
              [-39.952172432865616, -8.213913606921148],
              [-39.952733131554936, -8.213128954321187],
              [-39.952733126136735, -8.213128929772404],
              [-39.952733162389663, -8.213128879397916],
              [-39.952733198988291, -8.213128829272152],
              [-39.95273323593085, -8.213128779397429],
              [-39.952733273215649, -8.213128729776139],
              [-39.952733310840912, -8.213128680410593],
              [-39.952733348804884, -8.213128631303105],
              [-39.952733387105773, -8.213128582456003],
              [-39.952733425741783, -8.213128533871533],
              [-39.952733464711109, -8.213128485552023],
              [-39.95273350401191, -8.213128437499716],
              [-39.952733543642331, -8.213128389716896],
              [-39.952733583600526, -8.213128342205788],
              [-39.952733623884605, -8.21312829496862],
              [-39.952733664492705, -8.213128248007619],
              [-39.952733705422887, -8.213128201324997],
              [-39.952733746673239, -8.213128154922934],
              [-39.952733788241822, -8.213128108803623],
              [-39.952733830126697, -8.213128062969224],
              [-39.952733872325872, -8.213128017421882],
              [-39.952733914837374, -8.213127972163743],
              [-39.952733957659234, -8.213127927196949],
              [-39.952734000789391, -8.213127882523558],
              [-39.952734044225863, -8.213127838145738],
              [-39.952734087966583, -8.213127794065507],
              [-39.952734132009503, -8.213127750284997],
              [-39.952734176352564, -8.213127706806238],
              [-39.952734220993669, -8.213127663631266],
              [-39.952734265930729, -8.21312762076211],
              [-39.952734311161649, -8.213127578200799],
              [-39.952734356684267, -8.213127535949305],
              [-39.952734402496475, -8.213127494009642],
              [-39.952734448596111, -8.21312745238377],
              [-39.952734494980994, -8.213127411073625],
              [-39.952734541648994, -8.213127370081168],
              [-39.95273458859787, -8.213127329408314],
              [-39.952734635825429, -8.213127289056999],
              [-39.95273468332946, -8.213127249029069],
              [-39.952734731107739, -8.213127209326464],
              [-39.952734779158, -8.213127169951008],
              [-39.952734827478011, -8.213127130904542],
              [-39.95273487606547, -8.21312709218893],
              [-39.952734924918126, -8.213127053805991],
              [-39.952734974033667, -8.2131270157575],
              [-39.952735023409794, -8.21312697804527],
              [-39.952735073044195, -8.213126940671073],
              [-39.952735122934513, -8.213126903636626],
              [-39.95273517307843, -8.213126866943732],
              [-39.952735223473582, -8.213126830594071],
              [-39.9527352741176, -8.213126794589336],
              [-39.952735325008113, -8.213126758931253],
              [-39.952735376142726, -8.213126723621496],
              [-39.952735427519016, -8.213126688661704],
              [-39.952735479134596, -8.213126654053516],
              [-39.952735530987034, -8.213126619798551],
              [-39.952735583073917, -8.213126585898458],
              [-39.952735635392756, -8.213126552354799],
              [-39.952735687941122, -8.213126519169174],
              [-39.952735740716548, -8.213126486343116],
              [-39.952735793716528, -8.213126453878164],
              [-39.952735846938594, -8.213126421775854],
              [-39.952735900380247, -8.213126390037718],
              [-39.952735954038971, -8.213126358665214],
              [-39.952736007912243, -8.213126327659824],
              [-39.952736061997555, -8.213126297022999],
              [-39.952736116292343, -8.213126266756179],
              [-39.952736170794047, -8.213126236860782],
              [-39.952736225500139, -8.213126207338233],
              [-39.952736280408025, -8.213126178189931],
              [-39.952736335515134, -8.21312614941718],
              [-39.952736390818885, -8.213126121021393],
              [-39.952736446316671, -8.213126093003865],
              [-39.952736502005884, -8.21312606536595],
              [-39.952736557883917, -8.213126038108935],
              [-39.952736613948133, -8.213126011234074],
              [-39.952736670195904, -8.213125984742657],
              [-39.952736726624593, -8.213125958635899],
              [-39.952736783231543, -8.213125932915052],
              [-39.952736840014111, -8.21312590758132],
              [-39.952736896969604, -8.213125882635874],
              [-39.952736954095357, -8.21312585807993],
              [-39.952737011388706, -8.213125833914591],
              [-39.952737068846936, -8.213125810141042],
              [-39.952737126467355, -8.213125786760365],
              [-39.952737184247262, -8.21312576377365],
              [-39.952737242183929, -8.213125741182012],
              [-39.952737300274642, -8.213125718986451],
              [-39.952737358516686, -8.213125697188067],
              [-39.952737416907304, -8.213125675787882],
              [-39.952737475443776, -8.213125654786877],
              [-39.952737534123315, -8.213125634186071],
              [-39.952737592943215, -8.213125613986378],
              [-39.952737651900698, -8.213125594188798],
              [-39.952737710992963, -8.213125574794248],
              [-39.952737770217269, -8.213125555803623],
              [-39.952737829570808, -8.213125537217818],
              [-39.952737889050809, -8.213125519037723],
              [-39.95273794865448, -8.21312550126417],
              [-39.952738008379001, -8.21312548389799],
              [-39.952738068221315, -8.213125466940886],
              [-39.9527380254953, -8.213125505908796],
              [-39.953473947954052, -8.212919633948111],
              [-39.953979067686824, -8.213458562003638],
              [-39.953979049918992, -8.213458570911531],
              [-39.95397910159128, -8.213458625592599],
              [-39.953979153717526, -8.213458679844283],
              [-39.953979206294157, -8.213458733662895],
              [-39.953979259317528, -8.213458787044679],
              [-39.953979312784, -8.213458839985988],
              [-39.953979366689872, -8.213458892483162],
              [-39.953979421031448, -8.21345894453254],
              [-39.953979475804957, -8.213458996130614],
              [-39.953979531006638, -8.21345904727375],
              [-39.95397958663267, -8.213459097958474],
              [-39.953979642679236, -8.213459148181254],
              [-39.953979699142451, -8.213459197938645],
              [-39.953979756018434, -8.213459247227203],
              [-39.95397981330327, -8.213459296043538],
              [-39.95397987099301, -8.213459344384304],
              [-39.953979929083651, -8.213459392246161],
              [-39.953979987571202, -8.213459439625764],
              [-39.953980046451633, -8.213459486519882],
              [-39.953980105720895, -8.213459532925302],
              [-39.953980165374887, -8.213459578838792],
              [-39.953980225409481, -8.21345962425721],
              [-39.95398028582057, -8.213459669177386],
              [-39.953980346603956, -8.213459713596261],
              [-39.953980407755495, -8.213459757510767],
              [-39.953980469270917, -8.21345980091786],
              [-39.953980531146001, -8.213459843814553],
              [-39.953980593376492, -8.21345988619789],
              [-39.953980655958084, -8.213459928064935],
              [-39.953980718886477, -8.213459969412835],
              [-39.953980782157323, -8.213460010238697],
              [-39.95398084576626, -8.213460050539759],
              [-39.953980909708896, -8.213460090313163],
              [-39.953980973980833, -8.213460129556241],
              [-39.953981038577624, -8.213460168266261],
              [-39.953981103494826, -8.21346020644055],
              [-39.953981168727964, -8.213460244076481],
              [-39.953981234272533, -8.213460281171427],
              [-39.953981300124028, -8.213460317722882],
              [-39.953981366277894, -8.213460353728307],
              [-39.95398143272957, -8.213460389185201],
              [-39.953981499474494, -8.213460424091151],
              [-39.953981566508027, -8.213460458443715],
              [-39.95398163382557, -8.213460492240552],
              [-39.953981701422492, -8.21346052547932],
              [-39.953981769294103, -8.213460558157754],
              [-39.953981837435762, -8.213460590273559],
              [-39.953981905842724, -8.213460621824536],
              [-39.953981974510306, -8.213460652808514],
              [-39.953982043433761, -8.213460683223351],
              [-39.953982112608337, -8.213460713066967],
              [-39.953982182029272, -8.213460742337277],
              [-39.95398225169177, -8.213460771032269],
              [-39.953982321591027, -8.213460799149971],
              [-39.953982391722242, -8.213460826688436],
              [-39.953982462080546, -8.213460853645781],
              [-39.953982532661108, -8.213460880020135],
              [-39.953982603459067, -8.213460905809683],
              [-39.953982674469529, -8.213460931012646],
              [-39.953982745687597, -8.21346095562728],
              [-39.953982817108376, -8.213460979651922],
              [-39.953982888726919, -8.213461003084875],
              [-39.953982960538319, -8.213461025924534],
              [-39.953983032537586, -8.213461048169334],
              [-39.953983104719789, -8.213461069817726],
              [-39.953983177079934, -8.213461090868215],
              [-39.953983249613024, -8.213461111319356],
              [-39.953983322314066, -8.213461131169771],
              [-39.953983395178071, -8.213461150418048],
              [-39.953983468199958, -8.213461169062878],
              [-39.953983541374754, -8.213461187102984],
              [-39.953983614697385, -8.213461204537118],
              [-39.953983688162786, -8.213461221364048],
              [-39.953983761765919, -8.213461237582633],
              [-39.95398383550166, -8.213461253191765],
              [-39.953983909364979, -8.213461268190368],
              [-39.95398398335076, -8.213461282577372],
              [-39.953984057453894, -8.213461296351836],
              [-39.953984131669266, -8.213461309512796],
              [-39.953984205991802, -8.21346132205934],
              [-39.953984280416329, -8.213461333990589],
              [-39.953984354937745, -8.21346134530574],
              [-39.953984429550893, -8.213461356004004],
              [-39.953984504250634, -8.213461366084626],
              [-39.953984579031832, -8.213461375546924],
              [-39.9539846538893, -8.213461384390239],
              [-39.953984728817908, -8.213461392614006],
              [-39.95398480381246, -8.213461400217604],
              [-39.953984878867807, -8.213461407200528],
              [-39.953984953978775, -8.213461413562305],
              [-39.953985029140163, -8.213461419302467],
              [-39.953985104346813, -8.213461424420677],
              [-39.953985179593531, -8.213461428916512],
              [-39.953985254875121, -8.213461432789709],
              [-39.953985330186391, -8.213461436039982],
              [-39.953985405522161, -8.213461438667105],
              [-39.953985480877229, -8.213461440670907],
              [-39.953985556246401, -8.213461442051244],
              [-39.953985631624477, -8.213461442808017],
              [-39.953985707006275, -8.213461442941181],
              [-39.95398570156074, -8.213461440453385],
              [-39.955226940708435, -8.21345849690128],
              [-39.955226903325567, -8.213458499490734],
              [-39.955226921396083, -8.213458499465775],
              [-39.95522693946662, -8.213458499476632],
              [-39.955226957537079, -8.213458499523336],
              [-39.95522697560741, -8.213458499605874],
              [-39.955226993677563, -8.213458499724243],
              [-39.955227011747439, -8.213458499878442],
              [-39.955227029816953, -8.213458500068493],
              [-39.955227047886069, -8.213458500294381],
              [-39.955227065954702, -8.213458500556095],
              [-39.955227084022759, -8.213458500853648],
              [-39.955227102090184, -8.213458501187029],
              [-39.95522712015692, -8.21345850155623],
              [-39.955227138222888, -8.213458501961265],
              [-39.955227156287997, -8.213458502402137],
              [-39.955227174352189, -8.213458502878838],
              [-39.9552271924154, -8.21345850339136],
              [-39.955227210477553, -8.213458503939679],
              [-39.955227228538575, -8.213458504523819],
              [-39.955227246598383, -8.213458505143791],
              [-39.955227264656912, -8.213458505799565],
              [-39.955227282714105, -8.213458506491143],
              [-39.955227300769891, -8.213458507218533],
              [-39.955227318824178, -8.21345850798175],
              [-39.955227336876902, -8.213458508780745],
              [-39.955227354928006, -8.213458509615547],
              [-39.955227372977397, -8.213458510486165],
              [-39.955227391025012, -8.21345851139257],
              [-39.95522740907078, -8.213458512334745],
              [-39.955227427114629, -8.21345851331272],
              [-39.955227445156474, -8.213458514326467],
              [-39.955227463196294, -8.213458515376013],
              [-39.955227481233948, -8.213458516461317],
              [-39.955227499269419, -8.213458517582387],
              [-39.955227517302603, -8.213458518739245],
              [-39.955227535333449, -8.213458519931857],
              [-39.955227553361865, -8.213458521160224],
              [-39.955227571387788, -8.213458522424327],
              [-39.955227589411166, -8.213458523724162],
              [-39.955227607431901, -8.213458525059771],
              [-39.955227625449943, -8.213458526431099],
              [-39.955227643465186, -8.213458527838183],
              [-39.955227661477608, -8.213458529280954],
              [-39.955227679487102, -8.213458530759464],
              [-39.955227697493598, -8.213458532273675],
              [-39.955227715497038, -8.213458533823609],
              [-39.955227733497338, -8.213458535409234],
              [-39.955227751494448, -8.213458537030558],
              [-39.955227769488282, -8.213458538687558],
              [-39.955227787478769, -8.213458540380241],
              [-39.955227805465832, -8.213458542108617],
              [-39.955227823449412, -8.213458543872648],
              [-39.955227841429419, -8.213458545672347],
              [-39.955227859405802, -8.213458547507672],
              [-39.955227877378491, -8.213458549378666],
              [-39.955227895347406, -8.213458551285301],
              [-39.955227913312477, -8.213458553227555],
              [-39.955227931273605, -8.213458555205435],
              [-39.955227949230775, -8.213458557218919],
              [-39.955227967183873, -8.213458559268037],
              [-39.955227985132836, -8.21345856135277],
              [-39.955228003077607, -8.213458563473072],
              [-39.955228021018115, -8.213458565628946],
              [-39.955228038954267, -8.213458567820403],
              [-39.955228056886014, -8.213458570047427],
              [-39.95522807481327, -8.213458572310001],
              [-39.955228092735979, -8.213458574608133],
              [-39.955228110654041, -8.21345857694179],
              [-39.955228128567413, -8.213458579310997],
              [-39.955228146476024, -8.213458581715715],
              [-39.955228164379797, -8.213458584155941],
              [-39.955228182278653, -8.213458586631686],
              [-39.955228200172513, -8.213458589142908],
              [-39.955228218061336, -8.213458591689616],
              [-39.955228235945029, -8.213458594271801],
              [-39.955228253823527, -8.213458596889442],
              [-39.955228271696775, -8.213458599542522],
              [-39.955228289564673, -8.213458602231061],
              [-39.955228307427156, -8.213458604955024],
              [-39.955228325284175, -8.213458607714374],
              [-39.955228343135637, -8.213458610509168],
              [-39.955228360981486, -8.213458613339364],
              [-39.955228378821644, -8.213458616204941],
              [-39.95522839665604, -8.213458619105888],
              [-39.955228414484594, -8.213458622042191],
              [-39.955228432307266, -8.213458625013873],
              [-39.955228450123954, -8.213458628020838],
              [-39.955228467934603, -8.213458631063164],
              [-39.955228485739127, -8.2134586341408],
              [-39.955228503537477, -8.213458637253742],
              [-39.955228521329566, -8.213458640401974],
              [-39.955228539115346, -8.213458643585478],
              [-39.955228556894703, -8.213458646804259],
              [-39.955228574667615, -8.213458650058282],
              [-39.955228592433976, -8.213458653347542],
              [-39.95522861019375, -8.213458656672035],
              [-39.955228627946838, -8.213458660031744],
              [-39.955228645693161, -8.21345866342666],
              [-39.955228663432678, -8.213458666856756],
              [-39.955228681165195, -8.213458670323051],
              [-39.955228665966402, -8.213458673692887],
              [-39.956925885026045, -8.213792084616184],
              [-39.956925867761804, -8.21379207579789],
              [-39.956925905144182, -8.213792083060282],
              [-39.956925942556822, -8.213792090166354],
              [-39.956925979999035, -8.21379209711599],
              [-39.956926017470195, -8.21379210390907],
              [-39.956926054969635, -8.213792110545461],
              [-39.956926092496687, -8.213792117025037],
              [-39.956926130050689, -8.213792123347707],
              [-39.956926167630989, -8.213792129513363],
              [-39.95692620523694, -8.213792135521881],
              [-39.956926242867858, -8.213792141373183],
              [-39.956926280523078, -8.213792147067117],
              [-39.956926318201944, -8.213792152603615],
              [-39.956926355903782, -8.213792157982565],
              [-39.956926393627953, -8.213792163203902],
              [-39.956926431373773, -8.213792168267501],
              [-39.956926469140598, -8.213792173173289],
              [-39.956926506927736, -8.213792177921162],
              [-39.956926544734543, -8.213792182511074],
              [-39.956926582560342, -8.213792186942904],
              [-39.956926620404467, -8.213792191216589],
              [-39.956926658266262, -8.213792195332065],
              [-39.956926696145054, -8.213792199289228],
              [-39.956926734040174, -8.21379220308803],
              [-39.956926771950968, -8.21379220672841],
              [-39.956926809876734, -8.213792210210297],
              [-39.956926847816852, -8.213792213533663],
              [-39.956926885770628, -8.213792216698385],
              [-39.956926923737392, -8.213792219704459],
              [-39.956926961716484, -8.21379222255179],
              [-39.956926999707221, -8.213792225240352],
              [-39.956927037708965, -8.213792227770114],
              [-39.956927075721019, -8.213792230141006],
              [-39.956927113742708, -8.213792232353006],
              [-39.956927151773399, -8.213792234406046],
              [-39.95692718981239, -8.213792236300096],
              [-39.956927227859033, -8.213792238035166],
              [-39.956927265912633, -8.213792239611177],
              [-39.956927303972542, -8.213792241028106],
              [-39.956927342038078, -8.213792242285958],
              [-39.956927380108567, -8.21379224338469],
              [-39.956927418183369, -8.213792244324299],
              [-39.956927456261788, -8.213792245104719],
              [-39.956927494343141, -8.213792245725987],
              [-39.956927532426803, -8.213792246188069],
              [-39.956927570512057, -8.213792246490984],
              [-39.956927608598249, -8.213792246634682],
              [-39.956927646684726, -8.213792246619215],
              [-39.956927684770797, -8.213792246444537],
              [-39.956927722855795, -8.213792246110666],
              [-39.95692776093906, -8.213792245617618],
              [-39.956927799019894, -8.213792244965399],
              [-39.956927837097659, -8.213792244154023],
              [-39.956927875171665, -8.213792243183491],
              [-39.956927913241238, -8.21379224205379],
              [-39.956927951305744, -8.213792240765006],
              [-39.956927989364488, -8.213792239317124],
              [-39.956928027416787, -8.213792237710177],
              [-39.956928065461987, -8.213792235944187],
              [-39.956928103499422, -8.213792234019198],
              [-39.956928141528408, -8.213792231935241],
              [-39.956928179548285, -8.213792229692345],
              [-39.956928217558371, -8.213792227290542],
              [-39.956928255558026, -8.213792224729907],
              [-39.956928293546561, -8.213792222010433],
              [-39.956928331523301, -8.213792219132237],
              [-39.956928369487592, -8.213792216095305],
              [-39.956928407438767, -8.213792212899717],
              [-39.956928445376143, -8.213792209545534],
              [-39.956928483299052, -8.213792206032796],
              [-39.956928521206848, -8.213792202361589],
              [-39.956928559098849, -8.213792198531964],
              [-39.956928596974372, -8.21379219454399],
              [-39.956928634832799, -8.213792190397736],
              [-39.956928672673406, -8.213792186093304],
              [-39.956928710495575, -8.213792181630732],
              [-39.956928748298601, -8.213792177010101],
              [-39.956928786081832, -8.213792172231502],
              [-39.956928823844628, -8.213792167295017],
              [-39.956928861586299, -8.213792162200733],
              [-39.956928899306163, -8.213792156948736],
              [-39.956928937003603, -8.213792151539129],
              [-39.956928974677922, -8.213792145971981],
              [-39.956929012328459, -8.213792140247445],
              [-39.956929049954567, -8.213792134365574],
              [-39.956929087555558, -8.213792128326482],
              [-39.95692912513082, -8.213792122130283],
              [-39.956929162679643, -8.213792115777068],
              [-39.956929200201365, -8.213792109266977],
              [-39.956929237695363, -8.213792102600117],
              [-39.956929275160945, -8.213792095776586],
              [-39.956929312597467, -8.213792088796504],
              [-39.956929350004266, -8.213792081660026],
              [-39.95692938738069, -8.213792074367239],
              [-39.956929424726049, -8.213792066918325],
              [-39.956929462039732, -8.213792059313356],
              [-39.956929499321078, -8.213792051552501],
              [-39.956929536569376, -8.21379204363588],
              [-39.956929573784024, -8.213792035563614],
              [-39.956929610964345, -8.213792027335888],
              [-39.956929606802589, -8.213791985325502],
              [-39.957940454380505, -8.213566096891959],
              [-39.957940423660681, -8.213566123321536],
              [-39.957940533374376, -8.213566098088739],
              [-39.957940642765514, -8.213566071502175],
              [-39.95794075181724, -8.213566043565876],
              [-39.957940860512778, -8.21356601428419],
              [-39.957940968835409, -8.213565983661592],
              [-39.957941076768478, -8.213565951702805],
              [-39.957941184295358, -8.213565918412737],
              [-39.957941291399543, -8.21356588379653],
              [-39.957941398064513, -8.213565847859513],
              [-39.957941504273883, -8.213565810607168],
              [-39.957941610011304, -8.213565772045278],
              [-39.957941715260525, -8.213565732179744],
              [-39.957941820005324, -8.213565691016699],
              [-39.957941924229615, -8.21356564856249],
              [-39.957942027917355, -8.213565604823652],
              [-39.95794213105259, -8.21356555980689],
              [-39.957942233619441, -8.213565513519143],
              [-39.957942335602148, -8.21356546596755],
              [-39.957942436985014, -8.21356541715941],
              [-39.957942537752444, -8.213565367102243],
              [-39.95794263788892, -8.213565315803731],
              [-39.957942737379057, -8.213565263271795],
              [-39.957942836207543, -8.213565209514458],
              [-39.957942934359167, -8.213565154540056],
              [-39.957943031818836, -8.213565098357025],
              [-39.957943128571564, -8.213565040973998],
              [-39.957943224602452, -8.213564982399811],
              [-39.957943319896721, -8.213564922643496],
              [-39.957943414439725, -8.213564861714191],
              [-39.957943508216907, -8.213564799621293],
              [-39.957943601213856, -8.213564736374382],
              [-39.957943693416254, -8.213564671983166],
              [-39.95794378480992, -8.213564606457561],
              [-39.957943875380771, -8.213564539807626],
              [-39.957943965114907, -8.213564472043657],
              [-39.95794405399851, -8.213564403176045],
              [-39.957944142017908, -8.213564333215386],
              [-39.957944229159544, -8.213564262172438],
              [-39.957944315410025, -8.21356419005814],
              [-39.957944400756098, -8.213564116883605],
              [-39.957944485184605, -8.213564042660085],
              [-39.957944568682557, -8.213563967398981],
              [-39.957944651237128, -8.213563891111892],
              [-39.957944732835593, -8.21356381381052],
              [-39.957944813465424, -8.213563735506792],
              [-39.957944893114203, -8.213563656212749],
              [-39.957944971769692, -8.213563575940555],
              [-39.957945049419763, -8.213563494702607],
              [-39.957945126052479, -8.213563412511389],
              [-39.957945201656081, -8.213563329379523],
              [-39.957945276218894, -8.213563245319817],
              [-39.957945349729464, -8.213563160345222],
              [-39.957945422176486, -8.213563074468803],
              [-39.957945493548813, -8.213562987703741],
              [-39.957945563835473, -8.213562900063412],
              [-39.957945633025616, -8.213562811561276],
              [-39.957945701108635, -8.213562722210973],
              [-39.957945768074055, -8.213562632026244],
              [-39.957945833911552, -8.213562541020961],
              [-39.957945898611008, -8.21356244920911],
              [-39.957945962162476, -8.213562356604832],
              [-39.957946024556172, -8.213562263222371],
              [-39.95794608578251, -8.213562169076084],
              [-39.957946145832032, -8.213562074180484],
              [-39.957946204695538, -8.213561978550143],
              [-39.957946262363969, -8.213561882199775],
              [-39.957946318828427, -8.213561785144213],
              [-39.957946374080258, -8.213561687398364],
              [-39.957946428110944, -8.21356158897731],
              [-39.957946480912163, -8.213561489896165],
              [-39.957946532475816, -8.213561390170163],
              [-39.957946582793952, -8.213561289814653],
              [-39.957946631858846, -8.213561188845095],
              [-39.957946679662925, -8.213561087276997],
              [-39.957946726198855, -8.213560985125987],
              [-39.957946771459461, -8.213560882407819],
              [-39.957946815437801, -8.213560779138263],
              [-39.95794685812708, -8.213560675333181],
              [-39.957946899520771, -8.213560571008584],
              [-39.957946939612469, -8.213560466180512],
              [-39.957946978396016, -8.213560360865097],
              [-39.957947015865464, -8.213560255078507],
              [-39.957947052015022, -8.213560148837059],
              [-39.957947086839148, -8.213560042157107],
              [-39.957947120332456, -8.213559935055008],
              [-39.957947152489822, -8.213559827547265],
              [-39.957947183306302, -8.213559719650432],
              [-39.957947212777142, -8.213559611381111],
              [-39.957947240897802, -8.213559502755913],
              [-39.957947267663968, -8.213559393791604],
              [-39.95794729307152, -8.213559284504926],
              [-39.957947317116535, -8.213559174912703],
              [-39.957947339795332, -8.213559065031783],
              [-39.957947361104431, -8.213558954879057],
              [-39.957947381040512, -8.213558844471518],
              [-39.957947399600535, -8.213558733826099],
              [-39.957947416781657, -8.213558622959837],
              [-39.957947432581207, -8.213558511889831],
              [-39.957947446996783, -8.213558400633103],
              [-39.957947426027353, -8.213558392666775],
              [-39.958057578620362, -8.212664556097344],
              [-39.958057609725564, -8.212664560207745],
              [-39.958057613535637, -8.212664529721899],
              [-39.958057617449619, -8.212664499249097],
              [-39.958057621467468, -8.212664468789749],
              [-39.958057625589142, -8.212664438344147],
              [-39.958057629814618, -8.212664407912705],
              [-39.958057634143806, -8.212664377495722],
              [-39.958057638576676, -8.212664347093568],
              [-39.958057643113165, -8.212664316706567],
              [-39.958057647753236, -8.2126642863351],
              [-39.958057652496827, -8.212664255979531],
              [-39.958057657343879, -8.212664225640188],
              [-39.958057662294351, -8.212664195317412],
              [-39.958057667348186, -8.212664165011574],
              [-39.958057672505305, -8.212664134722992],
              [-39.958057677765659, -8.212664104452058],
              [-39.958057683129191, -8.212664074199102],
              [-39.958057688595844, -8.21266404396447],
              [-39.958057694165539, -8.212664013748505],
              [-39.958057699838228, -8.212663983551554],
              [-39.958057705613825, -8.212663953373983],
              [-39.958057711492287, -8.212663923216148],
              [-39.958057717473544, -8.212663893078348],
              [-39.958057723557495, -8.212663862960977],
              [-39.958057729744112, -8.212663832864385],
              [-39.958057736033297, -8.212663802788891],
              [-39.958057742424984, -8.212663772734855],
              [-39.95805774891911, -8.212663742702624],
              [-39.958057755515583, -8.212663712692519],
              [-39.958057762214338, -8.212663682704935],
              [-39.958057769015291, -8.212663652740185],
              [-39.958057775918363, -8.212663622798607],
              [-39.958057782923483, -8.212663592880608],
              [-39.958057790030573, -8.212663562986437],
              [-39.95805779723954, -8.212663533116505],
              [-39.9580578045503, -8.212663503271159],
              [-39.958057811962775, -8.212663473450727],
              [-39.958057819476878, -8.212663443655536],
              [-39.95805782709251, -8.212663413885945],
              [-39.958057834809615, -8.212663384142296],
              [-39.958057842628079, -8.212663354424938],
              [-39.958057850547796, -8.212663324734203],
              [-39.958057858568715, -8.212663295070456],
              [-39.95805786669073, -8.212663265433997],
              [-39.958057874913706, -8.212663235825225],
              [-39.958057883237622, -8.212663206244429],
              [-39.958057891662328, -8.212663176691981],
              [-39.958057900187747, -8.212663147168204],
              [-39.958057908813764, -8.212663117673483],
              [-39.958057917540302, -8.212663088208105],
              [-39.958057926367239, -8.212663058772424],
              [-39.958057935294498, -8.212663029366773],
              [-39.958057944321958, -8.212662999991514],
              [-39.958057953449504, -8.212662970646962],
              [-39.95805796267706, -8.212662941333473],
              [-39.958057972004482, -8.212662912051382],
              [-39.958057981431708, -8.212662882801029],
              [-39.958057990958601, -8.212662853582749],
              [-39.958058000585062, -8.212662824396864],
              [-39.958058010310957, -8.212662795243725],
              [-39.9580580201362, -8.212662766123675],
              [-39.958058030060677, -8.212662737037045],
              [-39.958058040084261, -8.21266270798416],
              [-39.958058050206823, -8.212662678965369],
              [-39.958058060428279, -8.212662649980995],
              [-39.9580580707485, -8.212662621031397],
              [-39.958058081167351, -8.212662592116871],
              [-39.958058091684727, -8.212662563237789],
              [-39.958058102300505, -8.21266253439444],
              [-39.958058113014552, -8.212662505587213],
              [-39.95805812382676, -8.212662476816392],
              [-39.958058134737009, -8.212662448082316],
              [-39.958058145745149, -8.212662419385337],
              [-39.95805815685106, -8.212662390725777],
              [-39.958058168054635, -8.212662362103957],
              [-39.958058179355724, -8.21266233352023],
              [-39.958058190754201, -8.212662304974899],
              [-39.958058202249923, -8.212662276468315],
              [-39.958058213842776, -8.212662248000809],
              [-39.958058225532618, -8.212662219572689],
              [-39.958058237319321, -8.212662191184283],
              [-39.958058249202736, -8.212662162835926],
              [-39.958058261182735, -8.212662134527923],
              [-39.958058273259191, -8.212662106260657],
              [-39.958058285431939, -8.212662078034436],
              [-39.958058297700859, -8.212662049849548],
              [-39.958058310065802, -8.212662021706343],
              [-39.958058322526618, -8.212661993605161],
              [-39.95805833508318, -8.212661965546289],
              [-39.958058347735331, -8.212661937530079],
              [-39.958058360482923, -8.21266190955685],
              [-39.958058373325819, -8.212661881626929],
              [-39.958058386263872, -8.212661853740627],
              [-39.958058399296895, -8.212661825898275],
              [-39.958058412424798, -8.21266179810017],
              [-39.958058425647394, -8.212661770346649],
              [-39.958058438964535, -8.212661742638067],
              [-39.958058452376072, -8.212661714974661],
              [-39.958058465881841, -8.212661687356837],
              [-39.958058479481643, -8.212661659785283],
              [-39.958058479911806, -8.212661658924278],
              [-39.958058480316119, -8.212661658108431],
              [-39.958058493369862, -8.212661631877772],
              [-39.958507503128857, -8.211762946678348],
              [-39.958507548242899, -8.211762941036776],
              [-39.958507571163686, -8.211762894835983],
              [-39.958507593819846, -8.211762848505805],
              [-39.958507616210667, -8.211762802047749],
              [-39.958507638335384, -8.211762755463326],
              [-39.958507660193334, -8.211762708754065],
              [-39.95850768178375, -8.21176266192151],
              [-39.95850770310598, -8.2117626149671],
              [-39.958507724159304, -8.211762567892443],
              [-39.958507744943027, -8.211762520699024],
              [-39.958507765456517, -8.211762473388353],
              [-39.958507785699055, -8.211762425962025],
              [-39.958507805670024, -8.211762378421534],
              [-39.958507825368763, -8.211762330768437],
              [-39.958507844794639, -8.211762283004298],
              [-39.958507863946998, -8.211762235130653],
              [-39.958507882825252, -8.211762187149041],
              [-39.95850790142876, -8.211762139061049],
              [-39.958507919756926, -8.211762090868229],
              [-39.958507937809159, -8.211762042572138],
              [-39.958507955584864, -8.211761994174333],
              [-39.958507973083471, -8.211761945676413],
              [-39.958507990304433, -8.211761897079947],
              [-39.958508007247154, -8.211761848386489],
              [-39.958508023911101, -8.211761799597648],
              [-39.958508040295726, -8.211761750714988],
              [-39.958508056400504, -8.211761701740118],
              [-39.958508072224916, -8.211761652674625],
              [-39.958508087768436, -8.211761603520079],
              [-39.95850810303056, -8.211761554278096],
              [-39.958508118010812, -8.211761504950266],
              [-39.958508132708687, -8.211761455538216],
              [-39.958508147123709, -8.211761406043484],
              [-39.958508161255409, -8.211761356467763],
              [-39.958508175103347, -8.211761306812605],
              [-39.958508188667039, -8.21176125707964],
              [-39.958508201946088, -8.211761207270484],
              [-39.958508214940032, -8.211761157386755],
              [-39.958508227648444, -8.211761107430073],
              [-39.958508240070941, -8.211761057402036],
              [-39.958508252207082, -8.211761007304299],
              [-39.958508264056512, -8.211760957138473],
              [-39.958508275618819, -8.211760906906186],
              [-39.95850828689364, -8.211760856609084],
              [-39.958508297880599, -8.211760806248789],
              [-39.958508308579361, -8.211760755826948],
              [-39.958508318989544, -8.21176070534518],
              [-39.958508329110835, -8.21176065480514],
              [-39.958508338942892, -8.211760604208447],
              [-39.95850834848541, -8.211760553556759],
              [-39.958508357738076, -8.211760502851723],
              [-39.958508366700585, -8.211760452094987],
              [-39.958508375372645, -8.211760401288187],
              [-39.958508383753973, -8.211760350432987],
              [-39.958508391844305, -8.211760299531003],
              [-39.958508399643371, -8.211760248583944],
              [-39.958508407150923, -8.211760197593442],
              [-39.958508414366705, -8.211760146561135],
              [-39.958508421290503, -8.211760095488701],
              [-39.958508427922077, -8.211760044377799],
              [-39.958508434261205, -8.211759993230054],
              [-39.95850844030771, -8.211759942047179],
              [-39.958508446061366, -8.211759890830818],
              [-39.958508451522007, -8.211759839582607],
              [-39.958508456689444, -8.211759788304215],
              [-39.958508461563511, -8.21175973699733],
              [-39.958508466144053, -8.211759685663614],
              [-39.958508470430921, -8.211759634304707],
              [-39.958508474423965, -8.211759582922324],
              [-39.958508478123065, -8.211759531518084],
              [-39.958508481528099, -8.211759480093701],
              [-39.958508484638962, -8.21175942865082],
              [-39.958508487455546, -8.211759377191102],
              [-39.958508489977767, -8.211759325716232],
              [-39.958508492205532, -8.211759274227873],
              [-39.958508494138769, -8.211759222727705],
              [-39.95850849577743, -8.211759171217404],
              [-39.95850849712145, -8.211759119698641],
              [-39.958508498170787, -8.211759068173082],
              [-39.958508498925411, -8.211759016642377],
              [-39.958508499385296, -8.211758965108269],
              [-39.958508499550426, -8.211758913572346],
              [-39.958508499420788, -8.211758862036339],
              [-39.958508498996395, -8.211758810501909],
              [-39.958508498277268, -8.21175875897074],
              [-39.958508497263416, -8.211758707444462],
              [-39.958508495954867, -8.211758655924806],
              [-39.958508494351683, -8.211758604413395],
              [-39.958508492453909, -8.211758552911904],
              [-39.9585084902616, -8.211758501422059],
              [-39.958508487774836, -8.211758449945469],
              [-39.958508484993693, -8.211758398483866],
              [-39.958508481918265, -8.211758347038845],
              [-39.958508478548652, -8.211758295612132],
              [-39.958508474884951, -8.211758244205377],
              [-39.958508470927285, -8.211758192820275],
              [-39.958508466675795, -8.211758141458482],
              [-39.958508462130609, -8.211758090121625],
              [-39.958508457291884, -8.211758038811428],
              [-39.958508452159755, -8.211757987529513],
              [-39.958508489776023, -8.211757969978258],
              [-39.958392774126708, -8.210634494221399],
              [-39.958392681178466, -8.210633590015041],
              [-39.958389720728057, -8.20939887250824],
              [-39.958389763692367, -8.209398864632124],
              [-39.958389763856395, -8.209398784526092],
              [-39.958389764732559, -8.209398704424645],
              [-39.958389766320799, -8.209398624334058],
              [-39.958389768620982, -8.209398544260644],
              [-39.95838977163293, -8.209398464210652],
              [-39.958389775356423, -8.209398384190367],
              [-39.958389779791148, -8.209398304206095],
              [-39.958389784936763, -8.209398224264094],
              [-39.958389790792864, -8.209398144370629],
              [-39.958389797358997, -8.209398064531971],
              [-39.958389804634649, -8.20939798475438],
              [-39.958389812619231, -8.209397905044131],
              [-39.958389821312139, -8.209397825407473],
              [-39.958389830712683, -8.209397745850646],
              [-39.958389840820104, -8.209397666379887],
              [-39.958389851633648, -8.209397587001417],
              [-39.958389863152441, -8.209397507721503],
              [-39.958389875375573, -8.209397428546332],
              [-39.958389888302115, -8.209397349482154],
              [-39.958389901931028, -8.209397270535103],
              [-39.958389916261254, -8.209397191711419],
              [-39.958389931291663, -8.209397113017314],
              [-39.958389947021075, -8.209397034458892],
              [-39.958389963448269, -8.209396956042358],
              [-39.958389980571937, -8.209396877773845],
              [-39.958389998390757, -8.209396799659503],
              [-39.958390016903302, -8.209396721705486],
              [-39.958390036108156, -8.209396643917884],
              [-39.958390056003779, -8.209396566302789],
              [-39.958390076588621, -8.209396488866309],
              [-39.958390097861091, -8.209396411614499],
              [-39.958390119819491, -8.209396334553436],
              [-39.9583901424621, -8.209396257689178],
              [-39.958390165787151, -8.209396181027707],
              [-39.958390189792809, -8.209396104575093],
              [-39.958390214477205, -8.209396028337304],
              [-39.95839023983838, -8.20939595232031],
              [-39.95839026587435, -8.20939587653009],
              [-39.958390292583104, -8.209395800972585],
              [-39.958390319962497, -8.209395725653744],
              [-39.958390348010418, -8.20939565057947],
              [-39.958390376724644, -8.209395575755631],
              [-39.958390406102943, -8.209395501188089],
              [-39.958390436142999, -8.209395426882702],
              [-39.958390466842452, -8.20939535284532],
              [-39.958390498198909, -8.209395279081741],
              [-39.958390530209883, -8.209395205597749],
              [-39.958390562872879, -8.209395132399115],
              [-39.958390596185332, -8.209395059491571],
              [-39.958390630144642, -8.209394986880834],
              [-39.95839066474813, -8.209394914572622],
              [-39.958390699993096, -8.2093948425726],
              [-39.958390735876755, -8.209394770886401],
              [-39.958390772396292, -8.20939469951966],
              [-39.958390809548867, -8.209394628477963],
              [-39.958390847331529, -8.209394557766885],
              [-39.958390885741345, -8.209394487392014],
              [-39.958390924775301, -8.209394417358817],
              [-39.958390964430322, -8.209394347672808],
              [-39.958391004703294, -8.209394278339468],
              [-39.958391045591064, -8.209394209364214],
              [-39.958391087090419, -8.20939414075246],
              [-39.958391129198127, -8.209394072509609],
              [-39.958391171910861, -8.209394004641],
              [-39.958391215225269, -8.209393937151955],
              [-39.958391259137962, -8.209393870047773],
              [-39.958391303645492, -8.209393803333725],
              [-39.958391348744371, -8.209393737015017],
              [-39.958391394431047, -8.209393671096864],
              [-39.958391440701959, -8.209393605584458],
              [-39.958391487553463, -8.209393540482898],
              [-39.958391534981892, -8.209393475797347],
              [-39.958391582983509, -8.209393411532847],
              [-39.958391631554569, -8.209393347694435],
              [-39.958391680691236, -8.209393284287133],
              [-39.958391730389678, -8.209393221315906],
              [-39.958391780645997, -8.2093931587857],
              [-39.958391831456233, -8.20939309670141],
              [-39.958391882816414, -8.209393035067908],
              [-39.958391934722499, -8.209392973890049],
              [-39.958391987170437, -8.209392913172604],
              [-39.958392040156092, -8.209392852920351],
              [-39.958392093675307, -8.209392793137996],
              [-39.958392147723892, -8.209392733830288],
              [-39.958392202297617, -8.209392675001821],
              [-39.958392257392184, -8.20939261665723],
              [-39.958392313003273, -8.209392558801099],
              [-39.958392369126521, -8.209392501437947],
              [-39.958392425757559, -8.209392444572304],
              [-39.958392482891888, -8.209392388208608],
              [-39.958392540525054, -8.209392332351266],
              [-39.958392598652544, -8.209392277004705],
              [-39.958392657269791, -8.209392222173246],
              [-39.958392716372188, -8.209392167861186],
              [-39.958392775955105, -8.209392114072786],
              [-39.95839283601385, -8.209392060812243],
              [-39.958392896543749, -8.209392008083762],
              [-39.958392957540021, -8.209391955891485],
              [-39.95839301899808, -8.209391904239133],
              [-39.958393062865298, -8.209391900462196],
              [-39.959065849035582, -8.208831453125356],
              [-39.959065845653541, -8.208831491193756],
              [-39.959065889195784, -8.208831455155538],
              [-39.959065932962744, -8.208831419388522],
              [-39.959065976952708, -8.208831383894083],
              [-39.95906602116397, -8.208831348673625],
              [-39.959066065594811, -8.208831313728552],
              [-39.959066110243512, -8.208831279060146],
              [-39.959066155108331, -8.208831244669812],
              [-39.959066200187529, -8.20883121055885],
              [-39.959066245479335, -8.208831176728648],
              [-39.959066290982008, -8.208831143180451],
              [-39.959066336693759, -8.208831109915584],
              [-39.959066382612832, -8.208831076935345],
              [-39.95906642873743, -8.208831044241037],
              [-39.959066475065775, -8.208831011833906],
              [-39.959066521596014, -8.208830979715207],
              [-39.959066568326413, -8.208830947886243],
              [-39.959066615255104, -8.208830916348164],
              [-39.959066662380266, -8.208830885102266],
              [-39.959066709700089, -8.208830854149767],
              [-39.959066757212724, -8.208830823491811],
              [-39.959066804916318, -8.208830793129652],
              [-39.959066852809002, -8.208830763064412],
              [-39.959066900888942, -8.208830733297326],
              [-39.959066949154263, -8.208830703829458],
              [-39.95906699760306, -8.208830674662059],
              [-39.959067046233478, -8.208830645796201],
              [-39.959067095043636, -8.208830617233025],
              [-39.959067144031614, -8.208830588973649],
              [-39.959067193195501, -8.208830561019145],
              [-39.959067242533408, -8.208830533370641],
              [-39.959067292043386, -8.20883050602915],
              [-39.959067341723539, -8.208830478995781],
              [-39.959067391571928, -8.208830452271595],
              [-39.959067441586605, -8.208830425857599],
              [-39.959067491765644, -8.208830399754838],
              [-39.959067542107071, -8.208830373964318],
              [-39.959067592608939, -8.20883034848706],
              [-39.959067643269293, -8.208830323324051],
              [-39.959067694086137, -8.208830298476242],
              [-39.959067745057531, -8.20883027394461],
              [-39.959067796181465, -8.208830249730108],
              [-39.959067847455962, -8.208830225833715],
              [-39.959067898879034, -8.208830202256287],
              [-39.959067950448677, -8.208830178998838],
              [-39.959068002162866, -8.208830156062151],
              [-39.959068054019625, -8.208830133447242],
              [-39.9590681060169, -8.208830111154905],
              [-39.959068158152697, -8.208830089186057],
              [-39.959068210424988, -8.208830067541493],
              [-39.959068262831735, -8.208830046222127],
              [-39.959068315370892, -8.208830025228742],
              [-39.959068368040441, -8.208830004562184],
              [-39.959068420838285, -8.208829984223211],
              [-39.959068473762422, -8.208829964212661],
              [-39.959068526810761, -8.208829944531276],
              [-39.959068579981263, -8.208829925179847],
              [-39.959068633271855, -8.208829906159119],
              [-39.959068686680446, -8.20882988746982],
              [-39.95906874020497, -8.208829869112691],
              [-39.959068793843365, -8.20882985108846],
              [-39.959068847593514, -8.208829833397772],
              [-39.959068901453357, -8.208829816041387],
              [-39.959068955420769, -8.208829799019913],
              [-39.959069009493675, -8.208829782334089],
              [-39.959069063669965, -8.208829765984454],
              [-39.959069117947529, -8.208829749971772],
              [-39.959069172324256, -8.20882973429655],
              [-39.95906922679805, -8.208829718959475],
              [-39.959069281366759, -8.208829703961111],
              [-39.9590693360283, -8.208829689302037],
              [-39.959069390780513, -8.20882967498285],
              [-39.959069445621282, -8.208829661004083],
              [-39.95906950054848, -8.208829647366281],
              [-39.959069555559978, -8.208829634069982],
              [-39.959069610653614, -8.208829621115706],
              [-39.959069665827258, -8.208829608503974],
              [-39.959069721078762, -8.208829596235251],
              [-39.959069776406004, -8.208829584309987],
              [-39.959069831806794, -8.2088295727287],
              [-39.959069887278979, -8.208829561491804],
              [-39.95906994282042, -8.208829550599766],
              [-39.959069998428973, -8.208829540052982],
              [-39.959070054102448, -8.208829529851867],
              [-39.959070109838684, -8.208829519996822],
              [-39.959070165635517, -8.208829510488229],
              [-39.959070221490791, -8.208829501326456],
              [-39.959070277402297, -8.208829492511853],
              [-39.959070333367904, -8.208829484044813],
              [-39.95907038938541, -8.208829475925571],
              [-39.959070445452646, -8.208829468154541],
              [-39.959070501567425, -8.208829460731963],
              [-39.959070557727578, -8.208829453658142],
              [-39.959070613930912, -8.208829446933377],
              [-39.959070670175237, -8.208829440557867],
              [-39.959070726458378, -8.208829434531925],
              [-39.959070782778149, -8.208829428855761],
              [-39.95907083913233, -8.208829423529577],
              [-39.959070895518764, -8.208829418553602],
              [-39.95907095193548, -8.20882941392736],
              [-39.959070927893876, -8.208829451290905],
              [-39.960534199801003, -8.208713996141878],
              [-39.960534244280161, -8.208714011304819],
              [-39.960534352477133, -8.208714003419521],
              [-39.960534460760954, -8.208713996822956],
              [-39.960534569116163, -8.208713991516078],
              [-39.960534677527285, -8.208713987499674],
              [-39.960534785978858, -8.208713984774297],
              [-39.960534894455357, -8.208713983340328],
              [-39.960535002941327, -8.208713983197979],
              [-39.960535111421244, -8.208713984347266],
              [-39.960535219879638, -8.208713986788011],
              [-39.960535328301013, -8.208713990519907],
              [-39.960535436669872, -8.208713995542398],
              [-39.960535544970753, -8.208714001854762],
              [-39.960535653188195, -8.208714009456106],
              [-39.96053576130673, -8.208714018345331],
              [-39.96053586931091, -8.208714028521184],
              [-39.96053597718533, -8.208714039982205],
              [-39.960536084914565, -8.208714052726767],
              [-39.960536192483239, -8.208714066753034],
              [-39.96053629987599, -8.208714082058986],
              [-39.960536407077484, -8.208714098642469],
              [-39.960536514072416, -8.208714116501113],
              [-39.960536620845488, -8.208714135632361],
              [-39.960536727381452, -8.208714156033468],
              [-39.960536833665103, -8.208714177701536],
              [-39.960536939681262, -8.208714200633478],
              [-39.960537045414796, -8.208714224825981],
              [-39.960537150850591, -8.208714250275618],
              [-39.960537255973577, -8.208714276978789],
              [-39.960537360768775, -8.208714304931613],
              [-39.960537465221194, -8.208714334130143],
              [-39.960537569315932, -8.208714364570195],
              [-39.960537673038097, -8.208714396247428],
              [-39.960537776372902, -8.208714429157313],
              [-39.960537879305583, -8.208714463295115],
              [-39.960537981821439, -8.208714498656017],
              [-39.960538083905817, -8.20871453523492],
              [-39.960538185544145, -8.208714573026635],
              [-39.960538286721921, -8.208714612025769],
              [-39.960538387424684, -8.20871465222673],
              [-39.96053848763804, -8.208714693623778],
              [-39.960538587347706, -8.208714736211014],
              [-39.960538686539408, -8.208714779982341],
              [-39.960538785199013, -8.20871482493153],
              [-39.96053888331241, -8.208714871052138],
              [-39.9605389808656, -8.208714918337584],
              [-39.960539077844636, -8.208714966781105],
              [-39.96053917423567, -8.208715016375821],
              [-39.960539270024952, -8.208715067114614],
              [-39.960539365198798, -8.20871511899024],
              [-39.960539459743586, -8.208715171995324],
              [-39.960539553645845, -8.208715226122226],
              [-39.960539646892165, -8.208715281363284],
              [-39.960539739469205, -8.208715337710595],
              [-39.960539831363782, -8.208715395156084],
              [-39.960539922562731, -8.208715453691578],
              [-39.960540013053041, -8.208715513308711],
              [-39.960540102821795, -8.208715573998962],
              [-39.960540191856175, -8.208715635753643],
              [-39.960540280143462, -8.208715698563969],
              [-39.960540367671044, -8.208715762420942],
              [-39.960540454426429, -8.208715827315443],
              [-39.960540540397204, -8.208715893238237],
              [-39.96054062557112, -8.208715960179889],
              [-39.960540709935991, -8.208716028130835],
              [-39.960540793479794, -8.208716097081371],
              [-39.960540876190571, -8.208716167021672],
              [-39.960540958056527, -8.208716237941697],
              [-39.960541039065959, -8.208716309831384],
              [-39.960541119207299, -8.208716382680414],
              [-39.960541198469109, -8.208716456478403],
              [-39.960541276840068, -8.208716531214806],
              [-39.960541354308965, -8.20871660687896],
              [-39.960541430864779, -8.208716683460057],
              [-39.96054150649654, -8.208716760947159],
              [-39.960541581193446, -8.208716839329163],
              [-39.960541654944855, -8.208716918594938],
              [-39.960541727740221, -8.208716998733134],
              [-39.960541799569107, -8.208717079732303],
              [-39.960541870421331, -8.208717161580894],
              [-39.960541940286731, -8.208717244267172],
              [-39.960542009155326, -8.208717327779377],
              [-39.960542077017301, -8.208717412105557],
              [-39.960542143862931, -8.208717497233689],
              [-39.960542209682721, -8.208717583151605],
              [-39.960542274467223, -8.208717669847029],
              [-39.96054233820719, -8.208717757307594],
              [-39.960542400893551, -8.208717845520782],
              [-39.960542462517331, -8.208717934474018],
              [-39.960542523069726, -8.208718024154592],
              [-39.960542582542104, -8.208718114549699],
              [-39.960542640925951, -8.208718205646434],
              [-39.96054269821294, -8.208718297431764],
              [-39.960542754394901, -8.208718389892603],
              [-39.960542809463796, -8.20871848301574],
              [-39.96054286341176, -8.208718576787886],
              [-39.960542916231077, -8.208718671195642],
              [-39.960542967914236, -8.208718766225504],
              [-39.960543018453841, -8.208718861863932],
              [-39.960543067842707, -8.208718958097018],
              [-39.960543108101696, -8.208718949245844],
              [-39.960986221949057, -8.209595276834593],
              [-39.960986218312691, -8.20959530647573],
              [-39.96098631615677, -8.209595494456401],
              [-39.960986418391329, -8.209595680103384],
              [-39.960986524960326, -8.209595863314915],
              [-39.960986635805327, -8.209596043990576],
              [-39.960986750865573, -8.209596222031323],
              [-39.96098687007801, -8.209596397339544],
              [-39.960986993377269, -8.209596569819174],
              [-39.960987120695791, -8.209596739375629],
              [-39.960987251963751, -8.209596905915967],
              [-39.960987387109192, -8.209597069348934],
              [-39.960987526058048, -8.209597229584883],
              [-39.960987668734148, -8.209597386536013],
              [-39.960987815059298, -8.209597540116285],
              [-39.960987964953247, -8.20959769024153],
              [-39.960988118333866, -8.209597836829428],
              [-39.96098827511706, -8.209597979799605],
              [-39.960988435216898, -8.209598119073735],
              [-39.960988598545576, -8.209598254575452],
              [-39.960988765013624, -8.209598386230457],
              [-39.960988934529745, -8.209598513966609],
              [-39.960989107001026, -8.209598637713874],
              [-39.960989282332939, -8.209598757404438],
              [-39.960989460429353, -8.209598872972656],
              [-39.960989641192668, -8.209598984355225],
              [-39.960989824523757, -8.209599091491055],
              [-39.960990010322149, -8.209599194321427],
              [-39.960990198485995, -8.209599292789944],
              [-39.960990388912137, -8.209599386842667],
              [-39.960990581496212, -8.209599476428034],
              [-39.960990776132626, -8.209599561496923],
              [-39.96099097271469, -8.209599642002702],
              [-39.960991171134658, -8.209599717901265],
              [-39.960991371283754, -8.209599789150989],
              [-39.960991573052283, -8.209599855712788],
              [-39.960991776329593, -8.209599917550234],
              [-39.960991981004298, -8.20959997462939],
              [-39.960992186964177, -8.209600026918979],
              [-39.960992394096351, -8.209600074390337],
              [-39.96099260228727, -8.209600117017425],
              [-39.960992811422798, -8.209600154776906],
              [-39.960993021388305, -8.209600187648068],
              [-39.960993232068688, -8.209600215612872],
              [-39.960993443348464, -8.209600238656009],
              [-39.960993655111807, -8.209600256764853],
              [-39.960993867242642, -8.209600269929469],
              [-39.960994079624697, -8.209600278142645],
              [-39.960994292141535, -8.209600281399888],
              [-39.960994504676663, -8.209600279699366],
              [-39.960994717113579, -8.209600273042055],
              [-39.96099492933584, -8.209600261431612],
              [-39.960995141227102, -8.20960024487437],
              [-39.960995352671212, -8.209600223379409],
              [-39.960995563552274, -8.209600196958515],
              [-39.960995773754675, -8.209600165626203],
              [-39.960995983163187, -8.209600129399604],
              [-39.960996191663035, -8.209600088298616],
              [-39.960996399139923, -8.209600042345729],
              [-39.960996605480112, -8.209599991566167],
              [-39.960996810570492, -8.209599935987745],
              [-39.960997014298648, -8.20959987564094],
              [-39.960997216552897, -8.209599810558819],
              [-39.960997417222373, -8.209599740777072],
              [-39.960997616197083, -8.209599666333949],
              [-39.960997813367911, -8.209599587270244],
              [-39.960998008626824, -8.20959950362931],
              [-39.960998201866772, -8.209599415457017],
              [-39.960998392981828, -8.209599322801626],
              [-39.960998581867237, -8.209599225714038],
              [-39.960998768419437, -8.20959912424737],
              [-39.96099895253618, -8.209599018457279],
              [-39.960999134116541, -8.209598908401771],
              [-39.960999313060974, -8.209598794141172],
              [-39.960999489271401, -8.209598675738114],
              [-39.960999662651197, -8.209598553257484],
              [-39.960999833105376, -8.209598426766455],
              [-39.961000000540444, -8.209598296334342],
              [-39.961000164864657, -8.209598162032631],
              [-39.961000325987925, -8.209598023934976],
              [-39.96100048382192, -8.209597882117054],
              [-39.961000638280126, -8.209597736656603],
              [-39.961000789277868, -8.20959758763335],
              [-39.961000936732383, -8.209597435129021],
              [-39.961001080562852, -8.209597279227186],
              [-39.961001220690406, -8.209597120013328],
              [-39.961001357038249, -8.209596957574698],
              [-39.961001489531633, -8.209596792000369],
              [-39.961001618097917, -8.209596623381058],
              [-39.96100174266666, -8.209596451809258],
              [-39.96100186316955, -8.209596277378941],
              [-39.961001979540548, -8.20959610018579],
              [-39.961002091715834, -8.209595920326926],
              [-39.961002199633938, -8.209595737900928],
              [-39.961002303235709, -8.209595553007798],
              [-39.961002402464352, -8.209595365748877],
              [-39.961002497265454, -8.209595176226861],
              [-39.961002587587068, -8.209594984545589],
              [-39.961002673379674, -8.209594790810156],
              [-39.961002754596258, -8.209594595126729],
              [-39.961002831192275, -8.209594397602615],
              [-39.961002832596691, -8.209594423213217],
              [-39.961333146316335, -8.208712089981125],
              [-39.96133350725772, -8.208711184692772],
              [-39.961649532590705, -8.208078597682697],
              [-39.937643130430516, -8.207895159726451],
              [-39.939128528734805, -8.208537554581648],
              [-39.939128541844333, -8.208537523208319],
              [-39.939128592215319, -8.20853754517057],
              [-39.93912864245187, -8.208537567436368],
              [-39.939128692552146, -8.208537590004891],
              [-39.939128742514292, -8.208537612875338],
              [-39.939128792336469, -8.208537636046863],
              [-39.939128842016899, -8.208537659518573],
              [-39.939128891553693, -8.20853768328965],
              [-39.939128940945089, -8.20853770735923],
              [-39.939128990189253, -8.208537731726393],
              [-39.939129039284367, -8.208537756390276],
              [-39.939129088228654, -8.20853778134995],
              [-39.939129137020281, -8.208537806604498],
              [-39.939129185657499, -8.208537832153022],
              [-39.939129234138512, -8.20853785799455],
              [-39.939129282461529, -8.208537884128171],
              [-39.93912933062478, -8.208537910552899],
              [-39.939129378626518, -8.208537937267758],
              [-39.939129426464952, -8.2085379642718],
              [-39.939129474138362, -8.208537991564022],
              [-39.939129521644958, -8.208538019143408],
              [-39.939129568983034, -8.20853804700897],
              [-39.939129616150822, -8.208538075159684],
              [-39.93912966314663, -8.208538103594471],
              [-39.939129709968689, -8.208538132312329],
              [-39.939129756615323, -8.208538161312198],
              [-39.939129803084796, -8.208538190593018],
              [-39.939129849375398, -8.208538220153702],
              [-39.939129895485451, -8.208538249993197],
              [-39.939129941413256, -8.208538280110357],
              [-39.939129987157123, -8.208538310504117],
              [-39.93913003271539, -8.208538341173364],
              [-39.939130078086365, -8.208538372116969],
              [-39.939130123268384, -8.208538403333757],
              [-39.939130168259808, -8.208538434822634],
              [-39.939130213058966, -8.208538466582425],
              [-39.939130257664232, -8.208538498611961],
              [-39.939130302073963, -8.208538530910076],
              [-39.939130346286525, -8.208538563475576],
              [-39.939130390300299, -8.20853859630726],
              [-39.939130434113686, -8.208538629403938],
              [-39.939130477725037, -8.208538662764404],
              [-39.939130521132803, -8.208538696387389],
              [-39.939130564335343, -8.208538730271719],
              [-39.939130607331116, -8.208538764416119],
              [-39.939130650118528, -8.208538798819337],
              [-39.93913069269599, -8.208538833480123],
              [-39.939130735061987, -8.208538868397193],
              [-39.939130777214913, -8.208538903569265],
              [-39.939130819153249, -8.208538938995078],
              [-39.939130860875444, -8.208538974673301],
              [-39.939130902379986, -8.208539010602623],
              [-39.93913094366534, -8.208539046781743],
              [-39.939130984729985, -8.208539083209326],
              [-39.939131025572429, -8.208539119884021],
              [-39.939131066191152, -8.208539156804505],
              [-39.939131106584682, -8.208539193969427],
              [-39.939131146751556, -8.208539231377419],
              [-39.939131186690261, -8.20853926902711],
              [-39.939131226399354, -8.208539306917094],
              [-39.939131265877364, -8.208539345045994],
              [-39.939131305122856, -8.208539383412445],
              [-39.939131344134388, -8.208539422015006],
              [-39.939131382910531, -8.208539460852245],
              [-39.939131421449851, -8.208539499922766],
              [-39.939131459750946, -8.208539539225145],
              [-39.939131497812411, -8.208539578757911],
              [-39.939131535632846, -8.208539618519628],
              [-39.939131573210865, -8.208539658508831],
              [-39.939131610545076, -8.208539698724035],
              [-39.939131647634134, -8.208539739163824],
              [-39.939131684476656, -8.208539779826655],
              [-39.939131721071298, -8.208539820711056],
              [-39.939131757416725, -8.208539861815542],
              [-39.939131793511592, -8.20853990313859],
              [-39.939131829354587, -8.2085399446787],
              [-39.939131864944386, -8.208539986434305],
              [-39.939131900279691, -8.208540028403915],
              [-39.939131935359207, -8.208540070585977],
              [-39.939131970181634, -8.208540112978936],
              [-39.939132004745709, -8.208540155581272],
              [-39.939132039050136, -8.208540198391372],
              [-39.939132073093695, -8.208540241407706],
              [-39.939132106875114, -8.208540284628677],
              [-39.939132140393177, -8.208540328052672],
              [-39.93913217364662, -8.208540371678156],
              [-39.939132206634234, -8.208540415503501],
              [-39.939132239354819, -8.208540459527095],
              [-39.939132271807175, -8.208540503747336],
              [-39.9391323039901, -8.208540548162583],
              [-39.939132335902428, -8.208540592771209],
              [-39.939132367542975, -8.208540637571579],
              [-39.939132398910594, -8.208540682562068],
              [-39.939132430004108, -8.208540727741003],
              [-39.939132460822428, -8.208540773106744],
              [-39.939132491364369, -8.208540818657635],
              [-39.939132521628821, -8.208540864391967],
              [-39.939132551614698, -8.208540910308084],
              [-39.939132581320884, -8.208540956404313],
              [-39.939132610745972, -8.208541002679974],
              [-39.93913262189001, -8.208540981881406],
              [-39.939696923091496, -8.2094344206635],
              [-39.93969693121953, -8.209434428803011],
              [-39.939696981017917, -8.209434506756828],
              [-39.939697031613079, -8.209434584199672],
              [-39.939697082999764, -8.209434661123506],
              [-39.939697135172594, -8.20943473752029],
              [-39.939697188126118, -8.209434813381984],
              [-39.939697241854816, -8.209434888700779],
              [-39.939697296353074, -8.209434963468723],
              [-39.939697351615187, -8.209435037678048],
              [-39.93969740763545, -8.209435111321019],
              [-39.939697464407935, -8.209435184389951],
              [-39.939697521926782, -8.209435256877223],
              [-39.939697580185936, -8.209435328775198],
              [-39.939697639179336, -8.209435400076455],
              [-39.939697698900822, -8.209435470773501],
              [-39.939697759344185, -8.209435540858978],
              [-39.939697820503078, -8.209435610325569],
              [-39.939697882371142, -8.209435679166017],
              [-39.939697944941912, -8.209435747373151],
              [-39.939698008208858, -8.209435814939821],
              [-39.939698072165349, -8.209435881858987],
              [-39.939698136804772, -8.209435948123687],
              [-39.939698202120326, -8.209436013726991],
              [-39.939698268105204, -8.209436078662041],
              [-39.939698334752521, -8.209436142922064],
              [-39.939698402055342, -8.209436206500369],
              [-39.939698470006604, -8.209436269390286],
              [-39.939698538599252, -8.209436331585266],
              [-39.939698607826074, -8.20943639307883],
              [-39.939698677679907, -8.209436453864555],
              [-39.939698748153425, -8.209436513936113],
              [-39.939698819239268, -8.209436573287181],
              [-39.939698890930032, -8.209436631911618],
              [-39.939698963218227, -8.209436689803228],
              [-39.939699036096307, -8.209436746956049],
              [-39.939699109556649, -8.209436803364119],
              [-39.939699183591628, -8.209436859021487],
              [-39.939699258193485, -8.209436913922399],
              [-39.939699333354426, -8.209436968061079],
              [-39.939699409066627, -8.209437021431913],
              [-39.939699485322166, -8.209437074029314],
              [-39.939699562113098, -8.209437125847753],
              [-39.939699639431403, -8.209437176881899],
              [-39.939699717269001, -8.209437227126358],
              [-39.939699795617784, -8.20943727657596],
              [-39.939699874469568, -8.209437325225453],
              [-39.93969995381611, -8.209437373069836],
              [-39.939700033649146, -8.209437420104077],
              [-39.93970011396032, -8.209437466323235],
              [-39.939700194741292, -8.209437511722571],
              [-39.93970027598359, -8.209437556297278],
              [-39.939700357678738, -8.209437600042701],
              [-39.93970043981821, -8.209437642954338],
              [-39.939700522393451, -8.209437685027668],
              [-39.939700605395821, -8.209437726258269],
              [-39.939700688816671, -8.209437766641877],
              [-39.939700772647285, -8.209437806174261],
              [-39.939700856878915, -8.209437844851317],
              [-39.939700941502764, -8.209437882668988],
              [-39.939701026510008, -8.209437919623298],
              [-39.939701111891765, -8.209437955710468],
              [-39.939701197639131, -8.209437990926656],
              [-39.93970128374314, -8.209438025268241],
              [-39.939701370194832, -8.209438058731596],
              [-39.939701456985169, -8.209438091313237],
              [-39.93970154410507, -8.209438123009788],
              [-39.939701631545475, -8.209438153817915],
              [-39.939701719297247, -8.209438183734397],
              [-39.939701807351206, -8.209438212756142],
              [-39.939701895698178, -8.209438240880088],
              [-39.939701984328934, -8.209438268103321],
              [-39.939702073234251, -8.209438294423009],
              [-39.939702162404814, -8.209438319836362],
              [-39.939702251831342, -8.209438344340773],
              [-39.939702341504464, -8.209438367933641],
              [-39.939702431414851, -8.209438390612567],
              [-39.939702521553116, -8.209438412375109],
              [-39.939702611909858, -8.209438433219061],
              [-39.9397027024756, -8.209438453142205],
              [-39.939702793240926, -8.209438472142466],
              [-39.939702884196379, -8.209438490217858],
              [-39.939702975332416, -8.209438507366521],
              [-39.939703066639574, -8.209438523586636],
              [-39.939703158108287, -8.209438538876524],
              [-39.939703249729007, -8.209438553234611],
              [-39.939703341492191, -8.209438566659335],
              [-39.939703433388253, -8.209438579149383],
              [-39.939703525407594, -8.20943859070335],
              [-39.939703617540623, -8.209438601320111],
              [-39.939703709777703, -8.209438610998523],
              [-39.939703802109236, -8.209438619737606],
              [-39.93970389452555, -8.209438627536414],
              [-39.939703987017026, -8.209438634394131],
              [-39.939704079574, -8.209438640310042],
              [-39.939704172186794, -8.209438645283571],
              [-39.939704264845766, -8.209438649314141],
              [-39.939704357541224, -8.209438652401349],
              [-39.939704450263513, -8.209438654544902],
              [-39.939704543002946, -8.209438655744549],
              [-39.939704635749834, -8.209438656000213],
              [-39.939704649365922, -8.209438653467657],
              [-39.94206800952518, -8.209433132045765],
              [-39.942068026105389, -8.209433134507764],
              [-39.942068181550866, -8.209433135470132],
              [-39.942068336957014, -8.209433139084183],
              [-39.942068492278281, -8.209433145348857],
              [-39.942068647469085, -8.209433154262292],
              [-39.942068802483952, -8.209433165821899],
              [-39.942068957277435, -8.209433180024297],
              [-39.942069111804116, -8.209433196865277],
              [-39.942069266018699, -8.209433216339926],
              [-39.942069419875956, -8.209433238442539],
              [-39.942069573330784, -8.209433263166634],
              [-39.942069726338197, -8.20943329050497],
              [-39.94206987885331, -8.209433320449524],
              [-39.94207003083141, -8.209433352991491],
              [-39.942070182227951, -8.209433388121365],
              [-39.942070332998505, -8.209433425828824],
              [-39.942070483098888, -8.209433466102826],
              [-39.942070632485077, -8.209433508931557],
              [-39.942070781113287, -8.209433554302446],
              [-39.942070928939899, -8.209433602202207],
              [-39.942071075921625, -8.209433652616786],
              [-39.942071222015315, -8.209433705531371],
              [-39.942071367178158, -8.209433760930521],
              [-39.942071511367587, -8.209433818797926],
              [-39.942071654541316, -8.20943387911665],
              [-39.942071796657366, -8.209433941868987],
              [-39.942071937674058, -8.209434007036551],
              [-39.942072077550058, -8.209434074600217],
              [-39.942072216244348, -8.209434144540195],
              [-39.942072353716256, -8.209434216835966],
              [-39.942072489925472, -8.209434291466335],
              [-39.942072624832065, -8.209434368409431],
              [-39.942072758396456, -8.209434447642645],
              [-39.942072890579503, -8.209434529142799],
              [-39.942073021342431, -8.209434612885966],
              [-39.942073150646927, -8.209434698847597],
              [-39.942073278455055, -8.209434787002495],
              [-39.942073404729328, -8.209434877324817],
              [-39.942073529432747, -8.209434969788052],
              [-39.942073652528727, -8.209435064365115],
              [-39.942073773981171, -8.209435161028239],
              [-39.94207389375449, -8.209435259749119],
              [-39.942074011813531, -8.209435360498789],
              [-39.942074128123714, -8.209435463247715],
              [-39.942074242650911, -8.209435567965748],
              [-39.942074355361548, -8.20943567462222],
              [-39.942074466222564, -8.209435783185807],
              [-39.942074575201481, -8.209435893624731],
              [-39.942074682266295, -8.209436005906582],
              [-39.942074787385664, -8.209436119998433],
              [-39.942074890528723, -8.209436235866836],
              [-39.942074991665272, -8.209436353477814],
              [-39.94207509076562, -8.209436472796892],
              [-39.942075187800725, -8.209436593789079],
              [-39.942075282742131, -8.209436716418889],
              [-39.942075375561991, -8.209436840650369],
              [-39.942075466233113, -8.209436966447091],
              [-39.942075554728873, -8.209437093772165],
              [-39.942075641023365, -8.20943722258826],
              [-39.94207572509125, -8.209437352857622],
              [-39.942075806907901, -8.209437484542034],
              [-39.942075886449317, -8.20943761760285],
              [-39.942075963692169, -8.209437752001111],
              [-39.942076038613813, -8.209437887697424],
              [-39.942076111192286, -8.209438024651917],
              [-39.942076181406307, -8.20943816282451],
              [-39.942076249235278, -8.209438302174643],
              [-39.942076314659332, -8.209438442661495],
              [-39.942076377659248, -8.20943858424382],
              [-39.942076438216588, -8.209438726880137],
              [-39.942076496313582, -8.209438870528622],
              [-39.942076551933198, -8.209439015147153],
              [-39.942076605059107, -8.209439160693307],
              [-39.94207665567577, -8.209439307124443],
              [-39.942076703768315, -8.209439454397598],
              [-39.942076749322652, -8.209439602469574],
              [-39.942076792325423, -8.20943975129698],
              [-39.942076832764016, -8.209439900836179],
              [-39.942076870626565, -8.209440051043289],
              [-39.942076905901992, -8.209440201874328],
              [-39.942076938579923, -8.209440353285022],
              [-39.942076968650802, -8.209440505230974],
              [-39.942076996105797, -8.209440657667644],
              [-39.942077020936871, -8.209440810550328],
              [-39.942077043136727, -8.209440963834211],
              [-39.942077062698871, -8.209441117474331],
              [-39.942077079617547, -8.209441271425655],
              [-39.942077093887818, -8.209441425643027],
              [-39.942077105505483, -8.209441580081229],
              [-39.942077114467132, -8.20944173469498],
              [-39.942077120770165, -8.209441889438942],
              [-39.942077124412691, -8.20944204426776],
              [-39.942077125393681, -8.209442199135996],
              [-39.942077123712828, -8.209442353998295],
              [-39.942077119370623, -8.209442508809188],
              [-39.942077112368352, -8.209442663523324],
              [-39.942077102708062, -8.209442818095312],
              [-39.94207709039258, -8.209442972479851],
              [-39.94207707542553, -8.209443126631657],
              [-39.94207705781195, -8.209443280504969],
              [-39.942077020590055, -8.209443240602633],
              [-39.941967546749495, -8.210332281754193],
              [-39.941967545246477, -8.210332279300799],
              [-39.941967538951225, -8.210332329253889],
              [-39.941967532376964, -8.210332379171303],
              [-39.941967525523907, -8.21033242905148],
              [-39.941967518392275, -8.210332478892871],
              [-39.941967510982259, -8.210332528693945],
              [-39.941967503294123, -8.21033257845313],
              [-39.941967495328065, -8.210332628168947],
              [-39.941967487084369, -8.210332677839778],
              [-39.941967478563278, -8.210332727464138],
              [-39.941967469765061, -8.210332777040476],
              [-39.941967460689973, -8.210332826567242],
              [-39.941967451338314, -8.210332876042909],
              [-39.941967441710368, -8.210332925465947],
              [-39.941967431806418, -8.210332974834833],
              [-39.941967421626799, -8.210333024148012],
              [-39.941967411171802, -8.210333073404001],
              [-39.941967400441762, -8.210333122601222],
              [-39.941967389437018, -8.210333171738181],
              [-39.941967378157891, -8.210333220813352],
              [-39.941967366604757, -8.210333269825197],
              [-39.941967354777958, -8.210333318772237],
              [-39.941967342677856, -8.210333367652916],
              [-39.941967330304834, -8.210333416465739],
              [-39.941967317659277, -8.210333465209168],
              [-39.941967304741574, -8.21033351388172],
              [-39.941967291552125, -8.210333562481896],
              [-39.941967278091326, -8.210333611008144],
              [-39.941967264359626, -8.210333659459003],
              [-39.941967250357415, -8.210333707832968],
              [-39.941967236085148, -8.210333756128501],
              [-39.941967221543258, -8.210333804344142],
              [-39.941967206732208, -8.210333852478424],
              [-39.941967191652459, -8.210333900529788],
              [-39.941967176304445, -8.210333948496778],
              [-39.941967160688677, -8.210333996377917],
              [-39.941967144805623, -8.210334044171697],
              [-39.941967128655776, -8.210334091876648],
              [-39.941967112239645, -8.210334139491337],
              [-39.941967095557729, -8.210334187014226],
              [-39.94196707861056, -8.210334234443852],
              [-39.941967061398636, -8.210334281778762],
              [-39.94196704392251, -8.210334329017497],
              [-39.941967026182724, -8.210334376158581],
              [-39.941967008179823, -8.210334423200553],
              [-39.94196698991437, -8.210334470141941],
              [-39.941966971386911, -8.210334516981312],
              [-39.941966952598044, -8.210334563717204],
              [-39.941966933548343, -8.210334610348186],
              [-39.941966914238392, -8.210334656872778],
              [-39.941966894668788, -8.210334703289558],
              [-39.941966874840176, -8.210334749597108],
              [-39.941966854753112, -8.210334795793955],
              [-39.941966834408255, -8.210334841878723],
              [-39.941966813806218, -8.210334887849909],
              [-39.94196679294766, -8.210334933706161],
              [-39.941966771833201, -8.210334979446001],
              [-39.941966750463529, -8.210335025068037],
              [-39.941966728839262, -8.210335070570862],
              [-39.94196670696109, -8.210335115953036],
              [-39.941966684829715, -8.210335161213196],
              [-39.9419666624458, -8.210335206349923],
              [-39.941966639810033, -8.210335251361839],
              [-39.941966616923125, -8.210335296247452],
              [-39.941966593785793, -8.210335341005489],
              [-39.941966570398733, -8.210335385634506],
              [-39.941966546762686, -8.210335430133131],
              [-39.941966522878374, -8.21033547449999],
              [-39.941966498746552, -8.210335518733686],
              [-39.941966474367938, -8.210335562832901],
              [-39.941966449743333, -8.210335606796203],
              [-39.94196642487347, -8.210335650622262],
              [-39.941966399759124, -8.210335694309723],
              [-39.941966374401076, -8.210335737857223],
              [-39.941966348800101, -8.210335781263412],
              [-39.941966322957001, -8.210335824526975],
              [-39.941966296872586, -8.210335867646528],
              [-39.941966270547631, -8.210335910620769],
              [-39.941966243982996, -8.210335953448334],
              [-39.941966217179484, -8.210335996127894],
              [-39.941966190137926, -8.210336038658161],
              [-39.94196616285916, -8.21033608103782],
              [-39.941966135344032, -8.210336123265508],
              [-39.94196610759338, -8.210336165339973],
              [-39.941966079608079, -8.210336207259884],
              [-39.941966051389009, -8.210336249023923],
              [-39.941966022937017, -8.21033629063084],
              [-39.941965994252996, -8.210336332079326],
              [-39.941965965337843, -8.210336373368092],
              [-39.941965936192439, -8.210336414495858],
              [-39.9419659068177, -8.210336455461341],
              [-39.941965877214528, -8.210336496263292],
              [-39.941965847383848, -8.21033653690043],
              [-39.941965817326583, -8.210336577371523],
              [-39.941965787043657, -8.210336617675269],
              [-39.941965756536007, -8.210336657810469],
              [-39.941965725804586, -8.210336697775832],
              [-39.941965694850346, -8.210336737570149],
              [-39.941965663674246, -8.210336777192193],
              [-39.94196563227699, -8.210336816641691],
              [-39.941965651028077, -8.210336808347259],
              [-39.941071551629619, -8.211453701622965],
              [-39.941071576530533, -8.211453743212491],
              [-39.941071469001884, -8.211453880227628],
              [-39.94107136413767, -8.211454019277463],
              [-39.94107126197666, -8.211454160310574],
              [-39.941071162556653, -8.211454303274772],
              [-39.9410710659144, -8.211454448117239],
              [-39.941070972085654, -8.211454594784389],
              [-39.941070881105098, -8.211454743221996],
              [-39.941070793006396, -8.211454893375134],
              [-39.941070707822092, -8.211455045188293],
              [-39.941070625583741, -8.211455198605361],
              [-39.941070546321704, -8.211455353569541],
              [-39.94107047006532, -8.211455510023606],
              [-39.941070396842782, -8.211455667909627],
              [-39.941070326681178, -8.211455827169248],
              [-39.941070259606427, -8.211455987743578],
              [-39.94107019564337, -8.211456149573234],
              [-39.941070134815639, -8.211456312598369],
              [-39.941070077145724, -8.211456476758672],
              [-39.941070022654969, -8.211456641993465],
              [-39.941069971363504, -8.211456808241627],
              [-39.941069923290314, -8.211456975441697],
              [-39.94106987845317, -8.211457143531858],
              [-39.941069836868664, -8.211457312449912],
              [-39.941069798552149, -8.211457482133399],
              [-39.941069763517817, -8.211457652519574],
              [-39.941069731778619, -8.211457823545434],
              [-39.941069703346272, -8.211457995147709],
              [-39.941069678231322, -8.211458167262963],
              [-39.941069656443034, -8.211458339827553],
              [-39.941069637989493, -8.211458512777654],
              [-39.941069622877478, -8.211458686049292],
              [-39.941069611112638, -8.211458859578434],
              [-39.941069602699265, -8.211459033300873],
              [-39.941069597640514, -8.211459207152357],
              [-39.941069595938217, -8.211459381068629],
              [-39.941069597593035, -8.211459554985353],
              [-39.94106960260433, -8.211459728838205],
              [-39.941069610970288, -8.211459902562915],
              [-39.94106962268777, -8.211460076095223],
              [-39.941069637752463, -8.211460249370955],
              [-39.941069656158803, -8.211460422326041],
              [-39.941069677899975, -8.211460594896531],
              [-39.941069702967944, -8.211460767018597],
              [-39.941069731353437, -8.211460938628578],
              [-39.941069763045945, -8.211461109663027],
              [-39.941069798033766, -8.211461280058678],
              [-39.941069836303946, -8.211461449752543],
              [-39.941069877842352, -8.211461618681843],
              [-39.941069922633602, -8.21146178678414],
              [-39.941069970661161, -8.211461953997222],
              [-39.941070021907223, -8.211462120259302],
              [-39.941070076352879, -8.211462285508853],
              [-39.941070133977981, -8.211462449684776],
              [-39.941070194761203, -8.211462612726379],
              [-39.941070258680099, -8.211462774573363],
              [-39.941070325710989, -8.211462935165853],
              [-39.941070395829129, -8.211463094444484],
              [-39.941070469008572, -8.211463252350333],
              [-39.941070545222239, -8.211463408825036],
              [-39.941070624441984, -8.211463563810694],
              [-39.941070706638456, -8.211463717250032],
              [-39.941070791781293, -8.211463869086264],
              [-39.94107087983901, -8.211464019263278],
              [-39.941070970779052, -8.211464167725515],
              [-39.941071064567758, -8.211464314418091],
              [-39.941071161170463, -8.211464459286729],
              [-39.941071260551446, -8.211464602277895],
              [-39.941071362673952, -8.211464743338668],
              [-39.941071467500208, -8.211464882416916],
              [-39.941071574991462, -8.211465019461201],
              [-39.941071685107957, -8.21146515442082],
              [-39.941071797808966, -8.211465287245888],
              [-39.941071913052816, -8.21146541788727],
              [-39.941072030796896, -8.211465546296662],
              [-39.941072150997655, -8.211465672426579],
              [-39.941072273610644, -8.211465796230385],
              [-39.941072398590514, -8.211465917662297],
              [-39.941072525891059, -8.211466036677384],
              [-39.941072655465206, -8.211466153231662],
              [-39.941072787265014, -8.211466267282027],
              [-39.941072921241762, -8.211466378786278],
              [-39.941073057345903, -8.211466487703209],
              [-39.941073195527117, -8.211466593992514],
              [-39.941073335734266, -8.211466697614902],
              [-39.941073477915545, -8.211466798532063],
              [-39.941073622018337, -8.211466896706655],
              [-39.941073767989373, -8.21146699210238],
              [-39.941073915774666, -8.211467084683989],
              [-39.941074065319583, -8.21146717441721],
              [-39.941074216568794, -8.211467261268862],
              [-39.941074369466378, -8.211467345206817],
              [-39.941074523955798, -8.211467426200061],
              [-39.941074679979906, -8.211467504218675],
              [-39.941074837481025, -8.211467579233732],
              [-39.9410749964009, -8.211467651217532],
              [-39.94107515668076, -8.211467720143439],
              [-39.941075318261333, -8.211467785985988],
              [-39.941075481082876, -8.211467848720789],
              [-39.941075645085157, -8.211467908324671],
              [-39.941075670175543, -8.211467891577719],
              [-39.942649300642636, -8.212022787364019],
              [-39.942649317278793, -8.212022807111964],
              [-39.942649346905327, -8.212022817501222],
              [-39.942649376567765, -8.212022827788269],
              [-39.942649406265744, -8.212022837973013],
              [-39.942649435998902, -8.212022848055353],
              [-39.942649465766912, -8.212022858035112],
              [-39.942649495569391, -8.212022867912234],
              [-39.942649525405997, -8.212022877686563],
              [-39.942649555276368, -8.212022887357985],
              [-39.942649585180156, -8.212022896926397],
              [-39.942649615116984, -8.212022906391665],
              [-39.942649645086505, -8.212022915753693],
              [-39.942649675088347, -8.212022925012343],
              [-39.942649705122172, -8.21202293416753],
              [-39.942649735187608, -8.212022943219141],
              [-39.942649765284301, -8.212022952167075],
              [-39.942649795411882, -8.212022961011197],
              [-39.942649825570001, -8.21202296975142],
              [-39.94264985575829, -8.212022978387653],
              [-39.942649885976394, -8.212022986919756],
              [-39.942649916223935, -8.212022995347661],
              [-39.942649946500566, -8.212023003671256],
              [-39.942649976805917, -8.212023011890452],
              [-39.942650007139648, -8.21202302000512],
              [-39.942650037501366, -8.212023028015189],
              [-39.94265006789071, -8.212023035920561],
              [-39.942650098307347, -8.212023043721135],
              [-39.942650128750877, -8.212023051416814],
              [-39.942650159220946, -8.212023059007512],
              [-39.942650189717199, -8.212023066493142],
              [-39.942650220239265, -8.212023073873613],
              [-39.94265025078677, -8.212023081148816],
              [-39.94265028135937, -8.21202308831869],
              [-39.942650311956683, -8.212023095383133],
              [-39.942650342578332, -8.212023102342076],
              [-39.942650373223984, -8.212023109195421],
              [-39.942650403893239, -8.212023115943104],
              [-39.942650434585751, -8.212023122585018],
              [-39.942650465301135, -8.212023129121086],
              [-39.942650496039043, -8.212023135551275],
              [-39.942650526799092, -8.212023141875463],
              [-39.942650557580912, -8.212023148093587],
              [-39.942650588384161, -8.212023154205546],
              [-39.942650619208429, -8.212023160211325],
              [-39.94265065005338, -8.212023166110823],
              [-39.942650680918632, -8.21202317190394],
              [-39.942650711803815, -8.212023177590641],
              [-39.942650742708565, -8.212023183170862],
              [-39.942650773632515, -8.212023188644501],
              [-39.942650804575273, -8.212023194011531],
              [-39.942650835536497, -8.21202319927186],
              [-39.942650866515798, -8.212023204425442],
              [-39.94265089751282, -8.212023209472198],
              [-39.942650928527172, -8.212023214412087],
              [-39.942650959558492, -8.212023219245065],
              [-39.942650990606396, -8.212023223971014],
              [-39.942651021670542, -8.212023228589931],
              [-39.942651052750541, -8.212023233101739],
              [-39.942651083846037, -8.212023237506386],
              [-39.942651114956618, -8.212023241803838],
              [-39.942651146081957, -8.212023245994027],
              [-39.942651177221641, -8.212023250076903],
              [-39.942651208375324, -8.212023254052408],
              [-39.942651239542641, -8.212023257920517],
              [-39.942651270723196, -8.212023261681166],
              [-39.942651301916626, -8.212023265334325],
              [-39.942651333122541, -8.212023268879939],
              [-39.942651364340591, -8.212023272317959],
              [-39.942651395570401, -8.212023275648356],
              [-39.942651426811572, -8.212023278871079],
              [-39.942651458063764, -8.212023281986099],
              [-39.942651489326586, -8.212023284993389],
              [-39.942651520599654, -8.212023287892894],
              [-39.942651551882612, -8.212023290684591],
              [-39.942651583175071, -8.21202329336843],
              [-39.942651614476674, -8.212023295944404],
              [-39.942651645787016, -8.212023298412454],
              [-39.942651677105765, -8.212023300772568],
              [-39.9426517084325, -8.212023303024708],
              [-39.94265173976688, -8.212023305168868],
              [-39.942651771108522, -8.212023307204991],
              [-39.942651802457036, -8.212023309133077],
              [-39.942651833812072, -8.212023310953098],
              [-39.942651865173239, -8.21202331266502],
              [-39.942651896540148, -8.212023314268841],
              [-39.942651927912451, -8.212023315764529],
              [-39.942651959289769, -8.21202331715207],
              [-39.942651990671699, -8.212023318431466],
              [-39.942652022057899, -8.212023319602661],
              [-39.942652053447958, -8.212023320665693],
              [-39.942652084841541, -8.212023321620505],
              [-39.942652116238243, -8.212023322467083],
              [-39.942652147637702, -8.212023323205466],
              [-39.942652179039541, -8.212023323835593],
              [-39.94265221044337, -8.212023324357492],
              [-39.942652241848826, -8.212023324771121],
              [-39.942652273255533, -8.212023325076487],
              [-39.942652304663106, -8.21202332527362],
              [-39.942652336071184, -8.212023325362491],
              [-39.942652367479383, -8.212023325343093],
              [-39.942652388452792, -8.212023322793256],
              [-39.945366899806722, -8.212016957988027],
              [-39.94536688269865, -8.21201696052891],
              [-39.945366932191689, -8.212016960547105],
              [-39.945366981683897, -8.21201696083412],
              [-39.945367031173795, -8.212016961389953],
              [-39.945367080659921, -8.212016962214598],
              [-39.945367130140795, -8.212016963308017],
              [-39.945367179614941, -8.21201696467017],
              [-39.945367229080908, -8.212016966301054],
              [-39.945367278537205, -8.21201696820061],
              [-39.945367327982382, -8.212016970368738],
              [-39.945367377414968, -8.212016972805406],
              [-39.945367426833464, -8.212016975510554],
              [-39.945367476236441, -8.212016978484071],
              [-39.945367525622402, -8.212016981725881],
              [-39.945367574989881, -8.212016985235895],
              [-39.945367624337436, -8.212016989013996],
              [-39.945367673663576, -8.212016993060121],
              [-39.945367722966843, -8.212016997374059],
              [-39.94536777224576, -8.212017001955752],
              [-39.945367821498884, -8.212017006805029],
              [-39.945367870724731, -8.212017011921782],
              [-39.945367919921843, -8.21201701730584],
              [-39.945367969088778, -8.212017022957015],
              [-39.945368018224038, -8.212017028875174],
              [-39.945368067326207, -8.212017035060153],
              [-39.94536811639378, -8.212017041511713],
              [-39.945368165425315, -8.212017048229709],
              [-39.945368214419368, -8.212017055213897],
              [-39.945368263374476, -8.212017062464126],
              [-39.945368312289169, -8.212017069980169],
              [-39.945368361162025, -8.212017077761772],
              [-39.945368409991559, -8.212017085808721],
              [-39.945368458776322, -8.212017094120769],
              [-39.945368507514893, -8.212017102697681],
              [-39.945368556205793, -8.21201711153919],
              [-39.945368604847602, -8.212017120645063],
              [-39.945368653438834, -8.21201713001499],
              [-39.94536870197809, -8.212017139648715],
              [-39.945368750463892, -8.212017149545938],
              [-39.945368798894812, -8.212017159706379],
              [-39.945368847269435, -8.212017170129705],
              [-39.94536889558627, -8.212017180815646],
              [-39.945368943843931, -8.21201719176389],
              [-39.945368992040954, -8.212017202974048],
              [-39.945369040175905, -8.212017214445858],
              [-39.945369088247389, -8.212017226178935],
              [-39.945369136253923, -8.212017238172942],
              [-39.945369184194135, -8.212017250427534],
              [-39.945369232066561, -8.212017262942357],
              [-39.945369279869787, -8.212017275716992],
              [-39.945369327602407, -8.212017288751095],
              [-39.945369375262985, -8.212017302044272],
              [-39.945369422850106, -8.212017315596132],
              [-39.945369470362365, -8.212017329406269],
              [-39.945369517798355, -8.212017343474265],
              [-39.945369565156639, -8.2120173577997],
              [-39.94536961243584, -8.21201737238218],
              [-39.94536965963453, -8.212017387221213],
              [-39.945369706751329, -8.212017402316398],
              [-39.945369753784803, -8.21201741766729],
              [-39.945369800733587, -8.212017433273415],
              [-39.945369847596268, -8.212017449134326],
              [-39.945369894371453, -8.212017465249547],
              [-39.945369941057756, -8.212017481618584],
              [-39.945369987653798, -8.212017498240963],
              [-39.945370034158174, -8.212017515116218],
              [-39.945370080569525, -8.212017532243795],
              [-39.945370126886452, -8.212017549623214],
              [-39.945370173107591, -8.212017567253927],
              [-39.945370219231556, -8.212017585135463],
              [-39.94537026525699, -8.212017603267228],
              [-39.945370311182508, -8.21201762164875],
              [-39.945370357006759, -8.212017640279431],
              [-39.945370402728372, -8.212017659158739],
              [-39.945370448346004, -8.212017678286132],
              [-39.94537049385827, -8.212017697661006],
              [-39.945370539263862, -8.212017717282812],
              [-39.945370584561381, -8.212017737150928],
              [-39.945370629749512, -8.212017757264819],
              [-39.945370674826904, -8.212017777623863],
              [-39.945370719792209, -8.212017798227413],
              [-39.945370764644096, -8.212017819074903],
              [-39.945370809381238, -8.212017840165705],
              [-39.945370854002292, -8.212017861499181],
              [-39.945370898505956, -8.212017883074711],
              [-39.945370942890882, -8.212017904891658],
              [-39.945370987155755, -8.212017926949352],
              [-39.945371031299281, -8.212017949247151],
              [-39.945371075320118, -8.212017971784391],
              [-39.945371119216972, -8.212017994560426],
              [-39.945371162988536, -8.212018017574511],
              [-39.945371206633503, -8.21201804082604],
              [-39.9453712501506, -8.212018064314268],
              [-39.945371293538507, -8.21201808803853],
              [-39.945371336795951, -8.212018111998125],
              [-39.945371379921632, -8.212018136192292],
              [-39.94537142291427, -8.212018160620344],
              [-39.945371465772588, -8.212018185281558],
              [-39.945371508495334, -8.212018210175186],
              [-39.94537155108106, -8.212018235301125],
              [-39.945371532593576, -8.212018213312886],
              [-39.947063341981377, -8.213022586598727],
              [-39.947063377247318, -8.213022609364641],
              [-39.947063497900132, -8.213022682263103],
              [-39.947063617401085, -8.213022757021104],
              [-39.947063735721265, -8.213022833620581],
              [-39.947063852832102, -8.213022912043042],
              [-39.947063968705308, -8.213022992269538],
              [-39.947064083312867, -8.213023074280651],
              [-39.947064196627117, -8.213023158056602],
              [-39.947064308620661, -8.213023243577179],
              [-39.947064419266447, -8.213023330821638],
              [-39.947064528537744, -8.21302341976898],
              [-39.947064636408122, -8.213023510397683],
              [-39.947064742851573, -8.213023602685823],
              [-39.947064847842341, -8.213023696611165],
              [-39.947064951355081, -8.213023792150979],
              [-39.947065053364774, -8.213023889282171],
              [-39.947065153846772, -8.213023987981304],
              [-39.947065252776781, -8.213024088224488],
              [-39.94706535013092, -8.213024189987545],
              [-39.94706544588567, -8.213024293245851],
              [-39.947065540017896, -8.213024397974491],
              [-39.947065632504845, -8.21302450414818],
              [-39.947065723324179, -8.213024611741218],
              [-39.94706581245395, -8.213024720727624],
              [-39.947065899872626, -8.213024831081086],
              [-39.947065985559092, -8.213024942774906],
              [-39.947066069492614, -8.21302505578212],
              [-39.947066151652976, -8.213025170075461],
              [-39.947066232020276, -8.213025285627239],
              [-39.947066310575117, -8.213025402409587],
              [-39.947066387298506, -8.213025520394266],
              [-39.947066462171904, -8.213025639552798],
              [-39.947066535177242, -8.213025759856382],
              [-39.947066606296879, -8.213025881275939],
              [-39.947066675513618, -8.213026003782129],
              [-39.94706674281074, -8.213026127345351],
              [-39.947066808171982, -8.213026251935799],
              [-39.947066871581569, -8.213026377523336],
              [-39.947066933024161, -8.213026504077607],
              [-39.947066992484942, -8.213026631568038],
              [-39.947067049949503, -8.213026759963848],
              [-39.947067105403981, -8.21302688923403],
              [-39.947067158834983, -8.213027019347326],
              [-39.947067210229612, -8.213027150272294],
              [-39.947067259575419, -8.213027281977299],
              [-39.947067306860504, -8.213027414430545],
              [-39.947067352073432, -8.213027547600017],
              [-39.947067395203284, -8.213027681453509],
              [-39.947067436239657, -8.213027815958743],
              [-39.947067475172595, -8.213027951083188],
              [-39.947067511992721, -8.213028086794205],
              [-39.947067546691144, -8.213028223058998],
              [-39.947067579259461, -8.21302835984465],
              [-39.947067609689825, -8.213028497118099],
              [-39.947067637974854, -8.213028634846182],
              [-39.947067664107742, -8.213028772995656],
              [-39.947067688082164, -8.213028911533121],
              [-39.947067709892316, -8.213029050425074],
              [-39.947067729532954, -8.213029189638011],
              [-39.947067746999316, -8.213029329138271],
              [-39.947067762287183, -8.213029468892158],
              [-39.947067775392867, -8.213029608865883],
              [-39.947067786313198, -8.213029749025646],
              [-39.94706779504552, -8.213029889337591],
              [-39.947067801587771, -8.213030029767811],
              [-39.947067805938332, -8.213030170282364],
              [-39.947067808096143, -8.21303031084731],
              [-39.947067808060709, -8.2130304514287],
              [-39.947067805832035, -8.213030591992576],
              [-39.94706780141064, -8.213030732504942],
              [-39.947067794797626, -8.213030872931849],
              [-39.947067785994555, -8.213031013239396],
              [-39.947067775003582, -8.2130311533937],
              [-39.947067761827356, -8.213031293360833],
              [-39.947067746469038, -8.213031433107053],
              [-39.947067728932367, -8.213031572598563],
              [-39.94706770922155, -8.213031711801642],
              [-39.947067687341395, -8.213031850682688],
              [-39.94706766329714, -8.21303198920813],
              [-39.947067637094612, -8.213032127344512],
              [-39.947067608740156, -8.213032265058438],
              [-39.947067578240613, -8.213032402316658],
              [-39.947067545603346, -8.213032539085999],
              [-39.947067510836241, -8.213032675333416],
              [-39.947067473947712, -8.213032811025986],
              [-39.947067434946653, -8.213032946130927],
              [-39.947067393842502, -8.213033080615633],
              [-39.947067350645177, -8.213033214447533],
              [-39.947067305365124, -8.213033347594367],
              [-39.94706725801327, -8.213033480023942],
              [-39.947067208601077, -8.213033611704255],
              [-39.947067157140459, -8.213033742603479],
              [-39.947067103643874, -8.21303387269001],
              [-39.947067048124239, -8.213034001932435],
              [-39.947066990594948, -8.213034130299473],
              [-39.947066931069926, -8.213034257760178],
              [-39.947066869563542, -8.213034384283684],
              [-39.94706680609066, -8.213034509839488],
              [-39.947066740666607, -8.213034634397209],
              [-39.947066673306672, -8.213034757927348],
              [-39.94706663901583, -8.213034788651587],
              [-39.946509532394529, -8.214037846089489],
              [-39.946509537927092, -8.214037814615615],
              [-39.946509490274245, -8.214037899331959],
              [-39.946509441711108, -8.214037983533663],
              [-39.9465093922433, -8.214038067211034],
              [-39.946509341876506, -8.214038150354408],
              [-39.946509290616561, -8.214038232954199],
              [-39.946509238469353, -8.214038315000851],
              [-39.94650918544091, -8.214038396484977],
              [-39.946509131537326, -8.214038477397139],
              [-39.946509076764826, -8.214038557728008],
              [-39.946509021129728, -8.214038637468342],
              [-39.946508964638447, -8.21403871660894],
              [-39.946508907297492, -8.214038795140672],
              [-39.946508849113478, -8.214038873054481],
              [-39.946508790093105, -8.214038950341363],
              [-39.946508730243181, -8.214039026992456],
              [-39.946508669570612, -8.214039102998898],
              [-39.946508608082382, -8.214039178351911],
              [-39.946508545785598, -8.214039253042861],
              [-39.946508482687427, -8.214039327063093],
              [-39.946508418795133, -8.214039400404062],
              [-39.946508354116119, -8.21403947305733],
              [-39.94650828865781, -8.214039545014527],
              [-39.946508222427759, -8.214039616267357],
              [-39.946508155433619, -8.214039686807588],
              [-39.946508087683092, -8.214039756627111],
              [-39.946508019183995, -8.214039825717839],
              [-39.946507949944241, -8.214039894071867],
              [-39.946507879971783, -8.214039961681248],
              [-39.946507809274713, -8.214040028538243],
              [-39.946507737861175, -8.214040094635116],
              [-39.946507665739396, -8.21404015996424],
              [-39.946507592917705, -8.21404022451809],
              [-39.946507519404484, -8.214040288289223],
              [-39.946507445208226, -8.214040351270272],
              [-39.946507370337443, -8.214040413454038],
              [-39.946507294800824, -8.214040474833277],
              [-39.946507218607046, -8.214040535400946],
              [-39.946507141764897, -8.214040595150061],
              [-39.946507064283232, -8.214040654073733],
              [-39.946506986170988, -8.214040712165147],
              [-39.946506907437175, -8.214040769417634],
              [-39.946506828090875, -8.214040825824592],
              [-39.946506748141232, -8.214040881379525],
              [-39.94650666759744, -8.214040936075987],
              [-39.946506586468814, -8.214040989907723],
              [-39.94650650476472, -8.214041042868482],
              [-39.946506422494537, -8.214041094952192],
              [-39.946506339667764, -8.21404114615283],
              [-39.946506256293958, -8.214041196464484],
              [-39.94650617238274, -8.214041245881379],
              [-39.946506087943767, -8.214041294397788],
              [-39.946506002986794, -8.214041342008141],
              [-39.946505917521584, -8.214041388706956],
              [-39.946505831558021, -8.214041434488827],
              [-39.946505745105995, -8.214041479348481],
              [-39.946505658175482, -8.21404152328075],
              [-39.946505570776509, -8.214041566280564],
              [-39.94650548291915, -8.214041608342972],
              [-39.946505394613517, -8.214041649463139],
              [-39.946505305869835, -8.214041689636282],
              [-39.946505216698284, -8.214041728857808],
              [-39.946505127109177, -8.214041767123183],
              [-39.946505037112814, -8.214041804427977],
              [-39.946504946719614, -8.214041840767909],
              [-39.946504855939978, -8.214041876138795],
              [-39.946504764784351, -8.214041910536546],
              [-39.94650467326327, -8.214041943957179],
              [-39.946504581387273, -8.214041976396881],
              [-39.946504489166955, -8.214042007851889],
              [-39.946504396612966, -8.214042038318585],
              [-39.946504303735935, -8.214042067793423],
              [-39.946504210546614, -8.214042096273046],
              [-39.946504117055717, -8.214042123754162],
              [-39.946504023274045, -8.21404215023359],
              [-39.946503929212405, -8.214042175708292],
              [-39.946503834881618, -8.21404220017531],
              [-39.946503740292592, -8.214042223631839],
              [-39.946503645456211, -8.214042246075175],
              [-39.946503550383412, -8.21404226750272],
              [-39.946503455085178, -8.214042287912013],
              [-39.946503359572453, -8.21404230730071],
              [-39.9465032638563, -8.21404232566656],
              [-39.946503167947718, -8.214042343007442],
              [-39.946503071857776, -8.214042359321372],
              [-39.946502975597546, -8.214042374606459],
              [-39.946502879178134, -8.214042388860946],
              [-39.946502782610658, -8.214042402083187],
              [-39.946502685906239, -8.214042414271647],
              [-39.94650258907604, -8.214042425424928],
              [-39.946502492131231, -8.214042435541757],
              [-39.946502395082959, -8.214042444620953],
              [-39.946502297942438, -8.214042452661491],
              [-39.946502200720865, -8.214042459662414],
              [-39.946502103429431, -8.214042465622942],
              [-39.946502006079392, -8.21404247054236],
              [-39.946501908681924, -8.214042474420113],
              [-39.946501811248282, -8.214042477255781],
              [-39.946501713789694, -8.214042479048986],
              [-39.946501616317143, -8.214042479799998],
              [-39.946501645405327, -8.214042477230851],
              [-39.944583631192423, -8.214046982846904],
              [-39.944583599535974, -8.214046985421891],
              [-39.944583554301289, -8.214046985415768],
              [-39.944583509067208, -8.214046985185066],
              [-39.944583463834824, -8.214046984729835],
              [-39.944583418605283, -8.21404698405007],
              [-39.944583373379722, -8.214046983145767],
              [-39.94458332815924, -8.21404698201696],
              [-39.944583282944961, -8.21404698066371],
              [-39.944583237738001, -8.214046979086001],
              [-39.944583192539504, -8.214046977283884],
              [-39.944583147350585, -8.214046975257434],
              [-39.944583102172359, -8.214046973006683],
              [-39.94458305700595, -8.214046970531664],
              [-39.944583011852473, -8.214046967832459],
              [-39.944582966713064, -8.21404696490913],
              [-39.944582921588825, -8.21404696176176],
              [-39.944582876480901, -8.214046958390419],
              [-39.944582831390392, -8.214046954795197],
              [-39.944582786318421, -8.214046950976179],
              [-39.944582741266117, -8.214046946933463],
              [-39.944582696234605, -8.214046942667135],
              [-39.944582651224962, -8.214046938177322],
              [-39.944582606238363, -8.214046933464124],
              [-39.944582561275872, -8.214046928527655],
              [-39.944582516338635, -8.214046923368036],
              [-39.944582471427751, -8.214046917985424],
              [-39.944582426544365, -8.214046912379903],
              [-39.944582381689564, -8.214046906551646],
              [-39.944582336864471, -8.214046900500778],
              [-39.944582292070194, -8.214046894227483],
              [-39.944582247307849, -8.214046887731895],
              [-39.944582202578552, -8.214046881014163],
              [-39.944582157883403, -8.214046874074466],
              [-39.944582113223518, -8.214046866912971],
              [-39.944582068600006, -8.214046859529887],
              [-39.944582024013982, -8.214046851925366],
              [-39.944581979466534, -8.214046844099595],
              [-39.944581934958784, -8.214046836052777],
              [-39.944581890491833, -8.214046827785078],
              [-39.944581846066775, -8.214046819296778],
              [-39.944581801684734, -8.214046810588043],
              [-39.944581757346796, -8.214046801659077],
              [-39.94458171305407, -8.214046792510144],
              [-39.94458166880765, -8.214046783141425],
              [-39.944581624608638, -8.214046773553177],
              [-39.944581580458127, -8.214046763745641],
              [-39.944581536357205, -8.214046753719037],
              [-39.944581492306995, -8.214046743473618],
              [-39.944581448308568, -8.214046733009647],
              [-39.944581404363028, -8.214046722327392],
              [-39.944581360471446, -8.214046711427123],
              [-39.944581316634945, -8.214046700309089],
              [-39.944581272854585, -8.214046688973603],
              [-39.944581229131451, -8.2140466774209],
              [-39.944581185466646, -8.214046665651288],
              [-39.944581141861256, -8.214046653665061],
              [-39.944581098316355, -8.214046641462499],
              [-39.944581054833023, -8.214046629043946],
              [-39.944581011412346, -8.21404661640968],
              [-39.944580968055391, -8.214046603560019],
              [-39.944580924763244, -8.214046590495265],
              [-39.944580881536965, -8.214046577215749],
              [-39.944580838377661, -8.214046563721862],
              [-39.944580795286363, -8.214046550013849],
              [-39.94458075226418, -8.2140465360921],
              [-39.944580709312135, -8.214046521956957],
              [-39.944580666431335, -8.214046507608765],
              [-39.944580623622834, -8.214046493047871],
              [-39.944580580887681, -8.214046478274639],
              [-39.944580538226951, -8.214046463289446],
              [-39.944580495641688, -8.214046448092638],
              [-39.944580453132971, -8.214046432684647],
              [-39.944580410701846, -8.214046417065815],
              [-39.944580368349349, -8.214046401236523],
              [-39.944580326076562, -8.214046385197188],
              [-39.944580283884513, -8.21404636894818],
              [-39.944580241774254, -8.214046352489953],
              [-39.944580199746838, -8.214046335822873],
              [-39.944580157803301, -8.214046318947377],
              [-39.944580115944696, -8.214046301863847],
              [-39.944580074172045, -8.214046284572758],
              [-39.944580032486392, -8.214046267074487],
              [-39.944579990888769, -8.214046249369511],
              [-39.944579949380227, -8.214046231458259],
              [-39.944579907961774, -8.214046213341158],
              [-39.944579866634427, -8.214046195018655],
              [-39.944579825399266, -8.214046176491252],
              [-39.944579784257265, -8.214046157759368],
              [-39.944579743209459, -8.214046138823475],
              [-39.944579702256881, -8.214046119684044],
              [-39.944579661400525, -8.214046100341552],
              [-39.94457962064142, -8.214046080796479],
              [-39.944579579980577, -8.214046061049306],
              [-39.944579539419003, -8.214046041100515],
              [-39.944579498957708, -8.214046020950637],
              [-39.94457945859768, -8.214046000600115],
              [-39.944579418339949, -8.214045980049505],
              [-39.944579378185495, -8.214045959299311],
              [-39.94457933813532, -8.21404593835002],
              [-39.94457929819054, -8.214045917201881],
              [-39.944579271152541, -8.21404590776161],
              [-39.94310949095378, -8.213263132097504],
              [-39.943109528700077, -8.213263094800151],
              [-39.943109477262659, -8.213263067614587],
              [-39.943109425651294, -8.213263040758271],
              [-39.943109373868083, -8.213263014232341],
              [-39.943109321915159, -8.213262988037835],
              [-39.943109269794675, -8.21326296217585],
              [-39.943109217508756, -8.213262936647467],
              [-39.943109165059568, -8.2132629114537],
              [-39.943109112449264, -8.21326288659562],
              [-39.94310905967999, -8.213262862074247],
              [-39.94310900675395, -8.213262837890571],
              [-39.94310895367331, -8.213262814045601],
              [-39.943108900440222, -8.21326279054032],
              [-39.943108847056941, -8.21326276737565],
              [-39.943108793525596, -8.213262744552603],
              [-39.943108739848434, -8.213262722072091],
              [-39.943108686027635, -8.213262699935044],
              [-39.943108632065432, -8.213262678142394],
              [-39.943108577964047, -8.213262656695017],
              [-39.94310852372567, -8.213262635593798],
              [-39.943108469352573, -8.213262614839589],
              [-39.943108414846996, -8.213262594433283],
              [-39.943108360211141, -8.213262574375662],
              [-39.943108305447304, -8.213262554667596],
              [-39.9431082505577, -8.213262535309891],
              [-39.943108195544603, -8.213262516303331],
              [-39.943108140410274, -8.213262497648708],
              [-39.943108085156979, -8.213262479346772],
              [-39.943108029786998, -8.213262461398307],
              [-39.943107974302599, -8.213262443803979],
              [-39.943107918706069, -8.213262426564617],
              [-39.943107862999696, -8.213262409680848],
              [-39.943107807185783, -8.213262393153409],
              [-39.943107751266602, -8.213262376982961],
              [-39.943107695244471, -8.213262361170193],
              [-39.943107639121699, -8.213262345715728],
              [-39.943107582900581, -8.2132623306202],
              [-39.943107526583425, -8.213262315884268],
              [-39.943107470172563, -8.213262301508506],
              [-39.943107413670312, -8.213262287493484],
              [-39.943107357078993, -8.213262273839838],
              [-39.943107300400939, -8.213262260548088],
              [-39.943107243638487, -8.213262247618813],
              [-39.943107186793952, -8.2132622350525],
              [-39.943107129869695, -8.213262222849675],
              [-39.943107072868038, -8.213262211010884],
              [-39.943107015791341, -8.213262199536549],
              [-39.943106958641948, -8.213262188427208],
              [-39.943106901422205, -8.213262177683289],
              [-39.943106844134476, -8.213262167305219],
              [-39.943106786781101, -8.213262157293435],
              [-39.943106729364466, -8.213262147648358],
              [-39.943106671886916, -8.213262138370371],
              [-39.943106614350803, -8.213262129459885],
              [-39.943106556758515, -8.213262120917237],
              [-39.943106499112403, -8.213262112742754],
              [-39.943106441414869, -8.213262104936836],
              [-39.943106383668258, -8.213262097499767],
              [-39.94310632587495, -8.213262090431858],
              [-39.94310626803734, -8.213262083733403],
              [-39.943106210157794, -8.213262077404675],
              [-39.943106152238691, -8.21326207144593],
              [-39.943106094282406, -8.213262065857421],
              [-39.943106036291354, -8.213262060639398],
              [-39.943105978267887, -8.213262055792026],
              [-39.943105920214421, -8.213262051315517],
              [-39.943105862133315, -8.21326204721009],
              [-39.943105804026978, -8.213262043475883],
              [-39.943105745897803, -8.213262040113056],
              [-39.943105687748158, -8.213262037121769],
              [-39.943105629580458, -8.213262034502101],
              [-39.943105571397091, -8.213262032254221],
              [-39.943105513200443, -8.21326203037815],
              [-39.943105454992931, -8.21326202887402],
              [-39.943105396776922, -8.213262027741875],
              [-39.943105338554822, -8.213262026981761],
              [-39.943105280329021, -8.213262026593689],
              [-39.943105222101927, -8.21326202657772],
              [-39.943105163875927, -8.213262026933814],
              [-39.943105105653409, -8.213262027661953],
              [-39.943105047436774, -8.213262028762145],
              [-39.943104989228438, -8.213262030234334],
              [-39.94310493103076, -8.213262032078442],
              [-39.94310487284617, -8.213262034294406],
              [-39.943104814677028, -8.213262036882146],
              [-39.943104756525742, -8.213262039841524],
              [-39.943104698394713, -8.213262043172433],
              [-39.943104640286329, -8.213262046874751],
              [-39.943104582202963, -8.213262050948302],
              [-39.943104524147017, -8.213262055392923],
              [-39.943104466120886, -8.213262060208454],
              [-39.943104408126956, -8.213262065394677],
              [-39.943104350167594, -8.213262070951371],
              [-39.943104292245209, -8.213262076878323],
              [-39.94310423436216, -8.213262083175296],
              [-39.943104176520848, -8.213262089842017],
              [-39.943104118723653, -8.213262096878207],
              [-39.943104060972928, -8.213262104283555],
              [-39.943104003271088, -8.213262112057826],
              [-39.943103945620457, -8.213262120200643],
              [-39.943103950990952, -8.213262150202299],
              [-39.941522991982545, -8.213490602292996],
              [-39.941522948194851, -8.213490578784572],
              [-39.941522919039819, -8.213490583045623],
              [-39.941522889898842, -8.213490587400965],
              [-39.941522860772196, -8.213490591850526],
              [-39.94152283166018, -8.213490596394244],
              [-39.941522802563149, -8.213490601032095],
              [-39.941522773481374, -8.213490605763997],
              [-39.94152274441516, -8.213490610589929],
              [-39.94152271536484, -8.21349061550983],
              [-39.941522686330686, -8.213490620523659],
              [-39.941522657313023, -8.213490625631335],
              [-39.941522628312171, -8.213490630832842],
              [-39.941522599328394, -8.213490636128105],
              [-39.941522570362039, -8.213490641517046],
              [-39.941522541413391, -8.213490646999661],
              [-39.941522512482749, -8.213490652575864],
              [-39.941522483570438, -8.213490658245574],
              [-39.941522454676743, -8.21349066400877],
              [-39.941522425801992, -8.213490669865367],
              [-39.941522396946468, -8.213490675815315],
              [-39.941522368110469, -8.213490681858545],
              [-39.941522339294316, -8.213490687995009],
              [-39.941522310498314, -8.213490694224619],
              [-39.941522281722762, -8.213490700547306],
              [-39.941522252967957, -8.213490706963022],
              [-39.941522224234205, -8.213490713471698],
              [-39.941522195521813, -8.213490720073258],
              [-39.941522166831085, -8.213490726767629],
              [-39.941522138162313, -8.213490733554774],
              [-39.941522109515802, -8.213490740434567],
              [-39.941522080891865, -8.213490747406983],
              [-39.941522052290793, -8.213490754471897],
              [-39.941522023712885, -8.213490761629277],
              [-39.941521995158439, -8.213490768879055],
              [-39.941521966627782, -8.213490776221111],
              [-39.941521938121205, -8.213490783655411],
              [-39.941521909638965, -8.213490791181856],
              [-39.94152188118143, -8.213490798800372],
              [-39.941521852748849, -8.213490806510864],
              [-39.941521824341542, -8.213490814313259],
              [-39.941521795959801, -8.21349082220749],
              [-39.94152176760393, -8.213490830193471],
              [-39.941521739274236, -8.213490838271074],
              [-39.941521710970996, -8.213490846440273],
              [-39.941521682694535, -8.21349085470095],
              [-39.941521654445125, -8.213490863053044],
              [-39.941521626223086, -8.213490871496429],
              [-39.941521598028707, -8.213490880031021],
              [-39.941521569862275, -8.213490888656771],
              [-39.941521541724107, -8.213490897373564],
              [-39.941521513614468, -8.213490906181283],
              [-39.941521485533684, -8.213490915079882],
              [-39.94152145748204, -8.213490924069243],
              [-39.941521429459819, -8.21349093314927],
              [-39.941521401467341, -8.21349094231986],
              [-39.941521373504891, -8.213490951580928],
              [-39.941521345572767, -8.213490960932372],
              [-39.941521317671246, -8.213490970374094],
              [-39.94152128980064, -8.213490979906005],
              [-39.941521261961235, -8.213490989527987],
              [-39.941521234153321, -8.213490999239957],
              [-39.941521206377203, -8.213491009041793],
              [-39.94152117863316, -8.213491018933393],
              [-39.941521150921488, -8.21349102891466],
              [-39.941521123242495, -8.213491038985493],
              [-39.941521095596464, -8.213491049145798],
              [-39.941521067983672, -8.213491059395468],
              [-39.941521040404403, -8.213491069734356],
              [-39.941521012858985, -8.213491080162367],
              [-39.941520985347694, -8.213491090679435],
              [-39.941520957870786, -8.213491101285388],
              [-39.941520930428588, -8.21349111198014],
              [-39.941520903021392, -8.213491122763605],
              [-39.941520875649466, -8.213491133635614],
              [-39.941520848313097, -8.213491144596105],
              [-39.941520821012595, -8.213491155644936],
              [-39.941520793748232, -8.213491166781992],
              [-39.941520766520291, -8.213491178007155],
              [-39.941520739329079, -8.213491189320315],
              [-39.941520712174864, -8.21349120072135],
              [-39.941520685057931, -8.213491212210128],
              [-39.941520657978572, -8.213491223786557],
              [-39.941520630937084, -8.213491235450483],
              [-39.941520603933732, -8.2134912472018],
              [-39.941520576968813, -8.213491259040374],
              [-39.941520550042604, -8.213491270966104],
              [-39.94152052315539, -8.21349128297884],
              [-39.941520496307454, -8.213491295078462],
              [-39.941520469499089, -8.213491307264833],
              [-39.941520442730571, -8.213491319537855],
              [-39.941520416002163, -8.213491331897371],
              [-39.941520389314185, -8.213491344343245],
              [-39.941520362666886, -8.213491356875346],
              [-39.941520336060563, -8.213491369493571],
              [-39.941520309495488, -8.21349138219777],
              [-39.94152028297195, -8.2134913949878],
              [-39.941520256490222, -8.213491407863549],
              [-39.941520230050571, -8.213491420824862],
              [-39.941520203653312, -8.213491433871607],
              [-39.941520177298671, -8.213491447003644],
              [-39.941520179700206, -8.213491422859359],
              [-39.940394729163749, -8.214054530161429],
              [-39.940394743486515, -8.214054505145439],
              [-39.940394659235018, -8.214054547845222],
              [-39.94039457543434, -8.214054591416676],
              [-39.940394492093588, -8.214054635855092],
              [-39.940394409221803, -8.214054681155652],
              [-39.940394326827956, -8.214054727313442],
              [-39.940394244920995, -8.214054774323447],
              [-39.940394163509787, -8.214054822180589],
              [-39.940394082603156, -8.214054870879657],
              [-39.940394002209899, -8.214054920415396],
              [-39.940393922338707, -8.214054970782406],
              [-39.940393842998255, -8.214055021975229],
              [-39.940393764197125, -8.21405507398833],
              [-39.940393685943874, -8.214055126816088],
              [-39.940393608246993, -8.214055180452769],
              [-39.940393531114907, -8.214055234892506],
              [-39.940393454555966, -8.214055290129435],
              [-39.940393378578484, -8.214055346157572],
              [-39.940393303190689, -8.214055402970837],
              [-39.940393228400758, -8.214055460563056],
              [-39.940393154216778, -8.214055518928014],
              [-39.940393080646814, -8.214055578059357],
              [-39.940393007698859, -8.214055637950684],
              [-39.9403929353808, -8.214055698595505],
              [-39.940392863700481, -8.214055759987232],
              [-39.940392792665676, -8.214055822119208],
              [-39.940392722284081, -8.214055884984729],
              [-39.940392652563332, -8.214055948576961],
              [-39.940392583510985, -8.214056012888998],
              [-39.94039251513454, -8.214056077913861],
              [-39.940392447441397, -8.214056143644518],
              [-39.940392380438901, -8.214056210073828],
              [-39.9403923141343, -8.214056277194615],
              [-39.940392248534806, -8.214056344999582],
              [-39.940392183647518, -8.214056413481373],
              [-39.940392119479469, -8.21405648263257],
              [-39.940392056037631, -8.21405655244566],
              [-39.940391993328873, -8.214056622913105],
              [-39.94039193135999, -8.214056694027267],
              [-39.940391870137724, -8.214056765780397],
              [-39.940391809668682, -8.214056838164748],
              [-39.940391749959439, -8.214056911172444],
              [-39.940391691016444, -8.214056984795594],
              [-39.940391632846122, -8.214057059026203],
              [-39.940391575454754, -8.214057133856203],
              [-39.940391518848578, -8.214057209277541],
              [-39.940391463033727, -8.21405728528199],
              [-39.940391408016247, -8.214057361861316],
              [-39.940391353802106, -8.214057439007235],
              [-39.940391300397188, -8.214057516711364],
              [-39.940391247807277, -8.214057594965274],
              [-39.940391196038085, -8.214057673760502],
              [-39.940391145095219, -8.214057753088511],
              [-39.940391094984186, -8.214057832940645],
              [-39.940391045710449, -8.214057913308325],
              [-39.940390997279302, -8.214057994182761],
              [-39.940390949696059, -8.214058075555231],
              [-39.940390902965831, -8.214058157416911],
              [-39.940390857093718, -8.214058239758915],
              [-39.940390812084658, -8.214058322572317],
              [-39.940390767943569, -8.214058405848105],
              [-39.940390724675211, -8.214058489577329],
              [-39.94039068228426, -8.214058573750831],
              [-39.940390640775377, -8.214058658359532],
              [-39.940390600152995, -8.214058743394245],
              [-39.940390560421548, -8.214058828845742],
              [-39.940390521585336, -8.214058914704779],
              [-39.940390483648571, -8.214059000962017],
              [-39.940390446615361, -8.214059087608149],
              [-39.940390410489755, -8.214059174633745],
              [-39.940390375275626, -8.214059262029361],
              [-39.940390340976805, -8.214059349785556],
              [-39.940390307597021, -8.214059437892791],
              [-39.940390275139869, -8.214059526341529],
              [-39.94039024360891, -8.214059615122167],
              [-39.940390213007532, -8.214059704225074],
              [-39.940390183339055, -8.21405979364059],
              [-39.940390154606696, -8.214059883359045],
              [-39.940390126813575, -8.214059973370684],
              [-39.94039009996272, -8.214060063665766],
              [-39.940390074057014, -8.214060154234483],
              [-39.940390049099292, -8.214060245067056],
              [-39.940390025092235, -8.214060336153604],
              [-39.940390002038448, -8.214060427484236],
              [-39.940389979940441, -8.21406051904909],
              [-39.94038995880063, -8.214060610838203],
              [-39.940389938621273, -8.214060702841634],
              [-39.940389919404566, -8.214060795049397],
              [-39.940389901152599, -8.214060887451554],
              [-39.940389883867354, -8.214060980038008],
              [-39.940389867550714, -8.214061072798767],
              [-39.940389852204397, -8.214061165723747],
              [-39.940389837830132, -8.214061258802909],
              [-39.940389824429445, -8.214061352026091],
              [-39.940389812003787, -8.214061445383264],
              [-39.940389800554513, -8.214061538864268],
              [-39.940389790082875, -8.214061632458973],
              [-39.940389780589982, -8.214061726157251],
              [-39.940389772076884, -8.214061819948897],
              [-39.940389764544499, -8.214061913823796],
              [-39.940389753675305, -8.214061958127829],
              [-39.940280346420877, -8.215520881587775],
              [-39.940280366622616, -8.215520910222997],
              [-39.940280365313619, -8.215520927914035],
              [-39.940280364039459, -8.215520945607585],
              [-39.940280362800131, -8.215520963303618],
              [-39.940280361595647, -8.21552098100201],
              [-39.940280360426001, -8.215520998702733],
              [-39.940280359291222, -8.21552101640569],
              [-39.940280358191288, -8.215521034110868],
              [-39.940280357126198, -8.215521051818129],
              [-39.940280356095968, -8.215521069527437],
              [-39.940280355100619, -8.215521087238731],
              [-39.940280354140143, -8.215521104951952],
              [-39.940280353214547, -8.215521122666994],
              [-39.940280352323803, -8.215521140383805],
              [-39.940280351467955, -8.215521158102323],
              [-39.940280350646987, -8.215521175822511],
              [-39.940280349860913, -8.215521193544252],
              [-39.94028034910972, -8.215521211267493],
              [-39.940280348393415, -8.21552122899217],
              [-39.940280347712019, -8.2155212467182],
              [-39.940280347065517, -8.215521264445528],
              [-39.940280346453918, -8.215521282174088],
              [-39.940280345877206, -8.215521299903829],
              [-39.940280345335431, -8.215521317634634],
              [-39.940280344828537, -8.21552133536647],
              [-39.940280344356559, -8.215521353099286],
              [-39.940280343919497, -8.215521370832965],
              [-39.940280343517351, -8.21552138856747],
              [-39.940280343150107, -8.215521406302718],
              [-39.940280342817786, -8.215521424038663],
              [-39.940280342520389, -8.215521441775213],
              [-39.9402803422579, -8.215521459512308],
              [-39.940280342030334, -8.215521477249894],
              [-39.940280341837678, -8.21552149498787],
              [-39.940280341679959, -8.215521512726202],
              [-39.940280341557163, -8.215521530464803],
              [-39.940280341469276, -8.215521548203629],
              [-39.940280341416312, -8.215521565942575],
              [-39.940280341398278, -8.215521583681591],
              [-39.940280341415168, -8.215521601420607],
              [-39.940280341466973, -8.215521619159563],
              [-39.940280341553709, -8.215521636898384],
              [-39.940280341675354, -8.215521654637007],
              [-39.940280341831929, -8.215521672375377],
              [-39.940280342023435, -8.215521690113384],
              [-39.940280342249842, -8.215521707850986],
              [-39.94028034251118, -8.215521725588099],
              [-39.940280342807426, -8.2155217433247],
              [-39.940280343138603, -8.215521761060657],
              [-39.940280343504696, -8.215521778795948],
              [-39.940280343905691, -8.215521796530496],
              [-39.940280344341609, -8.215521814264205],
              [-39.940280344812436, -8.215521831997025],
              [-39.940280345318165, -8.215521849728907],
              [-39.940280345858817, -8.215521867459776],
              [-39.940280346434363, -8.215521885189537],
              [-39.940280347044812, -8.215521902918139],
              [-39.940280347690162, -8.21552192064553],
              [-39.940280348370422, -8.215521938371612],
              [-39.940280349085569, -8.215521956096332],
              [-39.940280349835596, -8.215521973819621],
              [-39.940280350620526, -8.215521991541413],
              [-39.940280351440343, -8.215522009261624],
              [-39.940280352295055, -8.215522026980205],
              [-39.940280353184633, -8.215522044697089],
              [-39.940280354109092, -8.215522062412182],
              [-39.940280355068417, -8.215522080125451],
              [-39.94028035606263, -8.215522097836816],
              [-39.940280357091687, -8.215522115546193],
              [-39.940280358155633, -8.215522133253522],
              [-39.940280359254423, -8.215522150958728],
              [-39.940280360388059, -8.215522168661783],
              [-39.94028036155656, -8.215522186362564],
              [-39.940280362759907, -8.215522204061024],
              [-39.940280363998085, -8.215522221757094],
              [-39.940280365271093, -8.215522239450744],
              [-39.940280366578932, -8.215522257141849],
              [-39.940280367921602, -8.215522274830366],
              [-39.940280369299096, -8.215522292516237],
              [-39.940280370711413, -8.215522310199368],
              [-39.940280372158526, -8.215522327879706],
              [-39.940280373640448, -8.215522345557179],
              [-39.940280375157172, -8.215522363231761],
              [-39.940280376708671, -8.215522380903295],
              [-39.940280378294965, -8.215522398571769],
              [-39.940280379916061, -8.215522416237127],
              [-39.940280381571917, -8.215522433899286],
              [-39.940280383262532, -8.215522451558179],
              [-39.940280384987915, -8.215522469213727],
              [-39.940280386748064, -8.215522486865892],
              [-39.940280388542945, -8.215522504514569],
              [-39.940280390372578, -8.215522522159697],
              [-39.940280392236943, -8.215522539801222],
              [-39.940280394136039, -8.215522557439076],
              [-39.940280396069838, -8.21552257507318],
              [-39.940280398038368, -8.215522592703456],
              [-39.940280400041587, -8.215522610329886],
              [-39.940280402079516, -8.215522627952341],
              [-39.940280404152134, -8.215522645570786],
              [-39.940280406259426, -8.215522663185144],
              [-39.940280441460004, -8.215522690229532],
              [-39.94051043305263, -8.217429418644612],
              [-39.940510424878738, -8.217429405083879],
              [-39.940510440738151, -8.217429529287072],
              [-39.940510458321832, -8.217429653259634],
              [-39.940510477626439, -8.21742977697782],
              [-39.940510498648251, -8.217429900417921],
              [-39.940510521383246, -8.217430023556258],
              [-39.940510545827074, -8.217430146369258],
              [-39.94051057197504, -8.217430268833379],
              [-39.94051059982214, -8.217430390925134],
              [-39.94051062936304, -8.217430512621156],
              [-39.940510660592054, -8.217430633898084],
              [-39.940510693503228, -8.217430754732707],
              [-39.940510728090246, -8.217430875101858],
              [-39.94051076434647, -8.21743099498249],
              [-39.940510802264967, -8.217431114351573],
              [-39.940510841838474, -8.217431233186298],
              [-39.940510883059375, -8.217431351463853],
              [-39.940510925919803, -8.217431469161605],
              [-39.940510970411516, -8.217431586256945],
              [-39.940511016526024, -8.217431702727451],
              [-39.940511064254459, -8.217431818550825],
              [-39.940511113587668, -8.21743193370485],
              [-39.940511164516231, -8.217432048167479],
              [-39.940511217030384, -8.217432161916754],
              [-39.94051127112003, -8.217432274930879],
              [-39.940511326774839, -8.217432387188234],
              [-39.940511383984102, -8.217432498667225],
              [-39.940511442736906, -8.217432609346538],
              [-39.940511503021959, -8.217432719204988],
              [-39.940511564827723, -8.217432828221478],
              [-39.940511628142332, -8.217432936375143],
              [-39.940511692953685, -8.217433043645233],
              [-39.940511759249326, -8.21743315001121],
              [-39.940511827016586, -8.217433255452676],
              [-39.940511896242441, -8.217433359949448],
              [-39.940511966913668, -8.217433463481491],
              [-39.940512039016689, -8.217433566028939],
              [-39.940512112537689, -8.217433667572152],
              [-39.940512187462588, -8.217433768091688],
              [-39.940512263777038, -8.217433867568253],
              [-39.940512341466388, -8.217433965982801],
              [-39.940512420515759, -8.217434063316514],
              [-39.940512500910003, -8.217434159550642],
              [-39.94051258263373, -8.217434254666824],
              [-39.940512665671257, -8.217434348646805],
              [-39.940512750006675, -8.217434441472591],
              [-39.940512835623835, -8.217434533126335],
              [-39.940512922506294, -8.217434623590552],
              [-39.940513010637453, -8.217434712847847],
              [-39.940513100000381, -8.217434800881124],
              [-39.940513190577974, -8.217434887673514],
              [-39.940513282352867, -8.217434973208407],
              [-39.940513375307468, -8.217435057469411],
              [-39.940513469423969, -8.217435140440349],
              [-39.940513564684331, -8.217435222105339],
              [-39.940513661070305, -8.217435302448735],
              [-39.940513758563398, -8.217435381455129],
              [-39.940513857144957, -8.217435459109369],
              [-39.940513956796067, -8.217435535396616],
              [-39.940514057497644, -8.217435610302214],
              [-39.940514159230382, -8.217435683811798],
              [-39.940514261974783, -8.217435755911287],
              [-39.940514365711167, -8.217435826586883],
              [-39.94051447041965, -8.217435895825055],
              [-39.940514576080126, -8.217435963612495],
              [-39.940514682672436, -8.21743602993625],
              [-39.940514790176074, -8.217436094783592],
              [-39.940514898570463, -8.217436158142064],
              [-39.94051500783484, -8.217436219999556],
              [-39.940515117948237, -8.21743628034422],
              [-39.940515228889595, -8.217436339164481],
              [-39.940515340637617, -8.21743639644907],
              [-39.940515453170896, -8.21743645218697],
              [-39.94051556646788, -8.217436506367548],
              [-39.940515680506834, -8.217436558980442],
              [-39.940515795265924, -8.217436610015485],
              [-39.940515910723136, -8.217436659462967],
              [-39.940516026856379, -8.217436707313393],
              [-39.940516143643357, -8.217436753557577],
              [-39.940516261061717, -8.217436798186686],
              [-39.94051637908894, -8.217436841192134],
              [-39.940516497702419, -8.217436882565718],
              [-39.940516616879414, -8.217436922299456],
              [-39.940516736597075, -8.217436960385786],
              [-39.940516856832502, -8.217436996817387],
              [-39.940516977562616, -8.217437031587293],
              [-39.940517098764282, -8.217437064688792],
              [-39.940517220414279, -8.21743709611561],
              [-39.940517342489294, -8.217437125861641],
              [-39.940517464965936, -8.217437153921262],
              [-39.940517587820736, -8.217437180289057],
              [-39.940517711030125, -8.217437204959982],
              [-39.940517834570528, -8.217437227929304],
              [-39.940517958418248, -8.21743724919264],
              [-39.940518082549545, -8.217437268745902],
              [-39.940518206940659, -8.217437286585346],
              [-39.940518331567723, -8.217437302707548],
              [-39.940518456406849, -8.217437317109415],
              [-39.940518581434148, -8.21743732978821],
              [-39.940518706625632, -8.21743734074146],
              [-39.940518712920742, -8.217437358289738],
              [-39.941868263186755, -8.217546079542828],
              [-39.941868252443811, -8.217546040258853],
              [-39.941868305194546, -8.217546044661871],
              [-39.941868357918636, -8.217546049371235],
              [-39.941868410614241, -8.217546054386812],
              [-39.941868463279597, -8.217546059708422],
              [-39.941868515912923, -8.217546065335885],
              [-39.941868568512426, -8.217546071269032],
              [-39.941868621076296, -8.217546077507636],
              [-39.941868673602755, -8.217546084051484],
              [-39.941868726090021, -8.217546090900358],
              [-39.94186877853631, -8.217546098054028],
              [-39.941868830939853, -8.217546105512257],
              [-39.941868883298817, -8.217546113274782],
              [-39.941868935611488, -8.217546121341361],
              [-39.941868987876056, -8.217546129711687],
              [-39.941869040090722, -8.217546138385497],
              [-39.941869092253761, -8.217546147362496],
              [-39.941869144363352, -8.217546156642362],
              [-39.941869196417748, -8.21754616622478],
              [-39.94186924841518, -8.217546176109458],
              [-39.941869300353872, -8.217546186296017],
              [-39.941869352232061, -8.217546196784134],
              [-39.941869404047992, -8.217546207573459],
              [-39.941869455799868, -8.217546218663607],
              [-39.941869507485983, -8.217546230054207],
              [-39.941869559104518, -8.217546241744865],
              [-39.941869610653768, -8.217546253735213],
              [-39.941869662131957, -8.217546266024796],
              [-39.941869713537358, -8.217546278613217],
              [-39.941869764868201, -8.217546291500081],
              [-39.941869816122754, -8.217546304684909],
              [-39.941869867299239, -8.217546318167248],
              [-39.941869918395966, -8.217546331946663],
              [-39.941869969411144, -8.217546346022683],
              [-39.94187002034311, -8.217546360394838],
              [-39.941870071190067, -8.217546375062591],
              [-39.941870121950323, -8.217546390025493],
              [-39.941870172622146, -8.21754640528302],
              [-39.941870223203779, -8.217546420834694],
              [-39.941870273693553, -8.217546436679912],
              [-39.941870324089713, -8.217546452818169],
              [-39.941870374390568, -8.217546469248935],
              [-39.941870424594406, -8.217546485971621],
              [-39.941870474699499, -8.21754650298567],
              [-39.941870524704179, -8.217546520290508],
              [-39.941870574606696, -8.217546537885559],
              [-39.941870624405411, -8.217546555770188],
              [-39.941870674098595, -8.217546573943826],
              [-39.941870723684559, -8.217546592405817],
              [-39.941870773161639, -8.217546611155566],
              [-39.941870822528116, -8.217546630192397],
              [-39.941870871782335, -8.217546649515723],
              [-39.941870920922632, -8.217546669124847],
              [-39.941870969947331, -8.217546689019095],
              [-39.941871018854741, -8.217546709197796],
              [-39.941871067643213, -8.217546729660263],
              [-39.941871116311106, -8.21754675040583],
              [-39.941871164856742, -8.217546771433758],
              [-39.941871213278468, -8.217546792743359],
              [-39.941871261574661, -8.217546814333863],
              [-39.941871309743661, -8.21754683620458],
              [-39.941871357783839, -8.217546858354762],
              [-39.941871405693554, -8.217546880783633],
              [-39.94187145347118, -8.21754690349047],
              [-39.941871501115095, -8.217546926474443],
              [-39.941871548623674, -8.217546949734823],
              [-39.941871595995309, -8.217546973270791],
              [-39.941871643228389, -8.217546997081548],
              [-39.941871690321292, -8.217547021166315],
              [-39.941871737272436, -8.21754704552424],
              [-39.941871784080206, -8.217547070154479],
              [-39.941871830743047, -8.217547095056222],
              [-39.941871877259324, -8.217547120228636],
              [-39.941871923627481, -8.217547145670839],
              [-39.941871969845941, -8.21754717138197],
              [-39.941872015913141, -8.217547197361164],
              [-39.941872061827496, -8.217547223607518],
              [-39.941872107587457, -8.217547250120177],
              [-39.941872153191468, -8.217547276898227],
              [-39.941872198637952, -8.217547303940723],
              [-39.941872243925417, -8.217547331246765],
              [-39.94187228905227, -8.217547358815448],
              [-39.941872334017013, -8.21754738664583],
              [-39.94187237881809, -8.217547414736936],
              [-39.941872423454008, -8.217547443087829],
              [-39.941872467923211, -8.217547471697532],
              [-39.941872512224229, -8.217547500565111],
              [-39.94187255635552, -8.217547529689535],
              [-39.941872600315605, -8.217547559069818],
              [-39.941872644102972, -8.21754758870502],
              [-39.941872687716163, -8.217547618594065],
              [-39.941872731153666, -8.217547648735961],
              [-39.941872774414009, -8.217547679129684],
              [-39.941872817495728, -8.217547709774214],
              [-39.941872860397353, -8.21754774066849],
              [-39.941872903117421, -8.217547771811471],
              [-39.941872945654502, -8.217547803202102],
              [-39.94187298800712, -8.217547834839305],
              [-39.941873030173852, -8.217547866722006],
              [-39.941873072153491, -8.217547898848487],
              [-39.941873078876355, -8.217547877132418],
              [-39.942891144063559, -8.218331714613242],
              [-39.942891148607742, -8.218331748894734],
              [-39.94289117127726, -8.218331766291863],
              [-39.942891194001703, -8.218331783617723],
              [-39.942891216780843, -8.218331800872145],
              [-39.942891239614482, -8.218331818054946],
              [-39.942891262502357, -8.218331835165952],
              [-39.942891285444261, -8.218331852205017],
              [-39.942891308439961, -8.218331869171934],
              [-39.942891331489221, -8.218331886066565],
              [-39.942891354591829, -8.218331902888723],
              [-39.942891377747557, -8.218331919638276],
              [-39.942891400956142, -8.218331936315007],
              [-39.942891424217386, -8.218331952918756],
              [-39.942891447531046, -8.218331969449371],
              [-39.942891470896896, -8.218331985906703],
              [-39.942891494314686, -8.218332002290579],
              [-39.942891517784219, -8.218332018600846],
              [-39.942891541305215, -8.218332034837331],
              [-39.942891564877478, -8.218332050999855],
              [-39.942891588500764, -8.218332067088271],
              [-39.942891612174826, -8.218332083102428],
              [-39.942891635899443, -8.218332099042176],
              [-39.942891659674387, -8.218332114907321],
              [-39.942891683499383, -8.218332130697728],
              [-39.942891707374237, -8.218332146413253],
              [-39.942891731298694, -8.218332162053711],
              [-39.942891755272505, -8.218332177618988],
              [-39.942891779295458, -8.21833219310888],
              [-39.94289180336731, -8.21833220852325],
              [-39.942891827487777, -8.218332223861953],
              [-39.942891851656682, -8.218332239124837],
              [-39.942891875873755, -8.218332254311749],
              [-39.942891900138754, -8.218332269422536],
              [-39.942891924451438, -8.218332284457068],
              [-39.942891948811578, -8.218332299415144],
              [-39.942891973218913, -8.218332314296655],
              [-39.942891997673215, -8.218332329101441],
              [-39.942892022174235, -8.218332343829365],
              [-39.942892046721738, -8.21833235848028],
              [-39.942892071315462, -8.218332373053997],
              [-39.942892095955187, -8.218332387550451],
              [-39.94289212064065, -8.218332401969437],
              [-39.942892145371609, -8.21833241631086],
              [-39.942892170147822, -8.218332430574513],
              [-39.942892194969041, -8.218332444760298],
              [-39.942892219835024, -8.218332458868083],
              [-39.942892244745501, -8.2183324728977],
              [-39.942892269700259, -8.218332486849025],
              [-39.942892294699028, -8.218332500721914],
              [-39.942892319741574, -8.218332514516231],
              [-39.942892344827627, -8.218332528231841],
              [-39.942892369956965, -8.218332541868607],
              [-39.942892395129313, -8.2183325554264],
              [-39.942892420344428, -8.218332568905037],
              [-39.942892445602077, -8.218332582304461],
              [-39.942892470901981, -8.21833259562449],
              [-39.942892496243907, -8.218332608864975],
              [-39.942892521627606, -8.218332622025846],
              [-39.942892547052807, -8.218332635106922],
              [-39.942892572519284, -8.218332648108081],
              [-39.942892598026752, -8.218332661029226],
              [-39.942892623574991, -8.218332673870188],
              [-39.94289264916371, -8.21833268663087],
              [-39.942892674792702, -8.218332699311148],
              [-39.942892700461677, -8.218332711910824],
              [-39.942892726170385, -8.218332724429864],
              [-39.942892751918563, -8.218332736868105],
              [-39.942892777705985, -8.218332749225421],
              [-39.942892803532366, -8.218332761501722],
              [-39.942892829397465, -8.218332773696828],
              [-39.942892855301011, -8.218332785810647],
              [-39.942892881242763, -8.218332797843079],
              [-39.942892907222458, -8.218332809793967],
              [-39.94289293323984, -8.21833282166325],
              [-39.942892959294639, -8.218332833450722],
              [-39.942892985386592, -8.218332845156338],
              [-39.942893011515473, -8.218332856779959],
              [-39.942893037680975, -8.218332868321468],
              [-39.942893063882892, -8.218332879780752],
              [-39.942893090120926, -8.218332891157687],
              [-39.942893116394799, -8.218332902452179],
              [-39.942893142704307, -8.21833291366411],
              [-39.942893169049164, -8.21833292479336],
              [-39.942893195429079, -8.218332935839825],
              [-39.942893221843818, -8.218332946803379],
              [-39.94289324829311, -8.218332957683952],
              [-39.942893274776708, -8.218332968481393],
              [-39.94289330129434, -8.218332979195619],
              [-39.942893327845724, -8.218332989826527],
              [-39.942893354430609, -8.218333000373981],
              [-39.942893381048734, -8.21833301083792],
              [-39.942893407699856, -8.218333021218223],
              [-39.94289343438367, -8.21833303151476],
              [-39.942893461099928, -8.218333041727456],
              [-39.942893487848366, -8.218333051856222],
              [-39.942893514628729, -8.218333061900932],
              [-39.942893541440732, -8.218333071861469],
              [-39.942893568284113, -8.218333081737804],
              [-39.942893595158615, -8.218333091529752],
              [-39.942893622063963, -8.218333101237244],
              [-39.94289359837358, -8.218333065507847],
              [-39.944133737533647, -8.218778302544969],
              [-39.944133718196674, -8.218778327020473],
              [-39.944133819891633, -8.218778362852683],
              [-39.944133922006749, -8.218778397478541],
              [-39.944134024527628, -8.218778430893162],
              [-39.944134127439774, -8.21877846309186],
              [-39.944134230728679, -8.218778494070053],
              [-39.944134334379754, -8.21877852382338],
              [-39.944134438378349, -8.218778552347647],
              [-39.944134542709804, -8.218778579638812],
              [-39.944134647359384, -8.218778605693034],
              [-39.944134752312301, -8.218778630506641],
              [-39.944134857553742, -8.218778654076129],
              [-39.944134963068876, -8.218778676398136],
              [-39.944135068842776, -8.218778697469576],
              [-39.944135174860499, -8.218778717287398],
              [-39.944135281107101, -8.218778735848865],
              [-39.944135387567577, -8.218778753151325],
              [-39.944135494226906, -8.218778769192344],
              [-39.944135601070016, -8.218778783969647],
              [-39.944135708081795, -8.218778797481162],
              [-39.944135815247186, -8.218778809724974],
              [-39.944135922551034, -8.218778820699361],
              [-39.944136029978196, -8.218778830402766],
              [-39.944136137513496, -8.218778838833819],
              [-39.944136245141756, -8.218778845991329],
              [-39.944136352847785, -8.218778851874285],
              [-39.944136460616363, -8.218778856481899],
              [-39.944136568432306, -8.218778859813453],
              [-39.944136676280358, -8.2187788618685],
              [-39.944136784145314, -8.218778862646767],
              [-39.944136892011926, -8.218778862148135],
              [-39.944136999864995, -8.218778860372668],
              [-39.944137107689265, -8.218778857320592],
              [-39.944137215469517, -8.218778852992397],
              [-39.944137323190546, -8.218778847388672],
              [-39.944137430837138, -8.218778840510183],
              [-39.944137538394095, -8.21877883235792],
              [-39.944137645846226, -8.218778822933034],
              [-39.944137753178381, -8.218778812236856],
              [-39.944137860375385, -8.218778800270888],
              [-39.944137967422101, -8.218778787036831],
              [-39.944138074303439, -8.218778772536542],
              [-39.944138181004298, -8.218778756772078],
              [-39.944138287509602, -8.21877873974565],
              [-39.944138393804323, -8.21877872145968],
              [-39.944138499873461, -8.21877870191677],
              [-39.944138605702022, -8.218778681119625],
              [-39.944138711275102, -8.218778659071219],
              [-39.944138816577741, -8.218778635774635],
              [-39.944138921595119, -8.218778611233173],
              [-39.944139026312399, -8.218778585450323],
              [-39.944139130714767, -8.218778558429692],
              [-39.944139234787521, -8.218778530175122],
              [-39.944139338515946, -8.218778500690572],
              [-39.944139441885412, -8.218778469980233],
              [-39.944139544881317, -8.218778438048464],
              [-39.944139647489102, -8.218778404899696],
              [-39.944139749694322, -8.218778370538677],
              [-39.944139851482504, -8.218778334970237],
              [-39.944139952839315, -8.218778298199396],
              [-39.944140053750409, -8.218778260231307],
              [-39.944140154201563, -8.218778221071386],
              [-39.944140254178599, -8.218778180725144],
              [-39.944140353667379, -8.218778139198287],
              [-39.944140452653862, -8.218778096496669],
              [-39.9441405511241, -8.218778052626309],
              [-39.94414064906416, -8.218778007593416],
              [-39.944140746460228, -8.218777961404326],
              [-39.944140843298555, -8.218777914065612],
              [-39.944140939565436, -8.218777865583887],
              [-39.944141035247327, -8.218777815966021],
              [-39.944141130330721, -8.218777765219045],
              [-39.944141224802159, -8.218777713350114],
              [-39.944141318648306, -8.218777660366531],
              [-39.944141411855945, -8.218777606275795],
              [-39.944141504411881, -8.218777551085546],
              [-39.944141596303062, -8.218777494803556],
              [-39.944141687516513, -8.218777437437785],
              [-39.944141778039366, -8.218777378996315],
              [-39.944141867858818, -8.218777319487428],
              [-39.94414195696222, -8.218777258919484],
              [-39.944142045336953, -8.218777197301083],
              [-39.944142132970569, -8.218777134640893],
              [-39.944142219850697, -8.218777070947741],
              [-39.944142305965045, -8.21877700623067],
              [-39.944142391301483, -8.218776940498802],
              [-39.94414247584794, -8.218776873761401],
              [-39.944142559592507, -8.21877680602792],
              [-39.944142642523339, -8.218776737307861],
              [-39.944142724628733, -8.218776667610973],
              [-39.944142805897087, -8.218776596947089],
              [-39.944142886316953, -8.218776525326186],
              [-39.944142965876949, -8.218776452758348],
              [-39.944143044565855, -8.218776379253868],
              [-39.944143122372566, -8.218776304823109],
              [-39.944143199286096, -8.218776229476546],
              [-39.944143275295573, -8.21877615322488],
              [-39.944143350390277, -8.218776076078788],
              [-39.944143424559606, -8.218775998049255],
              [-39.944143497793092, -8.218775919147234],
              [-39.944143536406948, -8.218775928027595],
              [-39.94538214968987, -8.217425327225998],
              [-39.946624105864217, -8.216071459639306],
              [-39.946624079783703, -8.216071436779554],
              [-39.946624099269052, -8.216071415468651],
              [-39.946624118686131, -8.216071394095948],
              [-39.946624138034721, -8.216071372661713],
              [-39.946624157314652, -8.216071351166114],
              [-39.94662417652571, -8.216071329609427],
              [-39.94662419566771, -8.216071307991838],
              [-39.946624214740446, -8.216071286313575],
              [-39.946624233743719, -8.216071264574863],
              [-39.946624252677367, -8.216071242775927],
              [-39.946624271541161, -8.216071220916939],
              [-39.946624290334938, -8.2160711989982],
              [-39.946624309058492, -8.216071177019884],
              [-39.946624327711646, -8.216071154982219],
              [-39.946624346294186, -8.216071132885439],
              [-39.946624364805942, -8.216071110729761],
              [-39.946624383246721, -8.216071088515402],
              [-39.94662440161634, -8.216071066242643],
              [-39.946624419914613, -8.216071043911622],
              [-39.946624438141342, -8.216071021522605],
              [-39.946624456296355, -8.216070999075837],
              [-39.946624474379455, -8.21607097657154],
              [-39.94662449239047, -8.216070954009915],
              [-39.946624510329222, -8.216070931391252],
              [-39.946624528195514, -8.216070908715702],
              [-39.946624545989167, -8.216070885983532],
              [-39.946624563710003, -8.216070863195],
              [-39.946624581357845, -8.216070840350318],
              [-39.946624598932502, -8.216070817449712],
              [-39.946624616433816, -8.216070794493387],
              [-39.946624633861596, -8.216070771481622],
              [-39.946624651215672, -8.216070748414641],
              [-39.946624668495836, -8.216070725292642],
              [-39.94662468570197, -8.2160707021159],
              [-39.946624702833859, -8.216070678884618],
              [-39.946624719891339, -8.216070655599095],
              [-39.946624736874227, -8.216070632259502],
              [-39.946624753782359, -8.216070608866097],
              [-39.946624770615578, -8.216070585419143],
              [-39.946624787373679, -8.216070561918812],
              [-39.946624804056519, -8.21607053836542],
              [-39.946624820663914, -8.216070514759194],
              [-39.946624837195728, -8.216070491100318],
              [-39.946624853651741, -8.216070467389038],
              [-39.946624870031819, -8.216070443625668],
              [-39.946624886335798, -8.216070419810386],
              [-39.946624902563478, -8.216070395943461],
              [-39.94662491871474, -8.21607037202512],
              [-39.946624934789376, -8.216070348055609],
              [-39.946624950787275, -8.216070324035188],
              [-39.946624966708242, -8.216070299964068],
              [-39.946624982552088, -8.216070275842526],
              [-39.946624998318711, -8.216070251670795],
              [-39.946625014007907, -8.216070227449128],
              [-39.946625029619533, -8.216070203177763],
              [-39.946625045153439, -8.216070178856931],
              [-39.946625060609456, -8.216070154486927],
              [-39.94662507598742, -8.216070130067964],
              [-39.946625091287189, -8.216070105600263],
              [-39.946625106508613, -8.216070081084112],
              [-39.946625121651508, -8.216070056519763],
              [-39.946625136715738, -8.216070031907419],
              [-39.946625151701149, -8.216070007247401],
              [-39.946625166607596, -8.216069982539926],
              [-39.946625181434925, -8.216069957785214],
              [-39.946625196182971, -8.21606993298359],
              [-39.946625210851607, -8.216069908135216],
              [-39.946625225440663, -8.216069883240433],
              [-39.946625239949995, -8.216069858299392],
              [-39.946625254379484, -8.216069833312432],
              [-39.946625268728951, -8.216069808279755],
              [-39.946625282998234, -8.216069783201661],
              [-39.946625297187239, -8.216069758078408],
              [-39.94662531129579, -8.21606973291018],
              [-39.946625325323737, -8.216069707697349],
              [-39.946625339270966, -8.216069682440043],
              [-39.9466253531373, -8.216069657138538],
              [-39.946625366922646, -8.216069631793209],
              [-39.946625380626813, -8.216069606404201],
              [-39.946625394249686, -8.216069580971819],
              [-39.946625407791124, -8.216069555496297],
              [-39.946625421251007, -8.216069529977897],
              [-39.946625434629176, -8.216069504416863],
              [-39.946625447925491, -8.216069478813516],
              [-39.946625461139824, -8.216069453168084],
              [-39.946625474272054, -8.216069427480777],
              [-39.946625487322024, -8.21606940175195],
              [-39.946625500289635, -8.216069375981792],
              [-39.946625513174709, -8.21606935017059],
              [-39.946625525977154, -8.216069324318578],
              [-39.946625538696829, -8.216069298426042],
              [-39.946625551333604, -8.216069272493272],
              [-39.946625563887338, -8.216069246520521],
              [-39.946625576357931, -8.216069220508015],
              [-39.946625588745206, -8.216069194456056],
              [-39.946625601049107, -8.216069168364871],
              [-39.946625613269454, -8.216069142234764],
              [-39.946625625406149, -8.216069116065974],
              [-39.946625637459064, -8.216069089858806],
              [-39.946625649428064, -8.216069063613471],
              [-39.946625643570073, -8.216069104503218],
              [-39.947179786164007, -8.214848899999973],
              [-39.947179749198916, -8.214848884324669],
              [-39.947179839681056, -8.21484869116774],
              [-39.947179934707137, -8.214848500188873],
              [-39.947180034224345, -8.214848311494114],
              [-39.94718013817743, -8.214848125188306],
              [-39.94718024650863, -8.2148479413749],
              [-39.947180359157805, -8.21484776015601],
              [-39.947180476062343, -8.214847581632325],
              [-39.947180597157313, -8.214847405902958],
              [-39.947180722375457, -8.214847233065575],
              [-39.947180851647254, -8.214847063216132],
              [-39.947180984900868, -8.214846896448982],
              [-39.947181122062275, -8.214846732856772],
              [-39.947181263055292, -8.214846572530348],
              [-39.947181407801637, -8.214846415558796],
              [-39.947181556220883, -8.214846262029283],
              [-39.947181708230588, -8.214846112027086],
              [-39.947181863746316, -8.214845965635526],
              [-39.947182022681687, -8.214845822935953],
              [-39.947182184948439, -8.214845684007571],
              [-39.947182350456409, -8.214845548927586],
              [-39.947182519113674, -8.214845417771009],
              [-39.94718269082658, -8.214845290610722],
              [-39.947182865499698, -8.21484516751733],
              [-39.947183043036048, -8.214845048559207],
              [-39.947183223337014, -8.214844933802434],
              [-39.947183406302408, -8.214844823310793],
              [-39.947183591830637, -8.214844717145589],
              [-39.947183779818637, -8.214844615365809],
              [-39.94718397016198, -8.214844518028015],
              [-39.947184162754965, -8.214844425186262],
              [-39.947184357490578, -8.214844336892121],
              [-39.947184554260694, -8.21484425319464],
              [-39.947184752955998, -8.214844174140278],
              [-39.947184953466106, -8.214844099772993],
              [-39.947185155679648, -8.214844030134042],
              [-39.94718535948433, -8.214843965262158],
              [-39.947185564766919, -8.214843905193339],
              [-39.947185771413395, -8.214843849960968],
              [-39.947185979308998, -8.214843799595707],
              [-39.947186188338229, -8.214843754125527],
              [-39.947186398384986, -8.214843713575727],
              [-39.947186609332597, -8.214843677968791],
              [-39.94718682106388, -8.214843647324537],
              [-39.947187033461255, -8.214843621659934],
              [-39.94718724640672, -8.214843600989258],
              [-39.947187459781993, -8.214843585323994],
              [-39.947187673468569, -8.214843574672837],
              [-39.947187887347752, -8.214843569041754],
              [-39.947188101300725, -8.214843568433794],
              [-39.947188315208663, -8.214843572849347],
              [-39.947188528952758, -8.214843582285903],
              [-39.947188742414241, -8.214843596738323],
              [-39.947188955474608, -8.214843616198465],
              [-39.94718916801547, -8.214843640655603],
              [-39.947189379918761, -8.214843670096117],
              [-39.947189591066817, -8.214843704503657],
              [-39.947189801342326, -8.21484374385911],
              [-39.947190010628496, -8.214843788140612],
              [-39.947190218809084, -8.214843837323594],
              [-39.947190425768433, -8.214843891380694],
              [-39.947190631391635, -8.214843950281931],
              [-39.947190835564413, -8.214844013994552],
              [-39.947191038173393, -8.214844082483165],
              [-39.94719123910604, -8.214844155709738],
              [-39.947191438250734, -8.214844233633618],
              [-39.947191635496864, -8.214844316211501],
              [-39.947191830734852, -8.214844403397533],
              [-39.947192023856275, -8.214844495143254],
              [-39.947192214753841, -8.21484459139775],
              [-39.94719240332153, -8.214844692107517],
              [-39.947192589454595, -8.214844797216637],
              [-39.947192773049657, -8.214844906666727],
              [-39.947192954004727, -8.214845020396975],
              [-39.947193132219297, -8.214845138344227],
              [-39.947193307594361, -8.214845260442951],
              [-39.947193480032524, -8.21484538662531],
              [-39.947193649438006, -8.214845516821265],
              [-39.947193815716695, -8.214845650958489],
              [-39.947193978776241, -8.214845788962439],
              [-39.947194138526086, -8.214845930756493],
              [-39.947194294877455, -8.214846076261853],
              [-39.947194447743541, -8.214846225397732],
              [-39.947194597039406, -8.21484637808126],
              [-39.947194742682136, -8.214846534227677],
              [-39.94719488459085, -8.214846693750248],
              [-39.947195022686685, -8.214846856560309],
              [-39.947195156892967, -8.214847022567458],
              [-39.947195287135138, -8.214847191679491],
              [-39.94719541334085, -8.21484736380245],
              [-39.947195535440024, -8.214847538840736],
              [-39.947195653364801, -8.21484771669717],
              [-39.947195767049706, -8.214847897272929],
              [-39.947195876431593, -8.214848080467677],
              [-39.947195981449703, -8.21484826617969],
              [-39.947196082045693, -8.214848454305818],
              [-39.947196178163686, -8.21484864474156],
              [-39.9471962697503, -8.214848837381135],
              [-39.947196356754674, -8.21484903211751],
              [-39.947196439127389, -8.214849228843027],
              [-39.947196399911782, -8.214849222672948],
              [-39.947870680655591, -8.216514409305224],
              [-39.947870700647208, -8.216514450679696],
              [-39.947870728624629, -8.2165145206126],
              [-39.947870756016783, -8.216514590775059],
              [-39.947870782821781, -8.216514661162254],
              [-39.947870809037795, -8.216514731769312],
              [-39.947870834662979, -8.216514802591306],
              [-39.947870859695556, -8.216514873623355],
              [-39.947870884133792, -8.216514944860544],
              [-39.947870907976018, -8.216515016297903],
              [-39.94787093122055, -8.216515087930503],
              [-39.947870953865795, -8.216515159753394],
              [-39.947870975910185, -8.21651523176158],
              [-39.94787099735219, -8.216515303950093],
              [-39.947871018190327, -8.216515376313913],
              [-39.947871038423138, -8.216515448848011],
              [-39.94787105804923, -8.2165155215474],
              [-39.947871077067248, -8.216515594407014],
              [-39.947871095475875, -8.216515667421788],
              [-39.947871113273827, -8.216515740586717],
              [-39.94787113045988, -8.216515813896702],
              [-39.947871147032842, -8.216515887346675],
              [-39.947871162991554, -8.216515960931535],
              [-39.947871178334921, -8.216516034646178],
              [-39.947871193061872, -8.216516108485521],
              [-39.947871207171382, -8.216516182444431],
              [-39.947871220662506, -8.216516256517766],
              [-39.947871233534279, -8.216516330700408],
              [-39.947871245785819, -8.216516404987221],
              [-39.947871257416274, -8.216516479373073],
              [-39.947871268424819, -8.216516553852795],
              [-39.947871278810723, -8.216516628421209],
              [-39.947871288573253, -8.216516703073184],
              [-39.947871297711735, -8.216516777803516],
              [-39.947871306225537, -8.21651685260705],
              [-39.947871314114067, -8.216516927478603],
              [-39.947871321376773, -8.216517002413001],
              [-39.947871328013164, -8.216517077405001],
              [-39.947871334022757, -8.216517152449441],
              [-39.947871339405161, -8.216517227541113],
              [-39.947871344160006, -8.216517302674829],
              [-39.947871348286945, -8.216517377845367],
              [-39.947871351785686, -8.216517453047542],
              [-39.947871354656009, -8.216517528276109],
              [-39.947871356897686, -8.216517603525867],
              [-39.947871358510604, -8.216517678791595],
              [-39.947871359494613, -8.216517754068125],
              [-39.947871359849664, -8.216517829350188],
              [-39.947871359575721, -8.216517904632592],
              [-39.947871358672813, -8.216517979910112],
              [-39.94787135714099, -8.21651805517752],
              [-39.947871354980379, -8.216518130429632],
              [-39.947871352191122, -8.216518205661224],
              [-39.94787134877339, -8.216518280867087],
              [-39.94787134472746, -8.216518356042005],
              [-39.94787134005356, -8.216518431180747],
              [-39.947871334752065, -8.216518506278147],
              [-39.947871328823318, -8.216518581328959],
              [-39.94787132226773, -8.216518656328018],
              [-39.947871315085763, -8.21651873127013],
              [-39.947871307277907, -8.216518806150082],
              [-39.947871298844703, -8.216518880962694],
              [-39.94787128978674, -8.216518955702773],
              [-39.94787128010465, -8.216519030365143],
              [-39.947871269799087, -8.21651910494465],
              [-39.947871258870791, -8.216519179436101],
              [-39.947871247320478, -8.216519253834347],
              [-39.947871235148995, -8.216519328134218],
              [-39.947871222357158, -8.216519402330588],
              [-39.947871208945855, -8.216519476418323],
              [-39.947871194916011, -8.216519550392272],
              [-39.947871180268628, -8.216519624247324],
              [-39.947871165004685, -8.216519697978326],
              [-39.947871149125277, -8.216519771580202],
              [-39.947871132631455, -8.216519845047845],
              [-39.947871115524386, -8.216519918376148],
              [-39.947871097805283, -8.216519991560071],
              [-39.947871079475327, -8.21652006459451],
              [-39.947871060535824, -8.21652013747442],
              [-39.947871040988055, -8.21652021019473],
              [-39.947871020833396, -8.216520282750437],
              [-39.947871000073249, -8.216520355136492],
              [-39.947870978709034, -8.216520427347877],
              [-39.947870956742236, -8.216520499379584],
              [-39.947870934174389, -8.216520571226656],
              [-39.947870911007037, -8.21652064288407],
              [-39.947870887241805, -8.216520714346872],
              [-39.947870862880322, -8.216520785610138],
              [-39.947870837924292, -8.216520856668907],
              [-39.94787081237542, -8.216520927518259],
              [-39.947870786235505, -8.216520998153303],
              [-39.947870759506358, -8.216521068569101],
              [-39.947870732189806, -8.216521138760827],
              [-39.947870704287737, -8.216521208723597],
              [-39.947870675802115, -8.21652127845255],
              [-39.947870646734913, -8.216521347942876],
              [-39.947870617088128, -8.216521417189732],
              [-39.947870586863793, -8.216521486188368],
              [-39.947870556064053, -8.216521554933944],
              [-39.94787052469102, -8.216521623421729],
              [-39.947870492746219, -8.216521691648412],
              [-39.947870516479306, -8.216521735574769],
              [-39.946860997023649, -8.218654497615455],
              [-39.946860989367543, -8.218654527128306],
              [-39.946860936127486, -8.218654641676492],
              [-39.94686088449734, -8.218654756953814],
              [-39.946860834487168, -8.218654872937813],
              [-39.946860786106704, -8.218654989605863],
              [-39.946860739365391, -8.218655106935293],
              [-39.946860694272303, -8.218655224903195],
              [-39.946860650836243, -8.218655343486631],
              [-39.946860609065681, -8.218655462662456],
              [-39.946860568968724, -8.218655582407502],
              [-39.946860530553217, -8.218655702698415],
              [-39.946860493826613, -8.218655823511765],
              [-39.946860458796081, -8.218655944824024],
              [-39.946860425468458, -8.218656066611542],
              [-39.946860393850223, -8.218656188850613],
              [-39.946860363947529, -8.218656311517414],
              [-39.946860335766225, -8.218656434588066],
              [-39.946860309311766, -8.218656558038592],
              [-39.946860284589334, -8.218656681844914],
              [-39.946860261603732, -8.21865680598296],
              [-39.946860240359435, -8.218656930428493],
              [-39.946860220860593, -8.218657055157317],
              [-39.946860203110994, -8.218657180145112],
              [-39.946860187114098, -8.218657305367547],
              [-39.946860172873031, -8.21865743080021],
              [-39.946860160390557, -8.218657556418696],
              [-39.946860149669092, -8.218657682198483],
              [-39.946860140710768, -8.218657808115092],
              [-39.946860133517291, -8.21865793414397],
              [-39.946860128090087, -8.218658060260653],
              [-39.946860124430195, -8.218658186440496],
              [-39.946860122538325, -8.218658312658947],
              [-39.946860122414868, -8.218658438891401],
              [-39.946860124059832, -8.218658565113268],
              [-39.946860127472924, -8.218658691299964],
              [-39.946860132653413, -8.218658817426942],
              [-39.946860139600346, -8.218658943469551],
              [-39.946860148312375, -8.218659069403328],
              [-39.94686015878775, -8.218659195203708],
              [-39.94686017102449, -8.21865932084617],
              [-39.946860185020185, -8.218659446306258],
              [-39.946860200772107, -8.218659571559501],
              [-39.94686021827718, -8.218659696581529],
              [-39.946860237531993, -8.218659821347957],
              [-39.946860258532844, -8.218659945834522],
              [-39.946860281275576, -8.218660070016954],
              [-39.946860305755777, -8.218660193871058],
              [-39.946860331968715, -8.218660317372665],
              [-39.946860359909252, -8.218660440497807],
              [-39.946860389571938, -8.218660563222448],
              [-39.946860420951005, -8.218660685522671],
              [-39.946860454040326, -8.218660807374665],
              [-39.946860488833487, -8.218660928754693],
              [-39.946860525323707, -8.218661049639136],
              [-39.946860563503847, -8.218661170004397],
              [-39.946860603366503, -8.218661289827049],
              [-39.946860644903879, -8.218661409083767],
              [-39.94686068810789, -8.218661527751294],
              [-39.94686073297013, -8.218661645806517],
              [-39.946860779481874, -8.218661763226471],
              [-39.946860827634026, -8.218661879988257],
              [-39.94686087741723, -8.218661996069121],
              [-39.946860928821771, -8.218662111446442],
              [-39.946860981837673, -8.218662226097788],
              [-39.946861036454557, -8.218662340000758],
              [-39.946861092661827, -8.218662453133232],
              [-39.946861150448505, -8.218662565473126],
              [-39.946861209803345, -8.218662676998589],
              [-39.946861270714791, -8.218662787687878],
              [-39.946861333170958, -8.218662897519426],
              [-39.946861397159694, -8.218663006471846],
              [-39.946861462668537, -8.218663114523897],
              [-39.946861529684718, -8.218663221654552],
              [-39.946861598195177, -8.218663327842949],
              [-39.946861668186571, -8.218663433068377],
              [-39.946861739645286, -8.218663537310322],
              [-39.946861812557373, -8.218663640548534],
              [-39.946861886908636, -8.218663742762844],
              [-39.946861962684608, -8.218663843933378],
              [-39.946862039870503, -8.218663944040424],
              [-39.946862118451314, -8.218664043064489],
              [-39.946862198411701, -8.218664140986247],
              [-39.946862279736102, -8.218664237786653],
              [-39.946862362408702, -8.218664333446815],
              [-39.946862446413334, -8.218664427948156],
              [-39.9468625317337, -8.218664521272215],
              [-39.94686261835313, -8.21866461340084],
              [-39.946862706254777, -8.218664704316076],
              [-39.946862795421495, -8.21866479400021],
              [-39.946862885835955, -8.218664882435759],
              [-39.9468629774805, -8.218664969605536],
              [-39.946863070337294, -8.218665055492508],
              [-39.946863164388255, -8.218665140079983],
              [-39.946863259615043, -8.218665223351456],
              [-39.946863355999142, -8.21866530529074],
              [-39.946863453521743, -8.218665385881859],
              [-39.946863552163862, -8.218665465109083],
              [-39.946863651906277, -8.218665542957009],
              [-39.946863752729577, -8.218665619410467],
              [-39.946863854614094, -8.218665694454565],
              [-39.946863837850628, -8.218665705862819],
              [-39.948556212617284, -8.219894094893187],
              [-39.948556246488394, -8.21989413075171],
              [-39.948556313601046, -8.219894179000653],
              [-39.948556381152414, -8.219894226638054],
              [-39.948556449136902, -8.219894273659934],
              [-39.948556517548838, -8.219894320062412],
              [-39.948556586382558, -8.219894365841597],
              [-39.948556655632331, -8.219894410993723],
              [-39.948556725292391, -8.219894455515004],
              [-39.94855679535695, -8.219894499401759],
              [-39.948556865820201, -8.21989454265035],
              [-39.948556936676269, -8.219894585257162],
              [-39.948557007919284, -8.219894627218661],
              [-39.94855707954332, -8.219894668531357],
              [-39.94855715154241, -8.219894709191822],
              [-39.948557223910569, -8.219894749196671],
              [-39.948557296641802, -8.219894788542582],
              [-39.948557369730054, -8.219894827226303],
              [-39.948557443169264, -8.219894865244576],
              [-39.948557516953308, -8.219894902594284],
              [-39.948557591076046, -8.219894939272294],
              [-39.948557665531354, -8.219894975275581],
              [-39.948557740312999, -8.219895010601137],
              [-39.948557815414823, -8.219895045246021],
              [-39.948557890830521, -8.219895079207355],
              [-39.948557966553864, -8.219895112482334],
              [-39.948558042578568, -8.2198951450682],
              [-39.948558118898269, -8.219895176962204],
              [-39.948558195506671, -8.219895208161708],
              [-39.948558272397371, -8.219895238664147],
              [-39.948558349563989, -8.219895268466964],
              [-39.948558427000137, -8.219895297567666],
              [-39.948558504699342, -8.219895325963895],
              [-39.948558582655174, -8.219895353653225],
              [-39.948558660861146, -8.219895380633378],
              [-39.948558739310741, -8.219895406902129],
              [-39.948558817997458, -8.219895432457253],
              [-39.948558896914754, -8.219895457296678],
              [-39.94855897605607, -8.219895481418325],
              [-39.948559055414819, -8.219895504820158],
              [-39.948559134984421, -8.219895527500242],
              [-39.948559214758255, -8.219895549456716],
              [-39.948559294729691, -8.219895570687749],
              [-39.948559374892071, -8.21989559119155],
              [-39.948559455238758, -8.219895610966448],
              [-39.94855953576306, -8.219895630010781],
              [-39.948559616458269, -8.219895648322959],
              [-39.948559697317712, -8.219895665901484],
              [-39.948559778334648, -8.21989568274487],
              [-39.948559859502325, -8.219895698851735],
              [-39.948559940814036, -8.219895714220733],
              [-39.948560022262996, -8.219895728850583],
              [-39.948560103842468, -8.219895742740089],
              [-39.948560185545624, -8.21989575588808],
              [-39.948560267365707, -8.219895768293467],
              [-39.948560349295903, -8.219895779955202],
              [-39.948560431329405, -8.219895790872343],
              [-39.948560513459405, -8.219895801043965],
              [-39.948560595679062, -8.219895810469231],
              [-39.948560677981554, -8.219895819147361],
              [-39.948560760360053, -8.219895827077643],
              [-39.948560842807673, -8.219895834259379],
              [-39.948560925317594, -8.219895840691988],
              [-39.948561007882944, -8.219895846374959],
              [-39.948561090496867, -8.21989585130782],
              [-39.948561173152498, -8.219895855490133],
              [-39.948561255842961, -8.219895858921545],
              [-39.948561338561397, -8.219895861601783],
              [-39.948561421300916, -8.219895863530645],
              [-39.948561504054652, -8.219895864707961],
              [-39.948561586815714, -8.219895865133633],
              [-39.948561669577238, -8.219895864807592],
              [-39.948561752332331, -8.219895863729917],
              [-39.94856183507413, -8.219895861900666],
              [-39.948561917795757, -8.219895859319998],
              [-39.948562000490327, -8.219895855988117],
              [-39.948562083150968, -8.219895851905314],
              [-39.948562165770809, -8.219895847071939],
              [-39.948562248343002, -8.219895841488372],
              [-39.948562330860668, -8.219895835155114],
              [-39.948562413316942, -8.21989582807263],
              [-39.948562495704977, -8.219895820241554],
              [-39.948562578017949, -8.219895811662512],
              [-39.948562660248975, -8.219895802336232],
              [-39.94856274239126, -8.219895792263504],
              [-39.948562824437943, -8.21989578144513],
              [-39.948562906382222, -8.219895769882022],
              [-39.948562988217304, -8.219895757575145],
              [-39.948563069936341, -8.219895744525552],
              [-39.948563151532589, -8.219895730734271],
              [-39.948563232999234, -8.219895716202483],
              [-39.94856331432954, -8.219895700931399],
              [-39.9485633955167, -8.219895684922284],
              [-39.948563476554, -8.219895668176449],
              [-39.948563557434696, -8.219895650695285],
              [-39.948563638152073, -8.219895632480268],
              [-39.948563718699404, -8.219895613532888],
              [-39.948563799070023, -8.219895593854746],
              [-39.948563879257222, -8.219895573447461],
              [-39.948563959254351, -8.219895552312732],
              [-39.948564039054759, -8.219895530452343],
              [-39.948564023301515, -8.219895523561208],
              [-39.950591936279828, -8.219330080501688],
              [-39.950591920131338, -8.219330091863791],
              [-39.950592020811008, -8.219330064411604],
              [-39.950592121800852, -8.219330038114506],
              [-39.950592223087533, -8.219330012975984],
              [-39.950592324657627, -8.219329988999363],
              [-39.950592426497721, -8.219329966187797],
              [-39.95059252859437, -8.219329944544297],
              [-39.950592630934047, -8.219329924071763],
              [-39.950592733503235, -8.219329904772886],
              [-39.950592836288372, -8.219329886650209],
              [-39.950592939275865, -8.219329869706122],
              [-39.950593042452113, -8.219329853942881],
              [-39.950593145803467, -8.219329839362571],
              [-39.950593249316249, -8.219329825967082],
              [-39.950593352976803, -8.219329813758247],
              [-39.950593456771401, -8.219329802737628],
              [-39.950593560686329, -8.219329792906693],
              [-39.950593664707846, -8.219329784266771],
              [-39.950593768822202, -8.21932977681899],
              [-39.950593873015627, -8.219329770564325],
              [-39.950593977274352, -8.219329765503602],
              [-39.95059408158459, -8.219329761637491],
              [-39.950594185932552, -8.219329758966515],
              [-39.950594290304451, -8.219329757490993],
              [-39.950594394686462, -8.219329757211158],
              [-39.95059449906482, -8.219329758127028],
              [-39.950594603425699, -8.219329760238514],
              [-39.950594707755322, -8.219329763545286],
              [-39.95059481203986, -8.219329768046929],
              [-39.95059491626558, -8.219329773742857],
              [-39.95059502041866, -8.219329780632295],
              [-39.950595124485339, -8.219329788714363],
              [-39.950595228451874, -8.219329797987983],
              [-39.950595332304495, -8.219329808451928],
              [-39.950595436029502, -8.219329820104791],
              [-39.95059553961314, -8.219329832945075],
              [-39.950595643041765, -8.219329846971045],
              [-39.950595746301666, -8.21932986218086],
              [-39.950595849379212, -8.219329878572502],
              [-39.950595952260748, -8.219329896143822],
              [-39.95059605493271, -8.219329914892469],
              [-39.950596157381497, -8.219329934815976],
              [-39.950596259593581, -8.219329955911734],
              [-39.950596361555434, -8.219329978176923],
              [-39.950596463253582, -8.219330001608627],
              [-39.950596564674584, -8.219330026203718],
              [-39.950596665805023, -8.219330051958954],
              [-39.950596766631527, -8.219330078870948],
              [-39.950596867140789, -8.219330106936109],
              [-39.950596967319498, -8.219330136150765],
              [-39.950597067154405, -8.219330166511014],
              [-39.950597166632335, -8.219330198012885],
              [-39.950597265740114, -8.219330230652165],
              [-39.95059736446467, -8.219330264424579],
              [-39.950597462792899, -8.219330299325641],
              [-39.950597560711849, -8.219330335350723],
              [-39.950597658208565, -8.219330372495104],
              [-39.950597755270131, -8.219330410753852],
              [-39.950597851883742, -8.21933045012192],
              [-39.950597948036624, -8.219330490594064],
              [-39.950598043716056, -8.219330532164982],
              [-39.950598138909371, -8.219330574829135],
              [-39.950598233604019, -8.219330618580917],
              [-39.950598327787453, -8.219330663414542],
              [-39.95059842144726, -8.219330709324062],
              [-39.950598514570991, -8.219330756303421],
              [-39.950598607146397, -8.219330804346415],
              [-39.950598699161219, -8.219330853446657],
              [-39.950598790603273, -8.219330903597704],
              [-39.950598881460493, -8.219330954792886],
              [-39.950598971720844, -8.219331007025451],
              [-39.950599061372429, -8.219331060288491],
              [-39.950599150403363, -8.219331114574976],
              [-39.9505992388019, -8.219331169877723],
              [-39.950599326556322, -8.219331226189391],
              [-39.950599413655056, -8.219331283502573],
              [-39.950599500086568, -8.219331341809674],
              [-39.950599585839448, -8.219331401103013],
              [-39.950599670902342, -8.219331461374704],
              [-39.950599755264022, -8.21933152261683],
              [-39.95059983891332, -8.219331584821246],
              [-39.950599921839178, -8.219331647979761],
              [-39.950600004030633, -8.219331712084021],
              [-39.950600085476836, -8.219331777125547],
              [-39.950600166166993, -8.219331843095706],
              [-39.950600246090453, -8.219331909985831],
              [-39.95060032523665, -8.219331977787034],
              [-39.950600403595111, -8.219332046490397],
              [-39.950600481155469, -8.219332116086809],
              [-39.950600557907499, -8.219332186567067],
              [-39.95060063384102, -8.219332257921863],
              [-39.950600708946027, -8.219332330141766],
              [-39.950600783212558, -8.219332403217216],
              [-39.950600856630821, -8.219332477138547],
              [-39.950600929191097, -8.219332551895981],
              [-39.950601000883786, -8.21933262747967],
              [-39.950601071699417, -8.219332703879587],
              [-39.950601141628638, -8.21933278108566],
              [-39.950601210662199, -8.219332859087659],
              [-39.950601278790749, -8.219332937875636],
              [-39.950601293690639, -8.21933295256215],
              [-39.951839749968116, -8.220781903580471],
              [-39.951839742496368, -8.220781917028646],
              [-39.951839742497029, -8.220781917028072],
            ],
          ],
          [
            [
              [-39.97062902027016, -8.211728376625432],
              [-39.970629042456608, -8.211728987049502],
              [-39.9706291059493, -8.211729594589421],
              [-39.970629210458583, -8.211730196474008],
              [-39.970629355507782, -8.211730789957857],
              [-39.970629540435276, -8.211731372333878],
              [-39.97062976439755, -8.211731940945713],
              [-39.970630026373051, -8.211732493199715],
              [-39.970630325166802, -8.211733026576864],
              [-39.970630659415924, -8.211733538644291],
              [-39.970631027595815, -8.21173402706626],
              [-39.971985767267029, -8.213410179499634],
              [-39.97198617537952, -8.213410650320984],
              [-39.971986615012732, -8.213411092078555],
              [-39.97198708408677, -8.213411502682394],
              [-39.971987580382383, -8.213411880189895],
              [-39.971988101551602, -8.213412222815068],
              [-39.971988645128718, -8.213412528936908],
              [-39.971989208542055, -8.21341279710715],
              [-39.971989789126056, -8.213413026057056],
              [-39.97199038413396, -8.213413214703479],
              [-39.971990990750733, -8.213413362153876],
              [-39.971991606106464, -8.213413467710687],
              [-39.971992227289853, -8.213413530874517],
              [-39.971992851362039, -8.213413551346518],
              [-39.97199347537051, -8.213413529029843],
              [-39.971994096363055, -8.213413464030062],
              [-39.971994711401699, -8.213413356654694],
              [-39.971995317576685, -8.213413207411772],
              [-39.971995912020141, -8.21341301700734],
              [-39.971996491919747, -8.213412786342195],
              [-39.97199705453194, -8.213412516507679],
              [-39.971997597194992, -8.213412208780364],
              [-39.971998117341514, -8.213411864616102],
              [-39.971998612510667, -8.213411485643203],
              [-39.971999080359794, -8.2134110736546],
              [-39.971999518675446, -8.213410630599395],
              [-39.97334483799871, -8.211953767869002],
              [-39.973345245525017, -8.211953294819221],
              [-39.973345619374925, -8.211952794924567],
              [-39.973345957772274, -8.211952270560015],
              [-39.97334625910932, -8.211951724216807],
              [-39.973346521954475, -8.211951158490644],
              [-39.973346745058912, -8.211950576069228],
              [-39.973346927362705, -8.211949979719655],
              [-39.973347067999718, -8.211949372275168],
              [-39.973347166301785, -8.211948756621709],
              [-39.973347221801895, -8.211948135684237],
              [-39.973347234236336, -8.211947512412825],
              [-39.973347203546069, -8.211946889768601],
              [-39.973347129876878, -8.211946270709744],
              [-39.973347013578774, -8.211945658177365],
              [-39.972890721185429, -8.209926228969977],
              [-39.97289057097548, -8.209925653251691],
              [-39.972890383104108, -8.209925088616112],
              [-39.972890158384047, -8.209924537505771],
              [-39.972889897787383, -8.209924002304707],
              [-39.972889602441434, -8.20992348532811],
              [-39.972889273623807, -8.209922988812336],
              [-39.972888912756936, -8.20992251490526],
              [-39.97288852140187, -8.209922065656933],
              [-39.972888101251542, -8.209921643010711],
              [-39.97288765412349, -8.209921248794906],
              [-39.972887654123497, -8.209921248794906],
              [-39.971535736868326, -8.208806099612625],
              [-39.97153526662958, -8.2088056835495],
              [-39.971534826433711, -8.208805236047585],
              [-39.971534418393482, -8.208804759254662],
              [-39.971534044467312, -8.208804255459205],
              [-39.971533706449883, -8.208803727079134],
              [-39.971533405963527, -8.208803176650516],
              [-39.971533144450454, -8.208802606815128],
              [-39.971532923165825, -8.208802020307939],
              [-39.971532743171686, -8.208801419943956],
              [-39.971532605331959, -8.208800808604654],
              [-39.971532510308194, -8.208800189224181],
              [-39.971532458556482, -8.208799564775333],
              [-39.971532450325185, -8.208798938255136],
              [-39.971532485653825, -8.208798312670682],
              [-39.971532564372843, -8.208797691024479],
              [-39.97153268610441, -8.208797076300151],
              [-39.971532850264268, -8.208796471448125],
              [-39.97153305606453, -8.208795879371431],
              [-39.971533302517436, -8.208795302911785],
              [-39.971533588440131, -8.208794744835915],
              [-39.971533912460288, -8.20879420782237],
              [-39.971534273022762, -8.208793694448591],
              [-39.971534668397005, -8.208793207178541],
              [-39.971535096685386, -8.208792748350893],
              [-39.971535096685372, -8.208792748350893],
              [-39.972169689114985, -8.208158458926121],
              [-39.969706323114906, -8.208139773985376],
              [-39.970612467063575, -8.208237722495433],
              [-39.970613091770772, -8.208237812007489],
              [-39.970613708725494, -8.208237944548335],
              [-39.9706143149456, -8.208238119477311],
              [-39.970614907500831, -8.208238335948892],
              [-39.970615483527006, -8.208238592916702],
              [-39.970616040239797, -8.208238889138656],
              [-39.970616574948252, -8.208239223182941],
              [-39.970617085067808, -8.208239593434904],
              [-39.97061756813271, -8.208239998104867],
              [-39.970618021807987, -8.208240435236798],
              [-39.97061844390074, -8.208240902717757],
              [-39.970618832370732, -8.208241398288138],
              [-39.970619185340219, -8.208241919552478],
              [-39.970619501103094, -8.2082424639912],
              [-39.970619778133049, -8.208243028972705],
              [-39.970620015091043, -8.208243611766001],
              [-39.970620210831683, -8.208244209554147],
              [-39.970620364408845, -8.208244819447629],
              [-39.970620475080182, -8.208245438498423],
              [-39.970620542310748, -8.208246063714254],
              [-39.97062056577559, -8.208246692073072],
              [-39.97062902027016, -8.211728376625432],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0134000',
        uf: 'PE',
        nome_proje: 'PA MARTIRES DA RESISTENCIA I',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1962.6003',
        capacidade: 51.0,
        num_famili: 50.0,
        fase: 4.0,
        data_de_cr: '27/12/2004',
        forma_obte: 'Doação',
        data_obten: '23/12/2004',
        area_calc_: 2565.6097,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.78089996647951, -8.341515554343186],
              [-39.777811584175076, -8.342104493300299],
              [-39.778210687084162, -8.342641956350638],
              [-39.778210389831571, -8.342642150181081],
              [-39.778223254157616, -8.342659478273116],
              [-39.77501870767891, -8.344746293511015],
              [-39.77203675105094, -8.343205573797393],
              [-39.76493332169116, -8.344559936882588],
              [-39.758859287736442, -8.345717842498031],
              [-39.751005137840679, -8.347215045576764],
              [-39.747047441418111, -8.347969436969993],
              [-39.744691428789849, -8.345304419197399],
              [-39.742851594850045, -8.349438949017136],
              [-39.746133648082846, -8.350887620652966],
              [-39.756482104013948, -8.362364945864686],
              [-39.755401060005319, -8.363345464149459],
              [-39.756182121237181, -8.364199416479766],
              [-39.763534841875511, -8.372238738522151],
              [-39.762822786074445, -8.372884726123777],
              [-39.762085886227936, -8.373553100842784],
              [-39.76144508621757, -8.374134460491211],
              [-39.760177714061534, -8.375284034985857],
              [-39.759098614875334, -8.374139609437817],
              [-39.757521081427434, -8.372379380785365],
              [-39.756274009537307, -8.371015844354307],
              [-39.75362362409733, -8.36811792983876],
              [-39.752289117684562, -8.366658852167564],
              [-39.752043841752808, -8.366390601450375],
              [-39.753124902688064, -8.36541009100252],
              [-39.746993025932085, -8.358705136408195],
              [-39.744856893572283, -8.356369390712219],
              [-39.744357355862441, -8.357930721152865],
              [-39.743709838849675, -8.359954694148147],
              [-39.743340393521756, -8.361109220178941],
              [-39.744924885484174, -8.363243229395653],
              [-39.745217074232841, -8.363636756188859],
              [-39.749546162628597, -8.36946655565951],
              [-39.751232798312024, -8.371737818193029],
              [-39.749527700926606, -8.372777847540515],
              [-39.747670190189872, -8.373910957161371],
              [-39.752155560272811, -8.38120339138006],
              [-39.760770539567375, -8.3819363211056],
              [-39.776905295218576, -8.383308615988062],
              [-39.782381635047884, -8.383774176038079],
              [-39.782463118086632, -8.38378115971167],
              [-39.815792742610725, -8.386613159432208],
              [-39.798589529828284, -8.358650149600377],
              [-39.801343869126455, -8.343788224815411],
              [-39.783423655778236, -8.345304647509732],
              [-39.780980375956062, -8.341528634975694],
              [-39.780964009837255, -8.341503341328499],
              [-39.78089996647951, -8.341515554343186],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0054000',
        uf: 'PE',
        nome_proje: 'PA BARRO VERMELHO',
        municipio: 'CABROBO',
        area_hecta: '465.7925',
        capacidade: 18.0,
        num_famili: 17.0,
        fase: 4.0,
        data_de_cr: '28/06/2001',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2000',
        area_calc_: 454.031,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.40950347819382, -8.422381462488195],
              [-39.40700712274645, -8.419934802261629],
              [-39.405874385661264, -8.418817084547834],
              [-39.405301136505344, -8.418583767635305],
              [-39.403234530170451, -8.421703678624882],
              [-39.402823839096925, -8.422309856019854],
              [-39.402578674324005, -8.422670557987482],
              [-39.401935115954821, -8.422177168846943],
              [-39.401498380989672, -8.421842491214447],
              [-39.399031623498239, -8.4199514840713],
              [-39.398973151322963, -8.420333431405632],
              [-39.398921076718125, -8.420664719165579],
              [-39.398858590238525, -8.421029846499682],
              [-39.398799176985783, -8.421380136590493],
              [-39.398731853172684, -8.421723108094803],
              [-39.398674086954117, -8.42208524569233],
              [-39.398616063677608, -8.422462579460261],
              [-39.398552330499619, -8.422852672728617],
              [-39.398483510501222, -8.423243042500603],
              [-39.398409237256729, -8.4236307042331],
              [-39.39834354050631, -8.42396643786282],
              [-39.398290520461778, -8.42435118355553],
              [-39.398251288034487, -8.424716468100261],
              [-39.398246889911981, -8.425123596544362],
              [-39.398284633919964, -8.425701003157355],
              [-39.398243830377801, -8.426484177007856],
              [-39.398059388383096, -8.426981125798724],
              [-39.398016629739047, -8.427096404849667],
              [-39.397873866622426, -8.427577482231015],
              [-39.397703401538998, -8.4281520244267],
              [-39.398017883030519, -8.428588678780409],
              [-39.399533390573211, -8.429932520378117],
              [-39.399824032944636, -8.430681618054345],
              [-39.400092239930885, -8.431255171273063],
              [-39.402055188537261, -8.437549063649575],
              [-39.403473794501231, -8.442547147500223],
              [-39.404664762377209, -8.44700256723546],
              [-39.405179002045905, -8.448974516288143],
              [-39.405163108298439, -8.449587885779124],
              [-39.404082393306417, -8.451139887469528],
              [-39.403786699016152, -8.452021101752631],
              [-39.40624758341729, -8.4504221742087],
              [-39.407008026038895, -8.449889264918152],
              [-39.407011037080657, -8.449804772304999],
              [-39.407355264900048, -8.44964594305249],
              [-39.409971579504088, -8.447812561809359],
              [-39.412216860374791, -8.446235623235259],
              [-39.416488257537218, -8.44486673383501],
              [-39.418597989355163, -8.444249143632687],
              [-39.417284630468444, -8.440161317474111],
              [-39.416263659381862, -8.436949017879792],
              [-39.414442061914997, -8.431208531661012],
              [-39.412672814219135, -8.425524238747908],
              [-39.410251045117569, -8.423122748930155],
              [-39.409711443525836, -8.422587656632134],
              [-39.40950347819382, -8.422381462488195],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0091000',
        uf: 'PE',
        nome_proje: 'PA ANTÔNIO BAIÃO',
        municipio: 'SERRA TALHADA',
        area_hecta: '89.1187',
        capacidade: 5.0,
        num_famili: 6.0,
        fase: 4.0,
        data_de_cr: '06/07/2004',
        forma_obte: 'Confisco',
        data_obten: '06/07/2004',
        area_calc_: 89.0739,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.459326737114019, -8.026213346167628],
              [-38.459395680336378, -8.026226813135565],
              [-38.454196150181232, -8.018197507456366],
              [-38.451060000950413, -8.019413084540632],
              [-38.446741796437642, -8.021155017522966],
              [-38.440536729968031, -8.019736857858506],
              [-38.438308444904322, -8.021480989557658],
              [-38.443654046898665, -8.022850355824714],
              [-38.44346198399947, -8.023149762763977],
              [-38.448119372776475, -8.024343209185004],
              [-38.448185123434584, -8.024036846782003],
              [-38.44823806299943, -8.024047189213857],
              [-38.448243570348168, -8.024032221604925],
              [-38.459326730401067, -8.026213335709857],
              [-38.459326737114019, -8.026213346167628],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0018000',
        uf: 'PE',
        nome_proje: 'PA JACARÉ',
        municipio: 'FLORESTA',
        area_hecta: '333.0000',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 5.0,
        data_de_cr: '12/08/1996',
        forma_obte: 'Adjudicação',
        data_obten: '18/12/1992',
        area_calc_: 373.6263,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899211033474593, -8.458811082711859],
              [-37.893415661925417, -8.457736159411963],
              [-37.892713669366316, -8.46229665820708],
              [-37.892031366773494, -8.466634099094723],
              [-37.891745395458585, -8.468388228143263],
              [-37.891672107627059, -8.468826913472048],
              [-37.890871042227658, -8.474181251458042],
              [-37.890849929399224, -8.474321550415977],
              [-37.890661389105638, -8.475639683198064],
              [-37.890510811710136, -8.47651613668825],
              [-37.890073490383244, -8.479281464475395],
              [-37.889249473377973, -8.484477007977324],
              [-37.888506696112572, -8.489241391279172],
              [-37.88771051524526, -8.494213713439036],
              [-37.88692340819788, -8.499179725499001],
              [-37.88618745080376, -8.503756817837422],
              [-37.885754628235404, -8.506664399798616],
              [-37.885794840829298, -8.506675276989689],
              [-37.886485821616702, -8.506794552339077],
              [-37.887176706922233, -8.506915634984361],
              [-37.88786621737799, -8.507041143949161],
              [-37.888555910384326, -8.507166561831085],
              [-37.889248395398269, -8.507300578056579],
              [-37.8899360965287, -8.507424088732975],
              [-37.890628608295181, -8.507549239837868],
              [-37.891314387289157, -8.507678168943105],
              [-37.892005904361383, -8.507802139185943],
              [-37.892058293547279, -8.507811965200757],
              [-37.892727082331184, -8.503354764639077],
              [-37.893533777526457, -8.498095229332778],
              [-37.894291016654201, -8.493028631896408],
              [-37.894988222673526, -8.488170227113651],
              [-37.895713580881456, -8.483305747664547],
              [-37.896462804129435, -8.478249964928857],
              [-37.896511582670264, -8.477836441248973],
              [-37.89696165607382, -8.474755147013292],
              [-37.897720443757514, -8.469545819445269],
              [-37.897738024986943, -8.469400626139432],
              [-37.898416194781674, -8.464740655921847],
              [-37.89903518160947, -8.460218070651552],
              [-37.899211033474593, -8.458811082711859],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0013000',
        uf: 'PE',
        nome_proje: 'PA LAGEDO',
        municipio: 'FLORESTA',
        area_hecta: '1101.0000',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 5.0,
        data_de_cr: '03/09/1991',
        forma_obte: 'Adjudicação',
        data_obten: '23/01/1990',
        area_calc_: 1108.7828,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.272550286370347, -8.653556412529909],
              [-38.278427580018089, -8.626800830263218],
              [-38.26719887856013, -8.624353614822844],
              [-38.274011036492418, -8.594058500607341],
              [-38.269950684936141, -8.593149893180469],
              [-38.265700690072016, -8.611828690129807],
              [-38.262465641610277, -8.626479073558004],
              [-38.256770532352718, -8.652142181401141],
              [-38.253162068852518, -8.669066828894767],
              [-38.253785335959179, -8.669701617550492],
              [-38.254280742954521, -8.669817450819881],
              [-38.254961343027226, -8.670233381153437],
              [-38.255283382450195, -8.670644903150292],
              [-38.256596147869395, -8.671613328456379],
              [-38.257745552068883, -8.672320949055894],
              [-38.260469492233973, -8.67359156802414],
              [-38.267018349926403, -8.678798584246472],
              [-38.268652865745118, -8.671445586592149],
              [-38.269840706382759, -8.665956623362504],
              [-38.27162728243524, -8.657826235720792],
              [-38.272550286370347, -8.653556412529909],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0048000',
        uf: 'PE',
        nome_proje: 'PA SERRA DO CAPIM',
        municipio: 'FLORESTA',
        area_hecta: '471.0000',
        capacidade: 10.0,
        num_famili: 8.0,
        fase: 5.0,
        data_de_cr: '29/05/2000',
        forma_obte: 'Adjudicação',
        data_obten: '05/02/1999',
        area_calc_: 417.067,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.238425751089913, -8.473439463449681],
              [-38.249818937970367, -8.422285207115589],
              [-38.24592125081417, -8.421404393789611],
              [-38.234521272262235, -8.472586883615536],
              [-38.238425751089913, -8.473439463449681],
            ],
          ],
          [
            [
              [-38.249701848176564, -8.482808551063007],
              [-38.250047120433081, -8.4813300565345],
              [-38.251075591525719, -8.476925216318238],
              [-38.252104125886731, -8.472520370545155],
              [-38.253132541843776, -8.468115518871763],
              [-38.254160930261449, -8.463710661477251],
              [-38.255189382000481, -8.459305798539146],
              [-38.256217806235433, -8.454900929888698],
              [-38.257246202985044, -8.450496055530714],
              [-38.258274481428906, -8.446091175298095],
              [-38.259302732426718, -8.441686289368066],
              [-38.259764781193887, -8.439707104001165],
              [-38.260330955997091, -8.437281397745545],
              [-38.261358244142748, -8.432876317832463],
              [-38.262387411593643, -8.428471688059094],
              [-38.262847426100542, -8.426491948599111],
              [-38.263409651353946, -8.42406541161772],
              [-38.264430592733731, -8.419658855824098],
              [-38.265451597758265, -8.415252294581411],
              [-38.263544404022461, -8.414814129730187],
              [-38.262523379440694, -8.419220684358635],
              [-38.261502418493748, -8.42362723353331],
              [-38.260940182632858, -8.426053676421292],
              [-38.260477616816686, -8.428032955875508],
              [-38.259447975595698, -8.432437578115616],
              [-38.258418670119255, -8.436842285790735],
              [-38.257851486154536, -8.439267534240118],
              [-38.257395420742313, -8.441248355989254],
              [-38.256377591935532, -8.445655697116948],
              [-38.255360643938594, -8.450063215234859],
              [-38.254343668700592, -8.454470727724784],
              [-38.253326666203009, -8.458878234582164],
              [-38.252309636427412, -8.463285735802277],
              [-38.25129267019917, -8.46769323155444],
              [-38.250275585813078, -8.47210072148626],
              [-38.249258474093502, -8.476508205766667],
              [-38.248241425868912, -8.48091568456611],
              [-38.248050400703264, -8.481743080492183],
              [-38.249701848176564, -8.482808551063007],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0020000',
        uf: 'PE',
        nome_proje: 'PA VITORINO',
        municipio: 'FLORESTA',
        area_hecta: '180.0000',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 6.0,
        data_de_cr: '12/08/1996',
        forma_obte: 'Adjudicação',
        data_obten: '29/02/1996',
        area_calc_: 162.188,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.597594628569553, -8.624781303371066],
              [-38.608449936678241, -8.622247416410458],
              [-38.613232325071792, -8.621130940362953],
              [-38.614133685166998, -8.620908884980516],
              [-38.610582151828176, -8.617162524986037],
              [-38.609965638169868, -8.617290702036165],
              [-38.604743296825347, -8.618337311008601],
              [-38.595256676518034, -8.620238119548594],
              [-38.597594628569553, -8.624781303371066],
            ],
          ],
          [
            [
              [-38.5862652933723, -8.627407530176979],
              [-38.590755870776952, -8.626375687567375],
              [-38.597291444105466, -8.624854250714524],
              [-38.59694889718584, -8.624077641453614],
              [-38.596585521479476, -8.623317200751211],
              [-38.596240722209174, -8.622524488293386],
              [-38.595857637240542, -8.62175443845239],
              [-38.595413262567995, -8.621016524239018],
              [-38.594933140463496, -8.62030778751156],
              [-38.589087948014367, -8.621464053311762],
              [-38.585932590513785, -8.622033489397445],
              [-38.584568845090722, -8.622368222199826],
              [-38.584843845060057, -8.623303419022665],
              [-38.585107361742473, -8.624185418190919],
              [-38.585349019498075, -8.625027414335788],
              [-38.585598292726559, -8.625804384444066],
              [-38.585963251864314, -8.62659441367342],
              [-38.586156189648044, -8.627025981567991],
              [-38.5862652933723, -8.627407530176979],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0203000',
        uf: 'PE',
        nome_proje: 'PA JATOBÁ DOS BASILIOS',
        municipio: 'IBIMIRIM',
        area_hecta: '459.4264',
        capacidade: 42.0,
        num_famili: 31.0,
        fase: 3.0,
        data_de_cr: '28/11/2006',
        forma_obte: 'Doação',
        data_obten: '28/11/2006',
        area_calc_: 459.5536,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.644399698238566, -8.442586951620831],
              [-37.644262922999239, -8.442586478466648],
              [-37.643983179648366, -8.442616437481172],
              [-37.643640109149366, -8.443017661511641],
              [-37.643390344802164, -8.443357354396657],
              [-37.643078009179511, -8.443851555209461],
              [-37.642734196735759, -8.444438517659719],
              [-37.642576946483658, -8.444995108773279],
              [-37.642419496420835, -8.445582716347259],
              [-37.64229395483072, -8.445953765421731],
              [-37.641888017347881, -8.446540511259467],
              [-37.641575893496359, -8.446972766693452],
              [-37.641512797111247, -8.447251160869234],
              [-37.641667343090297, -8.447468456170544],
              [-37.641915721879982, -8.447500244356734],
              [-37.642350664979844, -8.447501752271602],
              [-37.642600108721332, -8.447254930602135],
              [-37.642880197274579, -8.447101176384511],
              [-37.643253667362345, -8.446916728092118],
              [-37.643533214982135, -8.446917696297822],
              [-37.643688394005643, -8.446980087439631],
              [-37.643874218468063, -8.447135546151756],
              [-37.643842184519215, -8.447414048502385],
              [-37.643624001406025, -8.447629962018061],
              [-37.64331265253157, -8.447814625773816],
              [-37.643311787376092, -8.448062218665095],
              [-37.643497504053734, -8.448248604111887],
              [-37.643527267088245, -8.448620191486905],
              [-37.643899332920199, -8.448838149127138],
              [-37.644333196952424, -8.449149191519401],
              [-37.644425407170573, -8.449428124038439],
              [-37.644454198064466, -8.450078321403616],
              [-37.644670960152908, -8.450295740881826],
              [-37.645104195138664, -8.450761595908761],
              [-37.645320309661564, -8.451164755145351],
              [-37.645724196265476, -8.451166152481569],
              [-37.646066517624469, -8.450981594186544],
              [-37.646408929513676, -8.450797035853036],
              [-37.647218229970932, -8.450335477031475],
              [-37.64752968745249, -8.45011988366333],
              [-37.647778591064359, -8.450027872121256],
              [-37.647779453575446, -8.449780278795481],
              [-37.648090496379943, -8.449657464231072],
              [-37.64840033606972, -8.449906220646326],
              [-37.648586163990004, -8.450061677617688],
              [-37.648771776742336, -8.450278987664555],
              [-37.648988434077751, -8.450527331721386],
              [-37.649454012302606, -8.450652736205832],
              [-37.649826423953634, -8.450746891946613],
              [-37.650168511375547, -8.450655200200265],
              [-37.650510060415584, -8.450718321948445],
              [-37.650789181272962, -8.450843082218018],
              [-37.651037224084277, -8.450998752634078],
              [-37.651098809513719, -8.451153509244177],
              [-37.650911275009783, -8.451462404013489],
              [-37.65063118498837, -8.451616254378424],
              [-37.650320481027904, -8.451615183305753],
              [-37.649729902233965, -8.451706018041921],
              [-37.649077523961502, -8.451703767323966],
              [-37.648672774096646, -8.451949966751794],
              [-37.648175270695702, -8.452072137650269],
              [-37.647739674503207, -8.452256285151984],
              [-37.647490212819086, -8.452534036908697],
              [-37.647613271327458, -8.452875019973053],
              [-37.647550069689608, -8.453184342123096],
              [-37.647487299444734, -8.45336986761675],
              [-37.647237638112834, -8.453678545287827],
              [-37.647112529027574, -8.453925709040892],
              [-37.646707559628069, -8.454233849352349],
              [-37.646458311611745, -8.454449747038213],
              [-37.646177263129211, -8.454851186361639],
              [-37.646020875188292, -8.455160185607173],
              [-37.646144365177321, -8.45537728204469],
              [-37.646517086832525, -8.455409498319245],
              [-37.646765561998023, -8.455441375022506],
              [-37.64710734749292, -8.455411539507319],
              [-37.647449547300567, -8.455288924361794],
              [-37.648103117650379, -8.454950715355777],
              [-37.648320639335005, -8.454951466752389],
              [-37.648600193328996, -8.454952432252936],
              [-37.648568376970211, -8.455168991766008],
              [-37.648380929018316, -8.455477884952765],
              [-37.648317511366905, -8.455849150757277],
              [-37.648627265038058, -8.456097816974971],
              [-37.648781602708112, -8.456376963677931],
              [-37.648470122929552, -8.456623574593726],
              [-37.648065259346168, -8.456900699016103],
              [-37.647939825300497, -8.457240823418132],
              [-37.647782897581976, -8.45770463699032],
              [-37.64781298820234, -8.457983264130748],
              [-37.64802923343575, -8.45832456939274],
              [-37.648495036021835, -8.458388032856114],
              [-37.648743422595359, -8.458419908310125],
              [-37.649271241198456, -8.458514602472297],
              [-37.649581950548914, -8.458515675245858],
              [-37.649986168843057, -8.458424199240154],
              [-37.650296787388946, -8.458425271121166],
              [-37.650669620522002, -8.458426557366375],
              [-37.650668651027551, -8.458705167889535],
              [-37.65076129830549, -8.458860212670146],
              [-37.651134239511563, -8.458830571950847],
              [-37.651414765600592, -8.458552925273278],
              [-37.651632827925887, -8.458398861317075],
              [-37.651881322912374, -8.45839971779322],
              [-37.652005230575604, -8.458524033539137],
              [-37.652284464792288, -8.458617867048144],
              [-37.652517197133839, -8.45871154018953],
              [-37.653168298115148, -8.459085177669925],
              [-37.653509119419446, -8.459334038261574],
              [-37.654067127054489, -8.459707444466979],
              [-37.654377102502885, -8.459894253890523],
              [-37.654625367597056, -8.459987888976956],
              [-37.654873971208367, -8.459957817078291],
              [-37.655092049797204, -8.459772824163238],
              [-37.655155338341473, -8.459463500432573],
              [-37.655342246948472, -8.459309417576149],
              [-37.655653065257376, -8.459279468554694],
              [-37.65580912636095, -8.459063334946137],
              [-37.655809984680843, -8.458815740912662],
              [-37.655593211677854, -8.458598235540892],
              [-37.65559439206308, -8.458257771158999],
              [-37.655564296078502, -8.457979053311927],
              [-37.655316337485303, -8.45782347557668],
              [-37.655037441565028, -8.457605756477285],
              [-37.655038515045788, -8.45729621879592],
              [-37.655411991777058, -8.457111759627207],
              [-37.655629424506536, -8.45711250683935],
              [-37.655940347904732, -8.457051721084632],
              [-37.655941206247142, -8.456804036583467],
              [-37.655724417428097, -8.456617548817503],
              [-37.655725168364761, -8.456400881411634],
              [-37.655912182601568, -8.456215780911705],
              [-37.656098445652205, -8.456247438164871],
              [-37.656440215823849, -8.456248611894587],
              [-37.656937402277727, -8.456219301369128],
              [-37.657186217923829, -8.456127283822429],
              [-37.657218549118923, -8.455786926080293],
              [-37.656939867356719, -8.45550735504372],
              [-37.656941153508427, -8.455135873322934],
              [-37.656943189691141, -8.454547724059799],
              [-37.657037339026054, -8.454269432532826],
              [-37.657348260030034, -8.454208645259516],
              [-37.65750313970296, -8.45433297517739],
              [-37.657688759784811, -8.454550282051891],
              [-37.657968298098766, -8.454582258237984],
              [-37.658186586294491, -8.454335319046868],
              [-37.658373475008858, -8.45421216112652],
              [-37.658559844753015, -8.45421279997349],
              [-37.658745893446977, -8.454306309236079],
              [-37.658807358514927, -8.454523190153445],
              [-37.658682165036929, -8.454770358397747],
              [-37.658463983774141, -8.454986371184072],
              [-37.658152419681095, -8.455232900226454],
              [-37.658182089425999, -8.455635415328848],
              [-37.658461093495774, -8.455822115147077],
              [-37.658615010658508, -8.456225056242516],
              [-37.658924756063989, -8.456504732812663],
              [-37.658830393329779, -8.456844878267381],
              [-37.658766786895285, -8.457247164077998],
              [-37.658859207122219, -8.457495078208321],
              [-37.659169826061088, -8.457496142928733],
              [-37.659698076057282, -8.457467026000193],
              [-37.659698611090477, -8.457312211647604],
              [-37.659948266556384, -8.457003524891832],
              [-37.66038407873841, -8.456757419920539],
              [-37.660757890114951, -8.456449067140127],
              [-37.660975627310059, -8.456387958201278],
              [-37.661317307466973, -8.456389127380191],
              [-37.661565359064618, -8.456544701865113],
              [-37.66190682574004, -8.456607814436154],
              [-37.662155945452199, -8.456453850003234],
              [-37.662342743506812, -8.456330689851564],
              [-37.662622924416411, -8.456176921683687],
              [-37.66290301461342, -8.456023062539909],
              [-37.66308947639817, -8.456023699675969],
              [-37.663119898047867, -8.456209547129422],
              [-37.662870458945804, -8.456456292484436],
              [-37.662713975459297, -8.45679631752774],
              [-37.662588660531434, -8.457105431479938],
              [-37.66230858555496, -8.457228272777337],
              [-37.662028601250704, -8.457351114156399],
              [-37.661686295080294, -8.457504759634668],
              [-37.661715968339017, -8.457907275022489],
              [-37.66140451002228, -8.458122879856662],
              [-37.661031766355983, -8.45812160419757],
              [-37.660782645134155, -8.45827556766527],
              [-37.660439413633647, -8.45872301586442],
              [-37.660375685337236, -8.459187155832028],
              [-37.660436311989713, -8.459620794614045],
              [-37.660808950183892, -8.459652997774381],
              [-37.661336989809811, -8.45968573250582],
              [-37.66192726058744, -8.459687752356345],
              [-37.662206910388285, -8.459688708980408],
              [-37.662143611019907, -8.460029052186675],
              [-37.661832792102615, -8.460058915922358],
              [-37.661397297206477, -8.460212151456084],
              [-37.661023268510327, -8.460582357725606],
              [-37.660990602445921, -8.461046604167464],
              [-37.661269520843277, -8.461233302438593],
              [-37.66157969968782, -8.461389090034825],
              [-37.662014661891483, -8.461390578541263],
              [-37.662386981349236, -8.461515741517506],
              [-37.662728223266036, -8.461671634560362],
              [-37.663194141303457, -8.461704245197877],
              [-37.66372143745609, -8.461953645229134],
              [-37.664000464658351, -8.462109415103381],
              [-37.663968442278922, -8.462387830535656],
              [-37.663688774215593, -8.462417892349517],
              [-37.663005205154199, -8.462446482605477],
              [-37.662694063790198, -8.46256921734706],
              [-37.662289734892191, -8.462691723419265],
              [-37.661699459453416, -8.462689703404148],
              [-37.661387996868307, -8.462905307574269],
              [-37.660952482157043, -8.463089559511412],
              [-37.660796009874609, -8.463398565552771],
              [-37.660515378493592, -8.463707146097214],
              [-37.660358247356641, -8.464232819906869],
              [-37.660575363509473, -8.464326435519093],
              [-37.660916820497135, -8.464420476987152],
              [-37.661350411800797, -8.464793448667313],
              [-37.661847182083157, -8.464888021276707],
              [-37.662280758946942, -8.465291919166722],
              [-37.66274668148143, -8.465324440573731],
              [-37.662777209732987, -8.465479361330603],
              [-37.662930813397445, -8.465975172437739],
              [-37.663613091431856, -8.46634899286404],
              [-37.663953606552234, -8.466690626514803],
              [-37.664418143804774, -8.467125555592876],
              [-37.664852685945803, -8.46725092979541],
              [-37.6649155585134, -8.467034383515212],
              [-37.665289681784877, -8.466664264768506],
              [-37.665539356566029, -8.466324557331497],
              [-37.665943049016491, -8.466387880219887],
              [-37.666346422259267, -8.466543983017852],
              [-37.666874579252799, -8.46654578501237],
              [-37.667216998711957, -8.466361299629455],
              [-37.667683578158574, -8.466177146741561],
              [-37.667963233300085, -8.46617809997565],
              [-37.668024294457872, -8.466487850900112],
              [-37.668271610090699, -8.466860181309261],
              [-37.66848809208448, -8.467139444236659],
              [-37.668953911917377, -8.467202975893228],
              [-37.669296118821698, -8.467080342240907],
              [-37.669606746768842, -8.467081399864149],
              [-37.669947997129938, -8.467237287769457],
              [-37.670132779798017, -8.46770227644701],
              [-37.670194267821373, -8.467888229624622],
              [-37.669913745264125, -8.46816589069612],
              [-37.669601749561103, -8.468536316274514],
              [-37.669661855412869, -8.469124589286226],
              [-37.669629409756688, -8.469526984136106],
              [-37.669845665861715, -8.469899117817475],
              [-37.670217465432955, -8.470178999427576],
              [-37.670557868271729, -8.470582573048825],
              [-37.670370533007663, -8.470860551302385],
              [-37.670245232889116, -8.471138740615327],
              [-37.670151193081722, -8.47138601868898],
              [-37.669679032833258, -8.471368675976136],
              [-37.669336183299713, -8.471677141449698],
              [-37.66902310406121, -8.472388032305942],
              [-37.668710784505549, -8.472851236803361],
              [-37.668708761727025, -8.473439388353727],
              [-37.668737482714306, -8.47412051658273],
              [-37.668984483445314, -8.474585718056272],
              [-37.669417987446515, -8.47498961035469],
              [-37.669821689973439, -8.475052930702297],
              [-37.670131776935534, -8.475239730881228],
              [-37.670099968891115, -8.475456293544699],
              [-37.669788073000973, -8.475795701030403],
              [-37.669786157465239, -8.476352925954048],
              [-37.669877645510105, -8.476848524400021],
              [-37.670217627236198, -8.477375896666185],
              [-37.670526758704476, -8.477841309348374],
              [-37.670836544777778, -8.478089962643612],
              [-37.671115996102699, -8.478152859000403],
              [-37.671426633163634, -8.478153916547841],
              [-37.671644701541112, -8.477999842047794],
              [-37.671645551677763, -8.477752246647695],
              [-37.671894592575455, -8.477598277364292],
              [-37.672175000062339, -8.477382560224319],
              [-37.672269040706041, -8.47713528176229],
              [-37.67227095284607, -8.476578056297218],
              [-37.67236509957884, -8.476299760503959],
              [-37.672583287858863, -8.476083831273083],
              [-37.672738696475797, -8.476084359674802],
              [-37.67276859192458, -8.476425022181861],
              [-37.672798275212571, -8.476827538356808],
              [-37.672920618742864, -8.477385095980859],
              [-37.673292730594042, -8.477603032211718],
              [-37.674037713442921, -8.477791307846184],
              [-37.674689490897947, -8.478010192901552],
              [-37.675278096498111, -8.478507569283654],
              [-37.675368849889914, -8.479219836447982],
              [-37.675585025148592, -8.47959196814657],
              [-37.675738633422633, -8.480118794869526],
              [-37.676203426932034, -8.48046084281547],
              [-37.676326607990752, -8.480801821978771],
              [-37.676481186400586, -8.481019017848229],
              [-37.676791599506188, -8.481112942849101],
              [-37.676789375360343, -8.481762949837423],
              [-37.676911724988436, -8.482320597626668],
              [-37.67715907278123, -8.482661997800236],
              [-37.677437364409201, -8.483065357275812],
              [-37.677591308898506, -8.483468295076412],
              [-37.677589720841908, -8.483932560042176],
              [-37.677806111608888, -8.484242927814357],
              [-37.678272272699417, -8.484213490169562],
              [-37.678676199219673, -8.484214858807553],
              [-37.678830674154895, -8.484463071447532],
              [-37.679016727796729, -8.484587501124329],
              [-37.679547366848347, -8.484774771236605],
              [-37.679608332233499, -8.485115539276691],
              [-37.679575998835716, -8.485486918683161],
              [-37.679792286716335, -8.485828122184371],
              [-37.680071428730038, -8.485983884344186],
              [-37.680350465391768, -8.48617057325125],
              [-37.680535584229574, -8.486542598270853],
              [-37.680721231268024, -8.486759988663012],
              [-37.681124527270477, -8.486947007136315],
              [-37.681185177184418, -8.487380646354945],
              [-37.681401980699157, -8.487598051489446],
              [-37.681805609126258, -8.48766136140919],
              [-37.681866576928947, -8.488002038991546],
              [-37.681772327016347, -8.488311264456568],
              [-37.681616053090522, -8.488589352829862],
              [-37.681522119694989, -8.488805706970313],
              [-37.68111850492955, -8.488711469358288],
              [-37.680776491156095, -8.48877225705167],
              [-37.680589468791901, -8.488957368568574],
              [-37.680681500240858, -8.489298151330637],
              [-37.680929157581183, -8.48957760623383],
              [-37.681021506179938, -8.489825608224358],
              [-37.680833969749848, -8.490134517566162],
              [-37.6805232162422, -8.490164392942063],
              [-37.679839700175037, -8.490162078380575],
              [-37.679435132291758, -8.49034645223076],
              [-37.679123335151587, -8.490654939453115],
              [-37.678935570044175, -8.491056718977394],
              [-37.679212930592939, -8.49170776433485],
              [-37.679833352203069, -8.492019410518306],
              [-37.68045326111141, -8.492454853604887],
              [-37.681011358356784, -8.492797304858028],
              [-37.681290097939083, -8.493045847372391],
              [-37.681631904116976, -8.493047004074663],
              [-37.681943173397819, -8.492893330345586],
              [-37.682253929507603, -8.492863363730077],
              [-37.682626800688311, -8.4928646245952],
              [-37.682875637700597, -8.492772593534905],
              [-37.683062963024625, -8.492525627502296],
              [-37.683063807628585, -8.492277940710341],
              [-37.68321976586617, -8.492092723127113],
              [-37.683375090754623, -8.492093247970718],
              [-37.68362327916595, -8.492217976539015],
              [-37.684181485292747, -8.492529406249728],
              [-37.68418021956829, -8.492900891384458],
              [-37.684178426565815, -8.493427101693063],
              [-37.684177160702646, -8.493798586810215],
              [-37.684238552646129, -8.494015466154787],
              [-37.684547818612565, -8.494449854578292],
              [-37.684857205097941, -8.494822388633391],
              [-37.685136039367855, -8.49507101970697],
              [-37.685633068809189, -8.495103624400556],
              [-37.685849893315797, -8.495290100744521],
              [-37.686316173975555, -8.49522972846084],
              [-37.686503091992513, -8.495075631703388],
              [-37.686782664966579, -8.495107501636625],
              [-37.687092791456081, -8.495263274139106],
              [-37.687372574978944, -8.495233289702581],
              [-37.687621833760765, -8.495017457289482],
              [-37.688057259048399, -8.494895124175677],
              [-37.68846214476703, -8.494617870076466],
              [-37.688370210987209, -8.494246070572798],
              [-37.688247212149875, -8.493874166472679],
              [-37.687937205728709, -8.493656450248604],
              [-37.687906981872175, -8.493408748798068],
              [-37.688093898684997, -8.493254560602619],
              [-37.688373575715204, -8.493255502213611],
              [-37.688684437429671, -8.493194603429934],
              [-37.688964429780221, -8.493102673149941],
              [-37.689027715840872, -8.492762413789247],
              [-37.689029607381578, -8.492205184992661],
              [-37.688564486467016, -8.491925092788245],
              [-37.688317543229388, -8.491459899117851],
              [-37.68800848484031, -8.490963568908315],
              [-37.687791661215982, -8.490777093936664],
              [-37.687233243494902, -8.49052752329985],
              [-37.686954830959607, -8.490155186005449],
              [-37.686427689959345, -8.489843865106563],
              [-37.686273102892258, -8.48962658145282],
              [-37.686057137318997, -8.489161581757633],
              [-37.685781388194705, -8.488914770836562],
              [-37.685782286980505, -8.488650535081069],
              [-37.685829427550331, -8.48776980757985],
              [-37.685521251360832, -8.487416359849195],
              [-37.685212684918255, -8.487151080650447],
              [-37.684771204283251, -8.486973432510585],
              [-37.684727418694528, -8.486841165457415],
              [-37.68415437384683, -8.486354704027628],
              [-37.683845750106634, -8.486133463575904],
              [-37.683271206859366, -8.48608748413765],
              [-37.683183486851021, -8.485866989121751],
              [-37.683036847695462, -8.485477370548072],
              [-37.682553887388842, -8.485264945276937],
              [-37.681889627883976, -8.485051906753693],
              [-37.68179981137672, -8.484780582443241],
              [-37.681921119124524, -8.484660448352408],
              [-37.682284926530087, -8.484360543523884],
              [-37.682197470980924, -8.48339652814753],
              [-37.682017198482406, -8.483094784867317],
              [-37.68189825308751, -8.482522137570445],
              [-37.681628678761463, -8.481798414188352],
              [-37.68132906403298, -8.481014362386837],
              [-37.681057642623372, -8.480832764479858],
              [-37.680483834900564, -8.480680257332594],
              [-37.680122291498101, -8.480317583246979],
              [-37.679579554023043, -8.479924272864698],
              [-37.679156889995149, -8.479772275454305],
              [-37.678976530510219, -8.479470531028433],
              [-37.678736181032292, -8.479048039473689],
              [-37.678313929933118, -8.478775589203771],
              [-37.678163509173686, -8.478564286086403],
              [-37.678165258663192, -8.478052274093042],
              [-37.678014735315017, -8.477871083998433],
              [-37.677773358247983, -8.47774981285546],
              [-37.677683443069697, -8.477508510799431],
              [-37.677655037265403, -8.476996487153141],
              [-37.677595574311447, -8.476695061507757],
              [-37.677082164043775, -8.476572868418868],
              [-37.676750255539993, -8.476391063253015],
              [-37.67669108981233, -8.476029502283245],
              [-37.676662800568486, -8.475457161969546],
              [-37.676482341713871, -8.475185529950576],
              [-37.676392621553525, -8.474914115072563],
              [-37.67606050874118, -8.474792535599422],
              [-37.676061229825329, -8.47458174450804],
              [-37.676303737273088, -8.474371773164403],
              [-37.676515883358235, -8.474221925457865],
              [-37.676727423691645, -8.474222642412036],
              [-37.676908500047197, -8.474313596145072],
              [-37.677331671729064, -8.474315029836639],
              [-37.67787583960525, -8.474286759401236],
              [-37.678087059518866, -8.474407928085069],
              [-37.678237273282505, -8.47467945710072],
              [-37.678449430963809, -8.474499494906555],
              [-37.678782058597456, -8.474470507192844],
              [-37.679265529283541, -8.47450225624692],
              [-37.679264193365782, -8.474893725925025],
              [-37.679172517894116, -8.475194640216332],
              [-37.679021069832537, -8.475284468019112],
              [-37.678959332088979, -8.475675733262102],
              [-37.679109250151875, -8.4760075783131],
              [-37.679471097663686, -8.476279823066632],
              [-37.679742823532941, -8.476371082349356],
              [-37.6805891724416, -8.476373943821045],
              [-37.680771072238969, -8.476223991502398],
              [-37.681165027089001, -8.475924097985816],
              [-37.681287357665504, -8.475502923320846],
              [-37.681681823967949, -8.475052554123616],
              [-37.682106535120063, -8.474602376897694],
              [-37.68253083557066, -8.47427256098937],
              [-37.682531860587886, -8.473971430060997],
              [-37.682290996806152, -8.473699505962172],
              [-37.68168684989687, -8.473577013094197],
              [-37.681505863221972, -8.473486061761744],
              [-37.681203790081717, -8.473424814873715],
              [-37.680266401457082, -8.47351198827689],
              [-37.679903927512278, -8.473450536275427],
              [-37.679512851666146, -8.472907082329041],
              [-37.679453489770289, -8.472575634151568],
              [-37.679425195870827, -8.472003293351273],
              [-37.679397300579879, -8.471340704105486],
              [-37.67930738520181, -8.471099402419561],
              [-37.678098474259023, -8.471065199687528],
              [-37.677644442852227, -8.471244433637088],
              [-37.677009233310692, -8.471362736175012],
              [-37.67640436014532, -8.471481140422123],
              [-37.675920082260625, -8.471660179240709],
              [-37.675647433775232, -8.471839935035176],
              [-37.675345363508484, -8.471778683948648],
              [-37.675164700902528, -8.471567277690594],
              [-37.674923740867627, -8.471325553471519],
              [-37.675014902550977, -8.471175295945045],
              [-37.675166452892064, -8.471055266136789],
              [-37.675197831981457, -8.47072412562834],
              [-37.675107494797359, -8.470633479168525],
              [-37.674957181502172, -8.470392062478837],
              [-37.67465573123021, -8.470150042598764],
              [-37.674384835920876, -8.469817876603088],
              [-37.674295323828211, -8.46948632601859],
              [-37.67438658857423, -8.469305865165662],
              [-37.674567971276971, -8.46930648055919],
              [-37.674809651307022, -8.469337413753633],
              [-37.675051949541157, -8.469187668750124],
              [-37.675384881341259, -8.469068344220737],
              [-37.675415744873149, -8.468887768679604],
              [-37.675840557954182, -8.468407304332633],
              [-37.676114438817933, -8.467866191699692],
              [-37.67617658886148, -8.467354384472383],
              [-37.675935321525124, -8.467203000202479],
              [-37.675906620327012, -8.466751111947246],
              [-37.675816798389647, -8.466509900637691],
              [-37.675515761613319, -8.466147520108326],
              [-37.675516585193705, -8.465906615950354],
              [-37.675578311443758, -8.46554537425598],
              [-37.675820503937913, -8.465425741360884],
              [-37.676001576220862, -8.465516694888988],
              [-37.676212288646639, -8.465758315621546],
              [-37.67648390352381, -8.465879688999715],
              [-37.677057893061622, -8.46597206288302],
              [-37.677480850065919, -8.466033721100105],
              [-37.677903498984136, -8.466185718026008],
              [-37.678234886465731, -8.466518086304912],
              [-37.678567404419745, -8.466519210768599],
              [-37.678779659612992, -8.466309134552807],
              [-37.678781303031386, -8.465827235170298],
              [-37.678843744749344, -8.465255291488431],
              [-37.678905171611795, -8.464954274582484],
              [-37.678513487575565, -8.464591589719719],
              [-37.678242797118045, -8.464199200284714],
              [-37.678001839047305, -8.463957387855618],
              [-37.677994604854881, -8.463415503034764],
              [-37.677783904125747, -8.463143769901034],
              [-37.677512187730926, -8.46305251069098],
              [-37.677119273453862, -8.463051181424094],
              [-37.676816489745697, -8.463200723748598],
              [-37.676544670813385, -8.46313957693186],
              [-37.676363907921598, -8.462958284691737],
              [-37.676364833809522, -8.462687176946734],
              [-37.676244869891789, -8.462415750363997],
              [-37.676275937704446, -8.462174948481996],
              [-37.676276966404366, -8.461873727700409],
              [-37.676096101306456, -8.461722548468696],
              [-37.675703085723065, -8.461751331015074],
              [-37.675399890797678, -8.462021414852174],
              [-37.675037222928978, -8.462020186111014],
              [-37.674583820050444, -8.46201864948099],
              [-37.674342247834254, -8.461957513400693],
              [-37.674525183810914, -8.461476319749044],
              [-37.674767773458143, -8.461266348394965],
              [-37.674828790517935, -8.461085784535845],
              [-37.674708210388452, -8.460995035808592],
              [-37.674285465627726, -8.460873149464824],
              [-37.673711381416538, -8.460810976186375],
              [-37.673377838504351, -8.461110978360651],
              [-37.673135854231887, -8.461170474500401],
              [-37.672803329327103, -8.461199459426279],
              [-37.672561666918305, -8.461138412486269],
              [-37.67259242772252, -8.460987859764629],
              [-37.67283472087513, -8.46083811540087],
              [-37.672956125089044, -8.460687960660035],
              [-37.673199230165594, -8.460327425326144],
              [-37.67341117509698, -8.4602076004666],
              [-37.673864987654575, -8.460088686095141],
              [-37.673986494230178, -8.459908417920534],
              [-37.673896468490895, -8.459727432535363],
              [-37.673685669573516, -8.459485810901484],
              [-37.673262823838279, -8.459394036748737],
              [-37.673233494505254, -8.459152940016933],
              [-37.673445541442668, -8.459003092455342],
              [-37.673566532719384, -8.458973389413279],
              [-37.673598219587021, -8.458551909870893],
              [-37.673447499095126, -8.458430854918126],
              [-37.673025994136054, -8.457947611671123],
              [-37.672574555082328, -8.457373836079514],
              [-37.672393281193443, -8.457343107627473],
              [-37.672212407458581, -8.457222040425776],
              [-37.671565451513558, -8.456958138514587],
              [-37.67027117180028, -8.455902669265219],
              [-37.670752305996558, -8.461298387023206],
              [-37.671955293879968, -8.467052673441904],
              [-37.665703890821646, -8.461220153442378],
              [-37.664746796991466, -8.45482645624506],
              [-37.656692165289613, -8.451500187825854],
              [-37.653125658394544, -8.456900789988813],
              [-37.65055604727457, -8.455279935775682],
              [-37.653881051875565, -8.450224604645525],
              [-37.648021648532897, -8.447650412042684],
              [-37.646287227109546, -8.448530992642567],
              [-37.645833945871004, -8.447250661094436],
              [-37.646678937315876, -8.444989224479862],
              [-37.644544044668329, -8.442786215037044],
              [-37.644399698238566, -8.442586951620831],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0188000',
        uf: 'PE',
        nome_proje: 'PA BANDEIRA II',
        municipio: 'IBIMIRIM',
        area_hecta: '208.0424',
        capacidade: 19.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '04/10/2006',
        forma_obte: 'Doação',
        data_obten: '04/10/2006',
        area_calc_: 208.105,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.727758998417016, -8.470094365041836],
              [-37.72774382166326, -8.469958576706537],
              [-37.727953374949443, -8.469707585928061],
              [-37.727770854971453, -8.468918331315631],
              [-37.726299784131115, -8.46808427448939],
              [-37.726616402701033, -8.467702144814758],
              [-37.726991027388351, -8.467330241736185],
              [-37.726066229340326, -8.465994437138528],
              [-37.726021555154581, -8.465713951459447],
              [-37.725968194249546, -8.465257727548924],
              [-37.726487191576723, -8.465009735271039],
              [-37.726220319360984, -8.464848619689649],
              [-37.725976931543457, -8.464313100415019],
              [-37.726290105743971, -8.462794677481181],
              [-37.724891342964249, -8.464588363522749],
              [-37.725333679150353, -8.46306794885062],
              [-37.722399329696181, -8.463988981296895],
              [-37.7222133118361, -8.464705138597028],
              [-37.721952966275296, -8.466421141695012],
              [-37.72221116128059, -8.466734248146436],
              [-37.722959912780958, -8.467272506156421],
              [-37.722818333764295, -8.467650682931723],
              [-37.722996619649727, -8.467985322025664],
              [-37.72353582102555, -8.468498568229057],
              [-37.723622373718605, -8.46877674908044],
              [-37.724643208038458, -8.46963385312584],
              [-37.725080949280674, -8.47075609889788],
              [-37.725079863489547, -8.472823556775468],
              [-37.725114122813707, -8.473094513102852],
              [-37.724998345024964, -8.473277802944347],
              [-37.724968064477856, -8.473398340647458],
              [-37.724220807708946, -8.47455189468676],
              [-37.723806994283656, -8.474932889569537],
              [-37.722329949250721, -8.472630992681303],
              [-37.722115895163697, -8.472400323469357],
              [-37.721060178992055, -8.47181909719116],
              [-37.719571874879136, -8.470210951380436],
              [-37.719417222118352, -8.470146509000545],
              [-37.717882038231934, -8.469945055323251],
              [-37.716906638895985, -8.46958048686858],
              [-37.716122629526161, -8.469651613215209],
              [-37.715750178228248, -8.469720745083364],
              [-37.714780713415706, -8.470825799644679],
              [-37.714556971692474, -8.47373344250663],
              [-37.714460325634612, -8.473898072047339],
              [-37.712986741466949, -8.473907506237126],
              [-37.713265103260618, -8.475857862022568],
              [-37.712819955382734, -8.476016458844873],
              [-37.711942843288952, -8.475074160797918],
              [-37.7098694804465, -8.474243934648358],
              [-37.70859845273236, -8.475314332306672],
              [-37.708769461931539, -8.479513458113995],
              [-37.705380004491367, -8.479692586523255],
              [-37.705052443588414, -8.47915596430051],
              [-37.703979542165989, -8.478532756348674],
              [-37.70238993779887, -8.479600724341266],
              [-37.702216819377909, -8.47976093568151],
              [-37.70205593308107, -8.479875971910062],
              [-37.702065799667224, -8.479967340441201],
              [-37.702108073997699, -8.48036221411077],
              [-37.702124192050817, -8.480512474276555],
              [-37.702123276151788, -8.480514732014989],
              [-37.702439414671737, -8.482099327796442],
              [-37.703215289149988, -8.482890107952151],
              [-37.704108575150379, -8.483584063291461],
              [-37.704125413629846, -8.483573810048583],
              [-37.704896551315635, -8.48258090147659],
              [-37.704585730270736, -8.48230712811662],
              [-37.705290343744402, -8.481947379519232],
              [-37.706935933057672, -8.481792502104391],
              [-37.708792090208554, -8.482094722893615],
              [-37.709176090445851, -8.482569584117458],
              [-37.710499776566586, -8.482685464161484],
              [-37.711435193225903, -8.48262561469863],
              [-37.711849752759868, -8.482599130878249],
              [-37.712151928021711, -8.482579781440499],
              [-37.711828031542069, -8.482166524219833],
              [-37.711556414782137, -8.481819997226312],
              [-37.711160161225195, -8.481314350753928],
              [-37.711290852482875, -8.480937863089499],
              [-37.711449979680211, -8.480777781913224],
              [-37.712160429339256, -8.480545276262371],
              [-37.712398567250098, -8.479788877112771],
              [-37.712647595966999, -8.479171326339943],
              [-37.712387150049452, -8.47866178846316],
              [-37.71238054269471, -8.478600182636189],
              [-37.712334266441438, -8.478558431348247],
              [-37.712276781175817, -8.478445835007726],
              [-37.712385153655433, -8.477897090912089],
              [-37.712768105657943, -8.478250676264633],
              [-37.713099647359449, -8.478549651886446],
              [-37.713156448409102, -8.478567654239065],
              [-37.71328241053574, -8.479229668509245],
              [-37.713402657476379, -8.479862273605701],
              [-37.713657644361518, -8.480074724644391],
              [-37.713732283849495, -8.480136826038734],
              [-37.714335241509083, -8.481199126187073],
              [-37.715734281267018, -8.481134734311524],
              [-37.715596390655563, -8.479687008911309],
              [-37.717015264964054, -8.479501588558575],
              [-37.716968833281399, -8.477972865250816],
              [-37.716237903641641, -8.476906259271274],
              [-37.715788787047586, -8.476262625185051],
              [-37.71581667677841, -8.476288670882369],
              [-37.717393646088254, -8.477044078609872],
              [-37.717515626676061, -8.477018706293393],
              [-37.717767538962221, -8.476953247249238],
              [-37.718173106934699, -8.475608680302948],
              [-37.717577706436344, -8.474676451503116],
              [-37.717281116460875, -8.473998955877729],
              [-37.717181862234519, -8.473772369097498],
              [-37.717028068607789, -8.473421078343611],
              [-37.717424946312512, -8.473766385187517],
              [-37.717771525737973, -8.473831006612937],
              [-37.718215602540134, -8.473830113316676],
              [-37.71831821745991, -8.473782611618196],
              [-37.7183735548142, -8.473666497711724],
              [-37.718337198003177, -8.47323492738844],
              [-37.718743748892784, -8.473813941064916],
              [-37.718735806589557, -8.473963218230363],
              [-37.71870220352271, -8.474016734142607],
              [-37.718469448568477, -8.474387012765474],
              [-37.718215600185481, -8.474790772194499],
              [-37.718676155127255, -8.475025779257802],
              [-37.718679977195521, -8.475023621433213],
              [-37.718707114269669, -8.475030131160906],
              [-37.719125462048623, -8.475668054693031],
              [-37.719136012154308, -8.475718821659234],
              [-37.719223089799982, -8.476139525722925],
              [-37.719336275062822, -8.476685744627014],
              [-37.719401607051438, -8.476733435759574],
              [-37.719461512243811, -8.47666436135877],
              [-37.720433768857703, -8.476090140920098],
              [-37.72091921359435, -8.475803434333406],
              [-37.721293102121813, -8.475960835322754],
              [-37.721465624276092, -8.476033474733697],
              [-37.720954540584962, -8.476602336470222],
              [-37.7205145915106, -8.478183003275504],
              [-37.722110446033248, -8.47883662970106],
              [-37.72265329255162, -8.478854684599],
              [-37.723063504674087, -8.478548286503695],
              [-37.723349335451751, -8.477976062733344],
              [-37.724130465943226, -8.478535407760081],
              [-37.724802149509159, -8.478845524563512],
              [-37.725420579387176, -8.478695166536166],
              [-37.72558063986812, -8.478746240842359],
              [-37.725712136049729, -8.477514260007414],
              [-37.726334990798499, -8.477619385678224],
              [-37.726941597885286, -8.477279530699697],
              [-37.726349741764636, -8.476697838857955],
              [-37.726128463937684, -8.476480350758075],
              [-37.726345090944129, -8.476344685777452],
              [-37.726913872559955, -8.475988519748576],
              [-37.726997196384055, -8.475868697410169],
              [-37.727507913426905, -8.475270525053244],
              [-37.727812920921124, -8.474913135827155],
              [-37.728015711270338, -8.474675688267606],
              [-37.728059847922026, -8.47437396900218],
              [-37.72850141876738, -8.472979855483199],
              [-37.729274321615598, -8.472503803124244],
              [-37.72783005907732, -8.471494850462198],
              [-37.728566143157074, -8.471055485211428],
              [-37.727758998417016, -8.470094365041836],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0273000',
        uf: 'PE',
        nome_proje: 'PA GINDAÍ',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '472.8280',
        capacidade: 47.0,
        num_famili: 46.0,
        fase: 4.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 473.0542,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.260763878876837, -8.849949097082408],
              [-35.259200118570426, -8.850822877457967],
              [-35.258148134827202, -8.851416346581228],
              [-35.256895444631851, -8.852124158590318],
              [-35.256255770566312, -8.852494480839841],
              [-35.255933898476457, -8.852860228920314],
              [-35.2554667536849, -8.853402452040212],
              [-35.254937334050297, -8.854013108156808],
              [-35.254791254096702, -8.854181771600917],
              [-35.253761979187999, -8.855373334179184],
              [-35.25264453655312, -8.856666214672115],
              [-35.252377558908307, -8.856975601135852],
              [-35.252038847880563, -8.857368067430327],
              [-35.251741187164988, -8.857711505682621],
              [-35.251347864228237, -8.858173815556059],
              [-35.251091410370044, -8.858462657023223],
              [-35.250559704817782, -8.859078667626306],
              [-35.250437054910357, -8.859220460634003],
              [-35.24987330663631, -8.859869853006151],
              [-35.24977243367826, -8.859989723035227],
              [-35.249095692855093, -8.860771973846273],
              [-35.249040661944697, -8.860832609820378],
              [-35.248386647399528, -8.861591015320341],
              [-35.248049222312524, -8.861982577422047],
              [-35.247733387138076, -8.862345357465228],
              [-35.247660551114201, -8.862433917137933],
              [-35.247614907103689, -8.862484259763201],
              [-35.246649193483016, -8.863602400015575],
              [-35.246538129750533, -8.863729552690872],
              [-35.245478877777835, -8.864954167202987],
              [-35.248059714343235, -8.866407794697642],
              [-35.248456608390526, -8.866753117471539],
              [-35.248529053747788, -8.866810636323363],
              [-35.248902439001675, -8.867130755948534],
              [-35.249400086616461, -8.867557016065625],
              [-35.250086779782755, -8.868148378124161],
              [-35.250156607534876, -8.868208437427841],
              [-35.250547403611272, -8.868538238496551],
              [-35.251206635545351, -8.869402087007844],
              [-35.251794498223965, -8.869418536146568],
              [-35.255824090337995, -8.869522456103592],
              [-35.257024878870261, -8.869548128177978],
              [-35.257572229730165, -8.869563818686325],
              [-35.262743937068301, -8.869710658248525],
              [-35.262890197505605, -8.869714761240976],
              [-35.26516468114599, -8.8698199449816],
              [-35.265908415283455, -8.869547191530557],
              [-35.266859145582998, -8.868741361438607],
              [-35.268235052628015, -8.868621149032059],
              [-35.268335372662534, -8.86845061814441],
              [-35.27167059138084, -8.86730326407573],
              [-35.273113994265216, -8.867570433641864],
              [-35.277986871416594, -8.864852849660251],
              [-35.276929047365066, -8.864073468264539],
              [-35.275716345940296, -8.863372574870157],
              [-35.274691422969696, -8.862198035769374],
              [-35.273511921370833, -8.861413199213697],
              [-35.270556319247682, -8.85676381060895],
              [-35.269567954578065, -8.855178647895546],
              [-35.269212777263697, -8.854620531386127],
              [-35.268893659597005, -8.854134855150045],
              [-35.268027977411556, -8.853162566265327],
              [-35.267937274011537, -8.853092824521132],
              [-35.26780510514061, -8.85300250060004],
              [-35.264285819460035, -8.850803990071487],
              [-35.262784966980931, -8.849886891564823],
              [-35.260763878876837, -8.849949097082408],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0221000',
        uf: 'PE',
        nome_proje: 'PA LIVRAMENTO',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '344.8500',
        capacidade: 45.0,
        num_famili: 44.0,
        fase: 4.0,
        data_de_cr: '24/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '19/11/1999',
        area_calc_: 338.7947,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.349222926021632, -8.245557324469285],
              [-35.347353275283808, -8.244980972661555],
              [-35.345421039481231, -8.245914724187791],
              [-35.34396576613225, -8.24592703384965],
              [-35.343430721832306, -8.245800465293799],
              [-35.343329772157382, -8.2482381997815],
              [-35.343575502601418, -8.252138816882892],
              [-35.343618322228551, -8.253522757278276],
              [-35.343681049139562, -8.255205029316473],
              [-35.343521942246284, -8.255687160238768],
              [-35.3435131503009, -8.25607314021498],
              [-35.343471484693204, -8.257638166048663],
              [-35.343682472092141, -8.25810646180855],
              [-35.343655041319138, -8.258839253789755],
              [-35.343711785791257, -8.259460663867792],
              [-35.341631830368819, -8.259589794581714],
              [-35.339439176482642, -8.259671481677531],
              [-35.340155295063852, -8.260284880578119],
              [-35.340075589702955, -8.260477497987914],
              [-35.340218467566501, -8.262582290733306],
              [-35.340108998393603, -8.263143568041873],
              [-35.340129339109623, -8.264762101504736],
              [-35.339334582771635, -8.264695078828376],
              [-35.337889205855141, -8.264474865583004],
              [-35.336077307998011, -8.264277304449983],
              [-35.33592100541491, -8.265530457573085],
              [-35.336550886602289, -8.267074845588409],
              [-35.337633242985042, -8.26746077500899],
              [-35.338423297538732, -8.267777289547059],
              [-35.339065484690451, -8.267821073242191],
              [-35.341131286437893, -8.26803764563061],
              [-35.342137229151568, -8.268112644404226],
              [-35.344273802067676, -8.268283413553277],
              [-35.34546574861546, -8.268360747195342],
              [-35.34649481364373, -8.268356876866825],
              [-35.348089576032152, -8.268350765855413],
              [-35.34881748178401, -8.26834513363247],
              [-35.350043308241041, -8.268335759662966],
              [-35.35222273880224, -8.268298989816921],
              [-35.353874164029257, -8.268286557190688],
              [-35.354673773404578, -8.268270550217244],
              [-35.354679198684579, -8.2676254716235],
              [-35.354689562304451, -8.264846558173524],
              [-35.35467075601732, -8.263695481463964],
              [-35.355877864019902, -8.263562382342581],
              [-35.356637869965802, -8.263265523877344],
              [-35.357201751448116, -8.263553497408104],
              [-35.358358827123539, -8.263186691067709],
              [-35.357163429943839, -8.262570112237871],
              [-35.355746146920978, -8.261104887332795],
              [-35.354819299815603, -8.259705096294608],
              [-35.354515121955636, -8.258488726375194],
              [-35.354193032464067, -8.257248609533313],
              [-35.353824139573938, -8.256545981091438],
              [-35.353331351848887, -8.255788595527351],
              [-35.353333362072597, -8.255469358825346],
              [-35.353464723990875, -8.255099652326043],
              [-35.353502489477144, -8.254950302061998],
              [-35.3534842146776, -8.25477533290961],
              [-35.353089609165458, -8.253376298133803],
              [-35.353490400068978, -8.252189132169846],
              [-35.353058972666688, -8.251435179907819],
              [-35.353036100059086, -8.251295675936611],
              [-35.352395156979092, -8.2495862627718],
              [-35.352244227973657, -8.249102436426595],
              [-35.351895045985721, -8.247881262437476],
              [-35.351309359669557, -8.246292992128581],
              [-35.349222926021632, -8.245557324469285],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0207000',
        uf: 'PE',
        nome_proje: 'PA CAMPO ALEGRE',
        municipio: 'PESQUEIRA',
        area_hecta: '807.0890',
        capacidade: 78.0,
        num_famili: 71.0,
        fase: 3.0,
        data_de_cr: '28/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '14/07/1999',
        area_calc_: 806.3602,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.606366055050934, -8.255827224388952],
              [-36.60228075013066, -8.255812537831318],
              [-36.598539780007471, -8.257790633462825],
              [-36.596668091268874, -8.258317057306707],
              [-36.596627812222913, -8.262348395270164],
              [-36.596244713453387, -8.265741862882482],
              [-36.595598855249307, -8.26587119679389],
              [-36.593689215953042, -8.267591798728404],
              [-36.591169359501194, -8.270426499416988],
              [-36.589268845269316, -8.272564486321622],
              [-36.586998399724621, -8.272393343311037],
              [-36.585215673653856, -8.272259027268406],
              [-36.585081110748625, -8.275299134498118],
              [-36.587985211653425, -8.274324249034315],
              [-36.588906159411579, -8.276300217060902],
              [-36.589108890235821, -8.277754890505426],
              [-36.589302317803138, -8.280231315052704],
              [-36.589500099373936, -8.282200839298801],
              [-36.58843026133885, -8.28582483390122],
              [-36.59401346731422, -8.285375839516529],
              [-36.593606249839695, -8.287243116557804],
              [-36.592750232738013, -8.291127983725675],
              [-36.591877538051492, -8.295065071520172],
              [-36.593843652803031, -8.295245141000166],
              [-36.594678035939857, -8.295306574098822],
              [-36.596184093096298, -8.295060344965146],
              [-36.597885800471168, -8.294675293620674],
              [-36.598125209303582, -8.294666885458556],
              [-36.598848620731744, -8.294534954948409],
              [-36.599063712913541, -8.294511577687244],
              [-36.600851294566986, -8.294403857643239],
              [-36.602632077145643, -8.294609610400961],
              [-36.605539718285264, -8.294857646896276],
              [-36.604483844412798, -8.290796657825508],
              [-36.604748543185345, -8.289293538810655],
              [-36.606389193979865, -8.288708248180228],
              [-36.608385940413513, -8.287977948642023],
              [-36.610965100825254, -8.287037289750682],
              [-36.613128416709635, -8.286232763962584],
              [-36.612564325223843, -8.282155560208206],
              [-36.611848550110977, -8.279444702336178],
              [-36.611474909929697, -8.276691606436716],
              [-36.611084576044234, -8.273953414374631],
              [-36.611092800592914, -8.273205940273993],
              [-36.610448255703162, -8.271383754777681],
              [-36.609613667583197, -8.26966174778997],
              [-36.608963909644466, -8.267923132265492],
              [-36.608966926779331, -8.266880631825737],
              [-36.608280721688637, -8.266161993648348],
              [-36.607761391935114, -8.264536499794115],
              [-36.60720699246906, -8.262648608176884],
              [-36.606797158063905, -8.261236792548685],
              [-36.60681985113068, -8.261039270291114],
              [-36.609356044427543, -8.259367061334091],
              [-36.610457549738804, -8.255946112985557],
              [-36.606366055050934, -8.255827224388952],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0422000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA APARECIDA',
        municipio: 'MANARI',
        area_hecta: '2342.0788',
        capacidade: 52.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '30/11/2018',
        forma_obte: 'Desapropriação',
        data_obten: '01/04/2016',
        area_calc_: 2342.7803,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.701688290778591, -8.910884900554185],
              [-37.697772931846409, -8.913757630897155],
              [-37.676198009300329, -8.927164852932286],
              [-37.673122866313719, -8.930420396674874],
              [-37.671867965064088, -8.931704081143197],
              [-37.669773404495608, -8.931876909456101],
              [-37.669887980354439, -8.933163403610701],
              [-37.669890347849027, -8.933774505607428],
              [-37.671329824449387, -8.936548024303486],
              [-37.673019106170138, -8.93986278655332],
              [-37.674378469374183, -8.942517913497444],
              [-37.675678103961879, -8.945134329195694],
              [-37.676219276420625, -8.946197372849491],
              [-37.679009285691471, -8.951785055801453],
              [-37.680062712627574, -8.95331560150527],
              [-37.680326309834598, -8.953711359619614],
              [-37.681671715686633, -8.955679864975496],
              [-37.682165788478244, -8.956207916716457],
              [-37.684053215484347, -8.956991683032848],
              [-37.68429703588496, -8.956973469650475],
              [-37.686192415258553, -8.956312914997909],
              [-37.686232027529925, -8.956298831245597],
              [-37.690810870085194, -8.954599755683132],
              [-37.691588202355966, -8.953962107458834],
              [-37.692384936874873, -8.953307470907166],
              [-37.692471156170619, -8.953230377628467],
              [-37.692211083008949, -8.952373805798283],
              [-37.702075441717945, -8.949767415696684],
              [-37.70542585084381, -8.948852163728727],
              [-37.721746811238773, -8.944910913066032],
              [-37.723048308460427, -8.94459658654853],
              [-37.724933338718074, -8.942590017023718],
              [-37.730869835721741, -8.936270530395023],
              [-37.720861141741651, -8.906141245217734],
              [-37.720392980507611, -8.905937181428101],
              [-37.720248850360264, -8.9058660058732],
              [-37.719079656126141, -8.90533990513085],
              [-37.712985766692661, -8.902595328644979],
              [-37.701688290778591, -8.910884900554185],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0298000',
        uf: 'PE',
        nome_proje: 'PA MUSSUMBU',
        municipio: 'GOIANA',
        area_hecta: '749.6562',
        capacidade: 45.0,
        num_famili: 44.0,
        fase: 3.0,
        data_de_cr: '25/08/2003',
        forma_obte: 'Desapropriação',
        data_obten: '06/11/2002',
        area_calc_: 766.3393,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.048187715353976, -7.581441721783123],
              [-35.046684407332116, -7.581410379328583],
              [-35.04598932010633, -7.581273532537055],
              [-35.045705958895354, -7.581277985561936],
              [-35.045505830325737, -7.581506917359898],
              [-35.04509555700556, -7.582046735580282],
              [-35.044758781800439, -7.582466651028784],
              [-35.044083152686646, -7.583308283032926],
              [-35.043817801021163, -7.583533362248076],
              [-35.039387347841739, -7.589270264226005],
              [-35.038102073576461, -7.590903921808486],
              [-35.036489057547413, -7.592920420174707],
              [-35.036279775523795, -7.593266428694228],
              [-35.03549165315345, -7.59427174762442],
              [-35.035235777419935, -7.594647508653625],
              [-35.033418917399565, -7.596966547735642],
              [-35.032697968689234, -7.59758747756204],
              [-35.032596706725421, -7.597661674481339],
              [-35.029503894055004, -7.602338118232823],
              [-35.029194003630863, -7.602991586623535],
              [-35.028844685629998, -7.603911352341773],
              [-35.02872566157594, -7.604328059622624],
              [-35.028168874900558, -7.605354621421773],
              [-35.027967290634358, -7.605836473145752],
              [-35.027372768108165, -7.606608896205014],
              [-35.026853604364391, -7.606939635488605],
              [-35.025785049632482, -7.608025475667691],
              [-35.025395245959707, -7.608322484291009],
              [-35.025250038321055, -7.608569354903882],
              [-35.024991581897766, -7.608911913490828],
              [-35.024782478451137, -7.609407789998824],
              [-35.022612240683799, -7.615549361746518],
              [-35.020434879425629, -7.619745411498151],
              [-35.02147474973053, -7.621236842852857],
              [-35.021546087898287, -7.621181203615445],
              [-35.023752629742226, -7.619460230175487],
              [-35.031628876256185, -7.613317044532455],
              [-35.039247926360588, -7.608873193252466],
              [-35.04176353543113, -7.607405907342685],
              [-35.041882072275165, -7.607359795853899],
              [-35.046010095227722, -7.60535631908009],
              [-35.047167808860294, -7.605394465389314],
              [-35.049986042284168, -7.605290408774616],
              [-35.051024914500893, -7.605222912472305],
              [-35.051526420986754, -7.605215143567476],
              [-35.052392217747503, -7.604979096747352],
              [-35.052832813697449, -7.604886131617148],
              [-35.057341390239884, -7.603631062933436],
              [-35.057420884468371, -7.603638568837832],
              [-35.057526572268685, -7.602607267446863],
              [-35.057682034111629, -7.601527531162576],
              [-35.057750395325449, -7.601215239094944],
              [-35.057992125878854, -7.59986369914296],
              [-35.058239718035225, -7.598546779010028],
              [-35.05834796941155, -7.597228614393493],
              [-35.058436369362951, -7.596391262448586],
              [-35.058548858247292, -7.595970336157315],
              [-35.058853521942474, -7.5955188301788],
              [-35.058919283678655, -7.59539274705411],
              [-35.059683230189542, -7.594336056359468],
              [-35.059948483971333, -7.593909211431814],
              [-35.060125550889296, -7.593524793547598],
              [-35.060315181099668, -7.592965742936806],
              [-35.060383712431232, -7.592697662929355],
              [-35.060499590236489, -7.592338487238463],
              [-35.060507374137366, -7.591821518716087],
              [-35.060430750128084, -7.590234386752461],
              [-35.060366543506383, -7.589979524367128],
              [-35.05999930343912, -7.589185445271854],
              [-35.05990619303396, -7.589200377809082],
              [-35.059142661232336, -7.589267855098211],
              [-35.056752425744023, -7.589113008287541],
              [-35.056617354262826, -7.589062263750374],
              [-35.056242844320835, -7.58892347037265],
              [-35.055722761000681, -7.588551392588783],
              [-35.05559927271505, -7.5884109065701],
              [-35.055450577920162, -7.588187126658234],
              [-35.055034311647916, -7.587638075158216],
              [-35.054323592544705, -7.586639115630143],
              [-35.053754297160921, -7.585789976331887],
              [-35.053080377210669, -7.584836943765912],
              [-35.052391125494339, -7.584890407232485],
              [-35.05207806358824, -7.584827427815666],
              [-35.051685335821432, -7.584629887687393],
              [-35.05123914180902, -7.584326099377232],
              [-35.050488837404551, -7.583407411052766],
              [-35.05042173264529, -7.583336979568981],
              [-35.048592159536952, -7.581713856742334],
              [-35.048187715353976, -7.581441721783123],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0314000',
        uf: 'PE',
        nome_proje: 'PA NÃO PENSEI',
        municipio: 'AMARAJI',
        area_hecta: '287.3664',
        capacidade: 35.0,
        num_famili: 35.0,
        fase: 4.0,
        data_de_cr: '02/03/2005',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/2003',
        area_calc_: 281.4687,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.481420001967869, -8.363837582548964],
              [-35.481211718331174, -8.364117697185851],
              [-35.48108171868018, -8.365135904940397],
              [-35.48013980891821, -8.367080382394155],
              [-35.479563095411677, -8.366875874648423],
              [-35.479496966658729, -8.36598193864023],
              [-35.478747879950525, -8.365471208616931],
              [-35.47834241395546, -8.365270820486474],
              [-35.478215471898494, -8.365294083088264],
              [-35.478027543534779, -8.366550831755399],
              [-35.476727328654334, -8.366665939253426],
              [-35.477120578314242, -8.366943339479079],
              [-35.479363368083277, -8.367738529251096],
              [-35.479675330276464, -8.368028907563502],
              [-35.479688526304919, -8.368232767772136],
              [-35.478808578103369, -8.368972772626256],
              [-35.478755456563931, -8.369014586839674],
              [-35.478200226977719, -8.368960430927778],
              [-35.478733440223145, -8.369420288749787],
              [-35.480761034721191, -8.371568766035359],
              [-35.480939462776256, -8.371760733582907],
              [-35.482884973624202, -8.373781533291623],
              [-35.484869769866378, -8.375869782812167],
              [-35.485282388253353, -8.376300674651707],
              [-35.485396386431724, -8.376419455501839],
              [-35.487756452841523, -8.378911144759583],
              [-35.488710349304228, -8.379776380029053],
              [-35.489167330878772, -8.380175040091201],
              [-35.489374636581125, -8.380605816551547],
              [-35.490382534356627, -8.381579669559766],
              [-35.491175560389394, -8.382382230161317],
              [-35.491310991934441, -8.382535103134723],
              [-35.492955883741757, -8.384193011668538],
              [-35.493690594506369, -8.38500529523669],
              [-35.494333279978925, -8.385645513593301],
              [-35.494621385312833, -8.385454445532622],
              [-35.49466154655466, -8.385427811039499],
              [-35.494739347174075, -8.385382512744695],
              [-35.494852840854833, -8.385310419466007],
              [-35.497128990888271, -8.383592444321733],
              [-35.502730720345518, -8.379631639037655],
              [-35.504983154378913, -8.377853673397718],
              [-35.505205947811966, -8.377678078960795],
              [-35.504384771150498, -8.377127674770117],
              [-35.504057774095941, -8.376934639556605],
              [-35.502974868647513, -8.37657288689203],
              [-35.502524136822302, -8.376210502845955],
              [-35.501504807643244, -8.375980328563045],
              [-35.501232005539173, -8.375812747705929],
              [-35.50089046115086, -8.375431775759505],
              [-35.500336514151051, -8.375210984523157],
              [-35.499864543748579, -8.375188507211019],
              [-35.499702321306543, -8.375055686180037],
              [-35.499355100093183, -8.375038906853563],
              [-35.498855402253554, -8.374902013447135],
              [-35.498433227436976, -8.37452877850367],
              [-35.497942262860889, -8.374358183258529],
              [-35.497263036644085, -8.373868955003308],
              [-35.496923223706276, -8.373476932725364],
              [-35.496548179940454, -8.373680669265568],
              [-35.495953054154064, -8.373280247040233],
              [-35.495716008698302, -8.373327527485817],
              [-35.49569463657437, -8.373394229975736],
              [-35.494902581412916, -8.372945066134861],
              [-35.494472861454852, -8.372617374820312],
              [-35.49433380928204, -8.372319323185753],
              [-35.493959368327545, -8.372144804531265],
              [-35.493860793754365, -8.371871647258779],
              [-35.493770251487106, -8.371899375613168],
              [-35.493364332832819, -8.371672359459328],
              [-35.492940640442335, -8.371434411568252],
              [-35.492733813811682, -8.371571554311762],
              [-35.492777540267305, -8.371747710313906],
              [-35.492541962885568, -8.372052763780042],
              [-35.492184392567196, -8.372123576333658],
              [-35.491726366997071, -8.371562693582963],
              [-35.491401009739619, -8.371468056719085],
              [-35.491019704487144, -8.371358004951325],
              [-35.491129772088158, -8.371101441923027],
              [-35.490866705544256, -8.370954024365926],
              [-35.490863742601277, -8.370692155506655],
              [-35.490768050875971, -8.370518034739311],
              [-35.49057302899952, -8.370395329074213],
              [-35.490681415300436, -8.370663536977306],
              [-35.490302815507306, -8.371059148930117],
              [-35.490130231685548, -8.371040138499881],
              [-35.490258400274392, -8.370604607145003],
              [-35.489763680688824, -8.370410508231741],
              [-35.489687812570921, -8.36995005530996],
              [-35.489318001716981, -8.369608242043254],
              [-35.488539575718519, -8.368561202817014],
              [-35.488804017592784, -8.367955474839516],
              [-35.488696098411623, -8.367490388231186],
              [-35.488548598910896, -8.367232872418382],
              [-35.488676313372892, -8.366545478430023],
              [-35.488001469673598, -8.365636494987147],
              [-35.487636974488161, -8.365509286913348],
              [-35.485582501449819, -8.365867642323762],
              [-35.484769715988264, -8.364977511490364],
              [-35.484060978936192, -8.364704027778643],
              [-35.483879297246439, -8.364922966085141],
              [-35.482259417203558, -8.364398881707995],
              [-35.481831988494129, -8.363932859936972],
              [-35.481420001967869, -8.363837582548964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0088000',
        uf: 'PE',
        nome_proje: 'PA ESTIVAS',
        municipio: 'AMARAJI',
        area_hecta: '1153.8000',
        capacidade: 117.0,
        num_famili: 91.0,
        fase: 4.0,
        data_de_cr: '02/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 1063.8399,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.513556190520816, -8.37148774042697],
              [-35.512956482924537, -8.372026619566725],
              [-35.512125088477106, -8.372758581805542],
              [-35.510578954017269, -8.373946087425383],
              [-35.509694300836742, -8.374368228968457],
              [-35.509693515566454, -8.374368603574776],
              [-35.509320120379627, -8.374546780946671],
              [-35.506486927434445, -8.376665273098238],
              [-35.505426977275128, -8.377457831767751],
              [-35.503748128047434, -8.378690212616885],
              [-35.498941931280804, -8.382218195161315],
              [-35.49430905550922, -8.385618853999297],
              [-35.492929448414479, -8.384165356582015],
              [-35.486822190497143, -8.388192427233246],
              [-35.48645936673082, -8.388407818570991],
              [-35.483627512339517, -8.390088938747489],
              [-35.484637136558106, -8.391150220046329],
              [-35.486546195030954, -8.393156928750605],
              [-35.486864108184299, -8.393581578982721],
              [-35.48743119730981, -8.394339061166637],
              [-35.487340495715642, -8.394960900130849],
              [-35.486917499642608, -8.397860884106436],
              [-35.48660641254147, -8.39936774078997],
              [-35.486850194846298, -8.399724337535631],
              [-35.487596294449766, -8.400815697718812],
              [-35.487893395017508, -8.401495705883107],
              [-35.487946920125708, -8.401618213851959],
              [-35.489314011961405, -8.402514721424826],
              [-35.490387102588109, -8.402421081362284],
              [-35.492365365337051, -8.403422085371894],
              [-35.492769692377721, -8.403238847235569],
              [-35.493421610645235, -8.403442390648058],
              [-35.495254406733302, -8.404014622238352],
              [-35.497120362871009, -8.404597195898763],
              [-35.497501572087465, -8.404716212197357],
              [-35.498796331176017, -8.405980835312789],
              [-35.501859249728824, -8.409184146379708],
              [-35.503995568360921, -8.406825813551514],
              [-35.504605786476986, -8.406167066474357],
              [-35.504967095732049, -8.405771253560674],
              [-35.507125585048612, -8.403414304774845],
              [-35.509885166267537, -8.401162655389147],
              [-35.511933574372932, -8.400251793233849],
              [-35.517312098816738, -8.397631164340197],
              [-35.530966786109019, -8.390314252194559],
              [-35.528364992291969, -8.387450735175358],
              [-35.528362314324212, -8.387447788233423],
              [-35.527917669650073, -8.386958408166514],
              [-35.526570235968094, -8.385502111922367],
              [-35.526414567774083, -8.385339014481575],
              [-35.522521653528337, -8.381043371118858],
              [-35.521140776051588, -8.379568595659222],
              [-35.518415906508558, -8.376626179661304],
              [-35.517870186959428, -8.376073077565815],
              [-35.514766420513212, -8.372778005635901],
              [-35.513556190520816, -8.37148774042697],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0414000',
        uf: 'PE',
        nome_proje: 'PA ENTRONCAMENTO',
        municipio: 'CATENDE',
        area_hecta: '532.5078',
        capacidade: 47.0,
        num_famili: 44.0,
        fase: 3.0,
        data_de_cr: '06/11/2014',
        forma_obte: 'Desapropriação',
        data_obten: '23/12/2011',
        area_calc_: 531.7393,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.754946078089638, -8.625724646878687],
              [-35.75377657079855, -8.626266848376474],
              [-35.753924173400407, -8.627215937425524],
              [-35.753574842113558, -8.627373329572611],
              [-35.75321334086405, -8.627536204511802],
              [-35.753108674914408, -8.627583361838658],
              [-35.752409227188956, -8.627898497642946],
              [-35.752271676728419, -8.627960470743634],
              [-35.744372234751125, -8.628239108928566],
              [-35.744100621482609, -8.628248686716427],
              [-35.742049986915561, -8.628320991196441],
              [-35.741609941549463, -8.628331214192803],
              [-35.741532385604437, -8.628333975123939],
              [-35.738117225663053, -8.628457153933605],
              [-35.73597108070652, -8.628534546160406],
              [-35.735893486226736, -8.628537344068826],
              [-35.735089129489438, -8.628566346777557],
              [-35.73440419338845, -8.628879217623826],
              [-35.732782098997887, -8.629620165365257],
              [-35.732595053234967, -8.629705604431205],
              [-35.73202677308791, -8.629834101014675],
              [-35.730738400061362, -8.630125418078071],
              [-35.730213040821759, -8.630244207014091],
              [-35.730034465323826, -8.630284584528976],
              [-35.728432921929425, -8.631714159552264],
              [-35.728357685921686, -8.631781316617319],
              [-35.730604423554766, -8.633751939910658],
              [-35.729702073398862, -8.634412624398072],
              [-35.728792418519987, -8.634045216110422],
              [-35.728357129131055, -8.634457530918786],
              [-35.728327085020446, -8.634485986378099],
              [-35.726306406516386, -8.636399985857571],
              [-35.72623838226037, -8.636464418446268],
              [-35.725809940847029, -8.636870237594399],
              [-35.727005076772656, -8.638029179913273],
              [-35.727251124237178, -8.638267774415965],
              [-35.727938816862199, -8.638934633411393],
              [-35.72813439504084, -8.639124285756695],
              [-35.728361309259249, -8.639344324263426],
              [-35.729061108680916, -8.639461264609114],
              [-35.729674433114006, -8.639563753344333],
              [-35.729980617005516, -8.639614928006237],
              [-35.730336951019019, -8.639674484264114],
              [-35.7306056281815, -8.639719389657863],
              [-35.73093794503572, -8.6397749311946],
              [-35.731228318647233, -8.639823442113675],
              [-35.733329771596843, -8.640174511544021],
              [-35.733904371000484, -8.640270502080199],
              [-35.734776923534554, -8.642138033733735],
              [-35.734898514551666, -8.642364945619414],
              [-35.735143303138877, -8.642922219172879],
              [-35.734825908948068, -8.643265305509672],
              [-35.73456219506307, -8.643550365746153],
              [-35.733808776979828, -8.644364766706753],
              [-35.734553993844706, -8.644823493606955],
              [-35.734902840690218, -8.645038229322505],
              [-35.735763268692061, -8.645567870492513],
              [-35.736041160315068, -8.645578063076805],
              [-35.736113745616606, -8.645580725348244],
              [-35.736951493222165, -8.645611451090756],
              [-35.738606244449237, -8.64567213628068],
              [-35.738983486543184, -8.646167974300916],
              [-35.739031324159612, -8.646230850773811],
              [-35.740279960376462, -8.647872012538734],
              [-35.742930855788032, -8.647229209751124],
              [-35.743589690010388, -8.647069449268331],
              [-35.744255026517891, -8.647598724067839],
              [-35.744584899630759, -8.647861136780604],
              [-35.744818799079212, -8.648047202369483],
              [-35.7451564983674, -8.648315839409193],
              [-35.745913614837519, -8.64890888165275],
              [-35.745942225574758, -8.648940875908005],
              [-35.746518512918684, -8.649408128276765],
              [-35.748066559591329, -8.649339941499147],
              [-35.748764213606009, -8.649309209920803],
              [-35.748938664553506, -8.649301541398884],
              [-35.749434089899324, -8.648701168728174],
              [-35.749457086126945, -8.64867330109421],
              [-35.749766682800718, -8.648304319630839],
              [-35.75002218063058, -8.648121362420893],
              [-35.751515153958564, -8.64791752895497],
              [-35.751603957151389, -8.64790540460641],
              [-35.752171707373883, -8.64675259331857],
              [-35.752507517880062, -8.64607073092276],
              [-35.753245522589239, -8.644572207929038],
              [-35.753285583946564, -8.644490862849338],
              [-35.753506236100385, -8.644042825501835],
              [-35.75399509648895, -8.643050184715642],
              [-35.754177590770254, -8.642633542088612],
              [-35.754241162232773, -8.642532373599966],
              [-35.754313819025278, -8.642412528671997],
              [-35.754016521034551, -8.64173290274173],
              [-35.753997364312198, -8.641696198727841],
              [-35.753966149928139, -8.641586427640021],
              [-35.753949226133457, -8.641526911958753],
              [-35.753592256054397, -8.640271553207162],
              [-35.753528264478376, -8.640046512204256],
              [-35.753509360095784, -8.639828673780473],
              [-35.753458016445315, -8.639237028498489],
              [-35.753380803137468, -8.638347271039152],
              [-35.753340540909221, -8.637883309762257],
              [-35.753294934234631, -8.637357757915161],
              [-35.753306999349249, -8.637222153044988],
              [-35.753322120711694, -8.637052197939832],
              [-35.753384107144925, -8.636355508513088],
              [-35.753478315600859, -8.635296667241063],
              [-35.753548143966611, -8.634511845156551],
              [-35.753662238369031, -8.63322950901893],
              [-35.753721239343449, -8.632566384728207],
              [-35.753774847899408, -8.631963868485579],
              [-35.754402794992664, -8.631183617044709],
              [-35.754861239557812, -8.630613977789086],
              [-35.755831209481059, -8.629408738175259],
              [-35.755778984978647, -8.628879348964388],
              [-35.755755574641867, -8.628642041778894],
              [-35.755703911223762, -8.628118334548082],
              [-35.755579783321352, -8.627840308179451],
              [-35.754946078089638, -8.625724646878687],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0035000',
        uf: 'PE',
        nome_proje: 'PA PATRIMÔNIO',
        municipio: 'CONDADO',
        area_hecta: '262.9429',
        capacidade: 89.0,
        num_famili: 86.0,
        fase: 3.0,
        data_de_cr: '31/01/1990',
        forma_obte: 'Compra e Venda',
        data_obten: '21/12/1988',
        area_calc_: 262.1179,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.094225417779448, -7.584671894019682],
              [-35.093693180264076, -7.584750934450586],
              [-35.093063827199067, -7.584830984130774],
              [-35.092540896437569, -7.584923809531571],
              [-35.092273056569773, -7.584954116448047],
              [-35.091930398719462, -7.585025101160961],
              [-35.09130720341701, -7.58514236333367],
              [-35.090551235506986, -7.585343701541948],
              [-35.088275816241804, -7.586043749609966],
              [-35.086397908317785, -7.58653251504218],
              [-35.082193084702013, -7.587569850803394],
              [-35.079946471721598, -7.587616765985481],
              [-35.079446118568725, -7.587753313141809],
              [-35.07920453719008, -7.587819195025997],
              [-35.07865311477272, -7.58807495191371],
              [-35.078187103303399, -7.588514625343414],
              [-35.077665605170559, -7.588851779034864],
              [-35.077111885374514, -7.58929006306546],
              [-35.076342648375764, -7.589921114846567],
              [-35.076145799350087, -7.589944745735342],
              [-35.075257218339488, -7.590287899828042],
              [-35.075642150191349, -7.591208141844976],
              [-35.076166439877731, -7.591923593882652],
              [-35.076626561973505, -7.592501762763178],
              [-35.076763484442196, -7.592658223397248],
              [-35.077755399676064, -7.593022944859929],
              [-35.078269965988419, -7.593184378364855],
              [-35.079603966737672, -7.593639033088292],
              [-35.081962434036704, -7.59448128890613],
              [-35.08274532801709, -7.594786792453831],
              [-35.084024559088824, -7.595380637765376],
              [-35.085115175414558, -7.596089558650482],
              [-35.086287376604147, -7.596807123748087],
              [-35.087841577173897, -7.597830205481709],
              [-35.088397390462433, -7.598013935463447],
              [-35.090814836733387, -7.598868232926177],
              [-35.090920543741937, -7.599406412366648],
              [-35.091826174861367, -7.599329729418423],
              [-35.093146161932808, -7.59907476855029],
              [-35.093691139407092, -7.598891522964401],
              [-35.095398702527611, -7.598541846662584],
              [-35.096278814170915, -7.598322900058951],
              [-35.097075502689947, -7.598201533645844],
              [-35.099748522932735, -7.59826997147886],
              [-35.099483960889593, -7.595376323353777],
              [-35.099432559431982, -7.593932994975589],
              [-35.099172549181411, -7.591601331793363],
              [-35.099153157832156, -7.591339269159529],
              [-35.099069960708206, -7.591072090844515],
              [-35.09869569785463, -7.591108973447152],
              [-35.098033100592168, -7.591247409945611],
              [-35.097042180262022, -7.591330931027274],
              [-35.096679754202796, -7.591324092206939],
              [-35.096163224833738, -7.591299464790215],
              [-35.095851435416073, -7.591328450147047],
              [-35.095826792778581, -7.591047970775137],
              [-35.095816142443468, -7.59096277596389],
              [-35.095674364835936, -7.589898470003104],
              [-35.095354916617644, -7.588065002057767],
              [-35.095180550333929, -7.587323486248779],
              [-35.094644895486233, -7.584581101858856],
              [-35.094225417779448, -7.584671894019682],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0229000',
        uf: 'PE',
        nome_proje: 'PA SANTA MARIA',
        municipio: 'ESCADA',
        area_hecta: '333.6000',
        capacidade: 35.0,
        num_famili: 33.0,
        fase: 5.0,
        data_de_cr: '31/05/2001',
        forma_obte: 'Desapropriação',
        data_obten: '03/05/2000',
        area_calc_: 383.028,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.248982989228949, -8.317253144830966],
              [-35.24759222932515, -8.317344077387485],
              [-35.246818535534132, -8.317732598276855],
              [-35.246261059815531, -8.31787575946675],
              [-35.244897297869244, -8.317465512354696],
              [-35.244795134247013, -8.318600815102464],
              [-35.244708502230019, -8.319722472043603],
              [-35.244603543262258, -8.321370740261649],
              [-35.244333472441589, -8.324398623427049],
              [-35.244137593595141, -8.328071545489205],
              [-35.243936299249746, -8.33096861142114],
              [-35.247345399244409, -8.330392082303559],
              [-35.247793886022599, -8.331248312749516],
              [-35.247808547210873, -8.331336538144102],
              [-35.247841478257762, -8.331382087614797],
              [-35.248078769529897, -8.331854101892858],
              [-35.248135973807003, -8.331922924112433],
              [-35.24817344874851, -8.331985440607383],
              [-35.248281260268101, -8.332159729226733],
              [-35.248316764177225, -8.332242594007832],
              [-35.248324632287016, -8.332269575233191],
              [-35.248352712282475, -8.332355555237806],
              [-35.248764647009359, -8.332616387019918],
              [-35.249769957732759, -8.333531606888423],
              [-35.250919569900383, -8.334696439298966],
              [-35.251475554493723, -8.335185047257694],
              [-35.255115912997496, -8.336036957311464],
              [-35.25669984029053, -8.336333536532953],
              [-35.257995546980098, -8.337402857690702],
              [-35.258707331637027, -8.337995166317679],
              [-35.259753676872108, -8.338928963338697],
              [-35.260131237337987, -8.338969139959554],
              [-35.260362578304608, -8.338917228424529],
              [-35.260486393621818, -8.338894261728848],
              [-35.26075638623648, -8.338850224436699],
              [-35.261295101567498, -8.338673872472112],
              [-35.261650923891708, -8.338538278422991],
              [-35.262156947435159, -8.338286476082434],
              [-35.262463672770103, -8.338093486047475],
              [-35.262814474037491, -8.337880007306325],
              [-35.263161723154795, -8.337758001579509],
              [-35.264223223785947, -8.336552966875711],
              [-35.264152605572072, -8.335737996878375],
              [-35.264107949726828, -8.335128106048126],
              [-35.264100980642361, -8.333519683132423],
              [-35.264134938954854, -8.332767052040886],
              [-35.264428398810239, -8.332123358232325],
              [-35.264315262475236, -8.33203547304225],
              [-35.264542390635008, -8.331640529404133],
              [-35.264573926975139, -8.331162250200702],
              [-35.264606537461887, -8.328121959978509],
              [-35.26458382098955, -8.325065668932796],
              [-35.264512876698092, -8.322567729697708],
              [-35.264110153822116, -8.322741734804991],
              [-35.263881450881286, -8.322807001046007],
              [-35.26367759083557, -8.322815901179881],
              [-35.263403546355669, -8.322691121901736],
              [-35.262899005163931, -8.322270968312308],
              [-35.262436056674844, -8.322012026618413],
              [-35.261986233311141, -8.321815467238059],
              [-35.261401325093182, -8.321663331595078],
              [-35.261045365347051, -8.321632241192816],
              [-35.26057233509276, -8.321655423236567],
              [-35.260247976061542, -8.321724488574315],
              [-35.26017663948295, -8.321644088757816],
              [-35.26013616649994, -8.321628501350663],
              [-35.259952421906583, -8.32146883907156],
              [-35.259721011265491, -8.321290882792786],
              [-35.259566945836845, -8.321182402766693],
              [-35.259446841181941, -8.321110928587592],
              [-35.259368431467614, -8.321091206323338],
              [-35.259228386666479, -8.321073627246626],
              [-35.258987405136125, -8.321109955655924],
              [-35.258858123071413, -8.321164472504657],
              [-35.258709268454453, -8.321258540589927],
              [-35.258585652976059, -8.321359636065475],
              [-35.258247647667083, -8.321741942575676],
              [-35.257906008672222, -8.321879356671388],
              [-35.257837721451587, -8.321898568421499],
              [-35.257651428876642, -8.321916591691309],
              [-35.257490323361324, -8.321896509034861],
              [-35.257435355429202, -8.32188427229166],
              [-35.257323087588105, -8.321845470194219],
              [-35.257251818150479, -8.3218153987584],
              [-35.257195395083073, -8.321784794769176],
              [-35.257050901071899, -8.321677209032078],
              [-35.256905480682704, -8.321566384379501],
              [-35.256859387681459, -8.321532360276539],
              [-35.256747033236373, -8.321461826733945],
              [-35.256594417322731, -8.321452300711849],
              [-35.256420768284514, -8.321434234745636],
              [-35.25621976978438, -8.321380698008113],
              [-35.256127801968027, -8.321350743867191],
              [-35.25605830688243, -8.321316180269591],
              [-35.255954398795303, -8.321244164727345],
              [-35.255896542601285, -8.321199002705928],
              [-35.255856254384305, -8.321157102301736],
              [-35.255801264541887, -8.321062399812199],
              [-35.255782925180291, -8.320982843401584],
              [-35.255714115466979, -8.32093090482428],
              [-35.255622334285611, -8.320894226727674],
              [-35.255501525108542, -8.320856817029375],
              [-35.255437483605498, -8.320831634164474],
              [-35.255264585969073, -8.320813343746096],
              [-35.255206961973812, -8.320808740818542],
              [-35.25511204968128, -8.320814657723044],
              [-35.255074645999095, -8.320805009995443],
              [-35.254970454876052, -8.320762127609921],
              [-35.254924594756005, -8.320729446435903],
              [-35.254884347701619, -8.320694716584654],
              [-35.254853985829122, -8.320657465621601],
              [-35.254791972472461, -8.320514847194451],
              [-35.254689475114731, -8.320217387166746],
              [-35.254673983990948, -8.320183861347564],
              [-35.25464905638821, -8.320152629983424],
              [-35.254507151700828, -8.320045871225636],
              [-35.254456437626203, -8.319990584272063],
              [-35.254399058647294, -8.319950125152412],
              [-35.254364789429815, -8.319937770455981],
              [-35.254224764207819, -8.319844894568828],
              [-35.254131671354635, -8.319775506099283],
              [-35.254108181012661, -8.319736782337541],
              [-35.254099338283538, -8.319629581807197],
              [-35.254116768013894, -8.319529986279871],
              [-35.254131972484373, -8.319356004978951],
              [-35.254193688352686, -8.318895819010905],
              [-35.254203191761512, -8.318863195158267],
              [-35.254234902161947, -8.318784883999236],
              [-35.254255371205531, -8.318744655507498],
              [-35.254263326619942, -8.318649850831896],
              [-35.254248229111951, -8.318613742686134],
              [-35.25422833663842, -8.318580997916486],
              [-35.254210225299673, -8.318561380589832],
              [-35.254133965992892, -8.318524165745997],
              [-35.254100904485917, -8.318495900147232],
              [-35.25390661652478, -8.318347526431246],
              [-35.25381288030178, -8.318244359521568],
              [-35.25371907071942, -8.318128083803206],
              [-35.253671956763249, -8.318072776235956],
              [-35.253583924538113, -8.317991127870334],
              [-35.253517849028704, -8.3179576073649],
              [-35.253422720851141, -8.317924496168461],
              [-35.253354152101565, -8.31789440845105],
              [-35.253171805450414, -8.317779970117281],
              [-35.253108442060352, -8.317744626406943],
              [-35.252941063072491, -8.317674530943789],
              [-35.252870191531152, -8.317643387551149],
              [-35.252759304005394, -8.317583603779706],
              [-35.252630737644168, -8.317537997533693],
              [-35.252562288401855, -8.31750195944408],
              [-35.252479161931554, -8.31746014821046],
              [-35.252434055425447, -8.317441131926026],
              [-35.252376714889486, -8.317424012729315],
              [-35.25230346244566, -8.317404994587978],
              [-35.252257727784972, -8.317373558997424],
              [-35.252229231577822, -8.317347725811912],
              [-35.252183416821211, -8.317296487129839],
              [-35.252112600617764, -8.317275014689026],
              [-35.251888569852696, -8.317264172087501],
              [-35.251808119783284, -8.317271949215499],
              [-35.251392957810921, -8.317273261586303],
              [-35.250697815329112, -8.317361435884916],
              [-35.250045237794794, -8.317703408150377],
              [-35.248982989228949, -8.317253144830966],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0068000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO RIACHÃO DO NORTE',
        municipio: 'AMARAJI',
        area_hecta: '487.0000',
        capacidade: 52.0,
        num_famili: 31.0,
        fase: 4.0,
        data_de_cr: '09/11/1995',
        forma_obte: 'Desapropriação',
        data_obten: '24/03/1995',
        area_calc_: 435.4846,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.531013703701156, -8.390353354252092],
              [-35.526417662825907, -8.392988641717068],
              [-35.522768464630502, -8.39490599462119],
              [-35.522745591300215, -8.394885238983989],
              [-35.520481800708851, -8.396094640691166],
              [-35.518969633352114, -8.39686318239141],
              [-35.516241539459585, -8.398229400327992],
              [-35.513116125690985, -8.399741554458439],
              [-35.50989375773765, -8.40123282592287],
              [-35.509565409546788, -8.401492715356104],
              [-35.507741441364601, -8.402926864555036],
              [-35.507076529820232, -8.403530303170124],
              [-35.506895930158564, -8.403735832756217],
              [-35.504648235445664, -8.406199214355743],
              [-35.501886284276722, -8.409216294600048],
              [-35.503225329171862, -8.410530432378332],
              [-35.504207451122028, -8.411650586331934],
              [-35.506365744662958, -8.41377874556596],
              [-35.508267326889047, -8.415698920660541],
              [-35.510584545563276, -8.418322458776082],
              [-35.510783917913386, -8.418134224822779],
              [-35.511515952433527, -8.417449058907081],
              [-35.51203755376897, -8.416955100456299],
              [-35.512107689823331, -8.416890203242914],
              [-35.513329327369703, -8.415723474559147],
              [-35.514795240066405, -8.414325836741178],
              [-35.515341450191251, -8.413806461869193],
              [-35.515407522392813, -8.413744493470331],
              [-35.517739393125453, -8.411593471490974],
              [-35.517806664418366, -8.411536720775555],
              [-35.519018291807456, -8.410360177460582],
              [-35.519261833231852, -8.410150756293776],
              [-35.519458902361322, -8.409968341204406],
              [-35.520976082060699, -8.408525656623018],
              [-35.521378898805153, -8.408150899478423],
              [-35.522707164780968, -8.406891155664892],
              [-35.524160481538452, -8.405494743590525],
              [-35.52422533969613, -8.405425814914826],
              [-35.525038016219561, -8.404685541914365],
              [-35.527179343697718, -8.402749962843444],
              [-35.528410046007856, -8.401556445895782],
              [-35.529143062934253, -8.400852678597012],
              [-35.531364100998182, -8.398448022794334],
              [-35.532060443417791, -8.397754939981359],
              [-35.532804204133704, -8.39700174870638],
              [-35.532880671059061, -8.396925196815813],
              [-35.533582404375814, -8.396075313293387],
              [-35.534848277387297, -8.394557580939559],
              [-35.532944516547055, -8.392474984026682],
              [-35.53169531253522, -8.391167347810063],
              [-35.531013703701156, -8.390353354252092],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0353000',
        uf: 'PE',
        nome_proje: 'PA MARÉ',
        municipio: 'ALIANCA',
        area_hecta: '618.6365',
        capacidade: 60.0,
        num_famili: 51.0,
        fase: 4.0,
        data_de_cr: '13/04/2006',
        forma_obte: 'Desapropriação',
        data_obten: '24/05/2004',
        area_calc_: 618.2192,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.218363171149647, -7.6507154156351],
              [-35.216561528368771, -7.6507794768849],
              [-35.216478750388951, -7.650777199742429],
              [-35.215396357049265, -7.650806127706924],
              [-35.212734030628482, -7.650917089185196],
              [-35.212643902132619, -7.650924900570757],
              [-35.211602073188978, -7.650969810437555],
              [-35.20663846899334, -7.651153682406409],
              [-35.20585325131109, -7.651161271119438],
              [-35.205669087631094, -7.651240257756585],
              [-35.205019980874859, -7.651307828373963],
              [-35.204890645568334, -7.651379918005947],
              [-35.204445097250769, -7.651669189140218],
              [-35.204220942407751, -7.651728278917663],
              [-35.203711098904463, -7.651778420120852],
              [-35.203027062935867, -7.65200073758114],
              [-35.202288994722061, -7.652520530925162],
              [-35.201834635754359, -7.652591188250136],
              [-35.201583873795016, -7.652534023413569],
              [-35.200871036820665, -7.651717857137027],
              [-35.200539795968112, -7.651487935272995],
              [-35.200184845318311, -7.651217764698629],
              [-35.199784341951144, -7.651148647476744],
              [-35.199486007060926, -7.651134983455375],
              [-35.200785398519187, -7.654988164248652],
              [-35.201416118351354, -7.656892389534216],
              [-35.201838598816835, -7.65807188200298],
              [-35.202138645467706, -7.658875507100616],
              [-35.203169184655437, -7.661932864844911],
              [-35.203007118868648, -7.662165920418349],
              [-35.202406795789372, -7.66291865879626],
              [-35.201162454461262, -7.664754163084688],
              [-35.200319814878966, -7.666664084455705],
              [-35.199954512854795, -7.667618451478569],
              [-35.199791686954491, -7.668085147588646],
              [-35.19908071916889, -7.669714568963593],
              [-35.199263639010404, -7.671266283533991],
              [-35.199375866528975, -7.671863871261464],
              [-35.199279069567559, -7.672759575105769],
              [-35.19914075345195, -7.67352794792315],
              [-35.198974708753816, -7.674687264583255],
              [-35.19909329193279, -7.675255288499427],
              [-35.199792687798222, -7.675337960586025],
              [-35.200517149917452, -7.675182151901053],
              [-35.201474470816386, -7.675229329486438],
              [-35.202241428127387, -7.675045657358453],
              [-35.202617986025906, -7.67504617857362],
              [-35.202972811129719, -7.675034415812441],
              [-35.20462270689886, -7.674198753337503],
              [-35.20474427152962, -7.67415344067187],
              [-35.205287669244171, -7.673906304564991],
              [-35.206194512229771, -7.67359604713115],
              [-35.206616973120006, -7.673645520519986],
              [-35.207243558696334, -7.673565787346297],
              [-35.207805009484439, -7.673381451041148],
              [-35.208390470405128, -7.673213243308949],
              [-35.208704314583791, -7.672897684365777],
              [-35.208866952606058, -7.67238629753394],
              [-35.209506925027149, -7.671243071120397],
              [-35.21000657093213, -7.670705412301062],
              [-35.21032711886793, -7.670702671192474],
              [-35.212191343067076, -7.67120439433746],
              [-35.212283144995425, -7.671359992025547],
              [-35.213142653885072, -7.67138128571432],
              [-35.213365900333187, -7.671142524746463],
              [-35.213674534186175, -7.670905285698203],
              [-35.214102332609251, -7.670677307871588],
              [-35.214610919659151, -7.670810094739331],
              [-35.215420571319456, -7.670768309326494],
              [-35.21685192672647, -7.669942350374672],
              [-35.21701598298781, -7.669752217129039],
              [-35.217750106988184, -7.668184185352957],
              [-35.218370839825539, -7.667712961921348],
              [-35.218821723953376, -7.667205216409992],
              [-35.219818342562796, -7.666790399350477],
              [-35.220234582230695, -7.666755837489888],
              [-35.220427687066554, -7.666759617224252],
              [-35.222180342901197, -7.666392809280835],
              [-35.222609298224647, -7.666394726667109],
              [-35.223477863010729, -7.666235516598073],
              [-35.223834921530937, -7.666230766551979],
              [-35.224340316296455, -7.666478912312704],
              [-35.224643506873697, -7.666576148633986],
              [-35.225238208837759, -7.666589212080185],
              [-35.225762119981518, -7.666369322888232],
              [-35.225978010693503, -7.666344726606659],
              [-35.227040000779716, -7.666592240229444],
              [-35.227131621905521, -7.666599945079434],
              [-35.227152881978377, -7.664969153197768],
              [-35.22710458233999, -7.663169309729732],
              [-35.226981001832691, -7.662173220408625],
              [-35.226793378074149, -7.660745494100094],
              [-35.226610373625633, -7.659004747567348],
              [-35.226415735851752, -7.657603613218654],
              [-35.226484401340372, -7.657252244118016],
              [-35.226831502147085, -7.655855757975274],
              [-35.227080486016249, -7.654827199194489],
              [-35.227191348713831, -7.654158928442476],
              [-35.227328766821735, -7.653867494766076],
              [-35.227410088756585, -7.652891352515876],
              [-35.227645552050575, -7.651597251100646],
              [-35.223203408224386, -7.650893710428579],
              [-35.220151566293076, -7.650735650671825],
              [-35.218363171149647, -7.6507154156351],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0121000',
        uf: 'PE',
        nome_proje: 'PA ANGICO TORTO',
        municipio: 'ITAIBA',
        area_hecta: '3000.0000',
        capacidade: 95.0,
        num_famili: 75.0,
        fase: 5.0,
        data_de_cr: '29/10/1997',
        forma_obte: 'Desapropriação',
        data_obten: '04/03/1997',
        area_calc_: 2237.4191,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.310583952806326, -8.912787048082988],
              [-37.30835871639195, -8.914247691250539],
              [-37.307740961164008, -8.914679955172211],
              [-37.307214734378, -8.915389500946098],
              [-37.306674105190723, -8.916027199835746],
              [-37.306446222100021, -8.916248826334206],
              [-37.306085651796465, -8.916493286274168],
              [-37.305772733515077, -8.916626630627905],
              [-37.305350981318014, -8.916721390994079],
              [-37.304616986002209, -8.916803007277492],
              [-37.303573153000428, -8.916988680426147],
              [-37.302526305600558, -8.917188985649975],
              [-37.30103131043132, -8.917457554024319],
              [-37.300768651730692, -8.917553036157328],
              [-37.299956644524237, -8.917938984256452],
              [-37.299011465249883, -8.919724760073116],
              [-37.297733480893413, -8.921799055480244],
              [-37.297139426447458, -8.922833476738321],
              [-37.296880685345855, -8.923353792137403],
              [-37.296282183705664, -8.924710466988584],
              [-37.295997440138756, -8.925119330128439],
              [-37.295275745561334, -8.925721017435274],
              [-37.29388755670935, -8.926773756474313],
              [-37.293154937460436, -8.927505763370675],
              [-37.292428678106333, -8.928136720149617],
              [-37.291953006009955, -8.928418719911122],
              [-37.291357436265727, -8.928823217596015],
              [-37.290515645587135, -8.929586942395849],
              [-37.288953152336155, -8.931050487652005],
              [-37.287855403441654, -8.932047405658301],
              [-37.287187188064394, -8.932548241813546],
              [-37.286372650012019, -8.933150942427607],
              [-37.285648546890627, -8.933631008469563],
              [-37.284853360723361, -8.934189847747685],
              [-37.283885051532714, -8.935225435602622],
              [-37.283085980340502, -8.935983475886795],
              [-37.282302200236622, -8.936621464373829],
              [-37.282259190310981, -8.93668279015656],
              [-37.282258970696695, -8.936729665342234],
              [-37.282673126158237, -8.937004051614561],
              [-37.283031370629097, -8.937263529820358],
              [-37.283347815605161, -8.937483262430336],
              [-37.28362946393343, -8.937583445854253],
              [-37.28382648421627, -8.937666391239029],
              [-37.283972149458116, -8.937705152388704],
              [-37.284175233990247, -8.937751503615203],
              [-37.28452839662328, -8.937838100998034],
              [-37.284921177422554, -8.937958573643396],
              [-37.285215201363329, -8.938090308248094],
              [-37.285523018563097, -8.93826532050908],
              [-37.285617116510458, -8.938305307497865],
              [-37.285695129479649, -8.938319584617574],
              [-37.286020679724025, -8.938325484445503],
              [-37.286475869128459, -8.938331983168863],
              [-37.287044581872728, -8.938319962362032],
              [-37.28735105641065, -8.938309659149457],
              [-37.287778508999011, -8.93826182738751],
              [-37.288208921604578, -8.938211079005177],
              [-37.288857746634442, -8.938079304586733],
              [-37.28914080767737, -8.938035199404958],
              [-37.289435831786626, -8.937953062134325],
              [-37.290081614314992, -8.937841779789013],
              [-37.290675372236379, -8.937671660902119],
              [-37.290775643555378, -8.937651614733952],
              [-37.291123339521008, -8.937647357844559],
              [-37.291783389113029, -8.937635750487539],
              [-37.291957127970811, -8.937657059324115],
              [-37.292101212306868, -8.937719248351733],
              [-37.292477444103405, -8.937914346520037],
              [-37.292624256688896, -8.938023424385635],
              [-37.292982347181209, -8.938318051054091],
              [-37.293269848500238, -8.938585984506204],
              [-37.293498750428142, -8.938783333386008],
              [-37.293663269123357, -8.938886632904525],
              [-37.293869241503273, -8.938946176456907],
              [-37.294113816621518, -8.938941442394121],
              [-37.294333730241824, -8.939170977088777],
              [-37.294820250350874, -8.939726943787987],
              [-37.294940451715782, -8.939856407049344],
              [-37.29515991664983, -8.940182622150935],
              [-37.295265386412169, -8.940312017596982],
              [-37.295508899789667, -8.940535800840944],
              [-37.295934428128753, -8.940902515259754],
              [-37.296589468722679, -8.941336204353989],
              [-37.29670965762427, -8.941468596959275],
              [-37.296779635510255, -8.941627126848649],
              [-37.296983349867972, -8.942173002696739],
              [-37.297160315673949, -8.942768561948451],
              [-37.297223870090221, -8.943041323864165],
              [-37.297985924673121, -8.944541944750702],
              [-37.298488570236415, -8.945434908155477],
              [-37.298958456658674, -8.946086013547838],
              [-37.299285572019244, -8.946708630155101],
              [-37.299349277875649, -8.946949165111914],
              [-37.299441114834828, -8.947476948276476],
              [-37.299633892759879, -8.947841126857112],
              [-37.299768925673078, -8.947950148866683],
              [-37.300685781687001, -8.94847878877016],
              [-37.301485122331968, -8.948933643169916],
              [-37.302179099241187, -8.949235664049104],
              [-37.303137036647485, -8.949802574707947],
              [-37.304377186795087, -8.950511405946672],
              [-37.304906194564914, -8.950806807537482],
              [-37.304916243895811, -8.951181867121221],
              [-37.304796783828465, -8.951527035789924],
              [-37.304759987769827, -8.951837425400109],
              [-37.304823290570404, -8.95216585212088],
              [-37.304820658009, -8.952734219961945],
              [-37.304712087296039, -8.953272804675802],
              [-37.304628635445376, -8.953477507957224],
              [-37.304604031919609, -8.953700059516656],
              [-37.304231198734193, -8.954038207479341],
              [-37.303835145973828, -8.95430007393262],
              [-37.3030664206727, -8.954864929523302],
              [-37.302404718873085, -8.955225188778678],
              [-37.301375853749747, -8.955970493447214],
              [-37.300937158117534, -8.956530998557971],
              [-37.300403138020378, -8.957313728766261],
              [-37.300105164085643, -8.958027227570222],
              [-37.299789318932682, -8.958781660759914],
              [-37.29973527351077, -8.958998216454587],
              [-37.29978754829844, -8.959162524680327],
              [-37.299830043703217, -8.959528943120793],
              [-37.299825367286516, -8.959901004317953],
              [-37.299641782879775, -8.960093526228878],
              [-37.299700304965576, -8.960181688794906],
              [-37.299606930405204, -8.960301380713458],
              [-37.299446590980104, -8.960564324142666],
              [-37.299356586805281, -8.960909624839163],
              [-37.29934338340653, -8.961214261674691],
              [-37.299550438507012, -8.962310953068991],
              [-37.299595457546523, -8.962768206419378],
              [-37.299685806127435, -8.962982496135751],
              [-37.299917344594363, -8.963250171163372],
              [-37.301376905312125, -8.964941506473952],
              [-37.301562076952813, -8.965041969995006],
              [-37.301951481174818, -8.965579909904838],
              [-37.302727836153046, -8.9665503054393],
              [-37.303426935838225, -8.967025211037202],
              [-37.303823462045528, -8.967299501425451],
              [-37.304781173003839, -8.967925011126944],
              [-37.305233170526229, -8.968310886876544],
              [-37.305688455339222, -8.968623532505791],
              [-37.306255413706111, -8.969001145034643],
              [-37.307128184133063, -8.969517858397593],
              [-37.308538874858428, -8.970321223287808],
              [-37.309168404496049, -8.970710105514753],
              [-37.310289794635857, -8.970354870659451],
              [-37.310871013947732, -8.97019931913456],
              [-37.311317689941369, -8.969826346498364],
              [-37.312131696865009, -8.969033159850149],
              [-37.312282854787625, -8.968843413152104],
              [-37.31283760872752, -8.96803732087586],
              [-37.313424137201402, -8.967369073070062],
              [-37.31382967622303, -8.966969540296317],
              [-37.314409753785412, -8.966421382837821],
              [-37.315291329168403, -8.965669511899392],
              [-37.316578474863185, -8.96450931202566],
              [-37.317208582688281, -8.963973095688008],
              [-37.318312151971526, -8.963002489756885],
              [-37.318655034507564, -8.962769664085174],
              [-37.318971329981785, -8.962551366203027],
              [-37.319308129982097, -8.962359529494426],
              [-37.319739435868513, -8.962121244817208],
              [-37.320510385795785, -8.961713108025579],
              [-37.321033733954614, -8.961322889129551],
              [-37.321900054016886, -8.960679331542599],
              [-37.322526603420251, -8.960273464191284],
              [-37.322644990540098, -8.960159738193417],
              [-37.323831032975335, -8.958547854369693],
              [-37.324683557869854, -8.957375392891247],
              [-37.324954368391282, -8.957114399986459],
              [-37.326512142238862, -8.95571952819355],
              [-37.326771873617574, -8.955625483367786],
              [-37.32715386635126, -8.955536385780702],
              [-37.327396956224831, -8.955537484567644],
              [-37.328002410867626, -8.955553404792584],
              [-37.328905841626892, -8.95548863455828],
              [-37.329107974395136, -8.955425091059222],
              [-37.329806236879691, -8.955120611076984],
              [-37.330675127606959, -8.954553214518663],
              [-37.332906657416466, -8.952403985617826],
              [-37.33365183679912, -8.951824303542436],
              [-37.334018059211893, -8.951638442285134],
              [-37.334692626045104, -8.951358747983909],
              [-37.335475592652344, -8.950892030021093],
              [-37.335933874680016, -8.950548369105976],
              [-37.336425126731314, -8.950081802351747],
              [-37.337157063751199, -8.94949619213892],
              [-37.337566412603977, -8.949221160118062],
              [-37.337743848241921, -8.949079859329675],
              [-37.338223210359551, -8.94863520984774],
              [-37.338891155121161, -8.948027334185905],
              [-37.339032352214758, -8.94791736586267],
              [-37.339274729265206, -8.947749986747047],
              [-37.339387227688405, -8.947633297416978],
              [-37.33989623782044, -8.946822547411063],
              [-37.338122941163952, -8.942809501437308],
              [-37.338055558021601, -8.942724233819508],
              [-37.337364492845076, -8.941124370300168],
              [-37.33674159937862, -8.939436917082716],
              [-37.33650575747496, -8.938814732635567],
              [-37.336403114003929, -8.938383585487106],
              [-37.336424348968308, -8.937599948359479],
              [-37.336470712062443, -8.937122592303224],
              [-37.3372401245202, -8.933788952949882],
              [-37.337085761731309, -8.932742307244258],
              [-37.336965361118438, -8.93200344725726],
              [-37.33683431528668, -8.931662998120808],
              [-37.335655205467965, -8.928209288053056],
              [-37.334899438521283, -8.925939666943718],
              [-37.334855230461997, -8.925942398505933],
              [-37.334666686097691, -8.925937158038595],
              [-37.334634137050791, -8.92596777534674],
              [-37.334595834930646, -8.925967603528353],
              [-37.331066349947648, -8.925907806235079],
              [-37.330547597493016, -8.92530192802889],
              [-37.33001131549522, -8.924663742382563],
              [-37.329456217837802, -8.924275972325146],
              [-37.330161936637765, -8.922317622816783],
              [-37.329608759450352, -8.918916528359517],
              [-37.333709625540656, -8.919655683463827],
              [-37.333966673426033, -8.914948583029433],
              [-37.331685868833354, -8.91503210766945],
              [-37.327816730896849, -8.913560059599543],
              [-37.327433290768539, -8.915272285963566],
              [-37.323262259248494, -8.914752492774349],
              [-37.322546414891185, -8.91634455217959],
              [-37.319736981044549, -8.91541189716021],
              [-37.318391204254311, -8.915104771919429],
              [-37.31706793409306, -8.914547977260208],
              [-37.316691370730808, -8.914426149706655],
              [-37.314993456834955, -8.913973125156089],
              [-37.313946601753685, -8.913534766257504],
              [-37.313487789761481, -8.913359826182109],
              [-37.313060929201065, -8.913284644168995],
              [-37.312321773675876, -8.913208044111361],
              [-37.311117997275993, -8.912938894684487],
              [-37.310797557244982, -8.912786553769923],
              [-37.310690116080671, -8.912765556932804],
              [-37.310583952806326, -8.912787048082988],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0064000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DOS BOIS',
        municipio: 'CABROBO',
        area_hecta: '1109.1929',
        capacidade: 30.0,
        num_famili: 26.0,
        fase: 4.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2001',
        area_calc_: 1120.1659,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.388333887135161, -8.394794362065531],
              [-39.386321019738318, -8.394686712229463],
              [-39.383522072017037, -8.394563536377586],
              [-39.383148555389589, -8.394550512739787],
              [-39.381335722713828, -8.394463085011544],
              [-39.38022444200309, -8.398628668944999],
              [-39.377951479186542, -8.402537125618693],
              [-39.375685837703919, -8.406453884985144],
              [-39.373309425021333, -8.410563310388378],
              [-39.37146592863192, -8.413837436116742],
              [-39.371035838127511, -8.414626223719097],
              [-39.372398448390491, -8.416483999753424],
              [-39.373415348188395, -8.417874913410897],
              [-39.376069626836369, -8.421532619516661],
              [-39.378921513128255, -8.425356907924012],
              [-39.381648153809003, -8.428962219374986],
              [-39.384325295271182, -8.432525225969091],
              [-39.384376692063448, -8.432597446779594],
              [-39.387534901889659, -8.436806236123378],
              [-39.387794255555988, -8.437260139016939],
              [-39.388379317731605, -8.438296682026612],
              [-39.389046763082355, -8.439500116492388],
              [-39.389737072207232, -8.440761235156264],
              [-39.390550896358789, -8.442256771049594],
              [-39.392451375067459, -8.444623716908769],
              [-39.393675309850011, -8.446138281325995],
              [-39.396252613653623, -8.449297245365376],
              [-39.398859021474777, -8.452447386572267],
              [-39.39996644685079, -8.453782048211288],
              [-39.403482188962386, -8.45211349552506],
              [-39.403680428797863, -8.45107888535818],
              [-39.40476305622613, -8.449355657634888],
              [-39.403847754736852, -8.445389841748119],
              [-39.402637259410042, -8.440923584349443],
              [-39.401325287281082, -8.436422329463007],
              [-39.400895523936747, -8.435023207968449],
              [-39.400864550651733, -8.434938757617722],
              [-39.400277556951544, -8.433022411293138],
              [-39.399898805922454, -8.43184131549188],
              [-39.399208964908965, -8.430342687849949],
              [-39.397705490086366, -8.428773607668882],
              [-39.397313012671731, -8.427927013577575],
              [-39.397565836775058, -8.427230730344295],
              [-39.397876000029598, -8.426554016931393],
              [-39.397920986387064, -8.426129751968393],
              [-39.397925793342125, -8.425944592069619],
              [-39.397895689929385, -8.424576447158785],
              [-39.398734726058436, -8.419470943052668],
              [-39.398676721429901, -8.418800753186749],
              [-39.396747237021046, -8.414346861696773],
              [-39.394998357033664, -8.41013862498278],
              [-39.392748378389577, -8.404936991159119],
              [-39.391730577783761, -8.403674932743797],
              [-39.391921590930266, -8.403210361766615],
              [-39.390502785824793, -8.399880511666948],
              [-39.389084373911174, -8.396555267482411],
              [-39.388333887135161, -8.394794362065531],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0225000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOÃO DE OURICURI',
        municipio: 'OURICURI',
        area_hecta: '517.1600',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 4.0,
        data_de_cr: '10/05/2001',
        forma_obte: 'Desapropriação',
        data_obten: '08/08/2000',
        area_calc_: 521.8673,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.17291473667467, -7.77651944500465],
              [-40.173310376562661, -7.775316062493673],
              [-40.164404996864597, -7.772784416830665],
              [-40.164491135769246, -7.774361704133159],
              [-40.17291473667467, -7.77651944500465],
            ],
          ],
          [
            [
              [-40.17291473667467, -7.77651944500465],
              [-40.172815440480896, -7.776713981229938],
              [-40.172035631813216, -7.777506205816875],
              [-40.171596864846919, -7.777744004292876],
              [-40.171275897218088, -7.778050301059247],
              [-40.170545090180312, -7.779216894474386],
              [-40.170381058004978, -7.779578648938973],
              [-40.170368548008661, -7.779776201259792],
              [-40.170352558837045, -7.780027121926928],
              [-40.170329883600935, -7.780384868973801],
              [-40.170539753020769, -7.781052630974681],
              [-40.170170754134489, -7.781206851753224],
              [-40.169978060758879, -7.781206750095578],
              [-40.166831911464165, -7.781488900732405],
              [-40.166541386186211, -7.781066446657093],
              [-40.166206587532919, -7.780701452105755],
              [-40.164686656455196, -7.781041878158032],
              [-40.164677102717036, -7.782015839085234],
              [-40.164736153527201, -7.783632178728225],
              [-40.164941022212545, -7.788630705936641],
              [-40.165246146753553, -7.798396338694118],
              [-40.166434568224737, -7.801415343274503],
              [-40.166584528768901, -7.801797123409632],
              [-40.167024425894617, -7.802709520998114],
              [-40.167169550220365, -7.80327626096431],
              [-40.167212114407043, -7.803255613991847],
              [-40.16794318708002, -7.803247989386083],
              [-40.168897215091029, -7.803194709254113],
              [-40.168921839218072, -7.803212276735694],
              [-40.169891317525355, -7.803171432216331],
              [-40.187139337513194, -7.797931890463451],
              [-40.187315940156381, -7.79711139431099],
              [-40.187378420131573, -7.796468114805051],
              [-40.187314162119947, -7.796255494457764],
              [-40.186889500791686, -7.79535492782882],
              [-40.186389836669761, -7.794289340331462],
              [-40.186034432186858, -7.793539881011631],
              [-40.185881718230704, -7.79331195255326],
              [-40.185468923237124, -7.793081315223707],
              [-40.184682133482191, -7.792615589629767],
              [-40.184685338990896, -7.792594599036827],
              [-40.184568158217076, -7.79197425025734],
              [-40.184421068637711, -7.791133496958308],
              [-40.184254338815457, -7.790144389481093],
              [-40.184123798747912, -7.78964209934911],
              [-40.184005907719317, -7.789284655611822],
              [-40.18367313870241, -7.788519483873354],
              [-40.184431867606612, -7.787585942658852],
              [-40.184609788866645, -7.787297942865928],
              [-40.183809837709809, -7.786661684294946],
              [-40.183760416830658, -7.78582156176587],
              [-40.183680081472893, -7.785436248329693],
              [-40.183683204475926, -7.785095016584945],
              [-40.183837450393597, -7.784965802139515],
              [-40.184313249285253, -7.784418046628531],
              [-40.184550045660409, -7.784139016845362],
              [-40.185089839583348, -7.783518641083708],
              [-40.185770597232747, -7.782722511417234],
              [-40.17286799826109, -7.779247289455841],
              [-40.173506325438346, -7.776684578650302],
              [-40.17291473667467, -7.77651944500465],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0192000',
        uf: 'PE',
        nome_proje: 'PA MANDACARÚ I',
        municipio: 'SERRA TALHADA',
        area_hecta: '668.2096',
        capacidade: 16.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '15/10/2006',
        forma_obte: 'Desapropriação',
        data_obten: '15/10/2006',
        area_calc_: 674.0566,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.355196255646455, -7.914858782805264],
              [-38.367149181985731, -7.933459854827356],
              [-38.377448655937549, -7.930091969944368],
              [-38.377868264676252, -7.929966146279344],
              [-38.39622780915365, -7.923775896551613],
              [-38.387280521646275, -7.916875793437855],
              [-38.387084089467734, -7.916766149349117],
              [-38.38684541590731, -7.916632925774802],
              [-38.386714340441799, -7.916559738970528],
              [-38.376588641647928, -7.908575719179075],
              [-38.355196255646455, -7.914858782805264],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0224000',
        uf: 'PE',
        nome_proje: 'PA CANUDOS',
        municipio: 'IBIMIRIM',
        area_hecta: '629.2473',
        capacidade: 30.0,
        num_famili: 28.0,
        fase: 3.0,
        data_de_cr: '15/03/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/07/2006',
        area_calc_: 629.467,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.793426659900469, -8.487854038622052],
              [-37.7693327468047, -8.474765688064682],
              [-37.749747173827473, -8.48864922915366],
              [-37.756948306295385, -8.49865870436766],
              [-37.793426659900469, -8.487854038622052],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0308000',
        uf: 'PE',
        nome_proje: 'PA RIACHÃO DO CARIÉ',
        municipio: 'TUPANATINGA',
        area_hecta: '848.6317',
        capacidade: 29.0,
        num_famili: 27.0,
        fase: 3.0,
        data_de_cr: '28/04/2005',
        forma_obte: 'Desapropriação',
        data_obten: '30/07/2004',
        area_calc_: 833.2933,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.142301381856051, -8.834148339653625],
              [-37.131617244159202, -8.840007171150868],
              [-37.123148736463286, -8.84359065490454],
              [-37.116910246001837, -8.846239225460554],
              [-37.120271857568483, -8.857244427347032],
              [-37.121347488521089, -8.861891635673718],
              [-37.121480908307021, -8.862468064468107],
              [-37.122732627037429, -8.862372556177409],
              [-37.140731109423704, -8.861019263811603],
              [-37.141387747181255, -8.860952011761519],
              [-37.140168650370839, -8.865024807695736],
              [-37.13995335678009, -8.865744053754499],
              [-37.155939045161993, -8.861549511463632],
              [-37.152610044785384, -8.85015609368917],
              [-37.147451997748661, -8.852461953892782],
              [-37.144753831494789, -8.848380538052712],
              [-37.144058638137963, -8.844475859827483],
              [-37.142944435049614, -8.836005253606736],
              [-37.142682035442768, -8.835100747775977],
              [-37.142509333316617, -8.834642245604325],
              [-37.142301381856051, -8.834148339653625],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0344000',
        uf: 'PE',
        nome_proje: 'PA ILHA GRANDE',
        municipio: 'TUPANATINGA',
        area_hecta: '890.6053',
        capacidade: 32.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '16/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/12/2004',
        area_calc_: 890.2202,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.120267792532708, -8.857226864445035],
              [-37.116223247118562, -8.857551559555732],
              [-37.111900418269826, -8.85789865553766],
              [-37.107167261092215, -8.858278641625613],
              [-37.105386890202666, -8.858421549203765],
              [-37.104798623216595, -8.858468766551113],
              [-37.103743005404624, -8.858553493498059],
              [-37.101349217437679, -8.859256318467237],
              [-37.099014331876774, -8.859932198043836],
              [-37.095600556070345, -8.860275492159566],
              [-37.094946295034752, -8.860341266953762],
              [-37.088557347620458, -8.860983713047462],
              [-37.085069935976072, -8.861334350138266],
              [-37.084960283097878, -8.861344557760594],
              [-37.08129450187058, -8.861651564275771],
              [-37.081054310573059, -8.861922083734404],
              [-37.080501078306646, -8.861595238156836],
              [-37.080402430142371, -8.861153050567374],
              [-37.080330790131548, -8.860831993084295],
              [-37.079170081815164, -8.860000198302899],
              [-37.079316374875212, -8.859834969509539],
              [-37.078086439339117, -8.858717414106273],
              [-37.076433894052357, -8.858936281141629],
              [-37.074925501286764, -8.860257081424008],
              [-37.075394497643686, -8.860856878948276],
              [-37.073011403198755, -8.863134327968064],
              [-37.072429881834047, -8.863911234888313],
              [-37.071076449245155, -8.86438595704967],
              [-37.064321675252039, -8.866755124390602],
              [-37.05731568358668, -8.869200728223722],
              [-37.038946875663534, -8.875375829580465],
              [-37.042924929675827, -8.881279751208856],
              [-37.062676501641619, -8.876160641889028],
              [-37.067394639499973, -8.874937634233],
              [-37.072388473095408, -8.873643082219946],
              [-37.073230973191642, -8.873424672174639],
              [-37.074028675673183, -8.873217873381517],
              [-37.07633968740663, -8.872618748219512],
              [-37.081172025291323, -8.870992598675009],
              [-37.081359687567691, -8.870929446028622],
              [-37.082929919439373, -8.870733428330908],
              [-37.08464917275186, -8.870518799726351],
              [-37.085659784701505, -8.870392632767654],
              [-37.090550436508224, -8.869782032385606],
              [-37.094855291499442, -8.869244512883521],
              [-37.098793021459045, -8.868752787882746],
              [-37.101526142031098, -8.868411462919257],
              [-37.102088185735248, -8.868341269629175],
              [-37.104583542434369, -8.868029615257882],
              [-37.110437461098257, -8.867298428298049],
              [-37.116256220186358, -8.866571536668188],
              [-37.122225410711025, -8.865825753080534],
              [-37.121385035407954, -8.862053853894308],
              [-37.121364435979096, -8.861964855774863],
              [-37.120267792532708, -8.857226864445035],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0158000',
        uf: 'PE',
        nome_proje: 'PA MARIA OTILIA',
        municipio: 'INAJA',
        area_hecta: '1700.0000',
        capacidade: 50.0,
        num_famili: 48.0,
        fase: 3.0,
        data_de_cr: '08/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2005',
        area_calc_: 983.2475,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.955717884673611, -8.770860763855421],
              [-37.931732225242044, -8.749579164725914],
              [-37.916640850249621, -8.776616068394272],
              [-37.926641504891457, -8.780473295224736],
              [-37.931460478798094, -8.788427361667262],
              [-37.931701492388747, -8.788204212708107],
              [-37.931944655846003, -8.788119167270997],
              [-37.937277650318656, -8.788780414427192],
              [-37.954749834807771, -8.774939368393753],
              [-37.953582470182717, -8.773472306911229],
              [-37.955717884673611, -8.770860763855421],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0328000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DO CARMO',
        municipio: 'SERTANIA',
        area_hecta: '732.5237',
        capacidade: 16.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '23/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '24/09/2004',
        area_calc_: 732.3849,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.306109671087242, -8.358063222673252],
              [-37.303822252523936, -8.3587398629238],
              [-37.303439931070521, -8.359015981909222],
              [-37.302786193500957, -8.358862906629595],
              [-37.302766617619284, -8.358959929483559],
              [-37.302719373455453, -8.358925911216362],
              [-37.302676608053623, -8.358926903165129],
              [-37.302553797354101, -8.358921041746679],
              [-37.302549364574261, -8.358959359126089],
              [-37.302118117995207, -8.362673425645825],
              [-37.30170696918568, -8.366418226814984],
              [-37.300695572166219, -8.375588110260729],
              [-37.302532058164154, -8.376416352918412],
              [-37.303290756013688, -8.376805238069165],
              [-37.304795224798006, -8.377508265683922],
              [-37.305606503740911, -8.377895474254181],
              [-37.306631267890083, -8.378380704209938],
              [-37.307350561829246, -8.378708654347077],
              [-37.308273498923725, -8.379143172770942],
              [-37.309052659177638, -8.379506096749665],
              [-37.31022189212019, -8.380052788069083],
              [-37.311219549834014, -8.380510586466423],
              [-37.312530925668938, -8.38111023142047],
              [-37.313077151877728, -8.381360126858961],
              [-37.31392396064178, -8.381744851194277],
              [-37.314758501954664, -8.382132414960296],
              [-37.316069155347826, -8.382755193747069],
              [-37.31654926165038, -8.382969178906009],
              [-37.317487075810668, -8.383412242236133],
              [-37.318424664752968, -8.383844904861904],
              [-37.319365511068192, -8.384280744109374],
              [-37.319716281478776, -8.384442637547236],
              [-37.320325940846637, -8.384723717397845],
              [-37.321296746869166, -8.385182465431367],
              [-37.321409290865546, -8.385234481939072],
              [-37.321483593870141, -8.385269156505368],
              [-37.32241466147569, -8.38570051772183],
              [-37.323108100369105, -8.386115405593394],
              [-37.323147408226319, -8.386138086509202],
              [-37.323948925789239, -8.386672963812702],
              [-37.325918094579215, -8.387945808684892],
              [-37.326641262017212, -8.385648154510307],
              [-37.330979758818359, -8.387823049852814],
              [-37.331722026194853, -8.385500388707019],
              [-37.332870374515458, -8.381906907415477],
              [-37.333821314498785, -8.378931039633921],
              [-37.335034899573074, -8.375122625353763],
              [-37.336060861426162, -8.37189524844579],
              [-37.335130271027552, -8.371567271742116],
              [-37.330338505108571, -8.368376841007446],
              [-37.326561893598395, -8.367156874439774],
              [-37.32514469736703, -8.366699058254092],
              [-37.323644679096276, -8.366417018618],
              [-37.320914734269465, -8.36545460918574],
              [-37.317321225547332, -8.363981362204443],
              [-37.316843982980437, -8.363781861077065],
              [-37.316214469869031, -8.36373433438551],
              [-37.31325963428737, -8.364282685262895],
              [-37.312365515221529, -8.364098127586791],
              [-37.310241386005103, -8.363280462562786],
              [-37.309392494099697, -8.362756759657003],
              [-37.30856721648103, -8.361873570765582],
              [-37.308527198100528, -8.361679547101156],
              [-37.308778794773978, -8.36043215467407],
              [-37.308770517128636, -8.360098844781772],
              [-37.308162838700866, -8.359032227608717],
              [-37.306976877565099, -8.358210424669663],
              [-37.306109671087242, -8.358063222673252],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0420000',
        uf: 'PE',
        nome_proje: 'PA WILSON ALVES DA SILVA',
        municipio: 'BEZERROS',
        area_hecta: '524.8650',
        capacidade: 45.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '09/04/2018',
        forma_obte: 'Desapropriação',
        data_obten: '26/12/2013',
        area_calc_: 524.0418,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.800277475308086, -8.146444640685983],
              [-35.798795453126182, -8.148428783253777],
              [-35.796302587016278, -8.148668481830095],
              [-35.794521721266705, -8.149582639192998],
              [-35.794334478450033, -8.151262342494588],
              [-35.795020602275429, -8.15137477871707],
              [-35.795169293681731, -8.152034802224334],
              [-35.794754015045058, -8.152183051632823],
              [-35.796442271486853, -8.152729662900144],
              [-35.799772705401864, -8.152943259627698],
              [-35.799502560735782, -8.153941169512009],
              [-35.799161127911105, -8.153921447554636],
              [-35.798960224430779, -8.155400845546001],
              [-35.799318342830446, -8.155428053748322],
              [-35.799715570724032, -8.157364923987011],
              [-35.79890525403799, -8.158138587537678],
              [-35.798554977576728, -8.15818889722758],
              [-35.798524269183005, -8.158700097538214],
              [-35.799186146643081, -8.158661003202797],
              [-35.800650747681999, -8.158317286250353],
              [-35.80178883535708, -8.158063276360849],
              [-35.804945110365352, -8.159274854817955],
              [-35.805334158377839, -8.15913275653145],
              [-35.816556098977088, -8.155718781989718],
              [-35.817574582367143, -8.151298890339506],
              [-35.820752387282212, -8.152442336292657],
              [-35.822543010203745, -8.1456330254383],
              [-35.824308554857602, -8.148170976046636],
              [-35.825234676529, -8.146212522968195],
              [-35.825384930679036, -8.14480777762205],
              [-35.824392315780976, -8.139910365142045],
              [-35.823943944550365, -8.139961233313826],
              [-35.823504983483438, -8.139980406110261],
              [-35.823264188063966, -8.140071893679709],
              [-35.822989248134228, -8.140137945783627],
              [-35.822682221405373, -8.140169566529284],
              [-35.821799292263748, -8.140208874854419],
              [-35.821650106821799, -8.140304799736738],
              [-35.821479356915788, -8.140390141835459],
              [-35.821329893540437, -8.140451128065504],
              [-35.821144342494122, -8.140510023481159],
              [-35.820721916683524, -8.140612176742984],
              [-35.820522956194637, -8.140637982075928],
              [-35.82010444511662, -8.140675827209813],
              [-35.819785002760952, -8.140773602437498],
              [-35.819766579050452, -8.1407272281755],
              [-35.820080060524759, -8.14059438202557],
              [-35.819408535358015, -8.139931401801768],
              [-35.818366621218964, -8.137741070717476],
              [-35.817784778441521, -8.137383503328365],
              [-35.813906100297025, -8.136097529607943],
              [-35.812006887770394, -8.135685313788668],
              [-35.810362500395691, -8.135829464922695],
              [-35.807606426584556, -8.138222685803727],
              [-35.805478870434222, -8.139440441237918],
              [-35.804395674009172, -8.139270801057636],
              [-35.805408343124249, -8.141819086440218],
              [-35.801775092982339, -8.142380606496213],
              [-35.801612895935037, -8.14372384127231],
              [-35.800277475308086, -8.146444640685983],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0002000',
        uf: 'PE',
        nome_proje: 'PA CRUZ DO PONTAL',
        municipio: 'LAGOA GRANDE',
        area_hecta: '670.0454',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 5.0,
        data_de_cr: '08/03/1989',
        forma_obte: 'Adjudicação',
        data_obten: '12/09/1989',
        area_calc_: 675.3493,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.16643056697513, -9.008953513999067],
              [-40.165744053286225, -9.009889322618301],
              [-40.159786717674159, -9.017977614847663],
              [-40.159819342754581, -9.018024537317109],
              [-40.161025815883832, -9.02029386317775],
              [-40.162126902681962, -9.022373425354086],
              [-40.163252113436172, -9.02445408170542],
              [-40.164369840079111, -9.026494332851945],
              [-40.165500521275888, -9.028598473997617],
              [-40.166591102621254, -9.030618905677096],
              [-40.167746212748746, -9.032757775390978],
              [-40.168888670115535, -9.034833195824779],
              [-40.169940243269046, -9.036818558058544],
              [-40.170121651187777, -9.037131872632898],
              [-40.171188152367812, -9.039115373111576],
              [-40.172296748101218, -9.04114322729494],
              [-40.173376757444125, -9.043160496771515],
              [-40.174452018277719, -9.045140156505438],
              [-40.175598472567899, -9.047243928191557],
              [-40.176711008179659, -9.049235847562084],
              [-40.177880581130211, -9.051306345346916],
              [-40.1789740146695, -9.053290809823492],
              [-40.180079062736368, -9.055485126054013],
              [-40.181178187450151, -9.05750474037364],
              [-40.183862992917177, -9.062451317087076],
              [-40.187333281713599, -9.068777120657021],
              [-40.187613319152447, -9.068639839294802],
              [-40.188336836506174, -9.068074638039848],
              [-40.189141027883799, -9.067558097184547],
              [-40.190170735668403, -9.067008717575119],
              [-40.191264852098627, -9.066459125669075],
              [-40.191972643925531, -9.066039203763447],
              [-40.193163978100806, -9.065617794176655],
              [-40.194438887169504, -9.065297211297153],
              [-40.192419174063168, -9.059973635432575],
              [-40.19229449617275, -9.059713235040716],
              [-40.191596334673385, -9.058358301280917],
              [-40.189092869906403, -9.053576963530498],
              [-40.188000541345637, -9.051446303561789],
              [-40.186908263021508, -9.049326671080827],
              [-40.185808635355755, -9.047153159515648],
              [-40.184636609252721, -9.044868915978721],
              [-40.183528358104383, -9.042692075515923],
              [-40.182418333091533, -9.040524730934033],
              [-40.18129820216209, -9.038350450311832],
              [-40.1801854777827, -9.036155340995119],
              [-40.179055232014896, -9.033938759657051],
              [-40.178081156026693, -9.031933646243886],
              [-40.177907328729418, -9.031574552725688],
              [-40.176959050411362, -9.029805924094243],
              [-40.175948819479196, -9.027864944111307],
              [-40.174924148289577, -9.025843339815026],
              [-40.173981904892706, -9.02398994429627],
              [-40.172931093503244, -9.021894531037944],
              [-40.171946091229202, -9.019948298636738],
              [-40.170908148837015, -9.017914601022618],
              [-40.169915850361505, -9.015983395222067],
              [-40.16886792396317, -9.013920600883091],
              [-40.16643056697513, -9.008953513999067],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0391000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA NOSSA SENHORA DAS GRACAS',
        municipio: 'LAJEDO',
        area_hecta: '792.1751',
        capacidade: 37.0,
        num_famili: 37.0,
        fase: 3.0,
        data_de_cr: '13/06/2011',
        forma_obte: 'Desapropriação',
        data_obten: '22/10/2008',
        area_calc_: 990.0661,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.221940377796614, -8.677581170616005],
              [-36.2218887431914, -8.677614133815567],
              [-36.221737472966289, -8.677688113084871],
              [-36.221584133433964, -8.677771835053466],
              [-36.221431201368055, -8.677886550126766],
              [-36.221278793663934, -8.677991691779358],
              [-36.221114870284097, -8.678070005688062],
              [-36.220916914423249, -8.678145993423186],
              [-36.220692093447958, -8.67819576429982],
              [-36.22045936739655, -8.678246290558015],
              [-36.220227060374015, -8.678289230275215],
              [-36.219955588881348, -8.678373264782424],
              [-36.219707323973424, -8.678462437533996],
              [-36.219486198643501, -8.678589845483275],
              [-36.219318307784754, -8.678689000416917],
              [-36.219189414113643, -8.67875744949167],
              [-36.21902529188857, -8.678862685203532],
              [-36.218830505079609, -8.678989110170869],
              [-36.218637059826932, -8.679105967653737],
              [-36.218501689750092, -8.679215388192498],
              [-36.218373770175162, -8.679299474589675],
              [-36.218191690816347, -8.679426443549586],
              [-36.218027145251824, -8.6795520947169],
              [-36.217850216122933, -8.679707109586822],
              [-36.217640132594788, -8.679876157707518],
              [-36.217470512648866, -8.680024991481703],
              [-36.217313412849741, -8.680138228200594],
              [-36.21714700158455, -8.680233597873411],
              [-36.218828110825754, -8.683787054405952],
              [-36.220215151859989, -8.685379661256292],
              [-36.222542202665188, -8.689114013899184],
              [-36.223574145970851, -8.690608437851045],
              [-36.218590184169891, -8.690477289916425],
              [-36.217977960276123, -8.689485832231483],
              [-36.216719838063959, -8.690110445817202],
              [-36.215764903044615, -8.68995980883499],
              [-36.213857037525635, -8.692484145386631],
              [-36.213825323259648, -8.693691258707641],
              [-36.212824659068566, -8.696174696597788],
              [-36.211594416295661, -8.697469701778202],
              [-36.208736167127064, -8.696631263392934],
              [-36.211300841182826, -8.709483873526064],
              [-36.2127654864505, -8.709711368272256],
              [-36.211704823866512, -8.713539742788399],
              [-36.210526426824657, -8.713674294505399],
              [-36.209695432861501, -8.714570601440107],
              [-36.211011610727155, -8.717003083724649],
              [-36.21116572216178, -8.720501106185228],
              [-36.205653548785648, -8.722250831485812],
              [-36.207693544743485, -8.726209957381233],
              [-36.20217491214018, -8.734878651038247],
              [-36.206218511949359, -8.741831489194572],
              [-36.209478595450356, -8.738735946151264],
              [-36.211761506051111, -8.736566208514809],
              [-36.215390549300068, -8.733104762964341],
              [-36.218549524434351, -8.730336774058562],
              [-36.22128416879054, -8.727693842399354],
              [-36.222313820052975, -8.726670035857067],
              [-36.228813401470333, -8.720433677713213],
              [-36.229232692731642, -8.715355764570113],
              [-36.227913198981838, -8.71516525267001],
              [-36.228059316543614, -8.713082647982578],
              [-36.215588962166144, -8.716891306368201],
              [-36.213568806447981, -8.714790581111965],
              [-36.222947258836783, -8.707332176489926],
              [-36.230341117408592, -8.701228972744147],
              [-36.230422574947383, -8.699116796438096],
              [-36.230305463058997, -8.697223721400436],
              [-36.231037886337973, -8.696161553146414],
              [-36.23452940786531, -8.693895087532503],
              [-36.234673957168873, -8.693686070611177],
              [-36.232128681169705, -8.690935803241901],
              [-36.221940377796614, -8.677581170616005],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0267000',
        uf: 'PE',
        nome_proje: 'PA HAVAI',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '404.0326',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 3.0,
        data_de_cr: '29/09/2008',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/2006',
        area_calc_: 405.0794,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.049683944954353, -8.450734968993602],
              [-40.047129131653712, -8.458258130776326],
              [-40.048315525603243, -8.45866138747942],
              [-40.052471546186311, -8.458714439898477],
              [-40.055144822917676, -8.45781641358472],
              [-40.059253701361399, -8.458541121743004],
              [-40.062027472892701, -8.461145295094955],
              [-40.064660229784295, -8.460613501181685],
              [-40.067807408615558, -8.462666381060366],
              [-40.070236100737716, -8.460966187178663],
              [-40.07173505083459, -8.461391123009443],
              [-40.076154588864924, -8.455946527591752],
              [-40.076203290814547, -8.455886523372246],
              [-40.084305808083748, -8.445904187981062],
              [-40.071250018727866, -8.447715236055167],
              [-40.071152049224906, -8.447728979351416],
              [-40.049683944954353, -8.450734968993602],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0359000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA GRANDE',
        municipio: 'TUPANATINGA',
        area_hecta: '603.4238',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 4.0,
        data_de_cr: '09/08/2006',
        forma_obte: 'Desapropriação',
        data_obten: '24/09/2004',
        area_calc_: 602.9788,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.18290501877879, -8.857282100358841],
              [-37.182761873026443, -8.857332756845535],
              [-37.179377775277182, -8.859742587029134],
              [-37.176972726207325, -8.86145931291361],
              [-37.175842362731331, -8.862267209281994],
              [-37.174938205168615, -8.862911528277056],
              [-37.174715380601413, -8.863161306189031],
              [-37.168898845092421, -8.870287009990538],
              [-37.178609460976958, -8.871500009455064],
              [-37.182779983443019, -8.872020891334516],
              [-37.186991826844675, -8.872546871858038],
              [-37.187533088400983, -8.872614461842913],
              [-37.190787091646342, -8.873020788535488],
              [-37.19133377010121, -8.87308904935831],
              [-37.193578250928105, -8.873369297198288],
              [-37.197602383384925, -8.873871717350202],
              [-37.198530428351987, -8.873987579034866],
              [-37.2021527428276, -8.874439784306304],
              [-37.206003491393645, -8.874920468204593],
              [-37.208906364041738, -8.875282817754515],
              [-37.211667665793435, -8.875627466930915],
              [-37.214090230479982, -8.875929785224216],
              [-37.216333898192794, -8.876209764358071],
              [-37.216458955390813, -8.876225355886866],
              [-37.216273718367809, -8.877347451375311],
              [-37.218257282381124, -8.877583312613405],
              [-37.220708697280877, -8.877793548325805],
              [-37.221901021354562, -8.876648219956758],
              [-37.222561525860321, -8.875805398240306],
              [-37.222157641282251, -8.875593813795764],
              [-37.222721477562516, -8.874981425688656],
              [-37.223417479547514, -8.874225486562723],
              [-37.223568808714475, -8.873438372178208],
              [-37.224459454941439, -8.873527232536562],
              [-37.224668268839096, -8.872184548286505],
              [-37.224498052414887, -8.871038874019352],
              [-37.222551310158835, -8.86878518180187],
              [-37.222200170012798, -8.868833044793428],
              [-37.221877567561407, -8.868877017581518],
              [-37.221693776058522, -8.868902069408357],
              [-37.220989245521508, -8.866958875769393],
              [-37.21685014040424, -8.866915481079602],
              [-37.216759726803282, -8.866901823918692],
              [-37.214554044099685, -8.866772133408421],
              [-37.212489807607298, -8.866764252989505],
              [-37.212534478094938, -8.865459764141265],
              [-37.208916491052676, -8.865330836585768],
              [-37.205839612225972, -8.86522113618379],
              [-37.205290837490857, -8.865201559644669],
              [-37.202461526520956, -8.865100616422243],
              [-37.199770340118533, -8.865004581370515],
              [-37.199976356526719, -8.866380244348278],
              [-37.197635311627145, -8.866254767151565],
              [-37.197022833442006, -8.866221936649701],
              [-37.195473061501495, -8.866138860166336],
              [-37.191698879705378, -8.865936516138937],
              [-37.186609241812135, -8.865663586949937],
              [-37.186598783330204, -8.863683977488234],
              [-37.186589093478595, -8.861850202056475],
              [-37.186580540883021, -8.860231922399786],
              [-37.186567089198981, -8.857687183271716],
              [-37.185494353681179, -8.857725376602017],
              [-37.185303367284341, -8.85770489166573],
              [-37.184642417921275, -8.857633998405078],
              [-37.18290501877879, -8.857282100358841],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0015000',
        uf: 'PE',
        nome_proje: 'PA MALHADA DO RIACHÃO',
        municipio: 'IGUARACI',
        area_hecta: '310.7846',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '28/07/1987',
        forma_obte: 'Desapropriação',
        data_obten: '15/03/1987',
        area_calc_: 646.8831,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.292213242816956, -7.71329440297213],
              [-37.291214429474664, -7.713286437299893],
              [-37.29114450447323, -7.713367750632899],
              [-37.291003522938446, -7.713481593078874],
              [-37.290925061981142, -7.71349905138355],
              [-37.290878869277762, -7.713466877709457],
              [-37.290847214650618, -7.713363675160695],
              [-37.290797701405957, -7.713271064309197],
              [-37.290712556573659, -7.713178311093015],
              [-37.29057376456506, -7.713135104402013],
              [-37.290477445046704, -7.713163154320891],
              [-37.290359346892203, -7.713290638913593],
              [-37.290212971773983, -7.713361140897893],
              [-37.290098850665878, -7.713385565224653],
              [-37.290045531733433, -7.713353363003731],
              [-37.289953346431318, -7.713239255452888],
              [-37.289875528034159, -7.713096770552115],
              [-37.289779536924051, -7.713043071658273],
              [-37.289638792186771, -7.713042509032923],
              [-37.289460305825052, -7.713123545425244],
              [-37.289285496839888, -7.71317616165587],
              [-37.289061017870289, -7.713175264007521],
              [-37.288847556999251, -7.713092660387876],
              [-37.288761955515866, -7.713113644076962],
              [-37.288676211147077, -7.713170170621604],
              [-37.288582968928289, -7.713319080091525],
              [-37.288550400366148, -7.713443351846068],
              [-37.288436222074608, -7.713481992856458],
              [-37.288297430367706, -7.713438785447053],
              [-37.288219462502006, -7.713333620363815],
              [-37.288173470186578, -7.713251686447713],
              [-37.288048931218469, -7.713208536006361],
              [-37.287927598077765, -7.71325425693966],
              [-37.287852485890582, -7.713325043205057],
              [-37.287748310864934, -7.713534332491351],
              [-37.287651848230333, -7.713597924567322],
              [-37.287480616434024, -7.713646999953828],
              [-37.287387845600669, -7.713678617653382],
              [-37.287249068316399, -7.713631855629518],
              [-37.286935381680571, -7.713662588731236],
              [-37.286693079793196, -7.713663395581439],
              [-37.286525797388123, -7.713616519189983],
              [-37.286372381336022, -7.713665665458717],
              [-37.286136969709077, -7.713725146191348],
              [-37.285912090086278, -7.713823766722066],
              [-37.285558492916103, -7.714032055763184],
              [-37.285333448393459, -7.714171550140324],
              [-37.285123050171876, -7.714213358805606],
              [-37.284877321091841, -7.714180384624311],
              [-37.284674378624366, -7.714140473196976],
              [-37.284450193364087, -7.714066710295361],
              [-37.28431838522031, -7.714059073012606],
              [-37.284246792576717, -7.714140535603832],
              [-37.284157126218524, -7.714285903737522],
              [-37.284078235241033, -7.714409989032887],
              [-37.283960364400585, -7.714480602904734],
              [-37.28386062460838, -7.714473094101112],
              [-37.283807320532731, -7.71443733690027],
              [-37.283786271201464, -7.714355502742589],
              [-37.283743627955069, -7.714326896969366],
              [-37.283686660428344, -7.714316005403048],
              [-37.283572610734986, -7.714322656464219],
              [-37.283408203801308, -7.714446398460669],
              [-37.283243631921941, -7.714611014552586],
              [-37.283133044957367, -7.7146425597095],
              [-37.28299067632026, -7.714602890598143],
              [-37.28290197017354, -7.714510121844806],
              [-37.282820103589913, -7.714488467214334],
              [-37.282706039537807, -7.714498672306239],
              [-37.282427582619441, -7.714629064631631],
              [-37.282091971568526, -7.714794770525725],
              [-37.281866897157123, -7.714941371281688],
              [-37.281731224471578, -7.715008358587433],
              [-37.281680967135884, -7.715100569303555],
              [-37.281648396431294, -7.71522484004947],
              [-37.281594661989693, -7.715295710792222],
              [-37.281455569517959, -7.715327140932641],
              [-37.281334422022439, -7.715326654150772],
              [-37.28094212256908, -7.715412158704289],
              [-37.280649139225524, -7.715610023481727],
              [-37.280480895080636, -7.715801280878672],
              [-37.280351946140534, -7.715967815879951],
              [-37.280119622188138, -7.716144598084976],
              [-37.279994351095091, -7.716282713083845],
              [-37.279924610752005, -7.716493322711073],
              [-37.279935477994336, -7.716645281030796],
              [-37.279930395036367, -7.716857993693984],
              [-37.279878357598776, -7.716933346749445],
              [-37.279806979203272, -7.716961494253059],
              [-37.279767540029987, -7.717021759170701],
              [-37.279752899353554, -7.717117667113095],
              [-37.279677799669756, -7.71718489721173],
              [-37.279585002650506, -7.717222732892928],
              [-37.279497040256551, -7.717223563758054],
              [-37.279424580677798, -7.717127437027243],
              [-37.279474243828567, -7.717051811211598],
              [-37.279479483380221, -7.71693098517235],
              [-37.279427405578055, -7.716885754168439],
              [-37.279332349361496, -7.716894849897672],
              [-37.279275089493858, -7.716956227831903],
              [-37.279186325877788, -7.717171499780026],
              [-37.279113648455827, -7.71732503065386],
              [-37.279124350539895, -7.717419855758082],
              [-37.279127283001451, -7.717477921554283],
              [-37.279062034544303, -7.717556643989114],
              [-37.279052053522939, -7.717675081355948],
              [-37.278993260964988, -7.717723815435079],
              [-37.278761654141888, -7.71782082458734],
              [-37.278563016039449, -7.718062410149821],
              [-37.278388898419514, -7.718237055791736],
              [-37.278122061219229, -7.718430284329048],
              [-37.27793587894999, -7.718651087327576],
              [-37.277601996755799, -7.719048715158359],
              [-37.277326753444491, -7.719264419841117],
              [-37.276848105788211, -7.719553945199304],
              [-37.276632853066666, -7.719693917470472],
              [-37.276386150440281, -7.719900850609858],
              [-37.276224024368751, -7.720286978062878],
              [-37.276028408340139, -7.720397012830991],
              [-37.275785453802975, -7.720704666301596],
              [-37.275620304704354, -7.720937104186837],
              [-37.275588981688294, -7.721045976938895],
              [-37.275752350815573, -7.721179330632626],
              [-37.275868239528783, -7.721305384109764],
              [-37.275860700016906, -7.72140724419426],
              [-37.275784367995882, -7.721485131293458],
              [-37.275503793687541, -7.7215503465491],
              [-37.2753322169944, -7.721683533539546],
              [-37.275248508535611, -7.721822998920457],
              [-37.275277288864352, -7.722048221922154],
              [-37.275330318888201, -7.722151511177551],
              [-37.275308564812718, -7.722243835674094],
              [-37.275165603954441, -7.722349888227346],
              [-37.274994324807437, -7.722409620276474],
              [-37.274730459884964, -7.722454761188851],
              [-37.274462685546979, -7.722585189747574],
              [-37.274374238947317, -7.722675634274995],
              [-37.274384668927496, -7.722739654099028],
              [-37.274474270145483, -7.722806758102259],
              [-37.27454377122254, -7.722851270250767],
              [-37.274564089414909, -7.722917699543271],
              [-37.274513015134538, -7.723015434487891],
              [-37.274380443727743, -7.723195379445379],
              [-37.274151416894277, -7.723436147966104],
              [-37.273929494576514, -7.72368227648388],
              [-37.273847223078022, -7.723760139059979],
              [-37.273689179225869, -7.723839563396298],
              [-37.273674680201012, -7.723886558702056],
              [-37.273714640752502, -7.72399560215098],
              [-37.273742848219101, -7.72406917197901],
              [-37.273702195624679, -7.724135354947493],
              [-37.27360710904312, -7.724151557537099],
              [-37.273405173964981, -7.724155480589984],
              [-37.273288813927017, -7.724145532155148],
              [-37.273116942450436, -7.724204668453782],
              [-37.273066783017057, -7.724271997678658],
              [-37.273009092803022, -7.724438817244941],
              [-37.273011760722717, -7.724659195504985],
              [-37.273028717623212, -7.724870745747931],
              [-37.273063425677691, -7.725098362159602],
              [-37.273045118539663, -7.725219134833456],
              [-37.272941438290701, -7.725304019206933],
              [-37.272837519867288, -7.725410968949141],
              [-37.272818024893517, -7.725531736791989],
              [-37.272817543428488, -7.725650211966026],
              [-37.272726504486329, -7.725839407229976],
              [-37.272674888558072, -7.725972781447994],
              [-37.272534021954606, -7.726147557881992],
              [-37.272407620089126, -7.726270262823181],
              [-37.272380410818016, -7.726535541456381],
              [-37.272289718234845, -7.726639434439393],
              [-37.27216626922938, -7.726666031194265],
              [-37.272060197767594, -7.726708703589728],
              [-37.272031450969614, -7.726767825824794],
              [-37.272059581125603, -7.726860351744945],
              [-37.272037517565643, -7.727028499916643],
              [-37.272006163986802, -7.727144480580295],
              [-37.271915427802682, -7.727259036190388],
              [-37.271891287380029, -7.727353720153632],
              [-37.271850277546243, -7.727507574380034],
              [-37.271800122257545, -7.727573718558726],
              [-37.27174307176827, -7.727582965848731],
              [-37.271697880837884, -7.727569758180023],
              [-37.271558158892098, -7.72748980539415],
              [-37.27140179062409, -7.727387282251935],
              [-37.271330699267303, -7.727344342762791],
              [-37.27127368737419, -7.727344111987501],
              [-37.271211741326368, -7.727388882493742],
              [-37.271047158436765, -7.727553787836035],
              [-37.270836305055688, -7.727705769450177],
              [-37.270746920144987, -7.727780048010355],
              [-37.270621960621895, -7.727839965244395],
              [-37.270546872036554, -7.727903638717852],
              [-37.270499927721275, -7.7280562838834],
              [-37.270452578226333, -7.728308448049714],
              [-37.270380691040501, -7.728460992152963],
              [-37.270315833825791, -7.728637260099795],
              [-37.270322391147786, -7.728777089465311],
              [-37.270312435711183, -7.728888417473511],
              [-37.270276446208882, -7.728975944624203],
              [-37.270114521215476, -7.729071254949192],
              [-37.269940887515055, -7.729125050821565],
              [-37.269779208505369, -7.729159938747679],
              [-37.269731544015372, -7.729197658212526],
              [-37.269724012155883, -7.729297148294836],
              [-37.269777980423207, -7.729461457535762],
              [-37.269840278061245, -7.729689170117053],
              [-37.269883372652068, -7.729831532621664],
              [-37.269940066362203, -7.729909957135408],
              [-37.270032190057968, -7.730038285529051],
              [-37.27002478849203, -7.730105787386432],
              [-37.269952669091751, -7.730315199290586],
              [-37.269814569905797, -7.730465993874471],
              [-37.269744807484486, -7.730534131544934],
              [-37.269715577381177, -7.73071172842758],
              [-37.269725581689805, -7.730782611431312],
              [-37.268279068950335, -7.730631203181982],
              [-37.266717084807077, -7.730406837107659],
              [-37.266400317588676, -7.730363530390783],
              [-37.266091780099927, -7.730278342151988],
              [-37.265775779528376, -7.730261704584693],
              [-37.265407322672822, -7.730274538045027],
              [-37.265013111037426, -7.730311832891958],
              [-37.264321466516954, -7.730309021418014],
              [-37.263638207414395, -7.730263251786037],
              [-37.2631416879413, -7.730224382466368],
              [-37.262768427199035, -7.730157353426399],
              [-37.261619578032949, -7.73003803395776],
              [-37.261371335511853, -7.730014503917489],
              [-37.261081937056908, -7.730017419641317],
              [-37.260564425776487, -7.730093104788724],
              [-37.260277029429744, -7.730108311274781],
              [-37.259966186200622, -7.730085548908822],
              [-37.259881320295641, -7.730062185391643],
              [-37.258902241852923, -7.733912019441355],
              [-37.257819962653961, -7.738073635063026],
              [-37.257474175485399, -7.739311795974232],
              [-37.258063481422226, -7.739248693608458],
              [-37.258305697504575, -7.739241494228836],
              [-37.258636631338021, -7.739246428358738],
              [-37.258837711366631, -7.739260556193397],
              [-37.259018200283883, -7.739290977406123],
              [-37.259387102252546, -7.739421456481436],
              [-37.259604253113203, -7.739520608061901],
              [-37.260034181709976, -7.739784403269735],
              [-37.260611489204329, -7.740145017479244],
              [-37.260827186569209, -7.740348569512992],
              [-37.26095608503946, -7.740447360566471],
              [-37.261376064049223, -7.740635368032579],
              [-37.261747112957188, -7.74074333509334],
              [-37.262060560555405, -7.740883822183751],
              [-37.262779429153404, -7.741259341579671],
              [-37.262565097025607, -7.741724205036478],
              [-37.262401695873763, -7.742532181033726],
              [-37.262273084202832, -7.743364864990246],
              [-37.262956899058423, -7.743530404538805],
              [-37.263617342383057, -7.743639550000287],
              [-37.264097256935521, -7.743727487263593],
              [-37.26489544816171, -7.743779870389631],
              [-37.265420474899017, -7.743878225906227],
              [-37.265769026971057, -7.74396767392945],
              [-37.266342969398892, -7.744150162611664],
              [-37.266796095400366, -7.744264601685914],
              [-37.266943786502146, -7.744285674325367],
              [-37.267733645123592, -7.744368726558271],
              [-37.268880200962613, -7.744557633812431],
              [-37.270103041013513, -7.744662912568101],
              [-37.271118728457317, -7.744732545076359],
              [-37.271943490183837, -7.744813684242881],
              [-37.273153960672367, -7.74493323483661],
              [-37.275958629282606, -7.745194350964854],
              [-37.276662958638909, -7.743598329357509],
              [-37.277643656908758, -7.743692371024705],
              [-37.278822437155213, -7.743782092504141],
              [-37.279237431878741, -7.742799570897663],
              [-37.280381459979203, -7.739943718423417],
              [-37.28152970326876, -7.736929216988217],
              [-37.28245760070039, -7.734345272612877],
              [-37.283603582221524, -7.731119374064401],
              [-37.284621187249734, -7.728167279975031],
              [-37.285677736292428, -7.7282759311292],
              [-37.286872791245273, -7.729283353978364],
              [-37.286955073013594, -7.729135669282446],
              [-37.289514485404283, -7.72079199235996],
              [-37.290250185515319, -7.718447062212412],
              [-37.2914056040565, -7.717139454275475],
              [-37.292646918497347, -7.71569497432144],
              [-37.293547212190227, -7.71433101782475],
              [-37.293610570652923, -7.713884974446647],
              [-37.293676859207572, -7.713219889092613],
              [-37.293023772286375, -7.713330393304636],
              [-37.292213242816956, -7.71329440297213],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0293000',
        uf: 'PE',
        nome_proje: 'PA OURO',
        municipio: 'BELO JARDIM',
        area_hecta: '186.2764',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 4.0,
        data_de_cr: '25/02/2003',
        forma_obte: 'Desapropriação',
        data_obten: '26/08/2002',
        area_calc_: 159.3921,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.413795697858667, -8.368181936970343],
              [-36.413825579574763, -8.370074565618989],
              [-36.413757366467337, -8.370840688923689],
              [-36.413274850692893, -8.372442237591409],
              [-36.412804350793856, -8.373336754251198],
              [-36.412755979199055, -8.373420205972971],
              [-36.412663474298206, -8.37357737768335],
              [-36.412587998298108, -8.373519410713737],
              [-36.412028812923701, -8.373759280273854],
              [-36.411409543933857, -8.37423442747604],
              [-36.411173385198502, -8.374338335172355],
              [-36.410601851974924, -8.374592760278365],
              [-36.410119928949129, -8.37492196203592],
              [-36.409758639205641, -8.374943447973605],
              [-36.408954852987719, -8.374738737299021],
              [-36.408685724306125, -8.374778719446359],
              [-36.408199603545718, -8.37485089259628],
              [-36.409179600703538, -8.376167167611364],
              [-36.409865032136246, -8.378501911977075],
              [-36.409809111330233, -8.378615224240335],
              [-36.408620526697099, -8.378924782317624],
              [-36.409481936917331, -8.380815906548991],
              [-36.410661551883507, -8.381478617849634],
              [-36.411446115286509, -8.380284530140553],
              [-36.411617253200816, -8.38002413597879],
              [-36.412006386703844, -8.379868368825836],
              [-36.412350182015018, -8.379885985950345],
              [-36.412727102249349, -8.38017744620999],
              [-36.413192255315764, -8.380875948192736],
              [-36.414002785146927, -8.38139453784134],
              [-36.414688297868338, -8.381552562179166],
              [-36.415391300319563, -8.381508281365795],
              [-36.415633402724005, -8.381933862629266],
              [-36.415991207034253, -8.382125251510059],
              [-36.416629650875251, -8.382004909805385],
              [-36.416982388732698, -8.382551401608499],
              [-36.417757622691347, -8.382878631460636],
              [-36.418018318337417, -8.382508844928218],
              [-36.420601895573263, -8.37884279846449],
              [-36.420654654626709, -8.378768230035568],
              [-36.421311345183476, -8.377847265536614],
              [-36.423151266162833, -8.375264603735666],
              [-36.423603402477646, -8.375475848795007],
              [-36.424894568999918, -8.373681920538848],
              [-36.424092711362768, -8.372919692763771],
              [-36.423853263995802, -8.372737579119194],
              [-36.423694610988704, -8.372660546111314],
              [-36.423097107830195, -8.372339371998669],
              [-36.422285761893619, -8.371903030633375],
              [-36.421339492924709, -8.371396857949934],
              [-36.420855464170344, -8.371137871269404],
              [-36.419570495809374, -8.37047568085632],
              [-36.419415765071619, -8.370395870926856],
              [-36.417967179884684, -8.369653899202159],
              [-36.417797647849135, -8.369562063809395],
              [-36.416555319832035, -8.368889120693284],
              [-36.415994855573921, -8.3687325564264],
              [-36.414946109932494, -8.368440411187505],
              [-36.413795697858667, -8.368181936970343],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0206000',
        uf: 'PE',
        nome_proje: 'PA SERRA DOS CAVALOS',
        municipio: 'ITAIBA',
        area_hecta: '1267.9000',
        capacidade: 52.0,
        num_famili: 51.0,
        fase: 3.0,
        data_de_cr: '28/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '23/12/1998',
        area_calc_: 1054.8186,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.260698295745392, -8.976470890439458],
              [-37.258752074804228, -8.977279850179885],
              [-37.258721754904251, -8.977307011421765],
              [-37.25871075456228, -8.977325493868772],
              [-37.258524054797384, -8.97740399445968],
              [-37.257051930598934, -8.978561816419957],
              [-37.256843517787935, -8.978883329386083],
              [-37.255012080675044, -8.981527612049222],
              [-37.253327695739429, -8.983936332367653],
              [-37.252015491609797, -8.982038176256705],
              [-37.25095152757762, -8.980638454790729],
              [-37.25076737188607, -8.980413362758057],
              [-37.250678947759376, -8.980325877875547],
              [-37.250523328972029, -8.980237079978119],
              [-37.249006282787803, -8.979758664363663],
              [-37.248364020907935, -8.979585915763217],
              [-37.246876253973355, -8.979071467012188],
              [-37.246787419858506, -8.979069598533469],
              [-37.240431078013607, -8.986200137471322],
              [-37.236423496612424, -8.990417172210467],
              [-37.236366710185884, -8.990482176793737],
              [-37.234324140341307, -8.992572719144865],
              [-37.232825361297316, -8.994099615194129],
              [-37.230712761070357, -8.996183374510116],
              [-37.230173508204082, -8.996705252880798],
              [-37.229616619602659, -8.997244223289043],
              [-37.229052786302198, -8.99793929705962],
              [-37.230020649013341, -8.999229126100632],
              [-37.230966873918952, -9.000425546754684],
              [-37.23190617006523, -9.001851393447168],
              [-37.234475978991533, -9.00510519836803],
              [-37.236167732327523, -9.007321216234397],
              [-37.236421829790558, -9.007544662290913],
              [-37.241487882919138, -9.011816749236987],
              [-37.241674247720724, -9.011981102214056],
              [-37.242540118970595, -9.012675705068446],
              [-37.242771942670466, -9.012748779455169],
              [-37.24308784463252, -9.012793323991184],
              [-37.244534201537405, -9.012830698522301],
              [-37.24559933717228, -9.012847082118517],
              [-37.246305207491737, -9.013248978584611],
              [-37.247212008011381, -9.014341393940867],
              [-37.247369281218702, -9.014711923236371],
              [-37.248320097236849, -9.015956888928462],
              [-37.248482394300439, -9.016209998452545],
              [-37.248812669619262, -9.016915057702166],
              [-37.249061536821493, -9.017245609440414],
              [-37.250311511191171, -9.018540999689003],
              [-37.251282716937126, -9.016064114756967],
              [-37.251476457930913, -9.015169516504809],
              [-37.251451420056888, -9.015043093453913],
              [-37.251789612068109, -9.013404015041784],
              [-37.252699175469488, -9.012506850043392],
              [-37.253487882392385, -9.011739842387255],
              [-37.253851901577512, -9.010514422075554],
              [-37.254102786910352, -9.009667467052111],
              [-37.254145985360047, -9.009553663733628],
              [-37.254145799973408, -9.009440739494138],
              [-37.254233928231002, -9.009477593300852],
              [-37.255154473213096, -9.006382577943109],
              [-37.256296815107518, -9.003019363027292],
              [-37.257648570044317, -9.001973104614542],
              [-37.257808309926993, -9.001792133873144],
              [-37.259792268374405, -9.000652206372589],
              [-37.26057391692779, -9.000325716749662],
              [-37.260574248930403, -9.000313331956775],
              [-37.263448886145383, -8.999111224557121],
              [-37.265426809144692, -8.998430522980749],
              [-37.268146036786561, -8.993587846084607],
              [-37.268254296657688, -8.993349396547503],
              [-37.269679383576928, -8.99092184206534],
              [-37.269631455001914, -8.990884367446057],
              [-37.268648340588165, -8.989290855333396],
              [-37.268579042803609, -8.989176611104051],
              [-37.266670414047859, -8.986082616291471],
              [-37.264657831524346, -8.982863812215093],
              [-37.26260021787072, -8.979560075634488],
              [-37.260698295745392, -8.976470890439458],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0285000',
        uf: 'PE',
        nome_proje: 'PA NOVA ESPERANÇA II',
        municipio: 'GRANITO',
        area_hecta: '993.1688',
        capacidade: 22.0,
        num_famili: 22.0,
        fase: 3.0,
        data_de_cr: '01/12/2009',
        forma_obte: 'Desapropriação',
        data_obten: '13/03/2009',
        area_calc_: 995.4654,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.710516313817422, -7.737776587669283],
              [-39.721753785876039, -7.722549533806385],
              [-39.702972105562807, -7.70943755816553],
              [-39.696910630504888, -7.705225139069332],
              [-39.698037964242246, -7.703604146244868],
              [-39.695354678842016, -7.701738440418162],
              [-39.692904973855633, -7.699973552511204],
              [-39.687677313233458, -7.696285604548899],
              [-39.68724662339163, -7.696120860210071],
              [-39.687091223309721, -7.695903846117479],
              [-39.686754099321533, -7.695620731987405],
              [-39.685411443404213, -7.69631546247631],
              [-39.679276536266144, -7.691890950479912],
              [-39.674136275480151, -7.688238497775192],
              [-39.673732250711247, -7.687898221497943],
              [-39.666933964871696, -7.683069600004006],
              [-39.666092433724089, -7.684280149530513],
              [-39.664909513782732, -7.685602391127396],
              [-39.659844356442747, -7.68995033427068],
              [-39.662960101184815, -7.693967181461801],
              [-39.663180127654641, -7.693826822708549],
              [-39.663458232116938, -7.69365869593312],
              [-39.663725290044397, -7.69350071663357],
              [-39.663988897566192, -7.693339757629007],
              [-39.664243986871597, -7.693182158364925],
              [-39.664511944291675, -7.693019926016619],
              [-39.664787880250188, -7.69285740980126],
              [-39.665063358681977, -7.692692361497049],
              [-39.665338742836184, -7.692525142347239],
              [-39.665601441947715, -7.692363912500765],
              [-39.665871380811183, -7.692193807046642],
              [-39.666158279107812, -7.692026207748289],
              [-39.666437836250431, -7.691860880959178],
              [-39.666719924219507, -7.691690575251784],
              [-39.667016866072785, -7.691509934842626],
              [-39.667320242552918, -7.691327475216226],
              [-39.667625063009915, -7.69114085239058],
              [-39.667937858926713, -7.690952046126227],
              [-39.668243497982871, -7.69076741155723],
              [-39.668543062879898, -7.690583509863044],
              [-39.668848784835184, -7.690394261760838],
              [-39.6691510828026, -7.690218948293336],
              [-39.669453192786754, -7.690039564619379],
              [-39.669754749681886, -7.689854573638525],
              [-39.670062754633264, -7.689676356243719],
              [-39.670358434079546, -7.689502047300137],
              [-39.670468589108289, -7.68943711217405],
              [-39.67070740428484, -7.689716685649804],
              [-39.677834664874801, -7.698056386066011],
              [-39.678903821438666, -7.697160405070539],
              [-39.681845715072235, -7.700904297891889],
              [-39.683387158238709, -7.702818589125642],
              [-39.687394113914962, -7.707969236188291],
              [-39.694086764749635, -7.716420919169707],
              [-39.698400817523385, -7.721945313840564],
              [-39.690697162689588, -7.728203452136322],
              [-39.697145912189136, -7.732831100593769],
              [-39.698682231562138, -7.731348275431997],
              [-39.709505703678353, -7.739098753609333],
              [-39.710516313817422, -7.737776587669283],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0086000',
        uf: 'PE',
        nome_proje: 'PA MANOEL RODRIGUES',
        municipio: 'CABROBO',
        area_hecta: '453.3427',
        capacidade: 13.0,
        num_famili: 11.0,
        fase: 4.0,
        data_de_cr: '17/06/2004',
        forma_obte: 'Confisco',
        data_obten: '17/06/2004',
        area_calc_: 453.7004,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.289387974843699, -8.449777743399054],
              [-39.285394722746901, -8.454090749491222],
              [-39.282403349390222, -8.457280385308433],
              [-39.281302936501795, -8.458450732640275],
              [-39.280634987453467, -8.459160995445959],
              [-39.279108808068159, -8.460783634725397],
              [-39.27854450452692, -8.462371483681933],
              [-39.277653316008617, -8.466364342052721],
              [-39.277062816853736, -8.467928147032847],
              [-39.287557753537662, -8.475945166525298],
              [-39.288941170566169, -8.472666592902884],
              [-39.300534171370593, -8.467231232223931],
              [-39.301288856144403, -8.46628469786689],
              [-39.295453885963006, -8.450745490502797],
              [-39.289387974843699, -8.449777743399054],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0006000',
        uf: 'PE',
        nome_proje: 'PA VALE DO PAJEÚ',
        municipio: 'AFOGADOS DA INGAZEIRA',
        area_hecta: '821.5987',
        capacidade: 29.0,
        num_famili: 26.0,
        fase: 7.0,
        data_de_cr: '16/09/1986',
        forma_obte: 'Compra e Venda',
        data_obten: '25/10/1984',
        area_calc_: 823.5217,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.571294548833968, -7.787178602033674],
              [-37.571091114662451, -7.787615701219185],
              [-37.570450815874786, -7.789404631133404],
              [-37.570228274863787, -7.790061389665926],
              [-37.569804401839299, -7.791518131416169],
              [-37.568160318866717, -7.7925213133395],
              [-37.566586456895642, -7.793977490143594],
              [-37.566550891726379, -7.794128846332187],
              [-37.564208449089456, -7.793861238539744],
              [-37.562157592498401, -7.793741090723086],
              [-37.561345570169671, -7.793993845613587],
              [-37.559405192421849, -7.794268563368524],
              [-37.559049711210541, -7.794260695860475],
              [-37.558429645590316, -7.795971430110001],
              [-37.5584045810212, -7.79671124682261],
              [-37.559390650892418, -7.798519831339196],
              [-37.559242003060923, -7.799037007325526],
              [-37.559053815921651, -7.799402572551549],
              [-37.558809313645071, -7.799641438443464],
              [-37.55858512471606, -7.799797144750906],
              [-37.558030097921076, -7.800058256452602],
              [-37.557384519411514, -7.80044223687359],
              [-37.557126708844933, -7.800667739576454],
              [-37.557012722259508, -7.800810503980927],
              [-37.556955586271094, -7.800923499933361],
              [-37.560901684261253, -7.802271924581146],
              [-37.561331952189434, -7.802864311203378],
              [-37.56132710114791, -7.805747335144181],
              [-37.561233441263497, -7.808486487108605],
              [-37.560802410867041, -7.812625643383477],
              [-37.560990469948166, -7.81278691503055],
              [-37.561220299625766, -7.812935844551895],
              [-37.561418570252371, -7.813039723080579],
              [-37.561678609432491, -7.813140482644532],
              [-37.562207067964891, -7.813332042434916],
              [-37.562483701904689, -7.813461155778429],
              [-37.562796948340342, -7.813622020399693],
              [-37.562960191187074, -7.813719120887223],
              [-37.563196869406958, -7.813818135626065],
              [-37.56341693628945, -7.813893789739324],
              [-37.563591999777373, -7.813950980443782],
              [-37.563733663968016, -7.81401305127068],
              [-37.563983485044481, -7.814173699550582],
              [-37.564124560661213, -7.814408051702481],
              [-37.564182425146207, -7.814570544348538],
              [-37.564238287797295, -7.814830407785874],
              [-37.564244253251985, -7.815038499844207],
              [-37.56421006880506, -7.815273088631011],
              [-37.564180812015039, -7.815530998193287],
              [-37.564239843108822, -7.815718463433388],
              [-37.564354668962018, -7.815941490510158],
              [-37.564495753754414, -7.816173345831853],
              [-37.564648647687427, -7.816368620623963],
              [-37.564809932879662, -7.816550607300631],
              [-37.564996232354773, -7.816739337225396],
              [-37.565287488012125, -7.816986683758028],
              [-37.56572303367529, -7.817016460839461],
              [-37.565978296589222, -7.817050619125201],
              [-37.56624350047953, -7.817106450492778],
              [-37.566501976825933, -7.817177240053144],
              [-37.567050382104597, -7.817395495961377],
              [-37.567337180130586, -7.817482194633462],
              [-37.567660792861254, -7.817541552480727],
              [-37.568019559647517, -7.81757106691426],
              [-37.568326770684337, -7.817545475240383],
              [-37.568578815277235, -7.817544665016707],
              [-37.568777198093422, -7.817616914190245],
              [-37.569018711897463, -7.817767544569624],
              [-37.569188716818076, -7.817840529711088],
              [-37.56940972111466, -7.817887054318679],
              [-37.569610204279016, -7.817832802352862],
              [-37.569776515510625, -7.817765117931599],
              [-37.570010627800343, -7.817638570495427],
              [-37.57019407789678, -7.817439442231917],
              [-37.570395769294812, -7.81727533174971],
              [-37.570557941377338, -7.817197645474962],
              [-37.570776546714875, -7.817213366509185],
              [-37.571186734283906, -7.817337933249397],
              [-37.571564976516406, -7.817530639335224],
              [-37.57187970710639, -7.817748099064188],
              [-37.572221868332107, -7.818243636159269],
              [-37.572768907491444, -7.819111066975963],
              [-37.572906823958441, -7.819294636935052],
              [-37.573079832137367, -7.819466673403208],
              [-37.573289652112912, -7.819612200932422],
              [-37.573502997146846, -7.819702809137541],
              [-37.573806583526967, -7.819762095059349],
              [-37.574217068798553, -7.81980010223609],
              [-37.574594323874877, -7.819793053146666],
              [-37.575050220905062, -7.819729673370285],
              [-37.57524207097098, -7.819760283287056],
              [-37.575502130180077, -7.819857706533599],
              [-37.575896967005143, -7.820080427933674],
              [-37.578197923985016, -7.811940044396779],
              [-37.578959279904119, -7.808415349567664],
              [-37.580653077701065, -7.808522578745331],
              [-37.58114204688497, -7.808547524149254],
              [-37.583014147616311, -7.809237948140283],
              [-37.583834839473759, -7.80939217562175],
              [-37.583621699661521, -7.810232078645645],
              [-37.583405282183776, -7.811053659927508],
              [-37.58315834809995, -7.812014963986396],
              [-37.5833151893438, -7.812032135652909],
              [-37.583598670655832, -7.812112986064677],
              [-37.583995854285149, -7.81213429211541],
              [-37.584663738503764, -7.812066616677691],
              [-37.584847779071197, -7.811937395052684],
              [-37.585142160833641, -7.811755275884119],
              [-37.585376252351224, -7.811632879886315],
              [-37.585595153632283, -7.811560370583985],
              [-37.585790832776254, -7.811444504121524],
              [-37.585919825419168, -7.811305110183557],
              [-37.586050733450875, -7.811092480645132],
              [-37.586121513888187, -7.810891302044406],
              [-37.586258391828984, -7.810392382882108],
              [-37.586357810025298, -7.810113064238066],
              [-37.586510606144358, -7.809843911568411],
              [-37.586674649395988, -7.809704634457087],
              [-37.586860295909659, -7.809593727515857],
              [-37.58711426970806, -7.809514676062113],
              [-37.587361432759316, -7.809475551861324],
              [-37.587632282095747, -7.809341624950769],
              [-37.588006700137093, -7.809183074886674],
              [-37.588207194904228, -7.809123819175289],
              [-37.588387548511996, -7.809097787941349],
              [-37.588646478925, -7.809033733337404],
              [-37.588945590883299, -7.808933191647347],
              [-37.589276167686201, -7.808657972524436],
              [-37.58962990784859, -7.808443587957567],
              [-37.589848862851191, -7.808354430272474],
              [-37.590044310698566, -7.808306808889522],
              [-37.590379545348156, -7.808384497943243],
              [-37.590684881196886, -7.80841880766875],
              [-37.591191029236953, -7.808298979139922],
              [-37.592062857464825, -7.808137088329054],
              [-37.592572359990207, -7.808012275401476],
              [-37.592846294821193, -7.807953261718543],
              [-37.593239048344209, -7.80780142584348],
              [-37.593501527486467, -7.807674125177076],
              [-37.593921003068381, -7.807517383674384],
              [-37.594300464264208, -7.807347191917422],
              [-37.594661587242001, -7.80717028043721],
              [-37.595302383788265, -7.806215266481193],
              [-37.596858668924405, -7.806017351729311],
              [-37.59651085003992, -7.80520886787054],
              [-37.595917828644872, -7.804603483281569],
              [-37.595913418526585, -7.804424524448613],
              [-37.595872692441169, -7.803874239907901],
              [-37.594560437418799, -7.803973087375335],
              [-37.594021156165127, -7.802521432355577],
              [-37.59423361446332, -7.802377318411953],
              [-37.594965339321803, -7.801931140002017],
              [-37.595149055823249, -7.801896793442871],
              [-37.596718062689824, -7.801382645022806],
              [-37.5966195601859, -7.800889597395953],
              [-37.596356571721799, -7.800670662922848],
              [-37.596103820104567, -7.800385178384854],
              [-37.595916230686598, -7.799829412832936],
              [-37.595772765710755, -7.799555942917068],
              [-37.595574121336533, -7.799312252658822],
              [-37.59307746423692, -7.80021782463565],
              [-37.591613437626791, -7.801283289684252],
              [-37.589175794848238, -7.801508213014015],
              [-37.587970769336849, -7.801986093183528],
              [-37.585951888424702, -7.801767948438048],
              [-37.586409735622297, -7.800124030112113],
              [-37.586439293790249, -7.800020091899133],
              [-37.587348794329728, -7.796584082019855],
              [-37.588039607403807, -7.794399111758068],
              [-37.586728222469965, -7.794248253983443],
              [-37.585491834728067, -7.794130934585192],
              [-37.583544259605397, -7.794062836281427],
              [-37.583362193257344, -7.794103842121248],
              [-37.583139926937939, -7.794187160426143],
              [-37.580742096008315, -7.793755495096976],
              [-37.578920676985845, -7.793399825223894],
              [-37.579231777268689, -7.792215683234112],
              [-37.579606991466463, -7.791321393674956],
              [-37.580473776789304, -7.789176980620408],
              [-37.571294548833968, -7.787178602033674],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0078000',
        uf: 'PE',
        nome_proje: 'PA NOVA VIDA',
        municipio: 'PARNAMIRIM',
        area_hecta: '698.4575',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '11/03/2003',
        forma_obte: 'Doação',
        data_obten: '11/03/2003',
        area_calc_: 693.9502,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.854733005716369, -8.204613037549001],
              [-39.85207166739734, -8.200821687366663],
              [-39.852299344712634, -8.200310189833584],
              [-39.852803100495798, -8.199209942270498],
              [-39.85349778547446, -8.197694146145718],
              [-39.853536099829796, -8.197611488463073],
              [-39.853567399293723, -8.197517178175589],
              [-39.854484348742723, -8.196786789863232],
              [-39.855571036340542, -8.195879128783076],
              [-39.856568191990064, -8.195047538929867],
              [-39.857450978214757, -8.194308263353257],
              [-39.854180834441905, -8.193023369085452],
              [-39.852490679632581, -8.192365516167285],
              [-39.849166498953181, -8.191056000450285],
              [-39.845799818369834, -8.189729811709171],
              [-39.841585699724327, -8.188078670207041],
              [-39.837366642882792, -8.186438797086083],
              [-39.83372189143369, -8.189477720470645],
              [-39.834684867245549, -8.192615194501276],
              [-39.832855705831321, -8.193592532633465],
              [-39.830454524129877, -8.194871864109437],
              [-39.833522067468955, -8.196098947427322],
              [-39.836691427087665, -8.197342798078839],
              [-39.83986158762761, -8.198613844271211],
              [-39.84267478506969, -8.199740082737566],
              [-39.84599156369886, -8.201084392944608],
              [-39.847632266360598, -8.201738937273852],
              [-39.851026382846257, -8.203108364647736],
              [-39.851260812613432, -8.20320934928144],
              [-39.854733005716369, -8.204613037549001],
            ],
          ],
          [
            [
              [-39.825286532373092, -8.218669930146339],
              [-39.828600506829822, -8.218561818847657],
              [-39.831689885246526, -8.21597376066655],
              [-39.834457479118413, -8.213643396188955],
              [-39.837232848606362, -8.211313542084897],
              [-39.840005649160723, -8.208986480936735],
              [-39.838789099079015, -8.207614695063009],
              [-39.839688747044057, -8.206834536273597],
              [-39.841202315349854, -8.207980218413475],
              [-39.840891672533729, -8.208245963018141],
              [-39.841968985470025, -8.209538531075768],
              [-39.844420670738465, -8.206835408751012],
              [-39.847928925581805, -8.208242154223868],
              [-39.850787411628851, -8.206719267518084],
              [-39.853990094861246, -8.205015737549099],
              [-39.851713411028641, -8.204088064620281],
              [-39.84755031383559, -8.202392397130163],
              [-39.843381729157421, -8.200705467665172],
              [-39.839638101340356, -8.199183393686992],
              [-39.839411635247586, -8.199760818001165],
              [-39.8359056912402, -8.198369448875228],
              [-39.832477902096308, -8.19700800391918],
              [-39.829061545595394, -8.195633751077647],
              [-39.827575064691736, -8.196442767008346],
              [-39.827494562880524, -8.196496385709768],
              [-39.825336043746866, -8.198325193868106],
              [-39.827337970389209, -8.200714381213354],
              [-39.82937267121158, -8.20309281595657],
              [-39.827342185264087, -8.204815913448202],
              [-39.826550944188597, -8.205481591097099],
              [-39.822749637659541, -8.208708434101709],
              [-39.819290109138137, -8.211644671752238],
              [-39.822293583729376, -8.215169107413598],
              [-39.825286532373092, -8.218669930146339],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0400000',
        uf: 'PE',
        nome_proje: 'PA JOSUE DE CASTRO',
        municipio: 'RIACHO DAS ALMAS',
        area_hecta: '140.7753',
        capacidade: 13.0,
        num_famili: 13.0,
        fase: 3.0,
        data_de_cr: '03/07/2013',
        forma_obte: 'Desapropriação',
        data_obten: '06/05/2010',
        area_calc_: 140.7067,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.960276320509934, -8.138281354384565],
              [-35.959245665640857, -8.138707916501527],
              [-35.957849558655141, -8.139285723652291],
              [-35.958098622606876, -8.140986008782344],
              [-35.956744449723047, -8.141290178911426],
              [-35.956868225242971, -8.14156977557397],
              [-35.958987735781136, -8.144435885312152],
              [-35.959241940230633, -8.144819691647445],
              [-35.95999894806053, -8.147823054274195],
              [-35.96010753723138, -8.148253871389102],
              [-35.960919693988451, -8.150619064830652],
              [-35.961315391415177, -8.1517714250471],
              [-35.961353046281374, -8.151881084355658],
              [-35.961573948821773, -8.152524400928302],
              [-35.96223330255242, -8.1525104267042],
              [-35.962624539072607, -8.152502134394176],
              [-35.962953302224719, -8.152495165917081],
              [-35.963011128373729, -8.152493940201404],
              [-35.963787660381051, -8.152477479627525],
              [-35.96611906052636, -8.152428050602028],
              [-35.968799012076403, -8.152371215039089],
              [-35.96886020025093, -8.151226889858842],
              [-35.968892667766099, -8.150619678996728],
              [-35.968895605382954, -8.150564739071525],
              [-35.968994760491924, -8.148710277053384],
              [-35.968999751920272, -8.148616922047081],
              [-35.969410505058136, -8.145471112022367],
              [-35.969521602665175, -8.144620234721369],
              [-35.969807961629535, -8.142383226014836],
              [-35.967927235184618, -8.141739376131389],
              [-35.966268230905719, -8.141171422900396],
              [-35.965782142295502, -8.141112138458071],
              [-35.965269297393569, -8.141049590128711],
              [-35.964553282394654, -8.141121928483948],
              [-35.963198055396127, -8.141258842193288],
              [-35.962105495776754, -8.141369216065268],
              [-35.961127518967203, -8.139865215748973],
              [-35.961028457569959, -8.139840680287406],
              [-35.960276320509934, -8.138281354384565],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0362000',
        uf: 'PE',
        nome_proje: 'PA SANTA ROSA II',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '486.1346',
        capacidade: 148.0,
        num_famili: 81.0,
        fase: 3.0,
        data_de_cr: '29/09/2006',
        forma_obte: 'Compra e Venda',
        data_obten: '04/04/1984',
        area_calc_: 486.5269,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.081186561023941, -8.053391204919139],
              [-35.081391884030246, -8.052068889678825],
              [-35.081387248962976, -8.051459036427426],
              [-35.081799929424406, -8.050552355293142],
              [-35.081849538858044, -8.047912587695048],
              [-35.08189802496112, -8.047136548298253],
              [-35.08198426282469, -8.04675730550014],
              [-35.081892410728514, -8.046185775188828],
              [-35.081316414042583, -8.042793840329336],
              [-35.081275160603035, -8.041859157118679],
              [-35.081100643568938, -8.040727412428007],
              [-35.081097139590938, -8.040636516820634],
              [-35.080659240382232, -8.036744611159952],
              [-35.080569682798476, -8.03572683433365],
              [-35.080393680685269, -8.033704901414181],
              [-35.080373632894336, -8.033051941353364],
              [-35.080102928176927, -8.030940330742512],
              [-35.079902046392135, -8.02970302945533],
              [-35.079859162603221, -8.02956573965862],
              [-35.079600050047361, -8.028162057434187],
              [-35.079292824174303, -8.027086569229652],
              [-35.076454805960992, -8.029210098345803],
              [-35.075404117218355, -8.030210148362888],
              [-35.074312620893203, -8.030808866900809],
              [-35.073470869956388, -8.031194191937946],
              [-35.070991933221677, -8.032792373702046],
              [-35.070656181020432, -8.032984226388793],
              [-35.070587474388354, -8.033075486372582],
              [-35.069017799325216, -8.034802512650916],
              [-35.069869374424421, -8.034852174676201],
              [-35.069671003487009, -8.036346032501401],
              [-35.06930735143569, -8.038982097686164],
              [-35.069291927166503, -8.039088241728971],
              [-35.069049058317084, -8.04081001303269],
              [-35.068916238670482, -8.041974002913724],
              [-35.068871082396072, -8.042137875652021],
              [-35.068369002394455, -8.045356868191],
              [-35.068102338069551, -8.047332940841482],
              [-35.068089286264083, -8.047457255826357],
              [-35.067944874794996, -8.048808625557905],
              [-35.067769439786723, -8.050562541200527],
              [-35.067717704558582, -8.051302615180782],
              [-35.067583326981207, -8.052234404070248],
              [-35.067478118772577, -8.052997476148843],
              [-35.067333782583063, -8.053914163028022],
              [-35.066980972290608, -8.056889589764635],
              [-35.066829375487103, -8.057723733238477],
              [-35.066798752326989, -8.057830711689517],
              [-35.06666086836362, -8.059120114207893],
              [-35.065890949184798, -8.06453414390611],
              [-35.065875428561917, -8.064621348217397],
              [-35.065640747435253, -8.066309367704475],
              [-35.067226201549921, -8.066334702619006],
              [-35.070306606080877, -8.066377477623709],
              [-35.071272521681806, -8.066327893978452],
              [-35.071429871274177, -8.066411565471126],
              [-35.071913838830504, -8.065476195970353],
              [-35.073170563899779, -8.063049637489121],
              [-35.073422679639421, -8.062626370448264],
              [-35.073682436656277, -8.062136394664043],
              [-35.074087659358128, -8.061253238513995],
              [-35.074626454801951, -8.060179245131893],
              [-35.075203281676743, -8.059184987382517],
              [-35.075791393989029, -8.058541068464701],
              [-35.076426442687627, -8.05798449533234],
              [-35.078275496240373, -8.056122763294416],
              [-35.078357225950718, -8.05605113348002],
              [-35.078454581846444, -8.055913512192156],
              [-35.078864684275494, -8.055542476141426],
              [-35.079018128275798, -8.05537312954184],
              [-35.079143957342886, -8.055229302927536],
              [-35.079828816222189, -8.054554207304673],
              [-35.079986185753043, -8.054409462989559],
              [-35.080048966038724, -8.054348535656208],
              [-35.080768352137333, -8.05373690340771],
              [-35.081186561023941, -8.053391204919139],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0337000',
        uf: 'PE',
        nome_proje: 'PA SANTA ROSA',
        municipio: 'ALAGOINHA',
        area_hecta: '535.9400',
        capacidade: 35.0,
        num_famili: 33.0,
        fase: 3.0,
        data_de_cr: '01/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '24/12/2004',
        area_calc_: 538.1835,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.757030912667076, -8.41160569323541],
              [-36.755606716760184, -8.412739336580552],
              [-36.753621701106589, -8.413640817323433],
              [-36.750249704145496, -8.415089233605732],
              [-36.747238103609391, -8.415304366009062],
              [-36.745483495737048, -8.415836081078041],
              [-36.746706931270715, -8.419223558660276],
              [-36.738596487912112, -8.424055994274774],
              [-36.738674882976419, -8.424296059480342],
              [-36.738746917944326, -8.424521535889617],
              [-36.73884178955759, -8.424816108471886],
              [-36.738939946259286, -8.425123354047223],
              [-36.739042028704638, -8.425442372445852],
              [-36.739154175708862, -8.42577898374242],
              [-36.739274053974583, -8.426144201610368],
              [-36.739406426607161, -8.426546278629623],
              [-36.739540537125677, -8.42696174283142],
              [-36.73968263089381, -8.427393703319773],
              [-36.739824753741608, -8.427820692757145],
              [-36.739964375560156, -8.428256344885551],
              [-36.740111258180306, -8.428707856440367],
              [-36.740259190373031, -8.429166243416017],
              [-36.740410871726418, -8.429619951926062],
              [-36.740563953155316, -8.430082707145493],
              [-36.740714429208467, -8.43054083768728],
              [-36.740848040956081, -8.430948706769172],
              [-36.740976423382534, -8.431319216236357],
              [-36.741091529549394, -8.431553075639151],
              [-36.743082828955117, -8.430151708805628],
              [-36.746800084791133, -8.42693615094989],
              [-36.751149025792067, -8.423256498613094],
              [-36.75594109674438, -8.424402548524535],
              [-36.757127395711166, -8.428203874116083],
              [-36.762638193870444, -8.428346691069786],
              [-36.765288344521956, -8.431278513293208],
              [-36.766340666145986, -8.43075013445322],
              [-36.770408942631121, -8.428923667975349],
              [-36.770806806785359, -8.428354856983695],
              [-36.775132675356147, -8.432256884489078],
              [-36.778321543007223, -8.427530288635028],
              [-36.77784256742472, -8.419220153559667],
              [-36.777436308561846, -8.419784409991486],
              [-36.775491318588656, -8.420906236674821],
              [-36.774771031156057, -8.420094265633431],
              [-36.774168248432339, -8.42053004672219],
              [-36.770581983916706, -8.419727668743036],
              [-36.77136394171427, -8.41735878582115],
              [-36.770554077965883, -8.416514757266016],
              [-36.769095131434575, -8.416093042153349],
              [-36.766420225525145, -8.415444047520248],
              [-36.764525987137191, -8.415568767951484],
              [-36.762981756679231, -8.415569290405077],
              [-36.757030912667076, -8.41160569323541],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0279000',
        uf: 'PE',
        nome_proje: 'PA SERRA D ÁGUA',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '419.5000',
        capacidade: 42.0,
        num_famili: 40.0,
        fase: 3.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/07/2001',
        area_calc_: 427.8501,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.188626514491524, -8.843457592223601],
              [-35.187203561591602, -8.843486666809687],
              [-35.186973919214608, -8.843487826996997],
              [-35.185759341668678, -8.843643606647522],
              [-35.18401402678289, -8.843829323390384],
              [-35.183664426763123, -8.843877189069534],
              [-35.183576430758151, -8.843889805195516],
              [-35.181670341509232, -8.844103936172104],
              [-35.180445524173528, -8.844247610896049],
              [-35.175952535744877, -8.844753755423342],
              [-35.181596291368933, -8.848406939937419],
              [-35.182292182857893, -8.848872355099857],
              [-35.183048754500888, -8.850385546702338],
              [-35.183252272348149, -8.850888078923079],
              [-35.183380091395321, -8.851026080200509],
              [-35.183422777220763, -8.851106096517446],
              [-35.18438278825495, -8.852853576911913],
              [-35.185546726941269, -8.855167005548987],
              [-35.186382688604674, -8.85705563746564],
              [-35.187016524988643, -8.858081133054158],
              [-35.188511767547624, -8.861255834833962],
              [-35.18864060326375, -8.861492404764274],
              [-35.188911127552537, -8.861989990579424],
              [-35.188972166821195, -8.86208134226834],
              [-35.190530266069757, -8.86491383736295],
              [-35.190975410984827, -8.865612704911072],
              [-35.192512373589366, -8.866990923897367],
              [-35.191791935213317, -8.867843378841007],
              [-35.191732130755042, -8.867914272455042],
              [-35.191845270369846, -8.867959426886303],
              [-35.192125901718583, -8.868582842570461],
              [-35.192009617492104, -8.869563470225332],
              [-35.193999692332135, -8.870392810946228],
              [-35.194063111001455, -8.87032540645321],
              [-35.19550673540725, -8.867840178559993],
              [-35.19787905610319, -8.863757438326342],
              [-35.198181334886371, -8.863239897422796],
              [-35.19851540516197, -8.862670666386258],
              [-35.199148094493644, -8.861586437543814],
              [-35.202873492410092, -8.854204326832573],
              [-35.204238431991207, -8.851455486942649],
              [-35.203170798798936, -8.850974050808784],
              [-35.203007818958248, -8.847771522574352],
              [-35.20296464511393, -8.846325065718419],
              [-35.199748146612784, -8.846337902581718],
              [-35.196820215771083, -8.846378223223711],
              [-35.193892329281944, -8.846335011039136],
              [-35.193329032747286, -8.845649804976983],
              [-35.194755852975106, -8.844809773112992],
              [-35.194395962745816, -8.843589335129838],
              [-35.192120061214538, -8.845376806919814],
              [-35.191987437008343, -8.845346715633466],
              [-35.189870495930201, -8.844665568159403],
              [-35.189178644294202, -8.844444997492621],
              [-35.188626514491524, -8.843457592223601],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0251000',
        uf: 'PE',
        nome_proje: 'PA SOLEDADE',
        municipio: 'IPOJUCA',
        area_hecta: '871.8800',
        capacidade: 90.0,
        num_famili: 90.0,
        fase: 4.0,
        data_de_cr: '27/05/2002',
        forma_obte: 'Desapropriação',
        data_obten: '27/12/2000',
        area_calc_: 963.5158,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199803186739217, -8.377201750495294],
              [-35.198992815082853, -8.378847658824599],
              [-35.197766683019296, -8.37880513581514],
              [-35.194181479684211, -8.386878771685918],
              [-35.194374999863165, -8.388366874712672],
              [-35.193380419143395, -8.388993481671347],
              [-35.191904185793696, -8.389356385019477],
              [-35.189800980112601, -8.389942054677402],
              [-35.193483847213258, -8.39706910036789],
              [-35.197321786382787, -8.404495937317847],
              [-35.19914363565627, -8.409559256096708],
              [-35.20786100356618, -8.419643062002342],
              [-35.211175805638582, -8.422001289768614],
              [-35.216208594457896, -8.418938925547506],
              [-35.218814274929557, -8.417694206597711],
              [-35.225003711092249, -8.414291603140072],
              [-35.224770992776115, -8.413596206694221],
              [-35.223904210002836, -8.411800121698729],
              [-35.222450747850097, -8.408979992488273],
              [-35.221275453317929, -8.406194979469886],
              [-35.218874122978534, -8.402673425241119],
              [-35.217860203808904, -8.400595405939976],
              [-35.217116573327964, -8.39836689833796],
              [-35.215381327903877, -8.394303373989043],
              [-35.214322148373398, -8.392526765500451],
              [-35.214271851830212, -8.39183611381822],
              [-35.213960469563673, -8.391171153190049],
              [-35.213807399419309, -8.387991851864447],
              [-35.213162577723459, -8.385889856081352],
              [-35.212943571872295, -8.384946246764835],
              [-35.211994726884171, -8.385000560819529],
              [-35.210821306142719, -8.384939529445735],
              [-35.209769169833216, -8.384303661448866],
              [-35.207334261005478, -8.383743051432441],
              [-35.205236655079084, -8.383425495007922],
              [-35.20412358279404, -8.382750087310587],
              [-35.20142705911578, -8.381570551913381],
              [-35.199803186739217, -8.377201750495294],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0159000',
        uf: 'PE',
        nome_proje: 'PA PAULISTA',
        municipio: 'SERRA TALHADA',
        area_hecta: '925.0033',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '08/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2005',
        area_calc_: 953.0242,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.465165686405932, -8.356392825098599],
              [-38.469223554256978, -8.365500777978957],
              [-38.480294911540732, -8.367366646589891],
              [-38.482475859768556, -8.372270578416527],
              [-38.47136369374735, -8.370313533646115],
              [-38.473326951046822, -8.374722363168633],
              [-38.510071462477946, -8.379375862545823],
              [-38.510957348423524, -8.379560757341453],
              [-38.510957280407254, -8.379560727030094],
              [-38.510957362700502, -8.37956074460236],
              [-38.510957444826715, -8.379560762933256],
              [-38.510957526778853, -8.379560782021194],
              [-38.510957608549845, -8.379560801864526],
              [-38.510957690132685, -8.379560822461599],
              [-38.510957771520388, -8.379560843810614],
              [-38.510957852705928, -8.379560865909726],
              [-38.51095793368237, -8.379560888757057],
              [-38.510958014442743, -8.379560912350627],
              [-38.510958094980111, -8.37956093668844],
              [-38.510958175287563, -8.379560961768378],
              [-38.510958255358197, -8.379560987588269],
              [-38.510958335185151, -8.379561014145953],
              [-38.510958414761568, -8.379561041439105],
              [-38.510958494080604, -8.379561069465385],
              [-38.510958573135468, -8.379561098222412],
              [-38.510958651919353, -8.379561127707705],
              [-38.510958730425493, -8.379561157918719],
              [-38.51095880864716, -8.379561188852877],
              [-38.510958886577633, -8.379561220507524],
              [-38.510958964210218, -8.379561252879917],
              [-38.510959041538257, -8.379561285967304],
              [-38.5109591185551, -8.379561319766834],
              [-38.510959195254138, -8.379561354275594],
              [-38.510959271628785, -8.379561389490636],
              [-38.51095934767249, -8.379561425408943],
              [-38.510959423378715, -8.379561462027413],
              [-38.510959498740952, -8.379561499342907],
              [-38.51095957375275, -8.379561537352226],
              [-38.510959648407663, -8.379561576052096],
              [-38.510959722699255, -8.379561615439222],
              [-38.510959796621187, -8.379561655510168],
              [-38.510959870167092, -8.379561696261547],
              [-38.510959943330647, -8.379561737689849],
              [-38.510960016105585, -8.379561779791494],
              [-38.510960088485646, -8.379561822562891],
              [-38.510960160464634, -8.379561866000351],
              [-38.510960232036339, -8.379561910100133],
              [-38.510960303194636, -8.37956195485849],
              [-38.510960373933408, -8.37956200027153],
              [-38.510960444246578, -8.379562046335398],
              [-38.510960514128122, -8.37956209304612],
              [-38.510960583572036, -8.379562140399681],
              [-38.510960652572351, -8.379562188392033],
              [-38.510960721123141, -8.379562237019023],
              [-38.510960789218515, -8.379562286276514],
              [-38.510960856852648, -8.379562336160223],
              [-38.510960924019706, -8.379562386665908],
              [-38.51096099071394, -8.379562437789238],
              [-38.510961056929624, -8.379562489525805],
              [-38.510961122661058, -8.379562541871188],
              [-38.510961187902623, -8.379562594820863],
              [-38.51096125264867, -8.379562648370298],
              [-38.510961316893685, -8.379562702514884],
              [-38.51096138063216, -8.379562757249994],
              [-38.510961443858577, -8.379562812570938],
              [-38.510961506567519, -8.379562868472911],
              [-38.510961568753622, -8.37956292495115],
              [-38.510961630411543, -8.379562982000808],
              [-38.510961691535982, -8.379563039616983],
              [-38.51096175212168, -8.379563097794732],
              [-38.510961812163444, -8.379563156529047],
              [-38.510961871656122, -8.379563215814882],
              [-38.510961930594597, -8.379563275647161],
              [-38.510961988973833, -8.379563336020739],
              [-38.510962046788777, -8.37956339693044],
              [-38.510962104034498, -8.379563458371017],
              [-38.510962160706057, -8.37956352033722],
              [-38.510962216798603, -8.379563582823705],
              [-38.510962272307317, -8.379563645825113],
              [-38.510962327227432, -8.379563709336022],
              [-38.510962381554251, -8.379563773351025],
              [-38.510962435283062, -8.37956383786457],
              [-38.510962488409291, -8.379563902871141],
              [-38.510962540928368, -8.379563968365176],
              [-38.510962592835774, -8.379564034341023],
              [-38.510962644127076, -8.379564100793033],
              [-38.510962694797826, -8.37956416771547],
              [-38.510962744843738, -8.37956423510262],
              [-38.510962794260436, -8.379564302948683],
              [-38.510962843043735, -8.379564371247856],
              [-38.510962891189436, -8.379564439994255],
              [-38.510962938693389, -8.379564509181963],
              [-38.510962985551529, -8.379564578805072],
              [-38.510963031759829, -8.379564648857567],
              [-38.510963077314315, -8.379564719333461],
              [-38.510963122211095, -8.379564790226704],
              [-38.510963166446281, -8.37956486153119],
              [-38.510963210016094, -8.379564933240813],
              [-38.510963252916802, -8.379565005349404],
              [-38.510963295144698, -8.379565077850776],
              [-38.510963336696179, -8.379565150738706],
              [-38.510963377567677, -8.379565224006926],
              [-38.510963417755661, -8.379565297649178],
              [-38.510963457256686, -8.37956537165911],
              [-38.51096349606739, -8.379565446030339],
              [-38.510963534184398, -8.37956552075654],
              [-38.510963571604471, -8.379565595831243],
              [-38.510963608324694, -8.379565671247494],
              [-38.510963609327945, -8.379565739903473],
              [-38.511303151756692, -8.380271316615318],
              [-38.51130311259876, -8.380271265887314],
              [-38.511303118119841, -8.380271277382558],
              [-38.511303123624664, -8.380271288885572],
              [-38.511303129113209, -8.380271300396295],
              [-38.511303134585454, -8.380271311914699],
              [-38.511303140041406, -8.380271323440768],
              [-38.511303145481051, -8.380271334974479],
              [-38.511303150904375, -8.380271346515801],
              [-38.511303156311364, -8.380271358064721],
              [-38.511303161702017, -8.380271369621234],
              [-38.511303167076292, -8.380271381185295],
              [-38.511303172434218, -8.38027139275688],
              [-38.51130317777578, -8.380271404335977],
              [-38.511303183100949, -8.380271415922529],
              [-38.511303188409713, -8.380271427516563],
              [-38.511303193702084, -8.38027143911804],
              [-38.511303198978027, -8.380271450726909],
              [-38.511303204237549, -8.38027146234319],
              [-38.511303209480616, -8.380271473966825],
              [-38.511303214707262, -8.380271485597806],
              [-38.51130321991743, -8.380271497236125],
              [-38.511303225111135, -8.380271508881723],
              [-38.511303230288362, -8.380271520534595],
              [-38.511303235449098, -8.380271532194726],
              [-38.511303240593335, -8.380271543862097],
              [-38.511303245721052, -8.380271555536657],
              [-38.511303250832256, -8.380271567218374],
              [-38.511303255926933, -8.380271578907278],
              [-38.511303261005061, -8.380271590603328],
              [-38.511303266066641, -8.380271602306481],
              [-38.511303271111657, -8.380271614016724],
              [-38.511303276140104, -8.380271625734039],
              [-38.511303281151974, -8.380271637458375],
              [-38.51130328614726, -8.380271649189748],
              [-38.511303291125948, -8.380271660928114],
              [-38.511303296088002, -8.38027167267345],
              [-38.51130330103345, -8.380271684425738],
              [-38.511303305962272, -8.380271696184966],
              [-38.511303310874446, -8.380271707951081],
              [-38.511303315769972, -8.38027171972408],
              [-38.511303320648835, -8.380271731503916],
              [-38.511303325511044, -8.380271743290605],
              [-38.511303330356562, -8.380271755084099],
              [-38.511303335185389, -8.380271766884396],
              [-38.511303339997518, -8.380271778691442],
              [-38.511303344792935, -8.380271790505226],
              [-38.511303349571641, -8.380271802325746],
              [-38.511303354333627, -8.380271814152934],
              [-38.511303359078873, -8.380271825986787],
              [-38.511303363807379, -8.380271837827292],
              [-38.511303368519108, -8.380271849674429],
              [-38.511303373214112, -8.380271861528154],
              [-38.511303377892311, -8.380271873388462],
              [-38.511303382553741, -8.380271885255317],
              [-38.511303387198375, -8.380271897128686],
              [-38.51130339182621, -8.380271909008577],
              [-38.511303396437235, -8.38027192089495],
              [-38.511303401031427, -8.380271932787799],
              [-38.5113034056088, -8.380271944687035],
              [-38.511303410169333, -8.380271956592702],
              [-38.511303414713026, -8.380271968504751],
              [-38.511303419239866, -8.380271980423162],
              [-38.51130342374983, -8.380271992347906],
              [-38.511303428242933, -8.380272004278977],
              [-38.511303432719131, -8.380272016216349],
              [-38.511303437178434, -8.380272028159967],
              [-38.511303441620868, -8.380272040109848],
              [-38.511303446046369, -8.380272052065937],
              [-38.511303450454974, -8.380272064028228],
              [-38.511303454846633, -8.380272075996681],
              [-38.511303459221352, -8.380272087971294],
              [-38.511303463579139, -8.380272099952014],
              [-38.511303467919973, -8.380272111938854],
              [-38.511303472243846, -8.380272123931784],
              [-38.511303476550751, -8.380272135930777],
              [-38.511303480840674, -8.380272147935765],
              [-38.511303485113601, -8.380272159946776],
              [-38.511303489369546, -8.380272171963766],
              [-38.51130349360848, -8.380272183986715],
              [-38.511303497830411, -8.380272196015575],
              [-38.511303502035311, -8.380272208050377],
              [-38.511303506223186, -8.380272220091074],
              [-38.511303510394015, -8.380272232137624],
              [-38.511303514547805, -8.380272244190021],
              [-38.511303518684542, -8.380272256248222],
              [-38.511303522804212, -8.38027226831222],
              [-38.511303526906815, -8.38027228038197],
              [-38.51130353099235, -8.380272292457464],
              [-38.51130353506079, -8.380272304538716],
              [-38.511303539112134, -8.380272316625625],
              [-38.511303543146376, -8.380272328718222],
              [-38.5113035471635, -8.380272340816459],
              [-38.511303551163515, -8.380272352920322],
              [-38.511303555146405, -8.380272365029786],
              [-38.511303559112143, -8.380272377144838],
              [-38.511303563060757, -8.380272389265418],
              [-38.511303566992211, -8.380272401391537],
              [-38.51130357090652, -8.380272413523171],
              [-38.511303574803655, -8.380272425660261],
              [-38.511303578683595, -8.380272437802963],
              [-38.511303604428775, -8.380272493039095],
              [-38.511502194870118, -8.380895403756327],
              [-38.511502142576511, -8.380895372333976],
              [-38.511502150118751, -8.380895396109112],
              [-38.511502157595189, -8.38089541990483],
              [-38.511502165005716, -8.380895443721],
              [-38.511502172350284, -8.380895467557423],
              [-38.511502179628842, -8.3808954914139],
              [-38.511502186841362, -8.380895515290289],
              [-38.511502193987745, -8.380895539186376],
              [-38.511502201067948, -8.380895563101982],
              [-38.511502208081929, -8.380895587036962],
              [-38.511502215029637, -8.380895610991097],
              [-38.511502221911016, -8.380895634964203],
              [-38.511502228726002, -8.380895658956119],
              [-38.511502235474545, -8.380895682966633],
              [-38.51150224215661, -8.380895706995599],
              [-38.511502248772139, -8.380895731042814],
              [-38.511502255321055, -8.380895755108105],
              [-38.511502261803372, -8.38089577919126],
              [-38.511502268218976, -8.380895803292139],
              [-38.511502274567846, -8.380895827410525],
              [-38.511502280849932, -8.380895851546232],
              [-38.511502287065177, -8.380895875699125],
              [-38.511502293213546, -8.380895899868968],
              [-38.511502299294989, -8.380895924055608],
              [-38.51150230530947, -8.380895948258846],
              [-38.511502311256933, -8.380895972478511],
              [-38.511502317137321, -8.380895996714388],
              [-38.511502322950605, -8.38089602096635],
              [-38.511502328696714, -8.380896045234138],
              [-38.511502334375656, -8.380896069517629],
              [-38.511502339987352, -8.380896093816604],
              [-38.511502345531767, -8.380896118130897],
              [-38.511502351008851, -8.380896142460314],
              [-38.511502356418575, -8.380896166804678],
              [-38.511502361760883, -8.380896191163803],
              [-38.511502367035753, -8.380896215537499],
              [-38.511502372243129, -8.380896239925571],
              [-38.511502377382982, -8.380896264327861],
              [-38.511502382455269, -8.380896288744154],
              [-38.511502387459956, -8.380896313174294],
              [-38.511502392396991, -8.380896337618061],
              [-38.511502397266334, -8.380896362075314],
              [-38.511502402067975, -8.380896386545823],
              [-38.511502406801867, -8.380896411029406],
              [-38.511502411467958, -8.38089643552591],
              [-38.511502416066236, -8.380896460035137],
              [-38.511502420596649, -8.380896484556887],
              [-38.511502425059163, -8.380896509090975],
              [-38.511502429453756, -8.380896533637221],
              [-38.511502433780379, -8.38089655819544],
              [-38.51150243803901, -8.380896582765438],
              [-38.511502442229613, -8.380896607347044],
              [-38.511502446352139, -8.380896631940036],
              [-38.511502450406603, -8.380896656544284],
              [-38.511502454392939, -8.380896681159552],
              [-38.511502458311121, -8.380896705785666],
              [-38.511502462161118, -8.380896730422451],
              [-38.511502465942925, -8.380896755069712],
              [-38.511502469656463, -8.380896779727264],
              [-38.511502473301753, -8.380896804394894],
              [-38.511502476878739, -8.38089682907248],
              [-38.511502480387399, -8.380896853759772],
              [-38.511502483827719, -8.380896878456594],
              [-38.511502487199664, -8.380896903162771],
              [-38.51150249050319, -8.380896927878075],
              [-38.511502493738313, -8.380896952602397],
              [-38.511502496904974, -8.380896977335492],
              [-38.511502500003161, -8.3808970020772],
              [-38.511502503032851, -8.380897026827309],
              [-38.511502505994024, -8.380897051585643],
              [-38.51150250888665, -8.380897076352012],
              [-38.511502511710717, -8.380897101126214],
              [-38.511502514466194, -8.380897125908083],
              [-38.511502517153048, -8.380897150697399],
              [-38.511502519771291, -8.380897175494038],
              [-38.511502522320875, -8.380897200297754],
              [-38.511502524801806, -8.380897225108361],
              [-38.511502527214041, -8.380897249925688],
              [-38.511502529557568, -8.380897274749536],
              [-38.511502531832377, -8.380897299579738],
              [-38.511502534038449, -8.380897324416084],
              [-38.511502536175762, -8.380897349258394],
              [-38.511502538244301, -8.380897374106482],
              [-38.511502540244045, -8.380897398960146],
              [-38.511502542175002, -8.380897423819217],
              [-38.511502544037128, -8.380897448683495],
              [-38.511502545830417, -8.380897473552773],
              [-38.511502547554862, -8.380897498426886],
              [-38.511502549210455, -8.380897523305631],
              [-38.511502550797175, -8.380897548188841],
              [-38.511502552315008, -8.380897573076329],
              [-38.511502553763954, -8.380897597967843],
              [-38.511502555143977, -8.380897622863262],
              [-38.511502556455099, -8.380897647762371],
              [-38.511502557697284, -8.380897672665],
              [-38.511502558870539, -8.380897697570932],
              [-38.511502559974836, -8.380897722479997],
              [-38.51150256101019, -8.380897747392007],
              [-38.511502561976577, -8.380897772306746],
              [-38.511502562873929, -8.380897797224634],
              [-38.511502555135166, -8.38089784638011],
              [-38.511530086230238, -8.381690412401259],
              [-38.511530010995443, -8.381690403721088],
              [-38.511530011766311, -8.381690425237595],
              [-38.511530012588608, -8.381690446752195],
              [-38.51153001346232, -8.381690468264804],
              [-38.511530014387453, -8.381690489775302],
              [-38.511530015363995, -8.381690511283502],
              [-38.511530016391951, -8.381690532789344],
              [-38.511530017471308, -8.381690554292689],
              [-38.511530018602059, -8.381690575793417],
              [-38.511530019784203, -8.381690597291394],
              [-38.51153002101772, -8.381690618786502],
              [-38.511530022302615, -8.381690640278665],
              [-38.511530023638876, -8.381690661767699],
              [-38.511530025026509, -8.381690683253499],
              [-38.511530026465486, -8.381690704735957],
              [-38.511530027955814, -8.381690726214927],
              [-38.511530029497457, -8.381690747690298],
              [-38.511530031090452, -8.381690769161981],
              [-38.511530032734747, -8.381690790629804],
              [-38.511530034430365, -8.381690812093675],
              [-38.51153003617727, -8.381690833553465],
              [-38.511530037975476, -8.381690855009042],
              [-38.511530039824962, -8.38169087646032],
              [-38.511530041725706, -8.381690897907143],
              [-38.511530043677716, -8.381690919349399],
              [-38.511530045680963, -8.381690940787001],
              [-38.511530047735441, -8.381690962219762],
              [-38.511530049841156, -8.381690983647603],
              [-38.511530051998079, -8.381691005070364],
              [-38.511530054206204, -8.381691026487967],
              [-38.51153005646551, -8.381691047900279],
              [-38.511530058776003, -8.381691069307175],
              [-38.511530061137648, -8.381691090708559],
              [-38.511530063550445, -8.381691112104244],
              [-38.511530066014359, -8.381691133494163],
              [-38.51153006852941, -8.381691154878181],
              [-38.511530071095564, -8.381691176256163],
              [-38.511530073712819, -8.381691197628008],
              [-38.511530076381135, -8.381691218993582],
              [-38.511530079100524, -8.381691240352785],
              [-38.511530081870944, -8.381691261705464],
              [-38.511530084692417, -8.38169128305155],
              [-38.511530087564886, -8.381691304390877],
              [-38.51153009048835, -8.381691325723324],
              [-38.511530093462802, -8.381691347048772],
              [-38.511530096488229, -8.381691368367111],
              [-38.511530099564581, -8.381691389678219],
              [-38.511530102691886, -8.381691410981988],
              [-38.511530105870094, -8.381691432278275],
              [-38.511530109099198, -8.381691453566978],
              [-38.51153011237917, -8.381691474847976],
              [-38.511530115710023, -8.381691496121102],
              [-38.511530119091688, -8.381691517386301],
              [-38.511530122524178, -8.381691538643418],
              [-38.511530126007472, -8.381691559892333],
              [-38.511530129541555, -8.381691581132921],
              [-38.511530133126378, -8.381691602365086],
              [-38.511530136761955, -8.381691623588685],
              [-38.511530140448258, -8.381691644803638],
              [-38.511530144185244, -8.381691666009754],
              [-38.511530147972927, -8.381691687206956],
              [-38.511530151811243, -8.38169170839514],
              [-38.511530155700214, -8.381691729574154],
              [-38.511530159639783, -8.381691750743899],
              [-38.511530163629935, -8.381691771904235],
              [-38.511530167670649, -8.381691793055046],
              [-38.51153017176194, -8.381691814196222],
              [-38.511530175903737, -8.381691835327647],
              [-38.511530180096024, -8.381691856449184],
              [-38.511530184338788, -8.381691877560721],
              [-38.511530188632001, -8.38169189866216],
              [-38.511530192975641, -8.381691919753354],
              [-38.511530197369687, -8.3816919408342],
              [-38.511530201814111, -8.381691961904574],
              [-38.511530206308869, -8.381691982964329],
              [-38.511530210853969, -8.3816920040134],
              [-38.511530215449362, -8.381692025051599],
              [-38.511530220095032, -8.381692046078861],
              [-38.511530224790938, -8.381692067095079],
              [-38.51153022953708, -8.381692088100053],
              [-38.511530234333399, -8.381692109093766],
              [-38.511530239179898, -8.381692130076022],
              [-38.511530244076539, -8.381692151046748],
              [-38.511530249023274, -8.381692172005792],
              [-38.511530254020094, -8.381692192953057],
              [-38.51153025906698, -8.381692213888421],
              [-38.511530264163902, -8.381692234811764],
              [-38.511530269310803, -8.381692255722957],
              [-38.511530274507678, -8.381692276621912],
              [-38.511530279754503, -8.38169229750846],
              [-38.511530285051208, -8.381692318382518],
              [-38.511530290397815, -8.381692339243987],
              [-38.511530295794266, -8.381692360092698],
              [-38.511530301240533, -8.381692380928564],
              [-38.511530306736596, -8.381692401751449],
              [-38.511530312282403, -8.381692422561258],
              [-38.511530317877948, -8.381692443357885],
              [-38.511530323523175, -8.381692464141194],
              [-38.511530329218076, -8.381692484911003],
              [-38.511530334962586, -8.381692505667294],
              [-38.511530356119103, -8.381692493108952],
              [-38.511671867557759, -8.382201545714347],
              [-38.511671865996121, -8.382201523439926],
              [-38.511671872522221, -8.382201547035221],
              [-38.51167187898421, -8.382201570648025],
              [-38.511671885382022, -8.382201594278184],
              [-38.511671891715594, -8.382201617925437],
              [-38.511671897984897, -8.38220164158969],
              [-38.511671904189861, -8.382201665270763],
              [-38.511671910330492, -8.382201688968454],
              [-38.511671916406684, -8.382201712682644],
              [-38.511671922418437, -8.382201736413089],
              [-38.511671928365693, -8.382201760159621],
              [-38.511671934248398, -8.382201783922108],
              [-38.511671940066513, -8.382201807700387],
              [-38.511671945820005, -8.382201831494219],
              [-38.511671951508831, -8.382201855303489],
              [-38.511671957132947, -8.382201879128008],
              [-38.511671962692304, -8.382201902967577],
              [-38.51167196818686, -8.382201926822029],
              [-38.511671973616593, -8.382201950691208],
              [-38.511671978981454, -8.382201974574947],
              [-38.511671984281378, -8.382201998473031],
              [-38.511671989516387, -8.382202022385314],
              [-38.511671994686381, -8.382202046311626],
              [-38.511671999791346, -8.382202070251786],
              [-38.511672004831254, -8.382202094205567],
              [-38.511672009806055, -8.382202118172879],
              [-38.511672014715728, -8.382202142153517],
              [-38.511672019560208, -8.382202166147286],
              [-38.511672024339497, -8.382202190153997],
              [-38.511672029053514, -8.382202214173514],
              [-38.511672033702283, -8.382202238205659],
              [-38.511672038285731, -8.382202262250237],
              [-38.511672042803809, -8.38220228630707],
              [-38.511672047256525, -8.38220231037598],
              [-38.511672051643806, -8.382202334456826],
              [-38.511672055965676, -8.382202358549419],
              [-38.511672060222047, -8.382202382653505],
              [-38.51167206441292, -8.382202406769014],
              [-38.511672068538253, -8.382202430895729],
              [-38.511672072598024, -8.382202455033472],
              [-38.51167207659217, -8.382202479182045],
              [-38.511672080520711, -8.382202503341292],
              [-38.511672084383591, -8.382202527511048],
              [-38.511672088180788, -8.382202551691154],
              [-38.511672091912267, -8.382202575881371],
              [-38.511672095578014, -8.382202600081548],
              [-38.511672099177986, -8.382202624291534],
              [-38.511672102712154, -8.382202648511129],
              [-38.511672106180526, -8.38220267274014],
              [-38.511672109583039, -8.382202696978407],
              [-38.51167211291969, -8.382202721225772],
              [-38.511672116190447, -8.382202745482019],
              [-38.511672119395278, -8.382202769746996],
              [-38.511672122534179, -8.382202794020534],
              [-38.511672125607113, -8.382202818302449],
              [-38.511672128614052, -8.382202842592537],
              [-38.511672131554988, -8.382202866890648],
              [-38.511672134429894, -8.382202891196592],
              [-38.511672137238747, -8.382202915510192],
              [-38.511672139981521, -8.382202939831256],
              [-38.51167214265822, -8.382202964159619],
              [-38.51167214526879, -8.382202988495123],
              [-38.511672147813243, -8.382203012837577],
              [-38.511672150291545, -8.382203037186768],
              [-38.511672152703682, -8.382203061542587],
              [-38.511672155049631, -8.382203085904806],
              [-38.511672157329357, -8.382203110273245],
              [-38.511672159542897, -8.382203134647733],
              [-38.511672161690178, -8.382203159028075],
              [-38.511672163771223, -8.382203183414196],
              [-38.511672165785996, -8.382203207805745],
              [-38.511672167734488, -8.382203232202684],
              [-38.511672169616681, -8.382203256604768],
              [-38.511672171432579, -8.382203281011851],
              [-38.511672173182149, -8.382203305423712],
              [-38.511672174865375, -8.38220332984022],
              [-38.511672176482257, -8.382203354261163],
              [-38.511672178032782, -8.382203378686372],
              [-38.511672179516943, -8.382203403115659],
              [-38.51167218093471, -8.382203427548873],
              [-38.511672182286077, -8.382203451985829],
              [-38.511672183571065, -8.38220347642631],
              [-38.511672184789624, -8.382203500870178],
              [-38.511672185941791, -8.382203525317216],
              [-38.511672187027507, -8.382203549767306],
              [-38.511672188046788, -8.38220357422021],
              [-38.511672188999626, -8.382203598675776],
              [-38.511672189886021, -8.382203623133829],
              [-38.511672190705951, -8.382203647594201],
              [-38.511672191459411, -8.382203672056653],
              [-38.511672192146413, -8.382203696521039],
              [-38.511672192766945, -8.382203720987231],
              [-38.511672193321004, -8.382203745454978],
              [-38.511672193808558, -8.382203769924105],
              [-38.511672194229632, -8.382203794394503],
              [-38.511672194584229, -8.382203818865921],
              [-38.511672194872318, -8.382203843338225],
              [-38.511672195093922, -8.382203867811171],
              [-38.511672195249041, -8.382203892284672],
              [-38.511672195337724, -8.382203916758025],
              [-38.511672227937439, -8.382203897887029],
              [-38.511673927108724, -8.382953196315327],
              [-38.522266823144768, -8.384675145433249],
              [-38.520506099501027, -8.376664826576221],
              [-38.509012868151295, -8.375025119822993],
              [-38.509118949111581, -8.374666522872005],
              [-38.509118902241603, -8.374666537360781],
              [-38.509118911499009, -8.374666505856579],
              [-38.509118920641541, -8.374666474319135],
              [-38.509118929669086, -8.374666442748877],
              [-38.509118938581523, -8.37466641114618],
              [-38.509118947378717, -8.37466637951149],
              [-38.509118956060604, -8.37466634784521],
              [-38.509118964627028, -8.374666316147771],
              [-38.509118973077868, -8.374666284419535],
              [-38.509118981413053, -8.37466625266102],
              [-38.509118989632441, -8.374666220872554],
              [-38.509118997735946, -8.374666189054633],
              [-38.509119005723434, -8.374666157207619],
              [-38.509119013594805, -8.374666125331926],
              [-38.509119021349967, -8.374666093428017],
              [-38.509119028988827, -8.374666061496264],
              [-38.509119036511265, -8.374666029537144],
              [-38.509119043917195, -8.374665997551038],
              [-38.509119051206511, -8.374665965538387],
              [-38.509119058379127, -8.374665933499596],
              [-38.50911906543493, -8.374665901435085],
              [-38.509119072373842, -8.374665869345293],
              [-38.509119079195777, -8.374665837230641],
              [-38.509119085900622, -8.374665805091526],
              [-38.509119092488312, -8.374665772928399],
              [-38.509119098958742, -8.374665740741667],
              [-38.509119105311854, -8.374665708531763],
              [-38.509119111547527, -8.374665676299136],
              [-38.509119117665712, -8.374665644044175],
              [-38.509119123666316, -8.374665611767298],
              [-38.509119129549248, -8.374665579468969],
              [-38.509119135314442, -8.374665547149581],
              [-38.509119140961829, -8.374665514809557],
              [-38.509119146491322, -8.374665482449345],
              [-38.509119151902844, -8.37466545006937],
              [-38.509119157196352, -8.374665417670045],
              [-38.509119162371739, -8.374665385251802],
              [-38.509119167428963, -8.374665352815068],
              [-38.509119172367953, -8.374665320360258],
              [-38.509119177188637, -8.374665287887829],
              [-38.509119181890952, -8.374665255398192],
              [-38.509119186474848, -8.374665222891755],
              [-38.509119190940247, -8.374665190368987],
              [-38.509119195287099, -8.374665157830298],
              [-38.509119199515354, -8.374665125276097],
              [-38.509119203624934, -8.374665092706838],
              [-38.509119207615811, -8.374665060122926],
              [-38.509119211487914, -8.374665027524834],
              [-38.509119215241213, -8.374664994912932],
              [-38.509119218875632, -8.374664962287705],
              [-38.509119222391135, -8.374664929649539],
              [-38.509119225787671, -8.374664896998897],
              [-38.50911922906522, -8.374664864336207],
              [-38.509119232223711, -8.374664831661855],
              [-38.509119235263107, -8.374664798976331],
              [-38.509119238183388, -8.374664766280029],
              [-38.509119240984475, -8.374664733573383],
              [-38.509119243666362, -8.374664700856837],
              [-38.509119246229027, -8.374664668130826],
              [-38.509119248672384, -8.374664635395719],
              [-38.509119250996456, -8.374664602652043],
              [-38.509119253201192, -8.37466456990016],
              [-38.509119255286549, -8.374664537140534],
              [-38.509119257252515, -8.3746645043736],
              [-38.509119259099059, -8.374664471599761],
              [-38.509119260826154, -8.374664438819474],
              [-38.509119262433792, -8.374664406033157],
              [-38.509119263921932, -8.374664373241249],
              [-38.509119265290572, -8.374664340444172],
              [-38.509119266539678, -8.374664307642378],
              [-38.509119267669249, -8.374664274836247],
              [-38.509119268679257, -8.374664242026274],
              [-38.509119269569688, -8.374664209212883],
              [-38.509119270340541, -8.374664176396481],
              [-38.509119270991803, -8.374664143577505],
              [-38.509119271523453, -8.374664110756374],
              [-38.509119271935511, -8.374664077933545],
              [-38.509119272227956, -8.374664045109457],
              [-38.509119272400774, -8.374664012284557],
              [-38.509119272453965, -8.37466397945918],
              [-38.509119272387551, -8.374663946633868],
              [-38.509119272201502, -8.374663913808998],
              [-38.509119271895841, -8.374663880985004],
              [-38.50911927147056, -8.374663848162351],
              [-38.50911927092568, -8.374663815341446],
              [-38.509119270261209, -8.374663782522722],
              [-38.509119269477125, -8.374663749706629],
              [-38.509119268573471, -8.37466371689357],
              [-38.509119267550247, -8.37466368408402],
              [-38.509119266407474, -8.374663651278365],
              [-38.509119265145131, -8.374663618477058],
              [-38.509119263763289, -8.374663585680521],
              [-38.509119262261933, -8.374663552889194],
              [-38.509119260641093, -8.374663520103518],
              [-38.509119258900796, -8.374663487323938],
              [-38.509119257041043, -8.374663454550834],
              [-38.509119255061869, -8.374663421784692],
              [-38.509119252963316, -8.374663389025899],
              [-38.509119250745385, -8.374663356274892],
              [-38.509119248408133, -8.374663323532143],
              [-38.509119316384755, -8.374663357542287],
              [-38.509092615171205, -8.374299439858158],
              [-38.509092605628176, -8.374299420441922],
              [-38.509092605044508, -8.374299412496519],
              [-38.509092604453556, -8.374299404551634],
              [-38.509092603855322, -8.374299396607277],
              [-38.509092603249819, -8.374299388663468],
              [-38.509092602637033, -8.374299380720226],
              [-38.509092602016985, -8.374299372777543],
              [-38.509092601389654, -8.374299364835389],
              [-38.509092600755039, -8.37429935689385],
              [-38.509092600113163, -8.374299348952876],
              [-38.509092599464005, -8.374299341012501],
              [-38.50909259880757, -8.374299333072726],
              [-38.509092598143866, -8.374299325133522],
              [-38.5090925974729, -8.374299317194957],
              [-38.509092596794659, -8.374299309256989],
              [-38.509092596109134, -8.374299301319633],
              [-38.509092595416348, -8.374299293382911],
              [-38.509092594716286, -8.374299285446785],
              [-38.509092594008969, -8.374299277511341],
              [-38.509092593294369, -8.374299269576523],
              [-38.5090925925725, -8.374299261642378],
              [-38.509092591843377, -8.374299253708894],
              [-38.509092591106977, -8.374299245776058],
              [-38.509092590363302, -8.374299237843887],
              [-38.509092589612372, -8.374299229912415],
              [-38.50909258885418, -8.374299221981607],
              [-38.50909258808872, -8.374299214051533],
              [-38.50909258731599, -8.374299206122108],
              [-38.509092586535999, -8.374299198193413],
              [-38.50909258574876, -8.374299190265416],
              [-38.509092584954246, -8.374299182338152],
              [-38.509092584152462, -8.374299174411604],
              [-38.509092583343438, -8.374299166485786],
              [-38.509092582527146, -8.374299158560719],
              [-38.509092581703584, -8.374299150636388],
              [-38.509092580872782, -8.374299142712834],
              [-38.509092580034711, -8.374299134790014],
              [-38.509092579189385, -8.374299126867944],
              [-38.509092578336791, -8.374299118946674],
              [-38.509092577476963, -8.374299111026167],
              [-38.509092576609874, -8.374299103106427],
              [-38.509092575735508, -8.374299095187506],
              [-38.509092574853909, -8.374299087269417],
              [-38.50909257396507, -8.374299079352058],
              [-38.509092573068948, -8.374299071435537],
              [-38.509092572165592, -8.37429906351983],
              [-38.509092571254982, -8.374299055604954],
              [-38.509092570337124, -8.374299047690933],
              [-38.509092569412012, -8.374299039777709],
              [-38.509092568479652, -8.374299031865338],
              [-38.509092567540044, -8.374299023953865],
              [-38.509092566593196, -8.374299016043173],
              [-38.509092565639108, -8.374299008133402],
              [-38.509092564677765, -8.374299000224482],
              [-38.509092563709189, -8.374298992316426],
              [-38.509092562733336, -8.374298984409256],
              [-38.509092561750272, -8.374298976502985],
              [-38.50909256075996, -8.374298968597596],
              [-38.509092559762408, -8.374298960693132],
              [-38.509092558757608, -8.374298952789561],
              [-38.509092557745568, -8.374298944886894],
              [-38.509092556726287, -8.374298936985198],
              [-38.509092555699787, -8.374298929084398],
              [-38.509092554666026, -8.37429892118452],
              [-38.509092553625045, -8.374298913285608],
              [-38.509092552576831, -8.374298905387629],
              [-38.509092551521384, -8.374298897490618],
              [-38.509092550458682, -8.374298889594595],
              [-38.509092549388761, -8.374298881699488],
              [-38.509092548311607, -8.374298873805399],
              [-38.509092547227226, -8.37429886591228],
              [-38.509092546135612, -8.374298858020163],
              [-38.509092545036779, -8.37429885012903],
              [-38.509092543930706, -8.374298842238863],
              [-38.509092542817406, -8.374298834349734],
              [-38.50909254169688, -8.37429882646159],
              [-38.509092540569142, -8.374298818574516],
              [-38.509092539434164, -8.374298810688428],
              [-38.509092538291959, -8.374298802803406],
              [-38.509092537142536, -8.374298794919405],
              [-38.509092535985907, -8.374298787036453],
              [-38.509092534822045, -8.374298779154556],
              [-38.509092533650964, -8.374298771273745],
              [-38.509092532472664, -8.374298763393972],
              [-38.509092531287145, -8.37429875551528],
              [-38.509092530094414, -8.374298747637662],
              [-38.50909252889447, -8.374298739761144],
              [-38.509092527687294, -8.374298731885714],
              [-38.509092526472919, -8.374298724011387],
              [-38.509092525251326, -8.374298716138162],
              [-38.509092522224016, -8.37429869099328],
              [-38.509025308965164, -8.3738674525306],
              [-38.509025316656583, -8.373867456284488],
              [-38.509025310317526, -8.373867416202467],
              [-38.509025303797998, -8.373867376149184],
              [-38.509025297098084, -8.373867336125416],
              [-38.50902529021797, -8.37386729613198],
              [-38.509025283157754, -8.373867256169694],
              [-38.509025275917608, -8.373867216239365],
              [-38.509025268497666, -8.373867176341763],
              [-38.509025260898071, -8.373867136477699],
              [-38.509025253118978, -8.373867096648009],
              [-38.509025245160551, -8.373867056853452],
              [-38.509025237022946, -8.373867017094865],
              [-38.509025228706314, -8.373866977373016],
              [-38.509025220210852, -8.373866937688737],
              [-38.509025211536709, -8.373866898042799],
              [-38.509025202684072, -8.373866858436031],
              [-38.509025193653095, -8.373866818869187],
              [-38.509025184443985, -8.373866779343098],
              [-38.509025175056934, -8.373866739858537],
              [-38.509025165492091, -8.373866700416327],
              [-38.509025155749676, -8.373866661017209],
              [-38.509025145829888, -8.373866621662049],
              [-38.509025135732927, -8.373866582351587],
              [-38.509025125458976, -8.373866543086626],
              [-38.509025115008271, -8.373866503867964],
              [-38.509025104380996, -8.373866464696391],
              [-38.509025093577385, -8.373866425572668],
              [-38.509025082597624, -8.373866386497612],
              [-38.509025071441982, -8.37386634747199],
              [-38.509025060110638, -8.373866308496609],
              [-38.509025048603867, -8.373866269572266],
              [-38.509025036921855, -8.373866230699692],
              [-38.509025025064851, -8.373866191879687],
              [-38.509025013033117, -8.373866153113054],
              [-38.509025000826853, -8.373866114400574],
              [-38.509024988446342, -8.373866075743003],
              [-38.509024975891812, -8.373866037141134],
              [-38.509024963163519, -8.37386599859574],
              [-38.50902495026174, -8.373865960107597],
              [-38.509024937186695, -8.373865921677487],
              [-38.509024923938675, -8.373865883306157],
              [-38.509024910517965, -8.373865844994393],
              [-38.509024896924792, -8.373865806742979],
              [-38.509024883159448, -8.373865768552676],
              [-38.509024869222209, -8.373865730424244],
              [-38.50902485511336, -8.373865692358494],
              [-38.509024840833185, -8.373865654356132],
              [-38.50902482638196, -8.373865616417964],
              [-38.509024811759993, -8.373865578544715],
              [-38.509024796967573, -8.373865540737167],
              [-38.509024782004985, -8.373865502996086],
              [-38.50902476687255, -8.37386546532224],
              [-38.509024751570557, -8.37386542771638],
              [-38.509024736099299, -8.373865390179253],
              [-38.509024720459131, -8.373865352711617],
              [-38.509024704650336, -8.373865315314223],
              [-38.509024688673243, -8.373865277987838],
              [-38.50902467252817, -8.373865240733204],
              [-38.509024656215445, -8.373865203551071],
              [-38.509024639735394, -8.373865166442188],
              [-38.509024623088344, -8.373865129407314],
              [-38.509024606274629, -8.373865092447168],
              [-38.50902458929459, -8.373865055562518],
              [-38.50902457214859, -8.373865018754097],
              [-38.509024554836941, -8.373864982022633],
              [-38.50902453736002, -8.373864945368897],
              [-38.509024519718146, -8.373864908793587],
              [-38.509024501911711, -8.373864872297444],
              [-38.50902448394104, -8.373864835881259],
              [-38.509024465806512, -8.373864799545684],
              [-38.509024447508494, -8.37386476329149],
              [-38.509024429047344, -8.37386472711944],
              [-38.50902441042345, -8.373864691030199],
              [-38.509024391637176, -8.373864655024539],
              [-38.509024372688884, -8.373864619103154],
              [-38.509024353578994, -8.37386458326678],
              [-38.509024334307853, -8.373864547516165],
              [-38.509024314875859, -8.373864511851966],
              [-38.509024295283417, -8.373864476274953],
              [-38.509024275530912, -8.373864440785844],
              [-38.509024255618741, -8.3738644053853],
              [-38.509024235547301, -8.373864370074093],
              [-38.509024215316998, -8.37386433485289],
              [-38.509024194928259, -8.373864299722435],
              [-38.509024174381459, -8.373864264683414],
              [-38.509024153677039, -8.373864229736526],
              [-38.509024132815398, -8.373864194882493],
              [-38.509024111796982, -8.373864160122006],
              [-38.509024090622169, -8.373864125455773],
              [-38.509024069291421, -8.373864090884467],
              [-38.509024047805156, -8.373864056408834],
              [-38.509024026163821, -8.373864022029526],
              [-38.509024004367838, -8.373863987747256],
              [-38.509023982417645, -8.373863953562678],
              [-38.509023960313677, -8.373863919476522],
              [-38.509023938056394, -8.373863885489442],
              [-38.50902391564621, -8.373863851602126],
              [-38.509023893083636, -8.373863817815272],
              [-38.509023870369084, -8.373863784129567],
              [-38.509023847503016, -8.373863750545672],
              [-38.509023860389064, -8.373863742237972],
              [-38.508821324883684, -8.373567715608957],
              [-38.508821282443932, -8.373567670251468],
              [-38.508821275235896, -8.373567659731734],
              [-38.508821268012994, -8.373567649222107],
              [-38.508821260775228, -8.373567638722603],
              [-38.50882125352264, -8.373567628233259],
              [-38.508821246255216, -8.373567617754125],
              [-38.508821238972985, -8.373567607285162],
              [-38.508821231675967, -8.373567596826438],
              [-38.508821224364155, -8.373567586377961],
              [-38.508821217037585, -8.373567575939724],
              [-38.508821209696265, -8.373567565511779],
              [-38.508821202340215, -8.37356755509413],
              [-38.508821194969428, -8.373567544686804],
              [-38.508821187583955, -8.373567534289855],
              [-38.508821180183773, -8.373567523903265],
              [-38.508821172768926, -8.373567513527039],
              [-38.508821165339405, -8.373567503161237],
              [-38.508821157895234, -8.373567492805845],
              [-38.508821150436447, -8.373567482460931],
              [-38.508821142963022, -8.373567472126462],
              [-38.508821135475003, -8.373567461802491],
              [-38.508821127972389, -8.373567451489023],
              [-38.50882112045521, -8.373567441186092],
              [-38.508821112923471, -8.373567430893724],
              [-38.508821105377173, -8.373567420611899],
              [-38.508821097816359, -8.373567410340687],
              [-38.508821090241028, -8.373567400080068],
              [-38.508821082651195, -8.373567389830097],
              [-38.508821075046882, -8.373567379590774],
              [-38.508821067428094, -8.373567369362128],
              [-38.508821059794855, -8.373567359144152],
              [-38.508821052147184, -8.373567348936918],
              [-38.508821044485089, -8.373567338740418],
              [-38.508821036808577, -8.373567328554666],
              [-38.508821029117662, -8.37356731837969],
              [-38.508821021412381, -8.373567308215502],
              [-38.508821013692746, -8.373567298062127],
              [-38.508821005958744, -8.373567287919585],
              [-38.508820998210425, -8.373567277787892],
              [-38.508820990447781, -8.373567267667077],
              [-38.508820982670834, -8.37356725755715],
              [-38.508820974879605, -8.373567247458155],
              [-38.508820967074108, -8.373567237370095],
              [-38.508820959254358, -8.373567227292982],
              [-38.508820951420354, -8.373567217226853],
              [-38.508820943572132, -8.373567207171709],
              [-38.508820935709707, -8.373567197127569],
              [-38.508820927833078, -8.373567187094473],
              [-38.508820919942295, -8.373567177072434],
              [-38.508820912037336, -8.373567167061477],
              [-38.508820904118217, -8.37356715706159],
              [-38.508820896184986, -8.373567147072839],
              [-38.508820888237643, -8.373567137095225],
              [-38.508820880276183, -8.37356712712876],
              [-38.508820872300653, -8.373567117173449],
              [-38.508820864311055, -8.373567107229357],
              [-38.508820856307402, -8.373567097296453],
              [-38.508820848289716, -8.373567087374786],
              [-38.508820840258004, -8.373567077464388],
              [-38.508820832212294, -8.373567067565245],
              [-38.508820824152593, -8.373567057677406],
              [-38.508820816078924, -8.373567047800872],
              [-38.508820807991306, -8.373567037935658],
              [-38.50882079988974, -8.373567028081796],
              [-38.508820791774241, -8.373567018239312],
              [-38.508820783644843, -8.373567008408214],
              [-38.508820775501555, -8.373566998588538],
              [-38.508820767344389, -8.373566988780301],
              [-38.508820759173368, -8.373566978983485],
              [-38.508820750988491, -8.373566969198144],
              [-38.508820742789808, -8.373566959424293],
              [-38.508820734577306, -8.373566949661932],
              [-38.508820726351004, -8.373566939911109],
              [-38.508820718110925, -8.373566930171828],
              [-38.50882070985709, -8.373566920444103],
              [-38.508820701589499, -8.37356691072797],
              [-38.508820693308188, -8.37356690102343],
              [-38.50882068501317, -8.37356689133053],
              [-38.508820676704445, -8.373566881649239],
              [-38.508820668382043, -8.373566871979637],
              [-38.50882066004597, -8.373566862321713],
              [-38.508820651696269, -8.373566852675498],
              [-38.508820643332925, -8.373566843040971],
              [-38.508820634955974, -8.373566833418197],
              [-38.508820626565424, -8.373566823807172],
              [-38.508820618161295, -8.373566814207914],
              [-38.508820609743594, -8.373566804620461],
              [-38.508820601312351, -8.37356679504482],
              [-38.508820592867579, -8.373566785481023],
              [-38.508820584409293, -8.373566775929055],
              [-38.508820575937513, -8.373566766388977],
              [-38.508820567452254, -8.373566756860766],
              [-38.508820558953531, -8.373566747344466],
              [-38.508820550441364, -8.373566737840104],
              [-38.50882054191576, -8.373566728347679],
              [-38.508820533376742, -8.373566718867226],
              [-38.508820524824337, -8.373566709398755],
              [-38.50882051625856, -8.373566699942282],
              [-38.50882050767941, -8.373566690497835],
              [-38.508820499086909, -8.373566681065416],
              [-38.508820508693525, -8.373566719632848],
              [-38.508545138051723, -8.373264813455348],
              [-38.520184173928456, -8.374912396447968],
              [-38.518552360475049, -8.366051984936732],
              [-38.518033107384632, -8.365909977186501],
              [-38.518195060209926, -8.365160786429476],
              [-38.503304180417459, -8.362798055333498],
              [-38.465165686405932, -8.356392825098599],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0084000',
        uf: 'PE',
        nome_proje: 'PA BAIXA DO JUAZEIRO',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1438.3547',
        capacidade: 25.0,
        num_famili: 24.0,
        fase: 4.0,
        data_de_cr: '21/11/2003',
        forma_obte: 'Arrecadação',
        data_obten: '21/11/2003',
        area_calc_: 1432.5469,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.218179339795789, -8.776970894832809],
              [-40.218229955102508, -8.77707374295365],
              [-40.223935940751794, -8.811120898627578],
              [-40.224623307835763, -8.811992450529711],
              [-40.225547281483401, -8.812949374877562],
              [-40.226339001629732, -8.813681362385346],
              [-40.227392059674528, -8.814336685369421],
              [-40.22845310468805, -8.814221806813549],
              [-40.229804376862695, -8.80867930647168],
              [-40.231429104627104, -8.802195464682102],
              [-40.24260017204395, -8.799342738311321],
              [-40.243566937398221, -8.800981862243983],
              [-40.242765356698179, -8.802517051468579],
              [-40.252690676390969, -8.805532658592227],
              [-40.258997660967133, -8.811325930873059],
              [-40.257211759924324, -8.805162057024665],
              [-40.251588184817038, -8.798567296102329],
              [-40.248565720307084, -8.796435529995637],
              [-40.254740421257885, -8.79173299191476],
              [-40.248765366829048, -8.782350680090598],
              [-40.241145141168829, -8.78386798100872],
              [-40.238775692015473, -8.780600490584263],
              [-40.237241746562908, -8.778413459358754],
              [-40.239233098822709, -8.771881093842826],
              [-40.237261528306576, -8.764718027796542],
              [-40.23811499068664, -8.764342671800446],
              [-40.236971376854051, -8.762070320226311],
              [-40.236364853371072, -8.761830118926856],
              [-40.236258742534375, -8.761788297584941],
              [-40.218308272563242, -8.755135166739709],
              [-40.218179339795789, -8.776970894832809],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0133000',
        uf: 'PE',
        nome_proje: 'PA ENTREMONTES',
        municipio: 'PARNAMIRIM',
        area_hecta: '1632.4513',
        capacidade: 107.0,
        num_famili: 106.0,
        fase: 3.0,
        data_de_cr: '23/12/2004',
        forma_obte: 'Doação',
        data_obten: '23/12/2004',
        area_calc_: 1633.2532,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.075900244157069, -8.157771412023756],
              [-40.075059299611596, -8.158753559577038],
              [-40.075059333467003, -8.158753566405966],
              [-40.075059305948201, -8.158753598701594],
              [-40.075059278581534, -8.158753631125329],
              [-40.075059251367598, -8.15875366367645],
              [-40.075059224306976, -8.15875369635425],
              [-40.0750591974003, -8.158753729157983],
              [-40.075059170648117, -8.158753762086972],
              [-40.075059144051046, -8.158753795140413],
              [-40.075059117609669, -8.158753828317669],
              [-40.07505909132454, -8.158753861617985],
              [-40.075059065196271, -8.158753895040633],
              [-40.075059039225401, -8.158753928584842],
              [-40.075059013412513, -8.158753962249925],
              [-40.07505898775819, -8.158753996035129],
              [-40.075058962262979, -8.158754029939702],
              [-40.075058936927427, -8.158754063962901],
              [-40.075058911752109, -8.158754098103998],
              [-40.075058886737594, -8.158754132362185],
              [-40.075058861884393, -8.158754166736788],
              [-40.075058837193069, -8.158754201227005],
              [-40.075058812664167, -8.158754235832085],
              [-40.075058788298222, -8.158754270551299],
              [-40.075058764095772, -8.158754305383841],
              [-40.075058740057329, -8.158754340328979],
              [-40.075058716183449, -8.158754375385941],
              [-40.07505869247462, -8.158754410553952],
              [-40.075058668931433, -8.15875444583223],
              [-40.075058645554307, -8.158754481220036],
              [-40.075058622343825, -8.158754516716526],
              [-40.075058599300469, -8.158754552321007],
              [-40.075058576424766, -8.158754588032625],
              [-40.075058553717192, -8.158754623850628],
              [-40.075058531178264, -8.158754659774244],
              [-40.075058508808461, -8.158754695802664],
              [-40.075058486608285, -8.158754731935124],
              [-40.07505846457822, -8.15875476817077],
              [-40.075058442718756, -8.158754804508872],
              [-40.075058421030356, -8.158754840948607],
              [-40.075058399513509, -8.158754877489159],
              [-40.075058378168691, -8.158754914129743],
              [-40.075058356996351, -8.158754950869577],
              [-40.075058335996985, -8.158754987707804],
              [-40.075058315171034, -8.158755024643659],
              [-40.075058294518946, -8.158755061676324],
              [-40.075058274041183, -8.158755098804965],
              [-40.075058253738206, -8.158755136028795],
              [-40.075058233610434, -8.158755173346965],
              [-40.075058213658338, -8.158755210758684],
              [-40.075058193882342, -8.158755248263123],
              [-40.075058174282887, -8.158755285859447],
              [-40.075058154860372, -8.158755323546842],
              [-40.075058135615272, -8.158755361324493],
              [-40.075058116547979, -8.158755399191545],
              [-40.075058097658911, -8.158755437147173],
              [-40.075058078948501, -8.158755475190574],
              [-40.075058060417135, -8.158755513320866],
              [-40.075058042065251, -8.158755551537267],
              [-40.075058023893213, -8.158755589838867],
              [-40.075058005901461, -8.158755628224895],
              [-40.075057988090343, -8.158755666694486],
              [-40.075057970460286, -8.158755705246758],
              [-40.075057953011672, -8.158755743880933],
              [-40.07505793574488, -8.158755782596097],
              [-40.075057918660285, -8.158755821391459],
              [-40.075057901758257, -8.158755860266133],
              [-40.075057885039193, -8.158755899219296],
              [-40.075057868503421, -8.158755938250046],
              [-40.075057852151332, -8.158755977357519],
              [-40.07505783598328, -8.158756016540909],
              [-40.075057819999607, -8.158756055799367],
              [-40.075057804200682, -8.158756095131979],
              [-40.075057788586832, -8.15875613453788],
              [-40.075057773158427, -8.158756174016235],
              [-40.075057757915786, -8.15875621356621],
              [-40.075057742859244, -8.158756253186846],
              [-40.075057727989126, -8.158756292877372],
              [-40.075057713305767, -8.158756332636839],
              [-40.075057698809495, -8.158756372464405],
              [-40.075057684500621, -8.15875641235921],
              [-40.075057670379451, -8.158756452320377],
              [-40.075057656446319, -8.15875649234698],
              [-40.075057642701495, -8.158756532438224],
              [-40.075057629145313, -8.158756572593134],
              [-40.075057615778057, -8.158756612810915],
              [-40.075057602600026, -8.158756653090656],
              [-40.075057589611504, -8.158756693431453],
              [-40.075057576812767, -8.158756733832401],
              [-40.075057564204123, -8.158756774292687],
              [-40.075057551785811, -8.158756814811372],
              [-40.075057539558145, -8.158756855387551],
              [-40.075057527521352, -8.158756896020405],
              [-40.075057515675717, -8.158756936708958],
              [-40.075057504021515, -8.158756977452377],
              [-40.075057492558983, -8.158757018249732],
              [-40.075057481288354, -8.158757059100152],
              [-40.075057470209927, -8.158757100002715],
              [-40.075057459323894, -8.158757140956553],
              [-40.075057448630531, -8.158757181960752],
              [-40.075057438130052, -8.158757223014421],
              [-40.075057427822678, -8.158757264116618],
              [-40.07505740346663, -8.158757272641225],
              [-40.074762684358703, -8.159944444260097],
              [-40.074762504762518, -8.1599451682552],
              [-40.074467082527839, -8.160835699029194],
              [-40.074467050333539, -8.160835705584111],
              [-40.074467040117682, -8.160835736198873],
              [-40.074467029792288, -8.160835766777106],
              [-40.074467019357506, -8.160835797318446],
              [-40.074467008813421, -8.160835827822496],
              [-40.074466998160212, -8.160835858288898],
              [-40.07446698739799, -8.160835888717216],
              [-40.074466976526885, -8.160835919107095],
              [-40.07446696554706, -8.160835949458145],
              [-40.07446695445865, -8.160835979769951],
              [-40.074466943261775, -8.160836010042164],
              [-40.074466931956614, -8.16083604027439],
              [-40.074466920543266, -8.160836070466251],
              [-40.074466909021908, -8.160836100617335],
              [-40.074466897392668, -8.16083613072726],
              [-40.074466885655717, -8.160836160795679],
              [-40.07446687381119, -8.160836190822165],
              [-40.074466861859229, -8.160836220806372],
              [-40.074466849799997, -8.16083625074792],
              [-40.074466837633658, -8.160836280646393],
              [-40.074466825360346, -8.160836310501436],
              [-40.074466812980226, -8.160836340312677],
              [-40.074466800493454, -8.160836370079711],
              [-40.074466787900192, -8.16083639980218],
              [-40.074466775200605, -8.160836429479708],
              [-40.074466762394835, -8.160836459111879],
              [-40.074466749483072, -8.160836488698363],
              [-40.07446673646546, -8.16083651823873],
              [-40.074466723342169, -8.160836547732647],
              [-40.074466710113377, -8.160836577179781],
              [-40.07446669677924, -8.16083660657967],
              [-40.074466683339928, -8.160836635931966],
              [-40.074466669795619, -8.160836665236305],
              [-40.074466656146484, -8.160836694492346],
              [-40.074466642392693, -8.160836723699656],
              [-40.074466628534417, -8.160836752857888],
              [-40.074466614571833, -8.160836781966685],
              [-40.07446660050514, -8.160836811025677],
              [-40.074466586334495, -8.160836840034474],
              [-40.074466572060082, -8.160836868992705],
              [-40.074466557682072, -8.160836897900031],
              [-40.074466543200693, -8.160836926756071],
              [-40.074466528616064, -8.160836955560432],
              [-40.074466513928414, -8.160836984312777],
              [-40.074466499137934, -8.160837013012737],
              [-40.07446648424478, -8.160837041659919],
              [-40.074466469249167, -8.160837070254027],
              [-40.074466454151271, -8.160837098794614],
              [-40.074466438951298, -8.160837127281367],
              [-40.074466423649426, -8.160837155713908],
              [-40.074466408245868, -8.160837184091871],
              [-40.074466392740803, -8.160837212414917],
              [-40.074466377134435, -8.160837240682643],
              [-40.074466361426971, -8.160837268894754],
              [-40.074466345618603, -8.160837297050822],
              [-40.07446632970953, -8.160837325150521],
              [-40.074466313699951, -8.160837353193516],
              [-40.074466297590078, -8.160837381179414],
              [-40.074466281380111, -8.160837409107852],
              [-40.074466265070264, -8.160837436978536],
              [-40.074466248660734, -8.160837464791033],
              [-40.07446623215175, -8.160837492545035],
              [-40.074466215543481, -8.16083752024017],
              [-40.074466198836191, -8.160837547876103],
              [-40.074466182030051, -8.160837575452479],
              [-40.074466165125301, -8.160837602968929],
              [-40.074466148122134, -8.16083763042513],
              [-40.074466131020777, -8.160837657820709],
              [-40.074466113821451, -8.160837685155331],
              [-40.074466096524375, -8.160837712428627],
              [-40.074466079129756, -8.160837739640272],
              [-40.074466061637828, -8.160837766789932],
              [-40.074466044048833, -8.160837793877247],
              [-40.074466026362963, -8.160837820901856],
              [-40.07446600858043, -8.160837847863444],
              [-40.074465990701512, -8.160837874761649],
              [-40.074465972726394, -8.160837901596144],
              [-40.074465954655324, -8.160837928366544],
              [-40.074465936488522, -8.160837955072557],
              [-40.074465918226231, -8.160837981713804],
              [-40.074465899868663, -8.160838008289991],
              [-40.074465881416067, -8.160838034800749],
              [-40.074465862868685, -8.160838061245743],
              [-40.074465844226744, -8.160838087624663],
              [-40.074465825490464, -8.160838113937107],
              [-40.074465806660115, -8.160838140182827],
              [-40.074465787735924, -8.16083816636144],
              [-40.074465768718127, -8.160838192472619],
              [-40.074465749606958, -8.160838218516023],
              [-40.074465730402679, -8.160838244491368],
              [-40.074465711105532, -8.160838270398223],
              [-40.074465691715758, -8.160838296236363],
              [-40.074465672233586, -8.160838322005409],
              [-40.074465652659292, -8.160838347705042],
              [-40.07446563299311, -8.160838373334926],
              [-40.074465613235297, -8.160838398894755],
              [-40.074465593386108, -8.160838424384197],
              [-40.074465573445778, -8.160838449802887],
              [-40.074465553414569, -8.160838475150582],
              [-40.074465533292823, -8.160838500426868],
              [-40.074465547013418, -8.160838506737225],
              [-40.073874640432159, -8.161577972500144],
              [-40.073874676479875, -8.161577975218798],
              [-40.07387462503835, -8.161578039004944],
              [-40.073874573019722, -8.161578102324718],
              [-40.073874520428248, -8.161578165172916],
              [-40.073874467268254, -8.161578227544421],
              [-40.073874413544061, -8.161578289434123],
              [-40.073874359260088, -8.161578350836953],
              [-40.073874304420777, -8.161578411747877],
              [-40.073874249030617, -8.161578472161935],
              [-40.073874193094134, -8.161578532074147],
              [-40.07387413661592, -8.161578591479625],
              [-40.073874079600586, -8.161578650373491],
              [-40.073874022052813, -8.161578708750957],
              [-40.073873963977292, -8.16157876660721],
              [-40.073873905378804, -8.161578823937536],
              [-40.073873846262131, -8.161578880737213],
              [-40.073873786632106, -8.161578937001636],
              [-40.073873726493623, -8.161578992726154],
              [-40.073873665851586, -8.161579047906223],
              [-40.073873604710982, -8.161579102537353],
              [-40.073873543076822, -8.161579156615009],
              [-40.073873480954141, -8.161579210134825],
              [-40.073873418348001, -8.161579263092399],
              [-40.073873355263558, -8.161579315483374],
              [-40.073873291705958, -8.161579367303522],
              [-40.073873227680409, -8.161579418548525],
              [-40.073873163192175, -8.161579469214233],
              [-40.073873098246494, -8.161579519296458],
              [-40.073873032848709, -8.161579568791158],
              [-40.073872967004185, -8.16157961769423],
              [-40.073872900718271, -8.161579666001712],
              [-40.07387283399644, -8.161579713709635],
              [-40.073872766844119, -8.161579760814071],
              [-40.073872699266836, -8.161579807311174],
              [-40.073872631270085, -8.161579853197164],
              [-40.073872562859464, -8.161579898468258],
              [-40.073872494040572, -8.161579943120749],
              [-40.073872424819029, -8.161579987151018],
              [-40.073872355200486, -8.161580030555426],
              [-40.073872285190689, -8.161580073330409],
              [-40.07387221479533, -8.161580115472493],
              [-40.0738721440202, -8.161580156978195],
              [-40.073872072871076, -8.161580197844149],
              [-40.07387200135377, -8.161580238067016],
              [-40.073871929474159, -8.161580277643479],
              [-40.073871857238117, -8.161580316570294],
              [-40.073871784651573, -8.161580354844311],
              [-40.07387171172045, -8.16158039246236],
              [-40.073871638450711, -8.16158042942136],
              [-40.073871564848375, -8.161580465718298],
              [-40.073871490919473, -8.16158050135021],
              [-40.073871416670038, -8.16158053631418],
              [-40.073871342106152, -8.161580570607351],
              [-40.073871267233919, -8.161580604226886],
              [-40.073871192059485, -8.16158063717006],
              [-40.073871116588975, -8.161580669434134],
              [-40.0738710408286, -8.161580701016538],
              [-40.073870964784547, -8.16158073191461],
              [-40.073870888463013, -8.161580762125883],
              [-40.073870811870293, -8.161580791647863],
              [-40.073870735012626, -8.1615808204781],
              [-40.073870657896329, -8.161580848614273],
              [-40.073870580527689, -8.16158087605408],
              [-40.07387050291306, -8.16158090279527],
              [-40.073870425058786, -8.161580928835631],
              [-40.073870346971233, -8.161580954173045],
              [-40.073870268656805, -8.161580978805434],
              [-40.073870190121909, -8.161581002730786],
              [-40.073870111372983, -8.16158102594715],
              [-40.073870032416458, -8.161581048452639],
              [-40.073869953258807, -8.161581070245367],
              [-40.073869873906524, -8.161581091323578],
              [-40.073869794366082, -8.161581111685535],
              [-40.07386971464399, -8.161581131329578],
              [-40.073869634746792, -8.161581150254134],
              [-40.073869554681025, -8.161581168457586],
              [-40.07386947445324, -8.161581185938468],
              [-40.073869394070002, -8.161581202695372],
              [-40.073869313537905, -8.161581218726914],
              [-40.073869232863522, -8.161581234031779],
              [-40.073869152053469, -8.161581248608719],
              [-40.073869071114359, -8.161581262456526],
              [-40.073868990052816, -8.161581275574068],
              [-40.073868908875482, -8.161581287960276],
              [-40.073868827588996, -8.161581299614154],
              [-40.073868746200006, -8.161581310534737],
              [-40.073868664715192, -8.161581320721108],
              [-40.073868583141227, -8.161581330172471],
              [-40.073868501484775, -8.161581338888011],
              [-40.073868419752515, -8.161581346867061],
              [-40.073868337951154, -8.161581354108906],
              [-40.073868256087387, -8.16158136061301],
              [-40.073868174167906, -8.161581366378833],
              [-40.073868092199419, -8.161581371405884],
              [-40.073868010188633, -8.161581375693771],
              [-40.073867928142278, -8.161581379242129],
              [-40.073867846067074, -8.161581382050686],
              [-40.073867763969702, -8.16158138411919],
              [-40.073867681856917, -8.16158138544745],
              [-40.07386759973619, -8.1615813860339],
              [-40.07386758959904, -8.161581386055117],
              [-40.073867579461876, -8.161581386065066],
              [-40.073867569324712, -8.161581386063729],
              [-40.073867559187555, -8.161581386051104],
              [-40.073867549050419, -8.161581386027194],
              [-40.073867538913312, -8.161581385992029],
              [-40.07386752877624, -8.161581385945547],
              [-40.073867518639247, -8.161581385887809],
              [-40.07386750850231, -8.161581385818785],
              [-40.073867498365473, -8.161581385738472],
              [-40.073867488228714, -8.161581385646896],
              [-40.073867478092083, -8.161581385544046],
              [-40.073867467955566, -8.161581385429908],
              [-40.073867457819183, -8.161581385304499],
              [-40.073867447682943, -8.161581385167819],
              [-40.073867437546873, -8.16158138501982],
              [-40.073867427410953, -8.16158138486057],
              [-40.073867417275231, -8.161581384690065],
              [-40.073867407139716, -8.161581384508237],
              [-40.073867397004392, -8.161581384315159],
              [-40.07386738686931, -8.161581384110809],
              [-40.073867376734455, -8.161581383895154],
              [-40.073867366599849, -8.16158138366823],
              [-40.073867356465499, -8.161581383430034],
              [-40.073867346331426, -8.161581383180536],
              [-40.073867336197644, -8.161581382919803],
              [-40.073867326064146, -8.161581382647745],
              [-40.073867315930968, -8.161581382364432],
              [-40.073867305798125, -8.161581382069851],
              [-40.073867295665593, -8.161581381763964],
              [-40.073867285533431, -8.161581381446826],
              [-40.073867275401618, -8.161581381118417],
              [-40.073867265270202, -8.161581380778717],
              [-40.073867255139135, -8.16158138042775],
              [-40.073867245008501, -8.161581380065511],
              [-40.073867234878279, -8.161581379692002],
              [-40.073867224748462, -8.161581379307218],
              [-40.073867214619099, -8.161581378911153],
              [-40.073867204490163, -8.161581378503829],
              [-40.073867194361711, -8.161581378085218],
              [-40.073867184233734, -8.161581377655336],
              [-40.073867174106233, -8.161581377214183],
              [-40.073867163979237, -8.16158137676176],
              [-40.073867153852767, -8.161581376298098],
              [-40.073867143726815, -8.161581375823133],
              [-40.073867133601389, -8.161581375336896],
              [-40.073867123476525, -8.161581374839404],
              [-40.073867113352243, -8.161581374330657],
              [-40.073867103228515, -8.161581373810623],
              [-40.073867093105385, -8.161581373279335],
              [-40.073867082982844, -8.161581372736775],
              [-40.073867072860928, -8.161581372182944],
              [-40.073867062739644, -8.161581371617856],
              [-40.073867052618994, -8.161581371041516],
              [-40.073867042498996, -8.161581370453906],
              [-40.073867032379653, -8.161581369855019],
              [-40.073867022260998, -8.161581369244885],
              [-40.07386701214304, -8.161581368623491],
              [-40.073867002025771, -8.161581367990859],
              [-40.07386699190922, -8.161581367346907],
              [-40.073866981793408, -8.161581366691749],
              [-40.073866971678328, -8.161581366025301],
              [-40.073866961564001, -8.161581365347617],
              [-40.073866951450448, -8.161581364658662],
              [-40.073866941337663, -8.161581363958469],
              [-40.073866931225673, -8.16158136324702],
              [-40.073866921114494, -8.161581362524315],
              [-40.073866911004117, -8.161581361790343],
              [-40.073866900894579, -8.161581361045139],
              [-40.073866890785887, -8.16158136028869],
              [-40.07386688067804, -8.161581359520982],
              [-40.073866870571052, -8.161581358742037],
              [-40.07386686046496, -8.161581357951857],
              [-40.073866850359764, -8.16158135715043],
              [-40.073866840255455, -8.161581356337733],
              [-40.073866830152056, -8.161581355513821],
              [-40.073866820049609, -8.161581354678663],
              [-40.073866809948107, -8.161581353832252],
              [-40.073866799847544, -8.161581352974606],
              [-40.073866789747953, -8.161581352105701],
              [-40.07386677964935, -8.161581351225573],
              [-40.073866769551721, -8.16158135033419],
              [-40.073866759455115, -8.161581349431589],
              [-40.073866749359517, -8.161581348517727],
              [-40.073866739264957, -8.161581347592662],
              [-40.073866729171456, -8.161581346656376],
              [-40.073866719078985, -8.161581345708836],
              [-40.073866708987588, -8.161581344750058],
              [-40.073866698897262, -8.161581343780036],
              [-40.073866688808046, -8.16158134279881],
              [-40.073866678719945, -8.161581341806347],
              [-40.073866668632931, -8.161581340802641],
              [-40.073866658547082, -8.161581339787736],
              [-40.073866648462364, -8.161581338761584],
              [-40.073866638378789, -8.161581337724249],
              [-40.0738666282964, -8.161581336675672],
              [-40.073866618215177, -8.161581335615843],
              [-40.073866608135155, -8.161581334544822],
              [-40.073866598056256, -8.161581333463184],
              [-40.073866571266358, -8.161581340169272],
              [-40.072530748516748, -8.161437101010895],
              [-40.072530764688345, -8.161437142687658],
              [-40.072530711916947, -8.161437136835755],
              [-40.072530659180821, -8.161437130676358],
              [-40.072530606481756, -8.161437124209668],
              [-40.072530553821565, -8.161437117435911],
              [-40.072530501202031, -8.161437110355322],
              [-40.072530448624981, -8.161437102968145],
              [-40.072530396092183, -8.161437095274632],
              [-40.072530343605479, -8.161437087275027],
              [-40.072530291166615, -8.161437078969628],
              [-40.072530238777418, -8.161437070358707],
              [-40.072530186439671, -8.161437061442541],
              [-40.072530134155166, -8.161437052221496],
              [-40.072530081925706, -8.161437042695814],
              [-40.072530029753054, -8.161437032865871],
              [-40.072529977639, -8.161437022731981],
              [-40.072529925585336, -8.161437012294519],
              [-40.072529873593837, -8.161437001553773],
              [-40.072529821666294, -8.161436990510175],
              [-40.072529769804476, -8.161436979164103],
              [-40.072529718010152, -8.161436967515918],
              [-40.072529666285114, -8.161436955566025],
              [-40.072529614631101, -8.161436943314843],
              [-40.072529563049905, -8.161436930762774],
              [-40.072529511543294, -8.161436917910285],
              [-40.072529460113017, -8.161436904757757],
              [-40.072529408760836, -8.161436891305707],
              [-40.072529357488513, -8.161436877554536],
              [-40.072529306297795, -8.161436863504745],
              [-40.072529255190432, -8.161436849156804],
              [-40.072529204168198, -8.161436834511221],
              [-40.0725291532328, -8.161436819568491],
              [-40.072529102386028, -8.16143680432911],
              [-40.072529051629587, -8.161436788793608],
              [-40.072529000965218, -8.161436772962526],
              [-40.072528950394656, -8.161436756836382],
              [-40.072528899919647, -8.161436740415757],
              [-40.072528849541897, -8.161436723701188],
              [-40.072528799263146, -8.161436706693255],
              [-40.072528749085109, -8.161436689392549],
              [-40.072528699009503, -8.161436671799654],
              [-40.072528649038041, -8.161436653915171],
              [-40.072528599172436, -8.161436635739719],
              [-40.072528549414372, -8.161436617273914],
              [-40.072528499765589, -8.161436598518396],
              [-40.072528450227772, -8.161436579473799],
              [-40.072528400802611, -8.161436560140775],
              [-40.072528351491798, -8.161436540519993],
              [-40.072528302297016, -8.161436520612122],
              [-40.072528253219964, -8.161436500417839],
              [-40.072528204262298, -8.161436479937832],
              [-40.072528155425722, -8.161436459172801],
              [-40.072528106711907, -8.161436438123474],
              [-40.072528058122472, -8.161436416790544],
              [-40.072528009659138, -8.161436395174773],
              [-40.07252796132353, -8.161436373276873],
              [-40.072527913117305, -8.161436351097628],
              [-40.07252786504214, -8.161436328637739],
              [-40.072527817099633, -8.161436305898013],
              [-40.072527769291476, -8.161436282879219],
              [-40.072527721619259, -8.161436259582166],
              [-40.072527674084647, -8.161436236007614],
              [-40.072527626689251, -8.161436212156429],
              [-40.072527579434698, -8.161436188029374],
              [-40.072527532322589, -8.161436163627291],
              [-40.07252748535457, -8.161436138951002],
              [-40.072527438532227, -8.161436114001374],
              [-40.072527391857157, -8.161436088779245],
              [-40.072527345330961, -8.16143606328548],
              [-40.072527298955244, -8.161436037520957],
              [-40.072527252731582, -8.161436011486549],
              [-40.072527206661555, -8.161435985183182],
              [-40.072527160746738, -8.161435958611714],
              [-40.072527114988716, -8.161435931773056],
              [-40.072527069389039, -8.161435904668139],
              [-40.072527023949277, -8.161435877297901],
              [-40.072526978670979, -8.161435849663262],
              [-40.072526933555714, -8.161435821765181],
              [-40.072526888605005, -8.161435793604618],
              [-40.072526843820391, -8.161435765182498],
              [-40.072526799203409, -8.16143573649981],
              [-40.072526754755586, -8.161435707557583],
              [-40.072526710478442, -8.161435678356749],
              [-40.072526666373506, -8.16143564889834],
              [-40.072526622442261, -8.161435619183333],
              [-40.072526578686229, -8.161435589212784],
              [-40.072526535106903, -8.161435558987685],
              [-40.072526491705773, -8.161435528509093],
              [-40.072526448484318, -8.161435497778031],
              [-40.072526405444037, -8.16143546679557],
              [-40.07252636258638, -8.161435435562757],
              [-40.072526319912825, -8.161435404080658],
              [-40.072526277424835, -8.161435372350372],
              [-40.072526235123846, -8.161435340372972],
              [-40.072526193011342, -8.161435308149541],
              [-40.072526151088731, -8.161435275681182],
              [-40.072526109357462, -8.161435242969031],
              [-40.072526067818956, -8.161435210014194],
              [-40.072526026474641, -8.161435176817783],
              [-40.072525985326443, -8.161435143379165],
              [-40.072525979016724, -8.161435138216573],
              [-40.072525972711681, -8.161435133048304],
              [-40.072525966411305, -8.161435127874402],
              [-40.072525960115598, -8.161435122694822],
              [-40.07252595382456, -8.161435117509624],
              [-40.072525947538225, -8.1614351123188],
              [-40.072525941256572, -8.16143510712234],
              [-40.072525934979595, -8.16143510192024],
              [-40.07252592870735, -8.161435096712536],
              [-40.072525922439787, -8.161435091499209],
              [-40.072525916176929, -8.161435086280244],
              [-40.072525909918788, -8.161435081055691],
              [-40.072525903665365, -8.16143507582551],
              [-40.072525897416682, -8.161435070589731],
              [-40.072525891172717, -8.161435065348378],
              [-40.072525884933484, -8.161435060101422],
              [-40.07252587869899, -8.161435054848857],
              [-40.072525872469242, -8.161435049590722],
              [-40.072525866244241, -8.161435044326998],
              [-40.072525860023994, -8.1614350390577],
              [-40.072525853808507, -8.161435033782833],
              [-40.072525847597788, -8.161435028502414],
              [-40.072525841391837, -8.161435023216422],
              [-40.072525835190653, -8.161435017924852],
              [-40.072525828994259, -8.161435012627749],
              [-40.072525822802646, -8.161435007325075],
              [-40.072525816615823, -8.16143500201686],
              [-40.072525810433788, -8.161434996703111],
              [-40.072525804256564, -8.161434991383821],
              [-40.072525798084136, -8.161434986058994],
              [-40.072525791916519, -8.161434980728647],
              [-40.072525785753726, -8.161434975392709],
              [-40.072525750641461, -8.16143494368829],
              [-40.07133614979108, -8.160404002002316],
              [-40.071336113059708, -8.160403989859377],
              [-40.071336026992562, -8.160403916215801],
              [-40.07133594000846, -8.16040384364975],
              [-40.071335852120953, -8.160403772172575],
              [-40.071335763343797, -8.160403701795447],
              [-40.071335673690854, -8.160403632529336],
              [-40.071335583176122, -8.160403564385081],
              [-40.071335491813741, -8.160403497373316],
              [-40.071335399617986, -8.160403431504513],
              [-40.071335306603274, -8.160403366788954],
              [-40.071335212784092, -8.160403303236746],
              [-40.071335118175128, -8.160403240857825],
              [-40.071335022791139, -8.160403179661936],
              [-40.071334926647054, -8.160403119658625],
              [-40.071334829757859, -8.160403060857274],
              [-40.071334732138695, -8.16040300326708],
              [-40.071334633804824, -8.160402946897012],
              [-40.071334534771609, -8.160402891755879],
              [-40.071334435054482, -8.16040283785229],
              [-40.071334334669061, -8.160402785194721],
              [-40.071334233631013, -8.160402733791322],
              [-40.071334131956085, -8.160402683650167],
              [-40.071334029660221, -8.160402634779073],
              [-40.071333926759351, -8.16040258718567],
              [-40.07133382326959, -8.160402540877396],
              [-40.071333719207068, -8.160402495861474],
              [-40.071333614588035, -8.160402452144966],
              [-40.071333509428861, -8.160402409734665],
              [-40.071333403745953, -8.160402368637207],
              [-40.071333297555832, -8.160402328859027],
              [-40.071333190875066, -8.160402290406335],
              [-40.071333083720333, -8.160402253285129],
              [-40.071332976108366, -8.160402217501213],
              [-40.071332868055983, -8.160402183060175],
              [-40.071332759580031, -8.160402149967371],
              [-40.071332650697506, -8.160402118228012],
              [-40.071332541425363, -8.160402087847023],
              [-40.071332431780704, -8.16040205882916],
              [-40.071332321780645, -8.160402031178949],
              [-40.071332211442368, -8.160402004900716],
              [-40.071332100783088, -8.16040197999858],
              [-40.071331989820138, -8.160401956476404],
              [-40.071331878570803, -8.16040193433788],
              [-40.071331767052484, -8.160401913586464],
              [-40.071331655282599, -8.160401894225386],
              [-40.071331543278603, -8.160401876257691],
              [-40.07133143105797, -8.16040185968617],
              [-40.071331318638279, -8.160401844513396],
              [-40.071331206037051, -8.160401830741772],
              [-40.07133109327188, -8.160401818373421],
              [-40.071330980360372, -8.160401807410304],
              [-40.071330867320171, -8.160401797854092],
              [-40.071330754168962, -8.160401789706336],
              [-40.071330640924373, -8.160401782968254],
              [-40.071330527604132, -8.16040177764093],
              [-40.071330414225905, -8.160401773725173],
              [-40.071330300807425, -8.160401771221631],
              [-40.071330187366399, -8.160401770130656],
              [-40.071330073920549, -8.160401770452438],
              [-40.07132996048761, -8.160401772186939],
              [-40.071329847085281, -8.160401775333845],
              [-40.071329733731261, -8.1604017798927],
              [-40.071329620443294, -8.160401785862794],
              [-40.071329507239035, -8.16040179324319],
              [-40.071329394136207, -8.160401802032709],
              [-40.071329281152458, -8.160401812230011],
              [-40.071329168305425, -8.160401823833482],
              [-40.071329055612743, -8.160401836841343],
              [-40.07132894309202, -8.160401851251502],
              [-40.071328830760834, -8.160401867061758],
              [-40.071328718636721, -8.160401884269627],
              [-40.071328606737204, -8.160401902872431],
              [-40.071328495079754, -8.160401922867241],
              [-40.071328383681809, -8.160401944250971],
              [-40.071328272560777, -8.160401967020231],
              [-40.071328161734009, -8.160401991171502],
              [-40.071328051218821, -8.160402016700994],
              [-40.071327941032486, -8.160402043604746],
              [-40.071327831192193, -8.160402071878512],
              [-40.071327721715107, -8.160402101517892],
              [-40.071327612618333, -8.160402132518291],
              [-40.071327503918909, -8.160402164874824],
              [-40.071327395633816, -8.160402198582453],
              [-40.071327287779965, -8.160402233635905],
              [-40.071327180374219, -8.160402270029723],
              [-40.071327073433324, -8.160402307758208],
              [-40.071326966973999, -8.16040234681547],
              [-40.071326861012871, -8.160402387195404],
              [-40.071326755566496, -8.160402428891695],
              [-40.071326650651351, -8.160402471897848],
              [-40.071326546283821, -8.160402516207148],
              [-40.071326442480185, -8.160402561812667],
              [-40.071326339256686, -8.160402608707265],
              [-40.071326236629432, -8.160402656883635],
              [-40.071326134614452, -8.160402706334255],
              [-40.071326033227685, -8.160402757051383],
              [-40.071325932484974, -8.160402809027129],
              [-40.07132583240206, -8.160402862253344],
              [-40.071325732994552, -8.160402916721726],
              [-40.07132563427799, -8.160402972423739],
              [-40.071325618159655, -8.160402944577031],
              [-40.070294367271771, -8.160993439933339],
              [-40.070294377712244, -8.160993448805039],
              [-40.070294314399931, -8.160993485395393],
              [-40.070294251385782, -8.160993522493225],
              [-40.070294188673927, -8.160993560096173],
              [-40.070294126268408, -8.16099359820179],
              [-40.070294064173304, -8.160993636807582],
              [-40.070294002392629, -8.160993675911023],
              [-40.070293940930434, -8.160993715509575],
              [-40.070293879790697, -8.160993755600684],
              [-40.070293818977397, -8.160993796181732],
              [-40.070293758494486, -8.160993837250093],
              [-40.070293698345893, -8.160993878803064],
              [-40.070293638535539, -8.160993920837987],
              [-40.070293579067311, -8.16099396335211],
              [-40.070293519945068, -8.160994006342641],
              [-40.07029346117266, -8.160994049806808],
              [-40.070293402753911, -8.160994093741802],
              [-40.070293344692615, -8.160994138144732],
              [-40.070293286992552, -8.160994183012749],
              [-40.070293229657473, -8.160994228342888],
              [-40.070293172691102, -8.160994274132259],
              [-40.07029311609714, -8.160994320377819],
              [-40.070293059879276, -8.160994367076599],
              [-40.070293004041162, -8.16099441422557],
              [-40.070292948586435, -8.160994461821652],
              [-40.07029289351869, -8.160994509861743],
              [-40.070292838841503, -8.160994558342734],
              [-40.070292784558461, -8.160994607261497],
              [-40.07029273067306, -8.160994656614768],
              [-40.07029267718881, -8.160994706399427],
              [-40.0702926241092, -8.160994756612173],
              [-40.070292571437683, -8.160994807249793],
              [-40.07029251917767, -8.160994858308975],
              [-40.070292467332564, -8.160994909786384],
              [-40.07029241590574, -8.160994961678679],
              [-40.070292364900546, -8.160995013982493],
              [-40.070292314320277, -8.160995066694417],
              [-40.070292264168259, -8.160995119811037],
              [-40.070292214447711, -8.160995173328878],
              [-40.070292165161909, -8.160995227244493],
              [-40.070292116314008, -8.160995281554335],
              [-40.070292067907239, -8.160995336254896],
              [-40.070292019944709, -8.16099539134262],
              [-40.070291972429558, -8.160995446813921],
              [-40.070291925364863, -8.16099550266518],
              [-40.070291878753693, -8.160995558892786],
              [-40.070291832599068, -8.160995615493096],
              [-40.070291786904008, -8.16099567246237],
              [-40.070291741671454, -8.160995729796975],
              [-40.070291696904384, -8.160995787493114],
              [-40.07029165260569, -8.160995845547101],
              [-40.070291608778248, -8.160995903955104],
              [-40.07029156542491, -8.160995962713368],
              [-40.070291522548509, -8.160996021818036],
              [-40.070291480151816, -8.160996081265324],
              [-40.070291438237597, -8.160996141051299],
              [-40.070291396808564, -8.160996201172098],
              [-40.070291355867433, -8.160996261623804],
              [-40.070291315416846, -8.160996322402502],
              [-40.070291275459446, -8.160996383504262],
              [-40.070291235997828, -8.160996444925049],
              [-40.070291197034564, -8.160996506660913],
              [-40.070291158572175, -8.160996568707839],
              [-40.07029112061317, -8.160996631061781],
              [-40.070291083160008, -8.160996693718683],
              [-40.070291046215132, -8.160996756674487],
              [-40.070291009780959, -8.16099681992509],
              [-40.070290973859819, -8.160996883466353],
              [-40.070290938454086, -8.16099694729418],
              [-40.070290903566054, -8.160997011404428],
              [-40.070290869197976, -8.160997075792883],
              [-40.070290835352097, -8.160997140455388],
              [-40.07029080203062, -8.160997205387751],
              [-40.070290769235704, -8.1609972705857],
              [-40.07029073696949, -8.160997336045057],
              [-40.070290705234065, -8.160997401761529],
              [-40.070290674031511, -8.160997467730825],
              [-40.070290643363847, -8.160997533948716],
              [-40.070290613233055, -8.16099760041085],
              [-40.070290583641103, -8.16099766711292],
              [-40.070290554589931, -8.160997734050584],
              [-40.070290526081408, -8.160997801219469],
              [-40.070290498117402, -8.16099786861524],
              [-40.070290470699703, -8.160997936233532],
              [-40.070290443830139, -8.160998004069894],
              [-40.070290417510421, -8.160998072119954],
              [-40.070290391742262, -8.160998140379279],
              [-40.07029036652736, -8.160998208843415],
              [-40.070290341867349, -8.160998277507932],
              [-40.070290317763821, -8.160998346368341],
              [-40.070290294218331, -8.160998415420195],
              [-40.07029027123243, -8.160998484658991],
              [-40.07029024880763, -8.160998554080205],
              [-40.07029022694536, -8.16099862367934],
              [-40.070290205647048, -8.160998693451877],
              [-40.070290184914072, -8.160998763393282],
              [-40.070290164747817, -8.160998833498981],
              [-40.070290145149542, -8.16099890376444],
              [-40.070290126120547, -8.160998974185068],
              [-40.070290107661677, -8.160999044757867],
              [-40.070290095678196, -8.160999091868504],
              [-40.070290083948933, -8.160999139042621],
              [-40.070290072474258, -8.160999186278884],
              [-40.070290061254504, -8.160999233575891],
              [-40.070290050289991, -8.160999280932261],
              [-40.070290039581039, -8.160999328346696],
              [-40.070290029127953, -8.160999375817763],
              [-40.070290018931026, -8.160999423344137],
              [-40.070290008990568, -8.160999470924407],
              [-40.070289999306866, -8.160999518557233],
              [-40.070289989880195, -8.1609995662412],
              [-40.070289980710818, -8.160999613974951],
              [-40.070289971799006, -8.160999661757129],
              [-40.070289963145015, -8.160999709586315],
              [-40.0702899547491, -8.160999757461166],
              [-40.07028994661151, -8.160999805380257],
              [-40.070289938732472, -8.160999853342247],
              [-40.070289931112207, -8.160999901345734],
              [-40.070289923750941, -8.160999949389353],
              [-40.070289916648875, -8.160999997471672],
              [-40.070289909806242, -8.161000045591347],
              [-40.07028990322322, -8.161000093746935],
              [-40.070289896899986, -8.161000141937084],
              [-40.070289890836762, -8.161000190160399],
              [-40.070289885033695, -8.161000238415479],
              [-40.070289879490957, -8.161000286700931],
              [-40.070289874208704, -8.161000335015377],
              [-40.070289869187093, -8.161000383357418],
              [-40.070289864426265, -8.161000431725636],
              [-40.070289859926369, -8.161000480118622],
              [-40.070289855687534, -8.161000528535025],
              [-40.070289851709866, -8.161000576973425],
              [-40.070289847993486, -8.161000625432429],
              [-40.070289844538507, -8.161000673910623],
              [-40.070289841345023, -8.161000722406621],
              [-40.070289838413139, -8.161000770919042],
              [-40.070289835742933, -8.16100081944642],
              [-40.070289833334478, -8.161000867987427],
              [-40.070289831187843, -8.161000916540594],
              [-40.070289829303086, -8.161000965104575],
              [-40.070289827680277, -8.161001013677957],
              [-40.070289826319453, -8.161001062259302],
              [-40.070289825220655, -8.161001110847252],
              [-40.070289824383906, -8.161001159440387],
              [-40.070289823809254, -8.161001208037291],
              [-40.07028982349668, -8.161001256636572],
              [-40.07028982344621, -8.161001305236841],
              [-40.070289823657866, -8.161001353836674],
              [-40.070289824131621, -8.161001402434668],
              [-40.070289824867466, -8.161001451029426],
              [-40.070289825865366, -8.161001499619532],
              [-40.070289827125301, -8.161001548203588],
              [-40.070289828647248, -8.161001596780197],
              [-40.07028983043115, -8.161001645347962],
              [-40.070289832476959, -8.161001693905463],
              [-40.070289834784624, -8.161001742451342],
              [-40.070289837354053, -8.161001790984129],
              [-40.070289840185197, -8.161001839502482],
              [-40.070289843277983, -8.161001888004963],
              [-40.070289846632278, -8.161001936490177],
              [-40.07028985024801, -8.161001984956741],
              [-40.0702898541251, -8.161002033403244],
              [-40.070289858263386, -8.161002081828268],
              [-40.07028986266279, -8.16100213023047],
              [-40.070289867323162, -8.161002178608394],
              [-40.070289872244388, -8.161002226960683],
              [-40.070289877426305, -8.161002275285918],
              [-40.070289882868771, -8.161002323582704],
              [-40.070289888571629, -8.161002371849657],
              [-40.070289894534703, -8.161002420085365],
              [-40.070289900757849, -8.161002468288448],
              [-40.07028990724087, -8.16100251645754],
              [-40.070289913983572, -8.161002564591207],
              [-40.070289920985765, -8.161002612688041],
              [-40.070289928247249, -8.161002660746723],
              [-40.070289935767818, -8.161002708765828],
              [-40.070289943547266, -8.161002756743947],
              [-40.07028995158533, -8.161002804679743],
              [-40.070289959881805, -8.161002852571801],
              [-40.070289968436462, -8.161002900418737],
              [-40.070289977249033, -8.161002948219187],
              [-40.070289986319274, -8.161002995971751],
              [-40.070289995646924, -8.161003043675057],
              [-40.070290005231691, -8.161003091327752],
              [-40.070290015073333, -8.161003138928436],
              [-40.070290025171538, -8.161003186475703],
              [-40.070290035526035, -8.161003233968248],
              [-40.070290046136513, -8.161003281404632],
              [-40.070290057002666, -8.161003328783551],
              [-40.07029006812418, -8.16100337610359],
              [-40.070290079500744, -8.161003423363406],
              [-40.070290091132016, -8.161003470561587],
              [-40.070290103017669, -8.16100351769685],
              [-40.070290115157349, -8.161003564767785],
              [-40.07029012755072, -8.161003611773022],
              [-40.070290140197415, -8.161003658711181],
              [-40.070290153097069, -8.161003705581008],
              [-40.070290166249329, -8.161003752381065],
              [-40.070290179653774, -8.161003799110038],
              [-40.070290219514028, -8.161003761047821],
              [-40.070589135523015, -8.162035614324815],
              [-40.070589172946249, -8.162035609548006],
              [-40.070589175208411, -8.162035617367772],
              [-40.070589177463518, -8.162035625189548],
              [-40.070589179711547, -8.162035633013359],
              [-40.070589181952506, -8.162035640839157],
              [-40.07058918418641, -8.162035648666986],
              [-40.070589186413237, -8.162035656496817],
              [-40.070589188632987, -8.162035664328656],
              [-40.070589190845673, -8.162035672162464],
              [-40.070589193051283, -8.162035679998253],
              [-40.070589195249809, -8.162035687836008],
              [-40.070589197441265, -8.162035695675739],
              [-40.07058919962563, -8.162035703517439],
              [-40.070589201802903, -8.162035711361083],
              [-40.070589203973121, -8.162035719206662],
              [-40.070589206136233, -8.162035727054217],
              [-40.070589208292255, -8.162035734903672],
              [-40.070589210441199, -8.162035742755089],
              [-40.070589212583045, -8.162035750608428],
              [-40.070589214717792, -8.162035758463672],
              [-40.070589216845455, -8.16203576632082],
              [-40.070589218966013, -8.162035774179904],
              [-40.07058922107948, -8.162035782040848],
              [-40.070589223185841, -8.162035789903712],
              [-40.070589225285119, -8.162035797768464],
              [-40.070589227377276, -8.162035805635055],
              [-40.070589229462321, -8.162035813503554],
              [-40.070589231540275, -8.162035821373921],
              [-40.070589233611116, -8.162035829246129],
              [-40.070589235674838, -8.162035837120193],
              [-40.070589237731454, -8.162035844996106],
              [-40.070589239780958, -8.162035852873863],
              [-40.070589241823349, -8.162035860753473],
              [-40.070589243858613, -8.162035868634904],
              [-40.070589245886765, -8.162035876518141],
              [-40.070589247907805, -8.162035884403195],
              [-40.070589249921717, -8.162035892290056],
              [-40.070589251928503, -8.162035900178712],
              [-40.070589253928162, -8.16203590806918],
              [-40.070589255920694, -8.162035915961402],
              [-40.070589257906114, -8.162035923855429],
              [-40.070589259884393, -8.162035931751264],
              [-40.070589261855531, -8.162035939648835],
              [-40.070589263819542, -8.16203594754821],
              [-40.070589265776412, -8.162035955449308],
              [-40.07058926772617, -8.162035963352171],
              [-40.070589269668773, -8.162035971256762],
              [-40.070589271604241, -8.162035979163116],
              [-40.070589273532562, -8.162035987071176],
              [-40.070589275453742, -8.162035994981007],
              [-40.070589277367787, -8.16203600289251],
              [-40.070589279274678, -8.162036010805746],
              [-40.070589281174428, -8.162036018720688],
              [-40.070589283067022, -8.162036026637333],
              [-40.070589284952469, -8.162036034555648],
              [-40.070589286830767, -8.162036042475652],
              [-40.07058928870191, -8.162036050397356],
              [-40.070589290565891, -8.16203605832073],
              [-40.070589292422724, -8.162036066245793],
              [-40.070589294272409, -8.162036074172507],
              [-40.070589296114917, -8.162036082100856],
              [-40.070589297950271, -8.162036090030844],
              [-40.070589299778455, -8.162036097962519],
              [-40.07058930159949, -8.162036105895812],
              [-40.07058930341335, -8.162036113830721],
              [-40.070589305220047, -8.162036121767269],
              [-40.070589307019567, -8.162036129705424],
              [-40.070589308811918, -8.162036137645208],
              [-40.070589310597121, -8.16203614558658],
              [-40.070589312375127, -8.162036153529558],
              [-40.07058931414597, -8.162036161474081],
              [-40.070589315909636, -8.162036169420213],
              [-40.070589317666112, -8.162036177367925],
              [-40.070589319415433, -8.162036185317241],
              [-40.070589321157556, -8.162036193268095],
              [-40.070589322892531, -8.162036201220939],
              [-40.07058932522294, -8.162036211957453],
              [-40.070589327540247, -8.162036222696717],
              [-40.070589329844474, -8.16203623343883],
              [-40.070589332135611, -8.162036244183685],
              [-40.07058933441364, -8.162036254931305],
              [-40.070589336678566, -8.16203626568171],
              [-40.07058933893039, -8.162036276434826],
              [-40.070589341169097, -8.162036287190652],
              [-40.070589343394694, -8.162036297949177],
              [-40.070589345607175, -8.162036308710432],
              [-40.070589347806539, -8.162036319474314],
              [-40.070589349992787, -8.162036330240879],
              [-40.070589352165904, -8.162036341010053],
              [-40.070589354325882, -8.162036351781873],
              [-40.070589356472745, -8.162036362556288],
              [-40.070589358606469, -8.162036373333283],
              [-40.070589360727048, -8.162036384112842],
              [-40.07058936283449, -8.16203639489499],
              [-40.070589364928779, -8.16203640567967],
              [-40.07058936700993, -8.162036416466879],
              [-40.070589369077922, -8.162036427256597],
              [-40.070589371132755, -8.162036438048792],
              [-40.070589373174457, -8.162036448843466],
              [-40.070589375202971, -8.162036459640648],
              [-40.07058937721834, -8.162036470440242],
              [-40.070589379220536, -8.162036481242263],
              [-40.070589381209572, -8.162036492046731],
              [-40.070589383185421, -8.162036502853585],
              [-40.070589385148111, -8.162036513662805],
              [-40.070589387097606, -8.162036524474415],
              [-40.070589389033934, -8.162036535288335],
              [-40.070589390957075, -8.16203654610463],
              [-40.070589392867021, -8.162036556923217],
              [-40.070589394763779, -8.162036567744133],
              [-40.070589396647357, -8.162036578567337],
              [-40.07058939851774, -8.162036589392786],
              [-40.070589400374921, -8.162036600220496],
              [-40.070589402218914, -8.162036611050475],
              [-40.070589404049684, -8.162036621882649],
              [-40.070589405867267, -8.162036632717028],
              [-40.070589407671619, -8.162036643553634],
              [-40.070589409462777, -8.162036654392381],
              [-40.070589411240718, -8.162036665233288],
              [-40.070589413005436, -8.162036676076349],
              [-40.070589414756938, -8.162036686921535],
              [-40.070589416495231, -8.162036697768841],
              [-40.070589418220294, -8.16203670861826],
              [-40.070589419932134, -8.162036719469732],
              [-40.070589421630736, -8.162036730323278],
              [-40.070589423316122, -8.162036741178881],
              [-40.070589424988249, -8.162036752036471],
              [-40.07058942664716, -8.162036762896136],
              [-40.07058942829282, -8.162036773757753],
              [-40.070589429925256, -8.162036784621366],
              [-40.070589431544434, -8.162036795486948],
              [-40.070589433150367, -8.162036806354502],
              [-40.070589434743056, -8.16203681722396],
              [-40.070589436322486, -8.162036828095374],
              [-40.070589437888671, -8.162036838968641],
              [-40.070589439441591, -8.162036849843833],
              [-40.070589440981273, -8.16203686072091],
              [-40.070589442507675, -8.162036871599792],
              [-40.070589444020825, -8.162036882480546],
              [-40.070589445520717, -8.162036893363119],
              [-40.070589447007329, -8.162036904247516],
              [-40.070589448480675, -8.162036915133678],
              [-40.070589449940748, -8.162036926021615],
              [-40.070589451387555, -8.162036936911322],
              [-40.070589452821082, -8.162036947802795],
              [-40.070589454241336, -8.162036958695957],
              [-40.07058945564831, -8.16203696959084],
              [-40.070589457042004, -8.162036980487422],
              [-40.070589458422397, -8.162036991385694],
              [-40.070589459789531, -8.162037002285599],
              [-40.070589461143356, -8.162037013187188],
              [-40.070589462483902, -8.162037024090347],
              [-40.070589463811153, -8.162037034995175],
              [-40.070589465125103, -8.162037045901593],
              [-40.070589466425773, -8.162037056809593],
              [-40.070589467713134, -8.162037067719142],
              [-40.070589468987201, -8.162037078630277],
              [-40.070589470247974, -8.162037089542894],
              [-40.070589471495424, -8.162037100457047],
              [-40.07058947272958, -8.162037111372701],
              [-40.070589473950427, -8.162037122289858],
              [-40.070589475157973, -8.162037133208463],
              [-40.070589476352211, -8.162037144128554],
              [-40.070589477533112, -8.162037155050056],
              [-40.070589478700718, -8.162037165972979],
              [-40.070589479855016, -8.162037176897316],
              [-40.070589480995991, -8.162037187823039],
              [-40.070589482123637, -8.162037198750161],
              [-40.070589483237981, -8.162037209678596],
              [-40.070589484338981, -8.162037220608385],
              [-40.070589485426673, -8.162037231539502],
              [-40.070589486501042, -8.162037242471934],
              [-40.07058948756206, -8.16203725340565],
              [-40.070589488609777, -8.162037264340629],
              [-40.070589489644256, -8.162037275275999],
              [-40.070589502918992, -8.162037241272531],
              [-40.070742888392523, -8.163669728720242],
              [-40.07074289043323, -8.163669750918649],
              [-40.070742892250458, -8.163669770488269],
              [-40.070742894025003, -8.163669790061792],
              [-40.070742895756872, -8.163669809639106],
              [-40.070742897446046, -8.163669829220119],
              [-40.070742899092529, -8.163669848804762],
              [-40.070742900696274, -8.163669868392891],
              [-40.070742902257329, -8.163669887984433],
              [-40.070742903775653, -8.163669907579342],
              [-40.070742905251244, -8.163669927177509],
              [-40.070742906684103, -8.163669946778789],
              [-40.070742908074202, -8.16366996638315],
              [-40.070742909421568, -8.163669985990454],
              [-40.070742910726175, -8.163670005600665],
              [-40.070742911988006, -8.1636700252136],
              [-40.070742913207077, -8.163670044829278],
              [-40.070742914383359, -8.163670064447492],
              [-40.070742915516881, -8.163670084068228],
              [-40.070742916607593, -8.163670103691373],
              [-40.070742917655537, -8.16367012331685],
              [-40.070742918660663, -8.163670142944532],
              [-40.070742919623001, -8.163670162574345],
              [-40.070742920542536, -8.163670182206197],
              [-40.070742921419253, -8.163670201840013],
              [-40.070742922253139, -8.163670221475641],
              [-40.070742923044243, -8.163670241113051],
              [-40.070742923792501, -8.163670260752143],
              [-40.070742924497935, -8.163670280392781],
              [-40.070742925160552, -8.16367030003491],
              [-40.070742925780316, -8.163670319678415],
              [-40.070742926357262, -8.16367033932325],
              [-40.07074292689137, -8.163670358969256],
              [-40.070742927382639, -8.163670378616359],
              [-40.070742927831063, -8.163670398264511],
              [-40.070742928236633, -8.163670417913552],
              [-40.070742928599365, -8.163670437563436],
              [-40.070742928919245, -8.163670457214085],
              [-40.070742929196278, -8.163670476865391],
              [-40.070742929430473, -8.163670496517211],
              [-40.070742929621801, -8.163670516169498],
              [-40.070742929770283, -8.163670535822183],
              [-40.070742929875891, -8.163670555475104],
              [-40.070742929938667, -8.163670575128222],
              [-40.070742929958584, -8.163670594781419],
              [-40.070742929935641, -8.163670614434613],
              [-40.070742929869844, -8.163670634087747],
              [-40.070742929761195, -8.163670653740658],
              [-40.070742929609693, -8.163670673393316],
              [-40.070742929415339, -8.163670693045578],
              [-40.070742929178138, -8.163670712697366],
              [-40.070742928898071, -8.163670732348608],
              [-40.070742928575164, -8.163670751999193],
              [-40.070742928209413, -8.163670771649027],
              [-40.070742927800808, -8.163670791298051],
              [-40.070742927349364, -8.163670810946112],
              [-40.070742926855083, -8.163670830593157],
              [-40.070742926317941, -8.163670850239097],
              [-40.070742925737974, -8.163670869883823],
              [-40.070742925115177, -8.163670889527246],
              [-40.07074292444954, -8.163670909169252],
              [-40.070742923741065, -8.163670928809786],
              [-40.070742922989794, -8.163670948448747],
              [-40.070742922195684, -8.163670968086054],
              [-40.070742921358757, -8.16367098772157],
              [-40.07074292047902, -8.163671007355232],
              [-40.070742919556473, -8.16367102698695],
              [-40.070742918591108, -8.163671046616605],
              [-40.070742917582962, -8.163671066244138],
              [-40.070742916531998, -8.163671085869437],
              [-40.070742915438252, -8.163671105492444],
              [-40.070742914301725, -8.163671125113002],
              [-40.070742913122409, -8.163671144731081],
              [-40.070742911900339, -8.163671164346527],
              [-40.07074291063546, -8.163671183959302],
              [-40.070742909327848, -8.163671203569296],
              [-40.070742907977483, -8.163671223176404],
              [-40.07074290658435, -8.163671242780513],
              [-40.070742905148478, -8.163671262381611],
              [-40.070742903669867, -8.163671281979525],
              [-40.070742902148524, -8.163671301574182],
              [-40.07074290058447, -8.163671321165515],
              [-40.070742898977684, -8.163671340753424],
              [-40.070742897328195, -8.163671360337824],
              [-40.070742895636009, -8.163671379918561],
              [-40.070742893901127, -8.163671399495623],
              [-40.070742892123562, -8.163671419068857],
              [-40.070742890303322, -8.163671438638211],
              [-40.070742888440407, -8.163671458203567],
              [-40.070742886534845, -8.163671477764856],
              [-40.070742884586629, -8.16367149732195],
              [-40.070742882595781, -8.163671516874839],
              [-40.070742880562278, -8.16367153642333],
              [-40.070742878486186, -8.163671555967364],
              [-40.070742876367447, -8.16367157550688],
              [-40.070742874206132, -8.163671595041745],
              [-40.070742872002221, -8.1636716145719],
              [-40.070742869755719, -8.163671634097252],
              [-40.070742867466656, -8.163671653617646],
              [-40.070742865135038, -8.163671673133061],
              [-40.070742862760802, -8.163671692643337],
              [-40.070742893691936, -8.163671718384622],
              [-40.070596928625278, -8.164859761484218],
              [-40.07059692958881, -8.164860123241407],
              [-40.070452636611449, -8.166641990447937],
              [-40.070452600339827, -8.166641972066376],
              [-40.07045259746527, -8.166642006730628],
              [-40.070452594456938, -8.166642041383595],
              [-40.070452591314876, -8.16664207602479],
              [-40.070452588039124, -8.166642110653699],
              [-40.070452584629749, -8.166642145269769],
              [-40.070452581086776, -8.166642179872518],
              [-40.070452577410258, -8.166642214461453],
              [-40.070452573600278, -8.166642249036032],
              [-40.070452569656858, -8.166642283595754],
              [-40.070452565580091, -8.166642318140131],
              [-40.070452561370004, -8.166642352668603],
              [-40.070452557026684, -8.166642387180678],
              [-40.070452552550186, -8.166642421675862],
              [-40.070452547940569, -8.166642456153658],
              [-40.070452543197909, -8.166642490613528],
              [-40.070452538322279, -8.166642525054979],
              [-40.070452533313748, -8.166642559477479],
              [-40.070452528172375, -8.166642593880523],
              [-40.070452522898265, -8.166642628263601],
              [-40.070452517491482, -8.166642662626238],
              [-40.070452511952098, -8.166642696967884],
              [-40.070452506280205, -8.166642731288052],
              [-40.070452500475881, -8.166642765586232],
              [-40.070452494539225, -8.166642799861917],
              [-40.070452488470302, -8.166642834114622],
              [-40.070452482269211, -8.166642868343787],
              [-40.070452475936065, -8.166642902548944],
              [-40.070452469470936, -8.166642936729588],
              [-40.070452462873916, -8.166642970885208],
              [-40.070452456145112, -8.166643005015287],
              [-40.07045244928463, -8.166643039119332],
              [-40.070452442292549, -8.166643073196822],
              [-40.070452435168988, -8.166643107247271],
              [-40.07045242791407, -8.166643141270173],
              [-40.070452420527864, -8.166643175265033],
              [-40.0704524130105, -8.166643209231319],
              [-40.07045240536209, -8.166643243168551],
              [-40.070452397582741, -8.166643277076213],
              [-40.070452389672568, -8.166643310953834],
              [-40.070452381631704, -8.166643344800887],
              [-40.070452373460263, -8.166643378616888],
              [-40.070452365158346, -8.166643412401292],
              [-40.070452356726094, -8.166643446153662],
              [-40.070452348163613, -8.166643479873461],
              [-40.070452339471053, -8.166643513560199],
              [-40.070452330648536, -8.16664354721339],
              [-40.070452321696195, -8.166643580832506],
              [-40.07045231261413, -8.166643614417062],
              [-40.070452303402512, -8.166643647966582],
              [-40.070452294061475, -8.166643681480549],
              [-40.070452284591148, -8.166643714958468],
              [-40.070452274991666, -8.166643748399858],
              [-40.070452265263192, -8.166643781804222],
              [-40.07045225540584, -8.166643815171044],
              [-40.070452245419766, -8.166643848499863],
              [-40.07045223530514, -8.16664388179016],
              [-40.070452225062077, -8.166643915041442],
              [-40.070452214690746, -8.166643948253242],
              [-40.070452204191312, -8.166643981425038],
              [-40.070452193563916, -8.16664401455637],
              [-40.070452182808715, -8.166644047646718],
              [-40.070452171925879, -8.166644080695626],
              [-40.070452160915544, -8.166644113702556],
              [-40.0704521497779, -8.166644146667068],
              [-40.070452138513105, -8.166644179588657],
              [-40.070452127121321, -8.16664421246683],
              [-40.070452115602706, -8.166644245301107],
              [-40.070452103957443, -8.166644278091006],
              [-40.070452092185711, -8.166644310836025],
              [-40.070452080287666, -8.166644343535697],
              [-40.070452068263492, -8.166644376189526],
              [-40.070452056113382, -8.166644408797046],
              [-40.070452043837498, -8.166644441357763],
              [-40.070452031436027, -8.1666444738712],
              [-40.070452018909151, -8.166644506336864],
              [-40.070452006257057, -8.166644538754293],
              [-40.070451993479942, -8.166644571123012],
              [-40.070451980577957, -8.166644603442485],
              [-40.070451967551342, -8.166644635712322],
              [-40.070451954400248, -8.166644667932005],
              [-40.070451941124908, -8.166644700101056],
              [-40.070451927725486, -8.166644732218973],
              [-40.070451914202202, -8.166644764285316],
              [-40.070451900555248, -8.166644796299561],
              [-40.070451886784817, -8.166644828261305],
              [-40.07045187289112, -8.166644860170042],
              [-40.070451858874371, -8.166644892025296],
              [-40.070451844734762, -8.166644923826622],
              [-40.070451830472521, -8.166644955573503],
              [-40.070451816087846, -8.166644987265505],
              [-40.070451801580944, -8.166645018902139],
              [-40.070451786952049, -8.166645050482984],
              [-40.070451772201359, -8.166645082007511],
              [-40.070451757329089, -8.166645113475289],
              [-40.070451742335486, -8.166645144885827],
              [-40.07045172722075, -8.166645176238683],
              [-40.070451711985108, -8.166645207533392],
              [-40.070451696628794, -8.16664523876949],
              [-40.070451681152193, -8.166645269945702],
              [-40.070451646853286, -8.166645248902645],
              [-40.069861543048425, -8.167828316378824],
              [-40.069861530820297, -8.167828344873611],
              [-40.069861485144905, -8.167828435168792],
              [-40.069861438459199, -8.167828524949529],
              [-40.069861390768992, -8.167828614204556],
              [-40.069861342080266, -8.167828702922739],
              [-40.069861292399075, -8.167828791092999],
              [-40.069861241731665, -8.167828878704276],
              [-40.069861190084332, -8.167828965745647],
              [-40.06986113746359, -8.167829052206224],
              [-40.069861083875956, -8.167829138075229],
              [-40.069861029328152, -8.167829223341894],
              [-40.069860973827005, -8.167829307995552],
              [-40.069860917379444, -8.167829392025642],
              [-40.069860859992524, -8.167829475421639],
              [-40.069860801673428, -8.167829558173153],
              [-40.069860742429427, -8.167829640269815],
              [-40.069860682267944, -8.167829721701386],
              [-40.06986062119649, -8.167829802457634],
              [-40.069860559222704, -8.167829882528519],
              [-40.069860496354345, -8.167829961904028],
              [-40.069860432599256, -8.167830040574206],
              [-40.069860367965404, -8.167830118529235],
              [-40.069860302460867, -8.16783019575937],
              [-40.069860236093845, -8.167830272254957],
              [-40.06986016887263, -8.167830348006451],
              [-40.069860100805634, -8.167830423004334],
              [-40.069860031901349, -8.167830497239297],
              [-40.069859962168394, -8.167830570702009],
              [-40.069859891615479, -8.167830643383306],
              [-40.069859820251452, -8.167830715274114],
              [-40.069859748085186, -8.167830786365421],
              [-40.069859675125748, -8.167830856648344],
              [-40.069859601382241, -8.167830926114135],
              [-40.069859526863873, -8.167830994754079],
              [-40.069859451579973, -8.167831062559571],
              [-40.069859375539949, -8.167831129522183],
              [-40.0698592987533, -8.167831195633523],
              [-40.069859221229635, -8.16783126088532],
              [-40.069859142978629, -8.167831325269448],
              [-40.069859064010068, -8.167831388777831],
              [-40.069858984333841, -8.16783145140254],
              [-40.069858903959883, -8.167831513135718],
              [-40.069858822898262, -8.167831573969686],
              [-40.069858741159088, -8.16783163389683],
              [-40.069858658752622, -8.16783169290963],
              [-40.069858575689103, -8.167831751000737],
              [-40.069858491978962, -8.167831808162918],
              [-40.069858407632651, -8.167831864388972],
              [-40.0698583226607, -8.167831919671917],
              [-40.069858237073745, -8.167831974004805],
              [-40.069858150882489, -8.167832027380888],
              [-40.069858064097694, -8.167832079793437],
              [-40.069857976730212, -8.167832131235929],
              [-40.069857888790978, -8.167832181701945],
              [-40.069857800290947, -8.167832231185148],
              [-40.069857711241212, -8.167832279679379],
              [-40.069857621652908, -8.16783232717858],
              [-40.069857531537217, -8.167832373676772],
              [-40.069857440905416, -8.167832419168182],
              [-40.069857349768817, -8.167832463647118],
              [-40.069857258138839, -8.16783250710801],
              [-40.069857166026928, -8.167832549545436],
              [-40.069857073444581, -8.167832590954074],
              [-40.069856980403387, -8.167832631328761],
              [-40.06985688691497, -8.167832670664442],
              [-40.069856792991011, -8.167832708956205],
              [-40.069856698643271, -8.167832746199259],
              [-40.069856603883522, -8.167832782388972],
              [-40.069856508723625, -8.167832817520786],
              [-40.069856413175472, -8.167832851590308],
              [-40.069856317250995, -8.167832884593315],
              [-40.069856220962187, -8.167832916525647],
              [-40.069856124321113, -8.167832947383333],
              [-40.069856027339817, -8.16783297716251],
              [-40.069855930030435, -8.167833005859464],
              [-40.069855832405132, -8.167833033470588],
              [-40.069855734476128, -8.167833059992446],
              [-40.069855636255625, -8.167833085421726],
              [-40.069855537755934, -8.167833109755222],
              [-40.069855438989357, -8.167833132989923],
              [-40.069855339968242, -8.167833155122903],
              [-40.069855240704968, -8.167833176151422],
              [-40.069855141211953, -8.167833196072834],
              [-40.069855041501597, -8.16783321488465],
              [-40.069854941586414, -8.167833232584506],
              [-40.06985484147885, -8.16783324917021],
              [-40.069854741191435, -8.167833264639683],
              [-40.069854640736722, -8.167833278990985],
              [-40.06985454012726, -8.167833292222317],
              [-40.069854439375611, -8.167833304332037],
              [-40.069854338494373, -8.167833315318632],
              [-40.069854237496159, -8.167833325180711],
              [-40.069854136393616, -8.167833333917084],
              [-40.069854035199349, -8.16783334152661],
              [-40.069853933926026, -8.167833348008353],
              [-40.069853832586311, -8.167833353361528],
              [-40.06985373119285, -8.167833357585426],
              [-40.069853629758342, -8.167833360679541],
              [-40.06985352829544, -8.167833362643492],
              [-40.069853426816387, -8.167833363477877],
              [-40.069853414828344, -8.167833363501702],
              [-40.069853402840309, -8.167833363509738],
              [-40.069853390852252, -8.167833363501995],
              [-40.069853378864238, -8.167833363478502],
              [-40.069853366876252, -8.167833363439206],
              [-40.069853354888316, -8.167833363384142],
              [-40.069853342900487, -8.167833363313312],
              [-40.069853330912743, -8.167833363226695],
              [-40.069853318925141, -8.167833363124311],
              [-40.069853306937674, -8.167833363006139],
              [-40.069853294950377, -8.167833362872203],
              [-40.069853282963273, -8.16783336272248],
              [-40.069853270976381, -8.167833362556975],
              [-40.069853258989696, -8.167833362375717],
              [-40.069853247003287, -8.167833362178671],
              [-40.069853235017135, -8.16783336196586],
              [-40.069853223031274, -8.16783336173728],
              [-40.069853211045732, -8.1678333614929],
              [-40.069853199060532, -8.167833361232764],
              [-40.06985318707568, -8.167833360956863],
              [-40.069853175091197, -8.167833360665194],
              [-40.069853163107126, -8.167833360357738],
              [-40.069853151123461, -8.167833360034495],
              [-40.069853139140243, -8.167833359695502],
              [-40.069853127157479, -8.167833359340738],
              [-40.069853115175206, -8.167833358970208],
              [-40.069853103193417, -8.167833358583907],
              [-40.069853091212167, -8.16783335818184],
              [-40.06985307923145, -8.167833357764001],
              [-40.069853067251302, -8.167833357330395],
              [-40.069853055271736, -8.167833356881019],
              [-40.069853043292781, -8.167833356415889],
              [-40.069853031314459, -8.16783335593499],
              [-40.069853019336762, -8.167833355438324],
              [-40.069853007359747, -8.167833354925905],
              [-40.069852995383421, -8.167833354397715],
              [-40.069852983407806, -8.167833353853773],
              [-40.069852971432923, -8.16783335329408],
              [-40.069852959458792, -8.167833352718601],
              [-40.069852947485444, -8.167833352127383],
              [-40.069852935512877, -8.167833351520416],
              [-40.069852923541134, -8.167833350897677],
              [-40.069852911570216, -8.167833350259201],
              [-40.069852899600171, -8.167833349604972],
              [-40.069852887630987, -8.167833348934989],
              [-40.069852875662704, -8.167833348249276],
              [-40.069852863695353, -8.16783334754779],
              [-40.069852851728932, -8.167833346830562],
              [-40.06985283976347, -8.167833346097588],
              [-40.069852827798989, -8.167833345348875],
              [-40.069852815835517, -8.167833344584393],
              [-40.069852803873076, -8.167833343804187],
              [-40.069852791911657, -8.16783334300823],
              [-40.069852779951319, -8.167833342196522],
              [-40.069852767992074, -8.167833341369091],
              [-40.069852756033931, -8.167833340525922],
              [-40.069852744076911, -8.167833339667006],
              [-40.069852732121035, -8.167833338792363],
              [-40.069852720166345, -8.167833337901985],
              [-40.069852708212842, -8.167833336995871],
              [-40.069852696260547, -8.167833336074036],
              [-40.069852684309467, -8.167833335136462],
              [-40.069852672359659, -8.167833334183154],
              [-40.069852660411122, -8.167833333214142],
              [-40.069852648463872, -8.167833332229392],
              [-40.069852636517957, -8.167833331228922],
              [-40.069852624573358, -8.167833330212748],
              [-40.069852612630122, -8.167833329180855],
              [-40.069852600688286, -8.167833328133238],
              [-40.069852588747814, -8.167833327069918],
              [-40.069852576808792, -8.167833325990863],
              [-40.069852564871191, -8.167833324896121],
              [-40.069852552935046, -8.167833323785668],
              [-40.069852541000408, -8.167833322659501],
              [-40.069852529067248, -8.167833321517607],
              [-40.069852517135637, -8.167833320360034],
              [-40.069852505205546, -8.167833319186752],
              [-40.069852493277033, -8.167833317997783],
              [-40.069852481350118, -8.167833316793093],
              [-40.069852469424781, -8.167833315572729],
              [-40.069852457501099, -8.167833314336649],
              [-40.069852445579059, -8.167833313084897],
              [-40.069852433658681, -8.167833311817471],
              [-40.069852421740002, -8.167833310534359],
              [-40.069852409823035, -8.167833309235542],
              [-40.069852397907795, -8.167833307921072],
              [-40.069852385994302, -8.167833306590895],
              [-40.069852374082593, -8.167833305245065],
              [-40.069852362172668, -8.167833303883533],
              [-40.069852350264561, -8.167833302506342],
              [-40.069852338358302, -8.167833301113481],
              [-40.069852326453905, -8.167833299704947],
              [-40.069852314551362, -8.167833298280764],
              [-40.069852302650723, -8.16783329684089],
              [-40.069852290752003, -8.167833295385375],
              [-40.06985227885523, -8.167833293914189],
              [-40.069852266960403, -8.167833292427382],
              [-40.069852255067573, -8.167833290924905],
              [-40.069852243176818, -8.167833289406015],
              [-40.069852207320281, -8.167833315253995],
              [-40.06809907247883, -8.167608318688716],
              [-40.068099047980155, -8.167608277396106],
              [-40.068099036089322, -8.16760827587807],
              [-40.068099024196478, -8.167608274375695],
              [-40.068099012301651, -8.16760827288897],
              [-40.068099000404871, -8.167608271417899],
              [-40.068098988506151, -8.167608269962452],
              [-40.068098976605519, -8.167608268522692],
              [-40.068098964702976, -8.167608267098604],
              [-40.068098952798572, -8.16760826569017],
              [-40.068098940892291, -8.167608264297391],
              [-40.068098928984199, -8.167608262920298],
              [-40.068098917074266, -8.167608261558845],
              [-40.068098905162564, -8.167608260213067],
              [-40.068098893249065, -8.167608258882952],
              [-40.068098881333832, -8.167608257568546],
              [-40.068098869416858, -8.167608256269794],
              [-40.068098857498178, -8.167608254986716],
              [-40.068098845577801, -8.167608253719337],
              [-40.068098833655768, -8.167608252467648],
              [-40.068098821732079, -8.167608251231629],
              [-40.068098809806756, -8.1676082500113],
              [-40.068098797879834, -8.167608248806676],
              [-40.068098785951328, -8.167608247617739],
              [-40.068098774021252, -8.167608246444521],
              [-40.068098762089633, -8.167608245286994],
              [-40.068098750156487, -8.167608244145148],
              [-40.068098738221849, -8.167608243019009],
              [-40.068098726285712, -8.167608241908544],
              [-40.068098714348125, -8.167608240813813],
              [-40.068098702409088, -8.167608239734802],
              [-40.068098690468659, -8.167608238671461],
              [-40.068098678526795, -8.167608237623845],
              [-40.068098666583566, -8.167608236591965],
              [-40.06809865463898, -8.167608235575772],
              [-40.068098642693073, -8.167608234575299],
              [-40.068098630745837, -8.167608233590579],
              [-40.068098618797308, -8.16760823262155],
              [-40.068098606847506, -8.167608231668254],
              [-40.068098594896448, -8.167608230730661],
              [-40.06809858294416, -8.167608229808808],
              [-40.068098570990664, -8.16760822890269],
              [-40.068098559035967, -8.167608228012313],
              [-40.068098547080112, -8.167608227137631],
              [-40.068098535123106, -8.167608226278711],
              [-40.06809852316497, -8.167608225435522],
              [-40.068098511205726, -8.16760822460804],
              [-40.068098499245387, -8.167608223796295],
              [-40.068098487284004, -8.1676082230003],
              [-40.068098475321555, -8.167608222220045],
              [-40.068098463358098, -8.167608221455508],
              [-40.068098451393624, -8.167608220706743],
              [-40.068098439428198, -8.16760821997371],
              [-40.068098427461784, -8.167608219256401],
              [-40.068098415494433, -8.167608218554843],
              [-40.068098403526164, -8.167608217869056],
              [-40.068098391557001, -8.167608217198991],
              [-40.068098379586949, -8.167608216544691],
              [-40.068098367616052, -8.16760821590613],
              [-40.068098355644317, -8.167608215283337],
              [-40.068098343671771, -8.167608214676264],
              [-40.068098331698415, -8.167608214084961],
              [-40.068098319724299, -8.167608213509411],
              [-40.068098307749437, -8.167608212949643],
              [-40.068098295773822, -8.16760821240562],
              [-40.068098283797518, -8.16760821187731],
              [-40.068098271820517, -8.16760821136482],
              [-40.068098259842841, -8.167608210868048],
              [-40.068098247864526, -8.167608210387046],
              [-40.068098235885579, -8.1676082099218],
              [-40.068098223906027, -8.167608209472318],
              [-40.068098211925893, -8.167608209038608],
              [-40.06809819994519, -8.167608208620683],
              [-40.068098187963933, -8.167608208218493],
              [-40.068098175982179, -8.167608207832075],
              [-40.068098163999899, -8.167608207461404],
              [-40.068098152017164, -8.167608207106504],
              [-40.068098140033953, -8.167608206767406],
              [-40.068098128050309, -8.167608206444031],
              [-40.068098116066231, -8.167608206136435],
              [-40.06809810408177, -8.167608205844559],
              [-40.068098092096925, -8.167608205568486],
              [-40.068098080111731, -8.167608205308182],
              [-40.068098068126204, -8.167608205063663],
              [-40.068098056140343, -8.167608204834911],
              [-40.068098044154233, -8.167608204621931],
              [-40.068098032167804, -8.167608204424699],
              [-40.068098020181147, -8.167608204243253],
              [-40.068098008194262, -8.167608204077576],
              [-40.068097996207165, -8.167608203927649],
              [-40.068097984219882, -8.167608203793522],
              [-40.068097972232415, -8.167608203675167],
              [-40.06809796024482, -8.167608203572577],
              [-40.068097948257083, -8.167608203485754],
              [-40.068097936269261, -8.167608203414719],
              [-40.068097924281354, -8.167608203359451],
              [-40.068097912293375, -8.167608203319952],
              [-40.068097900305354, -8.167608203296203],
              [-40.068097888317311, -8.167608203288266],
              [-40.068097876329261, -8.167608203296071],
              [-40.068097864341247, -8.167608203320061],
              [-40.068097854733118, -8.167608203350929],
              [-40.068097845125031, -8.167608203391918],
              [-40.068097835516994, -8.167608203443034],
              [-40.068097825909021, -8.167608203504274],
              [-40.068097816301119, -8.1676082035756],
              [-40.068097806693288, -8.167608203657089],
              [-40.068097797085557, -8.167608203748715],
              [-40.06809778747791, -8.167608203850449],
              [-40.068097777870406, -8.167608203962292],
              [-40.068097768263023, -8.167608204084273],
              [-40.068097758655746, -8.167608204216378],
              [-40.068097749048633, -8.167608204358631],
              [-40.068097739441676, -8.167608204510966],
              [-40.068097729834889, -8.167608204673446],
              [-40.068097720228273, -8.167608204846061],
              [-40.068097710621849, -8.16760820502877],
              [-40.068097701015624, -8.16760820522164],
              [-40.068097691409605, -8.16760820542461],
              [-40.068097681803813, -8.167608205637691],
              [-40.068097672198242, -8.167608205860926],
              [-40.06809766259294, -8.167608206094256],
              [-40.068097652987866, -8.167608206337723],
              [-40.068097643383069, -8.167608206591281],
              [-40.068097633778535, -8.167608206854977],
              [-40.068097624174293, -8.167608207128817],
              [-40.068097614570348, -8.16760820741276],
              [-40.068097604966702, -8.167608207706813],
              [-40.06809759536339, -8.167608208011004],
              [-40.068097585760391, -8.167608208325285],
              [-40.068097576157733, -8.167608208649726],
              [-40.068097566555423, -8.167608208984285],
              [-40.068097556953482, -8.167608209328939],
              [-40.068097547351904, -8.167608209683729],
              [-40.068097537750717, -8.167608210048659],
              [-40.068097528149913, -8.167608210423682],
              [-40.068097518549514, -8.167608210808806],
              [-40.068097508949535, -8.167608211204078],
              [-40.068097499349982, -8.167608211609469],
              [-40.068097489750848, -8.167608212024964],
              [-40.068097480152183, -8.167608212450569],
              [-40.068097470553965, -8.167608212886277],
              [-40.068097460956217, -8.167608213332111],
              [-40.068097451358945, -8.167608213788078],
              [-40.068097441762163, -8.167608214254145],
              [-40.068097432165885, -8.167608214730294],
              [-40.068097422570119, -8.167608215216601],
              [-40.068097412974865, -8.167608215712985],
              [-40.068097403380143, -8.167608216219502],
              [-40.068097393785969, -8.167608216736109],
              [-40.068097384192349, -8.167608217262858],
              [-40.068097374599304, -8.167608217799678],
              [-40.068097365006807, -8.167608218346638],
              [-40.06809735541492, -8.167608218903668],
              [-40.068097345823624, -8.167608219470827],
              [-40.068097336232917, -8.167608220048104],
              [-40.06809732664285, -8.167608220635486],
              [-40.068097317053407, -8.167608221232943],
              [-40.068097307464605, -8.167608221840519],
              [-40.068097297876449, -8.167608222458203],
              [-40.068097288288961, -8.16760822308601],
              [-40.068097278702133, -8.167608223723885],
              [-40.068097269115995, -8.167608224371904],
              [-40.068097259530546, -8.167608225029992],
              [-40.068097249945808, -8.16760822569819],
              [-40.06809724036178, -8.167608226376473],
              [-40.06809723077847, -8.167608227064861],
              [-40.06809722119592, -8.167608227763376],
              [-40.068097211614095, -8.167608228471957],
              [-40.06809720203303, -8.167608229190646],
              [-40.06809719245274, -8.167608229919407],
              [-40.068097182873224, -8.167608230658322],
              [-40.068097173294497, -8.167608231407295],
              [-40.068097163716573, -8.167608232166353],
              [-40.068097154139465, -8.167608232935484],
              [-40.068097144563168, -8.167608233714736],
              [-40.06809713498771, -8.167608234504094],
              [-40.068097125413097, -8.167608235303524],
              [-40.068097115839336, -8.167608236113061],
              [-40.068097106266421, -8.167608236932649],
              [-40.068097096694416, -8.167608237762362],
              [-40.068097087123277, -8.167608238602144],
              [-40.068097077553034, -8.167608239451999],
              [-40.0680970679837, -8.167608240311939],
              [-40.06809705841529, -8.167608241181989],
              [-40.068097048847797, -8.167608242062107],
              [-40.068097039281255, -8.167608242952312],
              [-40.068097029715666, -8.167608243852625],
              [-40.068097020151029, -8.167608244762992],
              [-40.068097010587366, -8.167608245683461],
              [-40.068097001024682, -8.167608246613971],
              [-40.068096991462994, -8.167608247554583],
              [-40.068096981902308, -8.167608248505248],
              [-40.068096972342637, -8.167608249466005],
              [-40.068096962784004, -8.167608250436849],
              [-40.068096953226387, -8.167608251417731],
              [-40.068096943669822, -8.167608252408716],
              [-40.068096934114315, -8.167608253409737],
              [-40.068096924559882, -8.167608254420829],
              [-40.068096915006521, -8.167608255442012],
              [-40.068096893837001, -8.167608233998314],
              [-40.065263489115686, -8.167912797212757],
              [-40.063624389210666, -8.168213932693154],
              [-40.063624400970447, -8.168213897934836],
              [-40.063624357620121, -8.168213906010298],
              [-40.063624314309642, -8.168213914295499],
              [-40.063624271040098, -8.168213922790237],
              [-40.063624227812475, -8.168213931494309],
              [-40.063624184627798, -8.168213940407526],
              [-40.06362414148709, -8.168213949529683],
              [-40.063624098391365, -8.168213958860539],
              [-40.063624055341634, -8.168213968399893],
              [-40.06362401233892, -8.168213978147506],
              [-40.063623969384253, -8.168213988103135],
              [-40.06362392647862, -8.168213998266562],
              [-40.063623883623031, -8.168214008637568],
              [-40.063623840818536, -8.168214019215895],
              [-40.063623798066118, -8.168214030001282],
              [-40.063623755366777, -8.168214040993469],
              [-40.063623712721537, -8.168214052192207],
              [-40.063623670131406, -8.168214063597244],
              [-40.06362362759738, -8.168214075208311],
              [-40.06362358512046, -8.168214087025113],
              [-40.063623542701656, -8.168214099047367],
              [-40.063623500341969, -8.168214111274828],
              [-40.063623458042393, -8.168214123707148],
              [-40.063623415803939, -8.168214136344082],
              [-40.063623373627593, -8.168214149185328],
              [-40.063623331514336, -8.168214162230557],
              [-40.063623289465198, -8.168214175479486],
              [-40.063623247481139, -8.168214188931774],
              [-40.063623205563175, -8.168214202587132],
              [-40.063623163712279, -8.168214216445239],
              [-40.063623121929432, -8.168214230505766],
              [-40.063623080215628, -8.168214244768365],
              [-40.063623038571883, -8.168214259232727],
              [-40.063622996999115, -8.168214273898458],
              [-40.063622955498353, -8.16821428876524],
              [-40.063622914070571, -8.168214303832736],
              [-40.063622872716742, -8.168214319100597],
              [-40.063622831437826, -8.168214334568408],
              [-40.063622790234817, -8.168214350235891],
              [-40.063622749108674, -8.168214366102594],
              [-40.063622708060386, -8.168214382168177],
              [-40.063622667090911, -8.168214398432262],
              [-40.063622626201202, -8.168214414894479],
              [-40.063622585392238, -8.16821443155442],
              [-40.063622544665002, -8.168214448411682],
              [-40.063622504020415, -8.168214465465889],
              [-40.06362246345946, -8.16821448271663],
              [-40.063622422983102, -8.168214500163504],
              [-40.063622382592264, -8.168214517806105],
              [-40.063622342287935, -8.168214535643969],
              [-40.063622302071039, -8.16821455367675],
              [-40.063622261942541, -8.168214571903961],
              [-40.06362222190338, -8.168214590325205],
              [-40.063622181954514, -8.168214608940023],
              [-40.063622142096868, -8.168214627747989],
              [-40.063622102331394, -8.168214646748664],
              [-40.063622062659022, -8.168214665941624],
              [-40.063622023080704, -8.168214685326358],
              [-40.063621983597351, -8.168214704902446],
              [-40.063621944209913, -8.16821472466942],
              [-40.063621904919316, -8.168214744626827],
              [-40.063621865726482, -8.168214764774147],
              [-40.063621826632343, -8.168214785110965],
              [-40.063621787637814, -8.168214805636742],
              [-40.06362174874382, -8.168214826351031],
              [-40.063621709951271, -8.168214847253351],
              [-40.063621671261096, -8.168214868343187],
              [-40.063621632674199, -8.168214889620074],
              [-40.063621594191503, -8.168214911083471],
              [-40.063621555813882, -8.168214932732889],
              [-40.063621517542295, -8.168214954567805],
              [-40.063621479377595, -8.168214976587704],
              [-40.063621441320713, -8.168214998792086],
              [-40.063621403372537, -8.168215021180409],
              [-40.063621365533962, -8.168215043752161],
              [-40.063621327805876, -8.168215066506821],
              [-40.063621290189197, -8.168215089443814],
              [-40.063621252684769, -8.168215112562594],
              [-40.063621215293509, -8.168215135862642],
              [-40.063621178016284, -8.168215159343431],
              [-40.063621140853996, -8.168215183004351],
              [-40.063621103807478, -8.168215206844879],
              [-40.063621066877651, -8.168215230864456],
              [-40.063621030065363, -8.168215255062496],
              [-40.063620993371494, -8.168215279438412],
              [-40.063620956796889, -8.168215303991689],
              [-40.063620920342444, -8.168215328721697],
              [-40.063620884008976, -8.168215353627867],
              [-40.063620847797381, -8.168215378709618],
              [-40.063620811708503, -8.168215403966341],
              [-40.063620775743182, -8.168215429397463],
              [-40.063620739902277, -8.168215455002342],
              [-40.06362070418664, -8.168215480780438],
              [-40.063620668597096, -8.168215506731073],
              [-40.063620633134491, -8.168215532853688],
              [-40.063620597799691, -8.16821555914766],
              [-40.063620562593478, -8.16821558561236],
              [-40.063620527516711, -8.168215612247149],
              [-40.063620492570223, -8.168215639051398],
              [-40.063620457754837, -8.168215666024489],
              [-40.063620490778753, -8.168215661301009],
              [-40.062283331862339, -8.169256883619719],
              [-40.062283323862609, -8.169256890657303],
              [-40.062283313474985, -8.169256898758221],
              [-40.0622833030992, -8.169256906874184],
              [-40.062283292735245, -8.169256915005144],
              [-40.062283282383163, -8.169256923151119],
              [-40.062283272042976, -8.169256931312068],
              [-40.062283261714711, -8.169256939487983],
              [-40.062283251398384, -8.169256947678841],
              [-40.062283241094008, -8.169256955884624],
              [-40.062283230801604, -8.169256964105333],
              [-40.062283220521195, -8.169256972340927],
              [-40.062283210252829, -8.169256980591392],
              [-40.062283199996507, -8.169256988856732],
              [-40.062283189752236, -8.169256997136904],
              [-40.062283179520065, -8.169257005431914],
              [-40.062283169299995, -8.169257013741721],
              [-40.062283159092061, -8.169257022066315],
              [-40.062283148896285, -8.169257030405689],
              [-40.062283138712672, -8.169257038759818],
              [-40.06228312854126, -8.169257047128678],
              [-40.062283118382069, -8.169257055512238],
              [-40.062283108235114, -8.169257063910528],
              [-40.062283098100423, -8.169257072323475],
              [-40.062283087977995, -8.169257080751093],
              [-40.062283077867882, -8.169257089193371],
              [-40.062283067770089, -8.16925709765027],
              [-40.062283057684652, -8.169257106121794],
              [-40.062283047611579, -8.169257114607927],
              [-40.062283037550891, -8.169257123108627],
              [-40.062283027502616, -8.169257131623889],
              [-40.062283017466768, -8.169257140153686],
              [-40.062283007443376, -8.169257148698023],
              [-40.06228299743244, -8.169257157256844],
              [-40.062282987434003, -8.169257165830162],
              [-40.062282977448092, -8.169257174417947],
              [-40.062282967474715, -8.169257183020186],
              [-40.062282957513908, -8.169257191636841],
              [-40.062282947565663, -8.169257200267921],
              [-40.062282937630009, -8.169257208913391],
              [-40.062282927706995, -8.169257217573243],
              [-40.062282917796601, -8.169257226247447],
              [-40.062282907898883, -8.16925723493601],
              [-40.062282898013841, -8.16925724363889],
              [-40.062282888141517, -8.169257252356072],
              [-40.062282878281906, -8.169257261087555],
              [-40.062282868435034, -8.169257269833292],
              [-40.062282858600952, -8.169257278593275],
              [-40.062282848779638, -8.169257287367481],
              [-40.062282838971143, -8.169257296155921],
              [-40.062282829175473, -8.169257304958544],
              [-40.062282819392649, -8.169257313775352],
              [-40.062282809622687, -8.169257322606311],
              [-40.062282799865621, -8.169257331451401],
              [-40.062282790121486, -8.169257340310645],
              [-40.062282780390255, -8.169257349183953],
              [-40.062282770671999, -8.169257358071359],
              [-40.06228276096671, -8.169257366972815],
              [-40.06228275127441, -8.169257375888321],
              [-40.062282741595119, -8.169257384817842],
              [-40.062282731928867, -8.169257393761377],
              [-40.062282722275668, -8.169257402718911],
              [-40.062282712635543, -8.16925741169039],
              [-40.062282703008528, -8.169257420675837],
              [-40.062282693394607, -8.169257429675197],
              [-40.062282683793839, -8.169257438688502],
              [-40.062282674206216, -8.169257447715671],
              [-40.06228266463178, -8.169257456756725],
              [-40.062282655070526, -8.169257465811622],
              [-40.062282645522515, -8.169257474880363],
              [-40.062282635987721, -8.169257483962918],
              [-40.062282626466185, -8.169257493059266],
              [-40.062282616957923, -8.169257502169396],
              [-40.062282607462969, -8.169257511293273],
              [-40.06228259798133, -8.169257520430925],
              [-40.062282588513021, -8.169257529582271],
              [-40.062282579058078, -8.169257538747312],
              [-40.062282569616521, -8.169257547926046],
              [-40.062282560188343, -8.169257557118442],
              [-40.062282550773595, -8.169257566324481],
              [-40.062282541372284, -8.169257575544131],
              [-40.062282531984415, -8.169257584777402],
              [-40.062282522610033, -8.16925759402424],
              [-40.062282513249151, -8.169257603284649],
              [-40.062282503901798, -8.169257612558601],
              [-40.06228249456796, -8.169257621846079],
              [-40.062282485247678, -8.169257631147079],
              [-40.062282475940997, -8.169257640461543],
              [-40.062282466647886, -8.169257649789479],
              [-40.062282457368397, -8.169257659130871],
              [-40.062282448102543, -8.169257668485672],
              [-40.062282438850353, -8.169257677853896],
              [-40.062282429611813, -8.169257687235509],
              [-40.062282420386985, -8.169257696630481],
              [-40.062282411175858, -8.16925770603879],
              [-40.062282401978472, -8.169257715460441],
              [-40.062282392794842, -8.169257724895393],
              [-40.062282383624975, -8.169257734343654],
              [-40.0622823744689, -8.169257743805172],
              [-40.062282365326631, -8.169257753279926],
              [-40.062282356198295, -8.169257762766518],
              [-40.062282346726157, -8.169257772637778],
              [-40.062282337269011, -8.169257782523331],
              [-40.0622823278269, -8.169257792423116],
              [-40.062282318399838, -8.169257802337128],
              [-40.062282308987847, -8.169257812265371],
              [-40.06228229959094, -8.169257822207758],
              [-40.062282290209147, -8.16925783216432],
              [-40.062282280842489, -8.169257842135032],
              [-40.062282271490979, -8.169257852119827],
              [-40.06228226215466, -8.169257862118727],
              [-40.062282252833533, -8.169257872131718],
              [-40.062282243527619, -8.169257882158716],
              [-40.06228223423696, -8.169257892199759],
              [-40.06228222496155, -8.169257902254794],
              [-40.062282215701416, -8.169257912323785],
              [-40.062282206456601, -8.169257922406736],
              [-40.062282197227105, -8.16925793250363],
              [-40.062282188012951, -8.169257942614415],
              [-40.062282178814172, -8.169257952739075],
              [-40.062282169630777, -8.169257962877623],
              [-40.0622821604628, -8.169257973030014],
              [-40.062282151310249, -8.169257983196191],
              [-40.062282142173153, -8.169257993376167],
              [-40.062282133051518, -8.169258003569924],
              [-40.062282123945394, -8.169258013777387],
              [-40.062282114854767, -8.169258023998591],
              [-40.062282105779687, -8.169258034233502],
              [-40.06228209672016, -8.169258044482065],
              [-40.062282087676202, -8.169258054744283],
              [-40.062282078647854, -8.16925806502014],
              [-40.06228206963511, -8.169258075309585],
              [-40.062282060638026, -8.169258085612613],
              [-40.062282051656588, -8.169258095929198],
              [-40.062282042690825, -8.169258106259337],
              [-40.062282033740772, -8.169258116602943],
              [-40.062282024806436, -8.169258126960068],
              [-40.062282015887845, -8.169258137330628],
              [-40.062282006985015, -8.169258147714626],
              [-40.062281998097944, -8.169258158112058],
              [-40.062281989226712, -8.169258168522857],
              [-40.062281980371289, -8.169258178947022],
              [-40.062281971531704, -8.16925818938453],
              [-40.062281962707978, -8.16925819983536],
              [-40.062281953900161, -8.169258210299478],
              [-40.062281945108225, -8.169258220776879],
              [-40.062281936332219, -8.169258231267515],
              [-40.062281927572158, -8.169258241771399],
              [-40.062281918828056, -8.169258252288445],
              [-40.062281910099955, -8.169258262818676],
              [-40.062281901387827, -8.169258273362074],
              [-40.06228189269175, -8.169258283918577],
              [-40.062281884011718, -8.16925829448819],
              [-40.062281875347729, -8.169258305070858],
              [-40.062281866699841, -8.16925831566661],
              [-40.062281858068047, -8.169258326275362],
              [-40.06228184945239, -8.169258336897137],
              [-40.062281840852854, -8.169258347531878],
              [-40.062281832269491, -8.169258358179597],
              [-40.062281823702314, -8.169258368840213],
              [-40.062281815151337, -8.169258379513755],
              [-40.062281806616582, -8.169258390200175],
              [-40.062281798098077, -8.16925840089948],
              [-40.062281789595822, -8.16925841161159],
              [-40.062281781109846, -8.169258422336524],
              [-40.062281772640176, -8.169258433074225],
              [-40.062281764186828, -8.169258443824695],
              [-40.062281755749801, -8.169258454587881],
              [-40.062281747329145, -8.169258465363809],
              [-40.062281738924874, -8.169258476152411],
              [-40.062281730536995, -8.169258486953671],
              [-40.062281722165523, -8.169258497767558],
              [-40.062281713810478, -8.169258508594064],
              [-40.062281705471904, -8.169258519433171],
              [-40.0622816971498, -8.169258530284811],
              [-40.062281688844187, -8.169258541149008],
              [-40.062281680555074, -8.169258552025699],
              [-40.062281672282502, -8.16925856291491],
              [-40.062281664026479, -8.169258573816562],
              [-40.062281655787011, -8.169258584730638],
              [-40.062281647564149, -8.169258595657151],
              [-40.062281639357884, -8.169258606596019],
              [-40.06228163116824, -8.169258617547275],
              [-40.062281622995243, -8.169258628510851],
              [-40.062281614838902, -8.16925863948677],
              [-40.062281606699251, -8.169258650474955],
              [-40.062281598576291, -8.169258661475396],
              [-40.062281590470057, -8.169258672488089],
              [-40.062281582380542, -8.169258683512984],
              [-40.062281574307796, -8.169258694550068],
              [-40.062281566251819, -8.169258705599301],
              [-40.062281558212646, -8.169258716660687],
              [-40.062281550190271, -8.169258727734194],
              [-40.062281542184721, -8.16925873881975],
              [-40.062281534196025, -8.169258749917391],
              [-40.06228152622419, -8.169258761027065],
              [-40.062281518269245, -8.169258772148723],
              [-40.062281510331196, -8.169258783282384],
              [-40.062281502410066, -8.169258794427991],
              [-40.062281494505903, -8.169258805585331],
              [-40.062281486619895, -8.169258816760699],
              [-40.062281478750727, -8.169258827947829],
              [-40.062281470898405, -8.169258839146726],
              [-40.062281463062959, -8.169258850357354],
              [-40.062281455244417, -8.169258861579658],
              [-40.062281447442771, -8.169258872813662],
              [-40.062281439658058, -8.169258884059332],
              [-40.062281431890291, -8.169258895316613],
              [-40.062281424139485, -8.169258906585489],
              [-40.062281416405661, -8.169258917865944],
              [-40.062281408688825, -8.169258929157944],
              [-40.062281400989015, -8.169258940461489],
              [-40.062281393306243, -8.169258951776531],
              [-40.062281385640503, -8.169258963103031],
              [-40.06228137799183, -8.169258974440993],
              [-40.06228137036026, -8.169258985790378],
              [-40.062281362745779, -8.16925899715115],
              [-40.062281355148414, -8.169259008523307],
              [-40.062281347568181, -8.169259019906809],
              [-40.062281340005114, -8.169259031301635],
              [-40.062281332459193, -8.16925904270774],
              [-40.062281324930481, -8.169259054125117],
              [-40.062281317418957, -8.169259065553737],
              [-40.062281309924664, -8.169259076993598],
              [-40.062281302447616, -8.169259088444647],
              [-40.062281294987805, -8.169259099906855],
              [-40.062281287545268, -8.169259111380189],
              [-40.062281280120018, -8.169259122864664],
              [-40.062281272712077, -8.169259134360225],
              [-40.062281265321452, -8.169259145866807],
              [-40.062281257948165, -8.169259157384465],
              [-40.062281250592235, -8.169259168913127],
              [-40.062281243253679, -8.169259180452777],
              [-40.062281235932495, -8.169259192003363],
              [-40.062281228628734, -8.169259203564907],
              [-40.062281221342388, -8.169259215137352],
              [-40.062281214073465, -8.169259226720687],
              [-40.062281206822014, -8.169259238314858],
              [-40.062281199588021, -8.169259249919865],
              [-40.062281192371522, -8.169259261535673],
              [-40.062281185172509, -8.169259273162268],
              [-40.062281177991025, -8.169259284799615],
              [-40.06228117082707, -8.169259296447665],
              [-40.062281163680673, -8.169259308106431],
              [-40.062281156551833, -8.169259319775868],
              [-40.062281149440579, -8.169259331455933],
              [-40.062281142346919, -8.169259343146638],
              [-40.062281135270887, -8.169259354847926],
              [-40.06228112821249, -8.169259366559778],
              [-40.062281121171708, -8.169259378282165],
              [-40.062281114148611, -8.169259390015084],
              [-40.062281107143193, -8.169259401758483],
              [-40.062281100155445, -8.169259413512348],
              [-40.06228109318544, -8.169259425276644],
              [-40.062281086233142, -8.169259437051361],
              [-40.062281079298593, -8.169259448836435],
              [-40.062281072381793, -8.169259460631874],
              [-40.062281065482765, -8.169259472437647],
              [-40.062281058601521, -8.169259484253713],
              [-40.06228105173809, -8.169259496080061],
              [-40.062281044892458, -8.169259507916651],
              [-40.062281038064683, -8.169259519763473],
              [-40.062281031254749, -8.169259531620492],
              [-40.062281024462678, -8.169259543487671],
              [-40.062281017688498, -8.169259555364977],
              [-40.062281010932189, -8.169259567252427],
              [-40.0622810041938, -8.169259579149955],
              [-40.062280997473344, -8.169259591057546],
              [-40.06228099077083, -8.169259602975176],
              [-40.062280984086264, -8.16925961490282],
              [-40.062280977419675, -8.169259626840407],
              [-40.062280970771063, -8.169259638787967],
              [-40.06228096414047, -8.169259650745468],
              [-40.062280957527868, -8.169259662712863],
              [-40.062280950933307, -8.169259674690135],
              [-40.0622809443568, -8.169259686677243],
              [-40.062280937798342, -8.169259698674177],
              [-40.062280931257959, -8.169259710680887],
              [-40.062280924735674, -8.169259722697385],
              [-40.06228091823148, -8.169259734723623],
              [-40.062280911745411, -8.16925974675955],
              [-40.062280905277483, -8.169259758805167],
              [-40.062280898827701, -8.169259770860441],
              [-40.062280892396075, -8.16925978292535],
              [-40.062280885982624, -8.169259794999867],
              [-40.062280879587377, -8.169259807083968],
              [-40.062280873210334, -8.16925981917761],
              [-40.062280866851502, -8.169259831280771],
              [-40.06228086051091, -8.169259843393425],
              [-40.062280854188579, -8.169259855515541],
              [-40.062280847884487, -8.169259867647096],
              [-40.062280841598685, -8.16925987978807],
              [-40.062280835331187, -8.169259891938427],
              [-40.062280829081963, -8.169259904098139],
              [-40.062280822851093, -8.169259916267185],
              [-40.062280816638534, -8.169259928445534],
              [-40.062280810444328, -8.169259940633147],
              [-40.062280804268497, -8.169259952830016],
              [-40.062280798111026, -8.169259965036098],
              [-40.062280784919402, -8.169259991374444],
              [-40.061985823300994, -8.16985140411726],
              [-40.061985783608307, -8.169851393988967],
              [-40.061985776907882, -8.169851407393731],
              [-40.061985770185174, -8.169851420787436],
              [-40.061985763440241, -8.169851434170029],
              [-40.061985756673046, -8.169851447541481],
              [-40.061985749883675, -8.169851460901752],
              [-40.061985743072086, -8.169851474250798],
              [-40.061985736238348, -8.169851487588577],
              [-40.061985729382428, -8.169851500915085],
              [-40.061985722504367, -8.169851514230254],
              [-40.061985715604209, -8.169851527534064],
              [-40.061985708681938, -8.16985154082646],
              [-40.061985701737591, -8.16985155410741],
              [-40.061985694771195, -8.169851567376911],
              [-40.061985687782744, -8.16985158063488],
              [-40.061985680772267, -8.169851593881301],
              [-40.061985673739784, -8.169851607116136],
              [-40.061985666685317, -8.169851620339358],
              [-40.061985659608908, -8.169851633550911],
              [-40.061985652510529, -8.169851646750796],
              [-40.061985645390244, -8.169851659938933],
              [-40.061985638248032, -8.169851673115319],
              [-40.061985631083935, -8.169851686279898],
              [-40.061985623897982, -8.169851699432646],
              [-40.06198561669018, -8.169851712573506],
              [-40.061985609460542, -8.169851725702461],
              [-40.061985602209091, -8.169851738819466],
              [-40.061985594935862, -8.169851751924499],
              [-40.061985587640869, -8.169851765017496],
              [-40.061985580324112, -8.169851778098433],
              [-40.061985572985627, -8.169851791167302],
              [-40.061985565625449, -8.169851804224029],
              [-40.061985558243585, -8.16985181726862],
              [-40.061985550840035, -8.169851830300985],
              [-40.061985543414849, -8.169851843321142],
              [-40.061985535968034, -8.169851856329011],
              [-40.061985528499605, -8.169851869324583],
              [-40.061985521009603, -8.169851882307819],
              [-40.06198551349803, -8.169851895278679],
              [-40.061985505964913, -8.169851908237128],
              [-40.061985498410266, -8.169851921183152],
              [-40.061985490834118, -8.16985193411668],
              [-40.061985483236491, -8.169851947037699],
              [-40.061985475617398, -8.16985195994614],
              [-40.061985467976847, -8.169851972842007],
              [-40.061985460314901, -8.169851985725243],
              [-40.061985452631554, -8.16985199859583],
              [-40.061985444926819, -8.169852011453688],
              [-40.06198543720074, -8.169852024298834],
              [-40.061985429453308, -8.169852037131236],
              [-40.061985421684568, -8.169852049950823],
              [-40.061985413894533, -8.169852062757554],
              [-40.061985406083224, -8.169852075551448],
              [-40.061985398250677, -8.169852088332435],
              [-40.061985390396885, -8.169852101100481],
              [-40.061985382521883, -8.169852113855557],
              [-40.061985374625721, -8.169852126597613],
              [-40.06198536670837, -8.169852139326657],
              [-40.061985358769881, -8.169852152042582],
              [-40.061985350810282, -8.169852164745404],
              [-40.06198534282958, -8.169852177435077],
              [-40.061985334827803, -8.169852190111563],
              [-40.061985326804965, -8.169852202774829],
              [-40.061985318761081, -8.169852215424845],
              [-40.061985310696215, -8.169852228061572],
              [-40.061985302610324, -8.16985224068498],
              [-40.061985294503501, -8.169852253295032],
              [-40.061985286375709, -8.169852265891699],
              [-40.061985278226999, -8.169852278474922],
              [-40.061985270057399, -8.169852291044693],
              [-40.061985261866916, -8.169852303600976],
              [-40.061985253655585, -8.169852316143718],
              [-40.061985245423408, -8.169852328672885],
              [-40.061985237170425, -8.169852341188449],
              [-40.061985228896667, -8.169852353690386],
              [-40.061985220602132, -8.169852366178647],
              [-40.061985212286856, -8.169852378653218],
              [-40.061985203950861, -8.169852391114047],
              [-40.061985195594168, -8.169852403561103],
              [-40.061985187216813, -8.169852415994377],
              [-40.061985178818787, -8.16985242841376],
              [-40.061985170400142, -8.169852440819312],
              [-40.061985161960891, -8.169852453210943],
              [-40.061985153501077, -8.169852465588646],
              [-40.061985145020692, -8.169852477952361],
              [-40.061985136519766, -8.169852490302072],
              [-40.061985127998341, -8.169852502637751],
              [-40.061985119456409, -8.169852514959354],
              [-40.061985110894028, -8.169852527266857],
              [-40.061985102311198, -8.169852539560178],
              [-40.061985093707968, -8.169852551839373],
              [-40.061985085084324, -8.169852564104328],
              [-40.061985076440315, -8.169852576355026],
              [-40.06198506777595, -8.169852588591457],
              [-40.061985059091271, -8.169852600813568],
              [-40.061985050386298, -8.169852613021348],
              [-40.061985041661046, -8.169852625214746],
              [-40.061985032915544, -8.169852637393726],
              [-40.061985024149799, -8.169852649558269],
              [-40.061985015363852, -8.169852661708649],
              [-40.06198499924642, -8.169852683887752],
              [-40.061984983061684, -8.169852706018125],
              [-40.061984966809753, -8.169852728099567],
              [-40.06198495049081, -8.169852750131879],
              [-40.061984934104999, -8.169852772114876],
              [-40.061984917652453, -8.169852794048317],
              [-40.061984901133336, -8.169852815932055],
              [-40.06198488454779, -8.169852837765815],
              [-40.06198486789598, -8.169852859549451],
              [-40.061984851178053, -8.169852881282752],
              [-40.061984834394167, -8.169852902965523],
              [-40.061984817544463, -8.16985292459756],
              [-40.061984800629105, -8.169852946178656],
              [-40.061984783648242, -8.16985296770865],
              [-40.061984766602045, -8.169852989187298],
              [-40.061984749490655, -8.169853010614437],
              [-40.06198473231423, -8.169853031989845],
              [-40.061984715072931, -8.169853053313336],
              [-40.06198469776691, -8.169853074584706],
              [-40.061984680396343, -8.169853095803772],
              [-40.061984662961365, -8.169853116970346],
              [-40.061984645462154, -8.16985313808423],
              [-40.061984627898866, -8.169853159145216],
              [-40.061984610271651, -8.169853180153122],
              [-40.061984592580693, -8.16985320110776],
              [-40.06198457482612, -8.169853222008911],
              [-40.061984557008152, -8.169853242856437],
              [-40.061984539126883, -8.169853263650092],
              [-40.061984521182531, -8.169853284389715],
              [-40.061984503175239, -8.169853305075113],
              [-40.061984485105164, -8.169853325706097],
              [-40.061984466972476, -8.169853346282457],
              [-40.061984448777366, -8.169853366804038],
              [-40.061984430519963, -8.169853387270605],
              [-40.061984412200459, -8.169853407682035],
              [-40.061984393819003, -8.169853428038124],
              [-40.061984375375793, -8.169853448338626],
              [-40.061984356870973, -8.169853468583385],
              [-40.061984338304725, -8.169853488772251],
              [-40.061984319677201, -8.169853508905002],
              [-40.061984300988605, -8.169853528981466],
              [-40.061984282239074, -8.169853549001465],
              [-40.061984263428783, -8.169853568964825],
              [-40.061984244557934, -8.169853588871318],
              [-40.061984225626695, -8.169853608720814],
              [-40.061984206635195, -8.16985362851309],
              [-40.061984187583654, -8.169853648247996],
              [-40.061984168472229, -8.169853667925327],
              [-40.061984149301104, -8.169853687544917],
              [-40.061984130070449, -8.169853707106599],
              [-40.061984110780429, -8.169853726610146],
              [-40.061984091431235, -8.169853746055429],
              [-40.061984072023051, -8.16985376544223],
              [-40.061984052556049, -8.169853784770408],
              [-40.061984033030392, -8.169853804039763],
              [-40.061984013446278, -8.169853823250126],
              [-40.061983993803871, -8.169853842401313],
              [-40.06198397410337, -8.169853861493184],
              [-40.061983954344939, -8.169853880525494],
              [-40.061983934528755, -8.169853899498149],
              [-40.061983914655031, -8.169853918410919],
              [-40.061983894723902, -8.169853937263664],
              [-40.06198387473561, -8.169853956056171],
              [-40.061983854690276, -8.169853974788284],
              [-40.061983834588133, -8.169853993459864],
              [-40.061983814429325, -8.169854012070717],
              [-40.061983794214065, -8.169854030620645],
              [-40.061983773942522, -8.169854049109508],
              [-40.061983753614911, -8.169854067537136],
              [-40.061983733231372, -8.169854085903349],
              [-40.061983712792141, -8.169854104207992],
              [-40.061983692297382, -8.169854122450902],
              [-40.061983671747257, -8.169854140631884],
              [-40.061983651142, -8.169854158750818],
              [-40.061983630481777, -8.16985417680746],
              [-40.06198360976677, -8.169854194801735],
              [-40.061983588997187, -8.169854212733403],
              [-40.061983568173211, -8.169854230602379],
              [-40.061983547295036, -8.169854248408408],
              [-40.061983526362845, -8.169854266151409],
              [-40.061983505376823, -8.16985428383116],
              [-40.061983484337198, -8.169854301447492],
              [-40.061983463244104, -8.16985431900029],
              [-40.061983442097798, -8.169854336489401],
              [-40.061983420898443, -8.169854353914602],
              [-40.06198339964623, -8.16985437127579],
              [-40.06198337834136, -8.169854388572782],
              [-40.061983356984022, -8.169854405805415],
              [-40.061983335574418, -8.169854422973543],
              [-40.061983314112751, -8.169854440077007],
              [-40.061983292599201, -8.169854457115648],
              [-40.061983271033981, -8.169854474089314],
              [-40.061983249417281, -8.169854490997823],
              [-40.061983227749295, -8.169854507841054],
              [-40.061983206030234, -8.169854524618856],
              [-40.061983184260292, -8.169854541331057],
              [-40.061983162439653, -8.169854557977496],
              [-40.061983140568543, -8.169854574558029],
              [-40.061983118647206, -8.169854591072239],
              [-40.061983108646949, -8.169854576647532],
              [-40.061398723659885, -8.170293478667075],
              [-40.061398699652742, -8.17029347809509],
              [-40.061398698544735, -8.170293478926908],
              [-40.061398651339708, -8.170293514126698],
              [-40.061398603906191, -8.170293549020226],
              [-40.061398556246196, -8.170293583606004],
              [-40.061398508361734, -8.170293617882594],
              [-40.061398460254829, -8.170293651848558],
              [-40.061398411927485, -8.170293685502413],
              [-40.061398363381791, -8.170293718842785],
              [-40.061398314619751, -8.170293751868256],
              [-40.061398265643433, -8.170293784577437],
              [-40.061398216454904, -8.170293816968938],
              [-40.061398167056254, -8.1702938490414],
              [-40.061398117449563, -8.17029388079346],
              [-40.061398067636922, -8.170293912223784],
              [-40.061398017620405, -8.170293943331039],
              [-40.061397967402165, -8.170293974113934],
              [-40.061397916984298, -8.170294004571133],
              [-40.061397866368928, -8.17029403470139],
              [-40.061397815558202, -8.170294064503413],
              [-40.061397764554265, -8.170294093975938],
              [-40.061397713359263, -8.170294123117726],
              [-40.061397661975356, -8.170294151927539],
              [-40.061397610404725, -8.170294180404202],
              [-40.061397558649531, -8.170294208546501],
              [-40.061397506711948, -8.170294236353209],
              [-40.061397454594186, -8.170294263823171],
              [-40.061397402298446, -8.170294290955269],
              [-40.061397349826919, -8.170294317748303],
              [-40.061397297181834, -8.170294344201173],
              [-40.061397244365409, -8.170294370312742],
              [-40.061397191379861, -8.17029439608193],
              [-40.061397138227441, -8.170294421507633],
              [-40.061397084910396, -8.170294446588782],
              [-40.061397031430936, -8.170294471324329],
              [-40.061396977791382, -8.170294495713208],
              [-40.061396923993925, -8.170294519754417],
              [-40.061396870040902, -8.170294543446907],
              [-40.061396815934543, -8.170294566789714],
              [-40.061396761677152, -8.17029458978185],
              [-40.061396707271015, -8.170294612422317],
              [-40.061396652718415, -8.170294634710183],
              [-40.061396598021688, -8.170294656644497],
              [-40.061396543183108, -8.170294678224352],
              [-40.061396488204998, -8.170294699448799],
              [-40.061396433089676, -8.170294720317001],
              [-40.061396377839479, -8.170294740828027],
              [-40.061396322456723, -8.170294760981038],
              [-40.061396266943753, -8.170294780775157],
              [-40.06139621130292, -8.17029480020955],
              [-40.061396155536556, -8.170294819283439],
              [-40.061396099647013, -8.170294837995993],
              [-40.061396043636677, -8.170294856346404],
              [-40.061395987507872, -8.170294874333935],
              [-40.061395931263, -8.170294891957784],
              [-40.061395874904399, -8.17029490921724],
              [-40.061395818434491, -8.170294926111554],
              [-40.061395761855628, -8.170294942640046],
              [-40.061395705170199, -8.170294958801986],
              [-40.061395648380625, -8.170294974596686],
              [-40.061395591489244, -8.170294990023514],
              [-40.061395534498509, -8.170295005081813],
              [-40.061395477410805, -8.170295019770922],
              [-40.061395420228557, -8.17029503409025],
              [-40.061395362954144, -8.170295048039177],
              [-40.061395305590011, -8.170295061617109],
              [-40.061395248138567, -8.170295074823487],
              [-40.061395190602241, -8.170295087657745],
              [-40.061395132983456, -8.170295100119342],
              [-40.061395075284651, -8.170295112207748],
              [-40.061395017508246, -8.170295123922461],
              [-40.061394959656702, -8.170295135262977],
              [-40.061394901732449, -8.170295146228833],
              [-40.061394843737908, -8.170295156819551],
              [-40.06139478567556, -8.170295167034684],
              [-40.061394727547842, -8.170295176873827],
              [-40.061394669357206, -8.170295186336528],
              [-40.061394611106103, -8.170295195422403],
              [-40.061394552796997, -8.170295204131099],
              [-40.061394494432349, -8.170295212462191],
              [-40.061394436014623, -8.170295220415388],
              [-40.061394377546279, -8.170295227990298],
              [-40.061394319029787, -8.170295235186655],
              [-40.061394260467615, -8.17029524200411],
              [-40.061394201862221, -8.170295248442374],
              [-40.061394143216106, -8.170295254501211],
              [-40.06139408453172, -8.170295260180348],
              [-40.061394025811545, -8.170295265479535],
              [-40.061393967058073, -8.170295270398555],
              [-40.061393908273772, -8.170295274937223],
              [-40.061393849461105, -8.170295279095306],
              [-40.061393790622589, -8.170295282872679],
              [-40.061393731760681, -8.170295286269143],
              [-40.061393672877877, -8.170295289284565],
              [-40.061393613976648, -8.170295291918814],
              [-40.061393555059503, -8.170295294171787],
              [-40.061393496128908, -8.170295296043406],
              [-40.061393437187341, -8.17029529753353],
              [-40.061393378237312, -8.170295298642174],
              [-40.061393319281301, -8.170295299369249],
              [-40.061393260321637, -8.17029529971524],
              [-40.061393149220962, -8.170295299329222],
              [-40.061393038133346, -8.170295297588272],
              [-40.061392927075431, -8.170295294492661],
              [-40.061392816063858, -8.170295290042848],
              [-40.061392705115281, -8.170295284239524],
              [-40.0613925942463, -8.170295277083511],
              [-40.061392483473533, -8.170295268575918],
              [-40.061392372813565, -8.170295258718028],
              [-40.061392262282986, -8.170295247511282],
              [-40.061392151898353, -8.170295234957406],
              [-40.061392041676214, -8.17029522105822],
              [-40.061391931633068, -8.170295205815867],
              [-40.061391821785413, -8.170295189232577],
              [-40.061391712149707, -8.170295171310855],
              [-40.061391602742354, -8.170295152053399],
              [-40.061391493579762, -8.170295131463098],
              [-40.061391384678295, -8.170295109543014],
              [-40.061391276054252, -8.170295086296443],
              [-40.061391167723933, -8.170295061726865],
              [-40.061391059703524, -8.170295035837976],
              [-40.061390952009255, -8.170295008633639],
              [-40.061390844657232, -8.170294980117914],
              [-40.061390737663544, -8.170294950295093],
              [-40.061390631044226, -8.17029491916964],
              [-40.061390524815238, -8.170294886746245],
              [-40.061390418992524, -8.170294853029713],
              [-40.061390313591936, -8.170294818025116],
              [-40.061390208629227, -8.170294781737711],
              [-40.06139010412015, -8.170294744172951],
              [-40.061390000080365, -8.17029470533644],
              [-40.061389896525462, -8.170294665233996],
              [-40.061389793470944, -8.170294623871628],
              [-40.061389690932238, -8.170294581255545],
              [-40.06138958892474, -8.170294537392108],
              [-40.061389487463714, -8.170294492287915],
              [-40.061389386564365, -8.170294445949715],
              [-40.061389286241806, -8.17029439838446],
              [-40.061389186511057, -8.17029434959923],
              [-40.061389087387091, -8.170294299601403],
              [-40.061388988884744, -8.170294248398404],
              [-40.061388891018751, -8.170294195997959],
              [-40.0613887938038, -8.170294142407883],
              [-40.061388697254458, -8.170294087636199],
              [-40.061388601385161, -8.170294031691144],
              [-40.061388506210321, -8.170293974581071],
              [-40.061388411744154, -8.170293916314566],
              [-40.061388318000823, -8.170293856900335],
              [-40.061388224994388, -8.170293796347304],
              [-40.061388132738763, -8.170293734664513],
              [-40.061388041247803, -8.170293671861232],
              [-40.061387950535178, -8.170293607946869],
              [-40.061387860614495, -8.170293542930974],
              [-40.061387771499227, -8.1702934768233],
              [-40.061387683202717, -8.170293409633752],
              [-40.061387595738196, -8.170293341372417],
              [-40.061387509118781, -8.170293272049509],
              [-40.061387423357438, -8.170293201675397],
              [-40.061387338467014, -8.170293130260632],
              [-40.061387254460236, -8.170293057815924],
              [-40.061387171349679, -8.170292984352091],
              [-40.061387089147807, -8.170292909880203],
              [-40.061387007866934, -8.170292834411377],
              [-40.06138692751923, -8.170292757956933],
              [-40.061386848116733, -8.170292680528298],
              [-40.061386769671344, -8.170292602137117],
              [-40.061386692194823, -8.170292522795096],
              [-40.061386615698758, -8.170292442514135],
              [-40.061386540194619, -8.170292361306261],
              [-40.061386465693722, -8.170292279183661],
              [-40.061386392207247, -8.170292196158604],
              [-40.061386319746177, -8.170292112243557],
              [-40.061386248321362, -8.170292027451078],
              [-40.061386177943511, -8.17029194179389],
              [-40.061386108623189, -8.1702918552848],
              [-40.061386040370763, -8.17029176793679],
              [-40.061385973196458, -8.170291679762938],
              [-40.061385907110342, -8.170291590776438],
              [-40.061385842122313, -8.170291500990627],
              [-40.061385778242112, -8.170291410418971],
              [-40.061385715479318, -8.170291319075051],
              [-40.061385653843317, -8.170291226972532],
              [-40.061385593343346, -8.170291134125229],
              [-40.061385533988471, -8.170291040547019],
              [-40.061385475787603, -8.170290946251962],
              [-40.061385418749438, -8.170290851254148],
              [-40.061385362882525, -8.170290755567844],
              [-40.061385308195248, -8.170290659207362],
              [-40.061385254695793, -8.170290562187166],
              [-40.061385202392159, -8.170290464521759],
              [-40.061385151292214, -8.170290366225794],
              [-40.061385101403587, -8.170290267313987],
              [-40.061385052733769, -8.170290167801165],
              [-40.061385005290042, -8.170290067702259],
              [-40.061384959079511, -8.170289967032216],
              [-40.061384914109091, -8.170289865806145],
              [-40.061384870385574, -8.170289764039207],
              [-40.061384827915447, -8.170289661746661],
              [-40.061384786705098, -8.170289558943839],
              [-40.061384746761028, -8.17028945564568],
              [-40.061384734706131, -8.170289445557252],
              [-40.060941791084915, -8.169122843700075],
              [-40.060941830556231, -8.169122820880789],
              [-40.060941830187993, -8.169122819911106],
              [-40.060941773405538, -8.169122674235096],
              [-40.060941714105404, -8.169122529558424],
              [-40.060941652305296, -8.169122385924252],
              [-40.060941588023674, -8.169122243375453],
              [-40.06094152127973, -8.16912210195458],
              [-40.060941452093395, -8.169121961703883],
              [-40.060941380485311, -8.169121822665231],
              [-40.060941306476863, -8.169121684880121],
              [-40.060941230090151, -8.169121548389718],
              [-40.060941151347976, -8.169121413234725],
              [-40.06094107027387, -8.169121279455537],
              [-40.060940986891993, -8.169121147092083],
              [-40.060940901227262, -8.169121016183889],
              [-40.06094081330528, -8.169120886770013],
              [-40.060940723152264, -8.169120758889125],
              [-40.060940630795145, -8.169120632579403],
              [-40.060940536261469, -8.16912050787853],
              [-40.060940439579504, -8.169120384823776],
              [-40.060940340778089, -8.169120263451823],
              [-40.060940239886733, -8.169120143798949],
              [-40.06094013693555, -8.169120025900888],
              [-40.060940031955276, -8.169119909792814],
              [-40.060939924977255, -8.169119795509404],
              [-40.060939816033446, -8.169119683084766],
              [-40.060939705156336, -8.169119572552459],
              [-40.060939592379071, -8.169119463945512],
              [-40.060939477735296, -8.169119357296335],
              [-40.060939361259244, -8.169119252636792],
              [-40.06093924298569, -8.169119149998096],
              [-40.060939122949947, -8.169119049410888],
              [-40.060939001187855, -8.16911895090524],
              [-40.060938877735765, -8.169118854510529],
              [-40.060938752630534, -8.169118760255518],
              [-40.060938625909515, -8.169118668168405],
              [-40.06093849761055, -8.169118578276656],
              [-40.060938367771932, -8.169118490607104],
              [-40.060938236432428, -8.169118405185916],
              [-40.060938103631258, -8.169118322038619],
              [-40.060937969408052, -8.169118241189992],
              [-40.060937833802889, -8.169118162664226],
              [-40.060937696856293, -8.169118086484751],
              [-40.060937558609098, -8.169118012674289],
              [-40.060937419102622, -8.169117941254902],
              [-40.060937278378489, -8.169117872247895],
              [-40.060937136478721, -8.169117805673883],
              [-40.060936993445679, -8.169117741552732],
              [-40.060936849322076, -8.169117679903591],
              [-40.06093670415094, -8.169117620744881],
              [-40.060936557975609, -8.169117564094227],
              [-40.060936410839737, -8.169117509968583],
              [-40.060936262787216, -8.169117458384113],
              [-40.060936113862311, -8.169117409356163],
              [-40.060935964109433, -8.169117362899438],
              [-40.060935813573316, -8.169117319027746],
              [-40.060935662298895, -8.169117277754232],
              [-40.060935510331333, -8.1691172390912],
              [-40.060935357716005, -8.169117203050185],
              [-40.060935204498485, -8.169117169641964],
              [-40.060935050724503, -8.1691171388765],
              [-40.060934896439981, -8.169117110762976],
              [-40.060934741690971, -8.169117085309805],
              [-40.060934586523686, -8.169117062524577],
              [-40.060934430984439, -8.169117042414079],
              [-40.060934275119699, -8.169117024984335],
              [-40.060934118975965, -8.169117010240553],
              [-40.060933962599869, -8.169116998187118],
              [-40.060933806038115, -8.169116988827648],
              [-40.060933649337407, -8.169116982164907],
              [-40.060933492544571, -8.16911697820087],
              [-40.060933335706409, -8.169116976936785],
              [-40.060933178869711, -8.169116978372983],
              [-40.06093302208135, -8.169116982509033],
              [-40.060932865388118, -8.169116989343742],
              [-40.060932708836795, -8.16911699887501],
              [-40.060932552474121, -8.169117011100061],
              [-40.060932396346779, -8.169117026015192],
              [-40.060932240501373, -8.169117043615971],
              [-40.060932084984472, -8.169117063897122],
              [-40.060931929842461, -8.169117086852628],
              [-40.060931775121688, -8.169117112475597],
              [-40.060931620868345, -8.169117140758392],
              [-40.060931467128455, -8.169117171692585],
              [-40.060931313947954, -8.169117205268929],
              [-40.060931161372551, -8.169117241477382],
              [-40.060931009447827, -8.169117280307161],
              [-40.060930858219102, -8.169117321746656],
              [-40.060930707731565, -8.169117365783505],
              [-40.060930558030144, -8.16911741240455],
              [-40.060930409159504, -8.16911746159586],
              [-40.06093026116411, -8.169117513342798],
              [-40.060930114088137, -8.169117567629867],
              [-40.060929967975518, -8.169117624440865],
              [-40.060929822869866, -8.169117683758868],
              [-40.060929678814489, -8.169117745566121],
              [-40.060929535852409, -8.169117809844181],
              [-40.060929394026317, -8.169117876573877],
              [-40.060929253378539, -8.169117945735259],
              [-40.060929113951076, -8.169118017307692],
              [-40.060928975785572, -8.169118091269814],
              [-40.06092898023995, -8.169118083958304],
              [-40.059300805701405, -8.170007922338215],
              [-40.059300771615902, -8.170007940235436],
              [-40.059300751870033, -8.170007950895959],
              [-40.059300732097732, -8.170007961507746],
              [-40.059300712299112, -8.170007972070698],
              [-40.059300692474309, -8.170007982584767],
              [-40.059300672623429, -8.170007993049898],
              [-40.059300652746614, -8.170008003466011],
              [-40.059300632843971, -8.170008013833048],
              [-40.059300612915607, -8.170008024150931],
              [-40.059300592961677, -8.170008034419624],
              [-40.059300572982281, -8.17000804463906],
              [-40.059300552977547, -8.17000805480917],
              [-40.059300532947596, -8.170008064929871],
              [-40.059300512892563, -8.170008075001128],
              [-40.059300492812554, -8.170008085022854],
              [-40.05930047270769, -8.170008094995039],
              [-40.059300452578121, -8.170008104917573],
              [-40.059300432423946, -8.170008114790413],
              [-40.0593004122453, -8.170008124613501],
              [-40.059300392042296, -8.170008134386757],
              [-40.059300371815063, -8.170008144110145],
              [-40.059300351563742, -8.170008153783581],
              [-40.059300331288419, -8.170008163407013],
              [-40.059300310989265, -8.170008172980397],
              [-40.059300290666357, -8.17000818250367],
              [-40.059300270319874, -8.170008191976775],
              [-40.059300249949878, -8.170008201399655],
              [-40.059300229556534, -8.170008210772226],
              [-40.059300209139955, -8.170008220094461],
              [-40.059300188700284, -8.17000822936628],
              [-40.059300168237627, -8.170008238587641],
              [-40.059300147752118, -8.170008247758492],
              [-40.059300127243873, -8.170008256878763],
              [-40.059300106713032, -8.170008265948407],
              [-40.059300086159702, -8.170008274967365],
              [-40.059300065584033, -8.170008283935582],
              [-40.059300044986124, -8.170008292852996],
              [-40.059300024366131, -8.170008301719575],
              [-40.059300003724154, -8.170008310535241],
              [-40.059299983060328, -8.170008319299951],
              [-40.059299962374787, -8.170008328013646],
              [-40.059299941667661, -8.170008336676283],
              [-40.059299920939054, -8.170008345287803],
              [-40.059299900189117, -8.170008353848146],
              [-40.059299879417971, -8.170008362357271],
              [-40.059299858625735, -8.170008370815085],
              [-40.05929983781256, -8.170008379221608],
              [-40.05929981697853, -8.170008387576743],
              [-40.05929979612381, -8.170008395880448],
              [-40.059299775248512, -8.170008404132677],
              [-40.059299754352779, -8.17000841233336],
              [-40.059299733436724, -8.170008420482466],
              [-40.059299712500483, -8.170008428579942],
              [-40.059299691544183, -8.170008436625736],
              [-40.059299670567952, -8.170008444619796],
              [-40.059299649571898, -8.170008452562062],
              [-40.059299628556197, -8.170008460452509],
              [-40.05929960752092, -8.170008468291085],
              [-40.059299586466246, -8.17000847607771],
              [-40.059299565392294, -8.170008483812389],
              [-40.059299544299165, -8.170008491495045],
              [-40.059299523187015, -8.170008499125629],
              [-40.059299502055957, -8.170008506704088],
              [-40.059299480906134, -8.170008514230396],
              [-40.059299459737673, -8.17000852170448],
              [-40.059299438550703, -8.170008529126322],
              [-40.05929941734535, -8.170008536495871],
              [-40.059299396121745, -8.170008543813063],
              [-40.059299374880027, -8.170008551077894],
              [-40.059299353620311, -8.170008558290284],
              [-40.059299332342746, -8.170008565450175],
              [-40.05929931104744, -8.170008572557572],
              [-40.059299289734533, -8.170008579612372],
              [-40.05929926840416, -8.170008586614591],
              [-40.059299247056458, -8.170008593564148],
              [-40.059299225691539, -8.170008600461022],
              [-40.059299204309553, -8.170008607305126],
              [-40.059299182910621, -8.170008614096464],
              [-40.059299161494884, -8.170008620835004],
              [-40.059299140062457, -8.170008627520673],
              [-40.059299118613474, -8.170008634153438],
              [-40.059299097148077, -8.170008640733254],
              [-40.059299075666388, -8.170008647260094],
              [-40.059299054168555, -8.170008653733897],
              [-40.059299032654707, -8.170008660154645],
              [-40.059299011124942, -8.170008666522303],
              [-40.059298989579439, -8.170008672836802],
              [-40.059298968018282, -8.170008679098114],
              [-40.05929894644165, -8.170008685306227],
              [-40.059298924849656, -8.170008691461067],
              [-40.059298903242428, -8.170008697562613],
              [-40.059298881620101, -8.170008703610849],
              [-40.05929885998281, -8.170008709605707],
              [-40.05929883833069, -8.170008715547151],
              [-40.059298816663869, -8.170008721435163],
              [-40.059298794982482, -8.170008727269698],
              [-40.059298773286642, -8.170008733050699],
              [-40.05929875157652, -8.170008738778151],
              [-40.059298729852216, -8.170008744452023],
              [-40.059298708113936, -8.170008750071871],
              [-40.059298686355739, -8.170008755641099],
              [-40.059298664583743, -8.170008761156545],
              [-40.059298642798105, -8.170008766618199],
              [-40.059298620998931, -8.170008772026017],
              [-40.059298599186377, -8.170008777379982],
              [-40.059298577360565, -8.17000878268006],
              [-40.059298555521643, -8.170008787926195],
              [-40.059298533669725, -8.170008793118377],
              [-40.059298511804954, -8.170008798256584],
              [-40.059298489927485, -8.17000880334073],
              [-40.059298468037433, -8.170008808370872],
              [-40.059298446134903, -8.170008813346902],
              [-40.059298424220088, -8.170008818268821],
              [-40.059298402293088, -8.170008823136598],
              [-40.059298380354051, -8.170008827950195],
              [-40.05929835840309, -8.170008832709602],
              [-40.059298336440357, -8.170008837414757],
              [-40.059298314465991, -8.170008842065632],
              [-40.059298292480122, -8.170008846662245],
              [-40.059298270482877, -8.170008851204528],
              [-40.059298248474413, -8.170008855692478],
              [-40.059298226454857, -8.170008860126043],
              [-40.059298204424316, -8.17000886450521],
              [-40.059298182382946, -8.170008868829946],
              [-40.059298160330897, -8.170008873100228],
              [-40.059298138268296, -8.170008877316024],
              [-40.059298116195258, -8.170008881477303],
              [-40.059298094111952, -8.170008885584062],
              [-40.059298072018485, -8.170008889636247],
              [-40.059298049915014, -8.170008893633865],
              [-40.059298027801646, -8.170008897576862],
              [-40.059298005678549, -8.170008901465234],
              [-40.059297983545854, -8.170008905298968],
              [-40.059297961403672, -8.170008909078012],
              [-40.059297939252168, -8.170008912802333],
              [-40.05929791709147, -8.170008916471961],
              [-40.059297894921691, -8.170008920086831],
              [-40.059297872742995, -8.170008923646924],
              [-40.05929785055551, -8.17000892715221],
              [-40.059297828359384, -8.1700089306027],
              [-40.059297806154724, -8.170008933998346],
              [-40.059297783941695, -8.170008937339132],
              [-40.059297761720416, -8.170008940625056],
              [-40.059297739491029, -8.170008943856066],
              [-40.059297717253685, -8.170008947032182],
              [-40.059297695008489, -8.170008950153331],
              [-40.059297672755612, -8.170008953219552],
              [-40.059297650495161, -8.170008956230772],
              [-40.059297628227306, -8.17000895918703],
              [-40.059297605952146, -8.170008962088254],
              [-40.059297583669839, -8.170008964934459],
              [-40.059297561380518, -8.170008967725618],
              [-40.059297539084334, -8.17000897046171],
              [-40.059297516781399, -8.170008973142712],
              [-40.05929749447187, -8.170008975768633],
              [-40.059297472155869, -8.170008978339419],
              [-40.059297449833551, -8.170008980855098],
              [-40.059297427505044, -8.170008983315611],
              [-40.059297405170462, -8.170008985720965],
              [-40.059297382829982, -8.170008988071171],
              [-40.059297360483711, -8.170008990366165],
              [-40.059297338131806, -8.170008992605991],
              [-40.059297315774394, -8.170008994790562],
              [-40.059297293411632, -8.170008996919902],
              [-40.059297271043611, -8.170008998994046],
              [-40.059297248670525, -8.170009001012893],
              [-40.059297226292479, -8.170009002976482],
              [-40.059297203909608, -8.170009004884779],
              [-40.059297181522069, -8.170009006737804],
              [-40.059297159129976, -8.170009008535516],
              [-40.059297136733498, -8.170009010277919],
              [-40.059297114332722, -8.170009011964977],
              [-40.05929709192786, -8.170009013596729],
              [-40.059297069518976, -8.170009015173132],
              [-40.059297047106249, -8.170009016694157],
              [-40.059297024689805, -8.170009018159838],
              [-40.059297002269787, -8.170009019570154],
              [-40.059296979846337, -8.170009020925072],
              [-40.059296957419541, -8.170009022224598],
              [-40.059296934989632, -8.170009023468722],
              [-40.059296912556675, -8.170009024657457],
              [-40.059296890120834, -8.170009025790769],
              [-40.059296867682242, -8.170009026868652],
              [-40.059296845241036, -8.170009027891115],
              [-40.059296822797357, -8.170009028858148],
              [-40.059296800351333, -8.170009029769751],
              [-40.059296777903128, -8.170009030625899],
              [-40.059296755452841, -8.170009031426599],
              [-40.059296733000636, -8.170009032171828],
              [-40.059296710546654, -8.170009032861611],
              [-40.05929668809101, -8.170009033495919],
              [-40.059296665633873, -8.170009034074761],
              [-40.059296643175358, -8.170009034598134],
              [-40.059296620715614, -8.170009035066034],
              [-40.059296598254768, -8.170009035478468],
              [-40.05929657579297, -8.170009035835415],
              [-40.059296553330363, -8.170009036136888],
              [-40.059296530867051, -8.170009036382874],
              [-40.059296508403207, -8.170009036573404],
              [-40.05929646444163, -8.170009036794584],
              [-40.058854779434654, -8.17001019290975],
              [-40.058854742806446, -8.170010193005593],
              [-40.058854671196436, -8.170010192621447],
              [-40.058854599591697, -8.170010191674418],
              [-40.058854527996708, -8.170010190164581],
              [-40.058854456415879, -8.170010188092009],
              [-40.058854384853696, -8.170010185456807],
              [-40.05885431331459, -8.170010182259233],
              [-40.05885424180304, -8.170010178499378],
              [-40.058854170323485, -8.170010174177584],
              [-40.058854098880367, -8.170010169294008],
              [-40.058854027478141, -8.170010163849025],
              [-40.058853956121254, -8.170010157842958],
              [-40.058853884814141, -8.17001015127617],
              [-40.058853813561228, -8.170010144149101],
              [-40.058853742366964, -8.170010136462148],
              [-40.058853671235795, -8.170010128215814],
              [-40.058853600172107, -8.170010119410636],
              [-40.058853529180361, -8.170010110047098],
              [-40.058853458264956, -8.170010100125873],
              [-40.058853387430318, -8.170010089647496],
              [-40.058853316680832, -8.170010078612618],
              [-40.058853246020917, -8.170010067022014],
              [-40.058853175454985, -8.17001005487632],
              [-40.058853104987406, -8.170010042176347],
              [-40.05885303462258, -8.170010028922835],
              [-40.058852964364867, -8.170010015116627],
              [-40.05885289421866, -8.170010000758626],
              [-40.058852824188321, -8.170009985849637],
              [-40.058852754278192, -8.170009970390693],
              [-40.058852684492649, -8.170009954382664],
              [-40.058852614836013, -8.170009937826567],
              [-40.058852545312625, -8.170009920723508],
              [-40.058852475926841, -8.170009903074472],
              [-40.058852406682938, -8.170009884880566],
              [-40.058852337585222, -8.170009866142959],
              [-40.058852268638034, -8.170009846862767],
              [-40.058852199845632, -8.170009827041234],
              [-40.05885213121234, -8.170009806679579],
              [-40.058852062742382, -8.170009785779042],
              [-40.058851994440026, -8.170009764340977],
              [-40.058851926309536, -8.170009742366677],
              [-40.058851858355155, -8.170009719857541],
              [-40.058851790581102, -8.170009696814912],
              [-40.058851722991605, -8.170009673240287],
              [-40.058851655590864, -8.170009649135096],
              [-40.058851588383071, -8.170009624500871],
              [-40.058851521372411, -8.170009599339124],
              [-40.058851454563069, -8.170009573651448],
              [-40.058851387959173, -8.170009547439383],
              [-40.058851321564909, -8.170009520704605],
              [-40.058851255384369, -8.170009493448763],
              [-40.058851189421695, -8.170009465673539],
              [-40.058851123680988, -8.170009437380706],
              [-40.05885105816634, -8.170009408571984],
              [-40.058850992881808, -8.170009379249221],
              [-40.0588509278315, -8.170009349414153],
              [-40.058850863019416, -8.170009319068729],
              [-40.058850798449626, -8.170009288214786],
              [-40.058850734126118, -8.170009256854255],
              [-40.058850670052912, -8.170009224989061],
              [-40.05885060623401, -8.170009192621219],
              [-40.058850542673376, -8.170009159752746],
              [-40.058850479374939, -8.170009126385667],
              [-40.058850416342672, -8.170009092522086],
              [-40.058850353580489, -8.17000905816408],
              [-40.058850291092277, -8.170009023313819],
              [-40.058850228881944, -8.170008987973459],
              [-40.058850166953356, -8.170008952145203],
              [-40.058850105310363, -8.170008915831223],
              [-40.058850043956816, -8.170008879033878],
              [-40.058849982896533, -8.17000884175537],
              [-40.058849922133284, -8.170008803998089],
              [-40.0588498616709, -8.170008765764347],
              [-40.058849801513098, -8.17000872705651],
              [-40.058849741663643, -8.170008687877031],
              [-40.058849682126244, -8.170008648228318],
              [-40.058849622904646, -8.170008608112846],
              [-40.058849564002486, -8.170008567533126],
              [-40.058849505423481, -8.170008526491648],
              [-40.05884944717122, -8.170008484991019],
              [-40.058849389249367, -8.170008443033753],
              [-40.05884933166152, -8.17000840062251],
              [-40.058849274411244, -8.170008357759931],
              [-40.058849217502136, -8.170008314448665],
              [-40.058849160937719, -8.170008270691417],
              [-40.058849104721496, -8.170008226490905],
              [-40.058849048857013, -8.17000818184988],
              [-40.058848993347702, -8.170008136771132],
              [-40.058848938197031, -8.170008091257454],
              [-40.058848883408437, -8.170008045311706],
              [-40.058848828985333, -8.17000799893667],
              [-40.058848774931079, -8.170007952135329],
              [-40.058848721249092, -8.17000790491052],
              [-40.058848667942655, -8.170007857265221],
              [-40.05884861501513, -8.170007809202405],
              [-40.058848562469777, -8.170007760725039],
              [-40.058848510309915, -8.170007711836154],
              [-40.058848458538741, -8.170007662538781],
              [-40.058848407159495, -8.170007612836006],
              [-40.058848356175396, -8.170007562730859],
              [-40.058848382164989, -8.170007586884253],
              [-40.057805094449499, -8.168974141614207],
              [-40.057805066617526, -8.168974113110751],
              [-40.05780503640603, -8.168974083325828],
              [-40.057805006054934, -8.168974053682136],
              [-40.057804975564878, -8.168974024180397],
              [-40.057804944936535, -8.168973994821194],
              [-40.057804914170582, -8.168973965605154],
              [-40.057804883267686, -8.168973936532998],
              [-40.057804852228529, -8.168973907605313],
              [-40.057804821053793, -8.168973878822737],
              [-40.057804789744154, -8.168973850185859],
              [-40.057804758300314, -8.168973821695385],
              [-40.057804726722942, -8.168973793351869],
              [-40.057804695012734, -8.168973765156018],
              [-40.057804663170401, -8.168973737108365],
              [-40.057804631196632, -8.168973709209565],
              [-40.057804599092115, -8.168973681460196],
              [-40.057804566857563, -8.168973653860924],
              [-40.057804534493691, -8.16897362641229],
              [-40.057804502001211, -8.16897359911496],
              [-40.057804469380805, -8.168973571969499],
              [-40.057804436633226, -8.168973544976497],
              [-40.057804403759164, -8.168973518136568],
              [-40.057804370759357, -8.168973491450261],
              [-40.057804337634522, -8.168973464918187],
              [-40.057804304385378, -8.168973438540949],
              [-40.057804271012671, -8.168973412319097],
              [-40.057804237517111, -8.168973386253221],
              [-40.057804203899437, -8.168973360343855],
              [-40.057804170160409, -8.168973334591575],
              [-40.057804136300732, -8.168973308996987],
              [-40.057804102321178, -8.168973283560639],
              [-40.057804068222474, -8.168973258283069],
              [-40.057804034005379, -8.168973233164833],
              [-40.057803999670639, -8.168973208206465],
              [-40.057803965218987, -8.168973183408557],
              [-40.057803930651218, -8.168973158771664],
              [-40.05780389596805, -8.168973134296268],
              [-40.057803861170264, -8.168973109982904],
              [-40.057803826258613, -8.16897308583215],
              [-40.057803791233887, -8.168973061844504],
              [-40.057803756096817, -8.168973038020539],
              [-40.057803720848192, -8.168973014360708],
              [-40.057803685488786, -8.16897299086558],
              [-40.057803650019373, -8.168972967535659],
              [-40.057803614440736, -8.168972944371433],
              [-40.057803578753642, -8.168972921373454],
              [-40.057803542958879, -8.168972898542195],
              [-40.05780350705723, -8.168972875878168],
              [-40.057803471049482, -8.168972853381856],
              [-40.057803434936439, -8.168972831053752],
              [-40.057803398718846, -8.168972808894376],
              [-40.057803362397564, -8.168972786904165],
              [-40.057803325973346, -8.168972765083611],
              [-40.057803289446994, -8.168972743433264],
              [-40.057803252819319, -8.168972721953505],
              [-40.057803216091116, -8.168972700644844],
              [-40.057803179263182, -8.168972679507766],
              [-40.057803142336368, -8.168972658542705],
              [-40.057803105311429, -8.168972637750114],
              [-40.057803068189195, -8.168972617130482],
              [-40.057803030970497, -8.168972596684256],
              [-40.057802993656125, -8.168972576411827],
              [-40.05780295624691, -8.168972556313713],
              [-40.057802918743661, -8.168972536390315],
              [-40.057802881147225, -8.168972516642103],
              [-40.057802843458411, -8.168972497069502],
              [-40.057802805678037, -8.16897247767289],
              [-40.05780276780694, -8.168972458452734],
              [-40.057802729845946, -8.168972439409462],
              [-40.057802691795906, -8.168972420543469],
              [-40.057802653657617, -8.168972401855193],
              [-40.057802615431939, -8.168972383345006],
              [-40.057802577119716, -8.168972365013341],
              [-40.057802538721766, -8.168972346860613],
              [-40.057802500238957, -8.168972328887193],
              [-40.057802461672111, -8.168972311093507],
              [-40.057802423022089, -8.168972293479893],
              [-40.057802384289715, -8.16897227604678],
              [-40.057802345475864, -8.168972258794536],
              [-40.057802306581372, -8.168972241723562],
              [-40.057802267607109, -8.168972224834185],
              [-40.057802228553896, -8.168972208126819],
              [-40.057802189422617, -8.168972191601798],
              [-40.057802150214115, -8.16897217525951],
              [-40.057802110929266, -8.168972159100303],
              [-40.057802071568915, -8.168972143124504],
              [-40.057802032133935, -8.16897212733249],
              [-40.057801992625173, -8.168972111724624],
              [-40.057801953043523, -8.168972096301207],
              [-40.057801913389817, -8.168972081062657],
              [-40.057801873664957, -8.168972066009193],
              [-40.057801833869817, -8.168972051141198],
              [-40.057801794005222, -8.168972036459023],
              [-40.057801754072095, -8.168972021962988],
              [-40.057801714071296, -8.168972007653345],
              [-40.057801674003684, -8.168971993530473],
              [-40.05780163387017, -8.1689719795947],
              [-40.057801593671613, -8.16897196584625],
              [-40.057801553408893, -8.168971952285492],
              [-40.057801513082886, -8.168971938912684],
              [-40.057801548808158, -8.168971980316186],
              [-40.056910525317051, -8.168678840056137],
              [-40.056791014388629, -8.168724914962457],
              [-40.05679101319916, -8.168724462764098],
              [-40.056776827860645, -8.169611900019499],
              [-40.055718749798061, -8.169890595457622],
              [-40.055718793946774, -8.169890554628713],
              [-40.055718743219074, -8.169890568145314],
              [-40.0557186925707, -8.169890581954119],
              [-40.055718642003349, -8.16989059605465],
              [-40.055718591518719, -8.169890610446414],
              [-40.05571854111848, -8.169890625128932],
              [-40.055718490804338, -8.16989064010173],
              [-40.055718440577976, -8.169890655364284],
              [-40.055718390441058, -8.169890670916098],
              [-40.055718340395295, -8.169890686756645],
              [-40.055718290442307, -8.169890702885416],
              [-40.055718240583836, -8.169890719301836],
              [-40.055718190821494, -8.169890736005394],
              [-40.05571814115698, -8.169890752995514],
              [-40.055718091591928, -8.169890770271614],
              [-40.055718042128021, -8.169890787833124],
              [-40.055717992766894, -8.16989080567946],
              [-40.055717943510224, -8.169890823810034],
              [-40.055717894359631, -8.169890842224213],
              [-40.055717845316792, -8.169890860921408],
              [-40.05571779638332, -8.16989087990099],
              [-40.055717747560855, -8.169890899162297],
              [-40.055717698851048, -8.169890918704716],
              [-40.055717650255531, -8.169890938527592],
              [-40.055717601775903, -8.169890958630219],
              [-40.055717553413814, -8.169890979011969],
              [-40.055717505170868, -8.169890999672166],
              [-40.055717457048694, -8.169891020610089],
              [-40.055717409048874, -8.169891041825041],
              [-40.055717361173031, -8.169891063316292],
              [-40.05571731342279, -8.169891085083183],
              [-40.055717265799714, -8.169891107124952],
              [-40.055717218305404, -8.169891129440852],
              [-40.05571717094147, -8.169891152030143],
              [-40.055717123709471, -8.169891174892069],
              [-40.055717076611003, -8.169891198025892],
              [-40.055717029647646, -8.169891221430804],
              [-40.055716982820975, -8.169891245106015],
              [-40.055716936132534, -8.169891269050749],
              [-40.055716889583898, -8.169891293264216],
              [-40.055716843176626, -8.1698913177456],
              [-40.055716796912279, -8.169891342494036],
              [-40.055716750792392, -8.169891367508763],
              [-40.055716704818508, -8.169891392788919],
              [-40.055716658992189, -8.169891418333622],
              [-40.055716613314921, -8.169891444142076],
              [-40.055716567788288, -8.16989147021339],
              [-40.055716522413796, -8.169891496546695],
              [-40.055716477192931, -8.169891523141095],
              [-40.055716432127234, -8.169891549995709],
              [-40.055716387218226, -8.169891577109661],
              [-40.055716342467377, -8.169891604482002],
              [-40.055716297876216, -8.169891632111851],
              [-40.055716253446228, -8.169891659998244],
              [-40.05571620917889, -8.169891688140304],
              [-40.055716165075673, -8.169891716537027],
              [-40.055716121138076, -8.169891745187524],
              [-40.055716077367556, -8.169891774090779],
              [-40.055716033765584, -8.16989180324587],
              [-40.055715990333617, -8.169891832651805],
              [-40.055715947073111, -8.169891862307594],
              [-40.055715903985515, -8.169891892212226],
              [-40.055715861072272, -8.169891922364744],
              [-40.055715818334811, -8.16989195276412],
              [-40.05571577577458, -8.169891983409348],
              [-40.055715733392958, -8.169892014299366],
              [-40.055715691191402, -8.169892045433182],
              [-40.055715649171326, -8.169892076809726],
              [-40.055715607334136, -8.16989210842795],
              [-40.055715565681218, -8.169892140286823],
              [-40.055715524213966, -8.169892172385229],
              [-40.05571548293377, -8.169892204722137],
              [-40.055715441842025, -8.169892237296452],
              [-40.055715400940102, -8.169892270107086],
              [-40.05571536022935, -8.169892303152928],
              [-40.05571531971114, -8.169892336432897],
              [-40.055715279386845, -8.169892369945856],
              [-40.0557152392578, -8.16989240369068],
              [-40.055715199325348, -8.169892437666265],
              [-40.055715159590839, -8.169892471871442],
              [-40.055715120055581, -8.169892506305088],
              [-40.055715080720908, -8.169892540966048],
              [-40.055715041588151, -8.16989257585316],
              [-40.055715002658594, -8.169892610965269],
              [-40.055714963933561, -8.169892646301182],
              [-40.055714925414321, -8.169892681859711],
              [-40.055714887102198, -8.169892717639698],
              [-40.055714848998448, -8.1698927536399],
              [-40.055714811104359, -8.16989278985916],
              [-40.055714773421187, -8.169892826296218],
              [-40.055714735950211, -8.169892862949906],
              [-40.055714698692668, -8.16989289981894],
              [-40.055714661649823, -8.169892936902155],
              [-40.055714624822897, -8.169892974198236],
              [-40.055714588213128, -8.169893011706012],
              [-40.055714551821744, -8.169893049424143],
              [-40.055714515649974, -8.169893087351429],
              [-40.055714479699006, -8.169893125486574],
              [-40.055714443970054, -8.169893163828304],
              [-40.055714408464326, -8.169893202375361],
              [-40.055714399845769, -8.16989322957682],
              [-40.05507396089515, -8.170592647319245],
              [-40.055073919598435, -8.170592602428691],
              [-40.055073900501917, -8.170592623347236],
              [-40.055073881471124, -8.170592644325177],
              [-40.055073862506262, -8.170592665362296],
              [-40.055073843607495, -8.170592686458386],
              [-40.055073824775036, -8.170592707613244],
              [-40.055073806009048, -8.170592728826673],
              [-40.055073787309709, -8.170592750098482],
              [-40.055073768677218, -8.170592771428439],
              [-40.055073750111767, -8.170592792816336],
              [-40.055073731613504, -8.170592814261958],
              [-40.055073713182644, -8.170592835765101],
              [-40.055073694819335, -8.170592857325564],
              [-40.055073676523797, -8.17059287894312],
              [-40.055073658296159, -8.17059290061758],
              [-40.055073640136634, -8.170592922348716],
              [-40.055073622045384, -8.17059294413632],
              [-40.055073604022603, -8.170592965980202],
              [-40.055073586068453, -8.170592987880099],
              [-40.055073568183118, -8.170593009835827],
              [-40.055073550366757, -8.170593031847178],
              [-40.055073532619566, -8.17059305391391],
              [-40.055073514941704, -8.170593076035821],
              [-40.055073497333339, -8.170593098212709],
              [-40.055073479794672, -8.170593120444343],
              [-40.055073462325844, -8.170593142730496],
              [-40.055073444927032, -8.170593165070994],
              [-40.055073427598415, -8.170593187465553],
              [-40.055073410340157, -8.170593209914005],
              [-40.055073393152419, -8.170593232416092],
              [-40.055073376035374, -8.170593254971651],
              [-40.055073358989198, -8.170593277580391],
              [-40.055073342014055, -8.170593300242144],
              [-40.055073325110108, -8.170593322956677],
              [-40.055073308277521, -8.170593345723752],
              [-40.055073291516464, -8.17059336854318],
              [-40.055073274827095, -8.170593391414675],
              [-40.055073258209582, -8.170593414338057],
              [-40.055073241664076, -8.170593437313119],
              [-40.055073225190753, -8.170593460339589],
              [-40.055073208789757, -8.170593483417266],
              [-40.055073192461272, -8.170593506545949],
              [-40.055073176205447, -8.17059352972537],
              [-40.055073160022438, -8.170593552955339],
              [-40.055073143912395, -8.170593576235602],
              [-40.055073127875488, -8.170593599565958],
              [-40.055073111911874, -8.170593622946143],
              [-40.055073096021701, -8.170593646375929],
              [-40.055073080205133, -8.170593669855119],
              [-40.055073064462313, -8.170593693383491],
              [-40.055073048793425, -8.17059371696079],
              [-40.055073033198582, -8.170593740586751],
              [-40.055073017677948, -8.170593764261202],
              [-40.055073002231687, -8.17059378798387],
              [-40.05507298685994, -8.170593811754564],
              [-40.055072971562872, -8.170593835573035],
              [-40.055072956340624, -8.170593859439034],
              [-40.055072941193323, -8.170593883352364],
              [-40.055072926121149, -8.170593907312767],
              [-40.055072911124235, -8.170593931319987],
              [-40.055072896202731, -8.170593955373825],
              [-40.055072881356779, -8.170593979474027],
              [-40.055072866586521, -8.170594003620341],
              [-40.05507285189212, -8.170594027812582],
              [-40.055072837273705, -8.170594052050481],
              [-40.055072822731411, -8.170594076333796],
              [-40.055072808265393, -8.170594100662278],
              [-40.055072793875794, -8.170594125035727],
              [-40.055072779562742, -8.170594149453887],
              [-40.055072765326393, -8.170594173916516],
              [-40.055072751166875, -8.170594198423375],
              [-40.055072737084323, -8.170594222974199],
              [-40.055072723078894, -8.170594247568792],
              [-40.055072709150693, -8.170594272206891],
              [-40.055072695299899, -8.170594296888291],
              [-40.055072681526603, -8.170594321612713],
              [-40.05507266783097, -8.170594346379922],
              [-40.05507265421312, -8.170594371189651],
              [-40.055072640673188, -8.170594396041711],
              [-40.055072627211317, -8.170594420935815],
              [-40.055072613827619, -8.170594445871746],
              [-40.055072600522251, -8.170594470849251],
              [-40.055072587295314, -8.170594495868075],
              [-40.055072574146948, -8.170594520927985],
              [-40.05507256107731, -8.170594546028743],
              [-40.055072548086478, -8.170594571170094],
              [-40.055072535174617, -8.170594596351789],
              [-40.055072522341838, -8.17059462157359],
              [-40.055072509588257, -8.170594646835241],
              [-40.055072496914022, -8.170594672136495],
              [-40.05507248431924, -8.170594697477124],
              [-40.055072471804039, -8.170594722856867],
              [-40.055072459368539, -8.170594748275468],
              [-40.055072447012861, -8.170594773732686],
              [-40.055072434737149, -8.170594799228278],
              [-40.055072422541471, -8.170594824761999],
              [-40.055072410426, -8.170594850333584],
              [-40.05507239839082, -8.170594875942776],
              [-40.05507238643608, -8.170594901589361],
              [-40.05507237456186, -8.170594927273047],
              [-40.055072333014074, -8.170594912572934],
              [-40.054723051630852, -8.171353622958041],
              [-40.054490157960295, -8.171879236329639],
              [-40.054490144088952, -8.171879199716297],
              [-40.05449013891365, -8.17187921141719],
              [-40.054490133754953, -8.171879223125369],
              [-40.054490128612883, -8.171879234840818],
              [-40.054490123487426, -8.171879246563497],
              [-40.054490118378617, -8.171879258293364],
              [-40.054490113286448, -8.171879270030422],
              [-40.054490108210956, -8.171879281774654],
              [-40.054490103152119, -8.171879293526038],
              [-40.05449009810998, -8.171879305284516],
              [-40.05449009308451, -8.171879317050079],
              [-40.05449008807576, -8.171879328822721],
              [-40.054490083083707, -8.171879340602398],
              [-40.054490078108373, -8.171879352389105],
              [-40.054490073149779, -8.171879364182809],
              [-40.054490068207926, -8.17187937598348],
              [-40.054490063282799, -8.171879387791096],
              [-40.054490058374448, -8.171879399605661],
              [-40.054490053482873, -8.171879411427124],
              [-40.054490048608066, -8.171879423255461],
              [-40.054490043750064, -8.171879435090649],
              [-40.054490038908853, -8.171879446932682],
              [-40.054490034084445, -8.171879458781493],
              [-40.054490029276842, -8.171879470637116],
              [-40.054490024486093, -8.171879482499502],
              [-40.054490019712162, -8.171879494368618],
              [-40.054490014955093, -8.171879506244442],
              [-40.054490010214863, -8.171879518126945],
              [-40.054490005491516, -8.171879530016144],
              [-40.05449000078503, -8.171879541911965],
              [-40.054489996095448, -8.171879553814419],
              [-40.054489991422749, -8.171879565723462],
              [-40.054489986766939, -8.171879577639045],
              [-40.054489982128061, -8.171879589561193],
              [-40.054489977506094, -8.1718796014899],
              [-40.054489972901067, -8.171879613425064],
              [-40.054489968312964, -8.171879625366717],
              [-40.05448996374183, -8.171879637314833],
              [-40.054489959187642, -8.171879649269354],
              [-40.054489954650421, -8.171879661230301],
              [-40.054489950130197, -8.171879673197605],
              [-40.054489945626926, -8.17187968517128],
              [-40.054489941140673, -8.171879697151283],
              [-40.054489936671416, -8.17187970913759],
              [-40.054489932219177, -8.171879721130189],
              [-40.054489927783955, -8.171879733129058],
              [-40.054489923365765, -8.171879745134147],
              [-40.054489918964606, -8.171879757145447],
              [-40.054489914580508, -8.171879769162933],
              [-40.054489910213462, -8.17187978118657],
              [-40.054489905863484, -8.17187979321637],
              [-40.054489901530573, -8.171879805252285],
              [-40.054489897214758, -8.171879817294288],
              [-40.054489892916017, -8.171879829342346],
              [-40.054489888634393, -8.171879841396457],
              [-40.054489884369865, -8.171879853456586],
              [-40.05448988012246, -8.171879865522687],
              [-40.05448987589218, -8.171879877594792],
              [-40.054489871679031, -8.171879889672848],
              [-40.054489867483035, -8.171879901756821],
              [-40.054489863304184, -8.171879913846681],
              [-40.054489859142492, -8.171879925942424],
              [-40.054489854997975, -8.171879938044016],
              [-40.054489850870617, -8.171879950151416],
              [-40.054489846760454, -8.171879962264629],
              [-40.054489842667486, -8.171879974383629],
              [-40.054489838591699, -8.171879986508388],
              [-40.054489834533143, -8.171879998638877],
              [-40.054489830491796, -8.171880010775052],
              [-40.054489826467673, -8.171880022916932],
              [-40.054489822460788, -8.171880035064463],
              [-40.054489818471147, -8.171880047217625],
              [-40.054489814498751, -8.171880059376399],
              [-40.0544898105436, -8.171880071540757],
              [-40.054489806605723, -8.171880083710645],
              [-40.054489802685126, -8.171880095886083],
              [-40.054489798781802, -8.17188010806705],
              [-40.054489794895765, -8.171880120253498],
              [-40.054489791027031, -8.171880132445391],
              [-40.054489787175591, -8.171880144642735],
              [-40.054489783341467, -8.171880156845486],
              [-40.054489779524673, -8.171880169053619],
              [-40.054489775725195, -8.171880181267131],
              [-40.054489771943032, -8.171880193485988],
              [-40.054489768178243, -8.171880205710156],
              [-40.054489764430798, -8.171880217939588],
              [-40.054489760700697, -8.171880230174329],
              [-40.054489756987969, -8.1718802424143],
              [-40.054489753292629, -8.171880254659477],
              [-40.054489749614632, -8.171880266909856],
              [-40.054489745954037, -8.171880279165403],
              [-40.054489742310842, -8.171880291426097],
              [-40.054489738685042, -8.171880303691902],
              [-40.05448973507665, -8.171880315962815],
              [-40.054489731485674, -8.171880328238803],
              [-40.054489727912127, -8.171880340519836],
              [-40.054489724356017, -8.171880352805873],
              [-40.054489720817323, -8.17188036509692],
              [-40.054489717296086, -8.171880377392942],
              [-40.054489713792307, -8.17188038969392],
              [-40.054489707206947, -8.1718804132292],
              [-40.054258035990856, -8.172695948281174],
              [-40.054258028059515, -8.17269597514157],
              [-40.054258017056441, -8.172696013134836],
              [-40.054258005886638, -8.172696051079777],
              [-40.054257994550333, -8.172696088975666],
              [-40.054257983047748, -8.172696126821769],
              [-40.054257971379108, -8.172696164617379],
              [-40.054257959544614, -8.172696202361756],
              [-40.054257947544521, -8.172696240054194],
              [-40.054257935379034, -8.17269627769396],
              [-40.054257923048411, -8.172696315280321],
              [-40.054257910552856, -8.172696352812583],
              [-40.054257897892633, -8.172696390290017],
              [-40.054257885067976, -8.172696427711902],
              [-40.054257872079134, -8.172696465077527],
              [-40.054257858926348, -8.17269650238619],
              [-40.054257845609875, -8.172696539637142],
              [-40.05425783212997, -8.172696576829715],
              [-40.054257818486889, -8.17269661396316],
              [-40.054257804680894, -8.172696651036775],
              [-40.054257790712249, -8.172696688049843],
              [-40.054257776581231, -8.172696725001686],
              [-40.054257762288096, -8.172696761891553],
              [-40.054257747833127, -8.17269679871877],
              [-40.054257733216588, -8.172696835482649],
              [-40.054257718438777, -8.17269687218246],
              [-40.054257703499964, -8.172696908817498],
              [-40.05425768840044, -8.172696945387054],
              [-40.054257673140498, -8.172696981890454],
              [-40.054257657720406, -8.172697018326991],
              [-40.054257642140492, -8.172697054695936],
              [-40.054257626401032, -8.172697090996639],
              [-40.054257610502326, -8.172697127228396],
              [-40.054257594444714, -8.172697163390479],
              [-40.054257578228459, -8.172697199482235],
              [-40.054257561853895, -8.172697235502943],
              [-40.054257545321327, -8.172697271451948],
              [-40.054257528631069, -8.172697307328525],
              [-40.054257511783455, -8.172697343132031],
              [-40.054257494778795, -8.172697378861738],
              [-40.054257477617419, -8.172697414516973],
              [-40.054257460299638, -8.172697450097067],
              [-40.054257442825815, -8.172697485601336],
              [-40.054257425196269, -8.172697521029098],
              [-40.054257407411335, -8.172697556379687],
              [-40.054257389471339, -8.172697591652396],
              [-40.054257371376671, -8.172697626846574],
              [-40.054257353127625, -8.17269766196152],
              [-40.054257334724568, -8.1726976969966],
              [-40.054257316167863, -8.17269773195112],
              [-40.054257297457859, -8.172697766824424],
              [-40.05425727859491, -8.172697801615811],
              [-40.054257259579401, -8.172697836324669],
              [-40.054257240411665, -8.172697870950284],
              [-40.05425722109208, -8.172697905492033],
              [-40.054257201621006, -8.172697939949222],
              [-40.054257181998842, -8.172697974321212],
              [-40.054257162225916, -8.172698008607346],
              [-40.054257142302667, -8.17269804280695],
              [-40.054257122229437, -8.172698076919392],
              [-40.054257102006609, -8.172698110943989],
              [-40.054257081634603, -8.17269814488011],
              [-40.054257061113773, -8.172698178727124],
              [-40.054257040444526, -8.172698212484361],
              [-40.054257019627251, -8.172698246151169],
              [-40.054256998662353, -8.172698279726909],
              [-40.054256977550253, -8.172698313210935],
              [-40.054256956291319, -8.172698346602621],
              [-40.054256934885977, -8.172698379901318],
              [-40.054256913334626, -8.172698413106394],
              [-40.054256891637685, -8.172698446217192],
              [-40.05425686979558, -8.172698479233109],
              [-40.054256847808709, -8.172698512153497],
              [-40.054256825677513, -8.172698544977738],
              [-40.054256803402403, -8.172698577705184],
              [-40.054256780983813, -8.172698610335202],
              [-40.054256758422163, -8.172698642867212],
              [-40.054256735717878, -8.172698675300538],
              [-40.054256712871407, -8.172698707634611],
              [-40.054256689883196, -8.172698739868791],
              [-40.054256666753659, -8.172698772002427],
              [-40.054256643483257, -8.172698804034955],
              [-40.054256620072429, -8.172698835965727],
              [-40.054256596521633, -8.172698867794159],
              [-40.054256572831299, -8.172698899519622],
              [-40.054256549001899, -8.172698931141527],
              [-40.054256525033885, -8.172698962659226],
              [-40.054256500927714, -8.172698994072171],
              [-40.05425647668384, -8.172699025379718],
              [-40.054256452302724, -8.172699056581294],
              [-40.054256427784864, -8.172699087676301],
              [-40.054256403130715, -8.172699118664125],
              [-40.054256378340725, -8.172699149544185],
              [-40.05425635341539, -8.172699180315909],
              [-40.05425632835518, -8.172699210978672],
              [-40.054256303160571, -8.172699241531904],
              [-40.05425627783206, -8.172699271975024],
              [-40.054256252370116, -8.172699302307443],
              [-40.054256226775237, -8.172699332528589],
              [-40.054256201047913, -8.172699362637855],
              [-40.054256175188279, -8.17269939263622],
              [-40.054256130948275, -8.172699416819729],
              [-40.053501767431037, -8.173570785623767],
              [-40.053501751083644, -8.173570772805743],
              [-40.053501675008121, -8.173570859551617],
              [-40.053501597829836, -8.173570945324965],
              [-40.053501519561337, -8.173571030111884],
              [-40.053501440215307, -8.173571113898578],
              [-40.053501359804635, -8.173571196671523],
              [-40.053501278342353, -8.17357127841726],
              [-40.05350119584169, -8.173571359122526],
              [-40.05350111231602, -8.173571438774184],
              [-40.053501027778907, -8.17357151735937],
              [-40.05350094224405, -8.173571594865329],
              [-40.053500855725339, -8.173571671279461],
              [-40.053500768236802, -8.173571746589387],
              [-40.053500679792641, -8.173571820782888],
              [-40.053500590407197, -8.173571893847905],
              [-40.053500500094998, -8.173571965772602],
              [-40.053500408870647, -8.173572036545336],
              [-40.053500316749002, -8.173572106154561],
              [-40.053500223744962, -8.173572174589042],
              [-40.05350012987364, -8.173572241837627],
              [-40.053500035150257, -8.173572307889424],
              [-40.053499939590189, -8.173572372733737],
              [-40.053499843208932, -8.173572436360033],
              [-40.05349974602214, -8.173572498757979],
              [-40.053499648045559, -8.173572559917465],
              [-40.05349954929509, -8.173572619828567],
              [-40.053499449786763, -8.17357267848155],
              [-40.053499349536722, -8.173572735866916],
              [-40.053499248561224, -8.173572791975358],
              [-40.053499146876646, -8.173572846797759],
              [-40.053499044499524, -8.173572900325224],
              [-40.053498941446414, -8.173572952549076],
              [-40.053498837734061, -8.173573003460824],
              [-40.0534987333793, -8.173573053052237],
              [-40.053498628399055, -8.173573101315263],
              [-40.053498522810358, -8.173573148242046],
              [-40.053498416630326, -8.173573193824986],
              [-40.053498309876211, -8.173573238056703],
              [-40.053498202565294, -8.173573280929997],
              [-40.053498094715039, -8.173573322437951],
              [-40.053497986342911, -8.173573362573794],
              [-40.053497877466498, -8.173573401331026],
              [-40.053497768103469, -8.173573438703313],
              [-40.053497658271553, -8.173573474684666],
              [-40.053497547988577, -8.173573509269231],
              [-40.053497437272441, -8.173573542451354],
              [-40.053497326141112, -8.173573574225694],
              [-40.053497214612598, -8.173573604587071],
              [-40.053497102705009, -8.173573633530586],
              [-40.0534969904365, -8.173573661051519],
              [-40.053496877825296, -8.173573687145433],
              [-40.05349676488963, -8.173573711808068],
              [-40.053496651647876, -8.173573735035413],
              [-40.053496538118367, -8.173573756823698],
              [-40.05349642431954, -8.17357377716943],
              [-40.053496310269864, -8.173573796069318],
              [-40.05349619598784, -8.173573813520216],
              [-40.053496081491971, -8.173573829519345],
              [-40.053495966800888, -8.173573844064119],
              [-40.053495851933185, -8.173573857152157],
              [-40.053495736907472, -8.173573868781327],
              [-40.053495621742428, -8.17357387894976],
              [-40.053495506456748, -8.17357388765581],
              [-40.05349539106912, -8.17357389489802],
              [-40.053495275598266, -8.173573900675288],
              [-40.053495160062937, -8.17357390498664],
              [-40.053495044481863, -8.173573907831367],
              [-40.053494928873803, -8.173573909208997],
              [-40.053494813257508, -8.17357390911935],
              [-40.053494697651729, -8.173573907562426],
              [-40.053494582075231, -8.173573904538479],
              [-40.05349446654678, -8.173573900047952],
              [-40.053494351085106, -8.173573894091634],
              [-40.053494235708932, -8.173573886670471],
              [-40.053494120436987, -8.173573877785666],
              [-40.053494005287966, -8.173573867438611],
              [-40.053493890280564, -8.173573855631064],
              [-40.053493775433431, -8.173573842364899],
              [-40.053493660765206, -8.173573827642322],
              [-40.05349354629449, -8.173573811465621],
              [-40.053493432039851, -8.173573793837503],
              [-40.053493318019825, -8.173573774760797],
              [-40.053493204252923, -8.173573754238621],
              [-40.053493090757598, -8.173573732274262],
              [-40.053492977552239, -8.173573708871311],
              [-40.053492864655269, -8.173573684033576],
              [-40.053492752084942, -8.173573657765074],
              [-40.05349263985957, -8.173573630070081],
              [-40.053492527997321, -8.173573600953084],
              [-40.053492416516377, -8.173573570418739],
              [-40.053492305434808, -8.173573538472114],
              [-40.053492194770641, -8.17357350511832],
              [-40.05349208454183, -8.173573470362786],
              [-40.053491974766253, -8.173573434211153],
              [-40.053491865461737, -8.173573396669267],
              [-40.053491756645997, -8.173573357743251],
              [-40.053491648336689, -8.173573317439393],
              [-40.053491540551398, -8.173573275764248],
              [-40.053491433307599, -8.173573232724593],
              [-40.053491326622762, -8.173573188327284],
              [-40.053491335222645, -8.173573164247465],
              [-40.052676230896687, -8.173227905058889],
              [-40.052676207695413, -8.173227882067769],
              [-40.052676191696307, -8.173227875272049],
              [-40.052676175710303, -8.173227868445805],
              [-40.052676159737445, -8.173227861589073],
              [-40.052676143777809, -8.173227854701846],
              [-40.052676127831454, -8.173227847784146],
              [-40.052676111898428, -8.173227840836022],
              [-40.052676095978796, -8.173227833857508],
              [-40.052676080072608, -8.173227826848599],
              [-40.05267606417992, -8.173227819809334],
              [-40.052676048300818, -8.173227812739745],
              [-40.052676032435329, -8.173227805639851],
              [-40.052676016583533, -8.173227798509684],
              [-40.05267600074545, -8.173227791349273],
              [-40.052675984921194, -8.173227784158637],
              [-40.05267596911078, -8.173227776937782],
              [-40.052675953314292, -8.173227769686784],
              [-40.052675937531767, -8.173227762405633],
              [-40.052675921763282, -8.173227755094358],
              [-40.052675906008879, -8.173227747752991],
              [-40.052675890268631, -8.173227740381551],
              [-40.052675874542579, -8.173227732980092],
              [-40.052675858830781, -8.173227725548625],
              [-40.052675843133322, -8.173227718087169],
              [-40.052675827450237, -8.173227710595741],
              [-40.052675811781583, -8.173227703074394],
              [-40.052675796127431, -8.173227695523154],
              [-40.052675780487817, -8.173227687942042],
              [-40.052675764862805, -8.173227680331095],
              [-40.052675749252479, -8.173227672690325],
              [-40.052675733656848, -8.17322766501975],
              [-40.052675718076017, -8.173227657319423],
              [-40.052675702510022, -8.173227649589371],
              [-40.052675686958921, -8.173227641829618],
              [-40.052675671422769, -8.173227634040174],
              [-40.052675655901616, -8.173227626221081],
              [-40.052675640395542, -8.173227618372367],
              [-40.05267562490458, -8.173227610494061],
              [-40.052675609428796, -8.173227602586207],
              [-40.05267559396826, -8.17322759464883],
              [-40.052675578523008, -8.173227586681953],
              [-40.052675563093104, -8.173227578685605],
              [-40.052675547678618, -8.173227570659801],
              [-40.052675532279586, -8.173227562604598],
              [-40.052675516896066, -8.173227554519995],
              [-40.052675501528121, -8.173227546406036],
              [-40.052675486175808, -8.173227538262758],
              [-40.052675470839198, -8.17322753009018],
              [-40.052675455518326, -8.173227521888348],
              [-40.05267544021325, -8.173227513657265],
              [-40.052675424924033, -8.173227505397001],
              [-40.052675409650739, -8.17322749710755],
              [-40.05267539439339, -8.173227488788962],
              [-40.052675379152078, -8.173227480441264],
              [-40.052675363926845, -8.173227472064459],
              [-40.05267534871777, -8.173227463658606],
              [-40.052675333524867, -8.173227455223731],
              [-40.052675318348221, -8.173227446759862],
              [-40.052675303187868, -8.173227438267039],
              [-40.052675288043886, -8.173227429745301],
              [-40.052675272916311, -8.173227421194667],
              [-40.05267525780522, -8.173227412615146],
              [-40.052675242710642, -8.173227404006811],
              [-40.052675227632655, -8.173227395369679],
              [-40.052675212571302, -8.173227386703777],
              [-40.052675197526646, -8.173227378009114],
              [-40.052675182498724, -8.173227369285767],
              [-40.052675167487635, -8.173227360533742],
              [-40.052675152493372, -8.173227351753081],
              [-40.05267513751604, -8.173227342943809],
              [-40.052675122555662, -8.173227334105924],
              [-40.052675107612323, -8.173227325239505],
              [-40.052675092686059, -8.173227316344571],
              [-40.052675077776925, -8.173227307421142],
              [-40.05267506288498, -8.17322729846928],
              [-40.052675048010279, -8.173227289489011],
              [-40.052675033152873, -8.173227280480358],
              [-40.052675018312812, -8.173227271443343],
              [-40.052675003490172, -8.173227262378036],
              [-40.052674988684977, -8.173227253284445],
              [-40.052674973897297, -8.173227244162593],
              [-40.052674959127195, -8.173227235012527],
              [-40.052674944374715, -8.173227225834289],
              [-40.052674929639913, -8.173227216627886],
              [-40.052674914922832, -8.173227207393388],
              [-40.052674900223543, -8.17322719813081],
              [-40.052674885542096, -8.173227188840174],
              [-40.052674870878548, -8.173227179521513],
              [-40.05267485623294, -8.173227170174888],
              [-40.052674841605331, -8.173227160800305],
              [-40.052674826995791, -8.173227151397828],
              [-40.052674812404341, -8.173227141967457],
              [-40.05267479783106, -8.173227132509259],
              [-40.05267478327599, -8.17322712302327],
              [-40.052674768739195, -8.173227113509522],
              [-40.052674754220725, -8.173227103968033],
              [-40.052674739720622, -8.173227094398836],
              [-40.052674725238958, -8.173227084801979],
              [-40.052674710775761, -8.173227075177483],
              [-40.052674696331138, -8.173227065524935],
              [-40.052674685699117, -8.173227095116999],
              [-40.052066736963887, -8.17281997672613],
              [-40.051232327134755, -8.172900648510979],
              [-40.051232326785055, -8.172900616068475],
              [-40.051232281876558, -8.172900620296295],
              [-40.051232236947484, -8.172900624301663],
              [-40.051232191999034, -8.172900628084539],
              [-40.051232147032245, -8.172900631644771],
              [-40.051232102048267, -8.172900634982335],
              [-40.051232057048189, -8.172900638097087],
              [-40.051232012033154, -8.172900640988976],
              [-40.05123196700422, -8.172900643657961],
              [-40.05123192196254, -8.172900646103926],
              [-40.051231876909235, -8.172900648326866],
              [-40.051231831845364, -8.172900650326646],
              [-40.051231786772107, -8.172900652103289],
              [-40.051231741690529, -8.172900653656717],
              [-40.051231696601761, -8.172900654986892],
              [-40.051231651506932, -8.17290065609377],
              [-40.051231606407129, -8.172900656977394],
              [-40.051231561303453, -8.17290065763766],
              [-40.051231516197056, -8.172900658074582],
              [-40.051231471089046, -8.172900658288164],
              [-40.051231425980518, -8.172900658278374],
              [-40.051231380872601, -8.172900658045238],
              [-40.05123133576641, -8.172900657588743],
              [-40.051231290663054, -8.172900656908917],
              [-40.051231245563621, -8.172900656005753],
              [-40.051231200469267, -8.172900654879289],
              [-40.051231155381096, -8.172900653529547],
              [-40.051231110300208, -8.172900651956564],
              [-40.051231065227718, -8.172900650160372],
              [-40.051231020164742, -8.172900648141017],
              [-40.051230975112396, -8.17290064589856],
              [-40.051230930071796, -8.172900643433049],
              [-40.051230885044042, -8.172900640744537],
              [-40.051230840030257, -8.172900637833084],
              [-40.05123079503155, -8.172900634698825],
              [-40.051230750049037, -8.172900631341772],
              [-40.051230705083796, -8.172900627762022],
              [-40.051230660137001, -8.172900623959645],
              [-40.051230615209711, -8.17290061993476],
              [-40.051230570303041, -8.172900615687483],
              [-40.051230525418127, -8.172900611217866],
              [-40.05123048055605, -8.172900606526074],
              [-40.051230435717933, -8.172900601612199],
              [-40.05123039090487, -8.172900596476364],
              [-40.051230346117983, -8.172900591118719],
              [-40.051230301358366, -8.172900585539349],
              [-40.051230256627129, -8.172900579738416],
              [-40.051230211925393, -8.172900573716051],
              [-40.051230167254225, -8.172900567472423],
              [-40.051230122614768, -8.172900561007712],
              [-40.051230078008096, -8.172900554321998],
              [-40.051230033435317, -8.172900547415519],
              [-40.051229988897532, -8.172900540288389],
              [-40.051229944395864, -8.172900532940837],
              [-40.051229899931386, -8.172900525373015],
              [-40.051229855505191, -8.172900517585118],
              [-40.051229811118404, -8.172900509577351],
              [-40.051229766772103, -8.172900501349879],
              [-40.051229722467376, -8.172900492902931],
              [-40.051229678205324, -8.172900484236694],
              [-40.051229633987049, -8.172900475351412],
              [-40.05122958981363, -8.172900466247274],
              [-40.051229545686176, -8.172900456924543],
              [-40.051229501605754, -8.172900447383403],
              [-40.05122945757347, -8.172900437624119],
              [-40.051229413590413, -8.172900427646923],
              [-40.051229369657655, -8.172900417452068],
              [-40.051229325776276, -8.172900407039803],
              [-40.051229281947386, -8.172900396410379],
              [-40.051229238172063, -8.172900385564075],
              [-40.051229194451345, -8.172900374501145],
              [-40.051229150786376, -8.172900363221837],
              [-40.051229107178195, -8.172900351726472],
              [-40.051229063627886, -8.172900340015328],
              [-40.051229020136539, -8.172900328088677],
              [-40.05122897670519, -8.172900315946816],
              [-40.051228933334947, -8.172900303590042],
              [-40.051228890026863, -8.172900291018681],
              [-40.051228846782031, -8.172900278233046],
              [-40.051228803601489, -8.172900265233418],
              [-40.051228760486339, -8.172900252020151],
              [-40.051228717437603, -8.172900238593527],
              [-40.051228674456389, -8.172900224953919],
              [-40.051228631543708, -8.172900211101652],
              [-40.05122858870066, -8.172900197037103],
              [-40.051228545928289, -8.172900182760566],
              [-40.051228503227648, -8.172900168272411],
              [-40.051228460599795, -8.172900153572993],
              [-40.051228418045795, -8.172900138662689],
              [-40.051228375566687, -8.172900123541854],
              [-40.051228333163522, -8.172900108210856],
              [-40.051228290837336, -8.172900092670099],
              [-40.051228248589197, -8.172900076919948],
              [-40.051228206420113, -8.172900060960803],
              [-40.051228164331171, -8.172900044793034],
              [-40.051228122323373, -8.172900028417057],
              [-40.051228080397806, -8.172900011833301],
              [-40.051228038555422, -8.172899995042149],
              [-40.05122799679733, -8.172899978043985],
              [-40.051227955124325, -8.172899960840097],
              [-40.051227968356486, -8.172899936316007],
              [-40.050115927639794, -8.172437599206638],
              [-40.050115894123202, -8.172437612824741],
              [-40.050115867470595, -8.17243760179319],
              [-40.050115840782901, -8.172437590846132],
              [-40.050115814060426, -8.172437579983688],
              [-40.050115787303412, -8.17243756920594],
              [-40.050115760512142, -8.172437558513028],
              [-40.050115733686894, -8.172437547905043],
              [-40.050115706827924, -8.172437537382097],
              [-40.050115679935502, -8.172437526944293],
              [-40.050115653009918, -8.172437516591762],
              [-40.050115626051422, -8.172437506324556],
              [-40.050115599060312, -8.172437496142813],
              [-40.050115572036837, -8.172437486046627],
              [-40.050115544981274, -8.172437476036118],
              [-40.050115517893914, -8.17243746611137],
              [-40.050115490775021, -8.172437456272483],
              [-40.05011546362487, -8.17243744651957],
              [-40.050115436443718, -8.172437436852704],
              [-40.050115409231864, -8.172437427272035],
              [-40.050115381989578, -8.172437417777592],
              [-40.050115354717128, -8.172437408369527],
              [-40.050115327414794, -8.172437399047896],
              [-40.050115300082851, -8.172437389812806],
              [-40.050115272721577, -8.172437380664332],
              [-40.050115245331249, -8.172437371602621],
              [-40.050115217912143, -8.172437362627724],
              [-40.050115190464531, -8.172437353739724],
              [-40.050115162988696, -8.172437344938739],
              [-40.050115135484909, -8.172437336224851],
              [-40.050115107953467, -8.172437327598111],
              [-40.050115080394633, -8.172437319058668],
              [-40.050115052808678, -8.172437310606544],
              [-40.050115025195893, -8.17243730224188],
              [-40.050114997556562, -8.172437293964734],
              [-40.050114969890942, -8.172437285775203],
              [-40.050114942199343, -8.172437277673358],
              [-40.050114914482023, -8.172437269659275],
              [-40.050114886739273, -8.172437261733043],
              [-40.050114858971376, -8.172437253894726],
              [-40.05011483117859, -8.172437246144442],
              [-40.050114803361225, -8.172437238482242],
              [-40.050114775519539, -8.172437230908209],
              [-40.050114747653829, -8.172437223422426],
              [-40.050114719764366, -8.172437216024957],
              [-40.050114691851448, -8.172437208715875],
              [-40.050114663915338, -8.172437201495258],
              [-40.050114635956341, -8.172437194363191],
              [-40.050114607974706, -8.172437187319742],
              [-40.05011457997076, -8.172437180364978],
              [-40.050114551944745, -8.172437173498958],
              [-40.050114523896958, -8.172437166721762],
              [-40.050114495827707, -8.17243716003348],
              [-40.050114467737238, -8.172437153434133],
              [-40.050114439625844, -8.172437146923819],
              [-40.050114411493837, -8.17243714050259],
              [-40.050114383341459, -8.172437134170524],
              [-40.050114355169029, -8.172437127927695],
              [-40.050114326976825, -8.172437121774134],
              [-40.050114298765124, -8.172437115709901],
              [-40.050114270534216, -8.172437109735107],
              [-40.050114242284373, -8.172437103849758],
              [-40.050114214015892, -8.172437098053944],
              [-40.050114185729065, -8.17243709234773],
              [-40.050114157424176, -8.172437086731133],
              [-40.050114129101502, -8.172437081204253],
              [-40.050114100761334, -8.172437075767137],
              [-40.050114072403964, -8.172437070419821],
              [-40.050114044029655, -8.17243706516237],
              [-40.050114015638734, -8.172437059994836],
              [-40.050113987231462, -8.17243705491728],
              [-40.050113958808126, -8.172437049929727],
              [-40.050113930369001, -8.172437045032272],
              [-40.050113901914415, -8.172437040224928],
              [-40.050113873444623, -8.172437035507748],
              [-40.050113844959931, -8.172437030880802],
              [-40.050113816460595, -8.17243702634412],
              [-40.050113787946948, -8.172437021897736],
              [-40.050113759419254, -8.172437017541711],
              [-40.05011373087779, -8.172437013276102],
              [-40.050113702322861, -8.172437009100923],
              [-40.05011367375478, -8.172437005016224],
              [-40.050113645173788, -8.172437001022054],
              [-40.050113616580184, -8.17243699711846],
              [-40.050113587974266, -8.172436993305478],
              [-40.050113559356348, -8.172436989583124],
              [-40.050113530726676, -8.172436985951483],
              [-40.050113502085566, -8.172436982410535],
              [-40.050113473433306, -8.172436978960347],
              [-40.050113444770176, -8.172436975600938],
              [-40.05011341609648, -8.172436972332354],
              [-40.050113387412495, -8.172436969154628],
              [-40.050113358718505, -8.17243696606781],
              [-40.05011333001481, -8.172436963071902],
              [-40.050113301301707, -8.172436960166952],
              [-40.050113272579473, -8.172436957352991],
              [-40.050113243848415, -8.172436954630022],
              [-40.050113215108794, -8.172436951998092],
              [-40.050113186360939, -8.172436949457216],
              [-40.050113157605118, -8.172436947007432],
              [-40.050113128841616, -8.172436944648751],
              [-40.050113112165498, -8.172436973432605],
              [-40.049411048013049, -8.172380461338502],
              [-40.049410321866205, -8.172380463222373],
              [-40.048766048012148, -8.172382134180696],
              [-40.048765140328399, -8.172382136534099],
              [-40.04753492450962, -8.172268746449854],
              [-40.047534943006099, -8.172268760903215],
              [-40.047534920736879, -8.172268758824083],
              [-40.047534898472875, -8.172268756690258],
              [-40.047534876214215, -8.172268754501788],
              [-40.047534853961032, -8.17226875225867],
              [-40.047534831713442, -8.172268749960907],
              [-40.047534809471628, -8.172268747608532],
              [-40.047534787235705, -8.172268745201544],
              [-40.047534765005786, -8.172268742739998],
              [-40.047534742782027, -8.172268740223851],
              [-40.047534720564556, -8.172268737653162],
              [-40.047534698353509, -8.17226873502792],
              [-40.047534676149034, -8.17226873234817],
              [-40.047534653951246, -8.172268729613906],
              [-40.047534631760279, -8.172268726825179],
              [-40.047534609576296, -8.172268723981936],
              [-40.047534587399397, -8.172268721084249],
              [-40.047534565229725, -8.172268718132111],
              [-40.047534543067435, -8.17226871512556],
              [-40.047534520912649, -8.172268712064593],
              [-40.047534498765494, -8.172268708949247],
              [-40.047534476626105, -8.17226870577954],
              [-40.047534454494638, -8.172268702555492],
              [-40.047534432371187, -8.172268699277101],
              [-40.047534410255935, -8.172268695944412],
              [-40.047534388148982, -8.172268692557441],
              [-40.047534366050478, -8.172268689116189],
              [-40.04753434396055, -8.172268685620685],
              [-40.047534321879326, -8.172268682070968],
              [-40.047534299806962, -8.172268678467034],
              [-40.047534277743566, -8.172268674808935],
              [-40.047534255689285, -8.172268671096671],
              [-40.047534233644249, -8.172268667330258],
              [-40.0475342116086, -8.172268663509742],
              [-40.047534189582471, -8.172268659635145],
              [-40.047534167565978, -8.172268655706464],
              [-40.047534145559275, -8.172268651723735],
              [-40.0475341235625, -8.172268647687023],
              [-40.047534101575756, -8.172268643596276],
              [-40.047534079599203, -8.172268639451564],
              [-40.047534057632973, -8.172268635252919],
              [-40.047534035677188, -8.172268631000355],
              [-40.047534013731998, -8.17226862669391],
              [-40.047533991797508, -8.172268622333561],
              [-40.047533969873875, -8.172268617919398],
              [-40.04753394796122, -8.172268613451415],
              [-40.047533926059678, -8.172268608929635],
              [-40.047533904169391, -8.17226860435412],
              [-40.047533882290502, -8.172268599724854],
              [-40.047533860423101, -8.172268595041874],
              [-40.047533838567368, -8.172268590305242],
              [-40.047533816723416, -8.172268585514955],
              [-40.047533794891372, -8.172268580671039],
              [-40.047533773071365, -8.172268575773554],
              [-40.047533751263543, -8.172268570822515],
              [-40.047533729468029, -8.17226856581793],
              [-40.047533707684963, -8.172268560759843],
              [-40.047533685914452, -8.172268555648291],
              [-40.047533664156674, -8.172268550483283],
              [-40.047533642411729, -8.17226854526489],
              [-40.047533620679765, -8.172268539993118],
              [-40.047533598960882, -8.172268534668026],
              [-40.047533577255258, -8.172268529289601],
              [-40.047533555562978, -8.172268523857923],
              [-40.047533533884213, -8.172268518372981],
              [-40.047533512219076, -8.172268512834851],
              [-40.047533490567695, -8.172268507243524],
              [-40.047533468930212, -8.172268501599079],
              [-40.047533447306755, -8.172268495901498],
              [-40.047533425697445, -8.172268490150854],
              [-40.047533404102431, -8.172268484347169],
              [-40.047533382521834, -8.172268478490473],
              [-40.047533360955796, -8.172268472580825],
              [-40.047533339404431, -8.172268466618226],
              [-40.047533317867874, -8.172268460602742],
              [-40.047533296346252, -8.172268454534423],
              [-40.047533274839729, -8.172268448413258],
              [-40.047533253348384, -8.172268442239307],
              [-40.047533231872386, -8.172268436012626],
              [-40.047533210411849, -8.172268429733224],
              [-40.047533188966902, -8.172268423401155],
              [-40.047533167537686, -8.172268417016438],
              [-40.047533146124316, -8.172268410579139],
              [-40.047533124726947, -8.172268404089285],
              [-40.047533103345678, -8.172268397546937],
              [-40.047533081980681, -8.172268390952089],
              [-40.047533060632027, -8.172268384304806],
              [-40.047533039299886, -8.172268377605141],
              [-40.047533017984385, -8.172268370853107],
              [-40.047532996685646, -8.172268364048762],
              [-40.04753297540379, -8.172268357192147],
              [-40.047532954138966, -8.17226835028327],
              [-40.047532932891286, -8.172268343322244],
              [-40.047532911660888, -8.172268336309047],
              [-40.047532890447904, -8.172268329243757],
              [-40.047532869252457, -8.172268322126419],
              [-40.047532848074667, -8.172268314957046],
              [-40.047532826914669, -8.172268307735688],
              [-40.047532805772605, -8.17226830046242],
              [-40.047532784648816, -8.172268293135302],
              [-40.047532744888819, -8.172268299891636],
              [-40.046537013015119, -8.171921595793183],
              [-40.04653705547657, -8.171921631174854],
              [-40.046537032737724, -8.171921623224549],
              [-40.046537010020138, -8.171921615214115],
              [-40.046536987323982, -8.171921607143629],
              [-40.046536964649405, -8.171921599013109],
              [-40.046536941996571, -8.171921590822667],
              [-40.046536919365643, -8.171921582572327],
              [-40.046536896756784, -8.171921574262134],
              [-40.046536874170137, -8.171921565892179],
              [-40.046536851605879, -8.171921557462513],
              [-40.046536829064152, -8.17192154897316],
              [-40.046536806545141, -8.171921540424227],
              [-40.046536784048975, -8.171921531815736],
              [-40.04653676157583, -8.171921523147786],
              [-40.04653673912587, -8.171921514420447],
              [-40.046536716699222, -8.171921505633701],
              [-40.046536694296073, -8.171921496787679],
              [-40.046536671916591, -8.171921487882438],
              [-40.046536649560899, -8.171921478918019],
              [-40.046536627229166, -8.171921469894476],
              [-40.046536604921577, -8.171921460811914],
              [-40.046536582638254, -8.171921451670375],
              [-40.046536560379373, -8.171921442469902],
              [-40.046536538145084, -8.171921433210569],
              [-40.046536515935536, -8.171921423892481],
              [-40.046536493750899, -8.171921414515708],
              [-40.046536471591331, -8.17192140508023],
              [-40.04653644945698, -8.17192139558618],
              [-40.046536427347995, -8.171921386033594],
              [-40.046536405264568, -8.171921376422571],
              [-40.046536383206806, -8.171921366753175],
              [-40.046536361174887, -8.171921357025429],
              [-40.046536339168966, -8.171921347239495],
              [-40.046536317189194, -8.171921337395347],
              [-40.046536295235754, -8.171921327493106],
              [-40.046536273308739, -8.171921317532819],
              [-40.046536251408355, -8.171921307514561],
              [-40.046536229534752, -8.171921297438406],
              [-40.046536207688071, -8.17192128730445],
              [-40.046536185868469, -8.171921277112689],
              [-40.046536164076102, -8.171921266863267],
              [-40.046536142311112, -8.17192125655626],
              [-40.046536120573677, -8.171921246191687],
              [-40.046536098863925, -8.171921235769648],
              [-40.046536077182019, -8.171921225290211],
              [-40.046536055528115, -8.171921214753478],
              [-40.046536033902356, -8.171921204159478],
              [-40.046536012304905, -8.17192119350832],
              [-40.046535990735926, -8.171921182800077],
              [-40.046535969195538, -8.171921172034823],
              [-40.046535947683907, -8.171921161212587],
              [-40.046535926201209, -8.171921150333509],
              [-40.046535904747564, -8.171921139397634],
              [-40.046535883323124, -8.171921128405032],
              [-40.046535861928056, -8.171921117355838],
              [-40.046535840562512, -8.17192110625008],
              [-40.046535819226627, -8.171921095087813],
              [-40.046535797920562, -8.171921083869156],
              [-40.046535776644461, -8.17192107259417],
              [-40.046535755398473, -8.171921061262944],
              [-40.046535734182761, -8.171921049875539],
              [-40.046535712997475, -8.171921038432098],
              [-40.046535691842735, -8.171921026932653],
              [-40.046535670718725, -8.171921015377263],
              [-40.046535649625575, -8.171921003766034],
              [-40.04653562856344, -8.17192099209905],
              [-40.046535607532462, -8.171920980376374],
              [-40.046535586532791, -8.171920968598133],
              [-40.046535565564582, -8.171920956764387],
              [-40.046535544627979, -8.171920944875216],
              [-40.046535523723136, -8.171920932930695],
              [-40.046535502850176, -8.171920920930905],
              [-40.046535482009283, -8.171920908875961],
              [-40.04653546120057, -8.171920896765934],
              [-40.046535440424201, -8.171920884600892],
              [-40.04653541968031, -8.171920872380911],
              [-40.046535398969077, -8.171920860106132],
              [-40.046535378290613, -8.171920847776569],
              [-40.046535357645055, -8.171920835392392],
              [-40.046535337032587, -8.171920822953631],
              [-40.046535316453337, -8.171920810460408],
              [-40.046535295907447, -8.171920797912756],
              [-40.046535275395058, -8.171920785310824],
              [-40.046535254916321, -8.171920772654676],
              [-40.046535234471378, -8.171920759944367],
              [-40.046535214060384, -8.171920747180048],
              [-40.046535193683461, -8.171920734361784],
              [-40.046535173340764, -8.171920721489645],
              [-40.046535153032451, -8.171920708563725],
              [-40.046535132758635, -8.171920695584165],
              [-40.046535112519479, -8.171920682551013],
              [-40.046535092315132, -8.17192066946437],
              [-40.046535072145716, -8.171920656324305],
              [-40.046535052011393, -8.171920643130932],
              [-40.046535031912285, -8.171920629884369],
              [-40.046535011848533, -8.171920616584666],
              [-40.046534991820295, -8.171920603231941],
              [-40.046534971827725, -8.171920589826284],
              [-40.046534951870932, -8.171920576367802],
              [-40.04653493195012, -8.171920562856181],
              [-40.046534922750929, -8.171920606354563],
              [-40.045597798272119, -8.171283162449448],
              [-40.04559780849101, -8.171283144963436],
              [-40.04559777231178, -8.171283120226803],
              [-40.045597736252965, -8.171283095316179],
              [-40.045597700315426, -8.171283070232198],
              [-40.045597664500008, -8.171283044975405],
              [-40.045597628807549, -8.171283019546381],
              [-40.045597593238867, -8.171282993945733],
              [-40.045597557794821, -8.171282968174081],
              [-40.045597522476214, -8.171282942232013],
              [-40.045597487283878, -8.171282916120113],
              [-40.04559745221863, -8.171282889839024],
              [-40.045597417281314, -8.171282863389383],
              [-40.045597382472728, -8.171282836771724],
              [-40.045597347793674, -8.171282809986741],
              [-40.04559731324499, -8.171282783035032],
              [-40.045597278827458, -8.17128275591724],
              [-40.045597244541895, -8.171282728633997],
              [-40.045597210389104, -8.171282701185936],
              [-40.045597176369874, -8.171282673573666],
              [-40.045597142485015, -8.171282645797879],
              [-40.045597108735286, -8.171282617859202],
              [-40.04559707512152, -8.171282589758283],
              [-40.045597041644463, -8.171282561495795],
              [-40.045597008304931, -8.171282533072409],
              [-40.045596975103685, -8.171282504488754],
              [-40.045596942041499, -8.171282475745542],
              [-40.045596909119155, -8.171282446843419],
              [-40.045596876337413, -8.171282417783035],
              [-40.045596843697041, -8.171282388565114],
              [-40.045596811198806, -8.171282359190311],
              [-40.045596778843468, -8.171282329659316],
              [-40.045596746631773, -8.171282299972834],
              [-40.045596714564503, -8.171282270131538],
              [-40.045596682642362, -8.171282240136163],
              [-40.045596650866123, -8.171282209987368],
              [-40.045596619236534, -8.171282179685871],
              [-40.045596587754311, -8.171282149232402],
              [-40.045596556420229, -8.171282118627641],
              [-40.045596525234977, -8.171282087872319],
              [-40.045596494199295, -8.171282056967119],
              [-40.045596463313935, -8.171282025912781],
              [-40.045596432579586, -8.171281994710078],
              [-40.045596401996974, -8.171281963359688],
              [-40.04559637156683, -8.171281931862396],
              [-40.045596341289844, -8.171281900218885],
              [-40.04559631116674, -8.171281868429903],
              [-40.045596281198215, -8.171281836496208],
              [-40.045596251384978, -8.171281804418527],
              [-40.045596221727706, -8.171281772197663],
              [-40.045596192227102, -8.171281739834278],
              [-40.045596162883854, -8.171281707329207],
              [-40.045596133698659, -8.17128167468316],
              [-40.045596104672185, -8.171281641896954],
              [-40.045596075805122, -8.17128160897134],
              [-40.045596047098137, -8.17128157590704],
              [-40.04559601855189, -8.171281542704847],
              [-40.045595990167079, -8.171281509365535],
              [-40.045595961944329, -8.171281475889923],
              [-40.045595933884343, -8.171281442278774],
              [-40.045595905987739, -8.171281408532858],
              [-40.045595878255178, -8.171281374652956],
              [-40.045595850687313, -8.171281340639878],
              [-40.045595823284799, -8.171281306494427],
              [-40.045595796048254, -8.171281272217373],
              [-40.045595768978323, -8.17128123780955],
              [-40.045595742075648, -8.171281203271718],
              [-40.045595715340845, -8.171281168604732],
              [-40.045595688774561, -8.171281133809387],
              [-40.04559566237738, -8.171281098886485],
              [-40.045595636149955, -8.171281063836851],
              [-40.045595610092882, -8.171281028661273],
              [-40.045595584206758, -8.171280993360613],
              [-40.045595558492209, -8.171280957935677],
              [-40.045595532949839, -8.171280922387318],
              [-40.045595507580217, -8.171280886716351],
              [-40.045595482383945, -8.171280850923576],
              [-40.045595457361649, -8.171280815009887],
              [-40.045595432513863, -8.171280778976058],
              [-40.045595407841184, -8.171280742822999],
              [-40.045595383344221, -8.17128070655151],
              [-40.045595359023494, -8.171280670162476],
              [-40.045595334879607, -8.171280633656712],
              [-40.0455953109131, -8.171280597035075],
              [-40.045595287124556, -8.171280560298468],
              [-40.045595263514521, -8.17128052344767],
              [-40.045595240083557, -8.171280486483607],
              [-40.045595216832183, -8.17128044940711],
              [-40.045595193760974, -8.171280412219046],
              [-40.045595170870449, -8.171280374920295],
              [-40.045595148161148, -8.17128033751173],
              [-40.045595125633611, -8.171280299994223],
              [-40.045595103288349, -8.171280262368631],
              [-40.04559508112591, -8.171280224635872],
              [-40.045595059146777, -8.171280186796794],
              [-40.045595037351482, -8.171280148852304],
              [-40.045595015740538, -8.171280110803272],
              [-40.04559499431442, -8.171280072650591],
              [-40.04559497307369, -8.171280034395147],
              [-40.045594952018796, -8.17127999603788],
              [-40.045594931150148, -8.171279957580047],
              [-40.045594975981189, -8.1712799138822],
              [-40.045124612836922, -8.170408196458569],
              [-40.045124430359593, -8.170407835167479],
              [-40.04471279491716, -8.169709973065599],
              [-40.044712768396792, -8.169709952396747],
              [-40.044712757947693, -8.169709934635627],
              [-40.044712747539201, -8.169709916850852],
              [-40.044712737171359, -8.169709899042507],
              [-40.044712726844239, -8.169709881210693],
              [-40.044712716557875, -8.169709863355489],
              [-40.044712706312346, -8.169709845477053],
              [-40.044712696107666, -8.169709827575364],
              [-40.044712685943935, -8.169709809650607],
              [-40.04471267582116, -8.169709791702834],
              [-40.044712665739439, -8.16970977373215],
              [-40.044712655698781, -8.169709755738648],
              [-40.044712645699271, -8.169709737722416],
              [-40.044712635740943, -8.169709719683555],
              [-40.044712625823848, -8.169709701622132],
              [-40.044712615948043, -8.169709683538319],
              [-40.044712606113592, -8.169709665432093],
              [-40.044712596320515, -8.169709647303607],
              [-40.044712586568885, -8.169709629152964],
              [-40.04471257685875, -8.169709610980297],
              [-40.04471256719016, -8.169709592785626],
              [-40.044712557563166, -8.169709574569058],
              [-40.044712547977795, -8.169709556330675],
              [-40.044712538434155, -8.169709538070652],
              [-40.044712528932223, -8.169709519789015],
              [-40.044712519472093, -8.169709501485869],
              [-40.044712510053799, -8.169709483161325],
              [-40.044712500677413, -8.169709464815446],
              [-40.044712491342942, -8.169709446448369],
              [-40.044712482050471, -8.169709428060141],
              [-40.044712472800029, -8.169709409650899],
              [-40.044712463591679, -8.169709391220714],
              [-40.044712454425458, -8.169709372769681],
              [-40.044712445301407, -8.16970935429792],
              [-40.044712436219577, -8.169709335805512],
              [-40.044712427180023, -8.169709317292563],
              [-40.044712418182783, -8.169709298759139],
              [-40.04471240922792, -8.169709280205341],
              [-40.044712400315454, -8.169709261631334],
              [-40.044712391445458, -8.169709243037104],
              [-40.044712382617973, -8.169709224422835],
              [-40.044712373833029, -8.169709205788596],
              [-40.044712365090682, -8.169709187134487],
              [-40.044712356390967, -8.169709168460574],
              [-40.044712347733935, -8.169709149766996],
              [-40.044712339119641, -8.169709131053796],
              [-40.044712330548137, -8.169709112321135],
              [-40.044712322019436, -8.169709093569088],
              [-40.044712313533594, -8.169709074797707],
              [-40.044712305090663, -8.169709056007166],
              [-40.04471229669069, -8.16970903719751],
              [-40.044712288333706, -8.169709018368843],
              [-40.04471228001978, -8.169708999521282],
              [-40.044712271748907, -8.169708980654912],
              [-40.044712263521163, -8.169708961769832],
              [-40.044712255336599, -8.16970894286616],
              [-40.044712247195235, -8.169708923943947],
              [-40.04471223909713, -8.169708905003349],
              [-40.044712231042318, -8.169708886044372],
              [-40.044712223030842, -8.169708867067193],
              [-40.044712215062724, -8.169708848071894],
              [-40.044712207138048, -8.169708829058594],
              [-40.044712199256807, -8.169708810027339],
              [-40.044712191419087, -8.169708790978284],
              [-40.044712183624902, -8.169708771911482],
              [-40.044712175874288, -8.169708752827063],
              [-40.044712168167301, -8.169708733725113],
              [-40.044712160503977, -8.169708714605751],
              [-40.044712152884358, -8.169708695469041],
              [-40.04471214530848, -8.169708676315105],
              [-40.044712137776386, -8.169708657144039],
              [-40.044712130288076, -8.169708637955914],
              [-40.044712122843663, -8.169708618750846],
              [-40.044712115443133, -8.169708599528954],
              [-40.044712108086529, -8.16970858029034],
              [-40.0447121007739, -8.169708561035067],
              [-40.044712093505289, -8.169708541763271],
              [-40.044712086280725, -8.169708522475027],
              [-40.044712079100243, -8.169708503170442],
              [-40.044712071963886, -8.169708483849625],
              [-40.044712064871675, -8.169708464512688],
              [-40.044712057823673, -8.169708445159701],
              [-40.044712050819896, -8.169708425790784],
              [-40.0447120438604, -8.169708406406034],
              [-40.044712036945192, -8.169708387005555],
              [-40.044712030074344, -8.169708367589426],
              [-40.044712023247868, -8.169708348157785],
              [-40.044712016465795, -8.169708328710689],
              [-40.04471200972818, -8.169708309248293],
              [-40.044712003035038, -8.169708289770641],
              [-40.044711996386418, -8.169708270277832],
              [-40.04471198978235, -8.169708250770015],
              [-40.044711983222861, -8.169708231247276],
              [-40.044711976708008, -8.169708211709697],
              [-40.044711970237785, -8.169708192157415],
              [-40.044711963812262, -8.169708172590495],
              [-40.044711957431453, -8.16970815300904],
              [-40.044711951095401, -8.169708133413188],
              [-40.044711944804128, -8.169708113802999],
              [-40.044711938557526, -8.169708094180095],
              [-40.044711973066867, -8.169708075934238],
              [-40.04441709948631, -8.168778202936572],
              [-40.044417127393444, -8.168778216167565],
              [-40.044417115824842, -8.168778179943663],
              [-40.044417104103559, -8.16877814376852],
              [-40.04441709222975, -8.168778107642794],
              [-40.044417080203672, -8.168778071567107],
              [-40.044417068025517, -8.168778035542095],
              [-40.044417055695504, -8.1687779995684],
              [-40.044417043213841, -8.168777963646678],
              [-40.04441703058076, -8.168777927777535],
              [-40.044417017796498, -8.168777891961627],
              [-40.044417004861231, -8.16877785619956],
              [-40.044416991775229, -8.168777820492009],
              [-40.044416978538727, -8.168777784839545],
              [-40.044416965151946, -8.168777749242842],
              [-40.044416951615112, -8.168777713702518],
              [-40.044416937928482, -8.168777678219202],
              [-40.044416924092275, -8.168777642793517],
              [-40.044416910106762, -8.168777607426078],
              [-40.044416895972176, -8.168777572117547],
              [-40.044416881688782, -8.168777536868499],
              [-40.0444168672568, -8.168777501679598],
              [-40.04441685267652, -8.168777466551459],
              [-40.044416837948177, -8.168777431484681],
              [-40.044416823072041, -8.168777396479875],
              [-40.044416808048368, -8.168777361537712],
              [-40.044416792877442, -8.168777326658759],
              [-40.044416777559491, -8.168777291843668],
              [-40.044416762094833, -8.168777257093017],
              [-40.044416746483705, -8.168777222407451],
              [-40.044416730726411, -8.168777187787555],
              [-40.044416714823214, -8.168777153233965],
              [-40.044416698774377, -8.168777118747283],
              [-40.044416682580234, -8.168777084328138],
              [-40.044416666241027, -8.168777049977102],
              [-40.044416649757054, -8.168777015694801],
              [-40.044416633128613, -8.168776981481839],
              [-40.044416616355996, -8.168776947338806],
              [-40.044416599439494, -8.168776913266326],
              [-40.044416582379412, -8.168776879264998],
              [-40.04441656517605, -8.168776845335405],
              [-40.044416547829712, -8.168776811478157],
              [-40.044416530340705, -8.16877677769387],
              [-40.04441651270934, -8.168776743983111],
              [-40.044416494935916, -8.168776710346505],
              [-40.044416477020761, -8.168776676784638],
              [-40.044416458964186, -8.168776643298063],
              [-40.044416440766518, -8.168776609887441],
              [-40.044416422428078, -8.168776576553311],
              [-40.044416403949164, -8.168776543296293],
              [-40.04441638533013, -8.168776510116919],
              [-40.044416366571291, -8.168776477015841],
              [-40.044416347672993, -8.168776443993607],
              [-40.044416328635563, -8.168776411050823],
              [-40.044416309459322, -8.168776378188058],
              [-40.044416290144639, -8.168776345405886],
              [-40.044416270691826, -8.168776312704887],
              [-40.044416251101246, -8.168776280085638],
              [-40.044416231373241, -8.168776247548729],
              [-40.04441621150815, -8.168776215094743],
              [-40.044416191506322, -8.168776182724223],
              [-40.044416171368127, -8.168776150437761],
              [-40.04441615109392, -8.168776118235915],
              [-40.044416130684048, -8.168776086119269],
              [-40.044416110138876, -8.168776054088397],
              [-40.044416089458757, -8.168776022143838],
              [-40.044416068644068, -8.168775990286164],
              [-40.044416047695186, -8.168775958515948],
              [-40.044416026612467, -8.168775926833774],
              [-40.044416005396286, -8.168775895240154],
              [-40.044415984047006, -8.168775863735668],
              [-40.044415962565019, -8.168775832320856],
              [-40.044415940950707, -8.168775800996311],
              [-40.044415919204468, -8.168775769762531],
              [-40.044415897326637, -8.168775738620115],
              [-40.044415875317632, -8.168775707569607],
              [-40.044415853177846, -8.168775676611551],
              [-40.044415830907653, -8.168775645746466],
              [-40.044415808507459, -8.168775614974926],
              [-40.044415785977669, -8.168775584297499],
              [-40.044415763318646, -8.168775553714674],
              [-40.044415740530823, -8.168775523227028],
              [-40.044415717614605, -8.168775492835074],
              [-40.044415694570361, -8.168775462539369],
              [-40.044415671398525, -8.168775432340423],
              [-40.044415648099516, -8.168775402238785],
              [-40.044415624673732, -8.168775372235007],
              [-40.044415601121585, -8.168775342329623],
              [-40.044415577443488, -8.168775312523104],
              [-40.044415553639858, -8.16877528281602],
              [-40.044415529711131, -8.168775253208896],
              [-40.044415505657732, -8.16877522370223],
              [-40.044415481480058, -8.168775194296582],
              [-40.044415457178559, -8.168775164992455],
              [-40.044415432753652, -8.168775135790323],
              [-40.044415408205786, -8.168775106690761],
              [-40.044415383535366, -8.16877507769428],
              [-40.044415358742853, -8.168775048801358],
              [-40.044415333828681, -8.168775020012506],
              [-40.044415308793283, -8.168774991328258],
              [-40.044415283637115, -8.168774962749117],
              [-40.044415275668626, -8.168774951780403],
              [-40.044007252787601, -8.16831340066882],
              [-40.044007284734676, -8.168313407416099],
              [-40.044007238430687, -8.168313355437897],
              [-40.044007191727204, -8.168313303815822],
              [-40.044007144626953, -8.168313252552839],
              [-40.044007097132727, -8.168313201652019],
              [-40.044007049247341, -8.168313151116342],
              [-40.044007000973615, -8.168313100948859],
              [-40.04400695231439, -8.16831305115244],
              [-40.044006903272553, -8.168313001730088],
              [-40.044006853850981, -8.168312952684735],
              [-40.044006804052628, -8.168312904019198],
              [-40.044006753880403, -8.168312855736421],
              [-40.044006703337303, -8.16831280783922],
              [-40.044006652426262, -8.168312760330476],
              [-40.044006601150322, -8.168312713212908],
              [-40.04400654951251, -8.168312666489367],
              [-40.044006497515838, -8.168312620162597],
              [-40.04400644516344, -8.1683125742353],
              [-40.044006392458364, -8.168312528710214],
              [-40.044006339403744, -8.168312483590059],
              [-40.044006286002698, -8.168312438877471],
              [-40.044006232258369, -8.168312394575091],
              [-40.044006178173966, -8.168312350685518],
              [-40.044006123752659, -8.168312307211373],
              [-40.044006068997668, -8.168312264155208],
              [-40.04400601391221, -8.16831222151956],
              [-40.044005958499561, -8.168312179306964],
              [-40.044005902762976, -8.168312137519917],
              [-40.044005846705758, -8.168312096160872],
              [-40.044005790331227, -8.168312055232253],
              [-40.044005733642699, -8.16831201473652],
              [-40.044005676643515, -8.168311974676024],
              [-40.044005619337057, -8.168311935053149],
              [-40.044005561726678, -8.168311895870231],
              [-40.044005503815825, -8.168311857129583],
              [-40.04400544560788, -8.168311818833505],
              [-40.04400538710631, -8.168311780984244],
              [-40.044005328314555, -8.168311743584061],
              [-40.044005269236081, -8.1683117066351],
              [-40.044005209874392, -8.16831167013963],
              [-40.044005150232977, -8.168311634099771],
              [-40.044005090315387, -8.168311598517633],
              [-40.04400503012512, -8.168311563395331],
              [-40.044004969665743, -8.168311528734932],
              [-40.044004908940849, -8.168311494538489],
              [-40.044004847954, -8.168311460808027],
              [-40.044004786708825, -8.168311427545529],
              [-40.0440047252089, -8.168311394752946],
              [-40.044004663457891, -8.16831136243224],
              [-40.044004601459434, -8.168311330585322],
              [-40.044004539217184, -8.168311299214066],
              [-40.04400447673482, -8.168311268320316],
              [-40.04400441401603, -8.16831123790587],
              [-40.04400435106453, -8.168311207972584],
              [-40.044004287884029, -8.168311178522185],
              [-40.04400422447825, -8.168311149556432],
              [-40.04400416085096, -8.168311121077016],
              [-40.044004097005896, -8.168311093085626],
              [-40.04400403294683, -8.168311065583945],
              [-40.044003968677551, -8.16831103857354],
              [-40.044003904201851, -8.168311012056055],
              [-40.044003839523548, -8.168310986033026],
              [-40.044003774646441, -8.168310960506011],
              [-40.04400370957439, -8.168310935476525],
              [-40.044003644311218, -8.168310910945996],
              [-40.044003578860767, -8.168310886915915],
              [-40.044003513226919, -8.168310863387694],
              [-40.044003447413552, -8.16831084036273],
              [-40.044003381424552, -8.168310817842366],
              [-40.044003315263815, -8.168310795827949],
              [-40.044003248935255, -8.168310774320743],
              [-40.044003182442751, -8.168310753322062],
              [-40.044003115790289, -8.168310732833143],
              [-40.044003048981757, -8.168310712855156],
              [-40.044002982021119, -8.168310693389337],
              [-40.044002914912333, -8.168310674436757],
              [-40.044002847659371, -8.16831065599863],
              [-40.044002780266169, -8.168310638075962],
              [-40.044002712736734, -8.168310620669848],
              [-40.044002645075047, -8.168310603781329],
              [-40.044002577285106, -8.168310587411383],
              [-40.044002509370912, -8.16831057156098],
              [-40.044002441336488, -8.168310556231056],
              [-40.044002373185847, -8.168310541422517],
              [-40.044002304922991, -8.168310527136258],
              [-40.044002236551975, -8.16831051337307],
              [-40.04400216807683, -8.168310500133835],
              [-40.044002099501597, -8.168310487419268],
              [-40.044002030830327, -8.168310475230184],
              [-40.044001962067092, -8.16831046356725],
              [-40.044001893215921, -8.168310452431173],
              [-40.044001824280897, -8.168310441822619],
              [-40.044001755266088, -8.16831043174221],
              [-40.044001686175591, -8.168310422190524],
              [-40.04400161701345, -8.168310413168156],
              [-40.044001547783765, -8.168310404675626],
              [-40.044001478490635, -8.168310396713418],
              [-40.044001409138133, -8.16831038928202],
              [-40.044001339730372, -8.168310382381899],
              [-40.044001270271444, -8.168310376013354],
              [-40.044001254136298, -8.168310341161298],
              [-40.043361555532904, -8.168254199308567],
              [-40.043361537186925, -8.168254189184786],
              [-40.043361499574289, -8.168254185959828],
              [-40.043361461948521, -8.168254182890804],
              [-40.043361424310262, -8.16825417997768],
              [-40.043361386660173, -8.168254177220598],
              [-40.043361348998893, -8.168254174619506],
              [-40.043361311327089, -8.168254172174562],
              [-40.043361273645409, -8.16825416988574],
              [-40.043361235954499, -8.168254167753087],
              [-40.043361198254992, -8.16825416577665],
              [-40.04336116054759, -8.168254163956478],
              [-40.043361122832899, -8.168254162292586],
              [-40.043361085111606, -8.168254160784992],
              [-40.043361047384323, -8.16825415943374],
              [-40.043361009651747, -8.168254158238831],
              [-40.043360971914495, -8.168254157200332],
              [-40.043360934173243, -8.168254156318206],
              [-40.043360896428631, -8.168254155592486],
              [-40.043360858681318, -8.168254155023201],
              [-40.043360820931952, -8.168254154610334],
              [-40.0433607831812, -8.16825415435394],
              [-40.043360745429695, -8.16825415425399],
              [-40.04336070767809, -8.168254154310489],
              [-40.043360669927061, -8.168254154523414],
              [-40.043360632177247, -8.168254154892788],
              [-40.043360594429309, -8.168254155418602],
              [-40.043360556683879, -8.168254156100854],
              [-40.043360518941618, -8.168254156939517],
              [-40.043360481203194, -8.168254157934562],
              [-40.043360443469247, -8.168254159085993],
              [-40.043360405740444, -8.168254160393809],
              [-40.043360368017403, -8.16825416185797],
              [-40.043360330300828, -8.168254163478416],
              [-40.043360292591316, -8.168254165255183],
              [-40.043360254889571, -8.168254167188197],
              [-40.043360217196202, -8.168254169277429],
              [-40.043360179511886, -8.168254171522866],
              [-40.043360141837269, -8.168254173924412],
              [-40.043360104173004, -8.168254176482087],
              [-40.043360066519739, -8.168254179195825],
              [-40.043360028878134, -8.168254182065565],
              [-40.043359991248821, -8.168254185091298],
              [-40.043359953632468, -8.168254188272918],
              [-40.043359916029715, -8.168254191610416],
              [-40.04335987844123, -8.168254195103714],
              [-40.043359840867673, -8.168254198752765],
              [-40.043359803309627, -8.168254202557481],
              [-40.043359765767818, -8.168254206517792],
              [-40.043359728242848, -8.16825421063367],
              [-40.043359690735393, -8.168254214905021],
              [-40.043359653246085, -8.168254219331743],
              [-40.043359615775593, -8.168254223913806],
              [-40.043359578324541, -8.168254228651119],
              [-40.043359540893583, -8.168254233543577],
              [-40.043359503483373, -8.168254238591134],
              [-40.043359466094564, -8.168254243793669],
              [-40.043359428727804, -8.168254249151094],
              [-40.043359391383703, -8.168254254663342],
              [-40.04335935406295, -8.168254260330292],
              [-40.043359316766185, -8.168254266151839],
              [-40.043359279494013, -8.168254272127903],
              [-40.043359242247128, -8.168254278258393],
              [-40.043359205026157, -8.168254284543176],
              [-40.043359167831738, -8.168254290982166],
              [-40.04335913066452, -8.168254297575224],
              [-40.04335909352514, -8.168254304322245],
              [-40.043359056414246, -8.168254311223134],
              [-40.043359019332478, -8.168254318277748],
              [-40.043358982280481, -8.16825432548597],
              [-40.043358945258902, -8.168254332847663],
              [-40.043358908268353, -8.168254340362724],
              [-40.043358871309501, -8.168254348031036],
              [-40.043358834382978, -8.16825435585244],
              [-40.043358797489425, -8.16825436382679],
              [-40.043358760629481, -8.16825437195396],
              [-40.04335872380377, -8.168254380233819],
              [-40.043358687012947, -8.168254388666229],
              [-40.043358650257638, -8.168254397250999],
              [-40.04335861353848, -8.168254405988032],
              [-40.043358576856107, -8.168254414877152],
              [-40.043358540211166, -8.168254423918212],
              [-40.043358503604274, -8.168254433111056],
              [-40.043358467036064, -8.168254442455529],
              [-40.043358430507183, -8.168254451951462],
              [-40.043358394018263, -8.168254461598686],
              [-40.043358357569929, -8.168254471397082],
              [-40.043358321162799, -8.168254481346411],
              [-40.043358284797513, -8.168254491446504],
              [-40.043358248474711, -8.168254501697223],
              [-40.04335821219501, -8.168254512098379],
              [-40.04335817595905, -8.168254522649825],
              [-40.04335813976742, -8.168254533351321],
              [-40.043358103620783, -8.168254544202725],
              [-40.043358067519762, -8.168254555203811],
              [-40.043358031464983, -8.168254566354415],
              [-40.043357995457043, -8.168254577654356],
              [-40.043357959496575, -8.16825458910337],
              [-40.043357923584232, -8.168254600701326],
              [-40.043357887720589, -8.168254612448017],
              [-40.043357851906315, -8.168254624343223],
              [-40.043357835235632, -8.168254661103354],
              [-40.042656896034778, -8.168489081071213],
              [-40.042656170362804, -8.168489263822275],
              [-40.041666586650059, -8.168723611803085],
              [-40.041666562703632, -8.168723646121295],
              [-40.041666451141879, -8.168723673280235],
              [-40.041666339934004, -8.168723701843716],
              [-40.0416662290978, -8.168723731807169],
              [-40.041666118651037, -8.168723763165755],
              [-40.041666008611394, -8.168723795914499],
              [-40.04166589899652, -8.168723830048163],
              [-40.041665789823924, -8.168723865561217],
              [-40.041665681111112, -8.168723902448027],
              [-40.041665572875509, -8.168723940702693],
              [-40.041665465134436, -8.168723980319058],
              [-40.041665357905153, -8.168724021290783],
              [-40.041665251204826, -8.168724063611331],
              [-40.041665145050544, -8.168724107273873],
              [-40.041665039459303, -8.16872415227146],
              [-40.04166493444805, -8.168724198596879],
              [-40.041664830033554, -8.168724246242684],
              [-40.041664726232561, -8.168724295201276],
              [-40.041664623061699, -8.168724345464804],
              [-40.041664520537481, -8.168724397025201],
              [-40.041664418676319, -8.16872444987424],
              [-40.041664317494565, -8.168724504003436],
              [-40.041664217008382, -8.168724559404131],
              [-40.041664117233871, -8.168724616067445],
              [-40.041664018187035, -8.168724673984284],
              [-40.04166391988371, -8.168724733145416],
              [-40.04166382233965, -8.168724793541344],
              [-40.041663725570473, -8.168724855162377],
              [-40.041663629591696, -8.168724917998674],
              [-40.04166353441866, -8.168724982040169],
              [-40.041663440066628, -8.168725047276601],
              [-40.041663346550706, -8.168725113697498],
              [-40.041663253885879, -8.16872518129226],
              [-40.041663162086955, -8.168725250050024],
              [-40.041663071168685, -8.168725319959815],
              [-40.041662981145592, -8.168725391010419],
              [-40.0416628920321, -8.168725463190473],
              [-40.04166280384252, -8.168725536488379],
              [-40.041662716590913, -8.168725610892425],
              [-40.041662630291292, -8.168725686390703],
              [-40.041662544957468, -8.168725762971109],
              [-40.041662460603121, -8.168725840621404],
              [-40.041662377241742, -8.1687259193291],
              [-40.041662294886663, -8.168725999081611],
              [-40.041662213551128, -8.168726079866181],
              [-40.041662133248124, -8.168726161669863],
              [-40.041662053990528, -8.168726244479583],
              [-40.041661975791023, -8.168726328282016],
              [-40.041661898662127, -8.168726413063787],
              [-40.041661822616227, -8.168726498811321],
              [-40.041661747665465, -8.168726585510859],
              [-40.041661673821856, -8.168726673148518],
              [-40.041661601097232, -8.168726761710291],
              [-40.04166152950323, -8.168726851181962],
              [-40.041661459051326, -8.168726941549215],
              [-40.041661389752804, -8.168727032797618],
              [-40.041661321618747, -8.168727124912452],
              [-40.04166125466007, -8.168727217879086],
              [-40.041661188887531, -8.168727311682554],
              [-40.041661124311624, -8.168727406307843],
              [-40.041661060942694, -8.168727501739838],
              [-40.041660998790917, -8.168727597963169],
              [-40.041660937866226, -8.168727694962527],
              [-40.041660878178384, -8.168727792722354],
              [-40.041660819736933, -8.168727891226929],
              [-40.041660762551267, -8.168727990460527],
              [-40.041660706630502, -8.168728090407267],
              [-40.041660651983648, -8.168728191051114],
              [-40.041660598619409, -8.168728292375972],
              [-40.041660546546346, -8.168728394365573],
              [-40.041660495772803, -8.168728497003633],
              [-40.041660446306913, -8.168728600273662],
              [-40.041660398156608, -8.168728704159173],
              [-40.041660351329568, -8.168728808643497],
              [-40.041660305833311, -8.168728913709908],
              [-40.041660261675126, -8.168729019341589],
              [-40.041660218862098, -8.16872912552161],
              [-40.041660177401063, -8.168729232232991],
              [-40.041660137298649, -8.168729339458592],
              [-40.041660098561316, -8.16872944718129],
              [-40.041660061195245, -8.168729555383814],
              [-40.041660025206404, -8.168729664048813],
              [-40.0416599906006, -8.168729773158914],
              [-40.041659957383345, -8.16872988269664],
              [-40.041659925559976, -8.168729992644424],
              [-40.041659895135567, -8.168730102984654],
              [-40.041659866115005, -8.168730213699698],
              [-40.041659838502937, -8.168730324771783],
              [-40.041659812303777, -8.16873043618318],
              [-40.041659787521752, -8.168730547915981],
              [-40.041659764160769, -8.168730659952313],
              [-40.041659742224617, -8.168730772274252],
              [-40.041659721716819, -8.1687308848638],
              [-40.041659702640622, -8.168730997702921],
              [-40.041659684999104, -8.168731110773534],
              [-40.041659668795084, -8.168731224057542],
              [-40.041659654031164, -8.168731337536789],
              [-40.041659640709689, -8.168731451193112],
              [-40.041659628832818, -8.168731565008329],
              [-40.041659618402441, -8.168731678964178],
              [-40.041659614548138, -8.16873171605549],
              [-40.041603413497654, -8.169424418691229],
              [-40.041603396771194, -8.169424439871303],
              [-40.041603395309764, -8.169424457660623],
              [-40.041603393813112, -8.169424475447034],
              [-40.041603392281239, -8.169424493230466],
              [-40.04160339071413, -8.169424511010874],
              [-40.041603389111813, -8.169424528788142],
              [-40.041603387474289, -8.169424546562229],
              [-40.041603385801565, -8.169424564333072],
              [-40.041603384093641, -8.169424582100586],
              [-40.04160338235053, -8.169424599864707],
              [-40.041603380572255, -8.169424617625358],
              [-40.041603378758772, -8.169424635382498],
              [-40.041603376910139, -8.169424653136021],
              [-40.041603375026355, -8.16942467088588],
              [-40.041603373107392, -8.169424688632004],
              [-40.041603371153293, -8.169424706374327],
              [-40.041603369164065, -8.169424724112764],
              [-40.0416033671397, -8.16942474184725],
              [-40.041603365080213, -8.169424759577716],
              [-40.041603362985612, -8.169424777304094],
              [-40.041603360855902, -8.169424795026314],
              [-40.041603358691091, -8.169424812744316],
              [-40.041603356491201, -8.169424830458045],
              [-40.041603354256218, -8.169424848167401],
              [-40.041603351986168, -8.169424865872315],
              [-40.041603349681061, -8.169424883572738],
              [-40.041603347340882, -8.169424901268616],
              [-40.041603344965672, -8.169424918959836],
              [-40.041603342555426, -8.169424936646362],
              [-40.041603340110143, -8.169424954328125],
              [-40.041603337629844, -8.169424972005023],
              [-40.041603335114537, -8.169424989677013],
              [-40.04160333256425, -8.169425007344019],
              [-40.041603329978948, -8.169425025005998],
              [-40.041603327358686, -8.169425042662841],
              [-40.041603324703445, -8.169425060314499],
              [-40.041603322013273, -8.169425077960929],
              [-40.041603319288129, -8.169425095601989],
              [-40.041603316528047, -8.1694251132377],
              [-40.041603313733049, -8.16942513086792],
              [-40.041603310903135, -8.169425148492603],
              [-40.041603308038312, -8.169425166111688],
              [-40.041603305138608, -8.169425183725116],
              [-40.041603302204003, -8.169425201332785],
              [-40.041603299234552, -8.16942521893467],
              [-40.041603296230228, -8.16942523653068],
              [-40.041603293191045, -8.169425254120746],
              [-40.04160329011706, -8.169425271704805],
              [-40.041603287008229, -8.169425289282771],
              [-40.041603283864596, -8.169425306854594],
              [-40.041603280686154, -8.169425324420223],
              [-40.041603277472952, -8.169425341979558],
              [-40.041603274224947, -8.169425359532527],
              [-40.04160327094219, -8.169425377079087],
              [-40.041603267624694, -8.169425394619166],
              [-40.041603264272453, -8.169425412152679],
              [-40.041603260885481, -8.169425429679558],
              [-40.041603257463827, -8.169425447199743],
              [-40.041603254007462, -8.169425464713173],
              [-40.041603250516417, -8.169425482219786],
              [-40.041603246990697, -8.169425499719482],
              [-40.04160324343033, -8.169425517212209],
              [-40.041603239835325, -8.169425534697897],
              [-40.041603236205681, -8.169425552176486],
              [-40.041603232541433, -8.169425569647897],
              [-40.041603228842582, -8.169425587112091],
              [-40.041603225109142, -8.169425604568961],
              [-40.041603221341141, -8.169425622018457],
              [-40.041603217538601, -8.169425639460508],
              [-40.041603213701507, -8.169425656895072],
              [-40.04160320982988, -8.169425674322042],
              [-40.041603205923742, -8.169425691741367],
              [-40.041603201983122, -8.169425709152968],
              [-40.041603198008019, -8.16942572655679],
              [-40.041603193998441, -8.169425743952765],
              [-40.041603189954429, -8.169425761340824],
              [-40.041603185875971, -8.169425778720905],
              [-40.041603181763108, -8.16942579609292],
              [-40.041603177615833, -8.169425813456808],
              [-40.041603173434183, -8.169425830812507],
              [-40.04160316921817, -8.169425848159957],
              [-40.041603164967789, -8.169425865499074],
              [-40.041603160683088, -8.169425882829804],
              [-40.041603156364054, -8.169425900152103],
              [-40.041603152010737, -8.169425917465846],
              [-40.041603147623114, -8.169425934770997],
              [-40.041603143201229, -8.169425952067481],
              [-40.041603138745103, -8.169425969355245],
              [-40.041603134254743, -8.169425986634231],
              [-40.041603129730163, -8.169426003904329],
              [-40.041603125171378, -8.169426021165503],
              [-40.041603120578415, -8.169426038417669],
              [-40.041603115951297, -8.169426055660773],
              [-40.041603111290037, -8.169426072894749],
              [-40.04160310659465, -8.169426090119535],
              [-40.041603101865142, -8.169426107335022],
              [-40.04160309710155, -8.169426124541218],
              [-40.041603092303895, -8.169426141737995],
              [-40.04160308747219, -8.169426158925297],
              [-40.041603082606358, -8.169426176103336],
              [-40.041603054881406, -8.169426137988058],
              [-40.041487111819031, -8.169834052766703],
              [-40.040741199941138, -8.170404120782083],
              [-40.040741176097946, -8.170404115618737],
              [-40.040741140984423, -8.170404142313883],
              [-40.040741105741034, -8.170404168838603],
              [-40.040741070368618, -8.170404195192235],
              [-40.040741034868006, -8.170404221374167],
              [-40.040740999240043, -8.170404247383784],
              [-40.040740963485575, -8.170404273220488],
              [-40.040740927605427, -8.170404298883664],
              [-40.040740891600464, -8.170404324372688],
              [-40.04074085547154, -8.170404349686956],
              [-40.040740819219486, -8.170404374825889],
              [-40.040740782845191, -8.170404399788877],
              [-40.040740746349499, -8.170404424575313],
              [-40.040740709733264, -8.170404449184657],
              [-40.040740672997366, -8.170404473616298],
              [-40.040740636142679, -8.170404497869644],
              [-40.040740599170057, -8.170404521944155],
              [-40.040740562080387, -8.170404545839258],
              [-40.040740524874543, -8.170404569554373],
              [-40.0407404875534, -8.170404593088929],
              [-40.040740450117831, -8.170404616442379],
              [-40.040740412568759, -8.17040463961421],
              [-40.040740374907024, -8.170404662603806],
              [-40.040740337133542, -8.170404685410654],
              [-40.040740299249215, -8.170404708034221],
              [-40.040740261254932, -8.170404730473997],
              [-40.040740223151566, -8.170404752729439],
              [-40.040740184940034, -8.170404774799941],
              [-40.040740146621268, -8.170404796685077],
              [-40.04074010819614, -8.170404818384322],
              [-40.040740069665567, -8.170404839897103],
              [-40.04074003103046, -8.170404861222961],
              [-40.040739992291741, -8.170404882361371],
              [-40.040739953450299, -8.170404903311866],
              [-40.040739914507107, -8.170404924073914],
              [-40.040739875463025, -8.170404944647039],
              [-40.040739836318998, -8.170404965030707],
              [-40.040739797075979, -8.170404985224522],
              [-40.040739757734855, -8.170405005227966],
              [-40.040739718296585, -8.170405025040539],
              [-40.040739678762094, -8.170405044661825],
              [-40.040739639132298, -8.170405064091298],
              [-40.040739599408155, -8.170405083328546],
              [-40.040739559590605, -8.170405102373119],
              [-40.040739519680578, -8.17040512122454],
              [-40.040739479679019, -8.170405139882384],
              [-40.040739439586893, -8.170405158346162],
              [-40.040739399405119, -8.170405176615498],
              [-40.040739359134662, -8.170405194689918],
              [-40.040739318776474, -8.17040521256904],
              [-40.040739278331515, -8.170405230252401],
              [-40.040739237800729, -8.170405247739589],
              [-40.040739197185083, -8.170405265030206],
              [-40.040739156485543, -8.170405282123841],
              [-40.040739115703047, -8.17040529902007],
              [-40.040739074838577, -8.170405315718536],
              [-40.040739033893104, -8.17040533221877],
              [-40.040738992867588, -8.170405348520443],
              [-40.040738951762997, -8.170405364623154],
              [-40.040738910580309, -8.170405380526507],
              [-40.040738869320492, -8.170405396230136],
              [-40.040738827984534, -8.170405411733675],
              [-40.040738786573399, -8.170405427036776],
              [-40.040738745088063, -8.170405442139065],
              [-40.040738703529527, -8.170405457040133],
              [-40.040738661898757, -8.170405471739729],
              [-40.040738620196741, -8.170405486237403],
              [-40.040738578424467, -8.170405500532857],
              [-40.040738536582907, -8.170405514625767],
              [-40.040738494673093, -8.17040552851579],
              [-40.040738452695962, -8.170405542202595],
              [-40.040738410652544, -8.170405555685848],
              [-40.040738368543821, -8.170405568965268],
              [-40.040738326370779, -8.17040558204047],
              [-40.040738284134434, -8.170405594911214],
              [-40.040738241835776, -8.170405607577196],
              [-40.040738199475811, -8.170405620038064],
              [-40.040738157055515, -8.170405632293523],
              [-40.04073811457593, -8.170405644343328],
              [-40.040738072038039, -8.170405656187171],
              [-40.040738029442835, -8.17040566782477],
              [-40.040737986791356, -8.170405679255847],
              [-40.040737944084569, -8.170405690480166],
              [-40.040737901323538, -8.170405701497437],
              [-40.040737858509232, -8.170405712307389],
              [-40.040737815642686, -8.170405722909749],
              [-40.040737772724903, -8.170405733304316],
              [-40.040737729756906, -8.170405743490841],
              [-40.040737686739682, -8.170405753469034],
              [-40.040737643674277, -8.170405763238696],
              [-40.040737600561712, -8.170405772799601],
              [-40.040737557402991, -8.170405782151494],
              [-40.040737514199144, -8.170405791294186],
              [-40.040737470951186, -8.170405800227449],
              [-40.040737427660147, -8.170405808951045],
              [-40.040737384327045, -8.170405817464811],
              [-40.040737340952901, -8.170405825768498],
              [-40.040737297538747, -8.17040583386197],
              [-40.040737254085613, -8.170405841744982],
              [-40.040737210594315, -8.170405849417978],
              [-40.040737166304055, -8.170405856636172],
              [-40.039684570477796, -8.170583287320493],
              [-40.039683844800138, -8.17058347006685],
              [-40.03874889875889, -8.17081875660962],
              [-40.038748918737049, -8.170818797114942],
              [-40.038748871843055, -8.170818809051021],
              [-40.038748825013592, -8.170818821236104],
              [-40.03874877825001, -8.170818833669777],
              [-40.038748731553625, -8.170818846351757],
              [-40.038748684925778, -8.170818859281631],
              [-40.038748638367792, -8.170818872459046],
              [-40.038748591880996, -8.170818885883659],
              [-40.038748545466689, -8.170818899555041],
              [-40.038748499126207, -8.170818913472846],
              [-40.038748452860872, -8.170818927636672],
              [-40.038748406671985, -8.17081894204607],
              [-40.038748360560867, -8.170818956700675],
              [-40.038748314528846, -8.170818971600033],
              [-40.038748268577194, -8.170818986743752],
              [-40.038748222707255, -8.17081900213141],
              [-40.038748176920294, -8.170819017762533],
              [-40.038748131217659, -8.170819033636686],
              [-40.038748085600609, -8.170819049753456],
              [-40.038748040070466, -8.170819066112347],
              [-40.038747994628508, -8.170819082712899],
              [-40.038747949276036, -8.170819099554677],
              [-40.03874790401435, -8.17081911663713],
              [-40.038747858844708, -8.170819133959823],
              [-40.038747813768431, -8.170819151522263],
              [-40.038747768786763, -8.170819169323938],
              [-40.038747723900997, -8.170819187364344],
              [-40.038747679112433, -8.170819205642973],
              [-40.038747634422329, -8.17081922415929],
              [-40.038747589831935, -8.170819242912813],
              [-40.038747545342538, -8.170819261902949],
              [-40.038747500955409, -8.17081928112918],
              [-40.038747456671793, -8.170819300590962],
              [-40.038747412492945, -8.170819320287769],
              [-40.038747368420161, -8.170819340218985],
              [-40.03874732445464, -8.170819360384087],
              [-40.038747280597676, -8.170819380782474],
              [-40.038747236850497, -8.170819401413612],
              [-40.038747193214341, -8.170819422276866],
              [-40.038747149690472, -8.170819443371647],
              [-40.038747106280098, -8.170819464697384],
              [-40.038747062984463, -8.170819486253441],
              [-40.038747019804809, -8.170819508039214],
              [-40.03874697674236, -8.170819530054112],
              [-40.038746933798336, -8.170819552297473],
              [-40.038746890973954, -8.170819574768666],
              [-40.038746848270442, -8.170819597467071],
              [-40.038746805689001, -8.170819620392045],
              [-40.038746763230854, -8.170819643542922],
              [-40.038746720897208, -8.170819666919044],
              [-40.038746678689257, -8.170819690519755],
              [-40.038746636608209, -8.17081971434439],
              [-40.038746594655265, -8.170819738392241],
              [-40.038746552831597, -8.170819762662646],
              [-40.038746511138413, -8.170819787154915],
              [-40.038746469576893, -8.170819811868352],
              [-40.038746428148229, -8.170819836802245],
              [-40.038746386853575, -8.170819861955886],
              [-40.038746345694129, -8.170819887328582],
              [-40.038746304671058, -8.170819912919583],
              [-40.038746263785505, -8.170819938728167],
              [-40.038746223038665, -8.170819964753619],
              [-40.038746182431666, -8.170819990995186],
              [-40.038746141965689, -8.170820017452115],
              [-40.03874610164187, -8.170820044123669],
              [-40.03874606146136, -8.170820071009063],
              [-40.038746021425304, -8.17082009810756],
              [-40.038745981534817, -8.170820125418397],
              [-40.038745941791063, -8.170820152940756],
              [-40.038745902195167, -8.170820180673882],
              [-40.038745862748236, -8.170820208616997],
              [-40.038745823451421, -8.170820236769293],
              [-40.038745784305817, -8.170820265129983],
              [-40.038745745312546, -8.170820293698229],
              [-40.03874570647271, -8.170820322473249],
              [-40.038745667787424, -8.170820351454221],
              [-40.038745629257782, -8.170820380640308],
              [-40.038745590884865, -8.170820410030656],
              [-40.038745552669781, -8.170820439624483],
              [-40.038745514613623, -8.170820469420912],
              [-40.038745476717466, -8.170820499419129],
              [-40.038745438982374, -8.170820529618242],
              [-40.038745401409436, -8.17082056001742],
              [-40.038745363999716, -8.170820590615786],
              [-40.038745326754267, -8.17082062141246],
              [-40.038745289674175, -8.170820652406585],
              [-40.038745252760457, -8.170820683597274],
              [-40.038745216014192, -8.170820714983634],
              [-40.038745179436418, -8.17082074656477],
              [-40.03874514302818, -8.170820778339824],
              [-40.038745106790493, -8.170820810307831],
              [-40.038745070724396, -8.170820842467927],
              [-40.038745034830924, -8.170820874819171],
              [-40.038744999111096, -8.170820907360675],
              [-40.038744963565925, -8.170820940091467],
              [-40.038744928196401, -8.170820973010674],
              [-40.038744893003575, -8.170821006117315],
              [-40.038744857988412, -8.170821039410459],
              [-40.038744823151909, -8.170821072889174],
              [-40.038744788495066, -8.170821106552498],
              [-40.038744820266047, -8.170821118541809],
              [-40.037937923771864, -8.171609029685888],
              [-40.036936231933538, -8.172747987668592],
              [-40.036936219420625, -8.172747996681679],
              [-40.036936167997204, -8.172748054649094],
              [-40.036936116077975, -8.172748112176096],
              [-40.036936063666793, -8.172748169258485],
              [-40.036936010767434, -8.172748225892066],
              [-40.03693595738379, -8.172748282072698],
              [-40.036935903519797, -8.172748337796268],
              [-40.036935849179372, -8.172748393058725],
              [-40.036935794366485, -8.172748447855994],
              [-40.036935739085152, -8.172748502184085],
              [-40.036935683339429, -8.172748556039],
              [-40.036935627133381, -8.172748609416823],
              [-40.036935570471115, -8.172748662313625],
              [-40.036935513356816, -8.172748714725548],
              [-40.036935455794612, -8.172748766648755],
              [-40.036935397788753, -8.172748818079453],
              [-40.036935339343472, -8.172748869013869],
              [-40.036935280463041, -8.172748919448287],
              [-40.036935221151758, -8.172748969378993],
              [-40.036935161414, -8.172749018802358],
              [-40.036935101254102, -8.172749067714753],
              [-40.036935040676475, -8.172749116112612],
              [-40.036934979685569, -8.172749163992377],
              [-40.03693491828583, -8.172749211350549],
              [-40.03693485648175, -8.172749258183675],
              [-40.036934794277855, -8.172749304488315],
              [-40.036934731678713, -8.172749350261062],
              [-40.036934668688872, -8.172749395498608],
              [-40.036934605312979, -8.172749440197608],
              [-40.036934541555645, -8.172749484354826],
              [-40.036934477421532, -8.172749527967007],
              [-40.03693441291535, -8.172749571030963],
              [-40.036934348041804, -8.172749613543537],
              [-40.036934282805674, -8.172749655501615],
              [-40.036934217211694, -8.172749696902136],
              [-40.036934151264688, -8.172749737742095],
              [-40.036934084969459, -8.172749778018453],
              [-40.036934018330882, -8.172749817728329],
              [-40.036933951353809, -8.17274985686875],
              [-40.036933884043179, -8.172749895436885],
              [-40.036933816403888, -8.172749933429882],
              [-40.03693374844088, -8.172749970845006],
              [-40.036933680159144, -8.172750007679497],
              [-40.036933611563676, -8.172750043930645],
              [-40.036933542659497, -8.172750079595827],
              [-40.036933473451633, -8.172750114672413],
              [-40.036933403945177, -8.172750149157842],
              [-40.036933334145189, -8.172750183049567],
              [-40.036933264056778, -8.172750216345161],
              [-40.03693319368508, -8.172750249042142],
              [-40.036933123035254, -8.172750281138155],
              [-40.036933052112452, -8.172750312630805],
              [-40.036932980921883, -8.172750343517832],
              [-40.036932909468739, -8.172750373796955],
              [-40.036932837758243, -8.172750403465958],
              [-40.036932765795676, -8.172750432522655],
              [-40.036932693586273, -8.172750460964957],
              [-40.036932621135328, -8.172750488790749],
              [-40.036932548448135, -8.172750515998011],
              [-40.036932475530037, -8.17275054258474],
              [-40.036932402386334, -8.172750568549008],
              [-40.036932329022406, -8.172750593888907],
              [-40.036932255443602, -8.172750618602588],
              [-40.036932181655338, -8.172750642688225],
              [-40.036932107662984, -8.172750666144063],
              [-40.036932033471956, -8.172750688968403],
              [-40.036931959087703, -8.172750711159562],
              [-40.036931884515631, -8.172750732715905],
              [-40.036931809761235, -8.172750753635864],
              [-40.036931734829963, -8.172750773917908],
              [-40.036931659727315, -8.172750793560567],
              [-40.036931584458777, -8.172750812562398],
              [-40.03693150902987, -8.172750830922007],
              [-40.036931433446071, -8.17275084863803],
              [-40.036931357712959, -8.172750865709165],
              [-40.036931281836061, -8.172750882134235],
              [-40.036931205820913, -8.17275089791195],
              [-40.0369311296731, -8.172750913041204],
              [-40.03693105339817, -8.172750927520868],
              [-40.036930977001738, -8.172750941349941],
              [-40.036930900489374, -8.172750954527324],
              [-40.036930823866669, -8.172750967052096],
              [-40.036930747139245, -8.172750978923334],
              [-40.036930670312707, -8.172750990140194],
              [-40.036930593392675, -8.172751000701821],
              [-40.036930516384793, -8.172751010607445],
              [-40.036930439294686, -8.172751019856376],
              [-40.036930362127983, -8.172751028447891],
              [-40.036930284890346, -8.172751036381404],
              [-40.036930207587432, -8.172751043656309],
              [-40.036930130224889, -8.172751050272057],
              [-40.036930052808373, -8.172751056228208],
              [-40.036929975343554, -8.172751061524275],
              [-40.03692989783611, -8.172751066159927],
              [-40.036929820291697, -8.17275107013478],
              [-40.036929742715998, -8.172751073448561],
              [-40.036929665114677, -8.172751076101022],
              [-40.036929587493439, -8.172751078091983],
              [-40.036929509857934, -8.172751079421293],
              [-40.036929432213867, -8.172751080088849],
              [-40.03692941611915, -8.172751080130096],
              [-40.034652223683437, -8.172756911531312],
              [-40.034652206196185, -8.172756911576048],
              [-40.034652102052007, -8.172756911247159],
              [-40.034651997918438, -8.172756909727733],
              [-40.03465189380924, -8.172756907017947],
              [-40.034651789738106, -8.172756903118184],
              [-40.034651685718707, -8.172756898028981],
              [-40.03465158176477, -8.172756891750943],
              [-40.03465147788998, -8.172756884284972],
              [-40.03465137410798, -8.172756875631988],
              [-40.034651270432455, -8.172756865793152],
              [-40.034651166877048, -8.172756854769762],
              [-40.034651063455399, -8.172756842563265],
              [-40.034650960181104, -8.172756829175279],
              [-40.034650857067774, -8.172756814607553],
              [-40.03465075412899, -8.172756798862006],
              [-40.034650651378278, -8.17275678194069],
              [-40.034650548829191, -8.172756763845898],
              [-40.03465044649522, -8.172756744579958],
              [-40.034650344389831, -8.172756724145406],
              [-40.034650242526453, -8.172756702544953],
              [-40.034650140918529, -8.172756679781436],
              [-40.034650039579404, -8.172756655857848],
              [-40.034649938522442, -8.172756630777336],
              [-40.034649837760931, -8.172756604543192],
              [-40.034649737308122, -8.172756577158896],
              [-40.034649637177267, -8.17275654862804],
              [-40.034649537381561, -8.172756518954397],
              [-40.034649437934092, -8.172756488141859],
              [-40.034649338847998, -8.172756456194465],
              [-40.034649240136289, -8.172756423116429],
              [-40.034649141811975, -8.172756388912118],
              [-40.034649043888002, -8.172756353586026],
              [-40.034648946377253, -8.172756317142824],
              [-40.034648849292552, -8.172756279587274],
              [-40.034648752646717, -8.172756240924334],
              [-40.034648656452461, -8.172756201159116],
              [-40.034648560722417, -8.172756160296812],
              [-40.034648465469203, -8.172756118342843],
              [-40.034648370705376, -8.172756075302711],
              [-40.034648276443392, -8.172756031182105],
              [-40.034648182695655, -8.172755985986775],
              [-40.034648089474537, -8.172755939722713],
              [-40.034647996792259, -8.172755892396054],
              [-40.034647904661078, -8.17275584401294],
              [-40.034647813093081, -8.172755794579764],
              [-40.034647722100352, -8.172755744103071],
              [-40.034647631694845, -8.172755692589497],
              [-40.034647541888468, -8.172755640045793],
              [-40.034647452693065, -8.172755586478893],
              [-40.034647364120325, -8.172755531895874],
              [-40.034647276181964, -8.172755476303887],
              [-40.034647188889544, -8.172755419710244],
              [-40.034647102254532, -8.172755362122421],
              [-40.034647016288368, -8.17275530354798],
              [-40.034646931002335, -8.172755243994642],
              [-40.034646846407696, -8.172755183470274],
              [-40.03464676251555, -8.172755121982798],
              [-40.034646679336952, -8.172755059540323],
              [-40.034646596882887, -8.172754996151083],
              [-40.034646515164155, -8.172754931823393],
              [-40.034646434191558, -8.172754866565723],
              [-40.034646353975724, -8.172754800386704],
              [-40.034646274527233, -8.172754733295053],
              [-40.034646195856546, -8.172754665299536],
              [-40.034646117973999, -8.172754596409153],
              [-40.034646040889854, -8.172754526632977],
              [-40.034645964614263, -8.172754455980161],
              [-40.034645889157268, -8.172754384460003],
              [-40.034645814528801, -8.172754312081967],
              [-40.034645740738675, -8.172754238855557],
              [-40.034645667796617, -8.172754164790392],
              [-40.034645595712234, -8.172754089896234],
              [-40.034645524494991, -8.172754014182974],
              [-40.034645454154294, -8.172753937660556],
              [-40.034645384699388, -8.172753860339009],
              [-40.034645316139404, -8.17275378222857],
              [-40.0346452484834, -8.172753703339497],
              [-40.034645181740252, -8.172753623682189],
              [-40.034645115918742, -8.172753543267126],
              [-40.034645051027567, -8.172753462104907],
              [-40.034644987075239, -8.172753380206228],
              [-40.034644924070207, -8.172753297581783],
              [-40.034644862020734, -8.172753214242583],
              [-40.03464480093502, -8.172753130199487],
              [-40.034644740821072, -8.172753045463597],
              [-40.034644681686835, -8.172752960046077],
              [-40.034644623540061, -8.17275287395816],
              [-40.034644566388437, -8.172752787211182],
              [-40.03464451023946, -8.172752699816575],
              [-40.03464445510054, -8.17275261178583],
              [-40.034644400978941, -8.172752523130534],
              [-40.034644347881766, -8.172752433862353],
              [-40.034644295816015, -8.172752343993059],
              [-40.034644244788538, -8.172752253534481],
              [-40.034644194806063, -8.172752162498519],
              [-40.034644145875156, -8.172752070897147],
              [-40.034644098002289, -8.172751978742426],
              [-40.034644051193716, -8.172751886046521],
              [-40.034644005455633, -8.172751792821598],
              [-40.034643960794689, -8.172751699078839],
              [-40.034643950200916, -8.172751687122398],
              [-40.03388056741646, -8.17112534070352],
              [-40.033880544131478, -8.171125310897583],
              [-40.033880530065375, -8.171125280786942],
              [-40.033880516110337, -8.171125250625009],
              [-40.033880502266562, -8.171125220412236],
              [-40.033880488534223, -8.171125190149017],
              [-40.033880474913524, -8.171125159835759],
              [-40.033880461404628, -8.171125129472877],
              [-40.03388044800775, -8.171125099060756],
              [-40.033880434723038, -8.171125068599832],
              [-40.033880421550691, -8.171125038090469],
              [-40.033880408490873, -8.171125007533155],
              [-40.033880395543761, -8.171124976928242],
              [-40.033880382709548, -8.171124946276176],
              [-40.033880369988388, -8.171124915577334],
              [-40.033880357380454, -8.171124884832155],
              [-40.033880344885915, -8.17112485404107],
              [-40.033880332504957, -8.171124823204462],
              [-40.033880320237735, -8.171124792322756],
              [-40.03388030808442, -8.171124761396383],
              [-40.033880296045147, -8.171124730425722],
              [-40.033880284120109, -8.171124699411239],
              [-40.033880272309474, -8.171124668353336],
              [-40.033880260613373, -8.171124637252419],
              [-40.033880249031988, -8.171124606108926],
              [-40.033880237565462, -8.17112457492326],
              [-40.033880226213952, -8.171124543695846],
              [-40.0338802149776, -8.171124512427113],
              [-40.033880203856583, -8.171124481117454],
              [-40.033880192851029, -8.171124449767323],
              [-40.033880181961095, -8.171124418377143],
              [-40.033880171186929, -8.17112438694733],
              [-40.033880160528675, -8.171124355478291],
              [-40.033880149986466, -8.171124323970478],
              [-40.033880139560459, -8.171124292424295],
              [-40.033880129250804, -8.171124260840166],
              [-40.033880119057613, -8.171124229218563],
              [-40.033880108981045, -8.17112419755985],
              [-40.033880099021218, -8.171124165864468],
              [-40.03388008917829, -8.171124134132874],
              [-40.033880079452359, -8.171124102365487],
              [-40.033880069843583, -8.171124070562726],
              [-40.033880060352089, -8.171124038724997],
              [-40.033880050978006, -8.171124006852772],
              [-40.033880041721453, -8.171123974946452],
              [-40.033880032582545, -8.171123943006494],
              [-40.033880023561423, -8.171123911033307],
              [-40.033880014658216, -8.171123879027327],
              [-40.033880005873016, -8.171123846988984],
              [-40.033879997205965, -8.17112381491871],
              [-40.033879988657176, -8.171123782816938],
              [-40.03387998022675, -8.171123750684091],
              [-40.033879971914821, -8.171123718520629],
              [-40.033879963721482, -8.171123686326967],
              [-40.033879955646846, -8.171123654103532],
              [-40.033879947691041, -8.171123621850773],
              [-40.033879939854145, -8.171123589569124],
              [-40.033879932136308, -8.171123557259017],
              [-40.033879924537594, -8.171123524920898],
              [-40.033879917058101, -8.171123492555195],
              [-40.033879909697973, -8.17112346016234],
              [-40.033879902457265, -8.171123427742764],
              [-40.033879895336092, -8.171123395296922],
              [-40.033879888334553, -8.17112336282524],
              [-40.033879881452748, -8.171123330328147],
              [-40.033879874690754, -8.171123297806103],
              [-40.033879868048665, -8.171123265259531],
              [-40.033879861526593, -8.171123232688879],
              [-40.033879855124582, -8.171123200094575],
              [-40.033879848842766, -8.171123167477067],
              [-40.033879842681209, -8.171123134836799],
              [-40.033879836639983, -8.171123102174205],
              [-40.033879830719187, -8.171123069489738],
              [-40.033879824918891, -8.171123036783793],
              [-40.033879819239168, -8.171123004056883],
              [-40.033879813680116, -8.171122971309384],
              [-40.033879808241807, -8.171122938541762],
              [-40.033879802924275, -8.171122905754478],
              [-40.033879797727629, -8.171122872947954],
              [-40.033879792651931, -8.171122840122637],
              [-40.033879787697266, -8.171122807278969],
              [-40.033879782863671, -8.171122774417389],
              [-40.033879778151224, -8.171122741538348],
              [-40.033879773559988, -8.171122708642287],
              [-40.033879769090028, -8.171122675729643],
              [-40.0338797647414, -8.171122642800889],
              [-40.033879760514168, -8.171122609856425],
              [-40.033879756408382, -8.171122576896712],
              [-40.033879752424099, -8.171122543922197],
              [-40.033879748561382, -8.171122510933341],
              [-40.033879744820268, -8.17112247793056],
              [-40.033879741200835, -8.171122444914316],
              [-40.033879737703089, -8.17112241188504],
              [-40.033879734327108, -8.171122378843192],
              [-40.033879731072936, -8.171122345789202],
              [-40.033879727940608, -8.171122312723547],
              [-40.033879724930166, -8.171122279646667],
              [-40.03387972204164, -8.17112224655898],
              [-40.033879719275099, -8.17112221346096],
              [-40.033879716630572, -8.171122180353018],
              [-40.033879714108032, -8.171122147235891],
              [-40.033879742351523, -8.171122177379587],
              [-40.033819473076392, -8.17031161889337],
              [-40.033819478765317, -8.170311605444036],
              [-40.033819471255022, -8.170311511090196],
              [-40.03381946275411, -8.170311416819834],
              [-40.033819453263533, -8.170311322643276],
              [-40.033819442784321, -8.170311228570837],
              [-40.033819431317596, -8.170311134612851],
              [-40.033819418864631, -8.170311040779545],
              [-40.033819405426819, -8.170310947081232],
              [-40.033819391005572, -8.170310853528186],
              [-40.033819375602533, -8.170310760130644],
              [-40.033819359219351, -8.170310666898821],
              [-40.033819341857821, -8.170310573842929],
              [-40.033819323519864, -8.170310480973191],
              [-40.033819304207462, -8.170310388299747],
              [-40.033819283922753, -8.170310295832763],
              [-40.03381926266794, -8.170310203582346],
              [-40.033819240445389, -8.170310111558642],
              [-40.033819217257488, -8.17031001977168],
              [-40.033819193106794, -8.170309928231546],
              [-40.033819167995951, -8.170309836948245],
              [-40.033819141927729, -8.170309745931782],
              [-40.033819114904958, -8.170309655192137],
              [-40.033819086930599, -8.170309564739249],
              [-40.033819058007715, -8.170309474582998],
              [-40.033819028139497, -8.17030938473328],
              [-40.033818997329199, -8.170309295199946],
              [-40.033818965580195, -8.170309205992771],
              [-40.033818932895954, -8.170309117121564],
              [-40.033818899280085, -8.170309028596026],
              [-40.033818864736233, -8.170308940425864],
              [-40.033818829268199, -8.170308852620757],
              [-40.033818792879885, -8.170308765190272],
              [-40.03381875557524, -8.170308678144043],
              [-40.033818717358379, -8.170308591491562],
              [-40.033818678233494, -8.17030850524233],
              [-40.033818638204835, -8.170308419405792],
              [-40.033818597276806, -8.170308333991382],
              [-40.033818555453891, -8.170308249008393],
              [-40.033818512740659, -8.170308164466174],
              [-40.033818469141828, -8.170308080373969],
              [-40.033818424662122, -8.170307996741007],
              [-40.033818379306425, -8.170307913576409],
              [-40.033818333079729, -8.170307830889342],
              [-40.033818285987067, -8.170307748688822],
              [-40.033818238033625, -8.170307666983835],
              [-40.033818189224647, -8.17030758578338],
              [-40.03381813956544, -8.170307505096316],
              [-40.033818089061505, -8.170307424931508],
              [-40.033818037718333, -8.170307345297708],
              [-40.033817985541575, -8.170307266203636],
              [-40.033817932536913, -8.170307187657993],
              [-40.033817878710167, -8.170307109669338],
              [-40.033817824067242, -8.170307032246244],
              [-40.033817768614121, -8.170306955397171],
              [-40.033817712356864, -8.170306879130564],
              [-40.03381765530164, -8.170306803454706],
              [-40.033817597454693, -8.17030672837797],
              [-40.033817538822376, -8.170306653908536],
              [-40.033817479411098, -8.17030658005458],
              [-40.033817419227354, -8.170306506824163],
              [-40.033817358277744, -8.17030643422533],
              [-40.033817296568962, -8.170306362266027],
              [-40.033817234107758, -8.17030629095412],
              [-40.033817170900953, -8.17030622029746],
              [-40.033817106955503, -8.170306150303727],
              [-40.033817042278379, -8.170306080980639],
              [-40.033816976876672, -8.170306012335756],
              [-40.033816910757565, -8.170305944376613],
              [-40.033816843928285, -8.17030587711063],
              [-40.033816776396151, -8.170305810545191],
              [-40.033816708168565, -8.170305744687603],
              [-40.033816639252997, -8.170305679545047],
              [-40.033816569656977, -8.170305615124656],
              [-40.033816499388159, -8.170305551433527],
              [-40.03381642845423, -8.170305488478627],
              [-40.03381635686295, -8.170305426266825],
              [-40.033816284622176, -8.170305364804928],
              [-40.03381621173979, -8.170305304099704],
              [-40.033816138223784, -8.170305244157785],
              [-40.033816064082238, -8.170305184985732],
              [-40.033815989323266, -8.170305126590037],
              [-40.033815913955017, -8.170305068977074],
              [-40.03381583798577, -8.170305012153172],
              [-40.03381576142386, -8.17030495612453],
              [-40.033815684277663, -8.170304900897321],
              [-40.033815606555621, -8.17030484647761],
              [-40.033815528266238, -8.170304792871304],
              [-40.033815449418121, -8.170304740084292],
              [-40.033815370019887, -8.170304688122348],
              [-40.03381529008022, -8.170304636991174],
              [-40.033815209607894, -8.170304586696396],
              [-40.033815128611714, -8.170304537243464],
              [-40.033815047100539, -8.170304488637848],
              [-40.033814965083344, -8.170304440884831],
              [-40.033814882569054, -8.170304393989678],
              [-40.033814799566734, -8.170304347957508],
              [-40.03381471608548, -8.170304302793346],
              [-40.033814632134423, -8.170304258502162],
              [-40.03381454772277, -8.170304215088795],
              [-40.033814462859745, -8.170304172558009],
              [-40.033814461730707, -8.170304215541691],
              [-40.032530354375055, -8.1696689813511],
              [-40.032530355922134, -8.169668981036059],
              [-40.032530296616962, -8.16966895143624],
              [-40.032530237530551, -8.16966892140521],
              [-40.032530178666043, -8.169668890944605],
              [-40.032530120026621, -8.169668860056042],
              [-40.032530061615397, -8.169668828741154],
              [-40.03253000343549, -8.169668797001611],
              [-40.032529945490019, -8.169668764839111],
              [-40.032529887782069, -8.169668732255399],
              [-40.032529830314729, -8.169668699252199],
              [-40.032529773091085, -8.169668665831272],
              [-40.032529716114176, -8.169668631994416],
              [-40.032529659387052, -8.169668597743422],
              [-40.032529602912746, -8.169668563080156],
              [-40.032529546694278, -8.169668528006442],
              [-40.032529490734653, -8.16966849252416],
              [-40.032529435036878, -8.169668456635195],
              [-40.032529379603893, -8.169668420341454],
              [-40.032529324438684, -8.169668383644915],
              [-40.032529269544199, -8.169668346547533],
              [-40.032529214923372, -8.169668309051264],
              [-40.032529160579102, -8.169668271158127],
              [-40.032529106514325, -8.169668232870182],
              [-40.032529052731924, -8.169668194189432],
              [-40.032528999234742, -8.169668155117936],
              [-40.032528946025671, -8.169668115657796],
              [-40.032528893107553, -8.169668075811124],
              [-40.032528840483195, -8.169668035580072],
              [-40.032528788155432, -8.169667994966773],
              [-40.032528736127055, -8.169667953973354],
              [-40.03252868440083, -8.169667912602071],
              [-40.032528632979549, -8.169667870855092],
              [-40.032528581865932, -8.169667828734694],
              [-40.032528531062717, -8.169667786243078],
              [-40.03252848057263, -8.169667743382529],
              [-40.032528430398386, -8.169667700155369],
              [-40.032528380542608, -8.169667656563862],
              [-40.032528331008024, -8.169667612610375],
              [-40.032528281797255, -8.169667568297246],
              [-40.032528232912924, -8.169667523626842],
              [-40.032528184357652, -8.16966747860155],
              [-40.032528136134033, -8.169667433223752],
              [-40.032528088244632, -8.16966738749592],
              [-40.032528040692043, -8.169667341420457],
              [-40.032527993478766, -8.169667294999865],
              [-40.032527946607367, -8.169667248236582],
              [-40.032527900080296, -8.169667201133127],
              [-40.032527853900092, -8.169667153692018],
              [-40.032527808069212, -8.169667105915797],
              [-40.032527762590092, -8.169667057807002],
              [-40.032527717465165, -8.169667009368235],
              [-40.032527672696816, -8.169666960602035],
              [-40.03252762828749, -8.16966691151107],
              [-40.032527584239546, -8.169666862097921],
              [-40.032527540555314, -8.169666812365232],
              [-40.03252749723714, -8.169666762315655],
              [-40.032527454287354, -8.169666711951898],
              [-40.03252741170823, -8.16966666127661],
              [-40.03252736950207, -8.169666610292531],
              [-40.032527327671104, -8.169666559002362],
              [-40.032527286217579, -8.169666507408866],
              [-40.032527245143697, -8.169666455514813],
              [-40.032527204451689, -8.169666403322944],
              [-40.032527164143687, -8.169666350836067],
              [-40.032527124221872, -8.169666298056974],
              [-40.032527084688354, -8.169666244988504],
              [-40.032527045545287, -8.16966619163345],
              [-40.032527006794716, -8.169666137994719],
              [-40.032526968438745, -8.169666084075143],
              [-40.032526930479406, -8.169666029877638],
              [-40.032526892918739, -8.169665975405069],
              [-40.032526855758753, -8.169665920660353],
              [-40.032526819001426, -8.169665865646431],
              [-40.032526782648716, -8.169665810366238],
              [-40.03252674670258, -8.169665754822699],
              [-40.032526711164941, -8.169665699018806],
              [-40.032526676037669, -8.169665642957542],
              [-40.032526641322683, -8.169665586641914],
              [-40.032526607021815, -8.1696655300749],
              [-40.032526573136892, -8.169665473259577],
              [-40.032526539669746, -8.169665416198923],
              [-40.032526506622148, -8.169665358895998],
              [-40.03252647399588, -8.169665301353898],
              [-40.032526441792676, -8.169665243575668],
              [-40.032526410014242, -8.169665185564421],
              [-40.032526378662311, -8.169665127323219],
              [-40.032526347738518, -8.16966506885522],
              [-40.032526317244553, -8.169665010163543],
              [-40.032526287182023, -8.169664951251276],
              [-40.032526257552554, -8.16966489212164],
              [-40.03252622835771, -8.169664832777743],
              [-40.032526199599054, -8.169664773222763],
              [-40.032526171278136, -8.169664713459907],
              [-40.03252614339646, -8.169664653492326],
              [-40.032526115955513, -8.169664593323271],
              [-40.032526088956772, -8.169664532955926],
              [-40.032526062401665, -8.169664472393551],
              [-40.032526036291635, -8.169664411639346],
              [-40.032526010628047, -8.169664350696573],
              [-40.032525985412022, -8.169664289569242],
              [-40.032525973679171, -8.169664262032793],
              [-40.032293867594049, -8.169090581601024],
              [-40.032293837633233, -8.169090549461185],
              [-40.032293791829503, -8.169090438391326],
              [-40.032293744549804, -8.16909032793721],
              [-40.032293695802444, -8.169090218118303],
              [-40.032293645596042, -8.169090108953947],
              [-40.032293593939443, -8.169090000463401],
              [-40.032293540841742, -8.169089892665795],
              [-40.032293486312312, -8.169089785580113],
              [-40.032293430360745, -8.169089679225262],
              [-40.032293372996939, -8.169089573619948],
              [-40.032293314230976, -8.169089468782813],
              [-40.032293254073245, -8.169089364732356],
              [-40.032293192534311, -8.169089261486885],
              [-40.032293129625053, -8.169089159064626],
              [-40.032293065356569, -8.169089057483616],
              [-40.03229299974015, -8.169088956761771],
              [-40.032292932787385, -8.169088856916837],
              [-40.03229286451009, -8.169088757966446],
              [-40.032292794920274, -8.169088659928006],
              [-40.032292724030242, -8.169088562818832],
              [-40.032292651852465, -8.169088466656028],
              [-40.032292578399648, -8.169088371456537],
              [-40.032292503684786, -8.169088277237142],
              [-40.032292427721011, -8.169088184014472],
              [-40.032292350521736, -8.169088091804932],
              [-40.03229227210057, -8.169088000624805],
              [-40.032292192471317, -8.169087910490116],
              [-40.032292111648047, -8.16908782141682],
              [-40.032292029644971, -8.169087733420591],
              [-40.032291946476569, -8.169087646516928],
              [-40.032291862157514, -8.169087560721184],
              [-40.032291776702635, -8.169087476048459],
              [-40.032291690127018, -8.169087392513681],
              [-40.03229160244593, -8.169087310131575],
              [-40.032291513674821, -8.169087228916668],
              [-40.032291423829342, -8.169087148883278],
              [-40.032291332925311, -8.169087070045542],
              [-40.032291240978779, -8.169086992417272],
              [-40.032291148005967, -8.169086916012244],
              [-40.032291054023219, -8.169086840843896],
              [-40.032290959047145, -8.169086766925478],
              [-40.032290863094474, -8.169086694270019],
              [-40.032290766182108, -8.169086622890326],
              [-40.032290668327143, -8.169086552798966],
              [-40.032290569546831, -8.169086484008329],
              [-40.032290469858594, -8.169086416530535],
              [-40.032290369279977, -8.169086350377444],
              [-40.032290267828749, -8.169086285560756],
              [-40.032290165522753, -8.169086222091883],
              [-40.032290062380049, -8.169086159982044],
              [-40.032289958418822, -8.169086099242127],
              [-40.032289853657403, -8.169086039882886],
              [-40.032289748114231, -8.169085981914767],
              [-40.032289641807935, -8.169085925347998],
              [-40.032289534757268, -8.169085870192559],
              [-40.032289426981059, -8.169085816458145],
              [-40.03228931849835, -8.169085764154254],
              [-40.032289209328248, -8.169085713290078],
              [-40.032289099489994, -8.169085663874602],
              [-40.032288989002957, -8.169085615916565],
              [-40.032288877886607, -8.169085569424379],
              [-40.032288766160541, -8.169085524406249],
              [-40.032288653844461, -8.169085480870137],
              [-40.032288540958135, -8.169085438823679],
              [-40.032288427521507, -8.169085398274321],
              [-40.032288313554552, -8.169085359229188],
              [-40.032288199077357, -8.169085321695198],
              [-40.032288084110114, -8.169085285678911],
              [-40.032287968673074, -8.169085251186718],
              [-40.032287852786602, -8.169085218224692],
              [-40.032287736471112, -8.169085186798624],
              [-40.032287619747137, -8.169085156914083],
              [-40.032287502635235, -8.16908512857631],
              [-40.032287385156039, -8.169085101790323],
              [-40.032287267330283, -8.169085076560833],
              [-40.0322871491787, -8.16908505289229],
              [-40.032287030722173, -8.169085030788866],
              [-40.032286911981551, -8.169085010254468],
              [-40.032286792977764, -8.169084991292682],
              [-40.032286673731811, -8.169084973906903],
              [-40.032286554264701, -8.169084958100147],
              [-40.032286434597474, -8.169084943875225],
              [-40.032286314751275, -8.169084931234641],
              [-40.032286194747186, -8.169084920180639],
              [-40.03228607460639, -8.169084910715142],
              [-40.032285954350037, -8.169084902839822],
              [-40.032285833999353, -8.169084896556072],
              [-40.032285713575561, -8.169084891865019],
              [-40.032285593099843, -8.169084888767461],
              [-40.0322854725935, -8.169084887263956],
              [-40.032285352077729, -8.169084887354769],
              [-40.032285231573795, -8.16908488903988],
              [-40.032285111102929, -8.169084892319015],
              [-40.032284990686378, -8.169084897191581],
              [-40.032284870345372, -8.169084903656696],
              [-40.032284750101127, -8.16908491171325],
              [-40.032284629974825, -8.16908492135982],
              [-40.032284509987655, -8.169084932594686],
              [-40.032284390160768, -8.169084945415854],
              [-40.032284270515298, -8.169084959821117],
              [-40.032284231766532, -8.169084998841356],
              [-40.03182754195965, -8.16914305098366],
              [-40.031827498586829, -8.169143022699606],
              [-40.03182740172771, -8.16914303554014],
              [-40.031827305012918, -8.169143049418853],
              [-40.031827208453684, -8.16914306433415],
              [-40.031827112061194, -8.169143080284272],
              [-40.031827015846609, -8.169143097267364],
              [-40.031826919821071, -8.169143115281486],
              [-40.031826823995729, -8.169143134324527],
              [-40.031826728381688, -8.169143154394291],
              [-40.031826632990011, -8.169143175488447],
              [-40.031826537831748, -8.169143197604548],
              [-40.031826442917961, -8.169143220740043],
              [-40.031826348259614, -8.169143244892245],
              [-40.031826253867692, -8.169143270058377],
              [-40.03182615975313, -8.169143296235466],
              [-40.031826065926843, -8.169143323420531],
              [-40.031825972399687, -8.1691433516104],
              [-40.03182587918252, -8.169143380801842],
              [-40.031825786286134, -8.169143410991412],
              [-40.031825693721295, -8.169143442175638],
              [-40.031825601498731, -8.169143474350912],
              [-40.031825509629122, -8.169143507513501],
              [-40.031825418123134, -8.169143541659585],
              [-40.031825326991346, -8.16914357678516],
              [-40.031825236244352, -8.169143612886195],
              [-40.031825145892618, -8.169143649958494],
              [-40.031825055946676, -8.169143687997778],
              [-40.031824966416892, -8.169143726999598],
              [-40.031824877313696, -8.169143766959468],
              [-40.031824788647363, -8.169143807872731],
              [-40.031824700428189, -8.169143849734654],
              [-40.031824612666405, -8.169143892540413],
              [-40.03182452537218, -8.169143936285018],
              [-40.031824438555631, -8.169143980963401],
              [-40.031824352226806, -8.169144026570379],
              [-40.031824266395702, -8.169144073100695],
              [-40.031824181072309, -8.169144120548941],
              [-40.031824096266469, -8.169144168909639],
              [-40.031824011988029, -8.169144218177141],
              [-40.031823928246737, -8.169144268345756],
              [-40.031823845052344, -8.169144319409696],
              [-40.031823762414454, -8.169144371363014],
              [-40.031823680342647, -8.1691444241997],
              [-40.031823598846437, -8.169144477913612],
              [-40.031823517935265, -8.169144532498567],
              [-40.031823437618513, -8.169144587948191],
              [-40.031823357905488, -8.169144644256086],
              [-40.03182327880544, -8.169144701415746],
              [-40.031823200327501, -8.169144759420512],
              [-40.031823122480802, -8.169144818263689],
              [-40.031823045274351, -8.169144877938427],
              [-40.031822968717087, -8.169144938437817],
              [-40.031822892817878, -8.169144999754881],
              [-40.031822817585542, -8.169145061882467],
              [-40.031822743028791, -8.169145124813415],
              [-40.031822669156242, -8.169145188540385],
              [-40.0318225959765, -8.169145253056067],
              [-40.031822523498001, -8.169145318352884],
              [-40.031822451729177, -8.169145384423365],
              [-40.031822380678321, -8.169145451259794],
              [-40.031822310353654, -8.169145518854426],
              [-40.031822240763375, -8.169145587199472],
              [-40.031822171915529, -8.169145656286997],
              [-40.031822103818058, -8.169145726108953],
              [-40.031822036478907, -8.169145796657274],
              [-40.031821969905842, -8.169145867923804],
              [-40.031821904106593, -8.16914593990027],
              [-40.031821839088792, -8.169146012578326],
              [-40.031821774859949, -8.169146085949546],
              [-40.03182171142754, -8.169146160005429],
              [-40.031821648798896, -8.169146234737426],
              [-40.031821586981266, -8.169146310136842],
              [-40.031821525981826, -8.169146386194949],
              [-40.031821465807667, -8.169146462902924],
              [-40.031821406465731, -8.169146540251885],
              [-40.031821347962889, -8.169146618232871],
              [-40.031821290305956, -8.169146696836831],
              [-40.031821233501567, -8.169146776054671],
              [-40.031821177556367, -8.169146855877193],
              [-40.031821122476771, -8.169146936295169],
              [-40.03182106826921, -8.16914701729926],
              [-40.031821014939943, -8.169147098880119],
              [-40.031820962495175, -8.169147181028269],
              [-40.031820910940951, -8.169147263734155],
              [-40.031820860283254, -8.169147346988243],
              [-40.031820810527975, -8.169147430780834],
              [-40.031820761680869, -8.169147515102244],
              [-40.031820713747585, -8.169147599942718],
              [-40.031820666733694, -8.169147685292407],
              [-40.031820620644638, -8.169147771141411],
              [-40.031820575485732, -8.169147857479821],
              [-40.031820531262255, -8.169147944297571],
              [-40.031820487979317, -8.169148031584658],
              [-40.03182044564192, -8.169148119330949],
              [-40.031820404254972, -8.169148207526263],
              [-40.031820363823293, -8.169148296160371],
              [-40.031820324351536, -8.169148385223016],
              [-40.03182028584429, -8.169148474703894],
              [-40.031820248306026, -8.169148564592581],
              [-40.031820211741092, -8.169148654878725],
              [-40.031820204142576, -8.169148677035743],
              [-40.031711527889222, -8.169421271967469],
              [-40.031711504355634, -8.169421294637257],
              [-40.031711431394228, -8.169421472117431],
              [-40.031711354672098, -8.169421648016151],
              [-40.031711274223724, -8.169421822254291],
              [-40.031711190085304, -8.169421994753474],
              [-40.031711102294651, -8.169422165436124],
              [-40.031711010891264, -8.169422334225516],
              [-40.031710915916257, -8.169422501045736],
              [-40.031710817412304, -8.169422665821761],
              [-40.031710715423735, -8.169422828479492],
              [-40.031710609996423, -8.169422988945751],
              [-40.031710501177749, -8.169423147148384],
              [-40.031710389016681, -8.169423303016281],
              [-40.031710273563625, -8.169423456479294],
              [-40.031710154870524, -8.169423607468445],
              [-40.031710032990773, -8.169423755915831],
              [-40.031709907979149, -8.169423901754699],
              [-40.03170977989187, -8.169424044919429],
              [-40.031709648786567, -8.169424185345688],
              [-40.031709514722174, -8.169424322970258],
              [-40.031709377759, -8.169424457731328],
              [-40.031709237958623, -8.16942458956823],
              [-40.031709095383931, -8.169424718421707],
              [-40.031708950099031, -8.1694248442338],
              [-40.031708802169241, -8.169424966947906],
              [-40.031708651661113, -8.169425086508911],
              [-40.031708498642331, -8.169425202862978],
              [-40.031708343181698, -8.169425315957831],
              [-40.031708185349125, -8.169425425742538],
              [-40.031708025215607, -8.16942553216778],
              [-40.031707862853153, -8.169425635185711],
              [-40.031707698334749, -8.169425734750007],
              [-40.031707531734426, -8.169425830815836],
              [-40.031707363127076, -8.169425923340018],
              [-40.031707192588534, -8.169426012281001],
              [-40.031707020195498, -8.169426097598686],
              [-40.031706846025472, -8.169426179254781],
              [-40.031706670156815, -8.169426257212569],
              [-40.03170649266859, -8.169426331436938],
              [-40.03170631364064, -8.169426401894556],
              [-40.031706133153449, -8.169426468553693],
              [-40.03170595128821, -8.169426531384415],
              [-40.031705768126685, -8.169426590358441],
              [-40.031705583751247, -8.169426645449271],
              [-40.031705398244867, -8.169426696632128],
              [-40.031705211690884, -8.169426743883943],
              [-40.03170502417322, -8.169426787183523],
              [-40.031704835776253, -8.169426826511339],
              [-40.031704646584657, -8.169426861849784],
              [-40.031704456683521, -8.16942689318291],
              [-40.031704266158272, -8.169426920496649],
              [-40.031704075094545, -8.169426943778708],
              [-40.031703883578302, -8.169426963018637],
              [-40.031703691695668, -8.169426978207731],
              [-40.031703499532902, -8.169426989339218],
              [-40.03170330717645, -8.169426996408053],
              [-40.031703114712805, -8.169426999411087],
              [-40.031702922228547, -8.169426998346939],
              [-40.031702729810206, -8.169426993216135],
              [-40.031702537544327, -8.169426984020934],
              [-40.031702345517367, -8.169426970765468],
              [-40.031702153815701, -8.169426953455739],
              [-40.03170196252551, -8.169426932099505],
              [-40.031701771732848, -8.169426906706384],
              [-40.031701581523507, -8.169426877287771],
              [-40.031701391983006, -8.169426843856902],
              [-40.03170120319664, -8.169426806428822],
              [-40.03170101524924, -8.169426765020381],
              [-40.031700828225347, -8.169426719650179],
              [-40.031700642209088, -8.169426670338609],
              [-40.031700457284131, -8.16942661710789],
              [-40.031700273533595, -8.169426559981922],
              [-40.031700091040157, -8.169426498986381],
              [-40.031699909885837, -8.169426434148736],
              [-40.031699730152134, -8.169426365498129],
              [-40.031699551919871, -8.169426293065435],
              [-40.03169937526922, -8.16942621688321],
              [-40.031699200279562, -8.169426136985741],
              [-40.031699027029667, -8.169426053408968],
              [-40.031698855597412, -8.169425966190433],
              [-40.031698686059897, -8.169425875369365],
              [-40.031698518493343, -8.169425780986668],
              [-40.031698352973144, -8.169425683084732],
              [-40.031698189573724, -8.169425581707563],
              [-40.031698028368531, -8.16942547690082],
              [-40.031697869430118, -8.169425368711595],
              [-40.031697712829917, -8.169425257188536],
              [-40.031697558638371, -8.169425142381836],
              [-40.031697406924827, -8.169425024343067],
              [-40.031697257757493, -8.169424903125339],
              [-40.031697111203464, -8.169424778783164],
              [-40.031696967328656, -8.169424651372484],
              [-40.031696826197759, -8.16942452095058],
              [-40.031696687874224, -8.169424387576102],
              [-40.0316965524203, -8.169424251309012],
              [-40.031696419896861, -8.16942411221059],
              [-40.031696290363534, -8.169423970343438],
              [-40.031696163878543, -8.169423825771325],
              [-40.031696040498787, -8.169423678559252],
              [-40.031695920279425, -8.16942352877372],
              [-40.031695921565564, -8.16942357278328],
              [-40.031294773545497, -8.168912699271917],
              [-40.031294792973512, -8.168912735950066],
              [-40.031294772521854, -8.168912710004626],
              [-40.031294751975338, -8.168912684133726],
              [-40.031294731334249, -8.168912658337659],
              [-40.031294710598864, -8.168912632616811],
              [-40.031294689769432, -8.168912606971507],
              [-40.031294668846257, -8.168912581402056],
              [-40.031294647829618, -8.168912555908827],
              [-40.031294626719784, -8.168912530492157],
              [-40.031294605517033, -8.168912505152361],
              [-40.031294584221662, -8.168912479889817],
              [-40.031294562833942, -8.16891245470485],
              [-40.031294541354171, -8.168912429597773],
              [-40.031294519782605, -8.16891240456895],
              [-40.031294498119571, -8.168912379618709],
              [-40.031294476365325, -8.168912354747363],
              [-40.031294454520165, -8.168912329955246],
              [-40.031294432584389, -8.168912305242671],
              [-40.031294410558289, -8.168912280610003],
              [-40.031294388442163, -8.168912256057572],
              [-40.031294366236288, -8.168912231585685],
              [-40.031294343940971, -8.168912207194669],
              [-40.031294321556501, -8.168912182884858],
              [-40.031294299083186, -8.168912158656553],
              [-40.031294276521308, -8.168912134510109],
              [-40.031294253871202, -8.168912110445815],
              [-40.031294231133117, -8.168912086464019],
              [-40.031294208307408, -8.168912062565026],
              [-40.031294185394344, -8.168912038749152],
              [-40.031294162394239, -8.168912015016701],
              [-40.03129413930742, -8.168911991368008],
              [-40.031294116134156, -8.168911967803401],
              [-40.031294092874781, -8.168911944323174],
              [-40.031294069529601, -8.168911920927659],
              [-40.031294046098921, -8.168911897617134],
              [-40.031294022583047, -8.168911874391933],
              [-40.031293998982321, -8.168911851252338],
              [-40.031293975297039, -8.168911828198686],
              [-40.031293951527495, -8.168911805231266],
              [-40.031293927674042, -8.16891178235041],
              [-40.031293903736966, -8.168911759556391],
              [-40.031293879716607, -8.168911736849543],
              [-40.031293855613285, -8.168911714230131],
              [-40.031293831427298, -8.168911691698481],
              [-40.031293807158988, -8.168911669254905],
              [-40.031293782808675, -8.168911646899678],
              [-40.03129375837667, -8.168911624633102],
              [-40.031293733863322, -8.168911602455493],
              [-40.031293709268937, -8.168911580367102],
              [-40.031293684593855, -8.168911558368253],
              [-40.031293659838397, -8.168911536459261],
              [-40.031293635002896, -8.168911514640381],
              [-40.031293610087673, -8.168911492911914],
              [-40.031293585093067, -8.168911471274162],
              [-40.031293560019414, -8.168911449727371],
              [-40.031293534867039, -8.168911428271887],
              [-40.031293509636292, -8.168911406907966],
              [-40.031293484327492, -8.168911385635861],
              [-40.031293458940986, -8.168911364455916],
              [-40.031293433477103, -8.168911343368366],
              [-40.031293407936197, -8.168911322373516],
              [-40.031293382318594, -8.168911301471631],
              [-40.031293356624637, -8.168911280662993],
              [-40.031293330854673, -8.168911259947894],
              [-40.031293305009036, -8.168911239326599],
              [-40.031293279088068, -8.168911218799368],
              [-40.031293253092137, -8.168911198366503],
              [-40.031293227021578, -8.168911178028255],
              [-40.031293200876725, -8.168911157784905],
              [-40.031293174657918, -8.168911137636718],
              [-40.031293148365528, -8.168911117583951],
              [-40.031293121999916, -8.168911097626886],
              [-40.031293095561409, -8.168911077765776],
              [-40.031293069050371, -8.168911058000885],
              [-40.031293042467134, -8.168911038332491],
              [-40.031293015812075, -8.168911018760856],
              [-40.031292989085557, -8.168910999286233],
              [-40.031292962287893, -8.168910979908871],
              [-40.031292935419472, -8.168910960629047],
              [-40.031292908480644, -8.168910941447004],
              [-40.031292881471785, -8.168910922362997],
              [-40.031292854393229, -8.168910903377313],
              [-40.031292827245352, -8.168910884490137],
              [-40.031292800028496, -8.1689108657018],
              [-40.031292772743051, -8.168910847012494],
              [-40.031292745389351, -8.168910828422495],
              [-40.03129271796778, -8.168910809932028],
              [-40.031292690478693, -8.168910791541357],
              [-40.031292662922468, -8.168910773250722],
              [-40.031292635299458, -8.168910755060356],
              [-40.031292607610048, -8.168910736970508],
              [-40.031292579854579, -8.168910718981451],
              [-40.031292552033435, -8.168910701093369],
              [-40.031292524146998, -8.168910683306541],
              [-40.031292496195633, -8.168910665621173],
              [-40.031292468179686, -8.168910648037535],
              [-40.031292440099563, -8.168910630555827],
              [-40.031292411955626, -8.168910613176317],
              [-40.031292383748251, -8.168910595899188],
              [-40.031292355477802, -8.168910578724711],
              [-40.031292317235064, -8.168910534947639],
              [-40.030240309712781, -8.168274067798754],
              [-40.030240269805688, -8.168274047202583],
              [-40.030240200077991, -8.168274005440864],
              [-40.030240129977443, -8.168273964303522],
              [-40.030240059509659, -8.168273923793853],
              [-40.0302399886803, -8.168273883915123],
              [-40.030239917495088, -8.168273844670537],
              [-40.030239845959699, -8.168273806063263],
              [-40.030239774079902, -8.168273768096402],
              [-40.030239701861468, -8.16827373077297],
              [-40.030239629310209, -8.168273694095991],
              [-40.030239556431937, -8.168273658068378],
              [-40.030239483232513, -8.16827362269308],
              [-40.030239409717815, -8.168273587972894],
              [-40.030239335893746, -8.168273553910636],
              [-40.030239261766255, -8.168273520509056],
              [-40.03023918734128, -8.168273487770781],
              [-40.030239112624798, -8.168273455698493],
              [-40.03023903762282, -8.168273424294755],
              [-40.030238962341372, -8.168273393562075],
              [-40.030238886786492, -8.168273363502918],
              [-40.030238810964249, -8.168273334119705],
              [-40.030238734880747, -8.168273305414802],
              [-40.030238658542096, -8.168273277390533],
              [-40.030238581954436, -8.16827325004914],
              [-40.030238505123876, -8.168273223392813],
              [-40.030238428056656, -8.168273197423677],
              [-40.030238350758907, -8.16827317214382],
              [-40.030238273236868, -8.168273147555301],
              [-40.03023819549675, -8.168273123660072],
              [-40.030238117544826, -8.168273100460091],
              [-40.030238039387335, -8.168273077957178],
              [-40.030237961030572, -8.168273056153152],
              [-40.030237882480805, -8.168273035049767],
              [-40.030237803744377, -8.168273014648722],
              [-40.030237724827593, -8.168272994951653],
              [-40.03023764573679, -8.168272975960146],
              [-40.030237566478348, -8.168272957675702],
              [-40.030237487058606, -8.168272940099856],
              [-40.030237407483945, -8.16827292323393],
              [-40.030237327760787, -8.168272907079334],
              [-40.030237247895499, -8.168272891637375],
              [-40.030237167894512, -8.168272876909251],
              [-40.030237087764256, -8.168272862896163],
              [-40.030237007511175, -8.168272849599266],
              [-40.030236927141701, -8.168272837019597],
              [-40.030236846662298, -8.168272825158178],
              [-40.030236766079426, -8.168272814015939],
              [-40.030236685399565, -8.168272803593819],
              [-40.030236604629202, -8.168272793892612],
              [-40.030236523774825, -8.168272784913118],
              [-40.030236442842899, -8.168272776656066],
              [-40.030236361839968, -8.168272769122089],
              [-40.030236280772506, -8.16827276231184],
              [-40.030236199647042, -8.168272756225825],
              [-40.0302361184701, -8.168272750864533],
              [-40.030236037248194, -8.168272746228419],
              [-40.030235955987841, -8.168272742317857],
              [-40.03023587469557, -8.168272739133126],
              [-40.030235793377926, -8.168272736674524],
              [-40.030235712041431, -8.168272734942224],
              [-40.030235630692601, -8.168272733936352],
              [-40.030235549338023, -8.168272733657005],
              [-40.030235467984177, -8.168272734104205],
              [-40.030235386637628, -8.168272735277951],
              [-40.030235305304913, -8.168272737178107],
              [-40.030235223992548, -8.168272739804516],
              [-40.03023514270707, -8.168272743156988],
              [-40.030235061455031, -8.16827274723523],
              [-40.030234980242923, -8.168272752038932],
              [-40.030234899077293, -8.168272757567722],
              [-40.030234817964654, -8.168272763821117],
              [-40.030234736911531, -8.168272770798648],
              [-40.030234655924424, -8.168272778499768],
              [-40.03023457500985, -8.168272786923811],
              [-40.030234494174302, -8.168272796070134],
              [-40.03023441342426, -8.168272805937974],
              [-40.030234332766241, -8.168272816526576],
              [-40.030234252206718, -8.168272827835079],
              [-40.030234171752127, -8.168272839862549],
              [-40.030234091408978, -8.168272852608057],
              [-40.030234011183694, -8.168272866070531],
              [-40.030233931082762, -8.16827288024894],
              [-40.030233851112548, -8.168272895142113],
              [-40.030233771279541, -8.168272910748847],
              [-40.030233691590105, -8.16827292706791],
              [-40.030233612050687, -8.168272944097998],
              [-40.030233532667644, -8.16827296183774],
              [-40.030233453447352, -8.168272980285654],
              [-40.030233374396218, -8.16827299944033],
              [-40.030233295520539, -8.168273019300216],
              [-40.030233216826666, -8.168273039863696],
              [-40.030233138320952, -8.168273061129122],
              [-40.030233060009671, -8.168273083094791],
              [-40.030232981899125, -8.168273105758947],
              [-40.030232903995589, -8.168273129119747],
              [-40.030232826305316, -8.168273153175337],
              [-40.030232748834543, -8.168273177923774],
              [-40.030232671589523, -8.168273203363045],
              [-40.030232594576418, -8.168273229491163],
              [-40.030232517801444, -8.168273256305998],
              [-40.030232501579171, -8.168273273706532],
              [-40.029246560204172, -8.168622533447078],
              [-40.029246536588431, -8.168622575257094],
              [-40.029246430421878, -8.168622613613129],
              [-40.029246324742466, -8.168622653282291],
              [-40.029246219566566, -8.168622694258424],
              [-40.029246114910457, -8.168622736535175],
              [-40.029246010790317, -8.168622780106022],
              [-40.02924590722229, -8.168622824964212],
              [-40.029245804222384, -8.168622871102812],
              [-40.029245701806566, -8.16862291851467],
              [-40.029245599990666, -8.168622967192452],
              [-40.029245498790466, -8.16862301712861],
              [-40.029245398221626, -8.168623068315433],
              [-40.029245298299699, -8.168623120744968],
              [-40.029245199040155, -8.168623174409118],
              [-40.029245100458382, -8.168623229299596],
              [-40.02924500256961, -8.168623285407886],
              [-40.029244905389007, -8.168623342725288],
              [-40.029244808931615, -8.168623401242961],
              [-40.029244713212357, -8.168623460951833],
              [-40.029244618246061, -8.168623521842667],
              [-40.029244524047414, -8.16862358390601],
              [-40.029244430631017, -8.168623647132291],
              [-40.029244338011317, -8.168623711511685],
              [-40.029244246202637, -8.168623777034288],
              [-40.029244155219224, -8.168623843689881],
              [-40.029244065075119, -8.168623911468188],
              [-40.029243975784311, -8.168623980358719],
              [-40.029243887360607, -8.168624050350816],
              [-40.029243799817692, -8.168624121433622],
              [-40.029243713169123, -8.168624193596145],
              [-40.029243627428308, -8.168624266827223],
              [-40.029243542608519, -8.168624341115493],
              [-40.029243458722895, -8.168624416449489],
              [-40.029243375784404, -8.168624492817557],
              [-40.029243293805905, -8.168624570207811],
              [-40.029243212800061, -8.168624648608338],
              [-40.029243132779428, -8.168624728006968],
              [-40.029243053756403, -8.168624808391428],
              [-40.029242975743202, -8.168624889749267],
              [-40.029242898751896, -8.16862497206791],
              [-40.029242822794409, -8.168625055334571],
              [-40.029242747882513, -8.168625139536399],
              [-40.029242674027792, -8.168625224660342],
              [-40.02924260124167, -8.168625310693253],
              [-40.029242529535424, -8.168625397621788],
              [-40.029242458920173, -8.168625485432504],
              [-40.029242389406789, -8.168625574111763],
              [-40.029242321006087, -8.168625663645905],
              [-40.029242253728633, -8.168625754021013],
              [-40.029242187584849, -8.168625845223158],
              [-40.029242122584961, -8.168625937238183],
              [-40.029242058739044, -8.168626030051838],
              [-40.029241996056989, -8.168626123649808],
              [-40.029241934548473, -8.168626218017513],
              [-40.029241874223032, -8.168626313140427],
              [-40.02924181509001, -8.168626409003799],
              [-40.02924175715853, -8.168626505592803],
              [-40.029241700437609, -8.168626602892459],
              [-40.029241644935979, -8.168626700887701],
              [-40.029241590662267, -8.168626799563418],
              [-40.029241537624856, -8.16862689890427],
              [-40.029241485831946, -8.168626998894899],
              [-40.029241435291574, -8.168627099519842],
              [-40.029241386011563, -8.168627200763531],
              [-40.029241337999522, -8.168627302610261],
              [-40.029241291262899, -8.168627405044296],
              [-40.029241245808933, -8.168627508049768],
              [-40.029241201644645, -8.168627611610754],
              [-40.029241158776877, -8.168627715711184],
              [-40.029241117212266, -8.168627820334953],
              [-40.029241076957248, -8.1686279254659],
              [-40.02924103801805, -8.168628031087714],
              [-40.029241000400695, -8.168628137184063],
              [-40.029240964111025, -8.168628243738519],
              [-40.029240929154639, -8.168628350734593],
              [-40.029240895536951, -8.168628458155727],
              [-40.029240863263162, -8.168628565985255],
              [-40.029240832338282, -8.168628674206555],
              [-40.029240802767077, -8.168628782802823],
              [-40.029240774554154, -8.168628891757253],
              [-40.029240747703859, -8.168629001052995],
              [-40.029240722220337, -8.168629110673141],
              [-40.029240698107543, -8.168629220600684],
              [-40.02924067536923, -8.168629330818678],
              [-40.029240654008895, -8.168629441309962],
              [-40.029240634029861, -8.168629552057496],
              [-40.029240615435207, -8.168629663044142],
              [-40.029240598227801, -8.16862977425269],
              [-40.029240582410331, -8.168629885665952],
              [-40.029240567985234, -8.168629997266668],
              [-40.02924055495474, -8.168630109037558],
              [-40.029240543320874, -8.168630220961358],
              [-40.029240533085442, -8.168630333020694],
              [-40.02924052425, -8.168630445198232],
              [-40.02924051681596, -8.168630557476623],
              [-40.029240510784426, -8.168630669838485],
              [-40.029240506156349, -8.168630782266385],
              [-40.029240502932446, -8.168630894743002],
              [-40.029240501113236, -8.16863100725087],
              [-40.029240500698982, -8.168631119772561],
              [-40.029240500752728, -8.168631140759592],
              [-40.029242867729828, -8.169555038009486],
              [-40.029242867839045, -8.169555080643784],
              [-40.029242867366634, -8.16955515513486],
              [-40.029242866278501, -8.169555229619496],
              [-40.029242864574726, -8.169555304092714],
              [-40.029242862255423, -8.169555378549385],
              [-40.02924285932076, -8.169555452984547],
              [-40.029242855770917, -8.169555527393067],
              [-40.029242851606149, -8.169555601769934],
              [-40.029242846826733, -8.169555676110127],
              [-40.029242841432996, -8.169555750408552],
              [-40.029242835425315, -8.169555824660208],
              [-40.029242828804065, -8.169555898860043],
              [-40.029242821569738, -8.169555973003032],
              [-40.029242813722789, -8.169556047084102],
              [-40.029242805263756, -8.169556121098285],
              [-40.029242796193238, -8.169556195040544],
              [-40.029242786511823, -8.169556268905902],
              [-40.029242776220173, -8.16955634268926],
              [-40.029242765319012, -8.169556416385673],
              [-40.029242753809037, -8.169556489990111],
              [-40.029242741691057, -8.169556563497627],
              [-40.029242728965897, -8.169556636903204],
              [-40.029242715634389, -8.169556710201835],
              [-40.029242701697463, -8.169556783388622],
              [-40.029242687156078, -8.169556856458547],
              [-40.029242672011193, -8.169556929406632],
              [-40.02924265626384, -8.169557002228],
              [-40.02924263991509, -8.169557074917648],
              [-40.029242622966059, -8.169557147470684],
              [-40.029242605417885, -8.169557219882156],
              [-40.029242587271774, -8.169557292147163],
              [-40.02924256852895, -8.169557364260839],
              [-40.029242549190656, -8.169557436218234],
              [-40.029242529258255, -8.169557508014497],
              [-40.029242508733084, -8.169557579644767],
              [-40.029242487616493, -8.169557651104157],
              [-40.029242465909959, -8.169557722387864],
              [-40.029242443614955, -8.169557793490979],
              [-40.029242420732956, -8.169557864408752],
              [-40.029242397265563, -8.169557935136357],
              [-40.029242373214331, -8.169558005668971],
              [-40.029242348580887, -8.169558076001794],
              [-40.029242323366944, -8.169558146130138],
              [-40.029242297574179, -8.169558216049158],
              [-40.02924227120436, -8.169558285754155],
              [-40.029242244259251, -8.169558355240389],
              [-40.029242216740698, -8.169558424503125],
              [-40.029242188650556, -8.169558493537732],
              [-40.029242159990744, -8.169558562339448],
              [-40.029242130763215, -8.16955863090363],
              [-40.029242100969924, -8.169558699225696],
              [-40.029242070612888, -8.169558767300902],
              [-40.029242039694211, -8.1695588351247],
              [-40.029242008215938, -8.169558902692453],
              [-40.029241976180259, -8.169558969999628],
              [-40.029241943589284, -8.169559037041596],
              [-40.029241910445258, -8.169559103813874],
              [-40.029241876750454, -8.169559170311889],
              [-40.029241842507119, -8.16955923653113],
              [-40.029241807717575, -8.169559302467119],
              [-40.029241772384211, -8.169559368115381],
              [-40.029241736509405, -8.169559433471429],
              [-40.029241700095582, -8.169559498530928],
              [-40.029241663145235, -8.169559563289358],
              [-40.029241625660852, -8.169559627742384],
              [-40.029241587644997, -8.169559691885619],
              [-40.029241549100206, -8.16955975571469],
              [-40.029241510029138, -8.169559819225297],
              [-40.029241470434428, -8.169559882413127],
              [-40.02924143031877, -8.169559945273916],
              [-40.029241389684856, -8.169560007803334],
              [-40.029241348535471, -8.169560069997182],
              [-40.029241306873395, -8.169560131851268],
              [-40.029241264701461, -8.169560193361349],
              [-40.02924122202252, -8.169560254523299],
              [-40.02924117883947, -8.169560315332921],
              [-40.029241135155239, -8.169560375786094],
              [-40.029241090972803, -8.169560435878752],
              [-40.029241046295148, -8.169560495606813],
              [-40.029241001125307, -8.169560554966186],
              [-40.029240955466335, -8.169560613952894],
              [-40.029240909321345, -8.169560672562886],
              [-40.029240862693463, -8.169560730792242],
              [-40.029240815585837, -8.169560788636964],
              [-40.029240768001671, -8.16956084609315],
              [-40.029240719944198, -8.169560903156901],
              [-40.029240671416694, -8.169560959824354],
              [-40.029240622422414, -8.169561016091642],
              [-40.02924057296471, -8.169561071954963],
              [-40.029240523046916, -8.169561127410537],
              [-40.02924047267242, -8.169561182454599],
              [-40.029240421844662, -8.169561237083403],
              [-40.029240370567067, -8.169561291293228],
              [-40.029240318843136, -8.169561345080449],
              [-40.029240266676354, -8.169561398441399],
              [-40.029240214070278, -8.169561451372413],
              [-40.02924016102844, -8.169561503869947],
              [-40.0292401075545, -8.169561555930434],
              [-40.029240053652018, -8.16956160755033],
              [-40.029239999324773, -8.16956165872605],
              [-40.029239980168775, -8.169561690186452],
              [-40.028373756444452, -8.170370898391079],
              [-40.028373755023487, -8.170370922141863],
              [-40.028373633014361, -8.170371034055782],
              [-40.028373508973544, -8.170371143730078],
              [-40.028373382942462, -8.170371251128065],
              [-40.028373254963228, -8.170371356213934],
              [-40.028373125078474, -8.17037145895257],
              [-40.028372993331644, -8.170371559309737],
              [-40.028372859766648, -8.170371657251881],
              [-40.028372724428102, -8.170371752746327],
              [-40.028372587361197, -8.170371845761206],
              [-40.028372448611663, -8.170371936265438],
              [-40.028372308225805, -8.170372024228852],
              [-40.028372166250506, -8.170372109622104],
              [-40.028372022733151, -8.170372192416577],
              [-40.028371877721639, -8.170372272584791],
              [-40.028371731264393, -8.170372350099875],
              [-40.028371583410276, -8.170372424936007],
              [-40.02837143420868, -8.170372497068172],
              [-40.028371283709369, -8.170372566472327],
              [-40.028371131962601, -8.170372633125247],
              [-40.028370979019044, -8.170372697004739],
              [-40.028370824929716, -8.170372758089474],
              [-40.028370669746067, -8.17037281635905],
              [-40.028370513519903, -8.170372871793983],
              [-40.02837035630337, -8.170372924375801],
              [-40.028370198148956, -8.170372974086975],
              [-40.028370039109433, -8.170373020910848],
              [-40.0283698792379, -8.17037306483185],
              [-40.02836971858774, -8.170373105835337],
              [-40.028369557212542, -8.170373143907556],
              [-40.028369395166209, -8.170373179035828],
              [-40.028369232502804, -8.170373211208421],
              [-40.02836906927665, -8.170373240414587],
              [-40.028368905542216, -8.170373266644615],
              [-40.028368741354164, -8.170373289889726],
              [-40.028368576767292, -8.170373310142152],
              [-40.028368411836539, -8.170373327395124],
              [-40.028368246616978, -8.170373341642939],
              [-40.028368081163748, -8.170373352880766],
              [-40.02836791553208, -8.170373361104911],
              [-40.02836774977726, -8.170373366312573],
              [-40.028367583954619, -8.170373368502077],
              [-40.028367418119494, -8.170373367672646],
              [-40.028367252327264, -8.170373363824586],
              [-40.028367086633274, -8.170373356959178],
              [-40.028366921092797, -8.170373347078689],
              [-40.028366755761134, -8.170373334186431],
              [-40.028366590693444, -8.170373318286698],
              [-40.028366425944839, -8.170373299384819],
              [-40.028366261570319, -8.17037327748711],
              [-40.028366097624748, -8.170373252600859],
              [-40.028365934162849, -8.170373224734366],
              [-40.028365771239173, -8.170373193896944],
              [-40.028365608908132, -8.170373160098865],
              [-40.028365447223891, -8.170373123351483],
              [-40.028365286240415, -8.170373083666993],
              [-40.028365126011465, -8.170373041058678],
              [-40.028364966590523, -8.170372995540705],
              [-40.028364808030801, -8.170372947128321],
              [-40.028364650385193, -8.170372895837687],
              [-40.028364493706363, -8.170372841685911],
              [-40.028364338046615, -8.170372784691059],
              [-40.028364183457875, -8.170372724872184],
              [-40.028364029991771, -8.170372662249251],
              [-40.028363877699512, -8.170372596843141],
              [-40.028363726631959, -8.170372528675669],
              [-40.02836357683951, -8.170372457769654],
              [-40.028363428372181, -8.170372384148692],
              [-40.028363281279532, -8.170372307837429],
              [-40.028363135610668, -8.170372228861284],
              [-40.028362991414184, -8.170372147246617],
              [-40.028362848738254, -8.170372063020727],
              [-40.028362707630478, -8.170371976211639],
              [-40.028362568137972, -8.170371886848418],
              [-40.028362430307276, -8.170371794960863],
              [-40.02836229418444, -8.170371700579611],
              [-40.028362159814861, -8.170371603736239],
              [-40.028362027243418, -8.170371504463025],
              [-40.028361896514348, -8.170371402793098],
              [-40.028361767671292, -8.170371298760406],
              [-40.028361640757261, -8.17037119239971],
              [-40.028361515814616, -8.170371083746481],
              [-40.028361392885074, -8.170370972836984],
              [-40.028361272009654, -8.17037085970823],
              [-40.028361153228715, -8.170370744398017],
              [-40.02836103658192, -8.170370626944848],
              [-40.028360922108185, -8.170370507387855],
              [-40.028360809845708, -8.17037038576702],
              [-40.028360699831993, -8.170370262122939],
              [-40.028360592103752, -8.170370136496825],
              [-40.028360486696947, -8.17037000893068],
              [-40.028360383646749, -8.170369879467023],
              [-40.028360282987585, -8.170369748149108],
              [-40.028360184753026, -8.170369615020766],
              [-40.02836008897588, -8.170369480126404],
              [-40.028359995688113, -8.170369343511108],
              [-40.028359904920869, -8.170369205220394],
              [-40.02835981670443, -8.170369065300539],
              [-40.028359731068264, -8.170368923798172],
              [-40.028359648040713, -8.170368780760795],
              [-40.028359681768464, -8.17036876357875],
              [-40.027836219550281, -8.169447684954504],
              [-40.027836197126355, -8.16944767424599],
              [-40.027836175788309, -8.169447636904927],
              [-40.027836154272457, -8.169447599665292],
              [-40.027836132579274, -8.169447562527916],
              [-40.027836110709245, -8.169447525493672],
              [-40.027836088662887, -8.169447488563376],
              [-40.027836066440663, -8.169447451737868],
              [-40.027836044043099, -8.169447415017975],
              [-40.027836021470698, -8.169447378404504],
              [-40.027835998723972, -8.169447341898314],
              [-40.027835975803427, -8.169447305500228],
              [-40.027835952709566, -8.16944726921105],
              [-40.027835929442936, -8.169447233031608],
              [-40.027835906004057, -8.169447196962709],
              [-40.027835882393454, -8.169447161005209],
              [-40.027835858611667, -8.169447125159866],
              [-40.0278358346592, -8.169447089427507],
              [-40.027835810536651, -8.169447053808932],
              [-40.027835786244516, -8.169447018304972],
              [-40.027835761783358, -8.1694469829164],
              [-40.027835737153737, -8.169446947644015],
              [-40.0278357123562, -8.169446912488635],
              [-40.027835687391324, -8.169446877451042],
              [-40.027835662259648, -8.169446842532034],
              [-40.02783563696174, -8.169446807732392],
              [-40.02783561149819, -8.169446773052881],
              [-40.027835585869568, -8.169446738494322],
              [-40.027835560076468, -8.169446704057473],
              [-40.027835534119411, -8.169446669743115],
              [-40.027835507999036, -8.169446635552001],
              [-40.027835481715933, -8.169446601484967],
              [-40.02783545527069, -8.169446567542733],
              [-40.027835428663877, -8.169446533726077],
              [-40.027835401896127, -8.169446500035717],
              [-40.027835374968028, -8.169446466472493],
              [-40.027835347880199, -8.169446433037102],
              [-40.02783532063323, -8.169446399730345],
              [-40.027835293227767, -8.169446366552929],
              [-40.027835265664407, -8.169446333505627],
              [-40.027835237943783, -8.169446300589179],
              [-40.027835210066499, -8.169446267804341],
              [-40.027835182033215, -8.169446235151854],
              [-40.027835153844542, -8.169446202632425],
              [-40.027835125501127, -8.169446170246808],
              [-40.02783509700361, -8.169446137995706],
              [-40.027835068352616, -8.169446105879903],
              [-40.027835039548819, -8.169446073900104],
              [-40.02783501059286, -8.169446042057032],
              [-40.027834981485398, -8.169446010351372],
              [-40.027834952227053, -8.169445978783873],
              [-40.027834922818521, -8.169445947355229],
              [-40.027834893260476, -8.169445916066163],
              [-40.027834863553551, -8.169445884917382],
              [-40.027834833698442, -8.169445853909588],
              [-40.027834803695811, -8.169445823043457],
              [-40.027834773546346, -8.169445792319696],
              [-40.027834743250715, -8.169445761739054],
              [-40.027834712809607, -8.169445731302142],
              [-40.027834682223691, -8.169445701009678],
              [-40.027834651493713, -8.169445670862389],
              [-40.027834620620304, -8.169445640860879],
              [-40.02783458960419, -8.169445611005852],
              [-40.027834558446074, -8.169445581298032],
              [-40.027834527146645, -8.16944555173799],
              [-40.027834495706621, -8.169445522326498],
              [-40.027834464126698, -8.169445493064142],
              [-40.027834432407609, -8.169445463951652],
              [-40.02783440055007, -8.169445434989644],
              [-40.027834368554771, -8.169445406178751],
              [-40.027834336422458, -8.169445377519635],
              [-40.027834304153863, -8.169445349012985],
              [-40.027834271749683, -8.169445320659404],
              [-40.027834239210684, -8.169445292459548],
              [-40.027834206537584, -8.169445264414039],
              [-40.027834173731115, -8.169445236523531],
              [-40.027834140792038, -8.169445208788643],
              [-40.027834107721084, -8.169445181209985],
              [-40.027834074518971, -8.169445153788207],
              [-40.027834041186509, -8.169445126523899],
              [-40.027834007724401, -8.1694450994177],
              [-40.027833974133415, -8.169445072470211],
              [-40.027833940414332, -8.169445045682057],
              [-40.027833906567871, -8.169445019053825],
              [-40.027833872594847, -8.169444992586154],
              [-40.027833838495972, -8.16944496627956],
              [-40.02783380427207, -8.169444940134717],
              [-40.027833769923888, -8.169444914152169],
              [-40.02783373545217, -8.169444888332531],
              [-40.027833700857741, -8.16944486267635],
              [-40.027833666141362, -8.169444837184244],
              [-40.027833631303828, -8.169444811856778],
              [-40.027833596345914, -8.169444786694486],
              [-40.027833561268402, -8.169444761697989],
              [-40.027833526072108, -8.169444736867849],
              [-40.027833490757807, -8.169444712204561],
              [-40.027833455326295, -8.169444687708749],
              [-40.027833419778396, -8.16944466338097],
              [-40.027833384114878, -8.169444639221725],
              [-40.027833348336571, -8.169444615231582],
              [-40.027833312444272, -8.169444591411098],
              [-40.027833307096664, -8.169444617355015],
              [-40.02731113952877, -8.169099825230322],
              [-40.02731117606821, -8.169099847771003],
              [-40.027311091178873, -8.169099792400017],
              [-40.02731100567366, -8.169099737977939],
              [-40.02731091956327, -8.169099684511586],
              [-40.027310832858426, -8.169099632007647],
              [-40.02731074557002, -8.169099580472649],
              [-40.027310657708917, -8.169099529913034],
              [-40.027310569286108, -8.169099480335143],
              [-40.027310480312643, -8.169099431745162],
              [-40.027310390799634, -8.169099384149145],
              [-40.02731030075828, -8.169099337553059],
              [-40.027310210199801, -8.169099291962709],
              [-40.027310119135535, -8.169099247383825],
              [-40.02731002757686, -8.169099203821919],
              [-40.027309935535222, -8.169099161282489],
              [-40.027309843022095, -8.169099119770825],
              [-40.027309750049056, -8.169099079292145],
              [-40.027309656627729, -8.169099039851465],
              [-40.027309562769787, -8.169099001453695],
              [-40.027309468486933, -8.169098964103709],
              [-40.027309373790963, -8.169098927806104],
              [-40.027309278693721, -8.169098892565454],
              [-40.027309183207066, -8.169098858386143],
              [-40.027309087342957, -8.169098825272451],
              [-40.027308991113337, -8.169098793228523],
              [-40.027308894530265, -8.16909876225836],
              [-40.027308797605777, -8.16909873236582],
              [-40.027308700352009, -8.169098703554646],
              [-40.02730860278109, -8.169098675828426],
              [-40.027308504905236, -8.169098649190635],
              [-40.027308406736672, -8.169098623644599],
              [-40.027308308287644, -8.169098599193518],
              [-40.027308209570464, -8.169098575840424],
              [-40.027308110597481, -8.16909855358827],
              [-40.027308011381045, -8.169098532439802],
              [-40.027307911933548, -8.169098512397705],
              [-40.027307812267424, -8.169098493464455],
              [-40.027307712395128, -8.169098475642395],
              [-40.027307612329139, -8.169098458933799],
              [-40.027307512081961, -8.169098443340713],
              [-40.027307411666108, -8.169098428865105],
              [-40.027307311094141, -8.169098415508785],
              [-40.027307210378623, -8.169098403273432],
              [-40.027307109532138, -8.169098392160555],
              [-40.027307008567284, -8.169098382171562],
              [-40.027306907496673, -8.169098373307675],
              [-40.027306806332952, -8.169098365569999],
              [-40.027306705088741, -8.169098358959541],
              [-40.027306603776694, -8.169098353477088],
              [-40.027306502409466, -8.169098349123329],
              [-40.027306400999727, -8.169098345898828],
              [-40.027306299560159, -8.169098343804002],
              [-40.027306198103425, -8.169098342839076],
              [-40.027306096642214, -8.169098343004185],
              [-40.027305995189174, -8.169098344299313],
              [-40.027305893757017, -8.169098346724274],
              [-40.027305792358383, -8.169098350278787],
              [-40.027305691005992, -8.169098354962417],
              [-40.027305589712441, -8.169098360774568],
              [-40.027305488490455, -8.169098367714492],
              [-40.027305387352619, -8.169098375781376],
              [-40.027305286311616, -8.169098384974149],
              [-40.027305185380037, -8.169098395291712],
              [-40.027305084570514, -8.169098406732738],
              [-40.027304983895661, -8.169098419295814],
              [-40.027304883367997, -8.169098432979395],
              [-40.027304783000154, -8.169098447781744],
              [-40.027304682804612, -8.169098463700992],
              [-40.027304582793938, -8.169098480735169],
              [-40.027304482980604, -8.169098498882178],
              [-40.027304383377086, -8.169098518139688],
              [-40.027304283995818, -8.169098538505366],
              [-40.027304184849243, -8.169098559976604],
              [-40.027304085949716, -8.169098582550749],
              [-40.027303987309622, -8.169098606224971],
              [-40.02730388894129, -8.169098630996347],
              [-40.027303790856983, -8.169098656861749],
              [-40.027303693068959, -8.169098683817928],
              [-40.027303595589466, -8.169098711861535],
              [-40.027303498430669, -8.169098740989075],
              [-40.027303401604691, -8.16909877119692],
              [-40.027303305123652, -8.169098802481219],
              [-40.027303208999591, -8.169098834838179],
              [-40.02730311324455, -8.169098868263685],
              [-40.027303017870459, -8.169098902753573],
              [-40.027302922889241, -8.169098938303538],
              [-40.02730282831277, -8.169098974909121],
              [-40.027302734152869, -8.169099012565786],
              [-40.027302640421269, -8.169099051268761],
              [-40.027302547129736, -8.169099091013278],
              [-40.027302454289909, -8.169099131794368],
              [-40.027302361913357, -8.169099173606893],
              [-40.027302270011653, -8.169099216445639],
              [-40.027302178596265, -8.169099260305281],
              [-40.027302087678628, -8.1690993051803],
              [-40.027301997270087, -8.169099351065125],
              [-40.027301907381961, -8.169099397954023],
              [-40.027301818025457, -8.169099445841107],
              [-40.027301729211743, -8.169099494720401],
              [-40.02730164095194, -8.16909954458581],
              [-40.027301608168443, -8.169099578104236],
              [-40.025667796227914, -8.170034723838624],
              [-40.025667764718051, -8.170034716365469],
              [-40.025667745295067, -8.170034727450698],
              [-40.02566772584472, -8.170034738488189],
              [-40.025667706367109, -8.170034749477839],
              [-40.025667686862356, -8.170034760419615],
              [-40.02566766733058, -8.170034771313414],
              [-40.025667647771904, -8.170034782159204],
              [-40.025667628186433, -8.170034792956878],
              [-40.025667608574324, -8.170034803706397],
              [-40.025667588935669, -8.170034814407714],
              [-40.02566756927056, -8.17003482506072],
              [-40.025667549579168, -8.17003483566539],
              [-40.025667529861579, -8.170034846221634],
              [-40.025667510117927, -8.170034856729405],
              [-40.02566749034834, -8.170034867188647],
              [-40.025667470552918, -8.170034877599258],
              [-40.025667450731781, -8.170034887961211],
              [-40.025667430885065, -8.170034898274441],
              [-40.025667411012883, -8.170034908538845],
              [-40.02566739111537, -8.170034918754396],
              [-40.02566737119264, -8.170034928921037],
              [-40.025667351244792, -8.170034939038688],
              [-40.025667331271961, -8.170034949107297],
              [-40.025667311274276, -8.170034959126813],
              [-40.02566729125185, -8.170034969097149],
              [-40.025667271204824, -8.170034979018258],
              [-40.025667251133306, -8.170034988890086],
              [-40.025667231037396, -8.170034998712584],
              [-40.025667210917241, -8.17003500848565],
              [-40.025667190772964, -8.17003501820925],
              [-40.025667170604684, -8.170035027883333],
              [-40.025667150412531, -8.170035037507819],
              [-40.025667130196602, -8.170035047082665],
              [-40.025667109957048, -8.170035056607828],
              [-40.025667089693982, -8.170035066083216],
              [-40.025667069407525, -8.170035075508789],
              [-40.025667049097798, -8.170035084884468],
              [-40.025667028764929, -8.170035094210242],
              [-40.025667008409059, -8.170035103486027],
              [-40.025666988030267, -8.170035112711751],
              [-40.025666967628716, -8.170035121887382],
              [-40.025666947204527, -8.170035131012856],
              [-40.0256669267578, -8.170035140088116],
              [-40.025666906288663, -8.170035149113113],
              [-40.025666885797271, -8.170035158087783],
              [-40.025666865283732, -8.17003516701209],
              [-40.025666844748187, -8.170035175885962],
              [-40.025666824190715, -8.170035184709338],
              [-40.025666803611458, -8.17003519348218],
              [-40.025666783010557, -8.170035202204422],
              [-40.025666762388155, -8.170035210876026],
              [-40.025666741744338, -8.170035219496933],
              [-40.025666721079233, -8.170035228067084],
              [-40.025666700392996, -8.170035236586447],
              [-40.025666679685756, -8.170035245054938],
              [-40.02566665895759, -8.170035253472543],
              [-40.025666638208662, -8.17003526183915],
              [-40.025666617439086, -8.170035270154791],
              [-40.025666596648996, -8.17003527841937],
              [-40.025666575838521, -8.170035286632842],
              [-40.025666555007774, -8.170035294795152],
              [-40.025666534156905, -8.170035302906252],
              [-40.025666513286005, -8.170035310966085],
              [-40.025666492395224, -8.170035318974628],
              [-40.025666471484698, -8.170035326931824],
              [-40.025666450554532, -8.170035334837584],
              [-40.025666429604854, -8.170035342691918],
              [-40.025666408635814, -8.17003535049475],
              [-40.02566638764754, -8.170035358246031],
              [-40.025666366640117, -8.17003536594571],
              [-40.025666345613729, -8.170035373593759],
              [-40.025666324568455, -8.170035381190132],
              [-40.025666303504444, -8.170035388734739],
              [-40.025666282421831, -8.170035396227592],
              [-40.025666261320751, -8.170035403668622],
              [-40.025666240201303, -8.17003541105778],
              [-40.025666219063631, -8.170035418394997],
              [-40.025666197907874, -8.170035425680274],
              [-40.025666176734134, -8.17003543291356],
              [-40.025666155542567, -8.170035440094784],
              [-40.025666134333299, -8.170035447223919],
              [-40.025666113106439, -8.170035454300926],
              [-40.025666091862142, -8.170035461325735],
              [-40.025666070600522, -8.170035468298352],
              [-40.025666049321693, -8.170035475218688],
              [-40.025666028025803, -8.170035482086744],
              [-40.025666006712981, -8.170035488902414],
              [-40.025665985383377, -8.170035495665726],
              [-40.025665964037081, -8.170035502376587],
              [-40.025665942674237, -8.170035509034976],
              [-40.025665921294994, -8.170035515640887],
              [-40.025665899899451, -8.17003552219421],
              [-40.025665878487764, -8.170035528694967],
              [-40.025665857060041, -8.170035535143064],
              [-40.025665835616451, -8.17003554153851],
              [-40.02566581415708, -8.170035547881273],
              [-40.025665792682069, -8.17003555417126],
              [-40.025665771191562, -8.170035560408493],
              [-40.025665749685686, -8.170035566592865],
              [-40.025665728164533, -8.170035572724755],
              [-40.025665710630506, -8.170035543095908],
              [-40.023383789510859, -8.170682639843271],
              [-40.023383791447628, -8.170682674574749],
              [-40.023383743216186, -8.170682688395585],
              [-40.023383695062194, -8.170682702481921],
              [-40.023383646987085, -8.170682716833316],
              [-40.023383598992361, -8.170682731449343],
              [-40.023383551079462, -8.170682746329557],
              [-40.023383503249889, -8.170682761473488],
              [-40.023383455505062, -8.170682776880657],
              [-40.02338340784646, -8.170682792550672],
              [-40.023383360275552, -8.170682808482956],
              [-40.023383312793776, -8.170682824677092],
              [-40.023383265402586, -8.17068284113253],
              [-40.02338321810344, -8.17068285784883],
              [-40.023383170897773, -8.170682874825435],
              [-40.023383123787028, -8.170682892061826],
              [-40.023383076772646, -8.170682909557474],
              [-40.02338302985607, -8.170682927311882],
              [-40.023382983038744, -8.170682945324469],
              [-40.023382936322065, -8.170682963594714],
              [-40.023382889707499, -8.170682982122035],
              [-40.023382843196444, -8.170683000905882],
              [-40.023382796790337, -8.170683019945672],
              [-40.023382750490597, -8.17068303924081],
              [-40.023382704298633, -8.170683058790724],
              [-40.023382658215851, -8.17068307859482],
              [-40.023382612243672, -8.170683098652468],
              [-40.023382566383511, -8.17068311896308],
              [-40.023382520636723, -8.170683139526027],
              [-40.023382475004766, -8.170683160340678],
              [-40.02338242948899, -8.170683181406398],
              [-40.023382384090816, -8.17068320272255],
              [-40.023382338811629, -8.170683224288453],
              [-40.023382293652794, -8.170683246103481],
              [-40.023382248615704, -8.170683268166968],
              [-40.023382203701722, -8.170683290478214],
              [-40.023382158912256, -8.17068331303655],
              [-40.023382114248626, -8.170683335841288],
              [-40.023382069712255, -8.170683358891759],
              [-40.02338202530445, -8.170683382187212],
              [-40.023381981026589, -8.170683405726958],
              [-40.02338193688005, -8.170683429510248],
              [-40.023381892866141, -8.170683453536375],
              [-40.023381848986226, -8.17068347780463],
              [-40.023381805241662, -8.170683502314226],
              [-40.023381761633765, -8.170683527064455],
              [-40.023381718163876, -8.170683552054546],
              [-40.023381674833324, -8.170683577283715],
              [-40.023381631643439, -8.170683602751216],
              [-40.023381588595527, -8.170683628456256],
              [-40.02338154569091, -8.170683654398056],
              [-40.023381502930896, -8.170683680575825],
              [-40.023381460316799, -8.170683706988765],
              [-40.023381417849926, -8.170683733636059],
              [-40.023381375531571, -8.170683760516885],
              [-40.023381333363027, -8.170683787630464],
              [-40.023381291345572, -8.170683814975913],
              [-40.023381249480522, -8.170683842552409],
              [-40.02338120776912, -8.170683870359136],
              [-40.023381166212658, -8.170683898395232],
              [-40.02338112481241, -8.170683926659835],
              [-40.023381083569632, -8.170683955152066],
              [-40.023381042485582, -8.170683983871063],
              [-40.023381001561539, -8.170684012815963],
              [-40.023380960798725, -8.170684041985888],
              [-40.023380920198413, -8.170684071379906],
              [-40.023380879761817, -8.170684100997155],
              [-40.023380839490201, -8.17068413083671],
              [-40.023380799384775, -8.170684160897654],
              [-40.023380759446773, -8.170684191179111],
              [-40.023380719677405, -8.170684221680112],
              [-40.023380680077913, -8.170684252399747],
              [-40.023380640649478, -8.170684283337053],
              [-40.023380601393335, -8.170684314491087],
              [-40.023380562310656, -8.170684345860915],
              [-40.023380523402643, -8.170684377445593],
              [-40.023380484670504, -8.170684409244103],
              [-40.023380446115389, -8.170684441255506],
              [-40.0233804077385, -8.170684473478833],
              [-40.023380369541023, -8.170684505913091],
              [-40.023380331524109, -8.170684538557261],
              [-40.023380293688909, -8.170684571410401],
              [-40.023380256036596, -8.170684604471443],
              [-40.02338021856832, -8.170684637739431],
              [-40.023380181285212, -8.17068467121331],
              [-40.023380144188444, -8.170684704892071],
              [-40.023380107279102, -8.17068473877468],
              [-40.023380070558353, -8.170684772860099],
              [-40.023380034027312, -8.170684807147312],
              [-40.023379997687087, -8.170684841635241],
              [-40.023379961538794, -8.170684876322845],
              [-40.023379925583534, -8.170684911209065],
              [-40.023379889822401, -8.17068494629283],
              [-40.023379854256511, -8.17068498157307],
              [-40.02337981888693, -8.170685017048712],
              [-40.023379783714745, -8.170685052718641],
              [-40.023379748741036, -8.1706850885818],
              [-40.023379713966861, -8.170685124637084],
              [-40.023379679393308, -8.170685160883369],
              [-40.023379645021407, -8.170685197319582],
              [-40.02337961085221, -8.170685233944583],
              [-40.023379620832095, -8.170685273183137],
              [-40.021131615628626, -8.17310817709869],
              [-40.01953494442008, -8.17327590374255],
              [-40.019534900100986, -8.173275877904487],
              [-40.019534877285437, -8.173275880329799],
              [-40.019534854476113, -8.173275882812556],
              [-40.019534831673163, -8.173275885352764],
              [-40.01953480887672, -8.173275887950396],
              [-40.019534786086943, -8.173275890605439],
              [-40.019534763303987, -8.173275893317843],
              [-40.019534740527973, -8.173275896087654],
              [-40.019534717759065, -8.173275898914826],
              [-40.01953469499739, -8.173275901799325],
              [-40.019534672243125, -8.173275904741129],
              [-40.019534649496393, -8.173275907740228],
              [-40.019534626757327, -8.173275910796651],
              [-40.019534604026084, -8.173275913910279],
              [-40.019534581302828, -8.173275917081195],
              [-40.019534558587694, -8.173275920309317],
              [-40.019534535880808, -8.173275923594661],
              [-40.019534513182329, -8.17327592693716],
              [-40.019534490492418, -8.173275930336827],
              [-40.019534467811205, -8.173275933793633],
              [-40.019534445138817, -8.173275937307555],
              [-40.019534422475417, -8.173275940878549],
              [-40.019534399821161, -8.173275944506642],
              [-40.019534377176178, -8.173275948191751],
              [-40.019534354540625, -8.173275951933894],
              [-40.01953433191462, -8.173275955733054],
              [-40.019534309298344, -8.173275959589196],
              [-40.019534286691915, -8.17327596350227],
              [-40.019534264095483, -8.173275967472261],
              [-40.019534241509199, -8.173275971499175],
              [-40.01953421893321, -8.173275975582941],
              [-40.019534196367637, -8.1732759797236],
              [-40.019534173812652, -8.173275983921021],
              [-40.019534151268388, -8.173275988175289],
              [-40.019534128734989, -8.173275992486266],
              [-40.019534106212589, -8.173275996854018],
              [-40.019534083701345, -8.173276001278495],
              [-40.019534061201412, -8.173276005759631],
              [-40.019534038712912, -8.173276010297442],
              [-40.019534016235994, -8.173276014891877],
              [-40.019533993770807, -8.173276019542886],
              [-40.019533971317493, -8.173276024250498],
              [-40.01953394887618, -8.173276029014636],
              [-40.019533926447039, -8.173276033835272],
              [-40.019533904030197, -8.173276038712386],
              [-40.019533881625797, -8.173276043645963],
              [-40.019533859233988, -8.173276048635946],
              [-40.019533836854919, -8.173276053682279],
              [-40.019533814488696, -8.173276058784998],
              [-40.019533792135526, -8.173276063944012],
              [-40.019533769795494, -8.173276069159314],
              [-40.01953374746877, -8.173276074430863],
              [-40.01953372515549, -8.173276079758628],
              [-40.019533702855796, -8.173276085142573],
              [-40.019533680569836, -8.173276090582647],
              [-40.019533658297746, -8.173276096078885],
              [-40.019533636039675, -8.173276101631149],
              [-40.019533613795751, -8.173276107239493],
              [-40.019533591566145, -8.173276112903862],
              [-40.019533569350962, -8.173276118624193],
              [-40.019533547150374, -8.173276124400466],
              [-40.019533524964508, -8.173276130232614],
              [-40.019533502793507, -8.173276136120602],
              [-40.019533480637527, -8.173276142064429],
              [-40.019533458496689, -8.173276148064064],
              [-40.019533436371127, -8.173276154119439],
              [-40.019533414261019, -8.173276160230493],
              [-40.01953339216648, -8.173276166397242],
              [-40.019533370087657, -8.173276172619625],
              [-40.019533348024687, -8.173276178897577],
              [-40.019533325977719, -8.17327618523108],
              [-40.019533303946886, -8.173276191620083],
              [-40.019533281932347, -8.173276198064585],
              [-40.019533259934207, -8.173276204564504],
              [-40.019533237952622, -8.173276211119802],
              [-40.019533215987757, -8.173276217730452],
              [-40.019533194039731, -8.17327622439641],
              [-40.01953317210868, -8.173276231117601],
              [-40.019533150194761, -8.173276237894047],
              [-40.019533128298107, -8.173276244725612],
              [-40.019533106418848, -8.173276251612352],
              [-40.019533084557125, -8.173276258554147],
              [-40.019533062713087, -8.173276265551022],
              [-40.019533040886863, -8.173276272602852],
              [-40.019533019078601, -8.173276279709636],
              [-40.019532997288458, -8.17327628687131],
              [-40.01953297551654, -8.173276294087877],
              [-40.01953295376299, -8.173276301359245],
              [-40.019532932027971, -8.173276308685367],
              [-40.019532910311611, -8.173276316066241],
              [-40.01953288861403, -8.173276323501765],
              [-40.019532866935393, -8.173276330991925],
              [-40.019532845275819, -8.17327633853667],
              [-40.019532823635473, -8.173276346135927],
              [-40.01953280201446, -8.173276353789648],
              [-40.019532780412938, -8.17327636149782],
              [-40.019532758831055, -8.173276369260405],
              [-40.019532737268925, -8.173276377077284],
              [-40.019532715726683, -8.173276384948455],
              [-40.019532694204486, -8.173276392873843],
              [-40.019532676332823, -8.173276361666378],
              [-40.018212121318378, -8.17376454433438],
              [-40.018212147210392, -8.173764563602118],
              [-40.018212128451161, -8.17376457056033],
              [-40.01821210970737, -8.173764577559727],
              [-40.018212090979119, -8.173764584600306],
              [-40.0182120722665, -8.173764591681998],
              [-40.018212053569592, -8.173764598804809],
              [-40.018212034888514, -8.17376460596868],
              [-40.018212016223309, -8.173764613173566],
              [-40.018211997574113, -8.173764620419467],
              [-40.018211978940997, -8.173764627706312],
              [-40.018211960324052, -8.173764635034084],
              [-40.018211941723372, -8.173764642402737],
              [-40.018211923139042, -8.173764649812249],
              [-40.01821190457116, -8.173764657262591],
              [-40.018211886019813, -8.173764664753689],
              [-40.018211867485093, -8.173764672285547],
              [-40.018211848967084, -8.173764679858115],
              [-40.018211830465873, -8.173764687471319],
              [-40.018211811981573, -8.173764695125184],
              [-40.018211793514247, -8.173764702819636],
              [-40.018211775063996, -8.173764710554664],
              [-40.018211756630912, -8.173764718330192],
              [-40.018211738215086, -8.173764726146233],
              [-40.018211719816598, -8.173764734002704],
              [-40.01821170143554, -8.173764741899578],
              [-40.01821168307201, -8.17376474983681],
              [-40.018211664726088, -8.173764757814373],
              [-40.018211646397866, -8.173764765832257],
              [-40.01821162808745, -8.173764773890388],
              [-40.018211609794896, -8.173764781988723],
              [-40.01821159152032, -8.173764790127221],
              [-40.018211573263798, -8.173764798305836],
              [-40.018211555025417, -8.173764806524566],
              [-40.018211536805282, -8.173764814783361],
              [-40.018211518603451, -8.173764823082166],
              [-40.018211500420044, -8.173764831420934],
              [-40.01821148225514, -8.173764839799615],
              [-40.018211464108823, -8.173764848218211],
              [-40.018211445981173, -8.173764856676666],
              [-40.018211427872288, -8.173764865174931],
              [-40.018211409782268, -8.173764873712956],
              [-40.018211391711183, -8.173764882290705],
              [-40.018211373659128, -8.173764890908149],
              [-40.018211355626185, -8.173764899565246],
              [-40.018211337612456, -8.173764908261935],
              [-40.018211319618018, -8.173764916998183],
              [-40.018211301642943, -8.173764925773936],
              [-40.01821128368735, -8.173764934589174],
              [-40.018211265751304, -8.173764943443853],
              [-40.018211247834891, -8.173764952337901],
              [-40.018211229938224, -8.173764961271319],
              [-40.018211212061367, -8.173764970244022],
              [-40.018211194204412, -8.173764979255973],
              [-40.018211176367444, -8.173764988307148],
              [-40.018211158550557, -8.173764997397502],
              [-40.018211140753834, -8.173765006526974],
              [-40.018211122977355, -8.173765015695526],
              [-40.018211105221205, -8.17376502490313],
              [-40.018211087485483, -8.173765034149714],
              [-40.018211069770274, -8.173765043435241],
              [-40.018211052075657, -8.1737650527597],
              [-40.018211034401702, -8.173765062122987],
              [-40.018211016748538, -8.173765071525088],
              [-40.018210999116221, -8.173765080965962],
              [-40.018210981504836, -8.173765090445549],
              [-40.018210963914477, -8.173765099963795],
              [-40.018210946345235, -8.173765109520682],
              [-40.018210928797174, -8.17376511911614],
              [-40.018210911270401, -8.173765128750162],
              [-40.018210893765001, -8.173765138422651],
              [-40.018210876281032, -8.173765148133583],
              [-40.01821085881862, -8.173765157882901],
              [-40.018210841377808, -8.173765167670592],
              [-40.018210823958704, -8.17376517749657],
              [-40.018210806561406, -8.173765187360784],
              [-40.018210789185957, -8.173765197263194],
              [-40.018210771832479, -8.173765207203779],
              [-40.018210754501055, -8.173765217182462],
              [-40.018210737191744, -8.173765227199199],
              [-40.018210719904651, -8.173765237253946],
              [-40.018210702639855, -8.17376524734666],
              [-40.018210685397435, -8.173765257477269],
              [-40.018210668177495, -8.173765267645747],
              [-40.01821065098008, -8.17376527785205],
              [-40.01821063380531, -8.173765288096101],
              [-40.018210616653249, -8.173765298377861],
              [-40.018210599523982, -8.1737653086973],
              [-40.018210582417602, -8.173765319054336],
              [-40.018210565334186, -8.173765329448942],
              [-40.018210548273807, -8.173765339881061],
              [-40.018210531236576, -8.173765350350619],
              [-40.018210514222552, -8.173765360857601],
              [-40.018210497231813, -8.173765371401956],
              [-40.018210480264457, -8.173765381983598],
              [-40.018210463320564, -8.17376539260253],
              [-40.018210446400218, -8.173765403258633],
              [-40.018210429503497, -8.173765413951903],
              [-40.018210412630481, -8.173765424682262],
              [-40.018210395781267, -8.173765435449688],
              [-40.018210378955914, -8.173765446254082],
              [-40.018210398990171, -8.173765453083979],
              [-40.017238020880541, -8.1743913123443],
              [-40.017238061150401, -8.174391331961113],
              [-40.017238047551444, -8.174391340697706],
              [-40.017238033936898, -8.174391349410127],
              [-40.01723802030677, -8.174391358098344],
              [-40.017238006661138, -8.174391366762345],
              [-40.017237993, -8.174391375402092],
              [-40.017237979323468, -8.17439138401757],
              [-40.017237965631523, -8.174391392608745],
              [-40.017237951924223, -8.174391401175578],
              [-40.017237938201617, -8.174391409718044],
              [-40.017237924463757, -8.174391418236118],
              [-40.017237910710669, -8.174391426729791],
              [-40.017237896942419, -8.174391435199023],
              [-40.017237883159041, -8.174391443643783],
              [-40.017237869360571, -8.174391452064036],
              [-40.017237855547052, -8.174391460459784],
              [-40.01723784171854, -8.17439146883099],
              [-40.017237827875078, -8.174391477177622],
              [-40.017237814016696, -8.174391485499646],
              [-40.017237800143455, -8.174391493797042],
              [-40.017237786255386, -8.174391502069801],
              [-40.017237772352523, -8.174391510317863],
              [-40.017237758434959, -8.174391518541215],
              [-40.01723774450268, -8.174391526739811],
              [-40.017237730555763, -8.174391534913664],
              [-40.017237716594245, -8.17439154306275],
              [-40.017237702618175, -8.174391551187028],
              [-40.017237688627581, -8.174391559286462],
              [-40.017237674622528, -8.174391567361038],
              [-40.017237660603044, -8.174391575410725],
              [-40.017237646569193, -8.174391583435472],
              [-40.017237632520988, -8.174391591435306],
              [-40.017237618458495, -8.174391599410161],
              [-40.017237604381798, -8.17439160736005],
              [-40.017237590290854, -8.174391615284909],
              [-40.017237576185757, -8.174391623184723],
              [-40.017237562066562, -8.174391631059464],
              [-40.017237547933306, -8.174391638909146],
              [-40.017237533786002, -8.174391646733692],
              [-40.017237519624757, -8.174391654533114],
              [-40.017237505449557, -8.174391662307325],
              [-40.017237491260481, -8.174391670056391],
              [-40.017237477057556, -8.174391677780235],
              [-40.017237462840839, -8.174391685478836],
              [-40.017237448610373, -8.174391693152163],
              [-40.017237434366201, -8.174391700800225],
              [-40.017237420108366, -8.174391708422968],
              [-40.017237405836923, -8.174391716020358],
              [-40.017237391551902, -8.174391723592411],
              [-40.017237377253373, -8.174391731139076],
              [-40.017237362941323, -8.174391738660308],
              [-40.017237348615886, -8.174391746156136],
              [-40.017237334277048, -8.174391753626505],
              [-40.017237319924853, -8.174391761071389],
              [-40.01723730555937, -8.17439176849077],
              [-40.017237291180628, -8.17439177588461],
              [-40.017237276788705, -8.174391783252911],
              [-40.017237262383595, -8.174391790595621],
              [-40.01723724796539, -8.174391797912762],
              [-40.017237233534104, -8.174391805204277],
              [-40.017237219089814, -8.174391812470136],
              [-40.017237204632536, -8.174391819710355],
              [-40.017237190162326, -8.174391826924863],
              [-40.017237175679234, -8.174391834113647],
              [-40.017237161183296, -8.174391841276721],
              [-40.017237146674567, -8.17439184841402],
              [-40.017237132153106, -8.174391855525547],
              [-40.01723711761894, -8.174391862611282],
              [-40.017237103072119, -8.174391869671167],
              [-40.01723708851268, -8.174391876705213],
              [-40.017237073940677, -8.174391883713378],
              [-40.017237059356184, -8.174391890695668],
              [-40.017237044759199, -8.174391897652013],
              [-40.017237030149808, -8.174391904582459],
              [-40.017237015528032, -8.174391911486932],
              [-40.017237000893928, -8.174391918365409],
              [-40.017236986247532, -8.1743919252179],
              [-40.017236971588893, -8.174391932044349],
              [-40.017236956918097, -8.174391938844767],
              [-40.017236942235144, -8.174391945619105],
              [-40.01723692754009, -8.174391952367364],
              [-40.017236912832978, -8.174391959089505],
              [-40.017236898113865, -8.174391965785532],
              [-40.017236883382822, -8.174391972455396],
              [-40.017236868639834, -8.174391979099076],
              [-40.017236853885002, -8.174391985716555],
              [-40.017236839118354, -8.174391992307822],
              [-40.017236824339925, -8.174391998872851],
              [-40.017236809549779, -8.174392005411631],
              [-40.017236794747973, -8.174392011924127],
              [-40.017236779934528, -8.174392018410325],
              [-40.017236765109502, -8.174392024870185],
              [-40.017236750272943, -8.174392031303727],
              [-40.017236735424902, -8.174392037710888],
              [-40.017236720565414, -8.174392044091674],
              [-40.017236705694529, -8.174392050446064],
              [-40.017236690812304, -8.174392056774009],
              [-40.017236675918788, -8.174392063075542],
              [-40.017236661014017, -8.174392069350583],
              [-40.017236646098155, -8.174392075597806],
              [-40.017236661167757, -8.174392039295457],
              [-40.015567253228518, -8.175089649368751],
              [-40.015566890606337, -8.175089831162262],
              [-40.014531786931158, -8.175573939680856],
              [-40.014531773266306, -8.175573919026702],
              [-40.014531638608204, -8.175573980670658],
              [-40.014531502958008, -8.175574040116029],
              [-40.014531366351896, -8.175574097346969],
              [-40.014531228826279, -8.175574152348227],
              [-40.014531090417812, -8.175574205105159],
              [-40.014530951163358, -8.175574255603669],
              [-40.014530811100066, -8.175574303830325],
              [-40.014530670265266, -8.175574349772264],
              [-40.014530528696476, -8.175574393417229],
              [-40.014530386431446, -8.175574434753614],
              [-40.014530243508077, -8.175574473770363],
              [-40.014530099964489, -8.175574510457139],
              [-40.014529955838917, -8.175574544804086],
              [-40.014529811169801, -8.17557457680212],
              [-40.01452966599566, -8.175574606442678],
              [-40.014529520355211, -8.17557463371784],
              [-40.014529374287292, -8.175574658620373],
              [-40.014529227830806, -8.175574681143619],
              [-40.014529081024804, -8.175574701281592],
              [-40.014528933908409, -8.17557471902891],
              [-40.014528786520835, -8.175574734380852],
              [-40.014528638901361, -8.175574747333302],
              [-40.014528491089337, -8.175574757882838],
              [-40.014528343124155, -8.175574766026667],
              [-40.014528195045273, -8.175574771762552],
              [-40.014528046892138, -8.175574775088988],
              [-40.014527898704237, -8.175574776005126],
              [-40.014527750521069, -8.175574774510698],
              [-40.014527602382145, -8.175574770606108],
              [-40.014527454326945, -8.175574764292385],
              [-40.014527306394911, -8.175574755571219],
              [-40.014527158625498, -8.175574744444914],
              [-40.014527011058071, -8.175574730916459],
              [-40.014526863731973, -8.175574714989443],
              [-40.014526716686476, -8.17557469666813],
              [-40.014526569960758, -8.175574675957428],
              [-40.014526423593935, -8.175574652862794],
              [-40.014526277625016, -8.175574627390416],
              [-40.014526132092918, -8.175574599547101],
              [-40.014525987036421, -8.175574569340217],
              [-40.01452584249418, -8.175574536777855],
              [-40.01452569850472, -8.175574501868708],
              [-40.014525555106445, -8.175574464622047],
              [-40.014525412337555, -8.175574425047836],
              [-40.014525270236113, -8.175574383156574],
              [-40.014525128839971, -8.175574338959454],
              [-40.014524988186842, -8.175574292468271],
              [-40.014524848314203, -8.175574243695381],
              [-40.014524709259327, -8.175574192653833],
              [-40.014524571059326, -8.175574139357183],
              [-40.014524433750957, -8.175574083819647],
              [-40.014524297370869, -8.175574026056049],
              [-40.014524161955393, -8.17557396608176],
              [-40.014524027540638, -8.175573903912779],
              [-40.014523894162416, -8.175573839565695],
              [-40.014523761856289, -8.175573773057627],
              [-40.014523630657507, -8.175573704406288],
              [-40.014523500601044, -8.175573633630011],
              [-40.014523371721587, -8.175573560747649],
              [-40.01452324405345, -8.175573485778633],
              [-40.014523117630674, -8.175573408742951],
              [-40.01452299248696, -8.175573329661097],
              [-40.014522868655661, -8.175573248554201],
              [-40.014522746169796, -8.175573165443838],
              [-40.014522625061986, -8.175573080352171],
              [-40.014522505364532, -8.1755729933019],
              [-40.014522387109324, -8.175572904316223],
              [-40.014522270327888, -8.175572813418837],
              [-40.01452215505136, -8.17557272063398],
              [-40.014522041310443, -8.175572625986417],
              [-40.014521929135455, -8.175572529501311],
              [-40.014521818556311, -8.175572431204426],
              [-40.014521709602477, -8.175572331121934],
              [-40.014521602302999, -8.175572229280512],
              [-40.014521496686463, -8.175572125707316],
              [-40.014521392781027, -8.175572020429954],
              [-40.01452129061439, -8.175571913476466],
              [-40.01452119021377, -8.175571804875394],
              [-40.014521091605936, -8.175571694655678],
              [-40.014520994817168, -8.175571582846676],
              [-40.014520899873261, -8.1755714694782],
              [-40.014520806799531, -8.175571354580455],
              [-40.014520715620776, -8.175571238184055],
              [-40.014520626361303, -8.175571120320054],
              [-40.014520539044909, -8.175571001019842],
              [-40.014520453694843, -8.175570880315238],
              [-40.014520370333877, -8.175570758238404],
              [-40.014520288984222, -8.175570634821888],
              [-40.014520209667552, -8.175570510098567],
              [-40.014520132405039, -8.175570384101691],
              [-40.014520057217226, -8.175570256864834],
              [-40.014519984124199, -8.175570128421905],
              [-40.014519913145421, -8.175569998807159],
              [-40.014519844299798, -8.175569868055177],
              [-40.014519777605713, -8.175569736200726],
              [-40.014519713080908, -8.175569603279007],
              [-40.014519650742592, -8.175569469325429],
              [-40.014519590607385, -8.1755693343757],
              [-40.014519532691011, -8.175569198466114],
              [-40.014519520856091, -8.175569177060835],
              [-40.014034496238857, -8.174404603398854],
              [-40.014034474980797, -8.174404587680161],
              [-40.014034438036447, -8.174404500274726],
              [-40.014034400174225, -8.174404413260127],
              [-40.014034361398323, -8.174404326645908],
              [-40.014034321712984, -8.174404240441575],
              [-40.014034281122584, -8.174404154656614],
              [-40.014034239631563, -8.174404069300504],
              [-40.01403419724452, -8.174403984382566],
              [-40.014034153966072, -8.174403899912166],
              [-40.014034109801003, -8.174403815898613],
              [-40.014034064754185, -8.174403732351138],
              [-40.014034018830557, -8.174403649278903],
              [-40.014033972035143, -8.174403566691067],
              [-40.01403392437313, -8.174403484596699],
              [-40.014033875849726, -8.174403403004817],
              [-40.014033826470296, -8.174403321924435],
              [-40.014033776240261, -8.174403241364422],
              [-40.014033725165135, -8.174403161333649],
              [-40.014033673250545, -8.174403081840923],
              [-40.014033620502175, -8.174403002895],
              [-40.014033566925868, -8.174402924504529],
              [-40.014033512527476, -8.174402846678182],
              [-40.014033457312998, -8.17440276942447],
              [-40.014033401288508, -8.17440269275189],
              [-40.014033344460174, -8.174402616668894],
              [-40.014033286834227, -8.174402541183861],
              [-40.014033228417006, -8.174402466305038],
              [-40.014033169214933, -8.174402392040689],
              [-40.014033109234546, -8.174402318398998],
              [-40.01403304848241, -8.17440224538805],
              [-40.014032986965226, -8.174402173015883],
              [-40.014032924689729, -8.174402101290431],
              [-40.014032861662805, -8.174402030219591],
              [-40.014032797891353, -8.174401959811169],
              [-40.014032733382408, -8.174401890072941],
              [-40.014032668143059, -8.174401821012536],
              [-40.014032602180457, -8.174401752637579],
              [-40.014032535501897, -8.174401684955585],
              [-40.014032468114692, -8.174401617974004],
              [-40.014032400026231, -8.174401551700164],
              [-40.014032331244032, -8.174401486141374],
              [-40.014032261775633, -8.174401421304834],
              [-40.014032191628694, -8.174401357197695],
              [-40.014032120810924, -8.174401293827007],
              [-40.014032049330098, -8.174401231199713],
              [-40.014031977194101, -8.17440116932271],
              [-40.014031904410849, -8.174401108202821],
              [-40.014031830988337, -8.174401047846764],
              [-40.014031756934642, -8.17440098826116],
              [-40.014031682257936, -8.174400929452553],
              [-40.014031606966405, -8.174400871427423],
              [-40.014031531068312, -8.174400814192177],
              [-40.014031454572056, -8.17440075775307],
              [-40.014031377485985, -8.174400702116301],
              [-40.014031299818626, -8.174400647288028],
              [-40.014031221578485, -8.174400593274243],
              [-40.014031142774215, -8.174400540080907],
              [-40.014031063414407, -8.174400487713861],
              [-40.014030983507851, -8.174400436178857],
              [-40.014030903063315, -8.174400385481592],
              [-40.014030822089644, -8.174400335627615],
              [-40.014030740595729, -8.174400286622429],
              [-40.014030658590542, -8.174400238471387],
              [-40.014030576083123, -8.174400191179837],
              [-40.014030493082508, -8.174400144752928],
              [-40.014030409597851, -8.174400099195779],
              [-40.014030325638309, -8.174400054513422],
              [-40.014030241213135, -8.174400010710743],
              [-40.014030156331593, -8.174399967792583],
              [-40.014030071003042, -8.174399925763643],
              [-40.014029985236846, -8.174399884628553],
              [-40.014029899042441, -8.174399844391822],
              [-40.014029812429321, -8.174399805057902],
              [-40.014029725406978, -8.174399766631106],
              [-40.014029637985011, -8.174399729115649],
              [-40.014029550173021, -8.174399692515671],
              [-40.014029461980648, -8.174399656835156],
              [-40.014029373417628, -8.17439962207809],
              [-40.014029284493667, -8.174399588248251],
              [-40.014029195218576, -8.174399555349376],
              [-40.014029105602141, -8.174399523385098],
              [-40.014029015654216, -8.17439949235891],
              [-40.014028925384707, -8.174399462274224],
              [-40.014028834803547, -8.174399433134347],
              [-40.01402874392069, -8.174399404942511],
              [-40.014028652746106, -8.174399377701773],
              [-40.014028561289855, -8.174399351415152],
              [-40.014028469561985, -8.174399326085544],
              [-40.014028377572565, -8.174399301715734],
              [-40.01402828533174, -8.174399278308371],
              [-40.014028192849644, -8.174399255866067],
              [-40.014028100136429, -8.174399234391281],
              [-40.014028007202285, -8.174399213886383],
              [-40.014027914057458, -8.174399194353589],
              [-40.014027820712208, -8.17439917579509],
              [-40.014027727176746, -8.174399158212907],
              [-40.014027633461396, -8.174399141608967],
              [-40.014027539576446, -8.174399125985094],
              [-40.014027445532214, -8.174399111343032],
              [-40.014027351339081, -8.174399097684374],
              [-40.014027311488192, -8.174399104485714],
              [-40.013546058526984, -8.174331846730439],
              [-40.013546088748349, -8.174331876628742],
              [-40.013546059248633, -8.174331872555726],
              [-40.013546029735643, -8.174331868579234],
              [-40.013546000209708, -8.174331864699319],
              [-40.01354597067116, -8.174331860915995],
              [-40.013545941120292, -8.174331857229351],
              [-40.013545911557443, -8.174331853639401],
              [-40.013545881982928, -8.174331850146187],
              [-40.013545852397044, -8.174331846749737],
              [-40.01354582280014, -8.174331843450107],
              [-40.01354579319252, -8.174331840247319],
              [-40.013545763574506, -8.174331837141406],
              [-40.013545733946415, -8.174331834132396],
              [-40.013545704308562, -8.174331831220327],
              [-40.01354567466128, -8.174331828405249],
              [-40.013545645004882, -8.174331825687174],
              [-40.01354561533968, -8.174331823066124],
              [-40.013545585666002, -8.174331820542138],
              [-40.013545555984194, -8.174331818115244],
              [-40.013545526294514, -8.174331815785466],
              [-40.013545496597324, -8.174331813552827],
              [-40.013545466892936, -8.174331811417376],
              [-40.013545437181676, -8.174331809379105],
              [-40.013545407463852, -8.174331807438035],
              [-40.01354537773981, -8.1743318055942],
              [-40.013545348009835, -8.174331803847615],
              [-40.013545318274268, -8.174331802198296],
              [-40.013545288533436, -8.174331800646291],
              [-40.013545258787637, -8.17433179919157],
              [-40.013545229037206, -8.17433179783416],
              [-40.013545199282476, -8.174331796574096],
              [-40.013545169523738, -8.174331795411378],
              [-40.013545139761334, -8.174331794346026],
              [-40.013545109995576, -8.174331793378034],
              [-40.013545080226798, -8.174331792507452],
              [-40.013545050455313, -8.174331791734264],
              [-40.013545020681434, -8.174331791058449],
              [-40.013544990905487, -8.174331790480062],
              [-40.013544961127799, -8.174331789999068],
              [-40.013544931348683, -8.174331789615509],
              [-40.013544901568466, -8.174331789329376],
              [-40.01354487178746, -8.174331789140668],
              [-40.013544842005992, -8.174331789049397],
              [-40.013544812224389, -8.174331789055559],
              [-40.01354478244297, -8.174331789159142],
              [-40.013544752662057, -8.174331789360144],
              [-40.01354472288196, -8.174331789658586],
              [-40.013544693103, -8.17433179005444],
              [-40.013544663325519, -8.174331790547715],
              [-40.013544633549813, -8.174331791138401],
              [-40.013544603776218, -8.174331791826503],
              [-40.01354457400506, -8.174331792611989],
              [-40.013544544236638, -8.174331793494888],
              [-40.013544514471292, -8.17433179447516],
              [-40.013544484709328, -8.174331795552797],
              [-40.013544454951095, -8.174331796727813],
              [-40.013544425196869, -8.174331798000155],
              [-40.01354439544702, -8.174331799369833],
              [-40.013544365701826, -8.174331800836843],
              [-40.013544335961633, -8.174331802401158],
              [-40.01354430622677, -8.17433180406274],
              [-40.01354427649752, -8.174331805821595],
              [-40.013544246774231, -8.174331807677708],
              [-40.013544217057216, -8.174331809631054],
              [-40.01354418734681, -8.174331811681585],
              [-40.013544157643317, -8.174331813829328],
              [-40.013544127947071, -8.174331816074231],
              [-40.013544098258372, -8.174331818416261],
              [-40.013544068577552, -8.17433182085542],
              [-40.013544038904918, -8.174331823391643],
              [-40.013544009240817, -8.174331826024964],
              [-40.013543979585542, -8.174331828755292],
              [-40.013543949939439, -8.174331831582613],
              [-40.013543920302801, -8.174331834506914],
              [-40.013543890675976, -8.174331837528133],
              [-40.013543861059247, -8.17433184064628],
              [-40.013543831452964, -8.174331843861282],
              [-40.013543801857431, -8.17433184717315],
              [-40.013543772272975, -8.174331850581808],
              [-40.013543742699909, -8.174331854087216],
              [-40.013543713138553, -8.174331857689392],
              [-40.013543683589219, -8.174331861388234],
              [-40.013543654052242, -8.174331865183742],
              [-40.013543624527934, -8.174331869075846],
              [-40.013543595016607, -8.17433187306451],
              [-40.013543565518589, -8.174331877149722],
              [-40.013543536034184, -8.17433188133141],
              [-40.013543506563714, -8.174331885609522],
              [-40.013543477107504, -8.174331889984041],
              [-40.013543447665882, -8.174331894454866],
              [-40.013543418239131, -8.174331899022009],
              [-40.0135433888276, -8.174331903685392],
              [-40.013543359431594, -8.17433190844498],
              [-40.013543330051434, -8.1743319133007],
              [-40.013543300687438, -8.17433191825252],
              [-40.013543271339898, -8.174331923300368],
              [-40.013543242009163, -8.174331928444198],
              [-40.013543212695545, -8.174331933683973],
              [-40.013543183399342, -8.174331939019609],
              [-40.013543154120882, -8.174331944451051],
              [-40.013543134567485, -8.174331947953164],
              [-40.012430386114396, -8.174542566345748],
              [-40.011665635412264, -8.174682946178191],
              [-40.011665640113129, -8.174682986609069],
              [-40.01166561453941, -8.174682991342554],
              [-40.011665588979405, -8.174682996149027],
              [-40.011665563433333, -8.174683001028486],
              [-40.011665537901408, -8.174683005980841],
              [-40.011665512383821, -8.174683011006078],
              [-40.011665486880794, -8.174683016104165],
              [-40.011665461392539, -8.17468302127503],
              [-40.011665435919255, -8.174683026518641],
              [-40.011665410461148, -8.174683031834977],
              [-40.011665385018453, -8.174683037223996],
              [-40.011665359591362, -8.174683042685617],
              [-40.011665334180051, -8.17468304821984],
              [-40.011665308784792, -8.174683053826573],
              [-40.011665283405755, -8.174683059505794],
              [-40.011665258043166, -8.17468306525746],
              [-40.011665232697204, -8.174683071081519],
              [-40.011665207368111, -8.174683076977908],
              [-40.01166518205607, -8.174683082946608],
              [-40.011665156761303, -8.174683088987535],
              [-40.011665131484015, -8.174683095100686],
              [-40.011665106224413, -8.174683101285982],
              [-40.011665080982709, -8.174683107543363],
              [-40.011665055759082, -8.174683113872783],
              [-40.011665030553779, -8.174683120274226],
              [-40.011665005367, -8.174683126747588],
              [-40.01166498019893, -8.17468313329282],
              [-40.011664955049788, -8.174683139909899],
              [-40.011664929919782, -8.174683146598765],
              [-40.011664904809116, -8.174683153359375],
              [-40.011664879717991, -8.174683160191607],
              [-40.011664854646632, -8.174683167095498],
              [-40.011664829595226, -8.174683174070887],
              [-40.011664804563985, -8.174683181117819],
              [-40.011664779553108, -8.174683188236196],
              [-40.011664754562808, -8.174683195425946],
              [-40.011664729593285, -8.174683202686987],
              [-40.011664704644765, -8.174683210019351],
              [-40.01166467971742, -8.174683217422873],
              [-40.011664654811476, -8.174683224897581],
              [-40.011664629927132, -8.174683232443325],
              [-40.011664605064595, -8.174683240060087],
              [-40.011664580224057, -8.174683247747835],
              [-40.011664555405737, -8.174683255506469],
              [-40.011664530609835, -8.174683263335929],
              [-40.011664505836549, -8.174683271236155],
              [-40.011664481086079, -8.174683279207111],
              [-40.011664456358638, -8.174683287248643],
              [-40.011664431654438, -8.174683295360786],
              [-40.011664406973672, -8.174683303543423],
              [-40.011664382316525, -8.174683311796501],
              [-40.011664357683216, -8.174683320119968],
              [-40.011664333073959, -8.174683328513693],
              [-40.011664308488932, -8.174683336977651],
              [-40.011664283928368, -8.174683345511781],
              [-40.011664259392418, -8.174683354116031],
              [-40.011664234881344, -8.174683362790264],
              [-40.01166421039531, -8.174683371534446],
              [-40.011664185934507, -8.174683380348508],
              [-40.011664161499176, -8.174683389232365],
              [-40.01166413708949, -8.174683398185971],
              [-40.011664112705645, -8.174683407209221],
              [-40.011664088347857, -8.174683416302063],
              [-40.01166406401633, -8.174683425464428],
              [-40.011664039711249, -8.174683434696217],
              [-40.011664015432814, -8.174683443997351],
              [-40.01166399118123, -8.174683453367786],
              [-40.011663966956689, -8.174683462807419],
              [-40.011663942759412, -8.174683472316175],
              [-40.011663918589569, -8.174683481893997],
              [-40.011663894447381, -8.174683491540769],
              [-40.011663870333031, -8.174683501256451],
              [-40.011663846246726, -8.174683511040934],
              [-40.011663822188652, -8.174683520894147],
              [-40.01166379815902, -8.174683530816033],
              [-40.011663774158031, -8.174683540806461],
              [-40.011663750185868, -8.174683550865382],
              [-40.011663726242723, -8.174683560992726],
              [-40.011663702328825, -8.174683571188355],
              [-40.011663678444329, -8.174683581452243],
              [-40.011663654589455, -8.174683591784312],
              [-40.011663630764396, -8.174683602184416],
              [-40.011663606969329, -8.174683612652506],
              [-40.011663583204502, -8.174683623188489],
              [-40.011663559470037, -8.174683633792288],
              [-40.011663535766203, -8.174683644463816],
              [-40.011663512093136, -8.174683655202969],
              [-40.011663488451049, -8.174683666009681],
              [-40.01166346484014, -8.17468367688385],
              [-40.011663441260609, -8.174683687825379],
              [-40.01166341771264, -8.174683698834208],
              [-40.011663394196439, -8.174683709910191],
              [-40.011663370712178, -8.174683721053313],
              [-40.011663347260068, -8.174683732263418],
              [-40.011663323840295, -8.174683743540452],
              [-40.011663300453051, -8.174683754884317],
              [-40.011663277098521, -8.174683766294896],
              [-40.011663253776909, -8.174683777772087],
              [-40.011663230488402, -8.174683789315869],
              [-40.011663207233184, -8.174683800926074],
              [-40.011663186674532, -8.174683766277848],
              [-40.010690173901708, -8.17517132772044],
              [-40.009857041141217, -8.175588626435745],
              [-40.009857050631851, -8.175588667681671],
              [-40.009857012631549, -8.175588686606401],
              [-40.009856974542757, -8.175588705353729],
              [-40.009856936366326, -8.17558872392323],
              [-40.009856898103088, -8.175588742314529],
              [-40.009856859753889, -8.175588760527175],
              [-40.009856821319545, -8.17558877856079],
              [-40.009856782800917, -8.175588796414953],
              [-40.009856744198842, -8.175588814089329],
              [-40.009856705514181, -8.175588831583486],
              [-40.009856666747773, -8.175588848897059],
              [-40.009856627900454, -8.17558886602967],
              [-40.009856588973072, -8.175588882980948],
              [-40.009856549966507, -8.175588899750506],
              [-40.00985651088159, -8.175588916337972],
              [-40.009856471719189, -8.175588932742999],
              [-40.009856432480163, -8.17558894896524],
              [-40.00985639316535, -8.175588965004284],
              [-40.00985635377566, -8.175588980859841],
              [-40.009856314311897, -8.175588996531545],
              [-40.009856274774961, -8.175589012019049],
              [-40.009856235165707, -8.175589027321985],
              [-40.009856195485, -8.175589042440063],
              [-40.009856155733729, -8.175589057372942],
              [-40.00985611591274, -8.175589072120271],
              [-40.00985607602292, -8.175589086681759],
              [-40.009856036065131, -8.175589101057064],
              [-40.009855996040265, -8.1755891152459],
              [-40.009855955949163, -8.175589129247896],
              [-40.009855915792755, -8.17558914306283],
              [-40.00985587557188, -8.175589156690307],
              [-40.009855835287446, -8.175589170130095],
              [-40.009855794940322, -8.17558918338187],
              [-40.009855754531387, -8.175589196445364],
              [-40.00985571406153, -8.175589209320259],
              [-40.009855673531639, -8.175589222006318],
              [-40.009855632942603, -8.175589234503237],
              [-40.009855592295324, -8.17558924681072],
              [-40.009855551590661, -8.175589258928525],
              [-40.009855510829532, -8.175589270856397],
              [-40.009855470012823, -8.17558928259405],
              [-40.009855429141417, -8.175589294141226],
              [-40.009855388216238, -8.17558930549772],
              [-40.009855347238151, -8.175589316663197],
              [-40.009855306208053, -8.17558932763748],
              [-40.009855265126866, -8.175589338420323],
              [-40.009855223995487, -8.175589349011467],
              [-40.009855182814796, -8.175589359410703],
              [-40.009855141585717, -8.175589369617747],
              [-40.009855100309139, -8.175589379632447],
              [-40.009855058985977, -8.175589389454545],
              [-40.009855017617134, -8.175589399083831],
              [-40.009854976203499, -8.17558940852013],
              [-40.009854934746002, -8.175589417763177],
              [-40.009854893245546, -8.175589426812806],
              [-40.009854851703025, -8.175589435668812],
              [-40.009854810119364, -8.175589444330971],
              [-40.009854768495472, -8.175589452799144],
              [-40.009854726832266, -8.175589461073121],
              [-40.009854685130634, -8.175589469152705],
              [-40.00985464339152, -8.175589477037759],
              [-40.009854601615814, -8.175589484728082],
              [-40.009854559804459, -8.175589492223517],
              [-40.009854517958345, -8.175589499523877],
              [-40.009854476078388, -8.175589506629029],
              [-40.009854434165526, -8.175589513538801],
              [-40.009854392220667, -8.175589520253052],
              [-40.00985435024473, -8.175589526771612],
              [-40.00985430823863, -8.175589533094366],
              [-40.009854266203284, -8.175589539221168],
              [-40.00985422413963, -8.175589545151887],
              [-40.009854182048571, -8.175589550886386],
              [-40.009854139931036, -8.175589556424544],
              [-40.009854097787958, -8.175589561766261],
              [-40.009854055620245, -8.175589566911356],
              [-40.009854013428814, -8.175589571859772],
              [-40.009853971214611, -8.17558957661138],
              [-40.009853928978558, -8.17558958116606],
              [-40.009853886721565, -8.175589585523767],
              [-40.009853844444564, -8.175589589684371],
              [-40.009853802148484, -8.175589593647775],
              [-40.009853759834265, -8.175589597413873],
              [-40.009853717502793, -8.175589600982629],
              [-40.009853675155036, -8.175589604353938],
              [-40.009853632791909, -8.175589607527741],
              [-40.00985359041433, -8.175589610503986],
              [-40.009853548023237, -8.175589613282529],
              [-40.009853505619553, -8.1755896158634],
              [-40.009853463204223, -8.175589618246494],
              [-40.00985342077815, -8.17558962043174],
              [-40.009853378342285, -8.175589622419169],
              [-40.009853335897546, -8.175589624208635],
              [-40.00985329344487, -8.175589625800177],
              [-40.009853250985188, -8.17558962719373],
              [-40.009853208519409, -8.175589628389273],
              [-40.009853166048501, -8.175589629386788],
              [-40.009853123573357, -8.175589630186222],
              [-40.009853081094924, -8.17558963078759],
              [-40.009853038614132, -8.175589631190853],
              [-40.009852996132075, -8.17558963139532],
              [-40.009852958984325, -8.175589631488101],
              [-40.0088115718072, -8.17559223099912],
              [-40.00881155214433, -8.175592231048178],
              [-40.008811523234094, -8.175592231013761],
              [-40.0088114943241, -8.175592230887496],
              [-40.008811465414674, -8.175592230669418],
              [-40.008811436506093, -8.175592230359515],
              [-40.008811407598643, -8.175592229957781],
              [-40.00881137869262, -8.175592229464247],
              [-40.008811349788338, -8.175592228878882],
              [-40.008811320886061, -8.175592228201687],
              [-40.008811291986113, -8.175592227432704],
              [-40.008811263088759, -8.175592226571922],
              [-40.008811234194312, -8.175592225619376],
              [-40.008811205303054, -8.175592224575025],
              [-40.0088111764153, -8.175592223438924],
              [-40.008811147531304, -8.175592222211051],
              [-40.008811118651401, -8.175592220891444],
              [-40.008811089775868, -8.175592219480098],
              [-40.008811060904982, -8.175592217977053],
              [-40.008811032039048, -8.175592216382295],
              [-40.008811003178394, -8.175592214695854],
              [-40.008810974323268, -8.17559221291777],
              [-40.008810945473968, -8.175592211048015],
              [-40.008810916630807, -8.175592209086636],
              [-40.008810887794063, -8.175592207033647],
              [-40.008810858964047, -8.175592204889067],
              [-40.00881083014103, -8.175592202652931],
              [-40.008810801325303, -8.175592200325235],
              [-40.008810772517194, -8.175592197906044],
              [-40.00881074371695, -8.175592195395343],
              [-40.008810714924891, -8.175592192793168],
              [-40.00881068614131, -8.175592190099545],
              [-40.008810657366496, -8.175592187314509],
              [-40.008810628600727, -8.175592184438081],
              [-40.008810599844317, -8.175592181470295],
              [-40.008810571097555, -8.175592178411192],
              [-40.008810542360713, -8.175592175260791],
              [-40.008810513634103, -8.17559217201911],
              [-40.008810484918016, -8.175592168686201],
              [-40.008810456212736, -8.17559216526209],
              [-40.008810427518561, -8.17559216174682],
              [-40.008810398835777, -8.175592158140415],
              [-40.008810370164674, -8.17559215444293],
              [-40.008810341505544, -8.175592150654378],
              [-40.008810312858685, -8.175592146774797],
              [-40.008810284224388, -8.175592142804263],
              [-40.008810255602945, -8.175592138742797],
              [-40.00881022699464, -8.175592134590431],
              [-40.008810198399765, -8.175592130347216],
              [-40.008810169818609, -8.17559212601318],
              [-40.008810141251473, -8.175592121588398],
              [-40.008810112698633, -8.175592117072879],
              [-40.008810084160388, -8.175592112466692],
              [-40.008810055637028, -8.175592107769889],
              [-40.008810027128831, -8.175592102982515],
              [-40.00880999863611, -8.175592098104575],
              [-40.008809970159135, -8.175592093136181],
              [-40.008809941698203, -8.175592088077334],
              [-40.0088099132536, -8.175592082928137],
              [-40.008809884825624, -8.175592077688584],
              [-40.008809856414544, -8.175592072358784],
              [-40.008809828020674, -8.17559206693873],
              [-40.008809799644283, -8.175592061428519],
              [-40.008809771285669, -8.175592055828222],
              [-40.008809742945125, -8.175592050137857],
              [-40.008809714622927, -8.17559204435749],
              [-40.008809686319367, -8.175592038487183],
              [-40.008809658034735, -8.175592032527025],
              [-40.008809629769324, -8.175592026477045],
              [-40.008809601523396, -8.175592020337298],
              [-40.00880957329727, -8.175592014107876],
              [-40.008809545091225, -8.175592007788818],
              [-40.008809516905522, -8.175592001380192],
              [-40.008809488740489, -8.175591994882065],
              [-40.008809460596375, -8.175591988294512],
              [-40.008809432473498, -8.175591981617607],
              [-40.008809404372109, -8.175591974851383],
              [-40.008809376292518, -8.175591967995938],
              [-40.008809348235012, -8.175591961051339],
              [-40.008809320199866, -8.175591954017637],
              [-40.008809292187358, -8.175591946894915],
              [-40.008809264197801, -8.175591939683272],
              [-40.008809236231443, -8.17559193238276],
              [-40.008809208288596, -8.175591924993444],
              [-40.008809180369532, -8.175591917515394],
              [-40.008809152474534, -8.175591909948702],
              [-40.008809124603893, -8.175591902293446],
              [-40.008809096757886, -8.175591894549703],
              [-40.008809068936799, -8.175591886717555],
              [-40.008809041140907, -8.175591878797038],
              [-40.008809013370502, -8.175591870788303],
              [-40.008808985625869, -8.175591862691382],
              [-40.008808957907277, -8.175591854506381],
              [-40.008808930215011, -8.17559184623337],
              [-40.008808902549369, -8.175591837872412],
              [-40.008808874910613, -8.17559182942365],
              [-40.008808847299036, -8.175591820887124],
              [-40.008808819714901, -8.175591812262928],
              [-40.008808792158511, -8.175591803551178],
              [-40.008808764630132, -8.175591794751931],
              [-40.008808737130323, -8.175591785863432],
              [-40.008808702105462, -8.175591774663264],
              [-40.008188713852846, -8.175387758515653],
              [-40.00818874991436, -8.175387738779435],
              [-40.008188641462191, -8.175387702328806],
              [-40.008188533479938, -8.175387664518542],
              [-40.008188425984748, -8.175387625354666],
              [-40.008188318993732, -8.175387584843396],
              [-40.008188212523898, -8.175387542991155],
              [-40.008188106592158, -8.175387499804637],
              [-40.008188001215373, -8.175387455290668],
              [-40.008187896410277, -8.175387409456352],
              [-40.00818779219356, -8.175387362308991],
              [-40.008187688581785, -8.175387313856053],
              [-40.008187585591408, -8.175387264105252],
              [-40.008187483238821, -8.175387213064479],
              [-40.008187381540296, -8.175387160741884],
              [-40.00818728051199, -8.175387107145758],
              [-40.00818718016999, -8.175387052284634],
              [-40.00818708053022, -8.175386996167246],
              [-40.008186981608553, -8.17538693880249],
              [-40.008186883420699, -8.175386880199509],
              [-40.008186785982268, -8.175386820367613],
              [-40.008186689308765, -8.175386759316318],
              [-40.008186593415537, -8.17538669705532],
              [-40.008186498317862, -8.175386633594519],
              [-40.008186404030845, -8.175386568944024],
              [-40.008186310569478, -8.175386503114117],
              [-40.008186217948612, -8.175386436115229],
              [-40.008186126182984, -8.175386367958016],
              [-40.008186035287203, -8.175386298653356],
              [-40.008185945275663, -8.175386228212236],
              [-40.00818585616274, -8.175386156645873],
              [-40.008185767962573, -8.175386083965634],
              [-40.008185680689174, -8.175386010183072],
              [-40.008185594356455, -8.175385935309915],
              [-40.008185508978116, -8.175385859358121],
              [-40.008185424567721, -8.175385782339685],
              [-40.008185341138713, -8.175385704266924],
              [-40.008185258704366, -8.175385625152179],
              [-40.008185177277745, -8.175385545008078],
              [-40.008185096871834, -8.175385463847334],
              [-40.008185017499414, -8.17538538168289],
              [-40.008184939173098, -8.175385298527788],
              [-40.008184861905335, -8.175385214395266],
              [-40.008184785708416, -8.175385129298661],
              [-40.008184710594449, -8.175385043251547],
              [-40.008184636575386, -8.175384956267575],
              [-40.008184563662979, -8.175384868360581],
              [-40.00818449186886, -8.175384779544553],
              [-40.008184421204405, -8.175384689833612],
              [-40.00818435168086, -8.175384599242012],
              [-40.008184283309284, -8.175384507784154],
              [-40.008184216100538, -8.175384415474591],
              [-40.008184150065311, -8.175384322328004],
              [-40.008184085214126, -8.175384228359178],
              [-40.008184021557263, -8.17538413358308],
              [-40.008183959104848, -8.175384038014796],
              [-40.008183897866829, -8.175383941669461],
              [-40.008183837852933, -8.175383844562447],
              [-40.008183779072695, -8.175383746709164],
              [-40.008183721535467, -8.175383648125182],
              [-40.008183665250385, -8.175383548826151],
              [-40.008183610226432, -8.175383448827882],
              [-40.008183556472318, -8.17538334814631],
              [-40.008183503996605, -8.175383246797395],
              [-40.008183452807643, -8.175383144797273],
              [-40.008183402913559, -8.175383042162128],
              [-40.008183354322306, -8.175382938908321],
              [-40.008183307041563, -8.175382835052254],
              [-40.008183261078919, -8.175382730610412],
              [-40.008183216441623, -8.175382625599427],
              [-40.008183173136793, -8.175382520035994],
              [-40.008183131171315, -8.1753824139369],
              [-40.00818309055186, -8.175382307319014],
              [-40.008183051284902, -8.1753822001993],
              [-40.008183013376645, -8.175382092594774],
              [-40.008182976833162, -8.175381984522518],
              [-40.008182941660223, -8.175381875999769],
              [-40.008182907863436, -8.175381767043747],
              [-40.008182875448192, -8.175381657671807],
              [-40.008182844419629, -8.175381547901287],
              [-40.008182814782671, -8.175381437749673],
              [-40.008182786542051, -8.175381327234479],
              [-40.008182759702244, -8.175381216373282],
              [-40.008182734267521, -8.175381105183689],
              [-40.008182710241947, -8.175380993683396],
              [-40.008182687629279, -8.17538088189011],
              [-40.0081826664332, -8.175380769821608],
              [-40.008182646657012, -8.17538065749576],
              [-40.008182628303885, -8.175380544930366],
              [-40.008182611376739, -8.175380432143331],
              [-40.00818259587826, -8.17538031915262],
              [-40.008182581810928, -8.175380205976154],
              [-40.008182569176945, -8.175380092631961],
              [-40.008182557978351, -8.175379979138036],
              [-40.008182548216908, -8.175379865512465],
              [-40.008182539894186, -8.175379751773265],
              [-40.008182533011485, -8.175379637938549],
              [-40.008182527569922, -8.175379524026454],
              [-40.008182523570355, -8.175379410055019],
              [-40.00818252101341, -8.175379296042404],
              [-40.008182519899833, -8.175379182006344],
              [-40.008182519890426, -8.175379178258657],
              [-40.008182519878844, -8.17537917364765],
              [-40.008180282697381, -8.174488339812331],
              [-40.008180282691981, -8.174488337733244],
              [-40.008180282060124, -8.174488253009558],
              [-40.008180280631741, -8.174488168295477],
              [-40.008180278406925, -8.174488083598471],
              [-40.008180275385882, -8.174487998925926],
              [-40.008180271568897, -8.174487914285313],
              [-40.008180266956259, -8.174487829684015],
              [-40.008180261548404, -8.174487745129511],
              [-40.008180255345806, -8.174487660629181],
              [-40.008180248349007, -8.174487576190462],
              [-40.008180240558616, -8.174487491820734],
              [-40.008180231975331, -8.17448740752746],
              [-40.008180222599883, -8.174487323317965],
              [-40.008180212433118, -8.174487239199697],
              [-40.008180201475888, -8.174487155180026],
              [-40.00818018972921, -8.174487071266277],
              [-40.008180177194077, -8.174486987465883],
              [-40.0081801638716, -8.174486903786164],
              [-40.00818014976295, -8.174486820234479],
              [-40.008180134869363, -8.174486736818157],
              [-40.008180119192147, -8.174486653544488],
              [-40.00818010273268, -8.174486570420807],
              [-40.008180085492384, -8.174486487454411],
              [-40.008180067472821, -8.174486404652574],
              [-40.008180048675534, -8.174486322022585],
              [-40.008180029102164, -8.174486239571644],
              [-40.008180008754472, -8.174486157307069],
              [-40.0081799876342, -8.174486075236009],
              [-40.008179965743231, -8.17448599336567],
              [-40.008179943083462, -8.174485911703256],
              [-40.008179919656904, -8.174485830255923],
              [-40.008179895465602, -8.174485749030829],
              [-40.008179870511675, -8.174485668035103],
              [-40.008179844797333, -8.174485587275857],
              [-40.008179818324791, -8.174485506760188],
              [-40.008179791096424, -8.174485426495119],
              [-40.00817976311459, -8.174485346487749],
              [-40.008179734381756, -8.174485266745052],
              [-40.008179704900428, -8.174485187274062],
              [-40.008179674673222, -8.17448510808171],
              [-40.008179643702753, -8.17448502917501],
              [-40.008179611991778, -8.174484950560856],
              [-40.008179579543047, -8.17448487224611],
              [-40.008179546359429, -8.174484794237687],
              [-40.008179512443824, -8.174484716542418],
              [-40.008179477799217, -8.174484639167135],
              [-40.008179442428649, -8.174484562118623],
              [-40.008179406335202, -8.174484485403662],
              [-40.008179369522082, -8.174484409028929],
              [-40.00817933199248, -8.174484333001157],
              [-40.008179293749741, -8.174484257327048],
              [-40.00817925479717, -8.174484182013186],
              [-40.008179215138192, -8.17448410706624],
              [-40.008179174776316, -8.174484032492737],
              [-40.008179133715068, -8.17448395829927],
              [-40.008179091958056, -8.17448388449232],
              [-40.008179049508939, -8.174483811078375],
              [-40.008179006371435, -8.174483738063843],
              [-40.008178962549358, -8.174483665455194],
              [-40.008178918046504, -8.174483593258756],
              [-40.008178872866829, -8.174483521480866],
              [-40.008178827014277, -8.174483450127852],
              [-40.008178780492877, -8.174483379205929],
              [-40.0081787333067, -8.174483308721369],
              [-40.008178685459889, -8.174483238680331],
              [-40.008178636956664, -8.174483169088974],
              [-40.008178587801261, -8.174483099953417],
              [-40.008178537998006, -8.174483031279687],
              [-40.008178487551255, -8.174482963073826],
              [-40.00817843646545, -8.174482895341853],
              [-40.008178384745058, -8.174482828089685],
              [-40.008178332394642, -8.174482761323208],
              [-40.008178279418779, -8.174482695048326],
              [-40.008178225822121, -8.1744826292708],
              [-40.008178171609366, -8.174482563996456],
              [-40.008178116785281, -8.174482499230994],
              [-40.008178061354684, -8.174482434980096],
              [-40.008178005322421, -8.174482371249418],
              [-40.008177948693422, -8.174482308044547],
              [-40.008177891472648, -8.174482245371001],
              [-40.008177833665123, -8.17448218323432],
              [-40.008177775275932, -8.174482121639933],
              [-40.008177716310193, -8.17448206059326],
              [-40.008177656773078, -8.174482000099651],
              [-40.008177596669803, -8.1744819401644],
              [-40.008177536005668, -8.174481880792817],
              [-40.008177474785953, -8.174481821990062],
              [-40.008177413016092, -8.174481763761305],
              [-40.008177350701452, -8.174481706111653],
              [-40.008177287847516, -8.174481649046205],
              [-40.008177224459828, -8.174481592569927],
              [-40.008177160543916, -8.174481536687777],
              [-40.008177096105406, -8.174481481404673],
              [-40.008177031149941, -8.17448142672548],
              [-40.008176965683255, -8.174481372654972],
              [-40.008176899711053, -8.174481319197909],
              [-40.008176833239141, -8.174481266358974],
              [-40.008176766273351, -8.174481214142823],
              [-40.008176698819568, -8.174481162554004],
              [-40.008176630883696, -8.174481111597078],
              [-40.008176633722542, -8.174481111500752],
              [-40.007622171831855, -8.174069265606747],
              [-40.007621444537513, -8.174068815210664],
              [-40.006718077588893, -8.173518285570202],
              [-40.006718089390652, -8.173518270816054],
              [-40.006718024921263, -8.17351823189396],
              [-40.006717960129691, -8.173518193506652],
              [-40.006717895020408, -8.173518155656817],
              [-40.00671782959791, -8.173518118346999],
              [-40.006717763866689, -8.17351808157982],
              [-40.006717697831306, -8.173518045357794],
              [-40.006717631496286, -8.173518009683402],
              [-40.006717564866221, -8.173517974559143],
              [-40.006717497945722, -8.173517939987432],
              [-40.006717430739364, -8.173517905970613],
              [-40.006717363251809, -8.173517872511072],
              [-40.006717295487704, -8.173517839611135],
              [-40.00671722745173, -8.173517807273015],
              [-40.006717159148579, -8.173517775498974],
              [-40.006717090582939, -8.173517744291193],
              [-40.006717021759563, -8.173517713651831],
              [-40.006716952683199, -8.173517683582993],
              [-40.0067168833586, -8.173517654086789],
              [-40.00671681379054, -8.173517625165195],
              [-40.006716743983837, -8.173517596820243],
              [-40.006716673943288, -8.173517569053896],
              [-40.006716603673731, -8.173517541868046],
              [-40.006716533180011, -8.173517515264574],
              [-40.00671646246699, -8.173517489245299],
              [-40.006716391539555, -8.173517463812054],
              [-40.006716320402575, -8.173517438966554],
              [-40.006716249060965, -8.173517414710526],
              [-40.006716177519657, -8.173517391045657],
              [-40.006716105783582, -8.173517367973558],
              [-40.006716033857671, -8.173517345495839],
              [-40.006715961746906, -8.173517323614032],
              [-40.006715889456245, -8.17351730232968],
              [-40.00671581699067, -8.173517281644202],
              [-40.006715744355191, -8.173517261559033],
              [-40.006715671554808, -8.173517242075592],
              [-40.00671559859456, -8.173517223195214],
              [-40.006715525479457, -8.173517204919186],
              [-40.006715452214557, -8.173517187248773],
              [-40.006715378804898, -8.173517170185193],
              [-40.006715305255547, -8.173517153729629],
              [-40.006715231571583, -8.173517137883215],
              [-40.006715157758094, -8.173517122647032],
              [-40.006715083820161, -8.173517108022132],
              [-40.006715009762864, -8.173517094009544],
              [-40.006714935591347, -8.173517080610196],
              [-40.006714861310705, -8.173517067825076],
              [-40.006714786926082, -8.17351705565501],
              [-40.006714712442559, -8.173517044100857],
              [-40.006714637865308, -8.173517033163384],
              [-40.006714563199488, -8.173517022843404],
              [-40.006714488450228, -8.173517013141595],
              [-40.00671441362266, -8.173517004058649],
              [-40.006714338721977, -8.173516995595143],
              [-40.006714263753345, -8.173516987751675],
              [-40.006714188721929, -8.173516980528817],
              [-40.006714113632896, -8.17351697392704],
              [-40.006714038491417, -8.173516967946833],
              [-40.006713963302701, -8.173516962588563],
              [-40.006713888071907, -8.173516957852616],
              [-40.006713812804229, -8.173516953739359],
              [-40.006713737504889, -8.173516950249013],
              [-40.006713662179024, -8.173516947381863],
              [-40.006713586831886, -8.173516945138086],
              [-40.006713511468625, -8.173516943517837],
              [-40.006713436094472, -8.173516942521241],
              [-40.006713360714606, -8.173516942148344],
              [-40.006713285334243, -8.173516942399205],
              [-40.006713209958541, -8.173516943273759],
              [-40.006713134592765, -8.173516944772],
              [-40.006713059242045, -8.173516946893807],
              [-40.006712983911626, -8.173516949639017],
              [-40.006712908606673, -8.173516953007432],
              [-40.006712833332408, -8.173516956998853],
              [-40.00671275809399, -8.173516961612963],
              [-40.006712682896627, -8.173516966849501],
              [-40.006712607745506, -8.173516972708045],
              [-40.006712532645793, -8.173516979188234],
              [-40.006712457602696, -8.173516986289627],
              [-40.006712382621373, -8.173516994011688],
              [-40.006712307706991, -8.173517002353908],
              [-40.006712232864714, -8.173517011315729],
              [-40.006712158099731, -8.173517020896531],
              [-40.006712083417156, -8.17351703109563],
              [-40.006712008822184, -8.173517041912339],
              [-40.006711934319938, -8.173517053345899],
              [-40.006711859915541, -8.173517065395513],
              [-40.006711785614158, -8.173517078060387],
              [-40.006711711420884, -8.173517091339603],
              [-40.006711637340842, -8.173517105232289],
              [-40.006711563379156, -8.173517119737468],
              [-40.006711489540919, -8.173517134854123],
              [-40.006711415831205, -8.173517150581223],
              [-40.006711342255123, -8.1735171669177],
              [-40.006711268817732, -8.17351718386241],
              [-40.006711195524112, -8.173517201414178],
              [-40.006711122379301, -8.173517219571828],
              [-40.00671104938835, -8.173517238334071],
              [-40.006710976556306, -8.173517257699626],
              [-40.006710903888163, -8.173517277667171],
              [-40.006710904277682, -8.173517308567195],
              [-40.005465134478101, -8.173865171209263],
              [-40.005465095119291, -8.173865147823751],
              [-40.005465024838649, -8.173865167752586],
              [-40.005464954721297, -8.173865188244513],
              [-40.005464884771776, -8.17386520929816],
              [-40.00546481499461, -8.173865230912218],
              [-40.005464745394342, -8.173865253085236],
              [-40.005464675975475, -8.173865275815782],
              [-40.005464606742521, -8.173865299102417],
              [-40.005464537699929, -8.173865322943593],
              [-40.005464468852196, -8.173865347337776],
              [-40.005464400203799, -8.173865372283423],
              [-40.005464331759157, -8.17386539777887],
              [-40.005464263522725, -8.173865423822457],
              [-40.005464195498909, -8.173865450412542],
              [-40.005464127692136, -8.173865477547389],
              [-40.005464060106789, -8.173865505225193],
              [-40.005463992747252, -8.173865533444218],
              [-40.005463925617889, -8.173865562202634],
              [-40.005463858723054, -8.173865591498545],
              [-40.005463792067076, -8.173865621330055],
              [-40.00546372565428, -8.173865651695248],
              [-40.005463659488953, -8.173865682592142],
              [-40.005463593575413, -8.173865714018742],
              [-40.005463527917897, -8.173865745972996],
              [-40.005463462520709, -8.173865778452853],
              [-40.005463397388034, -8.173865811456233],
              [-40.005463332524108, -8.173865844980918],
              [-40.005463267933152, -8.17386587902481],
              [-40.00546320361935, -8.173865913585676],
              [-40.005463139586851, -8.173865948661273],
              [-40.005463075839806, -8.173865984249332],
              [-40.005463012382364, -8.173866020347555],
              [-40.005462949218632, -8.173866056953582],
              [-40.005462886352703, -8.173866094065083],
              [-40.005462823788633, -8.17386613167962],
              [-40.005462761530502, -8.173866169794733],
              [-40.005462699582324, -8.173866208408002],
              [-40.005462637948142, -8.173866247516894],
              [-40.005462576631921, -8.173866287118884],
              [-40.005462515637639, -8.173866327211373],
              [-40.005462454969262, -8.17386636779182],
              [-40.005462394630712, -8.173866408857553],
              [-40.005462334625896, -8.173866450405942],
              [-40.00546227495871, -8.173866492434273],
              [-40.005462215633024, -8.173866534939831],
              [-40.005462156652676, -8.173866577919854],
              [-40.005462098021482, -8.173866621371559],
              [-40.005462039743264, -8.173866665292135],
              [-40.005461981821774, -8.173866709678711],
              [-40.005461924260786, -8.173866754528467],
              [-40.005461867064007, -8.173866799838459],
              [-40.005461810235147, -8.173866845605746],
              [-40.005461753777922, -8.173866891827357],
              [-40.005461697695957, -8.173866938500336],
              [-40.005461641992888, -8.173866985621602],
              [-40.005461586672347, -8.17386703318817],
              [-40.005461531737893, -8.173867081196875],
              [-40.005461477193109, -8.173867129644666],
              [-40.00546142304151, -8.173867178528416],
              [-40.005461369286621, -8.173867227844909],
              [-40.005461315931917, -8.17386727759096],
              [-40.005461262980852, -8.17386732776334],
              [-40.005461210436877, -8.173867378358837],
              [-40.005461158303362, -8.173867429374134],
              [-40.005461106583731, -8.173867480805907],
              [-40.005461055281287, -8.173867532650888],
              [-40.005461004399393, -8.173867584905661],
              [-40.005460953941331, -8.173867637566872],
              [-40.005460903910375, -8.173867690631107],
              [-40.005460854309739, -8.173867744094885],
              [-40.005460805142697, -8.173867797954797],
              [-40.005460756412383, -8.173867852207332],
              [-40.00546070812198, -8.17386790684896],
              [-40.005460660274601, -8.173867961876134],
              [-40.005460612873364, -8.173868017285349],
              [-40.005460565921332, -8.173868073072935],
              [-40.005460519421547, -8.173868129235316],
              [-40.00546047337702, -8.173868185768844],
              [-40.005460427790752, -8.17386824266989],
              [-40.005460382665675, -8.173868299934691],
              [-40.005460338004717, -8.173868357559602],
              [-40.005460293810792, -8.173868415540873],
              [-40.005460250086742, -8.173868473874748],
              [-40.005460206835409, -8.173868532557432],
              [-40.005460164059606, -8.173868591585109],
              [-40.005460121762077, -8.173868650954002],
              [-40.005460079945586, -8.173868710660205],
              [-40.005460038612831, -8.173868770699904],
              [-40.005459997766508, -8.17386883106918],
              [-40.005459957409251, -8.173868891764137],
              [-40.005459917543668, -8.173868952780829],
              [-40.00545987817236, -8.173869014115285],
              [-40.00545983929787, -8.173869075763548],
              [-40.005459800922715, -8.173869137721624],
              [-40.005459763049387, -8.173869199985509],
              [-40.005459725680332, -8.173869262551149],
              [-40.005459688817986, -8.173869325414495],
              [-40.005459652464715, -8.173869388571498],
              [-40.005459616622915, -8.173869452018026],
              [-40.005459581294858, -8.173869515749944],
              [-40.005459608382566, -8.173869526148771],
              [-40.004699872472429, -8.175253182579532],
              [-40.004699871547032, -8.175253160925138],
              [-40.004699845164765, -8.175253209323918],
              [-40.004699819078809, -8.175253257881863],
              [-40.004699793290115, -8.175253306597192],
              [-40.004699767799629, -8.175253355468088],
              [-40.004699742608317, -8.175253404492754],
              [-40.004699717717095, -8.175253453669365],
              [-40.004699693126909, -8.175253502996075],
              [-40.004699668838654, -8.175253552471098],
              [-40.004699644853225, -8.175253602092567],
              [-40.004699621171525, -8.175253651858648],
              [-40.004699597794435, -8.175253701767504],
              [-40.004699574722821, -8.175253751817289],
              [-40.004699551957529, -8.175253802006118],
              [-40.004699529499412, -8.175253852332155],
              [-40.004699507349287, -8.175253902793502],
              [-40.004699485507984, -8.175253953388365],
              [-40.004699463976323, -8.175254004114787],
              [-40.004699442755097, -8.175254054970909],
              [-40.004699421845096, -8.175254105954844],
              [-40.004699401247088, -8.175254157064717],
              [-40.004699380961824, -8.175254208298639],
              [-40.004699360990095, -8.175254259654679],
              [-40.004699341332604, -8.175254311130942],
              [-40.004699321990088, -8.175254362725539],
              [-40.004699302963282, -8.175254414436569],
              [-40.004699284252872, -8.175254466262054],
              [-40.004699265859571, -8.175254518200125],
              [-40.004699247784046, -8.175254570248807],
              [-40.004699230026979, -8.175254622406216],
              [-40.00469921258901, -8.175254674670393],
              [-40.004699195470806, -8.17525472703942],
              [-40.004699178672986, -8.175254779511334],
              [-40.004699162196182, -8.1752548320842],
              [-40.004699146040998, -8.175254884756081],
              [-40.004699130208039, -8.175254937525004],
              [-40.004699114697914, -8.175254990389012],
              [-40.004699099511143, -8.175255043346132],
              [-40.004699084648323, -8.175255096394444],
              [-40.004699070110028, -8.175255149531944],
              [-40.004699055896758, -8.175255202756665],
              [-40.004699042009058, -8.175255256066652],
              [-40.004699028447448, -8.175255309459921],
              [-40.004699015212402, -8.175255362934484],
              [-40.004699002304442, -8.175255416488357],
              [-40.004698989724048, -8.175255470119565],
              [-40.004698977471662, -8.175255523826081],
              [-40.004698965547746, -8.175255577605972],
              [-40.004698953952754, -8.175255631457215],
              [-40.004698942687114, -8.175255685377806],
              [-40.00469893175125, -8.175255739365749],
              [-40.004698921145526, -8.175255793419046],
              [-40.004698910870388, -8.1752558475357],
              [-40.004698900926194, -8.175255901713685],
              [-40.004698891313303, -8.175255955951014],
              [-40.004698882032095, -8.175256010245656],
              [-40.004698873082887, -8.17525606459562],
              [-40.00469886446605, -8.175256118998869],
              [-40.004698856181854, -8.175256173453395],
              [-40.004698848230646, -8.17525622795716],
              [-40.00469884061269, -8.175256282508158],
              [-40.004698833328305, -8.175256337104388],
              [-40.004698826377734, -8.175256391743806],
              [-40.004698819761231, -8.175256446424372],
              [-40.00469881347906, -8.175256501144085],
              [-40.004698807531454, -8.1752565559009],
              [-40.004698801918622, -8.175256610692786],
              [-40.00469879664076, -8.175256665517718],
              [-40.004698791698104, -8.175256720373634],
              [-40.004698787090796, -8.175256775258537],
              [-40.004698782819027, -8.175256830170383],
              [-40.004698778882954, -8.175256885107123],
              [-40.004698775282712, -8.175256940066729],
              [-40.004698772018457, -8.175256995047183],
              [-40.00469876909029, -8.175257050046389],
              [-40.004698766498322, -8.17525710506235],
              [-40.004698764242654, -8.175257160093024],
              [-40.004698762323379, -8.175257215136375],
              [-40.004698760740546, -8.175257270190325],
              [-40.004698759494232, -8.175257325252872],
              [-40.004698758584489, -8.175257380321964],
              [-40.004698758011315, -8.17525743539554],
              [-40.004698757774769, -8.175257490471601],
              [-40.004698757874856, -8.175257545548071],
              [-40.004698758311541, -8.175257600622899],
              [-40.004698759084846, -8.175257655694056],
              [-40.004698760194721, -8.175257710759492],
              [-40.004698761641116, -8.175257765817202],
              [-40.004698763423988, -8.175257820865083],
              [-40.004698765543289, -8.175257875901123],
              [-40.00469876799891, -8.17525793092331],
              [-40.004698770790796, -8.175257985929548],
              [-40.004698773918811, -8.175258040917852],
              [-40.004698777382842, -8.175258095886136],
              [-40.004698781182782, -8.175258150832391],
              [-40.004698785318467, -8.175258205754563],
              [-40.004698789789757, -8.175258260650653],
              [-40.004698794596479, -8.175258315518564],
              [-40.00469879973847, -8.175258370356298],
              [-40.004698805215511, -8.175258425161815],
              [-40.004698796357481, -8.175258430871965],
              [-40.004769779761055, -8.175947691559557],
              [-40.00476981197442, -8.175947653560971],
              [-40.004769815085993, -8.175947683280043],
              [-40.00476981829609, -8.175947712988714],
              [-40.004769821604668, -8.175947742686677],
              [-40.004769825011721, -8.175947772373549],
              [-40.00476982851719, -8.175947802049041],
              [-40.004769832121028, -8.175947831712881],
              [-40.004769835823232, -8.175947861364639],
              [-40.004769839623712, -8.175947891004068],
              [-40.004769843522467, -8.175947920630824],
              [-40.004769847519427, -8.175947950244584],
              [-40.004769851614569, -8.175947979845077],
              [-40.004769855807844, -8.175948009431883],
              [-40.004769860099181, -8.175948039004716],
              [-40.004769864488573, -8.175948068563271],
              [-40.004769868975949, -8.175948098107213],
              [-40.004769873561258, -8.175948127636223],
              [-40.004769878244467, -8.17594815714998],
              [-40.00476988302551, -8.175948186648181],
              [-40.004769887904324, -8.17594821613044],
              [-40.004769892880887, -8.175948245596516],
              [-40.004769897955128, -8.175948275046011],
              [-40.004769903126984, -8.175948304478672],
              [-40.004769908396405, -8.175948333894137],
              [-40.00476991376334, -8.1759483632921],
              [-40.004769919227726, -8.175948392672213],
              [-40.004769924789507, -8.175948422034194],
              [-40.004769930448617, -8.175948451377673],
              [-40.00476993620498, -8.175948480702397],
              [-40.004769942058559, -8.175948510008027],
              [-40.004769948009269, -8.175948539294209],
              [-40.004769954057075, -8.17594856856061],
              [-40.004769960201877, -8.175948597806991],
              [-40.004769966443632, -8.175948627032957],
              [-40.004769972782256, -8.175948656238226],
              [-40.004769979217691, -8.175948685422465],
              [-40.004769985749853, -8.17594871458539],
              [-40.004769992378677, -8.175948743726616],
              [-40.004769999104099, -8.175948772845901],
              [-40.004770005926048, -8.175948801942864],
              [-40.004770012844439, -8.175948831017228],
              [-40.004770019859194, -8.175948860068647],
              [-40.004770026970235, -8.175948889096817],
              [-40.004770034177504, -8.175948918101398],
              [-40.004770041480903, -8.175948947082119],
              [-40.004770048880353, -8.175948976038653],
              [-40.004770056375783, -8.175949004970649],
              [-40.004770063967101, -8.175949033877815],
              [-40.004770071654235, -8.175949062759843],
              [-40.004770079437101, -8.175949091616397],
              [-40.004770087315592, -8.175949120447179],
              [-40.004770095289643, -8.175949149251883],
              [-40.004770103359185, -8.175949178030166],
              [-40.004770111524088, -8.175949206781715],
              [-40.00477011978429, -8.175949235506241],
              [-40.004770128139697, -8.175949264203442],
              [-40.004770136590189, -8.175949292872916],
              [-40.004770145135716, -8.175949321514473],
              [-40.004770153776178, -8.175949350127748],
              [-40.004770162511456, -8.175949378712401],
              [-40.004770171341477, -8.175949407268144],
              [-40.004770180266135, -8.17594943579468],
              [-40.004770189285331, -8.175949464291671],
              [-40.004770198398965, -8.175949492758807],
              [-40.00477020760696, -8.175949521195792],
              [-40.004770216909179, -8.175949549602304],
              [-40.00477022630556, -8.175949577978042],
              [-40.004770235795959, -8.175949606322714],
              [-40.004770245380286, -8.175949634635977],
              [-40.004770255058475, -8.175949662917533],
              [-40.00477026483037, -8.175949691167069],
              [-40.004770274695879, -8.175949719384297],
              [-40.00477028465491, -8.175949747568868],
              [-40.004770294707342, -8.175949775720555],
              [-40.004770304853061, -8.175949803838959],
              [-40.00477031509196, -8.175949831923827],
              [-40.004770325423927, -8.175949859974825],
              [-40.004770335848853, -8.175949887991662],
              [-40.004770346366627, -8.175949915974019],
              [-40.004770356977126, -8.175949943921616],
              [-40.004770367680237, -8.175949971834106],
              [-40.004770378475847, -8.175949999711207],
              [-40.00477038936382, -8.17595002755264],
              [-40.004770400344064, -8.175950055358092],
              [-40.004770411416452, -8.175950083127219],
              [-40.004770422580854, -8.175950110859779],
              [-40.004770433837145, -8.1759501385554],
              [-40.004770445185223, -8.175950166213838],
              [-40.004770456624939, -8.175950193834764],
              [-40.004770468156188, -8.175950221417862],
              [-40.004770479778827, -8.175950248962865],
              [-40.004770491492742, -8.175950276469484],
              [-40.004770503297792, -8.175950303937363],
              [-40.004770515193883, -8.175950331366261],
              [-40.004770527180838, -8.175950358755845],
              [-40.004770539258551, -8.17595038610582],
              [-40.004770551426887, -8.175950413415894],
              [-40.004770563685717, -8.175950440685719],
              [-40.004770576034893, -8.175950467915058],
              [-40.004770588474308, -8.175950495103628],
              [-40.004770603712991, -8.175950493204589],
              [-40.005118792377104, -8.176708162212453],
              [-40.005118767565712, -8.176708182277949],
              [-40.005118795919039, -8.176708244600437],
              [-40.005118823798313, -8.176708307134875],
              [-40.005118851201928, -8.176708369877693],
              [-40.005118878128357, -8.176708432825313],
              [-40.005118904576008, -8.176708495974083],
              [-40.005118930543411, -8.176708559320442],
              [-40.005118956029051, -8.176708622860728],
              [-40.005118981031472, -8.176708686591311],
              [-40.005119005549268, -8.176708750508546],
              [-40.005119029581003, -8.176708814608789],
              [-40.00511905312532, -8.17670887888834],
              [-40.005119076180868, -8.176708943343531],
              [-40.00511909874632, -8.176709007970686],
              [-40.005119120820396, -8.176709072766098],
              [-40.005119142401824, -8.176709137726069],
              [-40.005119163489383, -8.176709202846853],
              [-40.005119184081835, -8.176709268124707],
              [-40.00511920417803, -8.176709333555952],
              [-40.005119223776802, -8.176709399136811],
              [-40.005119242877043, -8.176709464863567],
              [-40.005119261477638, -8.176709530732397],
              [-40.005119279577563, -8.176709596739567],
              [-40.005119297175739, -8.176709662881285],
              [-40.00511931427117, -8.176709729153764],
              [-40.00511933086289, -8.176709795553235],
              [-40.00511934694994, -8.17670986207589],
              [-40.00511936253141, -8.176709928717898],
              [-40.00511937760637, -8.176709995475443],
              [-40.005119392174009, -8.176710062344727],
              [-40.005119406233462, -8.176710129321894],
              [-40.005119419783931, -8.176710196403137],
              [-40.00511943282465, -8.176710263584617],
              [-40.005119445354865, -8.17671033086248],
              [-40.005119457373851, -8.176710398232869],
              [-40.00511946888092, -8.176710465691924],
              [-40.005119479875432, -8.176710533235816],
              [-40.005119490356741, -8.176710600860645],
              [-40.005119500324241, -8.17671066856254],
              [-40.005119509777387, -8.176710736337649],
              [-40.005119518715638, -8.176710804182079],
              [-40.005119527138433, -8.176710872091942],
              [-40.005119535045345, -8.176710940063359],
              [-40.005119542435899, -8.176711008092411],
              [-40.005119549309669, -8.176711076175238],
              [-40.005119555666269, -8.176711144307957],
              [-40.005119561505332, -8.176711212486634],
              [-40.005119566826522, -8.17671128070735],
              [-40.005119571629535, -8.176711348966261],
              [-40.005119575914108, -8.176711417259416],
              [-40.005119579679977, -8.176711485582921],
              [-40.00511958292693, -8.176711553932829],
              [-40.005119585654796, -8.176711622305271],
              [-40.005119587863398, -8.176711690696306],
              [-40.005119589552635, -8.17671175910206],
              [-40.00511959072238, -8.17671182751857],
              [-40.005119591372591, -8.176711895941949],
              [-40.00511959150321, -8.176711964368275],
              [-40.005119591114259, -8.176712032793613],
              [-40.005119590205723, -8.176712101214079],
              [-40.005119588777681, -8.176712169625715],
              [-40.005119586830226, -8.176712238024656],
              [-40.00511958436342, -8.176712306406932],
              [-40.00511958137745, -8.176712374768682],
              [-40.005119577872478, -8.17671244310595],
              [-40.005119573848695, -8.17671251141484],
              [-40.005119569306309, -8.176712579691463],
              [-40.005119564245632, -8.176712647931895],
              [-40.005119558666919, -8.176712716132203],
              [-40.005119552570505, -8.176712784288535],
              [-40.005119545956724, -8.176712852396911],
              [-40.005119538825966, -8.176712920453491],
              [-40.005119531178643, -8.176712988454417],
              [-40.005119523015182, -8.176713056395677],
              [-40.005119514336037, -8.17671312427348],
              [-40.005119505141728, -8.176713192083934],
              [-40.005119495432773, -8.176713259823106],
              [-40.005119485209732, -8.176713327487168],
              [-40.005119474473197, -8.176713395072232],
              [-40.005119463223764, -8.176713462574426],
              [-40.005119451462093, -8.176713529989888],
              [-40.005119439188832, -8.176713597314761],
              [-40.005119426404711, -8.17671366454517],
              [-40.005119413110464, -8.1767137316773],
              [-40.005119399306835, -8.176713798707269],
              [-40.0051193849946, -8.176713865631292],
              [-40.005119370174626, -8.176713932445509],
              [-40.005119354847722, -8.176713999146081],
              [-40.005119339014783, -8.176714065729211],
              [-40.005119322676698, -8.17671413219108],
              [-40.005119305834413, -8.176714198527876],
              [-40.005119288488899, -8.176714264735818],
              [-40.005119270641138, -8.176714330811118],
              [-40.005119252292147, -8.176714396749981],
              [-40.005119233443004, -8.176714462548647],
              [-40.005119214094762, -8.176714528203327],
              [-40.005119194248529, -8.176714593710274],
              [-40.005119173905449, -8.176714659065732],
              [-40.005119153066687, -8.176714724265972],
              [-40.005119131733679, -8.176714789306564],
              [-40.005119171993123, -8.176714763511573],
              [-40.005053855211976, -8.176911365230696],
              [-40.005053827660547, -8.176911368077773],
              [-40.005053782253711, -8.176911501279969],
              [-40.005053734773, -8.17691163376235],
              [-40.005053685229939, -8.176911765492903],
              [-40.005053633636471, -8.176911896439721],
              [-40.005053580005089, -8.17691202657114],
              [-40.00505352434876, -8.176912155855653],
              [-40.005053466680977, -8.176912284262],
              [-40.005053407015666, -8.176912411759099],
              [-40.005053345367287, -8.176912538316133],
              [-40.005053281750719, -8.176912663902474],
              [-40.005053216181388, -8.176912788487741],
              [-40.005053148675138, -8.176912912041763],
              [-40.005053079248313, -8.176913034534662],
              [-40.005053007917709, -8.176913155936814],
              [-40.005052934700579, -8.176913276218833],
              [-40.005052859614629, -8.176913395351649],
              [-40.005052782678035, -8.17691351330639],
              [-40.005052703909399, -8.176913630054575],
              [-40.005052623327813, -8.176913745567891],
              [-40.005052540952718, -8.176913859818439],
              [-40.005052456804094, -8.176913972778562],
              [-40.005052370902277, -8.17691408442092],
              [-40.00505228326805, -8.176914194718508],
              [-40.005052193922623, -8.176914303644685],
              [-40.005052102887589, -8.176914411173057],
              [-40.005052010185011, -8.176914517277604],
              [-40.005051915837285, -8.176914621932665],
              [-40.005051819867248, -8.176914725112937],
              [-40.00505172229812, -8.176914826793405],
              [-40.005051623153506, -8.176914926949555],
              [-40.005051522457393, -8.176915025557085],
              [-40.005051420234146, -8.176915122592112],
              [-40.005051316508499, -8.176915218031247],
              [-40.005051211305528, -8.17691531185134],
              [-40.005051104650718, -8.176915404029737],
              [-40.005050996569835, -8.176915494544085],
              [-40.005050887089062, -8.17691558337253],
              [-40.005050776234874, -8.176915670493544],
              [-40.005050664034073, -8.176915755886066],
              [-40.005050550513822, -8.176915839529446],
              [-40.005050435701598, -8.17691592140344],
              [-40.005050319625163, -8.176916001488205],
              [-40.005050202312589, -8.176916079764425],
              [-40.005050083792277, -8.176916156213146],
              [-40.00504996409289, -8.176916230815863],
              [-40.005049843243405, -8.176916303554464],
              [-40.005049721273046, -8.176916374411471],
              [-40.005049598211322, -8.176916443369651],
              [-40.005049474088011, -8.176916510412367],
              [-40.005049348933142, -8.176916575523371],
              [-40.005049222776989, -8.176916638686924],
              [-40.005049095650087, -8.176916699887748],
              [-40.005048967583171, -8.176916759111036],
              [-40.005048838607252, -8.176916816342453],
              [-40.005048708753513, -8.176916871568162],
              [-40.005048578053383, -8.176916924774787],
              [-40.005048446538481, -8.176916975949451],
              [-40.005048314240618, -8.176917025079799],
              [-40.005048181191817, -8.176917072153897],
              [-40.005048047424246, -8.176917117160404],
              [-40.005047912970277, -8.176917160088408],
              [-40.005047777862451, -8.176917200927527],
              [-40.005047642133441, -8.176917239667899],
              [-40.005047505816108, -8.176917276300125],
              [-40.005047368943409, -8.17691731081535],
              [-40.005047231548453, -8.176917343205245],
              [-40.005047093664508, -8.176917373461935],
              [-40.005046955324914, -8.176917401578127],
              [-40.005046816563144, -8.176917427546993],
              [-40.005046677412771, -8.176917451362309],
              [-40.005046537907461, -8.176917473018237],
              [-40.005046398080964, -8.176917492509565],
              [-40.005046257967116, -8.17691750983162],
              [-40.005046117599811, -8.176917524980162],
              [-40.005045977013005, -8.17691753795155],
              [-40.005045836240711, -8.176917548742628],
              [-40.005045695316994, -8.176917557350777],
              [-40.005045554275959, -8.176917563773968],
              [-40.005045413151706, -8.176917568010589],
              [-40.005045271978382, -8.176917570059622],
              [-40.005045130790158, -8.176917569920597],
              [-40.005044989621183, -8.176917567593549],
              [-40.005044848505598, -8.176917563079032],
              [-40.00504470747758, -8.17691755637812],
              [-40.005044566571215, -8.176917547492474],
              [-40.0050444258206, -8.176917536424204],
              [-40.005044285259793, -8.176917523176012],
              [-40.005044144922827, -8.176917507751057],
              [-40.005044004843597, -8.176917490153141],
              [-40.005043865056031, -8.176917470386487],
              [-40.005043725593964, -8.176917448455857],
              [-40.005043586491105, -8.176917424366597],
              [-40.005043447781127, -8.176917398124484],
              [-40.005043309497566, -8.176917369735925],
              [-40.005043171673904, -8.176917339207773],
              [-40.005043034343466, -8.17691730654739],
              [-40.005042897539496, -8.176917271762679],
              [-40.00504276129508, -8.176917234862081],
              [-40.005042625642957, -8.176917195854802],
              [-40.00504261414612, -8.176917181443763],
              [-40.004355784353812, -8.176713947406174],
              [-40.004355760326554, -8.176713903678907],
              [-40.004355721128107, -8.17671389198142],
              [-40.004355681983007, -8.176713880107961],
              [-40.004355642891994, -8.176713868058817],
              [-40.004355603855899, -8.176713855834201],
              [-40.00435556487551, -8.176713843434367],
              [-40.004355525951638, -8.176713830859597],
              [-40.004355487085029, -8.176713818110112],
              [-40.004355448276506, -8.176713805186212],
              [-40.004355409526845, -8.176713792088108],
              [-40.004355370836841, -8.176713778816076],
              [-40.004355332207282, -8.17671376537043],
              [-40.004355293638937, -8.176713751751404],
              [-40.004355255132623, -8.176713737959282],
              [-40.004355216689071, -8.176713723994364],
              [-40.004355178309112, -8.176713709856907],
              [-40.004355139993493, -8.176713695547189],
              [-40.004355101743009, -8.17671368106555],
              [-40.004355063558442, -8.176713666412251],
              [-40.004355025440553, -8.176713651587566],
              [-40.00435498739013, -8.176713636591844],
              [-40.004354949407926, -8.176713621425366],
              [-40.00435491149473, -8.176713606088443],
              [-40.004354873651316, -8.176713590581384],
              [-40.004354835878445, -8.176713574904511],
              [-40.004354798176877, -8.176713559058125],
              [-40.004354760547386, -8.176713543042579],
              [-40.004354722990747, -8.176713526858187],
              [-40.004354685507707, -8.176713510505255],
              [-40.004354648099046, -8.176713493984129],
              [-40.004354610765489, -8.176713477295154],
              [-40.004354573507833, -8.176713460438673],
              [-40.004354536326808, -8.176713443415006],
              [-40.00435449922319, -8.176713426224525],
              [-40.004354462197732, -8.176713408867545],
              [-40.004354425251165, -8.176713391344469],
              [-40.004354388384264, -8.176713373655613],
              [-40.004354351597769, -8.176713355801356],
              [-40.00435431489241, -8.176713337782036],
              [-40.004354278268956, -8.176713319598029],
              [-40.004354241728166, -8.176713301249741],
              [-40.004354205270751, -8.176713282737477],
              [-40.004354168897436, -8.176713264061673],
              [-40.004354132608995, -8.17671324522267],
              [-40.004354096406182, -8.176713226220874],
              [-40.004354060289693, -8.176713207056684],
              [-40.004354024260273, -8.176713187730442],
              [-40.004353988318655, -8.176713168242582],
              [-40.004353952465578, -8.176713148593482],
              [-40.004353916701767, -8.176713128783572],
              [-40.004353881027932, -8.176713108813194],
              [-40.004353845444832, -8.176713088682812],
              [-40.004353809953159, -8.176713068392804],
              [-40.00435377455365, -8.17671304794359],
              [-40.004353739247009, -8.176713027335573],
              [-40.004353704033974, -8.176713006569191],
              [-40.004353668915236, -8.176712985644881],
              [-40.00435363389154, -8.176712964563006],
              [-40.004353598963583, -8.176712943324061],
              [-40.00435356413206, -8.176712921928461],
              [-40.004353529397704, -8.176712900376605],
              [-40.00435349476119, -8.176712878668971],
              [-40.004353460223264, -8.176712856805969],
              [-40.004353425784601, -8.176712834788059],
              [-40.004353391445889, -8.176712812615728],
              [-40.004353357207862, -8.176712790289358],
              [-40.004353323071165, -8.176712767809438],
              [-40.004353289036537, -8.176712745176456],
              [-40.004353255104661, -8.176712722390777],
              [-40.004353221276205, -8.176712699452958],
              [-40.004353187551885, -8.176712676363419],
              [-40.004353153932364, -8.176712653122644],
              [-40.004353120418344, -8.176712629731105],
              [-40.004353087010479, -8.17671260618927],
              [-40.004353053709472, -8.176712582497615],
              [-40.004353020516, -8.176712558656616],
              [-40.004352987430714, -8.176712534666787],
              [-40.004352954454326, -8.176712510528597],
              [-40.004352921587461, -8.176712486242545],
              [-40.004352888830823, -8.176712461809117],
              [-40.004352856185051, -8.17671243722879],
              [-40.004352823650819, -8.176712412502091],
              [-40.004352791228797, -8.176712387629504],
              [-40.00435275891963, -8.176712362611555],
              [-40.004352726723987, -8.17671233744875],
              [-40.00435269464252, -8.17671231214158],
              [-40.004352662675863, -8.176712286690579],
              [-40.004352630824684, -8.176712261096236],
              [-40.004352599089636, -8.176712235359119],
              [-40.004352567471344, -8.176712209479717],
              [-40.004352535970462, -8.176712183458569],
              [-40.004352504587636, -8.17671215729621],
              [-40.004352473323486, -8.176712130993144],
              [-40.004352442178657, -8.17671210454993],
              [-40.004352411153782, -8.176712077967078],
              [-40.0043523802495, -8.17671205124517],
              [-40.004352349466423, -8.176712024384704],
              [-40.004352318805196, -8.176711997386251],
              [-40.004352288266425, -8.176711970250352],
              [-40.004352257850584, -8.176711942978441],
              [-40.004352239320596, -8.176711966495317],
              [-40.003592407085051, -8.176027581550134],
              [-40.003592437934302, -8.176027540186215],
              [-40.003592330030912, -8.176027444535942],
              [-40.003592220613783, -8.176027350608363],
              [-40.003592109710645, -8.17602725842726],
              [-40.003591997349552, -8.17602716801593],
              [-40.00359188355894, -8.176027079397294],
              [-40.00359176836762, -8.176026992593789],
              [-40.003591651804754, -8.176026907627364],
              [-40.003591533899872, -8.176026824519555],
              [-40.003591414682816, -8.176026743291411],
              [-40.003591294183735, -8.176026663963459],
              [-40.003591172433175, -8.176026586555798],
              [-40.003591049461924, -8.176026511088033],
              [-40.003590925301154, -8.176026437579266],
              [-40.00359079998227, -8.176026366048093],
              [-40.003590673537005, -8.176026296512655],
              [-40.003590545997355, -8.176026228990501],
              [-40.003590417395635, -8.176026163498792],
              [-40.003590287764389, -8.176026100054051],
              [-40.003590157136429, -8.176026038672372],
              [-40.003590025544838, -8.176025979369308],
              [-40.003589893022919, -8.176025922159834],
              [-40.003589759604225, -8.176025867058456],
              [-40.003589625322533, -8.176025814079107],
              [-40.003589490211851, -8.176025763235209],
              [-40.003589354306357, -8.176025714539636],
              [-40.00358921764046, -8.176025668004719],
              [-40.003589080248787, -8.176025623642227],
              [-40.003588942166111, -8.176025581463415],
              [-40.003588803427355, -8.176025541478943],
              [-40.003588664067685, -8.176025503698929],
              [-40.003588524122371, -8.176025468132927],
              [-40.003588383626827, -8.176025434789977],
              [-40.00358824261663, -8.176025403678496],
              [-40.003588101127477, -8.176025374806368],
              [-40.003587959195194, -8.176025348180911],
              [-40.003587816855706, -8.176025323808847],
              [-40.003587674145052, -8.176025301696335],
              [-40.003587531099356, -8.17602528184899],
              [-40.003587387754834, -8.176025264271853],
              [-40.003587244147781, -8.176025248969358],
              [-40.003587100314547, -8.17602523594538],
              [-40.003586956291542, -8.176025225203205],
              [-40.003586812115238, -8.176025216745582],
              [-40.003586667822127, -8.176025210574625],
              [-40.003586523448732, -8.176025206691907],
              [-40.003586379031617, -8.176025205098403],
              [-40.00358623460734, -8.176025205794529],
              [-40.003586090212458, -8.176025208780072],
              [-40.003585945883515, -8.176025214054317],
              [-40.003585801657088, -8.176025221615911],
              [-40.003585657569644, -8.176025231462965],
              [-40.00358551365769, -8.176025243592962],
              [-40.003585369957641, -8.176025258002824],
              [-40.003585226505898, -8.176025274688927],
              [-40.003585083338763, -8.176025293647035],
              [-40.003584940492487, -8.176025314872348],
              [-40.003584798003217, -8.176025338359478],
              [-40.003584655907041, -8.176025364102523],
              [-40.003584514239925, -8.1760253920949],
              [-40.003584373037747, -8.176025422329571],
              [-40.003584232336244, -8.176025454798891],
              [-40.003584092171032, -8.176025489494592],
              [-40.003583952577607, -8.176025526407932],
              [-40.003583813591298, -8.176025565529541],
              [-40.003583675247292, -8.17602560684953],
              [-40.003583537580617, -8.176025650357447],
              [-40.003583400626134, -8.176025696042259],
              [-40.003583264418502, -8.176025743892392],
              [-40.00358312899219, -8.176025793895759],
              [-40.003582994381517, -8.176025846039702],
              [-40.00358286062054, -8.176025900310981],
              [-40.003582727743122, -8.176025956695881],
              [-40.003582595782895, -8.176026015180144],
              [-40.003582464773295, -8.176026075748936],
              [-40.003582334747463, -8.176026138386938],
              [-40.003582205738326, -8.176026203078306],
              [-40.003582077778532, -8.176026269806631],
              [-40.003581950900511, -8.176026338555026],
              [-40.003581825136337, -8.176026409306104],
              [-40.003581700517877, -8.176026482041964],
              [-40.00358157707668, -8.176026556744137],
              [-40.003581454843982, -8.176026633393777],
              [-40.003581333850747, -8.176026711971446],
              [-40.003581214127578, -8.176026792457263],
              [-40.003581095704803, -8.176026874830816],
              [-40.003580978612405, -8.176026959071311],
              [-40.003580862880028, -8.176027045157365],
              [-40.003580748536962, -8.17602713306721],
              [-40.00358063561216, -8.176027222778599],
              [-40.0035805241342, -8.176027314268808],
              [-40.003580414131314, -8.176027407514683],
              [-40.003580305631367, -8.176027502492591],
              [-40.003580198661787, -8.176027599178509],
              [-40.003580093249681, -8.176027697547996],
              [-40.003579989421723, -8.176027797576079],
              [-40.003579887204218, -8.176027899237496],
              [-40.003579786623021, -8.176028002506445],
              [-40.00357968770362, -8.176028107356835],
              [-40.003579590471041, -8.176028213762098],
              [-40.003579610028957, -8.176028246405977],
              [-40.002960546773878, -8.176716597103248],
              [-40.002960184595253, -8.176716959768687],
              [-40.002336420553249, -8.177341198196933],
              [-40.002336393250509, -8.177341226412564],
              [-40.002336363325718, -8.177341256500659],
              [-40.002336333542686, -8.177341286728129],
              [-40.002336303902069, -8.177341317094223],
              [-40.002336274404513, -8.177341347598301],
              [-40.002336245050678, -8.177341378239706],
              [-40.002336215841204, -8.17734140901776],
              [-40.002336186776724, -8.177341439931777],
              [-40.002336157857876, -8.177341470981109],
              [-40.002336129085293, -8.177341502165046],
              [-40.002336100459615, -8.177341533482917],
              [-40.00233607198146, -8.177341564933998],
              [-40.002336043651468, -8.177341596517637],
              [-40.002336015470242, -8.177341628233169],
              [-40.002335987438414, -8.177341660079827],
              [-40.002335959556596, -8.177341692056959],
              [-40.002335931825385, -8.177341724163854],
              [-40.002335904245413, -8.177341756399819],
              [-40.002335876817277, -8.177341788764108],
              [-40.002335849541566, -8.177341821256052],
              [-40.002335822418878, -8.177341853874921],
              [-40.002335795449831, -8.177341886619974],
              [-40.002335768635014, -8.177341919490548],
              [-40.002335741974974, -8.177341952485907],
              [-40.002335715470338, -8.177341985605324],
              [-40.002335689121693, -8.177342018848018],
              [-40.002335662929582, -8.177342052213318],
              [-40.002335636894607, -8.177342085700497],
              [-40.002335611017337, -8.177342119308795],
              [-40.002335585298312, -8.177342153037486],
              [-40.002335559738107, -8.177342186885841],
              [-40.00233553433732, -8.177342220853076],
              [-40.002335509096469, -8.177342254938493],
              [-40.002335484016122, -8.177342289141317],
              [-40.002335459096813, -8.177342323460783],
              [-40.002335434339116, -8.177342357896206],
              [-40.002335409743544, -8.177342392446775],
              [-40.002335385310666, -8.17734242711173],
              [-40.002335361040998, -8.177342461890293],
              [-40.002335336935083, -8.177342496781757],
              [-40.002335312993431, -8.177342531785364],
              [-40.002335289216596, -8.177342566900247],
              [-40.002335265605083, -8.177342602125737],
              [-40.002335242159404, -8.177342637461008],
              [-40.002335218880091, -8.177342672905313],
              [-40.002335195767643, -8.177342708457859],
              [-40.002335172822562, -8.17734274411791],
              [-40.002335150045369, -8.177342779884597],
              [-40.002335127436538, -8.177342815757202],
              [-40.002335104996583, -8.177342851734927],
              [-40.002335082726006, -8.177342887816982],
              [-40.002335060625263, -8.17734292400257],
              [-40.002335038694881, -8.177342960290886],
              [-40.002335016935298, -8.177342996681118],
              [-40.00233499534702, -8.177343033172505],
              [-40.002334973930509, -8.177343069764243],
              [-40.002334952686248, -8.177343106455547],
              [-40.002334931614676, -8.177343143245549],
              [-40.0023349107163, -8.177343180133482],
              [-40.002334889991516, -8.177343217118555],
              [-40.00233486944083, -8.177343254199929],
              [-40.002334849064681, -8.177343291376777],
              [-40.002334828863518, -8.177343328648334],
              [-40.002334808837759, -8.177343366013746],
              [-40.002334788987866, -8.177343403472197],
              [-40.00233476931426, -8.17734344102287],
              [-40.002334749817408, -8.177343478664923],
              [-40.002334730497701, -8.177343516397565],
              [-40.002334711355559, -8.177343554219936],
              [-40.002334692391429, -8.177343592131239],
              [-40.002334673605716, -8.177343630130625],
              [-40.002334654998833, -8.177343668217231],
              [-40.002334636571184, -8.177343706390253],
              [-40.002334618323182, -8.177343744648864],
              [-40.002334600255203, -8.177343782992198],
              [-40.002334582367681, -8.177343821419452],
              [-40.00233456466097, -8.177343859929744],
              [-40.002334547135483, -8.177343898522221],
              [-40.002334529791604, -8.177343937196085],
              [-40.002334512629702, -8.177343975950457],
              [-40.002334495650175, -8.177344014784513],
              [-40.002334478853342, -8.177344053697365],
              [-40.002334462239638, -8.177344092688157],
              [-40.002334445809396, -8.177344131756058],
              [-40.002334429562978, -8.177344170900216],
              [-40.002334413500741, -8.177344210119745],
              [-40.002334397623031, -8.177344249413794],
              [-40.002334381930204, -8.177344288781528],
              [-40.002334366422609, -8.177344328222034],
              [-40.002334351100579, -8.177344367734516],
              [-40.002334335964449, -8.177344407318026],
              [-40.002334321014558, -8.177344446971766],
              [-40.002334306251228, -8.177344486694818],
              [-40.002334291674778, -8.177344526486344],
              [-40.002334277285541, -8.17734456634544],
              [-40.002334263083824, -8.177344606271275],
              [-40.002334249069939, -8.177344646262913],
              [-40.002334235244199, -8.177344686319534],
              [-40.002334221606894, -8.177344726440205],
              [-40.002334208158331, -8.17734476662411],
              [-40.002334250862781, -8.177344730804908],
              [-40.002129107055097, -8.177962504395765],
              [-40.002129073815844, -8.177962517882436],
              [-40.002129043663174, -8.177962610349166],
              [-40.002129014509904, -8.177962703133486],
              [-40.002128986359367, -8.177962796224699],
              [-40.002128959214822, -8.177962889612045],
              [-40.002128933079405, -8.177962983284763],
              [-40.002128907956148, -8.177963077231965],
              [-40.002128883847938, -8.177963171442849],
              [-40.002128860757573, -8.177963265906513],
              [-40.002128838687717, -8.177963360612017],
              [-40.00212881764093, -8.177963455548454],
              [-40.002128797619633, -8.177963550704812],
              [-40.00212877862613, -8.177963646070129],
              [-40.002128760662629, -8.177963741633377],
              [-40.00212874373122, -8.1779638373835],
              [-40.002128727833835, -8.177963933309437],
              [-40.002128712972329, -8.177964029400107],
              [-40.002128699148393, -8.177964125644397],
              [-40.002128686363655, -8.177964222031195],
              [-40.002128674619577, -8.177964318549371],
              [-40.002128663917517, -8.177964415187752],
              [-40.002128654258726, -8.177964511935169],
              [-40.002128645644312, -8.177964608780469],
              [-40.002128638075249, -8.177964705712407],
              [-40.002128631552431, -8.177964802719833],
              [-40.002128626076612, -8.177964899791526],
              [-40.002128621648438, -8.177964996916248],
              [-40.002128618268387, -8.177965094082777],
              [-40.002128615936876, -8.177965191279927],
              [-40.002128614654175, -8.177965288496427],
              [-40.002128614420407, -8.177965385721039],
              [-40.002128615235627, -8.177965482942549],
              [-40.002128617099736, -8.177965580149698],
              [-40.002128620012506, -8.177965677331265],
              [-40.002128623973597, -8.177965774476007],
              [-40.002128628982575, -8.177965871572734],
              [-40.002128635038837, -8.177965968610176],
              [-40.002128642141706, -8.177966065577166],
              [-40.002128650290345, -8.177966162462459],
              [-40.002128659483816, -8.17796625925488],
              [-40.002128669721053, -8.177966355943234],
              [-40.002128681000876, -8.17796645251634],
              [-40.002128693321978, -8.177966548963074],
              [-40.002128706682946, -8.177966645272281],
              [-40.002128721082229, -8.177966741432789],
              [-40.002128736518166, -8.177966837433505],
              [-40.002128752988959, -8.177966933263344],
              [-40.002128770492725, -8.17796702891123],
              [-40.002128789027438, -8.177967124366134],
              [-40.002128808590932, -8.17796721961699],
              [-40.002128829180968, -8.177967314652809],
              [-40.002128850795174, -8.177967409462589],
              [-40.002128873431033, -8.177967504035406],
              [-40.002128897085953, -8.177967598360274],
              [-40.002128921757162, -8.177967692426334],
              [-40.002128947441847, -8.177967786222727],
              [-40.002128974137008, -8.177967879738587],
              [-40.002129001839585, -8.177967972963122],
              [-40.002129030546371, -8.177968065885546],
              [-40.002129060254042, -8.177968158495116],
              [-40.002129090959166, -8.177968250781165],
              [-40.002129122658197, -8.17796834273298],
              [-40.002129155347482, -8.177968434339961],
              [-40.002129189023229, -8.177968525591544],
              [-40.002129223681536, -8.177968616477134],
              [-40.00212925931843, -8.177968706986267],
              [-40.002129295929777, -8.177968797108452],
              [-40.002129333511327, -8.177968886833314],
              [-40.002129372058761, -8.177968976150449],
              [-40.002129411567623, -8.177969065049558],
              [-40.00212945203333, -8.177969153520344],
              [-40.002129493451235, -8.177969241552626],
              [-40.002129535816515, -8.177969329136189],
              [-40.002129579124301, -8.177969416260924],
              [-40.002129623369569, -8.177969502916749],
              [-40.002129668547241, -8.177969589093689],
              [-40.002129714652042, -8.177969674781735],
              [-40.002129761678695, -8.177969759971022],
              [-40.002129809621735, -8.177969844651699],
              [-40.002129858475627, -8.177969928813953],
              [-40.00212990823475, -8.177970012448084],
              [-40.002129958893313, -8.177970095544429],
              [-40.002130010445491, -8.177970178093359],
              [-40.002130062885307, -8.177970260085361],
              [-40.002130116206715, -8.177970341510958],
              [-40.002130170403554, -8.177970422360724],
              [-40.002130225469564, -8.177970502625328],
              [-40.002130281398358, -8.177970582295504],
              [-40.002130338183484, -8.177970661362023],
              [-40.002130395818398, -8.177970739815761],
              [-40.00213045429642, -8.177970817647649],
              [-40.002130513610794, -8.177970894848697],
              [-40.002130573754677, -8.177970971409971],
              [-40.002130634721105, -8.177971047322636],
              [-40.002130696503038, -8.177971122577915],
              [-40.002130759093333, -8.177971197167107],
              [-40.002130822484759, -8.177971271081601],
              [-40.002130886669995, -8.177971344312839],
              [-40.002130951641625, -8.177971416852365],
              [-40.002131017392131, -8.177971488691814],
              [-40.002131035604528, -8.177971453348736],
              [-40.002891850105463, -8.178793852487004],
              [-40.00289182095846, -8.178793823589402],
              [-40.002891892629926, -8.178793901974274],
              [-40.002891963374125, -8.178793981191015],
              [-40.002892033181332, -8.178794061228711],
              [-40.002892102041933, -8.178794142076354],
              [-40.002892169946492, -8.178794223722818],
              [-40.002892236885664, -8.178794306156938],
              [-40.002892302850235, -8.178794389367274],
              [-40.00289236783113, -8.178794473342494],
              [-40.002892431819447, -8.178794558071029],
              [-40.002892494806382, -8.17879464354119],
              [-40.002892556783259, -8.178794729741268],
              [-40.002892617741573, -8.178794816659423],
              [-40.002892677672939, -8.178794904283686],
              [-40.002892736569123, -8.178794992601997],
              [-40.002892794422024, -8.178795081602241],
              [-40.002892851223706, -8.178795171272197],
              [-40.002892906966338, -8.1787952615995],
              [-40.002892961642267, -8.178795352571743],
              [-40.002893015243984, -8.178795444176453],
              [-40.00289306776412, -8.178795536401003],
              [-40.002893119195427, -8.178795629232738],
              [-40.002893169530886, -8.178795722658846],
              [-40.002893218763532, -8.178795816666547],
              [-40.002893266886637, -8.178795911242927],
              [-40.002893313893544, -8.178796006374917],
              [-40.002893359777829, -8.178796102049485],
              [-40.002893404533161, -8.178796198253497],
              [-40.002893448153394, -8.178796294973706],
              [-40.002893490632545, -8.178796392196789],
              [-40.002893531964745, -8.178796489909447],
              [-40.002893572144352, -8.178796588098191],
              [-40.002893611165803, -8.178796686749548],
              [-40.002893649023761, -8.178796785849947],
              [-40.002893685713019, -8.178796885385765],
              [-40.002893721228517, -8.178796985343336],
              [-40.002893755565395, -8.178797085708949],
              [-40.002893788718922, -8.178797186468723],
              [-40.002893820684534, -8.178797287608853],
              [-40.002893851457848, -8.178797389115443],
              [-40.00289388103463, -8.178797490974539],
              [-40.00289390941083, -8.178797593172138],
              [-40.002893936582524, -8.178797695694197],
              [-40.002893962545969, -8.178797798526608],
              [-40.002893987297625, -8.178797901655233],
              [-40.002894010834083, -8.178798005065897],
              [-40.002894033152096, -8.178798108744413],
              [-40.002894054248614, -8.178798212676499],
              [-40.002894074120704, -8.178798316847903],
              [-40.002894092765658, -8.178798421244267],
              [-40.002894110180904, -8.178798525851274],
              [-40.00289412636409, -8.178798630654514],
              [-40.002894141312929, -8.178798735639585],
              [-40.002894155025395, -8.178798840792073],
              [-40.002894167499612, -8.178798946097496],
              [-40.002894178733847, -8.178799051541407],
              [-40.002894188726565, -8.17879915710933],
              [-40.002894197476387, -8.178799262786692],
              [-40.00289420498212, -8.17879936855903],
              [-40.002894211242719, -8.178799474411772],
              [-40.002894216257346, -8.178799580330349],
              [-40.002894220025283, -8.178799686300271],
              [-40.002894222546018, -8.178799792306911],
              [-40.002894223819226, -8.178799898335683],
              [-40.002894223844713, -8.178800004372066],
              [-40.002894222622487, -8.178800110401436],
              [-40.002894220152697, -8.178800216409275],
              [-40.002894216435706, -8.178800322380956],
              [-40.002894211472011, -8.178800428301908],
              [-40.002894205262301, -8.178800534157659],
              [-40.002894197807414, -8.178800639933547],
              [-40.002894189108403, -8.17880074561509],
              [-40.002894179166439, -8.178800851187736],
              [-40.002894167982902, -8.178800956637021],
              [-40.002894155559311, -8.178801061948411],
              [-40.002894141897393, -8.178801167107405],
              [-40.002894126999024, -8.178801272099625],
              [-40.002894110866244, -8.178801376910579],
              [-40.002894093501276, -8.178801481525873],
              [-40.0028940749065, -8.17880158593112],
              [-40.002894055084489, -8.178801690111971],
              [-40.002894034037951, -8.178801794054104],
              [-40.002894011769783, -8.178801897743259],
              [-40.002893988283056, -8.178802001165163],
              [-40.002893963580966, -8.178802104305602],
              [-40.002893937666961, -8.178802207150371],
              [-40.002893910544564, -8.178802309685349],
              [-40.002893882217506, -8.178802411896481],
              [-40.002893852689695, -8.178802513769689],
              [-40.002893821965181, -8.178802615290957],
              [-40.002893790048191, -8.178802716446345],
              [-40.002893756943109, -8.178802817221934],
              [-40.002893722654498, -8.178802917603869],
              [-40.002893687187054, -8.178803017578399],
              [-40.002893650545651, -8.178803117131727],
              [-40.002893612735335, -8.178803216250174],
              [-40.002893573761305, -8.178803314920122],
              [-40.002893533628921, -8.178803413128026],
              [-40.002893492343695, -8.178803510860366],
              [-40.002893449911049, -8.178803608104204],
              [-40.002893417627568, -8.178803616304901],
              [-40.00261956015607, -8.179421470146618],
              [-40.002619526359304, -8.179421433885567],
              [-40.002619511015574, -8.179421468691135],
              [-40.002619495818784, -8.179421503560659],
              [-40.002619480769248, -8.179421538493543],
              [-40.002619465867184, -8.179421573489099],
              [-40.002619451112871, -8.179421608546773],
              [-40.002619436506585, -8.179421643665934],
              [-40.002619422048575, -8.179421678845952],
              [-40.002619407739083, -8.179421714086205],
              [-40.002619393578378, -8.179421749386064],
              [-40.00261937956671, -8.179421784744923],
              [-40.002619365704312, -8.179421820162117],
              [-40.002619351991441, -8.179421855637075],
              [-40.002619338428339, -8.179421891169142],
              [-40.00261932501526, -8.179421926757673],
              [-40.002619311752397, -8.179421962402099],
              [-40.002619298640028, -8.179421998101724],
              [-40.002619285678371, -8.179422033855946],
              [-40.002619272867634, -8.179422069664154],
              [-40.002619260208078, -8.179422105525658],
              [-40.002619247699911, -8.179422141439886],
              [-40.002619235343346, -8.179422177406165],
              [-40.002619223138609, -8.179422213423868],
              [-40.002619211085914, -8.179422249492402],
              [-40.002619199185474, -8.179422285611034],
              [-40.002619187437517, -8.179422321779215],
              [-40.002619175842206, -8.179422357996259],
              [-40.002619164399789, -8.179422394261527],
              [-40.002619153110452, -8.179422430574409],
              [-40.002619141974407, -8.179422466934264],
              [-40.002619130991818, -8.179422503340417],
              [-40.002619120162905, -8.179422539792247],
              [-40.00261910948786, -8.179422576289113],
              [-40.002619098966854, -8.17942261283034],
              [-40.002619088600106, -8.179422649415322],
              [-40.002619078387767, -8.179422686043381],
              [-40.002619068330027, -8.179422722713896],
              [-40.002619058427065, -8.179422759426195],
              [-40.002619048679058, -8.179422796179633],
              [-40.00261903908617, -8.179422832973572],
              [-40.002619029648585, -8.17942286980737],
              [-40.002619020366446, -8.179422906680356],
              [-40.00261901123995, -8.179422943591884],
              [-40.002619002269228, -8.179422980541315],
              [-40.002618993454455, -8.179423017527963],
              [-40.002618984795795, -8.179423054551231],
              [-40.00261897629337, -8.179423091610401],
              [-40.002618967947363, -8.179423128704865],
              [-40.002618959757896, -8.179423165833946],
              [-40.002618951725132, -8.179423202997016],
              [-40.002618943849193, -8.17942324019339],
              [-40.00261893613024, -8.179423277422414],
              [-40.00261892856841, -8.17942331468344],
              [-40.002618921163815, -8.179423351975785],
              [-40.002618913916599, -8.179423389298814],
              [-40.002618906826896, -8.17942342665186],
              [-40.002618899894813, -8.179423464034267],
              [-40.002618893120484, -8.179423501445392],
              [-40.002618886504045, -8.179423538884544],
              [-40.002618880045567, -8.179423576351052],
              [-40.002618873745206, -8.179423613844305],
              [-40.002618867603076, -8.179423651363598],
              [-40.002618861619254, -8.179423688908287],
              [-40.00261885579387, -8.179423726477699],
              [-40.002618850127007, -8.179423764071165],
              [-40.00261884461878, -8.17942380168804],
              [-40.002618839269303, -8.179423839327649],
              [-40.002618834078618, -8.179423876989322],
              [-40.002618829046867, -8.179423914672396],
              [-40.002618824174114, -8.179423952376224],
              [-40.002618819460459, -8.179423990100105],
              [-40.002618814905979, -8.179424027843417],
              [-40.002618810510747, -8.179424065605472],
              [-40.002618806274846, -8.17942410338558],
              [-40.002618802198349, -8.179424141183096],
              [-40.002618798281347, -8.179424178997349],
              [-40.002618794523876, -8.179424216827682],
              [-40.002618790926029, -8.179424254673403],
              [-40.002618787487862, -8.179424292533859],
              [-40.002618784209425, -8.179424330408366],
              [-40.002618781090789, -8.179424368296282],
              [-40.00261877813201, -8.179424406196922],
              [-40.00261877533314, -8.179424444109644],
              [-40.002618772694213, -8.179424482033731],
              [-40.002618770215292, -8.179424519968521],
              [-40.002618767896408, -8.179424557913359],
              [-40.002618765737616, -8.179424595867587],
              [-40.002618763738937, -8.179424633830498],
              [-40.002618761900443, -8.179424671801469],
              [-40.002618760222113, -8.179424709779793],
              [-40.00261875870401, -8.179424747764809],
              [-40.002618757346163, -8.179424785755847],
              [-40.002618756148578, -8.179424823752234],
              [-40.002618755111293, -8.179424861753326],
              [-40.002618754234305, -8.17942489975842],
              [-40.002618753517659, -8.179424937766816],
              [-40.002618752961347, -8.179424975777884],
              [-40.00261875256539, -8.179425013790944],
              [-40.002618752329781, -8.179425051805342],
              [-40.002618752254527, -8.179425089820388],
              [-40.002618752307434, -8.179425110991353],
              [-40.002619784786312, -8.179838279107186],
              [-40.002619784792103, -8.179838281421342],
              [-40.002619784877588, -8.179838315557074],
              [-40.002619785068589, -8.179838339531788],
              [-40.002619785323347, -8.179838363505867],
              [-40.002619785641855, -8.179838387479217],
              [-40.00261978602412, -8.179838411451668],
              [-40.002619786470127, -8.179838435423001],
              [-40.002619786979885, -8.179838459393064],
              [-40.002619787553392, -8.179838483361703],
              [-40.002619788190628, -8.179838507328736],
              [-40.002619788891614, -8.179838531294013],
              [-40.002619789656315, -8.17983855525738],
              [-40.002619790484736, -8.179838579218583],
              [-40.002619791376894, -8.179838603177556],
              [-40.002619792332752, -8.179838627134096],
              [-40.002619793352316, -8.179838651088016],
              [-40.002619794435581, -8.179838675039171],
              [-40.002619795582525, -8.179838698987369],
              [-40.002619796793176, -8.179838722932445],
              [-40.002619798067485, -8.179838746874282],
              [-40.002619799405458, -8.179838770812623],
              [-40.002619800807089, -8.179838794747372],
              [-40.00261980227237, -8.179838818678322],
              [-40.002619803801295, -8.179838842605339],
              [-40.002619805393834, -8.179838866528225],
              [-40.002619807049982, -8.179838890446828],
              [-40.002619808769737, -8.17983891436096],
              [-40.002619810553085, -8.179838938270482],
              [-40.002619812400013, -8.179838962175234],
              [-40.002619814310485, -8.179838986074996],
              [-40.002619816284536, -8.179839009969642],
              [-40.002619818322117, -8.179839033858995],
              [-40.002619820423213, -8.179839057742885],
              [-40.002619822587832, -8.179839081621125],
              [-40.002619824815945, -8.179839105493578],
              [-40.002619827107495, -8.179839129360067],
              [-40.002619829462553, -8.179839153220431],
              [-40.002619831881049, -8.17983917707449],
              [-40.002619834362946, -8.179839200922057],
              [-40.002619836908273, -8.179839224763015],
              [-40.002619839516996, -8.179839248597158],
              [-40.002619842189084, -8.179839272424363],
              [-40.002619844924524, -8.179839296244399],
              [-40.002619847723324, -8.179839320057118],
              [-40.002619850585404, -8.179839343862385],
              [-40.002619853510808, -8.179839367660014],
              [-40.002619856499493, -8.179839391449823],
              [-40.002619859551409, -8.17983941523169],
              [-40.002619862666585, -8.179839439005386],
              [-40.002619865844942, -8.179839462770767],
              [-40.002619869086502, -8.17983948652769],
              [-40.002619872391236, -8.179839510275947],
              [-40.002619875759102, -8.179839534015439],
              [-40.002619879190092, -8.179839557745915],
              [-40.002619882684186, -8.17983958146727],
              [-40.002619886241355, -8.179839605179307],
              [-40.002619889861563, -8.179839628881885],
              [-40.002619893544775, -8.179839652574824],
              [-40.002619897291012, -8.179839676257956],
              [-40.002619901100189, -8.179839699931092],
              [-40.002619904972327, -8.179839723594133],
              [-40.00261990890737, -8.179839747246842],
              [-40.002619912905303, -8.179839770889084],
              [-40.002619916966104, -8.179839794520662],
              [-40.002619921089718, -8.17983981814147],
              [-40.002619925276143, -8.179839841751306],
              [-40.002619929525345, -8.179839865349983],
              [-40.002619933837281, -8.179839888937339],
              [-40.002619938211929, -8.179839912513268],
              [-40.002619942649268, -8.179839936077537],
              [-40.002619947149263, -8.17983995963001],
              [-40.002619951711857, -8.179839983170535],
              [-40.00261995633705, -8.179840006698944],
              [-40.002619961024784, -8.179840030215033],
              [-40.002619965775047, -8.179840053718705],
              [-40.002619970587801, -8.179840077209739],
              [-40.002619975462999, -8.179840100687969],
              [-40.002619980400638, -8.179840124153255],
              [-40.002619985400635, -8.179840147605379],
              [-40.002619990463003, -8.179840171044264],
              [-40.002619995587686, -8.179840194469701],
              [-40.002620000774641, -8.179840217881523],
              [-40.002620006023832, -8.17984024127956],
              [-40.002620011335225, -8.179840264663682],
              [-40.002620016708804, -8.179840288033702],
              [-40.002620022144498, -8.179840311389414],
              [-40.002620027642294, -8.17984033473074],
              [-40.002620033202128, -8.179840358057408],
              [-40.002620038823999, -8.179840381369365],
              [-40.002620044507836, -8.179840404666395],
              [-40.002620050253597, -8.179840427948362],
              [-40.002620056061268, -8.17984045121503],
              [-40.002620061930791, -8.179840474466321],
              [-40.002620067862104, -8.179840497701976],
              [-40.002620073855226, -8.179840520921923],
              [-40.002620079910045, -8.17984054412597],
              [-40.002620086026567, -8.179840567313979],
              [-40.002620092204737, -8.179840590485725],
              [-40.002620098444496, -8.179840613641069],
              [-40.002620104745802, -8.179840636779884],
              [-40.002620111108634, -8.179840659901982],
              [-40.002620153839231, -8.179840666128184],
              [-40.002829734342974, -8.18059850003846],
              [-40.002829772545468, -8.180598521766624],
              [-40.002829791421881, -8.180598588985314],
              [-40.002829810818398, -8.180598656056858],
              [-40.002829830733852, -8.180598722977239],
              [-40.002829851167078, -8.180598789742477],
              [-40.002829872116848, -8.18059885634864],
              [-40.002829893581918, -8.180598922791699],
              [-40.002829915561009, -8.180598989067732],
              [-40.002829938052805, -8.180599055172774],
              [-40.002829961055973, -8.180599121102913],
              [-40.002829984569146, -8.180599186854193],
              [-40.002830008590919, -8.180599252422699],
              [-40.002830033119857, -8.180599317804537],
              [-40.002830058154508, -8.180599382995821],
              [-40.002830083693368, -8.180599447992632],
              [-40.002830109734937, -8.180599512791128],
              [-40.002830136277652, -8.18059957738746],
              [-40.002830163319921, -8.18059964177777],
              [-40.002830190860152, -8.180599705958187],
              [-40.002830218896698, -8.180599769924966],
              [-40.002830247427866, -8.180599833674215],
              [-40.002830276451995, -8.18059989720218],
              [-40.002830305967336, -8.180599960505091],
              [-40.002830335972128, -8.180600023579148],
              [-40.0028303664646, -8.180600086420597],
              [-40.002830397442914, -8.18060014902569],
              [-40.002830428905263, -8.18060021139072],
              [-40.002830460849708, -8.180600273511955],
              [-40.002830493274416, -8.180600335385691],
              [-40.002830526177398, -8.18060039700824],
              [-40.002830559556728, -8.180600458375968],
              [-40.002830593410415, -8.180600519485187],
              [-40.002830627736451, -8.180600580332273],
              [-40.002830662532766, -8.180600640913598],
              [-40.0028306977973, -8.180600701225549],
              [-40.002830733527944, -8.18060076126452],
              [-40.002830769722578, -8.180600821026953],
              [-40.002830806379059, -8.180600880509276],
              [-40.002830843495197, -8.180600939707976],
              [-40.002830881068753, -8.180600998619504],
              [-40.002830919097519, -8.180601057240333],
              [-40.002830957579221, -8.180601115567001],
              [-40.002830996511562, -8.180601173596026],
              [-40.002831035892235, -8.180601231323944],
              [-40.002831075718888, -8.180601288747308],
              [-40.002831115989174, -8.180601345862723],
              [-40.002831156700623, -8.180601402666772],
              [-40.002831197850881, -8.180601459156076],
              [-40.002831239437469, -8.180601515327291],
              [-40.002831281457915, -8.18060157117703],
              [-40.002831323909724, -8.180601626702007],
              [-40.002831366790332, -8.180601681898885],
              [-40.002831410097222, -8.180601736764379],
              [-40.002831453827802, -8.180601791295244],
              [-40.002831497979479, -8.180601845488166],
              [-40.002831542549615, -8.180601899339992],
              [-40.002831587535546, -8.180601952847518],
              [-40.002831632934587, -8.180602006007494],
              [-40.002831678744073, -8.180602058816779],
              [-40.002831724961219, -8.180602111272247],
              [-40.002831771583317, -8.180602163370772],
              [-40.002831818607568, -8.180602215109223],
              [-40.002831866031187, -8.180602266484563],
              [-40.002831913851331, -8.18060231749368],
              [-40.002831962065166, -8.180602368133549],
              [-40.002832010669806, -8.180602418401181],
              [-40.002832059662389, -8.180602468293559],
              [-40.002832109039943, -8.180602517807756],
              [-40.002832158799578, -8.180602566940756],
              [-40.002832208938294, -8.180602615689663],
              [-40.002832259453129, -8.18060266405158],
              [-40.002832310341063, -8.180602712023614],
              [-40.002832361599062, -8.180602759602909],
              [-40.002832413224091, -8.180602806786641],
              [-40.002832465213039, -8.180602853571989],
              [-40.002832517562851, -8.180602899956192],
              [-40.002832570270385, -8.180602945936434],
              [-40.002832623332509, -8.180602991510016],
              [-40.002832676746046, -8.180603036674219],
              [-40.002832730507841, -8.180603081426352],
              [-40.002832784614682, -8.180603125763751],
              [-40.002832839063331, -8.180603169683737],
              [-40.00283289385056, -8.180603213183753],
              [-40.002832948973115, -8.180603256261195],
              [-40.002833004427686, -8.180603298913432],
              [-40.002833060210975, -8.180603341138021],
              [-40.002833116319692, -8.180603382932402],
              [-40.002833172750456, -8.180603424294064],
              [-40.002833229499934, -8.180603465220564],
              [-40.002833286564723, -8.180603505709477],
              [-40.00283334394144, -8.180603545758379],
              [-40.00283340162666, -8.180603585364894],
              [-40.002833459616951, -8.18060362452664],
              [-40.002833517908854, -8.180603663241323],
              [-40.002833576498901, -8.180603701506604],
              [-40.002833635383602, -8.180603739320199],
              [-40.002833694559435, -8.180603776679865],
              [-40.002833754022909, -8.180603813583406],
              [-40.002833813770451, -8.180603850028586],
              [-40.002833873798508, -8.180603886013218],
              [-40.002833832627367, -8.18060391637187],
              [-40.003174461773128, -8.180806293462458],
              [-40.003174486604493, -8.180806291230644],
              [-40.003174578965975, -8.180806345381422],
              [-40.003174671962647, -8.180806398442002],
              [-40.003174765581548, -8.180806450404985],
              [-40.003174859809633, -8.180806501263106],
              [-40.003174954633764, -8.18080655100931],
              [-40.003175050040724, -8.180806599636641],
              [-40.00317514601722, -8.180806647138322],
              [-40.003175242549879, -8.180806693507742],
              [-40.00317533962523, -8.180806738738413],
              [-40.003175437229757, -8.180806782824074],
              [-40.003175535349833, -8.180806825758566],
              [-40.003175633971829, -8.180806867535866],
              [-40.003175733081939, -8.18080690815021],
              [-40.0031758326664, -8.180806947595876],
              [-40.003175932711301, -8.180806985867429],
              [-40.003176033202706, -8.180807022959492],
              [-40.003176134126598, -8.180807058866916],
              [-40.003176235468949, -8.180807093584683],
              [-40.003176337215585, -8.180807127107954],
              [-40.003176439352359, -8.180807159432065],
              [-40.00317654186501, -8.180807190552487],
              [-40.003176644739263, -8.180807220464915],
              [-40.003176747960786, -8.180807249165165],
              [-40.003176851515171, -8.180807276649235],
              [-40.003176955388, -8.180807302913299],
              [-40.003177059564798, -8.180807327953707],
              [-40.00317716403103, -8.180807351766957],
              [-40.003177268772149, -8.180807374349712],
              [-40.003177373773539, -8.180807395698848],
              [-40.003177479020593, -8.180807415811378],
              [-40.003177584498616, -8.180807434684514],
              [-40.003177690192906, -8.180807452315614],
              [-40.003177796088757, -8.180807468702202],
              [-40.003177902171373, -8.180807483842022],
              [-40.003178008426005, -8.180807497732957],
              [-40.003178114837816, -8.180807510373059],
              [-40.003178221391991, -8.18080752176059],
              [-40.003178328073652, -8.180807531893958],
              [-40.003178434867962, -8.180807540771731],
              [-40.003178541760022, -8.180807548392668],
              [-40.003178648734917, -8.18080755475574],
              [-40.003178755777761, -8.180807559860027],
              [-40.003178862873632, -8.180807563704811],
              [-40.003178970007589, -8.1808075662896],
              [-40.003179077164724, -8.180807567614018],
              [-40.003179184330072, -8.180807567677858],
              [-40.003179291488699, -8.180807566481134],
              [-40.00317939862569, -8.180807564024004],
              [-40.003179505726095, -8.180807560306818],
              [-40.003179612774993, -8.180807555330068],
              [-40.003179719757455, -8.180807549094492],
              [-40.003179826658588, -8.180807541600927],
              [-40.003179933463471, -8.180807532850432],
              [-40.003180040157233, -8.180807522844189],
              [-40.003180146724986, -8.180807511583636],
              [-40.003180253151889, -8.180807499070308],
              [-40.003180359423119, -8.180807485305992],
              [-40.003180465523833, -8.180807470292601],
              [-40.003180571439273, -8.180807454032204],
              [-40.003180677154653, -8.180807436527063],
              [-40.003180782655257, -8.180807417779624],
              [-40.003180887926369, -8.180807397792519],
              [-40.003180992953311, -8.180807376568525],
              [-40.003181097721466, -8.180807354110582],
              [-40.003181202216197, -8.180807330421853],
              [-40.003181306422988, -8.180807305505601],
              [-40.003181410327265, -8.180807279365347],
              [-40.003181513914576, -8.180807252004689],
              [-40.003181617170462, -8.180807223427465],
              [-40.003181720080541, -8.18080719363766],
              [-40.003181822630474, -8.180807162639407],
              [-40.003181924805965, -8.180807130437024],
              [-40.003182026592789, -8.180807097035004],
              [-40.003182127976714, -8.180807062438038],
              [-40.003182228943658, -8.180807026650907],
              [-40.0031823294795, -8.180806989678622],
              [-40.00318242957028, -8.180806951526328],
              [-40.003182529202007, -8.180806912199342],
              [-40.003182628360804, -8.180806871703137],
              [-40.003182727032858, -8.180806830043371],
              [-40.003182825204419, -8.180806787225841],
              [-40.003182922861797, -8.180806743256515],
              [-40.003183019991368, -8.180806698141533],
              [-40.003183116579599, -8.18080665188719],
              [-40.003183212613045, -8.180806604499898],
              [-40.00318330807832, -8.180806555986289],
              [-40.003183402962087, -8.180806506353123],
              [-40.003183497251143, -8.180806455607312],
              [-40.003183590932366, -8.180806403755915],
              [-40.00318368399266, -8.180806350806208],
              [-40.003183776419064, -8.180806296765521],
              [-40.003183868198704, -8.180806241641406],
              [-40.003183959318797, -8.180806185441559],
              [-40.003184049766624, -8.180806128173794],
              [-40.003184139529594, -8.180806069846078],
              [-40.003184228595174, -8.180806010466574],
              [-40.003184316950971, -8.180805950043538],
              [-40.003184404584651, -8.180805888585372],
              [-40.003184491484035, -8.180805826100713],
              [-40.003184536287819, -8.180805816243693],
              [-40.003938386913482, -8.180256952689057],
              [-40.003938380562268, -8.180256971296025],
              [-40.003938485747746, -8.180256895859216],
              [-40.003938592003472, -8.180256821926404],
              [-40.003938699307767, -8.180256749512639],
              [-40.003938807638789, -8.180256678632691],
              [-40.00393891697442, -8.180256609301008],
              [-40.003939027292411, -8.180256541531728],
              [-40.003939138570246, -8.180256475338672],
              [-40.003939250785251, -8.180256410735332],
              [-40.003939363914533, -8.180256347734865],
              [-40.003939477935049, -8.180256286350138],
              [-40.003939592823563, -8.180256226593631],
              [-40.003939708556636, -8.180256168477543],
              [-40.003939825110685, -8.18025611201371],
              [-40.003939942461955, -8.180256057213667],
              [-40.003940060586508, -8.180256004088566],
              [-40.003940179460265, -8.180255952649242],
              [-40.00394029905901, -8.180255902906175],
              [-40.003940419358365, -8.180255854869522],
              [-40.003940540333787, -8.180255808549045],
              [-40.00394066196062, -8.180255763954232],
              [-40.003940784214073, -8.180255721094138],
              [-40.003940907069243, -8.180255679977504],
              [-40.003941030501061, -8.180255640612723],
              [-40.003941154484359, -8.180255603007801],
              [-40.003941278993892, -8.180255567170423],
              [-40.003941404004266, -8.180255533107893],
              [-40.003941529489978, -8.180255500827146],
              [-40.003941655425479, -8.180255470334782],
              [-40.003941781785073, -8.180255441636982],
              [-40.003941908543013, -8.180255414739605],
              [-40.003942035673461, -8.180255389648151],
              [-40.003942163150498, -8.180255366367724],
              [-40.003942290948146, -8.180255344903097],
              [-40.003942419040321, -8.180255325258605],
              [-40.003942547400946, -8.180255307438269],
              [-40.003942676003845, -8.180255291445715],
              [-40.003942804822792, -8.180255277284225],
              [-40.003942933831539, -8.180255264956655],
              [-40.003943063003781, -8.180255254465539],
              [-40.003943192313201, -8.180255245813026],
              [-40.003943321733423, -8.180255239000847],
              [-40.003943451238065, -8.180255234030399],
              [-40.003943580800723, -8.180255230902732],
              [-40.003943710395006, -8.180255229618444],
              [-40.003943839994477, -8.180255230177805],
              [-40.003943969572724, -8.180255232580691],
              [-40.003944099103329, -8.180255236826653],
              [-40.003944228559888, -8.180255242914786],
              [-40.003944357916012, -8.180255250843853],
              [-40.003944487145333, -8.180255260612263],
              [-40.003944616221517, -8.180255272218007],
              [-40.003944745118233, -8.180255285658697],
              [-40.003944873809225, -8.180255300931632],
              [-40.003945002268232, -8.180255318033693],
              [-40.003945130469084, -8.180255336961356],
              [-40.003945258385649, -8.180255357710831],
              [-40.003945385991855, -8.180255380277801],
              [-40.003945513261684, -8.180255404657732],
              [-40.003945640169171, -8.180255430845635],
              [-40.003945766688481, -8.18025545883618],
              [-40.003945892793801, -8.180255488623644],
              [-40.003946018459438, -8.180255520201952],
              [-40.003946143659753, -8.180255553564685],
              [-40.003946268369262, -8.180255588705046],
              [-40.003946392562497, -8.18025562561586],
              [-40.003946516214171, -8.180255664289575],
              [-40.003946639299066, -8.180255704718364],
              [-40.003946761792108, -8.180255746893939],
              [-40.003946883668291, -8.180255790807736],
              [-40.003947004902813, -8.180255836450787],
              [-40.00394712547093, -8.180255883813771],
              [-40.003947245348073, -8.180255932887039],
              [-40.003947364509806, -8.180255983660622],
              [-40.003947482931828, -8.180256036124145],
              [-40.003947600590024, -8.180256090266893],
              [-40.003947717460385, -8.180256146077847],
              [-40.003947833519092, -8.18025620354566],
              [-40.003947948742493, -8.180256262658569],
              [-40.003948063107096, -8.18025632340456],
              [-40.003948176589581, -8.180256385771219],
              [-40.003948289166836, -8.180256449745839],
              [-40.003948400815872, -8.180256515315406],
              [-40.003948511513968, -8.180256582466534],
              [-40.003948621238543, -8.180256651185509],
              [-40.003948729967234, -8.180256721458372],
              [-40.003948837677868, -8.180256793270765],
              [-40.003948944348487, -8.180256866608081],
              [-40.00394904995737, -8.18025694145533],
              [-40.003949154482953, -8.18025701779727],
              [-40.003949257903962, -8.180257095618359],
              [-40.003949360199286, -8.180257174902691],
              [-40.003949461348078, -8.180257255634134],
              [-40.003949561329748, -8.18025733779622],
              [-40.003949660123872, -8.180257421372199],
              [-40.00394975771033, -8.180257506345031],
              [-40.003949854069241, -8.18025759269743],
              [-40.003949949180942, -8.180257680411771],
              [-40.003950043026059, -8.180257769470142],
              [-40.003950135585221, -8.180257859854661],
              [-40.003950098691902, -8.180257828011049],
              [-40.004147381477594, -8.18045323456894],
              [-40.004147416195117, -8.18045327483417],
              [-40.004147520935433, -8.180453380300024],
              [-40.004147623925199, -8.180453487463675],
              [-40.004147725136697, -8.18045359629623],
              [-40.004147824542713, -8.180453706768448],
              [-40.004147922116474, -8.180453818850594],
              [-40.004148017831739, -8.180453932512496],
              [-40.004148111662708, -8.180454047723551],
              [-40.004148203584165, -8.18045416445278],
              [-40.004148293571347, -8.180454282668745],
              [-40.004148381600061, -8.180454402339612],
              [-40.004148467646601, -8.180454523433214],
              [-40.004148551687805, -8.180454645916948],
              [-40.004148633701078, -8.180454769757848],
              [-40.004148713664307, -8.180454894922581],
              [-40.004148791556013, -8.180455021377462],
              [-40.004148867355205, -8.180455149088459],
              [-40.004148941041507, -8.180455278021185],
              [-40.004149012595065, -8.180455408140993],
              [-40.00414908199663, -8.180455539412829],
              [-40.00414914922753, -8.180455671801335],
              [-40.004149214269667, -8.180455805270933],
              [-40.004149277105533, -8.180455939785679],
              [-40.004149337718232, -8.180456075309371],
              [-40.004149396091428, -8.180456211805518],
              [-40.004149452209418, -8.180456349237421],
              [-40.004149506057118, -8.180456487568073],
              [-40.004149557620011, -8.180456626760247],
              [-40.004149606884226, -8.180456766776478],
              [-40.004149653836507, -8.180456907579085],
              [-40.004149698464232, -8.180457049130172],
              [-40.004149740755359, -8.180457191391653],
              [-40.00414978069854, -8.180457334325238],
              [-40.004149818283018, -8.180457477892443],
              [-40.004149853498653, -8.180457622054654],
              [-40.004149886336002, -8.180457766773062],
              [-40.004149916786197, -8.180457912008732],
              [-40.004149944841053, -8.180458057722548],
              [-40.004149970493032, -8.180458203875327],
              [-40.004149993735211, -8.180458350427701],
              [-40.004150014561354, -8.180458497340268],
              [-40.004150032965832, -8.180458644573466],
              [-40.004150048943721, -8.180458792087638],
              [-40.004150062490694, -8.180458939843138],
              [-40.004150073603121, -8.180459087800173],
              [-40.004150082278016, -8.180459235918937],
              [-40.004150088513036, -8.180459384159565],
              [-40.004150092306496, -8.180459532482146],
              [-40.00415009365738, -8.180459680846772],
              [-40.004150092565325, -8.180459829213499],
              [-40.004150089030624, -8.180459977542418],
              [-40.004150083054256, -8.180460125793614],
              [-40.004150074637778, -8.180460273927173],
              [-40.004150063783499, -8.180460421903225],
              [-40.004150050494303, -8.180460569681951],
              [-40.004150034773794, -8.180460717223598],
              [-40.004150016626191, -8.180460864488433],
              [-40.004149996056391, -8.180461011436833],
              [-40.004149973069921, -8.180461158029253],
              [-40.004149947672957, -8.180461304226224],
              [-40.004149919872368, -8.180461449988405],
              [-40.004149889675581, -8.180461595276585],
              [-40.004149857090773, -8.180461740051655],
              [-40.004149822126685, -8.18046188427463],
              [-40.004149784792745, -8.180462027906724],
              [-40.004149745098985, -8.180462170909252],
              [-40.004149703056093, -8.180462313243758],
              [-40.004149658675395, -8.180462454871925],
              [-40.004149611968813, -8.180462595755619],
              [-40.004149562948946, -8.180462735856972],
              [-40.004149511628952, -8.180462875138232],
              [-40.004149458022681, -8.180463013561932],
              [-40.004149402144535, -8.180463151090812],
              [-40.004149344009569, -8.180463287687864],
              [-40.004149283633396, -8.180463423316317],
              [-40.004149221032286, -8.180463557939673],
              [-40.004149156223107, -8.180463691521705],
              [-40.004149089223269, -8.180463824026473],
              [-40.004149020050811, -8.180463955418279],
              [-40.004148948724371, -8.180464085661793],
              [-40.00414887526312, -8.180464214721972],
              [-40.004148799686831, -8.180464342564051],
              [-40.004148722015856, -8.180464469153645],
              [-40.004148642271112, -8.180464594456677],
              [-40.004148560474015, -8.180464718439444],
              [-40.004148476646613, -8.180464841068543],
              [-40.004148390811459, -8.180464962310966],
              [-40.004148302991659, -8.180465082134109],
              [-40.00414821321084, -8.180465200505735],
              [-40.004148121493166, -8.180465317393981],
              [-40.00414802786333, -8.180465432767372],
              [-40.004147932346527, -8.180465546594871],
              [-40.004147834968421, -8.180465658845817],
              [-40.004147735755303, -8.180465769490034],
              [-40.004147634733819, -8.180465878497746],
              [-40.004147531931153, -8.180465985839579],
              [-40.004147427374996, -8.180466091486648],
              [-40.004147321093477, -8.180466195410581],
              [-40.004147213115196, -8.180466297583362],
              [-40.00414710347016, -8.180466397976641],
              [-40.004147142213782, -8.180466439650671],
              [-40.003461542217792, -8.181083869821512],
              [-40.003461569750257, -8.181083828349109],
              [-40.003461520431991, -8.181083873096977],
              [-40.003461471445085, -8.181083918204729],
              [-40.003461422792185, -8.181083963669952],
              [-40.003461374475904, -8.181084009490156],
              [-40.003461326498879, -8.181084055662867],
              [-40.003461278863675, -8.181084102185611],
              [-40.003461231572864, -8.181084149055858],
              [-40.003461184629039, -8.181084196271094],
              [-40.00346113803468, -8.181084243828767],
              [-40.003461091792339, -8.181084291726311],
              [-40.00346104590448, -8.181084339961108],
              [-40.003461000373647, -8.18108438853057],
              [-40.003460955202229, -8.181084437432101],
              [-40.003460910392697, -8.181084486663037],
              [-40.00346086594746, -8.181084536220721],
              [-40.003460821868927, -8.181084586102489],
              [-40.003460778159464, -8.181084636305606],
              [-40.003460734821459, -8.181084686827418],
              [-40.003460691857221, -8.181084737665175],
              [-40.003460649269101, -8.181084788816124],
              [-40.003460607059367, -8.181084840277521],
              [-40.00346056523032, -8.181084892046567],
              [-40.003460523784192, -8.181084944120471],
              [-40.003460482723263, -8.181084996496413],
              [-40.003460442049736, -8.181085049171589],
              [-40.003460401765786, -8.181085102143124],
              [-40.0034603618736, -8.181085155408194],
              [-40.003460322375339, -8.181085208963868],
              [-40.003460283273114, -8.181085262807319],
              [-40.003460244569084, -8.18108531693559],
              [-40.003460206265288, -8.181085371345779],
              [-40.003460168363823, -8.181085426034951],
              [-40.003460130866721, -8.181085481000149],
              [-40.003460093775999, -8.18108553623839],
              [-40.003460057093712, -8.181085591746722],
              [-40.003460020821784, -8.181085647522119],
              [-40.003459984962184, -8.181085703561582],
              [-40.003459949516866, -8.181085759862089],
              [-40.003459914487728, -8.18108581642057],
              [-40.003459879876665, -8.181085873234007],
              [-40.003459845685562, -8.181085930299343],
              [-40.003459811916258, -8.181085987613436],
              [-40.003459778570566, -8.181086045173243],
              [-40.003459745650268, -8.181086102975653],
              [-40.003459713157199, -8.181086161017527],
              [-40.003459681093076, -8.181086219295734],
              [-40.003459649459629, -8.181086277807115],
              [-40.003459618258567, -8.18108633654856],
              [-40.003459587491591, -8.181086395516845],
              [-40.00345955716034, -8.181086454708803],
              [-40.003459527266472, -8.181086514121233],
              [-40.003459497811576, -8.18108657375093],
              [-40.003459468797267, -8.181086633594681],
              [-40.003459440225114, -8.181086693649231],
              [-40.003459412096639, -8.181086753911385],
              [-40.003459384413368, -8.18108681437784],
              [-40.003459357176816, -8.181086875045374],
              [-40.003459330388424, -8.18108693591064],
              [-40.003459304049663, -8.181086996970407],
              [-40.003459278161955, -8.181087058221385],
              [-40.00345925272665, -8.181087119660255],
              [-40.003459227745182, -8.181087181283671],
              [-40.003459203218881, -8.181087243088347],
              [-40.003459179149061, -8.181087305070914],
              [-40.003459155537023, -8.18108736722805],
              [-40.003459132384044, -8.181087429556396],
              [-40.003459109691391, -8.181087492052562],
              [-40.003459087460257, -8.181087554713198],
              [-40.003459065691885, -8.181087617534912],
              [-40.003459044387398, -8.181087680514308],
              [-40.003459023547997, -8.181087743647996],
              [-40.003459003174768, -8.18108780693254],
              [-40.003458983268835, -8.181087870364543],
              [-40.003458963831278, -8.181087933940574],
              [-40.003458944863134, -8.181087997657219],
              [-40.003458926365411, -8.181088061511018],
              [-40.003458908339134, -8.181088125498515],
              [-40.003458890785275, -8.181088189616268],
              [-40.003458873704759, -8.181088253860826],
              [-40.003458857098529, -8.181088318228683],
              [-40.003458840967483, -8.1810883827164],
              [-40.003458825312478, -8.181088447320487],
              [-40.003458810134383, -8.18108851203745],
              [-40.003458795433971, -8.18108857686377],
              [-40.003458781212089, -8.181088641795993],
              [-40.003458767469482, -8.181088706830597],
              [-40.003458754206896, -8.181088771964056],
              [-40.003458741425028, -8.181088837192863],
              [-40.003458729124588, -8.181088902513483],
              [-40.003458717306238, -8.181088967922411],
              [-40.003458705970623, -8.181089033416105],
              [-40.003458695118326, -8.181089098991025],
              [-40.003458684749965, -8.181089164643634],
              [-40.003458674866096, -8.18108923037037],
              [-40.003458665467235, -8.181089296167706],
              [-40.003458656553903, -8.181089362032059],
              [-40.003458648126582, -8.181089427959913],
              [-40.003458640185713, -8.181089493947674],
              [-40.003458632731736, -8.181089559991808],
              [-40.003458651778317, -8.181089574827032],
              [-40.003391210481695, -8.181707459173618],
              [-40.003391196716528, -8.181707497130402],
              [-40.00339119028569, -8.181707557972251],
              [-40.003391184268075, -8.181707618856048],
              [-40.003391178663939, -8.181707679778965],
              [-40.003391173473538, -8.181707740738302],
              [-40.003391168697128, -8.181707801731227],
              [-40.0033911643349, -8.181707862754932],
              [-40.003391160387075, -8.181707923806631],
              [-40.003391156853823, -8.18170798488355],
              [-40.003391153735315, -8.181708045982887],
              [-40.003391151031686, -8.181708107101828],
              [-40.003391148743049, -8.181708168237614],
              [-40.003391146869525, -8.181708229387413],
              [-40.003391145411214, -8.181708290548443],
              [-40.003391144368159, -8.181708351717909],
              [-40.003391143740402, -8.181708412893],
              [-40.003391143527985, -8.181708474070932],
              [-40.003391143730923, -8.181708535248895],
              [-40.003391144349202, -8.181708596424093],
              [-40.003391145382778, -8.181708657593704],
              [-40.003391146831625, -8.181708718754967],
              [-40.003391148695677, -8.181708779905065],
              [-40.003391150974828, -8.181708841041196],
              [-40.003391153668986, -8.181708902160567],
              [-40.003391156778036, -8.181708963260375],
              [-40.003391160301824, -8.181709024337842],
              [-40.003391164240199, -8.181709085390152],
              [-40.003391168592962, -8.181709146414539],
              [-40.003391173359944, -8.18170920740819],
              [-40.00339117854088, -8.181709268368287],
              [-40.003391184135587, -8.181709329292095],
              [-40.003391190143766, -8.181709390176806],
              [-40.003391196565175, -8.181709451019641],
              [-40.003391203399495, -8.181709511817767],
              [-40.003391210646427, -8.181709572568472],
              [-40.003391218305644, -8.181709633268932],
              [-40.003391226376777, -8.181709693916396],
              [-40.003391234859471, -8.181709754508054],
              [-40.003391243753335, -8.181709815041167],
              [-40.003391253057956, -8.181709875512949],
              [-40.003391262772908, -8.181709935920622],
              [-40.003391272897773, -8.181709996261464],
              [-40.003391283432059, -8.181710056532673],
              [-40.003391294375263, -8.181710116731503],
              [-40.00339130572695, -8.181710176855217],
              [-40.003391317486539, -8.181710236901051],
              [-40.003391329653525, -8.181710296866239],
              [-40.003391342227346, -8.181710356748059],
              [-40.00339135520742, -8.181710416543744],
              [-40.003391368593149, -8.181710476250606],
              [-40.003391382383946, -8.181710535865879],
              [-40.003391396579154, -8.181710595386841],
              [-40.003391411178121, -8.181710654810757],
              [-40.003391426180194, -8.18171071413494],
              [-40.003391441584682, -8.181710773356643],
              [-40.003391457390876, -8.181710832473188],
              [-40.003391473598072, -8.181710891481833],
              [-40.003391490205487, -8.181710950379919],
              [-40.003391507212413, -8.181711009164701],
              [-40.003391524618024, -8.181711067833515],
              [-40.003391542421561, -8.181711126383673],
              [-40.003391560622184, -8.181711184812515],
              [-40.003391579219077, -8.181711243117345],
              [-40.00339159821138, -8.181711301295506],
              [-40.003391617598218, -8.181711359344355],
              [-40.003391637378733, -8.181711417261178],
              [-40.003391657551973, -8.181711475043375],
              [-40.003391678117055, -8.181711532688281],
              [-40.003391699073013, -8.181711590193261],
              [-40.003391720418911, -8.181711647555693],
              [-40.003391742153752, -8.181711704772949],
              [-40.003391764276557, -8.181711761842415],
              [-40.00339178678631, -8.181711818761482],
              [-40.003391809681958, -8.181711875527517],
              [-40.003391832962485, -8.181711932137953],
              [-40.00339185662682, -8.181711988590193],
              [-40.003391880673874, -8.181712044881664],
              [-40.003391905102518, -8.181712101009742],
              [-40.003391929911686, -8.181712156971921],
              [-40.00339195510022, -8.181712212765586],
              [-40.003391980666962, -8.181712268388221],
              [-40.003392006610738, -8.181712323837282],
              [-40.003392032930385, -8.181712379110207],
              [-40.003392059624673, -8.181712434204497],
              [-40.003392086692401, -8.181712489117606],
              [-40.003392114132311, -8.181712543847045],
              [-40.003392141943152, -8.181712598390277],
              [-40.003392170123675, -8.181712652744821],
              [-40.003392198672557, -8.181712706908208],
              [-40.003392227588513, -8.18171276087792],
              [-40.003392256870207, -8.181712814651506],
              [-40.003392286516309, -8.181712868226517],
              [-40.003392316525456, -8.181712921600532],
              [-40.003392346896291, -8.181712974771038],
              [-40.003392377627407, -8.181713027735638],
              [-40.003392408717382, -8.181713080491917],
              [-40.003392440164824, -8.181713133037443],
              [-40.00339247196829, -8.181713185369805],
              [-40.00339250412631, -8.18171323748664],
              [-40.003392536637435, -8.18171328938555],
              [-40.003392495992131, -8.181713334692327],
              [-40.003739441553563, -8.18226290114008],
              [-40.003739410229066, -8.182262870687511],
              [-40.003739434392386, -8.182262908760681],
              [-40.003739458745727, -8.182262946713482],
              [-40.003739483288484, -8.182262984544947],
              [-40.003739508020061, -8.182263022254158],
              [-40.003739532939846, -8.182263059840167],
              [-40.003739558047201, -8.182263097302021],
              [-40.003739583341513, -8.182263134638822],
              [-40.003739608822158, -8.182263171849637],
              [-40.00373963448849, -8.182263208933549],
              [-40.003739660339903, -8.182263245889638],
              [-40.003739686375745, -8.182263282716958],
              [-40.00373971259534, -8.182263319414629],
              [-40.003739738998064, -8.182263355981725],
              [-40.003739765583248, -8.18226339241734],
              [-40.00373979235026, -8.182263428720583],
              [-40.00373981929841, -8.182263464890529],
              [-40.003739846427038, -8.182263500926302],
              [-40.003739873735462, -8.182263536827012],
              [-40.003739901223028, -8.182263572591747],
              [-40.003739928889026, -8.182263608219609],
              [-40.003739956732787, -8.182263643709748],
              [-40.003739984753608, -8.182263679061258],
              [-40.003740012950821, -8.182263714273285],
              [-40.003740041323688, -8.182263749344937],
              [-40.003740069871533, -8.182263784275371],
              [-40.003740098593632, -8.182263819063698],
              [-40.003740127489273, -8.18226385370904],
              [-40.003740156557761, -8.182263888210576],
              [-40.00374018579835, -8.182263922567417],
              [-40.003740215210343, -8.182263956778741],
              [-40.003740244792972, -8.182263990843682],
              [-40.003740274545528, -8.182264024761373],
              [-40.003740304467257, -8.182264058530986],
              [-40.003740334557442, -8.182264092151716],
              [-40.003740364815314, -8.182264125622696],
              [-40.003740395240129, -8.182264158943109],
              [-40.003740425831147, -8.182264192112136],
              [-40.003740456587579, -8.182264225128897],
              [-40.003740487508679, -8.182264257992662],
              [-40.003740518593695, -8.182264290702552],
              [-40.003740549841822, -8.18226432325778],
              [-40.003740581252309, -8.182264355657534],
              [-40.003740612824366, -8.182264387901013],
              [-40.003740644557233, -8.182264419987408],
              [-40.003740676450093, -8.182264451915927],
              [-40.003740708502164, -8.182264483685763],
              [-40.003740740712651, -8.182264515296167],
              [-40.003740773080764, -8.182264546746326],
              [-40.00374080560568, -8.182264578035465],
              [-40.00374083828661, -8.182264609162818],
              [-40.003740871122744, -8.182264640127595],
              [-40.003740904113265, -8.182264670929031],
              [-40.003740937257341, -8.182264701566384],
              [-40.003740970554162, -8.182264732038849],
              [-40.003741004002904, -8.182264762345712],
              [-40.003741037602737, -8.182264792486221],
              [-40.00374107135282, -8.182264822459592],
              [-40.003741105252324, -8.182264852265096],
              [-40.003741139300388, -8.182264881902018],
              [-40.003741173496202, -8.182264911369595],
              [-40.003741207838885, -8.182264940667134],
              [-40.003741242327592, -8.182264969793859],
              [-40.003741276961492, -8.182264998749089],
              [-40.003741311739702, -8.182265027532077],
              [-40.003741346661357, -8.182265056142125],
              [-40.003741381725604, -8.182265084578503],
              [-40.003741416931575, -8.182265112840533],
              [-40.003741452278369, -8.182265140927495],
              [-40.003741487765147, -8.182265168838697],
              [-40.003741523391021, -8.182265196573441],
              [-40.003741559155067, -8.182265224131047],
              [-40.003741595056454, -8.182265251510836],
              [-40.003741631094258, -8.18226527871211],
              [-40.003741667267597, -8.182265305734205],
              [-40.00374170357555, -8.18226533257644],
              [-40.003741740017247, -8.18226535923819],
              [-40.003741776591767, -8.182265385718754],
              [-40.00374181329822, -8.182265412017475],
              [-40.003741850135675, -8.182265438133728],
              [-40.003741887103232, -8.182265464066845],
              [-40.003741924199964, -8.182265489816173],
              [-40.003741961424957, -8.182265515381106],
              [-40.003741998777279, -8.182265540760975],
              [-40.003742036256021, -8.182265565955166],
              [-40.003742073860231, -8.18226559096307],
              [-40.003742111588998, -8.182265615784027],
              [-40.003742149441379, -8.182265640417468],
              [-40.003742187416407, -8.182265664862754],
              [-40.0037422255132, -8.182265689119301],
              [-40.00374226373075, -8.182265713186462],
              [-40.003742302068147, -8.182265737063675],
              [-40.003742340524418, -8.182265760750351],
              [-40.003742379098654, -8.182265784245889],
              [-40.00374241778983, -8.182265807549694],
              [-40.003742456597017, -8.182265830661201],
              [-40.003742495519276, -8.182265853579839],
              [-40.00374253455562, -8.182265876305019],
              [-40.003742573705075, -8.182265898836226],
              [-40.003742612966668, -8.182265921172865],
              [-40.003742626038886, -8.182265877804687],
              [-40.004227758126369, -8.18254033469529],
              [-40.004227765818236, -8.182540338508351],
              [-40.0042277914145, -8.182540352915984],
              [-40.004227817057554, -8.182540367240787],
              [-40.004227842747149, -8.182540381482591],
              [-40.004227868482985, -8.182540395641267],
              [-40.004227894264794, -8.182540409716657],
              [-40.004227920092319, -8.182540423708595],
              [-40.004227945965283, -8.182540437616966],
              [-40.004227971883395, -8.18254045144162],
              [-40.004227997846414, -8.182540465182367],
              [-40.004228023854047, -8.18254047883911],
              [-40.004228049906018, -8.182540492411668],
              [-40.004228076002079, -8.182540505899951],
              [-40.004228102141894, -8.182540519303764],
              [-40.004228128325259, -8.182540532622982],
              [-40.004228154551868, -8.182540545857492],
              [-40.00422818082145, -8.18254055900711],
              [-40.004228207133707, -8.182540572071719],
              [-40.004228233488377, -8.182540585051216],
              [-40.004228259885181, -8.182540597945419],
              [-40.004228286323865, -8.182540610754193],
              [-40.004228312804102, -8.182540623477438],
              [-40.004228339325657, -8.182540636114984],
              [-40.004228365888231, -8.182540648666718],
              [-40.004228392491527, -8.182540661132485],
              [-40.004228419135309, -8.182540673512154],
              [-40.004228445819265, -8.182540685805622],
              [-40.004228472543119, -8.182540698012737],
              [-40.004228499306599, -8.18254071013339],
              [-40.004228526109401, -8.182540722167452],
              [-40.004228552951261, -8.182540734114783],
              [-40.004228579831903, -8.182540745975253],
              [-40.004228606751028, -8.182540757748775],
              [-40.004228633708358, -8.182540769435159],
              [-40.00422866070361, -8.182540781034335],
              [-40.0042286877365, -8.182540792546172],
              [-40.004228714806729, -8.182540803970523],
              [-40.004228741914041, -8.182540815307283],
              [-40.004228769058123, -8.182540826556348],
              [-40.004228796238706, -8.182540837717589],
              [-40.004228823455513, -8.182540848790895],
              [-40.00422885070823, -8.182540859776136],
              [-40.004228877996582, -8.182540870673204],
              [-40.004228905320275, -8.182540881481991],
              [-40.004228932679041, -8.182540892202351],
              [-40.004228960072581, -8.182540902834202],
              [-40.004228987500603, -8.182540913377442],
              [-40.004229014962817, -8.182540923831931],
              [-40.004229042458931, -8.182540934197565],
              [-40.004229069988668, -8.182540944474232],
              [-40.00422909755175, -8.182540954661851],
              [-40.004229125147837, -8.182540964760294],
              [-40.004229152776681, -8.182540974769438],
              [-40.004229180437981, -8.182540984689195],
              [-40.004229208131441, -8.182540994519458],
              [-40.004229235856783, -8.182541004260138],
              [-40.004229263613695, -8.182541013911118],
              [-40.004229291401913, -8.182541023472286],
              [-40.004229319221103, -8.182541032943567],
              [-40.004229347071004, -8.182541042324829],
              [-40.004229374951315, -8.182541051616028],
              [-40.004229402861739, -8.182541060817007],
              [-40.004229430801985, -8.182541069927701],
              [-40.004229458771761, -8.182541078948029],
              [-40.004229486770768, -8.182541087877855],
              [-40.004229514798716, -8.182541096717108],
              [-40.004229542855299, -8.182541105465681],
              [-40.004229570940225, -8.182541114123499],
              [-40.004229599053204, -8.182541122690479],
              [-40.004229627193943, -8.18254113116649],
              [-40.004229655362138, -8.182541139551466],
              [-40.004229683557497, -8.182541147845356],
              [-40.004229711779715, -8.182541156047987],
              [-40.004229740028507, -8.182541164159369],
              [-40.004229768303574, -8.182541172179347],
              [-40.004229796604605, -8.182541180107853],
              [-40.004229824931315, -8.182541187944803],
              [-40.004229853283398, -8.182541195690131],
              [-40.004229881660564, -8.182541203343741],
              [-40.004229910062506, -8.18254121090556],
              [-40.004229938488933, -8.182541218375526],
              [-40.004229966939533, -8.182541225753521],
              [-40.004229995414008, -8.182541233039458],
              [-40.004230023912065, -8.182541240233325],
              [-40.004230052433414, -8.182541247335006],
              [-40.004230080977734, -8.182541254344429],
              [-40.004230109544743, -8.182541261261512],
              [-40.004230138134133, -8.182541268086204],
              [-40.004230166745579, -8.182541274818426],
              [-40.004230195378824, -8.182541281458098],
              [-40.004230224033527, -8.182541288005162],
              [-40.004230252709412, -8.182541294459515],
              [-40.00423028140618, -8.182541300821134],
              [-40.004230310123496, -8.182541307089929],
              [-40.004230338861085, -8.182541313265837],
              [-40.004230367618646, -8.18254131934879],
              [-40.004230396395855, -8.182541325338734],
              [-40.004230425192432, -8.182541331235591],
              [-40.004230454008066, -8.182541337039297],
              [-40.004230482842445, -8.182541342749808],
              [-40.004230491467482, -8.182541326591732],
              [-40.004918886042773, -8.182676634028663],
              [-40.004918849434681, -8.18267662796465],
              [-40.00491897282064, -8.18267665310092],
              [-40.004919095844684, -8.182676679940347],
              [-40.004919218483202, -8.182676708477782],
              [-40.004919340712512, -8.182676738707723],
              [-40.004919462509122, -8.18267677062437],
              [-40.004919583849606, -8.182676804221565],
              [-40.004919704710552, -8.182676839492842],
              [-40.004919825068754, -8.182676876431406],
              [-40.004919944901019, -8.182676915030175],
              [-40.004920064184255, -8.182676955281686],
              [-40.004920182895553, -8.182676997178218],
              [-40.004920301012035, -8.182677040711692],
              [-40.004920418510942, -8.182677085873722],
              [-40.004920535369699, -8.182677132655622],
              [-40.004920651565776, -8.182677181048369],
              [-40.004920767076833, -8.182677231042666],
              [-40.004920881880629, -8.182677282628902],
              [-40.004920995955047, -8.182677335797122],
              [-40.004921109278136, -8.18267739053711],
              [-40.004921221828106, -8.182677446838303],
              [-40.004921333583262, -8.182677504689908],
              [-40.004921444522104, -8.182677564080755],
              [-40.00492155462328, -8.18267762499943],
              [-40.004921663865581, -8.182677687434193],
              [-40.004921772228023, -8.182677751373014],
              [-40.004921879689697, -8.182677816803622],
              [-40.004921986229931, -8.18267788371339],
              [-40.004922091828213, -8.182677952089437],
              [-40.004922196464236, -8.182678021918637],
              [-40.004922300117862, -8.182678093187498],
              [-40.004922402769111, -8.182678165882338],
              [-40.00492250439823, -8.18267823998916],
              [-40.004922604985673, -8.182678315493691],
              [-40.004922704512076, -8.182678392381408],
              [-40.004922802958241, -8.182678470637486],
              [-40.004922900305274, -8.182678550246912],
              [-40.004922996534404, -8.182678631194294],
              [-40.004923091627113, -8.182678713464087],
              [-40.004923185565112, -8.182678797040461],
              [-40.004923278330303, -8.182678881907313],
              [-40.004923369904816, -8.182678968048327],
              [-40.004923460271044, -8.182679055446881],
              [-40.004923549411608, -8.182679144086199],
              [-40.004923637309297, -8.18267923394918],
              [-40.004923723947236, -8.182679325018556],
              [-40.00492380930875, -8.182679417276782],
              [-40.004923893377381, -8.182679510706086],
              [-40.004923976136972, -8.182679605288509],
              [-40.00492405757155, -8.182679701005839],
              [-40.004924137665483, -8.182679797839635],
              [-40.004924216403353, -8.18267989577126],
              [-40.004924293769974, -8.182679994781891],
              [-40.004924369750469, -8.182680094852453],
              [-40.004924444330214, -8.182680195963675],
              [-40.004924517494864, -8.182680298096118],
              [-40.004924589230313, -8.182680401230071],
              [-40.004924659522757, -8.182680505345727],
              [-40.004924728358674, -8.182680610423041],
              [-40.004924795724811, -8.182680716441775],
              [-40.00492486160821, -8.18268082338151],
              [-40.004924925996157, -8.182680931221682],
              [-40.004924988876304, -8.182681039941532],
              [-40.004925050236508, -8.182681149520111],
              [-40.004925110064981, -8.18268125993634],
              [-40.004925168350191, -8.182681371168961],
              [-40.004925225080932, -8.182681483196589],
              [-40.004925280246269, -8.182681595997622],
              [-40.004925333835594, -8.182681709550348],
              [-40.00492538583859, -8.182681823832951],
              [-40.004925436245252, -8.182681938823384],
              [-40.004925485045867, -8.18268205449955],
              [-40.004925532231042, -8.18268217083914],
              [-40.004925577791695, -8.182682287819803],
              [-40.004925621719067, -8.182682405419001],
              [-40.004925664004695, -8.182682523614105],
              [-40.004925704640428, -8.18268264238235],
              [-40.004925743618472, -8.182682761700883],
              [-40.004925780931273, -8.182682881546727],
              [-40.004925816571721, -8.182683001896843],
              [-40.004925850532899, -8.182683122728028],
              [-40.00492588280828, -8.182683244017033],
              [-40.004925913391666, -8.182683365740514],
              [-40.004925942277161, -8.182683487875046],
              [-40.004925969459215, -8.182683610397111],
              [-40.004925994932563, -8.182683733283127],
              [-40.004926018692345, -8.182683856509447],
              [-40.00492604073397, -8.182683980052355],
              [-40.004926061053169, -8.182684103888027],
              [-40.004926079646083, -8.182684227992681],
              [-40.004926096509074, -8.182684352342385],
              [-40.004926111638952, -8.182684476913256],
              [-40.004926125032782, -8.182684601681268],
              [-40.00492613668797, -8.182684726622419],
              [-40.004926146602294, -8.182684851712658],
              [-40.004926154773827, -8.182684976927915],
              [-40.004926161201013, -8.182685102244038],
              [-40.004926165882601, -8.182685227636963],
              [-40.00492616881769, -8.182685353082542],
              [-40.004926170005589, -8.182685478556762],
              [-40.004927356563002, -8.183159020742789],
              [-40.004927356583124, -8.183159028783267],
              [-40.00492735629809, -8.183159116441967],
              [-40.004927355160348, -8.183159204093798],
              [-40.004927353170025, -8.183159291730531],
              [-40.004927350327279, -8.183159379343925],
              [-40.004927346632378, -8.183159466925755],
              [-40.004927342085679, -8.183159554467787],
              [-40.004927336687622, -8.183159641961806],
              [-40.004927330438697, -8.183159729399584],
              [-40.004927323339508, -8.183159816772902],
              [-40.004927315390695, -8.183159904073571],
              [-40.004927306593018, -8.183159991293371],
              [-40.004927296947322, -8.183160078424125],
              [-40.004927286454489, -8.183160165457629],
              [-40.004927275115513, -8.183160252385726],
              [-40.004927262931439, -8.18316033920023],
              [-40.004927249903439, -8.183160425892993],
              [-40.004927236032742, -8.183160512455885],
              [-40.004927221320628, -8.183160598880759],
              [-40.00492720576851, -8.183160685159496],
              [-40.004927189377788, -8.183160771284006],
              [-40.004927172150069, -8.183160857246182],
              [-40.00492715408695, -8.183160943037947],
              [-40.004927135190094, -8.183161028651249],
              [-40.004927115461321, -8.183161114078048],
              [-40.004927094902477, -8.183161199310334],
              [-40.004927073515475, -8.18316128434005],
              [-40.004927051302332, -8.183161369159262],
              [-40.004927028265129, -8.183161453759972],
              [-40.004927004406049, -8.183161538134231],
              [-40.004926979727323, -8.183161622274129],
              [-40.004926954231252, -8.183161706171756],
              [-40.004926927920252, -8.183161789819229],
              [-40.004926900796789, -8.183161873208689],
              [-40.004926872863415, -8.183161956332309],
              [-40.004926844122757, -8.183162039182271],
              [-40.004926814577502, -8.183162121750783],
              [-40.004926784230435, -8.183162204030124],
              [-40.004926753084398, -8.183162286012537],
              [-40.004926721142311, -8.183162367690315],
              [-40.00492668840721, -8.183162449055805],
              [-40.004926654882127, -8.183162530101351],
              [-40.004926620570252, -8.183162610819341],
              [-40.004926585474777, -8.183162691202215],
              [-40.004926549598999, -8.183162771242358],
              [-40.004926512946305, -8.183162850932318],
              [-40.004926475520158, -8.18316293026456],
              [-40.004926437324009, -8.183163009231674],
              [-40.004926398361505, -8.183163087826225],
              [-40.004926358636276, -8.183163166040805],
              [-40.004926318152073, -8.183163243868119],
              [-40.00492627691267, -8.183163321300803],
              [-40.004926234921996, -8.183163398331613],
              [-40.004926192183937, -8.183163474953336],
              [-40.004926148702538, -8.18316355115871],
              [-40.004926104481882, -8.18316362694061],
              [-40.004926059526106, -8.183163702291901],
              [-40.004926013839459, -8.183163777205548],
              [-40.004925967426203, -8.183163851674484],
              [-40.004925920290731, -8.183163925691705],
              [-40.004925872437454, -8.183163999250278],
              [-40.004925823870863, -8.183164072343263],
              [-40.004925774595534, -8.183164144963801],
              [-40.004925724616072, -8.183164217105086],
              [-40.004925673937208, -8.183164288760349],
              [-40.004925622563654, -8.183164359922843],
              [-40.004925570500284, -8.183164430585865],
              [-40.004925517751964, -8.183164500742807],
              [-40.004925464323662, -8.183164570387081],
              [-40.004925410220388, -8.183164639512119],
              [-40.00492535544722, -8.183164708111446],
              [-40.004925300009333, -8.183164776178605],
              [-40.004925243911892, -8.183164843707241],
              [-40.004925187160175, -8.183164910690969],
              [-40.004925129759549, -8.183164977123479],
              [-40.004925071715391, -8.183165042998555],
              [-40.004925013033116, -8.183165108310016],
              [-40.00492495371828, -8.183165173051714],
              [-40.004924893776433, -8.183165237217583],
              [-40.004924833213217, -8.183165300801599],
              [-40.004924772034336, -8.183165363797787],
              [-40.004924710245497, -8.183165426200196],
              [-40.004924647852548, -8.18316548800297],
              [-40.004924584861321, -8.183165549200321],
              [-40.004924521277736, -8.183165609786508],
              [-40.004924457107791, -8.183165669755839],
              [-40.004924392357474, -8.183165729102672],
              [-40.004924327032889, -8.183165787821428],
              [-40.004924261140189, -8.183165845906608],
              [-40.00492419468555, -8.183165903352732],
              [-40.004924127675196, -8.183165960154364],
              [-40.004924060115449, -8.183166016306231],
              [-40.004923992012642, -8.183166071803077],
              [-40.004923923373198, -8.183166126639618],
              [-40.004923854203525, -8.183166180810758],
              [-40.00492378451014, -8.183166234311418],
              [-40.004923714299593, -8.183166287136514],
              [-40.004923643578479, -8.183166339281099],
              [-40.004923572353427, -8.183166390740311],
              [-40.004923500631435, -8.18316644150868],
              [-40.004923471967594, -8.183166454662592],
              [-40.004234808135507, -8.183648955111664],
              [-40.004234796284607, -8.18364895599052],
              [-40.004234739217445, -8.18364899630067],
              [-40.004234682463753, -8.183649037047816],
              [-40.0042346260269, -8.183649078229529],
              [-40.004234569910267, -8.183649119843334],
              [-40.004234514117151, -8.183649161886803],
              [-40.004234458650892, -8.18364920435743],
              [-40.004234403514765, -8.183649247252667],
              [-40.00423434871206, -8.183649290569978],
              [-40.004234294246039, -8.183649334306793],
              [-40.004234240119892, -8.183649378460526],
              [-40.004234186336888, -8.183649423028561],
              [-40.004234132900194, -8.183649468008207],
              [-40.004234079812967, -8.183649513396848],
              [-40.004234027078404, -8.183649559191746],
              [-40.004233974699595, -8.183649605390167],
              [-40.004233922679688, -8.183649651989423],
              [-40.004233871021746, -8.183649698986722],
              [-40.004233819728846, -8.183649746379269],
              [-40.004233768804021, -8.183649794164234],
              [-40.004233718250319, -8.1836498423388],
              [-40.004233668070718, -8.183649890900115],
              [-40.004233618268223, -8.183649939845278],
              [-40.004233568845777, -8.183649989171387],
              [-40.004233519806306, -8.183650038875516],
              [-40.004233471152737, -8.183650088954723],
              [-40.004233422887971, -8.183650139405996],
              [-40.004233375014849, -8.183650190226373],
              [-40.00423332753622, -8.183650241412826],
              [-40.004233280454919, -8.183650292962298],
              [-40.004233233773718, -8.183650344871751],
              [-40.004233187495416, -8.183650397138097],
              [-40.004233141622748, -8.183650449758247],
              [-40.00423309615843, -8.183650502729021],
              [-40.004233051105182, -8.183650556047322],
              [-40.004233006465661, -8.183650609710002],
              [-40.004232962242526, -8.183650663713841],
              [-40.004232918438397, -8.183650718055613],
              [-40.004232875055898, -8.183650772732113],
              [-40.004232832097564, -8.183650827740077],
              [-40.004232789565989, -8.183650883076272],
              [-40.004232747463682, -8.183650938737408],
              [-40.004232705793143, -8.18365099472015],
              [-40.004232664556838, -8.183651051021183],
              [-40.004232623757233, -8.183651107637191],
              [-40.004232583396735, -8.183651164564772],
              [-40.004232543477769, -8.183651221800545],
              [-40.004232504002658, -8.183651279341158],
              [-40.004232464973775, -8.183651337183147],
              [-40.004232426393457, -8.183651395323089],
              [-40.004232388263965, -8.183651453757555],
              [-40.004232350587571, -8.18365151248306],
              [-40.004232313366515, -8.183651571496101],
              [-40.004232276603013, -8.183651630793175],
              [-40.004232240299245, -8.183651690370773],
              [-40.00423220445736, -8.183651750225369],
              [-40.004232169079494, -8.183651810353362],
              [-40.004232134167729, -8.183651870751252],
              [-40.004232099724177, -8.18365193141539],
              [-40.004232065750855, -8.183651992342201],
              [-40.004232032249782, -8.183652053528059],
              [-40.004231999222966, -8.183652114969302],
              [-40.004231966672329, -8.18365217666234],
              [-40.004231934599851, -8.183652238603457],
              [-40.00423190300743, -8.183652300789031],
              [-40.004231871896899, -8.183652363215314],
              [-40.004231841270155, -8.183652425878632],
              [-40.004231811128989, -8.183652488775257],
              [-40.004231781475205, -8.18365255190144],
              [-40.00423175231056, -8.183652615253424],
              [-40.004231723636778, -8.183652678827467],
              [-40.004231695455559, -8.18365274261977],
              [-40.0042316677686, -8.183652806626592],
              [-40.00423164057753, -8.183652870844087],
              [-40.004231613883952, -8.183652935268464],
              [-40.004231587689496, -8.183652999895907],
              [-40.00423156199566, -8.183653064722536],
              [-40.004231536804006, -8.183653129744531],
              [-40.004231512116007, -8.183653194958005],
              [-40.004231487933161, -8.183653260359119],
              [-40.004231464256868, -8.183653325943977],
              [-40.004231441088564, -8.183653391708699],
              [-40.004231418429619, -8.183653457649358],
              [-40.004231396281355, -8.183653523762025],
              [-40.004231374645116, -8.183653590042788],
              [-40.004231353522172, -8.183653656487721],
              [-40.00423133291379, -8.183653723092865],
              [-40.004231312821176, -8.183653789854249],
              [-40.004231293245532, -8.183653856767947],
              [-40.004231274188022, -8.183653923829972],
              [-40.004231255649792, -8.183653991036335],
              [-40.004231237631906, -8.183654058383031],
              [-40.004231220135466, -8.183654125866079],
              [-40.004231203161496, -8.18365419348147],
              [-40.00423118671101, -8.183654261225172],
              [-40.004231170784998, -8.183654329093203],
              [-40.004231155384375, -8.183654397081478],
              [-40.004231140510072, -8.183654465185974],
              [-40.004231126162985, -8.183654533402684],
              [-40.004231112343959, -8.183654601727516],
              [-40.004231100517423, -8.183654571712689],
              [-40.00409584224429, -8.184336977990874],
              [-40.004095816778737, -8.184337111740382],
              [-40.004095793336624, -8.184337245856637],
              [-40.004095771923261, -8.184337380309284],
              [-40.004095752543506, -8.184337515067833],
              [-40.004095735201723, -8.184337650101718],
              [-40.004095719901862, -8.184337785380404],
              [-40.004095706647391, -8.184337920873238],
              [-40.004095695441315, -8.184338056549473],
              [-40.004095686286156, -8.184338192378368],
              [-40.004095679183997, -8.184338328329213],
              [-40.004095674136451, -8.184338464371177],
              [-40.004095671144654, -8.18433860047341],
              [-40.004095670209296, -8.184338736605081],
              [-40.004095671330575, -8.184338872735378],
              [-40.00409567450825, -8.184339008833405],
              [-40.004095679741582, -8.184339144868424],
              [-40.00409568702942, -8.184339280809489],
              [-40.004095696370086, -8.184339416625884],
              [-40.004095707761458, -8.184339552286792],
              [-40.004095721200976, -8.184339687761494],
              [-40.004095736685578, -8.184339823019313],
              [-40.004095754211761, -8.184339958029591],
              [-40.004095773775553, -8.184340092761728],
              [-40.004095795372528, -8.184340227185228],
              [-40.00409581899779, -8.184340361269578],
              [-40.004095844645981, -8.184340494984438],
              [-40.004095872311296, -8.18434062829955],
              [-40.004095901987476, -8.1843407611846],
              [-40.004095933667763, -8.184340893609573],
              [-40.004095967345016, -8.184341025544473],
              [-40.00409600301159, -8.184341156959324],
              [-40.004096040659398, -8.184341287824401],
              [-40.004096080279929, -8.184341418110071],
              [-40.004096121864173, -8.184341547786795],
              [-40.00409616540275, -8.184341676825182],
              [-40.00409621088577, -8.184341805196002],
              [-40.004096258302923, -8.184341932870199],
              [-40.004096307643486, -8.184342059818803],
              [-40.00409635889627, -8.184342186013058],
              [-40.004096412049655, -8.184342311424386],
              [-40.004096467091607, -8.184342436024385],
              [-40.00409652400964, -8.184342559784762],
              [-40.004096582790886, -8.184342682677572],
              [-40.004096643422002, -8.184342804674914],
              [-40.00409670588926, -8.184342925749148],
              [-40.004096770178506, -8.184343045872845],
              [-40.004096836275188, -8.184343165018824],
              [-40.004096904164307, -8.184343283160029],
              [-40.004096973830492, -8.184343400269736],
              [-40.004097045257957, -8.184343516321396],
              [-40.004097118430522, -8.184343631288703],
              [-40.00409719333161, -8.184343745145654],
              [-40.004097269944232, -8.184343857866383],
              [-40.004097348251065, -8.184343969425413],
              [-40.004097428234331, -8.184344079797429],
              [-40.004097509875933, -8.184344188957448],
              [-40.004097593157361, -8.184344296880738],
              [-40.00409767805975, -8.184344403542827],
              [-40.004097764563859, -8.184344508919562],
              [-40.004097852650091, -8.184344612987067],
              [-40.004097942298486, -8.184344715721741],
              [-40.004098033488745, -8.184344817100321],
              [-40.004098126200184, -8.184344917099859],
              [-40.004098220411827, -8.184345015697687],
              [-40.004098316102322, -8.18434511287145],
              [-40.004098413249956, -8.184345208599153],
              [-40.004098511832744, -8.184345302859096],
              [-40.004098611828347, -8.184345395629915],
              [-40.004098713214113, -8.184345486890603],
              [-40.004098815967062, -8.184345576620467],
              [-40.004098920063903, -8.184345664799196],
              [-40.004099025481104, -8.184345751406799],
              [-40.004099132194717, -8.184345836423679],
              [-40.004099240180579, -8.184345919830525],
              [-40.004099349414247, -8.184346001608459],
              [-40.00409945987095, -8.184346081738981],
              [-40.004099571525686, -8.184346160203908],
              [-40.004099684353122, -8.184346236985483],
              [-40.004099798327729, -8.184346312066276],
              [-40.004099913423659, -8.184346385429309],
              [-40.00410002961484, -8.184346457057933],
              [-40.004100146874954, -8.184346526935943],
              [-40.004100265177442, -8.184346595047501],
              [-40.004100384495466, -8.184346661377136],
              [-40.004100504802025, -8.184346725909879],
              [-40.004100626069842, -8.18434678863111],
              [-40.004100748271462, -8.184346849526555],
              [-40.004100871379187, -8.184346908582489],
              [-40.0041009953651, -8.184346965785453],
              [-40.004101120201156, -8.18434702112256],
              [-40.004101245859033, -8.184347074581231],
              [-40.004101372310274, -8.184347126149342],
              [-40.004101499526236, -8.184347175815274],
              [-40.004101627478072, -8.184347223567711],
              [-40.004101756136812, -8.18434726939585],
              [-40.004101885473311, -8.184347313289313],
              [-40.004102015458258, -8.18434735523816],
              [-40.004102146062181, -8.184347395232868],
              [-40.004102277255527, -8.184347433264405],
              [-40.004102304123258, -8.184347403812771],
              [-40.005058636788014, -8.184616892985995],
              [-40.005058646277242, -8.184616911050677],
              [-40.005058809912278, -8.184616958816427],
              [-40.005058972617924, -8.184617009636211],
              [-40.00505913433679, -8.184617063492128],
              [-40.005059295011783, -8.184617120365154],
              [-40.005059454586231, -8.184617180235195],
              [-40.005059613003816, -8.184617243081176],
              [-40.005059770208661, -8.18461730888087],
              [-40.005059926145236, -8.184617377611088],
              [-40.005060080758582, -8.184617449247567],
              [-40.005060233994108, -8.184617523765056],
              [-40.005060385797741, -8.184617601137214],
              [-40.005060536115934, -8.184617681336773],
              [-40.005060684895632, -8.184617764335419],
              [-40.005060832084325, -8.184617850103868],
              [-40.005060977630102, -8.184617938611876],
              [-40.005061121481596, -8.184618029828171],
              [-40.005061263588033, -8.184618123720602],
              [-40.00506140389929, -8.184618220256004],
              [-40.005061542365844, -8.184618319400354],
              [-40.005061678938851, -8.184618421118666],
              [-40.005061813570094, -8.184618525375011],
              [-40.005061946212074, -8.184618632132615],
              [-40.005062076817993, -8.184618741353823],
              [-40.005062205341773, -8.18461885300006],
              [-40.005062331738046, -8.184618967031978],
              [-40.005062455962232, -8.184619083409297],
              [-40.005062577970463, -8.184619202090982],
              [-40.005062697719715, -8.184619323035138],
              [-40.005062815167726, -8.184619446199083],
              [-40.00506293027307, -8.18461957153937],
              [-40.005063042995097, -8.184619699011783],
              [-40.005063153294039, -8.184619828571314],
              [-40.005063261131006, -8.184619960172276],
              [-40.005063366467915, -8.18462009376821],
              [-40.005063469267618, -8.18462022931196],
              [-40.005063569493807, -8.184620366755741],
              [-40.005063667111152, -8.18462050605104],
              [-40.005063762085193, -8.184620647148662],
              [-40.005063854382414, -8.184620789998887],
              [-40.005063943970264, -8.184620934551262],
              [-40.00506403081711, -8.184621080754766],
              [-40.005064114892306, -8.184621228557873],
              [-40.005064196166195, -8.184621377908382],
              [-40.005064274610099, -8.18462152875359],
              [-40.005064350196342, -8.184621681040277],
              [-40.005064422898251, -8.18462183471472],
              [-40.00506449269016, -8.184621989722675],
              [-40.005064559547449, -8.184622146009451],
              [-40.005064623446529, -8.184622303519918],
              [-40.005064684364868, -8.184622462198478],
              [-40.005064742280943, -8.184622621989151],
              [-40.005064797174306, -8.184622782835538],
              [-40.005064849025636, -8.184622944680896],
              [-40.005064897816588, -8.18462310746809],
              [-40.005064943529987, -8.184623271139699],
              [-40.005064986149677, -8.184623435637981],
              [-40.00506502566062, -8.184623600904899],
              [-40.005065062048878, -8.184623766882096],
              [-40.005065095301624, -8.184623933511039],
              [-40.0050651254071, -8.184624100732886],
              [-40.00506515235471, -8.184624268488694],
              [-40.005065176134906, -8.184624436719222],
              [-40.005065196739324, -8.184624605365107],
              [-40.005065214160702, -8.184624774366865],
              [-40.00506522839288, -8.184624943664845],
              [-40.005065239430841, -8.184625113199326],
              [-40.005065247270672, -8.184625282910476],
              [-40.005065251909635, -8.184625452738418],
              [-40.005065253346075, -8.184625622623198],
              [-40.005065251579488, -8.18462579250488],
              [-40.005065246610485, -8.184625962323546],
              [-40.005065238440856, -8.184626132019261],
              [-40.005065227073452, -8.184626301532136],
              [-40.005065212512285, -8.184626470802343],
              [-40.005065194762516, -8.184626639770165],
              [-40.005065173830388, -8.184626808376018],
              [-40.005065149723272, -8.184626976560351],
              [-40.005065122449714, -8.184627144263841],
              [-40.005065092019315, -8.184627311427306],
              [-40.005065058442817, -8.184627477991754],
              [-40.005065021732065, -8.184627643898471],
              [-40.005064981900006, -8.184627809088843],
              [-40.005064938960729, -8.184627973504568],
              [-40.00506489292934, -8.184628137087696],
              [-40.005064843822097, -8.184628299780449],
              [-40.005064791656352, -8.184628461525493],
              [-40.005064736450493, -8.184628622265658],
              [-40.005064678223981, -8.184628781944305],
              [-40.005064616997402, -8.184628940505046],
              [-40.005064552792327, -8.184629097891936],
              [-40.005064485631436, -8.184629254049446],
              [-40.005064415538421, -8.184629408922468],
              [-40.005064342537999, -8.184629562456362],
              [-40.00506426665595, -8.184629714596966],
              [-40.005064187919032, -8.184629865290546],
              [-40.005064106355036, -8.184630014483982],
              [-40.005064021992759, -8.184630162124593],
              [-40.005063934861937, -8.184630308160282],
              [-40.005063844993799, -8.184630452539208],
              [-40.005063844818913, -8.184630446274431],
              [-40.004587801662957, -8.18537940219902],
              [-40.004587825161074, -8.185379407356718],
              [-40.004587767457473, -8.185379499361881],
              [-40.004587710865721, -8.185379592050099],
              [-40.004587655393955, -8.18537968540797],
              [-40.004587601050169, -8.185379779422149],
              [-40.004587547842164, -8.185379874079045],
              [-40.004587495777635, -8.185379969365075],
              [-40.004587444864043, -8.185380065266507],
              [-40.004587395108722, -8.185380161769526],
              [-40.004587346518811, -8.185380258860283],
              [-40.004587299101345, -8.185380356524796],
              [-40.004587252863111, -8.185380454748996],
              [-40.004587207810786, -8.185380553518746],
              [-40.004587163950838, -8.185380652819839],
              [-40.004587121289575, -8.185380752637991],
              [-40.004587079833158, -8.185380852958829],
              [-40.004587039587527, -8.185380953767952],
              [-40.004587000558487, -8.185381055050826],
              [-40.004586962751659, -8.185381156792877],
              [-40.004586926172472, -8.185381258979477],
              [-40.004586890826204, -8.185381361595866],
              [-40.004586856717914, -8.185381464627346],
              [-40.004586823852534, -8.185381568059089],
              [-40.00458679223479, -8.185381671876135],
              [-40.004586761869234, -8.185381776063656],
              [-40.00458673276021, -8.185381880606588],
              [-40.004586704911944, -8.18538198548989],
              [-40.004586678328423, -8.185382090698496],
              [-40.004586653013476, -8.185382196217242],
              [-40.004586628970742, -8.18538230203097],
              [-40.004586606203667, -8.185382408124401],
              [-40.004586584715568, -8.185382514482294],
              [-40.004586564509509, -8.185382621089394],
              [-40.004586545588403, -8.185382727930314],
              [-40.00458652795497, -8.185382834989698],
              [-40.004586511611755, -8.185382942252113],
              [-40.004586496561089, -8.185383049702144],
              [-40.004586482805166, -8.185383157324337],
              [-40.004586470345942, -8.185383265103177],
              [-40.004586459185219, -8.185383373023173],
              [-40.004586449324606, -8.185383481068788],
              [-40.004586440765529, -8.185383589224472],
              [-40.004586433509196, -8.185383697474693],
              [-40.004586427556667, -8.185383805803857],
              [-40.004586422908808, -8.18538391419634],
              [-40.00458641956628, -8.185384022636617],
              [-40.004586417529552, -8.185384131109004],
              [-40.004586416798936, -8.185384239597935],
              [-40.004586417374504, -8.185384348087817],
              [-40.004586419256221, -8.185384456562991],
              [-40.004586422443779, -8.185384565007881],
              [-40.004586426936726, -8.185384673406867],
              [-40.004586432734428, -8.185384781744359],
              [-40.004586439836054, -8.185384890004766],
              [-40.004586448240559, -8.185384998172514],
              [-40.004586457946758, -8.185385106232008],
              [-40.004586468953228, -8.185385214167736],
              [-40.004586481258414, -8.18538532196415],
              [-40.004586494860526, -8.18538542960574],
              [-40.004586509757608, -8.185385537077011],
              [-40.004586525947531, -8.185385644362512],
              [-40.004586543427948, -8.185385751446772],
              [-40.004586562196344, -8.185385858314444],
              [-40.004586582250035, -8.185385964950081],
              [-40.004586603586112, -8.185386071338344],
              [-40.004586626201537, -8.185386177463997],
              [-40.004586650093025, -8.185386283311722],
              [-40.004586675257151, -8.185386388866272],
              [-40.0045867016903, -8.185386494112466],
              [-40.004586729388649, -8.185386599035169],
              [-40.004586758348232, -8.185386703619267],
              [-40.004586788564865, -8.185386807849756],
              [-40.004586820034213, -8.185386911711571],
              [-40.004586852751743, -8.185387015189828],
              [-40.004586886712751, -8.185387118269587],
              [-40.004586921912349, -8.185387220936045],
              [-40.004586958345456, -8.185387323174412],
              [-40.00458699600685, -8.185387424969973],
              [-40.004587034891109, -8.185387526308123],
              [-40.004587074992621, -8.185387627174233],
              [-40.004587116305643, -8.185387727553803],
              [-40.004587158824208, -8.185387827432372],
              [-40.00458720254219, -8.185387926795601],
              [-40.004587247453316, -8.185388025629187],
              [-40.004587293551126, -8.185388123918884],
              [-40.004587340828991, -8.185388221650568],
              [-40.00458738928009, -8.185388318810155],
              [-40.004587438897445, -8.185388415383686],
              [-40.004587489673945, -8.185388511357241],
              [-40.004587541602255, -8.185388606717032],
              [-40.004587594674923, -8.185388701449321],
              [-40.004587648884296, -8.185388795540472],
              [-40.004587704222594, -8.185388888976979],
              [-40.004587760681829, -8.18538898174538],
              [-40.004587818253896, -8.185389073832303],
              [-40.004587876930486, -8.185389165224496],
              [-40.004587936703189, -8.185389255908854],
              [-40.004587997563391, -8.185389345872281],
              [-40.004588059502318, -8.185389435101856],
              [-40.00458812251108, -8.18538952358475],
              [-40.004588099361172, -8.185389530924471],
              [-40.005281019699872, -8.186350286715459],
              [-40.005281027611254, -8.186350300292304],
              [-40.0052810785525, -8.186350370217923],
              [-40.005281130161862, -8.186350439655504],
              [-40.005281182434651, -8.186350508598684],
              [-40.005281235366091, -8.186350577041162],
              [-40.005281288951352, -8.186350644976716],
              [-40.005281343185544, -8.186350712399129],
              [-40.005281398063701, -8.186350779302259],
              [-40.005281453580849, -8.186350845680005],
              [-40.00528150973188, -8.186350911526283],
              [-40.005281566511698, -8.186350976835108],
              [-40.005281623915124, -8.186351041600512],
              [-40.0052816819369, -8.186351105816591],
              [-40.005281740571739, -8.186351169477488],
              [-40.005281799814284, -8.186351232577382],
              [-40.005281859659149, -8.18635129511051],
              [-40.005281920100856, -8.186351357071169],
              [-40.005281981133876, -8.186351418453707],
              [-40.005282042752668, -8.186351479252517],
              [-40.00528210495159, -8.186351539462041],
              [-40.005282167724971, -8.186351599076808],
              [-40.005282231067078, -8.186351658091366],
              [-40.005282294972119, -8.18635171650034],
              [-40.00528235943429, -8.186351774298368],
              [-40.005282424447707, -8.186351831480199],
              [-40.005282490006401, -8.186351888040635],
              [-40.005282556104426, -8.186351943974492],
              [-40.005282622735734, -8.186351999276663],
              [-40.00528268989423, -8.186352053942088],
              [-40.005282757573816, -8.186352107965817],
              [-40.005282825768283, -8.186352161342871],
              [-40.005282894471442, -8.186352214068426],
              [-40.005282963676983, -8.18635226613762],
              [-40.005283033378632, -8.186352317545738],
              [-40.005283103569994, -8.186352368288082],
              [-40.005283174244681, -8.186352418359995],
              [-40.005283245396235, -8.186352467756951],
              [-40.005283317018183, -8.186352516474415],
              [-40.005283389103944, -8.186352564507953],
              [-40.005283461646975, -8.186352611853174],
              [-40.005283534640654, -8.186352658505758],
              [-40.005283608078315, -8.186352704461452],
              [-40.005283681953244, -8.186352749716034],
              [-40.005283756258713, -8.186352794265417],
              [-40.005283830987949, -8.186352838105519],
              [-40.005283906134096, -8.186352881232338],
              [-40.005283981690326, -8.18635292364193],
              [-40.005284057649739, -8.186352965330416],
              [-40.005284134005393, -8.186353006294027],
              [-40.005284210750339, -8.186353046528989],
              [-40.005284287877558, -8.186353086031662],
              [-40.00528436538, -8.186353124798392],
              [-40.005284443250609, -8.186353162825702],
              [-40.005284521482288, -8.186353200110092],
              [-40.005284600067874, -8.18635323664815],
              [-40.005284679000205, -8.186353272436536],
              [-40.005284758272076, -8.186353307472009],
              [-40.00528483787626, -8.18635334175136],
              [-40.005284917805476, -8.186353375271443],
              [-40.00528499805246, -8.186353408029234],
              [-40.005285078609852, -8.186353440021714],
              [-40.005285159470311, -8.186353471246017],
              [-40.005285240626471, -8.186353501699228],
              [-40.005285322070904, -8.186353531378581],
              [-40.005285403796215, -8.186353560281391],
              [-40.0052854857949, -8.186353588405019],
              [-40.005285568059492, -8.186353615746865],
              [-40.005285650582508, -8.186353642304462],
              [-40.005285733356374, -8.186353668075364],
              [-40.005285816373558, -8.186353693057251],
              [-40.0052858996265, -8.186353717247837],
              [-40.005285983107569, -8.186353740644876],
              [-40.005286066809163, -8.186353763246268],
              [-40.005286150723656, -8.186353785049947],
              [-40.005286234843368, -8.186353806053924],
              [-40.005286319160632, -8.18635382625628],
              [-40.005286403667746, -8.186353845655148],
              [-40.005286488357001, -8.186353864248797],
              [-40.00528657322068, -8.18635388203551],
              [-40.005286658251016, -8.18635389901365],
              [-40.005286743440266, -8.186353915181694],
              [-40.005286828780648, -8.186353930538161],
              [-40.005286914264374, -8.186353945081636],
              [-40.005286999883651, -8.186353958810807],
              [-40.005287085630663, -8.186353971724397],
              [-40.005287171497557, -8.186353983821252],
              [-40.005287257476532, -8.186353995100271],
              [-40.005287343559729, -8.186354005560402],
              [-40.00528742973929, -8.186354015200699],
              [-40.00528751600735, -8.186354024020293],
              [-40.005287602356042, -8.186354032018375],
              [-40.005287688777472, -8.186354039194194],
              [-40.00528777526376, -8.186354045547121],
              [-40.00528786180702, -8.186354051076561],
              [-40.005287948399342, -8.186354055782029],
              [-40.005288035032834, -8.186354059663079],
              [-40.005288121699586, -8.186354062719357],
              [-40.005288208391669, -8.186354064950571],
              [-40.005288295101217, -8.18635406635657],
              [-40.005288381820279, -8.186354066937181],
              [-40.005288421867839, -8.186354066837483],
              [-40.006043429696462, -8.186352186630289],
              [-40.006043443722035, -8.186352186595325],
              [-40.006043560362983, -8.186352187051414],
              [-40.00604367698844, -8.186352189000766],
              [-40.00604379357911, -8.186352192443081],
              [-40.006043910115764, -8.186352197377769],
              [-40.006044026579161, -8.186352203804013],
              [-40.00604414295006, -8.186352211720767],
              [-40.006044259209254, -8.186352221126713],
              [-40.006044375337538, -8.186352232020322],
              [-40.006044491315762, -8.186352244399785],
              [-40.006044607124743, -8.186352258263042],
              [-40.006044722745379, -8.18635227360782],
              [-40.006044838158559, -8.186352290431568],
              [-40.006044953345238, -8.186352308731522],
              [-40.006045068286404, -8.186352328504666],
              [-40.006045182963085, -8.186352349747708],
              [-40.006045297356337, -8.186352372457154],
              [-40.006045411447239, -8.186352396629271],
              [-40.006045525216997, -8.186352422260038],
              [-40.006045638646818, -8.186352449345243],
              [-40.006045751717963, -8.186352477880403],
              [-40.00604586441176, -8.186352507860841],
              [-40.006045976709594, -8.186352539281554],
              [-40.006046088592946, -8.186352572137386],
              [-40.006046200043329, -8.186352606422901],
              [-40.00604631104234, -8.186352642132441],
              [-40.006046421571639, -8.186352679260098],
              [-40.006046531613002, -8.186352717799771],
              [-40.006046641148238, -8.186352757745091],
              [-40.006046750159271, -8.186352799089427],
              [-40.006046858628103, -8.18635284182599],
              [-40.006046966536822, -8.186352885947711],
              [-40.006047073867592, -8.18635293144729],
              [-40.006047180602728, -8.186352978317224],
              [-40.006047286724574, -8.186353026549776],
              [-40.006047392215613, -8.186353076136987],
              [-40.006047497058439, -8.186353127070678],
              [-40.006047601235721, -8.186353179342438],
              [-40.006047704730285, -8.186353232943617],
              [-40.006047807525029, -8.186353287865341],
              [-40.006047909602962, -8.186353344098595],
              [-40.006048010947246, -8.186353401634072],
              [-40.006048111541148, -8.186353460462257],
              [-40.006048211368068, -8.18635352057346],
              [-40.006048310411501, -8.186353581957761],
              [-40.006048408655097, -8.186353644604997],
              [-40.006048506082649, -8.186353708504839],
              [-40.00604860267805, -8.18635377364671],
              [-40.006048698425374, -8.186353840019894],
              [-40.006048793308807, -8.186353907613414],
              [-40.006048887312645, -8.186353976416106],
              [-40.006048980421404, -8.186354046416607],
              [-40.006049072619724, -8.186354117603365],
              [-40.006049163892328, -8.186354189964618],
              [-40.006049254224187, -8.186354263488456],
              [-40.006049343600374, -8.186354338162696],
              [-40.00604943200613, -8.186354413975005],
              [-40.006049519426867, -8.18635449091289],
              [-40.006049605848126, -8.186354568963639],
              [-40.006049691255669, -8.186354648114341],
              [-40.006049775635354, -8.186354728351969],
              [-40.006049858973306, -8.186354809663275],
              [-40.006049941255718, -8.186354892034769],
              [-40.006050022469005, -8.186354975452906],
              [-40.006050102599772, -8.186355059903908],
              [-40.006050181634791, -8.18635514537382],
              [-40.006050259561007, -8.186355231848534],
              [-40.006050336365547, -8.186355319313753],
              [-40.006050412035741, -8.186355407755059],
              [-40.006050486559076, -8.186355497157827],
              [-40.006050559923267, -8.186355587507313],
              [-40.006050632116185, -8.186355678788605],
              [-40.006050703125915, -8.186355770986609],
              [-40.006050772940746, -8.186355864086114],
              [-40.006050841549126, -8.186355958071731],
              [-40.006050908939727, -8.186356052927986],
              [-40.006050975101452, -8.186356148639149],
              [-40.006051040023344, -8.186356245189472],
              [-40.006051103694695, -8.186356342563004],
              [-40.00605116610501, -8.186356440743655],
              [-40.006051227243951, -8.186356539715227],
              [-40.006051287101435, -8.186356639461332],
              [-40.006051345667579, -8.186356739965557],
              [-40.006051402932734, -8.186356841211287],
              [-40.006051458887413, -8.186356943181796],
              [-40.006051513522401, -8.186357045860273],
              [-40.006051566828653, -8.186357149229714],
              [-40.006051618797386, -8.18635725327308],
              [-40.00605166942001, -8.186357357973202],
              [-40.00605171868817, -8.18635746331277],
              [-40.006051766593728, -8.186357569274405],
              [-40.006051813128778, -8.186357675840615],
              [-40.00605185828563, -8.186357782993792],
              [-40.006051902056832, -8.186357890716261],
              [-40.006051944435164, -8.186357998990214],
              [-40.00605198541362, -8.186358107797785],
              [-40.006052024985422, -8.186358217121008],
              [-40.006052063144068, -8.186358326941814],
              [-40.006052099883227, -8.186358437242074],
              [-40.006052135196285, -8.186358548004296],
              [-40.006052159995839, -8.186358586235633],
              [-40.006329971218165, -8.187249786263351],
              [-40.006329985184003, -8.187249829133908],
              [-40.006330005726731, -8.187249897235061],
              [-40.006330025731693, -8.187249965494928],
              [-40.006330045197636, -8.187250033909336],
              [-40.006330064123368, -8.187250102474046],
              [-40.006330082507723, -8.187250171184825],
              [-40.006330100349551, -8.187250240037404],
              [-40.006330117647778, -8.187250309027569],
              [-40.00633013440131, -8.187250378151065],
              [-40.006330150609116, -8.187250447403597],
              [-40.006330166270189, -8.187250516780887],
              [-40.006330181383575, -8.187250586278678],
              [-40.006330195948351, -8.187250655892626],
              [-40.006330209963608, -8.187250725618467],
              [-40.006330223428471, -8.187250795451911],
              [-40.006330236342095, -8.1872508653886],
              [-40.006330248703726, -8.187250935424268],
              [-40.006330260512549, -8.187251005554542],
              [-40.006330271767887, -8.187251075775126],
              [-40.006330282469023, -8.187251146081651],
              [-40.006330292615274, -8.187251216469818],
              [-40.006330302206024, -8.187251286935231],
              [-40.006330311240717, -8.187251357473567],
              [-40.006330319718757, -8.187251428080494],
              [-40.006330327639631, -8.187251498751593],
              [-40.006330335002843, -8.187251569482539],
              [-40.006330341807946, -8.187251640268949],
              [-40.006330348054519, -8.187251711106464],
              [-40.006330353742172, -8.187251781990675],
              [-40.006330358870571, -8.187251852917264],
              [-40.006330363439361, -8.187251923881801],
              [-40.0063303674483, -8.18725199487991],
              [-40.006330370897125, -8.187252065907233],
              [-40.006330373785623, -8.187252136959362],
              [-40.006330376113617, -8.187252208031913],
              [-40.006330377880957, -8.187252279120528],
              [-40.006330379087522, -8.187252350220733],
              [-40.006330379733285, -8.187252421328221],
              [-40.006330379818152, -8.187252492438546],
              [-40.006330379342153, -8.187252563547345],
              [-40.0063303783053, -8.187252634650248],
              [-40.006330376707659, -8.187252705742802],
              [-40.006330374549336, -8.187252776820689],
              [-40.006330371830458, -8.187252847879449],
              [-40.006330368551204, -8.187252918914744],
              [-40.006330364711758, -8.18725298992217],
              [-40.006330360312369, -8.187253060897321],
              [-40.006330355353299, -8.187253131835842],
              [-40.006330349834876, -8.187253202733359],
              [-40.006330343757419, -8.187253273585469],
              [-40.006330337121298, -8.187253344387793],
              [-40.006330329926953, -8.187253415136],
              [-40.006330322174811, -8.187253485825662],
              [-40.006330313865348, -8.187253556452461],
              [-40.006330304999068, -8.187253627012021],
              [-40.006330295576547, -8.187253697499983],
              [-40.006330285598345, -8.187253767911994],
              [-40.006330275065075, -8.187253838243686],
              [-40.006330263977404, -8.187253908490741],
              [-40.006330252336006, -8.187253978648803],
              [-40.006330240141594, -8.187254048713548],
              [-40.00633022739494, -8.187254118680682],
              [-40.006330214096828, -8.187254188545825],
              [-40.006330200248073, -8.187254258304693],
              [-40.006330185849507, -8.187254327952957],
              [-40.006330170902061, -8.187254397486351],
              [-40.00633015540663, -8.187254466900544],
              [-40.006330139364181, -8.18725453619129],
              [-40.006330122775701, -8.187254605354285],
              [-40.006330105642228, -8.18725467438524],
              [-40.006330087964798, -8.187254743279951],
              [-40.006330069744521, -8.187254812034086],
              [-40.00633005098252, -8.187254880643449],
              [-40.006330031679937, -8.187254949103794],
              [-40.006330011837974, -8.18725501741087],
              [-40.006329991457882, -8.187255085560531],
              [-40.006329970540882, -8.187255153548477],
              [-40.006329949088276, -8.187255221370544],
              [-40.006329927101383, -8.187255289022554],
              [-40.006329904581591, -8.187255356500337],
              [-40.006329881530256, -8.18725542379967],
              [-40.00632985794882, -8.187255490916458],
              [-40.006329833838727, -8.187255557846536],
              [-40.006329809201482, -8.187255624585779],
              [-40.006329784038606, -8.187255691130026],
              [-40.006329758351633, -8.18725575747523],
              [-40.006329732142177, -8.187255823617226],
              [-40.006329705411815, -8.187255889552002],
              [-40.00632967816226, -8.187255955275404],
              [-40.006329650395131, -8.187256020783428],
              [-40.00632962211219, -8.187256086072038],
              [-40.006329593315151, -8.187256151137182],
              [-40.00632956400581, -8.187256215974822],
              [-40.00632953418598, -8.187256280581005],
              [-40.006329503857494, -8.187256344951683],
              [-40.00632947302222, -8.187256409082915],
              [-40.00632944168207, -8.187256472970732],
              [-40.006329409838997, -8.187256536611196],
              [-40.006329377494929, -8.187256600000362],
              [-40.006329344652215, -8.187256663133503],
              [-40.006329367029657, -8.187256704236521],
              [-40.005637979131734, -8.188572724377183],
              [-40.005637963330784, -8.188572691144605],
              [-40.005637958171796, -8.188572700949951],
              [-40.005637953000765, -8.188572710749023],
              [-40.005637947817704, -8.188572720541758],
              [-40.00563794262262, -8.188572730328175],
              [-40.0056379374155, -8.188572740108235],
              [-40.005637932196386, -8.188572749881958],
              [-40.005637926965257, -8.188572759649292],
              [-40.005637921722133, -8.188572769410239],
              [-40.005637916467023, -8.188572779164812],
              [-40.005637911199933, -8.18857278891293],
              [-40.005637905920871, -8.188572798654642],
              [-40.005637900629843, -8.18857280838988],
              [-40.005637895326849, -8.188572818118669],
              [-40.005637890011919, -8.188572827840979],
              [-40.005637884685044, -8.188572837556814],
              [-40.005637879346232, -8.188572847266149],
              [-40.005637873995518, -8.188572856968939],
              [-40.005637868632867, -8.188572866665208],
              [-40.005637863258301, -8.188572876354936],
              [-40.005637857871832, -8.188572886038092],
              [-40.005637852473484, -8.188572895714669],
              [-40.005637847063241, -8.188572905384676],
              [-40.005637841641139, -8.188572915048077],
              [-40.005637836207157, -8.188572924704832],
              [-40.005637830761309, -8.188572934354982],
              [-40.00563782530363, -8.188572943998455],
              [-40.005637819834085, -8.188572953635267],
              [-40.005637814352724, -8.188572963265411],
              [-40.005637808859518, -8.188572972888874],
              [-40.005637803354496, -8.188572982505629],
              [-40.005637797837672, -8.188572992115644],
              [-40.005637792309031, -8.188573001718931],
              [-40.005637786768609, -8.188573011315478],
              [-40.005637781216393, -8.188573020905245],
              [-40.005637775652403, -8.188573030488238],
              [-40.005637770076639, -8.188573040064453],
              [-40.005637764489116, -8.188573049633856],
              [-40.00563775888984, -8.188573059196417],
              [-40.005637753278819, -8.188573068752154],
              [-40.005637747656067, -8.188573078301044],
              [-40.005637742021577, -8.188573087843061],
              [-40.005637736375377, -8.18857309737821],
              [-40.00563773071746, -8.188573106906448],
              [-40.005637725047855, -8.188573116427813],
              [-40.005637719366547, -8.188573125942229],
              [-40.005637713673558, -8.188573135449721],
              [-40.00563770796888, -8.188573144950247],
              [-40.00563770225255, -8.188573154443828],
              [-40.005637696524545, -8.188573163930418],
              [-40.005637690784909, -8.188573173410006],
              [-40.005637685033612, -8.188573182882617],
              [-40.005637679270684, -8.188573192348187],
              [-40.005637673496139, -8.188573201806717],
              [-40.005637667709962, -8.188573211258189],
              [-40.005637661912189, -8.188573220702621],
              [-40.00563765610282, -8.188573230139966],
              [-40.005637650281855, -8.188573239570214],
              [-40.005637644449315, -8.188573248993352],
              [-40.005637638605194, -8.188573258409356],
              [-40.005637632749512, -8.188573267818263],
              [-40.005637626882276, -8.188573277220007],
              [-40.005637621003494, -8.18857328661457],
              [-40.00563761511318, -8.188573296001966],
              [-40.00563760921132, -8.188573305382182],
              [-40.005637603297949, -8.188573314755184],
              [-40.005637597373067, -8.188573324120952],
              [-40.005637591436681, -8.188573333479505],
              [-40.005637585488806, -8.188573342830828],
              [-40.005637579529449, -8.188573352174867],
              [-40.005637573558602, -8.18857336151164],
              [-40.005637567576308, -8.188573370841116],
              [-40.005637561582546, -8.188573380163291],
              [-40.005637555577323, -8.188573389478163],
              [-40.005637549560682, -8.188573398785689],
              [-40.005637543532593, -8.188573408085864],
              [-40.005637537493094, -8.188573417378672],
              [-40.005637531442176, -8.188573426664114],
              [-40.005637525379861, -8.188573435942169],
              [-40.005637519306156, -8.188573445212842],
              [-40.005637513221053, -8.188573454476099],
              [-40.005637507124575, -8.188573463731906],
              [-40.005637501016729, -8.188573472980275],
              [-40.005637494897535, -8.188573482221177],
              [-40.005637488766979, -8.188573491454632],
              [-40.005637482625104, -8.18857350068061],
              [-40.005637476471868, -8.188573509899074],
              [-40.005637470307342, -8.188573519110038],
              [-40.005637464131489, -8.188573528313462],
              [-40.005637457944324, -8.188573537509351],
              [-40.005637451745876, -8.188573546697691],
              [-40.005637445536152, -8.188573555878476],
              [-40.005637439315137, -8.188573565051673],
              [-40.005637433082867, -8.188573574217269],
              [-40.005637426839343, -8.188573583375264],
              [-40.005637420584556, -8.188573592525637],
              [-40.005637414318535, -8.188573601668374],
              [-40.005637408041309, -8.188573610803472],
              [-40.005637401752843, -8.18857361993088],
              [-40.005637395453157, -8.188573629051344],
              [-40.005637436761468, -8.18857363014436],
              [-40.005015801965435, -8.189472359095394],
              [-40.005015761703206, -8.18947236011568],
              [-40.005015708075085, -8.189472438525847],
              [-40.00501565527555, -8.189472517492245],
              [-40.005015603310405, -8.189472597006185],
              [-40.005015552185384, -8.189472677058939],
              [-40.005015501906115, -8.18947275764169],
              [-40.005015452478126, -8.189472838745569],
              [-40.005015403906853, -8.189472920361608],
              [-40.005015356197653, -8.189473002480881],
              [-40.005015309355748, -8.189473085094313],
              [-40.005015263386348, -8.189473168192841],
              [-40.00501521829446, -8.189473251767273],
              [-40.005015174085081, -8.189473335808485],
              [-40.005015130763049, -8.189473420307133],
              [-40.00501508833316, -8.189473505254],
              [-40.005015046800068, -8.189473590639686],
              [-40.00501500616835, -8.189473676454817],
              [-40.005014966442474, -8.189473762689921],
              [-40.005014927626824, -8.18947384933554],
              [-40.005014889725651, -8.18947393638209],
              [-40.00501485274313, -8.189474023820042],
              [-40.005014816683364, -8.189474111639752],
              [-40.005014781550294, -8.189474199831544],
              [-40.005014747347794, -8.18947428838573],
              [-40.005014714079607, -8.189474377292523],
              [-40.005014681749429, -8.189474466542201],
              [-40.005014650360799, -8.189474556124889],
              [-40.005014619917169, -8.189474646030755],
              [-40.005014590421915, -8.189474736249874],
              [-40.005014561878234, -8.189474826772344],
              [-40.005014534289337, -8.189474917588198],
              [-40.005014507658203, -8.189475008687412],
              [-40.005014481987786, -8.189475100060003],
              [-40.005014457280929, -8.189475191695893],
              [-40.005014433540289, -8.189475283584994],
              [-40.00501441076856, -8.189475375717196],
              [-40.005014388968199, -8.189475468082353],
              [-40.005014368141595, -8.189475560670283],
              [-40.00501434829107, -8.189475653470806],
              [-40.005014329418813, -8.189475746473722],
              [-40.005014311526885, -8.189475839668807],
              [-40.005014294617254, -8.189475933045756],
              [-40.005014278691789, -8.189476026594338],
              [-40.00501426375223, -8.189476120304228],
              [-40.005014249800254, -8.189476214165126],
              [-40.005014236837354, -8.189476308166668],
              [-40.005014224864986, -8.189476402298556],
              [-40.005014213884451, -8.189476496550412],
              [-40.005014203896977, -8.189476590911839],
              [-40.005014194903637, -8.189476685372506],
              [-40.005014186905449, -8.189476779921938],
              [-40.005014179903263, -8.189476874549779],
              [-40.005014173897877, -8.189476969245607],
              [-40.005014168889929, -8.189477063999009],
              [-40.005014164879995, -8.189477158799519],
              [-40.005014161868509, -8.189477253636746],
              [-40.00501415985579, -8.189477348500223],
              [-40.005014158842066, -8.189477443379523],
              [-40.005014158827443, -8.189477538264207],
              [-40.005014159811942, -8.189477633143808],
              [-40.005014161795437, -8.189477728007898],
              [-40.005014164777705, -8.189477822846033],
              [-40.005014168758443, -8.189477917647794],
              [-40.005014173737173, -8.189478012402709],
              [-40.005014179713385, -8.189478107100376],
              [-40.005014186686417, -8.189478201730369],
              [-40.00501419465548, -8.189478296282241],
              [-40.005014203619716, -8.18947839074564],
              [-40.005014213578121, -8.189478485110133],
              [-40.00501422452961, -8.189478579365336],
              [-40.005014236472988, -8.189478673500886],
              [-40.005014249406919, -8.189478767506413],
              [-40.00501426332999, -8.189478861371562],
              [-40.005014278240672, -8.189478955086017],
              [-40.005014294137318, -8.189479048639454],
              [-40.005014311018179, -8.189479142021588],
              [-40.005014328881401, -8.189479235222121],
              [-40.005014347725002, -8.189479328230798],
              [-40.005014367546934, -8.18947942103741],
              [-40.005014388344996, -8.189479513631692],
              [-40.0050144101169, -8.189479606003511],
              [-40.005014432860257, -8.189479698142639],
              [-40.005014456572567, -8.189479790038998],
              [-40.005014481251223, -8.189479881682416],
              [-40.005014506893474, -8.189479973062845],
              [-40.005014533496535, -8.189480064170219],
              [-40.005014561057472, -8.189480154994513],
              [-40.005014589573236, -8.189480245525708],
              [-40.005014619040708, -8.189480335753851],
              [-40.005014649456626, -8.18948042566902],
              [-40.005014680817659, -8.189480515261309],
              [-40.005014713120346, -8.189480604520872],
              [-40.005014746361127, -8.189480693437844],
              [-40.005014780536349, -8.189480782002478],
              [-40.005014815642255, -8.189480870205015],
              [-40.005014851674964, -8.189480958035761],
              [-40.005014888630541, -8.189481045485024],
              [-40.005014926504892, -8.189481132543193],
              [-40.005014965293867, -8.189481219200662],
              [-40.005015004993155, -8.18948130544792],
              [-40.005015007459441, -8.189481314794863],
              [-40.005432817583589, -8.190376641261716],
              [-40.00543284854151, -8.19037664830473],
              [-40.005432855283701, -8.190376662718483],
              [-40.005432862051315, -8.19037667712043],
              [-40.005432868844323, -8.190376691510455],
              [-40.005432875662741, -8.190376705888594],
              [-40.005432882506504, -8.190376720254738],
              [-40.005432889375619, -8.190376734608895],
              [-40.005432896270051, -8.190376748950978],
              [-40.005432903189778, -8.190376763280989],
              [-40.005432910134786, -8.190376777598834],
              [-40.005432917105047, -8.190376791904475],
              [-40.005432924100568, -8.190376806197909],
              [-40.005432931121277, -8.190376820479047],
              [-40.005432938167189, -8.190376834747861],
              [-40.005432945238262, -8.190376849004329],
              [-40.00543295233448, -8.190376863248387],
              [-40.005432959455831, -8.190376877479967],
              [-40.005432966602292, -8.190376891699069],
              [-40.005432973773836, -8.190376905905643],
              [-40.005432980970433, -8.190376920099604],
              [-40.005432988192069, -8.190376934280952],
              [-40.005432995438724, -8.19037694844962],
              [-40.005433002710369, -8.190376962605576],
              [-40.005433010006982, -8.190376976748764],
              [-40.005433017328549, -8.190376990879159],
              [-40.005433024675042, -8.190377004996684],
              [-40.005433032046433, -8.19037701910135],
              [-40.005433039442707, -8.190377033193078],
              [-40.005433046863836, -8.190377047271809],
              [-40.00543305430979, -8.190377061337506],
              [-40.005433061780572, -8.190377075390158],
              [-40.005433069276123, -8.190377089429683],
              [-40.005433076796457, -8.190377103456065],
              [-40.005433084341519, -8.190377117469259],
              [-40.005433091911314, -8.1903771314692],
              [-40.005433099505794, -8.190377145455875],
              [-40.005433107124944, -8.190377159429215],
              [-40.005433114768749, -8.190377173389182],
              [-40.005433122437161, -8.190377187335747],
              [-40.0054331301302, -8.190377201268856],
              [-40.005433137847803, -8.190377215188445],
              [-40.005433145589961, -8.190377229094532],
              [-40.005433153356648, -8.190377242987012],
              [-40.005433161147828, -8.190377256865849],
              [-40.005433168963499, -8.190377270731036],
              [-40.005433176803621, -8.190377284582498],
              [-40.005433184668185, -8.19037729842022],
              [-40.005433192557156, -8.19037731224412],
              [-40.005433200470499, -8.190377326054175],
              [-40.005433208408206, -8.190377339850381],
              [-40.00543321637025, -8.190377353632632],
              [-40.005433224356615, -8.190377367400917],
              [-40.005433232367245, -8.190377381155209],
              [-40.005433240402155, -8.190377394895425],
              [-40.00543324846128, -8.190377408621559],
              [-40.005433256544649, -8.19037742233354],
              [-40.005433264652169, -8.190377436031341],
              [-40.005433272783854, -8.190377449714932],
              [-40.005433280939684, -8.190377463384243],
              [-40.005433289119637, -8.190377477039242],
              [-40.005433297323655, -8.190377490679911],
              [-40.005433305551733, -8.19037750430617],
              [-40.005433313803856, -8.190377517918],
              [-40.00543332207998, -8.190377531515333],
              [-40.005433330380107, -8.190377545098171],
              [-40.005433338704172, -8.190377558666439],
              [-40.005433347052168, -8.190377572220097],
              [-40.005433355424074, -8.190377585759107],
              [-40.005433363819861, -8.190377599283451],
              [-40.005433372239494, -8.190377612793048],
              [-40.00543338068298, -8.190377626287875],
              [-40.005433389150255, -8.190377639767901],
              [-40.005433397641291, -8.190377653233067],
              [-40.005433406156094, -8.190377666683329],
              [-40.005433414694615, -8.190377680118672],
              [-40.005433423256825, -8.190377693539016],
              [-40.005433431842711, -8.190377706944343],
              [-40.005433440452251, -8.190377720334649],
              [-40.005433449085395, -8.190377733709788],
              [-40.005433457742136, -8.190377747069803],
              [-40.005433466422446, -8.190377760414654],
              [-40.005433475126274, -8.190377773744265],
              [-40.005433483853636, -8.190377787058605],
              [-40.005433492604453, -8.190377800357624],
              [-40.005433501378754, -8.190377813641293],
              [-40.005433510176466, -8.190377826909602],
              [-40.005433518997584, -8.190377840162459],
              [-40.005433527842087, -8.190377853399843],
              [-40.005433536709923, -8.19037786662172],
              [-40.005433545601086, -8.190377879828024],
              [-40.005433554515541, -8.190377893018752],
              [-40.005433563453259, -8.19037790619381],
              [-40.005433572414205, -8.190377919353226],
              [-40.005433581398378, -8.190377932496903],
              [-40.00543359040573, -8.190377945624819],
              [-40.005433599436223, -8.190377958736942],
              [-40.005433608489838, -8.190377971833243],
              [-40.005433617566574, -8.190377984913651],
              [-40.00543362666636, -8.190377997978112],
              [-40.005433635789188, -8.190378011026613],
              [-40.005433637950894, -8.190377995842484],
              [-40.006143951269451, -8.191392065868241],
              [-40.006143912339901, -8.191392024167044],
              [-40.006143933037336, -8.191392053841355],
              [-40.006143953615585, -8.191392083597835],
              [-40.006143974074348, -8.191392113436013],
              [-40.006143994413293, -8.191392143355387],
              [-40.006144014632106, -8.191392173355464],
              [-40.006144034730426, -8.191392203435807],
              [-40.006144054707974, -8.191392233595918],
              [-40.006144074564411, -8.191392263835336],
              [-40.006144094299415, -8.191392294153541],
              [-40.006144113912683, -8.191392324550064],
              [-40.0061441334039, -8.191392355024401],
              [-40.006144152772741, -8.191392385576115],
              [-40.006144172018921, -8.191392416204666],
              [-40.006144191142127, -8.191392446909612],
              [-40.006144210142025, -8.191392477690441],
              [-40.006144229018354, -8.191392508546643],
              [-40.006144247770791, -8.191392539477764],
              [-40.006144266399019, -8.191392570483284],
              [-40.006144284902767, -8.191392601562701],
              [-40.006144303281737, -8.191392632715534],
              [-40.006144321535622, -8.191392663941272],
              [-40.006144339664147, -8.19139269523944],
              [-40.006144357667011, -8.191392726609514],
              [-40.00614437554394, -8.191392758051029],
              [-40.00614439329464, -8.191392789563443],
              [-40.0061444109188, -8.191392821146286],
              [-40.006144428416185, -8.191392852799032],
              [-40.006144445786497, -8.191392884521184],
              [-40.006144463029457, -8.191392916312232],
              [-40.006144480144791, -8.191392948171655],
              [-40.006144497132226, -8.191392980098938],
              [-40.00614451399148, -8.191393012093602],
              [-40.006144530722302, -8.19139304415512],
              [-40.006144547324404, -8.191393076283005],
              [-40.006144563797548, -8.191393108476703],
              [-40.006144580141452, -8.191393140735753],
              [-40.006144596355853, -8.191393173059591],
              [-40.006144612440515, -8.191393205447723],
              [-40.006144628395148, -8.191393237899598],
              [-40.006144644219525, -8.191393270414771],
              [-40.006144659913396, -8.191393302992664],
              [-40.006144675476463, -8.191393335632743],
              [-40.006144690908542, -8.191393368334571],
              [-40.006144706209334, -8.191393401097542],
              [-40.006144721378625, -8.19139343392118],
              [-40.006144736416168, -8.191393466804897],
              [-40.00614475132172, -8.191393499748228],
              [-40.006144766095048, -8.191393532750647],
              [-40.006144780735895, -8.1913935658116],
              [-40.006144795244055, -8.19139359893058],
              [-40.006144809619286, -8.191393632107047],
              [-40.006144823861355, -8.191393665340488],
              [-40.006144837970027, -8.191393698630339],
              [-40.006144851945109, -8.191393731976111],
              [-40.006144865786339, -8.191393765377239],
              [-40.006144879493512, -8.191393798833198],
              [-40.006144893066413, -8.191393832343469],
              [-40.006144906504822, -8.191393865907488],
              [-40.006144919808541, -8.191393899524762],
              [-40.006144932977314, -8.191393933194711],
              [-40.006144946010977, -8.191393966916831],
              [-40.006144958909282, -8.191394000690515],
              [-40.006144971672065, -8.191394034515316],
              [-40.006144984299091, -8.191394068390638],
              [-40.006144996790177, -8.191394102315959],
              [-40.006145009145108, -8.191394136290779],
              [-40.006145021363686, -8.191394170314467],
              [-40.006145033445733, -8.191394204386528],
              [-40.006145045391051, -8.191394238506417],
              [-40.006145057199447, -8.191394272673582],
              [-40.006145068870715, -8.19139430688748],
              [-40.0061450804047, -8.191394341147577],
              [-40.006145091801201, -8.191394375453328],
              [-40.006145103060035, -8.19139440980414],
              [-40.006145114181017, -8.191394444199508],
              [-40.006145125163975, -8.191394478638871],
              [-40.006145136008762, -8.191394513121688],
              [-40.006145146715156, -8.191394547647404],
              [-40.006145157283015, -8.191394582215461],
              [-40.006145167712162, -8.191394616825308],
              [-40.006145178002427, -8.191394651476399],
              [-40.006145188153667, -8.1913946861682],
              [-40.006145198165697, -8.191394720900107],
              [-40.006145208038348, -8.191394755671611],
              [-40.006145217771504, -8.191394790482143],
              [-40.006145227364968, -8.191394825331127],
              [-40.006145236818618, -8.191394860218026],
              [-40.006145246132277, -8.191394895142286],
              [-40.006145255305803, -8.191394930103334],
              [-40.006145264339061, -8.191394965100628],
              [-40.006145273231908, -8.191395000133596],
              [-40.006145281984175, -8.191395035201694],
              [-40.006145290595761, -8.191395070304365],
              [-40.006145299066489, -8.191395105441014],
              [-40.006145307396267, -8.191395140611133],
              [-40.006145315584931, -8.191395175814087],
              [-40.006145323632346, -8.191395211049379],
              [-40.006145331538406, -8.191395246316409],
              [-40.006145339302755, -8.191395281615991],
              [-40.006145321054611, -8.191395318350718],
              [-40.006356261691828, -8.192363331351885],
              [-40.006356241357679, -8.192363298266521],
              [-40.006356245446469, -8.192363317130587],
              [-40.006356249494836, -8.192363336003275],
              [-40.00635625350278, -8.192363354884542],
              [-40.00635625747028, -8.192363373774295],
              [-40.006356261397293, -8.192363392672407],
              [-40.006356265283848, -8.192363411578857],
              [-40.006356269129888, -8.192363430493492],
              [-40.006356272935427, -8.192363449416296],
              [-40.006356276700409, -8.192363468347143],
              [-40.006356280424846, -8.192363487285936],
              [-40.006356284108712, -8.192363506232613],
              [-40.006356287751998, -8.192363525187059],
              [-40.00635629135467, -8.192363544149234],
              [-40.006356294916735, -8.192363563119025],
              [-40.006356298438156, -8.192363582096325],
              [-40.00635630191892, -8.192363601081077],
              [-40.006356305359013, -8.192363620073191],
              [-40.006356308758441, -8.19236363907258],
              [-40.006356312117155, -8.192363658079165],
              [-40.006356315435163, -8.192363677092823],
              [-40.006356318712434, -8.192363696113496],
              [-40.006356321948978, -8.192363715141086],
              [-40.006356325144743, -8.192363734175517],
              [-40.006356328299738, -8.192363753216721],
              [-40.00635633141394, -8.192363772264565],
              [-40.006356334487343, -8.192363791319016],
              [-40.006356337519946, -8.192363810379934],
              [-40.006356340511701, -8.192363829447251],
              [-40.00635634346262, -8.192363848520907],
              [-40.006356346372691, -8.192363867600779],
              [-40.006356349241877, -8.192363886686779],
              [-40.006356352070163, -8.192363905778851],
              [-40.006356354857587, -8.192363924876885],
              [-40.006356357604091, -8.1923639439808],
              [-40.006356360309667, -8.192363963090523],
              [-40.006356362974309, -8.192363982205949],
              [-40.006356365598023, -8.192364001326997],
              [-40.006356368180747, -8.192364020453571],
              [-40.006356370722536, -8.192364039585609],
              [-40.006356373223319, -8.192364058723001],
              [-40.006356375683126, -8.192364077865667],
              [-40.006356378101913, -8.19236409701351],
              [-40.006356380479687, -8.192364116166472],
              [-40.006356382816449, -8.192364135324439],
              [-40.00635638511217, -8.19236415448731],
              [-40.006356387366836, -8.192364173655051],
              [-40.006356389580453, -8.192364192827526],
              [-40.006356391753002, -8.19236421200465],
              [-40.006356393884467, -8.19236423118636],
              [-40.006356395974848, -8.192364250372536],
              [-40.006356398024131, -8.192364269563141],
              [-40.006356400032324, -8.192364288758062],
              [-40.006356401999383, -8.192364307957197],
              [-40.006356403925331, -8.19236432716048],
              [-40.006356405810138, -8.192364346367803],
              [-40.006356407653811, -8.19236436557909],
              [-40.006356409456323, -8.192364384794248],
              [-40.006356411217695, -8.192364404013217],
              [-40.006356412937897, -8.192364423235862],
              [-40.00635641461691, -8.192364442462129],
              [-40.006356416254761, -8.192364461691936],
              [-40.006356417851407, -8.192364480925146],
              [-40.006356419406877, -8.19236450016176],
              [-40.006356420921115, -8.192364519401581],
              [-40.00635642239417, -8.192364538644599],
              [-40.006356423825999, -8.192364557890718],
              [-40.006356425216602, -8.192364577139825],
              [-40.006356426565986, -8.192364596391846],
              [-40.006356427874124, -8.192364615646701],
              [-40.006356429141029, -8.192364634904267],
              [-40.006356430366679, -8.192364654164514],
              [-40.006356431551076, -8.192364673427308],
              [-40.006356432694218, -8.192364692692596],
              [-40.006356433796093, -8.192364711960243],
              [-40.006356434856706, -8.192364731230203],
              [-40.00635643587605, -8.192364750502385],
              [-40.006356436854091, -8.192364769776662],
              [-40.006356437790878, -8.192364789052993],
              [-40.006356438686367, -8.192364808331277],
              [-40.006356439540561, -8.192364827611387],
              [-40.006356440353471, -8.1923648468933],
              [-40.006356441125078, -8.1923648661769],
              [-40.006356441855381, -8.192364885462085],
              [-40.00635644254438, -8.192364904748787],
              [-40.006356443192068, -8.192364924036889],
              [-40.006356443798445, -8.192364943326345],
              [-40.006356444363504, -8.192364962617043],
              [-40.006356444887267, -8.192364981908911],
              [-40.006356445369697, -8.192365001201823],
              [-40.006356445810802, -8.192365020495734],
              [-40.006356446210596, -8.192365039790536],
              [-40.00635644656905, -8.192365059086134],
              [-40.006356446886194, -8.192365078382471],
              [-40.006356447161991, -8.192365097679438],
              [-40.00635644739647, -8.192365116976925],
              [-40.00635644758961, -8.192365136274873],
              [-40.006356447741432, -8.192365155573192],
              [-40.006356447851914, -8.19236517487178],
              [-40.006356447920972, -8.192365194171639],
              [-40.006356448011388, -8.192365230160506],
              [-40.00635887586219, -8.193331592392273],
              [-40.006358875886114, -8.193331601920086],
              [-40.006358875767994, -8.19333167595533],
              [-40.006358875041585, -8.193331749987106],
              [-40.006358873706951, -8.193331824010492],
              [-40.006358871764171, -8.193331898020507],
              [-40.006358869213379, -8.193331972012208],
              [-40.006358866054761, -8.193332045980625],
              [-40.006358862288508, -8.193332119920814],
              [-40.006358857914883, -8.193332193827782],
              [-40.006358852934177, -8.193332267696617],
              [-40.006358847346725, -8.193332341522364],
              [-40.006358841152895, -8.193332415300063],
              [-40.006358834353108, -8.193332489024757],
              [-40.006358826947825, -8.193332562691529],
              [-40.006358818937521, -8.193332636295439],
              [-40.006358810322752, -8.193332709831557],
              [-40.006358801104106, -8.193332783294954],
              [-40.006358791282175, -8.193332856680666],
              [-40.006358780857632, -8.193332929983852],
              [-40.006358769831152, -8.193333003199541],
              [-40.006358758203511, -8.193333076322846],
              [-40.006358745975461, -8.193333149348867],
              [-40.006358733147842, -8.193333222272726],
              [-40.006358719721497, -8.193333295089507],
              [-40.006358705697338, -8.193333367794358],
              [-40.006358691076301, -8.193333440382387],
              [-40.006358675859339, -8.193333512848724],
              [-40.006358660047518, -8.193333585188531],
              [-40.006358643641867, -8.19333365739697],
              [-40.00635862664349, -8.193333729469177],
              [-40.006358609053528, -8.193333801400323],
              [-40.00635859087317, -8.19333387318559],
              [-40.006358572103629, -8.193333944820187],
              [-40.00635855274615, -8.1933340162993],
              [-40.006358532802025, -8.193334087618155],
              [-40.006358512272627, -8.193334158771949],
              [-40.006358491159304, -8.193334229755905],
              [-40.006358469463464, -8.193334300565324],
              [-40.006358447186599, -8.193334371195403],
              [-40.006358424330152, -8.193334441641404],
              [-40.006358400895692, -8.193334511898652],
              [-40.006358376884762, -8.193334581962416],
              [-40.006358352298996, -8.193334651827985],
              [-40.006358327140028, -8.193334721490713],
              [-40.006358301409541, -8.193334790945912],
              [-40.006358275109278, -8.193334860188935],
              [-40.006358248240979, -8.193334929215137],
              [-40.006358220806462, -8.193334998019878],
              [-40.006358192807539, -8.193335066598559],
              [-40.006358164246123, -8.193335134946601],
              [-40.006358135124103, -8.193335203059394],
              [-40.006358105443425, -8.193335270932394],
              [-40.006358075206101, -8.193335338561052],
              [-40.006358044414135, -8.193335405940825],
              [-40.006358013069608, -8.193335473067217],
              [-40.006357981174624, -8.193335539935724],
              [-40.006357948731306, -8.193335606541854],
              [-40.006357915741816, -8.193335672881144],
              [-40.006357882208384, -8.193335738949138],
              [-40.006357848133248, -8.193335804741434],
              [-40.006357813518726, -8.19333587025363],
              [-40.006357778367089, -8.193335935481306],
              [-40.006357742680706, -8.193336000420091],
              [-40.006357706461984, -8.193336065065671],
              [-40.006357669713339, -8.193336129413675],
              [-40.006357632437236, -8.1933361934598],
              [-40.006357594636164, -8.193336257199757],
              [-40.006357556312672, -8.193336320629301],
              [-40.006357517469318, -8.193336383744128],
              [-40.006357478108711, -8.193336446540057],
              [-40.006357438233472, -8.193336509012864],
              [-40.006357397846294, -8.193336571158348],
              [-40.006357356949884, -8.193336632972381],
              [-40.006357315546957, -8.193336694450771],
              [-40.006357273640319, -8.193336755589423],
              [-40.006357231232755, -8.193336816384249],
              [-40.006357188327108, -8.19333687683117],
              [-40.00635714492627, -8.193336936926132],
              [-40.006357101033139, -8.193336996665103],
              [-40.006357056650643, -8.193337056044081],
              [-40.006357011781795, -8.193337115059105],
              [-40.00635696642955, -8.193337173706203],
              [-40.006356920596993, -8.193337231981443],
              [-40.006356874287164, -8.193337289880912],
              [-40.006356827503176, -8.19333734740078],
              [-40.006356780248183, -8.193337404537125],
              [-40.006356732525319, -8.19333746128617],
              [-40.006356684337803, -8.193337517644096],
              [-40.006356635688853, -8.193337573607092],
              [-40.006356586581745, -8.193337629171458],
              [-40.006356537019769, -8.193337684333446],
              [-40.006356487006215, -8.193337739089358],
              [-40.006356436544458, -8.193337793435516],
              [-40.006356385637886, -8.193337847368305],
              [-40.006356334289897, -8.193337900884094],
              [-40.006356282503937, -8.19333795397932],
              [-40.006356230283465, -8.193338006650402],
              [-40.006356177632014, -8.193338058893815],
              [-40.006356124553079, -8.193338110706039],
              [-40.006356071049865, -8.193338162084615],
              [-40.006356078446075, -8.193338201604496],
              [-40.005451943394675, -8.19419928902726],
              [-40.003789729107183, -8.194440566003481],
              [-40.003789703971385, -8.194440569295622],
              [-40.003789678979118, -8.194440572958689],
              [-40.003789653997188, -8.194440576691038],
              [-40.003789629025782, -8.194440580492628],
              [-40.003789604065076, -8.194440584363464],
              [-40.003789579115292, -8.194440588303475],
              [-40.003789554176599, -8.194440592312645],
              [-40.003789529249204, -8.194440596390942],
              [-40.003789504333298, -8.194440600538327],
              [-40.003789479429088, -8.194440604754787],
              [-40.003789454536722, -8.194440609040283],
              [-40.003789429656443, -8.194440613394788],
              [-40.003789404788421, -8.194440617818262],
              [-40.003789379932847, -8.194440622310658],
              [-40.003789355089914, -8.194440626871952],
              [-40.003789330259814, -8.194440631502083],
              [-40.003789305442766, -8.194440636201083],
              [-40.003789280638898, -8.194440640968828],
              [-40.003789255848474, -8.19444064580534],
              [-40.003789231071643, -8.194440650710588],
              [-40.003789206308618, -8.194440655684511],
              [-40.003789181559576, -8.194440660727064],
              [-40.003789156824716, -8.194440665838215],
              [-40.003789132104224, -8.194440671017928],
              [-40.003789107398298, -8.194440676266167],
              [-40.003789082707137, -8.194440681582915],
              [-40.003789058030897, -8.19444068696807],
              [-40.003789033369806, -8.194440692421617],
              [-40.003789008724056, -8.19444069794352],
              [-40.003788984093809, -8.194440703533747],
              [-40.003788959479273, -8.194440709192243],
              [-40.003788934880646, -8.194440714918963],
              [-40.003788910298098, -8.19444072071385],
              [-40.003788885731844, -8.194440726576874],
              [-40.003788861182052, -8.19444073250798],
              [-40.003788836648923, -8.194440738507151],
              [-40.00378881213264, -8.194440744574326],
              [-40.003788787633411, -8.194440750709427],
              [-40.003788763151398, -8.194440756912444],
              [-40.003788738686808, -8.194440763183326],
              [-40.003788714239846, -8.194440769522002],
              [-40.003788689810662, -8.194440775928438],
              [-40.003788665399469, -8.194440782402602],
              [-40.003788641006459, -8.194440788944393],
              [-40.003788616631809, -8.194440795553803],
              [-40.003788592275733, -8.194440802230773],
              [-40.003788567938386, -8.194440808975246],
              [-40.003788543619969, -8.19444081578718],
              [-40.003788519320679, -8.194440822666497],
              [-40.003788495040688, -8.194440829613168],
              [-40.003788470780208, -8.19444083662712],
              [-40.003788446539403, -8.194440843708312],
              [-40.003788422318486, -8.194440850856681],
              [-40.003788398117599, -8.194440858072204],
              [-40.003788373936985, -8.194440865354769],
              [-40.003788349776798, -8.194440872704368],
              [-40.003788325637231, -8.194440880120913],
              [-40.003788301518469, -8.194440887604369],
              [-40.003788277420718, -8.194440895154667],
              [-40.00378825334414, -8.194440902771737],
              [-40.003788229288922, -8.194440910455553],
              [-40.003788205255276, -8.194440918206023],
              [-40.003788181243351, -8.194440926023097],
              [-40.003788157253361, -8.194440933906712],
              [-40.003788133285482, -8.194440941856831],
              [-40.003788109339915, -8.194440949873366],
              [-40.003788085416822, -8.194440957956234],
              [-40.003788061516389, -8.194440966105441],
              [-40.003788037638813, -8.194440974320861],
              [-40.003788013784295, -8.194440982602458],
              [-40.003787989952983, -8.194440990950159],
              [-40.003787966145083, -8.194440999363884],
              [-40.003787942360773, -8.194441007843603],
              [-40.003787918600246, -8.194441016389252],
              [-40.003787894863663, -8.194441025000737],
              [-40.003787871151246, -8.194441033677997],
              [-40.003787847463151, -8.194441042420971],
              [-40.00378782379957, -8.194441051229576],
              [-40.00378780016068, -8.194441060103788],
              [-40.003787776546659, -8.194441069043487],
              [-40.003787752957706, -8.194441078048619],
              [-40.003787729394006, -8.194441087119118],
              [-40.003787705855736, -8.194441096254916],
              [-40.003787682343059, -8.194441105455944],
              [-40.003787658856169, -8.194441114722125],
              [-40.003787635395263, -8.194441124053379],
              [-40.003787611960497, -8.194441133449661],
              [-40.003787588552079, -8.194441142910888],
              [-40.003787565170171, -8.194441152436974],
              [-40.003787541814972, -8.194441162027848],
              [-40.003787518486646, -8.19444117168343],
              [-40.003787495185378, -8.194441181403684],
              [-40.003787471911352, -8.194441191188494],
              [-40.003787448664738, -8.194441201037785],
              [-40.003787425445722, -8.194441210951497],
              [-40.003787402254517, -8.194441220929573],
              [-40.003787379091243, -8.194441230971885],
              [-40.003787355956121, -8.194441241078367],
              [-40.003787332849306, -8.194441251248975],
              [-40.003787309770985, -8.194441261483622],
              [-40.003787280005064, -8.194441295630098],
              [-40.001912226935985, -8.195275939891943],
              [-40.001912189050763, -8.195275938546079],
              [-40.001912133264497, -8.195275963154479],
              [-40.001912077313477, -8.195275987388749],
              [-40.001912021200255, -8.195276011247808],
              [-40.001911964927352, -8.195276034730602],
              [-40.001911908497306, -8.195276057836047],
              [-40.001911851912674, -8.195276080563113],
              [-40.001911795176014, -8.19527610291073],
              [-40.001911738289884, -8.195276124877942],
              [-40.00191168125685, -8.195276146463748],
              [-40.001911624079497, -8.195276167667162],
              [-40.001911566760398, -8.195276188487199],
              [-40.001911509302161, -8.195276208922985],
              [-40.001911451707358, -8.195276228973521],
              [-40.001911393978595, -8.19527624863796],
              [-40.001911336118482, -8.195276267915384],
              [-40.001911278129661, -8.19527628680493],
              [-40.001911220014705, -8.195276305305745],
              [-40.001911161776256, -8.195276323417],
              [-40.001911103416958, -8.195276341137848],
              [-40.00191104493944, -8.195276358467519],
              [-40.001910986346353, -8.195276375405239],
              [-40.001910927640317, -8.19527639195022],
              [-40.001910868823984, -8.195276408101696],
              [-40.00191080990006, -8.195276423858994],
              [-40.001910750871161, -8.195276439221379],
              [-40.001910691739965, -8.195276454188116],
              [-40.001910632509123, -8.195276468758566],
              [-40.001910573181362, -8.195276482932076],
              [-40.001910513759306, -8.195276496707972],
              [-40.001910454245689, -8.195276510085685],
              [-40.001910394643147, -8.195276523064571],
              [-40.001910334954403, -8.195276535644066],
              [-40.001910275182176, -8.195276547823559],
              [-40.001910215329119, -8.195276559602537],
              [-40.001910155397937, -8.195276570980468],
              [-40.001910095391388, -8.195276581956847],
              [-40.00191003531215, -8.195276592531153],
              [-40.00190997516291, -8.195276602702913],
              [-40.001909914946452, -8.195276612471682],
              [-40.001909854665421, -8.195276621836998],
              [-40.001909794322593, -8.19527663079845],
              [-40.001909733920684, -8.195276639355644],
              [-40.001909673462393, -8.195276647508166],
              [-40.001909612950492, -8.195276655255677],
              [-40.001909552387694, -8.195276662597797],
              [-40.001909491776729, -8.195276669534215],
              [-40.001909431120339, -8.195276676064626],
              [-40.001909370421266, -8.195276682188727],
              [-40.001909309682262, -8.195276687906235],
              [-40.00190924890606, -8.195276693216902],
              [-40.001909188095404, -8.19527669812047],
              [-40.001909127253043, -8.195276702616715],
              [-40.001909066381728, -8.195276706705455],
              [-40.001909005484201, -8.195276710386489],
              [-40.001908944563212, -8.195276713659638],
              [-40.001908883621532, -8.195276716524825],
              [-40.001908822661889, -8.195276718981845],
              [-40.001908761687055, -8.195276721030607],
              [-40.001908700699786, -8.195276722671039],
              [-40.001908639702826, -8.195276723903042],
              [-40.001908578698917, -8.195276724726551],
              [-40.001908517690836, -8.195276725141566],
              [-40.001908456681349, -8.195276725148041],
              [-40.001908395673176, -8.195276724745991],
              [-40.001908334669118, -8.195276723935418],
              [-40.001908273671873, -8.195276722716365],
              [-40.00190821268427, -8.195276721088888],
              [-40.001908151708989, -8.195276719053103],
              [-40.001908090748842, -8.195276716609028],
              [-40.00190802980655, -8.195276713756819],
              [-40.001907968884858, -8.195276710496598],
              [-40.001907907986549, -8.195276706828498],
              [-40.001907847114353, -8.195276702752704],
              [-40.001907786271033, -8.195276698269387],
              [-40.001907725459318, -8.195276693378737],
              [-40.00190766468198, -8.195276688080986],
              [-40.00190760394176, -8.195276682376397],
              [-40.001907543241387, -8.195276676265214],
              [-40.00190748258359, -8.195276669747701],
              [-40.001907421971154, -8.195276662824153],
              [-40.001907361406772, -8.195276655494885],
              [-40.001907300893208, -8.195276647760213],
              [-40.001907240433191, -8.195276639620525],
              [-40.001907180029434, -8.195276631076176],
              [-40.001907119684695, -8.195276622127528],
              [-40.001907059401667, -8.195276612775016],
              [-40.001906999183099, -8.195276603019046],
              [-40.001906939031713, -8.195276592860052],
              [-40.001906878950194, -8.195276582298522],
              [-40.001906818941293, -8.195276571334897],
              [-40.001906759007689, -8.195276559969695],
              [-40.001906699152123, -8.195276548203434],
              [-40.001906639377268, -8.195276536036609],
              [-40.001906579685844, -8.195276523469822],
              [-40.001906520080531, -8.195276510503584],
              [-40.001906460564037, -8.195276497138515],
              [-40.001906401139053, -8.195276483375237],
              [-40.001906341808244, -8.195276469214326],
              [-40.001906282574552, -8.195276454655742],
              [-40.001906327925639, -8.195276497193083],
              [-40.000038185245174, -8.194810654718845],
              [-40.000038145325981, -8.194810678089837],
              [-40.000038074766188, -8.194810660795889],
              [-40.000038004069715, -8.194810644065239],
              [-40.000037933241074, -8.194810627898935],
              [-40.000037862284827, -8.194810612298053],
              [-40.000037791205521, -8.194810597263549],
              [-40.000037720007683, -8.194810582796411],
              [-40.000037648695901, -8.19481056889755],
              [-40.000037577274746, -8.194810555567869],
              [-40.000037505748786, -8.194810542808215],
              [-40.000037434122603, -8.194810530619431],
              [-40.000037362400768, -8.194810519002251],
              [-40.000037290587912, -8.194810507957458],
              [-40.000037218688611, -8.194810497485761],
              [-40.000037146707498, -8.194810487587789],
              [-40.00003707464915, -8.194810478264204],
              [-40.000037002518219, -8.19481046951562],
              [-40.00003693031929, -8.194810461342582],
              [-40.000036858057022, -8.194810453745612],
              [-40.000036785736029, -8.194810446725191],
              [-40.000036713360956, -8.194810440281765],
              [-40.000036640936443, -8.194810434415775],
              [-40.000036568467102, -8.194810429127592],
              [-40.000036495957602, -8.194810424417502],
              [-40.000036423412588, -8.194810420285886],
              [-40.000036350836695, -8.194810416732961],
              [-40.000036278234589, -8.194810413758942],
              [-40.000036205610925, -8.194810411364063],
              [-40.00003613297033, -8.194810409548436],
              [-40.000036060317484, -8.194810408312231],
              [-40.000035987657029, -8.194810407655462],
              [-40.000035914993632, -8.194810407578204],
              [-40.000035842331933, -8.194810408080475],
              [-40.000035769676593, -8.194810409162221],
              [-40.000035697032288, -8.194810410823388],
              [-40.00003562440363, -8.194810413063871],
              [-40.000035551795328, -8.194810415883504],
              [-40.000035479211995, -8.194810419282136],
              [-40.000035406658291, -8.19481042325951],
              [-40.000035334138879, -8.194810427815373],
              [-40.000035261658383, -8.194810432949483],
              [-40.000035189221464, -8.194810438661472],
              [-40.000035116832741, -8.194810444950987],
              [-40.000035044496883, -8.19481045181762],
              [-40.000034972218515, -8.194810459260918],
              [-40.000034900002248, -8.19481046728043],
              [-40.000034827852723, -8.194810475875625],
              [-40.000034755774593, -8.194810485045968],
              [-40.000034683772412, -8.194810494790875],
              [-40.000034611850865, -8.194810505109682],
              [-40.000034540014511, -8.194810516001779],
              [-40.000034468267962, -8.194810527466435],
              [-40.000034396615831, -8.194810539502896],
              [-40.0000343250627, -8.194810552110431],
              [-40.000034253613158, -8.194810565288231],
              [-40.000034182271769, -8.194810579035428],
              [-40.000034111043114, -8.194810593351153],
              [-40.000034039931755, -8.19481060823448],
              [-40.000033968942262, -8.194810623684479],
              [-40.000033898079167, -8.194810639700137],
              [-40.000033827347004, -8.194810656280453],
              [-40.000033756750341, -8.194810673424319],
              [-40.000033686293655, -8.19481069113068],
              [-40.000033615981479, -8.194810709398398],
              [-40.00003354581834, -8.194810728226283],
              [-40.000033475808713, -8.194810747613138],
              [-40.00003340595709, -8.194810767557714],
              [-40.000033336267947, -8.194810788058746],
              [-40.000033266745753, -8.194810809114896],
              [-40.000033197394927, -8.19481083072484],
              [-40.000033128219975, -8.194810852887185],
              [-40.000033059225274, -8.194810875600503],
              [-40.000032990415278, -8.194810898863347],
              [-40.000032921794386, -8.194810922674234],
              [-40.000032853367003, -8.194810947031611],
              [-40.000032785137485, -8.194810971933954],
              [-40.000032717110237, -8.194810997379641],
              [-40.000032649289608, -8.194811023367052],
              [-40.000032581679932, -8.194811049894511],
              [-40.000032514285557, -8.194811076960326],
              [-40.000032447110783, -8.194811104562783],
              [-40.000032380159944, -8.194811132700085],
              [-40.000032313437309, -8.194811161370449],
              [-40.00003224694715, -8.194811190571999],
              [-40.000032180693729, -8.194811220302922],
              [-40.000032114681304, -8.194811250561253],
              [-40.000032048914093, -8.194811281345114],
              [-40.000031983396312, -8.19481131265248],
              [-40.000031918132173, -8.194811344481366],
              [-40.000031853125833, -8.194811376829724],
              [-40.000031788381463, -8.194811409695523],
              [-40.000031723903255, -8.194811443076588],
              [-40.000031659695267, -8.194811476970838],
              [-40.000031595761662, -8.194811511376068],
              [-40.000031532106526, -8.194811546290085],
              [-40.000031468733944, -8.194811581710649],
              [-40.000031405647974, -8.194811617635532],
              [-40.00003134285263, -8.194811654062361],
              [-40.000031280351976, -8.194811690988841],
              [-40.000031218150006, -8.194811728412576],
              [-40.000031198292668, -8.194811757339348],
              [-39.998569838400392, -8.195698902906379],
              [-39.998569859766384, -8.195698920619691],
              [-39.998569785236647, -8.195698966358981],
              [-39.998569711153031, -8.195699012812165],
              [-39.998569637522472, -8.19569905997494],
              [-39.998569564351797, -8.195699107842918],
              [-39.998569491647807, -8.195699156411628],
              [-39.998569419417272, -8.195699205676593],
              [-39.998569347666901, -8.19569925563321],
              [-39.998569276403366, -8.19569930627685],
              [-39.998569205633295, -8.195699357602782],
              [-39.998569135363262, -8.195699409606217],
              [-39.998569065599817, -8.19569946228239],
              [-39.998568996349441, -8.195699515626318],
              [-39.998568927618564, -8.195699569633094],
              [-39.998568859413567, -8.195699624297671],
              [-39.998568791740837, -8.195699679614993],
              [-39.998568724606621, -8.195699735579907],
              [-39.998568658017177, -8.195699792187179],
              [-39.998568591978731, -8.195699849431604],
              [-39.998568526497351, -8.195699907307782],
              [-39.998568461579183, -8.195699965810391],
              [-39.998568397230251, -8.195700024933965],
              [-39.998568333456525, -8.195700084673035],
              [-39.998568270263952, -8.195700145022021],
              [-39.998568207658401, -8.195700205975305],
              [-39.998568145645685, -8.195700267527227],
              [-39.998568084231586, -8.195700329672084],
              [-39.998568023421804, -8.195700392404051],
              [-39.998567963222001, -8.19570045571734],
              [-39.998567903637763, -8.195700519606042],
              [-39.998567844674639, -8.195700584064223],
              [-39.998567786338114, -8.195700649085886],
              [-39.998567728633603, -8.195700714664998],
              [-39.998567671566477, -8.195700780795441],
              [-39.998567615142065, -8.195700847471093],
              [-39.998567559365561, -8.195700914685734],
              [-39.99856750424221, -8.195700982433092],
              [-39.998567449777099, -8.195701050706914],
              [-39.998567395975293, -8.195701119500814],
              [-39.998567342841817, -8.195701188808412],
              [-39.998567290381587, -8.195701258623263],
              [-39.998567238599513, -8.195701328938853],
              [-39.998567187500377, -8.195701399748677],
              [-39.998567137088955, -8.19570147104613],
              [-39.998567087369928, -8.195701542824606],
              [-39.998567038347915, -8.195701615077379],
              [-39.998566990027463, -8.19570168779779],
              [-39.998566942413092, -8.195701760979066],
              [-39.9985668955092, -8.195701834614384],
              [-39.99856684932017, -8.19570190869686],
              [-39.998566803850281, -8.195701983219703],
              [-39.998566759103781, -8.195702058175899],
              [-39.99856671508482, -8.19570213355852],
              [-39.998566671797491, -8.195702209360547],
              [-39.998566629245801, -8.195702285574901],
              [-39.99856658743375, -8.195702362194536],
              [-39.998566546365211, -8.195702439212308],
              [-39.998566506043971, -8.195702516621063],
              [-39.99856646647379, -8.195702594413614],
              [-39.998566427658375, -8.195702672582712],
              [-39.998566389601315, -8.195702751121065],
              [-39.998566352306156, -8.195702830021423],
              [-39.998566315776351, -8.195702909276411],
              [-39.998566280015332, -8.19570298887867],
              [-39.998566245026382, -8.195703068820782],
              [-39.998566210812783, -8.19570314909534],
              [-39.998566177377697, -8.195703229694868],
              [-39.998566144724251, -8.195703310611895],
              [-39.998566112855464, -8.195703391838851],
              [-39.9985660817743, -8.195703473368196],
              [-39.998566051483692, -8.195703555192377],
              [-39.998566021986399, -8.195703637303758],
              [-39.998565993285197, -8.195703719694711],
              [-39.998565965382745, -8.19570380235759],
              [-39.998565938281622, -8.195703885284686],
              [-39.998565911984379, -8.195703968468308],
              [-39.998565886493431, -8.195704051900707],
              [-39.998565861811173, -8.195704135574127],
              [-39.998565837939893, -8.195704219480772],
              [-39.9985658148818, -8.19570430361286],
              [-39.998565792639049, -8.195704387962589],
              [-39.998565771213705, -8.195704472522062],
              [-39.998565750607753, -8.195704557283442],
              [-39.998565730823131, -8.195704642238862],
              [-39.998565711861637, -8.195704727380434],
              [-39.998565693725105, -8.195704812700162],
              [-39.998565676415147, -8.195704898190234],
              [-39.998565659933398, -8.195704983842568],
              [-39.99856564428142, -8.195705069649298],
              [-39.998565629460629, -8.195705155602399],
              [-39.998565615472437, -8.195705241693934],
              [-39.998565602318109, -8.1957053279158],
              [-39.998565589998883, -8.195705414260072],
              [-39.998565578515908, -8.195705500718683],
              [-39.998565567870251, -8.195705587283589],
              [-39.998565558062914, -8.195705673946739],
              [-39.998565549094792, -8.195705760700095],
              [-39.998565540966716, -8.195705847535548],
              [-39.998565533679447, -8.195705934445098],
              [-39.998565527233673, -8.195706021420607],
              [-39.99856549901417, -8.195706058548723],
              [-39.998498733548701, -8.196670694913823],
              [-39.99849872450082, -8.196670732976603],
              [-39.998498722888286, -8.196670755841712],
              [-39.998498721217587, -8.196670778702671],
              [-39.998498719488737, -8.196670801559339],
              [-39.998498717701736, -8.196670824411555],
              [-39.998498715856634, -8.196670847259213],
              [-39.998498713953403, -8.196670870102116],
              [-39.99849871199207, -8.196670892940155],
              [-39.998498709972665, -8.196670915773137],
              [-39.998498707895173, -8.196670938600972],
              [-39.998498705759623, -8.196670961423472],
              [-39.998498703566035, -8.196670984240527],
              [-39.998498701314389, -8.196671007051943],
              [-39.998498699004728, -8.19667102985763],
              [-39.998498696637078, -8.196671052657376],
              [-39.998498694211428, -8.196671075451098],
              [-39.998498691727811, -8.196671098238614],
              [-39.998498689186235, -8.19667112101977],
              [-39.9984986865867, -8.196671143794468],
              [-39.998498683929263, -8.196671166562536],
              [-39.99849868121391, -8.196671189323791],
              [-39.998498678440669, -8.19667121207813],
              [-39.998498675609532, -8.196671234825384],
              [-39.998498672720579, -8.196671257565406],
              [-39.998498669773767, -8.196671280298087],
              [-39.998498666769137, -8.196671303023235],
              [-39.998498663706719, -8.196671325740738],
              [-39.998498660586527, -8.196671348450421],
              [-39.998498657408568, -8.196671371152172],
              [-39.99849865417287, -8.196671393845813],
              [-39.998498650879448, -8.19667141653121],
              [-39.998498647528343, -8.196671439208208],
              [-39.998498644119572, -8.196671461876688],
              [-39.998498640653132, -8.19667148453647],
              [-39.99849863712906, -8.196671507187432],
              [-39.998498633547385, -8.196671529829423],
              [-39.998498629908141, -8.196671552462286],
              [-39.99849862621133, -8.196671575085913],
              [-39.998498622456985, -8.196671597700112],
              [-39.998498618645101, -8.196671620304768],
              [-39.998498614775755, -8.196671642899727],
              [-39.998498610848934, -8.196671665484841],
              [-39.998498606864686, -8.196671688059975],
              [-39.998498602823005, -8.196671710624969],
              [-39.998498598723955, -8.196671733179699],
              [-39.998498594567536, -8.196671755723971],
              [-39.998498590353798, -8.196671778257706],
              [-39.998498586082732, -8.19667180078072],
              [-39.99849858175439, -8.196671823292871],
              [-39.998498577368807, -8.196671845794011],
              [-39.998498572926003, -8.19667186828401],
              [-39.998498568425994, -8.196671890762744],
              [-39.998498563868822, -8.196671913230011],
              [-39.998498559254507, -8.196671935685734],
              [-39.998498554583101, -8.196671958129716],
              [-39.99849854985461, -8.196671980561828],
              [-39.998498545069069, -8.196672002981924],
              [-39.998498540226507, -8.196672025389875],
              [-39.998498535326974, -8.196672047785521],
              [-39.998498530370476, -8.196672070168722],
              [-39.998498525357064, -8.196672092539327],
              [-39.998498520286759, -8.196672114897204],
              [-39.998498515159596, -8.196672137242238],
              [-39.998498509975605, -8.196672159574215],
              [-39.99849850473484, -8.19667218189306],
              [-39.998498499437297, -8.196672204198588],
              [-39.998498494083051, -8.196672226490662],
              [-39.998498488672105, -8.196672248769129],
              [-39.998498483204507, -8.196672271033886],
              [-39.998498477680286, -8.196672293284756],
              [-39.998498472099499, -8.19667231552161],
              [-39.998498466462145, -8.196672337744307],
              [-39.998498460768282, -8.196672359952686],
              [-39.998498455017959, -8.196672382146614],
              [-39.998498449211198, -8.196672404325978],
              [-39.998498443348019, -8.196672426490572],
              [-39.998498437428488, -8.196672448640316],
              [-39.998498431452632, -8.196672470775018],
              [-39.998498425420486, -8.196672492894566],
              [-39.998498419332101, -8.196672514998818],
              [-39.998498413187498, -8.196672537087613],
              [-39.998498406986727, -8.196672559160845],
              [-39.998498400729829, -8.196672581218332],
              [-39.998498394416856, -8.196672603259952],
              [-39.998498388047814, -8.196672625285592],
              [-39.998498381622781, -8.196672647295042],
              [-39.998498375141779, -8.196672669288194],
              [-39.998498368604842, -8.196672691264919],
              [-39.998498362012036, -8.196672713225093],
              [-39.998498355363374, -8.196672735168526],
              [-39.99849834865892, -8.196672757095081],
              [-39.998498341898717, -8.19667277900464],
              [-39.9984983350828, -8.196672800897089],
              [-39.998498328211213, -8.196672822772239],
              [-39.998498321284011, -8.196672844629973],
              [-39.998498314301223, -8.196672866470138],
              [-39.998498307262906, -8.196672888292619],
              [-39.998498300169103, -8.196672910097229],
              [-39.998498293019864, -8.196672931883867],
              [-39.998498285815408, -8.196672953651033],
              [-39.998498285310994, -8.196672957067793],
              [-39.998083824816106, -8.197919818433778],
              [-39.998083847741697, -8.197919841528698],
              [-39.998083843753008, -8.197919853487674],
              [-39.998083839747771, -8.197919865441168],
              [-39.998083835725978, -8.197919877389124],
              [-39.998083831687651, -8.197919889331546],
              [-39.998083827632804, -8.197919901268419],
              [-39.998083823561416, -8.197919913199685],
              [-39.998083819473514, -8.197919925125349],
              [-39.998083815369114, -8.197919937045397],
              [-39.998083811248208, -8.19791994895979],
              [-39.99808380711081, -8.197919960868495],
              [-39.998083802956927, -8.197919972771514],
              [-39.99808379878656, -8.197919984668813],
              [-39.998083794599737, -8.197919996560357],
              [-39.998083790396443, -8.197920008446131],
              [-39.9980837861767, -8.197920020326132],
              [-39.998083781940515, -8.197920032200312],
              [-39.998083777687896, -8.19792004406867],
              [-39.998083773418841, -8.197920055931176],
              [-39.998083769133366, -8.197920067787772],
              [-39.998083764831492, -8.197920079638497],
              [-39.998083760513197, -8.197920091483297],
              [-39.998083756178502, -8.197920103322145],
              [-39.99808375182743, -8.197920115155032],
              [-39.99808374745998, -8.197920126981915],
              [-39.998083743076158, -8.197920138802786],
              [-39.99808373867598, -8.197920150617637],
              [-39.998083734259438, -8.197920162426415],
              [-39.998083729826547, -8.197920174229115],
              [-39.998083725377327, -8.197920186025724],
              [-39.998083720911765, -8.197920197816176],
              [-39.998083716429903, -8.197920209600499],
              [-39.998083711931713, -8.197920221378666],
              [-39.99808370741723, -8.197920233150624],
              [-39.998083702886433, -8.197920244916352],
              [-39.998083698339371, -8.197920256675859],
              [-39.998083693776017, -8.197920268429085],
              [-39.998083689196406, -8.197920280176035],
              [-39.99808368460053, -8.19792029191669],
              [-39.998083679988397, -8.197920303651021],
              [-39.998083675360036, -8.197920315378971],
              [-39.998083670715424, -8.197920327100594],
              [-39.99808366605459, -8.19792033881579],
              [-39.998083661377542, -8.197920350524569],
              [-39.998083656684294, -8.197920362226922],
              [-39.998083651974845, -8.197920373922814],
              [-39.998083647249203, -8.197920385612209],
              [-39.998083642507375, -8.197920397295105],
              [-39.998083637749389, -8.197920408971477],
              [-39.998083632975231, -8.197920420641298],
              [-39.998083628184922, -8.197920432304558],
              [-39.998083623378463, -8.1979204439612],
              [-39.998083618555881, -8.197920455611227],
              [-39.998083613717171, -8.19792046725463],
              [-39.998083608862331, -8.197920478891362],
              [-39.998083603991397, -8.197920490521398],
              [-39.998083599104362, -8.197920502144752],
              [-39.99808359420124, -8.197920513761371],
              [-39.998083589282025, -8.197920525371243],
              [-39.998083584346759, -8.197920536974333],
              [-39.99808357939542, -8.197920548570639],
              [-39.99808357442803, -8.197920560160128],
              [-39.998083569444603, -8.197920571742788],
              [-39.998083564445146, -8.197920583318558],
              [-39.998083559429666, -8.197920594887464],
              [-39.998083554398164, -8.197920606449468],
              [-39.998083549350667, -8.197920618004536],
              [-39.998083544287176, -8.197920629552637],
              [-39.998083539207691, -8.197920641093802],
              [-39.99808353411224, -8.197920652627966],
              [-39.998083529000823, -8.197920664155133],
              [-39.998083523873454, -8.197920675675242],
              [-39.998083518730134, -8.197920687188299],
              [-39.998083513570876, -8.197920698694283],
              [-39.998083508395695, -8.19792071019317],
              [-39.998083503204597, -8.197920721684897],
              [-39.998083497997598, -8.197920733169505],
              [-39.998083492774697, -8.197920744646945],
              [-39.998083487535908, -8.197920756117183],
              [-39.998083482281245, -8.197920767580227],
              [-39.998083477010709, -8.197920779036039],
              [-39.998083471724328, -8.197920790484591],
              [-39.998083466422095, -8.197920801925857],
              [-39.998083461104031, -8.197920813359831],
              [-39.998083455770136, -8.197920824786483],
              [-39.998083450420417, -8.197920836205801],
              [-39.998083445054895, -8.197920847617738],
              [-39.998083439673593, -8.197920859022309],
              [-39.998083434276495, -8.197920870419468],
              [-39.998083428863623, -8.197920881809173],
              [-39.998083423434977, -8.197920893191469],
              [-39.998083417990593, -8.197920904566274],
              [-39.998083412530455, -8.197920915933588],
              [-39.998083407054587, -8.197920927293378],
              [-39.998083401562987, -8.197920938645645],
              [-39.998083396055684, -8.197920949990346],
              [-39.99808339053267, -8.19792096132746],
              [-39.998083384993976, -8.197920972656982],
              [-39.998083379439599, -8.197920983978875],
              [-39.998083373869541, -8.197920995292959],
              [-39.998083357297858, -8.19792102767201],
              [-39.997530040072689, -8.199028919692655],
              [-39.997530002237944, -8.199028907480843],
              [-39.997529983363002, -8.1990289454937],
              [-39.997529964667194, -8.199028983594337],
              [-39.997529946150898, -8.199029021781893],
              [-39.99752992781454, -8.19902906005553],
              [-39.997529909658525, -8.199029098414426],
              [-39.997529891683257, -8.199029136857716],
              [-39.997529873889093, -8.199029175384551],
              [-39.997529856276486, -8.199029213994107],
              [-39.997529838845779, -8.199029252685522],
              [-39.997529821597368, -8.19902929145797],
              [-39.997529804531624, -8.199029330310553],
              [-39.997529787648936, -8.199029369242437],
              [-39.997529770949683, -8.199029408252793],
              [-39.997529754434204, -8.199029447340704],
              [-39.997529738102884, -8.199029486505349],
              [-39.997529721956056, -8.199029525745869],
              [-39.997529705994125, -8.199029565061382],
              [-39.997529690217384, -8.199029604451047],
              [-39.997529674626215, -8.199029643914026],
              [-39.997529659220966, -8.19902968344935],
              [-39.997529644001943, -8.199029723056249],
              [-39.997529628969509, -8.199029762733792],
              [-39.997529614123991, -8.199029802481157],
              [-39.997529599465693, -8.199029842297412],
              [-39.997529584994965, -8.199029882181732],
              [-39.997529570712118, -8.199029922133208],
              [-39.997529556617451, -8.199029962150973],
              [-39.997529542711298, -8.199030002234149],
              [-39.997529528993951, -8.199030042381866],
              [-39.997529515465708, -8.199030082593243],
              [-39.997529502126874, -8.199030122867326],
              [-39.997529488977719, -8.19903016320332],
              [-39.997529476018585, -8.199030203600296],
              [-39.997529463249712, -8.199030244057369],
              [-39.997529450671394, -8.199030284573649],
              [-39.997529438283898, -8.199030325148255],
              [-39.997529426087517, -8.199030365780288],
              [-39.997529414082493, -8.199030406468868],
              [-39.997529402269123, -8.199030447213071],
              [-39.997529390647649, -8.199030488012006],
              [-39.997529379218314, -8.19903052886481],
              [-39.997529367981379, -8.199030569770555],
              [-39.997529356937108, -8.199030610728354],
              [-39.997529346085713, -8.199030651737306],
              [-39.997529335427465, -8.199030692796505],
              [-39.997529324962585, -8.199030733905019],
              [-39.997529314691278, -8.199030775061994],
              [-39.997529304613799, -8.1990308162665],
              [-39.99752929473037, -8.199030857517627],
              [-39.997529285041196, -8.199030898814485],
              [-39.997529275546498, -8.199030940156158],
              [-39.997529266246474, -8.199030981541757],
              [-39.997529257141352, -8.199031022970305],
              [-39.997529248231309, -8.199031064440952],
              [-39.997529239516545, -8.199031105952779],
              [-39.997529230997259, -8.199031147504837],
              [-39.99752922267362, -8.199031189096273],
              [-39.99752921454585, -8.199031230726117],
              [-39.997529206614097, -8.199031272393485],
              [-39.997529198878532, -8.199031314097477],
              [-39.997529191339346, -8.199031355837132],
              [-39.99752918399669, -8.199031397611529],
              [-39.997529176850719, -8.199031439419791],
              [-39.997529169901597, -8.199031481260965],
              [-39.997529163149494, -8.199031523134154],
              [-39.997529156594545, -8.199031565038423],
              [-39.997529150236886, -8.199031606972861],
              [-39.997529144076672, -8.199031648936533],
              [-39.997529138114025, -8.19903169092853],
              [-39.997529132349072, -8.199031732947926],
              [-39.997529126781977, -8.199031774993818],
              [-39.997529121412818, -8.199031817065213],
              [-39.997529116241715, -8.19903185916122],
              [-39.997529111268818, -8.199031901280961],
              [-39.997529106494213, -8.199031943423456],
              [-39.997529101918005, -8.199031985587803],
              [-39.997529097540294, -8.199032027773049],
              [-39.997529093361187, -8.199032069978305],
              [-39.997529089380755, -8.199032112202616],
              [-39.997529085599112, -8.199032154445046],
              [-39.997529082016307, -8.199032196704669],
              [-39.997529078632468, -8.199032238980605],
              [-39.997529075447623, -8.19903228127184],
              [-39.997529072461852, -8.19903232357753],
              [-39.997529069675245, -8.19903236589669],
              [-39.997529067087839, -8.199032408228417],
              [-39.997529064699705, -8.19903245057173],
              [-39.997529062510885, -8.199032492925747],
              [-39.997529060521437, -8.199032535289552],
              [-39.997529058731381, -8.199032577662162],
              [-39.997529057140788, -8.199032620042665],
              [-39.997529055749681, -8.199032662430156],
              [-39.997529054558086, -8.199032704823656],
              [-39.997529053566041, -8.199032747222304],
              [-39.997529052773544, -8.199032789625093],
              [-39.997529052180631, -8.199032832031092],
              [-39.997529051787325, -8.199032874439432],
              [-39.997529051593609, -8.199032916849143],
              [-39.997529051599493, -8.199032959259274],
              [-39.997529051681141, -8.199032992018326],
              [-39.997532839129157, -8.200552553466169],
              [-39.997532839138202, -8.200552557096769],
              [-39.997532839209136, -8.200552585528238],
              [-39.99753283977536, -8.200552645662802],
              [-39.997532840742906, -8.200552705792298],
              [-39.997532842111688, -8.200552765914031],
              [-39.997532843881658, -8.200552826025369],
              [-39.997532846052756, -8.200552886123671],
              [-39.997532848624857, -8.200552946206257],
              [-39.997532851597867, -8.200553006270495],
              [-39.997532854971652, -8.200553066313699],
              [-39.997532858746062, -8.200553126333203],
              [-39.997532862920927, -8.20055318632641],
              [-39.997532867496062, -8.200553246290619],
              [-39.997532872471261, -8.200553306223203],
              [-39.997532877846325, -8.200553366121499],
              [-39.997532883620984, -8.200553425982873],
              [-39.997532889795011, -8.200553485804663],
              [-39.997532896368121, -8.200553545584226],
              [-39.997532903340023, -8.200553605318939],
              [-39.997532910710412, -8.200553665006133],
              [-39.997532918478953, -8.200553724643168],
              [-39.997532926645299, -8.20055378422747],
              [-39.997532935209136, -8.200553843756326],
              [-39.997532944170018, -8.200553903227156],
              [-39.997532953527589, -8.200553962637303],
              [-39.997532963281436, -8.200554021984155],
              [-39.99753297343112, -8.200554081265073],
              [-39.997532983976157, -8.200554140477426],
              [-39.997532994916142, -8.200554199618653],
              [-39.997533006250563, -8.200554258686076],
              [-39.997533017978924, -8.200554317677126],
              [-39.997533030100698, -8.200554376589169],
              [-39.997533042615352, -8.200554435419612],
              [-39.997533055522325, -8.200554494165853],
              [-39.99753306882107, -8.200554552825327],
              [-39.997533082510969, -8.200554611395356],
              [-39.997533096591447, -8.200554669873419],
              [-39.997533111061855, -8.200554728256918],
              [-39.997533125921571, -8.200554786543281],
              [-39.997533141169932, -8.200554844729911],
              [-39.997533156806263, -8.200554902814273],
              [-39.997533172829883, -8.200554960793717],
              [-39.997533189240052, -8.200555018665774],
              [-39.997533206036088, -8.200555076427815],
              [-39.997533223217218, -8.200555134077346],
              [-39.997533240782708, -8.20055519161178],
              [-39.997533258731742, -8.200555249028588],
              [-39.997533277063553, -8.200555306325231],
              [-39.997533295777338, -8.200555363499165],
              [-39.997533314872257, -8.200555420547861],
              [-39.997533334347473, -8.200555477468823],
              [-39.997533354202112, -8.200555534259498],
              [-39.997533374435299, -8.200555590917421],
              [-39.997533395046169, -8.200555647440058],
              [-39.997533416033754, -8.200555703824875],
              [-39.99753343739718, -8.20055576006944],
              [-39.997533459135475, -8.200555816171237],
              [-39.997533481247693, -8.200555872127808],
              [-39.997533503732846, -8.200555927936639],
              [-39.997533526589933, -8.200555983595297],
              [-39.997533549817952, -8.200556039101322],
              [-39.997533573415886, -8.200556094452255],
              [-39.99753359738267, -8.200556149645591],
              [-39.997533621717267, -8.200556204678991],
              [-39.997533646418596, -8.200556259549963],
              [-39.997533671485549, -8.200556314256103],
              [-39.997533696917046, -8.200556368794967],
              [-39.997533722711928, -8.200556423164189],
              [-39.997533748869074, -8.200556477361303],
              [-39.997533775387332, -8.200556531383947],
              [-39.997533802265529, -8.200556585229759],
              [-39.997533829502451, -8.200556638896279],
              [-39.99753385709694, -8.200556692381248],
              [-39.99753388504773, -8.200556745682182],
              [-39.997533913353614, -8.200556798796796],
              [-39.997533942013334, -8.20055685172275],
              [-39.997533971025618, -8.200556904457677],
              [-39.997534000389187, -8.200556956999236],
              [-39.997534030102749, -8.200557009345152],
              [-39.997534060164966, -8.200557061493042],
              [-39.99753409057454, -8.200557113440663],
              [-39.997534121330119, -8.200557165185714],
              [-39.99753415243034, -8.200557216725842],
              [-39.997534183873817, -8.200557268058859],
              [-39.997534215659186, -8.200557319182387],
              [-39.997534247784998, -8.200557370094263],
              [-39.997534280249887, -8.200557420792199],
              [-39.99753431305237, -8.200557471273941],
              [-39.997534346191024, -8.200557521537302],
              [-39.997534379664387, -8.200557571580008],
              [-39.997534413470973, -8.200557621399895],
              [-39.997534447609262, -8.200557670994689],
              [-39.997534482077782, -8.200557720362269],
              [-39.997534516874985, -8.200557769500405],
              [-39.997534551999358, -8.200557818406974],
              [-39.997534587449316, -8.200557867079773],
              [-39.997534623223302, -8.20055791551667],
              [-39.997534659319768, -8.200557963715523],
              [-39.997534695737073, -8.200558011674186],
              [-39.997534732473625, -8.20055805939055],
              [-39.997534769527803, -8.20055810686252],
              [-39.997534759261562, -8.200558097726365],
              [-39.9980205949661, -8.201176240535885],
              [-39.998020580546253, -8.201176238246163],
              [-39.998020611221314, -8.201176277498032],
              [-39.998020641679226, -8.201176316917415],
              [-39.998020671919043, -8.201176356503122],
              [-39.998020701939858, -8.201176396253908],
              [-39.998020731740773, -8.201176436168659],
              [-39.998020761320873, -8.201176476246099],
              [-39.998020790679263, -8.201176516485033],
              [-39.998020819815054, -8.201176556884256],
              [-39.998020848727357, -8.201176597442537],
              [-39.998020877415335, -8.201176638158632],
              [-39.998020905878086, -8.201176679031342],
              [-39.998020934114741, -8.201176720059374],
              [-39.998020962124485, -8.201176761241594],
              [-39.998020989906443, -8.201176802576644],
              [-39.99802101745977, -8.201176844063323],
              [-39.998021044783634, -8.20117688570036],
              [-39.998021071877233, -8.2011769274865],
              [-39.99802109873972, -8.2011769694205],
              [-39.998021125370286, -8.201177011501073],
              [-39.99802115176815, -8.201177053726951],
              [-39.998021177932486, -8.201177096096847],
              [-39.998021203862507, -8.201177138609493],
              [-39.998021229557416, -8.201177181263565],
              [-39.998021255016475, -8.201177224057828],
              [-39.998021280238895, -8.201177266990944],
              [-39.998021305223887, -8.201177310061661],
              [-39.998021329970726, -8.201177353268593],
              [-39.998021354478666, -8.201177396610548],
              [-39.998021378746941, -8.201177440086129],
              [-39.998021402774825, -8.201177483694043],
              [-39.998021426561607, -8.201177527432957],
              [-39.998021450106542, -8.201177571301578],
              [-39.998021473408947, -8.20117761529856],
              [-39.998021496468098, -8.201177659422608],
              [-39.998021519283299, -8.201177703672323],
              [-39.998021541853866, -8.20117774804638],
              [-39.998021564179105, -8.201177792543477],
              [-39.998021586258332, -8.201177837162245],
              [-39.998021608090923, -8.201177881901346],
              [-39.998021629676167, -8.201177926759385],
              [-39.998021651013453, -8.201177971735065],
              [-39.998021672102091, -8.201178016826995],
              [-39.998021692941478, -8.20117806203381],
              [-39.998021713530989, -8.201178107354123],
              [-39.998021733869955, -8.201178152786582],
              [-39.998021753957815, -8.201178198329835],
              [-39.998021773793916, -8.201178243982486],
              [-39.998021793377688, -8.201178289743153],
              [-39.998021812708529, -8.201178335610425],
              [-39.998021831785856, -8.20117838158294],
              [-39.998021850609092, -8.201178427659316],
              [-39.998021869177656, -8.201178473838139],
              [-39.998021887490992, -8.20117852011805],
              [-39.998021905548562, -8.201178566497576],
              [-39.998021923349803, -8.201178612975397],
              [-39.998021940894176, -8.201178659550026],
              [-39.998021958181162, -8.201178706220155],
              [-39.998021975210222, -8.201178752984266],
              [-39.998021991980856, -8.201178799840994],
              [-39.998022008492548, -8.201178846788929],
              [-39.998022024744813, -8.201178893826636],
              [-39.998022040737126, -8.201178940952691],
              [-39.998022056469033, -8.201178988165671],
              [-39.998022071940049, -8.201179035464168],
              [-39.998022087149707, -8.201179082846721],
              [-39.998022102097551, -8.20117913031191],
              [-39.998022116783112, -8.201179177858304],
              [-39.998022131205964, -8.201179225484443],
              [-39.998022145365667, -8.201179273188892],
              [-39.99802215926178, -8.201179320970224],
              [-39.99802217289389, -8.201179368826987],
              [-39.998022186261593, -8.201179416757773],
              [-39.998022199364478, -8.201179464761058],
              [-39.998022212202152, -8.201179512835401],
              [-39.998022224774211, -8.201179560979387],
              [-39.998022237080313, -8.201179609191547],
              [-39.998022249120041, -8.201179657470421],
              [-39.998022260893038, -8.201179705814511],
              [-39.998022272398984, -8.2011797542224],
              [-39.998022283637496, -8.201179802692627],
              [-39.998022294608234, -8.201179851223687],
              [-39.99802230531089, -8.201179899814139],
              [-39.998022315745111, -8.201179948462498],
              [-39.998022325910611, -8.201179997167316],
              [-39.998022335807072, -8.201180045927108],
              [-39.99802234543418, -8.201180094740383],
              [-39.998022354791658, -8.201180143605688],
              [-39.998022363879215, -8.201180192521527],
              [-39.998022372696589, -8.201180241486432],
              [-39.998022381243509, -8.201180290498906],
              [-39.998022389519697, -8.201180339557485],
              [-39.998022397524927, -8.201180388660672],
              [-39.998022405258936, -8.201180437806975],
              [-39.998022412721511, -8.201180486994943],
              [-39.998022419912424, -8.201180536223051],
              [-39.99802242683144, -8.201180585489821],
              [-39.998022433478368, -8.201180634793753],
              [-39.998022439852981, -8.201180684133382],
              [-39.998022445955257, -8.201180733506668],
              [-39.998022421771424, -8.201180758101481],
              [-39.998164331814827, -8.202355516534322],
              [-39.998164342998301, -8.202355477382397],
              [-39.998164344645367, -8.20235549093197],
              [-39.998164346312954, -8.202355504479032],
              [-39.998164348001048, -8.202355518023568],
              [-39.998164349709668, -8.202355531565576],
              [-39.998164351438781, -8.202355545104973],
              [-39.998164353188436, -8.202355558641756],
              [-39.998164354958554, -8.202355572175914],
              [-39.998164356749193, -8.202355585707377],
              [-39.998164358560309, -8.202355599236142],
              [-39.998164360391932, -8.202355612762162],
              [-39.99816436224404, -8.202355626285438],
              [-39.998164364116612, -8.202355639805882],
              [-39.998164366009675, -8.202355653323494],
              [-39.998164367923209, -8.20235566683826],
              [-39.998164369857207, -8.202355680350127],
              [-39.99816437181169, -8.202355693859067],
              [-39.998164373786594, -8.202355707365053],
              [-39.998164375781982, -8.202355720868063],
              [-39.998164377797814, -8.202355734368069],
              [-39.998164379834101, -8.202355747865012],
              [-39.998164381890817, -8.202355761358888],
              [-39.998164383967975, -8.202355774849664],
              [-39.998164386065561, -8.20235578833729],
              [-39.998164388183575, -8.202355801821733],
              [-39.99816439032201, -8.202355815302974],
              [-39.998164392480874, -8.202355828780998],
              [-39.998164394660151, -8.202355842255756],
              [-39.998164396859814, -8.202355855727223],
              [-39.998164399079897, -8.202355869195378],
              [-39.998164401320373, -8.202355882660157],
              [-39.998164403581235, -8.202355896121574],
              [-39.998164405862504, -8.202355909579568],
              [-39.998164408164136, -8.202355923034142],
              [-39.998164410486147, -8.202355936485208],
              [-39.998164412828537, -8.202355949932763],
              [-39.99816441519129, -8.202355963376773],
              [-39.998164417574408, -8.202355976817246],
              [-39.998164419977876, -8.2023559902541],
              [-39.9981644224017, -8.202356003687331],
              [-39.998164424845868, -8.202356017116907],
              [-39.998164427310371, -8.202356030542811],
              [-39.99816442979521, -8.202356043964974],
              [-39.998164432300371, -8.202356057383382],
              [-39.99816443482586, -8.202356070798031],
              [-39.998164437371663, -8.202356084208862],
              [-39.998164439937774, -8.202356097615834],
              [-39.998164442524192, -8.202356111018926],
              [-39.998164445130911, -8.202356124418111],
              [-39.998164447757922, -8.202356137813387],
              [-39.99816445040522, -8.202356151204675],
              [-39.998164453072796, -8.202356164591972],
              [-39.998164455760651, -8.202356177975258],
              [-39.998164458468771, -8.202356191354484],
              [-39.998164461197149, -8.202356204729618],
              [-39.998164463945791, -8.202356218100642],
              [-39.998164466714684, -8.202356231467535],
              [-39.998164469503813, -8.20235624483022],
              [-39.998164472313185, -8.202356258188694],
              [-39.998164475142779, -8.202356271542941],
              [-39.99816447799261, -8.202356284892907],
              [-39.998164480862656, -8.202356298238579],
              [-39.998164483752909, -8.202356311579919],
              [-39.998164486663377, -8.2023563249169],
              [-39.998164489594025, -8.202356338249505],
              [-39.998164492544873, -8.202356351577675],
              [-39.998164495515901, -8.202356364901398],
              [-39.998164498507116, -8.202356378220628],
              [-39.998164501518502, -8.202356391535355],
              [-39.998164504550047, -8.202356404845521],
              [-39.998164507601757, -8.202356418151123],
              [-39.998164510673618, -8.202356431452126],
              [-39.998164513765609, -8.202356444748492],
              [-39.998164516877743, -8.202356458040216],
              [-39.998164520010015, -8.202356471327223],
              [-39.998164523162401, -8.20235648460951],
              [-39.998164526334904, -8.202356497887065],
              [-39.998164529527521, -8.202356511159804],
              [-39.998164532740219, -8.202356524427723],
              [-39.998164535973032, -8.202356537690795],
              [-39.998164539225911, -8.202356550948998],
              [-39.998164542498884, -8.202356564202319],
              [-39.998164545791923, -8.202356577450683],
              [-39.998164549105034, -8.202356590694079],
              [-39.99816455243819, -8.202356603932493],
              [-39.998164555791391, -8.202356617165885],
              [-39.998164559164643, -8.20235663039419],
              [-39.998164562557925, -8.202356643617412],
              [-39.998164565971237, -8.202356656835528],
              [-39.998164569404558, -8.202356670048488],
              [-39.998164572857895, -8.2023566832563],
              [-39.998164576331234, -8.202356696458887],
              [-39.998164579824554, -8.202356709656236],
              [-39.998164583337875, -8.202356722848315],
              [-39.998164586871162, -8.2023567360351],
              [-39.998164590424437, -8.202356749216584],
              [-39.998164593997664, -8.202356762392681],
              [-39.998164597590851, -8.202356775563386],
              [-39.998164601203968, -8.202356788728661],
              [-39.998164604837022, -8.202356801888522],
              [-39.998164607303536, -8.20235678203669],
              [-39.998374469613729, -8.203114887673889],
              [-39.998374500589144, -8.203114855743216],
              [-39.998374517940661, -8.203114917552986],
              [-39.998374535731998, -8.203114979238524],
              [-39.99837455396225, -8.203115040796714],
              [-39.998374572630524, -8.203115102224475],
              [-39.998374591735853, -8.203115163518691],
              [-39.998374611277292, -8.203115224676287],
              [-39.998374631253853, -8.203115285694194],
              [-39.998374651664527, -8.203115346569314],
              [-39.998374672508284, -8.20311540729859],
              [-39.998374693784072, -8.203115467878972],
              [-39.998374715490847, -8.203115528307412],
              [-39.998374737627472, -8.203115588580866],
              [-39.998374760192867, -8.203115648696288],
              [-39.998374783185881, -8.203115708650625],
              [-39.998374806605355, -8.20311576844091],
              [-39.998374830450111, -8.203115828064085],
              [-39.998374854718946, -8.203115887517196],
              [-39.998374879410648, -8.20311594679721],
              [-39.99837490452397, -8.203116005901165],
              [-39.998374930057651, -8.203116064826053],
              [-39.998374956010373, -8.203116123568959],
              [-39.998374982380881, -8.203116182126887],
              [-39.998375009167809, -8.203116240496879],
              [-39.998375036369822, -8.203116298676017],
              [-39.998375063985549, -8.203116356661383],
              [-39.998375092013596, -8.203116414450042],
              [-39.99837512045255, -8.203116472039069],
              [-39.998375149300983, -8.203116529425584],
              [-39.998375178557446, -8.203116586606683],
              [-39.998375208220459, -8.203116643579483],
              [-39.998375238288531, -8.203116700341125],
              [-39.998375268760149, -8.203116756888765],
              [-39.998375299633771, -8.203116813219529],
              [-39.998375330907855, -8.203116869330586],
              [-39.998375362580802, -8.203116925219135],
              [-39.998375394651042, -8.203116980882307],
              [-39.998375427116969, -8.203117036317366],
              [-39.998375459976906, -8.203117091521463],
              [-39.998375493229233, -8.203117146491865],
              [-39.998375526872273, -8.203117201225769],
              [-39.998375560904307, -8.203117255720464],
              [-39.998375595323651, -8.203117309973146],
              [-39.99837563012855, -8.203117363981113],
              [-39.998375665317269, -8.203117417741648],
              [-39.998375700888019, -8.203117471252025],
              [-39.998375736839016, -8.203117524509556],
              [-39.998375773168455, -8.203117577511557],
              [-39.998375809874496, -8.20311763025537],
              [-39.998375846955298, -8.203117682738355],
              [-39.998375884408993, -8.203117734957836],
              [-39.998375922233691, -8.203117786911227],
              [-39.998375960427495, -8.203117838595832],
              [-39.998375998988465, -8.2031178900091],
              [-39.998376037914689, -8.203117941148474],
              [-39.998376077204178, -8.203117992011324],
              [-39.998376116854949, -8.203118042595108],
              [-39.998376156865028, -8.203118092897293],
              [-39.998376197232396, -8.203118142915329],
              [-39.998376237955007, -8.203118192646709],
              [-39.998376279030815, -8.203118242088896],
              [-39.998376320457766, -8.203118291239411],
              [-39.998376362233763, -8.203118340095813],
              [-39.998376404356691, -8.203118388655604],
              [-39.998376446824452, -8.203118436916364],
              [-39.998376489634886, -8.203118484875644],
              [-39.998376532785855, -8.203118532531052],
              [-39.998376576275177, -8.203118579880172],
              [-39.99837662010065, -8.203118626920595],
              [-39.998376664260086, -8.203118673650007],
              [-39.998376708751252, -8.203118720066037],
              [-39.998376753571911, -8.203118766166314],
              [-39.998376798719796, -8.203118811948537],
              [-39.998376844192656, -8.203118857410397],
              [-39.998376889988172, -8.203118902549605],
              [-39.998376936104052, -8.203118947363901],
              [-39.998376982537984, -8.203118991851012],
              [-39.998377029287603, -8.203119036008728],
              [-39.998377076350586, -8.20311907983478],
              [-39.998377123724538, -8.203119123327017],
              [-39.998377171407093, -8.203119166483182],
              [-39.998377219395834, -8.20311920930115],
              [-39.998377267688348, -8.203119251778748],
              [-39.998377316282216, -8.203119293913828],
              [-39.998377365174967, -8.203119335704287],
              [-39.998377414364143, -8.203119377148029],
              [-39.998377463847291, -8.203119418242904],
              [-39.998377513621911, -8.203119458986915],
              [-39.998377563685473, -8.203119499377973],
              [-39.998377614035462, -8.203119539414052],
              [-39.99837766466937, -8.203119579093133],
              [-39.998377715584624, -8.203119618413217],
              [-39.99837776677866, -8.203119657372332],
              [-39.998377818248919, -8.203119695968528],
              [-39.998377869992773, -8.203119734199845],
              [-39.998377922007649, -8.203119772064355],
              [-39.998377974290918, -8.203119809560164],
              [-39.998378026839944, -8.203119846685363],
              [-39.99837807965207, -8.203119883438116],
              [-39.99837813272466, -8.20311991981653],
              [-39.998378113324023, -8.203119943385714],
              [-39.999491482939163, -8.203877256014941],
              [-39.999491497513056, -8.203877283536901],
              [-39.99949151005702, -8.203877292054571],
              [-39.999491522615294, -8.203877300551325],
              [-39.999491535187786, -8.203877309027126],
              [-39.999491547774497, -8.203877317481986],
              [-39.999491560375382, -8.203877325915858],
              [-39.999491572990401, -8.203877334328716],
              [-39.999491585619531, -8.203877342720567],
              [-39.999491598262715, -8.203877351091343],
              [-39.999491610919947, -8.203877359441057],
              [-39.999491623591169, -8.203877367769669],
              [-39.999491636276353, -8.20387737607715],
              [-39.999491648975464, -8.203877384363489],
              [-39.999491661688467, -8.203877392628652],
              [-39.999491674415324, -8.203877400872623],
              [-39.999491687155995, -8.203877409095384],
              [-39.999491699910465, -8.203877417296901],
              [-39.999491712678662, -8.20387742547716],
              [-39.999491725460587, -8.203877433636139],
              [-39.999491738256182, -8.203877441773809],
              [-39.99949175106542, -8.20387744989017],
              [-39.999491763888258, -8.203877457985151],
              [-39.999491776724675, -8.203877466058771],
              [-39.99949178957462, -8.203877474110982],
              [-39.999491802438065, -8.203877482141781],
              [-39.999491815314975, -8.203877490151116],
              [-39.999491828205301, -8.203877498139013],
              [-39.999491841109013, -8.20387750610541],
              [-39.999491854026097, -8.203877514050294],
              [-39.999491866956483, -8.203877521973656],
              [-39.999491879900141, -8.203877529875466],
              [-39.999491892857058, -8.203877537755686],
              [-39.999491905827185, -8.203877545614322],
              [-39.999491918810492, -8.20387755345133],
              [-39.999491931806908, -8.203877561266692],
              [-39.999491944816448, -8.203877569060394],
              [-39.999491957839048, -8.20387757683241],
              [-39.999491970874672, -8.20387758458272],
              [-39.999491983923278, -8.203877592311299],
              [-39.999491996984844, -8.203877600018144],
              [-39.999492010059328, -8.203877607703195],
              [-39.999492023146686, -8.203877615366466],
              [-39.999492036246906, -8.203877623007905],
              [-39.999492049359915, -8.203877630627513],
              [-39.999492062485707, -8.203877638225277],
              [-39.999492075624218, -8.203877645801139],
              [-39.99949208877544, -8.203877653355109],
              [-39.999492101939332, -8.203877660887176],
              [-39.999492115115828, -8.203877668397277],
              [-39.999492128304915, -8.203877675885435],
              [-39.999492141506558, -8.203877683351594],
              [-39.99949215472072, -8.203877690795752],
              [-39.999492167947331, -8.203877698217894],
              [-39.999492181186412, -8.203877705617977],
              [-39.999492194437877, -8.203877712995993],
              [-39.999492207701721, -8.203877720351906],
              [-39.999492220977885, -8.203877727685724],
              [-39.999492234266341, -8.203877734997421],
              [-39.999492247567048, -8.203877742286959],
              [-39.999492260879975, -8.203877749554326],
              [-39.999492274205082, -8.203877756799512],
              [-39.999492287542324, -8.203877764022481],
              [-39.999492300891689, -8.203877771223219],
              [-39.999492314253104, -8.203877778401688],
              [-39.999492327626562, -8.203877785557907],
              [-39.999492341012015, -8.203877792691843],
              [-39.999492354409405, -8.203877799803463],
              [-39.999492367818718, -8.203877806892747],
              [-39.999492381239918, -8.203877813959688],
              [-39.999492394672963, -8.203877821004237],
              [-39.99949240811781, -8.203877828026419],
              [-39.999492421574416, -8.203877835026189],
              [-39.999492435042761, -8.203877842003521],
              [-39.999492448522801, -8.203877848958401],
              [-39.99949246201448, -8.203877855890799],
              [-39.999492475517798, -8.203877862800729],
              [-39.999492489032683, -8.203877869688156],
              [-39.999492502559114, -8.203877876553065],
              [-39.999492516097042, -8.203877883395409],
              [-39.999492529646446, -8.20387789021518],
              [-39.999492543207268, -8.203877897012385],
              [-39.999492556779479, -8.203877903786989],
              [-39.999492570363039, -8.203877910538937],
              [-39.999492583957931, -8.203877917268269],
              [-39.999492597564092, -8.20387792397495],
              [-39.999492611181481, -8.203877930658928],
              [-39.999492624810067, -8.203877937320238],
              [-39.999492638449837, -8.203877943958823],
              [-39.999492652100706, -8.203877950574675],
              [-39.999492665762681, -8.203877957167759],
              [-39.999492679435683, -8.203877963738091],
              [-39.999492693119713, -8.203877970285619],
              [-39.999492706814699, -8.203877976810341],
              [-39.999492720520628, -8.203877983312248],
              [-39.99949273423745, -8.203877989791314],
              [-39.999492747965114, -8.203877996247515],
              [-39.999492761703614, -8.203878002680826],
              [-39.999492775452879, -8.203878009091248],
              [-39.999492789212887, -8.203878015478736],
              [-39.999492802983603, -8.20387802184332],
              [-39.999492846626644, -8.203878066608207],
              [-40.000537902029677, -8.204359969446982],
              [-40.000537944733786, -8.204360007619202],
              [-40.000537954872605, -8.20436001230242],
              [-40.000537965005606, -8.204360016998065],
              [-40.000537975132822, -8.204360021706142],
              [-40.000537985254205, -8.204360026426651],
              [-40.000537995369747, -8.204360031159567],
              [-40.000538005479427, -8.20436003590487],
              [-40.000538015583253, -8.204360040662582],
              [-40.000538025681188, -8.204360045432672],
              [-40.000538035773211, -8.204360050215145],
              [-40.000538045859322, -8.204360055010012],
              [-40.000538055939501, -8.204360059817226],
              [-40.000538066013718, -8.204360064636806],
              [-40.00053807608198, -8.204360069468748],
              [-40.000538086144267, -8.20436007431303],
              [-40.000538096200565, -8.204360079169644],
              [-40.000538106250858, -8.204360084038594],
              [-40.000538116295104, -8.204360088919868],
              [-40.000538126333346, -8.204360093813451],
              [-40.000538136365513, -8.204360098719336],
              [-40.000538146391605, -8.204360103637532],
              [-40.000538156411622, -8.204360108568023],
              [-40.000538166425535, -8.204360113510825],
              [-40.000538176433331, -8.204360118465875],
              [-40.000538186435008, -8.204360123433204],
              [-40.000538196430526, -8.204360128412814],
              [-40.000538206419876, -8.204360133404665],
              [-40.000538216403079, -8.204360138408747],
              [-40.000538226380073, -8.204360143425074],
              [-40.000538236350856, -8.204360148453645],
              [-40.000538246315429, -8.204360153494447],
              [-40.000538256273749, -8.204360158547463],
              [-40.000538266225838, -8.204360163612685],
              [-40.000538276171646, -8.20436016869011],
              [-40.000538286111187, -8.204360173779726],
              [-40.000538296044418, -8.204360178881553],
              [-40.000538305971347, -8.204360183995528],
              [-40.000538315891951, -8.204360189121678],
              [-40.000538325806204, -8.204360194260008],
              [-40.000538335714097, -8.204360199410486],
              [-40.000538345615624, -8.204360204573121],
              [-40.00053835551077, -8.2043602097479],
              [-40.0005383653995, -8.204360214934797],
              [-40.000538375281828, -8.204360220133816],
              [-40.000538385157718, -8.204360225344981],
              [-40.000538395027164, -8.204360230568225],
              [-40.000538404890143, -8.204360235803582],
              [-40.00053841474665, -8.204360241051049],
              [-40.000538424596655, -8.204360246310578],
              [-40.000538434440173, -8.204360251582187],
              [-40.000538444277147, -8.204360256865867],
              [-40.000538454107605, -8.204360262161616],
              [-40.000538463931505, -8.204360267469404],
              [-40.00053847374884, -8.204360272789257],
              [-40.000538483559595, -8.204360278121118],
              [-40.000538493363749, -8.204360283465039],
              [-40.000538503161287, -8.204360288820975],
              [-40.000538512952218, -8.204360294188902],
              [-40.000538522736491, -8.20436029956886],
              [-40.000538532514121, -8.204360304960788],
              [-40.000538542285071, -8.204360310364715],
              [-40.000538552049342, -8.204360315780617],
              [-40.000538561806906, -8.204360321208499],
              [-40.000538571557762, -8.204360326648342],
              [-40.000538581301896, -8.204360332100162],
              [-40.00053859103928, -8.204360337563905],
              [-40.0005386007699, -8.204360343039596],
              [-40.000538610493749, -8.204360348527212],
              [-40.000538620210811, -8.204360354026756],
              [-40.000538629921067, -8.204360359538224],
              [-40.000538639624509, -8.204360365061572],
              [-40.000538649321108, -8.204360370596847],
              [-40.000538659010878, -8.204360376143999],
              [-40.000538668693785, -8.204360381703024],
              [-40.000538678369807, -8.20436038727393],
              [-40.000538688038937, -8.204360392856692],
              [-40.000538697701174, -8.204360398451334],
              [-40.000538707356462, -8.2043604040578],
              [-40.000538717004844, -8.204360409676111],
              [-40.000538726646269, -8.204360415306247],
              [-40.000538736280717, -8.204360420948191],
              [-40.000538745908202, -8.204360426601962],
              [-40.000538755528687, -8.20436043226753],
              [-40.000538765142167, -8.204360437944903],
              [-40.00053877474862, -8.204360443634053],
              [-40.000538784348052, -8.204360449334999],
              [-40.000538793940407, -8.204360455047683],
              [-40.0005388035257, -8.204360460772159],
              [-40.000538813103923, -8.204360466508382],
              [-40.000538822675047, -8.204360472256345],
              [-40.000538832239044, -8.204360478016037],
              [-40.000538841795937, -8.204360483787465],
              [-40.000538851345702, -8.204360489570607],
              [-40.000538860888298, -8.204360495365455],
              [-40.000538870423718, -8.204360501171996],
              [-40.000538879951968, -8.204360506990229],
              [-40.000538889473027, -8.204360512820172],
              [-40.000538898986861, -8.204360518661757],
              [-40.000538908493482, -8.204360524515014],
              [-40.000538917992849, -8.20436053038012],
              [-40.00053890193395, -8.204360509614434],
              [-40.001079410883243, -8.204694705811974],
              [-40.001079404388577, -8.2046946851363],
              [-40.00107958272929, -8.204694798281901],
              [-40.001079758376726, -8.204694915534937],
              [-40.001079931235594, -8.204695036831755],
              [-40.001080101212182, -8.20469516210658],
              [-40.001080268214224, -8.204695291291404],
              [-40.001080432151149, -8.20469542431619],
              [-40.001080592934038, -8.204695561108791],
              [-40.001080750475637, -8.204695701594966],
              [-40.001080904690518, -8.204695845698526],
              [-40.001081055495007, -8.204695993341279],
              [-40.001081202807292, -8.20469614444316],
              [-40.001081346547458, -8.204696298922153],
              [-40.001081486637553, -8.204696456694499],
              [-40.001081623001561, -8.204696617674612],
              [-40.001081755565522, -8.204696781775159],
              [-40.001081884257509, -8.20469694890711],
              [-40.001082009007717, -8.204697118979821],
              [-40.001082129748468, -8.204697291900979],
              [-40.001082246414278, -8.204697467576828],
              [-40.001082358941851, -8.204697645912061],
              [-40.001082467270123, -8.204697826809952],
              [-40.001082571340355, -8.204698010172324],
              [-40.001082671096107, -8.204698195899747],
              [-40.001082766483208, -8.204698383891445],
              [-40.001082857449966, -8.204698574045464],
              [-40.001082943947019, -8.204698766258623],
              [-40.001083025927436, -8.204698960426644],
              [-40.001083103346737, -8.204699156444233],
              [-40.00108317616295, -8.204699354205045],
              [-40.001083244336556, -8.204699553601788],
              [-40.001083307830569, -8.204699754526294],
              [-40.001083366610558, -8.204699956869574],
              [-40.001083420644633, -8.20470016052184],
              [-40.001083469903477, -8.20470036537265],
              [-40.001083514360381, -8.204700571310859],
              [-40.001083553991194, -8.204700778224785],
              [-40.001083588774478, -8.204700986002116],
              [-40.001083618691318, -8.204701194530204],
              [-40.00108364372548, -8.20470140369588],
              [-40.001083663863405, -8.204701613385703],
              [-40.001083679094172, -8.204701823485907],
              [-40.001083689409498, -8.204702033882551],
              [-40.001083694803796, -8.20470224446146],
              [-40.001083695274161, -8.204702455108421],
              [-40.001083690820309, -8.204702665709172],
              [-40.001083681444669, -8.204702876149449],
              [-40.001083667152315, -8.204703086315083],
              [-40.001083647951006, -8.204703296092118],
              [-40.00108362385118, -8.204703505366695],
              [-40.001083594865882, -8.204703714025303],
              [-40.001083561010851, -8.204703921954788],
              [-40.001083522304441, -8.204704129042319],
              [-40.001083478767669, -8.20470433517556],
              [-40.001083430424131, -8.204704540242689],
              [-40.001083377300056, -8.204704744132476],
              [-40.001083319424268, -8.204704946734322],
              [-40.001083256828167, -8.20470514793829],
              [-40.001083189545689, -8.204705347635249],
              [-40.001083117613362, -8.204705545716875],
              [-40.001083041070181, -8.204705742075708],
              [-40.001082959957706, -8.204705936605235],
              [-40.001082874319906, -8.204706129199918],
              [-40.001082784203227, -8.204706319755269],
              [-40.001082689656577, -8.204706508167948],
              [-40.001082590731258, -8.204706694335723],
              [-40.0010824874809, -8.204706878157605],
              [-40.001082379961531, -8.2047070595339],
              [-40.001082268231485, -8.204707238366174],
              [-40.001082152351366, -8.204707414557451],
              [-40.001082032384019, -8.204707588012145],
              [-40.001081908394553, -8.204707758636156],
              [-40.001081780450214, -8.204707926336914],
              [-40.001081648620406, -8.204708091023472],
              [-40.001081512976633, -8.204708252606464],
              [-40.001081373592498, -8.204708410998244],
              [-40.001081230543619, -8.204708566112911],
              [-40.001081083907565, -8.204708717866291],
              [-40.001080933763895, -8.20470886617607],
              [-40.001080780194087, -8.204709010961787],
              [-40.001080623281403, -8.20470915214492],
              [-40.001080463111002, -8.204709289648873],
              [-40.00108029976974, -8.204709423399063],
              [-40.00108013334625, -8.204709553322928],
              [-40.001079963930792, -8.204709679349941],
              [-40.00107979161529, -8.204709801411809],
              [-40.0010796164932, -8.204709919442287],
              [-40.001079438659545, -8.2047100333773],
              [-40.00107925821078, -8.204710143155118],
              [-40.001079075244789, -8.204710248716166],
              [-40.001078889860835, -8.204710350003154],
              [-40.001078702159475, -8.204710446961156],
              [-40.001078512242557, -8.204710539537595],
              [-40.001078320213061, -8.204710627682172],
              [-40.001078126175202, -8.204710711347166],
              [-40.001077930234224, -8.204710790487171],
              [-40.001077732496405, -8.204710865059223],
              [-40.001077533069029, -8.204710935022838],
              [-40.001077332060277, -8.204711000340176],
              [-40.001077129578817, -8.204711060975807],
              [-40.001077091650991, -8.204711081515955],
              [-40.000122976873492, -8.204984775829713],
              [-40.000122953000243, -8.204984770238061],
              [-40.000122933398366, -8.204984775837191],
              [-40.000122913783933, -8.204984781392417],
              [-40.000122894157023, -8.20498478690374],
              [-40.000122874517722, -8.204984792371109],
              [-40.000122854866156, -8.204984797794506],
              [-40.000122835202404, -8.204984803173927],
              [-40.000122815526581, -8.20498480850935],
              [-40.000122795838763, -8.20498481380068],
              [-40.000122776139087, -8.204984819047954],
              [-40.000122756427615, -8.204984824251136],
              [-40.000122736704462, -8.204984829410163],
              [-40.000122716969727, -8.204984834525062],
              [-40.000122697223517, -8.204984839595788],
              [-40.00012267746591, -8.204984844622269],
              [-40.000122657697034, -8.204984849604543],
              [-40.000122637916967, -8.20498485454257],
              [-40.000122618125822, -8.204984859436321],
              [-40.000122598323678, -8.204984864285747],
              [-40.00012257851067, -8.204984869090824],
              [-40.000122558686876, -8.20498487385159],
              [-40.000122538852388, -8.204984878567959],
              [-40.000122519007313, -8.204984883239931],
              [-40.000122499151757, -8.204984887867489],
              [-40.000122479285814, -8.204984892450566],
              [-40.00012245940961, -8.204984896989181],
              [-40.000122439523203, -8.204984901483328],
              [-40.00012241962672, -8.204984905932944],
              [-40.000122399720247, -8.204984910338011],
              [-40.000122379803905, -8.204984914698507],
              [-40.000122359877771, -8.204984919014437],
              [-40.00012233994196, -8.204984923285753],
              [-40.000122319996571, -8.204984927512431],
              [-40.000122300041703, -8.204984931694474],
              [-40.000122280077456, -8.204984935831883],
              [-40.000122260103929, -8.20498493992457],
              [-40.000122240121222, -8.204984943972537],
              [-40.000122220129441, -8.204984947975785],
              [-40.000122200128686, -8.204984951934243],
              [-40.00012218011905, -8.204984955847982],
              [-40.000122160100645, -8.20498495971693],
              [-40.000122140073564, -8.204984963541024],
              [-40.000122120037915, -8.204984967320309],
              [-40.00012209999381, -8.204984971054724],
              [-40.000122079941313, -8.204984974744296],
              [-40.000122059880553, -8.204984978388996],
              [-40.000122039811636, -8.204984981988773],
              [-40.00012201973464, -8.204984985543604],
              [-40.000121999649686, -8.204984989053528],
              [-40.000121979556859, -8.204984992518474],
              [-40.000121959456294, -8.204984995938464],
              [-40.000121939348055, -8.204984999313442],
              [-40.000121919232257, -8.204985002643394],
              [-40.000121899108997, -8.204985005928348],
              [-40.000121878978391, -8.20498500916826],
              [-40.000121858840522, -8.204985012363093],
              [-40.000121838695506, -8.204985015512865],
              [-40.000121818543441, -8.204985018617503],
              [-40.000121798384427, -8.2049850216771],
              [-40.000121778218556, -8.204985024691512],
              [-40.000121758045935, -8.204985027660797],
              [-40.000121737866699, -8.204985030584933],
              [-40.000121717680891, -8.20498503346392],
              [-40.000121697488659, -8.204985036297709],
              [-40.000121677290089, -8.204985039086285],
              [-40.000121657085273, -8.204985041829675],
              [-40.000121636874326, -8.204985044527831],
              [-40.000121616657346, -8.204985047180758],
              [-40.000121596434447, -8.204985049788398],
              [-40.000121576205714, -8.204985052350807],
              [-40.000121555971248, -8.204985054867924],
              [-40.000121535731154, -8.204985057339762],
              [-40.00012151548556, -8.204985059766281],
              [-40.000121495234524, -8.204985062147495],
              [-40.000121474978172, -8.204985064483376],
              [-40.000121454716599, -8.204985066773935],
              [-40.00012143444993, -8.20498506901909],
              [-40.000121414178246, -8.204985071218942],
              [-40.000121393901644, -8.204985073373388],
              [-40.000121373620246, -8.204985075482448],
              [-40.000121353334144, -8.204985077546102],
              [-40.000121333043431, -8.204985079564368],
              [-40.00012131274822, -8.204985081537242],
              [-40.000121292448611, -8.204985083464649],
              [-40.000121272144725, -8.204985085346628],
              [-40.000121251836632, -8.204985087183188],
              [-40.00012123152446, -8.204985088974267],
              [-40.000121211208274, -8.204985090719896],
              [-40.000121190888237, -8.20498509242003],
              [-40.000121170564398, -8.204985094074706],
              [-40.000121150236886, -8.204985095683922],
              [-40.000121129905786, -8.204985097247581],
              [-40.000121109571232, -8.204985098765778],
              [-40.000121089233289, -8.204985100238451],
              [-40.000121068892085, -8.204985101665617],
              [-40.000121048547712, -8.204985103047221],
              [-40.000121028200283, -8.204985104383296],
              [-40.000121007849884, -8.204985105673842],
              [-40.000120987496622, -8.204985106918846],
              [-40.000120967140695, -8.20498510811716],
              [-40.000120980680911, -8.20498514255118],
              [-39.9988716055975, -8.205057339120035],
              [-39.998871578422133, -8.205057320735259],
              [-39.998871509119446, -8.205057324475963],
              [-39.998871439790086, -8.205057327688662],
              [-39.998871370438081, -8.20505733037319],
              [-39.99887130106751, -8.205057332529359],
              [-39.998871231682422, -8.20505733415707],
              [-39.998871162286868, -8.205057335256209],
              [-39.998871092884926, -8.205057335826725],
              [-39.998871023480604, -8.205057335868549],
              [-39.998870954077994, -8.205057335381724],
              [-39.998870884681153, -8.205057334366259],
              [-39.998870815294147, -8.205057332822204],
              [-39.99887074592101, -8.205057330749657],
              [-39.998870676565815, -8.20505732814876],
              [-39.998870607232583, -8.205057325019633],
              [-39.998870537925399, -8.205057321362487],
              [-39.998870468648306, -8.2050573171775],
              [-39.998870399405355, -8.205057312464961],
              [-39.998870330200582, -8.205057307225131],
              [-39.998870261038064, -8.205057301458304],
              [-39.99887019192181, -8.205057295164808],
              [-39.99887012285587, -8.205057288345031],
              [-39.998870053844271, -8.205057280999359],
              [-39.998869984891073, -8.205057273128251],
              [-39.998869916000302, -8.205057264732131],
              [-39.998869847175946, -8.205057255811498],
              [-39.998869778422083, -8.205057246366879],
              [-39.998869709742699, -8.205057236398822],
              [-39.998869641141809, -8.205057225907906],
              [-39.998869572623441, -8.205057214894744],
              [-39.998869504191575, -8.205057203360001],
              [-39.998869435850246, -8.205057191304359],
              [-39.998869367603405, -8.205057178728476],
              [-39.998869299455087, -8.205057165633104],
              [-39.99886923140923, -8.205057152019027],
              [-39.998869163469863, -8.205057137887023],
              [-39.998869095640913, -8.205057123237962],
              [-39.998869027926368, -8.205057108072603],
              [-39.998868960330178, -8.205057092391923],
              [-39.998868892856272, -8.20505707619683],
              [-39.998868825508644, -8.205057059488229],
              [-39.998868758291188, -8.2050570422671],
              [-39.998868691207868, -8.205057024534481],
              [-39.998868624262585, -8.205057006291405],
              [-39.998868557459232, -8.205056987538891],
              [-39.998868490801769, -8.20505696827809],
              [-39.998868424294038, -8.205056948510125],
              [-39.998868357939941, -8.205056928236127],
              [-39.998868291743371, -8.205056907457287],
              [-39.998868225708208, -8.205056886174818],
              [-39.998868159838274, -8.205056864389981],
              [-39.99886809413745, -8.205056842104041],
              [-39.998868028609557, -8.205056819318264],
              [-39.998867963258448, -8.205056796034052],
              [-39.998867898087916, -8.205056772252712],
              [-39.998867833101791, -8.205056747975677],
              [-39.998867768303853, -8.205056723204295],
              [-39.998867703697918, -8.205056697940117],
              [-39.998867639287745, -8.205056672184526],
              [-39.998867575077085, -8.205056645939063],
              [-39.998867511069726, -8.205056619205266],
              [-39.998867447269376, -8.2050565919847],
              [-39.998867383679794, -8.20505656427895],
              [-39.998867320304662, -8.205056536089643],
              [-39.998867257147715, -8.205056507418401],
              [-39.998867194212622, -8.205056478266938],
              [-39.998867131503104, -8.205056448636908],
              [-39.998867069022765, -8.205056418530093],
              [-39.998867006775313, -8.205056387948229],
              [-39.998866944764345, -8.205056356893122],
              [-39.998866882993511, -8.205056325366545],
              [-39.998866821466414, -8.20505629337041],
              [-39.99886676018663, -8.20505626090652],
              [-39.99886669915778, -8.20505622797681],
              [-39.998866638383419, -8.205056194583181],
              [-39.998866577867084, -8.205056160727619],
              [-39.998866517612313, -8.205056126412051],
              [-39.99886645762264, -8.205056091638546],
              [-39.998866397901587, -8.205056056409104],
              [-39.998866338452622, -8.205056020725749],
              [-39.998866279279206, -8.20505598459061],
              [-39.998866220384841, -8.20505594800583],
              [-39.998866161772938, -8.205055910973496],
              [-39.998866103446915, -8.205055873495763],
              [-39.99886604541021, -8.205055835574855],
              [-39.9988659876662, -8.205055797213001],
              [-39.998865930218258, -8.205055758412399],
              [-39.998865873069761, -8.205055719175336],
              [-39.998865816224018, -8.205055679504122],
              [-39.998865759684385, -8.205055639401062],
              [-39.998865703454129, -8.205055598868503],
              [-39.998865647536576, -8.205055557908777],
              [-39.99886559193498, -8.205055516524357],
              [-39.998865536652588, -8.205055474717609],
              [-39.998865481692626, -8.205055432490994],
              [-39.998865427058313, -8.205055389846962],
              [-39.998865372752853, -8.205055346788013],
              [-39.998865318779394, -8.205055303316662],
              [-39.99886526514112, -8.205055259435465],
              [-39.998865211841597, -8.205055215145768],
              [-39.998865245772883, -8.205055184284433],
              [-39.997962208302617, -8.204299067773469],
              [-39.997962222365018, -8.204299042097935],
              [-39.997962182641487, -8.204299009025021],
              [-39.997962142729293, -8.204298976178324],
              [-39.997962102629735, -8.20429894355892],
              [-39.997962062344115, -8.204298911167891],
              [-39.997962021873747, -8.20429887900627],
              [-39.997961981219945, -8.204298847075105],
              [-39.997961940384059, -8.204298815375452],
              [-39.997961899367368, -8.204298783908314],
              [-39.997961858171223, -8.20429875267474],
              [-39.99796181679698, -8.20429872167573],
              [-39.997961775245983, -8.204298690912312],
              [-39.99796173351956, -8.20429866038546],
              [-39.997961691619089, -8.204298630096162],
              [-39.997961649545935, -8.204298600045444],
              [-39.997961607301463, -8.204298570234231],
              [-39.997961564887021, -8.20429854066354],
              [-39.997961522304024, -8.20429851133429],
              [-39.997961479553837, -8.204298482247479],
              [-39.997961436637851, -8.204298453404011],
              [-39.997961393557482, -8.20429842480485],
              [-39.997961350314107, -8.204298396450934],
              [-39.997961306909126, -8.204298368343164],
              [-39.997961263343981, -8.204298340482469],
              [-39.99796121962008, -8.204298312869737],
              [-39.997961175738808, -8.204298285505869],
              [-39.997961131701643, -8.204298258391765],
              [-39.997961087509992, -8.204298231528307],
              [-39.997961043165304, -8.204298204916382],
              [-39.997960998669001, -8.204298178556835],
              [-39.997960954022545, -8.2042981524505],
              [-39.997960909227395, -8.204298126598285],
              [-39.997960864284998, -8.204298101000999],
              [-39.997960819196798, -8.204298075659471],
              [-39.997960773964287, -8.204298050574515],
              [-39.997960728588936, -8.204298025746985],
              [-39.997960683072208, -8.204298001177625],
              [-39.997960637415595, -8.204297976867288],
              [-39.997960591620561, -8.204297952816757],
              [-39.997960545688613, -8.204297929026817],
              [-39.997960499621236, -8.204297905498226],
              [-39.997960453419942, -8.204297882231735],
              [-39.997960407086225, -8.204297859228138],
              [-39.99796036062159, -8.204297836488161],
              [-39.997960314027559, -8.204297814012541],
              [-39.997960267305622, -8.204297791802038],
              [-39.997960220457315, -8.204297769857323],
              [-39.997960173484174, -8.204297748179149],
              [-39.997960126387696, -8.204297726768209],
              [-39.997960079169438, -8.204297705625203],
              [-39.997960031830942, -8.204297684750832],
              [-39.997959984373708, -8.204297664145741],
              [-39.997959936799319, -8.204297643810623],
              [-39.997959889109303, -8.204297623746157],
              [-39.997959841305217, -8.204297603952957],
              [-39.997959793388617, -8.204297584431677],
              [-39.997959745361058, -8.204297565182971],
              [-39.997959697224097, -8.204297546207442],
              [-39.997959648979318, -8.204297527505712],
              [-39.997959600628256, -8.204297509078392],
              [-39.99795955217251, -8.204297490926093],
              [-39.997959503613657, -8.204297473049396],
              [-39.997959454953275, -8.204297455448874],
              [-39.997959406192926, -8.204297438125106],
              [-39.997959357334224, -8.204297421078685],
              [-39.997959308378739, -8.204297404310108],
              [-39.99795925932807, -8.204297387819961],
              [-39.997959210183815, -8.204297371608785],
              [-39.997959160947566, -8.204297355677072],
              [-39.997959111620915, -8.204297340025342],
              [-39.997959062205496, -8.204297324654139],
              [-39.997959012702871, -8.204297309563938],
              [-39.997958963114705, -8.204297294755245],
              [-39.997958913442559, -8.204297280228539],
              [-39.997958863688091, -8.204297265984298],
              [-39.997958813852883, -8.204297252022949],
              [-39.997958763938584, -8.204297238344974],
              [-39.997958713946794, -8.204297224950814],
              [-39.997958663879153, -8.204297211840915],
              [-39.997958613737296, -8.204297199015699],
              [-39.997958563522843, -8.204297186475573],
              [-39.997958513237421, -8.204297174220947],
              [-39.997958462882679, -8.204297162252216],
              [-39.997958412460228, -8.204297150569786],
              [-39.997958361971747, -8.204297139174018],
              [-39.997958311418863, -8.204297128065299],
              [-39.997958260803202, -8.204297117243993],
              [-39.997958210126434, -8.204297106710417],
              [-39.997958159390194, -8.204297096464934],
              [-39.99795810859613, -8.2042970865079],
              [-39.997958057745912, -8.204297076839609],
              [-39.997958006841166, -8.204297067460397],
              [-39.997957955883585, -8.20429705837056],
              [-39.997957904874788, -8.204297049570405],
              [-39.997957853816438, -8.204297041060169],
              [-39.997957802710225, -8.204297032840195],
              [-39.997957751557784, -8.204297024910705],
              [-39.997957700360779, -8.204297017271976],
              [-39.997957649120892, -8.204297009924264],
              [-39.997957597839793, -8.20429700286782],
              [-39.997957573483298, -8.204296999091223],
              [-39.995943268407274, -8.20402568273742],
              [-39.995943274010678, -8.204025648379348],
              [-39.995943191869941, -8.204025637693745],
              [-39.995943109634759, -8.204025627755362],
              [-39.995943027311974, -8.204025618565039],
              [-39.995942944908435, -8.204025610123557],
              [-39.995942862431022, -8.204025602431601],
              [-39.99594277988659, -8.20402559548981],
              [-39.995942697282004, -8.204025589298762],
              [-39.995942614624148, -8.204025583858977],
              [-39.99594253191993, -8.204025579170928],
              [-39.995942449176212, -8.204025575234944],
              [-39.995942366399873, -8.204025572051421],
              [-39.995942283597842, -8.204025569620557],
              [-39.995942200776973, -8.204025567942622],
              [-39.995942117944196, -8.204025567017718],
              [-39.995942035106388, -8.204025566845946],
              [-39.995941952270471, -8.204025567427287],
              [-39.995941869443307, -8.204025568761727],
              [-39.995941786631839, -8.204025570849128],
              [-39.995941703842917, -8.204025573689339],
              [-39.99594162108346, -8.204025577282104],
              [-39.995941538360363, -8.204025581627159],
              [-39.995941455680502, -8.204025586724082],
              [-39.995941373050769, -8.204025592572497],
              [-39.995941290478044, -8.204025599171887],
              [-39.995941207969196, -8.204025606521729],
              [-39.995941125531111, -8.204025614621397],
              [-39.995941043170646, -8.204025623470219],
              [-39.995940960894636, -8.204025633067436],
              [-39.99594087870998, -8.204025643412288],
              [-39.995940796623493, -8.204025654503887],
              [-39.995940714642003, -8.20402566634132],
              [-39.99594063277236, -8.204025678923607],
              [-39.995940551021349, -8.204025692249678],
              [-39.995940469395826, -8.204025706318443],
              [-39.995940387902536, -8.204025721128737],
              [-39.995940306548299, -8.204025736679306],
              [-39.995940225339886, -8.20402575296888],
              [-39.995940144284042, -8.204025769996074],
              [-39.995940063387543, -8.204025787759498],
              [-39.995939982657092, -8.20402580625764],
              [-39.995939902099444, -8.204025825488964],
              [-39.995939821721294, -8.204025845451907],
              [-39.995939741529334, -8.204025866144764],
              [-39.995939661530223, -8.204025887565841],
              [-39.995939581730667, -8.204025909713328],
              [-39.99593950213729, -8.204025932585408],
              [-39.995939422756685, -8.204025956180164],
              [-39.995939343595523, -8.204025980495643],
              [-39.99593926466035, -8.204026005529784],
              [-39.995939185957766, -8.204026031280552],
              [-39.995939107494316, -8.204026057745743],
              [-39.995939029276535, -8.204026084923202],
              [-39.995938951310926, -8.204026112810652],
              [-39.995938873603983, -8.204026141405789],
              [-39.995938796162207, -8.204026170706186],
              [-39.995938718992008, -8.204026200709437],
              [-39.99593864209983, -8.204026231413049],
              [-39.995938565492068, -8.204026262814429],
              [-39.995938489175124, -8.20402629491101],
              [-39.995938413155322, -8.204026327700076],
              [-39.995938337439007, -8.204026361178929],
              [-39.995938262032475, -8.204026395344753],
              [-39.995938186942034, -8.204026430194723],
              [-39.995938112173903, -8.204026465725939],
              [-39.995938037734327, -8.204026501935424],
              [-39.995937963629501, -8.204026538820168],
              [-39.995937889865594, -8.204026576377114],
              [-39.995937816448752, -8.204026614603119],
              [-39.995937743385085, -8.204026653495017],
              [-39.995937670680682, -8.204026693049544],
              [-39.995937598341584, -8.204026733263413],
              [-39.995937526373837, -8.204026774133292],
              [-39.995937454783409, -8.204026815655764],
              [-39.995937383576276, -8.204026857827376],
              [-39.995937312758372, -8.204026900644628],
              [-39.995937242335579, -8.204026944103935],
              [-39.995937172313774, -8.204026988201672],
              [-39.99593710269879, -8.204027032934176],
              [-39.995937033496418, -8.204027078297752],
              [-39.995936964712406, -8.204027124288574],
              [-39.995936896352511, -8.20402717090284],
              [-39.995936828422423, -8.204027218136693],
              [-39.995936760927762, -8.204027265986147],
              [-39.995936693874178, -8.204027314447234],
              [-39.995936627267255, -8.204027363515934],
              [-39.995936561112536, -8.204027413188156],
              [-39.995936495415528, -8.204027463459735],
              [-39.995936430181708, -8.204027514326523],
              [-39.995936365416497, -8.204027565784267],
              [-39.99593630112529, -8.204027617828691],
              [-39.995936237313458, -8.204027670455455],
              [-39.99593617398628, -8.204027723660191],
              [-39.995936111149078, -8.204027777438414],
              [-39.995936048807039, -8.204027831785705],
              [-39.995935986965364, -8.204027886697533],
              [-39.995935925629219, -8.204027942169303],
              [-39.995935864803727, -8.20402799819642],
              [-39.995935804493911, -8.204028054774186],
              [-39.995935744704809, -8.204028111897914],
              [-39.995935739992468, -8.204028143272037],
              [-39.995256537283765, -8.20468299214356],
              [-39.993925719948045, -8.20621112662973],
              [-39.993925709906875, -8.206211101638218],
              [-39.993925658643015, -8.206211159992272],
              [-39.993925606878854, -8.206211217906279],
              [-39.993925554618208, -8.206211275375983],
              [-39.993925501864915, -8.206211332397162],
              [-39.993925448622875, -8.206211388965626],
              [-39.993925394895975, -8.206211445077217],
              [-39.993925340688172, -8.206211500727804],
              [-39.993925286003453, -8.206211555913304],
              [-39.993925230845825, -8.206211610629662],
              [-39.993925175219367, -8.206211664872839],
              [-39.99392511912815, -8.206211718638874],
              [-39.993925062576295, -8.206211771923794],
              [-39.993925005567988, -8.206211824723677],
              [-39.993924948107377, -8.206211877034681],
              [-39.993924890198713, -8.206211928852891],
              [-39.993924831846257, -8.206211980174604],
              [-39.993924773054268, -8.20621203099596],
              [-39.993924713827113, -8.206212081313208],
              [-39.993924654169092, -8.206212131122724],
              [-39.993924594084653, -8.206212180420776],
              [-39.993924533578166, -8.206212229203766],
              [-39.993924472654093, -8.206212277468168],
              [-39.993924411316911, -8.206212325210348],
              [-39.993924349571131, -8.206212372426812],
              [-39.993924287421287, -8.206212419114161],
              [-39.993924224871961, -8.206212465268866],
              [-39.993924161927744, -8.206212510887609],
              [-39.993924098593247, -8.206212555966999],
              [-39.993924034873153, -8.206212600503733],
              [-39.993923970772137, -8.206212644494498],
              [-39.993923906294889, -8.206212687936118],
              [-39.99392384144619, -8.206212730825383],
              [-39.993923776230758, -8.206212773159127],
              [-39.993923710653434, -8.206212814934224],
              [-39.993923644719018, -8.206212856147658],
              [-39.993923578432351, -8.206212896796341],
              [-39.993923511798307, -8.206212936877311],
              [-39.993923444821789, -8.206212976387622],
              [-39.993923377507727, -8.206213015324368],
              [-39.993923309861053, -8.206213053684687],
              [-39.993923241886762, -8.206213091465758],
              [-39.993923173589835, -8.2062131286648],
              [-39.993923104975288, -8.20621316527907],
              [-39.99392303604818, -8.206213201305905],
              [-39.993922966813564, -8.206213236742618],
              [-39.993922897276548, -8.206213271586613],
              [-39.993922827442219, -8.206213305835377],
              [-39.993922757315737, -8.206213339486336],
              [-39.993922686902238, -8.206213372537041],
              [-39.993922616206909, -8.20621340498505],
              [-39.993922545234931, -8.206213436828007],
              [-39.993922473991546, -8.206213468063526],
              [-39.993922402481964, -8.206213498689355],
              [-39.993922330711463, -8.20621352870322],
              [-39.993922258685309, -8.206213558102917],
              [-39.993922186408795, -8.206213586886282],
              [-39.993922113887237, -8.206213615051208],
              [-39.99392204112597, -8.206213642595596],
              [-39.993921968130337, -8.206213669517458],
              [-39.993921894905704, -8.206213695814803],
              [-39.993921821457469, -8.206213721485701],
              [-39.993921747791006, -8.206213746528244],
              [-39.993921673911757, -8.206213770940611],
              [-39.993921599825136, -8.206213794721025],
              [-39.993921525536578, -8.206213817867679],
              [-39.99392145105157, -8.206213840378934],
              [-39.993921376375567, -8.206213862253103],
              [-39.993921301514085, -8.206213883488592],
              [-39.993921226472594, -8.206213904083819],
              [-39.993921151256622, -8.206213924037289],
              [-39.993921075871704, -8.206213943347523],
              [-39.99392100032339, -8.206213962013125],
              [-39.993920924617207, -8.2062139800327],
              [-39.993920848758748, -8.206213997404937],
              [-39.99392077275359, -8.206214014128523],
              [-39.993920696607276, -8.206214030202274],
              [-39.993920620325461, -8.206214045624987],
              [-39.993920543913724, -8.206214060395538],
              [-39.99392046737767, -8.206214074512836],
              [-39.993920390722963, -8.206214087975857],
              [-39.993920313955194, -8.206214100783582],
              [-39.993920237080047, -8.206214112935093],
              [-39.993920160103137, -8.206214124429476],
              [-39.993920083030147, -8.206214135265903],
              [-39.993920005866734, -8.206214145443568],
              [-39.993919928618588, -8.206214154961751],
              [-39.993919851291338, -8.2062141638197],
              [-39.993919773890717, -8.206214172016789],
              [-39.99391969642241, -8.206214179552454],
              [-39.993919618892086, -8.206214186426065],
              [-39.993919541305473, -8.20621419263718],
              [-39.993919463668249, -8.206214198185327],
              [-39.993919385986139, -8.206214203070084],
              [-39.993919308264836, -8.206214207291087],
              [-39.993919230510087, -8.206214210848064],
              [-39.993919152727571, -8.206214213740726],
              [-39.993919074923035, -8.206214215968842],
              [-39.993918997102163, -8.206214217532274],
              [-39.993918919271024, -8.206214218430246],
              [-39.993918875826047, -8.206214218537401],
              [-39.993116989885465, -8.206216196012621],
              [-39.991644395014767, -8.206219823320168],
              [-39.991644356257886, -8.206219823416379],
              [-39.991644261844669, -8.206219824137934],
              [-39.9916441674441, -8.206219825837795],
              [-39.991644073066354, -8.206219828515732],
              [-39.991643978721697, -8.206219832171467],
              [-39.991643884420306, -8.206219836804587],
              [-39.991643790172368, -8.206219842414678],
              [-39.991643695988103, -8.206219849001045],
              [-39.991643601877691, -8.206219856563006],
              [-39.991643507851308, -8.206219865099724],
              [-39.991643413919114, -8.206219874610312],
              [-39.991643320091299, -8.206219885093692],
              [-39.991643226377995, -8.206219896548772],
              [-39.991643132789349, -8.206219908974317],
              [-39.991643039335472, -8.206219922368955],
              [-39.991642946026481, -8.206219936731241],
              [-39.991642852872452, -8.206219952059621],
              [-39.991642759883518, -8.206219968352469],
              [-39.991642667069677, -8.206219985607975],
              [-39.991642574440981, -8.206220003824276],
              [-39.991642482007492, -8.20622002299943],
              [-39.991642389779159, -8.206220043131356],
              [-39.991642297765956, -8.20622006421789],
              [-39.99164220597789, -8.206220086256698],
              [-39.991642114424835, -8.206220109245452],
              [-39.991642023116739, -8.206220133181613],
              [-39.991641932063438, -8.206220158062662],
              [-39.99164184127482, -8.206220183885868],
              [-39.991641750760685, -8.206220210648423],
              [-39.991641660530831, -8.206220238347445],
              [-39.991641570595007, -8.206220266979939],
              [-39.991641480962954, -8.206220296542835],
              [-39.991641391644372, -8.206220327032867],
              [-39.991641302648908, -8.206220358446808],
              [-39.991641213986199, -8.206220390781224],
              [-39.991641125665836, -8.206220424032615],
              [-39.991641037697356, -8.206220458197379],
              [-39.9916409500903, -8.206220493271848],
              [-39.991640862854148, -8.206220529252208],
              [-39.99164077599832, -8.206220566134583],
              [-39.991640689532197, -8.206220603914971],
              [-39.991640603465171, -8.206220642589273],
              [-39.991640517806538, -8.206220682153319],
              [-39.991640432565532, -8.206220722602829],
              [-39.991640347751414, -8.206220763933432],
              [-39.991640263373348, -8.206220806140639],
              [-39.991640179440459, -8.206220849219916],
              [-39.991640095961827, -8.206220893166574],
              [-39.991640012946483, -8.206220937975854],
              [-39.991639930403402, -8.206220983642918],
              [-39.991639848341528, -8.20622103016286],
              [-39.991639766769723, -8.206221077530621],
              [-39.991639685696818, -8.206221125741072],
              [-39.991639605131574, -8.206221174788986],
              [-39.991639525082725, -8.206221224669068],
              [-39.991639445558903, -8.206221275375935],
              [-39.991639366568734, -8.206221326904089],
              [-39.991639288120759, -8.206221379247946],
              [-39.991639210223468, -8.20622143240187],
              [-39.991639132885268, -8.206221486360095],
              [-39.991639056114536, -8.206221541116793],
              [-39.991638979919578, -8.206221596666026],
              [-39.991638904308644, -8.206221653001798],
              [-39.991638829289904, -8.206221710118006],
              [-39.99163875487146, -8.206221768008477],
              [-39.991638681061396, -8.206221826666944],
              [-39.991638607867664, -8.206221886087071],
              [-39.991638535298215, -8.206221946262422],
              [-39.991638463360871, -8.206222007186486],
              [-39.991638392063408, -8.2062220688527],
              [-39.991638321413561, -8.206222131254362],
              [-39.991638251418976, -8.206222194384722],
              [-39.991638182087186, -8.206222258236943],
              [-39.991638113425743, -8.206222322804175],
              [-39.991638045442038, -8.206222388079363],
              [-39.991637978143451, -8.20622245405546],
              [-39.991637911537239, -8.20622252072538],
              [-39.991637845630606, -8.206222588081838],
              [-39.991637780430715, -8.206222656117607],
              [-39.991637715944577, -8.206222724825277],
              [-39.991637652179207, -8.206222794197485],
              [-39.991637589141469, -8.20622286422665],
              [-39.991637526838197, -8.206222934905242],
              [-39.991637465276142, -8.206223006225613],
              [-39.991637404461933, -8.206223078179995],
              [-39.991637344402172, -8.206223150760676],
              [-39.991637285103351, -8.206223223959791],
              [-39.991637226571889, -8.206223297769387],
              [-39.991637168814115, -8.206223372181483],
              [-39.991637111836262, -8.206223447188075],
              [-39.99163705564451, -8.206223522781013],
              [-39.99163700024495, -8.206223598952128],
              [-39.991636945643549, -8.206223675693167],
              [-39.991636891846227, -8.206223752995848],
              [-39.991636838858788, -8.206223830851812],
              [-39.991636786686989, -8.206223909252628],
              [-39.991636735336463, -8.206223988189789],
              [-39.991636684812754, -8.206224067654805],
              [-39.991636635121345, -8.206224147639039],
              [-39.991636586267603, -8.206224228133856],
              [-39.991636598900826, -8.20622418372113],
              [-39.990837782398849, -8.207555916828339],
              [-39.990837808462956, -8.207555937259135],
              [-39.990837783126821, -8.207555979762345],
              [-39.990837758024242, -8.207556022402883],
              [-39.990837733155963, -8.207556065179482],
              [-39.99083770852274, -8.207556108090875],
              [-39.990837684125303, -8.207556151135751],
              [-39.990837659964377, -8.207556194312845],
              [-39.990837636040688, -8.207556237620853],
              [-39.990837612354944, -8.207556281058515],
              [-39.990837588907873, -8.207556324624495],
              [-39.990837565700154, -8.20755636831751],
              [-39.990837542732486, -8.207556412136245],
              [-39.990837520005556, -8.207556456079388],
              [-39.990837497520026, -8.207556500145641],
              [-39.990837475276614, -8.207556544333681],
              [-39.990837453275951, -8.207556588642198],
              [-39.990837431518685, -8.207556633069842],
              [-39.99083741000549, -8.207556677615326],
              [-39.990837388736992, -8.207556722277292],
              [-39.990837367713837, -8.207556767054385],
              [-39.990837346936651, -8.20755681194531],
              [-39.990837326406059, -8.207556856948699],
              [-39.990837306122664, -8.2075569020632],
              [-39.990837286087064, -8.2075569472875],
              [-39.990837266299884, -8.207556992620239],
              [-39.990837246761686, -8.207557038060044],
              [-39.990837227473087, -8.207557083605545],
              [-39.990837208434641, -8.207557129255434],
              [-39.990837189646911, -8.207557175008299],
              [-39.990837171110464, -8.207557220862792],
              [-39.990837152825875, -8.207557266817531],
              [-39.990837134793665, -8.207557312871154],
              [-39.990837117014379, -8.207557359022287],
              [-39.990837099488552, -8.20755740526956],
              [-39.990837082216707, -8.207557451611571],
              [-39.990837065199351, -8.207557498046938],
              [-39.990837048437008, -8.2075575445743],
              [-39.990837031930155, -8.20755759119224],
              [-39.990837015679332, -8.207557637899372],
              [-39.990836999684959, -8.207557684694295],
              [-39.990836983947574, -8.207557731575616],
              [-39.990836968467605, -8.207557778541929],
              [-39.990836953245534, -8.207557825591826],
              [-39.990836938281824, -8.207557872723934],
              [-39.990836923576893, -8.207557919936807],
              [-39.990836909131204, -8.207557967229056],
              [-39.990836894945197, -8.207558014599273],
              [-39.990836881019263, -8.207558062046022],
              [-39.990836867353835, -8.207558109567906],
              [-39.99083685394934, -8.207558157163486],
              [-39.990836840806153, -8.207558204831352],
              [-39.990836827924674, -8.207558252570104],
              [-39.990836815305272, -8.207558300378254],
              [-39.990836802948365, -8.207558348254441],
              [-39.990836790854281, -8.207558396197179],
              [-39.990836779023404, -8.207558444205073],
              [-39.990836767456088, -8.207558492276656],
              [-39.99083675615266, -8.20755854041052],
              [-39.990836745113477, -8.207558588605208],
              [-39.990836734338863, -8.207558636859298],
              [-39.990836723829133, -8.207558685171334],
              [-39.990836713584606, -8.207558733539891],
              [-39.990836703605588, -8.20755878196352],
              [-39.990836693892376, -8.207558830440739],
              [-39.99083668444527, -8.207558878970152],
              [-39.990836675264546, -8.207558927550288],
              [-39.990836666350468, -8.207558976179666],
              [-39.990836657703305, -8.20755902485687],
              [-39.990836649323334, -8.207559073580438],
              [-39.990836641210784, -8.207559122348913],
              [-39.990836633365916, -8.207559171160849],
              [-39.990836625788937, -8.207559220014765],
              [-39.990836618480103, -8.207559268909211],
              [-39.990836611439626, -8.207559317842733],
              [-39.990836604667685, -8.207559366813852],
              [-39.99083659816452, -8.207559415821141],
              [-39.990836591930311, -8.20755946486309],
              [-39.990836585965255, -8.207559513938254],
              [-39.990836580269516, -8.207559563045169],
              [-39.990836574843264, -8.207559612182367],
              [-39.990836569686678, -8.20755966134838],
              [-39.990836564799892, -8.207559710541746],
              [-39.990836560183062, -8.207559759760999],
              [-39.990836555836317, -8.207559809004637],
              [-39.990836551759799, -8.207559858271193],
              [-39.990836547953627, -8.207559907559242],
              [-39.990836544417917, -8.20755995686727],
              [-39.990836541152767, -8.207560006193829],
              [-39.990836538158263, -8.207560055537423],
              [-39.990836535434525, -8.207560104896592],
              [-39.990836532981625, -8.207560154269833],
              [-39.990836530799612, -8.207560203655698],
              [-39.990836528888586, -8.207560253052725],
              [-39.990836527248582, -8.207560302459393],
              [-39.990836525879637, -8.207560351874276],
              [-39.990836524781834, -8.207560401295883],
              [-39.990836523955167, -8.207560450722706],
              [-39.990836523399679, -8.207560500153306],
              [-39.990836523115377, -8.207560549586189],
              [-39.990836523102281, -8.207560599019889],
              [-39.99083652316147, -8.207560622906065],
              [-39.990839490711338, -8.208758038136835],
              [-39.990839490782157, -8.208758066708578],
              [-39.990839490584989, -8.208758120200839],
              [-39.990839490070272, -8.208758173691018],
              [-39.990839489238056, -8.208758227177226],
              [-39.990839488088326, -8.208758280657594],
              [-39.990839486621155, -8.208758334130257],
              [-39.990839484836563, -8.208758387593338],
              [-39.990839482734664, -8.208758441044969],
              [-39.990839480315479, -8.208758494483279],
              [-39.990839477579108, -8.208758547906401],
              [-39.990839474525664, -8.208758601312462],
              [-39.990839471155233, -8.208758654699576],
              [-39.990839467467936, -8.208758708065927],
              [-39.990839463463921, -8.208758761409621],
              [-39.990839459143302, -8.208758814728776],
              [-39.990839454506251, -8.208758868021553],
              [-39.990839449552915, -8.208758921286082],
              [-39.990839444283466, -8.20875897452046],
              [-39.990839438698124, -8.208759027722882],
              [-39.990839432797031, -8.208759080891463],
              [-39.990839426580429, -8.208759134024351],
              [-39.990839420048516, -8.208759187119652],
              [-39.990839413201535, -8.208759240175549],
              [-39.990839406039719, -8.208759293190177],
              [-39.990839398563331, -8.208759346161679],
              [-39.990839390772599, -8.208759399088182],
              [-39.990839382667843, -8.208759451967866],
              [-39.990839374249298, -8.208759504798882],
              [-39.990839365517289, -8.208759557579354],
              [-39.990839356472122, -8.208759610307462],
              [-39.990839347114097, -8.208759662981358],
              [-39.99083933744356, -8.2087597155992],
              [-39.990839327460826, -8.208759768159117],
              [-39.990839317166262, -8.208759820659308],
              [-39.990839306560233, -8.2087598730979],
              [-39.990839295643092, -8.208759925473112],
              [-39.990839284415223, -8.208759977783048],
              [-39.990839272877032, -8.208760030025941],
              [-39.990839261028924, -8.208760082199925],
              [-39.990839248871303, -8.208760134303189],
              [-39.990839236404575, -8.208760186333903],
              [-39.990839223629209, -8.208760238290257],
              [-39.990839210545644, -8.208760290170416],
              [-39.99083919715433, -8.208760341972594],
              [-39.990839183455748, -8.208760393694941],
              [-39.990839169450361, -8.208760445335658],
              [-39.990839155138673, -8.208760496892973],
              [-39.99083914052116, -8.208760548365035],
              [-39.990839125598363, -8.208760599750066],
              [-39.990839110370793, -8.208760651046296],
              [-39.990839094838975, -8.208760702251867],
              [-39.990839079003472, -8.208760753365025],
              [-39.990839062864815, -8.208760804383983],
              [-39.990839046423581, -8.208760855306956],
              [-39.990839029680338, -8.208760906132193],
              [-39.990839012635682, -8.208760956857867],
              [-39.99083899529019, -8.208761007482211],
              [-39.990838977644508, -8.208761058003489],
              [-39.990838959699211, -8.208761108419896],
              [-39.990838941454939, -8.208761158729681],
              [-39.990838922912339, -8.208761208931087],
              [-39.990838904072056, -8.208761259022358],
              [-39.990838884934746, -8.208761309001746],
              [-39.990838865501082, -8.208761358867497],
              [-39.990838845771748, -8.208761408617869],
              [-39.99083882574741, -8.208761458251114],
              [-39.990838805428801, -8.208761507765511],
              [-39.990838784816624, -8.20876155715932],
              [-39.990838763911576, -8.208761606430825],
              [-39.990838742714416, -8.20876165557825],
              [-39.990838721225863, -8.208761704599944],
              [-39.990838699446691, -8.208761753494175],
              [-39.990838677377646, -8.208761802259186],
              [-39.990838655019502, -8.208761850893323],
              [-39.990838632373055, -8.208761899394867],
              [-39.990838609439081, -8.208761947762143],
              [-39.990838586218388, -8.208761995993425],
              [-39.990838562711787, -8.208762044087035],
              [-39.990838538920094, -8.208762092041303],
              [-39.990838514844157, -8.208762139854548],
              [-39.990838490484805, -8.208762187525076],
              [-39.990838465842899, -8.208762235051243],
              [-39.990838440919283, -8.208762282431385],
              [-39.990838415714855, -8.208762329663855],
              [-39.990838390230479, -8.208762376746984],
              [-39.990838364467059, -8.208762423679124],
              [-39.990838338425476, -8.208762470458622],
              [-39.990838312106661, -8.208762517083874],
              [-39.990838285511522, -8.208762563553226],
              [-39.990838258640999, -8.208762609865063],
              [-39.990838231496028, -8.208762656017763],
              [-39.990838204077555, -8.208762702009713],
              [-39.990838176386539, -8.208762747839314],
              [-39.990838148423954, -8.208762793504931],
              [-39.990838120190766, -8.208762839004979],
              [-39.990838091687976, -8.208762884337887],
              [-39.990838062916588, -8.208762929502047],
              [-39.99083803387758, -8.208762974495883],
              [-39.990838004571998, -8.208763019317836],
              [-39.990837975000829, -8.208763063966405],
              [-39.990837959880409, -8.208763044780541],
              [-39.990305071771743, -8.209562595922351],
              [-39.990305027314186, -8.209562573908901],
              [-39.990305010059245, -8.209562599698105],
              [-39.990304992715693, -8.209562625428241],
              [-39.990304975283735, -8.209562651099029],
              [-39.990304957763577, -8.209562676710162],
              [-39.99030494015544, -8.209562702261318],
              [-39.990304922459508, -8.209562727752219],
              [-39.990304904676002, -8.209562753182533],
              [-39.990304886805134, -8.209562778551996],
              [-39.990304868847097, -8.209562803860287],
              [-39.990304850802112, -8.209562829107117],
              [-39.990304832670404, -8.209562854292184],
              [-39.990304814452159, -8.2095628794152],
              [-39.990304796147619, -8.209562904475865],
              [-39.990304777756975, -8.209562929473886],
              [-39.990304759280463, -8.209562954408982],
              [-39.990304740718287, -8.209562979280845],
              [-39.990304722070661, -8.209563004089189],
              [-39.990304703337813, -8.209563028833712],
              [-39.99030468451997, -8.20956305351414],
              [-39.990304665617337, -8.209563078130191],
              [-39.99030464663015, -8.209563102681541],
              [-39.990304627558622, -8.209563127167923],
              [-39.990304608402965, -8.209563151589029],
              [-39.990304589163443, -8.209563175944609],
              [-39.990304569840248, -8.209563200234358],
              [-39.990304550433628, -8.209563224457971],
              [-39.990304530943781, -8.209563248615215],
              [-39.990304511370958, -8.209563272705765],
              [-39.990304491715392, -8.209563296729325],
              [-39.990304471977318, -8.209563320685666],
              [-39.990304452156941, -8.209563344574473],
              [-39.990304432254511, -8.209563368395454],
              [-39.990304412270262, -8.209563392148359],
              [-39.990304392204429, -8.209563415832891],
              [-39.99030437205726, -8.209563439448768],
              [-39.990304351828961, -8.20956346299571],
              [-39.990304331519802, -8.209563486473476],
              [-39.990304311129975, -8.209563509881766],
              [-39.990304290659779, -8.209563533220297],
              [-39.990304270109419, -8.209563556488789],
              [-39.990304249479138, -8.209563579687028],
              [-39.99030422876919, -8.209563602814661],
              [-39.990304207979811, -8.209563625871427],
              [-39.990304187111235, -8.209563648857101],
              [-39.990304166163725, -8.209563671771374],
              [-39.990304145137529, -8.209563694613989],
              [-39.990304124032875, -8.209563717384695],
              [-39.990304102850025, -8.209563740083222],
              [-39.990304081589208, -8.209563762709289],
              [-39.990304060250693, -8.209563785262631],
              [-39.990304038834751, -8.209563807742992],
              [-39.990304017341586, -8.209563830150106],
              [-39.990303995771491, -8.20956385248369],
              [-39.990303974124679, -8.209563874743509],
              [-39.99030395240144, -8.209563896929291],
              [-39.990303930602018, -8.209563919040772],
              [-39.990303908726666, -8.20956394107769],
              [-39.990303886775635, -8.209563963039814],
              [-39.990303864749201, -8.209563984926836],
              [-39.990303842647613, -8.209564006738541],
              [-39.990303820471119, -8.209564028474656],
              [-39.99030379821999, -8.20956405013491],
              [-39.990303775894482, -8.209564071719086],
              [-39.990303753494878, -8.209564093226897],
              [-39.990303731021427, -8.209564114658122],
              [-39.990303708474372, -8.209564136012471],
              [-39.990303685854009, -8.209564157289732],
              [-39.990303663160581, -8.209564178489661],
              [-39.99030364039438, -8.209564199611957],
              [-39.990303617555661, -8.209564220656381],
              [-39.990303594644665, -8.20956424162272],
              [-39.990303571661698, -8.209564262510735],
              [-39.990303548607038, -8.20956428332013],
              [-39.990303525480897, -8.209564304050717],
              [-39.990303502283609, -8.209564324702249],
              [-39.990303479015424, -8.209564345274428],
              [-39.99030345567661, -8.209564365767012],
              [-39.990303432267446, -8.209564386179833],
              [-39.990303408788201, -8.209564406512591],
              [-39.990303385239145, -8.209564426765079],
              [-39.99030336162059, -8.20956444693703],
              [-39.990303337932765, -8.209564467028239],
              [-39.990303314175982, -8.209564487038454],
              [-39.990303290350518, -8.209564506967455],
              [-39.990303266456628, -8.209564526814971],
              [-39.990303242494612, -8.209564546580783],
              [-39.99030321846476, -8.209564566264687],
              [-39.990303194367321, -8.209564585866445],
              [-39.990303170202623, -8.209564605385808],
              [-39.990303145970906, -8.209564624822537],
              [-39.990303121672483, -8.209564644176416],
              [-39.990303097307645, -8.209564663447233],
              [-39.990303072876635, -8.209564682634745],
              [-39.990303048379793, -8.209564701738744],
              [-39.99030302381739, -8.209564720758978],
              [-39.99030299918968, -8.20956473969524],
              [-39.990302974496998, -8.209564758547316],
              [-39.990302949739608, -8.209564777315002],
              [-39.990302924917536, -8.209564795999523],
              [-39.990302898466197, -8.209564771873032],
              [-39.989770288675459, -8.20996429711534],
              [-39.989770256923045, -8.209964300860737],
              [-39.989770209933674, -8.209964335866104],
              [-39.989770162718202, -8.209964370568168],
              [-39.989770115278603, -8.209964404965486],
              [-39.989770067616853, -8.209964439056636],
              [-39.989770019734927, -8.209964472840177],
              [-39.989769971634864, -8.209964506314675],
              [-39.989769923318654, -8.209964539478808],
              [-39.989769874788323, -8.209964572331113],
              [-39.989769826045872, -8.209964604870224],
              [-39.989769777093358, -8.209964637094854],
              [-39.989769727932824, -8.209964669003556],
              [-39.989769678566333, -8.209964700595064],
              [-39.989769628995923, -8.209964731868043],
              [-39.98976957922369, -8.209964762821208],
              [-39.989769529251696, -8.209964793453208],
              [-39.989769479082028, -8.209964823762833],
              [-39.989769428716784, -8.209964853748756],
              [-39.989769378158066, -8.209964883409777],
              [-39.989769327408013, -8.209964912744619],
              [-39.989769276468714, -8.209964941752068],
              [-39.9897692253423, -8.209964970430908],
              [-39.989769174030918, -8.209964998779949],
              [-39.989769122536707, -8.209965026798013],
              [-39.989769070861811, -8.209965054483931],
              [-39.989769019008385, -8.209965081836531],
              [-39.989768966978609, -8.209965108854663],
              [-39.989768914774658, -8.209965135537216],
              [-39.989768862398698, -8.209965161883064],
              [-39.989768809852933, -8.209965187891134],
              [-39.989768757139544, -8.209965213560281],
              [-39.989768704260733, -8.209965238889511],
              [-39.989768651218725, -8.209965263877708],
              [-39.989768598015708, -8.209965288523835],
              [-39.989768544653934, -8.209965312826901],
              [-39.989768491135614, -8.209965336785839],
              [-39.989768437462978, -8.209965360399721],
              [-39.989768383638292, -8.209965383667482],
              [-39.989768329663789, -8.209965406588177],
              [-39.989768275541721, -8.209965429160864],
              [-39.989768221274353, -8.209965451384567],
              [-39.989768166863954, -8.209965473258412],
              [-39.989768112312795, -8.209965494781441],
              [-39.989768057623152, -8.209965515952732],
              [-39.989768002797305, -8.209965536771469],
              [-39.989767947837564, -8.209965557236748],
              [-39.989767892746194, -8.20996557734771],
              [-39.989767837525513, -8.209965597103484],
              [-39.98976778217785, -8.209965616503307],
              [-39.989767726705473, -8.20996563554634],
              [-39.989767671110734, -8.209965654231844],
              [-39.989767615395934, -8.20996567255894],
              [-39.989767559563411, -8.209965690526882],
              [-39.989767503615504, -8.209965708134972],
              [-39.989767447554527, -8.209965725382428],
              [-39.989767391382848, -8.209965742268576],
              [-39.989767335102776, -8.209965758792649],
              [-39.989767278716705, -8.209965774953996],
              [-39.989767222226973, -8.209965790751962],
              [-39.989767165635932, -8.209965806185846],
              [-39.989767108945955, -8.20996582125502],
              [-39.989767052159408, -8.209965835958858],
              [-39.98976699527865, -8.209965850296758],
              [-39.989766938306083, -8.209965864268097],
              [-39.989766881244066, -8.209965877872273],
              [-39.989766824095007, -8.209965891108752],
              [-39.989766766861251, -8.209965903976995],
              [-39.989766709545229, -8.209965916476408],
              [-39.989766652149306, -8.209965928606536],
              [-39.989766594675899, -8.209965940366832],
              [-39.989766537127402, -8.209965951756814],
              [-39.989766479506216, -8.209965962775975],
              [-39.98976642181475, -8.209965973423905],
              [-39.989766364055434, -8.20996598370014],
              [-39.989766306230642, -8.209965993604238],
              [-39.989766248342818, -8.209966003135825],
              [-39.989766190394356, -8.209966012294455],
              [-39.989766132387707, -8.209966021079731],
              [-39.989766074325274, -8.209966029491381],
              [-39.989766016209494, -8.209966037528927],
              [-39.989765958042781, -8.209966045192147],
              [-39.989765899827567, -8.209966052480675],
              [-39.989765841566303, -8.209966059394191],
              [-39.989765783261383, -8.209966065932415],
              [-39.989765724915287, -8.20996607209509],
              [-39.989765666530424, -8.209966077881923],
              [-39.989765608109238, -8.209966083292725],
              [-39.989765549654166, -8.209966088327249],
              [-39.989765491167667, -8.209966092985262],
              [-39.98976543265217, -8.209966097266564],
              [-39.989765374110114, -8.209966101171002],
              [-39.989765315543977, -8.209966104698434],
              [-39.989765256956161, -8.209966107848649],
              [-39.989765198349133, -8.209966110621581],
              [-39.989765139725343, -8.209966113017082],
              [-39.989765081087235, -8.209966115035046],
              [-39.989765022437268, -8.209966116675385],
              [-39.98976496377788, -8.209966117938057],
              [-39.989764905111521, -8.209966118822996],
              [-39.989764846440679, -8.209966119330256],
              [-39.989764819590683, -8.209966119396247],
              [-39.988693114550891, -8.209968752012029],
              [-39.987487145264112, -8.209971711034362],
              [-39.987487120719727, -8.20997171109453],
              [-39.987487060419475, -8.209971710879454],
              [-39.987487000122009, -8.209971710265306],
              [-39.987486939829942, -8.209971709252144],
              [-39.987486879545976, -8.209971707840047],
              [-39.987486819272746, -8.209971706029025],
              [-39.987486759012924, -8.209971703819191],
              [-39.98748669876916, -8.209971701210627],
              [-39.987486638544119, -8.209971698203431],
              [-39.987486578340459, -8.209971694797744],
              [-39.987486518160821, -8.209971690993756],
              [-39.987486458007879, -8.209971686791576],
              [-39.987486397884304, -8.209971682191423],
              [-39.987486337792689, -8.209971677193488],
              [-39.987486277735748, -8.209971671798023],
              [-39.987486217716082, -8.209971666005217],
              [-39.98748615773637, -8.20997165981535],
              [-39.987486097799248, -8.209971653228722],
              [-39.98748603790736, -8.209971646245563],
              [-39.987485978063361, -8.209971638866236],
              [-39.987485918269883, -8.209971631091033],
              [-39.987485858529546, -8.209971622920312],
              [-39.987485798845015, -8.209971614354442],
              [-39.987485739218897, -8.209971605393777],
              [-39.987485679653844, -8.209971596038747],
              [-39.987485620152462, -8.209971586289736],
              [-39.98748556071741, -8.209971576147154],
              [-39.987485501351287, -8.209971565611486],
              [-39.987485442056709, -8.209971554683216],
              [-39.987485382836304, -8.209971543362753],
              [-39.987485323692681, -8.209971531650666],
              [-39.98748526462844, -8.209971519547475],
              [-39.987485205646216, -8.20997150705362],
              [-39.987485146748554, -8.209971494169759],
              [-39.987485087938119, -8.209971480896389],
              [-39.987485029217467, -8.209971467234141],
              [-39.987484970589207, -8.20997145318362],
              [-39.987484912055891, -8.209971438745402],
              [-39.987484853620145, -8.209971423920166],
              [-39.98748479528453, -8.209971408708535],
              [-39.987484737051616, -8.209971393111195],
              [-39.98748467892397, -8.209971377128852],
              [-39.987484620904176, -8.209971360762172],
              [-39.98748456299478, -8.209971344011921],
              [-39.987484505198317, -8.209971326878803],
              [-39.987484447517367, -8.209971309363601],
              [-39.987484389954467, -8.209971291467069],
              [-39.987484332512153, -8.209971273189991],
              [-39.987484275192962, -8.209971254533173],
              [-39.987484217999416, -8.209971235497463],
              [-39.987484160934066, -8.209971216083698],
              [-39.987484103999378, -8.209971196292701],
              [-39.98748404719791, -8.209971176125372],
              [-39.987483990532148, -8.209971155582599],
              [-39.987483934004601, -8.209971134665265],
              [-39.987483877617755, -8.209971113374333],
              [-39.987483821374106, -8.209971091710736],
              [-39.987483765276124, -8.209971069675404],
              [-39.987483709326291, -8.209971047269336],
              [-39.987483653527093, -8.209971024493482],
              [-39.987483597880953, -8.209971001348874],
              [-39.987483542390358, -8.209970977836525],
              [-39.987483487057737, -8.209970953957505],
              [-39.987483431885551, -8.209970929712821],
              [-39.987483376876213, -8.209970905103541],
              [-39.987483322032169, -8.209970880130788],
              [-39.98748326735582, -8.209970854795632],
              [-39.987483212849597, -8.209970829099237],
              [-39.9874831585159, -8.209970803042703],
              [-39.987483104357111, -8.209970776627175],
              [-39.987483050375658, -8.209970749853836],
              [-39.987482996573874, -8.209970722723863],
              [-39.987482942954138, -8.209970695238432],
              [-39.987482889518859, -8.209970667398784],
              [-39.98748283627036, -8.209970639206118],
              [-39.987482783210993, -8.209970610661699],
              [-39.98748273034311, -8.209970581766799],
              [-39.987482677669036, -8.209970552522623],
              [-39.987482625191106, -8.209970522930538],
              [-39.987482572911617, -8.209970492991847],
              [-39.987482520832891, -8.209970462707838],
              [-39.987482468957218, -8.209970432079858],
              [-39.987482417286898, -8.209970401109265],
              [-39.987482365824178, -8.209970369797416],
              [-39.987482314571366, -8.209970338145691],
              [-39.987482263530701, -8.209970306155478],
              [-39.987482212704457, -8.209970273828203],
              [-39.987482162094857, -8.209970241165315],
              [-39.987482111704146, -8.209970208168203],
              [-39.987482061534529, -8.20997017483838],
              [-39.987482011588234, -8.209970141177267],
              [-39.987481961867466, -8.209970107186377],
              [-39.987481912374413, -8.209970072867192],
              [-39.987481863111263, -8.209970038221236],
              [-39.987481814080191, -8.209970003250067],
              [-39.987481765283349, -8.209969967955169],
              [-39.98748171672289, -8.209969932338129],
              [-39.987481668400982, -8.209969896400553],
              [-39.987481620319727, -8.209969860143957],
              [-39.987481572480824, -8.209969823571226],
              [-39.987481578531451, -8.209969825402682],
              [-39.9862781934713, -8.209043482503807],
              [-39.986278214462516, -8.209043462292215],
              [-39.986278119435561, -8.209043390128882],
              [-39.986278023463491, -8.209043319217905],
              [-39.986277926562948, -8.209043249571531],
              [-39.986277828750687, -8.20904318120188],
              [-39.986277730043696, -8.209043114120751],
              [-39.986277630459028, -8.209043048339781],
              [-39.986277530013972, -8.209042983870363],
              [-39.986277428725906, -8.209042920723656],
              [-39.986277326612395, -8.209042858910619],
              [-39.986277223691133, -8.209042798441974],
              [-39.986277119979931, -8.20904273932817],
              [-39.986277015496761, -8.209042681579445],
              [-39.986276910259726, -8.209042625205806],
              [-39.986276804287066, -8.209042570217028],
              [-39.98627669759712, -8.209042516622644],
              [-39.986276590208412, -8.209042464431947],
              [-39.986276482139488, -8.209042413653943],
              [-39.986276373409119, -8.209042364297449],
              [-39.986276264036114, -8.209042316371027],
              [-39.986276154039444, -8.209042269882964],
              [-39.986276043438131, -8.209042224841307],
              [-39.986275932251367, -8.209042181253851],
              [-39.986275820498413, -8.209042139128206],
              [-39.986275708198598, -8.209042098471611],
              [-39.986275595371431, -8.209042059291134],
              [-39.986275482036412, -8.209042021593543],
              [-39.986275368213185, -8.209041985385403],
              [-39.986275253921484, -8.209041950672953],
              [-39.986275139181096, -8.209041917462223],
              [-39.986275024011903, -8.209041885758943],
              [-39.986274908433856, -8.209041855568662],
              [-39.986274792466972, -8.209041826896549],
              [-39.986274676131359, -8.209041799747622],
              [-39.986274559447153, -8.209041774126527],
              [-39.986274442434592, -8.209041750037763],
              [-39.986274325113904, -8.209041727485459],
              [-39.98627420750546, -8.209041706473538],
              [-39.986274089629617, -8.20904168700566],
              [-39.986273971506797, -8.209041669085147],
              [-39.986273853157456, -8.209041652715156],
              [-39.986273734602101, -8.209041637898496],
              [-39.986273615861279, -8.209041624637734],
              [-39.986273496955562, -8.209041612935165],
              [-39.986273377905533, -8.209041602792851],
              [-39.986273258731835, -8.209041594212501],
              [-39.986273139455108, -8.209041587195619],
              [-39.986273020095993, -8.209041581743433],
              [-39.986272900675218, -8.209041577856867],
              [-39.986272781213422, -8.209041575536622],
              [-39.986272661731334, -8.20904157478307],
              [-39.986272542249623, -8.209041575596377],
              [-39.986272422789014, -8.209041577976365],
              [-39.9862723033702, -8.20904158192265],
              [-39.986272184013863, -8.209041587434537],
              [-39.986272064740682, -8.209041594511056],
              [-39.98627194557131, -8.209041603150988],
              [-39.986271826526412, -8.209041613352836],
              [-39.986271707626614, -8.209041625114848],
              [-39.986271588892478, -8.209041638434972],
              [-39.986271470344612, -8.209041653310907],
              [-39.986271352003534, -8.20904166974009],
              [-39.986271233889752, -8.209041687719647],
              [-39.98627111602373, -8.209041707246481],
              [-39.986270998425887, -8.209041728317224],
              [-39.986270881116582, -8.209041750928192],
              [-39.986270764116163, -8.209041775075486],
              [-39.986270647444897, -8.209041800754903],
              [-39.986270531122962, -8.209041827962039],
              [-39.986270415170537, -8.209041856692137],
              [-39.986270299607718, -8.209041886940218],
              [-39.986270184454519, -8.20904191870107],
              [-39.986270069730871, -8.209041951969166],
              [-39.986269955456663, -8.209041986738782],
              [-39.986269841651698, -8.209042023003853],
              [-39.986269728335692, -8.209042060758099],
              [-39.986269615528258, -8.209042099995003],
              [-39.986269503248955, -8.209042140707762],
              [-39.986269391517233, -8.209042182889304],
              [-39.986269280352445, -8.20904222653232],
              [-39.986269169773848, -8.209042271629281],
              [-39.986269059800605, -8.20904231817236],
              [-39.986268950451766, -8.209042366153493],
              [-39.986268841746273, -8.209042415564358],
              [-39.986268733702957, -8.209042466396388],
              [-39.986268626340539, -8.209042518640812],
              [-39.986268519677616, -8.209042572288535],
              [-39.986268413732681, -8.209042627330295],
              [-39.98626830852406, -8.209042683756552],
              [-39.986268204069994, -8.20904274155752],
              [-39.986268100388592, -8.209042800723182],
              [-39.986267997497798, -8.209042861243308],
              [-39.986267895415438, -8.209042923107402],
              [-39.986267794159204, -8.209042986304743],
              [-39.986267693746633, -8.20904305082437],
              [-39.986267594195134, -8.209043116655124],
              [-39.986267495521943, -8.20904318378563],
              [-39.98626739774415, -8.209043252204182],
              [-39.986267300878687, -8.209043321899015],
              [-39.986267204942365, -8.209043392857973],
              [-39.986267209055811, -8.209043418961087],
              [-39.98574329368585, -8.209436315710619],
              [-39.985743259081481, -8.209436281952328],
              [-39.985743146558683, -8.20943636498256],
              [-39.9857430327638, -8.209436446273772],
              [-39.98574291772394, -8.209436525806641],
              [-39.985742801466465, -8.209436603562194],
              [-39.985742684018987, -8.209436679521968],
              [-39.98574256540951, -8.209436753667875],
              [-39.985742445666219, -8.209436825982275],
              [-39.985742324817608, -8.209436896447992],
              [-39.985742202892453, -8.209436965048216],
              [-39.985742079919731, -8.209437031766617],
              [-39.985741955928724, -8.209437096587397],
              [-39.985741830948918, -8.209437159495074],
              [-39.985741705010057, -8.209437220474713],
              [-39.98574157814209, -8.209437279511757],
              [-39.985741450375215, -8.209437336592211],
              [-39.985741321739845, -8.209437391702451],
              [-39.985741192266573, -8.209437444829399],
              [-39.985741061986189, -8.209437495960392],
              [-39.985740930929701, -8.209437545083279],
              [-39.985740799128294, -8.209437592186346],
              [-39.985740666613331, -8.209437637258423],
              [-39.985740533416333, -8.209437680288778],
              [-39.985740399568989, -8.209437721267172],
              [-39.985740265103153, -8.209437760183819],
              [-39.9857401300508, -8.209437797029516],
              [-39.985739994444089, -8.209437831795453],
              [-39.985739858315256, -8.209437864473383],
              [-39.98573972169671, -8.209437895055535],
              [-39.985739584620937, -8.209437923534612],
              [-39.985739447120579, -8.209437949903844],
              [-39.985739309228329, -8.209437974156923],
              [-39.985739170976998, -8.209437996288159],
              [-39.985739032399501, -8.209438016292244],
              [-39.985738893528769, -8.209438034164421],
              [-39.985738754397865, -8.209438049900408],
              [-39.985738615039885, -8.20943806349649],
              [-39.985738475487999, -8.209438074949436],
              [-39.985738335775409, -8.209438084256529],
              [-39.985738195935348, -8.20943809141554],
              [-39.985738056001075, -8.209438096424769],
              [-39.985737916005903, -8.20943809928302],
              [-39.985737775983139, -8.209438099989603],
              [-39.985737635966096, -8.209438098544339],
              [-39.985737495988069, -8.209438094947622],
              [-39.985737356082389, -8.20943808920029],
              [-39.985737216282331, -8.209438081303695],
              [-39.985737076621163, -8.20943807125971],
              [-39.985736937132103, -8.209438059070733],
              [-39.985736797848332, -8.209438044739677],
              [-39.985736658803027, -8.209438028269934],
              [-39.985736520029221, -8.209438009665433],
              [-39.985736381559974, -8.209437988930603],
              [-39.9857362434282, -8.209437966070391],
              [-39.985736105666781, -8.209437941090183],
              [-39.985735968308482, -8.209437913995972],
              [-39.985735831386009, -8.209437884794177],
              [-39.985735694931911, -8.209437853491774],
              [-39.985735558978661, -8.209437820096184],
              [-39.985735423558623, -8.209437784615339],
              [-39.985735288703992, -8.209437747057731],
              [-39.985735154446871, -8.20943770743227],
              [-39.985735020819185, -8.209437665748373],
              [-39.985734887852743, -8.209437622015979],
              [-39.985734755579173, -8.209437576245485],
              [-39.985734624029952, -8.209437528447761],
              [-39.985734493236386, -8.209437478634205],
              [-39.985734363229568, -8.209437426816658],
              [-39.985734234040457, -8.209437373007447],
              [-39.985734105699777, -8.20943731721939],
              [-39.985733978238059, -8.209437259465757],
              [-39.985733851685652, -8.209437199760281],
              [-39.985733726072652, -8.209437138117147],
              [-39.985733601428919, -8.209437074551053],
              [-39.985733477784159, -8.209437009077085],
              [-39.985733355167746, -8.209436941710859],
              [-39.985733233608862, -8.209436872468405],
              [-39.985733113136469, -8.209436801366182],
              [-39.98573299377918, -8.209436728421082],
              [-39.985732875565411, -8.209436653650524],
              [-39.985732758523305, -8.209436577072255],
              [-39.985732642680695, -8.209436498704475],
              [-39.985732528065114, -8.209436418565883],
              [-39.985732414703897, -8.209436336675507],
              [-39.985732302623973, -8.209436253052836],
              [-39.985732191851987, -8.209436167717776],
              [-39.985732082414344, -8.209436080690644],
              [-39.985731974337043, -8.209435991992091],
              [-39.985731867645832, -8.2094359016433],
              [-39.985731762366065, -8.209435809665697],
              [-39.985731658522816, -8.209435716081197],
              [-39.985731556140763, -8.209435620912069],
              [-39.985731455244292, -8.209435524180927],
              [-39.985731355857396, -8.209435425910854],
              [-39.985731258003717, -8.209435326125158],
              [-39.985731161706553, -8.209435224847606],
              [-39.985731066988805, -8.209435122102297],
              [-39.985730973873011, -8.209435017913675],
              [-39.985730882381318, -8.20943491230654],
              [-39.985730792535627, -8.209434805305797],
              [-39.985730762454153, -8.209434808867732],
              [-39.984527531486094, -8.207979102114621],
              [-39.984527497420594, -8.207979083976408],
              [-39.984527429192703, -8.207979002415648],
              [-39.984527360008514, -8.20797892165848],
              [-39.984527289877533, -8.207978841716011],
              [-39.984527218809433, -8.207978762599286],
              [-39.984527146814003, -8.207978684319212],
              [-39.984527073901177, -8.207978606886567],
              [-39.984527000080988, -8.207978530312005],
              [-39.984526925363625, -8.207978454606115],
              [-39.984526849759362, -8.207978379779281],
              [-39.984526773278638, -8.207978305841854],
              [-39.98452669593199, -8.207978232804006],
              [-39.984526617730076, -8.207978160675811],
              [-39.984526538683681, -8.20797808946722],
              [-39.984526458803678, -8.207978019188028],
              [-39.984526378101087, -8.207977949847908],
              [-39.984526296587035, -8.207977881456436],
              [-39.984526214272748, -8.207977814023058],
              [-39.984526131169581, -8.207977747557056],
              [-39.984526047288981, -8.207977682067549],
              [-39.984525962642486, -8.207977617563609],
              [-39.984525877241786, -8.2079775540541],
              [-39.984525791098662, -8.207977491547785],
              [-39.984525704224957, -8.207977430053282],
              [-39.984525616632666, -8.20797736957905],
              [-39.984525528333826, -8.207977310133449],
              [-39.984525439340651, -8.20797725172465],
              [-39.984525349665375, -8.2079771943607],
              [-39.984525259320378, -8.207977138049511],
              [-39.984525168318086, -8.207977082798839],
              [-39.984525076671048, -8.207977028616304],
              [-39.984524984391918, -8.207976975509387],
              [-39.984524891493379, -8.207976923485401],
              [-39.984524797988243, -8.207976872551493],
              [-39.984524703889413, -8.207976822714686],
              [-39.984524609209842, -8.207976773981896],
              [-39.98452451396259, -8.207976726359773],
              [-39.984524418160767, -8.207976679854918],
              [-39.984524321817588, -8.207976634473747],
              [-39.984524224946313, -8.207976590222469],
              [-39.984524127560313, -8.207976547107231],
              [-39.984524029673018, -8.20797650513394],
              [-39.984523931297879, -8.207976464308413],
              [-39.984523832448481, -8.207976424636236],
              [-39.984523733138452, -8.207976386122899],
              [-39.984523633381464, -8.207976348773743],
              [-39.984523533191265, -8.207976312593853],
              [-39.984523432581668, -8.207976277588253],
              [-39.984523331566528, -8.207976243761754],
              [-39.984523230159787, -8.207976211119027],
              [-39.984523128375407, -8.20797617966457],
              [-39.984523026227414, -8.207976149402693],
              [-39.984522923729898, -8.20797612033761],
              [-39.984522820896956, -8.207976092473292],
              [-39.984522717742784, -8.207976065813593],
              [-39.984522614281602, -8.207976040362194],
              [-39.984522510527647, -8.20797601612259],
              [-39.98452240649523, -8.207975993098136],
              [-39.984522302198698, -8.20797597129198],
              [-39.984522197652389, -8.207975950707132],
              [-39.984522092870762, -8.207975931346457],
              [-39.984521987868206, -8.207975913212588],
              [-39.984521882659237, -8.20797589630804],
              [-39.984521777258323, -8.207975880635159],
              [-39.984521671679992, -8.207975866196064],
              [-39.984521565938827, -8.207975852992805],
              [-39.984521460049351, -8.207975841027137],
              [-39.984521354026185, -8.207975830300759],
              [-39.984521247883947, -8.207975820815101],
              [-39.984521141637252, -8.207975812571522],
              [-39.984521035300745, -8.207975805571117],
              [-39.984520928889069, -8.207975799814877],
              [-39.984520822416918, -8.207975795303595],
              [-39.984520715898924, -8.207975792037873],
              [-39.984520609349829, -8.20797579001818],
              [-39.984520502784243, -8.207975789244784],
              [-39.984520396216901, -8.207975789717798],
              [-39.984520289662477, -8.207975791437159],
              [-39.984520183135643, -8.207975794402618],
              [-39.984520076651101, -8.207975798613766],
              [-39.984519970223516, -8.207975804070015],
              [-39.984519863867554, -8.207975810770645],
              [-39.984519757597859, -8.20797581871471],
              [-39.984519651429103, -8.207975827901111],
              [-39.98451954537591, -8.207975838328604],
              [-39.984519439452903, -8.20797584999575],
              [-39.984519333674633, -8.207975862900895],
              [-39.984519228055738, -8.207975877042324],
              [-39.984519122610749, -8.207975892418062],
              [-39.984519017354174, -8.207975909025983],
              [-39.984518912300565, -8.207975926863813],
              [-39.984518807464362, -8.207975945929055],
              [-39.984518702860036, -8.207975966219134],
              [-39.984518598501985, -8.207975987731226],
              [-39.984518494404597, -8.20797601046236],
              [-39.984518390582217, -8.207976034409397],
              [-39.984518287049177, -8.207976059569082],
              [-39.984518183819716, -8.207976085937892],
              [-39.984518080908046, -8.207976113512233],
              [-39.984517978328398, -8.207976142288297],
              [-39.984517992419974, -8.207976140841037],
              [-39.983584537073739, -8.208243868921386],
              [-39.983584540826982, -8.20824389395063],
              [-39.983584529769239, -8.208243897114931],
              [-39.983584518707467, -8.208243900265245],
              [-39.983584507641694, -8.208243903401598],
              [-39.983584496571957, -8.208243906523981],
              [-39.983584485498234, -8.208243909632362],
              [-39.983584474420582, -8.208243912726749],
              [-39.983584463338993, -8.208243915807184],
              [-39.983584452253496, -8.20824391887359],
              [-39.983584441164105, -8.208243921926041],
              [-39.983584430070849, -8.208243924964455],
              [-39.983584418973727, -8.20824392798888],
              [-39.983584407872776, -8.208243930999263],
              [-39.983584396767995, -8.208243933995659],
              [-39.98358438565942, -8.208243936977993],
              [-39.983584374547057, -8.208243939946325],
              [-39.983584363430928, -8.208243942900628],
              [-39.983584352311041, -8.208243945840879],
              [-39.983584341187438, -8.208243948767104],
              [-39.983584330060111, -8.208243951679236],
              [-39.98358431892909, -8.208243954577345],
              [-39.983584307794388, -8.208243957461432],
              [-39.983584296656041, -8.208243960331394],
              [-39.983584285514034, -8.208243963187348],
              [-39.983584274368418, -8.20824396602921],
              [-39.983584263219178, -8.208243968856985],
              [-39.983584252066365, -8.208243971670679],
              [-39.983584240909977, -8.208243974470269],
              [-39.983584229750036, -8.208243977255817],
              [-39.983584218586564, -8.208243980027222],
              [-39.983584207419561, -8.208243982784586],
              [-39.983584196249083, -8.208243985527808],
              [-39.983584185075124, -8.208243988256958],
              [-39.983584173897675, -8.208243990971997],
              [-39.983584162716802, -8.208243993672877],
              [-39.983584151532497, -8.208243996359665],
              [-39.983584140344782, -8.208243999032309],
              [-39.983584129153677, -8.208244001690845],
              [-39.983584117959211, -8.208244004335222],
              [-39.983584106761377, -8.20824400696551],
              [-39.983584095560204, -8.208244009581636],
              [-39.983584084355719, -8.208244012183602],
              [-39.983584073147931, -8.208244014771408],
              [-39.98358406193686, -8.208244017345089],
              [-39.98358405072252, -8.208244019904614],
              [-39.98358403950494, -8.208244022449941],
              [-39.983584028284127, -8.208244024981163],
              [-39.983584017060103, -8.208244027498168],
              [-39.983584005832888, -8.208244030001019],
              [-39.983583994602498, -8.208244032489691],
              [-39.983583983368952, -8.208244034964155],
              [-39.983583972132266, -8.208244037424441],
              [-39.98358396089246, -8.208244039870548],
              [-39.983583949649557, -8.208244042302447],
              [-39.983583938403548, -8.208244044720168],
              [-39.983583927154505, -8.20824404712366],
              [-39.983583915902393, -8.208244049512993],
              [-39.98358390464724, -8.208244051888085],
              [-39.983583893389095, -8.208244054248983],
              [-39.983583882127952, -8.208244056595632],
              [-39.983583870863832, -8.208244058928088],
              [-39.983583859596742, -8.208244061246301],
              [-39.983583848326731, -8.208244063550302],
              [-39.9835838370538, -8.208244065840059],
              [-39.983583825777956, -8.208244068115587],
              [-39.983583814499212, -8.208244070376873],
              [-39.983583803217627, -8.208244072623947],
              [-39.983583791933171, -8.208244074856742],
              [-39.983583780645901, -8.208244077075271],
              [-39.98358376935581, -8.208244079279577],
              [-39.983583758062913, -8.208244081469605],
              [-39.983583746767252, -8.208244083645383],
              [-39.983583735468827, -8.208244085806887],
              [-39.983583724167666, -8.20824408795413],
              [-39.983583712863783, -8.208244090087092],
              [-39.983583701557194, -8.20824409220579],
              [-39.983583690247919, -8.208244094310247],
              [-39.983583678935986, -8.208244096400383],
              [-39.983583667621382, -8.208244098476232],
              [-39.983583656304162, -8.208244100537797],
              [-39.983583644984321, -8.208244102585072],
              [-39.983583633661894, -8.208244104618062],
              [-39.983583622336887, -8.208244106636741],
              [-39.983583611009315, -8.208244108641139],
              [-39.9835835996792, -8.20824411063121],
              [-39.983583588346583, -8.208244112607002],
              [-39.983583577011437, -8.20824411456846],
              [-39.983583565673818, -8.20824411651561],
              [-39.983583554333734, -8.208244118448425],
              [-39.983583542991184, -8.208244120366933],
              [-39.983583531646225, -8.208244122271122],
              [-39.983583520298836, -8.208244124160982],
              [-39.98358350894906, -8.20824412603648],
              [-39.983583497596904, -8.208244127897679],
              [-39.983583486242381, -8.20824412974455],
              [-39.983583474885535, -8.208244131577073],
              [-39.983583463526358, -8.208244133395249],
              [-39.983583452164879, -8.208244135199111],
              [-39.983583440801112, -8.208244136988593],
              [-39.983583429435058, -8.208244138763995],
              [-39.983583448401156, -8.208244142906258],
              [-39.981851775864179, -8.208513453822668],
              [-39.981851783766935, -8.208513462841486],
              [-39.981851605513128, -8.208513492378581],
              [-39.981851427884777, -8.208513525444777],
              [-39.981851250952211, -8.208513562026946],
              [-39.981851074785602, -8.208513602110623],
              [-39.981850899454727, -8.208513645679897],
              [-39.981850725029041, -8.208513692717545],
              [-39.981850551577679, -8.208513743204872],
              [-39.981850379169366, -8.208513797121942],
              [-39.981850207872419, -8.208513854447371],
              [-39.981850037754718, -8.208513915158369],
              [-39.981849868883664, -8.208513979230977],
              [-39.981849701326162, -8.208514046639751],
              [-39.981849535148605, -8.20851411735803],
              [-39.981849370416874, -8.208514191357724],
              [-39.981849207196191, -8.208514268609562],
              [-39.981849045551272, -8.208514349082895],
              [-39.981848885546142, -8.208514432745881],
              [-39.98184872724422, -8.208514519565359],
              [-39.981848570708223, -8.208514609506901],
              [-39.981848416000176, -8.208514702534911],
              [-39.981848263181384, -8.208514798612454],
              [-39.981848112312392, -8.20851489770153],
              [-39.981847963452992, -8.208514999762842],
              [-39.981847816662153, -8.208515104755994],
              [-39.981847671998061, -8.208515212639323],
              [-39.981847529518028, -8.208515323370108],
              [-39.981847389278499, -8.208515436904451],
              [-39.981847251335061, -8.208515553197421],
              [-39.981847115742362, -8.208515672202896],
              [-39.981846982554138, -8.208515793873694],
              [-39.981846851823157, -8.208515918161691],
              [-39.981846723601215, -8.208516045017573],
              [-39.981846597939111, -8.208516174391063],
              [-39.981846474886673, -8.208516306230942],
              [-39.981846354492632, -8.208516440484956],
              [-39.981846236804707, -8.208516577099909],
              [-39.981846121869509, -8.208516716021627],
              [-39.981846009732593, -8.208516857195125],
              [-39.981845900438387, -8.208517000564406],
              [-39.981845794030221, -8.208517146072728],
              [-39.981845690550223, -8.208517293662387],
              [-39.981845590039441, -8.208517443274934],
              [-39.981845492537666, -8.208517594851054],
              [-39.981845398083529, -8.208517748330681],
              [-39.981845306714469, -8.208517903653025],
              [-39.981845218466688, -8.208518060756532],
              [-39.981845133375167, -8.208518219578965],
              [-39.981845051473613, -8.208518380057376],
              [-39.981844972794455, -8.208518542128179],
              [-39.981844897368909, -8.208518705727139],
              [-39.981844825226858, -8.208518870789476],
              [-39.981844756396839, -8.20851903724974],
              [-39.981844690906186, -8.208519205042002],
              [-39.981844628780806, -8.208519374099764],
              [-39.98184457004534, -8.208519544356028],
              [-39.981844514723051, -8.208519715743348],
              [-39.981844462835866, -8.2085198881938],
              [-39.98184441440435, -8.208520061639081],
              [-39.981844369447671, -8.208520236010431],
              [-39.981844327983659, -8.208520411238768],
              [-39.981844290028747, -8.208520587254663],
              [-39.981844255597956, -8.208520763988368],
              [-39.981844224704965, -8.208520941369839],
              [-39.981844197361987, -8.20852111932882],
              [-39.981844173579852, -8.208521297794775],
              [-39.981844153367987, -8.208521476696983],
              [-39.981844136734416, -8.208521655964571],
              [-39.981844123685711, -8.208521835526513],
              [-39.981844114227059, -8.208522015311644],
              [-39.981844108362203, -8.208522195248753],
              [-39.981844106093462, -8.208522375266503],
              [-39.98184410742175, -8.208522555293589],
              [-39.981844112346501, -8.208522735258658],
              [-39.981844120865809, -8.208522915090427],
              [-39.981844132976292, -8.2085230947176],
              [-39.981844148673119, -8.208523274069019],
              [-39.981844167950108, -8.208523453073607],
              [-39.981844190799592, -8.208523631660483],
              [-39.981844217212533, -8.208523809758825],
              [-39.981844247178458, -8.208523987298095],
              [-39.981844280685507, -8.208524164207937],
              [-39.981844317720359, -8.208524340418254],
              [-39.981844358268404, -8.208524515859235],
              [-39.981844402313527, -8.20852469046133],
              [-39.981844449838313, -8.208524864155377],
              [-39.981844500823897, -8.208525036872556],
              [-39.981844555250078, -8.208525208544437],
              [-39.981844613095319, -8.20852537910298],
              [-39.981844674336685, -8.208525548480585],
              [-39.981844738949896, -8.208525716610184],
              [-39.981844806909351, -8.208525883425107],
              [-39.981844878188149, -8.208526048859287],
              [-39.981844952758024, -8.208526212847158],
              [-39.981845030589419, -8.208526375323746],
              [-39.981845111651516, -8.20852653622466],
              [-39.9818451959122, -8.208526695486182],
              [-39.981845283338053, -8.208526853045154],
              [-39.981845373894451, -8.208527008839209],
              [-39.981845467545511, -8.208527162806543],
              [-39.981845454912232, -8.208527125997675],
              [-39.982916159050951, -8.210248522358077],
              [-39.982916171046867, -8.210248555312518],
              [-39.982916190845003, -8.210248586996022],
              [-39.982916210774206, -8.210248618597843],
              [-39.982916230834142, -8.210248650117471],
              [-39.982916251024449, -8.210248681554354],
              [-39.982916271344806, -8.210248712907969],
              [-39.982916291794844, -8.210248744177763],
              [-39.982916312374229, -8.210248775363246],
              [-39.98291633308262, -8.210248806463845],
              [-39.982916353919634, -8.210248837479028],
              [-39.982916374884944, -8.210248868408298],
              [-39.982916395978187, -8.210248899251125],
              [-39.982916417198993, -8.21024893000693],
              [-39.982916438547015, -8.210248960675248],
              [-39.982916460021862, -8.21024899125554],
              [-39.982916481623221, -8.210249021747298],
              [-39.982916503350673, -8.210249052149956],
              [-39.982916525203862, -8.210249082463035],
              [-39.982916547182434, -8.210249112686022],
              [-39.982916569285983, -8.210249142818411],
              [-39.982916591514169, -8.210249172859637],
              [-39.9829166138666, -8.210249202809226],
              [-39.982916636342871, -8.210249232666683],
              [-39.982916658942649, -8.210249262431454],
              [-39.982916681665493, -8.21024929210307],
              [-39.982916704511048, -8.210249321681008],
              [-39.982916727478923, -8.210249351164764],
              [-39.982916750568712, -8.21024938055387],
              [-39.98291677378004, -8.210249409847782],
              [-39.982916797112509, -8.210249439046015],
              [-39.982916820565706, -8.210249468148087],
              [-39.982916844139254, -8.210249497153468],
              [-39.982916867832742, -8.210249526061693],
              [-39.982916891645765, -8.21024955487229],
              [-39.98291691557791, -8.210249583584716],
              [-39.98291693962878, -8.21024961219851],
              [-39.982916963797969, -8.210249640713174],
              [-39.982916988085051, -8.210249669128228],
              [-39.982917012489629, -8.210249697443205],
              [-39.982917037011276, -8.210249725657601],
              [-39.98291706164958, -8.210249753770947],
              [-39.982917086404093, -8.210249781782744],
              [-39.982917111274446, -8.210249809692543],
              [-39.982917136260184, -8.210249837499854],
              [-39.982917161360881, -8.210249865204204],
              [-39.982917186576124, -8.210249892805132],
              [-39.982917211905466, -8.210249920302161],
              [-39.982917237348495, -8.210249947694798],
              [-39.982917262904756, -8.210249974982617],
              [-39.98291728857383, -8.210250002165123],
              [-39.982917314355277, -8.210250029241882],
              [-39.98291734024864, -8.21025005621239],
              [-39.98291736625351, -8.210250083076241],
              [-39.982917392369416, -8.210250109832947],
              [-39.982917418595939, -8.210250136482042],
              [-39.982917444932596, -8.210250163023105],
              [-39.98291747137899, -8.210250189455664],
              [-39.982917497934622, -8.210250215779247],
              [-39.982917524599053, -8.210250241993455],
              [-39.98291755137187, -8.210250268097795],
              [-39.982917578252547, -8.210250294091868],
              [-39.982917605240672, -8.210250319975181],
              [-39.982917632335777, -8.210250345747342],
              [-39.982917659537399, -8.210250371407868],
              [-39.98291768684507, -8.210250396956349],
              [-39.982917714258328, -8.210250422392358],
              [-39.982917741776724, -8.210250447715428],
              [-39.982917769399762, -8.210250472925152],
              [-39.98291779712698, -8.210250498021098],
              [-39.982917824957916, -8.210250523002829],
              [-39.982917852892072, -8.210250547869938],
              [-39.982917880929008, -8.21025057262197],
              [-39.982917909068227, -8.210250597258545],
              [-39.982917937309232, -8.21025062177924],
              [-39.982917965651581, -8.210250646183582],
              [-39.982917994094755, -8.210250670471206],
              [-39.982918022638287, -8.210250694641674],
              [-39.982918051281708, -8.210250718694619],
              [-39.982918080024483, -8.210250742629579],
              [-39.982918108866166, -8.210250766446157],
              [-39.982918137806251, -8.210250790143968],
              [-39.982918166844236, -8.210250813722585],
              [-39.98291819597965, -8.210250837181629],
              [-39.982918225211982, -8.210250860520675],
              [-39.982918254540735, -8.210250883739363],
              [-39.98291828396539, -8.210250906837276],
              [-39.982918313485484, -8.210250929814022],
              [-39.982918343100494, -8.21025095266921],
              [-39.982918372809912, -8.210250975402419],
              [-39.982918402613244, -8.210250998013318],
              [-39.982918432509976, -8.210251020501499],
              [-39.98291846249959, -8.210251042866561],
              [-39.982918492581575, -8.210251065108153],
              [-39.982918522755455, -8.21025108722587],
              [-39.982918553020674, -8.210251109219364],
              [-39.98291858337673, -8.210251131088217],
              [-39.982918613823109, -8.210251152832097],
              [-39.982918644359295, -8.210251174450603],
              [-39.982918674984759, -8.210251195943407],
              [-39.982918705698985, -8.210251217310104],
              [-39.982918707529969, -8.21025122940128],
              [-39.984261627301905, -8.21118131159829],
              [-39.984261621359671, -8.211181327017814],
              [-39.984261646919741, -8.211181344785665],
              [-39.984261672418455, -8.211181362640891],
              [-39.984261697855459, -8.211181380583209],
              [-39.984261723230503, -8.211181398612405],
              [-39.984261748543254, -8.211181416728325],
              [-39.984261773793421, -8.211181434930682],
              [-39.984261798980725, -8.211181453219329],
              [-39.984261824104848, -8.211181471593976],
              [-39.984261849165513, -8.211181490054509],
              [-39.984261874162407, -8.211181508600639],
              [-39.984261899095266, -8.211181527232153],
              [-39.984261923963729, -8.211181545948815],
              [-39.984261948767575, -8.211181564750449],
              [-39.984261973506491, -8.21118158363681],
              [-39.984261998180152, -8.211181602607699],
              [-39.984262022788315, -8.211181621662844],
              [-39.98426204733066, -8.211181640802058],
              [-39.984262071806903, -8.211181660025124],
              [-39.984262096216746, -8.211181679331792],
              [-39.984262120559926, -8.211181698721827],
              [-39.984262144836144, -8.211181718195043],
              [-39.98426216904511, -8.211181737751151],
              [-39.984262193186552, -8.211181757389975],
              [-39.984262217260159, -8.211181777111239],
              [-39.984262241265668, -8.211181796914746],
              [-39.984262265202801, -8.211181816800236],
              [-39.984262289071268, -8.211181836767484],
              [-39.984262312870761, -8.211181856816264],
              [-39.984262336601056, -8.211181876946327],
              [-39.984262360261809, -8.211181897157457],
              [-39.984262383852787, -8.211181917449382],
              [-39.984262407373713, -8.211181937821886],
              [-39.98426243082428, -8.211181958274699],
              [-39.984262454204242, -8.211181978807618],
              [-39.98426247751329, -8.211181999420392],
              [-39.984262500751186, -8.211182020112771],
              [-39.984262523917629, -8.211182040884518],
              [-39.984262547012342, -8.211182061735359],
              [-39.984262570035092, -8.211182082665101],
              [-39.984262592985559, -8.211182103673455],
              [-39.984262615863493, -8.211182124760208],
              [-39.984262638668639, -8.21118214592506],
              [-39.98426266140072, -8.211182167167776],
              [-39.984262684059445, -8.211182188488159],
              [-39.984262706644579, -8.211182209885912],
              [-39.984262729155837, -8.21118223136078],
              [-39.984262751592951, -8.211182252912513],
              [-39.984262773955678, -8.211182274540892],
              [-39.98426279624374, -8.211182296245616],
              [-39.98426281845687, -8.211182318026482],
              [-39.984262840594802, -8.211182339883173],
              [-39.984262862657289, -8.211182361815446],
              [-39.984262884644082, -8.211182383823092],
              [-39.984262906554882, -8.211182405905785],
              [-39.984262928389477, -8.211182428063308],
              [-39.98426295014756, -8.211182450295381],
              [-39.984262971828926, -8.211182472601747],
              [-39.984262993433283, -8.211182494982156],
              [-39.984263014960391, -8.211182517436281],
              [-39.984263036409992, -8.211182539963964],
              [-39.984263057781838, -8.211182562564865],
              [-39.984263079075674, -8.211182585238715],
              [-39.984263100291244, -8.211182607985279],
              [-39.984263121428313, -8.21118263080427],
              [-39.984263142486597, -8.21118265369542],
              [-39.984263163465897, -8.211182676658474],
              [-39.984263184365936, -8.211182699693154],
              [-39.98426320518648, -8.211182722799185],
              [-39.984263225927258, -8.211182745976281],
              [-39.984263246588057, -8.211182769224211],
              [-39.984263267168622, -8.211182792542649],
              [-39.984263287668696, -8.21118281593138],
              [-39.98426330808806, -8.211182839390071],
              [-39.984263328426465, -8.211182862918465],
              [-39.984263348683662, -8.211182886516278],
              [-39.984263368859423, -8.211182910183242],
              [-39.984263388953508, -8.211182933919106],
              [-39.984263408965688, -8.211182957723548],
              [-39.984263428895702, -8.211182981596311],
              [-39.984263448743356, -8.211183005537084],
              [-39.98426346850836, -8.211183029545612],
              [-39.984263488190521, -8.21118305362161],
              [-39.984263507789613, -8.211183077764792],
              [-39.98426352730538, -8.21118310197487],
              [-39.984263546737601, -8.211183126251543],
              [-39.984263566086064, -8.211183150594575],
              [-39.984263585350512, -8.211183175003613],
              [-39.984263604530732, -8.21118319947842],
              [-39.984263623626489, -8.211183224018663],
              [-39.984263642637579, -8.21118324862411],
              [-39.984263661563759, -8.211183273294427],
              [-39.984263680404823, -8.211183298029312],
              [-39.984263699160529, -8.211183322828498],
              [-39.984263717830672, -8.21118334769173],
              [-39.984263736415009, -8.211183372618677],
              [-39.984263754913343, -8.211183397609025],
              [-39.984263773325459, -8.21118342266251],
              [-39.984263791651131, -8.211183447778811],
              [-39.984263809890379, -8.211183472956066],
              [-39.98426381134334, -8.211183476869477],
              [-39.984921394069538, -8.212094519768879],
              [-39.984921375757182, -8.212094526703256],
              [-39.984921506339873, -8.212094712495707],
              [-39.984921632205079, -8.212094901492081],
              [-39.984921753273689, -8.21209509357354],
              [-39.984921869469552, -8.212095288619246],
              [-39.984921980719562, -8.212095486506584],
              [-39.98492208695378, -8.212095687111017],
              [-39.984922188105379, -8.212095890306468],
              [-39.984922284110745, -8.212096095965054],
              [-39.984922374909516, -8.212096303957514],
              [-39.984922460444544, -8.212096514153011],
              [-39.984922540662083, -8.212096726419301],
              [-39.984922615511657, -8.212096940622947],
              [-39.984922684946191, -8.212097156629186],
              [-39.984922748922017, -8.212097374302202],
              [-39.984922807398902, -8.212097593505076],
              [-39.98492286034007, -8.212097814099968],
              [-39.984922907712232, -8.212098035948088],
              [-39.984922949485565, -8.212098258909972],
              [-39.984922985633844, -8.212098482845374],
              [-39.984923016134296, -8.212098707613446],
              [-39.984923040967743, -8.212098933072811],
              [-39.984923060118582, -8.212099159081722],
              [-39.984923073574755, -8.212099385498],
              [-39.984923081327821, -8.212099612179257],
              [-39.984923083372877, -8.212099838982921],
              [-39.984923079708651, -8.21210006576635],
              [-39.984923070337437, -8.212100292386928],
              [-39.984923055265156, -8.212100518702135],
              [-39.984923034501243, -8.212100744569613],
              [-39.9849230080588, -8.212100969847311],
              [-39.98492297595444, -8.212101194393554],
              [-39.984922938208335, -8.212101418067123],
              [-39.984922894844274, -8.212101640727349],
              [-39.984922845889464, -8.21210186223419],
              [-39.984922791374743, -8.212102082448341],
              [-39.984922731334386, -8.212102301231303],
              [-39.984922665806131, -8.212102518445443],
              [-39.984922594831218, -8.212102733954204],
              [-39.984922518454283, -8.212102947622016],
              [-39.984922436723338, -8.212103159314482],
              [-39.984922349689818, -8.212103368898534],
              [-39.984922257408428, -8.212103576242306],
              [-39.984922159937213, -8.212103781215376],
              [-39.984922057337506, -8.212103983688891],
              [-39.984921949673797, -8.21210418353547],
              [-39.9849218370138, -8.212104380629425],
              [-39.984921719428399, -8.212104574846816],
              [-39.984921596991526, -8.21210476606548],
              [-39.984921469780183, -8.212104954165152],
              [-39.984921337874376, -8.212105139027573],
              [-39.984921201357068, -8.212105320536438],
              [-39.984921060314115, -8.2121054985776],
              [-39.984920914834234, -8.212105673039089],
              [-39.984920765008894, -8.21210584381117],
              [-39.984920610932349, -8.212106010786457],
              [-39.984920452701488, -8.212106173859926],
              [-39.984920290415836, -8.21210633292903],
              [-39.984920124177457, -8.212106487893726],
              [-39.984919954090877, -8.212106638656545],
              [-39.984919780263098, -8.212106785122664],
              [-39.98491960280343, -8.212106927199983],
              [-39.984919421823484, -8.212107064799111],
              [-39.984919237437083, -8.212107197833554],
              [-39.984919049760194, -8.212107326219606],
              [-39.984918858910845, -8.212107449876553],
              [-39.984918665009069, -8.212107568726598],
              [-39.984918468176822, -8.212107682695025],
              [-39.984918268537882, -8.212107791710128],
              [-39.984918066217823, -8.212107895703356],
              [-39.984917861343874, -8.212107994609315],
              [-39.984917654044878, -8.212108088365804],
              [-39.984917444451227, -8.212108176913809],
              [-39.984917232694741, -8.2121082601977],
              [-39.984917018908597, -8.21210833816507],
              [-39.984916803227229, -8.212108410766882],
              [-39.984916585786294, -8.212108477957468],
              [-39.984916366722572, -8.21210853969459],
              [-39.984916146173788, -8.21210859593943],
              [-39.98491592427871, -8.212108646656571],
              [-39.984915701176838, -8.212108691814162],
              [-39.984915477008514, -8.212108731383788],
              [-39.984915251914707, -8.21210876534052],
              [-39.984915026037008, -8.212108793663068],
              [-39.984914799517448, -8.212108816333586],
              [-39.984914572498518, -8.212108833337821],
              [-39.984914345122974, -8.212108844665075],
              [-39.984914117533812, -8.21210885030824],
              [-39.984913889874186, -8.212108850263739],
              [-39.984913662287276, -8.212108844531617],
              [-39.984913434916209, -8.212108833115456],
              [-39.984913207903993, -8.212108816022486],
              [-39.984912981393371, -8.212108793263406],
              [-39.98491275552685, -8.212108764852557],
              [-39.984912530446437, -8.212108730807802],
              [-39.984912306293701, -8.212108691150549],
              [-39.984912083209643, -8.212108645905749],
              [-39.984911861334538, -8.212108595101853],
              [-39.984911640807937, -8.212108538770812],
              [-39.984911421768103, -8.212108476948149],
              [-39.984911442687689, -8.212108472191707],
              [-39.983594540613041, -8.211718901628284],
              [-39.98359450404601, -8.211718944178584],
              [-39.983594465037385, -8.211718932734739],
              [-39.983594425977699, -8.211718921465071],
              [-39.98359438686775, -8.21171891036987],
              [-39.983594347708305, -8.211718899449309],
              [-39.983594308500138, -8.211718888703617],
              [-39.983594269244058, -8.211718878133018],
              [-39.983594229940842, -8.211718867737721],
              [-39.983594190591276, -8.211718857517937],
              [-39.983594151196172, -8.211718847473884],
              [-39.983594111756275, -8.211718837605712],
              [-39.983594072272425, -8.211718827913655],
              [-39.983594032745387, -8.211718818397918],
              [-39.983593993175958, -8.211718809058674],
              [-39.983593953564942, -8.211718799896117],
              [-39.983593913913126, -8.211718790910425],
              [-39.983593874221292, -8.211718782101782],
              [-39.983593834490271, -8.211718773470366],
              [-39.983593794720832, -8.211718765016336],
              [-39.983593754913777, -8.211718756739888],
              [-39.983593715069922, -8.211718748641156],
              [-39.983593675190036, -8.211718740720336],
              [-39.983593635274936, -8.211718732977586],
              [-39.983593595325424, -8.211718725413046],
              [-39.983593555342317, -8.211718718026876],
              [-39.98359351532639, -8.211718710819197],
              [-39.983593475278454, -8.211718703790185],
              [-39.983593435199325, -8.21171869693997],
              [-39.983593395089798, -8.211718690268677],
              [-39.983593354950671, -8.211718683776473],
              [-39.983593314782766, -8.211718677463455],
              [-39.983593274586866, -8.211718671329756],
              [-39.983593234363802, -8.211718665375528],
              [-39.98359319411437, -8.211718659600844],
              [-39.983593153839372, -8.211718654005853],
              [-39.983593113539619, -8.211718648590688],
              [-39.983593073215928, -8.211718643355409],
              [-39.983593032869109, -8.211718638300141],
              [-39.98359299249995, -8.21171863342496],
              [-39.983592952109284, -8.211718628730003],
              [-39.983592911697905, -8.211718624215363],
              [-39.983592871266652, -8.211718619881111],
              [-39.983592830816299, -8.211718615727341],
              [-39.9835927903477, -8.211718611754142],
              [-39.983592749861607, -8.21171860796159],
              [-39.983592709358895, -8.211718604349754],
              [-39.983592668840338, -8.211718600918717],
              [-39.983592628306766, -8.21171859766854],
              [-39.98359258775897, -8.211718594599292],
              [-39.983592547197823, -8.211718591711005],
              [-39.983592506624056, -8.211718589003773],
              [-39.983592466038552, -8.211718586477668],
              [-39.983592425442083, -8.211718584132685],
              [-39.983592384835468, -8.211718581968903],
              [-39.983592344219545, -8.21171857998638],
              [-39.983592303595103, -8.211718578185138],
              [-39.983592262962986, -8.211718576565215],
              [-39.983592222323985, -8.211718575126651],
              [-39.983592181678922, -8.211718573869453],
              [-39.983592141028616, -8.211718572793668],
              [-39.983592100373883, -8.211718571899308],
              [-39.983592059715534, -8.21171857118639],
              [-39.983592019054406, -8.211718570654927],
              [-39.983591978391267, -8.211718570304935],
              [-39.98359193772697, -8.211718570136396],
              [-39.98359189706234, -8.211718570149362],
              [-39.983591856398156, -8.211718570343802],
              [-39.983591815735267, -8.211718570719707],
              [-39.983591775074458, -8.211718571277093],
              [-39.983591734416592, -8.211718572015915],
              [-39.983591693762435, -8.211718572936215],
              [-39.983591653112825, -8.211718574037919],
              [-39.983591612468594, -8.211718575321026],
              [-39.983591571830516, -8.211718576785513],
              [-39.983591531199444, -8.211718578431354],
              [-39.983591490576174, -8.211718580258502],
              [-39.983591449961523, -8.211718582266927],
              [-39.983591409356322, -8.211718584456595],
              [-39.983591368761374, -8.211718586827434],
              [-39.983591328177475, -8.211718589379423],
              [-39.983591287605478, -8.211718592112517],
              [-39.983591247046171, -8.211718595026637],
              [-39.983591206500371, -8.211718598121754],
              [-39.983591165968896, -8.211718601397781],
              [-39.983591125452541, -8.21171860485466],
              [-39.983591084952138, -8.211718608492314],
              [-39.983591044468525, -8.211718612310683],
              [-39.983591004002456, -8.211718616309669],
              [-39.983590963554789, -8.211718620489231],
              [-39.983590923126322, -8.211718624849254],
              [-39.983590882717863, -8.211718629389647],
              [-39.983590842330216, -8.211718634110344],
              [-39.983590801964205, -8.211718639011242],
              [-39.983590761620633, -8.211718644092239],
              [-39.983590721300317, -8.211718649353227],
              [-39.98359068100406, -8.211718654794108],
              [-39.983590640732665, -8.211718660414759],
              [-39.983590600486941, -8.211718666215097],
              [-39.983590560267707, -8.211718672194964],
              [-39.983590520075765, -8.211718678354313],
              [-39.983590545663503, -8.211718640061527],
              [-39.981855500859034, -8.211988503579308],
              [-39.981855511614839, -8.211988524904134],
              [-39.981855372008226, -8.211988547728476],
              [-39.981855232775587, -8.211988572717587],
              [-39.98185509395072, -8.211988599865357],
              [-39.981854955567364, -8.211988629165246],
              [-39.981854817659112, -8.21198866061007],
              [-39.981854680259495, -8.211988694192234],
              [-39.981854543401838, -8.211988729903581],
              [-39.981854407119407, -8.211988767735425],
              [-39.981854271445286, -8.211988807678578],
              [-39.981854136412437, -8.211988849723344],
              [-39.981854002053659, -8.211988893859516],
              [-39.981853868401558, -8.211988940076376],
              [-39.98185373548862, -8.211988988362689],
              [-39.981853603347126, -8.211989038706728],
              [-39.981853472009163, -8.211989091096278],
              [-39.981853341506628, -8.211989145518601],
              [-39.981853211871211, -8.211989201960471],
              [-39.981853083134411, -8.211989260408208],
              [-39.981852955327476, -8.211989320847612],
              [-39.981852828481465, -8.211989383263971],
              [-39.981852702627172, -8.211989447642166],
              [-39.981852577795159, -8.21198951396654],
              [-39.981852454015787, -8.211989582220978],
              [-39.981852331319047, -8.211989652388938],
              [-39.981852209734804, -8.21198972445336],
              [-39.981852089292545, -8.211989798396724],
              [-39.981851970021545, -8.211989874201068],
              [-39.981851851950758, -8.211989951848023],
              [-39.981851735108876, -8.211990031318688],
              [-39.981851619524242, -8.211990112593787],
              [-39.981851505224974, -8.211990195653563],
              [-39.981851392238809, -8.21199028047786],
              [-39.981851280593197, -8.211990367046067],
              [-39.981851170315231, -8.211990455337137],
              [-39.981851061431712, -8.211990545329668],
              [-39.981850953969087, -8.211990637001792],
              [-39.981850847953453, -8.211990730331241],
              [-39.981850743410554, -8.211990825295352],
              [-39.981850640365792, -8.211990921871042],
              [-39.981850538844178, -8.211991020034873],
              [-39.981850438870367, -8.211991119762999],
              [-39.981850340468661, -8.211991221031203],
              [-39.981850243662926, -8.211991323814878],
              [-39.981850148476724, -8.211991428089069],
              [-39.981850054933119, -8.211991533828456],
              [-39.981849963054863, -8.211991641007339],
              [-39.981849872864252, -8.211991749599704],
              [-39.981849784383208, -8.211991859579168],
              [-39.981849697633201, -8.211991970919044],
              [-39.981849612635344, -8.211992083592246],
              [-39.981849529410198, -8.211992197571446],
              [-39.981849447978064, -8.211992312828951],
              [-39.981849368358667, -8.211992429336766],
              [-39.981849290571375, -8.211992547066608],
              [-39.981849214635055, -8.211992665989866],
              [-39.981849140568144, -8.211992786077648],
              [-39.981849068388669, -8.2119929073008],
              [-39.98184899811411, -8.211993029629888],
              [-39.981848929761576, -8.211993153035197],
              [-39.981848863347665, -8.211993277486757],
              [-39.981848798888493, -8.211993402954343],
              [-39.981848736399705, -8.211993529407501],
              [-39.981848675896501, -8.211993656815471],
              [-39.98184861739356, -8.211993785147325],
              [-39.981848560905085, -8.211993914371922],
              [-39.98184850644482, -8.211994044457866],
              [-39.981848454025986, -8.21199417537356],
              [-39.981848403661303, -8.211994307087211],
              [-39.981848355363006, -8.211994439566814],
              [-39.981848309142812, -8.211994572780188],
              [-39.981848265011969, -8.211994706695011],
              [-39.981848222981192, -8.211994841278738],
              [-39.981848183060698, -8.211994976498719],
              [-39.981848145260138, -8.211995112322041],
              [-39.981848109588732, -8.211995248715789],
              [-39.981848076055137, -8.21199538564678],
              [-39.981848044667508, -8.21199552308178],
              [-39.981848015433442, -8.21199566098743],
              [-39.981847988360052, -8.211995799330207],
              [-39.981847963453909, -8.211995938076514],
              [-39.981847940721067, -8.211996077192705],
              [-39.981847920167056, -8.211996216644902],
              [-39.981847901796854, -8.211996356399307],
              [-39.981847885614926, -8.21199649642195],
              [-39.98184787162522, -8.21199663667884],
              [-39.981847859831099, -8.2119967771359],
              [-39.98184785023544, -8.211996917759009],
              [-39.981847842840608, -8.211997058514045],
              [-39.981847837648345, -8.211997199366776],
              [-39.981847834659938, -8.211997340283011],
              [-39.981847833876117, -8.21199748122854],
              [-39.981847835297074, -8.2119976221691],
              [-39.981847838922448, -8.211997763070505],
              [-39.981847844751371, -8.211997903898526],
              [-39.98184785278243, -8.211998044618921],
              [-39.98184786301367, -8.211998185197569],
              [-39.98184787544259, -8.211998325600252],
              [-39.981847890066177, -8.211998465792936],
              [-39.981847906880915, -8.211998605741568],
              [-39.981847900068487, -8.211998561193747],
              [-39.981983585955952, -8.213057669492295],
              [-39.981983564966342, -8.213057683761299],
              [-39.981983567233513, -8.213057701286196],
              [-39.981983569534854, -8.213057718806686],
              [-39.981983571870366, -8.213057736322675],
              [-39.981983574239997, -8.213057753834116],
              [-39.981983576643778, -8.213057771340926],
              [-39.981983579081692, -8.213057788843059],
              [-39.981983581553706, -8.213057806340455],
              [-39.981983584059847, -8.213057823833019],
              [-39.981983586600073, -8.213057841320694],
              [-39.981983589174398, -8.213057858803422],
              [-39.9819835917828, -8.213057876281145],
              [-39.981983594425266, -8.213057893753801],
              [-39.981983597101802, -8.213057911221304],
              [-39.981983599812388, -8.213057928683584],
              [-39.981983602557008, -8.213057946140589],
              [-39.981983605335664, -8.213057963592242],
              [-39.981983608148333, -8.213057981038506],
              [-39.981983610995016, -8.213057998479281],
              [-39.981983613875691, -8.213058015914509],
              [-39.981983616790359, -8.213058033344151],
              [-39.981983619738998, -8.213058050768112],
              [-39.981983622721614, -8.213058068186333],
              [-39.981983625738167, -8.213058085598744],
              [-39.981983628788662, -8.213058103005311],
              [-39.981983631873085, -8.213058120405897],
              [-39.981983634991451, -8.213058137800504],
              [-39.981983638143703, -8.213058155189035],
              [-39.981983641329855, -8.213058172571429],
              [-39.981983644549899, -8.213058189947631],
              [-39.981983647803801, -8.213058207317586],
              [-39.981983651091554, -8.213058224681184],
              [-39.981983654413185, -8.21305824203839],
              [-39.981983657768623, -8.213058259389157],
              [-39.981983661157891, -8.213058276733356],
              [-39.981983664580959, -8.21305829407101],
              [-39.981983668037834, -8.213058311401991],
              [-39.981983671528496, -8.21305832872625],
              [-39.981983675052909, -8.213058346043724],
              [-39.981983678611087, -8.213058363354357],
              [-39.981983682203001, -8.213058380658053],
              [-39.981983685828652, -8.21305839795478],
              [-39.98198368948804, -8.213058415244445],
              [-39.9819836931811, -8.213058432526992],
              [-39.981983696907861, -8.21305844980238],
              [-39.981983700668287, -8.213058467070507],
              [-39.981983704462372, -8.213058484331327],
              [-39.981983708290102, -8.213058501584767],
              [-39.981983712151468, -8.213058518830767],
              [-39.981983716046443, -8.213058536069289],
              [-39.981983719975034, -8.213058553300215],
              [-39.981983723937198, -8.213058570523511],
              [-39.981983727932935, -8.213058587739111],
              [-39.981983731962245, -8.213058604946966],
              [-39.981983736025072, -8.213058622146976],
              [-39.981983740121436, -8.21305863933908],
              [-39.981983744251309, -8.21305865652324],
              [-39.981983748414677, -8.213058673699363],
              [-39.981983752611534, -8.213058690867392],
              [-39.981983756841835, -8.213058708027283],
              [-39.981983761105603, -8.213058725178939],
              [-39.981983765402795, -8.213058742322307],
              [-39.981983769733404, -8.213058759457343],
              [-39.981983774097401, -8.213058776583956],
              [-39.981983778494786, -8.2130587937021],
              [-39.981983782925539, -8.213058810811718],
              [-39.981983787389645, -8.213058827912706],
              [-39.981983791887089, -8.213058845005047],
              [-39.981983796417829, -8.213058862088635],
              [-39.98198380098188, -8.21305887916343],
              [-39.981983805579205, -8.213058896229334],
              [-39.981983810209805, -8.213058913286334],
              [-39.981983814873637, -8.213058930334318],
              [-39.981983819570694, -8.213058947373263],
              [-39.981983824300976, -8.213058964403077],
              [-39.981983829064447, -8.213058981423716],
              [-39.981983833861086, -8.213058998435109],
              [-39.981983838690908, -8.213059015437191],
              [-39.981983843553834, -8.213059032429889],
              [-39.9819838484499, -8.213059049413159],
              [-39.981983853379056, -8.213059066386915],
              [-39.981983858341309, -8.213059083351071],
              [-39.981983863336623, -8.213059100305596],
              [-39.981983868364971, -8.213059117250436],
              [-39.981983873426358, -8.21305913418551],
              [-39.981983878520765, -8.213059151110784],
              [-39.981983883648134, -8.213059168026124],
              [-39.981983888808479, -8.21305918493154],
              [-39.981983894001793, -8.213059201826942],
              [-39.981983899228013, -8.213059218712255],
              [-39.981983904487151, -8.213059235587416],
              [-39.981983909779174, -8.213059252452352],
              [-39.981983915104067, -8.213059269307035],
              [-39.981983920461829, -8.213059286151379],
              [-39.981983925852404, -8.213059302985315],
              [-39.981983931275792, -8.213059319808808],
              [-39.981983936731972, -8.213059336621763],
              [-39.981983942220928, -8.213059353424123],
              [-39.981983947742613, -8.213059370215843],
              [-39.981983953297039, -8.213059386996823],
              [-39.981983966214749, -8.213059423958907],
              [-39.982114994429807, -8.213447244951878],
              [-39.982114998267477, -8.213447274995891],
              [-39.982115052064358, -8.213447439415903],
              [-39.982115102695936, -8.213447604830616],
              [-39.982115150143628, -8.213447771179565],
              [-39.982115194390154, -8.213447938401945],
              [-39.9821152354193, -8.213448106436626],
              [-39.982115273216074, -8.213448275222142],
              [-39.982115307766648, -8.213448444696763],
              [-39.98211533905841, -8.213448614798585],
              [-39.982115367079906, -8.213448785465435],
              [-39.982115391820898, -8.213448956634805],
              [-39.982115413272325, -8.213449128244218],
              [-39.982115431426351, -8.213449300230895],
              [-39.982115446276339, -8.213449472531893],
              [-39.982115457816874, -8.213449645084326],
              [-39.982115466043723, -8.213449817825014],
              [-39.982115470953879, -8.213449990690872],
              [-39.982115472545544, -8.213450163618621],
              [-39.982115470818123, -8.213450336545097],
              [-39.982115465772296, -8.213450509407082],
              [-39.982115457409861, -8.213450682141312],
              [-39.982115445733882, -8.213450854684677],
              [-39.982115430748635, -8.213451026974091],
              [-39.982115412459621, -8.213451198946538],
              [-39.982115390873481, -8.213451370539165],
              [-39.982115365998148, -8.213451541689231],
              [-39.982115337842693, -8.213451712334153],
              [-39.982115306417413, -8.213451882411528],
              [-39.982115271733811, -8.213452051859225],
              [-39.982115233804556, -8.213452220615238],
              [-39.982115192643526, -8.213452388617901],
              [-39.982115148265763, -8.213452555805745],
              [-39.982115100687494, -8.213452722117674],
              [-39.982115049926094, -8.213452887492906],
              [-39.982114996000163, -8.213453051870923],
              [-39.982114938929385, -8.213453215191652],
              [-39.982114878734649, -8.213453377395396],
              [-39.982114815437932, -8.213453538422833],
              [-39.982114749062411, -8.213453698215076],
              [-39.982114679632318, -8.213453856713734],
              [-39.982114607173074, -8.213454013860844],
              [-39.982114531711154, -8.213454169598924],
              [-39.982114453274143, -8.213454323871039],
              [-39.982114371890731, -8.213454476620823],
              [-39.982114287590669, -8.213454627792386],
              [-39.982114200404787, -8.213454777330483],
              [-39.982114110364932, -8.213454925180441],
              [-39.982114017504053, -8.213455071288204],
              [-39.98211392185609, -8.213455215600316],
              [-39.982113823456025, -8.213455358064056],
              [-39.982113722339818, -8.213455498627324],
              [-39.982113618544446, -8.213455637238724],
              [-39.982113512107865, -8.213455773847585],
              [-39.982113403068986, -8.213455908403949],
              [-39.982113291467677, -8.213456040858631],
              [-39.982113177344722, -8.213456171163182],
              [-39.982113060741867, -8.21345629927],
              [-39.982112941701743, -8.213456425132225],
              [-39.982112820267879, -8.21345654870383],
              [-39.982112696484656, -8.213456669939628],
              [-39.982112570397334, -8.213456788795323],
              [-39.982112442052035, -8.213456905227442],
              [-39.982112311495655, -8.21345701919341],
              [-39.982112178775935, -8.213457130651557],
              [-39.98211204394142, -8.213457239561164],
              [-39.982111907041379, -8.213457345882372],
              [-39.982111768125897, -8.213457449576351],
              [-39.982111627245736, -8.213457550605149],
              [-39.98211148445241, -8.213457648931819],
              [-39.982111339798145, -8.213457744520452],
              [-39.982111193335797, -8.213457837336083],
              [-39.982111045118934, -8.213457927344772],
              [-39.982110895201757, -8.21345801451363],
              [-39.982110743639062, -8.213458098810758],
              [-39.982110590486272, -8.213458180205345],
              [-39.982110435799378, -8.213458258667655],
              [-39.982110279634931, -8.21345833416896],
              [-39.982110122050038, -8.21345840668168],
              [-39.982109963102303, -8.213458476179273],
              [-39.982109802849862, -8.213458542636385],
              [-39.982109641351272, -8.213458606028697],
              [-39.98210947866562, -8.213458666332999],
              [-39.982109314852359, -8.213458723527284],
              [-39.982109149971379, -8.213458777590631],
              [-39.982108984082977, -8.213458828503233],
              [-39.982108817247799, -8.213458876246536],
              [-39.982108649526843, -8.213458920803033],
              [-39.982108480981445, -8.213458962156469],
              [-39.982108311673215, -8.213459000291707],
              [-39.982108141664035, -8.213459035194811],
              [-39.982107971016106, -8.213459066853028],
              [-39.982107799791791, -8.21345909525477],
              [-39.982107628053683, -8.213459120389658],
              [-39.982107455864622, -8.213459142248508],
              [-39.982107283287498, -8.213459160823298],
              [-39.982107110385463, -8.213459176107259],
              [-39.982106937221694, -8.213459188094827],
              [-39.982106763859541, -8.213459196781564],
              [-39.982106590362342, -8.213459202164353],
              [-39.982106416793691, -8.213459204241056],
              [-39.982106399716315, -8.213459204282723],
              [-39.981447339997985, -8.213460811712],
              [-39.980913771610851, -8.213462112280897],
              [-39.980913767954895, -8.213462112289806],
              [-39.980913737555902, -8.213462112362588],
              [-39.980913665957509, -8.213462113087871],
              [-39.980913594367088, -8.213462114375709],
              [-39.980913522789081, -8.213462116226042],
              [-39.980913451227941, -8.213462118638791],
              [-39.98091337968814, -8.213462121613745],
              [-39.980913308174117, -8.213462125150736],
              [-39.98091323669032, -8.213462129249539],
              [-39.980913165241184, -8.213462133909923],
              [-39.980913093831163, -8.213462139131577],
              [-39.980913022464712, -8.213462144914198],
              [-39.980912951146244, -8.213462151257394],
              [-39.980912879880229, -8.213462158160795],
              [-39.980912808671064, -8.213462165623969],
              [-39.980912737523205, -8.213462173646439],
              [-39.980912666441071, -8.213462182227717],
              [-39.980912595429075, -8.213462191367254],
              [-39.980912524491643, -8.213462201064507],
              [-39.980912453633181, -8.213462211318868],
              [-39.980912382858115, -8.213462222129682],
              [-39.980912312170815, -8.213462233496278],
              [-39.980912241575709, -8.213462245417983],
              [-39.980912171077172, -8.213462257894001],
              [-39.980912100679596, -8.213462270923609],
              [-39.980912030387366, -8.213462284505935],
              [-39.980911960204828, -8.213462298640192],
              [-39.980911890136376, -8.213462313325474],
              [-39.980911820186364, -8.21346232856088],
              [-39.980911750359155, -8.21346234434546],
              [-39.980911680659041, -8.2134623606782],
              [-39.980911611090406, -8.213462377558137],
              [-39.980911541657576, -8.213462394984177],
              [-39.980911472364845, -8.213462412955273],
              [-39.980911403216531, -8.213462431470276],
              [-39.980911334216955, -8.213462450528054],
              [-39.980911265370395, -8.213462470127418],
              [-39.980911196681134, -8.213462490267139],
              [-39.980911128153451, -8.213462510945943],
              [-39.980911059791588, -8.213462532162596],
              [-39.980910991599814, -8.213462553915734],
              [-39.980910923582378, -8.213462576204035],
              [-39.980910855743495, -8.21346259902611],
              [-39.980910788087392, -8.213462622380513],
              [-39.98091072061829, -8.213462646265819],
              [-39.980910653340359, -8.213462670680521],
              [-39.980910586257814, -8.213462695623106],
              [-39.98091051937481, -8.213462721092052],
              [-39.980910452695504, -8.213462747085728],
              [-39.980910386224046, -8.213462773602542],
              [-39.980910319964586, -8.213462800640846],
              [-39.980910253921238, -8.213462828198951],
              [-39.9809101880981, -8.213462856275145],
              [-39.980910122499274, -8.213462884867679],
              [-39.980910057128838, -8.213462913974778],
              [-39.980909991990863, -8.213462943594626],
              [-39.9809099270894, -8.213462973725381],
              [-39.98090986242849, -8.21346300436516],
              [-39.980909798012135, -8.213463035512085],
              [-39.980909733844378, -8.213463067164206],
              [-39.980909669929183, -8.213463099319549],
              [-39.980909606270529, -8.213463131976109],
              [-39.980909542872382, -8.213463165131872],
              [-39.980909479738699, -8.213463198784748],
              [-39.980909416873367, -8.213463232932678],
              [-39.980909354280342, -8.213463267573502],
              [-39.980909291963485, -8.213463302705096],
              [-39.980909229926681, -8.213463338325239],
              [-39.98090916817381, -8.213463374431752],
              [-39.98090910670868, -8.213463411022376],
              [-39.980909045535135, -8.213463448094814],
              [-39.980908984656978, -8.213463485646798],
              [-39.980908924077994, -8.213463523675971],
              [-39.98090886380195, -8.21346356217996],
              [-39.980908803832605, -8.213463601156388],
              [-39.980908744173675, -8.213463640602825],
              [-39.980908684828876, -8.213463680516799],
              [-39.980908625801909, -8.213463720895865],
              [-39.980908567096421, -8.213463761737462],
              [-39.980908508716098, -8.213463803039092],
              [-39.980908450664543, -8.213463844798152],
              [-39.980908392945402, -8.213463887012091],
              [-39.980908335562212, -8.213463929678232],
              [-39.980908278518591, -8.213463972793946],
              [-39.980908221818048, -8.213464016356559],
              [-39.980908165464172, -8.213464060363354],
              [-39.98090810946038, -8.213464104811585],
              [-39.980908053810225, -8.213464149698471],
              [-39.980907998517146, -8.213464195021238],
              [-39.98090794358459, -8.2134642407771],
              [-39.980907889015967, -8.213464286963164],
              [-39.980907834814658, -8.213464333576582],
              [-39.980907780984069, -8.213464380614422],
              [-39.980907727527523, -8.21346442807382],
              [-39.980907674448346, -8.213464475951763],
              [-39.980907621749857, -8.213464524245284],
              [-39.980907569435303, -8.213464572951388],
              [-39.98090751750798, -8.213464622067075],
              [-39.980907465971072, -8.213464671589232],
              [-39.980907414827811, -8.21346472151482],
              [-39.980907364081396, -8.213464771840734],
              [-39.980907389422505, -8.213464750655895],
              [-39.980106584201032, -8.214265215304129],
              [-39.980106571419562, -8.21426522806493],
              [-39.980106558612583, -8.214265240800302],
              [-39.980106545780146, -8.214265253510229],
              [-39.980106532922299, -8.214265266194642],
              [-39.980106520039115, -8.214265278853503],
              [-39.980106507130621, -8.214265291486743],
              [-39.980106494196875, -8.214265304094342],
              [-39.980106481237932, -8.214265316676213],
              [-39.980106468253851, -8.214265329232331],
              [-39.980106455244666, -8.214265341762657],
              [-39.980106442210428, -8.214265354267116],
              [-39.980106429151199, -8.214265366745673],
              [-39.980106416067045, -8.214265379198235],
              [-39.980106402957993, -8.21426539162481],
              [-39.980106389824115, -8.214265404025333],
              [-39.980106376665447, -8.214265416399757],
              [-39.980106363482044, -8.214265428748014],
              [-39.980106350273957, -8.214265441070063],
              [-39.980106337041249, -8.21426545336586],
              [-39.980106323783964, -8.214265465635355],
              [-39.980106310502173, -8.214265477878492],
              [-39.980106297195888, -8.214265490095228],
              [-39.980106283865197, -8.214265502285537],
              [-39.980106270510149, -8.214265514449341],
              [-39.980106257130799, -8.214265526586601],
              [-39.980106243727164, -8.214265538697257],
              [-39.980106230299349, -8.214265550781269],
              [-39.980106216847375, -8.214265562838589],
              [-39.980106203371307, -8.214265574869177],
              [-39.980106189871186, -8.214265586872994],
              [-39.9801061763471, -8.214265598849948],
              [-39.980106162799061, -8.214265610800041],
              [-39.980106149227147, -8.214265622723202],
              [-39.980106135631388, -8.214265634619403],
              [-39.980106122011875, -8.21426564648857],
              [-39.980106108368631, -8.214265658330675],
              [-39.980106094701725, -8.214265670145647],
              [-39.980106081011208, -8.214265681933451],
              [-39.980106067297115, -8.21426569369406],
              [-39.980106053559545, -8.214265705427417],
              [-39.980106039798521, -8.21426571713342],
              [-39.980106026014091, -8.214265728812141],
              [-39.980106012206342, -8.214265740463444],
              [-39.980105998375308, -8.214265752087273],
              [-39.980105984521032, -8.214265763683654],
              [-39.980105970643585, -8.214265775252478],
              [-39.98010595674301, -8.214265786793714],
              [-39.980105942819399, -8.214265798307345],
              [-39.980105928872774, -8.214265809793288],
              [-39.980105914903177, -8.214265821251509],
              [-39.980105900910687, -8.214265832681983],
              [-39.980105886895366, -8.214265844084636],
              [-39.980105872857266, -8.214265855459415],
              [-39.980105858796421, -8.214265866806317],
              [-39.980105844712888, -8.214265878125275],
              [-39.980105830606767, -8.214265889416223],
              [-39.980105816478073, -8.214265900679161],
              [-39.980105802326875, -8.214265911913991],
              [-39.980105788153217, -8.21426592312072],
              [-39.98010577395717, -8.21426593429929],
              [-39.980105759738791, -8.214265945449613],
              [-39.980105745498136, -8.214265956571703],
              [-39.980105731235248, -8.214265967665479],
              [-39.980105716950185, -8.214265978730905],
              [-39.98010570264303, -8.214265989767936],
              [-39.980105688313806, -8.214266000776547],
              [-39.980105673962584, -8.214266011756676],
              [-39.980105659589434, -8.214266022708285],
              [-39.980105645194385, -8.214266033631331],
              [-39.980105630777537, -8.214266044525788],
              [-39.980105616338896, -8.214266055391576],
              [-39.980105601878556, -8.21426606622866],
              [-39.980105587396565, -8.214266077037026],
              [-39.980105572892974, -8.214266087816602],
              [-39.980105558367839, -8.214266098567341],
              [-39.980105543821232, -8.214266109289238],
              [-39.980105529253201, -8.214266119982229],
              [-39.980105514663798, -8.214266130646248],
              [-39.980105500053099, -8.214266141281263],
              [-39.980105485421156, -8.214266151887262],
              [-39.980105470768009, -8.214266162464174],
              [-39.980105456093746, -8.214266173011991],
              [-39.980105441398393, -8.214266183530603],
              [-39.980105426682016, -8.214266194020052],
              [-39.980105411944685, -8.214266204480237],
              [-39.98010539718647, -8.214266214911131],
              [-39.980105382407402, -8.214266225312711],
              [-39.980105367607557, -8.214266235684896],
              [-39.980105352787, -8.214266246027702],
              [-39.980105337945766, -8.214266256341043],
              [-39.980105323083933, -8.2142662666249],
              [-39.980105308201544, -8.214266276879194],
              [-39.980105293298685, -8.21426628710392],
              [-39.98010527837539, -8.214266297299051],
              [-39.980105263431724, -8.214266307464515],
              [-39.980105248467751, -8.214266317600291],
              [-39.980105233483528, -8.214266327706317],
              [-39.980105218479132, -8.214266337782577],
              [-39.980105203454642, -8.21426634782873],
              [-39.98010522516654, -8.214266303919645],
              [-39.979302042281809, -8.214801777452877],
              [-39.979302011520737, -8.214801798816808],
              [-39.979301995709378, -8.214801809333643],
              [-39.979301979875956, -8.214801819817446],
              [-39.979301964020515, -8.214801830268216],
              [-39.979301948143153, -8.214801840685853],
              [-39.979301932243942, -8.214801851070339],
              [-39.979301916322925, -8.214801861421657],
              [-39.979301900380172, -8.214801871739731],
              [-39.979301884415769, -8.214801882024508],
              [-39.979301868429779, -8.214801892275956],
              [-39.979301852422296, -8.214801902494012],
              [-39.979301836393354, -8.214801912678682],
              [-39.979301820343039, -8.214801922829865],
              [-39.979301804271422, -8.214801932947529],
              [-39.979301788178567, -8.214801943031663],
              [-39.979301772064559, -8.214801953082191],
              [-39.979301755929448, -8.214801963099065],
              [-39.979301739773334, -8.214801973082261],
              [-39.979301723596258, -8.214801983031753],
              [-39.979301707398307, -8.214801992947429],
              [-39.979301691179536, -8.214802002829318],
              [-39.979301674940046, -8.21480201267731],
              [-39.97930165867988, -8.214802022491456],
              [-39.979301642399122, -8.214802032271608],
              [-39.979301626097858, -8.214802042017762],
              [-39.979301609776122, -8.214802051729905],
              [-39.979301593434023, -8.214802061407987],
              [-39.979301577071595, -8.214802071051913],
              [-39.979301560688945, -8.214802080661697],
              [-39.979301544286116, -8.214802090237281],
              [-39.979301527863214, -8.21480209977865],
              [-39.979301511420282, -8.214802109285682],
              [-39.979301494957404, -8.214802118758413],
              [-39.97930147847466, -8.214802128196794],
              [-39.979301461972085, -8.214802137600721],
              [-39.979301445449806, -8.214802146970216],
              [-39.979301428907846, -8.21480215630522],
              [-39.97930141234631, -8.214802165605686],
              [-39.979301395765269, -8.214802174871586],
              [-39.979301379164781, -8.214802184102894],
              [-39.979301362544923, -8.214802193299514],
              [-39.979301345905768, -8.214802202461444],
              [-39.979301329247392, -8.21480221158863],
              [-39.979301312569874, -8.214802220681046],
              [-39.97930129587327, -8.214802229738654],
              [-39.97930127915766, -8.214802238761385],
              [-39.979301262423128, -8.214802247749223],
              [-39.979301245669738, -8.214802256702134],
              [-39.979301228897569, -8.214802265620069],
              [-39.979301212106677, -8.214802274502992],
              [-39.979301195297161, -8.214802283350885],
              [-39.979301178469086, -8.214802292163684],
              [-39.979301161622523, -8.214802300941335],
              [-39.979301144757542, -8.214802309683826],
              [-39.979301127874223, -8.214802318391099],
              [-39.979301110972621, -8.214802327063142],
              [-39.979301094052857, -8.214802335699888],
              [-39.979301077114961, -8.214802344301315],
              [-39.979301060159017, -8.214802352867393],
              [-39.979301043185089, -8.214802361398073],
              [-39.979301026193298, -8.214802369893299],
              [-39.979301009183672, -8.214802378353076],
              [-39.979300992156297, -8.214802386777333],
              [-39.979300975111265, -8.214802395166048],
              [-39.979300958048626, -8.214802403519183],
              [-39.979300940968471, -8.214802411836732],
              [-39.979300923870866, -8.214802420118591],
              [-39.979300906755888, -8.214802428364779],
              [-39.979300889623616, -8.214802436575228],
              [-39.979300872474127, -8.214802444749923],
              [-39.979300855307478, -8.21480245288882],
              [-39.97930083812377, -8.214802460991866],
              [-39.979300820923072, -8.214802469059055],
              [-39.979300803705442, -8.214802477090331],
              [-39.979300786470965, -8.21480248508567],
              [-39.979300769219734, -8.214802493045013],
              [-39.979300751951797, -8.214802500968364],
              [-39.979300734667255, -8.214802508855671],
              [-39.979300717366165, -8.214802516706918],
              [-39.979300700048604, -8.214802524522014],
              [-39.979300682714658, -8.214802532300954],
              [-39.979300665364391, -8.214802540043728],
              [-39.979300647997896, -8.214802547750304],
              [-39.979300630615235, -8.214802555420587],
              [-39.979300613216495, -8.214802563054638],
              [-39.979300595801739, -8.214802570652322],
              [-39.97930057837106, -8.214802578213691],
              [-39.979300560924507, -8.21480258573863],
              [-39.979300543462202, -8.214802593227185],
              [-39.979300525984158, -8.214802600679288],
              [-39.97930050849051, -8.214802608094889],
              [-39.979300490981316, -8.214802615474007],
              [-39.97930047345664, -8.214802622816572],
              [-39.979300455916558, -8.214802630122549],
              [-39.979300438361186, -8.214802637391927],
              [-39.979300420790551, -8.214802644624667],
              [-39.979300403204746, -8.214802651820719],
              [-39.979300385603871, -8.21480265898005],
              [-39.979300367987975, -8.21480266610266],
              [-39.979300350357292, -8.214802673187046],
              [-39.979300319679908, -8.214802686066315],
              [-39.977961893960462, -8.215339007030423],
              [-39.977961918717654, -8.215338962718308],
              [-39.977961863521898, -8.215338985044209],
              [-39.977961808474369, -8.215339007730478],
              [-39.977961753577461, -8.21533903077613],
              [-39.977961698833525, -8.215339054180196],
              [-39.977961644244928, -8.215339077941646],
              [-39.977961589814022, -8.215339102059449],
              [-39.977961535543123, -8.215339126532614],
              [-39.977961481434605, -8.215339151360032],
              [-39.977961427490776, -8.215339176540667],
              [-39.977961373713974, -8.215339202073434],
              [-39.977961320106502, -8.215339227957232],
              [-39.977961266670668, -8.215339254190928],
              [-39.977961213408783, -8.21533928077339],
              [-39.977961160323112, -8.215339307703511],
              [-39.977961107415993, -8.215339334980094],
              [-39.977961054689658, -8.215339362601972],
              [-39.977961002146401, -8.21533939056796],
              [-39.977960949788489, -8.215339418876868],
              [-39.977960897618132, -8.21533944752745],
              [-39.977960845637639, -8.215339476518503],
              [-39.977960793849213, -8.215339505848755],
              [-39.977960742255085, -8.215339535516941],
              [-39.977960690857486, -8.215339565521806],
              [-39.977960639658626, -8.215339595862053],
              [-39.977960588660707, -8.215339626536354],
              [-39.977960537865933, -8.215339657543396],
              [-39.977960487276476, -8.215339688881842],
              [-39.977960436894527, -8.215339720550366],
              [-39.977960386722273, -8.215339752547576],
              [-39.977960336761832, -8.215339784872112],
              [-39.977960287015385, -8.215339817522572],
              [-39.977960237485057, -8.215339850497575],
              [-39.977960188173, -8.215339883795634],
              [-39.977960139081318, -8.215339917415397],
              [-39.977960090212143, -8.215339951355364],
              [-39.97796004156757, -8.215339985614108],
              [-39.97795999314971, -8.215340020190119],
              [-39.977959944960617, -8.215340055081928],
              [-39.977959897002386, -8.215340090288025],
              [-39.977959849277077, -8.215340125806897],
              [-39.977959801786739, -8.215340161637018],
              [-39.977959754533437, -8.215340197776856],
              [-39.977959707519197, -8.215340234224829],
              [-39.977959660746023, -8.215340270979389],
              [-39.977959614215962, -8.215340308038932],
              [-39.977959567930995, -8.215340345401881],
              [-39.977959521893105, -8.215340383066623],
              [-39.977959476104317, -8.215340421031529],
              [-39.977959430566578, -8.215340459294959],
              [-39.977959385281828, -8.215340497855291],
              [-39.977959340252049, -8.215340536710842],
              [-39.977959295479174, -8.215340575859948],
              [-39.977959250965107, -8.215340615300928],
              [-39.977959206711787, -8.215340655032071],
              [-39.977959162721127, -8.215340695051671],
              [-39.97795911899501, -8.215340735357989],
              [-39.97795907553531, -8.21534077594932],
              [-39.977959032343911, -8.21534081682389],
              [-39.977958989422667, -8.215340857979974],
              [-39.977958946773427, -8.215340899415766],
              [-39.977958904398037, -8.21534094112948],
              [-39.977958862298308, -8.215340983119338],
              [-39.977958820476054, -8.215341025383536],
              [-39.977958778933072, -8.215341067920228],
              [-39.977958737671187, -8.215341110727596],
              [-39.977958696692134, -8.215341153803816],
              [-39.977958655997696, -8.215341197146994],
              [-39.977958615589607, -8.215341240755306],
              [-39.977958575469636, -8.215341284626849],
              [-39.977958535639502, -8.215341328759727],
              [-39.977958496100896, -8.215341373152066],
              [-39.977958456855546, -8.215341417801913],
              [-39.977958417905135, -8.215341462707389],
              [-39.977958379251341, -8.215341507866539],
              [-39.977958340895825, -8.215341553277399],
              [-39.977958302840243, -8.215341598938052],
              [-39.97795826508623, -8.21534164484652],
              [-39.977958227635412, -8.215341691000825],
              [-39.97795819048941, -8.215341737398962],
              [-39.977958153649816, -8.215341784038971],
              [-39.97795811711822, -8.215341830918797],
              [-39.97795808089618, -8.21534187803646],
              [-39.977958044985279, -8.215341925389925],
              [-39.977958009387031, -8.215341972977141],
              [-39.977957974103013, -8.215342020796053],
              [-39.977957939134726, -8.215342068844615],
              [-39.977957904483659, -8.215342117120743],
              [-39.977957870151329, -8.215342165622355],
              [-39.97795783613919, -8.215342214347409],
              [-39.977957802448728, -8.215342263293765],
              [-39.977957769081371, -8.215342312459315],
              [-39.977957736038576, -8.215342361841962],
              [-39.977957703321763, -8.215342411439549],
              [-39.977957670932334, -8.21534246124998],
              [-39.977957638871693, -8.215342511271064],
              [-39.977957607141214, -8.215342561500695],
              [-39.977957575742259, -8.215342611936673],
              [-39.977957544676187, -8.215342662576848],
              [-39.977957513944339, -8.21534271341902],
              [-39.977957545593704, -8.215342725728645],
              [-39.977561270166511, -8.216003102872467],
              [-39.977561296342735, -8.216003100149651],
              [-39.977561234594312, -8.216003204651768],
              [-39.977561174257822, -8.216003309969309],
              [-39.977561115344109, -8.21600341608333],
              [-39.977561057863774, -8.216003522974791],
              [-39.977561001827134, -8.21600363062444],
              [-39.977560947244264, -8.216003739012933],
              [-39.977560894124991, -8.216003848120804],
              [-39.977560842478859, -8.216003957928402],
              [-39.97756079231516, -8.216004068416],
              [-39.977560743642883, -8.216004179563761],
              [-39.97756069647081, -8.21600429135167],
              [-39.977560650807419, -8.216004403759623],
              [-39.977560606660901, -8.216004516767452],
              [-39.977560564039209, -8.216004630354808],
              [-39.977560522949993, -8.216004744501303],
              [-39.977560483400644, -8.216004859186384],
              [-39.977560445398268, -8.216004974389428],
              [-39.977560408949707, -8.216005090089769],
              [-39.977560374061511, -8.216005206266589],
              [-39.977560340739956, -8.216005322898978],
              [-39.977560308991002, -8.216005439965983],
              [-39.977560278820405, -8.21600555744655],
              [-39.977560250233559, -8.216005675319572],
              [-39.977560223235599, -8.216005793563866],
              [-39.977560197831394, -8.216005912158138],
              [-39.977560174025506, -8.216006031081109],
              [-39.97756015182221, -8.216006150311363],
              [-39.977560131225509, -8.216006269827503],
              [-39.977560112239097, -8.216006389608019],
              [-39.977560094866384, -8.216006509631395],
              [-39.977560079110496, -8.216006629876025],
              [-39.977560064974277, -8.216006750320325],
              [-39.977560052460248, -8.216006870942618],
              [-39.977560041570676, -8.21600699172124],
              [-39.977560032307508, -8.216007112634456],
              [-39.977560024672414, -8.216007233660543],
              [-39.977560018666772, -8.216007354777735],
              [-39.977560014291655, -8.216007475964259],
              [-39.97756001154783, -8.216007597198342],
              [-39.977560010435845, -8.216007718458155],
              [-39.977560010955848, -8.216007839721941],
              [-39.977560013107762, -8.216007960967881],
              [-39.977560016891196, -8.216008082174175],
              [-39.977560022305475, -8.21600820331903],
              [-39.977560029349632, -8.216008324380679],
              [-39.977560038022389, -8.216008445337364],
              [-39.977560048322204, -8.216008566167305],
              [-39.9775600602472, -8.216008686848813],
              [-39.977560073795253, -8.216008807360167],
              [-39.977560088963919, -8.216008927679738],
              [-39.977560105750477, -8.216009047785892],
              [-39.977560124151907, -8.216009167656999],
              [-39.97756014416489, -8.216009287271532],
              [-39.977560165785846, -8.216009406607991],
              [-39.977560189010873, -8.216009525644916],
              [-39.977560213835801, -8.216009644360877],
              [-39.977560240256182, -8.216009762734577],
              [-39.977560268267226, -8.216009880744728],
              [-39.977560297863945, -8.216009998370092],
              [-39.977560329040998, -8.216010115589564],
              [-39.977560361792776, -8.21601023238205],
              [-39.977560396113375, -8.216010348726529],
              [-39.97756043199665, -8.216010464602109],
              [-39.977560469436163, -8.216010579987945],
              [-39.977560508425157, -8.216010694863305],
              [-39.977560548956632, -8.216010809207548],
              [-39.977560591023291, -8.216010923000113],
              [-39.977560634617582, -8.216011036220522],
              [-39.977560679731674, -8.21601114884842],
              [-39.977560726357446, -8.21601126086359],
              [-39.977560774486513, -8.216011372245857],
              [-39.977560824110228, -8.216011482975228],
              [-39.977560875219673, -8.216011593031791],
              [-39.977560927805655, -8.216011702395765],
              [-39.97756098185873, -8.216011811047471],
              [-39.977561037369149, -8.216011918967382],
              [-39.977561094326994, -8.216012026136081],
              [-39.977561152721968, -8.216012132534347],
              [-39.977561212543591, -8.216012238143016],
              [-39.977561273781106, -8.216012342943095],
              [-39.977561336423513, -8.216012446915785],
              [-39.977561400459543, -8.216012550042365],
              [-39.977561465877692, -8.216012652304272],
              [-39.977561532666186, -8.216012753683154],
              [-39.977561600813026, -8.2160128541608],
              [-39.977561670305967, -8.216012953719121],
              [-39.977561741132504, -8.216013052340221],
              [-39.97756181327992, -8.21601315000637],
              [-39.977561886735224, -8.216013246700037],
              [-39.977561961485222, -8.216013342403796],
              [-39.977562037516485, -8.216013437100468],
              [-39.977562114815328, -8.216013530773028],
              [-39.977562193367859, -8.216013623404622],
              [-39.977562273159961, -8.216013714978606],
              [-39.977562354177287, -8.216013805478537],
              [-39.977562436405279, -8.216013894888111],
              [-39.977562519829128, -8.216013983191278],
              [-39.977562604433871, -8.216014070372164],
              [-39.977562690204273, -8.21601415641506],
              [-39.977562658875492, -8.216014133452227],
              [-39.978227788178117, -8.216672474031464],
              [-39.978227805098335, -8.216672496047101],
              [-39.978227866336198, -8.216672556089922],
              [-39.978227928141713, -8.216672615552675],
              [-39.978227990509346, -8.216672674430011],
              [-39.978228053433547, -8.216672732716614],
              [-39.978228116908632, -8.216672790407278],
              [-39.978228180928916, -8.216672847496845],
              [-39.978228245488701, -8.216672903980205],
              [-39.978228310582182, -8.216672959852309],
              [-39.978228376203511, -8.216673015108089],
              [-39.978228442346847, -8.216673069742667],
              [-39.978228509006236, -8.216673123751116],
              [-39.978228576175709, -8.216673177128586],
              [-39.978228643849256, -8.216673229870322],
              [-39.978228712020822, -8.216673281971616],
              [-39.978228780684304, -8.216673333427741],
              [-39.978228849833521, -8.216673384234147],
              [-39.97822891946231, -8.216673434386243],
              [-39.9782289895644, -8.216673483879516],
              [-39.97822906013355, -8.216673532709619],
              [-39.978229131163431, -8.21667358087212],
              [-39.978229202647668, -8.216673628362706],
              [-39.978229274579853, -8.216673675177127],
              [-39.97822934695354, -8.216673721311173],
              [-39.978229419762272, -8.216673766760746],
              [-39.978229492999503, -8.216673811521739],
              [-39.978229566658676, -8.216673855590205],
              [-39.978229640733204, -8.216673898962121],
              [-39.97822971521645, -8.216673941633628],
              [-39.978229790101729, -8.216673983600913],
              [-39.978229865382339, -8.216674024860225],
              [-39.978229941051531, -8.216674065407839],
              [-39.978230017102554, -8.216674105240163],
              [-39.978230093528566, -8.216674144353581],
              [-39.978230170322718, -8.216674182744615],
              [-39.978230247478159, -8.216674220409868],
              [-39.978230324987948, -8.216674257345851],
              [-39.978230402845156, -8.216674293549397],
              [-39.978230481042829, -8.216674329017131],
              [-39.978230559573902, -8.216674363745966],
              [-39.978230638431413, -8.21667439773279],
              [-39.978230717608241, -8.216674430974493],
              [-39.978230797097339, -8.216674463468147],
              [-39.978230876891558, -8.216674495210798],
              [-39.978230956983744, -8.216674526199693],
              [-39.978231037366747, -8.216674556431956],
              [-39.978231118033342, -8.216674585904933],
              [-39.978231198976324, -8.216674614615981],
              [-39.978231280188432, -8.216674642562484],
              [-39.978231361662395, -8.216674669742025],
              [-39.978231443390918, -8.216674696152072],
              [-39.97823152536666, -8.216674721790339],
              [-39.978231607582302, -8.216674746654464],
              [-39.978231690030455, -8.216674770742264],
              [-39.978231772703765, -8.21667479405156],
              [-39.9782318555948, -8.21667481658025],
              [-39.978231938696133, -8.216674838326384],
              [-39.978232022000348, -8.216674859287956],
              [-39.978232105499963, -8.21667487946308],
              [-39.978232189187494, -8.216674898849989],
              [-39.978232273055447, -8.216674917446918],
              [-39.978232357096338, -8.216674935252215],
              [-39.978232441302588, -8.216674952264267],
              [-39.978232525666684, -8.216674968481566],
              [-39.978232610181074, -8.216674983902662],
              [-39.978232694838169, -8.216674998526152],
              [-39.97823277963041, -8.216675012350755],
              [-39.978232864550158, -8.216675025375201],
              [-39.978232949589845, -8.216675037598364],
              [-39.978233034741855, -8.216675049019116],
              [-39.978233119998549, -8.216675059636456],
              [-39.978233205352268, -8.216675069449396],
              [-39.978233290795394, -8.216675078457126],
              [-39.978233376320276, -8.216675086658757],
              [-39.978233461919238, -8.216675094053599],
              [-39.978233547584608, -8.216675100640964],
              [-39.97823363330874, -8.216675106420299],
              [-39.978233719083924, -8.21667511139106],
              [-39.978233804902473, -8.216675115552853],
              [-39.978233890756734, -8.216675118905224],
              [-39.978233976638997, -8.216675121447883],
              [-39.97823406254156, -8.216675123180698],
              [-39.978234148456743, -8.216675124103389],
              [-39.978234234376835, -8.216675124215955],
              [-39.978234320294156, -8.216675123518371],
              [-39.978234406200997, -8.216675122010678],
              [-39.978234492089676, -8.216675119693015],
              [-39.97823457795247, -8.216675116565593],
              [-39.978234663781734, -8.216675112628675],
              [-39.978234749569737, -8.21667510788266],
              [-39.978234835308818, -8.216675102327962],
              [-39.978234920991284, -8.216675095965059],
              [-39.978235006609466, -8.216675088794489],
              [-39.978235092155707, -8.216675080817],
              [-39.978235177622309, -8.216675072033212],
              [-39.978235263001643, -8.216675062443921],
              [-39.978235348286063, -8.216675052050006],
              [-39.978235433467923, -8.216675040852389],
              [-39.978235518539584, -8.21667502885211],
              [-39.978235603493445, -8.216675016050202],
              [-39.978235601495335, -8.216674987418733],
              [-39.979976206178634, -8.216404309425631],
              [-39.979976218620294, -8.216404314295486],
              [-39.979976231180544, -8.216404312333124],
              [-39.979976243738022, -8.216404310353226],
              [-39.979976256292709, -8.216404308355802],
              [-39.979976268844574, -8.216404306340847],
              [-39.979976281393597, -8.216404304308394],
              [-39.979976293939743, -8.216404302258427],
              [-39.979976306482996, -8.216404300190968],
              [-39.979976319023343, -8.216404298105994],
              [-39.979976331560735, -8.216404296003557],
              [-39.979976344095157, -8.216404293883606],
              [-39.979976356626587, -8.216404291746198],
              [-39.979976369155004, -8.216404289591289],
              [-39.97997638168038, -8.216404287418886],
              [-39.97997639420268, -8.216404285229007],
              [-39.979976406721924, -8.21640428302168],
              [-39.979976419238014, -8.216404280796874],
              [-39.979976431750984, -8.216404278554641],
              [-39.979976444260778, -8.216404276294895],
              [-39.979976456767389, -8.216404274017757],
              [-39.979976469270788, -8.216404271723121],
              [-39.979976481770947, -8.21640426941106],
              [-39.979976494267845, -8.216404267081554],
              [-39.97997650676146, -8.216404264734617],
              [-39.979976519251757, -8.216404262370236],
              [-39.979976531738714, -8.216404259988465],
              [-39.979976544222325, -8.216404257589247],
              [-39.979976556702532, -8.21640425517262],
              [-39.979976569179343, -8.216404252738563],
              [-39.979976581652714, -8.21640425028713],
              [-39.97997659412264, -8.216404247818286],
              [-39.979976606589069, -8.216404245332035],
              [-39.979976619051989, -8.216404242828405],
              [-39.979976631511384, -8.216404240307398],
              [-39.97997664396722, -8.216404237768984],
              [-39.979976656419488, -8.216404235213188],
              [-39.97997666886814, -8.216404232640052],
              [-39.979976681313161, -8.216404230049541],
              [-39.979976693754523, -8.216404227441648],
              [-39.979976706192218, -8.216404224816438],
              [-39.97997671862619, -8.21640422217385],
              [-39.979976731056468, -8.216404219513914],
              [-39.979976743482986, -8.216404216836606],
              [-39.979976755905717, -8.216404214142004],
              [-39.979976768324654, -8.216404211430078],
              [-39.979976780739747, -8.216404208700794],
              [-39.979976793151025, -8.216404205954234],
              [-39.979976805558401, -8.216404203190315],
              [-39.979976817961898, -8.216404200409089],
              [-39.979976830361473, -8.21640419761057],
              [-39.979976842757097, -8.216404194794794],
              [-39.979976855148756, -8.216404191961692],
              [-39.979976867536401, -8.216404189111302],
              [-39.979976879920045, -8.216404186243633],
              [-39.979976892299632, -8.216404183358677],
              [-39.979976904675169, -8.216404180456465],
              [-39.979976917046599, -8.216404177536972],
              [-39.979976929413915, -8.216404174600212],
              [-39.979976941777103, -8.216404171646257],
              [-39.979976954136113, -8.21640416867498],
              [-39.979976966490931, -8.216404165686516],
              [-39.979976978841556, -8.216404162680805],
              [-39.979976991187925, -8.216404159657859],
              [-39.979977003530031, -8.216404156617708],
              [-39.97997701586786, -8.216404153560312],
              [-39.979977028201382, -8.216404150485696],
              [-39.979977040530564, -8.216404147393906],
              [-39.979977052855389, -8.216404144284894],
              [-39.979977065175831, -8.216404141158693],
              [-39.979977077491867, -8.216404138015319],
              [-39.979977089803477, -8.216404134854759],
              [-39.979977102110617, -8.216404131677006],
              [-39.979977114413295, -8.216404128482083],
              [-39.979977126711461, -8.216404125270023],
              [-39.979977139005101, -8.216404122040769],
              [-39.979977151294186, -8.216404118794431],
              [-39.979977163578702, -8.216404115530901],
              [-39.979977175858615, -8.216404112250238],
              [-39.979977188133901, -8.21640410895245],
              [-39.979977200404541, -8.21640410563756],
              [-39.979977212670505, -8.21640410230553],
              [-39.979977224931787, -8.216404098956367],
              [-39.979977237188336, -8.216404095590145],
              [-39.979977249440161, -8.216404092206799],
              [-39.979977261687189, -8.216404088806375],
              [-39.97997727392945, -8.21640408538884],
              [-39.979977286166879, -8.216404081954272],
              [-39.979977298399476, -8.216404078502597],
              [-39.979977310627199, -8.216404075033855],
              [-39.979977322850047, -8.216404071548043],
              [-39.979977335067964, -8.216404068045243],
              [-39.979977347280965, -8.216404064525324],
              [-39.979977359488998, -8.21640406098842],
              [-39.979977371692037, -8.216404057434445],
              [-39.979977383890095, -8.216404053863503],
              [-39.979977396083093, -8.216404050275523],
              [-39.979977408271033, -8.21640404667049],
              [-39.979977420453906, -8.216404043048508],
              [-39.979977432631685, -8.216404039409522],
              [-39.979977444804327, -8.216404035753516],
              [-39.979977476437014, -8.216404035005594],
              [-39.981297814536617, -8.21600648961642],
              [-39.981297839538406, -8.216006454748438],
              [-39.98129804826474, -8.216006394605271],
              [-39.981298258376064, -8.21600633945617],
              [-39.981298469752048, -8.21600628933273],
              [-39.981298682271593, -8.216006244263612],
              [-39.981298895812934, -8.21600620427472],
              [-39.981299110253744, -8.216006169388896],
              [-39.981299325471198, -8.216006139626167],
              [-39.98129954134194, -8.216006115003538],
              [-39.981299757742399, -8.216006095535143],
              [-39.981299974548527, -8.216006081232159],
              [-39.981300191636159, -8.216006072102743],
              [-39.981300408880919, -8.216006068152124],
              [-39.981300626158372, -8.216006069382594],
              [-39.981300843344016, -8.216006075793427],
              [-39.981301060313442, -8.216006087380951],
              [-39.981301276942354, -8.216006104138565],
              [-39.98130149310667, -8.216006126056596],
              [-39.981301708682537, -8.21600615312256],
              [-39.98130192354644, -8.216006185320888],
              [-39.981302137575319, -8.216006222633201],
              [-39.981302350646565, -8.216006265038061],
              [-39.981302562638078, -8.216006312511196],
              [-39.981302773428453, -8.216006365025414],
              [-39.981302982896914, -8.216006422550665],
              [-39.981303190923477, -8.216006485053953],
              [-39.981303397388942, -8.216006552499476],
              [-39.981303602175061, -8.21600662484861],
              [-39.981303805164487, -8.216006702059882],
              [-39.981304006240961, -8.216006784089121],
              [-39.981304205289277, -8.216006870889233],
              [-39.981304402195413, -8.216006962410598],
              [-39.981304596846556, -8.216007058600731],
              [-39.981304789131208, -8.216007159404498],
              [-39.9813049789392, -8.216007264764229],
              [-39.981305166161818, -8.216007374619496],
              [-39.981305350691784, -8.216007488907422],
              [-39.981305532423391, -8.216007607562497],
              [-39.981305711252531, -8.21600773051675],
              [-39.981305887076765, -8.216007857699775],
              [-39.981306059795372, -8.216007989038674],
              [-39.981306229309389, -8.216008124458202],
              [-39.981306395521713, -8.216008263880827],
              [-39.981306558337117, -8.216008407226623],
              [-39.981306717662349, -8.216008554413513],
              [-39.98130687340614, -8.216008705357192],
              [-39.981307025479225, -8.216008859971103],
              [-39.981307173794526, -8.216009018166794],
              [-39.981307318267071, -8.216009179853526],
              [-39.981307458814065, -8.216009344938755],
              [-39.981307595355041, -8.216009513327858],
              [-39.981307727811732, -8.216009684924343],
              [-39.981307856108309, -8.216009859629995],
              [-39.981307980171216, -8.216010037344647],
              [-39.981308099929429, -8.216010217966565],
              [-39.9813082153143, -8.216010401392209],
              [-39.981308326259764, -8.216010587516529],
              [-39.981308432702249, -8.21601077623292],
              [-39.981308534580769, -8.216010967433229],
              [-39.981308631836967, -8.216011161007964],
              [-39.981308724415129, -8.216011356846193],
              [-39.981308812262228, -8.216011554835717],
              [-39.981308895327921, -8.216011754863185],
              [-39.98130897356463, -8.216011956813952],
              [-39.981309046927542, -8.216012160572314],
              [-39.981309115374621, -8.21601236602158],
              [-39.981309178866645, -8.216012573044024],
              [-39.98130923736727, -8.216012781521071],
              [-39.981309290842972, -8.216012991333278],
              [-39.981309339263099, -8.216013202360438],
              [-39.981309382599903, -8.216013414481685],
              [-39.981309420828623, -8.216013627575466],
              [-39.981309453927281, -8.216013841519729],
              [-39.981309481876956, -8.216014056191916],
              [-39.981309504661631, -8.216014271469062],
              [-39.981309522268255, -8.216014487227797],
              [-39.98130953468673, -8.216014703344529],
              [-39.981309541909958, -8.216014919695473],
              [-39.981309543933776, -8.216015136156683],
              [-39.981309540757053, -8.216015352604149],
              [-39.981309532381601, -8.216015568913875],
              [-39.981309518812211, -8.216015784961924],
              [-39.981309500056675, -8.216016000624546],
              [-39.981309476125702, -8.216016215778202],
              [-39.981309447033013, -8.21601643029963],
              [-39.981309412795305, -8.216016644065911],
              [-39.981309373432161, -8.216016856954612],
              [-39.981309328966127, -8.216017068843762],
              [-39.981309279422717, -8.21601727961197],
              [-39.981309224830255, -8.216017489138503],
              [-39.981309165220068, -8.216017697303341],
              [-39.981309100626277, -8.216017903987225],
              [-39.9813090310859, -8.216018109071726],
              [-39.981308956638749, -8.216018312439402],
              [-39.981308877327507, -8.216018513973715],
              [-39.981308793197584, -8.216018713559214],
              [-39.981308704297184, -8.216018911081568],
              [-39.981308610677253, -8.216019106427611],
              [-39.981308512391387, -8.216019299485456],
              [-39.981308409496442, -8.21601949014422],
              [-39.98130837703286, -8.216019487527893],
              [-39.980650764648956, -8.217203893132821],
              [-39.980650742449484, -8.217203897519646],
              [-39.980650737418721, -8.217203906567407],
              [-39.98065073237759, -8.217203915609442],
              [-39.980650727326072, -8.217203924645718],
              [-39.98065072226418, -8.217203933676243],
              [-39.980650717191942, -8.21720394270098],
              [-39.980650712109323, -8.217203951719947],
              [-39.980650707016373, -8.217203960733112],
              [-39.980650701913078, -8.217203969740471],
              [-39.980650696799437, -8.217203978742015],
              [-39.980650691675478, -8.217203987737715],
              [-39.980650686541182, -8.217203996727566],
              [-39.980650681396583, -8.217204005711578],
              [-39.980650676241673, -8.217204014689703],
              [-39.980650671076475, -8.217204023661955],
              [-39.980650665900953, -8.217204032628336],
              [-39.980650660715149, -8.217204041588797],
              [-39.980650655519064, -8.217204050543334],
              [-39.980650650312718, -8.217204059491968],
              [-39.980650645096084, -8.217204068434643],
              [-39.980650639869197, -8.217204077371385],
              [-39.980650634632049, -8.21720408630217],
              [-39.980650629384655, -8.217204095226966],
              [-39.980650624127023, -8.21720410414579],
              [-39.980650618859158, -8.217204113058621],
              [-39.980650613581055, -8.217204121965421],
              [-39.980650608292741, -8.217204130866225],
              [-39.98065060299421, -8.217204139760989],
              [-39.980650597685468, -8.217204148649701],
              [-39.98065059236653, -8.21720415753237],
              [-39.980650587037402, -8.217204166408978],
              [-39.980650581698079, -8.217204175279504],
              [-39.980650576348587, -8.217204184143936],
              [-39.980650570988914, -8.217204193002294],
              [-39.980650565619086, -8.217204201854511],
              [-39.980650560239098, -8.21720421070059],
              [-39.98065055484895, -8.217204219540575],
              [-39.980650549448661, -8.217204228374385],
              [-39.980650544038234, -8.21720423720204],
              [-39.980650538617681, -8.217204246023538],
              [-39.98065053318701, -8.217204254838842],
              [-39.980650527746214, -8.217204263647952],
              [-39.980650522295313, -8.217204272450859],
              [-39.980650516834324, -8.21720428124754],
              [-39.980650511363223, -8.217204290037994],
              [-39.980650505882039, -8.217204298822203],
              [-39.980650500390759, -8.217204307600172],
              [-39.980650494889431, -8.217204316371886],
              [-39.980650489378029, -8.217204325137303],
              [-39.980650483856579, -8.217204333896444],
              [-39.98065047832506, -8.217204342649293],
              [-39.980650472783495, -8.217204351395832],
              [-39.980650467231911, -8.217204360136062],
              [-39.980650461670294, -8.217204368869965],
              [-39.980650456098644, -8.217204377597509],
              [-39.980650450516997, -8.217204386318711],
              [-39.980650444925324, -8.21720439503355],
              [-39.980650439323661, -8.217204403741993],
              [-39.980650433711993, -8.217204412444062],
              [-39.98065042809035, -8.217204421139737],
              [-39.980650422458737, -8.217204429828998],
              [-39.980650416817141, -8.217204438511835],
              [-39.980650411165598, -8.217204447188223],
              [-39.980650405504065, -8.217204455858187],
              [-39.980650399832619, -8.217204464521689],
              [-39.980650394151205, -8.217204473178734],
              [-39.980650388459871, -8.217204481829278],
              [-39.980650382758604, -8.217204490473341],
              [-39.980650377047418, -8.217204499110913],
              [-39.980650371326334, -8.217204507741974],
              [-39.980650365595331, -8.217204516366486],
              [-39.980650359854444, -8.217204524984487],
              [-39.980650354103666, -8.217204533595925],
              [-39.980650348342998, -8.217204542200818],
              [-39.98065034257246, -8.217204550799146],
              [-39.980650336792053, -8.217204559390883],
              [-39.980650331001783, -8.217204567976019],
              [-39.980650325201673, -8.217204576554561],
              [-39.980650319391707, -8.217204585126483],
              [-39.980650313571921, -8.217204593691783],
              [-39.980650307742295, -8.217204602250453],
              [-39.980650301902855, -8.217204610802467],
              [-39.980650296053597, -8.217204619347822],
              [-39.980650290194525, -8.217204627886515],
              [-39.980650284325677, -8.217204636418543],
              [-39.98065027844703, -8.217204644943839],
              [-39.980650272558591, -8.217204653462471],
              [-39.980650266660383, -8.217204661974371],
              [-39.980650260752405, -8.217204670479525],
              [-39.980650254834678, -8.217204678977961],
              [-39.980650248907196, -8.217204687469664],
              [-39.980650242969958, -8.217204695954573],
              [-39.980650237023006, -8.21720470443273],
              [-39.980650231066306, -8.217204712904108],
              [-39.980650225099886, -8.217204721368688],
              [-39.98065021912376, -8.217204729826467],
              [-39.980650213137935, -8.217204738277427],
              [-39.980650207142403, -8.217204746721572],
              [-39.98065020113718, -8.217204755158907],
              [-39.980650195122337, -8.217204763588274],
              [-39.980650222187059, -8.217204798876432],
              [-39.979981828127798, -8.218140423650048],
              [-39.979981834967319, -8.218140397455766],
              [-39.97998182027186, -8.218140417967255],
              [-39.979981805519103, -8.218140438437862],
              [-39.979981790709154, -8.218140458867452],
              [-39.979981775842113, -8.218140479255831],
              [-39.979981760918108, -8.218140499602866],
              [-39.979981745937266, -8.218140519908415],
              [-39.979981730899695, -8.218140540172293],
              [-39.979981715805494, -8.218140560394325],
              [-39.97998170065479, -8.218140580574397],
              [-39.979981685447711, -8.218140600712323],
              [-39.979981670184372, -8.218140620807931],
              [-39.979981654864879, -8.218140640861128],
              [-39.979981639489374, -8.218140660871722],
              [-39.979981624057949, -8.218140680839559],
              [-39.979981608570746, -8.218140700764476],
              [-39.979981593027873, -8.218140720646302],
              [-39.979981577429463, -8.218140740484928],
              [-39.979981561775617, -8.218140760280168],
              [-39.979981546066469, -8.218140780031872],
              [-39.97998153030214, -8.218140799739894],
              [-39.979981514482766, -8.218140819404086],
              [-39.979981498608446, -8.218140839024286],
              [-39.979981482679321, -8.218140858600336],
              [-39.979981466695484, -8.218140878132095],
              [-39.979981450657107, -8.218140897619428],
              [-39.97998143456428, -8.218140917062133],
              [-39.97998141841714, -8.218140936460109],
              [-39.979981402215806, -8.218140955813155],
              [-39.979981385960414, -8.218140975121187],
              [-39.979981369651085, -8.218140994383997],
              [-39.979981353287933, -8.218141013601455],
              [-39.979981336871106, -8.218141032773426],
              [-39.979981320400725, -8.218141051899739],
              [-39.979981303876897, -8.218141070980277],
              [-39.979981287299779, -8.21814109001485],
              [-39.979981270669498, -8.218141109003332],
              [-39.979981253986182, -8.218141127945579],
              [-39.97998123724993, -8.218141146841445],
              [-39.9799812204609, -8.21814116569079],
              [-39.979981203619225, -8.218141184493437],
              [-39.97998118672502, -8.218141203249273],
              [-39.979981169778434, -8.218141221958136],
              [-39.979981152779565, -8.218141240619856],
              [-39.979981135728586, -8.218141259234349],
              [-39.979981118625624, -8.218141277801429],
              [-39.979981101470777, -8.218141296320963],
              [-39.979981084264203, -8.218141314792797],
              [-39.979981067006044, -8.218141333216792],
              [-39.97998104969642, -8.218141351592802],
              [-39.979981032335466, -8.218141369920726],
              [-39.979981014923318, -8.218141388200339],
              [-39.979980997460117, -8.21814140643156],
              [-39.979980979945992, -8.21814142461424],
              [-39.979980962381084, -8.218141442748218],
              [-39.979980944765515, -8.218141460833403],
              [-39.979980927099454, -8.218141478869581],
              [-39.979980909383016, -8.218141496856649],
              [-39.979980891616322, -8.218141514794477],
              [-39.979980873799541, -8.218141532682916],
              [-39.979980855932787, -8.218141550521812],
              [-39.979980838016225, -8.218141568311053],
              [-39.979980820049974, -8.218141586050502],
              [-39.979980802034177, -8.218141603739999],
              [-39.979980783968976, -8.218141621379418],
              [-39.979980765854513, -8.218141638968623],
              [-39.979980747690909, -8.21814165650747],
              [-39.979980729478349, -8.218141673995838],
              [-39.979980711216932, -8.218141691433598],
              [-39.979980692906828, -8.218141708820568],
              [-39.979980674548152, -8.218141726156647],
              [-39.979980656141059, -8.218141743441697],
              [-39.979980637685713, -8.218141760675586],
              [-39.979980619182228, -8.218141777858182],
              [-39.979980600630761, -8.218141794989339],
              [-39.979980582031445, -8.218141812068934],
              [-39.979980563384444, -8.218141829096824],
              [-39.979980544689887, -8.218141846072879],
              [-39.979980525947937, -8.218141862996976],
              [-39.979980507158693, -8.218141879868964],
              [-39.979980488322354, -8.218141896688747],
              [-39.979980469439056, -8.218141913456183],
              [-39.979980450508911, -8.218141930171122],
              [-39.979980431532113, -8.218141946833446],
              [-39.979980412508773, -8.218141963443037],
              [-39.979980393439078, -8.218141979999748],
              [-39.979980374323119, -8.218141996503451],
              [-39.979980355161089, -8.218142012954015],
              [-39.979980335953123, -8.218142029351325],
              [-39.979980316699375, -8.218142045695258],
              [-39.979980297399976, -8.218142061985684],
              [-39.979980278055102, -8.218142078222467],
              [-39.979980258664902, -8.218142094405479],
              [-39.979980239229512, -8.218142110534618],
              [-39.979980219749059, -8.218142126609733],
              [-39.979980200223771, -8.218142142630734],
              [-39.979980180653712, -8.218142158597448],
              [-39.97998016103908, -8.218142174509772],
              [-39.979980141380032, -8.218142190367605],
              [-39.979980121676789, -8.218142206169617],
              [-39.97998010773388, -8.218142236684002],
              [-39.979318067687423, -8.218671669357576],
              [-39.979318063604978, -8.218671699873193],
              [-39.979317949499418, -8.218671792657588],
              [-39.979317836919854, -8.218671887274104],
              [-39.97931772589591, -8.21867198369784],
              [-39.979317616456825, -8.218672081903383],
              [-39.979317508631404, -8.218672181864916],
              [-39.979317402448039, -8.218672283556096],
              [-39.979317297934656, -8.218672386950214],
              [-39.979317195118803, -8.218672492019985],
              [-39.979317094027508, -8.218672598737795],
              [-39.979316994687416, -8.21867270707552],
              [-39.979316897124647, -8.21867281700465],
              [-39.979316801364888, -8.218672928496249],
              [-39.979316707433362, -8.218673041520972],
              [-39.97931661535479, -8.21867315604908],
              [-39.979316525153394, -8.218673272050388],
              [-39.979316436852933, -8.218673389494406],
              [-39.979316350476658, -8.218673508350189],
              [-39.979316266047299, -8.218673628586474],
              [-39.979316183587066, -8.218673750171567],
              [-39.979316103117682, -8.218673873073504],
              [-39.979316024660335, -8.218673997259925],
              [-39.979315948235666, -8.218674122698136],
              [-39.979315873863783, -8.21867424935512],
              [-39.979315801564276, -8.218674377197553],
              [-39.979315731356181, -8.218674506191737],
              [-39.979315663257971, -8.218674636303756],
              [-39.979315597287574, -8.218674767499328],
              [-39.979315533462362, -8.218674899743958],
              [-39.979315471799126, -8.218675033002764],
              [-39.979315412314094, -8.218675167240756],
              [-39.979315355022941, -8.218675302422564],
              [-39.979315299940744, -8.218675438512578],
              [-39.979315247081999, -8.218675575475011],
              [-39.979315196460618, -8.218675713273779],
              [-39.979315148089924, -8.2186758518726],
              [-39.979315101982657, -8.218675991235036],
              [-39.979315058150952, -8.218676131324402],
              [-39.979315016606343, -8.218676272103776],
              [-39.979314977359763, -8.21867641353613],
              [-39.979314940421553, -8.218676555584205],
              [-39.979314905801431, -8.218676698210656],
              [-39.979314873508514, -8.218676841377896],
              [-39.9793148435513, -8.218676985048253],
              [-39.979314815937677, -8.218677129183918],
              [-39.979314790674906, -8.218677273746939],
              [-39.979314767769644, -8.218677418699267],
              [-39.979314747227924, -8.218677564002743],
              [-39.979314729055154, -8.218677709619108],
              [-39.979314713256095, -8.21867785551003],
              [-39.97931469983493, -8.218678001637144],
              [-39.979314688795185, -8.218678147961942],
              [-39.97931468013978, -8.218678294445896],
              [-39.979314673870967, -8.218678441050482],
              [-39.979314669990409, -8.218678587737145],
              [-39.979314668499129, -8.218678734467177],
              [-39.97931466939751, -8.218678881202008],
              [-39.979314672685334, -8.218679027903024],
              [-39.979314678361725, -8.218679174531559],
              [-39.97931468642517, -8.218679321049086],
              [-39.979314696873594, -8.218679467416973],
              [-39.979314709704191, -8.218679613596732],
              [-39.979314724913628, -8.218679759549834],
              [-39.979314742497877, -8.21867990523789],
              [-39.979314762452312, -8.218680050622575],
              [-39.979314784771702, -8.218680195665591],
              [-39.97931480945013, -8.218680340328749],
              [-39.979314836481137, -8.218680484573985],
              [-39.979314865857596, -8.218680628363332],
              [-39.979314897571761, -8.218680771658908],
              [-39.979314931615299, -8.218680914423029],
              [-39.979314967979256, -8.218681056618118],
              [-39.979315006654033, -8.21868119820671],
              [-39.97931504762947, -8.218681339151575],
              [-39.979315090894779, -8.218681479415556],
              [-39.979315136438565, -8.218681618961769],
              [-39.979315184248833, -8.218681757753487],
              [-39.97931523431302, -8.218681895754168],
              [-39.979315286617926, -8.218682032927456],
              [-39.979315341149793, -8.218682169237315],
              [-39.979315397894268, -8.218682304647784],
              [-39.979315456836403, -8.218682439123249],
              [-39.979315517960693, -8.218682572628307],
              [-39.979315581251051, -8.218682705127835],
              [-39.9793156466908, -8.218682836586925],
              [-39.979315714262711, -8.218682966970977],
              [-39.979315783949033, -8.218683096245684],
              [-39.979315855731386, -8.218683224377031],
              [-39.979315929590896, -8.218683351331258],
              [-39.979316005508103, -8.218683477074951],
              [-39.97931608346304, -8.218683601575004],
              [-39.979316163435165, -8.218683724798687],
              [-39.979316245403453, -8.218683846713519],
              [-39.979316329346304, -8.218683967287404],
              [-39.979316415241634, -8.218684086488643],
              [-39.979316503066833, -8.218684204285823],
              [-39.979316592798774, -8.218684320647951],
              [-39.979316684413838, -8.218684435544425],
              [-39.979316777887917, -8.218684548944944],
              [-39.979316873196403, -8.218684660819674],
              [-39.979316828698245, -8.218684696047964],
              [-39.980118248581533, -8.219610047760369],
              [-39.980118250028681, -8.219610005878073],
              [-39.980118266472715, -8.219610024812773],
              [-39.980118282969308, -8.219610043702044],
              [-39.980118299518317, -8.219610062545739],
              [-39.980118316119629, -8.219610081343733],
              [-39.98011833277311, -8.219610100095867],
              [-39.980118349478637, -8.219610118801967],
              [-39.980118366236084, -8.219610137461949],
              [-39.980118383045323, -8.219610156075634],
              [-39.98011839990621, -8.219610174642895],
              [-39.980118416818641, -8.219610193163572],
              [-39.980118433782465, -8.219610211637528],
              [-39.980118450797562, -8.219610230064646],
              [-39.980118467863804, -8.219610248444754],
              [-39.980118484981048, -8.2196102667777],
              [-39.980118502149161, -8.219610285063391],
              [-39.980118519368034, -8.219610303301655],
              [-39.980118536637526, -8.219610321492373],
              [-39.980118553957489, -8.219610339635361],
              [-39.980118571327807, -8.219610357730538],
              [-39.980118588748347, -8.219610375777711],
              [-39.980118606218959, -8.219610393776787],
              [-39.980118623739529, -8.219610411727594],
              [-39.980118641309907, -8.219610429630016],
              [-39.980118658929975, -8.219610447483884],
              [-39.980118676599581, -8.21961046528911],
              [-39.980118694318598, -8.219610483045537],
              [-39.980118712086878, -8.219610500753038],
              [-39.980118729904312, -8.219610518411448],
              [-39.980118747770739, -8.219610536020655],
              [-39.980118765686036, -8.219610553580528],
              [-39.980118783650056, -8.219610571090907],
              [-39.98011880166267, -8.219610588551694],
              [-39.980118819723728, -8.219610605962716],
              [-39.980118837833103, -8.21961062332384],
              [-39.980118855990646, -8.219610640634945],
              [-39.980118874196229, -8.219610657895924],
              [-39.98011889244971, -8.21961067510661],
              [-39.98011891075096, -8.219610692266887],
              [-39.980118929099788, -8.219610709376635],
              [-39.980118947496116, -8.219610726435683],
              [-39.980118965939766, -8.21961074344396],
              [-39.980118984430618, -8.219610760401299],
              [-39.980119002968522, -8.219610777307592],
              [-39.980119021553321, -8.219610794162664],
              [-39.980119040184888, -8.219610810966412],
              [-39.980119058863089, -8.219610827718709],
              [-39.980119077587759, -8.219610844419435],
              [-39.980119096358784, -8.219610861068444],
              [-39.980119115175995, -8.219610877665643],
              [-39.980119134039242, -8.219610894210858],
              [-39.980119152948411, -8.219610910703972],
              [-39.980119171903318, -8.21961092714489],
              [-39.980119190903849, -8.219610943533461],
              [-39.980119209949848, -8.219610959869557],
              [-39.980119229041172, -8.219610976153067],
              [-39.980119248177665, -8.21961099238386],
              [-39.980119267359186, -8.219611008561797],
              [-39.980119286585591, -8.219611024686774],
              [-39.980119305856732, -8.219611040758682],
              [-39.98011932517246, -8.219611056777332],
              [-39.980119344532632, -8.21961107274268],
              [-39.980119363937099, -8.219611088654593],
              [-39.980119383385691, -8.219611104512881],
              [-39.980119402878273, -8.219611120317492],
              [-39.980119422414724, -8.21961113606827],
              [-39.980119441994844, -8.219611151765108],
              [-39.980119461618521, -8.219611167407894],
              [-39.980119481285584, -8.219611182996486],
              [-39.980119500995897, -8.219611198530774],
              [-39.980119520749291, -8.219611214010627],
              [-39.980119540545637, -8.219611229435969],
              [-39.980119560384772, -8.219611244806638],
              [-39.980119580266532, -8.219611260122537],
              [-39.980119600190783, -8.219611275383517],
              [-39.98011962015736, -8.21961129058951],
              [-39.980119640166116, -8.219611305740363],
              [-39.980119660216907, -8.219611320835995],
              [-39.980119680309556, -8.21961133587625],
              [-39.980119700443922, -8.219611350861028],
              [-39.980119720619861, -8.219611365790204],
              [-39.980119740837203, -8.219611380663679],
              [-39.9801197610958, -8.219611395481332],
              [-39.980119781395494, -8.219611410243074],
              [-39.98011980173613, -8.219611424948766],
              [-39.980119822117544, -8.219611439598317],
              [-39.980119842539601, -8.219611454191567],
              [-39.980119863002123, -8.21961146872845],
              [-39.980119883504969, -8.219611483208855],
              [-39.980119904047974, -8.219611497632625],
              [-39.980119924630984, -8.219611511999698],
              [-39.980119945253826, -8.219611526309949],
              [-39.980119965916366, -8.219611540563248],
              [-39.980119986618433, -8.219611554759531],
              [-39.980120007359858, -8.219611568898639],
              [-39.980120028140497, -8.219611582980496],
              [-39.980120048960195, -8.219611597004997],
              [-39.980120069818774, -8.219611610972006],
              [-39.980120090716106, -8.219611624881448],
              [-39.980120111651992, -8.219611638733189],
              [-39.98012006819846, -8.21961167128552],
              [-39.981325968210811, -8.220407063775122],
              [-39.981326005580918, -8.220407092595439],
              [-39.981326080529193, -8.220407142569218],
              [-39.981326154975456, -8.220407193282302],
              [-39.981326228912359, -8.220407244729687],
              [-39.981326302332604, -8.220407296906284],
              [-39.98132637522896, -8.22040734980696],
              [-39.981326447594206, -8.220407403426488],
              [-39.981326519421209, -8.220407457759583],
              [-39.981326590702899, -8.220407512800859],
              [-39.98132666143222, -8.220407568544918],
              [-39.981326731602195, -8.220407624986215],
              [-39.981326801205903, -8.220407682119216],
              [-39.981326870236472, -8.220407739938256],
              [-39.981326938687083, -8.220407798437662],
              [-39.981327006550991, -8.220407857611631],
              [-39.981327073821504, -8.220407917454327],
              [-39.98132714049197, -8.220407977959844],
              [-39.981327206555818, -8.220408039122223],
              [-39.98132727200651, -8.220408100935432],
              [-39.981327336837623, -8.220408163393351],
              [-39.981327401042712, -8.220408226489834],
              [-39.981327464615475, -8.220408290218643],
              [-39.981327527549624, -8.220408354573495],
              [-39.981327589838962, -8.220408419548034],
              [-39.981327651477315, -8.220408485135865],
              [-39.981327712458636, -8.220408551330474],
              [-39.981327772776872, -8.220408618125363],
              [-39.981327832426089, -8.220408685513906],
              [-39.981327891400397, -8.220408753489499],
              [-39.981327949693977, -8.220408822045414],
              [-39.981328007301066, -8.220408891174879],
              [-39.981328064215987, -8.220408960871081],
              [-39.981328120433133, -8.220409031127101],
              [-39.981328175946935, -8.220409101936092],
              [-39.98132823075192, -8.220409173290973],
              [-39.981328284842689, -8.220409245184751],
              [-39.981328338213906, -8.220409317610327],
              [-39.981328390860284, -8.220409390560546],
              [-39.981328442776643, -8.220409464028204],
              [-39.981328493957854, -8.220409538006033],
              [-39.98132854439887, -8.220409612486776],
              [-39.981328594094698, -8.220409687463061],
              [-39.98132864304047, -8.220409762927508],
              [-39.981328691231326, -8.220409838872627],
              [-39.981328738662491, -8.220409915290952],
              [-39.981328785329332, -8.220409992174956],
              [-39.981328831227209, -8.220410069517003],
              [-39.981328876351618, -8.220410147309487],
              [-39.981328920698083, -8.220410225544763],
              [-39.981328964262218, -8.220410304215044],
              [-39.981329007039761, -8.220410383312606],
              [-39.981329049026456, -8.220410462829662],
              [-39.981329090218189, -8.220410542758323],
              [-39.98132913061086, -8.220410623090695],
              [-39.981329170200517, -8.220410703818889],
              [-39.981329208983226, -8.220410784934925],
              [-39.981329246955177, -8.220410866430802],
              [-39.981329284112618, -8.220410948298454],
              [-39.98132932045187, -8.220411030529817],
              [-39.981329355969358, -8.220411113116771],
              [-39.981329390661585, -8.220411196051183],
              [-39.9813294245251, -8.220411279324829],
              [-39.981329457556598, -8.220411362929527],
              [-39.981329489752795, -8.220411446857021],
              [-39.981329521110524, -8.220411531099012],
              [-39.981329551626679, -8.220411615647214],
              [-39.98132958129824, -8.220411700493258],
              [-39.981329610122295, -8.220411785628761],
              [-39.981329638096014, -8.220411871045329],
              [-39.981329665216606, -8.220411956734546],
              [-39.98132969148142, -8.220412042687958],
              [-39.981329716887828, -8.220412128897063],
              [-39.98132974143337, -8.220412215353363],
              [-39.981329765115589, -8.220412302048342],
              [-39.981329787932161, -8.220412388973445],
              [-39.981329809880826, -8.22041247612005],
              [-39.981329830959439, -8.22041256347959],
              [-39.981329851165881, -8.220412651043427],
              [-39.981329870498193, -8.220412738802944],
              [-39.981329888954455, -8.220412826749453],
              [-39.981329906532849, -8.220412914874293],
              [-39.98132992323162, -8.220413003168748],
              [-39.981329939049154, -8.22041309162411],
              [-39.98132995398386, -8.220413180231672],
              [-39.981329968034288, -8.220413268982666],
              [-39.981329981199025, -8.220413357868315],
              [-39.981329993476798, -8.220413446879899],
              [-39.981330004866386, -8.220413536008587],
              [-39.981330015366659, -8.220413625245611],
              [-39.981330024976586, -8.220413714582143],
              [-39.981330033695215, -8.220413804009356],
              [-39.981330041521687, -8.220413893518467],
              [-39.981330048455234, -8.220413983100627],
              [-39.98133005449516, -8.22041407274698],
              [-39.981330059640896, -8.220414162448693],
              [-39.981330063891896, -8.220414252196914],
              [-39.981330067247768, -8.220414341982744],
              [-39.981330069708164, -8.220414431797378],
              [-39.981330071272858, -8.220414521631938],
              [-39.981330071941777, -8.22041461147734],
              [-39.981330072038681, -8.220414650900608],
              [-39.981331490142438, -8.221213070479518],
              [-39.980799029965731, -8.222680888512629],
              [-39.980799013115103, -8.222680846893285],
              [-39.980798999205994, -8.222680885508701],
              [-39.980798985472859, -8.222680924186593],
              [-39.980798971915974, -8.222680962926134],
              [-39.980798958535622, -8.222681001726562],
              [-39.980798945332083, -8.222681040587053],
              [-39.980798932305611, -8.222681079506799],
              [-39.98079891945649, -8.222681118485038],
              [-39.98079890678499, -8.222681157520929],
              [-39.980798894291347, -8.222681196613683],
              [-39.980798881975829, -8.222681235762504],
              [-39.980798869838708, -8.222681274966595],
              [-39.980798857880217, -8.22268131422514],
              [-39.980798846100605, -8.222681353537306],
              [-39.980798834500099, -8.222681392902285],
              [-39.980798823078956, -8.222681432319286],
              [-39.98079881183741, -8.222681471787515],
              [-39.98079880077568, -8.222681511306147],
              [-39.980798789894024, -8.222681550874338],
              [-39.980798779192632, -8.222681590491298],
              [-39.980798768671733, -8.222681630156217],
              [-39.980798758331552, -8.222681669868265],
              [-39.98079874817229, -8.222681709626659],
              [-39.980798738194167, -8.222681749430533],
              [-39.980798728397396, -8.222681789279108],
              [-39.980798718782175, -8.222681829171533],
              [-39.980798709348655, -8.222681869107024],
              [-39.980798700097104, -8.222681909084711],
              [-39.980798691027644, -8.222681949103796],
              [-39.980798682140531, -8.222681989163478],
              [-39.980798673435885, -8.222682029262906],
              [-39.980798664913927, -8.222682069401246],
              [-39.980798656574805, -8.222682109577695],
              [-39.98079864841872, -8.222682149791401],
              [-39.980798640445812, -8.22268219004159],
              [-39.98079863265626, -8.222682230327377],
              [-39.980798625050213, -8.222682270647912],
              [-39.98079861762784, -8.222682311002446],
              [-39.980798610389286, -8.222682351390127],
              [-39.980798603334705, -8.222682391810038],
              [-39.980798596464247, -8.222682432261472],
              [-39.980798589778026, -8.222682472743521],
              [-39.980798583276226, -8.22268251325538],
              [-39.980798576958932, -8.222682553796165],
              [-39.980798570826302, -8.222682594365125],
              [-39.980798564878455, -8.222682634961391],
              [-39.98079855911552, -8.222682675584075],
              [-39.980798553537618, -8.222682716232441],
              [-39.98079854814484, -8.222682756905522],
              [-39.980798542937322, -8.22268279760261],
              [-39.980798537915156, -8.222682838322797],
              [-39.980798533078456, -8.222682879065275],
              [-39.980798528427307, -8.222682919829149],
              [-39.980798523961845, -8.222682960613678],
              [-39.980798519682097, -8.222683001417936],
              [-39.980798515588205, -8.22268304224113],
              [-39.980798511680227, -8.222683083082387],
              [-39.980798507958255, -8.222683123940875],
              [-39.98079850442236, -8.222683164815791],
              [-39.980798501072613, -8.222683205706256],
              [-39.980798497909078, -8.222683246611462],
              [-39.98079849493184, -8.222683287530497],
              [-39.980798492140956, -8.22268332846259],
              [-39.980798489536454, -8.222683369406877],
              [-39.98079848711842, -8.222683410362491],
              [-39.98079848488689, -8.222683451328647],
              [-39.980798482841912, -8.222683492304457],
              [-39.980798480983545, -8.222683533289075],
              [-39.980798479311787, -8.222683574281662],
              [-39.98079847782671, -8.222683615281388],
              [-39.980798476528328, -8.222683656287435],
              [-39.980798475416677, -8.222683697298905],
              [-39.980798474491777, -8.222683738315007],
              [-39.98079847375363, -8.22268377933483],
              [-39.980798473202263, -8.222683820357565],
              [-39.980798472837698, -8.222683861382411],
              [-39.980798472659927, -8.222683902408459],
              [-39.980798472668965, -8.222683943434886],
              [-39.980798472864812, -8.222683984460852],
              [-39.980798473247454, -8.222684025485506],
              [-39.98079847381689, -8.222684066507988],
              [-39.980798474573106, -8.22268410752752],
              [-39.980798475516089, -8.222684148543189],
              [-39.980798476645816, -8.222684189554158],
              [-39.980798477962267, -8.222684230559604],
              [-39.980798479465413, -8.222684271558666],
              [-39.980798481155219, -8.22268431255055],
              [-39.980798483031656, -8.222684353534346],
              [-39.980798485094688, -8.222684394509248],
              [-39.980798487344259, -8.222684435474429],
              [-39.980798489780355, -8.222684476428977],
              [-39.980798492402876, -8.222684517372144],
              [-39.980798495211808, -8.222684558302991],
              [-39.980798498207072, -8.22268459922075],
              [-39.980798501388627, -8.222684640124562],
              [-39.980798504756386, -8.222684681013551],
              [-39.980798508310293, -8.222684721886887],
              [-39.980798512050264, -8.222684762743786],
              [-39.98079851597624, -8.222684803583354],
              [-39.980798520088129, -8.222684844404721],
              [-39.980798495055325, -8.222684869270912],
              [-39.980934926295092, -8.224008518654403],
              [-39.980934897567401, -8.224008521071482],
              [-39.980934914787333, -8.224008675187664],
              [-39.980934934657, -8.224008828987065],
              [-39.980934957170561, -8.224008982424472],
              [-39.980934982321401, -8.224009135454796],
              [-39.980935010102115, -8.22400928803302],
              [-39.980935040504548, -8.224009440114294],
              [-39.980935073519767, -8.224009591653907],
              [-39.980935109138052, -8.224009742607342],
              [-39.980935147348923, -8.224009892930219],
              [-39.980935188141181, -8.224010042578309],
              [-39.980935231502805, -8.224010191507658],
              [-39.980935277421047, -8.224010339674484],
              [-39.980935325882427, -8.2240104870352],
              [-39.980935376872694, -8.224010633546497],
              [-39.980935430376846, -8.224010779165321],
              [-39.980935486379167, -8.224010923848853],
              [-39.980935544863193, -8.224011067554542],
              [-39.98093560581173, -8.224011210240169],
              [-39.980935669206858, -8.224011351863782],
              [-39.980935735029931, -8.224011492383744],
              [-39.980935803261623, -8.224011631758763],
              [-39.980935873881862, -8.224011769947817],
              [-39.980935946869877, -8.224011906910341],
              [-39.980936022204226, -8.224012042606049],
              [-39.980936099862767, -8.224012176995053],
              [-39.980936179822642, -8.224012310037836],
              [-39.980936262060382, -8.224012441695312],
              [-39.9809363465518, -8.22401257192875],
              [-39.980936433272056, -8.224012700699864],
              [-39.980936522195641, -8.224012827970814],
              [-39.980936613296421, -8.224012953704186],
              [-39.980936706547638, -8.224013077863008],
              [-39.98093680192185, -8.224013200410768],
              [-39.980936899391047, -8.224013321311475],
              [-39.980936998926538, -8.224013440529573],
              [-39.980937100499098, -8.224013558030009],
              [-39.980937204078849, -8.22401367377824],
              [-39.980937309635344, -8.224013787740239],
              [-39.980937417137575, -8.224013899882511],
              [-39.980937526553895, -8.22401401017208],
              [-39.980937637852165, -8.224014118576543],
              [-39.980937750999665, -8.224014225064025],
              [-39.980937865963135, -8.224014329603204],
              [-39.980937982708795, -8.22401443216339],
              [-39.980938101202277, -8.224014532714387],
              [-39.980938221408785, -8.224014631226655],
              [-39.980938343292969, -8.224014727671252],
              [-39.980938466819012, -8.224014822019788],
              [-39.98093859195059, -8.224014914244549],
              [-39.980938718650911, -8.224015004318431],
              [-39.980938846882744, -8.224015092214909],
              [-39.980938976608385, -8.224015177908218],
              [-39.980939107789702, -8.224015261373106],
              [-39.980939240388125, -8.224015342585066],
              [-39.980939374364659, -8.224015421520214],
              [-39.980939509679963, -8.224015498155353],
              [-39.98093964629421, -8.224015572467934],
              [-39.980939784167276, -8.224015644436124],
              [-39.980939923258603, -8.22401571403879],
              [-39.980940063527321, -8.224015781255456],
              [-39.980940204932175, -8.22401584606634],
              [-39.980940347431627, -8.224015908452404],
              [-39.980940490983748, -8.224015968395326],
              [-39.980940635546368, -8.224016025877463],
              [-39.980940781076981, -8.224016080881906],
              [-39.980940927532792, -8.224016133392508],
              [-39.980941074870778, -8.224016183393834],
              [-39.980941223047587, -8.224016230871156],
              [-39.980941372019686, -8.224016275810545],
              [-39.980941521743276, -8.224016318198776],
              [-39.980941672174332, -8.22401635802342],
              [-39.980941823268637, -8.224016395272729],
              [-39.980941974981789, -8.224016429935778],
              [-39.980942127269174, -8.224016462002362],
              [-39.980942280086019, -8.22401649146304],
              [-39.980942433387412, -8.224016518309188],
              [-39.980942587128261, -8.224016542532896],
              [-39.980942741263412, -8.224016564127043],
              [-39.980942895747525, -8.224016583085289],
              [-39.980943050535195, -8.22401659940204],
              [-39.980943205580907, -8.224016613072527],
              [-39.980943360839106, -8.224016624092693],
              [-39.980943516264126, -8.224016632459362],
              [-39.980943671810294, -8.224016638169998],
              [-39.980943827431879, -8.224016641222985],
              [-39.980943983083129, -8.22401664161738],
              [-39.980944138718279, -8.22401663935308],
              [-39.980944294291582, -8.224016634430754],
              [-39.980944449757317, -8.22401662685186],
              [-39.98094460506978, -8.2240166166186],
              [-39.980944760183291, -8.224016603734013],
              [-39.980944915052277, -8.224016588201888],
              [-39.980945069631183, -8.224016570026775],
              [-39.980945223874585, -8.224016549214017],
              [-39.98094537773715, -8.224016525769716],
              [-39.980945531173624, -8.224016499700806],
              [-39.980945684138902, -8.224016471014929],
              [-39.980945836588056, -8.224016439720497],
              [-39.980945988476215, -8.224016405826751],
              [-39.980946021264366, -8.22401645048962],
              [-39.982678069380732, -8.223614365965812],
              [-39.9826780328737, -8.22361433153648],
              [-39.982678098386856, -8.223614316582694],
              [-39.982678164009357, -8.223614302112527],
              [-39.982678229737587, -8.223614288126763],
              [-39.98267829556795, -8.22361427462619],
              [-39.982678361496838, -8.223614261611546],
              [-39.982678427520632, -8.223614249083507],
              [-39.982678493635724, -8.22361423704281],
              [-39.982678559838469, -8.223614225490058],
              [-39.982678626125249, -8.223614214425943],
              [-39.982678692492456, -8.22361420385101],
              [-39.982678758936409, -8.223614193765892],
              [-39.982678825453505, -8.223614184171103],
              [-39.982678892040063, -8.223614175067219],
              [-39.982678958692453, -8.223614166454666],
              [-39.98267902540703, -8.22361415833398],
              [-39.982679092180106, -8.223614150705597],
              [-39.982679159008065, -8.223614143569895],
              [-39.98267922588721, -8.223614136927301],
              [-39.982679292813884, -8.223614130778168],
              [-39.98267935978442, -8.22361412512284],
              [-39.982679426795144, -8.223614119961642],
              [-39.982679493842383, -8.22361411529482],
              [-39.982679560922485, -8.22361411112265],
              [-39.982679628031732, -8.22361410744535],
              [-39.982679695166482, -8.223614104263129],
              [-39.982679762323023, -8.22361410157616],
              [-39.982679829497705, -8.223614099384594],
              [-39.982679896686825, -8.22361409768855],
              [-39.98267996388671, -8.223614096488111],
              [-39.982680031093686, -8.223614095783379],
              [-39.982680098304044, -8.223614095574343],
              [-39.982680165514111, -8.223614095861047],
              [-39.982680232720213, -8.223614096643443],
              [-39.982680299918655, -8.223614097921535],
              [-39.982680367105758, -8.223614099695219],
              [-39.982680434277839, -8.223614101964413],
              [-39.982680501431219, -8.223614104728979],
              [-39.982680568562216, -8.223614107988793],
              [-39.982680635667137, -8.22361411174364],
              [-39.982680702742321, -8.223614115993328],
              [-39.982680769784089, -8.223614120737617],
              [-39.982680836788774, -8.223614125976276],
              [-39.982680903752673, -8.223614131708995],
              [-39.982680970672142, -8.223614137935465],
              [-39.9826810375435, -8.223614144655318],
              [-39.982681104363095, -8.223614151868219],
              [-39.982681171127261, -8.223614159573783],
              [-39.982681237832331, -8.223614167771565],
              [-39.982681304474646, -8.223614176461101],
              [-39.98268137105056, -8.223614185641932],
              [-39.982681437556437, -8.223614195313594],
              [-39.982681503988616, -8.223614205475489],
              [-39.982681570343452, -8.223614216127066],
              [-39.982681636617315, -8.223614227267817],
              [-39.982681702806566, -8.223614238897044],
              [-39.98268176890759, -8.223614251014158],
              [-39.982681834916747, -8.223614263618474],
              [-39.982681900830443, -8.223614276709313],
              [-39.982681966645039, -8.223614290285967],
              [-39.982682032356948, -8.223614304347668],
              [-39.982682097962545, -8.223614318893668],
              [-39.982682163458257, -8.22361433392315],
              [-39.982682228840481, -8.223614349435298],
              [-39.982682294105658, -8.223614365429242],
              [-39.982682359250163, -8.223614381904149],
              [-39.982682424270465, -8.223614398859088],
              [-39.98268248916299, -8.22361441629312],
              [-39.982682553924157, -8.223614434205331],
              [-39.982682618550463, -8.223614452594699],
              [-39.982682683038327, -8.223614471460239],
              [-39.982682747384239, -8.223614490800864],
              [-39.982682811584652, -8.223614510615594],
              [-39.982682875636051, -8.223614530903284],
              [-39.982682939534939, -8.223614551662852],
              [-39.982683003277806, -8.223614572893148],
              [-39.982683066861156, -8.223614594593014],
              [-39.982683130281501, -8.223614616761241],
              [-39.982683193535372, -8.223614639396667],
              [-39.982683256619296, -8.223614662497978],
              [-39.982683319529805, -8.223614686063977],
              [-39.982683382263488, -8.223614710093305],
              [-39.982683444816864, -8.223614734584688],
              [-39.982683507186536, -8.22361475953679],
              [-39.982683569369058, -8.223614784948204],
              [-39.982683631361041, -8.223614810817571],
              [-39.982683693159082, -8.223614837143471],
              [-39.982683754759805, -8.22361486392446],
              [-39.982683816159792, -8.223614891159048],
              [-39.982683877355733, -8.223614918845762],
              [-39.982683938344238, -8.223614946983096],
              [-39.982683999121974, -8.223614975569468],
              [-39.982684059685624, -8.223615004603344],
              [-39.982684120031848, -8.22361503408314],
              [-39.982684180157328, -8.223615064007225],
              [-39.982684240058795, -8.223615094373947],
              [-39.982684299732959, -8.223615125181643],
              [-39.982684359176538, -8.22361515642865],
              [-39.982684418386285, -8.223615188113227],
              [-39.982684477358937, -8.223615220233704],
              [-39.982684517119935, -8.223615254627234],
              [-39.98389210642592, -8.224278768659589],
              [-39.984298145216087, -8.225480382339194],
              [-39.985237840556074, -8.225077150812288],
              [-39.986444952745352, -8.224406186055962],
              [-39.987253410455352, -8.22533946167214],
              [-39.987524975695493, -8.226673536692452],
              [-40.020848036209216, -8.219955159786537],
              [-40.016496998991613, -8.211781249946357],
              [-40.013470481844777, -8.210368951183881],
              [-40.00642083762181, -8.204688816616242],
              [-40.027089125297643, -8.193421982151561],
              [-40.009559760929001, -8.182296695376087],
              [-40.030130795539847, -8.176040517467175],
              [-40.049467669280268, -8.177130338861696],
              [-40.072091014436083, -8.168253091317936],
              [-40.075900244157069, -8.157771412023756],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0090000',
        uf: 'PE',
        nome_proje: 'PA BARRA DA PORTEIRA',
        municipio: 'CABROBO',
        area_hecta: '138.4718',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 4.0,
        data_de_cr: '06/07/2004',
        forma_obte: 'Confisco',
        data_obten: '06/07/2004',
        area_calc_: 138.6023,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.262882143188897, -8.335213081408265],
              [-39.263124222054003, -8.333919616898919],
              [-39.229058669206765, -8.349101318355569],
              [-39.166844833824811, -8.377557814560241],
              [-39.167337532737427, -8.378490999320949],
              [-39.228471810672907, -8.35073588332272],
              [-39.230021990489384, -8.349805032353618],
              [-39.231579519541349, -8.348874171174858],
              [-39.262882143188897, -8.335213081408265],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0012000',
        uf: 'PE',
        nome_proje: 'PA CALDEIRÃO DO PERIQUITO',
        municipio: 'FLORESTA',
        area_hecta: '687.0000',
        capacidade: 10.0,
        num_famili: 2.0,
        fase: 5.0,
        data_de_cr: '03/09/1991',
        forma_obte: 'Adjudicação',
        data_obten: '29/11/1989',
        area_calc_: 694.0963,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.927218320831599, -8.514502208904457],
              [-37.918192583983227, -8.512810510592839],
              [-37.909815031526222, -8.549285343512125],
              [-37.909769548652093, -8.549656462061664],
              [-37.905129749390042, -8.569436969016508],
              [-37.90452341290765, -8.574207180121043],
              [-37.905861513061566, -8.574178145854583],
              [-37.913693712458247, -8.57428048118039],
              [-37.914039380272897, -8.571612637581199],
              [-37.918745516332365, -8.551554785043621],
              [-37.918825953488422, -8.551189730977722],
              [-37.927218320831599, -8.514502208904457],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0223000',
        uf: 'PE',
        nome_proje: 'PA PASSAGEM DE AREIA',
        municipio: 'BELEM DE MARIA',
        area_hecta: '222.8200',
        capacidade: 21.0,
        num_famili: 21.0,
        fase: 3.0,
        data_de_cr: '10/05/2001',
        forma_obte: 'Desapropriação',
        data_obten: '25/10/1999',
        area_calc_: 204.6995,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.801636692947511, -8.643084693240061],
              [-35.796514702125741, -8.644864533172527],
              [-35.79192344138049, -8.646476733510152],
              [-35.791630548958857, -8.646636621063827],
              [-35.790139646328498, -8.64710096073081],
              [-35.787511541104386, -8.64800410034514],
              [-35.787415342770757, -8.648034255317993],
              [-35.786176827749614, -8.648461959068452],
              [-35.784344272433877, -8.649089049861226],
              [-35.783118506069542, -8.64956029226809],
              [-35.778490518863855, -8.648105313693156],
              [-35.778629649308272, -8.649831177998671],
              [-35.779103981776082, -8.653820060763607],
              [-35.779446629770028, -8.657690001501114],
              [-35.779513845619512, -8.657693849527513],
              [-35.77953464761859, -8.657695234204738],
              [-35.779560262776528, -8.657696764585314],
              [-35.779587152967146, -8.657698827792613],
              [-35.779611864208931, -8.657700906845262],
              [-35.779638110242452, -8.657701800180185],
              [-35.779667359621882, -8.65770366552265],
              [-35.779695975461188, -8.657705806521417],
              [-35.77972295710255, -8.657707959412859],
              [-35.779751022236397, -8.65770929125131],
              [-35.779778911101069, -8.657711347189851],
              [-35.779807252581904, -8.657713219124611],
              [-35.779833417777631, -8.657715468287456],
              [-35.779857939485204, -8.657716464498918],
              [-35.779893271734856, -8.657718285579035],
              [-35.779927361458874, -8.657724000772308],
              [-35.77995481960469, -8.657729222097908],
              [-35.779981821816037, -8.657734175686983],
              [-35.780010462870365, -8.657739749804669],
              [-35.780043019141011, -8.657746831403411],
              [-35.78007041701791, -8.657756209287026],
              [-35.780094343013388, -8.65776263084776],
              [-35.780121090106057, -8.657769935396541],
              [-35.780148474720498, -8.657777506356242],
              [-35.780175320567125, -8.657785894393962],
              [-35.780201162428675, -8.657793566922125],
              [-35.780225364774722, -8.657800528564216],
              [-35.780250649285406, -8.657806488479631],
              [-35.780277480532646, -8.657812888901116],
              [-35.780303490699218, -8.657818753183303],
              [-35.780329865348463, -8.657824795512324],
              [-35.780354326133491, -8.657829857910221],
              [-35.780376415109764, -8.65783340158962],
              [-35.780401232403541, -8.657837557888389],
              [-35.780424586457542, -8.657840279209262],
              [-35.780444944465025, -8.657843022325526],
              [-35.78046757219996, -8.657845748936721],
              [-35.780482746602473, -8.657847355201238],
              [-35.780500905094414, -8.657847313451063],
              [-35.78052449102389, -8.657844521688924],
              [-35.780572145217292, -8.657830441705453],
              [-35.780619086229855, -8.657805795887828],
              [-35.780640483780132, -8.657789377098798],
              [-35.780658340541876, -8.657772984079969],
              [-35.780688832629828, -8.657733911472702],
              [-35.780712897938933, -8.657697505789461],
              [-35.780732036220805, -8.657670171018346],
              [-35.780746861092403, -8.657648921120508],
              [-35.780761217424477, -8.657625686918481],
              [-35.780777279488802, -8.657599820132274],
              [-35.78078956823898, -8.657579401838516],
              [-35.780799944443245, -8.657558184308648],
              [-35.780810651283204, -8.657532537187075],
              [-35.780820637114665, -8.657507618122139],
              [-35.780828537453203, -8.657483075631962],
              [-35.780835885757476, -8.657457543297451],
              [-35.780841698611717, -8.65743310634611],
              [-35.780846054192253, -8.657408047537597],
              [-35.780850225540313, -8.657382628668932],
              [-35.780851510231265, -8.657359760626521],
              [-35.780850975149804, -8.657336363714469],
              [-35.780849087533497, -8.657314241545746],
              [-35.780845487538258, -8.657293848507415],
              [-35.780839158567197, -8.657272752513901],
              [-35.78083184418989, -8.657253470702321],
              [-35.780822467555637, -8.657237727560942],
              [-35.780806328067847, -8.657215980133946],
              [-35.78078843703922, -8.657190631432686],
              [-35.780770280949135, -8.657166278510376],
              [-35.780752937312059, -8.657141287215101],
              [-35.780735515500567, -8.657118013149724],
              [-35.780716988962624, -8.657092669065699],
              [-35.780698547905878, -8.657066601547511],
              [-35.780681470007309, -8.657040704810763],
              [-35.780665681080748, -8.657017238159384],
              [-35.780648700622258, -8.656992244221854],
              [-35.780632081329095, -8.656966976595761],
              [-35.780616101540389, -8.656942246426084],
              [-35.780599939504214, -8.656917427221677],
              [-35.780583775488481, -8.656892336988726],
              [-35.780567968655774, -8.65686643099512],
              [-35.780553425572805, -8.656839521954527],
              [-35.780540010448398, -8.656817845037232],
              [-35.780523292419659, -8.656791493917387],
              [-35.780508649263837, -8.656763320689167],
              [-35.780494575397604, -8.656738486294222],
              [-35.780479295391174, -8.656710136986808],
              [-35.780464639633557, -8.656680247192478],
              [-35.780450053430023, -8.656647465656951],
              [-35.780438162364007, -8.656610779446849],
              [-35.780418901474384, -8.656571978421447],
              [-35.780411017724745, -8.656536979722258],
              [-35.780411721758647, -8.656496858924379],
              [-35.780419420519038, -8.656457229347998],
              [-35.78043335583267, -8.656413759679948],
              [-35.780449287834429, -8.656370185147143],
              [-35.780478808821876, -8.656310158297945],
              [-35.780503238589176, -8.656273930705945],
              [-35.780519758579352, -8.656248602720753],
              [-35.780540731187195, -8.656223784447738],
              [-35.780567149137674, -8.656198655513254],
              [-35.780593928370841, -8.65618563094052],
              [-35.780629849393364, -8.656168745130433],
              [-35.7806528320856, -8.65615800693617],
              [-35.780683311748916, -8.656141973865953],
              [-35.780715343438132, -8.656127104065403],
              [-35.780747208157834, -8.65611422319083],
              [-35.780777354524979, -8.656102258316624],
              [-35.780806504200456, -8.656090571741851],
              [-35.780835920265019, -8.65607807007579],
              [-35.780866421149383, -8.656064928055804],
              [-35.780897281874637, -8.656051331674313],
              [-35.780929868901445, -8.656037903423432],
              [-35.780960198811592, -8.656026208262654],
              [-35.780988532041292, -8.656014617967363],
              [-35.781018855318088, -8.65600201934053],
              [-35.781047555678498, -8.655990968472004],
              [-35.781073352122412, -8.655980119438254],
              [-35.781099962348705, -8.655968812722719],
              [-35.781127022540566, -8.655956960631098],
              [-35.781156079407467, -8.655945003670059],
              [-35.781183057426638, -8.655934326725452],
              [-35.781207857181379, -8.655923755986933],
              [-35.781230296431673, -8.655913202412538],
              [-35.781253807880283, -8.655900291932078],
              [-35.781282659926816, -8.65588517417164],
              [-35.781306819509929, -8.655873975635206],
              [-35.781323180563639, -8.655864098732254],
              [-35.781341865608788, -8.655849235652342],
              [-35.781364976057795, -8.655831178090418],
              [-35.78138645696405, -8.655813764844449],
              [-35.781411359947164, -8.655792531983407],
              [-35.781440354396203, -8.655772082493765],
              [-35.781465568903769, -8.655756178040638],
              [-35.781491768147013, -8.655738369059327],
              [-35.78152262481855, -8.655724230561766],
              [-35.78154544379295, -8.655715932983528],
              [-35.781570709407738, -8.65570698515049],
              [-35.781599974363814, -8.655698640665861],
              [-35.781624350018191, -8.655692138759312],
              [-35.781655614310196, -8.655684050782229],
              [-35.781684367725966, -8.655680227538873],
              [-35.781711386198864, -8.65567506165741],
              [-35.781736967321116, -8.655671984304529],
              [-35.78175927872438, -8.655668750034192],
              [-35.781790197781163, -8.65566310402199],
              [-35.781821507208974, -8.655661159530606],
              [-35.781850004178061, -8.655659506553707],
              [-35.781881048542616, -8.655658557846763],
              [-35.781909196963944, -8.655658895113472],
              [-35.781933523644412, -8.655658085638841],
              [-35.781958659440697, -8.655656186064125],
              [-35.781987781972056, -8.655653173273191],
              [-35.782019274296957, -8.655651408135276],
              [-35.782047500887458, -8.655650028171118],
              [-35.782076361010766, -8.655648372534941],
              [-35.782107950760434, -8.655647510190889],
              [-35.782138825492297, -8.655648189008676],
              [-35.78216906735382, -8.655649233830298],
              [-35.782202494783448, -8.655651339665798],
              [-35.782236395409207, -8.655656062224827],
              [-35.782268398099426, -8.655661973145229],
              [-35.782291222535257, -8.65566677631371],
              [-35.782388037280121, -8.655695435592191],
              [-35.782510279168562, -8.655749478964198],
              [-35.782553544193689, -8.655792984035076],
              [-35.782577001866194, -8.655822177197894],
              [-35.782595804104673, -8.655847970925885],
              [-35.782613152390084, -8.655873594535812],
              [-35.782629861185754, -8.655898680689884],
              [-35.782645839685337, -8.655923230067383],
              [-35.782662826818452, -8.655949127351997],
              [-35.782678911386185, -8.655975754005798],
              [-35.78269518151766, -8.656002921407644],
              [-35.78271018792384, -8.656031091867156],
              [-35.782723356634811, -8.656056294142696],
              [-35.782737341121837, -8.656081309769389],
              [-35.782751610594765, -8.656108039989267],
              [-35.782765703142978, -8.656135403937229],
              [-35.782778597514927, -8.656160337146492],
              [-35.782790740999907, -8.656181932860662],
              [-35.782805158184708, -8.656204054121538],
              [-35.782820404429472, -8.656227795655413],
              [-35.782836279551589, -8.656250629100297],
              [-35.782852798827747, -8.656274632413821],
              [-35.782870420857684, -8.656300434707314],
              [-35.78288686861282, -8.656327058698695],
              [-35.782902126160906, -8.656352336103383],
              [-35.782917015899891, -8.656376983725384],
              [-35.782933280093623, -8.656403337998997],
              [-35.782947910766595, -8.656429794514267],
              [-35.782960137754586, -8.656450395749836],
              [-35.7829758213568, -8.656471875327199],
              [-35.78299271244218, -8.656497050472025],
              [-35.783008586260465, -8.656519703221271],
              [-35.783025092286124, -8.65654189960703],
              [-35.783040219210299, -8.656561756914023],
              [-35.783058161909025, -8.656581774427226],
              [-35.783078216662425, -8.65660502917963],
              [-35.783096728009291, -8.65662829516803],
              [-35.783114502423892, -8.656650120911305],
              [-35.783132551871709, -8.656672306050549],
              [-35.783149220879203, -8.65669197142131],
              [-35.78316706748771, -8.65671126682515],
              [-35.783187283911332, -8.656731809871918],
              [-35.783208420832963, -8.656754062882506],
              [-35.783226997748713, -8.656773895063905],
              [-35.783247024621303, -8.656793355276537],
              [-35.783267514747287, -8.656814077032315],
              [-35.783285807336931, -8.656832284970243],
              [-35.783305455118004, -8.656849579538312],
              [-35.783325285150724, -8.656866963118647],
              [-35.78334702373224, -8.656884603858245],
              [-35.783370316355601, -8.656903678888225],
              [-35.78339379985907, -8.656924017425952],
              [-35.783414906250599, -8.656942114513358],
              [-35.783436188905483, -8.656959487515406],
              [-35.783458290650039, -8.656977125600664],
              [-35.783481672079112, -8.656995928923592],
              [-35.783504964050813, -8.657014913586767],
              [-35.783527160576277, -8.657033093084705],
              [-35.783549630806526, -8.657051451278287],
              [-35.783570545668624, -8.657068194488586],
              [-35.783593462535485, -8.657085555575724],
              [-35.783616747873104, -8.657103636777096],
              [-35.783639395692198, -8.657121451578499],
              [-35.783661857958798, -8.657138725611368],
              [-35.783681764846826, -8.657154211254019],
              [-35.783702111733021, -8.657167796332073],
              [-35.783722998034513, -8.657180654671071],
              [-35.783743416441375, -8.657191619066989],
              [-35.783765644644241, -8.657201757121232],
              [-35.783788049764048, -8.657211261435478],
              [-35.783809986997454, -8.657218871797662],
              [-35.783832186628644, -8.65722512498864],
              [-35.783854919693312, -8.657229838334393],
              [-35.783879826379852, -8.657233813046874],
              [-35.783904816535859, -8.657236793300072],
              [-35.783930342127185, -8.657238504743066],
              [-35.783947152667807, -8.65724037000497],
              [-35.783970389260745, -8.657239477960365],
              [-35.783995000322449, -8.657240292551771],
              [-35.784025149489075, -8.657241066810409],
              [-35.784055374822898, -8.657239852803965],
              [-35.784094115029291, -8.657235956604044],
              [-35.784112487992275, -8.657228052647508],
              [-35.78416136171063, -8.657207006414803],
              [-35.784218308314166, -8.6571849075298],
              [-35.784273241606783, -8.657160654879256],
              [-35.784301572727657, -8.657148793317667],
              [-35.784387261836102, -8.65710904741348],
              [-35.784415952121513, -8.657096641120594],
              [-35.784444820002115, -8.657083691423798],
              [-35.784472695173335, -8.657071562115725],
              [-35.784500383457605, -8.657058711368368],
              [-35.784528431559949, -8.657045406239595],
              [-35.784557657266234, -8.657031731139519],
              [-35.784586429684303, -8.657018149681106],
              [-35.78461257654412, -8.657005581207551],
              [-35.784637355587371, -8.656992209539823],
              [-35.784665021913334, -8.656976377379133],
              [-35.784694142859891, -8.656960805669],
              [-35.784717745628868, -8.656947984676135],
              [-35.784739157514707, -8.656933553328308],
              [-35.784759928554713, -8.656918403849676],
              [-35.784780231693105, -8.656901360431704],
              [-35.784798894650912, -8.65688351579624],
              [-35.784816000904797, -8.65686387550454],
              [-35.784833182665615, -8.656842156605988],
              [-35.784846840951403, -8.656822812476126],
              [-35.784861733045453, -8.65679839975401],
              [-35.784879608534595, -8.656772248629059],
              [-35.784896865787708, -8.656748451123519],
              [-35.784909251695417, -8.656728935564175],
              [-35.78492278594954, -8.65670507483448],
              [-35.784937302917811, -8.656679038529456],
              [-35.784950481328714, -8.656656174241201],
              [-35.784960847283429, -8.65663360149439],
              [-35.784972818183505, -8.656607041641088],
              [-35.784984880544265, -8.6565805714714],
              [-35.784990685409618, -8.656567428380903],
              [-35.784990684744884, -8.65656733803587],
              [-35.784996579077699, -8.65655401360282],
              [-35.78501436110232, -8.656527501759198],
              [-35.785035086634103, -8.65649383079232],
              [-35.785095273168558, -8.656393826267202],
              [-35.785104607488172, -8.656379121512735],
              [-35.785121218552575, -8.656353883107895],
              [-35.78513469789371, -8.656334901689679],
              [-35.785155492533228, -8.656310626650415],
              [-35.785177338753016, -8.656281193988184],
              [-35.785208575769566, -8.656244735934004],
              [-35.785229174311993, -8.656193808995587],
              [-35.785239528942732, -8.656169700375623],
              [-35.785247703987785, -8.656145517287316],
              [-35.785261196991918, -8.656116055131152],
              [-35.785272503762499, -8.656085615056535],
              [-35.78528177624662, -8.656062508179559],
              [-35.785293136852232, -8.656039386078644],
              [-35.785305556350671, -8.65601210015973],
              [-35.785313573081993, -8.655991080488809],
              [-35.785324750779253, -8.655967779021188],
              [-35.785338248437107, -8.655938949290016],
              [-35.785352487677272, -8.655912192211092],
              [-35.785366630809804, -8.655884713033691],
              [-35.785381853432682, -8.655855870733813],
              [-35.785394378990567, -8.655830662089404],
              [-35.785407902558028, -8.655805355832202],
              [-35.78542150694021, -8.655778693729419],
              [-35.785434590525881, -8.655755288025121],
              [-35.785442892907419, -8.655736073279755],
              [-35.785450091209071, -8.655714878864716],
              [-35.78545636433865, -8.655691342095125],
              [-35.785461626826617, -8.655666186374452],
              [-35.785466325982775, -8.655638504953917],
              [-35.785471542609841, -8.655607115401127],
              [-35.785478219828093, -8.655576799413],
              [-35.785483823538037, -8.655548659652572],
              [-35.785489773114783, -8.655518168267077],
              [-35.785496625259952, -8.655486947497362],
              [-35.785503310445172, -8.655457715650007],
              [-35.78551025537179, -8.655426765263165],
              [-35.785519820521316, -8.655394079128124],
              [-35.785531554622452, -8.65536002193249],
              [-35.785546383491749, -8.655327026394094],
              [-35.785563399250975, -8.655295099119082],
              [-35.785582247381804, -8.65526541725648],
              [-35.785599766268646, -8.655240172239688],
              [-35.785618914687646, -8.655214282891055],
              [-35.785639882191248, -8.655188832045397],
              [-35.785658963741, -8.655166195795729],
              [-35.785680207591341, -8.655141285034787],
              [-35.785702995503421, -8.655116453381147],
              [-35.78572679138405, -8.655092879279762],
              [-35.785748061818218, -8.655071582335109],
              [-35.785770045919897, -8.655048563533825],
              [-35.785796564100885, -8.655024788893902],
              [-35.785822102229197, -8.655003551198845],
              [-35.785848275203065, -8.654982218526973],
              [-35.785876815309344, -8.65496177211187],
              [-35.785905002234195, -8.65494268352556],
              [-35.785934283270002, -8.654924219404656],
              [-35.785965478155504, -8.654906735201596],
              [-35.785994153580546, -8.654892341259684],
              [-35.786020926452544, -8.654878503279832],
              [-35.786051504431526, -8.65486355336232],
              [-35.786081548975709, -8.654850143295279],
              [-35.786110867225574, -8.654836738510452],
              [-35.786141178817061, -8.654822603686421],
              [-35.786174563229444, -8.65480654912094],
              [-35.786209500344228, -8.65479174813467],
              [-35.786241458744499, -8.654779317958331],
              [-35.786274244203355, -8.6547682369992],
              [-35.786307494900761, -8.654758688611615],
              [-35.786343847683142, -8.654751195667249],
              [-35.786371334490902, -8.654748013786087],
              [-35.786401641704522, -8.654745624504152],
              [-35.786431942276714, -8.654742331770542],
              [-35.786462804852277, -8.654741384041774],
              [-35.786492923181989, -8.654738002273042],
              [-35.786526686984487, -8.654736491294894],
              [-35.786560086305698, -8.654734802261867],
              [-35.786594407465401, -8.654735003870076],
              [-35.7866257312972, -8.654735046613816],
              [-35.78673861194055, -8.654738470324691],
              [-35.786851567079033, -8.654727347076731],
              [-35.786883336860434, -8.65472630235177],
              [-35.786914191450336, -8.654724270439436],
              [-35.78694722428272, -8.654722132296175],
              [-35.786980804499031, -8.65472035156785],
              [-35.787013753857252, -8.6547192078837],
              [-35.787045787349527, -8.65471698666755],
              [-35.787079815513643, -8.654714389508628],
              [-35.787113749563382, -8.654711341284266],
              [-35.787147225692756, -8.654707754297057],
              [-35.787181330672524, -8.654703259216287],
              [-35.787215432990052, -8.654698402753262],
              [-35.787250984597435, -8.654693083963764],
              [-35.787286900673834, -8.654687943222548],
              [-35.787321362818808, -8.654682632378938],
              [-35.787356276229367, -8.654676956843607],
              [-35.787391642253752, -8.65467109729701],
              [-35.787428371426984, -8.654665408517287],
              [-35.787467191378617, -8.654660065889241],
              [-35.787504751603066, -8.654656268387326],
              [-35.787541674983331, -8.654652294834939],
              [-35.787576612337901, -8.654649871704752],
              [-35.787608352822261, -8.654644851731026],
              [-35.787644445796985, -8.654639077221315],
              [-35.787679622921829, -8.65463222518124],
              [-35.78771634475158, -8.654625542574218],
              [-35.787750972481611, -8.654618062077507],
              [-35.787783698321846, -8.654611227903116],
              [-35.787814235940104, -8.654603144787082],
              [-35.787848574669688, -8.654593407639181],
              [-35.787884373312664, -8.654584653698377],
              [-35.787916191261331, -8.654577826134615],
              [-35.787945458503884, -8.65456984261413],
              [-35.787972822536339, -8.654562324718846],
              [-35.787998808771476, -8.654552648477356],
              [-35.788031050054293, -8.654541661706821],
              [-35.78806039676617, -8.65453214165537],
              [-35.78808355793339, -8.654521040398537],
              [-35.788106890037582, -8.654508492299652],
              [-35.788127393077126, -8.654493977120627],
              [-35.788144350733575, -8.654478855348698],
              [-35.788209507617232, -8.654425164007323],
              [-35.788283111513763, -8.65438845378257],
              [-35.788319123649977, -8.654379455727394],
              [-35.788356896124249, -8.654377676741277],
              [-35.788423155611731, -8.654377617769288],
              [-35.787374028201334, -8.651772432091965],
              [-35.789854433670804, -8.651484832744433],
              [-35.790849862114698, -8.653565285082653],
              [-35.791665122974315, -8.65349654079602],
              [-35.791970090312198, -8.653471455692648],
              [-35.7919853408387, -8.653495738783478],
              [-35.791989176551972, -8.65349878266313],
              [-35.791995733213717, -8.653501445286061],
              [-35.792000399331769, -8.653506290107494],
              [-35.792006248352088, -8.653511487688776],
              [-35.792010476524489, -8.653518504092792],
              [-35.792017969034049, -8.65352495457326],
              [-35.792031294585598, -8.653534434347923],
              [-35.792036802434488, -8.653542615966991],
              [-35.792043731579994, -8.653546540759999],
              [-35.792048397033028, -8.653551295235959],
              [-35.792051619880958, -8.653557415486459],
              [-35.792058082241063, -8.653571914617839],
              [-35.792058340417398, -8.653582302955051],
              [-35.792059214800176, -8.653590066651914],
              [-35.792062255126126, -8.653657626093821],
              [-35.792062106862296, -8.653662144662913],
              [-35.792063528622563, -8.653670265760027],
              [-35.792066304866381, -8.653677383123274],
              [-35.792068608522506, -8.6536819741371],
              [-35.792067257186538, -8.653695717171916],
              [-35.79206708408099, -8.653709180554857],
              [-35.792068858339107, -8.653715853487949],
              [-35.792071169323911, -8.653721438300218],
              [-35.792077852085889, -8.653728888533953],
              [-35.792088929276495, -8.653741275919895],
              [-35.792091883764272, -8.653747940225154],
              [-35.792147951217153, -8.65381610629287],
              [-35.792173994984104, -8.653826577392207],
              [-35.792179908149635, -8.653828160507681],
              [-35.792219744744763, -8.653849914951062],
              [-35.792229922069858, -8.653863393094129],
              [-35.792237142896155, -8.653869935900342],
              [-35.792242108042316, -8.653878392515773],
              [-35.792248071835303, -8.653886841847049],
              [-35.7922524034636, -8.653895574136781],
              [-35.792258452722969, -8.65390330003974],
              [-35.792264408521604, -8.653910665227652],
              [-35.792271904384222, -8.653917567421553],
              [-35.792276324801705, -8.653926028023289],
              [-35.7922783006182, -8.653935409971435],
              [-35.792284265088405, -8.653943949636977],
              [-35.792295560018452, -8.653948926739842],
              [-35.792305212789536, -8.653952831643004],
              [-35.792311333515798, -8.65395793688243],
              [-35.792317016298547, -8.65396521371345],
              [-35.792322885976475, -8.653973211974177],
              [-35.792327674873768, -8.653982392678417],
              [-35.792328935723909, -8.653993315786238],
              [-35.792331181913411, -8.654002424710944],
              [-35.792328004848542, -8.654014825828421],
              [-35.792331441262341, -8.654025281309798],
              [-35.792335681441678, -8.654033923915982],
              [-35.792337655937473, -8.654043125174113],
              [-35.792339344071472, -8.654050431178286],
              [-35.792343602907749, -8.654061603447698],
              [-35.792345212914789, -8.654070626668966],
              [-35.792347427619269, -8.654087776941983],
              [-35.792345908706402, -8.654103418544032],
              [-35.792345612855037, -8.654112546033728],
              [-35.792343684819912, -8.654121956491876],
              [-35.792346393607978, -8.65413223657904],
              [-35.792351267965529, -8.654140693863237],
              [-35.792383594083354, -8.654153558498074],
              [-35.792392762275391, -8.654153310839424],
              [-35.792404642090361, -8.654151417078939],
              [-35.79244930896396, -8.654138712954303],
              [-35.792463565904796, -8.654139060581945],
              [-35.792490596204786, -8.654160185711129],
              [-35.792525026425984, -8.654175203357012],
              [-35.792535036354593, -8.654178292490148],
              [-35.792544393440558, -8.654179037290652],
              [-35.792555400035383, -8.654181848095599],
              [-35.792574903320634, -8.654179537259838],
              [-35.792584157625015, -8.65417865652217],
              [-35.792592870548695, -8.65417823148521],
              [-35.792600406565157, -8.654178266790904],
              [-35.792609746991609, -8.654176752978326],
              [-35.792617106765128, -8.654177512374631],
              [-35.792625643436544, -8.654177811418883],
              [-35.792633268240593, -8.654177575027449],
              [-35.792638437790721, -8.654176814473555],
              [-35.792647344273441, -8.65417801431842],
              [-35.792653418360686, -8.654176795410789],
              [-35.79266140964404, -8.654177008088553],
              [-35.792670207356522, -8.654175769286661],
              [-35.792676199319587, -8.654175725519725],
              [-35.792684182606187, -8.654174854063598],
              [-35.792691175233422, -8.654175074044264],
              [-35.792700806678326, -8.654176087893836],
              [-35.792709168448418, -8.654177291715971],
              [-35.792717524878221, -8.654177772773323],
              [-35.792727141671421, -8.65417679903522],
              [-35.792734687007538, -8.654178099169654],
              [-35.792742869860248, -8.654179665695139],
              [-35.792751951261472, -8.654179960766596],
              [-35.792766394439155, -8.65418093946718],
              [-35.792790473735288, -8.654183564425395],
              [-35.792802098511345, -8.654184021615936],
              [-35.792825871459108, -8.654182040976711],
              [-35.792834775284554, -8.654182879439141],
              [-35.792842775230461, -8.654184266599794],
              [-35.792849401368194, -8.654184037499013],
              [-35.792856120292058, -8.654184078768253],
              [-35.792863034793825, -8.654186015962217],
              [-35.792872574127358, -8.654186849772442],
              [-35.792974562257001, -8.6541907126329],
              [-35.79298974972248, -8.654194125327653],
              [-35.793013905816771, -8.654194852374797],
              [-35.793035181362555, -8.654198943396365],
              [-35.793048885580198, -8.654198210838478],
              [-35.793095576914347, -8.654201483741197],
              [-35.793104291158187, -8.654201239390519],
              [-35.793117189614371, -8.654202048663524],
              [-35.793123564726898, -8.654204712577981],
              [-35.793130666125514, -8.654207371195477],
              [-35.793139504480848, -8.654211643410559],
              [-35.793148864905746, -8.654212839926663],
              [-35.793154599169391, -8.654214785736992],
              [-35.793161796687123, -8.65421816644583],
              [-35.793169811962443, -8.654221631529573],
              [-35.793179006809865, -8.654224997646281],
              [-35.793187293113014, -8.654228280050951],
              [-35.793197299710485, -8.654230917442508],
              [-35.793207764907983, -8.654234183928303],
              [-35.793217493146251, -8.654236010200279],
              [-35.79322987020965, -8.654239985521013],
              [-35.793239160501081, -8.654243983383616],
              [-35.793248185772917, -8.654248977033218],
              [-35.793287382262974, -8.654270103563423],
              [-35.793294583790598, -8.654274026335404],
              [-35.793303334694251, -8.654278750939946],
              [-35.793313393283412, -8.654288435218286],
              [-35.793330427159908, -8.654295990500888],
              [-35.79333937163598, -8.654302339976908],
              [-35.793386937770336, -8.654338041997184],
              [-35.793394859595885, -8.654341146351408],
              [-35.793401516395207, -8.65434507310956],
              [-35.793408080407985, -8.654348729488678],
              [-35.793420488137933, -8.654356860661595],
              [-35.793446674734724, -8.654374377877261],
              [-35.793451885610438, -8.654379218682367],
              [-35.793457278717639, -8.654384148514175],
              [-35.793463489582713, -8.654389162720168],
              [-35.793469404089983, -8.654390926499921],
              [-35.793475514169494, -8.654394586194082],
              [-35.79348747929965, -8.654404256544229],
              [-35.793491897082582, -8.654412355734687],
              [-35.793498495760218, -8.654420710037801],
              [-35.793553288101279, -8.654463677554048],
              [-35.793564422154162, -8.654471456620595],
              [-35.793570637021602, -8.654477012890213],
              [-35.793577228369315, -8.654484373402843],
              [-35.793585526012208, -8.654489191659444],
              [-35.793594645403843, -8.654494636356079],
              [-35.793601574568079, -8.654498561119093],
              [-35.793616257987694, -8.6545074887976],
              [-35.793624565630914, -8.654513662218694],
              [-35.793643935866911, -8.654530235380141],
              [-35.793651343641599, -8.654537499575772],
              [-35.793657414927168, -8.654548206817504],
              [-35.793663996944737, -8.654554302506458],
              [-35.793669935454226, -8.654559318697485],
              [-35.793675329242269, -8.654564338859728],
              [-35.793683450647251, -8.654569881204049],
              [-35.793693467920292, -8.654573964088547],
              [-35.793700760900975, -8.654577976530673],
              [-35.793705876986579, -8.654582275936622],
              [-35.793713987725567, -8.654586372752027],
              [-35.79372108714194, -8.654588760321724],
              [-35.793730724601254, -8.654590587240344],
              [-35.79374046417184, -8.654593949358622],
              [-35.793749319215948, -8.654600480181655],
              [-35.793756367837069, -8.654608289094105],
              [-35.793763589365575, -8.654614922202729],
              [-35.79378848430818, -8.654629647978608],
              [-35.793795617717386, -8.654636643127192],
              [-35.793801576230315, -8.654644369664165],
              [-35.793805058819629, -8.654648771345315],
              [-35.794018258790992, -8.654799091113986],
              [-35.794024730695178, -8.654802567452849],
              [-35.794036129783045, -8.654809350721045],
              [-35.79404151291083, -8.654812925365905],
              [-35.794051815221358, -8.654818722798703],
              [-35.794057089559836, -8.654819858800479],
              [-35.794064730359942, -8.654821790649935],
              [-35.79407875228717, -8.65482719950935],
              [-35.794083846385355, -8.654828517526397],
              [-35.794086487884059, -8.654829672760748],
              [-35.794090218168584, -8.654830729697494],
              [-35.794092407066159, -8.654832068947114],
              [-35.794100137995756, -8.654833909787804],
              [-35.794102688707625, -8.654835065694591],
              [-35.794108412313548, -8.654835565958518],
              [-35.794114506401861, -8.654837057365004],
              [-35.794133883444665, -8.654842246385149],
              [-35.794145358661609, -8.654847041401071],
              [-35.794163544805755, -8.654850793517934],
              [-35.794171268400959, -8.654851640569285],
              [-35.794177074125564, -8.654850965692198],
              [-35.794190520659818, -8.654852222661514],
              [-35.794194708880084, -8.654853818345348],
              [-35.794200443145719, -8.654855764126168],
              [-35.794203365683181, -8.654858091863197],
              [-35.794212290179615, -8.65486173097081],
              [-35.794217031123409, -8.654864406814022],
              [-35.794222412919432, -8.654867800766874],
              [-35.794227336103987, -8.654870565618095],
              [-35.794233434193615, -8.654872599088332],
              [-35.794241438151609, -8.654874528288371],
              [-35.794249712469991, -8.654876184455524],
              [-35.794271861819148, -8.654887948723372],
              [-35.794287154109185, -8.654893257930041],
              [-35.794301425728641, -8.654895593063634],
              [-35.79430932823081, -8.654896077407372],
              [-35.794354253300398, -8.654893761358444],
              [-35.794361157145815, -8.654894253000544],
              [-35.794367953524059, -8.654892486680145],
              [-35.794374300644499, -8.654891356096773],
              [-35.794377549645127, -8.654888712213209],
              [-35.794383435486289, -8.654886591151216],
              [-35.794391854044079, -8.654883186687979],
              [-35.794397673771527, -8.65488440904717],
              [-35.794421836440399, -8.654898326978149],
              [-35.794427332364862, -8.654904882323757],
              [-35.79443174940392, -8.654925178704104],
              [-35.794442926153565, -8.65493873979797],
              [-35.794448418077991, -8.65494475308614],
              [-35.794453997446077, -8.654950313980025],
              [-35.794460766389953, -8.654957131067059],
              [-35.794467983276178, -8.654963131745969],
              [-35.794474177489441, -8.654965887302325],
              [-35.794483375017137, -8.654969614764413],
              [-35.794491019163431, -8.654971998325157],
              [-35.794499029792064, -8.654974830961789],
              [-35.794500846207136, -8.654974908033498],
              [-35.794536522688773, -8.655011148496085],
              [-35.794563130545122, -8.655036522939701],
              [-35.794567245320415, -8.65504046824282],
              [-35.794568369442999, -8.655045158209852],
              [-35.794568508913883, -8.655051752707335],
              [-35.794569828615494, -8.655058338586425],
              [-35.794573057529284, -8.655065271896863],
              [-35.794577016064522, -8.655072651628613],
              [-35.794579522008355, -8.655080041978728],
              [-35.79458120886882, -8.655087167268789],
              [-35.794582891718797, -8.655093750484058],
              [-35.794584952397983, -8.655102318637258],
              [-35.794587459676151, -8.655109889675815],
              [-35.79459042289011, -8.655117728428921],
              [-35.79459310240572, -8.655124033310809],
              [-35.794594970840855, -8.655131157273329],
              [-35.794599458769454, -8.655136455094517],
              [-35.794604119602191, -8.655140577103387],
              [-35.79460887922508, -8.655145782589097],
              [-35.794616271022029, -8.655150878483939],
              [-35.794620933188952, -8.655155181181062],
              [-35.79462396451575, -8.65515994755475],
              [-35.794626732818585, -8.655165980739566],
              [-35.79463267201519, -8.655171087252679],
              [-35.794638175951, -8.655178726733354],
              [-35.794645029687203, -8.655184730052369],
              [-35.794651341369423, -8.655191098739474],
              [-35.794656661722072, -8.65519846851419],
              [-35.794661984075759, -8.655206109321657],
              [-35.794665390565513, -8.655212499237198],
              [-35.794669433898349, -8.655219065204491],
              [-35.79470974657977, -8.655256084897452],
              [-35.794721075587368, -8.655265669482462],
              [-35.794726197694622, -8.655270781966593],
              [-35.794737977303292, -8.655279911510858],
              [-35.794743833043611, -8.655286012472112],
              [-35.794747948490752, -8.655290048125099],
              [-35.794754701439111, -8.6552946969313],
              [-35.794762351595367, -8.655297893591554],
              [-35.794768467034054, -8.655302276019574],
              [-35.794773852841843, -8.655306212026737],
              [-35.794779693242702, -8.655310235059561],
              [-35.794784452879284, -8.655315440541528],
              [-35.79478666112368, -8.655319399778728],
              [-35.794791510871441, -8.655324514252621],
              [-35.794798452064704, -8.655330065182088],
              [-35.794805662951305, -8.655335252743047],
              [-35.794812596150251, -8.65533971954069],
              [-35.79481679971768, -8.655343393142628],
              [-35.794822116740924, -8.655350311182842],
              [-35.794826408427646, -8.655353622743757],
              [-35.794827965155875, -8.655355418354864],
              [-35.794832164721697, -8.65535854989084],
              [-35.794838738766082, -8.655363561408441],
              [-35.794846320805767, -8.655369830444654],
              [-35.794852613151527, -8.655373579133222],
              [-35.794858370113104, -8.655378596614945],
              [-35.79486512573218, -8.655383606804392],
              [-35.794871522872313, -8.655389252058574],
              [-35.79487737128845, -8.655394359229415],
              [-35.794882854555624, -8.655399198022289],
              [-35.794889516720289, -8.655403847497725],
              [-35.794893073440747, -8.655405989882901],
              [-35.794896369797257, -8.655409760465336],
              [-35.794900121432079, -8.655413708417425],
              [-35.794904047970945, -8.655416751599201],
              [-35.794908244202858, -8.655419431403505],
              [-35.794911889033443, -8.655421211747628],
              [-35.794913720124541, -8.655423276408754],
              [-35.794918460411751, -8.655425861886078],
              [-35.794922107919561, -8.655428003607101],
              [-35.794926034458726, -8.655431046788546],
              [-35.794931876205993, -8.655435250515387],
              [-35.794937085770094, -8.655439910600851],
              [-35.794941938856454, -8.655445476793012],
              [-35.79494440880125, -8.655447988528806],
              [-35.794946431471978, -8.655451407017168],
              [-35.794951633032198, -8.655454982971049],
              [-35.794954930056804, -8.655458843905956],
              [-35.794958582901295, -8.655461708380621],
              [-35.794963146951403, -8.655465017947867],
              [-35.794966182296626, -8.655470326382154],
              [-35.794971855802331, -8.655476338321037],
              [-35.794976791681442, -8.655480819717384],
              [-35.794981730219646, -8.655485662481718],
              [-35.794987110686563, -8.655488875730237],
              [-35.794992753518159, -8.655490731814188],
              [-35.795000302219755, -8.655492483623286],
              [-35.795008843575324, -8.655493415014082],
              [-35.795017197353488, -8.655493534642902],
              [-35.795023286115836, -8.655494303276221],
              [-35.79502973470013, -8.655494617523837],
              [-35.795037631207677, -8.655494288740819],
              [-35.795046723288856, -8.655496029255358],
              [-35.795053362785993, -8.655497607012718],
              [-35.795054914837301, -8.655498770203568],
              [-35.795056005624943, -8.655498942926441],
              [-35.795057191859975, -8.655499747404452],
              [-35.795061291970086, -8.655501705116134],
              [-35.795067583652333, -8.655505363446467],
              [-35.795073235156011, -8.655508394013459],
              [-35.795080433383802, -8.655511865016871],
              [-35.79508698675442, -8.655514075838166],
              [-35.795095182972759, -8.655517449195109],
              [-35.795102811788695, -8.655517754820863],
              [-35.795111177573965, -8.655519500644081],
              [-35.795116748288002, -8.655523887047021],
              [-35.795124572686497, -8.655526088574062],
              [-35.795132053949182, -8.655531003101926],
              [-35.79513772078721, -8.655536111594637],
              [-35.79514266133814, -8.655541225397744],
              [-35.795147785455434, -8.655546608905905],
              [-35.795152183943614, -8.655552088068816],
              [-35.795155413531631, -8.655559111713767],
              [-35.795159729238101, -8.655565675680423],
              [-35.795165582329794, -8.655571415254482],
              [-35.795173344627095, -8.655577502264872],
              [-35.795181174363535, -8.655580426553323],
              [-35.7951896496158, -8.655584701367546],
              [-35.795196310452276, -8.655589170137805],
              [-35.795203877825266, -8.655593451590049],
              [-35.795211903145294, -8.655598271788286],
              [-35.795220206163442, -8.655603812748749],
              [-35.795227784886691, -8.655609630052467],
              [-35.795236810199057, -8.655614623636158],
              [-35.795244292798834, -8.655619718849271],
              [-35.795249533722959, -8.655628625135082],
              [-35.795257302693706, -8.655635615594441],
              [-35.795265613042361, -8.655642150340206],
              [-35.795272748505603, -8.655649416478806],
              [-35.795279251786283, -8.655657138994558],
              [-35.795286929294825, -8.655664039763575],
              [-35.795293702935879, -8.655671489254582],
              [-35.795298397138247, -8.655680128480562],
              [-35.795303818313656, -8.655688852748646],
              [-35.795307881000838, -8.655698038696002],
              [-35.795312306845638, -8.655707221987704],
              [-35.795316187968211, -8.655716409253499],
              [-35.795319976293555, -8.655725326159443],
              [-35.795323320699922, -8.655735601557264],
              [-35.795326751881667, -8.655745334216698],
              [-35.795331367974711, -8.655755690655173],
              [-35.795339217062583, -8.655761234930775],
              [-35.795348688309524, -8.655765141059868],
              [-35.795357519376964, -8.655768419417203],
              [-35.795365896518184, -8.655771701093697],
              [-35.79537464146879, -8.655775612524083],
              [-35.79538348721394, -8.655780878462867],
              [-35.795392332292145, -8.655786054057153],
              [-35.795399726111555, -8.655791420962052],
              [-35.795407220049455, -8.655798052031196],
              [-35.795414997032509, -8.655806126616046],
              [-35.795423033701049, -8.6558124826685],
              [-35.795431155819529, -8.655818115293894],
              [-35.795438630410509, -8.655822126370348],
              [-35.795447853983546, -8.655829377233468],
              [-35.795456142998965, -8.655833020948169],
              [-35.795465890621621, -8.655837467146945],
              [-35.795474550791781, -8.655842192336191],
              [-35.795482475992124, -8.655845748370659],
              [-35.795492044709739, -8.655850557264291],
              [-35.795499535314249, -8.655856736609588],
              [-35.795504943153411, -8.655863653978928],
              [-35.795510712130444, -8.655870297650759],
              [-35.79551520741969, -8.655876589249667],
              [-35.795519788167667, -8.655882157421638],
              [-35.795525641260426, -8.65588789698687],
              [-35.795531388896975, -8.655891649633894],
              [-35.795536234658428, -8.655896222027312],
              [-35.7955400931043, -8.655902337575812],
              [-35.79554468051591, -8.655908809189549],
              [-35.79555007633796, -8.655914100352783],
              [-35.795555283255801, -8.655918399057274],
              [-35.795559944774801, -8.655922611392187],
              [-35.795564520177308, -8.655927456809527],
              [-35.795570287831097, -8.655933919791272],
              [-35.795575764432819, -8.655937855126655],
              [-35.795576964690603, -8.655940556830801],
              [-35.795581717664433, -8.655944858845524],
              [-35.795588481308158, -8.655950953155449],
              [-35.795593965258732, -8.655955882276759],
              [-35.795600904472025, -8.655961162151419],
              [-35.795608547973039, -8.655963455336869],
              [-35.795615464500955, -8.655965663489726],
              [-35.795620567955737, -8.655968246307687],
              [-35.795628736829947, -8.655967915517548],
              [-35.795636188748489, -8.65596885486708],
              [-35.795646806214577, -8.655968144772395],
              [-35.795652427702748, -8.655967109819388],
              [-35.795659402323331, -8.655964890422872],
              [-35.795664911005439, -8.655960874756607],
              [-35.795667768169828, -8.655954348698756],
              [-35.795669727462588, -8.655949184443969],
              [-35.795672961119983, -8.655944462623507],
              [-35.795676385692779, -8.655941004294055],
              [-35.795679900385437, -8.655937454965425],
              [-35.795684611991142, -8.65593615561033],
              [-35.795691682744966, -8.655934658312121],
              [-35.795698474464963, -8.655932259554616],
              [-35.795709353614733, -8.655930101950215],
              [-35.795714967094924, -8.655927982856982],
              [-35.795723778158759, -8.655928550868262],
              [-35.795729062511093, -8.655931042012586],
              [-35.795737331512228, -8.655931975375321],
              [-35.795741597189128, -8.65593176348812],
              [-35.795747137897756, -8.655932084361075],
              [-35.795752508375529, -8.655933942422465],
              [-35.795759689924324, -8.655935154794371],
              [-35.7957687606714, -8.655934004263601],
              [-35.795776288696075, -8.655932955364973],
              [-35.7957823601146, -8.655931375016067],
              [-35.795784709244415, -8.655929821891402],
              [-35.795790522314171, -8.655930140780738],
              [-35.795797791989891, -8.655930991111017],
              [-35.795806318672092, -8.655929934898717],
              [-35.795811237883576, -8.655932157665095],
              [-35.795817263212705, -8.655936631068338],
              [-35.795820826614388, -8.655939676892579],
              [-35.795826368657366, -8.655940178452726],
              [-35.795834179052022, -8.655940482732953],
              [-35.795839077569035, -8.655939904810685],
              [-35.795852668336487, -8.655936101077113],
              [-35.795855379288817, -8.655934364616591],
              [-35.795863813181597, -8.655933038034451],
              [-35.795867450688505, -8.655933824580185],
              [-35.795871648932675, -8.65593677541149],
              [-35.795877741036243, -8.655937995749335],
              [-35.795884831797629, -8.655939208773708],
              [-35.79589245060842, -8.655938159208823],
              [-35.795899782377511, -8.655935124054572],
              [-35.795922101417666, -8.655932973129115],
              [-35.79593135974276, -8.655932634364328],
              [-35.795947449146446, -8.655935227172321],
              [-35.795955455127825, -8.655937427359001],
              [-35.795963479126428, -8.655942066838598],
              [-35.795968947731936, -8.655944918027643],
              [-35.795976683345138, -8.655947391238545],
              [-35.795985320841453, -8.65594904470187],
              [-35.795992396921932, -8.655948270151958],
              [-35.796008111833217, -8.655949329753067],
              [-35.796014388180147, -8.655950910137143],
              [-35.796021748642502, -8.655951759798828],
              [-35.796027675187624, -8.655955149731625],
              [-35.796033346045625, -8.655960800270201],
              [-35.796037205166243, -8.655967006155077],
              [-35.796043498863952, -8.655970935496343],
              [-35.796048688435711, -8.655972885234281],
              [-35.796053508176719, -8.655973934168603],
              [-35.796060141666388, -8.655974698797817],
              [-35.796067595585434, -8.655975909171064],
              [-35.796072794499928, -8.655979123727146],
              [-35.79607535122846, -8.655981092713956],
              [-35.79608662555453, -8.655983268990484],
              [-35.796101757705379, -8.655979182926387],
              [-35.796112666222648, -8.65598100047262],
              [-35.796117768335179, -8.655983402594506],
              [-35.79612268687152, -8.655985535002916],
              [-35.796127146810193, -8.65598703832239],
              [-35.796132963883537, -8.655987899271201],
              [-35.796138246235593, -8.655990119367173],
              [-35.796142620717049, -8.655992346113011],
              [-35.796143810300954, -8.655993602298183],
              [-35.796147851665118, -8.655999897206275],
              [-35.796151961126306, -8.656003119721134],
              [-35.796153148031863, -8.656004014538656],
              [-35.796161903001376, -8.656009281118322],
              [-35.79616500312725, -8.65601106543129],
              [-35.796166919675308, -8.656012406656748],
              [-35.79616983688328, -8.65601401160959],
              [-35.796183884225371, -8.656022853455378],
              [-35.796187452292408, -8.656026531684281],
              [-35.796190466302974, -8.65602894907027],
              [-35.796194760675824, -8.656032621986116],
              [-35.796198322745838, -8.656035487117251],
              [-35.7962032459547, -8.656038251942478],
              [-35.79620853297925, -8.656041104446311],
              [-35.796214182493948, -8.65604386395839],
              [-35.796217384085352, -8.656047093122396],
              [-35.796220114380525, -8.656047976640533],
              [-35.796224574978332, -8.65604957030274],
              [-35.796228224498897, -8.656051983048508],
              [-35.796232769210015, -8.656052672595759],
              [-35.796235222472362, -8.656052925696947],
              [-35.796244395349667, -8.656053310350226],
              [-35.796251213083778, -8.656054435023965],
              [-35.796257209062446, -8.656054933256515],
              [-35.796268293816425, -8.65605602671579],
              [-35.796270386600042, -8.656056643858957],
              [-35.796277203666762, -8.656057678188027],
              [-35.796281027420406, -8.656059095810051],
              [-35.796285978660322, -8.656065655097841],
              [-35.796288375840085, -8.65607060678642],
              [-35.796290773687254, -8.656075648810075],
              [-35.796297477254562, -8.656085899616732],
              [-35.796333107216768, -8.656152678284785],
              [-35.796334940311347, -8.656155013958946],
              [-35.796335330823609, -8.656158715430962],
              [-35.796334628553602, -8.656161973142961],
              [-35.796334752043926, -8.656166399369749],
              [-35.796334847502884, -8.656167031114714],
              [-35.796335944957043, -8.656168107283829],
              [-35.796337401564166, -8.656168638722269],
              [-35.796338493688069, -8.65616899212899],
              [-35.796339681929339, -8.656170067633932],
              [-35.796341878848956, -8.656172490995505],
              [-35.796347171891995, -8.656176156602857],
              [-35.796351545041524, -8.656178202647878],
              [-35.79635292553575, -8.656180722340007],
              [-35.796353606429861, -8.656186861120762],
              [-35.796354547680856, -8.656191371705068],
              [-35.796356659153005, -8.65619451849293],
              [-35.796359588376539, -8.656197749647477],
              [-35.796360326686958, -8.656199370538076],
              [-35.796360442835997, -8.656202802960928],
              [-35.796361458856246, -8.65620514462125],
              [-35.796362036282517, -8.656209567518051],
              [-35.796362973520161, -8.656213536037413],
              [-35.796363642401033, -8.656218048614209],
              [-35.796364137049402, -8.656223556314085],
              [-35.796365993513561, -8.656229054051417],
              [-35.796365755190848, -8.656233663613987],
              [-35.796365782546189, -8.656237367742568],
              [-35.796367643015031, -8.656243407544785],
              [-35.79636867304238, -8.65624764644142],
              [-35.796368687725767, -8.65624963402191],
              [-35.796368628975117, -8.656253971223675],
              [-35.796368580235914, -8.656259663596812],
              [-35.79636952748529, -8.656264987278442],
              [-35.79636883122258, -8.656269058096905],
              [-35.796368116930253, -8.656270689614171],
              [-35.796368705703259, -8.656276648370589],
              [-35.796369502747737, -8.65628621958299],
              [-35.796368006077593, -8.656292554992008],
              [-35.796368142917444, -8.656298788110782],
              [-35.796369932622724, -8.656307538910768],
              [-35.796372268376786, -8.656316466423091],
              [-35.796373482657643, -8.656321065358801],
              [-35.796376066087355, -8.656326648126628],
              [-35.796378367144207, -8.656330877715579],
              [-35.796381309718043, -8.65633591576168],
              [-35.796381512650321, -8.656338805464369],
              [-35.796381548024435, -8.656343593722667],
              [-35.796382490602525, -8.65634828499503],
              [-35.796383419831955, -8.656351169384275],
              [-35.796385533975354, -8.656354677548221],
              [-35.79638915278241, -8.656365221956339],
              [-35.796390808976554, -8.656368191376256],
              [-35.796392183474168, -8.656369897961429],
              [-35.796393223514144, -8.656375492020091],
              [-35.796394170106367, -8.656380725366253],
              [-35.796394520549576, -8.65639129368496],
              [-35.796395268881525, -8.656394269746572],
              [-35.796395028548311, -8.656398608276644],
              [-35.796395417727268, -8.656402129059988],
              [-35.796398989814229, -8.656406349349904],
              [-35.796408228767426, -8.656415678103798],
              [-35.796410328227665, -8.656417198687009],
              [-35.796411328897769, -8.656417462413309],
              [-35.796422517121414, -8.656420271748379],
              [-35.796425698694605, -8.656420790566383],
              [-35.796437333521183, -8.656422602794271],
              [-35.796441790119232, -8.656423654378274],
              [-35.796445983032889, -8.656425882446849],
              [-35.796457726668066, -8.656430133301837],
              [-35.796462371515233, -8.656432087016725],
              [-35.796558630998767, -8.656459300776806],
              [-35.796560273186394, -8.656460372950333],
              [-35.796561373988617, -8.65646190083895],
              [-35.796561566242765, -8.656463345025781],
              [-35.796560946743355, -8.65646551794385],
              [-35.796558886664307, -8.656469327693173],
              [-35.79655872511465, -8.656472039354902],
              [-35.796559201077606, -8.656475017408461],
              [-35.796560310548145, -8.656477719770976],
              [-35.796561805871114, -8.656483491195326],
              [-35.796563845917113, -8.656489258634267],
              [-35.796566789162362, -8.656494387021882],
              [-35.796569920656928, -8.656500417522356],
              [-35.796573038810784, -8.656504641130907],
              [-35.796581443998221, -8.656511717244049],
              [-35.796592398578127, -8.656519768552664],
              [-35.796594062117137, -8.656523731756463],
              [-35.796593905907891, -8.656527166180252],
              [-35.796593561449271, -8.65652969848213],
              [-35.796598110164084, -8.656543217617999],
              [-35.796598783722054, -8.656548362611222],
              [-35.796602991974972, -8.656564956122674],
              [-35.796604199585055, -8.656568651606049],
              [-35.796605024012237, -8.656569639422361],
              [-35.796607586087426, -8.656572331156481],
              [-35.796608140823125, -8.656573682342081],
              [-35.796608248298021, -8.656575940290285],
              [-35.796608823726501, -8.656580092153135],
              [-35.796608128802106, -8.656584343668666],
              [-35.79660816751764, -8.656589583646642],
              [-35.796610214898934, -8.65659634487951],
              [-35.796608899140416, -8.656602588615858],
              [-35.796608033989337, -8.656608377310196],
              [-35.796608604730849, -8.65662418428515],
              [-35.796607546657711, -8.656628438448651],
              [-35.796604897117831, -8.65663848662069],
              [-35.796604682820863, -8.656646348589533],
              [-35.796604605383955, -8.656648156145549],
              [-35.796601027279166, -8.656655410282879],
              [-35.796599335021526, -8.656659849784532],
              [-35.796597465862035, -8.656664923023643],
              [-35.796596057313693, -8.656670896391914],
              [-35.796595469864336, -8.656677405855692],
              [-35.796594399766732, -8.656680033815803],
              [-35.796592413119456, -8.656681493944674],
              [-35.796591146094613, -8.656682045309113],
              [-35.796589418458652, -8.656681696553417],
              [-35.796575464548724, -8.65667321544584],
              [-35.796570638782086, -8.656671353406168],
              [-35.796567911819444, -8.656670921612955],
              [-35.796566096066343, -8.656670934897944],
              [-35.796564923166173, -8.656671937318732],
              [-35.796562848401578, -8.656673759488267],
              [-35.796561326370281, -8.656676661811053],
              [-35.796559903803711, -8.656680737943404],
              [-35.796558922499599, -8.656683094206768],
              [-35.796558078031509, -8.656691683578723],
              [-35.796557751595564, -8.656696655190501],
              [-35.796556154797067, -8.656701726436912],
              [-35.796554115401086, -8.656708336873065],
              [-35.796552809654344, -8.656715935771688],
              [-35.796541964523108, -8.656734988505688],
              [-35.796535481874855, -8.656742354234121],
              [-35.796528602711383, -8.656745205394236],
              [-35.796524365063888, -8.656749211774082],
              [-35.796525516588439, -8.656757605853578],
              [-35.796525376390093, -8.656763208537008],
              [-35.796516705486709, -8.656781612911132],
              [-35.796511206153482, -8.656786893417506],
              [-35.796505690132499, -8.656789915302186],
              [-35.796498183458176, -8.656793855250623],
              [-35.796493039939989, -8.656798139305208],
              [-35.79648778560037, -8.656799713681657],
              [-35.796480975186171, -8.656799582811363],
              [-35.796460160787774, -8.656796482514045],
              [-35.796456622737203, -8.65679686979573],
              [-35.796453276282485, -8.656798610911073],
              [-35.796449137421568, -8.656803700765176],
              [-35.796439277592953, -8.656820939291068],
              [-35.796438037275927, -8.656825104439148],
              [-35.796437258228458, -8.656830260060831],
              [-35.796427897189659, -8.657492590004974],
              [-35.796430713611024, -8.657492840444799],
              [-35.796431813082116, -8.657494187644625],
              [-35.796432015350625, -8.657496986993639],
              [-35.796430231635206, -8.65750133681631],
              [-35.796430797720966, -8.657504223861396],
              [-35.796430381157009, -8.657509286473086],
              [-35.796429233630079, -8.657513721989924],
              [-35.796428370472022, -8.657519781717667],
              [-35.796428124143866, -8.657523307150258],
              [-35.796427594763713, -8.657525389060392],
              [-35.796427065392635, -8.657527470970447],
              [-35.796427543361212, -8.657530720056387],
              [-35.796425762315856, -8.65753543125569],
              [-35.796424720920719, -8.657541944041782],
              [-35.796424494618797, -8.657548179807797],
              [-35.796424525993238, -8.657552426000752],
              [-35.796423570712726, -8.657558305704498],
              [-35.796424513966699, -8.657563087319513],
              [-35.796424553351748, -8.657568417651246],
              [-35.796424044665464, -8.657573300247995],
              [-35.796423897802846, -8.657577999490226],
              [-35.796423473237624, -8.657581977972132],
              [-35.796423224906938, -8.657585232372305],
              [-35.796422618090233, -8.657589121838704],
              [-35.796422925832353, -8.657593908103671],
              [-35.796422242246678, -8.65759969547045],
              [-35.796423576029113, -8.6576081785563],
              [-35.796423820353397, -8.657616669622636],
              [-35.796423407127492, -8.657622183964133],
              [-35.796404907027799, -8.657784857975861],
              [-35.796705749363142, -8.657766484147036],
              [-35.801243836625794, -8.654576543069229],
              [-35.799513422815053, -8.651553483096562],
              [-35.798694275073146, -8.651721384076831],
              [-35.798393979122636, -8.651063398920474],
              [-35.797970069941954, -8.650177643178786],
              [-35.79697492724074, -8.647953922229492],
              [-35.797306828068351, -8.647777844278101],
              [-35.801668077108658, -8.645463897623447],
              [-35.801636692947511, -8.643084693240061],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0184000',
        uf: 'PE',
        nome_proje: 'PA BOA VISTA',
        municipio: 'SANTA CRUZ',
        area_hecta: '1233.7971',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '31/08/2006',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2006',
        area_calc_: 1234.3614,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.095156613323987, -8.297655191920121],
              [-40.09573943673238, -8.294936090028743],
              [-40.093431849028164, -8.295750947506717],
              [-40.080644627948573, -8.300415210652574],
              [-40.071745981348826, -8.33215194515757],
              [-40.082283745654735, -8.335015227134896],
              [-40.091293669648429, -8.322320462735682],
              [-40.093180849142215, -8.322990490537272],
              [-40.115691512879572, -8.328152615421132],
              [-40.116468302181758, -8.327149737209366],
              [-40.11651286826919, -8.32707934169628],
              [-40.117926728252577, -8.321244655278001],
              [-40.127888490736446, -8.32321382468889],
              [-40.128397690592408, -8.320671355101741],
              [-40.119030073115702, -8.316900352901131],
              [-40.119710042667414, -8.314393762862066],
              [-40.103232143184719, -8.306286878949804],
              [-40.095627644872138, -8.308913322601335],
              [-40.095156613323987, -8.297655191920121],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0259000',
        uf: 'PE',
        nome_proje: 'PA IRMÃ DOROTHY',
        municipio: 'SERRITA',
        area_hecta: '189.3854',
        capacidade: 14.0,
        num_famili: 14.0,
        fase: 3.0,
        data_de_cr: '17/07/2008',
        forma_obte: 'Desapropriação',
        data_obten: '17/04/2006',
        area_calc_: 235.4528,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.567114303198665, -7.951954478209938],
              [-39.55565470749508, -7.948791208588753],
              [-39.552206567183028, -7.960691966459302],
              [-39.561674782653554, -7.963242123064458],
              [-39.562045834677896, -7.963342112558459],
              [-39.563846818584743, -7.963827204081913],
              [-39.563565542554485, -7.965018927359242],
              [-39.563304923253064, -7.966122974611637],
              [-39.564380403940859, -7.966400827610531],
              [-39.566818188994496, -7.96703057478127],
              [-39.56747015562533, -7.967195752528831],
              [-39.567880328547844, -7.965459240877434],
              [-39.568051451039672, -7.964734759298668],
              [-39.568124969258001, -7.964423323647675],
              [-39.56812870670749, -7.96423843512677],
              [-39.568370583643656, -7.952305523436909],
              [-39.567114303198665, -7.951954478209938],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0366000',
        uf: 'PE',
        nome_proje: 'PA GOVERNADOR MIGUEL ARRAES',
        municipio: 'CATENDE',
        area_hecta: '23409.2322',
        capacidade: 4300.0,
        num_famili: 3858.0,
        fase: 4.0,
        data_de_cr: '15/12/2006',
        forma_obte: 'Desapropriação',
        data_obten: '13/10/2006',
        area_calc_: 23912.5185,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699773410961569, -8.621056254558932],
              [-35.706235754902792, -8.640235016292005],
              [-35.689653736758736, -8.62692705553672],
              [-35.688185877166291, -8.625212271518723],
              [-35.674959159225985, -8.623744034701813],
              [-35.662385599992547, -8.627106973247438],
              [-35.655539940605848, -8.624942443328569],
              [-35.650177559223572, -8.63572982135171],
              [-35.65664329917665, -8.63611555916922],
              [-35.656711394508505, -8.643478058981612],
              [-35.660967919869272, -8.648169981135871],
              [-35.651998563803375, -8.648005072530324],
              [-35.65032637498549, -8.648479381270013],
              [-35.650177554301749, -8.635729827935457],
              [-35.635840959522476, -8.637471216432408],
              [-35.638527495448585, -8.654899414712716],
              [-35.637959016539909, -8.659348085626705],
              [-35.637902322155568, -8.659550531931565],
              [-35.637848812149578, -8.659707908453312],
              [-35.63779047425335, -8.659879484056964],
              [-35.638138636878658, -8.660172613284821],
              [-35.638276528282198, -8.660220202846221],
              [-35.639869719984382, -8.660230921919526],
              [-35.641098085275864, -8.661105333991674],
              [-35.642553149259847, -8.661861454250507],
              [-35.642481518341611, -8.662716186942442],
              [-35.647368005430927, -8.66541378413266],
              [-35.649326404847628, -8.665684663829856],
              [-35.649511621138146, -8.665671912174803],
              [-35.649744616052608, -8.665922913504401],
              [-35.650170262352425, -8.666381453290581],
              [-35.651353284887605, -8.666537667831271],
              [-35.652525443027457, -8.667541054929558],
              [-35.655815702173342, -8.668601162907617],
              [-35.659288954733761, -8.670809941564348],
              [-35.659001913923255, -8.671851990545056],
              [-35.658253801059452, -8.675176330664277],
              [-35.658456234485328, -8.675401865428151],
              [-35.65832771869011, -8.676467921194748],
              [-35.658969282393713, -8.677039821354343],
              [-35.658875111724633, -8.677584808868996],
              [-35.65935625580304, -8.677844680286876],
              [-35.659368226774859, -8.677851145931633],
              [-35.661270128170621, -8.67655892282896],
              [-35.661790953789058, -8.676563321293241],
              [-35.663604096881144, -8.677608427643461],
              [-35.662446740025921, -8.683264799995957],
              [-35.660031180036718, -8.685879003078863],
              [-35.65793413252527, -8.687743610269793],
              [-35.661398960535287, -8.688155290994038],
              [-35.66257508906574, -8.687921265589067],
              [-35.662959340506312, -8.688754730567204],
              [-35.659154782892145, -8.693391716067007],
              [-35.658623698278198, -8.695628400189298],
              [-35.658502166905627, -8.695938836596229],
              [-35.65623001002546, -8.70167418200799],
              [-35.655271645601069, -8.700479739964628],
              [-35.655538611079017, -8.699257848600027],
              [-35.654015748101607, -8.696717527962017],
              [-35.652500869571455, -8.697116743172506],
              [-35.651471738501975, -8.697888319113526],
              [-35.649818549365193, -8.696762490140275],
              [-35.647019539657521, -8.69591644170151],
              [-35.645364748498309, -8.696383767183463],
              [-35.645257328427284, -8.696426766086583],
              [-35.644713465335649, -8.696317496850636],
              [-35.644602461471678, -8.694132726138863],
              [-35.64461179569782, -8.693854298804624],
              [-35.643362897559115, -8.681052897425449],
              [-35.640757992020127, -8.674907466495247],
              [-35.634323322142066, -8.672985807757263],
              [-35.624885470758095, -8.672062514496604],
              [-35.624588242195792, -8.666889783212616],
              [-35.625214545015119, -8.659145119996314],
              [-35.625196692341945, -8.658993218763086],
              [-35.6251869403565, -8.657338535119408],
              [-35.620692124290201, -8.649411129552341],
              [-35.617880974326134, -8.650091381990887],
              [-35.614997349601317, -8.649766987865936],
              [-35.610820595077918, -8.650550366149316],
              [-35.60690224117824, -8.649378384279132],
              [-35.606344179584745, -8.649929034176191],
              [-35.605892359097766, -8.654891667472988],
              [-35.607392442025422, -8.655550512094564],
              [-35.60820787125116, -8.656845609731205],
              [-35.607508769342246, -8.65882307476671],
              [-35.605935635580849, -8.660558827588414],
              [-35.60578979926548, -8.66300958059032],
              [-35.603884096248478, -8.667129563246402],
              [-35.604968660205003, -8.670192381864741],
              [-35.605542254658687, -8.670518859633553],
              [-35.605468730504839, -8.67074205122158],
              [-35.60596606659562, -8.672681858248085],
              [-35.601893533182306, -8.675121813761248],
              [-35.608120520496279, -8.683056151764209],
              [-35.608477496687335, -8.682956777110569],
              [-35.61049151303795, -8.687070012241669],
              [-35.61104935523818, -8.687787207332201],
              [-35.613584316310067, -8.690854827679036],
              [-35.614125007823986, -8.690876661722367],
              [-35.61454260872577, -8.69090608111294],
              [-35.615593407137858, -8.691039290468648],
              [-35.615771249251551, -8.691050469488228],
              [-35.615949579528333, -8.69105918266275],
              [-35.620860841308975, -8.691145194928664],
              [-35.625057930960416, -8.691218040389824],
              [-35.627415739406963, -8.691240233352117],
              [-35.627415514389924, -8.691532408282157],
              [-35.625053181212714, -8.691489076288837],
              [-35.620856048819149, -8.691416230976781],
              [-35.615940498557237, -8.691330144618773],
              [-35.615755979627963, -8.691321129104816],
              [-35.615684156303075, -8.691326927420555],
              [-35.615571006594102, -8.691309501888925],
              [-35.612133355570435, -8.690961056804955],
              [-35.61187376058232, -8.690945251136565],
              [-35.611738200974763, -8.690971175054987],
              [-35.616212641332666, -8.694330546860428],
              [-35.618103746488572, -8.695925440485237],
              [-35.609671883271673, -8.721870250113202],
              [-35.618378856624624, -8.719279483275072],
              [-35.623471846878594, -8.717606594879705],
              [-35.634297999443675, -8.714183842138349],
              [-35.63916927740442, -8.727400702550339],
              [-35.639234901588644, -8.727570836674598],
              [-35.638869397692794, -8.739402436453805],
              [-35.636610223126482, -8.740330771950754],
              [-35.635585234174535, -8.740873968900173],
              [-35.62728406376327, -8.740893157775812],
              [-35.622644798169439, -8.740095447348448],
              [-35.622400139532125, -8.740065045198824],
              [-35.616027212629952, -8.738235497353621],
              [-35.609955302377564, -8.736780262165436],
              [-35.6091887259035, -8.736483208554841],
              [-35.607516375791825, -8.736116194104564],
              [-35.602463386298766, -8.740261934986705],
              [-35.591784353295374, -8.744354445399214],
              [-35.584374561855476, -8.747006939903864],
              [-35.58170316336065, -8.748346890917839],
              [-35.57936144709717, -8.748605633122908],
              [-35.579005628802044, -8.751679472706277],
              [-35.578950709646513, -8.751777937435286],
              [-35.577888212349983, -8.752555453842959],
              [-35.57772579153967, -8.75278409798053],
              [-35.575545943978604, -8.756563980219472],
              [-35.57540844599103, -8.756937959124086],
              [-35.5753024701567, -8.757119789368817],
              [-35.575057918449922, -8.757431974461262],
              [-35.574963846457848, -8.757453534635747],
              [-35.566776560527963, -8.766144219070615],
              [-35.567466949382329, -8.766975536685747],
              [-35.571059288539466, -8.768636855801278],
              [-35.57144142429987, -8.769679590893],
              [-35.573507268119087, -8.770933462008298],
              [-35.57143675654811, -8.769706298968989],
              [-35.569826512138071, -8.778919803272441],
              [-35.569534904369064, -8.780973888597098],
              [-35.576465339620668, -8.783686887772117],
              [-35.576133915008256, -8.78957848666399],
              [-35.595559492066243, -8.794213299082491],
              [-35.590922311272024, -8.81355768530911],
              [-35.588207971971705, -8.825019889925541],
              [-35.589929739960837, -8.825474373114815],
              [-35.589929906183926, -8.826330164884224],
              [-35.587896452770728, -8.830143457072774],
              [-35.582694780943484, -8.840287207085266],
              [-35.582512861242378, -8.840633026673464],
              [-35.580527233902359, -8.844177347706708],
              [-35.580418341307585, -8.84444396624413],
              [-35.581738854666419, -8.846831224325598],
              [-35.585175986941508, -8.848098974786009],
              [-35.589576465773099, -8.851027337319916],
              [-35.595298901426418, -8.854549646072902],
              [-35.597550830877829, -8.850341340653934],
              [-35.599478474728912, -8.846794401719309],
              [-35.603068965296877, -8.840284167369692],
              [-35.608339738183936, -8.830708502034279],
              [-35.607501552822811, -8.8302116665204],
              [-35.607180837105581, -8.830045570319072],
              [-35.605997424972145, -8.829383391498798],
              [-35.60708621782792, -8.824511839951915],
              [-35.609364896326639, -8.81457303651702],
              [-35.611201890136961, -8.806462777471319],
              [-35.613118848734032, -8.798254464900308],
              [-35.606114159483134, -8.796610468495176],
              [-35.611696749140805, -8.776339229743902],
              [-35.612926176265283, -8.770626396335791],
              [-35.614556141861868, -8.76499147214912],
              [-35.614845710073915, -8.764052325671972],
              [-35.615674038645288, -8.761264257870906],
              [-35.615697191763708, -8.761225089690772],
              [-35.615925164822528, -8.761436682225657],
              [-35.616168950581972, -8.761658223495633],
              [-35.616609472087951, -8.762120688412454],
              [-35.616715433942481, -8.762178933490297],
              [-35.616894081067564, -8.762159678037909],
              [-35.617192044289943, -8.761945924220523],
              [-35.617515870561903, -8.761687325418247],
              [-35.617720819473767, -8.761574318159708],
              [-35.61805991733145, -8.761435613249708],
              [-35.618205275753517, -8.761355557855842],
              [-35.618406718251329, -8.761198460195642],
              [-35.618581482292193, -8.761022996169205],
              [-35.61868693623699, -8.761017819562333],
              [-35.619013263590773, -8.761111449306407],
              [-35.619239642296662, -8.761184446304776],
              [-35.619594311995662, -8.761244815784263],
              [-35.619735974782031, -8.761281694996368],
              [-35.619875525531619, -8.761356784512239],
              [-35.620322660976903, -8.76202478972856],
              [-35.620487713995772, -8.762220296393258],
              [-35.620653197528078, -8.762399093916935],
              [-35.620856241419879, -8.762657193811537],
              [-35.620954532853908, -8.76283342675738],
              [-35.621570464129192, -8.762537425352001],
              [-35.621887782701904, -8.762344814245097],
              [-35.622179038370597, -8.762142660996723],
              [-35.622509513479613, -8.761886018713067],
              [-35.622814584531056, -8.761685287340736],
              [-35.623343873670727, -8.761347972576807],
              [-35.623430875965504, -8.76130471809123],
              [-35.623603103020663, -8.761252269459234],
              [-35.623802442851193, -8.761245147337528],
              [-35.624095555307676, -8.761245777453034],
              [-35.624230439812393, -8.761253849655485],
              [-35.624329718703514, -8.761273527487459],
              [-35.62476056296763, -8.761416151463743],
              [-35.625002864561701, -8.761516332047249],
              [-35.625116918971102, -8.761577048318676],
              [-35.625228851932889, -8.761656438816283],
              [-35.625313537118338, -8.761764455181387],
              [-35.625516777409977, -8.762091631291636],
              [-35.625654193727627, -8.762267494509283],
              [-35.625775356724134, -8.762394937773166],
              [-35.625856028169224, -8.762460358589836],
              [-35.62595059841999, -8.762518273181273],
              [-35.626066242027477, -8.76257800135504],
              [-35.626185082107519, -8.762639380401765],
              [-35.626452631303096, -8.762824305311202],
              [-35.626531507556564, -8.762718197400966],
              [-35.626622657426189, -8.762625377554304],
              [-35.627190111172844, -8.76178673305677],
              [-35.627562521185745, -8.760409846413937],
              [-35.630555558995148, -8.758726960136169],
              [-35.630965323048962, -8.758277931962299],
              [-35.631144293406273, -8.758672004399084],
              [-35.631130155498404, -8.76459918608486],
              [-35.631318464464684, -8.765033216632302],
              [-35.634699269000279, -8.766048594271043],
              [-35.64156709477777, -8.767471258169186],
              [-35.641513854728736, -8.75505987269888],
              [-35.643669782623981, -8.751172711829565],
              [-35.643721275702411, -8.751105079159975],
              [-35.645121233333981, -8.74955490520483],
              [-35.647438246025452, -8.745683495285528],
              [-35.654178887359507, -8.747267156990626],
              [-35.658730653367854, -8.740401596380016],
              [-35.66724127188639, -8.745129436668631],
              [-35.664658361871432, -8.749889384370775],
              [-35.666663141281347, -8.750366191666647],
              [-35.666452789507012, -8.751442104314398],
              [-35.664387361012729, -8.75999395566016],
              [-35.667371314843344, -8.760654773192815],
              [-35.673649198618449, -8.762216518571542],
              [-35.675117862073684, -8.760723184815351],
              [-35.675276516957005, -8.760756162433749],
              [-35.676327861727358, -8.761208690337408],
              [-35.676896804503414, -8.763007432637153],
              [-35.678982699607438, -8.763495519352148],
              [-35.690650787699319, -8.76395853801475],
              [-35.695262518345046, -8.756423932309273],
              [-35.691862124342194, -8.751406263092887],
              [-35.692690115227087, -8.748056511465576],
              [-35.703397826785839, -8.750059438141806],
              [-35.705952280454277, -8.750104775741688],
              [-35.706791829225835, -8.742783338769847],
              [-35.707247421228921, -8.736970439116194],
              [-35.707810199775565, -8.730223731993961],
              [-35.707927444523328, -8.728945009838727],
              [-35.710875495870027, -8.729803993419258],
              [-35.712288989624845, -8.731252338815766],
              [-35.713689242687842, -8.731243880695775],
              [-35.714796628230367, -8.732130092958139],
              [-35.716625114704925, -8.732771069315582],
              [-35.716975848959223, -8.733295439738143],
              [-35.717659662480521, -8.733416057397569],
              [-35.721401123185984, -8.735893304752922],
              [-35.726993267869474, -8.738754809721708],
              [-35.734639954259123, -8.739607954548424],
              [-35.739298705777323, -8.740091123493857],
              [-35.739074597148552, -8.743292006032794],
              [-35.73853581075074, -8.749780661397805],
              [-35.738478110023941, -8.750367514836011],
              [-35.740465504890764, -8.751990648902968],
              [-35.741837306333551, -8.752883826647222],
              [-35.744939497851426, -8.756510711795226],
              [-35.747053929750408, -8.757608546464217],
              [-35.750641206550597, -8.758658586114002],
              [-35.753895450791717, -8.762235929262982],
              [-35.754905940961052, -8.764450553797838],
              [-35.764530173944515, -8.764595490851992],
              [-35.769771234566541, -8.764711192476698],
              [-35.769876483736674, -8.76470718602507],
              [-35.771059014856164, -8.764759497889832],
              [-35.777037081067405, -8.764091503376511],
              [-35.783887775560629, -8.764713551368487],
              [-35.784172604237767, -8.758885142968737],
              [-35.784429951777881, -8.752825796734278],
              [-35.784701365455028, -8.748690549787717],
              [-35.784726994952159, -8.74697601422351],
              [-35.785496640557504, -8.731758883745208],
              [-35.785250735329576, -8.730478268673048],
              [-35.781993613781843, -8.728725599954936],
              [-35.776261223990325, -8.715232239131543],
              [-35.776067754338349, -8.71448036672269],
              [-35.775403461441385, -8.709209238385293],
              [-35.775392635961644, -8.709108125009783],
              [-35.77323990033031, -8.706366125851963],
              [-35.77250827390359, -8.706274435378264],
              [-35.769809038446461, -8.703659655086065],
              [-35.767585407985287, -8.704507708168716],
              [-35.76605290114933, -8.705031133358421],
              [-35.765979428421645, -8.70696322979993],
              [-35.765822640882689, -8.709585510337369],
              [-35.765840299827346, -8.709895087277744],
              [-35.765048769451063, -8.722051285890025],
              [-35.757739605944607, -8.721754716106274],
              [-35.756764644445248, -8.72479679107027],
              [-35.756783607129151, -8.725155986260376],
              [-35.756691915271325, -8.727352650721732],
              [-35.752502780760871, -8.726908071134158],
              [-35.752299172592693, -8.726886075342239],
              [-35.749637674023525, -8.725935765969032],
              [-35.748168092477052, -8.725595632703145],
              [-35.745823471275003, -8.732083169491746],
              [-35.744593455315766, -8.735182270831995],
              [-35.739146714016059, -8.737296676084661],
              [-35.740361940109764, -8.728479621387661],
              [-35.740859717623003, -8.723801067709658],
              [-35.734685942470584, -8.723240631706876],
              [-35.735791350077889, -8.71931242547967],
              [-35.738353711711191, -8.710342434615713],
              [-35.738374178878765, -8.710228749414165],
              [-35.738523666129225, -8.705422324313888],
              [-35.751389426080443, -8.714508899024697],
              [-35.757154530581801, -8.714882887260845],
              [-35.757930218041267, -8.704126273219053],
              [-35.757949766357648, -8.703827266100459],
              [-35.757982073010808, -8.703090728737347],
              [-35.75645797568891, -8.701389495628144],
              [-35.756019878849109, -8.700917519186527],
              [-35.759221559210388, -8.684943899326393],
              [-35.760062993661649, -8.68476927229896],
              [-35.767154268341386, -8.685401852131335],
              [-35.768243197337092, -8.696834662529199],
              [-35.771426179081807, -8.697205016107084],
              [-35.777450676130101, -8.698042528150623],
              [-35.777524895705334, -8.701535027248367],
              [-35.791591079064986, -8.704700089374612],
              [-35.796859406261262, -8.7051942915206],
              [-35.801864603203001, -8.705377451815989],
              [-35.80236857880071, -8.697631364237228],
              [-35.802830623315543, -8.69077165383289],
              [-35.803077028637027, -8.686025029719579],
              [-35.803303078916997, -8.677650451851068],
              [-35.805457254820432, -8.676383114080076],
              [-35.808581696428448, -8.674384856194415],
              [-35.809948383585592, -8.673463071995489],
              [-35.815933239997271, -8.669248872548037],
              [-35.815294461632313, -8.66878702071117],
              [-35.815207613439114, -8.668731618644355],
              [-35.81516063576408, -8.66865102270225],
              [-35.815126493415235, -8.66816774316951],
              [-35.815030816059156, -8.667279057812305],
              [-35.814451361812345, -8.666771420234724],
              [-35.813993372761551, -8.66669202860079],
              [-35.81212843211145, -8.665797141323113],
              [-35.81073267195022, -8.660479518438647],
              [-35.807343157286049, -8.656463994748139],
              [-35.802553909221189, -8.658181791712362],
              [-35.803588421648421, -8.654537685526392],
              [-35.80124184069895, -8.654581629407838],
              [-35.796712653407987, -8.657782424631201],
              [-35.786246270655248, -8.661727550370639],
              [-35.780104777741229, -8.663167717588845],
              [-35.780767513308128, -8.667097136643529],
              [-35.780364220793849, -8.670279812767596],
              [-35.776725984858473, -8.670311658276999],
              [-35.776219421245074, -8.673707864671037],
              [-35.776221131488505, -8.673680813736334],
              [-35.770178089102046, -8.676566024163277],
              [-35.768553997232203, -8.677173022681547],
              [-35.768015015289066, -8.675899841085547],
              [-35.76737356156503, -8.676020587892392],
              [-35.766946124855096, -8.675483868077288],
              [-35.762430631862422, -8.673591198533913],
              [-35.762053148906105, -8.673438951678287],
              [-35.761389845079563, -8.674436219765973],
              [-35.759953503856124, -8.674428733722564],
              [-35.759343280065551, -8.674188945594683],
              [-35.75842696115604, -8.675768957916571],
              [-35.756790105527216, -8.675992540810023],
              [-35.755538537356564, -8.674681687223384],
              [-35.753443110867195, -8.675683119723351],
              [-35.74890353278991, -8.670837327274803],
              [-35.74768416295948, -8.671541362374507],
              [-35.745693737086974, -8.671783251344195],
              [-35.745260309344289, -8.671653795204421],
              [-35.744424584134272, -8.671144108992713],
              [-35.74091477500086, -8.673781648044709],
              [-35.73982421701492, -8.67384172368979],
              [-35.737607292818616, -8.670297339391077],
              [-35.735491193489956, -8.670982130606419],
              [-35.735811014370945, -8.672091438043692],
              [-35.732910613468015, -8.673455897781539],
              [-35.731823736608774, -8.675188606037905],
              [-35.73093015855104, -8.674775746468097],
              [-35.729839060530573, -8.674800044197243],
              [-35.728347523715136, -8.673561029507345],
              [-35.727447828777834, -8.672808904285176],
              [-35.726923814613563, -8.672090404577714],
              [-35.726433760232915, -8.671969380347742],
              [-35.724727126258045, -8.672335542043673],
              [-35.724302198511822, -8.677014335649229],
              [-35.724279056519656, -8.677184737366941],
              [-35.72388298612973, -8.678707812978752],
              [-35.725224751803793, -8.680201050030727],
              [-35.724107803272872, -8.682270880081083],
              [-35.722806832532491, -8.68291151774655],
              [-35.72160234504144, -8.683395530682297],
              [-35.708772076473771, -8.683637878228016],
              [-35.710636155741923, -8.670865383948202],
              [-35.710706869465355, -8.669726929829],
              [-35.708897570295512, -8.668507499495711],
              [-35.706014601041026, -8.669659840000731],
              [-35.704642434335206, -8.669853348992524],
              [-35.704621312180173, -8.670907479456538],
              [-35.702520415721096, -8.671455597298927],
              [-35.699639360181294, -8.673696551798178],
              [-35.698548851029599, -8.675016282247794],
              [-35.696060315817661, -8.675296331301654],
              [-35.696298999112962, -8.684061173163098],
              [-35.695102762064138, -8.685073891174801],
              [-35.694935700683679, -8.695215451653894],
              [-35.694811430799703, -8.695563652033925],
              [-35.691089610701262, -8.699586835471171],
              [-35.687788262588633, -8.699541765639259],
              [-35.681389910875261, -8.701850365829937],
              [-35.678332103871263, -8.701585858150054],
              [-35.674163199228204, -8.703715593739469],
              [-35.668496447297173, -8.703976672637816],
              [-35.668639940682638, -8.691759399862603],
              [-35.668645891918423, -8.691474195451962],
              [-35.668649552611015, -8.691330891207436],
              [-35.669163830535119, -8.684859560516754],
              [-35.669370213076007, -8.684429988990484],
              [-35.670087478208018, -8.684764863878495],
              [-35.671693707702765, -8.684663419239259],
              [-35.671795200397099, -8.684687312107796],
              [-35.671766632308078, -8.684097429797298],
              [-35.671901184000987, -8.684086140450827],
              [-35.672373238768188, -8.684036088285774],
              [-35.67288431027832, -8.684879048896788],
              [-35.675203876345144, -8.68436139434055],
              [-35.678785082728041, -8.684243023946671],
              [-35.68266261244468, -8.684020629721168],
              [-35.683868151752414, -8.680118969986617],
              [-35.6847377380236, -8.676595789353732],
              [-35.684229724733498, -8.672498426406568],
              [-35.684197259936958, -8.67153814958138],
              [-35.684225383415182, -8.67022070218405],
              [-35.684247317534087, -8.669674430549236],
              [-35.684337838501889, -8.667189239497787],
              [-35.682818572711739, -8.643903191749683],
              [-35.701115200695043, -8.645904838630589],
              [-35.704681146546783, -8.646364150549445],
              [-35.70905475451864, -8.647523846367717],
              [-35.712131168355626, -8.649178232640606],
              [-35.712204780180116, -8.658821265333421],
              [-35.712109313386016, -8.659164470288969],
              [-35.711772622775619, -8.660597498653496],
              [-35.717150665162769, -8.656589262654427],
              [-35.72281491436884, -8.659330103091273],
              [-35.721207467483914, -8.646433014705883],
              [-35.721077338080327, -8.640954304995983],
              [-35.72485695223429, -8.637515178482708],
              [-35.724395346171228, -8.635206636756992],
              [-35.724381102846088, -8.635146209634126],
              [-35.72363393927747, -8.633705579394677],
              [-35.722492964738464, -8.633795340081976],
              [-35.72330979791036, -8.630637123241131],
              [-35.723323408523676, -8.630286631920974],
              [-35.723705701298542, -8.626881122375256],
              [-35.724049277582182, -8.624608156584685],
              [-35.724681805758074, -8.621031611782838],
              [-35.719882264936608, -8.620996291798049],
              [-35.715102930310145, -8.62156156647832],
              [-35.715000706715728, -8.62158522183506],
              [-35.708374225517368, -8.621350931988594],
              [-35.708276605433895, -8.621373505043449],
              [-35.699773410961569, -8.621056254558932],
            ],
            [
              [-35.677959509012233, -8.717822565958013],
              [-35.696699647741731, -8.718659617954653],
              [-35.696540273089383, -8.72787910927549],
              [-35.691952719989928, -8.72820498965922],
              [-35.690738249174345, -8.728264371221163],
              [-35.689019378525224, -8.728275687311569],
              [-35.682757634536436, -8.728296581511708],
              [-35.678781960577886, -8.728432193945714],
              [-35.678786796612258, -8.727265773596603],
              [-35.678778160489394, -8.723574042924731],
              [-35.678781120712422, -8.722119748153416],
              [-35.678782037818699, -8.720842297002307],
              [-35.677565464191439, -8.719529784268952],
              [-35.677959509012233, -8.717822565958013],
            ],
            [
              [-35.76142373014261, -8.728338102322763],
              [-35.766707057421762, -8.729719528031147],
              [-35.771488539850111, -8.730901808670067],
              [-35.770920460078969, -8.740483245379204],
              [-35.773062037037164, -8.74866510913426],
              [-35.769623015198746, -8.747118912155191],
              [-35.767955331533848, -8.739976287814791],
              [-35.761936029194793, -8.739301906622241],
              [-35.759964495432079, -8.732734674550098],
              [-35.76142373014261, -8.728338102322763],
            ],
          ],
          [
            [
              [-35.768311201724686, -8.61904707528476],
              [-35.76680766711803, -8.619520514688865],
              [-35.76693793900926, -8.620094824508124],
              [-35.766928912040221, -8.62051599909487],
              [-35.766765841042904, -8.621144357636188],
              [-35.766158296127074, -8.621518970219473],
              [-35.760386355129214, -8.625463875504739],
              [-35.755556974440516, -8.627828492343147],
              [-35.756134044864538, -8.62961269327581],
              [-35.753888308262056, -8.631848595776326],
              [-35.753274697303425, -8.636592550404258],
              [-35.753426826614422, -8.639178188030071],
              [-35.754335959172515, -8.642435253037613],
              [-35.754508671434316, -8.642787531355253],
              [-35.752097701557503, -8.647783112674233],
              [-35.752735745454892, -8.65301100318171],
              [-35.758690124997564, -8.653053674233426],
              [-35.761276571211013, -8.652889205553461],
              [-35.766739383933178, -8.652471310636971],
              [-35.770577801874929, -8.652463967029668],
              [-35.776118463700904, -8.652556021875354],
              [-35.775946288855778, -8.647300946744302],
              [-35.778490749685062, -8.648109265948623],
              [-35.783120798433373, -8.649559712555675],
              [-35.78581850768358, -8.64563158657058],
              [-35.790033605359547, -8.640244714628858],
              [-35.781512578551755, -8.640019173406614],
              [-35.778623051350046, -8.638615975162866],
              [-35.777262621750509, -8.636027804249322],
              [-35.777173876799921, -8.635747592945561],
              [-35.777889421910302, -8.626106019400304],
              [-35.777319029354494, -8.622787291514125],
              [-35.775899286635763, -8.619799712261388],
              [-35.771597586326763, -8.620266499294972],
              [-35.768161226568253, -8.62037142318443],
              [-35.768311201724686, -8.61904707528476],
            ],
          ],
          [
            [
              [-35.786708774163309, -8.715353209488466],
              [-35.782144874007109, -8.717242090502126],
              [-35.782041991880142, -8.717300171411804],
              [-35.781241080888037, -8.717600300218262],
              [-35.781562578670759, -8.719061613312993],
              [-35.783843351019954, -8.720448575468088],
              [-35.787567569828056, -8.72262755508474],
              [-35.788794303263082, -8.72267756581963],
              [-35.788795729271364, -8.722501491540639],
              [-35.788988174279218, -8.717841248714066],
              [-35.7892144046167, -8.712974246747116],
              [-35.786419313398945, -8.712932754558047],
              [-35.786708774163309, -8.715353209488466],
            ],
          ],
          [
            [
              [-35.645383194682267, -8.775893360795843],
              [-35.643767012807501, -8.781826116373619],
              [-35.643748022954419, -8.781961183479195],
              [-35.64117732434223, -8.791321042931786],
              [-35.639876370298353, -8.7963358258694],
              [-35.639799684439708, -8.796656074700172],
              [-35.639734715945472, -8.796855773700679],
              [-35.638786689020115, -8.800354730395192],
              [-35.637838895883924, -8.804167254548528],
              [-35.641367647283381, -8.804979776162325],
              [-35.645825878313751, -8.805978321389675],
              [-35.649976357746979, -8.807047314630468],
              [-35.655612405081804, -8.808132824968631],
              [-35.657109734853137, -8.802460431895978],
              [-35.658366613417293, -8.797743025124925],
              [-35.661359623968899, -8.798398897523006],
              [-35.661769930512953, -8.796849676776294],
              [-35.666305521121735, -8.797946683998632],
              [-35.666522577315973, -8.797049120892405],
              [-35.666539125464958, -8.79697017540836],
              [-35.668269384475806, -8.790108420756999],
              [-35.67044670876755, -8.78163405649568],
              [-35.663138751628502, -8.779942987429525],
              [-35.658781706035555, -8.778952973951951],
              [-35.658660045876346, -8.778945310905877],
              [-35.653034680551528, -8.777651448731495],
              [-35.645383194682267, -8.775893360795843],
            ],
          ],
          [
            [
              [-35.677775491904875, -8.792767029044192],
              [-35.677792453671408, -8.793762038511082],
              [-35.677705662950935, -8.795648752340426],
              [-35.67771248521467, -8.795806765515211],
              [-35.677659146357215, -8.798575723187465],
              [-35.683215635416232, -8.798695711378731],
              [-35.687535504934779, -8.798778531787306],
              [-35.687715899453508, -8.793144216914143],
              [-35.679951317674636, -8.792895143794455],
              [-35.677775491904875, -8.792767029044192],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0249000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRINHA',
        municipio: 'ITAIBA',
        area_hecta: '2130.8000',
        capacidade: 82.0,
        num_famili: 78.0,
        fase: 5.0,
        data_de_cr: '27/12/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 2135.9836,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.262598797501099, -8.912685238919074],
              [-37.256429925740719, -8.910952798809308],
              [-37.239375685911583, -8.908476826044321],
              [-37.24058020773446, -8.904979163976645],
              [-37.231597598480029, -8.903553646755608],
              [-37.231759866850261, -8.907013340486372],
              [-37.204146086740145, -8.902525279047772],
              [-37.197657467808284, -8.901123931439461],
              [-37.172808516725254, -8.894603174702088],
              [-37.173865631550228, -8.890603484904759],
              [-37.151861973140207, -8.884740566021383],
              [-37.148243808608832, -8.887722649228339],
              [-37.146684129493934, -8.887547899757436],
              [-37.138342993383318, -8.902136346310902],
              [-37.137828581883007, -8.902207408660747],
              [-37.132166900022774, -8.910496468328633],
              [-37.139136601625992, -8.912319804036933],
              [-37.138634188604698, -8.917125953831889],
              [-37.15998013452905, -8.923115343702074],
              [-37.160686314974825, -8.917970814827815],
              [-37.170797912415757, -8.920647602106801],
              [-37.172569372637177, -8.915079927537647],
              [-37.163119770868057, -8.9124957637316],
              [-37.165974274735582, -8.900873332449482],
              [-37.191423545464694, -8.908905518475365],
              [-37.198526355912769, -8.910692844662782],
              [-37.205378245570358, -8.9121349842149],
              [-37.205790092910831, -8.910096652967521],
              [-37.267142715268058, -8.920667938259999],
              [-37.263646584577614, -8.914859937530364],
              [-37.262598797501099, -8.912685238919074],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0309000',
        uf: 'PE',
        nome_proje: 'PA ARAGUATI',
        municipio: 'PARNAMIRIM',
        area_hecta: '199.4141',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '19/03/2010',
        area_calc_: 200.0368,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.972218631339821, -8.289957266669331],
              [-39.972434929594613, -8.29003723429113],
              [-39.973416442596786, -8.290398420473711],
              [-39.974091615303422, -8.290633718023257],
              [-39.974336062697006, -8.290700961719278],
              [-39.977967755986597, -8.277193139483327],
              [-39.988273487569103, -8.238856250835717],
              [-39.984920467116723, -8.236569965575033],
              [-39.972218631339821, -8.289957266669331],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0334000',
        uf: 'PE',
        nome_proje: 'PA BOA VISTA II',
        municipio: 'JATAUBA',
        area_hecta: '769.6314',
        capacidade: 50.0,
        num_famili: 28.0,
        fase: 3.0,
        data_de_cr: '30/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/12/2004',
        area_calc_: 769.5046,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.417262498635324, -7.918470356606425],
              [-36.417074919746582, -7.918507419566303],
              [-36.414948794493803, -7.923248976318123],
              [-36.413957355652904, -7.925750769060227],
              [-36.411665433071555, -7.929494602667326],
              [-36.411320145190508, -7.929660627628168],
              [-36.411108481996401, -7.93111231327701],
              [-36.411098745675147, -7.931190781146271],
              [-36.410350422682122, -7.937212026472917],
              [-36.409599000457369, -7.943473797920671],
              [-36.409591850571573, -7.943544329423874],
              [-36.409207878665725, -7.946580049327796],
              [-36.408958649248156, -7.948552364465756],
              [-36.409803479705914, -7.948532873888491],
              [-36.410267325656648, -7.948630470373201],
              [-36.411402148747634, -7.948564077252012],
              [-36.414996211632001, -7.947212799472049],
              [-36.415485928945792, -7.947159819410966],
              [-36.421526878967562, -7.946161122169896],
              [-36.424073493414284, -7.94979757515287],
              [-36.424184147179538, -7.94977232613906],
              [-36.428794601767372, -7.950344098385997],
              [-36.428730793314188, -7.94763665186361],
              [-36.429003995923495, -7.945118351716105],
              [-36.433530532977073, -7.944180393414075],
              [-36.433723685404246, -7.942154091024276],
              [-36.433930494210415, -7.939504242696028],
              [-36.434133679030673, -7.936955040758145],
              [-36.434279883918727, -7.935071137100014],
              [-36.435672673299884, -7.935074754471966],
              [-36.435034762792547, -7.932804481023739],
              [-36.433473437414662, -7.929724692743181],
              [-36.433330170844528, -7.929353391130366],
              [-36.433321056899047, -7.92928836036989],
              [-36.433347875374913, -7.925884544786968],
              [-36.433407682248067, -7.924527315064458],
              [-36.433302335450485, -7.919894583434136],
              [-36.433302480505873, -7.919081091581732],
              [-36.427526274754072, -7.91869955633314],
              [-36.425193113352186, -7.918589632844214],
              [-36.424955121850132, -7.918804502382851],
              [-36.422481165287209, -7.918627996917879],
              [-36.422298921443165, -7.91857960793187],
              [-36.420788635709641, -7.918548306771417],
              [-36.419849897751135, -7.918898001516368],
              [-36.419684714061454, -7.918875562024153],
              [-36.417262498635324, -7.918470356606425],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0379000',
        uf: 'PE',
        nome_proje: 'PA GAMA',
        municipio: 'SAO BENTO DO UNA',
        area_hecta: '632.4900',
        capacidade: 70.0,
        num_famili: 70.0,
        fase: 4.0,
        data_de_cr: '07/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 640.4171,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.556075603354437, -8.503685907766831],
              [-36.554862368538259, -8.503406527659676],
              [-36.554686910413075, -8.503460553085665],
              [-36.550022609896622, -8.504161968754198],
              [-36.548950669333465, -8.504109142642978],
              [-36.546724436386931, -8.503465308268492],
              [-36.544680117274687, -8.503205656088952],
              [-36.541843617598587, -8.502479394748255],
              [-36.538057146636483, -8.50176500863671],
              [-36.533551877709705, -8.501482426470615],
              [-36.532098317844351, -8.501408960939496],
              [-36.530592258402137, -8.513093452828391],
              [-36.530257872902794, -8.515774222229302],
              [-36.52975309052389, -8.520234970187417],
              [-36.529453651935171, -8.52655806403676],
              [-36.528190864545365, -8.526441844743646],
              [-36.527678441071998, -8.530357401909363],
              [-36.528870044361085, -8.530462689584551],
              [-36.52916836876043, -8.5303208076957],
              [-36.531232488000086, -8.530288315266681],
              [-36.531634622133261, -8.530747528504556],
              [-36.53332010781925, -8.529720773173448],
              [-36.534556816829209, -8.527805139360616],
              [-36.534453250351767, -8.525905293836892],
              [-36.534563084438091, -8.525582004745203],
              [-36.535922381944665, -8.526165502260977],
              [-36.536726176658313, -8.52416920377752],
              [-36.537585759242411, -8.524546092610155],
              [-36.5379876222989, -8.522382397264211],
              [-36.548526536179224, -8.526846098860918],
              [-36.550776867120184, -8.517060205642407],
              [-36.552726508489407, -8.517278186910097],
              [-36.553269982204128, -8.515914610889791],
              [-36.555064893158921, -8.50964643281138],
              [-36.556141083365063, -8.503716031344249],
              [-36.556075603354437, -8.503685907766831],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0071000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO SERRA',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '900.0000',
        capacidade: 73.0,
        num_famili: 69.0,
        fase: 6.0,
        data_de_cr: '21/12/1995',
        forma_obte: 'Desapropriação',
        data_obten: '18/08/1994',
        area_calc_: 805.9784,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.217349932643359, -8.025970450271693],
              [-35.217301485596579, -8.026317627776644],
              [-35.217333561122771, -8.04866887848832],
              [-35.216803131762312, -8.061619544254629],
              [-35.216852320345176, -8.061692222682796],
              [-35.216902250022549, -8.06171780400757],
              [-35.217011137043542, -8.061761325041923],
              [-35.217103657602991, -8.061780529365095],
              [-35.217207247450816, -8.061767314222701],
              [-35.217314422538017, -8.061746306132894],
              [-35.217418781289837, -8.061724409362505],
              [-35.217524964239061, -8.061687769164848],
              [-35.217627490502707, -8.061645815741285],
              [-35.217713949763329, -8.061617869285085],
              [-35.217797416729468, -8.061590029400516],
              [-35.217881539197634, -8.061565982322534],
              [-35.218076029120084, -8.061474358976488],
              [-35.218165906922984, -8.061441332111722],
              [-35.21826191610851, -8.061418395684216],
              [-35.218346666381116, -8.061409711378881],
              [-35.218639661052777, -8.061337078660843],
              [-35.21876667723474, -8.061312347216267],
              [-35.21888404131353, -8.061297158922439],
              [-35.219003079663551, -8.061306276351575],
              [-35.219094277190422, -8.061332357006737],
              [-35.219189125005578, -8.061329312397261],
              [-35.219294951712875, -8.061310570889987],
              [-35.219388853180256, -8.061283668494442],
              [-35.21948843605152, -8.061250859978648],
              [-35.219568105086879, -8.061208487625247],
              [-35.219646279696832, -8.061174800761073],
              [-35.219727343559505, -8.061155379794691],
              [-35.219794029605481, -8.061110456442396],
              [-35.219849492763331, -8.061053120116295],
              [-35.21999278101444, -8.060968914201817],
              [-35.219993122061169, -8.060948303522016],
              [-35.22014397724908, -8.061070867992484],
              [-35.220187327506821, -8.061103986868206],
              [-35.220286168179925, -8.06118453076189],
              [-35.220422625920605, -8.061261345577403],
              [-35.220559076752473, -8.061320263234121],
              [-35.220695494571203, -8.061373124912281],
              [-35.22083742131165, -8.061421708366295],
              [-35.22098597809407, -8.061422168580394],
              [-35.221140926975167, -8.061414007082293],
              [-35.221290965425325, -8.061436694979887],
              [-35.221427761368425, -8.061492356403056],
              [-35.22156492543337, -8.06153237838755],
              [-35.221700139141603, -8.06156400466994],
              [-35.221830945184635, -8.061618884906046],
              [-35.221981524476867, -8.061657568527119],
              [-35.222146254960329, -8.061713439726148],
              [-35.222315207545833, -8.061778507671928],
              [-35.222479779321731, -8.061888342081652],
              [-35.222616212224978, -8.06206015544133],
              [-35.222711816402665, -8.062212214167374],
              [-35.222935850482052, -8.062482166951389],
              [-35.223044757847909, -8.062595737846559],
              [-35.22340260891697, -8.06282410805446],
              [-35.223599605290417, -8.06295907486208],
              [-35.223792322169459, -8.063140705691316],
              [-35.223967049853982, -8.063266663807005],
              [-35.22406021136527, -8.063320120464878],
              [-35.224159361679277, -8.063357545661477],
              [-35.224259917095949, -8.063386556989741],
              [-35.22445143131727, -8.063497419049012],
              [-35.22477800904435, -8.063728397964507],
              [-35.225141710992752, -8.063964959760424],
              [-35.225372019661798, -8.06415461073065],
              [-35.225622665378644, -8.064348218521518],
              [-35.226151292416709, -8.06464263590105],
              [-35.226369978346042, -8.064862720028719],
              [-35.226395759729137, -8.065198646999239],
              [-35.227650973565346, -8.068613954143604],
              [-35.229780628207401, -8.068826614994494],
              [-35.230028647450553, -8.068821558022474],
              [-35.230207399588437, -8.068787592244258],
              [-35.23102082331701, -8.068472312394045],
              [-35.231109538996009, -8.068425911331222],
              [-35.231159317982502, -8.068473365387874],
              [-35.231437511168195, -8.068397368655877],
              [-35.231393518720267, -8.068280554804669],
              [-35.231237791235891, -8.067849525308803],
              [-35.231149572570345, -8.067556694009701],
              [-35.231049473138029, -8.067263837003878],
              [-35.230999992490922, -8.06695660345286],
              [-35.230946011490587, -8.066572925345202],
              [-35.230844116033197, -8.066200535681119],
              [-35.230752886267183, -8.065953818876761],
              [-35.230716189798002, -8.065827745399686],
              [-35.230640590720093, -8.065652892997647],
              [-35.230362317346959, -8.064787668685195],
              [-35.230303622301371, -8.064519894575671],
              [-35.230229584604409, -8.064116891649714],
              [-35.230157348981294, -8.063761333078679],
              [-35.230064359894023, -8.063458132286595],
              [-35.229876065547295, -8.063008206519303],
              [-35.229729220181746, -8.062591770061619],
              [-35.229684713784721, -8.062364322318533],
              [-35.229692494716481, -8.062112636898849],
              [-35.23013341620387, -8.060602186754373],
              [-35.230168310455383, -8.060399525455576],
              [-35.230162302661363, -8.06027961193093],
              [-35.230138777418205, -8.060190254835573],
              [-35.230082417423162, -8.060083540952553],
              [-35.229975741770275, -8.059913918925426],
              [-35.229821953669003, -8.059636990237736],
              [-35.229604238348969, -8.059163180312263],
              [-35.229503087085767, -8.059025164154718],
              [-35.229301585601938, -8.058613814910824],
              [-35.229010044668996, -8.058271741201564],
              [-35.228006439002229, -8.057224706436198],
              [-35.227618526287671, -8.056722442947033],
              [-35.227320138424574, -8.055957021465058],
              [-35.227107054911009, -8.055616055815401],
              [-35.226709220290772, -8.054758162959038],
              [-35.226194975470371, -8.053757182229138],
              [-35.226046968524614, -8.053441803915685],
              [-35.22594687943166, -8.053032883674321],
              [-35.225866829138404, -8.05161050016768],
              [-35.225833443849723, -8.051010677115624],
              [-35.225813265716802, -8.050587312688497],
              [-35.225938992108006, -8.05026077713608],
              [-35.226161128675649, -8.050018594055727],
              [-35.226342774889616, -8.049735413115874],
              [-35.226470496470931, -8.049475393252232],
              [-35.226524662284973, -8.04918097264931],
              [-35.226494705503733, -8.048793726769276],
              [-35.226361826084691, -8.048175733075446],
              [-35.226251698877569, -8.047638694995339],
              [-35.226250112632513, -8.047414628540626],
              [-35.226351385258944, -8.046977860489472],
              [-35.226615794132108, -8.045940836254111],
              [-35.226779736029904, -8.045272241434892],
              [-35.22687772079987, -8.04463202510232],
              [-35.226925266288752, -8.043722993532054],
              [-35.226924637249802, -8.043474878402032],
              [-35.226915553999895, -8.043256276019845],
              [-35.22688579741142, -8.043104764194888],
              [-35.226809734409301, -8.042992913194668],
              [-35.226427986809192, -8.042736017786959],
              [-35.226308103562481, -8.042570984130284],
              [-35.226258044460351, -8.042371675908251],
              [-35.226285789437441, -8.042187854422025],
              [-35.226383628526236, -8.041986027046997],
              [-35.2266693112485, -8.041556936908547],
              [-35.226925608244329, -8.041060937204625],
              [-35.227016115526297, -8.040828507612902],
              [-35.227130472831732, -8.040595948713795],
              [-35.227260329894904, -8.040395213183395],
              [-35.227442781578709, -8.040210372132345],
              [-35.227753224796167, -8.039981540511475],
              [-35.228087995042046, -8.039723742645068],
              [-35.228301169941616, -8.039485585905947],
              [-35.228514478089373, -8.039172224591164],
              [-35.228661212833899, -8.038923671871471],
              [-35.228938924165419, -8.0381980576907],
              [-35.229195803575173, -8.0377098281112],
              [-35.229358261557969, -8.037484329703259],
              [-35.229559974931213, -8.037289892932906],
              [-35.230204478532812, -8.0368074237201],
              [-35.230354366145761, -8.036588501386053],
              [-35.230408194336192, -8.036448467718277],
              [-35.230420818038333, -8.036202812055146],
              [-35.230367150124458, -8.035907350202386],
              [-35.229966851511598, -8.035075321158681],
              [-35.22981133728922, -8.034745431005204],
              [-35.229728243130651, -8.034458353912425],
              [-35.229712292580935, -8.034161240947975],
              [-35.22986812976346, -8.033420922183545],
              [-35.230359914805796, -8.031908937221317],
              [-35.221449263484573, -8.026208029900568],
              [-35.221203981113824, -8.026211613769764],
              [-35.220883195312339, -8.026255376479616],
              [-35.22065418809926, -8.026266826480386],
              [-35.220447020474694, -8.02627544681323],
              [-35.220312780167028, -8.026270476637476],
              [-35.220177441979963, -8.026280426646576],
              [-35.220016450333617, -8.026325676629986],
              [-35.219786042539383, -8.026413241894003],
              [-35.219288578216982, -8.026586219650721],
              [-35.219075854339671, -8.026640516158245],
              [-35.218898288817613, -8.026639665967828],
              [-35.218708551671902, -8.026618453234594],
              [-35.21852854043825, -8.026567992053282],
              [-35.218370952135615, -8.026488485142471],
              [-35.218059928331286, -8.026292569641742],
              [-35.217639994530821, -8.026080337883082],
              [-35.217540073533343, -8.026032698566855],
              [-35.217349932643359, -8.025970450271693],
            ],
          ],
          [
            [
              [-35.230532876094976, -8.031963226763695],
              [-35.230043679105961, -8.033467424272629],
              [-35.22989464720267, -8.034175166020914],
              [-35.22990827082986, -8.034428091282932],
              [-35.229981569054694, -8.034681596697965],
              [-35.230130660986568, -8.034997872846475],
              [-35.230541232851969, -8.035851177733047],
              [-35.230603034276371, -8.036191247591775],
              [-35.230587778925887, -8.036486360340039],
              [-35.23051615856965, -8.036672950572443],
              [-35.230337390675722, -8.036933970231587],
              [-35.229677985007172, -8.037427638384839],
              [-35.22949611846289, -8.037602985812867],
              [-35.229350581288593, -8.037804981673727],
              [-35.229104562301757, -8.038272543666256],
              [-35.228825241668865, -8.039002324524088],
              [-35.22866790448839, -8.039268922245842],
              [-35.228444520236735, -8.039597071717402],
              [-35.228212145567618, -8.039856574128075],
              [-35.227862655429163, -8.040125750588196],
              [-35.227562057368765, -8.040347297775597],
              [-35.227402694208898, -8.040508783644661],
              [-35.227288664525631, -8.040685028288539],
              [-35.227182379652682, -8.040901182985912],
              [-35.227091246556604, -8.041135242992739],
              [-35.226825975488303, -8.041648555766534],
              [-35.22654155215649, -8.042075740982684],
              [-35.226461111234023, -8.042241770283356],
              [-35.226442818510804, -8.042362900730035],
              [-35.22647554268336, -8.042493245465305],
              [-35.226556043631028, -8.042604078235238],
              [-35.226940248516819, -8.042862587217565],
              [-35.227056629407237, -8.04303387644949],
              [-35.227096179410829, -8.043235049224636],
              [-35.227106082491382, -8.043470911478931],
              [-35.22710666734347, -8.043727523408968],
              [-35.227058381608643, -8.044650388463674],
              [-35.226957859051787, -8.045307430933111],
              [-35.226791787892893, -8.045984624279191],
              [-35.226527645143356, -8.04702047200986],
              [-35.226431599818859, -8.047434614378611],
              [-35.226432973921675, -8.047619814565106],
              [-35.226539374659616, -8.048138704508052],
              [-35.226674672390175, -8.048767712444411],
              [-35.226707363095024, -8.049190556799376],
              [-35.226644388458027, -8.049532569945697],
              [-35.226501149991059, -8.049824129505966],
              [-35.226305475213934, -8.050129080045233],
              [-35.226096050539404, -8.050357455051135],
              [-35.225996256233721, -8.050616600286363],
              [-35.226014591380988, -8.051001378424091],
              [-35.226047972977966, -8.051600478296221],
              [-35.226127026136844, -8.053006235383943],
              [-35.226218859955367, -8.053381485146819],
              [-35.226357874866721, -8.053677749659848],
              [-35.226872302735963, -8.05467900028167],
              [-35.22726701607975, -8.055529949897386],
              [-35.227483028354605, -8.055875780545943],
              [-35.227778105132884, -8.056632723256463],
              [-35.228144279252824, -8.057106812919519],
              [-35.229144874765574, -8.058150700087088],
              [-35.229454580004727, -8.058514097100765],
              [-35.229659016254402, -8.058931395954451],
              [-35.229761538254536, -8.059071302778454],
              [-35.229984025918299, -8.059555390967269],
              [-35.230132134907905, -8.059822046130872],
              [-35.230239726878061, -8.059993380511203],
              [-35.230308825369789, -8.060124249289641],
              [-35.230342535910893, -8.060251785479888],
              [-35.23035047930945, -8.060410465334849],
              [-35.230310397441635, -8.060642892842779],
              [-35.22987303737753, -8.062141121109414],
              [-35.229866652233504, -8.062349592952028],
              [-35.229904721357521, -8.062544264473235],
              [-35.230045395547457, -8.062943379729472],
              [-35.230235159870766, -8.063396732156527],
              [-35.230333320322771, -8.063716807463827],
              [-35.230407698424607, -8.064082658643148],
              [-35.230481547956458, -8.064484397070894],
              [-35.230537640845426, -8.064740705897139],
              [-35.230810654453592, -8.065589417473664],
              [-35.230887264056719, -8.06576661443602],
              [-35.230925255508346, -8.065897290666836],
              [-35.231017037419832, -8.066145450623578],
              [-35.231124023098943, -8.066536432591835],
              [-35.231179417161961, -8.066929684159454],
              [-35.231226175674202, -8.0672201201478],
              [-35.231322312529919, -8.067501519282596],
              [-35.231410069402877, -8.067792816420361],
              [-35.231563770570808, -8.068218162353045],
              [-35.231619083181897, -8.068365013970295],
              [-35.231797042806988, -8.068318939794029],
              [-35.231795527050181, -8.068174958560061],
              [-35.231808135668508, -8.068109267492829],
              [-35.231785643144889, -8.068059585831303],
              [-35.231712290569234, -8.068013345026454],
              [-35.231706335331133, -8.067936275763138],
              [-35.231669623839984, -8.067906737931803],
              [-35.231626059551253, -8.067884468561129],
              [-35.231599131089176, -8.067819716112668],
              [-35.231564490048527, -8.067770823756222],
              [-35.231502095232663, -8.067721630721039],
              [-35.231487981238175, -8.067612156292189],
              [-35.231438920442308, -8.067563342508043],
              [-35.231421893737533, -8.067518060072388],
              [-35.231373303645228, -8.067439324978714],
              [-35.231359540078984, -8.067327588899559],
              [-35.231367781321019, -8.067275841526161],
              [-35.231351412724955, -8.067218262602378],
              [-35.231351676187778, -8.067167101039301],
              [-35.231337536679042, -8.067102550018429],
              [-35.231296102728351, -8.06700569811432],
              [-35.231292087231694, -8.066901953514265],
              [-35.231310370433057, -8.066878714317946],
              [-35.231313516169024, -8.06682392148941],
              [-35.231295381411812, -8.066775029516355],
              [-35.231291079688809, -8.066734829887146],
              [-35.231241417880952, -8.066675624591451],
              [-35.231201050535589, -8.066591330907348],
              [-35.231207559135399, -8.066488252063802],
              [-35.231240950821999, -8.066425159421396],
              [-35.231230820059373, -8.066347389756499],
              [-35.231265683742834, -8.066337708950284],
              [-35.231303761059245, -8.066269077052135],
              [-35.231291610459181, -8.0662364224698],
              [-35.231252612087388, -8.066203281453754],
              [-35.231249210716285, -8.066112278337322],
              [-35.23124516617667, -8.066052824460444],
              [-35.231290183665308, -8.066042455611209],
              [-35.231355630458182, -8.065937157460702],
              [-35.231394678152022, -8.065896631233633],
              [-35.231242010099976, -8.06552704925582],
              [-35.231234102605235, -8.065474305203018],
              [-35.231209162141326, -8.065375103648556],
              [-35.231153921752636, -8.065241267422715],
              [-35.231133057136958, -8.064959186826387],
              [-35.231083554258625, -8.064928905038618],
              [-35.231147073822882, -8.064885624260574],
              [-35.231134555093568, -8.064851977384411],
              [-35.231080807605771, -8.06479189036475],
              [-35.23105080357918, -8.064761592735655],
              [-35.231072188849112, -8.0645929008618],
              [-35.23116072144483, -8.064562861403511],
              [-35.231189946852666, -8.064550409300997],
              [-35.231200337450687, -8.064493678834076],
              [-35.231173593258717, -8.064412836035476],
              [-35.231213790483622, -8.064284264771457],
              [-35.231372392618511, -8.064016211090781],
              [-35.231407090200449, -8.063744764899539],
              [-35.231472479355638, -8.063347872472264],
              [-35.231499143715901, -8.063066888832225],
              [-35.231460303673913, -8.062913710397716],
              [-35.231487464197883, -8.062673579850873],
              [-35.231398192097721, -8.062651378491424],
              [-35.231353620997766, -8.062693471337409],
              [-35.231240474257106, -8.062567904643661],
              [-35.231248677903281, -8.062376958519412],
              [-35.231178194425986, -8.062374088385445],
              [-35.231120854009923, -8.062221010617343],
              [-35.231177768765413, -8.06216411718172],
              [-35.231166290409895, -8.062038991000293],
              [-35.231124333506955, -8.061978930060393],
              [-35.231215806371843, -8.061724348587623],
              [-35.231266984823513, -8.061729493213887],
              [-35.231333166180491, -8.061559743918105],
              [-35.231290403536981, -8.061485044366764],
              [-35.23125759638387, -8.061439666999163],
              [-35.231276314564624, -8.061363457574586],
              [-35.231318191148723, -8.061342711235445],
              [-35.231322075930166, -8.06130689605904],
              [-35.231323560179675, -8.061263320506509],
              [-35.23128692277912, -8.061247159624257],
              [-35.231298744651021, -8.06112064111503],
              [-35.231319816260438, -8.061076687730113],
              [-35.231288565405393, -8.060818887861553],
              [-35.231340933621311, -8.060792932234252],
              [-35.231417360684922, -8.06075509502468],
              [-35.231461633199565, -8.060906707340086],
              [-35.231480021762927, -8.060902720474125],
              [-35.231510552757619, -8.060847236178246],
              [-35.23147172400116, -8.060811834395231],
              [-35.231479405836076, -8.060740746859347],
              [-35.231504555425502, -8.060745852457337],
              [-35.231519901691073, -8.060732843266329],
              [-35.231618477690894, -8.060467105311865],
              [-35.231576062043423, -8.060455676131191],
              [-35.231505180959729, -8.060479472865593],
              [-35.231463452679613, -8.060444538806202],
              [-35.231425447690462, -8.060311241300509],
              [-35.231442790226303, -8.060149531442418],
              [-35.231479091052115, -8.060137402432007],
              [-35.231552026239157, -8.060074727290836],
              [-35.231518628072337, -8.059954601488533],
              [-35.231469317228871, -8.059876502792285],
              [-35.231548872708629, -8.059764168090162],
              [-35.231614359916826, -8.059749078090919],
              [-35.231629855789549, -8.059763365548044],
              [-35.231681638098983, -8.059729820438941],
              [-35.231692992072553, -8.059666847917523],
              [-35.23172504683717, -8.059641183682348],
              [-35.230730576586808, -8.052746302120239],
              [-35.240919556501545, -8.045933142168874],
              [-35.241154620453656, -8.046246862486452],
              [-35.241294120632283, -8.046399399543544],
              [-35.241507137787643, -8.046579284296289],
              [-35.242541508633913, -8.04733497523465],
              [-35.242738554904349, -8.047496416721506],
              [-35.246939181717472, -8.043786149899056],
              [-35.246831146401469, -8.043781227416755],
              [-35.24671441458257, -8.043728627580085],
              [-35.246679761146929, -8.043644304328661],
              [-35.246638894162871, -8.043600870485511],
              [-35.246526289735122, -8.043606096375482],
              [-35.246430332280767, -8.043620631523455],
              [-35.246322079894227, -8.04367509507062],
              [-35.246240558611269, -8.043610371212845],
              [-35.246204747786962, -8.043612284489042],
              [-35.246166663787925, -8.043547232599037],
              [-35.246098404453768, -8.04346996259134],
              [-35.246066891735751, -8.043461909647267],
              [-35.246021248528493, -8.043407474493332],
              [-35.246063311681858, -8.043388172517297],
              [-35.246070300055003, -8.043356227282064],
              [-35.246011561511565, -8.043295175031826],
              [-35.2459793239437, -8.043270765789345],
              [-35.245951563246869, -8.043252388061823],
              [-35.245950732500361, -8.043200329039365],
              [-35.245909348166975, -8.043194680161216],
              [-35.245860080280984, -8.043190159053898],
              [-35.245806082146856, -8.04321594383938],
              [-35.245753858499626, -8.043218127608194],
              [-35.245732613762797, -8.043180823102752],
              [-35.245668098616278, -8.043157675010425],
              [-35.245610064770695, -8.042961036588764],
              [-35.245545130342009, -8.042911135862697],
              [-35.245563520484694, -8.042825256934274],
              [-35.245781315990811, -8.042802553517559],
              [-35.245932236086475, -8.042756262895821],
              [-35.245973067547702, -8.042727386539415],
              [-35.245938539168755, -8.042682200537552],
              [-35.245896394829821, -8.042637327347],
              [-35.245803869600678, -8.042583419695079],
              [-35.245721432841655, -8.042517073669696],
              [-35.245688104563349, -8.042442776085501],
              [-35.245748477002451, -8.042405296410939],
              [-35.245786342590968, -8.042150646700527],
              [-35.24571296700897, -8.04206644463128],
              [-35.24563801586207, -8.041992555405697],
              [-35.24555650658219, -8.041864017177462],
              [-35.245490624149447, -8.041872512331851],
              [-35.245445062422448, -8.041931694527062],
              [-35.245317151623709, -8.041890996068464],
              [-35.245229887452368, -8.041820789582998],
              [-35.245009999701267, -8.041545584510883],
              [-35.244931935504368, -8.04148273947135],
              [-35.244641901938813, -8.04136410833317],
              [-35.244452019648456, -8.041218536306536],
              [-35.244267305933064, -8.041155279604004],
              [-35.244128722063984, -8.041119610347559],
              [-35.244027734998589, -8.041093497563258],
              [-35.243883537150218, -8.041174821302024],
              [-35.243594691734948, -8.041272210788618],
              [-35.243427110836727, -8.04124067676978],
              [-35.243084757922112, -8.041387167890742],
              [-35.242992327380854, -8.04133389162531],
              [-35.24244952908893, -8.041541404375527],
              [-35.242266800241516, -8.041426795300369],
              [-35.242113810455912, -8.041278037745002],
              [-35.242032722167359, -8.041242415427899],
              [-35.24175311036123, -8.040825262600308],
              [-35.241584259328576, -8.040545980049068],
              [-35.241527605395085, -8.040517545698769],
              [-35.241445374633329, -8.040438994946795],
              [-35.241352317610932, -8.040436700681763],
              [-35.241190678485673, -8.040298113284109],
              [-35.241110709769707, -8.040251637988131],
              [-35.24099948081102, -8.040193492326432],
              [-35.240928467347523, -8.040126359562228],
              [-35.240815164840214, -8.040037402682595],
              [-35.240739744291858, -8.039993433355496],
              [-35.240704833245168, -8.040142674356241],
              [-35.240677417767508, -8.040236647123193],
              [-35.240605391048099, -8.040364849293807],
              [-35.2405337089589, -8.040341106565151],
              [-35.240486395660525, -8.040329162186],
              [-35.240369873932003, -8.040215456287942],
              [-35.240295079080461, -8.040285463342437],
              [-35.240236225236607, -8.040285784316506],
              [-35.240058552073798, -8.04041465297589],
              [-35.239978729706138, -8.040460915236904],
              [-35.239864974328611, -8.040454666021262],
              [-35.239722901242658, -8.040444142139922],
              [-35.239622919936586, -8.040270779757286],
              [-35.239642014986394, -8.040164378742972],
              [-35.239661503617789, -8.040080120772384],
              [-35.239638396755076, -8.039934721228168],
              [-35.239695857607501, -8.039845827196562],
              [-35.239761313429462, -8.039660445023802],
              [-35.239881878327182, -8.039502963604804],
              [-35.239896519997821, -8.039411320211412],
              [-35.239916904791194, -8.03937469210911],
              [-35.239890272267914, -8.039330456557034],
              [-35.239963899712045, -8.039196460903156],
              [-35.239911301264051, -8.03903115592775],
              [-35.239842589003104, -8.038970156742357],
              [-35.239821698683706, -8.038898140595197],
              [-35.239959752936471, -8.038738304156404],
              [-35.239954177228405, -8.038664215975896],
              [-35.239914888493296, -8.038577657199813],
              [-35.239797844822142, -8.038484381604482],
              [-35.239597879293271, -8.03850047614835],
              [-35.239499588753297, -8.038502910072054],
              [-35.239280246321492, -8.038574066323662],
              [-35.239142148185159, -8.038593710174409],
              [-35.238988966897203, -8.038641095080731],
              [-35.238870400785331, -8.038633967778301],
              [-35.238796729048296, -8.038644221583313],
              [-35.238742765355248, -8.038643250192411],
              [-35.238709161657916, -8.038551508104272],
              [-35.238667487319532, -8.038542515541732],
              [-35.238587844489949, -8.038373470871294],
              [-35.238626960177292, -8.03829624667148],
              [-35.23872068521743, -8.038238520030127],
              [-35.238666192185505, -8.038157557596772],
              [-35.238690745816442, -8.038004667101781],
              [-35.238808253614714, -8.037934608423681],
              [-35.238863795589744, -8.037975342206234],
              [-35.238975643126672, -8.038030683281402],
              [-35.239122897412997, -8.037927832161106],
              [-35.239219676366432, -8.03789783809184],
              [-35.2392606545245, -8.037945249542963],
              [-35.239312707309182, -8.037945056253296],
              [-35.23941361243584, -8.037873732088135],
              [-35.239444990344573, -8.037725052695606],
              [-35.23943513977229, -8.037499858124697],
              [-35.239402494056776, -8.037433962009775],
              [-35.239385602404717, -8.037346919502797],
              [-35.239498200193957, -8.03715884002753],
              [-35.239574258964765, -8.037104373167606],
              [-35.239579908482831, -8.03707623154212],
              [-35.239406876119652, -8.036959850187085],
              [-35.239421732363169, -8.036808097241977],
              [-35.239508345246364, -8.036660743859191],
              [-35.2394504613669, -8.036556750908442],
              [-35.239442455744808, -8.036469479194308],
              [-35.239233714510299, -8.036406531128209],
              [-35.239082425277907, -8.036352308785856],
              [-35.238924337495163, -8.036430904240115],
              [-35.238803637400409, -8.036547168792874],
              [-35.238565097580413, -8.036540604413238],
              [-35.238508007314081, -8.036515154635026],
              [-35.238463384777276, -8.036580387099777],
              [-35.238355089232719, -8.036511196951407],
              [-35.238223209191254, -8.036358164181037],
              [-35.238258679236132, -8.036211451048171],
              [-35.238234226593619, -8.036051957911964],
              [-35.238156698643088, -8.035888053595652],
              [-35.238182292709176, -8.035726389739514],
              [-35.238088132457342, -8.035390295220324],
              [-35.237986068655296, -8.035332911847963],
              [-35.237903093400874, -8.035267108756157],
              [-35.237855338703959, -8.035257245233794],
              [-35.23780016286829, -8.035250133768649],
              [-35.23773523023452, -8.035332560183818],
              [-35.237686341268308, -8.035314296672775],
              [-35.237468023519618, -8.035241276077084],
              [-35.237321075105449, -8.035217851813359],
              [-35.237254955090911, -8.03514978747987],
              [-35.236287130467836, -8.033985604649812],
              [-35.236028395637412, -8.033867970059424],
              [-35.233829481694009, -8.032961121373289],
              [-35.233686024111812, -8.032896007275397],
              [-35.232042291850782, -8.032254494859378],
              [-35.230532876094976, -8.031963226763695],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0145000',
        uf: 'PE',
        nome_proje: 'PA PADRE CÍCERO',
        municipio: 'CATENDE',
        area_hecta: '214.6000',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 6.0,
        data_de_cr: '28/09/1998',
        forma_obte: 'Desapropriação',
        data_obten: '13/05/1998',
        area_calc_: 229.1532,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.744021665690994, -8.709767441560933],
              [-35.74085969059589, -8.723801104043135],
              [-35.740361949340041, -8.728479648423894],
              [-35.739146668496176, -8.737296667375595],
              [-35.744593482708268, -8.735182297736488],
              [-35.745823498336549, -8.732083151222263],
              [-35.748168129015333, -8.725595668576396],
              [-35.749637692135657, -8.725935765834988],
              [-35.750820220011619, -8.722906809950635],
              [-35.751389416818157, -8.714508881018391],
              [-35.744021665690994, -8.709767441560933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0252000',
        uf: 'PE',
        nome_proje: 'PA FLORESTA',
        municipio: 'GRAVATA',
        area_hecta: '510.8955',
        capacidade: 36.0,
        num_famili: 21.0,
        fase: 4.0,
        data_de_cr: '27/05/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/10/2001',
        area_calc_: 509.1337,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.644335080129089, -8.130182641069787],
              [-35.643039472727921, -8.130880897875688],
              [-35.642225214522, -8.131637286837053],
              [-35.641592742419313, -8.131780377947649],
              [-35.640733692368343, -8.132496755176959],
              [-35.639249515469274, -8.133163338095256],
              [-35.638441976438912, -8.134020072611332],
              [-35.63639957695937, -8.133637005312877],
              [-35.63451217355594, -8.134367367709634],
              [-35.634217084703806, -8.134546574188411],
              [-35.632531584512598, -8.135535233215792],
              [-35.632192449061456, -8.135774183274233],
              [-35.628656631664043, -8.136720694162086],
              [-35.625424668567092, -8.138488336204718],
              [-35.624064319166898, -8.139700325550836],
              [-35.622360057782444, -8.141618740608711],
              [-35.621072131099389, -8.145037763408387],
              [-35.62656440867444, -8.14762900187649],
              [-35.63252226753427, -8.14954012613863],
              [-35.633892929452308, -8.144458154544637],
              [-35.637807036708132, -8.143167007820995],
              [-35.647971457991559, -8.155907880194761],
              [-35.649624528769444, -8.154661462143352],
              [-35.653499255260741, -8.148032205332397],
              [-35.652559331285531, -8.145474795687521],
              [-35.652366375160724, -8.137791880018098],
              [-35.650591002790037, -8.136385247761137],
              [-35.650013137757902, -8.135137417657196],
              [-35.65001340978953, -8.135137415881742],
              [-35.647931440145982, -8.133699603183304],
              [-35.646788622469899, -8.132752286684836],
              [-35.645603315084045, -8.131160417270829],
              [-35.645603587113975, -8.13116041549919],
              [-35.644335080129089, -8.130182641069787],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0234000',
        uf: 'PE',
        nome_proje: 'PA VALENTIM',
        municipio: 'GRAVATA',
        area_hecta: '329.2126',
        capacidade: 26.0,
        num_famili: 21.0,
        fase: 4.0,
        data_de_cr: '17/10/2001',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2000',
        area_calc_: 322.9686,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.589477772257645, -8.119342864258181],
              [-35.590334461501399, -8.124774376765387],
              [-35.590060963762383, -8.125891507934586],
              [-35.589741411964646, -8.127123878472739],
              [-35.594202010837122, -8.128231331220871],
              [-35.601630631534711, -8.130059826887191],
              [-35.60197027244859, -8.130134823922125],
              [-35.604579192849251, -8.130815545710259],
              [-35.60655610749123, -8.131322465401722],
              [-35.608186243510787, -8.13171620469361],
              [-35.609717553308528, -8.132070630158152],
              [-35.611411042929198, -8.132514732422552],
              [-35.612845410586083, -8.132868592750899],
              [-35.614138884748911, -8.133184768475799],
              [-35.615480145760905, -8.133514276698126],
              [-35.61680198965243, -8.133828000809375],
              [-35.618297308945237, -8.134183163039122],
              [-35.618747805093619, -8.134291857918855],
              [-35.618937500751137, -8.123657131501869],
              [-35.618204381407203, -8.123551066272723],
              [-35.616604831529742, -8.123323433409567],
              [-35.615032122016409, -8.123095621057034],
              [-35.613189087494888, -8.122825170292842],
              [-35.611308234888661, -8.122568146447932],
              [-35.609532180522606, -8.122334658444426],
              [-35.607619495495911, -8.122063181185883],
              [-35.607319957136603, -8.122018382968394],
              [-35.601456254685949, -8.121153634832796],
              [-35.601096470546729, -8.121103704623478],
              [-35.599052198662804, -8.120929806241605],
              [-35.589477772257645, -8.119342864258181],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0079000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO TAPUIA',
        municipio: 'AMARAJI',
        area_hecta: '239.4300',
        capacidade: 23.0,
        num_famili: 22.0,
        fase: 5.0,
        data_de_cr: '12/08/1996',
        forma_obte: 'Desapropriação',
        data_obten: '11/10/1995',
        area_calc_: 184.2105,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.415775116361509, -8.368687746482282],
              [-35.413727028884516, -8.370613996174013],
              [-35.413404649221228, -8.370918367341055],
              [-35.414077202861037, -8.371653721211253],
              [-35.414936934930019, -8.37226402196247],
              [-35.415360740352362, -8.372807667666917],
              [-35.416543931921161, -8.373979303153051],
              [-35.416684405428441, -8.374918632521311],
              [-35.416987696093535, -8.375212126940303],
              [-35.41731775157681, -8.375313316266698],
              [-35.418030089899446, -8.375067377036112],
              [-35.418387153566172, -8.375250913851289],
              [-35.418535304999253, -8.375507760354578],
              [-35.418402671254839, -8.37622037913331],
              [-35.418494588444979, -8.376702698200635],
              [-35.418129352829418, -8.377738031152973],
              [-35.41802303910972, -8.378024995693274],
              [-35.416531866927549, -8.382343472000606],
              [-35.416464240047567, -8.382678912778502],
              [-35.416748780450504, -8.382800714435987],
              [-35.417180511803522, -8.383008646559478],
              [-35.420172408454874, -8.384344952740335],
              [-35.421234436939613, -8.384817790170723],
              [-35.423887281337329, -8.385946740501051],
              [-35.424624824784374, -8.386236741738205],
              [-35.426669544381078, -8.387074329758228],
              [-35.427336739324055, -8.386879257886017],
              [-35.42815526328198, -8.386179587926845],
              [-35.428345883360528, -8.381858986004421],
              [-35.428401912804205, -8.381075081588438],
              [-35.428656592073843, -8.378169554066984],
              [-35.428753042836568, -8.377844781983885],
              [-35.42883871527539, -8.376499641516601],
              [-35.428086856705917, -8.375961559388571],
              [-35.428086856705917, -8.375961559388571],
              [-35.428020827736731, -8.375938467772414],
              [-35.426540214528792, -8.374957773027122],
              [-35.423984495790251, -8.373481993717567],
              [-35.423844334843871, -8.373372775819687],
              [-35.42239568230687, -8.372567916765318],
              [-35.421359525628745, -8.371943534202009],
              [-35.420286692960964, -8.371287289061053],
              [-35.420014856813665, -8.371134501272218],
              [-35.418035919596235, -8.369749759843002],
              [-35.417648345709445, -8.369474133346442],
              [-35.417275592983444, -8.369247219055035],
              [-35.41611761679988, -8.368438652296442],
              [-35.415775116361509, -8.368687746482282],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0394000',
        uf: 'PE',
        nome_proje: 'PA OZIEL NUNES ALVES',
        municipio: 'SAO BENTO DO UNA',
        area_hecta: '317.7477',
        capacidade: 16.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '11/11/2011',
        forma_obte: 'Desapropriação',
        data_obten: '16/07/2008',
        area_calc_: 316.6195,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.394307335344045, -8.553141637574003],
              [-36.393353749317271, -8.546081266893905],
              [-36.389224791100418, -8.549486062829141],
              [-36.389188566106746, -8.549366629501487],
              [-36.386908559403366, -8.542967278157541],
              [-36.386905921409493, -8.542968073621907],
              [-36.386778010235219, -8.543035344063412],
              [-36.386533207418942, -8.543231315577717],
              [-36.386213952315622, -8.543495911893439],
              [-36.385819995334302, -8.543839070958098],
              [-36.385297990640545, -8.544349258802537],
              [-36.384999412468282, -8.544683211134807],
              [-36.384884941729695, -8.544776234497979],
              [-36.384598644253572, -8.54485336788952],
              [-36.384019985961245, -8.544978225070487],
              [-36.383967954012761, -8.54500715099373],
              [-36.383845955986054, -8.545045815080375],
              [-36.383750127009705, -8.545134084384062],
              [-36.383664591791785, -8.545257032140054],
              [-36.383594840993808, -8.545394906028204],
              [-36.383477288739776, -8.545566975342316],
              [-36.383370434521169, -8.54571444788875],
              [-36.383295418565282, -8.545852286073524],
              [-36.383151224753568, -8.546043964530751],
              [-36.383007097989378, -8.546225793813104],
              [-36.382756477204197, -8.546515518829732],
              [-36.3822714103569, -8.547025950031818],
              [-36.381967967184735, -8.547300587829362],
              [-36.381728790211959, -8.547536081236567],
              [-36.382002618672622, -8.548527675082404],
              [-36.382281850825805, -8.549671928101757],
              [-36.381747036160057, -8.549596742307163],
              [-36.38177083020885, -8.550087120229449],
              [-36.380875836052319, -8.55013970792986],
              [-36.380901796790255, -8.551495230818634],
              [-36.378163595194145, -8.551721473779789],
              [-36.375659102338261, -8.551690123376842],
              [-36.375995986592969, -8.553665556683763],
              [-36.376861360914546, -8.558695113646571],
              [-36.382070970493757, -8.561747457996056],
              [-36.38753291552468, -8.564221874942993],
              [-36.391284991218697, -8.564557087430055],
              [-36.393063901194516, -8.564424154584565],
              [-36.392733257216058, -8.562826197945544],
              [-36.392184760481896, -8.560424707404607],
              [-36.395381083770616, -8.560740396275822],
              [-36.395308066282844, -8.560517610013676],
              [-36.394484006545483, -8.554141434039845],
              [-36.394307335344045, -8.553141637574003],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0327000',
        uf: 'PE',
        nome_proje: 'PA AÇUDINHO',
        municipio: 'SAO JOSE DO EGITO',
        area_hecta: '907.1893',
        capacidade: 29.0,
        num_famili: 28.0,
        fase: 5.0,
        data_de_cr: '21/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '30/07/2004',
        area_calc_: 964.7978,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.241667705150221, -7.475976326330112],
              [-37.241506754525183, -7.475900729266652],
              [-37.241868181807043, -7.479372363226687],
              [-37.241769288267932, -7.479590325191035],
              [-37.241192408091905, -7.479681285867438],
              [-37.236081382346718, -7.48048714065022],
              [-37.236076809994842, -7.48045267369733],
              [-37.236076593631608, -7.480416325429118],
              [-37.236062169756373, -7.480376122822959],
              [-37.236062322135176, -7.480315634845202],
              [-37.236057755435446, -7.480257207562881],
              [-37.236068911065551, -7.480186365722201],
              [-37.23607705612902, -7.480120755987243],
              [-37.236083177684449, -7.480062733134972],
              [-37.236109321615679, -7.480004338309575],
              [-37.236133443852651, -7.47995307829019],
              [-37.236166103403377, -7.479896427438415],
              [-37.236178383292852, -7.479838881301043],
              [-37.236202363182123, -7.479777946145846],
              [-37.236224982749619, -7.479717367211273],
              [-37.236249520691175, -7.479652817625265],
              [-37.236265536198161, -7.479589951855218],
              [-37.23629160545029, -7.479527578383842],
              [-37.236319040089128, -7.479463582875026],
              [-37.236348208648657, -7.479396429722964],
              [-37.236358252039274, -7.479331822142055],
              [-37.236374188173549, -7.479266153133701],
              [-37.236385784663931, -7.479198296770677],
              [-37.236404910335224, -7.479127938861539],
              [-37.236396746079841, -7.479062987198717],
              [-37.236401369619969, -7.478994470027039],
              [-37.236403551204027, -7.478924948506676],
              [-37.236400661165483, -7.478855045035157],
              [-37.236396138680199, -7.478785587116561],
              [-37.236389613400114, -7.478718562431538],
              [-37.236385995002969, -7.478649560211416],
              [-37.236380574596197, -7.47857810953995],
              [-37.236374785291979, -7.478508284889198],
              [-37.236369452569676, -7.478437557900269],
              [-37.236362215994689, -7.478367184962669],
              [-37.23635451884369, -7.478298708928548],
              [-37.236350000344146, -7.47822825644593],
              [-37.236341827733241, -7.478165384330209],
              [-37.236341519752614, -7.47806158501929],
              [-37.236337883394228, -7.47801961724528],
              [-37.236331638227533, -7.477950514103972],
              [-37.236326580536172, -7.477878974468011],
              [-37.236310441781356, -7.477814623830493],
              [-37.236301950006535, -7.477740990882669],
              [-37.236323739354091, -7.477661511553206],
              [-37.236302518105553, -7.477599491418214],
              [-37.236305913982626, -7.477520842691616],
              [-37.236300490656049, -7.477450115341973],
              [-37.236296968271915, -7.477379757260383],
              [-37.236291633355549, -7.477309572763144],
              [-37.236279142899576, -7.477239088400697],
              [-37.236256002366126, -7.47715870605478],
              [-37.236252808583821, -7.477096758017702],
              [-37.23623769246916, -7.477025901490186],
              [-37.236227736366253, -7.476955969763069],
              [-37.236218059656991, -7.476884140408234],
              [-37.23620638669847, -7.476813116818548],
              [-37.236188549257434, -7.476743153574147],
              [-37.236177057845467, -7.476672040294797],
              [-37.236164030032896, -7.476600016707144],
              [-37.236149082044534, -7.476532415846626],
              [-37.236146583371593, -7.476455280629185],
              [-37.236142343522147, -7.476383111353015],
              [-37.236130760071788, -7.476312359372982],
              [-37.236119727422015, -7.476239801267102],
              [-37.236101590278764, -7.476176798893551],
              [-37.236087554369931, -7.476107664603155],
              [-37.236078974187244, -7.476033488809845],
              [-37.236058689752724, -7.475963787033828],
              [-37.236035052405789, -7.475894343099191],
              [-37.236014816465214, -7.475835129824619],
              [-37.235984996146939, -7.475771357400018],
              [-37.235953445553292, -7.475709838466593],
              [-37.235923703134695, -7.475649230928262],
              [-37.235901598584086, -7.475581601452812],
              [-37.235885660681468, -7.475512369143163],
              [-37.235872082835876, -7.475441970858517],
              [-37.235861032795441, -7.47537375267759],
              [-37.235851702510374, -7.475305903040242],
              [-37.235839956428613, -7.475230539178276],
              [-37.235835950977751, -7.475167683739733],
              [-37.235835608680375, -7.475094987559209],
              [-37.235842148355424, -7.475068160031009],
              [-37.235811740688177, -7.475060443449206],
              [-37.23575724340931, -7.475050822198608],
              [-37.235696018898587, -7.475047051105199],
              [-37.235632344837825, -7.475044174370851],
              [-37.235566307097365, -7.47504336775128],
              [-37.235500174776298, -7.475043555324191],
              [-37.235430860199294, -7.475046623483612],
              [-37.235361373884174, -7.475047340118272],
              [-37.23528772298571, -7.475047407167677],
              [-37.235209844147349, -7.475040043146141],
              [-37.235137423936756, -7.475026914299628],
              [-37.235067996461609, -7.475012983667763],
              [-37.235000818483933, -7.475002859514444],
              [-37.234931934559938, -7.474988931037668],
              [-37.234858878432789, -7.474976251686786],
              [-37.234786934085108, -7.474957428459251],
              [-37.234718512210755, -7.474941241391599],
              [-37.234645393846272, -7.474921509282055],
              [-37.234574551855545, -7.474898892947525],
              [-37.234504440403498, -7.474874832864895],
              [-37.23443515371261, -7.474848425248068],
              [-37.234363960654129, -7.474823004572499],
              [-37.234292515817501, -7.474792609979763],
              [-37.23422253459497, -7.474758694997896],
              [-37.234155826963054, -7.474721718954316],
              [-37.234091675834584, -7.474679780236944],
              [-37.234033246395754, -7.474634247762353],
              [-37.233967773873687, -7.474582990859453],
              [-37.233918514353654, -7.474532431766998],
              [-37.233873931346324, -7.474490390528614],
              [-37.233805164577426, -7.474447348309918],
              [-37.233734700858051, -7.474375727727748],
              [-37.233701666465535, -7.474345396420115],
              [-37.233650335180577, -7.474314449360207],
              [-37.233593759525384, -7.474281040058215],
              [-37.233537253391987, -7.474252875176184],
              [-37.233478571666289, -7.474225063242955],
              [-37.233424920801802, -7.474207759740673],
              [-37.233354075755535, -7.474185956984721],
              [-37.233289129209091, -7.474161646181709],
              [-37.233227957624159, -7.474144764625056],
              [-37.233158345962892, -7.474131646746894],
              [-37.233079891803378, -7.474109723064869],
              [-37.233009991227433, -7.474078339915388],
              [-37.232967877329926, -7.474075639594425],
              [-37.232886661164855, -7.474064735608794],
              [-37.232806957694145, -7.474038105233032],
              [-37.232733780670877, -7.474010506407616],
              [-37.232662717027615, -7.473975501902085],
              [-37.232593388775271, -7.473936978104237],
              [-37.232527965332402, -7.47389611911724],
              [-37.232464989633236, -7.473854817847628],
              [-37.232404275407781, -7.473814339377944],
              [-37.232343209382442, -7.47377123742113],
              [-37.232268064893631, -7.473714787863083],
              [-37.232231721087999, -7.473696468583817],
              [-37.232166207496313, -7.473655518781236],
              [-37.23208802887644, -7.473610178245607],
              [-37.232029757726963, -7.473570432858498],
              [-37.23197388012737, -7.473543716997758],
              [-37.231920149036448, -7.473523881367965],
              [-37.23184726358982, -7.473491401118123],
              [-37.231778838487976, -7.473453604174489],
              [-37.231714775158672, -7.473434992946294],
              [-37.231643857233074, -7.473386336047358],
              [-37.231582944889311, -7.473350106265239],
              [-37.231504870449754, -7.473301420668741],
              [-37.231467803091086, -7.473260313550861],
              [-37.231407291706248, -7.473237014879484],
              [-37.231331201176708, -7.473190597618459],
              [-37.231282695965724, -7.473155321699498],
              [-37.231225303933137, -7.473122270560104],
              [-37.231165647182451, -7.473089210336907],
              [-37.231106972732263, -7.4730596802778],
              [-37.231018332584462, -7.473025238025499],
              [-37.230961667505134, -7.472969133640895],
              [-37.230907271880668, -7.472934376575534],
              [-37.230843678028791, -7.472889184764345],
              [-37.230801275456784, -7.472868219035195],
              [-37.230739527591389, -7.472837049125577],
              [-37.230671962022384, -7.472810828773739],
              [-37.230610085011584, -7.472789242448582],
              [-37.230534364630209, -7.472763441461867],
              [-37.230455360164015, -7.4727208098843],
              [-37.230376925778678, -7.472694093830312],
              [-37.230323657673111, -7.472671818665439],
              [-37.23025363206856, -7.47264911462637],
              [-37.230185789709225, -7.472624158929549],
              [-37.230136824758119, -7.4726131126095],
              [-37.230041224333, -7.472597448845836],
              [-37.229970791326501, -7.472563441113316],
              [-37.229911547309662, -7.472540418608171],
              [-37.229846952364205, -7.472518911707168],
              [-37.229850119336156, -7.472519828572308],
              [-37.229771941884991, -7.472496820520161],
              [-37.229700906769438, -7.472477367337999],
              [-37.229555403653777, -7.472436096351505],
              [-37.229484410442026, -7.472406245450711],
              [-37.229423548544489, -7.472402565453294],
              [-37.229141914929208, -7.47233217664955],
              [-37.229083175207329, -7.472318921014877],
              [-37.229009225305646, -7.472303434347323],
              [-37.228859356613633, -7.472266485613114],
              [-37.228800416432996, -7.472258021193995],
              [-37.228738451853218, -7.472258224574955],
              [-37.228660744128526, -7.472253572703805],
              [-37.228591645989539, -7.472248051217076],
              [-37.228379400775715, -7.472246114226125],
              [-37.228239777194183, -7.472252063649528],
              [-37.228165117083144, -7.472255470958964],
              [-37.228087756438114, -7.472254617856973],
              [-37.228010496225295, -7.472251323910774],
              [-37.227933606389996, -7.472246042285039],
              [-37.227853374251161, -7.472238396407107],
              [-37.227776690024697, -7.472227057704452],
              [-37.22770047442274, -7.472211832980901],
              [-37.227627163501609, -7.472195173254093],
              [-37.227553496430808, -7.472176975013094],
              [-37.227478113650868, -7.472157413629403],
              [-37.227401662408262, -7.47213323672449],
              [-37.227330743286949, -7.472107544956795],
              [-37.22725601361671, -7.472083284529524],
              [-37.227180865286456, -7.472050523302542],
              [-37.227111067536811, -7.472016336903196],
              [-37.227045146517547, -7.471986777286853],
              [-37.2269755259588, -7.471953586158822],
              [-37.226906628663301, -7.471920759592092],
              [-37.226841631797562, -7.47188668286363],
              [-37.226777706326274, -7.471856498321261],
              [-37.226712412189372, -7.471828749504628],
              [-37.226644659405061, -7.471804155361286],
              [-37.22657780631657, -7.471781101896592],
              [-37.226513760025341, -7.4717584213663],
              [-37.226441216650606, -7.471731185882058],
              [-37.226367122338559, -7.471706656635053],
              [-37.226295041048473, -7.471677162587933],
              [-37.226223849241094, -7.471651740825642],
              [-37.226148139689244, -7.471623407584449],
              [-37.226081555517048, -7.471601078465403],
              [-37.226008190345844, -7.47157546710647],
              [-37.225934923070916, -7.471548047811268],
              [-37.225874036154522, -7.471528182797988],
              [-37.225793845497293, -7.471510319745501],
              [-37.225709350750982, -7.471481499045201],
              [-37.225638763957186, -7.471463312912532],
              [-37.225565279069436, -7.471444934286453],
              [-37.225528979809113, -7.471460611139423],
              [-37.225428026910052, -7.47144700451719],
              [-37.225356689524617, -7.471435234857755],
              [-37.225280908565409, -7.471424622727165],
              [-37.225202405578806, -7.47141508462981],
              [-37.225122918535426, -7.471402468420069],
              [-37.225046236092261, -7.47139076763525],
              [-37.224970555239615, -7.471377805043149],
              [-37.224897132535091, -7.47136647900777],
              [-37.224822348090015, -7.471355870811407],
              [-37.224752646461042, -7.471342841817557],
              [-37.224679565685506, -7.471336580415244],
              [-37.224605496839374, -7.471328235460726],
              [-37.224534685531196, -7.471320807757581],
              [-37.224459517220836, -7.471315532497828],
              [-37.224386433179149, -7.471310084778911],
              [-37.224310533233101, -7.471306524453299],
              [-37.224231649716657, -7.471301505456556],
              [-37.224151324824987, -7.471294491497071],
              [-37.224072915077784, -7.471284320673425],
              [-37.224004832779137, -7.471274100977119],
              [-37.223932117695369, -7.471267027184489],
              [-37.223855793691392, -7.471256322222303],
              [-37.223779924097094, -7.47124525741295],
              [-37.223706688512557, -7.471232485290488],
              [-37.223633166558358, -7.471223328638977],
              [-37.223560100839578, -7.471213360069434],
              [-37.223486747287815, -7.471207368627913],
              [-37.223409316900018, -7.471201451180505],
              [-37.223333519665061, -7.471194907383013],
              [-37.223254727174499, -7.471189798169164],
              [-37.223176583057281, -7.471181165333787],
              [-37.223100965553755, -7.471174983884809],
              [-37.22302345666305, -7.4711660823334],
              [-37.222946658964332, -7.471160529018404],
              [-37.222868058275161, -7.471152798450988],
              [-37.222788562307336, -7.471142442206292],
              [-37.222714522841976, -7.47112686380209],
              [-37.222642187018714, -7.471115632206413],
              [-37.222565869337892, -7.471103389990646],
              [-37.222562791529256, -7.471102835101293],
              [-37.222489381453393, -7.471088434602793],
              [-37.222413068905112, -7.471074926561958],
              [-37.222337401448875, -7.471058708632898],
              [-37.222270325628614, -7.471046051512857],
              [-37.222196808543714, -7.471035719250024],
              [-37.222121397154957, -7.471023390197952],
              [-37.222049775855218, -7.47101469303272],
              [-37.221977427661429, -7.471006535423125],
              [-37.221904512908701, -7.471004071714439],
              [-37.221832757158474, -7.47100622386801],
              [-37.221765422315798, -7.47101282419395],
              [-37.221687755296443, -7.471020648701925],
              [-37.221616352395579, -7.471025153051123],
              [-37.221539023593458, -7.471038946335247],
              [-37.221464807704542, -7.47104461472932],
              [-37.221405726150856, -7.471071230137714],
              [-37.22131750261596, -7.47109094691748],
              [-37.221238196592388, -7.471100844298517],
              [-37.22116250458658, -7.471113107039328],
              [-37.221085633103698, -7.471125817091112],
              [-37.221009943290312, -7.471137537318098],
              [-37.220935972091077, -7.471149897368598],
              [-37.220863531377837, -7.471164614384165],
              [-37.220788941424729, -7.471173174453059],
              [-37.220717957437394, -7.471186089000257],
              [-37.220645162050218, -7.471198905816772],
              [-37.220570730219087, -7.471213162674375],
              [-37.220487435379162, -7.471223767155604],
              [-37.22041767880205, -7.471224390061973],
              [-37.220351232270687, -7.471235333694858],
              [-37.220275564772876, -7.471241538518555],
              [-37.220265594947172, -7.471242764137001],
              [-37.220173664948753, -7.471260566992282],
              [-37.220104667304049, -7.471275116925803],
              [-37.219711052546529, -7.471387634007094],
              [-37.219673253261497, -7.471415781643577],
              [-37.219625607837862, -7.471459350638193],
              [-37.21957467047342, -7.471510501262244],
              [-37.219518839135866, -7.471562174628392],
              [-37.219463192623557, -7.471612944573269],
              [-37.219406386028176, -7.471659369875784],
              [-37.219351476281716, -7.471707159056129],
              [-37.219296308308607, -7.47175160180549],
              [-37.219247012082064, -7.471800136941932],
              [-37.219190970195584, -7.471859042651221],
              [-37.219153005904076, -7.471905543936888],
              [-37.21910593955694, -7.471962677534312],
              [-37.219058953163461, -7.472022433500158],
              [-37.219008696420168, -7.472084436651136],
              [-37.21895555878433, -7.472141997799865],
              [-37.218900676878377, -7.472205248084322],
              [-37.21885386783552, -7.472243576225186],
              [-37.218824444524188, -7.472306115505156],
              [-37.218779326703455, -7.472374197203009],
              [-37.21873805094733, -7.472433072006755],
              [-37.218699586716248, -7.472491144412041],
              [-37.218657946018297, -7.472550650639255],
              [-37.218618205466129, -7.472610707023983],
              [-37.218571190715373, -7.4726774247931],
              [-37.218530723598185, -7.472737930309179],
              [-37.218500295946669, -7.472780031563746],
              [-37.218466918158384, -7.47283477909061],
              [-37.218433268961881, -7.472889435101505],
              [-37.218396965206814, -7.472950861561009],
              [-37.21836529024732, -7.473010136729463],
              [-37.21833941220769, -7.473069616130709],
              [-37.218297738344702, -7.473114926914262],
              [-37.218258466985809, -7.473126251125482],
              [-37.218199255467255, -7.473117784213422],
              [-37.215229038478213, -7.477500785190977],
              [-37.21012563389732, -7.484852465763053],
              [-37.209380744011824, -7.485706574474362],
              [-37.208738311932422, -7.486671404305335],
              [-37.208759075088025, -7.487665602743499],
              [-37.212026712090797, -7.48947516027779],
              [-37.212022490494313, -7.489644038814078],
              [-37.21325913181699, -7.490722469252125],
              [-37.212990503781832, -7.491505098638765],
              [-37.213120955924246, -7.491505718611032],
              [-37.213791044334492, -7.492847760300116],
              [-37.213764100493002, -7.493079294989966],
              [-37.215123118588991, -7.493096924290204],
              [-37.215656715822121, -7.494276748630737],
              [-37.229439211718329, -7.497800689495794],
              [-37.230894836693942, -7.498108626877704],
              [-37.230718261055337, -7.495237928433952],
              [-37.229843581668874, -7.494964699151383],
              [-37.229297473509398, -7.492946675894697],
              [-37.237664950370842, -7.494511282420501],
              [-37.242856129466325, -7.494292800042818],
              [-37.243051980120669, -7.494206601184667],
              [-37.243230424338392, -7.494078198545123],
              [-37.243466409469498, -7.494014402195399],
              [-37.243666944056663, -7.493980120906442],
              [-37.243946013489328, -7.493926080397576],
              [-37.244293753847415, -7.493849528647856],
              [-37.244634039363817, -7.493779456867247],
              [-37.244965528881401, -7.493711429305959],
              [-37.24531356799659, -7.49362809659972],
              [-37.245799691874737, -7.493653633384601],
              [-37.24623038495902, -7.493721083003681],
              [-37.246660306141202, -7.493800373765556],
              [-37.247129968772896, -7.493887236626176],
              [-37.247622398915361, -7.493989831816759],
              [-37.248182564556181, -7.494122714585535],
              [-37.248802414742606, -7.494237570010959],
              [-37.249402360683405, -7.494345835427624],
              [-37.249931055847938, -7.494442152930031],
              [-37.250453771790575, -7.494538536492644],
              [-37.250946239632292, -7.494632267575486],
              [-37.251498171090468, -7.494740610765502],
              [-37.25197940170019, -7.494767206635687],
              [-37.252202046159333, -7.494527491075548],
              [-37.252170479746432, -7.494151049600275],
              [-37.252152409456926, -7.493638945618295],
              [-37.252187106778734, -7.49304838828526],
              [-37.252549067383129, -7.491893997428189],
              [-37.252565437612205, -7.491842911963656],
              [-37.252919289057431, -7.491619177762684],
              [-37.253372505165153, -7.491650181025741],
              [-37.253871353051679, -7.491642847110926],
              [-37.254334536144547, -7.491628047397686],
              [-37.254826996777794, -7.491586690168072],
              [-37.255366252865272, -7.491556006235237],
              [-37.255784384472307, -7.491638767520628],
              [-37.256880292759533, -7.487542409647395],
              [-37.257011285574784, -7.487156574589106],
              [-37.256458866822165, -7.486944439089021],
              [-37.248947662316652, -7.482521280142944],
              [-37.25040570775009, -7.477760010014966],
              [-37.250379814203271, -7.477756019349328],
              [-37.250306332284765, -7.47775889240772],
              [-37.25023776605201, -7.477755817429256],
              [-37.250152226530155, -7.47776090304745],
              [-37.250031580562293, -7.477755089705962],
              [-37.24996192508884, -7.477752552865279],
              [-37.249882186282207, -7.477756938113117],
              [-37.249804808246516, -7.477759976459186],
              [-37.249722719121309, -7.477763086510662],
              [-37.249652706339454, -7.477759282364035],
              [-37.249572606258418, -7.477763394869696],
              [-37.249492967060583, -7.477765520014413],
              [-37.249412420514361, -7.477768003211031],
              [-37.249331906400002, -7.477762348986184],
              [-37.249268061028445, -7.477756760921505],
              [-37.249158707647929, -7.477758858457924],
              [-37.249034062781234, -7.477756283983417],
              [-37.248966487337832, -7.477754659415657],
              [-37.248843453660051, -7.477756973805069],
              [-37.248843640969184, -7.47775543745925],
              [-37.248798792685903, -7.477756706040908],
              [-37.248715609297463, -7.477761619898336],
              [-37.248635701603263, -7.477762930067704],
              [-37.248562878389293, -7.477759657090744],
              [-37.248485866006092, -7.477761882901921],
              [-37.248399288120687, -7.477754757753612],
              [-37.248258715439285, -7.477725356362313],
              [-37.248168952741842, -7.477699050115574],
              [-37.248117081657192, -7.477689440691003],
              [-37.248042249530194, -7.477667443332544],
              [-37.247971218984873, -7.477646274819083],
              [-37.247875044936968, -7.477614789280342],
              [-37.247835330361994, -7.477600888108836],
              [-37.247771372783212, -7.4775780297467],
              [-37.247699711023898, -7.477556135351827],
              [-37.247618132611372, -7.477522085667264],
              [-37.247562181679626, -7.477490579064973],
              [-37.247433988757301, -7.477469092911355],
              [-37.247193286323537, -7.477400685252001],
              [-37.246935154125694, -7.477341340306303],
              [-37.246727060234811, -7.477274147046134],
              [-37.246665613371384, -7.477280503198302],
              [-37.246476137245111, -7.477247109364681],
              [-37.246371027465081, -7.477230145137039],
              [-37.246313872304491, -7.477228019114881],
              [-37.246254800664907, -7.477229411724245],
              [-37.24604127657215, -7.477229014675834],
              [-37.245967267925629, -7.47725042039398],
              [-37.245939284063837, -7.477248139090748],
              [-37.245858379737896, -7.477226840587424],
              [-37.245796948393739, -7.477252003392704],
              [-37.245720401788752, -7.47725106599892],
              [-37.245720126406241, -7.477251969072387],
              [-37.245622466037332, -7.477252484763104],
              [-37.245567917809566, -7.477255432360024],
              [-37.245497965195348, -7.477259313253531],
              [-37.245426832375976, -7.477263822358448],
              [-37.245356332609155, -7.477268605220804],
              [-37.245278846888837, -7.477275982365462],
              [-37.245203924433042, -7.477276678846779],
              [-37.245143567935159, -7.477282225368147],
              [-37.245058292142893, -7.477289390641577],
              [-37.24498799740195, -7.477288206746484],
              [-37.244911151962228, -7.47729404928566],
              [-37.244826910600693, -7.47729172486045],
              [-37.24474693495165, -7.477287428226191],
              [-37.244666332398296, -7.477281320745733],
              [-37.244586742966426, -7.477271058109089],
              [-37.244505078628151, -7.477258617195774],
              [-37.244423627997179, -7.47723803960437],
              [-37.244353286780743, -7.477225824572383],
              [-37.24427119806014, -7.477206239000735],
              [-37.244201499303841, -7.477191946917399],
              [-37.244120854992907, -7.47717363290649],
              [-37.244046567211079, -7.477151546684678],
              [-37.243972818646085, -7.477130547600716],
              [-37.243894901478946, -7.47710989357466],
              [-37.243815008316936, -7.477084982078454],
              [-37.243745053019531, -7.477066891403529],
              [-37.243664079299315, -7.477040348078432],
              [-37.243588618963074, -7.477016991285125],
              [-37.243518777057652, -7.476993204772764],
              [-37.243442710805738, -7.476962793035738],
              [-37.243372449951131, -7.476930505657905],
              [-37.243301288626824, -7.476896858432868],
              [-37.243229430791537, -7.476856155916049],
              [-37.243159449339757, -7.476821970872248],
              [-37.24308851298548, -7.47677738408759],
              [-37.243024549580745, -7.476733457989456],
              [-37.242960585828342, -7.476689622300283],
              [-37.242901332791973, -7.476645805371276],
              [-37.242845929593898, -7.476613396285824],
              [-37.242780837932635, -7.476579773178228],
              [-37.242706059258438, -7.47654457438344],
              [-37.242633545719769, -7.476509294187017],
              [-37.242563212150337, -7.476472575999446],
              [-37.242491247968033, -7.476435851305859],
              [-37.242420198383677, -7.476396960245943],
              [-37.242351425508133, -7.476355094497639],
              [-37.242282017429858, -7.476313497460401],
              [-37.24221814405675, -7.476269752470446],
              [-37.242152618368408, -7.476231425892672],
              [-37.242086278827301, -7.476192734397273],
              [-37.242015755505903, -7.476158185388894],
              [-37.241940352623068, -7.476120542754758],
              [-37.241869221245203, -7.476079481301175],
              [-37.241799098314218, -7.476035440107297],
              [-37.241737245661277, -7.476007435629035],
              [-37.241667705150221, -7.475976326330112],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0315000',
        uf: 'PE',
        nome_proje: 'PA LAGOA DA OUTRA BANDA',
        municipio: 'SAO JOSE DO EGITO',
        area_hecta: '249.4805',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 4.0,
        data_de_cr: '12/05/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 249.2219,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.222121758557719, -7.513871761738991],
              [-37.220198791674306, -7.513340824067773],
              [-37.219961263273554, -7.513157764012558],
              [-37.220188082646452, -7.511587080623523],
              [-37.219084606614786, -7.511095808934278],
              [-37.218187085122615, -7.51139939937904],
              [-37.215261029306262, -7.51346797143202],
              [-37.214443152599472, -7.513124593345439],
              [-37.212696460081794, -7.516062307463204],
              [-37.2112146218075, -7.51885271784732],
              [-37.209803403534416, -7.520942147939538],
              [-37.209589361872318, -7.521373186594387],
              [-37.209710431333065, -7.521587241186558],
              [-37.206748014821535, -7.5261512129311],
              [-37.206616674812075, -7.526386296548941],
              [-37.203686205866163, -7.531271153753306],
              [-37.200120013475825, -7.537314916142457],
              [-37.203271206145196, -7.539085346217486],
              [-37.20761630029066, -7.531759493700338],
              [-37.208645175714004, -7.532045436724563],
              [-37.210260319990546, -7.529907035561113],
              [-37.213708563930361, -7.529033415670497],
              [-37.21627653065778, -7.529441887874881],
              [-37.219489539778991, -7.521712140777502],
              [-37.222121758557719, -7.513871761738991],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0305000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOSÉ II',
        municipio: 'AGUAS BELAS',
        area_hecta: '634.4500',
        capacidade: 23.0,
        num_famili: 21.0,
        fase: 4.0,
        data_de_cr: '10/05/2005',
        forma_obte: 'Desapropriação',
        data_obten: '21/11/2002',
        area_calc_: 634.8393,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.068877431722129, -8.926526753047675],
              [-37.067580967473376, -8.927779129866629],
              [-37.064606961271522, -8.930651983155949],
              [-37.06215359389752, -8.933022085157148],
              [-37.058836637293467, -8.936226212233167],
              [-37.058492584177067, -8.936558445753882],
              [-37.058170366814139, -8.936836009680981],
              [-37.05707447570677, -8.937780300874605],
              [-37.054825411325268, -8.939718199775275],
              [-37.052638979059978, -8.941602077686976],
              [-37.050623503033471, -8.943338759807302],
              [-37.048508270140516, -8.945161324953256],
              [-37.047602036704085, -8.945941955838498],
              [-37.037629329821641, -8.953067435285535],
              [-37.039286927384069, -8.958787106181175],
              [-37.040509111595817, -8.958377440002531],
              [-37.04154687500948, -8.958029586014753],
              [-37.043057059456203, -8.95750475155538],
              [-37.04500851463272, -8.956829702880082],
              [-37.045409579146209, -8.956693469500351],
              [-37.047813422652524, -8.955876921208276],
              [-37.050266105734238, -8.955043761648781],
              [-37.05204573886391, -8.954439219107213],
              [-37.052700692737105, -8.954170738318115],
              [-37.052754241648159, -8.954139584719485],
              [-37.054990694289941, -8.952838452054245],
              [-37.057165826669547, -8.951565186403943],
              [-37.059245655778597, -8.950343410995655],
              [-37.060749258496337, -8.949460120531185],
              [-37.061380958992686, -8.949068754779038],
              [-37.06338410064582, -8.947827825506248],
              [-37.064794453104966, -8.946954002766208],
              [-37.065251131783192, -8.946871384662609],
              [-37.065340579741871, -8.946855202488095],
              [-37.067392434419467, -8.946483991578139],
              [-37.069366159415125, -8.94612690360521],
              [-37.069594210572959, -8.945931557426974],
              [-37.069866513716114, -8.945551116783868],
              [-37.070138816251408, -8.945170675813378],
              [-37.070502828112978, -8.945347410136188],
              [-37.07149818010469, -8.944590229196836],
              [-37.071768915924991, -8.943593484178177],
              [-37.072055028821431, -8.9425460556561],
              [-37.072067965905333, -8.942498659522803],
              [-37.072186255366546, -8.942065433340963],
              [-37.075345794185921, -8.937858784989901],
              [-37.075387456682122, -8.937803314062112],
              [-37.074536646367349, -8.936785663743484],
              [-37.07701786744709, -8.936309912866966],
              [-37.079902428453892, -8.935756858870018],
              [-37.081979117215553, -8.935358622250444],
              [-37.082332940344905, -8.934597631133952],
              [-37.082319483849432, -8.934493507653405],
              [-37.082029360898183, -8.933313603945297],
              [-37.08177404691191, -8.932275180242554],
              [-37.080989859466953, -8.929085955951363],
              [-37.078021339827238, -8.929119953743374],
              [-37.077777787011918, -8.928181666861397],
              [-37.075689212017757, -8.928219480549078],
              [-37.071647725390065, -8.928292341937761],
              [-37.070344812470111, -8.928316035641442],
              [-37.068877431722129, -8.926526753047675],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0343000',
        uf: 'PE',
        nome_proje: 'PA MATA ESCURA II',
        municipio: 'AGUAS BELAS',
        area_hecta: '352.3329',
        capacidade: 15.0,
        num_famili: 15.0,
        fase: 3.0,
        data_de_cr: '14/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 352.4821,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.964710831739907, -9.171079861039495],
              [-36.960700235110615, -9.17483218944969],
              [-36.960293453610774, -9.17433262796764],
              [-36.956540724917055, -9.17899789479781],
              [-36.955646570640887, -9.179300542633866],
              [-36.955536090056334, -9.17933969145929],
              [-36.955523356925397, -9.179799550990626],
              [-36.955504394928205, -9.180489069847775],
              [-36.955492925765483, -9.180601366363447],
              [-36.955419584481113, -9.181334690318263],
              [-36.955380603151411, -9.181723531233301],
              [-36.954995918327796, -9.182765057926602],
              [-36.953970583767791, -9.185221888946561],
              [-36.95415890883946, -9.185187882646927],
              [-36.959205519076249, -9.184277321174068],
              [-36.96472116375088, -9.183304061248327],
              [-36.965895123041143, -9.183070945131542],
              [-36.968154879366992, -9.18262210291682],
              [-36.968640875999, -9.183160885107787],
              [-36.96907019294855, -9.183748523661595],
              [-36.969679771009808, -9.184680047895233],
              [-36.970191859492459, -9.185462412475163],
              [-36.970604092615829, -9.18630324419366],
              [-36.971091133136909, -9.186835883750694],
              [-36.971694262113246, -9.187495549073475],
              [-36.972577444316904, -9.18838910517773],
              [-36.973418703859146, -9.188912613462954],
              [-36.974692017043886, -9.189401482461472],
              [-36.977662683369914, -9.190540412200436],
              [-36.978002520894279, -9.190612192920192],
              [-36.978739843472518, -9.191242319410309],
              [-36.980218798324408, -9.191824076511546],
              [-36.980391433091334, -9.191974830006897],
              [-36.981040509040284, -9.192397722081267],
              [-36.981360701260193, -9.192661754353198],
              [-36.982094837180234, -9.193358480595784],
              [-36.986586661001688, -9.196095484574494],
              [-36.986772788407663, -9.196226062378212],
              [-36.987085980191694, -9.196426322176809],
              [-36.989115490985633, -9.197440418111166],
              [-36.989071098681151, -9.197520171981205],
              [-36.991425670418451, -9.198504611752307],
              [-36.991879678417213, -9.198805541613604],
              [-36.992677174180471, -9.195047648049067],
              [-36.982740894739997, -9.187807483515932],
              [-36.982612389391946, -9.187687712846703],
              [-36.981313771889482, -9.186476369243643],
              [-36.980555012283524, -9.185768613948342],
              [-36.97979625573398, -9.185060857679657],
              [-36.978375671206102, -9.18373573895035],
              [-36.977469915938094, -9.182890883940647],
              [-36.977052197538306, -9.182501105168736],
              [-36.973510669935578, -9.17919778723806],
              [-36.972624665892113, -9.178371205133638],
              [-36.971130384130312, -9.176977496456205],
              [-36.965383035438343, -9.171616618057914],
              [-36.965243436844858, -9.171498046684041],
              [-36.964710831739907, -9.171079861039495],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0392000',
        uf: 'PE',
        nome_proje: 'PA MARIA ALICE GONCALVES',
        municipio: 'TAQUARITINGA DO NORTE',
        area_hecta: '512.4502',
        capacidade: 25.0,
        num_famili: 15.0,
        fase: 3.0,
        data_de_cr: '29/08/2011',
        forma_obte: 'Desapropriação',
        data_obten: '19/02/2009',
        area_calc_: 523.1293,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.201921343745539, -7.89083565316891],
              [-36.200715997545537, -7.893038312413592],
              [-36.200751983727606, -7.893171189579776],
              [-36.200798987740754, -7.893242339966617],
              [-36.200531148361399, -7.893602134107922],
              [-36.198412894996096, -7.893601859946689],
              [-36.198588693072246, -7.890239522109566],
              [-36.198629741374006, -7.889176901709911],
              [-36.198706776216362, -7.887816271723839],
              [-36.198727998309408, -7.887557918061371],
              [-36.198000220977541, -7.887537865984563],
              [-36.19787448375596, -7.887532416025659],
              [-36.197784800648613, -7.887528562762635],
              [-36.19350357534185, -7.887344464972537],
              [-36.193597551047063, -7.88534164871099],
              [-36.193446305218657, -7.883247107963662],
              [-36.194074325975727, -7.868020720812353],
              [-36.189233484238329, -7.867667653072094],
              [-36.188966523581804, -7.874376312820727],
              [-36.188219659225282, -7.891578877027691],
              [-36.188170860324625, -7.892711366405538],
              [-36.188132851890465, -7.893936357000397],
              [-36.188123620761282, -7.894049187863057],
              [-36.188244462431506, -7.894369164822723],
              [-36.188035267474611, -7.897845175871224],
              [-36.188771912027043, -7.898096518645999],
              [-36.190942390798241, -7.899151867089902],
              [-36.191702512265415, -7.899644239606258],
              [-36.192973583370147, -7.900357790847333],
              [-36.193111714202544, -7.897993327714916],
              [-36.193149439748943, -7.897989063561055],
              [-36.196413940017599, -7.895516106739285],
              [-36.201805418708126, -7.895699235056924],
              [-36.202490227872893, -7.895831756921615],
              [-36.210418955601504, -7.895960281164728],
              [-36.220009769337111, -7.896157720562321],
              [-36.228154613112949, -7.896415469947162],
              [-36.233185375335381, -7.896617402916936],
              [-36.233258036317295, -7.895639891413808],
              [-36.23352601879531, -7.889835871677714],
              [-36.204388114597904, -7.888474717558934],
              [-36.201948233612121, -7.890515897450696],
              [-36.201921343745539, -7.89083565316891],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0088000',
        uf: 'PE',
        nome_proje: 'PA SANTO ANTONIO',
        municipio: 'CABROBO',
        area_hecta: '329.8786',
        capacidade: 9.0,
        num_famili: 9.0,
        fase: 4.0,
        data_de_cr: '17/06/2004',
        forma_obte: 'Confisco',
        data_obten: '15/02/2002',
        area_calc_: 339.7269,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.227119799431257, -8.395137932293842],
              [-39.22644563105758, -8.395920468119183],
              [-39.221120772085548, -8.398498059091402],
              [-39.219385187295615, -8.399774508848761],
              [-39.217998256502192, -8.401123030356777],
              [-39.215561072545974, -8.402628878749134],
              [-39.2200554927254, -8.402577547911632],
              [-39.246479333522558, -8.403456292331086],
              [-39.247080495396077, -8.403476086520429],
              [-39.247092270268524, -8.403279253567883],
              [-39.247132727098489, -8.402617112758906],
              [-39.247110467833672, -8.402179244102559],
              [-39.247035030959374, -8.401823087794764],
              [-39.246864459163788, -8.401418236980039],
              [-39.245234950973696, -8.399218806185891],
              [-39.245018928954011, -8.398756454644491],
              [-39.244432605351562, -8.397760389038574],
              [-39.244160602734979, -8.396816228687259],
              [-39.233643539736164, -8.395552282991126],
              [-39.227119799431257, -8.395137932293842],
            ],
          ],
          [
            [
              [-39.244311034822239, -8.396833864221559],
              [-39.244548024818556, -8.397711201450399],
              [-39.245134620171015, -8.398705729011418],
              [-39.245342819963419, -8.399151261110028],
              [-39.246982961868035, -8.401359730080591],
              [-39.247155727597182, -8.401786378621823],
              [-39.247238718610333, -8.402165776563905],
              [-39.247258896024064, -8.402615043585309],
              [-39.247218983447375, -8.403275646359807],
              [-39.247700223869479, -8.403266479504094],
              [-39.248889250486066, -8.403536726579819],
              [-39.2502836333895, -8.403910498540046],
              [-39.251227485384447, -8.404160904144163],
              [-39.251876390638088, -8.404263696252443],
              [-39.255287040055492, -8.403913527127822],
              [-39.256039925402099, -8.40383651507852],
              [-39.266140125274148, -8.402799559461654],
              [-39.266267209697652, -8.402675100945972],
              [-39.267070701490468, -8.401692510794682],
              [-39.267693121176372, -8.399466858937121],
              [-39.254422124135296, -8.399622821374207],
              [-39.254200976339462, -8.399396470110883],
              [-39.252838099625293, -8.397999759419465],
              [-39.252682670251446, -8.39784066201682],
              [-39.244311034822239, -8.396833864221559],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0219000',
        uf: 'PE',
        nome_proje: 'PA BOI BRANCO',
        municipio: 'IATI',
        area_hecta: '1201.4700',
        capacidade: 50.0,
        num_famili: 50.0,
        fase: 5.0,
        data_de_cr: '24/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/1997',
        area_calc_: 1209.0478,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.905919451972061, -8.902551431810972],
              [-36.903830387208387, -8.902447559646422],
              [-36.901435509204461, -8.902334177821501],
              [-36.899589858739333, -8.902233462723547],
              [-36.8979194088732, -8.903812966383819],
              [-36.895820525143371, -8.905808466684427],
              [-36.892687086871291, -8.908789882548504],
              [-36.892296757763297, -8.909140384293041],
              [-36.88631789790972, -8.914387282729743],
              [-36.884306018748326, -8.916185676083062],
              [-36.881599276134658, -8.918409637278033],
              [-36.881091806322765, -8.918877416424978],
              [-36.880433064877593, -8.919567690961095],
              [-36.878566618897899, -8.920036024756753],
              [-36.87453381017329, -8.917583955594596],
              [-36.874456116956324, -8.917532258848645],
              [-36.874395681039132, -8.917498648833115],
              [-36.870890838832331, -8.915194469885908],
              [-36.86886878144022, -8.913705788797628],
              [-36.867468584384483, -8.912654638414933],
              [-36.866380440302841, -8.911871297977822],
              [-36.863979593905619, -8.910079511431341],
              [-36.863923941600717, -8.910052164687187],
              [-36.863749394578484, -8.91007186158687],
              [-36.863584916693227, -8.910016862528892],
              [-36.863466183936644, -8.909910874307934],
              [-36.863385894579338, -8.909665181018156],
              [-36.862955211382236, -8.909322924835946],
              [-36.862181767880479, -8.908806502534389],
              [-36.859494583229981, -8.907258823935541],
              [-36.859406219607017, -8.907448317754932],
              [-36.859314158784272, -8.907476532327442],
              [-36.858470031924924, -8.90989072132648],
              [-36.857352920970122, -8.912929290525593],
              [-36.855099006027281, -8.918864196555988],
              [-36.854847254072119, -8.919495660051638],
              [-36.854407006145159, -8.920619749351268],
              [-36.853185650676089, -8.923765071101032],
              [-36.852653757509657, -8.92512300901776],
              [-36.859287363224674, -8.926771910310901],
              [-36.863347032467665, -8.927786533996606],
              [-36.863591599627895, -8.928138660452632],
              [-36.863660317060905, -8.928217515747697],
              [-36.865887689931185, -8.931359323082386],
              [-36.867016358578653, -8.932951010641892],
              [-36.867792803798693, -8.934382848268948],
              [-36.86792394394363, -8.935029721261534],
              [-36.868080580818294, -8.93623192602044],
              [-36.868996622397944, -8.936326137224995],
              [-36.870597470860332, -8.936432234234937],
              [-36.873591204229776, -8.936437393129326],
              [-36.875022178030051, -8.936304766233546],
              [-36.877549750657238, -8.93598144780465],
              [-36.877667749916036, -8.935949220477397],
              [-36.881631165931147, -8.935389848144283],
              [-36.880308443802853, -8.931514765298086],
              [-36.880296505364726, -8.930292864432083],
              [-36.881855388124663, -8.930454439867802],
              [-36.883217220699699, -8.930249066570983],
              [-36.885277679998993, -8.929091949630346],
              [-36.886348111402796, -8.928485095829545],
              [-36.887295590682335, -8.927854940034173],
              [-36.88834685622377, -8.92760782332409],
              [-36.890419640310569, -8.927132754982559],
              [-36.890786549809405, -8.92703378098048],
              [-36.895974692408664, -8.925653184270329],
              [-36.898389372338762, -8.924475930501774],
              [-36.899626821213126, -8.92349366785262],
              [-36.901006424136668, -8.922004553265504],
              [-36.902337682977333, -8.920319636611701],
              [-36.902632506595424, -8.920044245172617],
              [-36.902936538957931, -8.919986844357545],
              [-36.904070286161414, -8.919873019810447],
              [-36.910257867932721, -8.919228751788909],
              [-36.911950241458257, -8.91850200836303],
              [-36.915082483035953, -8.916000672745474],
              [-36.913950733708177, -8.914322660846022],
              [-36.911033470368892, -8.909596925258688],
              [-36.910968873161536, -8.909511139963723],
              [-36.910076153179332, -8.907557770699222],
              [-36.907925051496122, -8.902643614436217],
              [-36.905919451972061, -8.902551431810972],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0377000',
        uf: 'PE',
        nome_proje: 'PA ARMAZÉM',
        municipio: 'SAO BENTO DO UNA',
        area_hecta: '675.6600',
        capacidade: 64.0,
        num_famili: 62.0,
        fase: 4.0,
        data_de_cr: '07/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 689.0455,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.541843617598587, -8.502479394748255],
              [-36.544680117274687, -8.503205656088952],
              [-36.546724436386931, -8.503465308268492],
              [-36.548950669333465, -8.504109142642978],
              [-36.550022609896622, -8.504161968754198],
              [-36.5514882466111, -8.503992347247653],
              [-36.554686910413075, -8.503460553085665],
              [-36.555334615181401, -8.503147771570378],
              [-36.558565381273198, -8.499466136906603],
              [-36.558365165216159, -8.491898356866944],
              [-36.539513624805032, -8.485457025687321],
              [-36.534481165003967, -8.484686155678993],
              [-36.523569141081936, -8.482582163512195],
              [-36.523455215457552, -8.482611262822525],
              [-36.522445779398822, -8.486156030070893],
              [-36.520683016752997, -8.491235124183934],
              [-36.522432007370263, -8.498216846357442],
              [-36.529595687692122, -8.500982418665668],
              [-36.533551877709705, -8.501482426470615],
              [-36.538057146636483, -8.50176500863671],
              [-36.541843617598587, -8.502479394748255],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0292000',
        uf: 'PE',
        nome_proje: 'PA MUNDO NOVO',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '243.9809',
        capacidade: 16.0,
        num_famili: 14.0,
        fase: 3.0,
        data_de_cr: '25/02/2003',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 173.7074,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.228207701228108, -8.87585689117595],
              [-35.214375820950288, -8.882280787776033],
              [-35.213805892828248, -8.883901324189393],
              [-35.212898506570589, -8.886341199320199],
              [-35.212867841299698, -8.886427538436571],
              [-35.212164306918645, -8.888302332248156],
              [-35.212504459417261, -8.888926913521219],
              [-35.212902321810148, -8.88900259162663],
              [-35.21365239102505, -8.889262456247925],
              [-35.214356993577027, -8.889364131630007],
              [-35.215245016804978, -8.889217714593862],
              [-35.216205931999468, -8.888643693170447],
              [-35.216531165369382, -8.888634717898473],
              [-35.216862606053603, -8.888247896946813],
              [-35.217295316756726, -8.887804288907143],
              [-35.217735441524752, -8.88750549960613],
              [-35.217865632320525, -8.888113390418226],
              [-35.217922055662754, -8.888398939402173],
              [-35.218432886192552, -8.888887676046561],
              [-35.219568039957899, -8.889541680903662],
              [-35.219738981810217, -8.890279601855024],
              [-35.220484202915031, -8.889919652677078],
              [-35.220874154281631, -8.889818120374374],
              [-35.221529622005392, -8.889191385826527],
              [-35.221878977829071, -8.888326282151803],
              [-35.221804423852653, -8.887785093471264],
              [-35.222209771764554, -8.887681055284199],
              [-35.222921702919081, -8.887755383188457],
              [-35.223390508626871, -8.887960430930237],
              [-35.223743132239584, -8.887601770336207],
              [-35.223591690415972, -8.887090001154737],
              [-35.224637541419376, -8.887700965204232],
              [-35.22552715293871, -8.887892190828676],
              [-35.226301170171801, -8.887793720156232],
              [-35.226298441050098, -8.887474256634363],
              [-35.226958930814966, -8.886420473284106],
              [-35.22708236826584, -8.886017950348883],
              [-35.227894287608066, -8.885698102739665],
              [-35.228428254053604, -8.885543216088301],
              [-35.229230705236233, -8.885439174660961],
              [-35.229816585818988, -8.885119183675405],
              [-35.230377490711902, -8.88502474648349],
              [-35.230235255720089, -8.884775571036586],
              [-35.229342984353821, -8.883215308860731],
              [-35.228207701228108, -8.87585689117595],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0146000',
        uf: 'PE',
        nome_proje: 'PA CATOLÉ',
        municipio: 'SERRA TALHADA',
        area_hecta: '738.6335',
        capacidade: 22.0,
        num_famili: 22.0,
        fase: 3.0,
        data_de_cr: '21/07/2005',
        forma_obte: 'Desapropriação',
        data_obten: '21/07/2005',
        area_calc_: 774.8744,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.587813481767228, -8.06324342408573],
              [-38.602544260184246, -8.081618132139699],
              [-38.609186339006087, -8.086435665039597],
              [-38.639770466718964, -8.080514798464904],
              [-38.642600740581408, -8.068982692997512],
              [-38.6047255810957, -8.077640926387303],
              [-38.602870734676394, -8.064977540307334],
              [-38.600727249966482, -8.064674789940714],
              [-38.600526546007998, -8.063327651220108],
              [-38.5993541312397, -8.063527404047807],
              [-38.597680771317044, -8.052755647655626],
              [-38.586443115604609, -8.05442066885505],
              [-38.587813481767228, -8.06324342408573],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0147000',
        uf: 'PE',
        nome_proje: 'PA TRES IRMÃOS',
        municipio: 'SERRA TALHADA',
        area_hecta: '506.2210',
        capacidade: 13.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '25/08/2005',
        forma_obte: 'Desapropriação',
        data_obten: '17/08/2005',
        area_calc_: 506.6156,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.481221039906373, -8.154196704226047],
              [-38.457749877231088, -8.18062596769572],
              [-38.457545125294587, -8.18085652436605],
              [-38.453752353300324, -8.185126859543814],
              [-38.447260049423633, -8.192427282977246],
              [-38.452016496151096, -8.198802427824482],
              [-38.461754451077312, -8.187988082097348],
              [-38.471999491617112, -8.176571554195714],
              [-38.471999499135393, -8.176571537601955],
              [-38.47483581702582, -8.173410696509812],
              [-38.474835837394224, -8.173410712132263],
              [-38.488536841102508, -8.158141193120313],
              [-38.481865830805113, -8.155811457638441],
              [-38.481221039906373, -8.154196704226047],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0197000',
        uf: 'PE',
        nome_proje: 'PA QUEIMADA NOVA',
        municipio: 'SERTANIA',
        area_hecta: '944.2341',
        capacidade: 25.0,
        num_famili: 24.0,
        fase: 5.0,
        data_de_cr: '26/11/1999',
        forma_obte: 'Desapropriação',
        data_obten: '08/07/1999',
        area_calc_: 944.3149,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.43007008565101, -7.952480724954256],
              [-37.428216793761983, -7.952977010649458],
              [-37.426472030319417, -7.953626151771886],
              [-37.426741905508663, -7.961678445572995],
              [-37.427594472058779, -7.961548122977366],
              [-37.428815941219867, -7.963794702355359],
              [-37.434974468127294, -7.970867936828534],
              [-37.435222255701326, -7.970801868846716],
              [-37.435293964275196, -7.971027203427401],
              [-37.43551270392598, -7.9712534548557],
              [-37.435758082717861, -7.971343990824642],
              [-37.435939071425715, -7.971359955579602],
              [-37.436121646245944, -7.971627032079095],
              [-37.436683543848588, -7.971681959748708],
              [-37.437527198174109, -7.971491816562257],
              [-37.438222686656815, -7.971647615964994],
              [-37.439372749386635, -7.971743909961813],
              [-37.442621236672395, -7.971115209499787],
              [-37.442998023241174, -7.970421447957054],
              [-37.443185602606896, -7.970277745996675],
              [-37.443893994444906, -7.970091873026809],
              [-37.446665622210659, -7.969464149489261],
              [-37.447599069400738, -7.97105494800091],
              [-37.44804999984791, -7.97160098905027],
              [-37.448470453247026, -7.971882425698728],
              [-37.448612387687163, -7.971936307281994],
              [-37.448663837021805, -7.972003594480836],
              [-37.449222263909839, -7.972258962463785],
              [-37.449349915792922, -7.972564801966397],
              [-37.449302146625733, -7.972700439965418],
              [-37.449370285010126, -7.97298480709274],
              [-37.461487207416283, -7.973428573547119],
              [-37.462398448732614, -7.972580519671256],
              [-37.462683247498802, -7.971493317495676],
              [-37.464444870494894, -7.971056226484002],
              [-37.466876224703057, -7.968926323322106],
              [-37.477085225905796, -7.972160486302188],
              [-37.476672203666425, -7.966846131864735],
              [-37.476391955820482, -7.965388986709802],
              [-37.475614069963356, -7.962319813495621],
              [-37.474397128611628, -7.956811890718875],
              [-37.474285808519305, -7.956475462088694],
              [-37.473391462103017, -7.954465462077032],
              [-37.472332033027904, -7.954884031170873],
              [-37.472142910644493, -7.955032265215825],
              [-37.472090437849225, -7.955093651525145],
              [-37.472004052875448, -7.955176524588412],
              [-37.471763817127233, -7.955582281414273],
              [-37.470972235926425, -7.956709949613606],
              [-37.470539362007891, -7.956890108982464],
              [-37.470415611991271, -7.956922477239194],
              [-37.468406191140517, -7.956373604198629],
              [-37.46658043762757, -7.956463347371544],
              [-37.464607636528797, -7.956070213376326],
              [-37.4629724297614, -7.955217245670299],
              [-37.461225960387765, -7.956060767053354],
              [-37.459216449176857, -7.955469804825983],
              [-37.457474224880656, -7.954812280005167],
              [-37.455666587852306, -7.955493779871825],
              [-37.453647083683187, -7.955492050073979],
              [-37.451673043158252, -7.955799368820388],
              [-37.449743010217311, -7.955281179962604],
              [-37.447814010839686, -7.955020423936867],
              [-37.445874141867876, -7.954805011504763],
              [-37.44386766700125, -7.955055565919624],
              [-37.441874085644201, -7.954437548240792],
              [-37.439859372120232, -7.954468955740836],
              [-37.439236967304147, -7.954374113311584],
              [-37.436003638244522, -7.953241057694153],
              [-37.433992601629868, -7.952975591533711],
              [-37.432168281029377, -7.952482773384903],
              [-37.43007008565101, -7.952480724954256],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0047000',
        uf: 'PE',
        nome_proje: 'PA PORTEIRAS',
        municipio: 'ALAGOINHA',
        area_hecta: '204.6199',
        capacidade: 10.0,
        num_famili: 8.0,
        fase: 7.0,
        data_de_cr: '24/11/1992',
        forma_obte: 'Compra e Venda',
        data_obten: '04/04/1984',
        area_calc_: 205.476,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.79293925477787, -8.520225664030145],
              [-36.793445406167216, -8.520789934086171],
              [-36.79381769984122, -8.52122619663149],
              [-36.793900037729593, -8.521376968395767],
              [-36.793916017478843, -8.521604681242785],
              [-36.793878798631205, -8.521865934256947],
              [-36.795348316602485, -8.523860069756759],
              [-36.796058194147221, -8.524866789134965],
              [-36.797334743381981, -8.526958352315745],
              [-36.79763664257861, -8.527673437257372],
              [-36.797652597758244, -8.528257405385562],
              [-36.798067984671576, -8.528832672135898],
              [-36.79855406166692, -8.528985117267899],
              [-36.798732556962477, -8.529145831099028],
              [-36.799186647687144, -8.529870695850006],
              [-36.799419468007663, -8.530343622832103],
              [-36.799854696249071, -8.531084185722628],
              [-36.800294336974162, -8.531738093303696],
              [-36.801004435410533, -8.532891521352758],
              [-36.801389389886289, -8.533550424766007],
              [-36.801996502696383, -8.534763810097278],
              [-36.802183897328476, -8.535107258267155],
              [-36.801611116843155, -8.535347121041006],
              [-36.800677976252878, -8.53576936365803],
              [-36.801006033187036, -8.536485308446355],
              [-36.801745560958828, -8.53773021315347],
              [-36.801994362814796, -8.538066400584126],
              [-36.802175612078372, -8.538063373810353],
              [-36.802348540476288, -8.537960117157965],
              [-36.802635155969831, -8.537444538353327],
              [-36.802794381621013, -8.537242482695502],
              [-36.802959165124989, -8.537046246469226],
              [-36.803095348883886, -8.536895091980668],
              [-36.803361289451587, -8.536610352773899],
              [-36.803462892380352, -8.536493401442778],
              [-36.803631905072073, -8.536315544571895],
              [-36.803783655759318, -8.536154790968506],
              [-36.803878997377758, -8.536055259682],
              [-36.804168832399164, -8.535902713579095],
              [-36.804477906796876, -8.535728267997673],
              [-36.804725997089172, -8.535592860409709],
              [-36.805056206591679, -8.535414904386467],
              [-36.805425047546912, -8.535279056280745],
              [-36.805659759521028, -8.535205873329573],
              [-36.8058124920978, -8.53514360070325],
              [-36.807818828750598, -8.537006907468886],
              [-36.808868507254104, -8.537891668427873],
              [-36.809024746546811, -8.537444692713969],
              [-36.809245570990605, -8.536896112151686],
              [-36.80942424325692, -8.536478057921249],
              [-36.809602887007692, -8.536148769980871],
              [-36.809830212430306, -8.535660638450317],
              [-36.809996750127162, -8.53524721206268],
              [-36.810123657051129, -8.534862852095898],
              [-36.810250272175146, -8.534542217912657],
              [-36.81042306095766, -8.534118539749455],
              [-36.810571896363022, -8.533784606850718],
              [-36.810648233872307, -8.533554246076321],
              [-36.810793225477148, -8.533262876238584],
              [-36.810854937904402, -8.533049049100935],
              [-36.81092780000408, -8.532775623215242],
              [-36.810994743641935, -8.532630392386555],
              [-36.81101858557598, -8.532509286341625],
              [-36.811118139172095, -8.532211062637524],
              [-36.81121693299378, -8.532048056366975],
              [-36.811305961412202, -8.531890457772224],
              [-36.81137387148987, -8.531797296115837],
              [-36.811488760066183, -8.531605946941045],
              [-36.811637839939188, -8.531442226081499],
              [-36.811765367295386, -8.531295642130026],
              [-36.811937221829808, -8.531106936713082],
              [-36.812074527818645, -8.530951431825619],
              [-36.812186819969632, -8.530824969208231],
              [-36.812268501551053, -8.530678281560162],
              [-36.812298086248767, -8.530359799495798],
              [-36.81228759776927, -8.530105043732696],
              [-36.81227709826846, -8.529738415362237],
              [-36.812330580189162, -8.528768654046234],
              [-36.812349009137286, -8.528277363121157],
              [-36.812373800221501, -8.527922251947739],
              [-36.812411028804732, -8.527493535957577],
              [-36.812485734237413, -8.526487080431016],
              [-36.812540746199936, -8.526164989102478],
              [-36.812811260684271, -8.525637167147472],
              [-36.812903296175982, -8.525438259515767],
              [-36.813001244334536, -8.525214418838985],
              [-36.813148983146306, -8.524868193844487],
              [-36.813180804140025, -8.52472034417158],
              [-36.813185997122851, -8.524567835451824],
              [-36.813218027209381, -8.524397545755708],
              [-36.813271684440132, -8.524318779668267],
              [-36.813396269840723, -8.52411309902107],
              [-36.813585482169294, -8.523853766919306],
              [-36.813847618709964, -8.523509780884837],
              [-36.814261278773266, -8.522995083639813],
              [-36.813991066741423, -8.522706002871345],
              [-36.810296626219987, -8.526208771301953],
              [-36.810066093724885, -8.526351085259243],
              [-36.809840546909179, -8.526505863988175],
              [-36.809380079744592, -8.526932268161056],
              [-36.80895092845622, -8.527328526299568],
              [-36.808748980139654, -8.527502784448906],
              [-36.808547031646555, -8.527677042436277],
              [-36.808414288105979, -8.527836746018471],
              [-36.808195093552406, -8.527939430087375],
              [-36.807998640183044, -8.527985205469822],
              [-36.807773344057125, -8.52803095126594],
              [-36.807559655262274, -8.528082370377156],
              [-36.807241156289088, -8.528204609354926],
              [-36.806842938575457, -8.528380200502889],
              [-36.806652165625977, -8.528502902096101],
              [-36.806328865449728, -8.528801294545387],
              [-36.806143682449594, -8.529096133464661],
              [-36.805897924757296, -8.529610591886785],
              [-36.80579639562314, -8.529923517209724],
              [-36.805671661277614, -8.52990858613941],
              [-36.801755778709143, -8.523606664434171],
              [-36.800366155331481, -8.521202747474499],
              [-36.79967488833352, -8.518228658104983],
              [-36.79910101361844, -8.518101342132036],
              [-36.798777887298172, -8.518073481182043],
              [-36.798325841984834, -8.518060088053261],
              [-36.797894400184433, -8.518060726790329],
              [-36.79766861050831, -8.518060881861935],
              [-36.797119438598791, -8.518027559093742],
              [-36.796694917158653, -8.517913606556965],
              [-36.796426466039243, -8.517933979525182],
              [-36.79611214638863, -8.517992349577062],
              [-36.795800868487078, -8.518136257402116],
              [-36.795588610327862, -8.51823847072629],
              [-36.795254671897951, -8.51805357322959],
              [-36.795015091623974, -8.517832753347125],
              [-36.794717334676989, -8.517667190691023],
              [-36.794510378178146, -8.517685026229852],
              [-36.794151501443125, -8.51753647281418],
              [-36.793961824540531, -8.517443324042784],
              [-36.793578040264684, -8.517348321704517],
              [-36.793300092338335, -8.517606692405286],
              [-36.793159086313629, -8.517718241137926],
              [-36.793045837283373, -8.517981426956741],
              [-36.79289513128343, -8.518307675379035],
              [-36.792895583822677, -8.518560492292043],
              [-36.792821302609291, -8.518820676347762],
              [-36.792841384198482, -8.51926208938106],
              [-36.792876819546699, -8.51966240420016],
              [-36.79293925477787, -8.520225664030145],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0258000',
        uf: 'PE',
        nome_proje: 'PA BARRA NOVA',
        municipio: 'AGUAS BELAS',
        area_hecta: '1581.6080',
        capacidade: 84.0,
        num_famili: 61.0,
        fase: 5.0,
        data_de_cr: '03/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '06/09/2001',
        area_calc_: 1574.1875,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.932559015555057, -9.16749540160086],
              [-36.930235126883986, -9.169123940014014],
              [-36.929348643800054, -9.16975406463348],
              [-36.929060929346107, -9.169940075158198],
              [-36.928136256880777, -9.170550994475795],
              [-36.926188038714656, -9.170908544061778],
              [-36.923598595660671, -9.171359754865165],
              [-36.918355613459582, -9.17239516806173],
              [-36.91832926252566, -9.175556704997723],
              [-36.918232671208926, -9.177238097695769],
              [-36.918005054269642, -9.182477791084397],
              [-36.917859641381121, -9.185912530715452],
              [-36.917752532129349, -9.188441659177617],
              [-36.917745620421933, -9.188597005333163],
              [-36.917629104537646, -9.191240335871299],
              [-36.91742925776029, -9.19573398585286],
              [-36.920897789261261, -9.194609435041093],
              [-36.922908922080893, -9.193938278657168],
              [-36.923129179158309, -9.19381127832998],
              [-36.924397089375695, -9.193385138106166],
              [-36.924478775930467, -9.193355055016317],
              [-36.926431492943365, -9.192668534529997],
              [-36.928059557793674, -9.192210913145523],
              [-36.928656857306272, -9.192043046216076],
              [-36.929291381851428, -9.191778218374685],
              [-36.92979135727974, -9.191638716815923],
              [-36.931440447368871, -9.191084206036917],
              [-36.931536884285634, -9.191051765015869],
              [-36.931646736577555, -9.191090360160551],
              [-36.936845158765834, -9.192914355037885],
              [-36.938931925943642, -9.193585528366524],
              [-36.941473361137128, -9.194403194150702],
              [-36.94155999087306, -9.194430716456813],
              [-36.941609187544572, -9.194638814232297],
              [-36.942311179674455, -9.196489851626762],
              [-36.942523595838352, -9.197058108688761],
              [-36.942620710233996, -9.197318004790191],
              [-36.942960687556464, -9.199458228421323],
              [-36.945826258492907, -9.199325324422936],
              [-36.947715680717636, -9.199237644135808],
              [-36.950505918986316, -9.199090623665874],
              [-36.951041394159006, -9.199059949667307],
              [-36.951274732009104, -9.199046541186537],
              [-36.951844359765033, -9.200558923100866],
              [-36.953591450997294, -9.199167719651813],
              [-36.952682377595394, -9.197815400390001],
              [-36.952410632544634, -9.196945793217097],
              [-36.95254720832245, -9.196910229779011],
              [-36.956724374962725, -9.195823051886979],
              [-36.961317678007511, -9.194825313496189],
              [-36.961193799293525, -9.193882600827322],
              [-36.961286812606986, -9.193876075617112],
              [-36.96473068208681, -9.193632830931097],
              [-36.966010064097787, -9.193472633136198],
              [-36.965971555434926, -9.196344473029939],
              [-36.966447772540711, -9.196192039093658],
              [-36.968183204817358, -9.195317747132838],
              [-36.968742611798248, -9.197757792954613],
              [-36.966765328053199, -9.197983306337219],
              [-36.966851865512368, -9.200082515424223],
              [-36.966815287546758, -9.200529407133333],
              [-36.969096004276849, -9.199982982626047],
              [-36.970851996213831, -9.199622517278531],
              [-36.972419319164317, -9.199498452438526],
              [-36.974369075138156, -9.202209548542454],
              [-36.974461984305123, -9.202365642646495],
              [-36.974971407430687, -9.203349666468277],
              [-36.975106138244755, -9.203814518079714],
              [-36.97538035903947, -9.204947187797785],
              [-36.976483215944718, -9.208477846344509],
              [-36.977208038385115, -9.210798144008619],
              [-36.978466130344763, -9.210217902735137],
              [-36.982427496190837, -9.208868076031459],
              [-36.982500727455822, -9.208805208546043],
              [-36.983294022678884, -9.21107499251414],
              [-36.984233528134261, -9.213762414627086],
              [-36.985173812259418, -9.216442699907905],
              [-36.986180622309952, -9.219330276002371],
              [-36.987860010985969, -9.217862495715726],
              [-36.991206479571247, -9.214972665254766],
              [-36.991705598566654, -9.21453901288389],
              [-36.99421707572408, -9.212842156564257],
              [-36.996545254423054, -9.211346296052159],
              [-36.995803113431066, -9.209863265417628],
              [-36.995607853026186, -9.209414259863856],
              [-36.994726130834671, -9.207386983123911],
              [-36.994600035349187, -9.207096105823595],
              [-36.99424375726835, -9.206273586119242],
              [-36.993859388851362, -9.205044031780574],
              [-36.993131944938845, -9.202716952431549],
              [-36.992725062888965, -9.201415135922455],
              [-36.992477455230294, -9.200650894807969],
              [-36.991879678417213, -9.198805541613604],
              [-36.991425670418451, -9.198504611752327],
              [-36.989071098681151, -9.197520171981205],
              [-36.989115490985633, -9.197440418111166],
              [-36.987085980191694, -9.196426322176825],
              [-36.986772788407663, -9.196226062378212],
              [-36.986586661001688, -9.196095484574494],
              [-36.986508316872985, -9.196194393463601],
              [-36.984617120479747, -9.197283598705296],
              [-36.982725913937088, -9.198372791477871],
              [-36.980724246415903, -9.199575072157026],
              [-36.980215132106871, -9.199880924808312],
              [-36.978464999738371, -9.200474874175631],
              [-36.976170827959322, -9.201515301037889],
              [-36.97495996184346, -9.199701207377986],
              [-36.973251322821397, -9.197145325585971],
              [-36.976968597241587, -9.195577660680017],
              [-36.981137367646994, -9.193815593404722],
              [-36.980363311225105, -9.193116020452928],
              [-36.981360701260193, -9.192661754353217],
              [-36.981040509040284, -9.192397722081267],
              [-36.980391433091334, -9.191974830006913],
              [-36.980218798324408, -9.191824076511562],
              [-36.978739843472518, -9.191242319410309],
              [-36.978002520894279, -9.190612192920192],
              [-36.977662683369914, -9.190540412200436],
              [-36.97340369303592, -9.191743830721043],
              [-36.972022443944759, -9.192172141776465],
              [-36.968459817188076, -9.192821212012964],
              [-36.967640806139329, -9.190933561392589],
              [-36.969789350064275, -9.190628997738562],
              [-36.971318935151103, -9.190351043620373],
              [-36.971750368183464, -9.190105421440165],
              [-36.973418703859146, -9.188912613462954],
              [-36.972577444316904, -9.188389105177745],
              [-36.971694262113246, -9.187495549073493],
              [-36.971091133136909, -9.186835883750694],
              [-36.970604092615829, -9.18630324419366],
              [-36.970191859492459, -9.185462412475179],
              [-36.969679771009808, -9.184680047895233],
              [-36.96907019294855, -9.183748523661613],
              [-36.968640875999, -9.183160885107787],
              [-36.968154879366992, -9.182622102916836],
              [-36.965895123041143, -9.183070945131542],
              [-36.96472116375088, -9.183304061248327],
              [-36.959205519076249, -9.184277321174084],
              [-36.95415890883946, -9.185187882646927],
              [-36.953970583767791, -9.185221888946561],
              [-36.954995918327796, -9.182765057926602],
              [-36.955380603151411, -9.181723531233301],
              [-36.955419584481113, -9.181334690318263],
              [-36.955492925765483, -9.180601366363447],
              [-36.955504394928205, -9.180489069847791],
              [-36.955523356925397, -9.179799550990626],
              [-36.955536090056334, -9.17933969145929],
              [-36.955356194259473, -9.178522493845616],
              [-36.954853376556436, -9.178003129402487],
              [-36.95449444867981, -9.177678748167903],
              [-36.953859648752534, -9.177252375688939],
              [-36.952944852331875, -9.176872416437142],
              [-36.951849625043025, -9.177608988317568],
              [-36.951352191975488, -9.178276177473903],
              [-36.95084306045095, -9.178699051597006],
              [-36.949816791218666, -9.179227736014141],
              [-36.949586401444272, -9.178762159721019],
              [-36.948437640249495, -9.178903163720195],
              [-36.948042966117157, -9.178832132027525],
              [-36.94735126754906, -9.178541108984051],
              [-36.946905332165116, -9.178320000629672],
              [-36.945816257764498, -9.177764300867736],
              [-36.94515424600749, -9.177784672492105],
              [-36.944599415349835, -9.178070238040632],
              [-36.944282693092191, -9.178632770846692],
              [-36.944067349349169, -9.178635974984426],
              [-36.943493657140401, -9.178090070849009],
              [-36.942239302104525, -9.178716958068476],
              [-36.941404881992156, -9.178851232024016],
              [-36.940790871446495, -9.178924116686774],
              [-36.940500909617363, -9.178915594069306],
              [-36.939110244291818, -9.178870783827055],
              [-36.939030276396849, -9.178871774016649],
              [-36.938579103716734, -9.176897335751596],
              [-36.938474486734179, -9.176378146045053],
              [-36.938426820586237, -9.176141673461464],
              [-36.938051210674836, -9.174315099967124],
              [-36.936607722474101, -9.172112450011065],
              [-36.936350983039112, -9.171786921055332],
              [-36.93512125267381, -9.170297976761772],
              [-36.934066597741726, -9.169021281728112],
              [-36.933026461011423, -9.168176571555694],
              [-36.932694150282842, -9.167911173525527],
              [-36.932559015555057, -9.16749540160086],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0300000',
        uf: 'PE',
        nome_proje: 'PA CRISTO REI',
        municipio: 'AGUAS BELAS',
        area_hecta: '795.8800',
        capacidade: 63.0,
        num_famili: 56.0,
        fase: 4.0,
        data_de_cr: '11/09/2003',
        forma_obte: 'Desapropriação',
        data_obten: '06/08/2001',
        area_calc_: 1555.5485,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.983012184543185, -9.115697545306821],
              [-36.981620755372511, -9.116245949708265],
              [-36.980356716769514, -9.116655030387948],
              [-36.979647334468773, -9.116884585840756],
              [-36.977658179899286, -9.117635123900509],
              [-36.972965401936399, -9.119440522375971],
              [-36.9726787578997, -9.119560598006764],
              [-36.972105956710365, -9.119778875225665],
              [-36.971644331460162, -9.119943895334318],
              [-36.971843274503165, -9.122580966703337],
              [-36.971994234496577, -9.12277923492989],
              [-36.96935489240736, -9.123478720677005],
              [-36.965869347597277, -9.12533784247594],
              [-36.964338278372949, -9.123362611568719],
              [-36.963249512250101, -9.121858107394033],
              [-36.962107249835228, -9.120117187654929],
              [-36.961926152067335, -9.120246252318537],
              [-36.960105455375086, -9.121709493959699],
              [-36.958154474347964, -9.123240330483064],
              [-36.956512109275089, -9.124464820045217],
              [-36.954360236489904, -9.126016917695104],
              [-36.952851077761721, -9.127117660526588],
              [-36.949938003318316, -9.128899829183423],
              [-36.949621926784687, -9.129074858274702],
              [-36.946914241206144, -9.130533094368351],
              [-36.946581108409887, -9.128920370402371],
              [-36.945660091065271, -9.127996477712822],
              [-36.94640880758336, -9.126700377612881],
              [-36.94627206190944, -9.126071268387609],
              [-36.945818219665483, -9.123984102106752],
              [-36.94333129813527, -9.125842658307336],
              [-36.940524073722791, -9.127799895043811],
              [-36.938294346140815, -9.129449514695498],
              [-36.937149502704031, -9.130266780232169],
              [-36.936009213440187, -9.131113716280153],
              [-36.936165488221313, -9.131254173590627],
              [-36.936452836654226, -9.131504572313977],
              [-36.9368031075443, -9.131821768407729],
              [-36.939634526384403, -9.134385529293658],
              [-36.940432321307782, -9.13508211846923],
              [-36.942399199280011, -9.136826593827461],
              [-36.9423469668019, -9.138113791256501],
              [-36.942003540942252, -9.138836086950317],
              [-36.941336960490119, -9.143479409857861],
              [-36.940834912939984, -9.147313815309156],
              [-36.941744116247321, -9.147322232664171],
              [-36.943288904359079, -9.147878702231628],
              [-36.943227723726899, -9.14795148453287],
              [-36.947402544263944, -9.148406060772894],
              [-36.948255730435747, -9.148492246553355],
              [-36.953222593154443, -9.148993545856309],
              [-36.955270995775919, -9.149166282148034],
              [-36.957172477576655, -9.149326608593295],
              [-36.958834247046262, -9.149412174235417],
              [-36.960496109951961, -9.149497732937276],
              [-36.962200845448862, -9.149578643844407],
              [-36.963905582969417, -9.149659637287568],
              [-36.966122323295878, -9.149394296422445],
              [-36.968339063790886, -9.149128850876387],
              [-36.968847977912809, -9.149021329292058],
              [-36.96945038061029, -9.148853493988462],
              [-36.970095890741241, -9.14855889220077],
              [-36.970445309236972, -9.147751711523879],
              [-36.970501107768825, -9.147424066129791],
              [-36.970869013543869, -9.14702991846424],
              [-36.971159465098616, -9.146386656495689],
              [-36.971036839161002, -9.146195414817539],
              [-36.970726806992474, -9.146168730370826],
              [-36.970605375437714, -9.145590237483844],
              [-36.972531888725122, -9.146519713455058],
              [-36.974472056117769, -9.147369166219764],
              [-36.976011301928231, -9.147971035842515],
              [-36.976498883413718, -9.147324348498604],
              [-36.976967363780602, -9.147530355499873],
              [-36.977773430675008, -9.147813364963451],
              [-36.978811035656619, -9.148106522820742],
              [-36.979805374804997, -9.146217891009311],
              [-36.979895752801781, -9.146047544597074],
              [-36.980025732372319, -9.145806909247481],
              [-36.980139584023753, -9.145554974765712],
              [-36.980635517791967, -9.144457607316996],
              [-36.979914016656501, -9.144519126647738],
              [-36.978918617467443, -9.144469376415607],
              [-36.97906634102943, -9.143502140633837],
              [-36.981093918355313, -9.143547151110676],
              [-36.981312232119443, -9.143417471617665],
              [-36.982152955548699, -9.143215593077944],
              [-36.982303318673104, -9.142779269948665],
              [-36.98396047970671, -9.143053438440305],
              [-36.984099746993316, -9.143052856568167],
              [-36.984337498113476, -9.14312206611652],
              [-36.987248335398057, -9.144558281017575],
              [-36.990299711696572, -9.145983143052606],
              [-36.99025174760304, -9.145631231474489],
              [-36.990895294663851, -9.145213545967859],
              [-36.991354715741991, -9.145138982135839],
              [-36.991530568105397, -9.144389386316558],
              [-36.991930045780386, -9.143989872252245],
              [-36.992751021797091, -9.143578588573448],
              [-36.993298054851429, -9.143014009619314],
              [-36.993710065852248, -9.143104607312379],
              [-36.994226284341842, -9.143542907589064],
              [-36.994699636339092, -9.143741778306955],
              [-36.995441645655106, -9.143380673802183],
              [-36.995839533915252, -9.142955472533348],
              [-36.99543387641765, -9.142542102305741],
              [-36.995795740709696, -9.141499285714785],
              [-36.996226472659771, -9.14131644032906],
              [-36.996156885176639, -9.13876286342245],
              [-36.996035327779111, -9.137613508581612],
              [-36.994128184521905, -9.137408346377336],
              [-36.989010407109284, -9.136759265383095],
              [-36.98898561718547, -9.135924760640814],
              [-36.988861890531084, -9.135847146996806],
              [-36.987864291371146, -9.13543076260058],
              [-36.987326035489673, -9.135178553317749],
              [-36.986706998079377, -9.134587809777145],
              [-36.985668821021825, -9.134190540532797],
              [-36.984983896922984, -9.133881378050956],
              [-36.985088005882751, -9.133224949462319],
              [-36.985234664974328, -9.133235616617077],
              [-36.985817937650658, -9.133277994808935],
              [-36.985880356704939, -9.132918108305923],
              [-36.985934720096409, -9.132291957355918],
              [-36.989597113716364, -9.131696827665943],
              [-36.990796341628212, -9.131695329139458],
              [-36.991062713111802, -9.130324026936005],
              [-36.991085043995916, -9.130234656960488],
              [-36.991331959330928, -9.129056064245676],
              [-36.991433777508909, -9.127733840698843],
              [-36.992329000979872, -9.126042659914017],
              [-36.993043624656167, -9.125122744384113],
              [-36.991635530673186, -9.123929401344766],
              [-36.990955098397791, -9.123437042132172],
              [-36.990878144429523, -9.123388886873062],
              [-36.990426889427042, -9.123114747732984],
              [-36.990371917036612, -9.123185767388971],
              [-36.989573725578808, -9.122542243425757],
              [-36.988962610862707, -9.12204954177604],
              [-36.988750726889677, -9.121878693609911],
              [-36.988303878082881, -9.121273360993285],
              [-36.987297441384968, -9.118918816057196],
              [-36.986414194180249, -9.117533559723718],
              [-36.98588149743496, -9.116764854819257],
              [-36.985715180668585, -9.116611343213449],
              [-36.984614609450574, -9.11622259517954],
              [-36.984466594682154, -9.116227831625768],
              [-36.983881584081367, -9.116224589431065],
              [-36.983636729443276, -9.116257221463375],
              [-36.983012184543185, -9.115697545306821],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0229000',
        uf: 'PE',
        nome_proje: 'PA VALERIANO DIAS',
        municipio: 'PARNAMIRIM',
        area_hecta: '919.6475',
        capacidade: 69.0,
        num_famili: 67.0,
        fase: 3.0,
        data_de_cr: '11/05/2007',
        forma_obte: 'Doação',
        data_obten: '22/11/2004',
        area_calc_: 920.2106,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.935581483092207, -8.141583601734661],
              [-39.933399867804177, -8.141396089562868],
              [-39.918850738113392, -8.185041259912396],
              [-39.901848731061641, -8.187528539966724],
              [-39.906453032125448, -8.15303365717406],
              [-39.894151236246316, -8.162330276099379],
              [-39.891463968676298, -8.15868603605567],
              [-39.886671075729218, -8.162737533432047],
              [-39.890059156843336, -8.166861793197674],
              [-39.879604247406277, -8.175660421234955],
              [-39.872327003934849, -8.173084484352136],
              [-39.8726814873961, -8.178977386981501],
              [-39.887071120604602, -8.180540692301102],
              [-39.885445891236621, -8.182394392776992],
              [-39.888392063521636, -8.182706009321414],
              [-39.888437602347189, -8.181954866966512],
              [-39.888437550762148, -8.181954869572747],
              [-39.888437557796244, -8.181954763827394],
              [-39.888437566073407, -8.181954658171414],
              [-39.888437575592555, -8.18195455261934],
              [-39.888437586352318, -8.181954447185644],
              [-39.888437598351274, -8.18195434188479],
              [-39.888437611587719, -8.181954236731233],
              [-39.888437626059876, -8.181954131739452],
              [-39.888437641765748, -8.181954026923847],
              [-39.888437658703197, -8.181953922298765],
              [-39.888437676869849, -8.181953817878599],
              [-39.88843769626326, -8.181953713677688],
              [-39.888437716880738, -8.181953609710357],
              [-39.88843773871946, -8.181953505990865],
              [-39.888437761776444, -8.181953402533443],
              [-39.888437786048492, -8.181953299352305],
              [-39.888437811532299, -8.181953196461617],
              [-39.888437838224341, -8.181953093875491],
              [-39.888437866120995, -8.181952991608021],
              [-39.888437895218367, -8.181952889673266],
              [-39.888437925512534, -8.181952788085189],
              [-39.888437956999283, -8.181952686857787],
              [-39.888437989674301, -8.181952586004899],
              [-39.888438023533119, -8.181952485540414],
              [-39.888438058571097, -8.181952385478111],
              [-39.888438094783396, -8.181952285831708],
              [-39.888438132165057, -8.181952186614918],
              [-39.88843817071092, -8.181952087841358],
              [-39.888438210415764, -8.181951989524572],
              [-39.88843825127406, -8.181951891678118],
              [-39.888438293280238, -8.18195179431533],
              [-39.888438336428521, -8.181951697449634],
              [-39.888438380712984, -8.18195160109433],
              [-39.888438426127557, -8.181951505262642],
              [-39.888438472665996, -8.181951409967724],
              [-39.888438520321905, -8.18195131522268],
              [-39.888438569088763, -8.18195122104046],
              [-39.888438618959853, -8.18195112743407],
              [-39.888438669928334, -8.181951034416318],
              [-39.888438721987214, -8.181950941999974],
              [-39.888438775129337, -8.181950850197754],
              [-39.8884388293474, -8.181950759022234],
              [-39.888438884633985, -8.181950668485946],
              [-39.88843894098148, -8.181950578601306],
              [-39.888438998382142, -8.18195048938067],
              [-39.888439056828119, -8.181950400836278],
              [-39.888439116311361, -8.181950312980334],
              [-39.888439176823709, -8.181950225824794],
              [-39.888439238356867, -8.181950139381723],
              [-39.888439300902363, -8.181950053662968],
              [-39.888439364451628, -8.181949968680273],
              [-39.888439428995923, -8.181949884445315],
              [-39.888439494526402, -8.181949800969665],
              [-39.888439561034069, -8.181949718264793],
              [-39.888439628509772, -8.181949636342029],
              [-39.888439696944253, -8.181949555212649],
              [-39.888439766328119, -8.18194947488778],
              [-39.888439836651862, -8.181949395378444],
              [-39.888439907905799, -8.181949316695553],
              [-39.888439980080172, -8.181949238849935],
              [-39.888440053165041, -8.181949161852241],
              [-39.888440127150389, -8.181949085713088],
              [-39.888440202026068, -8.18194901044286],
              [-39.888440277781783, -8.181948936051974],
              [-39.888440354407145, -8.181948862550614],
              [-39.888440431891617, -8.181948789948835],
              [-39.888440510224576, -8.181948718256644],
              [-39.888440589395259, -8.181948647483859],
              [-39.8884406693928, -8.181948577640235],
              [-39.888440750206207, -8.181948508735342],
              [-39.888440831824397, -8.181948440778621],
              [-39.888440914236149, -8.181948373779433],
              [-39.88844099743018, -8.18194830774693],
              [-39.888441081395015, -8.181948242690238],
              [-39.888441166119172, -8.181948178618256],
              [-39.888441251591011, -8.181948115539779],
              [-39.888441337798774, -8.181948053463479],
              [-39.888441424730637, -8.18194799239788],
              [-39.888441512374676, -8.181947932351365],
              [-39.888441600718849, -8.181947873332161],
              [-39.888441689751012, -8.181947815348387],
              [-39.888441779458958, -8.181947758407997],
              [-39.888441869830373, -8.181947702518809],
              [-39.888441960852845, -8.181947647688505],
              [-39.888442052513881, -8.181947593924619],
              [-39.888442144800884, -8.181947541234537],
              [-39.888442237701184, -8.181947489625447],
              [-39.888442331202036, -8.181947439104469],
              [-39.888442425290592, -8.181947389678536],
              [-39.888442519953962, -8.181947341354439],
              [-39.888442615179102, -8.181947294138805],
              [-39.88844271095298, -8.18194724803813],
              [-39.888442807262408, -8.181947203058728],
              [-39.888442904094191, -8.18194715920678],
              [-39.888443001435043, -8.181947116488315],
              [-39.888443099271576, -8.18194707490918],
              [-39.888443197590348, -8.181947034475078],
              [-39.888443296377908, -8.181946995191607],
              [-39.888443395620641, -8.181946957064108],
              [-39.888443495304742, -8.181946920098113],
              [-39.888443576059409, -8.181946985150992],
              [-39.889676942018291, -8.181497746442417],
              [-39.889676910160219, -8.181497705971504],
              [-39.889677015205045, -8.181497666979352],
              [-39.889677119758588, -8.181497626697755],
              [-39.889677223804931, -8.181497585132794],
              [-39.88967732732818, -8.181497542290854],
              [-39.889677430312545, -8.181497498178436],
              [-39.889677532742354, -8.181497452802283],
              [-39.889677634601952, -8.181497406169337],
              [-39.889677735875829, -8.181497358286675],
              [-39.889677836548536, -8.181497309161625],
              [-39.889677936604684, -8.181497258801643],
              [-39.889678036029082, -8.181497207214452],
              [-39.889678134806516, -8.181497154407868],
              [-39.889678232921945, -8.181497100390009],
              [-39.88967833036039, -8.181497045169079],
              [-39.889678427107015, -8.18149698875345],
              [-39.889678523147055, -8.181496931151813],
              [-39.889678618465865, -8.181496872372909],
              [-39.889678713048902, -8.181496812425699],
              [-39.88967880688174, -8.181496751319331],
              [-39.889678899950098, -8.181496689063119],
              [-39.889678992239745, -8.181496625666574],
              [-39.889679083736631, -8.181496561139339],
              [-39.889679174426796, -8.181496495491253],
              [-39.889679264296404, -8.181496428732368],
              [-39.889679353331772, -8.181496360872824],
              [-39.889679441519299, -8.18149629192299],
              [-39.889679528845548, -8.181496221893367],
              [-39.889679615297197, -8.181496150794635],
              [-39.889679700861066, -8.181496078637654],
              [-39.889679785524095, -8.181496005433402],
              [-39.889679869273387, -8.18149593119306],
              [-39.889679952096174, -8.181495855927949],
              [-39.889680033979822, -8.181495779649561],
              [-39.889680114911819, -8.181495702369482],
              [-39.889680194879865, -8.181495624099535],
              [-39.889680273871733, -8.181495544851675],
              [-39.889680351875384, -8.181495464637919],
              [-39.88968042887894, -8.181495383470558],
              [-39.88968050487064, -8.18149530136194],
              [-39.889680579838902, -8.181495218324612],
              [-39.889680653772295, -8.181495134371209],
              [-39.889680726659527, -8.181495049514558],
              [-39.889680798489508, -8.181494963767589],
              [-39.889680869251272, -8.181494877143358],
              [-39.889680938934035, -8.181494789655106],
              [-39.889681007527159, -8.181494701316177],
              [-39.889681075020192, -8.181494612140021],
              [-39.889681141402832, -8.181494522140223],
              [-39.88968120666496, -8.181494431330551],
              [-39.889681270796636, -8.181494339724829],
              [-39.889681333788083, -8.181494247337017],
              [-39.889681395629665, -8.181494154181228],
              [-39.889681456311962, -8.181494060271669],
              [-39.889681515825735, -8.181493965622641],
              [-39.889681574161912, -8.18149387024857],
              [-39.889681631311582, -8.18149377416403],
              [-39.88968168726602, -8.181493677383653],
              [-39.889681742016698, -8.181493579922218],
              [-39.889681795555305, -8.181493481794567],
              [-39.889681847873625, -8.181493383015665],
              [-39.889681898963701, -8.181493283600581],
              [-39.889681948817753, -8.181493183564477],
              [-39.889681997428163, -8.181493082922614],
              [-39.88968204478752, -8.181492981690337],
              [-39.889682090888591, -8.181492879883079],
              [-39.889682135724378, -8.181492777516368],
              [-39.889682179288016, -8.181492674605806],
              [-39.889682221572876, -8.181492571167091],
              [-39.889682262572492, -8.181492467216023],
              [-39.88968230228064, -8.181492362768399],
              [-39.88968234069123, -8.181492257840169],
              [-39.889682377798437, -8.181492152447367],
              [-39.889682413596582, -8.181492046606046],
              [-39.889682448080201, -8.18149194033232],
              [-39.889682481244066, -8.181491833642443],
              [-39.889682513083081, -8.181491726552647],
              [-39.889682543592414, -8.181491619079271],
              [-39.889682572767413, -8.181491511238733],
              [-39.889682600603621, -8.181491403047445],
              [-39.889682627096789, -8.181491294521916],
              [-39.88968265224289, -8.181491185678659],
              [-39.889682676038078, -8.181491076534353],
              [-39.88968269847873, -8.18149096710559],
              [-39.889682719561435, -8.181490857409061],
              [-39.889682739282946, -8.181490747461501],
              [-39.88968275764028, -8.181490637279675],
              [-39.889682774630643, -8.181490526880372],
              [-39.889682790251427, -8.181490416280447],
              [-39.889682804500254, -8.181490305496776],
              [-39.889682817374961, -8.181490194546221],
              [-39.889682828873568, -8.181490083445709],
              [-39.889682838994332, -8.181489972212217],
              [-39.889682847735706, -8.181489860862671],
              [-39.889682855096375, -8.181489749414089],
              [-39.889682861075187, -8.181489637883411],
              [-39.889682865671247, -8.181489526287685],
              [-39.889682868883853, -8.181489414643924],
              [-39.88968287071252, -8.18148930296913],
              [-39.88968287115695, -8.18148919128037],
              [-39.88968291429417, -8.181489231502894],
              [-39.889680437096494, -8.180372246442111],
              [-39.889680393916905, -8.18037218709833],
              [-39.889680393514645, -8.18037211962214],
              [-39.889680392607133, -8.180372052150785],
              [-39.889680391194446, -8.180371984688094],
              [-39.889680389276634, -8.180371917237764],
              [-39.889680386853819, -8.180371849803574],
              [-39.889680383926141, -8.18037178238928],
              [-39.889680380493743, -8.180371714998602],
              [-39.889680376556846, -8.180371647635299],
              [-39.889680372115642, -8.180371580303165],
              [-39.889680367170392, -8.180371513005914],
              [-39.889680361721375, -8.18037144574731],
              [-39.889680355768874, -8.180371378531053],
              [-39.889680349313238, -8.180371311360915],
              [-39.889680342354822, -8.180371244240638],
              [-39.889680334894017, -8.180371177173978],
              [-39.889680326931241, -8.180371110164625],
              [-39.889680318466915, -8.180371043216331],
              [-39.889680309501529, -8.180370976332823],
              [-39.889680300035593, -8.1803709095178],
              [-39.889680290069606, -8.180370842775025],
              [-39.889680279604143, -8.180370776108179],
              [-39.889680268639772, -8.180370709520984],
              [-39.889680257177119, -8.180370643017152],
              [-39.889680245216823, -8.180370576600366],
              [-39.88968023275951, -8.180370510274331],
              [-39.889680219805918, -8.180370444042769],
              [-39.889680206356772, -8.180370377909318],
              [-39.889680192412783, -8.180370311877697],
              [-39.889680177974753, -8.180370245951563],
              [-39.889680163043494, -8.180370180134586],
              [-39.889680147619806, -8.180370114430419],
              [-39.88968013170458, -8.180370048842732],
              [-39.88968011529866, -8.180369983375183],
              [-39.889680098403019, -8.180369918031401],
              [-39.88968008101854, -8.180369852815048],
              [-39.889680063146216, -8.180369787729724],
              [-39.889680044787042, -8.180369722779062],
              [-39.889680025942049, -8.180369657966684],
              [-39.889680006612274, -8.180369593296197],
              [-39.889679986798775, -8.180369528771182],
              [-39.88967996650269, -8.180369464395275],
              [-39.889679945725128, -8.180369400172015],
              [-39.889679924467252, -8.180369336104985],
              [-39.889679902730251, -8.180369272197774],
              [-39.889679880515317, -8.180369208453914],
              [-39.889679857823694, -8.180369144876991],
              [-39.889679834656661, -8.180369081470456],
              [-39.889679811015483, -8.180369018237927],
              [-39.889679786901496, -8.180368955182876],
              [-39.889679762316021, -8.18036889230885],
              [-39.889679737260451, -8.180368829619315],
              [-39.889679711736157, -8.180368767117763],
              [-39.889679685744589, -8.180368704807703],
              [-39.889679659287168, -8.18036864269258],
              [-39.889679632365365, -8.180368580775859],
              [-39.889679604980692, -8.180368519060991],
              [-39.889679577134672, -8.180368457551385],
              [-39.889679548828852, -8.180368396250492],
              [-39.889679520064817, -8.180368335161713],
              [-39.889679490844152, -8.180368274288448],
              [-39.889679461168484, -8.18036821363409],
              [-39.889679431039475, -8.180368153202012],
              [-39.889679400458803, -8.180368092995595],
              [-39.889679369428158, -8.180368033018135],
              [-39.889679337949289, -8.180367973273032],
              [-39.889679306023915, -8.180367913763586],
              [-39.88967927365384, -8.180367854493124],
              [-39.889679240840863, -8.180367795464916],
              [-39.889679207586795, -8.180367736682275],
              [-39.889679173893505, -8.180367678148443],
              [-39.889679139762848, -8.180367619866725],
              [-39.889679105196755, -8.180367561840333],
              [-39.889679070197118, -8.180367504072478],
              [-39.889679034765905, -8.180367446566429],
              [-39.889678998905069, -8.180367389325337],
              [-39.889678962616635, -8.180367332352409],
              [-39.889678925902594, -8.180367275650806],
              [-39.889678888765005, -8.180367219223706],
              [-39.889678851205936, -8.180367163074258],
              [-39.889678813227476, -8.180367107205551],
              [-39.889678774831729, -8.180367051620705],
              [-39.889678736020841, -8.180366996322807],
              [-39.889678696796977, -8.18036694131497],
              [-39.889678657162307, -8.180366886600229],
              [-39.889678617119039, -8.180366832181637],
              [-39.889678576669418, -8.18036677806219],
              [-39.889678535815683, -8.180366724244955],
              [-39.889678494560101, -8.180366670732882],
              [-39.889678452904995, -8.180366617528971],
              [-39.889678410852639, -8.1803665646362],
              [-39.889678368405427, -8.180366512057489],
              [-39.889678325565676, -8.180366459795769],
              [-39.889678282335808, -8.180366407853949],
              [-39.889678238718204, -8.180366356234916],
              [-39.889678194715287, -8.180366304941556],
              [-39.889678150329523, -8.180366253976732],
              [-39.889678105563384, -8.180366203343244],
              [-39.889678060419357, -8.180366153043947],
              [-39.889678014899943, -8.180366103081633],
              [-39.889678063321568, -8.180366101436569],
              [-39.888547366295846, -8.179134381938852],
              [-39.888547328476697, -8.179134314756526],
              [-39.888547282957568, -8.179134264794033],
              [-39.888547237813818, -8.179134214494576],
              [-39.888547193047948, -8.179134163860947],
              [-39.888547148662475, -8.179134112896001],
              [-39.88854710465985, -8.179134061602479],
              [-39.88854706104253, -8.179134009983313],
              [-39.888547017812932, -8.179133958041373],
              [-39.888546974973465, -8.1791339057795],
              [-39.888546932526538, -8.179133853200636],
              [-39.888546890474466, -8.17913380030773],
              [-39.888546848819644, -8.17913374710367],
              [-39.888546807564346, -8.179133693591485],
              [-39.888546766710895, -8.179133639774115],
              [-39.888546726261545, -8.179133585654522],
              [-39.888546686218568, -8.179133531235822],
              [-39.888546646584174, -8.179133476520915],
              [-39.888546607360603, -8.17913342151294],
              [-39.888546568549984, -8.179133366214916],
              [-39.888546530154528, -8.179133310629963],
              [-39.888546492176332, -8.179133254761135],
              [-39.888546454617554, -8.179133198611529],
              [-39.888546417480235, -8.179133142184293],
              [-39.888546380766478, -8.179133085482587],
              [-39.888546344478314, -8.179133028509527],
              [-39.888546308617769, -8.179132971268341],
              [-39.888546273186826, -8.179132913762139],
              [-39.888546238187473, -8.179132855994187],
              [-39.888546203621644, -8.179132797967682],
              [-39.888546169491278, -8.17913273968585],
              [-39.888546135798251, -8.179132681151913],
              [-39.88854610254446, -8.179132622369142],
              [-39.88854606973176, -8.179132563340827],
              [-39.888546037361962, -8.179132504070244],
              [-39.888546005436872, -8.179132444560713],
              [-39.888545973958259, -8.179132384815501],
              [-39.888545942927891, -8.179132324837941],
              [-39.888545912347496, -8.179132264631406],
              [-39.88854588221875, -8.179132204199227],
              [-39.888545852543359, -8.179132143544773],
              [-39.888545823322971, -8.17913208267143],
              [-39.888545794559192, -8.179132021582536],
              [-39.888545766253642, -8.17913196028155],
              [-39.888545738407892, -8.179131898771834],
              [-39.88854571102349, -8.179131837056872],
              [-39.888545684101963, -8.179131775140036],
              [-39.888545657644798, -8.179131713024814],
              [-39.888545631653479, -8.179131650714686],
              [-39.888545606129462, -8.179131588213046],
              [-39.888545581074148, -8.179131525523447],
              [-39.88854555648895, -8.179131462649302],
              [-39.888545532375225, -8.179131399594185],
              [-39.888545508734303, -8.179131336361543],
              [-39.888545485567526, -8.179131272954947],
              [-39.888545462876166, -8.179131209377928],
              [-39.888545440661488, -8.179131145633995],
              [-39.888545418924743, -8.179131081726689],
              [-39.888545397667116, -8.179131017659573],
              [-39.888545376889816, -8.179130953436236],
              [-39.88854535659398, -8.179130889060229],
              [-39.888545336780751, -8.179130824535145],
              [-39.888545317451218, -8.179130759864591],
              [-39.888545298606473, -8.17913069505213],
              [-39.888545280247556, -8.179130630101421],
              [-39.888545262375487, -8.179130565016017],
              [-39.888545244991263, -8.179130499799587],
              [-39.888545228095865, -8.179130434455743],
              [-39.888545211690193, -8.179130368988137],
              [-39.888545195775208, -8.179130303400393],
              [-39.88854518035177, -8.179130237696185],
              [-39.888545165420737, -8.179130171879137],
              [-39.888545150982956, -8.179130105952934],
              [-39.888545137039223, -8.179130039921251],
              [-39.888545123590298, -8.179129973787763],
              [-39.888545110636954, -8.179129907556137],
              [-39.88854509817989, -8.179129841230054],
              [-39.888545086219835, -8.179129774813225],
              [-39.888545074757403, -8.179129708309317],
              [-39.888545063793266, -8.17912964172208],
              [-39.888545053328031, -8.179129575055192],
              [-39.888545043362285, -8.179129508312361],
              [-39.88854503389657, -8.17912944149732],
              [-39.888545024931418, -8.179129374613757],
              [-39.88854501646734, -8.179129307665415],
              [-39.888545008504771, -8.179129240656026],
              [-39.888545001044193, -8.17912917358932],
              [-39.888544994085997, -8.179129106469016],
              [-39.888544987630581, -8.179129039298868],
              [-39.888544981678315, -8.1791289720826],
              [-39.888544976229518, -8.179128904823967],
              [-39.888544971284482, -8.17912883752668],
              [-39.888544966843504, -8.179128770194518],
              [-39.888544962906806, -8.17912870283123],
              [-39.888544959474629, -8.179128635440504],
              [-39.888544956547165, -8.179128568026174],
              [-39.888544954124562, -8.179128500591952],
              [-39.888544952206971, -8.179128433141594],
              [-39.888544950794468, -8.179128365678867],
              [-39.888544949887184, -8.179128298207537],
              [-39.888544949485464, -8.179128230730296],
              [-39.888544992546954, -8.179128236923653],
              [-39.888542767957802, -8.178123583051708],
              [-39.888542724925863, -8.178123590318938],
              [-39.888542724069431, -8.178123484442622],
              [-39.888542721969102, -8.17812337858353],
              [-39.888542718625182, -8.178123272756215],
              [-39.88854271403811, -8.178123166975109],
              [-39.888542708208526, -8.178123061254777],
              [-39.888542701137212, -8.178122955609696],
              [-39.888542692825148, -8.178122850054319],
              [-39.888542683273471, -8.178122744603137],
              [-39.888542672483496, -8.17812263927058],
              [-39.8885426604567, -8.178122534071118],
              [-39.888542647194718, -8.178122429019115],
              [-39.888542632699398, -8.178122324129008],
              [-39.888542616972686, -8.178122219415144],
              [-39.88854260001677, -8.178122114891904],
              [-39.88854258183396, -8.178122010573592],
              [-39.888542562426743, -8.178121906474509],
              [-39.888542541797783, -8.178121802608931],
              [-39.888542519949922, -8.178121698991083],
              [-39.888542496886132, -8.178121595635158],
              [-39.888542472609586, -8.178121492555336],
              [-39.888542447123612, -8.178121389765707],
              [-39.888542420431698, -8.178121287280403],
              [-39.888542392537488, -8.178121185113461],
              [-39.888542363444834, -8.178121083278892],
              [-39.888542333157702, -8.178120981790618],
              [-39.888542301680246, -8.178120880662565],
              [-39.888542269016796, -8.178120779908598],
              [-39.888542235171798, -8.178120679542513],
              [-39.888542200149921, -8.178120579578074],
              [-39.888542163955961, -8.178120480028987],
              [-39.888542126594842, -8.178120380908851],
              [-39.888542088071738, -8.178120282231299],
              [-39.888542048391876, -8.178120184009838],
              [-39.888542007560737, -8.178120086257923],
              [-39.888541965583883, -8.178119988988959],
              [-39.888541922467091, -8.178119892216291],
              [-39.888541878216273, -8.178119795953142],
              [-39.888541832837461, -8.178119700212752],
              [-39.888541786336909, -8.178119605008181],
              [-39.888541738720967, -8.178119510352536],
              [-39.888541689996174, -8.178119416258767],
              [-39.888541640169201, -8.178119322739748],
              [-39.888541589246877, -8.178119229808306],
              [-39.888541537236179, -8.178119137477205],
              [-39.888541484144234, -8.178119045759063],
              [-39.888541429978332, -8.178118954666477],
              [-39.88854137474587, -8.178118864211903],
              [-39.88854131845445, -8.17811877440773],
              [-39.888541261111769, -8.178118685266316],
              [-39.888541202725676, -8.178118596799845],
              [-39.888541143304181, -8.178118509020431],
              [-39.888541082855433, -8.178118421940148],
              [-39.888541021387702, -8.178118335570872],
              [-39.888540958909431, -8.178118249924481],
              [-39.888540895429173, -8.178118165012698],
              [-39.888540830955641, -8.17811808084714],
              [-39.888540765497631, -8.178117997439388],
              [-39.888540699064144, -8.178117914800827],
              [-39.888540631664291, -8.178117832942808],
              [-39.888540563307295, -8.178117751876542],
              [-39.888540494002541, -8.178117671613144],
              [-39.888540423759487, -8.178117592163591],
              [-39.888540352587796, -8.178117513538798],
              [-39.888540280497224, -8.178117435749508],
              [-39.88854020749762, -8.178117358806434],
              [-39.888540133599015, -8.178117282720093],
              [-39.888540058811522, -8.178117207500893],
              [-39.8885399831454, -8.178117133159176],
              [-39.888539906611015, -8.178117059705109],
              [-39.88853982921885, -8.178116987148758],
              [-39.888539750979525, -8.178116915500079],
              [-39.888539671903743, -8.178116844768864],
              [-39.888539592002346, -8.17811677496484],
              [-39.888539511286304, -8.178116706097564],
              [-39.888539429766659, -8.178116638176469],
              [-39.888539347454596, -8.178116571210866],
              [-39.888539264361384, -8.178116505209914],
              [-39.888539180498405, -8.178116440182681],
              [-39.888539095877164, -8.178116376138073],
              [-39.888539010509248, -8.17811631308486],
              [-39.888538924406376, -8.178116251031675],
              [-39.888538837580327, -8.178116189987042],
              [-39.888538750043018, -8.178116129959321],
              [-39.888538661806415, -8.178116070956749],
              [-39.888538572882652, -8.178116012987386],
              [-39.888538483283895, -8.178115956059212],
              [-39.888538393022408, -8.178115900180009],
              [-39.888538302110575, -8.178115845357418],
              [-39.888538210560867, -8.178115791598968],
              [-39.88853811838581, -8.178115738912028],
              [-39.888538025598045, -8.17811568730383],
              [-39.888537932210298, -8.178115636781424],
              [-39.888537838235344, -8.178115587351733],
              [-39.888537743686079, -8.178115539021565],
              [-39.888537648575465, -8.178115491797515],
              [-39.888537552916524, -8.178115445686046],
              [-39.888537456722361, -8.178115400693514],
              [-39.888537360006168, -8.178115356826027],
              [-39.888537262781206, -8.178115314089677],
              [-39.888537303295053, -8.178115364603469],
              [-39.886730707995433, -8.177333819714429],
              [-39.886730677733041, -8.177333757261531],
              [-39.886730637812057, -8.177333740104062],
              [-39.886730597809041, -8.177333723137293],
              [-39.886730557724931, -8.177333706361607],
              [-39.886730517560643, -8.177333689777409],
              [-39.886730477317087, -8.177333673385059],
              [-39.886730436995208, -8.177333657184935],
              [-39.886730396595901, -8.177333641177434],
              [-39.886730356120133, -8.177333625362911],
              [-39.886730315568798, -8.177333609741716],
              [-39.886730274942828, -8.17733359431422],
              [-39.886730234243174, -8.17733357908077],
              [-39.886730193470761, -8.177333564041723],
              [-39.886730152626512, -8.17733354919741],
              [-39.886730111711387, -8.1773335345482],
              [-39.886730070726308, -8.1773335200944],
              [-39.886730029672215, -8.177333505836332],
              [-39.886729988550073, -8.177333491774361],
              [-39.886729947360791, -8.177333477908801],
              [-39.886729906105337, -8.177333464239945],
              [-39.886729864784641, -8.177333450768142],
              [-39.886729823399662, -8.177333437493665],
              [-39.88672978195136, -8.177333424416837],
              [-39.886729740440657, -8.177333411537958],
              [-39.886729698868535, -8.177333398857328],
              [-39.886729657235925, -8.177333386375219],
              [-39.886729615543793, -8.177333374091928],
              [-39.886729573793097, -8.177333362007754],
              [-39.886729531984791, -8.177333350122971],
              [-39.886729490119833, -8.17733333843781],
              [-39.886729448199183, -8.177333326952583],
              [-39.886729406223807, -8.177333315667534],
              [-39.886729364194665, -8.177333304582925],
              [-39.886729322112721, -8.177333293699022],
              [-39.886729279978944, -8.177333283016043],
              [-39.886729237794292, -8.177333272534257],
              [-39.886729195559752, -8.177333262253898],
              [-39.886729153276278, -8.177333252175229],
              [-39.88672911094482, -8.177333242298442],
              [-39.886729068566375, -8.177333232623779],
              [-39.886729026141921, -8.177333223151477],
              [-39.886728983672427, -8.177333213881717],
              [-39.88672894115885, -8.177333204814753],
              [-39.886728898602165, -8.177333195950768],
              [-39.886728856003373, -8.177333187289978],
              [-39.886728813363426, -8.177333178832566],
              [-39.88672877068332, -8.177333170578725],
              [-39.886728727964041, -8.177333162528683],
              [-39.886728685206542, -8.17733315468257],
              [-39.886728642411818, -8.177333147040599],
              [-39.886728599580849, -8.17733313960294],
              [-39.886728556714623, -8.177333132369739],
              [-39.886728513814113, -8.177333125341198],
              [-39.886728470880314, -8.177333118517469],
              [-39.886728427914193, -8.177333111898713],
              [-39.886728384916765, -8.177333105485083],
              [-39.886728341888997, -8.177333099276693],
              [-39.886728298831876, -8.17733309327371],
              [-39.886728255746391, -8.177333087476269],
              [-39.886728212633543, -8.177333081884516],
              [-39.886728169494312, -8.177333076498552],
              [-39.886728126329658, -8.17733307131852],
              [-39.886728083140632, -8.17733306634455],
              [-39.886728039928158, -8.177333061576746],
              [-39.886727996693288, -8.177333057015188],
              [-39.88672795343696, -8.177333052660018],
              [-39.886727910160211, -8.177333048511331],
              [-39.886727866864014, -8.177333044569188],
              [-39.886727823549371, -8.177333040833714],
              [-39.886727780217257, -8.177333037304999],
              [-39.8867277368687, -8.177333033983112],
              [-39.886727693504646, -8.177333030868118],
              [-39.886727650126126, -8.177333027960101],
              [-39.886727606734112, -8.177333025259106],
              [-39.886727563329629, -8.17733302276525],
              [-39.886727519913649, -8.177333020478528],
              [-39.886727476487174, -8.177333018399029],
              [-39.886727433051206, -8.177333016526777],
              [-39.886727389606733, -8.177333014861862],
              [-39.886727346154757, -8.177333013404256],
              [-39.886727302696265, -8.17733301215403],
              [-39.886727259232273, -8.177333011111212],
              [-39.886727215763756, -8.177333010275841],
              [-39.886727172291728, -8.17733300964789],
              [-39.886727128817178, -8.177333009227411],
              [-39.886727085341107, -8.177333009014411],
              [-39.886727041864511, -8.177333009008901],
              [-39.886726998388383, -8.177333009210853],
              [-39.886726954913719, -8.177333009620252],
              [-39.886726911441535, -8.177333010237129],
              [-39.886726867972818, -8.177333011061458],
              [-39.886726824508543, -8.177333012093214],
              [-39.886726781049738, -8.177333013332371],
              [-39.886726737597392, -8.177333014778917],
              [-39.8867266941525, -8.177333016432787],
              [-39.886726650716042, -8.177333018293989],
              [-39.886726607289042, -8.177333020362449],
              [-39.886726563872472, -8.177333022638114],
              [-39.886726520467356, -8.177333025120937],
              [-39.88672647707466, -8.177333027810896],
              [-39.886726530799315, -8.177333105319315],
              [-39.883104875264173, -8.177566240195423],
              [-39.883104842655719, -8.177566195458551],
              [-39.883104834484463, -8.177566195988287],
              [-39.883104826313662, -8.177566196525362],
              [-39.88310481814338, -8.177566197069787],
              [-39.883104809973574, -8.177566197621534],
              [-39.883104801804279, -8.177566198180648],
              [-39.883104793635489, -8.177566198747083],
              [-39.883104785467225, -8.177566199320864],
              [-39.883104777299479, -8.177566199901989],
              [-39.88310476913226, -8.177566200490459],
              [-39.883104760965573, -8.17756620108627],
              [-39.883104752799433, -8.177566201689411],
              [-39.883104744633847, -8.177566202299895],
              [-39.883104736468816, -8.177566202917724],
              [-39.883104728304346, -8.177566203542879],
              [-39.883104720140452, -8.177566204175381],
              [-39.883104711977126, -8.177566204815237],
              [-39.883104703814368, -8.177566205462394],
              [-39.883104695652229, -8.177566206116923],
              [-39.883104687490665, -8.177566206778764],
              [-39.883104679329719, -8.177566207447949],
              [-39.883104671169363, -8.17756620812446],
              [-39.883104663009618, -8.177566208808313],
              [-39.883104654850527, -8.177566209499496],
              [-39.883104646692047, -8.177566210198005],
              [-39.883104638534199, -8.177566210903858],
              [-39.883104630377012, -8.177566211617052],
              [-39.883104622220444, -8.177566212337561],
              [-39.883104614064557, -8.177566213065392],
              [-39.883104605909324, -8.177566213800571],
              [-39.883104597754759, -8.177566214543074],
              [-39.883104589600862, -8.177566215292906],
              [-39.883104581447661, -8.17756621605006],
              [-39.883104573295142, -8.177566216814546],
              [-39.88310456514332, -8.17756621758639],
              [-39.883104556992208, -8.177566218365543],
              [-39.883104548841786, -8.177566219152025],
              [-39.883104540692095, -8.177566219945833],
              [-39.883104532543115, -8.177566220746966],
              [-39.883104524394874, -8.177566221555411],
              [-39.883104516247364, -8.177566222371199],
              [-39.883104508100594, -8.177566223194281],
              [-39.883104499954577, -8.177566224024703],
              [-39.883104491809313, -8.177566224862456],
              [-39.883104483664809, -8.177566225707517],
              [-39.88310447552108, -8.177566226559904],
              [-39.883104467378111, -8.177566227419598],
              [-39.883104459235945, -8.177566228286624],
              [-39.883104512168181, -8.177566240988604],
              [-39.880958109215932, -8.177795769834145],
              [-39.880958086091731, -8.177795754722828],
              [-39.880957993624456, -8.177795764133872],
              [-39.880957901065315, -8.177795772601065],
              [-39.880957808424014, -8.177795780123496],
              [-39.880957715710267, -8.177795786700381],
              [-39.880957622933785, -8.177795792331048],
              [-39.880957530104297, -8.17779579701492],
              [-39.880957437231558, -8.177795800751467],
              [-39.880957344325275, -8.177795803540308],
              [-39.880957251395216, -8.177795805381171],
              [-39.880957158451089, -8.177795806273846],
              [-39.880957065502692, -8.177795806218221],
              [-39.880956972559709, -8.177795805214339],
              [-39.880956879631931, -8.177795803262283],
              [-39.880956786729072, -8.177795800362285],
              [-39.880956693860902, -8.177795796514605],
              [-39.880956601037127, -8.177795791719676],
              [-39.880956508267509, -8.177795785978004],
              [-39.880956415561748, -8.177795779290177],
              [-39.880956322929578, -8.177795771656909],
              [-39.880956230380711, -8.17779576307897],
              [-39.880956137924848, -8.177795753557287],
              [-39.880956045571693, -8.17779574309283],
              [-39.880955953330918, -8.177795731686757],
              [-39.880955861212186, -8.177795719340224],
              [-39.88095576922516, -8.177795706054514],
              [-39.880955677379504, -8.177795691831031],
              [-39.880955585684823, -8.177795676671259],
              [-39.88095549415074, -8.177795660576807],
              [-39.88095540278686, -8.17779564354934],
              [-39.880955311602754, -8.177795625590676],
              [-39.880955220607987, -8.177795606702661],
              [-39.880955129812094, -8.177795586887292],
              [-39.880955039224602, -8.177795566146663],
              [-39.880954948854999, -8.177795544482919],
              [-39.880954858712762, -8.177795521898336],
              [-39.880954768807356, -8.177795498395284],
              [-39.880954679148189, -8.177795473976254],
              [-39.880954589744668, -8.177795448643758],
              [-39.880954500606173, -8.177795422400502],
              [-39.880954411742046, -8.177795395249209],
              [-39.88095432316161, -8.177795367192735],
              [-39.880954234874132, -8.177795338234011],
              [-39.88095414688889, -8.17779530837608],
              [-39.8809540592151, -8.177795277622076],
              [-39.880953971861956, -8.177795245975226],
              [-39.880953884838618, -8.17779521343885],
              [-39.880953798154209, -8.17779518001633],
              [-39.880953711817824, -8.177795145711197],
              [-39.880953625838501, -8.177795110527041],
              [-39.880953540225249, -8.177795074467548],
              [-39.880953454987065, -8.177795037536523],
              [-39.8809533701329, -8.177794999737818],
              [-39.880953285671602, -8.177794961075394],
              [-39.880953201612073, -8.177794921553319],
              [-39.880953117963102, -8.177794881175705],
              [-39.880953034733452, -8.177794839946813],
              [-39.880952951931882, -8.177794797870973],
              [-39.880952869567054, -8.177794754952572],
              [-39.880952787647587, -8.177794711196093],
              [-39.880952706182107, -8.177794666606186],
              [-39.88095262517912, -8.177794621187497],
              [-39.88095254464713, -8.177794574944759],
              [-39.880952464594586, -8.177794527882833],
              [-39.880952385029886, -8.17779448000668],
              [-39.880952305961372, -8.17779443132132],
              [-39.880952227397323, -8.177794381831811],
              [-39.88095214934598, -8.177794331543385],
              [-39.880952071815521, -8.177794280461306],
              [-39.880951994814097, -8.177794228590917],
              [-39.880951918349759, -8.177794175937677],
              [-39.880951842430534, -8.177794122507095],
              [-39.880951767064388, -8.177794068304763],
              [-39.880951692259181, -8.177794013336369],
              [-39.880951618022827, -8.177793957607694],
              [-39.880951544363057, -8.177793901124573],
              [-39.880951471287602, -8.177793843892912],
              [-39.880951398804136, -8.177793785918729],
              [-39.880951326920261, -8.177793727208087],
              [-39.880951255643495, -8.177793667767171],
              [-39.880951184981328, -8.177793607602178],
              [-39.880951114941162, -8.177793546719414],
              [-39.880951045530338, -8.177793485125314],
              [-39.880950976756161, -8.177793422826275],
              [-39.880950908625778, -8.177793359828879],
              [-39.880950841146387, -8.177793296139685],
              [-39.880950774325079, -8.177793231765389],
              [-39.88095070816879, -8.17779316671275],
              [-39.880950642684503, -8.177793100988575],
              [-39.880950577879091, -8.177793034599782],
              [-39.880950513759331, -8.177792967553311],
              [-39.880950450331952, -8.177792899856179],
              [-39.880950387603583, -8.177792831515498],
              [-39.880950325580841, -8.177792762538434],
              [-39.88095026427019, -8.177792692932233],
              [-39.88095020367809, -8.177792622704171],
              [-39.880950143810871, -8.177792551861604],
              [-39.880950084674822, -8.177792480412005],
              [-39.880950026276146, -8.177792408362803],
              [-39.880949968621167, -8.177792335721236],
              [-39.88095002277619, -8.177792350554055],
              [-39.880162533265775, -8.176789676209362],
              [-39.880162495450726, -8.176789666817486],
              [-39.880162418885078, -8.176789567928799],
              [-39.880162343697229, -8.176789467996105],
              [-39.880162269901525, -8.176789367038472],
              [-39.880162197512021, -8.176789265075151],
              [-39.88016212654253, -8.176789162125568],
              [-39.880162057006579, -8.176789058209344],
              [-39.880161988917436, -8.176788953346341],
              [-39.880161922288075, -8.176788847556493],
              [-39.880161857131228, -8.176788740860042],
              [-39.880161793459273, -8.176788633277294],
              [-39.880161731284389, -8.17678852482876],
              [-39.880161670618413, -8.176788415535128],
              [-39.880161611472928, -8.176788305417244],
              [-39.88016155385921, -8.176788194496106],
              [-39.880161497788229, -8.176788082792877],
              [-39.880161443270708, -8.176787970328816],
              [-39.880161390316999, -8.176787857125399],
              [-39.880161338937235, -8.176787743204216],
              [-39.880161289141206, -8.176787628586967],
              [-39.880161240938413, -8.176787513295553],
              [-39.880161194338022, -8.176787397351905],
              [-39.88016114934895, -8.176787280778175],
              [-39.880161105979752, -8.176787163596577],
              [-39.880161064238713, -8.176787045829441],
              [-39.880161024133784, -8.176786927499231],
              [-39.880160985672617, -8.176786808628526],
              [-39.880160948862546, -8.176786689239982],
              [-39.880160913710597, -8.176786569356361],
              [-39.880160880223457, -8.176786449000533],
              [-39.880160848407527, -8.176786328195471],
              [-39.88016081826887, -8.176786206964159],
              [-39.880160789813218, -8.17678608532975],
              [-39.880160763046021, -8.176785963315421],
              [-39.880160737972361, -8.176785840944444],
              [-39.880160714597039, -8.176785718240168],
              [-39.880160692924498, -8.176785595225962],
              [-39.880160672958887, -8.176785471925342],
              [-39.880160654703992, -8.176785348361761],
              [-39.880160638163311, -8.176785224558779],
              [-39.880160623339982, -8.17678510054003],
              [-39.880160610236857, -8.176784976329134],
              [-39.880160598856399, -8.176784851949837],
              [-39.88016058920082, -8.176784727425826],
              [-39.880160581271916, -8.17678460278082],
              [-39.880160575071237, -8.176784478038622],
              [-39.880160570599955, -8.176784353222995],
              [-39.880160567858908, -8.176784228357747],
              [-39.880160566848637, -8.176784103466693],
              [-39.880160567569312, -8.17678397857366],
              [-39.880160570020834, -8.17678385370243],
              [-39.880160574202691, -8.176783728876861],
              [-39.880160580114115, -8.176783604120727],
              [-39.88016058775397, -8.176783479457811],
              [-39.880160597120785, -8.176783354911894],
              [-39.880160608212805, -8.176783230506732],
              [-39.88016062102789, -8.176783106266033],
              [-39.88016063556362, -8.176782982213505],
              [-39.880160651817192, -8.176782858372798],
              [-39.880160669785518, -8.176782734767505],
              [-39.880160689465193, -8.176782611421238],
              [-39.880160710852429, -8.176782488357475],
              [-39.880160733943178, -8.176782365599733],
              [-39.880160758733005, -8.176782243171363],
              [-39.880160785217235, -8.176782121095746],
              [-39.880160813390773, -8.176781999396171],
              [-39.880160843248248, -8.176781878095818],
              [-39.880160874783982, -8.17678175721781],
              [-39.88016090799195, -8.176781636785224],
              [-39.880160942865835, -8.176781516821002],
              [-39.880160979398973, -8.176781397348035],
              [-39.880161017584413, -8.176781278389122],
              [-39.880161057414846, -8.176781159966923],
              [-39.880161098882716, -8.176781042104011],
              [-39.880161141980075, -8.1767809248229],
              [-39.880161186698729, -8.176780808145896],
              [-39.880161233030151, -8.176780692095296],
              [-39.880161280965488, -8.176780576693199],
              [-39.880161330495625, -8.176780461961664],
              [-39.880161381611096, -8.176780347922506],
              [-39.880161434302153, -8.1767802345975],
              [-39.88016148855877, -8.17678012200825],
              [-39.880161544370594, -8.176780010176218],
              [-39.880161601726968, -8.176779899122741],
              [-39.880161660616956, -8.176779788869],
              [-39.880161721029339, -8.176779679436015],
              [-39.880161782952584, -8.176779570844646],
              [-39.880161846374904, -8.176779463115608],
              [-39.880161911284191, -8.176779356269465],
              [-39.880161977668067, -8.176779250326527],
              [-39.880162045513892, -8.176779145307053],
              [-39.880162114808698, -8.176779041231047],
              [-39.880162185539277, -8.176778938118357],
              [-39.880162257692149, -8.176778835988669],
              [-39.880162331253587, -8.176778734861433],
              [-39.880162406209514, -8.176778634755928],
              [-39.880162482545636, -8.17677853569128],
              [-39.880162560247449, -8.176778437686345],
              [-39.880162639300082, -8.176778340759782],
              [-39.880162719687632, -8.176778244931285],
              [-39.880162780597253, -8.176778279623818],
              [-39.879821702665133, -8.176180819916455],
              [-39.882077529725493, -8.174527458382528],
              [-39.882077475533947, -8.174527447220498],
              [-39.882077497502159, -8.174527421494208],
              [-39.882077519566636, -8.174527395849852],
              [-39.882077541727099, -8.174527370287763],
              [-39.882077563983209, -8.174527344808336],
              [-39.882077586334667, -8.174527319411904],
              [-39.882077608781152, -8.174527294098812],
              [-39.882077631322367, -8.17452726886942],
              [-39.882077653958, -8.174527243724096],
              [-39.882077676687722, -8.174527218663165],
              [-39.882077699511214, -8.174527193686972],
              [-39.882077722428178, -8.174527168795922],
              [-39.882077745438259, -8.174527143990295],
              [-39.882077768541158, -8.174527119270486],
              [-39.882077791736563, -8.174527094636799],
              [-39.882077815024132, -8.174527070089603],
              [-39.882077838403561, -8.174527045629251],
              [-39.882077861874492, -8.174527021256049],
              [-39.882077885436615, -8.174526996970355],
              [-39.882077909089617, -8.174526972772503],
              [-39.882077932833155, -8.174526948662825],
              [-39.88207795666689, -8.174526924641686],
              [-39.882077980590488, -8.174526900709383],
              [-39.882078004603635, -8.17452687686629],
              [-39.882078028705983, -8.174526853112711],
              [-39.882078052897192, -8.174526829448997],
              [-39.882078077176928, -8.174526805875443],
              [-39.882078101544856, -8.17452678239243],
              [-39.882078126000636, -8.17452675900023],
              [-39.882078150543919, -8.174526735699233],
              [-39.882078175174378, -8.174526712489691],
              [-39.882078199891659, -8.174526689371991],
              [-39.882078224695405, -8.174526666346408],
              [-39.882078249585305, -8.174526643413293],
              [-39.882078274560982, -8.174526620572948],
              [-39.882078299622094, -8.174526597825697],
              [-39.882078324768273, -8.17452657517188],
              [-39.882078349999219, -8.174526552611777],
              [-39.882078375314542, -8.174526530145728],
              [-39.882078400713894, -8.174526507774027],
              [-39.882078426196905, -8.174526485497005],
              [-39.882078451763263, -8.174526463314949],
              [-39.882078477412577, -8.174526441228199],
              [-39.882078503144498, -8.174526419237043],
              [-39.882078528958672, -8.174526397341781],
              [-39.882078554854736, -8.174526375542738],
              [-39.882078580832321, -8.17452635384022],
              [-39.882078606891078, -8.174526332234509],
              [-39.882078633030623, -8.174526310725923],
              [-39.882078659250631, -8.174526289314757],
              [-39.882078685550681, -8.174526268001296],
              [-39.882078711930447, -8.174526246785842],
              [-39.882078738389559, -8.1745262256687],
              [-39.882078764927627, -8.174526204650192],
              [-39.882078791544295, -8.174526183730555],
              [-39.882078818239187, -8.174526162910087],
              [-39.882078845011939, -8.17452614218915],
              [-39.88207887186217, -8.174526121567943],
              [-39.882078898789494, -8.174526101046807],
              [-39.882078925793564, -8.174526080626027],
              [-39.882078952873982, -8.174526060305874],
              [-39.882078980030371, -8.174526040086617],
              [-39.882079007262369, -8.174526019968559],
              [-39.882079034569585, -8.174525999951971],
              [-39.882079061951629, -8.174525980037128],
              [-39.88207908940813, -8.174525960224335],
              [-39.882079116938705, -8.174525940513817],
              [-39.882079144542956, -8.174525920905907],
              [-39.882079172220521, -8.174525901400854],
              [-39.882079199971002, -8.174525881998942],
              [-39.882079227794016, -8.174525862700412],
              [-39.882079255689177, -8.174525843505553],
              [-39.882079283656061, -8.17452582441463],
              [-39.882079311694334, -8.1745258054279],
              [-39.882079339803568, -8.174525786545614],
              [-39.882079367983387, -8.174525767768097],
              [-39.882079396233387, -8.174525749095581],
              [-39.882079424553183, -8.17452573052827],
              [-39.882079452942371, -8.17452571206649],
              [-39.882079481400567, -8.174525693710489],
              [-39.882079509927358, -8.174525675460503],
              [-39.882079538522369, -8.174525657316813],
              [-39.882079567185173, -8.174525639279624],
              [-39.882079595915386, -8.174525621349234],
              [-39.882079624712624, -8.174525603525888],
              [-39.882079653576461, -8.174525585809816],
              [-39.882079682506493, -8.174525568201277],
              [-39.882079711502321, -8.174525550700499],
              [-39.88207974056354, -8.174525533307737],
              [-39.88207976968976, -8.174525516023216],
              [-39.882079798880561, -8.17452549884726],
              [-39.882079828135545, -8.174525481780018],
              [-39.882079857454286, -8.174525464821754],
              [-39.882079886836387, -8.174525447972686],
              [-39.882079916281434, -8.174525431233102],
              [-39.882079945789037, -8.174525414603204],
              [-39.882079975358756, -8.174525398083201],
              [-39.882080004990179, -8.17452538167335],
              [-39.882080034682915, -8.17452536537388],
              [-39.882080064436593, -8.174525349184643],
              [-39.882080066808584, -8.174525372618728],
              [-39.884787339308076, -8.173058957179828],
              [-39.884787260729695, -8.173058889139661],
              [-39.884787370979282, -8.173058830394522],
              [-39.884787482030447, -8.173058773167902],
              [-39.884787593862121, -8.173058717470672],
              [-39.884787706453089, -8.173058663313425],
              [-39.884787819781991, -8.173058610706391],
              [-39.884787933827326, -8.173058559659566],
              [-39.884788048567472, -8.173058510182651],
              [-39.884788163980652, -8.173058462285033],
              [-39.884788280044972, -8.173058415975747],
              [-39.884788396738422, -8.17305837126364],
              [-39.884788514038846, -8.17305832815715],
              [-39.884788631924025, -8.17305828666448],
              [-39.884788750371548, -8.173058246793477],
              [-39.884788869358992, -8.173058208551737],
              [-39.884788988863761, -8.173058171946485],
              [-39.884789108863188, -8.173058136984684],
              [-39.884789229334523, -8.173058103672966],
              [-39.884789350254884, -8.173058072017646],
              [-39.884789471601344, -8.173058042024742],
              [-39.884789593350888, -8.173058013699913],
              [-39.884789715480416, -8.17305798704855],
              [-39.884789837966764, -8.17305796207572],
              [-39.884789960786698, -8.173057938786144],
              [-39.884790083916918, -8.173057917184257],
              [-39.884790207334042, -8.173057897274132],
              [-39.884790331014692, -8.173057879059558],
              [-39.884790454935391, -8.173057862543972],
              [-39.884790579072636, -8.173057847730552],
              [-39.884790703402857, -8.173057834622105],
              [-39.884790827902492, -8.17305782322107],
              [-39.88479095254791, -8.173057813529629],
              [-39.884791077315477, -8.173057805549634],
              [-39.884791202181511, -8.173057799282585],
              [-39.884791327122336, -8.173057794729679],
              [-39.884791452114243, -8.173057791891779],
              [-39.88479157713352, -8.17305779076942],
              [-39.884791702156456, -8.173057791362814],
              [-39.884791827159326, -8.173057793671838],
              [-39.88479195211842, -8.173057797696089],
              [-39.88479207701004, -8.173057803434761],
              [-39.884792201810484, -8.173057810886812],
              [-39.884792326496083, -8.173057820050794],
              [-39.884792451043182, -8.173057830924989],
              [-39.884792575428158, -8.173057843507308],
              [-39.884792699627397, -8.173057857795383],
              [-39.884792823617367, -8.173057873786513],
              [-39.884792947374528, -8.173057891477635],
              [-39.884793070875396, -8.173057910865396],
              [-39.884793194096574, -8.173057931946138],
              [-39.884793317014648, -8.173057954715864],
              [-39.88479343960632, -8.173057979170231],
              [-39.88479356184834, -8.173058005304631],
              [-39.88479368371749, -8.173058033114078],
              [-39.884793805190689, -8.173058062593309],
              [-39.884793926244868, -8.17305809373674],
              [-39.884794046857074, -8.173058126538434],
              [-39.884794167004415, -8.173058160992213],
              [-39.884794286664103, -8.173058197091503],
              [-39.884794405813452, -8.173058234829462],
              [-39.884794524429843, -8.173058274198931],
              [-39.884794642490782, -8.173058315192465],
              [-39.884794759973857, -8.173058357802272],
              [-39.884794876856802, -8.173058402020272],
              [-39.884794993117445, -8.173058447838072],
              [-39.884795108733712, -8.173058495246957],
              [-39.884795223683668, -8.17305854423798],
              [-39.884795337945519, -8.173058594801802],
              [-39.884795451497617, -8.173058646928871],
              [-39.884795564318381, -8.17305870060928],
              [-39.88479567638641, -8.173058755832832],
              [-39.88479578768046, -8.173058812589073],
              [-39.884795898179419, -8.173058870867207],
              [-39.884796007862299, -8.173058930656241],
              [-39.88479611670833, -8.173058991944769],
              [-39.884796224696835, -8.17305905472119],
              [-39.884796331807337, -8.173059118973571],
              [-39.884796438019528, -8.173059184689766],
              [-39.884796543313243, -8.173059251857277],
              [-39.884796647668523, -8.173059320463384],
              [-39.884796751065565, -8.173059390495055],
              [-39.884796853484744, -8.173059461939008],
              [-39.884796954906633, -8.173059534781673],
              [-39.884797055312006, -8.173059609009281],
              [-39.884797154681813, -8.173059684607699],
              [-39.884797252997188, -8.173059761562602],
              [-39.884797350239509, -8.173059839859411],
              [-39.884797446390294, -8.173059919483249],
              [-39.884797541431325, -8.173060000419001],
              [-39.884797635344562, -8.173060082651375],
              [-39.884797728112197, -8.173060166164694],
              [-39.884797819716631, -8.17306025094315],
              [-39.884797910140477, -8.173060336970691],
              [-39.884797999366583, -8.17306042423095],
              [-39.884798087378044, -8.173060512707396],
              [-39.884798174158135, -8.173060602383227],
              [-39.88479825969042, -8.173060693241442],
              [-39.884798343958643, -8.173060785264816],
              [-39.884798426946837, -8.173060878435882],
              [-39.884798508639804, -8.173060972736215],
              [-39.884798508963236, -8.173061012975012],
              [-39.8860414111018, -8.174515808448106],
              [-39.886041359833889, -8.174515776913164],
              [-39.886041388910755, -8.17451581077917],
              [-39.886041418154754, -8.174515844501979],
              [-39.886041447565177, -8.174515878080824],
              [-39.886041477141291, -8.174515911514847],
              [-39.886041506882421, -8.174515944803277],
              [-39.886041536787801, -8.174515977945251],
              [-39.886041566856726, -8.174516010939998],
              [-39.88604159708845, -8.174516043786729],
              [-39.886041627482264, -8.174516076484636],
              [-39.886041658037421, -8.174516109032913],
              [-39.886041688753153, -8.174516141430752],
              [-39.886041719628757, -8.174516173677414],
              [-39.886041750663452, -8.174516205772125],
              [-39.886041781856498, -8.174516237714041],
              [-39.886041813207129, -8.174516269502428],
              [-39.88604184471459, -8.17451630113651],
              [-39.886041876378123, -8.174516332615498],
              [-39.886041908196951, -8.17451636393867],
              [-39.886041940170294, -8.174516395105202],
              [-39.886041972297384, -8.174516426114417],
              [-39.886042004577448, -8.174516456965479],
              [-39.88604203700968, -8.174516487657701],
              [-39.88604206959333, -8.174516518190321],
              [-39.886042102327572, -8.174516548562588],
              [-39.886042135211611, -8.174516578773792],
              [-39.886042168244671, -8.174516608823163],
              [-39.886042201425923, -8.174516638709976],
              [-39.886042234754612, -8.174516668433542],
              [-39.886042268229872, -8.174516697993086],
              [-39.886042301850914, -8.174516727387912],
              [-39.886042335616914, -8.174516756617319],
              [-39.886042369527068, -8.174516785680622],
              [-39.886042403580547, -8.174516814577052],
              [-39.88604243777651, -8.174516843305916],
              [-39.886042472114148, -8.174516871866558],
              [-39.886042506592609, -8.174516900258277],
              [-39.886042541211054, -8.174516928480351],
              [-39.886042575968659, -8.174516956532131],
              [-39.886042610864585, -8.174516984412922],
              [-39.886042645897952, -8.174517012122033],
              [-39.886042681067941, -8.17451703965882],
              [-39.886042716373673, -8.174517067022601],
              [-39.886042751814308, -8.174517094212668],
              [-39.886042787389002, -8.174517121228433],
              [-39.886042823096851, -8.17451714806919],
              [-39.886042858937017, -8.174517174734337],
              [-39.886042894908613, -8.1745172012232],
              [-39.88604293101077, -8.174517227535086],
              [-39.886042967242624, -8.17451725366943],
              [-39.88604300360327, -8.174517279625542],
              [-39.88604304009187, -8.174517305402849],
              [-39.88604307670748, -8.174517331000704],
              [-39.886043113449254, -8.174517356418439],
              [-39.886043150316283, -8.174517381655491],
              [-39.886043187307678, -8.174517406711203],
              [-39.886043224422522, -8.174517431585024],
              [-39.886043261659957, -8.17451745627633],
              [-39.886043299019015, -8.174517480784496],
              [-39.886043336498851, -8.174517505108941],
              [-39.88604337409852, -8.174517529249073],
              [-39.88604341181712, -8.174517553204293],
              [-39.886043449653741, -8.174517576974075],
              [-39.886043487607452, -8.174517600557769],
              [-39.886043525677316, -8.174517623954815],
              [-39.886043563862444, -8.174517647164691],
              [-39.886043602161898, -8.174517670186839],
              [-39.886043640574741, -8.17451769302061],
              [-39.886043679100034, -8.174517715665537],
              [-39.886043717736854, -8.174517738121033],
              [-39.886043756484256, -8.174517760386559],
              [-39.886043795341308, -8.174517782461564],
              [-39.886043834307053, -8.174517804345562],
              [-39.886043873380551, -8.174517826037945],
              [-39.886043912560858, -8.174517847538244],
              [-39.886043951847022, -8.174517868845925],
              [-39.88604399123809, -8.17451788996045],
              [-39.886044030733089, -8.174517910881315],
              [-39.886044070331081, -8.174517931608015],
              [-39.886044110031101, -8.174517952140064],
              [-39.886044149832173, -8.174517972476963],
              [-39.88604418973334, -8.174517992618155],
              [-39.886044229733635, -8.174518012563237],
              [-39.886044269832077, -8.174518032311672],
              [-39.8860443100277, -8.174518051863009],
              [-39.886044350319523, -8.174518071216728],
              [-39.886044390706573, -8.174518090372425],
              [-39.886044431187869, -8.174518109329581],
              [-39.886044471762425, -8.174518128087778],
              [-39.886044512429251, -8.174518146646509],
              [-39.886044553187375, -8.174518165005361],
              [-39.886044594035809, -8.174518183163858],
              [-39.88604463497353, -8.174518201121584],
              [-39.886044675999571, -8.174518218878122],
              [-39.886044717112924, -8.174518236433032],
              [-39.886044758312607, -8.17451825378585],
              [-39.886044799597599, -8.174518270936201],
              [-39.88604484096691, -8.174518287883622],
              [-39.88604488241954, -8.174518304627721],
              [-39.886044923954451, -8.174518321168136],
              [-39.886044956847016, -8.174518333130834],
              [-39.887738731090565, -8.175188069440209],
              [-39.887738668184788, -8.175187995607684],
              [-39.887738754263822, -8.175188029137086],
              [-39.887738840681038, -8.1751880617915],
              [-39.887738927427449, -8.175188093567588],
              [-39.887739014494073, -8.175188124461981],
              [-39.887739101871887, -8.175188154471497],
              [-39.887739189551823, -8.175188183593061],
              [-39.887739277524794, -8.175188211823638],
              [-39.887739365781677, -8.175188239160304],
              [-39.887739454313341, -8.175188265600216],
              [-39.887739543110584, -8.17518829114063],
              [-39.887739632164219, -8.175188315778882],
              [-39.887739721465017, -8.175188339512474],
              [-39.887739811003698, -8.175188362338892],
              [-39.887739900770995, -8.175188384255785],
              [-39.887739990757609, -8.175188405260869],
              [-39.887740080954188, -8.175188425352003],
              [-39.887740171351403, -8.175188444527068],
              [-39.887740261939875, -8.175188462784098],
              [-39.887740352710217, -8.175188480121191],
              [-39.887740443653016, -8.175188496536554],
              [-39.887740534758834, -8.175188512028464],
              [-39.887740626018235, -8.175188526595353],
              [-39.887740717421764, -8.175188540235679],
              [-39.887740808959947, -8.175188552948052],
              [-39.887740900623285, -8.175188564731135],
              [-39.887740992402279, -8.175188575583729],
              [-39.88774108428742, -8.175188585504676],
              [-39.887741176269174, -8.175188594492955],
              [-39.887741268338004, -8.175188602547642],
              [-39.887741360484362, -8.175188609667902],
              [-39.887741452698712, -8.175188615853008],
              [-39.88774154497149, -8.175188621102345],
              [-39.887741637293125, -8.175188625415277],
              [-39.887741729654032, -8.175188628791441],
              [-39.887741822044674, -8.17518863123046],
              [-39.887741914455425, -8.175188632732077],
              [-39.887742006876756, -8.175188633296163],
              [-39.887742099299047, -8.1751886329226],
              [-39.887742191712739, -8.175188631611475],
              [-39.887742284108221, -8.175188629362916],
              [-39.887742376475956, -8.175188626177137],
              [-39.887742468806337, -8.175188622054508],
              [-39.887742561089809, -8.175188616995429],
              [-39.887742653316813, -8.175188611000415],
              [-39.887742745477766, -8.175188604070101],
              [-39.88774283756311, -8.175188596205206],
              [-39.887742929563338, -8.175188587406545],
              [-39.887743021468864, -8.175188577675026],
              [-39.887743113270197, -8.175188567011668],
              [-39.887743204957815, -8.175188555417567],
              [-39.887743296522189, -8.175188542893938],
              [-39.887743387953847, -8.175188529442057],
              [-39.887743479243305, -8.175188515063317],
              [-39.887743570381105, -8.175188499759226],
              [-39.887743661357788, -8.175188483531389],
              [-39.887743752163928, -8.175188466381424],
              [-39.887743842790115, -8.175188448311175],
              [-39.88774393322695, -8.175188429322491],
              [-39.887744023465061, -8.175188409417343],
              [-39.887744113495089, -8.175188388597764],
              [-39.887744203307712, -8.175188366865964],
              [-39.887744292893593, -8.175188344224157],
              [-39.887744382243476, -8.175188320674716],
              [-39.887744471348078, -8.175188296220082],
              [-39.887744560198165, -8.17518827086276],
              [-39.88774464878454, -8.175188244605394],
              [-39.887744737098011, -8.175188217450728],
              [-39.887744825129396, -8.175188189401556],
              [-39.887744912869621, -8.175188160460795],
              [-39.88774500030955, -8.175188130631431],
              [-39.887745087440145, -8.175188099916564],
              [-39.887745174252338, -8.17518806831937],
              [-39.887745260737169, -8.175188035843149],
              [-39.887745346885644, -8.175188002491259],
              [-39.887745432688853, -8.175187968267121],
              [-39.887745518137883, -8.175187933174326],
              [-39.887745603223884, -8.175187897216494],
              [-39.887745687938029, -8.175187860397365],
              [-39.887745772271543, -8.17518782272075],
              [-39.887745856215687, -8.175187784190571],
              [-39.887745939761764, -8.175187744810779],
              [-39.887746022901091, -8.175187704585479],
              [-39.887746105625062, -8.175187663518845],
              [-39.887746187925096, -8.175187621615144],
              [-39.887746269792665, -8.175187578878706],
              [-39.887746351219299, -8.175187535313951],
              [-39.887746432196529, -8.175187490925421],
              [-39.887746512715964, -8.175187445717688],
              [-39.887746592769282, -8.175187399695488],
              [-39.88774667234815, -8.175187352863539],
              [-39.88774675144434, -8.175187305226711],
              [-39.887746830049643, -8.175187256789954],
              [-39.887746908155911, -8.175187207558279],
              [-39.887746985755065, -8.175187157536802],
              [-39.887747062839033, -8.175187106730684],
              [-39.887747139399849, -8.175187055145214],
              [-39.887747215429535, -8.17518700278575],
              [-39.887747290920281, -8.175186949657698],
              [-39.887747365864193, -8.175186895766551],
              [-39.887747442931932, -8.17518696497898],
              [-39.89023017905037, -8.173382300009118],
              [-39.890230188559173, -8.173382292594775],
              [-39.890230209659826, -8.173382277362192],
              [-39.890230230804185, -8.173382262189872],
              [-39.890230251992058, -8.173382247077972],
              [-39.890230273223295, -8.173382232026576],
              [-39.890230294497705, -8.173382217035845],
              [-39.89023031581511, -8.173382202105856],
              [-39.890230337175353, -8.173382187236788],
              [-39.890230358578251, -8.173382172428733],
              [-39.890230380023617, -8.173382157681814],
              [-39.890230401511282, -8.173382142996164],
              [-39.890230423041082, -8.173382128371859],
              [-39.890230444612826, -8.173382113809069],
              [-39.890230466226335, -8.173382099307879],
              [-39.890230487881439, -8.173382084868459],
              [-39.890230509577968, -8.173382070490911],
              [-39.890230531315737, -8.17338205617528],
              [-39.890230553094554, -8.173382041921792],
              [-39.890230574914263, -8.173382027730495],
              [-39.890230596774657, -8.173382013601492],
              [-39.890230618675595, -8.173381999534952],
              [-39.890230640616863, -8.173381985530938],
              [-39.890230662598306, -8.173381971589629],
              [-39.890230684619723, -8.173381957711099],
              [-39.890230706680946, -8.173381943895436],
              [-39.890230728781788, -8.173381930142829],
              [-39.890230750922079, -8.173381916453309],
              [-39.890230773101628, -8.173381902827062],
              [-39.890230795320235, -8.173381889264157],
              [-39.890230817577752, -8.173381875764708],
              [-39.890230839873979, -8.173381862328824],
              [-39.890230862208739, -8.173381848956623],
              [-39.890230884581833, -8.173381835648213],
              [-39.890230906993096, -8.173381822403723],
              [-39.890230929442339, -8.173381809223198],
              [-39.890230951929382, -8.173381796106808],
              [-39.890230974454028, -8.173381783054658],
              [-39.890230997016097, -8.173381770066831],
              [-39.890231019615406, -8.173381757143439],
              [-39.89023104225177, -8.173381744284608],
              [-39.890231064925011, -8.173381731490387],
              [-39.89023108763493, -8.173381718760947],
              [-39.890231110381343, -8.173381706096334],
              [-39.890231133164065, -8.173381693496715],
              [-39.89023115598291, -8.173381680962155],
              [-39.890231178837709, -8.173381668492755],
              [-39.890231201728234, -8.173381656088628],
              [-39.890231224654322, -8.173381643749901],
              [-39.890231247615787, -8.17338163147665],
              [-39.890231270612439, -8.173381619268939],
              [-39.890231293644099, -8.173381607126901],
              [-39.890231316710548, -8.173381595050639],
              [-39.890231339811614, -8.173381583040273],
              [-39.890231362947119, -8.173381571095835],
              [-39.890231386116859, -8.173381559217479],
              [-39.890231409320641, -8.173381547405324],
              [-39.890231432558288, -8.173381535659434],
              [-39.8902314558296, -8.173381523979877],
              [-39.890231479134393, -8.173381512366792],
              [-39.890231502472467, -8.173381500820232],
              [-39.890231525843632, -8.173381489340327],
              [-39.890231549247709, -8.173381477927176],
              [-39.890231572684492, -8.17338146658085],
              [-39.890231596153789, -8.173381455301456],
              [-39.89023161965541, -8.173381444089086],
              [-39.890231643189175, -8.173381432943799],
              [-39.890231666754865, -8.173381421865779],
              [-39.890231690352316, -8.173381410854988],
              [-39.890231713981322, -8.173381399911612],
              [-39.890231737641685, -8.173381389035695],
              [-39.89023176133319, -8.173381378227345],
              [-39.890231785055683, -8.173381367486634],
              [-39.890231808808949, -8.173381356813694],
              [-39.890231832592796, -8.173381346208561],
              [-39.890231856407034, -8.173381335671339],
              [-39.890231880251463, -8.173381325202127],
              [-39.890231904125883, -8.173381314801009],
              [-39.890231928030111, -8.173381304468041],
              [-39.890231951963933, -8.173381294203349],
              [-39.890231975927151, -8.173381284006993],
              [-39.8902319999196, -8.173381273879052],
              [-39.89023202394106, -8.173381263819612],
              [-39.890232047991319, -8.17338125382881],
              [-39.890232072070205, -8.173381243906631],
              [-39.890232096177535, -8.173381234053238],
              [-39.890232120313073, -8.173381224268637],
              [-39.890232144476627, -8.173381214552974],
              [-39.890232168668021, -8.173381204906343],
              [-39.890232192887034, -8.173381195328732],
              [-39.890232217133502, -8.173381185820288],
              [-39.890232241407176, -8.173381176381108],
              [-39.890232265707887, -8.173381167011188],
              [-39.890232290035435, -8.173381157710686],
              [-39.890232314389628, -8.173381148479619],
              [-39.890232338770247, -8.173381139318085],
              [-39.890232363177098, -8.17338113022617],
              [-39.890232387609977, -8.173381121203946],
              [-39.890232412068706, -8.173381112251459],
              [-39.890232436553049, -8.173381103368826],
              [-39.890232461062915, -8.173381094555497],
              [-39.890232546047265, -8.17338111162614],
              [-39.891799863504097, -8.172820429954669],
              [-39.891799776957683, -8.172820358462237],
              [-39.891799962296865, -8.172820289873856],
              [-39.891800146092862, -8.172820217279771],
              [-39.891800328258448, -8.172820140714428],
              [-39.891800508707227, -8.172820060214164],
              [-39.891800687353573, -8.17281997581714],
              [-39.891800864112753, -8.172819887563415],
              [-39.891801038900901, -8.172819795494815],
              [-39.891801211635112, -8.172819699655072],
              [-39.891801382233432, -8.172819600089609],
              [-39.891801550614929, -8.172819496845708],
              [-39.89180171669971, -8.172819389972295],
              [-39.891801880409005, -8.172819279520121],
              [-39.891802041665137, -8.172819165541524],
              [-39.891802200391631, -8.172819048090641],
              [-39.891802356513153, -8.172818927223155],
              [-39.891802509955667, -8.17281880299638],
              [-39.891802660646356, -8.172818675469319],
              [-39.891802808513752, -8.172818544702407],
              [-39.891802953487691, -8.17281841075771],
              [-39.891803095499412, -8.172818273698772],
              [-39.891803234481529, -8.172818133590582],
              [-39.891803370368144, -8.172817990499667],
              [-39.891803503094735, -8.172817844493856],
              [-39.891803632598375, -8.172817695642419],
              [-39.891803758817623, -8.172817544016022],
              [-39.891803881692596, -8.17281738968652],
              [-39.891804001165013, -8.1728172327272],
              [-39.891804117178175, -8.172817073212471],
              [-39.891804229677064, -8.172816911218012],
              [-39.891804338608303, -8.172816746820716],
              [-39.891804443920215, -8.17281658009852],
              [-39.891804545562842, -8.172816411130537],
              [-39.891804643487959, -8.172816239996937],
              [-39.891804737649132, -8.172816066778923],
              [-39.891804828001654, -8.172815891558608],
              [-39.8918049145027, -8.172815714419183],
              [-39.891804997111201, -8.17281553544464],
              [-39.891805075788, -8.172815354719887],
              [-39.891805150495742, -8.172815172330704],
              [-39.891805221199014, -8.172814988363555],
              [-39.891805287864251, -8.172814802905734],
              [-39.891805350459826, -8.172814616045244],
              [-39.891805408956067, -8.1728144278707],
              [-39.891805463325198, -8.17281423847141],
              [-39.891805513541449, -8.172814047937184],
              [-39.89180555958098, -8.172813856358443],
              [-39.891805601421964, -8.17281366382605],
              [-39.891805639044549, -8.172813470431366],
              [-39.89180567243087, -8.172813276266115],
              [-39.891805701565104, -8.172813081422424],
              [-39.891805726433425, -8.172812885992718],
              [-39.89180574702403, -8.172812690069705],
              [-39.89180576332717, -8.172812493746342],
              [-39.891805775335087, -8.172812297115748],
              [-39.891805783042109, -8.172812100271216],
              [-39.891805786444557, -8.172811903306139],
              [-39.891805785540839, -8.172811706313951],
              [-39.89180578033136, -8.172811509388112],
              [-39.891805770818607, -8.172811312621997],
              [-39.891805757007084, -8.172811116108997],
              [-39.891805738903358, -8.172810919942339],
              [-39.891805716515996, -8.17281072421507],
              [-39.89180568985563, -8.172810529020053],
              [-39.891805658934913, -8.172810334449885],
              [-39.891805623768505, -8.17281014059685],
              [-39.891805584373103, -8.172809947552944],
              [-39.891805540767372, -8.17280975540973],
              [-39.891805492972011, -8.172809564258355],
              [-39.891805441009716, -8.172809374189514],
              [-39.891805384905105, -8.172809185293366],
              [-39.891805324684796, -8.172808997659521],
              [-39.891805260377375, -8.172808811377017],
              [-39.891805192013344, -8.17280862653417],
              [-39.891805119625118, -8.172808443218727],
              [-39.891805043247061, -8.172808261517641],
              [-39.891804962915415, -8.172808081517102],
              [-39.891804878668253, -8.172807903302489],
              [-39.891804790545564, -8.172807726958387],
              [-39.891804698589155, -8.172807552568395],
              [-39.891804602842633, -8.172807380215303],
              [-39.891804503351459, -8.172807209980833],
              [-39.891804400162783, -8.172807041945779],
              [-39.891804293325592, -8.172806876189821],
              [-39.891804182890553, -8.172806712791616],
              [-39.891804068910069, -8.172806551828662],
              [-39.891803951438199, -8.172806393377344],
              [-39.891803830530669, -8.172806237512807],
              [-39.891803706244872, -8.172806084309018],
              [-39.891803578639717, -8.172805933838633],
              [-39.891803447775779, -8.172805786173042],
              [-39.891803313715101, -8.172805641382316],
              [-39.891803176521343, -8.172805499535126],
              [-39.891803036259525, -8.172805360698771],
              [-39.891802892996225, -8.172805224939101],
              [-39.891802746799392, -8.172805092320516],
              [-39.891802597738412, -8.172804962905973],
              [-39.891802445883947, -8.172804836756839],
              [-39.891802291308075, -8.172804713932951],
              [-39.891802134084116, -8.172804594492563],
              [-39.891802188444359, -8.172804597030533],
              [-39.890003644168836, -8.171468892860183],
              [-39.890003617628025, -8.171468891218487],
              [-39.89000355693269, -8.171468845746871],
              [-39.890003496621262, -8.171468799770929],
              [-39.890003436697953, -8.171468753293862],
              [-39.890003377166977, -8.171468706318944],
              [-39.890003318032484, -8.171468658849459],
              [-39.890003259298609, -8.171468610888729],
              [-39.890003200969488, -8.171468562440104],
              [-39.890003143049171, -8.171468513506968],
              [-39.890003085541736, -8.171468464092733],
              [-39.890003028451197, -8.171468414200902],
              [-39.890002971781549, -8.171468363834942],
              [-39.890002915536748, -8.171468312998369],
              [-39.890002859720767, -8.171468261694741],
              [-39.89000280433747, -8.171468209927671],
              [-39.890002749390753, -8.17146815770076],
              [-39.890002694884465, -8.171468105017667],
              [-39.890002640822402, -8.171468051882092],
              [-39.890002587208379, -8.171467998297736],
              [-39.890002534046104, -8.171467944268379],
              [-39.890002481339351, -8.171467889797762],
              [-39.890002429091744, -8.17146783488973],
              [-39.890002377307006, -8.171467779548095],
              [-39.890002325988732, -8.171467723776749],
              [-39.89000227514051, -8.171467667579609],
              [-39.890002224765894, -8.171467610960581],
              [-39.890002174868421, -8.17146755392365],
              [-39.890002125451581, -8.171467496472802],
              [-39.890002076518833, -8.171467438612044],
              [-39.890002028073589, -8.17146738034544],
              [-39.890001980119266, -8.171467321677042],
              [-39.890001932659196, -8.171467262611005],
              [-39.890001885696712, -8.171467203151417],
              [-39.89000183923509, -8.171467143302467],
              [-39.890001793277612, -8.17146708306834],
              [-39.890001747827434, -8.17146702245322],
              [-39.890001702887808, -8.171466961461386],
              [-39.890001658461827, -8.171466900097107],
              [-39.890001614552624, -8.171466838364655],
              [-39.890001571163261, -8.171466776268366],
              [-39.890001528296793, -8.171466713812571],
              [-39.890001485956205, -8.171466651001674],
              [-39.89000144414446, -8.171466587840026],
              [-39.890001402864485, -8.171466524332059],
              [-39.890001362119172, -8.171466460482241],
              [-39.89000132191137, -8.171466396295024],
              [-39.890001282243894, -8.171466331774887],
              [-39.890001243119528, -8.171466266926384],
              [-39.890001204541008, -8.171466201754027],
              [-39.89000116651102, -8.17146613626239],
              [-39.890001129032228, -8.171466070456042],
              [-39.890001092107269, -8.171466004339591],
              [-39.890001055738715, -8.17146593791767],
              [-39.890001019929123, -8.171465871194931],
              [-39.890000984680995, -8.17146580417603],
              [-39.890000949996789, -8.171465736865674],
              [-39.890000915878929, -8.171465669268557],
              [-39.890000882329829, -8.171465601389418],
              [-39.890000849351814, -8.171465533233011],
              [-39.8900008169472, -8.171465464804097],
              [-39.890000785118254, -8.171465396107472],
              [-39.890000753867199, -8.171465327147946],
              [-39.890000723196231, -8.171465257930338],
              [-39.890000693107488, -8.171465188459507],
              [-39.890000663603075, -8.171465118740285],
              [-39.890000634685066, -8.171465048777591],
              [-39.890000606355464, -8.171464978576298],
              [-39.890000578616302, -8.171464908141324],
              [-39.89000055146947, -8.171464837477584],
              [-39.890000524916879, -8.171464766590029],
              [-39.890000498960397, -8.171464695483643],
              [-39.890000473601837, -8.171464624163384],
              [-39.890000448842976, -8.171464552634227],
              [-39.890000424685532, -8.171464480901196],
              [-39.890000401131218, -8.171464408969332],
              [-39.890000378181675, -8.171464336843664],
              [-39.890000355838509, -8.171464264529211],
              [-39.890000334103277, -8.171464192031038],
              [-39.890000312977499, -8.171464119354232],
              [-39.890000292462666, -8.171464046503894],
              [-39.890000272560201, -8.171463973485093],
              [-39.890000253271509, -8.171463900302957],
              [-39.890000234597927, -8.171463826962599],
              [-39.890000216540777, -8.171463753469142],
              [-39.890000199101308, -8.171463679827749],
              [-39.890000182280751, -8.171463606043565],
              [-39.890000166080284, -8.171463532121763],
              [-39.890000150501024, -8.17146345806751],
              [-39.890000135544078, -8.171463383885992],
              [-39.890000121210484, -8.171463309582403],
              [-39.890000107501258, -8.171463235161935],
              [-39.890000094417331, -8.171463160629798],
              [-39.890000081959663, -8.171463085991212],
              [-39.890000070129091, -8.17146301125138],
              [-39.890000058926447, -8.171462936415557],
              [-39.890000048352526, -8.171462861488978],
              [-39.890000038408068, -8.171462786476864],
              [-39.890000029093763, -8.171462711384509],
              [-39.890000020410255, -8.171462636217127],
              [-39.890000012358129, -8.171462560980228],
              [-39.890000090013871, -8.171462569556438],
              [-39.889885239229415, -8.17034592165434],
              [-39.889885239124872, -8.170345855574549],
              [-39.889885228974819, -8.170345750805428],
              [-39.889885220049116, -8.17034564592568],
              [-39.889885212348972, -8.170345540949565],
              [-39.889885205875451, -8.170345435891258],
              [-39.889885200629408, -8.170345330764983],
              [-39.889885196611566, -8.170345225585031],
              [-39.889885193822444, -8.170345120365601],
              [-39.889885192262462, -8.170345015120928],
              [-39.889885191931782, -8.170344909865308],
              [-39.889885192830498, -8.17034480461297],
              [-39.88988519495846, -8.170344699378211],
              [-39.88988519831539, -8.17034459417523],
              [-39.889885202900828, -8.170344489018305],
              [-39.889885208714148, -8.170344383921684],
              [-39.889885215754568, -8.170344278899552],
              [-39.889885224021143, -8.170344173966203],
              [-39.889885233512743, -8.170344069135803],
              [-39.889885244228076, -8.170343964422578],
              [-39.889885256165705, -8.170343859840658],
              [-39.889885269324012, -8.17034375540427],
              [-39.889885283701204, -8.170343651127519],
              [-39.889885299295351, -8.170343547024542],
              [-39.889885316104319, -8.170343443109441],
              [-39.88988533412585, -8.170343339396277],
              [-39.889885353357506, -8.170343235899104],
              [-39.889885373796645, -8.170343132631942],
              [-39.889885395440558, -8.170343029608782],
              [-39.889885418286255, -8.170342926843558],
              [-39.88988544233068, -8.170342824350199],
              [-39.889885467570572, -8.170342722142586],
              [-39.889885494002499, -8.170342620234548],
              [-39.889885521622887, -8.170342518639901],
              [-39.889885550428005, -8.170342417372403],
              [-39.889885580413932, -8.170342316445774],
              [-39.889885611576631, -8.170342215873674],
              [-39.88988564391186, -8.170342115669733],
              [-39.889885677415251, -8.170342015847513],
              [-39.889885712082254, -8.170341916420565],
              [-39.889885747908203, -8.17034181740229],
              [-39.889885784888214, -8.170341718806148],
              [-39.889885823017288, -8.170341620645482],
              [-39.889885862290271, -8.170341522933594],
              [-39.889885902701842, -8.170341425683688],
              [-39.889885944246515, -8.170341328908979],
              [-39.889885986918678, -8.170341232622544],
              [-39.889886030712546, -8.170341136837436],
              [-39.889886075622186, -8.17034104156663],
              [-39.889886121641503, -8.170340946823003],
              [-39.889886168764299, -8.170340852619409],
              [-39.889886216984145, -8.170340758968598],
              [-39.88988626629456, -8.170340665883282],
              [-39.88988631668883, -8.170340573376015],
              [-39.889886368160148, -8.17034048145938],
              [-39.889886420701536, -8.170340390145803],
              [-39.889886474305882, -8.170340299447618],
              [-39.88988652896591, -8.170340209377155],
              [-39.889886584674237, -8.170340119946578],
              [-39.889886641423324, -8.170340031168031],
              [-39.889886699205448, -8.170339943053531],
              [-39.889886758012821, -8.170339855615012],
              [-39.889886817837471, -8.170339768864293],
              [-39.889886878671284, -8.170339682813147],
              [-39.889886940506017, -8.170339597473237],
              [-39.889887003333328, -8.170339512856083],
              [-39.889887067144656, -8.170339428973195],
              [-39.889887131931403, -8.170339345835878],
              [-39.889887197684793, -8.170339263455439],
              [-39.889887264395881, -8.170339181842989],
              [-39.889887332055672, -8.17033910100962],
              [-39.889887400654985, -8.170339020966265],
              [-39.889887470184533, -8.170338941723784],
              [-39.889887540634895, -8.170338863292866],
              [-39.889887611996542, -8.170338785684175],
              [-39.88988768425979, -8.170338708908217],
              [-39.889887757414868, -8.170338632975373],
              [-39.889887831451865, -8.170338557895954],
              [-39.889887906360748, -8.170338483680073],
              [-39.889887982131377, -8.170338410337834],
              [-39.889888058753485, -8.170338337879175],
              [-39.889888136216705, -8.170338266313854],
              [-39.889888214510535, -8.17033819565161],
              [-39.889888293624374, -8.170338125902003],
              [-39.889888373547507, -8.170338057074467],
              [-39.889888454269112, -8.170337989178337],
              [-39.889888535778262, -8.170337922222805],
              [-39.889888618063907, -8.170337856216944],
              [-39.889888701114891, -8.170337791169684],
              [-39.889888784920004, -8.170337727089827],
              [-39.889888869467846, -8.170337663986098],
              [-39.889888954747029, -8.17033760186699],
              [-39.889889040745949, -8.170337540740933],
              [-39.889889127452975, -8.170337480616215],
              [-39.889889214856375, -8.17033742150096],
              [-39.889889302944304, -8.170337363403211],
              [-39.889889391704813, -8.170337306330801],
              [-39.889889481125913, -8.170337250291468],
              [-39.889889571195475, -8.170337195292781],
              [-39.8898896619013, -8.170337141342239],
              [-39.889889753231124, -8.170337088447191],
              [-39.889889758403903, -8.170337138587083],
              [-39.890671409773134, -8.169890521698878],
              [-39.890671367876358, -8.169890445030843],
              [-39.890671460550521, -8.169890392790569],
              [-39.890671553833307, -8.169890341637045],
              [-39.890671647711869, -8.169890291577246],
              [-39.890671742173318, -8.169890242618138],
              [-39.890671837204621, -8.169890194766412],
              [-39.890671932792714, -8.169890148028664],
              [-39.890672028924463, -8.169890102411332],
              [-39.890672125586612, -8.169890057920679],
              [-39.890672222765893, -8.169890014562821],
              [-39.8906723204489, -8.169889972343746],
              [-39.890672418622216, -8.16988993126926],
              [-39.890672517272321, -8.169889891344964],
              [-39.890672616385665, -8.169889852576436],
              [-39.890672715948568, -8.169889814968958],
              [-39.890672815947362, -8.169889778527683],
              [-39.890672916368274, -8.169889743257665],
              [-39.890673017197507, -8.169889709163762],
              [-39.890673118421155, -8.16988967625066],
              [-39.89067322002532, -8.169889644522868],
              [-39.890673321996005, -8.169889613984751],
              [-39.890673424319182, -8.169889584640547],
              [-39.890673526980777, -8.169889556494264],
              [-39.890673629966649, -8.169889529549771],
              [-39.890673733262645, -8.169889503810777],
              [-39.890673836854546, -8.16988947928083],
              [-39.890673940728092, -8.169889455963318],
              [-39.890674044869009, -8.169889433861455],
              [-39.890674149262942, -8.169889412978232],
              [-39.890674253895547, -8.169889393316573],
              [-39.89067435875242, -8.169889374879158],
              [-39.890674463819138, -8.169889357668538],
              [-39.89067456908122, -8.169889341687085],
              [-39.89067467452422, -8.169889326936968],
              [-39.890674780133601, -8.169889313420258],
              [-39.890674885894839, -8.169889301138774],
              [-39.890674991793389, -8.169889290094233],
              [-39.890675097814672, -8.16988928028815],
              [-39.890675203944113, -8.169889271721868],
              [-39.890675310167083, -8.169889264396568],
              [-39.890675416469001, -8.169889258313255],
              [-39.890675522835203, -8.169889253472771],
              [-39.890675629251092, -8.169889249875796],
              [-39.890675735701976, -8.169889247522775],
              [-39.890675842173252, -8.169889246414096],
              [-39.890675948650255, -8.169889246549895],
              [-39.890676055118341, -8.169889247930115],
              [-39.890676161562844, -8.169889250554602],
              [-39.89067626796912, -8.169889254422962],
              [-39.890676374322553, -8.16988925953471],
              [-39.890676480608469, -8.169889265889092],
              [-39.890676586812297, -8.169889273485293],
              [-39.890676692919385, -8.16988928232222],
              [-39.890676798915123, -8.16988929239864],
              [-39.890676904784954, -8.169889303713225],
              [-39.890677010514295, -8.169889316264388],
              [-39.890677116088618, -8.169889330050406],
              [-39.890677221493362, -8.169889345069391],
              [-39.890677326714062, -8.169889361319234],
              [-39.890677431736229, -8.169889378797764],
              [-39.890677536545383, -8.169889397502551],
              [-39.890677641127127, -8.169889417431005],
              [-39.890677745467073, -8.169889438580366],
              [-39.890677849550876, -8.169889460947795],
              [-39.890677953364182, -8.169889484530133],
              [-39.890678056892732, -8.169889509324179],
              [-39.89067816012227, -8.169889535326497],
              [-39.890678263038595, -8.169889562533577],
              [-39.890678365627537, -8.169889590941592],
              [-39.890678467875006, -8.169889620546682],
              [-39.890678569766898, -8.169889651344752],
              [-39.890678671289223, -8.169889683331538],
              [-39.890678772428004, -8.169889716502743],
              [-39.890678873169307, -8.169889750853654],
              [-39.890678973499291, -8.169889786379644],
              [-39.890679073404144, -8.16988982307582],
              [-39.890679172870122, -8.169889860937095],
              [-39.890679271883535, -8.169889899958291],
              [-39.890679370430753, -8.169889940134025],
              [-39.890679468498242, -8.16988998145878],
              [-39.890679566072478, -8.169890023926847],
              [-39.890679663140055, -8.169890067532414],
              [-39.8906797596876, -8.169890112269428],
              [-39.89067985570184, -8.1698901581318],
              [-39.890679951169552, -8.169890205113182],
              [-39.890680046077627, -8.169890253207113],
              [-39.890680140412989, -8.16989030240698],
              [-39.890680234162652, -8.169890352706011],
              [-39.890680327313717, -8.169890404097274],
              [-39.890680419853375, -8.169890456573709],
              [-39.890680511768899, -8.169890510128093],
              [-39.890680603047628, -8.169890564753079],
              [-39.890680693676998, -8.169890620441134],
              [-39.890680783644541, -8.169890677184595],
              [-39.890680872937914, -8.169890734975631],
              [-39.890680961544774, -8.169890793806308],
              [-39.890681049452979, -8.169890853668555],
              [-39.890681136650393, -8.169890914554133],
              [-39.890681223125043, -8.169890976454617],
              [-39.890681308864679, -8.169891039362158],
              [-39.890681395904927, -8.169891042384943],
              [-39.892033504092346, -8.170895344977019],
              [-39.892033425191201, -8.170895313155377],
              [-39.892033506817214, -8.17089537307846],
              [-39.892033589109857, -8.170895432089379],
              [-39.892033672058872, -8.170895490180806],
              [-39.89203375565392, -8.170895547345472],
              [-39.892033839884554, -8.170895603576229],
              [-39.892033924740289, -8.17089565886608],
              [-39.892034010210509, -8.170895713208118],
              [-39.892034096284576, -8.170895766595562],
              [-39.892034182951747, -8.170895819021737],
              [-39.892034270201194, -8.170895870480168],
              [-39.892034358022045, -8.170895920964373],
              [-39.892034446403336, -8.170895970468067],
              [-39.892034535334048, -8.170896018985077],
              [-39.892034624803081, -8.170896066509348],
              [-39.892034714799273, -8.170896113034949],
              [-39.892034805311411, -8.17089615855609],
              [-39.892034896328184, -8.170896203067095],
              [-39.892034987838244, -8.17089624656241],
              [-39.892035079830173, -8.17089628903655],
              [-39.892035172292495, -8.17089633048429],
              [-39.892035265213678, -8.170896370900433],
              [-39.89203535858212, -8.17089641027995],
              [-39.892035452386182, -8.170896448617876],
              [-39.892035546614146, -8.170896485909488],
              [-39.892035641254274, -8.170896522150098],
              [-39.892035736294758, -8.170896557335229],
              [-39.892035831723717, -8.170896591460446],
              [-39.892035927529299, -8.170896624521479],
              [-39.89203602369949, -8.170896656514245],
              [-39.892036120222315, -8.170896687434739],
              [-39.892036217085732, -8.170896717279104],
              [-39.892036314277675, -8.170896746043605],
              [-39.892036411785995, -8.170896773724696],
              [-39.89203650959854, -8.170896800318868],
              [-39.892036607703091, -8.170896825822828],
              [-39.892036706087431, -8.170896850233389],
              [-39.892036804739263, -8.170896873547491],
              [-39.892036903646286, -8.170896895762265],
              [-39.892037002796179, -8.170896916874907],
              [-39.892037102176552, -8.170896936882801],
              [-39.892037201775011, -8.170896955783432],
              [-39.89203730157913, -8.170896973574468],
              [-39.892037401576466, -8.170896990253654],
              [-39.892037501754523, -8.170897005818954],
              [-39.892037602100835, -8.170897020268393],
              [-39.892037702602849, -8.170897033600157],
              [-39.892037803248051, -8.170897045812641],
              [-39.892037904023873, -8.17089705690425],
              [-39.892038004917737, -8.170897066873644],
              [-39.892038105917074, -8.170897075719578],
              [-39.892038207009264, -8.170897083440932],
              [-39.892038308181704, -8.170897090036744],
              [-39.892038409421779, -8.170897095506197],
              [-39.892038510716844, -8.170897099848617],
              [-39.892038612054272, -8.170897103063432],
              [-39.892038713421407, -8.17089710515029],
              [-39.89203881480563, -8.1708971061089],
              [-39.892038916194252, -8.170897105939135],
              [-39.892039017574675, -8.170897104641025],
              [-39.8920391189342, -8.170897102214726],
              [-39.892039220260202, -8.170897098660596],
              [-39.892039321540061, -8.170897093979008],
              [-39.892039422761108, -8.170897088170552],
              [-39.892039523910732, -8.170897081235996],
              [-39.892039624976306, -8.170897073176167],
              [-39.892039725945224, -8.170897063992102],
              [-39.892039826804904, -8.1708970536849],
              [-39.892039927542747, -8.170897042255882],
              [-39.892040028146191, -8.170897029706451],
              [-39.892040128602659, -8.170897016038188],
              [-39.892040228899653, -8.170897001252811],
              [-39.892040329024653, -8.17089698535214],
              [-39.89204042896516, -8.170896968338164],
              [-39.892040528708712, -8.170896950213034],
              [-39.89204062824286, -8.170896930978957],
              [-39.892040727555191, -8.170896910638401],
              [-39.892040826633291, -8.170896889193845],
              [-39.89204092546484, -8.170896866648008],
              [-39.892041024037475, -8.170896843003668],
              [-39.892041122338917, -8.170896818263792],
              [-39.892041220356901, -8.170896792431451],
              [-39.892041318079187, -8.170896765509877],
              [-39.892041415493594, -8.170896737502447],
              [-39.892041512587944, -8.170896708412622],
              [-39.892041609350159, -8.170896678244072],
              [-39.89204170576815, -8.170896647000527],
              [-39.89204180182989, -8.170896614685889],
              [-39.892041897523399, -8.170896581304214],
              [-39.892041992836724, -8.170896546859632],
              [-39.892042087757993, -8.170896511356457],
              [-39.892042182275354, -8.17089647479912],
              [-39.892042276377005, -8.17089643719217],
              [-39.892042370051215, -8.170896398540322],
              [-39.892042463286302, -8.170896358848381],
              [-39.892042556070635, -8.170896318121324],
              [-39.892042648392632, -8.170896276364205],
              [-39.892042740240768, -8.170896233582212],
              [-39.892042831603597, -8.170896189780725],
              [-39.89204292246972, -8.170896144965212],
              [-39.892042946216364, -8.170896138159744],
              [-39.893168818843279, -8.170333078967721],
              [-39.893168814836571, -8.170333026200803],
              [-39.89316882502056, -8.170333021099241],
              [-39.893168835198082, -8.170333015984976],
              [-39.893168845369168, -8.170333010857952],
              [-39.893168855533787, -8.170333005718225],
              [-39.893168865691926, -8.170333000565774],
              [-39.89316887584355, -8.170332995400621],
              [-39.893168885988665, -8.170332990222763],
              [-39.89316889612725, -8.170332985032216],
              [-39.893168906259277, -8.170332979828999],
              [-39.893168916384745, -8.17033297461311],
              [-39.89316892650362, -8.170332969384553],
              [-39.893168936615908, -8.170332964143322],
              [-39.893168946721588, -8.170332958889453],
              [-39.893168956820638, -8.17033295362295],
              [-39.893168966913017, -8.170332948343825],
              [-39.893168976998751, -8.170332943052049],
              [-39.8931689870778, -8.170332937747681],
              [-39.893168997150184, -8.170332932430712],
              [-39.893169007215853, -8.170332927101141],
              [-39.893169017274772, -8.170332921758968],
              [-39.893169027326962, -8.170332916404206],
              [-39.893169037372402, -8.170332911036892],
              [-39.893169047411071, -8.170332905657009],
              [-39.893169057442947, -8.170332900264556],
              [-39.893169067468001, -8.170332894859566],
              [-39.893169077486249, -8.170332889442028],
              [-39.893169087497675, -8.170332884011984],
              [-39.893169097502245, -8.170332878569406],
              [-39.893169107499936, -8.17033287311431],
              [-39.893169117490743, -8.170332867646694],
              [-39.893169127474671, -8.170332862166596],
              [-39.893169137451665, -8.17033285667401],
              [-39.893169147421752, -8.170332851168954],
              [-39.893169157384861, -8.170332845651416],
              [-39.893169167341028, -8.170332840121409],
              [-39.893169177290218, -8.170332834578975],
              [-39.893169187232409, -8.170332829024066],
              [-39.893169197167587, -8.170332823456739],
              [-39.893169207095752, -8.170332817876982],
              [-39.893169217016869, -8.170332812284785],
              [-39.893169226930937, -8.170332806680211],
              [-39.893169236837913, -8.170332801063234],
              [-39.893169246737841, -8.170332795433854],
              [-39.893169256630642, -8.170332789792095],
              [-39.893169266516331, -8.170332784137969],
              [-39.893169276394886, -8.170332778471494],
              [-39.893169286266279, -8.170332772792634],
              [-39.893169296130523, -8.17033276710144],
              [-39.893169305987591, -8.170332761397914],
              [-39.893169315837447, -8.170332755682072],
              [-39.893169325680098, -8.170332749953879],
              [-39.89316933551553, -8.170332744213393],
              [-39.893169345343722, -8.170332738460603],
              [-39.89316935516463, -8.170332732695501],
              [-39.893169364978284, -8.170332726918129],
              [-39.893169374784641, -8.17033272112848],
              [-39.8931693845837, -8.17033271532658],
              [-39.893169394375434, -8.170332709512417],
              [-39.893169404159835, -8.170332703686002],
              [-39.893169413936903, -8.170332697847345],
              [-39.893169423706581, -8.170332691996487],
              [-39.89316943346887, -8.170332686133383],
              [-39.89316944322379, -8.170332680258078],
              [-39.893169452971271, -8.170332674370577],
              [-39.893169462711356, -8.170332668470863],
              [-39.893169472443979, -8.170332662558984],
              [-39.893169482169135, -8.170332656634942],
              [-39.89316949188683, -8.170332650698718],
              [-39.893169501597036, -8.170332644750367],
              [-39.893169511299739, -8.170332638789835],
              [-39.893169520994917, -8.170332632817173],
              [-39.893169530682563, -8.170332626832415],
              [-39.893169540362656, -8.170332620835527],
              [-39.893169550035175, -8.170332614826513],
              [-39.893169559700119, -8.170332608805431],
              [-39.893169569357482, -8.17033260277222],
              [-39.893169579007221, -8.170332596726965],
              [-39.893169588649336, -8.170332590669648],
              [-39.893169598283805, -8.170332584600255],
              [-39.893169607910629, -8.170332578518813],
              [-39.893169617529765, -8.170332572425343],
              [-39.893169627141226, -8.170332566319829],
              [-39.893169636744986, -8.17033256020227],
              [-39.893169646341015, -8.170332554072733],
              [-39.893169655929327, -8.170332547931199],
              [-39.893169665509873, -8.170332541777658],
              [-39.89316967508266, -8.170332535612136],
              [-39.893169684647681, -8.170332529434637],
              [-39.893169694204907, -8.170332523245195],
              [-39.893169703754339, -8.170332517043793],
              [-39.893169713295904, -8.170332510830429],
              [-39.893169722829668, -8.170332504605158],
              [-39.893169732355574, -8.170332498367955],
              [-39.893169741873614, -8.17033249211881],
              [-39.893169751383752, -8.170332485857777],
              [-39.893169760886003, -8.170332479584859],
              [-39.893169770380339, -8.170332473300041],
              [-39.893169779866767, -8.170332467003353],
              [-39.893169789345208, -8.170332460694798],
              [-39.893169816129934, -8.17033253409903],
              [-39.893842350989601, -8.169884252978452],
              [-39.893842295746779, -8.169884252982294],
              [-39.893842393055763, -8.169884187215361],
              [-39.893842489501587, -8.169884120198025],
              [-39.893842585068086, -8.169884051941521],
              [-39.893842679739201, -8.169883982457314],
              [-39.89384277349906, -8.169883911757065],
              [-39.89384286633193, -8.169883839852599],
              [-39.893842958222223, -8.169883766756065],
              [-39.893843049154533, -8.169883692479649],
              [-39.893843139113599, -8.169883617035856],
              [-39.893843228084314, -8.169883540437317],
              [-39.893843316051772, -8.169883462696905],
              [-39.893843403001192, -8.169883383827655],
              [-39.893843488918002, -8.169883303842795],
              [-39.89384357378777, -8.169883222755779],
              [-39.89384365759625, -8.169883140580154],
              [-39.893843740329395, -8.169883057329772],
              [-39.893843821973313, -8.169882973018577],
              [-39.893843902514313, -8.169882887660703],
              [-39.893843981938879, -8.169882801270477],
              [-39.893844060233675, -8.169882713862405],
              [-39.893844137385585, -8.169882625451153],
              [-39.893844213381634, -8.169882536051556],
              [-39.893844288209088, -8.169882445678606],
              [-39.893844361855386, -8.169882354347438],
              [-39.893844434308164, -8.169882262073463],
              [-39.893844505555279, -8.169882168872087],
              [-39.893844575584772, -8.169882074758988],
              [-39.893844644384892, -8.169881979749963],
              [-39.893844711944084, -8.169881883860915],
              [-39.893844778251022, -8.169881787107952],
              [-39.893844843294573, -8.169881689507314],
              [-39.893844907063837, -8.169881591075386],
              [-39.893844969548113, -8.169881491828686],
              [-39.893845030736877, -8.169881391783825],
              [-39.893845090619912, -8.169881290957642],
              [-39.893845149187143, -8.169881189367009],
              [-39.893845206428765, -8.169881087029006],
              [-39.893845262335134, -8.169880983960828],
              [-39.893845316896922, -8.169880880179694],
              [-39.89384537010492, -8.169880775703092],
              [-39.893845421950246, -8.169880670548547],
              [-39.893845472424168, -8.169880564733649],
              [-39.893845521518237, -8.169880458276172],
              [-39.893845569224183, -8.16988035119401],
              [-39.893845615534048, -8.169880243505101],
              [-39.893845660440029, -8.16988013522753],
              [-39.893845703934602, -8.169880026379461],
              [-39.893845746010456, -8.169879916979156],
              [-39.893845786660556, -8.169879807044978],
              [-39.89384582587806, -8.16987969659538],
              [-39.893845863656395, -8.16987958564884],
              [-39.893845899989202, -8.169879474224048],
              [-39.893845934870427, -8.169879362339708],
              [-39.893845968294194, -8.16987925001453],
              [-39.893846000254889, -8.169879137267378],
              [-39.893846030747149, -8.169879024117201],
              [-39.893846059765856, -8.169878910582971],
              [-39.893846087306159, -8.169878796683713],
              [-39.893846113363423, -8.1698786824386],
              [-39.893846137933281, -8.169878567866718],
              [-39.893846161011595, -8.169878452987364],
              [-39.893846182594501, -8.169878337819785],
              [-39.893846202678411, -8.169878222383307],
              [-39.893846221259892, -8.169878106697286],
              [-39.893846238335883, -8.16987799078113],
              [-39.893846253903483, -8.169877874654334],
              [-39.893846267960086, -8.169877758336373],
              [-39.893846280503347, -8.169877641846714],
              [-39.893846291531162, -8.169877525204962],
              [-39.893846301041656, -8.169877408430628],
              [-39.893846309033265, -8.169877291543409],
              [-39.893846315504611, -8.16987717456281],
              [-39.893846320454635, -8.169877057508474],
              [-39.893846323882507, -8.169876940400117],
              [-39.893846325787649, -8.169876823257304],
              [-39.893846326169729, -8.169876706099778],
              [-39.893846325028711, -8.169876588947131],
              [-39.893846322364737, -8.169876471819032],
              [-39.893846318178305, -8.169876354735123],
              [-39.893846312470096, -8.1698762377151],
              [-39.893846305241048, -8.169876120778548],
              [-39.893846296492413, -8.169876003945138],
              [-39.893846286225639, -8.169875887234424],
              [-39.893846274442438, -8.169875770666044],
              [-39.893846261144816, -8.169875654259469],
              [-39.893846246334974, -8.169875538034319],
              [-39.893846230015413, -8.169875422010062],
              [-39.893846212188876, -8.169875306206169],
              [-39.893846192858348, -8.169875190642056],
              [-39.893846172027068, -8.16987507533714],
              [-39.893846149698525, -8.169874960310755],
              [-39.8938461258765, -8.169874845582198],
              [-39.893846100564936, -8.169874731170729],
              [-39.893846073768138, -8.169874617095548],
              [-39.893846045490562, -8.169874503375814],
              [-39.893846015736962, -8.169874390030559],
              [-39.893845984512339, -8.169874277078833],
              [-39.893845951821923, -8.169874164539616],
              [-39.893845917671207, -8.16987405243175],
              [-39.893845959330029, -8.169874115220612],
              [-39.893392036009409, -8.168417965723014],
              [-39.893391979713932, -8.168417888836267],
              [-39.893391972602529, -8.168417866129268],
              [-39.893391965431199, -8.168417843440984],
              [-39.893391958199999, -8.168417820771564],
              [-39.893391950909013, -8.168417798121178],
              [-39.893391943558264, -8.16841777548998],
              [-39.893391936147786, -8.168417752878117],
              [-39.893391928677666, -8.168417730285761],
              [-39.893391921147938, -8.168417707713044],
              [-39.893391913558638, -8.168417685160149],
              [-39.893391905909844, -8.168417662627203],
              [-39.893391898201614, -8.168417640114415],
              [-39.893391890433968, -8.168417617621886],
              [-39.893391882606991, -8.168417595149778],
              [-39.893391874720713, -8.168417572698266],
              [-39.893391866775211, -8.16841755026751],
              [-39.893391858770528, -8.168417527857612],
              [-39.893391850706735, -8.16841750546881],
              [-39.893391842583846, -8.168417483101214],
              [-39.893391834401967, -8.16841746075497],
              [-39.893391826161128, -8.168417438430252],
              [-39.893391817861364, -8.168417416127181],
              [-39.893391809502795, -8.168417393845969],
              [-39.893391801085436, -8.168417371586701],
              [-39.893391792609343, -8.168417349349605],
              [-39.893391784074595, -8.168417327134767],
              [-39.893391775481234, -8.168417304942398],
              [-39.893391766829325, -8.168417282772619],
              [-39.893391758118923, -8.168417260625574],
              [-39.893391749350101, -8.168417238501453],
              [-39.893391740522908, -8.168417216400357],
              [-39.893391731637415, -8.168417194322501],
              [-39.893391722693664, -8.168417172267986],
              [-39.893391713691742, -8.168417150236985],
              [-39.893391704631703, -8.168417128229661],
              [-39.893391695513614, -8.168417106246157],
              [-39.893391686337502, -8.168417084286599],
              [-39.893391677103487, -8.168417062351187],
              [-39.893391667811585, -8.168417040440039],
              [-39.893391658461887, -8.16841701855332],
              [-39.89339164905445, -8.168416996691171],
              [-39.893391639589353, -8.168416974853747],
              [-39.893391630066638, -8.168416953041218],
              [-39.893391620486369, -8.168416931253713],
              [-39.893391610848639, -8.168416909491368],
              [-39.893391601153496, -8.16841688775437],
              [-39.893391591401006, -8.168416866042852],
              [-39.89339158159126, -8.168416844356949],
              [-39.893391571724287, -8.168416822696861],
              [-39.893391561800186, -8.168416801062696],
              [-39.893391551819015, -8.168416779454601],
              [-39.893391541780836, -8.168416757872743],
              [-39.893391531685737, -8.168416736317271],
              [-39.893391521533765, -8.168416714788306],
              [-39.893391511325021, -8.168416693286037],
              [-39.89339150105954, -8.168416671810611],
              [-39.893391490737407, -8.168416650362149],
              [-39.893391480358716, -8.168416628940792],
              [-39.893391469923507, -8.168416607546723],
              [-39.893391459431847, -8.16841658618007],
              [-39.89339144888384, -8.168416564840983],
              [-39.893391438279544, -8.168416543529615],
              [-39.893391427619036, -8.168416522246099],
              [-39.893391416902382, -8.168416500990588],
              [-39.893391406129666, -8.168416479763252],
              [-39.893391395300952, -8.168416458564224],
              [-39.893391384416326, -8.168416437393621],
              [-39.893391373475843, -8.168416416251612],
              [-39.893391362479612, -8.168416395138365],
              [-39.893391351427681, -8.168416374053997],
              [-39.893391340320129, -8.16841635299866],
              [-39.893391329157055, -8.16841633197251],
              [-39.893391317938509, -8.16841631097569],
              [-39.893391306664583, -8.168416290008327],
              [-39.893391295335356, -8.168416269070597],
              [-39.893391283950898, -8.168416248162606],
              [-39.893391272511295, -8.168416227284538],
              [-39.893391261016617, -8.168416206436516],
              [-39.893391249466951, -8.168416185618678],
              [-39.893391237862389, -8.168416164831186],
              [-39.893391226202979, -8.168416144074193],
              [-39.893391214488837, -8.168416123347797],
              [-39.893391202720018, -8.168416102652195],
              [-39.893391190896622, -8.168416081987493],
              [-39.893391179018707, -8.168416061353845],
              [-39.893391167086371, -8.168416040751394],
              [-39.893391155099707, -8.168416020180301],
              [-39.893391143058786, -8.168415999640661],
              [-39.893391130963685, -8.168415979132661],
              [-39.893391118814499, -8.168415958656418],
              [-39.893391106611311, -8.168415938212098],
              [-39.8933910943542, -8.168415917799821],
              [-39.893391082043244, -8.168415897419726],
              [-39.893391069678543, -8.168415877071956],
              [-39.893391057260182, -8.168415856756669],
              [-39.893391044788217, -8.168415836473995],
              [-39.893391032262798, -8.168415816224075],
              [-39.893391019683939, -8.168415796007055],
              [-39.893391007051768, -8.168415775823052],
              [-39.893390994366371, -8.168415755672218],
              [-39.893391032694389, -8.168415797268841],
              [-39.892257022004905, -8.166619627805513],
              [-39.892256979887314, -8.166619609595578],
              [-39.892256941734587, -8.16661954862985],
              [-39.892256904069107, -8.166619487364056],
              [-39.892256866893263, -8.166619425802082],
              [-39.892256830209391, -8.166619363947799],
              [-39.892256794019801, -8.166619301805122],
              [-39.892256758326774, -8.166619239377942],
              [-39.89225672313259, -8.166619176670229],
              [-39.892256688439439, -8.166619113685918],
              [-39.892256654249522, -8.166619050429],
              [-39.892256620564993, -8.166618986903481],
              [-39.892256587387983, -8.166618923113328],
              [-39.892256554720589, -8.166618859062597],
              [-39.892256522564857, -8.166618794755328],
              [-39.892256490922819, -8.166618730195561],
              [-39.892256459796499, -8.166618665387382],
              [-39.892256429187825, -8.166618600334898],
              [-39.892256399098748, -8.166618535042199],
              [-39.892256369531154, -8.166618469513395],
              [-39.892256340486931, -8.166618403752635],
              [-39.892256311967891, -8.166618337764051],
              [-39.892256283975847, -8.166618271551826],
              [-39.89225625651256, -8.166618205120106],
              [-39.892256229579743, -8.166618138473151],
              [-39.892256203179123, -8.166618071615094],
              [-39.892256177312369, -8.166618004550182],
              [-39.892256151981087, -8.166617937282671],
              [-39.892256127186904, -8.166617869816788],
              [-39.89225610293137, -8.166617802156757],
              [-39.892256079216011, -8.16661773430689],
              [-39.892256056042335, -8.16661766627146],
              [-39.892256033411797, -8.166617598054739],
              [-39.89225601132582, -8.166617529661043],
              [-39.892255989785802, -8.166617461094731],
              [-39.892255968793116, -8.166617392360058],
              [-39.892255948349074, -8.166617323461407],
              [-39.892255928454965, -8.166617254403103],
              [-39.892255909112052, -8.166617185189507],
              [-39.892255890321543, -8.166617115825005],
              [-39.892255872084625, -8.16661704631397],
              [-39.892255854402457, -8.166616976660789],
              [-39.89225583727616, -8.166616906869843],
              [-39.892255820706808, -8.16661683694552],
              [-39.892255804695438, -8.166616766892275],
              [-39.89225578924308, -8.166616696714513],
              [-39.892255774350687, -8.166616626416662],
              [-39.892255760019211, -8.166616556003142],
              [-39.892255746249553, -8.16661648547842],
              [-39.892255733042596, -8.166616414846933],
              [-39.892255720399149, -8.166616344113143],
              [-39.892255708320022, -8.166616273281493],
              [-39.892255696805989, -8.166616202356495],
              [-39.892255685857755, -8.166616131342597],
              [-39.892255675476022, -8.166616060244284],
              [-39.892255665661445, -8.166615989066027],
              [-39.892255656414626, -8.166615917812345],
              [-39.892255647736185, -8.16661584648773],
              [-39.892255639626647, -8.166615775096666],
              [-39.892255632086524, -8.166615703643666],
              [-39.892255625116285, -8.166615632133242],
              [-39.892255618716383, -8.166615560569896],
              [-39.892255612887226, -8.166615488958152],
              [-39.89225560762916, -8.166615417302511],
              [-39.892255602942548, -8.166615345607537],
              [-39.892255598827639, -8.166615273877744],
              [-39.892255595284745, -8.166615202117617],
              [-39.892255592314044, -8.166615130331731],
              [-39.892255589915777, -8.166615058524602],
              [-39.892255588090045, -8.166614986700747],
              [-39.892255586836981, -8.166614914864709],
              [-39.892255586156672, -8.166614843021032],
              [-39.892255586049146, -8.166614771174199],
              [-39.892255586514423, -8.166614699328816],
              [-39.892255587552462, -8.166614627489366],
              [-39.89225558916322, -8.166614555660397],
              [-39.892255591346562, -8.166614483846452],
              [-39.89225559410238, -8.166614412052052],
              [-39.892255597430477, -8.166614340281713],
              [-39.89225560133066, -8.166614268540014],
              [-39.892255605802667, -8.166614196831425],
              [-39.892255610846227, -8.166614125160502],
              [-39.892255616461028, -8.166614053531768],
              [-39.892255622646708, -8.166613981949745],
              [-39.892255629402875, -8.166613910418919],
              [-39.892255636729089, -8.166613838943844],
              [-39.892255644624925, -8.16661376752899],
              [-39.892255653089848, -8.166613696178885],
              [-39.892255662123333, -8.166613624898044],
              [-39.89225567172484, -8.166613553690937],
              [-39.892255681893722, -8.166613482562093],
              [-39.892255692629355, -8.16661341151598],
              [-39.892255703931056, -8.166613340557083],
              [-39.892255715798122, -8.166613269689881],
              [-39.892255728229792, -8.166613198918824],
              [-39.892255741225291, -8.166613128248411],
              [-39.892255754783797, -8.166613057683076],
              [-39.892255768904441, -8.166612987227285],
              [-39.892255783586364, -8.166612916885491],
              [-39.892255798828614, -8.166612846662117],
              [-39.892255814630026, -8.166612776562058],
              [-39.892255826917541, -8.166612847096465],
              [-39.892589634059874, -8.165159209547719],
              [-39.892589576489605, -8.165159136275021],
              [-39.892589608363053, -8.165159002163985],
              [-39.892589642283731, -8.165158868552437],
              [-39.892589678243759, -8.165158735471421],
              [-39.892589716234781, -8.165158602951836],
              [-39.892589756247979, -8.165158471024428],
              [-39.892589798274052, -8.165158339719849],
              [-39.892589842303266, -8.165158209068609],
              [-39.892589888325368, -8.165158079101017],
              [-39.892589936329699, -8.165157949847247],
              [-39.892589986305097, -8.165157821337331],
              [-39.892590038239959, -8.165157693601108],
              [-39.892590092122227, -8.165157566668212],
              [-39.892590147939394, -8.165157440568155],
              [-39.892590205678502, -8.165157315330195],
              [-39.89259026532612, -8.16515719098342],
              [-39.892590326868422, -8.165157067556681],
              [-39.892590390291105, -8.165156945078678],
              [-39.892590455579459, -8.165156823577819],
              [-39.892590522718315, -8.165156703082344],
              [-39.89259059169207, -8.165156583620194],
              [-39.892590662484722, -8.165156465219148],
              [-39.892590735079828, -8.165156347906672],
              [-39.89259080946055, -8.165156231710016],
              [-39.892590885609593, -8.16515611665614],
              [-39.892590963509278, -8.165156002771798],
              [-39.892591043141529, -8.165155890083417],
              [-39.892591124487836, -8.165155778617143],
              [-39.892591207529335, -8.165155668398878],
              [-39.892591292246713, -8.165155559454211],
              [-39.892591378620338, -8.165155451808461],
              [-39.892591466630122, -8.165155345486586],
              [-39.892591556255653, -8.165155240513281],
              [-39.892591647476088, -8.165155136912958],
              [-39.892591740270284, -8.165155034709619],
              [-39.892591834616645, -8.165154933927031],
              [-39.892591930493325, -8.165154834588591],
              [-39.892592027878017, -8.165154736717344],
              [-39.892592126748127, -8.165154640336034],
              [-39.892592227080684, -8.165154545467047],
              [-39.892592328852402, -8.165154452132404],
              [-39.892592432039649, -8.165154360353773],
              [-39.892592536618466, -8.165154270152478],
              [-39.892592642564551, -8.165154181549472],
              [-39.892592749853335, -8.165154094565301],
              [-39.892592858459871, -8.16515400922014],
              [-39.892592968358962, -8.165153925533874],
              [-39.892593079525085, -8.165153843525882],
              [-39.892593191932434, -8.165153763215239],
              [-39.892593305554904, -8.165153684620581],
              [-39.892593420366104, -8.165153607760153],
              [-39.892593536339383, -8.165153532651811],
              [-39.892593653447804, -8.16515345931297],
              [-39.892593771664195, -8.16515338776067],
              [-39.892593890961074, -8.165153318011573],
              [-39.892594011310763, -8.165153250081801],
              [-39.892594132685311, -8.16515318398717],
              [-39.892594255056537, -8.165153119743009],
              [-39.892594378396012, -8.165153057364252],
              [-39.892594502675124, -8.165152996865388],
              [-39.892594627865002, -8.165152938260457],
              [-39.892594753936571, -8.165152881563055],
              [-39.892594880860557, -8.165152826786372],
              [-39.892595008607493, -8.165152773943081],
              [-39.892595137147715, -8.165152723045511],
              [-39.892595266451373, -8.165152674105435],
              [-39.892595396488446, -8.165152627134235],
              [-39.892595527228764, -8.165152582142824],
              [-39.892595658641916, -8.16515253914163],
              [-39.892595790697435, -8.165152498140674],
              [-39.892595923364624, -8.165152459149425],
              [-39.892596056612703, -8.165152422176966],
              [-39.892596190410707, -8.165152387231888],
              [-39.892596324727599, -8.165152354322297],
              [-39.892596459532172, -8.165152323455819],
              [-39.892596594793133, -8.165152294639649],
              [-39.892596730479056, -8.165152267880456],
              [-39.892596866558449, -8.165152243184485],
              [-39.892597002999715, -8.165152220557419],
              [-39.892597139771162, -8.165152200004526],
              [-39.892597276841059, -8.165152181530653],
              [-39.892597414177537, -8.165152165140006],
              [-39.892597551748736, -8.165152150836397],
              [-39.892597689522709, -8.165152138623165],
              [-39.892597827467455, -8.165152128503163],
              [-39.892597965550948, -8.165152120478716],
              [-39.892598103741129, -8.165152114551683],
              [-39.892598242005903, -8.165152110723465],
              [-39.89259838031316, -8.165152108994933],
              [-39.892598518630805, -8.165152109366494],
              [-39.892598656926715, -8.165152111838054],
              [-39.892598795168766, -8.165152116409068],
              [-39.892598933324855, -8.165152123078441],
              [-39.892599071362916, -8.165152131844643],
              [-39.892599209250896, -8.165152142705638],
              [-39.892599346956779, -8.165152155658891],
              [-39.892599484448567, -8.165152170701415],
              [-39.892599621694359, -8.165152187829698],
              [-39.892599758662278, -8.165152207039748],
              [-39.892599895320828, -8.165152228326818],
              [-39.892599966484276, -8.165152222535484],
              [-39.893265562777955, -8.165261096900728],
              [-39.893265558926544, -8.165261102108776],
              [-39.89326566241278, -8.16526111964744],
              [-39.893265765688533, -8.16526113837738],
              [-39.893265868739995, -8.165261158296088],
              [-39.893265971553383, -8.165261179400893],
              [-39.893266074114955, -8.165261201688971],
              [-39.893266176410982, -8.165261225157346],
              [-39.893266278427816, -8.16526124980288],
              [-39.893266380151758, -8.165261275622274],
              [-39.893266481569263, -8.165261302612048],
              [-39.893266582666712, -8.165261330768647],
              [-39.893266683430639, -8.165261360088239],
              [-39.89326678384753, -8.165261390566972],
              [-39.893266883903955, -8.165261422200748],
              [-39.893266983586564, -8.165261454985318],
              [-39.893267082881998, -8.165261488916288],
              [-39.893267181776984, -8.165261523989177],
              [-39.893267280258286, -8.165261560199225],
              [-39.893267378312757, -8.165261597541631],
              [-39.893267475927274, -8.165261636011381],
              [-39.893267573088771, -8.165261675603338],
              [-39.893267669784272, -8.165261716312203],
              [-39.893267766000825, -8.165261758132546],
              [-39.893267861725576, -8.165261801058774],
              [-39.893267956945721, -8.165261845085169],
              [-39.893268051648498, -8.165261890205738],
              [-39.893268145821303, -8.165261936414552],
              [-39.893268239451487, -8.16526198370541],
              [-39.89326833252656, -8.165262032072006],
              [-39.893268425034059, -8.165262081507795],
              [-39.893268516961605, -8.165262132006228],
              [-39.893268608296914, -8.165262183560566],
              [-39.893268699027765, -8.16526223616385],
              [-39.893268789142049, -8.165262289809071],
              [-39.893268878627687, -8.165262344489086],
              [-39.893268967472714, -8.16526240019655],
              [-39.893269055665243, -8.165262456924033],
              [-39.8932691431935, -8.165262514663921],
              [-39.893269230045775, -8.165262573408517],
              [-39.893269316210436, -8.165262633149954],
              [-39.893269401675973, -8.165262693880228],
              [-39.893269486430938, -8.16526275559127],
              [-39.893269570464021, -8.165262818274762],
              [-39.893269653763973, -8.165262881922358],
              [-39.893269736319652, -8.165262946525552],
              [-39.893269818120025, -8.16526301207567],
              [-39.893269899154134, -8.165263078563981],
              [-39.893269979411173, -8.165263145981559],
              [-39.893270058880361, -8.165263214319419],
              [-39.893270137551134, -8.165263283568395],
              [-39.893270215412898, -8.165263353719235],
              [-39.893270292455284, -8.165263424762562],
              [-39.893270368667991, -8.165263496688876],
              [-39.893270444040816, -8.16526356948855],
              [-39.893270518563682, -8.165263643151883],
              [-39.893270592226607, -8.165263717668962],
              [-39.893270665019763, -8.165263793029862],
              [-39.893270736933395, -8.165263869224505],
              [-39.893270807957897, -8.165263946242696],
              [-39.893270878083783, -8.165264024074114],
              [-39.893270947301637, -8.165264102708388],
              [-39.893271015602231, -8.165264182134973],
              [-39.893271082976433, -8.165264262343229],
              [-39.893271149415206, -8.165264343322473],
              [-39.893271214909696, -8.165264425061848],
              [-39.893271279451113, -8.165264507550416],
              [-39.893271343030854, -8.165264590777168],
              [-39.893271405640412, -8.165264674730963],
              [-39.893271467271404, -8.165264759400577],
              [-39.893271527915587, -8.165264844774677],
              [-39.89327158756484, -8.165264930841872],
              [-39.893271646211197, -8.165265017590615],
              [-39.893271703846835, -8.165265105009315],
              [-39.893271760464017, -8.165265193086302],
              [-39.893271816055183, -8.165265281809798],
              [-39.893271870612885, -8.165265371167878],
              [-39.893271924129863, -8.165265461148659],
              [-39.893271976598911, -8.165265551740065],
              [-39.893272028013051, -8.165265642930034],
              [-39.893272078365378, -8.165265734706278],
              [-39.89327212764919, -8.165265827056617],
              [-39.893272175857852, -8.165265919968668],
              [-39.893272222984969, -8.165266013429996],
              [-39.893272269024195, -8.165266107428097],
              [-39.893272313969405, -8.165266201950399],
              [-39.893272357814553, -8.165266296984264],
              [-39.893272400553798, -8.165266392517022],
              [-39.893272442181434, -8.165266488535845],
              [-39.89327248269187, -8.165266585027926],
              [-39.893272522079705, -8.16526668198032],
              [-39.893272560339661, -8.165266779380058],
              [-39.893272597466627, -8.165266877214174],
              [-39.893272633455638, -8.165266975469549],
              [-39.893272668301861, -8.165267074133048],
              [-39.893272702000679, -8.165267173191452],
              [-39.893272734547544, -8.16526727263156],
              [-39.893272765938121, -8.165267372440011],
              [-39.893272796168226, -8.165267472603519],
              [-39.893272825233787, -8.165267573108666],
              [-39.893272853131108, -8.165267673941852],
              [-39.893272929899474, -8.165267683098756],
              [-39.893728079042667, -8.166950846465731],
              [-39.893728023190192, -8.16695078956492],
              [-39.893728025299559, -8.16695079735101],
              [-39.893728027415897, -8.166950805135219],
              [-39.893728029539197, -8.166950812917573],
              [-39.893728031669461, -8.166950820698027],
              [-39.893728033806688, -8.16695082847656],
              [-39.893728035950879, -8.166950836253191],
              [-39.893728038102026, -8.166950844027957],
              [-39.893728040260129, -8.1669508518008],
              [-39.893728042425188, -8.16695085957169],
              [-39.893728044597196, -8.166950867340701],
              [-39.893728046776168, -8.166950875107753],
              [-39.893728048962075, -8.16695088287285],
              [-39.893728051154945, -8.166950890636032],
              [-39.893728053354764, -8.166950898397246],
              [-39.893728055561503, -8.166950906156504],
              [-39.893728057775192, -8.166950913913761],
              [-39.893728059995837, -8.166950921669098],
              [-39.893728062223417, -8.166950929422448],
              [-39.893728064457932, -8.166950937173814],
              [-39.893728066699381, -8.166950944923174],
              [-39.893728068947766, -8.166950952670568],
              [-39.893728071203086, -8.166950960415939],
              [-39.893728073465333, -8.166950968159291],
              [-39.893728075734508, -8.166950975900676],
              [-39.893728078010597, -8.166950983639984],
              [-39.893728080293641, -8.166950991377311],
              [-39.893728082583586, -8.166950999112615],
              [-39.893728084880472, -8.166951006845867],
              [-39.89372808718425, -8.166951014577082],
              [-39.893728089494964, -8.166951022306241],
              [-39.893728091812591, -8.166951030033333],
              [-39.893728094137131, -8.166951037758388],
              [-39.893728096468578, -8.166951045481339],
              [-39.893728098806946, -8.166951053202233],
              [-39.893728101152227, -8.16695106092104],
              [-39.893728103504401, -8.166951068637781],
              [-39.893728105863488, -8.166951076352412],
              [-39.893728108229467, -8.166951084064944],
              [-39.893728110602353, -8.166951091775353],
              [-39.893728112982146, -8.166951099483688],
              [-39.89372811536883, -8.16695110718989],
              [-39.893728117762414, -8.16695111489395],
              [-39.893728120162876, -8.16695112259589],
              [-39.893728122570238, -8.166951130295695],
              [-39.893728124984492, -8.166951137993344],
              [-39.893728127405637, -8.166951145688888],
              [-39.893728129833676, -8.166951153382259],
              [-39.893728132268578, -8.166951161073444],
              [-39.893728134710372, -8.166951168762489],
              [-39.893728137159052, -8.166951176449366],
              [-39.893728139614602, -8.166951184134037],
              [-39.89372814207703, -8.166951191816567],
              [-39.89372814454633, -8.166951199496877],
              [-39.893728147022514, -8.16695120717498],
              [-39.893728149505549, -8.166951214850897],
              [-39.893728151995475, -8.166951222524608],
              [-39.893728154492244, -8.16695123019608],
              [-39.893728156995898, -8.166951237865362],
              [-39.893728159506402, -8.166951245532372],
              [-39.89372816202377, -8.166951253197182],
              [-39.893728164547987, -8.166951260859749],
              [-39.893728167079075, -8.166951268520078],
              [-39.893728169617006, -8.166951276178164],
              [-39.893728172161808, -8.166951283833983],
              [-39.893728174713431, -8.166951291487509],
              [-39.893728177271917, -8.166951299138764],
              [-39.893728179837247, -8.166951306787778],
              [-39.893728182409433, -8.16695131443449],
              [-39.893728184988454, -8.166951322078924],
              [-39.893728187574311, -8.166951329721075],
              [-39.89372819016701, -8.166951337360898],
              [-39.893728192766531, -8.166951344998399],
              [-39.893728195372894, -8.166951352633646],
              [-39.8937281979861, -8.16695136026655],
              [-39.893728200606127, -8.166951367897134],
              [-39.893728203232989, -8.166951375525377],
              [-39.893728205866658, -8.166951383151281],
              [-39.89372820850717, -8.166951390774829],
              [-39.893728211154503, -8.166951398396066],
              [-39.89372821380865, -8.166951406014913],
              [-39.893728216469604, -8.166951413631411],
              [-39.89372821913738, -8.166951421245546],
              [-39.893728221811976, -8.16695142885729],
              [-39.893728224493387, -8.166951436466665],
              [-39.893728227181583, -8.16695144407368],
              [-39.893728229876608, -8.166951451678285],
              [-39.893728232578411, -8.166951459280488],
              [-39.89372823528705, -8.166951466880265],
              [-39.893728238002467, -8.166951474477651],
              [-39.893728240724684, -8.166951482072616],
              [-39.893728243453694, -8.166951489665186],
              [-39.893728246189518, -8.166951497255301],
              [-39.893728248932121, -8.166951504843007],
              [-39.893728251681509, -8.166951512428243],
              [-39.89372825443769, -8.16695152001105],
              [-39.89372825720065, -8.166951527591404],
              [-39.893728259970395, -8.166951535169295],
              [-39.893728262746926, -8.16695154274473],
              [-39.893728265530228, -8.166951550317689],
              [-39.893728352965852, -8.166951569417233],
              [-39.894182690208225, -8.168186309576486],
              [-39.89418267976653, -8.168186236107319],
              [-39.894182703961306, -8.168186301075508],
              [-39.89418272865494, -8.168186365857068],
              [-39.894182753845989, -8.168186430448223],
              [-39.894182779532983, -8.168186494845157],
              [-39.894182805714401, -8.168186559044111],
              [-39.894182832388708, -8.168186623041317],
              [-39.894182859554334, -8.168186686832987],
              [-39.894182887209674, -8.1681867504154],
              [-39.894182915353134, -8.168186813784862],
              [-39.894182943983061, -8.168186876937609],
              [-39.894182973097742, -8.168186939869926],
              [-39.894183002695492, -8.168187002578144],
              [-39.894183032774571, -8.168187065058545],
              [-39.894183063333209, -8.168187127307476],
              [-39.894183094369595, -8.168187189321323],
              [-39.894183125881952, -8.168187251096391],
              [-39.89418315786839, -8.16818731262909],
              [-39.894183190327048, -8.16818737391579],
              [-39.894183223256022, -8.168187434952904],
              [-39.894183256653363, -8.168187495736838],
              [-39.894183290517127, -8.16818755626403],
              [-39.894183324845329, -8.168187616530906],
              [-39.894183359635939, -8.168187676533959],
              [-39.894183394886916, -8.168187736269665],
              [-39.894183430596208, -8.168187795734513],
              [-39.894183466761703, -8.168187854924998],
              [-39.894183503381285, -8.168187913837643],
              [-39.894183540452794, -8.168187972469029],
              [-39.894183577974061, -8.168188030815678],
              [-39.894183615942879, -8.168188088874187],
              [-39.894183654357029, -8.168188146641109],
              [-39.894183693214252, -8.168188204113079],
              [-39.894183732512261, -8.168188261286735],
              [-39.894183772248759, -8.168188318158711],
              [-39.894183812421403, -8.168188374725643],
              [-39.89418385302784, -8.168188430984255],
              [-39.894183894065684, -8.168188486931191],
              [-39.894183935532538, -8.168188542563231],
              [-39.894183977425939, -8.168188597877039],
              [-39.894184019743456, -8.168188652869421],
              [-39.894184062482609, -8.16818870753713],
              [-39.894184105640853, -8.168188761876957],
              [-39.894184149215683, -8.16818881588574],
              [-39.894184193204538, -8.168188869560247],
              [-39.894184237604826, -8.168188922897372],
              [-39.894184282413946, -8.168188975893981],
              [-39.894184327629276, -8.168189028546969],
              [-39.894184373248152, -8.168189080853221],
              [-39.894184419267894, -8.168189132809678],
              [-39.894184465685811, -8.168189184413299],
              [-39.894184512499159, -8.168189235661041],
              [-39.894184559705216, -8.168189286549886],
              [-39.894184607301192, -8.168189337076873],
              [-39.894184655284313, -8.168189387239016],
              [-39.894184703651725, -8.168189437033389],
              [-39.894184752400619, -8.168189486457038],
              [-39.89418480152812, -8.168189535507107],
              [-39.894184851031355, -8.168189584180682],
              [-39.894184900907419, -8.16818963247491],
              [-39.89418495115337, -8.168189680386963],
              [-39.894185001766267, -8.168189727914037],
              [-39.894185052743147, -8.168189775053326],
              [-39.894185104080989, -8.168189821802056],
              [-39.89418515577681, -8.168189868157524],
              [-39.894185207827562, -8.168189914116969],
              [-39.894185260230195, -8.168189959677704],
              [-39.894185312981627, -8.168190004837056],
              [-39.894185366078759, -8.168190049592374],
              [-39.894185419518486, -8.168190093941035],
              [-39.894185473297661, -8.168190137880417],
              [-39.894185527413114, -8.168190181407931],
              [-39.894185581861713, -8.168190224521062],
              [-39.894185636640223, -8.16819026721727],
              [-39.89418569174542, -8.168190309494017],
              [-39.894185747174113, -8.168190351348871],
              [-39.894185802922998, -8.168190392779323],
              [-39.894185858988834, -8.168190433782994],
              [-39.894185915368325, -8.168190474357434],
              [-39.89418597205816, -8.168190514500266],
              [-39.894186029055, -8.168190554209161],
              [-39.894186086355511, -8.168190593481766],
              [-39.89418614395634, -8.168190632315786],
              [-39.89418620185409, -8.168190670708915],
              [-39.894186260045352, -8.168190708658935],
              [-39.894186318526728, -8.168190746163599],
              [-39.89418637729478, -8.168190783220711],
              [-39.894186436346054, -8.168190819828085],
              [-39.89418649567709, -8.168190855983589],
              [-39.894186555284406, -8.168190891685089],
              [-39.894186615164493, -8.168190926930494],
              [-39.894186675313847, -8.168190961717734],
              [-39.894186735728937, -8.168190996044762],
              [-39.894186796406188, -8.168191029909547],
              [-39.894186857342085, -8.168191063310159],
              [-39.894186918533023, -8.168191096244575],
              [-39.894186979975416, -8.168191128710907],
              [-39.894187041665653, -8.168191160707211],
              [-39.89418710360011, -8.16819119223163],
              [-39.894187165775158, -8.168191223282312],
              [-39.89418723985893, -8.168191273973211],
              [-39.895315963367118, -8.168749534436053],
              [-39.895315909810108, -8.168749543197862],
              [-39.895315947834, -8.168749562116504],
              [-39.895315985768548, -8.168749581212335],
              [-39.895316023612949, -8.168749600484915],
              [-39.89531606136638, -8.168749619933843],
              [-39.895316099028001, -8.168749639558687],
              [-39.895316136596989, -8.168749659359021],
              [-39.895316174072526, -8.168749679334397],
              [-39.895316211453789, -8.16874969948439],
              [-39.895316248739938, -8.168749719808572],
              [-39.895316285930186, -8.168749740306465],
              [-39.8953163230237, -8.168749760977624],
              [-39.895316360019677, -8.168749781821619],
              [-39.895316396917295, -8.16874980283799],
              [-39.895316433715749, -8.168749824026264],
              [-39.89531647041423, -8.168749845385987],
              [-39.895316507011948, -8.168749866916672],
              [-39.895316543508081, -8.168749888617867],
              [-39.895316579901838, -8.168749910489087],
              [-39.895316616192424, -8.168749932529854],
              [-39.895316652379044, -8.168749954739674],
              [-39.895316688460881, -8.168749977118072],
              [-39.895316724437194, -8.168749999664563],
              [-39.895316760307146, -8.168750022378626],
              [-39.89531679606997, -8.168750045259792],
              [-39.895316831724884, -8.168750068307556],
              [-39.895316867271099, -8.168750091521396],
              [-39.89531690270784, -8.168750114900828],
              [-39.895316938034341, -8.168750138445326],
              [-39.895316973249805, -8.16875016215436],
              [-39.895317008353473, -8.168750186027427],
              [-39.895317043344576, -8.168750210064001],
              [-39.895317078222348, -8.168750234263561],
              [-39.895317112986014, -8.168750258625556],
              [-39.89531714763482, -8.168750283149464],
              [-39.895317182168014, -8.168750307834767],
              [-39.895317216584822, -8.16875033268089],
              [-39.895317250884496, -8.168750357687314],
              [-39.895317285066284, -8.168750382853487],
              [-39.895317319129454, -8.168750408178832],
              [-39.895317353073231, -8.168750433662801],
              [-39.895317386896885, -8.168750459304892],
              [-39.895317420599689, -8.168750485104454],
              [-39.895317454180862, -8.168750511060981],
              [-39.895317487639709, -8.16875053717389],
              [-39.895317520975475, -8.168750563442622],
              [-39.89531755418745, -8.168750589866558],
              [-39.895317587274874, -8.16875061644517],
              [-39.895317620237051, -8.168750643177855],
              [-39.895317653073235, -8.168750670064004],
              [-39.895317685782736, -8.168750697103061],
              [-39.895317718364801, -8.168750724294423],
              [-39.895317750818748, -8.168750751637482],
              [-39.895317783143845, -8.16875077913164],
              [-39.895317815339382, -8.168750806776304],
              [-39.895317847404684, -8.168750834570869],
              [-39.895317879338997, -8.168750862514718],
              [-39.895317911141667, -8.168750890607241],
              [-39.895317942811964, -8.168750918847834],
              [-39.895317974349226, -8.168750947235861],
              [-39.895318005752721, -8.168750975770712],
              [-39.895318037021809, -8.168751004451753],
              [-39.89531806815576, -8.168751033278342],
              [-39.895318099153918, -8.168751062249878],
              [-39.895318130015582, -8.168751091365721],
              [-39.895318160740111, -8.168751120625213],
              [-39.895318191326794, -8.168751150027715],
              [-39.895318221774964, -8.168751179572608],
              [-39.895318252083996, -8.168751209259204],
              [-39.895318282253179, -8.168751239086896],
              [-39.895318312281873, -8.168751269054976],
              [-39.895318342169404, -8.168751299162839],
              [-39.895318371915131, -8.168751329409783],
              [-39.895318401518402, -8.168751359795182],
              [-39.895318430978563, -8.168751390318368],
              [-39.895318460294988, -8.168751420978664],
              [-39.895318489466987, -8.168751451775369],
              [-39.895318518493958, -8.168751482707853],
              [-39.895318547375261, -8.168751513775419],
              [-39.895318576110242, -8.168751544977368],
              [-39.895318604698296, -8.168751576313019],
              [-39.895318633138793, -8.168751607781717],
              [-39.895318661431084, -8.168751639382748],
              [-39.895318689574594, -8.168751671115412],
              [-39.895318717568657, -8.168751702979032],
              [-39.895318745412702, -8.168751734972908],
              [-39.895318773106091, -8.168751767096312],
              [-39.895318800648219, -8.168751799348572],
              [-39.895318828038498, -8.168751831728983],
              [-39.895318855276308, -8.168751864236803],
              [-39.895318882361082, -8.168751896871351],
              [-39.895318909292179, -8.168751929631886],
              [-39.895318936069046, -8.168751962517705],
              [-39.895318962691078, -8.168751995528091],
              [-39.895318989157715, -8.168752028662306],
              [-39.895319015468345, -8.168752061919662],
              [-39.895319041622407, -8.168752095299359],
              [-39.895319067619347, -8.168752128800703],
              [-39.895319093458568, -8.168752162422972],
              [-39.895319119138989, -8.16875219616732],
              [-39.895319146423262, -8.168752240728768],
              [-39.895990659062207, -8.169638828853188],
              [-39.895990613356723, -8.169638780915413],
              [-39.895990727164808, -8.169638928011754],
              [-39.895990843980393, -8.169639072748549],
              [-39.895990963754194, -8.169639215064755],
              [-39.8959910864357, -8.169639354900356],
              [-39.895991211973197, -8.169639492196366],
              [-39.895991340313742, -8.169639626894924],
              [-39.8959914714032, -8.169639758939187],
              [-39.895991605186296, -8.169639888273503],
              [-39.895991741606608, -8.169640014843305],
              [-39.895991880606623, -8.169640138595218],
              [-39.8959920221277, -8.16964025947709],
              [-39.895992166110176, -8.16964037743791],
              [-39.895992312493327, -8.169640492427943],
              [-39.895992461215414, -8.169640604398682],
              [-39.895992612213739, -8.16964071330294],
              [-39.89599276542463, -8.169640819094772],
              [-39.895992920783435, -8.169640921729581],
              [-39.895993078224684, -8.169641021164054],
              [-39.895993237681957, -8.169641117356283],
              [-39.895993399088006, -8.16964121026569],
              [-39.895993562374791, -8.169641299853112],
              [-39.895993727473432, -8.169641386080762],
              [-39.895993894314323, -8.169641468912264],
              [-39.895994062827093, -8.169641548312729],
              [-39.895994232940673, -8.169641624248612],
              [-39.895994404583341, -8.169641696687929],
              [-39.895994577682714, -8.169641765600131],
              [-39.895994752165777, -8.169641830956154],
              [-39.895994927958967, -8.169641892728425],
              [-39.895995104988131, -8.169641950890899],
              [-39.895995283178642, -8.169642005419044],
              [-39.895995462455353, -8.169642056289863],
              [-39.895995642742633, -8.169642103481939],
              [-39.895995823964469, -8.169642146975349],
              [-39.895996006044456, -8.169642186751741],
              [-39.895996188905791, -8.169642222794359],
              [-39.895996372471366, -8.169642255087977],
              [-39.895996556663775, -8.169642283618998],
              [-39.895996741405334, -8.169642308375384],
              [-39.895996926618132, -8.169642329346711],
              [-39.895997112224066, -8.169642346524103],
              [-39.895997298144891, -8.169642359900338],
              [-39.895997484302178, -8.169642369469747],
              [-39.895997670617419, -8.169642375228355],
              [-39.895997857012063, -8.169642377173682],
              [-39.895998043407495, -8.169642375304932],
              [-39.895998229725095, -8.169642369622874],
              [-39.895998415886332, -8.169642360129901],
              [-39.895998601812664, -8.169642346830038],
              [-39.895998787425697, -8.169642329728891],
              [-39.895998972647149, -8.16964230883366],
              [-39.895999157398954, -8.169642284153177],
              [-39.895999341603144, -8.169642255697813],
              [-39.895999525182063, -8.169642223479606],
              [-39.895999708058298, -8.169642187512112],
              [-39.895999890154712, -8.169642147810526],
              [-39.896000071394546, -8.169642104391565],
              [-39.896000251701338, -8.169642057273569],
              [-39.896000430999074, -8.169642006476368],
              [-39.896000609212123, -8.169641952021408],
              [-39.896000786265354, -8.169641893931669],
              [-39.896000962084081, -8.169641832231619],
              [-39.896001136594172, -8.169641766947286],
              [-39.896001309722038, -8.169641698106211],
              [-39.896001481394677, -8.169641625737405],
              [-39.896001651539656, -8.169641549871411],
              [-39.89600182008526, -8.169641470540192],
              [-39.896001986960407, -8.169641387777224],
              [-39.896002152094717, -8.169641301617391],
              [-39.896002315418549, -8.169641212097062],
              [-39.896002476863039, -8.169641119253935],
              [-39.896002636360109, -8.169641023127223],
              [-39.896002793842463, -8.169640923757433],
              [-39.896002949243744, -8.169640821186476],
              [-39.896003102498376, -8.16964071545757],
              [-39.89600325354175, -8.169640606615355],
              [-39.896003402310157, -8.169640494705705],
              [-39.896003548740872, -8.169640379775801],
              [-39.896003692772155, -8.169640261874159],
              [-39.89600383434324, -8.169640141050438],
              [-39.896003973394436, -8.16964001735562],
              [-39.896004109867093, -8.169639890841855],
              [-39.896004243703707, -8.169639761562525],
              [-39.896004374847784, -8.169639629572128],
              [-39.896004503244043, -8.169639494926319],
              [-39.896004628838341, -8.169639357681888],
              [-39.896004751577713, -8.169639217896684],
              [-39.896004871410398, -8.169639075629687],
              [-39.896004988285846, -8.169638930940893],
              [-39.896005102154788, -8.169638783891315],
              [-39.896005212969186, -8.169638634542936],
              [-39.896005320682356, -8.169638482958781],
              [-39.896005425248831, -8.169638329202749],
              [-39.896005526624506, -8.169638173339676],
              [-39.896005624766651, -8.169638015435295],
              [-39.896005719633891, -8.169637855556203],
              [-39.896005811186214, -8.169637693769831],
              [-39.896005899384988, -8.169637530144369],
              [-39.896005984193046, -8.169637364748855],
              [-39.896005996485535, -8.169637438224552],
              [-39.896674906650851, -8.168299277791181],
              [-39.896674832556016, -8.168299212361248],
              [-39.896674856365109, -8.168299165073789],
              [-39.896674880451407, -8.168299117925869],
              [-39.896674904814063, -8.168299070919154],
              [-39.896674929452267, -8.16829902405523],
              [-39.896674954365182, -8.168298977335692],
              [-39.896674979551918, -8.168298930762148],
              [-39.896675005011645, -8.168298884336211],
              [-39.896675030743481, -8.168298838059455],
              [-39.896675056746552, -8.168298791933452],
              [-39.896675083019971, -8.168298745959827],
              [-39.896675109562828, -8.16829870014009],
              [-39.896675136374206, -8.168298654475857],
              [-39.896675163453217, -8.168298608968646],
              [-39.896675190798909, -8.168298563620015],
              [-39.896675218410358, -8.168298518431559],
              [-39.896675246286613, -8.16829847340483],
              [-39.896675274426727, -8.168298428541316],
              [-39.896675302829735, -8.168298383842574],
              [-39.896675331494663, -8.16829833931016],
              [-39.896675360420538, -8.168298294945567],
              [-39.896675389606344, -8.168298250750333],
              [-39.896675419051114, -8.168298206725918],
              [-39.89667544875384, -8.168298162873896],
              [-39.89667547871349, -8.168298119195731],
              [-39.896675508929036, -8.168298075692944],
              [-39.896675539399453, -8.168298032366991],
              [-39.896675570123705, -8.168297989219385],
              [-39.896675601100718, -8.168297946251577],
              [-39.896675632329448, -8.168297903465048],
              [-39.896675663808843, -8.168297860861244],
              [-39.896675695537787, -8.168297818441673],
              [-39.896675727515237, -8.168297776207723],
              [-39.896675759740063, -8.168297734160877],
              [-39.89667579221117, -8.168297692302557],
              [-39.89667582492747, -8.168297650634241],
              [-39.896675857887821, -8.168297609157285],
              [-39.8966758910911, -8.168297567873136],
              [-39.896675924536176, -8.168297526783233],
              [-39.896675958221898, -8.168297485888942],
              [-39.896675992147117, -8.16829744519168],
              [-39.896676026310672, -8.168297404692844],
              [-39.896676060711393, -8.168297364393821],
              [-39.896676095348113, -8.168297324295981],
              [-39.896676130219632, -8.168297284400722],
              [-39.896676165324749, -8.168297244709375],
              [-39.896676200662299, -8.168297205223297],
              [-39.896676236231038, -8.168297165943834],
              [-39.896676272029758, -8.168297126872359],
              [-39.896676308057231, -8.168297088010226],
              [-39.896676344312247, -8.168297049358713],
              [-39.896676380793544, -8.168297010919169],
              [-39.896676417499876, -8.168296972692906],
              [-39.896676454429993, -8.16829693468121],
              [-39.896676491582632, -8.168296896885446],
              [-39.896676528956512, -8.168296859306869],
              [-39.896676566550362, -8.168296821946743],
              [-39.896676604362895, -8.168296784806349],
              [-39.896676642392819, -8.168296747886956],
              [-39.896676680638826, -8.168296711189894],
              [-39.896676719099609, -8.168296674716313],
              [-39.896676757773861, -8.168296638467535],
              [-39.896676796660259, -8.168296602444817],
              [-39.896676835757461, -8.168296566649333],
              [-39.896676875064124, -8.168296531082312],
              [-39.896676914578919, -8.168296495745002],
              [-39.896676954300496, -8.16829646063859],
              [-39.896676994227477, -8.168296425764321],
              [-39.896677034358504, -8.168296391123327],
              [-39.896677074692221, -8.168296356716811],
              [-39.896677115227227, -8.168296322545986],
              [-39.896677155962131, -8.16829628861197],
              [-39.896677196895581, -8.168296254915974],
              [-39.896677238026115, -8.168296221459102],
              [-39.896677279352367, -8.168296188242543],
              [-39.896677320872911, -8.168296155267425],
              [-39.896677362586338, -8.168296122534839],
              [-39.896677404491193, -8.168296090045953],
              [-39.896677446586082, -8.168296057801827],
              [-39.896677488869528, -8.168296025803615],
              [-39.896677531340096, -8.16829599405238],
              [-39.896677573996328, -8.168295962549205],
              [-39.89667761683679, -8.168295931295214],
              [-39.896677659859982, -8.168295900291417],
              [-39.896677703064455, -8.1682958695389],
              [-39.896677746448724, -8.168295839038736],
              [-39.896677790011303, -8.168295808791937],
              [-39.896677833750708, -8.168295778799548],
              [-39.896677877665432, -8.16829574906262],
              [-39.896677921753991, -8.168295719582147],
              [-39.896677966014835, -8.168295690359129],
              [-39.896678010446507, -8.168295661394579],
              [-39.896678055047445, -8.168295632689464],
              [-39.896678099816128, -8.168295604244825],
              [-39.896678144751043, -8.168295576061565],
              [-39.896678189850626, -8.168295548140694],
              [-39.896678235113356, -8.168295520483126],
              [-39.896678280537692, -8.168295493089895],
              [-39.896678326122057, -8.168295465961826],
              [-39.896678371864652, -8.168295439100895],
              [-39.896678438264622, -8.168295471298947],
              [-39.898032171636984, -8.167505783986641],
              [-39.898032118603041, -8.167505770973177],
              [-39.898032144192761, -8.167505755990096],
              [-39.898032169733177, -8.167505740923698],
              [-39.898032195224019, -8.167505725774189],
              [-39.898032220664994, -8.167505710541684],
              [-39.898032246055855, -8.167505695226374],
              [-39.898032271396318, -8.167505679828423],
              [-39.898032296686125, -8.167505664348022],
              [-39.898032321924973, -8.167505648785271],
              [-39.89803234711264, -8.167505633140404],
              [-39.8980323722488, -8.167505617413561],
              [-39.898032397333239, -8.167505601604889],
              [-39.898032422365667, -8.167505585714592],
              [-39.898032447345805, -8.167505569742792],
              [-39.8980324722734, -8.167505553689722],
              [-39.898032497148172, -8.167505537555519],
              [-39.898032521969874, -8.167505521340317],
              [-39.898032546738222, -8.167505505044351],
              [-39.898032571452958, -8.167505488667778],
              [-39.898032596113836, -8.1675054722108],
              [-39.898032620720556, -8.167505455673549],
              [-39.898032645272885, -8.167505439056228],
              [-39.898032669770537, -8.167505422358976],
              [-39.898032694213278, -8.167505405582027],
              [-39.898032718600824, -8.167505388725496],
              [-39.898032742932934, -8.167505371789607],
              [-39.898032767209322, -8.167505354774546],
              [-39.898032791429756, -8.167505337680497],
              [-39.898032815593943, -8.167505320507599],
              [-39.898032839701663, -8.167505303256101],
              [-39.89803286375264, -8.167505285926106],
              [-39.898032887746602, -8.167505268517868],
              [-39.898032911683323, -8.167505251031537],
              [-39.898032935562526, -8.167505233467335],
              [-39.898032959383968, -8.167505215825415],
              [-39.898032983147367, -8.167505198105994],
              [-39.898033006852501, -8.16750518030921],
              [-39.898033030499107, -8.167505162435312],
              [-39.89803305408693, -8.167505144484455],
              [-39.898033077615729, -8.167505126456842],
              [-39.898033101085225, -8.167505108352691],
              [-39.898033124495178, -8.167505090172174],
              [-39.898033147845361, -8.167505071915473],
              [-39.898033171135509, -8.167505053582781],
              [-39.898033194365361, -8.167505035174324],
              [-39.898033217534682, -8.167505016690294],
              [-39.898033240643201, -8.167504998130822],
              [-39.898033263690721, -8.167504979496201],
              [-39.898033286676934, -8.167504960786568],
              [-39.898033309601651, -8.167504942002182],
              [-39.8980333324646, -8.16750492314319],
              [-39.898033355265525, -8.167504904209787],
              [-39.898033378004207, -8.167504885202229],
              [-39.898033400680376, -8.167504866120668],
              [-39.898033423293811, -8.167504846965345],
              [-39.898033445844263, -8.167504827736456],
              [-39.898033468331498, -8.167504808434149],
              [-39.89803349075526, -8.167504789058725],
              [-39.898033513115323, -8.167504769610357],
              [-39.898033535411443, -8.167504750089208],
              [-39.898033557643387, -8.167504730495539],
              [-39.898033579810892, -8.167504710829522],
              [-39.898033601913745, -8.167504691091429],
              [-39.898033623951733, -8.167504671281392],
              [-39.898033645924578, -8.167504651399682],
              [-39.898033667832053, -8.167504631446507],
              [-39.898033689673952, -8.167504611422011],
              [-39.89803371144999, -8.167504591326502],
              [-39.898033733159977, -8.167504571160148],
              [-39.898033754803656, -8.167504550923136],
              [-39.898033776380842, -8.167504530615732],
              [-39.898033797891252, -8.167504510238146],
              [-39.898033819334657, -8.167504489790559],
              [-39.898033840710866, -8.167504469273259],
              [-39.898033862019624, -8.167504448686431],
              [-39.898033883260702, -8.167504428030243],
              [-39.898033904433895, -8.167504407304985],
              [-39.898033925538961, -8.167504386510874],
              [-39.898033946575659, -8.167504365648096],
              [-39.898033967543789, -8.167504344716869],
              [-39.898033988443125, -8.16750432371745],
              [-39.898034009273424, -8.167504302650061],
              [-39.898034030034481, -8.167504281514931],
              [-39.898034050726054, -8.167504260312249],
              [-39.898034071347951, -8.167504239042254],
              [-39.898034091899937, -8.16750421770524],
              [-39.898034112381779, -8.167504196301358],
              [-39.898034132793299, -8.167504174830826],
              [-39.898034153134219, -8.16750415329393],
              [-39.898034173404362, -8.167504131690867],
              [-39.898034193603507, -8.167504110021923],
              [-39.898034213731428, -8.167504088287247],
              [-39.898034233787918, -8.167504066487114],
              [-39.898034253772778, -8.167504044621777],
              [-39.898034273685759, -8.1675040226914],
              [-39.898034293526663, -8.167504000696296],
              [-39.898034313295277, -8.16750397863664],
              [-39.898034332991408, -8.167503956512704],
              [-39.898034352614815, -8.167503934324735],
              [-39.898034372165313, -8.16750391207294],
              [-39.898034436878319, -8.167503970071866],
              [-39.898819966558406, -8.166606917101124],
              [-39.898819914568236, -8.166606873110219],
              [-39.898819990012264, -8.166606785842234],
              [-39.898820064334821, -8.166606697624019],
              [-39.898820137523849, -8.166606608469904],
              [-39.898820209567496, -8.166606518394332],
              [-39.898820280454061, -8.166606427411905],
              [-39.898820350172052, -8.16660633553739],
              [-39.898820418710145, -8.166606242785699],
              [-39.898820486057261, -8.166606149171839],
              [-39.898820552202444, -8.166606054711043],
              [-39.89882061713498, -8.166605959418602],
              [-39.898820680844345, -8.166605863309966],
              [-39.898820743320194, -8.166605766400727],
              [-39.898820804552415, -8.16660566870657],
              [-39.898820864531039, -8.166605570243396],
              [-39.898820923246376, -8.166605471027138],
              [-39.898820980688896, -8.166605371073906],
              [-39.898821036849263, -8.166605270399883],
              [-39.898821091718396, -8.166605169021423],
              [-39.898821145287371, -8.166605066954943],
              [-39.898821197547512, -8.166604964217036],
              [-39.89882124849035, -8.16660486082433],
              [-39.89882129810762, -8.166604756793614],
              [-39.89882134639128, -8.166604652141743],
              [-39.898821393333471, -8.166604546885702],
              [-39.898821438926618, -8.166604441042557],
              [-39.898821483163289, -8.166604334629474],
              [-39.89882152603635, -8.166604227663703],
              [-39.89882156753881, -8.166604120162596],
              [-39.898821607663983, -8.166604012143603],
              [-39.898821646405302, -8.166603903624203],
              [-39.898821683756552, -8.166603794622025],
              [-39.898821719711613, -8.166603685154746],
              [-39.898821754264681, -8.166603575240138],
              [-39.898821787410149, -8.166603464896005],
              [-39.898821819142661, -8.166603354140213],
              [-39.89882184945705, -8.166603242990753],
              [-39.8988218783484, -8.166603131465655],
              [-39.898821905812021, -8.166603019583029],
              [-39.898821931843479, -8.166602907360973],
              [-39.898821956438539, -8.166602794817715],
              [-39.898821979593201, -8.166602681971485],
              [-39.898822001303735, -8.166602568840622],
              [-39.898822021566616, -8.166602455443446],
              [-39.898822040378519, -8.166602341798361],
              [-39.898822057736439, -8.16660222792377],
              [-39.898822073637561, -8.166602113838193],
              [-39.898822088079264, -8.166601999560083],
              [-39.898822101059231, -8.166601885107976],
              [-39.898822112575388, -8.166601770500463],
              [-39.898822122625809, -8.166601655756104],
              [-39.89882213120891, -8.166601540893542],
              [-39.898822138323268, -8.166601425931379],
              [-39.898822143967742, -8.166601310888261],
              [-39.898822148141427, -8.166601195782841],
              [-39.898822150843621, -8.166601080633827],
              [-39.898822152073919, -8.166600965459844],
              [-39.898822151832086, -8.166600850279577],
              [-39.898822150118185, -8.166600735111727],
              [-39.898822146932496, -8.166600619974963],
              [-39.89882214227552, -8.16660050488796],
              [-39.89882213614802, -8.166600389869378],
              [-39.898822128550982, -8.166600274937878],
              [-39.898822119485658, -8.166600160112097],
              [-39.898822108953489, -8.166600045410664],
              [-39.898822096956224, -8.166599930852163],
              [-39.898822083495766, -8.166599816455182],
              [-39.898822068574312, -8.166599702238303],
              [-39.898822052194298, -8.166599588219995],
              [-39.898822034358361, -8.166599474418769],
              [-39.898822015069413, -8.1665993608531],
              [-39.898821994330561, -8.166599247541379],
              [-39.898821972145178, -8.166599134502023],
              [-39.898821948516861, -8.166599021753312],
              [-39.898821923449461, -8.166598909313576],
              [-39.898821896946998, -8.166598797201019],
              [-39.898821869013823, -8.166598685433838],
              [-39.898821839654417, -8.166598574030177],
              [-39.898821808873578, -8.166598463008077],
              [-39.898821776676279, -8.166598352385554],
              [-39.898821743067757, -8.166598242180539],
              [-39.89882170805344, -8.166598132410911],
              [-39.898821671639034, -8.166598023094496],
              [-39.898821633830437, -8.166597914248989],
              [-39.898821594633759, -8.16659780589206],
              [-39.898821554055381, -8.166597698041256],
              [-39.898821512101861, -8.166597590714115],
              [-39.898821468780042, -8.166597483928006],
              [-39.898821424096901, -8.166597377700265],
              [-39.898821378059736, -8.166597272048108],
              [-39.898821330675972, -8.166597166988657],
              [-39.898821281953296, -8.166597062539021],
              [-39.898821231899646, -8.166596958716047],
              [-39.898821180523086, -8.166596855536623],
              [-39.898821127832008, -8.16659675301748],
              [-39.89882107383491, -8.166596651175254],
              [-39.89882101854058, -8.166596550026412],
              [-39.898820961957966, -8.166596449587395],
              [-39.898820904096247, -8.16659634987449],
              [-39.898820844964803, -8.166596250903883],
              [-39.898820850400298, -8.166596242696924],
              [-39.898144923973135, -8.165481115951234],
              [-39.898144885911989, -8.165481111461204],
              [-39.898144836384063, -8.165481028760212],
              [-39.898144787742716, -8.165480945538425],
              [-39.898144739993484, -8.165480861805323],
              [-39.898144693141766, -8.165480777570343],
              [-39.898144647192865, -8.165480692843056],
              [-39.898144602152016, -8.165480607633079],
              [-39.898144558024299, -8.165480521950077],
              [-39.898144514814739, -8.165480435803762],
              [-39.898144472528209, -8.165480349203881],
              [-39.898144431169548, -8.165480262160258],
              [-39.898144390743411, -8.165480174682791],
              [-39.898144351254381, -8.165480086781374],
              [-39.898144312706947, -8.165479998465969],
              [-39.898144275105466, -8.165479909746592],
              [-39.898144238454215, -8.165479820633308],
              [-39.898144202757344, -8.165479731136232],
              [-39.898144168018909, -8.165479641265483],
              [-39.898144134242827, -8.16547955103127],
              [-39.898144101432955, -8.165479460443832],
              [-39.898144069592995, -8.165479369513424],
              [-39.898144038726564, -8.165479278250357],
              [-39.898144008837157, -8.165479186664989],
              [-39.898143979928172, -8.165479094767703],
              [-39.898143952002876, -8.165479002568912],
              [-39.898143925064439, -8.165478910079074],
              [-39.898143899115922, -8.165478817308673],
              [-39.898143874160247, -8.165478724268244],
              [-39.898143850200277, -8.165478630968286],
              [-39.898143827238705, -8.165478537419418],
              [-39.898143805278139, -8.165478443632241],
              [-39.898143784321064, -8.165478349617397],
              [-39.898143764369841, -8.165478255385542],
              [-39.898143745426779, -8.165478160947359],
              [-39.898143727493981, -8.165478066313517],
              [-39.898143710573521, -8.165477971494795],
              [-39.898143694667276, -8.165477876501942],
              [-39.898143679777064, -8.165477781345713],
              [-39.898143665904591, -8.165477686036903],
              [-39.89814365305142, -8.165477590586326],
              [-39.898143641218994, -8.165477495004778],
              [-39.898143630408669, -8.16547739930312],
              [-39.898143620621667, -8.165477303492167],
              [-39.898143611859098, -8.16547720758283],
              [-39.898143604121948, -8.165477111585956],
              [-39.898143597411099, -8.165477015512435],
              [-39.898143591727319, -8.165476919373168],
              [-39.898143587071253, -8.165476823179047],
              [-39.898143583443414, -8.165476726940962],
              [-39.898143580844227, -8.16547663066987],
              [-39.898143579273984, -8.165476534376666],
              [-39.898143578732871, -8.16547643807225],
              [-39.898143579220921, -8.165476341767537],
              [-39.898143580738115, -8.16547624547348],
              [-39.898143583284252, -8.16547614920098],
              [-39.898143586859049, -8.165476052960933],
              [-39.898143591462116, -8.165475956764293],
              [-39.898143597092918, -8.165475860621926],
              [-39.898143603750839, -8.165475764544752],
              [-39.898143611435074, -8.165475668543669],
              [-39.898143620144793, -8.165475572629534],
              [-39.898143629879009, -8.165475476813258],
              [-39.898143640636611, -8.165475381105699],
              [-39.898143652416358, -8.165475285517671],
              [-39.898143665216949, -8.165475190060061],
              [-39.898143679036906, -8.16547509474367],
              [-39.898143693874665, -8.165474999579338],
              [-39.898143709728565, -8.165474904577795],
              [-39.898143726596786, -8.16547480974983],
              [-39.898143744477423, -8.165474715106212],
              [-39.898143763368459, -8.165474620657671],
              [-39.898143783267734, -8.165474526414918],
              [-39.89814380417301, -8.165474432388617],
              [-39.898143826081899, -8.165474338589469],
              [-39.898143848991907, -8.165474245028051],
              [-39.898143872900491, -8.165474151715021],
              [-39.898143897804871, -8.165474058660939],
              [-39.898143923702293, -8.165473965876355],
              [-39.898143950589755, -8.1654738733718],
              [-39.89814397846424, -8.16547378115775],
              [-39.898144007322593, -8.165473689244672],
              [-39.898144037161522, -8.165473597642961],
              [-39.898144067977654, -8.16547350636303],
              [-39.898144099767514, -8.165473415415219],
              [-39.898144132527456, -8.165473324809854],
              [-39.898144166253807, -8.165473234557201],
              [-39.898144200942724, -8.165473144667473],
              [-39.898144236590269, -8.165473055150876],
              [-39.898144273192415, -8.16547296601756],
              [-39.898144310745003, -8.165472877277622],
              [-39.898144349243758, -8.165472788941152],
              [-39.898144388684351, -8.165472701018141],
              [-39.898144429062299, -8.165472613518563],
              [-39.898144470372984, -8.165472526452344],
              [-39.898144512611786, -8.165472439829342],
              [-39.898144555773868, -8.165472353659396],
              [-39.898144599854348, -8.165472267952243],
              [-39.898144644848259, -8.165472182717647],
              [-39.898144690750449, -8.165472097965241],
              [-39.89814473755554, -8.165472013705019],
              [-39.898144812991937, -8.16547207175322],
              [-39.899156611067482, -8.163673147375835],
              [-39.899156544729422, -8.163673184308379],
              [-39.899156563403004, -8.163673151272144],
              [-39.899156582215412, -8.163673118314225],
              [-39.899156601166325, -8.16367308543518],
              [-39.899156620255397, -8.163673052635597],
              [-39.8991566394823, -8.163673019916009],
              [-39.899156658846685, -8.163672987277018],
              [-39.899156678348248, -8.163672954719221],
              [-39.899156697986591, -8.163672922243151],
              [-39.899156717761407, -8.163672889849391],
              [-39.899156737672342, -8.163672857538533],
              [-39.899156757719041, -8.163672825311135],
              [-39.899156777901148, -8.163672793167736],
              [-39.899156798218321, -8.16367276110895],
              [-39.899156818670193, -8.163672729135282],
              [-39.899156839256399, -8.163672697247343],
              [-39.899156859976593, -8.163672665445679],
              [-39.899156880830397, -8.163672633730828],
              [-39.899156901817463, -8.163672602103368],
              [-39.899156922937408, -8.163672570563856],
              [-39.899156944189855, -8.163672539112817],
              [-39.899156965574441, -8.163672507750837],
              [-39.899156987090798, -8.163672476478471],
              [-39.89915700873852, -8.163672445296246],
              [-39.899157030517259, -8.163672414204704],
              [-39.899157052426602, -8.16367238320443],
              [-39.899157074466196, -8.163672352295954],
              [-39.899157096635612, -8.16367232147979],
              [-39.899157118934518, -8.163672290756503],
              [-39.899157141362465, -8.163672260126631],
              [-39.899157163919099, -8.163672229590707],
              [-39.899157186603986, -8.163672199149266],
              [-39.899157209416771, -8.163672168802874],
              [-39.899157232357013, -8.16367213855202],
              [-39.899157255424356, -8.163672108397263],
              [-39.899157278618326, -8.163672078339125],
              [-39.899157301938587, -8.163672048378116],
              [-39.899157325384678, -8.16367201851477],
              [-39.899157348956216, -8.163671988749666],
              [-39.89915737265278, -8.163671959083256],
              [-39.899157396473946, -8.163671929516104],
              [-39.8991574204193, -8.163671900048705],
              [-39.899157444488409, -8.163671870681572],
              [-39.899157468680883, -8.163671841415264],
              [-39.89915749299626, -8.163671812250247],
              [-39.899157517434134, -8.163671783187052],
              [-39.899157541994072, -8.163671754226195],
              [-39.89915756667564, -8.163671725368168],
              [-39.899157591478399, -8.163671696613513],
              [-39.899157616401922, -8.163671667962701],
              [-39.899157641445782, -8.16367163941624],
              [-39.899157666609504, -8.163671610974667],
              [-39.899157691892668, -8.163671582638441],
              [-39.899157717294834, -8.163671554408067],
              [-39.899157742815547, -8.163671526284066],
              [-39.89915776845438, -8.163671498266895],
              [-39.899157794210829, -8.163671470357082],
              [-39.899157820084504, -8.16367144255508],
              [-39.8991578460749, -8.163671414861394],
              [-39.899157872181611, -8.163671387276544],
              [-39.899157898404127, -8.16367135980096],
              [-39.89915792474202, -8.163671332435175],
              [-39.899157951194809, -8.163671305179651],
              [-39.899157977762044, -8.163671278034839],
              [-39.899158004443251, -8.163671251001247],
              [-39.89915803123796, -8.163671224079334],
              [-39.899158058145694, -8.163671197269563],
              [-39.899158085166, -8.163671170572453],
              [-39.899158112298373, -8.163671143988438],
              [-39.899158139542365, -8.163671117517973],
              [-39.899158166897486, -8.163671091161563],
              [-39.89915819436326, -8.163671064919633],
              [-39.899158221939203, -8.163671038792673],
              [-39.899158249624818, -8.163671012781133],
              [-39.89915827741963, -8.163670986885464],
              [-39.899158305323141, -8.163670961106126],
              [-39.899158333334874, -8.163670935443548],
              [-39.899158361454333, -8.163670909898203],
              [-39.899158389681006, -8.163670884470545],
              [-39.89915841801443, -8.163670859161011],
              [-39.899158446454074, -8.163670833970045],
              [-39.899158474999474, -8.163670808898102],
              [-39.899158503650085, -8.163670783945618],
              [-39.899158532405444, -8.163670759113019],
              [-39.899158561265011, -8.163670734400775],
              [-39.899158590228311, -8.163670709809272],
              [-39.899158619294809, -8.163670685338975],
              [-39.89915864846401, -8.163670660990309],
              [-39.8991586777354, -8.163670636763699],
              [-39.899158707108448, -8.163670612659569],
              [-39.899158736582663, -8.163670588678343],
              [-39.899158766157505, -8.163670564820434],
              [-39.899158795832477, -8.16367054108629],
              [-39.899158825607032, -8.1636705174763],
              [-39.899158855480664, -8.163670493990891],
              [-39.899158885452849, -8.163670470630453],
              [-39.899158915523053, -8.163670447395436],
              [-39.899158945690736, -8.163670424286231],
              [-39.899158975955388, -8.163670401303211],
              [-39.899159006316346, -8.163670378447485],
              [-39.899159055599114, -8.163670338170522],
              [-39.900496316608212, -8.16266795596324],
              [-39.900496313074463, -8.162668005607712],
              [-39.900496488938984, -8.16266787705599],
              [-39.900496667852636, -8.162667752751782],
              [-39.900496849712098, -8.162667632766826],
              [-39.900497034412389, -8.162667517170403],
              [-39.900497221846841, -8.16266740602928],
              [-39.90049741190726, -8.162667299407572],
              [-39.900497604483874, -8.162667197366906],
              [-39.900497799465505, -8.162667099966164],
              [-39.900497996739574, -8.162667007261595],
              [-39.900498196192167, -8.162666919306721],
              [-39.90049839770812, -8.162666836152344],
              [-39.900498601171094, -8.162666757846456],
              [-39.900498806463588, -8.16266668443429],
              [-39.900499013467076, -8.162666615958203],
              [-39.900499222062045, -8.162666552457788],
              [-39.900499432128029, -8.162666493969656],
              [-39.900499643543768, -8.162666440527612],
              [-39.900499856187182, -8.162666392162482],
              [-39.900500069935447, -8.162666348902221],
              [-39.900500284665206, -8.162666310771796],
              [-39.900500500252463, -8.162666277793212],
              [-39.900500716572708, -8.16266624998554],
              [-39.900500933501057, -8.162666227364804],
              [-39.900501150912262, -8.162666209944085],
              [-39.900501368680786, -8.162666197733415],
              [-39.900501586680889, -8.162666190739881],
              [-39.900501804786693, -8.162666188967489],
              [-39.900502022872274, -8.162666192417271],
              [-39.900502240811704, -8.162666201087275],
              [-39.900502458479153, -8.16266621497245],
              [-39.900502675748911, -8.162666234064792],
              [-39.900502892495567, -8.162666258353253],
              [-39.900503108593959, -8.162666287823864],
              [-39.900503323919317, -8.162666322459545],
              [-39.900503538347287, -8.162666362240348],
              [-39.90050375175408, -8.162666407143297],
              [-39.900503964016472, -8.162666457142429],
              [-39.90050417501191, -8.162666512208919],
              [-39.900504384618564, -8.162666572310952],
              [-39.900504592715393, -8.16266663741381],
              [-39.900504799182244, -8.162666707479938],
              [-39.900505003899923, -8.16266678246882],
              [-39.900505206750225, -8.162666862337236],
              [-39.900505407615995, -8.162666947039018],
              [-39.900505606381294, -8.162667036525276],
              [-39.900505802931342, -8.162667130744351],
              [-39.900505997152614, -8.162667229641812],
              [-39.900506188933015, -8.162667333160575],
              [-39.900506378161801, -8.162667441240851],
              [-39.900506564729696, -8.162667553820262],
              [-39.900506748528997, -8.162667670833782],
              [-39.900506929453563, -8.162667792213846],
              [-39.900507107398937, -8.162667917890394],
              [-39.900507282262367, -8.162668047790849],
              [-39.9005074539429, -8.162668181840214],
              [-39.900507622341379, -8.162668319961085],
              [-39.900507787360603, -8.162668462073684],
              [-39.900507948905286, -8.162668608096014],
              [-39.900508106882143, -8.162668757943687],
              [-39.900508261199967, -8.162668911530256],
              [-39.900508411769636, -8.162669068766984],
              [-39.900508558504221, -8.162669229563102],
              [-39.900508701319019, -8.162669393825782],
              [-39.900508840131543, -8.162669561460197],
              [-39.900508974861658, -8.162669732369471],
              [-39.900509105431553, -8.162669906455005],
              [-39.900509231765852, -8.162670083616259],
              [-39.900509353791612, -8.162670263750917],
              [-39.900509471438376, -8.162670446755008],
              [-39.900509584638215, -8.162670632522838],
              [-39.900509693325752, -8.162670820947152],
              [-39.900509797438247, -8.162671011919148],
              [-39.90050989691558, -8.162671205328579],
              [-39.900509991700311, -8.162671401063761],
              [-39.900510081737714, -8.162671599011658],
              [-39.900510166975792, -8.162671799058002],
              [-39.900510247365361, -8.162672001087298],
              [-39.900510322859965, -8.162672204982869],
              [-39.900510393416042, -8.162672410626969],
              [-39.900510458992834, -8.162672617900908],
              [-39.900510519552498, -8.16267282668494],
              [-39.900510575060046, -8.162673036858569],
              [-39.900510625483427, -8.162673248300424],
              [-39.900510670793558, -8.162673460888447],
              [-39.900510710964241, -8.162673674499869],
              [-39.900510745972291, -8.162673889011304],
              [-39.900510775797514, -8.162674104298965],
              [-39.900510800422659, -8.162674320238507],
              [-39.900510819833528, -8.162674536705282],
              [-39.900510834018917, -8.162674753574263],
              [-39.900510842970618, -8.162674970720216],
              [-39.900510846683467, -8.162675188017845],
              [-39.900510845155331, -8.162675405341599],
              [-39.900510838387085, -8.16267562256604],
              [-39.900510826382629, -8.162675839565734],
              [-39.900510809148919, -8.162676056215377],
              [-39.900510786695875, -8.162676272389884],
              [-39.900510759036472, -8.16267648796442],
              [-39.900510726186603, -8.162676702814656],
              [-39.900510768719116, -8.162676696934364],
              [-39.900290995648135, -8.164006627799672],
              [-39.900290906903116, -8.164007532440953],
              [-39.900181925458007, -8.165806351993579],
              [-39.900181853649023, -8.165806393150451],
              [-39.900181849572682, -8.165806465209014],
              [-39.900181846073558, -8.16580653729771],
              [-39.900181843151884, -8.165806609411913],
              [-39.900181840807832, -8.165806681547052],
              [-39.900181839041565, -8.165806753698563],
              [-39.900181837853175, -8.165806825861774],
              [-39.900181837242769, -8.165806898032171],
              [-39.900181837210347, -8.165806970205114],
              [-39.900181837755959, -8.165807042376025],
              [-39.900181838879512, -8.165807114540295],
              [-39.900181840580977, -8.165807186693339],
              [-39.900181842860249, -8.165807258830529],
              [-39.90018184571715, -8.165807330947331],
              [-39.900181849151529, -8.165807403039093],
              [-39.90018185316314, -8.165807475101269],
              [-39.900181857751754, -8.165807547129246],
              [-39.900181862917051, -8.165807619118436],
              [-39.900181868658741, -8.165807691064282],
              [-39.900181874976418, -8.165807762962164],
              [-39.900181881869713, -8.165807834807556],
              [-39.90018188933815, -8.165807906595834],
              [-39.90018189738128, -8.165807978322443],
              [-39.900181905998593, -8.165808049982829],
              [-39.900181915189521, -8.165808121572402],
              [-39.900181924953515, -8.165808193086638],
              [-39.900181935289908, -8.165808264520948],
              [-39.90018194619806, -8.165808335870802],
              [-39.900181957677283, -8.16580840713166],
              [-39.900181969726837, -8.165808478298981],
              [-39.900181982345963, -8.165808549368222],
              [-39.900181995533863, -8.165808620334868],
              [-39.900182009289686, -8.165808691194401],
              [-39.900182023612551, -8.165808761942293],
              [-39.900182038501555, -8.165808832574053],
              [-39.900182053955753, -8.165808903085182],
              [-39.90018206997415, -8.165808973471217],
              [-39.900182086555724, -8.165809043727629],
              [-39.900182103699436, -8.165809113849939],
              [-39.900182121404185, -8.165809183833735],
              [-39.900182139668843, -8.165809253674546],
              [-39.900182158492257, -8.165809323367895],
              [-39.900182177873198, -8.165809392909349],
              [-39.900182197810473, -8.165809462294492],
              [-39.900182218302788, -8.165809531518899],
              [-39.900182239348837, -8.165809600578161],
              [-39.900182260947297, -8.165809669467894],
              [-39.900182283096768, -8.165809738183709],
              [-39.900182305795866, -8.165809806721233],
              [-39.900182329043119, -8.165809875076079],
              [-39.900182352837056, -8.165809943243929],
              [-39.900182377176179, -8.165810011220399],
              [-39.900182402058924, -8.165810079001186],
              [-39.900182427483706, -8.165810146581981],
              [-39.900182453448906, -8.165810213958469],
              [-39.900182479952861, -8.165810281126367],
              [-39.900182506993914, -8.165810348081404],
              [-39.900182534570312, -8.165810414819282],
              [-39.900182562680321, -8.165810481335807],
              [-39.900182591322128, -8.165810547626682],
              [-39.900182620493922, -8.165810613687713],
              [-39.900182650193848, -8.16581067951471],
              [-39.900182680420016, -8.165810745103455],
              [-39.900182711170487, -8.165810810449779],
              [-39.900182742443327, -8.16581087554952],
              [-39.900182774236526, -8.16581094039854],
              [-39.900182806548067, -8.165811004992715],
              [-39.900182839375873, -8.165811069327926],
              [-39.900182872717885, -8.165811133400039],
              [-39.900182906571963, -8.16581119720505],
              [-39.900182940935949, -8.165811260738838],
              [-39.90018297580766, -8.165811323997373],
              [-39.900183011184858, -8.165811386976648],
              [-39.90018304706534, -8.165811449672621],
              [-39.90018308344677, -8.16581151208131],
              [-39.900183120326851, -8.165811574198749],
              [-39.900183157703232, -8.165811636020953],
              [-39.900183195573533, -8.165811697544031],
              [-39.900183233935344, -8.16581175876404],
              [-39.900183272786222, -8.165811819677083],
              [-39.900183312123687, -8.165811880279284],
              [-39.900183351945252, -8.16581194056678],
              [-39.900183392248366, -8.165812000535729],
              [-39.900183433030456, -8.165812060182317],
              [-39.900183474288951, -8.165812119502743],
              [-39.900183516021187, -8.165812178493237],
              [-39.900183558224526, -8.165812237150034],
              [-39.900183600896305, -8.165812295469429],
              [-39.900183644033767, -8.165812353447658],
              [-39.900183687634183, -8.165812411081072],
              [-39.900183731694767, -8.165812468365985],
              [-39.900183776212742, -8.165812525298715],
              [-39.900183821185237, -8.1658125818757],
              [-39.900183866609403, -8.165812638093268],
              [-39.900183912482348, -8.165812693947908],
              [-39.900183958801165, -8.165812749436027],
              [-39.900184005562878, -8.165812804554097],
              [-39.900184052764516, -8.165812859298597],
              [-39.900184100403088, -8.165812913666075],
              [-39.900184148475553, -8.165812967653022],
              [-39.900184209518599, -8.165812949355256],
              [-39.901089464249672, -8.166821470318201],
              [-39.901089385168056, -8.166821440659245],
              [-39.90108939886634, -8.166821455884978],
              [-39.901089412599141, -8.166821471079823],
              [-39.901089426366383, -8.166821486243732],
              [-39.901089440167993, -8.166821501376564],
              [-39.901089454003902, -8.16682151647827],
              [-39.901089467874051, -8.166821531548754],
              [-39.901089481778357, -8.166821546587961],
              [-39.901089495716732, -8.166821561595823],
              [-39.90108950968915, -8.166821576572229],
              [-39.901089523695504, -8.166821591517134],
              [-39.901089537735729, -8.166821606430448],
              [-39.901089551809761, -8.166821621312083],
              [-39.901089565917516, -8.166821636161988],
              [-39.901089580058944, -8.166821650980079],
              [-39.901089594233945, -8.166821665766255],
              [-39.901089608442469, -8.16682168052048],
              [-39.901089622684431, -8.166821695242659],
              [-39.90108963695976, -8.166821709932705],
              [-39.901089651268386, -8.166821724590568],
              [-39.901089665610229, -8.166821739216161],
              [-39.901089679985233, -8.166821753809424],
              [-39.901089694393292, -8.166821768370212],
              [-39.901089708834391, -8.166821782898531],
              [-39.901089723308381, -8.166821797394315],
              [-39.901089737815234, -8.166821811857446],
              [-39.901089752354864, -8.166821826287839],
              [-39.901089766927207, -8.166821840685456],
              [-39.901089781532178, -8.166821855050202],
              [-39.9010897961697, -8.166821869381993],
              [-39.901089810839707, -8.166821883680809],
              [-39.90108982554213, -8.166821897946518],
              [-39.901089840276875, -8.166821912179083],
              [-39.901089855043885, -8.166821926378409],
              [-39.901089869843069, -8.166821940544422],
              [-39.901089884674363, -8.166821954677092],
              [-39.901089899537681, -8.166821968776286],
              [-39.901089914432951, -8.166821982841967],
              [-39.901089929360097, -8.166821996874088],
              [-39.901089944319047, -8.166822010872529],
              [-39.901089959309722, -8.166822024837238],
              [-39.901089974332045, -8.166822038768132],
              [-39.901089989385937, -8.166822052665166],
              [-39.901090004471321, -8.166822066528232],
              [-39.901090019588132, -8.166822080357301],
              [-39.901090034736271, -8.166822094152252],
              [-39.90109004991568, -8.166822107913084],
              [-39.901090065126269, -8.166822121639685],
              [-39.901090080367965, -8.166822135331984],
              [-39.901090095640697, -8.166822148989898],
              [-39.901090110944367, -8.166822162613357],
              [-39.901090126278923, -8.166822176202336],
              [-39.901090141644268, -8.166822189756708],
              [-39.901090157040329, -8.166822203276459],
              [-39.901090172467015, -8.166822216761469],
              [-39.901090187924268, -8.166822230211693],
              [-39.901090203412018, -8.166822243627077],
              [-39.901090218930143, -8.166822257007521],
              [-39.901090234478595, -8.166822270352974],
              [-39.901090250057294, -8.16682228366337],
              [-39.901090265666149, -8.166822296938642],
              [-39.901090281305081, -8.166822310178723],
              [-39.901090296974026, -8.16682232338353],
              [-39.901090312672906, -8.16682233655302],
              [-39.901090328401608, -8.166822349687092],
              [-39.90109034416006, -8.166822362785712],
              [-39.901090359948213, -8.166822375848806],
              [-39.901090375765953, -8.166822388876291],
              [-39.901090391613231, -8.166822401868112],
              [-39.901090407489932, -8.166822414824221],
              [-39.901090423395992, -8.166822427744515],
              [-39.901090439331334, -8.166822440628946],
              [-39.901090455295851, -8.166822453477444],
              [-39.9010904712895, -8.166822466289949],
              [-39.901090487312167, -8.166822479066397],
              [-39.901090503363797, -8.166822491806718],
              [-39.901090519444288, -8.166822504510838],
              [-39.901090535553571, -8.166822517178687],
              [-39.901090551691539, -8.166822529810258],
              [-39.901090567858141, -8.166822542405425],
              [-39.901090584053264, -8.166822554964153],
              [-39.901090600276859, -8.166822567486367],
              [-39.901090616528812, -8.166822579971974],
              [-39.90109063280908, -8.166822592420948],
              [-39.901090649117521, -8.16682260483322],
              [-39.901090665454099, -8.166822617208716],
              [-39.901090681818715, -8.16682262954739],
              [-39.901090698211284, -8.166822641849157],
              [-39.901090714631721, -8.166822654113973],
              [-39.90109073107994, -8.166822666341799],
              [-39.901090747555884, -8.166822678532501],
              [-39.901090764059425, -8.166822690686059],
              [-39.901090780590508, -8.166822702802412],
              [-39.901090797149045, -8.166822714881487],
              [-39.901090813734925, -8.166822726923241],
              [-39.901090830348103, -8.166822738927603],
              [-39.901090846988481, -8.1668227508945],
              [-39.901090863655966, -8.166822762823889],
              [-39.90109088035048, -8.166822774715701],
              [-39.901090897071924, -8.166822786569869],
              [-39.901090919629659, -8.166822823743811],
              [-39.902671312237267, -8.167940450349791],
              [-39.902671307353408, -8.167940486102905],
              [-39.90267141968306, -8.167940566835419],
              [-39.902671530763364, -8.167940649266304],
              [-39.902671640568471, -8.167940733376325],
              [-39.902671749072795, -8.167940819145917],
              [-39.902671856251082, -8.167940906555069],
              [-39.902671962078387, -8.167940995583489],
              [-39.902672066530066, -8.167941086210442],
              [-39.902672169581798, -8.167941178414775],
              [-39.902672271209596, -8.167941272175069],
              [-39.902672371389784, -8.167941367469469],
              [-39.902672470099063, -8.167941464275838],
              [-39.90267256731444, -8.167941562571572],
              [-39.902672663013277, -8.167941662333838],
              [-39.902672757173299, -8.167941763539394],
              [-39.902672849772586, -8.16794186616468],
              [-39.902672940789564, -8.167941970185753],
              [-39.902673030203061, -8.167942075578461],
              [-39.902673117992251, -8.167942182318262],
              [-39.902673204136697, -8.167942290380262],
              [-39.902673288616349, -8.167942399739365],
              [-39.902673371411517, -8.167942510370041],
              [-39.902673452502953, -8.167942622246573],
              [-39.902673531871763, -8.167942735342878],
              [-39.902673609499473, -8.167942849632684],
              [-39.902673685368001, -8.167942965089326],
              [-39.902673759459688, -8.167943081685957],
              [-39.902673831757298, -8.167943199395427],
              [-39.902673902243976, -8.167943318190332],
              [-39.902673970903322, -8.167943438043011],
              [-39.902674037719365, -8.167943558925547],
              [-39.902674102676521, -8.167943680809797],
              [-39.902674165759692, -8.167943803667406],
              [-39.902674226954176, -8.167943927469766],
              [-39.902674286245713, -8.167944052188037],
              [-39.902674343620539, -8.167944177793206],
              [-39.902674399065255, -8.167944304256002],
              [-39.902674452566977, -8.167944431547014],
              [-39.902674504113243, -8.167944559636581],
              [-39.902674553692044, -8.16794468849486],
              [-39.902674601291864, -8.167944818091913],
              [-39.902674646901595, -8.167944948397533],
              [-39.902674690510615, -8.167945079381376],
              [-39.902674732108778, -8.167945211012956],
              [-39.902674771686421, -8.167945343261614],
              [-39.902674809234291, -8.167945476096559],
              [-39.902674844743672, -8.167945609486894],
              [-39.902674878206284, -8.167945743401564],
              [-39.902674909614355, -8.167945877809348],
              [-39.902674938960551, -8.167946012678977],
              [-39.90267496623806, -8.167946147979068],
              [-39.902674991440506, -8.167946283678111],
              [-39.902675014562043, -8.167946419744501],
              [-39.902675035597284, -8.167946556146564],
              [-39.902675054541326, -8.167946692852535],
              [-39.902675071389758, -8.167946829830615],
              [-39.902675086138665, -8.167946967048875],
              [-39.902675098784606, -8.167947104475381],
              [-39.902675109324619, -8.167947242078172],
              [-39.902675117756296, -8.167947379825151],
              [-39.902675124077646, -8.167947517684301],
              [-39.902675128287193, -8.1679476556235],
              [-39.902675130383955, -8.167947793610638],
              [-39.90267513036747, -8.167947931613607],
              [-39.902675128237711, -8.167948069600234],
              [-39.902675123995181, -8.167948207538423],
              [-39.902675117640889, -8.167948345396077],
              [-39.9026751091763, -8.167948483141052],
              [-39.902675098603382, -8.167948620741319],
              [-39.902675085924592, -8.167948758164824],
              [-39.902675071142895, -8.167948895379611],
              [-39.902675054261721, -8.167949032353668],
              [-39.902675035285, -8.167949169055152],
              [-39.902675014217174, -8.167949305452215],
              [-39.902674991063108, -8.167949441513109],
              [-39.902674965828218, -8.167949577206139],
              [-39.902674938518381, -8.167949712499748],
              [-39.90267490913994, -8.16794984736242],
              [-39.902674877699759, -8.167949981762757],
              [-39.902674844205123, -8.167950115669477],
              [-39.902674808663861, -8.167950249051357],
              [-39.902674771084229, -8.167950381877404],
              [-39.902674731474988, -8.167950514116665],
              [-39.902674689845362, -8.167950645738378],
              [-39.902674646205035, -8.167950776711873],
              [-39.902674600564168, -8.167950907006619],
              [-39.902674552933377, -8.167951036592372],
              [-39.90267450332378, -8.16795116543892],
              [-39.902674451746883, -8.167951293516225],
              [-39.902674398214735, -8.167951420794536],
              [-39.902674342739793, -8.167951547244156],
              [-39.90267428533496, -8.167951672835708],
              [-39.902674226013602, -8.167951797539931],
              [-39.902674164789524, -8.16795192132777],
              [-39.902674101676993, -8.167952044170406],
              [-39.902674036690705, -8.167952166039248],
              [-39.902673969845779, -8.167952286905924],
              [-39.902673901157776, -8.167952406742289],
              [-39.902673830642705, -8.167952525520452],
              [-39.902673758317114, -8.167952643212622],
              [-39.902673790493282, -8.167952654806026],
              [-39.902115009403268, -8.168846586274681],
              [-39.902114954894934, -8.168846569611876],
              [-39.902114935368544, -8.168846600707788],
              [-39.902114915715742, -8.168846631724511],
              [-39.902114895936855, -8.168846662661569],
              [-39.902114876032208, -8.168846693518464],
              [-39.902114856002122, -8.168846724294655],
              [-39.902114835846923, -8.168846754989641],
              [-39.902114815566975, -8.168846785602943],
              [-39.902114795162568, -8.168846816134028],
              [-39.902114774634065, -8.168846846582404],
              [-39.902114753981792, -8.168846876947615],
              [-39.902114733206091, -8.168846907229085],
              [-39.902114712307309, -8.16884693742638],
              [-39.902114691285789, -8.168846967538942],
              [-39.90211467014187, -8.168846997566332],
              [-39.902114648875909, -8.168847027508024],
              [-39.902114627488238, -8.168847057363532],
              [-39.902114605979236, -8.168847087132367],
              [-39.902114584349235, -8.168847116814035],
              [-39.902114562598598, -8.168847146408071],
              [-39.90211454072768, -8.168847175913946],
              [-39.902114518736852, -8.168847205331209],
              [-39.90211449662646, -8.168847234659363],
              [-39.902114474396882, -8.168847263897923],
              [-39.902114452048458, -8.168847293046419],
              [-39.902114429581594, -8.168847322104378],
              [-39.902114406996631, -8.168847351071294],
              [-39.902114384293938, -8.168847379946722],
              [-39.90211436147392, -8.168847408730162],
              [-39.902114338536919, -8.168847437421155],
              [-39.902114315483324, -8.16884746601923],
              [-39.902114292313527, -8.168847494523884],
              [-39.902114269027884, -8.168847522934689],
              [-39.902114245626805, -8.168847551251176],
              [-39.902114222110647, -8.168847579472837],
              [-39.902114198479815, -8.16884760759925],
              [-39.902114174734699, -8.168847635629959],
              [-39.902114150875676, -8.168847663564465],
              [-39.902114126903157, -8.168847691402332],
              [-39.902114102817521, -8.168847719143086],
              [-39.902114078619164, -8.168847746786293],
              [-39.902114054308491, -8.168847774331486],
              [-39.902114029885901, -8.168847801778192],
              [-39.902114005351798, -8.168847829126003],
              [-39.902113980706581, -8.16884785637445],
              [-39.902113955950661, -8.168847883523073],
              [-39.902113931084436, -8.16884791057142],
              [-39.902113906108319, -8.168847937519084],
              [-39.902113881022721, -8.168847964365595],
              [-39.902113855828055, -8.168847991110514],
              [-39.90211383052474, -8.168848017753412],
              [-39.902113805113181, -8.168848044293822],
              [-39.902113779593819, -8.168848070731322],
              [-39.902113753967036, -8.168848097065492],
              [-39.902113728233289, -8.168848123295868],
              [-39.902113702392981, -8.168848149422056],
              [-39.902113676446554, -8.168848175443621],
              [-39.902113650394405, -8.168848201360102],
              [-39.902113624236989, -8.16884822717109],
              [-39.90211359797474, -8.168848252876161],
              [-39.902113571608069, -8.168848278474927],
              [-39.902113545137418, -8.168848303966914],
              [-39.902113518563226, -8.16884832935172],
              [-39.902113491885935, -8.168848354628938],
              [-39.90211346510597, -8.168848379798128],
              [-39.902113438223772, -8.168848404858924],
              [-39.902113411239803, -8.168848429810867],
              [-39.902113384154489, -8.168848454653556],
              [-39.902113356968286, -8.168848479386607],
              [-39.902113329681619, -8.168848504009569],
              [-39.902113302294978, -8.168848528522091],
              [-39.902113274808762, -8.168848552923727],
              [-39.902113247223454, -8.168848577214098],
              [-39.902113219539515, -8.168848601392776],
              [-39.902113191757373, -8.168848625459425],
              [-39.902113163877516, -8.168848649413572],
              [-39.902113135900379, -8.168848673254878],
              [-39.90211310782643, -8.168848696982941],
              [-39.902113079656132, -8.168848720597333],
              [-39.902113051389946, -8.168848744097724],
              [-39.902113023028342, -8.168848767483686],
              [-39.902112994571787, -8.168848790754859],
              [-39.902112966020731, -8.16884881391082],
              [-39.902112937375669, -8.168848836951218],
              [-39.902112908637058, -8.168848859875686],
              [-39.902112879805372, -8.168848882683848],
              [-39.902112850881089, -8.168848905375299],
              [-39.902112821864669, -8.168848927949711],
              [-39.902112792756625, -8.168848950406648],
              [-39.902112763557405, -8.168848972745781],
              [-39.902112734267483, -8.168848994966737],
              [-39.902112704887372, -8.168849017069144],
              [-39.902112675417541, -8.168849039052644],
              [-39.902112645858466, -8.168849060916886],
              [-39.90211261621063, -8.168849082661493],
              [-39.90211258647453, -8.168849104286119],
              [-39.902112556650664, -8.1688491257904],
              [-39.902112526739522, -8.168849147173987],
              [-39.902112496741573, -8.168849168436523],
              [-39.902112466657186, -8.168849189578376],
              [-39.902112473689023, -8.168849214812928],
              [-39.900983407541005, -8.169639229171159],
              [-39.90098295449355, -8.169639591957363],
              [-39.899854498055888, -8.170541753280212],
              [-39.899854418822912, -8.170541741865517],
              [-39.89985438045931, -8.170541772706185],
              [-39.899854342264348, -8.170541803754025],
              [-39.899854304239184, -8.170541835008068],
              [-39.899854266384899, -8.170541866467369],
              [-39.89985422870263, -8.170541898131061],
              [-39.899854191193498, -8.170541929998155],
              [-39.899854153858598, -8.170541962067746],
              [-39.899854116699018, -8.170541994338855],
              [-39.89985407971588, -8.170542026810564],
              [-39.899854042910263, -8.170542059481916],
              [-39.899854006283235, -8.170542092351894],
              [-39.89985396983591, -8.170542125419589],
              [-39.899853933569347, -8.170542158683999],
              [-39.89985389748459, -8.170542192144135],
              [-39.899853861582748, -8.170542225799048],
              [-39.899853825864845, -8.170542259647723],
              [-39.89985379033196, -8.170542293689167],
              [-39.899853754985116, -8.170542327922364],
              [-39.89985371982538, -8.170542362346321],
              [-39.89985368485376, -8.170542396959972],
              [-39.899853650071329, -8.170542431762387],
              [-39.899853615479081, -8.170542466752453],
              [-39.899853581078027, -8.170542501929193],
              [-39.899853546869203, -8.170542537291539],
              [-39.899853512853632, -8.170542572838484],
              [-39.899853479032274, -8.170542608568923],
              [-39.899853445406151, -8.17054264448187],
              [-39.899853411976274, -8.170542680576197],
              [-39.899853378743586, -8.170542716850903],
              [-39.899853345709104, -8.170542753304856],
              [-39.899853312873773, -8.170542789937072],
              [-39.899853280238581, -8.170542826746368],
              [-39.899853247804494, -8.170542863731722],
              [-39.899853215572449, -8.170542900892013],
              [-39.899853183543414, -8.170542938226165],
              [-39.899853151718311, -8.17054297573306],
              [-39.899853120098129, -8.170543013411603],
              [-39.899853088683734, -8.170543051260667],
              [-39.899853057476093, -8.170543089279164],
              [-39.899853026476123, -8.170543127465972],
              [-39.899852995684725, -8.170543165819938],
              [-39.899852965102824, -8.170543204339944],
              [-39.8998529347313, -8.170543243024854],
              [-39.89985290457107, -8.170543281873528],
              [-39.899852874623008, -8.170543320884804],
              [-39.899852844888009, -8.170543360057566],
              [-39.899852815366941, -8.170543399390656],
              [-39.899852786060677, -8.170543438882889],
              [-39.899852756970077, -8.170543478533078],
              [-39.899852728096022, -8.170543518340139],
              [-39.899852699439336, -8.170543558302811],
              [-39.899852671000872, -8.170543598419956],
              [-39.899852642781475, -8.170543638690368],
              [-39.899852614781963, -8.170543679112887],
              [-39.899852587003181, -8.170543719686316],
              [-39.899852559445932, -8.170543760409423],
              [-39.899852532111041, -8.170543801281061],
              [-39.899852504999302, -8.170543842299995],
              [-39.899852478111526, -8.170543883465012],
              [-39.899852451448496, -8.170543924774913],
              [-39.899852425011005, -8.170543966228474],
              [-39.899852398799837, -8.170544007824482],
              [-39.899852372815744, -8.170544049561686],
              [-39.899852347059515, -8.170544091438883],
              [-39.899852321531895, -8.170544133454827],
              [-39.899852296233654, -8.170544175608248],
              [-39.899852271165507, -8.170544217897934],
              [-39.899852246328237, -8.170544260322655],
              [-39.899852221722554, -8.170544302881142],
              [-39.899852197349162, -8.170544345572134],
              [-39.899852173208807, -8.170544388394363],
              [-39.899852149302205, -8.170544431346579],
              [-39.899852125630048, -8.170544474427544],
              [-39.899852102193023, -8.170544517635934],
              [-39.899852078991849, -8.1705445609705],
              [-39.899852056027186, -8.170544604429976],
              [-39.899852033299723, -8.170544648013056],
              [-39.899852010810129, -8.170544691718479],
              [-39.899851988559064, -8.170544735544944],
              [-39.899851966547189, -8.170544779491136],
              [-39.899851944775143, -8.170544823555801],
              [-39.899851923243588, -8.17054486773762],
              [-39.899851901953134, -8.170544912035281],
              [-39.899851880904421, -8.170544956447499],
              [-39.899851860098089, -8.17054500097294],
              [-39.899851839534705, -8.170545045610311],
              [-39.899851819214916, -8.170545090358274],
              [-39.89985179913932, -8.170545135215534],
              [-39.899851779308484, -8.170545180180778],
              [-39.899851759723013, -8.170545225252651],
              [-39.899851740383475, -8.170545270429823],
              [-39.899851721290432, -8.170545315710962],
              [-39.899851702444472, -8.170545361094755],
              [-39.899851683846137, -8.170545406579851],
              [-39.899851665495966, -8.1705454521649],
              [-39.89985164739452, -8.170545497848552],
              [-39.899851629542312, -8.170545543629485],
              [-39.89985161193988, -8.170545589506352],
              [-39.899851594587737, -8.170545635477783],
              [-39.899851602064416, -8.170545648829414],
              [-39.899516658398397, -8.171440345928993],
              [-39.899516657041836, -8.171440357357653],
              [-39.899516622542372, -8.171440451092993],
              [-39.899516589083476, -8.171440545202087],
              [-39.899516556669234, -8.171440639673451],
              [-39.899516525303582, -8.171440734495569],
              [-39.899516494990358, -8.171440829656907],
              [-39.899516465733242, -8.171440925145838],
              [-39.899516437535787, -8.171441020950759],
              [-39.899516410401453, -8.171441117059944],
              [-39.899516384333552, -8.171441213461733],
              [-39.89951635933523, -8.17144131014436],
              [-39.899516335409551, -8.171441407096042],
              [-39.89951631255942, -8.171441504304992],
              [-39.899516290787652, -8.171441601759337],
              [-39.899516270096861, -8.171441699447177],
              [-39.899516250489604, -8.171441797356637],
              [-39.899516231968221, -8.171441895475832],
              [-39.899516214535019, -8.171441993792691],
              [-39.899516198192103, -8.171442092295369],
              [-39.899516182941461, -8.171442190971748],
              [-39.899516168784963, -8.171442289809896],
              [-39.899516155724314, -8.171442388797701],
              [-39.899516143761126, -8.171442487923125],
              [-39.899516132896842, -8.171442587174072],
              [-39.899516123132798, -8.171442686538477],
              [-39.899516114470167, -8.17144278600424],
              [-39.899516106910021, -8.171442885559216],
              [-39.899516100453283, -8.171442985191268],
              [-39.899516095100743, -8.171443084888301],
              [-39.899516090853005, -8.171443184638068],
              [-39.899516087710651, -8.171443284428522],
              [-39.899516085674023, -8.17144338424743],
              [-39.899516084743397, -8.17144348408266],
              [-39.899516084918872, -8.171443583922068],
              [-39.899516086200414, -8.171443683753427],
              [-39.899516088587895, -8.171443783564621],
              [-39.899516092081001, -8.171443883343487],
              [-39.899516096679314, -8.171443983077861],
              [-39.899516102382265, -8.171444082755587],
              [-39.8995161091892, -8.171444182364498],
              [-39.899516117099239, -8.17144428189247],
              [-39.899516126111436, -8.171444381327388],
              [-39.89951613622469, -8.171444480657113],
              [-39.89951614743778, -8.171444579869567],
              [-39.899516159749318, -8.171444678952639],
              [-39.899516173157849, -8.171444777894253],
              [-39.899516187661675, -8.17144487668237],
              [-39.899516203259076, -8.171444975304945],
              [-39.89951621994814, -8.171445073749977],
              [-39.899516237726822, -8.171445172005438],
              [-39.899516256592968, -8.171445270059371],
              [-39.899516276544276, -8.171445367899855],
              [-39.899516297578316, -8.171445465514882],
              [-39.899516319692509, -8.171445562892652],
              [-39.899516342884176, -8.171445660021259],
              [-39.899516367150518, -8.171445756888829],
              [-39.899516392488529, -8.171445853483648],
              [-39.899516418895125, -8.171445949793879],
              [-39.89951644636713, -8.171446045807802],
              [-39.899516474901155, -8.171446141513702],
              [-39.899516504493747, -8.171446236899955],
              [-39.899516535141274, -8.171446331954908],
              [-39.899516566840049, -8.171446426666988],
              [-39.899516599586164, -8.171446521024661],
              [-39.899516633375661, -8.171446615016414],
              [-39.899516668204377, -8.171446708630805],
              [-39.89951670406812, -8.171446801856401],
              [-39.899516740962483, -8.171446894681875],
              [-39.899516778882983, -8.171446987095917],
              [-39.899516817825017, -8.171447079087248],
              [-39.899516857783802, -8.171447170644644],
              [-39.899516898754513, -8.171447261756962],
              [-39.89951694073212, -8.171447352413111],
              [-39.899516983711521, -8.171447442602036],
              [-39.899517027687473, -8.171447532312769],
              [-39.899517072654639, -8.171447621534321],
              [-39.899517118607527, -8.171447710255878],
              [-39.899517165540523, -8.171447798466602],
              [-39.899517213447922, -8.171447886155752],
              [-39.899517262323904, -8.171447973312617],
              [-39.899517312162473, -8.171448059926606],
              [-39.89951736295756, -8.171448145987165],
              [-39.899517414703034, -8.171448231483799],
              [-39.899517467392506, -8.171448316406089],
              [-39.899517521019618, -8.17144840074369],
              [-39.899517575577811, -8.171448484486346],
              [-39.899517631060434, -8.171448567623806],
              [-39.899517687460751, -8.171448650145976],
              [-39.899517744771849, -8.171448732042768],
              [-39.899517802986772, -8.17144881330424],
              [-39.899517862098428, -8.17144889392047],
              [-39.899517922099598, -8.171448973881628],
              [-39.899517982982978, -8.171449053177966],
              [-39.89951804474115, -8.171449131799864],
              [-39.899518107366589, -8.171449209737686],
              [-39.899518170851657, -8.171449286981961],
              [-39.899518235188637, -8.171449363523276],
              [-39.899518300369664, -8.171449439352312],
              [-39.899518366386808, -8.171449514459825],
              [-39.899518433232032, -8.171449588836646],
              [-39.899518494503901, -8.171449567173562],
              [-39.900525378267652, -8.172557531619987],
              [-39.900525334051821, -8.172557568195186],
              [-39.900525464891864, -8.172557715352472],
              [-39.900525592490816, -8.172557865308605],
              [-39.900525716788387, -8.172558017992733],
              [-39.900525837725851, -8.172558173332675],
              [-39.900525955246067, -8.172558331255068],
              [-39.900526069293505, -8.172558491685278],
              [-39.900526179814278, -8.172558654547521],
              [-39.900526286756161, -8.172558819764815],
              [-39.900526390068642, -8.172558987259148],
              [-39.90052648970287, -8.172559156951348],
              [-39.900526585611821, -8.172559328761205],
              [-39.900526677750143, -8.172559502607571],
              [-39.9005267660743, -8.172559678408343],
              [-39.900526850542562, -8.172559856080396],
              [-39.900526931115024, -8.172560035539776],
              [-39.900527007753624, -8.172560216701749],
              [-39.900527080422144, -8.172560399480677],
              [-39.900527149086237, -8.172560583790194],
              [-39.900527213713474, -8.172560769543214],
              [-39.900527274273323, -8.172560956651997],
              [-39.900527330737148, -8.172561145028123],
              [-39.900527383078291, -8.172561334582568],
              [-39.900527431272018, -8.172561525225788],
              [-39.900527475295533, -8.172561716867705],
              [-39.900527515128076, -8.172561909417768],
              [-39.900527550750809, -8.172562102784989],
              [-39.900527582146871, -8.172562296877983],
              [-39.90052760930147, -8.172562491605108],
              [-39.900527632201772, -8.172562686874306],
              [-39.900527650836921, -8.172562882593303],
              [-39.900527665198148, -8.172563078669659],
              [-39.900527675278646, -8.172563275010701],
              [-39.900527681073655, -8.172563471523688],
              [-39.900527682580467, -8.172563668115728],
              [-39.900527679798323, -8.172563864693972],
              [-39.900527672728565, -8.172564061165517],
              [-39.900527661374532, -8.172564257437553],
              [-39.90052764574159, -8.17256445341731],
              [-39.9005276258371, -8.172564649012216],
              [-39.900527601670504, -8.172564844129834],
              [-39.900527573253214, -8.172565038678],
              [-39.900527540598624, -8.172565232564775],
              [-39.900527503722188, -8.172565425698524],
              [-39.900527462641342, -8.172565617988024],
              [-39.900527417375471, -8.172565809342391],
              [-39.900527367945969, -8.172565999671233],
              [-39.900527314376234, -8.172566188884614],
              [-39.900527256691504, -8.172566376893139],
              [-39.900527194919071, -8.172566563607967],
              [-39.900527129088125, -8.172566748940884],
              [-39.900527059229766, -8.172566932804319],
              [-39.900526985377006, -8.172567115111404],
              [-39.900526907564746, -8.172567295775979],
              [-39.900526825829722, -8.172567474712693],
              [-39.900526740210594, -8.172567651837019],
              [-39.900526650747786, -8.172567827065235],
              [-39.900526557483587, -8.172568000314572],
              [-39.900526460462046, -8.172568171503142],
              [-39.900526359729014, -8.172568340550084],
              [-39.900526255332096, -8.172568507375493],
              [-39.900526147320598, -8.172568671900592],
              [-39.9005260357456, -8.172568834047619],
              [-39.90052592065976, -8.17256899373996],
              [-39.900525802117478, -8.172569150902152],
              [-39.900525680174795, -8.172569305459954],
              [-39.900525554889285, -8.17256945734033],
              [-39.900525426320165, -8.172569606471527],
              [-39.90052529452818, -8.172569752783071],
              [-39.900525159575608, -8.17256989620584],
              [-39.9005250215262, -8.172570036672058],
              [-39.900524880445197, -8.172570174115354],
              [-39.900524736399262, -8.172570308470775],
              [-39.900524589456424, -8.172570439674869],
              [-39.900524439686151, -8.172570567665604],
              [-39.900524287159193, -8.172570692382561],
              [-39.90052413194762, -8.172570813766772],
              [-39.900523974124759, -8.172570931760895],
              [-39.900523813765197, -8.172571046309185],
              [-39.900523650944677, -8.172571157357465],
              [-39.900523485740166, -8.172571264853346],
              [-39.900523318229702, -8.172571368745986],
              [-39.900523148492425, -8.172571468986309],
              [-39.900522976608556, -8.172571565526967],
              [-39.900522802659289, -8.172571658322328],
              [-39.900522626726833, -8.172571747328552],
              [-39.900522448894293, -8.172571832503584],
              [-39.90052226924572, -8.172571913807184],
              [-39.900522087865966, -8.172571991200916],
              [-39.900521904840758, -8.172572064648234],
              [-39.900521720256563, -8.172572134114411],
              [-39.900521534200593, -8.172572199566661],
              [-39.900521346760783, -8.172572260974013],
              [-39.900521158025654, -8.172572318307513],
              [-39.900520968084422, -8.172572371540005],
              [-39.900520777026806, -8.172572420646375],
              [-39.900520584943095, -8.1725724656034],
              [-39.900520391924047, -8.172572506389855],
              [-39.900520198060853, -8.17257254298648],
              [-39.900520003444313, -8.172572575376424],
              [-39.900520056361366, -8.1725725572307],
              [-39.897594552838619, -8.173026940330544],
              [-39.897594513394978, -8.173026940162869],
              [-39.897594477297147, -8.173026945843366],
              [-39.897594441222552, -8.173026951668628],
              [-39.89759440517178, -8.173026957638548],
              [-39.897594369145423, -8.173026963753054],
              [-39.897594333144077, -8.173026970012018],
              [-39.897594297168318, -8.173026976415334],
              [-39.897594261218707, -8.173026982962913],
              [-39.897594225295848, -8.173026989654655],
              [-39.89759418940033, -8.173026996490428],
              [-39.897594153532701, -8.173027003470159],
              [-39.897594117693586, -8.173027010593714],
              [-39.897594081883533, -8.173027017860955],
              [-39.897594046103144, -8.173027025271811],
              [-39.897594010352989, -8.173027032826152],
              [-39.897593974633644, -8.173027040523804],
              [-39.89759393894569, -8.173027048364698],
              [-39.897593903289703, -8.173027056348689],
              [-39.897593867666266, -8.173027064475631],
              [-39.89759383207597, -8.173027072745425],
              [-39.897593796519381, -8.173027081157915],
              [-39.897593760997047, -8.173027089712974],
              [-39.89759372550958, -8.173027098410458],
              [-39.897593690057555, -8.173027107250197],
              [-39.897593654641518, -8.173027116232099],
              [-39.897593619262068, -8.173027125356013],
              [-39.897593583919779, -8.173027134621764],
              [-39.897593548615212, -8.173027144029225],
              [-39.89759351334893, -8.173027153578218],
              [-39.897593478121536, -8.173027163268594],
              [-39.897593442933577, -8.173027173100214],
              [-39.897593407785628, -8.173027183072902],
              [-39.897593372678259, -8.173027193186499],
              [-39.89759333761203, -8.173027203440848],
              [-39.897593302587531, -8.173027213835786],
              [-39.897593267605323, -8.17302722437115],
              [-39.897593232665955, -8.17302723504673],
              [-39.897593197770014, -8.173027245862379],
              [-39.897593162918049, -8.173027256817944],
              [-39.89759312811065, -8.173027267913213],
              [-39.897593093348348, -8.173027279148029],
              [-39.897593058631728, -8.173027290522198],
              [-39.897593023961363, -8.173027302035552],
              [-39.897592989337788, -8.173027313687864],
              [-39.897592954761571, -8.173027325478991],
              [-39.897592920233286, -8.173027337408731],
              [-39.897592885753483, -8.17302734947685],
              [-39.897592851322713, -8.173027361683209],
              [-39.897592816941568, -8.173027374027582],
              [-39.897592782610566, -8.173027386509746],
              [-39.897592748330268, -8.173027399129539],
              [-39.897592714101258, -8.173027411886768],
              [-39.897592679924067, -8.173027424781198],
              [-39.897592645799243, -8.173027437812609],
              [-39.897592611727354, -8.173027450980797],
              [-39.897592577708963, -8.173027464285575],
              [-39.897592543744594, -8.173027477726691],
              [-39.897592509834816, -8.173027491303959],
              [-39.897592475980176, -8.173027505017123],
              [-39.897592442181214, -8.173027518865997],
              [-39.897592408438484, -8.173027532850325],
              [-39.897592374752541, -8.173027546969905],
              [-39.897592341123925, -8.173027561224519],
              [-39.897592307553161, -8.173027575613894],
              [-39.897592274040825, -8.173027590137826],
              [-39.89759224058745, -8.173027604796081],
              [-39.897592207193561, -8.173027619588421],
              [-39.897592173859721, -8.173027634514554],
              [-39.897592140586468, -8.173027649574317],
              [-39.897592107374329, -8.173027664767421],
              [-39.897592074223851, -8.173027680093622],
              [-39.897592041135567, -8.173027695552692],
              [-39.897592008110017, -8.173027711144355],
              [-39.897591975147741, -8.173027726868375],
              [-39.897591942249257, -8.173027742724503],
              [-39.897591909415112, -8.173027758712459],
              [-39.897591876645834, -8.173027774832013],
              [-39.897591843941946, -8.173027791082889],
              [-39.897591811304011, -8.173027807464804],
              [-39.897591778732519, -8.17302782397754],
              [-39.897591746228017, -8.173027840620785],
              [-39.897591713791016, -8.173027857394272],
              [-39.897591681422064, -8.17302787429775],
              [-39.897591649121665, -8.173027891330948],
              [-39.897591616890367, -8.173027908493586],
              [-39.89759158472868, -8.173027925785354],
              [-39.89759155263711, -8.17302794320601],
              [-39.897591520616203, -8.173027960755249],
              [-39.897591488666457, -8.173027978432804],
              [-39.89759145678839, -8.173027996238366],
              [-39.897591424982544, -8.173028014171685],
              [-39.897591393249407, -8.173028032232438],
              [-39.897591361589512, -8.173028050420321],
              [-39.897591330003351, -8.173028068735077],
              [-39.897591298491456, -8.173028087176355],
              [-39.897591267054331, -8.173028105743935],
              [-39.897591235692488, -8.173028124437462],
              [-39.897591204406439, -8.173028143256628],
              [-39.897591173196673, -8.173028162201168],
              [-39.897591142063717, -8.173028181270725],
              [-39.897591197082214, -8.173028213999331],
              [-39.896128128513112, -8.173928395750638],
              [-39.89612808007827, -8.173928379757706],
              [-39.896127980122607, -8.173928442155937],
              [-39.896127880989134, -8.17392850584306],
              [-39.896127782694606, -8.173928570808254],
              [-39.896127685255586, -8.173928637040621],
              [-39.896127588688564, -8.173928704528905],
              [-39.896127493009836, -8.173928773261755],
              [-39.896127398235528, -8.173928843227539],
              [-39.896127304381679, -8.173928914414491],
              [-39.896127211464133, -8.173928986810518],
              [-39.896127119498566, -8.173929060403482],
              [-39.896127028500501, -8.173929135180872],
              [-39.896126938485324, -8.173929211130105],
              [-39.896126849468232, -8.173929288238337],
              [-39.896126761464224, -8.173929366492567],
              [-39.896126674488194, -8.17392944587959],
              [-39.896126588554814, -8.173929526385972],
              [-39.896126503678602, -8.173929607998147],
              [-39.896126419873895, -8.17392969070233],
              [-39.896126337154811, -8.173929774484542],
              [-39.896126255535357, -8.173929859330638],
              [-39.896126175029288, -8.173929945226293],
              [-39.89612609565021, -8.173930032157035],
              [-39.896126017411532, -8.173930120108151],
              [-39.896125940326449, -8.173930209064768],
              [-39.896125864407978, -8.173930299011909],
              [-39.896125789668957, -8.173930389934339],
              [-39.896125716121979, -8.173930481816775],
              [-39.896125643779492, -8.173930574643654],
              [-39.896125572653688, -8.173930668399329],
              [-39.896125502756583, -8.173930763067943],
              [-39.896125434099986, -8.173930858633511],
              [-39.896125366695479, -8.173930955079941],
              [-39.896125300554452, -8.173931052390897],
              [-39.896125235688075, -8.173931150549965],
              [-39.89612517210729, -8.173931249540539],
              [-39.896125109822847, -8.173931349345954],
              [-39.896125048845249, -8.173931449949341],
              [-39.896124989184791, -8.17393155133373],
              [-39.896124930851578, -8.173931653481963],
              [-39.89612487385542, -8.173931756376801],
              [-39.896124818205948, -8.173931860000909],
              [-39.896124763912582, -8.173931964336742],
              [-39.896124710984473, -8.173932069366709],
              [-39.896124659430548, -8.17393217507305],
              [-39.89612460925953, -8.173932281437924],
              [-39.89612456047989, -8.173932388443388],
              [-39.896124513099863, -8.17393249607138],
              [-39.896124467127443, -8.173932604303738],
              [-39.896124422570381, -8.173932713122149],
              [-39.896124379436223, -8.173932822508238],
              [-39.896124337732246, -8.173932932443552],
              [-39.896124297465498, -8.173933042909512],
              [-39.896124258642757, -8.173933153887514],
              [-39.896124221270604, -8.173933265358766],
              [-39.896124185355319, -8.173933377304468],
              [-39.896124150902999, -8.173933489705712],
              [-39.896124117919435, -8.173933602543519],
              [-39.896124086410211, -8.173933715798812],
              [-39.896124056380643, -8.173933829452515],
              [-39.896124027835803, -8.173933943485379],
              [-39.89612400078051, -8.173934057878236],
              [-39.896123975219339, -8.173934172611689],
              [-39.896123951156596, -8.173934287666407],
              [-39.896123928596346, -8.173934403022949],
              [-39.89612390754241, -8.173934518661838],
              [-39.896123887998314, -8.173934634563539],
              [-39.89612386996739, -8.173934750708501],
              [-39.896123853452664, -8.173934867077115],
              [-39.896123838456944, -8.173934983649708],
              [-39.89612382498273, -8.173935100406617],
              [-39.896123813032332, -8.173935217328129],
              [-39.89612380260774, -8.173935334394489],
              [-39.896123793710743, -8.173935451585903],
              [-39.896123786342812, -8.173935568882621],
              [-39.896123780505214, -8.17393568626485],
              [-39.896123776198912, -8.173935803712736],
              [-39.896123773424648, -8.173935921206473],
              [-39.896123772182904, -8.173936038726193],
              [-39.896123772473864, -8.173936156252086],
              [-39.896123774297493, -8.173936273764271],
              [-39.896123777653472, -8.173936391242925],
              [-39.89612378254126, -8.173936508668231],
              [-39.896123788960004, -8.173936626020321],
              [-39.896123796908633, -8.173936743279411],
              [-39.896123806385795, -8.17393686042568],
              [-39.896123817389906, -8.173936977439368],
              [-39.896123829919105, -8.173937094300705],
              [-39.896123843971253, -8.173937210989946],
              [-39.896123859543998, -8.173937327487412],
              [-39.896123876634718, -8.173937443773442],
              [-39.896123895240507, -8.17393755982836],
              [-39.896123915358238, -8.173937675632594],
              [-39.896123936984502, -8.17393779116658],
              [-39.896123960115666, -8.173937906410828],
              [-39.89612398474781, -8.173938021345867],
              [-39.896124010876747, -8.1739381359523],
              [-39.896124038498137, -8.173938250210742],
              [-39.896124067607246, -8.173938364101941],
              [-39.896124098199209, -8.173938477606661],
              [-39.896124156913935, -8.173938443885012],
              [-39.89646449093199, -8.175169181182731],
              [-39.896464409707228, -8.175169145198918],
              [-39.896464417889945, -8.175169174988206],
              [-39.896464425970514, -8.175169204805142],
              [-39.896464433948879, -8.175169234649429],
              [-39.896464441824889, -8.175169264520678],
              [-39.896464449598497, -8.175169294418534],
              [-39.896464457269552, -8.175169324342701],
              [-39.89646446483804, -8.175169354292764],
              [-39.896464472303833, -8.175169384268393],
              [-39.896464479666847, -8.17516941426927],
              [-39.896464486927002, -8.175169444295035],
              [-39.896464494084206, -8.175169474345303],
              [-39.896464501138382, -8.175169504419758],
              [-39.896464508089444, -8.175169534518027],
              [-39.896464514937314, -8.175169564639789],
              [-39.896464521681892, -8.175169594784675],
              [-39.896464528323136, -8.175169624952328],
              [-39.896464534860961, -8.175169655142401],
              [-39.896464541295266, -8.175169685354536],
              [-39.896464547625982, -8.175169715588408],
              [-39.896464553853065, -8.175169745843625],
              [-39.896464559976408, -8.17516977611986],
              [-39.896464565995942, -8.175169806416758],
              [-39.896464571911615, -8.175169836733952],
              [-39.896464577723364, -8.175169867071087],
              [-39.896464583431083, -8.175169897427837],
              [-39.896464589034736, -8.175169927803815],
              [-39.896464594534272, -8.175169958198696],
              [-39.896464599929587, -8.175169988612094],
              [-39.896464605220643, -8.175170019043678],
              [-39.896464610407371, -8.175170049493097],
              [-39.896464615489698, -8.175170079959981],
              [-39.896464620467597, -8.175170110443979],
              [-39.896464625340997, -8.175170140944733],
              [-39.896464630109811, -8.175170171461879],
              [-39.89646463477402, -8.175170201995089],
              [-39.896464639333573, -8.175170232543982],
              [-39.896464643788377, -8.175170263108214],
              [-39.896464648138426, -8.175170293687424],
              [-39.896464652383635, -8.175170324281265],
              [-39.896464656523975, -8.175170354889341],
              [-39.896464660559381, -8.175170385511345],
              [-39.896464664489827, -8.175170416146909],
              [-39.896464668315247, -8.175170446795661],
              [-39.896464672035613, -8.175170477457259],
              [-39.896464675650861, -8.175170508131311],
              [-39.896464679160964, -8.175170538817516],
              [-39.896464682565892, -8.175170569515467],
              [-39.896464685865574, -8.175170600224853],
              [-39.896464689060018, -8.175170630945253],
              [-39.896464692149131, -8.175170661676347],
              [-39.896464695132906, -8.175170692417765],
              [-39.896464698011307, -8.17517072316916],
              [-39.8964647007843, -8.17517075393018],
              [-39.896464703451855, -8.175170784700468],
              [-39.896464706013951, -8.175170815479632],
              [-39.896464708470525, -8.175170846267319],
              [-39.896464710821569, -8.175170877063225],
              [-39.896464713067054, -8.17517090786691],
              [-39.896464715206967, -8.175170938678077],
              [-39.896464717241265, -8.175170969496335],
              [-39.896464719169941, -8.175171000321342],
              [-39.896464720992952, -8.175171031152715],
              [-39.896464722710299, -8.17517106199014],
              [-39.896464724321923, -8.175171092833201],
              [-39.896464725827848, -8.175171123681571],
              [-39.896464727228036, -8.175171154534908],
              [-39.896464728522481, -8.175171185392813],
              [-39.896464729711155, -8.17517121625494],
              [-39.896464730794065, -8.175171247120941],
              [-39.896464731771168, -8.175171277990447],
              [-39.896464732642478, -8.175171308863085],
              [-39.89646473340796, -8.175171339738531],
              [-39.896464734067635, -8.175171370616372],
              [-39.896464734621489, -8.1751714014963],
              [-39.896464735069479, -8.175171432377931],
              [-39.896464735411655, -8.175171463260906],
              [-39.896464735647982, -8.175171494144838],
              [-39.896464735778451, -8.175171525029411],
              [-39.896464735803079, -8.17517155591427],
              [-39.89646473572185, -8.175171586799024],
              [-39.896464735534792, -8.17517161768329],
              [-39.896464735241864, -8.175171648566764],
              [-39.896464734843107, -8.175171679449072],
              [-39.896464734338501, -8.17517171032981],
              [-39.896464733728081, -8.175171741208683],
              [-39.896464733011825, -8.17517177208528],
              [-39.896464732189763, -8.175171802959261],
              [-39.896464731261879, -8.175171833830277],
              [-39.896464730228203, -8.175171864697939],
              [-39.896464729088748, -8.175171895561927],
              [-39.896464727843522, -8.175171926421852],
              [-39.896464726492532, -8.175171957277348],
              [-39.896464725035806, -8.17517198812804],
              [-39.896464723473358, -8.175172018973662],
              [-39.896464721805195, -8.175172049813753],
              [-39.896464720031354, -8.175172080647998],
              [-39.896464718151854, -8.175172111476028],
              [-39.896464716166705, -8.175172142297484],
              [-39.896464714076046, -8.175172173111322],
              [-39.896464769916271, -8.175172165230762],
              [-39.89635579696872, -8.176737547818831],
              [-39.89635579876083, -8.176737541472734],
              [-39.896355786298393, -8.176737700310726],
              [-39.896355771029782, -8.176737858905376],
              [-39.896355752959735, -8.176738017207414],
              [-39.896355732093852, -8.176738175167769],
              [-39.89635570843862, -8.176738332737386],
              [-39.896355682001392, -8.1767384898674],
              [-39.896355652790334, -8.176738646509017],
              [-39.896355620814546, -8.176738802613643],
              [-39.896355586083921, -8.176738958132868],
              [-39.896355548609257, -8.176739113018394],
              [-39.896355508402181, -8.176739267222185],
              [-39.896355465475168, -8.176739420696373],
              [-39.896355419841534, -8.176739573393332],
              [-39.896355371515433, -8.176739725265692],
              [-39.896355320511873, -8.176739876266321],
              [-39.896355266846676, -8.176740026348362],
              [-39.896355210536505, -8.176740175465234],
              [-39.896355151598819, -8.176740323570687],
              [-39.896355090051912, -8.176740470618741],
              [-39.896355025914893, -8.176740616563773],
              [-39.896354959207635, -8.176740761360479],
              [-39.896354889950864, -8.176740904963971],
              [-39.896354818166067, -8.176741047329646],
              [-39.896354743875513, -8.176741188413347],
              [-39.896354667102258, -8.176741328171287],
              [-39.896354587870135, -8.176741466560072],
              [-39.896354506203714, -8.176741603536822],
              [-39.896354422128333, -8.176741739058993],
              [-39.896354335670118, -8.176741873084531],
              [-39.89635424685585, -8.176742005571844],
              [-39.896354155713119, -8.176742136479824],
              [-39.896354062270198, -8.176742265767871],
              [-39.896353966556084, -8.176742393395825],
              [-39.896353868600478, -8.176742519324117],
              [-39.896353768433777, -8.176742643513633],
              [-39.896353666087059, -8.176742765925885],
              [-39.8963535615921, -8.17674288652284],
              [-39.896353454981295, -8.176743005267138],
              [-39.896353346287746, -8.176743122121849],
              [-39.896353235545178, -8.176743237050756],
              [-39.896353122787978, -8.176743350018201],
              [-39.896353008051094, -8.176743460989133],
              [-39.896352891370157, -8.176743569929098],
              [-39.896352772781363, -8.176743676804323],
              [-39.896352652321518, -8.176743781581603],
              [-39.896352530027997, -8.176743884228443],
              [-39.896352405938757, -8.176743984712987],
              [-39.896352280092302, -8.176744083004037],
              [-39.896352152527669, -8.176744179071145],
              [-39.89635202328445, -8.176744272884472],
              [-39.896351892402762, -8.176744364414906],
              [-39.896351759923192, -8.176744453634004],
              [-39.896351625886886, -8.176744540514141],
              [-39.896351490335398, -8.176744625028327],
              [-39.896351353310834, -8.176744707150357],
              [-39.89635121485567, -8.176744786854716],
              [-39.896351075012888, -8.176744864116706],
              [-39.8963509338259, -8.176744938912318],
              [-39.896350791338492, -8.176745011218351],
              [-39.896350647594886, -8.176745081012388],
              [-39.896350502639706, -8.176745148272767],
              [-39.896350356517907, -8.176745212978616],
              [-39.896350209274836, -8.176745275109853],
              [-39.896350060956173, -8.176745334647189],
              [-39.896349911607992, -8.176745391572132],
              [-39.896349761276589, -8.17674544586705],
              [-39.89634961000862, -8.176745497515116],
              [-39.896349457851031, -8.176745546500232],
              [-39.896349304851057, -8.176745592807244],
              [-39.896349151056135, -8.176745636421785],
              [-39.896348996514021, -8.176745677330343],
              [-39.896348841272655, -8.176745715520159],
              [-39.896348685380218, -8.176745750979418],
              [-39.896348528885092, -8.176745783697097],
              [-39.896348371835806, -8.176745813663082],
              [-39.89634821428114, -8.176745840868048],
              [-39.89634805626995, -8.176745865303566],
              [-39.896347897851278, -8.176745886962079],
              [-39.896347739074287, -8.176745905836773],
              [-39.896347579988252, -8.176745921921881],
              [-39.89634742064252, -8.176745935212409],
              [-39.896347261086547, -8.176745945704152],
              [-39.896347101369848, -8.176745953393947],
              [-39.896346941541985, -8.176745958279348],
              [-39.896346781652547, -8.176745960358897],
              [-39.896346621751135, -8.176745959631873],
              [-39.896346461887404, -8.176745956098557],
              [-39.896346302110942, -8.176745949760052],
              [-39.89634614247133, -8.17674594061827],
              [-39.896345983018087, -8.176745928676111],
              [-39.896345823800736, -8.17674591393723],
              [-39.89634566486864, -8.176745896406212],
              [-39.896345506271139, -8.176745876088514],
              [-39.896345348057451, -8.176745852990441],
              [-39.896345190276655, -8.176745827119124],
              [-39.896345032977727, -8.176745798482646],
              [-39.896344876209469, -8.176745767089844],
              [-39.896344720020522, -8.176745732950485],
              [-39.896344564458843, -8.176745696075708],
              [-39.896344650008288, -8.176745712531275],
              [-39.89454234211415, -8.176301732764154],
              [-39.894542298902486, -8.176301722391806],
              [-39.894542290257498, -8.17630172026017],
              [-39.894542281610349, -8.17630171813723],
              [-39.894542272961047, -8.176301716023007],
              [-39.894542264309607, -8.176301713917486],
              [-39.894542255656042, -8.176301711820686],
              [-39.894542247000338, -8.176301709732591],
              [-39.894542238342545, -8.176301707653225],
              [-39.894542229682635, -8.176301705582556],
              [-39.894542221020636, -8.176301703520602],
              [-39.894542212356541, -8.176301701467381],
              [-39.894542203690371, -8.176301699422872],
              [-39.894542195022133, -8.176301697387096],
              [-39.894542186351849, -8.176301695360051],
              [-39.894542177679497, -8.176301693341699],
              [-39.894542169005113, -8.176301691332114],
              [-39.89454216032869, -8.176301689331229],
              [-39.894542151650242, -8.176301687339109],
              [-39.894542142969783, -8.176301685355698],
              [-39.894542134287313, -8.176301683381054],
              [-39.894542125602854, -8.176301681415124],
              [-39.894542116916391, -8.176301679457957],
              [-39.894542108227967, -8.176301677509526],
              [-39.89454209953756, -8.176301675569855],
              [-39.894542090845192, -8.176301673638935],
              [-39.89454208215087, -8.176301671716743],
              [-39.894542073454602, -8.176301669803317],
              [-39.894542064756408, -8.176301667898654],
              [-39.894542056056288, -8.17630166600274],
              [-39.894542047354228, -8.17630166411559],
              [-39.894542038650286, -8.176301662237188],
              [-39.894542029944425, -8.176301660367535],
              [-39.894542021236674, -8.176301658506658],
              [-39.894542012527054, -8.176301656654552],
              [-39.894542003815552, -8.176301654811203],
              [-39.894541995102188, -8.176301652976624],
              [-39.894541986386969, -8.176301651150826],
              [-39.894541977669903, -8.176301649333793],
              [-39.894541968950996, -8.176301647525552],
              [-39.894541960230249, -8.176301645726097],
              [-39.894541951507705, -8.176301643935387],
              [-39.894541942783327, -8.17630164215346],
              [-39.894541934057159, -8.176301640380348],
              [-39.894541925329193, -8.176301638616016],
              [-39.894541916599444, -8.176301636860462],
              [-39.894541907867932, -8.17630163511371],
              [-39.894541899134644, -8.176301633375708],
              [-39.894541890399601, -8.176301631646531],
              [-39.894541881662796, -8.176301629926154],
              [-39.894541872924265, -8.176301628214542],
              [-39.894541864183992, -8.176301626511727],
              [-39.894541855442007, -8.176301624817725],
              [-39.89454184669831, -8.176301623132506],
              [-39.8945418379529, -8.176301621456121],
              [-39.894541829205799, -8.176301619788495],
              [-39.894541820457022, -8.176301618129703],
              [-39.89454181170656, -8.176301616479725],
              [-39.894541802954414, -8.176301614838541],
              [-39.894541794200627, -8.176301613206157],
              [-39.894541785445185, -8.176301611582609],
              [-39.894541776688101, -8.176301609967867],
              [-39.894541767929375, -8.17630160836193],
              [-39.89454175916903, -8.176301606764836],
              [-39.894541750407072, -8.176301605176544],
              [-39.894541741643522, -8.176301603597077],
              [-39.894541732878359, -8.176301602026429],
              [-39.894541724111612, -8.176301600464612],
              [-39.894541715343287, -8.176301598911623],
              [-39.894541706573399, -8.176301597367447],
              [-39.894541697801927, -8.176301595832109],
              [-39.894541689028927, -8.176301594305581],
              [-39.89454168025437, -8.176301592787901],
              [-39.894541671478279, -8.176301591279035],
              [-39.894541662700675, -8.176301589779017],
              [-39.894541653921536, -8.17630158828783],
              [-39.894541645140912, -8.17630158680549],
              [-39.89454163635876, -8.176301585331998],
              [-39.894541627575137, -8.176301583867321],
              [-39.894541618790029, -8.176301582411508],
              [-39.894541610003458, -8.176301580964529],
              [-39.89454160121543, -8.176301579526427],
              [-39.894541592425924, -8.176301578097137],
              [-39.894541583634997, -8.1763015766767],
              [-39.894541574842627, -8.176301575265125],
              [-39.894541566048815, -8.176301573862398],
              [-39.894541557253596, -8.176301572468503],
              [-39.894541548456964, -8.176301571083487],
              [-39.894541539658952, -8.1763015697073],
              [-39.89454153085952, -8.17630156834],
              [-39.894541522058731, -8.176301566981541],
              [-39.894541513256556, -8.176301565631952],
              [-39.894541504453009, -8.176301564291224],
              [-39.89454149564812, -8.176301562959328],
              [-39.894541486841895, -8.17630156163631],
              [-39.894541478034313, -8.176301560322143],
              [-39.894541469225409, -8.176301559016856],
              [-39.894541460415198, -8.17630155772045],
              [-39.894541451603665, -8.176301556432891],
              [-39.894541442790825, -8.176301555154215],
              [-39.894541433976705, -8.176301553884416],
              [-39.894541381816801, -8.176301545796308],
              [-39.892166776798753, -8.175969533029061],
              [-39.892166713448731, -8.17596947055525],
              [-39.892166633237963, -8.175969459701429],
              [-39.892166552933247, -8.1759694495607],
              [-39.892166472540957, -8.175969440133898],
              [-39.892166392067509, -8.175969431421766],
              [-39.892166311519283, -8.175969423424956],
              [-39.892166230902689, -8.175969416144159],
              [-39.892166150224128, -8.175969409579912],
              [-39.892166069490038, -8.175969403732791],
              [-39.892165988706829, -8.175969398603208],
              [-39.892165907880909, -8.175969394191585],
              [-39.892165827018736, -8.175969390498288],
              [-39.892165746126707, -8.175969387523585],
              [-39.892165665211287, -8.175969385267742],
              [-39.892165584278885, -8.175969383730944],
              [-39.892165503335953, -8.175969382913266],
              [-39.892165422388913, -8.175969382814788],
              [-39.892165341444219, -8.175969383435556],
              [-39.892165260508293, -8.175969384775481],
              [-39.892165179587579, -8.175969386834483],
              [-39.892165098688508, -8.175969389612368],
              [-39.892165017817526, -8.175969393108943],
              [-39.89216493698104, -8.175969397323897],
              [-39.892164856185495, -8.175969402256948],
              [-39.892164775437323, -8.175969407907672],
              [-39.892164694742924, -8.175969414275606],
              [-39.892164614108722, -8.175969421360277],
              [-39.892164533541148, -8.175969429161098],
              [-39.89216445304659, -8.175969437677438],
              [-39.892164372631441, -8.175969446908667],
              [-39.89216429230212, -8.175969456853995],
              [-39.892164212064984, -8.175969467512662],
              [-39.892164131926428, -8.175969478883815],
              [-39.892164051892856, -8.17596949096656],
              [-39.892163971970589, -8.17596950375991],
              [-39.892163892166018, -8.175969517262864],
              [-39.892163812485443, -8.175969531474346],
              [-39.892163732935238, -8.175969546393235],
              [-39.892163653521706, -8.175969562018329],
              [-39.892163574251192, -8.175969578348385],
              [-39.892163495129971, -8.175969595382114],
              [-39.892163416164344, -8.17596961311817],
              [-39.892163337360593, -8.175969631555132],
              [-39.892163258724992, -8.175969650691535],
              [-39.892163180263786, -8.175969670525845],
              [-39.892163101983201, -8.175969691056489],
              [-39.892163023889481, -8.175969712281862],
              [-39.892162945988815, -8.175969734200232],
              [-39.892162868287436, -8.175969756809895],
              [-39.892162790791495, -8.175969780109055],
              [-39.892162713507147, -8.175969804095828],
              [-39.892162636440553, -8.175969828768316],
              [-39.892162559597843, -8.175969854124563],
              [-39.892162482985135, -8.175969880162558],
              [-39.892162406608492, -8.175969906880226],
              [-39.892162330474008, -8.175969934275429],
              [-39.892162254587738, -8.175969962346004],
              [-39.892162178955708, -8.175969991089714],
              [-39.892162103583935, -8.175970020504277],
              [-39.892162028478424, -8.175970050587356],
              [-39.892161953645129, -8.175970081336548],
              [-39.892161879090018, -8.175970112749402],
              [-39.892161804819004, -8.175970144823429],
              [-39.892161730838005, -8.175970177556071],
              [-39.892161657152897, -8.175970210944776],
              [-39.892161583769557, -8.175970244986827],
              [-39.892161510693782, -8.175970279679541],
              [-39.892161437931414, -8.175970315020153],
              [-39.892161365488235, -8.175970351005871],
              [-39.892161293370016, -8.175970387633804],
              [-39.892161221582462, -8.175970424901044],
              [-39.892161150131308, -8.175970462804642],
              [-39.892161079022209, -8.17597050134159],
              [-39.892161008260857, -8.175970540508803],
              [-39.89216093785285, -8.175970580303183],
              [-39.892160867803796, -8.17597062072155],
              [-39.892160798119271, -8.175970661760706],
              [-39.892160728804818, -8.175970703417363],
              [-39.89216065986593, -8.175970745688238],
              [-39.892160591308098, -8.175970788569938],
              [-39.892160523136788, -8.175970832059081],
              [-39.892160455357399, -8.175970876152185],
              [-39.892160387975331, -8.175970920845776],
              [-39.892160320995941, -8.175970966136285],
              [-39.892160254424574, -8.175971012020089],
              [-39.892160188266494, -8.175971058493566],
              [-39.892160122526967, -8.175971105553002],
              [-39.892160057211235, -8.175971153194666],
              [-39.892159992324501, -8.1759712014148],
              [-39.892159927871901, -8.175971250209525],
              [-39.892159863858559, -8.175971299574964],
              [-39.892159800289598, -8.175971349507204],
              [-39.892159737170033, -8.175971400002272],
              [-39.892159674504896, -8.175971451056148],
              [-39.892159612299189, -8.175971502664774],
              [-39.892159550557835, -8.175971554824038],
              [-39.892159489285753, -8.175971607529808],
              [-39.892159428487815, -8.175971660777902],
              [-39.892159368168869, -8.175971714564042],
              [-39.892159308333689, -8.175971768883995],
              [-39.892159338404163, -8.175971810555183],
              [-39.890810276375618, -8.177207544431052],
              [-39.890810262687353, -8.17720747551537],
              [-39.89081018738144, -8.177207545282489],
              [-39.890810112873545, -8.177207615895078],
              [-39.890810039173246, -8.177207687344108],
              [-39.89080996629, -8.177207759620385],
              [-39.890809894233151, -8.177207832714648],
              [-39.890809823011956, -8.177207906617539],
              [-39.890809752635541, -8.177207981319521],
              [-39.890809683112941, -8.17720805681104],
              [-39.890809614453097, -8.1772081330824],
              [-39.890809546664798, -8.177208210123819],
              [-39.890809479756747, -8.177208287925401],
              [-39.890809413737543, -8.177208366477192],
              [-39.890809348615633, -8.177208445769059],
              [-39.890809284399388, -8.177208525790881],
              [-39.890809221097065, -8.177208606532327],
              [-39.890809158716756, -8.177208687983118],
              [-39.890809097266491, -8.177208770132731],
              [-39.890809036754135, -8.177208852970663],
              [-39.890808977187461, -8.177208936486254],
              [-39.890808918574137, -8.177209020668833],
              [-39.890808860921659, -8.177209105507563],
              [-39.890808804237416, -8.177209190991553],
              [-39.890808748528706, -8.177209277109821],
              [-39.890808693802661, -8.177209363851352],
              [-39.890808640066318, -8.177209451204993],
              [-39.890808587326575, -8.177209539159533],
              [-39.890808535590175, -8.177209627703709],
              [-39.890808484863783, -8.177209716826159],
              [-39.890808435153893, -8.177209806515416],
              [-39.890808386466887, -8.177209896759969],
              [-39.890808338809023, -8.177209987548283],
              [-39.890808292186399, -8.177210078868654],
              [-39.890808246605026, -8.17721017070938],
              [-39.890808202070716, -8.177210263058722],
              [-39.890808158589209, -8.177210355904752],
              [-39.890808116166092, -8.177210449235586],
              [-39.890808074806777, -8.177210543039283],
              [-39.890808034516603, -8.177210637303752],
              [-39.890807995300719, -8.177210732016938],
              [-39.890807957164171, -8.177210827166649],
              [-39.890807920111847, -8.177210922740709],
              [-39.890807884148494, -8.17721101872683],
              [-39.890807849278758, -8.1772111151127],
              [-39.890807815507081, -8.177211211885945],
              [-39.890807782837804, -8.177211309034179],
              [-39.890807751275126, -8.177211406544899],
              [-39.890807720823084, -8.177211504405619],
              [-39.890807691485612, -8.177211602603746],
              [-39.89080766326645, -8.177211701126685],
              [-39.890807636169228, -8.177211799961851],
              [-39.890807610197427, -8.177211899096505],
              [-39.890807585354374, -8.177211998517947],
              [-39.890807561643257, -8.177212098213396],
              [-39.890807539067119, -8.177212198170078],
              [-39.890807517628879, -8.177212298375146],
              [-39.890807497331252, -8.177212398815744],
              [-39.890807478176853, -8.177212499479012],
              [-39.890807460168162, -8.177212600352018],
              [-39.890807443307459, -8.177212701421789],
              [-39.89080742759694, -8.177212802675383],
              [-39.89080741303858, -8.177212904099823],
              [-39.890807399634291, -8.177213005682049],
              [-39.890807387385756, -8.177213107409068],
              [-39.890807376294582, -8.177213209267787],
              [-39.890807366362161, -8.177213311245168],
              [-39.890807357589772, -8.17721341332809],
              [-39.890807349978573, -8.177213515503487],
              [-39.890807343529502, -8.177213617758248],
              [-39.890807338243405, -8.177213720079212],
              [-39.890807334120979, -8.177213822453293],
              [-39.890807331162698, -8.177213924867326],
              [-39.890807329368982, -8.177214027308175],
              [-39.890807328740053, -8.177214129762714],
              [-39.890807329276008, -8.177214232217754],
              [-39.890807330976749, -8.177214334660185],
              [-39.890807333842076, -8.177214437076852],
              [-39.890807337871621, -8.177214539454582],
              [-39.890807343064864, -8.177214641780289],
              [-39.890807349421117, -8.177214744040809],
              [-39.890807356939604, -8.177214846223031],
              [-39.890807365619338, -8.177214948313827],
              [-39.890807375459204, -8.177215050300093],
              [-39.890807386457944, -8.177215152168776],
              [-39.890807398614157, -8.177215253906812],
              [-39.890807411926247, -8.177215355501056],
              [-39.890807426392549, -8.17721545693856],
              [-39.890807442011194, -8.177215558206274],
              [-39.890807458780159, -8.177215659291212],
              [-39.890807476697312, -8.177215760180392],
              [-39.890807495760342, -8.177215860860857],
              [-39.890807515966813, -8.177215961319698],
              [-39.890807537314117, -8.177216061544067],
              [-39.890807559799519, -8.177216161521013],
              [-39.890807583420163, -8.177216261237771],
              [-39.890807608172977, -8.177216360681546],
              [-39.890807634054809, -8.177216459839558],
              [-39.890807661062318, -8.177216558699083],
              [-39.890807689192059, -8.177216657247417],
              [-39.890807718440406, -8.177216755471941],
              [-39.890807755187119, -8.17721677533204],
              [-39.891146822460271, -8.178332113882812],
              [-39.891146742524334, -8.178332087318596],
              [-39.891146771965943, -8.17833218229182],
              [-39.891146802453349, -8.17833227693691],
              [-39.891146833982866, -8.178332371242433],
              [-39.89114686655067, -8.178332465197011],
              [-39.891146900152847, -8.1783325587893],
              [-39.891146934785318, -8.178332652007983],
              [-39.891146970443934, -8.178332744841786],
              [-39.891147007124346, -8.178332837279546],
              [-39.891147044822155, -8.178332929310061],
              [-39.891147083532807, -8.178333020922228],
              [-39.891147123251592, -8.178333112104964],
              [-39.891147163973756, -8.178333202847277],
              [-39.891147205694352, -8.178333293138207],
              [-39.891147248408359, -8.178333382966818],
              [-39.891147292110603, -8.178333472322269],
              [-39.891147336795811, -8.178333561193812],
              [-39.891147382458591, -8.178333649570673],
              [-39.891147429093422, -8.178333737442172],
              [-39.89114747669467, -8.178333824797715],
              [-39.891147525256585, -8.178333911626723],
              [-39.891147574773299, -8.178333997918735],
              [-39.891147625238837, -8.178334083663351],
              [-39.891147676647108, -8.178334168850169],
              [-39.891147728991896, -8.178334253468913],
              [-39.891147782266884, -8.178334337509371],
              [-39.891147836465628, -8.178334420961395],
              [-39.891147891581582, -8.178334503814863],
              [-39.891147947608097, -8.178334586059815],
              [-39.891148004538401, -8.178334667686311],
              [-39.891148062365616, -8.17833474868447],
              [-39.891148121082772, -8.178334829044525],
              [-39.891148180682748, -8.178334908756772],
              [-39.891148241158383, -8.178334987811594],
              [-39.891148302502337, -8.178335066199409],
              [-39.891148364707213, -8.178335143910777],
              [-39.891148427765508, -8.178335220936294],
              [-39.89114849166959, -8.178335297266662],
              [-39.89114855641175, -8.178335372892667],
              [-39.891148621984172, -8.178335447805152],
              [-39.891148688378912, -8.178335521995118],
              [-39.891148755587984, -8.178335595453543],
              [-39.891148823603245, -8.178335668171608],
              [-39.891148892416503, -8.178335740140495],
              [-39.891148962019422, -8.178335811351522],
              [-39.891149032403604, -8.17833588179608],
              [-39.891149103560544, -8.178335951465654],
              [-39.891149175481665, -8.178336020351866],
              [-39.891149248158264, -8.178336088446395],
              [-39.891149321581565, -8.17833615574096],
              [-39.891149395742701, -8.178336222227482],
              [-39.891149470632705, -8.178336287897899],
              [-39.891149546242552, -8.178336352744315],
              [-39.891149622563098, -8.178336416758867],
              [-39.891149699585107, -8.178336479933858],
              [-39.891149777299304, -8.17833654226161],
              [-39.891149855696305, -8.178336603734611],
              [-39.891149934766624, -8.178336664345474],
              [-39.89115001450071, -8.178336724086821],
              [-39.891150094888914, -8.178336782951444],
              [-39.891150175921574, -8.178336840932296],
              [-39.891150257588862, -8.178336898022296],
              [-39.891150339880944, -8.178336954214602],
              [-39.891150422787859, -8.178337009502394],
              [-39.891150506299589, -8.178337063878987],
              [-39.89115059040607, -8.178337117337868],
              [-39.89115067509713, -8.17833716987252],
              [-39.891150760362542, -8.178337221476616],
              [-39.891150846191998, -8.178337272143951],
              [-39.891150932575137, -8.178337321868387],
              [-39.891151019501521, -8.178337370643895],
              [-39.891151106960685, -8.178337418464595],
              [-39.891151194942005, -8.178337465324732],
              [-39.891151283434901, -8.17833751121864],
              [-39.891151372428666, -8.178337556140757],
              [-39.89115146191255, -8.17833760008569],
              [-39.891151551875758, -8.178337643048089],
              [-39.891151642307399, -8.178337685022768],
              [-39.89115173319658, -8.178337726004715],
              [-39.891151824532294, -8.178337765988923],
              [-39.891151916303556, -8.178337804970589],
              [-39.891152008499205, -8.178337842944959],
              [-39.891152101108169, -8.178337879907506],
              [-39.89115219411925, -8.178337915853744],
              [-39.891152287521201, -8.178337950779326],
              [-39.891152381302739, -8.178337984680043],
              [-39.891152475452543, -8.178338017551805],
              [-39.891152569959239, -8.178338049390602],
              [-39.891152664811436, -8.17833808019263],
              [-39.891152759997631, -8.178338109954151],
              [-39.891152855506363, -8.178338138671572],
              [-39.891152951326077, -8.178338166341428],
              [-39.891153047445208, -8.178338192960378],
              [-39.891153143852151, -8.178338218525196],
              [-39.89115324053526, -8.178338243032806],
              [-39.891153337482841, -8.178338266480234],
              [-39.891153434683197, -8.17833828886466],
              [-39.891153532124591, -8.178338310183385],
              [-39.891153629795255, -8.178338330433837],
              [-39.891153727683367, -8.178338349613547],
              [-39.891153735141003, -8.178338339317277],
              [-39.894656297790988, -8.179004685014464],
              [-39.894656216567704, -8.179004696069056],
              [-39.894656307643686, -8.179004713877084],
              [-39.894656398532163, -8.179004732611983],
              [-39.894656489223635, -8.179004752271821],
              [-39.894656579708624, -8.179004772854546],
              [-39.894656669977664, -8.179004794357976],
              [-39.894656760021334, -8.179004816779885],
              [-39.894656849830199, -8.179004840117932],
              [-39.894656939394878, -8.179004864369679],
              [-39.894657028706014, -8.179004889532557],
              [-39.894657117754257, -8.179004915603981],
              [-39.894657206530319, -8.179004942581184],
              [-39.894657295024899, -8.17900497046138],
              [-39.894657383228761, -8.179004999241636],
              [-39.894657471132675, -8.179005028918949],
              [-39.894657558727438, -8.179005059490214],
              [-39.894657646003907, -8.179005090952234],
              [-39.894657732952972, -8.179005123301712],
              [-39.894657819565516, -8.179005156535284],
              [-39.894657905832496, -8.179005190649464],
              [-39.894657991744907, -8.179005225640671],
              [-39.894658077293762, -8.17900526150529],
              [-39.894658162470094, -8.179005298239534],
              [-39.894658247265021, -8.179005335839587],
              [-39.894658331669653, -8.179005374301498],
              [-39.894658415675202, -8.179005413621255],
              [-39.894658499272872, -8.179005453794755],
              [-39.894658582453907, -8.179005494817789],
              [-39.89465866520964, -8.179005536686057],
              [-39.894658747531402, -8.179005579395229],
              [-39.894658829410581, -8.179005622940757],
              [-39.894658910838629, -8.179005667318158],
              [-39.894658991807034, -8.179005712522761],
              [-39.894659072307313, -8.179005758549858],
              [-39.89465915233108, -8.179005805394644],
              [-39.894659231869952, -8.179005853052201],
              [-39.894659310915607, -8.179005901517545],
              [-39.89465938945979, -8.179005950785644],
              [-39.894659467494286, -8.179006000851288],
              [-39.894659545010953, -8.179006051709274],
              [-39.894659622001662, -8.179006103354302],
              [-39.894659698458376, -8.179006155780959],
              [-39.894659774373103, -8.179006208983756],
              [-39.89465984973792, -8.179006262957152],
              [-39.894659924544918, -8.179006317695469],
              [-39.894659998786302, -8.179006373193014],
              [-39.8946600724543, -8.179006429443975],
              [-39.894660145541209, -8.179006486442489],
              [-39.894660218039398, -8.179006544182561],
              [-39.894660289941285, -8.179006602658189],
              [-39.894660361239332, -8.179006661863225],
              [-39.89466043192612, -8.179006721791543],
              [-39.89466050199421, -8.179006782436817],
              [-39.894660571436347, -8.17900684379272],
              [-39.894660640245213, -8.179006905852841],
              [-39.894660708413653, -8.179006968610699],
              [-39.894660775934504, -8.179007032059737],
              [-39.894660842800732, -8.179007096193315],
              [-39.894660909005346, -8.179007161004716],
              [-39.894660974541402, -8.179007226487176],
              [-39.894661039402081, -8.179007292633857],
              [-39.894661103580582, -8.179007359437835],
              [-39.89466116707019, -8.179007426892127],
              [-39.89466122986429, -8.17900749498968],
              [-39.894661291956304, -8.179007563723376],
              [-39.894661353339735, -8.179007633086023],
              [-39.894661414008176, -8.17900770307039],
              [-39.894661473955296, -8.179007773669129],
              [-39.894661533174798, -8.179007844874896],
              [-39.894661591660515, -8.179007916680234],
              [-39.894661649406316, -8.179007989077597],
              [-39.894661706406175, -8.179008062059482],
              [-39.894661762654138, -8.179008135618227],
              [-39.894661818144307, -8.179008209746121],
              [-39.894661872870891, -8.179008284435469],
              [-39.894661926828164, -8.179008359678395],
              [-39.894661980010511, -8.17900843546709],
              [-39.894662032412327, -8.179008511793588],
              [-39.89466208402817, -8.179008588649928],
              [-39.894662134852631, -8.179008666028061],
              [-39.894662184880396, -8.179008743919955],
              [-39.894662234106242, -8.179008822317401],
              [-39.894662282525005, -8.179008901212244],
              [-39.894662330131652, -8.179008980596199],
              [-39.894662376921168, -8.179009060460999],
              [-39.894662422888707, -8.179009140798273],
              [-39.894662468029424, -8.179009221599649],
              [-39.894662512338613, -8.17900930285666],
              [-39.894662555811657, -8.179009384560807],
              [-39.894662598443993, -8.179009466703546],
              [-39.894662640231161, -8.179009549276328],
              [-39.894662681168803, -8.179009632270485],
              [-39.894662721252644, -8.179009715677354],
              [-39.894662760478482, -8.179009799488192],
              [-39.894662798842226, -8.17900988369426],
              [-39.894662836339862, -8.17900996828673],
              [-39.894662872967452, -8.179010053256805],
              [-39.894662908721202, -8.17901013859556],
              [-39.894662943597346, -8.179010224294101],
              [-39.894662977592162, -8.179010310343546],
              [-39.894663027774357, -8.179010368119368],
              [-39.895228162419812, -8.180462740015029],
              [-39.895228163883139, -8.180462669555826],
              [-39.895228206450511, -8.180462781119076],
              [-39.895228247536593, -8.180462893232281],
              [-39.895228287134273, -8.180463005875907],
              [-39.895228325236609, -8.180463119030369],
              [-39.895228361836985, -8.180463232675963],
              [-39.895228396929028, -8.180463346792878],
              [-39.895228430506627, -8.180463461361249],
              [-39.895228462563928, -8.180463576361126],
              [-39.895228493095352, -8.180463691772474],
              [-39.895228522095564, -8.180463807575189],
              [-39.895228549559555, -8.180463923749118],
              [-39.895228575482506, -8.180464040274035],
              [-39.895228599859927, -8.180464157129641],
              [-39.895228622687554, -8.18046427429557],
              [-39.895228643961424, -8.180464391751471],
              [-39.89522866367782, -8.180464509476844],
              [-39.895228681833323, -8.180464627451176],
              [-39.895228698424752, -8.180464745653964],
              [-39.895228713449256, -8.180464864064614],
              [-39.895228726904165, -8.180464982662459],
              [-39.895228738787182, -8.180465101426909],
              [-39.895228749096212, -8.180465220337252],
              [-39.895228757829479, -8.1804653393728],
              [-39.895228764985433, -8.180465458512787],
              [-39.895228770562852, -8.180465577736495],
              [-39.895228774560749, -8.180465697023134],
              [-39.895228776978442, -8.180465816351973],
              [-39.895228777815511, -8.180465935702223],
              [-39.8952287770718, -8.180466055053072],
              [-39.895228774747444, -8.180466174383755],
              [-39.895228770842849, -8.180466293673465],
              [-39.895228765358688, -8.180466412901497],
              [-39.895228758295914, -8.180466532047003],
              [-39.895228749655779, -8.180466651089287],
              [-39.895228739439759, -8.180466770007616],
              [-39.895228727649638, -8.180466888781233],
              [-39.895228714287491, -8.18046700738951],
              [-39.895228699355634, -8.180467125811779],
              [-39.895228682856661, -8.180467244027422],
              [-39.895228664793436, -8.180467362015822],
              [-39.895228645169112, -8.180467479756448],
              [-39.895228623987137, -8.180467597228821],
              [-39.895228601251155, -8.180467714412455],
              [-39.89522857696516, -8.180467831286967],
              [-39.895228551133357, -8.180467947831982],
              [-39.895228523760245, -8.180468064027204],
              [-39.895228494850606, -8.180468179852395],
              [-39.895228464409463, -8.180468295287415],
              [-39.895228432442131, -8.180468410312148],
              [-39.895228398954181, -8.180468524906548],
              [-39.895228363951404, -8.180468639050684],
              [-39.895228327439916, -8.180468752724636],
              [-39.8952282894261, -8.180468865908654],
              [-39.895228249916556, -8.180468978583022],
              [-39.895228208918155, -8.180469090728103],
              [-39.895228166438052, -8.180469202324355],
              [-39.895228122483651, -8.180469313352374],
              [-39.89522807706259, -8.180469423792816],
              [-39.895228030182778, -8.180469533626445],
              [-39.895227981852386, -8.180469642834137],
              [-39.895227932079834, -8.180469751396869],
              [-39.89522788087379, -8.180469859295723],
              [-39.895227828243144, -8.180469966511945],
              [-39.89522777419711, -8.180470073026829],
              [-39.895227718745055, -8.180470178821846],
              [-39.895227661896655, -8.180470283878554],
              [-39.895227603661816, -8.180470388178676],
              [-39.895227544050663, -8.180470491704058],
              [-39.895227483073597, -8.18047059443664],
              [-39.895227420741222, -8.180470696358553],
              [-39.895227357064385, -8.180470797452069],
              [-39.895227292054201, -8.180470897699523],
              [-39.895227225721975, -8.180470997083519],
              [-39.895227158079251, -8.180471095586707],
              [-39.895227089137798, -8.18047119319195],
              [-39.895227018909672, -8.180471289882265],
              [-39.895226947407039, -8.180471385640793],
              [-39.895226874642418, -8.180471480450857],
              [-39.895226800628421, -8.180471574295957],
              [-39.895226725377967, -8.180471667159745],
              [-39.895226648904163, -8.180471759026062],
              [-39.895226571220313, -8.180471849878897],
              [-39.895226492339958, -8.180471939702427],
              [-39.895226412276813, -8.180472028481015],
              [-39.895226331044853, -8.180472116199207],
              [-39.895226248658176, -8.180472202841715],
              [-39.895226165131177, -8.180472288393458],
              [-39.895226080478388, -8.180472372839548],
              [-39.895225994714529, -8.180472456165246],
              [-39.895225907854559, -8.180472538356099],
              [-39.895225819913605, -8.180472619397747],
              [-39.895225730906951, -8.180472699276081],
              [-39.895225640850143, -8.180472777977215],
              [-39.895225549758834, -8.180472855487436],
              [-39.89522545764887, -8.180472931793233],
              [-39.895225364536344, -8.180473006881304],
              [-39.895225270437429, -8.180473080738617],
              [-39.89522517536853, -8.180473153352278],
              [-39.89522507934511, -8.180473224711172],
              [-39.895225099502952, -8.180473238355894],
              [-39.893556930787021, -8.181695941047447],
              [-39.893556866464905, -8.181695936926062],
              [-39.893556682855547, -8.181696075108594],
              [-39.893556502820438, -8.181696217883635],
              [-39.893556326475242, -8.181696365159437],
              [-39.893556153933275, -8.181696516841415],
              [-39.893555985305426, -8.181696672832043],
              [-39.893555820700023, -8.181696833031154],
              [-39.893555660222837, -8.181696997335751],
              [-39.89355550397697, -8.181697165640289],
              [-39.893555352062855, -8.181697337836638],
              [-39.893555204578092, -8.181697513814132],
              [-39.89355506161742, -8.181697693459689],
              [-39.893554923272724, -8.18169787665791],
              [-39.893554789632894, -8.181698063291044],
              [-39.893554660783785, -8.181698253239189],
              [-39.893554536808203, -8.181698446380297],
              [-39.893554417785822, -8.181698642590241],
              [-39.893554303793088, -8.181698841742985],
              [-39.893554194903274, -8.181699043710537],
              [-39.893554091186324, -8.18169924836312],
              [-39.893553992708902, -8.181699455569252],
              [-39.893553899534275, -8.18169966519579],
              [-39.893553811722313, -8.181699877108034],
              [-39.89355372932944, -8.1817000911698],
              [-39.893553652408599, -8.181700307243574],
              [-39.893553581009222, -8.181700525190502],
              [-39.893553515177167, -8.181700744870536],
              [-39.893553454954748, -8.181700966142543],
              [-39.893553400380682, -8.181701188864338],
              [-39.893553351489984, -8.18170141289278],
              [-39.893553308314104, -8.181701638083952],
              [-39.893553270880794, -8.181701864293158],
              [-39.893553239214071, -8.181702091375037],
              [-39.893553213334322, -8.181702319183691],
              [-39.893553193258143, -8.181702547572696],
              [-39.893553178998452, -8.18170277639534],
              [-39.89355317056441, -8.181703005504595],
              [-39.893553167961436, -8.181703234753266],
              [-39.893553171191193, -8.181703463993971],
              [-39.893553180251629, -8.181703693079482],
              [-39.893553195136889, -8.181703921862567],
              [-39.893553215837429, -8.181704150196268],
              [-39.893553242339941, -8.181704377933789],
              [-39.893553274627429, -8.181704604928898],
              [-39.893553312679074, -8.181704831035656],
              [-39.893553356470505, -8.181705056108788],
              [-39.893553405973513, -8.181705280003726],
              [-39.893553461156365, -8.181705502576561],
              [-39.893553521983534, -8.18170572368428],
              [-39.893553588415969, -8.181705943184815],
              [-39.89355366041098, -8.181706160937148],
              [-39.893553737922318, -8.181706376801298],
              [-39.893553820900152, -8.181706590638655],
              [-39.893553909291178, -8.181706802311739],
              [-39.893554003038616, -8.181707011684598],
              [-39.893554102082199, -8.181707218622671],
              [-39.893554206358303, -8.181707422993018],
              [-39.893554315799932, -8.181707624664247],
              [-39.893554430336756, -8.181707823506827],
              [-39.893554549895164, -8.181708019393023],
              [-39.893554674398352, -8.181708212196908],
              [-39.893554803766328, -8.181708401794618],
              [-39.893554937915944, -8.181708588064318],
              [-39.893555076761025, -8.181708770886386],
              [-39.893555220212356, -8.181708950143245],
              [-39.893555368177722, -8.18170912571977],
              [-39.893555520562096, -8.18170929750314],
              [-39.893555677267528, -8.181709465382969],
              [-39.89355583819335, -8.181709629251381],
              [-39.893556003236164, -8.181709789003088],
              [-39.893556172289891, -8.181709944535452],
              [-39.893556345245941, -8.181710095748526],
              [-39.893556521993155, -8.181710242545144],
              [-39.893556702417982, -8.181710384831003],
              [-39.893556886404482, -8.181710522514651],
              [-39.89355707383443, -8.181710655507636],
              [-39.893557264587386, -8.181710783724487],
              [-39.893557458540812, -8.181710907082868],
              [-39.893557655570056, -8.18171102550345],
              [-39.893557855548522, -8.181711138910147],
              [-39.893558058347708, -8.181711247230131],
              [-39.893558263837321, -8.181711350393778],
              [-39.893558471885328, -8.1817114483348],
              [-39.893558682358019, -8.181711540990252],
              [-39.893558895120179, -8.181711628300627],
              [-39.893559110035078, -8.181711710209797],
              [-39.893559326964656, -8.181711786665144],
              [-39.893559545769492, -8.181711857617588],
              [-39.893559766309004, -8.181711923021442],
              [-39.893559988441488, -8.181711982834761],
              [-39.893560212024205, -8.181712037019077],
              [-39.893560436913482, -8.181712085539576],
              [-39.893560662964852, -8.181712128365065],
              [-39.893560890033037, -8.181712165468035],
              [-39.893561117972141, -8.181712196824682],
              [-39.8935613466357, -8.181712222414841],
              [-39.893561575876781, -8.18171224222205],
              [-39.89356180554811, -8.181712256233581],
              [-39.893562035502086, -8.181712264440462],
              [-39.893562265590951, -8.181712266837382],
              [-39.893562322960221, -8.181712299574006],
              [-39.895679187859734, -8.181707612697069],
              [-39.895679148509878, -8.181707579920836],
              [-39.895679274412956, -8.181707580511722],
              [-39.895679400295698, -8.181707582842442],
              [-39.89567952613389, -8.181707586912532],
              [-39.895679651903322, -8.181707592721169],
              [-39.895679777579787, -8.181707600267289],
              [-39.89567990313914, -8.181707609549431],
              [-39.89568002855718, -8.181707620565767],
              [-39.895680153809828, -8.18170763331424],
              [-39.895680278872959, -8.18170764779236],
              [-39.895680403722508, -8.181707663997352],
              [-39.895680528334488, -8.181707681926087],
              [-39.895680652684902, -8.181707701575142],
              [-39.895680776749828, -8.181707722940697],
              [-39.895680900505397, -8.181707746018684],
              [-39.895681023927814, -8.181707770804632],
              [-39.895681146993326, -8.181707797293779],
              [-39.895681269678271, -8.181707825481041],
              [-39.895681391959045, -8.181707855360994],
              [-39.895681513812107, -8.181707886927894],
              [-39.895681635214018, -8.181707920175628],
              [-39.89568175614145, -8.181707955097879],
              [-39.895681876571103, -8.181707991687842],
              [-39.895681996479851, -8.181708029938541],
              [-39.895682115844593, -8.181708069842578],
              [-39.895682234642358, -8.181708111392302],
              [-39.895682352850322, -8.181708154579706],
              [-39.895682470445742, -8.1817081993965],
              [-39.895682587405979, -8.181708245834036],
              [-39.895682703708552, -8.181708293883377],
              [-39.89568281933105, -8.181708343535316],
              [-39.895682934251255, -8.181708394780266],
              [-39.895683048447061, -8.181708447608393],
              [-39.895683161896514, -8.181708502009519],
              [-39.895683274577763, -8.18170855797319],
              [-39.895683386469138, -8.18170861548861],
              [-39.895683497549115, -8.181708674544762],
              [-39.895683607796329, -8.18170873513025],
              [-39.895683717189563, -8.181708797233448],
              [-39.895683825707792, -8.181708860842384],
              [-39.895683933330105, -8.181708925944836],
              [-39.895684040035825, -8.18170899252824],
              [-39.895684145804417, -8.181709060579841],
              [-39.895684250615531, -8.181709130086524],
              [-39.895684354449024, -8.181709201034911],
              [-39.895684457284879, -8.181709273411364],
              [-39.895684559103344, -8.181709347201963],
              [-39.895684659884836, -8.181709422392515],
              [-39.895684759609949, -8.181709498968544],
              [-39.895684858259514, -8.181709576915313],
              [-39.895684955814524, -8.181709656217809],
              [-39.895685052256248, -8.181709736860855],
              [-39.895685147566105, -8.181709818828828],
              [-39.895685241725779, -8.181709902106055],
              [-39.89568533471715, -8.181709986676465],
              [-39.895685426522327, -8.181710072523808],
              [-39.895685517123646, -8.181710159631546],
              [-39.895685606503676, -8.181710247982942],
              [-39.895685694645231, -8.181710337560984],
              [-39.895685781531355, -8.181710428348454],
              [-39.895685867145325, -8.181710520327874],
              [-39.895685951470682, -8.181710613481558],
              [-39.895686034491185, -8.181710707791579],
              [-39.89568611619088, -8.181710803239818],
              [-39.895686196554038, -8.181710899807911],
              [-39.895686275565211, -8.181710997477206],
              [-39.895686353209186, -8.181711096229009],
              [-39.895686429471048, -8.181711196044292],
              [-39.895686504336105, -8.181711296903844],
              [-39.895686577789959, -8.181711398788241],
              [-39.895686649818479, -8.181711501677936],
              [-39.895686720407831, -8.181711605553057],
              [-39.895686789544385, -8.181711710393701],
              [-39.895686857214891, -8.181711816179671],
              [-39.895686923406302, -8.18171192289063],
              [-39.895686988105894, -8.181712030506013],
              [-39.89568705130123, -8.181712139005114],
              [-39.895687112980134, -8.181712248367083],
              [-39.895687173130753, -8.181712358570897],
              [-39.895687231741505, -8.181712469595347],
              [-39.895687288801128, -8.181712581419065],
              [-39.895687344298629, -8.181712694020543],
              [-39.895687398223338, -8.181712807378098],
              [-39.895687450564886, -8.181712921469941],
              [-39.895687501313219, -8.181713036274136],
              [-39.89568755045854, -8.181713151768582],
              [-39.895687597991419, -8.181713267931032],
              [-39.895687643902704, -8.181713384739211],
              [-39.895687688183564, -8.181713502170622],
              [-39.895687730825479, -8.181713620202622],
              [-39.895687771820235, -8.181713738812537],
              [-39.895687811159966, -8.181713857977577],
              [-39.895687848837092, -8.181713977674827],
              [-39.895687884844378, -8.181714097881192],
              [-39.895687919174861, -8.181714218573559],
              [-39.895687951821991, -8.181714339728769],
              [-39.895687982779435, -8.181714461323498],
              [-39.895688012041276, -8.181714583334301],
              [-39.895688039601879, -8.181714705737747],
              [-39.895688065456561, -8.181714828509397],
              [-39.895688099929551, -8.181714828496155],
              [-39.895915171718094, -8.182831494582187],
              [-39.895915108375654, -8.182831454862185],
              [-39.895915117717657, -8.182831501435244],
              [-39.895915126814081, -8.18283154805656],
              [-39.895915135664666, -8.18283159472481],
              [-39.895915144269175, -8.182831641438744],
              [-39.895915152627346, -8.182831688197041],
              [-39.895915160738987, -8.182831734998421],
              [-39.89591516860385, -8.182831781841607],
              [-39.895915176221713, -8.182831828725297],
              [-39.895915183592372, -8.182831875648185],
              [-39.895915190715648, -8.182831922609001],
              [-39.895915197591293, -8.182831969606422],
              [-39.895915204219179, -8.182832016639193],
              [-39.895915210599064, -8.18283206370597],
              [-39.89591521673082, -8.182832110805496],
              [-39.895915222614256, -8.182832157936433],
              [-39.895915228249194, -8.182832205097521],
              [-39.895915233635513, -8.182832252287445],
              [-39.895915238773057, -8.182832299504874],
              [-39.895915243661655, -8.182832346748569],
              [-39.895915248301208, -8.182832394017151],
              [-39.895915252691566, -8.182832441309371],
              [-39.895915256832609, -8.182832488623907],
              [-39.895915260724252, -8.182832535959466],
              [-39.895915264366337, -8.182832583314715],
              [-39.895915267758788, -8.182832630688377],
              [-39.895915270901526, -8.182832678079148],
              [-39.895915273794451, -8.182832725485708],
              [-39.895915276437492, -8.182832772906735],
              [-39.895915278830572, -8.182832820340934],
              [-39.895915280973611, -8.182832867787008],
              [-39.895915282866554, -8.182832915243647],
              [-39.895915284509364, -8.182832962709549],
              [-39.895915285901985, -8.182833010183364],
              [-39.895915287044382, -8.182833057663835],
              [-39.895915287936518, -8.18283310514961],
              [-39.895915288578379, -8.182833152639413],
              [-39.895915288969945, -8.182833200131912],
              [-39.895915289111194, -8.182833247625819],
              [-39.895915289002133, -8.182833295119813],
              [-39.895915288642755, -8.182833342612561],
              [-39.895915288033088, -8.182833390102761],
              [-39.895915287173125, -8.182833437589141],
              [-39.895915286062895, -8.182833485070342],
              [-39.895915284702461, -8.182833532545096],
              [-39.89591528309181, -8.182833580012078],
              [-39.895915281231019, -8.182833627469979],
              [-39.895915279120132, -8.1828336749175],
              [-39.895915276759204, -8.182833722353298],
              [-39.895915274148329, -8.182833769776105],
              [-39.895915271287521, -8.182833817184575],
              [-39.8959152681769, -8.182833864577455],
              [-39.895915264816544, -8.182833911953391],
              [-39.895915261206547, -8.182833959311109],
              [-39.895915257346999, -8.182834006649273],
              [-39.895915253238016, -8.182834053966603],
              [-39.895915248879696, -8.182834101261767],
              [-39.895915244272189, -8.182834148533471],
              [-39.895915239415601, -8.182834195780446],
              [-39.895915234310067, -8.182834243001334],
              [-39.895915228955737, -8.182834290194846],
              [-39.895915223352745, -8.182834337359729],
              [-39.895915217501262, -8.182834384494624],
              [-39.895915211401423, -8.182834431598261],
              [-39.89591520505342, -8.18283447866933],
              [-39.895915198457431, -8.182834525706527],
              [-39.895915191613625, -8.182834572708581],
              [-39.895915184522188, -8.182834619674184],
              [-39.895915177183319, -8.182834666602028],
              [-39.895915169597224, -8.182834713490836],
              [-39.895915161764115, -8.182834760339301],
              [-39.895915153684193, -8.182834807146131],
              [-39.895915145357712, -8.182834853910043],
              [-39.895915136784865, -8.182834900629738],
              [-39.895915127965921, -8.182834947303961],
              [-39.895915118901094, -8.182834993931385],
              [-39.895915109590646, -8.182835040510737],
              [-39.895915100034834, -8.182835087040731],
              [-39.895915090233927, -8.182835133520092],
              [-39.895915080188189, -8.18283517994753],
              [-39.895915069897896, -8.182835226321794],
              [-39.895915059363332, -8.182835272641556],
              [-39.895915048584797, -8.182835318905564],
              [-39.895915037562581, -8.182835365112526],
              [-39.895915026296976, -8.182835411261186],
              [-39.895915014788315, -8.182835457350258],
              [-39.895915003036905, -8.182835503378497],
              [-39.895914991043057, -8.182835549344626],
              [-39.895914978807134, -8.18283559524737],
              [-39.895914966329443, -8.182835641085454],
              [-39.895914953610337, -8.182835686857635],
              [-39.895914940650179, -8.182835732562623],
              [-39.895914927449297, -8.182835778199177],
              [-39.895914914008095, -8.182835823766032],
              [-39.895914900326908, -8.182835869261938],
              [-39.895914886406139, -8.182835914685635],
              [-39.895914872246152, -8.182835960035854],
              [-39.895914857847345, -8.18283600531138],
              [-39.895914843210136, -8.182836050510952],
              [-39.895914828334675, -8.182836095634185],
              [-39.895914909697289, -8.18283610782863],
              [-39.895468117121212, -8.184179293675399],
              [-39.895468097030616, -8.184179294457547],
              [-39.895468055942793, -8.1841794211108],
              [-39.895468016730149, -8.184179548352791],
              [-39.89546797940114, -8.184179676155974],
              [-39.895467943963872, -8.184179804492674],
              [-39.895467910426014, -8.18417993333507],
              [-39.895467878794832, -8.184180062655273],
              [-39.895467849077164, -8.184180192425259],
              [-39.89546782127946, -8.184180322616955],
              [-39.895467795407747, -8.184180453202147],
              [-39.895467771467615, -8.184180584152514],
              [-39.895467749464252, -8.184180715439766],
              [-39.895467729402426, -8.184180847035401],
              [-39.895467711286486, -8.184180978910955],
              [-39.895467695120338, -8.184181111037866],
              [-39.895467680907508, -8.18418124338749],
              [-39.895467668651072, -8.184181375931193],
              [-39.895467658353645, -8.184181508640249],
              [-39.895467650017515, -8.184181641485928],
              [-39.895467643644444, -8.184181774439452],
              [-39.895467639235818, -8.184181907472029],
              [-39.895467636792624, -8.18418204055483],
              [-39.89546763631536, -8.184182173659051],
              [-39.895467637804153, -8.184182306755845],
              [-39.895467641258648, -8.184182439816421],
              [-39.895467646678128, -8.184182572811919],
              [-39.895467654061392, -8.184182705713553],
              [-39.895467663406855, -8.184182838492536],
              [-39.895467674712499, -8.184182971120109],
              [-39.895467687975874, -8.184183103567531],
              [-39.895467703194093, -8.184183235806152],
              [-39.895467720363875, -8.184183367807309],
              [-39.895467739481504, -8.184183499542415],
              [-39.895467760542815, -8.184183630982934],
              [-39.895467783543268, -8.1841837621004],
              [-39.895467808477882, -8.184183892866423],
              [-39.89546783534125, -8.18418402325268],
              [-39.895467864127546, -8.184184153230923],
              [-39.895467894830546, -8.184184282772993],
              [-39.895467927443605, -8.184184411850856],
              [-39.895467961959646, -8.184184540436551],
              [-39.89546799837121, -8.184184668502208],
              [-39.895468036670394, -8.184184796020107],
              [-39.895468076848907, -8.184184922962627],
              [-39.895468118898044, -8.18418504930229],
              [-39.895468162808719, -8.184185175011709],
              [-39.895468208571401, -8.18418530006365],
              [-39.895468256176187, -8.184185424431055],
              [-39.895468305612752, -8.184185548086981],
              [-39.895468356870381, -8.184185671004629],
              [-39.89546840993804, -8.184185793157397],
              [-39.895468464804154, -8.184185914518817],
              [-39.895468521456884, -8.184186035062607],
              [-39.895468579883939, -8.184186154762651],
              [-39.895468640072686, -8.184186273593046],
              [-39.895468702010078, -8.18418639152806],
              [-39.895468765682672, -8.184186508542108],
              [-39.895468831076734, -8.184186624609893],
              [-39.895468898178059, -8.184186739706208],
              [-39.895468966972132, -8.184186853806194],
              [-39.895469037444037, -8.184186966885115],
              [-39.895469109578535, -8.184187078918479],
              [-39.895469183359978, -8.184187189882026],
              [-39.895469258772408, -8.184187299751715],
              [-39.895469335799469, -8.184187408503766],
              [-39.895469414424504, -8.184187516114596],
              [-39.895469494630468, -8.184187622560925],
              [-39.895469576399975, -8.184187727819682],
              [-39.895469659715346, -8.184187831868075],
              [-39.895469744558525, -8.184187934683587],
              [-39.895469830911125, -8.184188036243942],
              [-39.895469918754458, -8.184188136527144],
              [-39.895470008069481, -8.18418823551141],
              [-39.89547009883686, -8.184188333175403],
              [-39.895470191036942, -8.18418842949791],
              [-39.895470284649768, -8.18418852445806],
              [-39.895470379655038, -8.184188618035328],
              [-39.89547047603218, -8.184188710209435],
              [-39.895470573760321, -8.184188800960387],
              [-39.895470672818306, -8.184188890268555],
              [-39.895470773184677, -8.184188978114593],
              [-39.895470874837685, -8.184189064479483],
              [-39.89547097775533, -8.184189149344498],
              [-39.895471081915311, -8.184189232691283],
              [-39.895471187295065, -8.18418931450179],
              [-39.895471293871772, -8.184189394758263],
              [-39.895471401622345, -8.184189473443338],
              [-39.895471510523478, -8.184189550539978],
              [-39.895471620551533, -8.1841896260315],
              [-39.895471731682719, -8.184189699901504],
              [-39.895471843892949, -8.184189772134042],
              [-39.895471957157923, -8.184189842713455],
              [-39.895472071453113, -8.184189911624459],
              [-39.895472186753771, -8.18418997885207],
              [-39.895472303034907, -8.184190044381804],
              [-39.895472420271346, -8.184190108199456],
              [-39.895472538437701, -8.184190170291183],
              [-39.895472657508371, -8.184190230643546],
              [-39.895472777457563, -8.184190289243471],
              [-39.8954728982593, -8.184190346078317],
              [-39.895472953048554, -8.184190407619166],
              [-39.897165760209703, -8.184971708647478],
              [-39.897165700392691, -8.184971644539269],
              [-39.897165713556525, -8.184971650602161],
              [-39.897165726730059, -8.184971656644114],
              [-39.897165739913248, -8.184971662665067],
              [-39.89716575310608, -8.184971668665044],
              [-39.897165766308532, -8.18497167464399],
              [-39.897165779520542, -8.184971680601942],
              [-39.897165792742086, -8.18497168653883],
              [-39.897165805973138, -8.18497169245469],
              [-39.897165819213654, -8.184971698349472],
              [-39.897165832463614, -8.184971704223193],
              [-39.897165845722974, -8.184971710075782],
              [-39.897165858991691, -8.184971715907276],
              [-39.897165872269746, -8.184971721717627],
              [-39.897165885557108, -8.184971727506841],
              [-39.897165898853736, -8.184971733274898],
              [-39.897165912159593, -8.18497173902179],
              [-39.897165925474638, -8.184971744747484],
              [-39.897165938798871, -8.184971750451984],
              [-39.897165952132205, -8.184971756135253],
              [-39.897165965474649, -8.184971761797312],
              [-39.897165978826152, -8.184971767438105],
              [-39.897165992186686, -8.184971773057669],
              [-39.897166005556208, -8.184971778655934],
              [-39.897166018934698, -8.184971784232919],
              [-39.897166032322104, -8.184971789788609],
              [-39.897166045718407, -8.184971795322967],
              [-39.897166059123563, -8.18497180083601],
              [-39.897166072537537, -8.184971806327693],
              [-39.897166085960315, -8.184971811798023],
              [-39.897166099391832, -8.184971817246995],
              [-39.897166112832068, -8.184971822674584],
              [-39.897166126281007, -8.184971828080741],
              [-39.89716613973858, -8.184971833465482],
              [-39.897166153204793, -8.184971838828812],
              [-39.897166166679568, -8.184971844170708],
              [-39.897166180162891, -8.184971849491141],
              [-39.897166193654741, -8.184971854790112],
              [-39.89716620715506, -8.184971860067581],
              [-39.897166220663834, -8.18497186532357],
              [-39.897166234181, -8.184971870558044],
              [-39.897166247706558, -8.184971875771007],
              [-39.89716626124045, -8.184971880962419],
              [-39.897166274782641, -8.184971886132281],
              [-39.897166288333118, -8.184971891280577],
              [-39.897166301891829, -8.184971896407312],
              [-39.897166315458747, -8.184971901512446],
              [-39.897166329033837, -8.18497190659598],
              [-39.897166342617048, -8.184971911657886],
              [-39.897166356208373, -8.184971916698188],
              [-39.897166369807742, -8.184971921716828],
              [-39.897166383415154, -8.184971926713837],
              [-39.89716639703056, -8.184971931689164],
              [-39.897166410653924, -8.184971936642807],
              [-39.897166424285217, -8.184971941574785],
              [-39.897166437924405, -8.184971946485044],
              [-39.897166451571444, -8.184971951373575],
              [-39.897166465226292, -8.184971956240389],
              [-39.897166478888948, -8.184971961085466],
              [-39.897166492559336, -8.184971965908785],
              [-39.897166506237454, -8.184971970710349],
              [-39.897166519923246, -8.1849719754901],
              [-39.897166533616684, -8.184971980248099],
              [-39.897166547317731, -8.184971984984287],
              [-39.897166561026353, -8.184971989698639],
              [-39.897166574742535, -8.184971994391171],
              [-39.89716658846622, -8.184971999061876],
              [-39.897166602197366, -8.18497200371071],
              [-39.897166615935959, -8.184972008337713],
              [-39.897166629681934, -8.184972012942817],
              [-39.897166643435298, -8.184972017526036],
              [-39.897166657195982, -8.18497202208739],
              [-39.897166670963962, -8.184972026626808],
              [-39.897166684739211, -8.184972031144296],
              [-39.897166698521673, -8.184972035639863],
              [-39.897166712311339, -8.184972040113486],
              [-39.897166726108146, -8.184972044565137],
              [-39.897166739912095, -8.184972048994858],
              [-39.897166753723106, -8.184972053402578],
              [-39.897166767541179, -8.184972057788297],
              [-39.897166781366259, -8.184972062152031],
              [-39.897166795198331, -8.184972066493749],
              [-39.897166809037337, -8.184972070813437],
              [-39.897166822883257, -8.184972075111119],
              [-39.897166836736055, -8.184972079386753],
              [-39.897166850595681, -8.184972083640288],
              [-39.897166864462115, -8.184972087871788],
              [-39.897166878335312, -8.184972092081205],
              [-39.897166892215246, -8.184972096268517],
              [-39.897166906101866, -8.18497210043375],
              [-39.897166919995151, -8.184972104576863],
              [-39.897166933895079, -8.184972108697876],
              [-39.89716694780158, -8.184972112796739],
              [-39.897166961714639, -8.184972116873485],
              [-39.897166975634221, -8.184972120928062],
              [-39.897166989560283, -8.184972124960488],
              [-39.89716700349279, -8.184972128970733],
              [-39.897167017431705, -8.184972132958791],
              [-39.897167031376995, -8.184972136924666],
              [-39.897167045328644, -8.184972140868338],
              [-39.897167122851471, -8.184972157842713],
              [-39.898746529452822, -8.185417250465994],
              [-39.898746525485706, -8.185417244720446],
              [-39.898746639348566, -8.185417277608744],
              [-39.898746752770634, -8.185417311975801],
              [-39.898746865732583, -8.185417347815743],
              [-39.898746978215115, -8.185417385122481],
              [-39.898747090199073, -8.185417423889623],
              [-39.898747201665344, -8.185417464110595],
              [-39.898747312594907, -8.18541750577849],
              [-39.898747422968867, -8.18541754888626],
              [-39.898747532768382, -8.185417593426507],
              [-39.898747641974737, -8.185417639391657],
              [-39.898747750569271, -8.185417686773842],
              [-39.898747858533497, -8.185417735565007],
              [-39.898747965849005, -8.185417785756824],
              [-39.898748072497483, -8.185417837340712],
              [-39.898748178460728, -8.185417890307908],
              [-39.898748283720671, -8.185417944649361],
              [-39.898748388259357, -8.185418000355789],
              [-39.898748492058978, -8.185418057417698],
              [-39.898748595101807, -8.185418115825371],
              [-39.898748697370287, -8.185418175568824],
              [-39.898748798846967, -8.185418236637904],
              [-39.898748899514537, -8.185418299022155],
              [-39.89874899935581, -8.185418362710971],
              [-39.898749098353804, -8.185418427693469],
              [-39.898749196491579, -8.185418493958569],
              [-39.898749293752438, -8.185418561494952],
              [-39.898749390119775, -8.185418630291135],
              [-39.898749485577163, -8.185418700335362],
              [-39.898749580108301, -8.185418771615671],
              [-39.898749673697083, -8.185418844119955],
              [-39.898749766327562, -8.185418917835802],
              [-39.898749857983908, -8.185418992750638],
              [-39.89874994865049, -8.185419068851697],
              [-39.898750038311867, -8.185419146126018],
              [-39.898750126952741, -8.185419224560416],
              [-39.898750214557978, -8.185419304141501],
              [-39.898750301112649, -8.185419384855695],
              [-39.898750386601989, -8.185419466689217],
              [-39.898750471011397, -8.185419549628152],
              [-39.898750554326519, -8.185419633658325],
              [-39.898750636533116, -8.185419718765422],
              [-39.898750717617169, -8.185419804934915],
              [-39.898750797564851, -8.185419892152106],
              [-39.898750876362527, -8.185419980402138],
              [-39.89875095399676, -8.185420069669931],
              [-39.898751030454306, -8.185420159940284],
              [-39.898751105722127, -8.185420251197764],
              [-39.898751179787382, -8.185420343426856],
              [-39.898751252637439, -8.185420436611812],
              [-39.898751324259877, -8.185420530736694],
              [-39.898751394642474, -8.185420625785531],
              [-39.898751463773223, -8.185420721742016],
              [-39.89875153164035, -8.185420818589893],
              [-39.898751598232259, -8.185420916312554],
              [-39.89875166353761, -8.185421014893391],
              [-39.898751727545267, -8.185421114315517],
              [-39.898751790244269, -8.185421214562036],
              [-39.898751851623977, -8.185421315615834],
              [-39.898751911673905, -8.185421417459672],
              [-39.898751970383799, -8.185421520076194],
              [-39.898752027743662, -8.185421623447896],
              [-39.898752083743688, -8.18542172755712],
              [-39.898752138374341, -8.185421832386156],
              [-39.898752191626329, -8.18542193791707],
              [-39.898752243490513, -8.185422044131892],
              [-39.89875229395809, -8.185422151012512],
              [-39.89875234302044, -8.185422258540676],
              [-39.898752390669195, -8.185422366698079],
              [-39.898752436896217, -8.185422475466252],
              [-39.898752481693634, -8.185422584826629],
              [-39.898752525053816, -8.185422694760589],
              [-39.898752566969335, -8.185422805249368],
              [-39.898752607433082, -8.185422916274121],
              [-39.898752646438133, -8.185423027815933],
              [-39.898752683977833, -8.185423139855743],
              [-39.898752720045785, -8.185423252374468],
              [-39.898752754635836, -8.185423365352872],
              [-39.89875278774209, -8.185423478771744],
              [-39.89875281935889, -8.185423592611709],
              [-39.898752849480857, -8.185423706853367],
              [-39.89875287810284, -8.185423821477244],
              [-39.898752905219979, -8.185423936463732],
              [-39.898752930827619, -8.185424051793262],
              [-39.898752954921427, -8.185424167446156],
              [-39.898752977497253, -8.185424283402721],
              [-39.898752998551288, -8.185424399643116],
              [-39.898753018079915, -8.185424516147584],
              [-39.89875303607981, -8.185424632896211],
              [-39.898753052547896, -8.185424749869069],
              [-39.898753067481366, -8.185424867046253],
              [-39.898753080877697, -8.18542498440776],
              [-39.898753092734566, -8.185425101933557],
              [-39.898753103049977, -8.185425219603605],
              [-39.898753111822174, -8.185425337397838],
              [-39.898753119049637, -8.185425455296189],
              [-39.898753124731151, -8.185425573278522],
              [-39.898753128865735, -8.185425691324692],
              [-39.8987531314527, -8.185425809414619],
              [-39.898753132491585, -8.185425927528209],
              [-39.898753175519296, -8.185425918333456],
              [-39.898757434396195, -8.187325601695839],
              [-39.898757391274188, -8.187325568928413],
              [-39.898757390453312, -8.187325712428885],
              [-39.898757387347409, -8.187325855898312],
              [-39.898757381957275, -8.187325999300599],
              [-39.898757374284244, -8.187326142599614],
              [-39.898757364330258, -8.187326285759298],
              [-39.898757352097824, -8.187326428743592],
              [-39.898757337590027, -8.187326571516541],
              [-39.898757320810496, -8.187326714042184],
              [-39.898757301763489, -8.187326856284633],
              [-39.898757280453772, -8.187326998208112],
              [-39.898757256886718, -8.187327139776837],
              [-39.898757231068274, -8.187327280955186],
              [-39.898757203004919, -8.187327421707636],
              [-39.898757172703732, -8.187327561998755],
              [-39.898757140172329, -8.187327701793215],
              [-39.898757105418923, -8.187327841055808],
              [-39.898757068452227, -8.187327979751492],
              [-39.898757029281583, -8.187328117845354],
              [-39.898756987916826, -8.187328255302637],
              [-39.89875694436838, -8.187328392088725],
              [-39.8987568986472, -8.187328528169186],
              [-39.898756850764826, -8.18732866350976],
              [-39.898756800733274, -8.187328798076367],
              [-39.898756748565155, -8.187328931835157],
              [-39.898756694273601, -8.187329064752426],
              [-39.898756637872289, -8.187329196794714],
              [-39.898756579375394, -8.187329327928806],
              [-39.898756518797683, -8.187329458121667],
              [-39.898756456154359, -8.187329587340544],
              [-39.89875639146122, -8.187329715552874],
              [-39.898756324734542, -8.18732984272641],
              [-39.898756255991124, -8.187329968829127],
              [-39.898756185248281, -8.187330093829289],
              [-39.898756112523813, -8.187330217695397],
              [-39.898756037836044, -8.18733034039632],
              [-39.898755961203747, -8.187330461901107],
              [-39.898755882646235, -8.187330582179221],
              [-39.898755802183267, -8.187330701200363],
              [-39.898755719835137, -8.187330818934571],
              [-39.898755635622535, -8.187330935352181],
              [-39.898755549566673, -8.187331050423932],
              [-39.898755461689234, -8.187331164120835],
              [-39.89875537201231, -8.187331276414248],
              [-39.89875528055849, -8.187331387275929],
              [-39.898755187350801, -8.187331496677954],
              [-39.898755092412721, -8.187331604592782],
              [-39.898754995768108, -8.187331710993286],
              [-39.898754897441336, -8.187331815852593],
              [-39.898754797457137, -8.187331919144365],
              [-39.898754695840694, -8.187332020842607],
              [-39.89875459261755, -8.187332120921679],
              [-39.898754487813747, -8.187332219356433],
              [-39.898754381455639, -8.187332316122045],
              [-39.898754273569999, -8.187332411194191],
              [-39.898754164183991, -8.187332504548905],
              [-39.898754053325149, -8.187332596162715],
              [-39.898753941021383, -8.187332686012526],
              [-39.898753827300979, -8.187332774075749],
              [-39.898753712192544, -8.187332860330207],
              [-39.898753595725069, -8.187332944754186],
              [-39.898753477927876, -8.187333027326403],
              [-39.898753358830611, -8.187333108026081],
              [-39.898753238463257, -8.187333186832932],
              [-39.898753116856106, -8.187333263727115],
              [-39.898752994039803, -8.187333338689267],
              [-39.898752870045236, -8.187333411700489],
              [-39.898752744903639, -8.187333482742414],
              [-39.898752618646505, -8.187333551797185],
              [-39.898752491305622, -8.187333618847406],
              [-39.898752362913051, -8.187333683876169],
              [-39.898752233501106, -8.187333746867139],
              [-39.898752103102368, -8.187333807804443],
              [-39.898751971749668, -8.187333866672725],
              [-39.89875183947607, -8.187333923457173],
              [-39.89875170631489, -8.187333978143537],
              [-39.898751572299609, -8.187334030717979],
              [-39.898751437464007, -8.187334081167313],
              [-39.898751301842019, -8.187334129478803],
              [-39.898751165467758, -8.187334175640336],
              [-39.898751028375592, -8.18733421964024],
              [-39.89875089060002, -8.187334261467457],
              [-39.898750752175722, -8.187334301111481],
              [-39.898750613137544, -8.187334338562291],
              [-39.898750473520501, -8.187334373810499],
              [-39.898750333359736, -8.187334406847215],
              [-39.898750192690521, -8.187334437664102],
              [-39.898750051548276, -8.18733446625342],
              [-39.898749909968544, -8.187334492607977],
              [-39.898749767986942, -8.187334516721108],
              [-39.898749625639262, -8.187334538586789],
              [-39.898749482961271, -8.187334558199497],
              [-39.898749339988946, -8.187334575554265],
              [-39.898749196758246, -8.18733459064677],
              [-39.898749053305231, -8.187334603473195],
              [-39.898748909666047, -8.187334614030302],
              [-39.89874876587681, -8.187334622315433],
              [-39.898748621973731, -8.187334628326509],
              [-39.898748477993067, -8.187334632062031],
              [-39.89874833397085, -8.187334633521163],
              [-39.898748377092872, -8.187334666288622],
              [-39.897063758973701, -8.187338412345806],
              [-39.897063723306701, -8.187338379562867],
              [-39.897063625447323, -8.187338380305826],
              [-39.897063527601667, -8.187338382099876],
              [-39.897063429781127, -8.187338384944786],
              [-39.897063331997046, -8.187338388840216],
              [-39.89706323426077, -8.187338393785737],
              [-39.897063136583711, -8.187338399780762],
              [-39.897063038977187, -8.187338406824574],
              [-39.89706294145256, -8.18733841491637],
              [-39.897062844021129, -8.187338424055197],
              [-39.897062746694267, -8.187338434240031],
              [-39.897062649483239, -8.187338445469663],
              [-39.89706255239939, -8.187338457742788],
              [-39.897062455453955, -8.187338471057982],
              [-39.897062358658239, -8.187338485413708],
              [-39.897062262023468, -8.187338500808265],
              [-39.897062165560897, -8.187338517239887],
              [-39.897062069281702, -8.187338534706688],
              [-39.897061973197104, -8.187338553206589],
              [-39.897061877318265, -8.187338572737477],
              [-39.897061781656298, -8.187338593297055],
              [-39.897061686222372, -8.187338614882956],
              [-39.897061591027544, -8.187338637492672],
              [-39.897061496082884, -8.187338661123547],
              [-39.897061401399426, -8.187338685772863],
              [-39.897061306988178, -8.187338711437761],
              [-39.897061212860109, -8.187338738115209],
              [-39.897061119026162, -8.187338765802171],
              [-39.897061025497244, -8.187338794495373],
              [-39.897060932284226, -8.187338824191539],
              [-39.897060839397916, -8.187338854887148],
              [-39.897060746849156, -8.187338886578674],
              [-39.897060654648669, -8.18733891926242],
              [-39.897060562807177, -8.187338952934613],
              [-39.897060471335351, -8.187338987591319],
              [-39.897060380243836, -8.187339023228496],
              [-39.897060289543205, -8.187339059842063],
              [-39.897060199244009, -8.187339097427694],
              [-39.897060109356744, -8.187339135981054],
              [-39.897060019891839, -8.187339175497646],
              [-39.897059930859697, -8.187339215972891],
              [-39.897059842270679, -8.187339257402096],
              [-39.897059754135086, -8.187339299780403],
              [-39.897059666463136, -8.187339343102934],
              [-39.89705957926504, -8.187339387364638],
              [-39.897059492550916, -8.187339432560359],
              [-39.897059406330847, -8.187339478684883],
              [-39.897059320614865, -8.187339525732805],
              [-39.897059235412932, -8.187339573698658],
              [-39.897059150734925, -8.187339622576888],
              [-39.897059066590707, -8.187339672361787],
              [-39.897058982990053, -8.187339723047613],
              [-39.897058899942685, -8.187339774628455],
              [-39.897058817458237, -8.187339827098286],
              [-39.897058735546324, -8.187339880451072],
              [-39.89705865421643, -8.187339934680566],
              [-39.897058573478034, -8.187339989780449],
              [-39.897058493340523, -8.187340045744376],
              [-39.897058413813212, -8.187340102565811],
              [-39.897058334905296, -8.187340160238136],
              [-39.897058256625996, -8.18734021875466],
              [-39.897058178984395, -8.187340278108604],
              [-39.897058101989515, -8.187340338293042],
              [-39.897058025650317, -8.187340399300981],
              [-39.897057949975654, -8.18734046112534],
              [-39.89705787497433, -8.187340523758941],
              [-39.897057800655062, -8.187340587194473],
              [-39.897057727026493, -8.187340651424599],
              [-39.897057654097175, -8.187340716441845],
              [-39.897057581875558, -8.187340782238651],
              [-39.89705751037009, -8.187340848807349],
              [-39.897057439589048, -8.187340916140238],
              [-39.897057369540654, -8.187340984229461],
              [-39.897057300233065, -8.187341053067124],
              [-39.89705723167431, -8.187341122645254],
              [-39.897057163872404, -8.187341192955703],
              [-39.897057096835184, -8.187341263990334],
              [-39.897057030570451, -8.187341335740893],
              [-39.89705696508593, -8.187341408199039],
              [-39.897056900389188, -8.187341481356318],
              [-39.897056836487799, -8.187341555204272],
              [-39.897056773389139, -8.187341629734311],
              [-39.897056711100568, -8.187341704937756],
              [-39.897056649629306, -8.187341780805868],
              [-39.897056588982544, -8.187341857329837],
              [-39.897056529167287, -8.187341934500768],
              [-39.897056470190478, -8.187342012309699],
              [-39.897056412059001, -8.187342090747588],
              [-39.8970563547796, -8.187342169805286],
              [-39.897056298358947, -8.187342249473666],
              [-39.897056242803565, -8.187342329743414],
              [-39.897056188119961, -8.187342410605199],
              [-39.897056134314433, -8.187342492049677],
              [-39.897056081393274, -8.187342574067328],
              [-39.897056029362631, -8.187342656648655],
              [-39.897055978228543, -8.187342739784059],
              [-39.897055927996938, -8.187342823463826],
              [-39.897055878673683, -8.187342907678287],
              [-39.897055830264492, -8.187342992417626],
              [-39.897055782774999, -8.187343077672026],
              [-39.897055781222612, -8.187343133176448],
              [-39.895820524379815, -8.189589167893672],
              [-39.895820512317101, -8.189589104018184],
              [-39.895820458426691, -8.189589203473254],
              [-39.895820405787859, -8.189589303591525],
              [-39.895820354408855, -8.189589404357358],
              [-39.895820304297679, -8.189589505755],
              [-39.895820255462148, -8.189589607768637],
              [-39.895820207909921, -8.189589710382331],
              [-39.895820161648381, -8.189589813580058],
              [-39.895820116684803, -8.189589917345714],
              [-39.895820073026158, -8.189590021663061],
              [-39.895820030679289, -8.189590126515846],
              [-39.895819989650811, -8.189590231887678],
              [-39.895819949947125, -8.189590337762132],
              [-39.895819911574435, -8.189590444122619],
              [-39.895819874538731, -8.189590550952582],
              [-39.89581983884581, -8.189590658235314],
              [-39.895819804501215, -8.189590765954044],
              [-39.895819771510332, -8.189590874091989],
              [-39.895819739878291, -8.189590982632231],
              [-39.89581970961008, -8.189591091557826],
              [-39.895819680710382, -8.189591200851764],
              [-39.895819653183715, -8.189591310497006],
              [-39.895819627034399, -8.18959142047639],
              [-39.895819602266485, -8.189591530772775],
              [-39.895819578883881, -8.189591641368899],
              [-39.895819556890203, -8.189591752247543],
              [-39.895819536288897, -8.189591863391346],
              [-39.895819517083197, -8.189591974782964],
              [-39.895819499276072, -8.189592086405],
              [-39.895819482870323, -8.189592198240035],
              [-39.895819467868513, -8.189592310270591],
              [-39.895819454272974, -8.189592422479178],
              [-39.895819442085838, -8.189592534848284],
              [-39.895819431309, -8.189592647360339],
              [-39.895819421944161, -8.189592759997803],
              [-39.895819413992754, -8.189592872743042],
              [-39.895819407456045, -8.189592985578473],
              [-39.895819402335043, -8.189593098486501],
              [-39.895819398630543, -8.189593211449461],
              [-39.895819396343143, -8.189593324449737],
              [-39.89581939547319, -8.189593437469664],
              [-39.895819396020812, -8.18959355049158],
              [-39.895819397985932, -8.189593663497869],
              [-39.895819401368243, -8.189593776470858],
              [-39.895819406167234, -8.189593889392908],
              [-39.895819412382103, -8.189594002246402],
              [-39.895819420011925, -8.189594115013701],
              [-39.895819429055486, -8.18959422767719],
              [-39.895819439511406, -8.189594340219315],
              [-39.895819451378017, -8.189594452622458],
              [-39.895819464653485, -8.189594564869092],
              [-39.895819479335728, -8.189594676941654],
              [-39.895819495422472, -8.189594788822696],
              [-39.895819512911167, -8.189594900494674],
              [-39.895819531799113, -8.189595011940193],
              [-39.895819552083353, -8.189595123141865],
              [-39.895819573760718, -8.189595234082304],
              [-39.89581959682782, -8.189595344744198],
              [-39.895819621281063, -8.189595455110247],
              [-39.895819647116625, -8.189595565163231],
              [-39.895819674330468, -8.189595674885966],
              [-39.895819702918338, -8.189595784261293],
              [-39.8958197328758, -8.189595893272152],
              [-39.895819764198137, -8.18959600190151],
              [-39.895819796880481, -8.189596110132429],
              [-39.895819830917723, -8.189596217947972],
              [-39.895819866304549, -8.189596325331305],
              [-39.895819903035431, -8.189596432265676],
              [-39.895819941104627, -8.189596538734385],
              [-39.895819980506197, -8.189596644720812],
              [-39.895820021233995, -8.189596750208391],
              [-39.895820063281647, -8.189596855180662],
              [-39.89582010664261, -8.189596959621205],
              [-39.89582015131009, -8.189597063513746],
              [-39.895820197277111, -8.189597166842024],
              [-39.895820244536502, -8.189597269589937],
              [-39.895820293080895, -8.189597371741414],
              [-39.895820342902695, -8.189597473280529],
              [-39.895820393994121, -8.189597574191419],
              [-39.895820446347187, -8.189597674458293],
              [-39.89582049995375, -8.189597774065543],
              [-39.895820554805404, -8.189597872997568],
              [-39.895820610893608, -8.189597971238936],
              [-39.895820668209566, -8.189598068774323],
              [-39.89582072674439, -8.189598165588485],
              [-39.895820786488891, -8.189598261666314],
              [-39.895820847433733, -8.189598356992786],
              [-39.895820909569423, -8.189598451553048],
              [-39.895820972886241, -8.189598545332275],
              [-39.895821037374333, -8.189598638315838],
              [-39.895821103023593, -8.189598730489257],
              [-39.895821169823776, -8.189598821838123],
              [-39.895821237764451, -8.189598912348185],
              [-39.895821306835025, -8.189599002005245],
              [-39.895821377024689, -8.189599090795356],
              [-39.895821448322501, -8.18959917870465],
              [-39.895821520717313, -8.189599265719385],
              [-39.895821594197834, -8.18959935182597],
              [-39.895821668752575, -8.18959943701098],
              [-39.89582174436989, -8.189599521261091],
              [-39.895821818500657, -8.189599566127002],
              [-39.896837570879882, -8.190717102466726],
              [-39.89683750716884, -8.190717110716735],
              [-39.896837545089546, -8.190717152179515],
              [-39.896837583267619, -8.190717193407187],
              [-39.8968376217016, -8.190717234398182],
              [-39.896837660390005, -8.190717275150845],
              [-39.89683769933135, -8.190717315663687],
              [-39.896837738524134, -8.190717355935103],
              [-39.896837777966844, -8.190717395963556],
              [-39.896837817657982, -8.190717435747505],
              [-39.896837857596019, -8.190717475285451],
              [-39.896837897779378, -8.190717514575816],
              [-39.896837938206566, -8.190717553617104],
              [-39.896837978876007, -8.190717592407848],
              [-39.896838019786117, -8.190717630946539],
              [-39.896838060935373, -8.190717669231647],
              [-39.896838102322121, -8.190717707261783],
              [-39.896838143944841, -8.190717745035423],
              [-39.896838185801883, -8.190717782551165],
              [-39.896838227891649, -8.190717819807505],
              [-39.896838270212527, -8.190717856803058],
              [-39.896838312762895, -8.190717893536354],
              [-39.896838355541092, -8.190717930006038],
              [-39.896838398545498, -8.190717966210658],
              [-39.896838441774435, -8.19071800214884],
              [-39.896838485226269, -8.190718037819227],
              [-39.896838528899309, -8.190718073220399],
              [-39.896838572791879, -8.190718108351037],
              [-39.896838616902293, -8.190718143209743],
              [-39.896838661228848, -8.190718177795207],
              [-39.896838705769838, -8.190718212106116],
              [-39.896838750523557, -8.190718246141097],
              [-39.896838795488293, -8.190718279898906],
              [-39.896838840662291, -8.1907183133782],
              [-39.896838886043824, -8.19071834657773],
              [-39.896838931631144, -8.190718379496161],
              [-39.896838977422512, -8.190718412132261],
              [-39.896839023416156, -8.190718444484753],
              [-39.896839069610316, -8.190718476552433],
              [-39.896839116003186, -8.190718508334058],
              [-39.896839162593018, -8.190718539828381],
              [-39.896839209378001, -8.190718571034177],
              [-39.896839256356337, -8.190718601950307],
              [-39.896839303526221, -8.190718632575562],
              [-39.896839350885834, -8.190718662908711],
              [-39.896839398433364, -8.190718692948638],
              [-39.896839446166979, -8.190718722694145],
              [-39.896839494084823, -8.190718752144141],
              [-39.896839542185084, -8.19071878129745],
              [-39.896839590465895, -8.190718810152987],
              [-39.896839638925407, -8.190718838709603],
              [-39.89683968756173, -8.190718866966213],
              [-39.896839736373018, -8.190718894921762],
              [-39.896839785357407, -8.190718922575133],
              [-39.896839834512988, -8.190718949925275],
              [-39.896839883837863, -8.190718976971128],
              [-39.896839933330156, -8.19071900371169],
              [-39.896839982987956, -8.190719030145901],
              [-39.896840032809351, -8.190719056272753],
              [-39.89684008279243, -8.190719082091247],
              [-39.89684013293526, -8.190719107600362],
              [-39.896840183235923, -8.190719132799154],
              [-39.896840233692465, -8.190719157686631],
              [-39.896840284302975, -8.190719182261834],
              [-39.896840335065498, -8.190719206523825],
              [-39.896840385978059, -8.19071923047167],
              [-39.896840437038719, -8.19071925410444],
              [-39.896840488245495, -8.190719277421234],
              [-39.896840539596454, -8.190719300421126],
              [-39.896840591089592, -8.190719323103281],
              [-39.896840642722928, -8.19071934546681],
              [-39.896840694494479, -8.190719367510839],
              [-39.896840746402269, -8.190719389234518],
              [-39.896840798444273, -8.190719410637039],
              [-39.896840850618517, -8.190719431717564],
              [-39.896840902922989, -8.190719452475262],
              [-39.896840955355664, -8.190719472909386],
              [-39.896841007914524, -8.190719493019085],
              [-39.896841060597559, -8.190719512803627],
              [-39.896841113402743, -8.190719532262236],
              [-39.896841166328038, -8.190719551394176],
              [-39.896841219371403, -8.190719570198677],
              [-39.896841272530814, -8.190719588675071],
              [-39.89684132580421, -8.190719606822604],
              [-39.896841379189546, -8.190719624640574],
              [-39.896841432684788, -8.190719642128359],
              [-39.89684148628784, -8.190719659285174],
              [-39.896841539996672, -8.190719676110485],
              [-39.896841593809199, -8.190719692603537],
              [-39.89684164772337, -8.19071970876378],
              [-39.896841701737088, -8.190719724590561],
              [-39.896841755848314, -8.190719740083246],
              [-39.896841810054902, -8.190719755241245],
              [-39.896841864354826, -8.190719770064032],
              [-39.896841918745984, -8.19071978455098],
              [-39.896841973226266, -8.190719798701524],
              [-39.896842027793596, -8.190719812515145],
              [-39.896842082445851, -8.19071982599133],
              [-39.89684213718094, -8.190719839129489],
              [-39.896842191996768, -8.190719851929227],
              [-39.896842246891232, -8.190719864389971],
              [-39.896842297540708, -8.190719895739679],
              [-39.899330293786591, -8.191276561675259],
              [-39.899330211018039, -8.191276529100033],
              [-39.899330258357168, -8.19127653982425],
              [-39.899330305638067, -8.191276550800563],
              [-39.899330352859359, -8.1912765620286],
              [-39.899330400019707, -8.191276573508135],
              [-39.899330447117762, -8.191276585238775],
              [-39.899330494152196, -8.191276597220197],
              [-39.899330541121621, -8.191276609452084],
              [-39.899330588024732, -8.191276621934023],
              [-39.899330634860164, -8.191276634665739],
              [-39.899330681626608, -8.191276647646825],
              [-39.899330728322688, -8.19127666087692],
              [-39.899330774947074, -8.191276674355629],
              [-39.899330821498474, -8.191276688082583],
              [-39.899330867975507, -8.191276702057362],
              [-39.899330914376868, -8.191276716279615],
              [-39.899330960701228, -8.191276730748857],
              [-39.899331006947257, -8.191276745464775],
              [-39.89933105311362, -8.191276760426861],
              [-39.899331099199038, -8.191276775634767],
              [-39.899331145202147, -8.191276791087988],
              [-39.899331191121661, -8.191276806786092],
              [-39.899331236956264, -8.191276822728659],
              [-39.89933128270463, -8.191276838915254],
              [-39.899331328365449, -8.191276855345372],
              [-39.899331373937429, -8.191276872018536],
              [-39.899331419419255, -8.191276888934286],
              [-39.899331464809649, -8.191276906092122],
              [-39.899331510107274, -8.191276923491607],
              [-39.89933155531088, -8.191276941132207],
              [-39.899331600419146, -8.191276959013368],
              [-39.899331645430777, -8.19127697713467],
              [-39.899331690344511, -8.191276995495517],
              [-39.899331735159031, -8.191277014095414],
              [-39.899331779873073, -8.191277032933849],
              [-39.899331824485358, -8.191277052010262],
              [-39.899331868994615, -8.191277071324141],
              [-39.899331913399578, -8.191277090874872],
              [-39.899331957698941, -8.191277110661964],
              [-39.899332001891473, -8.191277130684769],
              [-39.899332045975903, -8.19127715094281],
              [-39.89933208995096, -8.191277171435448],
              [-39.899332133815385, -8.191277192162092],
              [-39.899332177567928, -8.191277213122195],
              [-39.899332221207345, -8.191277234315093],
              [-39.899332264732379, -8.191277255740225],
              [-39.899332308141794, -8.191277277396969],
              [-39.899332351434339, -8.191277299284733],
              [-39.899332394608763, -8.191277321402806],
              [-39.899332437663872, -8.191277343750638],
              [-39.899332480598403, -8.191277366327586],
              [-39.899332523411132, -8.191277389132939],
              [-39.899332566100831, -8.191277412166093],
              [-39.899332608666292, -8.191277435426397],
              [-39.899332651106292, -8.191277458913179],
              [-39.899332693419616, -8.191277482625749],
              [-39.899332735605043, -8.19127750656344],
              [-39.899332777661407, -8.191277530725589],
              [-39.899332819587443, -8.191277555111469],
              [-39.899332861382, -8.191277579720387],
              [-39.89933290304387, -8.191277604551651],
              [-39.89933294457186, -8.191277629604562],
              [-39.899332985964769, -8.191277654878393],
              [-39.899333027221431, -8.191277680372409],
              [-39.89933306834066, -8.19127770608587],
              [-39.899333109321276, -8.191277732018106],
              [-39.899333150162114, -8.191277758168312],
              [-39.899333190862016, -8.191277784535764],
              [-39.899333231419789, -8.191277811119672],
              [-39.899333271834294, -8.19127783791933],
              [-39.899333312104375, -8.191277864933969],
              [-39.899333352228865, -8.191277892162768],
              [-39.899333392206628, -8.191277919604984],
              [-39.899333432036528, -8.191277947259811],
              [-39.899333471717391, -8.191277975126475],
              [-39.899333511248145, -8.191278003204179],
              [-39.899333550627581, -8.191278031492107],
              [-39.899333589854642, -8.191278059989473],
              [-39.899333628928183, -8.191278088695425],
              [-39.899333667847067, -8.191278117609215],
              [-39.899333706610193, -8.191278146729911],
              [-39.899333745216474, -8.191278176056748],
              [-39.899333783664773, -8.191278205588912],
              [-39.899333821954009, -8.191278235325457],
              [-39.899333860083075, -8.191278265265664],
              [-39.899333898050898, -8.191278295408537],
              [-39.899333935856369, -8.191278325753323],
              [-39.899333973498422, -8.19127835629912],
              [-39.899334010975977, -8.191278387045026],
              [-39.899334048287976, -8.19127841799023],
              [-39.899334085433331, -8.191278449133767],
              [-39.899334122410977, -8.191278480474795],
              [-39.899334159219883, -8.191278512012399],
              [-39.899334195858962, -8.191278543745662],
              [-39.899334232327199, -8.191278575673735],
              [-39.899334268623527, -8.191278607795667],
              [-39.899334304746922, -8.191278640110491],
              [-39.899334340696328, -8.191278672617386],
              [-39.899334376470755, -8.191278705315368],
              [-39.899334412069308, -8.191278738202966],
              [-39.899334474590312, -8.191278813466839],
              [-39.901203213879988, -8.193014521181409],
              [-39.901246192979244, -8.194323876967818],
              [-39.901246151130934, -8.194323852613854],
              [-39.901246153924774, -8.194323928124808],
              [-39.901246157351721, -8.194324003609873],
              [-39.901246161411507, -8.194324079063769],
              [-39.901246166103853, -8.194324154481247],
              [-39.901246171428433, -8.194324229857047],
              [-39.901246177384877, -8.194324305185871],
              [-39.901246183972781, -8.19432438046249],
              [-39.901246191191674, -8.194324455681651],
              [-39.90124619904104, -8.194324530838113],
              [-39.901246207520359, -8.194324605926607],
              [-39.901246216629005, -8.194324680941902],
              [-39.901246226366375, -8.19432475587875],
              [-39.901246236731765, -8.194324830731929],
              [-39.90124624772448, -8.194324905496229],
              [-39.901246259343722, -8.194324980166405],
              [-39.90124627158869, -8.194325054737272],
              [-39.90124628445853, -8.194325129203607],
              [-39.901246297952341, -8.194325203560208],
              [-39.901246312069212, -8.194325277801909],
              [-39.901246326808106, -8.194325351923515],
              [-39.901246342168022, -8.19432542591985],
              [-39.901246358147908, -8.194325499785736],
              [-39.901246374746599, -8.194325573516041],
              [-39.901246391962978, -8.194325647105593],
              [-39.901246409795824, -8.194325720549298],
              [-39.901246428243901, -8.19432579384198],
              [-39.901246447305908, -8.194325866978549],
              [-39.901246466980538, -8.194325939953899],
              [-39.901246487266377, -8.194326012762939],
              [-39.901246508162068, -8.194326085400585],
              [-39.901246529666103, -8.194326157861738],
              [-39.901246551776993, -8.194326230141391],
              [-39.901246574493214, -8.194326302234447],
              [-39.901246597813156, -8.19432637413593],
              [-39.901246621735211, -8.19432644584078],
              [-39.901246646257697, -8.19432651734402],
              [-39.9012466713789, -8.194326588640628],
              [-39.901246697097072, -8.194326659725657],
              [-39.901246723410424, -8.194326730594113],
              [-39.9012467503171, -8.194326801241077],
              [-39.90124677781526, -8.194326871661612],
              [-39.901246805902936, -8.194326941850814],
              [-39.901246834578195, -8.194327011803759],
              [-39.901246863839027, -8.194327081515572],
              [-39.901246893683414, -8.194327150981383],
              [-39.901246924109252, -8.194327220196366],
              [-39.901246955114395, -8.194327289155673],
              [-39.901246986696727, -8.194327357854476],
              [-39.901247018853994, -8.194327426288007],
              [-39.901247051583987, -8.194327494451475],
              [-39.901247084884417, -8.194327562340131],
              [-39.901247118752948, -8.194327629949214],
              [-39.901247153187228, -8.194327697274048],
              [-39.901247188184833, -8.194327764309882],
              [-39.90124722374334, -8.194327831052096],
              [-39.90124725986027, -8.194327897495974],
              [-39.901247296533093, -8.194327963636903],
              [-39.901247333759237, -8.194328029470263],
              [-39.901247371536122, -8.194328094991475],
              [-39.901247409861114, -8.194328160195941],
              [-39.901247448731517, -8.194328225079122],
              [-39.901247488144641, -8.194328289636495],
              [-39.901247528097699, -8.194328353863551],
              [-39.901247568587962, -8.194328417755807],
              [-39.901247609612547, -8.194328481308764],
              [-39.901247651168632, -8.194328544518063],
              [-39.901247693253275, -8.194328607379243],
              [-39.901247735863564, -8.194328669887913],
              [-39.901247778996542, -8.194328732039722],
              [-39.901247822649154, -8.194328793830326],
              [-39.901247866818387, -8.194328855255437],
              [-39.901247911501144, -8.194328916310715],
              [-39.901247956694327, -8.194328976991974],
              [-39.901248002394752, -8.194329037294889],
              [-39.901248048599236, -8.194329097215322],
              [-39.901248095304567, -8.194329156749077],
              [-39.901248142507477, -8.194329215891987],
              [-39.901248190204676, -8.194329274639909],
              [-39.901248238392824, -8.194329332988771],
              [-39.901248287068569, -8.194329390934463],
              [-39.901248336228505, -8.194329448472976],
              [-39.901248385869209, -8.194329505600288],
              [-39.901248435987213, -8.194329562312427],
              [-39.901248486579007, -8.194329618605366],
              [-39.901248537641081, -8.194329674475247],
              [-39.901248589169853, -8.194329729918154],
              [-39.901248641161743, -8.194329784930208],
              [-39.901248693613113, -8.194329839507551],
              [-39.901248746520288, -8.194329893646415],
              [-39.901248799879596, -8.194329947342988],
              [-39.901248853687314, -8.194330000593546],
              [-39.901248907939667, -8.194330053394363],
              [-39.90124896263287, -8.194330105741736],
              [-39.901249017763135, -8.194330157632015],
              [-39.901249073326575, -8.194330209061604],
              [-39.901249129319353, -8.1943302600269],
              [-39.90124918573752, -8.194330310524352],
              [-39.901249242577158, -8.194330360550406],
              [-39.901249299834291, -8.194330410101619],
              [-39.901249294266449, -8.194330472469748],
              [-39.902036588492564, -8.195006049804116],
              [-39.902036572059679, -8.19500599760026],
              [-39.902036654998867, -8.195006067914273],
              [-39.902036738778719, -8.195006137231962],
              [-39.902036823387149, -8.195006205543288],
              [-39.902036908812001, -8.195006272838448],
              [-39.90203699504098, -8.195006339107744],
              [-39.902037082061668, -8.195006404341656],
              [-39.902037169861558, -8.195006468530758],
              [-39.902037258427995, -8.195006531665898],
              [-39.902037347748227, -8.195006593737888],
              [-39.902037437809419, -8.195006654737846],
              [-39.902037528598612, -8.195006714656994],
              [-39.902037620102732, -8.195006773486714],
              [-39.902037712308605, -8.195006831218512],
              [-39.902037805202973, -8.195006887844094],
              [-39.902037898772448, -8.19500694335531],
              [-39.902037993003574, -8.19500699774418],
              [-39.902038087882779, -8.19500705100287],
              [-39.902038183396456, -8.195007103123697],
              [-39.902038279530792, -8.195007154099191],
              [-39.902038376272003, -8.195007203922001],
              [-39.90203847360614, -8.195007252584958],
              [-39.902038571519192, -8.195007300081061],
              [-39.902038669997083, -8.195007346403456],
              [-39.902038769025637, -8.195007391545513],
              [-39.902038868590608, -8.195007435500704],
              [-39.902038968677651, -8.19500747826272],
              [-39.902039069272362, -8.19500751982541],
              [-39.902039170360283, -8.195007560182775],
              [-39.902039271926846, -8.195007599329008],
              [-39.90203937395745, -8.195007637258456],
              [-39.902039476437402, -8.195007673965717],
              [-39.90203957935195, -8.195007709445498],
              [-39.902039682686308, -8.195007743692646],
              [-39.902039786425561, -8.195007776702253],
              [-39.902039890554811, -8.195007808469605],
              [-39.902039995059049, -8.195007838990087],
              [-39.902040099923276, -8.195007868259315],
              [-39.902040205132359, -8.195007896273083],
              [-39.902040310671175, -8.19500792302734],
              [-39.902040416524528, -8.195007948518269],
              [-39.902040522677211, -8.195007972742179],
              [-39.902040629113898, -8.19500799569558],
              [-39.902040735819313, -8.195008017375196],
              [-39.902040842778085, -8.195008037777884],
              [-39.902040949974811, -8.195008056900702],
              [-39.902041057394072, -8.195008074740924],
              [-39.902041165020414, -8.195008091295968],
              [-39.902041272838332, -8.195008106563463],
              [-39.902041380832344, -8.195008120541186],
              [-39.90204148898686, -8.195008133227164],
              [-39.902041597286349, -8.195008144619498],
              [-39.902041705715213, -8.195008154716621],
              [-39.902041814257856, -8.195008163517052],
              [-39.902041922898654, -8.195008171019545],
              [-39.90204203162196, -8.195008177222991],
              [-39.902042140412135, -8.195008182126513],
              [-39.902042249253519, -8.195008185729421],
              [-39.902042358130473, -8.195008188031132],
              [-39.902042467027279, -8.195008189031391],
              [-39.902042575928306, -8.195008188730009],
              [-39.902042684817857, -8.195008187127046],
              [-39.902042793680288, -8.195008184222736],
              [-39.902042902499907, -8.195008180017483],
              [-39.902043011261064, -8.195008174511903],
              [-39.902043119948111, -8.195008167706813],
              [-39.902043228545388, -8.195008159603143],
              [-39.902043337037284, -8.195008150202076],
              [-39.902043445408175, -8.195008139504976],
              [-39.902043553642478, -8.195008127513379],
              [-39.902043661724598, -8.195008114229017],
              [-39.902043769639, -8.195008099653782],
              [-39.902043877370126, -8.195008083789801],
              [-39.902043984902512, -8.195008066639307],
              [-39.902044092220649, -8.195008048204834],
              [-39.902044199309096, -8.195008028488974],
              [-39.902044306152447, -8.195008007494595],
              [-39.902044412735336, -8.195007985224745],
              [-39.902044519042413, -8.19500796168257],
              [-39.90204462505838, -8.195007936871498],
              [-39.902044730767969, -8.195007910795086],
              [-39.90204483615598, -8.1950078834571],
              [-39.902044941207251, -8.195007854861464],
              [-39.902045045906654, -8.195007825012301],
              [-39.902045150239118, -8.195007793913893],
              [-39.90204525418963, -8.195007761570707],
              [-39.902045357743241, -8.195007727987422],
              [-39.902045460885034, -8.195007693168876],
              [-39.902045563600169, -8.19500765712006],
              [-39.902045665873871, -8.195007619846159],
              [-39.902045767691419, -8.195007581352568],
              [-39.902045869038162, -8.195007541644788],
              [-39.902045969899476, -8.195007500728552],
              [-39.902046070260916, -8.195007458609735],
              [-39.90204617010798, -8.195007415294434],
              [-39.902046269426322, -8.195007370788852],
              [-39.902046368201646, -8.1950073250994],
              [-39.902046466419755, -8.19500727823268],
              [-39.902046564066467, -8.195007230195381],
              [-39.902046661127777, -8.195007180994455],
              [-39.902046667441084, -8.195007203042811],
              [-39.903889407360303, -8.194059203078343],
              [-39.90388942334647, -8.194059162198254],
              [-39.90388943740534, -8.194059154950097],
              [-39.903889451451498, -8.19405914767751],
              [-39.903889465484895, -8.194059140380539],
              [-39.903889479505523, -8.194059133059202],
              [-39.903889493513304, -8.194059125713503],
              [-39.903889507508218, -8.194059118343491],
              [-39.903889521490193, -8.194059110949176],
              [-39.903889535459207, -8.194059103530567],
              [-39.903889549415226, -8.194059096087711],
              [-39.903889563358184, -8.194059088620607],
              [-39.903889577288069, -8.194059081129312],
              [-39.903889591204809, -8.194059073613817],
              [-39.903889605108382, -8.194059066074145],
              [-39.903889618998733, -8.194059058510343],
              [-39.903889632875817, -8.194059050922414],
              [-39.903889646739628, -8.194059043310371],
              [-39.903889660590067, -8.194059035674256],
              [-39.903889674427127, -8.194059028014111],
              [-39.903889688250764, -8.19405902032992],
              [-39.903889702060923, -8.194059012621725],
              [-39.903889715857574, -8.194059004889551],
              [-39.903889729640667, -8.194058997133434],
              [-39.903889743410176, -8.194058989353358],
              [-39.903889757166034, -8.194058981549389],
              [-39.903889770908222, -8.194058973721537],
              [-39.903889784636682, -8.194058965869818],
              [-39.903889798351393, -8.194058957994246],
              [-39.903889812052299, -8.194058950094865],
              [-39.903889825739341, -8.194058942171695],
              [-39.9038898394125, -8.194058934224753],
              [-39.903889853071732, -8.194058926254073],
              [-39.903889866716987, -8.194058918259685],
              [-39.903889880348238, -8.194058910241576],
              [-39.903889893965435, -8.194058902199794],
              [-39.903889907568519, -8.194058894134375],
              [-39.903889921157486, -8.194058886045335],
              [-39.903889934732277, -8.194058877932695],
              [-39.903889948292829, -8.19405886979648],
              [-39.903889961839141, -8.194058861636732],
              [-39.903889975371129, -8.194058853453463],
              [-39.9038899888888, -8.194058845246708],
              [-39.903890002392075, -8.19405883701647],
              [-39.903890015880933, -8.194058828762778],
              [-39.903890029355303, -8.194058820485671],
              [-39.903890042815185, -8.194058812185142],
              [-39.903890056260515, -8.194058803861264],
              [-39.903890069691265, -8.19405879551404],
              [-39.903890083107356, -8.194058787143494],
              [-39.903890096508803, -8.194058778749637],
              [-39.903890109895514, -8.194058770332509],
              [-39.903890123267487, -8.194058761892167],
              [-39.903890136624689, -8.194058753428573],
              [-39.903890149967026, -8.194058744941788],
              [-39.903890163294506, -8.194058736431836],
              [-39.903890176607064, -8.194058727898753],
              [-39.903890189904658, -8.194058719342557],
              [-39.903890203187274, -8.194058710763262],
              [-39.903890216454855, -8.194058702160909],
              [-39.903890229707365, -8.194058693535524],
              [-39.903890242944712, -8.194058684887128],
              [-39.903890256166946, -8.194058676215739],
              [-39.903890269373996, -8.19405866752138],
              [-39.903890282565783, -8.194058658804087],
              [-39.903890295742293, -8.19405865006391],
              [-39.903890308903499, -8.194058641300842],
              [-39.903890322049328, -8.194058632514915],
              [-39.903890335179767, -8.194058623706161],
              [-39.90389034829478, -8.194058614874628],
              [-39.903890361394296, -8.194058606020324],
              [-39.903890374478316, -8.194058597143245],
              [-39.903890387546767, -8.194058588243454],
              [-39.903890400599622, -8.194058579320991],
              [-39.903890413636844, -8.194058570375855],
              [-39.903890426658393, -8.194058561408095],
              [-39.903890439664231, -8.19405855241771],
              [-39.903890452654309, -8.19405854340474],
              [-39.903890465628592, -8.194058534369209],
              [-39.903890478587044, -8.194058525311155],
              [-39.903890491529623, -8.194058516230617],
              [-39.903890504456264, -8.194058507127608],
              [-39.903890517366989, -8.194058498002125],
              [-39.903890530261698, -8.194058488854242],
              [-39.903890543140392, -8.194058479683967],
              [-39.90389055600302, -8.194058470491346],
              [-39.903890568849526, -8.194058461276384],
              [-39.903890581679875, -8.194058452039121],
              [-39.903890594494072, -8.194058442779573],
              [-39.90389060729202, -8.194058433497773],
              [-39.90389062007371, -8.194058424193734],
              [-39.903890632839087, -8.194058414867536],
              [-39.903890645588135, -8.19405840551917],
              [-39.90389065832079, -8.194058396148668],
              [-39.90389067103704, -8.194058386756048],
              [-39.903890683736812, -8.194058377341349],
              [-39.903890696420106, -8.194058367904582],
              [-39.903890709086852, -8.194058358445815],
              [-39.903890721737021, -8.194058348965072],
              [-39.903890734370592, -8.194058339462361],
              [-39.903890746987507, -8.194058329937722],
              [-39.903890767181508, -8.194058386034326],
              [-39.905843141706342, -8.192581848219296],
              [-39.905843091102192, -8.192581798987204],
              [-39.905843102843072, -8.192581790092715],
              [-39.905843114569421, -8.192581781179191],
              [-39.905843126281219, -8.192581772246751],
              [-39.905843137978444, -8.192581763295333],
              [-39.905843149661045, -8.192581754324983],
              [-39.90584316132901, -8.192581745335747],
              [-39.905843172982301, -8.192581736327611],
              [-39.905843184620871, -8.19258172730065],
              [-39.905843196244724, -8.192581718254841],
              [-39.905843207853799, -8.192581709190213],
              [-39.90584321944808, -8.192581700106821],
              [-39.905843231027532, -8.19258169100469],
              [-39.905843242592127, -8.192581681883828],
              [-39.905843254141828, -8.19258167274425],
              [-39.905843265676609, -8.192581663585973],
              [-39.905843277196453, -8.192581654409011],
              [-39.905843288701305, -8.192581645213451],
              [-39.905843300191144, -8.192581635999275],
              [-39.905843311665947, -8.192581626766483],
              [-39.905843323125673, -8.192581617515181],
              [-39.905843334570299, -8.192581608245293],
              [-39.905843345999799, -8.192581598956911],
              [-39.905843357414128, -8.192581589650048],
              [-39.905843368813272, -8.19258158032469],
              [-39.905843380197197, -8.192581570980916],
              [-39.905843391565853, -8.192581561618727],
              [-39.905843402919238, -8.192581552238131],
              [-39.905843414257319, -8.192581542839187],
              [-39.905843425580031, -8.192581533421885],
              [-39.905843436887388, -8.192581523986268],
              [-39.905843448179347, -8.192581514532321],
              [-39.905843459455852, -8.192581505060163],
              [-39.905843470716917, -8.192581495569749],
              [-39.905843481962485, -8.192581486061075],
              [-39.905843493192542, -8.192581476534276],
              [-39.905843504407024, -8.192581466989282],
              [-39.905843515605937, -8.192581457426142],
              [-39.905843526789234, -8.19258144784488],
              [-39.905843537956898, -8.192581438245512],
              [-39.905843549108894, -8.192581428628115],
              [-39.905843560245195, -8.192581418992663],
              [-39.905843571365764, -8.1925814093392],
              [-39.905843582470574, -8.192581399667734],
              [-39.905843593559595, -8.192581389978312],
              [-39.905843604632807, -8.192581380270981],
              [-39.905843615690166, -8.19258137054571],
              [-39.905843626731667, -8.192581360802521],
              [-39.905843637757251, -8.192581351041524],
              [-39.905843648766904, -8.19258134126267],
              [-39.905843659760592, -8.192581331465981],
              [-39.905843670738292, -8.192581321651552],
              [-39.90584368169997, -8.192581311819353],
              [-39.905843692645604, -8.192581301969421],
              [-39.905843703575165, -8.192581292101746],
              [-39.905843714488604, -8.192581282216423],
              [-39.905843725385914, -8.192581272313463],
              [-39.90584373626708, -8.192581262392848],
              [-39.905843747132025, -8.192581252454652],
              [-39.905843757980755, -8.192581242498884],
              [-39.905843768813249, -8.192581232525518],
              [-39.905843779629457, -8.192581222534683],
              [-39.905843790429358, -8.192581212526315],
              [-39.905843801212917, -8.192581202500476],
              [-39.90584381198012, -8.192581192457206],
              [-39.905843822730922, -8.192581182396541],
              [-39.905843833465312, -8.192581172318457],
              [-39.905843844183252, -8.192581162223023],
              [-39.905843854884715, -8.192581152110257],
              [-39.905843865569665, -8.192581141980179],
              [-39.905843876238087, -8.192581131832801],
              [-39.905843886889954, -8.192581121668194],
              [-39.905843897525223, -8.192581111486337],
              [-39.905843908143851, -8.192581101287287],
              [-39.905843918745866, -8.192581091071022],
              [-39.905843929331191, -8.192581080837662],
              [-39.905843939899825, -8.192581070587137],
              [-39.905843950451718, -8.192581060319553],
              [-39.90584396098685, -8.192581050034891],
              [-39.905843971505206, -8.192581039733151],
              [-39.905843982006743, -8.192581029414431],
              [-39.905843992491441, -8.192581019078734],
              [-39.90584400295927, -8.19258100872606],
              [-39.905844013410203, -8.19258099835646],
              [-39.905844023844203, -8.192580987969933],
              [-39.905844034261257, -8.19258097756655],
              [-39.905844044661329, -8.192580967146341],
              [-39.905844055044398, -8.192580956709289],
              [-39.905844065410434, -8.19258094625541],
              [-39.905844075759411, -8.192580935784811],
              [-39.905844086091292, -8.192580925297436],
              [-39.905844096406071, -8.192580914793366],
              [-39.90584410670369, -8.192580904272631],
              [-39.905844116984142, -8.192580893735219],
              [-39.905844127247406, -8.192580883181185],
              [-39.905844137493432, -8.192580872610527],
              [-39.905844147722206, -8.192580862023298],
              [-39.905844157933707, -8.192580851419565],
              [-39.905844168127906, -8.192580840799295],
              [-39.905844178304775, -8.192580830162514],
              [-39.905844228778619, -8.192580850893599],
              [-39.906950360873019, -8.191422852384086],
              [-39.906950311553459, -8.191422823227198],
              [-39.906950337614241, -8.19142279605742],
              [-39.906950363788091, -8.191422768995777],
              [-39.906950390074577, -8.191422742042727],
              [-39.906950416473272, -8.191422715198707],
              [-39.906950442983664, -8.191422688464243],
              [-39.906950469605327, -8.191422661839754],
              [-39.906950496337807, -8.191422635325711],
              [-39.90695052318064, -8.191422608922561],
              [-39.906950550133352, -8.191422582630789],
              [-39.906950577195488, -8.191422556450814],
              [-39.906950604366564, -8.191422530383115],
              [-39.906950631646126, -8.191422504428092],
              [-39.90695065903369, -8.191422478586267],
              [-39.906950686528802, -8.191422452858038],
              [-39.906950714130978, -8.191422427243849],
              [-39.906950741839736, -8.191422401744166],
              [-39.9069507696546, -8.191422376359405],
              [-39.906950797575107, -8.191422351090024],
              [-39.906950825600752, -8.191422325936445],
              [-39.906950853731047, -8.191422300899125],
              [-39.906950881965528, -8.191422275978484],
              [-39.906950910303699, -8.191422251174965],
              [-39.906950938745076, -8.19142222648895],
              [-39.906950967289141, -8.191422201920902],
              [-39.906950995935432, -8.191422177471241],
              [-39.906951024683458, -8.191422153140403],
              [-39.906951053532687, -8.191422128928782],
              [-39.906951082482657, -8.191422104836791],
              [-39.906951111532848, -8.19142208086488],
              [-39.906951140682757, -8.191422057013417],
              [-39.906951169931908, -8.191422033282867],
              [-39.90695119927976, -8.191422009673602],
              [-39.906951228725831, -8.191421986186061],
              [-39.906951258269594, -8.191421962820609],
              [-39.906951287910559, -8.191421939577681],
              [-39.906951317648186, -8.191421916457685],
              [-39.906951347481993, -8.191421893460969],
              [-39.906951377411438, -8.191421870587982],
              [-39.906951407436047, -8.191421847839106],
              [-39.906951437555243, -8.19142182521473],
              [-39.90695146776855, -8.191421802715213],
              [-39.906951498075429, -8.191421780340995],
              [-39.906951528475354, -8.191421758092439],
              [-39.906951558967805, -8.191421735969929],
              [-39.906951589552257, -8.191421713973856],
              [-39.906951620228185, -8.191421692104573],
              [-39.906951650995055, -8.191421670362507],
              [-39.906951681852327, -8.191421648747991],
              [-39.906951712799483, -8.191421627261382],
              [-39.906951743835982, -8.191421605903116],
              [-39.906951774961286, -8.19142158467351],
              [-39.90695180617486, -8.19142156357295],
              [-39.906951837476164, -8.191421542601791],
              [-39.906951868864667, -8.19142152176039],
              [-39.906951900339827, -8.191421501049138],
              [-39.906951931901069, -8.191421480468382],
              [-39.906951963547883, -8.191421460018457],
              [-39.906951995279712, -8.191421439699738],
              [-39.906952027096011, -8.191421419512551],
              [-39.906952058996225, -8.191421399457251],
              [-39.906952090979793, -8.191421379534214],
              [-39.906952123046167, -8.191421359743739],
              [-39.906952155194837, -8.191421340086183],
              [-39.90695218742519, -8.19142132056192],
              [-39.906952219736681, -8.191421301171234],
              [-39.906952252128782, -8.191421281914515],
              [-39.906952284600912, -8.191421262792067],
              [-39.906952317152495, -8.191421243804179],
              [-39.906952349783019, -8.19142122495124],
              [-39.906952382491859, -8.191421206233569],
              [-39.906952415278496, -8.191421187651454],
              [-39.906952448142334, -8.191421169205238],
              [-39.90695248108284, -8.191421150895239],
              [-39.906952514099402, -8.191421132721773],
              [-39.90695254719148, -8.191421114685172],
              [-39.906952580358492, -8.1914210967857],
              [-39.906952613599877, -8.191421079023714],
              [-39.906952646915045, -8.191421061399495],
              [-39.906952680303434, -8.191421043913342],
              [-39.906952713764447, -8.191421026565568],
              [-39.906952747297531, -8.191421009356484],
              [-39.906952780902095, -8.191420992286373],
              [-39.906952814577572, -8.191420975355523],
              [-39.906952848323357, -8.191420958564242],
              [-39.906952882138881, -8.191420941912812],
              [-39.906952916023556, -8.191420925401543],
              [-39.906952949976805, -8.191420909030684],
              [-39.906952983998032, -8.191420892800529],
              [-39.906953018086654, -8.19142087671136],
              [-39.906953052242081, -8.191420860763468],
              [-39.90695308646373, -8.191420844957102],
              [-39.906953120751005, -8.191420829292577],
              [-39.906953155103309, -8.191420813770122],
              [-39.906953189520046, -8.191420798390025],
              [-39.906953224000645, -8.191420783152523],
              [-39.906953258544483, -8.191420768057924],
              [-39.906953293150977, -8.191420753106479],
              [-39.906953327819537, -8.191420738298396],
              [-39.906953362549565, -8.191420723633973],
              [-39.906953351789859, -8.191420765448308],
              [-39.908589501863815, -8.190733961273471],
              [-39.908589683006326, -8.190733779977545],
              [-39.910385863980885, -8.190046066191588],
              [-39.910386589573577, -8.190045793223025],
              [-39.912076581108749, -8.189568865644008],
              [-39.912076556049342, -8.189568815559273],
              [-39.912076574576275, -8.189568810309572],
              [-39.912076593091982, -8.189568805020697],
              [-39.91207661159639, -8.189568799692738],
              [-39.912076630089416, -8.189568794325627],
              [-39.912076648570967, -8.189568788919455],
              [-39.912076667040942, -8.189568783474231],
              [-39.912076685499301, -8.189568777989942],
              [-39.912076703945935, -8.189568772466668],
              [-39.91207672238076, -8.18956876690442],
              [-39.912076740803705, -8.189568761303182],
              [-39.912076759214678, -8.189568755663023],
              [-39.912076777613592, -8.189568749983941],
              [-39.912076796000377, -8.189568744266005],
              [-39.912076814374949, -8.18956873850918],
              [-39.912076832737199, -8.189568732713534],
              [-39.912076851087086, -8.18956872687909],
              [-39.912076869424496, -8.189568721005839],
              [-39.91207688774935, -8.189568715093838],
              [-39.912076906061586, -8.189568709143137],
              [-39.912076924361088, -8.189568703153714],
              [-39.912076942647808, -8.189568697125623],
              [-39.912076960921652, -8.189568691058886],
              [-39.912076979182537, -8.189568684953532],
              [-39.912076997430361, -8.189568678809579],
              [-39.912077015665076, -8.189568672627075],
              [-39.912077033886582, -8.189568666406005],
              [-39.91207705209478, -8.189568660146456],
              [-39.912077070289619, -8.189568653848426],
              [-39.912077088471001, -8.189568647511916],
              [-39.912077106638868, -8.189568641137006],
              [-39.912077124793093, -8.189568634723688],
              [-39.912077142933633, -8.189568628272022],
              [-39.912077161060374, -8.18956862178201],
              [-39.912077179173274, -8.189568615253693],
              [-39.912077197272211, -8.189568608687079],
              [-39.912077215357122, -8.189568602082234],
              [-39.912077233427951, -8.189568595439184],
              [-39.912077251484568, -8.189568588757922],
              [-39.912077269526925, -8.1895685820385],
              [-39.912077287554929, -8.189568575280971],
              [-39.912077305568502, -8.189568568485356],
              [-39.912077323567566, -8.189568561651646],
              [-39.912077341552035, -8.189568554779912],
              [-39.912077359521824, -8.189568547870158],
              [-39.912077377476862, -8.189568540922448],
              [-39.91207739541705, -8.189568533936766],
              [-39.912077413342331, -8.189568526913179],
              [-39.912077431252619, -8.189568519851736],
              [-39.912077449147816, -8.189568512752441],
              [-39.912077467027849, -8.189568505615345],
              [-39.912077484892663, -8.189568498440446],
              [-39.91207750274215, -8.189568491227794],
              [-39.912077520576233, -8.189568483977441],
              [-39.912077538394833, -8.18956847668937],
              [-39.912077556197879, -8.189568469363648],
              [-39.912077573985272, -8.189568462000331],
              [-39.912077591756947, -8.189568454599405],
              [-39.912077609512821, -8.189568447160921],
              [-39.912077627252813, -8.189568439684939],
              [-39.912077644976861, -8.189568432171439],
              [-39.912077662684837, -8.189568424620495],
              [-39.912077680376704, -8.189568417032151],
              [-39.912077698052386, -8.189568409406396],
              [-39.912077715711774, -8.189568401743296],
              [-39.912077733354813, -8.189568394042885],
              [-39.912077750981396, -8.189568386305213],
              [-39.912077768591459, -8.189568378530259],
              [-39.912077786184938, -8.189568370718101],
              [-39.91207780376174, -8.189568362868762],
              [-39.912077821321773, -8.189568354982313],
              [-39.912077838864981, -8.189568347058749],
              [-39.912077856391278, -8.189568339098102],
              [-39.912077873900564, -8.189568331100402],
              [-39.912077891392791, -8.189568323065707],
              [-39.912077908867872, -8.189568314994075],
              [-39.912077926325722, -8.189568306885505],
              [-39.912077943766263, -8.189568298740046],
              [-39.912077961189418, -8.189568290557736],
              [-39.912077978595093, -8.189568282338602],
              [-39.912077995983239, -8.189568274082713],
              [-39.912078013353764, -8.189568265790074],
              [-39.912078030706589, -8.189568257460717],
              [-39.912078048041636, -8.189568249094707],
              [-39.912078065358827, -8.189568240692047],
              [-39.912078082658098, -8.189568232252801],
              [-39.912078099939322, -8.189568223777007],
              [-39.912078117202483, -8.189568215264696],
              [-39.912078134447462, -8.189568206715903],
              [-39.912078151674208, -8.189568198130681],
              [-39.91207816888263, -8.18956818950906],
              [-39.912078186072641, -8.189568180851056],
              [-39.912078203244192, -8.189568172156745],
              [-39.91207822039717, -8.189568163426152],
              [-39.912078237531524, -8.189568154659311],
              [-39.91207825464717, -8.189568145856281],
              [-39.912078271744029, -8.189568137017087],
              [-39.91207828882203, -8.189568128141747],
              [-39.912078305881082, -8.189568119230337],
              [-39.912078322921118, -8.189568110282881],
              [-39.912078304205799, -8.189568138198865],
              [-39.913076374279655, -8.189042753969385],
              [-39.913076318938046, -8.189042726954881],
              [-39.913076406804052, -8.189042680082151],
              [-39.913076494148221, -8.189042632251047],
              [-39.913076580960038, -8.18904258346735],
              [-39.913076667229063, -8.189042533736931],
              [-39.91307675294486, -8.189042483065828],
              [-39.913076838097105, -8.189042431460114],
              [-39.913076922675543, -8.189042378926011],
              [-39.913077006669972, -8.18904232546986],
              [-39.913077090070281, -8.189042271098099],
              [-39.913077172866394, -8.18904221581729],
              [-39.913077255048343, -8.189042159634077],
              [-39.913077336606207, -8.18904210255527],
              [-39.913077417530168, -8.189042044587714],
              [-39.913077497810455, -8.18904198573842],
              [-39.91307757743742, -8.189041926014474],
              [-39.913077656401441, -8.189041865423071],
              [-39.913077734693005, -8.189041803971527],
              [-39.91307781230266, -8.189041741667243],
              [-39.913077889221078, -8.18904167851772],
              [-39.913077965438973, -8.189041614530593],
              [-39.913078040947134, -8.189041549713563],
              [-39.913078115736496, -8.189041484074439],
              [-39.913078189798021, -8.189041417621135],
              [-39.913078263122799, -8.189041350361682],
              [-39.913078335701968, -8.189041282304162],
              [-39.91307840752679, -8.189041213456782],
              [-39.913078478588602, -8.189041143827858],
              [-39.913078548878865, -8.189041073425789],
              [-39.913078618389058, -8.189041002259042],
              [-39.913078687110826, -8.189040930336191],
              [-39.913078755035883, -8.189040857665928],
              [-39.913078822156052, -8.189040784256985],
              [-39.913078888463239, -8.189040710118238],
              [-39.91307895394943, -8.189040635258603],
              [-39.913079018606744, -8.189040559687115],
              [-39.91307908242738, -8.189040483412896],
              [-39.91307914540365, -8.189040406445129],
              [-39.913079207527971, -8.189040328793105],
              [-39.913079268792849, -8.189040250466153],
              [-39.913079329190879, -8.189040171473769],
              [-39.913079388714806, -8.189040091825392],
              [-39.913079447357468, -8.189040011530725],
              [-39.913079505111739, -8.189039930599368],
              [-39.913079561970697, -8.189039849041105],
              [-39.913079617927494, -8.189039766865799],
              [-39.91307967297535, -8.189039684083314],
              [-39.913079727107672, -8.18903960070365],
              [-39.913079780317908, -8.189039516736836],
              [-39.913079832599635, -8.18903943219301],
              [-39.913079883946565, -8.189039347082341],
              [-39.91307993435251, -8.189039261415143],
              [-39.913079983811407, -8.189039175201703],
              [-39.913080032317261, -8.189039088452422],
              [-39.913080079864244, -8.189039001177749],
              [-39.91308012644663, -8.189038913388224],
              [-39.913080172058777, -8.189038825094389],
              [-39.913080216695214, -8.189038736306921],
              [-39.913080260350547, -8.189038647036524],
              [-39.91308030301952, -8.189038557293967],
              [-39.913080344696972, -8.18903846709004],
              [-39.913080385377889, -8.189038376435629],
              [-39.913080425057387, -8.18903828534169],
              [-39.913080463730665, -8.189038193819179],
              [-39.913080501393054, -8.189038101879142],
              [-39.913080538040035, -8.189038009532636],
              [-39.91308057366718, -8.189037916790816],
              [-39.913080608270178, -8.189037823664849],
              [-39.913080641844857, -8.189037730165962],
              [-39.913080674387196, -8.189037636305436],
              [-39.913080705893272, -8.189037542094558],
              [-39.913080736359255, -8.189037447544756],
              [-39.913080765781501, -8.189037352667347],
              [-39.91308079415645, -8.18903725747384],
              [-39.91308082148069, -8.189037161975651],
              [-39.913080847750919, -8.189037066184307],
              [-39.913080872963974, -8.189036970111356],
              [-39.913080897116807, -8.1890368737684],
              [-39.913080920206518, -8.189036777167013],
              [-39.91308094223033, -8.189036680318914],
              [-39.913080963185578, -8.189036583235694],
              [-39.913080983069719, -8.189036485929103],
              [-39.913081001880379, -8.189036388410889],
              [-39.913081019615291, -8.189036290692753],
              [-39.913081036272303, -8.189036192786487],
              [-39.913081051849417, -8.189036094703942],
              [-39.913081066344752, -8.189035996456891],
              [-39.913081079756566, -8.189035898057188],
              [-39.913081092083225, -8.189035799516725],
              [-39.91308110332325, -8.18903570084732],
              [-39.913081113475322, -8.189035602060935],
              [-39.913081122538152, -8.18903550316945],
              [-39.913081130510697, -8.189035404184807],
              [-39.913081137391977, -8.189035305118892],
              [-39.913081143181174, -8.189035205983679],
              [-39.913081147877591, -8.189035106791117],
              [-39.913081151480633, -8.189035007553152],
              [-39.913081153989893, -8.189034908281725],
              [-39.91308115540506, -8.189034808988868],
              [-39.913081155725955, -8.189034709686508],
              [-39.913081167265929, -8.189034784058803],
              [-39.913078083978675, -8.187681387280142],
              [-39.913078072524932, -8.187681350731051],
              [-39.913078073340643, -8.187681207231106],
              [-39.913078076441394, -8.187681063762133],
              [-39.913078081826384, -8.187680920360217],
              [-39.913078089494263, -8.187680777061489],
              [-39.913078099443076, -8.187680633902014],
              [-39.913078111670366, -8.187680490917842],
              [-39.913078126173005, -8.187680348144935],
              [-39.913078142947363, -8.187680205619271],
              [-39.913078161989233, -8.1876800633767],
              [-39.913078183293798, -8.187679921453039],
              [-39.913078206855694, -8.187679779884045],
              [-39.913078232669015, -8.187679638705328],
              [-39.913078260727232, -8.18767949795245],
              [-39.913078291023297, -8.187679357660818],
              [-39.913078323549591, -8.187679217865748],
              [-39.913078358297923, -8.187679078602462],
              [-39.913078395259518, -8.187678939906013],
              [-39.91307843442511, -8.187678801811295],
              [-39.913078475784808, -8.187678664353099],
              [-39.913078519328224, -8.187678527565994],
              [-39.913078565044373, -8.187678391484457],
              [-39.913078612921751, -8.187678256142728],
              [-39.913078662948337, -8.187678121574866],
              [-39.913078715111503, -8.18767798781478],
              [-39.913078769398126, -8.1876778548961],
              [-39.913078825794535, -8.187677722852305],
              [-39.913078884286534, -8.18767759171668],
              [-39.913078944859436, -8.187677461522176],
              [-39.913079007497913, -8.18767733230159],
              [-39.913079072186257, -8.187677204087441],
              [-39.913079138908181, -8.187677076912045],
              [-39.913079207646845, -8.187676950807381],
              [-39.913079278384998, -8.187676825805205],
              [-39.913079351104805, -8.187676701937001],
              [-39.913079425787949, -8.187676579233916],
              [-39.913079502415655, -8.187676457726878],
              [-39.913079580968613, -8.187676337446437],
              [-39.913079661427062, -8.187676218422917],
              [-39.913079743770737, -8.187676100686277],
              [-39.913079827978905, -8.187675984266111],
              [-39.913079914030362, -8.187675869191747],
              [-39.913080001903481, -8.187675755492199],
              [-39.913080091576091, -8.187675643196028],
              [-39.913080183025656, -8.187675532331534],
              [-39.913080276229124, -8.18767542292662],
              [-39.913080371163062, -8.187675315008855],
              [-39.913080467803553, -8.187675208605377],
              [-39.913080566126283, -8.187675103742968],
              [-39.91308066610646, -8.18767500044804],
              [-39.913080767718938, -8.187674898746586],
              [-39.913080870938167, -8.187674798664228],
              [-39.913080975738126, -8.187674700226149],
              [-39.91308108209244, -8.187674603457115],
              [-39.913081189974342, -8.187674508381521],
              [-39.913081299356662, -8.187674415023279],
              [-39.913081410211873, -8.187674323405899],
              [-39.913081522512066, -8.187674233552446],
              [-39.913081636228966, -8.187674145485536],
              [-39.913081751333941, -8.187674059227334],
              [-39.913081867798027, -8.187673974799564],
              [-39.913081985591901, -8.187673892223476],
              [-39.913082104685905, -8.187673811519856],
              [-39.913082225050061, -8.187673732709031],
              [-39.913082346654065, -8.187673655810826],
              [-39.913082469467312, -8.187673580844613],
              [-39.91308259345886, -8.187673507829247],
              [-39.913082718597529, -8.187673436783147],
              [-39.9130828448518, -8.18767336772417],
              [-39.913082972189898, -8.187673300669692],
              [-39.913083100579733, -8.187673235636604],
              [-39.913083229989027, -8.187673172641288],
              [-39.913083360385166, -8.187673111699599],
              [-39.913083491735335, -8.187673052826872],
              [-39.913083624006482, -8.187672996037922],
              [-39.913083757165303, -8.187672941347055],
              [-39.913083891178267, -8.187672888768041],
              [-39.913084026011653, -8.187672838314107],
              [-39.913084161631488, -8.187672789997961],
              [-39.913084298003653, -8.18767274383177],
              [-39.913084435093822, -8.187672699827145],
              [-39.913084572867469, -8.187672657995186],
              [-39.913084711289912, -8.18767261834639],
              [-39.913084850326314, -8.187672580890776],
              [-39.913084989941659, -8.187672545637771],
              [-39.913085130100811, -8.187672512596212],
              [-39.91308527076847, -8.187672481774436],
              [-39.913085411909258, -8.187672453180205],
              [-39.913085553487605, -8.187672426820711],
              [-39.913085695467892, -8.187672402702617],
              [-39.913085837814364, -8.187672380831975],
              [-39.913085980491196, -8.187672361214277],
              [-39.913086123462456, -8.187672343854453],
              [-39.913086266692154, -8.187672328756925],
              [-39.913086410144267, -8.187672315925461],
              [-39.913086553782634, -8.1876723053633],
              [-39.913086697571117, -8.187672297073044],
              [-39.913086841473522, -8.187672291056868],
              [-39.913086985453624, -8.187672287316232],
              [-39.913087129475791, -8.187672285851457],
              [-39.913087140929534, -8.187672322400532],
              [-39.914020948762058, -8.187670209630031],
              [-39.91402093730823, -8.187670173080976],
              [-39.91402108133493, -8.187670173893373],
              [-39.914021225330572, -8.187670176982442],
              [-39.914021369258876, -8.187670182347405],
              [-39.91402151308364, -8.187670189986909],
              [-39.914021656768647, -8.187670199898992],
              [-39.914021800277737, -8.187670212081214],
              [-39.91402194357476, -8.18767022653048],
              [-39.914022086623646, -8.187670243243153],
              [-39.914022229388401, -8.187670262215024],
              [-39.914022371833077, -8.187670283441353],
              [-39.9140225139218, -8.187670306916722],
              [-39.914022655618815, -8.187670332635312],
              [-39.91402279688846, -8.187670360590557],
              [-39.914022937695151, -8.1876703907755],
              [-39.914023078003432, -8.187670423182468],
              [-39.914023217778002, -8.187670457803373],
              [-39.91402335698367, -8.187670494629447],
              [-39.914023495585404, -8.187670533651442],
              [-39.914023633548283, -8.187670574859544],
              [-39.914023770837588, -8.187670618243372],
              [-39.914023907418766, -8.187670663791987],
              [-39.914024043257427, -8.187670711493942],
              [-39.914024178319373, -8.187670761337218],
              [-39.914024312570596, -8.187670813309275],
              [-39.914024445977319, -8.187670867397022],
              [-39.914024578505938, -8.187670923586829],
              [-39.914024710123094, -8.187670981864573],
              [-39.914024840795676, -8.187671042215589],
              [-39.91402497049075, -8.187671104624659],
              [-39.914025099175682, -8.187671169076081],
              [-39.914025226818076, -8.187671235553648],
              [-39.914025353385817, -8.187671304040608],
              [-39.914025478847016, -8.187671374519709],
              [-39.914025603170096, -8.187671446973221],
              [-39.914025726323779, -8.187671521382914],
              [-39.914025848277021, -8.187671597730034],
              [-39.914025968999162, -8.187671675995398],
              [-39.914026088459799, -8.187671756159261],
              [-39.914026206628826, -8.187671838201462],
              [-39.914026323476541, -8.187671922101345],
              [-39.914026438973508, -8.187672007837802],
              [-39.914026553090658, -8.187672095389233],
              [-39.914026665799234, -8.187672184733565],
              [-39.914026777070902, -8.187672275848357],
              [-39.914026886877636, -8.187672368710647],
              [-39.914026995191783, -8.187672463297094],
              [-39.914027101986079, -8.187672559583836],
              [-39.914027207233651, -8.187672657546663],
              [-39.914027310908004, -8.187672757160902],
              [-39.91402741298301, -8.187672858401495],
              [-39.914027513432998, -8.187672961242923],
              [-39.914027612232672, -8.187673065659322],
              [-39.914027709357171, -8.187673171624382],
              [-39.914027804782023, -8.187673279111474],
              [-39.914027898483219, -8.187673388093492],
              [-39.914027990437177, -8.187673498543003],
              [-39.914028080620731, -8.187673610432222],
              [-39.914028169011189, -8.187673723732971],
              [-39.914028255586295, -8.187673838416726],
              [-39.914028340324258, -8.187673954454613],
              [-39.914028423203739, -8.187674071817435],
              [-39.914028504203884, -8.187674190475619],
              [-39.914028583304308, -8.187674310399348],
              [-39.914028660485059, -8.187674431558371],
              [-39.914028735726745, -8.187674553922214],
              [-39.914028809010418, -8.187674677460068],
              [-39.914028880317616, -8.187674802140847],
              [-39.914028949630399, -8.18767492793315],
              [-39.914029016931316, -8.1876750548053],
              [-39.914029082203413, -8.187675182725378],
              [-39.914029145430277, -8.187675311661174],
              [-39.914029206595991, -8.187675441580208],
              [-39.914029265685137, -8.187675572449807],
              [-39.91402932268285, -8.187675704236998],
              [-39.914029377574785, -8.187675836908625],
              [-39.914029430347128, -8.187675970431265],
              [-39.914029480986564, -8.187676104771354],
              [-39.914029529480395, -8.187676239895028],
              [-39.914029575816357, -8.187676375768262],
              [-39.914029619982834, -8.187676512356875],
              [-39.914029661968684, -8.187676649626471],
              [-39.914029701763326, -8.187676787542504],
              [-39.914029739356771, -8.187676926070283],
              [-39.914029774739525, -8.187677065174849],
              [-39.914029807902708, -8.187677204821252],
              [-39.914029838837941, -8.18767734497432],
              [-39.914029867537465, -8.187677485598769],
              [-39.914029893994055, -8.187677626659182],
              [-39.914029918201024, -8.187677768120071],
              [-39.914029940152297, -8.187677909945805],
              [-39.914029959842338, -8.187678052100718],
              [-39.914029977266203, -8.187678194549004],
              [-39.914029992419486, -8.187678337254772],
              [-39.914030005298407, -8.187678480182132],
              [-39.914030015899698, -8.187678623295088],
              [-39.914030024220686, -8.187678766557601],
              [-39.914030030259276, -8.187678909933631],
              [-39.914030034013976, -8.187679053387077],
              [-39.914030035483975, -8.187679196881639],
              [-39.914030046937782, -8.187679233430678],
              [-39.914032048441648, -8.188556897089903],
              [-39.914032036890994, -8.188556817899467],
              [-39.914032038112161, -8.188556947112945],
              [-39.91403204118604, -8.188557076295842],
              [-39.914032046111998, -8.188557205421816],
              [-39.914032052889048, -8.188557334464459],
              [-39.914032061515805, -8.188557463397483],
              [-39.914032071990476, -8.188557592194538],
              [-39.914032084310961, -8.18855772082936],
              [-39.914032098474721, -8.188557849275657],
              [-39.914032114478893, -8.18855797750726],
              [-39.914032132320173, -8.188558105497945],
              [-39.914032151994931, -8.188558233221569],
              [-39.914032173499166, -8.188558360652136],
              [-39.914032196828479, -8.188558487763578],
              [-39.914032221978118, -8.18855861452996],
              [-39.914032248942924, -8.188558740925432],
              [-39.914032277717425, -8.188558866924154],
              [-39.91403230829571, -8.188558992500427],
              [-39.914032340671561, -8.188559117628607],
              [-39.914032374838385, -8.188559242283157],
              [-39.914032410789162, -8.188559366438632],
              [-39.914032448516608, -8.1885594900697],
              [-39.914032488012978, -8.18855961315111],
              [-39.91403252927023, -8.188559735657755],
              [-39.914032572279936, -8.188559857564591],
              [-39.914032617033321, -8.188559978846794],
              [-39.914032663521247, -8.188560099479545],
              [-39.914032711734215, -8.188560219438259],
              [-39.914032761662412, -8.188560338698405],
              [-39.91403281329562, -8.188560457235704],
              [-39.914032866623302, -8.188560575025939],
              [-39.914032921634593, -8.188560692045039],
              [-39.914032978318232, -8.188560808269125],
              [-39.914033036662673, -8.188560923674471],
              [-39.914033096655999, -8.188561038237538],
              [-39.914033158285967, -8.188561151934964],
              [-39.914033221539988, -8.18856126474347],
              [-39.914033286405164, -8.18856137664009],
              [-39.914033352868231, -8.188561487601934],
              [-39.914033420915644, -8.188561597606382],
              [-39.914033490533505, -8.188561706630972],
              [-39.914033561707598, -8.188561814653484],
              [-39.914033634423411, -8.188561921651813],
              [-39.914033708666089, -8.188562027604162],
              [-39.914033784420482, -8.188562132488865],
              [-39.914033861671115, -8.188562236284545],
              [-39.91403394040222, -8.188562338969961],
              [-39.914034020597754, -8.18856244052423],
              [-39.91403410224131, -8.188562540926579],
              [-39.914034185316261, -8.188562640156507],
              [-39.914034269805605, -8.188562738193761],
              [-39.914034355692131, -8.18856283501834],
              [-39.914034442958275, -8.188562930610484],
              [-39.91403453158626, -8.188563024950666],
              [-39.914034621557974, -8.188563118019642],
              [-39.914034712855049, -8.188563209798414],
              [-39.914034805458854, -8.188563300268219],
              [-39.914034899350497, -8.18856338941065],
              [-39.914034994510807, -8.18856347720744],
              [-39.914035090920336, -8.188563563640702],
              [-39.914035188559438, -8.188563648692828],
              [-39.914035287408154, -8.188563732346378],
              [-39.91403538744634, -8.188563814584327],
              [-39.914035488653539, -8.188563895389908],
              [-39.914035591009124, -8.188563974746588],
              [-39.914035694492171, -8.188564052638176],
              [-39.914035799081582, -8.188564129048768],
              [-39.914035904755998, -8.188564203962772],
              [-39.914036011493842, -8.188564277364897],
              [-39.914036119273334, -8.188564349240178],
              [-39.914036228072462, -8.188564419573913],
              [-39.914036337869049, -8.188564488351769],
              [-39.914036448640644, -8.188564555559681],
              [-39.914036560364671, -8.188564621183959],
              [-39.914036673018266, -8.188564685211178],
              [-39.914036786578507, -8.188564747628284],
              [-39.914036901022172, -8.18856480842253],
              [-39.914037016325899, -8.188564867581531],
              [-39.914037132466163, -8.1885649250932],
              [-39.914037249419273, -8.188564980945772],
              [-39.914037367161313, -8.188565035127851],
              [-39.914037485668281, -8.188565087628414],
              [-39.914037604915983, -8.188565138436704],
              [-39.914037724880068, -8.188565187542375],
              [-39.91403784553605, -8.188565234935389],
              [-39.914037966859297, -8.188565280606072],
              [-39.914038088825073, -8.188565324545124],
              [-39.914038211408446, -8.188565366743541],
              [-39.914038334584404, -8.188565407192709],
              [-39.914038458327802, -8.188565445884421],
              [-39.914038582613379, -8.188565482810731],
              [-39.914038707415784, -8.188565517964111],
              [-39.914038832709537, -8.188565551337403],
              [-39.914038958469042, -8.18856558292376],
              [-39.914039084668644, -8.188565612716779],
              [-39.914039211282571, -8.188565640710355],
              [-39.914039338284987, -8.18856566689878],
              [-39.914039465649978, -8.18856569127667],
              [-39.914039593351532, -8.188565713839123],
              [-39.914039721363572, -8.188565734581438],
              [-39.914039693815965, -8.188565743288958],
              [-39.915036586927108, -8.188720043565143],
              [-39.915036599233275, -8.188719987088561],
              [-39.915036613401078, -8.188719989269394],
              [-39.915036627572313, -8.188719991427948],
              [-39.915036641746966, -8.18871999356419],
              [-39.915036655924951, -8.188719995678106],
              [-39.915036670106311, -8.188719997769722],
              [-39.915036684290946, -8.188719999839028],
              [-39.91503669847885, -8.188720001886006],
              [-39.915036712669973, -8.188720003910671],
              [-39.915036726864294, -8.188720005913009],
              [-39.915036741061769, -8.18872000789298],
              [-39.915036755262363, -8.188720009850609],
              [-39.915036769466049, -8.188720011785911],
              [-39.915036783672768, -8.188720013698882],
              [-39.915036797882529, -8.188720015589476],
              [-39.915036812095252, -8.188720017457724],
              [-39.915036826310917, -8.188720019303576],
              [-39.915036840529496, -8.188720021127082],
              [-39.915036854750952, -8.188720022928194],
              [-39.915036868975236, -8.188720024706946],
              [-39.915036883202319, -8.188720026463297],
              [-39.915036897432174, -8.188720028197258],
              [-39.915036911664757, -8.188720029908826],
              [-39.91503692590004, -8.188720031598013],
              [-39.915036940137973, -8.188720033264801],
              [-39.915036954378543, -8.188720034909164],
              [-39.915036968621692, -8.188720036531132],
              [-39.915036982867399, -8.18872003813067],
              [-39.915036997115621, -8.188720039707784],
              [-39.915037011366337, -8.188720041262515],
              [-39.915037025619483, -8.188720042794822],
              [-39.915037039875052, -8.188720044304684],
              [-39.915037054132988, -8.188720045792101],
              [-39.915037068393261, -8.188720047257103],
              [-39.915037082655836, -8.188720048699651],
              [-39.915037096920713, -8.188720050119763],
              [-39.915037111187779, -8.188720051517402],
              [-39.915037125457062, -8.188720052892609],
              [-39.915037139728511, -8.18872005424536],
              [-39.915037154002079, -8.188720055575663],
              [-39.915037168277749, -8.18872005688349],
              [-39.915037182555466, -8.188720058168874],
              [-39.915037196835186, -8.188720059431777],
              [-39.91503721111691, -8.188720060672207],
              [-39.915037225400575, -8.188720061890173],
              [-39.915037239686171, -8.188720063085643],
              [-39.915037253973615, -8.188720064258657],
              [-39.915037268262907, -8.188720065409155],
              [-39.91503728255401, -8.188720066537195],
              [-39.915037296846897, -8.188720067642745],
              [-39.915037311141504, -8.188720068725813],
              [-39.915037325437815, -8.188720069786369],
              [-39.91503733973579, -8.188720070824434],
              [-39.915037354035398, -8.188720071840024],
              [-39.915037368336584, -8.188720072833084],
              [-39.915037382639333, -8.188720073803635],
              [-39.915037396943603, -8.188720074751689],
              [-39.915037411249358, -8.188720075677233],
              [-39.915037425556562, -8.188720076580255],
              [-39.915037439865173, -8.188720077460784],
              [-39.915037454175163, -8.188720078318783],
              [-39.915037468486503, -8.188720079154255],
              [-39.91503748279915, -8.188720079967251],
              [-39.915037497113062, -8.188720080757706],
              [-39.915037511428203, -8.188720081525634],
              [-39.915037525744552, -8.188720082271049],
              [-39.915037540062073, -8.188720082993926],
              [-39.91503755438071, -8.188720083694273],
              [-39.915037568700441, -8.188720084372077],
              [-39.915037583021231, -8.188720085027374],
              [-39.915037597343037, -8.18872008566011],
              [-39.915037611665831, -8.188720086270317],
              [-39.915037625989569, -8.188720086857986],
              [-39.915037640314232, -8.188720087423128],
              [-39.915037654639768, -8.188720087965711],
              [-39.91503766896615, -8.188720088485752],
              [-39.915037683293328, -8.188720088983249],
              [-39.915037697621287, -8.188720089458222],
              [-39.915037711949971, -8.188720089910632],
              [-39.915037726279358, -8.188720090340505],
              [-39.915037740609407, -8.188720090747818],
              [-39.915037754940087, -8.188720091132588],
              [-39.915037769271365, -8.188720091494817],
              [-39.915037783603189, -8.188720091834487],
              [-39.915037797935547, -8.188720092151614],
              [-39.915037812268373, -8.188720092446202],
              [-39.915037826601655, -8.188720092718228],
              [-39.915037840935355, -8.188720092967715],
              [-39.915037855269418, -8.188720093194627],
              [-39.915037869603843, -8.188720093399001],
              [-39.91503788393856, -8.188720093580811],
              [-39.915037898273546, -8.188720093740065],
              [-39.915037912608774, -8.188720093876762],
              [-39.915037926944201, -8.188720093990899],
              [-39.915037941279799, -8.188720094082479],
              [-39.915037955615503, -8.188720094151503],
              [-39.915037969951314, -8.188720094197988],
              [-39.915037984287174, -8.188720094221898],
              [-39.915037998623056, -8.188720094223246],
              [-39.915038012958931, -8.188720094202042],
              [-39.915038039545216, -8.188720130716755],
              [-39.916297461732817, -8.188717274283913],
              [-39.91629745027921, -8.18871723773451],
              [-39.916297594300985, -8.188717236269193],
              [-39.916297738281294, -8.188717232527409],
              [-39.916297882183912, -8.188717226510034],
              [-39.916298025972587, -8.188717218218633],
              [-39.91629816961111, -8.18871720765528],
              [-39.916298313063365, -8.188717194822649],
              [-39.916298456293198, -8.18871717972392],
              [-39.916298599264564, -8.188717162362961],
              [-39.916298741941489, -8.188717142744094],
              [-39.916298884288025, -8.188717120872296],
              [-39.916299026268355, -8.188717096753038],
              [-39.916299167846724, -8.188717070392402],
              [-39.916299308987519, -8.188717041797034],
              [-39.916299449655178, -8.188717010974127],
              [-39.916299589814287, -8.188716977931426],
              [-39.916299729429589, -8.188716942677281],
              [-39.91629986846592, -8.188716905220538],
              [-39.916300006888278, -8.188716865570639],
              [-39.916300144661825, -8.188716823737566],
              [-39.916300281751845, -8.188716779731866],
              [-39.916300418123868, -8.188716733564592],
              [-39.916300553743554, -8.188716685247385],
              [-39.916300688576747, -8.188716634792387],
              [-39.916300822589506, -8.188716582212322],
              [-39.916300955748099, -8.188716527520405],
              [-39.916301088018997, -8.188716470730432],
              [-39.916301219368926, -8.188716411856653],
              [-39.916301349764794, -8.188716350913948],
              [-39.916301479173775, -8.188716287917641],
              [-39.916301607563305, -8.188716222883533],
              [-39.91630173490104, -8.188716155828066],
              [-39.916301861154963, -8.188716086768132],
              [-39.916301986293263, -8.18871601572101],
              [-39.91630211028442, -8.188715942704723],
              [-39.916302233097255, -8.188715867737583],
              [-39.916302354700832, -8.188715790838458],
              [-39.916302475064541, -8.188715712026713],
              [-39.916302594158076, -8.188715631322154],
              [-39.916302711951467, -8.188715548745169],
              [-39.916302828415049, -8.188715464316495],
              [-39.916302943519518, -8.188715378057442],
              [-39.916303057235893, -8.188715289989675],
              [-39.916303169535531, -8.188715200135343],
              [-39.91630328039016, -8.188715108517133],
              [-39.916303389771905, -8.18871501515809],
              [-39.916303497653182, -8.18871492008166],
              [-39.916303604006885, -8.188714823311853],
              [-39.916303708806211, -8.188714724872984],
              [-39.916303812024765, -8.188714624789831],
              [-39.916303913636582, -8.188714523087645],
              [-39.916304013616106, -8.188714419791991],
              [-39.916304111938103, -8.188714314928864],
              [-39.916304208577856, -8.188714208524674],
              [-39.916304303511069, -8.188714100606191],
              [-39.916304396713784, -8.188713991200606],
              [-39.916304488162574, -8.188713880335454],
              [-39.916304577834417, -8.188713768038669],
              [-39.916304665706726, -8.18871365433848],
              [-39.91630475175738, -8.188713539263517],
              [-39.916304835964731, -8.188713422842781],
              [-39.91630491830756, -8.188713305105532],
              [-39.916304998765149, -8.188713186081458],
              [-39.916305077317233, -8.188713065800496],
              [-39.916305153944059, -8.188712944292924],
              [-39.916305228626307, -8.188712821589334],
              [-39.916305301345211, -8.188712697720627],
              [-39.916305372082434, -8.188712572717982],
              [-39.916305440820182, -8.188712446612866],
              [-39.916305507541146, -8.188712319437006],
              [-39.916305572228531, -8.188712191222475],
              [-39.916305634866056, -8.188712062001475],
              [-39.916305695437948, -8.188711931806608],
              [-39.916305753928953, -8.188711800670571],
              [-39.916305810324367, -8.188711668626468],
              [-39.916305864609981, -8.18871153570748],
              [-39.91630591677211, -8.188711401947055],
              [-39.916305966797651, -8.188711267378883],
              [-39.916306014673992, -8.188711132036888],
              [-39.91630606038909, -8.1887109959551],
              [-39.91630610393144, -8.188710859167763],
              [-39.916306145290065, -8.188710721709334],
              [-39.916306184454562, -8.188710583614441],
              [-39.916306221415063, -8.188710444917797],
              [-39.916306256162287, -8.188710305654334],
              [-39.916306288687466, -8.188710165859129],
              [-39.916306318982421, -8.188710025567396],
              [-39.916306347039509, -8.188709884814401],
              [-39.916306372851686, -8.188709743635602],
              [-39.916306396412452, -8.188709602066547],
              [-39.916306417715873, -8.188709460142858],
              [-39.916306436756585, -8.188709317900264],
              [-39.916306453529778, -8.188709175374591],
              [-39.916306468031259, -8.188709032601698],
              [-39.916306480257354, -8.188708889617544],
              [-39.916306490205002, -8.188708746458103],
              [-39.916306497871695, -8.188708603159448],
              [-39.916306503255484, -8.188708459757638],
              [-39.916306506355042, -8.188708316288769],
              [-39.916306507169573, -8.188708172788957],
              [-39.916306518623649, -8.188708209337936],
              [-39.91630440954885, -8.187785686300737],
              [-39.916304398063168, -8.187785635904254],
              [-39.916304399636516, -8.18778544566433],
              [-39.916304405225638, -8.187785255499485],
              [-39.916304414828069, -8.18778506549382],
              [-39.916304428439545, -8.187784875731452],
              [-39.916304446054077, -8.187784686296288],
              [-39.916304467663828, -8.187784497272188],
              [-39.916304493259254, -8.187784308742753],
              [-39.916304522829051, -8.187784120791445],
              [-39.91630455636011, -8.1877839335014],
              [-39.916304593837587, -8.187783746955482],
              [-39.916304635244941, -8.187783561236245],
              [-39.916304680563819, -8.187783376425857],
              [-39.916304729774176, -8.187783192606085],
              [-39.916304782854233, -8.187783009858261],
              [-39.916304839780501, -8.187782828263273],
              [-39.916304900527798, -8.187782647901425],
              [-39.916304965069266, -8.187782468852536],
              [-39.916305033376318, -8.187782291195834],
              [-39.916305105418736, -8.187782115009936],
              [-39.916305181164653, -8.187781940372778],
              [-39.916305260580558, -8.187781767361653],
              [-39.916305343631301, -8.187781596053064],
              [-39.91630543028014, -8.187781426522871],
              [-39.916305520488727, -8.187781258846064],
              [-39.916305614217165, -8.187781093096802],
              [-39.916305711423973, -8.187780929348447],
              [-39.916305812066149, -8.18778076767347],
              [-39.916305916099148, -8.187780608143383],
              [-39.916306023476942, -8.187780450828775],
              [-39.916306134152038, -8.187780295799246],
              [-39.91630624807545, -8.187780143123405],
              [-39.916306365196782, -8.187779992868819],
              [-39.916306485464183, -8.187779845101916],
              [-39.91630660882447, -8.187779699888122],
              [-39.916306735223067, -8.187779557291654],
              [-39.916306864604017, -8.187779417375637],
              [-39.916306996910095, -8.187779280201985],
              [-39.91630713208275, -8.187779145831387],
              [-39.916307270062184, -8.187779014323279],
              [-39.91630741078734, -8.187778885735854],
              [-39.916307554195939, -8.187778760125996],
              [-39.916307700224564, -8.187778637549316],
              [-39.916307848808565, -8.18777851806003],
              [-39.916307999882228, -8.187778401710986],
              [-39.916308153378687, -8.187778288553718],
              [-39.916308309230025, -8.187778178638231],
              [-39.916308467367308, -8.187778072013186],
              [-39.916308627720539, -8.187777968725801],
              [-39.916308790218785, -8.187777868821714],
              [-39.916308954790132, -8.187777772345161],
              [-39.916309121361785, -8.187777679338812],
              [-39.916309289860031, -8.187777589843837],
              [-39.916309460210307, -8.187777503899811],
              [-39.916309632337253, -8.187777421544812],
              [-39.916309806164691, -8.187777342815224],
              [-39.916309981615733, -8.187777267745892],
              [-39.916310158612738, -8.187777196370041],
              [-39.916310337077384, -8.187777128719254],
              [-39.916310516930714, -8.187777064823468],
              [-39.916310698093156, -8.187777004710938],
              [-39.916310880484552, -8.18777694840829],
              [-39.916311064024164, -8.187776895940402],
              [-39.916311248630826, -8.187776847330527],
              [-39.916311434222855, -8.187776802600117],
              [-39.916311620718098, -8.187776761769022],
              [-39.916311808034088, -8.187776724855267],
              [-39.916311996087906, -8.187776691875211],
              [-39.916312184796354, -8.187776662843429],
              [-39.91631237407595, -8.187776637772767],
              [-39.916312563842936, -8.187776616674343],
              [-39.916312754013333, -8.187776599557461],
              [-39.916312944503019, -8.187776586429699],
              [-39.916313135227696, -8.187776577296882],
              [-39.916313326102987, -8.187776572163052],
              [-39.916313517044429, -8.187776571030463],
              [-39.916313707967532, -8.18777657389963],
              [-39.91631389878782, -8.187776580769286],
              [-39.916314089420887, -8.187776591636398],
              [-39.916314279782348, -8.187776606496119],
              [-39.916314469788006, -8.187776625341924],
              [-39.91631465935378, -8.187776648165423],
              [-39.916314848395793, -8.187776674956551],
              [-39.916315036830369, -8.187776705703454],
              [-39.916315224574205, -8.187776740392501],
              [-39.916315411544176, -8.187776779008354],
              [-39.916315597657572, -8.187776821533967],
              [-39.916315782832044, -8.187776867950486],
              [-39.91631596698565, -8.187776918237379],
              [-39.916316150036906, -8.187776972372406],
              [-39.916316331904852, -8.18777703033158],
              [-39.916316512508992, -8.187777092089286],
              [-39.916316691769417, -8.187777157618216],
              [-39.916316869606803, -8.187777226889308],
              [-39.916317045942478, -8.187777299872003],
              [-39.916317220698424, -8.187777376533958],
              [-39.9163173937973, -8.187777456841264],
              [-39.916317565162544, -8.187777540758358],
              [-39.916317734718298, -8.18777762824814],
              [-39.916317902389054, -8.187777719272216],
              [-39.9163179171072, -8.187777787026928],
              [-39.917150394001069, -8.188241049838698],
              [-39.91715039986402, -8.188241035388421],
              [-39.91715042499029, -8.188241049424247],
              [-39.91715045007173, -8.188241063539451],
              [-39.917150475108102, -8.188241077733887],
              [-39.917150500099119, -8.188241092007384],
              [-39.917150525044569, -8.188241106359829],
              [-39.917150549944168, -8.18824112079108],
              [-39.917150574797695, -8.188241135300975],
              [-39.917150599604852, -8.188241149889382],
              [-39.917150624365441, -8.18824116455616],
              [-39.917150649079204, -8.188241179301146],
              [-39.917150673745859, -8.188241194124151],
              [-39.917150698365205, -8.188241209025097],
              [-39.917150722936938, -8.188241224003812],
              [-39.917150747460852, -8.188241239060147],
              [-39.917150771936704, -8.188241254193947],
              [-39.917150796364211, -8.188241269405049],
              [-39.91715082074316, -8.188241284693301],
              [-39.917150845073287, -8.188241300058566],
              [-39.917150869354352, -8.18824131550066],
              [-39.917150893586111, -8.188241331019453],
              [-39.917150917768332, -8.188241346614776],
              [-39.917150941900765, -8.188241362286488],
              [-39.917150965983147, -8.188241378034425],
              [-39.917150990015259, -8.188241393858421],
              [-39.917151013996843, -8.188241409758309],
              [-39.917151037927688, -8.18824142573397],
              [-39.917151061807509, -8.188241441785165],
              [-39.917151085636092, -8.188241457911799],
              [-39.91715110941319, -8.188241474113683],
              [-39.917151133138574, -8.188241490390688],
              [-39.91715115681199, -8.188241506742605],
              [-39.917151180433223, -8.188241523169289],
              [-39.917151204001989, -8.188241539670592],
              [-39.917151227518112, -8.188241556246316],
              [-39.917151250981306, -8.18824157289632],
              [-39.917151274391351, -8.188241589620388],
              [-39.917151297748021, -8.188241606418432],
              [-39.917151321051065, -8.188241623290212],
              [-39.917151344300251, -8.188241640235583],
              [-39.917151367495372, -8.188241657254393],
              [-39.917151390636135, -8.18824167434644],
              [-39.91715141372238, -8.188241691511555],
              [-39.917151436753826, -8.188241708749588],
              [-39.917151459730256, -8.188241726060358],
              [-39.917151482651427, -8.188241743443671],
              [-39.917151505517133, -8.188241760899382],
              [-39.917151528327118, -8.188241778427257],
              [-39.917151551081162, -8.188241796027176],
              [-39.917151573779023, -8.188241813698987],
              [-39.91715159642051, -8.188241831442442],
              [-39.917151619005367, -8.188241849257398],
              [-39.917151641533344, -8.188241867143663],
              [-39.917151664004265, -8.188241885101101],
              [-39.917151686417874, -8.188241903129471],
              [-39.917151708773957, -8.188241921228609],
              [-39.917151731072281, -8.188241939398335],
              [-39.917151753312609, -8.188241957638487],
              [-39.917151775494744, -8.18824197594884],
              [-39.917151797618459, -8.188241994329269],
              [-39.917151819683504, -8.188242012779508],
              [-39.917151841689687, -8.188242031299454],
              [-39.917151863636754, -8.188242049888865],
              [-39.917151885524511, -8.188242068547591],
              [-39.917151907352753, -8.188242087275407],
              [-39.917151929121211, -8.188242106072163],
              [-39.917151950829705, -8.188242124937648],
              [-39.917151972477988, -8.188242143871655],
              [-39.917151994065883, -8.18824216287404],
              [-39.917152015593139, -8.188242181944561],
              [-39.91715203705953, -8.188242201083071],
              [-39.917152058464879, -8.188242220289325],
              [-39.91715207980895, -8.188242239563174],
              [-39.91715210109151, -8.188242258904395],
              [-39.917152122312373, -8.18824227831284],
              [-39.917152143471306, -8.188242297788255],
              [-39.917152164568115, -8.188242317330474],
              [-39.917152185602575, -8.188242336939291],
              [-39.917152206574471, -8.188242356614557],
              [-39.917152227483598, -8.188242376355975],
              [-39.917152248329742, -8.188242396163401],
              [-39.917152269112698, -8.188242416036653],
              [-39.917152289832245, -8.188242435975535],
              [-39.917152310488198, -8.188242455979806],
              [-39.917152331080324, -8.188242476049284],
              [-39.917152351608436, -8.188242496183765],
              [-39.917152372072302, -8.188242516383047],
              [-39.917152392471749, -8.188242536646918],
              [-39.917152412806537, -8.188242556975201],
              [-39.917152433076488, -8.188242577367681],
              [-39.917152453281389, -8.188242597824125],
              [-39.917152473421027, -8.188242618344393],
              [-39.917152493495216, -8.188242638928189],
              [-39.917152513503737, -8.18824265957538],
              [-39.917152533446398, -8.188242680285683],
              [-39.917152553323, -8.188242701058979],
              [-39.91715257313335, -8.188242721894975],
              [-39.917152592877223, -8.188242742793555],
              [-39.917152612554432, -8.188242763754401],
              [-39.917152632164644, -8.188242784778168],
              [-39.9171526675294, -8.18824285355185],
              [-39.917696484217714, -8.188827694122969],
              [-39.918588719438361, -8.187558727558422],
              [-39.919716394835241, -8.185961816762157],
              [-39.919716340664031, -8.185961738286791],
              [-39.919716362160202, -8.185961707978031],
              [-39.919716383781022, -8.185961677757378],
              [-39.91971640552611, -8.185961647625396],
              [-39.91971642739513, -8.185961617582564],
              [-39.919716449387678, -8.185961587629395],
              [-39.919716471503406, -8.185961557766397],
              [-39.919716493741944, -8.185961527994071],
              [-39.919716516102895, -8.185961498312926],
              [-39.919716538585909, -8.185961468723464],
              [-39.91971656119059, -8.185961439226148],
              [-39.919716583916568, -8.185961409821495],
              [-39.919716606763458, -8.185961380510001],
              [-39.919716629730864, -8.185961351292164],
              [-39.919716652818416, -8.185961322168465],
              [-39.919716676025701, -8.185961293139432],
              [-39.919716699352357, -8.185961264205488],
              [-39.919716722797979, -8.185961235367188],
              [-39.919716746362177, -8.185961206624963],
              [-39.919716770044538, -8.185961177979324],
              [-39.919716793844678, -8.185961149430767],
              [-39.919716817762193, -8.185961120979742],
              [-39.919716841796685, -8.185961092626727],
              [-39.919716865947748, -8.185961064372238],
              [-39.91971689021495, -8.185961036216691],
              [-39.919716914597927, -8.185961008160609],
              [-39.91971693909624, -8.185960980204436],
              [-39.91971696370949, -8.185960952348658],
              [-39.919716988437237, -8.185960924593765],
              [-39.91971701327909, -8.185960896940196],
              [-39.919717038234609, -8.185960869388438],
              [-39.919717063303402, -8.18596084193892],
              [-39.919717088485029, -8.185960814592139],
              [-39.919717113779072, -8.185960787348526],
              [-39.919717139185103, -8.185960760208582],
              [-39.91971716470271, -8.185960733172713],
              [-39.919717190331411, -8.185960706241396],
              [-39.919717216070836, -8.185960679415095],
              [-39.919717241920516, -8.185960652694241],
              [-39.919717267880046, -8.1859606260793],
              [-39.919717293948949, -8.185960599570718],
              [-39.919717320126814, -8.185960573168941],
              [-39.919717346413194, -8.185960546874403],
              [-39.919717372807618, -8.185960520687551],
              [-39.919717399309697, -8.185960494608844],
              [-39.919717425918961, -8.185960468638704],
              [-39.919717452634941, -8.185960442777583],
              [-39.919717479457198, -8.185960417025878],
              [-39.919717506385304, -8.185960391384073],
              [-39.919717533418776, -8.185960365852571],
              [-39.919717560557167, -8.185960340431819],
              [-39.919717587800029, -8.185960315122214],
              [-39.919717615146901, -8.1859602899242],
              [-39.919717642597305, -8.185960264838215],
              [-39.919717670150817, -8.185960239864663],
              [-39.919717697806924, -8.185960215003965],
              [-39.919717725565206, -8.185960190256568],
              [-39.919717753425161, -8.185960165622836],
              [-39.919717781386332, -8.185960141103225],
              [-39.919717809448244, -8.185960116698144],
              [-39.919717837610442, -8.185960092407992],
              [-39.919717865872443, -8.185960068233184],
              [-39.919717894233763, -8.18596004417412],
              [-39.919717922693927, -8.185960020231221],
              [-39.919717951252458, -8.185959996404863],
              [-39.919717979908889, -8.18595997269548],
              [-39.919718008662706, -8.185959949103445],
              [-39.919718037513448, -8.185959925629168],
              [-39.919718066460625, -8.185959902273053],
              [-39.919718095503754, -8.185959879035465],
              [-39.91971812464233, -8.185959855916797],
              [-39.919718153875891, -8.185959832917478],
              [-39.919718183203905, -8.185959810037868],
              [-39.919718212625909, -8.18595978727836],
              [-39.9197182421414, -8.185959764639314],
              [-39.919718271749886, -8.185959742121144],
              [-39.91971830145085, -8.185959719724233],
              [-39.919718331243814, -8.185959697448919],
              [-39.919718361128268, -8.185959675295607],
              [-39.919718391103693, -8.185959653264659],
              [-39.919718421169605, -8.18595963135642],
              [-39.919718451325501, -8.185959609571306],
              [-39.919718481570847, -8.185959587909677],
              [-39.919718511905167, -8.185959566371867],
              [-39.919718542327921, -8.185959544958282],
              [-39.919718572838612, -8.185959523669247],
              [-39.919718603436721, -8.185959502505114],
              [-39.919718634121743, -8.185959481466252],
              [-39.919718664893146, -8.18595946055304],
              [-39.919718695750419, -8.185959439765774],
              [-39.919718726693056, -8.185959419104883],
              [-39.91971875772051, -8.185959398570635],
              [-39.919718788832277, -8.185959378163435],
              [-39.919718820027825, -8.185959357883592],
              [-39.919718851306619, -8.185959337731472],
              [-39.919718882668164, -8.185959317707379],
              [-39.919718914111904, -8.185959297811706],
              [-39.919718945637314, -8.185959278044741],
              [-39.919718977243875, -8.18595925840682],
              [-39.91971900893126, -8.185959238897183],
              [-39.919719112275146, -8.185959278146383],
              [-39.922637873868283, -8.184170516674889],
              [-39.922637801708369, -8.184170470480995],
              [-39.922637820084574, -8.184170459189081],
              [-39.922637838433758, -8.184170447853665],
              [-39.922637856755841, -8.184170436474812],
              [-39.922637875050725, -8.184170425052558],
              [-39.92263789331826, -8.184170413586989],
              [-39.922637911558397, -8.18417040207815],
              [-39.922637929771, -8.184170390526116],
              [-39.922637947955977, -8.184170378930963],
              [-39.922637966113228, -8.184170367292733],
              [-39.922637984242648, -8.184170355611494],
              [-39.922638002344115, -8.184170343887354],
              [-39.922638020417558, -8.184170332120356],
              [-39.92263803846285, -8.184170320310548],
              [-39.922638056479919, -8.184170308458015],
              [-39.922638074468622, -8.184170296562838],
              [-39.92263809242889, -8.184170284625042],
              [-39.922638110360616, -8.184170272644723],
              [-39.922638128263678, -8.184170260621947],
              [-39.922638146137999, -8.184170248556802],
              [-39.922638163983457, -8.184170236449303],
              [-39.922638181799975, -8.18417022429955],
              [-39.922638199587439, -8.184170212107617],
              [-39.922638217345742, -8.184170199873583],
              [-39.922638235074793, -8.184170187597465],
              [-39.922638252774497, -8.184170175279412],
              [-39.922638270444736, -8.184170162919433],
              [-39.92263828808543, -8.184170150517625],
              [-39.922638305696466, -8.184170138074034],
              [-39.922638323277738, -8.184170125588754],
              [-39.922638340829174, -8.184170113061846],
              [-39.922638358350646, -8.184170100493395],
              [-39.92263837584207, -8.184170087883418],
              [-39.922638393303338, -8.184170075232066],
              [-39.922638410734358, -8.184170062539346],
              [-39.922638428135024, -8.18417004980537],
              [-39.92263844550525, -8.184170037030185],
              [-39.922638462844937, -8.184170024213888],
              [-39.922638480153964, -8.184170011356514],
              [-39.922638497432267, -8.184169998458175],
              [-39.922638514679726, -8.184169985518924],
              [-39.92263853189624, -8.184169972538832],
              [-39.922638549081732, -8.184169959517982],
              [-39.922638566236088, -8.184169946456434],
              [-39.922638583359216, -8.184169933354307],
              [-39.922638600451016, -8.184169920211614],
              [-39.922638617511396, -8.184169907028449],
              [-39.92263863454027, -8.184169893804889],
              [-39.922638651537511, -8.184169880541036],
              [-39.922638668503048, -8.184169867236943],
              [-39.922638685436787, -8.184169853892659],
              [-39.922638702338617, -8.184169840508302],
              [-39.922638719208443, -8.184169827083926],
              [-39.922638736046196, -8.18416981361961],
              [-39.922638752851739, -8.184169800115431],
              [-39.922638769625003, -8.184169786571465],
              [-39.922638786365894, -8.184169772987778],
              [-39.922638803074307, -8.184169759364458],
              [-39.922638819750148, -8.184169745701592],
              [-39.922638836393332, -8.184169731999246],
              [-39.922638853003768, -8.184169718257502],
              [-39.922638869581348, -8.184169704476416],
              [-39.922638886125981, -8.184169690656095],
              [-39.92263890263758, -8.184169676796621],
              [-39.922638919116046, -8.184169662898054],
              [-39.922638935561281, -8.184169648960461],
              [-39.922638951973205, -8.184169634983959],
              [-39.922638968351727, -8.184169620968589],
              [-39.922638984696739, -8.184169606914461],
              [-39.922639001008143, -8.184169592821634],
              [-39.922639017285888, -8.184169578690184],
              [-39.922639033529826, -8.184169564520216],
              [-39.922639049739907, -8.184169550311765],
              [-39.922639065916009, -8.184169536064966],
              [-39.922639082058083, -8.184169521779886],
              [-39.922639098165988, -8.184169507456556],
              [-39.922639114239679, -8.184169493095098],
              [-39.922639130279023, -8.18416947869561],
              [-39.922639146283956, -8.184169464258163],
              [-39.922639162254384, -8.184169449782823],
              [-39.922639178190209, -8.184169435269673],
              [-39.922639194091325, -8.184169420718803],
              [-39.92263920995768, -8.184169406130271],
              [-39.922639225789162, -8.184169391504202],
              [-39.922639241585685, -8.184169376840668],
              [-39.922639257347171, -8.184169362139741],
              [-39.9226392730735, -8.184169347401481],
              [-39.922639288764614, -8.184169332626006],
              [-39.922639304420407, -8.184169317813394],
              [-39.922639320040801, -8.184169302963747],
              [-39.922639335625703, -8.184169288077092],
              [-39.922639351175022, -8.184169273153556],
              [-39.922639366688678, -8.184169258193206],
              [-39.922639382166572, -8.184169243196147],
              [-39.922639397608634, -8.184169228162425],
              [-39.922639413014736, -8.184169213092185],
              [-39.922639428384848, -8.184169197985444],
              [-39.922639443718843, -8.184169182842339],
              [-39.922639459016644, -8.184169167662954],
              [-39.922639474278185, -8.184169152447353],
              [-39.922639504895947, -8.18416924673677],
              [-39.924145080566831, -8.182664619978231],
              [-39.924145261698285, -8.182664438676433],
              [-39.925557669891838, -8.181347058425789],
              [-39.926686238182207, -8.180313232951114],
              [-39.926686134300724, -8.180313175084096],
              [-39.926686163837367, -8.180313147905585],
              [-39.926686193252891, -8.180313120596976],
              [-39.926686222546721, -8.180313093158848],
              [-39.926686251718309, -8.180313065591685],
              [-39.926686280767051, -8.180313037896081],
              [-39.926686309692393, -8.180313010072542],
              [-39.926686338493781, -8.18031298212164],
              [-39.92668636717061, -8.180312954043906],
              [-39.926686395722349, -8.180312925839871],
              [-39.926686424148443, -8.180312897510149],
              [-39.926686452448301, -8.180312869055227],
              [-39.926686480621399, -8.180312840475727],
              [-39.926686508667181, -8.180312811772147],
              [-39.926686536585088, -8.180312782945091],
              [-39.926686564374577, -8.180312753995091],
              [-39.926686592035097, -8.18031272492275],
              [-39.926686619566105, -8.180312695728622],
              [-39.926686646967042, -8.180312666413313],
              [-39.926686674237423, -8.180312636977343],
              [-39.926686701376674, -8.180312607421323],
              [-39.926686728384261, -8.180312577745831],
              [-39.926686755259688, -8.18031254795145],
              [-39.926686782002392, -8.180312518038743],
              [-39.926686808611862, -8.180312488008315],
              [-39.926686835087565, -8.180312457860758],
              [-39.926686861428998, -8.180312427596645],
              [-39.92668688763564, -8.180312397216586],
              [-39.926686913706959, -8.180312366721168],
              [-39.926686939642472, -8.180312336111019],
              [-39.926686965441661, -8.18031230538671],
              [-39.926686991104013, -8.180312274548836],
              [-39.926687016629025, -8.180312243598017],
              [-39.926687042016212, -8.180312212534862],
              [-39.926687067265043, -8.180312181359978],
              [-39.926687092375055, -8.180312150073959],
              [-39.926687117345736, -8.180312118677442],
              [-39.926687142176597, -8.18031208717102],
              [-39.926687166867168, -8.180312055555337],
              [-39.926687191416931, -8.180312023830997],
              [-39.92668721582546, -8.180311991998655],
              [-39.926687240092228, -8.180311960058889],
              [-39.926687264216767, -8.180311928012358],
              [-39.926687288198629, -8.180311895859671],
              [-39.926687312037316, -8.18031186360148],
              [-39.926687335732346, -8.180311831238381],
              [-39.926687359283306, -8.180311798771028],
              [-39.926687382689707, -8.180311766200067],
              [-39.926687405951064, -8.180311733526132],
              [-39.926687429066959, -8.180311700749856],
              [-39.92668745203693, -8.180311667871893],
              [-39.926687474860508, -8.180311634892881],
              [-39.92668749753728, -8.1803116018135],
              [-39.926687520066757, -8.180311568634325],
              [-39.926687542448526, -8.180311535356088],
              [-39.926687564682148, -8.180311501979391],
              [-39.926687586767173, -8.180311468504906],
              [-39.926687608703183, -8.180311434933289],
              [-39.926687630489724, -8.180311401265181],
              [-39.92668765212639, -8.180311367501258],
              [-39.926687673612754, -8.180311333642191],
              [-39.926687694948392, -8.180311299688626],
              [-39.926687716132889, -8.180311265641238],
              [-39.926687737165821, -8.180311231500701],
              [-39.926687758046754, -8.180311197267667],
              [-39.926687778775339, -8.180311162942839],
              [-39.926687799351122, -8.180311128526883],
              [-39.926687819773719, -8.180311094020441],
              [-39.92668784004271, -8.180311059424209],
              [-39.926687860157706, -8.180311024738876],
              [-39.92668788011833, -8.180310989965097],
              [-39.926687899924161, -8.180310955103598],
              [-39.926687919574832, -8.18031092015503],
              [-39.926687939069957, -8.180310885120068],
              [-39.926687958409147, -8.180310849999438],
              [-39.926687977592003, -8.180310814793799],
              [-39.926687996618185, -8.180310779503852],
              [-39.926688015487308, -8.180310744130287],
              [-39.926688034198982, -8.180310708673792],
              [-39.926688052752858, -8.180310673135061],
              [-39.926688071148583, -8.180310637514822],
              [-39.926688089385777, -8.180310601813728],
              [-39.926688107464088, -8.180310566032507],
              [-39.926688125383144, -8.180310530171866],
              [-39.926688143142627, -8.180310494232499],
              [-39.926688160742174, -8.180310458215111],
              [-39.926688178181422, -8.180310422120394],
              [-39.926688195460059, -8.180310385949092],
              [-39.92668821257773, -8.180310349701877],
              [-39.926688229534086, -8.180310313379463],
              [-39.926688246328823, -8.180310276982604],
              [-39.926688262961598, -8.180310240511957],
              [-39.926688279432078, -8.180310203968284],
              [-39.926688295739957, -8.180310167352292],
              [-39.926688311884895, -8.180310130664671],
              [-39.926688327866579, -8.180310093906165],
              [-39.926688343684702, -8.180310057077479],
              [-39.926688359338968, -8.180310020179361],
              [-39.926688374829048, -8.180309983212513],
              [-39.926688390154631, -8.180309946177697],
              [-39.926688409226038, -8.18030997200011],
              [-39.927344377123795, -8.17871484980048],
              [-39.927344738544242, -8.17871412542188],
              [-39.928283205420165, -8.176930231236733],
              [-39.928283144202354, -8.176930170526575],
              [-39.928283160318884, -8.176930139747476],
              [-39.928283176316711, -8.176930108906944],
              [-39.928283192195607, -8.17693007800546],
              [-39.928283207955353, -8.176930047043472],
              [-39.928283223595706, -8.17693001602143],
              [-39.928283239116418, -8.176929984939823],
              [-39.928283254517275, -8.176929953799078],
              [-39.928283269798072, -8.17692992259966],
              [-39.928283284958539, -8.176929891342029],
              [-39.928283299998498, -8.176929860026636],
              [-39.928283314917685, -8.176929828653975],
              [-39.928283329715917, -8.176929797224467],
              [-39.928283344392966, -8.176929765738633],
              [-39.928283358948576, -8.176929734196879],
              [-39.928283373382612, -8.17692970259969],
              [-39.92828338769479, -8.17692967094751],
              [-39.928283401884933, -8.176929639240823],
              [-39.928283415952812, -8.176929607480126],
              [-39.928283429898244, -8.176929575665852],
              [-39.928283443721, -8.176929543798471],
              [-39.928283457420903, -8.176929511878459],
              [-39.928283470997734, -8.176929479906301],
              [-39.928283484451278, -8.176929447882442],
              [-39.928283497781365, -8.176929415807361],
              [-39.928283510987782, -8.176929383681543],
              [-39.928283524070338, -8.176929351505468],
              [-39.928283537028861, -8.176929319279575],
              [-39.928283549863117, -8.176929287004359],
              [-39.928283562572958, -8.176929254680299],
              [-39.928283575158169, -8.176929222307892],
              [-39.92828358761858, -8.176929189887577],
              [-39.928283599954007, -8.176929157419835],
              [-39.928283612164243, -8.176929124905163],
              [-39.928283624249154, -8.176929092344048],
              [-39.928283636208533, -8.176929059736926],
              [-39.928283648042189, -8.176929027084338],
              [-39.928283659749994, -8.176928994386722],
              [-39.92828367133172, -8.176928961644551],
              [-39.928283682787232, -8.176928928858329],
              [-39.928283694116359, -8.176928896028546],
              [-39.928283705318925, -8.176928863155675],
              [-39.928283716394773, -8.176928830240204],
              [-39.928283727343725, -8.176928797282605],
              [-39.928283738165639, -8.176928764283383],
              [-39.928283748860352, -8.176928731243045],
              [-39.928283759427686, -8.176928698162001],
              [-39.928283769867505, -8.17692866504083],
              [-39.928283780179662, -8.176928631879941],
              [-39.928283790363977, -8.176928598679876],
              [-39.928283800420324, -8.176928565441104],
              [-39.928283810348546, -8.176928532164101],
              [-39.928283820148494, -8.176928498849387],
              [-39.928283829820025, -8.176928465497435],
              [-39.928283839363012, -8.176928432108747],
              [-39.928283848777298, -8.176928398683799],
              [-39.928283858062748, -8.176928365223096],
              [-39.928283867219214, -8.176928331727128],
              [-39.928283876246574, -8.17692829819638],
              [-39.928283885144701, -8.176928264631359],
              [-39.928283893913459, -8.176928231032557],
              [-39.9282839025527, -8.176928197400441],
              [-39.92828391106233, -8.176928163735571],
              [-39.928283919442194, -8.176928130038389],
              [-39.928283927692178, -8.176928096309393],
              [-39.928283935812175, -8.17692806254909],
              [-39.928283943802043, -8.176928028758008],
              [-39.928283951661676, -8.17692799493661],
              [-39.92828395939096, -8.176927961085392],
              [-39.928283966989788, -8.17692792720486],
              [-39.928283974458012, -8.176927893295517],
              [-39.928283981795566, -8.176927859357864],
              [-39.928283989002324, -8.176927825392415],
              [-39.928283996078179, -8.176927791399642],
              [-39.928284003023023, -8.176927757380058],
              [-39.928284009836759, -8.17692772333416],
              [-39.928284016519292, -8.176927689262453],
              [-39.928284023070511, -8.176927655165422],
              [-39.928284029490328, -8.176927621043609],
              [-39.928284035778638, -8.17692758689749],
              [-39.928284041935356, -8.176927552727593],
              [-39.928284047960418, -8.176927518534368],
              [-39.92828405385368, -8.17692748431838],
              [-39.928284059615102, -8.176927450080102],
              [-39.928284065244561, -8.176927415820044],
              [-39.928284070742009, -8.176927381538693],
              [-39.928284076107346, -8.176927347236582],
              [-39.9282840813405, -8.176927312914213],
              [-39.928284086441387, -8.17692727857208],
              [-39.928284091409942, -8.176927244210692],
              [-39.928284096246088, -8.176927209830536],
              [-39.928284100949746, -8.176927175432159],
              [-39.928284105520852, -8.176927141016019],
              [-39.928284109959336, -8.176927106582655],
              [-39.928284114265132, -8.176927072132573],
              [-39.928284118438192, -8.176927037666271],
              [-39.928284122478452, -8.176927003184256],
              [-39.928284126385812, -8.176926968687061],
              [-39.928284130160272, -8.176926934175182],
              [-39.928284133801739, -8.176926899649105],
              [-39.928284196389534, -8.176926972989683],
              [-39.92846861195418, -8.17514562342499],
              [-39.928468540676349, -8.175145582464156],
              [-39.928468542624692, -8.175145563832661],
              [-39.928468544611761, -8.175145545205238],
              [-39.928468546637561, -8.175145526581973],
              [-39.928468548702057, -8.175145507962913],
              [-39.928468550805256, -8.175145489348143],
              [-39.928468552947145, -8.175145470737748],
              [-39.928468555127715, -8.175145452131851],
              [-39.92846855734696, -8.17514543353046],
              [-39.928468559604866, -8.1751454149337],
              [-39.928468561901418, -8.175145396341652],
              [-39.928468564236617, -8.175145377754367],
              [-39.928468566610441, -8.175145359171944],
              [-39.928468569022897, -8.175145340594456],
              [-39.928468571473971, -8.175145322021997],
              [-39.928468573963634, -8.175145303454622],
              [-39.928468576491888, -8.17514528489245],
              [-39.928468579058723, -8.175145266335511],
              [-39.92846858166412, -8.175145247783924],
              [-39.928468584308085, -8.175145229237744],
              [-39.928468586990604, -8.175145210697066],
              [-39.928468589711635, -8.17514519216196],
              [-39.928468592471212, -8.175145173632513],
              [-39.92846859526928, -8.175145155108787],
              [-39.928468598105866, -8.175145136590901],
              [-39.928468600980928, -8.175145118078891],
              [-39.928468603894473, -8.175145099572839],
              [-39.928468606846465, -8.17514508107287],
              [-39.928468609836919, -8.175145062579009],
              [-39.928468612865807, -8.175145044091375],
              [-39.92846861593312, -8.175145025610025],
              [-39.928468619038846, -8.175145007135054],
              [-39.928468622182962, -8.175144988666529],
              [-39.928468625365461, -8.175144970204524],
              [-39.928468628586337, -8.175144951749115],
              [-39.928468631845575, -8.175144933300427],
              [-39.92846863514314, -8.175144914858487],
              [-39.928468638479032, -8.175144896423367],
              [-39.928468641853257, -8.175144877995201],
              [-39.928468645265767, -8.175144859574024],
              [-39.928468648716567, -8.175144841159938],
              [-39.928468652205638, -8.175144822753019],
              [-39.928468655732964, -8.175144804353309],
              [-39.928468659298524, -8.175144785960944],
              [-39.928468662902311, -8.175144767575983],
              [-39.928468666544305, -8.175144749198466],
              [-39.928468670224497, -8.17514473082851],
              [-39.928468673942866, -8.175144712466215],
              [-39.928468677699392, -8.175144694111616],
              [-39.928468681494074, -8.175144675764798],
              [-39.928468685326884, -8.175144657425877],
              [-39.928468689197814, -8.175144639094903],
              [-39.928468693106808, -8.175144620771928],
              [-39.928468697053908, -8.175144602457086],
              [-39.928468701039058, -8.175144584150413],
              [-39.928468705062272, -8.175144565852005],
              [-39.928468709123493, -8.175144547561937],
              [-39.928468713222749, -8.175144529280303],
              [-39.928468717359976, -8.17514451100716],
              [-39.928468721535182, -8.175144492742586],
              [-39.92846872574836, -8.175144474486686],
              [-39.928468729999459, -8.175144456239511],
              [-39.928468734288494, -8.175144438001142],
              [-39.928468738615429, -8.175144419771664],
              [-39.928468742980243, -8.175144401551147],
              [-39.928468747382922, -8.17514438333969],
              [-39.928468751823452, -8.175144365137362],
              [-39.928468756301811, -8.175144346944213],
              [-39.928468760817971, -8.175144328760377],
              [-39.928468765371917, -8.175144310585869],
              [-39.928468769963651, -8.175144292420812],
              [-39.928468774593121, -8.175144274265286],
              [-39.928468779260335, -8.175144256119328],
              [-39.928468783965243, -8.175144237983055],
              [-39.928468788707853, -8.175144219856515],
              [-39.928468793488122, -8.175144201739792],
              [-39.928468798306042, -8.175144183632993],
              [-39.928468803161593, -8.175144165536164],
              [-39.928468808054745, -8.175144147449391],
              [-39.928468812985493, -8.175144129372773],
              [-39.928468817953799, -8.175144111306361],
              [-39.928468822959651, -8.175144093250244],
              [-39.928468828003034, -8.175144075204498],
              [-39.928468833083912, -8.175144057169199],
              [-39.928468838202271, -8.175144039144406],
              [-39.928468843358083, -8.175144021130244],
              [-39.928468848551333, -8.175144003126741],
              [-39.928468853781993, -8.175143985133987],
              [-39.928468859050042, -8.175143967152083],
              [-39.928468864355452, -8.17514394918109],
              [-39.928468869698222, -8.175143931221079],
              [-39.928468875078316, -8.175143913272118],
              [-39.928468880495693, -8.175143895334308],
              [-39.928468885950352, -8.175143877407718],
              [-39.928468891442265, -8.175143859492396],
              [-39.928468896971417, -8.175143841588477],
              [-39.928468902537759, -8.175143823696015],
              [-39.928468908141284, -8.175143805815072],
              [-39.928468913781977, -8.17514378794572],
              [-39.928468919459689, -8.175143770089001],
              [-39.928468970859875, -8.175143813727654],
              [-39.929218041606454, -8.172796437077213],
              [-39.929218221475693, -8.172795713114782],
              [-39.930062519112056, -8.17072912528427],
              [-39.930062699816915, -8.17072876309428],
              [-39.931469288062964, -8.167631360700836],
              [-39.931469265203361, -8.167631256677392],
              [-39.931469295751953, -8.167631190126459],
              [-39.931469326839974, -8.167631123824002],
              [-39.931469358465407, -8.167631057774349],
              [-39.931469390626155, -8.167630991981822],
              [-39.931469423320138, -8.167630926450752],
              [-39.931469456545209, -8.167630861185391],
              [-39.931469490299179, -8.167630796190053],
              [-39.931469524579853, -8.167630731468963],
              [-39.931469559384979, -8.167630667026364],
              [-39.931469594712283, -8.16763060286647],
              [-39.931469630559441, -8.167630538993508],
              [-39.931469666924116, -8.167630475411636],
              [-39.931469703803934, -8.167630412125014],
              [-39.931469741196466, -8.167630349137799],
              [-39.931469779099274, -8.167630286454106],
              [-39.931469817509864, -8.167630224078039],
              [-39.931469856425728, -8.167630162013701],
              [-39.93146989584433, -8.167630100265134],
              [-39.931469935763083, -8.16763003883637],
              [-39.931469976179358, -8.167629977731465],
              [-39.931470017090518, -8.167629916954397],
              [-39.9314700584939, -8.167629856509162],
              [-39.931470100386775, -8.167629796399696],
              [-39.931470142766408, -8.16762973662996],
              [-39.931470185630012, -8.167629677203857],
              [-39.931470228974803, -8.167629618125279],
              [-39.931470272797917, -8.16762955939806],
              [-39.931470317096512, -8.167629501026102],
              [-39.931470361867667, -8.16762944301318],
              [-39.931470407108456, -8.167629385363091],
              [-39.931470452815915, -8.16762932807965],
              [-39.931470498987075, -8.167629271166572],
              [-39.931470545618879, -8.167629214627619],
              [-39.931470592708294, -8.167629158466454],
              [-39.931470640252236, -8.167629102686773],
              [-39.931470688247579, -8.16762904729222],
              [-39.931470736691189, -8.16762899228643],
              [-39.93147078557989, -8.167628937672998],
              [-39.931470834910499, -8.167628883455492],
              [-39.931470884679769, -8.167628829637486],
              [-39.93147093488443, -8.167628776222505],
              [-39.93147098552123, -8.167628723214007],
              [-39.931471036586835, -8.167628670615477],
              [-39.931471088077899, -8.167628618430385],
              [-39.931471139991054, -8.167628566662129],
              [-39.931471192322903, -8.167628515314101],
              [-39.93147124507, -8.167628464389646],
              [-39.931471298228921, -8.167628413892126],
              [-39.931471351796169, -8.167628363824832],
              [-39.931471405768256, -8.167628314191058],
              [-39.931471460141623, -8.167628264994024],
              [-39.93147151491273, -8.167628216236967],
              [-39.931471570077967, -8.167628167923072],
              [-39.931471625633748, -8.167628120055527],
              [-39.931471681576426, -8.167628072637434],
              [-39.931471737902335, -8.167628025671929],
              [-39.931471794607788, -8.167627979162052],
              [-39.931471851689075, -8.167627933110861],
              [-39.931471909142459, -8.1676278875214],
              [-39.931471966964182, -8.167627842396625],
              [-39.931472025150441, -8.167627797739488],
              [-39.93147208369745, -8.167627753552919],
              [-39.931472142601365, -8.167627709839818],
              [-39.931472201858327, -8.16762766660303],
              [-39.93147226146445, -8.167627623845407],
              [-39.931472321415868, -8.167627581569739],
              [-39.931472381708602, -8.167627539778819],
              [-39.931472442338737, -8.167627498475325],
              [-39.931472503302302, -8.167627457662],
              [-39.931472564595296, -8.167627417341501],
              [-39.931472626213719, -8.167627377516496],
              [-39.931472688153519, -8.167627338189554],
              [-39.931472750410663, -8.167627299363264],
              [-39.931472812981063, -8.167627261040181],
              [-39.931472875860614, -8.167627223222802],
              [-39.931472939045214, -8.167627185913583],
              [-39.931473002530709, -8.167627149115027],
              [-39.931473066312961, -8.167627112829502],
              [-39.931473130387786, -8.167627077059358],
              [-39.931473194750993, -8.167627041806979],
              [-39.931473259398359, -8.167627007074666],
              [-39.931473324325658, -8.167626972864689],
              [-39.931473389528641, -8.167626939179298],
              [-39.931473455003044, -8.167626906020674],
              [-39.931473520744561, -8.167626873390983],
              [-39.931473586748901, -8.167626841292394],
              [-39.93147365301175, -8.167626809726968],
              [-39.931473719528746, -8.167626778696825],
              [-39.931473786295555, -8.167626748203993],
              [-39.931473853307807, -8.167626718250403],
              [-39.931473920561103, -8.167626688838059],
              [-39.931473988051039, -8.167626659968899],
              [-39.931474055773215, -8.167626631644799],
              [-39.931474123723163, -8.167626603867625],
              [-39.93147419189647, -8.167626576639162],
              [-39.931474260288653, -8.167626549961224],
              [-39.931474328895241, -8.167626523835555],
              [-39.931474397711739, -8.167626498263868],
              [-39.931474466733789, -8.167626473247399],
              [-39.931474541642004, -8.167626555098181],
              [-39.934110244562476, -8.166683304484314],
              [-39.934110170051561, -8.166683200651729],
              [-39.93411018305634, -8.166683195986359],
              [-39.934110196053972, -8.166683191301274],
              [-39.934110209044427, -8.166683186596465],
              [-39.93411022202767, -8.166683181871946],
              [-39.934110235003679, -8.166683177127746],
              [-39.934110247972427, -8.166683172363859],
              [-39.934110260933878, -8.166683167580288],
              [-39.93411027388801, -8.166683162777082],
              [-39.93411028683478, -8.166683157954196],
              [-39.934110299774161, -8.166683153111682],
              [-39.934110312706132, -8.166683148249529],
              [-39.934110325630648, -8.166683143367766],
              [-39.934110338547704, -8.166683138466411],
              [-39.93411035145725, -8.166683133545465],
              [-39.934110364359256, -8.166683128604925],
              [-39.934110377253702, -8.166683123644827],
              [-39.934110390140553, -8.166683118665174],
              [-39.934110403019773, -8.166683113665961],
              [-39.93411041589134, -8.166683108647224],
              [-39.934110428755226, -8.166683103608944],
              [-39.934110441611388, -8.166683098551177],
              [-39.93411045445982, -8.166683093473885],
              [-39.934110467300471, -8.16668308837712],
              [-39.934110480133313, -8.166683083260882],
              [-39.934110492958325, -8.166683078125187],
              [-39.934110505775479, -8.166683072970018],
              [-39.934110518584731, -8.166683067795393],
              [-39.934110531386061, -8.166683062601365],
              [-39.934110544179433, -8.166683057387916],
              [-39.934110556964839, -8.166683052155056],
              [-39.934110569742217, -8.166683046902827],
              [-39.934110582511565, -8.166683041631178],
              [-39.934110595272841, -8.166683036340206],
              [-39.93411060802601, -8.166683031029843],
              [-39.934110620771044, -8.166683025700149],
              [-39.934110633507927, -8.166683020351128],
              [-39.934110646236618, -8.166683014982775],
              [-39.934110658957088, -8.166683009595111],
              [-39.934110671669316, -8.166683004188179],
              [-39.934110684373259, -8.166682998761946],
              [-39.93411069706891, -8.166682993316458],
              [-39.934110709756212, -8.166682987851718],
              [-39.934110722435143, -8.166682982367711],
              [-39.934110735105676, -8.166682976864498],
              [-39.934110747767804, -8.166682971342048],
              [-39.934110760421461, -8.166682965800398],
              [-39.934110773066642, -8.166682960239546],
              [-39.934110785703304, -8.166682954659525],
              [-39.934110798331425, -8.166682949060336],
              [-39.934110810950976, -8.166682943441995],
              [-39.93411082356193, -8.16668293780452],
              [-39.934110836164244, -8.16668293214793],
              [-39.934110848757911, -8.166682926472204],
              [-39.93411086134288, -8.166682920777374],
              [-39.934110873919131, -8.166682915063463],
              [-39.934110886486636, -8.166682909330488],
              [-39.934110899045379, -8.166682903578442],
              [-39.934110911595297, -8.166682897807345],
              [-39.934110924136391, -8.166682892017205],
              [-39.934110936668631, -8.16668288620806],
              [-39.934110949191947, -8.166682880379884],
              [-39.934110961706359, -8.166682874532745],
              [-39.934110974211833, -8.166682868666603],
              [-39.934110986708326, -8.166682862781483],
              [-39.934110999195795, -8.166682856877447],
              [-39.93411101167424, -8.166682850954444],
              [-39.934111024143604, -8.166682845012511],
              [-39.934111036603888, -8.166682839051685],
              [-39.934111049055041, -8.166682833071954],
              [-39.934111061497035, -8.16668282707333],
              [-39.934111073929856, -8.166682821055844],
              [-39.934111086353468, -8.166682815019509],
              [-39.934111098767843, -8.166682808964309],
              [-39.934111111172939, -8.1666828028903],
              [-39.934111123568748, -8.166682796797474],
              [-39.934111135955234, -8.166682790685837],
              [-39.934111148332342, -8.166682784555423],
              [-39.934111160700091, -8.166682778406226],
              [-39.934111173058419, -8.166682772238289],
              [-39.934111185407303, -8.166682766051588],
              [-39.934111197746724, -8.166682759846175],
              [-39.934111210076644, -8.16668275362205],
              [-39.934111222397043, -8.166682747379198],
              [-39.934111234707878, -8.166682741117683],
              [-39.934111247009142, -8.166682734837476],
              [-39.934111259300785, -8.166682728538646],
              [-39.934111271582786, -8.166682722221132],
              [-39.934111283855124, -8.166682715885031],
              [-39.934111296117763, -8.166682709530317],
              [-39.934111308370674, -8.16668270315698],
              [-39.93411132061383, -8.166682696765061],
              [-39.934111332847202, -8.166682690354587],
              [-39.934111345070761, -8.166682683925572],
              [-39.9341113572845, -8.166682677477977],
              [-39.934111369488342, -8.166682671011893],
              [-39.934111381682314, -8.166682664527269],
              [-39.934111393866345, -8.166682658024168],
              [-39.934111406040429, -8.166682651502594],
              [-39.934111418204544, -8.166682644962581],
              [-39.934111513874392, -8.166682668450242],
              [-39.935335293711326, -8.166023493796223],
              [-39.935335266578008, -8.166023439175541],
              [-39.935335280455575, -8.166023431716088],
              [-39.935335294346118, -8.166023424280676],
              [-39.935335308249599, -8.166023416869292],
              [-39.93533532216599, -8.166023409482003],
              [-39.935335336095257, -8.166023402118823],
              [-39.935335350037306, -8.16602339477974],
              [-39.935335363992138, -8.166023387464818],
              [-39.935335377959717, -8.166023380174057],
              [-39.935335391939972, -8.166023372907475],
              [-39.935335405932889, -8.166023365665104],
              [-39.935335419938404, -8.166023358446981],
              [-39.935335433956482, -8.166023351253088],
              [-39.935335447987086, -8.166023344083497],
              [-39.93533546203016, -8.16602333693816],
              [-39.935335476085669, -8.166023329817158],
              [-39.935335490153577, -8.166023322720509],
              [-39.935335504233834, -8.166023315648221],
              [-39.935335518326397, -8.166023308600296],
              [-39.935335532431225, -8.166023301576805],
              [-39.935335546548281, -8.166023294577712],
              [-39.935335560677515, -8.166023287603091],
              [-39.935335574818907, -8.166023280652915],
              [-39.935335588972364, -8.166023273727237],
              [-39.935335603137894, -8.166023266826064],
              [-39.935335617315431, -8.166023259949407],
              [-39.935335631504927, -8.1660232530973],
              [-39.93533564570636, -8.166023246269797],
              [-39.935335659919673, -8.166023239466861],
              [-39.935335674144802, -8.166023232688525],
              [-39.935335688381763, -8.166023225934831],
              [-39.935335702630454, -8.166023219205782],
              [-39.935335716890862, -8.166023212501425],
              [-39.935335731162937, -8.166023205821734],
              [-39.935335745446636, -8.166023199166785],
              [-39.935335759741918, -8.166023192536537],
              [-39.935335774048724, -8.166023185931076],
              [-39.935335788367041, -8.166023179350368],
              [-39.935335802696791, -8.166023172794468],
              [-39.935335817037981, -8.166023166263377],
              [-39.935335831390503, -8.166023159757119],
              [-39.935335845754359, -8.166023153275699],
              [-39.935335860129499, -8.166023146819157],
              [-39.935335874515864, -8.1660231403875],
              [-39.935335888913428, -8.166023133980788],
              [-39.935335903322141, -8.166023127598965],
              [-39.935335917741959, -8.166023121242114],
              [-39.935335932172826, -8.166023114910221],
              [-39.935335946614714, -8.166023108603303],
              [-39.935335961067587, -8.166023102321427],
              [-39.935335975531387, -8.166023096064544],
              [-39.935335990006067, -8.166023089832716],
              [-39.935336004491596, -8.166023083625984],
              [-39.935336018987911, -8.166023077444311],
              [-39.935336033494998, -8.166023071287732],
              [-39.935336048012786, -8.166023065156262],
              [-39.935336062541261, -8.166023059049953],
              [-39.935336077080329, -8.166023052968805],
              [-39.935336091629999, -8.166023046912796],
              [-39.935336106190185, -8.166023040882006],
              [-39.93533612076088, -8.166023034876423],
              [-39.935336135342027, -8.166023028896037],
              [-39.935336149933569, -8.166023022940964],
              [-39.935336164535478, -8.166023017011119],
              [-39.935336179147697, -8.166023011106553],
              [-39.935336193770191, -8.166023005227299],
              [-39.935336208402916, -8.166022999373379],
              [-39.935336223045823, -8.166022993544786],
              [-39.935336237698856, -8.166022987741522],
              [-39.935336252361999, -8.166022981963641],
              [-39.93533626703519, -8.166022976211158],
              [-39.935336281718399, -8.166022970484057],
              [-39.935336296411549, -8.166022964782405],
              [-39.935336311114625, -8.166022959106183],
              [-39.935336325827578, -8.166022953455428],
              [-39.935336340550357, -8.16602294783012],
              [-39.935336355282921, -8.166022942230331],
              [-39.93533637002524, -8.166022936656042],
              [-39.935336384777237, -8.166022931107282],
              [-39.935336399538905, -8.166022925584045],
              [-39.935336414310157, -8.166022920086387],
              [-39.935336429090981, -8.166022914614301],
              [-39.935336443881326, -8.166022909167813],
              [-39.935336458681149, -8.166022903746908],
              [-39.935336473490388, -8.16602289835166],
              [-39.935336488309026, -8.166022892982046],
              [-39.935336503136988, -8.1660228876381],
              [-39.93533651797425, -8.166022882319822],
              [-39.935336532820756, -8.166022877027215],
              [-39.935336547676478, -8.166022871760326],
              [-39.935336562541352, -8.166022866519176],
              [-39.935336577415349, -8.166022861303745],
              [-39.935336592298405, -8.16602285611407],
              [-39.935336607190493, -8.166022850950164],
              [-39.935336622091555, -8.166022845812028],
              [-39.935336637001562, -8.1660228406997],
              [-39.935336651920466, -8.166022835613189],
              [-39.935336666848201, -8.166022830552519],
              [-39.935336681784726, -8.166022825517691],
              [-39.935336696729912, -8.166022820510182],
              [-39.935336744559052, -8.166022857339236],
              [-39.936737604511499, -8.165554738600783],
              [-39.936737514019427, -8.165554678939731],
              [-39.936737683721759, -8.165554620358975],
              [-39.936737852227672, -8.16555455844423],
              [-39.936738019471186, -8.165554493219688],
              [-39.93673818538683, -8.165554424710926],
              [-39.936738349909646, -8.16555435294474],
              [-39.936738512975225, -8.165554277949234],
              [-39.936738674519738, -8.165554199753778],
              [-39.936738834479932, -8.165554118388945],
              [-39.936738992793181, -8.165554033886636],
              [-39.936739149397532, -8.165553946279912],
              [-39.936739304231651, -8.165553855603077],
              [-39.936739457234943, -8.165553761891635],
              [-39.936739608347487, -8.165553665182246],
              [-39.936739757510132, -8.165553565512766],
              [-39.936739904664499, -8.16555346292226],
              [-39.936740049752956, -8.165553357450859],
              [-39.93674019271873, -8.165553249139865],
              [-39.936740333505824, -8.165553138031672],
              [-39.936740472059128, -8.165553024169789],
              [-39.936740608324399, -8.165552907598785],
              [-39.936740742248318, -8.16555278836428],
              [-39.936740873778433, -8.165552666512964],
              [-39.936741002863243, -8.165552542092541],
              [-39.93674112945223, -8.165552415151735],
              [-39.936741253495832, -8.165552285740223],
              [-39.936741374945463, -8.165552153908671],
              [-39.936741493753622, -8.165552019708702],
              [-39.936741609873778, -8.16555188319284],
              [-39.936741723260461, -8.165551744414515],
              [-39.936741833869284, -8.165551603428085],
              [-39.93674194165694, -8.165551460288736],
              [-39.936742046581273, -8.165551315052493],
              [-39.936742148601155, -8.165551167776254],
              [-39.936742247676669, -8.165551018517618],
              [-39.936742343769033, -8.165550867335037],
              [-39.936742436840625, -8.165550714287718],
              [-39.936742526854985, -8.165550559435546],
              [-39.936742613776907, -8.165550402839186],
              [-39.936742697572349, -8.165550244559899],
              [-39.936742778208504, -8.165550084659671],
              [-39.936742855653804, -8.165549923201095],
              [-39.936742929877944, -8.165549760247378],
              [-39.936743000851834, -8.165549595862323],
              [-39.936743068547734, -8.165549430110309],
              [-39.936743132939107, -8.165549263056185],
              [-39.936743194000748, -8.165549094765362],
              [-39.936743251708762, -8.165548925303712],
              [-39.936743306040562, -8.165548754737594],
              [-39.936743356974866, -8.16554858313377],
              [-39.936743404491743, -8.165548410559435],
              [-39.936743448572571, -8.165548237082135],
              [-39.936743489200111, -8.165548062769801],
              [-39.93674352635847, -8.165547887690662],
              [-39.936743560033079, -8.16554771191327],
              [-39.936743590210767, -8.165547535506438],
              [-39.936743616879703, -8.165547358539191],
              [-39.936743640029462, -8.165547181080848],
              [-39.936743659650979, -8.165547003200887],
              [-39.936743675736558, -8.165546824968915],
              [-39.936743688279925, -8.165546646454724],
              [-39.936743697276171, -8.165546467728209],
              [-39.936743702721735, -8.165546288859334],
              [-39.936743704614528, -8.165546109918104],
              [-39.936743702953791, -8.165545930974584],
              [-39.936743697740184, -8.165545752098843],
              [-39.936743688975739, -8.165545573360898],
              [-39.93674367666388, -8.165545394830687],
              [-39.936743660809448, -8.165545216578169],
              [-39.936743641418609, -8.165545038673065],
              [-39.936743618499008, -8.165544861185088],
              [-39.936743592059585, -8.165544684183663],
              [-39.936743562110706, -8.165544507738129],
              [-39.936743528664067, -8.165544331917522],
              [-39.936743491732791, -8.165544156790707],
              [-39.936743451331338, -8.165543982426183],
              [-39.936743407475511, -8.165543808892297],
              [-39.936743360182469, -8.165543636256924],
              [-39.936743309470742, -8.165543464587666],
              [-39.936743255360192, -8.165543293951716],
              [-39.936743197871991, -8.165543124415942],
              [-39.93674313702865, -8.165542956046647],
              [-39.936743072853965, -8.165542788909754],
              [-39.936743005373089, -8.165542623070667],
              [-39.936742934612411, -8.165542458594397],
              [-39.936742860599686, -8.16554229554527],
              [-39.936742783363819, -8.165542133987099],
              [-39.936742702935092, -8.165541973983196],
              [-39.936742619344997, -8.165541815596187],
              [-39.936742532626219, -8.165541658888001],
              [-39.936742442812751, -8.165541503920075],
              [-39.936742349939706, -8.165541350753077],
              [-39.936742254043502, -8.165541199446888],
              [-39.936742155161632, -8.165541050060808],
              [-39.936742053332821, -8.165540902653344],
              [-39.936741948596939, -8.165540757282139],
              [-39.936741840994998, -8.165540614004135],
              [-39.936741730569096, -8.165540472875387],
              [-39.936741617362479, -8.165540333951204],
              [-39.936741501419469, -8.165540197285926],
              [-39.936741564662029, -8.165540258477598],
              [-39.936184277766678, -8.164896413994748],
              [-39.9361842219921, -8.164896344118972],
              [-39.936184191165339, -8.164896308689308],
              [-39.936184160154653, -8.16489627341935],
              [-39.936184128960868, -8.164896238310073],
              [-39.936184097584814, -8.164896203362403],
              [-39.936184066027337, -8.164896168577297],
              [-39.936184034289298, -8.164896133955688],
              [-39.936184002371533, -8.164896099498488],
              [-39.936183970274918, -8.16489606520663],
              [-39.936183938000298, -8.164896031081049],
              [-39.936183905548553, -8.164895997122628],
              [-39.936183872920537, -8.164895963332317],
              [-39.936183840117167, -8.164895929710992],
              [-39.93618380713928, -8.164895896259598],
              [-39.936183773987786, -8.164895862979023],
              [-39.936183740663552, -8.164895829870122],
              [-39.936183707167515, -8.164895796933839],
              [-39.936183673500551, -8.164895764171032],
              [-39.936183639663561, -8.16489573158259],
              [-39.936183605657448, -8.164895699169371],
              [-39.936183571483156, -8.164895666932262],
              [-39.936183537141588, -8.164895634872112],
              [-39.936183502633668, -8.164895602989803],
              [-39.936183467960305, -8.164895571286193],
              [-39.936183433122451, -8.16489553976211],
              [-39.936183398121038, -8.164895508418425],
              [-39.936183362957017, -8.164895477255961],
              [-39.936183327631312, -8.164895446275592],
              [-39.936183292144896, -8.164895415478108],
              [-39.936183256498694, -8.164895384864352],
              [-39.936183220693685, -8.164895354435158],
              [-39.93618318473083, -8.164895324191354],
              [-39.936183148611093, -8.164895294133727],
              [-39.936183112335442, -8.164895264263086],
              [-39.936183075904857, -8.164895234580237],
              [-39.936183039320319, -8.164895205085998],
              [-39.936183002582808, -8.16489517578113],
              [-39.936182965693291, -8.164895146666423],
              [-39.936182928652791, -8.164895117742697],
              [-39.936182891462295, -8.164895089010708],
              [-39.936182854122805, -8.164895060471215],
              [-39.936182816635309, -8.164895032124972],
              [-39.936182779000831, -8.1648950039728],
              [-39.936182741220378, -8.164894976015407],
              [-39.936182703294953, -8.164894948253549],
              [-39.936182665225587, -8.164894920687981],
              [-39.936182627013309, -8.164894893319437],
              [-39.936182588659143, -8.164894866148664],
              [-39.936182550164112, -8.164894839176396],
              [-39.936182511529246, -8.164894812403343],
              [-39.936182472755611, -8.164894785830239],
              [-39.936182433844223, -8.164894759457804],
              [-39.936182394796134, -8.164894733286712],
              [-39.936182355612395, -8.164894707317707],
              [-39.936182316294065, -8.16489468155145],
              [-39.936182276842189, -8.164894655988673],
              [-39.936182237257832, -8.164894630630025],
              [-39.936182197542074, -8.164894605476212],
              [-39.936182157695946, -8.164894580527907],
              [-39.936182117720563, -8.164894555785764],
              [-39.936182077616962, -8.164894531250468],
              [-39.936182037386246, -8.164894506922675],
              [-39.936181997029486, -8.164894482803046],
              [-39.93618195654777, -8.164894458892194],
              [-39.936181915942178, -8.164894435190771],
              [-39.936181875213812, -8.164894411699441],
              [-39.936181834363772, -8.164894388418839],
              [-39.936181793393125, -8.164894365349546],
              [-39.936181752303014, -8.164894342492223],
              [-39.936181711094513, -8.16489431984747],
              [-39.936181669768743, -8.164894297415907],
              [-39.936181628326814, -8.164894275198108],
              [-39.936181586769834, -8.164894253194698],
              [-39.936181545098925, -8.164894231406269],
              [-39.93618150331519, -8.164894209833413],
              [-39.936181461419785, -8.164894188476689],
              [-39.936181419413813, -8.164894167336662],
              [-39.936181377298418, -8.164894146413934],
              [-39.936181335074714, -8.164894125709006],
              [-39.936181292743861, -8.164894105222512],
              [-39.936181250306944, -8.164894084954971],
              [-39.936181207765188, -8.164894064906935],
              [-39.936181165119663, -8.164894045078912],
              [-39.936181122371536, -8.164894025471458],
              [-39.936181079521972, -8.164894006085113],
              [-39.936181036572108, -8.164893986920363],
              [-39.936180993523109, -8.164893967977742],
              [-39.936180950376119, -8.164893949257769],
              [-39.936180907132304, -8.164893930760931],
              [-39.936180863792828, -8.164893912487731],
              [-39.936180820358857, -8.164893894438686],
              [-39.936180776831556, -8.164893876614233],
              [-39.93618073321209, -8.164893859014896],
              [-39.93618068950164, -8.164893841641101],
              [-39.936180645701377, -8.164893824493344],
              [-39.936180601812481, -8.164893807572087],
              [-39.936180557836131, -8.164893790877782],
              [-39.936180513773508, -8.164893774410869],
              [-39.936180469625796, -8.164893758171811],
              [-39.936180425394177, -8.164893742161034],
              [-39.936180459326629, -8.164893799962158],
              [-39.935144267825123, -8.164521758362227],
              [-39.935144192021824, -8.164521675522526],
              [-39.935144183086663, -8.164521672319545],
              [-39.935144174148149, -8.164521669125891],
              [-39.935144165206289, -8.164521665941557],
              [-39.935144156261074, -8.164521662766536],
              [-39.935144147312556, -8.164521659600849],
              [-39.935144138360698, -8.164521656444471],
              [-39.935144129405522, -8.164521653297435],
              [-39.93514412044707, -8.16452165015974],
              [-39.935144111485315, -8.164521647031384],
              [-39.935144102520304, -8.164521643912387],
              [-39.935144093551976, -8.164521640802711],
              [-39.935144084580429, -8.164521637702393],
              [-39.935144075605621, -8.164521634611399],
              [-39.935144066627565, -8.164521631529814],
              [-39.935144057646283, -8.164521628457551],
              [-39.935144048661783, -8.164521625394659],
              [-39.935144039674064, -8.164521622341146],
              [-39.935144030683148, -8.164521619296991],
              [-39.935144021689048, -8.164521616262206],
              [-39.935144012691765, -8.164521613236829],
              [-39.935144003691306, -8.16452161022081],
              [-39.935143994687692, -8.164521607214182],
              [-39.935143985680917, -8.164521604216926],
              [-39.935143976671, -8.164521601229062],
              [-39.93514396765795, -8.164521598250587],
              [-39.935143958641788, -8.164521595281542],
              [-39.935143949622514, -8.164521592321865],
              [-39.935143940600135, -8.164521589371599],
              [-39.935143931574643, -8.164521586430721],
              [-39.935143922546111, -8.164521583499303],
              [-39.935143913514466, -8.164521580577254],
              [-39.935143904479766, -8.164521577664651],
              [-39.935143895442025, -8.164521574761437],
              [-39.935143886401228, -8.164521571867679],
              [-39.935143877357412, -8.164521568983295],
              [-39.935143868310561, -8.164521566108389],
              [-39.935143859260705, -8.164521563242889],
              [-39.93514385020783, -8.164521560386829],
              [-39.935143841151984, -8.164521557540228],
              [-39.935143832093146, -8.164521554703033],
              [-39.935143823031325, -8.164521551875312],
              [-39.935143813966555, -8.164521549057032],
              [-39.935143804898814, -8.164521546248212],
              [-39.935143795828132, -8.16452154344881],
              [-39.93514378675453, -8.164521540658853],
              [-39.935143777677993, -8.164521537878402],
              [-39.93514376859855, -8.164521535107379],
              [-39.935143759516194, -8.16452153234583],
              [-39.935143750430939, -8.164521529593769],
              [-39.935143741342806, -8.164521526851162],
              [-39.935143732251802, -8.164521524118054],
              [-39.935143723157928, -8.164521521394382],
              [-39.935143714061198, -8.164521518680257],
              [-39.935143704961632, -8.164521515975567],
              [-39.935143695859217, -8.164521513280368],
              [-39.935143686754003, -8.164521510594646],
              [-39.935143677645947, -8.164521507918463],
              [-39.935143668535105, -8.16452150525174],
              [-39.935143659421442, -8.164521502594489],
              [-39.935143650305022, -8.164521499946764],
              [-39.935143641185824, -8.164521497308577],
              [-39.935143632063848, -8.164521494679867],
              [-39.935143622939115, -8.164521492060665],
              [-39.935143613811647, -8.164521489450987],
              [-39.935143604681443, -8.164521486850816],
              [-39.935143595548524, -8.16452148426017],
              [-39.935143586412877, -8.164521481679047],
              [-39.935143577274523, -8.164521479107437],
              [-39.935143568133483, -8.164521476545362],
              [-39.935143558989751, -8.164521473992798],
              [-39.935143549843346, -8.164521471449792],
              [-39.935143540694284, -8.164521468916323],
              [-39.935143531542558, -8.164521466392387],
              [-39.935143522388174, -8.164521463877985],
              [-39.935143513231182, -8.164521461373123],
              [-39.935143504071547, -8.164521458877823],
              [-39.935143494909312, -8.164521456392064],
              [-39.935143485744454, -8.164521453915857],
              [-39.935143476577004, -8.164521451449216],
              [-39.935143467406981, -8.164521448992131],
              [-39.935143458234386, -8.164521446544565],
              [-39.935143449059233, -8.164521444106596],
              [-39.935143439881493, -8.164521441678197],
              [-39.935143430701224, -8.164521439259344],
              [-39.935143421518433, -8.164521436850045],
              [-39.93514341233309, -8.164521434450373],
              [-39.935143403145254, -8.164521432060241],
              [-39.935143393954903, -8.1645214296797],
              [-39.935143384762078, -8.164521427308738],
              [-39.935143375566753, -8.164521424947374],
              [-39.935143366368941, -8.164521422595611],
              [-39.935143357168684, -8.164521420253402],
              [-39.935143347965976, -8.164521417920783],
              [-39.935143338760803, -8.164521415597774],
              [-39.93514332955322, -8.164521413284321],
              [-39.935143320343201, -8.164521410980512],
              [-39.935143311130766, -8.164521408686278],
              [-39.935143301915936, -8.164521406401645],
              [-39.935143292698704, -8.164521404126644],
              [-39.935143359496578, -8.16452148912955],
              [-39.934011896393052, -8.164242821314085],
              [-39.934011825572796, -8.164242757333227],
              [-39.934011803792906, -8.164242751997875],
              [-39.934011781999857, -8.164242746716189],
              [-39.934011760193776, -8.164242741488126],
              [-39.934011738374799, -8.164242736313795],
              [-39.934011716543054, -8.164242731193221],
              [-39.934011694698683, -8.16424272612638],
              [-39.934011672841798, -8.164242721113377],
              [-39.934011650972565, -8.16424271615419],
              [-39.934011629091088, -8.164242711248839],
              [-39.934011607197533, -8.164242706397372],
              [-39.934011585291991, -8.164242701599845],
              [-39.934011563374639, -8.164242696856252],
              [-39.934011541445571, -8.164242692166646],
              [-39.934011519504963, -8.164242687531022],
              [-39.934011497552902, -8.164242682949455],
              [-39.934011475589557, -8.164242678421937],
              [-39.934011453615057, -8.164242673948509],
              [-39.934011431629521, -8.164242669529182],
              [-39.934011409633101, -8.164242665164002],
              [-39.934011387625915, -8.164242660853009],
              [-39.934011365608114, -8.164242656596207],
              [-39.934011343579826, -8.164242652393604],
              [-39.934011321541185, -8.16424264824529],
              [-39.934011299492319, -8.164242644151191],
              [-39.934011277433363, -8.164242640111427],
              [-39.93401125536446, -8.164242636125984],
              [-39.934011233285737, -8.164242632194876],
              [-39.934011211197337, -8.164242628318153],
              [-39.934011189099394, -8.164242624495799],
              [-39.934011166992029, -8.164242620727896],
              [-39.934011144875406, -8.164242617014395],
              [-39.934011122749617, -8.164242613355363],
              [-39.934011100614839, -8.164242609750868],
              [-39.934011078471194, -8.164242606200853],
              [-39.934011056318802, -8.164242602705343],
              [-39.934011034157813, -8.164242599264403],
              [-39.934011011988353, -8.164242595878044],
              [-39.934010989810581, -8.164242592546236],
              [-39.934010967624594, -8.164242589269083],
              [-39.934010945430565, -8.164242586046544],
              [-39.934010923228598, -8.164242582878643],
              [-39.934010901018858, -8.164242579765443],
              [-39.934010878801445, -8.164242576706908],
              [-39.934010856576535, -8.16424257370309],
              [-39.934010834344228, -8.164242570754004],
              [-39.934010812104688, -8.164242567859658],
              [-39.934010789858036, -8.164242565020075],
              [-39.934010767604398, -8.164242562235257],
              [-39.934010745343933, -8.164242559505263],
              [-39.934010723076767, -8.16424255683008],
              [-39.934010700803036, -8.164242554209697],
              [-39.934010678522867, -8.164242551644197],
              [-39.93401065623641, -8.16424254913353],
              [-39.934010633943799, -8.164242546677796],
              [-39.934010611645164, -8.164242544276913],
              [-39.93401058934063, -8.164242541930946],
              [-39.934010567030349, -8.164242539639909],
              [-39.934010544714468, -8.164242537403792],
              [-39.934010522393102, -8.164242535222618],
              [-39.934010500066414, -8.164242533096427],
              [-39.934010477734489, -8.164242531025184],
              [-39.934010455397512, -8.164242529008925],
              [-39.934010433055612, -8.164242527047714],
              [-39.934010410708908, -8.16424252514148],
              [-39.934010388357542, -8.164242523290262],
              [-39.934010366001651, -8.164242521494094],
              [-39.934010343641383, -8.164242519752987],
              [-39.934010321276865, -8.164242518066924],
              [-39.934010298908234, -8.164242516435914],
              [-39.934010276535616, -8.16424251486],
              [-39.934010254159169, -8.164242513339129],
              [-39.93401023177902, -8.164242511873388],
              [-39.934010209395289, -8.164242510462763],
              [-39.934010187008148, -8.164242509107211],
              [-39.934010164617703, -8.164242507806827],
              [-39.934010142224096, -8.164242506561566],
              [-39.934010119827491, -8.164242505371423],
              [-39.934010097427979, -8.164242504236421],
              [-39.934010075025739, -8.164242503156579],
              [-39.934010052620877, -8.1642425021319],
              [-39.934010030213543, -8.164242501162382],
              [-39.93401000780387, -8.164242500248026],
              [-39.934009985392009, -8.164242499388861],
              [-39.934009962978088, -8.164242498584873],
              [-39.934009940562234, -8.16424249783606],
              [-39.934009918144596, -8.164242497142443],
              [-39.934009895725303, -8.164242496504018],
              [-39.93400987330449, -8.164242495920799],
              [-39.934009850882305, -8.164242495392788],
              [-39.934009828458883, -8.164242494919975],
              [-39.934009806034354, -8.164242494502382],
              [-39.934009783608857, -8.164242494139998],
              [-39.934009761182523, -8.164242493832806],
              [-39.934009738755506, -8.16424249358087],
              [-39.934009716327935, -8.164242493384107],
              [-39.934009693899931, -8.164242493242567],
              [-39.934009671471649, -8.164242493156248],
              [-39.934009649043233, -8.164242493125158],
              [-39.934009626614795, -8.164242493149269],
              [-39.934009717289769, -8.164242555010047],
              [-39.933068979967707, -8.164244723977523],
              [-39.933068873850274, -8.164244662151365],
              [-39.93306884023923, -8.164244662290837],
              [-39.933068806628931, -8.164244662554312],
              [-39.933068773019848, -8.164244662941787],
              [-39.933068739412434, -8.164244663453267],
              [-39.933068705807138, -8.164244664088748],
              [-39.933068672204456, -8.164244664848214],
              [-39.933068638604823, -8.164244665731644],
              [-39.9330686050087, -8.164244666739055],
              [-39.933068571416548, -8.16424466787041],
              [-39.933068537828831, -8.164244669125695],
              [-39.933068504246009, -8.164244670504893],
              [-39.933068470668537, -8.164244672007998],
              [-39.9330684370969, -8.164244673634947],
              [-39.933068403531529, -8.164244675385783],
              [-39.933068369972894, -8.164244677260442],
              [-39.933068336421449, -8.164244679258905],
              [-39.933068302877665, -8.164244681381138],
              [-39.933068269342009, -8.164244683627125],
              [-39.933068235814922, -8.164244685996826],
              [-39.933068202296866, -8.164244688490225],
              [-39.93306816878831, -8.164244691107255],
              [-39.933068135289702, -8.164244693847918],
              [-39.933068101801517, -8.164244696712119],
              [-39.933068068324211, -8.164244699699919],
              [-39.933068034858231, -8.164244702811175],
              [-39.933068001404038, -8.164244706045901],
              [-39.93306796796211, -8.16424470940402],
              [-39.933067934532879, -8.164244712885523],
              [-39.933067901116814, -8.164244716490312],
              [-39.933067867714385, -8.164244720218404],
              [-39.933067834326039, -8.164244724069691],
              [-39.933067800952223, -8.16424472804416],
              [-39.933067767593407, -8.164244732141709],
              [-39.933067734250045, -8.164244736362335],
              [-39.933067700922614, -8.164244740705955],
              [-39.933067667611546, -8.164244745172496],
              [-39.933067634317297, -8.16424474976191],
              [-39.933067601040335, -8.164244754474129],
              [-39.933067567781123, -8.164244759309099],
              [-39.9330675345401, -8.164244764266757],
              [-39.933067501317723, -8.164244769347009],
              [-39.933067468114466, -8.164244774549806],
              [-39.933067434930756, -8.164244779875085],
              [-39.933067401767083, -8.164244785322756],
              [-39.933067368623867, -8.164244790892754],
              [-39.933067335501583, -8.164244796585006],
              [-39.933067302400673, -8.164244802399431],
              [-39.933067269321604, -8.164244808335923],
              [-39.933067236264826, -8.164244814394415],
              [-39.933067203230792, -8.164244820574842],
              [-39.933067170219942, -8.164244826877114],
              [-39.933067137232754, -8.164244833301129],
              [-39.933067104269661, -8.164244839846825],
              [-39.933067071331116, -8.164244846514094],
              [-39.933067038417562, -8.164244853302829],
              [-39.93306700552948, -8.164244860212973],
              [-39.933066972667298, -8.16424486724442],
              [-39.93306693983147, -8.164244874397045],
              [-39.93306690702245, -8.16424488167079],
              [-39.93306687424068, -8.164244889065523],
              [-39.933066841486628, -8.164244896581152],
              [-39.933066808760728, -8.164244904217609],
              [-39.933066776063427, -8.164244911974734],
              [-39.933066743395187, -8.164244919852464],
              [-39.933066710756428, -8.164244927850662],
              [-39.93306667814764, -8.164244935969208],
              [-39.93306664556922, -8.164244944208036],
              [-39.933066613021666, -8.164244952567007],
              [-39.93306658050539, -8.164244961046002],
              [-39.93306654802084, -8.164244969644887],
              [-39.933066515568477, -8.164244978363595],
              [-39.933066483148735, -8.164244987201956],
              [-39.933066450762063, -8.164244996159901],
              [-39.933066418408892, -8.164245005237257],
              [-39.933066386089706, -8.164245014433929],
              [-39.93306635380489, -8.164245023749789],
              [-39.933066321554932, -8.164245033184692],
              [-39.933066289340239, -8.164245042738532],
              [-39.933066257161286, -8.164245052411145],
              [-39.933066225018507, -8.164245062202436],
              [-39.933066192912307, -8.164245072112241],
              [-39.93306616084319, -8.164245082140427],
              [-39.933066128811532, -8.164245092286874],
              [-39.933066096817818, -8.164245102551442],
              [-39.933066064862459, -8.164245112933996],
              [-39.933066032945909, -8.164245123434375],
              [-39.933066001068596, -8.164245134052411],
              [-39.933065969230967, -8.16424514478803],
              [-39.933065937433447, -8.164245155641014],
              [-39.933065905676486, -8.164245166611252],
              [-39.933065873960501, -8.164245177698566],
              [-39.933065842285941, -8.164245188902861],
              [-39.93306581065324, -8.164245200223926],
              [-39.933065779062829, -8.16424521166164],
              [-39.93306574751513, -8.164245223215795],
              [-39.933065716010589, -8.164245234886298],
              [-39.933065684549646, -8.164245246672937],
              [-39.933065653132722, -8.164245258575596],
              [-39.933065621760228, -8.164245270594092],
              [-39.933065713727011, -8.164245364609538],
              [-39.931841862281473, -8.164716771470209],
              [-39.931841777903422, -8.164716703601011],
              [-39.931841770274943, -8.164716706543469],
              [-39.931841762649128, -8.16471670949278],
              [-39.931841755025978, -8.16471671244892],
              [-39.9318417474055, -8.164716715411927],
              [-39.931841739787707, -8.164716718381788],
              [-39.931841732172614, -8.164716721358463],
              [-39.931841724560215, -8.164716724341993],
              [-39.931841716950515, -8.164716727332353],
              [-39.931841709343516, -8.164716730329529],
              [-39.931841701739231, -8.164716733333556],
              [-39.931841694137667, -8.164716736344399],
              [-39.931841686538824, -8.164716739362056],
              [-39.931841678942732, -8.164716742386553],
              [-39.931841671349368, -8.164716745417859],
              [-39.931841663758746, -8.16471674845597],
              [-39.931841656170882, -8.164716751500912],
              [-39.931841648585774, -8.164716754552675],
              [-39.93184164100343, -8.164716757611233],
              [-39.931841633423851, -8.164716760676603],
              [-39.931841625847042, -8.164716763748796],
              [-39.931841618273019, -8.164716766827773],
              [-39.931841610701781, -8.164716769913561],
              [-39.93184160313335, -8.164716773006134],
              [-39.931841595567711, -8.164716776105493],
              [-39.931841588004865, -8.164716779211663],
              [-39.931841580444853, -8.164716782324618],
              [-39.931841572887649, -8.164716785444353],
              [-39.931841565333279, -8.164716788570857],
              [-39.931841557781731, -8.16471679170416],
              [-39.931841550233031, -8.164716794844241],
              [-39.93184154268716, -8.164716797991092],
              [-39.931841535144144, -8.164716801144746],
              [-39.931841527603993, -8.16471680430516],
              [-39.931841520066683, -8.164716807472342],
              [-39.931841512532252, -8.164716810646274],
              [-39.931841505000691, -8.164716813826985],
              [-39.931841497472, -8.164716817014462],
              [-39.931841489946223, -8.164716820208673],
              [-39.931841482423323, -8.164716823409666],
              [-39.931841474903322, -8.164716826617404],
              [-39.931841467386214, -8.164716829831896],
              [-39.931841459872018, -8.164716833053117],
              [-39.931841452360757, -8.164716836281103],
              [-39.931841444852409, -8.164716839515842],
              [-39.931841437346982, -8.164716842757326],
              [-39.931841429844489, -8.164716846005525],
              [-39.931841422344945, -8.164716849260474],
              [-39.931841414848357, -8.164716852522155],
              [-39.93184140735471, -8.164716855790546],
              [-39.931841399864027, -8.164716859065692],
              [-39.931841392376306, -8.164716862347552],
              [-39.931841384891555, -8.164716865636123],
              [-39.931841377409782, -8.16471686893143],
              [-39.931841369931, -8.164716872233447],
              [-39.931841362455202, -8.164716875542183],
              [-39.931841354982403, -8.164716878857631],
              [-39.931841347512595, -8.164716882179777],
              [-39.9318413400458, -8.164716885508641],
              [-39.931841332582025, -8.164716888844216],
              [-39.931841325121276, -8.164716892186494],
              [-39.931841317663526, -8.164716895535449],
              [-39.931841310208824, -8.164716898891101],
              [-39.931841302757164, -8.164716902253454],
              [-39.931841295308537, -8.164716905622505],
              [-39.931841287862973, -8.164716908998217],
              [-39.931841280420464, -8.164716912380626],
              [-39.931841318081602, -8.164716953610004],
              [-39.930806899771177, -8.165187650259167],
              [-39.9308068409035, -8.165187595638479],
              [-39.930806797767218, -8.165187615130598],
              [-39.930806754529456, -8.165187634398173],
              [-39.930806711191366, -8.165187653440686],
              [-39.930806667754155, -8.165187672257622],
              [-39.930806624219002, -8.165187690848448],
              [-39.930806580587053, -8.165187709212699],
              [-39.930806536859528, -8.165187727349824],
              [-39.930806493037643, -8.165187745259354],
              [-39.930806449122535, -8.165187762940795],
              [-39.930806405115455, -8.165187780393675],
              [-39.930806361017538, -8.165187797617518],
              [-39.930806316830058, -8.165187814611832],
              [-39.930806272554172, -8.165187831376162],
              [-39.930806228191081, -8.165187847910081],
              [-39.930806183742007, -8.165187864213083],
              [-39.930806139208158, -8.165187880284778],
              [-39.930806094590771, -8.165187896124708],
              [-39.930806049891025, -8.165187911732465],
              [-39.930806005110149, -8.165187927107571],
              [-39.930805960249366, -8.165187942249627],
              [-39.930805915309904, -8.165187957158267],
              [-39.930805870292971, -8.16518797183306],
              [-39.930805825199798, -8.165187986273558],
              [-39.930805780031605, -8.165188000479443],
              [-39.930805734789644, -8.165188014450273],
              [-39.930805689475136, -8.165188028185669],
              [-39.930805644089311, -8.165188041685314],
              [-39.930805598633405, -8.165188054948784],
              [-39.930805553108648, -8.165188067975738],
              [-39.930805507516311, -8.165188080765795],
              [-39.930805461857595, -8.165188093318646],
              [-39.930805416133779, -8.165188105633925],
              [-39.930805370346086, -8.165188117711327],
              [-39.930805324495758, -8.165188129550502],
              [-39.93080527858406, -8.165188141151084],
              [-39.930805232612222, -8.165188152512824],
              [-39.93080518658153, -8.165188163635378],
              [-39.930805140493213, -8.165188174518475],
              [-39.930805094348528, -8.16518818516178],
              [-39.930805048148727, -8.165188195565012],
              [-39.930805001895074, -8.165188205727921],
              [-39.93080495558884, -8.165188215650197],
              [-39.930804909231263, -8.165188225331589],
              [-39.930804862823607, -8.165188234771803],
              [-39.930804816367157, -8.165188243970604],
              [-39.930804769863158, -8.165188252927738],
              [-39.930804723312896, -8.165188261642959],
              [-39.930804676717614, -8.165188270116053],
              [-39.930804630078597, -8.165188278346756],
              [-39.930804583397126, -8.165188286334859],
              [-39.930804536674444, -8.16518829408011],
              [-39.930804489911829, -8.16518830158236],
              [-39.930804443110574, -8.165188308841383],
              [-39.930804396271945, -8.16518831585695],
              [-39.930804349397199, -8.165188322628916],
              [-39.93080430248763, -8.165188329157075],
              [-39.930804255544523, -8.165188335441226],
              [-39.930804208569128, -8.16518834148121],
              [-39.930804161562762, -8.165188347276912],
              [-39.930804114526673, -8.165188352828118],
              [-39.930804067462155, -8.165188358134669],
              [-39.930804020370481, -8.165188363196467],
              [-39.930803973252935, -8.165188368013379],
              [-39.930803926110826, -8.165188372585199],
              [-39.930803878945397, -8.165188376911875],
              [-39.930803831757977, -8.165188380993262],
              [-39.930803784549781, -8.165188384829271],
              [-39.930803737322186, -8.165188388419748],
              [-39.930803690076409, -8.165188391764634],
              [-39.930803642813757, -8.165188394863852],
              [-39.930803595535522, -8.165188397717285],
              [-39.930803548242991, -8.165188400324858],
              [-39.930803500937444, -8.165188402686523],
              [-39.930803453620165, -8.165188404802175],
              [-39.930803406292462, -8.165188406671774],
              [-39.930803358955615, -8.16518840829527],
              [-39.930803311610894, -8.165188409672673],
              [-39.930803264259616, -8.165188410803882],
              [-39.930803216903065, -8.165188411688881],
              [-39.930803169542514, -8.165188412327625],
              [-39.93080312217927, -8.165188412720134],
              [-39.930803074814605, -8.165188412866383],
              [-39.930803027449805, -8.165188412766373],
              [-39.930802980086206, -8.165188412420081],
              [-39.930802932725051, -8.165188411827543],
              [-39.930802885367648, -8.165188410988769],
              [-39.930802838015275, -8.165188409903823],
              [-39.93080279066924, -8.165188408572638],
              [-39.930802743330815, -8.165188406995327],
              [-39.930802696001287, -8.1651884051719],
              [-39.930802648681954, -8.165188403102411],
              [-39.930802601374118, -8.165188400786921],
              [-39.930802554079037, -8.165188398225514],
              [-39.930802506798017, -8.165188395418252],
              [-39.930802459532352, -8.165188392365161],
              [-39.930802412283292, -8.165188389066365],
              [-39.930802365052173, -8.165188385521976],
              [-39.930802317840275, -8.165188381732076],
              [-39.930802270649174, -8.165188377695324],
              [-39.930802363156218, -8.165188474682148],
              [-39.929774813085459, -8.165097859723296],
              [-39.929774745351672, -8.165097821032758],
              [-39.929774610252196, -8.165097808111181],
              [-39.929774475361718, -8.165097793178376],
              [-39.929774340710395, -8.165097776237655],
              [-39.929774206328318, -8.165097757292793],
              [-39.929774072245564, -8.165097736348068],
              [-39.929773938492069, -8.165097713408146],
              [-39.929773805097767, -8.16509768847814],
              [-39.929773672092459, -8.165097661563633],
              [-39.929773539505895, -8.165097632670655],
              [-39.929773407367705, -8.165097601805645],
              [-39.929773275707447, -8.165097568975511],
              [-39.929773144554531, -8.165097534187586],
              [-39.929773013938316, -8.165097497449677],
              [-39.929772883887949, -8.165097458769996],
              [-39.929772754432562, -8.16509741815711],
              [-39.929772625601061, -8.165097375620194],
              [-39.929772497422256, -8.16509733116871],
              [-39.929772369924805, -8.165097284812639],
              [-39.929772243137201, -8.165097236562284],
              [-39.929772117087822, -8.165097186428458],
              [-39.929771991804813, -8.165097134422368],
              [-39.929771867316198, -8.165097080555661],
              [-39.929771743649802, -8.16509702484036],
              [-39.929771620833279, -8.165096967288926],
              [-39.929771498894091, -8.165096907914233],
              [-39.929771377859481, -8.165096846729522],
              [-39.929771257756521, -8.16509678374852],
              [-39.929771138612075, -8.165096718985293],
              [-39.929771020452748, -8.165096652454304],
              [-39.929770903304977, -8.165096584170419],
              [-39.929770787194954, -8.165096514148919],
              [-39.929770672148621, -8.165096442405467],
              [-39.929770558191713, -8.165096368956092],
              [-39.929770445349718, -8.165096293817227],
              [-39.929770333647816, -8.165096217005628],
              [-39.929770223111042, -8.165096138538519],
              [-39.929770113764064, -8.165096058433418],
              [-39.929770005631347, -8.165095976708248],
              [-39.929769898737057, -8.165095893381251],
              [-39.929769793105102, -8.165095808471085],
              [-39.92976968875908, -8.165095721996741],
              [-39.929769585722333, -8.165095633977513],
              [-39.929769484017889, -8.1650955444331],
              [-39.929769383668514, -8.165095453383522],
              [-39.929769284696604, -8.165095360849117],
              [-39.929769187124293, -8.165095266850621],
              [-39.929769090973409, -8.165095171408996],
              [-39.929768996265437, -8.165095074545595],
              [-39.929768903021561, -8.165094976282099],
              [-39.929768811262612, -8.165094876640442],
              [-39.929768721009125, -8.165094775642917],
              [-39.929768632281252, -8.165094673312085],
              [-39.929768545098838, -8.165094569670861],
              [-39.929768459481387, -8.165094464742388],
              [-39.929768375448035, -8.165094358550114],
              [-39.929768293017567, -8.165094251117804],
              [-39.929768212208408, -8.165094142469453],
              [-39.929768133038621, -8.165094032629383],
              [-39.929768055525919, -8.165093921622113],
              [-39.929767979687618, -8.165093809472499],
              [-39.929767905540672, -8.165093696205576],
              [-39.929767833101664, -8.165093581846708],
              [-39.929767762386795, -8.165093466421427],
              [-39.929767693411868, -8.165093349955541],
              [-39.929767626192302, -8.165093232475114],
              [-39.929767560743123, -8.165093114006385],
              [-39.929767497078963, -8.165092994575838],
              [-39.929767435214067, -8.165092874210202],
              [-39.929767375162257, -8.165092752936335],
              [-39.929767316936932, -8.165092630781404],
              [-39.929767260551166, -8.165092507772686],
              [-39.92976720601753, -8.165092383937727],
              [-39.929767153348216, -8.165092259304163],
              [-39.929767102554997, -8.165092133899865],
              [-39.929767053649236, -8.165092007752875],
              [-39.929767006641882, -8.165091880891396],
              [-39.9297669615434, -8.165091753343795],
              [-39.929766918363917, -8.165091625138569],
              [-39.929766877113074, -8.165091496304401],
              [-39.929766837800081, -8.165091366870078],
              [-39.929766800433732, -8.165091236864551],
              [-39.929766765022386, -8.16509110631687],
              [-39.929766731573949, -8.165090975256255],
              [-39.929766700095932, -8.165090843711956],
              [-39.929766670595335, -8.165090711713416],
              [-39.92976664307875, -8.165090579290123],
              [-39.929766617552353, -8.165090446471703],
              [-39.929766594021849, -8.165090313287838],
              [-39.929766572492483, -8.165090179768329],
              [-39.929766552969085, -8.165090045942982],
              [-39.929766535456011, -8.165089911841742],
              [-39.92976651995717, -8.16508977749462],
              [-39.929766506476042, -8.165089642931553],
              [-39.92976649501562, -8.165089508182724],
              [-39.929766485578504, -8.165089373278244],
              [-39.929766478166762, -8.165089238248212],
              [-39.92976647278207, -8.16508910312289],
              [-39.929766469425616, -8.165088967932428],
              [-39.929766468097498, -8.165088832707912],
              [-39.929766532303383, -8.165088924816617],
              [-39.929765477716657, -8.164633000670067],
              [-39.929765413681309, -8.164632982262862],
              [-39.929765413964951, -8.164632886943192],
              [-39.929765415256774, -8.164632791631801],
              [-39.929765417556659, -8.164632696339256],
              [-39.929765420864328, -8.164632601076153],
              [-39.929765425179426, -8.164632505853103],
              [-39.929765430501476, -8.164632410680658],
              [-39.929765436829868, -8.164632315569341],
              [-39.929765444163927, -8.164632220529793],
              [-39.929765452502807, -8.164632125572536],
              [-39.929765461845605, -8.164632030708127],
              [-39.929765472191271, -8.164631935947087],
              [-39.929765483538667, -8.16463184129997],
              [-39.929765495886521, -8.16463174677726],
              [-39.929765509233476, -8.164631652389449],
              [-39.929765523578027, -8.164631558147041],
              [-39.929765538918616, -8.16463146406052],
              [-39.929765555253503, -8.16463137014029],
              [-39.929765572580898, -8.164631276396813],
              [-39.929765590898839, -8.164631182840512],
              [-39.929765610205344, -8.164631089481775],
              [-39.929765630498238, -8.16463099633093],
              [-39.92976565177527, -8.164630903398377],
              [-39.929765674034087, -8.164630810694415],
              [-39.929765697272202, -8.164630718229349],
              [-39.929765721487037, -8.164630626013432],
              [-39.929765746675905, -8.164630534056922],
              [-39.929765772836021, -8.164630442370029],
              [-39.92976579996445, -8.164630350962941],
              [-39.929765828058201, -8.164630259845834],
              [-39.929765857114155, -8.16463016902879],
              [-39.929765887129065, -8.164630078521895],
              [-39.929765918099626, -8.164629988335214],
              [-39.929765950022386, -8.164629898478786],
              [-39.92976598289377, -8.16462980896257],
              [-39.929766016710161, -8.164629719796494],
              [-39.929766051467809, -8.1646296309905],
              [-39.929766087162839, -8.164629542554428],
              [-39.929766123791268, -8.164629454498096],
              [-39.929766161349043, -8.164629366831294],
              [-39.929766199832009, -8.164629279563766],
              [-39.929766239235867, -8.16462919270521],
              [-39.929766279556254, -8.164629106265267],
              [-39.929766320788694, -8.164629020253507],
              [-39.929766362928596, -8.164628934679536],
              [-39.929766405971264, -8.164628849552821],
              [-39.929766449911959, -8.164628764882835],
              [-39.92976649474577, -8.164628680678943],
              [-39.929766540467739, -8.164628596950573],
              [-39.929766587072749, -8.164628513706969],
              [-39.929766634555669, -8.164628430957388],
              [-39.929766682911186, -8.164628348711044],
              [-39.929766732133963, -8.164628266977045],
              [-39.929766782218508, -8.164628185764485],
              [-39.92976683315927, -8.164628105082372],
              [-39.929766884950581, -8.164628024939679],
              [-39.929766937586699, -8.164627945345284],
              [-39.929766991061776, -8.164627866308042],
              [-39.929767045369857, -8.164627787836762],
              [-39.929767100504925, -8.164627709940129],
              [-39.929767156460869, -8.164627632626811],
              [-39.929767213231443, -8.164627555905385],
              [-39.929767270810359, -8.164627479784361],
              [-39.929767329191222, -8.164627404272212],
              [-39.929767388367537, -8.164627329377307],
              [-39.929767448332747, -8.164627255108016],
              [-39.929767509080172, -8.164627181472524],
              [-39.929767570603076, -8.164627108479038],
              [-39.929767632894631, -8.164627036135672],
              [-39.929767695947902, -8.164626964450465],
              [-39.929767759755897, -8.164626893431368],
              [-39.929767824311526, -8.164626823086241],
              [-39.929767889607618, -8.16462675342294],
              [-39.929767955636919, -8.164626684449178],
              [-39.929768022392096, -8.164626616172676],
              [-39.929768089865732, -8.164626548600939],
              [-39.929768158050344, -8.164626481741486],
              [-39.929768226938343, -8.164626415601759],
              [-39.929768296522091, -8.164626350189128],
              [-39.929768366793844, -8.164626285510838],
              [-39.9297684377458, -8.164626221574073],
              [-39.929768509370092, -8.16462615838592],
              [-39.929768581658763, -8.164626095953437],
              [-39.929768654603748, -8.164626034283513],
              [-39.929768728196997, -8.164625973383021],
              [-39.929768802430296, -8.164625913258726],
              [-39.929768877295409, -8.164625853917276],
              [-39.929768952784045, -8.164625795365335],
              [-39.929769028887776, -8.164625737609319],
              [-39.929769105598197, -8.1646256806557],
              [-39.929769182906753, -8.164625624510784],
              [-39.929769260804868, -8.164625569180819],
              [-39.92976933928388, -8.164625514671947],
              [-39.929769418335084, -8.164625460990196],
              [-39.9297694979497, -8.164625408141573],
              [-39.929769578118886, -8.164625356131918],
              [-39.929769658833713, -8.164625304967021],
              [-39.929769740085241, -8.164625254652583],
              [-39.929769821864447, -8.164625205194149],
              [-39.929769904162519, -8.16462515659666],
              [-39.929769998220735, -8.164625212133005],
              [-39.930894213523622, -8.163969354443305],
              [-39.930894119304511, -8.163969255391773],
              [-39.930894161098763, -8.163969231156383],
              [-39.930894203021978, -8.163969207143197],
              [-39.930894245072999, -8.163969183352865],
              [-39.930894287250624, -8.163969159786097],
              [-39.930894329553638, -8.163969136443558],
              [-39.930894371980862, -8.16396911332588],
              [-39.930894414531103, -8.163969090433763],
              [-39.930894457203117, -8.16396906776783],
              [-39.930894499995709, -8.163969045328729],
              [-39.930894542907673, -8.163969023117099],
              [-39.930894585937779, -8.163969001133559],
              [-39.930894629084825, -8.163968979378749],
              [-39.93089467234757, -8.163968957853294],
              [-39.930894715724769, -8.163968936557778],
              [-39.930894759215214, -8.163968915492813],
              [-39.930894802817683, -8.163968894659027],
              [-39.930894846530926, -8.163968874056955],
              [-39.930894890353706, -8.163968853687237],
              [-39.930894934284737, -8.163968833550385],
              [-39.930894978322833, -8.16396881364707],
              [-39.930895022466721, -8.163968793977752],
              [-39.930895066715145, -8.163968774543088],
              [-39.930895111066839, -8.163968755343564],
              [-39.93089515552056, -8.163968736379703],
              [-39.930895200075042, -8.163968717652137],
              [-39.93089524472903, -8.163968699161316],
              [-39.930895289481249, -8.163968680907791],
              [-39.930895334330408, -8.163968662892062],
              [-39.930895379275277, -8.163968645114675],
              [-39.930895424314542, -8.163968627576105],
              [-39.930895469446952, -8.163968610276875],
              [-39.930895514671192, -8.163968593217444],
              [-39.930895559986013, -8.16396857639832],
              [-39.93089560539012, -8.163968559819999],
              [-39.930895650882213, -8.163968543482914],
              [-39.930895696461008, -8.163968527387546],
              [-39.930895742125216, -8.163968511534332],
              [-39.930895787873531, -8.163968495923751],
              [-39.930895833704668, -8.163968480556219],
              [-39.93089587961731, -8.163968465432204],
              [-39.930895925610173, -8.163968450552122],
              [-39.930895971681934, -8.163968435916372],
              [-39.930896017831294, -8.163968421525384],
              [-39.930896064056938, -8.163968407379572],
              [-39.930896110357573, -8.163968393479347],
              [-39.930896156731862, -8.163968379825091],
              [-39.930896203178484, -8.163968366417183],
              [-39.93089624969614, -8.16396835325601],
              [-39.930896296283514, -8.163968340341968],
              [-39.930896342939263, -8.163968327675379],
              [-39.930896389662067, -8.16396831525666],
              [-39.930896436450617, -8.163968303086122],
              [-39.930896483303584, -8.163968291164085],
              [-39.930896530219613, -8.163968279490954],
              [-39.930896577197387, -8.163968268067016],
              [-39.930896624235579, -8.163968256892604],
              [-39.93089667133286, -8.163968245968059],
              [-39.930896718487858, -8.163968235293646],
              [-39.930896765699273, -8.163968224869716],
              [-39.930896812965749, -8.163968214696499],
              [-39.930896860285934, -8.163968204774369],
              [-39.930896907658507, -8.163968195103552],
              [-39.930896955082112, -8.16396818568432],
              [-39.930897002555412, -8.163968176516965],
              [-39.930897050077043, -8.163968167601734],
              [-39.930897097645676, -8.163968158938909],
              [-39.930897145259948, -8.16396815052866],
              [-39.930897192918501, -8.163968142371338],
              [-39.930897240619998, -8.163968134467064],
              [-39.930897288363077, -8.16396812681614],
              [-39.930897336146394, -8.163968119418755],
              [-39.930897383968578, -8.163968112275077],
              [-39.930897431828271, -8.163968105385402],
              [-39.930897479724138, -8.163968098749862],
              [-39.930897527654778, -8.163968092368648],
              [-39.930897575618872, -8.163968086241944],
              [-39.930897623615039, -8.163968080369967],
              [-39.930897671641922, -8.163968074752804],
              [-39.930897719698144, -8.163968069390679],
              [-39.930897767782383, -8.163968064283713],
              [-39.930897815893211, -8.163968059432042],
              [-39.930897864029319, -8.163968054835856],
              [-39.930897912189316, -8.163968050495225],
              [-39.930897960371816, -8.163968046410339],
              [-39.930898008575497, -8.16396804258123],
              [-39.930898056798952, -8.163968039008074],
              [-39.930898105040832, -8.163968035690925],
              [-39.930898153299772, -8.163968032629917],
              [-39.930898201574387, -8.163968029825128],
              [-39.930898249863304, -8.163968027276585],
              [-39.930898298165168, -8.163968024984452],
              [-39.9308983464786, -8.163968022948707],
              [-39.930898394802242, -8.163968021169479],
              [-39.930898443134687, -8.163968019646783],
              [-39.930898491474601, -8.163968018380656],
              [-39.930898539820603, -8.163968017371136],
              [-39.930898588171317, -8.163968016618297],
              [-39.930898636525356, -8.163968016122064],
              [-39.930898684881413, -8.163968015882519],
              [-39.930898749077613, -8.163968077804917],
              [-39.931742907378116, -8.163966136200463],
              [-39.931742875509116, -8.163966074203586],
              [-39.931742926665073, -8.163966073942252],
              [-39.931742977818757, -8.163966073393601],
              [-39.931743028968498, -8.163966072557736],
              [-39.931743080112717, -8.163966071434686],
              [-39.93174313124976, -8.163966070024388],
              [-39.931743182378021, -8.163966068326978],
              [-39.931743233495858, -8.163966066342462],
              [-39.931743284601673, -8.163966064070951],
              [-39.93174333569381, -8.163966061512488],
              [-39.931743386770691, -8.163966058667153],
              [-39.931743437830661, -8.163966055535013],
              [-39.93174348887208, -8.163966052116207],
              [-39.931743539893368, -8.163966048410822],
              [-39.931743590892886, -8.163966044419],
              [-39.931743641869012, -8.163966040140842],
              [-39.931743692820135, -8.16396603557647],
              [-39.931743743744633, -8.163966030726078],
              [-39.931743794640894, -8.163966025589811],
              [-39.931743845507285, -8.163966020167804],
              [-39.931743896342205, -8.163966014460238],
              [-39.931743947144035, -8.163966008467284],
              [-39.931743997911148, -8.163966002189149],
              [-39.93174404864196, -8.163965995626025],
              [-39.931744099334836, -8.163965988778118],
              [-39.931744149988177, -8.163965981645662],
              [-39.93174420060037, -8.163965974228853],
              [-39.931744251169803, -8.163965966527961],
              [-39.931744301694891, -8.163965958543207],
              [-39.931744352174, -8.163965950274868],
              [-39.931744402605538, -8.163965941723179],
              [-39.931744452987907, -8.163965932888434],
              [-39.931744503319521, -8.163965923770904],
              [-39.931744553598755, -8.16396591437088],
              [-39.931744603824015, -8.163965904688668],
              [-39.931744653993725, -8.163965894724575],
              [-39.931744704106272, -8.163965884478895],
              [-39.931744754160071, -8.163965873951947],
              [-39.931744804153531, -8.163965863144105],
              [-39.931744854085068, -8.163965852055696],
              [-39.931744903953096, -8.163965840687057],
              [-39.931744953756024, -8.163965829038581],
              [-39.931745003492281, -8.163965817110597],
              [-39.931745053160284, -8.163965804903519],
              [-39.931745102758434, -8.163965792417727],
              [-39.931745152285202, -8.1639657796536],
              [-39.931745201738956, -8.163965766611556],
              [-39.931745251118194, -8.163965753292018],
              [-39.931745300421284, -8.163965739695403],
              [-39.93174534964669, -8.163965725822125],
              [-39.931745398792842, -8.163965711672654],
              [-39.931745447858184, -8.1639656972474],
              [-39.93174549684116, -8.163965682546854],
              [-39.931745545740206, -8.163965667571482],
              [-39.931745594553782, -8.163965652321762],
              [-39.931745643280316, -8.163965636798167],
              [-39.931745691918273, -8.163965621001175],
              [-39.931745740466106, -8.163965604931311],
              [-39.931745788922285, -8.163965588589084],
              [-39.931745837285241, -8.163965571975002],
              [-39.931745885553468, -8.16396555508959],
              [-39.931745933725409, -8.163965537933429],
              [-39.931745981799558, -8.163965520507006],
              [-39.931746029774381, -8.163965502810898],
              [-39.931746077648341, -8.163965484845665],
              [-39.931746125419934, -8.163965466611867],
              [-39.931746173087618, -8.163965448110117],
              [-39.931746220649913, -8.163965429340953],
              [-39.931746268105279, -8.163965410305005],
              [-39.931746315452237, -8.163965391002877],
              [-39.93174636268926, -8.163965371435166],
              [-39.931746409814842, -8.163965351602521],
              [-39.931746456827511, -8.163965331505526],
              [-39.931746503725769, -8.163965311144878],
              [-39.931746550508088, -8.163965290521173],
              [-39.931746597173031, -8.163965269635105],
              [-39.931746643719109, -8.163965248487312],
              [-39.93174669014482, -8.163965227078478],
              [-39.931746736448702, -8.163965205409259],
              [-39.93174678262929, -8.163965183480389],
              [-39.931746828685093, -8.163965161292534],
              [-39.931746874614682, -8.163965138846395],
              [-39.931746920416579, -8.163965116142688],
              [-39.931746966089328, -8.163965093182146],
              [-39.931747011631487, -8.163965069965471],
              [-39.931747057041605, -8.163965046493439],
              [-39.931747102318226, -8.16396502276678],
              [-39.931747147459944, -8.163964998786216],
              [-39.931747192465302, -8.163964974552551],
              [-39.931747237332878, -8.163964950066555],
              [-39.931747282061245, -8.163964925328994],
              [-39.931747326648967, -8.163964900340641],
              [-39.931747371094659, -8.163964875102298],
              [-39.931747415396885, -8.163964849614755],
              [-39.931747459554238, -8.163964823878844],
              [-39.93174750356534, -8.163964797895392],
              [-39.93174754742877, -8.163964771665196],
              [-39.931747591143129, -8.163964745189094],
              [-39.931747634707044, -8.163964718467943],
              [-39.931747678119144, -8.163964691502578],
              [-39.931747715027782, -8.163964768483151],
              [-39.932498928973253, -8.163495174358163],
              [-39.932498901573034, -8.163495134435285],
              [-39.932498908428549, -8.163495130145925],
              [-39.93249891528022, -8.16349512585048],
              [-39.932498922128033, -8.163495121548944],
              [-39.932498928972002, -8.163495117241336],
              [-39.932498935812127, -8.163495112927651],
              [-39.932498942648373, -8.163495108607895],
              [-39.932498949480745, -8.163495104282067],
              [-39.932498956309239, -8.16349509995017],
              [-39.932498963133874, -8.163495095612198],
              [-39.932498969954601, -8.163495091268153],
              [-39.932498976771456, -8.163495086918054],
              [-39.932498983584402, -8.163495082561896],
              [-39.932498990393455, -8.163495078199686],
              [-39.932498997198607, -8.163495073831431],
              [-39.93249900399983, -8.163495069457143],
              [-39.932499010797144, -8.163495065076798],
              [-39.932499017590516, -8.163495060690432],
              [-39.932499024379979, -8.163495056298023],
              [-39.932499031165513, -8.163495051899579],
              [-39.932499037947103, -8.163495047495097],
              [-39.932499044724743, -8.163495043084588],
              [-39.932499051498425, -8.163495038668062],
              [-39.932499058268171, -8.16349503424553],
              [-39.932499065033937, -8.163495029816977],
              [-39.932499071795746, -8.163495025382414],
              [-39.932499078553583, -8.163495020941832],
              [-39.932499085307441, -8.163495016495265],
              [-39.932499092057327, -8.163495012042725],
              [-39.932499098803206, -8.163495007584128],
              [-39.932499105545105, -8.163495003119561],
              [-39.932499112282997, -8.163494998649023],
              [-39.932499119016889, -8.163494994172478],
              [-39.932499125746787, -8.163494989689964],
              [-39.93249913247265, -8.16349498520148],
              [-39.932499139194498, -8.163494980706989],
              [-39.932499145912324, -8.163494976206554],
              [-39.932499152626107, -8.163494971700157],
              [-39.932499159335869, -8.163494967187786],
              [-39.93249916604158, -8.163494962669446],
              [-39.932499172743249, -8.163494958145149],
              [-39.932499179440867, -8.163494953614896],
              [-39.932499186134429, -8.163494949078711],
              [-39.932499192823926, -8.163494944536568],
              [-39.932499199509351, -8.163494939988485],
              [-39.932499206190712, -8.163494935434473],
              [-39.932499212867981, -8.163494930874515],
              [-39.932499219541171, -8.16349492630864],
              [-39.932499226210275, -8.163494921736811],
              [-39.93249923287528, -8.16349491715906],
              [-39.932499239536185, -8.163494912575393],
              [-39.93249924619299, -8.16349490798582],
              [-39.932499252845687, -8.163494903390308],
              [-39.932499259494264, -8.163494898788926],
              [-39.93249926613872, -8.163494894181607],
              [-39.932499272779047, -8.163494889568403],
              [-39.932499279415246, -8.163494884949293],
              [-39.932499286047303, -8.163494880324283],
              [-39.932499292675217, -8.163494875693385],
              [-39.932499299298996, -8.163494871056598],
              [-39.932499305918618, -8.163494866413926],
              [-39.932499312534077, -8.163494861765383],
              [-39.932499319145364, -8.163494857110956],
              [-39.932499325752502, -8.163494852450656],
              [-39.932499332355462, -8.163494847784472],
              [-39.932499338954237, -8.163494843112467],
              [-39.932499345548834, -8.163494838434543],
              [-39.932499352139217, -8.163494833750782],
              [-39.932499358725451, -8.16349482906117],
              [-39.932499365307436, -8.163494824365703],
              [-39.932499371885243, -8.163494819664402],
              [-39.932499378458836, -8.163494814957263],
              [-39.932499385028215, -8.163494810244273],
              [-39.932499391593375, -8.163494805525461],
              [-39.932499398154285, -8.163494800800814],
              [-39.932499404710995, -8.163494796070315],
              [-39.93249941126345, -8.163494791333999],
              [-39.932499417811684, -8.163494786591846],
              [-39.932499424355647, -8.163494781843903],
              [-39.932499430895362, -8.163494777090143],
              [-39.932499437430828, -8.163494772330585],
              [-39.93249944396203, -8.16349476756522],
              [-39.932499450488955, -8.163494762794056],
              [-39.932499457011616, -8.163494758017091],
              [-39.93249946353, -8.163494753234323],
              [-39.932499470044093, -8.16349474844575],
              [-39.932499476553907, -8.163494743651391],
              [-39.932499483059416, -8.163494738851268],
              [-39.932499489560641, -8.163494734045358],
              [-39.932499496057545, -8.163494729233678],
              [-39.932499502550151, -8.163494724416232],
              [-39.932499509038443, -8.16349471959302],
              [-39.932499515522409, -8.163494714764035],
              [-39.932499522002061, -8.163494709929278],
              [-39.932499528477372, -8.163494705088777],
              [-39.932499534948349, -8.163494700242506],
              [-39.932499563311332, -8.163494720679335],
              [-39.933249290286554, -8.162932785856071],
              [-39.933249220137768, -8.162932723756475],
              [-39.933249287898171, -8.162932672464658],
              [-39.93324935517299, -8.162932620542133],
              [-39.933249421956333, -8.162932567993485],
              [-39.933249488242339, -8.162932514823289],
              [-39.933249554025167, -8.162932461036263],
              [-39.933249619299048, -8.162932406637102],
              [-39.933249684058218, -8.1629323516306],
              [-39.93324974829703, -8.162932296021577],
              [-39.933249812009791, -8.162932239814962],
              [-39.933249875190931, -8.16293218301565],
              [-39.93324993783488, -8.162932125628666],
              [-39.933249999936173, -8.162932067659035],
              [-39.933250061489289, -8.162932009111842],
              [-39.93325012248885, -8.162931949992272],
              [-39.933250182929491, -8.162931890305464],
              [-39.933250242805919, -8.162931830056694],
              [-39.933250302112846, -8.162931769251252],
              [-39.933250360845072, -8.162931707894538],
              [-39.933250418997424, -8.16293164599184],
              [-39.933250476564808, -8.162931583548696],
              [-39.933250533542157, -8.162931520570526],
              [-39.933250589924462, -8.162931457062873],
              [-39.933250645706757, -8.162931393031357],
              [-39.933250700884166, -8.162931328481591],
              [-39.933250755451809, -8.162931263419232],
              [-39.933250809404903, -8.16293119785003],
              [-39.933250862738717, -8.162931131779697],
              [-39.933250915448546, -8.162931065214071],
              [-39.933250967529766, -8.162930998159025],
              [-39.933251018977806, -8.162930930620412],
              [-39.933251069788135, -8.162930862604195],
              [-39.933251119956267, -8.162930794116326],
              [-39.933251169477842, -8.162930725162848],
              [-39.933251218348474, -8.162930655749783],
              [-39.933251266563879, -8.162930585883293],
              [-39.933251314119801, -8.162930515569471],
              [-39.933251361012083, -8.16293044481454],
              [-39.933251407236597, -8.162930373624654],
              [-39.933251452789264, -8.162930302006133],
              [-39.933251497666099, -8.162930229965227],
              [-39.933251541863164, -8.162930157508304],
              [-39.933251585376553, -8.162930084641699],
              [-39.933251628202463, -8.162930011371865],
              [-39.933251670337107, -8.162929937705165],
              [-39.933251711776798, -8.162929863648126],
              [-39.933251752517897, -8.162929789207256],
              [-39.93325179255681, -8.162929714389092],
              [-39.933251831890033, -8.162929639200192],
              [-39.933251870514098, -8.16292956364717],
              [-39.933251908425603, -8.162929487736703],
              [-39.93325194562123, -8.162929411475426],
              [-39.933251982097715, -8.162929334870055],
              [-39.933252017851828, -8.162929257927329],
              [-39.933252052880455, -8.162929180653967],
              [-39.933252087180477, -8.162929103056793],
              [-39.933252120748932, -8.162929025142656],
              [-39.933252153582849, -8.162928946918356],
              [-39.933252185679315, -8.162928868390797],
              [-39.933252217035545, -8.162928789566887],
              [-39.93325224764876, -8.162928710453512],
              [-39.933252277516281, -8.162928631057678],
              [-39.933252306635488, -8.162928551386294],
              [-39.933252335003807, -8.162928471446403],
              [-39.933252362618745, -8.162928391245062],
              [-39.933252389477893, -8.16292831078928],
              [-39.933252415578885, -8.16292823008615],
              [-39.933252440919411, -8.162928149142749],
              [-39.933252465497247, -8.16292806796619],
              [-39.933252489310249, -8.162927986563608],
              [-39.933252512356312, -8.162927904942167],
              [-39.933252534633418, -8.162927823109039],
              [-39.933252556139607, -8.162927741071405],
              [-39.933252576872995, -8.162927658836511],
              [-39.933252596831721, -8.162927576411542],
              [-39.933252616014087, -8.162927493803755],
              [-39.933252634418373, -8.162927411020423],
              [-39.933252652042952, -8.162927328068786],
              [-39.933252668886311, -8.162927244956185],
              [-39.933252684946957, -8.162927161689892],
              [-39.933252700223456, -8.162927078277232],
              [-39.933252714714499, -8.16292699472554],
              [-39.933252728418772, -8.162926911042158],
              [-39.933252741335117, -8.162926827234443],
              [-39.933252753462369, -8.162926743309761],
              [-39.933252764799462, -8.162926659275483],
              [-39.933252775345409, -8.162926575139004],
              [-39.933252785099285, -8.162926490907696],
              [-39.933252794060223, -8.162926406588983],
              [-39.933252802227436, -8.162926322190263],
              [-39.933252809600233, -8.162926237718978],
              [-39.933252816177927, -8.162926153182523],
              [-39.933252821959954, -8.162926068588357],
              [-39.933252826945818, -8.162925983943879],
              [-39.933252831135064, -8.162925899256534],
              [-39.93325283452733, -8.162925814533791],
              [-39.933252837122325, -8.162925729783069],
              [-39.933252838919813, -8.162925645011843],
              [-39.933252839919632, -8.162925560227555],
              [-39.93325284012171, -8.162925475437667],
              [-39.933252904268713, -8.162925542039725],
              [-39.933250954697307, -8.162085596825282],
              [-39.933250890560743, -8.162085534686543],
              [-39.93325089053495, -8.162085525562844],
              [-39.933250890499927, -8.162085516439186],
              [-39.93325089045566, -8.162085507315567],
              [-39.933250890402149, -8.162085498191983],
              [-39.933250890339423, -8.16208548906846],
              [-39.933250890267438, -8.16208547994502],
              [-39.933250890186223, -8.162085470821637],
              [-39.933250890095771, -8.162085461698375],
              [-39.933250889996081, -8.162085452575189],
              [-39.933250889887155, -8.162085443452122],
              [-39.933250889768992, -8.162085434329144],
              [-39.933250889641599, -8.162085425206289],
              [-39.933250889504976, -8.162085416083592],
              [-39.933250889359094, -8.16208540696103],
              [-39.93325088920399, -8.162085397838608],
              [-39.933250889039662, -8.162085388716344],
              [-39.933250888866084, -8.162085379594266],
              [-39.933250888683276, -8.162085370472367],
              [-39.933250888491237, -8.162085361350634],
              [-39.933250888289948, -8.162085352229099],
              [-39.933250888079435, -8.162085343107799],
              [-39.933250887859685, -8.16208533398669],
              [-39.933250887630692, -8.162085324865819],
              [-39.933250887392475, -8.162085315745175],
              [-39.933250887145036, -8.162085306624803],
              [-39.933250886888345, -8.162085297504621],
              [-39.933250886622425, -8.162085288384764],
              [-39.933250886347274, -8.162085279265145],
              [-39.933250886062893, -8.162085270145834],
              [-39.93325088576929, -8.162085261026817],
              [-39.933250885466428, -8.162085251908072],
              [-39.933250885154344, -8.162085242789637],
              [-39.933250884833036, -8.162085233671542],
              [-39.933250884502492, -8.162085224553769],
              [-39.933250884162725, -8.162085215436324],
              [-39.93325088381372, -8.162085206319233],
              [-39.933250883455479, -8.162085197202485],
              [-39.933250883088022, -8.162085188086099],
              [-39.93325088271132, -8.162085178970116],
              [-39.93325088232541, -8.162085169854461],
              [-39.933250881930249, -8.162085160739231],
              [-39.933250881525879, -8.162085151624428],
              [-39.933250881112265, -8.162085142510014],
              [-39.933250880689435, -8.162085133396008],
              [-39.933250880257376, -8.162085124282433],
              [-39.933250879816093, -8.162085115169312],
              [-39.933250879365573, -8.162085106056651],
              [-39.933250878905831, -8.162085096944434],
              [-39.933250878436866, -8.162085087832676],
              [-39.933250877958685, -8.162085078721391],
              [-39.933250877471266, -8.162085069610606],
              [-39.93325087697464, -8.16208506050031],
              [-39.933250876468776, -8.162085051390521],
              [-39.933250875953696, -8.162085042281213],
              [-39.933250875429387, -8.162085033172445],
              [-39.93325087489584, -8.162085024064238],
              [-39.933250874353121, -8.162085014956558],
              [-39.933250873801143, -8.162085005849438],
              [-39.933250873239963, -8.162084996742847],
              [-39.933250872669547, -8.162084987636851],
              [-39.933250872089921, -8.162084978531427],
              [-39.933250871501073, -8.162084969426564],
              [-39.933250870903009, -8.16208496032233],
              [-39.933250870295737, -8.162084951218674],
              [-39.933250869679249, -8.16208494211566],
              [-39.933250869053531, -8.162084933013242],
              [-39.933250868418604, -8.162084923911506],
              [-39.933250867774468, -8.162084914810375],
              [-39.933250867121124, -8.162084905709877],
              [-39.93325086645855, -8.162084896610104],
              [-39.933250865786775, -8.162084887510957],
              [-39.933250865105776, -8.162084878412509],
              [-39.933250864415577, -8.162084869314723],
              [-39.933250863716154, -8.162084860217627],
              [-39.933250863007544, -8.162084851121261],
              [-39.933250862289711, -8.162084842025628],
              [-39.933250861562676, -8.162084832930704],
              [-39.933250860826426, -8.162084823836542],
              [-39.93325086008096, -8.162084814743077],
              [-39.933250859326314, -8.162084805650387],
              [-39.933250858562445, -8.162084796558482],
              [-39.933250857789382, -8.16208478746732],
              [-39.933250857007103, -8.162084778376938],
              [-39.933250856215629, -8.162084769287384],
              [-39.933250855414947, -8.162084760198598],
              [-39.933250854605078, -8.162084751110633],
              [-39.933250853785985, -8.16208474202347],
              [-39.933250852957713, -8.162084732937151],
              [-39.933250852120231, -8.162084723851649],
              [-39.933250851273556, -8.16208471476704],
              [-39.933250850417679, -8.162084705683249],
              [-39.933250849552621, -8.162084696600314],
              [-39.933250848678355, -8.162084687518279],
              [-39.933250847794895, -8.162084678437127],
              [-39.933250846902254, -8.162084669356839],
              [-39.933250846000398, -8.162084660277495],
              [-39.933250845089368, -8.162084651199049],
              [-39.933250844169145, -8.162084642121487],
              [-39.933250843239726, -8.162084633044904],
              [-39.933250952598165, -8.162084692394153],
              [-39.933155025162868, -8.161152892072529],
              [-39.93315494979754, -8.161152796936403],
              [-39.933154939175367, -8.161152687119364],
              [-39.9331549298984, -8.161152577181374],
              [-39.93315492196799, -8.161152467138816],
              [-39.933154915385316, -8.161152357008076],
              [-39.933154910151359, -8.1611522468055],
              [-39.9331549062669, -8.161152136547519],
              [-39.933154903732515, -8.161152026250514],
              [-39.933154902548573, -8.161151915930903],
              [-39.933154902715273, -8.161151805605119],
              [-39.933154904232573, -8.161151695289595],
              [-39.933154907100239, -8.161151585000695],
              [-39.933154911317864, -8.161151474754861],
              [-39.93315491688481, -8.161151364568502],
              [-39.933154923800252, -8.16115125445798],
              [-39.933154932063154, -8.161151144439737],
              [-39.933154941672299, -8.161151034530086],
              [-39.933154952626261, -8.161150924745417],
              [-39.933154964923389, -8.161150815102022],
              [-39.933154978561859, -8.161150705616276],
              [-39.933154993539652, -8.161150596304427],
              [-39.933155009854524, -8.161150487182763],
              [-39.933155027504064, -8.161150378267521],
              [-39.933155046485645, -8.161150269574904],
              [-39.933155066796431, -8.161150161121085],
              [-39.933155088433409, -8.161150052922199],
              [-39.93315511139334, -8.161149944994339],
              [-39.933155135672827, -8.161149837353566],
              [-39.93315516126826, -8.161149730015907],
              [-39.933155188175839, -8.16114962299735],
              [-39.933155216391526, -8.161149516313762],
              [-39.933155245911138, -8.161149409981084],
              [-39.93315527673029, -8.161149304015087],
              [-39.933155308844398, -8.161149198431563],
              [-39.933155342248675, -8.161149093246234],
              [-39.933155376938146, -8.161148988474704],
              [-39.933155412907659, -8.161148884132608],
              [-39.933155450151858, -8.161148780235479],
              [-39.9331554886652, -8.16114867679874],
              [-39.933155528441972, -8.161148573837796],
              [-39.933155569476227, -8.161148471367992],
              [-39.933155611761862, -8.161148369404534],
              [-39.933155655292609, -8.161148267962622],
              [-39.933155700061967, -8.161148167057341],
              [-39.933155746063285, -8.161148066703722],
              [-39.933155793289721, -8.161147966916669],
              [-39.933155841734248, -8.16114786771106],
              [-39.933155891389653, -8.161147769101616],
              [-39.933155942248526, -8.161147671103043],
              [-39.933155994303341, -8.161147573729934],
              [-39.933156047546326, -8.161147476996746],
              [-39.933156101969558, -8.161147380917896],
              [-39.933156157564945, -8.161147285507651],
              [-39.933156214324214, -8.161147190780239],
              [-39.933156272238925, -8.161147096749767],
              [-39.933156331300452, -8.161147003430179],
              [-39.933156391500013, -8.16114691083539],
              [-39.933156452828641, -8.161146818979208],
              [-39.933156515277226, -8.161146727875231],
              [-39.933156578836453, -8.161146637537058],
              [-39.933156643496879, -8.16114654797812],
              [-39.933156709248884, -8.161146459211748],
              [-39.933156776082683, -8.161146371251171],
              [-39.933156843988328, -8.161146284109439],
              [-39.933156912955724, -8.161146197799559],
              [-39.93315698297458, -8.161146112334341],
              [-39.93315705403451, -8.161146027726497],
              [-39.933157126124918, -8.16114594398865],
              [-39.933157199235104, -8.161145861133239],
              [-39.933157273354155, -8.161145779172578],
              [-39.933157348471077, -8.161145698118895],
              [-39.933157424574667, -8.161145617984237],
              [-39.933157501653596, -8.161145538780554],
              [-39.933157579696427, -8.161145460519577],
              [-39.933157658691506, -8.161145383212995],
              [-39.933157738627123, -8.161145306872289],
              [-39.933157819491349, -8.161145231508838],
              [-39.933157901272168, -8.161145157133825],
              [-39.933157983957408, -8.161145083758331],
              [-39.933158067534755, -8.16114501139328],
              [-39.933158151991769, -8.161144940049452],
              [-39.933158237315929, -8.16114486973742],
              [-39.933158323494489, -8.161144800467692],
              [-39.933158410514643, -8.161144732250557],
              [-39.93315849836344, -8.161144665096172],
              [-39.933158587027819, -8.161144599014515],
              [-39.933158676494557, -8.161144534015467],
              [-39.933158766750381, -8.161144470108619],
              [-39.933158857781834, -8.161144407303517],
              [-39.933158949575379, -8.161144345609568],
              [-39.933159042117346, -8.161144285035864],
              [-39.933159135393986, -8.161144225591444],
              [-39.933159229391414, -8.16114416728519],
              [-39.93315932409562, -8.16114411012571],
              [-39.93315941949254, -8.161144054121591],
              [-39.933159515567972, -8.161143999281087],
              [-39.933159612307612, -8.1611439456124],
              [-39.933159709697073, -8.161143893123503],
              [-39.933159807721871, -8.161143841822222],
              [-39.933159906366782, -8.161143791717025],
              [-39.933159996472476, -8.161143836258802],
              [-39.933906434541271, -8.16077039276124],
              [-39.933906387449355, -8.160770320755407],
              [-39.933906426588031, -8.160770301055832],
              [-39.933906465630749, -8.160770281168103],
              [-39.933906504576598, -8.160770261092686],
              [-39.933906543424648, -8.160770240830034],
              [-39.933906582174018, -8.160770220380652],
              [-39.933906620823791, -8.160770199744954],
              [-39.933906659373065, -8.160770178923473],
              [-39.933906697820952, -8.160770157916698],
              [-39.933906736166527, -8.160770136725082],
              [-39.933906774408939, -8.160770115349136],
              [-39.933906812547256, -8.160770093789347],
              [-39.933906850580605, -8.160770072046247],
              [-39.933906888508076, -8.160770050120334],
              [-39.933906926328838, -8.160770028012124],
              [-39.933906964041959, -8.160770005722103],
              [-39.933907001646567, -8.160769983250827],
              [-39.933907039141801, -8.16076996059879],
              [-39.933907076526765, -8.160769937766549],
              [-39.933907113800622, -8.16076991475463],
              [-39.933907150962447, -8.160769891563552],
              [-39.933907188011425, -8.160769868193878],
              [-39.933907224946658, -8.160769844646129],
              [-39.93390726176731, -8.160769820920867],
              [-39.933907298472498, -8.160769797018649],
              [-39.93390733506137, -8.160769772940043],
              [-39.933907371533095, -8.160769748685583],
              [-39.933907407886792, -8.160769724255873],
              [-39.93390744412163, -8.160769699651453],
              [-39.933907480236741, -8.160769674872897],
              [-39.933907516231322, -8.160769649920796],
              [-39.933907552104507, -8.16076962479573],
              [-39.933907587855444, -8.160769599498305],
              [-39.933907623483343, -8.160769574029077],
              [-39.933907658987337, -8.160769548388659],
              [-39.933907694366603, -8.160769522577635],
              [-39.933907729620323, -8.160769496596636],
              [-39.933907764747666, -8.160769470446215],
              [-39.933907799747828, -8.160769444127036],
              [-39.933907834619973, -8.16076941763969],
              [-39.933907869363303, -8.1607693909848],
              [-39.933907903976994, -8.160769364162975],
              [-39.933907938460244, -8.160769337174854],
              [-39.933907972812257, -8.16076931002107],
              [-39.933908007032201, -8.160769282702226],
              [-39.933908041119324, -8.160769255219011],
              [-39.933908075072779, -8.160769227572025],
              [-39.933908108891814, -8.160769199761951],
              [-39.933908142575632, -8.16076917178941],
              [-39.933908176123438, -8.160769143655061],
              [-39.933908209534451, -8.160769115359564],
              [-39.933908242807881, -8.16076908690358],
              [-39.933908275942983, -8.160769058287741],
              [-39.933908308938932, -8.160769029512753],
              [-39.933908341795025, -8.160769000579283],
              [-39.933908374510452, -8.160768971487993],
              [-39.933908407084459, -8.160768942239573],
              [-39.933908439516273, -8.160768912834703],
              [-39.933908471805147, -8.16076888327405],
              [-39.933908503950342, -8.160768853558336],
              [-39.93390853595109, -8.16076882368824],
              [-39.933908567806647, -8.160768793664467],
              [-39.933908599516258, -8.160768763487715],
              [-39.933908631079206, -8.160768733158669],
              [-39.933908662494758, -8.160768702678064],
              [-39.933908693762149, -8.160768672046599],
              [-39.933908724880695, -8.160768641264978],
              [-39.933908755849615, -8.160768610333914],
              [-39.933908786668226, -8.160768579254162],
              [-39.933908817335791, -8.160768548026409],
              [-39.933908847851612, -8.160768516651412],
              [-39.933908878214943, -8.160768485129902],
              [-39.93390890842511, -8.160768453462584],
              [-39.933908938481409, -8.160768421650255],
              [-39.933908968383108, -8.160768389693592],
              [-39.933908998129532, -8.160768357593399],
              [-39.933909027719992, -8.160768325350389],
              [-39.933909057153762, -8.160768292965335],
              [-39.933909086430205, -8.160768260438971],
              [-39.933909115548616, -8.160768227772065],
              [-39.933909144508291, -8.160768194965382],
              [-39.933909173308571, -8.160768162019677],
              [-39.933909201948822, -8.160768128935731],
              [-39.933909230428306, -8.160768095714319],
              [-39.933909258746411, -8.160768062356199],
              [-39.933909286902455, -8.160768028862186],
              [-39.933909314895793, -8.160767995233016],
              [-39.933909342725748, -8.160767961469485],
              [-39.933909370391696, -8.160767927572405],
              [-39.93390939789299, -8.160767893542554],
              [-39.933909425228961, -8.160767859380714],
              [-39.933909452399, -8.160767825087675],
              [-39.933909479402473, -8.160767790664275],
              [-39.933909506238727, -8.160767756111291],
              [-39.933909532907158, -8.160767721429547],
              [-39.933909559407127, -8.160767686619845],
              [-39.933909585738022, -8.160767651682985],
              [-39.933909611899239, -8.160767616619777],
              [-39.933909637890153, -8.160767581431076],
              [-39.933909663710146, -8.160767546117681],
              [-39.933909695711563, -8.160767581491154],
              [-39.934660592283336, -8.159735425915553],
              [-39.934660507411309, -8.159735403267],
              [-39.934660513303946, -8.159735395157231],
              [-39.934660519187581, -8.159735387040975],
              [-39.934660525062199, -8.159735378918258],
              [-39.934660530927786, -8.159735370789058],
              [-39.934660536784342, -8.159735362653377],
              [-39.934660542631839, -8.159735354511248],
              [-39.934660548470305, -8.159735346362668],
              [-39.934660554299704, -8.159735338207643],
              [-39.934660560120044, -8.159735330046201],
              [-39.934660565931303, -8.159735321878349],
              [-39.934660571733502, -8.159735313704095],
              [-39.9346605775266, -8.159735305523446],
              [-39.934660583310617, -8.159735297336415],
              [-39.934660589085524, -8.159735289143001],
              [-39.934660594851344, -8.159735280943229],
              [-39.934660600608026, -8.159735272737089],
              [-39.9346606063556, -8.159735264524619],
              [-39.934660612094049, -8.159735256305822],
              [-39.934660617823354, -8.159735248080676],
              [-39.934660623543529, -8.159735239849233],
              [-39.934660629254545, -8.15973523161148],
              [-39.934660634956408, -8.159735223367425],
              [-39.93466064064912, -8.159735215117097],
              [-39.934660646332638, -8.159735206860487],
              [-39.934660652007004, -8.159735198597613],
              [-39.934660657672161, -8.159735190328496],
              [-39.93466066332816, -8.159735182053133],
              [-39.934660668974942, -8.159735173771523],
              [-39.934660674612516, -8.159735165483701],
              [-39.934660680240881, -8.159735157189665],
              [-39.934660685860045, -8.159735148889437],
              [-39.934660691469972, -8.159735140582997],
              [-39.934660697070655, -8.159735132270379],
              [-39.934660702662121, -8.1597351239516],
              [-39.93466070824433, -8.159735115626642],
              [-39.934660713817287, -8.159735107295539],
              [-39.934660719380986, -8.159735098958294],
              [-39.934660724935426, -8.159735090614902],
              [-39.934660730480587, -8.159735082265396],
              [-39.934660736016475, -8.159735073909786],
              [-39.934660741543055, -8.159735065548082],
              [-39.934660747060363, -8.159735057180264],
              [-39.934660752568362, -8.159735048806386],
              [-39.934660758067054, -8.159735040426449],
              [-39.934660763556437, -8.15973503204042],
              [-39.934660769036505, -8.159735023648361],
              [-39.934660774507236, -8.159735015250247],
              [-39.934660779968631, -8.159735006846102],
              [-39.934660785420689, -8.159734998435965],
              [-39.934660790863411, -8.159734990019786],
              [-39.934660796296768, -8.159734981597616],
              [-39.934660801720753, -8.159734973169467],
              [-39.934660807135394, -8.159734964735343],
              [-39.934660812540656, -8.159734956295262],
              [-39.934660817936539, -8.159734947849223],
              [-39.934660823323028, -8.159734939397222],
              [-39.934660828700117, -8.15973493093928],
              [-39.934660834067834, -8.159734922475414],
              [-39.934660839426115, -8.159734914005657],
              [-39.934660844774989, -8.159734905529955],
              [-39.934660850114447, -8.159734897048383],
              [-39.934660855444491, -8.159734888560914],
              [-39.934660860765099, -8.159734880067592],
              [-39.934660866076243, -8.159734871568396],
              [-39.934660871377964, -8.15973486306334],
              [-39.934660876670229, -8.159734854552443],
              [-39.934660881953029, -8.159734846035725],
              [-39.934660887226357, -8.159734837513179],
              [-39.934660892490236, -8.159734828984829],
              [-39.934660897744621, -8.159734820450669],
              [-39.934660902989535, -8.159734811910722],
              [-39.934660908224956, -8.159734803364987],
              [-39.934660913450855, -8.159734794813492],
              [-39.934660918667284, -8.159734786256225],
              [-39.934660923874176, -8.159734777693215],
              [-39.934660929071555, -8.159734769124469],
              [-39.934660934259412, -8.159734760549982],
              [-39.934660939437748, -8.159734751969792],
              [-39.934660944606556, -8.159734743383893],
              [-39.934660949765799, -8.15973473479229],
              [-39.934660954915508, -8.159734726195015],
              [-39.934660960055659, -8.159734717592068],
              [-39.934660965186239, -8.159734708983468],
              [-39.934660970307284, -8.159734700369189],
              [-39.934660975418737, -8.159734691749261],
              [-39.934660980520604, -8.159734683123725],
              [-39.934660985612879, -8.159734674492555],
              [-39.934660990695569, -8.159734665855778],
              [-39.934660995768667, -8.159734657213413],
              [-39.934661000832143, -8.15973464856541],
              [-39.934661005886035, -8.159734639911852],
              [-39.934661010930292, -8.159734631252741],
              [-39.934661015964934, -8.159734622588061],
              [-39.934661020989935, -8.159734613917825],
              [-39.934661026005323, -8.15973460524207],
              [-39.934661031011046, -8.159734596560758],
              [-39.934661036007135, -8.159734587873945],
              [-39.934661040993561, -8.159734579181631],
              [-39.934661045970316, -8.159734570483828],
              [-39.934661135003815, -8.159734610671224],
              [-39.935035035172007, -8.159080293078443],
              [-39.93503497147195, -8.159080224608134],
              [-39.935035005952976, -8.159080163722393],
              [-39.935035039960646, -8.159080102572872],
              [-39.935035073492941, -8.159080041163243],
              [-39.935035106547829, -8.159079979497188],
              [-39.935035139123357, -8.159079917578413],
              [-39.935035171217585, -8.159079855410562],
              [-39.93503520282858, -8.159079792997387],
              [-39.935035233954444, -8.159079730342606],
              [-39.935035264593346, -8.15907966744998],
              [-39.935035294743457, -8.159079604323241],
              [-39.935035324402932, -8.159079540966165],
              [-39.935035353570029, -8.159079477382567],
              [-39.935035382243015, -8.159079413576219],
              [-39.935035410420163, -8.15907934955092],
              [-39.935035438099789, -8.15907928531051],
              [-39.935035465280237, -8.159079220858837],
              [-39.93503549195988, -8.159079156199748],
              [-39.935035518137148, -8.159079091337111],
              [-39.935035543810443, -8.159079026274785],
              [-39.935035568978272, -8.159078961016673],
              [-39.935035593639086, -8.159078895566655],
              [-39.935035617791435, -8.159078829928658],
              [-39.935035641433885, -8.159078764106598],
              [-39.935035664564985, -8.159078698104414],
              [-39.935035687183401, -8.159078631926075],
              [-39.93503570928776, -8.159078565575474],
              [-39.935035730876741, -8.159078499056598],
              [-39.935035751949052, -8.159078432373436],
              [-39.935035772503433, -8.159078365529982],
              [-39.93503579253867, -8.159078298530213],
              [-39.935035812053549, -8.159078231378137],
              [-39.935035831046903, -8.159078164077775],
              [-39.935035849517611, -8.159078096633099],
              [-39.93503586746457, -8.159078029048203],
              [-39.935035884886702, -8.159077961327093],
              [-39.93503590178296, -8.159077893473818],
              [-39.935035918152337, -8.159077825492446],
              [-39.935035933993852, -8.159077757387008],
              [-39.93503594930656, -8.159077689161599],
              [-39.935035964089565, -8.159077620820295],
              [-39.935035978341958, -8.159077552367194],
              [-39.9350359920629, -8.159077483806339],
              [-39.935036005251575, -8.159077415141864],
              [-39.935036017907173, -8.15907734637786],
              [-39.935036030028968, -8.159077277518437],
              [-39.935036041616222, -8.159077208567698],
              [-39.935036052668231, -8.159077139529783],
              [-39.935036063184356, -8.159077070408808],
              [-39.935036073163957, -8.159077001208894],
              [-39.935036082606437, -8.159076931934196],
              [-39.935036091511236, -8.159076862588854],
              [-39.93503609987782, -8.159076793176981],
              [-39.93503610770567, -8.159076723702743],
              [-39.935036114994368, -8.159076654170279],
              [-39.93503612174343, -8.15907658458379],
              [-39.935036127952486, -8.159076514947383],
              [-39.935036133621139, -8.159076445265221],
              [-39.935036138749062, -8.159076375541483],
              [-39.935036143335942, -8.159076305780335],
              [-39.935036147381524, -8.159076235985966],
              [-39.935036150885537, -8.159076166162489],
              [-39.93503615384779, -8.159076096314116],
              [-39.935036156268112, -8.159076026445012],
              [-39.93503615814636, -8.159075956559352],
              [-39.935036159482387, -8.159075886661313],
              [-39.935036160276155, -8.159075816755093],
              [-39.935036160527581, -8.15907574684487],
              [-39.935036160236677, -8.159075676934751],
              [-39.935036159403467, -8.159075607029001],
              [-39.935036158027955, -8.159075537131734],
              [-39.935036156110264, -8.159075467247151],
              [-39.935036153650522, -8.159075397379421],
              [-39.935036150648827, -8.159075327532719],
              [-39.935036147105407, -8.159075257711223],
              [-39.935036143020433, -8.159075187919095],
              [-39.935036138394167, -8.159075118160558],
              [-39.93503613322688, -8.159075048439707],
              [-39.935036127518892, -8.159074978760726],
              [-39.935036121270542, -8.159074909127797],
              [-39.935036114482202, -8.159074839545063],
              [-39.935036107154268, -8.159074770016733],
              [-39.935036099287181, -8.159074700546892],
              [-39.935036090881425, -8.15907463113972],
              [-39.935036081937476, -8.159074561799374],
              [-39.935036072455894, -8.159074492529975],
              [-39.935036062437241, -8.159074423335666],
              [-39.935036051882086, -8.159074354220603],
              [-39.935036040791111, -8.159074285188879],
              [-39.935036029164934, -8.159074216244665],
              [-39.935036017004272, -8.159074147392047],
              [-39.93503600430985, -8.159074078635168],
              [-39.935035991082422, -8.159074009978086],
              [-39.935035977322784, -8.159073941424916],
              [-39.935035963031751, -8.159073872979825],
              [-39.935035948210178, -8.159073804646807],
              [-39.935035932858945, -8.159073736429985],
              [-39.93503591697899, -8.159073668333463],
              [-39.935035900571243, -8.159073600361264],
              [-39.935035883636687, -8.159073532517491],
              [-39.935035927299616, -8.159073598195382],
              [-39.934846481896329, -8.158326971428302],
              [-39.934846441761827, -8.158326913966963],
              [-39.934846439419289, -8.158326904754967],
              [-39.934846437067023, -8.15832689554542],
              [-39.934846434705037, -8.158326886338353],
              [-39.934846432333366, -8.158326877133733],
              [-39.934846429951982, -8.158326867931629],
              [-39.934846427560885, -8.158326858732025],
              [-39.934846425160096, -8.158326849534921],
              [-39.934846422749608, -8.158326840340314],
              [-39.934846420329428, -8.15832683114826],
              [-39.934846417899557, -8.158326821958749],
              [-39.934846415459994, -8.158326812771776],
              [-39.934846413010739, -8.158326803587364],
              [-39.93484641055182, -8.158326794405523],
              [-39.934846408083203, -8.158326785226263],
              [-39.934846405604915, -8.158326776049584],
              [-39.934846403116943, -8.158326766875525],
              [-39.934846400619321, -8.158326757704064],
              [-39.934846398111993, -8.158326748535204],
              [-39.934846395595024, -8.158326739368995],
              [-39.934846393068376, -8.158326730205435],
              [-39.93484639053208, -8.158326721044507],
              [-39.93484638798612, -8.158326711886231],
              [-39.934846385430497, -8.158326702730658],
              [-39.934846382865224, -8.158326693577749],
              [-39.93484638029031, -8.158326684427507],
              [-39.934846377705746, -8.158326675280003],
              [-39.934846375111533, -8.158326666135196],
              [-39.934846372507685, -8.158326656993109],
              [-39.934846369894181, -8.158326647853755],
              [-39.934846367271057, -8.158326638717135],
              [-39.93484636463829, -8.158326629583266],
              [-39.934846361995895, -8.158326620452168],
              [-39.934846359343879, -8.158326611323851],
              [-39.934846356682229, -8.158326602198288],
              [-39.934846354010972, -8.158326593075527],
              [-39.93484635133008, -8.158326583955581],
              [-39.934846348639581, -8.158326574838444],
              [-39.934846345939469, -8.158326565724124],
              [-39.934846343229744, -8.15832655661262],
              [-39.934846340510418, -8.158326547503986],
              [-39.934846337781494, -8.158326538398187],
              [-39.934846335042955, -8.158326529295254],
              [-39.934846332294818, -8.158326520195198],
              [-39.934846329537095, -8.158326511098004],
              [-39.93484632676978, -8.158326502003716],
              [-39.934846323992872, -8.15832649291233],
              [-39.934846321206386, -8.158326483823846],
              [-39.934846318410308, -8.158326474738285],
              [-39.934846315604659, -8.158326465655675],
              [-39.934846312789432, -8.158326456576017],
              [-39.934846309964634, -8.1583264474993],
              [-39.934846307130236, -8.158326438425531],
              [-39.934846304286324, -8.158326429354751],
              [-39.934846301432827, -8.158326420286944],
              [-39.934846298569759, -8.15832641122214],
              [-39.934846295697135, -8.158326402160336],
              [-39.934846292814953, -8.158326393101522],
              [-39.934846289923236, -8.158326384045745],
              [-39.934846287021976, -8.158326374993006],
              [-39.934846284111153, -8.158326365943323],
              [-39.934846281190794, -8.158326356896671],
              [-39.934846278260892, -8.158326347853096],
              [-39.934846275321455, -8.158326338812573],
              [-39.934846272372482, -8.158326329775141],
              [-39.93484626941401, -8.158326320740812],
              [-39.934846266445994, -8.158326311709587],
              [-39.934846263468458, -8.158326302681452],
              [-39.9348462604814, -8.158326293656453],
              [-39.934846257484828, -8.158326284634596],
              [-39.934846254478749, -8.158326275615876],
              [-39.934846251463156, -8.158326266600291],
              [-39.934846248438049, -8.158326257587865],
              [-39.934846245403456, -8.158326248578627],
              [-39.934846242359356, -8.15832623957256],
              [-39.93484623930577, -8.158326230569701],
              [-39.93484623624267, -8.15832622157005],
              [-39.934846233170099, -8.158326212573584],
              [-39.934846230088048, -8.158326203580359],
              [-39.934846226996513, -8.158326194590353],
              [-39.934846223895491, -8.158326185603608],
              [-39.934846220784998, -8.158326176620102],
              [-39.934846217665026, -8.158326167639851],
              [-39.934846214535597, -8.15832615866289],
              [-39.934846211396689, -8.15832614968919],
              [-39.934846208248338, -8.158326140718794],
              [-39.934846205090523, -8.1583261317517],
              [-39.934846201923243, -8.1583261227879],
              [-39.934846198746534, -8.158326113827425],
              [-39.934846195560361, -8.158326104870291],
              [-39.934846192364752, -8.158326095916493],
              [-39.9348461891597, -8.158326086966037],
              [-39.934846185945197, -8.158326078018955],
              [-39.934846182721287, -8.158326069075232],
              [-39.934846179487934, -8.158326060134918],
              [-39.934846176245166, -8.158326051197976],
              [-39.934846172992955, -8.158326042264443],
              [-39.934846169731344, -8.158326033334319],
              [-39.934846166460318, -8.158326024407581],
              [-39.934846163179863, -8.158326015484306],
              [-39.934846207489542, -8.158326067625616],
              [-39.934468039215687, -8.157299139009497],
              [-39.934468006441953, -8.157299042477685],
              [-39.93446797719767, -8.157298961878892],
              [-39.934467948721185, -8.157298881007577],
              [-39.93446792101507, -8.157298799871004],
              [-39.93446789408182, -8.157298718476486],
              [-39.934467867923836, -8.157298636831293],
              [-39.934467842543462, -8.157298554942793],
              [-39.934467817942995, -8.157298472818351],
              [-39.934467794124636, -8.1572983904653],
              [-39.934467771090539, -8.157298307891065],
              [-39.934467748842735, -8.157298225103029],
              [-39.934467727383236, -8.157298142108658],
              [-39.934467706714003, -8.157298058915391],
              [-39.934467686836854, -8.157297975530712],
              [-39.934467667753587, -8.157297891962061],
              [-39.934467649465923, -8.157297808217026],
              [-39.934467631975487, -8.15729772430304],
              [-39.934467615283864, -8.157297640227673],
              [-39.934467599392555, -8.157297555998491],
              [-39.934467584302958, -8.157297471623055],
              [-39.934467570016466, -8.157297387108907],
              [-39.934467556534337, -8.157297302463707],
              [-39.934467543857799, -8.157297217694971],
              [-39.93446753198797, -8.157297132810358],
              [-39.934467520925935, -8.157297047817496],
              [-39.934467510672675, -8.157296962723963],
              [-39.934467501229115, -8.157296877537458],
              [-39.934467492596106, -8.157296792265598],
              [-39.934467484774423, -8.157296706916087],
              [-39.934467477764748, -8.15729662149651],
              [-39.934467471567743, -8.157296536014616],
              [-39.934467466183953, -8.157296450478052],
              [-39.934467461613856, -8.157296364894462],
              [-39.934467457857863, -8.157296279271574],
              [-39.934467454916309, -8.157296193617112],
              [-39.934467452789455, -8.157296107938652],
              [-39.934467451477524, -8.15729602224399],
              [-39.934467450980577, -8.157295936540802],
              [-39.934467451298708, -8.157295850836752],
              [-39.934467452431875, -8.157295765139555],
              [-39.934467454379956, -8.15729567945692],
              [-39.934467457142802, -8.157295593796514],
              [-39.93446746072015, -8.157295508166033],
              [-39.934467465111702, -8.157295422573199],
              [-39.934467470317017, -8.157295337025637],
              [-39.934467476335669, -8.157295251531075],
              [-39.934467483167111, -8.157295166097182],
              [-39.934467490810711, -8.15729508073165],
              [-39.9344674992658, -8.157294995442099],
              [-39.934467508531611, -8.157294910236233],
              [-39.934467518607313, -8.157294825121646],
              [-39.934467529492011, -8.157294740106037],
              [-39.934467541184716, -8.157294655197022],
              [-39.934467553684385, -8.157294570402199],
              [-39.934467566989881, -8.15729448572924],
              [-39.934467581100023, -8.157294401185693],
              [-39.93446759601354, -8.157294316779184],
              [-39.934467611729112, -8.157294232517293],
              [-39.934467628245287, -8.157294148407514],
              [-39.934467645560609, -8.157294064457485],
              [-39.934467663673523, -8.157293980674734],
              [-39.934467682582394, -8.157293897066749],
              [-39.934467702285538, -8.157293813641063],
              [-39.934467722781157, -8.15729373040516],
              [-39.934467744067447, -8.157293647366524],
              [-39.934467766142475, -8.157293564532591],
              [-39.934467789004266, -8.157293481910804],
              [-39.934467812650759, -8.157293399508589],
              [-39.934467837079836, -8.157293317333357],
              [-39.934467862289303, -8.157293235392464],
              [-39.934467888276899, -8.157293153693271],
              [-39.934467915040287, -8.157293072243151],
              [-39.934467942577065, -8.157292991049367],
              [-39.934467970884754, -8.157292910119219],
              [-39.93446799996083, -8.157292829460005],
              [-39.934468029802652, -8.15729274907895],
              [-39.934468060407575, -8.157292668983262],
              [-39.934468091772821, -8.157292589180127],
              [-39.934468123895606, -8.157292509676759],
              [-39.934468156773001, -8.157292430480263],
              [-39.934468190402107, -8.157292351597729],
              [-39.934468224779863, -8.157292273036267],
              [-39.934468259903191, -8.157292194802936],
              [-39.93446829576893, -8.15729211690477],
              [-39.934468332373889, -8.157292039348716],
              [-39.934468369714764, -8.157291962141789],
              [-39.934468407788195, -8.15729188529089],
              [-39.93446844659077, -8.157291808802929],
              [-39.934468486119002, -8.157291732684781],
              [-39.934468526369336, -8.157291656943277],
              [-39.934468567338179, -8.15729158158523],
              [-39.934468609021827, -8.157291506617396],
              [-39.934468651416559, -8.157291432046495],
              [-39.93446869451855, -8.157291357879213],
              [-39.934468738323936, -8.157291284122254],
              [-39.934468782828795, -8.157291210782232],
              [-39.934468828029111, -8.157291137865705],
              [-39.934468873920828, -8.157291065379241],
              [-39.934468920499846, -8.15729099332933],
              [-39.934468967762697, -8.157290921721073],
              [-39.934469018759422, -8.157290996827431],
              [-39.935030553186941, -8.156448847586766],
              [-39.935030915417947, -8.15644848497673],
              [-39.935594021706557, -8.155697770005311],
              [-39.935593943955972, -8.15569772413901],
              [-39.9355939659306, -8.155697694968206],
              [-39.935593988023108, -8.155697665885958],
              [-39.935594010233132, -8.155697636892713],
              [-39.935594032560324, -8.155697607988934],
              [-39.935594055004323, -8.155697579175117],
              [-39.93559407756473, -8.15569755045173],
              [-39.935594100241239, -8.155697521819247],
              [-39.935594123033439, -8.155697493278108],
              [-39.935594145940968, -8.155697464828767],
              [-39.935594168963455, -8.155697436471719],
              [-39.935594192100517, -8.155697408207427],
              [-39.935594215351777, -8.155697380036337],
              [-39.935594238716874, -8.155697351958906],
              [-39.93559426219543, -8.155697323975595],
              [-39.935594285787047, -8.155697296086858],
              [-39.935594309491336, -8.155697268293157],
              [-39.935594333307932, -8.155697240594924],
              [-39.935594357236425, -8.155697212992635],
              [-39.935594381276452, -8.155697185486707],
              [-39.935594405427601, -8.155697158077606],
              [-39.935594429689495, -8.155697130765805],
              [-39.935594454061714, -8.155697103551697],
              [-39.935594478543884, -8.155697076435732],
              [-39.935594503135604, -8.155697049418372],
              [-39.935594527836479, -8.155697022500085],
              [-39.935594552646094, -8.155696995681261],
              [-39.935594577564046, -8.155696968962303],
              [-39.935594602589944, -8.155696942343754],
              [-39.935594627723376, -8.155696915825967],
              [-39.9355946529639, -8.155696889409363],
              [-39.935594678311155, -8.155696863094436],
              [-39.935594703764721, -8.155696836881535],
              [-39.935594729324144, -8.155696810771172],
              [-39.935594754989054, -8.155696784763714],
              [-39.935594780759004, -8.155696758859603],
              [-39.935594806633596, -8.155696733059278],
              [-39.935594832612388, -8.155696707363091],
              [-39.935594858694991, -8.155696681771548],
              [-39.935594884880942, -8.155696656284992],
              [-39.935594911169829, -8.15569663090389],
              [-39.935594937561234, -8.155696605628618],
              [-39.935594964054715, -8.155696580459612],
              [-39.935594990649854, -8.155696555397277],
              [-39.935595017346209, -8.155696530442023],
              [-39.935595044143341, -8.155696505594216],
              [-39.935595071040837, -8.155696480854315],
              [-39.93559509803822, -8.155696456222691],
              [-39.935595125135102, -8.155696431699779],
              [-39.935595152331004, -8.155696407285957],
              [-39.935595179625487, -8.15569638298158],
              [-39.935595207018117, -8.155696358787079],
              [-39.935595234508455, -8.155696334702869],
              [-39.935595262096051, -8.155696310729313],
              [-39.935595289780444, -8.155696286866824],
              [-39.935595317561187, -8.155696263115736],
              [-39.935595345437832, -8.155696239476539],
              [-39.935595373409917, -8.155696215949487],
              [-39.935595401477009, -8.155696192535068],
              [-39.935595429638632, -8.155696169233623],
              [-39.935595457894337, -8.155696146045521],
              [-39.935595486243663, -8.15569612297117],
              [-39.935595514686156, -8.155696100010905],
              [-39.935595543221318, -8.155696077165137],
              [-39.935595571848722, -8.155696054434209],
              [-39.935595600567893, -8.155696031818492],
              [-39.935595629378383, -8.155696009318394],
              [-39.935595658279688, -8.155695986934244],
              [-39.935595687271345, -8.155695964666393],
              [-39.935595716352914, -8.155695942515234],
              [-39.935595745523869, -8.15569592048112],
              [-39.935595774783778, -8.155695898564401],
              [-39.935595804132163, -8.155695876765439],
              [-39.935595833568527, -8.155695855084565],
              [-39.935595863092388, -8.155695833522211],
              [-39.935595892703297, -8.155695812078623],
              [-39.935595922400758, -8.155695790754207],
              [-39.935595952184265, -8.155695769549304],
              [-39.935595982053364, -8.155695748464248],
              [-39.935596012007551, -8.155695727499399],
              [-39.935596042046349, -8.155695706655075],
              [-39.935596072169268, -8.155695685931653],
              [-39.935596102375811, -8.155695665329443],
              [-39.935596132665502, -8.155695644848775],
              [-39.935596163037829, -8.155695624489971],
              [-39.935596193492295, -8.155695604253362],
              [-39.935596224028437, -8.155695584139341],
              [-39.935596254645731, -8.155695564148184],
              [-39.935596285343685, -8.155695544280182],
              [-39.935596316121796, -8.155695524535751],
              [-39.935596346979594, -8.15569550491513],
              [-39.935596377916525, -8.155695485418699],
              [-39.935596408932113, -8.15569546604673],
              [-39.935596440025869, -8.155695446799532],
              [-39.935596471197265, -8.155695427677443],
              [-39.935596502445783, -8.155695408680783],
              [-39.935596533770955, -8.155695389809852],
              [-39.935596565172247, -8.15569537106493],
              [-39.935596596649148, -8.155695352446362],
              [-39.935596628201104, -8.15569533395454],
              [-39.935596648503676, -8.155695412408928],
              [-39.936720648043504, -8.155039723454358],
              [-39.936720565503073, -8.155039652087426],
              [-39.936720658968646, -8.155039596809939],
              [-39.936720751763239, -8.155039540421251],
              [-39.936720843873545, -8.155039482929427],
              [-39.936720935286345, -8.155039424342721],
              [-39.936721025988561, -8.15503936466953],
              [-39.936721115967167, -8.155039303918402],
              [-39.936721205209253, -8.155039242098066],
              [-39.93672129370205, -8.155039179217368],
              [-39.936721381432847, -8.155039115285332],
              [-39.936721468389067, -8.155039050311151],
              [-39.936721554558254, -8.155038984304118],
              [-39.936721639928031, -8.15503891727368],
              [-39.936721724486183, -8.155038849229451],
              [-39.936721808220561, -8.155038780181217],
              [-39.936721891119177, -8.15503871013887],
              [-39.936721973170144, -8.155038639112465],
              [-39.936722054361674, -8.155038567112136],
              [-39.936722134682149, -8.155038494148242],
              [-39.936722214120053, -8.155038420231229],
              [-39.936722292663973, -8.155038345371741],
              [-39.936722370302668, -8.155038269580468],
              [-39.936722447025005, -8.155038192868284],
              [-39.936722522819977, -8.155038115246198],
              [-39.936722597676706, -8.155038036725323],
              [-39.936722671584477, -8.155037957316944],
              [-39.936722744532673, -8.15503787703244],
              [-39.936722816510851, -8.155037795883294],
              [-39.936722887508679, -8.155037713881178],
              [-39.936722957515983, -8.155037631037816],
              [-39.936723026522735, -8.155037547365128],
              [-39.936723094519031, -8.155037462875086],
              [-39.936723161495095, -8.155037377579818],
              [-39.936723227441377, -8.155037291491549],
              [-39.936723292348375, -8.155037204622611],
              [-39.936723356206798, -8.155037116985477],
              [-39.936723419007514, -8.155037028592705],
              [-39.936723480741463, -8.155036939456958],
              [-39.936723541399829, -8.155036849591003],
              [-39.93672360097392, -8.155036759007771],
              [-39.93672365945519, -8.15503666772023],
              [-39.936723716835246, -8.155036575741484],
              [-39.93672377310584, -8.15503648308469],
              [-39.936723828258948, -8.155036389763163],
              [-39.936723882286636, -8.155036295790278],
              [-39.936723935181163, -8.155036201179477],
              [-39.936723986934936, -8.155036105944379],
              [-39.936724037540543, -8.155036010098607],
              [-39.936724086990729, -8.155035913655913],
              [-39.936724135278396, -8.155035816630102],
              [-39.93672418239661, -8.155035719035098],
              [-39.93672422833864, -8.155035620884924],
              [-39.936724273097887, -8.155035522193655],
              [-39.936724316667942, -8.155035422975397],
              [-39.936724359042543, -8.155035323244404],
              [-39.936724400215624, -8.155035223014972],
              [-39.936724440181266, -8.155035122301458],
              [-39.936724478933755, -8.155035021118325],
              [-39.936724516467521, -8.155034919480073],
              [-39.9367245527772, -8.155034817401289],
              [-39.936724587857576, -8.155034714896585],
              [-39.936724621703618, -8.155034611980689],
              [-39.936724654310467, -8.155034508668329],
              [-39.936724685673454, -8.155034404974328],
              [-39.936724715788102, -8.155034300913551],
              [-39.936724744650057, -8.155034196500914],
              [-39.936724772255182, -8.155034091751414],
              [-39.936724798599563, -8.155033986680062],
              [-39.936724823679384, -8.155033881301911],
              [-39.936724847491043, -8.155033775632065],
              [-39.936724870031149, -8.155033669685709],
              [-39.936724891296457, -8.155033563477998],
              [-39.936724911283932, -8.155033457024183],
              [-39.936724929990703, -8.155033350339519],
              [-39.936724947414078, -8.155033243439288],
              [-39.936724963551562, -8.155033136338844],
              [-39.936724978400832, -8.15503302905354],
              [-39.936724991959778, -8.155032921598767],
              [-39.93672500422646, -8.155032813989903],
              [-39.93672501519908, -8.1550327062424],
              [-39.936725024876118, -8.155032598371713],
              [-39.936725033256153, -8.155032490393294],
              [-39.936725040337976, -8.155032382322638],
              [-39.936725046120593, -8.155032274175204],
              [-39.936725050603172, -8.155032165966558],
              [-39.936725053785068, -8.155032057712177],
              [-39.936725055665804, -8.155031949427599],
              [-39.936725056245152, -8.155031841128345],
              [-39.936725055522999, -8.155031732829922],
              [-39.936725053499451, -8.155031624547897],
              [-39.936725050174807, -8.155031516297777],
              [-39.936725045549544, -8.155031408095102],
              [-39.936725039624314, -8.155031299955349],
              [-39.93672503239997, -8.155031191894038],
              [-39.936725023877557, -8.155031083926664],
              [-39.936725014058283, -8.155030976068742],
              [-39.936725002943554, -8.155030868335695],
              [-39.936724990535005, -8.155030760742987],
              [-39.936724976834363, -8.15503065330606],
              [-39.936724961843623, -8.155030546040292],
              [-39.936724983624572, -8.155030578637042],
              [-39.936630437523853, -8.154382226424881],
              [-39.936630368686608, -8.154382129872277],
              [-39.936630361374618, -8.15438208069977],
              [-39.936630353791529, -8.154382031568053],
              [-39.936630345937552, -8.154381982478604],
              [-39.936630337812922, -8.154381933432903],
              [-39.936630329417866, -8.154381884432455],
              [-39.936630320752684, -8.154381835478709],
              [-39.936630311817581, -8.15438178657314],
              [-39.936630302612876, -8.154381737717269],
              [-39.936630293138819, -8.154381688912544],
              [-39.936630283395694, -8.15438164016043],
              [-39.936630273383834, -8.154381591462403],
              [-39.936630263103488, -8.15438154281995],
              [-39.936630252555005, -8.154381494234553],
              [-39.936630241738683, -8.154381445707642],
              [-39.936630230654856, -8.154381397240696],
              [-39.936630219303865, -8.154381348835187],
              [-39.936630207686036, -8.154381300492572],
              [-39.936630195801733, -8.154381252214307],
              [-39.936630183651324, -8.154381204001865],
              [-39.936630171235159, -8.154381155856685],
              [-39.936630158553605, -8.154381107780239],
              [-39.936630145607076, -8.154381059773984],
              [-39.936630132395941, -8.154381011839352],
              [-39.936630118920604, -8.154380963977783],
              [-39.936630105181472, -8.154380916190744],
              [-39.936630091178948, -8.154380868479649],
              [-39.936630076913481, -8.15438082084597],
              [-39.936630062385483, -8.154380773291127],
              [-39.936630047595408, -8.154380725816575],
              [-39.936630032543668, -8.154380678423738],
              [-39.936630017230755, -8.154380631114044],
              [-39.936630001657136, -8.15438058388894],
              [-39.936629985823245, -8.154380536749819],
              [-39.93662996972958, -8.154380489698124],
              [-39.936629953376638, -8.154380442735306],
              [-39.936629936764888, -8.154380395862736],
              [-39.936629919894862, -8.154380349081839],
              [-39.936629902767038, -8.154380302394053],
              [-39.936629885381954, -8.154380255800765],
              [-39.93662986774013, -8.154380209303373],
              [-39.936629849842099, -8.154380162903321],
              [-39.9366298316884, -8.154380116601985],
              [-39.936629813279595, -8.154380070400775],
              [-39.936629794616209, -8.154380024301071],
              [-39.936629775698819, -8.154379978304268],
              [-39.936629756528021, -8.154379932411768],
              [-39.936629737104376, -8.154379886624961],
              [-39.936629717428453, -8.154379840945223],
              [-39.936629697500877, -8.154379795373913],
              [-39.936629677322237, -8.154379749912417],
              [-39.936629656893125, -8.154379704562132],
              [-39.936629636214199, -8.154379659324414],
              [-39.936629615286044, -8.15437961420062],
              [-39.936629594109306, -8.154379569192121],
              [-39.936629572684637, -8.15437952430028],
              [-39.936629551012651, -8.154379479526451],
              [-39.936629529094056, -8.154379434871975],
              [-39.936629506929457, -8.154379390338214],
              [-39.936629484519571, -8.154379345926534],
              [-39.936629461865053, -8.154379301638246],
              [-39.936629438966584, -8.154379257474677],
              [-39.936629415824846, -8.154379213437196],
              [-39.936629392440565, -8.154379169527123],
              [-39.936629368814437, -8.154379125745766],
              [-39.936629344947164, -8.15437908209446],
              [-39.936629320839494, -8.154379038574536],
              [-39.936629296492121, -8.154378995187278],
              [-39.936629271905787, -8.154378951934014],
              [-39.936629247081271, -8.154378908816069],
              [-39.936629222019285, -8.154378865834705],
              [-39.936629196720602, -8.154378822991241],
              [-39.936629171185963, -8.154378780286997],
              [-39.936629145416184, -8.154378737723222],
              [-39.936629119412004, -8.154378695301215],
              [-39.936629093174211, -8.154378653022269],
              [-39.936629066703631, -8.154378610887658],
              [-39.936629040001023, -8.154378568898647],
              [-39.936629013067225, -8.154378527056497],
              [-39.936628985903035, -8.154378485362473],
              [-39.936628958509282, -8.154378443817867],
              [-39.936628930886776, -8.154378402423905],
              [-39.936628903036372, -8.154378361181838],
              [-39.936628874958892, -8.154378320092938],
              [-39.936628846655196, -8.154378279158404],
              [-39.936628818126152, -8.154378238379506],
              [-39.936628789372598, -8.154378197757449],
              [-39.936628760395408, -8.15437815729349],
              [-39.936628731195469, -8.154378116988838],
              [-39.93662870177365, -8.154378076844704],
              [-39.936628672130851, -8.154378036862301],
              [-39.936628642267955, -8.154377997042857],
              [-39.936628612185878, -8.154377957387551],
              [-39.936628581885515, -8.154377917897568],
              [-39.936628551367797, -8.154377878574145],
              [-39.936628520633619, -8.154377839418437],
              [-39.936628489683933, -8.154377800431659],
              [-39.936628458519678, -8.154377761614953],
              [-39.936628427141784, -8.154377722969508],
              [-39.936628395551189, -8.154377684496474],
              [-39.936628430117267, -8.154377708885484],
              [-39.936258416243739, -8.153929601803533],
              [-39.935628067659621, -8.153124119476383],
              [-39.934966428746293, -8.152562543956721],
              [-39.934209795830157, -8.151626025860569],
              [-39.934209813112417, -8.151625934112237],
              [-39.934209807972877, -8.151625927756333],
              [-39.934209802827596, -8.151625921405081],
              [-39.934209797676552, -8.151625915058439],
              [-39.934209792519773, -8.151625908716458],
              [-39.934209787357261, -8.15162590237909],
              [-39.934209782188987, -8.151625896046385],
              [-39.934209777014992, -8.151625889718309],
              [-39.93420977183527, -8.151625883394898],
              [-39.934209766649822, -8.151625877076162],
              [-39.934209761458646, -8.151625870762075],
              [-39.934209756261765, -8.151625864452688],
              [-39.934209751059164, -8.151625858147939],
              [-39.934209745850858, -8.151625851847875],
              [-39.934209740636852, -8.151625845552521],
              [-39.934209735417134, -8.15162583926185],
              [-39.934209730191739, -8.151625832975874],
              [-39.934209724960624, -8.151625826694595],
              [-39.934209719723853, -8.151625820418028],
              [-39.934209714481383, -8.151625814146161],
              [-39.93420970923323, -8.151625807879018],
              [-39.934209703979413, -8.151625801616591],
              [-39.934209698719933, -8.151625795358909],
              [-39.934209693454775, -8.151625789105942],
              [-39.934209688183962, -8.151625782857723],
              [-39.934209682907486, -8.151625776614249],
              [-39.934209677625368, -8.151625770375508],
              [-39.934209672337587, -8.15162576414153],
              [-39.934209667044158, -8.151625757912313],
              [-39.934209661745115, -8.151625751687844],
              [-39.934209656440409, -8.151625745468156],
              [-39.934209651130089, -8.15162573925323],
              [-39.934209645814136, -8.151625733043069],
              [-39.934209640492568, -8.151625726837706],
              [-39.934209635165367, -8.151625720637137],
              [-39.934209629832566, -8.151625714441355],
              [-39.934209624494159, -8.151625708250382],
              [-39.934209619150131, -8.151625702064207],
              [-39.934209613800512, -8.151625695882847],
              [-39.9342096084453, -8.151625689706286],
              [-39.93420960308449, -8.151625683534551],
              [-39.934209597718095, -8.151625677367635],
              [-39.934209592346122, -8.151625671205563],
              [-39.934209586968549, -8.151625665048304],
              [-39.934209581585414, -8.151625658895915],
              [-39.934209576196714, -8.15162565274834],
              [-39.934209570802444, -8.151625646605623],
              [-39.934209565402618, -8.151625640467744],
              [-39.934209559997228, -8.151625634334742],
              [-39.934209554586282, -8.15162562820659],
              [-39.93420961345705, -8.151625664506165],
              [-39.933551457514412, -8.150881020189418],
              [-39.933551425796182, -8.150880942309673],
              [-39.933551356414313, -8.150880862874848],
              [-39.933551287965322, -8.150880782640472],
              [-39.933551220458504, -8.150880701617437],
              [-39.933551153902997, -8.150880619816657],
              [-39.933551088307823, -8.150880537249279],
              [-39.933551023681886, -8.150880453926483],
              [-39.933550960033934, -8.150880369859573],
              [-39.933550897372619, -8.150880285059966],
              [-39.93355083570642, -8.150880199539083],
              [-39.933550775043685, -8.150880113308592],
              [-39.933550715392649, -8.150880026380175],
              [-39.933550656761419, -8.150879938765577],
              [-39.933550599157897, -8.150879850476697],
              [-39.933550542589934, -8.150879761525506],
              [-39.933550487065183, -8.150879671924061],
              [-39.933550432591183, -8.150879581684508],
              [-39.933550379175294, -8.150879490819092],
              [-39.933550326824765, -8.1508793993401],
              [-39.933550275546722, -8.150879307259954],
              [-39.933550225348093, -8.150879214591136],
              [-39.93355017623567, -8.15087912134625],
              [-39.933550128216133, -8.150879027537863],
              [-39.933550081295984, -8.150878933178713],
              [-39.933550035481588, -8.15087883828164],
              [-39.933549990779156, -8.150878742859469],
              [-39.933549947194741, -8.150878646925138],
              [-39.933549904734249, -8.150878550491671],
              [-39.933549863403456, -8.150878453572114],
              [-39.933549823207947, -8.150878356179623],
              [-39.933549784153179, -8.150878258327397],
              [-39.933549746244438, -8.150878160028718],
              [-39.933549709486876, -8.150878061296881],
              [-39.933549673885459, -8.150877962145296],
              [-39.93354963944504, -8.150877862587384],
              [-39.933549606170267, -8.150877762636677],
              [-39.933549574065658, -8.150877662306694],
              [-39.933549543135562, -8.150877561611031],
              [-39.933549513384158, -8.150877460563366],
              [-39.933549484815515, -8.150877359177381],
              [-39.933549457433479, -8.150877257466814],
              [-39.933549431241765, -8.150877155445469],
              [-39.933549406243927, -8.150877053127173],
              [-39.93354938244336, -8.150876950525797],
              [-39.933549359843269, -8.150876847655244],
              [-39.933549338446753, -8.150876744529485],
              [-39.933549318256695, -8.150876641162483],
              [-39.933549299275825, -8.150876537568237],
              [-39.933549281506721, -8.150876433760811],
              [-39.9335492649518, -8.150876329754276],
              [-39.933549249613293, -8.150876225562698],
              [-39.933549235493274, -8.150876121200252],
              [-39.933549222593683, -8.150876016681057],
              [-39.933549210916254, -8.15087591201929],
              [-39.933549200462565, -8.150875807229134],
              [-39.93354919123405, -8.150875702324804],
              [-39.933549183231939, -8.150875597320496],
              [-39.933549176457326, -8.150875492230494],
              [-39.933549170911128, -8.150875387069034],
              [-39.933549166594112, -8.150875281850332],
              [-39.933549163506839, -8.1508751765887],
              [-39.933549161649751, -8.150875071298362],
              [-39.93354916102308, -8.150874965993635],
              [-39.933549161626914, -8.150874860688754],
              [-39.93354916346118, -8.150874755398039],
              [-39.93354916652563, -8.150874650135727],
              [-39.933549170819845, -8.1508745449161],
              [-39.933549176343227, -8.150874439753432],
              [-39.933549183095046, -8.150874334661975],
              [-39.933549191074405, -8.150874229655985],
              [-39.933549200280169, -8.150874124749683],
              [-39.933549210711142, -8.15087401995727],
              [-39.933549222365862, -8.150873915293001],
              [-39.933549235242815, -8.150873810771039],
              [-39.933549249340182, -8.15087370640553],
              [-39.933549264656087, -8.150873602210668],
              [-39.933549281188476, -8.150873498200555],
              [-39.93354929893507, -8.150873394389297],
              [-39.933549317893466, -8.150873290790969],
              [-39.933549338061134, -8.150873187419624],
              [-39.93354935943529, -8.15087308428925],
              [-39.933549382013048, -8.150872981413839],
              [-39.933549405791375, -8.150872878807347],
              [-39.93354943076703, -8.150872776483677],
              [-39.933549456936632, -8.150872674456703],
              [-39.933549484296606, -8.150872572740262],
              [-39.933549512843278, -8.150872471348132],
              [-39.933549542572756, -8.150872370294044],
              [-39.933549573481024, -8.150872269591741],
              [-39.933549605563897, -8.150872169254846],
              [-39.933549638816977, -8.150872069296961],
              [-39.933549673235831, -8.150871969731641],
              [-39.933549708815733, -8.150871870572411],
              [-39.933549745551886, -8.150871771832653],
              [-39.933549783439311, -8.150871673525808],
              [-39.933549822472855, -8.150871575665164],
              [-39.933549862647247, -8.15087147826404],
              [-39.933549903957037, -8.150871381335588],
              [-39.933549946396596, -8.150871284892981],
              [-39.933549989960532, -8.150871188948759],
              [-39.933549982600852, -8.150871255671222],
              [-39.934108296646428, -8.149660467391453],
              [-39.934108294698312, -8.149660404062478],
              [-39.934108338794637, -8.149660309861943],
              [-39.934108383974532, -8.149660216172625],
              [-39.934108430232008, -8.149660123006873],
              [-39.934108477560976, -8.149660030376994],
              [-39.934108525955168, -8.149659938295233],
              [-39.93410857540821, -8.149659846773737],
              [-39.934108625913566, -8.149659755824599],
              [-39.934108677464543, -8.149659665459829],
              [-39.934108730054355, -8.149659575691368],
              [-39.934108783676038, -8.149659486531093],
              [-39.934108838322551, -8.149659397990748],
              [-39.934108893986618, -8.149659310082056],
              [-39.934108950660928, -8.149659222816606],
              [-39.934109008337977, -8.149659136205939],
              [-39.934109067010141, -8.149659050261478],
              [-39.934109126669689, -8.149658964994597],
              [-39.934109187308756, -8.14965888041653],
              [-39.934109248919285, -8.14965879653845],
              [-39.93410931149316, -8.149658713371442],
              [-39.934109375022132, -8.149658630926547],
              [-39.934109439497789, -8.149658549214577],
              [-39.934109504911632, -8.149658468246336],
              [-39.934109571255, -8.149658388032545],
              [-39.934109638519146, -8.149658308583815],
              [-39.934109706695189, -8.149658229910612],
              [-39.934109775774104, -8.149658152023321],
              [-39.934109845746782, -8.149658074932253],
              [-39.934109916603987, -8.149657998647553],
              [-39.934109988336346, -8.149657923179339],
              [-39.934110060934387, -8.149657848537565],
              [-39.934110134388526, -8.149657774732061],
              [-39.934110208689056, -8.149657701772622],
              [-39.934110283826172, -8.149657629668836],
              [-39.934110359789948, -8.149657558430278],
              [-39.934110436570329, -8.14965748806631],
              [-39.934110514157204, -8.149657418586282],
              [-39.934110592540314, -8.149657349999297],
              [-39.934110671709284, -8.149657282314479],
              [-39.934110751653705, -8.149657215540714],
              [-39.934110832362954, -8.149657149686901],
              [-39.93411091382643, -8.149657084761644],
              [-39.934110996033347, -8.14965702077359],
              [-39.934111078972848, -8.149656957731152],
              [-39.934111162633982, -8.149656895642677],
              [-39.934111247005696, -8.14965683451635],
              [-39.934111332076832, -8.14965677436023],
              [-39.934111417836164, -8.149656715182324],
              [-39.934111504272394, -8.149656656990391],
              [-39.934111591374069, -8.149656599792131],
              [-39.934111679129686, -8.149656543595123],
              [-39.934111767527682, -8.149656488406762],
              [-39.934111856556342, -8.149656434234318],
              [-39.934111946203927, -8.149656381085029],
              [-39.934112036458608, -8.149656328965811],
              [-39.934112127308445, -8.149656277883633],
              [-39.934112218741447, -8.14965622784519],
              [-39.934112310745533, -8.149656178857102],
              [-39.934112403308553, -8.149656130925866],
              [-39.93411249641828, -8.149656084057773],
              [-39.934112590062412, -8.149656038259023],
              [-39.934112684228595, -8.149655993535717],
              [-39.934112778904371, -8.149655949893669],
              [-39.934112874077258, -8.149655907338719],
              [-39.934112969734656, -8.14965586587644],
              [-39.934113065863961, -8.149655825512342],
              [-39.934113162452455, -8.149655786251762],
              [-39.934113259487397, -8.149655748099853],
              [-39.934113356955947, -8.149655711061635],
              [-39.934113454845246, -8.149655675142093],
              [-39.934113553142367, -8.149655640345889],
              [-39.934113651834316, -8.149655606677644],
              [-39.934113750908068, -8.149655574141836],
              [-39.934113850350542, -8.149655542742689],
              [-39.934113950148586, -8.149655512484406],
              [-39.934114050289033, -8.149655483370971],
              [-39.934114150758624, -8.149655455406219],
              [-39.934114251544145, -8.149655428593878],
              [-39.934114352632243, -8.149655402937444],
              [-39.934114454009588, -8.149655378440331],
              [-39.934114555662752, -8.149655355105759],
              [-39.934114657578355, -8.149655332936858],
              [-39.934114759742918, -8.149655311936471],
              [-39.934114862142934, -8.149655292107441],
              [-39.934114964764881, -8.149655273452378],
              [-39.934115067595222, -8.149655255973709],
              [-39.934115170620359, -8.149655239673782],
              [-39.934115273826706, -8.14965522455474],
              [-39.934115377200591, -8.149655210618574],
              [-39.934115480728401, -8.149655197867139],
              [-39.934115584396423, -8.149655186302089],
              [-39.934115688191014, -8.149655175924975],
              [-39.93411579209841, -8.149655166737157],
              [-39.934115896104927, -8.149655158739845],
              [-39.934116000196816, -8.149655151934132],
              [-39.934116104360307, -8.149655146320882],
              [-39.934116208581678, -8.149655141900821],
              [-39.934116312847138, -8.14965513867458],
              [-39.934116417142917, -8.14965513664254],
              [-39.934116521454449, -8.149655135806238],
              [-39.934116544222277, -8.149655202666409],
              [-39.935051619638543, -8.149653048091308],
              [-39.935051632934154, -8.1496529811484],
              [-39.935051682418717, -8.1496529808999],
              [-39.935051731901162, -8.149652980382621],
              [-39.935051781380039, -8.14965297959648],
              [-39.935051830853887, -8.149652978541571],
              [-39.935051880321225, -8.14965297721794],
              [-39.935051929780592, -8.149652975625557],
              [-39.935051979230501, -8.149652973764535],
              [-39.93505202866951, -8.149652971634927],
              [-39.93505207809612, -8.14965296923671],
              [-39.935052127508875, -8.149652966570077],
              [-39.935052176906318, -8.149652963635035],
              [-39.935052226286935, -8.149652960431682],
              [-39.935052275649319, -8.149652956960132],
              [-39.935052324991979, -8.149652953220446],
              [-39.935052374313429, -8.1496529492128],
              [-39.935052423612234, -8.149652944937236],
              [-39.935052472886902, -8.149652940393937],
              [-39.935052522135976, -8.149652935583052],
              [-39.935052571358, -8.149652930504651],
              [-39.935052620551517, -8.149652925158943],
              [-39.935052669715041, -8.149652919546051],
              [-39.935052718847118, -8.149652913666184],
              [-39.93505276794631, -8.149652907519496],
              [-39.935052817011133, -8.149652901106174],
              [-39.935052866040145, -8.149652894426412],
              [-39.935052915031882, -8.149652887480393],
              [-39.935052963984866, -8.14965288026834],
              [-39.93505301289769, -8.149652872790428],
              [-39.935053061768848, -8.149652865046908],
              [-39.935053110596925, -8.149652857038042],
              [-39.935053159380459, -8.149652848763997],
              [-39.935053208117992, -8.149652840225082],
              [-39.935053256808075, -8.149652831421541],
              [-39.93505330544928, -8.149652822353602],
              [-39.93505335404015, -8.149652813021563],
              [-39.935053402579221, -8.149652803425708],
              [-39.93505345106508, -8.149652793566299],
              [-39.935053499496284, -8.14965278344364],
              [-39.935053547871362, -8.149652773058019],
              [-39.935053596188908, -8.149652762409765],
              [-39.935053644447471, -8.149652751499161],
              [-39.935053692645639, -8.149652740326582],
              [-39.935053740781939, -8.149652728892324],
              [-39.935053788854965, -8.149652717196769],
              [-39.93505383686329, -8.149652705240213],
              [-39.935053884805484, -8.149652693023009],
              [-39.93505393268012, -8.14965268054555],
              [-39.935053980485769, -8.149652667808212],
              [-39.935054028221039, -8.149652654811344],
              [-39.935054075884459, -8.149652641555345],
              [-39.935054123474664, -8.14965262804064],
              [-39.935054170990206, -8.14965261426758],
              [-39.935054218429677, -8.149652600236609],
              [-39.935054265791692, -8.149652585948134],
              [-39.935054313074822, -8.149652571402582],
              [-39.935054360277654, -8.149652556600357],
              [-39.935054407398809, -8.149652541541954],
              [-39.935054454436873, -8.149652526227776],
              [-39.935054501390432, -8.149652510658285],
              [-39.935054548258115, -8.149652494833973],
              [-39.935054595038523, -8.14965247875528],
              [-39.935054641730268, -8.149652462422697],
              [-39.935054688331952, -8.149652445836699],
              [-39.935054734842204, -8.149652428997777],
              [-39.935054781259616, -8.149652411906461],
              [-39.935054827582839, -8.149652394563221],
              [-39.935054873810486, -8.149652376968602],
              [-39.935054919941166, -8.149652359123113],
              [-39.935054965973521, -8.149652341027268],
              [-39.935055011906186, -8.149652322681614],
              [-39.935055057737792, -8.149652304086745],
              [-39.935055103466979, -8.149652285243137],
              [-39.935055149092378, -8.149652266151406],
              [-39.935055194612659, -8.149652246812087],
              [-39.935055240026436, -8.149652227225761],
              [-39.935055285332382, -8.149652207393054],
              [-39.935055330529131, -8.149652187314473],
              [-39.935055375615363, -8.149652166990673],
              [-39.93505542058972, -8.149652146422239],
              [-39.935055465450866, -8.1496521256098],
              [-39.935055510197479, -8.149652104553986],
              [-39.935055554828224, -8.149652083255381],
              [-39.935055599341773, -8.149652061714628],
              [-39.935055643736796, -8.149652039932386],
              [-39.935055688011992, -8.149652017909281],
              [-39.93505573216602, -8.149651995645987],
              [-39.935055776197594, -8.149651973143154],
              [-39.935055820105383, -8.149651950401456],
              [-39.935055863888103, -8.149651927421568],
              [-39.935055907544417, -8.149651904204186],
              [-39.935055951073075, -8.149651880749982],
              [-39.935055994472741, -8.149651857059638],
              [-39.935056037742157, -8.1496518331339],
              [-39.935056080880017, -8.149651808973438],
              [-39.935056123885047, -8.149651784579019],
              [-39.935056166755977, -8.149651759951334],
              [-39.935056209491499, -8.149651735091092],
              [-39.935056252090384, -8.149651709999091],
              [-39.935056294551337, -8.149651684676023],
              [-39.935056336550588, -8.149651771006701],
              [-39.936464887965009, -8.148806491673637],
              [-39.936464872177325, -8.14880642174248],
              [-39.936464916614398, -8.148806394903405],
              [-39.936464960896551, -8.148806367811311],
              [-39.936465005022328, -8.14880634046702],
              [-39.936465048990264, -8.148806312871491],
              [-39.93646509279894, -8.148806285025591],
              [-39.936465136446913, -8.148806256930232],
              [-39.93646517993276, -8.148806228586352],
              [-39.936465223255034, -8.148806199994844],
              [-39.936465266412348, -8.14880617115668],
              [-39.936465309403275, -8.148806142072811],
              [-39.936465352226385, -8.148806112744175],
              [-39.936465394880301, -8.148806083171767],
              [-39.936465437363609, -8.148806053356468],
              [-39.936465479674915, -8.148806023299334],
              [-39.936465521812849, -8.148805993001345],
              [-39.936465563776018, -8.148805962463477],
              [-39.93646560556305, -8.148805931686702],
              [-39.936465647172582, -8.148805900672073],
              [-39.936465688603228, -8.148805869420592],
              [-39.936465729853659, -8.14880583793326],
              [-39.93646577092251, -8.148805806211172],
              [-39.936465811808418, -8.148805774255273],
              [-39.936465852510061, -8.148805742066697],
              [-39.936465893026103, -8.148805709646476],
              [-39.93646593335523, -8.14880567699562],
              [-39.936465973496098, -8.148805644115296],
              [-39.936466013447408, -8.148805611006482],
              [-39.936466053207837, -8.148805577670352],
              [-39.93646609277608, -8.148805544107907],
              [-39.936466132150848, -8.148805510320324],
              [-39.936466171330849, -8.148805476308679],
              [-39.936466210314812, -8.148805442074067],
              [-39.936466249101443, -8.148805407617658],
              [-39.936466287689456, -8.148805372940533],
              [-39.936466326077628, -8.148805338043873],
              [-39.936466364264668, -8.148805302928773],
              [-39.93646640224933, -8.148805267596437],
              [-39.936466440030379, -8.148805232047993],
              [-39.936466477606579, -8.14880519628459],
              [-39.936466514976694, -8.148805160307463],
              [-39.936466552139471, -8.148805124117708],
              [-39.936466589093747, -8.148805087716559],
              [-39.93646662583825, -8.148805051105178],
              [-39.936466662371835, -8.148805014284818],
              [-39.936466698693266, -8.148804977256638],
              [-39.936466734801343, -8.148804940021881],
              [-39.936466770694928, -8.148804902581753],
              [-39.936466806372813, -8.148804864937492],
              [-39.936466841833827, -8.148804827090308],
              [-39.936466877076818, -8.148804789041442],
              [-39.936466912100641, -8.148804750792172],
              [-39.936466946904112, -8.148804712343763],
              [-39.936466981486127, -8.148804673697397],
              [-39.936467015845544, -8.148804634854429],
              [-39.936467049981225, -8.148804595816086],
              [-39.936467083892069, -8.148804556583668],
              [-39.936467117576932, -8.148804517158446],
              [-39.936467151034755, -8.148804477541685],
              [-39.936467184264401, -8.148804437734725],
              [-39.936467217264806, -8.148804397738866],
              [-39.936467250034873, -8.148804357555427],
              [-39.936467282573538, -8.148804317185697],
              [-39.936467314879756, -8.148804276631006],
              [-39.936467346952419, -8.148804235892678],
              [-39.93646737879051, -8.148804194972088],
              [-39.936467410392964, -8.148804153870524],
              [-39.936467441758772, -8.148804112589367],
              [-39.936467472886896, -8.148804071129952],
              [-39.936467503776299, -8.148804029493649],
              [-39.936467534425994, -8.148803987681834],
              [-39.936467564834956, -8.148803945695862],
              [-39.936467595002192, -8.148803903537083],
              [-39.936467624926735, -8.148803861206945],
              [-39.936467654607561, -8.148803818706766],
              [-39.936467684043741, -8.148803776038001],
              [-39.936467713234279, -8.14880373320196],
              [-39.936467742178245, -8.148803690200131],
              [-39.936467770874671, -8.148803647033917],
              [-39.936467799322628, -8.14880360370468],
              [-39.936467827521177, -8.148803560213905],
              [-39.936467855469388, -8.14880351656296],
              [-39.936467883166365, -8.148803472753299],
              [-39.936467910611171, -8.148803428786366],
              [-39.936467937802917, -8.148803384663596],
              [-39.936467964740736, -8.148803340386433],
              [-39.936467991423719, -8.148803295956332],
              [-39.936468017851006, -8.148803251374733],
              [-39.936468044021716, -8.148803206643105],
              [-39.93646806993501, -8.148803161762931],
              [-39.936468095590016, -8.148803116735662],
              [-39.9364681209859, -8.148803071562796],
              [-39.936468146121854, -8.148803026245787],
              [-39.936468170997017, -8.148802980786135],
              [-39.936468195610594, -8.148802935185328],
              [-39.936468219961796, -8.148802889444857],
              [-39.936468244049784, -8.148802843566218],
              [-39.936468267873785, -8.148802797550925],
              [-39.936468291433044, -8.148802751400478],
              [-39.93646831472676, -8.148802705116411],
              [-39.936468328457067, -8.148802775544279],
              [-39.936747136864575, -8.148244908941905],
              [-39.936747088332069, -8.148244807972921],
              [-39.936747121931603, -8.148244741419969],
              [-39.93674715608018, -8.148244675145042],
              [-39.936747190775463, -8.148244609152647],
              [-39.936747226015086, -8.148244543447268],
              [-39.936747261796683, -8.148244478033362],
              [-39.936747298117787, -8.148244412915362],
              [-39.936747334975976, -8.148244348097673],
              [-39.936747372368735, -8.148244283584729],
              [-39.936747410293506, -8.148244219380853],
              [-39.936747448747738, -8.148244155490456],
              [-39.936747487728816, -8.148244091917846],
              [-39.936747527234097, -8.148244028667342],
              [-39.936747567260902, -8.14824396574323],
              [-39.936747607806502, -8.14824390314979],
              [-39.936747648868163, -8.148243840891261],
              [-39.936747690443099, -8.148243778971876],
              [-39.936747732528474, -8.14824371739582],
              [-39.936747775121447, -8.148243656167299],
              [-39.936747818219104, -8.148243595290442],
              [-39.93674786181856, -8.14824353476936],
              [-39.936747905916825, -8.148243474608218],
              [-39.936747950510913, -8.148243414811056],
              [-39.936747995597798, -8.148243355381945],
              [-39.936748041174425, -8.148243296324916],
              [-39.936748087237717, -8.148243237643955],
              [-39.936748133784526, -8.148243179343078],
              [-39.936748180811705, -8.148243121426226],
              [-39.936748228316056, -8.148243063897347],
              [-39.93674827629436, -8.148243006760312],
              [-39.936748324743348, -8.148242950019021],
              [-39.936748373659746, -8.148242893677311],
              [-39.936748423040257, -8.148242837739005],
              [-39.936748472881483, -8.14824278220788],
              [-39.936748523180079, -8.148242727087739],
              [-39.936748573932611, -8.148242672382336],
              [-39.936748625135657, -8.148242618095335],
              [-39.936748676785719, -8.148242564230431],
              [-39.936748728879316, -8.148242510791308],
              [-39.936748781412895, -8.148242457781537],
              [-39.936748834382882, -8.148242405204773],
              [-39.936748887785718, -8.148242353064541],
              [-39.936748941617751, -8.148242301364412],
              [-39.936748995875334, -8.148242250107872],
              [-39.936749050554802, -8.148242199298403],
              [-39.936749105652424, -8.148242148939451],
              [-39.936749161164471, -8.148242099034446],
              [-39.936749217087176, -8.148242049586766],
              [-39.936749273416744, -8.148242000599769],
              [-39.936749330149354, -8.148241952076782],
              [-39.936749387281154, -8.1482419040211],
              [-39.936749444808264, -8.148241856435952],
              [-39.936749502726791, -8.148241809324597],
              [-39.936749561032798, -8.148241762690212],
              [-39.936749619722328, -8.148241716535987],
              [-39.936749678791401, -8.148241670865051],
              [-39.936749738236003, -8.148241625680464],
              [-39.936749798052126, -8.148241580985328],
              [-39.936749858235686, -8.14824153678266],
              [-39.936749918782589, -8.148241493075476],
              [-39.936749979688763, -8.148241449866722],
              [-39.936750040950066, -8.148241407159357],
              [-39.9367501025623, -8.148241364956233],
              [-39.936750164521342, -8.14824132326026],
              [-39.936750226822944, -8.148241282074244],
              [-39.936750289462921, -8.148241241401012],
              [-39.936750352436981, -8.148241201243277],
              [-39.936750415740867, -8.148241161603783],
              [-39.936750479370296, -8.148241122485203],
              [-39.93675054332094, -8.148241083890225],
              [-39.936750607588451, -8.148241045821434],
              [-39.936750672168486, -8.148241008281452],
              [-39.936750737056656, -8.148240971272786],
              [-39.93675080224854, -8.148240934797968],
              [-39.936750867739754, -8.148240898859457],
              [-39.936750933525808, -8.148240863459733],
              [-39.936750999602282, -8.148240828601153],
              [-39.936751065964671, -8.148240794286082],
              [-39.936751132608471, -8.148240760516874],
              [-39.936751199529162, -8.148240727295818],
              [-39.936751266722219, -8.148240694625155],
              [-39.936751334183057, -8.14824066250711],
              [-39.936751401907109, -8.148240630943866],
              [-39.936751469889778, -8.148240599937536],
              [-39.936751538126458, -8.148240569490257],
              [-39.936751606612511, -8.148240539604092],
              [-39.936751675343316, -8.14824051028106],
              [-39.936751744314165, -8.148240481523139],
              [-39.936751813520409, -8.148240453332313],
              [-39.93675188295736, -8.148240425710458],
              [-39.936751952620277, -8.148240398659469],
              [-39.93675202250445, -8.148240372181178],
              [-39.936752092605126, -8.148240346277371],
              [-39.936752162917578, -8.148240320949805],
              [-39.936752233436998, -8.148240296200235],
              [-39.936752304158638, -8.148240272030304],
              [-39.93675237507766, -8.148240248441656],
              [-39.936752446189274, -8.148240225435901],
              [-39.936752517488657, -8.148240203014581],
              [-39.936752588971153, -8.148240181178863],
              [-39.936752572098641, -8.148240283769029],
              [-39.937691438153415, -8.147957649265962],
              [-39.937691380110728, -8.1479576217359],
              [-39.937691387839017, -8.147957619406059],
              [-39.93769139556521, -8.147957617069368],
              [-39.937691403289321, -8.147957614725856],
              [-39.937691411011343, -8.147957612375494],
              [-39.937691418731262, -8.147957610018281],
              [-39.937691426449057, -8.147957607654231],
              [-39.937691434164769, -8.147957605283315],
              [-39.93769144187835, -8.14795760290558],
              [-39.937691449589806, -8.147957600520995],
              [-39.937691457299124, -8.147957598129574],
              [-39.93769146500631, -8.147957595731336],
              [-39.937691472711336, -8.147957593326243],
              [-39.937691480414237, -8.147957590914352],
              [-39.937691488114957, -8.147957588495609],
              [-39.937691495813525, -8.147957586070067],
              [-39.937691503509932, -8.147957583637686],
              [-39.937691511204143, -8.147957581198492],
              [-39.937691518896202, -8.147957578752463],
              [-39.937691526586057, -8.14795757629963],
              [-39.937691534273725, -8.14795757383998],
              [-39.93769154195919, -8.14795757137353],
              [-39.937691549642459, -8.147957568900244],
              [-39.937691557323497, -8.147957566420178],
              [-39.937691565002332, -8.147957563933273],
              [-39.937691572678943, -8.14795756143957],
              [-39.937691580353331, -8.14795755893905],
              [-39.937691588025473, -8.147957556431727],
              [-39.937691595695391, -8.147957553917623],
              [-39.937691603363028, -8.147957551396713],
              [-39.937691611028441, -8.147957548869021],
              [-39.937691618691574, -8.14795754633453],
              [-39.937691626352461, -8.147957543793238],
              [-39.937691634011067, -8.14795754124518],
              [-39.937691641667392, -8.147957538690337],
              [-39.937691619685296, -8.147957648846386],
              [-39.939384818055338, -8.147391806176513],
              [-39.939384787850315, -8.147391711573983],
              [-39.939384854209287, -8.147391689113155],
              [-39.939384920392243, -8.147391666142456],
              [-39.939384986395233, -8.147391642663244],
              [-39.93938505221432, -8.147391618676913],
              [-39.939385117845589, -8.147391594184889],
              [-39.939385183285097, -8.147391569188651],
              [-39.939385248528978, -8.147391543689722],
              [-39.939385313573339, -8.147391517689552],
              [-39.939385378414258, -8.147391491189738],
              [-39.939385443047925, -8.147391464191816],
              [-39.939385507470476, -8.147391436697452],
              [-39.939385571678038, -8.147391408708218],
              [-39.93938563566681, -8.14739138022586],
              [-39.939385699432997, -8.147391351252017],
              [-39.939385762972734, -8.147391321788454],
              [-39.93938582628229, -8.147391291836897],
              [-39.939385889357879, -8.147391261399159],
              [-39.939385952195721, -8.147391230477028],
              [-39.939386014792071, -8.147391199072372],
              [-39.939386077143219, -8.147391167187022],
              [-39.93938613924545, -8.147391134822929],
              [-39.939386201095033, -8.147391101982015],
              [-39.939386262688288, -8.14739106866619],
              [-39.939386324021569, -8.147391034877465],
              [-39.939386385091183, -8.147391000617874],
              [-39.939386445893518, -8.14739096588948],
              [-39.939386506424938, -8.147390930694311],
              [-39.93938656668184, -8.14739089503446],
              [-39.939386626660614, -8.147390858912098],
              [-39.9393866863577, -8.14739082232934],
              [-39.939386745769539, -8.14739078528838],
              [-39.939386804892592, -8.14739074779142],
              [-39.939386863723314, -8.147390709840701],
              [-39.93938692225823, -8.147390671438483],
              [-39.939386980493815, -8.147390632587038],
              [-39.939387038426602, -8.14739059328871],
              [-39.939387096053174, -8.147390553545836],
              [-39.939387153370049, -8.147390513360778],
              [-39.939387210373852, -8.147390472735959],
              [-39.939387267061143, -8.147390431673749],
              [-39.939387323428583, -8.14739039017665],
              [-39.939387379472763, -8.147390348247095],
              [-39.93938743519039, -8.147390305887591],
              [-39.93938749057812, -8.14739026310068],
              [-39.939387545632663, -8.147390219888916],
              [-39.939387600350713, -8.147390176254872],
              [-39.939387654729025, -8.147390132201126],
              [-39.939387708764372, -8.147390087730322],
              [-39.939387762453485, -8.147390042845087],
              [-39.939387815793211, -8.147389997548132],
              [-39.939387868780344, -8.147389951842168],
              [-39.93938792141175, -8.147389905729872],
              [-39.939387973684248, -8.147389859214021],
              [-39.939388025594752, -8.147389812297382],
              [-39.939388077140165, -8.147389764982762],
              [-39.939388128317411, -8.147389717272974],
              [-39.939388179123448, -8.147389669170868],
              [-39.939388229555242, -8.147389620679283],
              [-39.939388279609773, -8.147389571801153],
              [-39.939388329284078, -8.147389522539372],
              [-39.939388378575181, -8.147389472896892],
              [-39.939388427480139, -8.147389422876628],
              [-39.939388475996047, -8.147389372481598],
              [-39.939388524120012, -8.147389321714796],
              [-39.939388571849143, -8.147389270579266],
              [-39.939388619180647, -8.147389219078049],
              [-39.939388666111647, -8.147389167214197],
              [-39.939388712639392, -8.147389114990801],
              [-39.939388758761055, -8.147389062411021],
              [-39.939388804473914, -8.147389009477953],
              [-39.93938884977527, -8.147388956194746],
              [-39.939388894662365, -8.147388902564588],
              [-39.939388939132584, -8.147388848590701],
              [-39.939388983183235, -8.147388794276258],
              [-39.93938902681171, -8.147388739624519],
              [-39.939389070015395, -8.147388684638745],
              [-39.939389112791709, -8.147388629322231],
              [-39.939389155138123, -8.147388573678249],
              [-39.939389197052094, -8.147388517710095],
              [-39.939389238531156, -8.147388461421169],
              [-39.939389279572794, -8.147388404814796],
              [-39.939389320174584, -8.147388347894307],
              [-39.93938936033409, -8.147388290663153],
              [-39.939389400048931, -8.147388233124724],
              [-39.939389439316749, -8.147388175282465],
              [-39.939389478135176, -8.147388117139812],
              [-39.939389516501919, -8.147388058700241],
              [-39.939389554414682, -8.147387999967201],
              [-39.939389591871212, -8.147387940944228],
              [-39.939389628869272, -8.147387881634849],
              [-39.939389665406644, -8.147387822042578],
              [-39.939389701481183, -8.147387762170968],
              [-39.939389737090686, -8.1473877020236],
              [-39.939389772233064, -8.147387641604066],
              [-39.939389806906235, -8.147387580915929],
              [-39.939389841108103, -8.147387519962857],
              [-39.939389874836635, -8.147387458748435],
              [-39.939389908089844, -8.147387397276367],
              [-39.939389940865716, -8.147387335550272],
              [-39.939389981369217, -8.147387362489313],
              [-39.940322551276644, -8.145614589894929],
              [-39.940322520250938, -8.145614498095975],
              [-39.940322592232548, -8.145614358318841],
              [-39.940322661766714, -8.14561421731692],
              [-39.940322728832463, -8.145614075132768],
              [-39.940322793409557, -8.145613931809285],
              [-39.940322855478506, -8.14561378738971],
              [-39.94032291502058, -8.145613641917588],
              [-39.940322972017796, -8.145613495436844],
              [-39.940323026453001, -8.145613347991679],
              [-39.940323078309731, -8.145613199626569],
              [-39.940323127572341, -8.145613050386308],
              [-39.940323174225988, -8.145612900315889],
              [-39.940323218256587, -8.145612749460613],
              [-39.940323259650839, -8.145612597866025],
              [-39.940323298396258, -8.145612445577799],
              [-39.940323334481171, -8.145612292641983],
              [-39.940323367894663, -8.145612139104623],
              [-39.940323398626674, -8.145611985012126],
              [-39.940323426667923, -8.145611830410969],
              [-39.940323452009949, -8.145611675347775],
              [-39.940323474645098, -8.145611519869355],
              [-39.940323494566556, -8.145611364022596],
              [-39.940323511768291, -8.145611207854564],
              [-39.940323526245123, -8.145611051412336],
              [-39.940323537992697, -8.145610894743161],
              [-39.940323547007452, -8.145610737894286],
              [-39.940323553286667, -8.145610580913052],
              [-39.940323556828453, -8.145610423846826],
              [-39.940323557631736, -8.14561026674297],
              [-39.940323555696288, -8.145610109648937],
              [-39.940323551022679, -8.145609952612103],
              [-39.94032354361233, -8.145609795679846],
              [-39.940323533467456, -8.145609638899543],
              [-39.940323520591136, -8.145609482318445],
              [-39.940323504987262, -8.145609325983862],
              [-39.940323486660532, -8.145609169942936],
              [-39.940323465616473, -8.14560901424276],
              [-39.940323441861423, -8.145608858930334],
              [-39.940323415402595, -8.145608704052476],
              [-39.940323386247911, -8.145608549655929],
              [-39.94032335440621, -8.145608395787322],
              [-39.940323319887092, -8.145608242493033],
              [-39.940323282700973, -8.145608089819342],
              [-39.940323242859066, -8.145607937812317],
              [-39.940323200373399, -8.145607786517838],
              [-39.940323155256792, -8.145607635981541],
              [-39.940323107522865, -8.145607486248851],
              [-39.940323057185999, -8.145607337364915],
              [-39.940323004261401, -8.145607189374708],
              [-39.940322948765044, -8.145607042322839],
              [-39.940322890713645, -8.14560689625373],
              [-39.940322830124749, -8.145606751211419],
              [-39.940322767016639, -8.145606607239685],
              [-39.940322701408348, -8.145606464381952],
              [-39.940322633319674, -8.145606322681363],
              [-39.940322562771151, -8.145606182180641],
              [-39.94032248978408, -8.145606042922209],
              [-39.940322414380489, -8.14560590494807],
              [-39.940322336583108, -8.145605768299843],
              [-39.940322256415442, -8.145605633018782],
              [-39.940322173901649, -8.145605499145677],
              [-39.940322089066647, -8.145605366720963],
              [-39.940322001936018, -8.14560523578457],
              [-39.940321912536071, -8.145605106376024],
              [-39.940321820893779, -8.145604978534381],
              [-39.940321727036789, -8.145604852298156],
              [-39.940321630993431, -8.145604727705509],
              [-39.940321532792645, -8.14560460479402],
              [-39.940321432464117, -8.145604483600749],
              [-39.940321330038088, -8.145604364162288],
              [-39.940321225545475, -8.145604246514667],
              [-39.940321119017796, -8.145604130693373],
              [-39.940321010487203, -8.145604016733373],
              [-39.940320899986467, -8.145603904669059],
              [-39.940320787548892, -8.145603794534233],
              [-39.940320673208412, -8.145603686362135],
              [-39.940320556999545, -8.145603580185384],
              [-39.940320438957343, -8.145603476036028],
              [-39.940320319117433, -8.145603373945502],
              [-39.940320197515987, -8.145603273944603],
              [-39.940320074189643, -8.145603176063497],
              [-39.940319949175674, -8.145603080331737],
              [-39.940319822511775, -8.145602986778231],
              [-39.940319694236173, -8.145602895431162],
              [-39.940319564387558, -8.145602806318097],
              [-39.940319433005136, -8.145602719465945],
              [-39.940319300128522, -8.145602634900913],
              [-39.940319165797845, -8.145602552648494],
              [-39.940319030053615, -8.145602472733517],
              [-39.940318892936787, -8.145602395180122],
              [-39.940318754488743, -8.145602320011665],
              [-39.940318614751256, -8.145602247250881],
              [-39.940318473766489, -8.145602176919667],
              [-39.940318331576997, -8.145602109039286],
              [-39.940318188225667, -8.145602043630214],
              [-39.940318043755752, -8.145601980712176],
              [-39.940317898210857, -8.145601920304154],
              [-39.940317751634893, -8.145601862424396],
              [-39.940317604072092, -8.145601807090353],
              [-39.940317455566976, -8.145601754318713],
              [-39.940317438656884, -8.145601849208889],
              [-39.938444248100346, -8.144954358795582],
              [-39.938444227087309, -8.14495431061281],
              [-39.938444135922452, -8.144954278560267],
              [-39.938444045105364, -8.144954245541989],
              [-39.938443954646324, -8.144954211561757],
              [-39.938443864555651, -8.144954176623408],
              [-39.938443774843513, -8.144954140730915],
              [-39.938443685520106, -8.144954103888347],
              [-39.938443596595576, -8.144954066099899],
              [-39.938443508079992, -8.144954027369835],
              [-39.9384434199834, -8.144953987702568],
              [-39.938443332315799, -8.144953947102588],
              [-39.938443245087115, -8.144953905574488],
              [-39.938443158307287, -8.144953863122991],
              [-39.938443071986114, -8.144953819752933],
              [-39.93844298613341, -8.144953775469189],
              [-39.938442900758915, -8.144953730276828],
              [-39.938442815872307, -8.144953684180949],
              [-39.938442731483214, -8.144953637186799],
              [-39.938442647601228, -8.144953589299696],
              [-39.938442564235821, -8.144953540525044],
              [-39.9384424813965, -8.144953490868437],
              [-39.938442399092636, -8.144953440335467],
              [-39.938442317333575, -8.144953388931883],
              [-39.93844223612858, -8.144953336663523],
              [-39.938442155486875, -8.144953283536283],
              [-39.938442075417612, -8.144953229556236],
              [-39.938441995929857, -8.14495317472946],
              [-39.938441917032641, -8.144953119062185],
              [-39.93844183873491, -8.144953062560704],
              [-39.938441761045539, -8.144953005231521],
              [-39.938441683973338, -8.144952947081054],
              [-39.938441607527089, -8.144952888115943],
              [-39.938441531715412, -8.144952828342815],
              [-39.938441456546933, -8.144952767768521],
              [-39.938441382030199, -8.144952706399913],
              [-39.938441308173623, -8.144952644243926],
              [-39.938441234985611, -8.144952581307638],
              [-39.93844116247444, -8.14495251759819],
              [-39.938441090648368, -8.144952453122773],
              [-39.938441019515516, -8.144952387888754],
              [-39.938440949083962, -8.144952321903506],
              [-39.938440879361714, -8.144952255174502],
              [-39.93844081035666, -8.144952187709322],
              [-39.938440742076622, -8.144952119515629],
              [-39.93844067452936, -8.144952050601157],
              [-39.938440607722548, -8.144951980973717],
              [-39.938440541663731, -8.144951910641197],
              [-39.938440476360455, -8.144951839611593],
              [-39.938440411820075, -8.144951767892978],
              [-39.93844034804993, -8.144951695493443],
              [-39.938440285057268, -8.144951622421251],
              [-39.938440222849209, -8.144951548684661],
              [-39.938440161432837, -8.144951474292045],
              [-39.938440100815093, -8.144951399251827],
              [-39.938440041002892, -8.144951323572535],
              [-39.938439982002976, -8.144951247262778],
              [-39.938439923822067, -8.144951170331145],
              [-39.938439866466759, -8.144951092786449],
              [-39.938439809943546, -8.144951014637437],
              [-39.938439754258852, -8.144950935892961],
              [-39.938439699418979, -8.144950856561973],
              [-39.938439645430179, -8.144950776653481],
              [-39.938439592298558, -8.144950696176547],
              [-39.938439540030117, -8.14495061514029],
              [-39.938439488630841, -8.144950533553875],
              [-39.938439438106514, -8.144950451426658],
              [-39.938439388462896, -8.144950368767821],
              [-39.93843933970561, -8.144950285586859],
              [-39.938439291840169, -8.144950201893131],
              [-39.938439244872022, -8.144950117696167],
              [-39.938439198806499, -8.144950033005523],
              [-39.938439153648822, -8.144949947830771],
              [-39.938439109404086, -8.144949862181587],
              [-39.938439066077365, -8.144949776067696],
              [-39.938439023673538, -8.14494968949886],
              [-39.938438982197432, -8.144949602484935],
              [-39.938438941653722, -8.144949515035737],
              [-39.938438902047054, -8.144949427161219],
              [-39.938438863381883, -8.144949338871337],
              [-39.938438825662594, -8.144949250176134],
              [-39.9384387888935, -8.144949161085643],
              [-39.938438753078742, -8.144949071609975],
              [-39.938438718222393, -8.144948981759281],
              [-39.938438684328403, -8.144948891543727],
              [-39.938438651400631, -8.144948800973598],
              [-39.938438619442792, -8.144948710059143],
              [-39.938438588458524, -8.144948618810716],
              [-39.938438558451331, -8.144948527238581],
              [-39.93843852942463, -8.144948435353198],
              [-39.938438501381718, -8.144948343164948],
              [-39.938438474325757, -8.144948250684344],
              [-39.938438448259838, -8.144948157921791],
              [-39.938438423186902, -8.144948064887915],
              [-39.938438399109799, -8.144947971593208],
              [-39.938438376031243, -8.144947878048264],
              [-39.938438353953877, -8.144947784263673],
              [-39.938438332880217, -8.144947690250111],
              [-39.938438312812607, -8.144947596018218],
              [-39.938438293753357, -8.144947501578722],
              [-39.938438275704264, -8.144947406942819],
              [-39.938438332351971, -8.144947498765559],
              [-39.938249157988572, -8.143925924011329],
              [-39.938249105462674, -8.143925881507805],
              [-39.938249088191718, -8.143925785335847],
              [-39.938249071964357, -8.143925688983581],
              [-39.938249056782446, -8.143925592462287],
              [-39.938249042647769, -8.143925495783199],
              [-39.93824902956198, -8.143925398957615],
              [-39.938249017526608, -8.143925301996839],
              [-39.938249006543039, -8.143925204912163],
              [-39.938248996612572, -8.143925107714916],
              [-39.938248987736358, -8.14392501041646],
              [-39.938248979915443, -8.143924913028153],
              [-39.938248973150728, -8.143924815561324],
              [-39.938248967443002, -8.143924718027375],
              [-39.93824896279294, -8.14392462043768],
              [-39.938248959201083, -8.143924522803628],
              [-39.938248956667842, -8.143924425136616],
              [-39.938248955193522, -8.143924327448039],
              [-39.938248954778288, -8.143924229749278],
              [-39.938248955422196, -8.143924132051746],
              [-39.938248957125168, -8.143924034366858],
              [-39.938248959886991, -8.143923936705985],
              [-39.938248963707359, -8.143923839080554],
              [-39.938248968585832, -8.14392374150192],
              [-39.938248974521827, -8.143923643981505],
              [-39.938248981514654, -8.143923546530678],
              [-39.938248989563498, -8.143923449160786],
              [-39.938248998667426, -8.143923351883231],
              [-39.938249008825373, -8.143923254709339],
              [-39.938249020036132, -8.143923157650432],
              [-39.93824903229843, -8.143923060717901],
              [-39.938249045610817, -8.143922963922988],
              [-39.938249059971731, -8.143922867276997],
              [-39.93824907537951, -8.143922770791271],
              [-39.938249091832368, -8.143922674477],
              [-39.938249109328353, -8.143922578345425],
              [-39.938249127865461, -8.143922482407806],
              [-39.938249147441496, -8.1439223866753],
              [-39.938249168054185, -8.143922291159129],
              [-39.938249189701132, -8.143922195870365],
              [-39.938249212379802, -8.143922100820157],
              [-39.938249236087543, -8.14392200601964],
              [-39.938249260821607, -8.14392191147981],
              [-39.938249286579087, -8.143921817211748],
              [-39.938249313356991, -8.143921723226438],
              [-39.938249341152179, -8.143921629534798],
              [-39.938249369961433, -8.143921536147879],
              [-39.938249399781377, -8.143921443076474],
              [-39.938249430608522, -8.143921350331466],
              [-39.938249462439281, -8.143921257923729],
              [-39.938249495269936, -8.143921165863965],
              [-39.938249529096666, -8.143921074162963],
              [-39.938249563915498, -8.143920982831402],
              [-39.938249599722425, -8.143920891879953],
              [-39.938249636513206, -8.143920801319224],
              [-39.938249674283568, -8.143920711159831],
              [-39.93824971302913, -8.143920621412233],
              [-39.938249752745321, -8.143920532086886],
              [-39.938249793427545, -8.143920443194299],
              [-39.938249835071055, -8.14392035474477],
              [-39.938249877670977, -8.143920266748657],
              [-39.938249921222344, -8.143920179216215],
              [-39.938249965720068, -8.143920092157659],
              [-39.938250011158971, -8.143920005583167],
              [-39.938250057533729, -8.143919919502789],
              [-39.938250104838964, -8.143919833926612],
              [-39.938250153069127, -8.143919748864633],
              [-39.938250202218597, -8.143919664326727],
              [-39.938250252281641, -8.14391958032283],
              [-39.938250303252417, -8.143919496862649],
              [-39.938250355124978, -8.14391941395602],
              [-39.938250407893264, -8.143919331612574],
              [-39.938250461551128, -8.143919249841925],
              [-39.938250516092317, -8.143919168653598],
              [-39.938250571510444, -8.143919088057114],
              [-39.938250627799057, -8.143919008061795],
              [-39.938250684951569, -8.143918928677023],
              [-39.93825074296133, -8.14391884991208],
              [-39.938250801821574, -8.143918771776148],
              [-39.938250861525425, -8.143918694278346],
              [-39.938250922065897, -8.143918617427682],
              [-39.938250983435964, -8.143918541233171],
              [-39.938251045628434, -8.143918465703686],
              [-39.938251108636067, -8.143918390848031],
              [-39.938251172451508, -8.143918316674934],
              [-39.938251237067284, -8.143918243193067],
              [-39.938251302475891, -8.143918170411006],
              [-39.938251368669697, -8.143918098337243],
              [-39.938251435640936, -8.143918026980206],
              [-39.938251503381842, -8.143917956348153],
              [-39.938251571884486, -8.143917886449398],
              [-39.93825164114088, -8.143917817292092],
              [-39.938251711142925, -8.143917748884245],
              [-39.938251781882492, -8.143917681233889],
              [-39.938251853351275, -8.143917614348915],
              [-39.938251925540989, -8.14391754823712],
              [-39.938251998443157, -8.143917482906243],
              [-39.938252072049309, -8.143917418363881],
              [-39.938252146350841, -8.143917354617535],
              [-39.938252221339091, -8.143917291674695],
              [-39.938252297005612, -8.143917229542144],
              [-39.938252314760504, -8.143917324576273],
              [-39.939754271329328, -8.142697474914653],
              [-39.939754299163937, -8.142697393475855],
              [-39.939754305565131, -8.14269738827236],
              [-39.939754311961572, -8.142697383063046],
              [-39.939754318353273, -8.142697377847917],
              [-39.939754324740186, -8.142697372627019],
              [-39.939754331122323, -8.142697367400318],
              [-39.939754337499693, -8.142697362167837],
              [-39.939754343872266, -8.142697356929586],
              [-39.939754350240079, -8.142697351685532],
              [-39.939754356603089, -8.142697346435714],
              [-39.939754362961295, -8.14269734118013],
              [-39.939754369314699, -8.142697335918776],
              [-39.939754375663291, -8.142697330651671],
              [-39.939754382007081, -8.142697325378801],
              [-39.939754388346039, -8.142697320100199],
              [-39.939754394680179, -8.142697314815843],
              [-39.939754401009495, -8.142697309525756],
              [-39.939754407333972, -8.142697304229904],
              [-39.939754413653617, -8.142697298928331],
              [-39.939754419968402, -8.142697293621026],
              [-39.939754426278348, -8.142697288308002],
              [-39.939754432583428, -8.14269728298925],
              [-39.939754438883647, -8.142697277664794],
              [-39.93975444517902, -8.142697272334624],
              [-39.939754451469504, -8.14269726699875],
              [-39.939754457755107, -8.142697261657149],
              [-39.939754464035836, -8.142697256309861],
              [-39.939754470311684, -8.142697250956894],
              [-39.939754476582635, -8.142697245598224],
              [-39.939754482848684, -8.142697240233858],
              [-39.939754489109845, -8.142697234863842],
              [-39.939754495366081, -8.142697229488139],
              [-39.939754501617422, -8.142697224106758],
              [-39.939754507863825, -8.142697218719709],
              [-39.939754514105324, -8.142697213326976],
              [-39.939754520341893, -8.142697207928613],
              [-39.939754526573516, -8.142697202524602],
              [-39.939754532800201, -8.142697197114924],
              [-39.939754539021962, -8.142697191699613],
              [-39.939754545238749, -8.142697186278669],
              [-39.939754551450598, -8.14269718085208],
              [-39.939754557657473, -8.142697175419839],
              [-39.939754563859395, -8.142697169981982],
              [-39.939754570056351, -8.142697164538513],
              [-39.939754576248326, -8.14269715908941],
              [-39.93975458243532, -8.142697153634709],
              [-39.939754588617333, -8.142697148174413],
              [-39.939754594794358, -8.14269714270848],
              [-39.939754600966381, -8.142697137236933],
              [-39.939754607133409, -8.142697131759823],
              [-39.939754613295428, -8.142697126277113],
              [-39.939754619452437, -8.142697120788821],
              [-39.939754625604436, -8.142697115294931],
              [-39.939754631751413, -8.142697109795495],
              [-39.939754637893351, -8.142697104290479],
              [-39.939754644030273, -8.14269709877988],
              [-39.939754650162151, -8.142697093263699],
              [-39.939754656289004, -8.14269708774199],
              [-39.939754662410778, -8.142697082214729],
              [-39.939754668527534, -8.142697076681909],
              [-39.939754674639211, -8.142697071143553],
              [-39.93975468074585, -8.142697065599654],
              [-39.939754686847408, -8.142697060050189],
              [-39.939754692943893, -8.142697054495207],
              [-39.939754699035305, -8.142697048934712],
              [-39.939754705121636, -8.14269704336869],
              [-39.93975471120288, -8.142697037797133],
              [-39.939754717279023, -8.142697032220099],
              [-39.939754723350092, -8.142697026637549],
              [-39.939754729416045, -8.142697021049472],
              [-39.939754735476896, -8.142697015455914],
              [-39.939754741532624, -8.142697009856837],
              [-39.939754747583251, -8.142697004252302],
              [-39.939754753628748, -8.142696998642268],
              [-39.939754759669121, -8.142696993026755],
              [-39.93975476570435, -8.142696987405762],
              [-39.939754771734457, -8.142696981779322],
              [-39.939754777759426, -8.142696976147386],
              [-39.939754783779236, -8.142696970509983],
              [-39.939754789793895, -8.142696964867138],
              [-39.939754795803402, -8.142696959218846],
              [-39.939754801807752, -8.142696953565091],
              [-39.939754807806921, -8.142696947905906],
              [-39.939754813800931, -8.142696942241288],
              [-39.939754819789762, -8.142696936571213],
              [-39.939754825773406, -8.142696930895724],
              [-39.93975483175187, -8.142696925214803],
              [-39.93975483772514, -8.142696919528454],
              [-39.939754843693208, -8.14269691383671],
              [-39.939754849656076, -8.142696908139536],
              [-39.939754855613749, -8.142696902436985],
              [-39.939754861566207, -8.142696896729021],
              [-39.939754867513443, -8.142696891015659],
              [-39.939754873455449, -8.142696885296901],
              [-39.939754879392247, -8.14269687957275],
              [-39.939754885323815, -8.142696873843237],
              [-39.939754891250132, -8.142696868108326],
              [-39.939754897171227, -8.142696862368075],
              [-39.939754903087064, -8.142696856622422],
              [-39.939754908997649, -8.142696850871395],
              [-39.939754905416962, -8.142696930785599],
              [-39.940467064819565, -8.142003479127965],
              [-39.940466981612573, -8.14200347197751],
              [-39.940466974055063, -8.142003479338237],
              [-39.939870437615276, -8.141952222682971],
              [-39.935581483092207, -8.141583601734661],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0251000',
        uf: 'PE',
        nome_proje: 'PA CASA NOVA',
        municipio: 'OROCO',
        area_hecta: '117.4789',
        capacidade: 4.0,
        num_famili: 3.0,
        fase: 3.0,
        data_de_cr: '30/11/2007',
        forma_obte: 'Desapropriação',
        data_obten: '30/11/2007',
        area_calc_: 117.5681,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.554412680819887, -8.360267731746628],
              [-39.555358719771519, -8.361509190832024],
              [-39.63124676182818, -8.359685901902026],
              [-39.631698828739786, -8.358418894463366],
              [-39.554412680819887, -8.360267731746628],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0223000',
        uf: 'PE',
        nome_proje: 'PA VITORINO',
        municipio: 'OROCO',
        area_hecta: '111.8920',
        capacidade: 15.0,
        num_famili: 15.0,
        fase: 3.0,
        data_de_cr: '27/02/2007',
        forma_obte: 'Confisco',
        data_obten: '27/02/2007',
        area_calc_: 112.023,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.508475078633566, -8.584938541400273],
              [-39.50896583544322, -8.585231494146397],
              [-39.510133556172526, -8.585885260028531],
              [-39.525008265664894, -8.540096205214072],
              [-39.52381436268427, -8.539410311341673],
              [-39.523280476865203, -8.539103593408585],
              [-39.508475078633566, -8.584938541400273],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0175000',
        uf: 'PE',
        nome_proje: 'PA ANGICO II',
        municipio: 'ITACURUBA',
        area_hecta: '749.0000',
        capacidade: 52.0,
        num_famili: 52.0,
        fase: 3.0,
        data_de_cr: '03/08/2006',
        forma_obte: 'Dação',
        data_obten: '03/08/2006',
        area_calc_: 749.9568,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.680319991227393, -8.697783731858625],
              [-38.68015085750352, -8.697525467550705],
              [-38.674813151460874, -8.688819527328073],
              [-38.673766823556292, -8.689913094666055],
              [-38.671493240306319, -8.691068918826602],
              [-38.676833022533835, -8.697296538020886],
              [-38.676760097045708, -8.697531649621528],
              [-38.67300698451637, -8.700898118220616],
              [-38.671974762229645, -8.701823993990416],
              [-38.670738094630863, -8.702220911803668],
              [-38.670465447943762, -8.702139269702048],
              [-38.669196448468021, -8.697941230165675],
              [-38.666787300644025, -8.698129077286005],
              [-38.664587296787886, -8.698244732372231],
              [-38.664225578073989, -8.696109764424044],
              [-38.663625471706219, -8.696263001995455],
              [-38.663416851650659, -8.695747245655022],
              [-38.66326268582376, -8.69533103371066],
              [-38.659742358352233, -8.697914809543629],
              [-38.658289865116274, -8.695724590247877],
              [-38.65691637942038, -8.696636912412002],
              [-38.656498531919638, -8.696292821323754],
              [-38.654734125377814, -8.697231917881263],
              [-38.654788568760672, -8.697340508617323],
              [-38.654624716701193, -8.697584578147382],
              [-38.654860820486832, -8.697856145655564],
              [-38.648586505453714, -8.69987651144235],
              [-38.648744108301848, -8.703513784985859],
              [-38.650321754636373, -8.706092188971063],
              [-38.655590888513593, -8.714644533526187],
              [-38.655589166224047, -8.716525914227521],
              [-38.65650694521873, -8.716969955192093],
              [-38.658284405104752, -8.721774509971375],
              [-38.662175532621447, -8.721606134050576],
              [-38.662848254592276, -8.721624822064685],
              [-38.663403310795573, -8.721572671854084],
              [-38.663761959680791, -8.721057237276026],
              [-38.664055121026962, -8.720474810563083],
              [-38.664495172783305, -8.720016250483971],
              [-38.664941067983293, -8.719528207928226],
              [-38.665050264822128, -8.719410717748152],
              [-38.665395996684737, -8.719103488549786],
              [-38.665678099083479, -8.718787157907535],
              [-38.665914963019361, -8.718226569150572],
              [-38.666015140483104, -8.718027664692096],
              [-38.666406437418097, -8.717593842905282],
              [-38.666697548622658, -8.717367970478843],
              [-38.666943020512974, -8.717350095490628],
              [-38.66700661718145, -8.717395376812199],
              [-38.667015700133291, -8.717404429888539],
              [-38.667260964002793, -8.717621727544016],
              [-38.667433532385495, -8.717802780959937],
              [-38.66746935246772, -8.718417880231264],
              [-38.667432756580837, -8.71868015656953],
              [-38.667141396572674, -8.719186427892643],
              [-38.667004839313925, -8.719403390989703],
              [-38.666458207497563, -8.720723498173328],
              [-38.66668799046176, -8.720869688033755],
              [-38.675120337978079, -8.722621436350977],
              [-38.675347716852606, -8.722504044233206],
              [-38.677385215706394, -8.721230418252331],
              [-38.677521884184635, -8.720877774488917],
              [-38.679205407338657, -8.719871120871685],
              [-38.674656465413655, -8.712372914836322],
              [-38.681289888681, -8.709248827524819],
              [-38.676848861852967, -8.700028181879313],
              [-38.678122241658883, -8.699206153393417],
              [-38.678552596003151, -8.698928512040208],
              [-38.678552279578007, -8.698927836094766],
              [-38.680319991227393, -8.697783731858625],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0204000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOSÉ',
        municipio: 'IBIMIRIM',
        area_hecta: '449.6291',
        capacidade: 42.0,
        num_famili: 33.0,
        fase: 3.0,
        data_de_cr: '29/11/2006',
        forma_obte: 'Doação',
        data_obten: '30/10/2006',
        area_calc_: 449.7437,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.639798415237188, -8.392478956506595],
              [-37.636567995021828, -8.387860323035452],
              [-37.631428361480594, -8.395791887450432],
              [-37.628753552246039, -8.403500173009039],
              [-37.629703452363849, -8.404143900114219],
              [-37.62871257198946, -8.406970247995448],
              [-37.627612190765397, -8.407359597376439],
              [-37.627613756874716, -8.409237724265346],
              [-37.626461188571056, -8.407449808225657],
              [-37.623957030126526, -8.408841893432202],
              [-37.62369337646075, -8.408977518832499],
              [-37.623465652942485, -8.409147813597828],
              [-37.623465604804942, -8.409147781380598],
              [-37.623465562130853, -8.40914781328005],
              [-37.62363679406689, -8.409262352847705],
              [-37.624203549657068, -8.409264335361639],
              [-37.624353996315108, -8.409453134561474],
              [-37.624768314022013, -8.409831129408833],
              [-37.624919026726673, -8.4099446020646],
              [-37.625522897894363, -8.410134985734178],
              [-37.625710592793489, -8.410474478993738],
              [-37.626049224763911, -8.410889826855787],
              [-37.62650269141205, -8.410853792192228],
              [-37.626577940168886, -8.410967000811088],
              [-37.626463587386645, -8.41123020207371],
              [-37.626048030712091, -8.41122875059318],
              [-37.625594340274773, -8.411302402332694],
              [-37.625479812044489, -8.411640929913242],
              [-37.625175241383836, -8.412279921561581],
              [-37.625287247736132, -8.412656859356582],
              [-37.625663791363301, -8.413034721818009],
              [-37.626115626770812, -8.413488084035496],
              [-37.626606435015397, -8.413602744513279],
              [-37.627286531997179, -8.413605119451494],
              [-37.627285471405521, -8.413906334873779],
              [-37.627548195253517, -8.414396744462747],
              [-37.628001094686972, -8.414548889590584],
              [-37.628605901918341, -8.414475762758057],
              [-37.628758074376371, -8.414175074239118],
              [-37.628759663753335, -8.413723205616174],
              [-37.629024333993435, -8.41368651005655],
              [-37.629403042323318, -8.413424229483613],
              [-37.629442673374577, -8.412897256290503],
              [-37.6297839577662, -8.412559607700615],
              [-37.630162135230179, -8.412447979241691],
              [-37.630389952419868, -8.412147553234325],
              [-37.630201500665578, -8.411996241899935],
              [-37.629937716046769, -8.411807049540741],
              [-37.62978752997202, -8.411543015797019],
              [-37.629863615218483, -8.411392625920833],
              [-37.630053166367091, -8.411205012734674],
              [-37.630167425754742, -8.410941900149282],
              [-37.62982808600551, -8.410752444677001],
              [-37.62967750378467, -8.410601265138625],
              [-37.629678297450425, -8.410375375914054],
              [-37.629829805640917, -8.410262957708012],
              [-37.630321405093106, -8.410151723802469],
              [-37.630585849986232, -8.410152644532895],
              [-37.630587700616992, -8.409625539315796],
              [-37.63051293843877, -8.409399296541817],
              [-37.630363678129164, -8.408871665262931],
              [-37.630251311829745, -8.408570054490495],
              [-37.629949751567999, -8.408380730948448],
              [-37.62976192092043, -8.408078947741737],
              [-37.629498577314891, -8.407739102082983],
              [-37.629423947817514, -8.407475331605172],
              [-37.629650704038063, -8.407476121397471],
              [-37.629990834603859, -8.407439596866556],
              [-37.630255277622048, -8.407440517524446],
              [-37.630331452140382, -8.407290218114179],
              [-37.630520868750203, -8.407140222389902],
              [-37.630898867105756, -8.40710391923465],
              [-37.631011275196272, -8.407367821024],
              [-37.63116159268958, -8.407594326567576],
              [-37.631424979314822, -8.407896462493952],
              [-37.631840096181222, -8.408048470871273],
              [-37.63214201228822, -8.408162466751056],
              [-37.632367490185104, -8.408502179410167],
              [-37.632593628713288, -8.408653530158853],
              [-37.632971760828056, -8.408579516673052],
              [-37.632972816348854, -8.408278300449719],
              [-37.632898448310215, -8.407939203582901],
              [-37.632748129283478, -8.407712788952283],
              [-37.632938336871121, -8.407336902382903],
              [-37.63335477264031, -8.407112456626368],
              [-37.633657916483628, -8.40684990796742],
              [-37.633735317273043, -8.406323064306916],
              [-37.633774149411543, -8.406021979104553],
              [-37.633775335687936, -8.40568314460555],
              [-37.633474170777703, -8.405380879000598],
              [-37.633361761579422, -8.405116887285804],
              [-37.633249261381984, -8.404852985672678],
              [-37.633212011602993, -8.404702201119614],
              [-37.633363871414765, -8.404514364201711],
              [-37.633591194601841, -8.404326879603122],
              [-37.633592249103174, -8.40402566323651],
              [-37.633366112804623, -8.403874222966481],
              [-37.633026026495884, -8.403873042100271],
              [-37.632573004107456, -8.403758522472662],
              [-37.632460331948472, -8.403569857317121],
              [-37.631969400794929, -8.403492914376935],
              [-37.631970456441358, -8.403191698202349],
              [-37.63189569404873, -8.402965455688426],
              [-37.631404236002815, -8.403039075021566],
              [-37.631215746197178, -8.4029254735915],
              [-37.631103206843221, -8.402699190084528],
              [-37.630914717296712, -8.402585588548776],
              [-37.630574764736394, -8.402546697226246],
              [-37.63061306997691, -8.402396265833582],
              [-37.630839864797608, -8.402359345628421],
              [-37.630878037985632, -8.402246532285362],
              [-37.63087883015713, -8.402020642851108],
              [-37.631068730221827, -8.401757792396273],
              [-37.631143888307662, -8.401870999807784],
              [-37.631370286882486, -8.401947023980682],
              [-37.631521485946514, -8.401947549537832],
              [-37.631522145545652, -8.401759368574513],
              [-37.631295788729091, -8.401645545225225],
              [-37.631410043494327, -8.401382431571543],
              [-37.631561242342265, -8.401382957078415],
              [-37.631825153831471, -8.401534438902081],
              [-37.632126314772826, -8.401836705146621],
              [-37.632540898285797, -8.402139365187628],
              [-37.632690556153499, -8.402554051074612],
              [-37.632879137151107, -8.402667652155762],
              [-37.633332858463994, -8.402556281272856],
              [-37.633372044241092, -8.402179960088175],
              [-37.63348603458099, -8.401992081915193],
              [-37.633750606256726, -8.401955291063794],
              [-37.633940018580105, -8.401805383456841],
              [-37.633940677318847, -8.40161711187311],
              [-37.633752450785835, -8.401428184927431],
              [-37.633526974332426, -8.401088563833033],
              [-37.633414302230591, -8.400899898975391],
              [-37.63352842372349, -8.400674402574287],
              [-37.633792994486562, -8.400637611548389],
              [-37.634095169201736, -8.400676368845147],
              [-37.634209422138184, -8.400413163590398],
              [-37.633832352058469, -8.400185963143944],
              [-37.633304174847844, -8.399958238027775],
              [-37.633153594555516, -8.399807060191034],
              [-37.633192162039649, -8.3995813016212],
              [-37.633456954483208, -8.399506892878827],
              [-37.633721260588985, -8.399545518922123],
              [-37.633985303607801, -8.399659381096606],
              [-37.634287609496006, -8.399660429546424],
              [-37.634665732561245, -8.399586412953923],
              [-37.635006382609205, -8.399399319803006],
              [-37.635196582853119, -8.399023430991578],
              [-37.635122476513885, -8.398609007594986],
              [-37.634783406402342, -8.398344321283119],
              [-37.634443719578378, -8.398230197503516],
              [-37.634142690745158, -8.397890224552061],
              [-37.633615174741998, -8.39747422869705],
              [-37.633238371213181, -8.397171701444167],
              [-37.632936817046755, -8.396982381263895],
              [-37.633013424274587, -8.396681427031631],
              [-37.633429057489757, -8.39668286899026],
              [-37.633731006754822, -8.396759243914795],
              [-37.634071086969726, -8.396760423180535],
              [-37.634524406254357, -8.396761994658833],
              [-37.634827367879204, -8.39657477063435],
              [-37.635054685260634, -8.396387284264812],
              [-37.63513129041619, -8.396086419760083],
              [-37.63501927626767, -8.395709483696701],
              [-37.634642300501056, -8.395482284939241],
              [-37.634529669958667, -8.395255911611265],
              [-37.634492814369437, -8.394992272674804],
              [-37.634757340267072, -8.394993189273899],
              [-37.635021249423694, -8.395144668509946],
              [-37.635474526137799, -8.395183947520154],
              [-37.63589120975449, -8.394884169831721],
              [-37.636081406315597, -8.394508280021322],
              [-37.636159061661353, -8.393906107830183],
              [-37.636387033685068, -8.393530348463068],
              [-37.636841491094366, -8.393230700050868],
              [-37.636993384099185, -8.393005332538111],
              [-37.636843198313812, -8.392741211207646],
              [-37.636769221494134, -8.392289169695847],
              [-37.636732889737004, -8.391874877044343],
              [-37.636431509070384, -8.391610232722943],
              [-37.636470204351014, -8.391346855103114],
              [-37.6367725041233, -8.391347900617957],
              [-37.63692255829875, -8.391649640111901],
              [-37.637071693879101, -8.392214887929974],
              [-37.637133246404524, -8.392584143498398],
              [-37.637378404609287, -8.392748216353423],
              [-37.637760526119045, -8.39274953722953],
              [-37.63786882059329, -8.393021923476356],
              [-37.637840444366852, -8.393321056655338],
              [-37.637648814343308, -8.393483619542607],
              [-37.637347941483966, -8.393645804606148],
              [-37.637156121270955, -8.393862805085059],
              [-37.636990879190101, -8.394270341836188],
              [-37.636908020949249, -8.394542067089262],
              [-37.636551522663197, -8.395030509276843],
              [-37.636304559651407, -8.395383324223705],
              [-37.636548769778337, -8.395819406305836],
              [-37.63641105367995, -8.396145380015072],
              [-37.636218566769564, -8.396552821825377],
              [-37.636271243694061, -8.397097118096807],
              [-37.63651630928787, -8.397288320352287],
              [-37.636870717380788, -8.397425643196302],
              [-37.636869958144942, -8.397643213840807],
              [-37.636678325383102, -8.397805775817925],
              [-37.636295253357275, -8.398049332734915],
              [-37.636021044911445, -8.398402052788366],
              [-37.635801792846756, -8.398646085904513],
              [-37.635992001138959, -8.398891627519435],
              [-37.636346125854836, -8.39911042695959],
              [-37.636754732385732, -8.399356724419693],
              [-37.637081899705059, -8.399493862643206],
              [-37.637491361546253, -8.399495279425128],
              [-37.637737381825303, -8.399387343813324],
              [-37.638011396109029, -8.399116189175617],
              [-37.638339705777618, -8.398899750987159],
              [-37.638502957664429, -8.39906354061071],
              [-37.63844753163017, -8.399280922399479],
              [-37.638309906133749, -8.399606897089292],
              [-37.63830838821427, -8.400042219446076],
              [-37.638635936067992, -8.400070571164436],
              [-37.638745658763384, -8.39990772517889],
              [-37.639019202639666, -8.399745445315515],
              [-37.639484280656482, -8.39947494976942],
              [-37.639703720908528, -8.39917647638438],
              [-37.639786772377484, -8.398823093231098],
              [-37.639924585904879, -8.398442679829651],
              [-37.640519456066635, -8.398138719744017],
              [-37.640793045246255, -8.397962874042507],
              [-37.641040057735559, -8.397569272362638],
              [-37.641231211941118, -8.397569931761062],
              [-37.641503949126026, -8.39763887550129],
              [-37.641503192450848, -8.397856446564058],
              [-37.641325277271285, -8.397991839081852],
              [-37.641283423082214, -8.398250142548598],
              [-37.641241142860238, -8.39863088622095],
              [-37.641063558522283, -8.398671057316012],
              [-37.640844592830284, -8.39883352720126],
              [-37.640611768471246, -8.39906404258015],
              [-37.640611011168851, -8.399281613546313],
              [-37.640733545656573, -8.399377258820884],
              [-37.640924089093886, -8.399527577377112],
              [-37.64114196683478, -8.399677899748131],
              [-37.641414752595502, -8.399733279496099],
              [-37.641674059111033, -8.399747738387815],
              [-37.641810499244137, -8.399761863828253],
              [-37.641810026231354, -8.399897868352619],
              [-37.641727689593637, -8.400020026090383],
              [-37.641537770268286, -8.40010781116848],
              [-37.641253171105852, -8.400106829325619],
              [-37.640924801470511, -8.400105696222845],
              [-37.640619167203994, -8.399886525177296],
              [-37.640312683076097, -8.39988546713653],
              [-37.639874811691811, -8.399905748631268],
              [-37.639764321510143, -8.400210838035886],
              [-37.639785067115646, -8.400538083802212],
              [-37.640069195795967, -8.40064812317836],
              [-37.640309720792928, -8.400736218290026],
              [-37.640506156581104, -8.400889632068436],
              [-37.640415582345057, -8.401134473914215],
              [-37.640152403220213, -8.401162683435844],
              [-37.639800892306525, -8.401365387964578],
              [-37.639362368777292, -8.401363872855075],
              [-37.638894695192782, -8.401362256493334],
              [-37.638280918549853, -8.401331016035073],
              [-37.638163243556264, -8.401534527551474],
              [-37.638308737130025, -8.401738949243759],
              [-37.638541862995446, -8.401943673937247],
              [-37.638540948808966, -8.402205825887926],
              [-37.638423781682903, -8.402263657298915],
              [-37.638131665567514, -8.402204410371874],
              [-37.637693028999323, -8.402261129440296],
              [-37.637721354912223, -8.402523472903491],
              [-37.637602967535528, -8.402930900105806],
              [-37.637281406828038, -8.402929787156497],
              [-37.636755249696705, -8.402927965514893],
              [-37.636404244648404, -8.402984986431175],
              [-37.636402616581449, -8.40345105399533],
              [-37.636400784657354, -8.403975447812659],
              [-37.636663558759707, -8.404063712834452],
              [-37.637013751045231, -8.404239725669616],
              [-37.637276118549046, -8.40444455247224],
              [-37.637597578862582, -8.404474783556873],
              [-37.638093887146518, -8.404651301285934],
              [-37.638503172946649, -8.404652717250341],
              [-37.638765744751524, -8.404799307333478],
              [-37.638764830400234, -8.405061459270616],
              [-37.638734776343547, -8.405294392122411],
              [-37.638646939974791, -8.405352415327881],
              [-37.638413213537262, -8.405293279786981],
              [-37.63832608862171, -8.405147386907496],
              [-37.637945840304901, -8.405204307742352],
              [-37.637594527888226, -8.405348773635513],
              [-37.637477156324842, -8.405464840440855],
              [-37.637417645676592, -8.405785116348763],
              [-37.637300477277648, -8.405842947217042],
              [-37.636951507663795, -8.405914443972398],
              [-37.636898577210928, -8.406274621882231],
              [-37.637130245393344, -8.406507104460228],
              [-37.637387704342807, -8.406739676250027],
              [-37.637154327778497, -8.406996230203221],
              [-37.637230801208233, -8.407279629595298],
              [-37.637643456032983, -8.407461284429463],
              [-37.637719391697793, -8.407924817376959],
              [-37.6378475376276, -8.408208395641136],
              [-37.638312494850879, -8.408210005125632],
              [-37.638363447010605, -8.408390407387911],
              [-37.638620459690529, -8.408777430650112],
              [-37.638930132312076, -8.408855638476119],
              [-37.639085866974213, -8.40865026929257],
              [-37.639319064150378, -8.408445167932953],
              [-37.639565972154536, -8.408110347461024],
              [-37.639955595511928, -8.407917904024821],
              [-37.640311989086307, -8.407983702493922],
              [-37.64063553435269, -8.40814632764236],
              [-37.640537486549356, -8.408372153155232],
              [-37.640439663949557, -8.408533322282398],
              [-37.640633958537222, -8.40859856035393],
              [-37.640925984572469, -8.408502719211231],
              [-37.641185906585065, -8.408342109811477],
              [-37.641478382651464, -8.408116955465923],
              [-37.641706221502773, -8.407859384778012],
              [-37.642095619152187, -8.407731504970887],
              [-37.642419930343571, -8.40770034072014],
              [-37.642841859882225, -8.407540288977406],
              [-37.643198387751646, -8.407541518552279],
              [-37.643327730513647, -8.407638814693712],
              [-37.643327056643265, -8.4078326030998],
              [-37.643034940313918, -8.40792853631743],
              [-37.642710719709818, -8.407959701365881],
              [-37.642418356995577, -8.408152483349559],
              [-37.641930933747723, -8.40847381568698],
              [-37.641540747717791, -8.408827856851465],
              [-37.641280128824846, -8.409214537961244],
              [-37.641246245977911, -8.409634375823684],
              [-37.6414400003843, -8.409828835586985],
              [-37.641633395755875, -8.410152517904308],
              [-37.641957280938115, -8.410218202972366],
              [-37.642054091293325, -8.410347761081516],
              [-37.641956043496833, -8.410573586966061],
              [-37.641794033726207, -8.41057302756769],
              [-37.641697110992595, -8.410475752370715],
              [-37.641567768210805, -8.410378365193218],
              [-37.641146510284614, -8.410344626771909],
              [-37.640563039589047, -8.410342610663859],
              [-37.640239717782123, -8.410115419286754],
              [-37.64014358377392, -8.409792072706601],
              [-37.639949176493793, -8.40975902683414],
              [-37.639593097460157, -8.409628662221751],
              [-37.639139984625913, -8.409433214144377],
              [-37.638686195692905, -8.409431644059257],
              [-37.638070419708335, -8.409397229357758],
              [-37.637876463886293, -8.409235050837724],
              [-37.637585790560891, -8.408943313649011],
              [-37.637165324995706, -8.408683500451938],
              [-37.636938837250796, -8.408553492184778],
              [-37.636452968714295, -8.40845486822754],
              [-37.636291546799079, -8.408260518557301],
              [-37.636194942659628, -8.408098676818836],
              [-37.636000988325371, -8.40793649759091],
              [-37.635741494510704, -8.408000254904188],
              [-37.635675977172355, -8.4081938178554],
              [-37.635772242412756, -8.408452508370212],
              [-37.636031488100691, -8.408485780947299],
              [-37.636192684218571, -8.408744696513487],
              [-37.636256733346123, -8.408971082432011],
              [-37.636320579173479, -8.409229660505872],
              [-37.63661145523205, -8.409489115920509],
              [-37.636610552191208, -8.409747469570783],
              [-37.636383273462911, -8.409843622074797],
              [-37.636123326807834, -8.410036511024048],
              [-37.635830642944875, -8.410293943219621],
              [-37.635603680753327, -8.41032543929963],
              [-37.634987791434249, -8.410323302470406],
              [-37.63463092288395, -8.410419004103538],
              [-37.634499207372251, -8.410999916921456],
              [-37.634660515849518, -8.411226640641637],
              [-37.634821281632995, -8.411582495509634],
              [-37.635177337563753, -8.41174523808434],
              [-37.635825106438041, -8.411876709100595],
              [-37.636051369620859, -8.412071284019159],
              [-37.636180259579554, -8.412297804443824],
              [-37.6365036926626, -8.412492806578092],
              [-37.636632470126756, -8.412751609811808],
              [-37.636599485157603, -8.41291300232205],
              [-37.636372882203737, -8.412815366716838],
              [-37.636017141684391, -8.412587969105019],
              [-37.635758005962316, -8.412522503631847],
              [-37.63536905445676, -8.412521154086482],
              [-37.634720945398435, -8.412486621222511],
              [-37.634590359981019, -8.412744524364211],
              [-37.634136568058793, -8.412742948416295],
              [-37.633617709274169, -8.412805802789455],
              [-37.633455155743462, -8.412934370912454],
              [-37.633259275618002, -8.413321360363351],
              [-37.633160746963128, -8.413708597653315],
              [-37.633646620345324, -8.413807226566179],
              [-37.634035572853158, -8.41380857801987],
              [-37.634327038398872, -8.413874157008877],
              [-37.634488460761929, -8.414068598074801],
              [-37.63435821377611, -8.414229652415866],
              [-37.633968242361981, -8.414518937375515],
              [-37.633902156263431, -8.414874094797881],
              [-37.634030819265512, -8.415165181562234],
              [-37.634549228293139, -8.415231639461743],
              [-37.634937616768944, -8.415394495031821],
              [-37.635650210066437, -8.415558475617397],
              [-37.636395416011602, -8.415641905143078],
              [-37.636589034761379, -8.415901023857439],
              [-37.636522385846774, -8.416417596492758],
              [-37.636456188455696, -8.416805037520369],
              [-37.636942180291349, -8.416871289769452],
              [-37.63746081871183, -8.416873088030879],
              [-37.637882082651068, -8.416906831463665],
              [-37.637978328332245, -8.417197895555233],
              [-37.638398576937291, -8.417522365579488],
              [-37.63881871350123, -8.417879118179391],
              [-37.63927260310443, -8.417880689991172],
              [-37.639532667099765, -8.417655516690109],
              [-37.639889654751101, -8.41752752857083],
              [-37.640180989852375, -8.417657760424021],
              [-37.640179186379122, -8.418174558704866],
              [-37.640113104934692, -8.418529627482766],
              [-37.639951001168136, -8.418529066485254],
              [-37.639821860073219, -8.418399395799073],
              [-37.639595253043389, -8.418301761441841],
              [-37.639303106057319, -8.418429973682477],
              [-37.638881072354167, -8.418590019120698],
              [-37.638686074444529, -8.418750850623375],
              [-37.638620217418868, -8.419041262650987],
              [-37.63839282038785, -8.419169698399495],
              [-37.638198499342849, -8.41913674169844],
              [-37.637972118498269, -8.418974450165095],
              [-37.637778475150178, -8.418747705428439],
              [-37.637454041971921, -8.418811147210073],
              [-37.637355875869922, -8.419069253986972],
              [-37.637160538400678, -8.419326933273169],
              [-37.637030516148798, -8.419423422569489],
              [-37.636836443568939, -8.419293525780281],
              [-37.636642009749728, -8.419292851207915],
              [-37.636253186533722, -8.4192269353802],
              [-37.63618775746572, -8.419420498364017],
              [-37.635992101913793, -8.419742832782173],
              [-37.636120428222767, -8.420130858362882],
              [-37.636022147781048, -8.42042124747279],
              [-37.635762328392111, -8.42054956886636],
              [-37.635372124204316, -8.420903510449392],
              [-37.635015132455123, -8.421031493712297],
              [-37.634689995309515, -8.421321094005592],
              [-37.634656102621577, -8.421740839321611],
              [-37.634459991376133, -8.422192394392381],
              [-37.634393676882468, -8.422612117329509],
              [-37.634521888487058, -8.42303251655491],
              [-37.634747159892704, -8.423485445999303],
              [-37.635070125151699, -8.423841955610129],
              [-37.635328724208563, -8.424036644243168],
              [-37.635814611351819, -8.424135182139846],
              [-37.636170338923733, -8.42439486465577],
              [-37.636298801537158, -8.424718324298818],
              [-37.636168641748981, -8.424879379022171],
              [-37.635875718953905, -8.425201375023518],
              [-37.63554989915977, -8.425684763117914],
              [-37.635743543834785, -8.425911509218674],
              [-37.635970153347984, -8.426009236741447],
              [-37.636487672328649, -8.426334047819529],
              [-37.636811342557962, -8.426464395263841],
              [-37.636874262469675, -8.427013700613072],
              [-37.636970169169786, -8.42740170414757],
              [-37.637228070708005, -8.427822462935627],
              [-37.637616472443732, -8.427985317934954],
              [-37.638069717609426, -8.42814839758311],
              [-37.638718170349556, -8.428118363607711],
              [-37.638945368974639, -8.428022301559357],
              [-37.639173336209851, -8.427732361375087],
              [-37.639336482159912, -8.427409913030917],
              [-37.639435101711634, -8.427022583914248],
              [-37.639241228430706, -8.426860405037033],
              [-37.639080928077206, -8.426343045313482],
              [-37.638757934521003, -8.426018911779348],
              [-37.638402090307338, -8.425791604446912],
              [-37.638014029301551, -8.425531811388812],
              [-37.637852827016118, -8.425272805056702],
              [-37.63785361797661, -8.425046734370641],
              [-37.638080566851329, -8.425047521563808],
              [-37.638534712838499, -8.424952156103563],
              [-37.638696819182051, -8.42495271811037],
              [-37.639085557631077, -8.425018722589257],
              [-37.639377032715892, -8.425084299266841],
              [-37.639798735096491, -8.425021193623598],
              [-37.640155729840501, -8.424893206368482],
              [-37.640642183771924, -8.424830323991056],
              [-37.641258435770716, -8.424735516361295],
              [-37.641550812679377, -8.424542737465869],
              [-37.642069912986123, -8.424415309032319],
              [-37.642491636703873, -8.424319826609564],
              [-37.642622334960649, -8.424029637813922],
              [-37.642623235884493, -8.423771193274986],
              [-37.642688866345303, -8.423545346220418],
              [-37.643046309420086, -8.423288133666329],
              [-37.643240724090923, -8.423321088669718],
              [-37.64317498119275, -8.423579309272515],
              [-37.643173968165492, -8.423870036840993],
              [-37.64346499340796, -8.424064832773471],
              [-37.643918237802509, -8.424227905363557],
              [-37.644307180793135, -8.424261531602465],
              [-37.644533701538329, -8.424359254064777],
              [-37.645019593226678, -8.424457781000752],
              [-37.645505822170783, -8.424459458379948],
              [-37.645829585968301, -8.424589798944885],
              [-37.646250748065128, -8.424655817835591],
              [-37.646413303733326, -8.424527244598295],
              [-37.646641060609504, -8.4242695815205],
              [-37.646933862779029, -8.423979858964707],
              [-37.647128299770934, -8.423980528815223],
              [-37.647419754776365, -8.424078473381567],
              [-37.647483721313115, -8.424304767993149],
              [-37.647126504142797, -8.424497328423028],
              [-37.647060987755189, -8.424690984001156],
              [-37.647222421246845, -8.424885331023219],
              [-37.647416388447645, -8.425047506657776],
              [-37.647513000765983, -8.425209346909128],
              [-37.647285580563583, -8.425370070995612],
              [-37.64689605303542, -8.425530236325613],
              [-37.646442243895692, -8.425528672220288],
              [-37.646086169944255, -8.425365937217245],
              [-37.645567855603723, -8.425267209124948],
              [-37.644919497184866, -8.425297255573058],
              [-37.644400170850687, -8.425489344122456],
              [-37.643835197367643, -8.425358711911381],
              [-37.643400369581094, -8.425357209876783],
              [-37.643182325353017, -8.425511271912896],
              [-37.642840567089401, -8.425541017727788],
              [-37.642001789037991, -8.425538117701738],
              [-37.641504536183952, -8.425598341842132],
              [-37.641286042026735, -8.425907125990925],
              [-37.641284313348493, -8.426402402334057],
              [-37.641158473112689, -8.426835304912943],
              [-37.641406389355289, -8.427021905093934],
              [-37.641591772414415, -8.42730115987422],
              [-37.641932542948879, -8.427580952198104],
              [-37.642335972647459, -8.427706145672822],
              [-37.642676869340406, -8.427923993688159],
              [-37.643142310274165, -8.428080418176268],
              [-37.643141123269928, -8.428420881326565],
              [-37.642892216656016, -8.428543818878051],
              [-37.64255036457763, -8.428573654124591],
              [-37.642083843372433, -8.428726765358162],
              [-37.641896838910831, -8.428911860555608],
              [-37.641584857465247, -8.429282265281255],
              [-37.641770224350921, -8.429592446964811],
              [-37.642141857509266, -8.429903273107296],
              [-37.642265447271654, -8.430089442908995],
              [-37.642139804978136, -8.430491419505017],
              [-37.641953231666456, -8.430552718097754],
              [-37.641611053928706, -8.430675331761087],
              [-37.641423399590842, -8.431046166428683],
              [-37.641421562014749, -8.431572369176276],
              [-37.641482600253944, -8.431882120600999],
              [-37.641730860949878, -8.43194492428621],
              [-37.641761362871023, -8.43213077203356],
              [-37.641543205910878, -8.432315758908777],
              [-37.641294188239655, -8.432469621450352],
              [-37.641386718491383, -8.432655684004667],
              [-37.641541368216103, -8.43284196164864],
              [-37.641852704572216, -8.432657297397645],
              [-37.641946856348127, -8.432379010062224],
              [-37.642165030326844, -8.432163096109548],
              [-37.642351479063123, -8.432163741314559],
              [-37.642599524322627, -8.432288397539402],
              [-37.642972331179763, -8.432289687152903],
              [-37.643469376808568, -8.432291405982324],
              [-37.643717206634577, -8.432478005065393],
              [-37.644089690120687, -8.432572163690198],
              [-37.644462821117898, -8.432480581872278],
              [-37.644711514292673, -8.432419587190601],
              [-37.645084321602923, -8.432420874817577],
              [-37.64514579439215, -8.432606739077315],
              [-37.645393086216338, -8.43294815088843],
              [-37.6458272593256, -8.433166319149155],
              [-37.646479696416364, -8.433168570685199],
              [-37.646820923410317, -8.433293546019332],
              [-37.647069169612855, -8.433387273488645],
              [-37.647441133605554, -8.43360531605582],
              [-37.647813727430623, -8.433668454438298],
              [-37.647999548336053, -8.433823910552137],
              [-37.647936782894, -8.434009436822905],
              [-37.647687658374771, -8.434194230222182],
              [-37.647314310483878, -8.434347758543957],
              [-37.64694160858209, -8.434315546171652],
              [-37.646693883470192, -8.434098022143917],
              [-37.646415439150189, -8.433756503391548],
              [-37.645638852610752, -8.433753822988844],
              [-37.645296887993375, -8.433814586482205],
              [-37.644985642640385, -8.433999253882943],
              [-37.644705581911353, -8.434122084472799],
              [-37.64454974345221, -8.434276271015994],
              [-37.644299555071086, -8.434739762222295],
              [-37.644173480864112, -8.435265536042801],
              [-37.644297288645255, -8.435389852505168],
              [-37.644638499490483, -8.435545756776758],
              [-37.644792936012145, -8.435793977053804],
              [-37.644636557148182, -8.436102977076203],
              [-37.644480717746561, -8.436257163446939],
              [-37.644386566304917, -8.436535451475743],
              [-37.644168083681777, -8.436813309605],
              [-37.643825252579354, -8.437121664426883],
              [-37.643731208280443, -8.437369025652595],
              [-37.643543551552, -8.437739861020432],
              [-37.643262947634199, -8.438017413084699],
              [-37.642982217569845, -8.438356999082572],
              [-37.642577912426397, -8.438479397492024],
              [-37.64229772220925, -8.438664169458267],
              [-37.64186159937465, -8.439003125835526],
              [-37.641705233929592, -8.4392811972542],
              [-37.641703935664673, -8.439652676931519],
              [-37.642014304444096, -8.43974662325776],
              [-37.642387334740825, -8.439685971120527],
              [-37.642822377175662, -8.439656550616908],
              [-37.643133736211638, -8.439440959349181],
              [-37.64341412550958, -8.439225260613044],
              [-37.643850247861295, -8.438886211908496],
              [-37.644285306905303, -8.438825772346906],
              [-37.644596431551122, -8.438703050023307],
              [-37.644876603653358, -8.438549293527283],
              [-37.64515711654338, -8.43827164945119],
              [-37.645468995856085, -8.437932169199343],
              [-37.645779812906923, -8.437871388977337],
              [-37.645997324816989, -8.437872140156271],
              [-37.646245590684487, -8.437934851256218],
              [-37.646618511191406, -8.437905211697938],
              [-37.646836363308211, -8.43778216541063],
              [-37.646960603955094, -8.437782594152075],
              [-37.647146624924432, -8.43790703421041],
              [-37.646990893166731, -8.438030295041115],
              [-37.646772626493359, -8.438246301634704],
              [-37.646430765838296, -8.438276048519183],
              [-37.646181745411646, -8.438429913821636],
              [-37.645777871972179, -8.43842851904639],
              [-37.645590863584424, -8.43861361545644],
              [-37.645465004525335, -8.439077536576917],
              [-37.645215568395024, -8.439324361288625],
              [-37.644832142921189, -8.439709622432861],
              [-37.644644934766106, -8.439925644431364],
              [-37.644612363150316, -8.44035887022004],
              [-37.644642451775432, -8.440637587701652],
              [-37.644953146223202, -8.440638661893555],
              [-37.645325961450666, -8.440639950536232],
              [-37.645667625542814, -8.440641131193091],
              [-37.645916199369822, -8.440641989979387],
              [-37.646008949161363, -8.440766108496128],
              [-37.646256767982365, -8.440983724237455],
              [-37.646193585985699, -8.441262029191757],
              [-37.646160907654135, -8.441726272314757],
              [-37.64597368171534, -8.441973312179998],
              [-37.645569157141082, -8.442157656721774],
              [-37.645226736223158, -8.442373142390791],
              [-37.6449465612206, -8.442526898694272],
              [-37.64466680050505, -8.44258787548493],
              [-37.644399698238566, -8.442586951620831],
              [-37.644544044668329, -8.442786215037044],
              [-37.646678937315876, -8.444989224479862],
              [-37.647949720095419, -8.441596350761406],
              [-37.648936554490014, -8.438286861521378],
              [-37.650111588004052, -8.432845951618102],
              [-37.645649393868958, -8.430674007303965],
              [-37.644527810837701, -8.430562252330169],
              [-37.645262984165768, -8.427771691257009],
              [-37.64502962539224, -8.427498421400781],
              [-37.649386777102308, -8.427241875636671],
              [-37.649255906864184, -8.422556619999623],
              [-37.642908586573363, -8.42142444702599],
              [-37.637617177131467, -8.423216528001744],
              [-37.63708991365732, -8.42212167908553],
              [-37.641845782951414, -8.419673675429943],
              [-37.642083464892622, -8.416619876978654],
              [-37.638294442896246, -8.414602219441976],
              [-37.638490016077007, -8.411987496325754],
              [-37.637745768734376, -8.411163367660766],
              [-37.637851826345873, -8.411089040276741],
              [-37.64002406285816, -8.412086760527771],
              [-37.642961293080184, -8.412573832805144],
              [-37.646631465996514, -8.405322258567374],
              [-37.640541563678113, -8.406029137679067],
              [-37.640668903715024, -8.403546200913112],
              [-37.640238432126161, -8.403396228877549],
              [-37.643838127255314, -8.401097082700074],
              [-37.642751204806828, -8.393569317931977],
              [-37.638834880748803, -8.397132570649301],
              [-37.639798415237188, -8.392478956506595],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0208000',
        uf: 'PE',
        nome_proje: 'PA PADRE CICERO',
        municipio: 'IBIMIRIM',
        area_hecta: '437.0379',
        capacidade: 50.0,
        num_famili: 40.0,
        fase: 3.0,
        data_de_cr: '13/12/2006',
        forma_obte: 'Doação',
        data_obten: '13/12/2006',
        area_calc_: 437.1678,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.615893239863858, -8.473877183857448],
              [-37.615248516217527, -8.473881235149163],
              [-37.615253142743875, -8.473892547692355],
              [-37.615870041782031, -8.473893107204622],
              [-37.615893239863858, -8.473877183857448],
            ],
          ],
          [
            [
              [-37.615290131891705, -8.468566552714485],
              [-37.615058732963817, -8.469510876495502],
              [-37.615058752281762, -8.469510913168703],
              [-37.615290131891705, -8.468566552714485],
            ],
          ],
          [
            [
              [-37.615290133325182, -8.468566552642507],
              [-37.615445577216924, -8.467932192764604],
              [-37.616052439080292, -8.467841382253965],
              [-37.616684405490631, -8.467791171559393],
              [-37.617079315677877, -8.46776634516015],
              [-37.61721157653249, -8.467583243504038],
              [-37.616922963035428, -8.467319889211492],
              [-37.616607823092302, -8.467108979769023],
              [-37.616239721233164, -8.467002779539836],
              [-37.616162015451479, -8.466635274644229],
              [-37.615978572344801, -8.466424831148263],
              [-37.616032002998701, -8.466189002246562],
              [-37.616269151870881, -8.46613739348518],
              [-37.616558419948035, -8.466217090269899],
              [-37.616742331511119, -8.466296413973538],
              [-37.61698317657499, -8.466201683493882],
              [-37.61729972005503, -8.466019233790254],
              [-37.617668473715646, -8.465968089710827],
              [-37.618142308768867, -8.465943541072891],
              [-37.618590363042053, -8.465787779413048],
              [-37.618801265908765, -8.465709851989086],
              [-37.618802107617924, -8.465473746143713],
              [-37.618670876759865, -8.465368385320126],
              [-37.618249723285437, -8.465366896698194],
              [-37.617907870587445, -8.46526079101541],
              [-37.618066656207766, -8.46502533412318],
              [-37.618251500154066, -8.464868642031099],
              [-37.618436159950235, -8.464738083010404],
              [-37.618673120846275, -8.464738920461974],
              [-37.618699833864561, -8.464634117797322],
              [-37.618542544699395, -8.464449992099816],
              [-37.618200786162241, -8.464317662855127],
              [-37.617727423554221, -8.46418486801371],
              [-37.61764933953777, -8.463948483171883],
              [-37.617571538645151, -8.463607292736109],
              [-37.617177568432041, -8.463369881123711],
              [-37.616756791796995, -8.463263495579476],
              [-37.616810405985966, -8.463001352365426],
              [-37.616994971263331, -8.462897108294809],
              [-37.617389499498415, -8.46300340070772],
              [-37.617652333209769, -8.463109227134558],
              [-37.61802089970908, -8.463084305871369],
              [-37.618390491895369, -8.462797145230638],
              [-37.618575054115858, -8.462719124586449],
              [-37.61894333998422, -8.462772874205703],
              [-37.61936439734572, -8.4628005855733],
              [-37.619732029755916, -8.463037902123043],
              [-37.619942650762312, -8.46303864564956],
              [-37.62010134078173, -8.462829411513313],
              [-37.62033895666454, -8.462620455798071],
              [-37.620367256191926, -8.462069755214136],
              [-37.620367346637011, -8.462044345103632],
              [-37.614355355949307, -8.460525230833881],
              [-37.615546384043391, -8.461630957406843],
              [-37.616066436508355, -8.462015310554195],
              [-37.616060811658691, -8.462471411578161],
              [-37.615822478843036, -8.463185766160056],
              [-37.615579579322002, -8.464364544474968],
              [-37.615702917858272, -8.466881784182183],
              [-37.615290133325182, -8.468566552642507],
            ],
          ],
          [
            [
              [-37.61368528424417, -8.47389105364109],
              [-37.61524851765077, -8.473881235076515],
              [-37.615136812673114, -8.473608099663776],
              [-37.614849416930177, -8.473003832965949],
              [-37.614349515619914, -8.472949611090698],
              [-37.614045449953757, -8.472841374545673],
              [-37.614233425503407, -8.472293503525691],
              [-37.614615076466087, -8.472294857537635],
              [-37.615115164662811, -8.472296631173865],
              [-37.615406124995346, -8.471904299492707],
              [-37.61551317145841, -8.471432552525766],
              [-37.615541295549477, -8.470908167735248],
              [-37.615437998043987, -8.470357002476284],
              [-37.615255401219855, -8.469884228810093],
              [-37.615058753715203, -8.469510913096574],
              [-37.614735762093481, -8.470829171157087],
              [-37.61368528424417, -8.47389105364109],
            ],
          ],
          [
            [
              [-37.65627065388238, -8.492924102817756],
              [-37.660689295314775, -8.492912730017249],
              [-37.66516810366452, -8.490422825235365],
              [-37.665168134670715, -8.490422817497404],
              [-37.665189945577836, -8.490410691982163],
              [-37.664961312317182, -8.490307270769515],
              [-37.664424110266353, -8.490258858809058],
              [-37.663887220164511, -8.490094063761136],
              [-37.663116700581774, -8.489905135831799],
              [-37.662697196137202, -8.489601027582296],
              [-37.662183974021978, -8.489343259199531],
              [-37.661506529929653, -8.489294362116551],
              [-37.661040007340169, -8.489059902816699],
              [-37.660666385966941, -8.488988807374467],
              [-37.660432514541625, -8.489034575148903],
              [-37.660338080130018, -8.489313588569839],
              [-37.659917529093782, -8.489312142970171],
              [-37.659473543544777, -8.489310616324566],
              [-37.659123023915519, -8.489309410704681],
              [-37.658889000746598, -8.489424988813409],
              [-37.658911778841762, -8.489588021766595],
              [-37.65944801866079, -8.489939106532704],
              [-37.659797327309519, -8.490289547960973],
              [-37.660263769677371, -8.490547249052449],
              [-37.660566301402497, -8.490897438740179],
              [-37.660659121760887, -8.491084043340578],
              [-37.660308438827002, -8.491129409374446],
              [-37.660028120806018, -8.491105114731292],
              [-37.659491151440356, -8.490963644366389],
              [-37.658883585598481, -8.490984794531263],
              [-37.658439355850319, -8.491053077398112],
              [-37.658065248092754, -8.491121691628107],
              [-37.657832103317581, -8.490957934246135],
              [-37.657294983905757, -8.490886181850721],
              [-37.656780793590379, -8.490907740859264],
              [-37.656546688066207, -8.491046557460745],
              [-37.656265955595941, -8.491115491792771],
              [-37.656166837393549, -8.490938269443827],
              [-37.65624423155495, -8.49064979412873],
              [-37.656128312640035, -8.490393297404529],
              [-37.655778359548883, -8.490229136204128],
              [-37.655288253394623, -8.490064491214749],
              [-37.654844673075644, -8.489946577538179],
              [-37.654424690194837, -8.489782083144034],
              [-37.653817928419294, -8.489547222043845],
              [-37.653702011445276, -8.489290724886411],
              [-37.653258027057674, -8.489289191166298],
              [-37.65265061767996, -8.489240520827847],
              [-37.652370870438006, -8.489053359189157],
              [-37.652088891245384, -8.488464591788208],
              [-37.651932758465655, -8.488071768281122],
              [-37.652208476795074, -8.488033565577094],
              [-37.65268029662105, -8.488231338340995],
              [-37.653151889684494, -8.488468265858307],
              [-37.653702598212412, -8.48862706361586],
              [-37.653977633665804, -8.48878499929898],
              [-37.654094848945206, -8.489059948522163],
              [-37.654645741057287, -8.489140343217043],
              [-37.655196997565994, -8.489142245431481],
              [-37.655472990187562, -8.489025458000309],
              [-37.65582855217346, -8.488673646549534],
              [-37.656025611272433, -8.48863507948381],
              [-37.656418956190009, -8.488754174951993],
              [-37.657048600125535, -8.488834747254515],
              [-37.65736360389193, -8.488835832349393],
              [-37.657403751579139, -8.488600672298464],
              [-37.657207737792334, -8.488364608413441],
              [-37.657129941406239, -8.488089705167113],
              [-37.657131304683062, -8.48769742543392],
              [-37.656579504017742, -8.487852510476889],
              [-37.6559893286136, -8.487732736795717],
              [-37.655595938861786, -8.487652977847155],
              [-37.655281482581401, -8.487494907355188],
              [-37.655282437938631, -8.487220366000747],
              [-37.655479678230698, -8.487103397149067],
              [-37.655677282289858, -8.486907936416534],
              [-37.655441986295187, -8.486632489971646],
              [-37.655049462726829, -8.486278098575143],
              [-37.654617200643706, -8.486041218834233],
              [-37.654184665830854, -8.485882830724792],
              [-37.653792144268436, -8.48552843834756],
              [-37.653516837586679, -8.485448994892652],
              [-37.653123223717223, -8.485408389138772],
              [-37.652768483279132, -8.485524903162524],
              [-37.652453209468653, -8.485602216323995],
              [-37.652296529638576, -8.485366376973763],
              [-37.652022180961225, -8.485012391512024],
              [-37.651477421312485, -8.484347749616587],
              [-37.651159627015986, -8.483950207334926],
              [-37.650845813929408, -8.483635331297339],
              [-37.650572061472275, -8.483085205486917],
              [-37.650100750890708, -8.48276969371986],
              [-37.649549504630279, -8.482767785721947],
              [-37.649037404374972, -8.482844505346115],
              [-37.648762649328894, -8.48260816566785],
              [-37.648330853572389, -8.482214386560377],
              [-37.647938157881612, -8.481938481806303],
              [-37.647229552169506, -8.481896778923094],
              [-37.646678398638819, -8.481894867378809],
              [-37.646245049095874, -8.481971766175734],
              [-37.645850478869264, -8.482205784533701],
              [-37.645495603212325, -8.482361447529101],
              [-37.645220210168475, -8.482282088936531],
              [-37.644867258906295, -8.481888580390894],
              [-37.64439558938696, -8.481651554083649],
              [-37.644120013104612, -8.48165059643474],
              [-37.643883490694193, -8.481728267015487],
              [-37.643803778758283, -8.482002533397319],
              [-37.64360585036038, -8.482315725479973],
              [-37.643328897296442, -8.48270704479955],
              [-37.642856539652705, -8.482666155393792],
              [-37.642247805484537, -8.482610774241511],
              [-37.641755892444813, -8.482396643433992],
              [-37.641230044056805, -8.482379439214533],
              [-37.640735023781026, -8.482377714679791],
              [-37.640255336483165, -8.482406788893519],
              [-37.639915252162318, -8.48235939401355],
              [-37.639652047768941, -8.482404685578679],
              [-37.639652752450793, -8.482204387385138],
              [-37.639731006527136, -8.48195805985204],
              [-37.639809260481329, -8.481711732284626],
              [-37.639810127876629, -8.48146513488974],
              [-37.63940945468503, -8.481032209709507],
              [-37.638946294771948, -8.480753157585553],
              [-37.638745579208283, -8.480644575379692],
              [-37.638485888625539, -8.480672516181906],
              [-37.638359951438666, -8.480350420222592],
              [-37.638190281093323, -8.48019564639997],
              [-37.638129511832062, -8.479887251788899],
              [-37.638130488681369, -8.479609818584416],
              [-37.638332017543505, -8.479487176499372],
              [-37.638517888029206, -8.479426242775666],
              [-37.638657471590605, -8.479303384364163],
              [-37.638642573043548, -8.479149241093472],
              [-37.638040429338716, -8.478823494157771],
              [-37.637515726981889, -8.478482553426534],
              [-37.636991206236232, -8.478064567159121],
              [-37.636544071910969, -8.477646941120438],
              [-37.636013619565915, -8.4772889780473],
              [-37.63548875244183, -8.476789874158188],
              [-37.635200866618867, -8.476468929728366],
              [-37.634976575682209, -8.476324182452958],
              [-37.634640066121875, -8.476115018843885],
              [-37.634464118081254, -8.475938428534176],
              [-37.634289265292381, -8.475425807749989],
              [-37.634001946076687, -8.474944895779633],
              [-37.633953400801531, -8.474905570168735],
              [-37.634724176142399, -8.474054616021769],
              [-37.634958692028349, -8.474055435931982],
              [-37.635452247226958, -8.474057161030627],
              [-37.635813182943501, -8.47403599578319],
              [-37.636134399179653, -8.474151510686433],
              [-37.636550801378554, -8.474241947164352],
              [-37.636843549927313, -8.474213760690304],
              [-37.637212208155319, -8.474192078544615],
              [-37.637626280514191, -8.474273643731875],
              [-37.638016153795633, -8.474288839519614],
              [-37.638333103416826, -8.474274933713113],
              [-37.638771584931746, -8.474130962025166],
              [-37.63881331326472, -8.473577771253161],
              [-37.638524995182344, -8.473456857023113],
              [-37.638354889113032, -8.473297560699466],
              [-37.638241355614937, -8.473219576572191],
              [-37.638254130897536, -8.473151075804905],
              [-37.638328399446408, -8.473004387360055],
              [-37.638397842043652, -8.472912753437997],
              [-37.638460556766404, -8.472874630120192],
              [-37.638661593997021, -8.472864841185968],
              [-37.638953872398993, -8.472789266399806],
              [-37.639225422608448, -8.472667500252406],
              [-37.639512389497717, -8.472526707028333],
              [-37.639588338846117, -8.472315095915686],
              [-37.639642680025339, -8.472178465772892],
              [-37.639738384227542, -8.472057352648415],
              [-37.639893211129596, -8.471808759293705],
              [-37.639925159378421, -8.471763655925379],
              [-37.639932423622547, -8.471764223796619],
              [-37.639940027003419, -8.471797528235106],
              [-37.639971407984945, -8.472120741199939],
              [-37.639912388041949, -8.472503322648794],
              [-37.63999526197388, -8.473009382004218],
              [-37.640464714887912, -8.473278958509328],
              [-37.64076621673189, -8.473603111831205],
              [-37.641136912091447, -8.47364789846341],
              [-37.641335204289057, -8.473747518087221],
              [-37.641520783631364, -8.474001546628303],
              [-37.641841995755044, -8.474274223203892],
              [-37.642130443596962, -8.474332829887134],
              [-37.642607993599789, -8.474314686308603],
              [-37.642648684354597, -8.474314827763305],
              [-37.642648225136831, -8.474445767837411],
              [-37.642661099428821, -8.474633724740675],
              [-37.642534276078464, -8.474848415197695],
              [-37.642532219067867, -8.475434842205093],
              [-37.643018975742685, -8.475511319431423],
              [-37.643368495852215, -8.475534327614422],
              [-37.643733710788084, -8.475459002866014],
              [-37.644092117661252, -8.475460247792062],
              [-37.644499860223007, -8.475483005022262],
              [-37.6450430204588, -8.475274732545431],
              [-37.645090742286904, -8.474927016202411],
              [-37.645190588965121, -8.474919676183912],
              [-37.645651878153885, -8.474824697952069],
              [-37.645944382636117, -8.474503060209971],
              [-37.645968185736216, -8.474163851459751],
              [-37.64602912545427, -8.473983927305691],
              [-37.646157780517733, -8.473868171540399],
              [-37.646488547928385, -8.473695151047684],
              [-37.646840839455969, -8.473314850004193],
              [-37.64667437099753, -8.472867370636855],
              [-37.646572731137454, -8.472530439880874],
              [-37.646641524034209, -8.472338334768677],
              [-37.646723293114121, -8.472461511782942],
              [-37.646955447713957, -8.472748163782368],
              [-37.647126457056636, -8.473091936172851],
              [-37.647509854709561, -8.473322955155265],
              [-37.647731643862649, -8.473379066109466],
              [-37.64818768903887, -8.47338064507719],
              [-37.648374364196577, -8.472958080995788],
              [-37.648347055668026, -8.472819177131294],
              [-37.648568766876203, -8.472689454590006],
              [-37.648812960424507, -8.472155679976803],
              [-37.649074631687327, -8.472001046313268],
              [-37.649076795319601, -8.47138097821391],
              [-37.648766041159973, -8.470791206833907],
              [-37.648314180791978, -8.470398264395845],
              [-37.648176944158656, -8.470240894239602],
              [-37.648092449868393, -8.469753548306841],
              [-37.647742929571649, -8.469367651385452],
              [-37.647323549396745, -8.469011806278985],
              [-37.646898053176102, -8.468846926774075],
              [-37.646430580535863, -8.468712195320261],
              [-37.64605228213567, -8.468764690110762],
              [-37.645972211410268, -8.468727517403062],
              [-37.645988624970769, -8.468709036242315],
              [-37.646151318013878, -8.468521144981432],
              [-37.646491917821365, -8.46821016239779],
              [-37.646900033689327, -8.46796804923089],
              [-37.646850422559872, -8.467349610864607],
              [-37.646559138949613, -8.467089883306278],
              [-37.646428542062232, -8.466774374601346],
              [-37.645890583538247, -8.466043920619231],
              [-37.645295299510536, -8.465707630395112],
              [-37.644241532898803, -8.465913772799116],
              [-37.643872566093556, -8.46636301205338],
              [-37.643642108502377, -8.466397479767549],
              [-37.643543877777574, -8.466384930868077],
              [-37.643387749915235, -8.466228307811704],
              [-37.642946791134584, -8.466226776919088],
              [-37.64268958175866, -8.466197398475446],
              [-37.64268985390494, -8.466119720593605],
              [-37.642562480655556, -8.465636927986059],
              [-37.642439928438307, -8.465359517201946],
              [-37.642225514189413, -8.464885193892796],
              [-37.641685103411596, -8.464883316341689],
              [-37.641123719280692, -8.464853422472768],
              [-37.640989878945916, -8.464842648223145],
              [-37.640978302721351, -8.46467314344401],
              [-37.640961037173902, -8.464494304665442],
              [-37.641127243420932, -8.46428879405957],
              [-37.640803908344296, -8.463769057864939],
              [-37.640033348866119, -8.463766378020063],
              [-37.639767038478034, -8.464105103841455],
              [-37.639646425773854, -8.464207321357559],
              [-37.638922250980102, -8.464315757278007],
              [-37.638495777499905, -8.46458691624388],
              [-37.638245490498882, -8.464681718513086],
              [-37.638252287577927, -8.464634266854615],
              [-37.638384612504794, -8.464171549344043],
              [-37.638134285642479, -8.463812577652646],
              [-37.637768967160511, -8.4635071010057],
              [-37.637339354280748, -8.463482996500982],
              [-37.637265627325455, -8.463424593549258],
              [-37.637144436157016, -8.462968046304432],
              [-37.636715505769665, -8.462621021035474],
              [-37.636428843141488, -8.462445040972529],
              [-37.635996548636115, -8.462460714655744],
              [-37.635729235468858, -8.462619299009356],
              [-37.635516459250169, -8.462869226035641],
              [-37.635422396560372, -8.462988897189407],
              [-37.635273181610842, -8.463011345356188],
              [-37.634809669279605, -8.462761589676115],
              [-37.634416415347083, -8.462575108001934],
              [-37.63392437641042, -8.462327783814432],
              [-37.633681679357565, -8.462150599130299],
              [-37.633634460252004, -8.461812772019982],
              [-37.633552187052494, -8.461267559016123],
              [-37.6332173900757, -8.461065726915669],
              [-37.632790698424515, -8.461064235337728],
              [-37.632498504626447, -8.461401508620177],
              [-37.632221557980458, -8.461870589998851],
              [-37.632219636636684, -8.462415056328101],
              [-37.632352387902735, -8.462605331192082],
              [-37.632291170071355, -8.46265720415763],
              [-37.632016415192098, -8.463019586636779],
              [-37.632089922795394, -8.463937517286841],
              [-37.632317102940718, -8.464470938981464],
              [-37.632711852731745, -8.464902852468375],
              [-37.633242721757043, -8.465369062144285],
              [-37.633594784435637, -8.465646644229636],
              [-37.633594661332104, -8.465681549409231],
              [-37.63352713556533, -8.465770657222041],
              [-37.633343785826028, -8.465788282810855],
              [-37.632640280672135, -8.465752453924237],
              [-37.631755256224835, -8.466013318845846],
              [-37.632410371568248, -8.466506460354232],
              [-37.632667068578805, -8.46686066466671],
              [-37.633244553898543, -8.467295478086356],
              [-37.633420517209572, -8.467363643973387],
              [-37.633324506585602, -8.467442795311014],
              [-37.633514509067432, -8.468114171892662],
              [-37.633876368130508, -8.468576354035019],
              [-37.634108009633685, -8.469159436518508],
              [-37.634531597350232, -8.469245287506718],
              [-37.634581542090821, -8.469273947217188],
              [-37.634581475774368, -8.469292756230226],
              [-37.634577925267081, -8.469295094976983],
              [-37.633811353150698, -8.469292415093099],
              [-37.63348971419353, -8.469066393239185],
              [-37.632628229742345, -8.469063379122161],
              [-37.632526665023029, -8.469609214726647],
              [-37.632434476542862, -8.469608892025718],
              [-37.631580257350585, -8.469605900841973],
              [-37.631322739886109, -8.470177594788497],
              [-37.631619538309863, -8.470724463685583],
              [-37.6319722309714, -8.470901673800196],
              [-37.632254602501256, -8.471213376577005],
              [-37.632381845927661, -8.471369359953956],
              [-37.632176142545042, -8.471389076594754],
              [-37.631759694455482, -8.472006875435174],
              [-37.632078794530543, -8.472694620265733],
              [-37.632544869772765, -8.473018360513766],
              [-37.633047588102649, -8.473459786275674],
              [-37.633048040960325, -8.473460149576876],
              [-37.633344949929054, -8.473719725309566],
              [-37.633858149141794, -8.474022921016052],
              [-37.633593659300963, -8.474614034245141],
              [-37.633585598657888, -8.474607495147836],
              [-37.633121057361812, -8.474285840972312],
              [-37.632897390091344, -8.473965120192112],
              [-37.632881841661778, -8.473815586651481],
              [-37.632387643621136, -8.473559027282453],
              [-37.631875727774094, -8.473484348350247],
              [-37.631412848815721, -8.473361280305156],
              [-37.630999169302889, -8.473092522398415],
              [-37.630609407136063, -8.472969619395576],
              [-37.630170613064884, -8.472919520167453],
              [-37.629902401607204, -8.472918579272109],
              [-37.629805799813319, -8.472650932596709],
              [-37.629928978999601, -8.472286936373143],
              [-37.630196253497729, -8.472167513044225],
              [-37.630328879863917, -8.471905734389882],
              [-37.6301726029658, -8.471433057074899],
              [-37.62980505021509, -8.471143299736619],
              [-37.629595907320841, -8.470749109998213],
              [-37.629333808840052, -8.470407273570496],
              [-37.629123830869375, -8.470249190523822],
              [-37.628807937676932, -8.470248081832066],
              [-37.628624116219299, -8.470116224307029],
              [-37.6286778168813, -8.469827944808719],
              [-37.628889370878113, -8.469566443690377],
              [-37.629021999214395, -8.469278350655028],
              [-37.628996588080284, -8.469016017790617],
              [-37.6288132309707, -8.468753130648039],
              [-37.628840406015428, -8.468517206704355],
              [-37.629077743876664, -8.468386827245698],
              [-37.629236153784809, -8.468256261157963],
              [-37.6291582472589, -8.467967519743695],
              [-37.628869159495778, -8.467835383584726],
              [-37.628711864100559, -8.46765117060208],
              [-37.628607897190641, -8.467283664595509],
              [-37.628792833732611, -8.467074518617348],
              [-37.628977768403033, -8.466891506454775],
              [-37.628978418088352, -8.46670793813049],
              [-37.628768722523958, -8.466444958709991],
              [-37.628532407425467, -8.466286783277917],
              [-37.628428069811072, -8.466024083021921],
              [-37.62842890536605, -8.46578806664877],
              [-37.628298135499961, -8.465551588422393],
              [-37.627903600010541, -8.465445306142236],
              [-37.627429857181717, -8.465443642784829],
              [-37.627061382613896, -8.465416124284177],
              [-37.626825348080352, -8.465179185520775],
              [-37.626773877915973, -8.464838088133268],
              [-37.626379992397325, -8.46457446068883],
              [-37.626169926012771, -8.46444251029591],
              [-37.625880377088741, -8.464441492561713],
              [-37.625669196406918, -8.464598186616454],
              [-37.625536847313306, -8.464807515977773],
              [-37.625483703853028, -8.464938450793538],
              [-37.62561437868041, -8.465201153673192],
              [-37.62582360591454, -8.465595344847603],
              [-37.62598071338369, -8.465831916261294],
              [-37.625980155352671, -8.465989260347902],
              [-37.625690326217857, -8.466066914321772],
              [-37.625479703054417, -8.466066173628199],
              [-37.625373971328195, -8.466197013863409],
              [-37.625346887654224, -8.466406713275527],
              [-37.625345770989782, -8.466721401354086],
              [-37.625213327438971, -8.466957044872153],
              [-37.624949929880955, -8.467008566812463],
              [-37.624608073023339, -8.466902466475032],
              [-37.624293484977919, -8.466560352529786],
              [-37.62405764009489, -8.46624483039658],
              [-37.624059316870053, -8.465772708094137],
              [-37.624192225824174, -8.46540603545221],
              [-37.624245556333861, -8.465222562475375],
              [-37.62427208173596, -8.465170207189168],
              [-37.624036144411242, -8.464880909153706],
              [-37.623456953300739, -8.464905094193565],
              [-37.623062138618714, -8.464903703593123],
              [-37.622798369607324, -8.465060120187729],
              [-37.622691984877022, -8.465374527455181],
              [-37.622769606502999, -8.465741941280728],
              [-37.62279436061408, -8.466187932165269],
              [-37.622819487927387, -8.466528936705668],
              [-37.622608398562946, -8.46665931430613],
              [-37.62234518767022, -8.466658386521381],
              [-37.621871353643627, -8.466656715870297],
              [-37.621529215927765, -8.46665550919888],
              [-37.621186520744274, -8.46678542170919],
              [-37.620923309924045, -8.466784492953272],
              [-37.620895943389925, -8.467072953955961],
              [-37.621211366231094, -8.467205188429784],
              [-37.621552662004071, -8.467468635412699],
              [-37.621998946983325, -8.467784991450712],
              [-37.622471569964333, -8.468127573815357],
              [-37.622813240470876, -8.468286124048005],
              [-37.62315510014232, -8.468366001765681],
              [-37.623391690896483, -8.468471732641845],
              [-37.623549076886178, -8.468629723424833],
              [-37.623443066830049, -8.468812920122176],
              [-37.623206007338744, -8.468864533442744],
              [-37.622994636944163, -8.469073582985745],
              [-37.622862284631054, -8.469282910918778],
              [-37.622886851903004, -8.469781349722368],
              [-37.623149039320232, -8.470070741236826],
              [-37.623437752771252, -8.470307867919473],
              [-37.623647539138538, -8.470544625925257],
              [-37.623752433607969, -8.470649892748526],
              [-37.623462693882331, -8.47070132044246],
              [-37.623094958850373, -8.47049022993883],
              [-37.622647832628154, -8.470383756434408],
              [-37.622332033489961, -8.470356418521096],
              [-37.622094693024394, -8.470486702884465],
              [-37.621937213440013, -8.470355025895264],
              [-37.621648314043512, -8.470170436594262],
              [-37.621279742950932, -8.470169135949609],
              [-37.62083271164218, -8.470036346124894],
              [-37.620702412834717, -8.469668746163025],
              [-37.620387172612489, -8.469510287373764],
              [-37.620071094295341, -8.469561619603251],
              [-37.620096125472521, -8.469928847856592],
              [-37.620252952372184, -8.470217959241662],
              [-37.62025183105871, -8.470532646581873],
              [-37.620171595078787, -8.470899503002201],
              [-37.619881854791835, -8.470951018359704],
              [-37.61935542781076, -8.47094915800575],
              [-37.618855437038647, -8.470894851471018],
              [-37.61843455823692, -8.47081469031116],
              [-37.618066265049201, -8.470760939017403],
              [-37.617777274077376, -8.470602571020763],
              [-37.617382457351333, -8.470574949517596],
              [-37.617118682431688, -8.4707313612454],
              [-37.616696867261254, -8.470913527776148],
              [-37.616406752400003, -8.471069845657803],
              [-37.616352852220338, -8.471436793983827],
              [-37.616404034331985, -8.471830429209257],
              [-37.616613816460287, -8.472067190419043],
              [-37.616850222366125, -8.472199148898317],
              [-37.61690158949002, -8.472566560557937],
              [-37.616061343569505, -8.472719663257983],
              [-37.615771320830021, -8.472849756502693],
              [-37.615559569381098, -8.47316369654353],
              [-37.615584689380817, -8.473504790908954],
              [-37.615742073712525, -8.47366269418932],
              [-37.615899270619501, -8.473873045209617],
              [-37.615893241297137, -8.473877183784779],
              [-37.626188004439456, -8.473812349486977],
              [-37.627397816651666, -8.475328030623851],
              [-37.63444851891002, -8.47891692506086],
              [-37.636404743414516, -8.480093736724818],
              [-37.640354808949731, -8.483513717852006],
              [-37.646240767339556, -8.484725055880375],
              [-37.646460394446557, -8.484674002131994],
              [-37.650455326664463, -8.486290168015113],
              [-37.649498760585324, -8.487594557456935],
              [-37.649464776582477, -8.487806135234388],
              [-37.65157216048776, -8.490906664251252],
              [-37.652151080870993, -8.491102819223549],
              [-37.65627065388238, -8.492924102817756],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0174000',
        uf: 'PE',
        nome_proje: 'PA PAULO FREIRE',
        municipio: 'ITACURUBA',
        area_hecta: '1699.0000',
        capacidade: 48.0,
        num_famili: 48.0,
        fase: 3.0,
        data_de_cr: '03/08/2006',
        forma_obte: 'Doação',
        data_obten: '03/08/2006',
        area_calc_: 1628.7463,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.69277346748364, -8.689723112453546],
              [-38.680322082597954, -8.697786925343117],
              [-38.678552596003151, -8.698928512040208],
              [-38.688896140080175, -8.721022938225273],
              [-38.695171886952963, -8.721028026795906],
              [-38.695168221308577, -8.725548150353532],
              [-38.695167913798592, -8.725927231765112],
              [-38.69515720459988, -8.739118283289246],
              [-38.689245633873995, -8.739113482984497],
              [-38.704610246025666, -8.770648045196147],
              [-38.721900888022482, -8.750083476577375],
              [-38.712945626622925, -8.73169606712818],
              [-38.710318114693408, -8.726300629873197],
              [-38.710156183945244, -8.7259680066296],
              [-38.70475321571103, -8.714702816469291],
              [-38.700936705191666, -8.716591443957379],
              [-38.696462827007942, -8.718766961602208],
              [-38.694958771641801, -8.715785111925907],
              [-38.696123364658007, -8.715170161208523],
              [-38.698812744468277, -8.711071414074254],
              [-38.701358096323602, -8.707624054198449],
              [-38.69277346748364, -8.689723112453546],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0096000',
        uf: 'PE',
        nome_proje: 'PA VÁRZEA GRANDE',
        municipio: 'GRAVATA',
        area_hecta: '385.8099',
        capacidade: 38.0,
        num_famili: 36.0,
        fase: 5.0,
        data_de_cr: '30/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '17/09/1996',
        area_calc_: 385.3813,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.560886079728803, -8.29205443058002],
              [-35.560762122052971, -8.292400159730423],
              [-35.560707581049456, -8.292551785352106],
              [-35.560585189428686, -8.292887202576566],
              [-35.56044206120972, -8.293340773034368],
              [-35.56039236508505, -8.293498060699706],
              [-35.560422734382414, -8.293676612557165],
              [-35.560197779425081, -8.29371086120349],
              [-35.559885367306293, -8.293717205740148],
              [-35.559460441494423, -8.293749123749828],
              [-35.559063602755089, -8.293719230247154],
              [-35.558706614701109, -8.293692875917575],
              [-35.558265913520628, -8.293740527558947],
              [-35.557523158281015, -8.293740957277873],
              [-35.557014216789447, -8.293716118749311],
              [-35.556593744221779, -8.293665228289022],
              [-35.556164344442109, -8.293496464618732],
              [-35.555244851835262, -8.293088837734915],
              [-35.554348121062674, -8.293480005246368],
              [-35.554013649388573, -8.29366704171545],
              [-35.553500970259108, -8.293737922244034],
              [-35.552877403039318, -8.294016093857159],
              [-35.552788822844782, -8.294010968114426],
              [-35.552449567521194, -8.293991272125851],
              [-35.552173201231014, -8.293932043700213],
              [-35.551824622663453, -8.29388755562757],
              [-35.551605128507667, -8.293798321830961],
              [-35.551198614939629, -8.293619103706645],
              [-35.550870147355745, -8.293752782337618],
              [-35.550475967845529, -8.293882272558664],
              [-35.550149190805435, -8.293771764973567],
              [-35.54995363912056, -8.293906219048772],
              [-35.549882958353486, -8.294214736640388],
              [-35.549638315535468, -8.294502588598855],
              [-35.549343194117029, -8.294784979892182],
              [-35.54882355657211, -8.295085886925266],
              [-35.548666653241661, -8.295262656933915],
              [-35.54864839242525, -8.295239004310993],
              [-35.54864839242525, -8.295239004310993],
              [-35.548648346791367, -8.295238945303485],
              [-35.548648301058215, -8.295238886377939],
              [-35.548648255225785, -8.295238827534364],
              [-35.54864820929398, -8.295238768754666],
              [-35.548648163262897, -8.295238710056953],
              [-35.548648117141568, -8.295238651432115],
              [-35.548648070920905, -8.295238592880187],
              [-35.548648024600979, -8.295238534410245],
              [-35.548647978181727, -8.295238476013218],
              [-35.548647931672271, -8.295238417698116],
              [-35.548647885063495, -8.295238359455928],
              [-35.54864783836446, -8.295238301286615],
              [-35.54864779155708, -8.295238243199325],
              [-35.548647744659455, -8.295238185184909],
              [-35.548647697671576, -8.295238127243364],
              [-35.548647650584414, -8.295238069383791],
              [-35.548647603397995, -8.295238011606168],
              [-35.548647556121324, -8.295237953901431],
              [-35.548647508745375, -8.295237896278666],
              [-35.5486474612701, -8.295237838728813],
              [-35.548647413704636, -8.295237781260873],
              [-35.548647366048918, -8.295237723865801],
              [-35.548647318293924, -8.295237666552721],
              [-35.548647270439609, -8.295237609312549],
              [-35.548647222495148, -8.295237552163313],
              [-35.548647174460378, -8.295237495077926],
              [-35.548647126326401, -8.295237438083547],
              [-35.54864707809309, -8.295237381162082],
              [-35.54864702976959, -8.295237324322544],
              [-35.548646981355887, -8.295237267564897],
              [-35.548646932842864, -8.295237210880185],
              [-35.548646884239638, -8.295237154277382],
              [-35.548646835546222, -8.295237097756488],
              [-35.54864678675353, -8.295237041317547],
              [-35.548646737870584, -8.295236984951496],
              [-35.548646688888375, -8.295236928667395],
              [-35.548646639816027, -8.295236872474263],
              [-35.548646590653426, -8.295236816353983],
              [-35.548646541400565, -8.295236760306578],
              [-35.548646492048491, -8.295236704350181],
              [-35.54864644260622, -8.295236648475674],
              [-35.54864639307376, -8.295236592683098],
              [-35.548646343451047, -8.295236536963376],
              [-35.548646293729114, -8.29523648133468],
              [-35.548646243916934, -8.295236425778835],
              [-35.548646194014609, -8.295236370313946],
              [-35.548646144022037, -8.295236314921905],
              [-35.548646093939325, -8.295236259620836],
              [-35.54864604375728, -8.295236204392694],
              [-35.548645993494176, -8.295236149255429],
              [-35.548645943131739, -8.295236094191091],
              [-35.548645892679168, -8.295236039217704],
              [-35.548645842136395, -8.295235984326228],
              [-35.548645791512499, -8.29523592951659],
              [-35.54864574078934, -8.295235874788917],
              [-35.548645689976041, -8.295235820152215],
              [-35.548645639063409, -8.295235765588423],
              [-35.548645588069718, -8.295235711115504],
              [-35.548645536985831, -8.295235656724515],
              [-35.54864548581174, -8.295235602415435],
              [-35.54864543454746, -8.295235548188254],
              [-35.548645383202107, -8.295235494051957],
              [-35.548645331757491, -8.295235439997635],
              [-35.548645280222672, -8.295235386025199],
              [-35.548645228597728, -8.295235332143736],
              [-35.548645176891647, -8.295235278344109],
              [-35.548645125086303, -8.295235224626451],
              [-35.548645073199829, -8.29523517099064],
              [-35.548645021223216, -8.295235117445786],
              [-35.54864496915647, -8.295235063991859],
              [-35.548644916999471, -8.295235010610806],
              [-35.548644864761407, -8.295234957320643],
              [-35.548644812433203, -8.295234904121433],
              [-35.548644760014795, -8.295234851004134],
              [-35.548644707506256, -8.295234797977763],
              [-35.548644654907513, -8.295234745033305],
              [-35.548644602227654, -8.2952346921707],
              [-35.548644549457656, -8.295234639399046],
              [-35.548644496606592, -8.295234586718262],
              [-35.548644443656258, -8.29523453411945],
              [-35.548644390633868, -8.29523448160243],
              [-35.548644337512322, -8.295234429185456],
              [-35.548644284309603, -8.295234376841284],
              [-35.548644231016802, -8.295234324597097],
              [-35.548644177642878, -8.295234272434765],
              [-35.548644124178814, -8.295234220363378],
              [-35.548644070624547, -8.295234168373906],
              [-35.548644016989222, -8.295234116475305],
              [-35.548643963272831, -8.295234064667598],
              [-35.548643909466236, -8.295234012941801],
              [-35.548643855569566, -8.295233961315976],
              [-35.548643801591773, -8.295233909772003],
              [-35.54864374753285, -8.295233858309881],
              [-35.548643693383852, -8.295233806947751],
              [-35.548643639153731, -8.295233755667473],
              [-35.548643584833464, -8.29523370447812],
              [-35.548643530432138, -8.295233653379668],
              [-35.548643475940615, -8.295233602363105],
              [-35.548643421377157, -8.295233551446433],
              [-35.548643366714423, -8.295233500611712],
              [-35.54864331197976, -8.295233449876863],
              [-35.5486432571549, -8.295233399223903],
              [-35.548643202248968, -8.295233348661844],
              [-35.548643147261977, -8.29523329819067],
              [-35.548643092184847, -8.295233247810444],
              [-35.54864303702665, -8.295233197521114],
              [-35.548642981787381, -8.295233147322655],
              [-35.54864292645798, -8.295233097215148],
              [-35.548642871056529, -8.295233047189416],
              [-35.548642815564989, -8.295232997263676],
              [-35.548642759992383, -8.295232947428824],
              [-35.548642704338718, -8.295232897684865],
              [-35.548642648603987, -8.29523284803178],
              [-35.548642592788191, -8.295232798469588],
              [-35.548642536882305, -8.295232749007379],
              [-35.54864248090437, -8.295232699626967],
              [-35.548642424836302, -8.295232650337493],
              [-35.548642368696299, -8.295232601147886],
              [-35.548642312466093, -8.295232552040172],
              [-35.548642256163959, -8.295232503032331],
              [-35.548642199771677, -8.295232454115441],
              [-35.548642143298395, -8.295232405298478],
              [-35.548642086753055, -8.295232356563295],
              [-35.548642030117641, -8.295232307928099],
              [-35.548641973410227, -8.295232259383734],
              [-35.548641916612674, -8.295232210930306],
              [-35.548641859743128, -8.295232162567709],
              [-35.548641802792581, -8.295232114305042],
              [-35.54864174576096, -8.295232066133252],
              [-35.548641688648274, -8.295232018052351],
              [-35.54864163146366, -8.295231970071322],
              [-35.548641574188906, -8.295231922181227],
              [-35.548641516842153, -8.295231874381962],
              [-35.548641459414391, -8.295231826682631],
              [-35.548641401905563, -8.29523177907417],
              [-35.548641344315726, -8.295231731565641],
              [-35.548641286653897, -8.295231684147948],
              [-35.548641228911009, -8.295231636821127],
              [-35.548641171087105, -8.295231589594234],
              [-35.548641113191209, -8.295231542458161],
              [-35.548641055214297, -8.295231495422035],
              [-35.548640997156333, -8.29523144847678],
              [-35.548640939026427, -8.29523140163138],
              [-35.548640880815455, -8.295231354876876],
              [-35.548640822532541, -8.29523130822224],
              [-35.548640764168567, -8.295231261658481],
              [-35.548640705723585, -8.295231215194669],
              [-35.548640647206668, -8.295231168830693],
              [-35.548640588608684, -8.295231122557626],
              [-35.548640529938702, -8.295231076375376],
              [-35.548640471196848, -8.29523103030202],
              [-35.548640412373864, -8.295230984310511],
              [-35.548640353469935, -8.295230938427977],
              [-35.548640294494007, -8.295230892636274],
              [-35.54864023544615, -8.295230846944428],
              [-35.548640176317221, -8.295230801343472],
              [-35.54864011711642, -8.29523075585141],
              [-35.548640057834554, -8.295230710450237],
              [-35.548639998480688, -8.295230665139899],
              [-35.548639939054944, -8.295230619938458],
              [-35.548639879557207, -8.295230574827848],
              [-35.548639819978462, -8.295230529817148],
              [-35.548639760327724, -8.295230484897285],
              [-35.548639700605108, -8.29523044008633],
              [-35.548639640801426, -8.295230395366254],
              [-35.548639580925808, -8.295230350746044],
              [-35.548639520978256, -8.295230306225692],
              [-35.548639460958768, -8.295230261805212],
              [-35.548639400867337, -8.295230217484601],
              [-35.548639340694848, -8.295230173254868],
              [-35.548639280459554, -8.295230129133987],
              [-35.548639220143187, -8.295230085103983],
              [-35.548639159754885, -8.295230041173847],
              [-35.548639099303728, -8.295229997343524],
              [-35.548639038771555, -8.295229953613113],
              [-35.548638978167446, -8.295229909982577],
              [-35.548638917491409, -8.295229866451898],
              [-35.54863885674343, -8.295229823021089],
              [-35.548638795923516, -8.295229779690153],
              [-35.548638735031659, -8.295229736459069],
              [-35.548638674067881, -8.295229693327856],
              [-35.548638613032153, -8.295229650296502],
              [-35.548638551924505, -8.295229607365018],
              [-35.548638490754037, -8.295229564542373],
              [-35.54863842950251, -8.295229521810615],
              [-35.548638368188115, -8.295229479178673],
              [-35.548638306801841, -8.295229436655619],
              [-35.548638245343575, -8.295229394223391],
              [-35.548638183813438, -8.295229351900083],
              [-35.548638122220375, -8.295229309667537],
              [-35.548638060546359, -8.295229267543942],
              [-35.548637998809482, -8.295229225520163],
              [-35.548637937000727, -8.295229183605272],
              [-35.548637875129046, -8.295229141781162],
              [-35.548637813176427, -8.295229100066017],
              [-35.548637751170006, -8.295229058450614],
              [-35.548637689082582, -8.295229016935137],
              [-35.54863762693229, -8.295228975519461],
              [-35.548637564710127, -8.295228934212693],
              [-35.548637502425095, -8.295228893005723],
              [-35.548637440068127, -8.295228851898624],
              [-35.548637377639217, -8.295228810891382],
              [-35.548637315147516, -8.29522876999299],
              [-35.548637252583866, -8.295228729194472],
              [-35.548637189957354, -8.295228688495749],
              [-35.548637127268044, -8.295228647905878],
              [-35.548637064506799, -8.295228607415869],
              [-35.548637001673612, -8.295228567025706],
              [-35.548636938777619, -8.295228526744415],
              [-35.548636875818765, -8.295228486562904],
              [-35.548636812788033, -8.295228446490306],
              [-35.548636749694438, -8.29522840651752],
              [-35.548636686528909, -8.295228366644606],
              [-35.54863662330964, -8.295228326880451],
              [-35.548636560009363, -8.295228287216247],
              [-35.548636496655362, -8.295228247660821],
              [-35.548636433229419, -8.295228208205247],
              [-35.54863636974067, -8.295228168858525],
              [-35.54863630618906, -8.295228129611599],
              [-35.548636242574645, -8.295228090473529],
              [-35.548636178888295, -8.295228051435329],
              [-35.548636115139075, -8.295228012496926],
              [-35.548636051327108, -8.295227973676397],
              [-35.548635987452279, -8.295227934955683],
              [-35.548635923514588, -8.295227896334783],
              [-35.548635859505019, -8.295227857822759],
              [-35.548635795441719, -8.295227819419548],
              [-35.548635731306476, -8.295227781116189],
              [-35.548635667108378, -8.295227742912648],
              [-35.548635602856599, -8.295227704826901],
              [-35.548635538532885, -8.295227666841045],
              [-35.548635474155446, -8.295227628963968],
              [-35.548635409706058, -8.295227591186746],
              [-35.548635345193865, -8.295227553518373],
              [-35.548635280627948, -8.295227515958802],
              [-35.548635215990096, -8.295227478499079],
              [-35.548635151298505, -8.295227441148134],
              [-35.54863508654411, -8.295227403906042],
              [-35.548635021726902, -8.295227366772808],
              [-35.54863495684684, -8.295227329739349],
              [-35.548634891903973, -8.29522729281476],
              [-35.548634826907367, -8.295227255998945],
              [-35.548634761838827, -8.295227219282991],
              [-35.548634696716611, -8.295227182684867],
              [-35.548634631531534, -8.295227146186541],
              [-35.548634566292719, -8.295227109797008],
              [-35.548634500982033, -8.295227073516369],
              [-35.548634435626617, -8.295227037335431],
              [-35.548634370199395, -8.295227001272423],
              [-35.548634304718377, -8.295226965309167],
              [-35.548634239174547, -8.295226929454751],
              [-35.548634173567912, -8.29522689370919],
              [-35.548634107907553, -8.2952268580724],
              [-35.548634042193449, -8.295226822544393],
              [-35.54863397640748, -8.295226787125294],
              [-35.548633910576783, -8.295226751805895],
              [-35.548633844674271, -8.295226716604427],
              [-35.54863377872703, -8.295226681502637],
              [-35.548633712717056, -8.295226646518742],
              [-35.548633646644205, -8.295226611634639],
              [-35.548633580517631, -8.295226576859335],
              [-35.548633514328301, -8.295226542201904],
              [-35.548633448094257, -8.295226507644172],
              [-35.548633381788335, -8.295226473195333],
              [-35.548633315437812, -8.295226438864253],
              [-35.548633249024419, -8.295226404632984],
              [-35.548633182557353, -8.295226370519549],
              [-35.548633116027425, -8.295226336505911],
              [-35.548633049443829, -8.295226302610091],
              [-35.548632982806431, -8.295226268814025],
              [-35.548632916115352, -8.295226235135777],
              [-35.548632849361425, -8.295226201557343],
              [-35.548632782562883, -8.295226168096663],
              [-35.548632715701544, -8.295226134744819],
              [-35.548632648786466, -8.295226101501774],
              [-35.548632581817657, -8.295226068367523],
              [-35.548632514795173, -8.295226035351087],
              [-35.548632447709828, -8.295226002434447],
              [-35.548632380579868, -8.295225969635585],
              [-35.54863231338706, -8.295225936936514],
              [-35.548632246149644, -8.295225904355203],
              [-35.548632178849473, -8.295225871891788],
              [-35.548632111504588, -8.295225839528065],
              [-35.548632044096898, -8.295225807273184],
              [-35.5486319766446, -8.295225775136055],
              [-35.548631909138578, -8.295225743107723],
              [-35.548631841569737, -8.295225711188227],
              [-35.548631773956309, -8.295225679386489],
              [-35.548631706289086, -8.295225647684507],
              [-35.548631638568182, -8.295225616100353],
              [-35.548631570802613, -8.295225584624907],
              [-35.548631502974303, -8.29522555326735],
              [-35.548631435101328, -8.295225522018535],
              [-35.548631367174622, -8.295225490878492],
              [-35.548631299194184, -8.295225459847243],
              [-35.548631231160073, -8.295225428933795],
              [-35.548631163081296, -8.2952253981291],
              [-35.548631094948782, -8.295225367433167],
              [-35.5486310267626, -8.295225336855065],
              [-35.54863095853176, -8.295225306385701],
              [-35.548630890247232, -8.295225276034154],
              [-35.548630821918053, -8.29522524579132],
              [-35.548630753535136, -8.295225215657291],
              [-35.548630685098487, -8.295225185632034],
              [-35.548630616617238, -8.295225155724534],
              [-35.548630548082308, -8.295225125934866],
              [-35.548630479502719, -8.295225096253921],
              [-35.5486304108694, -8.29522506668177],
              [-35.548630342191473, -8.295225037227377],
              [-35.548630273459814, -8.295225007881777],
              [-35.548630204683562, -8.295224978653918],
              [-35.548630135862631, -8.295224949534814],
              [-35.548630066987975, -8.29522492052447],
              [-35.548629998068719, -8.295224891631902],
              [-35.548629929095782, -8.295224862857166],
              [-35.548629860087267, -8.295224834191076],
              [-35.548629791015934, -8.29522480563385],
              [-35.54862972190913, -8.295224777203352],
              [-35.548629652748538, -8.295224748872608],
              [-35.548629583543338, -8.29522472065962],
              [-35.548629514293545, -8.295224692564412],
              [-35.548629444999079, -8.295224664577917],
              [-35.548629375650947, -8.295224636709261],
              [-35.548629306267223, -8.295224608949262],
              [-35.548629236829818, -8.29522458130708],
              [-35.548629167347819, -8.29522455378266],
              [-35.548629097821156, -8.295224526366972],
              [-35.548629028249884, -8.295224499069061],
              [-35.548628958633955, -8.295224471879854],
              [-35.548628888973425, -8.295224444808419],
              [-35.548628819259221, -8.29522441785482],
              [-35.548628749509419, -8.295224391009882],
              [-35.548628679715023, -8.295224364282701],
              [-35.548628609876019, -8.295224337673279],
              [-35.548628539992357, -8.295224311172589],
              [-35.548628470064081, -8.295224284789661],
              [-35.548628400091154, -8.295224258515473],
              [-35.5486283300827, -8.295224232358979],
              [-35.548628260020564, -8.295224206320322],
              [-35.548628189922901, -8.295224180399345],
              [-35.548628119780574, -8.295224154587105],
              [-35.548628049593702, -8.295224128901681],
              [-35.548627979362109, -8.295224103315938],
              [-35.548627909095053, -8.295224077856931],
              [-35.548627838783325, -8.295224052506644],
              [-35.548627768426989, -8.295224027274132],
              [-35.548627698035133, -8.295224002159321],
              [-35.548627627589603, -8.295223977162344],
              [-35.548627557117548, -8.29522395227397],
              [-35.548627486591826, -8.295223927503411],
              [-35.548627416030563, -8.295223902850569],
              [-35.548627345433779, -8.295223878315428],
              [-35.548627274792331, -8.295223853889006],
              [-35.548627204106275, -8.295223829580358],
              [-35.548627133384748, -8.295223805398452],
              [-35.548627062627574, -8.295223781316169],
              [-35.548626991816789, -8.295223757360752],
              [-35.548626920979537, -8.295223733522981],
              [-35.548626850097627, -8.29522370979393],
              [-35.548626779180246, -8.295223686191633],
              [-35.548626708218201, -8.295223662698056],
              [-35.548626637220629, -8.295223639322179],
              [-35.548626566187515, -8.295223616064019],
              [-35.548626495109815, -8.295223592923618],
              [-35.54862642399651, -8.295223569891895],
              [-35.548626352847741, -8.295223546986895],
              [-35.548626281654364, -8.295223524199667],
              [-35.548626210425404, -8.295223501521118],
              [-35.548626139160909, -8.295223478960256],
              [-35.548626067860937, -8.295223456526148],
              [-35.548625996525388, -8.295223434200715],
              [-35.548625925145231, -8.295223411993041],
              [-35.548625853738606, -8.295223389903009],
              [-35.548625782287388, -8.295223367930738],
              [-35.548625710800628, -8.295223346076183],
              [-35.548625639287422, -8.29522332433927],
              [-35.548625567729609, -8.295223302720114],
              [-35.54862549613626, -8.295223281218677],
              [-35.548625424507385, -8.295223259834938],
              [-35.548625352842983, -8.295223238568902],
              [-35.548625281143046, -8.295223217420579],
              [-35.548625209416585, -8.295223196380864],
              [-35.548625137645587, -8.295223175467939],
              [-35.548625065839055, -8.295223154672735],
              [-35.548624994006062, -8.295223133995176],
              [-35.548624922137542, -8.295223113435314],
              [-35.548624850233494, -8.295223092993169],
              [-35.548624778293913, -8.295223072668723],
              [-35.54862470631879, -8.29522305246198],
              [-35.548624634317285, -8.295223032381914],
              [-35.548624562280182, -8.295223012410531],
              [-35.548624490207544, -8.295222992556845],
              [-35.548624418099436, -8.295222972829915],
              [-35.548624345964811, -8.295222953211589],
              [-35.548624273794708, -8.295222933720005],
              [-35.548624201598095, -8.295222914337018],
              [-35.548624129366019, -8.295222895080791],
              [-35.548624057098394, -8.295222875942262],
              [-35.548623984804316, -8.295222856921374],
              [-35.54862391247471, -8.295222838018205],
              [-35.54862384010957, -8.295222819232738],
              [-35.548623767727044, -8.295222800564851],
              [-35.548623695299973, -8.295222782023764],
              [-35.548623622855459, -8.295222763591237],
              [-35.548623550366401, -8.295222745285507],
              [-35.548623477859955, -8.295222727097379],
              [-35.548623405317969, -8.29522270902695],
              [-35.548623332740469, -8.295222691074219],
              [-35.548623260145632, -8.295222673248118],
              [-35.548623187515204, -8.295222655530686],
              [-35.548623114849299, -8.295222637939998],
              [-35.548623042166014, -8.295222620466895],
              [-35.548622969447194, -8.29522260311149],
              [-35.548622896701922, -8.295222585873743],
              [-35.548622823921171, -8.295222568762737],
              [-35.548622751123034, -8.295222551769333],
              [-35.54862267828937, -8.295222534893609],
              [-35.548622605429244, -8.295222518135544],
              [-35.548622532542659, -8.295222501495141],
              [-35.548622459629669, -8.295222484981402],
              [-35.54862238669017, -8.295222468576277],
              [-35.548622313715192, -8.295222452297914],
              [-35.548622240722892, -8.295222436146156],
              [-35.548622167704067, -8.295222420103018],
              [-35.548622094649772, -8.295222404186616],
              [-35.548622021578097, -8.295222388387803],
              [-35.548621948479955, -8.295222372706643],
              [-35.548621875355423, -8.295222357152168],
              [-35.548621802204423, -8.295222341715332],
              [-35.548621729026962, -8.295222326396155],
              [-35.548621655823048, -8.295222311194605],
              [-35.54862158259273, -8.295222296119752],
              [-35.548621509335952, -8.295222281162561],
              [-35.548621436061786, -8.295222266322932],
              [-35.548621362761224, -8.295222251610003],
              [-35.548621289434202, -8.295222237014716],
              [-35.548621216080726, -8.29522222253709],
              [-35.548621142709912, -8.295222208186082],
              [-35.548621069312588, -8.295222193943683],
              [-35.548620995888861, -8.295222179827961],
              [-35.548620922447803, -8.295222165838879],
              [-35.548620848980285, -8.29522215196744],
              [-35.54862077548632, -8.295222138213642],
              [-35.548620701974954, -8.29522212457743],
              [-35.548620628437199, -8.295222111067911],
              [-35.548620554882049, -8.29522209767598],
              [-35.548620481300496, -8.29522208441073],
              [-35.548620407701563, -8.29522207126308],
              [-35.548620334076169, -8.295222058233067],
              [-35.548620260433445, -8.295222045329684],
              [-35.548620186764204, -8.295222032534902],
              [-35.548620113077689, -8.295222019875796],
              [-35.548620039373795, -8.295222007334278],
              [-35.548619965643439, -8.295221994910399],
              [-35.548619891895697, -8.295221982604104],
              [-35.548619818130625, -8.295221970424452],
              [-35.548619744339099, -8.295221958362442],
              [-35.548619670530236, -8.29522194642705],
              [-35.54861959669492, -8.295221934609316],
              [-35.54861952285129, -8.295221922909112],
              [-35.548619448981256, -8.295221911335588],
              [-35.548619375093836, -8.29522189987965],
              [-35.548619301189085, -8.295221888550349],
              [-35.548619227266954, -8.29522187733863],
              [-35.548619153327493, -8.295221866253536],
              [-35.548619079361572, -8.295221855286087],
              [-35.54861900538733, -8.295221844436178],
              [-35.548618931386692, -8.295221833712949],
              [-35.54861885737774, -8.295221823107246],
              [-35.548618783342384, -8.295221812628244],
              [-35.548618709289649, -8.295221802266807],
              [-35.548618635228586, -8.295221792022927],
              [-35.548618561141133, -8.295221781905711],
              [-35.548618487045424, -8.295221771915067],
              [-35.548618412932328, -8.295221762042015],
              [-35.548618338792771, -8.29522175228661],
              [-35.548618264644958, -8.295221742657784],
              [-35.54861819047975, -8.295221733146526],
              [-35.548618116306294, -8.295221723761852],
              [-35.548618042106376, -8.295221714494815],
              [-35.548617967898203, -8.295221705354365],
              [-35.548617893672649, -8.295221696331478],
              [-35.548617819429758, -8.295221687435234],
              [-35.548617745178561, -8.295221678656514],
              [-35.548617670909969, -8.295221669995394],
              [-35.548617596624048, -8.295221661460896],
              [-35.548617522329877, -8.295221653052948],
              [-35.548617448018312, -8.29522164476262],
              [-35.548617373689424, -8.295221636598892],
              [-35.548617299352223, -8.295221628552708],
              [-35.548617224997635, -8.295221620624112],
              [-35.548617150634783, -8.295221612822079],
              [-35.548617076254608, -8.295221605146686],
              [-35.548617001866113, -8.2952215975888],
              [-35.548616927469368, -8.295221590157496],
              [-35.548616853055236, -8.295221582843778],
              [-35.54861677862371, -8.295221575647657],
              [-35.548616704183935, -8.295221568578103],
              [-35.548616629735903, -8.295221561635115],
              [-35.548616555270478, -8.295221554809713],
              [-35.548616480796802, -8.295221548110888],
              [-35.548616406314814, -8.295221541529569],
              [-35.548616331824562, -8.295221535074841],
              [-35.54861625731693, -8.295221528737706],
              [-35.548616182801041, -8.295221522527124],
              [-35.548616108276832, -8.295221516434081],
              [-35.548616033744366, -8.295221510467604],
              [-35.548615959194514, -8.295221504618725],
              [-35.548615884636412, -8.295221498896399],
              [-35.54861581007912, -8.295221493300595],
              [-35.548615735504441, -8.295221487822376],
              [-35.548615660921456, -8.295221482461679],
              [-35.5486155863302, -8.295221477227567],
              [-35.548615511730695, -8.295221472120017],
              [-35.548615437122884, -8.295221467129997],
              [-35.548615362506801, -8.295221462266539],
              [-35.548615287882413, -8.295221457520624],
              [-35.548615213249761, -8.295221452901272],
              [-35.548615138608859, -8.295221448408485],
              [-35.548615063959637, -8.295221444033228],
              [-35.548614989311183, -8.295221439775432],
              [-35.548614914645391, -8.295221435644281],
              [-35.548614839980416, -8.295221431639636],
              [-35.548614765307128, -8.295221427752532],
              [-35.548614690625584, -8.295221423991975],
              [-35.548614615935726, -8.295221420348968],
              [-35.548614541246678, -8.29522141683246],
              [-35.548614466549381, -8.295221413442519],
              [-35.548614391843763, -8.295221410170122],
              [-35.548614317138906, -8.295221407015191],
              [-35.548614242416782, -8.295221403995923],
              [-35.548614167704422, -8.295221401085023],
              [-35.548614092974795, -8.295221398309803],
              [-35.548614018245928, -8.295221395652053],
              [-35.548613943517815, -8.295221393111767],
              [-35.548613868781445, -8.29522139069805],
              [-35.548613794036818, -8.295221388410894],
              [-35.548613719292952, -8.295221386241229],
              [-35.548613644549896, -8.295221384198063],
              [-35.548613569798526, -8.295221382272443],
              [-35.548613495038907, -8.29522138047337],
              [-35.548613420280098, -8.295221378800822],
              [-35.54861334552205, -8.29522137724574],
              [-35.548613270764811, -8.295221375817167],
              [-35.548613195999259, -8.295221374506136],
              [-35.548613121234524, -8.295221373321594],
              [-35.548613046461483, -8.295221372254613],
              [-35.548612971689245, -8.295221371314124],
              [-35.548612896917831, -8.295221370500133],
              [-35.54861282214717, -8.295221369803633],
              [-35.548612747377319, -8.295221369233639],
              [-35.54861267259917, -8.295221368781171],
              [-35.548612597821823, -8.295221368455229],
              [-35.548612523045293, -8.295221368255772],
              [-35.548612448269523, -8.295221368173801],
              [-35.548612373494571, -8.295221368218355],
              [-35.548612298720371, -8.295221368380359],
              [-35.548612223946989, -8.295221368668887],
              [-35.54861214917436, -8.295221369074884],
              [-35.548612074393482, -8.295221369607443],
              [-35.548611999622494, -8.295221370266468],
              [-35.548611924852253, -8.295221371042944],
              [-35.548611850082835, -8.295221371945948],
              [-35.548611775314171, -8.295221372966431],
              [-35.548611700546324, -8.295221374113405],
              [-35.548611625779294, -8.295221375386905],
              [-35.548611551013011, -8.295221376777871],
              [-35.548611476256625, -8.295221378295286],
              [-35.548611401500999, -8.295221379930171],
              [-35.54861132674619, -8.295221381691576],
              [-35.548611251992185, -8.295221383579488],
              [-35.548611177238946, -8.295221385584874],
              [-35.548611102495592, -8.295221387716701],
              [-35.548611027752997, -8.295221389966017],
              [-35.548610953020287, -8.295221392341775],
              [-35.54861087827927, -8.295221394835067],
              [-35.548610803557203, -8.295221397454743],
              [-35.548610728826887, -8.295221400201008],
              [-35.548610654106398, -8.295221403064676],
              [-35.548610579395799, -8.295221406054797],
              [-35.548610504685946, -8.295221409162403],
              [-35.548610429976918, -8.295221412396495],
              [-35.548610355277717, -8.295221415748019],
              [-35.548610280579396, -8.295221419235084],
              [-35.548610205890846, -8.295221422830537],
              [-35.548610131212179, -8.295221426552423],
              [-35.548610056534322, -8.295221430400833],
              [-35.548609981866356, -8.295221434375692],
              [-35.548609907199094, -8.295221438458995],
              [-35.548609832541779, -8.295221442677768],
              [-35.548609757894283, -8.29522144701397],
              [-35.548609683256686, -8.295221451476619],
              [-35.548609608619842, -8.295221456056755],
              [-35.548609533992888, -8.295221460763321],
              [-35.548609459375761, -8.295221465587316],
              [-35.548609384759445, -8.295221470537815],
              [-35.548609310162043, -8.295221475605684],
              [-35.548609235565443, -8.295221480800043],
              [-35.548609160978742, -8.295221486120868],
              [-35.548609086401868, -8.295221491559101],
              [-35.548609011834877, -8.295221497123782],
              [-35.54860893727772, -8.295221502805893],
              [-35.548608862730447, -8.295221508614448],
              [-35.548608788193, -8.295221514540417],
              [-35.548608713665445, -8.295221520592834],
              [-35.54860863914778, -8.295221526771716],
              [-35.548608564639942, -8.295221533068004],
              [-35.54860849014193, -8.295221539481707],
              [-35.54860841565381, -8.295221546021857],
              [-35.54860834117558, -8.295221552688472],
              [-35.548608266707177, -8.295221559472497],
              [-35.548608192257731, -8.295221566382914],
              [-35.548608117818119, -8.295221573410741],
              [-35.548608043388398, -8.29522158056503],
              [-35.548607968968497, -8.295221587836732],
              [-35.548607894558486, -8.295221595234882],
              [-35.548607820167383, -8.295221602750399],
              [-35.548607745786157, -8.295221610392353],
              [-35.548607671414771, -8.295221618151729],
              [-35.548607597062343, -8.295221626037497],
              [-35.548607522719792, -8.295221634049728],
              [-35.548607448387081, -8.295221642179353],
              [-35.548607374073271, -8.295221650426351],
              [-35.548607299769351, -8.295221658799791],
              [-35.548607225484382, -8.295221667299646],
              [-35.548607151209247, -8.295221675916888],
              [-35.548607076953012, -8.295221684651503],
              [-35.548607002706667, -8.29522169351258],
              [-35.548606928470214, -8.295221702500092],
              [-35.548606854261728, -8.295221711604912],
              [-35.548606780063075, -8.295221720827142],
              [-35.548606705874313, -8.295221730175818],
              [-35.548606631704502, -8.295221739650906],
              [-35.548606557553597, -8.295221749243328],
              [-35.54860648341252, -8.295221758953179],
              [-35.5486064092904, -8.295221768789434],
              [-35.548606335187245, -8.295221778752063],
              [-35.548606261102933, -8.295221788823024],
              [-35.548606187028568, -8.29522179902947],
              [-35.548606112973097, -8.295221809353269],
              [-35.548606038936533, -8.295221819794437],
              [-35.548605964918934, -8.295221830361996],
              [-35.548605890911162, -8.295221841046962],
              [-35.548605816931413, -8.295221851858264],
              [-35.548605742961499, -8.295221862786988],
              [-35.548605669019558, -8.295221873833011],
              [-35.548605595087508, -8.295221885005482],
              [-35.548605521174416, -8.295221896304341],
              [-35.548605447280224, -8.29522190772057],
              [-35.548605373404932, -8.295221919254153],
              [-35.548605299557678, -8.295221930914064],
              [-35.548605225720245, -8.295221942691406],
              [-35.548605151901782, -8.295221954595119],
              [-35.548605078111287, -8.295221966616143],
              [-35.548605004330618, -8.295221978754595],
              [-35.548604930577987, -8.295221991019364],
              [-35.548604856844257, -8.295222003401497],
              [-35.548604783129484, -8.295222015910024],
              [-35.548604709433619, -8.295222028535921],
              [-35.548604635756703, -8.29522204128819],
              [-35.548604562107769, -8.295222054157771],
              [-35.548604488477736, -8.295222067144703],
              [-35.548604414875733, -8.295222080257982],
              [-35.54860434128355, -8.295222093488656],
              [-35.548604267719355, -8.295222106836642],
              [-35.548604194174111, -8.295222120311035],
              [-35.548604120656847, -8.295222133902701],
              [-35.548604047158541, -8.295222147620779],
              [-35.548603973688202, -8.295222161456149],
              [-35.54860390023677, -8.295222175408876],
              [-35.548603826804296, -8.295222189488003],
              [-35.548603753399803, -8.295222203684427],
              [-35.548603680023334, -8.295222218007185],
              [-35.548603606656641, -8.295222232438327],
              [-35.548603533327046, -8.295222246995731],
              [-35.548603460016416, -8.295222261679543],
              [-35.54860338673376, -8.295222276480661],
              [-35.548603313470004, -8.295222291399117],
              [-35.548603240234215, -8.295222306434884],
              [-35.548603167026464, -8.295222321596981],
              [-35.54860309383762, -8.295222336876447],
              [-35.548603020676801, -8.295222352282233],
              [-35.548602947543898, -8.295222367796288],
              [-35.548602874439027, -8.295222383436693],
              [-35.54860280135312, -8.295222399203469],
              [-35.548602728295123, -8.29522241507852],
              [-35.548602655265164, -8.295222431079903],
              [-35.548602582263172, -8.295222447198579],
              [-35.548602509289218, -8.295222463443604],
              [-35.5486024363341, -8.295222479796944],
              [-35.548602363416087, -8.29522249627656],
              [-35.548602290517039, -8.295222512882582],
              [-35.548602217654974, -8.295222529596799],
              [-35.548602144811873, -8.295222546437406],
              [-35.54860207199674, -8.29522256339531],
              [-35.548601999218654, -8.295222580470465],
              [-35.548601926459533, -8.295222597672014],
              [-35.548601853737459, -8.295222614990797],
              [-35.548601781034279, -8.295222632426951],
              [-35.548601708368146, -8.295222649980333],
              [-35.548601635729987, -8.295222667651014],
              [-35.548601563110786, -8.29522268544809],
              [-35.548601490537713, -8.295222703362352],
              [-35.548601417983534, -8.295222721393971],
              [-35.548601345457328, -8.295222739542883],
              [-35.54860127296822, -8.295222757818085],
              [-35.548601200507093, -8.295222776210583],
              [-35.548601128073891, -8.295222794711336],
              [-35.548601055677779, -8.295222813338381],
              [-35.548600983309697, -8.295222832091737],
              [-35.54860091096954, -8.295222850953387],
              [-35.548600838666481, -8.295222869941293],
              [-35.548600766391345, -8.29522288903747],
              [-35.548600694144234, -8.295222908259982],
              [-35.54860062193417, -8.295222927599728],
              [-35.548600549761154, -8.295222947056727],
              [-35.548600477607089, -8.295222966640118],
              [-35.548600405499094, -8.295222986331648],
              [-35.54860033341, -8.295223006140544],
              [-35.548600261367078, -8.295223026075641],
              [-35.548600189352129, -8.295223046128068],
              [-35.548600117365154, -8.295223066297769],
              [-35.548600045415228, -8.295223086584723],
              [-35.548599973502348, -8.295223106988912],
              [-35.548599901617436, -8.2952231275104],
              [-35.548599829769572, -8.295223148149136],
              [-35.548599757958762, -8.295223168905107],
              [-35.548599686185042, -8.295223189787359],
              [-35.548599614439247, -8.295223210777877],
              [-35.548599542730557, -8.295223231894669],
              [-35.54859947105885, -8.295223253119662],
              [-35.548599399415174, -8.295223274471004],
              [-35.548599327808539, -8.29522329593958],
              [-35.548599256247975, -8.295223317516294],
              [-35.548599184715435, -8.295223339219362],
              [-35.548599113219943, -8.295223361039646],
              [-35.548599041761435, -8.295223382968162],
              [-35.548598970330971, -8.295223405022993],
              [-35.548598898946615, -8.295223427195015],
              [-35.548598827599243, -8.295223449475241],
              [-35.548598756279915, -8.295223471881812],
              [-35.548598685006688, -8.295223494405546],
              [-35.548598613770459, -8.295223517037511],
              [-35.548598542571341, -8.295223539795732],
              [-35.548598471409257, -8.295223562671207],
              [-35.548598400284163, -8.295223585654881],
              [-35.548598329196174, -8.295223608764827],
              [-35.548598258145169, -8.295223631982987],
              [-35.548598187131276, -8.295223655327421],
              [-35.548598116163433, -8.295223678780014],
              [-35.548598045232644, -8.295223702349823],
              [-35.548597974338897, -8.295223726036902],
              [-35.548597903482197, -8.295223749841201],
              [-35.548597832671618, -8.295223773762695],
              [-35.548597761889006, -8.295223797801484],
              [-35.548597691161589, -8.295223821957387],
              [-35.548597620462083, -8.295223846221569],
              [-35.548597549808754, -8.295223870611965],
              [-35.548597479192416, -8.295223895110576],
              [-35.548597408622193, -8.295223919726347],
              [-35.548597338089017, -8.295223944459389],
              [-35.548597267592882, -8.295223969309649],
              [-35.548597197142868, -8.2952239942771],
              [-35.548597126738919, -8.295224019352695],
              [-35.54859705637201, -8.295224044545542],
              [-35.548596986042199, -8.295224069864645],
              [-35.548596915758459, -8.295224095291919],
              [-35.548596845511703, -8.295224120827376],
              [-35.548596775320192, -8.29522414648901],
              [-35.548596705156598, -8.2952241722589],
              [-35.548596635048199, -8.29522419814592],
              [-35.548596564976833, -8.295224224150184],
              [-35.548596494951596, -8.29522425027162],
              [-35.548596424963343, -8.295224276501276],
              [-35.548596355021211, -8.295224302848105],
              [-35.548596285125193, -8.295224329312111],
              [-35.548596215266215, -8.295224355893373],
              [-35.548596145462383, -8.295224382582715],
              [-35.548596075695585, -8.295224409389307],
              [-35.548596005974851, -8.295224436304025],
              [-35.548595936300295, -8.295224463344992],
              [-35.548595866671796, -8.295224490494086],
              [-35.548595797080345, -8.295224517760428],
              [-35.548595727544011, -8.295224545134849],
              [-35.548595658044739, -8.295224572626523],
              [-35.548595588591589, -8.295224600235379],
              [-35.548595519193555, -8.295224627952313],
              [-35.548595449832582, -8.295224655786503],
              [-35.54859538051771, -8.295224683737869],
              [-35.548595311257984, -8.295224711797333],
              [-35.548595242035297, -8.295224739974014],
              [-35.548595172867806, -8.295224768267854],
              [-35.548595103737291, -8.295224796669869],
              [-35.548595034661972, -8.295224825189022],
              [-35.548594965632716, -8.295224853816332],
              [-35.548594896640502, -8.295224882560865],
              [-35.548594827712492, -8.295224911413433],
              [-35.54859475882153, -8.295224940383253],
              [-35.548594689976689, -8.295224969470251],
              [-35.548594621186972, -8.295224998665335],
              [-35.548594552443312, -8.295225027968568],
              [-35.548594483745781, -8.295225057388985],
              [-35.54859441510343, -8.295225086926518],
              [-35.548594346507144, -8.295225116572212],
              [-35.548594277956973, -8.295225146335079],
              [-35.548594209461932, -8.29522517620603],
              [-35.548594141012956, -8.295225206185137],
              [-35.548594072610094, -8.295225236281434],
              [-35.548594004262426, -8.295225266494837],
              [-35.54859393596081, -8.295225296816396],
              [-35.548593867714331, -8.295225327246055],
              [-35.548593799513974, -8.295225357792891],
              [-35.54859373136874, -8.295225388447806],
              [-35.548593663269628, -8.295225419219918],
              [-35.548593595225647, -8.295225450100112],
              [-35.548593527227787, -8.295225481097482],
              [-35.548593459284994, -8.295225512193911],
              [-35.548593391397453, -8.295225543416516],
              [-35.548593323555913, -8.295225574738204],
              [-35.548593255760494, -8.295225606177091],
              [-35.54859318802928, -8.295225637724013],
              [-35.548593120344172, -8.295225669388113],
              [-35.54859305271421, -8.295225701160296],
              [-35.548592985130298, -8.295225733040636],
              [-35.548592917610641, -8.295225765038037],
              [-35.548592850137055, -8.295225797143594],
              [-35.548592782718607, -8.295225829357232],
              [-35.548592715346267, -8.295225861688049],
              [-35.548592648038067, -8.295225894117863],
              [-35.548592580775988, -8.295225926664859],
              [-35.548592513569105, -8.29522595932899],
              [-35.548592446426369, -8.295225992092107],
              [-35.548592379329747, -8.295226024972402],
              [-35.548592312279183, -8.295226057960852],
              [-35.548592245292824, -8.295226091057325],
              [-35.548592178361595, -8.295226124261898],
              [-35.548592111485561, -8.295226157583587],
              [-35.548592044664652, -8.295226191013379],
              [-35.54859197789888, -8.295226224551248],
              [-35.548591911188232, -8.295226258197204],
              [-35.548591844532723, -8.29522629195125],
              [-35.548591777932408, -8.295226325822437],
              [-35.548591711387154, -8.29522635979265],
              [-35.548591644906175, -8.295226393879961],
              [-35.548591578471246, -8.29522642807539],
              [-35.548591512100465, -8.295226462369822],
              [-35.548591445784872, -8.295226496781389],
              [-35.548591379524474, -8.29522653131008],
              [-35.54859131331915, -8.295226565937808],
              [-35.54859124716895, -8.295226600673638],
              [-35.548591181082955, -8.295226635517492],
              [-35.548591115052155, -8.295226670478479],
              [-35.548591049085495, -8.29522670553845],
              [-35.5485909831649, -8.295226740706584],
              [-35.54859091731764, -8.295226775991718],
              [-35.548590851516373, -8.295226811375951],
              [-35.548590785779318, -8.295226846868225],
              [-35.548590720097451, -8.295226882477637],
              [-35.548590654479725, -8.295226918186014],
              [-35.548590588917136, -8.295226954002509],
              [-35.54859052341881, -8.295226989936063],
              [-35.54859045797555, -8.295227025968659],
              [-35.548590392587428, -8.295227062109358],
              [-35.548590327263504, -8.295227098358076],
              [-35.548590262003792, -8.295227134714818],
              [-35.548590196799204, -8.295227171179659],
              [-35.54859013165877, -8.295227207743499],
              [-35.54859006657351, -8.295227244424465],
              [-35.548590001561479, -8.295227281204365],
              [-35.548589936595569, -8.295227318101437],
              [-35.548589871702873, -8.295227355097461],
              [-35.548589806865309, -8.29522739220157],
              [-35.548589742082875, -8.295227429413758],
              [-35.548589677373656, -8.295227466724889],
              [-35.548589612719631, -8.295227504153143],
              [-35.548589548129748, -8.295227541680397],
              [-35.548589483604076, -8.295227579315672],
              [-35.548589419133464, -8.295227617050006],
              [-35.548589354736194, -8.295227654901351],
              [-35.548589290393998, -8.295227692851748],
              [-35.548589226116007, -8.295227730910172],
              [-35.548589161902221, -8.295227769076636],
              [-35.548589097752576, -8.29522780734208],
              [-35.548589033658068, -8.295227845715626],
              [-35.548588969636832, -8.295227884197137],
              [-35.548588905679743, -8.29522792277765],
              [-35.548588841777779, -8.295227961466242],
              [-35.548588777949099, -8.295228000262819],
              [-35.548588714175494, -8.295228039158436],
              [-35.54858865047516, -8.295228078162033],
              [-35.548588586839031, -8.295228117273659],
              [-35.548588523267043, -8.295228156484267],
              [-35.548588459750185, -8.29522819580299],
              [-35.548588396306549, -8.295228235220621],
              [-35.548588332927125, -8.295228274746311],
              [-35.548588269620971, -8.295228314379948],
              [-35.548588206369878, -8.295228354112648],
              [-35.548588143192021, -8.295228393944289],
              [-35.548588080069287, -8.295228433884009],
              [-35.548588017019824, -8.295228473931711],
              [-35.548587954043583, -8.295228514078344],
              [-35.54858789112248, -8.295228554333073],
              [-35.548587828274584, -8.295228594686728],
              [-35.548587765490844, -8.295228635139386],
              [-35.548587702771307, -8.295228675700079],
              [-35.548587640125035, -8.295228716368724],
              [-35.548587577542854, -8.295228757127351],
              [-35.548587515034015, -8.295228798002977],
              [-35.548587452580186, -8.295228838968612],
              [-35.548587390208702, -8.295228880042167],
              [-35.548587327892349, -8.295228921223821],
              [-35.548587265658234, -8.295228962495305],
              [-35.548587203479308, -8.295229003883911],
              [-35.548587141373538, -8.295229045362419],
              [-35.54858707934104, -8.295229086948911],
              [-35.548587017372689, -8.295229128634384],
              [-35.548586955477568, -8.295229170418807],
              [-35.548586893646636, -8.295229212311249],
              [-35.548586831888926, -8.295229254302635],
              [-35.548586770195364, -8.295229296393005],
              [-35.548586708575073, -8.295229338591357],
              [-35.548586647027946, -8.295229380879613],
              [-35.548586585545024, -8.295229423275895],
              [-35.548586524135374, -8.295229465780139],
              [-35.548586462789814, -8.295229508374348],
              [-35.548586401526542, -8.295229551067441],
              [-35.548586340327468, -8.295229593868561],
              [-35.548586279201615, -8.295229636768623],
              [-35.548586218139917, -8.295229679767663],
              [-35.5485861571605, -8.295229722865596],
              [-35.548586096245288, -8.295229766071564],
              [-35.548586035403233, -8.295229809367424],
              [-35.548585974634456, -8.295229852771246],
              [-35.548585913929763, -8.295229896265052],
              [-35.548585853307429, -8.295229939866747],
              [-35.548585792749229, -8.295229983567458],
              [-35.54858573227326, -8.295230027357999],
              [-35.548585671861503, -8.295230071256563],
              [-35.548585611522959, -8.295230115254073],
              [-35.548585551257631, -8.295230159350526],
              [-35.548585491074597, -8.295230203545845],
              [-35.548585430955704, -8.295230247840168],
              [-35.548585370909969, -8.295230292224376],
              [-35.548585310937511, -8.295230336716571],
              [-35.548585251047349, -8.295230381307647],
              [-35.548585191221271, -8.295230425988668],
              [-35.548585131468471, -8.295230470777657],
              [-35.548585071797895, -8.295230515656511],
              [-35.548585012200597, -8.295230560643326],
              [-35.54858495266739, -8.295230605720091],
              [-35.548584893216471, -8.295230650895743],
              [-35.548584833847841, -8.295230696170275],
              [-35.548584774543357, -8.295230741543795],
              [-35.548584715321105, -8.295230787007162],
              [-35.548584656163058, -8.295230832578566],
              [-35.548584597087235, -8.295230878239801],
              [-35.548584538093714, -8.295230923999904],
              [-35.548584479164269, -8.295230969849989],
              [-35.548584420317169, -8.295231015807978],
              [-35.548584361552315, -8.295231061855803],
              [-35.548584302851594, -8.295231108002641],
              [-35.548584244233169, -8.295231154248333],
              [-35.548584185696974, -8.295231200583885],
              [-35.54858412722492, -8.295231247018426],
              [-35.548584068835162, -8.295231293551833],
              [-35.548584010527634, -8.295231340175105],
              [-35.54858395229332, -8.295231386897303],
              [-35.548583894132229, -8.295231433718429],
              [-35.548583836053368, -8.295231480629399],
              [-35.548583778056802, -8.295231527639258],
              [-35.548583720133387, -8.295231574739015],
              [-35.548583662283193, -8.295231621937702],
              [-35.548583604515287, -8.295231669235262],
              [-35.548583546829612, -8.295231716622682],
              [-35.548583489217094, -8.295231764099992],
              [-35.548583431686929, -8.295231811685209],
              [-35.54858337423893, -8.295231859351247],
              [-35.548583316864153, -8.2952319071162],
              [-35.548583259562598, -8.295231954980107],
              [-35.54858320235234, -8.295232002933792],
              [-35.548583145215304, -8.295232050986419],
              [-35.548583088160498, -8.295232099128889],
              [-35.548583031178843, -8.295232147361252],
              [-35.548582974279491, -8.295232195692497],
              [-35.548582917462355, -8.295232244113572],
              [-35.548582860727464, -8.295232292624496],
              [-35.548582804074861, -8.295232341234307],
              [-35.548582747495466, -8.295232389943038],
              [-35.548582690998259, -8.295232438732601],
              [-35.548582634583326, -8.295232487621014],
              [-35.548582578250638, -8.295232536599288],
              [-35.548582522000238, -8.29523258567643],
              [-35.548582465832006, -8.295232634834383],
              [-35.548582409736987, -8.295232684091276],
              [-35.548582353733337, -8.295232733446985],
              [-35.548582297802781, -8.295232782883557],
              [-35.548582241963601, -8.295232832418939],
              [-35.548582186197564, -8.295232882044226],
              [-35.548582130522838, -8.295232931759303],
              [-35.548582074921271, -8.295232981564284],
              [-35.548582019411008, -8.295233031459039],
              [-35.548581963973966, -8.295233081452738],
              [-35.548581908628165, -8.295233131527185],
              [-35.548581853355586, -8.295233181700558],
              [-35.548581798174304, -8.295233231963719],
              [-35.548581743075253, -8.295233282316726],
              [-35.548581688058434, -8.295233332759567],
              [-35.548581633123852, -8.29523338329227],
              [-35.548581578271495, -8.295233433914786],
              [-35.54858152351045, -8.295233484627108],
              [-35.548581468831621, -8.295233535429258],
              [-35.548581414225964, -8.295233586321313],
              [-35.548581359711605, -8.295233637303159],
              [-35.548581305288558, -8.295233688374791],
              [-35.548581250938661, -8.295233739536314],
              [-35.548581196680068, -8.295233790787625],
              [-35.548581142512781, -8.295233842128706],
              [-35.548581088418601, -8.295233893550655],
              [-35.548581034415776, -8.295233945071431],
              [-35.548580980495124, -8.295233996673016],
              [-35.548580926656761, -8.295234048373485],
              [-35.548580872909646, -8.295234100154689],
              [-35.548580819253829, -8.295234152025678],
              [-35.548580765671119, -8.295234203977538],
              [-35.548580712179771, -8.295234256028206],
              [-35.54858065877967, -8.295234308159628],
              [-35.548580605461794, -8.295234360380892],
              [-35.548580552235222, -8.295234412691945],
              [-35.548580499081758, -8.295234465083848],
              [-35.548580446028723, -8.295234517574521],
              [-35.548580393057797, -8.295234570136966],
              [-35.548580340169167, -8.295234622798294],
              [-35.548580287380858, -8.295234675540296],
              [-35.548580234665707, -8.295234728372202],
              [-35.548580182041853, -8.295234781293901],
              [-35.548580129509247, -8.29523483429635],
              [-35.548580077067946, -8.295234887388572],
              [-35.548580024708819, -8.295234940561594],
              [-35.548579972440997, -8.29523499382441],
              [-35.548579920255335, -8.295235047168035],
              [-35.548579868160992, -8.295235100601451],
              [-35.548579816157947, -8.295235154124631],
              [-35.548579764237076, -8.295235207728625],
              [-35.548579712407452, -8.295235261413371],
              [-35.548579660669127, -8.295235315187885],
              [-35.548579609022106, -8.295235369052207],
              [-35.548579557466269, -8.295235422988222],
              [-35.548579505992727, -8.295235477023123],
              [-35.548579454610369, -8.29523553112972],
              [-35.54857940331938, -8.295235585335158],
              [-35.548579352110501, -8.295235639612354],
              [-35.548579301001993, -8.295235693979274],
              [-35.548579249975667, -8.295235748427009],
              [-35.548579199049655, -8.295235802955432],
              [-35.548579148205874, -8.295235857573685],
              [-35.548579097453334, -8.295235912272691],
              [-35.548579046792099, -8.295235967061483],
              [-35.548578996222048, -8.295236021921985],
              [-35.548578945743309, -8.29523607687228],
              [-35.54857889535581, -8.295236131903321],
              [-35.548578845059616, -8.295236187024141],
              [-35.548578794854606, -8.295236242216664],
              [-35.548578744740901, -8.295236297498983],
              [-35.548578694718451, -8.295236352862046],
              [-35.548578644787234, -8.295236408305845],
              [-35.548578594947266, -8.295236463830411],
              [-35.548578545198538, -8.295236519435711],
              [-35.548578495541065, -8.295236575121763],
              [-35.54857844597489, -8.295236630897602],
              [-35.548578396508972, -8.295236686745076],
              [-35.548578347125286, -8.295236742682418],
              [-35.548578297841864, -8.29523679869139],
              [-35.548578248649747, -8.295236854790156],
              [-35.548578199548807, -8.295236910960648],
              [-35.548578150539171, -8.295236967220898],
              [-35.548578101629808, -8.295237023552813],
              [-35.548578052811735, -8.295237079974523],
              [-35.548578004084852, -8.295237136467925],
              [-35.548577955449218, -8.295237193042079],
              [-35.548577955449218, -8.295237193042079],
              [-35.54776696210044, -8.296182215782343],
              [-35.547706680582728, -8.296190101839807],
              [-35.546107262993111, -8.295088342216337],
              [-35.546123044618923, -8.295003114255465],
              [-35.546449985483349, -8.294138819440194],
              [-35.545817035696182, -8.293989779245051],
              [-35.545154919205153, -8.293833875424186],
              [-35.544960997121066, -8.293615789343869],
              [-35.544365363287127, -8.293908049179407],
              [-35.543659483265543, -8.294184022791731],
              [-35.54303102999085, -8.293789055921364],
              [-35.542756292717655, -8.293616397340948],
              [-35.542263661294577, -8.293306864301256],
              [-35.541581004386074, -8.293060444887841],
              [-35.540112979960099, -8.292382186774608],
              [-35.539630735523104, -8.291372675746663],
              [-35.53905747065064, -8.292987516028328],
              [-35.539465297190674, -8.293385704002846],
              [-35.537333389807891, -8.295937569528595],
              [-35.537333389807891, -8.295937569528595],
              [-35.537333343243517, -8.295937625438038],
              [-35.537333296770278, -8.295937681410152],
              [-35.537333250388279, -8.295937737463007],
              [-35.537333204088405, -8.295937793587649],
              [-35.537333157888781, -8.295937849783932],
              [-35.537333111780399, -8.295937906060967],
              [-35.537333065754133, -8.295937962409795],
              [-35.537333019819052, -8.295938018830318],
              [-35.537332973984235, -8.295938075322494],
              [-35.537332928231521, -8.29593813188645],
              [-35.537332882579129, -8.295938188531096],
              [-35.537332837008847, -8.29593824524752],
              [-35.53733279153883, -8.2959383020356],
              [-35.537332746159933, -8.295938358886351],
              [-35.53733270086321, -8.295938415817917],
              [-35.537332655666816, -8.295938472830176],
              [-35.537332610561535, -8.295938529905115],
              [-35.537332565547445, -8.295938587051751],
              [-35.537332520624538, -8.295938644270116],
              [-35.537332475792816, -8.295938701560196],
              [-35.537332431052349, -8.295938758931015],
              [-35.537332386412068, -8.295938816364465],
              [-35.537332341862978, -8.295938873869636],
              [-35.537332297405072, -8.295938931446504],
              [-35.53733225303835, -8.295938989095101],
              [-35.537332208762827, -8.295939046815393],
              [-35.53733216457848, -8.29593910460742],
              [-35.537332120494391, -8.295939162471086],
              [-35.537332076501485, -8.295939220406483],
              [-35.537332032599714, -8.295939278404543],
              [-35.537331988798257, -8.295939336483311],
              [-35.537331945087928, -8.295939394624741],
              [-35.537331901468782, -8.295939452837883],
              [-35.537331857940828, -8.295939511122761],
              [-35.537331814513067, -8.295939569470235],
              [-35.537331771176497, -8.295939627889446],
              [-35.537331727931104, -8.295939686380351],
              [-35.537331684785975, -8.295939744942935],
              [-35.537331641732038, -8.295939803577207],
              [-35.537331598778295, -8.295939862274107],
              [-35.537331555915678, -8.295939921033694],
              [-35.537331513144309, -8.295939979874023],
              [-35.537331470473141, -8.29594003877698],
              [-35.53733142789315, -8.295940097751638],
              [-35.537331385404293, -8.295940156788992],
              [-35.537331343024775, -8.295940215897927],
              [-35.537331300727303, -8.295940275069611],
              [-35.537331258530088, -8.29594033431294],
              [-35.537331216433131, -8.295940393627935],
              [-35.537331174427308, -8.29594045300561],
              [-35.537331132512612, -8.295940512445943],
              [-35.537331090698167, -8.295940571957951],
              [-35.537331048983987, -8.295940631541614],
              [-35.537331007360933, -8.295940691187941],
              [-35.537330965838073, -8.295940750896902],
              [-35.537330924406405, -8.295940810677573],
              [-35.537330883074937, -8.295940870520866],
              [-35.537330841843726, -8.295940930435815],
              [-35.53733080070365, -8.295940990413438],
              [-35.53733075966376, -8.295941050453681],
              [-35.537330718715069, -8.295941110565641],
              [-35.537330677866507, -8.295941170731178],
              [-35.537330637118274, -8.295941230977412],
              [-35.537330596461103, -8.295941291277302],
              [-35.537330555904191, -8.295941351648827],
              [-35.537330515447486, -8.295941412082989],
              [-35.537330475090968, -8.295941472579752],
              [-35.537330434825648, -8.295941533148232],
              [-35.537330394660465, -8.29594159377031],
              [-35.537330354586473, -8.295941654464098],
              [-35.537330314621748, -8.295941715220453],
              [-35.53733027474815, -8.295941776039459],
              [-35.537330234974753, -8.295941836921104],
              [-35.537330195301614, -8.295941897874407],
              [-35.537330155719545, -8.295941958881349],
              [-35.537330116246814, -8.295942019959902],
              [-35.537330076865139, -8.295942081092079],
              [-35.537330037583729, -8.29594214229591],
              [-35.537329998402463, -8.29594220355334],
              [-35.53732995931238, -8.295942264882468],
              [-35.537329920331572, -8.295942326274172],
              [-35.537329881450901, -8.295942387719457],
              [-35.537329842661421, -8.295942449236442],
              [-35.537329803972078, -8.295942510807018],
              [-35.537329765382999, -8.295942572449253],
              [-35.537329726894058, -8.295942634145083],
              [-35.537329688505324, -8.29594269590352],
              [-35.537329650216783, -8.295942757724573],
              [-35.537329612028437, -8.295942819608259],
              [-35.537329573940305, -8.295942881554545],
              [-35.53732953595231, -8.295942943554431],
              [-35.537329498064508, -8.295943005616934],
              [-35.537329460276908, -8.295943067742055],
              [-35.537329422589515, -8.295943129929796],
              [-35.537329385002316, -8.295943192180152],
              [-35.537329347515261, -8.295943254484108],
              [-35.537329310128406, -8.295943316850662],
              [-35.537329272841752, -8.295943379279855],
              [-35.537329235655235, -8.295943441762628],
              [-35.537329198568926, -8.295943504308013],
              [-35.537329161582761, -8.295943566906981],
              [-35.53732912470592, -8.29594362957755],
              [-35.537329087920085, -8.295943692292735],
              [-35.537329051243589, -8.295943755079502],
              [-35.537329014667236, -8.295943817919863],
              [-35.537328978181947, -8.295943880813862],
              [-35.537328941805931, -8.295943943770421],
              [-35.537328905539191, -8.29594400678954],
              [-35.53732886936352, -8.295944069862298],
              [-35.53732883329706, -8.295944132988597],
              [-35.537328797321734, -8.295944196177553],
              [-35.537328761455676, -8.295944259429085],
              [-35.537328725689704, -8.295944322725159],
              [-35.537328690033057, -8.295944386092833],
              [-35.53732865446743, -8.295944449505107],
              [-35.53732861901107, -8.295944512979954],
              [-35.537328583654855, -8.295944576508386],
              [-35.537328548407906, -8.295944640099371],
              [-35.537328513252035, -8.295944703744],
              [-35.537328478205374, -8.295944767442148],
              [-35.537328443258914, -8.295944831202918],
              [-35.537328408421615, -8.295944895008184],
              [-35.537328373684502, -8.295944958876067],
              [-35.537328339047548, -8.295945022797532],
              [-35.53732830451078, -8.295945086781614],
              [-35.537328270083179, -8.2959451508102],
              [-35.537328235755773, -8.295945214901383],
              [-35.537328201537584, -8.295945279046109],
              [-35.537328167419538, -8.295945343244412],
              [-35.53732813340163, -8.295945407496294],
              [-35.537328099492932, -8.295945471801714],
              [-35.537328065684441, -8.295945536169738],
              [-35.537328031985105, -8.295945600582259],
              [-35.537327998385912, -8.295945665048379],
              [-35.537327964886913, -8.295945729577101],
              [-35.537327931497074, -8.295945794150322],
              [-35.53732789820738, -8.295945858777102],
              [-35.53732786502696, -8.295945923466466],
              [-35.537327831946619, -8.295945988200383],
              [-35.537327798975497, -8.295946052987809],
              [-35.537327766104511, -8.295946117828827],
              [-35.537327733342742, -8.295946182723366],
              [-35.537327700681111, -8.295946247671491],
              [-35.53732766811963, -8.295946312673207],
              [-35.537327635676377, -8.295946377719334],
              [-35.537327603324186, -8.295946442819114],
              [-35.537327571090287, -8.295946507972376],
              [-35.537327538956532, -8.295946573179203],
              [-35.537327506922921, -8.29594663843962],
              [-35.537327474998456, -8.295946703744528],
              [-35.537327443183266, -8.295946769111993],
              [-35.537327411468105, -8.295946834514975],
              [-35.537327379862219, -8.295946899980501],
              [-35.537327348356413, -8.295946965490586],
              [-35.537327316959825, -8.295947031054213],
              [-35.53732728567239, -8.295947096662299],
              [-35.537327254485092, -8.295947162323989],
              [-35.537327223407019, -8.295947228039196],
              [-35.537327192438092, -8.295947293798905],
              [-35.537327161569316, -8.295947359612194],
              [-35.537327130809693, -8.295947425469965],
              [-35.537327100150208, -8.295947491381314],
              [-35.537327069608956, -8.295947557337108],
              [-35.537327039167849, -8.295947623346464],
              [-35.537327008826821, -8.295947689400396],
              [-35.537326978604085, -8.295947755507774],
              [-35.537326948481429, -8.295947821659709],
              [-35.537326918467983, -8.295947887865163],
              [-35.537326888563697, -8.295947954115103],
              [-35.537326858759492, -8.295948020409602],
              [-35.537326829064448, -8.295948086748586],
              [-35.537326799478613, -8.295948153141088],
              [-35.537326770001997, -8.29594821958713],
              [-35.53732674062541, -8.295948286068676],
              [-35.537326711358034, -8.295948352603741],
              [-35.537326682208885, -8.295948419183246],
              [-35.53732665315075, -8.295948485807356],
              [-35.537326624210898, -8.295948552484925],
              [-35.537326595380151, -8.295948619197942],
              [-35.53732656664954, -8.295948685964554],
              [-35.537326538028083, -8.295948752775667],
              [-35.537326509515786, -8.295948819631246],
              [-35.53732648111265, -8.295948886531324],
              [-35.537326452818718, -8.295948953484922],
              [-35.537326424633889, -8.295949020473985],
              [-35.537326396558278, -8.295949087516567],
              [-35.53732636858269, -8.295949154594657],
              [-35.537326340716319, -8.29594922172628],
              [-35.537326312968119, -8.29594928889329],
              [-35.537326285320056, -8.29594935611388],
              [-35.537326257781089, -8.295949423369935],
              [-35.537326230351347, -8.295949490679504],
              [-35.537326203030695, -8.295949558024525],
              [-35.53732617581926, -8.295949625423082],
              [-35.537326148716922, -8.295949692857084],
              [-35.537326121723737, -8.295949760335587],
              [-35.537326094839706, -8.295949827858569],
              [-35.537326068064779, -8.295949895416999],
              [-35.537326041399069, -8.295949963028967],
              [-35.537326014833383, -8.295950030676437],
              [-35.537325988385923, -8.295950098368333],
              [-35.537325962047625, -8.295950166104729],
              [-35.537325935818473, -8.295950233885607],
              [-35.537325909698431, -8.295950301701948],
              [-35.537325883687537, -8.295950369562771],
              [-35.53732585778581, -8.295950437468079],
              [-35.537325831993165, -8.295950505408848],
              [-35.537325806309688, -8.295950573394098],
              [-35.537325780735358, -8.295950641423845],
              [-35.537325755270139, -8.295950709489041],
              [-35.537325729923083, -8.295950777589626],
              [-35.537325704676171, -8.295950845743802],
              [-35.537325679547429, -8.295950913933366],
              [-35.537325654518703, -8.295950982158455],
              [-35.537325629608219, -8.295951050427965],
              [-35.537325604806824, -8.295951118732935],
              [-35.537325580114597, -8.295951187082387],
              [-35.53732555553146, -8.295951255467287],
              [-35.537325531057483, -8.295951323896686],
              [-35.537325506692596, -8.295951392361527],
              [-35.537325482445944, -8.295951460870816],
              [-35.537325458308338, -8.295951529406507],
              [-35.537325434270862, -8.295951597995776],
              [-35.537325410351507, -8.295951666611415],
              [-35.537325386550371, -8.295951735271492],
              [-35.537325362849273, -8.295951803967055],
              [-35.537325339266339, -8.295951872698039],
              [-35.537325315783491, -8.295951941473547],
              [-35.537325292418807, -8.295952010284477],
              [-35.5373252691723, -8.295952079130773],
              [-35.537325246025823, -8.295952148012594],
              [-35.537325222997509, -8.295952216929814],
              [-35.537325200078357, -8.295952285891518],
              [-35.537325177268293, -8.295952354888684],
              [-35.537325154567277, -8.295952423912256],
              [-35.537325131984495, -8.29595249298025],
              [-35.537325109510803, -8.295952562083707],
              [-35.537325087146208, -8.29595263122261],
              [-35.537325064890716, -8.295952700396978],
              [-35.537325042753388, -8.295952769606746],
              [-35.53732502072517, -8.295952838851955],
              [-35.537324998806042, -8.295952908132612],
              [-35.537324977005085, -8.295952977448673],
              [-35.537324955313224, -8.295953046800179],
              [-35.53732493373046, -8.295953116187142],
              [-35.537324912265873, -8.295953185609497],
              [-35.537324890901253, -8.295953255058329],
              [-35.537324869663877, -8.295953324542491],
              [-35.537324848526588, -8.29595339407121],
              [-35.537324827507398, -8.295953463626294],
              [-35.537324806597312, -8.29595353321681],
              [-35.537324785805346, -8.2959536028337],
              [-35.537324765122527, -8.295953672495077],
              [-35.537324744548755, -8.295953742182864],
              [-35.537324724093153, -8.295953811906049],
              [-35.537324703746584, -8.295953881655638],
              [-35.537324683509119, -8.295953951440694],
              [-35.537324663389825, -8.295954021261153],
              [-35.537324643379627, -8.295954091117055],
              [-35.537324623487535, -8.295954160999306],
              [-35.537324603704491, -8.295954230907995],
              [-35.537324584030607, -8.295954300861149],
              [-35.537324564474773, -8.295954370831634],
              [-35.537324545037166, -8.295954440846556],
              [-35.537324525699532, -8.295954510887945],
              [-35.537324506489078, -8.295954580955639],
              [-35.537324487378655, -8.295954651058839],
              [-35.537324468386338, -8.2959547211884],
              [-35.537324449512198, -8.295954791353363],
              [-35.537324430747091, -8.295954861544734],
              [-35.537324412091024, -8.295954931762534],
              [-35.537324393553135, -8.295955002015711],
              [-35.537324375133409, -8.295955072304279],
              [-35.537324356813599, -8.295955142610305],
              [-35.537324338621033, -8.295955212951659],
              [-35.5373243205375, -8.295955283319422],
              [-35.537324302563071, -8.295955353722643],
              [-35.537324284706692, -8.295955424143191],
              [-35.537324266959409, -8.295955494599186],
              [-35.537324249330247, -8.29595556508154],
              [-35.537324231819248, -8.295955635599286],
              [-35.537324214408166, -8.295955706134475],
              [-35.53732419712432, -8.295955776704991],
              [-35.537324179949522, -8.295955847301943],
              [-35.537324162883756, -8.295955917925291],
              [-35.537324145945178, -8.295955988574956],
              [-35.537324129106572, -8.295956059251088],
              [-35.537324112386074, -8.295956129953566],
              [-35.537324095783681, -8.295956200682411],
              [-35.537324079290336, -8.295956271437682],
              [-35.537324062915161, -8.295956342228333],
              [-35.537324046658043, -8.295956413036313],
              [-35.537324030509964, -8.295956483870714],
              [-35.537324014470926, -8.295956554731525],
              [-35.537323998550001, -8.295956625618695],
              [-35.537323982747182, -8.295956696532235],
              [-35.537323967053354, -8.29595676746314],
              [-35.537323951477696, -8.295956838429449],
              [-35.537323936020087, -8.295956909413068],
              [-35.537323920671575, -8.295956980432148],
              [-35.53732390544112, -8.295957051468553],
              [-35.537323890319648, -8.295957122522328],
              [-35.537323875316346, -8.295957193611503],
              [-35.537323860431094, -8.295957264717993],
              [-35.537323845654889, -8.295957335850916],
              [-35.537323830996797, -8.295957407010189],
              [-35.537323816456755, -8.295957478186772],
              [-35.53732380202576, -8.295957549389778],
              [-35.537323787712808, -8.29595762061011],
              [-35.537323773518033, -8.295957691865846],
              [-35.537323759432184, -8.295957763129909],
              [-35.537323745464505, -8.295957834429377],
              [-35.537323731605802, -8.29595790574621],
              [-35.537323717865156, -8.295957977080388],
              [-35.537323704242624, -8.295958048440896],
              [-35.537323690738148, -8.295958119818751],
              [-35.537323677342712, -8.295958191223006],
              [-35.537323664065333, -8.295958262644588],
              [-35.537323650896987, -8.2959583340926],
              [-35.537323637855771, -8.295958405557853],
              [-35.537323624923602, -8.295958477049535],
              [-35.537323612100401, -8.2959585485586],
              [-35.537323599404338, -8.295958620084917],
              [-35.537323586817251, -8.295958691628616],
              [-35.537323574339212, -8.29595876319874],
              [-35.537323561988288, -8.295958834786132],
              [-35.537323549746411, -8.29595890639993],
              [-35.537323537622534, -8.295958978022016],
              [-35.537323525616763, -8.295959049670463],
              [-35.537323513719976, -8.295959121336281],
              [-35.537323501941252, -8.295959193019426],
              [-35.537323490280627, -8.295959264728936],
              [-35.537323478738003, -8.295959336446712],
              [-35.537323467304425, -8.295959408190932],
              [-35.537323455988897, -8.295959479952462],
              [-35.537323444791362, -8.295959551722255],
              [-35.537323433711947, -8.295959623518435],
              [-35.537323422741508, -8.295959695331984],
              [-35.537323411898193, -8.295959767162797],
              [-35.537323401154794, -8.295959839011042],
              [-35.537323390538525, -8.295959910876567],
              [-35.537323380040306, -8.295959982759401],
              [-35.537323369651006, -8.295960054650584],
              [-35.537323359379826, -8.295960126568128],
              [-35.537323349226639, -8.295960198493944],
              [-35.5373233391825, -8.295960270446185],
              [-35.537323329256338, -8.29596034240671],
              [-35.537323319457322, -8.295960414384506],
              [-35.537323309767281, -8.295960486379666],
              [-35.537323300186223, -8.295960558392228],
              [-35.537323290732239, -8.295960630413006],
              [-35.53732328138728, -8.295960702460187],
              [-35.537323272160272, -8.295960774506634],
              [-35.53732326305137, -8.29596084657943],
              [-35.537323254060475, -8.295960918660528],
              [-35.537323245178548, -8.29596099075898],
              [-35.537323236423759, -8.295961062874715],
              [-35.53732322777789, -8.295961134998798],
              [-35.537323219250077, -8.295961207140186],
              [-35.537323210840313, -8.295961279298901],
              [-35.537323202548549, -8.295961351465907],
              [-35.537323194365712, -8.295961423641257],
              [-35.537323186300924, -8.295961495833915],
              [-35.537323178354193, -8.2959615680439],
              [-35.537323170525461, -8.295961640262172],
              [-35.537323162814722, -8.295961712488733],
              [-35.53732315522204, -8.295961784732619],
              [-35.537323147738341, -8.295961856993872],
              [-35.537323140381645, -8.295961929254331],
              [-35.537323133133995, -8.295962001541199],
              [-35.537323126004289, -8.295962073827315],
              [-35.537323118992624, -8.295962146130755],
              [-35.537323112089894, -8.295962218442545],
              [-35.537323105314286, -8.295962290771582],
              [-35.537323098647548, -8.29596236309993],
              [-35.537323092107933, -8.295962435445558],
              [-35.537323085677244, -8.295962507799517],
              [-35.537323079364604, -8.295962580170785],
              [-35.537323073169908, -8.295962652541323],
              [-35.537323067093268, -8.295962724929185],
              [-35.537323061125548, -8.295962797325373],
              [-35.537323055284901, -8.295962869729793],
              [-35.53732304955318, -8.295962942142557],
              [-35.537323043939452, -8.295963014563625],
              [-35.537323038443773, -8.295963087001986],
              [-35.537323033066045, -8.295963159439614],
              [-35.537323027806302, -8.295963231885528],
              [-35.537323022664616, -8.295963304348751],
              [-35.537323017640865, -8.295963376811226],
              [-35.537323012726105, -8.295963449291083],
              [-35.537323007929281, -8.295963521770206],
              [-35.537323003259516, -8.29596359425754],
              [-35.537322998698684, -8.295963666753201],
              [-35.537322994255852, -8.295963739257175],
              [-35.537322989931006, -8.295963811769429],
              [-35.537322985724167, -8.295963884289954],
              [-35.53732298162619, -8.295963956809805],
              [-35.537322977655286, -8.295964029337869],
              [-35.537322973802368, -8.295964101874221],
              [-35.537322970058383, -8.295964174418913],
              [-35.537322966432392, -8.295964246971897],
              [-35.537322962924335, -8.295964319524129],
              [-35.537322959543346, -8.295964392084592],
              [-35.537322956271289, -8.295964464653402],
              [-35.537322953117162, -8.295964537221439],
              [-35.537322950071967, -8.29596460979784],
              [-35.537322947153783, -8.295964682373416],
              [-35.537322944353591, -8.295964754957295],
              [-35.537322941662325, -8.295964827549508],
              [-35.537322939098068, -8.295964900140902],
              [-35.537322936642738, -8.29596497274065],
              [-35.537322934305344, -8.295965045339663],
              [-35.537322932085949, -8.295965117946942],
              [-35.537322929993557, -8.295965190553416],
              [-35.537322928010099, -8.295965263168235],
              [-35.537322926135502, -8.295965335782356],
              [-35.537322924387922, -8.295965408395658],
              [-35.537322922758328, -8.295965481017259],
              [-35.537322921246677, -8.29596555363811],
              [-35.537322919843952, -8.295965626267311],
              [-35.537322918568236, -8.295965698895683],
              [-35.537322917401383, -8.295965771523377],
              [-35.537322916352537, -8.295965844159342],
              [-35.537322915430629, -8.295965916785477],
              [-35.537322914617654, -8.29596598941996],
              [-35.537322913922623, -8.295966062053671],
              [-35.537322913345584, -8.295966134695687],
              [-35.537322912886417, -8.295966207327902],
              [-35.537322912536183, -8.295966279968454],
              [-35.537322912312959, -8.295966352608216],
              [-35.537322912207671, -8.29596642524721],
              [-35.537322912211252, -8.295966497885532],
              [-35.537322912341835, -8.295966570523026],
              [-35.537322912581295, -8.295966643159828],
              [-35.537322912947758, -8.295966715795833],
              [-35.53732291342309, -8.295966788431128],
              [-35.537322914016421, -8.295966861074717],
              [-35.537322914727625, -8.295966933708533],
              [-35.537322915556778, -8.295967006341574],
              [-35.537322916503868, -8.295967078973884],
              [-35.537322917568886, -8.295967151605426],
              [-35.537322918751791, -8.295967224227194],
              [-35.537322920043628, -8.295967296857288],
              [-35.537322921462462, -8.295967369486581],
              [-35.537322922990107, -8.295967442106154],
              [-35.537322924644769, -8.295967514724904],
              [-35.537322926408294, -8.295967587342972],
              [-35.537322928289761, -8.295967659960278],
              [-35.537322930298174, -8.29596773256775],
              [-35.537322932415456, -8.295967805174529],
              [-35.537322934650682, -8.295967877780539],
              [-35.537322937003836, -8.295967950385819],
              [-35.537322939465803, -8.295968022981365],
              [-35.537322942054722, -8.29596809556706],
              [-35.537322944761634, -8.295968168161027],
              [-35.537322947577366, -8.29596824074528],
              [-35.537322950520043, -8.295968313319687],
              [-35.537322953571646, -8.295968385902441],
              [-35.537322956750138, -8.2959684584663],
              [-35.537322960037507, -8.295968531029489],
              [-35.537322963442804, -8.295968603591911],
              [-35.537322966965988, -8.29596867614454],
              [-35.537322970607107, -8.295968748696421],
              [-35.537322974366106, -8.295968821238525],
              [-35.537322978242983, -8.295968893770826],
              [-35.53732298222873, -8.295968966302448],
              [-35.537322986341429, -8.295969038824227],
              [-35.537322990562991, -8.29596911134529],
              [-35.537322994911456, -8.295969183847482],
              [-35.537322999368783, -8.295969256348986],
              [-35.537323003944046, -8.295969328849736],
              [-35.537323008637195, -8.295969401340702],
              [-35.537323013448159, -8.295969473812834],
              [-35.537323018377123, -8.295969546293268],
              [-35.537323023423909, -8.295969618754864],
              [-35.5373230285795, -8.295969691206739],
              [-35.537323033862108, -8.295969763657789],
              [-35.537323039253522, -8.295969836099127],
              [-35.537323044771888, -8.295969908530614],
              [-35.537323050399067, -8.295969980952371],
              [-35.537323056144125, -8.295970053364337],
              [-35.537323062007054, -8.295970125766514],
              [-35.53732306798787, -8.295970198158919],
              [-35.537323074077491, -8.295970270541574],
              [-35.537323080294122, -8.295970342923438],
              [-35.537323086628589, -8.295970415286453],
              [-35.537323093071848, -8.295970487639757],
              [-35.537323099632999, -8.295970559983269],
              [-35.537323106312023, -8.295970632316994],
              [-35.537323113108926, -8.295970704640943],
              [-35.53732312002365, -8.295970776946048],
              [-35.537323127056325, -8.29597084925042],
              [-35.537323134197742, -8.295970921536],
              [-35.537323141466111, -8.295970993811752],
              [-35.537323148843292, -8.295971066077772],
              [-35.537323156338346, -8.295971138334004],
              [-35.537323163951228, -8.295971210571421],
              [-35.537323171682047, -8.295971282808072],
              [-35.537323179530688, -8.295971355025912],
              [-35.537323187497158, -8.295971427224922],
              [-35.537323195572426, -8.2959714994142],
              [-35.537323203765581, -8.295971571593689],
              [-35.537323212085688, -8.295971643763348],
              [-35.537323220505471, -8.295971715914277],
              [-35.537323229052149, -8.295971788046334],
              [-35.537323237716762, -8.295971860177643],
              [-35.537323246490068, -8.295971932281141],
              [-35.53732325539039, -8.295972004383831],
              [-35.537323264399461, -8.295972076467764],
              [-35.537323273526354, -8.295972148532872],
              [-35.537323282771126, -8.295972220588174],
              [-35.537323292124654, -8.295972292624736],
              [-35.537323301605063, -8.295972364642397],
              [-35.537323311194292, -8.295972436650327],
              [-35.537323320901343, -8.295972508639442],
              [-35.537323330726267, -8.29597258061877],
              [-35.537323340669019, -8.295972652579268],
              [-35.53732335072052, -8.295972724521013],
              [-35.537323360898974, -8.295972796452892],
              [-35.537323371186176, -8.295972868366022],
              [-35.537323381591207, -8.295972940260317],
              [-35.537323392114054, -8.295973012135805],
              [-35.537323402745713, -8.29597308400154],
              [-35.537323413495187, -8.295973155848467],
              [-35.537323424362491, -8.295973227676567],
              [-35.537323435347616, -8.295973299485834],
              [-35.537323446450564, -8.295973371276274],
              [-35.537323457662261, -8.295973443047961],
              [-35.53732346900091, -8.295973514809798],
              [-35.537323480448251, -8.29597358654383],
              [-35.537323492004404, -8.295973658268146],
              [-35.537323503687389, -8.295973729964516],
              [-35.537323515479187, -8.295973801651174],
              [-35.537323527388743, -8.295973873309983],
              [-35.537323539416121, -8.295973944949942],
              [-35.537323551561386, -8.295974016580134],
              [-35.537323563815342, -8.295974088182515],
              [-35.537323576187113, -8.295974159766066],
              [-35.537323588676713, -8.295974231330803],
              [-35.537323601284136, -8.295974302876715],
              [-35.537323614000307, -8.295974374403857],
              [-35.537323626834308, -8.295974445912167],
              [-35.537323639786067, -8.295974517392628],
              [-35.537323652846574, -8.295974588854317],
              [-35.537323666024903, -8.29597466029718],
              [-35.537323679321055, -8.295974731721232],
              [-35.537323692734972, -8.295974803117415],
              [-35.537323706257695, -8.295974874503864],
              [-35.537323719898133, -8.295974945853416],
              [-35.537323733656443, -8.295975017193186],
              [-35.537323747532525, -8.29597508850509],
              [-35.537323761517293, -8.295975159789204],
              [-35.537323775619939, -8.29597523106351],
              [-35.537323789831291, -8.295975302310024],
              [-35.537323804160394, -8.295975373528671],
              [-35.537323818607319, -8.295975444728505],
              [-35.53732383317201, -8.295975515900475],
              [-35.537323847845457, -8.29597558705367],
              [-35.537323862636725, -8.295975658188059],
              [-35.537323877545703, -8.295975729285539],
              [-35.537323892563478, -8.295975800373288],
              [-35.537323907698969, -8.295975871424147],
              [-35.537323922943202, -8.295975942456234],
              [-35.537323938314337, -8.295976013469437],
              [-35.537323953785091, -8.295976084454903],
              [-35.537323969382676, -8.295976155412447],
              [-35.537323985088953, -8.295976226342178],
              [-35.53732400091306, -8.295976297253098],
              [-35.537324016845858, -8.295976368136211],
              [-35.537324032896414, -8.295976438991458],
              [-35.5373240490648, -8.29597650982789],
              [-35.537324065341821, -8.295976580627475],
              [-35.537324081736656, -8.295976651408232],
              [-35.537324098240184, -8.295976722161196],
              [-35.537324114861484, -8.295976792886295],
              [-35.537324131600549, -8.295976863583542],
              [-35.537324148448349, -8.295976934262017],
              [-35.537324165413871, -8.295977004903587],
              [-35.537324182497208, -8.295977075526347],
              [-35.537324199689181, -8.295977146112257],
              [-35.537324216989909, -8.295977216679395],
              [-35.537324234408395, -8.295977287218683],
              [-35.53732425194459, -8.295977357721069],
              [-35.537324269589533, -8.295977428204701],
              [-35.537324287352185, -8.295977498651427],
              [-35.537324305223585, -8.295977569079378],
              [-35.537324323212694, -8.295977639470443],
              [-35.537324341319568, -8.295977709833638],
              [-35.537324359535134, -8.295977780169045],
              [-35.537324377859385, -8.295977850476639],
              [-35.537324396301408, -8.295977920756384],
              [-35.537324414861189, -8.295977991008247],
              [-35.537324433529619, -8.295978061223297],
              [-35.537324452306727, -8.295978131410516],
              [-35.5373244712016, -8.295978201569884],
              [-35.537324490214189, -8.295978271692366],
              [-35.537324509335512, -8.295978341796076],
              [-35.537324528565485, -8.295978411862938],
              [-35.537324547913158, -8.295978481892911],
              [-35.537324567378597, -8.295978551895017],
              [-35.537324586952721, -8.295978621869333],
              [-35.537324606635551, -8.29597869181584],
              [-35.537324626436074, -8.295978761725456],
              [-35.537324646345297, -8.295978831607261],
              [-35.537324666372228, -8.295978901452163],
              [-35.537324686507787, -8.295978971260231],
              [-35.537324706761169, -8.29597904104947],
              [-35.537324727123135, -8.295979110792841],
              [-35.537324747593786, -8.295979180508404],
              [-35.537324768182195, -8.295979250196117],
              [-35.53732478888832, -8.29597931984692],
              [-35.537324809694006, -8.295979389460951],
              [-35.537324830626531, -8.295979459047059],
              [-35.537324851658617, -8.295979528596394],
              [-35.537324872808462, -8.295979598117864],
              [-35.537324894066892, -8.295979667593439],
              [-35.537324915443129, -8.295979737050208],
              [-35.537324936927959, -8.295979806461107],
              [-35.537324958530547, -8.295979875844139],
              [-35.537324980232697, -8.29597994519038],
              [-35.537325002052548, -8.295980014499731],
              [-35.537325023990114, -8.295980083772196],
              [-35.537325046036315, -8.295980153007811],
              [-35.537325068191201, -8.295980222215615],
              [-35.537325090463796, -8.295980291386531],
              [-35.537325112835951, -8.295980360520661],
              [-35.537325135325815, -8.295980429617895],
              [-35.537325157933388, -8.295980498678244],
              [-35.537325180649596, -8.295980567701744],
              [-35.537325203474431, -8.295980636688398],
              [-35.537325226407958, -8.295980705647258],
              [-35.537325249459137, -8.295980774560173],
              [-35.537325272618951, -8.295980843436254],
              [-35.537325295887399, -8.295980912275509],
              [-35.537325319264539, -8.295980981086954],
              [-35.537325342759331, -8.295981049852468],
              [-35.537325366362758, -8.295981118581135],
              [-35.537325390074805, -8.295981187272956],
              [-35.537325413904519, -8.295981255918848],
              [-35.537325437833836, -8.295981324537008],
              [-35.537325461880819, -8.295981393109219],
              [-35.53732548603648, -8.295981461653643],
              [-35.537325510309792, -8.295981530152114],
              [-35.537325534682616, -8.295981598604781],
              [-35.537325559173198, -8.295981667029594],
              [-35.537325583772358, -8.295981735408519],
              [-35.537325608480145, -8.2959818037506],
              [-35.537325633305649, -8.295981872055791],
              [-35.53732565823065, -8.295981940315167],
              [-35.537325683273366, -8.29598200853764],
              [-35.537325708415636, -8.295982076723339],
              [-35.537325733675559, -8.295982144863094],
              [-35.537325759053182, -8.29598221296596],
              [-35.53732578453031, -8.29598228102301],
              [-35.53732581011608, -8.295982349043216],
              [-35.537325835819544, -8.295982417026513],
              [-35.53732586162252, -8.295982484964005],
              [-35.537325887543147, -8.295982552855564],
              [-35.537325913572403, -8.295982620710292],
              [-35.5373259397103, -8.295982688528154],
              [-35.537325965956704, -8.295982756291126],
              [-35.537325992311814, -8.295982824026288],
              [-35.537326018775431, -8.295982891706542],
              [-35.537326045347747, -8.295982959358986],
              [-35.537326072028577, -8.295983026956524],
              [-35.537326098827116, -8.295983094517169],
              [-35.537326125725151, -8.295983162031987],
              [-35.537326152740846, -8.295983229500859],
              [-35.537326179856102, -8.295983296932976],
              [-35.537326207079929, -8.295983364319211],
              [-35.537326234421407, -8.295983431659495],
              [-35.537326261862383, -8.295983498953969],
              [-35.537326289421067, -8.295983566211552],
              [-35.537326317079263, -8.295983633423326],
              [-35.537326344855103, -8.295983700589154],
              [-35.537326372730448, -8.295983767709153],
              [-35.537326400714363, -8.295983834783302],
              [-35.537326428815931, -8.295983901811502],
              [-35.537326457017066, -8.295983968802915],
              [-35.537326485326709, -8.295984035739414],
              [-35.537326513744993, -8.295984102639085],
              [-35.537326542280873, -8.295984169483791],
              [-35.537326570916306, -8.295984236291707],
              [-35.537326599651244, -8.295984303053794],
              [-35.537326628503777, -8.295984369760934],
              [-35.537326657464938, -8.295984436431217],
              [-35.537326686525546, -8.295984503046657],
              [-35.53732671570387, -8.295984569625187],
              [-35.537326744981634, -8.295984636148869],
              [-35.537326774367962, -8.295984702626681],
              [-35.537326803862882, -8.295984769058604],
              [-35.537326833466373, -8.295984835444662],
              [-35.537326863178443, -8.295984901784848],
              [-35.537326892999019, -8.295984968070105],
              [-35.537326922919114, -8.295985034309554],
              [-35.537326952947787, -8.295985100503129],
              [-35.537326983085023, -8.295985166650821],
              [-35.537327013330845, -8.295985232752646],
              [-35.537327043685181, -8.295985298799556],
              [-35.537327074139014, -8.295985364800655],
              [-35.537327104701376, -8.295985430746832],
              [-35.537327135372365, -8.295985496656177],
              [-35.537327166142752, -8.295985562501613],
              [-35.537327197030841, -8.295985628310163],
              [-35.53732722801837, -8.295985694063861],
              [-35.537327259114477, -8.295985759771687],
              [-35.537327290310031, -8.295985825424649],
              [-35.537327321614107, -8.295985891022703],
              [-35.537327353026818, -8.295985956583921],
              [-35.537327384547986, -8.295986022081177],
              [-35.537327416168715, -8.295986087541678],
              [-35.537327447897901, -8.295986152938198],
              [-35.53732747973573, -8.295986218297902],
              [-35.537327511672942, -8.295986283593702],
              [-35.537327543718732, -8.295986348843631],
              [-35.537327575873036, -8.295986414038648],
              [-35.537327608126851, -8.295986479187857],
              [-35.537327640489181, -8.295986544282142],
              [-35.537327672951015, -8.295986609330614],
              [-35.537327705521307, -8.295986674315118],
              [-35.537327738200176, -8.295986739253774],
              [-35.53732777097855, -8.295986804146596],
              [-35.537327803865381, -8.295986868975476],
              [-35.537327836851723, -8.295986933758524],
              [-35.537327869946573, -8.295986998486679],
              [-35.537327903149951, -8.295987063159911],
              [-35.537327936452826, -8.295987127787331],
              [-35.537327969855092, -8.295987192350843],
              [-35.537328003365943, -8.295987256868505],
              [-35.537328036985301, -8.295987321331239],
              [-35.537328070704106, -8.295987385739123],
              [-35.537328104522359, -8.29598745009214],
              [-35.537328138449126, -8.295987514390262],
              [-35.537328172484415, -8.295987578633463],
              [-35.537328206619151, -8.29598764282181],
              [-35.537328240853334, -8.295987706955311],
              [-35.537328275196089, -8.29598777104292],
              [-35.537328309638241, -8.295987835066658],
              [-35.5373283441889, -8.29598789903547],
              [-35.537328378839014, -8.295987962949416],
              [-35.537328413588568, -8.295988026808532],
              [-35.537328448446644, -8.295988090612729],
              [-35.537328483404167, -8.295988154362064],
              [-35.53732851847014, -8.295988218047453],
              [-35.537328553635625, -8.295988281687031],
              [-35.5373285889005, -8.2959883452627],
              [-35.537328624273947, -8.2959884087925],
              [-35.537328659746784, -8.295988472258427],
              [-35.537328695319005, -8.295988535660449],
              [-35.53732873099981, -8.29598859901658],
              [-35.537328766779993, -8.295988662308845],
              [-35.53732880265963, -8.295988725546255],
              [-35.537328838647781, -8.295988788728742],
              [-35.537328874735323, -8.295988851847339],
              [-35.537328910922312, -8.295988914911081],
              [-35.537328947208749, -8.295988977919961],
              [-35.537328983603643, -8.295989040864908],
              [-35.537329020088904, -8.295989103755046],
              [-35.537329056682694, -8.295989166590275],
              [-35.53732909338494, -8.295989229361558],
              [-35.537329130177561, -8.295989292078046],
              [-35.537329167078639, -8.295989354730587],
              [-35.53732920407009, -8.295989417328318],
              [-35.537329241170006, -8.29598947986212],
              [-35.53732927836937, -8.295989542341053],
              [-35.537329315677184, -8.295989604756038],
              [-35.537329353075378, -8.295989667116233],
              [-35.53732939058203, -8.295989729412463],
              [-35.537329428179, -8.295989791644883],
              [-35.53732946588449, -8.295989853822366],
              [-35.537329503689421, -8.295989915945007],
              [-35.537329541593685, -8.295989977994735],
              [-35.537329579597397, -8.295990039989594],
              [-35.53732961770055, -8.295990101929586],
              [-35.537329655903036, -8.295990163796667],
              [-35.537329694204971, -8.295990225608897],
              [-35.537329732606345, -8.295990287366262],
              [-35.537329771116127, -8.295990349050637],
              [-35.537329809716283, -8.29599041068022],
              [-35.537329848415823, -8.295990472245929],
              [-35.537329887214753, -8.29599053374772],
              [-35.537329926122148, -8.295990595185575],
              [-35.537329965119909, -8.295990656568641],
              [-35.537330004217061, -8.295990717887801],
              [-35.537330043413547, -8.295990779134049],
              [-35.537330082709474, -8.295990840325427],
              [-35.537330122104791, -8.295990901452933],
              [-35.537330161599549, -8.295990962525575],
              [-35.537330201184567, -8.295991023525344],
              [-35.537330240878049, -8.295991084461164],
              [-35.537330280661841, -8.295991145333138],
              [-35.537330320554105, -8.295991206141183],
              [-35.537330360536728, -8.295991266894431],
              [-35.537330400618693, -8.295991327574734],
              [-35.537330440800041, -8.295991388191169],
              [-35.53733048108078, -8.295991448743692],
              [-35.537330521451828, -8.295991509232392],
              [-35.537330561922268, -8.295991569657209],
              [-35.537330602501164, -8.295991630018067],
              [-35.537330643161319, -8.295991690315152],
              [-35.537330683929916, -8.29599175054827],
              [-35.537330724788781, -8.295991810708536],
              [-35.537330765756103, -8.295991870804858],
              [-35.537330806804661, -8.295991930837403],
              [-35.537330847961691, -8.295991990806],
              [-35.537330889209031, -8.295992050710751],
              [-35.537330930555711, -8.295992110542588],
              [-35.537330972001762, -8.295992170310521],
              [-35.537331013538143, -8.295992230014619],
              [-35.537331055173908, -8.295992289654848],
              [-35.537331096909, -8.29599234922213],
              [-35.537331138734402, -8.295992408725583],
              [-35.537331180659145, -8.295992468156124],
              [-35.537331222683278, -8.295992527522756],
              [-35.537331264797722, -8.295992586825557],
              [-35.537331307002475, -8.295992646064528],
              [-35.537331349315579, -8.295992705221472],
              [-35.537331391709984, -8.295992764323685],
              [-35.537331434212796, -8.295992823352908],
              [-35.537331476805917, -8.295992882318286],
              [-35.5373315194893, -8.295992941210807],
              [-35.537331562272009, -8.295993000030403],
              [-35.537331605154101, -8.295993058786109],
              [-35.537331648126518, -8.295993117477984],
              [-35.537331691189195, -8.295993176096971],
              [-35.537331734351191, -8.295993234643046],
              [-35.537331777603512, -8.295993293125285],
              [-35.537331820955167, -8.295993351534602],
              [-35.537331864406198, -8.295993409880012],
              [-35.537331907938416, -8.295993468152625],
              [-35.537331951569975, -8.295993526352291],
              [-35.537331995300917, -8.295993584488089],
              [-35.537332039122106, -8.295993642551013],
              [-35.537332083033562, -8.295993700541056],
              [-35.537332127035334, -8.295993758467276],
              [-35.537332171136377, -8.295993816311535],
              [-35.537332215336811, -8.295993874091884],
              [-35.537332259618488, -8.295993931808479],
              [-35.537332303999435, -8.295993989443087],
              [-35.537332348470699, -8.295994047013883],
              [-35.537332393041289, -8.295994104511751],
              [-35.53733243770214, -8.295994161936727],
              [-35.537332482453252, -8.295994219288858],
              [-35.537332527294616, -8.295994276568111],
              [-35.537332572226298, -8.295994333783518],
              [-35.537332617257249, -8.295994390916974],
              [-35.537332662378518, -8.295994447986603],
              [-35.537332707589989, -8.295994504974303],
              [-35.537332752891778, -8.295994561898187],
              [-35.537332798292887, -8.295994618749145],
              [-35.53733284377514, -8.29599467551825],
              [-35.537332889356769, -8.295994732223466],
              [-35.537332935028658, -8.295994788855811],
              [-35.537332980790744, -8.295994845406248],
              [-35.537333026643154, -8.295994901892856],
              [-35.537333072585817, -8.29599495830659],
              [-35.537333118618676, -8.295995014638416],
              [-35.537333164750869, -8.295995070897316],
              [-35.537333210964306, -8.295995127092437],
              [-35.537333257267939, -8.295995183205653],
              [-35.537333303670906, -8.29599523924594],
              [-35.537333350155059, -8.295995295213434],
              [-35.537333396738482, -8.295995351098938],
              [-35.537333443403149, -8.295995406920692],
              [-35.537333490167086, -8.295995462660457],
              [-35.537333537012216, -8.295995518327429],
              [-35.5373335839476, -8.295995573921513],
              [-35.537333630982253, -8.295995629433648],
              [-35.537333678098094, -8.295995684872969],
              [-35.537333725304187, -8.295995740239421],
              [-35.537333772600547, -8.295995795532985],
              [-35.537333819987104, -8.295995850744674],
              [-35.537333867454848, -8.295995905883531],
              [-35.537333915021918, -8.295995960949464],
              [-35.537333962670111, -8.295996015933543],
              [-35.537334010417645, -8.295996070844696],
              [-35.537334058246302, -8.295996125674018],
              [-35.537334106165211, -8.295996180430446],
              [-35.537334154165308, -8.295996235114078],
              [-35.537334202264688, -8.295996289715731],
              [-35.537334250445241, -8.295996344244585],
              [-35.537334298715997, -8.295996398691534],
              [-35.53733434706794, -8.295996453065669],
              [-35.53733439551916, -8.295996507357836],
              [-35.53733444405156, -8.295996561577189],
              [-35.537334492674162, -8.295996615714632],
              [-35.537334541377888, -8.295996669770227],
              [-35.537334590171881, -8.295996723752969],
              [-35.53733463905612, -8.295996777662825],
              [-35.537334688021431, -8.295996831481801],
              [-35.537334737077003, -8.295996885227913],
              [-35.537334786222836, -8.295996938901155],
              [-35.537334835449791, -8.295996992492546],
              [-35.537334884766942, -8.295997046002023],
              [-35.537334934165294, -8.29599709943869],
              [-35.537334983653771, -8.295997152784425],
              [-35.537335033232573, -8.295997206066314],
              [-35.53733508289244, -8.295997259257328],
              [-35.537335132633501, -8.295997312375516],
              [-35.537335182464695, -8.295997365402771],
              [-35.537335232377075, -8.295997418357212],
              [-35.537335282379722, -8.295997471238785],
              [-35.537335332472502, -8.295997524029424],
              [-35.537335382646468, -8.295997576747236],
              [-35.537335432901564, -8.295997629383214],
              [-35.537335483237783, -8.295997681937321],
              [-35.537335533664205, -8.295997734409539],
              [-35.53733558418083, -8.295997786799846],
              [-35.537335634778643, -8.295997839117343],
              [-35.537335685457521, -8.295997891343966],
              [-35.537335736217585, -8.295997943497756],
              [-35.537335787067789, -8.295997995560622],
              [-35.537335837999173, -8.295998047550652],
              [-35.53733588902071, -8.295998099449754],
              [-35.53733594011436, -8.2959981512761],
              [-35.5373359912982, -8.295998203020558],
              [-35.537336042572193, -8.295998254674046],
              [-35.537336093918292, -8.295998306254798],
              [-35.537336145354594, -8.295998357753628],
              [-35.537336196871962, -8.295998409161578],
              [-35.53733624847046, -8.295998460487681],
              [-35.537336300150145, -8.295998511740988],
              [-35.537336351910902, -8.295998562903387],
              [-35.537336403761856, -8.295998613983896],
              [-35.537336455693939, -8.295998664982539],
              [-35.537336507707145, -8.295998715899344],
              [-35.537336559801481, -8.295998766734312],
              [-35.537336611976883, -8.295998817478377],
              [-35.537336664233415, -8.29599886814059],
              [-35.53733671657114, -8.29599891873001],
              [-35.537336768989874, -8.295998969219495],
              [-35.537336821489788, -8.295999019636172],
              [-35.537336874079841, -8.2959990699619],
              [-35.537336926742014, -8.295999120214892],
              [-35.537336979485204, -8.295999170367953],
              [-35.537337032318639, -8.295999220448131],
              [-35.537337085224074, -8.295999270437507],
              [-35.537337138210646, -8.295999320345013],
              [-35.537337191278347, -8.295999370170675],
              [-35.537337244427107, -8.295999419905455],
              [-35.537337297656997, -8.295999469558387],
              [-35.53733735096796, -8.29599951912043],
              [-35.537337404350971, -8.295999568600681],
              [-35.537337457824187, -8.295999617999039],
              [-35.537337511369408, -8.295999667306564],
              [-35.537337564995745, -8.295999716532236],
              [-35.537337618703148, -8.295999765667021],
              [-35.537337672491695, -8.295999814719954],
              [-35.537337726352284, -8.295999863691113],
              [-35.537337780303019, -8.29599991257132],
              [-35.537337834325754, -8.295999961360698],
              [-35.537337888429612, -8.296000010068221],
              [-35.537337942605468, -8.296000058684932],
              [-35.537337996862455, -8.296000107219774],
              [-35.537338051200514, -8.29600015566376],
              [-35.537338105619696, -8.296000204025875],
              [-35.537338160110878, -8.296000252297162],
              [-35.537338214683182, -8.296000300486616],
              [-35.537338269327485, -8.296000348585233],
              [-35.537338324052868, -8.296000396592964],
              [-35.53733837885931, -8.29600044450982],
              [-35.537338433737808, -8.296000492344865],
              [-35.53733848869738, -8.296000540089038],
              [-35.537338543729, -8.296000587751433],
              [-35.5373385988417, -8.296000635322924],
              [-35.537338654035459, -8.296000682803539],
              [-35.537338709301224, -8.296000730193324],
              [-35.537338764639046, -8.296000777501314],
              [-35.537338820057926, -8.296000824718416],
              [-35.537338875548819, -8.296000871844706],
              [-35.537338931120765, -8.296000918880098],
              [-35.53733898676478, -8.296000965833702],
              [-35.537339042480781, -8.296001012696488],
              [-35.537339098277855, -8.296001059468384],
              [-35.537339154156008, -8.296001106149392],
              [-35.537339210097073, -8.296001152739626],
              [-35.537339266119218, -8.296001199238985],
              [-35.537339322222486, -8.296001245656495],
              [-35.537339378388623, -8.296001291974193],
              [-35.537339434635882, -8.296001338210035],
              [-35.537339490964222, -8.296001384355005],
              [-35.537339547355472, -8.296001430409202],
              [-35.537339603827803, -8.296001476372508],
              [-35.537339660372126, -8.296001522245001],
              [-35.537339716988448, -8.296001568026664],
              [-35.537339773676713, -8.296001613708453],
              [-35.537339830446108, -8.296001659308406],
              [-35.537339887287487, -8.296001704817511],
              [-35.53733994420088, -8.296001750235803],
              [-35.537340001186259, -8.296001795563278],
              [-35.537340058243636, -8.296001840799901],
              [-35.537340115373013, -8.296001885945714],
              [-35.537340172574389, -8.29600193100071],
              [-35.537340229856774, -8.296001975955759],
              [-35.537340287202142, -8.296002020829093],
              [-35.537340344628525, -8.296002065602492],
              [-35.537340402117891, -8.296002110294179],
              [-35.537340459688266, -8.296002154885917],
              [-35.537340517321567, -8.296002199386898],
              [-35.537340575035934, -8.296002243797007],
              [-35.537340632813169, -8.296002288107303],
              [-35.537340690662468, -8.296002332335805],
              [-35.537340748592776, -8.296002376464378],
              [-35.537340806586002, -8.296002420502194],
              [-35.537340864651227, -8.296002464449158],
              [-35.537340922788395, -8.296002508296286],
              [-35.537340980997612, -8.296002552061609],
              [-35.537341039269705, -8.29600259572713],
              [-35.537341097622814, -8.296002639292745],
              [-35.537341156038892, -8.296002682776601],
              [-35.537341214526919, -8.296002726160607],
              [-35.537341273086938, -8.296002769453779],
              [-35.537341331718899, -8.296002812647098],
              [-35.537341390413843, -8.296002855758683],
              [-35.537341449180666, -8.296002898761353],
              [-35.537341508019551, -8.296002941682255],
              [-35.537341566921292, -8.296002984503337],
              [-35.537341625894989, -8.296003027224549],
              [-35.537341684940728, -8.296003069863987],
              [-35.537341744058409, -8.296003112403552],
              [-35.537341803238952, -8.296003154843303],
              [-35.537341862482435, -8.296003197192301],
              [-35.537341921806984, -8.296003239450407],
              [-35.537341981185314, -8.296003281608758],
              [-35.537342040644667, -8.296003323667197],
              [-35.537342100166946, -8.296003365634858],
              [-35.537342159752143, -8.296003407511749],
              [-35.53734221940929, -8.296003449288785],
              [-35.537342279138372, -8.296003490965951],
              [-35.537342338930372, -8.296003532552358],
              [-35.537342398785299, -8.29600357404799],
              [-35.537342458712175, -8.296003615443755],
              [-35.537342518701912, -8.296003656739721],
              [-35.537342578763649, -8.296003697944856],
              [-35.537342638888248, -8.296003739050175],
              [-35.537342699075715, -8.296003780055702],
              [-35.537342759335189, -8.296003820970393],
              [-35.537342819657574, -8.296003861794315],
              [-35.537342880042779, -8.296003902509398],
              [-35.537342940499975, -8.296003943133648],
              [-35.537343001020098, -8.296003983667143],
              [-35.537343061603039, -8.296004024091786],
              [-35.537343122257965, -8.296004064425597],
              [-35.537343182975768, -8.296004104659609],
              [-35.537343243747415, -8.296004144802907],
              [-35.537343304600071, -8.296004184846277],
              [-35.53734336550653, -8.296004224789909],
              [-35.537343426475857, -8.296004264633741],
              [-35.53734348751712, -8.296004304377687],
              [-35.537343548621315, -8.29600434403088],
              [-35.537343609788365, -8.296004383584272],
              [-35.537343671018292, -8.29600442303785],
              [-35.537343732311143, -8.29600446240066],
              [-35.5373437936668, -8.296004501654613],
              [-35.537343855085382, -8.29600454081781],
              [-35.537343916566833, -8.296004579881208],
              [-35.537343978120226, -8.296004618844741],
              [-35.537344039727408, -8.296004657708517],
              [-35.537344101397458, -8.296004696472478],
              [-35.537344163130442, -8.296004735145685],
              [-35.53734422492623, -8.296004773710051],
              [-35.537344286784936, -8.296004812183645],
              [-35.537344348706462, -8.296004850548389],
              [-35.537344410690906, -8.296004888822376],
              [-35.537344472729146, -8.296004926996604],
              [-35.537344534839328, -8.296004965070964],
              [-35.537344597003248, -8.296005003036546],
              [-35.537344659230094, -8.296005040911371],
              [-35.537344721519808, -8.296005078686367],
              [-35.537344783872378, -8.296005116361567],
              [-35.537344846287823, -8.296005153936971],
              [-35.537344908757071, -8.296005191412618],
              [-35.537344971289173, -8.296005228788452],
              [-35.537345033875077, -8.296005266064547],
              [-35.53734509653286, -8.296005303231752],
              [-35.537345159244495, -8.296005340308225],
              [-35.537345222009932, -8.296005377284954],
              [-35.537345284847248, -8.296005414152795],
              [-35.537345347738409, -8.296005450929917],
              [-35.537345347738409, -8.296005450929917],
              [-35.539176564419741, -8.297390165603082],
              [-35.538956001055162, -8.299069361622076],
              [-35.538955999084799, -8.29932320804253],
              [-35.538896404992755, -8.299748864295418],
              [-35.538904286725185, -8.300001214558936],
              [-35.538904047519125, -8.300204004031521],
              [-35.538920561098671, -8.300430092245339],
              [-35.538996891504745, -8.300731076392502],
              [-35.539036124080994, -8.300864120436863],
              [-35.5390275565819, -8.300928608130025],
              [-35.538997612477409, -8.301153817750562],
              [-35.538953403226671, -8.301248715944853],
              [-35.538912361806005, -8.301469477889144],
              [-35.53884187359661, -8.301625813806808],
              [-35.538490486081955, -8.304392350930026],
              [-35.53844983921848, -8.30473095160256],
              [-35.538422990016919, -8.30507063908115],
              [-35.538362239976536, -8.305528112822053],
              [-35.538738702210935, -8.305688194866079],
              [-35.539101269887354, -8.305846196255855],
              [-35.541252854222449, -8.306870167357351],
              [-35.543161463180112, -8.307638577838301],
              [-35.543579808181576, -8.30780706176863],
              [-35.544317209136466, -8.308321875738164],
              [-35.544613957595026, -8.308515173887193],
              [-35.545073607906389, -8.308771591245806],
              [-35.545172729925724, -8.309130533379649],
              [-35.545158783319927, -8.309261114727763],
              [-35.545237322781105, -8.309537591666956],
              [-35.545526924313734, -8.310226153708779],
              [-35.550315550525589, -8.311302850168316],
              [-35.553358083134533, -8.309444520993335],
              [-35.553652741259562, -8.30910393051086],
              [-35.552789233716773, -8.306360659144586],
              [-35.55255041036397, -8.305598400687813],
              [-35.552904782011424, -8.305372557186612],
              [-35.554041548927643, -8.304454174210326],
              [-35.554566765117123, -8.304075325070167],
              [-35.554738681102691, -8.303905324131904],
              [-35.555560867620734, -8.303235752481493],
              [-35.555665693821631, -8.303143356178952],
              [-35.555592898527713, -8.302703641863822],
              [-35.555516072264261, -8.302384052193378],
              [-35.55544522696956, -8.302203590545091],
              [-35.558201003005507, -8.300658046690053],
              [-35.560995861877103, -8.301786573360172],
              [-35.562333691934612, -8.301477223126671],
              [-35.562560315882976, -8.301280119313162],
              [-35.562611844973119, -8.301195746041198],
              [-35.562751845249544, -8.301113514370966],
              [-35.563914913977605, -8.300102227320821],
              [-35.564914282682601, -8.299604013429629],
              [-35.5652581969183, -8.299432630925569],
              [-35.565351542513277, -8.298662462826032],
              [-35.565347773593018, -8.298291529859693],
              [-35.563762613396811, -8.296638345584119],
              [-35.564230236616531, -8.295985685740693],
              [-35.564830294450971, -8.295148004935154],
              [-35.565111987979257, -8.294727881805629],
              [-35.564996687393126, -8.294225911224578],
              [-35.564403837019732, -8.293531007185138],
              [-35.564221081104506, -8.293108371692476],
              [-35.563827555248636, -8.293334280299653],
              [-35.563620147740828, -8.293458152594591],
              [-35.563458752288945, -8.293528412134805],
              [-35.563375015668903, -8.29355660314628],
              [-35.56244713222717, -8.292974549725406],
              [-35.561307160651964, -8.292297705275653],
              [-35.560886079728803, -8.29205443058002],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0211000',
        uf: 'PE',
        nome_proje: 'PA VELHO II',
        municipio: 'PAUDALHO',
        area_hecta: '211.1037',
        capacidade: 19.0,
        num_famili: 18.0,
        fase: 5.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '14/10/1999',
        area_calc_: 264.1699,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.147160952300204, -7.953344408999675],
              [-35.145239852848718, -7.953727218139416],
              [-35.145159252428343, -7.95371136355312],
              [-35.139381321615744, -7.954527796098776],
              [-35.138308952465152, -7.954603919858019],
              [-35.137818412908203, -7.954640254154843],
              [-35.137366303850236, -7.954673678169618],
              [-35.134693207450411, -7.95484463035134],
              [-35.134693207450411, -7.95484463035134],
              [-35.131393106875571, -7.955018785750431],
              [-35.131499728177225, -7.955437673697107],
              [-35.131764804325677, -7.956567251089368],
              [-35.132227444112388, -7.962532153069038],
              [-35.132319481266997, -7.963951883363682],
              [-35.132465377991259, -7.967245144017352],
              [-35.132491597315521, -7.967510500585181],
              [-35.132512567050597, -7.967515002861536],
              [-35.132556742517714, -7.967518029805801],
              [-35.132631293091087, -7.967520719663457],
              [-35.132689102279421, -7.967494930692063],
              [-35.132733989440922, -7.967477795765162],
              [-35.132755318005117, -7.96746403630609],
              [-35.132756313091477, -7.967463579210277],
              [-35.132772073178444, -7.967460153474828],
              [-35.132822923331425, -7.967438829674441],
              [-35.132870669001001, -7.967413363647599],
              [-35.132911216727585, -7.967381245401378],
              [-35.132958383092259, -7.967331465981625],
              [-35.132986288768876, -7.96730953707883],
              [-35.133046364639029, -7.96726619968329],
              [-35.133019774957575, -7.967384664138587],
              [-35.133059452378212, -7.967412120950914],
              [-35.133005985728154, -7.96748850906588],
              [-35.13300967852156, -7.967536218842757],
              [-35.133003379508104, -7.967545561992757],
              [-35.132980014708977, -7.967586360178161],
              [-35.132949875985027, -7.967632345768695],
              [-35.132922626344737, -7.967693412529671],
              [-35.132914736504738, -7.967728255378291],
              [-35.132900266251262, -7.967770634906739],
              [-35.132883055100486, -7.967861570891056],
              [-35.132884880432059, -7.9678813581048],
              [-35.132880974523808, -7.967897558986937],
              [-35.132883808464968, -7.967972030247296],
              [-35.132887376283065, -7.96799560509255],
              [-35.132884016243835, -7.968064684493476],
              [-35.132909995078236, -7.96812592930058],
              [-35.132909997887054, -7.968126471656211],
              [-35.132949490385421, -7.968223262703133],
              [-35.133000015465221, -7.968279228626048],
              [-35.133040759228784, -7.968319968048571],
              [-35.133102260061818, -7.968359154309811],
              [-35.133210599268537, -7.968407771762572],
              [-35.13330948597185, -7.968417115840069],
              [-35.133310391780419, -7.968416930387044],
              [-35.133312390842811, -7.968417733659959],
              [-35.133338616661476, -7.968439112757006],
              [-35.133387784647574, -7.968460644945882],
              [-35.133471189520904, -7.968492125157361],
              [-35.133582155208025, -7.968505113187616],
              [-35.133626149871141, -7.968508231313804],
              [-35.133694290582469, -7.968498840954267],
              [-35.133710548380343, -7.968486463474429],
              [-35.133740132112649, -7.968473384614456],
              [-35.133783036510017, -7.96849359306754],
              [-35.133850298276869, -7.968507077233549],
              [-35.13385811411753, -7.968510472013977],
              [-35.133877738305216, -7.968517783385177],
              [-35.133900590441812, -7.968535564002306],
              [-35.133932197485969, -7.968562971848498],
              [-35.133938694888251, -7.968574418603262],
              [-35.133945420010861, -7.968594813321619],
              [-35.133954827624123, -7.96862540891881],
              [-35.133963674534158, -7.968670289859376],
              [-35.133975295822111, -7.968708105678632],
              [-35.133972674401925, -7.968727192603186],
              [-35.133978147991954, -7.968803548501213],
              [-35.134007005611018, -7.968895331995132],
              [-35.134048303539487, -7.968990486432123],
              [-35.134117547919821, -7.969071576052939],
              [-35.134152774072113, -7.969097428504742],
              [-35.134185375790857, -7.969124288813773],
              [-35.134269457726703, -7.969163900905538],
              [-35.134349330925517, -7.969178766327214],
              [-35.134405096073223, -7.969178479100071],
              [-35.134513768576326, -7.969168879798641],
              [-35.13459878336851, -7.969091063472357],
              [-35.13467079974874, -7.969007167209277],
              [-35.134683524266563, -7.968942920973761],
              [-35.134705267557827, -7.968869227171198],
              [-35.134700628697367, -7.968726516838377],
              [-35.134691264369437, -7.968686791128217],
              [-35.134687982047268, -7.968665836318266],
              [-35.134687388584595, -7.968638811170704],
              [-35.134693121277152, -7.968607685645624],
              [-35.134684101661165, -7.968512003455518],
              [-35.134649211367332, -7.968428477088148],
              [-35.13462753121302, -7.968391888258549],
              [-35.13462338316652, -7.9683613559996],
              [-35.134619281036734, -7.968339682241755],
              [-35.134662342354758, -7.968197720554357],
              [-35.134677454419844, -7.968139247335302],
              [-35.134680100938176, -7.968072521940937],
              [-35.134675077785779, -7.968030604385662],
              [-35.134675172676779, -7.968031417451101],
              [-35.134672421774603, -7.968008019229843],
              [-35.134677080468663, -7.967944628100482],
              [-35.1346890748748, -7.967914464676384],
              [-35.134700303124291, -7.967876440805252],
              [-35.13471015366337, -7.967852525700336],
              [-35.134724235926249, -7.967822803496581],
              [-35.134744706903199, -7.967783556873865],
              [-35.13475035100592, -7.967770330080967],
              [-35.134778439122023, -7.967731134626034],
              [-35.134797058856883, -7.967702112217655],
              [-35.134811105722569, -7.967683056840258],
              [-35.1348477420756, -7.967648608308008],
              [-35.134903426724442, -7.967545361200663],
              [-35.134934633885592, -7.96744314413162],
              [-35.134938856775605, -7.967365653596088],
              [-35.13493351897533, -7.967315511677874],
              [-35.134922343746815, -7.967101332321093],
              [-35.134957231893544, -7.96709699443667],
              [-35.135182869060984, -7.967033369008494],
              [-35.135190739937194, -7.967029893437193],
              [-35.135251262958242, -7.96700291504345],
              [-35.135330976404397, -7.966917080832992],
              [-35.135364908126299, -7.96683319998305],
              [-35.135371312748326, -7.966826748923628],
              [-35.135424389893018, -7.96671031753284],
              [-35.135474395543199, -7.966753540053632],
              [-35.135506468983372, -7.966783476443274],
              [-35.135573020753604, -7.966852466756179],
              [-35.135487146430933, -7.966921790396825],
              [-35.135452428886701, -7.966959031329231],
              [-35.135416823331425, -7.966999892648491],
              [-35.135391368165791, -7.967039707327284],
              [-35.135362562950647, -7.967097979901743],
              [-35.135346151559652, -7.967150674517097],
              [-35.135341046368168, -7.967197887162839],
              [-35.135326632865514, -7.967233667561873],
              [-35.135318892250694, -7.967297255331417],
              [-35.135320793363704, -7.967349132441474],
              [-35.13532428656, -7.967375781059962],
              [-35.135327774133671, -7.967401344959652],
              [-35.135321726349048, -7.967459138717471],
              [-35.135318175741141, -7.967473891448397],
              [-35.135274508773321, -7.967743855997912],
              [-35.135264387431256, -7.967820472961937],
              [-35.135258222024277, -7.967890561085751],
              [-35.135234254392131, -7.967954955623736],
              [-35.135224087347765, -7.968005267796639],
              [-35.135224020574078, -7.968009878306793],
              [-35.135211428503531, -7.968047186039034],
              [-35.135198771063706, -7.968089375468083],
              [-35.135192272952118, -7.968130267625342],
              [-35.135191216023102, -7.968153775849144],
              [-35.135190002807789, -7.968199612513809],
              [-35.135192608488737, -7.968229971913871],
              [-35.135191957599531, -7.968244348126217],
              [-35.135196302740987, -7.968295399082238],
              [-35.135203337887262, -7.968323114198703],
              [-35.135203928330171, -7.968332060285965],
              [-35.135205762214497, -7.968353474523428],
              [-35.135206164760262, -7.968396139032417],
              [-35.135218695481456, -7.968451938775404],
              [-35.135238993311141, -7.968501732397733],
              [-35.135248587742225, -7.968515875029312],
              [-35.135253671400285, -7.968534470261221],
              [-35.135253498018976, -7.968536007876579],
              [-35.135260124110793, -7.968589758939848],
              [-35.135271347905544, -7.968638333785329],
              [-35.13527856442645, -7.968666047953654],
              [-35.135294177890437, -7.968739278096376],
              [-35.135377802947481, -7.968830688815191],
              [-35.135417903066511, -7.968852177046959],
              [-35.135458396355141, -7.968879538940946],
              [-35.135527858529976, -7.968915158313179],
              [-35.135649264115045, -7.968965425194503],
              [-35.135860216040903, -7.968937490571101],
              [-35.135978547908074, -7.968832202951116],
              [-35.136023735694607, -7.96876815098046],
              [-35.136066077378885, -7.968697334039851],
              [-35.136090610217941, -7.968619558057416],
              [-35.136105639353012, -7.968562621984407],
              [-35.136117159085593, -7.968493410232822],
              [-35.136126791526173, -7.968427462442141],
              [-35.136127884417832, -7.968375931520892],
              [-35.136132218896343, -7.968337491194745],
              [-35.136134730042777, -7.968297161966928],
              [-35.136137744719697, -7.968266502444585],
              [-35.136141515275682, -7.968241714707984],
              [-35.136146646414844, -7.968217010352939],
              [-35.136152524993292, -7.968196550729236],
              [-35.136167102437909, -7.968157424840941],
              [-35.136178696519863, -7.968120031841784],
              [-35.136192220556161, -7.9680876006371],
              [-35.136215958200303, -7.968031342836775],
              [-35.136234921216257, -7.967963629442486],
              [-35.13624085483071, -7.96793629949287],
              [-35.136243958302785, -7.967905277925989],
              [-35.136256287535041, -7.967834796479218],
              [-35.136279156287934, -7.967838294417673],
              [-35.136276990504719, -7.967875277235947],
              [-35.136275212362023, -7.967952031967839],
              [-35.136291617350054, -7.968003020712366],
              [-35.136300726447686, -7.968063448180448],
              [-35.136318060695857, -7.968118771109867],
              [-35.136322960227282, -7.968136824906957],
              [-35.136321384816846, -7.968147770846553],
              [-35.136311206660693, -7.968195913585147],
              [-35.136297992269917, -7.968270557775692],
              [-35.136294730715385, -7.968323546197803],
              [-35.136282469196175, -7.968389597933024],
              [-35.136271443107077, -7.968519010358545],
              [-35.136274946171589, -7.968599986438338],
              [-35.136279537693071, -7.968646064343119],
              [-35.136279653035714, -7.968668300982598],
              [-35.13628264494151, -7.968703178121525],
              [-35.136287852910172, -7.968745727431511],
              [-35.136289557549254, -7.968777176185071],
              [-35.136289944744853, -7.968816857704097],
              [-35.136297652519175, -7.968869337600828],
              [-35.136310722290375, -7.968924140136429],
              [-35.1363187863844, -7.968957906385993],
              [-35.136324300314001, -7.968989516288906],
              [-35.136329016472757, -7.969024655720367],
              [-35.136403498336712, -7.969293739929313],
              [-35.136429102870807, -7.969352636003112],
              [-35.136437440113326, -7.969369135347035],
              [-35.136444585756323, -7.969400646435932],
              [-35.136471079229899, -7.969456102908465],
              [-35.136498155392268, -7.969501522495301],
              [-35.136561266416841, -7.969571343779165],
              [-35.136595164945248, -7.969603440085521],
              [-35.136635591615558, -7.969635412329129],
              [-35.136692521606875, -7.969667389856538],
              [-35.136694430003672, -7.96966819357733],
              [-35.136711124842137, -7.969687632848903],
              [-35.13678335344143, -7.969732096413458],
              [-35.136809321063829, -7.969756098019132],
              [-35.136873132526681, -7.969786141724772],
              [-35.136924457731332, -7.969803956072515],
              [-35.136977364893681, -7.969812089967316],
              [-35.136980998932572, -7.969813427154834],
              [-35.137005468383947, -7.969828306553524],
              [-35.137052805762828, -7.969846503029716],
              [-35.137082872205028, -7.969856562622195],
              [-35.137133804696184, -7.969868593663634],
              [-35.137192545093207, -7.969882482731279],
              [-35.137237288688141, -7.969890116329704],
              [-35.137328177901686, -7.969895975169549],
              [-35.137414739453092, -7.969854218054762],
              [-35.137444471117462, -7.969834720118038],
              [-35.137468442749316, -7.969823568248008],
              [-35.137499577228049, -7.969812289029663],
              [-35.137532896349562, -7.969802625653285],
              [-35.137538331688532, -7.969801603270158],
              [-35.13755049527289, -7.969804071579786],
              [-35.137581782363725, -7.969804723737987],
              [-35.137618464580513, -7.969849008917019],
              [-35.13764410098139, -7.969879068638961],
              [-35.137672312786421, -7.969933612161612],
              [-35.137713362902268, -7.969980857834812],
              [-35.137764918194485, -7.970025518236489],
              [-35.137809266989414, -7.970061899453046],
              [-35.137860103005941, -7.970107738680145],
              [-35.137916846141273, -7.970138632224207],
              [-35.137999087742891, -7.970173009993655],
              [-35.138094193009067, -7.970204971078437],
              [-35.138131230661209, -7.97021291550896],
              [-35.138139590608596, -7.970216307382655],
              [-35.138148503982954, -7.970221504303243],
              [-35.138210464992781, -7.97024441609882],
              [-35.138281352997815, -7.970275146185309],
              [-35.138330040260364, -7.970291346827637],
              [-35.138380933292815, -7.970313230971279],
              [-35.138411001187379, -7.970323561626265],
              [-35.138482143803806, -7.970368391991181],
              [-35.138544056953251, -7.970382083670112],
              [-35.138682447668145, -7.970402792974917],
              [-35.138735936557588, -7.970400708969215],
              [-35.138752453497432, -7.970403335562041],
              [-35.138810928979758, -7.970418581662066],
              [-35.138890513914781, -7.970430283756647],
              [-35.138942939583863, -7.970432996147681],
              [-35.13895936725838, -7.970435894376409],
              [-35.13899534078346, -7.970448454368309],
              [-35.13902167974414, -7.970456634734661],
              [-35.139026985860959, -7.970465646849943],
              [-35.139196355911679, -7.970515032057211],
              [-35.139252949309359, -7.97053453637063],
              [-35.139316683966356, -7.970549755232931],
              [-35.139336451123505, -7.970549653159254],
              [-35.139344626435772, -7.970552413195129],
              [-35.139388321914382, -7.970567735514672],
              [-35.13941214030487, -7.970579454277035],
              [-35.139470354123688, -7.970596690312952],
              [-35.139503744614579, -7.970600766451799],
              [-35.13956299862739, -7.970608776804078],
              [-35.139653542573434, -7.970617981484749],
              [-35.139751148383532, -7.970607624343864],
              [-35.139924150684926, -7.970570753595212],
              [-35.140006340300069, -7.97052531235578],
              [-35.140048296508084, -7.970502587286608],
              [-35.140097597857356, -7.970462468428609],
              [-35.140166018909291, -7.970384917661959],
              [-35.140193025917426, -7.970277388879948],
              [-35.14019259602081, -7.970194679670615],
              [-35.140171918377639, -7.970124278367714],
              [-35.140152862079916, -7.970086682077816],
              [-35.140119402586805, -7.969999533307574],
              [-35.139982250075185, -7.969902976646682],
              [-35.139930780126086, -7.969874768038839],
              [-35.139883914405779, -7.969842648656987],
              [-35.139804803695476, -7.969800029170301],
              [-35.13974282817216, -7.969774315451711],
              [-35.139704842479816, -7.969758421296421],
              [-35.13964938428694, -7.969730504414639],
              [-35.13960413239856, -7.969712297492693],
              [-35.139581848021358, -7.969699124493855],
              [-35.139568282055379, -7.969688527926297],
              [-35.139568281585689, -7.969688437534095],
              [-35.139509828342852, -7.969642547481481],
              [-35.139410104887673, -7.969611785692378],
              [-35.139359384792492, -7.969605719907977],
              [-35.139341692869372, -7.969603822564905],
              [-35.139332776675381, -7.969598083317064],
              [-35.139262834492229, -7.969574851299559],
              [-35.139199031392678, -7.969563881317644],
              [-35.139147854631567, -7.969557185100083],
              [-35.138874196516106, -7.969436112409711],
              [-35.138819474123693, -7.969410180292968],
              [-35.138801379130307, -7.969400511017431],
              [-35.138778810087146, -7.969384898768218],
              [-35.138746755463288, -7.969358668843656],
              [-35.138740373955969, -7.969352102935153],
              [-35.138728207634244, -7.969331646041803],
              [-35.138678227652001, -7.969293486085842],
              [-35.138671579757336, -7.969288006295865],
              [-35.138661288438172, -7.969279381478966],
              [-35.138607264320392, -7.969230756546674],
              [-35.13853184988502, -7.969183959508416],
              [-35.138502119982512, -7.969151389903119],
              [-35.138437607749403, -7.969091158097217],
              [-35.138386530330841, -7.969051195851583],
              [-35.138336907944435, -7.96901203964069],
              [-35.138319163000951, -7.968999927863992],
              [-35.138289610722119, -7.968966634161108],
              [-35.137921716323689, -7.968683878024086],
              [-35.137851112028272, -7.968637869550418],
              [-35.1377928159297, -7.968604724074579],
              [-35.137787269764878, -7.968601860036931],
              [-35.137786996325261, -7.968601590263356],
              [-35.137767351006609, -7.968590211415592],
              [-35.137734154795787, -7.968571128488435],
              [-35.137711755449629, -7.968553255405202],
              [-35.137657728244832, -7.968503997537188],
              [-35.137632695717642, -7.968467968597207],
              [-35.137590531770044, -7.968433203155108],
              [-35.137487169531951, -7.968330233806779],
              [-35.137524813848337, -7.968297858970668],
              [-35.137620890810744, -7.968377363138291],
              [-35.137930231131094, -7.968559902346711],
              [-35.137932863978605, -7.968560521526467],
              [-35.137932955122132, -7.968560611450964],
              [-35.137976464315258, -7.968575031003413],
              [-35.138047466241261, -7.968592834124497],
              [-35.13810838122442, -7.96860644069809],
              [-35.138128088135005, -7.96861221470076],
              [-35.138129180449987, -7.968613022626212],
              [-35.138162136556552, -7.968638253622436],
              [-35.13822007748881, -7.968672847194224],
              [-35.138277161518374, -7.968699580792435],
              [-35.138656659157981, -7.968772108044568],
              [-35.138728916162862, -7.968752209792272],
              [-35.138776582009342, -7.968728913042387],
              [-35.138830548595656, -7.968696634649632],
              [-35.138896365463644, -7.968641425125938],
              [-35.138936350835415, -7.968588428015854],
              [-35.138955679220416, -7.968556237998025],
              [-35.138985517943837, -7.968505010767781],
              [-35.139008405903198, -7.968407446725999],
              [-35.139009617329592, -7.96834389272517],
              [-35.139012639306983, -7.968314679514381],
              [-35.13900596205977, -7.968251166231608],
              [-35.139046212442061, -7.968179365584268],
              [-35.139059810805307, -7.968178753026359],
              [-35.139061330606332, -7.968192033260371],
              [-35.139076902818971, -7.968274664385588],
              [-35.139080477497927, -7.968316950834295],
              [-35.139085304646301, -7.968373422842668],
              [-35.139088437218049, -7.968417881053665],
              [-35.13908626653744, -7.968453869505058],
              [-35.139086630393486, -7.968523923813983],
              [-35.139094785697033, -7.968592672377977],
              [-35.13910122823178, -7.968610989318655],
              [-35.139102315327555, -7.968628249168209],
              [-35.139109348264789, -7.968690404687016],
              [-35.139112248331912, -7.968725011033253],
              [-35.139109706970771, -7.968759464670455],
              [-35.139104456004219, -7.968848350141355],
              [-35.139127102732807, -7.968948752570964],
              [-35.139394697352351, -7.969141991715699],
              [-35.139519800286706, -7.969135922111654],
              [-35.139637428095114, -7.969052422513222],
              [-35.139654586306413, -7.969038865052076],
              [-35.13966598407702, -7.969033563291582],
              [-35.139695191214642, -7.969017864536156],
              [-35.139740293691631, -7.968989880370724],
              [-35.139786788693442, -7.968968126266852],
              [-35.139842919648778, -7.968933576703678],
              [-35.139890922748449, -7.968905396758641],
              [-35.13995333797159, -7.968858701800322],
              [-35.140019896017087, -7.968806561737627],
              [-35.140085437860293, -7.968733364874891],
              [-35.140123623193809, -7.968682998445312],
              [-35.140154449294215, -7.968647314001271],
              [-35.140202754449561, -7.968590025278375],
              [-35.140244703920722, -7.968531142267012],
              [-35.140282230062439, -7.968476078690952],
              [-35.140329887324711, -7.968398816005105],
              [-35.14036758307784, -7.968306599203756],
              [-35.140383065449299, -7.968214858702381],
              [-35.140408528113745, -7.968124241793567],
              [-35.140398248132314, -7.968030555258779],
              [-35.140401724815796, -7.967966718425656],
              [-35.140392313049858, -7.967900507494933],
              [-35.140392687535943, -7.967850426724047],
              [-35.140375924200953, -7.967695666646163],
              [-35.140245216639663, -7.967530466769612],
              [-35.140145948039574, -7.967499883476917],
              [-35.140113296862481, -7.967480978720462],
              [-35.140036958547938, -7.967448288307645],
              [-35.13999662542254, -7.967434304532742],
              [-35.139983580440365, -7.967262169346816],
              [-35.140012308095372, -7.967101841021671],
              [-35.139947968316903, -7.966935123174207],
              [-35.139838985313965, -7.966814918028118],
              [-35.13971062750479, -7.966752846479563],
              [-35.139559881535995, -7.966727410044921],
              [-35.139558825895897, -7.966716206504502],
              [-35.139571857281339, -7.966676275020303],
              [-35.139586818176532, -7.966641214904812],
              [-35.139608286420689, -7.966619499677214],
              [-35.139626604539956, -7.966602320460019],
              [-35.139638533147703, -7.966594484909125],
              [-35.13969389526342, -7.966551623070066],
              [-35.139755320906531, -7.966471577551342],
              [-35.139786757517371, -7.966343958252907],
              [-35.139794909498846, -7.966272413694035],
              [-35.139793356476908, -7.966217823093451],
              [-35.139783769676804, -7.966152788142003],
              [-35.139755825940426, -7.96606253733449],
              [-35.139703370605396, -7.965984164394519],
              [-35.139685678914574, -7.965964820769611],
              [-35.139650125512425, -7.965910857636708],
              [-35.139609368433419, -7.965867587969883],
              [-35.139570530137796, -7.96580966439774],
              [-35.139513113074877, -7.965753644601504],
              [-35.139490629761632, -7.96573703755123],
              [-35.139457197981152, -7.96570747011241],
              [-35.139396249748394, -7.965669909107042],
              [-35.13929321614085, -7.9656301246046],
              [-35.139191653411736, -7.965611665730425],
              [-35.139086555157171, -7.965628298358622],
              [-35.139010447115609, -7.965657346298566],
              [-35.138937845311368, -7.965680590858269],
              [-35.138851589157774, -7.965728583715726],
              [-35.138789489608627, -7.965818576057338],
              [-35.138771995769631, -7.965872270610877],
              [-35.138753696290301, -7.965910511757326],
              [-35.138721124064993, -7.965994114491219],
              [-35.138711375994134, -7.966055181482489],
              [-35.138691839813006, -7.966117202931681],
              [-35.138683523076224, -7.966209448852204],
              [-35.138682773064431, -7.966239644693569],
              [-35.138679391738421, -7.966252046266612],
              [-35.138665736339036, -7.966294060048956],
              [-35.138650187574228, -7.966338162696377],
              [-35.138630634955817, -7.966397020391228],
              [-35.138625349939659, -7.966409522190345],
              [-35.138609590928056, -7.96641312890075],
              [-35.138523406248595, -7.966422522651641],
              [-35.138436217813897, -7.96646563894263],
              [-35.138396124571855, -7.966497845655682],
              [-35.138353533740847, -7.966538020012846],
              [-35.13830309154897, -7.966620359006241],
              [-35.138272594503697, -7.966754391520061],
              [-35.138099789912651, -7.966968886646756],
              [-35.138058070194511, -7.967054615657156],
              [-35.138007937481042, -7.967074309204034],
              [-35.137974717485839, -7.967050616239575],
              [-35.137945037070438, -7.96702753777656],
              [-35.137936903042714, -7.966980303063277],
              [-35.137950857893344, -7.966926084393442],
              [-35.137950762182193, -7.966907644271643],
              [-35.137969484984204, -7.966881061922226],
              [-35.137998398268479, -7.966826223725676],
              [-35.138016823238026, -7.966794671174961],
              [-35.138047379441758, -7.966741903591166],
              [-35.138064960779715, -7.966687575812617],
              [-35.138073114675635, -7.966651285304771],
              [-35.138083035147254, -7.966605946166891],
              [-35.138082782267652, -7.966557224479712],
              [-35.138078591802831, -7.966518556972193],
              [-35.138074510657262, -7.966500950970905],
              [-35.138069663294822, -7.966475484544497],
              [-35.138059836957034, -7.966434134246999],
              [-35.138049079362695, -7.966405534472006],
              [-35.138039803984384, -7.966382983524845],
              [-35.138022893344157, -7.966339409888744],
              [-35.13798921314195, -7.966296917088851],
              [-35.137961491969001, -7.966266958471016],
              [-35.1379380065598, -7.966249452548973],
              [-35.137932266876689, -7.966244239230976],
              [-35.137915791992249, -7.966232211245645],
              [-35.137884658993876, -7.966208778674313],
              [-35.137807780720088, -7.966176813683814],
              [-35.137778384934073, -7.966173620655248],
              [-35.137775025765556, -7.966172824426788],
              [-35.137720624228493, -7.966156201070835],
              [-35.137648307041481, -7.966164438376656],
              [-35.137646042064901, -7.96616481164513],
              [-35.137643228824196, -7.966164374170151],
              [-35.137601756487889, -7.966157989186511],
              [-35.137526987808826, -7.966165606341487],
              [-35.137460180006471, -7.966187103298824],
              [-35.137436652088461, -7.966196354528083],
              [-35.137412391734635, -7.966204253602529],
              [-35.137358444179249, -7.966222701196775],
              [-35.137325634965684, -7.966243209283685],
              [-35.137323548052464, -7.966242948850986],
              [-35.137290633312809, -7.966243118557189],
              [-35.137275762738611, -7.966243195232109],
              [-35.13726759502638, -7.96624188141522],
              [-35.137327525848335, -7.966205685523887],
              [-35.137367235682348, -7.966186949769281],
              [-35.137535705658159, -7.966115663254044],
              [-35.137595121148316, -7.966067537830233],
              [-35.137628927585844, -7.966012041654403],
              [-35.137640778379762, -7.965989200964652],
              [-35.137653338623615, -7.965963283176712],
              [-35.137666612989534, -7.965935192223082],
              [-35.137726467590767, -7.965901798933452],
              [-35.137732700180273, -7.965897156641721],
              [-35.137761069453596, -7.965894750471266],
              [-35.137837270553668, -7.965883600478374],
              [-35.137925238177047, -7.965868322015051],
              [-35.137940271783584, -7.965864719075775],
              [-35.137963002827213, -7.965859178137698],
              [-35.138014221159082, -7.965839027051207],
              [-35.138042770033657, -7.965818812093449],
              [-35.138059578649688, -7.965807787582595],
              [-35.138077013535877, -7.965795132736038],
              [-35.138100862828821, -7.96577792505019],
              [-35.138162550331607, -7.965713335943442],
              [-35.138188347461679, -7.965634649507609],
              [-35.138192235791429, -7.965615104101422],
              [-35.138195966691327, -7.965600169665239],
              [-35.13819854369914, -7.965590032110081],
              [-35.138207412654062, -7.965551749225519],
              [-35.138207237671651, -7.96551803273427],
              [-35.13820710725863, -7.965492903556817],
              [-35.138202619336241, -7.965466802512939],
              [-35.138200747213347, -7.965455512774629],
              [-35.138199902064628, -7.965449912633905],
              [-35.138198591819695, -7.965442055014971],
              [-35.13819718104034, -7.96543229961634],
              [-35.138196436417232, -7.965428597255301],
              [-35.138193455700794, -7.96539588958606],
              [-35.138166476357597, -7.965316661791931],
              [-35.138133629214444, -7.965277418915708],
              [-35.138076591646715, -7.965207114460843],
              [-35.137994610182808, -7.965152757775436],
              [-35.138001674450862, -7.965133647964268],
              [-35.138006317262317, -7.965119703168033],
              [-35.138009974852032, -7.965108113724906],
              [-35.138012202296025, -7.965100509043731],
              [-35.138017978840075, -7.965077880470039],
              [-35.138021325944592, -7.965058880223881],
              [-35.13802273732697, -7.965051279751331],
              [-35.138044277206113, -7.965008411767283],
              [-35.138080077701595, -7.964917831988413],
              [-35.138083339581364, -7.964864934004466],
              [-35.138084050905604, -7.964862218485042],
              [-35.138088022871216, -7.964841316718503],
              [-35.138095755771033, -7.964811265652143],
              [-35.1380954785648, -7.964757843542858],
              [-35.138088860752944, -7.96470571966805],
              [-35.138084357697132, -7.964694172310975],
              [-35.138083892603412, -7.964691914832194],
              [-35.138040576432992, -7.964574805290413],
              [-35.138034804413508, -7.964563354870682],
              [-35.137989415290434, -7.964518572130356],
              [-35.137969377482221, -7.96450140998373],
              [-35.137967374685545, -7.964499883591961],
              [-35.137942592288326, -7.964477051005102],
              [-35.137930586297223, -7.964469971694663],
              [-35.137778800886707, -7.964453760032389],
              [-35.137757501784876, -7.964420694818291],
              [-35.137766325604865, -7.964391180496937],
              [-35.13817865216928, -7.964325506591355],
              [-35.138325683494003, -7.964281810664199],
              [-35.13845386938916, -7.964275997044946],
              [-35.138461667656514, -7.964293493478869],
              [-35.138486643943494, -7.964336211956741],
              [-35.138518608676335, -7.964380159920595],
              [-35.13854124118393, -7.96440806567709],
              [-35.13855366241738, -7.96442526707798],
              [-35.13856872491629, -7.964444714739945],
              [-35.138586067864615, -7.964466772093497],
              [-35.138599198545897, -7.964480896394578],
              [-35.138626932726844, -7.964513385984643],
              [-35.138641222458865, -7.964523707718406],
              [-35.138662891358244, -7.964558126879962],
              [-35.1387067678235, -7.964590985175132],
              [-35.138730800943179, -7.964609211411979],
              [-35.138757985118865, -7.96462308242819],
              [-35.13876262356311, -7.964625770349694],
              [-35.13876954620212, -7.964631791118384],
              [-35.138798966140904, -7.964657130777439],
              [-35.138802333272857, -7.96465946367803],
              [-35.138806080459609, -7.964665139239574],
              [-35.138856537071113, -7.964707907027537],
              [-35.138892564950098, -7.964731043105162],
              [-35.138934970027982, -7.964759931564214],
              [-35.13897279214229, -7.964779352184674],
              [-35.139006991069763, -7.964799514642626],
              [-35.139057532867056, -7.964823750957723],
              [-35.139099040244645, -7.964836915280647],
              [-35.139147337360008, -7.964847965487833],
              [-35.139208066178433, -7.964860759435574],
              [-35.139249346219344, -7.964865066200925],
              [-35.139277364417552, -7.964864921634313],
              [-35.139283624201632, -7.964865522095816],
              [-35.13929661636169, -7.964870426788128],
              [-35.139367947440746, -7.964881900475674],
              [-35.139425301709529, -7.964873468968015],
              [-35.139448312199427, -7.964869372839872],
              [-35.139457188837348, -7.964867519138736],
              [-35.139549405563002, -7.964849868203866],
              [-35.139588350690332, -7.964841079688759],
              [-35.13965652758786, -7.964821383308054],
              [-35.139776986763643, -7.964759473787225],
              [-35.139821186231728, -7.96464498633027],
              [-35.139833635512623, -7.964597735861076],
              [-35.139840373827795, -7.96453333980654],
              [-35.139838747023532, -7.964499450053104],
              [-35.139838571461667, -7.964465643204016],
              [-35.139813669525644, -7.964367421904331],
              [-35.139771604234099, -7.964316656176234],
              [-35.139744320080872, -7.964283531597675],
              [-35.139724977667719, -7.964243134432269],
              [-35.139706571084567, -7.964208336921168],
              [-35.139694632379069, -7.964179291323115],
              [-35.13967210006102, -7.964118300536406],
              [-35.13963289947484, -7.964025486289961],
              [-35.139626375758986, -7.964008977662491],
              [-35.139620648867314, -7.963988758715221],
              [-35.139600085014855, -7.963940141881627],
              [-35.139573836044967, -7.96387935105007],
              [-35.139552221297336, -7.963820434598378],
              [-35.139523868560673, -7.963756129205551],
              [-35.139521299658469, -7.963750357176128],
              [-35.139519264018624, -7.963742503308994],
              [-35.139505521929138, -7.963680472791824],
              [-35.139450207777038, -7.963522928380871],
              [-35.139427462201155, -7.963316040992759],
              [-35.139415986596198, -7.963166586735311],
              [-35.139439600899536, -7.962981878144773],
              [-35.139432724682138, -7.962845055462114],
              [-35.139316583410476, -7.962638197873702],
              [-35.139182980163469, -7.96252607396563],
              [-35.139005660340025, -7.962464254356574],
              [-35.138751990229252, -7.962419641901861],
              [-35.138675264511939, -7.962381981220895],
              [-35.138629525614554, -7.962339640981406],
              [-35.138520528516842, -7.962268790871581],
              [-35.138465729666855, -7.96224538990252],
              [-35.138430294400486, -7.962231561368331],
              [-35.138379946986895, -7.962209764546041],
              [-35.138328528377251, -7.962191227467666],
              [-35.138275512995229, -7.962162031982229],
              [-35.138172226828438, -7.962108327388144],
              [-35.138129604013635, -7.962089835325624],
              [-35.138073064118373, -7.962062917855476],
              [-35.137979631753403, -7.962055896700901],
              [-35.137453695210283, -7.962069997331961],
              [-35.137411254743796, -7.962086667978234],
              [-35.137339966373794, -7.962118312102656],
              [-35.137271458175569, -7.962161512476891],
              [-35.137212206403333, -7.962223648137588],
              [-35.137189293745017, -7.962264082474014],
              [-35.136955454619752, -7.962791387633324],
              [-35.136938620474638, -7.962745011236174],
              [-35.137106050748692, -7.962316036711329],
              [-35.13712080998824, -7.962189588141172],
              [-35.137094798867054, -7.962122106547008],
              [-35.137058779732769, -7.962065614344933],
              [-35.136818375468174, -7.961833814084982],
              [-35.136764653546969, -7.96180823781681],
              [-35.136529596915373, -7.961645923713889],
              [-35.136497298064917, -7.961607400919441],
              [-35.136463204028068, -7.961572412784935],
              [-35.136423791445473, -7.961525971834809],
              [-35.136370781168729, -7.961480233692659],
              [-35.136227027690857, -7.961403957324571],
              [-35.136045074654263, -7.961410318107609],
              [-35.135848956328786, -7.961447847738893],
              [-35.135556710304158, -7.961535680048593],
              [-35.135373645457832, -7.961519989824362],
              [-35.135200537808544, -7.961500994028895],
              [-35.135127158443211, -7.961461597820049],
              [-35.135103666263994, -7.961425199038715],
              [-35.135100087550633, -7.961382008484608],
              [-35.135213385444928, -7.96140800148873],
              [-35.135393146851001, -7.961433652290253],
              [-35.135509640165424, -7.961446340649721],
              [-35.13565576294566, -7.961402379343794],
              [-35.135808595974368, -7.961358473834698],
              [-35.13617088637946, -7.961313941587305],
              [-35.13619912094471, -7.961285592847911],
              [-35.136435514638123, -7.961076104634895],
              [-35.136476935226341, -7.961020117423289],
              [-35.136517256523184, -7.960926983414801],
              [-35.13651901761358, -7.960846884139987],
              [-35.136506186999959, -7.960698159632604],
              [-35.136491932701944, -7.960624651311935],
              [-35.136447182305254, -7.960545514746904],
              [-35.136201980318894, -7.96040250667726],
              [-35.136199860179111, -7.960395828340254],
              [-35.135930007935251, -7.960255387666886],
              [-35.135860944578376, -7.960208918495746],
              [-35.135609529272749, -7.960091704611605],
              [-35.135653797473971, -7.960060290334431],
              [-35.135881637910416, -7.960142642591898],
              [-35.135946414926018, -7.960149359909105],
              [-35.135956039841091, -7.960151931809762],
              [-35.136002267769747, -7.960166247429537],
              [-35.136021342344307, -7.960172567281397],
              [-35.13606870302938, -7.960213001292663],
              [-35.136541749423451, -7.960334135555001],
              [-35.136626782904713, -7.960295279612854],
              [-35.136823543106054, -7.960136797626545],
              [-35.136909266597343, -7.960003655853214],
              [-35.13701616443069, -7.959792122738331],
              [-35.137067826382228, -7.959787698431488],
              [-35.137045168412278, -7.959894752701954],
              [-35.137023698582432, -7.95996862581318],
              [-35.137007593675492, -7.960027917650486],
              [-35.136992420342132, -7.960091995641278],
              [-35.136988373321785, -7.960098434548487],
              [-35.136951066205377, -7.960143281973521],
              [-35.136883763182233, -7.960226521097558],
              [-35.136811754250594, -7.960329038637021],
              [-35.136765934609343, -7.960376008995907],
              [-35.136748021870254, -7.960401411935877],
              [-35.136677032017211, -7.960560602046291],
              [-35.136667626453224, -7.960950254016881],
              [-35.136667368950732, -7.960953057592024],
              [-35.136676216681643, -7.960998119249357],
              [-35.136678998942656, -7.961027573765557],
              [-35.13668468929928, -7.961058278837335],
              [-35.136762753760429, -7.961354282710613],
              [-35.136789767793871, -7.961387860968241],
              [-35.136801121379307, -7.961409045357006],
              [-35.13683891099199, -7.961474748801806],
              [-35.136865477300333, -7.961509414104186],
              [-35.136926677731417, -7.961630770786194],
              [-35.136955001296897, -7.961689562545974],
              [-35.137016305569723, -7.961778466776905],
              [-35.137099086537305, -7.96184728298299],
              [-35.137146252593915, -7.961885096335474],
              [-35.137263387057523, -7.961951475598495],
              [-35.137355756444435, -7.961980830013278],
              [-35.137451719229759, -7.961986211159433],
              [-35.137454073460468, -7.96198556626257],
              [-35.137563713371726, -7.96198807464465],
              [-35.138033022688759, -7.961930243376676],
              [-35.138086735173204, -7.961919028672449],
              [-35.138130494224207, -7.961911842663292],
              [-35.138164018456614, -7.961906878889741],
              [-35.138174269224876, -7.961907729997765],
              [-35.138222793918167, -7.961910282086427],
              [-35.138357382021709, -7.961915192706775],
              [-35.138409319006705, -7.96191130912673],
              [-35.138420924237778, -7.961911068498939],
              [-35.138583426992859, -7.961965823623063],
              [-35.138663715768118, -7.96199117223542],
              [-35.138885920892008, -7.962000783385355],
              [-35.13898797620174, -7.961992031112565],
              [-35.139098856571287, -7.96190160651818],
              [-35.139204305883041, -7.961813017813373],
              [-35.139228415540686, -7.9616887809937],
              [-35.139237328045724, -7.961658904644096],
              [-35.139242861110503, -7.96164179142934],
              [-35.139255594550754, -7.961614336036875],
              [-35.139266794511975, -7.961588425272629],
              [-35.139431290553922, -7.961258538689451],
              [-35.139565597007888, -7.961121891723622],
              [-35.139575247881943, -7.961111988877025],
              [-35.139595911115009, -7.961092447353309],
              [-35.13961470305739, -7.961079243135323],
              [-35.139647797047687, -7.961043818339372],
              [-35.139700232164103, -7.960996180839182],
              [-35.139766142949853, -7.960854462947556],
              [-35.139777803162623, -7.960777476596064],
              [-35.139798391661692, -7.960691133492617],
              [-35.139779980998853, -7.960603093268106],
              [-35.139685261634689, -7.960295605820639],
              [-35.139652641748754, -7.960142825593209],
              [-35.139610974071587, -7.959906476562658],
              [-35.139557491183744, -7.959629872248206],
              [-35.139609162939287, -7.959557470482456],
              [-35.139683971209095, -7.959487842031799],
              [-35.139738634524278, -7.959432780694991],
              [-35.139730498497769, -7.959542381489449],
              [-35.139717787553636, -7.959888660204675],
              [-35.139740077778143, -7.960042849629606],
              [-35.139967924155272, -7.960248588660712],
              [-35.140130734743281, -7.960327838772543],
              [-35.140234917145833, -7.960344657128434],
              [-35.140324441739942, -7.960332624673224],
              [-35.140431285019616, -7.960285610351952],
              [-35.140860398405614, -7.959908889484531],
              [-35.141183416488033, -7.959566162095988],
              [-35.141405309097749, -7.959214517407183],
              [-35.141454660383872, -7.958958671155467],
              [-35.141529144316436, -7.9586733975056],
              [-35.141584078464575, -7.958280599967416],
              [-35.141702315310965, -7.957887475717062],
              [-35.141858465523278, -7.957367173956448],
              [-35.142034432929201, -7.957233681878348],
              [-35.142204823828557, -7.957131508443847],
              [-35.142292987069389, -7.957042421168238],
              [-35.142313543117801, -7.95692211107466],
              [-35.142348711151854, -7.956913871971106],
              [-35.142372660461753, -7.956951663896254],
              [-35.142385763309967, -7.957035326458043],
              [-35.142394665762673, -7.957117866697431],
              [-35.14249592286405, -7.957205528031368],
              [-35.142540330617273, -7.957212109741493],
              [-35.142629216902151, -7.957238894752968],
              [-35.142636786459477, -7.957381886261039],
              [-35.14257181236615, -7.958003895289242],
              [-35.142602956386568, -7.958085466246015],
              [-35.142582759873648, -7.958140058379814],
              [-35.14251804933356, -7.958167636437675],
              [-35.142438271016211, -7.958200219984452],
              [-35.142312093095576, -7.95828317273654],
              [-35.14226824159217, -7.958372031300073],
              [-35.142248131364198, -7.958545469629989],
              [-35.142217730364123, -7.958606925425714],
              [-35.142152789740642, -7.958590233253254],
              [-35.142084188200656, -7.958525883085748],
              [-35.141967942382635, -7.958489022796703],
              [-35.141875801742827, -7.958492903929054],
              [-35.141749947984131, -7.95852664778576],
              [-35.141661366934521, -7.958609797547027],
              [-35.141586478536702, -7.958742741166955],
              [-35.141607078278525, -7.959036527202897],
              [-35.141586330100303, -7.959248475674662],
              [-35.141546904950303, -7.959404719706792],
              [-35.141255655832332, -7.95967030096158],
              [-35.14125568111497, -7.959670285744239],
              [-35.141082993284819, -7.959854136484722],
              [-35.140930872270253, -7.96008723673452],
              [-35.140895253554355, -7.960160278927325],
              [-35.140892464753129, -7.96026930969988],
              [-35.140923854726751, -7.960412152604865],
              [-35.140985074790137, -7.960519677888175],
              [-35.141068517107307, -7.960663427240095],
              [-35.141077213034443, -7.960714093945698],
              [-35.140946367133608, -7.960696328715679],
              [-35.140699936430572, -7.960683589294861],
              [-35.140353951831408, -7.960812018094846],
              [-35.140030115103961, -7.961014817931458],
              [-35.139983908082812, -7.961091892118075],
              [-35.139932958849641, -7.961163747863368],
              [-35.139766768035443, -7.961376672050855],
              [-35.1396043633994, -7.961567881874255],
              [-35.139543764095478, -7.961719696626584],
              [-35.139536921258021, -7.961798918019578],
              [-35.139533995546664, -7.961829215459274],
              [-35.139532657855732, -7.961851007581614],
              [-35.139529860710624, -7.961906072612384],
              [-35.139535162557515, -7.961966700366526],
              [-35.139544566762083, -7.962031555514185],
              [-35.139570976133321, -7.962175689827376],
              [-35.139609737299743, -7.962393522778449],
              [-35.139691825547807, -7.962573347045443],
              [-35.139683594147648, -7.962629615249835],
              [-35.139713495864896, -7.962835109752739],
              [-35.139790817753038, -7.96298756884475],
              [-35.139890937659139, -7.963060091434344],
              [-35.139869148782033, -7.963247050453396],
              [-35.139926486241954, -7.963357669305671],
              [-35.139942022758362, -7.963381091838321],
              [-35.139956566949849, -7.963422960094975],
              [-35.140005885462422, -7.963491044230909],
              [-35.140011808340169, -7.963496618156905],
              [-35.140018748253532, -7.963523429670289],
              [-35.140040329773186, -7.963994999646786],
              [-35.140053751261455, -7.964012738194904],
              [-35.140070397970135, -7.964040403557877],
              [-35.140080805173667, -7.964053909106381],
              [-35.140082638832759, -7.964057786628641],
              [-35.140126055161232, -7.964141720318121],
              [-35.140157548268654, -7.964199591382538],
              [-35.140215128100039, -7.964269531119728],
              [-35.140399000099677, -7.964405530450909],
              [-35.140626111550212, -7.964451724966718],
              [-35.140726652627087, -7.964448132444968],
              [-35.140825601050146, -7.964434695069044],
              [-35.140913537595836, -7.964396094331123],
              [-35.140991523076096, -7.964344528084391],
              [-35.14116118016819, -7.964135562762445],
              [-35.141464718101354, -7.963934945496311],
              [-35.141512144581341, -7.963883175432619],
              [-35.141675688551757, -7.963614671301579],
              [-35.141722517823865, -7.963622474588428],
              [-35.141713691482053, -7.963686339004578],
              [-35.141704111089609, -7.963727247006237],
              [-35.141682791072313, -7.963812328384276],
              [-35.141670471215782, -7.963849544333709],
              [-35.141667137906843, -7.963871165943167],
              [-35.141630507224676, -7.963923965009578],
              [-35.141554594706108, -7.964025418648219],
              [-35.141508091782079, -7.964237454215739],
              [-35.141530436912142, -7.96429727065856],
              [-35.141633975269407, -7.964364441698402],
              [-35.141669612784916, -7.964417138673464],
              [-35.141640582685845, -7.964519163725759],
              [-35.141599278756821, -7.964545139641634],
              [-35.141556471424579, -7.964578445295728],
              [-35.14154978413422, -7.964582818794038],
              [-35.141509968563241, -7.964563679925407],
              [-35.141376655851253, -7.964525137054484],
              [-35.141243136377824, -7.964551498788527],
              [-35.141186017153437, -7.964570234346628],
              [-35.141075903645564, -7.964598825428696],
              [-35.140966717513983, -7.96463138908448],
              [-35.140847725791538, -7.964731347605603],
              [-35.140614356739505, -7.965121341481517],
              [-35.140572388577212, -7.96526383992246],
              [-35.140630223572181, -7.965435201417262],
              [-35.140687652869012, -7.965511017471418],
              [-35.140740180637913, -7.96558586447443],
              [-35.140783806458629, -7.965622791544569],
              [-35.140784446339602, -7.965623782580413],
              [-35.140791609551307, -7.965641191831097],
              [-35.140791976942275, -7.965642093879535],
              [-35.140791805929197, -7.965644083452335],
              [-35.140786009776157, -7.965697808014994],
              [-35.140797677419663, -7.965831713140613],
              [-35.140874689944226, -7.966011653442272],
              [-35.140951497271118, -7.966134645925018],
              [-35.141091843635529, -7.966217897986663],
              [-35.141189877713138, -7.966237820891113],
              [-35.141261065229116, -7.966204187832131],
              [-35.141314541237456, -7.966147414744291],
              [-35.141413353213707, -7.96598554931638],
              [-35.141473526356457, -7.965908854735578],
              [-35.141652845376463, -7.965814188814702],
              [-35.141800322306395, -7.965734150534536],
              [-35.141894488264924, -7.965742613121964],
              [-35.142013571319978, -7.965817206412744],
              [-35.142342658505903, -7.96578676029736],
              [-35.142406097175311, -7.965745212381379],
              [-35.142617455824748, -7.965569386714025],
              [-35.142631170956491, -7.965538943139533],
              [-35.142644801575393, -7.965527121361765],
              [-35.142740946353747, -7.965410467012322],
              [-35.14278844797834, -7.965233680287686],
              [-35.142754429819732, -7.965073947554681],
              [-35.142698545938735, -7.964981491227606],
              [-35.142700386473663, -7.964916939758043],
              [-35.142706360588392, -7.964914920194104],
              [-35.142665868293257, -7.964294839715142],
              [-35.142722490066603, -7.964180468751042],
              [-35.142860712282058, -7.964082037569935],
              [-35.143013988581494, -7.963670129517361],
              [-35.143068252635317, -7.963695250033766],
              [-35.143101842285184, -7.963737742811732],
              [-35.143148910468312, -7.963965836976967],
              [-35.143096408697922, -7.964105226045921],
              [-35.14304177403735, -7.964270388667929],
              [-35.143053127080798, -7.96443087128722],
              [-35.143092032728418, -7.964606307416417],
              [-35.14315799985134, -7.964737129385851],
              [-35.143255026999533, -7.964825034005258],
              [-35.143255454395977, -7.96490720071751],
              [-35.143246528388751, -7.965004150149713],
              [-35.14323394619656, -7.96518265464506],
              [-35.143257089045967, -7.965273743417457],
              [-35.143310416206695, -7.965327885424155],
              [-35.143420590112051, -7.96538064878719],
              [-35.143524988874717, -7.965386527055005],
              [-35.143581569232516, -7.965386234501185],
              [-35.143634540983911, -7.965389486008754],
              [-35.143661267707408, -7.965402545452626],
              [-35.1436717785163, -7.965435937195248],
              [-35.143669537167732, -7.965458276304083],
              [-35.143667295348948, -7.96548052503001],
              [-35.143664955331481, -7.965501327935119],
              [-35.143663508886135, -7.965519595176036],
              [-35.143661147703384, -7.965536330424121],
              [-35.143654879565624, -7.965551549170526],
              [-35.14364940631247, -7.965562696039972],
              [-35.143641831112021, -7.965570689947272],
              [-35.14358820919454, -7.965581904996313],
              [-35.143544816945592, -7.965589903311418],
              [-35.143334867309889, -7.965670445896884],
              [-35.143260155003013, -7.965775961377588],
              [-35.143201594993656, -7.965918636031777],
              [-35.14319333322269, -7.966090971344696],
              [-35.143158925299176, -7.966135261927376],
              [-35.143104941606303, -7.966164105794577],
              [-35.143049936043852, -7.96618825440166],
              [-35.142999445436672, -7.966226210083511],
              [-35.142810179027173, -7.966465469388655],
              [-35.142767351045947, -7.966512244145132],
              [-35.142728790380978, -7.966560081573251],
              [-35.142671711054284, -7.966603946961793],
              [-35.14260905547205, -7.966639163256774],
              [-35.142457197135414, -7.966887991782665],
              [-35.142478978967077, -7.966978906849225],
              [-35.142746925718185, -7.967239938111183],
              [-35.142832473105081, -7.967317235441051],
              [-35.143120450425265, -7.96750675083838],
              [-35.143211474768378, -7.967538731908969],
              [-35.143308949470686, -7.96753822785518],
              [-35.143434106039031, -7.967455502113665],
              [-35.143513710282662, -7.967366503490227],
              [-35.143550074264503, -7.967279897973159],
              [-35.14359279835088, -7.96716089820152],
              [-35.14360311675771, -7.967070178828446],
              [-35.14366145944733, -7.966972883449874],
              [-35.143718066241121, -7.966960206596711],
              [-35.143845893155614, -7.966972471926732],
              [-35.143993090893154, -7.966978218994446],
              [-35.144068905627549, -7.966979996303966],
              [-35.144114005537702, -7.967003898425243],
              [-35.144123490344185, -7.967031781357447],
              [-35.144136144443038, -7.967111082529245],
              [-35.144139050655809, -7.967164219641301],
              [-35.144131487013041, -7.967209275377787],
              [-35.144091703301882, -7.967283424125391],
              [-35.144024478409037, -7.967503431243504],
              [-35.14400933100282, -7.967607102045728],
              [-35.144012327779329, -7.967764735085962],
              [-35.144033904579615, -7.967955537286358],
              [-35.144145983748359, -7.968095430968437],
              [-35.144257384204678, -7.968192029011153],
              [-35.144396810253326, -7.968202787739981],
              [-35.144450434483311, -7.968209380270498],
              [-35.144567587450794, -7.968209135635886],
              [-35.144620446277578, -7.968208048548872],
              [-35.144837351731901, -7.968122316543191],
              [-35.144917480329596, -7.968064410786343],
              [-35.144937248236332, -7.968064489270068],
              [-35.144969472394976, -7.968071011699764],
              [-35.144977203708649, -7.968075581821134],
              [-35.145006640221567, -7.968103994201807],
              [-35.145024744358288, -7.968132826822618],
              [-35.145027017133636, -7.968203594147299],
              [-35.145029091895587, -7.96825375249173],
              [-35.145029134354914, -7.968314135958971],
              [-35.145068989206734, -7.968445182836532],
              [-35.145146429769994, -7.968619786193643],
              [-35.145194858895898, -7.968673501165253],
              [-35.145259719458657, -7.968696035276666],
              [-35.14532435649793, -7.968727881182625],
              [-35.145566468529566, -7.968763689557567],
              [-35.145612563559801, -7.968769688137538],
              [-35.14570817897404, -7.968777780562998],
              [-35.145863046397167, -7.968741363154552],
              [-35.145952326165101, -7.96866469811247],
              [-35.145983732897882, -7.968636151541903],
              [-35.146017036490996, -7.968588793058474],
              [-35.146046645035355, -7.968545792643716],
              [-35.146061321315436, -7.968491027866047],
              [-35.146066108201104, -7.968487387287294],
              [-35.146104800420879, -7.968325651623988],
              [-35.146116817487197, -7.968160799886819],
              [-35.146093468581299, -7.968047746654523],
              [-35.146014452429633, -7.967936247184412],
              [-35.145911434972653, -7.967864916819317],
              [-35.145883331447386, -7.967848700893768],
              [-35.145833069549582, -7.967826091273847],
              [-35.145830430374659, -7.967806850867468],
              [-35.145921193707196, -7.967771307782685],
              [-35.145949905351607, -7.967765012284982],
              [-35.145952897598839, -7.967764996790991],
              [-35.14620195490469, -7.967672046947554],
              [-35.14625632186916, -7.967612376112838],
              [-35.146637398403954, -7.967153005610026],
              [-35.146653898154902, -7.96697836807456],
              [-35.14662149860871, -7.966816367860462],
              [-35.146529368892374, -7.966607038948734],
              [-35.146411115567084, -7.966482997056366],
              [-35.146300732485948, -7.966424992858272],
              [-35.146275834675052, -7.966414907174107],
              [-35.14623550490731, -7.9664015567874],
              [-35.146198918657483, -7.966393158715474],
              [-35.14599408166842, -7.966323620957405],
              [-35.145947573649842, -7.966290506078055],
              [-35.145923936132199, -7.966261250166306],
              [-35.14590334008151, -7.966224023774026],
              [-35.145900984520132, -7.966189595585488],
              [-35.145915564618214, -7.965855240505549],
              [-35.145847401906764, -7.965546805030464],
              [-35.145837600778847, -7.965510426686931],
              [-35.145831634400004, -7.965444288646134],
              [-35.145837874863844, -7.965441182928894],
              [-35.145894486030329, -7.965429409769514],
              [-35.145940083977692, -7.965427094673047],
              [-35.146149461024848, -7.965480518822836],
              [-35.146240193072806, -7.965543506183806],
              [-35.146280430877333, -7.965574032059314],
              [-35.146316611445648, -7.965609008272625],
              [-35.146487224174493, -7.96586249552165],
              [-35.14649326395287, -7.965890486598902],
              [-35.146585108896041, -7.966080020608032],
              [-35.146604194859464, -7.966105865044064],
              [-35.146636420496833, -7.966130104730768],
              [-35.146662010941611, -7.966151305344806],
              [-35.146687511657824, -7.966172687218745],
              [-35.146706630846523, -7.966187503324377],
              [-35.14685230221049, -7.966335448126629],
              [-35.146983511274712, -7.966405185989172],
              [-35.147075137157188, -7.96643083545829],
              [-35.147157513353896, -7.966421550098114],
              [-35.147376265155344, -7.966325050618798],
              [-35.147649291243994, -7.96613380750684],
              [-35.147700236161796, -7.966043962525332],
              [-35.147730915547989, -7.96591074272775],
              [-35.147739730566478, -7.96575793018477],
              [-35.147755676616633, -7.965651091548496],
              [-35.147743336604762, -7.965562568774212],
              [-35.147735734367615, -7.965547964232215],
              [-35.147732348551386, -7.965524659967775],
              [-35.147723478512994, -7.965492977421707],
              [-35.147716226924906, -7.965458665040856],
              [-35.147709093822961, -7.965429685320305],
              [-35.147641266093871, -7.96513354239523],
              [-35.147637831245781, -7.965066035417384],
              [-35.147646754399268, -7.965038328444906],
              [-35.147667226475654, -7.964982177744156],
              [-35.147765680017251, -7.964682551992742],
              [-35.147800048503655, -7.964213316381845],
              [-35.147798938360999, -7.964087312060346],
              [-35.14777241676552, -7.963800446575368],
              [-35.147770206377366, -7.963706899604154],
              [-35.14739812727862, -7.962951410084665],
              [-35.147385965827702, -7.96291450165115],
              [-35.147360680140544, -7.962864734752761],
              [-35.147292151572664, -7.962747124594919],
              [-35.147260816725741, -7.962702360707299],
              [-35.14718738105325, -7.96258269684935],
              [-35.147119516654804, -7.962522935771788],
              [-35.14700598930721, -7.962418123352835],
              [-35.146913841059821, -7.962396453689801],
              [-35.146655529127926, -7.96238323727276],
              [-35.146549789940607, -7.962415784294492],
              [-35.146400281305965, -7.962557935384577],
              [-35.146366030499053, -7.962562632391036],
              [-35.146333920541039, -7.96256053869921],
              [-35.146290149088728, -7.962565284975308],
              [-35.146252226472093, -7.962543967290332],
              [-35.146239660250345, -7.962516371551875],
              [-35.146241504261241, -7.962469989528938],
              [-35.146498589276192, -7.96217794970099],
              [-35.146505701794851, -7.961959248150662],
              [-35.146419078301726, -7.96181443219823],
              [-35.146385307404387, -7.96178938684765],
              [-35.146352341282402, -7.961762167856825],
              [-35.146316441617593, -7.961728817196933],
              [-35.146270627271413, -7.961706907573605],
              [-35.146237180190681, -7.961691803946116],
              [-35.146181583556817, -7.96167193361596],
              [-35.145882570506743, -7.961573865766058],
              [-35.145828397366188, -7.96154874511807],
              [-35.145818178692501, -7.961501792733663],
              [-35.145834654471457, -7.961461753029841],
              [-35.146111048851175, -7.961430131172063],
              [-35.146258753911468, -7.961359582195537],
              [-35.146267520909525, -7.961354113144851],
              [-35.146326791286292, -7.961313309625017],
              [-35.146375160697694, -7.961268675531117],
              [-35.146414334073661, -7.961269015192433],
              [-35.146451677388036, -7.961301002449664],
              [-35.146484396584491, -7.961350459816384],
              [-35.14655012659393, -7.961435723420794],
              [-35.146628302324444, -7.96152544233176],
              [-35.146728956254186, -7.961595971603584],
              [-35.146758384078829, -7.961605401128082],
              [-35.146991035576676, -7.961740692826046],
              [-35.147085062690131, -7.961827165742701],
              [-35.147102148705365, -7.961834670438863],
              [-35.147151366208789, -7.961883228758602],
              [-35.147253995043592, -7.961967306826832],
              [-35.147375493739446, -7.962053185440662],
              [-35.147447130775149, -7.962088701197156],
              [-35.147467525287276, -7.962104685842447],
              [-35.147541885820154, -7.96214082023477],
              [-35.14769847660888, -7.962174630501562],
              [-35.14773682785998, -7.962208601064545],
              [-35.147785907218037, -7.962282832058698],
              [-35.147872601662542, -7.962388958322499],
              [-35.147914756021834, -7.962421914817289],
              [-35.148041490148309, -7.962450998244856],
              [-35.148086714671649, -7.962481498162594],
              [-35.148091921900836, -7.962506329703537],
              [-35.14820545218442, -7.962698643693895],
              [-35.14824531411373, -7.962744086462882],
              [-35.148293748729699, -7.962764174342556],
              [-35.148304464191661, -7.96280199416217],
              [-35.148294277854589, -7.962865684716633],
              [-35.148292085062728, -7.962914689934555],
              [-35.148304755080879, -7.962962171846732],
              [-35.148346819454908, -7.963064822954604],
              [-35.148367412426623, -7.963118862622831],
              [-35.148457228037415, -7.963336609787707],
              [-35.148485835597533, -7.96339738751263],
              [-35.148504759739971, -7.963444385031984],
              [-35.1485400425316, -7.96351588510892],
              [-35.148572696479313, -7.963570133440014],
              [-35.148626662928294, -7.963659886787973],
              [-35.148701648089677, -7.963746186609176],
              [-35.148762599129903, -7.963801644202933],
              [-35.148847903163421, -7.963884455511892],
              [-35.148955149627355, -7.963967062684277],
              [-35.149010653725959, -7.964073440573227],
              [-35.149024655852841, -7.964150293746441],
              [-35.149029449246612, -7.964200076289865],
              [-35.148955366182548, -7.964286787028546],
              [-35.148932153647081, -7.964460736032952],
              [-35.148949485842984, -7.964671898219879],
              [-35.148979586823387, -7.964792961273777],
              [-35.149013414526067, -7.964846209097294],
              [-35.149147000454171, -7.965041401590073],
              [-35.149151723182733, -7.965077625303074],
              [-35.149170357480358, -7.965190702642882],
              [-35.149102655369497, -7.965231731134502],
              [-35.149054620070189, -7.96525349403592],
              [-35.148971905923474, -7.965284837724718],
              [-35.148865077369322, -7.965369367978022],
              [-35.148801015164743, -7.965430264363735],
              [-35.148748170355368, -7.965607801959532],
              [-35.148754592612171, -7.965709100955593],
              [-35.14875828335105, -7.965756086982665],
              [-35.148762424950526, -7.965819974453575],
              [-35.148769141878468, -7.965925610854912],
              [-35.148752639941542, -7.965960588687349],
              [-35.148623387501424, -7.966092149930215],
              [-35.148560349127592, -7.96619290500848],
              [-35.14853463335939, -7.966234619790511],
              [-35.148486375840783, -7.966318123296035],
              [-35.148459392814615, -7.966429810029481],
              [-35.148453758473991, -7.966462200477679],
              [-35.148448548433329, -7.966471628517646],
              [-35.14842565514148, -7.966532944287625],
              [-35.148422977838315, -7.96654118406705],
              [-35.148343172764264, -7.966643562747977],
              [-35.148306414377096, -7.966758644745204],
              [-35.148265967536375, -7.96703582331603],
              [-35.148226562944664, -7.967112953323252],
              [-35.148128946409685, -7.967173119661719],
              [-35.148089589880584, -7.967207311987171],
              [-35.148010765876634, -7.967271810249117],
              [-35.147930848052667, -7.967387387105906],
              [-35.147880224453097, -7.967504077677321],
              [-35.147875161621116, -7.967524352298194],
              [-35.147887090759909, -7.967586210764122],
              [-35.147889302120369, -7.967610334661832],
              [-35.147879981389124, -7.967631354503329],
              [-35.147858983227941, -7.967638785291534],
              [-35.14780330274106, -7.967603006394208],
              [-35.147737212970931, -7.967588162591825],
              [-35.147664521443289, -7.967576697572812],
              [-35.147555965940576, -7.967573644287731],
              [-35.14744289238493, -7.967608399325671],
              [-35.147381669011757, -7.967657348843824],
              [-35.1473422141611, -7.967690095319082],
              [-35.147291692177681, -7.967739350958632],
              [-35.147234888303203, -7.967818559757411],
              [-35.147203614031916, -7.967924664282778],
              [-35.147111775526092, -7.968206176973905],
              [-35.147035591970294, -7.968359790550986],
              [-35.147019353844144, -7.968393230297221],
              [-35.147008856654523, -7.968414708209946],
              [-35.147007688254959, -7.968416702945056],
              [-35.14699977160987, -7.968428766445119],
              [-35.146994181387221, -7.968434851847112],
              [-35.14695626533917, -7.968484494138105],
              [-35.146919269489494, -7.968554108880866],
              [-35.14688448447405, -7.968647757138814],
              [-35.146879892198875, -7.968688729699561],
              [-35.146870449899843, -7.968721230295517],
              [-35.146865961697976, -7.96874737759386],
              [-35.146860521990654, -7.968764942335333],
              [-35.146854874537148, -7.968777446049984],
              [-35.146827722294816, -7.96882197048055],
              [-35.146817610874493, -7.968847875729613],
              [-35.146803197240573, -7.968866029328783],
              [-35.146787922096316, -7.968892865281247],
              [-35.146775541669385, -7.968900974543002],
              [-35.146662279346344, -7.969003978412392],
              [-35.146376920685753, -7.96931731814302],
              [-35.146351354580553, -7.969370421836876],
              [-35.146321652575793, -7.969447682335153],
              [-35.146236870314915, -7.969847937099025],
              [-35.146233200971331, -7.969874622535756],
              [-35.146232212508856, -7.969876345155779],
              [-35.146187447662363, -7.969951604599226],
              [-35.146160455429623, -7.969992060434611],
              [-35.146146972041777, -7.970014728949053],
              [-35.146118509698994, -7.970051486216406],
              [-35.146084038590367, -7.97010102025162],
              [-35.146072609133675, -7.970117621675253],
              [-35.145766143626638, -7.970452584548438],
              [-35.145711336849125, -7.970567127292363],
              [-35.145685728944535, -7.97066443420985],
              [-35.145673975171746, -7.970740607417881],
              [-35.145668329574463, -7.970788274667129],
              [-35.145665589788862, -7.97080193845615],
              [-35.145664874749571, -7.970803930842633],
              [-35.14565681295678, -7.97082295547616],
              [-35.145651765789978, -7.970828857271839],
              [-35.145630211353648, -7.970851386787602],
              [-35.145586794186514, -7.970889487018517],
              [-35.145517108175781, -7.970967677748178],
              [-35.145418850644475, -7.971218308759511],
              [-35.145418795300145, -7.97122508864486],
              [-35.145275366037197, -7.971472970558922],
              [-35.145253636321243, -7.971531478069077],
              [-35.145229130069211, -7.971596598778383],
              [-35.145210772022033, -7.971710500795428],
              [-35.145190267931198, -7.971830199189907],
              [-35.145215268317223, -7.971894701947742],
              [-35.145370440118306, -7.972038258704068],
              [-35.145517620780105, -7.972109992961104],
              [-35.145626855677136, -7.972121359272039],
              [-35.145842363128999, -7.972010684587368],
              [-35.146074738592404, -7.971796058948316],
              [-35.146337109636285, -7.971630090952649],
              [-35.146342983245248, -7.971626173548816],
              [-35.146353204051763, -7.971621239283554],
              [-35.146401840363197, -7.97161023032418],
              [-35.147127265079526, -7.97148977160145],
              [-35.147265497594546, -7.97146275029735],
              [-35.147406023494902, -7.971423423392189],
              [-35.147467844963892, -7.971402131383921],
              [-35.147618752656214, -7.97135398232772],
              [-35.147687652377002, -7.971333738334984],
              [-35.147741490662057, -7.971311764519359],
              [-35.147906333316094, -7.971205871400559],
              [-35.147943686355582, -7.97116997188487],
              [-35.148003656287649, -7.971106655939359],
              [-35.148059102164304, -7.971010459828161],
              [-35.148101225703677, -7.97088088680254],
              [-35.148109128318715, -7.970848755762959],
              [-35.148111543043903, -7.970842325231358],
              [-35.148117843107435, -7.970833253105432],
              [-35.148149624377922, -7.970789608543256],
              [-35.1482489703345, -7.970765319649634],
              [-35.148357894166182, -7.970751918818907],
              [-35.148398956417473, -7.970766530595231],
              [-35.148533063954027, -7.970783190897794],
              [-35.148587699603489, -7.970479633906185],
              [-35.149289173570978, -7.965718081033951],
              [-35.149280303784565, -7.963774283397066],
              [-35.148217231628614, -7.959042935100419],
              [-35.147785017262663, -7.956820289859746],
              [-35.147160952300204, -7.953344408999675],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0154000',
        uf: 'PE',
        nome_proje: 'PA LUIZ GONZAGA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '2000.3130',
        capacidade: 50.0,
        num_famili: 48.0,
        fase: 3.0,
        data_de_cr: '06/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '06/12/2005',
        area_calc_: 2001.7571,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.931786441161968, -8.560952260632742],
              [-39.930885180673307, -8.561399856024307],
              [-39.929136860825977, -8.562279897568743],
              [-39.926639541275961, -8.563530368247523],
              [-39.923950718773746, -8.564878326187408],
              [-39.921073115774028, -8.565115550102114],
              [-39.919404724675012, -8.565243970711922],
              [-39.918962394735246, -8.565278305359966],
              [-39.917721441061353, -8.565376941687651],
              [-39.91397220900209, -8.56566682990595],
              [-39.913282152124218, -8.565725891965032],
              [-39.907561771422607, -8.566192664382326],
              [-39.906632956545394, -8.566266384412282],
              [-39.905406858051506, -8.566618727545405],
              [-39.901440027330189, -8.567819035599603],
              [-39.901006659674927, -8.56795788043636],
              [-39.900728279167559, -8.568046710723854],
              [-39.899778449926529, -8.568383650768638],
              [-39.898507929608854, -8.569056694594028],
              [-39.89784398622988, -8.56948422148465],
              [-39.897232893215815, -8.570014638385182],
              [-39.895310451507164, -8.571677721268777],
              [-39.89507457856287, -8.571877512507314],
              [-39.892481853946848, -8.574186086142557],
              [-39.89154088167227, -8.575019516417195],
              [-39.890122154125251, -8.575004340079415],
              [-39.889075087646326, -8.574997346972241],
              [-39.886286633853999, -8.574978344606636],
              [-39.885742518260294, -8.574974528653469],
              [-39.885093268439576, -8.574969867683718],
              [-39.884249593930313, -8.575095707440745],
              [-39.882850100616501, -8.575305119750178],
              [-39.882580502560252, -8.5753455309884],
              [-39.882018238585502, -8.575431741740276],
              [-39.882062214796761, -8.576690964356045],
              [-39.882117863182991, -8.577849587990368],
              [-39.882162664472681, -8.578993222841492],
              [-39.882211939588693, -8.580106458650571],
              [-39.88226159979699, -8.581307513242288],
              [-39.882301317862066, -8.582255893870562],
              [-39.882339838220339, -8.583039581221628],
              [-39.882359904387719, -8.583622980425512],
              [-39.882423555825319, -8.585058068061356],
              [-39.882506483783423, -8.586969924614602],
              [-39.882611761405194, -8.589468792306659],
              [-39.882773114324394, -8.593619916090546],
              [-39.882785019641176, -8.594366763183476],
              [-39.882843926908258, -8.603405180465231],
              [-39.883652959938317, -8.607202449154899],
              [-39.884075692439048, -8.607353511479761],
              [-39.884583399558835, -8.607546795379806],
              [-39.893985695686098, -8.611044704613633],
              [-39.902747295664739, -8.614237273238677],
              [-39.903299680777053, -8.613452386304635],
              [-39.90337268521796, -8.613350919745447],
              [-39.903765035358198, -8.611531390793809],
              [-39.903793726352333, -8.611444137307419],
              [-39.904325375376239, -8.610578081295097],
              [-39.905788396563629, -8.608644878441202],
              [-39.905064624576411, -8.607245365590467],
              [-39.905059682181935, -8.607115773923752],
              [-39.905035239961983, -8.606237007101024],
              [-39.906768332091779, -8.605036017565679],
              [-39.907317837117517, -8.604996555562247],
              [-39.907417023631879, -8.60703759500764],
              [-39.907457696423627, -8.607631883083542],
              [-39.90810366696568, -8.607649532120362],
              [-39.912361196884802, -8.607771953395241],
              [-39.912963888409372, -8.60470798464449],
              [-39.925790021769075, -8.581946492831021],
              [-39.926061450463209, -8.58146288343392],
              [-39.931766350016076, -8.571289317404636],
              [-39.931948762683454, -8.57041376579955],
              [-39.932066318317297, -8.569882319499511],
              [-39.932488530483845, -8.567892583519198],
              [-39.933303260990776, -8.564111068540374],
              [-39.931154729351789, -8.56164566005326],
              [-39.931786441161968, -8.560952260632742],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0016000',
        uf: 'PE',
        nome_proje: 'PA RETIRO SAUDOSO',
        municipio: 'GRAVATA',
        area_hecta: '66.0268',
        capacidade: 12.0,
        num_famili: 9.0,
        fase: 3.0,
        data_de_cr: '04/08/1987',
        forma_obte: 'Desapropriação',
        data_obten: '28/04/1987',
        area_calc_: 65.3912,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.527853621374419, -8.265231004007225],
              [-35.526875433686726, -8.265903557476813],
              [-35.526421861156983, -8.26645399393148],
              [-35.5251016562366, -8.267304930527738],
              [-35.524618935179916, -8.267638759282953],
              [-35.524014285361496, -8.268094020389412],
              [-35.523956372415149, -8.268517028724816],
              [-35.523921903650375, -8.269557000765785],
              [-35.523785481752732, -8.270018805132798],
              [-35.523725862086742, -8.270435955724988],
              [-35.523329909043348, -8.271693888362098],
              [-35.523266274669467, -8.271995959862034],
              [-35.523215681306915, -8.272235073324072],
              [-35.522679721674095, -8.273550895800584],
              [-35.522341381314803, -8.274335752857967],
              [-35.522201974724993, -8.274572455678401],
              [-35.522112750866853, -8.275048921207729],
              [-35.521841311018477, -8.275672516929767],
              [-35.521658991320976, -8.276073017713875],
              [-35.521926305783971, -8.276145316951004],
              [-35.523038222720395, -8.276574500347964],
              [-35.523778944264812, -8.276847640966896],
              [-35.524899179726162, -8.277143883220331],
              [-35.525738045799088, -8.277015887412549],
              [-35.526067255254489, -8.277170814020955],
              [-35.526403794348113, -8.277325427959498],
              [-35.527313294938743, -8.277642239700056],
              [-35.528017406579906, -8.277883852018951],
              [-35.527918737445297, -8.277561087270742],
              [-35.527899197821419, -8.277463473242372],
              [-35.527752802369264, -8.277061428166347],
              [-35.527787951936475, -8.276881750917891],
              [-35.527900451463509, -8.276580825853172],
              [-35.527937400444593, -8.276504658445026],
              [-35.527970219462652, -8.276356973575773],
              [-35.528020249539857, -8.276187996108192],
              [-35.528053046654634, -8.276079849011976],
              [-35.528083749814975, -8.275870576862365],
              [-35.528106974241226, -8.275813708433606],
              [-35.528136993794369, -8.275662452774856],
              [-35.528203736932561, -8.275371253245735],
              [-35.52823155310692, -8.275316561354314],
              [-35.528283580922462, -8.275098604904716],
              [-35.528578441734432, -8.273865405920192],
              [-35.528825362472539, -8.272849857549316],
              [-35.528802299433003, -8.272380615859204],
              [-35.528793476697139, -8.272078139831146],
              [-35.528850437243705, -8.27173167632281],
              [-35.528862275663251, -8.271412768699046],
              [-35.52887350667536, -8.271190620366482],
              [-35.528768796512971, -8.270533038464201],
              [-35.528563605178654, -8.269947873300078],
              [-35.528417110038866, -8.269645069571796],
              [-35.528274678846813, -8.269634072049124],
              [-35.528125793851444, -8.269586777904543],
              [-35.527918740009014, -8.269525093243676],
              [-35.527824270883805, -8.269155231864815],
              [-35.527820746932782, -8.268147324339425],
              [-35.527773587068594, -8.267466532492881],
              [-35.527775233983881, -8.266679151968374],
              [-35.527853621374419, -8.265231004007225],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0094000',
        uf: 'PE',
        nome_proje: 'PA VALE DO MOXOTÓ',
        municipio: 'INAJA',
        area_hecta: '1924.1202',
        capacidade: 110.0,
        num_famili: 110.0,
        fase: 4.0,
        data_de_cr: '06/07/2004',
        forma_obte: 'Doação',
        data_obten: '06/09/1954',
        area_calc_: 1986.0618,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.867742855399122, -8.868934477464066],
              [-37.857208565819668, -8.866416538192377],
              [-37.856544427669512, -8.867381614897337],
              [-37.854879872081028, -8.869548575754497],
              [-37.851415367039088, -8.873832583177311],
              [-37.849713699666303, -8.875837611654099],
              [-37.848016741925278, -8.877988334113896],
              [-37.844016000877211, -8.883178102229154],
              [-37.841814850321036, -8.886018683451713],
              [-37.841747241339824, -8.886090820763371],
              [-37.837896450378778, -8.891021690860498],
              [-37.837069848856927, -8.891885469874786],
              [-37.836125956227136, -8.893203581875893],
              [-37.835157153599788, -8.894459846346345],
              [-37.833339605648909, -8.897102965701505],
              [-37.833104230809454, -8.897514335173243],
              [-37.832941693691403, -8.897869049814236],
              [-37.832418098793937, -8.899468809788488],
              [-37.83233256167312, -8.900146071240941],
              [-37.832221569917628, -8.900648625221388],
              [-37.832129654997487, -8.901042718471889],
              [-37.832012286244094, -8.902064613207944],
              [-37.831979695104906, -8.902161727214795],
              [-37.831689958139982, -8.903883489898488],
              [-37.831657587663145, -8.903939638059407],
              [-37.831335797087576, -8.905702356206483],
              [-37.847954623839165, -8.909495346864311],
              [-37.86642725005931, -8.913710467683837],
              [-37.891038780400528, -8.87443989084375],
              [-37.880963255198395, -8.872058965946378],
              [-37.867742855399122, -8.868934477464066],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0370000',
        uf: 'PE',
        nome_proje: 'PA PORTEIRAS II',
        municipio: 'CUSTODIA',
        area_hecta: '978.8610',
        capacidade: 42.0,
        num_famili: 38.0,
        fase: 4.0,
        data_de_cr: '08/08/2007',
        forma_obte: 'Compra e Venda',
        data_obten: '28/12/2006',
        area_calc_: 978.7087,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.740294252370646, -8.248505777734023],
              [-37.740372727391645, -8.248578642324656],
              [-37.740460124221094, -8.248614819003489],
              [-37.741623790964738, -8.248912925081035],
              [-37.742103502537233, -8.24916104275577],
              [-37.742462954708628, -8.249602852709232],
              [-37.743845578552502, -8.252030443232851],
              [-37.744021229632587, -8.252550534031538],
              [-37.744003904733894, -8.253120752999562],
              [-37.743903282157, -8.25364983484709],
              [-37.743090028016731, -8.254954589239549],
              [-37.743028976637582, -8.25511283696156],
              [-37.742983785644235, -8.255193271289908],
              [-37.742772232000476, -8.25583531736353],
              [-37.742715479090883, -8.256098209912823],
              [-37.742261715410216, -8.257085764358107],
              [-37.742067926390526, -8.257678669739526],
              [-37.74187153782303, -8.258318230363402],
              [-37.741633900081666, -8.259340917596887],
              [-37.741432789145527, -8.259894460793923],
              [-37.740933191752362, -8.260302914301143],
              [-37.74038378180655, -8.260359063045337],
              [-37.739841166114765, -8.260362509805976],
              [-37.739575397024062, -8.260464043454105],
              [-37.73934437890486, -8.260657204809176],
              [-37.739880374699581, -8.261281073524822],
              [-37.743800893965279, -8.261771881260513],
              [-37.746123237590858, -8.2620199884703],
              [-37.747826252459298, -8.262214688522919],
              [-37.749483656779141, -8.262422171040001],
              [-37.751246940322488, -8.26265104839749],
              [-37.752822907604134, -8.262861790361466],
              [-37.754597569381083, -8.263082911664577],
              [-37.756306788502378, -8.263272170140329],
              [-37.758002737952459, -8.263496197391422],
              [-37.759751146086451, -8.263725715936705],
              [-37.761422688324913, -8.263947302296016],
              [-37.763114202868593, -8.264169848019202],
              [-37.764915425233376, -8.264409365419953],
              [-37.766675943555462, -8.264631295747511],
              [-37.768331225770361, -8.264889068303901],
              [-37.770029494797541, -8.265162340302307],
              [-37.771733594972183, -8.265429925960857],
              [-37.773519099267681, -8.265678763061148],
              [-37.775266667499025, -8.26568945296944],
              [-37.776157611275558, -8.265665049412705],
              [-37.776532184006363, -8.263604379892145],
              [-37.778223651955642, -8.261841010805137],
              [-37.778358889873161, -8.261703149244637],
              [-37.779500761711638, -8.260936944520937],
              [-37.779377822622173, -8.260558824407088],
              [-37.77912066080799, -8.259870466092197],
              [-37.779097805526284, -8.259804198281296],
              [-37.778698330568716, -8.258691358125121],
              [-37.777760685540983, -8.255959001557883],
              [-37.777025931202481, -8.253822322899957],
              [-37.776888393972825, -8.253439636251258],
              [-37.776084070153331, -8.253291399270774],
              [-37.774365990289667, -8.252979392953957],
              [-37.772717863854815, -8.252681249535392],
              [-37.77091987468792, -8.25234926970373],
              [-37.769187082142949, -8.252072195245338],
              [-37.76753807698482, -8.251855962296574],
              [-37.765827453153257, -8.25163365480004],
              [-37.76415255100077, -8.251427095547266],
              [-37.762416382776195, -8.251217898600268],
              [-37.760719357667718, -8.251004293746673],
              [-37.759054258282646, -8.250800909432925],
              [-37.757347145080942, -8.250592776030556],
              [-37.755659234431775, -8.250399255082298],
              [-37.753833381671846, -8.250163608364488],
              [-37.752090426223532, -8.249953171479071],
              [-37.750367403677807, -8.249698205629542],
              [-37.748571437340232, -8.249372828450156],
              [-37.746834981668691, -8.249119343520606],
              [-37.744815374950392, -8.248869848174746],
              [-37.740294252370646, -8.248505777734023],
            ],
          ],
          [
            [
              [-37.720342866738598, -8.257405086925326],
              [-37.718316612521434, -8.258680125803812],
              [-37.733998387738801, -8.260633820048939],
              [-37.721369561325574, -8.257980534241401],
              [-37.720342866738598, -8.257405086925326],
            ],
          ],
          [
            [
              [-37.693767099433856, -8.250114757030731],
              [-37.695357083611476, -8.255203740110414],
              [-37.701779766347926, -8.256277962207712],
              [-37.701473171067143, -8.255426364918018],
              [-37.693767099433856, -8.250114757030731],
            ],
          ],
          [
            [
              [-37.693065980514419, -8.244600114668719],
              [-37.693415097786549, -8.247063799245751],
              [-37.698838973031229, -8.247422911001136],
              [-37.69993223117411, -8.248027468848367],
              [-37.70056610398089, -8.247730827337238],
              [-37.717706476129024, -8.249955771964098],
              [-37.720274176737888, -8.255337577561159],
              [-37.722912575284148, -8.256395742304319],
              [-37.726766160177959, -8.257263146191523],
              [-37.735592673945469, -8.259130887139703],
              [-37.738062657818567, -8.259875621068485],
              [-37.739171906235022, -8.260509978821634],
              [-37.739541855503411, -8.260248706672879],
              [-37.739735588046578, -8.260190354196478],
              [-37.740373915243381, -8.260178255853148],
              [-37.740860301861254, -8.260128510472903],
              [-37.741278984324929, -8.259786180664962],
              [-37.741459489164463, -8.259289545781717],
              [-37.741696207737732, -8.25827056358899],
              [-37.74209197863766, -8.257020661757588],
              [-37.742542177572041, -8.256040873630365],
              [-37.742596628168556, -8.255788373730919],
              [-37.742781771508504, -8.255115497532801],
              [-37.742931532224091, -8.254866009694798],
              [-37.743731191262384, -8.253583097923933],
              [-37.743822859529608, -8.253101103891114],
              [-37.743838766620684, -8.252577453743088],
              [-37.743679034589299, -8.252104619067085],
              [-37.742312481645655, -8.249705112875141],
              [-37.741986307167224, -8.249304192712009],
              [-37.741558527312293, -8.249082915788481],
              [-37.740402388490502, -8.248786822648327],
              [-37.740152256052674, -8.248683213810729],
              [-37.739741229506279, -8.248446795047721],
              [-37.731664622631065, -8.247556870423006],
              [-37.730367330432848, -8.247434025236137],
              [-37.717842128328506, -8.246482781091347],
              [-37.693065980514419, -8.244600114668719],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0010000',
        uf: 'PE',
        nome_proje: 'PA PAQUEVIRA',
        municipio: 'BOM JARDIM',
        area_hecta: '298.0000',
        capacidade: 86.0,
        num_famili: 62.0,
        fase: 6.0,
        data_de_cr: '29/01/1987',
        forma_obte: 'Compra e Venda',
        data_obten: '26/08/1986',
        area_calc_: 313.5052,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.557591939950711, -7.731347499998548],
              [-35.553475559951131, -7.732041389998551],
              [-35.553419169951127, -7.732051109998555],
              [-35.547800279951694, -7.732971389998584],
              [-35.547177219951763, -7.733091669998587],
              [-35.542598329952213, -7.733863329998603],
              [-35.542418889952238, -7.733832499998597],
              [-35.539717779952504, -7.734356109998601],
              [-35.539627219952514, -7.734368059998604],
              [-35.533209169953153, -7.735445559998625],
              [-35.533065829953159, -7.735464439998625],
              [-35.532106389953256, -7.735625829998631],
              [-35.531572499953313, -7.735707219998634],
              [-35.531677499953297, -7.736260279998645],
              [-35.531767219953288, -7.73681971999864],
              [-35.531866669953281, -7.737328889998635],
              [-35.532029999953274, -7.738192219998637],
              [-35.532064169953259, -7.738508059998643],
              [-35.532124169953264, -7.73885388999864],
              [-35.53215332995326, -7.739046669998638],
              [-35.532234439953257, -7.73980555999864],
              [-35.532586939953234, -7.743269169998624],
              [-35.532569169953227, -7.743609169998629],
              [-35.532454169953247, -7.743612779998621],
              [-35.532256389953261, -7.743860279998635],
              [-35.532278059953263, -7.744108889998638],
              [-35.532211939953271, -7.744169169998634],
              [-35.531906389953299, -7.744173329998636],
              [-35.531780829953313, -7.744171389998629],
              [-35.531751669953309, -7.744269169998624],
              [-35.531725279953321, -7.744475279998638],
              [-35.531550559953331, -7.74464777999864],
              [-35.531490829953341, -7.745007499998636],
              [-35.531407779953348, -7.745183059998643],
              [-35.531451109953345, -7.745335279998632],
              [-35.531592779953328, -7.745410829998637],
              [-35.531706109953319, -7.745400559998627],
              [-35.532188059953278, -7.745304999998641],
              [-35.532597499953233, -7.745450829998632],
              [-35.533109169953185, -7.745554999998626],
              [-35.533436939953148, -7.745717499998619],
              [-35.533858059953104, -7.74598138999862],
              [-35.534801669953026, -7.747283059998617],
              [-35.534934719953007, -7.747243889998628],
              [-35.536702499952831, -7.74667555999862],
              [-35.538092219952695, -7.74624138999861],
              [-35.538474439952658, -7.746087219998614],
              [-35.538481109952656, -7.746087499998609],
              [-35.539340279952569, -7.745793329998597],
              [-35.541622779952341, -7.744970829998604],
              [-35.541769169952339, -7.744923329998596],
              [-35.541768329952326, -7.744916109998593],
              [-35.541929439952312, -7.744866389998591],
              [-35.543664439952138, -7.744279169998589],
              [-35.543797499952127, -7.744207219998597],
              [-35.54639360995187, -7.743296109998579],
              [-35.547142779951791, -7.743036109998584],
              [-35.547331939951768, -7.742964999998579],
              [-35.547957779951702, -7.742813889998578],
              [-35.549114169951594, -7.742426389998568],
              [-35.549229719951583, -7.742387219998579],
              [-35.54985916995151, -7.742175279998575],
              [-35.550834439951416, -7.741896669998574],
              [-35.551393059951359, -7.741499169998562],
              [-35.55274332995122, -7.741170279998559],
              [-35.552944999951194, -7.741104439998568],
              [-35.557468059950729, -7.738559169998541],
              [-35.557505829950721, -7.735631109998552],
              [-35.557563889950707, -7.732808059998553],
              [-35.557556939950715, -7.732423329998545],
              [-35.557591939950711, -7.731347499998548],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0005000',
        uf: 'PE',
        nome_proje: 'PIC CAXANGÁ',
        municipio: 'RIBEIRAO',
        area_hecta: '19724.0000',
        capacidade: 971.0,
        num_famili: 868.0,
        fase: 7.0,
        data_de_cr: '15/09/1965',
        forma_obte: 'Desapropriação',
        data_obten: '18/02/1965',
        area_calc_: 18872.335,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.352093260425754, -8.34752726331606],
              [-35.351835230601971, -8.347594231198663],
              [-35.351604484602696, -8.347692959363387],
              [-35.351304257630197, -8.347900537327885],
              [-35.351136677422083, -8.348061158231923],
              [-35.351063960119149, -8.34826625340105],
              [-35.351085920780498, -8.348367237070219],
              [-35.350840966466315, -8.348771162664605],
              [-35.35065147499089, -8.349208649513127],
              [-35.35045748802915, -8.349458191740046],
              [-35.350158999692873, -8.349630360755441],
              [-35.349726908277482, -8.349797400188013],
              [-35.349321048982524, -8.349916403356959],
              [-35.349234205788413, -8.350047827346906],
              [-35.349198398093407, -8.350259507726028],
              [-35.349037670419925, -8.350435006337625],
              [-35.348981560836023, -8.350633380653779],
              [-35.348858086380183, -8.350838867928426],
              [-35.348649793375571, -8.3509508726329],
              [-35.348295776418574, -8.350929476264465],
              [-35.34808796346821, -8.350946160817156],
              [-35.347943403855794, -8.351007437653728],
              [-35.347862899089812, -8.351071691202055],
              [-35.34782652872579, -8.351189389184539],
              [-35.347868898553976, -8.351511375006929],
              [-35.347958492319144, -8.351839793970283],
              [-35.348003891484382, -8.352104698938268],
              [-35.347941361979316, -8.352356817560649],
              [-35.347867377750816, -8.352666066956951],
              [-35.347957613282702, -8.352819936949343],
              [-35.347958275755602, -8.352930702010896],
              [-35.3478889253904, -8.353169434578851],
              [-35.3477750788742, -8.353294305472499],
              [-35.347595023017398, -8.353577381590627],
              [-35.34746741141565, -8.353821002746866],
              [-35.347260662663636, -8.354084698334955],
              [-35.347007007246006, -8.354316077276058],
              [-35.346771561791662, -8.354565709853606],
              [-35.346654866036594, -8.35477787013024],
              [-35.346369735873147, -8.355004455749262],
              [-35.346242487471947, -8.355142832494222],
              [-35.346071445215614, -8.355284825520505],
              [-35.345576885937263, -8.355531729925152],
              [-35.34457943417695, -8.355117863777602],
              [-35.344175315027115, -8.355406313809764],
              [-35.344025928180436, -8.355526320928032],
              [-35.343951791947504, -8.355512479913006],
              [-35.340926612330129, -8.358622147907154],
              [-35.339698120690862, -8.358581514576391],
              [-35.337675785681171, -8.358582174258224],
              [-35.335853581723484, -8.358556244562285],
              [-35.335693963433847, -8.35621124527354],
              [-35.335637961482476, -8.35509681049003],
              [-35.335525779866558, -8.352684918966711],
              [-35.335417341952279, -8.350794777286362],
              [-35.335366826953404, -8.349839019405186],
              [-35.335284522602414, -8.348481793300406],
              [-35.333895036293832, -8.34842075247586],
              [-35.3317435699282, -8.348522181296955],
              [-35.330060285114904, -8.34854718297361],
              [-35.326661767137367, -8.348566984307288],
              [-35.325445135828204, -8.348594647336911],
              [-35.319827146646752, -8.348731447541882],
              [-35.316728007708122, -8.348861303787544],
              [-35.316659960396478, -8.348864142221773],
              [-35.313468971684649, -8.348963238316962],
              [-35.311604859218832, -8.349276998045896],
              [-35.309802240809589, -8.349909533822583],
              [-35.309530760721501, -8.350010990807332],
              [-35.306800981572103, -8.350949356198139],
              [-35.306623189419298, -8.351164510157098],
              [-35.306527052956149, -8.351298656810943],
              [-35.306369753228132, -8.351588076805687],
              [-35.306155908537399, -8.351938383047809],
              [-35.305889100999707, -8.352418697899722],
              [-35.30571578649834, -8.352716526429379],
              [-35.305580402243201, -8.352905402822369],
              [-35.305519268784032, -8.352988550081465],
              [-35.305511293954297, -8.353083227915954],
              [-35.306342051552441, -8.354457637234662],
              [-35.306402037475188, -8.354611662315579],
              [-35.307014004153395, -8.35578714482663],
              [-35.308895523948266, -8.358610133367474],
              [-35.308913154777258, -8.358614278458667],
              [-35.309027489779631, -8.358812001996679],
              [-35.309123288239093, -8.35897503618688],
              [-35.309232417035155, -8.359197916506488],
              [-35.309342683590721, -8.35947529112949],
              [-35.30938171729187, -8.359600153423527],
              [-35.309417578914136, -8.359756577930778],
              [-35.309444063136333, -8.359861719586981],
              [-35.30949353771593, -8.359956423308525],
              [-35.309552455888543, -8.360021155065015],
              [-35.30975774023954, -8.360128415867919],
              [-35.310339074721092, -8.361168853888705],
              [-35.310645265897477, -8.361122505617775],
              [-35.31085115879381, -8.361171653253988],
              [-35.31092566426836, -8.361231638801209],
              [-35.311040278681318, -8.361234325677412],
              [-35.311269388795829, -8.361219559707102],
              [-35.311865361115679, -8.361115374240098],
              [-35.312228318388676, -8.360935346047155],
              [-35.312530370160871, -8.36071539318546],
              [-35.312866640390688, -8.360582514660262],
              [-35.31327105117586, -8.360573435766794],
              [-35.314127015991474, -8.360441381269707],
              [-35.314771411114165, -8.360484102699319],
              [-35.316412408994275, -8.358033708465978],
              [-35.32218059425454, -8.358121726429115],
              [-35.327408912585192, -8.358490441775148],
              [-35.331321896868509, -8.359207548680626],
              [-35.331737927107561, -8.360930485640825],
              [-35.331851266645579, -8.361362436846145],
              [-35.331505415934281, -8.361538601299353],
              [-35.331343289796678, -8.36164434960626],
              [-35.331248305220377, -8.361779647053924],
              [-35.331240868331285, -8.362052339355818],
              [-35.331346389970413, -8.362399837993229],
              [-35.331567465945689, -8.362759647173204],
              [-35.331797162197319, -8.363135042269569],
              [-35.331982153081668, -8.363388489374602],
              [-35.332082981194127, -8.363634468495404],
              [-35.332117104786839, -8.363957405402324],
              [-35.332230586162765, -8.364506527141232],
              [-35.332318766755762, -8.364971255081608],
              [-35.332348630599029, -8.365298469563186],
              [-35.332324416201295, -8.365652176490338],
              [-35.332274904502327, -8.365748945962933],
              [-35.332092953856517, -8.365888858485039],
              [-35.331803704876592, -8.36659968506982],
              [-35.332160896875344, -8.366712516929502],
              [-35.332108702781554, -8.366842200233505],
              [-35.332109780642185, -8.367094304618565],
              [-35.332471581169827, -8.367197937092048],
              [-35.332632418405289, -8.367132195031241],
              [-35.333107595866188, -8.367295022439979],
              [-35.333819056057344, -8.367530860561001],
              [-35.334063451797171, -8.367738423600002],
              [-35.334360486304199, -8.368621756495864],
              [-35.334456186213181, -8.368938864887712],
              [-35.33446393373243, -8.368973969244101],
              [-35.335993857072978, -8.375905911749578],
              [-35.339754645013372, -8.382688510981158],
              [-35.341930519477572, -8.380547167739385],
              [-35.342957392026079, -8.379500180644531],
              [-35.345046123719712, -8.377466468653237],
              [-35.347029579527579, -8.379352176136273],
              [-35.348296400845754, -8.380579334901372],
              [-35.349400140495298, -8.378861842712519],
              [-35.351099689747592, -8.376183203201396],
              [-35.353075515203656, -8.373094771776229],
              [-35.354566226781195, -8.37067307615057],
              [-35.358169763655724, -8.36503400683409],
              [-35.359206572121025, -8.363350393527103],
              [-35.361570096414958, -8.359639782727024],
              [-35.364953217504187, -8.35889372805404],
              [-35.367807639407332, -8.358169570863959],
              [-35.372124131804618, -8.361218796201618],
              [-35.379503451558094, -8.366372363355696],
              [-35.379768124682634, -8.364425402974506],
              [-35.380149490773917, -8.362920109260598],
              [-35.379627639426936, -8.361913087867119],
              [-35.379596824372015, -8.361216814896776],
              [-35.379498726680026, -8.361247410977931],
              [-35.379314133512928, -8.361261445940627],
              [-35.379029236028906, -8.361361221118775],
              [-35.378465478129513, -8.361538772613228],
              [-35.378113896739272, -8.36167157512463],
              [-35.377742362237406, -8.361671730866597],
              [-35.377607602552615, -8.361702637398025],
              [-35.377312382746886, -8.361881283983394],
              [-35.377036548525872, -8.361994289182203],
              [-35.376741354107736, -8.36187775782683],
              [-35.376114466149652, -8.361731677434756],
              [-35.375990150152873, -8.361629030983325],
              [-35.375819646195943, -8.361407271398576],
              [-35.375831377303776, -8.361291425462202],
              [-35.375827455431889, -8.360988589126178],
              [-35.374269079167668, -8.359190187256953],
              [-35.374131393092071, -8.358632561462809],
              [-35.373866568430309, -8.358600258795017],
              [-35.373620787681809, -8.358610681352733],
              [-35.373387983173991, -8.358575926713652],
              [-35.372451301198382, -8.358433776171179],
              [-35.372294097997624, -8.358475028165682],
              [-35.372109634380848, -8.358525481514048],
              [-35.371989812044745, -8.358535328394975],
              [-35.371803390440597, -8.358487099246382],
              [-35.371585234145378, -8.358398479995078],
              [-35.371296559622813, -8.358264009178162],
              [-35.371118300815155, -8.358154905462708],
              [-35.370816425926186, -8.357953451894753],
              [-35.370650543613706, -8.357819961681443],
              [-35.370140338319338, -8.357323492443877],
              [-35.36987571580439, -8.357114222781849],
              [-35.369794620810431, -8.357075122419676],
              [-35.369667874702905, -8.357065488023544],
              [-35.369256158215173, -8.357113776343638],
              [-35.368842501808203, -8.357171475337347],
              [-35.368696243443715, -8.357146683346668],
              [-35.368549953811261, -8.357086643504061],
              [-35.368327609935214, -8.356920501535745],
              [-35.368066464051722, -8.356715728479092],
              [-35.367966480345501, -8.356659478250759],
              [-35.367322879707658, -8.35640430225499],
              [-35.366060332648495, -8.356129508973797],
              [-35.3653979514024, -8.355950179702074],
              [-35.364255795047093, -8.35560904446238],
              [-35.363936851628033, -8.355465890646252],
              [-35.363822459450148, -8.355473262153732],
              [-35.36374017287504, -8.355537561832852],
              [-35.36232799982777, -8.355039868486399],
              [-35.360222211334175, -8.354374403974424],
              [-35.359611550488744, -8.354223495814646],
              [-35.358041858044629, -8.353668247597282],
              [-35.35695760094093, -8.351784043389504],
              [-35.356190281567123, -8.3502976086861],
              [-35.355826559636277, -8.349420825065863],
              [-35.355795360947532, -8.348470842945234],
              [-35.355794407214724, -8.348402435813808],
              [-35.35581527596522, -8.347825047242441],
              [-35.355393992076856, -8.347693774043405],
              [-35.355020056182219, -8.347666915411857],
              [-35.354896788053125, -8.347562951749765],
              [-35.354685061035212, -8.347559848271178],
              [-35.354511764779993, -8.347635038713872],
              [-35.354365676188742, -8.347832312496372],
              [-35.354230301231738, -8.347933352588367],
              [-35.353836029318302, -8.347924063219384],
              [-35.353228299852418, -8.34786660174945],
              [-35.352938904532742, -8.347815972847789],
              [-35.352593245136426, -8.347634806928298],
              [-35.352323976610883, -8.347531709530944],
              [-35.352093260425754, -8.34752726331606],
            ],
          ],
          [
            [
              [-35.370763128856559, -8.378348191836464],
              [-35.370248374946215, -8.379882878122348],
              [-35.369245099567806, -8.38164172944483],
              [-35.368171305476267, -8.383985279526421],
              [-35.367418951547172, -8.385793677364846],
              [-35.366311135503651, -8.388165795291199],
              [-35.364170759148692, -8.393113793728894],
              [-35.363727004208201, -8.394148868494426],
              [-35.363369513590676, -8.394142504638571],
              [-35.362796303465331, -8.394023982534357],
              [-35.361051452155195, -8.393736633044517],
              [-35.359947824882973, -8.393565203945567],
              [-35.357793696187471, -8.393244914952797],
              [-35.357043231471735, -8.393968850884523],
              [-35.356484524488721, -8.394494373355098],
              [-35.354347112506638, -8.396737882175058],
              [-35.352255930608635, -8.398855227885607],
              [-35.351781067243969, -8.399325412451319],
              [-35.351407598056234, -8.399784434672387],
              [-35.348278475141441, -8.402949250867287],
              [-35.347746972787867, -8.402059072364965],
              [-35.347578633304401, -8.401939978836266],
              [-35.347240583965643, -8.401810246383725],
              [-35.346464205868656, -8.401617994363944],
              [-35.345986491330642, -8.401495382411902],
              [-35.345825057949178, -8.401399385439499],
              [-35.345468499417784, -8.400983885741471],
              [-35.345385193006301, -8.400869812348807],
              [-35.345331778733104, -8.400756405565831],
              [-35.345341754663458, -8.399912818696468],
              [-35.34526756304696, -8.399627282250313],
              [-35.345089928487631, -8.399172747964624],
              [-35.344680073049325, -8.398540994794841],
              [-35.343562222810966, -8.398099941675643],
              [-35.3419961300662, -8.397732688779667],
              [-35.341596123746548, -8.39759150678208],
              [-35.341270524788285, -8.397552222466238],
              [-35.340741308396858, -8.39763832441583],
              [-35.340415896104226, -8.397700432872259],
              [-35.339754319708014, -8.397777121435672],
              [-35.339120054583326, -8.397907089062736],
              [-35.338738586510004, -8.398013303686547],
              [-35.338588693223556, -8.398039919931323],
              [-35.336617479593137, -8.39838993899223],
              [-35.336065513134763, -8.398414514201486],
              [-35.335124072358902, -8.39852397837663],
              [-35.335138031441453, -8.398685032777893],
              [-35.336362825741098, -8.401165788611506],
              [-35.335278399993364, -8.40221272139377],
              [-35.333966647108667, -8.403151123926044],
              [-35.332886601340576, -8.40391032933359],
              [-35.331362056060257, -8.404844995356044],
              [-35.329498424372602, -8.406415344689776],
              [-35.328155163597195, -8.407547203391438],
              [-35.327012638698136, -8.408407838183367],
              [-35.32702009188467, -8.40864381718993],
              [-35.324975630065474, -8.41004294721094],
              [-35.32715070626594, -8.412503423242917],
              [-35.327575149672533, -8.412898996728604],
              [-35.328226103413648, -8.413594217089491],
              [-35.32996951007992, -8.415651500877347],
              [-35.331725238119937, -8.417568281984629],
              [-35.333059099101774, -8.419022175764542],
              [-35.336099054463872, -8.422453105837404],
              [-35.340153710788094, -8.426927393963791],
              [-35.342093166699023, -8.42923027290121],
              [-35.345035031649722, -8.427703772264362],
              [-35.348872619620522, -8.427340460288764],
              [-35.351204649376569, -8.429550166796249],
              [-35.351652327245709, -8.430097728778877],
              [-35.352146644573416, -8.430820861757036],
              [-35.354200775902932, -8.434172403115683],
              [-35.356773292242579, -8.439387095641214],
              [-35.36049725127446, -8.442813618390362],
              [-35.367352977098555, -8.449101243730947],
              [-35.364971979412225, -8.451397596980899],
              [-35.364689684495453, -8.451819073564323],
              [-35.363557319143325, -8.452983121340299],
              [-35.363002182553295, -8.453508352122487],
              [-35.360079248251594, -8.456282876084533],
              [-35.358408970781554, -8.458000404107029],
              [-35.355730588676543, -8.460713874934742],
              [-35.354026658110676, -8.46252519514268],
              [-35.352607187709921, -8.463801573124227],
              [-35.35481924451193, -8.465909805694269],
              [-35.356088431570441, -8.467558554302308],
              [-35.357308286314463, -8.468589280093124],
              [-35.359125244520314, -8.47130115360601],
              [-35.364598596839585, -8.468170818696642],
              [-35.36882275251466, -8.465751403022056],
              [-35.36915932658578, -8.465587694267532],
              [-35.369918585838811, -8.466762979961738],
              [-35.370339279096271, -8.46754323926336],
              [-35.370685353739347, -8.46823318980223],
              [-35.370870619955163, -8.468714231720673],
              [-35.370992660488461, -8.469104896544936],
              [-35.371214300810109, -8.469943087587183],
              [-35.371261408387575, -8.470192393519536],
              [-35.37132666924969, -8.471079750485478],
              [-35.371420714986918, -8.472480297727021],
              [-35.371604865640037, -8.474637581681099],
              [-35.371705786401215, -8.475996960423375],
              [-35.371810703951084, -8.477310934241581],
              [-35.371998324256978, -8.479567464718979],
              [-35.372106557065266, -8.480956578713878],
              [-35.372310450152732, -8.483542015952407],
              [-35.372384775421537, -8.484511567617734],
              [-35.372474766250363, -8.485248449767228],
              [-35.372650186216845, -8.485979139217326],
              [-35.373124450507497, -8.487590087840292],
              [-35.373550378080672, -8.489215510504915],
              [-35.373742303865136, -8.489849664723048],
              [-35.37396198622254, -8.490364526390609],
              [-35.374232599053563, -8.490822352070211],
              [-35.374594775753643, -8.491344853276253],
              [-35.374772605721787, -8.491539471330512],
              [-35.375032458201183, -8.491637156202946],
              [-35.376792216715721, -8.491958264595844],
              [-35.378218543028517, -8.492261546522185],
              [-35.378673478687602, -8.492116955639162],
              [-35.378865904302145, -8.491905897559549],
              [-35.379023427504521, -8.491578766169775],
              [-35.379235811714437, -8.490908112918593],
              [-35.379526109024845, -8.489936340930607],
              [-35.379874185045679, -8.488632374524968],
              [-35.380033279640436, -8.488098187192964],
              [-35.380165511663243, -8.487827935498169],
              [-35.380318349107974, -8.487665335546161],
              [-35.380539644296803, -8.487516498649157],
              [-35.381815977212945, -8.486829418037708],
              [-35.382537113614589, -8.48642793538818],
              [-35.382953641578901, -8.48606234921642],
              [-35.383296241182755, -8.485719904822615],
              [-35.384250021584691, -8.484701532241097],
              [-35.384572520158841, -8.484333684075208],
              [-35.384829252195431, -8.483926530697532],
              [-35.385618686286136, -8.4825915029689],
              [-35.385900879775782, -8.482155831268551],
              [-35.386189067792891, -8.481768338955527],
              [-35.386384140780116, -8.481526064707884],
              [-35.386743493129785, -8.481129628032592],
              [-35.386923196472125, -8.480981032488879],
              [-35.386971004592972, -8.481065838277715],
              [-35.389008646446506, -8.484411464616731],
              [-35.389443057740102, -8.485096589150514],
              [-35.389566777635082, -8.48552410216751],
              [-35.388286130407572, -8.488504327333283],
              [-35.387731124233312, -8.489971227405807],
              [-35.386674661292446, -8.49327341128453],
              [-35.386290505062689, -8.494727920290869],
              [-35.385252181647502, -8.496939736108214],
              [-35.38451777058107, -8.498164209364704],
              [-35.384253462041457, -8.499355266913479],
              [-35.387558329967035, -8.498954719998947],
              [-35.391779929804208, -8.498416282143822],
              [-35.394813780904258, -8.498063460289277],
              [-35.397244941847298, -8.497770545008708],
              [-35.398385798670503, -8.497654414574759],
              [-35.400921480231403, -8.497543677408157],
              [-35.401745548870899, -8.497203920950135],
              [-35.402200579295176, -8.49707632000281],
              [-35.402782157253007, -8.497140799576584],
              [-35.403100833525066, -8.497076435847154],
              [-35.403470893882023, -8.497028771860817],
              [-35.403925011466072, -8.49703686035458],
              [-35.403854738566743, -8.496907281103155],
              [-35.4036245737569, -8.496545668086883],
              [-35.403372184448621, -8.496274950597229],
              [-35.403078923391945, -8.49584565757368],
              [-35.402772355114777, -8.495566765239559],
              [-35.402266107739372, -8.495246561484613],
              [-35.401756729899581, -8.494880996800651],
              [-35.401469875271268, -8.494565110633786],
              [-35.401405013889942, -8.494213820629147],
              [-35.401436375726611, -8.493759833639174],
              [-35.401434364100439, -8.493436518112773],
              [-35.401347277704481, -8.493176123791489],
              [-35.401072102318786, -8.49290554423081],
              [-35.40076872188984, -8.492680517613145],
              [-35.400533641584566, -8.492443724849254],
              [-35.40061139636083, -8.492119917461622],
              [-35.400798109421594, -8.491908886970574],
              [-35.401104681781739, -8.491731152123968],
              [-35.401462813687552, -8.491598478597139],
              [-35.401616928795427, -8.491642907756182],
              [-35.401958885355846, -8.491657816446828],
              [-35.402260641629468, -8.491621921009857],
              [-35.402477958835654, -8.491751046243477],
              [-35.402671074035744, -8.491653423994959],
              [-35.402737502520914, -8.491341031395292],
              [-35.402706685201053, -8.490966842074599],
              [-35.402552542517277, -8.490460111567112],
              [-35.402327428883027, -8.489993525437294],
              [-35.402353816595088, -8.489655853902979],
              [-35.402549444418746, -8.489504328534267],
              [-35.402833382916789, -8.489352258484015],
              [-35.403111288352207, -8.489146337562286],
              [-35.403205921538486, -8.488788391796353],
              [-35.403306958045768, -8.488543854827032],
              [-35.403401944024779, -8.48824263105069],
              [-35.403637883563739, -8.487702296544677],
              [-35.403823745883628, -8.48735513295658],
              [-35.404089725032222, -8.487064199255208],
              [-35.404216063819909, -8.486765618325935],
              [-35.404491188665617, -8.486571059074297],
              [-35.404728361926999, -8.486229251246726],
              [-35.404835341725871, -8.486024384486532],
              [-35.404902832478022, -8.485688727076191],
              [-35.405099306519361, -8.485410135758235],
              [-35.405257527294097, -8.485199280416062],
              [-35.405353253094198, -8.485017172725165],
              [-35.405464044959714, -8.484809919688864],
              [-35.405563088086176, -8.484531582992208],
              [-35.405526691272883, -8.484081048893074],
              [-35.405575441838984, -8.483935342048939],
              [-35.405655921400687, -8.483756030996322],
              [-35.405737650567382, -8.483613716548525],
              [-35.405939801470829, -8.483595451878639],
              [-35.406057056788072, -8.48366846950735],
              [-35.406197966996686, -8.483880314969396],
              [-35.406553112042999, -8.484184432530519],
              [-35.406760408272888, -8.484534841649221],
              [-35.406956062806344, -8.484845615634706],
              [-35.4072529519878, -8.48508786284086],
              [-35.407891640045783, -8.485054364938209],
              [-35.408325251451529, -8.484442927544112],
              [-35.408711317460025, -8.483779602615325],
              [-35.408855393034017, -8.483475589981873],
              [-35.409109719357119, -8.483091726413878],
              [-35.409263092259536, -8.482924938047976],
              [-35.409534467016293, -8.482829540781415],
              [-35.410082208669841, -8.482595063166167],
              [-35.410419056764681, -8.482149687313365],
              [-35.410616304073486, -8.482715706524557],
              [-35.410738354313899, -8.483100674112782],
              [-35.410956198242424, -8.483314877691715],
              [-35.411214153930871, -8.483565703494854],
              [-35.411455991325127, -8.483972619238219],
              [-35.411780654969043, -8.484869683188222],
              [-35.4122422003149, -8.485998095095564],
              [-35.41258391236078, -8.485990163859965],
              [-35.413101697209314, -8.486112006974611],
              [-35.41347876487049, -8.486274430357909],
              [-35.413795991483624, -8.486433415321988],
              [-35.41391983339534, -8.486618766903689],
              [-35.414104524249446, -8.48672522272107],
              [-35.414200533209325, -8.486663557843546],
              [-35.414298572496314, -8.486459383359797],
              [-35.414450964221423, -8.486071661744552],
              [-35.414637910607283, -8.485768082262618],
              [-35.414812082752775, -8.485375609817533],
              [-35.414916491260982, -8.485216136672358],
              [-35.415031603039523, -8.484945986044897],
              [-35.415098624022612, -8.484730020860045],
              [-35.415159681701525, -8.484471549875328],
              [-35.415301122653368, -8.484311847296171],
              [-35.415453887264341, -8.48414072980353],
              [-35.415510080619427, -8.484015589580872],
              [-35.415674026954242, -8.483810368585177],
              [-35.415764191463204, -8.483650983673588],
              [-35.415780574268283, -8.483537434863763],
              [-35.415848516736425, -8.483468945485575],
              [-35.415945016755707, -8.483411623867918],
              [-35.416047036757298, -8.483325906208412],
              [-35.416074814143116, -8.48321228680048],
              [-35.416085428621038, -8.483087429023977],
              [-35.416159103750317, -8.483024576465757],
              [-35.416351855814206, -8.48292349745901],
              [-35.416538083884063, -8.482808927451387],
              [-35.416642126802749, -8.482630888622637],
              [-35.416822858366984, -8.482571606460432],
              [-35.416985919024768, -8.482489168989392],
              [-35.417328542626052, -8.48224872105213],
              [-35.41751708354775, -8.482090979332533],
              [-35.418097766051716, -8.482091393452343],
              [-35.418496967861309, -8.482088917668502],
              [-35.418816653579071, -8.482211286118297],
              [-35.419412546974598, -8.482463352499716],
              [-35.419540955106079, -8.482645295417932],
              [-35.419878152631618, -8.482943611639401],
              [-35.420091790385413, -8.4832108821687],
              [-35.420564440473122, -8.483916945013281],
              [-35.420808811871822, -8.484452981911156],
              [-35.420949078965116, -8.484571497030291],
              [-35.421280520922288, -8.484660717857373],
              [-35.421406444164099, -8.484735580058477],
              [-35.421534234229206, -8.485006907127305],
              [-35.422481244483379, -8.486400216741117],
              [-35.423014645103599, -8.487122525690754],
              [-35.423372121625484, -8.487541181408247],
              [-35.423603919391901, -8.487866175938752],
              [-35.423763210697452, -8.488354206625775],
              [-35.423999431313646, -8.488631635705952],
              [-35.424134585151968, -8.4888751699603],
              [-35.424231375664078, -8.488936384521974],
              [-35.424409411048941, -8.488925064337357],
              [-35.424536806856146, -8.489132948351205],
              [-35.424666531688189, -8.489278006910299],
              [-35.424898086515263, -8.489506119196175],
              [-35.427678308996875, -8.493566988613349],
              [-35.428384891863956, -8.493090993698848],
              [-35.42930539676388, -8.492858485098854],
              [-35.431221285063728, -8.491817005798817],
              [-35.433242256363577, -8.490775008098797],
              [-35.433015305963586, -8.49004573199884],
              [-35.432948696563599, -8.489229066898886],
              [-35.432439431763626, -8.487302926498874],
              [-35.43241813636363, -8.486791933498823],
              [-35.433789489763527, -8.487537461798818],
              [-35.434539716963464, -8.487826132498846],
              [-35.436704690663291, -8.488860045398805],
              [-35.437406250263244, -8.489170610498883],
              [-35.438180561063177, -8.489550908198847],
              [-35.439820298163049, -8.490426109698811],
              [-35.440794288462975, -8.489506646398846],
              [-35.44148860636291, -8.488242486798789],
              [-35.441849454662879, -8.487559928898829],
              [-35.442587381562824, -8.485903155698825],
              [-35.443430024062756, -8.486084145498845],
              [-35.444642157762651, -8.486377998198837],
              [-35.446431652762513, -8.486781604998791],
              [-35.448233647862374, -8.487225319498776],
              [-35.450392454939163, -8.4861366614328],
              [-35.451858836331738, -8.487778621716055],
              [-35.455120868303794, -8.489933244975068],
              [-35.459160358214746, -8.492608705017158],
              [-35.46132259822366, -8.493977038079098],
              [-35.462494987026389, -8.495073628770227],
              [-35.467042796471858, -8.498313617574658],
              [-35.47058786469939, -8.5005815058646],
              [-35.473067165660289, -8.501775151989158],
              [-35.473458421723919, -8.501312144423304],
              [-35.473997738452056, -8.5006739064806],
              [-35.475536950613126, -8.498817820769155],
              [-35.476350316505545, -8.499756076875325],
              [-35.478034272496402, -8.501590650014114],
              [-35.478368586777577, -8.501954869072085],
              [-35.479793707828158, -8.503020199021201],
              [-35.483068500597859, -8.504843448860976],
              [-35.48314970087651, -8.506196582082232],
              [-35.483245250669398, -8.508722119665647],
              [-35.483879003360308, -8.509301608929768],
              [-35.485758072492906, -8.509520157454167],
              [-35.48591058775812, -8.510680959726553],
              [-35.486336730095381, -8.51194704903839],
              [-35.48673971689697, -8.512947166458462],
              [-35.486891676295777, -8.513689832484816],
              [-35.487182797894256, -8.514219923279871],
              [-35.491708817945806, -8.517112362045552],
              [-35.49239489961537, -8.517552396245971],
              [-35.492874502648469, -8.517880875841493],
              [-35.494626969587031, -8.519080932518031],
              [-35.495951134557608, -8.519945041058472],
              [-35.497308415903547, -8.520830872015736],
              [-35.498077720532216, -8.521332947991045],
              [-35.498793755624689, -8.521800195651819],
              [-35.5006453607213, -8.523008696604174],
              [-35.503160828412938, -8.524650284793388],
              [-35.503367413683655, -8.525306968303752],
              [-35.503437204130364, -8.525635805286624],
              [-35.503808551624644, -8.527385369925822],
              [-35.504043665170613, -8.528125231242376],
              [-35.504348508697078, -8.52959346925692],
              [-35.512619304700927, -8.535009307944906],
              [-35.510701532405108, -8.541144296828593],
              [-35.510505982386697, -8.541556991728658],
              [-35.510228436517096, -8.542521496928462],
              [-35.509939935810827, -8.543493598790119],
              [-35.511974809803199, -8.544866027819563],
              [-35.512817578431147, -8.545374326327693],
              [-35.513974285470873, -8.546216245603111],
              [-35.515442995033453, -8.54726530632456],
              [-35.525975203728414, -8.560532811129816],
              [-35.531478724021994, -8.560462399682287],
              [-35.533452768606722, -8.561102281084226],
              [-35.534637670573652, -8.561221832198024],
              [-35.538975288041222, -8.565009348397322],
              [-35.541490392187612, -8.565224997087055],
              [-35.544461349301876, -8.565888224132234],
              [-35.549377878498994, -8.569634355949288],
              [-35.549948120440305, -8.570052414922966],
              [-35.552769696585628, -8.576307508360001],
              [-35.553098280128907, -8.577241926237663],
              [-35.552982936417891, -8.577986244079304],
              [-35.552675360305386, -8.579255539927532],
              [-35.552517231939262, -8.579912559069719],
              [-35.552283164539077, -8.580089272785083],
              [-35.552017586125636, -8.580387737597082],
              [-35.551826065991101, -8.580481949545042],
              [-35.551081954120008, -8.581056158172883],
              [-35.550398723920694, -8.581469991510659],
              [-35.549694158150217, -8.581916138259707],
              [-35.547946313375569, -8.58318244681859],
              [-35.547135129586806, -8.583799098139707],
              [-35.545455027606437, -8.584993456392551],
              [-35.545578892136092, -8.586602191568559],
              [-35.545708254267893, -8.588226976563655],
              [-35.545749060523995, -8.588421533415429],
              [-35.545788305672346, -8.588941718902081],
              [-35.545795293505051, -8.5891603174069],
              [-35.546079854116996, -8.591363310924956],
              [-35.547395505639159, -8.591097877840955],
              [-35.548350702629527, -8.590846121937172],
              [-35.549002616985824, -8.590576400371205],
              [-35.549039613132571, -8.590582842650337],
              [-35.549152856449645, -8.590586340353166],
              [-35.54949931087063, -8.590556937004006],
              [-35.549719762406298, -8.590557556022823],
              [-35.549873357397381, -8.590566931304197],
              [-35.54994952178523, -8.590592362275984],
              [-35.549995910558458, -8.590632087588764],
              [-35.550023091574239, -8.590691459284798],
              [-35.550056674279162, -8.590852812610091],
              [-35.550071552504399, -8.59101401867842],
              [-35.55005065243283, -8.591296643421588],
              [-35.550036473045786, -8.591416563578798],
              [-35.55001598946631, -8.591517006272687],
              [-35.549956189571752, -8.591617076748554],
              [-35.550138645666742, -8.592113608332248],
              [-35.550329805283233, -8.592838980861504],
              [-35.550344490011057, -8.592916870046126],
              [-35.550404498884454, -8.593228237613584],
              [-35.550444832368029, -8.593489310873229],
              [-35.55044464859261, -8.593692637118496],
              [-35.550415975669061, -8.593926153833349],
              [-35.550381223442166, -8.594214563403563],
              [-35.550360316132085, -8.594509568393946],
              [-35.550495923312646, -8.594408543695609],
              [-35.550545304498293, -8.594393306026534],
              [-35.550608406416686, -8.594393249411103],
              [-35.550751340122659, -8.594437395325759],
              [-35.550958201105161, -8.594509854156646],
              [-35.551563910915817, -8.59474332331664],
              [-35.552100414616199, -8.594960080496039],
              [-35.552406653453282, -8.595066851359247],
              [-35.552736935500079, -8.595198132541263],
              [-35.552666658268528, -8.595306315489202],
              [-35.552064103678369, -8.596059901563214],
              [-35.551417014878623, -8.596942826095054],
              [-35.551585493527348, -8.597086476388414],
              [-35.551972206984146, -8.597278832903728],
              [-35.552227304274503, -8.59747667052425],
              [-35.552586200798544, -8.597813074197878],
              [-35.553099537659364, -8.598298099583149],
              [-35.554577469502732, -8.599471367329247],
              [-35.554672665364812, -8.599653727145077],
              [-35.554955063686727, -8.599779178077828],
              [-35.555129186935154, -8.599884111780625],
              [-35.555287939491471, -8.600025745830729],
              [-35.555410356607453, -8.600151264833434],
              [-35.55614185926575, -8.600700531168361],
              [-35.557093884623882, -8.601354690628851],
              [-35.557731134831378, -8.60180770661991],
              [-35.558154401971628, -8.60119564336631],
              [-35.558599670495205, -8.600669191117239],
              [-35.560789938667597, -8.597911867037036],
              [-35.560808809614791, -8.597668906384028],
              [-35.561535528415682, -8.59688863037219],
              [-35.561721789984595, -8.596536402309459],
              [-35.562920772204933, -8.594944892725067],
              [-35.563933569253948, -8.593717853599266],
              [-35.565041923086063, -8.592371820807779],
              [-35.566515622570805, -8.590476465453328],
              [-35.566789388334364, -8.590213066182462],
              [-35.568095963814685, -8.588698318368301],
              [-35.566542500982266, -8.588468568831914],
              [-35.564670363596278, -8.588239940051134],
              [-35.562750585860336, -8.587934084136895],
              [-35.560686291882433, -8.587659089564134],
              [-35.558607739970569, -8.587342970573637],
              [-35.556458067880627, -8.587022431426581],
              [-35.554229301906219, -8.586686318222998],
              [-35.554672639810398, -8.584664132131401],
              [-35.554699925370443, -8.584576295437124],
              [-35.555047676675613, -8.582665533311925],
              [-35.555556269477066, -8.580735180518076],
              [-35.555576190142133, -8.580645766088461],
              [-35.555880779611428, -8.579245682774115],
              [-35.556694829557195, -8.575570855176739],
              [-35.557004937723399, -8.57423616193778],
              [-35.557367675311625, -8.572232225902178],
              [-35.560445545385598, -8.572764590795357],
              [-35.563748055409235, -8.57326019340022],
              [-35.564180449008546, -8.569071194475002],
              [-35.564199319872806, -8.568947357743005],
              [-35.56427622799189, -8.56812279711186],
              [-35.564405766152198, -8.566305394522404],
              [-35.565945768827341, -8.550976743741085],
              [-35.564251911931656, -8.550690258321115],
              [-35.558826134314351, -8.549777518452338],
              [-35.556100787982025, -8.549515306544935],
              [-35.55541230767853, -8.55252847473418],
              [-35.552612398302458, -8.548950728530237],
              [-35.550140724293023, -8.545849806544538],
              [-35.548570382919664, -8.545164850853165],
              [-35.54651642983012, -8.544120226004479],
              [-35.544511925456419, -8.54322004176556],
              [-35.541934739477298, -8.542066217318707],
              [-35.542045804805667, -8.537959812774355],
              [-35.541910805763862, -8.537638965067103],
              [-35.541495397770809, -8.537273484025352],
              [-35.539438262821186, -8.535739073693485],
              [-35.538569750412691, -8.535280036678376],
              [-35.536968788402412, -8.534300292306666],
              [-35.535630922168274, -8.53346181138317],
              [-35.53539002838064, -8.533418701650493],
              [-35.534684478840781, -8.532992257811566],
              [-35.53247523541863, -8.531754104272306],
              [-35.529855248599965, -8.530285825236403],
              [-35.527931411847995, -8.529014959212331],
              [-35.52718499995563, -8.527183059998535],
              [-35.526661939955659, -8.526465829998539],
              [-35.525284999955794, -8.526899719998568],
              [-35.523555559955945, -8.523787499998564],
              [-35.523074719955986, -8.522079169998563],
              [-35.521408059956137, -8.521276669998569],
              [-35.518829719956372, -8.519856669998575],
              [-35.514012576935087, -8.517307771110326],
              [-35.512025212741882, -8.5159846203355],
              [-35.511567652613259, -8.515713895021577],
              [-35.508407520427021, -8.513721291037662],
              [-35.506700802892006, -8.512716790126406],
              [-35.504956109957604, -8.511946109998609],
              [-35.505071389957578, -8.508079999998559],
              [-35.505254719957563, -8.502584439998595],
              [-35.505265206539775, -8.502270106780839],
              [-35.504268896178345, -8.50142852460406],
              [-35.503578132017147, -8.500892800398651],
              [-35.503170113915793, -8.500448885033327],
              [-35.502909072450514, -8.50035693479289],
              [-35.502611812972823, -8.500258015014872],
              [-35.50179097621556, -8.499744729693212],
              [-35.501142969625462, -8.49910789249846],
              [-35.50023467221952, -8.49849433271088],
              [-35.499763325640565, -8.498324505608686],
              [-35.499371322755287, -8.498118156851589],
              [-35.49842690518814, -8.497512025581196],
              [-35.497332917962176, -8.496710588104433],
              [-35.49690259991965, -8.496452265121121],
              [-35.496714276862178, -8.49641746150172],
              [-35.496273338133783, -8.496474306586263],
              [-35.495983805618756, -8.496450955059528],
              [-35.495715393452578, -8.496337439047718],
              [-35.495428103946821, -8.496101604544892],
              [-35.494840827027843, -8.49590370386853],
              [-35.494558272868126, -8.495840693681142],
              [-35.494258145283105, -8.495857021331723],
              [-35.493899957509321, -8.495844911425973],
              [-35.493631825279515, -8.495774605801886],
              [-35.493377137711406, -8.495545762797535],
              [-35.493094071900288, -8.495403529131513],
              [-35.492753178924247, -8.495268867775263],
              [-35.492419752937579, -8.495170169880799],
              [-35.491965985515776, -8.494920993015144],
              [-35.491327443531056, -8.494626182627632],
              [-35.490797741056305, -8.49438109102973],
              [-35.490396241433345, -8.494383659208882],
              [-35.489792222606333, -8.493832941817415],
              [-35.489646446191053, -8.493713572722568],
              [-35.489867264126062, -8.493494980596987],
              [-35.49002581779564, -8.493447732471989],
              [-35.490239743883272, -8.493421703453244],
              [-35.490322734196454, -8.493356586425744],
              [-35.490380122738223, -8.49325184719493],
              [-35.49040028706883, -8.493101183591778],
              [-35.490354580467042, -8.492994834577985],
              [-35.490089930676611, -8.492774650209856],
              [-35.489934949487662, -8.492667215968316],
              [-35.4898482608533, -8.492589592476339],
              [-35.489757515221768, -8.492461729141949],
              [-35.489730867675199, -8.492339361162614],
              [-35.489798415811805, -8.492040117136524],
              [-35.489846904613636, -8.491764572354514],
              [-35.489872132024566, -8.491533908449146],
              [-35.489869746788401, -8.491416998420757],
              [-35.489819329211038, -8.491337526216284],
              [-35.489719948432217, -8.491273007746926],
              [-35.489573043331092, -8.4912475450688],
              [-35.489418982884992, -8.491182974703376],
              [-35.489376195472374, -8.49111763799608],
              [-35.489345484344199, -8.490981218759975],
              [-35.489367040566052, -8.490763957017018],
              [-35.489443929980133, -8.49059110360051],
              [-35.489577319188726, -8.490396329262806],
              [-35.489607641245897, -8.490306334422188],
              [-35.489631466384544, -8.490173674325083],
              [-35.489653246054381, -8.490014606271746],
              [-35.489703598910296, -8.48981977412223],
              [-35.489817733484692, -8.489497258279307],
              [-35.489982956674915, -8.489009472395427],
              [-35.490026954184735, -8.488811214405752],
              [-35.490041812914072, -8.488690012642349],
              [-35.490027396300071, -8.488404787438739],
              [-35.490030253153201, -8.488266749619994],
              [-35.49011229322722, -8.487877369697712],
              [-35.490215722878766, -8.487606670111708],
              [-35.490288896919822, -8.487478148755345],
              [-35.490306087876796, -8.487369548275927],
              [-35.49029057850889, -8.487261501989707],
              [-35.490246891616209, -8.487164497707337],
              [-35.490084333184036, -8.486958647857879],
              [-35.489985916848823, -8.486800772727129],
              [-35.489916618029966, -8.486594452801221],
              [-35.489901175471402, -8.486356392475454],
              [-35.489899528699603, -8.486100907810719],
              [-35.489918926946636, -8.485939867442912],
              [-35.489969808028441, -8.485718671576432],
              [-35.489980300239985, -8.485597755778086],
              [-35.489971061737023, -8.485432374758542],
              [-35.489988375468918, -8.485292935241551],
              [-35.490036885510015, -8.485168954018166],
              [-35.490157093442171, -8.484923851429693],
              [-35.4903072381274, -8.484732425951284],
              [-35.490433978198112, -8.484609246756351],
              [-35.490537250441605, -8.484521999092257],
              [-35.490609034901333, -8.484429985130642],
              [-35.490675496032686, -8.484302549983154],
              [-35.490774079830743, -8.484064297659398],
              [-35.490844647813695, -8.483850781429663],
              [-35.490918099859748, -8.483708795470449],
              [-35.490985611421451, -8.483621499697747],
              [-35.49108884379001, -8.483524141158375],
              [-35.491228141166381, -8.483455128021289],
              [-35.491396220835085, -8.483413083463059],
              [-35.491546426816697, -8.483389809301876],
              [-35.491744757734871, -8.483392181170814],
              [-35.491966477090088, -8.483382718671828],
              [-35.492117422328356, -8.483371760869193],
              [-35.492295199778901, -8.483377075989928],
              [-35.492564205294229, -8.483417077334765],
              [-35.492742522034327, -8.48344165595997],
              [-35.492952644087005, -8.483442085186205],
              [-35.49333860219533, -8.483427103319102],
              [-35.493705680531669, -8.483372753844318],
              [-35.493989169297819, -8.483338349283708],
              [-35.494265089733624, -8.483321114573702],
              [-35.494415314513951, -8.483326574781408],
              [-35.494626313782227, -8.483344936034099],
              [-35.494758333932388, -8.483377239036665],
              [-35.494894194496823, -8.483397705691244],
              [-35.495037883074346, -8.483418947286188],
              [-35.495298485959559, -8.483406911877827],
              [-35.495778329958355, -8.483043889998656],
              [-35.497158329958218, -8.480269719998617],
              [-35.501192499957881, -8.48505166999861],
              [-35.505689999957497, -8.489947779998586],
              [-35.507121939957351, -8.486766669998632],
              [-35.509016939957185, -8.482281109998619],
              [-35.509088329957152, -8.482063889998591],
              [-35.509386109957141, -8.481817779998599],
              [-35.510841109956992, -8.476612219998582],
              [-35.511020570802714, -8.47624128439042],
              [-35.511107524010654, -8.475994937339172],
              [-35.511099844440601, -8.475845377012028],
              [-35.511075157060858, -8.475762716202704],
              [-35.511020641219851, -8.475632158490493],
              [-35.51100039644534, -8.474993775664743],
              [-35.511036644540781, -8.474790500597198],
              [-35.510862694198856, -8.474449654637263],
              [-35.510134598594618, -8.471416723667462],
              [-35.509791789775186, -8.469864053109758],
              [-35.509299764445984, -8.468154690405161],
              [-35.509244440318, -8.466971109640429],
              [-35.511006109956938, -8.462874999998633],
              [-35.506908329957312, -8.460813609998606],
              [-35.504406669957532, -8.459803059998597],
              [-35.500765559957848, -8.45833166999866],
              [-35.500669719957855, -8.45616360999861],
              [-35.50060056735952, -8.455215729645527],
              [-35.500672663932406, -8.45474506260787],
              [-35.500686419849565, -8.454381633593936],
              [-35.500668012253961, -8.454023753447201],
              [-35.500600687617947, -8.453570007453173],
              [-35.500761072182819, -8.451394278977528],
              [-35.500795247013563, -8.451284523911243],
              [-35.500948795826822, -8.4501294003627],
              [-35.500947177025267, -8.449878277911651],
              [-35.500833345477353, -8.449285904408216],
              [-35.500727256806485, -8.449062165683388],
              [-35.500578393082101, -8.448862744810015],
              [-35.500351839146177, -8.448682521515849],
              [-35.500275678566076, -8.448522710406349],
              [-35.500295835401268, -8.448319538002105],
              [-35.500329476749485, -8.448126965975955],
              [-35.500333947931381, -8.447988012816742],
              [-35.499811008961608, -8.446783776864814],
              [-35.499169501197088, -8.445916921348058],
              [-35.498944016264204, -8.445485556504428],
              [-35.498746033702972, -8.445158209464951],
              [-35.498404719958032, -8.444779719998628],
              [-35.498606109958004, -8.444383329998621],
              [-35.498911389957982, -8.443866109998615],
              [-35.500425638316862, -8.442227399209882],
              [-35.502164039153101, -8.440924701874236],
              [-35.510551669956911, -8.433308889998623],
              [-35.517794439956248, -8.426331109998589],
              [-35.516999537879428, -8.425420946558869],
              [-35.515252644061171, -8.423481315951429],
              [-35.514708009126615, -8.422898837336925],
              [-35.514436707382181, -8.422438891888778],
              [-35.513660811411242, -8.421750352927633],
              [-35.512764944416354, -8.420673058127196],
              [-35.511902140421576, -8.419804853473847],
              [-35.511359540484733, -8.419189470233629],
              [-35.510894798239825, -8.4187150402998],
              [-35.510590446220021, -8.41832995663534],
              [-35.508267326889047, -8.415698920660523],
              [-35.506365744662958, -8.41377874556596],
              [-35.504818998929878, -8.412253599932541],
              [-35.501451177565677, -8.415132056594439],
              [-35.497939935766645, -8.41844479694201],
              [-35.497026453724324, -8.419291506614998],
              [-35.496481364105961, -8.419743934730626],
              [-35.495952434031125, -8.420225630298374],
              [-35.495742122207979, -8.419994803903686],
              [-35.4956665574921, -8.419901298294944],
              [-35.494701789044534, -8.41889238044908],
              [-35.494385941688911, -8.418422739833257],
              [-35.493215519070219, -8.417412772445996],
              [-35.49234087036119, -8.418188737417141],
              [-35.491925085785525, -8.418539123995139],
              [-35.49032650823505, -8.420074640765078],
              [-35.489895127238853, -8.420498777826106],
              [-35.488835075739139, -8.421421322012391],
              [-35.488806556265558, -8.421446354932279],
              [-35.488522002773443, -8.421740239051294],
              [-35.487946288018698, -8.422352286822996],
              [-35.48422315085125, -8.425870395569859],
              [-35.482270095076714, -8.42759261913042],
              [-35.485502242414171, -8.431210956111292],
              [-35.482078800367042, -8.433353937997795],
              [-35.481374412942301, -8.433765825213886],
              [-35.480182732088593, -8.434552156257118],
              [-35.478861823199175, -8.435271174589422],
              [-35.476632266018669, -8.436562326813362],
              [-35.473566482949479, -8.438425145495422],
              [-35.472378442317172, -8.438945605710229],
              [-35.470841413042116, -8.436908789314902],
              [-35.470592256618424, -8.436547010601007],
              [-35.468911174947266, -8.434639329937928],
              [-35.467901961628662, -8.433742652306597],
              [-35.46752280079798, -8.433189326604962],
              [-35.463884561803255, -8.432795419404671],
              [-35.46270301626172, -8.432647882862831],
              [-35.460665002945312, -8.432417551320205],
              [-35.459565819251672, -8.432319274561713],
              [-35.459784078803416, -8.432989722670936],
              [-35.459720771956938, -8.433058586601749],
              [-35.460407579501954, -8.43408920214006],
              [-35.460595861399099, -8.434406833373757],
              [-35.460636725508877, -8.434667123088918],
              [-35.460684080652641, -8.435540499966319],
              [-35.460614011808801, -8.4369286403518],
              [-35.460626197540002, -8.437203630129432],
              [-35.460550125433748, -8.437510801344702],
              [-35.460005166831955, -8.438562410656939],
              [-35.459898977586469, -8.438722510288052],
              [-35.457573474208502, -8.438847364727645],
              [-35.457106862762728, -8.440709534793147],
              [-35.457222824653506, -8.441609225190273],
              [-35.457893168391955, -8.442353663966781],
              [-35.459140405610377, -8.443910306147448],
              [-35.45938837797496, -8.444690080294109],
              [-35.459202033306312, -8.444889720947465],
              [-35.459029673133188, -8.445309907719013],
              [-35.459081601705016, -8.44620474444481],
              [-35.459152443683962, -8.446537761567248],
              [-35.459349601090835, -8.446999499609435],
              [-35.459667151599788, -8.447518168889475],
              [-35.460313517527474, -8.448349006605067],
              [-35.46058842117958, -8.449296396271823],
              [-35.460594647333707, -8.449922682441484],
              [-35.460930521009978, -8.450652468211834],
              [-35.458100081230427, -8.45126304338044],
              [-35.455800811318014, -8.451159018613454],
              [-35.455469872173943, -8.451111326701044],
              [-35.453020305891755, -8.449340399989863],
              [-35.452292410881213, -8.448294936062835],
              [-35.452119397451042, -8.447739003506726],
              [-35.4520294002239, -8.446837046058794],
              [-35.452105759298789, -8.44657406971816],
              [-35.452022645513246, -8.44653972406282],
              [-35.45113947414098, -8.444475928756619],
              [-35.450439678535176, -8.442765897810265],
              [-35.449722262574916, -8.441507436899879],
              [-35.449599828040526, -8.441148893816973],
              [-35.449105190526183, -8.44063873452277],
              [-35.44864028832508, -8.440299906998943],
              [-35.448524440433154, -8.440116234084977],
              [-35.448441388463756, -8.440159111392763],
              [-35.447592558439474, -8.440050317642568],
              [-35.446663527661805, -8.439098353091396],
              [-35.446477441858242, -8.438781307560381],
              [-35.44561208804474, -8.438140759469896],
              [-35.445329350727754, -8.438075023262508],
              [-35.444774710835496, -8.437741170743504],
              [-35.443466908986196, -8.437243112804719],
              [-35.443168522155503, -8.437010993687084],
              [-35.442833273356513, -8.436845231617832],
              [-35.441642645531026, -8.436109906019363],
              [-35.440851646562216, -8.435772849051519],
              [-35.439608602288835, -8.435235550811715],
              [-35.435994483680041, -8.432863053115254],
              [-35.434427923037155, -8.431959541044799],
              [-35.429163919785083, -8.428047080758724],
              [-35.428299678881793, -8.426486868088249],
              [-35.426945314606861, -8.425119705560776],
              [-35.426223603832604, -8.424488865874174],
              [-35.426016057594637, -8.424093085071291],
              [-35.425443446584936, -8.423620147041801],
              [-35.421237508717915, -8.419664116254562],
              [-35.418554696328556, -8.416957909559491],
              [-35.416045237295016, -8.414412270973742],
              [-35.416080680241194, -8.410940556290917],
              [-35.416251148761759, -8.409033588825364],
              [-35.415749672185719, -8.408083709679387],
              [-35.414064583258806, -8.406874493490704],
              [-35.412431557244837, -8.405510951004731],
              [-35.409700686518057, -8.402304339696144],
              [-35.408610832819924, -8.401171447882662],
              [-35.406456818708449, -8.398934622270529],
              [-35.404566418313713, -8.397215321696667],
              [-35.404380129635783, -8.397044471800124],
              [-35.404173084374456, -8.396774785651548],
              [-35.403540348212985, -8.396086088741297],
              [-35.400651396316434, -8.392874530648728],
              [-35.398698029056547, -8.391218423763053],
              [-35.398142306265513, -8.390668154185892],
              [-35.395408323236552, -8.38773178479147],
              [-35.389931138388292, -8.392636446944003],
              [-35.389750354843379, -8.392881460672088],
              [-35.388736831807968, -8.392484860252855],
              [-35.387640439291289, -8.392043378857503],
              [-35.385664665679258, -8.391249851131553],
              [-35.383987032979611, -8.390772168499257],
              [-35.381965596240285, -8.389961877001177],
              [-35.381320864905284, -8.389789925055547],
              [-35.381372316600981, -8.389352829771511],
              [-35.381395564341886, -8.388961285109204],
              [-35.381388658668065, -8.388762788470515],
              [-35.381261651432588, -8.388485602071722],
              [-35.38040523179648, -8.387225801126645],
              [-35.379526172164589, -8.38598882350775],
              [-35.379187464437152, -8.385556918660328],
              [-35.378804017299615, -8.385261424070576],
              [-35.378509497688746, -8.385070334489729],
              [-35.378178195509214, -8.384919174212564],
              [-35.377807711020054, -8.38488170065078],
              [-35.377365977319378, -8.384368162784961],
              [-35.376181978052578, -8.382628154466499],
              [-35.375787195503179, -8.382341232602656],
              [-35.375147694757224, -8.381855826355952],
              [-35.374568644319702, -8.381241004168089],
              [-35.373777691596707, -8.379498616831244],
              [-35.37374114147822, -8.379107430268768],
              [-35.3720977412815, -8.378629472529015],
              [-35.370763128856559, -8.378348191836464],
            ],
          ],
          [
            [
              [-35.586725373615515, -8.535442591277288],
              [-35.585214576906175, -8.535463002657956],
              [-35.57609009001218, -8.536281991778511],
              [-35.575269813273181, -8.536213067516185],
              [-35.574384479025312, -8.53603714072208],
              [-35.574209730932132, -8.535976321065757],
              [-35.573502682185435, -8.535904835849969],
              [-35.572787914260282, -8.535916846507606],
              [-35.572723823414385, -8.53661632511812],
              [-35.572734395262479, -8.536955082232026],
              [-35.572619798202616, -8.537740414325311],
              [-35.57236175306808, -8.538410427089596],
              [-35.572287927974699, -8.538778210732868],
              [-35.572069076681714, -8.539801827736264],
              [-35.571542509772797, -8.541751516337511],
              [-35.571385887033436, -8.542382692990628],
              [-35.570774775647983, -8.543950729715331],
              [-35.570697406793897, -8.544794180697235],
              [-35.57085071561773, -8.545371648894113],
              [-35.57003815729032, -8.549554543912103],
              [-35.569559886602093, -8.551440732378039],
              [-35.572206141424545, -8.551577129630124],
              [-35.572401919580308, -8.552666542532485],
              [-35.583521634913595, -8.557424247171902],
              [-35.582429208764921, -8.560086742420745],
              [-35.592554167068258, -8.56159262350373],
              [-35.593766328414453, -8.560369688983318],
              [-35.594901860896016, -8.558866293569885],
              [-35.594711128590667, -8.55767343534542],
              [-35.595458816509435, -8.556519729386391],
              [-35.59608333829555, -8.55618084711722],
              [-35.59616972425313, -8.553614317492721],
              [-35.596205130697541, -8.552713311646361],
              [-35.596333592252066, -8.55206786298346],
              [-35.596775660740171, -8.549920406835147],
              [-35.590720185485267, -8.547059834888355],
              [-35.593827749850547, -8.536726848041106],
              [-35.591006450791618, -8.536278609662828],
              [-35.587874970936419, -8.535720023728711],
              [-35.586725373615515, -8.535442591277288],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0266000',
        uf: 'PE',
        nome_proje: 'PA MATA VERDE',
        municipio: 'IGUARACI',
        area_hecta: '1706.7606',
        capacidade: 41.0,
        num_famili: 39.0,
        fase: 4.0,
        data_de_cr: '30/10/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 1703.6484,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.148090240655677, -7.767306754716527],
              [-37.148397268679908, -7.776651714581966],
              [-37.149313862628212, -7.778414780006007],
              [-37.149551846790835, -7.780796549760693],
              [-37.153123653395625, -7.786242583851046],
              [-37.154357289406484, -7.787487002813362],
              [-37.158521471915748, -7.793416892688199],
              [-37.163439834687111, -7.794361060256175],
              [-37.163171165044524, -7.79629738313631],
              [-37.164607332977766, -7.796869379244287],
              [-37.165676732705343, -7.797805279576544],
              [-37.16691435768108, -7.798874558672863],
              [-37.16862696795009, -7.800949197970604],
              [-37.16892646861595, -7.80318892288351],
              [-37.171240741644731, -7.80331603574091],
              [-37.173289973548023, -7.80025849196685],
              [-37.174309038979018, -7.799578146480748],
              [-37.17567786465257, -7.798928671348105],
              [-37.176945008445244, -7.798463048709102],
              [-37.178485272922536, -7.797120912615844],
              [-37.17993965733416, -7.79624262976729],
              [-37.180129166065477, -7.796219195871273],
              [-37.180281307862884, -7.796035987606262],
              [-37.180741339596793, -7.795834934940808],
              [-37.18049652088002, -7.793766409031664],
              [-37.180538814814597, -7.793171687448217],
              [-37.181647674746792, -7.791751742105308],
              [-37.182971861852629, -7.79176076035304],
              [-37.185291590505088, -7.792156756341714],
              [-37.187828276795486, -7.791525561224768],
              [-37.189459064631215, -7.789966848554987],
              [-37.191873826795636, -7.791038009414049],
              [-37.195592089504416, -7.795008461235403],
              [-37.199495250582238, -7.798254713619417],
              [-37.199624302947861, -7.799767774439691],
              [-37.200467401724168, -7.800070863001809],
              [-37.20137785264032, -7.800199793227101],
              [-37.201441816530632, -7.800156241819969],
              [-37.201697568363947, -7.800233321045027],
              [-37.204333707698261, -7.800932958802507],
              [-37.204674691865712, -7.801200537413886],
              [-37.204759890837934, -7.801396426822078],
              [-37.207600603914187, -7.802405695080811],
              [-37.207917412306813, -7.802515940287596],
              [-37.209314500263289, -7.803393189830659],
              [-37.21103594235845, -7.803891817630143],
              [-37.211687334938361, -7.803736637604653],
              [-37.213319429318879, -7.804568298009173],
              [-37.214093712630167, -7.80446628138742],
              [-37.215567052477034, -7.804417655079011],
              [-37.216590945369212, -7.804382670794569],
              [-37.217220931465981, -7.804948949103123],
              [-37.217516547155952, -7.805057615955387],
              [-37.217802150595809, -7.804931769927242],
              [-37.218032242940588, -7.805017800947165],
              [-37.218231988433125, -7.805228710372096],
              [-37.21887037281931, -7.806133360599062],
              [-37.218979838710837, -7.806179733906026],
              [-37.219434900503444, -7.806303193926992],
              [-37.220073838082364, -7.806298442877283],
              [-37.224255796243114, -7.804282907337049],
              [-37.224289994566277, -7.804061346088331],
              [-37.224888845535396, -7.803889975012063],
              [-37.22498801239022, -7.804056807742388],
              [-37.225422673024717, -7.804428299608461],
              [-37.227023056672749, -7.804753053086302],
              [-37.228482751940604, -7.806099419555853],
              [-37.229611262627159, -7.806347821978529],
              [-37.23321417629397, -7.805974867852068],
              [-37.234069964445787, -7.800605971950225],
              [-37.232016393002759, -7.800383539016613],
              [-37.23091922471864, -7.799412964002512],
              [-37.229952336135518, -7.797996564413245],
              [-37.229137142991867, -7.797595466474568],
              [-37.228887471625434, -7.797631537083213],
              [-37.22880792819322, -7.797724105171708],
              [-37.227581235733865, -7.797388225516788],
              [-37.226869022328373, -7.797164468980169],
              [-37.226851581081775, -7.796968223765973],
              [-37.225364863092651, -7.795785829976208],
              [-37.223413082810808, -7.794454450218727],
              [-37.223065034468895, -7.794079470573032],
              [-37.222927445799918, -7.793954293373748],
              [-37.222895547194568, -7.793841657079899],
              [-37.222899945191493, -7.793493264250442],
              [-37.219934576017081, -7.791322698822482],
              [-37.219831025490599, -7.791140847801532],
              [-37.219694268747162, -7.790462999176625],
              [-37.219459124873268, -7.790331823793409],
              [-37.21948707175396, -7.790190804837889],
              [-37.217991491816811, -7.789312076213709],
              [-37.217474437131628, -7.789105502550424],
              [-37.21740382093396, -7.789067882598941],
              [-37.215287118227131, -7.786977853022539],
              [-37.212397306042156, -7.784934123247834],
              [-37.21118701544642, -7.7847589865533],
              [-37.20832096900169, -7.784767698089719],
              [-37.206112031092239, -7.785195624461617],
              [-37.205838628084202, -7.785399507181928],
              [-37.205766542852309, -7.785549957508231],
              [-37.204956716432868, -7.785119163192604],
              [-37.204853056707627, -7.785184246891869],
              [-37.203875388593744, -7.784478064376854],
              [-37.202924502237494, -7.784297656895336],
              [-37.202914164822097, -7.783921728723995],
              [-37.20242338121944, -7.783272687001161],
              [-37.201075376396204, -7.783677510141094],
              [-37.19920735877799, -7.78364071234769],
              [-37.198600337691303, -7.78355815461512],
              [-37.198250972949324, -7.783745371516423],
              [-37.178957310040246, -7.777485314906071],
              [-37.178696911236855, -7.777267279998274],
              [-37.178358026997408, -7.777319802036818],
              [-37.177063690595368, -7.776870935472466],
              [-37.17703939095982, -7.77700854680931],
              [-37.173937351661614, -7.775568495564751],
              [-37.173827931670395, -7.775853894874181],
              [-37.148090240655677, -7.767306754716527],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0168000',
        uf: 'PE',
        nome_proje: 'PA GILVAN SANTOS',
        municipio: 'SERRA TALHADA',
        area_hecta: '1008.0969',
        capacidade: 40.0,
        num_famili: 37.0,
        fase: 3.0,
        data_de_cr: '28/03/2006',
        forma_obte: 'Desapropriação',
        data_obten: '28/03/2006',
        area_calc_: 1008.7683,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.32253016627152, -8.130305146571251],
              [-38.324935528314157, -8.112802865264268],
              [-38.324354415806013, -8.112446526540381],
              [-38.321670785773691, -8.111260799706521],
              [-38.320168638547884, -8.11018439222841],
              [-38.316623971731126, -8.109963647006571],
              [-38.310394913927844, -8.109093978709369],
              [-38.303522128468011, -8.10768621289686],
              [-38.301057698273503, -8.104030661878902],
              [-38.298377886173192, -8.102237976227133],
              [-38.298148858901612, -8.112154668581399],
              [-38.29803305099685, -8.117171937758251],
              [-38.298019999828092, -8.117736135065655],
              [-38.297862278499451, -8.12456447704977],
              [-38.297945972911144, -8.127484398933602],
              [-38.297943148729864, -8.127647020973924],
              [-38.297942435528853, -8.127691791891095],
              [-38.297872020911612, -8.131803200255957],
              [-38.297862621763521, -8.132353745645634],
              [-38.297817254056802, -8.135004720308105],
              [-38.297761875915675, -8.138242780015288],
              [-38.299493565302704, -8.138823014280243],
              [-38.300995846412185, -8.139792138357722],
              [-38.30336434158864, -8.139612416228982],
              [-38.306914812721665, -8.139343164718143],
              [-38.309258924857645, -8.138877185691586],
              [-38.309495248644062, -8.138900923374468],
              [-38.309971051367803, -8.13890815390862],
              [-38.310718928741068, -8.138893414381897],
              [-38.310800420908635, -8.138904587451876],
              [-38.310852311088638, -8.138922222604004],
              [-38.311640946634249, -8.139331484941968],
              [-38.310728930252615, -8.141273053228048],
              [-38.312136777000291, -8.141805652699217],
              [-38.312282037022598, -8.14189607641859],
              [-38.316332464334621, -8.146298922029231],
              [-38.317146076298904, -8.146711744745856],
              [-38.317339037293102, -8.146723285113744],
              [-38.318826159261278, -8.145590105585773],
              [-38.319371666376895, -8.144801309750664],
              [-38.320312246115002, -8.143881752157808],
              [-38.320704142166115, -8.143590347953813],
              [-38.321308453098204, -8.139194263643073],
              [-38.321414042454265, -8.138424898567735],
              [-38.322090835941282, -8.133501432026513],
              [-38.322095981425974, -8.133464265987042],
              [-38.32253016627152, -8.130305146571251],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0281000',
        uf: 'PE',
        nome_proje: 'PA ADÃO PRETO',
        municipio: 'SERRA TALHADA',
        area_hecta: '599.4832',
        capacidade: 8.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '17/03/2009',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2007',
        area_calc_: 601.6899,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.339433665811988, -8.121975333857229],
              [-38.330284778474649, -8.135982565965453],
              [-38.330040849899035, -8.136657096727063],
              [-38.329735327547496, -8.137526172572286],
              [-38.327387824784175, -8.143955225020823],
              [-38.327875814357355, -8.144606273096803],
              [-38.327938969885366, -8.145707597377701],
              [-38.328782859245422, -8.146097745005537],
              [-38.328969863070803, -8.146252812983496],
              [-38.329013192207313, -8.146288702543604],
              [-38.331053384603315, -8.147980035874856],
              [-38.332351637278272, -8.148565210753334],
              [-38.333065427924026, -8.149084659530921],
              [-38.33377784376988, -8.150381426548977],
              [-38.335510445093448, -8.152128946625318],
              [-38.335915258969969, -8.153745759023002],
              [-38.336593887883879, -8.154017765915393],
              [-38.337364133753304, -8.153612726952701],
              [-38.338450171436698, -8.153930440285823],
              [-38.340077623277871, -8.155332526243013],
              [-38.339668674938608, -8.156279951051291],
              [-38.339395357979107, -8.157272640170772],
              [-38.340299498281652, -8.15808671059265],
              [-38.340992368858572, -8.15881459917455],
              [-38.342062861425362, -8.155770543979269],
              [-38.34246866663463, -8.154576364993828],
              [-38.342751987189473, -8.153788738127444],
              [-38.34347685384018, -8.151705594357132],
              [-38.352865650669727, -8.125048870999512],
              [-38.352746965884677, -8.125024532096745],
              [-38.340007458467049, -8.122106783531486],
              [-38.339954198449824, -8.122094576910762],
              [-38.339433665811988, -8.121975333857229],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0152000',
        uf: 'PE',
        nome_proje: 'PA MIGUEL ARRAES',
        municipio: 'PARNAMIRIM',
        area_hecta: '262.3045',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 3.0,
        data_de_cr: '13/09/2005',
        forma_obte: 'Desapropriação',
        data_obten: '13/09/2005',
        area_calc_: 262.4954,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.59156786527393, -7.987715062026012],
              [-39.591567878094786, -7.987715063247299],
              [-39.591567890917375, -7.987715064450374],
              [-39.591567903741662, -7.987715065635268],
              [-39.591567916567634, -7.987715066801947],
              [-39.591567929395246, -7.987715067950428],
              [-39.591567942224508, -7.987715069080697],
              [-39.591567955055353, -7.987715070192762],
              [-39.591567967887784, -7.987715071286617],
              [-39.591567980721749, -7.987715072362272],
              [-39.591567993557248, -7.987715073419698],
              [-39.591568006394255, -7.987715074458891],
              [-39.591568019232724, -7.987715075479885],
              [-39.59156803207263, -7.987715076482649],
              [-39.591568044913956, -7.98771507746718],
              [-39.591568057756689, -7.987715078433497],
              [-39.591568070600772, -7.987715079381584],
              [-39.591568083446219, -7.987715080311475],
              [-39.59156809629296, -7.987715081223099],
              [-39.591568109140994, -7.987715082116491],
              [-39.591568121990306, -7.98771508299167],
              [-39.591568134840827, -7.987715083848601],
              [-39.591568147692577, -7.987715084687302],
              [-39.591568160545521, -7.987715085507753],
              [-39.591568173399601, -7.987715086309959],
              [-39.591568186254833, -7.987715087093913],
              [-39.591568199111158, -7.987715087859621],
              [-39.591568211968571, -7.9877150886071],
              [-39.591568224827029, -7.987715089336314],
              [-39.591568237686516, -7.987715090047278],
              [-39.591568250547013, -7.987715090739997],
              [-39.591568263408483, -7.987715091414482],
              [-39.591568276270912, -7.987715092070689],
              [-39.591568289134258, -7.987715092708649],
              [-39.591568301998507, -7.987715093328341],
              [-39.591568314863622, -7.987715093929773],
              [-39.591568327729583, -7.987715094512989],
              [-39.591568340596368, -7.987715095077921],
              [-39.591568353463941, -7.987715095624593],
              [-39.591568366332289, -7.987715096153003],
              [-39.591568379201377, -7.987715096663145],
              [-39.591568392071174, -7.987715097155022],
              [-39.591568404941661, -7.987715097628654],
              [-39.591568417812816, -7.987715098084006],
              [-39.591568430684603, -7.987715098521092],
              [-39.591568443557009, -7.9877150989399],
              [-39.591568456430004, -7.987715099340441],
              [-39.591568469303546, -7.98771509972272],
              [-39.591568482177635, -7.987715100086735],
              [-39.591568495052201, -7.987715100432471],
              [-39.591568507927299, -7.987715100759925],
              [-39.591568520802802, -7.987715101069113],
              [-39.591568533678753, -7.987715101360022],
              [-39.591568546555109, -7.987715101632669],
              [-39.591568559431835, -7.987715101887034],
              [-39.591568572308915, -7.987715102123117],
              [-39.59156858518633, -7.987715102340957],
              [-39.591568598064022, -7.987715102540498],
              [-39.591568610941998, -7.987715102721761],
              [-39.591568623820216, -7.987715102884739],
              [-39.591568636698653, -7.98771510302946],
              [-39.591568649577283, -7.987715103155896],
              [-39.591568662456083, -7.987715103264054],
              [-39.591568675335019, -7.987715103353933],
              [-39.591568688214096, -7.987715103425527],
              [-39.591568701093237, -7.987715103478846],
              [-39.591568713972435, -7.987715103513886],
              [-39.59156872685169, -7.98771510353066],
              [-39.591568739730945, -7.987715103529151],
              [-39.591568752610186, -7.987715103509365],
              [-39.591568765489384, -7.9877151034713],
              [-39.591568778368519, -7.987715103414958],
              [-39.59156879124756, -7.987715103340332],
              [-39.591568804126474, -7.987715103247425],
              [-39.591568817005239, -7.987715103136243],
              [-39.591568829883848, -7.98771510300678],
              [-39.591568842762271, -7.987715102859054],
              [-39.591568855640432, -7.987715102693048],
              [-39.591568868518372, -7.987715102508761],
              [-39.591568881396029, -7.987715102306196],
              [-39.591568894273379, -7.987715102085348],
              [-39.591568907150403, -7.987715101846247],
              [-39.591568920027079, -7.987715101588859],
              [-39.591568932903357, -7.987715101313193],
              [-39.591568945779244, -7.987715101019266],
              [-39.591568958654683, -7.987715100707057],
              [-39.591568971529675, -7.987715100376571],
              [-39.591568984404184, -7.987715100027823],
              [-39.591568997278181, -7.987715099660795],
              [-39.59156901015163, -7.987715099275504],
              [-39.591569023024533, -7.987715098871938],
              [-39.591569035896832, -7.987715098450105],
              [-39.591569048768505, -7.98771509801001],
              [-39.591569061639568, -7.987715097551656],
              [-39.591569074509948, -7.98771509707502],
              [-39.591569087379632, -7.987715096580108],
              [-39.591569100248591, -7.987715096066966],
              [-39.591569113116812, -7.987715095535529],
              [-39.591569125984265, -7.987715094985846],
              [-39.591569138850915, -7.987715094417901],
              [-39.591569135729934, -7.987715150659758],
              [-39.592808260924521, -7.987659648551822],
              [-39.592808286546017, -7.987659646266999],
              [-39.592808339279401, -7.987659643754013],
              [-39.592808391997202, -7.987659640935246],
              [-39.592808444697667, -7.987659637810717],
              [-39.592808497379011, -7.987659634380647],
              [-39.592808550039443, -7.987659630645054],
              [-39.592808602677167, -7.987659626604096],
              [-39.592808655290433, -7.987659622257906],
              [-39.592808707877431, -7.987659617606658],
              [-39.592808760436391, -7.98765961265047],
              [-39.592808812965536, -7.987659607389549],
              [-39.592808865463084, -7.987659601824049],
              [-39.59280891792725, -7.987659595954174],
              [-39.592808970356273, -7.987659589780113],
              [-39.592809022748391, -7.987659583302063],
              [-39.592809075101798, -7.987659576520278],
              [-39.592809127414732, -7.987659569434963],
              [-39.592809179685432, -7.987659562046319],
              [-39.592809231912113, -7.987659554354677],
              [-39.592809284093015, -7.987659546360246],
              [-39.592809336226388, -7.987659538063296],
              [-39.592809388310457, -7.987659529464118],
              [-39.592809440343437, -7.987659520563031],
              [-39.592809492323589, -7.987659511360294],
              [-39.592809544249143, -7.987659501856213],
              [-39.592809596118364, -7.987659492051139],
              [-39.592809647929442, -7.987659481945399],
              [-39.592809699680679, -7.987659471539333],
              [-39.592809751370311, -7.987659460833282],
              [-39.592809802996577, -7.987659449827628],
              [-39.592809854557714, -7.987659438522734],
              [-39.592809906052025, -7.98765942691901],
              [-39.592809957477733, -7.9876594150168],
              [-39.592810008833091, -7.987659402816523],
              [-39.592810060116364, -7.987659390318615],
              [-39.592810111325832, -7.987659377523467],
              [-39.592810162459756, -7.987659364431534],
              [-39.592810213516387, -7.987659351043259],
              [-39.59281026449402, -7.98765933735908],
              [-39.592810315390921, -7.987659323379456],
              [-39.59281036620537, -7.987659309104895],
              [-39.592810416935642, -7.987659294535837],
              [-39.592810467580009, -7.987659279672807],
              [-39.59281051813678, -7.987659264516298],
              [-39.592810568604222, -7.987659249066811],
              [-39.592810618980643, -7.987659233324896],
              [-39.592810669264331, -7.987659217291043],
              [-39.592810719453581, -7.987659200965857],
              [-39.592810769546702, -7.987659184349827],
              [-39.592810819541988, -7.987659167443551],
              [-39.592810869437756, -7.987659150247603],
              [-39.592810919232299, -7.987659132762556],
              [-39.592810968923949, -7.987659114988987],
              [-39.592811018511043, -7.987659096927537],
              [-39.592811067991853, -7.987659078578763],
              [-39.592811117364732, -7.98765905994335],
              [-39.592811166628017, -7.987659041021897],
              [-39.592811215780024, -7.987659021815013],
              [-39.592811264819105, -7.9876590023234],
              [-39.592811313743567, -7.987658982547678],
              [-39.592811362551792, -7.987658962488555],
              [-39.592811411242124, -7.987658942146677],
              [-39.592811459812886, -7.987658921522752],
              [-39.592811508262457, -7.987658900617459],
              [-39.592811556589183, -7.987658879431524],
              [-39.592811604791429, -7.987658857965669],
              [-39.592811652867582, -7.987658836220594],
              [-39.592811700816007, -7.987658814197073],
              [-39.592811748635071, -7.987658791895813],
              [-39.592811796323161, -7.987658769317597],
              [-39.592811843878671, -7.987658746463184],
              [-39.592811891299966, -7.987658723333332],
              [-39.592811938585463, -7.987658699928816],
              [-39.592811985733562, -7.987658676250472],
              [-39.592812032742657, -7.987658652299055],
              [-39.592812079611143, -7.987658628075431],
              [-39.592812126337478, -7.987658603580353],
              [-39.592812172920041, -7.987658578814688],
              [-39.592812219357263, -7.987658553779296],
              [-39.592812265647581, -7.987658528474952],
              [-39.592812311789409, -7.987658502902588],
              [-39.592812357781206, -7.987658477063031],
              [-39.592812403621416, -7.987658450957177],
              [-39.592812449308468, -7.987658424585885],
              [-39.592812494840835, -7.987658397950055],
              [-39.592812540216961, -7.98765837105059],
              [-39.592812585435318, -7.987658343888411],
              [-39.592812630494372, -7.987658316464429],
              [-39.592812675392608, -7.987658288779597],
              [-39.592812720128471, -7.987658260834787],
              [-39.592812764700497, -7.987658232631029],
              [-39.592812809107151, -7.987658204169211],
              [-39.592812853346913, -7.987658175450319],
              [-39.59281289741832, -7.987658146475324],
              [-39.592812941319856, -7.987658117245214],
              [-39.592812985050053, -7.987658087760994],
              [-39.592813028607409, -7.987658058023639],
              [-39.592813071990456, -7.987658028034181],
              [-39.592813115197742, -7.987657997793556],
              [-39.592813158227798, -7.987657967302879],
              [-39.592813158402663, -7.98765801340977],
              [-39.593566322533377, -7.987121009528057],
              [-39.593566280463804, -7.987121016142506],
              [-39.593566287044176, -7.987121011446513],
              [-39.593566293620334, -7.987121006744663],
              [-39.593566300192279, -7.987121002036971],
              [-39.593566306760032, -7.987120997323442],
              [-39.593566313323542, -7.987120992604071],
              [-39.593566319882832, -7.987120987878843],
              [-39.593566326437895, -7.987120983147808],
              [-39.593566332988729, -7.98712097841095],
              [-39.593566339535307, -7.987120973668272],
              [-39.593566346077658, -7.987120968919767],
              [-39.593566352615738, -7.987120964165456],
              [-39.593566359149584, -7.987120959405339],
              [-39.593566365679145, -7.987120954639416],
              [-39.593566372204457, -7.987120949867686],
              [-39.593566378725491, -7.98712094509012],
              [-39.593566385242227, -7.987120940306775],
              [-39.593566391754713, -7.987120935517645],
              [-39.593566398262894, -7.987120930722723],
              [-39.593566404766797, -7.987120925922015],
              [-39.593566411266387, -7.987120921115545],
              [-39.593566417761679, -7.98712091630329],
              [-39.593566424252671, -7.987120911485246],
              [-39.593566430739337, -7.98712090666146],
              [-39.593566437221696, -7.987120901831885],
              [-39.593566443699714, -7.987120896996559],
              [-39.593566450173419, -7.987120892155473],
              [-39.593566456642783, -7.987120887308614],
              [-39.593566463107813, -7.987120882456034],
              [-39.593566469568493, -7.987120877597699],
              [-39.593566476024819, -7.987120872733628],
              [-39.59356648247681, -7.98712086786381],
              [-39.593566488924431, -7.987120862988261],
              [-39.593566495367682, -7.987120858106969],
              [-39.593566501806556, -7.987120853219958],
              [-39.593566508241061, -7.987120848327204],
              [-39.593566514671195, -7.987120843428764],
              [-39.593566521096946, -7.987120838524604],
              [-39.593566527518298, -7.987120833614737],
              [-39.593566533935245, -7.987120828699163],
              [-39.593566540347808, -7.987120823777886],
              [-39.593566546755966, -7.987120818850888],
              [-39.593566553159704, -7.987120813918201],
              [-39.593566559559029, -7.987120808979827],
              [-39.593566565953928, -7.987120804035762],
              [-39.593566572344422, -7.987120799086028],
              [-39.59356657873046, -7.987120794130604],
              [-39.593566585112065, -7.987120789169492],
              [-39.593566591489235, -7.987120784202726],
              [-39.593566597861951, -7.987120779230305],
              [-39.593566604230233, -7.987120774252197],
              [-39.593566610594053, -7.987120769268436],
              [-39.593566616953375, -7.987120764279001],
              [-39.593566623308277, -7.987120759283929],
              [-39.593566629658696, -7.987120754283221],
              [-39.593566636004624, -7.987120749276873],
              [-39.59356664234609, -7.98712074426489],
              [-39.593566648683051, -7.987120739247264],
              [-39.593566655015536, -7.987120734223971],
              [-39.593566661343523, -7.987120729195077],
              [-39.593566667667005, -7.987120724160557],
              [-39.593566673985968, -7.987120719120417],
              [-39.593566680300427, -7.98712071407466],
              [-39.593566686610366, -7.987120709023278],
              [-39.593566692915786, -7.987120703966313],
              [-39.593566699216673, -7.987120698903738],
              [-39.593566705513062, -7.987120693835566],
              [-39.593566711804861, -7.987120688761787],
              [-39.593566718092148, -7.987120683682407],
              [-39.593566724374881, -7.987120678597453],
              [-39.593566730653066, -7.987120673506913],
              [-39.593566736926704, -7.987120668410801],
              [-39.593566743195765, -7.987120663309123],
              [-39.593566749460258, -7.987120658201856],
              [-39.593566755720168, -7.987120653089039],
              [-39.593566761975524, -7.987120647970633],
              [-39.593566768226289, -7.987120642846674],
              [-39.593566774472471, -7.987120637717161],
              [-39.593566780714063, -7.987120632582076],
              [-39.593566786951044, -7.987120627441474],
              [-39.593566793183435, -7.987120622295318],
              [-39.593566799411199, -7.987120617143628],
              [-39.593566805634389, -7.987120611986398],
              [-39.593566811852938, -7.987120606823633],
              [-39.593566818066869, -7.98712060165535],
              [-39.593566824276159, -7.987120596481546],
              [-39.593566830480853, -7.987120591302205],
              [-39.5935668366809, -7.987120586117364],
              [-39.593566842876285, -7.987120580927019],
              [-39.593566849067031, -7.987120575731173],
              [-39.593566855253123, -7.987120570529807],
              [-39.593566861434567, -7.987120565322933],
              [-39.593566867611344, -7.987120560110582],
              [-39.59356686630467, -7.987120556491424],
              [-39.594268046808594, -7.986528360323531],
              [-39.594795947259406, -7.9860824910311],
              [-39.56999289252505, -7.979635822586714],
              [-39.569632906455332, -7.98079002907346],
              [-39.569632890772475, -7.98078997817947],
              [-39.569632856953831, -7.980790089109417],
              [-39.569632824564302, -7.980790200462355],
              [-39.569632793609188, -7.980790312219988],
              [-39.569632764093605, -7.98079042436397],
              [-39.569632736022399, -7.980790536875876],
              [-39.569632709400167, -7.980790649737217],
              [-39.56963268423128, -7.980790762929439],
              [-39.569632660519886, -7.980790876433971],
              [-39.569632638269894, -7.980790990232118],
              [-39.56963261748492, -7.980791104305229],
              [-39.569632598168411, -7.980791218634565],
              [-39.569632580323535, -7.980791333201299],
              [-39.56963256395322, -7.980791447986642],
              [-39.569632549060145, -7.980791562971707],
              [-39.569632535646775, -7.980791678137637],
              [-39.569632523715306, -7.980791793465492],
              [-39.569632513267699, -7.980791908936329],
              [-39.569632504305666, -7.980792024531195],
              [-39.569632496830693, -7.980792140231052],
              [-39.569632490843986, -7.980792256016905],
              [-39.569632486346535, -7.98079237186975],
              [-39.569632483339113, -7.980792487770537],
              [-39.569632481822175, -7.980792603700221],
              [-39.569632481795963, -7.98079271963978],
              [-39.569632483260527, -7.980792835570163],
              [-39.569632486215582, -7.980792951472293],
              [-39.569632490660666, -7.980793067327141],
              [-39.569632496595055, -7.980793183115678],
              [-39.569632504017733, -7.980793298818873],
              [-39.569632512927527, -7.980793414417739],
              [-39.569632523322944, -7.980793529893272],
              [-39.569632535202309, -7.980793645226471],
              [-39.569632548563625, -7.980793760398416],
              [-39.569632563404731, -7.98079387539016],
              [-39.569632579723176, -7.980793990182836],
              [-39.569632597516282, -7.980794104757576],
              [-39.569632616781121, -7.980794219095567],
              [-39.56963263751453, -7.98079433317798],
              [-39.569632659713108, -7.980794446986144],
              [-39.5696326833732, -7.980794560501288],
              [-39.569632708490929, -7.98079467370481],
              [-39.569632735062157, -7.980794786578087],
              [-39.569632763082517, -7.980794899102602],
              [-39.569632792547416, -7.980795011259805],
              [-39.569632823452018, -7.980795123031333],
              [-39.56963285579122, -7.980795234398814],
              [-39.569632889559728, -7.980795345343918],
              [-39.569632924751993, -7.980795455848467],
              [-39.569632961362238, -7.980795565894232],
              [-39.569632999384432, -7.98079567546318],
              [-39.56963303881232, -7.980795784537313],
              [-39.56963307963948, -7.980795893098708],
              [-39.569633121859134, -7.98079600112952],
              [-39.569633165464381, -7.98079610861201],
              [-39.569633210448053, -7.980796215528505],
              [-39.569633256802753, -7.98079632186144],
              [-39.569633304520885, -7.98079642759336],
              [-39.569633353594597, -7.980796532706894],
              [-39.569633404015804, -7.980796637184709],
              [-39.569633455776255, -7.980796741009732],
              [-39.569633508867426, -7.980796844164853],
              [-39.569633563280611, -7.980796946633135],
              [-39.569633619006837, -7.980797048397749],
              [-39.569633676036993, -7.980797149441976],
              [-39.569633734361695, -7.980797249749211],
              [-39.569633793971327, -7.980797349302977],
              [-39.569633854856122, -7.980797448086889],
              [-39.569633917006094, -7.980797546084733],
              [-39.569633980410998, -7.980797643280433],
              [-39.569634045060432, -7.980797739658012],
              [-39.569634110943788, -7.980797835201593],
              [-39.569634178050222, -7.980797929895534],
              [-39.569634246368722, -7.980798023724271],
              [-39.569634315888038, -7.980798116672337],
              [-39.569634386596789, -7.980798208724526],
              [-39.569634458483307, -7.980798299865651],
              [-39.569634531535833, -7.980798390080779],
              [-39.56963460574233, -7.980798479355101],
              [-39.569634681090612, -7.98079856767389],
              [-39.569634757568309, -7.980798655022697],
              [-39.569634835162873, -7.98079874138711],
              [-39.569634913861506, -7.980798826753016],
              [-39.569634993651306, -7.980798911106342],
              [-39.569635074519176, -7.980798994433216],
              [-39.569635156451803, -7.98079907671997],
              [-39.569635239435755, -7.980799157953108],
              [-39.569635323457376, -7.980799238119242],
              [-39.569635408502869, -7.980799317205216],
              [-39.569635494558256, -7.980799395198022],
              [-39.569635581609404, -7.980799472084869],
              [-39.569635669642011, -7.980799547853158],
              [-39.569635758641617, -7.980799622490368],
              [-39.569635848593592, -7.980799695984271],
              [-39.569635939483149, -7.980799768322786],
              [-39.569636031295381, -7.98079983949402],
              [-39.569636124015176, -7.980799909486311],
              [-39.569636217627327, -7.980799978288136],
              [-39.569636312116415, -7.980800045888172],
              [-39.569636407466966, -7.980800112275372],
              [-39.569636459201973, -7.980800154771748],
              [-39.570161597797423, -7.981160786223882],
              [-39.57016157700658, -7.981160787517561],
              [-39.570161597283587, -7.981160801402967],
              [-39.570161617598274, -7.981160815233504],
              [-39.570161637950513, -7.981160829009093],
              [-39.570161658340133, -7.981160842729643],
              [-39.570161678766986, -7.98116085639501],
              [-39.570161699230944, -7.981160870005138],
              [-39.570161719731836, -7.981160883559903],
              [-39.570161740269526, -7.981160897059219],
              [-39.57016176084386, -7.981160910502973],
              [-39.570161781454658, -7.981160923891059],
              [-39.570161802101801, -7.981160937223416],
              [-39.570161822785138, -7.981160950499905],
              [-39.570161843504501, -7.981160963720446],
              [-39.570161864259745, -7.981160976884962],
              [-39.570161885050716, -7.98116098999333],
              [-39.570161905877271, -7.981161003045462],
              [-39.570161926739232, -7.981161016041237],
              [-39.570161947636457, -7.981161028980609],
              [-39.570161968568819, -7.981161041863441],
              [-39.570161989536125, -7.981161054689651],
              [-39.570162010538247, -7.981161067459152],
              [-39.570162031575016, -7.981161080171847],
              [-39.570162052646303, -7.981161092827632],
              [-39.570162073751909, -7.981161105426421],
              [-39.5701620948917, -7.981161117968118],
              [-39.570162116065539, -7.981161130452641],
              [-39.57016213727325, -7.981161142879881],
              [-39.570162158514677, -7.981161155249775],
              [-39.570162179789669, -7.98116116756219],
              [-39.570162201098078, -7.981161179817073],
              [-39.570162222439727, -7.981161192014326],
              [-39.570162243814472, -7.981161204153858],
              [-39.570162265222159, -7.98116121623558],
              [-39.570162286662622, -7.981161228259398],
              [-39.570162308135714, -7.981161240225212],
              [-39.570162329641271, -7.98116125213296],
              [-39.570162351179128, -7.98116126398252],
              [-39.570162372749145, -7.981161275773863],
              [-39.570162394351144, -7.981161287506849],
              [-39.570162415984967, -7.981161299181396],
              [-39.57016243765046, -7.981161310797425],
              [-39.570162459347479, -7.98116132235486],
              [-39.570162481075855, -7.981161333853623],
              [-39.570162502835416, -7.981161345293609],
              [-39.570162524626014, -7.981161356674755],
              [-39.570162546447484, -7.981161367996958],
              [-39.570162568299665, -7.981161379260151],
              [-39.570162590182406, -7.981161390464231],
              [-39.57016261209553, -7.981161401609154],
              [-39.57016263403888, -7.981161412694799],
              [-39.570162656012307, -7.981161423721113],
              [-39.570162678015656, -7.981161434687996],
              [-39.570162700048733, -7.981161445595347],
              [-39.570162722111377, -7.981161456443139],
              [-39.570162744203479, -7.981161467231246],
              [-39.570162766324806, -7.981161477959584],
              [-39.570162788475258, -7.981161488628125],
              [-39.570162810654644, -7.981161499236767],
              [-39.570162832862785, -7.981161509785425],
              [-39.570162855099532, -7.981161520274012],
              [-39.570162877364744, -7.981161530702462],
              [-39.570162899658214, -7.981161541070684],
              [-39.570162921979822, -7.981161551378629],
              [-39.570162944329361, -7.98116156162622],
              [-39.570162966706704, -7.981161571813358],
              [-39.57016298911168, -7.981161581939991],
              [-39.570163011544082, -7.981161592006019],
              [-39.570163034003812, -7.981161602011407],
              [-39.570163056490657, -7.981161611956038],
              [-39.570163079004452, -7.98116162183986],
              [-39.57016310154507, -7.981161631662806],
              [-39.570163124112305, -7.981161641424782],
              [-39.57016314670603, -7.981161651125729],
              [-39.570163169326023, -7.981161660765581],
              [-39.570163191972163, -7.981161670344262],
              [-39.570163214644282, -7.981161679861728],
              [-39.5701632373422, -7.981161689317869],
              [-39.570163260065733, -7.981161698712631],
              [-39.570163282814761, -7.981161708045941],
              [-39.570163305589077, -7.981161717317729],
              [-39.570163328388517, -7.981161726527953],
              [-39.570163351212926, -7.981161735676495],
              [-39.570163374062147, -7.981161744763319],
              [-39.570163396935989, -7.981161753788369],
              [-39.570163419834302, -7.981161762751565],
              [-39.570163442756893, -7.981161771652831],
              [-39.570163465703615, -7.981161780492116],
              [-39.570163488674289, -7.981161789269349],
              [-39.570163511668753, -7.981161797984464],
              [-39.570163534686841, -7.981161806637394],
              [-39.570163557728371, -7.981161815228091],
              [-39.570163580793171, -7.981161823756488],
              [-39.570163603881099, -7.981161832222511],
              [-39.570163626991956, -7.981161840626116],
              [-39.570163650125586, -7.98116184896722],
              [-39.570163673281826, -7.981161857245774],
              [-39.57016369646049, -7.981161865461701],
              [-39.570163719661416, -7.981161873614958],
              [-39.570163742884418, -7.981161881705495],
              [-39.570163776976813, -7.981161868642295],
              [-39.57097789506949, -7.981444312432375],
              [-39.570977884282073, -7.981444280925832],
              [-39.570977925686108, -7.981444295175888],
              [-39.570977967158683, -7.981444309226555],
              [-39.570978008698816, -7.981444323077469],
              [-39.570978050305541, -7.981444336728338],
              [-39.570978091977892, -7.981444350178813],
              [-39.570978133714888, -7.98144436342861],
              [-39.570978175515556, -7.981444376477376],
              [-39.570978217378936, -7.981444389324851],
              [-39.570978259304034, -7.981444401970714],
              [-39.570978301289884, -7.981444414414684],
              [-39.570978343335518, -7.981444426656453],
              [-39.570978385439929, -7.98144443869576],
              [-39.570978427602157, -7.981444450532309],
              [-39.57097846982122, -7.981444462165816],
              [-39.570978512096119, -7.981444473596026],
              [-39.570978554425878, -7.981444484822667],
              [-39.570978596809518, -7.981444495845465],
              [-39.570978639246036, -7.981444506664191],
              [-39.570978681734445, -7.981444517278569],
              [-39.570978724273765, -7.981444527688345],
              [-39.570978766863007, -7.981444537893312],
              [-39.57097880950117, -7.98144454789321],
              [-39.570978852187253, -7.981444557687782],
              [-39.570978894920273, -7.981444567276846],
              [-39.570978937699245, -7.981444576660155],
              [-39.57097898052313, -7.981444585837502],
              [-39.570979023390983, -7.981444594808637],
              [-39.570979066301767, -7.981444603573379],
              [-39.570979109254509, -7.981444612131539],
              [-39.570979152248185, -7.981444620482888],
              [-39.570979195281808, -7.981444628627257],
              [-39.570979238354361, -7.981444636564427],
              [-39.570979281464851, -7.981444644294248],
              [-39.570979324612274, -7.981444651816521],
              [-39.570979367795616, -7.981444659131058],
              [-39.570979411013887, -7.981444666237715],
              [-39.570979454266066, -7.981444673136303],
              [-39.570979497551136, -7.981444679826671],
              [-39.570979540868116, -7.981444686308675],
              [-39.570979584215976, -7.981444692582128],
              [-39.570979627593701, -7.981444698646928],
              [-39.570979671000295, -7.981444704502908],
              [-39.570979714434749, -7.981444710149953],
              [-39.57097975789604, -7.981444715587896],
              [-39.570979801383174, -7.981444720816622],
              [-39.570979844895078, -7.981444725836048],
              [-39.570979888430827, -7.981444730645999],
              [-39.570979931989335, -7.981444735246386],
              [-39.570979975569621, -7.981444739637107],
              [-39.570980019170669, -7.981444743818075],
              [-39.570980062791456, -7.981444747789158],
              [-39.570980106430952, -7.981444751550291],
              [-39.570980150088168, -7.981444755101374],
              [-39.570980193762061, -7.981444758442326],
              [-39.570980237451629, -7.981444761573078],
              [-39.57098028115584, -7.981444764493551],
              [-39.570980324873702, -7.981444767203671],
              [-39.570980368604168, -7.981444769703383],
              [-39.570980412346231, -7.981444771992594],
              [-39.570980456098866, -7.981444774071295],
              [-39.570980499861065, -7.981444775939439],
              [-39.570980543631791, -7.981444777596951],
              [-39.570980587410027, -7.981444779043824],
              [-39.570980631194779, -7.981444780280003],
              [-39.570980674985009, -7.981444781305473],
              [-39.57098071877968, -7.981444782120191],
              [-39.570980762577797, -7.981444782724172],
              [-39.570980806378323, -7.981444783117346],
              [-39.570980850180234, -7.981444783299753],
              [-39.570980893982522, -7.981444783271358],
              [-39.570980937784178, -7.981444783032178],
              [-39.57098098158415, -7.981444782582237],
              [-39.570981025381435, -7.981444781921532],
              [-39.570981069175012, -7.981444781050063],
              [-39.570981112963864, -7.98144477996784],
              [-39.570981156746967, -7.981444778674923],
              [-39.570981200523285, -7.981444777171314],
              [-39.570981244291815, -7.981444775457069],
              [-39.570981288051534, -7.981444773532219],
              [-39.570981331801427, -7.981444771396801],
              [-39.570981375540455, -7.981444769050869],
              [-39.570981419267646, -7.981444766494505],
              [-39.570981462981919, -7.981444763727727],
              [-39.570981506682294, -7.981444760750628],
              [-39.57098155036774, -7.981444757563253],
              [-39.570981594037235, -7.981444754165703],
              [-39.570981637689776, -7.981444750558051],
              [-39.570981681324334, -7.981444746740367],
              [-39.570981724939905, -7.98144474271275],
              [-39.570981768535468, -7.981444738475301],
              [-39.570981812109977, -7.981444734028078],
              [-39.570981855662453, -7.981444729371247],
              [-39.570981899191885, -7.981444724504907],
              [-39.570981942697237, -7.981444719429118],
              [-39.5709819861775, -7.98144471414403],
              [-39.570982029631651, -7.981444708649774],
              [-39.570982073058708, -7.981444702946479],
              [-39.570982116457628, -7.981444697034272],
              [-39.570982159827416, -7.981444690913257],
              [-39.5709821603131, -7.981444758807299],
              [-39.571548425583401, -7.981363384924955],
              [-39.571548392441059, -7.981363385090154],
              [-39.571548457691406, -7.981363375479298],
              [-39.571548522869946, -7.981363365395871],
              [-39.57154858797324, -7.981363354840409],
              [-39.571548652997841, -7.98136334381346],
              [-39.57154871794031, -7.981363332315596],
              [-39.571548782797215, -7.981363320347477],
              [-39.57154884756514, -7.981363307909656],
              [-39.571548912240658, -7.981363295002837],
              [-39.571548976820345, -7.981363281627694],
              [-39.571549041300784, -7.98136326778496],
              [-39.571549105678599, -7.981363253475328],
              [-39.571549169950352, -7.981363238699576],
              [-39.571549234112645, -7.981363223458469],
              [-39.571549298162125, -7.981363207752823],
              [-39.571549362095382, -7.981363191583497],
              [-39.571549425909041, -7.981363174951255],
              [-39.571549489599718, -7.981363157857047],
              [-39.571549553164068, -7.981363140301784],
              [-39.571549616598723, -7.981363122286385],
              [-39.571549679900343, -7.981363103811764],
              [-39.57154974306556, -7.981363084878931],
              [-39.571549806091042, -7.981363065488876],
              [-39.57154986897347, -7.981363045642639],
              [-39.571549931709512, -7.981363025341246],
              [-39.571549994295864, -7.981363004585785],
              [-39.571550056729194, -7.981362983377362],
              [-39.571550119006247, -7.98136296171704],
              [-39.57155018112369, -7.981362939606047],
              [-39.571550243078256, -7.981362917045501],
              [-39.571550304866669, -7.981362894036598],
              [-39.571550366485667, -7.981362870580585],
              [-39.571550427931996, -7.981362846678659],
              [-39.571550489202409, -7.981362822332074],
              [-39.571550550293665, -7.981362797542186],
              [-39.571550611202525, -7.98136277231024],
              [-39.571550671925785, -7.981362746637592],
              [-39.571550732460238, -7.981362720525616],
              [-39.571550792802675, -7.981362693975651],
              [-39.571550852949912, -7.981362666989142],
              [-39.571550912898786, -7.98136263956751],
              [-39.571550972646094, -7.981362611712151],
              [-39.571551032188708, -7.981362583424616],
              [-39.571551091523467, -7.981362554706341],
              [-39.571551150647252, -7.981362525558858],
              [-39.571551209556922, -7.981362495983715],
              [-39.571551268249372, -7.981362465982474],
              [-39.571551326721483, -7.981362435556731],
              [-39.571551384970199, -7.981362404708062],
              [-39.57155144299243, -7.981362373438108],
              [-39.571551500785084, -7.981362341748555],
              [-39.571551558345142, -7.981362309641038],
              [-39.571551615669541, -7.981362277117285],
              [-39.571551672755263, -7.981362244178966],
              [-39.571551729599278, -7.981362210827871],
              [-39.571551786198597, -7.98136217706574],
              [-39.571551842550221, -7.981362142894361],
              [-39.57155189865118, -7.981362108315541],
              [-39.571551954498496, -7.981362073331108],
              [-39.571552010089235, -7.9813620379429],
              [-39.571552065420434, -7.981362002152808],
              [-39.571552120489201, -7.981361965962706],
              [-39.571552175292602, -7.981361929374497],
              [-39.571552229827766, -7.981361892390129],
              [-39.571552284091773, -7.981361855011563],
              [-39.571552338081787, -7.981361817240767],
              [-39.571552391794938, -7.981361779079736],
              [-39.571552445228399, -7.981361740530494],
              [-39.57155249837934, -7.981361701595068],
              [-39.57155255124497, -7.981361662275516],
              [-39.571552603822475, -7.981361622573919],
              [-39.57155265610907, -7.98136158249238],
              [-39.571552708102018, -7.981361542033012],
              [-39.571552759798543, -7.981361501197948],
              [-39.571552811195936, -7.98136145998935],
              [-39.571552862291483, -7.981361418409393],
              [-39.571552913082463, -7.981361376460293],
              [-39.571552963566205, -7.981361334144222],
              [-39.571553013740029, -7.981361291463464],
              [-39.571553063601321, -7.981361248420256],
              [-39.571553113147409, -7.981361205016883],
              [-39.571553162375665, -7.981361161255601],
              [-39.571553211283522, -7.98136111713876],
              [-39.571553259868381, -7.981361072668683],
              [-39.571553308127683, -7.981361027847726],
              [-39.571553356058857, -7.981360982678234],
              [-39.571553403659401, -7.981360937162603],
              [-39.571553450926771, -7.98136089130325],
              [-39.571553497858474, -7.981360845102581],
              [-39.571553544452023, -7.981360798563052],
              [-39.571553590705001, -7.981360751687133],
              [-39.571553636614922, -7.98136070447726],
              [-39.571553682179356, -7.981360656935978],
              [-39.57155372739593, -7.981360609065761],
              [-39.571553772262227, -7.981360560869152],
              [-39.571553816775875, -7.98136051234869],
              [-39.57155386093455, -7.981360463506976],
              [-39.571553904735872, -7.981360414346553],
              [-39.571553948177574, -7.981360364870023],
              [-39.571553991257339, -7.981360315079999],
              [-39.571554046972146, -7.981360301807688],
              [-39.571794835274041, -7.981079931015948],
              [-39.571794837991519, -7.981079898586799],
              [-39.571794871679998, -7.98107985958862],
              [-39.571794905590501, -7.981079820782126],
              [-39.57179493972194, -7.981079782168592],
              [-39.571794974073249, -7.98107974374928],
              [-39.571795008643292, -7.981079705525438],
              [-39.571795043430932, -7.981079667498295],
              [-39.571795078435038, -7.981079629669071],
              [-39.57179511365451, -7.98107959203901],
              [-39.571795149088196, -7.981079554609311],
              [-39.571795184734952, -7.981079517381191],
              [-39.571795220593614, -7.981079480355858],
              [-39.571795256663016, -7.981079443534535],
              [-39.571795292942021, -7.981079406918363],
              [-39.571795329429406, -7.981079370508558],
              [-39.571795366124014, -7.981079334306289],
              [-39.571795403024666, -7.981079298312747],
              [-39.571795440130181, -7.981079262529099],
              [-39.571795477439338, -7.981079226956462],
              [-39.5717955149509, -7.981079191596028],
              [-39.571795552663708, -7.981079156448911],
              [-39.571795590576492, -7.981079121516297],
              [-39.571795628688065, -7.981079086799246],
              [-39.571795666997168, -7.981079052298957],
              [-39.571795705502566, -7.98107901801651],
              [-39.57179574420303, -7.981078983953015],
              [-39.571795783097265, -7.981078950109575],
              [-39.57179582218405, -7.98107891648728],
              [-39.571795861462121, -7.981078883087232],
              [-39.571795900930177, -7.981078849910494],
              [-39.571795940586966, -7.98107881695816],
              [-39.571795980431197, -7.981078784231278],
              [-39.571796020461569, -7.981078751730943],
              [-39.571796060676803, -7.981078719458172],
              [-39.571796101075591, -7.981078687414008],
              [-39.571796141656627, -7.981078655599532],
              [-39.571796182418574, -7.981078624015709],
              [-39.571796223360145, -7.981078592663628],
              [-39.571796264480007, -7.98107856154426],
              [-39.571796305776815, -7.981078530658595],
              [-39.57179634724924, -7.98107850000768],
              [-39.571796388895933, -7.981078469592481],
              [-39.571796430715587, -7.981078439414006],
              [-39.571796472706772, -7.981078409473223],
              [-39.57179651486819, -7.981078379771071],
              [-39.571796557198454, -7.981078350308532],
              [-39.571796599696199, -7.981078321086588],
              [-39.571796642360027, -7.98107829210614],
              [-39.571796685188595, -7.981078263368166],
              [-39.571796728180487, -7.981078234873549],
              [-39.571796771334341, -7.981078206623273],
              [-39.571796814648721, -7.981078178618197],
              [-39.571796858122248, -7.981078150859252],
              [-39.571796901753515, -7.981078123347348],
              [-39.571796945541102, -7.981078096083364],
              [-39.571796989483602, -7.981078069068143],
              [-39.571797033579593, -7.981078042302647],
              [-39.571797077827618, -7.981078015787671],
              [-39.571797122226286, -7.981077989524077],
              [-39.571797166774118, -7.981077963512758],
              [-39.571797211469708, -7.981077937754527],
              [-39.571797256311598, -7.981077912250208],
              [-39.571797301298318, -7.981077887000651],
              [-39.571797346428433, -7.98107786200666],
              [-39.571797391700471, -7.98107783726905],
              [-39.571797437112984, -7.98107781278863],
              [-39.571797482664493, -7.981077788566165],
              [-39.571797528353507, -7.981077764602455],
              [-39.571797574178568, -7.98107774089827],
              [-39.57179762013817, -7.981077717454404],
              [-39.57179766623085, -7.981077694271576],
              [-39.571797712455101, -7.981077671350569],
              [-39.571797758809431, -7.981077648692124],
              [-39.571797805292334, -7.981077626296935],
              [-39.571797851902311, -7.981077604165779],
              [-39.571797898637854, -7.981077582299329],
              [-39.571797945497444, -7.981077560698319],
              [-39.571797992479574, -7.981077539363453],
              [-39.571798039582703, -7.981077518295405],
              [-39.57179808680533, -7.981077497494855],
              [-39.571798134145922, -7.981077476962477],
              [-39.571798181602922, -7.981077456698949],
              [-39.571798229174824, -7.981077436704928],
              [-39.571798276860079, -7.981077416981059],
              [-39.571798324657138, -7.981077397527967],
              [-39.571798372564452, -7.981077378346289],
              [-39.571798420580471, -7.98107735943664],
              [-39.571798468703655, -7.981077340799665],
              [-39.571798516932425, -7.981077322435928],
              [-39.571798565265233, -7.98107730434603],
              [-39.571798613700501, -7.981077286530562],
              [-39.571798662236681, -7.981077268990111],
              [-39.571798710872187, -7.981077251725237],
              [-39.57179875960545, -7.981077234736503],
              [-39.571798808434885, -7.981077218024445],
              [-39.571798857358928, -7.981077201589638],
              [-39.571798906375975, -7.981077185432574],
              [-39.571798955484446, -7.981077169553779],
              [-39.571799004682759, -7.981077153953793],
              [-39.571799053969535, -7.981077138632233],
              [-39.571799096113665, -7.981077211581558],
              [-39.572323963965715, -7.980915663268885],
              [-39.572323930989782, -7.980915612358616],
              [-39.572323997721277, -7.980915592102281],
              [-39.572324064607002, -7.980915572357954],
              [-39.572324131643022, -7.980915553126796],
              [-39.572324198825378, -7.980915534409949],
              [-39.57232426615009, -7.980915516208519],
              [-39.572324333613189, -7.980915498523586],
              [-39.572324401210658, -7.980915481356169],
              [-39.572324468938547, -7.980915464707317],
              [-39.572324536792827, -7.98091544857798],
              [-39.572324604769484, -7.980915432969137],
              [-39.572324672864497, -7.980915417881726],
              [-39.572324741073871, -7.980915403316605],
              [-39.57232480939355, -7.980915389274634],
              [-39.572324877819497, -7.980915375756664],
              [-39.572324946347678, -7.980915362763466],
              [-39.572325014974027, -7.980915350295836],
              [-39.572325083694523, -7.980915338354504],
              [-39.572325152505044, -7.980915326940202],
              [-39.572325221401584, -7.980915316053555],
              [-39.572325290380043, -7.980915305695228],
              [-39.572325359436341, -7.980915295865815],
              [-39.572325428566401, -7.980915286565931],
              [-39.572325497766144, -7.980915277796138],
              [-39.572325567031477, -7.980915269556891],
              [-39.572325636358308, -7.980915261848724],
              [-39.572325705742536, -7.980915254672075],
              [-39.572325775180047, -7.980915248027395],
              [-39.57232584466675, -7.980915241915036],
              [-39.572325914198544, -7.980915236335379],
              [-39.572325983771307, -7.980915231288729],
              [-39.572326053380941, -7.98091522677545],
              [-39.572326123023323, -7.980915222795747],
              [-39.572326192694341, -7.9809152193499],
              [-39.572326262389879, -7.980915216438061],
              [-39.572326332105796, -7.980915214060455],
              [-39.572326401838012, -7.980915212217188],
              [-39.572326471582357, -7.980915210908373],
              [-39.572326541334753, -7.98091521013409],
              [-39.57232661109105, -7.980915209894391],
              [-39.572326680847134, -7.980915210189289],
              [-39.572326750598897, -7.980915211018741],
              [-39.572326820342177, -7.980915212382752],
              [-39.572326890072887, -7.980915214281199],
              [-39.572326959786906, -7.980915216713965],
              [-39.572327029480107, -7.980915219680949],
              [-39.572327099148353, -7.980915223181931],
              [-39.572327168787538, -7.980915227216726],
              [-39.572327238393555, -7.980915231785071],
              [-39.57232730796229, -7.980915236886737],
              [-39.572327377489621, -7.980915242521407],
              [-39.572327446971435, -7.98091524868872],
              [-39.572327516403647, -7.98091525538836],
              [-39.572327585782126, -7.98091526261989],
              [-39.572327655102796, -7.980915270382916],
              [-39.572327724361543, -7.980915278676954],
              [-39.572327793554287, -7.980915287501538],
              [-39.572327862676929, -7.980915296856105],
              [-39.572327931725368, -7.980915306740148],
              [-39.572328000695549, -7.980915317153053],
              [-39.572328069583399, -7.980915328094216],
              [-39.572328138384833, -7.980915339562972],
              [-39.572328207095786, -7.980915351558655],
              [-39.572328275712195, -7.980915364080559],
              [-39.572328344230002, -7.980915377127964],
              [-39.57232841264517, -7.98091539070007],
              [-39.57232848095363, -7.980915404796091],
              [-39.572328549151401, -7.980915419415191],
              [-39.57232861723439, -7.980915434556493],
              [-39.572328685198599, -7.980915450219097],
              [-39.572328753040019, -7.9809154664021],
              [-39.57232882075462, -7.980915483104549],
              [-39.572328888338411, -7.980915500325433],
              [-39.572328955787391, -7.980915518063751],
              [-39.572329023097581, -7.980915536318445],
              [-39.572329090265015, -7.980915555088425],
              [-39.572329157285708, -7.98091557437262],
              [-39.572329224155695, -7.980915594169873],
              [-39.572329290871032, -7.980915614479006],
              [-39.572329357427776, -7.980915635298802],
              [-39.572329423821991, -7.980915656628064],
              [-39.572329490049746, -7.980915678465502],
              [-39.572329556107135, -7.980915700809858],
              [-39.572329621990249, -7.980915723659772],
              [-39.572329687695209, -7.980915747013942],
              [-39.572329753218121, -7.980915770870961],
              [-39.572329818555104, -7.980915795229414],
              [-39.572329883702288, -7.980915820087856],
              [-39.572329948655863, -7.980915845444834],
              [-39.572330013411957, -7.980915871298836],
              [-39.572330077966754, -7.980915897648356],
              [-39.572330142316439, -7.980915924491816],
              [-39.572330206457195, -7.980915951827623],
              [-39.572330270385244, -7.980915979654188],
              [-39.572330334096804, -7.98091600796984],
              [-39.572330397588125, -7.980916036772915],
              [-39.572330460855426, -7.980916066061741],
              [-39.572330523894998, -7.980916095834531],
              [-39.572330586703089, -7.980916126089573],
              [-39.572330649275919, -7.980916156825259],
              [-39.572330679212065, -7.98091619669468],
              [-39.573060251420131, -7.981278079271193],
              [-39.573060261519537, -7.981278018681213],
              [-39.573060319032066, -7.981278047459373],
              [-39.57306037633839, -7.981278076643416],
              [-39.573060433435643, -7.981278106231861],
              [-39.57306049032097, -7.98127813622323],
              [-39.573060546991485, -7.981278166616043],
              [-39.573060603444354, -7.981278197408706],
              [-39.57306065967672, -7.981278228599671],
              [-39.573060715685784, -7.981278260187443],
              [-39.573060771468697, -7.981278292170406],
              [-39.573060827022672, -7.98127832454693],
              [-39.573060882344912, -7.981278357315389],
              [-39.573060937432636, -7.981278390474134],
              [-39.573060992283054, -7.981278424021516],
              [-39.573061046893436, -7.981278457955821],
              [-39.573061101261018, -7.98127849227537],
              [-39.573061155383066, -7.981278526978409],
              [-39.573061209256863, -7.981278562063197],
              [-39.573061262879705, -7.981278597528032],
              [-39.573061316248875, -7.981278633371031],
              [-39.57306136936171, -7.981278669590448],
              [-39.573061422215524, -7.98127870618446],
              [-39.573061474807659, -7.981278743151225],
              [-39.573061527135479, -7.981278780488876],
              [-39.573061579196356, -7.981278818195507],
              [-39.573061630987652, -7.981278856269276],
              [-39.573061682506783, -7.981278894708229],
              [-39.573061733751132, -7.981278933510425],
              [-39.573061784718156, -7.981278972673959],
              [-39.573061835405269, -7.981279012196811],
              [-39.573061885809906, -7.981279052077035],
              [-39.57306193592958, -7.981279092312606],
              [-39.573061985761719, -7.981279132901503],
              [-39.573062035303849, -7.981279173841678],
              [-39.573062084553456, -7.981279215131077],
              [-39.573062133508088, -7.981279256767644],
              [-39.573062182165252, -7.981279298749248],
              [-39.573062230522538, -7.981279341073781],
              [-39.573062278577481, -7.98127938373913],
              [-39.573062326327673, -7.981279426743171],
              [-39.573062373770732, -7.981279470083684],
              [-39.573062420904243, -7.981279513758534],
              [-39.573062467725855, -7.981279557765484],
              [-39.5730625142332, -7.981279602102362],
              [-39.573062560423956, -7.98127964676693],
              [-39.573062606295778, -7.981279691756935],
              [-39.573062651846364, -7.981279737070087],
              [-39.573062697073425, -7.981279782704168],
              [-39.573062741974702, -7.981279828656823],
              [-39.573062786547922, -7.98127987492576],
              [-39.573062830790832, -7.981279921508677],
              [-39.573062874701222, -7.981279968403198],
              [-39.573062918276889, -7.981280015606973],
              [-39.573062961515639, -7.981280063117627],
              [-39.573063004415282, -7.981280110932801],
              [-39.573063046973672, -7.98128015905004],
              [-39.573063089188658, -7.981280207466979],
              [-39.573063131058142, -7.98128025618113],
              [-39.573063172579978, -7.981280305190066],
              [-39.573063213752107, -7.981280354491322],
              [-39.573063254572453, -7.981280404082407],
              [-39.573063295038963, -7.981280453960855],
              [-39.573063335149591, -7.981280504124121],
              [-39.57306337490234, -7.981280554569694],
              [-39.573063414295191, -7.981280605295071],
              [-39.573063453326178, -7.981280656297657],
              [-39.573063491993331, -7.981280707574896],
              [-39.573063530294689, -7.981280759124212],
              [-39.573063568228349, -7.981280810943008],
              [-39.573063605792392, -7.981280863028702],
              [-39.573063642984941, -7.98128091537863],
              [-39.5730636798041, -7.981280967990214],
              [-39.573063716248043, -7.981281020860752],
              [-39.573063752314916, -7.981281073987624],
              [-39.573063788002933, -7.981281127368161],
              [-39.573063823310278, -7.98128118099967],
              [-39.573063858235159, -7.981281234879406],
              [-39.573063892775856, -7.981281289004725],
              [-39.573063926930608, -7.981281343372882],
              [-39.573063960697702, -7.981281397981121],
              [-39.573063994075454, -7.981281452826731],
              [-39.573064027062152, -7.981281507906913],
              [-39.573064059656168, -7.981281563218908],
              [-39.573064091855862, -7.981281618759994],
              [-39.573064123659591, -7.981281674527287],
              [-39.573064155065758, -7.981281730518042],
              [-39.57306418607282, -7.981281786729419],
              [-39.573064216679171, -7.981281843158603],
              [-39.573064246883291, -7.981281899802751],
              [-39.573064276683667, -7.981281956658996],
              [-39.573064306078798, -7.98128201372448],
              [-39.573064335067208, -7.981282070996395],
              [-39.573064363647418, -7.981282128471774],
              [-39.573064391818015, -7.981282186147768],
              [-39.57306441957757, -7.98128224402147],
              [-39.57306444692469, -7.98128230208998],
              [-39.573064473858004, -7.981282360350372],
              [-39.573064500376148, -7.981282418799697],
              [-39.573064526477779, -7.981282477435045],
              [-39.573064552161341, -7.981282536254358],
              [-39.573064522333119, -7.981282595939407],
              [-39.573181131549376, -7.981552161198168],
              [-39.573181142388655, -7.98155214729466],
              [-39.573181210128716, -7.981552308192939],
              [-39.573181274742566, -7.981552470363908],
              [-39.573181336206169, -7.981552633747225],
              [-39.573181394496658, -7.981552798282078],
              [-39.573181449592319, -7.981552963907227],
              [-39.573181501472668, -7.98155313056105],
              [-39.57318155011837, -7.981553298181533],
              [-39.573181595511358, -7.981553466706275],
              [-39.573181637634704, -7.981553636072549],
              [-39.57318167647275, -7.981553806217344],
              [-39.573181712011049, -7.981553977077336],
              [-39.573181744236351, -7.981554148588945],
              [-39.573181773136682, -7.981554320688307],
              [-39.573181798701278, -7.981554493311405],
              [-39.573181820920617, -7.981554666393978],
              [-39.573181839786457, -7.981554839871629],
              [-39.573181855291764, -7.981555013679785],
              [-39.573181867430755, -7.981555187753779],
              [-39.573181876198923, -7.981555362028803],
              [-39.573181881593015, -7.981555536440013],
              [-39.573181883610992, -7.981555710922497],
              [-39.573181882252129, -7.98155588541131],
              [-39.573181877516937, -7.981556059841535],
              [-39.573181869407151, -7.981556234148237],
              [-39.573181857925803, -7.981556408266564],
              [-39.573181843077187, -7.981556582131699],
              [-39.573181824866793, -7.981556755678937],
              [-39.573181803301416, -7.981556928843707],
              [-39.573181778389099, -7.981557101561537],
              [-39.573181750139064, -7.98155727376818],
              [-39.573181718561877, -7.981557445399523],
              [-39.573181683669269, -7.981557616391703],
              [-39.573181645474214, -7.981557786681076],
              [-39.573181603990946, -7.981557956204288],
              [-39.573181559234889, -7.981558124898224],
              [-39.573181511222714, -7.9815582927001],
              [-39.573181459972268, -7.981558459547495],
              [-39.573181405502645, -7.981558625378286],
              [-39.573181347834108, -7.981558790130791],
              [-39.573181286988124, -7.981558953743663],
              [-39.57318122298733, -7.981559116156026],
              [-39.573181155855544, -7.981559277307437],
              [-39.573181085617755, -7.981559437137929],
              [-39.573181012300083, -7.981559595588009],
              [-39.573180935929869, -7.981559752598712],
              [-39.57318085653548, -7.98155990811158],
              [-39.573180774146479, -7.981560062068785],
              [-39.573180688793521, -7.981560214413008],
              [-39.573180600508387, -7.98156036508754],
              [-39.573180509323933, -7.981560514036316],
              [-39.573180415274081, -7.981560661203893],
              [-39.573180318393838, -7.981560806535521],
              [-39.573180218719266, -7.981560949977117],
              [-39.573180116287446, -7.981561091475256],
              [-39.573180011136515, -7.981561230977339],
              [-39.573179903305586, -7.981561368431398],
              [-39.573179792834807, -7.981561503786298],
              [-39.573179679765282, -7.981561636991658],
              [-39.573179564139096, -7.981561767997935],
              [-39.573179445999287, -7.981561896756337],
              [-39.573179325389795, -7.981562023218943],
              [-39.573179202355526, -7.981562147338709],
              [-39.573179076942289, -7.981562269069425],
              [-39.573178949196716, -7.981562388365824],
              [-39.573178819166372, -7.981562505183477],
              [-39.573178686899645, -7.9815626194789],
              [-39.573178552445754, -7.981562731209571],
              [-39.573178415854763, -7.981562840333925],
              [-39.573178277177462, -7.98156294681135],
              [-39.573178136465515, -7.98156305060219],
              [-39.573177993771225, -7.981563151667837],
              [-39.57317784914774, -7.981563249970663],
              [-39.573177702648884, -7.981563345474103],
              [-39.57317755432917, -7.981563438142598],
              [-39.573177404243786, -7.981563527941671],
              [-39.573177252448609, -7.9815636148379],
              [-39.573177099000112, -7.981563698798943],
              [-39.573176943955396, -7.981563779793553],
              [-39.573176787372191, -7.981563857791595],
              [-39.573176629308755, -7.981563932764043],
              [-39.57317646982392, -7.981564004682966],
              [-39.573176308977011, -7.981564073521627],
              [-39.573176146827933, -7.98156413925444],
              [-39.573175983436997, -7.981564201856868],
              [-39.573175818865025, -7.981564261305668],
              [-39.573175653173273, -7.981564317578679],
              [-39.573175486423374, -7.981564370654977],
              [-39.573175318677443, -7.981564420514798],
              [-39.57317514999783, -7.981564467139606],
              [-39.573174980447376, -7.981564510512049],
              [-39.573174810089156, -7.981564550615978],
              [-39.573174638986558, -7.981564587436466],
              [-39.573174467203287, -7.981564620959777],
              [-39.573174294803245, -7.981564651173509],
              [-39.57317412185062, -7.981564678066348],
              [-39.57317394840976, -7.981564701628315],
              [-39.573173774545218, -7.981564721850633],
              [-39.573173600321709, -7.981564738725795],
              [-39.573173425802977, -7.981564752248322],
              [-39.573173436466334, -7.981564744627373],
              [-39.571961036564907, -7.981647014039625],
              [-39.571960492145685, -7.98164701479189],
              [-39.571635530051886, -7.981687895565491],
              [-39.571635518299502, -7.981687856782851],
              [-39.571635487462501, -7.98168786071148],
              [-39.571635456639179, -7.98168786474536],
              [-39.571635425829903, -7.981687868884515],
              [-39.571635395035024, -7.981687873128864],
              [-39.571635364254938, -7.981687877478364],
              [-39.571635333489958, -7.981687881932987],
              [-39.571635302740475, -7.981687886492649],
              [-39.57163527200683, -7.98168789115729],
              [-39.571635241289428, -7.981687895926868],
              [-39.571635210588575, -7.981687900801368],
              [-39.571635179904668, -7.981687905780671],
              [-39.571635149238048, -7.981687910864728],
              [-39.571635118589086, -7.981687916053505],
              [-39.571635087958136, -7.981687921346934],
              [-39.571635057345553, -7.981687926744944],
              [-39.571635026751714, -7.981687932247504],
              [-39.571634996176961, -7.981687937854494],
              [-39.571634965621655, -7.981687943565897],
              [-39.571634935086159, -7.981687949381593],
              [-39.571634904570828, -7.981687955301568],
              [-39.571634874076025, -7.981687961325716],
              [-39.571634843602119, -7.981687967453992],
              [-39.571634813149437, -7.98168797368629],
              [-39.571634782718363, -7.981687980022564],
              [-39.571634752309244, -7.98168798646275],
              [-39.571634721922443, -7.981687993006745],
              [-39.571634691558302, -7.981687999654506],
              [-39.571634661217175, -7.981688006405901],
              [-39.571634630899446, -7.981688013260911],
              [-39.571634600605464, -7.981688020219419],
              [-39.571634570335569, -7.98168802728134],
              [-39.57163454009013, -7.981688034446605],
              [-39.571634509869483, -7.981688041715145],
              [-39.571634479673996, -7.981688049086848],
              [-39.571634449504025, -7.981688056561637],
              [-39.571634419359924, -7.981688064139418],
              [-39.571634389242043, -7.981688071820118],
              [-39.571634359150728, -7.981688079603639],
              [-39.571634329086343, -7.981688087489878],
              [-39.571634299049251, -7.981688095478729],
              [-39.571634269039777, -7.98168810357015],
              [-39.571634239058298, -7.98168811176402],
              [-39.571634209105177, -7.981688120060249],
              [-39.571634179180712, -7.981688128458718],
              [-39.571634149285309, -7.981688136959351],
              [-39.571634119419294, -7.981688145562038],
              [-39.571634089583007, -7.981688154266684],
              [-39.571634059776834, -7.981688163073184],
              [-39.571634030001093, -7.981688171981419],
              [-39.571634000256147, -7.981688180991307],
              [-39.571633970542329, -7.981688190102727],
              [-39.571633940860011, -7.981688199315558],
              [-39.571633911209531, -7.98168820862972],
              [-39.571633881591239, -7.981688218045097],
              [-39.57163385200549, -7.981688227561596],
              [-39.571633822452604, -7.981688237179069],
              [-39.571633792932957, -7.981688246897419],
              [-39.571633763446883, -7.981688256716523],
              [-39.571633733994723, -7.981688266636291],
              [-39.571633704576833, -7.9816882766566],
              [-39.571633675193567, -7.981688286777322],
              [-39.571633645845239, -7.981688296998324],
              [-39.571633616532225, -7.981688307319523],
              [-39.571633587254851, -7.981688317740752],
              [-39.571633558013474, -7.981688328261933],
              [-39.57163352880842, -7.98168833888291],
              [-39.571633499640043, -7.981688349603555],
              [-39.571633470508687, -7.981688360423794],
              [-39.571633441414697, -7.981688371343445],
              [-39.571633412358402, -7.981688382362413],
              [-39.571633383340149, -7.981688393480548],
              [-39.571633354360266, -7.981688404697728],
              [-39.571633325419121, -7.98168841601382],
              [-39.571633296517042, -7.981688427428683],
              [-39.571633267654363, -7.981688438942174],
              [-39.571633238831417, -7.981688450554189],
              [-39.571633210048546, -7.981688462264567],
              [-39.571633181306105, -7.981688474073235],
              [-39.571633152604413, -7.98168848597995],
              [-39.571633123943812, -7.981688497984649],
              [-39.571633095324636, -7.981688510087155],
              [-39.571633066747232, -7.981688522287324],
              [-39.571633038211914, -7.981688534585034],
              [-39.571633009719037, -7.981688546980115],
              [-39.571632981268934, -7.981688559472452],
              [-39.571632952861926, -7.98168857206189],
              [-39.57163292449836, -7.981688584748263],
              [-39.571632896178556, -7.981688597531434],
              [-39.571632867902856, -7.981688610411266],
              [-39.571632839671601, -7.981688623387575],
              [-39.571632811485088, -7.981688636460242],
              [-39.571632783343681, -7.981688649629096],
              [-39.57163275524772, -7.981688662893996],
              [-39.571632727197482, -7.98168867625479],
              [-39.571632699193344, -7.981688689711286],
              [-39.571632671235626, -7.981688703263352],
              [-39.571632643324634, -7.981688716910822],
              [-39.571632615460722, -7.981688730653538],
              [-39.571632587644203, -7.981688744491331],
              [-39.57163262773927, -7.981688804089854],
              [-39.571390347285075, -7.981809801087294],
              [-39.57139033767956, -7.981809772971024],
              [-39.571390312192207, -7.981809785654658],
              [-39.571390286665014, -7.981809798258487],
              [-39.571390261098273, -7.981809810782361],
              [-39.57139023549221, -7.981809823226142],
              [-39.571390209847074, -7.981809835589713],
              [-39.571390184163128, -7.981809847872995],
              [-39.571390158440622, -7.981809860075826],
              [-39.571390132679824, -7.981809872198093],
              [-39.571390106880962, -7.98180988423968],
              [-39.571390081044314, -7.981809896200467],
              [-39.571390055170099, -7.981809908080332],
              [-39.571390029258623, -7.981809919879161],
              [-39.571390003310114, -7.98180993159685],
              [-39.57138997732482, -7.981809943233267],
              [-39.571389951303019, -7.981809954788328],
              [-39.571389925244937, -7.981809966261858],
              [-39.571389899150859, -7.981809977653776],
              [-39.571389873021026, -7.981809988963983],
              [-39.571389846855716, -7.981810000192372],
              [-39.571389820655156, -7.981810011338831],
              [-39.571389794419623, -7.981810022403188],
              [-39.571389768149373, -7.981810033385407],
              [-39.571389741844669, -7.981810044285357],
              [-39.571389715505774, -7.981810055102934],
              [-39.571389689132921, -7.981810065838016],
              [-39.571389662726403, -7.98181007649051],
              [-39.571389636286476, -7.981810087060293],
              [-39.571389609813373, -7.981810097547282],
              [-39.571389583307386, -7.981810107951404],
              [-39.571389556768743, -7.981810118272464],
              [-39.571389530197742, -7.981810128510459],
              [-39.571389503594631, -7.981810138665235],
              [-39.571389476959659, -7.981810148736692],
              [-39.571389450293104, -7.981810158724761],
              [-39.571389423595221, -7.981810168629323],
              [-39.57138939686628, -7.981810178450267],
              [-39.571389370106537, -7.981810188187513],
              [-39.57138934331627, -7.981810197840967],
              [-39.571389316495718, -7.981810207410533],
              [-39.571389289645175, -7.981810216896116],
              [-39.571389262764875, -7.981810226297619],
              [-39.571389235855108, -7.981810235614941],
              [-39.571389208916131, -7.98181024484801],
              [-39.571389181948213, -7.981810253996715],
              [-39.571389154951596, -7.981810263060996],
              [-39.571389127926572, -7.98181027204073],
              [-39.571389100873418, -7.981810280935837],
              [-39.571389073792353, -7.981810289746243],
              [-39.571389046683692, -7.981810298471854],
              [-39.571389019547688, -7.981810307112559],
              [-39.571388992384598, -7.981810315668302],
              [-39.5713889651947, -7.981810324138999],
              [-39.571388937978249, -7.981810332524566],
              [-39.571388910735536, -7.981810340824908],
              [-39.571388883466796, -7.981810349039947],
              [-39.571388856172334, -7.98181035716962],
              [-39.571388828852406, -7.981810365213825],
              [-39.571388801507275, -7.981810373172479],
              [-39.571388774137219, -7.981810381045529],
              [-39.571388746742493, -7.981810388832858],
              [-39.57138871932338, -7.981810396534434],
              [-39.571388691880145, -7.981810404150147],
              [-39.571388664413057, -7.981810411679971],
              [-39.5713886369224, -7.981810419123735],
              [-39.571388609408437, -7.981810426481494],
              [-39.571388581871425, -7.98181043375305],
              [-39.571388554311639, -7.981810440938396],
              [-39.571388526729379, -7.981810448037479],
              [-39.571388499124893, -7.981810455050182],
              [-39.571388471498459, -7.981810461976482],
              [-39.571388443850338, -7.981810468816267],
              [-39.571388416180824, -7.981810475569513],
              [-39.57138838849017, -7.981810482236112],
              [-39.571388360778649, -7.981810488816037],
              [-39.571388333046563, -7.981810495309174],
              [-39.571388305294143, -7.981810501715508],
              [-39.571388277521699, -7.981810508034931],
              [-39.57138824972948, -7.981810514267464],
              [-39.571388221917779, -7.98181052041292],
              [-39.571388194086843, -7.981810526471333],
              [-39.571388166236979, -7.981810532442619],
              [-39.571388138368434, -7.981810538326727],
              [-39.571388110481514, -7.981810544123553],
              [-39.571388082576462, -7.981810549833082],
              [-39.571388054653568, -7.981810555455266],
              [-39.571388026713123, -7.981810560990044],
              [-39.571387998755355, -7.981810566437312],
              [-39.571387970780577, -7.98181057179709],
              [-39.571387942789066, -7.9818105770693],
              [-39.571387914781077, -7.981810582253874],
              [-39.571387886756909, -7.981810587350762],
              [-39.571387858716825, -7.981810592359925],
              [-39.571387830661088, -7.981810597281302],
              [-39.57138780259001, -7.981810602114851],
              [-39.57138777450384, -7.981810606860511],
              [-39.57138774640287, -7.981810611518261],
              [-39.571387718287355, -7.981810616088056],
              [-39.5713876901576, -7.981810620569822],
              [-39.571387662013755, -7.98181062496398],
              [-39.571387717055046, -7.981810618782804],
              [-39.570330406596192, -7.981973985410188],
              [-39.570330378836481, -7.981973988612738],
              [-39.570330314286096, -7.981973998823931],
              [-39.5703302498112, -7.981974009498374],
              [-39.570330185415088, -7.981974020635482],
              [-39.570330121101122, -7.9819740322347],
              [-39.570330056872642, -7.981974044295412],
              [-39.570329992732979, -7.981974056816991],
              [-39.570329928685446, -7.981974069798786],
              [-39.570329864733388, -7.981974083240138],
              [-39.57032980088011, -7.981974097140339],
              [-39.570329737128915, -7.981974111498698],
              [-39.570329673483144, -7.981974126314379],
              [-39.570329609946057, -7.981974141586724],
              [-39.570329546520981, -7.981974157314886],
              [-39.570329483211204, -7.981974173498067],
              [-39.570329420020002, -7.981974190135405],
              [-39.570329356950644, -7.981974207226019],
              [-39.57032929400642, -7.981974224769059],
              [-39.570329231190605, -7.981974242763617],
              [-39.570329168506426, -7.981974261208754],
              [-39.570329105957157, -7.981974280103498],
              [-39.570329043546032, -7.981974299446876],
              [-39.570328981276297, -7.981974319237909],
              [-39.570328919151194, -7.98197433947553],
              [-39.570328857173926, -7.981974360158689],
              [-39.57032879534772, -7.981974381286344],
              [-39.570328733675773, -7.981974402857389],
              [-39.570328672161295, -7.981974424870708],
              [-39.570328610807472, -7.981974447325139],
              [-39.570328549617493, -7.981974470219559],
              [-39.570328488594527, -7.981974493552721],
              [-39.57032842774175, -7.981974517323456],
              [-39.570328367062316, -7.981974541530532],
              [-39.570328306559354, -7.98197456617268],
              [-39.570328246236016, -7.981974591248611],
              [-39.570328186095452, -7.981974616757033],
              [-39.570328126140744, -7.981974642696621],
              [-39.570328066375041, -7.98197466906604],
              [-39.570328006801418, -7.981974695863904],
              [-39.570327947422975, -7.981974723088847],
              [-39.570327888242801, -7.981974750739447],
              [-39.570327829263938, -7.981974778814235],
              [-39.570327770489477, -7.98197480731181],
              [-39.570327711922452, -7.981974836230661],
              [-39.570327653565904, -7.981974865569293],
              [-39.570327595422881, -7.981974895326184],
              [-39.570327537496361, -7.981974925499776],
              [-39.57032747978937, -7.98197495608852],
              [-39.570327422304914, -7.981974987090835],
              [-39.570327365045955, -7.981975018505113],
              [-39.570327308015464, -7.981975050329707],
              [-39.570327251216426, -7.981975082562997],
              [-39.57032719465176, -7.98197511520328],
              [-39.570327138324409, -7.981975148248863],
              [-39.570327082237299, -7.981975181698044],
              [-39.570327026393322, -7.981975215549081],
              [-39.570326970795414, -7.98197524980022],
              [-39.570326915446422, -7.981975284449685],
              [-39.570326860349248, -7.981975319495674],
              [-39.570326805506724, -7.981975354936375],
              [-39.570326750921716, -7.981975390769935],
              [-39.570326696597029, -7.981975426994529],
              [-39.570326642535512, -7.98197546360825],
              [-39.570326588739945, -7.981975500609171],
              [-39.570326535213162, -7.981975537995419],
              [-39.570326481957878, -7.981975575765042],
              [-39.570326428976912, -7.981975613916074],
              [-39.570326376272973, -7.981975652446538],
              [-39.570326323848818, -7.981975691354452],
              [-39.57032627170716, -7.981975730637749],
              [-39.570326219850692, -7.981975770294423],
              [-39.570326168282122, -7.981975810322449],
              [-39.570326117004136, -7.981975850719699],
              [-39.570326066019355, -7.981975891484116],
              [-39.570326015330465, -7.981975932613556],
              [-39.570325964940075, -7.98197597410589],
              [-39.570325914850812, -7.981976015958959],
              [-39.570325865065243, -7.981976058170608],
              [-39.570325815585996, -7.981976100738637],
              [-39.570325766415614, -7.981976143660839],
              [-39.570325717556649, -7.981976186934998],
              [-39.570325669011631, -7.981976230558876],
              [-39.570325620783095, -7.981976274530172],
              [-39.570325572873536, -7.981976318846653],
              [-39.570325525285419, -7.981976363505943],
              [-39.570325478021246, -7.981976408505807],
              [-39.570325431083454, -7.981976453843862],
              [-39.570325384474472, -7.981976499517766],
              [-39.570325338196739, -7.981976545525172],
              [-39.570325292252633, -7.981976591863665],
              [-39.570325246644543, -7.981976638530853],
              [-39.570325201374843, -7.981976685524305],
              [-39.570325156445882, -7.981976732841594],
              [-39.570325111859994, -7.981976780480284],
              [-39.570325067619471, -7.981976828437855],
              [-39.570325023726639, -7.98197687671187],
              [-39.570324980183742, -7.98197692529978],
              [-39.570324936993067, -7.981976974199064],
              [-39.570324894156848, -7.981977023407234],
              [-39.570324851677292, -7.981977072921699],
              [-39.570324875925664, -7.98197706838694],
              [-39.569637392238178, -7.98278430118626],
              [-39.569637365591305, -7.98278427721687],
              [-39.569637299358106, -7.982784355884574],
              [-39.569637234025208, -7.982784435296849],
              [-39.569637169601037, -7.982784515443474],
              [-39.569637106093893, -7.982784596314129],
              [-39.569637043511975, -7.982784677898397],
              [-39.56963698186334, -7.982784760185701],
              [-39.56963692115594, -7.982784843165528],
              [-39.569636861397591, -7.982784926827107],
              [-39.569636802596023, -7.982785011159653],
              [-39.569636744758796, -7.982785096152334],
              [-39.569636687893379, -7.982785181794156],
              [-39.569636632007096, -7.982785268074092],
              [-39.56963657710714, -7.982785354981043],
              [-39.569636523200607, -7.982785442503769],
              [-39.569636470294455, -7.982785530630996],
              [-39.569636418395476, -7.982785619351368],
              [-39.569636367510384, -7.982785708653432],
              [-39.569636317645731, -7.982785798525702],
              [-39.56963626880794, -7.982785888956569],
              [-39.569636221003314, -7.982785979934376],
              [-39.569636174238035, -7.982786071447392],
              [-39.569636128518091, -7.982786163483865],
              [-39.56963608384941, -7.982786256031854],
              [-39.569636040237732, -7.98278634907946],
              [-39.569635997688692, -7.982786442614716],
              [-39.569635956207769, -7.982786536625534],
              [-39.569635915800319, -7.982786631099794],
              [-39.569635876471537, -7.982786726025301],
              [-39.569635838226503, -7.982786821389854],
              [-39.569635801070149, -7.982786917181112],
              [-39.569635765007256, -7.982787013386791],
              [-39.569635730042485, -7.982787109994417],
              [-39.569635696180342, -7.982787206991595],
              [-39.569635663425188, -7.982787304365756],
              [-39.569635631781239, -7.982787402104404],
              [-39.5696356012526, -7.982787500194915],
              [-39.569635571843179, -7.98278759862464],
              [-39.569635543556771, -7.98278769738091],
              [-39.569635516397042, -7.982787796450957],
              [-39.569635490367475, -7.982787895822038],
              [-39.569635465471436, -7.982787995481321],
              [-39.56963544171213, -7.982788095415984],
              [-39.569635419092613, -7.982788195613101],
              [-39.569635397615826, -7.982788296059784],
              [-39.569635377284513, -7.982788396743082],
              [-39.569635358101301, -7.982788497650006],
              [-39.569635340068658, -7.982788598767565],
              [-39.56963532318894, -7.982788700082691],
              [-39.569635307464289, -7.982788801582364],
              [-39.569635292896734, -7.982788903253457],
              [-39.569635279488168, -7.982789005082904],
              [-39.569635267240322, -7.982789107057553],
              [-39.569635256154761, -7.982789209164237],
              [-39.569635246232906, -7.982789311389797],
              [-39.569635237476078, -7.982789413721096],
              [-39.569635229885357, -7.982789516144915],
              [-39.569635223461745, -7.982789618648049],
              [-39.569635218206081, -7.982789721217272],
              [-39.569635214119018, -7.98278982383937],
              [-39.569635211201103, -7.982789926501122],
              [-39.569635209452692, -7.98279002918926],
              [-39.56963520887404, -7.982790131890586],
              [-39.56963520946519, -7.982790234591834],
              [-39.569635211226085, -7.982790337279759],
              [-39.569635214156484, -7.982790439941144],
              [-39.569635218256039, -7.982790542562757],
              [-39.56963522352418, -7.982790645131325],
              [-39.569635229960248, -7.982790747633658],
              [-39.569635237563418, -7.982790850056549],
              [-39.569635246332716, -7.982790952386801],
              [-39.569635256266977, -7.982791054611166],
              [-39.569635267364966, -7.982791156716514],
              [-39.569635279625217, -7.982791258689676],
              [-39.569635293046161, -7.982791360517491],
              [-39.569635307626072, -7.98279146218683],
              [-39.56963532336308, -7.982791563684591],
              [-39.569635340255125, -7.982791664997695],
              [-39.569635358300054, -7.982791766113073],
              [-39.56963537749553, -7.982791867017685],
              [-39.569635397839093, -7.982791967698523],
              [-39.569635419328094, -7.982792068142604],
              [-39.569635441959804, -7.982792168336998],
              [-39.569635465731253, -7.982792268268762],
              [-39.569635490639413, -7.982792367925051],
              [-39.569635516681068, -7.982792467292978],
              [-39.56963554385284, -7.982792566359743],
              [-39.569635572151263, -7.982792665112582],
              [-39.569635601572649, -7.982792763538744],
              [-39.569635632113226, -7.982792861625569],
              [-39.569635663769056, -7.982792959360387],
              [-39.569635696536054, -7.982793056730622],
              [-39.569635730409999, -7.982793153723692],
              [-39.569635765386522, -7.982793250327104],
              [-39.569635801461111, -7.982793346528397],
              [-39.569635838629111, -7.982793442315192],
              [-39.569635876885755, -7.982793537675115],
              [-39.569635916226076, -7.982793632595871],
              [-39.569635956645016, -7.982793727065238],
              [-39.569635998137372, -7.982793821071042],
              [-39.569636044465362, -7.982793890917101],
              [-39.569795781716188, -7.98315032191447],
              [-39.569795785922594, -7.98315033412533],
              [-39.56979582019941, -7.983150409670968],
              [-39.569795855169467, -7.983150484900193],
              [-39.569795890829823, -7.983150559806737],
              [-39.569795927177474, -7.983150634384261],
              [-39.569795964209376, -7.983150708626524],
              [-39.569796001922413, -7.983150782527289],
              [-39.569796040313427, -7.983150856080342],
              [-39.569796079379181, -7.983150929279486],
              [-39.569796119116376, -7.983151002118595],
              [-39.569796159521701, -7.983151074591536],
              [-39.569796200591746, -7.98315114669222],
              [-39.569796242323058, -7.983151218414587],
              [-39.569796284712154, -7.983151289752622],
              [-39.569796327755441, -7.983151360700301],
              [-39.569796371449321, -7.983151431251696],
              [-39.569796415790115, -7.983151501400853],
              [-39.5697964607741, -7.983151571141892],
              [-39.569796506397502, -7.983151640468962],
              [-39.569796552656477, -7.983151709376218],
              [-39.569796599547139, -7.983151777857881],
              [-39.569796647065559, -7.983151845908207],
              [-39.569796695207735, -7.983151913521443],
              [-39.569796743969611, -7.983151980691943],
              [-39.569796793347109, -7.983152047414047],
              [-39.569796843336086, -7.983152113682156],
              [-39.569796893932313, -7.983152179490693],
              [-39.569796945131557, -7.983152244834139],
              [-39.569796996929504, -7.98315230970699],
              [-39.569797049321828, -7.983152374103801],
              [-39.569797102304101, -7.98315243801916],
              [-39.569797155871875, -7.983152501447702],
              [-39.569797210020653, -7.983152564384095],
              [-39.569797264745887, -7.983152626823032],
              [-39.56979732004298, -7.983152688759321],
              [-39.569797375907278, -7.983152750187672],
              [-39.569797432334092, -7.983152811103023],
              [-39.569797489318674, -7.983152871500159],
              [-39.569797546856243, -7.983152931374051],
              [-39.569797604941968, -7.983152990719675],
              [-39.569797663570959, -7.983153049532043],
              [-39.569797722738301, -7.983153107806209],
              [-39.569797782439011, -7.983153165537271],
              [-39.569797842668073, -7.98315322272037],
              [-39.569797903420429, -7.983153279350746],
              [-39.569797964690977, -7.983153335423571],
              [-39.569798026474565, -7.983153390934182],
              [-39.569798088765999, -7.983153445877887],
              [-39.569798151560043, -7.983153500250129],
              [-39.569798214851438, -7.983153554046234],
              [-39.569798278634856, -7.983153607261784],
              [-39.569798342904932, -7.983153659892221],
              [-39.569798407656258, -7.983153711933189],
              [-39.56979847288342, -7.983153763380308],
              [-39.569798538580912, -7.983153814229233],
              [-39.569798604743227, -7.983153864475703],
              [-39.569798671364808, -7.983153914115463],
              [-39.569798738440042, -7.983153963144393],
              [-39.569798805963302, -7.983154011558343],
              [-39.569798873928903, -7.983154059353271],
              [-39.569798942331154, -7.983154106525116],
              [-39.569799011164299, -7.983154153069932],
              [-39.569799080422555, -7.983154198983837],
              [-39.569799150100096, -7.983154244262919],
              [-39.569799220191072, -7.983154288903443],
              [-39.569799290689602, -7.983154332901618],
              [-39.569799361589737, -7.983154376253747],
              [-39.569799432885553, -7.983154418956202],
              [-39.569799504571023, -7.983154461005366],
              [-39.569799576640165, -7.983154502397728],
              [-39.569799649086889, -7.98315454312979],
              [-39.569799721905099, -7.983154583198156],
              [-39.569799795088727, -7.983154622599452],
              [-39.569799868631584, -7.983154661330364],
              [-39.569799942527489, -7.98315469938762],
              [-39.569800016770259, -7.983154736768057],
              [-39.569800091353642, -7.983154773468512],
              [-39.569800166271357, -7.983154809485885],
              [-39.569800241517115, -7.983154844817201],
              [-39.569800317084614, -7.983154879459465],
              [-39.569800392967487, -7.983154913409706],
              [-39.569800469159347, -7.983154946665173],
              [-39.569800545653813, -7.983154979223007],
              [-39.569800622444454, -7.983155011080509],
              [-39.569800699524805, -7.983155042234968],
              [-39.569800776888407, -7.983155072683789],
              [-39.569800854528744, -7.983155102424377],
              [-39.569800932439293, -7.983155131454309],
              [-39.569801010613524, -7.983155159771076],
              [-39.569801089044851, -7.983155187372331],
              [-39.569801167726688, -7.983155214255742],
              [-39.569801246652425, -7.983155240419042],
              [-39.569801325815433, -7.983155265860033],
              [-39.569801405209041, -7.983155290576607],
              [-39.569801484826606, -7.983155314566672],
              [-39.569801564661432, -7.983155337828211],
              [-39.569801644706786, -7.983155360359233],
              [-39.569801724955973, -7.983155382157888],
              [-39.569801805402243, -7.983155403222349],
              [-39.56980188603881, -7.983155423550816],
              [-39.56980186823192, -7.983155469278939],
              [-39.570287387227921, -7.983275463029024],
              [-39.570287351143001, -7.983275451672379],
              [-39.570287364462786, -7.983275454954941],
              [-39.570287377787523, -7.983275458217411],
              [-39.570287391117205, -7.983275461459794],
              [-39.570287404451761, -7.98327546468209],
              [-39.570287417791207, -7.983275467884293],
              [-39.570287431135498, -7.983275471066392],
              [-39.570287444484585, -7.983275474228352],
              [-39.570287457838468, -7.983275477370169],
              [-39.570287471197105, -7.983275480491847],
              [-39.570287484560453, -7.983275483593424],
              [-39.570287497928497, -7.983275486674835],
              [-39.570287511301188, -7.983275489736099],
              [-39.57028752467852, -7.983275492777184],
              [-39.570287538060448, -7.983275495798115],
              [-39.570287551446938, -7.983275498798871],
              [-39.570287564837969, -7.983275501779453],
              [-39.570287578233511, -7.983275504739833],
              [-39.570287591633516, -7.983275507680019],
              [-39.570287605037969, -7.983275510599984],
              [-39.570287618446848, -7.983275513499773],
              [-39.570287631860097, -7.983275516379359],
              [-39.570287645277702, -7.983275519238684],
              [-39.570287658699627, -7.983275522077773],
              [-39.570287672125851, -7.983275524896635],
              [-39.570287685556323, -7.983275527695275],
              [-39.570287698991024, -7.983275530473658],
              [-39.570287712429938, -7.983275533231768],
              [-39.570287725873015, -7.983275535969636],
              [-39.570287739320236, -7.98327553868723],
              [-39.570287752771556, -7.983275541384555],
              [-39.570287766226954, -7.983275544061599],
              [-39.570287779686403, -7.983275546718375],
              [-39.570287793149845, -7.983275549354828],
              [-39.570287806617294, -7.983275551971005],
              [-39.570287820088694, -7.983275554566875],
              [-39.570287833564009, -7.983275557142439],
              [-39.570287847043218, -7.983275559697676],
              [-39.570287860526285, -7.983275562232608],
              [-39.570287874013189, -7.983275564747218],
              [-39.570287887503881, -7.983275567241448],
              [-39.570287900998352, -7.983275569715378],
              [-39.570287914496561, -7.983275572168966],
              [-39.570287927998471, -7.983275574602179],
              [-39.570287941504063, -7.98327557701505],
              [-39.570287955013285, -7.983275579407548],
              [-39.570287968526138, -7.983275581779689],
              [-39.570287982042558, -7.983275584131453],
              [-39.570287995562545, -7.983275586462865],
              [-39.570288009086042, -7.983275588773866],
              [-39.570288022613035, -7.983275591064495],
              [-39.570288036143488, -7.983275593334715],
              [-39.570288049677359, -7.983275595584548],
              [-39.57028806321464, -7.983275597813967],
              [-39.570288076755276, -7.983275600023015],
              [-39.570288090299265, -7.983275602211624],
              [-39.570288103846543, -7.983275604379807],
              [-39.570288117397084, -7.983275606527584],
              [-39.570288130950885, -7.983275608654902],
              [-39.570288144507906, -7.983275610761815],
              [-39.570288158068088, -7.983275612848284],
              [-39.570288171631432, -7.983275614914314],
              [-39.570288185197889, -7.983275616959904],
              [-39.570288198767429, -7.983275618985018],
              [-39.570288212340039, -7.983275620989693],
              [-39.570288225915661, -7.983275622973892],
              [-39.570288239494289, -7.983275624937633],
              [-39.570288253075873, -7.983275626880903],
              [-39.570288266660391, -7.983275628803696],
              [-39.570288280247802, -7.983275630706033],
              [-39.570288293838104, -7.983275632587859],
              [-39.57028830743122, -7.983275634449196],
              [-39.570288321027157, -7.983275636290061],
              [-39.570288334625872, -7.983275638110399],
              [-39.570288348227329, -7.983275639910268],
              [-39.570288361831494, -7.983275641689624],
              [-39.570288375438359, -7.983275643448454],
              [-39.570288389047874, -7.98327564518678],
              [-39.570288402659997, -7.983275646904619],
              [-39.570288416274721, -7.983275648601877],
              [-39.57028842989201, -7.983275650278647],
              [-39.570288443511807, -7.983275651934876],
              [-39.570288457134112, -7.983275653570582],
              [-39.570288470758882, -7.983275655185761],
              [-39.570288484386083, -7.983275656780383],
              [-39.570288498015685, -7.983275658354465],
              [-39.570288511647668, -7.983275659908026],
              [-39.570288525281988, -7.983275661441007],
              [-39.570288538918632, -7.983275662953449],
              [-39.570288552557535, -7.983275664445316],
              [-39.570288566198684, -7.983275665916628],
              [-39.570288579842057, -7.983275667367382],
              [-39.570288593487618, -7.983275668797561],
              [-39.570288607135339, -7.983275670207166],
              [-39.570288620785163, -7.983275671596212],
              [-39.570288634437091, -7.983275672964673],
              [-39.570288648091086, -7.98327567431257],
              [-39.570288661747099, -7.98327567563986],
              [-39.570288675405109, -7.983275676946577],
              [-39.570288689065094, -7.983275678232689],
              [-39.570288657921125, -7.983275732632944],
              [-39.571145236342758, -7.983355686499036],
              [-39.571145267106495, -7.983355675355726],
              [-39.571145282722327, -7.983355676827666],
              [-39.571145298335601, -7.983355678326538],
              [-39.571145313946232, -7.983355679852283],
              [-39.571145329554206, -7.98335568140494],
              [-39.571145345159451, -7.98335568298447],
              [-39.571145360761953, -7.983355684590911],
              [-39.571145376361628, -7.983355686224229],
              [-39.57114539195846, -7.983355687884439],
              [-39.5711454075524, -7.983355689571509],
              [-39.571145423143363, -7.983355691285438],
              [-39.571145438731335, -7.983355693026246],
              [-39.571145454316266, -7.983355694793911],
              [-39.571145469898127, -7.983355696588417],
              [-39.571145485476841, -7.983355698409804],
              [-39.571145501052378, -7.983355700258018],
              [-39.571145516624675, -7.983355702133069],
              [-39.571145532193711, -7.983355704034935],
              [-39.571145547759414, -7.983355705963642],
              [-39.571145563321757, -7.983355707919162],
              [-39.571145578880696, -7.983355709901518],
              [-39.571145594436153, -7.983355711910672],
              [-39.571145609988108, -7.983355713946637],
              [-39.571145625536516, -7.983355716009375],
              [-39.571145641081323, -7.983355718098923],
              [-39.57114565662247, -7.983355720215251],
              [-39.571145672159936, -7.983355722358367],
              [-39.571145687693665, -7.983355724528247],
              [-39.5711457032236, -7.983355726724866],
              [-39.571145718749705, -7.983355728948266],
              [-39.57114573427193, -7.983355731198422],
              [-39.571145749790219, -7.983355733475303],
              [-39.57114576530455, -7.98335573577893],
              [-39.571145780814867, -7.9833557381093],
              [-39.571145796321119, -7.983355740466378],
              [-39.571145811823243, -7.983355742850201],
              [-39.571145827321224, -7.983355745260734],
              [-39.571145842815, -7.983355747697944],
              [-39.571145858304533, -7.983355750161858],
              [-39.57114587378976, -7.983355752652451],
              [-39.571145889270653, -7.983355755169757],
              [-39.571145904747141, -7.983355757713737],
              [-39.571145920219209, -7.98335576028434],
              [-39.571145935686793, -7.983355762881624],
              [-39.571145951149866, -7.983355765505562],
              [-39.571145966608348, -7.983355768156148],
              [-39.571145982062205, -7.983355770833356],
              [-39.571145997511408, -7.983355773537177],
              [-39.5711460129559, -7.983355776267637],
              [-39.571146028395617, -7.983355779024695],
              [-39.571146043830545, -7.983355781808358],
              [-39.571146059260627, -7.983355784618615],
              [-39.571146074685799, -7.983355787455461],
              [-39.571146090106033, -7.983355790318903],
              [-39.571146105521287, -7.983355793208873],
              [-39.571146120931495, -7.983355796125435],
              [-39.571146136336615, -7.983355799068536],
              [-39.571146151736627, -7.983355802038196],
              [-39.571146167131467, -7.983355805034369],
              [-39.571146182521069, -7.983355808057067],
              [-39.571146197905421, -7.983355811106288],
              [-39.571146213284457, -7.98335581418202],
              [-39.571146228658144, -7.983355817284242],
              [-39.571146244026409, -7.983355820412962],
              [-39.571146259389252, -7.983355823568134],
              [-39.571146274746589, -7.983355826749785],
              [-39.57114629009839, -7.983355829957912],
              [-39.571146305444586, -7.983355833192482],
              [-39.571146320785147, -7.983355836453508],
              [-39.571146336120066, -7.983355839740941],
              [-39.571146351449237, -7.983355843054801],
              [-39.571146366772638, -7.983355846395088],
              [-39.571146382090234, -7.983355849761731],
              [-39.571146397401954, -7.983355853154802],
              [-39.571146412707776, -7.983355856574245],
              [-39.571146428007644, -7.983355860020048],
              [-39.571146443301515, -7.983355863492227],
              [-39.57114645858934, -7.983355866990765],
              [-39.571146473871089, -7.98335587051564],
              [-39.571146489146678, -7.983355874066826],
              [-39.571146504416099, -7.983355877644336],
              [-39.571146519679296, -7.983355881248157],
              [-39.571146534936226, -7.983355884878266],
              [-39.571146550186825, -7.983355888534668],
              [-39.571146565431071, -7.983355892217328],
              [-39.571146580668895, -7.983355895926279],
              [-39.571146595900274, -7.983355899661454],
              [-39.571146611125144, -7.983355903422888],
              [-39.571146626343477, -7.983355907210543],
              [-39.571146641555224, -7.983355911024428],
              [-39.571146656760327, -7.983355914864547],
              [-39.571146671958743, -7.98335591873083],
              [-39.571146687150431, -7.983355922623286],
              [-39.571146702335362, -7.98335592654193],
              [-39.571146717513471, -7.983355930486733],
              [-39.571146732684703, -7.983355934457711],
              [-39.571146747849042, -7.983355938454809],
              [-39.571146763006411, -7.983355942478032],
              [-39.571146778156788, -7.983355946527364],
              [-39.57114679330013, -7.983355950603006],
              [-39.571146779247378, -7.983355955724637],
              [-39.572033795343366, -7.983595512828886],
              [-39.572033808721429, -7.983595486949487],
              [-39.572033969739614, -7.983595532023753],
              [-39.572034129897062, -7.983595580048764],
              [-39.572034289139339, -7.983595631008236],
              [-39.572034447412364, -7.983595684884849],
              [-39.572034604662356, -7.983595741660295],
              [-39.572034760835898, -7.983595801315277],
              [-39.572034915879925, -7.983595863829541],
              [-39.572035069741744, -7.983595929181853],
              [-39.572035222369131, -7.983595997349977],
              [-39.572035373710186, -7.983596068310789],
              [-39.572035523713524, -7.983596142040158],
              [-39.572035672328155, -7.983596218513047],
              [-39.572035819503611, -7.98359629770348],
              [-39.572035965189876, -7.983596379584552],
              [-39.572036109337461, -7.983596464128432],
              [-39.572036251897408, -7.983596551306412],
              [-39.572036392821268, -7.983596641088857],
              [-39.572036532061169, -7.983596733445284],
              [-39.572036669569798, -7.98359682834431],
              [-39.572036805300435, -7.983596925753679],
              [-39.572036939206988, -7.983597025640315],
              [-39.572037071243948, -7.983597127970287],
              [-39.572037201366463, -7.983597232708817],
              [-39.572037329530303, -7.983597339820325],
              [-39.572037455691962, -7.983597449268417],
              [-39.572037579808566, -7.983597561015904],
              [-39.572037701837914, -7.983597675024864],
              [-39.572037821738604, -7.983597791256505],
              [-39.572037939469872, -7.983597909671357],
              [-39.572038054991722, -7.9835980302292],
              [-39.572038168264896, -7.983598152889067],
              [-39.572038279250933, -7.983598277609308],
              [-39.572038387912116, -7.983598404347529],
              [-39.572038494211526, -7.98359853306067],
              [-39.572038598113046, -7.98359866370501],
              [-39.572038699581384, -7.983598796236149],
              [-39.572038798582057, -7.983598930609092],
              [-39.572038895081462, -7.98359906677817],
              [-39.572038989046774, -7.983599204697128],
              [-39.572039080446082, -7.983599344319085],
              [-39.572039169248363, -7.983599485596623],
              [-39.572039255423398, -7.98359962848175],
              [-39.572039338941941, -7.983599772925906],
              [-39.572039419775606, -7.983599918880037],
              [-39.572039497896952, -7.983600066294553],
              [-39.572039573279405, -7.983600215119381],
              [-39.572039645897391, -7.983600365303948],
              [-39.572039715726206, -7.983600516797202],
              [-39.572039782742152, -7.983600669547706],
              [-39.572039846922451, -7.983600823503585],
              [-39.572039908245287, -7.983600978612504],
              [-39.572039966689829, -7.983601134821762],
              [-39.572040022236237, -7.983601292078307],
              [-39.572040074865647, -7.983601450328702],
              [-39.572040124560139, -7.983601609519171],
              [-39.572040171302866, -7.983601769595669],
              [-39.572040215077941, -7.98360193050377],
              [-39.572040255870476, -7.983602092188822],
              [-39.572040293666625, -7.98360225459592],
              [-39.572040328453554, -7.983602417669871],
              [-39.572040360219411, -7.983602581355264],
              [-39.572040388953468, -7.983602745596478],
              [-39.57204041464589, -7.983602910337747],
              [-39.572040437287988, -7.983603075523063],
              [-39.572040456872067, -7.98360324109635],
              [-39.572040473391482, -7.983603407001312],
              [-39.572040486840599, -7.983603573181617],
              [-39.57204049721485, -7.983603739580795],
              [-39.572040504510731, -7.983603906142317],
              [-39.572040508725735, -7.983604072809588],
              [-39.572040509858454, -7.983604239526016],
              [-39.572040507908497, -7.983604406234912],
              [-39.572040502876519, -7.983604572879685],
              [-39.572040494764238, -7.98360473940367],
              [-39.572040483574419, -7.983604905750342],
              [-39.572040469310842, -7.983605071863169],
              [-39.572040451978367, -7.983605237685685],
              [-39.572040431582877, -7.983605403161576],
              [-39.572040408131301, -7.983605568234619],
              [-39.572040381631616, -7.983605732848762],
              [-39.572040352092806, -7.983605896948054],
              [-39.572040319524909, -7.983606060476753],
              [-39.57204028393901, -7.983606223379297],
              [-39.572040245347189, -7.983606385600337],
              [-39.572040203762548, -7.983606547084748],
              [-39.572040159199226, -7.983606707777717],
              [-39.572040111672351, -7.98360686762461],
              [-39.572040061198081, -7.9836070265711],
              [-39.572040007793575, -7.983607184563232],
              [-39.572039951476938, -7.983607341547294],
              [-39.572039892267348, -7.983607497470005],
              [-39.572039830184885, -7.983607652278351],
              [-39.572039765250665, -7.983607805919725],
              [-39.57203969748673, -7.983607958341976],
              [-39.572039626916123, -7.983608109493315],
              [-39.572039553562796, -7.98360825932232],
              [-39.572039477451682, -7.983608407778144],
              [-39.572039398608645, -7.983608554810393],
              [-39.572039317061254, -7.983608700368425],
              [-39.572039348667957, -7.983608711089095],
              [-39.571884835762908, -7.983878651375527],
              [-39.57188482867663, -7.983878579695377],
              [-39.571884778789091, -7.983878665743356],
              [-39.571884727956132, -7.983878751239713],
              [-39.571884676183878, -7.983878836174162],
              [-39.571884623478596, -7.983878920536415],
              [-39.571884569846638, -7.983879004316289],
              [-39.571884515294485, -7.983879087503682],
              [-39.571884459828709, -7.983879170088543],
              [-39.571884403456018, -7.983879252060927],
              [-39.571884346183218, -7.983879333410896],
              [-39.571884288017223, -7.98387941412867],
              [-39.571884228965047, -7.983879494204471],
              [-39.571884169033837, -7.983879573628646],
              [-39.571884108230805, -7.983879652391619],
              [-39.571884046563312, -7.983879730483843],
              [-39.571883984038791, -7.983879807895941],
              [-39.571883920664789, -7.983879884618536],
              [-39.571883856448977, -7.983879960642383],
              [-39.571883791399088, -7.983880035958255],
              [-39.571883725522987, -7.983880110557149],
              [-39.571883658828611, -7.983880184429967],
              [-39.57188359132401, -7.983880257567827],
              [-39.57188352301737, -7.983880329961929],
              [-39.57188345391689, -7.983880401603494],
              [-39.571883384030954, -7.983880472483875],
              [-39.571883313367969, -7.983880542594512],
              [-39.57188324193649, -7.983880611926977],
              [-39.571883169745114, -7.983880680472855],
              [-39.57188309680258, -7.983880748223897],
              [-39.571883023117678, -7.983880815171914],
              [-39.571882948699319, -7.983880881308814],
              [-39.571882873556483, -7.983880946626646],
              [-39.571882797698223, -7.98388101111748],
              [-39.571882721133719, -7.983881074773566],
              [-39.571882643872215, -7.983881137587212],
              [-39.571882565923019, -7.983881199550822],
              [-39.571882487295568, -7.98388126065694],
              [-39.57188240799934, -7.98388132089815],
              [-39.571882328043905, -7.983881380267203],
              [-39.571882247438914, -7.983881438756918],
              [-39.571882166194115, -7.983881496360241],
              [-39.571882084319306, -7.983881553070235],
              [-39.571882001824356, -7.983881608880028],
              [-39.571881918719257, -7.983881663782904],
              [-39.571881835014011, -7.983881717772226],
              [-39.571881750718752, -7.983881770841455],
              [-39.571881665843648, -7.983881822984216],
              [-39.571881580398923, -7.983881874194211],
              [-39.57188149439493, -7.983881924465217],
              [-39.571881407842007, -7.983881973791229],
              [-39.571881320750634, -7.983882022166246],
              [-39.571881233131336, -7.983882069584417],
              [-39.57188114499467, -7.983882116040057],
              [-39.57188105635128, -7.983882161527546],
              [-39.571880967211882, -7.983882206041391],
              [-39.571880877587205, -7.983882249576202],
              [-39.571880787488091, -7.983882292126744],
              [-39.571880696925433, -7.983882333687864],
              [-39.571880605910131, -7.983882374254557],
              [-39.571880514453198, -7.983882413821906],
              [-39.571880422565663, -7.98388245238516],
              [-39.571880330258637, -7.983882489939659],
              [-39.571880237543226, -7.983882526480815],
              [-39.571880144430672, -7.983882562004285],
              [-39.57188005093218, -7.983882596505764],
              [-39.571879957059053, -7.9838826299811],
              [-39.57187986282262, -7.983882662426201],
              [-39.571879768234261, -7.983882693837215],
              [-39.571879673305396, -7.98388272421028],
              [-39.571879578047479, -7.983882753541775],
              [-39.571879482472028, -7.983882781828168],
              [-39.571879386590567, -7.983882809066014],
              [-39.571879290414664, -7.983882835252039],
              [-39.571879193955944, -7.983882860383067],
              [-39.571879097226038, -7.983882884456076],
              [-39.571879000236642, -7.98388290746816],
              [-39.571878902999465, -7.983882929416542],
              [-39.571878805526232, -7.98388295029858],
              [-39.571878707828702, -7.983882970111713],
              [-39.571878609918713, -7.983882988853614],
              [-39.571878511808038, -7.983883006521946],
              [-39.571878413508557, -7.98388302311465],
              [-39.571878315032109, -7.983883038629671],
              [-39.571878216390608, -7.983883053065145],
              [-39.571878117595944, -7.983883066419346],
              [-39.571878018660051, -7.983883078690647],
              [-39.571877919594883, -7.98388308987758],
              [-39.571877820412368, -7.983883099978789],
              [-39.571877721124523, -7.983883108993044],
              [-39.571877621743297, -7.983883116919272],
              [-39.57187752228073, -7.983883123756518],
              [-39.571877422748791, -7.983883129503919],
              [-39.571877323159505, -7.983883134160821],
              [-39.571877223524915, -7.983883137726654],
              [-39.571877123857028, -7.983883140201009],
              [-39.571877024167904, -7.983883141583517],
              [-39.571876924469528, -7.983883141874077],
              [-39.571876824773987, -7.983883141072621],
              [-39.571876725093283, -7.983883139179289],
              [-39.57187662543987, -7.983883136193788],
              [-39.571876675719686, -7.983883185233314],
              [-39.570781786604606, -7.983844355423367],
              [-39.570781742796505, -7.983844335944553],
              [-39.570781689972357, -7.983844334226013],
              [-39.570781637139063, -7.983844332814243],
              [-39.570781584298409, -7.98384433170931],
              [-39.570781531452198, -7.983844330911187],
              [-39.570781478602214, -7.983844330419883],
              [-39.570781425750262, -7.983844330235502],
              [-39.570781372898132, -7.983844330357971],
              [-39.570781320047615, -7.983844330787337],
              [-39.570781267200495, -7.98384433152355],
              [-39.570781214358583, -7.983844332566621],
              [-39.570781161523648, -7.983844333916482],
              [-39.57078110869751, -7.983844335573148],
              [-39.570781055881945, -7.983844337536483],
              [-39.57078100307875, -7.983844339806446],
              [-39.570780950289716, -7.983844342382988],
              [-39.570780897516613, -7.983844345265952],
              [-39.570780844761266, -7.983844348455356],
              [-39.570780792025452, -7.983844351950973],
              [-39.570780739310962, -7.983844355752761],
              [-39.570780686619578, -7.98384435986057],
              [-39.570780633953085, -7.983844364274237],
              [-39.570780581313272, -7.983844368993654],
              [-39.570780528701938, -7.983844374018669],
              [-39.570780476120845, -7.983844379349029],
              [-39.570780423571797, -7.983844384984625],
              [-39.570780371056571, -7.983844390925253],
              [-39.570780318576958, -7.983844397170682],
              [-39.570780266134719, -7.983844403720735],
              [-39.570780213731645, -7.983844410575193],
              [-39.570780161369498, -7.983844417733798],
              [-39.570780109050077, -7.983844425196295],
              [-39.570780056775149, -7.98384443296248],
              [-39.570780004546485, -7.983844441032051],
              [-39.570779952365861, -7.983844449404764],
              [-39.570779900235046, -7.983844458080301],
              [-39.570779848155802, -7.983844467058392],
              [-39.570779796129905, -7.983844476338721],
              [-39.570779744159118, -7.983844485920956],
              [-39.570779692245203, -7.983844495804826],
              [-39.570779640389922, -7.983844505989938],
              [-39.57077958859503, -7.983844516475987],
              [-39.57077953686229, -7.983844527262587],
              [-39.570779485193469, -7.983844538349409],
              [-39.570779433590275, -7.983844549735995],
              [-39.570779382054518, -7.983844561422066],
              [-39.570779330587911, -7.983844573407192],
              [-39.570779279192209, -7.983844585690912],
              [-39.570779227869146, -7.983844598272863],
              [-39.570779176620469, -7.983844611152623],
              [-39.570779125447928, -7.983844624329715],
              [-39.570779074353261, -7.983844637803712],
              [-39.570779023338162, -7.983844651574154],
              [-39.570778972404405, -7.983844665640584],
              [-39.570778921553718, -7.983844680002513],
              [-39.570778870787798, -7.983844694659433],
              [-39.57077882010838, -7.983844709610903],
              [-39.570778769517197, -7.98384472485638],
              [-39.570778719015948, -7.983844740395357],
              [-39.570778668606351, -7.983844756227293],
              [-39.570778618290106, -7.983844772351665],
              [-39.57077856806896, -7.98384478876793],
              [-39.570778517944554, -7.983844805475511],
              [-39.57077846791865, -7.983844822473849],
              [-39.570778417992912, -7.983844839762392],
              [-39.57077836816903, -7.983844857340491],
              [-39.570778318448717, -7.983844875207608],
              [-39.570778268833635, -7.983844893363153],
              [-39.57077821932549, -7.983844911806425],
              [-39.570778169925944, -7.983844930536872],
              [-39.570778120636675, -7.983844949553834],
              [-39.57077807145938, -7.983844968856635],
              [-39.570778022395693, -7.983844988444682],
              [-39.570777973447299, -7.983845008317279],
              [-39.570777924615854, -7.983845028473756],
              [-39.570777875903012, -7.983845048913416],
              [-39.570777827310437, -7.983845069635582],
              [-39.570777778839762, -7.983845090639549],
              [-39.57077773049263, -7.9838451119246],
              [-39.570777682270702, -7.983845133490011],
              [-39.570777634175585, -7.983845155335081],
              [-39.570777586208933, -7.983845177459037],
              [-39.57077753837239, -7.983845199861102],
              [-39.570777490667545, -7.983845222540524],
              [-39.570777443096027, -7.983845245496588],
              [-39.570777395659441, -7.983845268728492],
              [-39.570777348359421, -7.983845292235411],
              [-39.570777301197552, -7.983845316016585],
              [-39.570777254175425, -7.983845340071201],
              [-39.570777207294675, -7.983845364398422],
              [-39.570777160556844, -7.983845388997453],
              [-39.570777113963565, -7.983845413867436],
              [-39.570777067516374, -7.983845439007553],
              [-39.570777021216884, -7.983845464416936],
              [-39.57077697506665, -7.983845490094716],
              [-39.570776929067222, -7.983845516040035],
              [-39.570776883220191, -7.983845542251999],
              [-39.570776837527099, -7.983845568729723],
              [-39.570776791989474, -7.983845595472337],
              [-39.5707767466089, -7.983845622478877],
              [-39.570776797825076, -7.983845628627266],
              [-39.570375347042649, -7.984086150352446],
              [-39.570375300519828, -7.98408611143156],
              [-39.570375219774924, -7.984086160374604],
              [-39.570375139545718, -7.984086210153249],
              [-39.57037505984092, -7.984086260762121],
              [-39.570374980669179, -7.98408631219572],
              [-39.570374902039092, -7.984086364448475],
              [-39.57037482395917, -7.984086417514743],
              [-39.570374746437892, -7.984086471388742],
              [-39.57037466948367, -7.984086526064602],
              [-39.570374593104837, -7.984086581536444],
              [-39.570374517309702, -7.984086637798231],
              [-39.570374442106463, -7.984086694843842],
              [-39.570374367503284, -7.984086752667115],
              [-39.570374293508259, -7.98408681126177],
              [-39.570374220129416, -7.984086870621432],
              [-39.570374147374707, -7.984086930739682],
              [-39.570374075252033, -7.984086991610008],
              [-39.57037400376921, -7.984087053225783],
              [-39.57037393293399, -7.984087115580371],
              [-39.57037386275406, -7.984087178666915],
              [-39.570373793237039, -7.984087242478709],
              [-39.57037372439045, -7.984087307008695],
              [-39.570373656221783, -7.984087372249941],
              [-39.570373588738391, -7.984087438195392],
              [-39.570373521947658, -7.984087504837843],
              [-39.570373455856775, -7.984087572170091],
              [-39.570373390472938, -7.984087640184835],
              [-39.570373325803224, -7.9840877088747],
              [-39.570373261854641, -7.984087778232226],
              [-39.570373198634151, -7.984087848249892],
              [-39.570373136148596, -7.984087918920094],
              [-39.570373074404749, -7.984087990235166],
              [-39.570373013409323, -7.984088062187388],
              [-39.570372953168928, -7.984088134768949],
              [-39.570372893690084, -7.984088207971972],
              [-39.570372834979253, -7.98408828178856],
              [-39.570372777042799, -7.984088356210625],
              [-39.570372719887025, -7.984088431230166],
              [-39.5703726635181, -7.984088506839007],
              [-39.570372607942154, -7.984088583028965],
              [-39.570372553165221, -7.984088659791777],
              [-39.57037249919324, -7.984088737119097],
              [-39.570372446032067, -7.984088815002558],
              [-39.570372393687443, -7.98408889343373],
              [-39.570372342165086, -7.984088972404045],
              [-39.570372291470555, -7.984089051904975],
              [-39.570372241609363, -7.984089131927895],
              [-39.570372192586895, -7.984089212464145],
              [-39.570372144408509, -7.984089293504952],
              [-39.570372097079378, -7.984089375041517],
              [-39.570372050604696, -7.984089457065052],
              [-39.570372004989473, -7.984089539566628],
              [-39.570371960238631, -7.984089622537302],
              [-39.570371916357061, -7.984089705968052],
              [-39.570371873349529, -7.984089789849844],
              [-39.570371831220662, -7.984089874173629],
              [-39.570371789975063, -7.984089958930161],
              [-39.570371749617188, -7.984090044110323],
              [-39.57037171015142, -7.984090129704855],
              [-39.570371671582038, -7.984090215704476],
              [-39.570371633913226, -7.984090302099855],
              [-39.570371597149084, -7.984090388881611],
              [-39.570371561293577, -7.984090476040327],
              [-39.570371526350606, -7.984090563566571],
              [-39.570371492323936, -7.984090651450829],
              [-39.570371459217313, -7.984090739683603],
              [-39.570371427034274, -7.984090828255258],
              [-39.570371395778338, -7.984090917156241],
              [-39.570371365452885, -7.984091006376929],
              [-39.570371336061214, -7.984091095907593],
              [-39.5703713076065, -7.984091185738508],
              [-39.570371280091834, -7.984091275859999],
              [-39.570371253520214, -7.984091366262276],
              [-39.570371227894505, -7.984091456935476],
              [-39.570371203217483, -7.984091547869803],
              [-39.57037117949185, -7.984091639055411],
              [-39.570371156720142, -7.984091730482374],
              [-39.570371134904875, -7.98409182214079],
              [-39.57037111404837, -7.984091914020714],
              [-39.570371094152918, -7.984092006112188],
              [-39.570371075220677, -7.984092098405215],
              [-39.570371057253688, -7.98409219088975],
              [-39.570371040253896, -7.984092283555871],
              [-39.570371024223157, -7.9840923763934],
              [-39.570371009163203, -7.984092469392356],
              [-39.57037099507567, -7.984092562542577],
              [-39.570370981962093, -7.984092655834041],
              [-39.570370969823884, -7.984092749256569],
              [-39.570370958662359, -7.984092842800047],
              [-39.570370948478732, -7.98409293645432],
              [-39.570370939274135, -7.984093030209237],
              [-39.570370931049503, -7.984093124054676],
              [-39.570370923805775, -7.984093217980372],
              [-39.570370917543727, -7.984093311976187],
              [-39.570370912264046, -7.984093406031905],
              [-39.570370907967295, -7.984093500137349],
              [-39.570370904653942, -7.984093594282297],
              [-39.570370902324335, -7.984093688456531],
              [-39.570370900978745, -7.984093782649831],
              [-39.570370900617313, -7.984093876852019],
              [-39.57037091171442, -7.984093935307125],
              [-39.570371749199268, -7.984697698859248],
              [-39.570371738193586, -7.984697706442983],
              [-39.570371738241043, -7.984697725750316],
              [-39.57037173832984, -7.984697745057507],
              [-39.570371738459997, -7.984697764364438],
              [-39.570371738631472, -7.984697783671079],
              [-39.570371738844301, -7.984697802977288],
              [-39.570371739098462, -7.984697822282986],
              [-39.57037173939397, -7.984697841588154],
              [-39.570371739730795, -7.984697860892626],
              [-39.570371740108982, -7.984697880196339],
              [-39.570371740528486, -7.984697899499197],
              [-39.570371740989337, -7.984697918801124],
              [-39.570371741491513, -7.984697938102032],
              [-39.570371742035015, -7.98469795740179],
              [-39.570371742619834, -7.984697976700379],
              [-39.570371743245992, -7.98469799599768],
              [-39.570371743913469, -7.984698015293558],
              [-39.57037174462225, -7.984698034588033],
              [-39.570371745372341, -7.984698053880937],
              [-39.570371746163751, -7.984698073172195],
              [-39.570371746996479, -7.98469809246173],
              [-39.570371747870489, -7.984698111749442],
              [-39.570371748785803, -7.984698131035241],
              [-39.570371749742421, -7.984698150319064],
              [-39.570371750740321, -7.984698169600804],
              [-39.570371751779518, -7.984698188880389],
              [-39.570371752859991, -7.984698208157682],
              [-39.570371753981725, -7.984698227432671],
              [-39.570371755144748, -7.98469824670521],
              [-39.570371756349019, -7.984698265975226],
              [-39.570371757594565, -7.984698285242665],
              [-39.570371758881357, -7.984698304507398],
              [-39.570371760209419, -7.984698323769328],
              [-39.570371761578691, -7.98469834302842],
              [-39.570371762989218, -7.984698362284541],
              [-39.570371764440985, -7.98469838153759],
              [-39.570371765933949, -7.984698400787552],
              [-39.570371767468153, -7.984698420034254],
              [-39.570371769043547, -7.984698439277698],
              [-39.570371770660159, -7.9846984585177],
              [-39.570371772317962, -7.984698477754237],
              [-39.570371774016948, -7.984698496987204],
              [-39.57037177575711, -7.984698516216483],
              [-39.570371777538455, -7.984698535442059],
              [-39.570371779360954, -7.98469855466378],
              [-39.570371781224615, -7.984698573881571],
              [-39.570371783129424, -7.984698593095364],
              [-39.570371785075359, -7.98469861230506],
              [-39.570371787062442, -7.984698631510565],
              [-39.570371789090629, -7.984698650711805],
              [-39.570371791159936, -7.984698669908693],
              [-39.570371793270347, -7.984698689101107],
              [-39.570371795421842, -7.984698708289],
              [-39.57037179761442, -7.984698727472291],
              [-39.570371799848061, -7.984698746650841],
              [-39.570371802122793, -7.984698765824598],
              [-39.570371804438537, -7.984698784993498],
              [-39.570371806795357, -7.984698804157405],
              [-39.57037180919319, -7.984698823316249],
              [-39.57037181163205, -7.984698842469969],
              [-39.570371814111915, -7.984698861618441],
              [-39.570371816632765, -7.9846988807616],
              [-39.570371819194619, -7.984698899899364],
              [-39.570371821797444, -7.984698919031594],
              [-39.570371824441217, -7.984698938158274],
              [-39.57037182712596, -7.984698957279289],
              [-39.570371829851631, -7.98469897639452],
              [-39.570371832618221, -7.984698995503932],
              [-39.570371835425732, -7.984699014607404],
              [-39.570371838274149, -7.984699033704842],
              [-39.570371841163436, -7.984699052796209],
              [-39.5703718440936, -7.984699071881371],
              [-39.570371847064635, -7.984699090960243],
              [-39.570371850076512, -7.984699110032756],
              [-39.570371853129217, -7.984699129098828],
              [-39.570371856222742, -7.984699148158338],
              [-39.570371859357081, -7.984699167211245],
              [-39.570371862532205, -7.984699186257433],
              [-39.570371865748108, -7.984699205296814],
              [-39.570371869004774, -7.984699224329331],
              [-39.570371872302182, -7.984699243354846],
              [-39.570371875640312, -7.984699262373299],
              [-39.570371879019177, -7.98469928138462],
              [-39.570371882438735, -7.984699300388712],
              [-39.570371885898979, -7.984699319385482],
              [-39.570371889399894, -7.984699338374849],
              [-39.570371892941459, -7.984699357356718],
              [-39.570371896523667, -7.984699376331016],
              [-39.57037190014649, -7.98469939529764],
              [-39.570371903809935, -7.984699414256494],
              [-39.570371907513959, -7.98469943320754],
              [-39.57037191125854, -7.984699452150645],
              [-39.570371915043694, -7.984699471085744],
              [-39.57037191886937, -7.98469949001275],
              [-39.570371922735575, -7.984699508931544],
              [-39.570371926642267, -7.984699527842094],
              [-39.570371930589474, -7.984699546744268],
              [-39.570371934577125, -7.984699565638032],
              [-39.570371938605227, -7.98469958452324],
              [-39.570371942673766, -7.984699603399841],
              [-39.570371933308628, -7.984699598089954],
              [-39.570494944266436, -7.9852672838857],
              [-39.570495126996214, -7.985268188150122],
              [-39.570577198091243, -7.985953969713012],
              [-39.570577151136916, -7.98595391969385],
              [-39.570577162451983, -7.985954010343256],
              [-39.570577174684828, -7.985954100874144],
              [-39.570577187834211, -7.985954191277244],
              [-39.570577201898807, -7.98595428154339],
              [-39.570577216877162, -7.985954371663299],
              [-39.570577232767761, -7.985954461627843],
              [-39.570577249568963, -7.985954551427827],
              [-39.570577267279091, -7.98595464105408],
              [-39.570577285896299, -7.985954730497482],
              [-39.570577305418723, -7.985954819748918],
              [-39.570577325844347, -7.985954908799227],
              [-39.570577347171096, -7.985954997639377],
              [-39.570577369396787, -7.985955086260293],
              [-39.570577392519169, -7.985955174652967],
              [-39.570577416535876, -7.985955262808329],
              [-39.570577441444456, -7.985955350717415],
              [-39.570577467242352, -7.985955438371255],
              [-39.570577493926976, -7.985955525760947],
              [-39.570577521495572, -7.985955612877529],
              [-39.570577549945334, -7.985955699712092],
              [-39.570577579273369, -7.985955786255862],
              [-39.570577609476679, -7.985955872499946],
              [-39.57057764055218, -7.985955958435596],
              [-39.570577672496718, -7.985956044054007],
              [-39.570577705307009, -7.985956129346455],
              [-39.570577738979729, -7.985956214304239],
              [-39.570577773511445, -7.985956298918715],
              [-39.570577808898619, -7.985956383181247],
              [-39.570577845137649, -7.985956467083194],
              [-39.570577882224846, -7.985956550616079],
              [-39.570577920156417, -7.98595663377132],
              [-39.570577958928496, -7.985956716540443],
              [-39.570577998537118, -7.985956798915063],
              [-39.570578038978269, -7.9859568808867],
              [-39.570578080247799, -7.985956962447034],
              [-39.570578122341523, -7.98595704358771],
              [-39.570578165255121, -7.98595712430055],
              [-39.570578208984237, -7.985957204577188],
              [-39.57057825352441, -7.985957284409559],
              [-39.570578298871069, -7.98595736378941],
              [-39.570578345019634, -7.985957442708735],
              [-39.570578391965363, -7.985957521159444],
              [-39.570578439703489, -7.985957599133531],
              [-39.570578488229117, -7.985957676623052],
              [-39.57057853753733, -7.985957753620086],
              [-39.570578587623075, -7.985957830116833],
              [-39.570578638481251, -7.985957906105445],
              [-39.570578690106686, -7.985957981578176],
              [-39.570578742494092, -7.985958056527339],
              [-39.57057879563812, -7.985958130945304],
              [-39.570578849533376, -7.985958204824421],
              [-39.570578904174361, -7.985958278157222],
              [-39.570578959555462, -7.985958350936225],
              [-39.570579015671086, -7.98595842315396],
              [-39.570579072515486, -7.985958494803097],
              [-39.570579130082841, -7.985958565876293],
              [-39.570579188367311, -7.985958636366336],
              [-39.57057924736295, -7.985958706266043],
              [-39.570579307063731, -7.985958775568227],
              [-39.570579367463559, -7.985958844265893],
              [-39.570579428556279, -7.985958912351983],
              [-39.570579490335668, -7.985958979819553],
              [-39.570579552795408, -7.985959046661733],
              [-39.570579615929155, -7.985959112871726],
              [-39.570579679730436, -7.985959178442774],
              [-39.570579744192777, -7.985959243368174],
              [-39.570579809309578, -7.9859593076413],
              [-39.570579875074216, -7.98595937125561],
              [-39.57057994147997, -7.985959434204602],
              [-39.570580008520068, -7.985959496481889],
              [-39.570580076187674, -7.985959558081079],
              [-39.570580144475898, -7.985959618995895],
              [-39.570580213377767, -7.985959679220146],
              [-39.570580282886255, -7.985959738747676],
              [-39.570580352994256, -7.98595979757241],
              [-39.570580423694643, -7.98595985568837],
              [-39.570580494980184, -7.985959913089592],
              [-39.570580566843631, -7.985959969770222],
              [-39.570580639277637, -7.985960025724532],
              [-39.570580712274811, -7.985960080946784],
              [-39.570580785827737, -7.985960135431308],
              [-39.570580859928896, -7.98596018917261],
              [-39.570580934570707, -7.985960242165173],
              [-39.570581009745581, -7.985960294403593],
              [-39.570581085445866, -7.985960345882552],
              [-39.570581161663824, -7.985960396596808],
              [-39.570581238391647, -7.985960446541176],
              [-39.57058131562156, -7.985960495710557],
              [-39.570581393345684, -7.985960544099938],
              [-39.570581471556046, -7.985960591704401],
              [-39.570581550244711, -7.985960638519066],
              [-39.570581629403641, -7.985960684539176],
              [-39.570581709024779, -7.985960729760031],
              [-39.570581789099975, -7.985960774177014],
              [-39.570581869621066, -7.985960817785601],
              [-39.570581950579864, -7.985960860581344],
              [-39.570582031968087, -7.985960902559864],
              [-39.570582113777434, -7.985960943716893],
              [-39.570582195999592, -7.985960984048243],
              [-39.57058219844609, -7.985961018044004],
              [-39.57183304323101, -7.986566764059259],
              [-39.572743860892878, -7.986170049666367],
              [-39.574336981645786, -7.986522863473246],
              [-39.577869660949311, -7.989805313554638],
              [-39.581316573417858, -7.990662484672044],
              [-39.591366418235481, -7.993382575631648],
              [-39.617624230453082, -8.000742872408889],
              [-39.617644237362008, -8.000651577607419],
              [-39.618693876451012, -7.998696811665025],
              [-39.585347485630564, -7.98934923267355],
              [-39.586383355067888, -7.988905638854082],
              [-39.586383360691087, -7.988905654947916],
              [-39.586383371336197, -7.988905650397863],
              [-39.586383381987105, -7.988905645861379],
              [-39.58638339264381, -7.988905641338468],
              [-39.586383403306307, -7.988905636829078],
              [-39.586383413974573, -7.988905632333275],
              [-39.586383424648574, -7.988905627851045],
              [-39.586383435328322, -7.988905623382403],
              [-39.586383446013777, -7.988905618927379],
              [-39.586383456704922, -7.988905614485928],
              [-39.58638346740176, -7.988905610058084],
              [-39.586383478104239, -7.988905605643859],
              [-39.586383488812388, -7.98890560124326],
              [-39.586383499526143, -7.988905596856277],
              [-39.586383510245504, -7.988905592482936],
              [-39.586383520970465, -7.988905588123252],
              [-39.586383531700996, -7.988905583777205],
              [-39.58638354243709, -7.988905579444848],
              [-39.58638355317872, -7.988905575126132],
              [-39.586383563925871, -7.988905570821084],
              [-39.586383574678536, -7.988905566529709],
              [-39.586383585436664, -7.98890556225203],
              [-39.586383596200285, -7.988905557988035],
              [-39.586383606969328, -7.988905553737747],
              [-39.586383617743842, -7.988905549501169],
              [-39.586383628523741, -7.988905545278291],
              [-39.586383639309048, -7.988905541069139],
              [-39.586383650099748, -7.98890553687371],
              [-39.586383660895791, -7.988905532692025],
              [-39.586383671697192, -7.988905528524077],
              [-39.586383682503936, -7.98890552436987],
              [-39.586383693315966, -7.988905520229419],
              [-39.586383704133297, -7.988905516102742],
              [-39.5863837149559, -7.988905511989806],
              [-39.586383725783769, -7.98890550789068],
              [-39.586383736616874, -7.988905503805307],
              [-39.586383747455216, -7.988905499733741],
              [-39.586383758298744, -7.988905495675972],
              [-39.58638376914746, -7.988905491631991],
              [-39.586383780001348, -7.988905487601818],
              [-39.586383790860403, -7.988905483585449],
              [-39.586383801724573, -7.988905479582911],
              [-39.586383812593873, -7.988905475594193],
              [-39.586383823468253, -7.988905471619302],
              [-39.586383834347728, -7.988905467658271],
              [-39.586383845232255, -7.988905463711083],
              [-39.586383856121842, -7.98890545977773],
              [-39.586383867016458, -7.988905455858292],
              [-39.58638387791607, -7.988905451952678],
              [-39.586383888820677, -7.988905448060937],
              [-39.586383899730279, -7.988905444183072],
              [-39.586383910644827, -7.988905440319084],
              [-39.586383921564291, -7.988905436469006],
              [-39.586383932488694, -7.988905432632817],
              [-39.586383943418014, -7.988905428810511],
              [-39.5863839543522, -7.988905425002139],
              [-39.586383965291269, -7.988905421207666],
              [-39.586383976235162, -7.988905417427136],
              [-39.586383987183915, -7.988905413660545],
              [-39.586383998137464, -7.988905409907866],
              [-39.586384009095823, -7.988905406169127],
              [-39.586384020058958, -7.988905402444335],
              [-39.586384031026839, -7.988905398733488],
              [-39.58638404199948, -7.988905395036615],
              [-39.586384052976847, -7.988905391353701],
              [-39.58638406395891, -7.988905387684766],
              [-39.58638407494567, -7.988905384029824],
              [-39.586384085937091, -7.988905380388842],
              [-39.586384096933173, -7.988905376761854],
              [-39.586384107933888, -7.988905373148859],
              [-39.586384118939222, -7.988905369549862],
              [-39.58638412994916, -7.98890536596489],
              [-39.586384140963666, -7.988905362393893],
              [-39.586384151982749, -7.988905358836944],
              [-39.586384163006372, -7.988905355294039],
              [-39.586384174034521, -7.988905351765143],
              [-39.586384185067189, -7.988905348250277],
              [-39.586384196104341, -7.988905344749437],
              [-39.586384207145969, -7.988905341262659],
              [-39.586384218192045, -7.988905337789892],
              [-39.586384229242576, -7.988905334331255],
              [-39.586384240297527, -7.988905330886644],
              [-39.586384251356861, -7.988905327456117],
              [-39.58638426242058, -7.988905324039628],
              [-39.586384273488676, -7.988905320637274],
              [-39.586384284561127, -7.988905317248942],
              [-39.586384295637885, -7.988905313874743],
              [-39.586384306718983, -7.988905310514656],
              [-39.586384317804352, -7.988905307168646],
              [-39.586384328893992, -7.988905303836768],
              [-39.586384339987909, -7.988905300518984],
              [-39.586384351086068, -7.988905297215311],
              [-39.586384362188426, -7.988905293925788],
              [-39.586384373295004, -7.988905290650393],
              [-39.586384384405754, -7.98890528738911],
              [-39.586384395520689, -7.98890528414197],
              [-39.586384406639759, -7.988905280908981],
              [-39.586384417762964, -7.98890527769017],
              [-39.586384428890277, -7.988905274485472],
              [-39.58638444002181, -7.988905271293604],
              [-39.586384443406509, -7.988905275505443],
              [-39.587138168954112, -7.988689745953533],
              [-39.587138191924055, -7.98868974620215],
              [-39.587138225653455, -7.988689736627954],
              [-39.587138259419724, -7.988689727183774],
              [-39.587138293222353, -7.988689717869755],
              [-39.587138327060842, -7.988689708686037],
              [-39.587138360934688, -7.988689699632761],
              [-39.587138394843386, -7.988689690710052],
              [-39.587138428786425, -7.988689681918065],
              [-39.587138462763299, -7.988689673256924],
              [-39.587138496773484, -7.988689664726745],
              [-39.587138530816517, -7.988689656327662],
              [-39.587138564891845, -7.988689648059792],
              [-39.587138598998983, -7.988689639923276],
              [-39.5871386331374, -7.988689631918263],
              [-39.587138667306611, -7.988689624044802],
              [-39.587138701506106, -7.988689616303049],
              [-39.58713873573533, -7.988689608693123],
              [-39.587138769993821, -7.988689601215138],
              [-39.587138804281061, -7.988689593869187],
              [-39.587138838596502, -7.988689586655395],
              [-39.587138872939676, -7.988689579573875],
              [-39.587138907310042, -7.988689572624719],
              [-39.587138941707103, -7.988689565807999],
              [-39.587138976130326, -7.988689559123891],
              [-39.587139010579207, -7.988689552572467],
              [-39.587139045053242, -7.988689546153805],
              [-39.587139079551903, -7.98868953986802],
              [-39.587139114074695, -7.98868953371518],
              [-39.587139148621048, -7.988689527695388],
              [-39.587139183190509, -7.98868952180876],
              [-39.587139217782529, -7.988689516055367],
              [-39.587139252396597, -7.988689510435295],
              [-39.587139287032201, -7.988689504948625],
              [-39.587139321688817, -7.988689499595429],
              [-39.587139356365924, -7.988689494375771],
              [-39.587139391063012, -7.988689489289809],
              [-39.587139425779554, -7.988689484337522],
              [-39.587139460515061, -7.988689479519046],
              [-39.587139495268978, -7.988689474834451],
              [-39.587139530040815, -7.988689470283771],
              [-39.587139564830004, -7.988689465867075],
              [-39.587139599636075, -7.988689461584476],
              [-39.587139634458488, -7.988689457436005],
              [-39.587139669296732, -7.988689453421729],
              [-39.58713970415026, -7.988689449541715],
              [-39.587139739018603, -7.988689445795984],
              [-39.587139773901185, -7.988689442184647],
              [-39.587139808797517, -7.988689438707704],
              [-39.587139843707064, -7.988689435365272],
              [-39.587139878629316, -7.988689432157333],
              [-39.587139913563746, -7.988689429083974],
              [-39.587139948509822, -7.988689426145246],
              [-39.58713998346704, -7.988689423341151],
              [-39.587140018434866, -7.988689420671792],
              [-39.587140053412774, -7.988689418137161],
              [-39.587140088400261, -7.98868941573732],
              [-39.587140123396786, -7.988689413472285],
              [-39.58714015840183, -7.988689411342119],
              [-39.587140193414875, -7.988689409346808],
              [-39.587140228435388, -7.988689407486453],
              [-39.587140263462871, -7.988689405761006],
              [-39.587140298496749, -7.988689404170536],
              [-39.58714033353656, -7.988689402715059],
              [-39.587140368581743, -7.988689401394593],
              [-39.587140403631778, -7.988689400209151],
              [-39.587140438686156, -7.988689399158758],
              [-39.587140473744341, -7.988689398243412],
              [-39.587140508805817, -7.988689397463149],
              [-39.587140543870056, -7.988689396817983],
              [-39.587140578936527, -7.988689396307916],
              [-39.587140614004724, -7.988689395932949],
              [-39.587140649074101, -7.9886893956931],
              [-39.587140684144167, -7.988689395588387],
              [-39.587140719214354, -7.988689395618774],
              [-39.587140754284171, -7.988689395784266],
              [-39.587140789353086, -7.988689396084879],
              [-39.587140824420572, -7.988689396520628],
              [-39.587140859486098, -7.988689397091469],
              [-39.587140894549172, -7.988689397797393],
              [-39.58714092960922, -7.988689398638399],
              [-39.587140964665771, -7.988689399614507],
              [-39.587140999718258, -7.988689400725655],
              [-39.587141034766177, -7.988689401971848],
              [-39.587141069809007, -7.98868940335305],
              [-39.587141104846211, -7.988689404869276],
              [-39.587141139877296, -7.988689406520463],
              [-39.587141174901689, -7.988689408306609],
              [-39.587141209918919, -7.988689410227702],
              [-39.587141244928425, -7.988689412283672],
              [-39.587141279929696, -7.988689414474518],
              [-39.587141314922228, -7.98868941680021],
              [-39.587141349905458, -7.988689419260679],
              [-39.587141384878905, -7.988689421855931],
              [-39.587141419842013, -7.988689424585897],
              [-39.587141454794285, -7.988689427450558],
              [-39.587141489735195, -7.988689430449866],
              [-39.587141524664204, -7.988689433583771],
              [-39.587141559580807, -7.988689436852225],
              [-39.587141594484478, -7.988689440255173],
              [-39.587141629374784, -7.988689443791964],
              [-39.587141616604569, -7.988689469704992],
              [-39.58816393160842, -7.988795111497618],
              [-39.588163919231526, -7.988795078933466],
              [-39.588163971004207, -7.988795084133633],
              [-39.588164022805934, -7.988795089037746],
              [-39.588164074635024, -7.988795093645689],
              [-39.588164126489779, -7.988795097957253],
              [-39.58816417836848, -7.988795101972315],
              [-39.588164230269435, -7.988795105690755],
              [-39.588164282190924, -7.988795109112461],
              [-39.588164334131228, -7.988795112237283],
              [-39.588164386088678, -7.988795115065137],
              [-39.588164438061526, -7.988795117595921],
              [-39.588164490048079, -7.988795119829581],
              [-39.588164542046641, -7.988795121766024],
              [-39.588164594055471, -7.988795123405176],
              [-39.588164646072876, -7.988795124746999],
              [-39.588164698097145, -7.988795125791436],
              [-39.588164750126587, -7.988795126538483],
              [-39.588164802159454, -7.98879512698806],
              [-39.588164854194062, -7.988795127140203],
              [-39.588164906228677, -7.988795126994876],
              [-39.588164958261601, -7.988795126552113],
              [-39.588165010291135, -7.988795125811901],
              [-39.588165062315525, -7.988795124774287],
              [-39.58816511433313, -7.988795123439279],
              [-39.588165166342172, -7.988795121806922],
              [-39.588165218340983, -7.9887951198773],
              [-39.588165270327828, -7.988795117650464],
              [-39.588165322301016, -7.988795115126491],
              [-39.588165374258836, -7.988795112305442],
              [-39.588165426199566, -7.988795109187439],
              [-39.588165478121489, -7.988795105772556],
              [-39.588165530022934, -7.988795102060929],
              [-39.588165581902174, -7.98879509805264],
              [-39.588165633757491, -7.98879509374787],
              [-39.588165685587185, -7.988795089146747],
              [-39.588165737389573, -7.988795084249408],
              [-39.588165789162929, -7.988795079056013],
              [-39.588165840905553, -7.988795073566763],
              [-39.588165892615748, -7.988795067781798],
              [-39.588165944291823, -7.988795061701333],
              [-39.588165995932073, -7.988795055325546],
              [-39.588166047534784, -7.988795048654687],
              [-39.588166099098267, -7.988795041688932],
              [-39.588166150620829, -7.988795034428549],
              [-39.58816620210078, -7.988795026873761],
              [-39.588166253536436, -7.988795019024786],
              [-39.588166304926077, -7.988795010881922],
              [-39.58816635626804, -7.988795002445415],
              [-39.588166407560621, -7.988794993715572],
              [-39.588166458802142, -7.988794984692651],
              [-39.588166509990899, -7.988794975376957],
              [-39.588166561125234, -7.988794965768792],
              [-39.588166612203459, -7.988794955868484],
              [-39.588166663223873, -7.988794945676355],
              [-39.588166714184823, -7.988794935192725],
              [-39.588166765084644, -7.988794924417946],
              [-39.588166815921625, -7.988794913352385],
              [-39.58816686669411, -7.988794901996407],
              [-39.588166917400443, -7.98879489035037],
              [-39.588166968038934, -7.988794878414647],
              [-39.588167018607933, -7.988794866189643],
              [-39.588167069105765, -7.98879485367577],
              [-39.588167119530794, -7.988794840873427],
              [-39.588167169881331, -7.988794827783063],
              [-39.588167220155739, -7.988794814405077],
              [-39.588167270352358, -7.988794800739918],
              [-39.588167320469537, -7.988794786788032],
              [-39.588167370505637, -7.988794772549886],
              [-39.588167420459001, -7.98879475802595],
              [-39.588167470327988, -7.98879474321668],
              [-39.588167520110964, -7.988794728122585],
              [-39.588167569806288, -7.988794712744143],
              [-39.588167619412332, -7.988794697081889],
              [-39.588167668927454, -7.9887946811363],
              [-39.588167718350029, -7.988794664907915],
              [-39.588167767678428, -7.9887946483973],
              [-39.588167816911032, -7.988794631604954],
              [-39.588167866046255, -7.98879461453144],
              [-39.588167915082408, -7.988794597177352],
              [-39.588167964017948, -7.988794579543219],
              [-39.58816801285122, -7.988794561629653],
              [-39.588168061580653, -7.988794543437193],
              [-39.58816811020462, -7.988794524966493],
              [-39.588168158721523, -7.988794506218142],
              [-39.588168207129783, -7.988794487192748],
              [-39.588168255427789, -7.98879446789095],
              [-39.588168303613969, -7.988794448313367],
              [-39.58816835168674, -7.98879442846067],
              [-39.588168399644488, -7.98879440833346],
              [-39.588168447485678, -7.98879438793244],
              [-39.588168495208706, -7.988794367258285],
              [-39.588168542812028, -7.988794346311663],
              [-39.588168590294067, -7.988794325093263],
              [-39.588168637653261, -7.988794303603768],
              [-39.588168684888046, -7.98879428184391],
              [-39.588168731996873, -7.988794259814361],
              [-39.588168778978194, -7.988794237515913],
              [-39.588168825830465, -7.988794214949269],
              [-39.588168872552153, -7.988794192115167],
              [-39.588168919141694, -7.988794169014331],
              [-39.58816892089574, -7.988794199888592],
              [-39.589137853745918, -7.9883102629109],
              [-39.589137847615802, -7.988310277254365],
              [-39.58913785666978, -7.988310272739012],
              [-39.589137865728802, -7.988310268233727],
              [-39.589137874792875, -7.988310263738538],
              [-39.589137883861952, -7.988310259253389],
              [-39.589137892936066, -7.98831025477832],
              [-39.58913790201516, -7.988310250313404],
              [-39.589137911099264, -7.988310245858576],
              [-39.589137920188328, -7.988310241413864],
              [-39.589137929282387, -7.988310236979218],
              [-39.589137938381398, -7.988310232554691],
              [-39.589137947485355, -7.988310228140321],
              [-39.589137956594236, -7.988310223736081],
              [-39.589137965708055, -7.988310219341945],
              [-39.589137974826762, -7.988310214957947],
              [-39.589137983950394, -7.988310210584111],
              [-39.5891379930789, -7.988310206220435],
              [-39.589138002212302, -7.98831020186689],
              [-39.589138011350563, -7.988310197523515],
              [-39.589138020493678, -7.988310193190308],
              [-39.589138029641639, -7.988310188867288],
              [-39.589138038794424, -7.98831018455442],
              [-39.589138047952034, -7.98831018025174],
              [-39.589138057114461, -7.988310175959246],
              [-39.589138066281684, -7.988310171676968],
              [-39.589138075453697, -7.988310167404861],
              [-39.589138084630477, -7.988310163142994],
              [-39.589138093812046, -7.988310158891292],
              [-39.589138102998341, -7.98831015464983],
              [-39.589138112189382, -7.988310150418565],
              [-39.589138121385155, -7.988310146197542],
              [-39.589138130585653, -7.988310141986775],
              [-39.589138139790855, -7.988310137786223],
              [-39.589138149000746, -7.988310133595909],
              [-39.589138158215334, -7.988310129415833],
              [-39.589138167434591, -7.988310125245992],
              [-39.589138176658501, -7.988310121086423],
              [-39.589138185887066, -7.988310116937141],
              [-39.58913819512027, -7.988310112798113],
              [-39.5891382043581, -7.988310108669356],
              [-39.589138213600556, -7.988310104550886],
              [-39.589138222847602, -7.988310100442669],
              [-39.589138232099245, -7.98831009634474],
              [-39.589138241355464, -7.988310092257131],
              [-39.589138250616259, -7.988310088179796],
              [-39.589138259881608, -7.988310084112775],
              [-39.589138269151505, -7.988310080056083],
              [-39.589138278425942, -7.988310076009677],
              [-39.589138287704891, -7.98831007197357],
              [-39.589138296988359, -7.988310067947825],
              [-39.589138306276318, -7.98831006393238],
              [-39.589138315568775, -7.988310059927276],
              [-39.589138324865715, -7.98831005593251],
              [-39.589138334167117, -7.98831005194808],
              [-39.58913834347296, -7.988310047974037],
              [-39.589138352783252, -7.988310044010285],
              [-39.589138362097984, -7.988310040056906],
              [-39.589138371417114, -7.988310036113914],
              [-39.589138380740664, -7.988310032181277],
              [-39.589138390068619, -7.98831002825903],
              [-39.589138399400952, -7.98831002434712],
              [-39.589138408737647, -7.988310020445616],
              [-39.589138418078704, -7.988310016554482],
              [-39.589138427424132, -7.98831001267374],
              [-39.589138436773887, -7.988310008803437],
              [-39.589138446127961, -7.988310004943491],
              [-39.589138455486349, -7.988310001093979],
              [-39.58913846484905, -7.988309997254859],
              [-39.589138474216028, -7.988309993426181],
              [-39.589138483587313, -7.988309989607874],
              [-39.589138492962832, -7.988309985800023],
              [-39.589138502342628, -7.988309982002606],
              [-39.589138511726659, -7.988309978215601],
              [-39.589138521114926, -7.988309974439071],
              [-39.589138530507405, -7.988309970672941],
              [-39.589138539904106, -7.988309966917304],
              [-39.589138549304998, -7.988309963172107],
              [-39.589138558710097, -7.988309959437348],
              [-39.589138568119338, -7.988309955713065],
              [-39.589138577532758, -7.988309951999237],
              [-39.589138586950327, -7.988309948295902],
              [-39.589138596372045, -7.988309944603055],
              [-39.589138605797864, -7.988309940920663],
              [-39.589138615227824, -7.988309937248781],
              [-39.589138624661871, -7.988309933587355],
              [-39.589138634100017, -7.988309929936435],
              [-39.589138643542249, -7.988309926296021],
              [-39.589138652988538, -7.988309922666136],
              [-39.589138662438891, -7.988309919046737],
              [-39.589138671893295, -7.988309915437847],
              [-39.589138681351727, -7.988309911839465],
              [-39.589138690814181, -7.988309908251606],
              [-39.589138700280643, -7.98830990467427],
              [-39.589138709751111, -7.988309901107475],
              [-39.589138719225545, -7.98830989755124],
              [-39.589138728703979, -7.988309894005529],
              [-39.589138738186364, -7.988309890470358],
              [-39.589138747672685, -7.988309886945727],
              [-39.589138757162964, -7.988309883431675],
              [-39.58913876665715, -7.988309879928346],
              [-39.589138760604598, -7.988309899813823],
              [-39.590164010359473, -7.987932251117752],
              [-39.590163967239008, -7.987932210929699],
              [-39.590163974860445, -7.987932208125914],
              [-39.590163982484363, -7.987932205328947],
              [-39.590163990110824, -7.987932202538765],
              [-39.590163997739779, -7.987932199755388],
              [-39.590164005371221, -7.98793219697885],
              [-39.590164013005158, -7.987932194209096],
              [-39.590164020641581, -7.987932191446161],
              [-39.590164028280483, -7.987932188690061],
              [-39.590164035921866, -7.98793218594077],
              [-39.5901640435657, -7.987932183198292],
              [-39.590164051212007, -7.987932180462655],
              [-39.590164058860744, -7.987932177733835],
              [-39.59016406651196, -7.987932175011839],
              [-39.590164074165592, -7.987932172296693],
              [-39.590164081821676, -7.987932169588352],
              [-39.590164089480183, -7.987932166886863],
              [-39.59016409714112, -7.987932164192197],
              [-39.590164104804479, -7.987932161504362],
              [-39.590164112470219, -7.987932158823384],
              [-39.59016412013839, -7.987932156149227],
              [-39.590164127808961, -7.987932153481939],
              [-39.590164135481913, -7.98793215082147],
              [-39.59016414315726, -7.987932148167856],
              [-39.590164150834987, -7.98793214552113],
              [-39.59016415851508, -7.987932142881243],
              [-39.590164166197546, -7.98793214024822],
              [-39.590164173882364, -7.987932137622039],
              [-39.590164181569556, -7.987932135002713],
              [-39.590164189259085, -7.98793213239027],
              [-39.590164196950958, -7.987932129784663],
              [-39.590164204645177, -7.987932127185948],
              [-39.590164212341719, -7.987932124594085],
              [-39.590164220040585, -7.987932122009073],
              [-39.590164227741774, -7.987932119430968],
              [-39.590164235445272, -7.987932116859717],
              [-39.590164243151079, -7.987932114295355],
              [-39.590164250859175, -7.987932111737858],
              [-39.59016425856958, -7.987932109187236],
              [-39.590164266282265, -7.987932106643486],
              [-39.590164273997239, -7.987932104106655],
              [-39.590164281714472, -7.987932101576692],
              [-39.590164289433993, -7.98793209905362],
              [-39.590164297155766, -7.98793209653745],
              [-39.590164304879806, -7.987932094028151],
              [-39.590164312606085, -7.987932091525775],
              [-39.590164320334608, -7.987932089030271],
              [-39.590164328065377, -7.987932086541687],
              [-39.590164335798377, -7.987932084059989],
              [-39.590164343533601, -7.987932081585182],
              [-39.590164351271056, -7.987932079117291],
              [-39.5901643590107, -7.987932076656291],
              [-39.590164366752568, -7.987932074202227],
              [-39.590164374496624, -7.987932071755053],
              [-39.590164382242897, -7.987932069314782],
              [-39.59016438999133, -7.987932066881452],
              [-39.590164397741958, -7.987932064455595],
              [-39.590164373050236, -7.987932069697557],
              [-39.591026448499434, -7.987662650632941],
              [-39.591026396483137, -7.987662628805198],
              [-39.591026431664389, -7.98766261788668],
              [-39.591026466889822, -7.987662607110679],
              [-39.591026502158869, -7.987662596477339],
              [-39.591026537470952, -7.987662585986871],
              [-39.59102657282547, -7.987662575639394],
              [-39.591026608221846, -7.987662565435152],
              [-39.591026643659511, -7.987662555374277],
              [-39.591026679137855, -7.987662545456932],
              [-39.591026714656309, -7.987662535683282],
              [-39.591026750214283, -7.987662526053487],
              [-39.591026785811195, -7.987662516567701],
              [-39.591026821446462, -7.987662507226095],
              [-39.591026857119481, -7.987662498028836],
              [-39.591026892829667, -7.987662488976044],
              [-39.59102692857644, -7.987662480067892],
              [-39.591026964359216, -7.98766247130451],
              [-39.591027000177391, -7.987662462686036],
              [-39.591027036030376, -7.987662454212622],
              [-39.591027071917594, -7.987662445884405],
              [-39.591027107838428, -7.987662437701551],
              [-39.59102714379231, -7.987662429664151],
              [-39.591027179778635, -7.987662421772352],
              [-39.591027215796807, -7.987662414026291],
              [-39.591027251846235, -7.98766240642612],
              [-39.591027287926337, -7.987662398971929],
              [-39.591027324036503, -7.987662391663847],
              [-39.591027360176149, -7.987662384502009],
              [-39.591027396344657, -7.987662377486511],
              [-39.591027432541452, -7.987662370617495],
              [-39.59102746876593, -7.987662363895046],
              [-39.591027505017507, -7.987662357319305],
              [-39.591027541295553, -7.987662350890353],
              [-39.591027577599505, -7.98766234460833],
              [-39.591027613928738, -7.9876623384733],
              [-39.591027650282662, -7.987662332485383],
              [-39.591027686660688, -7.987662326644664],
              [-39.591027723062204, -7.987662320951246],
              [-39.591027759486607, -7.987662315405232],
              [-39.591027795933307, -7.98766231000672],
              [-39.591027832401693, -7.987662304755784],
              [-39.591027868891189, -7.987662299652501],
              [-39.591027905401148, -7.987662294696981],
              [-39.59102794193101, -7.987662289889307],
              [-39.591027978480149, -7.987662285229526],
              [-39.591028015047961, -7.987662280717726],
              [-39.59102805163387, -7.987662276354009],
              [-39.591028088237245, -7.98766227213843],
              [-39.591028124857495, -7.987662268071051],
              [-39.591028161494002, -7.987662264151943],
              [-39.591028198146169, -7.987662260381192],
              [-39.591028234813386, -7.987662256758798],
              [-39.591028271495091, -7.987662253284865],
              [-39.59102830819063, -7.987662249959454],
              [-39.591028344899399, -7.987662246782644],
              [-39.591028381620816, -7.987662243754428],
              [-39.591028418354277, -7.987662240874894],
              [-39.591028455099156, -7.987662238144075],
              [-39.591028491854857, -7.987662235562026],
              [-39.591028528620768, -7.987662233128757],
              [-39.591028565396293, -7.987662230844379],
              [-39.591028602180813, -7.987662228708874],
              [-39.591028638973739, -7.987662226722271],
              [-39.591028675774453, -7.987662224884645],
              [-39.591028712582357, -7.987662223196008],
              [-39.59102874939682, -7.987662221656363],
              [-39.591028786217265, -7.987662220265748],
              [-39.591028823043061, -7.987662219024227],
              [-39.591028859873624, -7.987662217931748],
              [-39.59102889670833, -7.987662216988388],
              [-39.591028933546554, -7.98766221619414],
              [-39.591028970387725, -7.98766221554903],
              [-39.591029007231221, -7.987662215053047],
              [-39.591029044076429, -7.987662214706203],
              [-39.591029080922759, -7.987662214508526],
              [-39.591029117769594, -7.987662214459982],
              [-39.591029154616308, -7.987662214560594],
              [-39.591029191462312, -7.987662214810362],
              [-39.591029228306986, -7.987662215209284],
              [-39.591029265149736, -7.987662215757361],
              [-39.59102930198997, -7.987662216454564],
              [-39.591029338827035, -7.98766221730091],
              [-39.591029375660355, -7.987662218296346],
              [-39.591029412489313, -7.987662219440853],
              [-39.591029449313325, -7.987662220734471],
              [-39.591029486131745, -7.987662222177121],
              [-39.591029522943984, -7.987662223768813],
              [-39.591029559749437, -7.987662225509498],
              [-39.591029596547507, -7.987662227399147],
              [-39.591029633337563, -7.98766222943776],
              [-39.591029670119013, -7.987662231625266],
              [-39.591029706891256, -7.987662233961672],
              [-39.591029743653664, -7.98766223644689],
              [-39.591029780405663, -7.987662239080905],
              [-39.59102981714662, -7.987662241863671],
              [-39.591029853875938, -7.987662244795116],
              [-39.591029890593013, -7.987662247875245],
              [-39.591029927297235, -7.987662251103957],
              [-39.591029963987999, -7.987662254481231],
              [-39.591030000664695, -7.987662258007314],
              [-39.591029986743827, -7.987662283772009],
              [-39.591567842646676, -7.987715059968335],
              [-39.591567865273944, -7.987715062025468],
              [-39.59156786527393, -7.987715062026012],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0272000',
        uf: 'PE',
        nome_proje: 'PA PAU FERRO',
        municipio: 'BARREIROS',
        area_hecta: '536.8000',
        capacidade: 52.0,
        num_famili: 47.0,
        fase: 4.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 526.9346,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.244986380963695, -8.841245400940727],
              [-35.24325205351515, -8.841713801383055],
              [-35.241579493531113, -8.842146593548868],
              [-35.240896400947776, -8.842312159207614],
              [-35.240230297828859, -8.842480558137714],
              [-35.239604353222305, -8.842516963976459],
              [-35.239436600464948, -8.842539252782544],
              [-35.239325184789102, -8.842542120868341],
              [-35.237456047195899, -8.842564300551727],
              [-35.237222857723047, -8.842565693418068],
              [-35.236358918510085, -8.84260241435579],
              [-35.235981130622271, -8.842661915195968],
              [-35.234796737647294, -8.842888208739412],
              [-35.234706721927665, -8.842904156612104],
              [-35.232635138469114, -8.843296583231581],
              [-35.231982582882502, -8.843430777174415],
              [-35.231902909058604, -8.843435288958958],
              [-35.231250592218487, -8.842463325219251],
              [-35.231190225444749, -8.842276491269571],
              [-35.231142322026734, -8.842198601677438],
              [-35.230823983081621, -8.841684889663162],
              [-35.230280306787158, -8.840808009170445],
              [-35.230233505813047, -8.84073395415048],
              [-35.229766304987514, -8.839996245674675],
              [-35.227874366298479, -8.839266621175286],
              [-35.226246551646881, -8.837398996195478],
              [-35.225907209880553, -8.837019300557493],
              [-35.224386672385691, -8.837644881846998],
              [-35.222407675569137, -8.836396821721914],
              [-35.221556065643384, -8.836054260720115],
              [-35.220509722010597, -8.835877074801626],
              [-35.21841805011492, -8.835822925736197],
              [-35.218383732414942, -8.836130715590597],
              [-35.218281752593121, -8.83669271243706],
              [-35.218192624748411, -8.837084976954852],
              [-35.218101614315096, -8.840271574097727],
              [-35.21809548808811, -8.840420188420307],
              [-35.218021978387746, -8.842599012127422],
              [-35.21799317117511, -8.843461410803013],
              [-35.217957834924597, -8.844644866363918],
              [-35.217914665477863, -8.845476148525471],
              [-35.217402138627769, -8.846253519939607],
              [-35.216431508282064, -8.847421490248708],
              [-35.215945016029885, -8.847874434313018],
              [-35.215873675814869, -8.84796230605493],
              [-35.215367211389349, -8.848572406403303],
              [-35.214017451137344, -8.850199072257645],
              [-35.215564824710853, -8.852301907960754],
              [-35.216856584661421, -8.854024182025906],
              [-35.217764932565181, -8.854614547369465],
              [-35.222813685083906, -8.857498663375143],
              [-35.224169586332721, -8.857662914332126],
              [-35.22534132744476, -8.857804177091108],
              [-35.229753183634976, -8.858336320322261],
              [-35.230624915461355, -8.85839193287395],
              [-35.230709523015548, -8.858358599899695],
              [-35.234203731011945, -8.85494765145422],
              [-35.235659282307203, -8.853529224885035],
              [-35.237494631617373, -8.85173885567052],
              [-35.238812354819451, -8.850457656763174],
              [-35.239975005537666, -8.850358470278071],
              [-35.241781261027405, -8.850191667756887],
              [-35.246129369109781, -8.849796798594186],
              [-35.24687490034929, -8.849355139477387],
              [-35.246917287370103, -8.849133902239364],
              [-35.246925838267309, -8.84902898599983],
              [-35.246865957913677, -8.848486491882516],
              [-35.246777767668398, -8.848295576309448],
              [-35.246577790411223, -8.847803444914975],
              [-35.246457922678879, -8.847013501298287],
              [-35.246394759861566, -8.846738612031007],
              [-35.246508240027794, -8.846538496097699],
              [-35.246821526718072, -8.846399619304988],
              [-35.247153282869043, -8.845975285368565],
              [-35.247057357553963, -8.84509037522613],
              [-35.246817069406582, -8.844801816052763],
              [-35.246569208801724, -8.84451126838866],
              [-35.246556773717636, -8.844210992811831],
              [-35.246872332517462, -8.843435600990318],
              [-35.2472880010667, -8.843281079196242],
              [-35.247827279094949, -8.842935210932229],
              [-35.247961803876876, -8.842931258178305],
              [-35.248046279537725, -8.842688401427715],
              [-35.248414333101209, -8.842121303207483],
              [-35.248579532198612, -8.841647850395287],
              [-35.248511120696378, -8.8415966872687],
              [-35.247338021717106, -8.840639698360082],
              [-35.246255155777753, -8.840916307435661],
              [-35.244986380963695, -8.841245400940727],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0373000',
        uf: 'PE',
        nome_proje: 'PA SANTA RITA II',
        municipio: 'CUSTODIA',
        area_hecta: '2557.1247',
        capacidade: 83.0,
        num_famili: 82.0,
        fase: 4.0,
        data_de_cr: '19/10/2007',
        forma_obte: 'Compra e Venda',
        data_obten: '25/09/2007',
        area_calc_: 2456.7327,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.728921344193076, -8.19200590460801],
              [-37.7298158579801, -8.19311806568507],
              [-37.730026596764432, -8.193639895760274],
              [-37.730535364727032, -8.195637358533626],
              [-37.730613794466819, -8.195692407914525],
              [-37.734654315872106, -8.196030031109824],
              [-37.736060179775627, -8.196269837468028],
              [-37.735497080800158, -8.197326143102295],
              [-37.735513564692631, -8.197423681988717],
              [-37.735561390975093, -8.197455031475597],
              [-37.740210241372772, -8.197887565415213],
              [-37.740214296543677, -8.197925741042154],
              [-37.761177237452308, -8.200355453948587],
              [-37.761464116164689, -8.200867741102703],
              [-37.760905427972922, -8.20148432288895],
              [-37.759214680839932, -8.203265015511459],
              [-37.756592300160875, -8.206081911938098],
              [-37.756235143451349, -8.206482607861931],
              [-37.755514016589942, -8.207314272766808],
              [-37.75388550371229, -8.209177603493549],
              [-37.753409046717643, -8.20970164047627],
              [-37.752793152892693, -8.2104037613638],
              [-37.752575176360125, -8.210647619389499],
              [-37.750378650276517, -8.212229549618149],
              [-37.748038047814838, -8.213842035806355],
              [-37.747658697709376, -8.213933640601994],
              [-37.746459996754247, -8.214126240424624],
              [-37.742666766162827, -8.21469372518491],
              [-37.742129967026209, -8.214801020105034],
              [-37.738118453045644, -8.215646493442117],
              [-37.737987635413823, -8.215766269164481],
              [-37.737336277792373, -8.216994029195112],
              [-37.737221822375048, -8.217335869827162],
              [-37.737220068306257, -8.217574789272737],
              [-37.736358857530682, -8.218509788299793],
              [-37.735159766678805, -8.219794965317849],
              [-37.734334802297511, -8.220660800611112],
              [-37.733493095501906, -8.221538156362984],
              [-37.733060700433001, -8.221980640402226],
              [-37.732494645087556, -8.222607547912165],
              [-37.732155679963682, -8.22352736013857],
              [-37.731971646215129, -8.223995675643096],
              [-37.731785240085657, -8.224467691262996],
              [-37.731436478342957, -8.225384125385007],
              [-37.731262982924633, -8.225849127508013],
              [-37.730601496531996, -8.227622692730385],
              [-37.730380453185951, -8.228223917009601],
              [-37.730146656635391, -8.229123982064126],
              [-37.730100403777413, -8.229452740957315],
              [-37.729973221277788, -8.230195879591776],
              [-37.729561277057591, -8.231508569165239],
              [-37.729190626773324, -8.231484878730708],
              [-37.726949712732079, -8.231070833670779],
              [-37.724794884421534, -8.230876078551734],
              [-37.724445044934079, -8.230930674371956],
              [-37.723892240558691, -8.230869413620438],
              [-37.724116525069988, -8.233399533227468],
              [-37.72481982460522, -8.233408459352249],
              [-37.725118757365884, -8.233377214457301],
              [-37.725824387188716, -8.236177448321207],
              [-37.733644447347395, -8.237132842650313],
              [-37.734632621356205, -8.237253522389734],
              [-37.736579110311816, -8.237465118725972],
              [-37.736054302308169, -8.239590456041958],
              [-37.735331865237207, -8.242515502418092],
              [-37.736102259010472, -8.242641914607038],
              [-37.742634092028823, -8.243306329555994],
              [-37.767051129708364, -8.24599785358323],
              [-37.767140435667173, -8.246004729894626],
              [-37.773734389714825, -8.24695443637423],
              [-37.773777756198278, -8.246904106595951],
              [-37.773059250931659, -8.245072419801762],
              [-37.772043545814242, -8.241826242417812],
              [-37.771856248695734, -8.24171506775521],
              [-37.771798834939439, -8.241375401209403],
              [-37.771548534570833, -8.241000417270104],
              [-37.770831431737435, -8.239925849938606],
              [-37.770307340797366, -8.238817417836506],
              [-37.770046409192851, -8.238534824690806],
              [-37.769532049647758, -8.238097535214198],
              [-37.769449752643986, -8.237174129057912],
              [-37.76967094450908, -8.237038070399322],
              [-37.769863667444405, -8.23683174727949],
              [-37.769969760260018, -8.23657569097632],
              [-37.770088087684464, -8.236120354747076],
              [-37.770415130409937, -8.23480607889651],
              [-37.77060198271905, -8.2337362695542],
              [-37.77070629677867, -8.233497209053976],
              [-37.770813852723073, -8.233207696028023],
              [-37.770859122729583, -8.23298120595954],
              [-37.770526185317941, -8.231558196142894],
              [-37.77137281658689, -8.231159438907623],
              [-37.771886452386468, -8.23106379271457],
              [-37.772543910051169, -8.23101669650744],
              [-37.773224288776262, -8.230925356303175],
              [-37.773960034837103, -8.230806510941099],
              [-37.774434763564798, -8.230669413986657],
              [-37.774525317317362, -8.230298908635525],
              [-37.774947005912331, -8.230111186658903],
              [-37.775568939928689, -8.229718788085819],
              [-37.775921233733449, -8.229573900274721],
              [-37.775999083316748, -8.228669068018371],
              [-37.776560925062441, -8.228097604662908],
              [-37.77666986317341, -8.227917790581802],
              [-37.776895891657809, -8.227178994674492],
              [-37.776663271690161, -8.225893843509422],
              [-37.777409525370004, -8.22545334621738],
              [-37.779479567244749, -8.224474532124221],
              [-37.779793100473469, -8.224090141558422],
              [-37.780407324887058, -8.223453536082465],
              [-37.780661629195137, -8.223290801403969],
              [-37.782111833690841, -8.222671920420067],
              [-37.784167630013322, -8.221355622581024],
              [-37.785061443946425, -8.221340417970934],
              [-37.785506404106037, -8.221047848539207],
              [-37.785710800798121, -8.220758621779801],
              [-37.786035309840578, -8.218911291723119],
              [-37.786337372456735, -8.21787726534275],
              [-37.78662436105666, -8.217680892345896],
              [-37.786961516580732, -8.217255598505016],
              [-37.787619141085358, -8.216548570188618],
              [-37.78838523366116, -8.215334164651715],
              [-37.788551765474189, -8.214867659356216],
              [-37.78855857827196, -8.214267462322574],
              [-37.789598091494419, -8.213525676079882],
              [-37.78985627673628, -8.213247188795011],
              [-37.790247345330648, -8.212701503138472],
              [-37.791324886222561, -8.210638929527528],
              [-37.790617743793121, -8.210317208279543],
              [-37.790464077597228, -8.210253442097407],
              [-37.790226147899055, -8.209958632223042],
              [-37.79008267127611, -8.20982734148909],
              [-37.790068627114032, -8.209189188958025],
              [-37.790380874741849, -8.207692068702217],
              [-37.791400378867728, -8.206205390319795],
              [-37.791706077374513, -8.205791390632374],
              [-37.792247153620345, -8.204352651248954],
              [-37.792543790129784, -8.203511859494565],
              [-37.792951412958274, -8.20225811059799],
              [-37.792851631592043, -8.202175967838683],
              [-37.786595642070886, -8.20151617798618],
              [-37.781788649703245, -8.201004647928439],
              [-37.773431181148887, -8.20009223012956],
              [-37.772959518260251, -8.200041055731171],
              [-37.773029334568307, -8.199947487521243],
              [-37.772625425335413, -8.199676310683147],
              [-37.77205020297788, -8.199356410130996],
              [-37.770844588997164, -8.19865250153253],
              [-37.769836863675089, -8.198152761798973],
              [-37.76939345586954, -8.197858039232328],
              [-37.768858236578225, -8.197430368563932],
              [-37.768237463973044, -8.196842095702751],
              [-37.768046407846001, -8.196602312922174],
              [-37.767393371806541, -8.195910121789272],
              [-37.765454686864167, -8.195721967082129],
              [-37.761300341739229, -8.195303632406221],
              [-37.728921344193076, -8.19200590460801],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0247000',
        uf: 'PE',
        nome_proje: 'PA CARICÉ',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '204.0239',
        capacidade: 21.0,
        num_famili: 21.0,
        fase: 4.0,
        data_de_cr: '14/12/2001',
        forma_obte: 'Desapropriação',
        data_obten: '30/12/2000',
        area_calc_: 191.321,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.250010829555691, -8.071408294601538],
              [-35.248982054319171, -8.071582188554217],
              [-35.248037914634921, -8.07174173162808],
              [-35.247843740532403, -8.071774524441217],
              [-35.247482240157211, -8.071725802396205],
              [-35.246558584265038, -8.071601113212894],
              [-35.246076471314389, -8.071536040932756],
              [-35.245403342792869, -8.071445161876225],
              [-35.245146162817711, -8.071410480952432],
              [-35.244419312948146, -8.071312356512621],
              [-35.244113681075952, -8.071346030004982],
              [-35.243797042985364, -8.071571024974155],
              [-35.243804940180816, -8.072393703719749],
              [-35.243711409365005, -8.076004156790781],
              [-35.243547932655609, -8.077063318078578],
              [-35.24353893421997, -8.078685211080222],
              [-35.243538351921153, -8.078793228112238],
              [-35.243537211625508, -8.078997240418126],
              [-35.239474383719461, -8.079830759066372],
              [-35.239210305434398, -8.079884992406253],
              [-35.238765583918578, -8.079976189475962],
              [-35.239161477446729, -8.080602491832417],
              [-35.239671027986759, -8.080869600048977],
              [-35.2398485220087, -8.081116653165898],
              [-35.239878065236645, -8.081579234470228],
              [-35.240018757516836, -8.082037057760285],
              [-35.240416843212586, -8.082499381359858],
              [-35.240280887760484, -8.082895033246743],
              [-35.240908455980644, -8.083147391349833],
              [-35.241220829829082, -8.083643093975917],
              [-35.241162279248407, -8.084684868747754],
              [-35.241573621891739, -8.084855253766547],
              [-35.241836244761458, -8.084963996083603],
              [-35.242330774312876, -8.085168786440246],
              [-35.244528404302805, -8.086078894159398],
              [-35.245591073084739, -8.086887558341315],
              [-35.246975105740084, -8.087940730669473],
              [-35.247434244618347, -8.088290174795389],
              [-35.247912874936596, -8.088654334505145],
              [-35.249149016015707, -8.085990516322275],
              [-35.24965467458469, -8.084900963855116],
              [-35.250105514796438, -8.084403617390052],
              [-35.250963942051605, -8.083456850627366],
              [-35.25281994431122, -8.082433820880109],
              [-35.253712745942629, -8.081941700694166],
              [-35.253805813580279, -8.081890398743667],
              [-35.254104949155455, -8.081059895669069],
              [-35.254030744278566, -8.080730209677309],
              [-35.253965373041446, -8.080439694072171],
              [-35.253646758343095, -8.080195506239086],
              [-35.253391768282832, -8.079713067310953],
              [-35.253314957088818, -8.078733680484008],
              [-35.253519693918015, -8.078005293872467],
              [-35.254070982302004, -8.077277076216197],
              [-35.254463800270031, -8.076758165493461],
              [-35.255159850747951, -8.075838784240112],
              [-35.253571228279391, -8.073511103162446],
              [-35.253035765149384, -8.073527248859335],
              [-35.251923395144424, -8.073231261365139],
              [-35.251703965637113, -8.073172849336991],
              [-35.25141624900548, -8.072873025851438],
              [-35.250751129682861, -8.072179858957838],
              [-35.250010829555691, -8.071408294601538],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0268000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOAQUIM',
        municipio: 'AGUA PRETA',
        area_hecta: '226.6723',
        capacidade: 20.0,
        num_famili: 0.0,
        fase: 4.0,
        data_de_cr: '04/11/2003',
        forma_obte: 'Desapropriação',
        data_obten: '06/08/2001',
        area_calc_: 229.4209,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.463754890500354, -8.660647733600024],
              [-35.458432268851169, -8.665811015959967],
              [-35.456162154360712, -8.667777233748499],
              [-35.454608566308941, -8.669118484951795],
              [-35.452173148962437, -8.671236569398792],
              [-35.452066266262456, -8.671448458098803],
              [-35.451933543862452, -8.671821105898792],
              [-35.451724644662477, -8.672127095898794],
              [-35.451587812962494, -8.67239837149882],
              [-35.451336030662503, -8.672645984598827],
              [-35.451138264062521, -8.672890178098751],
              [-35.450949802862546, -8.673210857898736],
              [-35.450803320662544, -8.673604651198744],
              [-35.450658287562561, -8.67392785359873],
              [-35.450461643062582, -8.674387218498769],
              [-35.449114639662696, -8.677911028198794],
              [-35.448949269862709, -8.678207701298822],
              [-35.448929718562709, -8.678455991998812],
              [-35.448943743562715, -8.678714008198817],
              [-35.448884685762714, -8.678934446498751],
              [-35.448653945162746, -8.679682592798748],
              [-35.448611672062746, -8.679858369298799],
              [-35.448585040362737, -8.680107247798828],
              [-35.448601979862737, -8.680618561798777],
              [-35.448597979462747, -8.680743935398741],
              [-35.448574390762737, -8.680817831498725],
              [-35.448491869762748, -8.681005524698763],
              [-35.44850211816275, -8.681255341198797],
              [-35.448501403162751, -8.681411149398787],
              [-35.448470706862757, -8.681607456798766],
              [-35.448445134062766, -8.681865546598805],
              [-35.44844056066276, -8.682210621398811],
              [-35.44844488436275, -8.682247104498787],
              [-35.448455834462763, -8.682339034198799],
              [-35.448471718862763, -8.682477564898798],
              [-35.448689743962746, -8.683367245698822],
              [-35.45032018436261, -8.682399715698743],
              [-35.450509488262597, -8.682292310398765],
              [-35.454036415962314, -8.680380465798738],
              [-35.454228749062288, -8.680278371598769],
              [-35.460749895561761, -8.676885870898786],
              [-35.460933416961737, -8.676770184898727],
              [-35.462765454661586, -8.675391749098742],
              [-35.466769410547528, -8.671937251108446],
              [-35.464706918958498, -8.664346555574717],
              [-35.463754890500354, -8.660647733600024],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0019000',
        uf: 'PE',
        nome_proje: 'PA LULAÇO',
        municipio: 'CARNAUBEIRA DA PENHA',
        area_hecta: '412.0000',
        capacidade: 13.0,
        num_famili: 11.0,
        fase: 3.0,
        data_de_cr: '12/08/1996',
        forma_obte: 'Adjudicação',
        data_obten: '12/08/1996',
        area_calc_: 420.4612,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.858926317475294, -8.408101565309916],
              [-38.863720624305238, -8.407337653577368],
              [-38.865975733083097, -8.407128394186211],
              [-38.865668616956675, -8.405794999779243],
              [-38.867811502156499, -8.40567478843718],
              [-38.867872579620304, -8.407181227440331],
              [-38.869514782858673, -8.406771205277977],
              [-38.874768289279366, -8.405840965620344],
              [-38.875631293881504, -8.407670397595483],
              [-38.879479679157633, -8.406605595871936],
              [-38.881085238196761, -8.406568907683099],
              [-38.882743334790547, -8.406419523031602],
              [-38.882446141452412, -8.402110209967187],
              [-38.890873986760752, -8.398957873037693],
              [-38.892045027141165, -8.398009966743309],
              [-38.892021245492934, -8.395318140337315],
              [-38.889477763543155, -8.395715613643409],
              [-38.876767453009784, -8.399438003022281],
              [-38.872689166073371, -8.401244879499146],
              [-38.869151580698251, -8.396031495251442],
              [-38.866551789067266, -8.396862075639518],
              [-38.866517785976676, -8.396952337107892],
              [-38.866407680202975, -8.397258486218357],
              [-38.866284369704495, -8.397662411464102],
              [-38.866134587476161, -8.398178490484822],
              [-38.865979361270767, -8.398675481807695],
              [-38.865885174207698, -8.399177921069549],
              [-38.865825324438902, -8.399675397026829],
              [-38.865679742595155, -8.400123546370864],
              [-38.865564516925922, -8.40052123274689],
              [-38.865428666263306, -8.400933475104083],
              [-38.865245584367351, -8.401334846779005],
              [-38.865107820236005, -8.401762375047579],
              [-38.865001403329934, -8.402163682381216],
              [-38.864973807574096, -8.402635751630706],
              [-38.864946296008206, -8.403126816205297],
              [-38.864981466370594, -8.403603881917094],
              [-38.86484080787011, -8.403992332968167],
              [-38.8646868128411, -8.404332477015862],
              [-38.864500683915139, -8.404345890622485],
              [-38.864034948485198, -8.40439177106118],
              [-38.863554848560575, -8.404472561154346],
              [-38.863109262258384, -8.404565121661134],
              [-38.862808574427895, -8.40461748022101],
              [-38.862701929295298, -8.404625583888137],
              [-38.862410219950434, -8.404718378141114],
              [-38.862056070444446, -8.404910739979858],
              [-38.861677522676892, -8.404998166467347],
              [-38.861286539877007, -8.405058090239564],
              [-38.860890664896225, -8.405081197181492],
              [-38.860583560712818, -8.405039117958649],
              [-38.860305251141732, -8.4049990387007],
              [-38.860095231937755, -8.405011808561291],
              [-38.859667933145076, -8.405776986323483],
              [-38.859361137833169, -8.406389158772443],
              [-38.859185814574857, -8.406913909164041],
              [-38.859010522436598, -8.407351190624954],
              [-38.858926317475294, -8.408101565309916],
            ],
          ],
          [
            [
              [-38.847584008066256, -8.421293903221192],
              [-38.850375910674011, -8.42032223695751],
              [-38.848694069565703, -8.417809783510528],
              [-38.846409559742874, -8.418455460692734],
              [-38.847584008066256, -8.421293903221192],
            ],
          ],
          [
            [
              [-38.83813702466297, -8.428785114813818],
              [-38.839468996408257, -8.420968385113891],
              [-38.841558148675858, -8.418723720176894],
              [-38.84589806192929, -8.416333487554315],
              [-38.850400006063587, -8.413162380185575],
              [-38.852222167994888, -8.413803391277838],
              [-38.851849490628069, -8.41251781261524],
              [-38.849029439992151, -8.413143584801137],
              [-38.848061476959934, -8.411710243695746],
              [-38.844554404125439, -8.412364753477343],
              [-38.835491747772686, -8.414041516195732],
              [-38.835308656031785, -8.41480884866329],
              [-38.830403963525448, -8.413404731949374],
              [-38.831309100275654, -8.420912774965473],
              [-38.8344406701124, -8.426612686394698],
              [-38.83813702466297, -8.428785114813818],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0170000',
        uf: 'PE',
        nome_proje: 'PA MORRO DO MEL',
        municipio: 'LAGOA GRANDE',
        area_hecta: '914.4488',
        capacidade: 22.0,
        num_famili: 21.0,
        fase: 3.0,
        data_de_cr: '03/04/2006',
        forma_obte: 'Desapropriação',
        data_obten: '03/04/2006',
        area_calc_: 911.8846,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.394435263602432, -8.738117763795204],
              [-40.394040501087453, -8.738306037070373],
              [-40.393904642770579, -8.739168120122281],
              [-40.39383419192631, -8.740045696799823],
              [-40.393758093834329, -8.740920038897716],
              [-40.393181540192593, -8.741718183645892],
              [-40.392917479501854, -8.742103557595694],
              [-40.39316123714103, -8.742491766393142],
              [-40.393780032571954, -8.741516139834445],
              [-40.394542240549285, -8.740855663587254],
              [-40.395032472857693, -8.740480669109022],
              [-40.395491688087468, -8.739928643051107],
              [-40.396172766835797, -8.738986423292628],
              [-40.396324872701612, -8.738852483980091],
              [-40.396079350201944, -8.738722722399396],
              [-40.395587972478324, -8.738569089223832],
              [-40.394435263602432, -8.738117763795204],
            ],
          ],
          [
            [
              [-40.373502058785107, -8.716871183555297],
              [-40.357233306656632, -8.718608085609956],
              [-40.359071132575799, -8.723210981496406],
              [-40.365189089619506, -8.725300062158665],
              [-40.366069834403589, -8.732157900880841],
              [-40.366576925021754, -8.736106941208066],
              [-40.367048585304346, -8.736090139258817],
              [-40.370839889082262, -8.738302316848982],
              [-40.371001473643965, -8.738396500179366],
              [-40.370796936779229, -8.738909962877079],
              [-40.370746588358436, -8.739035748524767],
              [-40.370466032289023, -8.739739021763228],
              [-40.370569369836922, -8.739836942510301],
              [-40.372140069464969, -8.741354776559966],
              [-40.373337230681159, -8.742429868500974],
              [-40.376795549933931, -8.741409776975164],
              [-40.378170167853177, -8.743901107603405],
              [-40.379657706362003, -8.746543845609803],
              [-40.381313270072994, -8.749466374908492],
              [-40.379688722801092, -8.753238400408792],
              [-40.378810793135223, -8.755291850861507],
              [-40.377843359810797, -8.757528017803539],
              [-40.376364298429607, -8.758340102922137],
              [-40.371955267141054, -8.760904995259194],
              [-40.372210529889031, -8.761382971052591],
              [-40.373102820646835, -8.763056340346546],
              [-40.37368273390404, -8.76414315619304],
              [-40.374172577284568, -8.7649593786445],
              [-40.375509322191064, -8.767186344390234],
              [-40.377077477188521, -8.76979885344093],
              [-40.381260792020989, -8.772897061885741],
              [-40.385181930686763, -8.771656510988823],
              [-40.381996074437666, -8.765029774428568],
              [-40.382522300770511, -8.762921448354133],
              [-40.385036294889389, -8.757637112718916],
              [-40.3854826557615, -8.756697300822237],
              [-40.388311607694, -8.750749245454598],
              [-40.389692328738676, -8.747847543823992],
              [-40.391714947019544, -8.746327097796726],
              [-40.391646640626796, -8.745702592045513],
              [-40.39143441019381, -8.743915639444202],
              [-40.392436107237536, -8.742736232915302],
              [-40.392826004881023, -8.742044302863269],
              [-40.393090065643776, -8.741658928967903],
              [-40.393651969949197, -8.740880912900225],
              [-40.393725453516161, -8.740036692414931],
              [-40.393787653205536, -8.7392622322382],
              [-40.393798917565078, -8.739138306548782],
              [-40.393941792039271, -8.738231979151218],
              [-40.394321593058173, -8.738077670944001],
              [-40.393903696140917, -8.737445680263253],
              [-40.393247586077443, -8.737745686146958],
              [-40.392700900763408, -8.737921766358085],
              [-40.391919440471099, -8.736528451208249],
              [-40.385477569367708, -8.731243183668862],
              [-40.381836893254068, -8.727132091524252],
              [-40.379820271160803, -8.725101837254954],
              [-40.379244974882532, -8.724562722249793],
              [-40.378833861871371, -8.724232529325686],
              [-40.378651545563386, -8.724084800677058],
              [-40.378156377146425, -8.72368410768415],
              [-40.377504095067643, -8.723192110031501],
              [-40.376870506700392, -8.722666946850701],
              [-40.376461333606784, -8.722319381640322],
              [-40.376106061805594, -8.721997934829215],
              [-40.375654154678422, -8.721496254572292],
              [-40.374602616514039, -8.72020930418047],
              [-40.373502058785107, -8.716871183555297],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0283000',
        uf: 'PE',
        nome_proje: 'PA TERRA NOVA I',
        municipio: 'FLORESTA',
        area_hecta: '620.2331',
        capacidade: 13.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '15/09/2009',
        forma_obte: 'Doação',
        data_obten: '11/09/2009',
        area_calc_: 547.2016,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.561953132463472, -8.755689426921855],
              [-38.56257990401479, -8.753212276398676],
              [-38.562985444298597, -8.750286237054084],
              [-38.563621519399334, -8.742925247469115],
              [-38.563716348514731, -8.742295734272886],
              [-38.564023305485023, -8.741644938030801],
              [-38.565571847478111, -8.729316475441811],
              [-38.565865465312726, -8.726979219443741],
              [-38.565990822619078, -8.725491458710001],
              [-38.559546980887539, -8.72200976075333],
              [-38.55925166697827, -8.722508521444954],
              [-38.558952864972028, -8.729308839956161],
              [-38.558823492658789, -8.732254193567465],
              [-38.558477398779971, -8.740129540498343],
              [-38.558291776469503, -8.744354783227772],
              [-38.558007307172801, -8.750827770099868],
              [-38.557840246446148, -8.754949737655794],
              [-38.558098554190764, -8.754940632624724],
              [-38.558455857877071, -8.754942858733658],
              [-38.558820090416035, -8.755006055801468],
              [-38.559183006277998, -8.755106606791417],
              [-38.559555628193507, -8.75522616321634],
              [-38.559948883457331, -8.755350175327099],
              [-38.560290698080784, -8.755458388982168],
              [-38.560578921679983, -8.755523757300951],
              [-38.560867451448324, -8.755560724491984],
              [-38.561164268363051, -8.755586123490096],
              [-38.561462359978265, -8.755609986086256],
              [-38.561750356706092, -8.755636459803924],
              [-38.561953132463472, -8.755689426921855],
            ],
          ],
          [
            [
              [-38.55195506135744, -8.774911948682353],
              [-38.552232844341276, -8.774899434370013],
              [-38.552420252062817, -8.774809659124861],
              [-38.552614269179578, -8.774591272124706],
              [-38.552711201387282, -8.774507811434948],
              [-38.552827667860242, -8.774360064106361],
              [-38.55290527975545, -8.774237958449792],
              [-38.552929671608268, -8.773989341058853],
              [-38.552929935865521, -8.773768463159486],
              [-38.552887870970963, -8.773589684708794],
              [-38.552814190498886, -8.77338979403002],
              [-38.552677087208707, -8.77323188733414],
              [-38.552571567983918, -8.773121504118018],
              [-38.552487202306352, -8.773037285911689],
              [-38.552339297614708, -8.77301612627344],
              [-38.552191458720038, -8.773015950989672],
              [-38.55200125291897, -8.773089351453851],
              [-38.551853234898687, -8.773162711437303],
              [-38.551715829293137, -8.77325724918949],
              [-38.55156773539575, -8.773393742858163],
              [-38.551409122174924, -8.773582865692427],
              [-38.551261116449055, -8.77364573327608],
              [-38.551155556042588, -8.773645607815149],
              [-38.550975985156612, -8.773645394323347],
              [-38.550764821471624, -8.77352954845257],
              [-38.550596015020616, -8.773424154596364],
              [-38.550490528157226, -8.773287359555635],
              [-38.550407109090848, -8.773096863645254],
              [-38.550501581082244, -8.772866691188609],
              [-38.550607497667706, -8.772646029537041],
              [-38.550639366042063, -8.772456756241519],
              [-38.550629071974399, -8.77224644844409],
              [-38.550724466722613, -8.772004699440268],
              [-38.550840917196524, -8.771794542390898],
              [-38.550946717118805, -8.771594864799955],
              [-38.551126552268499, -8.771374200383519],
              [-38.551338106057798, -8.771164156163172],
              [-38.551538971356209, -8.770996158237994],
              [-38.551739899492574, -8.770775608983431],
              [-38.551919695937173, -8.770586511044597],
              [-38.552099530020648, -8.770365846099949],
              [-38.552184325846433, -8.770166324088699],
              [-38.552321981882187, -8.76986158121397],
              [-38.552480643331094, -8.769630398790506],
              [-38.552512551010892, -8.76948318442088],
              [-38.552470423793395, -8.769356957138671],
              [-38.552396731707653, -8.769167558541124],
              [-38.552365151380293, -8.76904134374425],
              [-38.552365352796677, -8.768873107475603],
              [-38.552312782359593, -8.768736375634766],
              [-38.552143861369586, -8.768652057180807],
              [-38.551985386475351, -8.768651869292887],
              [-38.551784636092698, -8.768725257267432],
              [-38.551573260594793, -8.768788049902215],
              [-38.55143593232912, -8.768819453833647],
              [-38.551235156575601, -8.768913825811296],
              [-38.5510344311203, -8.768966229066827],
              [-38.550833539065529, -8.769081585017382],
              [-38.550696122164922, -8.769186614633234],
              [-38.550526885389573, -8.76936514195368],
              [-38.550357726645629, -8.769554251897427],
              [-38.550209684415947, -8.769648686056007],
              [-38.550082940564479, -8.769648535128249],
              [-38.549977366268408, -8.769585365933466],
              [-38.549935305571658, -8.769480122821182],
              [-38.549861447842034, -8.769353857487861],
              [-38.549745493794973, -8.769227541973088],
              [-38.549735084036392, -8.769038218337938],
              [-38.549767056003205, -8.768838453044426],
              [-38.549904510687355, -8.768702128217528],
              [-38.549946991441949, -8.768533851916922],
              [-38.549957740732147, -8.768365628262515],
              [-38.550031823700941, -8.768229046985784],
              [-38.550232725875702, -8.768029482774294],
              [-38.550475931115308, -8.767808984438918],
              [-38.550645179888136, -8.767619874490205],
              [-38.550772150478316, -8.767430714194791],
              [-38.550761793202028, -8.76727295756308],
              [-38.550719729930037, -8.767094178898358],
              [-38.550603940578618, -8.766831103865307],
              [-38.550498392288382, -8.766746860086915],
              [-38.550371750545736, -8.766662591184204],
              [-38.550139510135317, -8.766536137429199],
              [-38.549907181282073, -8.766483309503377],
              [-38.549706520378791, -8.76648307045771],
              [-38.549410807618045, -8.766440658867385],
              [-38.549252333720588, -8.76644046988034],
              [-38.549125667083096, -8.766377184880334],
              [-38.54909417898164, -8.766250969967356],
              [-38.549168261798926, -8.766114388839515],
              [-38.549178960618299, -8.765988224255342],
              [-38.549041772716748, -8.765903942350375],
              [-38.548883273745389, -8.765924737522175],
              [-38.548809285685898, -8.765982717910184],
              [-38.54869298833021, -8.766066697274915],
              [-38.548555698988565, -8.766066533301247],
              [-38.548546708816914, -8.766057477591584],
              [-38.548259915497802, -8.772103244129186],
              [-38.549236821157606, -8.773047620639764],
              [-38.54969288247878, -8.77403108143843],
              [-38.549921676553829, -8.774002953025686],
              [-38.550215024376428, -8.773975624971785],
              [-38.550479152899534, -8.773975939508354],
              [-38.550732605709491, -8.774007717650774],
              [-38.550912126216325, -8.774049990314584],
              [-38.551091532999088, -8.774186963590692],
              [-38.551281474391786, -8.774334351021789],
              [-38.551439696583728, -8.774471298966597],
              [-38.551608506427044, -8.774650228084138],
              [-38.551742093825929, -8.774783076418506],
              [-38.55195506135744, -8.774911948682353],
            ],
          ],
          [
            [
              [-38.556968054826626, -8.775998558087862],
              [-38.557231047371296, -8.775808017645039],
              [-38.557463812836893, -8.775728423432209],
              [-38.557598799657086, -8.77552624551871],
              [-38.557831394632871, -8.775513674144364],
              [-38.557954181688707, -8.775401750670525],
              [-38.558219178435792, -8.775207141595468],
              [-38.558288401604386, -8.775103928947685],
              [-38.558454812617292, -8.774853849069379],
              [-38.558598629475981, -8.77464200294764],
              [-38.558709981475914, -8.774434731757699],
              [-38.558788852333805, -8.774245874329523],
              [-38.559057584920033, -8.774043399827161],
              [-38.559238409533172, -8.773906036814697],
              [-38.559359721262737, -8.77365653696876],
              [-38.559489609428532, -8.773458784361942],
              [-38.559628297368896, -8.773123286918162],
              [-38.559739355029322, -8.772779074013584],
              [-38.559886476981632, -8.772693227737273],
              [-38.560059319411415, -8.7726934291312],
              [-38.560258165494318, -8.772693660726434],
              [-38.560405085098196, -8.772702424507907],
              [-38.560508948401626, -8.772599251708666],
              [-38.560595587122755, -8.772453004724916],
              [-38.560613024106374, -8.772315360393284],
              [-38.560595880511698, -8.772203454024984],
              [-38.560518193172499, -8.772082884475298],
              [-38.560380093609133, -8.771919190483409],
              [-38.560319640968352, -8.771833102337407],
              [-38.560319772583775, -8.771721216069437],
              [-38.560432515884422, -8.771488981721832],
              [-38.560510435992306, -8.771334131858495],
              [-38.560597043986142, -8.771213753463693],
              [-38.560804749268769, -8.77102468355829],
              [-38.561012484827152, -8.770809654461344],
              [-38.561133723465929, -8.770620483851355],
              [-38.561143324200614, -8.770419605932142],
              [-38.561160784176245, -8.770184637553534],
              [-38.561172694492313, -8.769875132060353],
              [-38.561207587538441, -8.769582929175042],
              [-38.561409364535116, -8.769330989338638],
              [-38.56154181236964, -8.769119128652996],
              [-38.561654574166923, -8.768792192868901],
              [-38.561732614235815, -8.768534049094566],
              [-38.561750222143957, -8.768327572517236],
              [-38.561845436079011, -8.768155556882279],
              [-38.56194089204827, -8.767854469558998],
              [-38.562114186073011, -8.767544789224694],
              [-38.562270113977455, -8.767235088675514],
              [-38.562348264281276, -8.76695975937098],
              [-38.562400380953839, -8.766710268555864],
              [-38.562443910823561, -8.766495138733445],
              [-38.56243552513623, -8.766279948794578],
              [-38.562383780764463, -8.766133631446465],
              [-38.562340674175701, -8.766064749180469],
              [-38.562245692475479, -8.765961345427451],
              [-38.562133425928174, -8.765866514363948],
              [-38.562038454396792, -8.765754517837616],
              [-38.561969556631801, -8.765582311900737],
              [-38.561866018369372, -8.765410065797672],
              [-38.561866098994344, -8.765341233545014],
              [-38.561952483135272, -8.765332740937344],
              [-38.561961191087114, -8.765272511419779],
              [-38.562039159725913, -8.765152122553449],
              [-38.562108369993076, -8.765135017269699],
              [-38.562186046471474, -8.765264179216691],
              [-38.562255045058109, -8.765350276961488],
              [-38.562393243827472, -8.765427952536925],
              [-38.562583458852544, -8.765419489541737],
              [-38.56270450365615, -8.765393851399148],
              [-38.562790968676197, -8.765393951431173],
              [-38.562955361813067, -8.765308123731936],
              [-38.563111056764463, -8.76519641723754],
              [-38.563387897337677, -8.765050389232329],
              [-38.563586849338925, -8.764878492871496],
              [-38.563803186908409, -8.764689430980457],
              [-38.564019614600539, -8.764422853499214],
              [-38.564118438464803, -8.764272097047696],
              [-38.561528247541546, -8.762619295721695],
              [-38.562133251772615, -8.759877737258723],
              [-38.562250174949071, -8.755910920749681],
              [-38.562250265866865, -8.755910920854848],
              [-38.562155934442067, -8.755875536235084],
              [-38.561958176486563, -8.755807650794466],
              [-38.561743204030797, -8.75576633758687],
              [-38.561463025282919, -8.755740596520068],
              [-38.561165842741467, -8.755716734973957],
              [-38.560866480133207, -8.755691242567824],
              [-38.560569133051338, -8.755652727482749],
              [-38.560270002313068, -8.755584723429283],
              [-38.559922917015996, -8.755474242373429],
              [-38.559529481181663, -8.755349054171919],
              [-38.55915967696199, -8.75523004369869],
              [-38.558806213965177, -8.755131584050977],
              [-38.558459615792593, -8.75507139238848],
              [-38.558116403561868, -8.755069725433124],
              [-38.557758263997655, -8.75508233179878],
              [-38.557364223651824, -8.75508503679376],
              [-38.556971146283949, -8.755119128605385],
              [-38.556602878430461, -8.75516247496348],
              [-38.556238567873713, -8.755165394518727],
              [-38.555892789531129, -8.755104296986447],
              [-38.555556044353978, -8.754939463788274],
              [-38.555238865530704, -8.754758824580723],
              [-38.55491911188313, -8.754602965346336],
              [-38.554606297380452, -8.754422602116861],
              [-38.554301671125877, -8.754237725794642],
              [-38.554021838230952, -8.754075852715932],
              [-38.553732292100506, -8.753901666810471],
              [-38.553464409331134, -8.753706702798468],
              [-38.553215852302131, -8.753468978660305],
              [-38.552972311462007, -8.753218325972544],
              [-38.552720695537822, -8.752954096125167],
              [-38.552475916023837, -8.752675402237662],
              [-38.552287839023144, -8.752421829880463],
              [-38.552138765023322, -8.752168755840167],
              [-38.552005345764648, -8.751901590105639],
              [-38.551872131350365, -8.751615339662058],
              [-38.54966092901374, -8.751326351385481],
              [-38.549565187169534, -8.75133184535769],
              [-38.549194177728587, -8.751314037340311],
              [-38.549149372653204, -8.752206632844461],
              [-38.549116389296927, -8.753172416240325],
              [-38.549106727917412, -8.754174316822063],
              [-38.549127700602952, -8.754425159013026],
              [-38.549052300762355, -8.755204565224224],
              [-38.54897266595664, -8.756706750314942],
              [-38.548959429396945, -8.757354897452277],
              [-38.548857650682116, -8.759501600746898],
              [-38.548797760555843, -8.760762760400894],
              [-38.548910537152963, -8.760882831295618],
              [-38.549100382430986, -8.761103845520319],
              [-38.549353560426447, -8.761356502101926],
              [-38.54951182964885, -8.761524927207377],
              [-38.549596128797162, -8.761661787636362],
              [-38.549690987352534, -8.761788078005509],
              [-38.549786088697701, -8.761788191257416],
              [-38.549870552512957, -8.761788291821459],
              [-38.549986734467325, -8.761798922288794],
              [-38.550166299640303, -8.761799135964512],
              [-38.550356348285248, -8.761851913324259],
              [-38.550588686973946, -8.761894248689416],
              [-38.550884472012328, -8.761873615807366],
              [-38.551095714960788, -8.761842299705981],
              [-38.551296539927073, -8.761779494606211],
              [-38.55159345393551, -8.761727114636946],
              [-38.551783656645945, -8.761727340158158],
              [-38.551921045179611, -8.761643384717733],
              [-38.552026680568034, -8.761654002060439],
              [-38.552132124368846, -8.761748737430084],
              [-38.552132036249439, -8.761822363435627],
              [-38.552089698752788, -8.76194849070618],
              [-38.552036660663205, -8.762127247054234],
              [-38.551994181819197, -8.762295433280883],
              [-38.55196232792354, -8.762474124263107],
              [-38.55196203831327, -8.762715986579797],
              [-38.551982917587885, -8.762894740048614],
              [-38.552035489848855, -8.763105188487152],
              [-38.552130286692808, -8.763284029530199],
              [-38.552225262553407, -8.763389335109604],
              [-38.552341419998172, -8.763420949195629],
              [-38.552446990086509, -8.763410582022818],
              [-38.552542179935053, -8.763337068598693],
              [-38.55267966949237, -8.76316899474735],
              [-38.552817149306662, -8.763085039111735],
              [-38.55300731206745, -8.763043204860441],
              [-38.553155197266619, -8.763001320542608],
              [-38.553313795377321, -8.762896314880319],
              [-38.553408956735339, -8.762770249867163],
              [-38.553567692598307, -8.762549649433213],
              [-38.553747496510574, -8.762350058138205],
              [-38.553895456632269, -8.762245039650381],
              [-38.554159789955342, -8.762066622667581],
              [-38.554402888785297, -8.761930239614589],
              [-38.554561269965177, -8.761930426239301],
              [-38.554603459848678, -8.762004011624809],
              [-38.554603309553961, -8.762130279360674],
              [-38.554529204547698, -8.762287846044126],
              [-38.554412796803675, -8.762466528105017],
              [-38.55429628885279, -8.762729237857686],
              [-38.554148124978873, -8.7629288667308],
              [-38.554021120054635, -8.763149595297175],
              [-38.553925833481486, -8.763380762964278],
              [-38.553862249350644, -8.763559507143416],
              [-38.55387262053484, -8.763706681172392],
              [-38.55394633746586, -8.763875095201303],
              [-38.55405182316148, -8.764011889275347],
              [-38.554199520678637, -8.76412774875925],
              [-38.5544107679505, -8.764170056985581],
              [-38.554516400909058, -8.764107047542852],
              [-38.554611815832843, -8.763844312902899],
              [-38.554685821128693, -8.763770773964479],
              [-38.554833722261179, -8.763792022967927],
              [-38.55500267598196, -8.763771147095296],
              [-38.555161248892205, -8.763687215424927],
              [-38.555309121679805, -8.763655822428129],
              [-38.555372455119496, -8.763687463904665],
              [-38.55538301779, -8.763750519835911],
              [-38.555340665131148, -8.763813603975994],
              [-38.555308884187006, -8.763855625745027],
              [-38.555308796671284, -8.7639292517744],
              [-38.555329856025892, -8.764034379208372],
              [-38.555329793454916, -8.764087020914555],
              [-38.555202951385468, -8.764170989986889],
              [-38.555160548741235, -8.764276042745525],
              [-38.555086455864561, -8.764423207799815],
              [-38.55495953249207, -8.764575375824311],
              [-38.554822052554634, -8.764659332229165],
              [-38.554716494633752, -8.764659207890187],
              [-38.554621392494397, -8.764659095842013],
              [-38.554557971292923, -8.764701080254969],
              [-38.55450512187786, -8.764722002318068],
              [-38.554399576442059, -8.764711385729949],
              [-38.554262196570292, -8.764711223746909],
              [-38.554156688770618, -8.764669040163794],
              [-38.554061586636067, -8.764668927974679],
              [-38.553924222421145, -8.764731899830116],
              [-38.553829007429066, -8.764826397910339],
              [-38.553744392356094, -8.764952475448755],
              [-38.553691417355935, -8.765078590327516],
              [-38.553670135713702, -8.7652363095934],
              [-38.553553676384233, -8.765457050377472],
              [-38.553479491821804, -8.765604124507206],
              [-38.55345824773606, -8.765730276827981],
              [-38.553426444266798, -8.765866999291093],
              [-38.553457820867862, -8.766087824235042],
              [-38.553457557157266, -8.766308702240176],
              [-38.553478412103054, -8.766508530402712],
              [-38.55360504506617, -8.766676916563839],
              [-38.553752705888613, -8.766824252724453],
              [-38.55393218574973, -8.766898090736175],
              [-38.554069437858324, -8.766929819695585],
              [-38.554153968787851, -8.76695090378416],
              [-38.554196068077133, -8.767024579562273],
              [-38.554291095568836, -8.767087825596018],
              [-38.554375598247638, -8.7670563582888],
              [-38.554512950662193, -8.767003968924104],
              [-38.554629134433199, -8.767014598049396],
              [-38.554724199586403, -8.767046277051637],
              [-38.5548192865676, -8.766983255143822],
              [-38.554935570452344, -8.766909766002238],
              [-38.555084723775977, -8.766873038132813],
              [-38.555317012743295, -8.766883894177008],
              [-38.555517764936454, -8.766884130361243],
              [-38.555634023729667, -8.766831625316458],
              [-38.555707904487384, -8.766863279161672],
              [-38.555760654231939, -8.7669264751271],
              [-38.55591902509758, -8.766937153448946],
              [-38.556024724529721, -8.766895218498171],
              [-38.556172685919094, -8.766790199201823],
              [-38.556363003552143, -8.76669581219598],
              [-38.556510964771462, -8.766590792757423],
              [-38.556637769995902, -8.766538390218262],
              [-38.556753941174122, -8.766559601298024],
              [-38.5567749475399, -8.76663316162219],
              [-38.556764288547122, -8.766727850058706],
              [-38.55674303304157, -8.766864494770504],
              [-38.556679499521955, -8.76700108988233],
              [-38.55661589110192, -8.767200818848451],
              [-38.5565206261114, -8.767337467141445],
              [-38.55637263969497, -8.767463470887048],
              [-38.556309130828069, -8.767579081598962],
              [-38.556308968670486, -8.767715751038001],
              [-38.556319303292277, -8.767894582402553],
              [-38.556372028385965, -8.767978762612906],
              [-38.556509281245482, -8.76801040026467],
              [-38.5566253781166, -8.768094654820247],
              [-38.556699134503305, -8.768231501445356],
              [-38.55667784143504, -8.768399713069559],
              [-38.556667032955843, -8.768620397869761],
              [-38.556666945659209, -8.768694023894142],
              [-38.556687864812439, -8.768841210243995],
              [-38.556719343136372, -8.768978007256454],
              [-38.556803887139338, -8.768988598619611],
              [-38.55692136314083, -8.76890678890376],
              [-38.557026972121577, -8.768864853592412],
              [-38.55718049155805, -8.768827044648958],
              [-38.557348452820825, -8.768801553734379],
              [-38.557575170240405, -8.768758132044171],
              [-38.557744254278447, -8.768705778675042],
              [-38.55789218250132, -8.768705951807091],
              [-38.557923748419626, -8.76876903225536],
              [-38.557944833423413, -8.768853265691593],
              [-38.557891871704854, -8.768968798564273],
              [-38.557828338092207, -8.769105484306902],
              [-38.557733110456951, -8.769210475504668],
              [-38.557595707734045, -8.769305015562209],
              [-38.557447629925832, -8.769431019588689],
              [-38.55733129588824, -8.769546568573643],
              [-38.5572889217298, -8.769704263351864],
              [-38.557309890969144, -8.769809390580749],
              [-38.557373200766484, -8.769862016093994],
              [-38.557499944816747, -8.769862164579521],
              [-38.557647873492243, -8.769862337829853],
              [-38.557742811387399, -8.769925582944332],
              [-38.557784915540203, -8.770072884499367],
              [-38.557763709907292, -8.770167470158192],
              [-38.557679028724799, -8.7702725641191],
              [-38.557520544381163, -8.770356496812783],
              [-38.557404309761303, -8.770387837166128],
              [-38.557309118942314, -8.770461351745958],
              [-38.557213969189768, -8.770576925497652],
              [-38.557076419068466, -8.770718589492684],
              [-38.556949462796084, -8.770897259817797],
              [-38.556811959623381, -8.771075827267783],
              [-38.556685003099176, -8.771254497488293],
              [-38.556631978181962, -8.771422671828555],
              [-38.556631816070229, -8.771559341245792],
              [-38.556684466832394, -8.771706655279129],
              [-38.556779342689573, -8.771822451890097],
              [-38.556789830896342, -8.771948641631942],
              [-38.556768508970656, -8.772064301868649],
              [-38.556842382138633, -8.772179983368018],
              [-38.556958364434138, -8.772285312531936],
              [-38.556958277157847, -8.772358938544919],
              [-38.556884258062219, -8.772442969991701],
              [-38.556736381863722, -8.772474363424246],
              [-38.556599065122299, -8.772495186572655],
              [-38.556503886048162, -8.772558208748533],
              [-38.556303059449768, -8.772694642446595],
              [-38.556176177077646, -8.77281017859594],
              [-38.556176064721548, -8.772904788914447],
              [-38.556228724427839, -8.772967984733784],
              [-38.556387088939005, -8.773062781253129],
              [-38.556568351240806, -8.773093294815451],
              [-38.556723068662471, -8.773273471496559],
              [-38.556813307199917, -8.773466506765979],
              [-38.556840723666504, -8.773578606202628],
              [-38.556840586476021, -8.773694291291786],
              [-38.556734872717477, -8.773746718522901],
              [-38.556555201824509, -8.773830625842816],
              [-38.556375480922419, -8.773956592152006],
              [-38.556354224624869, -8.774093327212007],
              [-38.556343312134651, -8.77432459441882],
              [-38.556353662866378, -8.774566469228487],
              [-38.5563640100995, -8.77473471793102],
              [-38.556374407168128, -8.774860998004947],
              [-38.556374057601346, -8.775155411542009],
              [-38.556289453305155, -8.775270997351649],
              [-38.556246937065367, -8.775470750879403],
              [-38.556294472423914, -8.77563831968723],
              [-38.556414692641781, -8.775774226039966],
              [-38.55655094377277, -8.775886182004941],
              [-38.556743432485959, -8.775958315619389],
              [-38.556968054826626, -8.775998558087862],
            ],
          ],
          [
            [
              [-38.565586733059057, -8.777470334416327],
              [-38.565999558731988, -8.777440779971968],
              [-38.566382581880085, -8.777080415702303],
              [-38.566745384577231, -8.77675005714968],
              [-38.567007581495048, -8.776379513535105],
              [-38.567008057859766, -8.7759685098665],
              [-38.566968243735239, -8.775567590362588],
              [-38.566958406916044, -8.775347062286228],
              [-38.567069371235895, -8.775156792559137],
              [-38.567300985810412, -8.775126938272345],
              [-38.567582625188642, -8.775317748403731],
              [-38.567894600153949, -8.775458393372983],
              [-38.568206669783166, -8.775438670551111],
              [-38.568468632711827, -8.775268652928853],
              [-38.568559490890735, -8.775088309232148],
              [-38.568610013818443, -8.774956852816544],
              [-38.568698291355354, -8.774806535493946],
              [-38.568748773900808, -8.774631210806843],
              [-38.568786857856459, -8.774405762704744],
              [-38.568887707719355, -8.774233570805688],
              [-38.569013616520905, -8.774171032760981],
              [-38.569112259438477, -8.774099056768069],
              [-38.569137125258422, -8.77398204296521],
              [-38.569090879228732, -8.773874173904112],
              [-38.569051811594377, -8.773770202376705],
              [-38.568869496938689, -8.77362590756268],
              [-38.568737524379308, -8.773506724787433],
              [-38.568725121695813, -8.773381437505497],
              [-38.568794508594728, -8.773212375422577],
              [-38.56896457512758, -8.773018283179296],
              [-38.569115719475818, -8.772911905673181],
              [-38.569172421654137, -8.772861861115299],
              [-38.569292106774348, -8.772755538090806],
              [-38.569518781051109, -8.772592896248927],
              [-38.569770767447572, -8.772398987424443],
              [-38.569928112700239, -8.77235538887763],
              [-38.570066488630935, -8.772361787477237],
              [-38.570204926678812, -8.772393240726471],
              [-38.570280355871326, -8.772424622236137],
              [-38.570500640658913, -8.772362281473971],
              [-38.570588880733069, -8.772243349707667],
              [-38.570645661794487, -8.772124382154324],
              [-38.570601792376515, -8.772005300168383],
              [-38.570482316576133, -8.771930000418227],
              [-38.570450922397647, -8.771873614406532],
              [-38.570356617648784, -8.771810825336775],
              [-38.570212014258864, -8.771685387659607],
              [-38.57009254582033, -8.771603846756742],
              [-38.570004579726806, -8.771484624064241],
              [-38.569922848323372, -8.771478289961539],
              [-38.569859839605364, -8.771478218209088],
              [-38.569790713174683, -8.771421698724374],
              [-38.569683818151553, -8.771396522323586],
              [-38.569583098525129, -8.771377684425126],
              [-38.569513993823627, -8.771302351333048],
              [-38.56942594849167, -8.77125214171646],
              [-38.569243512078479, -8.771214396982696],
              [-38.569111424683257, -8.771195432737072],
              [-38.568991891678834, -8.771170241727686],
              [-38.568885061781835, -8.771088714882543],
              [-38.568828489949098, -8.771025968515925],
              [-38.568853812508316, -8.770906965330493],
              [-38.568988546644903, -8.77083828670964],
              [-38.569202578866076, -8.770757035497134],
              [-38.569328428586083, -8.770744696918754],
              [-38.56954240298689, -8.770713554700619],
              [-38.569750161345141, -8.77063238646862],
              [-38.569920309800736, -8.770444625278394],
              [-38.57007786328515, -8.770219222494504],
              [-38.570229229308048, -8.769918739145615],
              [-38.570311255514802, -8.769668286124253],
              [-38.570361849043678, -8.769474147644223],
              [-38.570343110296456, -8.769323708079824],
              [-38.570286682336054, -8.769135779370851],
              [-38.570211369098637, -8.769004089034711],
              [-38.570192680750218, -8.768809871748207],
              [-38.570268308060015, -8.768747366416047],
              [-38.568273874334537, -8.767870803666952],
              [-38.568273892578752, -8.767870721190594],
              [-38.568273874438965, -8.767870713217205],
              [-38.569873395229948, -8.760639706329943],
              [-38.569524536521016, -8.760483283152107],
              [-38.569223939214531, -8.760341567539653],
              [-38.569063016100394, -8.760259436519084],
              [-38.569062925285621, -8.76025934596565],
              [-38.569050838274755, -8.760254809693162],
              [-38.569159768675661, -8.760878495906963],
              [-38.568796563735681, -8.762599344654207],
              [-38.566529487802534, -8.762673996384329],
              [-38.565985314468925, -8.762612408818686],
              [-38.565770565700994, -8.763001277787746],
              [-38.565788701568231, -8.763199383942085],
              [-38.565808910861982, -8.76341277851205],
              [-38.565839271250631, -8.763575351884516],
              [-38.565852716706971, -8.76374125247524],
              [-38.565838882143403, -8.763910649280181],
              [-38.565804693490719, -8.764069711412963],
              [-38.565705896067641, -8.764198308143516],
              [-38.565556135799717, -8.764289580910541],
              [-38.56536895187962, -8.764350328965577],
              [-38.565273565465141, -8.764438317484425],
              [-38.565239451256005, -8.764533069750192],
              [-38.565263087273706, -8.764692288770821],
              [-38.565310575881398, -8.764824400250431],
              [-38.565320617239969, -8.76494633827128],
              [-38.565310312165714, -8.765051338759601],
              [-38.565330572101672, -8.765142716463478],
              [-38.565320251255685, -8.765261284418306],
              [-38.565258908543207, -8.765393270722258],
              [-38.565115828450864, -8.765525253420225],
              [-38.564921773696348, -8.765629951961253],
              [-38.564744807836902, -8.765738107173945],
              [-38.564604957023668, -8.765829390928552],
              [-38.564417649894338, -8.765995150644141],
              [-38.564325666595394, -8.766127101453568],
              [-38.564213194538468, -8.766286163576696],
              [-38.5641245317168, -8.766455383509802],
              [-38.564124444961379, -8.766529823664424],
              [-38.564158275479926, -8.766678924092327],
              [-38.564195568905632, -8.766821244769785],
              [-38.564198763268152, -8.766966872765657],
              [-38.56413391353999, -8.767142903890562],
              [-38.56405555221825, -8.767288347352574],
              [-38.56397698126888, -8.767535546664972],
              [-38.563976819383313, -8.767674387039751],
              [-38.563986805073313, -8.767843720731598],
              [-38.563990098174102, -8.767982565091216],
              [-38.563996773167247, -8.768106127323559],
              [-38.564040905303074, -8.768153664431795],
              [-38.564051017357102, -8.768214639315291],
              [-38.564006739040956, -8.768292465565517],
              [-38.563876432864234, -8.768618386965617],
              [-38.563805136702342, -8.768708392730234],
              [-38.563698347668414, -8.76882721100087],
              [-38.563614964845407, -8.768912137573787],
              [-38.563599981912688, -8.768973716626386],
              [-38.563668179340723, -8.76904597438957],
              [-38.563770543154227, -8.769135366619011],
              [-38.563913349582755, -8.769239639286569],
              [-38.564087931947114, -8.769305869174536],
              [-38.564233688068661, -8.769375774179723],
              [-38.564420385828647, -8.769422480721328],
              [-38.564647938053888, -8.769364674198524],
              [-38.564869664528658, -8.769312649617394],
              [-38.565003958655595, -8.769231489829115],
              [-38.565085592305508, -8.76924316140026],
              [-38.565126341590918, -8.769307065926123],
              [-38.565126260477037, -8.769376802691941],
              [-38.565126138857558, -8.769481362615331],
              [-38.565102630298966, -8.769603261992701],
              [-38.565108425280329, -8.769702039933446],
              [-38.565119968500291, -8.769783458114135],
              [-38.565061529428064, -8.769841550107587],
              [-38.564956474903276, -8.769876252341389],
              [-38.5648281648457, -8.76989347092975],
              [-38.564682333943558, -8.769887514116133],
              [-38.564513149782044, -8.769869952741653],
              [-38.56433228725006, -8.769887110574164],
              [-38.564209748682742, -8.769945038048078],
              [-38.564098834393299, -8.770014646926727],
              [-38.563993698516271, -8.770118995188573],
              [-38.563882696029218, -8.77026412953318],
              [-38.563678161420484, -8.770542659807509],
              [-38.563572937101505, -8.770722533465815],
              [-38.563444477454638, -8.770867557040022],
              [-38.563368459009844, -8.771030098011217],
              [-38.563257435710362, -8.771192598488113],
              [-38.56320469303239, -8.771355166330018],
              [-38.563227890239631, -8.771500365165794],
              [-38.563286014895688, -8.771634026806638],
              [-38.563455084197464, -8.771750540769546],
              [-38.563595062896042, -8.771785525730925],
              [-38.563665025172263, -8.771826218548737],
              [-38.563711539187658, -8.771936621105226],
              [-38.563699792669297, -8.772029589984696],
              [-38.563664658979512, -8.772139898215221],
              [-38.563588653966406, -8.77229077119692],
              [-38.563518440738974, -8.772464896464284],
              [-38.56350065524348, -8.772668297616399],
              [-38.563483089639035, -8.772761259755875],
              [-38.563418756341889, -8.772883111779734],
              [-38.563412828820674, -8.772975996918191],
              [-38.563377620232323, -8.773150253061434],
              [-38.5633890344305, -8.773341839027481],
              [-38.563383079624089, -8.773458060190347],
              [-38.563417976099331, -8.773550992526431],
              [-38.563546324191023, -8.773580175297324],
              [-38.563697995782135, -8.773568772994413],
              [-38.563826334380458, -8.77352821882832],
              [-38.563951401446367, -8.773486213641752],
              [-38.564041698722015, -8.773398400647634],
              [-38.564113517363296, -8.773328927619971],
              [-38.564172454900977, -8.773233842402387],
              [-38.564227800457722, -8.77310022139091],
              [-38.564277518637311, -8.773036149814111],
              [-38.564424514555348, -8.772979697783859],
              [-38.564668049853346, -8.772862212890178],
              [-38.564738978451345, -8.772854154160818],
              [-38.5649183198041, -8.772817185931027],
              [-38.565034102380551, -8.772783852857362],
              [-38.565125734332888, -8.772720733931315],
              [-38.565235985778912, -8.772674369642983],
              [-38.565346575535003, -8.772649804128868],
              [-38.565463228335183, -8.772649938372664],
              [-38.56550395100485, -8.772737088453106],
              [-38.565427919552363, -8.772911207415619],
              [-38.565293586713608, -8.773102716073021],
              [-38.565194154333739, -8.773305842444136],
              [-38.565088990618129, -8.773433526995669],
              [-38.565024630647109, -8.773578624891901],
              [-38.56503617715002, -8.773735387800839],
              [-38.565059250354871, -8.773909711258918],
              [-38.565076683486041, -8.774008412136149],
              [-38.565035744433857, -8.774107136224472],
              [-38.564918878624447, -8.774211471166222],
              [-38.564872161030621, -8.774275365379577],
              [-38.564819529796139, -8.774420476739904],
              [-38.564807570845225, -8.774617914987214],
              [-38.564801575984397, -8.774768868881921],
              [-38.564719805851389, -8.77487333464779],
              [-38.564643834474253, -8.774995173450966],
              [-38.564562037155042, -8.77512288475655],
              [-38.56447430957089, -8.775267955570436],
              [-38.564018476460333, -8.775534166028143],
              [-38.563625571160102, -8.775714069053183],
              [-38.563353498872985, -8.775974340429158],
              [-38.563161810763617, -8.776304893681708],
              [-38.563101250496779, -8.776465191136475],
              [-38.563101180150696, -8.776525340188604],
              [-38.563322714974603, -8.776485527253014],
              [-38.563594424720627, -8.776535950675465],
              [-38.563926516410348, -8.776656542285794],
              [-38.564289028405781, -8.776576822337688],
              [-38.564641363557627, -8.776647417934518],
              [-38.56492335710562, -8.776768041354011],
              [-38.565313327095723, -8.776845192102533],
              [-38.565295203185933, -8.77702742767478],
              [-38.565325128776848, -8.777329745526522],
              [-38.565586733059057, -8.777470334416327],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0336000',
        uf: 'PE',
        nome_proje: 'PA SÃO GERALDO',
        municipio: 'ITAIBA',
        area_hecta: '461.6962',
        capacidade: 23.0,
        num_famili: 23.0,
        fase: 4.0,
        data_de_cr: '01/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/12/2004',
        area_calc_: 461.4647,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.220614413196735, -8.933012675163205],
              [-37.221170729586483, -8.93699364628786],
              [-37.220732703326462, -8.940113665679325],
              [-37.220356912352479, -8.942791697093172],
              [-37.220357980325751, -8.942946212472876],
              [-37.221980575088715, -8.949922065686255],
              [-37.223008255692257, -8.949784151292347],
              [-37.224414981841875, -8.949595252204873],
              [-37.225510991918256, -8.949448165081407],
              [-37.227355084175564, -8.949200503859984],
              [-37.228922994875163, -8.948979448984447],
              [-37.230221026787454, -8.948779067177037],
              [-37.23146355204738, -8.948587185276255],
              [-37.233338121357399, -8.948297778174782],
              [-37.23658814852211, -8.947759502791975],
              [-37.237769222810797, -8.947563870238843],
              [-37.239003166575372, -8.947359534388948],
              [-37.239609308904846, -8.947259257181544],
              [-37.240394506302088, -8.947116703674105],
              [-37.242238097827688, -8.946782257802425],
              [-37.244182157818841, -8.946429563281793],
              [-37.246059873744748, -8.946088749200982],
              [-37.247975788808802, -8.945764469977441],
              [-37.249901969017223, -8.94544185608135],
              [-37.251787198123907, -8.945126090356334],
              [-37.254010942241422, -8.94475359452983],
              [-37.257888770283522, -8.944104250028422],
              [-37.258057935096673, -8.94095881567557],
              [-37.258062222975298, -8.940879273703434],
              [-37.231284800912086, -8.935191780521112],
              [-37.231272257766847, -8.935228608101184],
              [-37.220614413196735, -8.933012675163205],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0006000',
        uf: 'PE',
        nome_proje: 'PA VARZINHA',
        municipio: 'CABROBO',
        area_hecta: '1029.0000',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 5.0,
        data_de_cr: '12/09/1989',
        forma_obte: 'Adjudicação',
        data_obten: '07/08/1989',
        area_calc_: 308.3539,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.519165470444975, -8.213879280732533],
              [-39.519039930515298, -8.21445525608608],
              [-39.515909930102907, -8.228483677117199],
              [-39.509966563110297, -8.255443421957118],
              [-39.508275512588888, -8.255934098913006],
              [-39.50760291583213, -8.256750551600932],
              [-39.507204044724723, -8.257185856546736],
              [-39.506315207698002, -8.258325763787868],
              [-39.505048343089683, -8.258662666827966],
              [-39.50390657643311, -8.2589105853093],
              [-39.503161970254162, -8.259088987311081],
              [-39.502779466018829, -8.259332872503549],
              [-39.500925267176953, -8.26050862279855],
              [-39.498655398449095, -8.272258925038132],
              [-39.500914063554056, -8.271419426284032],
              [-39.502273021283685, -8.278733786942807],
              [-39.506000696192586, -8.279420763508451],
              [-39.506399664845723, -8.279470820169157],
              [-39.511892488492464, -8.254868680143115],
              [-39.517757701908039, -8.228519652003433],
              [-39.520569415114487, -8.216229926359903],
              [-39.52073398806651, -8.215510987050305],
              [-39.520451625202796, -8.214878825978737],
              [-39.520176588394087, -8.214433708580346],
              [-39.519609979663798, -8.214091720176578],
              [-39.519165470444975, -8.213879280732533],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0186000',
        uf: 'PE',
        nome_proje: 'PA SERRA BRANCA',
        municipio: 'BETANIA',
        area_hecta: '1364.0033',
        capacidade: 35.0,
        num_famili: 33.0,
        fase: 3.0,
        data_de_cr: '02/10/2006',
        forma_obte: 'Desapropriação',
        data_obten: '02/10/2006',
        area_calc_: 1325.6356,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.913427535274216, -8.403545220973813],
              [-37.935816838747748, -8.376258633930048],
              [-37.933320263900882, -8.374588603084987],
              [-37.931235611997913, -8.371851447709073],
              [-37.928768464896756, -8.37027415468118],
              [-37.905594862456056, -8.397782565246768],
              [-37.906775216648178, -8.398698167190283],
              [-37.908504095320083, -8.399876489878586],
              [-37.910494787155159, -8.401300343760276],
              [-37.911670784277952, -8.401858914161487],
              [-37.913427535274216, -8.403545220973813],
            ],
          ],
          [
            [
              [-37.91334199379498, -8.403648744436152],
              [-37.911592199557816, -8.40197188648917],
              [-37.910425176964651, -8.401417578617195],
              [-37.908425877017038, -8.399987567477119],
              [-37.9066941865497, -8.398807328134099],
              [-37.905513760024355, -8.397870453518923],
              [-37.903800066932988, -8.399877323476455],
              [-37.903105030894679, -8.400716115888361],
              [-37.900536643987763, -8.40381838193905],
              [-37.900205403745403, -8.404208590528921],
              [-37.895594604794994, -8.409771760327803],
              [-37.895868202255471, -8.41034661359885],
              [-37.896244235592903, -8.410994540467652],
              [-37.897686684626017, -8.413452437142539],
              [-37.899062422353779, -8.415784568720518],
              [-37.899437120121718, -8.416287690215221],
              [-37.899967499901095, -8.416819179505245],
              [-37.902530625844953, -8.419398115152012],
              [-37.909419375161562, -8.411182671687436],
              [-37.909727501172391, -8.410824442185101],
              [-37.910112539016346, -8.410376937146319],
              [-37.909044343870889, -8.408798909391955],
              [-37.910405412628876, -8.407164433961418],
              [-37.911014645218749, -8.406395380913818],
              [-37.91334199379498, -8.403648744436152],
            ],
          ],
          [
            [
              [-37.901441576512383, -8.42078860742969],
              [-37.898699862836288, -8.418023253962387],
              [-37.898135992894346, -8.417422959212779],
              [-37.89766280221653, -8.416791600410928],
              [-37.896180677083301, -8.414280037475439],
              [-37.894877581546396, -8.412042623525833],
              [-37.894560741418665, -8.411505424423234],
              [-37.894427329276041, -8.41119476183291],
              [-37.861056187830748, -8.451167076892959],
              [-37.869294185674008, -8.454274896306423],
              [-37.869510744572857, -8.454318777715939],
              [-37.869758685313066, -8.454322261200511],
              [-37.872096995883936, -8.454154337922475],
              [-37.87502264660619, -8.453310302139366],
              [-37.890743987859949, -8.433510931094057],
              [-37.901441576512383, -8.42078860742969],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0061000',
        uf: 'PE',
        nome_proje: 'PA 1º DE MAIO',
        municipio: 'PETROLINA',
        area_hecta: '455.0983',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 4.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2001',
        area_calc_: 455.1148,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.590533184038037, -9.36316772798672],
              [-40.602313142986773, -9.359866769054731],
              [-40.602571063342204, -9.350504318267415],
              [-40.603862533557809, -9.349955267983557],
              [-40.607008957665521, -9.349350715619],
              [-40.609197710870518, -9.349665122054185],
              [-40.611953310298432, -9.349873415320822],
              [-40.611957386436266, -9.357163858889784],
              [-40.619550382934008, -9.354989693615861],
              [-40.619458516687402, -9.354751326800033],
              [-40.619345470860488, -9.354401755670716],
              [-40.619255592371665, -9.354120703771144],
              [-40.6191360763858, -9.353690873447947],
              [-40.618993737027687, -9.35324704239777],
              [-40.618907843318446, -9.35294165046526],
              [-40.618814224664938, -9.352599675611444],
              [-40.618715940210471, -9.352272369219863],
              [-40.618577936836076, -9.351840633635069],
              [-40.618442452316941, -9.3514022861436],
              [-40.618290144269537, -9.350869260199822],
              [-40.618118303035054, -9.350324930831222],
              [-40.618008656113204, -9.349784205673693],
              [-40.618200412224404, -9.349240388884308],
              [-40.618575333502029, -9.348953420175357],
              [-40.618918122982741, -9.348805204013612],
              [-40.619248318659388, -9.348650354286825],
              [-40.619780549175069, -9.348423064362358],
              [-40.620168864141313, -9.348257189368256],
              [-40.620704050658418, -9.348039468754127],
              [-40.621192184474047, -9.347844204359626],
              [-40.62154259008804, -9.347650562919183],
              [-40.621868418760805, -9.347398534961332],
              [-40.622179871060347, -9.347089158952107],
              [-40.622420458443983, -9.346751535394004],
              [-40.622608805570174, -9.346357912337082],
              [-40.622784815268304, -9.345954038312271],
              [-40.623001704531518, -9.345453143060796],
              [-40.623202273467385, -9.345005847986736],
              [-40.623353015155587, -9.344591058725785],
              [-40.623379909599343, -9.344245912469857],
              [-40.623341170793466, -9.343960107102976],
              [-40.623244026227709, -9.34370205474597],
              [-40.623051814037474, -9.34339841549296],
              [-40.622775116771429, -9.343054023201942],
              [-40.622426706526831, -9.342615745701783],
              [-40.622114881594875, -9.342214461232279],
              [-40.621848798568685, -9.341881411721904],
              [-40.621546198256532, -9.341466702991964],
              [-40.621203915545088, -9.341053531156113],
              [-40.620904032532941, -9.340537092293832],
              [-40.620812273636446, -9.33996653978962],
              [-40.620841956653038, -9.339357549446548],
              [-40.620880514761225, -9.338837034962204],
              [-40.620913813116786, -9.338301173905824],
              [-40.621228971285717, -9.336746858109322],
              [-40.617969337461886, -9.337443529846722],
              [-40.617807279727344, -9.339569747655965],
              [-40.616275718201415, -9.340412153278857],
              [-40.611386131392855, -9.341811748067279],
              [-40.610159489569099, -9.34201703687935],
              [-40.604423593016207, -9.342760976278079],
              [-40.6041528857047, -9.341792399406206],
              [-40.60094004531512, -9.342432839534572],
              [-40.595280185721705, -9.343593020619979],
              [-40.595351579996759, -9.344537909999085],
              [-40.595436177249091, -9.345343318323414],
              [-40.595440213900268, -9.346229739601476],
              [-40.595039973683228, -9.347198537689231],
              [-40.594963447937353, -9.348165881682164],
              [-40.594645601977732, -9.349456643089241],
              [-40.594327387632909, -9.350666845396566],
              [-40.59416983981631, -9.351634553406985],
              [-40.59417497695712, -9.352762742672729],
              [-40.594100284949, -9.354132972752705],
              [-40.593458343983293, -9.355344629277262],
              [-40.593302352388122, -9.356634663932066],
              [-40.592017629606509, -9.358896767366144],
              [-40.591374850812414, -9.359947304491293],
              [-40.59065196005195, -9.361159322097542],
              [-40.590533184038037, -9.36316772798672],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0185000',
        uf: 'PE',
        nome_proje: 'PA TRES CONQUISTAS',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1057.4291',
        capacidade: 25.0,
        num_famili: 24.0,
        fase: 3.0,
        data_de_cr: '31/08/2006',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2006',
        area_calc_: 1049.2043,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.119059166391878, -8.968774293462998],
              [-40.119184196634315, -8.976355646013422],
              [-40.119542276057132, -8.976796622485541],
              [-40.121497193005737, -8.976961683964195],
              [-40.122282213326621, -8.977020884797117],
              [-40.12671697889261, -8.977864859604605],
              [-40.126734199230988, -8.977831998991302],
              [-40.12678465301601, -8.977735720807543],
              [-40.129438640334804, -8.972671191531632],
              [-40.129969587757245, -8.971682082014809],
              [-40.130659768810077, -8.970845702131232],
              [-40.141832691417051, -8.962163573399286],
              [-40.147555535560514, -8.957766839518266],
              [-40.154688853595751, -8.952303971530844],
              [-40.161686591015332, -8.946552534967632],
              [-40.159837657363724, -8.945910906070139],
              [-40.15973619695783, -8.94599203497231],
              [-40.141988509033034, -8.960538792472835],
              [-40.14206709676688, -8.957924368273432],
              [-40.142101972846852, -8.956734349450615],
              [-40.142105405581432, -8.956467522666451],
              [-40.142160741270608, -8.954529941076512],
              [-40.142202840730256, -8.952850625202794],
              [-40.142264308992893, -8.950414914378861],
              [-40.142273013050605, -8.950103008041777],
              [-40.1307134338578, -8.958794116401659],
              [-40.130390375285138, -8.957830803162276],
              [-40.130094308895842, -8.956966143089284],
              [-40.12956874316054, -8.955420597978936],
              [-40.128695601677933, -8.95566227047218],
              [-40.125245114681483, -8.956596401559729],
              [-40.123800848579236, -8.956989576830225],
              [-40.122519649129153, -8.957338599483933],
              [-40.122157334813593, -8.957435305704818],
              [-40.11984923524993, -8.962513252484081],
              [-40.119059166391878, -8.968774293462998],
            ],
          ],
          [
            [
              [-40.1307560064485, -8.970942939266566],
              [-40.130083608449091, -8.971757769497609],
              [-40.12955932151629, -8.972734471710814],
              [-40.126886969929025, -8.977897231777572],
              [-40.129179538970185, -8.978313134247641],
              [-40.131371241508091, -8.978715105657532],
              [-40.135856953473706, -8.979541310423047],
              [-40.139453775825615, -8.97748716197651],
              [-40.140175391009464, -8.977075223825949],
              [-40.141102517424102, -8.9765454019914],
              [-40.141553978224515, -8.976279198351399],
              [-40.144419230106607, -8.973893252073855],
              [-40.149912765258556, -8.969327155479332],
              [-40.149998226232313, -8.969243238474069],
              [-40.164844021369319, -8.95687981794971],
              [-40.172595813507854, -8.950398566202516],
              [-40.172666919550728, -8.95033660243686],
              [-40.161799148584805, -8.946589521942933],
              [-40.154774090517414, -8.952409915435462],
              [-40.147638867297964, -8.957874243842115],
              [-40.147611650759714, -8.957895154137889],
              [-40.147502289151845, -8.957979175934485],
              [-40.141916293205476, -8.962270771332218],
              [-40.1307560064485, -8.970942939266566],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0153000',
        uf: 'PE',
        nome_proje: 'PA SERRA GRANDE',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '758.7108',
        capacidade: 100.0,
        num_famili: 100.0,
        fase: 6.0,
        data_de_cr: '16/11/1998',
        forma_obte: 'Desapropriação',
        data_obten: '08/01/1998',
        area_calc_: 758.0866,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.352269763293471, -8.176484788163098],
              [-35.350104351955672, -8.1780083904181],
              [-35.34809486573819, -8.17934787610511],
              [-35.347802083622845, -8.179710018882968],
              [-35.34631741700845, -8.181123444889126],
              [-35.344972653531954, -8.182403384720519],
              [-35.344946415268581, -8.182430561176329],
              [-35.344283209373764, -8.183080459628979],
              [-35.342667724489822, -8.184977466579417],
              [-35.342143687811216, -8.185507170692476],
              [-35.341812361794553, -8.185810425738881],
              [-35.341443472982924, -8.186145080258315],
              [-35.339424374183309, -8.188103622876335],
              [-35.33803650536408, -8.189433683465037],
              [-35.336337545852388, -8.191165945132472],
              [-35.334725278226941, -8.193346358775267],
              [-35.334694373918332, -8.194735720573286],
              [-35.334643421794027, -8.198007867774649],
              [-35.334619554626606, -8.198876940022167],
              [-35.334952447927179, -8.202210220322012],
              [-35.334957268077233, -8.202258908345888],
              [-35.335016044624261, -8.202832313323565],
              [-35.336822670549935, -8.203932641058703],
              [-35.338885484445363, -8.205696861394864],
              [-35.340358346331925, -8.207332086480465],
              [-35.341752966286407, -8.209143640634082],
              [-35.342347354914651, -8.209871188944749],
              [-35.343614516500296, -8.209916957049098],
              [-35.345005649481081, -8.209911114049266],
              [-35.348384249444429, -8.209950705765973],
              [-35.350524212948031, -8.210059415380018],
              [-35.353179699297222, -8.210056018740293],
              [-35.353223433962178, -8.210055943801775],
              [-35.355383715437256, -8.210088136405163],
              [-35.358892444338203, -8.210078252663811],
              [-35.358731528889827, -8.205378201851344],
              [-35.358714352158202, -8.204911667303843],
              [-35.359306619947176, -8.204922207081349],
              [-35.360379450152848, -8.204925410697111],
              [-35.361308596467396, -8.204944366696125],
              [-35.362311304585795, -8.204948066647393],
              [-35.362917655566463, -8.204962585565751],
              [-35.362736522749309, -8.201405625895411],
              [-35.362586920331879, -8.200773840426139],
              [-35.362346270919979, -8.199732442208006],
              [-35.362157185583442, -8.19882160306314],
              [-35.361961244745224, -8.197531386616088],
              [-35.358793356939302, -8.197925739816593],
              [-35.358357862269159, -8.195467574821471],
              [-35.358377975283531, -8.195401660038504],
              [-35.358612600426454, -8.193006456119706],
              [-35.35878344344254, -8.190331987748406],
              [-35.358876232151744, -8.188635894803555],
              [-35.359088736782169, -8.186442460421336],
              [-35.356509103434334, -8.185355959216428],
              [-35.355834228009179, -8.184680866859134],
              [-35.355651821380128, -8.184235892531614],
              [-35.355699733321742, -8.183544108778626],
              [-35.355660925338263, -8.18297583937029],
              [-35.354019944623367, -8.181956683891507],
              [-35.353367641923406, -8.180195515647632],
              [-35.353191894396794, -8.179585834740827],
              [-35.352269763293471, -8.176484788163098],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0312000',
        uf: 'PE',
        nome_proje: 'PA LAGOA DO SERROTINHO',
        municipio: 'AGUAS BELAS',
        area_hecta: '515.5546',
        capacidade: 23.0,
        num_famili: 22.0,
        fase: 4.0,
        data_de_cr: '28/04/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 516.9214,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.927669402099674, -8.967893281922187],
              [-36.926638677140119, -8.968905073332298],
              [-36.925009622969704, -8.967933316260263],
              [-36.92401160787189, -8.970499074300465],
              [-36.925929274798904, -8.971864319043117],
              [-36.925990040135538, -8.972146601154385],
              [-36.922578755456314, -8.973841671171703],
              [-36.922839534868018, -8.974267811276935],
              [-36.917009176528076, -8.976793395283552],
              [-36.913177691733061, -8.97894925630025],
              [-36.909784116754516, -8.979867888449805],
              [-36.904006349177195, -8.981032651309773],
              [-36.904189271677076, -8.981175698336118],
              [-36.904504633783915, -8.981371021697482],
              [-36.904764575883647, -8.981600087754424],
              [-36.904704154578518, -8.981695130473001],
              [-36.90359378863382, -8.983037402186209],
              [-36.902615802177067, -8.984219702059828],
              [-36.903872203262431, -8.986528349370319],
              [-36.899794286438954, -8.99006709020588],
              [-36.900475885264264, -8.991523138899046],
              [-36.900803792634242, -8.992114098901334],
              [-36.9000704686228, -8.99243877168945],
              [-36.900583761032202, -8.993739832153292],
              [-36.900682603124686, -8.993990241966213],
              [-36.900855547421365, -8.993909059806972],
              [-36.900858412792751, -8.994295326155715],
              [-36.900865766005758, -8.995275320401117],
              [-36.901202177345269, -8.995953016355161],
              [-36.901165194561436, -8.996122699651764],
              [-36.90115398819168, -8.996174024331108],
              [-36.901182217916947, -8.996213686978637],
              [-36.901445031322233, -8.996583446029288],
              [-36.901910974007798, -8.997456315529909],
              [-36.90496751820023, -8.995823219591649],
              [-36.907110751989123, -8.994678002422626],
              [-36.909256345465508, -8.99353151757712],
              [-36.911174656341203, -8.992506483785506],
              [-36.909978559611012, -8.990848210113128],
              [-36.910607356617277, -8.990516685254804],
              [-36.911322926840938, -8.990226418305324],
              [-36.912178384129504, -8.989879361105748],
              [-36.913460772017046, -8.989108691677373],
              [-36.912248054686273, -8.988240192508869],
              [-36.915155722808656, -8.986530661866475],
              [-36.915247823732507, -8.986451274226868],
              [-36.915437574921114, -8.986752997299966],
              [-36.915542454395883, -8.986951101045371],
              [-36.915646907346897, -8.987128411749113],
              [-36.915738488543269, -8.987267141838633],
              [-36.915834134655199, -8.987426414338392],
              [-36.915880532998209, -8.987516709028581],
              [-36.91597455080781, -8.987738163410505],
              [-36.915990798498477, -8.987774684033573],
              [-36.916059558746824, -8.987913465911875],
              [-36.916130726211364, -8.988076486995315],
              [-36.916253109400358, -8.988282373111803],
              [-36.916357439021077, -8.98844955893332],
              [-36.916430372965984, -8.988558082375077],
              [-36.916610679007256, -8.98876284946064],
              [-36.916732652940823, -8.989008596994299],
              [-36.918616845019208, -8.987996790190151],
              [-36.920315939000147, -8.987235766009473],
              [-36.922014496237395, -8.98647219831979],
              [-36.92339501388858, -8.985851642556826],
              [-36.925033764517757, -8.985114930073049],
              [-36.925729426681244, -8.98699434955804],
              [-36.928357756155805, -8.985441589217023],
              [-36.930234820915963, -8.984348948945714],
              [-36.931937096975233, -8.983358005433397],
              [-36.936523012556236, -8.980688371355313],
              [-36.937384034312444, -8.980187062014574],
              [-36.938172169773459, -8.979728190310535],
              [-36.934062126571774, -8.97695891358021],
              [-36.934991703341005, -8.975491577895578],
              [-36.932923806258422, -8.972853218039582],
              [-36.931878100760898, -8.973603517899198],
              [-36.928327353023747, -8.97111601991989],
              [-36.930171445322337, -8.96905676635385],
              [-36.929386001789439, -8.968194247221259],
              [-36.928966911421696, -8.968349095207383],
              [-36.928127619920801, -8.968309497847599],
              [-36.927669402099674, -8.967893281922187],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0241000',
        uf: 'PE',
        nome_proje: 'PA ABREU E LIMA I',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1837.6767',
        capacidade: 50.0,
        num_famili: 50.0,
        fase: 3.0,
        data_de_cr: '26/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '06/07/2007',
        area_calc_: 1857.6008,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.122289376097392, -8.976990530111006],
              [-40.111039113174257, -8.999826206480613],
              [-40.108170812814478, -9.005437840341932],
              [-40.100938722473259, -9.020025034187681],
              [-40.103750264903724, -9.021505073947262],
              [-40.093812883358517, -9.040062271090488],
              [-40.101170198061816, -9.043829020674471],
              [-40.101496641641837, -9.043188226089123],
              [-40.101948343330498, -9.042293822817204],
              [-40.102397824756849, -9.041417874627959],
              [-40.102847118674376, -9.04054075100874],
              [-40.105109980439579, -9.036140608886651],
              [-40.107379912829799, -9.031718822924233],
              [-40.108694026319561, -9.029164682777473],
              [-40.110891512383027, -9.024906518133902],
              [-40.111059629751828, -9.024580805979891],
              [-40.111152501124394, -9.024399384086216],
              [-40.112036815472671, -9.022672082220124],
              [-40.112527336118994, -9.021697462223649],
              [-40.112535456556415, -9.021698095974239],
              [-40.112539503771821, -9.021690190603376],
              [-40.113563475334956, -9.021778325084579],
              [-40.122180886184033, -9.022450733377172],
              [-40.150385767185853, -9.027621428161583],
              [-40.15153063164982, -9.018328856051053],
              [-40.133072954847862, -9.003723914901252],
              [-40.140929168583106, -8.988966673628365],
              [-40.132066215717941, -8.982485962035893],
              [-40.136255701906478, -8.979593839493395],
              [-40.122289376097392, -8.976990530111006],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0310000',
        uf: 'PE',
        nome_proje: 'PA MELANCIA II',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.0233',
        capacidade: 10.0,
        num_famili: 8.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '22/09/2010',
        area_calc_: 1320.1814,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.033535215551709, -8.593386916291548],
              [-40.032706814419271, -8.594138516111935],
              [-40.031848225167444, -8.594917507979522],
              [-40.032674574409242, -8.595085228417421],
              [-40.032314325438257, -8.596913787159268],
              [-40.055167764581377, -8.633604279325072],
              [-40.059660305833539, -8.652894912599685],
              [-40.069487053706908, -8.660175942151735],
              [-40.120093485448685, -8.728334292200362],
              [-40.12286295265951, -8.732686654413307],
              [-40.122992012588448, -8.732889472779714],
              [-40.124258442800134, -8.734879658992908],
              [-40.124627228416927, -8.735459434626794],
              [-40.13276792963763, -8.748252087750393],
              [-40.132942106825716, -8.748525947929739],
              [-40.13727368561797, -8.75533223978797],
              [-40.137802630985568, -8.756163287419012],
              [-40.148015761693649, -8.772210142453746],
              [-40.151101499248455, -8.768636711145955],
              [-40.151101499248455, -8.768636711145955],
              [-40.151101499248455, -8.768636711145955],
              [-40.143983507752615, -8.757027022091062],
              [-40.143621271270277, -8.756436219426755],
              [-40.14357590948093, -8.756362228544253],
              [-40.127375947966534, -8.729935512029202],
              [-40.12724679803501, -8.729724811939581],
              [-40.12421529877345, -8.72477900669386],
              [-40.073771131033361, -8.655779970315095],
              [-40.063725604004588, -8.648726924031676],
              [-40.06000504318844, -8.632379249145972],
              [-40.033624228206143, -8.593306096272142],
              [-40.033535215551709, -8.593386916291548],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0070000',
        uf: 'PE',
        nome_proje: 'PA UMBURANA/LEALDADE',
        municipio: 'PARNAMIRIM',
        area_hecta: '450.9869',
        capacidade: 12.0,
        num_famili: 11.0,
        fase: 3.0,
        data_de_cr: '05/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '15/01/2002',
        area_calc_: 453.7442,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.723860327527618, -8.142695309066157],
              [-39.722770390967881, -8.1423067856391],
              [-39.722033776664837, -8.142044258572188],
              [-39.719161533735871, -8.14102041557681],
              [-39.715515865903882, -8.139694821604349],
              [-39.715339667463802, -8.139635255116875],
              [-39.711505181516451, -8.138235154451275],
              [-39.708101254265166, -8.137155267163656],
              [-39.703590011122429, -8.13573871934517],
              [-39.698652723863091, -8.134162395184324],
              [-39.699087061339995, -8.137049226327076],
              [-39.699364691984606, -8.138444011500729],
              [-39.70039622148002, -8.142429758042159],
              [-39.700424399085875, -8.142608797952985],
              [-39.700867231308933, -8.145562903391262],
              [-39.701367678051973, -8.148914610734222],
              [-39.705261137270746, -8.15009852613386],
              [-39.709597670458933, -8.151434032928435],
              [-39.714381019902603, -8.15297926782328],
              [-39.716870633653414, -8.153793431663734],
              [-39.719824069522765, -8.154739986381792],
              [-39.721639834377001, -8.155317707442983],
              [-39.725252136254191, -8.156474424453386],
              [-39.726123254506398, -8.153841183667453],
              [-39.726170742200175, -8.153697828644578],
              [-39.726590195749495, -8.152336736098045],
              [-39.726803326719526, -8.151933407376188],
              [-39.726838697794165, -8.151866502753398],
              [-39.727032107046853, -8.151500293055927],
              [-39.72707921030392, -8.15124487332537],
              [-39.727265557020964, -8.150235318132951],
              [-39.72734585114452, -8.150264117575043],
              [-39.728175281528351, -8.147932141158746],
              [-39.728234140076161, -8.147953652631388],
              [-39.729601743489773, -8.14462089356881],
              [-39.727922146068437, -8.144027041631213],
              [-39.724565441698495, -8.142904524339768],
              [-39.724298707821333, -8.142825315391219],
              [-39.724135507387963, -8.142777035804709],
              [-39.723860327527618, -8.142695309066157],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0303000',
        uf: 'PE',
        nome_proje: 'PA PATATIVA DO ASSARÉ',
        municipio: 'OURICURI',
        area_hecta: '650.0000',
        capacidade: 32.0,
        num_famili: 30.0,
        fase: 4.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '08/11/2002',
        area_calc_: 789.6607,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.081248596806752, -7.940947169738806],
              [-40.07782599195577, -7.940509908452682],
              [-40.073829376892419, -7.940053208271057],
              [-40.071697252678462, -7.941692882030981],
              [-40.069735761355219, -7.940146598650784],
              [-40.068476698873617, -7.938739950445481],
              [-40.067187492296355, -7.939323618883226],
              [-40.064604350962064, -7.940033415956878],
              [-40.061494974871309, -7.940823767731858],
              [-40.060636633499257, -7.941080368181048],
              [-40.058797776465951, -7.941461921463019],
              [-40.056293898274355, -7.944553963124764],
              [-40.055590467969296, -7.945760246644799],
              [-40.053952118504299, -7.945911551515825],
              [-40.054598241480974, -7.948267536889778],
              [-40.059515592977121, -7.950719279918713],
              [-40.064813980888012, -7.953331779148499],
              [-40.067681219180308, -7.954716575270345],
              [-40.069031100811657, -7.955405336791028],
              [-40.088712203025288, -7.96990368771657],
              [-40.092049068528283, -7.972982083521916],
              [-40.094229209932806, -7.974985638435436],
              [-40.097505296747109, -7.978035831502275],
              [-40.100382265404498, -7.980687489692877],
              [-40.102963695759556, -7.975761690085158],
              [-40.102058624310061, -7.975180581490925],
              [-40.102912823260688, -7.971638674870134],
              [-40.099087263566105, -7.96699281000808],
              [-40.097251525973796, -7.964750056989398],
              [-40.094885730949422, -7.961859603658027],
              [-40.094142574796464, -7.960981129621],
              [-40.093182060082441, -7.958803511042906],
              [-40.087741509608271, -7.955441667807273],
              [-40.081889537349277, -7.951974545123866],
              [-40.076749772584719, -7.948846804845613],
              [-40.076918610612431, -7.948642333201],
              [-40.077599227316519, -7.947721872789693],
              [-40.077856962100917, -7.947445541388096],
              [-40.078022658064178, -7.947288920674861],
              [-40.078160589287911, -7.94709565303289],
              [-40.078280032786928, -7.94687484892709],
              [-40.078383968417505, -7.946690713489554],
              [-40.079129967933163, -7.94569374985049],
              [-40.079772318092125, -7.944817792691993],
              [-40.080346878190504, -7.944072335882971],
              [-40.080601968166164, -7.943271726097321],
              [-40.081022598945616, -7.941875044372579],
              [-40.081248596806752, -7.940947169738806],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0018000',
        uf: 'PE',
        nome_proje: 'PA SÍTIO BOQUEIRÃO',
        municipio: 'FLORES',
        area_hecta: '667.6087',
        capacidade: 23.0,
        num_famili: 23.0,
        fase: 5.0,
        data_de_cr: '30/09/1988',
        forma_obte: 'Compra e Venda',
        data_obten: '15/03/1988',
        area_calc_: 667.4848,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.760257780001176, -7.876255],
              [-37.759222780001174, -7.8773525],
              [-37.757980000001176, -7.878871939999986],
              [-37.757903890001174, -7.878949169999984],
              [-37.757370000001181, -7.879334169999989],
              [-37.756870830001183, -7.87978694],
              [-37.756780560001189, -7.879915],
              [-37.756344720001188, -7.880658889999976],
              [-37.755620560001184, -7.881627499999983],
              [-37.755565280001186, -7.881705],
              [-37.7546441700012, -7.883039719999981],
              [-37.754262500001197, -7.88337],
              [-37.752679170001201, -7.884305],
              [-37.75248056000121, -7.88478611],
              [-37.752346670001202, -7.884985829999986],
              [-37.751607780001208, -7.885843889999983],
              [-37.750541110001215, -7.886834719999984],
              [-37.74950833000122, -7.887369169999978],
              [-37.748729170001219, -7.888076389999979],
              [-37.747771940001229, -7.889494169999988],
              [-37.747964720001228, -7.890645],
              [-37.747996670001228, -7.892049719999976],
              [-37.747641110001233, -7.893285279999984],
              [-37.747223610001228, -7.893938889999977],
              [-37.746942500001239, -7.894324719999983],
              [-37.744570830001244, -7.897056669999976],
              [-37.744824170001245, -7.901107219999984],
              [-37.744835000001245, -7.901429169999981],
              [-37.745197500001247, -7.902521939999988],
              [-37.744776390001242, -7.903749439999987],
              [-37.744727220001238, -7.904254719999982],
              [-37.743855000001247, -7.904842779999976],
              [-37.74252639000126, -7.90570361],
              [-37.741306110001254, -7.906419439999985],
              [-37.741694720001256, -7.907510559999984],
              [-37.741796940001258, -7.907824169999976],
              [-37.742341940001261, -7.909322779999973],
              [-37.741634170001255, -7.909527499999981],
              [-37.741478330001257, -7.910657219999984],
              [-37.741475280001261, -7.911000559999979],
              [-37.742065830001252, -7.911764169999981],
              [-37.742508060001249, -7.91267028],
              [-37.742664720001251, -7.912708889999984],
              [-37.743490000001252, -7.912506939999979],
              [-37.743953610001249, -7.909910559999989],
              [-37.747825830001226, -7.910935559999979],
              [-37.749108610001223, -7.911115559999983],
              [-37.749921670001214, -7.911121939999986],
              [-37.749932780001217, -7.912126939999981],
              [-37.75111333000121, -7.912180279999988],
              [-37.751350830001208, -7.91207083],
              [-37.751795000001209, -7.911839719999973],
              [-37.752082500001208, -7.91153722],
              [-37.752209440001202, -7.911139439999989],
              [-37.752501940001203, -7.910626389999976],
              [-37.753094440001199, -7.910328059999981],
              [-37.753466110001199, -7.91013417],
              [-37.753757780001195, -7.910016669999982],
              [-37.753922780001197, -7.909899719999986],
              [-37.754232780001196, -7.909596389999989],
              [-37.756314720001178, -7.906886389999981],
              [-37.756543330001186, -7.906666669999976],
              [-37.758207500001177, -7.905259439999981],
              [-37.76279528000115, -7.898869439999983],
              [-37.763110830001153, -7.89864638999998],
              [-37.764492500001147, -7.89735528],
              [-37.765379440001141, -7.896517779999987],
              [-37.767465830001129, -7.894895],
              [-37.767897780001128, -7.894841389999986],
              [-37.769109720001133, -7.894685279999979],
              [-37.770333890001119, -7.89449889],
              [-37.771668060001119, -7.893166669999981],
              [-37.772959720001111, -7.891885],
              [-37.773666940001107, -7.891015559999978],
              [-37.773603890001105, -7.89095833],
              [-37.772985000001107, -7.890386389999979],
              [-37.771693610001115, -7.88945889],
              [-37.769931940001122, -7.888469719999982],
              [-37.769647220001133, -7.887949439999986],
              [-37.76959111000113, -7.887660559999984],
              [-37.769441390001127, -7.887527499999988],
              [-37.76935972000112, -7.887254439999984],
              [-37.769319440001127, -7.886801109999989],
              [-37.769004720001121, -7.886297779999981],
              [-37.768977500001128, -7.885833609999983],
              [-37.768854440001121, -7.885528609999987],
              [-37.768445830001127, -7.885002779999983],
              [-37.76810111000114, -7.884777779999986],
              [-37.76720083000113, -7.883356669999977],
              [-37.767018610001138, -7.882954169999982],
              [-37.766067220001133, -7.88235472],
              [-37.765741390001153, -7.88220332999998],
              [-37.765446940001141, -7.8819275],
              [-37.764828330001144, -7.881604169999978],
              [-37.764455830001147, -7.881414439999982],
              [-37.76321667000115, -7.88030278],
              [-37.761758890001161, -7.87889639],
              [-37.761700280001158, -7.87862277999998],
              [-37.761696940001166, -7.877951669999986],
              [-37.761516940001165, -7.877793609999983],
              [-37.761239170001168, -7.877442219999981],
              [-37.760823890001163, -7.876988059999976],
              [-37.760635830001164, -7.876767779999983],
              [-37.760257780001176, -7.876255],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0030000',
        uf: 'PE',
        nome_proje: 'PA OURO VERDE',
        municipio: 'LAGOA GRANDE',
        area_hecta: '539.0000',
        capacidade: 100.0,
        num_famili: 97.0,
        fase: 5.0,
        data_de_cr: '19/12/1997',
        forma_obte: 'Compra e Venda',
        data_obten: '12/03/1997',
        area_calc_: 530.8017,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.120034116738118, -9.03527615473879],
              [-40.114264437734825, -9.044354335889468],
              [-40.113729999850001, -9.045195733069271],
              [-40.113197018274334, -9.046038120079352],
              [-40.11266103164229, -9.046880334822291],
              [-40.112128676936159, -9.047721000630888],
              [-40.111595506677318, -9.048563477080364],
              [-40.111061419886958, -9.049404508836469],
              [-40.110528699275818, -9.050246802008171],
              [-40.109994060290582, -9.051087291782379],
              [-40.109461696969902, -9.051928949784857],
              [-40.108926600642079, -9.052770163367949],
              [-40.108394415710627, -9.05361218152194],
              [-40.108302782835665, -9.053754621777896],
              [-40.106986230823928, -9.055830547917679],
              [-40.106925200260712, -9.055924874714128],
              [-40.105958951113855, -9.057451887177445],
              [-40.105287403796986, -9.058512089544875],
              [-40.104747596400124, -9.059365793187652],
              [-40.104248242659942, -9.060150191819197],
              [-40.103530601624271, -9.061283873484619],
              [-40.103015229586454, -9.062097800944274],
              [-40.102346123263949, -9.063158263606443],
              [-40.105450962182104, -9.065497750715135],
              [-40.105686337500394, -9.065679083892011],
              [-40.106169277436059, -9.065981393059305],
              [-40.107351351590665, -9.066563469551394],
              [-40.107909675874971, -9.066763356980177],
              [-40.109307054436591, -9.067121039583938],
              [-40.109720691056694, -9.067222337254199],
              [-40.112772523540833, -9.067982388342891],
              [-40.112977387627993, -9.068033402846833],
              [-40.113752048510015, -9.068226653541423],
              [-40.114500847702864, -9.068412203976576],
              [-40.115262399218999, -9.068602416468527],
              [-40.116099785929144, -9.068749077854189],
              [-40.117165959738287, -9.068738768148949],
              [-40.11766445834607, -9.066890399430386],
              [-40.118877402994372, -9.06250922869423],
              [-40.119156884022445, -9.061507539878161],
              [-40.119431553463443, -9.060509573493658],
              [-40.119671892967894, -9.059648900560125],
              [-40.119945065594116, -9.058638368267788],
              [-40.12022064251245, -9.057670151691033],
              [-40.120492719109265, -9.056688380632604],
              [-40.120761888319862, -9.055708246208106],
              [-40.121038755468128, -9.054717145619987],
              [-40.121313626656445, -9.053728221478876],
              [-40.121552927161375, -9.052857513065007],
              [-40.121807482186362, -9.051860150189833],
              [-40.122104488133544, -9.050881196213549],
              [-40.12234505003218, -9.050007499413105],
              [-40.12548728049736, -9.038707719667949],
              [-40.120034116738118, -9.03527615473879],
            ],
          ],
          [
            [
              [-40.102154436675228, -9.06345935520674],
              [-40.101374917614137, -9.064682931516495],
              [-40.096058566390013, -9.073079756993137],
              [-40.093189580452531, -9.077607104365228],
              [-40.093118777102191, -9.077721354487457],
              [-40.096504051605748, -9.079410928753864],
              [-40.096574945056602, -9.079296677799954],
              [-40.099312618145454, -9.074981862230489],
              [-40.099609186966688, -9.074522014308942],
              [-40.099562326995589, -9.07437140082231],
              [-40.104384773077953, -9.066960132054252],
              [-40.10515536389169, -9.065734858807053],
              [-40.102154436675228, -9.06345935520674],
            ],
          ],
          [
            [
              [-40.10915540504044, -9.067452127556747],
              [-40.104791235842292, -9.076732157181072],
              [-40.10486979559662, -9.076775328143899],
              [-40.105267887676355, -9.076996411853065],
              [-40.107281658818046, -9.072618083038957],
              [-40.109585603167012, -9.067554279452112],
              [-40.109242721202584, -9.06747347653717],
              [-40.10915540504044, -9.067452127556747],
            ],
          ],
          [
            [
              [-40.104710848416602, -9.076894458784103],
              [-40.103950143920379, -9.078413886739925],
              [-40.101916118167303, -9.082408461414273],
              [-40.102622289698054, -9.08274844321245],
              [-40.104505498103045, -9.078648241832655],
              [-40.105193516081549, -9.077162222284509],
              [-40.104710848416602, -9.076894458784103],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0056000',
        uf: 'PE',
        nome_proje: 'PA ÁGUA VIVA',
        municipio: 'PETROLINA',
        area_hecta: '2343.2716',
        capacidade: 190.0,
        num_famili: 172.0,
        fase: 4.0,
        data_de_cr: '13/08/2001',
        forma_obte: 'Desapropriação',
        data_obten: '08/11/2000',
        area_calc_: 2336.2508,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.655174681531747, -9.274481797132976],
              [-40.656358345302607, -9.277145154488066],
              [-40.657131003806001, -9.281474926825666],
              [-40.658229818707376, -9.286233730033066],
              [-40.659251941966026, -9.290643702518601],
              [-40.660317025536258, -9.295084750036731],
              [-40.661352656167089, -9.299487500180682],
              [-40.662113421548973, -9.302807465672798],
              [-40.667504169821292, -9.301738939677607],
              [-40.670312765024107, -9.300928448621878],
              [-40.671695227674164, -9.300747024957856],
              [-40.673200393111394, -9.30081673050986],
              [-40.673446856139115, -9.300831848998962],
              [-40.674139643823871, -9.302274572962855],
              [-40.676300211596342, -9.306749987973228],
              [-40.6777858763637, -9.309882673295251],
              [-40.680131167443086, -9.314781574196255],
              [-40.682411846063872, -9.319572890366562],
              [-40.684083946694251, -9.323081576778549],
              [-40.684822462448039, -9.324737962525097],
              [-40.685733054688356, -9.327424049616454],
              [-40.686503789192919, -9.328829020285607],
              [-40.685208293505035, -9.329491823679881],
              [-40.685076541225563, -9.330109873430905],
              [-40.683665540126491, -9.33056735064301],
              [-40.683629941515562, -9.332140513466829],
              [-40.684027387207486, -9.332409959200641],
              [-40.685183277567255, -9.335389174292612],
              [-40.68912031731378, -9.334211408105686],
              [-40.689094701198378, -9.333976819953646],
              [-40.692781349429239, -9.330924882450946],
              [-40.693563260477539, -9.330262599920752],
              [-40.697044642579371, -9.327339631023724],
              [-40.698549356584834, -9.329589941506324],
              [-40.699538420173404, -9.331125289949],
              [-40.70395089835101, -9.329824314181183],
              [-40.707904705330606, -9.328626842386917],
              [-40.711789404170389, -9.327450910305226],
              [-40.712567493444965, -9.327190941156539],
              [-40.717046936147206, -9.325816174012148],
              [-40.718031003901004, -9.32142550331676],
              [-40.718370212465096, -9.31689572839346],
              [-40.718621120087136, -9.313331696513128],
              [-40.716912686752202, -9.31020719212964],
              [-40.714190467475071, -9.306607104223835],
              [-40.711460136922028, -9.302992739014657],
              [-40.710060008058839, -9.301159673745463],
              [-40.704704422581557, -9.29612876367057],
              [-40.701333504272149, -9.292416716764301],
              [-40.698039118567962, -9.288800549851366],
              [-40.694939149278426, -9.285380064356273],
              [-40.69208964325599, -9.28226765570729],
              [-40.689995100965071, -9.28209095250566],
              [-40.689507060569575, -9.281991545400089],
              [-40.688582576983798, -9.281144409428491],
              [-40.688284346032873, -9.280996999010116],
              [-40.68781610075532, -9.281020818301759],
              [-40.687359498611428, -9.281100366512989],
              [-40.686374794853478, -9.281457982686129],
              [-40.684559072730863, -9.281613914999953],
              [-40.678479796010329, -9.28158590940728],
              [-40.677993552948472, -9.281538826477711],
              [-40.676297852165348, -9.281231692060627],
              [-40.671806853951374, -9.280414499962223],
              [-40.667318993741127, -9.27958484651921],
              [-40.666390978775546, -9.277747723202467],
              [-40.666069210846786, -9.277297426511552],
              [-40.665716396458592, -9.276998987653142],
              [-40.66367903070833, -9.276030828435863],
              [-40.663609049366727, -9.275993993941215],
              [-40.66238420508769, -9.27543831017992],
              [-40.659946714027072, -9.275099546659025],
              [-40.655174681531747, -9.274481797132976],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0100000',
        uf: 'PE',
        nome_proje: 'PA ESPERANÇA',
        municipio: 'PETROLINA',
        area_hecta: '959.7000',
        capacidade: 26.0,
        num_famili: 23.0,
        fase: 3.0,
        data_de_cr: '21/10/2004',
        forma_obte: 'Desapropriação',
        data_obten: '07/04/2004',
        area_calc_: 979.2976,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.725330839964919, -8.881422859343083],
              [-40.721878762222779, -8.87895309238813],
              [-40.716934363611429, -8.884597930006526],
              [-40.716781916514073, -8.884757125639121],
              [-40.716733346917096, -8.884791434892611],
              [-40.716613495449423, -8.884941619965208],
              [-40.716485626864973, -8.885107754591145],
              [-40.716398488059674, -8.885235004997199],
              [-40.716320004684015, -8.885366284137278],
              [-40.716280000282453, -8.885463390911999],
              [-40.716237674214554, -8.885589078855132],
              [-40.716139577147793, -8.885901274105871],
              [-40.716030628853325, -8.886128350316541],
              [-40.716007027181448, -8.886175112008045],
              [-40.715996472176798, -8.886192700692543],
              [-40.715892572874992, -8.886332595584989],
              [-40.71579436226186, -8.886444617098839],
              [-40.715669756892893, -8.886589308712345],
              [-40.714975727422107, -8.88743053947813],
              [-40.714319605903121, -8.888213097965554],
              [-40.714037356386683, -8.888573245066317],
              [-40.713780098468902, -8.888911849263071],
              [-40.713529052667816, -8.889217876115469],
              [-40.713293465600273, -8.889544265161156],
              [-40.713018201807571, -8.889901667335584],
              [-40.712571008923447, -8.890452710524636],
              [-40.712304507549405, -8.890817847668663],
              [-40.711985707167322, -8.891236839934443],
              [-40.711712287568808, -8.891619910428732],
              [-40.711529168191333, -8.891870743764208],
              [-40.711307873717772, -8.892143180283222],
              [-40.711162447768295, -8.892327567596087],
              [-40.710983067619189, -8.892522327474948],
              [-40.710772568551704, -8.892809451582488],
              [-40.710488699034215, -8.893174577360469],
              [-40.710222932197979, -8.893502912179075],
              [-40.709746922012769, -8.89411511463522],
              [-40.709460093627364, -8.894489566115576],
              [-40.708900852147281, -8.895190935645772],
              [-40.707524300431409, -8.896917903122405],
              [-40.704610338056483, -8.900531241500707],
              [-40.703530448814298, -8.90177484841924],
              [-40.703297541292471, -8.90205502050925],
              [-40.703037852287707, -8.902324103783899],
              [-40.702772363784476, -8.902617082627229],
              [-40.702573344783659, -8.902833357922804],
              [-40.702388056321084, -8.903010782969778],
              [-40.702188188663953, -8.903161602657663],
              [-40.700450328456341, -8.904232719741191],
              [-40.700439079459485, -8.904238105541658],
              [-40.70009801906933, -8.904468771793251],
              [-40.700085145255471, -8.904476696588169],
              [-40.699957152766764, -8.904618959372771],
              [-40.694569879294981, -8.912517577992434],
              [-40.711726875119943, -8.91848408965819],
              [-40.708424473910505, -8.927113849877731],
              [-40.713433725050677, -8.930307109431217],
              [-40.714987423537252, -8.931062564045856],
              [-40.719967826739271, -8.932619561437713],
              [-40.720161111186698, -8.932072121503351],
              [-40.722693690531266, -8.93255031584763],
              [-40.723106290791094, -8.931521224392533],
              [-40.73055654208779, -8.927517912329343],
              [-40.733170130758673, -8.920991173420793],
              [-40.73350413903627, -8.918902725398148],
              [-40.726870137894345, -8.915580587738729],
              [-40.715940019883988, -8.91268764002376],
              [-40.715056218577544, -8.912440731856314],
              [-40.715756834204065, -8.910216779455734],
              [-40.718347168026796, -8.902128521295579],
              [-40.718794389826627, -8.900746125188],
              [-40.721590606467615, -8.892028276394983],
              [-40.721964887083374, -8.890877221548646],
              [-40.722227621910527, -8.890136341761471],
              [-40.725112455172756, -8.881983522868632],
              [-40.725330839964919, -8.881422859343083],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0004000',
        uf: 'PE',
        nome_proje: 'PA POÇO DO ANGICO',
        municipio: 'PETROLINA',
        area_hecta: '1495.2139',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 7.0,
        data_de_cr: '12/09/1989',
        forma_obte: 'Adjudicação',
        data_obten: '12/09/1989',
        area_calc_: 1495.6783,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.312952243417975, -8.897974107125592],
              [-40.30353269343609, -8.897785281069822],
              [-40.301397180604631, -8.911478419950488],
              [-40.289225833025945, -8.912185290775094],
              [-40.288644580940847, -8.915951625085233],
              [-40.289721441437273, -8.925710679866741],
              [-40.295363402391146, -8.928868370466942],
              [-40.303164248858387, -8.933085630807392],
              [-40.303618047443514, -8.933341850163258],
              [-40.303667599629875, -8.933364373768237],
              [-40.313450363774209, -8.937790994427228],
              [-40.316806008327362, -8.937554830802762],
              [-40.32061257735139, -8.937280227494742],
              [-40.324188764999249, -8.936976295876322],
              [-40.327540131168327, -8.936671417248988],
              [-40.330988414035303, -8.936336862576855],
              [-40.334346263756025, -8.935994915670493],
              [-40.337358132015439, -8.935719023695839],
              [-40.338573433274064, -8.935585689622581],
              [-40.338871718754689, -8.934514398604989],
              [-40.339199450155022, -8.933413431321592],
              [-40.339531952366684, -8.932448902461884],
              [-40.339921439372958, -8.931450438142475],
              [-40.340934363350101, -8.929046733174067],
              [-40.341647531178474, -8.927610241616362],
              [-40.342030031499142, -8.927190720722995],
              [-40.34206701129537, -8.927157038315153],
              [-40.342745184496287, -8.926473031661041],
              [-40.33609375177376, -8.921621235759295],
              [-40.335417580780629, -8.921131828636463],
              [-40.335360235361321, -8.921091703832236],
              [-40.335347380500117, -8.921082707227599],
              [-40.327427668333193, -8.915401259689402],
              [-40.325584897717285, -8.914078898536399],
              [-40.324774569046426, -8.913498794204264],
              [-40.323370181951333, -8.912495245632385],
              [-40.320411498099055, -8.91036721565351],
              [-40.314783484790894, -8.906313150819267],
              [-40.314680927015189, -8.906368584383834],
              [-40.31463056745249, -8.906373645469058],
              [-40.313798773975321, -8.899795526283341],
              [-40.313586819854635, -8.899339517325188],
              [-40.312952243417975, -8.897974107125592],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0342000',
        uf: 'PE',
        nome_proje: 'PA LAGEDO DO MOCOTÓ',
        municipio: 'AGUAS BELAS',
        area_hecta: '1072.5319',
        capacidade: 38.0,
        num_famili: 33.0,
        fase: 4.0,
        data_de_cr: '14/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2004',
        area_calc_: 1072.3374,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.936404047789459, -8.89986341323384],
              [-36.936224491604278, -8.899917468714129],
              [-36.93606743953584, -8.900010880515957],
              [-36.936024622138419, -8.900043999059218],
              [-36.937549950697658, -8.903021947887005],
              [-36.938960656459983, -8.905759621658472],
              [-36.941295029736956, -8.910510911544163],
              [-36.942141245760503, -8.912198494127727],
              [-36.944517666352581, -8.915230321751469],
              [-36.946777592551008, -8.918602291534045],
              [-36.94235151944941, -8.92031258871291],
              [-36.937329814928034, -8.922539380422341],
              [-36.937259277530195, -8.922570898216408],
              [-36.941087838422121, -8.926508266538381],
              [-36.93031267206829, -8.933779111187794],
              [-36.929175306057601, -8.934540306495499],
              [-36.928894905727212, -8.934660412327922],
              [-36.928591165333003, -8.934872951970283],
              [-36.928237533851188, -8.935001958833988],
              [-36.927307451211597, -8.935341256578484],
              [-36.926251087315258, -8.935794737225232],
              [-36.925347093573116, -8.936181993911045],
              [-36.923353935432729, -8.937451728925749],
              [-36.920464690273896, -8.939393478975681],
              [-36.921932372452389, -8.94492834271759],
              [-36.921940618945889, -8.945013088663503],
              [-36.922359063924155, -8.946393320065789],
              [-36.92257223742164, -8.947340585328387],
              [-36.922651834304247, -8.947652169918731],
              [-36.923562146004144, -8.951077355200582],
              [-36.924044265747511, -8.954840113842819],
              [-36.925597329300793, -8.956553667115916],
              [-36.926498062602683, -8.95875576031534],
              [-36.925932022485163, -8.960970059206833],
              [-36.927916718076993, -8.961330741759433],
              [-36.927677908145192, -8.95994762878103],
              [-36.928069673727705, -8.956542390889153],
              [-36.928734886109204, -8.956300964634243],
              [-36.930393176719228, -8.955699174277136],
              [-36.9322223651698, -8.95503550568756],
              [-36.932682508711984, -8.954868495453963],
              [-36.934243546160957, -8.954808331864912],
              [-36.935040660389149, -8.954777616996774],
              [-36.935811073635193, -8.955161032909684],
              [-36.938111896515963, -8.956306514487808],
              [-36.938846693703731, -8.956526020869505],
              [-36.939808121183709, -8.956813319085651],
              [-36.93987818812608, -8.956834319411067],
              [-36.943181262435814, -8.95783638255369],
              [-36.943884139199703, -8.958059047396725],
              [-36.945152133394863, -8.95846076080956],
              [-36.946261389111903, -8.957973088722785],
              [-36.947676915360155, -8.956159738506857],
              [-36.955334270521917, -8.951712057264718],
              [-36.955342735540455, -8.95136850756341],
              [-36.955310807800331, -8.95125768611025],
              [-36.95531207594447, -8.951193421322795],
              [-36.955193210233013, -8.950859292743443],
              [-36.955119340683076, -8.950705210984884],
              [-36.954923494222982, -8.950383403077231],
              [-36.954650933810143, -8.950075001989481],
              [-36.954430384230164, -8.949878979543376],
              [-36.954323867896377, -8.949775429683582],
              [-36.954188186848533, -8.949619831200167],
              [-36.954080116677851, -8.94946899546464],
              [-36.953968958666991, -8.949333871604006],
              [-36.953852431007775, -8.94919980280995],
              [-36.953749189723652, -8.949128452024738],
              [-36.953669975259359, -8.949101256974647],
              [-36.953624443775965, -8.949082202929111],
              [-36.953481854294672, -8.949056646316267],
              [-36.953069839155035, -8.94897545304938],
              [-36.952849492983788, -8.948938438034729],
              [-36.952616310459895, -8.948904606188488],
              [-36.95238654060644, -8.948878295958671],
              [-36.952152565624388, -8.9488723918722],
              [-36.951926865066291, -8.948849990907469],
              [-36.951687774470805, -8.9488484876494],
              [-36.951361581252065, -8.948864807573594],
              [-36.951265732212633, -8.948869746246888],
              [-36.951061710440939, -8.948872414034339],
              [-36.95083701449456, -8.948865475710358],
              [-36.950643530328463, -8.94885337662163],
              [-36.950544499168366, -8.948842116596293],
              [-36.950445468020298, -8.948830856545779],
              [-36.950330941754274, -8.94881051629252],
              [-36.950133154226442, -8.94870646033138],
              [-36.949860040165596, -8.948546664880787],
              [-36.949670512017775, -8.948445095150237],
              [-36.949118281522395, -8.948126103114985],
              [-36.948723256710828, -8.947884998630974],
              [-36.94832823247966, -8.947643893839256],
              [-36.948214522509119, -8.947591557387325],
              [-36.947857068258365, -8.947427496922085],
              [-36.947885502869759, -8.947317642612111],
              [-36.94762513033622, -8.946961124592443],
              [-36.947011908379778, -8.946122196645415],
              [-36.946040883111358, -8.944047652259012],
              [-36.945737053350832, -8.942897759677409],
              [-36.945714276741285, -8.942809226262264],
              [-36.945566510782442, -8.942232133517834],
              [-36.943788172454333, -8.940474377686984],
              [-36.94346465880129, -8.940128174973081],
              [-36.944341832105053, -8.937764038502936],
              [-36.944898685016085, -8.936184746720295],
              [-36.945512531385759, -8.935595151454951],
              [-36.94652271662553, -8.934505871366648],
              [-36.946896143242043, -8.934169315590516],
              [-36.947904617150208, -8.933351931608682],
              [-36.948701349434458, -8.931301365168171],
              [-36.947234332048133, -8.929407861922757],
              [-36.94686391892121, -8.928930781818636],
              [-36.943475365065915, -8.924566692778617],
              [-36.944107442874085, -8.924240158224253],
              [-36.949613919847756, -8.921383897834893],
              [-36.950265417752455, -8.921045434835779],
              [-36.951372978427628, -8.920447517824078],
              [-36.952496856899614, -8.919840827799812],
              [-36.952563426629162, -8.919803318671859],
              [-36.95408357029897, -8.918932950929786],
              [-36.955899703873548, -8.917701261967238],
              [-36.956823448447054, -8.917003509651458],
              [-36.954346330808335, -8.914369107198452],
              [-36.952759636091059, -8.912682705057408],
              [-36.952704878138988, -8.912623555471859],
              [-36.950344052868452, -8.910092378688265],
              [-36.947406373167333, -8.907386348703731],
              [-36.945918225589075, -8.90611611497615],
              [-36.945443197420687, -8.90243292838198],
              [-36.945168486663427, -8.899747916192064],
              [-36.945088123277905, -8.899749370915428],
              [-36.944922296780412, -8.899770240958668],
              [-36.944747844735616, -8.899805617055639],
              [-36.944547680989686, -8.899838681594986],
              [-36.944314612499433, -8.899839021773222],
              [-36.94410402983835, -8.899817067793151],
              [-36.943961897036409, -8.899794678190613],
              [-36.943833869391888, -8.899753202508316],
              [-36.943724048433559, -8.899739488507281],
              [-36.943564303993028, -8.899745566593229],
              [-36.943381736906964, -8.899736603201383],
              [-36.943170015738254, -8.899739501297809],
              [-36.942942633593638, -8.899747103716843],
              [-36.941854069671969, -8.89977100805371],
              [-36.941606486806513, -8.89979865622629],
              [-36.941384045998809, -8.899816680514808],
              [-36.941174691561962, -8.899786505691305],
              [-36.940962389969854, -8.899779455774308],
              [-36.940702671635052, -8.899782810142847],
              [-36.940455753064974, -8.899789218202248],
              [-36.940210199995086, -8.899795272068104],
              [-36.939955313947429, -8.899796031171775],
              [-36.939724391234101, -8.899802888840114],
              [-36.939505671275917, -8.899822017323901],
              [-36.9392888992884, -8.899817924766921],
              [-36.939051574101477, -8.899815616607277],
              [-36.938825990792218, -8.899810480648483],
              [-36.93859735226237, -8.899815271156871],
              [-36.938374191336948, -8.899815843263728],
              [-36.938122422134718, -8.899828189006053],
              [-36.937909709559534, -8.899845903471874],
              [-36.937691605737399, -8.899836198079356],
              [-36.937047264474998, -8.899828469751764],
              [-36.936602764149924, -8.899877712485232],
              [-36.936404047789459, -8.89986341323384],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0063000',
        uf: 'PE',
        nome_proje: 'PA ALTO DA AREIA',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1467.8144',
        capacidade: 120.0,
        num_famili: 114.0,
        fase: 4.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2001',
        area_calc_: 1467.2749,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.244284905596153, -9.006055588327653],
              [-40.243387600307699, -9.005026442517707],
              [-40.242936013713013, -9.004584403979489],
              [-40.242828356867975, -9.004463318742816],
              [-40.242474574646764, -9.004080362393118],
              [-40.242305124535498, -9.003896907295013],
              [-40.241773815945905, -9.003308999550507],
              [-40.241092676729558, -9.002555202863569],
              [-40.240615424550136, -9.002027066441634],
              [-40.240508769813275, -9.001905976933067],
              [-40.239314556205343, -9.000038256625764],
              [-40.238127671995379, -8.998182171626151],
              [-40.23803979087225, -8.998044288286831],
              [-40.236907587473574, -8.996268678289089],
              [-40.23574317680405, -8.99443366748342],
              [-40.235548574437324, -8.994126492218747],
              [-40.233522438509915, -8.99094801914619],
              [-40.231078692353734, -8.987131483884745],
              [-40.229785663961835, -8.985141694592487],
              [-40.228444880318456, -8.983038475799644],
              [-40.226781414777555, -8.98042511317513],
              [-40.226442719269329, -8.979863845016974],
              [-40.224923223110139, -8.977562884892349],
              [-40.223902637765541, -8.975968794578451],
              [-40.221413804668465, -8.972150506782786],
              [-40.217908250160249, -8.975065844875857],
              [-40.213858959368956, -8.978298009265997],
              [-40.210213577278239, -8.9811870711564],
              [-40.208060820831271, -8.982670969796953],
              [-40.206677766696544, -8.983534509396263],
              [-40.204609538547892, -8.984898382651636],
              [-40.202179443449523, -8.98636660881246],
              [-40.200474124244565, -8.987377317322228],
              [-40.203299693500426, -8.991908802630764],
              [-40.205931610162359, -8.995648976080673],
              [-40.206768198127023, -8.996727895678001],
              [-40.209522678441743, -9.000347990447057],
              [-40.21225418194426, -9.00390736232332],
              [-40.215035409846578, -9.007522157776974],
              [-40.218085632705396, -9.005555858232929],
              [-40.218293409006272, -9.00586019669308],
              [-40.2202441695893, -9.008837978179294],
              [-40.220845353657815, -9.009328565035682],
              [-40.224435834837266, -9.012134027386944],
              [-40.228017773258273, -9.014925910896213],
              [-40.231248689505591, -9.017410105110404],
              [-40.230984755367096, -9.02179943343212],
              [-40.231583364258732, -9.026267077635676],
              [-40.231606137379835, -9.026437192674234],
              [-40.232894491625991, -9.030664412224118],
              [-40.233264590535761, -9.031055266159802],
              [-40.23462898361101, -9.029809979078497],
              [-40.235268717603844, -9.029423314874091],
              [-40.235947229668454, -9.029518515906933],
              [-40.235965454044567, -9.029507784130693],
              [-40.240160203819372, -9.030134524304653],
              [-40.240334837215414, -9.027439095460082],
              [-40.240833889797173, -9.025844561113692],
              [-40.241423059969847, -9.022007127925743],
              [-40.241618509067365, -9.021895103378991],
              [-40.241642312223497, -9.021881411805508],
              [-40.241642375803266, -9.021881423794442],
              [-40.241679535350443, -9.021860125227059],
              [-40.241955979306994, -9.021913178292547],
              [-40.24652545503389, -9.02278890155975],
              [-40.247011314787677, -9.022871896164409],
              [-40.24734737776631, -9.019995151553665],
              [-40.247470375255219, -9.019654534054977],
              [-40.248230141633222, -9.016776532174944],
              [-40.248394412684846, -9.015952694017013],
              [-40.24851271153976, -9.015198100925552],
              [-40.24856034060506, -9.014234310393164],
              [-40.248500800871618, -9.013054483075623],
              [-40.248348682533027, -9.012092636361775],
              [-40.247622878386863, -9.012062995644586],
              [-40.247354581622602, -9.011041676932209],
              [-40.247226248148543, -9.010542120672534],
              [-40.246942595682391, -9.009869825440505],
              [-40.246453608763943, -9.008762982274003],
              [-40.246235478546922, -9.00847009239917],
              [-40.245636464193844, -9.00758508499432],
              [-40.24533369384357, -9.007251877398506],
              [-40.244284905596153, -9.006055588327653],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0257000',
        uf: 'PE',
        nome_proje: 'PA MATA ESCURA',
        municipio: 'AGUAS BELAS',
        area_hecta: '316.3925',
        capacidade: 19.0,
        num_famili: 11.0,
        fase: 4.0,
        data_de_cr: '03/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '06/08/2001',
        area_calc_: 315.6923,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.963994853050316, -9.219369214779176],
              [-36.962890813301492, -9.220413181401653],
              [-36.96249526403281, -9.220787167696002],
              [-36.962056535484969, -9.221202129115103],
              [-36.963925728242721, -9.2229517446873],
              [-36.9649015180891, -9.22366488469083],
              [-36.965241204794758, -9.224100706035294],
              [-36.965356019662501, -9.225149222471453],
              [-36.965419855371167, -9.225694850466013],
              [-36.965389385815627, -9.225812192442316],
              [-36.965358916231992, -9.225929534410934],
              [-36.964888034552729, -9.226841763884044],
              [-36.96430710407995, -9.227667041199922],
              [-36.963836197488575, -9.228947268463926],
              [-36.962611224256669, -9.228973683000712],
              [-36.961789164737013, -9.228850965881863],
              [-36.961495875738862, -9.228859517348074],
              [-36.961305752445206, -9.228679727232249],
              [-36.960944628866415, -9.228301543436928],
              [-36.960086375610153, -9.227780606643119],
              [-36.959068281137604, -9.227621246215785],
              [-36.958164914083419, -9.227415077208832],
              [-36.956669005952868, -9.227061719967782],
              [-36.95511921822861, -9.226806942930525],
              [-36.954514817835488, -9.226870577828871],
              [-36.953424822072918, -9.226985232704445],
              [-36.953161267744939, -9.227012929864351],
              [-36.955271474064084, -9.229878441117831],
              [-36.955311820314357, -9.229981901890946],
              [-36.957034372590044, -9.232326606124875],
              [-36.957597813727453, -9.233547252349354],
              [-36.955921901357534, -9.235042980780374],
              [-36.957100860525721, -9.237415239612826],
              [-36.956544129258759, -9.237776726194832],
              [-36.956177555865885, -9.238453959500896],
              [-36.955116667664008, -9.240534599395582],
              [-36.952937958804135, -9.242400951349897],
              [-36.955396526699467, -9.244068652176846],
              [-36.957721636703212, -9.245610835909652],
              [-36.959419633088494, -9.247018821061324],
              [-36.962082063995346, -9.244942837597259],
              [-36.96231404781156, -9.244695838750046],
              [-36.962539777241886, -9.244507922795824],
              [-36.96293730873662, -9.24431691412483],
              [-36.963419944511223, -9.244009687310399],
              [-36.963594757309217, -9.243926069126763],
              [-36.964720204052973, -9.243162102564279],
              [-36.9667450604436, -9.241477666027407],
              [-36.966904473596699, -9.241302568514932],
              [-36.967673336095466, -9.240457925453658],
              [-36.968442625361504, -9.239696898457817],
              [-36.966936037709814, -9.238829972767743],
              [-36.965730404739666, -9.237985086289193],
              [-36.965669826129513, -9.237902663861977],
              [-36.964815849549858, -9.236821846139106],
              [-36.964729810195124, -9.23668929058595],
              [-36.964393487361676, -9.236014841340575],
              [-36.964274524677457, -9.235674008526253],
              [-36.96469163687869, -9.235461863556008],
              [-36.965178282151754, -9.23521440562125],
              [-36.970248652928504, -9.232635261158865],
              [-36.975401504205117, -9.229988787120362],
              [-36.974374286618165, -9.229176484605155],
              [-36.973555125067314, -9.228241871983673],
              [-36.973055331386234, -9.227671728268939],
              [-36.972834274146159, -9.227331406100905],
              [-36.972590209033655, -9.226909597672785],
              [-36.972535605062461, -9.226720904346283],
              [-36.971883622572584, -9.224522592115859],
              [-36.971834267168205, -9.224466358609083],
              [-36.971441546827307, -9.224457092576905],
              [-36.96941313327315, -9.224559814501374],
              [-36.968882250645756, -9.224575347498803],
              [-36.968552989565957, -9.224526662545621],
              [-36.968288689410734, -9.224335066917464],
              [-36.967965140475258, -9.223367992111752],
              [-36.967740560058452, -9.222913297697636],
              [-36.967087496176106, -9.222101649432062],
              [-36.966661095776772, -9.221682876341351],
              [-36.965097885525957, -9.220294417531926],
              [-36.963994853050316, -9.219369214779176],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0201000',
        uf: 'PE',
        nome_proje: 'PA COLINAS',
        municipio: 'GRANITO',
        area_hecta: '832.1935',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '27/11/2006',
        forma_obte: 'Desapropriação',
        data_obten: '27/11/2006',
        area_calc_: 832.7427,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.678091598792079, -7.897110620719611],
              [-39.675988963014717, -7.897550265995002],
              [-39.660259526392359, -7.90064447132613],
              [-39.661012636897567, -7.90176884738884],
              [-39.661238706875253, -7.902396849473178],
              [-39.661313611494727, -7.90574131883464],
              [-39.664457783207247, -7.910250077596594],
              [-39.664496926745549, -7.9142431291787],
              [-39.665055237945388, -7.914990083654686],
              [-39.665650352681368, -7.915321901752873],
              [-39.666157859639377, -7.915614331978347],
              [-39.667136629036335, -7.91639434971808],
              [-39.701331101653743, -7.906633381247047],
              [-39.702991065758582, -7.908940857868063],
              [-39.708672242707109, -7.907041673462089],
              [-39.701222967508414, -7.891307750566575],
              [-39.701172779766082, -7.890917543660604],
              [-39.695228536931694, -7.892665002359474],
              [-39.678091598792079, -7.897110620719611],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0213000',
        uf: 'PE',
        nome_proje: 'PA SÍTIO NOVO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.0894',
        capacidade: 20.0,
        num_famili: 18.0,
        fase: 3.0,
        data_de_cr: '03/01/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 1320.2235,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.13875974111015, -8.782928148123681],
              [-40.107727569174273, -8.738999931688967],
              [-40.056634227371937, -8.673363632626497],
              [-40.047463877038517, -8.665398674946797],
              [-40.040655700742164, -8.637279022499877],
              [-40.028116082119205, -8.608351823775344],
              [-40.027432305854894, -8.609642137786603],
              [-40.026763444901178, -8.61090491768992],
              [-40.026528102047301, -8.611348517180225],
              [-40.035818269782133, -8.638503823087794],
              [-40.043398222095881, -8.669566529816402],
              [-40.052349753203622, -8.677759456279666],
              [-40.10360543783365, -8.74255507444623],
              [-40.135678602020583, -8.786505380207219],
              [-40.136943406118725, -8.785031272552585],
              [-40.13875974111015, -8.782928148123681],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0297000',
        uf: 'PE',
        nome_proje: 'PA SERRA AZUL',
        municipio: 'PALMARES',
        area_hecta: '515.7983',
        capacidade: 39.0,
        num_famili: 39.0,
        fase: 4.0,
        data_de_cr: '25/08/2003',
        forma_obte: 'Desapropriação',
        data_obten: '08/11/2002',
        area_calc_: 528.0728,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.689551696522116, -8.541159711794126],
              [-35.686615787325358, -8.541412761205418],
              [-35.685575067703468, -8.541502566763038],
              [-35.684003732014403, -8.541637792985947],
              [-35.681979526405811, -8.541812202728282],
              [-35.680951319318673, -8.541900825511174],
              [-35.679470786639904, -8.542028538365901],
              [-35.678069992748597, -8.542149278935902],
              [-35.675536019387792, -8.542367572003771],
              [-35.672232099277295, -8.542652246099969],
              [-35.671209868627912, -8.542740531955873],
              [-35.668726864474856, -8.54295418354986],
              [-35.66414792041148, -8.543348690276106],
              [-35.661431009807025, -8.543582702091177],
              [-35.66010620865913, -8.543696804362357],
              [-35.659475786560769, -8.543751104149045],
              [-35.651700644710544, -8.544418341267745],
              [-35.651044994555271, -8.54447469835025],
              [-35.64793831251589, -8.544744631884484],
              [-35.647433404036839, -8.546939510164069],
              [-35.647069946972707, -8.548519602053801],
              [-35.64704852911003, -8.548612458549062],
              [-35.646880726552226, -8.549342184058757],
              [-35.649842810589298, -8.550643973015307],
              [-35.653743503390338, -8.552358204486829],
              [-35.653805876021721, -8.552385607469889],
              [-35.654052999614301, -8.55249424155444],
              [-35.654908461539755, -8.552870051456809],
              [-35.654975960804862, -8.552916756057314],
              [-35.660087524361458, -8.558061592379877],
              [-35.667268204795484, -8.556849910865671],
              [-35.66857269380796, -8.556629739374296],
              [-35.668841476670984, -8.556563096549642],
              [-35.670492412814205, -8.556153755515949],
              [-35.672387565756274, -8.555683806630075],
              [-35.673891231312574, -8.555310891716069],
              [-35.675030871794576, -8.555028318871196],
              [-35.675123684200919, -8.555007797885276],
              [-35.675980457260486, -8.554516734506691],
              [-35.677972653959706, -8.553375089504531],
              [-35.678427604033516, -8.553034360090731],
              [-35.679159785645687, -8.552486144921879],
              [-35.687552069019702, -8.546201009125685],
              [-35.687627170825181, -8.546140761174279],
              [-35.688970905786647, -8.545067195910505],
              [-35.689551696522116, -8.541159711794126],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0232000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DO CARMO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1263.0911',
        capacidade: 20.0,
        num_famili: 18.0,
        fase: 3.0,
        data_de_cr: '10/08/2007',
        forma_obte: 'Desapropriação',
        data_obten: '01/11/2006',
        area_calc_: 1263.2053,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.04097617184884, -8.662379749422691],
              [-40.041588276171915, -8.66215008654066],
              [-40.035818268377469, -8.638503824207804],
              [-40.026528100641457, -8.611348518295111],
              [-40.026211838085523, -8.611944633283789],
              [-40.025693959021716, -8.612922668181385],
              [-40.025269650082407, -8.613722378270024],
              [-40.025115586847924, -8.614013189470816],
              [-40.030980799399636, -8.639728567332007],
              [-40.039332476441231, -8.673734352228946],
              [-40.048065178286009, -8.68215524382914],
              [-40.099483225518725, -8.746110182518597],
              [-40.132586720886529, -8.790067434055944],
              [-40.135678600621631, -8.786505381356816],
              [-40.103605436433099, -8.742555075587031],
              [-40.052349751800612, -8.67775945740717],
              [-40.050383137125543, -8.675959457438911],
              [-40.048354860549892, -8.676923046436544],
              [-40.039681835592347, -8.668971631003444],
              [-40.038630701931247, -8.665345913489254],
              [-40.038618846945766, -8.661025693478685],
              [-40.039772794022014, -8.662482778275939],
              [-40.04097617184884, -8.662379749422691],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0311000',
        uf: 'PE',
        nome_proje: 'PA PORTELINHA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '153.1827',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '07/04/2010',
        area_calc_: 155.794,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.837118560914192, -8.666043460346101],
              [-39.836099392961927, -8.665908217582153],
              [-39.831252036633657, -8.737172648927352],
              [-39.810210765245024, -8.761317363751846],
              [-39.811107090308198, -8.762204954554248],
              [-39.832703683334152, -8.737277361988296],
              [-39.837118560914192, -8.666043460346101],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0228000',
        uf: 'PE',
        nome_proje: 'PA ABREU E LIMA',
        municipio: 'TACARATU',
        area_hecta: '18984.9334',
        capacidade: 400.0,
        num_famili: 302.0,
        fase: 3.0,
        data_de_cr: '26/04/2007',
        forma_obte: 'Desapropriação',
        data_obten: '18/11/2005',
        area_calc_: 18982.0244,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.074254715231035, -8.696844569178669],
              [-38.060369717822574, -8.711344281678651],
              [-38.057700902426582, -8.714128490371102],
              [-38.042638946381899, -8.7307150982433],
              [-38.027028978326165, -8.746690706656882],
              [-38.0218966983578, -8.752209938501151],
              [-38.006126172535176, -8.769260375072403],
              [-37.983130349291635, -8.794849639718947],
              [-37.98579286148523, -8.797206585829645],
              [-37.991311228314991, -8.791594327294048],
              [-38.028262089598485, -8.747820027289807],
              [-38.031949623083413, -8.751183430091324],
              [-38.003563066563828, -8.784929660665098],
              [-37.998772593836371, -8.790701881701819],
              [-37.996572089679354, -8.793435323636535],
              [-37.995522971568136, -8.794563642972093],
              [-37.994887332568794, -8.795153325065066],
              [-37.994366674009811, -8.795618145946378],
              [-37.993967881684625, -8.795933615762198],
              [-37.993550380073934, -8.796241619122029],
              [-37.98838467058016, -8.799531401829208],
              [-38.039406066732745, -8.845088678054637],
              [-38.056515102866371, -8.860344958103054],
              [-38.072943241502628, -8.874991772069224],
              [-38.087732881675215, -8.888112315426325],
              [-38.093016694984669, -8.881444991751746],
              [-38.098597107306389, -8.867818959348961],
              [-38.101625752087834, -8.867670151867015],
              [-38.104095804703498, -8.867545575613152],
              [-38.116962347014336, -8.851325981604212],
              [-38.154519135014361, -8.803968197068484],
              [-38.115908552709662, -8.75249658455912],
              [-38.109461929080517, -8.743892137443257],
              [-38.085573306557848, -8.711955440601487],
              [-38.074254715231035, -8.696844569178669],
            ],
            [
              [-38.064451942377922, -8.7284361510991],
              [-38.069651852521552, -8.73388243541825],
              [-38.020771183514952, -8.778356302641557],
              [-38.016875689716009, -8.774788295601608],
              [-38.035552058515684, -8.75447025530249],
              [-38.03767734487068, -8.756395161897069],
              [-38.057815229444941, -8.735346383817392],
              [-38.064451942377922, -8.7284361510991],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0023000',
        uf: 'PE',
        nome_proje: 'PA SÃO FRANCISCO',
        municipio: 'PETROLINA',
        area_hecta: '762.2066',
        capacidade: 60.0,
        num_famili: 58.0,
        fase: 6.0,
        data_de_cr: '30/12/1996',
        forma_obte: 'Compra e Venda',
        data_obten: '29/10/1996',
        area_calc_: 742.6464,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.241686607933694, -9.021884683302893],
              [-40.241541407060154, -9.022810006308356],
              [-40.241486793102553, -9.023172364862821],
              [-40.241253622160016, -9.024681442472014],
              [-40.241060511722608, -9.025935099505894],
              [-40.240810286906516, -9.027166070584924],
              [-40.240669173003674, -9.028489364254936],
              [-40.240458787511763, -9.029852681357896],
              [-40.240226581725665, -9.031352803039772],
              [-40.239962432630598, -9.033088784861185],
              [-40.239647822955504, -9.035125799310954],
              [-40.239657840045929, -9.035182103655323],
              [-40.240060472387654, -9.035423006461416],
              [-40.238124884333551, -9.048375300062926],
              [-40.239120835409409, -9.048691243452977],
              [-40.239618475096542, -9.048750962542103],
              [-40.240256022385211, -9.04880848923078],
              [-40.241365850560832, -9.049224056599002],
              [-40.242436479513067, -9.049615337002514],
              [-40.243650744033232, -9.050029728729212],
              [-40.244184717149551, -9.05017866302369],
              [-40.244911284433478, -9.050435006217393],
              [-40.245873962043426, -9.050860463285895],
              [-40.246776221656937, -9.051308188109777],
              [-40.247785050676981, -9.051714311268166],
              [-40.248324940864158, -9.05215349945072],
              [-40.248467984593489, -9.052295439342315],
              [-40.248770972560052, -9.051966868706705],
              [-40.249170201961597, -9.051451774166116],
              [-40.250828977020291, -9.048155907488589],
              [-40.250891193029624, -9.048021768224881],
              [-40.249427469429385, -9.047470526217612],
              [-40.24301351536981, -9.045109588949739],
              [-40.24318942287092, -9.04457057034891],
              [-40.243364839715774, -9.044021244326187],
              [-40.243533200545833, -9.04348360771545],
              [-40.243698831271004, -9.042945799477966],
              [-40.24386403221235, -9.04241540795773],
              [-40.244027601840806, -9.041886920982506],
              [-40.244190375652252, -9.041365218954544],
              [-40.2443480917808, -9.040854295267332],
              [-40.244446501917224, -9.040530763493917],
              [-40.244261175550925, -9.040259739684206],
              [-40.244112815621975, -9.040051168118842],
              [-40.242107812952753, -9.037122412972192],
              [-40.241506085596185, -9.03620224041142],
              [-40.241522950560231, -9.035548461934777],
              [-40.243064223000566, -9.032413810722142],
              [-40.245101581538172, -9.031233753214867],
              [-40.246518954034521, -9.030547822555866],
              [-40.246817532510384, -9.030475368793727],
              [-40.247738396066588, -9.030264431723312],
              [-40.248923444536565, -9.030005841520234],
              [-40.249031728993444, -9.029989195733922],
              [-40.249941391118547, -9.03001322911779],
              [-40.250935966976876, -9.030049359861096],
              [-40.251176044136855, -9.030053786771392],
              [-40.251506210752709, -9.030885070616698],
              [-40.25165319931908, -9.031276403122652],
              [-40.252157034543515, -9.031688673865284],
              [-40.252867363921368, -9.03231076019256],
              [-40.253409383941928, -9.032821286354752],
              [-40.253617916370452, -9.032910280778983],
              [-40.254437143330918, -9.033191703339657],
              [-40.257139023153258, -9.034282267519435],
              [-40.260235849729206, -9.035436834134568],
              [-40.260915599739022, -9.03534280319945],
              [-40.261927691776684, -9.035207025351212],
              [-40.263215745453323, -9.035009345337654],
              [-40.263255989051338, -9.035045740399166],
              [-40.263881803442011, -9.035737723671568],
              [-40.264518931078648, -9.036438528646967],
              [-40.2650937981712, -9.03707552268312],
              [-40.265219101561534, -9.037217336376168],
              [-40.264785446986885, -9.038440355060807],
              [-40.264530145110399, -9.039131846333305],
              [-40.264588031711746, -9.039244864663702],
              [-40.264876344902568, -9.039827774592652],
              [-40.264942339956349, -9.039918157466579],
              [-40.264963529727368, -9.039942500380988],
              [-40.265733511262269, -9.040260778565601],
              [-40.266574561846987, -9.040611092863811],
              [-40.267466972971754, -9.040976419662689],
              [-40.267641248607447, -9.041048341774772],
              [-40.268156055979254, -9.041571866877055],
              [-40.268827931059384, -9.042266392292204],
              [-40.270372143839161, -9.04250176867809],
              [-40.272246727233458, -9.042779940769979],
              [-40.274189576961497, -9.043068807587199],
              [-40.274667153756425, -9.04360809548478],
              [-40.276139148316112, -9.045316879729972],
              [-40.278196022422939, -9.04383390944621],
              [-40.276817235601079, -9.04263175540852],
              [-40.275136957622017, -9.041214607833661],
              [-40.274106736996984, -9.040232328348583],
              [-40.273374895148386, -9.039482045826576],
              [-40.272774173145159, -9.038766573921936],
              [-40.270708279779264, -9.036171538972212],
              [-40.266984640350856, -9.031470322071812],
              [-40.262604321197927, -9.025956448787435],
              [-40.262525350150298, -9.025848114087811],
              [-40.259182528900723, -9.025213828740547],
              [-40.257494255604499, -9.024886015833646],
              [-40.255011187454592, -9.024417488749185],
              [-40.251526557498423, -9.023753591703596],
              [-40.248856795437391, -9.023246048346659],
              [-40.247016792230809, -9.022888225433014],
              [-40.24652866552745, -9.022806776255473],
              [-40.241686607933694, -9.021884683302893],
            ],
          ],
          [
            [
              [-40.263062855549393, -9.025949808293953],
              [-40.263166097272943, -9.026079310377431],
              [-40.268033625306202, -9.032212382257109],
              [-40.272126564375519, -9.03736553726206],
              [-40.27290716089896, -9.038349775711557],
              [-40.273709037150958, -9.039304910171012],
              [-40.274570559488929, -9.040172842002415],
              [-40.275494477156428, -9.041036937034212],
              [-40.276496236903213, -9.041891986501204],
              [-40.277534159423105, -9.042733523036782],
              [-40.278505480528125, -9.043621949641523],
              [-40.281515841558871, -9.041461669751214],
              [-40.290348706524533, -9.035120395549498],
              [-40.28798671632682, -9.031140599500898],
              [-40.287679427737523, -9.030626504076221],
              [-40.285393086051222, -9.030188972506302],
              [-40.279874495496351, -9.029143448222625],
              [-40.277826035554995, -9.02875585895325],
              [-40.273348434121324, -9.027903290896321],
              [-40.269169059019241, -9.027110493966394],
              [-40.264794890909904, -9.026277895696742],
              [-40.263062855549393, -9.025949808293953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0120000',
        uf: 'PE',
        nome_proje: 'PA PROVINCIA DO UIGE',
        municipio: 'INAJA',
        area_hecta: '2828.0556',
        capacidade: 102.0,
        num_famili: 91.0,
        fase: 4.0,
        data_de_cr: '16/11/2004',
        forma_obte: 'Desapropriação',
        data_obten: '16/11/2004',
        area_calc_: 2830.4909,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.965170586398386, -8.818469174418627],
              [-37.96589534087029, -8.817163658568681],
              [-37.966746212814144, -8.815633107673866],
              [-37.96671503938606, -8.815618868466583],
              [-37.961772724554066, -8.81399392129866],
              [-37.961411208489821, -8.813587038842069],
              [-37.960873788780148, -8.813665771680453],
              [-37.958717965718641, -8.812891593772157],
              [-37.947134441567286, -8.808457807025095],
              [-37.938478209620072, -8.804860763741248],
              [-37.937651900814856, -8.805660516721819],
              [-37.932450420953948, -8.798978421666053],
              [-37.92637948255684, -8.815119774312009],
              [-37.906639437668908, -8.80538636442469],
              [-37.903308492320868, -8.81983287986767],
              [-37.903078529784715, -8.820533428282607],
              [-37.902693603881872, -8.821298964905614],
              [-37.902605568654238, -8.821451996459631],
              [-37.899672814174195, -8.826425649057995],
              [-37.899409065081471, -8.826788369607831],
              [-37.898972561070593, -8.827205719118691],
              [-37.916206603773496, -8.847548960944778],
              [-37.9222495749245, -8.854718517795229],
              [-37.924293231249258, -8.853015024989899],
              [-37.919268211310033, -8.865027536164996],
              [-37.915230773899083, -8.864116743922022],
              [-37.91261638158614, -8.870117187002306],
              [-37.913555094099209, -8.870491438094524],
              [-37.91352494527424, -8.870567262339735],
              [-37.913553564022628, -8.870575756118161],
              [-37.925230452146408, -8.875172324622996],
              [-37.926335477026036, -8.87556791596973],
              [-37.93416544199529, -8.87837262065046],
              [-37.9351800004385, -8.875189030603318],
              [-37.936428748817328, -8.87088017572872],
              [-37.936465030781619, -8.870728671126221],
              [-37.938747952311864, -8.862053568384059],
              [-37.934688269992051, -8.861025462279626],
              [-37.938948980824847, -8.84814797145402],
              [-37.935545587824421, -8.843545612302378],
              [-37.938709605435825, -8.840883067428631],
              [-37.939776815679849, -8.839984993615662],
              [-37.949264368918293, -8.831982758504308],
              [-37.953986763017198, -8.827962688741385],
              [-37.95903802576575, -8.823688344024397],
              [-37.963162603189815, -8.820176996100022],
              [-37.965170586398386, -8.818469174418627],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0024000',
        uf: 'PE',
        nome_proje: 'PA SAFRA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '3326.3768',
        capacidade: 220.0,
        num_famili: 203.0,
        fase: 5.0,
        data_de_cr: '02/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 3384.8781,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.935020983158552, -8.977273169335911],
              [-39.922381902636126, -8.986760614763011],
              [-39.922530598762712, -8.98829364871459],
              [-39.923664974884566, -8.991213368607621],
              [-39.924202493960173, -8.992252349318761],
              [-39.924502587685687, -8.992716004614673],
              [-39.924526784799546, -8.99278766258875],
              [-39.925707768845761, -8.99563553701174],
              [-39.926242519608742, -8.997047312293423],
              [-39.926269442428229, -8.997153149392911],
              [-39.926551326222075, -8.998465714022862],
              [-39.927962667063355, -9.000807983028608],
              [-39.928712903838964, -9.001990759481949],
              [-39.92969376398289, -9.003426905764261],
              [-39.930260387538119, -9.004341988371838],
              [-39.930788314514174, -9.004993536609215],
              [-39.945303364494102, -8.994095811057903],
              [-39.946973000475666, -8.992812264221524],
              [-39.94887965338485, -8.991377881724008],
              [-39.9465758402186, -8.989765033379561],
              [-39.946309183517798, -8.989575976418607],
              [-39.946070180239474, -8.989386757856641],
              [-39.945711402697206, -8.98906779475802],
              [-39.945647828578068, -8.989001846746262],
              [-39.944395626123807, -8.987714307154558],
              [-39.944314912700612, -8.98763357071304],
              [-39.943686195208912, -8.986979224092467],
              [-39.943339813530613, -8.986597732978806],
              [-39.943104032480399, -8.986316527168199],
              [-39.942796637172883, -8.985920013266258],
              [-39.94268278011733, -8.985756066530149],
              [-39.939084326471708, -8.980289864190873],
              [-39.938805253224594, -8.979937498699355],
              [-39.938596075298442, -8.97974014922238],
              [-39.938320287911857, -8.979533291889583],
              [-39.935020983158552, -8.977273169335911],
            ],
          ],
          [
            [
              [-40.047022502140521, -8.900192344597981],
              [-40.044550122932876, -8.90135751486352],
              [-40.034117921434301, -8.902281242727788],
              [-40.0220910812005, -8.911498030298123],
              [-40.018483489638776, -8.914252981031353],
              [-40.020631625364473, -8.916943305303457],
              [-40.017124812996052, -8.919987010966681],
              [-40.014818258953582, -8.917068472030021],
              [-40.011312865324797, -8.919788628316489],
              [-40.008454419809112, -8.922012305867197],
              [-39.983435116121797, -8.940859294260987],
              [-39.982710786371698, -8.941408548498865],
              [-39.981988360507593, -8.941956349474145],
              [-39.981266114566417, -8.942504239214141],
              [-39.980542411642922, -8.943052312488634],
              [-39.979820707451296, -8.943600379276489],
              [-39.97909617448682, -8.944145829625146],
              [-39.978373368615728, -8.9446917263998],
              [-39.977648830724206, -8.945236993473536],
              [-39.973610061725488, -8.948298123137739],
              [-39.972154533099093, -8.949368127554919],
              [-39.970057634688246, -8.950940024215617],
              [-39.968804660885631, -8.951879254963542],
              [-39.96808090938503, -8.952423418746694],
              [-39.967352880760487, -8.952967592496725],
              [-39.966629034340301, -8.953511934980659],
              [-39.965902368220952, -8.954057097561924],
              [-39.965176337277718, -8.954602528584267],
              [-39.964453398994976, -8.955148854719878],
              [-39.963726183308161, -8.955695100348009],
              [-39.96300360749273, -8.95624259882614],
              [-39.962279117698905, -8.95678946800391],
              [-39.961549710209759, -8.95733490172482],
              [-39.960815195018526, -8.957875825530811],
              [-39.960096718062758, -8.958428915728986],
              [-39.95936676071836, -8.958975342022571],
              [-39.958642979587836, -8.959519218788735],
              [-39.957916924434798, -8.960063914182051],
              [-39.957194049624135, -8.9606082383703],
              [-39.956467534758936, -8.961152751639638],
              [-39.955744384763193, -8.961697888065661],
              [-39.955018680760219, -8.962241130656007],
              [-39.954294887255166, -8.962785361926313],
              [-39.953571457015798, -8.963330224120323],
              [-39.952845204840663, -8.963875092373399],
              [-39.952120313167534, -8.964419322827196],
              [-39.951391512512032, -8.964965280464321],
              [-39.950669245491611, -8.965506155478142],
              [-39.94994535381219, -8.966052731123517],
              [-39.949220544673544, -8.966597137369899],
              [-39.948495733311127, -8.967141542403356],
              [-39.947771920046399, -8.967685853219612],
              [-39.947045021069592, -8.968233697856176],
              [-39.946324290603897, -8.968775918240533],
              [-39.94559956177671, -8.969320499069845],
              [-39.944875922248158, -8.969865075894585],
              [-39.944151189207361, -8.970409744736578],
              [-39.94342318753575, -8.970957586079287],
              [-39.942702993401809, -8.971500432109183],
              [-39.941979254954717, -8.972045366075154],
              [-39.941255963029917, -8.972587946263355],
              [-39.940530221790659, -8.973133968166497],
              [-39.939804929633098, -8.97367863111195],
              [-39.939079724581532, -8.974222659536608],
              [-39.93835461128814, -8.974767862216789],
              [-39.93762949483677, -8.975312701924036],
              [-39.936907470489139, -8.975858165639728],
              [-39.936180071126778, -8.976401290342572],
              [-39.935323486268317, -8.977044676656181],
              [-39.938530722162326, -8.979228880651442],
              [-39.938828329781693, -8.979466883939034],
              [-39.939066959864256, -8.979691832822025],
              [-39.939383446904984, -8.980090317424304],
              [-39.939474836106363, -8.980220317534902],
              [-39.942098432918073, -8.984210957205086],
              [-39.942165240665126, -8.984296794376974],
              [-39.942988475026311, -8.985565453332777],
              [-39.943370553662241, -8.986067951788389],
              [-39.943610095619469, -8.9863612666643],
              [-39.943942698353283, -8.986726061526378],
              [-39.94522664795425, -8.988050782810328],
              [-39.94532780578146, -8.988157693983592],
              [-39.945943188864966, -8.988789552114758],
              [-39.94629523346687, -8.989107989640626],
              [-39.946512076954946, -8.989276011742216],
              [-39.946779377782221, -8.989467960969471],
              [-39.949169525459503, -8.991154112891829],
              [-39.952982102757737, -8.988269766338954],
              [-39.953703381215298, -8.987719118373956],
              [-39.954427848286116, -8.987171174121325],
              [-39.955152861980629, -8.986624402941331],
              [-39.955878779046863, -8.986076090738704],
              [-39.956601774719758, -8.985524619526258],
              [-39.957325137458866, -8.984975226244941],
              [-39.958049956460762, -8.984427003675169],
              [-39.958775864542879, -8.983878686618713],
              [-39.959497857056235, -8.983329655010458],
              [-39.960224208086295, -8.982778530761818],
              [-39.960947380540453, -8.982230217170383],
              [-39.961667720184955, -8.981677840002902],
              [-39.962391522038239, -8.981128437088952],
              [-39.963117872416348, -8.98058047333334],
              [-39.96384367620751, -8.980033052415331],
              [-39.964568746712594, -8.979484366050997],
              [-39.965293544240026, -8.978936493132235],
              [-39.966020161875207, -8.978389789938904],
              [-39.966746686545868, -8.977843176205264],
              [-39.967473484975478, -8.977297736235242],
              [-39.968201193179084, -8.976753197043518],
              [-39.968927080640789, -8.976208932718258],
              [-39.969651770407907, -8.975659786632411],
              [-39.970373653525854, -8.975116434777593],
              [-39.971101167742468, -8.974570715510296],
              [-39.971826316427524, -8.974025634312124],
              [-39.972555106356722, -8.973482441521904],
              [-39.973280982598034, -8.972938983862853],
              [-39.974007488509407, -8.972393714555038],
              [-39.974733718822371, -8.971848263877554],
              [-39.975458766112986, -8.971303448174206],
              [-39.976184810764025, -8.970758266868149],
              [-39.976907746773755, -8.970207937570381],
              [-39.977635880470174, -8.969663290940995],
              [-39.978359910773186, -8.969115669481207],
              [-39.979085488779162, -8.968569399289439],
              [-39.979812525953776, -8.968025384975451],
              [-39.980539466297351, -8.9674800131226],
              [-39.981266311745649, -8.966934007234151],
              [-39.981993428073302, -8.966388089846422],
              [-39.982720733804385, -8.965845788269556],
              [-39.983448662579264, -8.965299233214186],
              [-39.984175142304913, -8.964755846134935],
              [-39.98490233747539, -8.964208747963934],
              [-39.985628902720094, -8.963664996469866],
              [-39.986351549196847, -8.963119264569423],
              [-39.987080109094144, -8.962574781821008],
              [-39.987777522193959, -8.962050277438202],
              [-39.988506277464317, -8.961512393763643],
              [-39.989235665505447, -8.96097387410418],
              [-39.989965413685411, -8.960434809624699],
              [-39.990696432789761, -8.959895650075596],
              [-39.991427081429293, -8.95935486240035],
              [-39.992158458430765, -8.958815156799151],
              [-39.992886282969422, -8.958274374239998],
              [-39.993616381149586, -8.957734307861029],
              [-39.994344930854759, -8.957194244414923],
              [-39.995074838983442, -8.956652819523715],
              [-40.020601929624377, -8.937764724208023],
              [-40.021009681208625, -8.938294017441404],
              [-40.021113378841264, -8.938429478512685],
              [-40.02121734864842, -8.938564667483719],
              [-40.021320772580125, -8.938699767482357],
              [-40.021424650586852, -8.938834594879728],
              [-40.021528437469833, -8.938969332052313],
              [-40.02163240683651, -8.939104249558589],
              [-40.021736012983965, -8.939239348902126],
              [-40.021840347318928, -8.939374627075388],
              [-40.028997536893222, -8.93138866861892],
              [-40.027586342464154, -8.927513180067162],
              [-40.02755573184951, -8.927429610270334],
              [-40.026337769361959, -8.924083902917252],
              [-40.040380905044458, -8.912738484520199],
              [-40.0424577543859, -8.916155526618152],
              [-40.05216990159262, -8.90536745820352],
              [-40.047022502140521, -8.900192344597981],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0044000',
        uf: 'PE',
        nome_proje: 'PA BRILHANTE',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '939.8747',
        capacidade: 55.0,
        num_famili: 48.0,
        fase: 5.0,
        data_de_cr: '25/05/2000',
        forma_obte: 'Desapropriação',
        data_obten: '29/11/1999',
        area_calc_: 916.2086,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.01503778, -8.87163583],
              [-39.95335167, -8.90697],
              [-39.95036944, -8.90867889],
              [-39.94756917, -8.91028306],
              [-39.94436139, -8.91211944],
              [-39.94186083, -8.91355222],
              [-39.93947333, -8.91491528],
              [-39.93717444, -8.9162325],
              [-39.93496306, -8.9175],
              [-39.93282056, -8.91872667],
              [-39.93075222, -8.91991167],
              [-39.9278225, -8.92158778],
              [-39.92679528, -8.92217778],
              [-39.92490222, -8.9232625],
              [-39.92304833, -8.92432361],
              [-39.92124278, -8.92535833],
              [-39.91948194, -8.92636778],
              [-39.91899889, -8.92664389],
              [-39.91778389, -8.92739861],
              [-39.91610417, -8.92843417],
              [-39.91442472, -8.92946972],
              [-39.9127825, -8.93048056],
              [-39.90400333, -8.93582333],
              [-39.89898639, -8.93887806],
              [-39.89847722, -8.93919111],
              [-39.89963444, -8.94035667],
              [-39.89989778, -8.94064056],
              [-39.90012917, -8.94092389],
              [-39.90034667, -8.94122889],
              [-39.90054389, -8.94154417],
              [-39.90079306, -8.94203056],
              [-39.90284472, -8.94665833],
              [-39.90310361, -8.94735889],
              [-39.90321306, -8.947815],
              [-39.90541361, -8.94624972],
              [-39.906985, -8.945135],
              [-39.90855639, -8.94402056],
              [-39.91006139, -8.94295306],
              [-39.91146, -8.94195556],
              [-39.91285861, -8.94096028],
              [-39.91425722, -8.93996444],
              [-39.91565722, -8.93896667],
              [-39.91704194, -8.93798056],
              [-39.91842806, -8.937],
              [-39.91981056, -8.93602389],
              [-39.92120444, -8.93503611],
              [-39.92262333, -8.9340275],
              [-39.92409028, -8.93298556],
              [-39.92558833, -8.93192444],
              [-39.92712667, -8.93083667],
              [-39.92869528, -8.92972667],
              [-39.93031556, -8.92858333],
              [-39.93198861, -8.92740389],
              [-39.93370056, -8.92619472],
              [-39.93546639, -8.92494389],
              [-39.93729528, -8.92365444],
              [-39.93920528, -8.92230528],
              [-39.94120167, -8.92090361],
              [-39.94327, -8.91945194],
              [-39.94542611, -8.91794111],
              [-39.94768944, -8.9163375],
              [-39.9505875, -8.91430361],
              [-39.95321111, -8.91246028],
              [-39.95601, -8.91049694],
              [-39.97182806, -8.89939194],
              [-39.97114167, -8.89839639],
              [-40.01274556, -8.8753275],
              [-40.01231444, -8.87456806],
              [-40.01634222, -8.87236667],
              [-40.01503778, -8.87163583],
            ],
          ],
          [
            [
              [-39.89816917, -8.93938083],
              [-39.88560694, -8.94692667],
              [-39.88688722, -8.94962278],
              [-39.887235, -8.95048194],
              [-39.89143889, -8.95144556],
              [-39.89433889, -8.95212028],
              [-39.89576056, -8.95315611],
              [-39.90290306, -8.948025],
              [-39.90275361, -8.94745833],
              [-39.90251139, -8.94680639],
              [-39.90046667, -8.94218694],
              [-39.90022278, -8.94171694],
              [-39.90003972, -8.9414225],
              [-39.89983111, -8.94113222],
              [-39.89961111, -8.94086417],
              [-39.89937444, -8.94060972],
              [-39.89816917, -8.93938083],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0144000',
        uf: 'PE',
        nome_proje: 'PA JOSÉ IVALDO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '351.7714',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '13/07/2005',
        forma_obte: 'Desapropriação',
        data_obten: '13/07/2005',
        area_calc_: 351.7367,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.98117929655151, -8.851698775326614],
              [-39.914905441523786, -8.891566461875879],
              [-39.911151529139929, -8.890573577288393],
              [-39.909991114444296, -8.891644333382779],
              [-39.914034976798064, -8.892680381400481],
              [-39.982798666597148, -8.852652546111978],
              [-39.98117929655151, -8.851698775326614],
            ],
          ],
          [
            [
              [-40.09755516618096, -8.795643749627857],
              [-40.049056441531796, -8.819047042171972],
              [-40.030523633327078, -8.827965395143217],
              [-40.025353200654301, -8.830478535088767],
              [-39.981937430254973, -8.851406930755973],
              [-39.983438685865664, -8.852293902927029],
              [-40.025565422729073, -8.831961044963283],
              [-40.032998250011495, -8.828372615600031],
              [-40.049667123370796, -8.820322852490442],
              [-40.098500063504616, -8.796733641926759],
              [-40.09755516618096, -8.795643749627857],
            ],
          ],
          [
            [
              [-39.898668853479258, -8.887272856149599],
              [-39.898162210011456, -8.888613685907922],
              [-39.903088904389996, -8.889881339604036],
              [-39.905063764082279, -8.890384645592396],
              [-39.908172847330839, -8.891178387056694],
              [-39.909541939685901, -8.891529485057603],
              [-39.910696338171526, -8.890452595008618],
              [-39.908203811973131, -8.889793576414863],
              [-39.905965191033324, -8.889203833921574],
              [-39.90391120519083, -8.888658307360574],
              [-39.898668853479258, -8.887272856149599],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0151000',
        uf: 'PE',
        nome_proje: 'PA JOSE IVALDO I',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '775.8007',
        capacidade: 50.0,
        num_famili: 48.0,
        fase: 3.0,
        data_de_cr: '23/08/2005',
        forma_obte: 'Compra e Venda',
        data_obten: '16/08/2005',
        area_calc_: 774.7032,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.975759972734039, -8.848511579221142],
              [-39.920576514638135, -8.885779967745217],
              [-39.918058492067658, -8.886524077290309],
              [-39.913779199796252, -8.884771632177218],
              [-39.912601290852059, -8.888685251868193],
              [-39.916161172308009, -8.890103217825231],
              [-39.978987462809904, -8.850411236774853],
              [-39.975759972734039, -8.848511579221142],
            ],
          ],
          [
            [
              [-39.900171573152811, -8.879781730623076],
              [-39.899056084913113, -8.880929852472564],
              [-39.898597232600764, -8.882398993073981],
              [-39.898425344734598, -8.88347773286463],
              [-39.899318519389766, -8.883795560225174],
              [-39.905406488058695, -8.886036061219874],
              [-39.907561563206215, -8.886830679495228],
              [-39.910012104387953, -8.887731554524533],
              [-39.912261580900939, -8.888557476910096],
              [-39.912884478696697, -8.886516067996668],
              [-39.913438317340457, -8.884646393156032],
              [-39.910172301135582, -8.883439308574568],
              [-39.906357935867973, -8.882047398906632],
              [-39.900171573152811, -8.879781730623076],
            ],
          ],
          [
            [
              [-40.093785419383998, -8.791304107313691],
              [-40.053551600357352, -8.810843842850081],
              [-40.028549336689103, -8.822981449655126],
              [-40.022846122027815, -8.825750953315564],
              [-39.976531862219034, -8.848229658243714],
              [-39.979383405700659, -8.849907451031092],
              [-40.023560567081276, -8.828708947946975],
              [-40.029865016665525, -8.825682565109613],
              [-40.0549633959224, -8.813636290164581],
              [-40.096049995762442, -8.793911851764452],
              [-40.093785419383998, -8.791304107313691],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0199000',
        uf: 'PE',
        nome_proje: 'PA LYNDOPHO SILVA',
        municipio: 'PETROLINA',
        area_hecta: '945.5232',
        capacidade: 33.0,
        num_famili: 31.0,
        fase: 3.0,
        data_de_cr: '27/11/2006',
        forma_obte: 'Desapropriação',
        data_obten: '27/11/2006',
        area_calc_: 949.4523,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.721220039685534, -9.077542919729947],
              [-40.718059938900581, -9.075874235514172],
              [-40.715694276854236, -9.073298933826141],
              [-40.71501618659034, -9.072995981290862],
              [-40.712918788487279, -9.071337802820432],
              [-40.711018426744488, -9.070985235203107],
              [-40.710056730033571, -9.070374569829873],
              [-40.707428297818005, -9.068167048616154],
              [-40.705062266224004, -9.067106248238252],
              [-40.704245554556422, -9.066163073297167],
              [-40.702667238760107, -9.065776948750319],
              [-40.701779912377134, -9.066447692030904],
              [-40.70108219243577, -9.066588365636928],
              [-40.700447927514738, -9.066586613983596],
              [-40.700124834019128, -9.066411087844306],
              [-40.699984996550434, -9.066268614369674],
              [-40.699060449585382, -9.065292744747429],
              [-40.695200932693091, -9.067822870954089],
              [-40.69014893012119, -9.067994716484522],
              [-40.68840283644392, -9.069195064401173],
              [-40.683683159942127, -9.080696727717674],
              [-40.683019339782469, -9.084543357626268],
              [-40.683120710852833, -9.084746139779533],
              [-40.687223369132454, -9.086026979865933],
              [-40.68716703418179, -9.086157073858365],
              [-40.687080384552353, -9.086340471035601],
              [-40.686699101371929, -9.087107311289522],
              [-40.686559447421295, -9.087394024645993],
              [-40.686410577683887, -9.087674722883266],
              [-40.686302221441991, -9.087923860421622],
              [-40.686240623162774, -9.088095659256718],
              [-40.686127485587264, -9.088256123364259],
              [-40.686022211277631, -9.088464108550156],
              [-40.68590779478987, -9.088701249077964],
              [-40.68577744175041, -9.088973543624473],
              [-40.685644935445538, -9.089252267455116],
              [-40.685517148546566, -9.089509270251185],
              [-40.685402842750818, -9.089750750057105],
              [-40.685347442663733, -9.089867006394536],
              [-40.682149894747738, -9.089376172557714],
              [-40.681032635510661, -9.092121215761129],
              [-40.681237299182079, -9.095295958276221],
              [-40.68107333680755, -9.095657735680515],
              [-40.681355291956734, -9.09578491309354],
              [-40.68346846246159, -9.096797646841328],
              [-40.684099621890631, -9.097309364625367],
              [-40.685527305467069, -9.098969806962998],
              [-40.685620000775593, -9.098911332872241],
              [-40.686322753994688, -9.096586358251434],
              [-40.688279766636519, -9.093663913995632],
              [-40.691058189517889, -9.091560055098041],
              [-40.692000674761978, -9.092971914678108],
              [-40.693100373199016, -9.09384517329441],
              [-40.698907549733086, -9.095483566221397],
              [-40.702439245544994, -9.095422446785559],
              [-40.702539974505228, -9.095379101710435],
              [-40.702882290134717, -9.095227327661247],
              [-40.704403261992603, -9.094462563454231],
              [-40.710788467574524, -9.087097151757867],
              [-40.711312201556026, -9.086534139965712],
              [-40.714997113531297, -9.084828847078285],
              [-40.721220039685534, -9.077542919729947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0149000',
        uf: 'PE',
        nome_proje: 'PA MONTE ALEGRE',
        municipio: 'SALGUEIRO',
        area_hecta: '1911.5130',
        capacidade: 50.0,
        num_famili: 49.0,
        fase: 3.0,
        data_de_cr: '25/08/2005',
        forma_obte: 'Desapropriação',
        data_obten: '18/08/2005',
        area_calc_: 1857.5927,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.141465535192971, -8.001693796496715],
              [-39.14148080435038, -8.002675960915417],
              [-39.141259627138105, -8.004664672804475],
              [-39.141095941471384, -8.005868603291631],
              [-39.141129556651123, -8.006609733610263],
              [-39.141645080557645, -8.009541093500319],
              [-39.142017736436088, -8.013225302483082],
              [-39.141933843070682, -8.015107575800402],
              [-39.141709689145131, -8.016886620150597],
              [-39.141718183841299, -8.017873539179179],
              [-39.141752345688602, -8.0186388013875],
              [-39.141920563358987, -8.019349496181377],
              [-39.14254198565807, -8.020774275511954],
              [-39.143576336255109, -8.022144200337868],
              [-39.144321638920339, -8.023127689502601],
              [-39.144284353073914, -8.022390312072378],
              [-39.144015306697561, -8.02089037910949],
              [-39.143995885104516, -8.020783558339028],
              [-39.14341857391981, -8.01779973372069],
              [-39.142645240150266, -8.013831316388957],
              [-39.142033386020465, -8.010146409113062],
              [-39.142586180685043, -8.008116746771762],
              [-39.144533739229345, -8.004712645713978],
              [-39.144974699750378, -8.003944589388944],
              [-39.145319792901709, -8.003323121937587],
              [-39.145565338731437, -8.002755817726515],
              [-39.141465535192971, -8.001693796496715],
            ],
          ],
          [
            [
              [-39.139439272179708, -8.001152685759916],
              [-39.120705615781866, -7.996799941266725],
              [-39.120518718485812, -7.99719761045351],
              [-39.120137463128664, -7.996967928694717],
              [-39.119781388640966, -7.99661049821082],
              [-39.107987197809635, -7.99407558848973],
              [-39.10807780140383, -7.99440673110928],
              [-39.108249458633892, -7.99536292647228],
              [-39.108251368394427, -7.996307949746795],
              [-39.108511844995206, -7.996844146254005],
              [-39.108766464663979, -7.999177864592553],
              [-39.10882611692481, -7.999200290861469],
              [-39.108389675312978, -8.001272349491373],
              [-39.109169729525767, -8.002064379310815],
              [-39.109546007616032, -8.00336946017061],
              [-39.108926968067294, -8.00390886459261],
              [-39.109594204508774, -8.005109102994497],
              [-39.110239604631452, -8.00547402702697],
              [-39.110553089960455, -8.005892416775687],
              [-39.11039091995152, -8.006978503301525],
              [-39.110998231758188, -8.007690775728044],
              [-39.11151824241945, -8.008450786004955],
              [-39.111658479488121, -8.009207723554843],
              [-39.111641186583832, -8.009453450149387],
              [-39.111474447603499, -8.010817697311479],
              [-39.111756920815601, -8.011235461159259],
              [-39.112790874601025, -8.011629673443899],
              [-39.113694303767048, -8.012366346747713],
              [-39.113451424763134, -8.015075857697003],
              [-39.113295612995906, -8.016167252803836],
              [-39.112272903387876, -8.019095109236131],
              [-39.111012816155572, -8.019383740716323],
              [-39.11069246074149, -8.01991061138574],
              [-39.104668150462608, -8.027928143732359],
              [-39.104516892655802, -8.028305290197858],
              [-39.104088624436031, -8.028675179878892],
              [-39.102910319048533, -8.028191567609579],
              [-39.099675778164716, -8.026896365497858],
              [-39.097993567958255, -8.026203429532277],
              [-39.097895465587442, -8.026160757970752],
              [-39.090323870609893, -8.023142737281471],
              [-39.090223782670535, -8.023101583953492],
              [-39.081977664937675, -8.019783487730727],
              [-39.082347758506153, -8.020302000173515],
              [-39.082333565967865, -8.020788964146975],
              [-39.082164495666369, -8.021285588523519],
              [-39.082119911279136, -8.021840645721435],
              [-39.081041717926496, -8.022634746799378],
              [-39.080631892076433, -8.0233503122068],
              [-39.08086614317304, -8.024342135910363],
              [-39.080959941086419, -8.024881242395102],
              [-39.080685755295583, -8.025629358885665],
              [-39.0805652910121, -8.027575001478306],
              [-39.080674739693002, -8.027796351503953],
              [-39.081034626670238, -8.028017548807826],
              [-39.081615365484339, -8.028527384330852],
              [-39.082485044209243, -8.02870146856448],
              [-39.083210319423365, -8.029168568765165],
              [-39.083747540757535, -8.029786940475812],
              [-39.091951965899071, -8.031532495811101],
              [-39.097311022604885, -8.032676246036502],
              [-39.103818825954619, -8.034015455203273],
              [-39.103973556914717, -8.034473273440117],
              [-39.105728617482981, -8.034483092675208],
              [-39.111967747389436, -8.036282775706287],
              [-39.112115175463181, -8.036317307517617],
              [-39.114672945118748, -8.037017865175164],
              [-39.124673718982514, -8.039718279247342],
              [-39.135596339909526, -8.042673879140645],
              [-39.136723070704221, -8.041162236076911],
              [-39.136798566619163, -8.041074885587753],
              [-39.141380359119864, -8.035480160903971],
              [-39.141702326776951, -8.034423163416236],
              [-39.142716837747614, -8.030374674306234],
              [-39.143857468449617, -8.02561872463852],
              [-39.141060713797764, -8.021820956246554],
              [-39.140416628033698, -8.020686061820813],
              [-39.140154427414998, -8.019766358009567],
              [-39.13995261453347, -8.018913715578655],
              [-39.139903422316038, -8.017905433109634],
              [-39.139893976272184, -8.016807420162566],
              [-39.140125302133811, -8.014938268432472],
              [-39.140200221928325, -8.01325193876318],
              [-39.139847349867118, -8.009797596916558],
              [-39.139321836243703, -8.006809341311113],
              [-39.139276909115772, -8.005818714403103],
              [-39.139471193067067, -8.004356788805708],
              [-39.139661533984402, -8.002709312359253],
              [-39.139439272179708, -8.001152685759916],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0237000',
        uf: 'PE',
        nome_proje: 'PA JOSUÉ DE CASTRO',
        municipio: 'OURICURI',
        area_hecta: '1807.3220',
        capacidade: 50.0,
        num_famili: 50.0,
        fase: 3.0,
        data_de_cr: '19/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '29/11/2006',
        area_calc_: 1805.6023,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.895631040459733, -8.051122881884886],
              [-39.898814093720283, -8.053064026517697],
              [-39.915681319335818, -8.063320764281194],
              [-39.922184966010526, -8.067232288338092],
              [-39.934014351893161, -8.05030168331777],
              [-39.940006498443815, -8.036860036521249],
              [-39.940059503711247, -8.036203086745401],
              [-39.906128221359396, -8.021326028200352],
              [-39.905729951919689, -8.030115040727296],
              [-39.9059741513151, -8.035110681363349],
              [-39.906126752945205, -8.035677934397938],
              [-39.906527924216803, -8.036147022450979],
              [-39.90648672693289, -8.037292047201495],
              [-39.906124525003982, -8.038181459318309],
              [-39.904213655168739, -8.039128007425377],
              [-39.90354078808862, -8.039997148107112],
              [-39.902991773138751, -8.040162994176891],
              [-39.900525591140308, -8.041760152966441],
              [-39.900051576505163, -8.04222193531983],
              [-39.895768058794779, -8.04423346669779],
              [-39.895404384710758, -8.044210626184947],
              [-39.895341970724893, -8.044214970430259],
              [-39.896482964501516, -8.044900060001467],
              [-39.895631040459733, -8.051122881884886],
            ],
          ],
          [
            [
              [-39.886801324821235, -8.030279026117451],
              [-39.886076178351516, -8.031501721704835],
              [-39.886108341136541, -8.031620795546081],
              [-39.888452346952036, -8.033008862458901],
              [-39.893275899162802, -8.0358345818684],
              [-39.88985181762893, -8.04164267575058],
              [-39.89096940652464, -8.04186818377638],
              [-39.892167507035239, -8.042468732889677],
              [-39.893531747113556, -8.043460171566414],
              [-39.89479595653421, -8.043888370626195],
              [-39.89524918358957, -8.044160498869273],
              [-39.89539134776733, -8.044101058797956],
              [-39.89574833356496, -8.044123479284741],
              [-39.899976069648069, -8.042141277137286],
              [-39.900473935536873, -8.041664363206456],
              [-39.902934029009621, -8.04006927864101],
              [-39.903475862435698, -8.039903352562641],
              [-39.904142753920873, -8.039041930687022],
              [-39.9060391376401, -8.038102558577165],
              [-39.90637859615088, -8.037268995003165],
              [-39.906417586746166, -8.036185310308289],
              [-39.906027942824245, -8.035729700968702],
              [-39.905866200976199, -8.035128471249632],
              [-39.905620988527104, -8.030114742214311],
              [-39.905790811806092, -8.025882842236163],
              [-39.906039872707993, -8.02129069594541],
              [-39.902130171972949, -8.01957257232457],
              [-39.897556937822635, -8.017553931102103],
              [-39.895117951308379, -8.01647207174676],
              [-39.886801324821235, -8.030279026117451],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0182000',
        uf: 'PE',
        nome_proje: 'PA JOSIAS BARROS',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '3196.4677',
        capacidade: 69.0,
        num_famili: 63.0,
        fase: 3.0,
        data_de_cr: '31/08/2006',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2006',
        area_calc_: 3190.5989,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.892495116420505, -8.629103221012146],
              [-39.929852618331807, -8.630780569588513],
              [-39.93101075125108, -8.630567719969539],
              [-39.932144988854347, -8.630357855340153],
              [-39.933016000884969, -8.630198035740406],
              [-39.933620462372367, -8.630273313552266],
              [-39.934069384999148, -8.630196657225492],
              [-39.935120642889686, -8.62981150323531],
              [-39.936036750434596, -8.629642658667647],
              [-39.937153906492355, -8.629437072191557],
              [-39.939345393170754, -8.62903357365172],
              [-39.941568931010778, -8.628624576050679],
              [-39.942599330557812, -8.628434788262179],
              [-39.950364039975874, -8.627007527679856],
              [-39.95289997515787, -8.626541339567529],
              [-39.954617880780319, -8.626225457258235],
              [-39.955366295422969, -8.626299332631568],
              [-39.955723101629381, -8.62531310940642],
              [-39.955643520056277, -8.625407827607717],
              [-39.950990497011752, -8.624445600915928],
              [-39.947399530428079, -8.6229177487025],
              [-39.942527082851832, -8.620833033564853],
              [-39.93842535969155, -8.619070217636777],
              [-39.934894516765844, -8.617546813689945],
              [-39.93012987247824, -8.615491493980024],
              [-39.925924371156768, -8.613688716903601],
              [-39.921113519319043, -8.611651672271712],
              [-39.918373768310111, -8.611984475051855],
              [-39.917628410074364, -8.611906993995376],
              [-39.915841210521052, -8.612492458597854],
              [-39.91543585336062, -8.612843406034974],
              [-39.914671982355976, -8.613095753522137],
              [-39.913272972362272, -8.612783150269143],
              [-39.912684112236491, -8.612762433136345],
              [-39.912183517890909, -8.612900522878746],
              [-39.91184378766593, -8.612860097876283],
              [-39.911414995930244, -8.612973051319926],
              [-39.905466927442667, -8.613686490979235],
              [-39.905255741948082, -8.61516069416575],
              [-39.900422790933867, -8.620200681938417],
              [-39.899930193903543, -8.620921805316648],
              [-39.899756990478025, -8.621422782785256],
              [-39.899109998405031, -8.621570723116252],
              [-39.892495116420505, -8.629103221012146],
            ],
          ],
          [
            [
              [-39.948073150780921, -8.569764253344394],
              [-39.931766331843313, -8.571289317448475],
              [-39.926061441310814, -8.581462856323437],
              [-39.925790030921412, -8.581946519941516],
              [-39.912963915649314, -8.604707975535668],
              [-39.912361178731857, -8.607771962482456],
              [-39.908103676117619, -8.607649559231294],
              [-39.907457723620851, -8.607631855886799],
              [-39.907417069046708, -8.607037585856306],
              [-39.907317846140117, -8.604996528408389],
              [-39.906768377506403, -8.605036008414443],
              [-39.905034231060448, -8.606236919032741],
              [-39.905059654877071, -8.607115755899603],
              [-39.905064624619357, -8.607245383678755],
              [-39.905788351234513, -8.608644923768907],
              [-39.904325402616848, -8.610578072186824],
              [-39.903793744612955, -8.611444173441251],
              [-39.903765017076054, -8.611531345615797],
              [-39.903372676109029, -8.613350910722655],
              [-39.903299689864461, -8.613452386283271],
              [-39.902747268423759, -8.614237282346837],
              [-39.893985704752247, -8.611044695548328],
              [-39.88884336127046, -8.618065154167502],
              [-39.888577536990766, -8.618850455356526],
              [-39.888057401133565, -8.627507399277324],
              [-39.892272539996519, -8.629031511586103],
              [-39.899048813041901, -8.621464075951037],
              [-39.899666616945993, -8.621322809854549],
              [-39.899823618281403, -8.620868695992201],
              [-39.900331133481373, -8.620125733899494],
              [-39.905143957848495, -8.615108004108405],
              [-39.905315008639654, -8.613913968582152],
              [-39.905314695327881, -8.613782218527978],
              [-39.905362770984262, -8.613580558345767],
              [-39.911392706674825, -8.612857300120639],
              [-39.911835390020272, -8.612740687310096],
              [-39.912179183830546, -8.61278159587482],
              [-39.912661003919141, -8.612646766512519],
              [-39.913268078206421, -8.612665361497747],
              [-39.914665461012568, -8.612973839202814],
              [-39.915393088958361, -8.612724594907979],
              [-39.915781647211254, -8.612388191529016],
              [-39.917615512470803, -8.611787439888964],
              [-39.918372735259439, -8.611866154346693],
              [-39.921130634942685, -8.61153114693893],
              [-39.925970862944261, -8.613580630567414],
              [-39.930176730227736, -8.615383564586994],
              [-39.934941507274822, -8.617438941639303],
              [-39.938479224542618, -8.618965299980703],
              [-39.942573831073879, -8.620725057374759],
              [-39.947446080799679, -8.622809687939441],
              [-39.951026086510353, -8.624332876749936],
              [-39.95562091403383, -8.625283069223199],
              [-39.955759017983496, -8.625201832298435],
              [-39.956139737846229, -8.624147468239203],
              [-39.956770300291517, -8.62241011624041],
              [-39.95701699741192, -8.621729858520261],
              [-39.957653232084851, -8.61998869307509],
              [-39.958403889042927, -8.617940024019232],
              [-39.959204161013197, -8.615712953864268],
              [-39.960063659549583, -8.613332239157996],
              [-39.960485980726531, -8.612177804863986],
              [-39.960944182454895, -8.610892557878778],
              [-39.960460026304588, -8.609648030433805],
              [-39.959485913215616, -8.607247311216518],
              [-39.958749037171181, -8.605458297899927],
              [-39.957950910864838, -8.603556546744546],
              [-39.957477030533305, -8.602378823111636],
              [-39.957204292127685, -8.601706360689283],
              [-39.955826511057815, -8.59837946822652],
              [-39.955971842544713, -8.597448933594077],
              [-39.956074617254075, -8.596832994139156],
              [-39.956229871115511, -8.59579016171265],
              [-39.956342848259844, -8.595169955226767],
              [-39.956459274262436, -8.594439438652016],
              [-39.956755754605851, -8.592526496007475],
              [-39.956588984996621, -8.592026818077661],
              [-39.955888788226822, -8.590259588925548],
              [-39.95476849152444, -8.587330022301455],
              [-39.953777404342794, -8.58475180075818],
              [-39.951515785726109, -8.578878023511281],
              [-39.951151824262595, -8.577914033644417],
              [-39.950772159122259, -8.576904481550192],
              [-39.950401488243337, -8.575944658246671],
              [-39.949949325886777, -8.574759203754905],
              [-39.949081664511311, -8.57243930113737],
              [-39.948073150780921, -8.569764253344394],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0274000',
        uf: 'PE',
        nome_proje: 'PA CUSTODIA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '1245.4897',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '07/11/2008',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2007',
        area_calc_: 1246.9358,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.028586375048228, -8.622523128776821],
              [-39.028320745689165, -8.621665991455243],
              [-39.028056151135985, -8.620897985937544],
              [-39.027683868529977, -8.619801228526788],
              [-39.026660668446311, -8.617858349231369],
              [-39.024442219884584, -8.615868898386454],
              [-39.023656954202387, -8.615164980967739],
              [-39.023378888397076, -8.613693946849891],
              [-39.019772309959734, -8.610361100705834],
              [-39.021859195922076, -8.608958680330248],
              [-39.023472092033707, -8.605880133417449],
              [-39.02173368504338, -8.605477303045712],
              [-39.020683080491999, -8.605119219046468],
              [-39.019448537011868, -8.604611378981454],
              [-39.018209991511107, -8.603729058096027],
              [-39.017663060813611, -8.603221773999788],
              [-39.016558209162667, -8.601899427854724],
              [-39.015845986710225, -8.601555986235239],
              [-39.015002512034258, -8.600719795642613],
              [-39.013454273076782, -8.599959348714844],
              [-39.007435494018267, -8.598873906570699],
              [-39.006685964526397, -8.598967434013993],
              [-39.00524092486382, -8.599357233756376],
              [-39.004021543288303, -8.599973253125365],
              [-39.003360300398342, -8.599881359909482],
              [-39.002602662349801, -8.59941435430288],
              [-39.001595280406235, -8.599226218541354],
              [-39.000487040336495, -8.598595457155657],
              [-38.999469059804262, -8.597355972338017],
              [-38.998025272797136, -8.597038547913153],
              [-38.997195124001799, -8.597898676858817],
              [-39.000449475437094, -8.605478460862734],
              [-39.001350989289207, -8.608056462804171],
              [-39.004790542285221, -8.616091481193775],
              [-39.011462805328975, -8.630530270312104],
              [-39.011958393503882, -8.630304494345161],
              [-39.012535353439382, -8.630040290787914],
              [-39.015074304043637, -8.62888131967809],
              [-39.016702992843442, -8.628137685939675],
              [-39.016875006462911, -8.628059038847944],
              [-39.017039630872077, -8.627983937752454],
              [-39.01745325021232, -8.627795343400415],
              [-39.017616738257793, -8.627719816980813],
              [-39.017783189250842, -8.627644824024967],
              [-39.018113355232813, -8.627493933498478],
              [-39.018257020562466, -8.62742812235938],
              [-39.018530063572328, -8.62730200013314],
              [-39.018800488890001, -8.627177795437476],
              [-39.019073340670261, -8.627051636652132],
              [-39.019345492500037, -8.626926979222343],
              [-39.019886941757996, -8.626678586547934],
              [-39.020183450758132, -8.62654397746374],
              [-39.020714774014259, -8.626297664563323],
              [-39.021252831584967, -8.626053919409594],
              [-39.021789761302735, -8.625807713250904],
              [-39.023605130778016, -8.62497763910261],
              [-39.025959170799915, -8.623901823638574],
              [-39.028586375048228, -8.622523128776821],
            ],
          ],
          [
            [
              [-39.023464607458756, -8.605654209943555],
              [-39.024483587986019, -8.602241253886987],
              [-39.023194157488959, -8.596431368277189],
              [-39.023301782346067, -8.592159436838166],
              [-39.023066040371837, -8.590247539933054],
              [-39.023926381968543, -8.588592778949476],
              [-39.025147378218115, -8.588008638004837],
              [-39.025641779784408, -8.583370353537868],
              [-39.018051137585978, -8.57942879156696],
              [-39.016179661138061, -8.57845760018542],
              [-39.013721734665815, -8.57719293739148],
              [-39.00827449217951, -8.574609015070379],
              [-39.007472615724978, -8.585770577428423],
              [-39.002771857316617, -8.583963047332031],
              [-39.001113219696812, -8.590226755055635],
              [-39.001081439189733, -8.59034096093802],
              [-39.000070941863498, -8.594124325157397],
              [-38.999824698864565, -8.594977060309226],
              [-38.999558561435933, -8.595264356425503],
              [-38.999147803153072, -8.595847776578966],
              [-38.998094788830883, -8.596943608965475],
              [-38.999587092414728, -8.597156674692288],
              [-39.00062439709076, -8.598419688204705],
              [-39.001680821691487, -8.599021318480249],
              [-39.002682898504162, -8.599208463350333],
              [-39.00343603029809, -8.599672691362814],
              [-39.003983883648857, -8.599748826801074],
              [-39.005162209249747, -8.599153548383255],
              [-39.006586071986511, -8.598769461269727],
              [-39.006769866534349, -8.59873817839736],
              [-39.007427174078536, -8.598656158436377],
              [-39.013522466843391, -8.599750760418877],
              [-39.015131106507198, -8.600540874198854],
              [-39.015974004926221, -8.601376493353182],
              [-39.016695761143176, -8.601724532118672],
              [-39.017821908257282, -8.603072365350682],
              [-39.018348397234334, -8.603560689360133],
              [-39.019555067400951, -8.604420302579086],
              [-39.020760147471215, -8.604916022543902],
              [-39.021788613462334, -8.605266560920279],
              [-39.02346458019246, -8.605654191854656],
              [-39.023464607458756, -8.605654209943555],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0165000',
        uf: 'PE',
        nome_proje: 'PA PIRAMBEBA',
        municipio: 'BETANIA',
        area_hecta: '1424.9216',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '14/02/2006',
        forma_obte: 'Desapropriação',
        data_obten: '14/02/2006',
        area_calc_: 1209.8068,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.886129420645993, -8.45680213420499],
              [-37.901760237464039, -8.437667027818428],
              [-37.901094856261302, -8.436584040772702],
              [-37.900860573171798, -8.435788064464917],
              [-37.900767244237763, -8.434936433930192],
              [-37.900802147650616, -8.434181846359696],
              [-37.900915120452758, -8.433586130433596],
              [-37.901504084277612, -8.431731575693986],
              [-37.902377919503145, -8.42930805493088],
              [-37.903323577875447, -8.426563376227103],
              [-37.903644405315319, -8.425346398954307],
              [-37.903607321426087, -8.424116602168063],
              [-37.903354975264428, -8.423222062418201],
              [-37.902942815646185, -8.422418192932565],
              [-37.902445627298484, -8.421790182463271],
              [-37.901441576512383, -8.42078860742969],
              [-37.890743987859949, -8.433510931094057],
              [-37.87502264660619, -8.453310302139366],
              [-37.877261986836075, -8.453028121136317],
              [-37.877614035755599, -8.45302611924169],
              [-37.877932575135837, -8.453068778142594],
              [-37.880745580502577, -8.453647868567078],
              [-37.886129420645993, -8.45680213420499],
            ],
          ],
          [
            [
              [-37.919209306837026, -8.416288929771408],
              [-37.920546510486929, -8.414693005464311],
              [-37.919974516888438, -8.414165563895967],
              [-37.918844698403205, -8.413731565522465],
              [-37.918145993465764, -8.412762265140861],
              [-37.916142989021971, -8.408919448571654],
              [-37.914777746826161, -8.407636825457459],
              [-37.914308681225791, -8.406887148026525],
              [-37.914140715429838, -8.405496563454031],
              [-37.911916311884355, -8.408162763493618],
              [-37.911188557759587, -8.409028538733414],
              [-37.910112539016346, -8.410376937146319],
              [-37.909727501172391, -8.410824442185101],
              [-37.909419375161562, -8.411182671687436],
              [-37.902530625844953, -8.419398115152012],
              [-37.903809942768625, -8.420591834968123],
              [-37.904479641687246, -8.421437742989689],
              [-37.905054940260456, -8.422559786261129],
              [-37.905416317934851, -8.423840809618202],
              [-37.905467958896821, -8.42555309054476],
              [-37.90506354024874, -8.427087176055256],
              [-37.904091953280201, -8.429907144663439],
              [-37.903225563161108, -8.432310044990501],
              [-37.902679930041515, -8.434028181502262],
              [-37.902610880542944, -8.434392294439085],
              [-37.902588342621748, -8.434879597575973],
              [-37.902648976173232, -8.435432857571929],
              [-37.902791482007132, -8.435917013183948],
              [-37.902906438670222, -8.436233481336156],
              [-37.918024957454826, -8.417754187871104],
              [-37.918353817786716, -8.417327130774156],
              [-37.918492811177174, -8.417128784487241],
              [-37.919209306837026, -8.416288929771408],
            ],
          ],
          [
            [
              [-37.937619589127642, -8.377228868460588],
              [-37.914255258448158, -8.405359639682876],
              [-37.914440551636176, -8.406841375170401],
              [-37.914884053681021, -8.407550195620301],
              [-37.916253269903528, -8.408836551769866],
              [-37.918262390011236, -8.412691099211461],
              [-37.918931664827419, -8.413619571387319],
              [-37.920048817197404, -8.414048704130147],
              [-37.920631396491281, -8.41459371126269],
              [-37.929959637133898, -8.403189157360629],
              [-37.937039225926384, -8.394544837517158],
              [-37.936287860379835, -8.393173612563],
              [-37.935608602712506, -8.390470644789296],
              [-37.938029572718555, -8.389149323437913],
              [-37.940667835212984, -8.388633040985074],
              [-37.945457871294479, -8.384120219263075],
              [-37.946587360998834, -8.381782763269502],
              [-37.944508508884816, -8.380260510010149],
              [-37.942546899987967, -8.379611867768615],
              [-37.937619589127642, -8.377228868460588],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0137000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DO NAVIO II',
        municipio: 'BETANIA',
        area_hecta: '12235.0327',
        capacidade: 249.0,
        num_famili: 235.0,
        fase: 3.0,
        data_de_cr: '19/05/2005',
        forma_obte: 'Desapropriação',
        data_obten: '30/07/2004',
        area_calc_: 12126.8704,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.929953890403752, -8.192031267833764],
              [-37.913298343180038, -8.228280983612457],
              [-37.91192722045944, -8.233142625657601],
              [-37.898947539013697, -8.262028173891096],
              [-37.903132520940851, -8.26460095289403],
              [-37.912109416805059, -8.241573104892089],
              [-37.914394820401832, -8.238003725438166],
              [-37.932564560814072, -8.192195275552288],
              [-37.931847663045282, -8.192548353295349],
              [-37.930598714051072, -8.192371684106785],
              [-37.929953890403752, -8.192031267833764],
            ],
          ],
          [
            [
              [-37.902920922036103, -8.127069280785424],
              [-37.900617961821503, -8.131489158300027],
              [-37.897617020676151, -8.137236996597226],
              [-37.897033222490279, -8.138338393497973],
              [-37.894230719108918, -8.143996319292651],
              [-37.891434316253132, -8.14960240928678],
              [-37.888428653721832, -8.155544189630609],
              [-37.882544789575064, -8.16727045192123],
              [-37.885224289900393, -8.169297896668752],
              [-37.885276602669983, -8.170006803986997],
              [-37.885896288188043, -8.171204378219217],
              [-37.888553322432358, -8.171886421544542],
              [-37.890224063965839, -8.173024562976241],
              [-37.891026494699894, -8.175389064701621],
              [-37.891697778858777, -8.177463580072319],
              [-37.893278510047402, -8.177912214086248],
              [-37.895695013097175, -8.179476223187388],
              [-37.897583586111296, -8.180395198220365],
              [-37.899774865620095, -8.180427702530553],
              [-37.902908856617969, -8.18214468953661],
              [-37.9052687879459, -8.18364728823903],
              [-37.908033125205463, -8.174699843143721],
              [-37.908460946471848, -8.174128083306673],
              [-37.908390732444744, -8.173495687287712],
              [-37.90955207427946, -8.169684088528003],
              [-37.911299378616931, -8.163988292077111],
              [-37.912719439589729, -8.159508669024234],
              [-37.913639451154985, -8.156237565571322],
              [-37.914196580450032, -8.15533663427699],
              [-37.914282432203755, -8.154231106561371],
              [-37.914992294617271, -8.151872927349519],
              [-37.917409287896803, -8.143996690835989],
              [-37.918372725232423, -8.141115212234483],
              [-37.919287814160235, -8.137880131174889],
              [-37.902920922036103, -8.127069280785424],
            ],
          ],
          [
            [
              [-37.939945006232684, -8.040482404754886],
              [-37.938411730505067, -8.040580264334501],
              [-37.938263825499597, -8.040577132823335],
              [-37.918334518616653, -8.04058873617552],
              [-37.908650568387699, -8.0424661829624],
              [-37.91791154277945, -8.05385917822016],
              [-37.917805785713462, -8.053944577272278],
              [-37.916037628846198, -8.05561753764926],
              [-37.915745862881508, -8.055895673285486],
              [-37.933084999926102, -8.054861911125629],
              [-37.944360369587855, -8.054140082375234],
              [-37.956294317805693, -8.053315272765921],
              [-37.956228643291347, -8.053164666488136],
              [-37.953220020299682, -8.050712534072391],
              [-37.95239201724187, -8.050195841007092],
              [-37.951180779712104, -8.050455541159749],
              [-37.946920919868617, -8.051441224965719],
              [-37.939945006232684, -8.040482404754886],
            ],
          ],
          [
            [
              [-37.955596135808392, -8.054181883716799],
              [-37.952446321325056, -8.054385346059496],
              [-37.939617352415347, -8.055167561458434],
              [-37.914951171702114, -8.056643659784619],
              [-37.901149128865896, -8.06980527996954],
              [-37.900454003035499, -8.074583247908153],
              [-37.899028911519068, -8.077397895489151],
              [-37.898081397234336, -8.077923824874519],
              [-37.896106678985092, -8.078728110425134],
              [-37.895036250706212, -8.079050999283497],
              [-37.889872690041251, -8.082576447056436],
              [-37.887872009802287, -8.08381502118872],
              [-37.887301923439793, -8.084638171085642],
              [-37.889727622410696, -8.092242262860244],
              [-37.889554490405544, -8.094910547863547],
              [-37.88928942270703, -8.095542427319005],
              [-37.889213074281898, -8.097403963163124],
              [-37.889381083496922, -8.097611217500583],
              [-37.893293705002087, -8.100663122265013],
              [-37.894813039368771, -8.102205858773864],
              [-37.896381901974799, -8.106787669623744],
              [-37.897929708636362, -8.10768141390869],
              [-37.898532110571914, -8.108036504576464],
              [-37.902804786865445, -8.110612662246636],
              [-37.89333428840947, -8.113700006581061],
              [-37.894565744707123, -8.116890502893524],
              [-37.892441770255303, -8.117517620777745],
              [-37.89407313536848, -8.119339991560821],
              [-37.89416307853805, -8.119441020846585],
              [-37.894782224223377, -8.120135951641609],
              [-37.903564347311672, -8.11714113091514],
              [-37.906001238659492, -8.120140360454004],
              [-37.906294268660758, -8.120576910621688],
              [-37.905097203392351, -8.123014723056615],
              [-37.904270165921254, -8.124491780335337],
              [-37.903985533812502, -8.125040945960418],
              [-37.903099766542198, -8.126745648071315],
              [-37.919389966260638, -8.137512649356331],
              [-37.91962626939484, -8.137670967852381],
              [-37.937808022132657, -8.149851651409056],
              [-37.937910679311244, -8.149920421427765],
              [-37.952683857137437, -8.159739530766117],
              [-37.95944667890813, -8.14485474138794],
              [-37.959506813493135, -8.144768705790881],
              [-37.963853179808702, -8.146838426506772],
              [-37.966828545344462, -8.140798471121728],
              [-37.973227406283208, -8.127812185883863],
              [-37.979019069065941, -8.116024310694669],
              [-37.987006274721168, -8.110845642720253],
              [-37.986720182588769, -8.086640525171692],
              [-37.981074797677422, -8.08393019670917],
              [-37.953603040725781, -8.074697265833942],
              [-37.945416198249703, -8.073088271004496],
              [-37.943464279077872, -8.066869197643365],
              [-37.947046860597133, -8.063962919798852],
              [-37.947919168109671, -8.061880018089752],
              [-37.947624432003828, -8.058403957820593],
              [-37.948355421280986, -8.058410807485789],
              [-37.948908725582548, -8.057957518156879],
              [-37.951163588963993, -8.057924935826328],
              [-37.952040993868458, -8.059147829378961],
              [-37.952288113627617, -8.059261195209052],
              [-37.952880252039868, -8.059253220700725],
              [-37.953063154204251, -8.058621308475127],
              [-37.953473967975974, -8.057744838295536],
              [-37.954114590295106, -8.056912582169188],
              [-37.954319319441531, -8.056560882828629],
              [-37.95468116218477, -8.055709627401809],
              [-37.954970604242007, -8.055090212736344],
              [-37.955596135808392, -8.054181883716799],
            ],
          ],
          [
            [
              [-37.882459051270672, -8.167440591669338],
              [-37.881768762354085, -8.168852305684315],
              [-37.881598304851479, -8.169308483921494],
              [-37.882139302394258, -8.17040890203822],
              [-37.882485969479106, -8.171112148299621],
              [-37.882553880328061, -8.17195393536756],
              [-37.88244330704277, -8.172590441130382],
              [-37.882943882243652, -8.173951986869811],
              [-37.883011992172534, -8.175254159000886],
              [-37.883562840639939, -8.176200569390049],
              [-37.884062086733287, -8.176896820515397],
              [-37.885888485142587, -8.177489534848227],
              [-37.886725381976504, -8.177980795061305],
              [-37.887822242777467, -8.178487800066678],
              [-37.888798761529884, -8.177475587120194],
              [-37.889876317702992, -8.177175552204369],
              [-37.890508472345857, -8.177588755198832],
              [-37.891365089090129, -8.178714802578845],
              [-37.892144460067115, -8.179544017045433],
              [-37.893064099792703, -8.180136168545147],
              [-37.894135820812899, -8.180423857412917],
              [-37.895273700938901, -8.18095038267586],
              [-37.896626311739219, -8.181373179759637],
              [-37.897611804118782, -8.18230661827714],
              [-37.898551967489674, -8.183251172865672],
              [-37.900154458102151, -8.183754624769396],
              [-37.901203449412016, -8.183995821734467],
              [-37.901998207925075, -8.184663503161946],
              [-37.903318696552269, -8.185565047882546],
              [-37.904404024538032, -8.186001168238228],
              [-37.905416252058608, -8.186791716338913],
              [-37.907029341216969, -8.187648734049118],
              [-37.907815318408574, -8.188357577210004],
              [-37.909119469290893, -8.188881723748386],
              [-37.90996536698951, -8.189318422938117],
              [-37.910888979183241, -8.189506858395131],
              [-37.912294238451892, -8.189316068114774],
              [-37.912970001462625, -8.189303602114935],
              [-37.913660697842744, -8.189260495243182],
              [-37.913236451064613, -8.188901940376104],
              [-37.90904173973118, -8.188413767686439],
              [-37.907477123549612, -8.187421217254188],
              [-37.906093648848241, -8.185418723726949],
              [-37.90521424192098, -8.183827213632718],
              [-37.902816143671451, -8.182300313408295],
              [-37.899726986926304, -8.180607889076605],
              [-37.897540348502105, -8.180575453247645],
              [-37.895605403591553, -8.179633913638801],
              [-37.893202517760152, -8.178078717630243],
              [-37.89155476444521, -8.177611061393531],
              [-37.890071528013259, -8.173139762155904],
              [-37.88847830738122, -8.172054429082865],
              [-37.885797334219028, -8.171407842852949],
              [-37.885098276388966, -8.170056882001704],
              [-37.885049387684937, -8.169394371237335],
              [-37.882459051270672, -8.167440591669338],
            ],
          ],
          [
            [
              [-37.909812556134632, -8.169763151074914],
              [-37.908667529441509, -8.173521210770147],
              [-37.908743397623873, -8.17420452769276],
              [-37.908278941837899, -8.174825248218538],
              [-37.90709173524413, -8.178572337266454],
              [-37.905507650811863, -8.183797694008668],
              [-37.906325605354802, -8.185275960966669],
              [-37.907670054154508, -8.187221965341132],
              [-37.909135335928049, -8.188151500355012],
              [-37.913379850004176, -8.188645467692881],
              [-37.913627757201994, -8.189022666577923],
              [-37.913660697842744, -8.189260495243182],
              [-37.914192254482764, -8.188942455618809],
              [-37.915337795422793, -8.188963442174133],
              [-37.916385308664161, -8.189073262944682],
              [-37.917518041730936, -8.188923360461839],
              [-37.91808026928004, -8.188997888253031],
              [-37.918729623029876, -8.189373901468985],
              [-37.919624148672803, -8.189919591848254],
              [-37.920347806947575, -8.190533041784326],
              [-37.920814433721077, -8.190868191282856],
              [-37.921314268504254, -8.190568491550756],
              [-37.921777361677222, -8.190054976004506],
              [-37.922431035000294, -8.18895518965344],
              [-37.92289954804464, -8.188440104140103],
              [-37.923365404774955, -8.188325430766737],
              [-37.924291775778094, -8.18864897783515],
              [-37.925148773519226, -8.189401644712889],
              [-37.925191507996473, -8.190319012271109],
              [-37.925698300659491, -8.190920173316032],
              [-37.926373217528727, -8.19090698350532],
              [-37.927678167710674, -8.190431820421736],
              [-37.928809582680472, -8.190508107884009],
              [-37.929785749822855, -8.190360513736197],
              [-37.93059414117694, -8.19016920639309],
              [-37.930385426247966, -8.190795851104147],
              [-37.930016566901145, -8.191862059010358],
              [-37.930647580380239, -8.1922141735248],
              [-37.931831798062063, -8.192381686148593],
              [-37.932626296486148, -8.192044920342722],
              [-37.932715392603512, -8.191628193564311],
              [-37.933752438539813, -8.191250500893663],
              [-37.934276235461773, -8.190918071941905],
              [-37.935529939157689, -8.18940057500307],
              [-37.936882256705331, -8.188485280902237],
              [-37.938932583456719, -8.187450419208732],
              [-37.940678586793389, -8.187800574341654],
              [-37.941574953039854, -8.187898918032976],
              [-37.943275469752471, -8.18858543369943],
              [-37.94520670357327, -8.182301744739762],
              [-37.949325813630921, -8.168889859883855],
              [-37.949575534114423, -8.168039267795557],
              [-37.952562813108067, -8.160073620556901],
              [-37.91952563277092, -8.138039464989905],
              [-37.918633063452702, -8.141194939297451],
              [-37.917668680363008, -8.144079251823932],
              [-37.915252893884009, -8.151951571510686],
              [-37.914551651855575, -8.154281118600498],
              [-37.91446297396763, -8.155423048247439],
              [-37.913891323734106, -8.156347462318552],
              [-37.912980429214663, -8.159586156858897],
              [-37.911559410998514, -8.164068808339081],
              [-37.909812556134632, -8.169763151074914],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0162000',
        uf: 'PE',
        nome_proje: 'PA POÇO VERDE',
        municipio: 'INAJA',
        area_hecta: '827.5812',
        capacidade: 53.0,
        num_famili: 50.0,
        fase: 3.0,
        data_de_cr: '22/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '22/12/2005',
        area_calc_: 1061.6204,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.715118281789202, -8.782624237851499],
              [-37.738327762324907, -8.754794952349126],
              [-37.770197250826669, -8.716301225239892],
              [-37.769169014901323, -8.715290833261852],
              [-37.765413907603481, -8.711873683072358],
              [-37.751563476201312, -8.728488151445047],
              [-37.713937590032046, -8.773540261999367],
              [-37.71311485727626, -8.774744365722713],
              [-37.711376140266985, -8.776611095549038],
              [-37.715118281789202, -8.782624237851499],
            ],
          ],
          [
            [
              [-37.714881984262476, -8.782906714164529],
              [-37.711089942792434, -8.776947756803171],
              [-37.706611774285925, -8.782290965379676],
              [-37.703544824450447, -8.785963312995547],
              [-37.700679690264366, -8.789384276888457],
              [-37.698740447591746, -8.791740401312101],
              [-37.702942595336516, -8.794264748417325],
              [-37.704174846682633, -8.795734737071218],
              [-37.714881984262476, -8.782906714164529],
            ],
          ],
          [
            [
              [-37.698602038955599, -8.791910762723873],
              [-37.692832866593115, -8.798840563902852],
              [-37.680790108092303, -8.813275483102446],
              [-37.685294775397672, -8.815855026274997],
              [-37.686709995888386, -8.816658851861266],
              [-37.687293183359706, -8.815957053551186],
              [-37.704036821285868, -8.795887166351957],
              [-37.704053289804911, -8.795867976834746],
              [-37.702811590130466, -8.794418571616967],
              [-37.700784635523462, -8.793209271518194],
              [-37.698602038955599, -8.791910762723873],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0150000',
        uf: 'PE',
        nome_proje: 'PA ANTONIO HENRIQUE NOGUEIRA',
        municipio: 'MIRANDIBA',
        area_hecta: '821.1348',
        capacidade: 25.0,
        num_famili: 24.0,
        fase: 3.0,
        data_de_cr: '25/08/2005',
        forma_obte: 'Desapropriação',
        data_obten: '18/08/2005',
        area_calc_: 832.766,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.610753065624344, -8.156746719936473],
              [-38.611427544831997, -8.15702299637028],
              [-38.611946567364285, -8.157395287149155],
              [-38.612544599586634, -8.157720583967667],
              [-38.613359982751035, -8.158151660007272],
              [-38.613946091139958, -8.158453998879187],
              [-38.615640947869736, -8.158908765615319],
              [-38.61575501569758, -8.158881785171229],
              [-38.617239385637916, -8.158805988623435],
              [-38.619652777141404, -8.159163352617171],
              [-38.619906676637186, -8.159472672919021],
              [-38.620003804140175, -8.160445223084384],
              [-38.620192361827719, -8.161537148529202],
              [-38.620457684451324, -8.161923909103814],
              [-38.621051295844538, -8.162275371990811],
              [-38.621957637637962, -8.162616182621846],
              [-38.622744827432626, -8.163231123460843],
              [-38.623437531057974, -8.163609854720615],
              [-38.62421437586621, -8.164003542993569],
              [-38.624602462725342, -8.164141937693856],
              [-38.625165908651248, -8.164594172594665],
              [-38.625501221671932, -8.164931829749067],
              [-38.62632247931122, -8.165170106047171],
              [-38.626758181492207, -8.165607811419758],
              [-38.62748342399626, -8.165849114796206],
              [-38.627848866416429, -8.16599778471863],
              [-38.630985194591226, -8.157425402432164],
              [-38.6303963101698, -8.156810219337579],
              [-38.629158500736906, -8.155270708804672],
              [-38.628776569454558, -8.153649585720373],
              [-38.628641181430964, -8.153121306497431],
              [-38.628548947389568, -8.152594902963063],
              [-38.62799301426562, -8.149800796638878],
              [-38.627704495207148, -8.149314708661969],
              [-38.62715377651589, -8.148762683552219],
              [-38.62688103579864, -8.148374868673528],
              [-38.626722692550729, -8.148123001264439],
              [-38.626113019102348, -8.144546976111258],
              [-38.625508473188724, -8.144196917253877],
              [-38.62492866178286, -8.14415218651606],
              [-38.623578713605852, -8.144021552799781],
              [-38.622888260408793, -8.144031531069277],
              [-38.622644834678333, -8.144006131755948],
              [-38.622377675511508, -8.147685900251744],
              [-38.621599518676945, -8.150718850470893],
              [-38.611604259292541, -8.153400283689265],
              [-38.610753065624344, -8.156746719936473],
            ],
          ],
          [
            [
              [-38.610719854718901, -8.156873249639615],
              [-38.609453487542439, -8.161855628162034],
              [-38.609200527182693, -8.162850808089658],
              [-38.607914567386857, -8.167914458614955],
              [-38.607689090559568, -8.168803788636129],
              [-38.605959765405643, -8.175552024705691],
              [-38.603625838302136, -8.184828792947393],
              [-38.609424130925703, -8.187041143198261],
              [-38.610506161750081, -8.187493293985273],
              [-38.610818098371105, -8.187679927309759],
              [-38.611416934124534, -8.1878283670782],
              [-38.613168147429363, -8.187318583693264],
              [-38.613493304468413, -8.187287670774893],
              [-38.616870842351275, -8.187580284080877],
              [-38.617639298830035, -8.187295290290038],
              [-38.617861892994163, -8.187277220498814],
              [-38.619596313465834, -8.187711783266897],
              [-38.621728510511957, -8.18198953396206],
              [-38.622714076947041, -8.179342674432853],
              [-38.625233484772615, -8.173150921797214],
              [-38.625595571350196, -8.17216305905063],
              [-38.627802402993083, -8.166127605322977],
              [-38.627440293973393, -8.165977808278267],
              [-38.627053436386866, -8.165857981287802],
              [-38.626684318601008, -8.165726279632233],
              [-38.62628491228655, -8.165337908160069],
              [-38.625501906884296, -8.165058925887349],
              [-38.625429252951719, -8.165052264657685],
              [-38.62507451183766, -8.164695038816602],
              [-38.624830595161683, -8.164499404709607],
              [-38.624534939916813, -8.164261965687208],
              [-38.624101662185652, -8.164100970905388],
              [-38.623376135343612, -8.163721470811586],
              [-38.622671914961849, -8.163333224436828],
              [-38.622001957821965, -8.162827248216498],
              [-38.621890040399258, -8.162735786291298],
              [-38.62128208464609, -8.162512233697635],
              [-38.620992022903366, -8.162398103141818],
              [-38.620534992622666, -8.16213459903093],
              [-38.620328756289652, -8.161937390274408],
              [-38.620063198208989, -8.161589300033894],
              [-38.620005256879629, -8.16127375051075],
              [-38.619868785681767, -8.160463467457346],
              [-38.619775248665768, -8.159526866208314],
              [-38.619553336097667, -8.159302493135563],
              [-38.617261749839102, -8.158933774456859],
              [-38.615548690485035, -8.159019276810511],
              [-38.615422368816141, -8.158975603869342],
              [-38.614713813965885, -8.158779633919394],
              [-38.613997050894234, -8.158614770723315],
              [-38.613912971003742, -8.158598882005867],
              [-38.613312690123387, -8.158286308000077],
              [-38.612654698801791, -8.15793239785266],
              [-38.612003683679532, -8.157565694286106],
              [-38.611873888079323, -8.157510333050473],
              [-38.611400941552517, -8.157146627640072],
              [-38.610719854718901, -8.156873249639615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0010000',
        uf: 'PE',
        nome_proje: 'PA MANGA NOVA',
        municipio: 'PETROLINA',
        area_hecta: '1525.1498',
        capacidade: 25.0,
        num_famili: 23.0,
        fase: 7.0,
        data_de_cr: '03/09/1991',
        forma_obte: 'Adjudicação',
        data_obten: '04/01/1990',
        area_calc_: 1524.8857,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.381995438768207, -8.931886797006896],
              [-40.379040614175402, -8.932960572805586],
              [-40.375673091665597, -8.934277839755305],
              [-40.372216906207875, -8.935797873041292],
              [-40.368476426535914, -8.937566154391023],
              [-40.364168019853516, -8.939564730866021],
              [-40.361006949632063, -8.940774417031106],
              [-40.360929898117476, -8.940865850432404],
              [-40.356088056146014, -8.942257998348316],
              [-40.353644436997939, -8.943018020363738],
              [-40.347598470533804, -8.944787746882973],
              [-40.342526919494652, -8.946267558937619],
              [-40.334576167313081, -8.948557346436232],
              [-40.327901593083574, -8.950465045925915],
              [-40.335295133278251, -8.962876915134007],
              [-40.335334746600637, -8.962940704642001],
              [-40.335351898165754, -8.962930424357562],
              [-40.336071312030654, -8.964104925850357],
              [-40.342111019992231, -8.966147879432825],
              [-40.342141565305518, -8.966482632487523],
              [-40.343800459814801, -8.967034443668991],
              [-40.347362569466881, -8.96822570005817],
              [-40.352359578843306, -8.972876030049786],
              [-40.352873097847493, -8.973371861431682],
              [-40.353694767053298, -8.974165062971959],
              [-40.355453817460926, -8.973451923992069],
              [-40.362607612338905, -8.970863449443518],
              [-40.365060625734905, -8.96997300521992],
              [-40.367226067119809, -8.96918506917873],
              [-40.369148804417023, -8.968482389009273],
              [-40.368616552718841, -8.966314571413898],
              [-40.3695063636277, -8.965630272825535],
              [-40.368771405051376, -8.963246090922873],
              [-40.368444057849032, -8.960832185010396],
              [-40.368911111717949, -8.960067074419102],
              [-40.37399910836816, -8.958189584469583],
              [-40.382624805137937, -8.955018848529814],
              [-40.383788132723438, -8.95465071648813],
              [-40.383587405983235, -8.95204890950823],
              [-40.383375667085922, -8.949299024578647],
              [-40.383120879548308, -8.946562772907619],
              [-40.38290794783547, -8.943806650993785],
              [-40.382739848376538, -8.941048461917875],
              [-40.382485262929123, -8.938312296783995],
              [-40.382312992216761, -8.935505833772874],
              [-40.381995438768207, -8.931886797006896],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0233000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA APARECIDA II',
        municipio: 'VERDEJANTE',
        area_hecta: '2743.7313',
        capacidade: 43.0,
        num_famili: 41.0,
        fase: 3.0,
        data_de_cr: '11/09/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 2635.1815,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.953519115663681, -8.107391025085683],
              [-38.960065406538789, -8.105069529423215],
              [-38.959554915360982, -8.09981716937601],
              [-38.958914645921489, -8.100173168042],
              [-38.958106263603781, -8.100352802856731],
              [-38.957629326874915, -8.100705943357772],
              [-38.957096275630519, -8.100697950742289],
              [-38.954280050105126, -8.101428431403338],
              [-38.954054260401065, -8.101847856031295],
              [-38.953753073881678, -8.103436566602962],
              [-38.953392212771249, -8.103834818721225],
              [-38.953537428195268, -8.104599077348144],
              [-38.953223501872912, -8.105621681965815],
              [-38.953202309882997, -8.105953170737614],
              [-38.953519115663681, -8.107391025085683],
            ],
          ],
          [
            [
              [-38.943877787641419, -8.110330793760451],
              [-38.951444989821404, -8.108126218719182],
              [-38.953410686727956, -8.107430876013485],
              [-38.953092633498613, -8.105961516760656],
              [-38.953115611739442, -8.105602088654084],
              [-38.953425405934524, -8.10459294524712],
              [-38.95327513277109, -8.103802066834236],
              [-38.953651726415544, -8.103386452219389],
              [-38.953950347591167, -8.101811275427508],
              [-38.954206724746662, -8.101335032035164],
              [-38.957082165242269, -8.10058918078599],
              [-38.957594023747319, -8.100596855668142],
              [-38.958059921297476, -8.100251888938244],
              [-38.958874795739028, -8.100070811518064],
              [-38.959445018881958, -8.099744821648111],
              [-38.959228199964315, -8.097535434359305],
              [-38.959301283227077, -8.097306156213889],
              [-38.958973277224231, -8.09375219683081],
              [-38.956964009847262, -8.092730004433859],
              [-38.956301528844726, -8.092188753637553],
              [-38.957192612555616, -8.09105995702429],
              [-38.957702447538388, -8.090531994336812],
              [-38.958418700279047, -8.089997504591677],
              [-38.958564110050744, -8.08972715303628],
              [-38.958419511825475, -8.088304951718246],
              [-38.957824984970095, -8.087387288957681],
              [-38.957828812676247, -8.086370903753938],
              [-38.958066797528801, -8.085500456997593],
              [-38.957755908995352, -8.084197836840918],
              [-38.958026231983872, -8.083982006148075],
              [-38.957875644376266, -8.082426496734472],
              [-38.956479082509276, -8.081981647363909],
              [-38.955003754047873, -8.081235547523686],
              [-38.954511554682767, -8.079598399220888],
              [-38.954396066700951, -8.078921809003846],
              [-38.954623681988686, -8.077610992720647],
              [-38.954377552969568, -8.076966999412891],
              [-38.953759127835987, -8.076799946902426],
              [-38.952809317009319, -8.076894040714622],
              [-38.952280423290397, -8.07674887196049],
              [-38.95179854910559, -8.07639522871473],
              [-38.951593261354851, -8.076007353587606],
              [-38.951405481189418, -8.075186023137768],
              [-38.951582397242646, -8.073956261948352],
              [-38.951899275335272, -8.073214329741612],
              [-38.951931819014263, -8.072832126481233],
              [-38.951529883674162, -8.072130819482828],
              [-38.951269707391518, -8.071888075958826],
              [-38.951252718036827, -8.071982874244023],
              [-38.951151452707002, -8.072919353812186],
              [-38.950949949801448, -8.073333216475209],
              [-38.949727754255171, -8.073687571115856],
              [-38.948896589352444, -8.073413859354721],
              [-38.947940033262789, -8.073187130495249],
              [-38.947615655783927, -8.072957812360634],
              [-38.947073378264093, -8.072322855759964],
              [-38.946498201300045, -8.072587733279416],
              [-38.945396698514408, -8.073479647789711],
              [-38.945475276180161, -8.073030159339687],
              [-38.944042840176984, -8.063470515823672],
              [-38.942780934404553, -8.063773356033948],
              [-38.942210443220667, -8.064339333693436],
              [-38.939495088465776, -8.062139868875398],
              [-38.937310520650179, -8.060256842219381],
              [-38.937216181784422, -8.059884024922146],
              [-38.936479798402893, -8.059027576444631],
              [-38.936412688440697, -8.058527067782673],
              [-38.936168875459401, -8.05810638710272],
              [-38.935262823392286, -8.057749557183881],
              [-38.93452253636854, -8.057551862103516],
              [-38.934208825879033, -8.057029526729554],
              [-38.934056266671718, -8.056024042365296],
              [-38.932898524707682, -8.055324797784882],
              [-38.929737195445306, -8.054450718228537],
              [-38.929479531182515, -8.05467910200198],
              [-38.928495458919286, -8.055014641706261],
              [-38.927730270260184, -8.054928034628334],
              [-38.926582229907488, -8.054440381164984],
              [-38.925884309566833, -8.053278160917138],
              [-38.9260667631671, -8.051938901044712],
              [-38.925956994152209, -8.050913476799138],
              [-38.925101398316691, -8.048962670009512],
              [-38.924727004251224, -8.047410630439659],
              [-38.924273516336221, -8.046470096937396],
              [-38.923415347132092, -8.046026461624161],
              [-38.922389991177518, -8.04511345903426],
              [-38.922117343719208, -8.044605581316729],
              [-38.922183110447087, -8.04378215621904],
              [-38.922444941571847, -8.043302717452756],
              [-38.922512273382537, -8.041938705589756],
              [-38.921819406975096, -8.040721373507168],
              [-38.921578828233024, -8.040036432962824],
              [-38.921300592493388, -8.03950569609394],
              [-38.920774959238699, -8.039139853615303],
              [-38.919946651443169, -8.03809572313425],
              [-38.918790753445833, -8.03780094332385],
              [-38.918286512858764, -8.037234099588654],
              [-38.917908902847437, -8.036611049986464],
              [-38.917206715730259, -8.036199076558272],
              [-38.915152135275036, -8.036077947303749],
              [-38.914468752583211, -8.036007689829773],
              [-38.913766790987196, -8.036048091580861],
              [-38.912851251406771, -8.035761034840272],
              [-38.912462598381033, -8.035577443494285],
              [-38.910021418740378, -8.03639948068362],
              [-38.909759901368346, -8.036583291805128],
              [-38.909390032838239, -8.037344997606711],
              [-38.908900145117407, -8.037929682638955],
              [-38.908829479366936, -8.038441761352603],
              [-38.909207234622812, -8.039239189991537],
              [-38.909558795620065, -8.039850944045165],
              [-38.90983842827255, -8.040536113593955],
              [-38.911088667365739, -8.042167420846686],
              [-38.911147468382957, -8.042869321073006],
              [-38.911304577947199, -8.043430892146656],
              [-38.91136166681337, -8.044162705484236],
              [-38.911546780524041, -8.044572914680309],
              [-38.912158412809909, -8.045116048743623],
              [-38.913171061307828, -8.046060064992073],
              [-38.913971938940868, -8.046437639925417],
              [-38.913478865769228, -8.047257359280531],
              [-38.914304695295925, -8.048754964551494],
              [-38.914795248005241, -8.049661726538988],
              [-38.915360597000493, -8.050689116347133],
              [-38.91578979234091, -8.051472754895778],
              [-38.91606278980624, -8.051545726770749],
              [-38.916214825257278, -8.051630107329157],
              [-38.916705197283108, -8.052259386520047],
              [-38.918893659224118, -8.053392622737938],
              [-38.920241094505649, -8.054086868352949],
              [-38.920690538384534, -8.054560416180481],
              [-38.92142042547998, -8.054828313115552],
              [-38.921901856826565, -8.05500454849051],
              [-38.923550968000697, -8.055854568305703],
              [-38.92219623003821, -8.056508766112772],
              [-38.922255002586013, -8.057041585587616],
              [-38.922183924916297, -8.058163705139812],
              [-38.922058147484208, -8.05885678520378],
              [-38.920946324006977, -8.05967597886943],
              [-38.920700922295964, -8.062199649366274],
              [-38.916970710144994, -8.064255680441608],
              [-38.916977563085979, -8.064386036770838],
              [-38.916997139363843, -8.064877745970277],
              [-38.916927452761776, -8.065331129308674],
              [-38.916654405002532, -8.066211392519],
              [-38.916417755726229, -8.066580808395514],
              [-38.916201865474811, -8.067333523034897],
              [-38.916009815256253, -8.067743001306388],
              [-38.91520652981032, -8.068736865498121],
              [-38.914760856942017, -8.069021842368754],
              [-38.914038350257925, -8.069291862770898],
              [-38.913144095963858, -8.069950458843628],
              [-38.91264331402823, -8.070558011802767],
              [-38.912789212723318, -8.071059209542053],
              [-38.913438867077545, -8.071826334511471],
              [-38.914512913608824, -8.07228504103611],
              [-38.914849190898131, -8.072719901769716],
              [-38.91492407467495, -8.073167950614135],
              [-38.913675324800238, -8.074096572795304],
              [-38.913134747773853, -8.075048444220249],
              [-38.913233764399635, -8.075832566293617],
              [-38.913152226399028, -8.076672035695374],
              [-38.913249840456814, -8.076970431605394],
              [-38.913756539228153, -8.077383954720949],
              [-38.914830932334489, -8.078642292649338],
              [-38.91508250503199, -8.079172212821824],
              [-38.915222602133937, -8.079798127826404],
              [-38.915485347882004, -8.080245744960299],
              [-38.915833021046524, -8.080692085899956],
              [-38.916734825111341, -8.081642320321714],
              [-38.916962068022372, -8.08195298026031],
              [-38.917368524513826, -8.082388839031179],
              [-38.917489712314691, -8.082741068476933],
              [-38.917516298705692, -8.082955217212252],
              [-38.917572913572116, -8.083462708791691],
              [-38.91802513519292, -8.083763192000756],
              [-38.919181561873962, -8.083614623620468],
              [-38.919861852027545, -8.083148280636978],
              [-38.92043433799715, -8.082791601378544],
              [-38.920956967565274, -8.082617748552043],
              [-38.921585752052351, -8.08212033040243],
              [-38.922657906713972, -8.08188610210836],
              [-38.923233767280422, -8.080844213035613],
              [-38.92336866298092, -8.080484986825267],
              [-38.924015107273533, -8.080210558081315],
              [-38.924573099377604, -8.079953922614404],
              [-38.926043908221679, -8.079637960106027],
              [-38.927247345151208, -8.079434534307806],
              [-38.928169942332346, -8.080268150846704],
              [-38.928749648997737, -8.081991539811707],
              [-38.929297445745355, -8.08242539429606],
              [-38.929738764379579, -8.082573744386055],
              [-38.931062188521786, -8.082901335753858],
              [-38.931818661858188, -8.08225919508992],
              [-38.931958445245655, -8.081667415756318],
              [-38.93209343382847, -8.081359764144267],
              [-38.933247705689681, -8.080224154383176],
              [-38.934666481899718, -8.080034011601368],
              [-38.937965732754868, -8.079099499551306],
              [-38.940057336624697, -8.090071809567732],
              [-38.940253346277807, -8.091098132745506],
              [-38.943877787641419, -8.110330793760451],
            ],
          ],
          [
            [
              [-38.95608191361039, -8.06291549308024],
              [-38.955979343638028, -8.061857047207219],
              [-38.955636459701779, -8.058426359173028],
              [-38.955577684598296, -8.057847502468139],
              [-38.955459928554241, -8.056750701540363],
              [-38.955382028597832, -8.055948653356447],
              [-38.953145894704946, -8.031799420814279],
              [-38.951327343595302, -8.031217337420358],
              [-38.950794727026576, -8.031252036067436],
              [-38.949302084614445, -8.030629377168431],
              [-38.94831011077526, -8.030886219366481],
              [-38.94754657430893, -8.030376062997188],
              [-38.946568897054156, -8.030188142812996],
              [-38.946127658273134, -8.029884580377741],
              [-38.949778033651228, -8.054947615547711],
              [-38.949895174515866, -8.055752310859518],
              [-38.950053678135411, -8.056842482883221],
              [-38.950137628190916, -8.057416129217733],
              [-38.951060258824697, -8.063767175331552],
              [-38.95144532028165, -8.063752781607697],
              [-38.951771020398454, -8.063758608164676],
              [-38.952580490216, -8.063853455943011],
              [-38.952847743432613, -8.063943828055427],
              [-38.9532535963369, -8.064282293004792],
              [-38.953572338443919, -8.064326974286255],
              [-38.953966196773479, -8.064329187848985],
              [-38.954321581850657, -8.064273434549021],
              [-38.954671782851875, -8.064130565973505],
              [-38.955377766062711, -8.063579524653596],
              [-38.955720135574403, -8.063276259140181],
              [-38.95608191361039, -8.06291549308024],
            ],
          ],
          [
            [
              [-38.939406551752469, -8.031219164687208],
              [-38.93877339177758, -8.031306079958576],
              [-38.937864238009254, -8.030912336237501],
              [-38.936844964116439, -8.030368535329897],
              [-38.935948119940427, -8.030034372218154],
              [-38.935771750593062, -8.029996561526069],
              [-38.935179396590101, -8.030350818442153],
              [-38.934470324019379, -8.030321380507031],
              [-38.934380303028348, -8.031424460087417],
              [-38.933982349954199, -8.032198070022574],
              [-38.933410518506669, -8.032724292254317],
              [-38.932665460860321, -8.032902802172783],
              [-38.931065108412319, -8.03222978459206],
              [-38.929312918330908, -8.032327730790463],
              [-38.928798621739851, -8.031787768490277],
              [-38.928159910338714, -8.031326552345526],
              [-38.927897864276154, -8.030815994684264],
              [-38.926755099631819, -8.030530261534885],
              [-38.926169411730093, -8.030709719745872],
              [-38.925213275028263, -8.030991143563845],
              [-38.924502030585622, -8.030926989389064],
              [-38.924260868088048, -8.031021371385084],
              [-38.923946654323153, -8.031046170549171],
              [-38.92361955736645, -8.03100330649683],
              [-38.923114282068234, -8.031136507357283],
              [-38.92229574539072, -8.031484950309579],
              [-38.921513056234794, -8.031492327625037],
              [-38.91896118332469, -8.031902536205823],
              [-38.912617088118573, -8.035527228441747],
              [-38.91289060721067, -8.035659584206591],
              [-38.913780434534267, -8.035938579177163],
              [-38.914471216186655, -8.035898820866949],
              [-38.915160692004335, -8.03596970477334],
              [-38.916038343994337, -8.03601740699211],
              [-38.917238648031478, -8.036091856078951],
              [-38.917987879163363, -8.036531430211175],
              [-38.918374591083946, -8.037169497615393],
              [-38.918850734986378, -8.037704756279512],
              [-38.92000888808132, -8.037999167445138],
              [-38.920850446992787, -8.039060001596404],
              [-38.921384779724669, -8.039431898855383],
              [-38.921679006743524, -8.039993138988047],
              [-38.92191896040913, -8.040676299445296],
              [-38.922622600525322, -8.041912559771992],
              [-38.922552492216631, -8.043332825787513],
              [-38.922289834632082, -8.043813778145434],
              [-38.922228441219694, -8.044582450202212],
              [-38.922476921686943, -8.045045310314803],
              [-38.923477670538077, -8.045936401970243],
              [-38.924355929652499, -8.046390422771383],
              [-38.924830183806776, -8.047374025002489],
              [-38.925205039317632, -8.048927975886045],
              [-38.926063506135151, -8.050885327457003],
              [-38.926176456501906, -8.051940468182419],
              [-38.925997363422994, -8.053255063621577],
              [-38.926657497634835, -8.054354359636266],
              [-38.92775834961607, -8.054821968825955],
              [-38.92848339562169, -8.054904032405863],
              [-38.929423537659872, -8.054583471618571],
              [-38.929710951451298, -8.054328718978844],
              [-38.930941234362649, -8.054714042871838],
              [-38.932938662805462, -8.055222118826288],
              [-38.934156325346457, -8.055957553669833],
              [-38.934313300022978, -8.056992137802537],
              [-38.934593328790541, -8.057458392162166],
              [-38.935297040882645, -8.057646319784578],
              [-38.936244197570346, -8.058019337851007],
              [-38.936517781519413, -8.058491385893046],
              [-38.936583477266161, -8.058981346376378],
              [-38.937315528250053, -8.059832755746143],
              [-38.937407728915879, -8.060197122781071],
              [-38.93956507136128, -8.062056681609182],
              [-38.942203072654593, -8.06419348961037],
              [-38.942726053209228, -8.063674646610794],
              [-38.944024823807872, -8.063343903190754],
              [-38.943378953471502, -8.058865457440973],
              [-38.941373360145725, -8.055840858815916],
              [-38.940958979443188, -8.055227268554956],
              [-38.940806962487819, -8.055033030176775],
              [-38.940449825439813, -8.055153338250788],
              [-38.93980170123379, -8.055347894312927],
              [-38.939598921637796, -8.055049959186309],
              [-38.938662456145018, -8.053958568087314],
              [-38.939310266138612, -8.053059114022167],
              [-38.940175205676333, -8.05192608081579],
              [-38.942151167196485, -8.05027421663581],
              [-38.941996371796343, -8.04939619968631],
              [-38.939406606203448, -8.031219164695244],
              [-38.939406551752469, -8.031219164687208],
            ],
          ],
          [
            [
              [-38.956093417334301, -8.063041199958134],
              [-38.955793415515345, -8.063356569443318],
              [-38.955448606247614, -8.063661996099688],
              [-38.954725429374456, -8.064225968119008],
              [-38.954351074757689, -8.064378690448541],
              [-38.95397441079713, -8.064437782010378],
              [-38.953564411235718, -8.064435477730806],
              [-38.953214859197118, -8.064386477504183],
              [-38.952796659571071, -8.064041177793717],
              [-38.952555830870558, -8.06395974117021],
              [-38.951762798309375, -8.063867024838844],
              [-38.951449729645027, -8.063861424249668],
              [-38.951079348924559, -8.063889066031724],
              [-38.952080913491649, -8.0707952952804],
              [-38.952182703316382, -8.071596884960988],
              [-38.951722214701235, -8.071137806982344],
              [-38.951390342528335, -8.071190105095086],
              [-38.951292104202281, -8.071755995356238],
              [-38.951602993112168, -8.072040752850343],
              [-38.952043216843336, -8.072807542354035],
              [-38.952006322824779, -8.073240838753986],
              [-38.951688187103741, -8.073985716033519],
              [-38.951516072739629, -8.075182102420101],
              [-38.95169443297091, -8.075965851989645],
              [-38.951883234728861, -8.076322577977974],
              [-38.952328774571363, -8.076649555725972],
              [-38.952818703883025, -8.076784029673822],
              [-38.953768287008508, -8.076689958369892],
              [-38.954459587528866, -8.07687669635704],
              [-38.954547058680681, -8.077105563627095],
              [-38.954579861223841, -8.07719286174973],
              [-38.954736094203327, -8.077600171968061],
              [-38.954506572995108, -8.078921966447263],
              [-38.954617805878577, -8.07957362699949],
              [-38.955094683383635, -8.081159810694839],
              [-38.956520448654615, -8.081880845415958],
              [-38.957866682305543, -8.082287719511656],
              [-38.95714353352075, -8.074488409463781],
              [-38.957131707504594, -8.074400621463914],
              [-38.956093417334301, -8.063041199958134],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0157000',
        uf: 'PE',
        nome_proje: 'PA JUVENTUDE',
        municipio: 'CABROBO',
        area_hecta: '1351.5749',
        capacidade: 40.0,
        num_famili: 38.0,
        fase: 3.0,
        data_de_cr: '08/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2005',
        area_calc_: 1346.2293,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.515274644677298, -8.526645279070237],
              [-39.520799905266067, -8.519750713703738],
              [-39.523527016593007, -8.516306851566176],
              [-39.518475710862646, -8.510067395670106],
              [-39.518242858173522, -8.510248847563133],
              [-39.516802367468671, -8.511995096123284],
              [-39.51692978492914, -8.512751566899396],
              [-39.515051737310095, -8.514801118664286],
              [-39.514578350254546, -8.515223770878682],
              [-39.512581778056592, -8.516241058943383],
              [-39.511105834753721, -8.517098042334089],
              [-39.515274644677298, -8.526645279070221],
              [-39.515274644677298, -8.526645279070237],
            ],
          ],
          [
            [
              [-39.515347463483579, -8.52680974648449],
              [-39.515347463483586, -8.526809746484508],
              [-39.516034646350761, -8.528553941578908],
              [-39.516244312693246, -8.529083426172125],
              [-39.518978164570449, -8.535913769169504],
              [-39.527020356004861, -8.540355042326846],
              [-39.527246513332607, -8.540479932932385],
              [-39.52780908555755, -8.539797072003044],
              [-39.528689037473619, -8.539137753859672],
              [-39.5296203050804, -8.538839899031762],
              [-39.530216210151032, -8.538299083505276],
              [-39.531030361514333, -8.538101214601216],
              [-39.531584186044839, -8.537673317495249],
              [-39.532433339154601, -8.536926967129608],
              [-39.532976825047925, -8.537452373338066],
              [-39.533278749963493, -8.537192079103384],
              [-39.533826810360779, -8.537248516006521],
              [-39.534387502678115, -8.536600669084789],
              [-39.5350300275975, -8.535791645426153],
              [-39.53556631672862, -8.535885379429832],
              [-39.536394989010454, -8.535791885289395],
              [-39.537129628493936, -8.535479053483277],
              [-39.537471826573281, -8.534645559421936],
              [-39.538144609428642, -8.534343602177046],
              [-39.537974361161389, -8.53418277531101],
              [-39.523636425484291, -8.51644451314335],
              [-39.521030663713013, -8.519750404281844],
              [-39.515347463483579, -8.52680974648449],
            ],
          ],
          [
            [
              [-39.485885015776432, -8.431831516740312],
              [-39.483851218511823, -8.433486777280278],
              [-39.48270288666113, -8.434414214960428],
              [-39.481533375982266, -8.43539435391386],
              [-39.481661237622838, -8.435624465832811],
              [-39.492241498047541, -8.465594755920154],
              [-39.495351223487944, -8.474398105614334],
              [-39.495546181660679, -8.474954705556296],
              [-39.509480554171034, -8.514375791439143],
              [-39.50952863655499, -8.514511802824408],
              [-39.509727870639523, -8.515005243625918],
              [-39.510194537784898, -8.514826816224561],
              [-39.510388217244532, -8.515328820402662],
              [-39.510576961071521, -8.515824083431447],
              [-39.510770541917722, -8.516335150587999],
              [-39.511032488541332, -8.516937201560109],
              [-39.512476144686062, -8.516069770166073],
              [-39.514475168094791, -8.515072898505901],
              [-39.514923625905489, -8.514672503876554],
              [-39.516735949527494, -8.512694679277478],
              [-39.516609486427839, -8.511943872045467],
              [-39.518115992163501, -8.510117597286314],
              [-39.518269469520966, -8.509990507941415],
              [-39.518365014806577, -8.509929218532978],
              [-39.517179753982148, -8.508463331515861],
              [-39.516832005991382, -8.507989437231943],
              [-39.517179790777227, -8.507352916615554],
              [-39.517398469751882, -8.506949780943897],
              [-39.517622755072438, -8.506716529390054],
              [-39.517939945129498, -8.506513210490446],
              [-39.517691530092286, -8.506145338445663],
              [-39.517633417798933, -8.506002984607012],
              [-39.500439852927045, -8.464187823215624],
              [-39.500237288074111, -8.463664701786245],
              [-39.499912667408815, -8.462913422023663],
              [-39.498240620356235, -8.458897234717757],
              [-39.485992065507169, -8.432082076696474],
              [-39.485885015776432, -8.431831516740312],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0138000',
        uf: 'PE',
        nome_proje: 'PA CARAIBAS III',
        municipio: 'IBIMIRIM',
        area_hecta: '1419.9229',
        capacidade: 28.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '19/05/2005',
        forma_obte: 'Desapropriação',
        data_obten: '11/08/2004',
        area_calc_: 1466.4666,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.656970662531663, -8.407900112419002],
              [-37.656984546262713, -8.408482637125871],
              [-37.678570638987473, -8.411497557671662],
              [-37.679271150728411, -8.411592100182794],
              [-37.680794708841425, -8.411800072292365],
              [-37.691412618082786, -8.413242263860791],
              [-37.690737042181617, -8.412561235813666],
              [-37.689000169993079, -8.410745766804144],
              [-37.687882886357336, -8.410308905220594],
              [-37.68722521414216, -8.40985140973039],
              [-37.685409845412771, -8.407783423678294],
              [-37.684084083156996, -8.407586142910374],
              [-37.683342845916137, -8.405884196642887],
              [-37.683142024662047, -8.405132694230851],
              [-37.683646140553613, -8.403293650258872],
              [-37.683712041329244, -8.402697352616862],
              [-37.677708203245153, -8.401044373683575],
              [-37.677940293525808, -8.399448176764551],
              [-37.679007803651274, -8.398536592368611],
              [-37.678936524291416, -8.398370458387468],
              [-37.669451132373759, -8.39718035579387],
              [-37.669216655676273, -8.397728608669762],
              [-37.668941739271055, -8.397885190999002],
              [-37.667915644667481, -8.397749701811174],
              [-37.666597785813416, -8.397808389876715],
              [-37.666023550807907, -8.398088033033282],
              [-37.665272770388988, -8.397949361506232],
              [-37.66422024157248, -8.397124748264861],
              [-37.663230340315145, -8.396888490149653],
              [-37.662097300730991, -8.396853665852378],
              [-37.661432807628614, -8.397371525156508],
              [-37.660650390924353, -8.398022419599062],
              [-37.660170846770022, -8.398717605195301],
              [-37.659564856209236, -8.399318832310668],
              [-37.659225850115845, -8.400307615547469],
              [-37.658222306371663, -8.401975941619652],
              [-37.658120481129323, -8.402421393787362],
              [-37.658268773281485, -8.403662670277894],
              [-37.658221835287264, -8.405165375859896],
              [-37.657621065415647, -8.405938762304659],
              [-37.656970662531663, -8.407900112419002],
            ],
          ],
          [
            [
              [-37.689033436512474, -8.41061662739139],
              [-37.690812275296224, -8.412482621449362],
              [-37.691536516543039, -8.413261648599164],
              [-37.69357865619444, -8.413526963276357],
              [-37.744093841481281, -8.420670800335115],
              [-37.74346251074347, -8.419150488916218],
              [-37.744023509855722, -8.418068156905331],
              [-37.745036835844765, -8.41711276168563],
              [-37.746187844967913, -8.414525978399181],
              [-37.747754867671318, -8.411346379162863],
              [-37.748190582061781, -8.411148189892199],
              [-37.753568105543856, -8.410351289903211],
              [-37.753901788448353, -8.409361327093844],
              [-37.755104131875953, -8.408149368897305],
              [-37.690771433072513, -8.399787664475742],
              [-37.689700062463693, -8.4012233785303],
              [-37.6888442813963, -8.401681212285043],
              [-37.687869447202402, -8.401666218273066],
              [-37.687284399101685, -8.401719545516684],
              [-37.68485940051427, -8.401668860705479],
              [-37.684290948789339, -8.401731771049823],
              [-37.684118525261376, -8.401850081158985],
              [-37.683817573374327, -8.402724816593187],
              [-37.683751797173123, -8.403320986648502],
              [-37.683262844969562, -8.405064541021096],
              [-37.683445997629946, -8.405848210580526],
              [-37.684160369778041, -8.407487773655593],
              [-37.685502205489733, -8.407687445946756],
              [-37.687297871691278, -8.409769579443344],
              [-37.687933808143931, -8.410211954840531],
              [-37.689033436512474, -8.41061662739139],
            ],
          ],
          [
            [
              [-37.655399252321324, -8.402526394758427],
              [-37.655028769057097, -8.40379078355808],
              [-37.654996876282475, -8.4038960523289],
              [-37.654524593430367, -8.40521443145559],
              [-37.65417588236599, -8.406286060664856],
              [-37.655110615150576, -8.408231397061099],
              [-37.656859600060983, -8.408463012328706],
              [-37.65685296720968, -8.407877557544872],
              [-37.657513135080968, -8.405882863025578],
              [-37.658103128402203, -8.405133077538151],
              [-37.658149869256889, -8.403662534682363],
              [-37.658011909138814, -8.402507739377283],
              [-37.658006851568338, -8.402388823321214],
              [-37.658111369014314, -8.401931594168781],
              [-37.659117068798743, -8.400259684611061],
              [-37.659467519293877, -8.399249456912925],
              [-37.660079751082939, -8.39864203777049],
              [-37.660567323764383, -8.397935213515092],
              [-37.661349079065019, -8.397286303782305],
              [-37.662072282666699, -8.396720329108971],
              [-37.663252494551493, -8.396771785036924],
              [-37.664244001788404, -8.396991183444635],
              [-37.665318930250336, -8.39783832383481],
              [-37.666006728273977, -8.397965362292487],
              [-37.666606970103189, -8.397673054103969],
              [-37.667925899348162, -8.397631764096428],
              [-37.668911843179423, -8.397766789212543],
              [-37.669123657743029, -8.397646147385327],
              [-37.669353416193957, -8.397169578591814],
              [-37.656147083600487, -8.395539757401515],
              [-37.655631440227566, -8.397137411559275],
              [-37.655399252321324, -8.402526394758427],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0211000',
        uf: 'PE',
        nome_proje: 'PA MORORO DOS PEIXOTOS',
        municipio: 'TERRA NOVA',
        area_hecta: '2040.6141',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '03/01/2007',
        forma_obte: 'Desapropriação',
        data_obten: '18/05/2006',
        area_calc_: 2030.5909,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.495219113535732, -8.214396784901107],
              [-39.495533689756599, -8.213408153674623],
              [-39.496247465370622, -8.212566655988397],
              [-39.497659442251923, -8.213293380716086],
              [-39.497919604658904, -8.214099350907368],
              [-39.49867185269342, -8.214829057990059],
              [-39.498946642914589, -8.216113442116828],
              [-39.4992203719424, -8.217643999343007],
              [-39.499839583744752, -8.218706322211458],
              [-39.500470801652334, -8.218798195700801],
              [-39.500789815984376, -8.218395824365237],
              [-39.502260412807935, -8.217653421747473],
              [-39.503007852359765, -8.21714173714423],
              [-39.50258541267484, -8.216418266312697],
              [-39.502896899965421, -8.216162831858201],
              [-39.50361240485789, -8.215960511264196],
              [-39.504353468608031, -8.215378940742147],
              [-39.505576961262058, -8.214706348245695],
              [-39.507022456210969, -8.213661293227952],
              [-39.508060057908637, -8.21386742565228],
              [-39.509424291575833, -8.213637945461166],
              [-39.510203444534909, -8.213353629536694],
              [-39.51110079831448, -8.21341293064734],
              [-39.512491939511484, -8.212901524268803],
              [-39.514067530392232, -8.212656572828452],
              [-39.515174728136948, -8.212466295672494],
              [-39.51649886445886, -8.213252328221516],
              [-39.516080252314659, -8.214333085826022],
              [-39.516933623973181, -8.214261384671861],
              [-39.518391608452347, -8.213610858177953],
              [-39.519421754399026, -8.213931155916214],
              [-39.520425220086345, -8.21481687644398],
              [-39.521232532678248, -8.215947861851079],
              [-39.522953759440263, -8.216400253342162],
              [-39.523805855683342, -8.216628103526935],
              [-39.524794617498095, -8.216392203627986],
              [-39.526373965115646, -8.214910128937889],
              [-39.52664663427646, -8.214601587931323],
              [-39.527962250222522, -8.213694089183583],
              [-39.528464702059104, -8.213034015799634],
              [-39.529663207240283, -8.211926190393282],
              [-39.52973172993773, -8.211381712632715],
              [-39.530403479998128, -8.211161005564852],
              [-39.530427751384806, -8.210691741644855],
              [-39.530910518738281, -8.202103346374944],
              [-39.530913774208813, -8.202045779136585],
              [-39.531359000518577, -8.194660346289718],
              [-39.531865240333978, -8.185664559777647],
              [-39.532077632717915, -8.182022706245617],
              [-39.522014347108687, -8.175681162048276],
              [-39.518836962184189, -8.173449759469085],
              [-39.519234409235686, -8.17108616682347],
              [-39.519762240285509, -8.167741752517824],
              [-39.513017502432895, -8.168162616823235],
              [-39.509799596518747, -8.167880504044442],
              [-39.508560447965124, -8.167687375480813],
              [-39.508426578801256, -8.166699073091758],
              [-39.507884753924365, -8.163017486655219],
              [-39.507531727613923, -8.162916252836856],
              [-39.502767717837727, -8.160954604708772],
              [-39.500528277144419, -8.160240369101119],
              [-39.495585280147878, -8.159073949833761],
              [-39.495364028753798, -8.168157500980543],
              [-39.495358235059093, -8.168288427619439],
              [-39.495181813838535, -8.17707038208815],
              [-39.495016317403689, -8.184682972319038],
              [-39.49436916737799, -8.214659722544708],
              [-39.494269408990057, -8.21511569267823],
              [-39.495219113535732, -8.214396784901107],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0302000',
        uf: 'PE',
        nome_proje: 'PA JOSE ALMEIDA',
        municipio: 'PETROLINA',
        area_hecta: '1602.8754',
        capacidade: 100.0,
        num_famili: 95.0,
        fase: 3.0,
        data_de_cr: '12/06/2012',
        forma_obte: 'Desapropriação',
        data_obten: '26/11/2010',
        area_calc_: 1603.6179,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.677242367175616, -9.373264011497465],
              [-40.677211660597912, -9.379399364205904],
              [-40.677169103481297, -9.385433147808126],
              [-40.677180207145874, -9.389426972007316],
              [-40.661891427559773, -9.389354596412185],
              [-40.658593828982532, -9.389354891640508],
              [-40.662885381152599, -9.396784492708914],
              [-40.676964252740646, -9.423307568174904],
              [-40.67871060328595, -9.422624686873695],
              [-40.681743960627188, -9.428975860515484],
              [-40.684494638369493, -9.42788048264039],
              [-40.686128322153408, -9.427171611479764],
              [-40.688542265396954, -9.426152950518224],
              [-40.691860766199483, -9.424774253029714],
              [-40.694518511350758, -9.423588842422191],
              [-40.695334684640578, -9.423251297910634],
              [-40.699540069945051, -9.433839436450786],
              [-40.699574406286047, -9.433915668088904],
              [-40.701003473962693, -9.437440674069297],
              [-40.701073545321719, -9.437673776725957],
              [-40.701137484075659, -9.437881774720479],
              [-40.704722045716075, -9.444127458947046],
              [-40.704965373964534, -9.443670065681316],
              [-40.701738838807209, -9.437591087988254],
              [-40.701732782919535, -9.43704340704144],
              [-40.700365322030855, -9.433567652629437],
              [-40.700328050878746, -9.433505720230203],
              [-40.699570045013509, -9.431593477886173],
              [-40.699225585086126, -9.43069934648317],
              [-40.695893494782084, -9.422231661261995],
              [-40.697440653031791, -9.422292924307138],
              [-40.699059118814155, -9.422394612104817],
              [-40.703126050320805, -9.432211248694189],
              [-40.703159475766988, -9.43228730346627],
              [-40.705050623923476, -9.436748607141485],
              [-40.705236479812136, -9.43728872532831],
              [-40.70578785376631, -9.439259541204018],
              [-40.707736419439833, -9.442660562041759],
              [-40.707878260714921, -9.442544961069057],
              [-40.705963749742715, -9.439236627931193],
              [-40.705409171631253, -9.437262482822659],
              [-40.705328012800614, -9.43701569279354],
              [-40.705229189050662, -9.436731739040269],
              [-40.703319935593392, -9.432218085918683],
              [-40.703301280313717, -9.432146028193763],
              [-40.699095287832094, -9.422008110409324],
              [-40.699948632967519, -9.42177037214201],
              [-40.699971962242863, -9.421811758347514],
              [-40.70106781476052, -9.421508203609697],
              [-40.691310793071487, -9.39741064271124],
              [-40.698092937086109, -9.397520324534874],
              [-40.701433167618582, -9.397596562590325],
              [-40.70212318473498, -9.397632838659391],
              [-40.706260367515554, -9.397700850026045],
              [-40.718633654158985, -9.397926928033831],
              [-40.71997647692416, -9.397950679801005],
              [-40.721141416080968, -9.397977553445504],
              [-40.721129940876963, -9.391893984441054],
              [-40.687154839275273, -9.391683934053255],
              [-40.687592199942706, -9.373215114680834],
              [-40.684770078319289, -9.373228461063702],
              [-40.677242367175616, -9.373264011497465],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0075000',
        uf: 'PE',
        nome_proje: 'PA SÃO PAULO',
        municipio: 'PETROLINA',
        area_hecta: '352.6659',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '11/03/2003',
        forma_obte: 'Desapropriação',
        data_obten: '16/07/2002',
        area_calc_: 352.6839,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.588188573379888, -9.399965348556401],
              [-40.593781160526021, -9.397531114310704],
              [-40.596208831005328, -9.395215092618312],
              [-40.598975639702374, -9.39524507884027],
              [-40.600134403160055, -9.392686585251095],
              [-40.572941130304429, -9.392570309497705],
              [-40.572437567185958, -9.395784336541364],
              [-40.572215467905352, -9.396988230283675],
              [-40.571982216666946, -9.39818258949737],
              [-40.571282080904624, -9.401742793531099],
              [-40.5700751534185, -9.407343881340784],
              [-40.569823022087519, -9.408694474675128],
              [-40.574975988403381, -9.410874695643063],
              [-40.578179149095369, -9.408649426288115],
              [-40.580526650580204, -9.405340257039127],
              [-40.583587688945798, -9.405778257915877],
              [-40.583916013530683, -9.405536819933722],
              [-40.585356540445638, -9.403799062928105],
              [-40.588188573379888, -9.399965348556401],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0169000',
        uf: 'PE',
        nome_proje: 'PA LAGOA DA BARAÚNA',
        municipio: 'LAGOA GRANDE',
        area_hecta: '608.2816',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '03/04/2006',
        forma_obte: 'Arrecadação',
        data_obten: '03/04/2006',
        area_calc_: 608.1461,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.350166624656076, -8.741860995442616],
              [-40.337388055414323, -8.73900418504385],
              [-40.340828349009776, -8.730611633970346],
              [-40.340577733298481, -8.729864673584879],
              [-40.34711932724273, -8.726609222724802],
              [-40.348235352358031, -8.721592352997177],
              [-40.342242353227, -8.716036027161445],
              [-40.340018533120777, -8.714148035009865],
              [-40.337157189173723, -8.713391094667891],
              [-40.333233758282617, -8.712686056584523],
              [-40.329644992021549, -8.714427815741356],
              [-40.324067314957553, -8.717671823017103],
              [-40.325104190222305, -8.71883999178046],
              [-40.325265177930731, -8.720033188413522],
              [-40.329633584852822, -8.722845460810111],
              [-40.331243718303099, -8.729524792972637],
              [-40.332724551677146, -8.737422641455511],
              [-40.333094113831635, -8.739492799541347],
              [-40.333930836292659, -8.740428056036283],
              [-40.334654177624209, -8.74119840530272],
              [-40.33718577268791, -8.743911026376962],
              [-40.336459658832993, -8.753605482543126],
              [-40.341088548379027, -8.757870630531174],
              [-40.350166624656076, -8.741860995442616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0306000',
        uf: 'PE',
        nome_proje: 'PA BAIXA DA FAVARIA',
        municipio: 'DORMENTES',
        area_hecta: '109.3099',
        capacidade: 3.0,
        num_famili: 3.0,
        fase: 3.0,
        data_de_cr: '26/12/2012',
        forma_obte: 'Confisco',
        data_obten: '26/12/2012',
        area_calc_: 109.3351,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.384051101741896, -8.472758674390642],
              [-40.384091938412126, -8.473454873274321],
              [-40.383851986156785, -8.47422853358394],
              [-40.383860554527125, -8.474856880887213],
              [-40.383856133354669, -8.47640678441126],
              [-40.383769276114492, -8.477141243992509],
              [-40.383750055460354, -8.477539567661251],
              [-40.383744134481859, -8.477725238068455],
              [-40.38997449795081, -8.478467859615506],
              [-40.390566319908302, -8.474725472914022],
              [-40.390777164325662, -8.473240977435839],
              [-40.390749751974028, -8.472412952845051],
              [-40.390722300192294, -8.471583724814902],
              [-40.390783777941188, -8.471496315373505],
              [-40.390826116101579, -8.47143611869466],
              [-40.391098110905624, -8.471049394153551],
              [-40.391187805860717, -8.470879054371512],
              [-40.391717535084155, -8.469873042132704],
              [-40.392075906176416, -8.469162347893555],
              [-40.392135872059285, -8.468953308645139],
              [-40.391915220338852, -8.465829614047188],
              [-40.390410765782114, -8.466109536107936],
              [-40.388724352104745, -8.465745924893248],
              [-40.384736801606287, -8.46488612794117],
              [-40.384263898008072, -8.466241615724659],
              [-40.384223652015081, -8.466356972801528],
              [-40.384148375853115, -8.466720562600759],
              [-40.384163946250247, -8.466726439554623],
              [-40.38361894010643, -8.469526036155205],
              [-40.383606441751184, -8.469590237620606],
              [-40.383588353433076, -8.469777027279276],
              [-40.383629837885017, -8.469963687330704],
              [-40.383999245980391, -8.470735568538325],
              [-40.384100869224127, -8.47110062047477],
              [-40.384150080612372, -8.472155302044255],
              [-40.384051101741896, -8.472758674390642],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0032000',
        uf: 'PE',
        nome_proje: 'PA CATALUNHA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '6825.0000',
        capacidade: 600.0,
        num_famili: 599.0,
        fase: 6.0,
        data_de_cr: '16/12/1998',
        forma_obte: 'Desapropriação',
        data_obten: '23/06/1998',
        area_calc_: 6670.4611,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.080137471535927, -8.898943629393141],
              [-40.063727040248807, -8.895421439235692],
              [-40.05679463886807, -8.907501303258277],
              [-40.056569137606679, -8.907894168835181],
              [-40.056050511157487, -8.909263771978349],
              [-40.038570818171564, -8.942673887936708],
              [-40.037327524516577, -8.946471939122457],
              [-40.034697268278975, -8.956099750307471],
              [-39.986824508748491, -9.010202703511611],
              [-39.986892459601428, -9.010228778698595],
              [-39.987063650022016, -9.010290088942241],
              [-39.9873259771582, -9.010379190469818],
              [-39.987700664546296, -9.010506155199788],
              [-39.988119146885737, -9.010647110296878],
              [-39.988556033400421, -9.010798325407332],
              [-39.989022855896692, -9.010951901329541],
              [-39.989503991734971, -9.011116742911446],
              [-39.98997219719687, -9.011276825378316],
              [-39.990417910080566, -9.011428376116077],
              [-39.990863809166399, -9.011581372784173],
              [-39.991222831165821, -9.011700779034001],
              [-39.991660982116073, -9.011847826613563],
              [-39.992166873925321, -9.012016305692661],
              [-39.992543744284241, -9.012142083539018],
              [-39.992870823704294, -9.012256781010903],
              [-39.993209999091633, -9.012370269817461],
              [-39.993574400766811, -9.012493728797864],
              [-39.993939731390775, -9.012624148619789],
              [-39.99572419356933, -9.013448188496408],
              [-39.996174005451586, -9.013666463936586],
              [-39.996661259395417, -9.013903900632576],
              [-39.997180586308922, -9.014159789470055],
              [-39.997720940197915, -9.014420051921753],
              [-39.998269127434753, -9.014683638440985],
              [-39.998830230214843, -9.014946103723464],
              [-39.999395014573672, -9.015223842080909],
              [-39.999879520598164, -9.015453051657365],
              [-40.000274783198321, -9.015648769899332],
              [-40.000678315797813, -9.015841299836978],
              [-40.001168450038342, -9.016065608568793],
              [-40.001697677049805, -9.016313685715351],
              [-40.002235683797991, -9.016578378671371],
              [-40.002783065496992, -9.016844582631787],
              [-40.003335276375033, -9.01711330477228],
              [-40.003872012457286, -9.017378269850228],
              [-40.004394531544882, -9.017633776916844],
              [-40.004906752576545, -9.017882438027716],
              [-40.005391109089054, -9.018121045505094],
              [-40.005903152936199, -9.018370971689121],
              [-40.006415280633668, -9.0186180932794],
              [-40.006920788122791, -9.018872467234623],
              [-40.007437866659785, -9.019132958476074],
              [-40.00795011888124, -9.019391472509279],
              [-40.008421745345345, -9.019630924389574],
              [-40.008905849272189, -9.019875315425594],
              [-40.009404802995135, -9.020127171304956],
              [-40.009931924861306, -9.02039956883625],
              [-40.010481710183278, -9.020675792039979],
              [-40.010968998405403, -9.02091935735343],
              [-40.011361551347512, -9.021118144677738],
              [-40.011725404785871, -9.021301274383285],
              [-40.012174240731035, -9.021522063033478],
              [-40.012637948683071, -9.021758274933266],
              [-40.013112129035306, -9.021998074816166],
              [-40.013592964283177, -9.022242648855498],
              [-40.014083276605511, -9.022492712734413],
              [-40.014564391784596, -9.02273936464702],
              [-40.015001676746593, -9.022959910027822],
              [-40.015415286968839, -9.023172471306868],
              [-40.015816781911333, -9.02337918711752],
              [-40.016204788604441, -9.02357707686253],
              [-40.01656874284182, -9.023762100128939],
              [-40.016927345765602, -9.023952744929028],
              [-40.017307808715834, -9.02415273431272],
              [-40.017685999014873, -9.024353181737245],
              [-40.018059900817804, -9.024548937859384],
              [-40.018430519872567, -9.024741808656072],
              [-40.018802330559275, -9.024937750603302],
              [-40.019169590966577, -9.025132981252147],
              [-40.019497940231155, -9.025304715300461],
              [-40.019769071694917, -9.025445497375987],
              [-40.020010593594009, -9.025570806203865],
              [-40.020189798967678, -9.025663730444931],
              [-40.02034247626932, -9.025737103351986],
              [-40.020338575166527, -9.02580566574364],
              [-40.019910327818636, -9.038089971041284],
              [-40.022066558761217, -9.039219953434984],
              [-40.022653145549214, -9.0444643133953],
              [-40.022517742120542, -9.048708187775475],
              [-40.022583470521958, -9.052845778948729],
              [-40.023283259307831, -9.052835504502424],
              [-40.024447756264678, -9.052690624970351],
              [-40.0253850557677, -9.052603081965088],
              [-40.026748029350976, -9.052316196023851],
              [-40.027701094359259, -9.05211040179474],
              [-40.028670675825765, -9.051762301326916],
              [-40.029606090610528, -9.051621485760888],
              [-40.029341300975041, -9.04927168051131],
              [-40.031206524269187, -9.03961011323932],
              [-40.034326892125755, -9.033029182171823],
              [-40.034367309659892, -9.032942158331569],
              [-40.036946784125924, -9.02752331530281],
              [-40.045668107454354, -9.009254330962992],
              [-40.060588018313986, -8.977671558609059],
              [-40.069977799304198, -8.957815584319473],
              [-40.077704468063658, -8.941487971133744],
              [-40.087513050966422, -8.920783579015934],
              [-40.087415044858986, -8.920180114853627],
              [-40.087786631760423, -8.918762075362537],
              [-40.090606507420183, -8.907923377880573],
              [-40.090547054346963, -8.907869288109522],
              [-40.090547052975708, -8.907869290547351],
              [-40.089960540502247, -8.907335682603815],
              [-40.080921768785814, -8.899111943734917],
              [-40.080137463146308, -8.898943656378073],
              [-40.080137471535927, -8.898943629393141],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0027000',
        uf: 'PE',
        nome_proje: 'PA JATOBÁ',
        municipio: 'LAGOA GRANDE',
        area_hecta: '806.0000',
        capacidade: 40.0,
        num_famili: 26.0,
        fase: 5.0,
        data_de_cr: '29/10/1997',
        forma_obte: 'Desapropriação',
        data_obten: '09/06/1997',
        area_calc_: 680.6162,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.043599112101177, -9.013720368261593],
              [-40.042808163098577, -9.015380318922423],
              [-40.042016032717676, -9.017042984988629],
              [-40.041197560544596, -9.018753565713689],
              [-40.040367515874976, -9.020491670881324],
              [-40.03953492560796, -9.022232856945404],
              [-40.038703860641363, -9.023969153936065],
              [-40.037881206971605, -9.025690775214631],
              [-40.037041909605541, -9.027448255375537],
              [-40.036176823726997, -9.02925699443464],
              [-40.035314855628251, -9.031077118495602],
              [-40.03446400268286, -9.032873786800284],
              [-40.035033834032646, -9.033244600011225],
              [-40.034578798494685, -9.033379186922422],
              [-40.033734809389038, -9.03516100328971],
              [-40.031562266704157, -9.039746667739383],
              [-40.030759292683364, -9.043835232728377],
              [-40.030559219424369, -9.044853844942498],
              [-40.029843307929241, -9.048498842049526],
              [-40.029693811741652, -9.049260289873033],
              [-40.029897816682499, -9.051030021526426],
              [-40.029930974980637, -9.051332530614218],
              [-40.030353587877208, -9.051177508935865],
              [-40.03038812396845, -9.051164841061297],
              [-40.032361947977385, -9.050440853229826],
              [-40.03236944420015, -9.05038964478528],
              [-40.032992517638945, -9.04721753070079],
              [-40.033322076470697, -9.045539810064074],
              [-40.033501157541679, -9.044627881540032],
              [-40.034677590356345, -9.038637818402547],
              [-40.034728696240194, -9.038502380570952],
              [-40.035745050147149, -9.036485481995767],
              [-40.036214803000426, -9.035546596096788],
              [-40.036381895286631, -9.035218019073731],
              [-40.037471291009055, -9.033044183984753],
              [-40.038427808679572, -9.031141038556317],
              [-40.039373164287923, -9.029250765175068],
              [-40.040294997266201, -9.027409121470759],
              [-40.041201989450464, -9.025597905144501],
              [-40.042076577147832, -9.023846558047508],
              [-40.042939262196491, -9.022134673865738],
              [-40.043795553938672, -9.020416566417982],
              [-40.044652291573051, -9.01869791382704],
              [-40.045493588808569, -9.017021990035412],
              [-40.046359158712889, -9.01531126825998],
              [-40.046886086886182, -9.01424795096859],
              [-40.047673133188475, -9.01266171081854],
              [-40.048471947702794, -9.011057620048094],
              [-40.048900221826358, -9.010203671740484],
              [-40.049923731011816, -9.008162943113591],
              [-40.05164481751995, -9.004754355872754],
              [-40.051960214720445, -9.004129894203434],
              [-40.053983050472631, -9.00007860906433],
              [-40.05601603233211, -8.996027016606442],
              [-40.056952748874345, -8.994163780119438],
              [-40.05724062660363, -8.993591124237803],
              [-40.058058220140211, -8.991964719211895],
              [-40.060077355644225, -8.987903385372766],
              [-40.062120923620604, -8.983843692654155],
              [-40.063734840220398, -8.980625385581606],
              [-40.063990409073092, -8.980115672841883],
              [-40.064160516913127, -8.979776498125034],
              [-40.066195631568895, -8.975716092264882],
              [-40.068058474175167, -8.972013039303874],
              [-40.068240877389385, -8.971650585739271],
              [-40.068642014105777, -8.970845718094143],
              [-40.070268881624585, -8.967581956854634],
              [-40.07249416379716, -8.96312215484188],
              [-40.073409897742437, -8.96128988251718],
              [-40.073659560699561, -8.960790673391479],
              [-40.074519402889315, -8.959070431226738],
              [-40.076541664286438, -8.955009756287009],
              [-40.07776999357305, -8.952537274729481],
              [-40.078177233191731, -8.951717551445492],
              [-40.078501016755197, -8.951065920445798],
              [-40.080518189832432, -8.947003347908725],
              [-40.082348655075997, -8.943359671264206],
              [-40.084417161884836, -8.939336094659987],
              [-40.086483863027567, -8.935300307365216],
              [-40.089141362348855, -8.930053289278581],
              [-40.091181569154841, -8.926009153173331],
              [-40.092841582283903, -8.922728774653871],
              [-40.087523680783058, -8.920902833035198],
              [-40.085516388204489, -8.925105118419422],
              [-40.083461163814768, -8.929443824509031],
              [-40.081230700183575, -8.934143151379931],
              [-40.079178570872841, -8.938482919653268],
              [-40.076948631471225, -8.943208637107245],
              [-40.074804226466306, -8.947748432773842],
              [-40.072558150825593, -8.952496066918002],
              [-40.072312193349212, -8.953017330770134],
              [-40.072028812467593, -8.953617834675102],
              [-40.070461840057355, -8.956937946632431],
              [-40.069212094533817, -8.959573438950908],
              [-40.068958166738241, -8.960109194324987],
              [-40.068518196996799, -8.96103698612766],
              [-40.066581171787931, -8.965141969211318],
              [-40.064650128965518, -8.96922205895274],
              [-40.062730719996459, -8.973281218328703],
              [-40.060773893424027, -8.977404869992995],
              [-40.058834437877941, -8.981478725446687],
              [-40.058815994030716, -8.981517394767355],
              [-40.05827633883375, -8.982652203153066],
              [-40.056888084323262, -8.985571314853086],
              [-40.054756416616158, -8.990078627881431],
              [-40.052831174654763, -8.994139671949634],
              [-40.052812821137877, -8.994178521703091],
              [-40.052581456215606, -8.994667630943502],
              [-40.050888649549108, -8.998245796669552],
              [-40.050053755331227, -9.000039457222043],
              [-40.04833194734357, -9.003670784814577],
              [-40.048116752912193, -9.004124575977265],
              [-40.046221038328461, -9.008187685435322],
              [-40.045502624038065, -9.009724891622856],
              [-40.04545208343648, -9.009833016750401],
              [-40.044715007253998, -9.011367652346072],
              [-40.043946270516066, -9.012966496589263],
              [-40.043599112101177, -9.013720368261593],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0071000',
        uf: 'PE',
        nome_proje: 'PA CHAPADA DO PEBA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '236.4717',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 5.0,
        data_de_cr: '05/12/2002',
        forma_obte: 'Arrecadação',
        data_obten: '30/10/2002',
        area_calc_: 238.7566,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.733068637961409, -8.670842721090981],
              [-39.733325154636553, -8.673523039988236],
              [-39.733565561227891, -8.676095758621894],
              [-39.733813258224096, -8.678628937712491],
              [-39.734057248929027, -8.681123412426473],
              [-39.734285781411558, -8.683565729021458],
              [-39.734509007923315, -8.685941668054067],
              [-39.734534362546981, -8.686172436479337],
              [-39.734929093680826, -8.690357046905365],
              [-39.73498034992619, -8.690865252376442],
              [-39.735018223437997, -8.691269380739657],
              [-39.736763020600591, -8.694954562137545],
              [-39.738205212386966, -8.698249051620168],
              [-39.738281449719537, -8.698420840717352],
              [-39.739840379450165, -8.701989868109132],
              [-39.740233655137324, -8.702890211455767],
              [-39.741671743163614, -8.70138900218017],
              [-39.741568839876685, -8.70018564531091],
              [-39.741381180024817, -8.697991713914728],
              [-39.741353916120019, -8.697809790752508],
              [-39.740788024603354, -8.693298838823553],
              [-39.740185268281962, -8.68852068898644],
              [-39.739805785382806, -8.685372926302623],
              [-39.739595119657153, -8.684043333219176],
              [-39.738977328075094, -8.679643901545099],
              [-39.738321935156648, -8.674970398762152],
              [-39.73764099611617, -8.670050386893614],
              [-39.737603541994048, -8.669767454771648],
              [-39.737045536000345, -8.66560631236548],
              [-39.736455579906213, -8.661132105725143],
              [-39.7362888644194, -8.660980297108708],
              [-39.733556337086384, -8.658446917215674],
              [-39.731920388056672, -8.658904993942505],
              [-39.732018778513172, -8.659964016524849],
              [-39.732281153512226, -8.662723648567576],
              [-39.732541161385349, -8.665478400193489],
              [-39.732572946571409, -8.665746239640704],
              [-39.733068637961409, -8.670842721090981],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0083000',
        uf: 'PE',
        nome_proje: 'PA LAGOA DAS CARAIBAS',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1177.4595',
        capacidade: 20.0,
        num_famili: 18.0,
        fase: 4.0,
        data_de_cr: '21/11/2003',
        forma_obte: 'Arrecadação',
        data_obten: '21/11/2003',
        area_calc_: 1177.7807,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.294901464023297, -8.774886960967123],
              [-40.284406297504134, -8.778333946267354],
              [-40.279086617172261, -8.781525861775599],
              [-40.275471660573963, -8.779708324940669],
              [-40.275034861368084, -8.780239997303378],
              [-40.272633177723847, -8.780566332767799],
              [-40.27202344930074, -8.78141156711942],
              [-40.27351266842625, -8.785091329223885],
              [-40.27864258573657, -8.784933710729911],
              [-40.283800378651016, -8.787439106656198],
              [-40.286946345813142, -8.794781183739971],
              [-40.283935131362981, -8.798059074203724],
              [-40.280496038676944, -8.797818122383671],
              [-40.280254161921683, -8.798726867831103],
              [-40.282929471541344, -8.802512133634815],
              [-40.284676599893764, -8.804837107866957],
              [-40.28278647714734, -8.806260873302197],
              [-40.281928190723391, -8.809508082743507],
              [-40.282264019077267, -8.811301982297939],
              [-40.283904602527976, -8.81748552923545],
              [-40.285172060582738, -8.81725819490844],
              [-40.284831276850092, -8.815220879659677],
              [-40.287480169724049, -8.814028444209381],
              [-40.288023145596277, -8.815747742653544],
              [-40.291577635018001, -8.814170560777633],
              [-40.291577636419156, -8.814170559615846],
              [-40.285194602066724, -8.812127191098977],
              [-40.28638678312165, -8.809065212726763],
              [-40.29220941555019, -8.806221299156777],
              [-40.290637538067756, -8.804192377591839],
              [-40.295161840098011, -8.799979159483376],
              [-40.297228012789738, -8.798150513908009],
              [-40.311441841195382, -8.804056419712092],
              [-40.315978306938291, -8.800069739179017],
              [-40.323376422302374, -8.807440630692332],
              [-40.325688268065754, -8.807622661957264],
              [-40.326710171368028, -8.806573519508881],
              [-40.329054546560052, -8.8052761806312],
              [-40.330582981509153, -8.804660745502277],
              [-40.332074294809679, -8.80330138843231],
              [-40.331254297113745, -8.802181526256453],
              [-40.330282075145739, -8.801124143286863],
              [-40.328237968463675, -8.798894783703753],
              [-40.328237968455561, -8.798894782785096],
              [-40.327398916833999, -8.795967124047438],
              [-40.327398916825871, -8.795967123128918],
              [-40.327398916809614, -8.795967121291868],
              [-40.326720331351638, -8.796334122049251],
              [-40.325198049777121, -8.794608579515744],
              [-40.324125230648505, -8.794983744586332],
              [-40.322384383968412, -8.792015826614845],
              [-40.320876494164295, -8.789440628705027],
              [-40.320876494156053, -8.789440627786774],
              [-40.320695566810691, -8.789690123067215],
              [-40.309042880513417, -8.792737291288832],
              [-40.309042880496392, -8.792737289452063],
              [-40.307022034698406, -8.788019627034533],
              [-40.307654262488313, -8.785316570726248],
              [-40.31216783453803, -8.783936851277142],
              [-40.31216783452961, -8.78393685035917],
              [-40.312167834521169, -8.783936849441197],
              [-40.310862956263385, -8.781415871471969],
              [-40.297483457372849, -8.776795032343601],
              [-40.294901464023297, -8.774886960967123],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0316000',
        uf: 'PE',
        nome_proje: 'PA BARRO ALTO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '101.9845',
        capacidade: 8.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '08/07/2015',
        forma_obte: 'Desapropriação',
        data_obten: '26/12/2013',
        area_calc_: 102.5262,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.958958510410334, -8.956348685742023],
              [-39.933385973571255, -8.975581422252061],
              [-39.933080236965736, -8.975811340588557],
              [-39.920603668298831, -8.985193460904407],
              [-39.921099069294222, -8.985795408064586],
              [-39.926865335651634, -8.981459395861721],
              [-39.930263613055551, -8.978903935123389],
              [-39.93375858074787, -8.976275698167976],
              [-39.934064317193261, -8.97604577930475],
              [-39.936088794993303, -8.974523325511971],
              [-39.939467136856507, -8.971982684617371],
              [-39.942845430340505, -8.9694420173183],
              [-39.946223675454007, -8.96690132362351],
              [-39.949295765059219, -8.964590827372556],
              [-39.952673917839213, -8.962050083378868],
              [-39.956052022274271, -8.959509313067441],
              [-39.959430078373202, -8.956968516483142],
              [-40.007481304183706, -8.920820140803308],
              [-40.007001110162335, -8.920206848044124],
              [-39.958958510410334, -8.956348685742023],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0312000',
        uf: 'PE',
        nome_proje: 'PA PITOMBEIRA',
        municipio: 'OURICURI',
        area_hecta: '347.8439',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2009',
        area_calc_: 347.899,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.456775142910907, -7.988977914215956],
              [-40.454604932712769, -7.989253184423013],
              [-40.450071928808178, -7.989819279205294],
              [-40.450944522671001, -7.99597374112527],
              [-40.45216367817816, -7.995792824980911],
              [-40.457662920751517, -7.995015965195067],
              [-40.475369189847363, -7.992597101293171],
              [-40.474323118055153, -7.987812563161146],
              [-40.473710131055157, -7.985156040182376],
              [-40.472204640725842, -7.978432829408373],
              [-40.471983041658504, -7.977446424520751],
              [-40.470912449500105, -7.972242537379353],
              [-40.470663867628581, -7.971107229252676],
              [-40.464367471068492, -7.972267556586794],
              [-40.462051752761894, -7.972681320073763],
              [-40.462586287232405, -7.975105612258877],
              [-40.464654727249936, -7.984599048076214],
              [-40.465359410510032, -7.987977260168382],
              [-40.456775142910907, -7.988977914215956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0082000',
        uf: 'PE',
        nome_proje: 'PA MADRE PAULINA',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1628.9309',
        capacidade: 62.0,
        num_famili: 60.0,
        fase: 4.0,
        data_de_cr: '02/10/2003',
        forma_obte: 'Desapropriação',
        data_obten: '06/11/2002',
        area_calc_: 1684.8567,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.122150093093197, -8.957445018770271],
              [-40.122154740880681, -8.957434682965046],
              [-40.120121894597226, -8.958015736369704],
              [-40.11756847063269, -8.957213430600291],
              [-40.116580701628024, -8.956671902924638],
              [-40.114705870474474, -8.960190324005023],
              [-40.113137390274119, -8.963126771766504],
              [-40.11103004898883, -8.967105110530717],
              [-40.108896571562674, -8.971116890901417],
              [-40.10677914643292, -8.975097234524151],
              [-40.104632735959306, -8.979131737632279],
              [-40.102371061784297, -8.983385702078689],
              [-40.100109647005517, -8.987651232852196],
              [-40.098005915309258, -8.991615678160606],
              [-40.096101854000345, -8.995202858591758],
              [-40.095873574484905, -8.995639074524291],
              [-40.09375102914715, -8.999637021727404],
              [-40.091935628629273, -9.003057705028105],
              [-40.089649591585044, -9.007377620467976],
              [-40.087002752309068, -9.012375684675584],
              [-40.084962913009534, -9.016225403025279],
              [-40.082963683203005, -9.020009247851522],
              [-40.080258695903723, -9.025119412193826],
              [-40.078161924629157, -9.029080238794258],
              [-40.076293187544763, -9.032613713026509],
              [-40.07419133926205, -9.036576620165123],
              [-40.072257496500413, -9.040237424782957],
              [-40.072053082288349, -9.040639829059611],
              [-40.070834913671661, -9.043039216573522],
              [-40.068798167241034, -9.047057532151955],
              [-40.067470774006921, -9.049668941785086],
              [-40.06730550834839, -9.049991556242739],
              [-40.066834108343961, -9.050934096647334],
              [-40.066730737985388, -9.051143757003512],
              [-40.068892406935795, -9.052230555903447],
              [-40.067793501030586, -9.056894378255782],
              [-40.068191662126743, -9.057275580029573],
              [-40.069281768835509, -9.058317565759292],
              [-40.069581209340825, -9.058633759269181],
              [-40.070189051539387, -9.059280860209428],
              [-40.070851172408716, -9.059884120558426],
              [-40.071448346328673, -9.060613908436046],
              [-40.071448529627972, -9.060614360078601],
              [-40.071775206000368, -9.061124456429503],
              [-40.071774853874736, -9.061128436636881],
              [-40.072118648794252, -9.061673661957322],
              [-40.072280167384918, -9.061897740831716],
              [-40.075759436836819, -9.056752589373048],
              [-40.078010236896027, -9.053413414854207],
              [-40.078213588325362, -9.053112841159201],
              [-40.080461991279968, -9.049716507811064],
              [-40.08204210742651, -9.047347768340734],
              [-40.084267470062962, -9.048555469596094],
              [-40.085043219469576, -9.047063338983213],
              [-40.08509278926185, -9.046968054077757],
              [-40.085782421955116, -9.045642397894488],
              [-40.086470598215861, -9.04431801131215],
              [-40.087158220761594, -9.042992540338499],
              [-40.087848478046745, -9.041667422486432],
              [-40.088537001381937, -9.040342037661924],
              [-40.089229885217506, -9.039016096471183],
              [-40.089913036498956, -9.03769226338909],
              [-40.089965417867013, -9.037594889705305],
              [-40.090382396032169, -9.036792483453233],
              [-40.091070353844231, -9.035466192991104],
              [-40.091759402802893, -9.034141255011686],
              [-40.092451175226699, -9.03281594638038],
              [-40.093130388637157, -9.031490583895405],
              [-40.093829067041135, -9.030165705262062],
              [-40.094509753850048, -9.02884928990451],
              [-40.096442113699709, -9.025123289538897],
              [-40.098536055746067, -9.02108488878628],
              [-40.100627547000229, -9.017062314157032],
              [-40.10273352196733, -9.01300025902856],
              [-40.104775569862312, -9.00907955761244],
              [-40.106893981693588, -9.00500705044173],
              [-40.108987568543327, -9.000981365436511],
              [-40.111062682213635, -8.996996876752817],
              [-40.112080176485271, -8.995052711230748],
              [-40.113127001226658, -8.993035745718911],
              [-40.115226144088489, -8.989023858757744],
              [-40.117347099518106, -8.984960531421946],
              [-40.119426159349096, -8.980952850682407],
              [-40.121493955742594, -8.976963736168015],
              [-40.117353908442276, -8.976639054189427],
              [-40.113277667247395, -8.976328330852292],
              [-40.115211552781112, -8.972255635060721],
              [-40.117164112801696, -8.968145436892206],
              [-40.119110372560478, -8.964069888394631],
              [-40.119838888809319, -8.962513076851605],
              [-40.119840748400115, -8.962498360608553],
              [-40.122148134638913, -8.957445555179932],
              [-40.122150093093197, -8.957445018770271],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0072000',
        uf: 'PE',
        nome_proje: 'PA SANTA TEREZA',
        municipio: 'DORMENTES',
        area_hecta: '2280.8100',
        capacidade: 40.0,
        num_famili: 40.0,
        fase: 4.0,
        data_de_cr: '05/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '09/10/2001',
        area_calc_: 2233.139,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.614058898376548, -8.573555506960863],
              [-40.613925373981452, -8.574225978024991],
              [-40.612013676569582, -8.576334305916388],
              [-40.611780048523173, -8.576726795427208],
              [-40.610587917983779, -8.579206357528115],
              [-40.609839511959251, -8.579715103880023],
              [-40.609074991536545, -8.579806090275701],
              [-40.606112370757359, -8.58012450476741],
              [-40.60506815120786, -8.580434738925664],
              [-40.603169886041393, -8.580913988229245],
              [-40.602608972111, -8.581055655171422],
              [-40.602170385863047, -8.581166341976015],
              [-40.602107392809089, -8.581157290445718],
              [-40.601715981068331, -8.581100957259261],
              [-40.601666895297406, -8.581093837126133],
              [-40.600467892049998, -8.580910832251215],
              [-40.595929635218212, -8.580272014646935],
              [-40.593130088944676, -8.579903010386124],
              [-40.58953121754648, -8.57938428873798],
              [-40.586508985930948, -8.578898605579088],
              [-40.583184383742299, -8.578380411472475],
              [-40.582160459126044, -8.578222759506422],
              [-40.58011484258671, -8.578645085830555],
              [-40.57700583839015, -8.579343372849802],
              [-40.573758334716047, -8.58007710410609],
              [-40.570298524698799, -8.580814929648126],
              [-40.568455316938149, -8.581181127658466],
              [-40.566455904953393, -8.578879183046304],
              [-40.565020091156292, -8.577258429541024],
              [-40.564659131941092, -8.576851010191852],
              [-40.564385970964146, -8.576542516583491],
              [-40.563981136790318, -8.574958355930361],
              [-40.562824565415362, -8.574750822533915],
              [-40.559526699424474, -8.574788303198895],
              [-40.555366511179031, -8.574754272227922],
              [-40.550848722103062, -8.574713314370955],
              [-40.550318347154068, -8.576836370423685],
              [-40.549356774499607, -8.581011465237733],
              [-40.550061746191595, -8.585512614653567],
              [-40.550794434143839, -8.590009216730756],
              [-40.551576794377219, -8.594471434081859],
              [-40.552415541799562, -8.598896978546517],
              [-40.552415632648675, -8.598896978180109],
              [-40.55301321174818, -8.598843479813086],
              [-40.55426167815309, -8.598731563249506],
              [-40.558775668787987, -8.598330830711168],
              [-40.562961716868877, -8.597963116039601],
              [-40.56609087942666, -8.597680313037996],
              [-40.571332974593673, -8.597212276558276],
              [-40.574627594871878, -8.596918513473893],
              [-40.575602494945905, -8.596829891882035],
              [-40.578045443107399, -8.596614090978438],
              [-40.581499702271991, -8.596307590699059],
              [-40.584944763825362, -8.595998476049973],
              [-40.58838587529624, -8.595703362884484],
              [-40.590661828660764, -8.59477168755094],
              [-40.590779004735225, -8.59472382365129],
              [-40.591103445571406, -8.594663891451747],
              [-40.591696408056826, -8.594554474814792],
              [-40.591934791025786, -8.594510449794086],
              [-40.594372103189187, -8.594151800283289],
              [-40.594453099039391, -8.594142151771823],
              [-40.594537182517676, -8.594132219202582],
              [-40.600347045637974, -8.59336885432473],
              [-40.600797242478627, -8.594354814053895],
              [-40.601093272205809, -8.594972503272476],
              [-40.604635725927679, -8.596319742185829],
              [-40.607090006408178, -8.597296701123264],
              [-40.60727937694999, -8.597371681719022],
              [-40.610022294478341, -8.598435120544471],
              [-40.612852226193404, -8.59953424947404],
              [-40.615989340260292, -8.600635952886574],
              [-40.619504189131398, -8.601774013594541],
              [-40.619505099136489, -8.6017743714385],
              [-40.620369176971273, -8.602163330646393],
              [-40.621397785516301, -8.605328703232416],
              [-40.622402096068903, -8.608735317556601],
              [-40.623371198407533, -8.612018472442216],
              [-40.623657943013939, -8.612981661876473],
              [-40.624273119230573, -8.61496735812084],
              [-40.624875418064654, -8.616935384540925],
              [-40.626519838291536, -8.616404543357424],
              [-40.626783419744243, -8.616324401955563],
              [-40.630333584197437, -8.615190702520248],
              [-40.631983467427609, -8.616001798958763],
              [-40.636171387999092, -8.61808203792137],
              [-40.639775176613782, -8.61986878091828],
              [-40.641140817436018, -8.619037165027223],
              [-40.641086857859726, -8.616511132603835],
              [-40.641018907115274, -8.613120135728918],
              [-40.64095560562253, -8.608423942001815],
              [-40.640949169904978, -8.606993293689527],
              [-40.643511782193862, -8.60559209350469],
              [-40.644299023989127, -8.605180585103971],
              [-40.641633515259912, -8.602328719999404],
              [-40.640005927516043, -8.60060633435641],
              [-40.639902900738882, -8.600499357310191],
              [-40.637985641270724, -8.598465168129822],
              [-40.636090545678577, -8.596446694375468],
              [-40.633913477215529, -8.594150826409324],
              [-40.632616466395667, -8.592770400969744],
              [-40.631310646660602, -8.591388469546921],
              [-40.628731245537075, -8.588657725570503],
              [-40.626427389113012, -8.58622001563957],
              [-40.624269337801792, -8.583946323861747],
              [-40.622257025604377, -8.581843797062321],
              [-40.6203146661733, -8.579801090005724],
              [-40.618522684427084, -8.577911359609599],
              [-40.617977381414164, -8.577338132183339],
              [-40.617590669820054, -8.576931603807518],
              [-40.617158999491423, -8.57650537118594],
              [-40.617143217463081, -8.576489704475708],
              [-40.614115994938651, -8.573609881180555],
              [-40.614058898376548, -8.573555506960863],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0081000',
        uf: 'PE',
        nome_proje: 'PA DEZINHO ÂNGELO',
        municipio: 'PARNAMIRIM',
        area_hecta: '1576.2160',
        capacidade: 37.0,
        num_famili: 36.0,
        fase: 3.0,
        data_de_cr: '14/08/2003',
        forma_obte: 'Desapropriação',
        data_obten: '14/08/2003',
        area_calc_: 2209.8594,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.723041897562474, -8.249603690711668],
              [-39.718606207919592, -8.248735045936076],
              [-39.713813956819095, -8.247799782503384],
              [-39.708887934109342, -8.246836386106091],
              [-39.704446195076414, -8.245959087916141],
              [-39.70000141248287, -8.245097572629271],
              [-39.69502309806559, -8.244106685623454],
              [-39.690045179027464, -8.243122879500316],
              [-39.685909318040508, -8.242309784895175],
              [-39.681337804818291, -8.241420143129462],
              [-39.676861203735179, -8.24054050821945],
              [-39.672397164546858, -8.239670659626745],
              [-39.667800258264265, -8.238769233318331],
              [-39.663348594747561, -8.237896908605089],
              [-39.664974420268472, -8.24116051151459],
              [-39.666579773731115, -8.244435535822641],
              [-39.667923285630124, -8.247213428131406],
              [-39.67268502173301, -8.249152106495753],
              [-39.67697098159104, -8.250954227266483],
              [-39.680458060086536, -8.252434845251436],
              [-39.684985696922467, -8.253237731020363],
              [-39.689535433728423, -8.25405346021193],
              [-39.694104790458056, -8.254865393807043],
              [-39.698294143394207, -8.255604763702724],
              [-39.701888361105127, -8.256202133210488],
              [-39.706348193352042, -8.256978659213146],
              [-39.710811635475622, -8.257786151343378],
              [-39.716321484014443, -8.258774788208127],
              [-39.720476448445993, -8.259541928949631],
              [-39.7205455662207, -8.259553834129377],
              [-39.720713410879824, -8.259582837128526],
              [-39.72491416467151, -8.260308241940606],
              [-39.725022519217788, -8.2603269493037],
              [-39.730533084814724, -8.26133021658941],
              [-39.730640355375328, -8.261351728239807],
              [-39.730955980468295, -8.261409490736394],
              [-39.731953930223362, -8.261706415639559],
              [-39.733578382352945, -8.262250559253124],
              [-39.733586673156793, -8.262253346265718],
              [-39.733588921276194, -8.262254067392862],
              [-39.733912992187314, -8.262318955403655],
              [-39.73412536570158, -8.262361078215203],
              [-39.734611761828276, -8.262457597792322],
              [-39.73831582616203, -8.263062809809233],
              [-39.743379697567264, -8.263888476416524],
              [-39.743493322626875, -8.263908706430893],
              [-39.745168747947105, -8.264218899667929],
              [-39.747057486385707, -8.264544244392358],
              [-39.747232983716906, -8.264585251118207],
              [-39.749671445846353, -8.26502514778762],
              [-39.75413954736841, -8.265774879326328],
              [-39.758619241866178, -8.26655031486891],
              [-39.763099437355024, -8.267296121536134],
              [-39.767504440437989, -8.268029267765989],
              [-39.770638584652673, -8.26854095895726],
              [-39.772089442276915, -8.268775673995128],
              [-39.776530723132907, -8.269568071195964],
              [-39.779353225629492, -8.270104988885178],
              [-39.779351976484563, -8.270106988481617],
              [-39.780911263072824, -8.270401237018202],
              [-39.78536445082274, -8.27127789609078],
              [-39.786760896310241, -8.271539163846654],
              [-39.788854656366119, -8.266951212623265],
              [-39.790854981227483, -8.263020712670833],
              [-39.791809597801674, -8.261056409366176],
              [-39.79089692044176, -8.258821021042923],
              [-39.788702342729813, -8.254946370999216],
              [-39.786935201856004, -8.250902091894654],
              [-39.786527317732101, -8.248952954918677],
              [-39.787371234861205, -8.24656622230054],
              [-39.787768304581078, -8.245529105681509],
              [-39.783516900838301, -8.245772324425468],
              [-39.778903550235043, -8.246041798185811],
              [-39.774341138291867, -8.246319080381582],
              [-39.769748960655534, -8.246562541211716],
              [-39.76524591662259, -8.246845666976249],
              [-39.760957240492829, -8.247090076813395],
              [-39.756520846128808, -8.247340508600089],
              [-39.755545663593715, -8.247401952147099],
              [-39.75536392289964, -8.24741622345462],
              [-39.753821573098413, -8.247487233015452],
              [-39.753656260767897, -8.24749884969906],
              [-39.752031476987234, -8.24757380615929],
              [-39.747537265570493, -8.247844508139014],
              [-39.742930248135849, -8.248099179218521],
              [-39.738278192958752, -8.248357317801046],
              [-39.737490446048675, -8.248394946738383],
              [-39.737363193799062, -8.248416797782232],
              [-39.73638141219633, -8.248695281551031],
              [-39.730924278284746, -8.250242969909921],
              [-39.730194037673058, -8.250400865905753],
              [-39.727504130878813, -8.250481281310238],
              [-39.727399225203094, -8.250460397386179],
              [-39.723041897562474, -8.249603690711668],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0177000',
        uf: 'PE',
        nome_proje: 'PA ALIANÇA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '202.9109',
        capacidade: 4.0,
        num_famili: 3.0,
        fase: 3.0,
        data_de_cr: '04/07/2006',
        forma_obte: 'Confisco',
        data_obten: '04/09/2006',
        area_calc_: 205.2354,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.132182020298032, -8.510682393316928],
              [-39.124117098990062, -8.53195036210858],
              [-39.125216222081661, -8.531952360716385],
              [-39.128519807204306, -8.537232304387853],
              [-39.13753813184988, -8.514260408073909],
              [-39.13933880319513, -8.508622895393493],
              [-39.13840232149041, -8.50818109367815],
              [-39.137868764924079, -8.508122126633779],
              [-39.137335145294145, -8.507885689371431],
              [-39.136860830555669, -8.507826700017443],
              [-39.136439955957719, -8.507806767200821],
              [-39.13613620812221, -8.50780687360886],
              [-39.135865858332004, -8.507954859381586],
              [-39.13558438169261, -8.507984535835028],
              [-39.135480724981498, -8.508028894100109],
              [-39.134857095879035, -8.508403768657061],
              [-39.132182020298032, -8.510682393316928],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0066000',
        uf: 'PE',
        nome_proje: 'PA RIACHO FUNDO',
        municipio: 'LAGOA GRANDE',
        area_hecta: '467.3222',
        capacidade: 35.0,
        num_famili: 34.0,
        fase: 4.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Desapropriação',
        data_obten: '31/08/2001',
        area_calc_: 469.5805,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.112535456556415, -9.021698095974239],
              [-40.112036815472671, -9.022672082220124],
              [-40.101343382166228, -9.043917683462643],
              [-40.101170198061816, -9.043829020674471],
              [-40.100916043117749, -9.044327914250928],
              [-40.100353915296438, -9.045439601968171],
              [-40.099845293549947, -9.046436456920548],
              [-40.099359146375662, -9.047405299581461],
              [-40.098879009065811, -9.048346721991271],
              [-40.098406641299292, -9.049270938053011],
              [-40.097931895050387, -9.050221567924252],
              [-40.097449108921793, -9.051251804424467],
              [-40.096950711757955, -9.052295109959358],
              [-40.0964424995963, -9.053372809830762],
              [-40.095916092172764, -9.054481582994953],
              [-40.095388424577294, -9.055595785590233],
              [-40.094851267035523, -9.056731720556124],
              [-40.094307632487222, -9.057862881350207],
              [-40.093747720158909, -9.05902764175972],
              [-40.093571815550902, -9.059405191696106],
              [-40.091824263388808, -9.062684616721855],
              [-40.089449183380871, -9.067141022234626],
              [-40.088981546324717, -9.068019456468903],
              [-40.088897590887406, -9.068177335188878],
              [-40.088768401493539, -9.068420538629811],
              [-40.088397025939052, -9.069119533447044],
              [-40.088184784553967, -9.069518894432914],
              [-40.085904889818025, -9.073917485293707],
              [-40.086203104007133, -9.073937851422702],
              [-40.086463117175136, -9.073962219588219],
              [-40.086698091726717, -9.074041013652534],
              [-40.087065518036063, -9.074173493930939],
              [-40.087295091312939, -9.074271385497681],
              [-40.087618497613896, -9.074409060507941],
              [-40.087748406037718, -9.074464110711729],
              [-40.0882507262681, -9.074765935205951],
              [-40.088462395442157, -9.074900324691257],
              [-40.089772050974695, -9.075635910484802],
              [-40.091620634098469, -9.072527651610082],
              [-40.091867243530821, -9.072121403630717],
              [-40.092558181205462, -9.070983021142323],
              [-40.092699409915028, -9.070750271080215],
              [-40.0927932004642, -9.070595798770054],
              [-40.094710314134403, -9.067450517514187],
              [-40.096854490822814, -9.063947511100984],
              [-40.098482816859715, -9.06129740051961],
              [-40.098686134643451, -9.060960643095298],
              [-40.099400869397364, -9.059792517821673],
              [-40.100081150694713, -9.058664829173425],
              [-40.10075312765435, -9.057560044710634],
              [-40.101417964451443, -9.056472011378213],
              [-40.10204021846787, -9.055443340305571],
              [-40.102678415034909, -9.054392916137694],
              [-40.103307017226463, -9.053359793207045],
              [-40.103922200596138, -9.052342807417157],
              [-40.104532238669023, -9.0513401251734],
              [-40.105130514857329, -9.050359815155392],
              [-40.105705588204586, -9.049409056281423],
              [-40.106277851740018, -9.048462646166332],
              [-40.107309086344799, -9.046771926627267],
              [-40.108021096992417, -9.045592855763788],
              [-40.108579432935535, -9.044677142780129],
              [-40.109112312057405, -9.043797318432752],
              [-40.109640691559839, -9.042934328066037],
              [-40.110170593372118, -9.042064188235042],
              [-40.112388268988724, -9.038413037830887],
              [-40.114511967345081, -9.034952348173528],
              [-40.116879788258998, -9.031104842209578],
              [-40.119537665610679, -9.026784644802667],
              [-40.122219019779635, -9.022485795966862],
              [-40.117330226841553, -9.022102508789763],
              [-40.113563475334956, -9.021778325084579],
              [-40.112535456556415, -9.021698095974239],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0295000',
        uf: 'PE',
        nome_proje: 'PA DEUSDETE RAMOS',
        municipio: 'LAGOA GRANDE',
        area_hecta: '381.3345',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 3.0,
        data_de_cr: '07/12/2011',
        forma_obte: 'Desapropriação',
        data_obten: '09/11/2009',
        area_calc_: 381.4428,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.321059546116508, -8.765010760938864],
              [-40.327220534037558, -8.773726207876518],
              [-40.327247589142722, -8.77376447925851],
              [-40.329303946039687, -8.776673310627533],
              [-40.332925583160552, -8.773171104675967],
              [-40.341216352709452, -8.765152034356365],
              [-40.338338349491899, -8.762329404449451],
              [-40.340274452103799, -8.759124992134259],
              [-40.341052431627077, -8.757837354969977],
              [-40.336302265579306, -8.753460457052235],
              [-40.33375527892337, -8.754507005738612],
              [-40.3334150317094, -8.754646810578913],
              [-40.333301615909441, -8.754693412132543],
              [-40.332961368305448, -8.754833216614038],
              [-40.327992998707202, -8.756874627775399],
              [-40.323684164008803, -8.754992064125046],
              [-40.323393387740261, -8.754865019100752],
              [-40.323296462346178, -8.754822670705993],
              [-40.323005686304711, -8.754695625334389],
              [-40.322915069342329, -8.754656033064522],
              [-40.321502681018622, -8.756111465497613],
              [-40.320909611242442, -8.756722605258513],
              [-40.317715406465723, -8.760279838628868],
              [-40.319068380916327, -8.762193899008338],
              [-40.319227799791129, -8.762419427938342],
              [-40.319280939451993, -8.76249460422652],
              [-40.319440358597163, -8.762720133024342],
              [-40.320689848426063, -8.764487761484611],
              [-40.320848290163873, -8.764711904176467],
              [-40.320901104114867, -8.764786618382665],
              [-40.321059546116508, -8.765010760938864],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0282000',
        uf: 'PE',
        nome_proje: 'PA UMBURANA BRAVA',
        municipio: 'DORMENTES',
        area_hecta: '99.9575',
        capacidade: 3.0,
        num_famili: 3.0,
        fase: 3.0,
        data_de_cr: '29/04/2009',
        forma_obte: 'Desapropriação',
        data_obten: '29/04/2009',
        area_calc_: 99.9689,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.533086053327921, -8.468310100228576],
              [-40.531221677202446, -8.467985469620702],
              [-40.527576671391444, -8.473069745393376],
              [-40.526199285332737, -8.475009095549835],
              [-40.525999458952548, -8.475258087460716],
              [-40.522597572749042, -8.479771270796816],
              [-40.518540052778867, -8.484773828768176],
              [-40.521021390193795, -8.487612484130702],
              [-40.521908718960354, -8.488640837831467],
              [-40.530127751147546, -8.474536541310068],
              [-40.535082540126531, -8.468694695000126],
              [-40.533086053327921, -8.468310100228576],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0080000',
        uf: 'PE',
        nome_proje: 'PA SENHORA SANTANA',
        municipio: 'PARNAMIRIM',
        area_hecta: '984.0280',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 4.0,
        data_de_cr: '25/08/2003',
        forma_obte: 'Desapropriação',
        data_obten: '21/11/2002',
        area_calc_: 906.0062,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.680458060086536, -8.252434845251436],
              [-39.680233171017093, -8.253927810398334],
              [-39.684767519135981, -8.254716394988087],
              [-39.689235879699815, -8.255509200948543],
              [-39.693712710275584, -8.256308905439699],
              [-39.698048558177355, -8.257062675517703],
              [-39.702405745248079, -8.257707641103877],
              [-39.706875876158129, -8.258393874411176],
              [-39.709867714735829, -8.258649162586632],
              [-39.711935443182909, -8.259571769670318],
              [-39.713004274397555, -8.259455089152898],
              [-39.715271856797003, -8.263352335746795],
              [-39.716759963052134, -8.265881490938952],
              [-39.719549288879179, -8.266888595286616],
              [-39.719660486423436, -8.266921951401329],
              [-39.72064577968321, -8.26739484774737],
              [-39.720961127280425, -8.268147347939555],
              [-39.721691304466383, -8.270031143887287],
              [-39.722300413825664, -8.270696101499443],
              [-39.722630761985833, -8.271056662521865],
              [-39.72423596554205, -8.273126639121031],
              [-39.728768092537841, -8.273845058397683],
              [-39.733210455330322, -8.27447856885075],
              [-39.737706472106147, -8.27515082204582],
              [-39.742666517777415, -8.275887916407157],
              [-39.742846626495897, -8.275915982098377],
              [-39.747243797813539, -8.276594730606547],
              [-39.75169995174727, -8.277343776750429],
              [-39.753495023762774, -8.277607214412898],
              [-39.756156358992847, -8.278076219382802],
              [-39.760611126394437, -8.278799478789889],
              [-39.76368049476617, -8.279297231104062],
              [-39.767209782379666, -8.279940961635834],
              [-39.771193895347025, -8.280583420142309],
              [-39.770832055535905, -8.279136065976665],
              [-39.771783459549006, -8.278297325096311],
              [-39.772985844089348, -8.276860605653837],
              [-39.77525639860135, -8.275014983248415],
              [-39.776115398755074, -8.272994726911513],
              [-39.778314761869225, -8.271766526655286],
              [-39.779323356267078, -8.270152802922057],
              [-39.779351976484563, -8.270106988481617],
              [-39.776530735876712, -8.269574583333149],
              [-39.772063850457968, -8.268782416462724],
              [-39.770638584652673, -8.26854095895726],
              [-39.767504440437989, -8.268029267765989],
              [-39.763099437355024, -8.267296121536134],
              [-39.758619241866178, -8.26655031486891],
              [-39.75413954736841, -8.265774879326328],
              [-39.749671445846353, -8.26502514778762],
              [-39.747232983716906, -8.264585251118207],
              [-39.747053314664861, -8.264546875153069],
              [-39.745168747947105, -8.264218899667929],
              [-39.743493322626875, -8.263908706430893],
              [-39.73831582616203, -8.263062809809233],
              [-39.73461040171884, -8.262458595212014],
              [-39.733912992187314, -8.262318955403655],
              [-39.733593100593716, -8.262255506894153],
              [-39.733586673156793, -8.262253346265718],
              [-39.730955980468295, -8.261409490736394],
              [-39.730640355375328, -8.261351728239807],
              [-39.730533084814724, -8.26133021658941],
              [-39.725022519217788, -8.2603269493037],
              [-39.72491416467151, -8.260308241940606],
              [-39.720476448445993, -8.259541928949631],
              [-39.716321484014443, -8.258774788208127],
              [-39.710811635475622, -8.257786151343378],
              [-39.706348193352042, -8.256978659213146],
              [-39.701888361105127, -8.256202133210488],
              [-39.698294143394207, -8.255604763702724],
              [-39.694104790458056, -8.254865393807043],
              [-39.689535433728423, -8.25405346021193],
              [-39.684989278616918, -8.253261331967328],
              [-39.680458060086536, -8.252434845251436],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0036000',
        uf: 'PE',
        nome_proje: 'PA AQUARIUS',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '2667.0600',
        capacidade: 150.0,
        num_famili: 148.0,
        fase: 5.0,
        data_de_cr: '27/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/1999',
        area_calc_: 2807.2017,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.991235492546835, -8.857758668747243],
              [-39.985414689475618, -8.861021172661333],
              [-39.98088579638965, -8.863573697626164],
              [-39.977759363369081, -8.865316241528037],
              [-39.971602192405207, -8.868745119302526],
              [-39.963941559428775, -8.8730189688123],
              [-39.956840015219747, -8.876980314074792],
              [-39.950850948340737, -8.8803062597931],
              [-39.945355593683644, -8.883357468836595],
              [-39.942280364456018, -8.8850632618297],
              [-39.942153590504226, -8.885133220950323],
              [-39.941358536297173, -8.885575309986573],
              [-39.940564561194321, -8.886013415422864],
              [-39.939684596066314, -8.886503648676319],
              [-39.938891074872537, -8.886943106389674],
              [-39.93809719664555, -8.887386000220406],
              [-39.937304309610028, -8.887826086404703],
              [-39.936510418605735, -8.888265540523236],
              [-39.935715897263535, -8.888708250562184],
              [-39.934919633861639, -8.889146170123912],
              [-39.93412847213984, -8.889588416515149],
              [-39.933334663093831, -8.890027502624935],
              [-39.932539399491176, -8.890467585705863],
              [-39.931746232148768, -8.890910284799762],
              [-39.930953511550278, -8.89135062982119],
              [-39.930820368927755, -8.891421324927897],
              [-39.93002918856876, -8.891860307739819],
              [-39.929232561552205, -8.892304005530095],
              [-39.928443370826891, -8.892740176719355],
              [-39.927650460187728, -8.893180606372988],
              [-39.926845370376263, -8.893624682427555],
              [-39.926055099826954, -8.894067815692438],
              [-39.925255815326217, -8.894507533104836],
              [-39.924471086869666, -8.894950106961742],
              [-39.923664071592192, -8.89539119710642],
              [-39.922892574399093, -8.895816822812092],
              [-39.922080407553644, -8.896272393129996],
              [-39.921283300528607, -8.896714087215221],
              [-39.92049291562104, -8.897153502148022],
              [-39.919711702699082, -8.897588370903465],
              [-39.918897677522118, -8.898031458797956],
              [-39.918776452032212, -8.898105557387868],
              [-39.917986977421812, -8.898547859414869],
              [-39.917204185143163, -8.898971874476841],
              [-39.91639437202484, -8.899429055835714],
              [-39.915594518796176, -8.89986938916509],
              [-39.914802039670313, -8.900313591788613],
              [-39.914008177290256, -8.900750922803523],
              [-39.913212504782592, -8.901192597895324],
              [-39.91242146214347, -8.901631727776286],
              [-39.911639033954891, -8.902061429217646],
              [-39.910845910598162, -8.90250662072453],
              [-39.910052317961906, -8.902946747180833],
              [-39.907956524854626, -8.904108512670895],
              [-39.905902324704407, -8.905251445074498],
              [-39.905081443668095, -8.904955168109526],
              [-39.902638889233145, -8.903947980040041],
              [-39.902303145969142, -8.903811414191052],
              [-39.893696997353743, -8.90031817377278],
              [-39.893569821651191, -8.900266565714997],
              [-39.890636860629122, -8.905539263420184],
              [-39.887331475471584, -8.909716775023441],
              [-39.885441920769658, -8.912993173960032],
              [-39.883911124853519, -8.916834875943019],
              [-39.883290891702963, -8.921014922036022],
              [-39.883424834572395, -8.921041374221586],
              [-39.897756808368861, -8.923872191426742],
              [-39.898231720089257, -8.923783858132493],
              [-39.902674879784676, -8.921389517137406],
              [-39.908128176532749, -8.918452187152225],
              [-39.912152958622698, -8.916290837257476],
              [-39.916890485718255, -8.913742414347961],
              [-39.917693842112392, -8.913310297180901],
              [-39.918490020927784, -8.912881904244509],
              [-39.919287383919503, -8.912455044337966],
              [-39.920084643128128, -8.912023842006157],
              [-39.920887169596355, -8.91159036408518],
              [-39.921685072991721, -8.911163769541899],
              [-39.924082739333976, -8.909870581669155],
              [-39.924881983522972, -8.909437827685309],
              [-39.925681769562537, -8.909004347289207],
              [-39.92580793018351, -8.908939097151027],
              [-39.926605256314147, -8.908504807131616],
              [-39.927403766380827, -8.908071959674944],
              [-39.92820365756441, -8.907646613783953],
              [-39.929002716617092, -8.907216655968684],
              [-39.929798764024206, -8.906783357847397],
              [-39.930601277294173, -8.906354292724091],
              [-39.93139567774076, -8.905918644211592],
              [-39.932195374587408, -8.90549229626067],
              [-39.93299286843672, -8.905058717070471],
              [-39.933790193535764, -8.904631105826953],
              [-39.934589139192603, -8.904197791249485],
              [-39.935383998384289, -8.903767650810659],
              [-39.936186855016949, -8.903336041695503],
              [-39.936986348845679, -8.902906067414369],
              [-39.937779018259647, -8.902475475703461],
              [-39.937910071578749, -8.902404873585716],
              [-39.938709555795874, -8.901972815889712],
              [-39.939506223827991, -8.901542753443886],
              [-39.93988494314128, -8.901339483152791],
              [-39.940303243947966, -8.901108890098843],
              [-39.941109721582173, -8.900675543964557],
              [-39.941339127146762, -8.900550609025196],
              [-39.941906290136103, -8.90024448233034],
              [-39.94270602976038, -8.899809522222393],
              [-39.943503500508051, -8.899377189086053],
              [-39.944301433015177, -8.898948380410003],
              [-39.945100627223354, -8.898515858962528],
              [-39.945900004796329, -8.898084692114004],
              [-39.946311583214488, -8.897860079133356],
              [-39.946701909100454, -8.897646643873239],
              [-39.947498108507013, -8.897218918750452],
              [-39.948296473889101, -8.89678548887699],
              [-39.949094118813157, -8.896355586464171],
              [-39.94922307814722, -8.896285890515527],
              [-39.955424976778403, -8.892939865018414],
              [-39.962267005516829, -8.889255479454159],
              [-39.967402722734974, -8.886490116114908],
              [-39.977000465783867, -8.88125118408292],
              [-39.982287842541503, -8.878360615716595],
              [-39.987425941081703, -8.875552041967584],
              [-39.992260105691145, -8.872934134627856],
              [-39.994817943388711, -8.871546148705791],
              [-39.999420791145923, -8.869078451195607],
              [-40.005337957370841, -8.865916852584693],
              [-40.006227183549903, -8.865743711777235],
              [-40.010888864455268, -8.863733348389218],
              [-40.014329356540465, -8.862254044884155],
              [-40.018188696559214, -8.860592515120112],
              [-40.022532096943756, -8.85872559779671],
              [-40.025156796140521, -8.857594087617008],
              [-40.025984837707895, -8.857240827556787],
              [-40.030296031537624, -8.855366319946413],
              [-40.03382788401845, -8.853842016157749],
              [-40.037286597882108, -8.852350532870167],
              [-40.039911286202198, -8.851218295813654],
              [-40.043036342097402, -8.849861086700079],
              [-40.045819280540798, -8.848658191668466],
              [-40.048852560600963, -8.847339630883454],
              [-40.052980965505824, -8.84554634010664],
              [-40.057185260025108, -8.843724577646334],
              [-40.060326189235163, -8.842370901191181],
              [-40.066882391754682, -8.839539340062645],
              [-40.072392695673109, -8.837158601892057],
              [-40.073491358961235, -8.828834815668632],
              [-40.069584704955474, -8.830866422709091],
              [-40.064980845937683, -8.833263169208958],
              [-40.06053190840165, -8.835581832528431],
              [-40.056493204026928, -8.837689930989404],
              [-40.052485388078999, -8.839774119180994],
              [-40.047396731840372, -8.842425169881105],
              [-40.044436119924413, -8.84396445175544],
              [-40.040653884781136, -8.845934671010188],
              [-40.036434330232083, -8.848132342325231],
              [-40.030776026581478, -8.851077696973849],
              [-40.026991738277992, -8.853052592599363],
              [-40.022404176637053, -8.855441595569644],
              [-40.01777399204402, -8.857862498754608],
              [-40.011333238421692, -8.861299624303529],
              [-40.010804984667345, -8.861689395215073],
              [-40.005398745794437, -8.865264446133111],
              [-39.996594916909245, -8.860043294498114],
              [-39.996914673341401, -8.858875871994268],
              [-40.001556615057964, -8.855996293444999],
              [-40.003178468869066, -8.855123375684851],
              [-40.010630228172154, -8.851238978842437],
              [-40.016104748308877, -8.848978756576932],
              [-40.021908069268839, -8.846581454868749],
              [-40.025650419445661, -8.845036943272609],
              [-40.031748420224588, -8.842519769623635],
              [-40.038071556440805, -8.839905927666354],
              [-40.042791985048126, -8.837956916022867],
              [-40.049049762, -8.83537781546689],
              [-40.052473345861792, -8.833959659885648],
              [-40.052472800830444, -8.833959842289902],
              [-40.055911618542552, -8.832537364894401],
              [-40.059552841410714, -8.8310326224889],
              [-40.065083993067468, -8.828745452987336],
              [-40.068272683601101, -8.827424058184318],
              [-40.071755974723061, -8.825989656052958],
              [-40.073941214248947, -8.825082569226657],
              [-40.07432796287587, -8.821926843865931],
              [-40.06756771494851, -8.824846437602027],
              [-40.06338818975204, -8.82665166767312],
              [-40.059639408637224, -8.828263912773126],
              [-40.055469244089963, -8.830065600114375],
              [-40.051274149967824, -8.831877353971178],
              [-40.046904095596574, -8.833762265589552],
              [-40.041616371960934, -8.836035759822343],
              [-40.036778780103788, -8.838123900910325],
              [-40.034105518491586, -8.839287442515021],
              [-40.029252640257049, -8.841372734185622],
              [-40.02182128535118, -8.844589156759255],
              [-40.019631689723333, -8.845531972596028],
              [-40.014965382041211, -8.847544418725706],
              [-40.010865592009537, -8.849320052520561],
              [-40.005153620516495, -8.851786135498118],
              [-40.001660700827649, -8.853296419120701],
              [-39.996317260748505, -8.855604572312791],
              [-39.99213597162391, -8.857410804920587],
              [-39.991361338221452, -8.857689059027429],
              [-39.991235492546835, -8.857758668747243],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0172000',
        uf: 'PE',
        nome_proje: 'PA MÃE MARIANA',
        municipio: 'SANTA CRUZ',
        area_hecta: '1218.0918',
        capacidade: 30.0,
        num_famili: 27.0,
        fase: 3.0,
        data_de_cr: '29/05/2006',
        forma_obte: 'Arrecadação',
        data_obten: '04/07/2006',
        area_calc_: 1218.3485,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.400984864493793, -8.134412283297381],
              [-40.400683254643255, -8.136775785825348],
              [-40.40050990560453, -8.139644978248587],
              [-40.400751356109076, -8.141541349330938],
              [-40.401148906378175, -8.144195881338819],
              [-40.402057474546595, -8.147289940006369],
              [-40.404306264352442, -8.15313382197728],
              [-40.406938273865052, -8.159931186585725],
              [-40.407797249249427, -8.160445364555533],
              [-40.408067054181231, -8.160676286169636],
              [-40.409581533547794, -8.161288465792479],
              [-40.410850928259258, -8.163127525172712],
              [-40.412337805571092, -8.165256457397019],
              [-40.417702889897996, -8.165408703550904],
              [-40.420987850356724, -8.165353237273873],
              [-40.419649477748685, -8.148145521950347],
              [-40.419465244323014, -8.146392035860883],
              [-40.419170802134524, -8.143957104114618],
              [-40.418347482890987, -8.136983784646729],
              [-40.418539093101153, -8.133046490557373],
              [-40.426859605574819, -8.132336706778903],
              [-40.427141353357896, -8.125630193408886],
              [-40.427281120972822, -8.124094683661935],
              [-40.427284787576589, -8.122718081680421],
              [-40.426044735979723, -8.122867476884633],
              [-40.421972444901073, -8.123383336568224],
              [-40.419074470191759, -8.123767558247707],
              [-40.419234778539106, -8.121922166196322],
              [-40.419539948191677, -8.117760393684533],
              [-40.41984480550925, -8.114000486831271],
              [-40.414665586455726, -8.113163685980728],
              [-40.414770835624807, -8.109786258222929],
              [-40.415414679495449, -8.104836122476392],
              [-40.414493407183791, -8.104792296538466],
              [-40.411640027212243, -8.104634801287959],
              [-40.409714495293358, -8.104663708518087],
              [-40.407337833956838, -8.104782874933525],
              [-40.4046632424092, -8.104922406043743],
              [-40.404453897571138, -8.106046528853614],
              [-40.40412628758628, -8.108680141228993],
              [-40.403739965195641, -8.112019936617791],
              [-40.403473840788166, -8.114956908135822],
              [-40.403372153256363, -8.117163911249051],
              [-40.403205238554087, -8.120395430302793],
              [-40.403639403286817, -8.121111669251739],
              [-40.403704987095225, -8.121705200757193],
              [-40.40365908907313, -8.1219459005932],
              [-40.403071004150341, -8.122872024391324],
              [-40.402647629621491, -8.126518141227596],
              [-40.401717764813398, -8.130888986338656],
              [-40.401695885100047, -8.130990794610968],
              [-40.400984864493793, -8.134412283297381],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0219000',
        uf: 'PE',
        nome_proje: 'PA MILAGRE',
        municipio: 'OURICURI',
        area_hecta: '373.9368',
        capacidade: 15.0,
        num_famili: 14.0,
        fase: 3.0,
        data_de_cr: '27/02/2007',
        forma_obte: 'Desapropriação',
        data_obten: '27/02/2007',
        area_calc_: 374.0666,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.259363774230785, -7.799727363525629],
              [-40.234265448711078, -7.803462430120268],
              [-40.237113414382421, -7.818289642652312],
              [-40.251672667463112, -7.814152623303614],
              [-40.25129113065087, -7.810486852571449],
              [-40.260302654216545, -7.809134929711751],
              [-40.259363774230785, -7.799727363525629],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0235000',
        uf: 'PE',
        nome_proje: 'PA TABOLEIRO E BARRA DO LAGES',
        municipio: 'OURICURI',
        area_hecta: '623.8826',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '19/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '18/05/2006',
        area_calc_: 608.7847,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.012205466000317, -7.98994289106544],
              [-40.002134115943477, -7.979508280349043],
              [-40.001640709319219, -7.979942242302783],
              [-40.001512967087955, -7.98009205300739],
              [-40.001366185510953, -7.980321951461467],
              [-40.00121660548384, -7.980632260146219],
              [-40.000994521762074, -7.981161253535239],
              [-40.000946923749765, -7.981324346262102],
              [-40.000945229426932, -7.981336469672093],
              [-40.000945229206856, -7.981336379229996],
              [-40.00086253776513, -7.981395095570811],
              [-40.000102010268847, -7.981934886744983],
              [-39.997155202537414, -7.980188854762003],
              [-39.996772107198339, -7.979908590839474],
              [-39.996760109775245, -7.979899756335914],
              [-39.996599787628782, -7.979672226563617],
              [-39.993234884005226, -7.97489579544401],
              [-39.994099333701094, -7.974486095973581],
              [-39.992496126065262, -7.973476979654192],
              [-39.991668695353773, -7.97513071977241],
              [-39.989305501822976, -7.978775252506423],
              [-39.98272171432712, -7.98528149045488],
              [-39.99904242194345, -8.003019714673334],
              [-39.9991055740321, -8.003057909499436],
              [-40.001076773724343, -8.005303615729897],
              [-40.01036917289435, -8.006077315851085],
              [-40.013938605099952, -8.004207622887433],
              [-40.008321148078792, -7.99847187290516],
              [-40.007962200626281, -7.996692300367711],
              [-40.012205466000317, -7.98994289106544],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0339000',
        uf: 'PE',
        nome_proje: 'PA LOPES',
        municipio: 'OURICURI',
        area_hecta: '1128.7386',
        capacidade: 24.0,
        num_famili: 24.0,
        fase: 3.0,
        data_de_cr: '02/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2004',
        area_calc_: 1129.3494,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.931409594552463, -8.055748819145842],
              [-39.929756992243973, -8.058241681442871],
              [-39.92219482175981, -8.067222872902329],
              [-39.91965218584361, -8.070537947505469],
              [-39.922951436327374, -8.072121167312842],
              [-39.927201265105182, -8.073880651653653],
              [-39.930475277157683, -8.074842510865814],
              [-39.931419147795225, -8.075030929398295],
              [-39.93546811042863, -8.073752675585359],
              [-39.938303480665361, -8.073549018054029],
              [-39.939406490216179, -8.073486795184678],
              [-39.943960167394899, -8.074990527810051],
              [-39.953329111408422, -8.079489988979814],
              [-39.954271902566489, -8.080873383826342],
              [-39.954580148010798, -8.080781045269134],
              [-39.955297079911759, -8.080356457894032],
              [-39.956249396091799, -8.07957578624911],
              [-39.958937989755469, -8.077391345459738],
              [-39.963790427191817, -8.073694021072011],
              [-39.96679883665005, -8.071398714863447],
              [-39.967171492480887, -8.071111310874155],
              [-39.967233857096808, -8.071005435529475],
              [-39.967168913767409, -8.066056811265401],
              [-39.96715297499361, -8.064848618709508],
              [-39.966943529323459, -8.063663764817978],
              [-39.966613296533531, -8.062476753521208],
              [-39.965796375027281, -8.059406865941263],
              [-39.965036899122609, -8.059281943187729],
              [-39.962768381694531, -8.058108166746127],
              [-39.947806457861795, -8.050528565059039],
              [-39.946029235964929, -8.049670826391552],
              [-39.942810052637185, -8.049107622291656],
              [-39.934008559539059, -8.050284882412729],
              [-39.931409594552463, -8.055748819145842],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0011000',
        uf: 'PE',
        nome_proje: 'PA VENEZUELA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '769.0000',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 5.0,
        data_de_cr: '03/09/1991',
        forma_obte: 'Desapropriação',
        data_obten: '30/06/1988',
        area_calc_: 768.3925,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.902521200130202, -8.698275146901544],
              [-38.902106706565135, -8.700922949661095],
              [-38.901991254711916, -8.701621756593736],
              [-38.90180759230514, -8.702782939429682],
              [-38.904195552411146, -8.704203653476188],
              [-38.904247407370704, -8.704413787853253],
              [-38.903299496071526, -8.7104901507811],
              [-38.904287184768492, -8.711367790312513],
              [-38.904143213509258, -8.713393709891166],
              [-38.905189303359315, -8.71452408612882],
              [-38.905620907949569, -8.715036516650137],
              [-38.906245465530382, -8.71502527420837],
              [-38.907537802814787, -8.714841067638535],
              [-38.908117942721361, -8.714674775667518],
              [-38.908627333262196, -8.714967783768166],
              [-38.909542858676772, -8.715485753065371],
              [-38.910545646477878, -8.716064343914978],
              [-38.911614970709962, -8.716700023160055],
              [-38.912232479202572, -8.716520437102186],
              [-38.912907444148743, -8.716334712433323],
              [-38.913498763428976, -8.715784624699667],
              [-38.913896491475867, -8.715413769145073],
              [-38.914635578832119, -8.714279932288518],
              [-38.91489215035601, -8.71297367404711],
              [-38.915160083192184, -8.711669950733175],
              [-38.915502327009115, -8.710593279593304],
              [-38.916515174827452, -8.710885031945438],
              [-38.917688127115277, -8.711541430613472],
              [-38.918374321557756, -8.712340455635532],
              [-38.91961219038879, -8.71257589607035],
              [-38.919747032071633, -8.711618484091439],
              [-38.91990053495978, -8.7105413167275],
              [-38.91995274207342, -8.709995627303204],
              [-38.919120288902469, -8.707442607830988],
              [-38.919383977054856, -8.707194642794679],
              [-38.920669950332751, -8.705992888553999],
              [-38.921065205802329, -8.705625009572],
              [-38.921279554801217, -8.705685114511775],
              [-38.922008050665454, -8.705891134674916],
              [-38.923614034290267, -8.706349331603798],
              [-38.923909689143045, -8.70579654450804],
              [-38.924211717592655, -8.705189577313478],
              [-38.92447484241827, -8.704099404246957],
              [-38.924551641062173, -8.703737789906416],
              [-38.924835418117887, -8.702556082525792],
              [-38.925472601605478, -8.702030858956681],
              [-38.925696032442367, -8.701207331413709],
              [-38.925818115192811, -8.700773725285103],
              [-38.925098086083246, -8.699629267958548],
              [-38.92489952737629, -8.69880864246476],
              [-38.926557843444556, -8.698350642363698],
              [-38.926908670935866, -8.698250397877121],
              [-38.927571262011334, -8.69696609677022],
              [-38.927616693796701, -8.695656260405912],
              [-38.926235284203983, -8.691962089186278],
              [-38.926202665880147, -8.69187750961437],
              [-38.923693040552486, -8.685200883070086],
              [-38.922026574645905, -8.683129450289632],
              [-38.91971211777728, -8.680474092488678],
              [-38.91641876578597, -8.672516529670505],
              [-38.91329885189149, -8.674657574046654],
              [-38.913684046501821, -8.676834858551846],
              [-38.915052042048977, -8.678756652673261],
              [-38.915483658744918, -8.679907307076702],
              [-38.915006080934759, -8.681024744240352],
              [-38.913194679910305, -8.68375664104151],
              [-38.913291370174562, -8.685076820024925],
              [-38.912445826183074, -8.685697222680693],
              [-38.91114387738908, -8.685440578626636],
              [-38.908627257599889, -8.686407827476035],
              [-38.907710349564809, -8.687375811952728],
              [-38.906167615124978, -8.692519748668087],
              [-38.903539011450526, -8.693208612632409],
              [-38.902521200130202, -8.698275146901544],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0257000',
        uf: 'PE',
        nome_proje: 'PA SANTA MARTA I',
        municipio: 'OURICURI',
        area_hecta: '628.6109',
        capacidade: 52.0,
        num_famili: 52.0,
        fase: 3.0,
        data_de_cr: '20/12/2007',
        forma_obte: 'Doação',
        data_obten: '20/12/2007',
        area_calc_: 531.1799,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.060527518417828, -8.167698784055727],
              [-40.056647336092006, -8.167053786573501],
              [-40.051702271635506, -8.166231723688927],
              [-40.037481911219125, -8.163867407866434],
              [-40.03387499024479, -8.168234784824936],
              [-40.033905998054323, -8.168291773604963],
              [-40.033997917211281, -8.168492135929343],
              [-40.034117981226615, -8.16876559271058],
              [-40.034293054170277, -8.169075537254567],
              [-40.034514085830594, -8.169467484201975],
              [-40.034749310608937, -8.169519790438493],
              [-40.034785048413454, -8.169510112345399],
              [-40.035110103716477, -8.169480249495232],
              [-40.035352081147614, -8.16940555959788],
              [-40.035396738167861, -8.16940517400511],
              [-40.03563046294471, -8.169403761890358],
              [-40.035836384369333, -8.169532202953308],
              [-40.036046881311094, -8.169780556288393],
              [-40.036065216404232, -8.169780509338425],
              [-40.036333920074895, -8.16982703112313],
              [-40.036578028281269, -8.169874610580827],
              [-40.036718563899946, -8.169884741603125],
              [-40.036784354757707, -8.169737788113402],
              [-40.036959253326316, -8.169627635604247],
              [-40.037170092341107, -8.169656669236032],
              [-40.037335465552218, -8.169759528304544],
              [-40.037565291523578, -8.169584027150568],
              [-40.037784607744989, -8.169380968505049],
              [-40.038104319245427, -8.169180184542592],
              [-40.038322579342804, -8.169025242688395],
              [-40.03862244112581, -8.168870814818273],
              [-40.038894004613233, -8.168900234360574],
              [-40.039205998926811, -8.168699107688607],
              [-40.039511971224513, -8.168591240349331],
              [-40.039577297988252, -8.168546033204404],
              [-40.039643579452402, -8.16851900212048],
              [-40.039744282062621, -8.168464660024096],
              [-40.039789982243789, -8.168446544959441],
              [-40.040042801795025, -8.168283102543178],
              [-40.039803155310857, -8.168030213913807],
              [-40.039659798721807, -8.167841652204423],
              [-40.039593449968194, -8.16752690911877],
              [-40.039381855965367, -8.167275756891188],
              [-40.039225519720524, -8.167051956528747],
              [-40.03906980026111, -8.166891101040818],
              [-40.038877709961682, -8.166775739932097],
              [-40.038712447401352, -8.16671529766186],
              [-40.038442398745765, -8.166779751114275],
              [-40.038194356140593, -8.166824703252219],
              [-40.038086051643077, -8.16674720224675],
              [-40.038069317803505, -8.166594039020978],
              [-40.038418030935659, -8.166552717655305],
              [-40.038639008647216, -8.166501594463162],
              [-40.038765941728983, -8.166411732687331],
              [-40.038720210137797, -8.166171821102946],
              [-40.03859396367325, -8.165965398179862],
              [-40.038594025466125, -8.1658488202795],
              [-40.038802689311105, -8.166090574802569],
              [-40.038947291694917, -8.166269908835194],
              [-40.039057629203747, -8.166466695324059],
              [-40.039239942280133, -8.166592029911914],
              [-40.039446283215177, -8.166637262921828],
              [-40.039496354120438, -8.166870199301988],
              [-40.039496468283311, -8.166879243050998],
              [-40.039625872503265, -8.167113060926569],
              [-40.039783709660277, -8.167320487532606],
              [-40.040117958389324, -8.167547086280392],
              [-40.040213278279282, -8.167692631218586],
              [-40.040378158350926, -8.167885568955773],
              [-40.040771239387375, -8.167942349649483],
              [-40.041109859338349, -8.167823815734376],
              [-40.041288385760936, -8.167678380514438],
              [-40.041450432801859, -8.167513542958924],
              [-40.041499553607643, -8.167449475215779],
              [-40.041548493098553, -8.167385498375362],
              [-40.041744910926973, -8.167104085092113],
              [-40.041924691958918, -8.167267681114241],
              [-40.042285132586962, -8.167268561842238],
              [-40.042654267065714, -8.167086911259016],
              [-40.043069563221117, -8.166960310014035],
              [-40.04325483304055, -8.166860529037233],
              [-40.043241312063493, -8.166617098549374],
              [-40.043389503617043, -8.166465048155791],
              [-40.043472006906114, -8.166393478064867],
              [-40.043464513194422, -8.166269051510408],
              [-40.043547221565561, -8.166171614951926],
              [-40.043809765146236, -8.166153754352074],
              [-40.043926412297012, -8.166402616567238],
              [-40.043998853779463, -8.166545868042663],
              [-40.044326617104581, -8.166861020878409],
              [-40.044417707035791, -8.166915230888879],
              [-40.044555652829743, -8.1671865575073],
              [-40.044720860673941, -8.167295473385787],
              [-40.044949601950528, -8.167367958604292],
              [-40.045240557177536, -8.167385295332643],
              [-40.045778586550583, -8.167439345314277],
              [-40.046190379472918, -8.167466679274687],
              [-40.046352126225997, -8.167640539447728],
              [-40.046326802860222, -8.167849974049211],
              [-40.046327079310778, -8.167886420746486],
              [-40.046383439430571, -8.168023291968133],
              [-40.046411056416645, -8.168032355022012],
              [-40.046605119517544, -8.168033028904924],
              [-40.046715105998821, -8.16781478351901],
              [-40.046846059261185, -8.16763121292475],
              [-40.046938330761954, -8.167511683597505],
              [-40.047107583352563, -8.167465754303636],
              [-40.04731547475641, -8.167548330925051],
              [-40.047495644324094, -8.167511959882175],
              [-40.047694889713661, -8.167411598165684],
              [-40.047928017678402, -8.167495375292132],
              [-40.048186778672715, -8.167523555614641],
              [-40.04839926687108, -8.167524090506067],
              [-40.048577057167719, -8.167132566618671],
              [-40.048645449428747, -8.166906108123611],
              [-40.048819406953157, -8.166924197613435],
              [-40.049149223031463, -8.167050772991784],
              [-40.049295022757249, -8.167095976860354],
              [-40.049347571176256, -8.167267496020999],
              [-40.049258907017069, -8.167412520573114],
              [-40.049099774588981, -8.167649343662369],
              [-40.048923303262988, -8.167920126708838],
              [-40.048841592390943, -8.168155844656903],
              [-40.048735364618054, -8.16824882112447],
              [-40.04857546124569, -8.168225268844495],
              [-40.0483021025155, -8.168272915509801],
              [-40.048028411361663, -8.168367139551325],
              [-40.04772326088672, -8.168475643984944],
              [-40.047399684964688, -8.168619196255456],
              [-40.047197152201676, -8.168747060232285],
              [-40.04704010548771, -8.168879328438814],
              [-40.046865515419178, -8.16868849911685],
              [-40.046626633660466, -8.168451982796142],
              [-40.046397080388097, -8.168451400721256],
              [-40.046057351782828, -8.168596712106913],
              [-40.045954926107633, -8.16879069985027],
              [-40.046139406655307, -8.168980418759523],
              [-40.046489297890368, -8.169112461140823],
              [-40.046828797568494, -8.169192707835085],
              [-40.047094851001518, -8.16926717524446],
              [-40.047241543078705, -8.169375866486151],
              [-40.047213959476203, -8.169484104352287],
              [-40.04726835183412, -8.169666743206362],
              [-40.047305021265352, -8.169875293816514],
              [-40.047214275298664, -8.170092675609265],
              [-40.047113890439917, -8.170338209239596],
              [-40.047269608991563, -8.170392612939402],
              [-40.04743473237302, -8.170537975052195],
              [-40.047709625703419, -8.17076481074041],
              [-40.047936546845108, -8.170799765989303],
              [-40.048246933779879, -8.170853768598748],
              [-40.048466829769488, -8.170944452876759],
              [-40.048586790837227, -8.171035486508989],
              [-40.048786388311768, -8.171034697777291],
              [-40.049075912698406, -8.171024541301513],
              [-40.049318145900713, -8.171014055087571],
              [-40.049626500953991, -8.171019857278877],
              [-40.049818557355515, -8.171155199980944],
              [-40.050106678331105, -8.171337322071906],
              [-40.050355615005493, -8.171356391728073],
              [-40.050620486498872, -8.171325316031211],
              [-40.051027392572024, -8.171356817462181],
              [-40.05154282021747, -8.1714433857156],
              [-40.0518959833502, -8.171679691796609],
              [-40.052089083970657, -8.17179748519643],
              [-40.052208542153586, -8.171904074612797],
              [-40.052412957153749, -8.171905803827542],
              [-40.052982675536605, -8.171788648365565],
              [-40.053363344514189, -8.171679671878037],
              [-40.053473713781663, -8.171677756537175],
              [-40.053692307451925, -8.171721321915589],
              [-40.054007233611095, -8.171636934896128],
              [-40.054173558014099, -8.17133976786622],
              [-40.05427971201663, -8.171080651965958],
              [-40.054360689848409, -8.1709125842983],
              [-40.054518362576665, -8.170604677375435],
              [-40.054541731490396, -8.170413064569287],
              [-40.054701470199298, -8.170097012625943],
              [-40.054932484680378, -8.169824366883104],
              [-40.055158367358139, -8.169671476952034],
              [-40.055406432444116, -8.169462908474816],
              [-40.055653093541515, -8.169376437486042],
              [-40.055897655937351, -8.169285811598517],
              [-40.056133395140165, -8.169257159970524],
              [-40.056437694825227, -8.169240267284152],
              [-40.056437604058146, -8.169240267521133],
              [-40.056574463197535, -8.169233217619954],
              [-40.05678389029746, -8.16913960801841],
              [-40.05695879811433, -8.169070055070026],
              [-40.05715989221499, -8.169017707664882],
              [-40.057267394616993, -8.169030450138292],
              [-40.057462988610425, -8.169198971224091],
              [-40.057624196241591, -8.169441923677962],
              [-40.057815261517696, -8.169613983540076],
              [-40.05796192370299, -8.16981374356619],
              [-40.058130323706216, -8.169961353292699],
              [-40.058432537817943, -8.170117385554331],
              [-40.058619330202873, -8.170217737306105],
              [-40.058794412029684, -8.170317486527544],
              [-40.059017322238716, -8.170380391884017],
              [-40.059323078809776, -8.170399488184048],
              [-40.059671547480797, -8.170369182663505],
              [-40.060046095811188, -8.170246831207168],
              [-40.060410938950724, -8.170127218063662],
              [-40.060704987542394, -8.170009327700436],
              [-40.060926867991505, -8.169957285690758],
              [-40.061216618463021, -8.169861744890595],
              [-40.061664601885518, -8.169707002869357],
              [-40.061901406177121, -8.169635114888747],
              [-40.062169023072592, -8.169544967498267],
              [-40.062416988355828, -8.169403230436966],
              [-40.062671228515754, -8.169197445412301],
              [-40.062906855466032, -8.16898953859781],
              [-40.063151620834383, -8.168805031560828],
              [-40.063271736681919, -8.16861081313597],
              [-40.063468517126211, -8.168506742525439],
              [-40.0637290594699, -8.168453331361597],
              [-40.063929600899556, -8.168467093634618],
              [-40.064314244413836, -8.168456405113119],
              [-40.064700367929326, -8.168456203340746],
              [-40.065114575346342, -8.168435668789249],
              [-40.065543923083986, -8.168442678056806],
              [-40.065972296942483, -8.168390541885504],
              [-40.066609567978027, -8.168320038117045],
              [-40.067167820852028, -8.168230568554659],
              [-40.067652213248927, -8.16815024664549],
              [-40.068037825261179, -8.168060326974151],
              [-40.068336297809161, -8.168003466582785],
              [-40.068656263526449, -8.167973771545153],
              [-40.069008662562041, -8.167989300821723],
              [-40.069450379353214, -8.167971854634551],
              [-40.069773929269793, -8.167924784857524],
              [-40.070083841597366, -8.167767052871033],
              [-40.070256087078064, -8.167687553220633],
              [-40.070452335121601, -8.167554992442682],
              [-40.070557861933295, -8.167371482788159],
              [-40.070570326580437, -8.167075983813005],
              [-40.070485832310219, -8.166636671079871],
              [-40.070479760199042, -8.16626534233065],
              [-40.0704598379548, -8.16583978646589],
              [-40.07047539526414, -8.165342237317287],
              [-40.070488969480543, -8.16502023659138],
              [-40.070518205806543, -8.164580803898756],
              [-40.07056599210015, -8.164222175121326],
              [-40.070586603153345, -8.163884057540615],
              [-40.070605502712255, -8.163584923934797],
              [-40.070648221119313, -8.163334202953468],
              [-40.070722068407498, -8.163116138781241],
              [-40.070736197657183, -8.162900312625892],
              [-40.070695817262923, -8.162699734007434],
              [-40.070695984582834, -8.162694397630741],
              [-40.07064042334553, -8.162485176939144],
              [-40.070542946030479, -8.162248482971206],
              [-40.070327889622305, -8.161895342239465],
              [-40.070276628621258, -8.161732957789377],
              [-40.070262601271395, -8.161577981418121],
              [-40.070244878366836, -8.161364500399731],
              [-40.070309963104258, -8.161196201224882],
              [-40.07054185834582, -8.161089954919404],
              [-40.070742345750212, -8.161050807249552],
              [-40.071004041960215, -8.160955877304808],
              [-40.071276680084885, -8.160845724420451],
              [-40.07147140819076, -8.160825312633163],
              [-40.071676582260345, -8.160841953328999],
              [-40.071953768327838, -8.160973894625821],
              [-40.072211997409688, -8.161144684451649],
              [-40.072463997283833, -8.161293965972822],
              [-40.072742921958387, -8.161396961406655],
              [-40.0730187924284, -8.161409796537022],
              [-40.073336908353888, -8.161403436787751],
              [-40.073639506355157, -8.161398203200726],
              [-40.073847311541272, -8.161346373086321],
              [-40.074051850756064, -8.161261179366944],
              [-40.074243654385654, -8.161097427435427],
              [-40.074423504467326, -8.160944017240176],
              [-40.074559036761357, -8.160747584935715],
              [-40.074712443153466, -8.160514748513418],
              [-40.074795697359392, -8.160285444462842],
              [-40.074905957589849, -8.159938587889155],
              [-40.074944187088619, -8.159633259449484],
              [-40.077074440128747, -8.160418668234046],
              [-40.078056563990202, -8.157760393849371],
              [-40.08097827057756, -8.149852283865302],
              [-40.079288764338521, -8.149359366960683],
              [-40.086806182761308, -8.143478834745485],
              [-40.087655728891761, -8.127329726236789],
              [-40.070543489572955, -8.14354629361859],
              [-40.074471803622551, -8.149731020540374],
              [-40.074213008863993, -8.151386749218073],
              [-40.060527518417828, -8.167698784055727],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0253000',
        uf: 'PE',
        nome_proje: 'PA MIGUEL BERNARDO',
        municipio: 'PARNAMIRIM',
        area_hecta: '882.6874',
        capacidade: 45.0,
        num_famili: 45.0,
        fase: 3.0,
        data_de_cr: '10/12/2007',
        forma_obte: 'Doação',
        data_obten: '10/12/2007',
        area_calc_: 883.1298,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.020848036209216, -8.219955159786537],
              [-39.987524975695493, -8.226673536692452],
              [-39.987525342631521, -8.226675073303747],
              [-39.988064578812292, -8.227475425137252],
              [-39.989141433380283, -8.228274724258858],
              [-39.990216873663165, -8.22853933121525],
              [-39.991807916419717, -8.229259122567314],
              [-39.994229190626839, -8.230055989694558],
              [-39.996783593035097, -8.231119494554047],
              [-39.998666846085435, -8.232184650847248],
              [-40.00014840025932, -8.233652358248859],
              [-40.002033445782587, -8.235386306678457],
              [-40.002171597642658, -8.236857264242527],
              [-40.004054489519618, -8.237655046077823],
              [-40.005267360796879, -8.239123311160423],
              [-40.006207890829714, -8.239120954099198],
              [-40.009702187969836, -8.239647137958309],
              [-40.010878229770363, -8.23893520814257],
              [-40.011752488320219, -8.239577579730168],
              [-40.011241104095767, -8.240153261063082],
              [-40.011628029664081, -8.241045935579528],
              [-40.011629326542014, -8.241556382599292],
              [-40.010486719516692, -8.242173267751115],
              [-40.010781903987734, -8.24272521057136],
              [-40.010976271624308, -8.243152146059904],
              [-40.011782980365759, -8.243574281354745],
              [-40.012923943972041, -8.243524100924018],
              [-40.013683281679157, -8.242954664330249],
              [-40.014729492485024, -8.242952019145193],
              [-40.016109668278574, -8.243468742606026],
              [-40.016777600093413, -8.244506851077601],
              [-40.017303459781679, -8.245404863448975],
              [-40.017400447637989, -8.246161518566433],
              [-40.01740177724789, -8.246681551228425],
              [-40.016064135768865, -8.246330142791599],
              [-40.015549591313601, -8.245311994205602],
              [-40.015100282604308, -8.244170408242669],
              [-40.015100264727629, -8.244170383889761],
              [-40.015100224080157, -8.244170282434133],
              [-40.0151001822056, -8.24417018147496],
              [-40.015100139110061, -8.244170081026963],
              [-40.015100094799863, -8.244169981104825],
              [-40.015100049281408, -8.244169881723128],
              [-40.015100002561375, -8.24416978289636],
              [-40.01509995464658, -8.244169684638926],
              [-40.015099905544012, -8.244169586965192],
              [-40.015099855260829, -8.244169489889375],
              [-40.015099803804368, -8.244169393425629],
              [-40.015099751182127, -8.244169297588064],
              [-40.015099697401801, -8.244169202390639],
              [-40.015099642471206, -8.244169107847227],
              [-40.015099586398399, -8.244169013971641],
              [-40.015099529191517, -8.244168920777598],
              [-40.015099470858935, -8.244168828278635],
              [-40.015099411409132, -8.244168736488279],
              [-40.015099350850832, -8.244168645419927],
              [-40.015099289192804, -8.244168555086837],
              [-40.015099226444093, -8.244168465502211],
              [-40.015099162613829, -8.244168376679106],
              [-40.015099097711328, -8.244168288630499],
              [-40.015099031746075, -8.244168201369227],
              [-40.01509896472767, -8.244168114907989],
              [-40.01509889666589, -8.244168029259429],
              [-40.015098827570682, -8.244167944436066],
              [-40.015098757452108, -8.244167860450212],
              [-40.015098686320414, -8.244167777314171],
              [-40.015098614185966, -8.244167695040025],
              [-40.015098541059288, -8.244167613639817],
              [-40.015098466951045, -8.244167533125387],
              [-40.015098391872044, -8.244167453508522],
              [-40.015098315833249, -8.244167374800821],
              [-40.015098238845752, -8.244167297013735],
              [-40.015098160920786, -8.244167220158651],
              [-40.015098082069706, -8.244167144246768],
              [-40.015098002304022, -8.244167069289164],
              [-40.015097921635373, -8.244166995296723],
              [-40.015097840075519, -8.244166922280312],
              [-40.015097757636369, -8.244166850250574],
              [-40.015097674329951, -8.244166779217975],
              [-40.015097590168395, -8.244166709192916],
              [-40.015097505164015, -8.244166640185588],
              [-40.015097419329166, -8.244166572206058],
              [-40.015097332676405, -8.244166505264271],
              [-40.015097245218364, -8.244166439369959],
              [-40.015097156967812, -8.24416637453276],
              [-40.015097067937589, -8.244166310762116],
              [-40.015096978140718, -8.244166248067303],
              [-40.015096887590289, -8.244166186457541],
              [-40.015096796299495, -8.244166125941755],
              [-40.015096704281675, -8.244166066528802],
              [-40.015096611550248, -8.244166008227333],
              [-40.015096518118739, -8.244165951045849],
              [-40.015096424000767, -8.244165894992705],
              [-40.015096329210074, -8.244165840076107],
              [-40.015096233760488, -8.24416578630402],
              [-40.015096137665942, -8.244165733684291],
              [-40.015096040940428, -8.244165682224633],
              [-40.015095943598091, -8.244165631932493],
              [-40.015095845653093, -8.244165582815263],
              [-40.015095747119751, -8.244165534880118],
              [-40.015095648012434, -8.244165488133993],
              [-40.015095548345599, -8.244165442583745],
              [-40.015095448133771, -8.244165398236005],
              [-40.015095347391593, -8.244165355097239],
              [-40.01509524613374, -8.244165313173777],
              [-40.015095144374996, -8.244165272471665],
              [-40.015095042130199, -8.244165232996897],
              [-40.015094939414276, -8.244165194755226],
              [-40.015094836242199, -8.244165157752262],
              [-40.015094732629024, -8.244165121993321],
              [-40.015094628589857, -8.244165087483642],
              [-40.01509452413989, -8.244165054228317],
              [-40.015094419294364, -8.244165022232147],
              [-40.015094314068556, -8.244164991499794],
              [-40.015094208477819, -8.244164962035779],
              [-40.015094102537581, -8.244164933844401],
              [-40.015093996263246, -8.244164906929726],
              [-40.015093889670382, -8.244164881295703],
              [-40.0150937827745, -8.24416485694608],
              [-40.015093675591174, -8.244164833884408],
              [-40.015093568136095, -8.244164812114057],
              [-40.015093460424893, -8.244164791638184],
              [-40.015093352473322, -8.244164772459788],
              [-40.015093244297077, -8.244164754581654],
              [-40.015093135911982, -8.244164738006408],
              [-40.015093027333833, -8.24416472273643],
              [-40.015092918578461, -8.244164708774026],
              [-40.015092809661759, -8.244164696121159],
              [-40.015092700599588, -8.244164684779674],
              [-40.015092591407878, -8.244164674751303],
              [-40.015092482102538, -8.244164666037419],
              [-40.015092372699527, -8.244164658639351],
              [-40.015092263214818, -8.244164652558158],
              [-40.015092153664341, -8.244164647794749],
              [-40.015092044064112, -8.244164644349752],
              [-40.01509193443011, -8.244164642223748],
              [-40.015091824778331, -8.244164641417029],
              [-40.015091789651706, -8.244164641505918],
              [-40.013751646893994, -8.244168031057548],
              [-40.013175912895797, -8.244680116715843],
              [-40.011380733090391, -8.244620796347204],
              [-40.010546418003223, -8.244303822748059],
              [-40.010480702746051, -8.243665653999326],
              [-40.010030310020547, -8.243092305512858],
              [-40.009195028088662, -8.242392312153118],
              [-40.008296644712175, -8.242011733118154],
              [-40.007718572805764, -8.241566314974131],
              [-40.007099801723967, -8.242258207501093],
              [-40.003260880215279, -8.247647373506714],
              [-40.043491023240193, -8.252066697681133],
              [-40.046045895290803, -8.239678763806973],
              [-40.013315840809312, -8.238840247499304],
              [-40.020848036209216, -8.219955159786537],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0034000',
        uf: 'PE',
        nome_proje: 'PA BOM JESUS',
        municipio: 'OROCO',
        area_hecta: '361.2200',
        capacidade: 60.0,
        num_famili: 56.0,
        fase: 5.0,
        data_de_cr: '13/08/1999',
        forma_obte: 'Desapropriação',
        data_obten: '16/11/1998',
        area_calc_: 348.7778,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.550610058851056, -8.554391758921604],
              [-39.55018170179946, -8.555718084808436],
              [-39.549757792658859, -8.557043047252625],
              [-39.549326059256366, -8.558363226617926],
              [-39.548914262399734, -8.559647107097421],
              [-39.548505301055528, -8.560882230984966],
              [-39.548105422465156, -8.562116075357011],
              [-39.547731744767631, -8.563309180051949],
              [-39.547385870860943, -8.564374801903298],
              [-39.547077390350253, -8.565346303219387],
              [-39.546792775201794, -8.566231572309366],
              [-39.54650460494333, -8.567109881655782],
              [-39.545706376153177, -8.56958425782355],
              [-39.545376196773972, -8.570638640329546],
              [-39.544725254110276, -8.572662730753557],
              [-39.544329356214597, -8.573899822534795],
              [-39.54393381896179, -8.575136551682141],
              [-39.543044209747514, -8.577915414310914],
              [-39.542081454036406, -8.580900059952285],
              [-39.541888919088755, -8.581496753655806],
              [-39.541165353342812, -8.58372734671112],
              [-39.541258856494196, -8.585261598742813],
              [-39.541267137019176, -8.585397532528438],
              [-39.542677822355678, -8.585440855032314],
              [-39.542678048153221, -8.585445296700728],
              [-39.543262320117499, -8.585085028320099],
              [-39.54328947408078, -8.585072508056284],
              [-39.543847085263721, -8.584816654947158],
              [-39.543848445707717, -8.584815893535813],
              [-39.543849530020466, -8.584815011904372],
              [-39.543850374811242, -8.584814030893774],
              [-39.543851012587581, -8.58481296889542],
              [-39.543851472177806, -8.5848118421063],
              [-39.543851779111932, -8.584810664758921],
              [-39.543851955965081, -8.584809449328523],
              [-39.543852022666897, -8.584808206719686],
              [-39.543851889228343, -8.58480563501203],
              [-39.543851492949464, -8.58480305367201],
              [-39.543850926998218, -8.584800510464765],
              [-39.543844727528374, -8.58478007952381],
              [-39.543844204923239, -8.584777656386422],
              [-39.543843882137118, -8.584775613456442],
              [-39.543843720788729, -8.584773896037149],
              [-39.543843684455595, -8.584772454335234],
              [-39.54384388710821, -8.584770087037153],
              [-39.543844288282394, -8.58476845779961],
              [-39.543844744356235, -8.5847673272111],
              [-39.543845700403168, -8.584765791733421],
              [-39.543846309389195, -8.584765101369266],
              [-39.543847010970566, -8.584764465783465],
              [-39.543856677028678, -8.584757430886599],
              [-39.543866646280989, -8.584751586501028],
              [-39.543876833805733, -8.584746773073325],
              [-39.543897918763868, -8.584739598038084],
              [-39.543919102512419, -8.584735125957387],
              [-39.543940008857838, -8.584732589554283],
              [-39.543960364862727, -8.584731399229323],
              [-39.54412130385569, -8.584734417352186],
              [-39.544140603740445, -8.58473325505477],
              [-39.544157012459991, -8.58473141703821],
              [-39.544170936108841, -8.584729069562433],
              [-39.544194156763588, -8.584723020906274],
              [-39.544210466189405, -8.58471661916616],
              [-39.544221992539875, -8.584710631697101],
              [-39.544238022659691, -8.584699538913865],
              [-39.544245423214782, -8.584692964006109],
              [-39.544252384701771, -8.584685655233049],
              [-39.544369877200324, -8.584551533942738],
              [-39.544370601624003, -8.584549723933549],
              [-39.544662774596901, -8.584173403998257],
              [-39.544659768886049, -8.584168614428531],
              [-39.544971172780464, -8.583704712180726],
              [-39.544971081647496, -8.583704531410847],
              [-39.54518931984375, -8.583419036265113],
              [-39.545189227678335, -8.583418131902119],
              [-39.545435657769488, -8.583083663700213],
              [-39.545435929490104, -8.58308303017073],
              [-39.545709642107198, -8.582762904677606],
              [-39.545713461299201, -8.582764617811605],
              [-39.54594261019318, -8.582547486542227],
              [-39.545942792459357, -8.582547848081568],
              [-39.546362564153412, -8.582244610349195],
              [-39.546365284700833, -8.582240626726147],
              [-39.546521159796391, -8.582075087800096],
              [-39.54671191467753, -8.581946537164097],
              [-39.546713214932552, -8.581947147936781],
              [-39.546941792607711, -8.581731575106984],
              [-39.546942337724929, -8.581731483884854],
              [-39.547300270026547, -8.581588609054645],
              [-39.547300178115947, -8.58158788559059],
              [-39.547612967603193, -8.581460450905253],
              [-39.547934079079987, -8.581369726572918],
              [-39.547932891359572, -8.581365296244298],
              [-39.548453713777974, -8.581252941765216],
              [-39.54845006738384, -8.581244987411502],
              [-39.548912876236727, -8.581297875185957],
              [-39.548912877405847, -8.581298689227838],
              [-39.54931121993193, -8.581326251987303],
              [-39.549311945886437, -8.581325527359471],
              [-39.549907556531764, -8.581338879384273],
              [-39.549906648309261, -8.581339242475881],
              [-39.550411556923294, -8.581409253781128],
              [-39.550415089167892, -8.581401017859093],
              [-39.550851061588261, -8.581496995410481],
              [-39.550840887704567, -8.58149981386395],
              [-39.551211128028214, -8.581574991067727],
              [-39.551210218893424, -8.581574721018937],
              [-39.551533846827219, -8.581591172493686],
              [-39.551537038654637, -8.581598946565345],
              [-39.551890638550667, -8.58160386783498],
              [-39.551889275827428, -8.581604141132187],
              [-39.552473372376959, -8.581635957276555],
              [-39.552474098717902, -8.581635503990231],
              [-39.552808228602274, -8.581624623683982],
              [-39.552811497461541, -8.581622810015412],
              [-39.553097797861426, -8.581528784712262],
              [-39.553353798150567, -8.580717540369429],
              [-39.553435038497256, -8.58046561321977],
              [-39.553655147378386, -8.579783400783951],
              [-39.553987711067627, -8.5786929199502],
              [-39.554322832445898, -8.577550065114833],
              [-39.554690228488404, -8.576418560270911],
              [-39.555052834551837, -8.575243917755584],
              [-39.555412836521732, -8.574092162365943],
              [-39.555780054663181, -8.572904849778334],
              [-39.556157344113636, -8.571708296588067],
              [-39.556556602994441, -8.570435915002506],
              [-39.556583010429613, -8.570348503006366],
              [-39.556942821905999, -8.569196475402087],
              [-39.556956208605143, -8.569153673622035],
              [-39.557347042208754, -8.56790228774865],
              [-39.557473038447476, -8.567498973812162],
              [-39.558704769707866, -8.563557408056216],
              [-39.559940876311138, -8.5596408872679],
              [-39.556178462131484, -8.557529893583448],
              [-39.55334349407665, -8.555925589186245],
              [-39.552027012104226, -8.555181986146586],
              [-39.551886444400807, -8.555122670982529],
              [-39.550610058851056, -8.554391758921604],
            ],
            [
              [-39.542677822355678, -8.585440855032314],
              [-39.542677767689121, -8.585439779684945],
              [-39.542678132732568, -8.585440864563456],
              [-39.542677822355678, -8.585440855032314],
            ],
            [
              [-39.546521159796391, -8.582075087800096],
              [-39.546519445831393, -8.582076242848599],
              [-39.546521352256356, -8.58207488340803],
              [-39.546521159796391, -8.582075087800096],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0095000',
        uf: 'PE',
        nome_proje: 'PA SALÃOZINHO',
        municipio: 'CABROBO',
        area_hecta: '253.4848',
        capacidade: 7.0,
        num_famili: 6.0,
        fase: 4.0,
        data_de_cr: '22/07/2004',
        forma_obte: 'Confisco',
        data_obten: '22/06/2004',
        area_calc_: 253.003,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.474936852880958, -8.368244075596071],
              [-39.472399461182746, -8.370204300873167],
              [-39.481700428790127, -8.404821280381414],
              [-39.487915558029442, -8.430075832114715],
              [-39.490557760127551, -8.428449037648612],
              [-39.484565918745851, -8.400977938378997],
              [-39.474936852880958, -8.368244075596071],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0286000',
        uf: 'PE',
        nome_proje: 'PA VÁRZEA DA ONÇA',
        municipio: 'CABROBO',
        area_hecta: '669.3907',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 3.0,
        data_de_cr: '01/12/2009',
        forma_obte: 'Confisco',
        data_obten: '01/12/2009',
        area_calc_: 669.9338,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.107447435140898, -8.382396387045869],
              [-39.108203089140211, -8.382489257817804],
              [-39.109016418536953, -8.382571166272916],
              [-39.110506026816537, -8.382461302631826],
              [-39.112128477180903, -8.382199071074652],
              [-39.113129029906702, -8.382033526054492],
              [-39.113554281914226, -8.381960407541669],
              [-39.114932329237682, -8.381177038643459],
              [-39.115335283649991, -8.376333288348174],
              [-39.116681509265639, -8.373083421602335],
              [-39.123350696898441, -8.368870307685814],
              [-39.125668171556697, -8.367696117345673],
              [-39.12197549957034, -8.366650810708816],
              [-39.120065739480651, -8.364238442095054],
              [-39.120208734258149, -8.362862320571448],
              [-39.096891004426809, -8.366696738768326],
              [-39.093181829696043, -8.367714244469086],
              [-39.093062218984471, -8.373112937338558],
              [-39.090461182554968, -8.37371650862932],
              [-39.090024004970509, -8.376025449993415],
              [-39.088064301052782, -8.376241172597307],
              [-39.087451548715705, -8.377003565627241],
              [-39.081358109914135, -8.377498566552939],
              [-39.079512755634582, -8.377298676953895],
              [-39.076822609249902, -8.379980541361535],
              [-39.076061096011635, -8.380525765393005],
              [-39.075146425695223, -8.381002181552759],
              [-39.076093291874187, -8.383543218232042],
              [-39.07621583625069, -8.383605065198489],
              [-39.076870617913848, -8.383934462527938],
              [-39.077525672648669, -8.384261235532929],
              [-39.078979139057935, -8.385015515436365],
              [-39.079312894441919, -8.385200427125556],
              [-39.081562617042316, -8.383830583882961],
              [-39.082396727935958, -8.383832400594562],
              [-39.083366514884283, -8.383707551166561],
              [-39.085010996922605, -8.383563830599524],
              [-39.086011222810846, -8.383492697858717],
              [-39.086561563543682, -8.383447531391241],
              [-39.088642288203637, -8.383213064790525],
              [-39.090872895335906, -8.383032825210361],
              [-39.09210265168587, -8.382966237295097],
              [-39.093063645757859, -8.382939327905254],
              [-39.094149171697431, -8.38291926099879],
              [-39.095291284212628, -8.38289311702624],
              [-39.096745408006726, -8.382884531933918],
              [-39.097726602845462, -8.382641059203408],
              [-39.099478187763339, -8.382229326972144],
              [-39.101439334472687, -8.381884197845295],
              [-39.102989782686187, -8.381709581826657],
              [-39.103877788187305, -8.381848738832911],
              [-39.1048785208135, -8.38200495971213],
              [-39.105969726446787, -8.382175897773246],
              [-39.106149035902462, -8.382205066248932],
              [-39.106328345359287, -8.382234144188669],
              [-39.106597762962338, -8.382274866292935],
              [-39.106903423566656, -8.382319739283854],
              [-39.107281025258871, -8.382373276077633],
              [-39.107447435140898, -8.382396387045869],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0089000',
        uf: 'PE',
        nome_proje: 'PA PAJEU',
        municipio: 'CABROBO',
        area_hecta: '299.1859',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 4.0,
        data_de_cr: '06/07/2004',
        forma_obte: 'Confisco',
        data_obten: '06/07/2004',
        area_calc_: 299.4127,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.118941467218534, -8.352511676628668],
              [-39.086131237328942, -8.355082920077704],
              [-39.083795841627712, -8.353790830248924],
              [-39.083513887463063, -8.353634856254869],
              [-39.083433225349232, -8.353590188864645],
              [-39.083231933501835, -8.353478791602409],
              [-39.082955429910022, -8.353325801048273],
              [-39.073453563847444, -8.348305384999907],
              [-39.067987264243818, -8.354736755112922],
              [-39.067987264259472, -8.354736845567182],
              [-39.067772869231653, -8.354989159257661],
              [-39.06770131334104, -8.355073293962104],
              [-39.067486827105299, -8.355325517057562],
              [-39.067486827120845, -8.355325607511855],
              [-39.066485221895142, -8.356504034151317],
              [-39.067321343013568, -8.357443802990684],
              [-39.067519291005006, -8.357666286730408],
              [-39.067519290989445, -8.357666196276192],
              [-39.067585152601794, -8.357740357520509],
              [-39.067750124632859, -8.357925760526845],
              [-39.067783100892569, -8.35796284112261],
              [-39.068651568760032, -8.358939054552502],
              [-39.068687906427769, -8.358979933564662],
              [-39.070011329226574, -8.360467492928672],
              [-39.071468460437877, -8.360464699714322],
              [-39.07187908751834, -8.360463992156539],
              [-39.072015599926317, -8.360463876881013],
              [-39.072338397666748, -8.360463275275755],
              [-39.072426227000037, -8.36046316875545],
              [-39.073644393386836, -8.360461134829055],
              [-39.120520897777155, -8.354253617195779],
              [-39.12011402817479, -8.352855953039002],
              [-39.118941467218534, -8.352511676628668],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0092000',
        uf: 'PE',
        nome_proje: 'PA TAMBORIL',
        municipio: 'CABROBO',
        area_hecta: '319.2039',
        capacidade: 9.0,
        num_famili: 9.0,
        fase: 4.0,
        data_de_cr: '06/07/2004',
        forma_obte: 'Confisco',
        data_obten: '06/07/2004',
        area_calc_: 319.5429,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.201326213943652, -8.419784921817797],
              [-39.201999260472782, -8.423664940044485],
              [-39.253050622372307, -8.42237988019278],
              [-39.267812592181379, -8.421566871344297],
              [-39.268077340769295, -8.420834382786451],
              [-39.267074666649478, -8.418471074353494],
              [-39.266607362952648, -8.417369672971246],
              [-39.253713409323694, -8.418359894852809],
              [-39.201326213943652, -8.419784921817797],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0248000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRINHA',
        municipio: 'CABROBO',
        area_hecta: '251.6358',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 3.0,
        data_de_cr: '26/12/2007',
        forma_obte: 'Confisco',
        data_obten: '26/12/2007',
        area_calc_: 252.3121,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.203993476254396, -8.391145141983726],
              [-39.204365841843853, -8.394175596864216],
              [-39.249725753857497, -8.376876766723269],
              [-39.255118953905239, -8.374099115042847],
              [-39.255970536202717, -8.369602579206182],
              [-39.242903898058785, -8.375850304381217],
              [-39.239724873481173, -8.375862648723629],
              [-39.203993476254396, -8.391145141983726],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0238000',
        uf: 'PE',
        nome_proje: 'PA ELOITA PEREIRA',
        municipio: 'CABROBO',
        area_hecta: '825.7785',
        capacidade: 30.0,
        num_famili: 28.0,
        fase: 3.0,
        data_de_cr: '19/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 827.9732,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.391921590930266, -8.403210361766615],
              [-39.393082143649686, -8.40479392470041],
              [-39.395332398981843, -8.409996279827633],
              [-39.395818651093947, -8.411166330089605],
              [-39.398496438956045, -8.409321029655601],
              [-39.402111466077535, -8.412522786840798],
              [-39.403079295401156, -8.410906867852898],
              [-39.405156288935238, -8.41162862063185],
              [-39.408055690863975, -8.414489148065714],
              [-39.419145726220577, -8.423799887353672],
              [-39.41418166673602, -8.411771089433675],
              [-39.404733267589506, -8.389456481965668],
              [-39.400307538701469, -8.379002932028619],
              [-39.396761071649742, -8.377964050946604],
              [-39.394887567169292, -8.377441849152527],
              [-39.384154985629543, -8.377256334739169],
              [-39.381483367688872, -8.378764231405778],
              [-39.388333887135161, -8.394794362065531],
              [-39.389084373911174, -8.396555267482411],
              [-39.390502785824793, -8.399880511666948],
              [-39.391921590930266, -8.403210361766615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0093000',
        uf: 'PE',
        nome_proje: 'PA JATOBAZINHO',
        municipio: 'CABROBO',
        area_hecta: '605.9396',
        capacidade: 13.0,
        num_famili: 13.0,
        fase: 4.0,
        data_de_cr: '06/07/2004',
        forma_obte: 'Confisco',
        data_obten: '06/07/2004',
        area_calc_: 606.4542,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.178446915289783, -8.353932347606241],
              [-39.178799414784422, -8.354948709533016],
              [-39.179069247507215, -8.355727124264494],
              [-39.180056303683116, -8.356053848305745],
              [-39.212321715265368, -8.34403175243447],
              [-39.25391321230471, -8.323088643333575],
              [-39.266749924538821, -8.316073613071687],
              [-39.267320832354308, -8.313051266895675],
              [-39.267707134267084, -8.311006666131627],
              [-39.246665145915074, -8.322454333850883],
              [-39.203191877988665, -8.340664960722913],
              [-39.178223421944203, -8.351788509678657],
              [-39.178134070282795, -8.352231954960839],
              [-39.178446915289783, -8.353932347606241],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0255000',
        uf: 'PE',
        nome_proje: 'PA CANDIDO GREGÓRIO',
        municipio: 'CABROBO',
        area_hecta: '197.5757',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 3.0,
        data_de_cr: '21/12/2007',
        forma_obte: 'Confisco',
        data_obten: '21/12/2007',
        area_calc_: 197.7396,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.262882143188897, -8.335213081408265],
              [-39.231586876226636, -8.348874166875076],
              [-39.230029347192875, -8.349805028082297],
              [-39.228471810672907, -8.35073588332272],
              [-39.167337714514368, -8.378491270605416],
              [-39.167717875806289, -8.379210669611028],
              [-39.167972679980252, -8.379690418891645],
              [-39.23041204259804, -8.351758875604663],
              [-39.231957032505939, -8.350807487171425],
              [-39.233500108180877, -8.349857179438722],
              [-39.262565221804678, -8.336907213771514],
              [-39.262665131445843, -8.336373743417729],
              [-39.262882143188897, -8.335213081408265],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0131000',
        uf: 'PE',
        nome_proje: 'PA ANTONIO CONSELHEIRO III',
        municipio: 'TACARATU',
        area_hecta: '1400.8915',
        capacidade: 114.0,
        num_famili: 114.0,
        fase: 4.0,
        data_de_cr: '14/12/2004',
        forma_obte: 'Doação',
        data_obten: '14/12/2004',
        area_calc_: 1401.7152,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.11102170919353, -8.903920983505131],
              [-38.104090147514007, -8.904859423205622],
              [-38.078540117772953, -8.943150930781176],
              [-38.113504666547691, -8.965441760579626],
              [-38.122538445436582, -8.96081182266512],
              [-38.121013137448656, -8.959558574781969],
              [-38.104472462823438, -8.9459674248775],
              [-38.116413710804004, -8.938381120436674],
              [-38.116413753158412, -8.938381155537611],
              [-38.125299378447025, -8.932735664656734],
              [-38.108880926519106, -8.934599932697804],
              [-38.109541632267351, -8.912721900800976],
              [-38.109680450396077, -8.90812492425656],
              [-38.109810796263076, -8.907703779910861],
              [-38.110410940735022, -8.905829191971621],
              [-38.11102170919353, -8.903920983505131],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0260000',
        uf: 'PE',
        nome_proje: 'PA POÇO DA UMBURANA',
        municipio: 'CABROBO',
        area_hecta: '854.8287',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '17/07/2008',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/2006',
        area_calc_: 846.4046,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.54176023642821, -8.34812998594826],
              [-39.530750849567156, -8.330083970282386],
              [-39.528986790172638, -8.331804345084832],
              [-39.527719795926188, -8.332470663188056],
              [-39.526190098027485, -8.332890214380656],
              [-39.525543463673159, -8.332875332574547],
              [-39.523083935231035, -8.331484101349654],
              [-39.508311732551618, -8.319515724584145],
              [-39.508217142468261, -8.319615613018151],
              [-39.508045714925572, -8.319779096267769],
              [-39.507867350024277, -8.319985643014775],
              [-39.50769326598617, -8.320131582016183],
              [-39.507551014622074, -8.320314746143294],
              [-39.507421237719278, -8.320453030578204],
              [-39.50725043785058, -8.320610814300892],
              [-39.507053204237174, -8.320763385513645],
              [-39.50684903080456, -8.32095721117007],
              [-39.506625723760692, -8.321173131178242],
              [-39.506390897831956, -8.32140055303352],
              [-39.506143728800026, -8.321634141153808],
              [-39.505920160264139, -8.321859196694692],
              [-39.505688222870475, -8.32207358954331],
              [-39.505462717154622, -8.322275582292091],
              [-39.505251758429921, -8.322490762288963],
              [-39.505038357913627, -8.322714085885233],
              [-39.504816049692231, -8.32293145092209],
              [-39.5046016159258, -8.323128363903184],
              [-39.504400156278152, -8.323315762948058],
              [-39.504208420660376, -8.323508667078892],
              [-39.504020069978004, -8.323721104268291],
              [-39.503809843841346, -8.323942252418265],
              [-39.503588623102317, -8.324158891847512],
              [-39.503360413304307, -8.324378615356361],
              [-39.503139483221666, -8.32460990726903],
              [-39.502926278614758, -8.324846254554281],
              [-39.499418067715034, -8.32790982758423],
              [-39.525424557592437, -8.35752466230182],
              [-39.525452183540679, -8.357468727131804],
              [-39.525594664509022, -8.357318661250535],
              [-39.525766629505, -8.357211701118105],
              [-39.525956587959243, -8.357112767115128],
              [-39.526146916511038, -8.35701898822057],
              [-39.526330272891293, -8.35694104737094],
              [-39.526504532074249, -8.35685199311272],
              [-39.526673334575669, -8.356757519004317],
              [-39.526826018300063, -8.356630865856779],
              [-39.526961885059592, -8.356493200065421],
              [-39.52708892281666, -8.356341254788905],
              [-39.527186609840953, -8.35617804223504],
              [-39.52727793488809, -8.356011581911696],
              [-39.527347812320173, -8.355835019670126],
              [-39.527441294228105, -8.355651642175633],
              [-39.527512615686319, -8.355468384640245],
              [-39.52754914328532, -8.355278389660239],
              [-39.527590299109704, -8.355085674991258],
              [-39.527631176236966, -8.354888347708123],
              [-39.527716038276871, -8.354711313191965],
              [-39.527800975870754, -8.35452297223207],
              [-39.527876913558494, -8.354328311711608],
              [-39.528006936456173, -8.35416822165258],
              [-39.528140604021957, -8.354016990855945],
              [-39.528265729904732, -8.353862515192006],
              [-39.528386850672554, -8.35370144194227],
              [-39.528569639658606, -8.353608305217078],
              [-39.52876520647547, -8.353561914318862],
              [-39.528921998392022, -8.353452440590353],
              [-39.529072433347345, -8.353343427550517],
              [-39.529242879334014, -8.353257000367936],
              [-39.529423701524863, -8.353187383057723],
              [-39.529603076973103, -8.353122561485391],
              [-39.529774907498435, -8.353052775145203],
              [-39.529935103344819, -8.3529759491558],
              [-39.530100830664296, -8.352892784159227],
              [-39.530272193819556, -8.3528133200257],
              [-39.530437083206145, -8.352715050768879],
              [-39.530606716822994, -8.352632784846838],
              [-39.530806512566002, -8.352624828817671],
              [-39.530984619935367, -8.352630107552455],
              [-39.531186577625427, -8.352609033098696],
              [-39.531396813086012, -8.352597625648924],
              [-39.531581218781184, -8.35255920803494],
              [-39.531756016243328, -8.352467618298306],
              [-39.531928614935033, -8.352362011602988],
              [-39.532083009297544, -8.352226851956567],
              [-39.532249585184992, -8.352100268690263],
              [-39.53241132185461, -8.352021811568083],
              [-39.5325499818494, -8.351870391803178],
              [-39.532711461228409, -8.351803241233839],
              [-39.532902216450289, -8.351826320877969],
              [-39.533077970307119, -8.351770728630353],
              [-39.533241998783701, -8.351708187400233],
              [-39.533286013803881, -8.35168379694122],
              [-39.533377305513206, -8.351629222781428],
              [-39.533797008348564, -8.351376300344327],
              [-39.534033973476731, -8.351319539791065],
              [-39.534205321288276, -8.351229943599604],
              [-39.534414425522797, -8.351122025391172],
              [-39.534586013002155, -8.351008459327279],
              [-39.534756440025674, -8.350909819100572],
              [-39.534954742815067, -8.3508067090849],
              [-39.535295084252887, -8.350700150845617],
              [-39.535513727509759, -8.350661413857267],
              [-39.535777263142478, -8.350642515152657],
              [-39.536190244500517, -8.350657242516601],
              [-39.536478497426501, -8.350648711700476],
              [-39.5366711978564, -8.350633435898473],
              [-39.536847576543778, -8.350637176731022],
              [-39.537045903727879, -8.350618908255626],
              [-39.537235831877538, -8.35056881249386],
              [-39.537635607233419, -8.350423820673655],
              [-39.537817804275022, -8.350299203922265],
              [-39.538038232949468, -8.350171912106964],
              [-39.538230720305187, -8.35006763256526],
              [-39.538391080613088, -8.349980491741144],
              [-39.538705908181534, -8.349880115974273],
              [-39.538846109192143, -8.349795173199498],
              [-39.539275037137287, -8.349518353798969],
              [-39.53942065481283, -8.349409886336737],
              [-39.539582630450759, -8.349308994388199],
              [-39.539748332357739, -8.349210087212361],
              [-39.539945938102242, -8.349130131095102],
              [-39.540154539445766, -8.349054863318367],
              [-39.54041369496921, -8.348955918913921],
              [-39.540663350593547, -8.348883398847159],
              [-39.540837557032305, -8.348827172256158],
              [-39.540907169365049, -8.348793248756451],
              [-39.541042606372848, -8.348678915356807],
              [-39.541169187566389, -8.348531036889359],
              [-39.541626597522551, -8.3482307495453],
              [-39.54176023642821, -8.34812998594826],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0050000',
        uf: 'PE',
        nome_proje: 'PA GANGORRINHA',
        municipio: 'CABROBO',
        area_hecta: '1192.1750',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 4.0,
        data_de_cr: '15/12/2000',
        forma_obte: 'Confisco',
        data_obten: '15/12/2000',
        area_calc_: 1215.48,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.18941258166754, -8.251676421185374],
              [-39.18937258244511, -8.251769065306259],
              [-39.189156712951672, -8.252268294160229],
              [-39.189110340742197, -8.252325664080978],
              [-39.186118528455701, -8.256015701488659],
              [-39.183859930049195, -8.258798667069556],
              [-39.183420333358264, -8.259345484073858],
              [-39.183110328009079, -8.259731051780651],
              [-39.183054243761191, -8.259800636790187],
              [-39.182362809780905, -8.260660901450255],
              [-39.181606122086308, -8.261602060704137],
              [-39.180862862195269, -8.262526568899327],
              [-39.180176561366892, -8.26351373493987],
              [-39.179514758292669, -8.26446552120407],
              [-39.178831808378781, -8.265447798947646],
              [-39.178147584619239, -8.266431794746838],
              [-39.177463811236109, -8.267415156018039],
              [-39.176773319267078, -8.268408288176111],
              [-39.176096617407069, -8.26938142269219],
              [-39.173741367596314, -8.272833827183824],
              [-39.171682861537647, -8.275929972116634],
              [-39.171311067466952, -8.276489139186864],
              [-39.171107519747373, -8.276795775985338],
              [-39.168998971974091, -8.279971980775146],
              [-39.167606141710472, -8.282070745255677],
              [-39.164972367345875, -8.284975062759093],
              [-39.166791884931001, -8.285571485768552],
              [-39.16885734917998, -8.286238260253421],
              [-39.171533600665498, -8.287107017528074],
              [-39.178340326200569, -8.293996552976632],
              [-39.179008345026112, -8.294628347424769],
              [-39.179739972636732, -8.295345500751338],
              [-39.180813479406481, -8.296397630393498],
              [-39.18090648629034, -8.29623386617974],
              [-39.181704254986201, -8.294828298088897],
              [-39.183240300514882, -8.29222577181501],
              [-39.184453931979391, -8.290167380260209],
              [-39.185373843297782, -8.288627785827913],
              [-39.187496640553171, -8.285047160869508],
              [-39.187668318362682, -8.284767395894356],
              [-39.187955687591405, -8.284299069896626],
              [-39.189450777323209, -8.281862975075567],
              [-39.190752940249787, -8.27954618555265],
              [-39.193588803693281, -8.274779876060892],
              [-39.196489834594971, -8.269903884350033],
              [-39.196792775772842, -8.269383895125896],
              [-39.196839137794115, -8.269304363071051],
              [-39.19843697342737, -8.266634541539783],
              [-39.198621152775821, -8.266326815405787],
              [-39.198666154007718, -8.266251263804099],
              [-39.198673140112923, -8.266239591743037],
              [-39.198946866985132, -8.265780038835141],
              [-39.19906172871223, -8.265587314255109],
              [-39.199155908868931, -8.265438289317164],
              [-39.200421346227593, -8.263436629638216],
              [-39.201723045835443, -8.257945314649451],
              [-39.203198206818342, -8.251692824870675],
              [-39.204672061006093, -8.245456882611988],
              [-39.205852054080118, -8.240462389038338],
              [-39.207162412647072, -8.234960285177928],
              [-39.207658989721445, -8.2328816602766],
              [-39.208594705797999, -8.228994534772308],
              [-39.204638090723968, -8.230941156426848],
              [-39.200564688469875, -8.232921447201688],
              [-39.196708089934148, -8.234760030212843],
              [-39.194916450194526, -8.23897381535873],
              [-39.193163752127241, -8.243053701325083],
              [-39.191015090401301, -8.247996431336402],
              [-39.190986349154564, -8.248084909356164],
              [-39.190954052788577, -8.248144443683817],
              [-39.190901894562714, -8.24825283280081],
              [-39.18941258166754, -8.251676421185374],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0060000',
        uf: 'PE',
        nome_proje: 'PA ANTONIO DE BARROS',
        municipio: 'CABROBO',
        area_hecta: '259.3167',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 4.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Confisco',
        data_obten: '12/06/2002',
        area_calc_: 264.2918,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.287050987095753, -8.269127404393034],
              [-39.285923094526268, -8.27352525305321],
              [-39.285902267668732, -8.273606314380707],
              [-39.285900819799998, -8.273613280343922],
              [-39.28496295409181, -8.278000678421211],
              [-39.284391528871474, -8.280561012681057],
              [-39.28403645347472, -8.282181198601851],
              [-39.283827280985776, -8.283135813510983],
              [-39.283544249102476, -8.284426968061876],
              [-39.283521168471488, -8.284532362868495],
              [-39.283424786844158, -8.284992206823503],
              [-39.283393926740793, -8.285139668056587],
              [-39.28279219117627, -8.288008558422737],
              [-39.282522226633411, -8.289295722941789],
              [-39.282521049858026, -8.289300970081541],
              [-39.28249851330969, -8.289406002622165],
              [-39.281592785750263, -8.293623497269587],
              [-39.280616379956143, -8.298083907109335],
              [-39.280137144753084, -8.300485152345269],
              [-39.279420228771592, -8.303886436899447],
              [-39.278750945342004, -8.306854776149654],
              [-39.278425036308931, -8.308300179875282],
              [-39.279195144856992, -8.30831284674837],
              [-39.27973363969874, -8.307372928258044],
              [-39.28223406585807, -8.303008410892348],
              [-39.283164623730336, -8.298575800193486],
              [-39.284082362190411, -8.294148894729567],
              [-39.284346802993014, -8.292886789091474],
              [-39.284575585882976, -8.291795304060059],
              [-39.285013328742657, -8.289706509846381],
              [-39.285946100877865, -8.285276151525748],
              [-39.286709793490608, -8.281630415922017],
              [-39.286829970809904, -8.281057125390992],
              [-39.287371219897118, -8.27847880965388],
              [-39.288438278932389, -8.274095112723685],
              [-39.288466070547962, -8.273977231647304],
              [-39.288486891301439, -8.273888481654351],
              [-39.289155872875689, -8.271045584783591],
              [-39.289269315889314, -8.270585184397085],
              [-39.289628986242015, -8.26908149485724],
              [-39.289698512018163, -8.268790907546057],
              [-39.289830592161174, -8.268238230820799],
              [-39.289894323697922, -8.267971617875959],
              [-39.290258244459849, -8.266450195747794],
              [-39.290280061463505, -8.266358912231222],
              [-39.290369774008205, -8.265984097842274],
              [-39.290833108706266, -8.26382571914073],
              [-39.291768678803351, -8.259407102677759],
              [-39.292333153480399, -8.256756037881772],
              [-39.293275348756332, -8.252374589094243],
              [-39.293465556006204, -8.251485833234158],
              [-39.293492702492173, -8.251358906887969],
              [-39.293823436246335, -8.249812992606419],
              [-39.294604103226035, -8.245844941409347],
              [-39.294704691254978, -8.245330819331192],
              [-39.29472911483041, -8.245206337140212],
              [-39.295559861086701, -8.24096019240417],
              [-39.297858050825269, -8.229297656091374],
              [-39.298069166444719, -8.228226075309108],
              [-39.298736788206369, -8.224837726026918],
              [-39.296192260488112, -8.223961574202153],
              [-39.295479263103999, -8.227529775503969],
              [-39.29526532552444, -8.228600543181789],
              [-39.292897919389283, -8.24044737226262],
              [-39.291912643397069, -8.245487898865363],
              [-39.291151641029067, -8.249314733319842],
              [-39.290526500269323, -8.252694897877076],
              [-39.290293146564089, -8.253944593904857],
              [-39.290267911799624, -8.254079840410549],
              [-39.289843891658549, -8.256350443762257],
              [-39.289266070792635, -8.25915094471187],
              [-39.288380137704074, -8.263437820050864],
              [-39.28827087380327, -8.263900207284822],
              [-39.288227873987459, -8.264081778695255],
              [-39.287907231643729, -8.265437999882815],
              [-39.287883422944859, -8.265538239633011],
              [-39.287122218667861, -8.268807237909275],
              [-39.287050987095753, -8.269127404393034],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0148000',
        uf: 'PE',
        nome_proje: 'PA SANTA LUZIA',
        municipio: 'SALGUEIRO',
        area_hecta: '899.9424',
        capacidade: 25.0,
        num_famili: 23.0,
        fase: 3.0,
        data_de_cr: '25/08/2005',
        forma_obte: 'Desapropriação',
        data_obten: '28/03/2005',
        area_calc_: 900.6631,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.019899819350087, -8.179753917318591],
              [-39.018117345831605, -8.179884799235952],
              [-39.017041809665869, -8.179841337107234],
              [-39.015905069526021, -8.179422666723182],
              [-39.015438882955287, -8.179313234110902],
              [-39.015008648177208, -8.179219629271635],
              [-39.014523397805306, -8.179127111415433],
              [-39.01383387837916, -8.17895870817607],
              [-39.010462369957871, -8.17820278561601],
              [-39.004921117346413, -8.177485581919852],
              [-38.996719440286697, -8.176692216643653],
              [-38.987915346858905, -8.179016474621609],
              [-38.990829833839015, -8.210286227851398],
              [-38.991925986760378, -8.222877399297175],
              [-38.997809107856376, -8.213658397137161],
              [-39.019899819350087, -8.179753917318591],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0074000',
        uf: 'PE',
        nome_proje: 'PA JOSÉ LUIZ DE BARROS',
        municipio: 'SALGUEIRO',
        area_hecta: '2815.1278',
        capacidade: 50.0,
        num_famili: 46.0,
        fase: 4.0,
        data_de_cr: '11/03/2003',
        forma_obte: 'Desapropriação',
        data_obten: '19/08/2002',
        area_calc_: 2620.4984,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.98791108036697, -8.179002815771478],
              [-38.981561684005399, -8.156628943503961],
              [-38.961579815217824, -8.1574323218057],
              [-38.961428011952634, -8.156679629694182],
              [-38.961320488700849, -8.156147109687916],
              [-38.959776177986512, -8.148492005802153],
              [-38.959651964203104, -8.147876355142168],
              [-38.955385462229927, -8.126725474386877],
              [-38.9552747795138, -8.126176488856508],
              [-38.955079361655137, -8.125207962111505],
              [-38.954733163204601, -8.125120001156139],
              [-38.954419548854915, -8.125063522013821],
              [-38.954130441459093, -8.125023236898208],
              [-38.953840335485012, -8.124984127383316],
              [-38.953584179143853, -8.124940770135945],
              [-38.953205482405068, -8.124871257061024],
              [-38.952923092501386, -8.124830700495037],
              [-38.952695891809725, -8.124796843848349],
              [-38.953036234128447, -8.126841445018945],
              [-38.953127851412816, -8.127391514392047],
              [-38.954121143315582, -8.133358240637858],
              [-38.952296720013578, -8.133614742715483],
              [-38.955131369357026, -8.150552088681186],
              [-38.95542928964835, -8.152332280162202],
              [-38.957067897691083, -8.162122693663941],
              [-38.957160889336485, -8.162678640973752],
              [-38.959810124689945, -8.178506028321138],
              [-38.959963913658754, -8.179424976914015],
              [-38.959475843991761, -8.179505704676032],
              [-38.956947338599669, -8.179923978970585],
              [-38.95640997039196, -8.180012838726244],
              [-38.954025265619499, -8.180407320494288],
              [-38.955497373577892, -8.190029009539716],
              [-38.954811560668404, -8.190055798502138],
              [-38.954830430949954, -8.190176015364109],
              [-38.954914258872975, -8.190708352645963],
              [-38.955223534776806, -8.192674336798484],
              [-38.955233877318747, -8.192739827387822],
              [-38.95531189990637, -8.193235710504846],
              [-38.955868587593493, -8.196773015355133],
              [-38.955874303364098, -8.196808836165495],
              [-38.956756440952688, -8.202414970849604],
              [-38.95687475072868, -8.203166302646075],
              [-38.956961305756131, -8.203716911386202],
              [-38.958986979607253, -8.216588139247746],
              [-38.959083067573381, -8.217198629325249],
              [-38.960369077175102, -8.225369730760203],
              [-38.960414900103544, -8.225660909561766],
              [-38.960471345947667, -8.225954712590623],
              [-38.962564801217674, -8.236845135947638],
              [-38.962565242311705, -8.236845493405465],
              [-38.962565255175157, -8.236845497809336],
              [-38.963008824267348, -8.237204966864875],
              [-38.968073448408127, -8.241309196099541],
              [-38.968470279798083, -8.241896407989129],
              [-38.970878298909177, -8.245459566963998],
              [-38.970878257677271, -8.245459571357742],
              [-38.970878426306598, -8.245459820880169],
              [-38.970878453533231, -8.245459858186388],
              [-38.970878480950468, -8.245459895353811],
              [-38.970878508557576, -8.245459932381483],
              [-38.970878536353858, -8.245459969268422],
              [-38.970878564338591, -8.245460006013694],
              [-38.970878592511042, -8.245460042616337],
              [-38.970878620870472, -8.245460079075402],
              [-38.970878649416164, -8.245460115389916],
              [-38.970878678147358, -8.245460151558975],
              [-38.970878707063321, -8.245460187581616],
              [-38.970878736163286, -8.245460223456883],
              [-38.970878765446493, -8.245460259183877],
              [-38.970878794912203, -8.245460294761646],
              [-38.970878824559634, -8.245460330189301],
              [-38.970878854388019, -8.245460365465894],
              [-38.970878884396569, -8.245460400590492],
              [-38.970878914584524, -8.245460435562201],
              [-38.970878944951089, -8.245460470380134],
              [-38.970878975495481, -8.245460505043342],
              [-38.970879006216904, -8.245460539550953],
              [-38.970879037114557, -8.245460573902049],
              [-38.970879068187642, -8.245460608095749],
              [-38.970879099435336, -8.245460642131148],
              [-38.970879130856837, -8.245460676007395],
              [-38.970879162451325, -8.245460709723615],
              [-38.970879194217993, -8.245460743278873],
              [-38.970879226155986, -8.245460776672331],
              [-38.97087925826451, -8.245460809903127],
              [-38.97087929054269, -8.245460842970378],
              [-38.97087932298971, -8.245460875873226],
              [-38.970879355604744, -8.245460908610843],
              [-38.970879388386912, -8.245460941182358],
              [-38.970879421335361, -8.245460973586937],
              [-38.97087945444926, -8.245461005823715],
              [-38.970879487727736, -8.245461037891872],
              [-38.970879521169913, -8.245461069790544],
              [-38.970879554774939, -8.245461101518959],
              [-38.970879588541926, -8.245461133076253],
              [-38.970879622470001, -8.245461164461586],
              [-38.970879656558289, -8.245461195674189],
              [-38.970879690805901, -8.245461226713207],
              [-38.97087972521193, -8.245461257577873],
              [-38.970879759775507, -8.245461288267364],
              [-38.970879794495701, -8.245461318780867],
              [-38.970879829371647, -8.245461349117639],
              [-38.97087986440242, -8.24546137927684],
              [-38.970879899587111, -8.245461409257695],
              [-38.970879934924795, -8.245461439059428],
              [-38.970879970414572, -8.245461468681283],
              [-38.970880006055495, -8.245461498122483],
              [-38.970880041846669, -8.245461527382249],
              [-38.970880077787122, -8.245461556459828],
              [-38.970880113875957, -8.245461585354461],
              [-38.97088015011223, -8.245461614065391],
              [-38.970880186494981, -8.245461642591856],
              [-38.970880223023265, -8.245461670933169],
              [-38.970880259696159, -8.245461699088567],
              [-38.970880296512682, -8.245461727057295],
              [-38.970880333471882, -8.245461754838646],
              [-38.970880370572807, -8.245461782431894],
              [-38.970880407814484, -8.245461809836334],
              [-38.970880445195959, -8.245461837051216],
              [-38.97088048271624, -8.245461864075878],
              [-38.970880520374358, -8.245461890909587],
              [-38.970880558169341, -8.245461917551673],
              [-38.970880596100201, -8.245461944001409],
              [-38.970880634165958, -8.245461970258123],
              [-38.970880672365624, -8.245461996321138],
              [-38.970880710698161, -8.245462022189786],
              [-38.97088074916266, -8.24546204786337],
              [-38.970880787758034, -8.245462073341232],
              [-38.970880826483345, -8.245462098622708],
              [-38.970880865337527, -8.245462123707128],
              [-38.970880904319628, -8.245462148593873],
              [-38.970880943428568, -8.245462173282272],
              [-38.970880982663402, -8.245462197771706],
              [-38.970881022023072, -8.245462222061505],
              [-38.970881061506553, -8.245462246151058],
              [-38.970881101112838, -8.245462270039729],
              [-38.970881140840866, -8.245462293726906],
              [-38.970881180689631, -8.245462317211976],
              [-38.970881220658093, -8.245462340494301],
              [-38.970881260745195, -8.245462363573324],
              [-38.97088130094992, -8.245462386448386],
              [-38.970881341271202, -8.245462409118952],
              [-38.970881381708011, -8.245462431584382],
              [-38.970881422259268, -8.245462453844132],
              [-38.970881462923948, -8.245462475897579],
              [-38.970881503700966, -8.245462497744191],
              [-38.97088154458929, -8.245462519383384],
              [-38.970881585587833, -8.245462540814611],
              [-38.970881626695565, -8.245462562037293],
              [-38.970881667911371, -8.24546258305088],
              [-38.970881709234206, -8.245462603854829],
              [-38.97088175066299, -8.245462624448599],
              [-38.970881792196643, -8.245462644831674],
              [-38.970881833834099, -8.245462665003492],
              [-38.97088187557425, -8.245462684963528],
              [-38.970881917416037, -8.245462704711301],
              [-38.970881917929312, -8.245462705810938],
              [-38.975969593464846, -8.247848046675587],
              [-38.975969700830063, -8.24784809787146],
              [-38.975972775961566, -8.24784953960962],
              [-38.97597412987335, -8.247852785036441],
              [-38.975974137777143, -8.247852796064043],
              [-38.976512682985174, -8.249143732138297],
              [-38.978569231111145, -8.25407332855093],
              [-38.978569247186378, -8.254073362989814],
              [-38.978569263406591, -8.254073397361143],
              [-38.978569279771556, -8.254073431664343],
              [-38.978569296280938, -8.254073465898774],
              [-38.978569312934482, -8.254073500063848],
              [-38.97856932973189, -8.254073534158941],
              [-38.978569346672842, -8.254073568183482],
              [-38.978569363757067, -8.254073602136881],
              [-38.978569380984254, -8.254073636018527],
              [-38.978569398354082, -8.254073669827818],
              [-38.978569415866275, -8.254073703564149],
              [-38.978569433520491, -8.254073737226941],
              [-38.978569451316446, -8.254073770815589],
              [-38.978569469253813, -8.254073804329506],
              [-38.978569487332287, -8.254073837768106],
              [-38.97856950555154, -8.254073871130784],
              [-38.97856952391124, -8.25407390441697],
              [-38.978569542411094, -8.254073937626078],
              [-38.978569561050747, -8.254073970757508],
              [-38.97856957982988, -8.254074003810679],
              [-38.978569598748166, -8.25407403678501],
              [-38.978569617805277, -8.254074069679925],
              [-38.978569637000859, -8.254074102494839],
              [-38.978569656334585, -8.254074135229164],
              [-38.978569675806106, -8.254074167882345],
              [-38.978569695415104, -8.2540742004538],
              [-38.978569715161186, -8.254074232942941],
              [-38.978569735044054, -8.25407426534921],
              [-38.978569755063333, -8.254074297672004],
              [-38.978569775218673, -8.254074329910797],
              [-38.978569795509706, -8.254074362065017],
              [-38.978569815936105, -8.254074394134063],
              [-38.978569836497478, -8.254074426117372],
              [-38.978569857193477, -8.254074458014426],
              [-38.978569878023741, -8.254074489824594],
              [-38.978569898987899, -8.254074521547397],
              [-38.978569920085583, -8.254074553182244],
              [-38.978569941316401, -8.254074584728539],
              [-38.978569962680012, -8.254074616185783],
              [-38.978569984176019, -8.254074647553367],
              [-38.978570005804059, -8.254074678830765],
              [-38.978570027563727, -8.254074710017425],
              [-38.978570049454653, -8.254074741112808],
              [-38.978570071476447, -8.254074772116343],
              [-38.978570093628733, -8.254074803027484],
              [-38.978570115911097, -8.254074833845696],
              [-38.978570138323171, -8.254074864570455],
              [-38.978570160864543, -8.254074895201196],
              [-38.978570183534821, -8.254074925737402],
              [-38.978570206333615, -8.254074956178478],
              [-38.978570229260505, -8.254074986523921],
              [-38.978570252315102, -8.254075016773221],
              [-38.978570275496999, -8.254075046925815],
              [-38.978570298805764, -8.254075076981186],
              [-38.978570322241012, -8.254075106938792],
              [-38.978570345802325, -8.254075136798111],
              [-38.978570369489283, -8.254075166558621],
              [-38.978570393301474, -8.254075196219814],
              [-38.978570417238473, -8.254075225781122],
              [-38.978570441299851, -8.25407525524205],
              [-38.978570465485198, -8.254075284602084],
              [-38.97857048979408, -8.254075313860694],
              [-38.978570514226064, -8.25407534301738],
              [-38.978570538780737, -8.254075372071611],
              [-38.978570563457637, -8.254075401022877],
              [-38.978570588256353, -8.254075429870664],
              [-38.978570613176437, -8.254075458614468],
              [-38.978570638217462, -8.2540754872538],
              [-38.978570663378974, -8.254075515788143],
              [-38.97857068866054, -8.254075544216967],
              [-38.978570714061703, -8.254075572539826],
              [-38.978570739582011, -8.254075600756188],
              [-38.978570765221022, -8.254075628865554],
              [-38.97857079097831, -8.254075656867428],
              [-38.978570816853384, -8.254075684761316],
              [-38.978570842845798, -8.254075712546737],
              [-38.978570868955089, -8.254075740223209],
              [-38.978570895180795, -8.254075767790201],
              [-38.978570921522483, -8.254075795247266],
              [-38.978570947979655, -8.254075822593927],
              [-38.978570974551872, -8.254075849829668],
              [-38.978571001238635, -8.254075876954026],
              [-38.978571028039497, -8.2540759039665],
              [-38.978571054953974, -8.254075930866652],
              [-38.978571081981585, -8.254075957653978],
              [-38.97857110912188, -8.254075984328036],
              [-38.978571136374349, -8.254076010888324],
              [-38.978571163738543, -8.254076037334402],
              [-38.978571191213952, -8.254076063665815],
              [-38.978571218800113, -8.254076089882057],
              [-38.978571246496521, -8.254076115982672],
              [-38.978571274302695, -8.254076141967245],
              [-38.97857130221815, -8.254076167835247],
              [-38.978571330242382, -8.254076193586277],
              [-38.978571358374921, -8.254076219219845],
              [-38.978571386615243, -8.254076244735533],
              [-38.978571414962865, -8.254076270132879],
              [-38.97857144341728, -8.254076295411416],
              [-38.978571471977986, -8.254076320570729],
              [-38.980064793439496, -8.246277388034699],
              [-38.98024408004072, -8.245340646864417],
              [-38.983984564054047, -8.225805186917119],
              [-38.982615614119425, -8.217965492420447],
              [-38.982504328567508, -8.217328503902989],
              [-38.980593649087503, -8.206385174504746],
              [-38.980315451921285, -8.20479197719963],
              [-38.980308735291857, -8.204753262132503],
              [-38.979927432655998, -8.202553106294673],
              [-38.979027879867701, -8.197363203804599],
              [-38.978933033811423, -8.196815945921793],
              [-38.978356429816316, -8.193488345846717],
              [-38.975116986710752, -8.174795434430804],
              [-38.98791108036697, -8.179002815771478],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0142000',
        uf: 'PE',
        nome_proje: 'PA DA CIDADANIA',
        municipio: 'JATOBA',
        area_hecta: '340.1657',
        capacidade: 44.0,
        num_famili: 41.0,
        fase: 3.0,
        data_de_cr: '06/07/2005',
        forma_obte: 'Doação',
        data_obten: '06/07/2005',
        area_calc_: 332.1162,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.283417983276131, -9.151862316950384],
              [-38.278588172069547, -9.149370872518798],
              [-38.278394720295331, -9.149622825406535],
              [-38.277290961857595, -9.149158188659337],
              [-38.276965923841978, -9.149582083117942],
              [-38.275155132944164, -9.147842945210227],
              [-38.274171884256837, -9.146165058699896],
              [-38.272872472458786, -9.151302363968968],
              [-38.274155679092296, -9.152947209954895],
              [-38.272376663931233, -9.155454445779462],
              [-38.274671832702353, -9.157515553011285],
              [-38.273916696019384, -9.158187754280343],
              [-38.271729704311802, -9.160124191080524],
              [-38.270312057027468, -9.161380220182931],
              [-38.270862197077086, -9.161980606684661],
              [-38.270634971179504, -9.162185546164507],
              [-38.269915881770054, -9.162801916163147],
              [-38.269720817851017, -9.162633297993585],
              [-38.26893445266785, -9.161831829483839],
              [-38.267475549011351, -9.15567140315561],
              [-38.265870485869854, -9.155254104916771],
              [-38.264999347316888, -9.154661562803851],
              [-38.264800373599769, -9.154715877431636],
              [-38.264753227390031, -9.15462814214308],
              [-38.264575886465074, -9.15469615748655],
              [-38.264914606083344, -9.157766854688534],
              [-38.26563085524603, -9.15804497364322],
              [-38.265723263363554, -9.159094665947563],
              [-38.264599740842705, -9.158853977941172],
              [-38.264239796650926, -9.160130216612959],
              [-38.262065542552527, -9.158510566108871],
              [-38.259828370157926, -9.156826379775126],
              [-38.259303252294472, -9.157517016670914],
              [-38.258551299833094, -9.160284579139434],
              [-38.257588566186584, -9.161244560916458],
              [-38.257375014005895, -9.161567457505035],
              [-38.257688903325068, -9.161970754244422],
              [-38.257540846281415, -9.162222696796237],
              [-38.25799105564959, -9.162635678914684],
              [-38.259093887685118, -9.163508543783013],
              [-38.260264032397835, -9.166865295467968],
              [-38.26029615179921, -9.166957613214771],
              [-38.262192073174532, -9.16965714471664],
              [-38.265303852128184, -9.173140569871872],
              [-38.266667137203036, -9.171928953999824],
              [-38.266878945675572, -9.171745602476742],
              [-38.26707891289518, -9.171566568100443],
              [-38.267696941913265, -9.17102154188562],
              [-38.268220231744074, -9.170560073398551],
              [-38.268732668591653, -9.170108711762639],
              [-38.2689418367776, -9.169924720410245],
              [-38.269143983754802, -9.169746955329938],
              [-38.269480442018661, -9.16944962381891],
              [-38.269820459325871, -9.169147415185785],
              [-38.27020969864747, -9.168809037651487],
              [-38.270828807442811, -9.168265454550378],
              [-38.271110275402066, -9.168019563239303],
              [-38.271663097685355, -9.167532553010894],
              [-38.272173703406558, -9.167082267403067],
              [-38.272867776647793, -9.166462225423382],
              [-38.273476277180372, -9.165894738715147],
              [-38.273749273596195, -9.16565054646286],
              [-38.274294699764887, -9.1651717471225],
              [-38.274566139934116, -9.164931440111889],
              [-38.275377992808856, -9.164214492250881],
              [-38.275796875179267, -9.163839718391305],
              [-38.276199428460856, -9.163483000040344],
              [-38.276811597055257, -9.16294183326244],
              [-38.277361274641258, -9.162471991015519],
              [-38.277894606056073, -9.161983393391875],
              [-38.278438111894189, -9.161503588163463],
              [-38.278977795889631, -9.161022689064458],
              [-38.279519024223248, -9.160542515660721],
              [-38.280063152813931, -9.160067683266726],
              [-38.28059770529601, -9.159556653450398],
              [-38.280847073482775, -9.159290068515507],
              [-38.281289374994913, -9.15871418709408],
              [-38.281638789717881, -9.158073272698791],
              [-38.281898063797577, -9.157404955635521],
              [-38.282084126960932, -9.156708817456355],
              [-38.282298270259844, -9.155942912178295],
              [-38.282487788342252, -9.155247684901378],
              [-38.282705355324715, -9.154452753534805],
              [-38.282943816268165, -9.153631272812403],
              [-38.283090113045418, -9.153066198423813],
              [-38.283229047574615, -9.152541447275834],
              [-38.283417983276131, -9.151862316950384],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0242000',
        uf: 'PE',
        nome_proje: 'PA VIDA NOVA',
        municipio: 'SAO JOSE DO BELMONTE',
        area_hecta: '3480.0000',
        capacidade: 170.0,
        num_famili: 159.0,
        fase: 4.0,
        data_de_cr: '04/12/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 2970.6781,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.846322228254131, -7.930150549648577],
              [-38.846481608103886, -7.929991586940313],
              [-38.847756279280858, -7.928720696921967],
              [-38.848750669828732, -7.92772930039738],
              [-38.849315571590651, -7.927166055106057],
              [-38.849875480067318, -7.926607420459219],
              [-38.85073662344157, -7.925748217474769],
              [-38.851512085088913, -7.924974462964757],
              [-38.851968702105644, -7.924518908314176],
              [-38.852871858782947, -7.923617744027159],
              [-38.853159476146516, -7.923334989652438],
              [-38.85319423707228, -7.923300809541887],
              [-38.853359510181257, -7.92313840880287],
              [-38.854259661085294, -7.922253522358345],
              [-38.854914850147786, -7.92160952342247],
              [-38.855135757917317, -7.921389882663155],
              [-38.859255181709052, -7.917295083898408],
              [-38.860875466908844, -7.91568026378026],
              [-38.859875098620243, -7.914664198435792],
              [-38.857329759378423, -7.912078736321231],
              [-38.857494186516966, -7.910662974752493],
              [-38.857983184525644, -7.906451146280075],
              [-38.858605727698077, -7.900873746072134],
              [-38.858717570955257, -7.899860042609059],
              [-38.858741049907877, -7.89964684555785],
              [-38.858769448608122, -7.899639618664322],
              [-38.858795562136493, -7.899415658223096],
              [-38.859011112681458, -7.897563009363818],
              [-38.859684774778742, -7.891771783661348],
              [-38.860027418111038, -7.888835330680233],
              [-38.860540563460873, -7.884140104312061],
              [-38.861179067074637, -7.87863415885704],
              [-38.861583028798513, -7.875102249543381],
              [-38.861990622664671, -7.871537595174085],
              [-38.862713892417688, -7.865239184123487],
              [-38.863597041958521, -7.857351414215109],
              [-38.863703311332529, -7.856382842789381],
              [-38.865783406606873, -7.837318572976016],
              [-38.865920234608296, -7.836064619746278],
              [-38.865924413097964, -7.836046258430044],
              [-38.864172837297957, -7.83711787694792],
              [-38.862988028159798, -7.837842682058832],
              [-38.86127417812736, -7.838897837393492],
              [-38.860044719092926, -7.839654731290998],
              [-38.859933395267547, -7.839723260199712],
              [-38.859905360163154, -7.839740528038854],
              [-38.858318787425375, -7.840717287660661],
              [-38.858270160619711, -7.840735724393012],
              [-38.857990012145521, -7.840841735309596],
              [-38.857489681863576, -7.841031072330637],
              [-38.857052856394624, -7.841196459379502],
              [-38.856587753119456, -7.841821264009257],
              [-38.85600929884999, -7.842598267321535],
              [-38.854468044364047, -7.844668553718062],
              [-38.853392161039835, -7.84611376247488],
              [-38.853355587236493, -7.846162867500836],
              [-38.853227805714127, -7.846334509002808],
              [-38.851579053261759, -7.849618488713443],
              [-38.850757536102982, -7.856819510720021],
              [-38.850675546443931, -7.857538337292842],
              [-38.850652068791497, -7.857744116878638],
              [-38.84994302581179, -7.863874624783551],
              [-38.848950726827162, -7.872557971840433],
              [-38.848571152469702, -7.875846911152792],
              [-38.848266013805819, -7.878491466967157],
              [-38.847884152324475, -7.881800846779721],
              [-38.847435048001188, -7.885641087969364],
              [-38.847209405080633, -7.887681423805259],
              [-38.846614686877054, -7.892979399583525],
              [-38.845888867702719, -7.899230821665546],
              [-38.845865204879324, -7.899432891720627],
              [-38.845771190410993, -7.900235744776809],
              [-38.84563831263722, -7.90039580290843],
              [-38.84474439609378, -7.899487022054444],
              [-38.844524477088548, -7.899263423498589],
              [-38.844488745924529, -7.899227137344748],
              [-38.844282883846198, -7.899017835943997],
              [-38.843915234648208, -7.898644025030623],
              [-38.843594199620469, -7.89831763019445],
              [-38.843337644005601, -7.89805683989515],
              [-38.843018604990213, -7.897732525861665],
              [-38.842168592480832, -7.896868350901954],
              [-38.841575227239218, -7.896265146708815],
              [-38.84155509474261, -7.89624469602131],
              [-38.841484540298126, -7.896173118573113],
              [-38.840778180865229, -7.895456890859049],
              [-38.840388772833506, -7.895061992368163],
              [-38.838988031616999, -7.896461262524376],
              [-38.836279185157871, -7.899167287723027],
              [-38.834697986343848, -7.900749464425314],
              [-38.834688456655861, -7.90075895853503],
              [-38.834692261119649, -7.900773794819884],
              [-38.834682731358996, -7.900783469841365],
              [-38.834441311424584, -7.901026519974183],
              [-38.831620670314202, -7.903867067355724],
              [-38.828829125181308, -7.90667829871962],
              [-38.823917698560088, -7.911551016073165],
              [-38.819088821008172, -7.916186532615078],
              [-38.816073592732408, -7.919121953091542],
              [-38.813924966975044, -7.921213604574508],
              [-38.810561188767153, -7.924525575362027],
              [-38.806017338776947, -7.9289993255559],
              [-38.801557486716881, -7.933495134130101],
              [-38.804296205871985, -7.936299290160227],
              [-38.807106968409599, -7.939177002551076],
              [-38.8091883594292, -7.941305027679234],
              [-38.810522398066993, -7.94266889508308],
              [-38.812393146972916, -7.944589031891403],
              [-38.815401726361195, -7.947676997945783],
              [-38.818437844715788, -7.950761155672696],
              [-38.820155765290252, -7.952506159871729],
              [-38.82189302177882, -7.954262924669584],
              [-38.822132026348079, -7.954030194147384],
              [-38.822141466718229, -7.954020971709616],
              [-38.823710103407464, -7.952493300940064],
              [-38.82639875300292, -7.949874654559826],
              [-38.827791630539487, -7.948509804746512],
              [-38.828419125921712, -7.947894874744502],
              [-38.829557290457508, -7.946779571289043],
              [-38.829670934087346, -7.946668266830351],
              [-38.829703429618988, -7.946636439716507],
              [-38.830659958067294, -7.945699075543854],
              [-38.832030296798251, -7.944356363154626],
              [-38.832544591357269, -7.943852370050243],
              [-38.832959586090233, -7.943441053581497],
              [-38.83347887298418, -7.942926297019194],
              [-38.83407367772832, -7.942336672503187],
              [-38.834406615150499, -7.942006641521564],
              [-38.834464434386057, -7.941949315558769],
              [-38.834820153925939, -7.941596679342157],
              [-38.836390431922418, -7.940040103100192],
              [-38.837762463965916, -7.938680094308229],
              [-38.83967999627599, -7.936762630936329],
              [-38.841007082284129, -7.935435700338376],
              [-38.842965878582277, -7.933487029422925],
              [-38.84296606011123, -7.933486848579615],
              [-38.843173184433716, -7.933280777703919],
              [-38.846322228254131, -7.930150549648577],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0049000',
        uf: 'PE',
        nome_proje: 'PA JATUBARANA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '591.7987',
        capacidade: 8.0,
        num_famili: 8.0,
        fase: 5.0,
        data_de_cr: '15/12/2000',
        forma_obte: 'Confisco',
        data_obten: '06/06/2000',
        area_calc_: 505.0931,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.058924614201871, -8.744063575172779],
              [-40.055307874127244, -8.73980183961158],
              [-40.054178882424083, -8.738478439640826],
              [-40.053923335345843, -8.738178987093994],
              [-40.053570203274141, -8.737765220806553],
              [-40.05063473026528, -8.734324879081511],
              [-40.04744958960066, -8.730583767218434],
              [-40.044878598305047, -8.727551412304054],
              [-40.041292309371428, -8.723303527247463],
              [-40.041000200199456, -8.722956050498407],
              [-40.040917322095076, -8.722996432305518],
              [-40.040554582576569, -8.723173148146076],
              [-40.040146363607938, -8.723371874338802],
              [-40.039738144418216, -8.7235706905787],
              [-40.03932992475702, -8.723769506428086],
              [-40.038921704624379, -8.723968321886915],
              [-40.03851348377038, -8.724167046517566],
              [-40.038105262694941, -8.724365861195292],
              [-40.037697041148071, -8.724564675482368],
              [-40.040619929322709, -8.72802751422833],
              [-40.043542781360792, -8.731490233336011],
              [-40.046401632858263, -8.734898294273222],
              [-40.047586489502535, -8.736383723086512],
              [-40.049283305764909, -8.738516713060907],
              [-40.050635971188044, -8.740124931668142],
              [-40.051012834032178, -8.740573182223812],
              [-40.05129776114881, -8.740911805317481],
              [-40.05415850205241, -8.744261090331829],
              [-40.057205799551809, -8.747760947831315],
              [-40.060090814013904, -8.751137328599562],
              [-40.063055817213552, -8.754588338271967],
              [-40.066050427757872, -8.758073781364551],
              [-40.068826556092063, -8.761304778105131],
              [-40.072063443007266, -8.765072000377801],
              [-40.074986074980195, -8.768473448901474],
              [-40.077851568739057, -8.771808107660904],
              [-40.078099947459485, -8.772097158818948],
              [-40.080050049119372, -8.774366708516148],
              [-40.082501506255731, -8.777219481771658],
              [-40.084651796053642, -8.779721662848489],
              [-40.08573776164058, -8.780985377392076],
              [-40.08716175226656, -8.782658244176126],
              [-40.089667798880342, -8.785736529123945],
              [-40.092597154920831, -8.789334841240324],
              [-40.093572871575695, -8.790527129560465],
              [-40.096795001404296, -8.794295202570206],
              [-40.099002640404947, -8.796816462787987],
              [-40.101886608793883, -8.800100365323495],
              [-40.10379483689448, -8.802221199844588],
              [-40.106775709408552, -8.80541089700276],
              [-40.109964307933282, -8.808820704939025],
              [-40.111246239667409, -8.810191544688434],
              [-40.113535134455695, -8.812639291700833],
              [-40.11388547189064, -8.8130140144984],
              [-40.114897372606713, -8.8117955584362],
              [-40.115804619521988, -8.81075846325931],
              [-40.113322799911444, -8.807855052667122],
              [-40.110117622699619, -8.804105104724863],
              [-40.107314116617587, -8.800825201795353],
              [-40.104607547611828, -8.797658484461563],
              [-40.101082245000939, -8.793533702815312],
              [-40.098093892593219, -8.790037203083775],
              [-40.096729415745465, -8.78844063303371],
              [-40.093664700120414, -8.784854506133577],
              [-40.090392057278088, -8.781025040145668],
              [-40.087393145129532, -8.777515798773534],
              [-40.084424118597902, -8.774041258569659],
              [-40.081908225997765, -8.771097068630704],
              [-40.079421198153305, -8.768186597752958],
              [-40.076791641148759, -8.765109203046579],
              [-40.073526739113632, -8.7612881154701],
              [-40.070836078322166, -8.758139033059692],
              [-40.068247983572519, -8.755110008229511],
              [-40.065790450107436, -8.752233612822476],
              [-40.062773858623366, -8.74870282296181],
              [-40.059623254088002, -8.745015015230146],
              [-40.058924614201871, -8.744063575172779],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0240000',
        uf: 'PE',
        nome_proje: 'PA LAGO AZUL',
        municipio: 'PETROLANDIA',
        area_hecta: '567.4768',
        capacidade: 50.0,
        num_famili: 49.0,
        fase: 3.0,
        data_de_cr: '26/10/2007',
        forma_obte: 'Doação',
        data_obten: '26/10/2007',
        area_calc_: 567.8764,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.41268823963258, -9.024384748835915],
              [-38.420587684879159, -9.004514287120022],
              [-38.402729189657293, -9.004488919214978],
              [-38.400193940781797, -9.004485250531657],
              [-38.396008194525422, -9.004479132190614],
              [-38.391812348028424, -8.996687505861949],
              [-38.39116397721336, -8.994560311865623],
              [-38.390173901241909, -8.996029333444659],
              [-38.387362865650168, -9.000199854744757],
              [-38.386774420412358, -9.001072858314702],
              [-38.386772925651954, -9.001096100525174],
              [-38.386715933155649, -9.002038908276582],
              [-38.38710430246914, -9.002854023640699],
              [-38.387414797887736, -9.003668918695201],
              [-38.387730059263077, -9.00386878663079],
              [-38.387803261437597, -9.004483943176213],
              [-38.388346852625233, -9.00572586026809],
              [-38.389124414627247, -9.006929363573724],
              [-38.389980209372276, -9.007977879720588],
              [-38.390797499103428, -9.00871609950708],
              [-38.391576398660327, -9.009182824744146],
              [-38.392160632814026, -9.009416418096063],
              [-38.39301773645623, -9.009689352642923],
              [-38.393367835749238, -9.010116474940196],
              [-38.393835192063051, -9.010388765042372],
              [-38.393873678323096, -9.010660257983504],
              [-38.393483798606411, -9.01073721856492],
              [-38.393210631621294, -9.010930685209852],
              [-38.393209919934293, -9.011357229040453],
              [-38.393637989285168, -9.011784570182577],
              [-38.394104739989096, -9.012367090095317],
              [-38.394610715845189, -9.012833269567425],
              [-38.395078050127175, -9.013066756804282],
              [-38.395468410047997, -9.012757076931351],
              [-38.395702058452279, -9.012912576334921],
              [-38.395974581515866, -9.013106940931035],
              [-38.396715792169246, -9.012720413433112],
              [-38.397340570626341, -9.012100884562408],
              [-38.397458184979541, -9.011674441440272],
              [-38.397544674113831, -9.011583684410679],
              [-38.397809639946381, -9.01128727298347],
              [-38.397966154640883, -9.010938496323682],
              [-38.39820044486077, -9.010706160888271],
              [-38.398434002310609, -9.010861658415536],
              [-38.398433360789276, -9.011249491690386],
              [-38.398589105341252, -9.01136606039775],
              [-38.399290474488815, -9.011522321746227],
              [-38.399718935791078, -9.011716938192251],
              [-38.399925581942298, -9.011862080044718],
              [-38.399834717974045, -9.012453994332077],
              [-38.399639110314361, -9.012841509565353],
              [-38.398898286708437, -9.012995415536455],
              [-38.398235404664021, -9.013110645957772],
              [-38.398032313408237, -9.013401098942099],
              [-38.397875669035997, -9.013827478332241],
              [-38.397875026854244, -9.014215221058404],
              [-38.39775764223009, -9.014447747508003],
              [-38.397602051986865, -9.01429237714418],
              [-38.397329500469311, -9.014059212099371],
              [-38.397057104482727, -9.013787336066782],
              [-38.396589381630463, -9.013786569150716],
              [-38.396160082547027, -9.014096095822095],
              [-38.395886785255883, -9.014367167129883],
              [-38.395573877320274, -9.014987115226853],
              [-38.395573167930159, -9.015413749596679],
              [-38.395455356450114, -9.01595650551462],
              [-38.395687948728174, -9.016693663886651],
              [-38.396505811016858, -9.017160444015339],
              [-38.397050800221997, -9.01758788316376],
              [-38.397479459933791, -9.017666188802941],
              [-38.397986603766462, -9.017434300863998],
              [-38.398689010714932, -9.016970103356782],
              [-38.399117990944767, -9.016854400018323],
              [-38.399195319466195, -9.017242360613727],
              [-38.398960448088694, -9.017823728133695],
              [-38.399310588778178, -9.018289647115838],
              [-38.400011459093307, -9.01875622877308],
              [-38.400556135109817, -9.019377579740521],
              [-38.400672021318179, -9.019998321637917],
              [-38.400514571777464, -9.020967560138054],
              [-38.400279955745525, -9.021393813178769],
              [-38.399889718177654, -9.021625804420495],
              [-38.400042853839402, -9.02332463794535],
              [-38.400353777537973, -9.02390689671439],
              [-38.400859777070195, -9.024373068872412],
              [-38.401092640499066, -9.024955199825575],
              [-38.401442571417611, -9.0254987198901],
              [-38.401792913839884, -9.025848323221435],
              [-38.401636459293229, -9.026158299642026],
              [-38.401518561215966, -9.026701057403921],
              [-38.401907270202237, -9.027399755897891],
              [-38.402140611255774, -9.027749168475163],
              [-38.402725137609771, -9.02782763200408],
              [-38.402919283990492, -9.028332096025693],
              [-38.403503774826689, -9.028488251810861],
              [-38.403892908158177, -9.028876627465319],
              [-38.404360423808022, -9.029071303159652],
              [-38.404320500990778, -9.02961418839624],
              [-38.404203078460107, -9.029924229192996],
              [-38.404202505428927, -9.030273261059035],
              [-38.404358323288051, -9.03035111688077],
              [-38.404670059200363, -9.030390423929816],
              [-38.404943426835963, -9.030080545260802],
              [-38.405216730259951, -9.029809558224574],
              [-38.406191087689599, -9.029966250732436],
              [-38.406620208465689, -9.029773027133622],
              [-38.406933148389953, -9.029075466704956],
              [-38.407284576310161, -9.028765802575938],
              [-38.407479714886428, -9.028611001706992],
              [-38.40748110760714, -9.027757822152399],
              [-38.407560155815595, -9.027098594662679],
              [-38.407560725413887, -9.026749562522848],
              [-38.407366193243405, -9.026477818790367],
              [-38.407054586818624, -9.02636100177445],
              [-38.407055220224471, -9.025973168206225],
              [-38.406939293983378, -9.025313716545027],
              [-38.406744980770682, -9.024964369796001],
              [-38.406512080473597, -9.024343440245541],
              [-38.406474217772093, -9.02368411454278],
              [-38.406396880457002, -9.023296155020502],
              [-38.405734171220793, -9.023295083949522],
              [-38.405305819913906, -9.02302296110479],
              [-38.405306454948935, -9.0226351275896],
              [-38.404917072272283, -9.022401778499885],
              [-38.404917898234835, -9.021897631125674],
              [-38.40491885127723, -9.02131588085529],
              [-38.404412405010461, -9.021121233769518],
              [-38.404178874747835, -9.02088813650883],
              [-38.404179637957839, -9.020422790598996],
              [-38.403829325437606, -9.020111900768299],
              [-38.403791403892129, -9.019491376418454],
              [-38.403792103958928, -9.019064741497969],
              [-38.404065844658568, -9.018522235036588],
              [-38.404611353615408, -9.018639523443415],
              [-38.4050005057858, -9.019066699119582],
              [-38.405350437701095, -9.019610215647639],
              [-38.405544873821484, -9.019882050513029],
              [-38.40636339098738, -9.019960885832345],
              [-38.406792626069517, -9.019690148690286],
              [-38.407144016827594, -9.019341682353359],
              [-38.407144586328059, -9.01899265009259],
              [-38.407456502852604, -9.018915549799825],
              [-38.407846101196313, -9.019071293321023],
              [-38.408391612552471, -9.019188576247016],
              [-38.408663074506222, -9.020042195342308],
              [-38.408973820804761, -9.020740761601116],
              [-38.409246104958122, -9.021090232564159],
              [-38.409791458993645, -9.021362538779462],
              [-38.410063240549448, -9.022022240123249],
              [-38.410296495690233, -9.022371647759483],
              [-38.41072510189462, -9.022488739962217],
              [-38.410855229531478, -9.02264225553048],
              [-38.411153079762684, -9.022993575162943],
              [-38.411503681228396, -9.023188054241652],
              [-38.411698689441359, -9.023110763301364],
              [-38.412181831113315, -9.023261858654978],
              [-38.412322054184756, -9.02330567818116],
              [-38.412282109803066, -9.023926168378713],
              [-38.412437459610324, -9.024236648627019],
              [-38.41268823963258, -9.024384748835915],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0033000',
        uf: 'PE',
        nome_proje: 'PA SANTA MARIA',
        municipio: 'PETROLINA',
        area_hecta: '1098.6734',
        capacidade: 35.0,
        num_famili: 35.0,
        fase: 6.0,
        data_de_cr: '20/07/1998',
        forma_obte: 'Desapropriação',
        data_obten: '12/12/1997',
        area_calc_: 1128.1836,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.337175580591548, -8.90287621282318],
              [-40.336969165602973, -8.902626468323719],
              [-40.334252553376437, -8.899339925192884],
              [-40.3309207891471, -8.895308976319571],
              [-40.325452321657913, -8.896154718246816],
              [-40.325417630198316, -8.896065950395075],
              [-40.324341296903683, -8.893314599342949],
              [-40.324130493764201, -8.892776032128763],
              [-40.323712650205358, -8.891707926535718],
              [-40.322708161568059, -8.889128578094189],
              [-40.321891609323004, -8.887022676319528],
              [-40.320264975053689, -8.882882007321633],
              [-40.318392690330022, -8.878128361627258],
              [-40.316723731683233, -8.874026426755622],
              [-40.315916224305319, -8.872165893131564],
              [-40.314836163622225, -8.870416548866512],
              [-40.313542071113915, -8.868358775246776],
              [-40.312073579924601, -8.865987727531587],
              [-40.311503203626103, -8.865063101504498],
              [-40.310916436754788, -8.863412656235059],
              [-40.310885211742693, -8.863324958944975],
              [-40.310433837189066, -8.8612384395849],
              [-40.309617583004027, -8.860817728461717],
              [-40.309311452646625, -8.860203609932576],
              [-40.308123870507089, -8.861093792504654],
              [-40.306845662571526, -8.861864108191014],
              [-40.306284723555386, -8.862910799092518],
              [-40.307284212311558, -8.865343200961602],
              [-40.30602623715901, -8.867135025963396],
              [-40.305231721414309, -8.868212736232826],
              [-40.3002372156663, -8.869201905890549],
              [-40.300306546339257, -8.869524859871614],
              [-40.300666730232223, -8.871004842109842],
              [-40.30094682664172, -8.872179269790715],
              [-40.301198244446873, -8.87272314139917],
              [-40.301749088388817, -8.873913082586972],
              [-40.303555994322373, -8.877830692881268],
              [-40.305876145740193, -8.882853437453216],
              [-40.308442633884042, -8.888345072716165],
              [-40.310583909276602, -8.892912722418432],
              [-40.312902914354531, -8.897859797905896],
              [-40.312952243417975, -8.897974107125592],
              [-40.313798773975321, -8.899795526283341],
              [-40.31463056745249, -8.906373645469058],
              [-40.314680927015189, -8.906368584383834],
              [-40.314783484790894, -8.906313150819267],
              [-40.320411498099055, -8.91036721565351],
              [-40.323370181951333, -8.912495245632385],
              [-40.324774569046426, -8.913498794204264],
              [-40.325584897717285, -8.914078898536399],
              [-40.327427668333193, -8.915401259689402],
              [-40.335347380500117, -8.921082707227599],
              [-40.335360236191363, -8.921091702773099],
              [-40.335812007169615, -8.920515228273114],
              [-40.3374229205196, -8.919580646369022],
              [-40.33795611579594, -8.919389822633244],
              [-40.341781123362409, -8.919048771288061],
              [-40.342005118500822, -8.919004014428383],
              [-40.343315177027357, -8.918911927474017],
              [-40.344175428037268, -8.918835208392474],
              [-40.344439498926754, -8.918832896648581],
              [-40.344879245241813, -8.918802006764388],
              [-40.346425167687286, -8.916221840503002],
              [-40.347091202555774, -8.915061590770964],
              [-40.344206098146365, -8.911381403204933],
              [-40.340441814871312, -8.906827563047752],
              [-40.337345096888434, -8.903081327816288],
              [-40.337175580591548, -8.90287621282318],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0313000',
        uf: 'PE',
        nome_proje: 'PA MARIA TEREZA',
        municipio: 'PETROLINA',
        area_hecta: '29.3725',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 3.0,
        data_de_cr: '08/11/2013',
        forma_obte: 'Confisco',
        data_obten: '26/11/1991',
        area_calc_: 29.3761,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.514660481993644, -9.148624582214254],
              [-40.512253269157831, -9.148635869798582],
              [-40.509795514515837, -9.148648474906553],
              [-40.509805093496986, -9.150639368044002],
              [-40.512263111204845, -9.150627862750619],
              [-40.514671605216833, -9.150615146227683],
              [-40.514660481993644, -9.148624582214254],
            ],
          ],
          [
            [
              [-40.519451944544876, -9.144516773109201],
              [-40.517046128291064, -9.144527805622609],
              [-40.514641735410692, -9.144535255486593],
              [-40.514650674966994, -9.146601413238361],
              [-40.514660481993644, -9.148624582214254],
              [-40.517066538591692, -9.148613554751483],
              [-40.517055280844758, -9.146622069169936],
              [-40.515664045380326, -9.146629062210103],
              [-40.515664799688018, -9.146597250103509],
              [-40.515664845906272, -9.146524970642901],
              [-40.517056095763579, -9.146517389871821],
              [-40.519459589044544, -9.146510053147878],
              [-40.519451944544876, -9.144516773109201],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0067000',
        uf: 'PE',
        nome_proje: 'PA JOSÉ RAMOS',
        municipio: 'LAGOA GRANDE',
        area_hecta: '588.5472',
        capacidade: 80.0,
        num_famili: 78.0,
        fase: 4.0,
        data_de_cr: '06/11/2002',
        forma_obte: 'Desapropriação',
        data_obten: '08/11/2000',
        area_calc_: 625.0179,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.233264590535761, -9.031055266159802],
              [-40.231125562574327, -9.033007540373241],
              [-40.23005303584722, -9.033676709332424],
              [-40.226184031978278, -9.036447718450516],
              [-40.222901189333925, -9.038978544143385],
              [-40.221794805954623, -9.042162883484325],
              [-40.221157838662336, -9.043489734689778],
              [-40.220908775360364, -9.045033504593787],
              [-40.220908775665151, -9.045033595024192],
              [-40.220476861333566, -9.044364846073792],
              [-40.218969503044413, -9.042026420074029],
              [-40.216756547294054, -9.038074676231581],
              [-40.214625656901411, -9.03438217925126],
              [-40.212503440706158, -9.030685294237848],
              [-40.212353740877759, -9.030423446119228],
              [-40.210431187052706, -9.026926189819136],
              [-40.208799451445742, -9.023825680862416],
              [-40.206860842668846, -9.019772020760737],
              [-40.206024547335169, -9.01792308009739],
              [-40.204668462041916, -9.014667360251176],
              [-40.204543173296308, -9.014328921683955],
              [-40.202892059827803, -9.010119555471871],
              [-40.201202926267044, -9.005925221422334],
              [-40.199391097455617, -9.001776399034821],
              [-40.197693255635052, -8.997893966518182],
              [-40.196187853135243, -8.994515690090948],
              [-40.194636189524516, -8.991055983158269],
              [-40.192088913934114, -8.992849094806957],
              [-40.192888981095329, -8.994622149415365],
              [-40.194920311370971, -8.999281250689965],
              [-40.196735329434603, -9.003423672626351],
              [-40.1985527965169, -9.007549884116436],
              [-40.200360806439384, -9.011690580506555],
              [-40.202203519742476, -9.015831057990566],
              [-40.202350996764643, -9.016167706782671],
              [-40.204182057981548, -9.020345192419949],
              [-40.206015074671257, -9.024522023206162],
              [-40.206570565650082, -9.025766984554409],
              [-40.207017654112079, -9.026691810010798],
              [-40.207366633651986, -9.027356151496706],
              [-40.207709496008398, -9.028013368460908],
              [-40.208051889533181, -9.028665974439969],
              [-40.208386869958161, -9.029304768238926],
              [-40.208721305262777, -9.029943382490833],
              [-40.20905174860934, -9.030584812798624],
              [-40.209389816160382, -9.031220701181468],
              [-40.209722695898606, -9.031855430561883],
              [-40.210050734473981, -9.032483845202572],
              [-40.210378928769416, -9.033104029577974],
              [-40.210700377521086, -9.033719714158218],
              [-40.21101889170631, -9.034327992576184],
              [-40.211337674226748, -9.034934551472453],
              [-40.211649971616225, -9.035532902060989],
              [-40.21196381539913, -9.036130885398443],
              [-40.212263048990479, -9.036712096289312],
              [-40.212580439172889, -9.037308981903816],
              [-40.212890440177219, -9.037899380438851],
              [-40.213076417177419, -9.038235533106235],
              [-40.213187886224212, -9.038461967129834],
              [-40.213547615214843, -9.039014671216719],
              [-40.215133647844759, -9.041416710031829],
              [-40.21545499922189, -9.041889868346949],
              [-40.215864154481203, -9.042503175359956],
              [-40.21628290047159, -9.043127572985526],
              [-40.216706423115092, -9.043765247510754],
              [-40.217129837222693, -9.044397224508415],
              [-40.217547051043461, -9.045024518960847],
              [-40.217942226754303, -9.045617521991343],
              [-40.218466292374224, -9.046394033577471],
              [-40.219392381054668, -9.047795625268794],
              [-40.219690705528272, -9.048247179558729],
              [-40.217135109964438, -9.050026477616068],
              [-40.216065751418569, -9.050605625006964],
              [-40.213926155634596, -9.052970000593438],
              [-40.21263532201035, -9.053866384292872],
              [-40.21038899987056, -9.054798117185817],
              [-40.210565884126304, -9.055214693832211],
              [-40.212027596777801, -9.057989817271713],
              [-40.212129959529982, -9.058157228997027],
              [-40.213108450097835, -9.05776440854304],
              [-40.215469381179346, -9.056580606285557],
              [-40.217781610201264, -9.055284636813479],
              [-40.218826605607582, -9.054329912120028],
              [-40.220824646332737, -9.052972122223858],
              [-40.22316642800012, -9.051712288377441],
              [-40.224799195508723, -9.05100490571067],
              [-40.226313286158216, -9.050580963429114],
              [-40.229064979195456, -9.049677017382319],
              [-40.229976013794648, -9.049284473665624],
              [-40.229976369694931, -9.049282121262914],
              [-40.23095574375121, -9.04900283693196],
              [-40.231485801415957, -9.048937392493967],
              [-40.234566742730749, -9.048501457465971],
              [-40.236241148202112, -9.048411440641615],
              [-40.237668196564442, -9.048533950343478],
              [-40.237657926568218, -9.048323914204746],
              [-40.23831399066016, -9.044058155911403],
              [-40.238519427977934, -9.042681376055549],
              [-40.238949758418634, -9.039845277680024],
              [-40.239545350630486, -9.035883932778949],
              [-40.239577082648168, -9.035346937746999],
              [-40.23955564271138, -9.034982754771452],
              [-40.239994065266082, -9.031882028350687],
              [-40.240010979962705, -9.03177399680065],
              [-40.240233567379647, -9.030173885211516],
              [-40.238040096762262, -9.029837650308577],
              [-40.236212776832922, -9.029555784300079],
              [-40.235979333031857, -9.029523020249822],
              [-40.235947229668454, -9.029518515906933],
              [-40.235268717603844, -9.029423314874091],
              [-40.23462898361101, -9.029809979078497],
              [-40.233264590535761, -9.031055266159802],
            ],
            [
              [-40.219690705528272, -9.048247179558729],
              [-40.219690746240694, -9.04824715121298],
              [-40.219690746545297, -9.048247241643391],
              [-40.219690705528272, -9.048247179558729],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0096000',
        uf: 'PE',
        nome_proje: 'PA ANTONIO CONSELHEIRO I',
        municipio: 'TACARATU',
        area_hecta: '9125.4083',
        capacidade: 300.0,
        num_famili: 290.0,
        fase: 4.0,
        data_de_cr: '22/07/2004',
        forma_obte: 'Doação',
        data_obten: '22/07/2004',
        area_calc_: 8030.0474,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.11102170919353, -8.903920983505131],
              [-38.111222761629151, -8.903292896541416],
              [-38.123004039231795, -8.866485303404087],
              [-38.104155920089127, -8.867424008455504],
              [-38.104155878554756, -8.867424060824467],
              [-38.103267407345051, -8.867468235928119],
              [-38.093143525403065, -8.880862087621322],
              [-38.092762755489801, -8.881788453385688],
              [-38.092762677293798, -8.881788551968079],
              [-38.089532807139896, -8.889645414884081],
              [-38.089287418787926, -8.890245161873366],
              [-38.082254682105315, -8.907213932416299],
              [-38.078214334114271, -8.916961839575785],
              [-38.07455634638454, -8.920045358156745],
              [-38.067972433775104, -8.923022833918818],
              [-38.069379856890016, -8.93487176510911],
              [-38.071904505045424, -8.95717656336366],
              [-38.077600316233926, -8.952923470161563],
              [-38.086213950848659, -8.960776971929382],
              [-38.079211782143716, -8.96677406780773],
              [-38.084983060044443, -8.975063508646267],
              [-38.093323128061542, -8.963149175121575],
              [-38.095807827694749, -8.959608446321768],
              [-38.096596280572079, -8.9646468329095],
              [-38.097871896117873, -8.96690354822513],
              [-38.098296380470266, -8.967654337153064],
              [-38.098090630158211, -8.971595149637338],
              [-38.098439412651814, -8.972503753847757],
              [-38.09740524396458, -8.975342150445496],
              [-38.091648947187245, -8.984418483112249],
              [-38.104559813092585, -9.002452987563554],
              [-38.108179949981171, -8.994370396556441],
              [-38.111622275520666, -8.996020029862223],
              [-38.113800493505003, -8.991061061410678],
              [-38.113640978036493, -8.990332899719409],
              [-38.112445447462136, -8.990084748510611],
              [-38.114254769012625, -8.98638825398756],
              [-38.118317638173842, -8.990180321806582],
              [-38.117672381625439, -9.010048484345031],
              [-38.125218952457971, -9.012881480868117],
              [-38.133120553372208, -9.015672843507698],
              [-38.155647028976929, -9.024352490797815],
              [-38.157676298833792, -9.02489193070341],
              [-38.160347588230003, -9.025241724168255],
              [-38.160107868823324, -9.026458071130838],
              [-38.163047565242067, -9.027357442057745],
              [-38.162987345486705, -9.028537661931464],
              [-38.168344053135627, -9.030330905212361],
              [-38.172429560890983, -9.03156643980487],
              [-38.182731851000156, -9.038532590381831],
              [-38.182383752381817, -9.051837585061275],
              [-38.183576417835233, -9.060047060758933],
              [-38.202223547302303, -9.009469296402861],
              [-38.201937966140903, -9.009930930463407],
              [-38.180009016498552, -9.016961914090855],
              [-38.179221930175721, -9.01722741510989],
              [-38.177173349163105, -9.017783216936943],
              [-38.161990819954831, -9.022720881130029],
              [-38.1676728139145, -9.004311559956504],
              [-38.16744884525675, -8.997705251855809],
              [-38.186949820799597, -8.996822212131859],
              [-38.116413753158412, -8.938381155537611],
              [-38.104472462823438, -8.9459674248775],
              [-38.121013137448656, -8.959558574781969],
              [-38.122538445436582, -8.96081182266512],
              [-38.113504666547691, -8.965441760579626],
              [-38.078540117772953, -8.943150930781176],
              [-38.104090147514007, -8.904859423205622],
              [-38.11102170919353, -8.903920983505131],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0097000',
        uf: 'PE',
        nome_proje: 'PA ANTONIO CONSELHEIRO II',
        municipio: 'TACARATU',
        area_hecta: '4938.5134',
        capacidade: 150.0,
        num_famili: 145.0,
        fase: 4.0,
        data_de_cr: '22/07/2004',
        forma_obte: 'Doação',
        data_obten: '22/07/2004',
        area_calc_: 4937.2881,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.181939138162328, -8.926195756657377],
              [-38.187787077260616, -8.938594336537728],
              [-38.189979241989825, -8.943273382543223],
              [-38.194098111950403, -8.952099880704177],
              [-38.194916305225554, -8.953831917571934],
              [-38.198043767270093, -8.960522867842208],
              [-38.199097811184402, -8.962858842383724],
              [-38.203054313480919, -8.97128633668472],
              [-38.207223824395875, -8.973399003903118],
              [-38.212806219221314, -8.961727347977281],
              [-38.217200957651514, -8.944980743401139],
              [-38.206663092153143, -8.945729630005385],
              [-38.201946635411986, -8.946103341016784],
              [-38.203499878221862, -8.941734135306811],
              [-38.201562626601671, -8.941587068208813],
              [-38.196881279622993, -8.941238157557457],
              [-38.196690420523083, -8.936478378551026],
              [-38.204763948815767, -8.937062672036289],
              [-38.206700476648464, -8.93719823271465],
              [-38.227753638063909, -8.938746016044799],
              [-38.228217462482249, -8.937217222928163],
              [-38.228738023333392, -8.935263462925985],
              [-38.229112055623759, -8.933392695487413],
              [-38.229133993379484, -8.933254543570774],
              [-38.228315280611611, -8.933047987498481],
              [-38.224901185938272, -8.932739152536609],
              [-38.222162113371674, -8.932199805713134],
              [-38.222244809966526, -8.930732175461621],
              [-38.229393987910825, -8.931241904055412],
              [-38.229452334549471, -8.930657670364882],
              [-38.229494886901726, -8.92984150694099],
              [-38.229529227433005, -8.928690866664041],
              [-38.229457772726199, -8.926290530838701],
              [-38.229373040436933, -8.925276212842695],
              [-38.227797461233699, -8.912458734103954],
              [-38.227708369548083, -8.911530237426105],
              [-38.227768181163597, -8.909640183593039],
              [-38.227867543007562, -8.908925615059086],
              [-38.228166209515159, -8.907717548069694],
              [-38.228625593556153, -8.906503392301643],
              [-38.229225806040084, -8.905355551095798],
              [-38.230070878269551, -8.904149788589892],
              [-38.231285867078157, -8.902883376493214],
              [-38.232132735354497, -8.902205802438262],
              [-38.23301846178876, -8.901646516032192],
              [-38.233234368253058, -8.901517445476557],
              [-38.233933214571941, -8.901166696515272],
              [-38.242578962502947, -8.897515259538306],
              [-38.242016274041468, -8.890351324990604],
              [-38.23979032192328, -8.890514927226093],
              [-38.236893332534706, -8.890698578044509],
              [-38.217917032398937, -8.891877338305108],
              [-38.217986569988064, -8.897465164357468],
              [-38.23091427092929, -8.897648819849277],
              [-38.222889365419846, -8.909336625213989],
              [-38.204460680047887, -8.904688153582038],
              [-38.190673218954672, -8.901179639140784],
              [-38.192595309854241, -8.896027397325726],
              [-38.211700128089674, -8.897692853917276],
              [-38.211557395479481, -8.896648023327515],
              [-38.213079392526907, -8.892469598298788],
              [-38.213094711039474, -8.892152807930113],
              [-38.214083783130725, -8.869583315505706],
              [-38.21409383987767, -8.869387164965936],
              [-38.214344225566926, -8.860814199196849],
              [-38.169374568973801, -8.864421635824177],
              [-38.143657950511042, -8.866009923350161],
              [-38.140998309461736, -8.874068424237906],
              [-38.169355127190947, -8.872344078279632],
              [-38.169284105828851, -8.902703876053922],
              [-38.182195848760855, -8.902707411982357],
              [-38.181939138162328, -8.926195756657377],
            ],
          ],
          [
            [
              [-38.204281288835531, -8.974856673391209],
              [-38.204932758243466, -8.975411675908315],
              [-38.2052669154399, -8.975894725930184],
              [-38.205507307182138, -8.976452370118079],
              [-38.205982088222413, -8.975769553907663],
              [-38.207031647139296, -8.973803051382495],
              [-38.203371095847409, -8.971975469365947],
              [-38.203706028324454, -8.973435755183543],
              [-38.203982402317024, -8.973801377421333],
              [-38.204062811921553, -8.974178696628167],
              [-38.204281288835531, -8.974856673391209],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0216000',
        uf: 'PE',
        nome_proje: 'PA TERRA NOVA',
        municipio: 'SAO JOSE DO BELMONTE',
        area_hecta: '1261.9000',
        capacidade: 58.0,
        num_famili: 48.0,
        fase: 4.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '19/11/1999',
        area_calc_: 1053.9315,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.872467131276899, -7.84003298385117],
              [-38.872389200409231, -7.841855565929352],
              [-38.872314923700976, -7.843591129902852],
              [-38.872241468209445, -7.845308512230463],
              [-38.872171848764083, -7.84693788152516],
              [-38.872106158200197, -7.848472634525205],
              [-38.872045034788208, -7.849902368969175],
              [-38.871203146719317, -7.869584860009938],
              [-38.87708299529622, -7.87427533337137],
              [-38.877556165672949, -7.891906203089473],
              [-38.877740133059348, -7.932916426412591],
              [-38.885798036788451, -7.941225018407271],
              [-38.885866290266456, -7.930612114558297],
              [-38.885867199846906, -7.924504728387906],
              [-38.885867355435842, -7.92345775395401],
              [-38.885831450294013, -7.920699717114987],
              [-38.885765623934411, -7.91564630016873],
              [-38.885758031936881, -7.913632985006874],
              [-38.885755731068969, -7.913023793906433],
              [-38.885701786840279, -7.910533908992983],
              [-38.885680209194526, -7.909538317882371],
              [-38.885671390141432, -7.908857576381791],
              [-38.885631704051548, -7.905795862764795],
              [-38.885614136479802, -7.904719220762288],
              [-38.885538112443172, -7.900067158119056],
              [-38.88549656027476, -7.89886191812166],
              [-38.885479144010198, -7.898643982136342],
              [-38.885463526483072, -7.898463078345901],
              [-38.885463730361138, -7.898451105423709],
              [-38.88546371129263, -7.898450866812437],
              [-38.885469715147998, -7.89809964302695],
              [-38.88547468442404, -7.897807816835747],
              [-38.885472652610773, -7.897399019624429],
              [-38.885471501782092, -7.897179797915718],
              [-38.885287347856554, -7.885878484459821],
              [-38.885017481818565, -7.874669892382475],
              [-38.885010697872644, -7.874002681923636],
              [-38.885003117651905, -7.873260581453829],
              [-38.884952078960211, -7.871364873103704],
              [-38.88481096030911, -7.86397857553075],
              [-38.884774660024533, -7.862079302957618],
              [-38.884577270953535, -7.851446349912343],
              [-38.872467131276899, -7.84003298385117],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0193000',
        uf: 'PE',
        nome_proje: 'PA AREIAS',
        municipio: 'TACARATU',
        area_hecta: '716.7631',
        capacidade: 18.0,
        num_famili: 18.0,
        fase: 3.0,
        data_de_cr: '30/10/2006',
        forma_obte: 'Desapropriação',
        data_obten: '30/10/2006',
        area_calc_: 719.0009,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.9528438162478, -9.030335056427541],
              [-37.958992248924233, -9.012664354160973],
              [-37.960427164108062, -9.00853996462441],
              [-37.960231570553432, -9.008448522949447],
              [-37.958447018702749, -9.007059611345559],
              [-37.957259341723251, -9.005408850410454],
              [-37.957065300503871, -9.00376090229291],
              [-37.955081167768164, -9.002700964548007],
              [-37.953361371250857, -9.001839463449899],
              [-37.951309148528431, -9.001372307939144],
              [-37.948859404789609, -9.000970026152185],
              [-37.945758012547799, -9.000684878773505],
              [-37.942766995522575, -9.00035950704984],
              [-37.941216950744028, -9.000355055041467],
              [-37.939871987250257, -9.003021955503558],
              [-37.934312080316587, -9.014028458212579],
              [-37.933569220970071, -9.015518954566064],
              [-37.933272644913018, -9.016020286556394],
              [-37.932896628817353, -9.016529346065365],
              [-37.932367487597539, -9.017076848033158],
              [-37.923653684005082, -9.026687115789297],
              [-37.930392539412132, -9.026620705303696],
              [-37.930684293429927, -9.026618479910494],
              [-37.930979880250874, -9.026612195816538],
              [-37.931281765143794, -9.026598785357624],
              [-37.931586768088216, -9.026576882725417],
              [-37.93187835383587, -9.02653911450564],
              [-37.932159626812073, -9.026481600998624],
              [-37.93242919899852, -9.026412296566697],
              [-37.932694166132599, -9.026331221850084],
              [-37.932948610537636, -9.026239897080666],
              [-37.933197149407476, -9.026145932322105],
              [-37.933322287411762, -9.026097460199985],
              [-37.936147506789887, -9.025013997448818],
              [-37.936372726478218, -9.024929910805881],
              [-37.936586710286299, -9.024861617831506],
              [-37.936789398259933, -9.024798537336192],
              [-37.937006905449252, -9.024738574429445],
              [-37.937237130522547, -9.024684797838873],
              [-37.937498064842679, -9.024644404035257],
              [-37.937776270349183, -9.024639691861791],
              [-37.938054563653466, -9.024667175043511],
              [-37.938335792696776, -9.024717366008588],
              [-37.938620242606731, -9.024786105510636],
              [-37.9389045712837, -9.024865244627994],
              [-37.939196540891189, -9.024944676910057],
              [-37.939499689274747, -9.025027849131209],
              [-37.939807201008648, -9.025112209381104],
              [-37.940101259467681, -9.025193003560046],
              [-37.940012174346755, -9.02554382034935],
              [-37.940930906446617, -9.026072266978533],
              [-37.941220625871445, -9.026331117042808],
              [-37.942223385701077, -9.027277347788269],
              [-37.942478845863995, -9.027492779360921],
              [-37.943771445900381, -9.028128468454588],
              [-37.944043432868639, -9.027635648556213],
              [-37.944009424551425, -9.027255084737941],
              [-37.943974156713267, -9.026807142157381],
              [-37.943737775731819, -9.024630200338699],
              [-37.943915319790293, -9.021482075490354],
              [-37.946899823749916, -9.018904877665964],
              [-37.947724655194186, -9.019344319445404],
              [-37.947903309395379, -9.029677929867006],
              [-37.949757298734141, -9.029977336829919],
              [-37.949713907683865, -9.030101924754229],
              [-37.949890670656096, -9.030164288928278],
              [-37.949808884952077, -9.030353067461895],
              [-37.95021346603248, -9.030502811703562],
              [-37.950346280909564, -9.030505090576352],
              [-37.950538975032799, -9.030500938681527],
              [-37.950747248536693, -9.030488872784833],
              [-37.950967269828915, -9.030472589811751],
              [-37.951194098398012, -9.030461752345561],
              [-37.951413477908808, -9.030447185563467],
              [-37.951635786316608, -9.030426477320468],
              [-37.951847590774804, -9.030420299252755],
              [-37.9528438162478, -9.030335056427541],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0305000',
        uf: 'PE',
        nome_proje: 'PA CACIMBA DOS SONHOS',
        municipio: 'PETROLINA',
        area_hecta: '1100.0347',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '31/10/2012',
        forma_obte: 'Desapropriação',
        data_obten: '31/10/2012',
        area_calc_: 1100.0365,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.588765705802707, -9.171161130679373],
              [-40.603358729173877, -9.206606106641237],
              [-40.610508425988037, -9.206149384162742],
              [-40.611450948456948, -9.205938009255499],
              [-40.613267284597377, -9.205503064688266],
              [-40.625855158935181, -9.202689364629942],
              [-40.625996650801504, -9.202797116505094],
              [-40.626180592781246, -9.203183662749693],
              [-40.626495787227228, -9.203529933244992],
              [-40.627104563601506, -9.204239821908885],
              [-40.627510157927283, -9.204268071856873],
              [-40.627677607017617, -9.204135933088006],
              [-40.627618959822428, -9.203700341487458],
              [-40.627327755566348, -9.20243964708572],
              [-40.62710671222144, -9.20105550766138],
              [-40.627031026879727, -9.200160799713064],
              [-40.626786198721042, -9.196957607814811],
              [-40.626329835314792, -9.196948833995032],
              [-40.626012522862595, -9.193663362703822],
              [-40.625901288208766, -9.193423458141941],
              [-40.625426575182907, -9.192626775600855],
              [-40.623989689198488, -9.192621861550508],
              [-40.623591956356314, -9.184655872784674],
              [-40.623580877271635, -9.184594747357602],
              [-40.623273263805771, -9.178742917203094],
              [-40.623217868880047, -9.17825889953728],
              [-40.623819045031198, -9.176741220966159],
              [-40.624195380955143, -9.176093401715367],
              [-40.623867816093039, -9.175339242339684],
              [-40.621964558585866, -9.175629069807471],
              [-40.621645704706154, -9.174972680244066],
              [-40.61905326702054, -9.176405700309138],
              [-40.617821968261197, -9.17492446765929],
              [-40.616368258289441, -9.174478862720264],
              [-40.615446630017026, -9.171838411667427],
              [-40.613990139366138, -9.170423925766396],
              [-40.611552215745711, -9.173904527566414],
              [-40.611108347153753, -9.172847713403176],
              [-40.609294258153284, -9.172559713902519],
              [-40.607286793508287, -9.172697033147449],
              [-40.606870978086846, -9.172698962442331],
              [-40.605915281474644, -9.17280788296611],
              [-40.605045586485573, -9.172959041680555],
              [-40.604881708676047, -9.17297825920369],
              [-40.603678706573824, -9.172586541035235],
              [-40.602169954636992, -9.173981765657842],
              [-40.597782927816958, -9.174992752291402],
              [-40.597423092651702, -9.173568704347211],
              [-40.593538969080186, -9.173704225630145],
              [-40.59224229336084, -9.171548916450188],
              [-40.59158820111773, -9.170942673783813],
              [-40.591381868835946, -9.170576470239267],
              [-40.588765705802707, -9.171161130679373],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0227000',
        uf: 'PE',
        nome_proje: 'PA CAIÇARA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.1589',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '09/04/2007',
        forma_obte: 'Desapropriação',
        data_obten: '01/11/2006',
        area_calc_: 1320.3082,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.025115588253627, -8.614013188355514],
              [-40.024972284238203, -8.614283802881719],
              [-40.024052326749221, -8.616019342021554],
              [-40.026143293836824, -8.640953251845962],
              [-40.035266644237623, -8.677902138742068],
              [-40.043780506773189, -8.686550991815999],
              [-40.095360936402777, -8.749665252426526],
              [-40.129494784578213, -8.793629467043797],
              [-40.129860159787526, -8.79320857033883],
              [-40.132586722285289, -8.790067432905904],
              [-40.099483226919084, -8.746110181377421],
              [-40.048065179688784, -8.682155242701144],
              [-40.039332477844326, -8.673734351102782],
              [-40.030980800804215, -8.639728566211996],
              [-40.025115588253627, -8.614013188355514],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0058000',
        uf: 'PE',
        nome_proje: 'PA SANTA MARTA',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1316.3167',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 5.0,
        data_de_cr: '24/10/2001',
        forma_obte: 'Desapropriação',
        data_obten: '24/10/2001',
        area_calc_: 1303.0171,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.262132160839471, -8.906157615729152],
              [-40.260520030276332, -8.906551570734514],
              [-40.26003288367383, -8.906684073985828],
              [-40.259225704389173, -8.906861790070046],
              [-40.258072532354525, -8.907391546121925],
              [-40.257843333734115, -8.907566490821205],
              [-40.257558079241832, -8.907623703076855],
              [-40.257359791290938, -8.907663439369584],
              [-40.257170678728151, -8.907727108732903],
              [-40.253123181066734, -8.908930202039992],
              [-40.24969631987863, -8.909978522384446],
              [-40.249612842680548, -8.910006293889305],
              [-40.24504866023004, -8.911395521231805],
              [-40.239998781273862, -8.912960578286141],
              [-40.242338395016723, -8.91856882385207],
              [-40.244222460089546, -8.923099002326053],
              [-40.243663394796947, -8.923406080038845],
              [-40.239507175618094, -8.924986787155881],
              [-40.237783702909645, -8.925649610334867],
              [-40.236863429516106, -8.928608789704022],
              [-40.235548669333454, -8.932848253890375],
              [-40.233908008308532, -8.93816231297021],
              [-40.235936798098287, -8.941638974119046],
              [-40.240351029450537, -8.941289453525309],
              [-40.24464872222044, -8.94031738325476],
              [-40.249875586264302, -8.938336814271578],
              [-40.250351741603922, -8.937874644304033],
              [-40.250665135271227, -8.937821137564727],
              [-40.250953652858819, -8.937753516834137],
              [-40.251559306007408, -8.937529827176084],
              [-40.252082952921235, -8.937313828832568],
              [-40.252611715420755, -8.937104685296985],
              [-40.253181448322586, -8.936882471038828],
              [-40.253780296427401, -8.936637730727014],
              [-40.254390176777804, -8.936401452735998],
              [-40.254947080856823, -8.936178284694597],
              [-40.255431247117151, -8.935986922448306],
              [-40.255981148098563, -8.935764048013169],
              [-40.256337866317303, -8.935615618698202],
              [-40.256779255388025, -8.935414904537961],
              [-40.257696700480523, -8.935019054428045],
              [-40.258153170935799, -8.934840895362273],
              [-40.258600793083133, -8.934681575393311],
              [-40.259419665049855, -8.934316169332057],
              [-40.259939819881438, -8.934092667203773],
              [-40.260479980920579, -8.933869006026356],
              [-40.26097978818273, -8.933652806184194],
              [-40.261475692676115, -8.93343906064087],
              [-40.261986288019678, -8.933213327706016],
              [-40.262503873962558, -8.932984314863496],
              [-40.263017831164177, -8.932758117081926],
              [-40.263527708362517, -8.932535730646434],
              [-40.26418733670485, -8.932251702404496],
              [-40.265050824374718, -8.931852764499437],
              [-40.26551690688008, -8.931642642632569],
              [-40.265958661164852, -8.931445534798252],
              [-40.26638289917026, -8.931258885774389],
              [-40.266774442548659, -8.931085279476097],
              [-40.267150096126983, -8.930919051931367],
              [-40.267308372075249, -8.930850688670928],
              [-40.267810780787045, -8.930732046191714],
              [-40.269662129757975, -8.929903343685767],
              [-40.269909657270645, -8.929793618745375],
              [-40.2704999927752, -8.92951415829757],
              [-40.272263638126319, -8.92865273754386],
              [-40.27310592152071, -8.928333705709218],
              [-40.275148457550443, -8.927364066147987],
              [-40.275232092207517, -8.92732896327111],
              [-40.277076634850104, -8.926515263009378],
              [-40.281152385446219, -8.924657716651007],
              [-40.285208000928833, -8.922802642286641],
              [-40.289054164264762, -8.92095494767649],
              [-40.288449137067339, -8.915453311180075],
              [-40.288453291871527, -8.915393341848267],
              [-40.283459091705147, -8.915576195574099],
              [-40.283250624523724, -8.915565340250843],
              [-40.278814608387435, -8.913976759275247],
              [-40.275615041740217, -8.912817674225],
              [-40.275611817680669, -8.912884422777815],
              [-40.27545666783282, -8.912828888262391],
              [-40.275280391650888, -8.912765016120984],
              [-40.271020926552204, -8.911224830756288],
              [-40.267312488379993, -8.909898933943534],
              [-40.267049590198084, -8.907866047346275],
              [-40.266978896395294, -8.907908338289786],
              [-40.265644120600278, -8.905340583883019],
              [-40.265378242674153, -8.905398821014099],
              [-40.262132160839471, -8.906157615729152],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0009000',
        uf: 'PE',
        nome_proje: 'PA CURIMATÁ',
        municipio: 'PETROLINA',
        area_hecta: '337.5925',
        capacidade: 10.0,
        num_famili: 10.0,
        fase: 6.0,
        data_de_cr: '11/07/1991',
        forma_obte: 'Adjudicação',
        data_obten: '11/07/1991',
        area_calc_: 341.8246,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.725330839964919, -8.881422859343083],
              [-40.725112455172756, -8.881983522868632],
              [-40.722227621910527, -8.890136341761471],
              [-40.721964887083374, -8.890877221548646],
              [-40.721590606467615, -8.892028276394983],
              [-40.718794389826627, -8.900746125188],
              [-40.718347168026796, -8.902128521295579],
              [-40.715756834204065, -8.910216779455734],
              [-40.715056218577544, -8.912440731856314],
              [-40.715940019883988, -8.91268764002376],
              [-40.726870137894345, -8.915580587738729],
              [-40.726897807010317, -8.915161126901872],
              [-40.727684256954895, -8.905231287253576],
              [-40.727789791245513, -8.904297380506634],
              [-40.728854536561279, -8.895141807866443],
              [-40.728892812109507, -8.89479318119249],
              [-40.728634570383775, -8.894745104912692],
              [-40.729516676050139, -8.891036819166546],
              [-40.729647970894938, -8.890497623806867],
              [-40.730337891997245, -8.887742362307762],
              [-40.730919426575824, -8.885294372970447],
              [-40.728655567325312, -8.883731880038736],
              [-40.726340318612181, -8.882121508042104],
              [-40.725330839964919, -8.881422859343083],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0003000',
        uf: 'PE',
        nome_proje: 'PA FEDERAÇÃO',
        municipio: 'PETROLINA',
        area_hecta: '1756.9430',
        capacidade: 25.0,
        num_famili: 23.0,
        fase: 6.0,
        data_de_cr: '12/09/1989',
        forma_obte: 'Adjudicação',
        data_obten: '27/02/1989',
        area_calc_: 1763.9789,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.358835468566802, -8.895233253468911],
              [-40.356451946292069, -8.89810550635641],
              [-40.355393803154648, -8.900013219360289],
              [-40.353342187852142, -8.903816921134837],
              [-40.349492276452523, -8.910879013395622],
              [-40.347091202555774, -8.915061590770964],
              [-40.346425167687286, -8.916221840503002],
              [-40.344879245241813, -8.918802006764388],
              [-40.344439498926754, -8.918832896648581],
              [-40.344175428037268, -8.918835208392474],
              [-40.343315177027357, -8.918911927474017],
              [-40.342005118500822, -8.919004014428383],
              [-40.341781123362409, -8.919048771288061],
              [-40.33795611579594, -8.919389822633244],
              [-40.3374229205196, -8.919580646369022],
              [-40.335812007169615, -8.920515228273114],
              [-40.335360236191363, -8.921091702773099],
              [-40.335417580780629, -8.921131828636463],
              [-40.33609375177376, -8.921621235759295],
              [-40.342745184496287, -8.926473031661041],
              [-40.34206701129537, -8.927157038315153],
              [-40.342030031499142, -8.927190720722995],
              [-40.341647531178474, -8.927610241616362],
              [-40.340934363350101, -8.929046733174067],
              [-40.339921439372958, -8.931450438142475],
              [-40.339531952366684, -8.932448902461884],
              [-40.339199450155022, -8.933413431321592],
              [-40.338871718754689, -8.934514398604989],
              [-40.338575792422283, -8.935584234262011],
              [-40.338573792411758, -8.93558442233552],
              [-40.338573433274064, -8.935585689622581],
              [-40.337659025818958, -8.935686017609383],
              [-40.335645430946315, -8.946122607352256],
              [-40.33452153555308, -8.945993547247516],
              [-40.328022357321643, -8.945546492012395],
              [-40.327916353613453, -8.949544795251594],
              [-40.327901593083574, -8.950465045925915],
              [-40.334576167313081, -8.948557346436232],
              [-40.342526919494652, -8.946267558937619],
              [-40.347598470533804, -8.944787746882973],
              [-40.353644436997939, -8.943018020363738],
              [-40.356088056146014, -8.942257998348316],
              [-40.360929898117476, -8.940865850432404],
              [-40.361006949632063, -8.940774417031106],
              [-40.364168019853516, -8.939564730866021],
              [-40.368476426535914, -8.937566154391023],
              [-40.372216906207875, -8.935797873041292],
              [-40.375673091665597, -8.934277839755305],
              [-40.379040614175402, -8.932960572805586],
              [-40.381995438768207, -8.931886797006896],
              [-40.381989050668942, -8.931615270356341],
              [-40.378831998668069, -8.92085423113601],
              [-40.378807210820383, -8.920767332789199],
              [-40.375607378477575, -8.90959286984056],
              [-40.37258923232902, -8.898672497651889],
              [-40.371416761086827, -8.90001160902079],
              [-40.370775697512641, -8.900743986274653],
              [-40.369783514295634, -8.899927917353571],
              [-40.366036059718759, -8.897203732028087],
              [-40.364535710271703, -8.897473911984305],
              [-40.363476534879709, -8.897665608243623],
              [-40.362801849924786, -8.899784165282217],
              [-40.362077595568195, -8.9014342189539],
              [-40.36171883702891, -8.900785811458727],
              [-40.358835468566802, -8.895233253468911],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0239000',
        uf: 'PE',
        nome_proje: 'PA NOVA ESPERANÇA I',
        municipio: 'AFRANIO',
        area_hecta: '767.7931',
        capacidade: 15.0,
        num_famili: 15.0,
        fase: 3.0,
        data_de_cr: '26/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 765.736,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.885016843888458, -8.439100106973557],
              [-40.884895206971088, -8.439013087737219],
              [-40.884054043178935, -8.44136951730183],
              [-40.884137600342228, -8.442235393647744],
              [-40.883103979957852, -8.443346400032132],
              [-40.880775734147029, -8.447417851149012],
              [-40.88034080217637, -8.44758637905648],
              [-40.879058134634704, -8.447730587624871],
              [-40.877097395750802, -8.44898788124511],
              [-40.874187097663814, -8.449540831423677],
              [-40.873056223436386, -8.450529061559465],
              [-40.872190029029049, -8.451103314055867],
              [-40.869828179436155, -8.446330057842619],
              [-40.868387249637166, -8.446438552664663],
              [-40.867035193782904, -8.446713330371466],
              [-40.866278120278331, -8.44681186691699],
              [-40.862790573111432, -8.446927015646784],
              [-40.862687448446778, -8.447405311682672],
              [-40.862688103008544, -8.447541734024455],
              [-40.862716200825702, -8.451088209815689],
              [-40.863947069984, -8.452195903163517],
              [-40.864954581565591, -8.454646306110842],
              [-40.864775186652608, -8.45616347941373],
              [-40.864802238541152, -8.456539627374514],
              [-40.86512178568654, -8.457202962171007],
              [-40.867032840713371, -8.457452049375542],
              [-40.870926787636648, -8.46180916149412],
              [-40.871289297500454, -8.462302587682714],
              [-40.871445632324374, -8.462559771516045],
              [-40.872676073454834, -8.465782594735824],
              [-40.878835358180346, -8.467622374653903],
              [-40.879194000757714, -8.471618806535618],
              [-40.88245886205015, -8.475622295590115],
              [-40.895524716556842, -8.472203279921702],
              [-40.895416871325956, -8.472024166453037],
              [-40.893180624618573, -8.470317024846896],
              [-40.892755323045556, -8.469821038039402],
              [-40.895106793692726, -8.468318296165286],
              [-40.895561742284784, -8.467190894177351],
              [-40.896254405192934, -8.466507227117848],
              [-40.896372909494026, -8.465970543831716],
              [-40.896257128386388, -8.465505423843592],
              [-40.896082442379715, -8.465360988828856],
              [-40.895625998077897, -8.464734880508956],
              [-40.895500441765812, -8.464071727530348],
              [-40.895652514874527, -8.462823746789335],
              [-40.89391573601489, -8.462137212525448],
              [-40.891831219356369, -8.46056410961733],
              [-40.889887835031281, -8.459477329388307],
              [-40.887261853328624, -8.457914314985919],
              [-40.88769091704561, -8.455830271449027],
              [-40.890252195762365, -8.452522769996287],
              [-40.891110441493375, -8.450622349101604],
              [-40.89208057177877, -8.449621382872614],
              [-40.892904495585725, -8.444110387442164],
              [-40.892355645227561, -8.443687134573471],
              [-40.890629279523147, -8.443161529025497],
              [-40.888837782033335, -8.442442487537841],
              [-40.885016843888458, -8.439100106973557],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0206000',
        uf: 'PE',
        nome_proje: 'PA ESTRELA DALVA',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1320.6741',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 3.0,
        data_de_cr: '11/12/2006',
        forma_obte: 'Desapropriação',
        data_obten: '11/12/2006',
        area_calc_: 1320.6936,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.154187542015201, -8.765062895512894],
              [-40.157244722134379, -8.761522432992182],
              [-40.132975299324897, -8.71847274808257],
              [-40.132090268180065, -8.717931596965681],
              [-40.132058301805351, -8.716612326168725],
              [-40.082173397471102, -8.647302560765663],
              [-40.071853931955609, -8.640389945279637],
              [-40.071035229676596, -8.638912671819199],
              [-40.069628179034453, -8.629942089386677],
              [-40.038480759117562, -8.587645391802209],
              [-40.037954411494198, -8.588361096342044],
              [-40.037182268052824, -8.589410819967251],
              [-40.037179341540778, -8.589414798411955],
              [-40.037198646437453, -8.589419096277704],
              [-40.036328641385239, -8.590571278072813],
              [-40.036033202757402, -8.590920359283544],
              [-40.064842284278186, -8.631154158533473],
              [-40.067788811879794, -8.644558000287864],
              [-40.078055109913365, -8.651383958502118],
              [-40.128337033240634, -8.721223682467073],
              [-40.154187542015201, -8.765062895512894],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0207000',
        uf: 'PE',
        nome_proje: 'PA BEGARD',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1320.0169',
        capacidade: 20.0,
        num_famili: 19.0,
        fase: 3.0,
        data_de_cr: '11/12/2006',
        forma_obte: 'Desapropriação',
        data_obten: '11/12/2006',
        area_calc_: 1320.0542,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.036033202757402, -8.590920359283544],
              [-40.035221166305639, -8.591879832528322],
              [-40.035200281247619, -8.59187564667992],
              [-40.034457883119011, -8.592549503540139],
              [-40.03362422887902, -8.593306093199065],
              [-40.060005043834337, -8.632379246041879],
              [-40.063725604644212, -8.648726920913651],
              [-40.073771131664358, -8.655779967192064],
              [-40.124215299352961, -8.724779003516439],
              [-40.151101499798429, -8.768636707933283],
              [-40.154187542015201, -8.765062895512894],
              [-40.128337033240634, -8.721223682467073],
              [-40.078055109913365, -8.651383958502118],
              [-40.067788811879794, -8.644558000287864],
              [-40.064842284278186, -8.631154158533473],
              [-40.036033202757402, -8.590920359283544],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0181000',
        uf: 'PE',
        nome_proje: 'PA POCINHOS',
        municipio: 'LAGOA GRANDE',
        area_hecta: '1182.1902',
        capacidade: 10.0,
        num_famili: 9.0,
        fase: 3.0,
        data_de_cr: '31/07/2006',
        forma_obte: 'Arrecadação',
        data_obten: '31/07/2006',
        area_calc_: 1182.5815,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.270556116144199, -8.749990101258915],
              [-40.270121965760318, -8.749983148132975],
              [-40.270121965453754, -8.749983057702877],
              [-40.263297427261136, -8.743609683218846],
              [-40.263297427565831, -8.743609773649215],
              [-40.260103407566511, -8.740629932499305],
              [-40.259472965698521, -8.740064570553487],
              [-40.259848716021963, -8.742875108808706],
              [-40.258771558565634, -8.743215999115321],
              [-40.250736094101335, -8.733826128282992],
              [-40.251804311821452, -8.733507448683982],
              [-40.252315596128845, -8.733454304944379],
              [-40.250803622526085, -8.732051089880123],
              [-40.248999441268516, -8.73010253003098],
              [-40.248304120444402, -8.732321396093266],
              [-40.243374805781038, -8.725556263558344],
              [-40.240169694945635, -8.721049567732113],
              [-40.237516481664422, -8.717902495468628],
              [-40.236856113854024, -8.717722876985068],
              [-40.234225217117171, -8.717954078186979],
              [-40.231380013293375, -8.71760845396509],
              [-40.229823480235616, -8.717323207258035],
              [-40.229286349101528, -8.718362660099642],
              [-40.228408830385845, -8.721267120911062],
              [-40.228424380733664, -8.722743564374415],
              [-40.230079529543417, -8.725298080814142],
              [-40.230273605606058, -8.726914024355715],
              [-40.231425947800737, -8.728715320103593],
              [-40.232286012528448, -8.729028770263819],
              [-40.235011686297568, -8.732082220047197],
              [-40.235579345578977, -8.73614856119306],
              [-40.235421203153351, -8.736843506564771],
              [-40.236252333401296, -8.740203160918702],
              [-40.236331446177012, -8.740847413831634],
              [-40.237422359162082, -8.741886265579367],
              [-40.238080864512654, -8.742947506988456],
              [-40.240169186297464, -8.743156899660372],
              [-40.243404369884402, -8.74435754174679],
              [-40.243770410700506, -8.746466481075572],
              [-40.244407647333539, -8.748012945251707],
              [-40.244307257925215, -8.749342806483419],
              [-40.247541877223554, -8.75182853309375],
              [-40.249222959314046, -8.751828685152617],
              [-40.250178246149211, -8.751266390962854],
              [-40.252280193308088, -8.751514007496631],
              [-40.252623125359825, -8.751668686510365],
              [-40.254443290837578, -8.752758237371783],
              [-40.254481255323768, -8.752911754889219],
              [-40.258773837169521, -8.753554756472973],
              [-40.260560695078397, -8.753248575597279],
              [-40.26172001947306, -8.753654094761728],
              [-40.267971724042965, -8.754127945960622],
              [-40.281648760038358, -8.759651763663648],
              [-40.290147940945154, -8.754270193408431],
              [-40.28791612904201, -8.742238326587907],
              [-40.290954175171848, -8.741067854250009],
              [-40.294818638533485, -8.743117384199833],
              [-40.296602481157251, -8.749116394438612],
              [-40.299917758988322, -8.748674758591182],
              [-40.299547991919816, -8.747101729600226],
              [-40.301044662602081, -8.742224306146619],
              [-40.305488411169854, -8.742747711124512],
              [-40.301096436557593, -8.737261232331806],
              [-40.29845344925689, -8.734401666717183],
              [-40.298049696618818, -8.734925374241602],
              [-40.295281103180493, -8.738219181897568],
              [-40.287272077508774, -8.736579107383404],
              [-40.287222865033463, -8.73110767862855],
              [-40.284227587178258, -8.734240856641375],
              [-40.284492148451029, -8.735188488398535],
              [-40.284232354076423, -8.735979280824356],
              [-40.284381434736154, -8.737789379590723],
              [-40.284461630794624, -8.738726602977936],
              [-40.281026471540365, -8.747164661718744],
              [-40.270556116144199, -8.749990101258915],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0263000',
        uf: 'PE',
        nome_proje: 'PA VERA LÚCIA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '956.8522',
        capacidade: 17.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '01/09/2008',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2008',
        area_calc_: 957.3115,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.090547054346963, -8.907869288109522],
              [-40.112795753087333, -8.868310519261783],
              [-40.112496215941185, -8.867854440308372],
              [-40.112151441518876, -8.867322891508335],
              [-40.111913186705763, -8.8669249604483],
              [-40.110456218513676, -8.864661890378157],
              [-40.109280166275049, -8.864313225648177],
              [-40.106102229251974, -8.863370353648461],
              [-40.102984917052545, -8.862444908796641],
              [-40.092421886350664, -8.859427677992977],
              [-40.080137471535927, -8.898943629393141],
              [-40.080921768785814, -8.899111943734917],
              [-40.083958019686861, -8.901874446708987],
              [-40.089960540502247, -8.907335682603815],
              [-40.090547054346963, -8.907869288109522],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0077000',
        uf: 'PE',
        nome_proje: 'PA SÃO PEDRO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1472.0328',
        capacidade: 40.0,
        num_famili: 40.0,
        fase: 4.0,
        data_de_cr: '11/03/2003',
        forma_obte: 'Desapropriação',
        data_obten: '15/07/2002',
        area_calc_: 1421.9971,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.780980375956062, -8.341528634975694],
              [-39.781066617133675, -8.341542664262272],
              [-39.780659510661131, -8.340725831474517],
              [-39.780196150414987, -8.339796322203634],
              [-39.779537037716615, -8.338474117175014],
              [-39.777555446844353, -8.33450089835026],
              [-39.780164224034763, -8.332073535323731],
              [-39.78469803460203, -8.334909947639106],
              [-39.784729635633504, -8.334909071136499],
              [-39.785142267882826, -8.335172177062741],
              [-39.785755943269436, -8.335563408532435],
              [-39.785942528595847, -8.335682428013307],
              [-39.787976020008301, -8.337004426325413],
              [-39.787983203442288, -8.337009024812529],
              [-39.791089931882425, -8.339050180011267],
              [-39.791129124132894, -8.339075878977425],
              [-39.793765175237787, -8.340836752067407],
              [-39.796490836117101, -8.342664355313902],
              [-39.800519554988092, -8.34035611283482],
              [-39.80455879945572, -8.338067981594804],
              [-39.801849434907737, -8.333718498764874],
              [-39.799995840571057, -8.33280918763923],
              [-39.79998675079414, -8.33280495500021],
              [-39.799269578667413, -8.332476272040067],
              [-39.795875342411399, -8.330920636854595],
              [-39.795401815827319, -8.33072305675382],
              [-39.790662616940885, -8.32944529326196],
              [-39.788662250256507, -8.328912382615966],
              [-39.788617914032137, -8.329038281294237],
              [-39.785940725111232, -8.327361650509422],
              [-39.787110545243927, -8.326359179774521],
              [-39.787137563143638, -8.326337328700758],
              [-39.787222243449776, -8.326268874004208],
              [-39.787223574525179, -8.326253314616842],
              [-39.787244704197981, -8.326011419555122],
              [-39.783458236162659, -8.323619364633966],
              [-39.779819860128754, -8.321333524509447],
              [-39.776015947418855, -8.31893907256674],
              [-39.772429220474763, -8.316693033068098],
              [-39.768462778162132, -8.314216599798989],
              [-39.765017063384597, -8.31206237811848],
              [-39.760960448475522, -8.30953918303754],
              [-39.757414182266992, -8.307329364651629],
              [-39.754108098994486, -8.305242425890622],
              [-39.75394772328999, -8.305141157579984],
              [-39.753583150668973, -8.30491093643586],
              [-39.753513145710691, -8.304866840312398],
              [-39.753278673948529, -8.304718769892578],
              [-39.753122208133234, -8.304620026330667],
              [-39.752867098896161, -8.304458970295348],
              [-39.748681088375292, -8.301858366775352],
              [-39.745620712303158, -8.299959107836541],
              [-39.743379674706361, -8.303697227488607],
              [-39.74114237327683, -8.30741543215084],
              [-39.741113948354311, -8.307462608076266],
              [-39.738706534925846, -8.311429939227413],
              [-39.738363968928915, -8.31199234301115],
              [-39.738265472008692, -8.312154064599985],
              [-39.738117635845057, -8.312396737563699],
              [-39.737156017685599, -8.313975287877339],
              [-39.737014789475531, -8.314207365948416],
              [-39.736920637134823, -8.314362024343186],
              [-39.736644063509118, -8.314815948879607],
              [-39.739347413235883, -8.317076998499326],
              [-39.73966892866472, -8.317345933349825],
              [-39.740125326668156, -8.317727675890898],
              [-39.741549373608038, -8.318920463234184],
              [-39.741849427946214, -8.319171889314909],
              [-39.742049706993541, -8.319339747295418],
              [-39.743598280981672, -8.320636760164328],
              [-39.747260210156341, -8.323709615323221],
              [-39.75034964257086, -8.326264618884357],
              [-39.753848819729292, -8.329179609675942],
              [-39.757310578097112, -8.332065964433742],
              [-39.760921648242203, -8.33507518630698],
              [-39.763846140183816, -8.337528179448366],
              [-39.767261284512529, -8.34037346031692],
              [-39.767350068449034, -8.340449987232956],
              [-39.768406048875022, -8.341329615560362],
              [-39.769928548231007, -8.342116350934829],
              [-39.771290511583011, -8.342820000569144],
              [-39.77203675105094, -8.343205573797393],
              [-39.772046842881146, -8.34320364956038],
              [-39.775005239112836, -8.344732119760836],
              [-39.778210389831571, -8.342642150181081],
              [-39.777154155474847, -8.341219408052664],
              [-39.778082591359301, -8.341057226103674],
              [-39.78089996647951, -8.341515554343186],
              [-39.780964009837255, -8.341503341328499],
              [-39.780980375956062, -8.341528634975694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0247000',
        uf: 'PE',
        nome_proje: 'PA NÉ LARANJEIRA',
        municipio: 'PARNAMIRIM',
        area_hecta: '664.6824',
        capacidade: 44.0,
        num_famili: 44.0,
        fase: 3.0,
        data_de_cr: '29/10/2007',
        forma_obte: 'Doação',
        data_obten: '29/10/2007',
        area_calc_: 672.705,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.94110075169219, -8.234038913584213],
              [-39.941100767650703, -8.234038904388676],
              [-39.941100712477954, -8.234039052366155],
              [-39.941100654721183, -8.234039199361694],
              [-39.94110059439798, -8.234039345330654],
              [-39.941100531526729, -8.234039490228504],
              [-39.941100466126564, -8.234039634011159],
              [-39.941100398217401, -8.234039776634811],
              [-39.94110032781996, -8.234039918055947],
              [-39.941100254955671, -8.234040058231605],
              [-39.941100179646703, -8.234040197119032],
              [-39.941100101916049, -8.234040334675836],
              [-39.941100021787349, -8.234040470860204],
              [-39.94109993928501, -8.2340406056306],
              [-39.941099854434199, -8.234040738946065],
              [-39.941099767260738, -8.234040870765867],
              [-39.941099677791179, -8.234041001049926],
              [-39.941099586052793, -8.234041129758541],
              [-39.941099492073533, -8.23404125685239],
              [-39.941099395882013, -8.234041382292911],
              [-39.941099297507527, -8.234041506041892],
              [-39.941099196980041, -8.234041628061528],
              [-39.941099094330198, -8.234041748314631],
              [-39.941098989589278, -8.234041866764764],
              [-39.941098882789163, -8.234041983375695],
              [-39.941098773962388, -8.234042098111807],
              [-39.941098663142121, -8.234042210938382],
              [-39.941098550362099, -8.234042321820908],
              [-39.941098435656713, -8.234042430725578],
              [-39.941098319060863, -8.234042537619326],
              [-39.941098200610092, -8.23404264246952],
              [-39.941098080340495, -8.234042745244174],
              [-39.94109795828868, -8.234042845912164],
              [-39.941097834491842, -8.234042944442542],
              [-39.941097708987719, -8.234043040805494],
              [-39.941097581814496, -8.234043134971706],
              [-39.941097453010919, -8.234043226912307],
              [-39.94109732261628, -8.23404331659939],
              [-39.941097190670241, -8.2340434040057],
              [-39.941097057213021, -8.234043489104479],
              [-39.941096922285269, -8.234043571869929],
              [-39.941096785928103, -8.234043652276714],
              [-39.941096648183034, -8.234043730300442],
              [-39.941096509092077, -8.234043805917262],
              [-39.941096368697529, -8.234043879104165],
              [-39.941096227042202, -8.234043949838863],
              [-39.941096084169246, -8.234044018099901],
              [-39.941095940122167, -8.234044083866312],
              [-39.941095794944879, -8.234044147118148],
              [-39.941095648681568, -8.234044207836176],
              [-39.941095501376836, -8.234044266001909],
              [-39.941095353075504, -8.234044321597498],
              [-39.941095203822769, -8.234044374606132],
              [-39.941095053664128, -8.234044425011682],
              [-39.94109490264529, -8.23404447279867],
              [-39.941094750812269, -8.234044517952615],
              [-39.941094598211308, -8.234044560459733],
              [-39.941094444888904, -8.234044600307104],
              [-39.941094290891769, -8.234044637482551],
              [-39.941094136266805, -8.234044671974784],
              [-39.941093981061123, -8.234044703773339],
              [-39.941093825321992, -8.234044732868412],
              [-39.941093669096865, -8.234044759251198],
              [-39.941093512433355, -8.234044782913692],
              [-39.941093355379138, -8.234044803848652],
              [-39.94109319798207, -8.234044822049773],
              [-39.941093040290127, -8.234044837511336],
              [-39.941092882351313, -8.234044850228839],
              [-39.941092724213782, -8.234044860198235],
              [-39.941092565925658, -8.23404486741665],
              [-39.941092407535194, -8.23404487188175],
              [-39.94109224909063, -8.234044873592202],
              [-39.941092090640218, -8.234044872547567],
              [-39.941091932232261, -8.234044868747993],
              [-39.941091773914991, -8.234044862194819],
              [-39.941091615736653, -8.234044852889987],
              [-39.941091457745415, -8.234044840836233],
              [-39.941091299989388, -8.234044826037362],
              [-39.941091142516669, -8.234044808497867],
              [-39.941090985375212, -8.234044788223061],
              [-39.941090828612886, -8.234044765219075],
              [-39.941090672277468, -8.23404473949293],
              [-39.94109051641653, -8.23404471105248],
              [-39.941090361077606, -8.234044679906491],
              [-39.941090206307969, -8.234044646064238],
              [-39.941090052154806, -8.234044609536204],
              [-39.941089898665069, -8.23404457033338],
              [-39.941089745885492, -8.234044528467923],
              [-39.94108959386265, -8.234044483952388],
              [-39.941089442642834, -8.234044436800424],
              [-39.941089292272103, -8.234044387026314],
              [-39.941089142796265, -8.234044334645267],
              [-39.94108899426088, -8.234044279673304],
              [-39.941088846711182, -8.234044222127176],
              [-39.941088700192097, -8.23404416202427],
              [-39.941088554748291, -8.234044099383004],
              [-39.941088410424058, -8.23404403422235],
              [-39.94108826726336, -8.2340439665623],
              [-39.941088125309818, -8.234043896423477],
              [-39.941087984606661, -8.234043823827102],
              [-39.941087845195682, -8.234043748796498],
              [-39.941087871492208, -8.234043737253895],
              [-39.93906017676084, -8.232929612034289],
              [-39.939060225237206, -8.232929574629114],
              [-39.939060193678699, -8.232929557206043],
              [-39.939060162189904, -8.232929539658146],
              [-39.939060130771395, -8.232929521985739],
              [-39.939060099423628, -8.232929504189098],
              [-39.9390600681471, -8.232929486268382],
              [-39.939060036942301, -8.232929468224016],
              [-39.93906000580975, -8.232929450056318],
              [-39.93905997474991, -8.232929431765438],
              [-39.93905994376329, -8.232929413351767],
              [-39.939059912850375, -8.23292939481553],
              [-39.939059882011648, -8.232929376157022],
              [-39.939059851247599, -8.232929357376623],
              [-39.939059820558704, -8.232929338474582],
              [-39.939059789945482, -8.23292931945106],
              [-39.939059759408366, -8.232929300306537],
              [-39.939059728947875, -8.232929281041368],
              [-39.939059698564485, -8.232929261655633],
              [-39.939059668258658, -8.232929242149805],
              [-39.939059638030891, -8.23292922252414],
              [-39.939059607881674, -8.232929202778882],
              [-39.939059577811442, -8.232929182914534],
              [-39.939059547820698, -8.232929162931139],
              [-39.939059517909911, -8.232929142829276],
              [-39.939059488079565, -8.232929122609082],
              [-39.939059458330114, -8.23292910227098],
              [-39.939059428662013, -8.232929081815248],
              [-39.939059399075774, -8.232929061242228],
              [-39.939059369571822, -8.232929040552269],
              [-39.939059340150649, -8.232929019745493],
              [-39.939059310812723, -8.232928998822503],
              [-39.939059281558485, -8.232928977783576],
              [-39.939059252388425, -8.232928956628911],
              [-39.939059223302962, -8.232928935358951],
              [-39.939059194302601, -8.232928913974002],
              [-39.939059165387775, -8.232928892474362],
              [-39.939059136558953, -8.232928870860464],
              [-39.939059107816561, -8.232928849132593],
              [-39.939059079161098, -8.232928827291097],
              [-39.939059050592988, -8.23292880533627],
              [-39.939059022112687, -8.232928783268539],
              [-39.93905899372065, -8.232928761088269],
              [-39.93905896541731, -8.232928738795732],
              [-39.939058937203143, -8.232928716391262],
              [-39.939058909078547, -8.232928693875277],
              [-39.939058881044048, -8.232928671248096],
              [-39.939058853099979, -8.232928648510098],
              [-39.939058825246875, -8.232928625661746],
              [-39.939058797485131, -8.232928602703184],
              [-39.939058769815198, -8.232928579634962],
              [-39.939058742237513, -8.232928556457292],
              [-39.939058714752491, -8.232928533170675],
              [-39.9390586873606, -8.232928509775405],
              [-39.939058660062258, -8.232928486271849],
              [-39.939058632857893, -8.232928462660423],
              [-39.939058605747924, -8.232928438941535],
              [-39.939058578732791, -8.232928415115433],
              [-39.939058551812948, -8.232928391182536],
              [-39.939058524988759, -8.232928367143252],
              [-39.9390584982607, -8.232928342998017],
              [-39.939058471629174, -8.232928318747097],
              [-39.939058445094602, -8.232928294390966],
              [-39.93905841865741, -8.232928269929856],
              [-39.939058392317989, -8.232928245364409],
              [-39.939058366076793, -8.232928220694754],
              [-39.93905833993422, -8.232928195921451],
              [-39.939058313890669, -8.232928171044838],
              [-39.939058287946587, -8.232928146065342],
              [-39.939058262102328, -8.232928120983306],
              [-39.939058236358377, -8.232928095799117],
              [-39.939058210715061, -8.232928070513211],
              [-39.939058185172826, -8.232928045125956],
              [-39.93905815973207, -8.232928019637807],
              [-39.939058134393221, -8.232927994049119],
              [-39.939058109156633, -8.232927968360324],
              [-39.939058084022726, -8.232927942571814],
              [-39.939058058991883, -8.232927916683957],
              [-39.939058034064523, -8.232927890697237],
              [-39.939058009241037, -8.232927864612078],
              [-39.939057984521796, -8.232927838428749],
              [-39.93905795990721, -8.232927812147819],
              [-39.939057935397663, -8.232927785769613],
              [-39.939057910993547, -8.23292775929454],
              [-39.939057886695217, -8.232927732723118],
              [-39.93905786250312, -8.232927706055674],
              [-39.939057838417568, -8.232927679292617],
              [-39.939057814438982, -8.232927652434443],
              [-39.939057790567716, -8.232927625481514],
              [-39.939057766804176, -8.232927598434364],
              [-39.939057743148737, -8.23292757129326],
              [-39.939057719601749, -8.232927544058761],
              [-39.939057696163587, -8.232927516731202],
              [-39.939057672834629, -8.232927489311139],
              [-39.939057649615229, -8.232927461798919],
              [-39.939057626505793, -8.232927434194934],
              [-39.939057603506647, -8.232927406499671],
              [-39.939057580618169, -8.232927378713637],
              [-39.939057557840719, -8.232927350837098],
              [-39.939057535174669, -8.232927322870657],
              [-39.939057512620323, -8.232927294815038],
              [-39.939057538451564, -8.232927266681374],
              [-39.93784926223632, -8.231418151408487],
              [-39.937849231040474, -8.231418133408496],
              [-39.937849132972346, -8.231418013049897],
              [-39.937849032847929, -8.231417894383471],
              [-39.937848930696646, -8.231417777444079],
              [-39.937848826548503, -8.231417662266043],
              [-39.937848720434111, -8.231417548883261],
              [-39.937848612384634, -8.231417437329061],
              [-39.937848502431812, -8.231417327636171],
              [-39.937848390607968, -8.231417219836807],
              [-39.937848276945914, -8.231417113962586],
              [-39.937848161479074, -8.231417010044776],
              [-39.93784804424137, -8.231416908113806],
              [-39.937847925267228, -8.23141680819958],
              [-39.937847804591613, -8.231416710331525],
              [-39.937847682249966, -8.23141661453834],
              [-39.937847558278236, -8.231416520848224],
              [-39.937847432712857, -8.231416429288659],
              [-39.937847305590687, -8.231416339886557],
              [-39.937847176949091, -8.231416252668202],
              [-39.93784704682588, -8.231416167659226],
              [-39.937846915259243, -8.231416084884474],
              [-39.937846782287856, -8.231416004368477],
              [-39.937846647950778, -8.23141592613471],
              [-39.937846512287486, -8.231415850206181],
              [-39.937846375337813, -8.231415776605317],
              [-39.937846237142011, -8.231415705353518],
              [-39.937846097740668, -8.231415636472081],
              [-39.937845957174744, -8.23141556998085],
              [-39.937845815485538, -8.23141550589958],
              [-39.93784567271468, -8.231415444247034],
              [-39.937845528904091, -8.231415385041416],
              [-39.937845384096029, -8.23141532829999],
              [-39.937845238333054, -8.231415274039461],
              [-39.937845091657984, -8.231415222275755],
              [-39.937844944113891, -8.231415173024192],
              [-39.937844795744127, -8.231415126299114],
              [-39.937844646592303, -8.23141508211434],
              [-39.937844496702191, -8.231415040482725],
              [-39.937844346117885, -8.231415001416638],
              [-39.93784419488356, -8.231414964927424],
              [-39.937844043043704, -8.231414931025922],
              [-39.937843890642895, -8.23141489972206],
              [-39.937843737725913, -8.23141487102507],
              [-39.937843584337678, -8.231414844943266],
              [-39.937843430523259, -8.231414821484384],
              [-39.937843276327833, -8.231414800655285],
              [-39.937843121796718, -8.231414782462098],
              [-39.937842966975289, -8.231414766910282],
              [-39.93784281190905, -8.231414754004209],
              [-39.937842656643554, -8.231414743747811],
              [-39.937842501224409, -8.231414736144052],
              [-39.937842345697263, -8.231414731195118],
              [-39.937842190107837, -8.231414728902651],
              [-39.937842034501813, -8.231414729267048],
              [-39.937841878924921, -8.231414732288409],
              [-39.937841723422849, -8.231414737965734],
              [-39.937841568041328, -8.231414746297444],
              [-39.937841412825946, -8.231414757281085],
              [-39.937841257822328, -8.231414770913341],
              [-39.937841103076018, -8.231414787190218],
              [-39.937840948632477, -8.231414806107061],
              [-39.937840794537067, -8.231414827658153],
              [-39.937840640835063, -8.231414851837307],
              [-39.93784048757162, -8.231414878637292],
              [-39.937840334791773, -8.231414908050406],
              [-39.937840182540398, -8.231414940067745],
              [-39.937840030862219, -8.231414974680103],
              [-39.937839879801807, -8.231415011877306],
              [-39.937839729403535, -8.231415051648325],
              [-39.937839579711572, -8.231415093981596],
              [-39.937839430769927, -8.231415138864554],
              [-39.937839282622349, -8.23141518628416],
              [-39.937839135312345, -8.23141523622629],
              [-39.937838988883193, -8.231415288676445],
              [-39.937838843377925, -8.231415343619226],
              [-39.937838698839279, -8.231415401038326],
              [-39.937838555309717, -8.23141546091694],
              [-39.937838412831411, -8.231415523237592],
              [-39.937838271446218, -8.231415587981795],
              [-39.937838131195669, -8.231415655130752],
              [-39.937837992120976, -8.231415724664485],
              [-39.937837854262995, -8.231415796562718],
              [-39.937837717662212, -8.2314158708043],
              [-39.937837582358782, -8.231415947367489],
              [-39.937837448392415, -8.231416026229583],
              [-39.937837315802525, -8.23141610736765],
              [-39.937837184628009, -8.231416190757674],
              [-39.937837054907448, -8.231416276375359],
              [-39.937836926678926, -8.231416364195363],
              [-39.937836799980126, -8.231416454191923],
              [-39.937836674848263, -8.23141654633862],
              [-39.937836551320096, -8.231416640608435],
              [-39.937836429431918, -8.231416736973586],
              [-39.937836309219556, -8.231416835405909],
              [-39.937836190718308, -8.231416935876307],
              [-39.937836073962984, -8.231417038355257],
              [-39.937835958987876, -8.231417142812841],
              [-39.937835845826783, -8.231417249218214],
              [-39.937835734512937, -8.231417357540193],
              [-39.937835625079067, -8.231417467746947],
              [-39.937835642809155, -8.231417459661046],
              [-39.936760699279482, -8.232518672040682],
              [-39.936760690354404, -8.232518708319299],
              [-39.936760637055833, -8.232518762465851],
              [-39.936760583303389, -8.232518816165433],
              [-39.936760529100866, -8.232518869414079],
              [-39.936760474452058, -8.23251892220804],
              [-39.936760419360823, -8.232518974543668],
              [-39.936760363831041, -8.232519026417249],
              [-39.936760307866606, -8.23251907782517],
              [-39.936760251471441, -8.232519128763808],
              [-39.936760194649537, -8.232519179229577],
              [-39.936760137404875, -8.232519229218809],
              [-39.936760079741482, -8.232519278728235],
              [-39.936760021663382, -8.232519327754199],
              [-39.936759963174715, -8.232519376293272],
              [-39.936759904279555, -8.232519424342021],
              [-39.936759844982063, -8.232519471897183],
              [-39.936759785286391, -8.232519518955328],
              [-39.936759725196751, -8.232519565513121],
              [-39.936759664717343, -8.232519611567389],
              [-39.93675960385243, -8.232519657114743],
              [-39.936759542606325, -8.232519702152159],
              [-39.936759480983284, -8.232519746676285],
              [-39.936759418987677, -8.232519790684218],
              [-39.936759356623853, -8.232519834172596],
              [-39.936759293896181, -8.232519877138623],
              [-39.936759230809081, -8.232519919579094],
              [-39.936759167367001, -8.23251996149105],
              [-39.936759103574381, -8.232520002871651],
              [-39.936759039435714, -8.232520043717898],
              [-39.936758974955502, -8.232520084026909],
              [-39.936758910138309, -8.232520123795856],
              [-39.93675884498866, -8.232520163022032],
              [-39.936758779511138, -8.232520201702577],
              [-39.93675871371039, -8.232520239834843],
              [-39.936758647590977, -8.232520277416016],
              [-39.936758581157584, -8.232520314443608],
              [-39.936758514414876, -8.232520350914971],
              [-39.936758447367552, -8.232520386827575],
              [-39.936758380020294, -8.232520422178792],
              [-39.936758312377883, -8.232520456966204],
              [-39.936758244445059, -8.232520491187339],
              [-39.936758176226583, -8.23252052483978],
              [-39.936758107727272, -8.232520557921204],
              [-39.936758038951936, -8.232520590429314],
              [-39.936757969905386, -8.232520622361776],
              [-39.936757900592518, -8.232520653716259],
              [-39.936757831018177, -8.232520684490728],
              [-39.936757761187259, -8.232520714682895],
              [-39.936757691104674, -8.232520744290747],
              [-39.936757620775367, -8.232520773312014],
              [-39.936757550204248, -8.232520801744901],
              [-39.93675747939632, -8.232520829587202],
              [-39.936757408356527, -8.232520856837088],
              [-39.936757337089894, -8.232520883492592],
              [-39.936757265601386, -8.232520909551754],
              [-39.936757193896092, -8.232520935012968],
              [-39.936757121978999, -8.232520959874222],
              [-39.936757049855181, -8.232520984133911],
              [-39.936756977529726, -8.23252100779025],
              [-39.936756905007698, -8.232521030841522],
              [-39.936756832294201, -8.2325210532863],
              [-39.936756759394356, -8.232521075122825],
              [-39.936756686313274, -8.232521096349631],
              [-39.936756613056112, -8.232521116965197],
              [-39.936756539627993, -8.23252113696814],
              [-39.936756466034112, -8.232521156357018],
              [-39.936756392279619, -8.232521175130403],
              [-39.93675631836971, -8.232521193287088],
              [-39.936756244309585, -8.232521210825764],
              [-39.936756170104445, -8.232521227745151],
              [-39.936756095759513, -8.232521244044065],
              [-39.93675602127999, -8.232521259721397],
              [-39.936755946671163, -8.232521274775985],
              [-39.936755871938239, -8.232521289206808],
              [-39.936755797086469, -8.232521303012845],
              [-39.936755722121141, -8.232521316193203],
              [-39.936755647047505, -8.232521328746868],
              [-39.936755571870854, -8.232521340672909],
              [-39.936755496596476, -8.232521351970592],
              [-39.936755421229648, -8.232521362639211],
              [-39.936755345775659, -8.232521372677732],
              [-39.936755270239857, -8.232521382085686],
              [-39.936755194627501, -8.232521390862249],
              [-39.93675511894395, -8.232521399006975],
              [-39.936755043194509, -8.232521406519142],
              [-39.936754967384474, -8.232521413398297],
              [-39.936754891519243, -8.232521419643975],
              [-39.936754815604104, -8.232521425255742],
              [-39.936754739644392, -8.232521430233081],
              [-39.936754663645452, -8.232521434575778],
              [-39.936754587612647, -8.232521438283483],
              [-39.9367545115513, -8.232521441355876],
              [-39.936754435466753, -8.232521443792866],
              [-39.936754359364393, -8.232521445594086],
              [-39.936754283249549, -8.232521446759623],
              [-39.936754207127557, -8.232521447289367],
              [-39.936754131003781, -8.23252144718311],
              [-39.936754054883579, -8.232521446441041],
              [-39.936753978772302, -8.23252144506313],
              [-39.936753902675733, -8.232521443048384],
              [-39.936753896984939, -8.232521491645548],
              [-39.935609824844079, -8.232486353126266],
              [-39.935609802217485, -8.232486383858848],
              [-39.935609779147583, -8.232486383181159],
              [-39.935609756076047, -8.232486382561829],
              [-39.935609733002984, -8.232486382000927],
              [-39.935609709928592, -8.23248638149855],
              [-39.935609686852956, -8.232486381054519],
              [-39.935609663776276, -8.232486380668993],
              [-39.935609640698694, -8.232486380341893],
              [-39.935609617620351, -8.232486380073237],
              [-39.935609594541383, -8.232486379863087],
              [-39.935609571461974, -8.232486379711361],
              [-39.93560954838226, -8.232486379618077],
              [-39.935609525302368, -8.232486379583198],
              [-39.935609502222441, -8.232486379606861],
              [-39.935609479142677, -8.232486379688929],
              [-39.935609456063183, -8.232486379829439],
              [-39.93560943298413, -8.232486380028467],
              [-39.935609409905659, -8.232486380285804],
              [-39.935609386827913, -8.23248638060176],
              [-39.935609363751041, -8.232486380976038],
              [-39.935609340675214, -8.232486381408872],
              [-39.935609317600559, -8.232486381899992],
              [-39.935609294527247, -8.232486382449633],
              [-39.935609271455384, -8.232486383057722],
              [-39.935609248385163, -8.232486383724302],
              [-39.935609225316725, -8.232486384449217],
              [-39.935609202250205, -8.232486385232548],
              [-39.935609179185754, -8.232486386074315],
              [-39.935609156123526, -8.2324863869745],
              [-39.935609133063679, -8.232486387933086],
              [-39.935609110006325, -8.232486388950122],
              [-39.935609086951686, -8.232486390025558],
              [-39.935609063899847, -8.232486391159345],
              [-39.935609040850942, -8.232486392351511],
              [-39.935609017805177, -8.232486393602027],
              [-39.935608994762703, -8.232486394910875],
              [-39.935608971723624, -8.232486396278135],
              [-39.935608948688106, -8.232486397703729],
              [-39.935608925656304, -8.232486399187653],
              [-39.935608902628367, -8.232486400729904],
              [-39.93560887960443, -8.232486402330485],
              [-39.935608856584665, -8.232486403989363],
              [-39.935608833569198, -8.232486405706503],
              [-39.935608810558165, -8.23248640748195],
              [-39.935608787551772, -8.232486409315747],
              [-39.935608764550125, -8.232486411207782],
              [-39.935608741553381, -8.232486413157913],
              [-39.935608718561667, -8.232486415166402],
              [-39.935608695575176, -8.232486417233138],
              [-39.935608672594029, -8.232486419358095],
              [-39.935608649618374, -8.232486421541156],
              [-39.93560862664836, -8.232486423782499],
              [-39.935608603684145, -8.232486426081945],
              [-39.935608580725891, -8.232486428439563],
              [-39.935608557773698, -8.232486430855321],
              [-39.935608534827757, -8.232486433329184],
              [-39.935608511888205, -8.232486435861135],
              [-39.935608488955189, -8.232486438451209],
              [-39.93560846602886, -8.232486441099335],
              [-39.935608443109331, -8.232486443805598],
              [-39.935608420196822, -8.232486446569832],
              [-39.935608397291425, -8.232486449392086],
              [-39.935608374393311, -8.232486452272303],
              [-39.935608351502594, -8.232486455210575],
              [-39.935608328619495, -8.232486458206711],
              [-39.935608305744069, -8.232486461260899],
              [-39.935608282876544, -8.232486464372959],
              [-39.93560826001702, -8.232486467542978],
              [-39.935608237165667, -8.23248647077083],
              [-39.935608214322613, -8.232486474056563],
              [-39.935608191488029, -8.232486477400158],
              [-39.935608168662043, -8.232486480801537],
              [-39.93560814584481, -8.232486484260777],
              [-39.935608123036495, -8.232486487777782],
              [-39.935608100237218, -8.232486491352461],
              [-39.935608077447142, -8.232486494984919],
              [-39.935608054666389, -8.232486498675055],
              [-39.935608031895143, -8.232486502422967],
              [-39.935608009133531, -8.232486506228474],
              [-39.935607986381704, -8.232486510091549],
              [-39.935607963639825, -8.232486514012322],
              [-39.935607940908, -8.232486517990683],
              [-39.935607918186413, -8.23248652202664],
              [-39.935607895475194, -8.232486526120097],
              [-39.93560787277449, -8.232486530271011],
              [-39.935607850084459, -8.232486534479445],
              [-39.935607827405242, -8.232486538745302],
              [-39.935607804736982, -8.232486543068632],
              [-39.935607782079821, -8.232486547449378],
              [-39.935607759433921, -8.23248655188746],
              [-39.935607736799412, -8.232486556382828],
              [-39.935607714176456, -8.232486560935515],
              [-39.935607691565181, -8.232486565545518],
              [-39.935607668965773, -8.232486570212719],
              [-39.935607646378315, -8.232486574937255],
              [-39.935607623802994, -8.232486579718922],
              [-39.935607601239965, -8.232486584557751],
              [-39.935607578689329, -8.232486589453668],
              [-39.935607556151261, -8.232486594406723],
              [-39.935607533625927, -8.232486599416827],
              [-39.935607555852549, -8.232486629741549],
              [-39.934595463568122, -8.232713014080513],
              [-39.934595488225, -8.232713044040597],
              [-39.934595413796146, -8.232713061021778],
              [-39.934595339513272, -8.232713078625986],
              [-39.934595265381638, -8.232713096851993],
              [-39.934595191406515, -8.232713115698422],
              [-39.934595117593119, -8.232713135164113],
              [-39.934595043946658, -8.232713155247406],
              [-39.934594970472361, -8.232713175947211],
              [-39.934594897175408, -8.23271319726188],
              [-39.934594824061001, -8.232713219189867],
              [-39.934594751134306, -8.232713241729749],
              [-39.934594678400458, -8.232713264879894],
              [-39.934594605864646, -8.232713288638552],
              [-39.934594533531964, -8.232713313004153],
              [-39.934594461407556, -8.232713337974973],
              [-39.934594389496532, -8.232713363549269],
              [-39.934594317803914, -8.232713389725133],
              [-39.934594246334832, -8.23271341650074],
              [-39.934594175094325, -8.232713443874283],
              [-39.934594104087438, -8.232713471843738],
              [-39.9345940333192, -8.232713500407145],
              [-39.934593962794594, -8.232713529562572],
              [-39.934593892518635, -8.232713559307816],
              [-39.934593822496268, -8.232713589640776],
              [-39.934593752732475, -8.232713620559403],
              [-39.934593683232151, -8.232713652061506],
              [-39.934593614000249, -8.232713684144812],
              [-39.934593545041665, -8.232713716807096],
              [-39.93459347636125, -8.232713750045965],
              [-39.934593407963881, -8.232713783859111],
              [-39.934593339854388, -8.232713818244106],
              [-39.934593272037617, -8.232713853198652],
              [-39.934593204518329, -8.232713888720125],
              [-39.934593137301306, -8.232713924806067],
              [-39.934593070391315, -8.232713961453966],
              [-39.934593003793083, -8.232713998661126],
              [-39.934592937511326, -8.232714036425062],
              [-39.934592871550727, -8.232714074743015],
              [-39.934592805915969, -8.232714113612181],
              [-39.934592740611656, -8.232714153030033],
              [-39.934592675642435, -8.232714192993635],
              [-39.934592611012917, -8.23271423350011],
              [-39.934592546727636, -8.232714274546717],
              [-39.934592482791182, -8.23271431613057],
              [-39.934592419208016, -8.232714358248552],
              [-39.934592355982694, -8.232714400897894],
              [-39.934592293119685, -8.23271444407545],
              [-39.934592230623394, -8.232714487778095],
              [-39.934592168498277, -8.232714532002902],
              [-39.934592106748724, -8.232714576746714],
              [-39.934592045379077, -8.232714622006334],
              [-39.934591984393727, -8.232714667778499],
              [-39.93459192379693, -8.232714714060039],
              [-39.934591863593013, -8.232714760847683],
              [-39.934591803786219, -8.232714808138091],
              [-39.934591744380782, -8.232714855927989],
              [-39.934591685380909, -8.232714904213919],
              [-39.93459162679077, -8.232714952992465],
              [-39.934591568614515, -8.232715002260221],
              [-39.934591510856258, -8.232715052013665],
              [-39.934591453520042, -8.232715102249287],
              [-39.934591396609989, -8.232715152963538],
              [-39.934591340130105, -8.232715204152813],
              [-39.934591284084348, -8.232715255813513],
              [-39.934591228476727, -8.232715307941996],
              [-39.934591173311155, -8.232715360534641],
              [-39.934591118591534, -8.232715413587529],
              [-39.934591064321751, -8.232715467097027],
              [-39.934591010505628, -8.232715521059403],
              [-39.934590957146973, -8.232715575470815],
              [-39.934590904249568, -8.232715630327329],
              [-39.934590851817148, -8.232715685625147],
              [-39.934590799853439, -8.232715741360193],
              [-39.934590748362083, -8.232715797528845],
              [-39.934590697346749, -8.232715854126898],
              [-39.934590646811067, -8.232715911150345],
              [-39.934590596758561, -8.232715968595224],
              [-39.934590547192776, -8.232716026457451],
              [-39.934590498117274, -8.232716084732957],
              [-39.93459044953547, -8.232716143417571],
              [-39.934590401450812, -8.232716202507092],
              [-39.934590353866717, -8.232716261997549],
              [-39.934590306786561, -8.232716321884444],
              [-39.934590260213625, -8.232716382163836],
              [-39.934590214151264, -8.232716442831228],
              [-39.934590168602682, -8.232716503882463],
              [-39.934590123571134, -8.232716565313215],
              [-39.934590079059781, -8.232716627119043],
              [-39.934590035071814, -8.232716689295597],
              [-39.934589991610324, -8.232716751838613],
              [-39.934589948678379, -8.232716814743505],
              [-39.934589906278987, -8.232716878005952],
              [-39.934589864415216, -8.23271694162141],
              [-39.934589823089965, -8.232717005585378],
              [-39.934589782306176, -8.232717069893297],
              [-39.934589742066763, -8.232717134540637],
              [-39.934589702374531, -8.232717199522888],
              [-39.934589663232316, -8.23271726483541],
              [-39.934589624642861, -8.23271733047363],
              [-39.934589586608908, -8.232717396432864],
              [-39.93458957270807, -8.232717369065176],
              [-39.934141749147877, -8.233501649617956],
              [-39.934141764589832, -8.233501633390667],
              [-39.934141750028651, -8.233501658987405],
              [-39.934141735551115, -8.233501684630985],
              [-39.934141721157417, -8.233501710321523],
              [-39.934141706847676, -8.233501736058495],
              [-39.934141692622028, -8.23350176184166],
              [-39.934141678480664, -8.233501787670864],
              [-39.934141664423713, -8.233501813545725],
              [-39.93414165045133, -8.233501839466046],
              [-39.934141636563652, -8.233501865431498],
              [-39.934141622760833, -8.233501891441803],
              [-39.934141609043024, -8.233501917496667],
              [-39.934141595410374, -8.233501943595911],
              [-39.934141581862995, -8.233501969739196],
              [-39.934141568401081, -8.233501995926247],
              [-39.934141555024731, -8.233502022156669],
              [-39.934141541734128, -8.23350204843042],
              [-39.934141528529381, -8.233502074747097],
              [-39.934141515410623, -8.233502101106447],
              [-39.934141502378004, -8.233502127508117],
              [-39.934141489431667, -8.233502153951889],
              [-39.934141476571753, -8.233502180437526],
              [-39.93414146379839, -8.233502206964653],
              [-39.934141451111728, -8.233502233533006],
              [-39.934141438511872, -8.233502260142412],
              [-39.934141425998966, -8.233502286792385],
              [-39.934141413573151, -8.233502313482859],
              [-39.934141401234562, -8.233502340213489],
              [-39.934141388983328, -8.233502366983942],
              [-39.934141376819575, -8.233502393793916],
              [-39.934141364743411, -8.233502420643212],
              [-39.934141352754992, -8.233502447531485],
              [-39.93414134085446, -8.233502474458417],
              [-39.9341413290419, -8.233502501423859],
              [-39.93414131731744, -8.233502528427453],
              [-39.934141305681223, -8.233502555468815],
              [-39.934141294133383, -8.233502582547773],
              [-39.93414128267402, -8.233502609663976],
              [-39.934141271303261, -8.233502636817239],
              [-39.934141260021228, -8.233502664007203],
              [-39.934141248828034, -8.233502691233584],
              [-39.9341412377238, -8.233502718496009],
              [-39.934141226708647, -8.233502745794418],
              [-39.934141215782695, -8.233502773128263],
              [-39.934141204946052, -8.233502800497428],
              [-39.934141194198844, -8.2335028279015],
              [-39.934141183541179, -8.233502855340348],
              [-39.934141172973163, -8.233502882813623],
              [-39.93414116249491, -8.233502910320871],
              [-39.934141152106534, -8.233502937861937],
              [-39.934141141808148, -8.233502965436609],
              [-39.934141131599851, -8.233502993044526],
              [-39.934141121481758, -8.233503020685351],
              [-39.934141111453989, -8.23350304835872],
              [-39.934141101516609, -8.233503076064569],
              [-39.93414109166978, -8.233503103802359],
              [-39.934141081913566, -8.233503131572027],
              [-39.934141072248075, -8.233503159373138],
              [-39.934141062673426, -8.233503187205338],
              [-39.934141053189698, -8.233503215068543],
              [-39.934141043797013, -8.233503242962261],
              [-39.934141034495447, -8.233503270886263],
              [-39.934141025285122, -8.233503298840342],
              [-39.93414101616613, -8.23350332682406],
              [-39.93414100713855, -8.233503354837129],
              [-39.934140998202516, -8.233503382879398],
              [-39.93414098935807, -8.233503410950464],
              [-39.934140980605342, -8.233503439050024],
              [-39.934140971944416, -8.233503467177822],
              [-39.934140963375377, -8.233503495333439],
              [-39.934140954898325, -8.233503523516809],
              [-39.934140946513345, -8.233503551727457],
              [-39.93414093822053, -8.233503579965115],
              [-39.934140930019979, -8.23350360822951],
              [-39.934140921911748, -8.233503636520377],
              [-39.934140913895924, -8.233503664837242],
              [-39.934140905972626, -8.233503693180033],
              [-39.934140898141933, -8.23350372154842],
              [-39.934140890403903, -8.233503749941864],
              [-39.934140882758619, -8.233503778360356],
              [-39.934140875206168, -8.2335038068034],
              [-39.934140867746642, -8.233503835270822],
              [-39.934140860380126, -8.233503863762184],
              [-39.934140853106655, -8.233503892277389],
              [-39.93414084592635, -8.233503920815895],
              [-39.934140838839276, -8.233503949377633],
              [-39.93414083184549, -8.233503977962165],
              [-39.934140824945089, -8.233504006569223],
              [-39.93414081813814, -8.233504035198401],
              [-39.934140811424705, -8.233504063849585],
              [-39.934140804804862, -8.233504092522281],
              [-39.934140798278676, -8.233504121216336],
              [-39.934140791846239, -8.233504149931367],
              [-39.93414078550758, -8.233504178667156],
              [-39.93414077926279, -8.233504207423275],
              [-39.934140773111935, -8.233504236199499],
              [-39.934140767055084, -8.233504264995512],
              [-39.934140761092287, -8.233504293810919],
              [-39.934140755223602, -8.23350432264558],
              [-39.934140749449135, -8.233504351499155],
              [-39.934140756867748, -8.233504365224151],
              [-39.933927532971886, -8.234578807052623],
              [-39.957057727810557, -8.235830945753301],
              [-39.958010723598122, -8.235322015488252],
              [-39.957548334100785, -8.235188537395995],
              [-39.957547970525418, -8.23518835737773],
              [-39.956080306048975, -8.234687003709038],
              [-39.956080279464928, -8.234686972869978],
              [-39.956080249316841, -8.234686962511507],
              [-39.956080219205433, -8.234686952047598],
              [-39.956080189131129, -8.234686941478378],
              [-39.95608015909427, -8.234686930803997],
              [-39.95608012909522, -8.234686920024609],
              [-39.956080099134383, -8.234686909140228],
              [-39.956080069212085, -8.234686898151107],
              [-39.956080039328754, -8.234686887057347],
              [-39.956080009484715, -8.234686875859012],
              [-39.956079979680332, -8.234686864556405],
              [-39.956079949916003, -8.23468685314951],
              [-39.956079920192067, -8.234686841638611],
              [-39.956079890508903, -8.234686830023692],
              [-39.956079860866872, -8.234686818304935],
              [-39.956079831266365, -8.234686806482507],
              [-39.956079801707709, -8.23468679455663],
              [-39.956079772191302, -8.234686782527454],
              [-39.956079742717478, -8.234686770394974],
              [-39.956079713286634, -8.234686758159379],
              [-39.956079683899098, -8.234686745820936],
              [-39.956079654555253, -8.234686733379643],
              [-39.956079625255455, -8.234686720835786],
              [-39.95607959600008, -8.234686708189535],
              [-39.956079566789469, -8.234686695440953],
              [-39.956079537624014, -8.234686682590176],
              [-39.956079508504011, -8.234686669637473],
              [-39.956079479429889, -8.234686656582859],
              [-39.956079450401951, -8.234686643426684],
              [-39.956079421420604, -8.234686630168937],
              [-39.956079392486181, -8.234686616809865],
              [-39.956079363599052, -8.234686603349637],
              [-39.956079334759536, -8.234686589788385],
              [-39.956079305968039, -8.234686576126348],
              [-39.95607927722488, -8.234686562363558],
              [-39.956079248530429, -8.234686548500298],
              [-39.956079219885055, -8.234686534536753],
              [-39.956079191289064, -8.234686520472975],
              [-39.956079162742867, -8.234686506309279],
              [-39.956079134246785, -8.234686492045734],
              [-39.956079105801159, -8.23468647768258],
              [-39.956079077406365, -8.234686463219909],
              [-39.95607904906273, -8.234686448657992],
              [-39.956079020770616, -8.234686433997],
              [-39.956078992530401, -8.234686419237132],
              [-39.956078964342367, -8.234686404378468],
              [-39.956078936206929, -8.234686389421199],
              [-39.956078908124383, -8.234686374365641],
              [-39.956078880095106, -8.234686359211842],
              [-39.956078852119454, -8.234686343960146],
              [-39.956078824197732, -8.234686328610554],
              [-39.95607879633031, -8.234686313163436],
              [-39.956078768517528, -8.234686297618762],
              [-39.956078740759729, -8.234686281976925],
              [-39.956078713057266, -8.234686266238089],
              [-39.956078685410446, -8.234686250402357],
              [-39.95607865781966, -8.234686234469979],
              [-39.956078630285198, -8.234686218441139],
              [-39.956078602807438, -8.234686202316043],
              [-39.956078575386712, -8.234686186094955],
              [-39.956078548023349, -8.234686169777921],
              [-39.956078520717696, -8.234686153365237],
              [-39.956078493470066, -8.234686136857183],
              [-39.956078466280829, -8.234686120253775],
              [-39.956078439150303, -8.234686103555331],
              [-39.95607841207881, -8.234686086762075],
              [-39.956078385066725, -8.234686069874231],
              [-39.95607835811434, -8.234686052891844],
              [-39.956078331221995, -8.234686035815342],
              [-39.956078304390026, -8.234686018644831],
              [-39.956078277618772, -8.234686001380444],
              [-39.956078250908575, -8.234685984022587],
              [-39.956078224259734, -8.234685966571249],
              [-39.956078197672589, -8.234685949026879],
              [-39.956078171147475, -8.234685931389487],
              [-39.95607814468471, -8.234685913659424],
              [-39.956078118284637, -8.234685895836851],
              [-39.95607809194756, -8.234685877922075],
              [-39.956078065673829, -8.234685859915151],
              [-39.956078039463733, -8.2346858418164],
              [-39.956078013317629, -8.234685823626021],
              [-39.95607798723583, -8.234685805344352],
              [-39.956077961218654, -8.234685786971442],
              [-39.956077935266421, -8.234685768507559],
              [-39.956077909379445, -8.234685749953089],
              [-39.956077883558066, -8.234685731308032],
              [-39.95607785780259, -8.23468571257281],
              [-39.956077832113316, -8.234685693747558],
              [-39.956077806490612, -8.234685674832487],
              [-39.956077780934727, -8.234685655827825],
              [-39.956077755446032, -8.234685636733921],
              [-39.956077730024809, -8.234685617550953],
              [-39.956077704671401, -8.2346855982791],
              [-39.956077679386091, -8.234685578918691],
              [-39.956077654169192, -8.234685559469877],
              [-39.956077629021053, -8.234685539932915],
              [-39.956077603941949, -8.234685520308105],
              [-39.956077578932188, -8.234685500595628],
              [-39.956077553992095, -8.23468548079577],
              [-39.956077529121934, -8.234685460909192],
              [-39.956077488022167, -8.234685472892826],
              [-39.953986672676216, -8.23300748684419],
              [-39.953986656014941, -8.233007488643239],
              [-39.953986631112812, -8.233007468586369],
              [-39.953986606281894, -8.233007448442066],
              [-39.953986581522429, -8.233007428210668],
              [-39.953986556834749, -8.233007407892275],
              [-39.953986532219176, -8.23300738748735],
              [-39.953986507676007, -8.233007366996056],
              [-39.953986483205519, -8.233007346418658],
              [-39.953986458808068, -8.233007325755455],
              [-39.953986434483916, -8.233007305006602],
              [-39.953986410233391, -8.233007284172364],
              [-39.953986386056762, -8.233007263252945],
              [-39.953986361954371, -8.233007242248883],
              [-39.953986337926494, -8.233007221160161],
              [-39.953986313973409, -8.233007199987163],
              [-39.953986290095479, -8.233007178730128],
              [-39.953986266292937, -8.233007157389274],
              [-39.95398624256611, -8.233007135964936],
              [-39.953986218915297, -8.233007114457395],
              [-39.953986195340775, -8.233007092866808],
              [-39.953986171842828, -8.233007071193578],
              [-39.953986148421777, -8.233007049437953],
              [-39.953986125077911, -8.23300702760009],
              [-39.953986101811502, -8.233007005680301],
              [-39.953986078622854, -8.233006983678948],
              [-39.953986055512246, -8.233006961596242],
              [-39.953986032479953, -8.233006939432423],
              [-39.95398600952629, -8.233006917187822],
              [-39.953985986651524, -8.233006894862731],
              [-39.953985963855963, -8.233006872457414],
              [-39.953985941139877, -8.233006849972055],
              [-39.953985918503506, -8.233006827407081],
              [-39.953985895947206, -8.233006804762701],
              [-39.953985873471204, -8.233006782039157],
              [-39.953985851075821, -8.233006759236751],
              [-39.95398582876129, -8.233006736355851],
              [-39.953985806527918, -8.233006713396675],
              [-39.95398578437598, -8.233006690359511],
              [-39.953985762305734, -8.23300666724468],
              [-39.953985740317478, -8.233006644052393],
              [-39.953985718411467, -8.233006620782955],
              [-39.953985696587992, -8.233006597436713],
              [-39.953985674847281, -8.23300657401394],
              [-39.953985653189697, -8.233006550514922],
              [-39.953985631615396, -8.233006526939903],
              [-39.953985610124739, -8.233006503289348],
              [-39.953985588717934, -8.233006479563238],
              [-39.953985567395257, -8.233006455762151],
              [-39.953985546157, -8.233006431886249],
              [-39.953985525003404, -8.233006407935909],
              [-39.95398550393476, -8.233006383911373],
              [-39.953985482951275, -8.233006359812986],
              [-39.953985462053268, -8.233006335640978],
              [-39.953985441240967, -8.233006311395689],
              [-39.953985420514648, -8.233006287077391],
              [-39.953985399874547, -8.233006262686347],
              [-39.953985379320955, -8.233006238223025],
              [-39.953985358854084, -8.233006213687597],
              [-39.953985338474226, -8.233006189080402],
              [-39.95398531818163, -8.233006164401838],
              [-39.953985297976523, -8.233006139651982],
              [-39.953985277859147, -8.233006114831362],
              [-39.953985257829835, -8.233006089940181],
              [-39.95398523788873, -8.233006064978779],
              [-39.953985218036145, -8.233006039947423],
              [-39.953985198272328, -8.233006014846504],
              [-39.953985178597485, -8.233005989676299],
              [-39.953985159011893, -8.233005964437101],
              [-39.953985139515794, -8.233005939129248],
              [-39.953985120109401, -8.233005913752983],
              [-39.953985100792991, -8.233005888308664],
              [-39.953985081566799, -8.233005862796766],
              [-39.95398506243103, -8.233005837217368],
              [-39.95398504338597, -8.233005811570893],
              [-39.953985024431816, -8.233005785857642],
              [-39.953985005568839, -8.233005760077901],
              [-39.953984986797245, -8.233005734232178],
              [-39.953984968117297, -8.233005708320569],
              [-39.953984949529172, -8.233005682343462],
              [-39.953984931033176, -8.233005656301176],
              [-39.953984912629494, -8.233005630194116],
              [-39.953984894318353, -8.233005604022523],
              [-39.953984876099987, -8.233005577786757],
              [-39.953984857974639, -8.233005551487228],
              [-39.953984839942507, -8.233005525124032],
              [-39.953984822003839, -8.233005498697676],
              [-39.95398480415885, -8.233005472208433],
              [-39.953984786407766, -8.233005445656715],
              [-39.953984768750793, -8.233005419042769],
              [-39.953984751188159, -8.233005392366888],
              [-39.953984733720098, -8.233005365629479],
              [-39.953984716346817, -8.233005338830896],
              [-39.953984699068499, -8.233005311971372],
              [-39.953984681885409, -8.233005285051316],
              [-39.953984664797744, -8.233005258071055],
              [-39.953984647805697, -8.233005231030885],
              [-39.953984630909503, -8.233005203931155],
              [-39.953984614109366, -8.233005176772314],
              [-39.953984597405501, -8.233005149554559],
              [-39.953984580798014, -8.233005122278772],
              [-39.953984578998359, -8.233005140305512],
              [-39.953476264440354, -8.232166947939609],
              [-39.953476284876096, -8.232166955912065],
              [-39.953476263044728, -8.23216691972201],
              [-39.9534762413832, -8.232166883430846],
              [-39.953476219891947, -8.23216684703938],
              [-39.953476198571508, -8.232166810548264],
              [-39.953476177422267, -8.232166773958516],
              [-39.953476156444736, -8.232166737270704],
              [-39.953476135639342, -8.232166700485799],
              [-39.953476115006566, -8.23216666360443],
              [-39.953476094546858, -8.232166626627585],
              [-39.9534760742606, -8.232166589556003],
              [-39.953476054148304, -8.2321665523904],
              [-39.953476034210404, -8.23216651513169],
              [-39.953476014447311, -8.232166477780757],
              [-39.953475994859467, -8.232166440338233],
              [-39.95347597544729, -8.232166402804966],
              [-39.953475956211214, -8.232166365181891],
              [-39.953475937151651, -8.232166327469788],
              [-39.953475918269014, -8.232166289669445],
              [-39.95347589956372, -8.232166251781653],
              [-39.953475881036191, -8.232166213807272],
              [-39.953475862686801, -8.232166175747174],
              [-39.953475844515964, -8.232166137602089],
              [-39.95347582652407, -8.232166099373],
              [-39.953475808711524, -8.232166061060571],
              [-39.953475791078723, -8.232166022665742],
              [-39.95347577362601, -8.232165984189383],
              [-39.953475756353797, -8.232165945632275],
              [-39.953475739262451, -8.232165906995141],
              [-39.953475722352366, -8.232165868279088],
              [-39.95347570562388, -8.232165829484725],
              [-39.953475689077386, -8.23216579061296],
              [-39.953475672713196, -8.232165751664722],
              [-39.953475656531722, -8.232165712640775],
              [-39.953475640533291, -8.232165673541971],
              [-39.953475624718251, -8.232165634369194],
              [-39.953475609086965, -8.232165595123275],
              [-39.953475593639737, -8.232165555805103],
              [-39.953475578376931, -8.232165516415606],
              [-39.953475563298895, -8.232165476955453],
              [-39.953475548405919, -8.232165437425589],
              [-39.953475533698352, -8.232165397826911],
              [-39.9534755191765, -8.232165358160335],
              [-39.953475504840704, -8.232165318426571],
              [-39.953475490691226, -8.232165278626601],
              [-39.953475476728443, -8.232165238761262],
              [-39.953475462952618, -8.232165198831426],
              [-39.953475449364056, -8.232165158837962],
              [-39.953475435963043, -8.232165118781715],
              [-39.953475422749882, -8.232165078663504],
              [-39.953475409724874, -8.232165038484364],
              [-39.953475396888287, -8.232164998245079],
              [-39.953475384240413, -8.23216495794655],
              [-39.953475371781522, -8.232164917589623],
              [-39.95347535951187, -8.232164877175155],
              [-39.953475347431748, -8.232164836704086],
              [-39.953475335541384, -8.232164796177296],
              [-39.953475323841076, -8.232164755595708],
              [-39.953475312331079, -8.23216471496003],
              [-39.953475301011629, -8.232164674271354],
              [-39.953475289882967, -8.23216463353041],
              [-39.953475278945319, -8.232164592738155],
              [-39.953475268198972, -8.232164551895476],
              [-39.953475257644136, -8.232164511003273],
              [-39.953475247281027, -8.232164470062431],
              [-39.953475237109892, -8.23216442907383],
              [-39.953475227130937, -8.232164388038395],
              [-39.953475217344383, -8.232164346956987],
              [-39.953475207750465, -8.232164305830562],
              [-39.953475198349359, -8.232164264659861],
              [-39.953475189141294, -8.232164223445945],
              [-39.953475180126453, -8.23216418218961],
              [-39.953475171305037, -8.232164140891847],
              [-39.953475162677265, -8.232164099553458],
              [-39.953475154243286, -8.232164058175464],
              [-39.953475146003292, -8.232164016758659],
              [-39.95347513795749, -8.232163975304019],
              [-39.953475130106021, -8.232163933812291],
              [-39.953475122449056, -8.232163892284674],
              [-39.953475114986809, -8.232163850721758],
              [-39.953475107719392, -8.232163809124637],
              [-39.953475100647005, -8.23216376749423],
              [-39.953475093769754, -8.232163725831258],
              [-39.953475087087824, -8.232163684136875],
              [-39.953475080601343, -8.232163642411837],
              [-39.953475074310475, -8.23216360065704],
              [-39.953475068215326, -8.232163558873443],
              [-39.953475062316052, -8.23216351706199],
              [-39.953475056612774, -8.232163475223571],
              [-39.953475051105613, -8.232163433359082],
              [-39.953475045794704, -8.23216339146936],
              [-39.953475040680154, -8.232163349555474],
              [-39.953475035762061, -8.232163307618242],
              [-39.95347503104054, -8.232163265658556],
              [-39.953475026515711, -8.232163223677416],
              [-39.953475022187632, -8.232163181675661],
              [-39.953475018056452, -8.232163139654267],
              [-39.953475014122233, -8.23216309761411],
              [-39.953475010385041, -8.232163055556031],
              [-39.953475006845146, -8.232163013480505],
              [-39.953474984153907, -8.232163061938973],
              [-39.95330075603809, -8.230032100552522],
              [-39.95330074907821, -8.230032123274636],
              [-39.953300747560185, -8.230032104468901],
              [-39.95330074608151, -8.230032085660122],
              [-39.953300744642242, -8.230032066848253],
              [-39.953300743242352, -8.23003204803349],
              [-39.953300741881868, -8.230032029215838],
              [-39.953300740560799, -8.23003201039535],
              [-39.953300739279136, -8.230031991572272],
              [-39.953300738036901, -8.230031972746472],
              [-39.953300736834066, -8.230031953918107],
              [-39.953300735670652, -8.230031935087316],
              [-39.953300734546687, -8.230031916254163],
              [-39.953300733462143, -8.23003189741876],
              [-39.953300732417077, -8.230031878581107],
              [-39.953300731411424, -8.230031859741338],
              [-39.953300730445228, -8.230031840899484],
              [-39.953300729518489, -8.230031822055658],
              [-39.953300728631206, -8.23003180320991],
              [-39.9533007277834, -8.230031784362454],
              [-39.953300726975044, -8.230031765513189],
              [-39.95330072620618, -8.230031746662377],
              [-39.953300725476758, -8.230031727809942],
              [-39.953300724786835, -8.230031708956048],
              [-39.953300724136383, -8.230031690100796],
              [-39.953300723525409, -8.230031671244189],
              [-39.953300722953941, -8.230031652386307],
              [-39.953300722421936, -8.230031633527354],
              [-39.953300721929423, -8.230031614667364],
              [-39.953300721476417, -8.230031595806304],
              [-39.953300721062895, -8.230031576944446],
              [-39.953300720688873, -8.230031558081661],
              [-39.953300720354356, -8.230031539218247],
              [-39.953300720059318, -8.2300315203541],
              [-39.953300719803792, -8.230031501489323],
              [-39.953300719587766, -8.23003148262416],
              [-39.953300719411239, -8.23003146375857],
              [-39.953300719274225, -8.230031444892681],
              [-39.953300719176703, -8.230031426026533],
              [-39.953300719118687, -8.230031407160205],
              [-39.953300719100184, -8.230031388293767],
              [-39.953300719121188, -8.230031369427351],
              [-39.953300719181676, -8.230031350560999],
              [-39.953300719281678, -8.230031331694921],
              [-39.953300719421186, -8.230031312829018],
              [-39.953300719600193, -8.230031293963391],
              [-39.953300719818706, -8.230031275098296],
              [-39.953300720076733, -8.230031256233557],
              [-39.953300720374237, -8.230031237369454],
              [-39.953300720711241, -8.230031218506083],
              [-39.953300721087743, -8.230031199643422],
              [-39.953300721503759, -8.230031180781562],
              [-39.953300721959273, -8.230031161920605],
              [-39.953300722454244, -8.230031143060648],
              [-39.953300722988736, -8.230031124201691],
              [-39.953300723562691, -8.23003110534397],
              [-39.953300724176145, -8.230031086487438],
              [-39.953300724829077, -8.230031067632263],
              [-39.953300725521501, -8.230031048778441],
              [-39.953300726253389, -8.230031029926158],
              [-39.953300727024747, -8.230031011075399],
              [-39.95330072783559, -8.230030992226267],
              [-39.953300728685882, -8.23003097337884],
              [-39.953300729575638, -8.230030954533348],
              [-39.953300730504857, -8.230030935689607],
              [-39.95330073147354, -8.230030916847882],
              [-39.953300732481651, -8.230030898008218],
              [-39.953300733529225, -8.230030879170741],
              [-39.953300734616242, -8.230030860335422],
              [-39.953300735742694, -8.230030841502369],
              [-39.953300736908567, -8.230030822671745],
              [-39.953300738113889, -8.230030803843558],
              [-39.953300739358603, -8.230030785018009],
              [-39.953300740642753, -8.230030766194975],
              [-39.953300741966309, -8.230030747374746],
              [-39.953300743329279, -8.230030728557235],
              [-39.953300744731635, -8.230030709742582],
              [-39.95330074617339, -8.230030690930931],
              [-39.953300747654524, -8.230030672122272],
              [-39.95330074917505, -8.230030653316859],
              [-39.95330075073494, -8.230030634514527],
              [-39.953300752334194, -8.23003061571551],
              [-39.953300753972812, -8.230030596919855],
              [-39.953300755650794, -8.230030578127645],
              [-39.953300757368105, -8.230030559338941],
              [-39.953300759124758, -8.230030540553857],
              [-39.95330076092074, -8.230030521772482],
              [-39.953300762756051, -8.230030502994905],
              [-39.953300764630661, -8.230030484221126],
              [-39.953300766544594, -8.230030465451316],
              [-39.953300768497812, -8.230030446685587],
              [-39.953300770490337, -8.230030427923809],
              [-39.953300772522134, -8.230030409166286],
              [-39.953300774593195, -8.230030390413015],
              [-39.953300776703543, -8.230030371664167],
              [-39.953300778853105, -8.230030352919673],
              [-39.953300781041939, -8.230030334179702],
              [-39.953300783270002, -8.230030315444321],
              [-39.953300785537294, -8.230030296713561],
              [-39.953300787843801, -8.230030277987662],
              [-39.953300790189502, -8.230030259267027],
              [-39.953300751713428, -8.23003029170679],
              [-39.953452843613647, -8.228826860656584],
              [-39.951442346471921, -8.227805099929375],
              [-39.951442372595523, -8.227805065402958],
              [-39.951442219551431, -8.227804985784509],
              [-39.951442068054845, -8.227804903280886],
              [-39.951441918160583, -8.227804817921907],
              [-39.951441769922852, -8.22780472973856],
              [-39.951441623395276, -8.227804638762644],
              [-39.951441478630841, -8.227804545027112],
              [-39.951441335681906, -8.227804448565793],
              [-39.951441194600193, -8.22780434941374],
              [-39.951441055436689, -8.227804247606626],
              [-39.951440918241765, -8.227804143181425],
              [-39.951440783065031, -8.227804036175744],
              [-39.951440649955394, -8.227803926628413],
              [-39.951440518960993, -8.227803814579177],
              [-39.951440390129186, -8.227803700068186],
              [-39.951440263506584, -8.227803583137158],
              [-39.951440139138995, -8.227803463828401],
              [-39.951440017071391, -8.22780334218484],
              [-39.951439897347917, -8.227803218250706],
              [-39.951439780011889, -8.227803092070658],
              [-39.951439665105731, -8.227802963690451],
              [-39.951439552671033, -8.227802833156479],
              [-39.951439442748431, -8.227802700515854],
              [-39.951439335377692, -8.227802565816777],
              [-39.951439230597657, -8.227802429107706],
              [-39.951439128446225, -8.227802290438314],
              [-39.951439028960316, -8.227802149858622],
              [-39.951438932175961, -8.227802007419546],
              [-39.951438838128134, -8.227801863172557],
              [-39.951438746850833, -8.227801717169744],
              [-39.951438658377107, -8.227801569464154],
              [-39.951438572738958, -8.227801420108968],
              [-39.951438489967352, -8.227801269158361],
              [-39.951438410092216, -8.227801116666898],
              [-39.951438333142434, -8.227800962689692],
              [-39.951438259145874, -8.22780080728236],
              [-39.951438188129259, -8.227800650501324],
              [-39.951438120118304, -8.227800492403089],
              [-39.951438055137572, -8.227800333044994],
              [-39.951437993210625, -8.227800172484617],
              [-39.951437934359809, -8.227800010779921],
              [-39.951437878606427, -8.227799847989559],
              [-39.951437825970643, -8.227799684172334],
              [-39.951437776471508, -8.227799519387549],
              [-39.951437730126919, -8.227799353694763],
              [-39.951437686953625, -8.227799187153812],
              [-39.951437646967264, -8.227799019825158],
              [-39.951437610182253, -8.227798851769057],
              [-39.951437576611958, -8.227798683046521],
              [-39.951437546268487, -8.227798513718474],
              [-39.951437519162809, -8.227798343846105],
              [-39.951437495304731, -8.227798173490948],
              [-39.951437474702914, -8.227798002714561],
              [-39.951437457364776, -8.227797831578828],
              [-39.951437443296605, -8.227797660145482],
              [-39.951437432503475, -8.22779748847662],
              [-39.95143742498928, -8.227797316634327],
              [-39.951437420756776, -8.22779714468073],
              [-39.951437419807483, -8.227796972678085],
              [-39.951437422141716, -8.227796800688484],
              [-39.951437427758655, -8.227796628774284],
              [-39.951437436656249, -8.227796456997602],
              [-39.9514374488313, -8.227796285420448],
              [-39.951437464279387, -8.22779611410513],
              [-39.951437482994955, -8.227795943113399],
              [-39.951437504971203, -8.227795772507186],
              [-39.951437530200188, -8.227795602348083],
              [-39.951437558672787, -8.227795432697839],
              [-39.95143759037871, -8.227795263617788],
              [-39.951437625306482, -8.22779509516886],
              [-39.951437663443464, -8.227794927412198],
              [-39.951437704775877, -8.22779476040836],
              [-39.951437749288765, -8.227794594217899],
              [-39.951437796966019, -8.22779442890074],
              [-39.951437847790402, -8.227794264516772],
              [-39.951437901743525, -8.227794101125488],
              [-39.95143795880589, -8.227793938785947],
              [-39.951438018956836, -8.227793777556757],
              [-39.95143808217464, -8.227793617496445],
              [-39.951438148436409, -8.227793458662726],
              [-39.951438217718177, -8.227793301113199],
              [-39.951438289994897, -8.227793144904616],
              [-39.951438365240413, -8.227792990093764],
              [-39.951438443427527, -8.227792836736411],
              [-39.951438524527944, -8.227792684888154],
              [-39.951438608512333, -8.227792534603877],
              [-39.951438695350348, -8.22779238593783],
              [-39.951438785010524, -8.22779223894398],
              [-39.951438877460475, -8.227792093675417],
              [-39.951438972666757, -8.227791950184651],
              [-39.951439070594915, -8.227791808523607],
              [-39.95143917120955, -8.227791668743469],
              [-39.951439274474254, -8.227791530894807],
              [-39.951439380351694, -8.227791395027582],
              [-39.951439488803558, -8.227791261190767],
              [-39.951439599790632, -8.227791129433008],
              [-39.951439713272791, -8.227790999801702],
              [-39.951439829208937, -8.227790872343896],
              [-39.951439947557191, -8.227790747105633],
              [-39.95144006827509, -8.227790624131853],
              [-39.951440042360396, -8.227790634527276],
              [-39.952216391690079, -8.227014695418374],
              [-39.952216394277073, -8.227014688335833],
              [-39.952216474709502, -8.227014606921761],
              [-39.952216554103352, -8.227014524502103],
              [-39.952216632445975, -8.227014441090034],
              [-39.95221670972488, -8.22701435669892],
              [-39.952216785927703, -8.227014271342071],
              [-39.952216861042324, -8.227014185033189],
              [-39.952216935056747, -8.227014097786055],
              [-39.952217007959177, -8.22701400961461],
              [-39.952217079737956, -8.22701392053288],
              [-39.952217150381642, -8.227013830555109],
              [-39.952217219878996, -8.227013739695542],
              [-39.95221728821889, -8.227013647968864],
              [-39.952217355390452, -8.227013555389503],
              [-39.952217421382962, -8.227013461972392],
              [-39.952217486185873, -8.227013367732354],
              [-39.952217549788863, -8.227013272684429],
              [-39.952217612181762, -8.227013176843846],
              [-39.95221767335466, -8.22701308022571],
              [-39.952217733297765, -8.227012982845642],
              [-39.952217792001541, -8.227012884719093],
              [-39.952217849456581, -8.227012785861724],
              [-39.952217905653768, -8.22701268628934],
              [-39.952217960584122, -8.227012586017752],
              [-39.952218014238838, -8.227012485062906],
              [-39.952218066609412, -8.227012383441112],
              [-39.952218117687451, -8.227012281168378],
              [-39.952218167464842, -8.227012178261109],
              [-39.952218215933605, -8.227012074735789],
              [-39.95221826308606, -8.227011970608807],
              [-39.952218308914624, -8.227011865896793],
              [-39.952218353412043, -8.227011760616547],
              [-39.952218396571176, -8.227011654784757],
              [-39.952218438385138, -8.227011548418423],
              [-39.952218478847307, -8.227011441534287],
              [-39.952218517951167, -8.227011334149736],
              [-39.952218555690514, -8.227011226281649],
              [-39.952218592059324, -8.227011117947219],
              [-39.952218627051799, -8.227011009163842],
              [-39.952218660662353, -8.227010899948931],
              [-39.952218692885616, -8.227010790319765],
              [-39.952218723716442, -8.227010680293906],
              [-39.952218753149921, -8.227010569888879],
              [-39.952218781181394, -8.227010459122305],
              [-39.952218807806318, -8.227010348011859],
              [-39.952218833020503, -8.227010236575197],
              [-39.95221885681989, -8.22701012483023],
              [-39.952218879200707, -8.227010012794759],
              [-39.952218900159387, -8.227009900486546],
              [-39.952218919692555, -8.227009787923578],
              [-39.952218937797134, -8.227009675123787],
              [-39.952218954470219, -8.227009562105268],
              [-39.952218969709136, -8.227009448885919],
              [-39.952218983511472, -8.227009335483856],
              [-39.952218995875008, -8.227009221917148],
              [-39.95221900679779, -8.227009108203989],
              [-39.952219016278065, -8.227008994362375],
              [-39.952219024314324, -8.227008880410605],
              [-39.95221903090529, -8.227008766366843],
              [-39.952219036049904, -8.22700865224915],
              [-39.952219039747362, -8.227008538075941],
              [-39.95221904199704, -8.227008423865191],
              [-39.952219042798617, -8.227008309635348],
              [-39.952219042151931, -8.22700819540451],
              [-39.952219040057109, -8.227008081190846],
              [-39.952219036514478, -8.227007967012726],
              [-39.952219031524606, -8.227007852888335],
              [-39.952219025088283, -8.227007738835718],
              [-39.952219017206531, -8.227007624873265],
              [-39.952219007880629, -8.227007511019069],
              [-39.952218997112041, -8.227007397291283],
              [-39.952218984902501, -8.227007283708],
              [-39.952218971253934, -8.227007170287495],
              [-39.952218956168544, -8.227007057047748],
              [-39.952218939648745, -8.227006944006853],
              [-39.952218921697124, -8.227006831182788],
              [-39.952218902316602, -8.227006718593731],
              [-39.952218881510213, -8.227006606257406],
              [-39.952218859281345, -8.227006494191873],
              [-39.952218835633481, -8.227006382414974],
              [-39.952218810570422, -8.227006270944509],
              [-39.952218784096168, -8.227006159798357],
              [-39.95221875621494, -8.227006048994152],
              [-39.952218726931171, -8.227005938549615],
              [-39.952218696249545, -8.227005828482273],
              [-39.952218664174985, -8.227005718809878],
              [-39.952218630712544, -8.227005609549829],
              [-39.952218595867613, -8.227005500719468],
              [-39.952218559645736, -8.227005392336267],
              [-39.952218522052675, -8.227005284417496],
              [-39.952218483094448, -8.22700517698031],
              [-39.952218442777266, -8.22700507004196],
              [-39.952218401107537, -8.227004963619359],
              [-39.952218358091955, -8.227004857729579],
              [-39.952218313737347, -8.227004752389512],
              [-39.952218268050785, -8.22700464761599],
              [-39.95221822103958, -8.227004543425666],
              [-39.952218172711241, -8.227004439835113],
              [-39.952218123073436, -8.227004336860963],
              [-39.952218072134102, -8.227004234519661],
              [-39.952218091530554, -8.227004200021188],
              [-39.951087858706948, -8.224769138688041],
              [-39.951087861684663, -8.224769108536872],
              [-39.951087806301189, -8.224769000808873],
              [-39.951087749475612, -8.224768893828905],
              [-39.951087691218085, -8.224768787616135],
              [-39.951087631539032, -8.224768682189486],
              [-39.951087570449189, -8.224768577567939],
              [-39.951087507959443, -8.224768473770302],
              [-39.951087444081033, -8.224768370814976],
              [-39.951087378825349, -8.224768268720601],
              [-39.951087312204116, -8.224768167505356],
              [-39.951087244229257, -8.224768067187281],
              [-39.951087174912935, -8.224767967784567],
              [-39.951087104267557, -8.224767869314741],
              [-39.951087032305814, -8.224767771795701],
              [-39.951086959040559, -8.224767675244761],
              [-39.951086884484923, -8.224767579679245],
              [-39.951086808652256, -8.224767485116274],
              [-39.95108673155616, -8.224767391572723],
              [-39.951086653210417, -8.224767299065457],
              [-39.951086573629055, -8.224767207610961],
              [-39.951086492826356, -8.224767117225685],
              [-39.951086410816757, -8.224767027925786],
              [-39.951086327614952, -8.224766939727184],
              [-39.951086243235871, -8.224766852645725],
              [-39.951086157694604, -8.224766766697117],
              [-39.951086071006479, -8.224766681896478],
              [-39.951085983186992, -8.224766598259354],
              [-39.951085894251925, -8.224766515800418],
              [-39.951085804217165, -8.224766434534605],
              [-39.951085713098834, -8.224766354476383],
              [-39.951085620913275, -8.224766275640155],
              [-39.95108552767698, -8.224766198040026],
              [-39.951085433406668, -8.22476612168991],
              [-39.951085338119192, -8.224766046603341],
              [-39.951085241831649, -8.22476597279401],
              [-39.951085144561262, -8.224765900274919],
              [-39.951085046325446, -8.224765829059191],
              [-39.951084947141823, -8.224765759159499],
              [-39.95108484702812, -8.224765690588322],
              [-39.951084746002302, -8.224765623358145],
              [-39.951084644082435, -8.224765557480845],
              [-39.951084541286789, -8.22476549296821],
              [-39.951084437633767, -8.224765429831885],
              [-39.951084333141949, -8.224765368083109],
              [-39.951084227830023, -8.224765307732918],
              [-39.951084121716868, -8.224765248792151],
              [-39.951084014821475, -8.224765191271491],
              [-39.951083907162996, -8.224765135180959],
              [-39.951083798760706, -8.224765080530869],
              [-39.951083689634025, -8.224765027330934],
              [-39.951083579802507, -8.22476497559059],
              [-39.951083469285798, -8.224764925319141],
              [-39.951083358103709, -8.224764876525635],
              [-39.951083246276134, -8.224764829218824],
              [-39.951083133823154, -8.22476478340714],
              [-39.951083020764834, -8.224764739098768],
              [-39.951082907121474, -8.224764696301621],
              [-39.951082792913397, -8.224764655023453],
              [-39.951082678161072, -8.224764615271614],
              [-39.951082562885041, -8.224764577053282],
              [-39.951082447105968, -8.224764540375206],
              [-39.951082330844564, -8.224764505244035],
              [-39.951082214121662, -8.224764471665925],
              [-39.951082096958181, -8.224764439647025],
              [-39.951081979375104, -8.224764409193034],
              [-39.951081861393455, -8.224764380309344],
              [-39.951081743034386, -8.224764353001252],
              [-39.951081624319102, -8.224764327273588],
              [-39.951081505268846, -8.224764303131002],
              [-39.951081385904949, -8.224764280577677],
              [-39.951081266248806, -8.224764259617722],
              [-39.951081146321812, -8.224764240254986],
              [-39.951081026145488, -8.224764222492794],
              [-39.9510809057413, -8.224764206334401],
              [-39.951080785130848, -8.224764191782766],
              [-39.951080664335748, -8.224764178840349],
              [-39.9510805433776, -8.224764167509642],
              [-39.951080422278082, -8.224764157792496],
              [-39.95108030105888, -8.224764149690754],
              [-39.951080179741702, -8.224764143205839],
              [-39.951080058348289, -8.224764138338916],
              [-39.951079936900371, -8.224764135090926],
              [-39.951079815419682, -8.224764133462239],
              [-39.951079693928001, -8.224764133453393],
              [-39.951079572447078, -8.224764135064339],
              [-39.951079450998684, -8.224764138294603],
              [-39.951079329604553, -8.224764143143826],
              [-39.95107920828643, -8.224764149611071],
              [-39.951079087066034, -8.224764157695105],
              [-39.951078965965095, -8.224764167394524],
              [-39.951078845005284, -8.22476417870765],
              [-39.951078724208273, -8.224764191632421],
              [-39.951078603595697, -8.224764206166466],
              [-39.951078483189143, -8.224764222307266],
              [-39.95107836301019, -8.224764240051917],
              [-39.951078243080367, -8.224764259397245],
              [-39.951078123421119, -8.224764280339667],
              [-39.951078004053933, -8.224764302875583],
              [-39.951077885000124, -8.224764327000777],
              [-39.951077766281045, -8.224764352711215],
              [-39.951077770370844, -8.224764369083015],
              [-39.950069883843, -8.224989694097211],
              [-39.950069929871098, -8.224989659450067],
              [-39.950069879240445, -8.224989670920342],
              [-39.95006982867649, -8.224989682679],
              [-39.950069778180868, -8.224989694725449],
              [-39.950069727755228, -8.224989707059446],
              [-39.950069677401224, -8.224989719680593],
              [-39.950069627120499, -8.224989732588469],
              [-39.950069576914693, -8.224989745782675],
              [-39.950069526785455, -8.224989759262684],
              [-39.950069476734441, -8.224989773028044],
              [-39.950069426763264, -8.224989787078464],
              [-39.950069376873564, -8.22498980141332],
              [-39.950069327066984, -8.224989816032231],
              [-39.950069277345143, -8.224989830934746],
              [-39.950069227709662, -8.224989846120327],
              [-39.950069178162181, -8.224989861588432],
              [-39.950069128704314, -8.224989877338599],
              [-39.950069079337666, -8.224989893370354],
              [-39.950069030063887, -8.224989909683172],
              [-39.950068980884538, -8.224989926276423],
              [-39.950068931801262, -8.224989943149602],
              [-39.950068882815671, -8.224989960302233],
              [-39.950068833929357, -8.224989977733719],
              [-39.950068785143905, -8.224989995443469],
              [-39.950068736460913, -8.224990013430858],
              [-39.950068687881988, -8.224990031695421],
              [-39.950068639408727, -8.224990050236384],
              [-39.950068591042694, -8.224990069053302],
              [-39.950068542785473, -8.22499008814547],
              [-39.950068494638657, -8.224990107512307],
              [-39.950068446603829, -8.224990127153223],
              [-39.950068398682511, -8.224990147067498],
              [-39.950068350876329, -8.224990167254479],
              [-39.950068303186804, -8.224990187713505],
              [-39.950068255615527, -8.22499020844395],
              [-39.950068208164041, -8.224990229445163],
              [-39.950068160833887, -8.224990250716338],
              [-39.950068113626649, -8.224990272256932],
              [-39.950068066543828, -8.224990294066098],
              [-39.950068019586993, -8.224990316143224],
              [-39.950067972757672, -8.224990338487467],
              [-39.950067926057379, -8.224990361098316],
              [-39.950067879487669, -8.224990383974793],
              [-39.950067833050063, -8.224990407116264],
              [-39.950067786746061, -8.224990430521977],
              [-39.950067740577175, -8.224990454191131],
              [-39.950067694544956, -8.224990478122933],
              [-39.950067648650865, -8.224990502316603],
              [-39.950067602896425, -8.22499052677153],
              [-39.95006755728312, -8.224990551486652],
              [-39.95006751181247, -8.224990576461304],
              [-39.950067466485912, -8.224990601694662],
              [-39.950067421304986, -8.224990627185742],
              [-39.95006737627115, -8.224990652934004],
              [-39.950067331385839, -8.224990678938401],
              [-39.950067286650579, -8.224990705198131],
              [-39.950067242066794, -8.224990731712355],
              [-39.95006719763596, -8.224990758480109],
              [-39.950067153359527, -8.224990785500644],
              [-39.950067109238951, -8.224990812773031],
              [-39.950067065275647, -8.224990840296414],
              [-39.950067021471099, -8.224990868069753],
              [-39.950066977826687, -8.224990896092239],
              [-39.950066934343887, -8.224990924362977],
              [-39.950066891024079, -8.224990952881075],
              [-39.950066847868712, -8.224990981645476],
              [-39.950066804879206, -8.224991010655341],
              [-39.950066762056935, -8.224991039909606],
              [-39.950066719403296, -8.224991069407501],
              [-39.950066676919718, -8.22499109914782],
              [-39.950066634607587, -8.224991129129879],
              [-39.950066592468239, -8.224991159352435],
              [-39.950066550503117, -8.224991189814631],
              [-39.950066508713547, -8.224991220515449],
              [-39.950066467100925, -8.224991251453885],
              [-39.950066425666598, -8.224991282628975],
              [-39.950066384411926, -8.224991314039588],
              [-39.95006634333825, -8.224991345684815],
              [-39.95006630244692, -8.224991377563432],
              [-39.950066261739288, -8.224991409674672],
              [-39.950066221216666, -8.224991442017277],
              [-39.950066180880377, -8.224991474590265],
              [-39.950066140731764, -8.224991507392531],
              [-39.950066100772091, -8.22499154042298],
              [-39.95006606100273, -8.22499157368061],
              [-39.950066021424945, -8.224991607164371],
              [-39.950065982040037, -8.224991640873041],
              [-39.950065942849299, -8.224991674805562],
              [-39.950065903853989, -8.224991708960832],
              [-39.950065865055414, -8.224991743337757],
              [-39.950065826454832, -8.224991777935168],
              [-39.9500657880535, -8.224991812752068],
              [-39.950065749852683, -8.224991847787091],
              [-39.950065711853618, -8.224991883039241],
              [-39.950065674057583, -8.224991918507353],
              [-39.95006563646578, -8.224991954190225],
              [-39.950065599079423, -8.224991990086677],
              [-39.95006556189977, -8.224992026195643],
              [-39.95006552492805, -8.224992062515842],
              [-39.950065488165414, -8.224992099046107],
              [-39.950065441288409, -8.224992146555218],
              [-39.949391976261886, -8.225665185904132],
              [-39.949392015049249, -8.225665152155671],
              [-39.949391982161984, -8.225665184854625],
              [-39.949391949107152, -8.225665217385481],
              [-39.949391915885634, -8.225665249747339],
              [-39.949391882498325, -8.225665281939273],
              [-39.949391848946028, -8.225665313960661],
              [-39.949391815229696, -8.225665345810501],
              [-39.94939178135018, -8.225665377488088],
              [-39.949391747308361, -8.225665408992475],
              [-39.949391713105143, -8.225665440322883],
              [-39.949391678741414, -8.225665471478402],
              [-39.949391644218075, -8.225665502458375],
              [-39.949391609536036, -8.225665533261939],
              [-39.949391574696214, -8.225665563888212],
              [-39.949391539699505, -8.225665594336421],
              [-39.949391504546831, -8.225665624605822],
              [-39.949391469239117, -8.225665654695607],
              [-39.949391433777294, -8.225665684604971],
              [-39.949391398162263, -8.225665714333084],
              [-39.949391362394962, -8.225665743879228],
              [-39.949391326476366, -8.22566577324268],
              [-39.949391290407391, -8.225665802422524],
              [-39.949391254188967, -8.225665831418121],
              [-39.949391217822082, -8.225665860228577],
              [-39.949391181307632, -8.225665888853328],
              [-39.949391144646633, -8.22566591729152],
              [-39.949391107839993, -8.225665945542334],
              [-39.949391070888723, -8.225665973605217],
              [-39.949391033793752, -8.225666001479224],
              [-39.949390996556083, -8.225666029163746],
              [-39.949390959176668, -8.225666056658037],
              [-39.949390921656487, -8.225666083961451],
              [-39.94939088399655, -8.225666111073103],
              [-39.949390846197822, -8.225666137992425],
              [-39.949390808261278, -8.225666164718634],
              [-39.949390770187932, -8.225666191251092],
              [-39.949390731978802, -8.225666217589129],
              [-39.949390693634854, -8.225666243731833],
              [-39.949390655157082, -8.225666269678795],
              [-39.949390616546545, -8.225666295429276],
              [-39.949390577804209, -8.225666320982461],
              [-39.949390538931105, -8.225666346337777],
              [-39.949390499928263, -8.225666371494608],
              [-39.949390460796693, -8.225666396452219],
              [-39.949390421537402, -8.225666421210001],
              [-39.949390382151442, -8.225666445767203],
              [-39.949390342639845, -8.22566647012326],
              [-39.949390303003639, -8.225666494277592],
              [-39.949390263243892, -8.225666518229547],
              [-39.949390223361569, -8.225666541978365],
              [-39.949390183357778, -8.225666565523595],
              [-39.949390143233558, -8.225666588864456],
              [-39.949390102989952, -8.225666612000481],
              [-39.949390062628019, -8.225666634931013],
              [-39.949390022148805, -8.225666657655452],
              [-39.949389981553395, -8.225666680173228],
              [-39.949389940842813, -8.225666702483613],
              [-39.949389900018161, -8.225666724586166],
              [-39.949389859080483, -8.225666746480323],
              [-39.949389818030866, -8.225666768165468],
              [-39.949389776870404, -8.225666789640956],
              [-39.949389735600136, -8.225666810906342],
              [-39.949389694221161, -8.22566683196097],
              [-39.94938965273456, -8.225666852804411],
              [-39.949389611141434, -8.225666873435998],
              [-39.949389569442857, -8.225666893855212],
              [-39.94938952763993, -8.225666914061621],
              [-39.949389485733732, -8.22566693405453],
              [-39.949389443725387, -8.225666953833599],
              [-39.949389401615974, -8.225666973398182],
              [-39.949389359406595, -8.225666992747776],
              [-39.949389317098365, -8.225667011881868],
              [-39.949389274692415, -8.225667030800055],
              [-39.949389232189787, -8.225667049501761],
              [-39.94938918959167, -8.225667067986475],
              [-39.949389146899151, -8.225667086253774],
              [-39.949389104113337, -8.225667104303156],
              [-39.949389061235372, -8.225667122134071],
              [-39.949389018266345, -8.225667139746236],
              [-39.949388975207398, -8.225667157139039],
              [-39.949388932059684, -8.225667174311988],
              [-39.949388888824288, -8.225667191264813],
              [-39.949388845502376, -8.225667207996919],
              [-39.949388802095093, -8.225667224507871],
              [-39.949388758603526, -8.225667240797293],
              [-39.949388715028867, -8.225667256864789],
              [-39.949388671372226, -8.225667272709959],
              [-39.949388627634733, -8.225667288332209],
              [-39.949388583817587, -8.225667303731266],
              [-39.949388539921898, -8.225667318906753],
              [-39.94938849594881, -8.225667333858199],
              [-39.949388451899502, -8.225667348585185],
              [-39.949388407775103, -8.225667363087499],
              [-39.94938836357678, -8.225667377364537],
              [-39.949388319305697, -8.225667391416039],
              [-39.949388274962999, -8.22566740524165],
              [-39.949388230549857, -8.22566741884105],
              [-39.949388186067445, -8.225667432213676],
              [-39.949388141516884, -8.225667445359443],
              [-39.949388096899952, -8.225667458275856],
              [-39.949388077964116, -8.22566745618448],
              [-39.948603787461785, -8.225892430568878],
              [-39.948603771020366, -8.225892381088762],
              [-39.948603685609335, -8.225892406034424],
              [-39.948603600447903, -8.225892431813215],
              [-39.948603515544249, -8.225892458422541],
              [-39.948603430906537, -8.225892485859891],
              [-39.948603346542903, -8.225892514122529],
              [-39.948603262461461, -8.225892543207866],
              [-39.94860317867029, -8.225892573113027],
              [-39.94860309517744, -8.225892603835163],
              [-39.94860301199094, -8.2258926353713],
              [-39.948602929118785, -8.225892667718414],
              [-39.948602846568939, -8.225892700873519],
              [-39.94860276434936, -8.2258927348332],
              [-39.948602682467893, -8.225892769594314],
              [-39.948602600932475, -8.225892805153512],
              [-39.948602519750885, -8.225892841507408],
              [-39.948602438930976, -8.225892878652422],
              [-39.948602358480507, -8.225892916585169],
              [-39.948602278407193, -8.225892955301775],
              [-39.948602198718724, -8.225892994798635],
              [-39.948602119422787, -8.225893035071923],
              [-39.948602040526978, -8.22589307611781],
              [-39.948601962038907, -8.225893117932234],
              [-39.948601883966106, -8.22589316051136],
              [-39.94860180631607, -8.225893203850925],
              [-39.948601729096275, -8.225893247946926],
              [-39.948601652314153, -8.225893292794924],
              [-39.948601575977044, -8.225893338390835],
              [-39.948601500092323, -8.225893384730103],
              [-39.948601424667288, -8.225893431808288],
              [-39.948601349709165, -8.225893479620945],
              [-39.948601275225172, -8.225893528163438],
              [-39.948601201222473, -8.22589357743111],
              [-39.948601127708159, -8.225893627419222],
              [-39.948601054689306, -8.225893678122899],
              [-39.948600982172948, -8.225893729537365],
              [-39.948600910166057, -8.225893781657701],
              [-39.948600838675517, -8.225893834478747],
              [-39.948600767708236, -8.225893887995591],
              [-39.94860069727104, -8.22589394220299],
              [-39.948600627370681, -8.225893997095762],
              [-39.948600558013865, -8.225894052668643],
              [-39.948600489207294, -8.225894108916266],
              [-39.948600420957554, -8.225894165833227],
              [-39.94860035327121, -8.225894223414061],
              [-39.948600286154786, -8.225894281653233],
              [-39.948600219614711, -8.225894340545151],
              [-39.948600153657402, -8.225894400084226],
              [-39.948600088289169, -8.225894460264595],
              [-39.948600023516306, -8.225894521080562],
              [-39.948599959345067, -8.225894582526204],
              [-39.948599895781598, -8.22589464459573],
              [-39.948599832832009, -8.225894707283105],
              [-39.948599770502362, -8.225894770582256],
              [-39.948599708798604, -8.225894834487271],
              [-39.948599647726745, -8.225894898991781],
              [-39.948599587292584, -8.225894964089729],
              [-39.948599527501955, -8.225895029774854],
              [-39.948599468360626, -8.225895096040782],
              [-39.948599409874234, -8.225895162881226],
              [-39.948599352048461, -8.225895230289597],
              [-39.94859929488883, -8.225895298259596],
              [-39.948599238400817, -8.225895366784682],
              [-39.948599182589895, -8.225895435858146],
              [-39.948599127461392, -8.225895505473394],
              [-39.948599073020638, -8.225895575623703],
              [-39.948599019272841, -8.225895646302444],
              [-39.948598966223194, -8.225895717502752],
              [-39.948598913876751, -8.225895789217837],
              [-39.948598862238597, -8.22589586144071],
              [-39.948598811313651, -8.225895934164397],
              [-39.948598761106851, -8.225896007382101],
              [-39.948598711622985, -8.225896081086573],
              [-39.948598662866829, -8.22589615527086],
              [-39.948598614843071, -8.22589622992786],
              [-39.948598567556303, -8.225896305050284],
              [-39.948598521011085, -8.225896380630926],
              [-39.948598475211909, -8.225896456662591],
              [-39.948598430163166, -8.225896533137874],
              [-39.948598385869175, -8.225896610049602],
              [-39.948598342334193, -8.225896687390209],
              [-39.948598299562427, -8.225896765152314],
              [-39.948598257557968, -8.225896843328421],
              [-39.948598216324868, -8.225896921910994],
              [-39.948598175867069, -8.22589700089256],
              [-39.948598136188473, -8.225897080265529],
              [-39.948598097292894, -8.225897160022226],
              [-39.948598059184071, -8.225897240155014],
              [-39.948598021865685, -8.225897320656129],
              [-39.94859798534128, -8.225897401517853],
              [-39.94859794961441, -8.225897482732508],
              [-39.948597914688492, -8.225897564292124],
              [-39.948597880566879, -8.22589764618907],
              [-39.948597847252827, -8.225897728415241],
              [-39.948597814749597, -8.225897810962914],
              [-39.948597783060265, -8.225897893824046],
              [-39.948597752187901, -8.225897976990742],
              [-39.948597722135467, -8.225898060454959],
              [-39.948597692905849, -8.225898144208664],
              [-39.948597664501847, -8.225898228243853],
              [-39.948597627961412, -8.225898233453387],
              [-39.948375524507561, -8.226566136030142],
              [-39.948375569792887, -8.226566117828243],
              [-39.948375549667269, -8.226566177650765],
              [-39.948375529123247, -8.226566237331925],
              [-39.948375508161824, -8.226566296868839],
              [-39.948375486783988, -8.22656635625864],
              [-39.948375464990832, -8.226566415498397],
              [-39.948375442783401, -8.226566474585287],
              [-39.948375420162748, -8.226566533516417],
              [-39.94837539713, -8.226566592288901],
              [-39.948375373686275, -8.226566650899839],
              [-39.948375349832688, -8.226566709346482],
              [-39.948375325570446, -8.226566767625933],
              [-39.948375300900693, -8.22656682573527],
              [-39.948375275824652, -8.226566883671811],
              [-39.948375250343531, -8.226566941432662],
              [-39.948375224458559, -8.226566999014951],
              [-39.948375198171036, -8.226567056416068],
              [-39.948375171482191, -8.226567113633021],
              [-39.948375144393339, -8.226567170663019],
              [-39.948375116905815, -8.226567227503475],
              [-39.948375089020963, -8.226567284151507],
              [-39.948375060740105, -8.226567340604401],
              [-39.948375032064639, -8.226567396859338],
              [-39.948375002995959, -8.226567452913603],
              [-39.948374973535493, -8.226567508764482],
              [-39.948374943684627, -8.226567564409262],
              [-39.94837491344488, -8.226567619845222],
              [-39.948374882817674, -8.226567675069663],
              [-39.94837485180453, -8.22656773007991],
              [-39.948374820406919, -8.226567784873389],
              [-39.948374788626403, -8.226567839447178],
              [-39.948374756464524, -8.22656789379883],
              [-39.948374723922832, -8.226567947925641],
              [-39.948374691002932, -8.22656800182498],
              [-39.94837465770641, -8.226568055494209],
              [-39.948374624034905, -8.226568108930804],
              [-39.948374589990017, -8.226568162131956],
              [-39.948374555573444, -8.226568215095309],
              [-39.948374520786842, -8.226568267818232],
              [-39.948374485631895, -8.226568320298004],
              [-39.948374450110315, -8.226568372532181],
              [-39.948374414223885, -8.22656842451825],
              [-39.948374377974261, -8.226568476253728],
              [-39.948374341363284, -8.226568527735919],
              [-39.948374304392686, -8.226568578962386],
              [-39.948374267064281, -8.226568629930735],
              [-39.948374229379894, -8.226568680638477],
              [-39.948374191341365, -8.22656873108307],
              [-39.948374152950535, -8.226568781262005],
              [-39.948374114209251, -8.226568831172901],
              [-39.948374075119446, -8.226568880813376],
              [-39.948374035682988, -8.226568930181006],
              [-39.94837399590179, -8.226568979273321],
              [-39.948373955777804, -8.226569028087992],
              [-39.948373915312985, -8.226569076622592],
              [-39.948373874509294, -8.226569124874853],
              [-39.948373833368727, -8.226569172842366],
              [-39.948373791893268, -8.22656922052272],
              [-39.948373750084954, -8.226569267913696],
              [-39.948373707945819, -8.226569315012986],
              [-39.948373665477916, -8.226569361818195],
              [-39.948373622683285, -8.226569408327158],
              [-39.948373579564006, -8.226569454537627],
              [-39.948373536122233, -8.226569500447209],
              [-39.948373492360027, -8.226569546053863],
              [-39.948373448279533, -8.226569591355204],
              [-39.948373403882911, -8.22656963634914],
              [-39.948373359172301, -8.226569681033441],
              [-39.94837331414989, -8.226569725405911],
              [-39.948373268817868, -8.226569769464325],
              [-39.948373223178443, -8.226569813206687],
              [-39.948373177233826, -8.226569856630851],
              [-39.948373130986255, -8.226569899734576],
              [-39.948373084437982, -8.226569942515951],
              [-39.948373037591274, -8.226569984972693],
              [-39.948372990448426, -8.226570027102746],
              [-39.948372943011698, -8.22657006890428],
              [-39.948372895283448, -8.226570110375121],
              [-39.948372847265944, -8.226570151513195],
              [-39.94837279896155, -8.226570192316579],
              [-39.948372750372613, -8.22657023278326],
              [-39.948372701501512, -8.226570272911289],
              [-39.948372652350599, -8.226570312698641],
              [-39.948372602922298, -8.226570352143504],
              [-39.948372553218974, -8.226570391243804],
              [-39.948372503243085, -8.226570429997798],
              [-39.94837245299702, -8.226570468403526],
              [-39.94837240248328, -8.226570506459121],
              [-39.948372351704279, -8.226570544162726],
              [-39.948372300662506, -8.226570581512489],
              [-39.948372249360418, -8.226570618506654],
              [-39.948372197800566, -8.226570655143311],
              [-39.948372145985431, -8.226570691420832],
              [-39.948372093917506, -8.226570727337332],
              [-39.94837204159937, -8.226570762891114],
              [-39.948371989033546, -8.226570798080374],
              [-39.948371936222586, -8.226570832903471],
              [-39.948371883169067, -8.226570867358774],
              [-39.948371829875583, -8.226570901444468],
              [-39.948371776345056, -8.226570935157966],
              [-39.948371722998743, -8.226570938476213],
              [-39.947477738719677, -8.227129723386424],
              [-39.947477736134417, -8.227129673662205],
              [-39.947477625390889, -8.227129741775041],
              [-39.947477513678614, -8.227129808299207],
              [-39.947477401020549, -8.227129873221147],
              [-39.94747728743986, -8.227129936527412],
              [-39.947477172959871, -8.227129998205124],
              [-39.947477057604125, -8.227130058241373],
              [-39.947476941396324, -8.227130116624087],
              [-39.947476824360351, -8.22713017334109],
              [-39.947476706520256, -8.227130228380824],
              [-39.947476587900283, -8.227130281731842],
              [-39.947476468524769, -8.227130333383323],
              [-39.947476348418284, -8.227130383324631],
              [-39.947476227605485, -8.227130431545408],
              [-39.947476106111232, -8.2271304780358],
              [-39.947475983960459, -8.22713052278627],
              [-39.947475861178319, -8.227130565787638],
              [-39.947475737789986, -8.227130607031045],
              [-39.947475613820856, -8.227130646508032],
              [-39.947475489296416, -8.22713068421039],
              [-39.947475364242244, -8.227130720130472],
              [-39.947475238684035, -8.2271307542608],
              [-39.947475112647588, -8.227130786594525],
              [-39.947474986158838, -8.227130817124815],
              [-39.947474859243748, -8.22713084584554],
              [-39.947474731928409, -8.227130872750649],
              [-39.947474604239005, -8.227130897834781],
              [-39.947474476201791, -8.227130921092696],
              [-39.947474347843034, -8.227130942519553],
              [-39.947474219189132, -8.227130962111103],
              [-39.94747409026656, -8.227130979863153],
              [-39.947473961101771, -8.227130995772205],
              [-39.947473831721339, -8.227131009834849],
              [-39.947473702151832, -8.227131022048267],
              [-39.947473572419895, -8.227131032409973],
              [-39.947473442552194, -8.227131040917756],
              [-39.947473312575411, -8.227131047570026],
              [-39.947473182516248, -8.227131052365243],
              [-39.947473052401456, -8.227131055302429],
              [-39.947472922257774, -8.22713105638111],
              [-39.947472792111931, -8.227131055600998],
              [-39.947472661990709, -8.227131052962246],
              [-39.947472531920809, -8.227131048465319],
              [-39.947472401928998, -8.227131042111347],
              [-39.947472272041963, -8.227131033901397],
              [-39.947472142286429, -8.227131023837357],
              [-39.947472012689047, -8.22713101192112],
              [-39.947471883276442, -8.227130998155285],
              [-39.947471754075224, -8.227130982542624],
              [-39.947471625111973, -8.227130965086282],
              [-39.947471496413144, -8.227130945789879],
              [-39.947471368005203, -8.22713092465756],
              [-39.947471239914563, -8.227130901693371],
              [-39.94747111216752, -8.227130876902255],
              [-39.94747098479035, -8.227130850289193],
              [-39.947470857809222, -8.227130821859674],
              [-39.947470731250235, -8.227130791619567],
              [-39.947470605139408, -8.227130759575171],
              [-39.947470479502634, -8.227130725732815],
              [-39.947470354365777, -8.227130690099706],
              [-39.947470229754536, -8.227130652683105],
              [-39.947470105694478, -8.227130613490608],
              [-39.947469982211175, -8.22713057253042],
              [-39.947469859329964, -8.227130529810792],
              [-39.947469737076105, -8.22713048534062],
              [-39.947469615474702, -8.227130439129047],
              [-39.947469494550788, -8.227130391185465],
              [-39.947469374329195, -8.227130341519805],
              [-39.947469254834616, -8.227130290142265],
              [-39.947469136091662, -8.227130237063436],
              [-39.947469018124657, -8.227130182294216],
              [-39.947468900957915, -8.227130125845774],
              [-39.947468784615495, -8.227130067729886],
              [-39.947468669121299, -8.227130007958319],
              [-39.947468554499082, -8.227129946543469],
              [-39.947468440772361, -8.227129883497883],
              [-39.947468327964572, -8.22712981883457],
              [-39.947468216098834, -8.227129752566757],
              [-39.947468105198183, -8.227129684708121],
              [-39.947467995285386, -8.227129615272633],
              [-39.947467886383066, -8.227129544274467],
              [-39.947467778513577, -8.227129471728292],
              [-39.94746767169908, -8.22712939764906],
              [-39.947467565961539, -8.227129322051836],
              [-39.947467461322717, -8.227129244952305],
              [-39.947467357804051, -8.22712916636633],
              [-39.947467255426879, -8.227129086309951],
              [-39.947467154212234, -8.227129004799608],
              [-39.947467054180876, -8.227128921852223],
              [-39.947466955353413, -8.227128837484591],
              [-39.947466857750115, -8.227128751714281],
              [-39.947466761391063, -8.227128664558823],
              [-39.947466666296059, -8.227128576036144],
              [-39.947466572484657, -8.227128486164451],
              [-39.947466479976136, -8.227128394962188],
              [-39.947466388789472, -8.227128302448094],
              [-39.947466298943453, -8.22712820864124],
              [-39.947466210456525, -8.227128113560807],
              [-39.947466123346871, -8.22712801722639],
              [-39.947466037632516, -8.227127919657683],
              [-39.947466023338002, -8.227127942146652],
              [-39.946793375554748, -8.226351084639145],
              [-39.946793378640635, -8.226351064915464],
              [-39.94679332678669, -8.226351005553241],
              [-39.946793274416251, -8.226350946642739],
              [-39.946793221533319, -8.226350888188342],
              [-39.946793168141859, -8.226350830194608],
              [-39.946793114245956, -8.226350772665857],
              [-39.946793059849625, -8.226350715606511],
              [-39.946793004957044, -8.226350659020801],
              [-39.946792949572334, -8.226350602913051],
              [-39.946792893699708, -8.226350547287442],
              [-39.946792837343381, -8.226350492148367],
              [-39.946792780507622, -8.226350437499805],
              [-39.946792723196751, -8.226350383346023],
              [-39.946792665415103, -8.226350329690924],
              [-39.946792607167012, -8.226350276538819],
              [-39.946792548456948, -8.226350223893631],
              [-39.946792489289329, -8.226350171759373],
              [-39.946792429668605, -8.226350120139875],
              [-39.946792369599336, -8.226350069039118],
              [-39.946792309086035, -8.226350018461],
              [-39.946792248133313, -8.226349968409419],
              [-39.946792186745768, -8.226349918887987],
              [-39.946792124928038, -8.226349869900483],
              [-39.946792062684821, -8.226349821450768],
              [-39.946792000020828, -8.226349773542443],
              [-39.94679193694077, -8.226349726179095],
              [-39.94679187344947, -8.226349679364231],
              [-39.946791809551712, -8.226349633101607],
              [-39.946791745252327, -8.226349587394523],
              [-39.946791680556203, -8.226349542246574],
              [-39.946791615468221, -8.226349497661101],
              [-39.946791549993307, -8.226349453641461],
              [-39.946791484136433, -8.226349410191045],
              [-39.946791417902574, -8.22634936731323],
              [-39.946791351296731, -8.226349325011022],
              [-39.946791284323972, -8.226349283287801],
              [-39.946791216989361, -8.226349242146712],
              [-39.946791149297994, -8.226349201590828],
              [-39.946791081254979, -8.226349161623254],
              [-39.946791012865496, -8.226349122246924],
              [-39.94679094413469, -8.226349083464973],
              [-39.946790875067791, -8.226349045280205],
              [-39.946790805670005, -8.226349007695639],
              [-39.946790735946614, -8.22634897071393],
              [-39.946790665902867, -8.226348934338036],
              [-39.946790595544108, -8.226348898570665],
              [-39.94679052487561, -8.226348863414501],
              [-39.946790453902743, -8.226348828872185],
              [-39.946790382630901, -8.226348794946459],
              [-39.94679031106547, -8.2263487616398],
              [-39.94679023921185, -8.226348728954703],
              [-39.946790167075491, -8.226348696893702],
              [-39.946790094661857, -8.226348665459213],
              [-39.94679002197644, -8.226348634653588],
              [-39.946789949024719, -8.226348604479114],
              [-39.946789875812236, -8.226348574938203],
              [-39.946789802344519, -8.22634854603298],
              [-39.946789728627131, -8.226348517765709],
              [-39.9467896546657, -8.226348490138406],
              [-39.94678958046574, -8.226348463153373],
              [-39.946789506032928, -8.226348436812486],
              [-39.946789431372892, -8.226348411117714],
              [-39.946789356491259, -8.226348386071203],
              [-39.946789281393741, -8.226348361674592],
              [-39.946789206085981, -8.22634833792992],
              [-39.946789130573698, -8.226348314838926],
              [-39.946789054862606, -8.226348292403364],
              [-39.946788978958423, -8.226348270624959],
              [-39.946788902866935, -8.226348249505261],
              [-39.946788826593853, -8.226348229045925],
              [-39.946788750144989, -8.226348209248506],
              [-39.946788673526129, -8.226348190114559],
              [-39.946788596743033, -8.226348171645361],
              [-39.946788519801572, -8.226348153842432],
              [-39.946788442707515, -8.226348136707182],
              [-39.946788365466738, -8.226348120240834],
              [-39.946788288085074, -8.226348104444629],
              [-39.946788210568378, -8.226348089319824],
              [-39.946788132922521, -8.226348074867422],
              [-39.946788055153398, -8.226348061088624],
              [-39.946787977266865, -8.226348047984454],
              [-39.946787899268848, -8.226348035555985],
              [-39.946787821165223, -8.226348023803986],
              [-39.946787742961924, -8.226348012729476],
              [-39.946787664664853, -8.226348002333198],
              [-39.946787586279974, -8.22634799261612],
              [-39.94678750781317, -8.22634798357879],
              [-39.946787429270437, -8.226347975221977],
              [-39.946787350657679, -8.226347967546271],
              [-39.94678727198086, -8.226347960552278],
              [-39.946787193245932, -8.226347954240529],
              [-39.946787114458871, -8.226347948611478],
              [-39.946787035625604, -8.226347943665587],
              [-39.946786956752163, -8.226347939403238],
              [-39.946786877844467, -8.226347935824695],
              [-39.946786798908498, -8.226347932930379],
              [-39.946786719950246, -8.226347930720275],
              [-39.946786640975674, -8.226347929194702],
              [-39.946786561990791, -8.226347928353691],
              [-39.946786483001517, -8.226347928197455],
              [-39.946786468499859, -8.226347935405235],
              [-39.944758819646559, -8.226352706662036],
              [-39.944758873604719, -8.226352699362195],
              [-39.944758796835963, -8.226352699865986],
              [-39.944758720074233, -8.226352701016504],
              [-39.944758643325002, -8.226352702813786],
              [-39.944758566593784, -8.226352705257488],
              [-39.944758489886027, -8.226352708347509],
              [-39.944758413207268, -8.226352712083681],
              [-39.944758336562934, -8.226352716465742],
              [-39.944758259958547, -8.22635272149326],
              [-39.944758183399578, -8.226352727165985],
              [-39.944758106891506, -8.226352733483564],
              [-39.9447580304398, -8.226352740445378],
              [-39.944757954049898, -8.226352748051015],
              [-39.944757877727291, -8.226352756299988],
              [-39.944757801477458, -8.226352765191571],
              [-39.944757725305791, -8.226352774725287],
              [-39.944757649217799, -8.226352784900348],
              [-39.944757573218872, -8.226352795716027],
              [-39.944757497314477, -8.226352807171663],
              [-39.944757421510033, -8.226352819266307],
              [-39.944757345810977, -8.226352831999145],
              [-39.944757270222695, -8.226352845369256],
              [-39.944757194750593, -8.226352859375627],
              [-39.944757119400094, -8.226352874017421],
              [-39.944757044176562, -8.226352889293381],
              [-39.944756969085383, -8.226352905202582],
              [-39.944756894131942, -8.226352921743839],
              [-39.944756819321583, -8.226352938915854],
              [-39.944756744659664, -8.226352956717633],
              [-39.944756670151499, -8.226352975147655],
              [-39.944756595802446, -8.226352994204762],
              [-39.944756521617812, -8.226353013887552],
              [-39.944756447602906, -8.226353034194602],
              [-39.94475637376302, -8.226353055124486],
              [-39.944756300103435, -8.226353076675673],
              [-39.944756226629401, -8.226353098846655],
              [-39.944756153346184, -8.226353121635832],
              [-39.944756080259047, -8.226353145041644],
              [-39.944756007373179, -8.226353169062243],
              [-39.944755934693816, -8.226353193696029],
              [-39.944755862226152, -8.226353218941259],
              [-39.944755789975353, -8.226353244796069],
              [-39.944755717946613, -8.226353271258603],
              [-39.944755646145055, -8.226353298327021],
              [-39.944755574575851, -8.226353325999421],
              [-39.94475550324406, -8.226353354273789],
              [-39.944755432154835, -8.226353383148032],
              [-39.944755361313248, -8.226353412620163],
              [-39.944755290724352, -8.226353442687985],
              [-39.944755220393205, -8.226353473349484],
              [-39.944755150324831, -8.226353504602416],
              [-39.944755080524246, -8.226353536444405],
              [-39.944755010996438, -8.226353568873327],
              [-39.944754941746389, -8.226353601886977],
              [-39.944754872779022, -8.226353635482772],
              [-39.944754804099304, -8.226353669658348],
              [-39.944754735712131, -8.226353704411347],
              [-39.944754667622384, -8.226353739739219],
              [-39.944754599834951, -8.226353775639334],
              [-39.944754532354679, -8.226353812109414],
              [-39.944754465186364, -8.226353849146562],
              [-39.944754398334844, -8.226353886748347],
              [-39.944754331804901, -8.226353924911987],
              [-39.94475426560124, -8.226353963634747],
              [-39.944754199728656, -8.226354002913716],
              [-39.944754134191832, -8.226354042746404],
              [-39.944754068995472, -8.226354083129639],
              [-39.944754004144201, -8.226354124060794],
              [-39.944753939642695, -8.226354165536748],
              [-39.944753875495543, -8.226354207554735],
              [-39.944753811707351, -8.226354250111516],
              [-39.944753748282665, -8.226354293204233],
              [-39.944753685226033, -8.226354336829687],
              [-39.94475362254196, -8.226354380984825],
              [-39.944753560234929, -8.226354425666514],
              [-39.944753498309375, -8.22635447087143],
              [-39.944753436769744, -8.226354516596473],
              [-39.944753375620451, -8.226354562838301],
              [-39.944753314865871, -8.226354609593722],
              [-39.94475325451031, -8.22635465685922],
              [-39.944753194558103, -8.226354704631511],
              [-39.944753135013549, -8.226354752907142],
              [-39.944753075880882, -8.226354801682675],
              [-39.944753017164388, -8.226354850954699],
              [-39.944752958868179, -8.226354900719603],
              [-39.944752900996491, -8.226354950973798],
              [-39.944752843553445, -8.226355001713779],
              [-39.944752786543127, -8.22635505293586],
              [-39.944752729969657, -8.226355104636442],
              [-39.944752673837016, -8.226355156811724],
              [-39.944752618149288, -8.226355209458077],
              [-39.944752562910416, -8.226355262571673],
              [-39.944752508124367, -8.22635531614875],
              [-39.94475245379504, -8.226355370185434],
              [-39.94475239992633, -8.226355424677871],
              [-39.944752346522094, -8.226355479622246],
              [-39.944752293586156, -8.226355535014559],
              [-39.944752241122274, -8.226355590850751],
              [-39.944752189134235, -8.226355647127086],
              [-39.94475213762572, -8.226355703839234],
              [-39.94475210873609, -8.226355707056534],
              [-39.94374188552684, -8.227477494084555],
              [-39.943741870144791, -8.227477528884055],
              [-39.943741825405823, -8.227477578934925],
              [-39.943741781040373, -8.227477629314649],
              [-39.943741737050857, -8.227477680020556],
              [-39.943741693439712, -8.227477731049889],
              [-39.943741650209326, -8.227477782399598],
              [-39.943741607362071, -8.227477834067207],
              [-39.943741564900314, -8.227477886049646],
              [-39.943741522826336, -8.227477938344105],
              [-39.94374148114251, -8.22747799094776],
              [-39.943741439851095, -8.227478043857564],
              [-39.943741398954351, -8.22747809707089],
              [-39.943741358454545, -8.227478150584625],
              [-39.943741318353901, -8.227478204395871],
              [-39.943741278654606, -8.227478258501661],
              [-39.943741239358836, -8.227478312899088],
              [-39.943741200468757, -8.227478367585029],
              [-39.943741161986509, -8.227478422556572],
              [-39.943741123914194, -8.22747847781069],
              [-39.943741086253915, -8.227478533344396],
              [-39.943741049007727, -8.22747858915459],
              [-39.943741012177703, -8.22747864523823],
              [-39.943740975765834, -8.227478701592215],
              [-39.943740939774123, -8.227478758213438],
              [-39.94374090420456, -8.227478815098781],
              [-39.943740869059091, -8.227478872245257],
              [-39.943740834339657, -8.227478929649523],
              [-39.943740800048147, -8.227478987308496],
              [-39.943740766186437, -8.227479045219063],
              [-39.943740732756403, -8.227479103378046],
              [-39.943740699759914, -8.22747916178222],
              [-39.943740667198703, -8.22747922042838],
              [-39.943740635074619, -8.227479279313282],
              [-39.943740603389408, -8.22747933843373],
              [-39.943740572144819, -8.227479397786409],
              [-39.943740541342535, -8.227479457368158],
              [-39.943740510984277, -8.227479517175643],
              [-39.943740481071714, -8.227479577205681],
              [-39.943740451606494, -8.227479637454822],
              [-39.943740422590217, -8.227479697919867],
              [-39.943740394024474, -8.227479758597379],
              [-39.943740365910827, -8.227479819484124],
              [-39.943740338250841, -8.227479880576828],
              [-39.943740311046021, -8.227479941872049],
              [-39.943740284297874, -8.227480003366345],
              [-39.943740258007885, -8.227480065056369],
              [-39.943740232177447, -8.227480126938943],
              [-39.943740206808002, -8.227480189010356],
              [-39.943740181900964, -8.227480251267378],
              [-39.943740157457668, -8.227480313706559],
              [-39.943740133479494, -8.227480376324502],
              [-39.943740109967713, -8.227480439117729],
              [-39.943740086923647, -8.227480502082869],
              [-39.94374006434856, -8.227480565216311],
              [-39.943740042243668, -8.227480628514725],
              [-39.943740020610207, -8.227480691974529],
              [-39.943739999449377, -8.227480755592401],
              [-39.943739978762295, -8.227480819364654],
              [-39.943739958550147, -8.227480883287894],
              [-39.943739938814012, -8.227480947358535],
              [-39.943739919554993, -8.227481011573197],
              [-39.943739900774112, -8.2274810759282],
              [-39.943739882472435, -8.227481140420183],
              [-39.943739864650972, -8.227481205045454],
              [-39.943739847310688, -8.22748126980046],
              [-39.943739830452508, -8.22748133468175],
              [-39.943739814077411, -8.227481399685733],
              [-39.94373979818625, -8.227481464808777],
              [-39.943739782779929, -8.227481530047339],
              [-39.94373976785927, -8.227481595397833],
              [-39.943739753425106, -8.227481660856704],
              [-39.943739739478239, -8.227481726420327],
              [-39.943739726019409, -8.22748179208511],
              [-39.943739713049375, -8.227481857847515],
              [-39.943739700568848, -8.227481923703817],
              [-39.943739688578496, -8.227481989650389],
              [-39.943739677078995, -8.227482055683737],
              [-39.943739666070975, -8.22748212180014],
              [-39.943739655555042, -8.22748218799598],
              [-39.943739645531757, -8.227482254267683],
              [-39.943739636001673, -8.227482320611498],
              [-39.943739626965339, -8.22748238702389],
              [-39.94373961842323, -8.227482453501146],
              [-39.943739610375822, -8.227482520039656],
              [-39.943739602823555, -8.22748258663569],
              [-39.943739595766836, -8.227482653285719],
              [-39.943739589206054, -8.227482719985966],
              [-39.943739583141586, -8.227482786732804],
              [-39.943739577573758, -8.227482853522613],
              [-39.943739572502857, -8.227482920351667],
              [-39.943739567929178, -8.227482987216318],
              [-39.943739563852986, -8.227483054112932],
              [-39.943739560274473, -8.227483121037826],
              [-39.943739557193851, -8.227483187987275],
              [-39.943739554611298, -8.227483254957557],
              [-39.943739552526935, -8.227483321945147],
              [-39.943739550940911, -8.227483388946256],
              [-39.943739549853262, -8.22748345595725],
              [-39.943739549264095, -8.227483522974415],
              [-39.943739549173401, -8.227483589994117],
              [-39.943739539486302, -8.227483559278452],
              [-39.943742450066928, -8.228713577937683],
              [-39.943742459656917, -8.22871356747391],
              [-39.943742460155029, -8.228713643262981],
              [-39.943742461290633, -8.228713719045311],
              [-39.943742463063636, -8.228713794815482],
              [-39.94374246547391, -8.228713870568221],
              [-39.9437424685213, -8.22871394629813],
              [-39.943742472205592, -8.228714021999949],
              [-39.943742476526516, -8.22871409766835],
              [-39.943742481483774, -8.228714173297988],
              [-39.943742487077046, -8.228714248883628],
              [-39.943742493305898, -8.228714324419919],
              [-39.943742500169883, -8.22871439990155],
              [-39.943742507668567, -8.228714475323166],
              [-39.943742515801404, -8.22871455067963],
              [-39.943742524567817, -8.228714625965605],
              [-39.943742533967182, -8.228714701175646],
              [-39.943742543998859, -8.228714776304608],
              [-39.943742554662137, -8.22871485134716],
              [-39.943742565956249, -8.228714926298009],
              [-39.943742577880414, -8.22871500115199],
              [-39.943742590433807, -8.22871507590378],
              [-39.943742603615547, -8.228715150548258],
              [-39.943742617424675, -8.228715225079938],
              [-39.943742631860246, -8.228715299493741],
              [-39.943742646921272, -8.228715373784443],
              [-39.943742662606653, -8.22871544794676],
              [-39.943742678915292, -8.228715521975605],
              [-39.943742695846062, -8.22871559586566],
              [-39.943742713397761, -8.228715669611736],
              [-39.943742731569166, -8.228715743208751],
              [-39.943742750359, -8.22871581665148],
              [-39.943742769765933, -8.228715889934728],
              [-39.943742789788622, -8.22871596305337],
              [-39.943742810425647, -8.228716036002364],
              [-39.943742831675543, -8.22871610877645],
              [-39.943742853536847, -8.228716181370649],
              [-39.94374287600801, -8.228716253779739],
              [-39.943742899087454, -8.228716325998638],
              [-39.943742922773566, -8.228716398022396],
              [-39.943742947064671, -8.228716469845816],
              [-39.943742971959061, -8.228716541463973],
              [-39.943742997455004, -8.228716612871814],
              [-39.943743023550695, -8.228716684064347],
              [-39.943743050244322, -8.228716755036404],
              [-39.943743077533988, -8.22871682578314],
              [-39.943743105417788, -8.228716896299662],
              [-39.943743133893754, -8.228716966580796],
              [-39.94374316295989, -8.228717036621799],
              [-39.943743192614171, -8.228717106417673],
              [-39.943743222854479, -8.228717175963533],
              [-39.943743253678733, -8.228717245254522],
              [-39.943743285084736, -8.228717314285758],
              [-39.943743317070307, -8.22871738305234],
              [-39.943743349633188, -8.228717451549491],
              [-39.943743382771082, -8.228717519772331],
              [-39.943743416481674, -8.228717587716266],
              [-39.943743450762589, -8.228717655376318],
              [-39.943743485611449, -8.228717722747792],
              [-39.943743521025752, -8.228717789825922],
              [-39.943743557003067, -8.228717856606117],
              [-39.943743593540816, -8.228717923083586],
              [-39.943743630636476, -8.228717989253679],
              [-39.943743668287425, -8.22871805511179],
              [-39.94374370649102, -8.228718120653243],
              [-39.943743745244582, -8.228718185873488],
              [-39.943743784545369, -8.228718250767875],
              [-39.943743824390651, -8.228718315331935],
              [-39.943743864777623, -8.228718379560991],
              [-39.943743905703435, -8.228718443450708],
              [-39.943743947165231, -8.228718506996575],
              [-39.943743989160055, -8.22871857019396],
              [-39.943744031685021, -8.228718633038566],
              [-39.943744074737111, -8.228718695525959],
              [-39.943744118313305, -8.228718757651793],
              [-39.943744162410532, -8.228718819411549],
              [-39.943744207025702, -8.22871888080109],
              [-39.943744252155696, -8.228718941815988],
              [-39.943744297797338, -8.228719002451971],
              [-39.943744343947401, -8.228719062704743],
              [-39.943744390602681, -8.22871912257026],
              [-39.943744437759868, -8.228719182044017],
              [-39.943744485415678, -8.228719241122013],
              [-39.94374453356675, -8.228719299800195],
              [-39.943744582209696, -8.228719358074276],
              [-39.943744631341126, -8.228719415940239],
              [-39.943744680957565, -8.228719473394014],
              [-39.943744731055524, -8.22871953043148],
              [-39.943744781631501, -8.228719587048824],
              [-39.943744832681951, -8.228719643241892],
              [-39.943744884203269, -8.228719699006751],
              [-39.943744936191848, -8.228719754339654],
              [-39.943744988644042, -8.228719809236447],
              [-39.943745041556149, -8.228719863693515],
              [-39.943745094924473, -8.228719917706959],
              [-39.943745148745265, -8.228719971272932],
              [-39.943745203014736, -8.228720024387721],
              [-39.94374525772907, -8.228720077047527],
              [-39.943745312884438, -8.228720129248849],
              [-39.943745368476961, -8.228720180987777],
              [-39.943745424502737, -8.228720232260731],
              [-39.943745370995224, -8.228720263862792],
              [-39.945102613985298, -8.229951892234803],
              [-39.946294444796585, -8.231064519993904],
              [-39.946294418449966, -8.231064515731926],
              [-39.946294597458049, -8.231064687493477],
              [-39.946294771679547, -8.231064864072371],
              [-39.946294940983847, -8.231065045336065],
              [-39.946295105244097, -8.231065231148989],
              [-39.946295264337159, -8.231065421371667],
              [-39.946295418143826, -8.231065615861676],
              [-39.946295566548805, -8.231065814473139],
              [-39.946295709440875, -8.231066017057337],
              [-39.946295846712971, -8.231066223462324],
              [-39.946295978262171, -8.231066433533403],
              [-39.946296103989916, -8.231066647113268],
              [-39.946296223801966, -8.231066864041683],
              [-39.946296337608523, -8.231067084156143],
              [-39.946296445324307, -8.231067307291736],
              [-39.946296546868581, -8.231067533281216],
              [-39.946296642165251, -8.23106776195511],
              [-39.946296731142873, -8.231067993142192],
              [-39.946296813734783, -8.231068226669031],
              [-39.946296889879065, -8.231068462360705],
              [-39.946296959518683, -8.231068700040531],
              [-39.946297022601406, -8.231068939530443],
              [-39.946297079079983, -8.231069180650874],
              [-39.946297128912072, -8.23106942322112],
              [-39.946297172060326, -8.231069667059542],
              [-39.946297208492425, -8.231069911983187],
              [-39.946297238181046, -8.231070157808514],
              [-39.94629726110395, -8.231070404351422],
              [-39.946297277243929, -8.231070651427039],
              [-39.946297286588944, -8.231070898850284],
              [-39.946297289131934, -8.231071146435534],
              [-39.946297284871015, -8.231071393997482],
              [-39.946297273809378, -8.231071641350423],
              [-39.946297255955322, -8.231071888308936],
              [-39.94629723132222, -8.231072134688125],
              [-39.946297199928544, -8.231072380303177],
              [-39.946297161797816, -8.23107262497007],
              [-39.946297116958597, -8.231072868505423],
              [-39.946297065444504, -8.231073110726701],
              [-39.946297007294156, -8.231073351452514],
              [-39.946296942551122, -8.231073590502223],
              [-39.946296871263939, -8.231073827696806],
              [-39.946296793485999, -8.231074062858433],
              [-39.946296709275643, -8.231074295810949],
              [-39.946296618695932, -8.231074526379665],
              [-39.946296521814787, -8.231074754391869],
              [-39.946296418704804, -8.231074979676597],
              [-39.946296309443277, -8.231075202065037],
              [-39.946296194112065, -8.231075421390528],
              [-39.946296072797601, -8.231075637488654],
              [-39.94629594559084, -8.231075850197522],
              [-39.946295812587095, -8.231076059357635],
              [-39.946295673886063, -8.231076264812394],
              [-39.946295529591666, -8.231076466407648],
              [-39.946295379812064, -8.231076663992333],
              [-39.946295224659522, -8.231076857418429],
              [-39.9462950642503, -8.231077046540998],
              [-39.946294898704629, -8.231077231218203],
              [-39.946294728146576, -8.23107741131164],
              [-39.946294552703975, -8.23107758668645],
              [-39.946294372508291, -8.23107775721108],
              [-39.946294187694605, -8.231077922757866],
              [-39.946293998401408, -8.231078083202563],
              [-39.946293804770555, -8.231078238425004],
              [-39.946293606947194, -8.231078388308839],
              [-39.946293405079551, -8.231078532741812],
              [-39.946293199318973, -8.231078671615579],
              [-39.946292989819611, -8.23107880482614],
              [-39.946292776738503, -8.231078932273629],
              [-39.946292560235342, -8.231079053862546],
              [-39.946292340472382, -8.231079169501662],
              [-39.946292117614341, -8.231079279104454],
              [-39.946291891828224, -8.231079382588737],
              [-39.946291663283247, -8.231079479876838],
              [-39.94629143215073, -8.231079570895973],
              [-39.946291198603852, -8.231079655577863],
              [-39.94629096281767, -8.231079733859078],
              [-39.946290724968904, -8.231079805681055],
              [-39.946290485235799, -8.231079870989676],
              [-39.946290243798025, -8.231079929736225],
              [-39.946290000836527, -8.231079981876713],
              [-39.94628975653341, -8.231080027371908],
              [-39.94628951107174, -8.231080066187769],
              [-39.946289264635524, -8.23108009829517],
              [-39.946289017409399, -8.231080123670079],
              [-39.9462887695787, -8.231080142293608],
              [-39.946288521329173, -8.231080154151597],
              [-39.946288272846836, -8.231080159235292],
              [-39.946288024317901, -8.231080157540807],
              [-39.946287775928688, -8.231080149069408],
              [-39.946287527865309, -8.231080133827538],
              [-39.946287280313697, -8.231080111826458],
              [-39.946287033459377, -8.231080083082807],
              [-39.946286787487352, -8.231080047618091],
              [-39.94628654258198, -8.231080005458821],
              [-39.946286298926793, -8.231079956636695],
              [-39.946286056704395, -8.231079901188259],
              [-39.946285816096349, -8.231079839155068],
              [-39.946285577282971, -8.231079770583595],
              [-39.946285340444348, -8.231079695525032],
              [-39.946285311623242, -8.23107973592851],
              [-39.943690814084654, -8.23021830973099],
              [-39.943690868649348, -8.230218320922539],
              [-39.943690840442862, -8.230218311608803],
              [-39.943690812205908, -8.230218302387117],
              [-39.943690783938813, -8.230218293257584],
              [-39.943690755641889, -8.230218284220301],
              [-39.943690727315399, -8.230218275275309],
              [-39.943690698959699, -8.23021826642263],
              [-39.94369067057503, -8.23021825766256],
              [-39.943690642161755, -8.230218248995076],
              [-39.943690613720158, -8.23021824042026],
              [-39.943690585250529, -8.230218231938336],
              [-39.943690556753168, -8.230218223549233],
              [-39.943690528228437, -8.230218215253013],
              [-39.943690499676578, -8.230218207050019],
              [-39.943690471097923, -8.230218198940044],
              [-39.94369044249278, -8.230218190923393],
              [-39.943690413861439, -8.230218182999931],
              [-39.943690385204221, -8.230218175169995],
              [-39.943690356521429, -8.230218167433513],
              [-39.943690327813393, -8.230218159790617],
              [-39.943690299080387, -8.230218152241315],
              [-39.943690270322733, -8.230218144785836],
              [-39.943690241540729, -8.23021813742414],
              [-39.943690212734694, -8.230218130156274],
              [-39.943690183904955, -8.230218122982416],
              [-39.943690155051776, -8.230218115902609],
              [-39.94369012617549, -8.230218108916899],
              [-39.943690097276424, -8.230218102025512],
              [-39.943690068354869, -8.230218095228214],
              [-39.943690039411109, -8.230218088525406],
              [-39.943690010445508, -8.230218081916954],
              [-39.943689981458363, -8.230218075402997],
              [-39.943689952449937, -8.230218068983595],
              [-39.943689923420607, -8.230218062658851],
              [-39.943689894370621, -8.230218056428848],
              [-39.943689865300335, -8.230218050293486],
              [-39.943689836210055, -8.230218044253048],
              [-39.943689807100064, -8.230218038307449],
              [-39.943689777970718, -8.230218032456758],
              [-39.943689748822301, -8.230218026701191],
              [-39.943689719655126, -8.230218021040631],
              [-39.943689690469505, -8.230218015475227],
              [-39.943689661265743, -8.230218010005016],
              [-39.94368963204419, -8.230218004630025],
              [-39.943689602805115, -8.230217999350383],
              [-39.943689573548866, -8.230217994166102],
              [-39.943689544275728, -8.230217989077286],
              [-39.943689514986033, -8.230217984083923],
              [-39.943689485680089, -8.230217979186204],
              [-39.943689456358207, -8.230217974383921],
              [-39.943689427020701, -8.230217969677376],
              [-39.943689397667882, -8.230217965066455],
              [-39.943689368300092, -8.23021796055127],
              [-39.943689338917622, -8.230217956131909],
              [-39.943689309520771, -8.230217951808422],
              [-39.943689280109886, -8.230217947580753],
              [-39.943689250685267, -8.23021794344899],
              [-39.94368922124724, -8.230217939413315],
              [-39.943689191796111, -8.230217935473544],
              [-39.943689162332163, -8.230217931629843],
              [-39.943689132855773, -8.230217927882316],
              [-39.943689103367234, -8.230217924230868],
              [-39.94368907386685, -8.230217920675578],
              [-39.943689044354919, -8.230217917216558],
              [-39.943689014831797, -8.230217913853785],
              [-39.943688985297797, -8.230217910587202],
              [-39.943688955753196, -8.230217907417037],
              [-39.943688926198348, -8.23021790434322],
              [-39.943688896633581, -8.23021790136584],
              [-39.943688867059173, -8.230217898484788],
              [-39.943688837475442, -8.230217895700191],
              [-39.943688807882737, -8.230217893012112],
              [-39.943688778281377, -8.230217890420546],
              [-39.94368874867164, -8.230217887925512],
              [-39.94368871905386, -8.230217885527091],
              [-39.943688689428377, -8.230217883225272],
              [-39.94368865979547, -8.23021788101998],
              [-39.943688630155478, -8.230217878911382],
              [-39.943688600508729, -8.230217876899401],
              [-39.943688570855521, -8.230217874984117],
              [-39.943688541196181, -8.230217873165628],
              [-39.943688511531022, -8.230217871443729],
              [-39.94368848186037, -8.230217869818626],
              [-39.943688452184531, -8.230217868290303],
              [-39.943688422503833, -8.230217866858787],
              [-39.943688392818601, -8.230217865524017],
              [-39.943688363129134, -8.23021786428605],
              [-39.943688333435745, -8.230217863144944],
              [-39.943688303738774, -8.230217862100645],
              [-39.943688274038536, -8.230217861153204],
              [-39.943688244335355, -8.230217860302535],
              [-39.943688214629539, -8.230217859548837],
              [-39.943688184921371, -8.230217858891926],
              [-39.943688155211241, -8.230217858331955],
              [-39.943688125499406, -8.230217857868872],
              [-39.943688095786236, -8.230217857502639],
              [-39.943688066071992, -8.23021785723329],
              [-39.943688036357038, -8.230217857060895],
              [-39.943688006641658, -8.23021785698535],
              [-39.943687976926213, -8.230217857006673],
              [-39.943687998697712, -8.230217864129086],
              [-39.942899899165425, -8.230219714589136],
              [-39.942899869796086, -8.230219707483684],
              [-39.942899827341911, -8.230219707682183],
              [-39.942899784889136, -8.230219708078572],
              [-39.942899742438662, -8.230219708672665],
              [-39.94289969999145, -8.23021970946454],
              [-39.942899657548431, -8.230219710454115],
              [-39.942899615110491, -8.230219711641467],
              [-39.942899572678627, -8.230219713026433],
              [-39.942899530253705, -8.230219714609188],
              [-39.942899487836677, -8.23021971638955],
              [-39.94289944542848, -8.230219718367378],
              [-39.942899403030026, -8.230219720542927],
              [-39.942899360642244, -8.230219722915887],
              [-39.942899318266065, -8.230219725486226],
              [-39.94289927590242, -8.230219728254072],
              [-39.942899233552239, -8.23021973121916],
              [-39.942899191216419, -8.230219734381633],
              [-39.942899148895918, -8.230219737741256],
              [-39.942899106591646, -8.230219741297988],
              [-39.942899064304527, -8.230219745051757],
              [-39.942899022035505, -8.230219749002478],
              [-39.942898979785475, -8.23021975315014],
              [-39.942898937555391, -8.230219757494535],
              [-39.942898895346161, -8.230219762035613],
              [-39.942898853158702, -8.230219766773352],
              [-39.942898810993931, -8.230219771707551],
              [-39.942898768852814, -8.230219776838155],
              [-39.94289872673621, -8.230219782164959],
              [-39.942898684645087, -8.230219787687966],
              [-39.942898642580346, -8.230219793406981],
              [-39.942898600542897, -8.230219799321954],
              [-39.942898558533706, -8.230219805432617],
              [-39.94289851655364, -8.230219811739051],
              [-39.942898474603638, -8.230219818240862],
              [-39.942898432684608, -8.230219824938121],
              [-39.942898390797481, -8.230219831830583],
              [-39.942898348943153, -8.230219838918149],
              [-39.942898307122562, -8.23021984620055],
              [-39.942898265336616, -8.230219853677731],
              [-39.942898223586226, -8.2302198613495],
              [-39.942898181872295, -8.230219869215706],
              [-39.942898140195751, -8.230219877276202],
              [-39.942898098557492, -8.230219885530696],
              [-39.942898056958455, -8.230219893979058],
              [-39.9428980153995, -8.230219902621281],
              [-39.942897973881593, -8.230219911456835],
              [-39.942897932405607, -8.230219920485858],
              [-39.942897890972446, -8.230219929707969],
              [-39.942897849583041, -8.230219939122986],
              [-39.942897808238286, -8.230219948730792],
              [-39.942897766939069, -8.230219958531039],
              [-39.942897725686322, -8.230219968523562],
              [-39.942897684480904, -8.230219978708257],
              [-39.942897643323768, -8.230219989084754],
              [-39.942897602215794, -8.230219999652878],
              [-39.942897561157857, -8.230220010412358],
              [-39.9428975201509, -8.230220021363074],
              [-39.942897479195771, -8.230220032504709],
              [-39.942897438293414, -8.230220043836951],
              [-39.942897397444689, -8.23022005535972],
              [-39.942897356650469, -8.230220067072551],
              [-39.942897315911722, -8.230220078975398],
              [-39.942897275229264, -8.230220091067917],
              [-39.942897234604025, -8.230220103349792],
              [-39.942897194036874, -8.230220115820812],
              [-39.942897153528712, -8.230220128480644],
              [-39.942897113080427, -8.230220141329077],
              [-39.942897072692894, -8.230220154365867],
              [-39.942897032366993, -8.230220167590629],
              [-39.942896992103599, -8.2302201810031],
              [-39.942896951903627, -8.230220194603071],
              [-39.942896911767924, -8.230220208390119],
              [-39.942896871697378, -8.230220222363977],
              [-39.942896831692856, -8.230220236524405],
              [-39.942896791755253, -8.230220250871005],
              [-39.942896751885435, -8.230220265403588],
              [-39.942896712084256, -8.230220280121671],
              [-39.942896672352589, -8.230220295025115],
              [-39.942896632691344, -8.230220310113401],
              [-39.942896593101352, -8.230220325386332],
              [-39.942896553583473, -8.230220340843584],
              [-39.942896514138582, -8.230220356484727],
              [-39.942896474767551, -8.230220372309459],
              [-39.942896435471233, -8.230220388317438],
              [-39.942896396250482, -8.230220404508358],
              [-39.942896357106171, -8.230220420881727],
              [-39.942896318039146, -8.230220437437463],
              [-39.94289627905026, -8.230220454174834],
              [-39.94289624014035, -8.230220471093762],
              [-39.942896201310297, -8.230220488193732],
              [-39.942896162560942, -8.230220505474476],
              [-39.942896123893114, -8.230220522935484],
              [-39.942896085307687, -8.230220540576539],
              [-39.942896046805487, -8.230220558397049],
              [-39.942896008387365, -8.230220576396855],
              [-39.942895970054124, -8.230220594575353],
              [-39.942895931806667, -8.230220612932287],
              [-39.942895893645783, -8.2302206314671],
              [-39.942895855572324, -8.230220650179623],
              [-39.942895817587114, -8.230220669069274],
              [-39.94289581621878, -8.230220719045519],
              [-39.941997029905863, -8.230670243701534],
              [-39.941997017912733, -8.230670249471304],
              [-39.941996958625943, -8.230670279513957],
              [-39.941996899561651, -8.230670309988501],
              [-39.941996840723036, -8.23067034089326],
              [-39.941996782113236, -8.230670372226649],
              [-39.9419967237354, -8.230670403986858],
              [-39.941996665592683, -8.230670436172201],
              [-39.941996607688168, -8.230670468781108],
              [-39.941996550024975, -8.230670501811582],
              [-39.941996492606215, -8.230670535262087],
              [-39.941996435434959, -8.230670569130652],
              [-39.941996378514276, -8.230670603415563],
              [-39.941996321847228, -8.230670638114933],
              [-39.941996265436828, -8.230670673226928],
              [-39.94199620928611, -8.230670708749647],
              [-39.941996153398129, -8.230670744681097],
              [-39.941996097775842, -8.230670781019557],
              [-39.941996042422232, -8.23067081776292],
              [-39.941995987340299, -8.230670854909324],
              [-39.94199593253299, -8.230670892456594],
              [-39.941995878003233, -8.230670930402907],
              [-39.941995823753928, -8.230670968746086],
              [-39.941995769788043, -8.230671007484146],
              [-39.941995716108458, -8.230671046615003],
              [-39.941995662718035, -8.230671086136585],
              [-39.941995609619639, -8.230671126046662],
              [-39.941995556816146, -8.230671166343246],
              [-39.941995504310363, -8.230671207024066],
              [-39.941995452105111, -8.230671248086914],
              [-39.941995400203204, -8.230671289529743],
              [-39.941995348607414, -8.23067133135017],
              [-39.941995297320517, -8.230671373545896],
              [-39.941995246345279, -8.230671416114998],
              [-39.941995195684392, -8.230671459054809],
              [-39.941995145340627, -8.23067150236324],
              [-39.941995095316649, -8.230671546037859],
              [-39.941995045615165, -8.230671590076389],
              [-39.941994996238812, -8.230671634476433],
              [-39.941994947190274, -8.230671679235694],
              [-39.941994898472181, -8.230671724351634],
              [-39.941994850087106, -8.230671769821921],
              [-39.941994802037691, -8.230671815644085],
              [-39.941994754326501, -8.230671861815656],
              [-39.941994706956081, -8.230671908334132],
              [-39.941994659928987, -8.230671955197149],
              [-39.941994613247751, -8.230672002402049],
              [-39.941994566914843, -8.230672049946355],
              [-39.941994520932802, -8.230672097827474],
              [-39.94199447530405, -8.230672146042949],
              [-39.941994430031052, -8.23067219459014],
              [-39.94199438511626, -8.230672243466358],
              [-39.941994340562033, -8.230672292669],
              [-39.941994296370794, -8.230672342195504],
              [-39.941994252544937, -8.230672392043218],
              [-39.941994209086758, -8.230672442209341],
              [-39.941994165998622, -8.230672492691426],
              [-39.941994123282846, -8.230672543486428],
              [-39.941994080941726, -8.230672594591889],
              [-39.941994038977505, -8.230672646004887],
              [-39.941993997392466, -8.230672697722829],
              [-39.94199395618881, -8.230672749742801],
              [-39.941993915368784, -8.230672802062159],
              [-39.941993874934539, -8.230672854677946],
              [-39.941993834888294, -8.230672907587437],
              [-39.94199379523215, -8.230672960787652],
              [-39.941993755968241, -8.230673014275927],
              [-39.94199371709869, -8.230673068049363],
              [-39.9419936786256, -8.230673122104939],
              [-39.941993640551011, -8.230673176439762],
              [-39.941993602876963, -8.230673231051059],
              [-39.941993565605486, -8.2306732859359],
              [-39.941993528738578, -8.23067334109119],
              [-39.941993492278208, -8.230673396514073],
              [-39.941993456226363, -8.230673452201524],
              [-39.941993420584943, -8.230673508150579],
              [-39.9419933853559, -8.230673564358224],
              [-39.941993350541075, -8.230673620821493],
              [-39.941993316142359, -8.230673677537295],
              [-39.941993282161611, -8.230673734502567],
              [-39.941993248600639, -8.230673791714374],
              [-39.941993215461238, -8.230673849169483],
              [-39.941993182745215, -8.230673906864903],
              [-39.941993150454287, -8.230673964797555],
              [-39.941993118590226, -8.230674022964356],
              [-39.941993087154714, -8.230674081362146],
              [-39.941993056149443, -8.230674139987652],
              [-39.941993025576075, -8.230674198837978],
              [-39.94199299543628, -8.230674257909767],
              [-39.941992965731622, -8.230674317199954],
              [-39.941992936463741, -8.230674376705291],
              [-39.941992907634187, -8.230674436422667],
              [-39.941992879244509, -8.230674496348835],
              [-39.941992851296241, -8.230674556480553],
              [-39.941992823790855, -8.230674616814651],
              [-39.941992796729878, -8.230674677347826],
              [-39.941992770114702, -8.23067473807685],
              [-39.941992743946798, -8.23067479899848],
              [-39.941992718227532, -8.230674860109447],
              [-39.94199269295833, -8.230674921406456],
              [-39.941992668140522, -8.230674982886201],
              [-39.941992671366137, -8.230674962702599],
              [-39.941544843988723, -8.231796240701344],
              [-39.941544869166677, -8.231796242345366],
              [-39.941544856312426, -8.231796274712753],
              [-39.941544843583301, -8.231796307129141],
              [-39.941544830979502, -8.231796339594057],
              [-39.941544818501214, -8.23179637210708],
              [-39.941544806148627, -8.231796404667735],
              [-39.941544793921921, -8.231796437275515],
              [-39.941544781821285, -8.231796469929916],
              [-39.941544769846878, -8.231796502630518],
              [-39.941544757998898, -8.231796535376747],
              [-39.941544746277494, -8.231796568168079],
              [-39.941544734682871, -8.231796601004259],
              [-39.941544723215195, -8.231796633884555],
              [-39.941544711874613, -8.231796666808602],
              [-39.941544700661318, -8.231796699775934],
              [-39.941544689575466, -8.231796732785924],
              [-39.941544678617227, -8.231796765838233],
              [-39.941544667786751, -8.231796798932278],
              [-39.941544657084215, -8.231796832067632],
              [-39.94154464650974, -8.231796865243757],
              [-39.941544636063526, -8.231796898460182],
              [-39.941544625745713, -8.231796931716463],
              [-39.94154461555646, -8.23179696501195],
              [-39.941544605495906, -8.231796998346406],
              [-39.94154459556421, -8.231797031719086],
              [-39.941544585761498, -8.231797065129635],
              [-39.941544576087949, -8.231797098577504],
              [-39.941544566543676, -8.231797132062225],
              [-39.941544557128836, -8.231797165583293],
              [-39.94154454784357, -8.23179719914015],
              [-39.941544538688007, -8.231797232732491],
              [-39.941544529662288, -8.231797266359528],
              [-39.941544520766548, -8.231797300021004],
              [-39.941544512000924, -8.231797333716372],
              [-39.941544503365506, -8.23179736744507],
              [-39.94154449486048, -8.231797401206656],
              [-39.941544486485931, -8.2317974350005],
              [-39.941544478242015, -8.23179746882631],
              [-39.941544470128825, -8.231797502683389],
              [-39.941544462146503, -8.231797536571356],
              [-39.941544454295148, -8.231797570489714],
              [-39.941544446574881, -8.231797604437917],
              [-39.941544438985829, -8.23179763841553],
              [-39.941544431528094, -8.231797672421912],
              [-39.941544424201787, -8.231797706456573],
              [-39.941544417007023, -8.231797740519159],
              [-39.941544409943909, -8.231797774609102],
              [-39.941544403012536, -8.231797808725789],
              [-39.941544396213018, -8.231797842868867],
              [-39.941544389545449, -8.231797877037749],
              [-39.941544383009926, -8.231797911231894],
              [-39.941544376606586, -8.231797945450896],
              [-39.941544370335464, -8.231797979694184],
              [-39.941544364196673, -8.231798013961217],
              [-39.941544358190313, -8.231798048251646],
              [-39.941544352316463, -8.231798082564739],
              [-39.941544346575213, -8.231798116900116],
              [-39.941544340966672, -8.231798151257349],
              [-39.941544335490889, -8.231798185635716],
              [-39.941544330147963, -8.231798220034833],
              [-39.941544324937958, -8.231798254454251],
              [-39.941544319860974, -8.231798288893359],
              [-39.941544314917067, -8.231798323351713],
              [-39.941544310106316, -8.231798357828707],
              [-39.941544305428792, -8.231798392323849],
              [-39.941544300884573, -8.231798426836775],
              [-39.94154429647373, -8.231798461366841],
              [-39.941544292196291, -8.231798495913505],
              [-39.941544288052349, -8.231798530476402],
              [-39.941544284041974, -8.231798565054824],
              [-39.941544280165232, -8.231798599648393],
              [-39.941544276422135, -8.231798634256673],
              [-39.941544272812763, -8.231798668879016],
              [-39.941544269337179, -8.231798703514869],
              [-39.941544265995425, -8.231798738163809],
              [-39.941544262787559, -8.231798772825352],
              [-39.941544259713623, -8.231798807498913],
              [-39.941544256773639, -8.231798842183965],
              [-39.941544253967699, -8.231798876880134],
              [-39.941544251295809, -8.231798911586646],
              [-39.941544248758021, -8.231798946303323],
              [-39.941544246354368, -8.231798981029389],
              [-39.941544244084902, -8.23179901576434],
              [-39.941544241949615, -8.231799050507853],
              [-39.941544239948591, -8.231799085259185],
              [-39.941544238081818, -8.23179912001809],
              [-39.941544236349351, -8.23179915478374],
              [-39.941544234751198, -8.231799189555829],
              [-39.941544233287381, -8.231799224333832],
              [-39.941544231957955, -8.231799259117153],
              [-39.941544230762894, -8.231799293905311],
              [-39.941544229702245, -8.231799328697772],
              [-39.941544228776017, -8.231799363494092],
              [-39.941544227984217, -8.231799398293672],
              [-39.941544227326865, -8.231799433096038],
              [-39.941544226803963, -8.231799467900641],
              [-39.941544226415516, -8.231799502706959],
              [-39.941544226161547, -8.231799537514643],
              [-39.941544226042048, -8.231799572322954],
              [-39.941544226057033, -8.231799607131443],
              [-39.941544216398633, -8.231799588566833],
              [-39.941546600038308, -8.232808746951711],
              [-39.941546609712141, -8.232808772024715],
              [-39.941546609731027, -8.232808803932768],
              [-39.941546609636887, -8.232808835840633],
              [-39.941546609429771, -8.232808867747952],
              [-39.941546609109672, -8.232808899654371],
              [-39.941546608676553, -8.232808931559527],
              [-39.941546608130473, -8.232808963462821],
              [-39.941546607471416, -8.232808995364058],
              [-39.941546606699376, -8.232809027262848],
              [-39.941546605814388, -8.232809059158647],
              [-39.941546604816452, -8.232809091051195],
              [-39.941546603705589, -8.232809122939893],
              [-39.941546602481786, -8.232809154824643],
              [-39.94154660114507, -8.232809186704845],
              [-39.941546599695478, -8.232809218580174],
              [-39.941546598133016, -8.232809250450153],
              [-39.941546596457712, -8.23280928231452],
              [-39.941546594669553, -8.232809314172751],
              [-39.941546592768589, -8.232809346024506],
              [-39.941546590754854, -8.232809377869398],
              [-39.941546588628341, -8.232809409706976],
              [-39.941546586389101, -8.232809441536952],
              [-39.94154658403717, -8.232809473358834],
              [-39.941546581572553, -8.232809505172154],
              [-39.941546578995279, -8.232809536976758],
              [-39.941546576305392, -8.23280956877206],
              [-39.941546573502926, -8.232809600557667],
              [-39.941546570587917, -8.232809632333229],
              [-39.941546567560401, -8.232809664098374],
              [-39.941546564420406, -8.232809695852696],
              [-39.941546561167982, -8.232809727595745],
              [-39.941546557803171, -8.232809759327145],
              [-39.941546554325996, -8.232809791046581],
              [-39.941546550736533, -8.232809822753643],
              [-39.94154654703479, -8.232809854447744],
              [-39.941546543220845, -8.232809886128731],
              [-39.94154653929472, -8.232809917796171],
              [-39.941546535256478, -8.232809949449535],
              [-39.941546531106169, -8.23280998108854],
              [-39.941546526843844, -8.23281001271268],
              [-39.941546522469544, -8.232810044321717],
              [-39.941546517983333, -8.232810075915216],
              [-39.941546513385283, -8.23281010749267],
              [-39.941546508675422, -8.232810139053827],
              [-39.941546503853814, -8.23281017059816],
              [-39.941546498920545, -8.23281020212549],
              [-39.941546493875649, -8.232810233635174],
              [-39.941546488719197, -8.232810265126945],
              [-39.941546483451269, -8.232810296600427],
              [-39.941546478071892, -8.232810328055184],
              [-39.941546472581166, -8.232810359490829],
              [-39.94154646697914, -8.232810390906941],
              [-39.941546461265929, -8.232810422303231],
              [-39.941546455441539, -8.232810453679251],
              [-39.941546449506085, -8.232810485034571],
              [-39.941546443459622, -8.232810516368877],
              [-39.941546437302243, -8.232810547681639],
              [-39.941546431033998, -8.232810578972597],
              [-39.94154642465498, -8.23281061024141],
              [-39.941546418165274, -8.232810641487582],
              [-39.941546411564964, -8.23281067271067],
              [-39.941546404854122, -8.232810703910433],
              [-39.94154639803282, -8.232810735086449],
              [-39.941546391101156, -8.232810766238241],
              [-39.94154638405923, -8.232810797365513],
              [-39.941546376907105, -8.232810828467809],
              [-39.941546369644897, -8.232810859544754],
              [-39.941546362272661, -8.232810890596033],
              [-39.941546354790525, -8.232810921621221],
              [-39.941546347198553, -8.232810952619795],
              [-39.94154633949686, -8.232810983591593],
              [-39.941546331685529, -8.232811014536168],
              [-39.941546323764648, -8.232811045453056],
              [-39.94154631573435, -8.232811076341878],
              [-39.941546307594706, -8.232811107202286],
              [-39.941546299345831, -8.232811138033922],
              [-39.941546290987809, -8.232811168836335],
              [-39.941546282520783, -8.232811199609234],
              [-39.941546273944816, -8.232811230352203],
              [-39.941546265260037, -8.232811261064747],
              [-39.941546256466545, -8.232811291746618],
              [-39.941546247564453, -8.232811322397374],
              [-39.941546238553862, -8.232811353016697],
              [-39.941546229434906, -8.232811383604098],
              [-39.941546220207691, -8.232811414159391],
              [-39.941546210872332, -8.23281144468192],
              [-39.941546201428913, -8.232811475171479],
              [-39.941546191877585, -8.232811505627753],
              [-39.941546182218495, -8.232811536050182],
              [-39.941546172451702, -8.232811566438514],
              [-39.941546162577353, -8.232811596792242],
              [-39.941546152595571, -8.232811627111149],
              [-39.941546142506482, -8.232811657394778],
              [-39.941546132310222, -8.232811687642828],
              [-39.941546122006905, -8.232811717854771],
              [-39.941546111596672, -8.232811748030347],
              [-39.941546101079631, -8.23281177816907],
              [-39.94154609045593, -8.2328118082707],
              [-39.941546079725683, -8.232811838334777],
              [-39.941546068889068, -8.232811868360946],
              [-39.941546062805379, -8.232811913719216],
              [-39.94110075169219, -8.234038913584213],
            ],
          ],
          [
            [
              [-39.932320751425735, -8.234491774826209],
              [-39.933391363585642, -8.234549765859727],
              [-39.933128846969083, -8.233965355700741],
              [-39.933128871922882, -8.233965377053565],
              [-39.933128805466872, -8.233965232529018],
              [-39.933128736471367, -8.233965089190503],
              [-39.933128664957678, -8.233964947082113],
              [-39.933128590947902, -8.233964806247895],
              [-39.933128514464926, -8.233964666731199],
              [-39.933128435532346, -8.233964528575315],
              [-39.933128354174549, -8.233964391822836],
              [-39.933128270416695, -8.233964256515968],
              [-39.933128184284634, -8.233964122696529],
              [-39.933128095804989, -8.233963990405988],
              [-39.933128005005095, -8.233963859685032],
              [-39.93312791191299, -8.233963730574136],
              [-39.933127816557445, -8.2339636031132],
              [-39.93312771896791, -8.233963477341517],
              [-39.933127619174527, -8.233963353298041],
              [-39.933127517208156, -8.23396323102102],
              [-39.933127413100273, -8.233963110548222],
              [-39.933127306883037, -8.233962991916966],
              [-39.933127198589276, -8.233962875163911],
              [-39.933127088252441, -8.233962760324896],
              [-39.93312697590661, -8.233962647435625],
              [-39.933126861586501, -8.233962536530926],
              [-39.933126745327428, -8.233962427644988],
              [-39.933126627165308, -8.233962320811548],
              [-39.933126507136635, -8.233962216063555],
              [-39.933126385278506, -8.233962113433265],
              [-39.933126261628544, -8.233962012952619],
              [-39.933126136224985, -8.233961914652486],
              [-39.933126009106537, -8.233961818563312],
              [-39.933125880312488, -8.233961724714776],
              [-39.933125749882613, -8.233961633135742],
              [-39.933125617857215, -8.23396154385466],
              [-39.933125484277078, -8.233961456899083],
              [-39.933125349183477, -8.233961372295854],
              [-39.933125212618151, -8.233961290071019],
              [-39.933125074623256, -8.23396121025014],
              [-39.933124935241459, -8.233961132857798],
              [-39.933124794515798, -8.233961057917945],
              [-39.933124652489774, -8.233960985453646],
              [-39.933124509207218, -8.233960915487307],
              [-39.933124364712441, -8.233960848040558],
              [-39.93312421905005, -8.23396078313433],
              [-39.93312407226508, -8.23396072078854],
              [-39.933123924402835, -8.233960661022515],
              [-39.933123775509017, -8.23396060385469],
              [-39.933123625629619, -8.233960549302788],
              [-39.933123474810948, -8.233960497383483],
              [-39.933123323099608, -8.233960448113081],
              [-39.933123170542451, -8.233960401506648],
              [-39.933123017186603, -8.233960357578541],
              [-39.93312286307944, -8.233960316342419],
              [-39.933122708268591, -8.233960277811075],
              [-39.933122552801862, -8.23396024199617],
              [-39.933122396727299, -8.2339602089091],
              [-39.933122240093098, -8.233960178559846],
              [-39.933122082947655, -8.233960150957815],
              [-39.93312192533952, -8.233960126111665],
              [-39.933121767317395, -8.233960104028847],
              [-39.933121608930094, -8.233960084716434],
              [-39.933121450226523, -8.233960068180215],
              [-39.933121291255745, -8.233960054425406],
              [-39.93312113206683, -8.233960043456166],
              [-39.933120972709013, -8.233960035275905],
              [-39.933120813231461, -8.233960029887287],
              [-39.933120653683474, -8.233960027291683],
              [-39.933120494114362, -8.233960027490205],
              [-39.933120334573395, -8.233960030482622],
              [-39.933120175109849, -8.233960036268094],
              [-39.933120015773035, -8.233960044844705],
              [-39.933119856612116, -8.233960056209964],
              [-39.933119697676304, -8.233960070360189],
              [-39.933119539014683, -8.23396008729115],
              [-39.933119380676288, -8.233960106997616],
              [-39.93311922270999, -8.233960129473477],
              [-39.933119065164647, -8.233960154711653],
              [-39.933118908088879, -8.233960182704541],
              [-39.933118751531254, -8.233960213443321],
              [-39.933118595540108, -8.233960246918697],
              [-39.933118440163632, -8.233960283120133],
              [-39.933118285449858, -8.233960322036561],
              [-39.933118131446541, -8.233960363655942],
              [-39.933117978201295, -8.233960407965419],
              [-39.933117825761443, -8.233960454951216],
              [-39.933117674174071, -8.233960504598937],
              [-39.933117523486011, -8.233960556893178],
              [-39.933117373743841, -8.233960611817862],
              [-39.933117224993786, -8.233960669355918],
              [-39.933117077281821, -8.233960729489629],
              [-39.933116930653568, -8.233960792200449],
              [-39.933116785154347, -8.233960857468878],
              [-39.933116640829084, -8.233960925274866],
              [-39.933116497722359, -8.233960995597389],
              [-39.93311635587844, -8.233961068414766],
              [-39.933116215341052, -8.233961143704498],
              [-39.933116076153681, -8.233961221443398],
              [-39.933115938359336, -8.23396130160735],
              [-39.933115802000529, -8.233961384171632],
              [-39.933115667119402, -8.233961469110707],
              [-39.933115533757672, -8.233961556398301],
              [-39.933115492601374, -8.233961588114155],
              [-39.932320751425735, -8.234491774826209],
            ],
          ],
          [
            [
              [-39.928238548110734, -8.234270630713644],
              [-39.931764007137382, -8.234461616940344],
              [-39.932216458311785, -8.23373720612746],
              [-39.932216492542246, -8.233737174475277],
              [-39.932216544327396, -8.23373709054321],
              [-39.932216595191775, -8.233737006054252],
              [-39.932216645129309, -8.233736921018188],
              [-39.932216694134098, -8.233736835445409],
              [-39.932216742200318, -8.233736749345837],
              [-39.932216789322261, -8.233736662729758],
              [-39.932216835494373, -8.233736575607493],
              [-39.932216880711096, -8.233736487989407],
              [-39.932216924967129, -8.233736399885748],
              [-39.932216968257201, -8.233736311307176],
              [-39.932217010576188, -8.233736222263994],
              [-39.932217051919054, -8.233736132766882],
              [-39.932217092280901, -8.233736042826445],
              [-39.93221713165692, -8.233735952453358],
              [-39.93221717004247, -8.233735861658262],
              [-39.932217207432984, -8.233735770452105],
              [-39.932217243824006, -8.233735678845605],
              [-39.932217279211244, -8.233735586849539],
              [-39.932217313590492, -8.233735494474951],
              [-39.932217346957664, -8.233735401732821],
              [-39.932217379308803, -8.233735308634072],
              [-39.932217410640064, -8.233735215189762],
              [-39.932217440947753, -8.233735121411003],
              [-39.93221747022826, -8.233735027308972],
              [-39.932217498478096, -8.233734932894745],
              [-39.932217525693929, -8.233734838179588],
              [-39.93221755187254, -8.23373474317472],
              [-39.932217577010768, -8.233734647891305],
              [-39.932217601105684, -8.233734552340822],
              [-39.932217624154418, -8.233734456534565],
              [-39.932217646154236, -8.233734360483808],
              [-39.932217667102513, -8.233734264200084],
              [-39.932217686996779, -8.233734167694797],
              [-39.932217705834645, -8.233734070979216],
              [-39.93221772361391, -8.233733974065048],
              [-39.932217740332447, -8.233733876963583],
              [-39.93221775598829, -8.233733779686521],
              [-39.932217770579562, -8.233733682245319],
              [-39.932217784104537, -8.233733584651574],
              [-39.932217796561595, -8.233733486916861],
              [-39.932217807949279, -8.233733389052775],
              [-39.932217818266253, -8.233733291070877],
              [-39.932217827511252, -8.233733192982855],
              [-39.932217835683197, -8.233733094800362],
              [-39.932217842781128, -8.233732996534915],
              [-39.932217848804207, -8.233732898198411],
              [-39.932217853751688, -8.233732799802276],
              [-39.932217857623009, -8.233732701358338],
              [-39.932217860417701, -8.233732602878218],
              [-39.932217862135452, -8.233732504373645],
              [-39.932217862776042, -8.233732405856156],
              [-39.932217862339364, -8.233732307337666],
              [-39.932217860825531, -8.23373220882973],
              [-39.932217858234687, -8.233732110344105],
              [-39.932217854567135, -8.233732011892466],
              [-39.932217849823324, -8.23373191348635],
              [-39.932217844003823, -8.233731815137649],
              [-39.932217837109299, -8.233731716857886],
              [-39.932217829140612, -8.233731618658785],
              [-39.93221782009865, -8.233731520551981],
              [-39.932217809984522, -8.233731422549068],
              [-39.932217798799421, -8.233731324661761],
              [-39.932217786544705, -8.233731226901668],
              [-39.932217773221751, -8.233731129280359],
              [-39.932217758832209, -8.233731031809402],
              [-39.932217743377755, -8.233730934500473],
              [-39.932217726860237, -8.233730837364808],
              [-39.932217709281616, -8.233730740414327],
              [-39.932217690643974, -8.233730643660307],
              [-39.932217670949512, -8.233730547114231],
              [-39.932217650200585, -8.233730450787681],
              [-39.932217628399641, -8.233730354692012],
              [-39.932217605549255, -8.233730258838536],
              [-39.932217581652168, -8.233730163238738],
              [-39.932217556711201, -8.233730067903927],
              [-39.932217530729311, -8.233729972845488],
              [-39.932217503709587, -8.23372987807455],
              [-39.932217475655236, -8.233729783602556],
              [-39.932217446569588, -8.233729689440556],
              [-39.932217416456069, -8.23372959559976],
              [-39.932217385318289, -8.233729502091276],
              [-39.93221735315992, -8.233729408926296],
              [-39.932217319984787, -8.233729316115781],
              [-39.932217285796831, -8.23372922367076],
              [-39.93221725060009, -8.233729131602336],
              [-39.932217214398747, -8.233729039921235],
              [-39.932217177197103, -8.233728948638408],
              [-39.932217138999583, -8.233728857764634],
              [-39.9322170998107, -8.233728767310833],
              [-39.932217059635128, -8.23372867728774],
              [-39.932217018477587, -8.233728587705853],
              [-39.932216976343021, -8.233728498576001],
              [-39.932216933236376, -8.233728409908615],
              [-39.932216889162817, -8.233728321714256],
              [-39.932216844127524, -8.233728234003417],
              [-39.932216798135869, -8.233728146786438],
              [-39.932216751193309, -8.233728060073803],
              [-39.932216703305414, -8.233727973875729],
              [-39.932216654477848, -8.233727888202406],
              [-39.93221661809347, -8.23372789009519],
              [-39.931767951981506, -8.232950485658355],
              [-39.931767934798046, -8.23295045051842],
              [-39.931767884651762, -8.232950362500276],
              [-39.931767835497254, -8.232950273928672],
              [-39.931767787340682, -8.232950184814538],
              [-39.9317677401881, -8.232950095169238],
              [-39.931767694045377, -8.232950005003842],
              [-39.931767648918324, -8.232949914329753],
              [-39.931767604812606, -8.232949823158235],
              [-39.931767561733693, -8.23294973150081],
              [-39.931767519687021, -8.232949639368801],
              [-39.931767478677827, -8.232949546773947],
              [-39.931767438711269, -8.232949453727601],
              [-39.931767399792321, -8.232949360241626],
              [-39.931767361925907, -8.232949266327589],
              [-39.931767325116716, -8.232949171997317],
              [-39.931767289369411, -8.232949077262663],
              [-39.93176725468841, -8.232948982135463],
              [-39.931767221078111, -8.232948886627526],
              [-39.931767188542672, -8.232948790750903],
              [-39.931767157086199, -8.232948694517619],
              [-39.931767126712629, -8.232948597939641],
              [-39.931767097425741, -8.232948501029124],
              [-39.931767069229245, -8.232948403798185],
              [-39.931767042126623, -8.232948306259013],
              [-39.931767016121299, -8.232948208423885],
              [-39.931766991216534, -8.232948110304921],
              [-39.931766967415427, -8.232948011914447],
              [-39.93176694472097, -8.23294791326488],
              [-39.931766923135996, -8.232947814368456],
              [-39.931766902663227, -8.232947715237559],
              [-39.931766883305208, -8.232947615884573],
              [-39.931766865064368, -8.232947516322167],
              [-39.931766847942974, -8.232947416562592],
              [-39.931766831943207, -8.232947316618374],
              [-39.93176681706705, -8.232947216502053],
              [-39.931766803316357, -8.232947116226205],
              [-39.931766790692869, -8.232947015803292],
              [-39.931766779198149, -8.232946915245995],
              [-39.931766768833675, -8.232946814566784],
              [-39.93176675960067, -8.232946713778398],
              [-39.931766751500348, -8.232946612893416],
              [-39.931766744533718, -8.23294651192443],
              [-39.931766738701647, -8.232946410884033],
              [-39.931766734004846, -8.232946309785037],
              [-39.93176673044394, -8.232946208639991],
              [-39.93176672801934, -8.232946107461549],
              [-39.931766726731368, -8.232946006262496],
              [-39.931766726580179, -8.232945905055258],
              [-39.931766727565815, -8.232945803852838],
              [-39.931766729688114, -8.232945702667667],
              [-39.931766732946819, -8.232945601512474],
              [-39.931766737341547, -8.232945500400001],
              [-39.93176674287173, -8.232945399342826],
              [-39.931766749536671, -8.232945298353606],
              [-39.931766757335531, -8.232945197445025],
              [-39.931766766267373, -8.232945096629702],
              [-39.931766776331038, -8.232944995920244],
              [-39.931766787525262, -8.232944895329325],
              [-39.931766799848688, -8.232944794869409],
              [-39.93176681329971, -8.232944694553238],
              [-39.9317668278767, -8.232944594393278],
              [-39.93176684357779, -8.232944494402091],
              [-39.931766860401062, -8.232944394592161],
              [-39.931766878344369, -8.232944294976006],
              [-39.931766897405453, -8.23294419556616],
              [-39.931766917581982, -8.232944096375048],
              [-39.931766938871391, -8.232943997415065],
              [-39.931766961271002, -8.232943898698593],
              [-39.931766984778058, -8.232943800238061],
              [-39.931767009389574, -8.232943702045651],
              [-39.931767035102482, -8.232943604133739],
              [-39.931767061913575, -8.232943506514694],
              [-39.931767089819452, -8.232943409200622],
              [-39.931767118816666, -8.232943312203712],
              [-39.931767148901564, -8.232943215536119],
              [-39.931767180070395, -8.232943119209976],
              [-39.931767212319237, -8.232943023237306],
              [-39.93176724564406, -8.232942927630152],
              [-39.931767280040695, -8.232942832400459],
              [-39.931767315504807, -8.232942737560185],
              [-39.931767352032004, -8.232942643121266],
              [-39.931767389617676, -8.232942549095393],
              [-39.931767428257118, -8.232942455494415],
              [-39.931767467945519, -8.232942362330016],
              [-39.931767508677879, -8.232942269613867],
              [-39.931767550449123, -8.232942177357657],
              [-39.931767593254001, -8.232942085572867],
              [-39.931767637087141, -8.232941994271018],
              [-39.9317676819431, -8.232941903463507],
              [-39.931767727816215, -8.232941813161755],
              [-39.931767774700759, -8.23294172337696],
              [-39.931767822590864, -8.232941634120477],
              [-39.931767871480545, -8.23294154540352],
              [-39.931767921363644, -8.232941457237047],
              [-39.931767972233963, -8.23294136963227],
              [-39.931768024085088, -8.232941282599977],
              [-39.931768076910537, -8.232941196151156],
              [-39.931768130703709, -8.232941110296636],
              [-39.931768185457884, -8.232941025047207],
              [-39.931768241165706, -8.232940940414116],
              [-39.931768202137924, -8.232940988528439],
              [-39.932666641174208, -8.231592563995592],
              [-39.933567788429713, -8.230240061786985],
              [-39.933567756661695, -8.230240071023704],
              [-39.93356776296396, -8.230240061551607],
              [-39.933567769254275, -8.23024005207159],
              [-39.933567775532623, -8.230240042583731],
              [-39.933567781799006, -8.230240033087949],
              [-39.933567788053409, -8.230240023584406],
              [-39.933567794295818, -8.230240014073031],
              [-39.933567800526227, -8.230240004553915],
              [-39.933567806744634, -8.230239995026858],
              [-39.933567812950997, -8.230239985492149],
              [-39.933567819145352, -8.230239975949585],
              [-39.933567825327664, -8.230239966399363],
              [-39.933567831497918, -8.230239956841324],
              [-39.933567837656113, -8.230239947275695],
              [-39.933567843802244, -8.23023993770221],
              [-39.933567849936296, -8.230239928121172],
              [-39.933567856058254, -8.23023991853238],
              [-39.933567862168125, -8.230239908935934],
              [-39.933567868265861, -8.230239899331835],
              [-39.933567874351489, -8.230239889720162],
              [-39.933567880425016, -8.230239880100907],
              [-39.933567886486379, -8.230239870474012],
              [-39.933567892535606, -8.230239860839532],
              [-39.933567898572662, -8.230239851197503],
              [-39.933567904597581, -8.230239841547887],
              [-39.9335679106103, -8.230239831890815],
              [-39.933567916610841, -8.23023982222616],
              [-39.933567922599188, -8.230239812554005],
              [-39.933567928575336, -8.230239802874296],
              [-39.933567934539269, -8.230239793187216],
              [-39.933567940490974, -8.230239783492637],
              [-39.933567946430458, -8.230239773790673],
              [-39.933567952357684, -8.230239764081242],
              [-39.933567958272668, -8.230239754364412],
              [-39.933567964175396, -8.230239744640127],
              [-39.933567970065852, -8.230239734908512],
              [-39.93356797594403, -8.230239725169531],
              [-39.933567981809936, -8.230239715423245],
              [-39.93356798766353, -8.230239705669478],
              [-39.933567993504809, -8.230239695908512],
              [-39.933567999333775, -8.230239686140246],
              [-39.93356800515042, -8.230239676364679],
              [-39.933568010954737, -8.230239666581815],
              [-39.933568016746705, -8.230239656791651],
              [-39.93356802252633, -8.230239646994287],
              [-39.933568028293578, -8.230239637189708],
              [-39.933568034048456, -8.230239627377935],
              [-39.933568039790977, -8.23023961755894],
              [-39.933568045521092, -8.23023960773275],
              [-39.933568051238815, -8.230239597899448],
              [-39.933568056944125, -8.230239588058931],
              [-39.933568062637029, -8.230239578211297],
              [-39.933568068317491, -8.230239568356549],
              [-39.933568073985541, -8.23023955849469],
              [-39.933568079641134, -8.230239548625802],
              [-39.933568085284278, -8.230239538749776],
              [-39.933568090914974, -8.230239528866761],
              [-39.933568096533186, -8.230239518976614],
              [-39.933568102138921, -8.230239509079519],
              [-39.933568107732171, -8.230239499175331],
              [-39.933568113312937, -8.230239489264195],
              [-39.933568118881169, -8.23023947934613],
              [-39.93356812443691, -8.230239469421022],
              [-39.933568129980138, -8.230239459489081],
              [-39.933568135510811, -8.230239449550131],
              [-39.933568141028957, -8.230239439604219],
              [-39.93356814653454, -8.230239429651499],
              [-39.93356815202759, -8.230239419691813],
              [-39.933568157508056, -8.230239409725387],
              [-39.933568162975959, -8.230239399752012],
              [-39.933568168431272, -8.230239389771794],
              [-39.933568173873986, -8.230239379784836],
              [-39.933568179304125, -8.23023936979093],
              [-39.933568184721629, -8.230239359790369],
              [-39.933568190126543, -8.230239349782957],
              [-39.933568195518809, -8.230239339768808],
              [-39.933568200898435, -8.23023932974788],
              [-39.933568206265427, -8.230239319720312],
              [-39.933568211619779, -8.230239309685969],
              [-39.933568216961454, -8.23023929964498],
              [-39.933568222290468, -8.230239289597215],
              [-39.933568227606806, -8.230239279542896],
              [-39.933568232910446, -8.230239269481897],
              [-39.933568238201417, -8.230239259414278],
              [-39.933568243479677, -8.230239249339945],
              [-39.933568248745225, -8.230239239259145],
              [-39.933568253998047, -8.230239229171683],
              [-39.93356825923815, -8.230239219077662],
              [-39.933568264465528, -8.230239208977137],
              [-39.93356826968013, -8.230239198870052],
              [-39.93356827488202, -8.23023918875648],
              [-39.933568280071121, -8.230239178636367],
              [-39.933568285247468, -8.230239168509714],
              [-39.933568290411039, -8.230239158376706],
              [-39.933568295561813, -8.230239148237121],
              [-39.933568300699811, -8.230239138091132],
              [-39.93356830582502, -8.230239127938775],
              [-39.933568310937382, -8.230239117780007],
              [-39.933568316036954, -8.230239107614752],
              [-39.933568321123687, -8.23023909744321],
              [-39.933568330808434, -8.230239065650368],
              [-39.935028742385697, -8.227314080481914],
              [-39.935028796336063, -8.227314071989875],
              [-39.935028839550426, -8.22731398425441],
              [-39.935028881810666, -8.22731389605881],
              [-39.935028923111851, -8.227313807413488],
              [-39.935028963449149, -8.227313718328713],
              [-39.935029002817892, -8.227313628814944],
              [-39.935029041213426, -8.227313538882601],
              [-39.935029078631295, -8.22731344854221],
              [-39.935029115067145, -8.227313357804254],
              [-39.935029150516684, -8.22731326667931],
              [-39.935029184975797, -8.227313175178185],
              [-39.935029218440476, -8.227313083311449],
              [-39.93502925090678, -8.227312991089779],
              [-39.935029282370941, -8.227312898524044],
              [-39.935029312829265, -8.227312805624956],
              [-39.935029342278227, -8.227312712403382],
              [-39.935029370714354, -8.227312618870355],
              [-39.935029398134347, -8.227312525036625],
              [-39.935029424534996, -8.227312430913221],
              [-39.935029449913245, -8.227312336511098],
              [-39.935029474266074, -8.227312241841299],
              [-39.935029497590683, -8.227312146914862],
              [-39.935029519884345, -8.227312051742832],
              [-39.935029541144459, -8.227311956336443],
              [-39.935029561368516, -8.227311860706777],
              [-39.935029580554179, -8.227311764864941],
              [-39.935029598699202, -8.227311668822175],
              [-39.935029615801476, -8.227311572589652],
              [-39.935029631858974, -8.227311476178695],
              [-39.93502964686985, -8.227311379600501],
              [-39.935029660832349, -8.227311282866355],
              [-39.935029673744836, -8.227311185987521],
              [-39.935029685605784, -8.227311088975378],
              [-39.935029696413842, -8.227310991841229],
              [-39.935029706167725, -8.227310894596391],
              [-39.935029714866303, -8.227310797252265],
              [-39.935029722508553, -8.227310699820054],
              [-39.935029729093607, -8.227310602311366],
              [-39.935029734620642, -8.227310504737551],
              [-39.935029739089082, -8.227310407109766],
              [-39.935029742498358, -8.227310309439684],
              [-39.935029744848087, -8.227310211738608],
              [-39.935029746137978, -8.22731011401793],
              [-39.93502974636791, -8.227310016289046],
              [-39.935029745537854, -8.227309918563339],
              [-39.935029743647867, -8.227309820852422],
              [-39.935029740698205, -8.227309723167487],
              [-39.935029736689209, -8.227309625520004],
              [-39.935029731621341, -8.227309527921264],
              [-39.935029725495184, -8.227309430382903],
              [-39.935029718311476, -8.227309332916189],
              [-39.935029710071035, -8.227309235532426],
              [-39.935029700774848, -8.227309138243101],
              [-39.93502969042396, -8.227309041059497],
              [-39.935029679019614, -8.22730894399305],
              [-39.935029666563139, -8.22730884705503],
              [-39.93502965305597, -8.227308750256753],
              [-39.935029638499692, -8.227308653609498],
              [-39.935029622896025, -8.227308557124601],
              [-39.935029606246744, -8.227308460813331],
              [-39.935029588553839, -8.227308364686836],
              [-39.93502956981937, -8.227308268756495],
              [-39.935029550045513, -8.227308173033425],
              [-39.935029529234548, -8.227308077528734],
              [-39.935029507388975, -8.227307982253624],
              [-39.935029484511283, -8.227307887219345],
              [-39.935029460604177, -8.227307792436816],
              [-39.935029435670423, -8.227307697917238],
              [-39.935029409712953, -8.227307603671557],
              [-39.935029382734783, -8.227307509710791],
              [-39.935029354739065, -8.227307416045894],
              [-39.935029325729097, -8.227307322687935],
              [-39.935029295708219, -8.227307229647625],
              [-39.935029264679976, -8.227307136935988],
              [-39.93502923264797, -8.227307044563664],
              [-39.93502919961594, -8.227306952541696],
              [-39.935029165587757, -8.227306860880569],
              [-39.935029130567379, -8.227306769591202],
              [-39.935029094558892, -8.227306678684108],
              [-39.93502905756651, -8.227306588169879],
              [-39.935029019594566, -8.227306498059246],
              [-39.935028980647488, -8.227306408362605],
              [-39.935028940729801, -8.227306319090371],
              [-39.935028899846188, -8.227306230253172],
              [-39.935028858001402, -8.227306141861211],
              [-39.935028815200347, -8.227306053924915],
              [-39.935028771448032, -8.22730596645447],
              [-39.935028726749522, -8.227305879460085],
              [-39.935028681110076, -8.227305792951981],
              [-39.935028634535009, -8.22730570694026],
              [-39.93502858702977, -8.227305621434915],
              [-39.93502853859988, -8.227305536445881],
              [-39.935028489251003, -8.227305451983195],
              [-39.93502843898893, -8.227305368056712],
              [-39.935028387819486, -8.227305284676188],
              [-39.935028335748676, -8.227305201851236],
              [-39.935028282782575, -8.227305119591765],
              [-39.935028228927365, -8.22730503790728],
              [-39.93502817418932, -8.227304956807258],
              [-39.935028118574856, -8.227304876301284],
              [-39.935028085272158, -8.227304856796966],
              [-39.934011069239773, -8.225849637488137],
              [-39.93401112412549, -8.225849594039333],
              [-39.934011103184218, -8.225849564201212],
              [-39.934011082122566, -8.225849534447315],
              [-39.934011060940854, -8.225849504778163],
              [-39.934011039639422, -8.225849475194213],
              [-39.934011018218619, -8.225849445695918],
              [-39.934010996678822, -8.225849416283729],
              [-39.934010975020321, -8.22584938695819],
              [-39.934010953243515, -8.225849357719767],
              [-39.934010931348737, -8.225849328568883],
              [-39.934010909336358, -8.225849299506089],
              [-39.934010887206718, -8.225849270531747],
              [-39.934010864960158, -8.22584924164642],
              [-39.93401084259709, -8.225849212850514],
              [-39.934010820117834, -8.225849184144634],
              [-39.934010797522774, -8.225849155529071],
              [-39.934010774812265, -8.225849127004338],
              [-39.934010751986683, -8.22584909857091],
              [-39.934010729046392, -8.225849070229295],
              [-39.934010705991767, -8.225849041979838],
              [-39.934010682823185, -8.225849013823167],
              [-39.934010659541016, -8.225848985759562],
              [-39.934010636145636, -8.225848957789557],
              [-39.934010612637422, -8.225848929913679],
              [-39.934010589016779, -8.225848902132215],
              [-39.934010565284055, -8.225848874445804],
              [-39.934010541439655, -8.225848846854719],
              [-39.934010517483962, -8.225848819359477],
              [-39.934010493417361, -8.225848791960553],
              [-39.934010469240235, -8.225848764658362],
              [-39.934010444952996, -8.225848737453328],
              [-39.934010420556021, -8.225848710345918],
              [-39.934010396049715, -8.225848683336645],
              [-39.934010371434475, -8.225848656425852],
              [-39.934010346710686, -8.225848629613978],
              [-39.934010321878766, -8.225848602901381],
              [-39.934010296939107, -8.225848576288714],
              [-39.934010271892113, -8.225848549776225],
              [-39.934010246738204, -8.225848523364395],
              [-39.934010221477763, -8.225848497053716],
              [-39.93401019611121, -8.225848470844534],
              [-39.93401017063897, -8.225848444737297],
              [-39.93401014506145, -8.225848418732344],
              [-39.934010119379039, -8.225848392830294],
              [-39.934010093592185, -8.225848367031404],
              [-39.934010067701301, -8.225848341336054],
              [-39.934010041706784, -8.225848315744834],
              [-39.934010015609083, -8.225848290258071],
              [-39.934009989408587, -8.225848264876195],
              [-39.93400996310578, -8.225848239599539],
              [-39.934009936701031, -8.225848214428597],
              [-39.934009910194789, -8.22584818936379],
              [-39.934009883587485, -8.225848164405377],
              [-39.934009856879534, -8.225848139554042],
              [-39.934009830071396, -8.225848114809825],
              [-39.934009803163484, -8.225848090173473],
              [-39.934009776156238, -8.225848065645154],
              [-39.934009749050105, -8.225848041225433],
              [-39.934009721845506, -8.225848016914536],
              [-39.934009694542929, -8.225847992712962],
              [-39.934009667142753, -8.22584796862105],
              [-39.934009639645453, -8.225847944639249],
              [-39.934009612051497, -8.225847920767917],
              [-39.934009584361263, -8.225847897007419],
              [-39.934009556575276, -8.225847873358218],
              [-39.934009528693956, -8.225847849820651],
              [-39.934009500717728, -8.225847826395016],
              [-39.934009472647091, -8.225847803081832],
              [-39.93400944448247, -8.225847779881379],
              [-39.934009416224342, -8.225847756794018],
              [-39.934009387873161, -8.225847733820281],
              [-39.934009359429346, -8.225847710960355],
              [-39.934009330893424, -8.225847688214708],
              [-39.9340093022658, -8.225847665583665],
              [-39.934009273546984, -8.225847643067617],
              [-39.93400924473741, -8.22584762066691],
              [-39.934009215837548, -8.225847598381996],
              [-39.934009186847888, -8.225847576213093],
              [-39.93400915776887, -8.225847554160655],
              [-39.934009128600991, -8.225847532225012],
              [-39.934009099344721, -8.225847510406545],
              [-39.934009070000506, -8.225847488705559],
              [-39.934009040568853, -8.22584746712249],
              [-39.934009011050222, -8.225847445657511],
              [-39.934008981445096, -8.225847424311125],
              [-39.934008951753967, -8.225847403083725],
              [-39.934008921977302, -8.225847381975509],
              [-39.934008892115585, -8.225847360986895],
              [-39.934008862169286, -8.225847340118122],
              [-39.934008832138915, -8.225847319369759],
              [-39.934008802024955, -8.225847298741956],
              [-39.934008771827891, -8.225847278235001],
              [-39.93400874154819, -8.225847257849463],
              [-39.93400871118638, -8.225847237585446],
              [-39.93400868074292, -8.225847217443283],
              [-39.934008650218331, -8.225847197423491],
              [-39.934008619613103, -8.22584717752626],
              [-39.934008588927703, -8.225847157751838],
              [-39.934008558162652, -8.225847138100715],
              [-39.934008527318461, -8.225847118573142],
              [-39.934008521368185, -8.225847110996003],
              [-39.932765215696499, -8.225063502869391],
              [-39.932765207258015, -8.225063459330935],
              [-39.932765191138238, -8.225063449194112],
              [-39.932765174997122, -8.225063439091194],
              [-39.932765158834741, -8.225063429022054],
              [-39.932765142651199, -8.225063418986707],
              [-39.932765126446519, -8.225063408985321],
              [-39.932765110220799, -8.225063399017813],
              [-39.932765093974119, -8.225063389084177],
              [-39.93276507770652, -8.225063379184602],
              [-39.932765061418095, -8.225063369319159],
              [-39.932765045108923, -8.225063359487672],
              [-39.932765028779059, -8.225063349690451],
              [-39.932765012428597, -8.22506333992736],
              [-39.932764996057593, -8.225063330198489],
              [-39.932764979666082, -8.225063320503873],
              [-39.932764963254208, -8.225063310843582],
              [-39.932764946821997, -8.225063301217705],
              [-39.932764930369537, -8.225063291626142],
              [-39.932764913896897, -8.225063282068993],
              [-39.932764897404155, -8.225063272546356],
              [-39.932764880891376, -8.225063263058333],
              [-39.932764864358624, -8.225063253604777],
              [-39.932764847805998, -8.225063244185851],
              [-39.932764831233563, -8.225063234801505],
              [-39.932764814641374, -8.225063225451926],
              [-39.932764798029503, -8.225063216137061],
              [-39.932764781398063, -8.225063206856948],
              [-39.932764764747098, -8.225063197611714],
              [-39.932764748076664, -8.225063188401233],
              [-39.932764731386868, -8.225063179225717],
              [-39.932764714677745, -8.225063170085066],
              [-39.93276469794943, -8.225063160979468],
              [-39.932764681201938, -8.225063151908836],
              [-39.932764664435368, -8.225063142873323],
              [-39.932764647649805, -8.22506313387278],
              [-39.932764630845298, -8.225063124907468],
              [-39.932764614021934, -8.225063115977338],
              [-39.932764597179769, -8.225063107082386],
              [-39.932764580318917, -8.225063098222645],
              [-39.932764563439427, -8.225063089398208],
              [-39.932764546541364, -8.225063080609106],
              [-39.932764529624812, -8.225063071855431],
              [-39.932764512689857, -8.225063063137137],
              [-39.932764495736556, -8.225063054454226],
              [-39.932764478764994, -8.225063045806825],
              [-39.93276446177525, -8.22506303719504],
              [-39.932764444767393, -8.225063028618713],
              [-39.932764427741482, -8.22506302007799],
              [-39.932764410697629, -8.225063011572935],
              [-39.932764393635871, -8.225063003103553],
              [-39.932764376556307, -8.225062994669859],
              [-39.932764359459007, -8.225062986271922],
              [-39.932764342344029, -8.225062977909774],
              [-39.93276432521148, -8.22506296958348],
              [-39.932764308061415, -8.225062961293009],
              [-39.932764290893907, -8.225062953038426],
              [-39.932764273709068, -8.22506294481977],
              [-39.932764256506914, -8.225062936637052],
              [-39.932764239287557, -8.225062928490372],
              [-39.932764222051084, -8.225062920379665],
              [-39.932764204797536, -8.22506291230517],
              [-39.932764187527013, -8.225062904266695],
              [-39.932764170239587, -8.225062896264449],
              [-39.932764152935341, -8.225062888298288],
              [-39.932764135614335, -8.225062880368387],
              [-39.932764118276637, -8.22506287247468],
              [-39.93276410092237, -8.225062864617298],
              [-39.932764083551561, -8.225062856796303],
              [-39.932764066164324, -8.225062849011524],
              [-39.932764048760703, -8.225062841263229],
              [-39.932764031340781, -8.22506283355135],
              [-39.932764013904674, -8.225062825875927],
              [-39.932763996452401, -8.22506281823693],
              [-39.93276397898407, -8.225062810634563],
              [-39.932763961499752, -8.225062803068703],
              [-39.932763943999532, -8.225062795539403],
              [-39.932763926483489, -8.225062788046696],
              [-39.932763908951692, -8.225062780590751],
              [-39.932763891404207, -8.22506277317145],
              [-39.932763873841125, -8.225062765788925],
              [-39.93276385626254, -8.225062758443059],
              [-39.932763838668478, -8.225062751134045],
              [-39.932763821059076, -8.22506274386185],
              [-39.932763803434383, -8.225062736626521],
              [-39.932763785794471, -8.225062729428039],
              [-39.932763768139438, -8.225062722266514],
              [-39.932763750469327, -8.225062715141906],
              [-39.932763732784252, -8.225062708054207],
              [-39.932763715084278, -8.225062701003653],
              [-39.932763697369488, -8.225062693990045],
              [-39.932763679639955, -8.225062687013516],
              [-39.932763661895734, -8.225062680074117],
              [-39.932763644136962, -8.22506267317185],
              [-39.932763626363659, -8.225062666306771],
              [-39.932763608575918, -8.225062659478898],
              [-39.932763590773853, -8.225062652688251],
              [-39.932763572957484, -8.225062645934784],
              [-39.932763555126925, -8.225062639218713],
              [-39.93276353728227, -8.225062632539855],
              [-39.932763519423588, -8.225062625898364],
              [-39.93276348869518, -8.225062602443185],
              [-39.930955322557502, -8.224392357675875],
              [-39.930955328179074, -8.224392326006875],
              [-39.930955295636032, -8.224392313872242],
              [-39.930955263139843, -8.224392301613696],
              [-39.930955230690984, -8.224392289231304],
              [-39.930955198289936, -8.22439227672535],
              [-39.930955165937171, -8.224392264095854],
              [-39.930955133633162, -8.224392251343129],
              [-39.930955101378409, -8.224392238467281],
              [-39.930955069173336, -8.224392225468508],
              [-39.930955037018443, -8.224392212347075],
              [-39.930955004914203, -8.224392199103054],
              [-39.930954972861073, -8.224392185736814],
              [-39.930954940859543, -8.224392172248352],
              [-39.93095490891006, -8.22439215863804],
              [-39.930954877013107, -8.224392144905972],
              [-39.930954845169133, -8.224392131052369],
              [-39.930954813378627, -8.224392117077402],
              [-39.93095478164205, -8.224392102981369],
              [-39.930954749959852, -8.224392088764457],
              [-39.930954718332501, -8.224392074426781],
              [-39.930954686760479, -8.224392059968608],
              [-39.930954655244228, -8.224392045390179],
              [-39.930954623784224, -8.224392030691703],
              [-39.930954592380907, -8.224392015873354],
              [-39.930954561034767, -8.224392000935298],
              [-39.930954529746238, -8.224391985877903],
              [-39.930954498515803, -8.224391970701269],
              [-39.930954467343881, -8.224391955405634],
              [-39.930954436230984, -8.22439193999133],
              [-39.930954405177509, -8.224391924458459],
              [-39.930954374183941, -8.224391908807254],
              [-39.930954343250733, -8.22439189303809],
              [-39.93095431237834, -8.224391877150994],
              [-39.93095428156721, -8.224391861146266],
              [-39.930954250817798, -8.224391845024218],
              [-39.930954220130545, -8.224391828785043],
              [-39.930954189505918, -8.224391812428891],
              [-39.930954158944338, -8.224391795956187],
              [-39.930954128446274, -8.224391779367048],
              [-39.930954098012187, -8.224391762661687],
              [-39.930954067642475, -8.224391745840423],
              [-39.930954037337607, -8.224391728903516],
              [-39.930954007098059, -8.224391711851057],
              [-39.930953976924243, -8.224391694683591],
              [-39.930953946816608, -8.224391677401094],
              [-39.930953916775564, -8.224391660003851],
              [-39.930953886801611, -8.224391642492323],
              [-39.930953856895123, -8.224391624866488],
              [-39.930953827056598, -8.224391607126899],
              [-39.930953797286442, -8.224391589273576],
              [-39.930953767585102, -8.224391571306832],
              [-39.93095373795299, -8.224391553226976],
              [-39.930953708390554, -8.224391535034274],
              [-39.930953678898248, -8.224391516728979],
              [-39.930953649476471, -8.22439149831137],
              [-39.930953620125678, -8.224391479781739],
              [-39.930953590846293, -8.224391461140335],
              [-39.930953561638731, -8.224391442387295],
              [-39.930953532503445, -8.224391423523068],
              [-39.930953503440826, -8.224391404547944],
              [-39.930953474451343, -8.22439138546207],
              [-39.930953445535373, -8.224391366265833],
              [-39.930953416693399, -8.224391346959452],
              [-39.930953387925776, -8.224391327543197],
              [-39.930953359232987, -8.224391308017537],
              [-39.930953330615424, -8.224391288382503],
              [-39.930953302073483, -8.224391268638417],
              [-39.930953273607621, -8.224391248785697],
              [-39.930953245218248, -8.224391228824649],
              [-39.930953216905756, -8.224391208755437],
              [-39.9309531886706, -8.224391188578398],
              [-39.930953160513141, -8.224391168293934],
              [-39.930953132433842, -8.224391147902097],
              [-39.93095310443308, -8.224391127403477],
              [-39.93095307651128, -8.224391106798183],
              [-39.930953048668854, -8.224391086086543],
              [-39.930953020906202, -8.224391065268909],
              [-39.930952993223741, -8.224391044345612],
              [-39.930952965621863, -8.224391023316869],
              [-39.93095293810098, -8.224391002183026],
              [-39.930952910661482, -8.224390980944341],
              [-39.930952883303796, -8.224390959601271],
              [-39.930952856028298, -8.224390938153938],
              [-39.930952828835409, -8.224390916602822],
              [-39.930952801725503, -8.224390894948181],
              [-39.930952774699016, -8.22439087319038],
              [-39.930952747756301, -8.224390851329556],
              [-39.930952720897785, -8.224390829366166],
              [-39.930952694123832, -8.224390807300569],
              [-39.930952667434852, -8.224390785133128],
              [-39.930952640831244, -8.224390762863973],
              [-39.930952614313377, -8.224390740493524],
              [-39.930952587881656, -8.224390718022118],
              [-39.930952561536458, -8.224390695450124],
              [-39.93095253527818, -8.224390672777913],
              [-39.930952509107186, -8.224390650005635],
              [-39.930952483023894, -8.224390627133776],
              [-39.930952457028653, -8.224390604162554],
              [-39.930952431121852, -8.224390581092479],
              [-39.930952405303962, -8.22439055792314],
              [-39.930952413267903, -8.224390555545133],
              [-39.929708150091848, -8.223269675450808],
              [-39.929708178582366, -8.22326969292147],
              [-39.929708159916615, -8.223269676153087],
              [-39.929708141204337, -8.22326965943628],
              [-39.929708122445689, -8.223269642771013],
              [-39.929708103640792, -8.223269626157602],
              [-39.929708084789802, -8.223269609596201],
              [-39.929708065892861, -8.223269593086762],
              [-39.929708046950104, -8.223269576629582],
              [-39.929708027961702, -8.223269560224598],
              [-39.929708008927761, -8.223269543872112],
              [-39.929707989848481, -8.223269527572256],
              [-39.929707970723946, -8.223269511324983],
              [-39.929707951554349, -8.223269495130609],
              [-39.929707932339817, -8.223269478989083],
              [-39.929707913080499, -8.223269462900664],
              [-39.929707893776559, -8.223269446865428],
              [-39.929707874428125, -8.223269430883496],
              [-39.929707855035346, -8.223269414955],
              [-39.9297078355984, -8.223269399080063],
              [-39.929707816117386, -8.223269383258783],
              [-39.929707796592488, -8.223269367491222],
              [-39.92970777702385, -8.223269351777672],
              [-39.929707757411613, -8.223269336118131],
              [-39.929707737755948, -8.223269320512683],
              [-39.929707718056981, -8.223269304961617],
              [-39.929707698314886, -8.223269289464907],
              [-39.929707678529788, -8.223269274022629],
              [-39.929707658701844, -8.223269258635103],
              [-39.929707638831239, -8.223269243302202],
              [-39.929707618918087, -8.223269228024202],
              [-39.929707598962544, -8.223269212801222],
              [-39.929707578964781, -8.223269197633305],
              [-39.929707558924953, -8.22326918252066],
              [-39.929707538843196, -8.223269167463297],
              [-39.929707518719667, -8.223269152461461],
              [-39.929707498554535, -8.223269137515071],
              [-39.929707478347943, -8.223269122624412],
              [-39.929707458100054, -8.223269107789568],
              [-39.929707437811011, -8.223269093010583],
              [-39.929707417480977, -8.223269078287606],
              [-39.929707397110107, -8.223269063620808],
              [-39.929707376698552, -8.223269049010264],
              [-39.929707356246467, -8.223269034455985],
              [-39.929707335754017, -8.223269019958215],
              [-39.929707315221385, -8.223269005517091],
              [-39.929707294648672, -8.223268991132564],
              [-39.929707274036062, -8.223268976804848],
              [-39.929707253383718, -8.223268962534032],
              [-39.929707232691797, -8.22326894832028],
              [-39.929707211960448, -8.223268934163693],
              [-39.92970719118987, -8.223268920064241],
              [-39.929707170380169, -8.223268906022158],
              [-39.929707149531517, -8.223268892037577],
              [-39.92970712864409, -8.223268878110529],
              [-39.929707107718038, -8.223268864241085],
              [-39.929707086753531, -8.223268850429532],
              [-39.929707065750705, -8.223268836675764],
              [-39.929707044709765, -8.223268822979971],
              [-39.929707023630826, -8.223268809342315],
              [-39.929707002514071, -8.223268795762833],
              [-39.929706981359672, -8.223268782241574],
              [-39.929706960167763, -8.223268768778846],
              [-39.929706938938537, -8.223268755374539],
              [-39.929706917672128, -8.223268742028909],
              [-39.929706896368728, -8.223268728741889],
              [-39.929706875028472, -8.223268715513749],
              [-39.929706853651545, -8.223268702344473],
              [-39.929706832238111, -8.223268689234258],
              [-39.929706810788304, -8.22326867618319],
              [-39.92970678930233, -8.223268663191254],
              [-39.929706767780331, -8.223268650258632],
              [-39.929706746222472, -8.22326863738544],
              [-39.929706724628915, -8.22326862457175],
              [-39.929706702999844, -8.223268611817748],
              [-39.929706681335418, -8.223268599123323],
              [-39.929706659635784, -8.22326858648877],
              [-39.929706637901127, -8.223268573914083],
              [-39.929706616131604, -8.223268561399353],
              [-39.929706594327392, -8.223268548944755],
              [-39.929706572488641, -8.223268536550384],
              [-39.929706550615542, -8.223268524216312],
              [-39.929706528708252, -8.223268511942582],
              [-39.929706506766927, -8.223268499729325],
              [-39.929706484791751, -8.223268487576574],
              [-39.929706462782896, -8.223268475484497],
              [-39.929706440740524, -8.223268463453277],
              [-39.929706418664786, -8.223268451482801],
              [-39.929706396555879, -8.22326843957328],
              [-39.929706374413939, -8.22326842772479],
              [-39.929706352239187, -8.223268415937438],
              [-39.929706330031749, -8.223268404211202],
              [-39.929706307791818, -8.223268392546377],
              [-39.929706285519543, -8.223268380942949],
              [-39.929706263215124, -8.223268369400881],
              [-39.929706240878701, -8.223268357920407],
              [-39.929706218510468, -8.223268346501699],
              [-39.92970619611058, -8.223268335144654],
              [-39.929706173679214, -8.223268323849442],
              [-39.929706151216536, -8.223268312616094],
              [-39.929706128722756, -8.223268301444747],
              [-39.929706149701083, -8.223268323424989],
              [-39.927445668336119, -8.222149602796048],
              [-39.927445707708969, -8.222149564386207],
              [-39.927445658936996, -8.222149540428711],
              [-39.927445610021863, -8.222149516762684],
              [-39.927445560965296, -8.222149493389011],
              [-39.927445511769101, -8.222149470308439],
              [-39.92744546243501, -8.222149447521844],
              [-39.927445412964794, -8.222149425030139],
              [-39.927445363360242, -8.222149402833946],
              [-39.927445313623139, -8.222149380934267],
              [-39.927445263755239, -8.222149359331736],
              [-39.927445213758368, -8.222149338027215],
              [-39.927445163634296, -8.222149317021383],
              [-39.927445113384827, -8.222149296315017],
              [-39.927445063011767, -8.222149275908935],
              [-39.927445012516912, -8.222149255803755],
              [-39.92744496190209, -8.22214923600032],
              [-39.927444911169111, -8.222149216499311],
              [-39.927444860319795, -8.222149197301388],
              [-39.927444809355961, -8.222149178407143],
              [-39.927444758279442, -8.222149159817423],
              [-39.927444707092057, -8.222149141532862],
              [-39.927444655795703, -8.22214912355404],
              [-39.927444604392136, -8.222149105881716],
              [-39.927444552883252, -8.222149088516424],
              [-39.927444501270877, -8.222149071458766],
              [-39.927444449556873, -8.222149054709428],
              [-39.927444397743095, -8.222149038269066],
              [-39.927444345831397, -8.222149022138055],
              [-39.927444293823655, -8.222149006317258],
              [-39.927444241721716, -8.222148990807023],
              [-39.927444189527442, -8.222148975607977],
              [-39.927444137242759, -8.222148960720707],
              [-39.927444084869478, -8.222148946145676],
              [-39.927444032409511, -8.222148931883421],
              [-39.927443979864741, -8.222148917934547],
              [-39.927443927237057, -8.222148904299441],
              [-39.927443874528315, -8.222148890978639],
              [-39.927443821740447, -8.22214887797268],
              [-39.927443768875307, -8.222148865281889],
              [-39.927443715934835, -8.222148852906736],
              [-39.927443662920901, -8.222148840847792],
              [-39.927443609835414, -8.222148829105434],
              [-39.927443556680274, -8.222148817680068],
              [-39.927443503457397, -8.222148806572058],
              [-39.927443450168695, -8.222148795781907],
              [-39.927443396816059, -8.222148785309928],
              [-39.92744334340145, -8.222148775156519],
              [-39.927443289926735, -8.222148765321982],
              [-39.927443236393849, -8.222148755806852],
              [-39.927443182804708, -8.22214874661119],
              [-39.927443129161254, -8.222148737735635],
              [-39.927443075465384, -8.222148729180216],
              [-39.927443021719057, -8.222148720945491],
              [-39.927442967924186, -8.222148713031535],
              [-39.927442914082661, -8.222148705438832],
              [-39.927442860196493, -8.222148698167503],
              [-39.927442806267557, -8.222148691217877],
              [-39.9274427522978, -8.222148684590238],
              [-39.927442698289184, -8.222148678284743],
              [-39.927442644243605, -8.222148672301685],
              [-39.927442590163018, -8.222148666641253],
              [-39.927442536049384, -8.222148661303635],
              [-39.92744248190462, -8.222148656289002],
              [-39.927442427730696, -8.222148651597514],
              [-39.927442373529509, -8.222148647229474],
              [-39.927442319303047, -8.222148643184941],
              [-39.927442265053237, -8.222148639464061],
              [-39.927442210782026, -8.222148636067017],
              [-39.927442156491374, -8.222148632993825],
              [-39.927442102183207, -8.222148630244693],
              [-39.9274420478595, -8.222148627819752],
              [-39.927441993522187, -8.222148625718923],
              [-39.9274419391732, -8.22214862394244],
              [-39.92744188481452, -8.222148622490325],
              [-39.927441830448075, -8.222148621362619],
              [-39.92744177607586, -8.222148620559336],
              [-39.927441721699743, -8.222148620080509],
              [-39.927441667321744, -8.222148619926278],
              [-39.927441612943788, -8.222148620096458],
              [-39.927441558567836, -8.222148620591183],
              [-39.927441504195833, -8.22214862141027],
              [-39.927441449829743, -8.22214862255387],
              [-39.927441395471476, -8.222148624021951],
              [-39.927441341123028, -8.222148625814365],
              [-39.927441286786319, -8.222148627930963],
              [-39.927441232463337, -8.222148630371885],
              [-39.927441178155988, -8.2221486331369],
              [-39.927441123866224, -8.222148636225844],
              [-39.927441069596036, -8.222148639638814],
              [-39.92744101534732, -8.222148643375606],
              [-39.927440961122052, -8.222148647435928],
              [-39.927440906922172, -8.222148651819911],
              [-39.927440852749619, -8.222148656527148],
              [-39.927440798606334, -8.222148661557579],
              [-39.927440744494263, -8.222148666911004],
              [-39.927440690415345, -8.222148672587293],
              [-39.92744063637155, -8.222148678586128],
              [-39.927440582364781, -8.222148684907435],
              [-39.927440528396986, -8.222148691550837],
              [-39.927440474470089, -8.222148698516246],
              [-39.927440491603818, -8.222148710300537],
              [-39.92484324462221, -8.222492046458521],
              [-39.922809677515986, -8.222721832561456],
              [-39.922809669529748, -8.222721787197639],
              [-39.922809646179289, -8.222721789866123],
              [-39.92280962283585, -8.222721792594834],
              [-39.92280959949958, -8.222721795383853],
              [-39.922809576170643, -8.222721798232898],
              [-39.922809552849174, -8.222721801142184],
              [-39.922809529535336, -8.22272180411156],
              [-39.922809506229314, -8.222721807141095],
              [-39.922809482931243, -8.222721810230754],
              [-39.922809459641257, -8.222721813380431],
              [-39.92280943635955, -8.222721816590148],
              [-39.922809413086263, -8.222721819859988],
              [-39.922809389821538, -8.222721823189866],
              [-39.922809366565559, -8.222721826579699],
              [-39.92280934331847, -8.222721830029482],
              [-39.922809320080411, -8.222721833539284],
              [-39.922809296851561, -8.222721837108919],
              [-39.922809273632062, -8.222721840738544],
              [-39.922809250422063, -8.222721844427964],
              [-39.922809227221734, -8.222721848177185],
              [-39.922809204031232, -8.222721851986323],
              [-39.922809180850706, -8.222721855855328],
              [-39.922809157680312, -8.222721859783993],
              [-39.9228091345202, -8.222721863772444],
              [-39.92280911137054, -8.222721867820621],
              [-39.922809088231489, -8.222721871928476],
              [-39.922809065103166, -8.222721876095999],
              [-39.922809041985751, -8.222721880323299],
              [-39.922809018879413, -8.222721884610056],
              [-39.922808995784287, -8.222721888956389],
              [-39.922808972700523, -8.222721893362319],
              [-39.922808949628305, -8.222721897827819],
              [-39.922808926567761, -8.222721902352696],
              [-39.922808903519027, -8.222721906937165],
              [-39.922808880482329, -8.222721911581027],
              [-39.922808857457738, -8.222721916284291],
              [-39.922808834445483, -8.222721921046931],
              [-39.922808811445655, -8.222721925868871],
              [-39.922808788458447, -8.222721930750131],
              [-39.922808765483985, -8.222721935690682],
              [-39.922808742522456, -8.22272194069045],
              [-39.922808719574, -8.22272194574942],
              [-39.922808696638761, -8.222721950867593],
              [-39.922808673716887, -8.222721956044895],
              [-39.92280865080857, -8.222721961281334],
              [-39.922808627913909, -8.222721966576771],
              [-39.922808605033104, -8.222721971931339],
              [-39.92280858216629, -8.22272197734484],
              [-39.922808559313609, -8.222721982817287],
              [-39.92280853647523, -8.222721988348663],
              [-39.922808513651297, -8.222721993938983],
              [-39.922808490841987, -8.222721999588114],
              [-39.922808468047428, -8.222722005296044],
              [-39.922808445267769, -8.222722011062794],
              [-39.922808422503181, -8.222722016888207],
              [-39.922808399753812, -8.222722022772377],
              [-39.922808377019798, -8.222722028715237],
              [-39.922808354301303, -8.222722034716641],
              [-39.922808331598489, -8.222722040776594],
              [-39.922808308911492, -8.222722046895139],
              [-39.922808286240475, -8.222722053072172],
              [-39.922808263585587, -8.222722059307674],
              [-39.922808240946985, -8.222722065601582],
              [-39.922808218324803, -8.222722071953857],
              [-39.922808195719206, -8.222722078364447],
              [-39.922808173130356, -8.222722084833325],
              [-39.922808150558375, -8.222722091360414],
              [-39.922808128003446, -8.222722097945789],
              [-39.922808105465691, -8.222722104589211],
              [-39.922808082945295, -8.222722111290814],
              [-39.922808060442378, -8.222722118050463],
              [-39.922808037957111, -8.222722124868126],
              [-39.922808015489636, -8.222722131743716],
              [-39.922807993040095, -8.222722138677344],
              [-39.922807970608659, -8.222722145668707],
              [-39.922807948195448, -8.222722152718042],
              [-39.922807925800647, -8.222722159825064],
              [-39.922807903424378, -8.222722166989884],
              [-39.922807881066824, -8.222722174212338],
              [-39.922807858728099, -8.222722181492552],
              [-39.922807836408367, -8.222722188830268],
              [-39.922807814107784, -8.22272219622552],
              [-39.922807791826479, -8.222722203678275],
              [-39.922807769564649, -8.222722211188568],
              [-39.922807747322366, -8.222722218756093],
              [-39.922807725099858, -8.22272222638107],
              [-39.922807702897231, -8.222722234063344],
              [-39.922807680714634, -8.222722241802767],
              [-39.92280765855223, -8.222722249599455],
              [-39.922807636410163, -8.222722257453253],
              [-39.922807614288573, -8.222722265364187],
              [-39.922807592187631, -8.222722273332028],
              [-39.922807570107452, -8.22272228135698],
              [-39.922807548048212, -8.22272228943879],
              [-39.92280752601004, -8.222722297577526],
              [-39.922807503993113, -8.222722305773004],
              [-39.92280748199753, -8.222722314025241],
              [-39.922807460023485, -8.222722322334231],
              [-39.922807438071104, -8.222722330699778],
              [-39.922807416140508, -8.222722339122081],
              [-39.922807409189645, -8.222722380428804],
              [-39.921341739103632, -8.223287396357119],
              [-39.921341753483816, -8.223287380905306],
              [-39.921341715508859, -8.223287395447965],
              [-39.921341677468881, -8.223287409820921],
              [-39.921341639364606, -8.223287424023903],
              [-39.921341601196836, -8.223287438056639],
              [-39.921341562966326, -8.22328745191875],
              [-39.921341524673856, -8.223287465610062],
              [-39.921341486320173, -8.22328747913032],
              [-39.921341447906073, -8.223287492479139],
              [-39.921341409432287, -8.223287505656344],
              [-39.921341370899633, -8.223287518661667],
              [-39.921341332308856, -8.223287531494837],
              [-39.921341293660731, -8.223287544155543],
              [-39.921341254956054, -8.223287556643635],
              [-39.921341216195586, -8.223287568958765],
              [-39.921341177380107, -8.223287581100735],
              [-39.921341138510407, -8.223287593069248],
              [-39.921341099587274, -8.22328760486416],
              [-39.921341060611454, -8.223287616485084],
              [-39.921341021583757, -8.223287627932022],
              [-39.921340982504944, -8.223287639204514],
              [-39.921340943375853, -8.22328765030241],
              [-39.921340904197201, -8.223287661225571],
              [-39.921340864969828, -8.223287671973555],
              [-39.921340825694493, -8.223287682546395],
              [-39.921340786372006, -8.223287692943856],
              [-39.921340747003121, -8.22328770316553],
              [-39.921340707588662, -8.2232877132114],
              [-39.92134066812941, -8.223287723081175],
              [-39.921340628626162, -8.223287732774605],
              [-39.921340589079698, -8.223287742291683],
              [-39.921340549490822, -8.223287751632117],
              [-39.921340509860336, -8.223287760795666],
              [-39.921340470189023, -8.223287769782118],
              [-39.921340430477684, -8.223287778591503],
              [-39.921340390727124, -8.223287787223438],
              [-39.921340350938117, -8.223287795677791],
              [-39.921340311111514, -8.223287803954442],
              [-39.921340271248035, -8.223287812053194],
              [-39.921340231348552, -8.223287819973857],
              [-39.921340191413854, -8.223287827716319],
              [-39.921340151444696, -8.22328783528042],
              [-39.921340111441936, -8.223287842666066],
              [-39.921340071406341, -8.22328784987301],
              [-39.921340031338744, -8.223287856901083],
              [-39.921339991239932, -8.223287863750171],
              [-39.921339951110703, -8.223287870420249],
              [-39.921339910951879, -8.223287876911035],
              [-39.921339870764264, -8.22328788322257],
              [-39.921339830548654, -8.223287889354507],
              [-39.921339790305879, -8.223287895306918],
              [-39.921339750036722, -8.223287901079576],
              [-39.921339709742021, -8.223287906672304],
              [-39.921339669422537, -8.223287912085205],
              [-39.921339629079121, -8.223287917317906],
              [-39.921339588712577, -8.223287922370586],
              [-39.921339548323722, -8.223287927242895],
              [-39.921339507913331, -8.223287931934893],
              [-39.921339467482248, -8.223287936446429],
              [-39.921339427031285, -8.223287940777386],
              [-39.92133938656125, -8.223287944927677],
              [-39.921339346072926, -8.22328794889723],
              [-39.92133930556718, -8.223287952686094],
              [-39.921339265044772, -8.223287956293952],
              [-39.921339224506561, -8.223287959720947],
              [-39.921339183953343, -8.223287962966809],
              [-39.921339143385921, -8.223287966031743],
              [-39.921339102805142, -8.223287968915376],
              [-39.921339062211743, -8.223287971617864],
              [-39.921339021606663, -8.223287974139149],
              [-39.921338980990612, -8.223287976478993],
              [-39.92133894036445, -8.223287978637542],
              [-39.92133889972898, -8.223287980614691],
              [-39.921338859085033, -8.223287982410438],
              [-39.921338818433419, -8.223287984024655],
              [-39.921338777774963, -8.223287985457382],
              [-39.921338737110446, -8.22328798670862],
              [-39.921338696440721, -8.223287987778235],
              [-39.921338655766604, -8.223287988666254],
              [-39.921338615088878, -8.223287989372679],
              [-39.921338574408388, -8.223287989897573],
              [-39.921338533725937, -8.223287990240699],
              [-39.921338493042377, -8.223287990402307],
              [-39.921338452358476, -8.223287990382213],
              [-39.921338411675094, -8.223287990180495],
              [-39.92133837099302, -8.223287989797088],
              [-39.921338330313084, -8.223287989232157],
              [-39.921338289636076, -8.223287988485611],
              [-39.921338248962847, -8.223287987557374],
              [-39.921338208294195, -8.223287986447653],
              [-39.921338167630957, -8.223287985156333],
              [-39.921338126973943, -8.22328798368348],
              [-39.921338086323949, -8.223287982029074],
              [-39.9213380456818, -8.223287980193232],
              [-39.92133800504832, -8.223287978175952],
              [-39.921337964424325, -8.223287975977296],
              [-39.921337923810626, -8.223287973597287],
              [-39.921337883208054, -8.223287971036017],
              [-39.921337842617383, -8.223287968293453],
              [-39.92133780203956, -8.223287965369606],
              [-39.921337836669842, -8.223287947961131],
              [-39.919869972610293, -8.223178887364515],
              [-39.91986924634292, -8.223178889026052],
              [-39.919532439826035, -8.223179659424563],
              [-39.919532465012523, -8.223179652193267],
              [-39.919532424997072, -8.223179652372691],
              [-39.919532384982801, -8.223179652727765],
              [-39.919532344970484, -8.223179653258558],
              [-39.91953230496091, -8.223179653965076],
              [-39.919532264954832, -8.223179654847224],
              [-39.919532224953066, -8.223179655905103],
              [-39.919532184956374, -8.223179657138592],
              [-39.919532144965522, -8.223179658547846],
              [-39.919532104981286, -8.223179660132489],
              [-39.919532065004432, -8.223179661892786],
              [-39.919532025035799, -8.223179663828608],
              [-39.919531985076091, -8.223179665939943],
              [-39.919531945126117, -8.223179668226729],
              [-39.919531905186645, -8.223179670688967],
              [-39.919531865258463, -8.223179673326532],
              [-39.919531825342332, -8.223179676139354],
              [-39.919531785439027, -8.223179679127503],
              [-39.919531745549349, -8.223179682290819],
              [-39.919531705674039, -8.223179685629207],
              [-39.919531665813892, -8.223179689142745],
              [-39.919531625969675, -8.223179692831248],
              [-39.919531586142163, -8.223179696694826],
              [-39.919531546332145, -8.2231797007331],
              [-39.919531506540359, -8.223179704946144],
              [-39.919531466767637, -8.223179709333976],
              [-39.919531427014675, -8.22317971389643],
              [-39.919531387282305, -8.223179718633263],
              [-39.919531347571287, -8.223179723544709],
              [-39.919531307882366, -8.223179728630395],
              [-39.919531268216332, -8.223179733890307],
              [-39.919531228573959, -8.223179739324358],
              [-39.919531188956007, -8.223179744932457],
              [-39.919531149363273, -8.223179750714523],
              [-39.919531109796495, -8.22317975667035],
              [-39.919531070256447, -8.223179762799871],
              [-39.919531030743904, -8.223179769102982],
              [-39.919530991259641, -8.223179775579473],
              [-39.919530951804418, -8.223179782229371],
              [-39.919530912378981, -8.223179789052415],
              [-39.919530872984133, -8.223179796048484],
              [-39.919530833620605, -8.223179803217606],
              [-39.919530794289201, -8.223179810559389],
              [-39.919530754990646, -8.223179818073852],
              [-39.919530715725713, -8.223179825760797],
              [-39.919530676495185, -8.223179833620136],
              [-39.919530637299815, -8.223179841651701],
              [-39.919530598140355, -8.223179849855283],
              [-39.919530559017559, -8.223179858230655],
              [-39.919530519932216, -8.223179866777834],
              [-39.919530480885051, -8.223179875496507],
              [-39.919530441876844, -8.223179884386591],
              [-39.919530402908364, -8.223179893447961],
              [-39.91953036398035, -8.223179902680261],
              [-39.919530325093547, -8.223179912083507],
              [-39.919530286248737, -8.223179921657314],
              [-39.919530247446666, -8.223179931401697],
              [-39.91953020868808, -8.223179941316392],
              [-39.91953016997374, -8.22317995140115],
              [-39.919530131304398, -8.223179961655793],
              [-39.919530092680795, -8.223179972080152],
              [-39.919530054103681, -8.22317998267402],
              [-39.91953001557382, -8.223179993437249],
              [-39.919529977091962, -8.22318000436951],
              [-39.919529938658833, -8.223180015470575],
              [-39.919529900275208, -8.223180026740414],
              [-39.919529861941804, -8.223180038178652],
              [-39.919529823659389, -8.223180049785153],
              [-39.919529785428693, -8.223180061559662],
              [-39.919529747250451, -8.223180073501842],
              [-39.919529709125449, -8.223180085611657],
              [-39.919529671054377, -8.223180097888765],
              [-39.919529633037996, -8.223180110332899],
              [-39.919529595077044, -8.223180122943855],
              [-39.919529557172254, -8.223180135721341],
              [-39.919529519324371, -8.223180148665243],
              [-39.919529481534106, -8.223180161775135],
              [-39.919529443802219, -8.223180175050883],
              [-39.919529406129449, -8.223180188492259],
              [-39.919529368516514, -8.223180202098833],
              [-39.919529330964124, -8.223180215870494],
              [-39.919529293473055, -8.223180229806925],
              [-39.919529256043987, -8.223180243907905],
              [-39.919529218677695, -8.223180258173047],
              [-39.919529181374863, -8.223180272602123],
              [-39.919529144136241, -8.223180287194955],
              [-39.919529106962536, -8.223180301951027],
              [-39.919529069854484, -8.223180316870327],
              [-39.919529032812818, -8.223180331952433],
              [-39.919528995838228, -8.223180347196932],
              [-39.919528958931437, -8.223180362603797],
              [-39.9195289220932, -8.223180378172479],
              [-39.919528885324162, -8.223180393902812],
              [-39.919528848625099, -8.223180409794425],
              [-39.919528811996699, -8.223180425847048],
              [-39.919528775439687, -8.223180442060405],
              [-39.91952873895476, -8.223180458434125],
              [-39.919528702542614, -8.223180474967862],
              [-39.919528666203995, -8.223180491661342],
              [-39.919528629939585, -8.223180508514295],
              [-39.919528628797472, -8.223180482133124],
              [-39.917836873256526, -8.223971207804999],
              [-39.917836853084793, -8.22397123888986],
              [-39.917836839069928, -8.223971245454852],
              [-39.917836825066345, -8.223971252043706],
              [-39.917836811074039, -8.223971258656286],
              [-39.917836797093102, -8.223971265292665],
              [-39.917836783123533, -8.223971271952701],
              [-39.91783676916539, -8.223971278636478],
              [-39.917836755218715, -8.223971285344122],
              [-39.917836741283558, -8.223971292075356],
              [-39.917836727359926, -8.22397129883025],
              [-39.917836713447905, -8.223971305608805],
              [-39.917836699547479, -8.223971312411051],
              [-39.917836685658749, -8.22397131923687],
              [-39.9178366717817, -8.223971326086252],
              [-39.917836657916425, -8.223971332959326],
              [-39.917836644062923, -8.223971339855856],
              [-39.917836630221252, -8.223971346775896],
              [-39.917836616391448, -8.223971353719504],
              [-39.917836602573558, -8.22397136068661],
              [-39.917836588767635, -8.223971367677121],
              [-39.917836574973691, -8.223971374691201],
              [-39.91783656119177, -8.223971381728591],
              [-39.917836547421921, -8.223971388789467],
              [-39.917836533664172, -8.223971395873752],
              [-39.917836519918588, -8.223971402981304],
              [-39.917836506185203, -8.223971410112282],
              [-39.917836492464041, -8.223971417266563],
              [-39.917836478755127, -8.223971424444164],
              [-39.917836465058549, -8.223971431645053],
              [-39.917836451374328, -8.223971438869249],
              [-39.917836437702476, -8.223971446116662],
              [-39.917836424043053, -8.223971453387312],
              [-39.917836410396113, -8.223971460681149],
              [-39.917836396761679, -8.223971467998119],
              [-39.917836383139786, -8.223971475338304],
              [-39.917836369530484, -8.223971482701632],
              [-39.917836355933822, -8.223971490088058],
              [-39.917836342349823, -8.223971497497621],
              [-39.91783632877852, -8.223971504930214],
              [-39.917836315219965, -8.223971512385843],
              [-39.917836301674207, -8.223971519864623],
              [-39.917836288141274, -8.223971527366304],
              [-39.917836274621209, -8.223971534890932],
              [-39.917836261114033, -8.22397154243861],
              [-39.91783624761981, -8.223971550009134],
              [-39.917836234138562, -8.223971557602693],
              [-39.917836220670338, -8.223971565219118],
              [-39.917836207215174, -8.223971572858472],
              [-39.917836193773113, -8.223971580520589],
              [-39.917836180344217, -8.223971588205641],
              [-39.917836166928474, -8.223971595913369],
              [-39.917836153525933, -8.223971603643962],
              [-39.917836140136686, -8.223971611397399],
              [-39.917836126760719, -8.223971619173401],
              [-39.917836113398081, -8.223971626972284],
              [-39.917836100048817, -8.223971634793807],
              [-39.917836086712974, -8.223971642637931],
              [-39.917836073390568, -8.223971650504811],
              [-39.917836060081676, -8.223971658394355],
              [-39.917836046786277, -8.22397166630641],
              [-39.917836033504479, -8.223971674241104],
              [-39.91783602023628, -8.223971682198313],
              [-39.917836006981709, -8.223971690178097],
              [-39.91783599374083, -8.223971698180375],
              [-39.917835980513672, -8.223971706205209],
              [-39.917835967300277, -8.223971714252453],
              [-39.917835954100667, -8.223971722322087],
              [-39.917835940914919, -8.223971730414231],
              [-39.917835927743027, -8.223971738528748],
              [-39.917835914585069, -8.223971746665606],
              [-39.917835901441045, -8.223971754824838],
              [-39.917835888311018, -8.223971763006379],
              [-39.917835875195003, -8.223971771210218],
              [-39.917835862093085, -8.223971779436388],
              [-39.91783584900525, -8.223971787684761],
              [-39.917835835931569, -8.223971795955439],
              [-39.917835822872057, -8.223971804248256],
              [-39.917835809826762, -8.223971812563189],
              [-39.917835796795735, -8.223971820900434],
              [-39.917835783779012, -8.22397182925971],
              [-39.91783577077662, -8.223971837641109],
              [-39.917835757788581, -8.223971846044645],
              [-39.917835744814951, -8.223971854470218],
              [-39.917835731855781, -8.223971862917805],
              [-39.917835718911093, -8.223971871387384],
              [-39.917835705980913, -8.223971879878942],
              [-39.917835693065292, -8.223971888392557],
              [-39.917835680164288, -8.22397189692804],
              [-39.917835667277913, -8.223971905485433],
              [-39.917835654406197, -8.2239719140647],
              [-39.917835641549203, -8.223971922665914],
              [-39.917835628706946, -8.223971931288894],
              [-39.917835615879476, -8.223971939933726],
              [-39.91783560306682, -8.223971948600303],
              [-39.917835590269036, -8.223971957288665],
              [-39.917835577486144, -8.223971965998773],
              [-39.917835564718189, -8.223971974730567],
              [-39.91783555196519, -8.223971983484056],
              [-39.917835539227212, -8.223971992259257],
              [-39.917835526504263, -8.223972001055969],
              [-39.917835513373973, -8.223972024902844],
              [-39.916370499703454, -8.224986799157071],
              [-39.916370532338071, -8.224986755942817],
              [-39.916370492466804, -8.224986783403221],
              [-39.916370452448923, -8.224986810651151],
              [-39.916370412285595, -8.224986837685801],
              [-39.916370371977955, -8.22498686450651],
              [-39.916370331527148, -8.224986891112383],
              [-39.916370290934317, -8.224986917502779],
              [-39.91637025020065, -8.224986943676784],
              [-39.916370209327276, -8.224986969633807],
              [-39.916370168315389, -8.224986995373049],
              [-39.916370127166132, -8.224987020893739],
              [-39.916370085880686, -8.224987046195247],
              [-39.91637004446023, -8.224987071276743],
              [-39.916370002905957, -8.224987096137573],
              [-39.916369961219026, -8.224987120777039],
              [-39.916369919400644, -8.224987145194399],
              [-39.916369877451991, -8.224987169388926],
              [-39.916369835374283, -8.224987193360013],
              [-39.916369793168712, -8.224987217106991],
              [-39.916369750836452, -8.224987240629044],
              [-39.916369708378767, -8.224987263925655],
              [-39.9163696657968, -8.224987286996086],
              [-39.916369623091818, -8.224987309839651],
              [-39.916369580265005, -8.22498733245579],
              [-39.916369537317614, -8.224987354843744],
              [-39.916369494250851, -8.224987377002945],
              [-39.916369451065925, -8.224987398932798],
              [-39.916369407764108, -8.22498742063255],
              [-39.916369364346593, -8.224987442101764],
              [-39.916369320814638, -8.224987463339717],
              [-39.916369277169515, -8.224987484345721],
              [-39.916369233412397, -8.224987505119381],
              [-39.916369189544596, -8.224987525659971],
              [-39.916369145567316, -8.224987545966883],
              [-39.916369101481855, -8.224987566039639],
              [-39.916369057289437, -8.224987585877564],
              [-39.916369012991325, -8.224987605480136],
              [-39.916368968588792, -8.224987624846786],
              [-39.916368924083116, -8.224987643977089],
              [-39.91636887947552, -8.224987662870289],
              [-39.916368834767333, -8.22498768152593],
              [-39.916368789959783, -8.22498769994349],
              [-39.916368745054193, -8.224987718122525],
              [-39.916368700051791, -8.224987736062346],
              [-39.916368654953899, -8.224987753762623],
              [-39.916368609761768, -8.224987771222736],
              [-39.91636856447672, -8.224987788442192],
              [-39.916368519100033, -8.224987805420566],
              [-39.916368473633007, -8.224987822157299],
              [-39.916368428076929, -8.224987838652016],
              [-39.916368382433106, -8.224987854904112],
              [-39.916368336702817, -8.224987870913207],
              [-39.916368290887391, -8.224987886678797],
              [-39.916368244988135, -8.224987902200509],
              [-39.916368199006349, -8.224987917477833],
              [-39.916368152943321, -8.224987932510343],
              [-39.916368106800419, -8.224987947297651],
              [-39.916368060578897, -8.224987961839338],
              [-39.916368014280096, -8.224987976134901],
              [-39.916367967905352, -8.22498799018401],
              [-39.916367921455986, -8.224988003986281],
              [-39.916367874933314, -8.224988017541284],
              [-39.916367828338629, -8.224988030848507],
              [-39.916367781673316, -8.224988043907825],
              [-39.916367734938682, -8.224988056718681],
              [-39.916367688136042, -8.224988069280775],
              [-39.91636764126676, -8.224988081593773],
              [-39.916367594332144, -8.224988093657309],
              [-39.916367547333557, -8.224988105470873],
              [-39.916367500272322, -8.224988117034444],
              [-39.916367453149782, -8.224988128347448],
              [-39.916367405967286, -8.224988139409678],
              [-39.916367358726184, -8.224988150220723],
              [-39.916367311427805, -8.224988160780354],
              [-39.916367264073521, -8.224988171088238],
              [-39.916367216664653, -8.224988181144093],
              [-39.91636716920258, -8.224988190947661],
              [-39.916367121688623, -8.224988200498615],
              [-39.916367074124182, -8.22498820979672],
              [-39.916367026510578, -8.224988218841702],
              [-39.916366978849176, -8.224988227633185],
              [-39.916366931141347, -8.224988236171084],
              [-39.916366883388442, -8.22498824445513],
              [-39.916366835591802, -8.224988252485128],
              [-39.916366787752821, -8.224988260260622],
              [-39.916366739872849, -8.224988267781598],
              [-39.916366691953257, -8.224988275047806],
              [-39.916366643995396, -8.224988282059067],
              [-39.916366596000657, -8.224988288815057],
              [-39.916366547970377, -8.224988295315631],
              [-39.916366499905955, -8.22498830156071],
              [-39.916366451808742, -8.224988307549998],
              [-39.916366403680136, -8.224988313283378],
              [-39.916366355521461, -8.224988318760627],
              [-39.91636630733413, -8.224988323981687],
              [-39.916366259119506, -8.224988328946328],
              [-39.916366210878984, -8.224988333654473],
              [-39.916366162613883, -8.22498833810595],
              [-39.916366114325619, -8.224988342300625],
              [-39.916366066015634, -8.22498834623822],
              [-39.916366054822525, -8.224988346833095],
              [-39.914900060727788, -8.225103926701056],
              [-39.914900046865903, -8.225103914516861],
              [-39.914900021822781, -8.225103916456259],
              [-39.914899996774395, -8.225103918326685],
              [-39.914899971720878, -8.225103920127985],
              [-39.914899946662473, -8.225103921860224],
              [-39.91489992159935, -8.22510392352334],
              [-39.914899896531686, -8.225103925117393],
              [-39.914899871459696, -8.225103926642451],
              [-39.914899846383605, -8.225103928098315],
              [-39.914899821303536, -8.225103929485016],
              [-39.914899796219714, -8.225103930802611],
              [-39.914899771132333, -8.225103932051033],
              [-39.91489974604157, -8.225103933230365],
              [-39.914899720947624, -8.22510393434043],
              [-39.9148996958507, -8.225103935381382],
              [-39.914899670750998, -8.22510393635317],
              [-39.914899645648674, -8.225103937255772],
              [-39.914899620543942, -8.225103938089111],
              [-39.914899595436992, -8.225103938853332],
              [-39.914899570328011, -8.225103939548285],
              [-39.91489954521721, -8.225103940174055],
              [-39.914899520104761, -8.225103940730541],
              [-39.91489949499087, -8.225103941217858],
              [-39.914899469875692, -8.225103941635988],
              [-39.914899444759477, -8.225103941984832],
              [-39.914899419642374, -8.22510394226439],
              [-39.91489939452461, -8.225103942474776],
              [-39.914899369406328, -8.225103942615958],
              [-39.914899344287782, -8.225103942687786],
              [-39.914899319169123, -8.225103942690426],
              [-39.914899294050542, -8.225103942623878],
              [-39.914899268932253, -8.225103942488037],
              [-39.914899243814425, -8.225103942283011],
              [-39.914899218697265, -8.225103942008694],
              [-39.914899193580979, -8.225103941665216],
              [-39.91489916846573, -8.225103941252522],
              [-39.914899143351725, -8.225103940770543],
              [-39.914899118239155, -8.225103940219277],
              [-39.91489909312822, -8.225103939598917],
              [-39.914899068019075, -8.225103938909285],
              [-39.914899042911976, -8.225103938150355],
              [-39.91489901780708, -8.225103937322318],
              [-39.914898992704558, -8.225103936425104],
              [-39.914898967604636, -8.22510393545868],
              [-39.914898942507492, -8.225103934423061],
              [-39.914898917413318, -8.225103933318261],
              [-39.914898892322313, -8.225103932144272],
              [-39.914898867234655, -8.225103930901131],
              [-39.914898842150556, -8.225103929588917],
              [-39.914898817070195, -8.225103928207501],
              [-39.914898791993757, -8.225103926756981],
              [-39.914898766921453, -8.225103925237253],
              [-39.914898741853477, -8.225103923648517],
              [-39.914898716789992, -8.225103921990668],
              [-39.914898691731203, -8.225103920263686],
              [-39.914898666677331, -8.225103918467724],
              [-39.914898641628518, -8.225103916602714],
              [-39.914898616584992, -8.225103914668605],
              [-39.914898591546937, -8.225103912665464],
              [-39.91489856651453, -8.225103910593322],
              [-39.914898541487972, -8.225103908452237],
              [-39.914898516467467, -8.225103906242129],
              [-39.914898491453208, -8.22510390396309],
              [-39.914898466445337, -8.225103901615137],
              [-39.914898441444109, -8.225103899198213],
              [-39.914898416449681, -8.225103896712373],
              [-39.914898391462266, -8.225103894157682],
              [-39.914898366482021, -8.225103891534074],
              [-39.914898341509165, -8.225103888841717],
              [-39.914898316543891, -8.22510388608041],
              [-39.914898291586375, -8.225103883250346],
              [-39.914898266636818, -8.225103880351535],
              [-39.914898241695397, -8.225103877383868],
              [-39.914898216762332, -8.22510387434755],
              [-39.914898191837786, -8.225103871242462],
              [-39.914898166921965, -8.225103868068706],
              [-39.914898142015033, -8.2251038648263],
              [-39.914898117117204, -8.225103861515221],
              [-39.914898092228682, -8.225103858135556],
              [-39.914898067349633, -8.22510385468734],
              [-39.914898042480267, -8.225103851170504],
              [-39.91489801762075, -8.22510384758511],
              [-39.914897992771294, -8.225103843931253],
              [-39.914897967932085, -8.225103840208869],
              [-39.914897943103291, -8.225103836418],
              [-39.914897918285135, -8.22510383255873],
              [-39.9148978934778, -8.225103828631173],
              [-39.914897868681457, -8.225103824635106],
              [-39.914897843896291, -8.225103820570823],
              [-39.914897819122515, -8.22510381643815],
              [-39.914897794360321, -8.22510381223724],
              [-39.914897769609894, -8.225103807968024],
              [-39.914897744871411, -8.225103803630688],
              [-39.914897720145071, -8.225103799225213],
              [-39.914897695431065, -8.225103794751501],
              [-39.914897670729566, -8.225103790209685],
              [-39.914897646040785, -8.225103785599812],
              [-39.914897621364894, -8.225103780921986],
              [-39.914897596702104, -8.225103776176038],
              [-39.914897572052574, -8.225103771362287],
              [-39.914897518361322, -8.225103751599795],
              [-39.913773644856441, -8.224882640357997],
              [-39.913773620657139, -8.224882661702109],
              [-39.913773513407222, -8.224882639933151],
              [-39.913773406428561, -8.224882616877659],
              [-39.913773299736668, -8.224882592538826],
              [-39.913773193347076, -8.224882566920279],
              [-39.913773087275239, -8.224882540025835],
              [-39.913772981536589, -8.224882511859272],
              [-39.913772876146474, -8.2248824824248],
              [-39.913772771120229, -8.22488245172657],
              [-39.913772666473093, -8.224882419769109],
              [-39.913772562220323, -8.224882386557022],
              [-39.913772458377039, -8.224882352095266],
              [-39.913772354958347, -8.22488231638869],
              [-39.913772251979282, -8.224882279442564],
              [-39.913772149454807, -8.2248822412622],
              [-39.913772047399831, -8.224882201853307],
              [-39.913771945829168, -8.224882161221377],
              [-39.913771844757612, -8.224882119372507],
              [-39.913771744199842, -8.224882076312706],
              [-39.91377164417046, -8.224882032048237],
              [-39.913771544684018, -8.22488198658553],
              [-39.913771445755003, -8.224881939931235],
              [-39.913771347397734, -8.224881892092014],
              [-39.913771249626578, -8.224881843074941],
              [-39.913771152455695, -8.224881792887198],
              [-39.91377105589924, -8.224881741535821],
              [-39.913770959971238, -8.22488168902853],
              [-39.913770864685624, -8.224881635372965],
              [-39.913770770056239, -8.224881580576652],
              [-39.913770676096853, -8.224881524647829],
              [-39.913770582821137, -8.224881467594509],
              [-39.913770490242641, -8.224881409424993],
              [-39.913770398374815, -8.224881350147728],
              [-39.913770307231026, -8.224881289771373],
              [-39.913770216824496, -8.224881228304779],
              [-39.913770127168377, -8.224881165756656],
              [-39.913770038275707, -8.224881102136232],
              [-39.913769950159413, -8.224881037452782],
              [-39.913769862832304, -8.224880971715667],
              [-39.913769776307056, -8.22488090493446],
              [-39.913769690596261, -8.224880837118814],
              [-39.913769605712375, -8.224880768278647],
              [-39.913769521667732, -8.22488069842397],
              [-39.913769438474539, -8.224880627564877],
              [-39.913769356144918, -8.224880555711753],
              [-39.913769274690821, -8.224880482874966],
              [-39.913769194124093, -8.22488040906517],
              [-39.913769114456414, -8.22488033429298],
              [-39.913769035699403, -8.22488025856943],
              [-39.913768957864477, -8.224880181905334],
              [-39.913768880962976, -8.224880104311993],
              [-39.913768805006079, -8.22488002580058],
              [-39.913768730004797, -8.224879946382584],
              [-39.913768655970067, -8.224879866069614],
              [-39.913768582912624, -8.224879784873099],
              [-39.913768510843099, -8.224879702805037],
              [-39.913768439771978, -8.224879619877386],
              [-39.913768369709565, -8.224879536102058],
              [-39.913768300666071, -8.22487945149134],
              [-39.913768232651513, -8.224879366057532],
              [-39.913768165675783, -8.224879279812949],
              [-39.91376809974863, -8.224879192770194],
              [-39.913768034879624, -8.224879104941962],
              [-39.91376797107818, -8.224879016340902],
              [-39.913767908353599, -8.224878926980026],
              [-39.91376784671499, -8.224878836872259],
              [-39.913767786171299, -8.224878746030649],
              [-39.913767726731344, -8.224878654468505],
              [-39.91376766840375, -8.224878562199009],
              [-39.913767611197002, -8.224878469235708],
              [-39.913767555119442, -8.224878375592061],
              [-39.913767500179162, -8.224878281281724],
              [-39.913767446384213, -8.224878186318278],
              [-39.913767393742368, -8.224878090715686],
              [-39.913767342261309, -8.22487799448764],
              [-39.913767291948467, -8.224877897648383],
              [-39.913767242811218, -8.224877800211759],
              [-39.913767194856675, -8.224877702192073],
              [-39.913767148091821, -8.224877603603522],
              [-39.913767102523437, -8.224877504460448],
              [-39.913767058158165, -8.224877404777223],
              [-39.913767015002442, -8.224877304568386],
              [-39.913766973062536, -8.224877203848511],
              [-39.913766932344544, -8.224877102632208],
              [-39.913766892854404, -8.224877000934223],
              [-39.913766854597839, -8.224876898769267],
              [-39.913766817580424, -8.224876796152264],
              [-39.91376678180751, -8.22487669309815],
              [-39.913766747284328, -8.224876589621854],
              [-39.913766714015878, -8.224876485738362],
              [-39.913766682007008, -8.224876381462819],
              [-39.913766651262357, -8.224876276810409],
              [-39.913766621786401, -8.224876171796456],
              [-39.913766593583446, -8.224876066435979],
              [-39.913766566657557, -8.224875960744463],
              [-39.91376654101267, -8.224875854737196],
              [-39.913766516652494, -8.224875748429557],
              [-39.913766493580582, -8.224875641837178],
              [-39.91376647180028, -8.224875534975387],
              [-39.913766451314608, -8.224875427859963],
              [-39.91376645635232, -8.224875421193424],
              [-39.913538582019882, -8.22364382302124],
              [-39.913538581191673, -8.223643461247883],
              [-39.913311130141132, -8.222635710232998],
              [-39.913311181636914, -8.222635661972165],
              [-39.913311161670585, -8.222635575459227],
              [-39.913311140852898, -8.22263548914575],
              [-39.91331111918587, -8.222635403039998],
              [-39.913311096671613, -8.222635317150303],
              [-39.913311073312265, -8.222635231484851],
              [-39.913311049110057, -8.222635146051857],
              [-39.91331102406739, -8.222635060859705],
              [-39.91331099818661, -8.222634975916476],
              [-39.91331097147026, -8.222634891230467],
              [-39.913310943920862, -8.222634806809813],
              [-39.913310915541111, -8.222634722662574],
              [-39.91331088633374, -8.222634638796814],
              [-39.913310856301521, -8.222634555220665],
              [-39.91331082544739, -8.222634471942309],
              [-39.913310793774308, -8.222634388969599],
              [-39.913310761285324, -8.222634306310608],
              [-39.913310727983578, -8.222634223973291],
              [-39.913310693872234, -8.222634141965543],
              [-39.913310658954636, -8.222634060295274],
              [-39.913310623234125, -8.222633978970412],
              [-39.913310586714147, -8.222633897998678],
              [-39.913310549398197, -8.222633817387972],
              [-39.913310511289893, -8.222633737145994],
              [-39.913310472392894, -8.222633657280609],
              [-39.913310432710958, -8.222633577799316],
              [-39.913310392247901, -8.222633498709863],
              [-39.91331035100761, -8.222633420019964],
              [-39.913310308994092, -8.222633341737016],
              [-39.913310266211354, -8.222633263868683],
              [-39.913310222663561, -8.222633186422446],
              [-39.913310178354855, -8.222633109405763],
              [-39.913310133289563, -8.222633032825994],
              [-39.913310087471949, -8.222632956690658],
              [-39.913310040906516, -8.222632881006904],
              [-39.913309993597679, -8.222632805782185],
              [-39.913309945550047, -8.222632731023662],
              [-39.913309896768212, -8.222632656738494],
              [-39.91330984725689, -8.222632582933956],
              [-39.913309797020837, -8.222632509617105],
              [-39.913309746064918, -8.222632436794898],
              [-39.91330969439403, -8.222632364474565],
              [-39.913309642013139, -8.222632292662976],
              [-39.913309588927305, -8.222632221366938],
              [-39.913309535141629, -8.22263215059348],
              [-39.91330948066129, -8.222632080349316],
              [-39.913309425491569, -8.222632010641235],
              [-39.91330936963773, -8.222631941475933],
              [-39.913309313105209, -8.222631872860171],
              [-39.913309255899406, -8.222631804800537],
              [-39.913309198025864, -8.222631737303439],
              [-39.913309139490146, -8.222631670375568],
              [-39.913309080297864, -8.222631604023215],
              [-39.913309020454761, -8.222631538252902],
              [-39.913308959966585, -8.222631473070949],
              [-39.913308898839169, -8.222631408483489],
              [-39.91330883707839, -8.222631344496916],
              [-39.913308774690208, -8.222631281117293],
              [-39.913308711680635, -8.222631218350744],
              [-39.913308648055718, -8.222631156203432],
              [-39.913308583821603, -8.222631094681073],
              [-39.913308518984515, -8.222631033789959],
              [-39.913308453550627, -8.222630973535654],
              [-39.9133083875263, -8.222630913924128],
              [-39.913308320917864, -8.222630854961043],
              [-39.913308253731742, -8.222630796652069],
              [-39.913308185974437, -8.222630739002835],
              [-39.913308117652406, -8.22263068201892],
              [-39.913308048772308, -8.222630625705818],
              [-39.91330797934075, -8.222630570068967],
              [-39.913307909364377, -8.222630515113744],
              [-39.913307838849988, -8.222630460845398],
              [-39.913307767804355, -8.222630407269078],
              [-39.91330769623432, -8.222630354390072],
              [-39.913307624146789, -8.222630302213457],
              [-39.913307551548684, -8.22263025074419],
              [-39.913307478447038, -8.222630199987318],
              [-39.913307404848844, -8.2226301499476],
              [-39.913307330761228, -8.222630100630015],
              [-39.91330725619131, -8.222630052039259],
              [-39.913307181146266, -8.222630004179988],
              [-39.913307105633351, -8.222629957056823],
              [-39.91330702965984, -8.222629910674241],
              [-39.913306953233018, -8.222629865036785],
              [-39.913306876360281, -8.222629820148855],
              [-39.913306799049018, -8.222629776014772],
              [-39.913306721306675, -8.222629732638747],
              [-39.91330664314075, -8.222629690025073],
              [-39.913306564558766, -8.222629648177643],
              [-39.913306485568313, -8.222629607100634],
              [-39.913306406176943, -8.222629566797936],
              [-39.913306326392366, -8.222629527273579],
              [-39.913306246222241, -8.222629488531245],
              [-39.913306165674328, -8.222629450574647],
              [-39.913306084756336, -8.22262941340747],
              [-39.91330600347608, -8.222629377033398],
              [-39.913305921841378, -8.222629341455773],
              [-39.913305839860129, -8.222629306678078],
              [-39.913305757540179, -8.222629272703664],
              [-39.913305674889507, -8.22262923953595],
              [-39.913305668231075, -8.222629210683138],
              [-39.912180018839585, -8.222183887831653],
              [-39.912180016875432, -8.222183893743809],
              [-39.912179984439348, -8.222183880982799],
              [-39.912179951954307, -8.222183868346026],
              [-39.912179919420808, -8.222183855833636],
              [-39.912179886839262, -8.222183843445853],
              [-39.912179854210201, -8.222183831182836],
              [-39.912179821534117, -8.222183819044833],
              [-39.912179788811457, -8.22218380703193],
              [-39.912179756042711, -8.222183795144387],
              [-39.912179723228384, -8.222183783382318],
              [-39.912179690368951, -8.222183771745875],
              [-39.91217965746489, -8.222183760235341],
              [-39.912179624516675, -8.222183748850817],
              [-39.912179591524804, -8.222183737592434],
              [-39.912179558489775, -8.222183726460333],
              [-39.912179525412078, -8.222183715454779],
              [-39.912179492292161, -8.222183704575965],
              [-39.91217945913057, -8.222183693823906],
              [-39.912179425927739, -8.222183683198958],
              [-39.91217939268423, -8.222183672700973],
              [-39.91217935940044, -8.222183662330353],
              [-39.912179326076931, -8.222183652087251],
              [-39.912179292714171, -8.22218364197178],
              [-39.912179259312651, -8.222183631983976],
              [-39.912179225872869, -8.222183622124092],
              [-39.912179192395307, -8.222183612392255],
              [-39.91217915888047, -8.222183602788553],
              [-39.912179125328855, -8.222183593313288],
              [-39.912179091740938, -8.222183583966459],
              [-39.912179058117246, -8.222183574748197],
              [-39.912179024458247, -8.222183565658737],
              [-39.912178990764467, -8.222183556698068],
              [-39.912178957036367, -8.22218354786653],
              [-39.912178923274475, -8.222183539164122],
              [-39.912178889479279, -8.222183530590859],
              [-39.912178855651256, -8.222183522147121],
              [-39.912178821790931, -8.222183513832835],
              [-39.912178787898796, -8.222183505648255],
              [-39.912178753975375, -8.222183497593429],
              [-39.912178720021139, -8.222183489668478],
              [-39.912178686036569, -8.222183481873461],
              [-39.912178652022234, -8.222183474208652],
              [-39.912178617978554, -8.222183466674064],
              [-39.912178583906091, -8.222183459269781],
              [-39.912178549805326, -8.222183451995987],
              [-39.912178515676786, -8.22218344485268],
              [-39.912178481520932, -8.222183437840114],
              [-39.912178447338299, -8.222183430958287],
              [-39.912178413129382, -8.222183424207316],
              [-39.912178378894694, -8.222183417587281],
              [-39.912178344634725, -8.222183411098349],
              [-39.912178310349994, -8.222183404740612],
              [-39.912178276040997, -8.222183398514092],
              [-39.912178241708233, -8.22218339241893],
              [-39.912178207352241, -8.222183386455168],
              [-39.912178172973483, -8.222183380622932],
              [-39.912178138572514, -8.222183374922343],
              [-39.912178104149824, -8.222183369353516],
              [-39.912178069705895, -8.222183363916301],
              [-39.912178035241254, -8.222183358611032],
              [-39.91217800075642, -8.222183353437744],
              [-39.912177966251903, -8.222183348396394],
              [-39.912177931728181, -8.222183343487179],
              [-39.912177897185799, -8.222183338710082],
              [-39.912177862625249, -8.222183334065253],
              [-39.912177828047056, -8.222183329552692],
              [-39.912177793451704, -8.222183325172484],
              [-39.912177758839725, -8.222183320924737],
              [-39.912177724211617, -8.222183316809513],
              [-39.912177689567891, -8.222183312826735],
              [-39.912177654909073, -8.222183308976673],
              [-39.912177620235646, -8.222183305259243],
              [-39.912177585548164, -8.222183301674512],
              [-39.912177550847119, -8.222183298222491],
              [-39.912177516133013, -8.222183294903473],
              [-39.91217748140636, -8.222183291717245],
              [-39.91217744666767, -8.222183288663899],
              [-39.912177411917483, -8.222183285743526],
              [-39.912177377156283, -8.22218328295622],
              [-39.912177342384595, -8.222183280301902],
              [-39.912177307602924, -8.222183277780745],
              [-39.912177272811782, -8.222183275392627],
              [-39.912177238011701, -8.222183273137771],
              [-39.912177203203179, -8.222183271016071],
              [-39.912177168386719, -8.222183269027594],
              [-39.912177133562864, -8.222183267172369],
              [-39.912177098732123, -8.222183265450386],
              [-39.91217706389498, -8.222183263861821],
              [-39.912177029051968, -8.222183262406473],
              [-39.912176994203612, -8.222183261084561],
              [-39.912176959350404, -8.222183259895967],
              [-39.91217692449289, -8.222183258840804],
              [-39.912176889631567, -8.222183257919026],
              [-39.912176854766926, -8.222183257130673],
              [-39.912176819899514, -8.222183256475825],
              [-39.912176785029821, -8.222183255954404],
              [-39.91217675015838, -8.222183255566339],
              [-39.91217671528571, -8.222183255311739],
              [-39.912176680412315, -8.222183255190679],
              [-39.912176645538686, -8.222183255203046],
              [-39.912176658407105, -8.222183262347459],
              [-39.911169779650578, -8.222185545069191],
              [-39.911169789247801, -8.222185537874612],
              [-39.911169645213761, -8.222185539339403],
              [-39.911169501221195, -8.222185543080659],
              [-39.911169357306328, -8.222185549097592],
              [-39.911169213505389, -8.222185557388411],
              [-39.911169069854601, -8.222185567951238],
              [-39.911168926390111, -8.222185580783393],
              [-39.911168783148035, -8.222185595881559],
              [-39.911168640164441, -8.222185613242035],
              [-39.911168497475323, -8.222185632860409],
              [-39.911168355116594, -8.222185654731698],
              [-39.911168213124085, -8.222185678850414],
              [-39.911168071533581, -8.222185705210578],
              [-39.911167930380692, -8.22218573380542],
              [-39.911167789700933, -8.222185764627801],
              [-39.911167649529794, -8.222185797669979],
              [-39.911167509902484, -8.2221858329236],
              [-39.911167370854187, -8.22218587037986],
              [-39.911167232419913, -8.222185910029243],
              [-39.9111670946345, -8.222185951861814],
              [-39.91116695753265, -8.22218599586702],
              [-39.911166821148854, -8.222186042033812],
              [-39.911166685517451, -8.22218609035049],
              [-39.911166550672618, -8.22218614080507],
              [-39.911166416648278, -8.222186193384561],
              [-39.911166283478153, -8.222186248076058],
              [-39.911166151195786, -8.222186304865565],
              [-39.911166019834482, -8.222186363738793],
              [-39.911165889427309, -8.222186424681006],
              [-39.911165760007087, -8.222186487676936],
              [-39.911165631606409, -8.222186552710523],
              [-39.911165504257568, -8.222186619765484],
              [-39.911165377992674, -8.222186688825024],
              [-39.911165252843489, -8.222186759871615],
              [-39.911165128841503, -8.222186832887479],
              [-39.911165006017946, -8.222186907854113],
              [-39.91116488440376, -8.222186984752804],
              [-39.911164764029529, -8.222187063564103],
              [-39.911164644925556, -8.222187144268203],
              [-39.911164527121834, -8.222187226844801],
              [-39.911164410648055, -8.222187311273021],
              [-39.911164295533482, -8.222187397531641],
              [-39.911164181807138, -8.222187485598949],
              [-39.911164069497616, -8.222187575452846],
              [-39.911163958633225, -8.222187667070635],
              [-39.911163849241845, -8.2221877604293],
              [-39.911163741351025, -8.222187855505243],
              [-39.911163634987922, -8.222187952274714],
              [-39.911163530179351, -8.222188050713156],
              [-39.911163426951632, -8.222188150795882],
              [-39.911163325330797, -8.222188252497714],
              [-39.91116322534242, -8.222188355792991],
              [-39.911163127011662, -8.222188460655707],
              [-39.911163030363305, -8.222188567059549],
              [-39.911162935421629, -8.222188674977618],
              [-39.911162842210572, -8.222188784382823],
              [-39.911162750753597, -8.222188895247607],
              [-39.911162661073732, -8.222189007544062],
              [-39.911162573193543, -8.222189121243927],
              [-39.911162487135158, -8.222189236318476],
              [-39.911162402920219, -8.222189352738985],
              [-39.911162320569957, -8.222189470475843],
              [-39.911162240105099, -8.222189589499605],
              [-39.911162161545874, -8.222189709780258],
              [-39.9111620849121, -8.222189831287515],
              [-39.911162010223038, -8.222189953990787],
              [-39.911161937497518, -8.222190077859246],
              [-39.911161866753815, -8.222190202861622],
              [-39.911161798009744, -8.222190328966432],
              [-39.911161731282661, -8.222190456141991],
              [-39.911161666589301, -8.222190584356326],
              [-39.911161603945985, -8.222190713577003],
              [-39.911161543368465, -8.222190843771678],
              [-39.911161484871997, -8.222190974907409],
              [-39.911161428471324, -8.222191106951318],
              [-39.911161374180615, -8.222191239870128],
              [-39.911161322013555, -8.222191373630272],
              [-39.911161271983261, -8.222191508198245],
              [-39.911161224102365, -8.222191643539972],
              [-39.911161178382891, -8.222191779621587],
              [-39.911161134836341, -8.222191916408701],
              [-39.911161093473702, -8.222192053866952],
              [-39.911161054305374, -8.222192191961758],
              [-39.911161017341207, -8.222192330658157],
              [-39.91116098259053, -8.222192469921408],
              [-39.91116095006209, -8.222192609716521],
              [-39.911160919764058, -8.22219275000816],
              [-39.91116089170405, -8.222192890760958],
              [-39.911160865889187, -8.22219303193965],
              [-39.911160842325877, -8.222193173508558],
              [-39.911160821020147, -8.222193315432138],
              [-39.91116080197731, -8.222193457674647],
              [-39.911160785202156, -8.222193600200177],
              [-39.911160770698928, -8.222193742972983],
              [-39.911160758471269, -8.222193885957061],
              [-39.911160748522256, -8.222194029116437],
              [-39.911160740854399, -8.22219417241503],
              [-39.911160735469629, -8.222194315816841],
              [-39.911160732369297, -8.22219445928563],
              [-39.911160731554169, -8.222194602785427],
              [-39.911160721956179, -8.222194609980718],
              [-39.91116198356432, -8.222747219226322],
              [-39.911161993159247, -8.222747210766578],
              [-39.911161993253231, -8.222747236206292],
              [-39.911161993419057, -8.222747261645686],
              [-39.911161993656705, -8.222747287084472],
              [-39.911161993966175, -8.222747312522449],
              [-39.911161994347459, -8.222747337959555],
              [-39.911161994800572, -8.222747363395404],
              [-39.911161995325493, -8.222747388829912],
              [-39.911161995922214, -8.222747414262892],
              [-39.911161996590742, -8.222747439694006],
              [-39.911161997331071, -8.222747465123241],
              [-39.911161998143193, -8.222747490550223],
              [-39.911161999027115, -8.222747515974874],
              [-39.911161999982809, -8.222747541397018],
              [-39.911162001010268, -8.222747566816265],
              [-39.911162002109485, -8.222747592232595],
              [-39.911162003280467, -8.222747617645723],
              [-39.911162004523206, -8.22274764305546],
              [-39.911162005837667, -8.222747668461677],
              [-39.911162007223851, -8.222747693864026],
              [-39.91116200868175, -8.222747719262406],
              [-39.911162010211363, -8.222747744656665],
              [-39.911162011812657, -8.222747770046499],
              [-39.911162013485622, -8.222747795431744],
              [-39.911162015230275, -8.222747820812105],
              [-39.911162017046578, -8.222747846187618],
              [-39.911162018934505, -8.222747871557901],
              [-39.911162020894047, -8.222747896922765],
              [-39.911162022925204, -8.222747922282061],
              [-39.911162025027956, -8.222747947635565],
              [-39.911162027202288, -8.222747972983132],
              [-39.911162029448157, -8.222747998324403],
              [-39.911162031765578, -8.222748023659328],
              [-39.911162034154522, -8.222748048987592],
              [-39.911162036614947, -8.222748074309086],
              [-39.911162039146888, -8.222748099623647],
              [-39.911162041750273, -8.222748124930989],
              [-39.911162044425105, -8.22274815023094],
              [-39.911162047171338, -8.2227481755233],
              [-39.911162049989002, -8.222748200807832],
              [-39.911162052878019, -8.222748226084388],
              [-39.911162055838389, -8.222748251352762],
              [-39.911162058870097, -8.222748276612723],
              [-39.911162061973108, -8.222748301864113],
              [-39.911162065147408, -8.222748327106681],
              [-39.911162068392947, -8.222748352340211],
              [-39.911162071709732, -8.222748377564617],
              [-39.911162075097728, -8.222748402779562],
              [-39.911162078556899, -8.222748427985014],
              [-39.911162082087202, -8.22274845318055],
              [-39.911162085688638, -8.222748478366157],
              [-39.91116208936117, -8.222748503541576],
              [-39.911162093104771, -8.222748528706656],
              [-39.911162096919391, -8.222748553861035],
              [-39.911162100805036, -8.222748579004739],
              [-39.911162104761644, -8.222748604137362],
              [-39.911162108789185, -8.222748629258863],
              [-39.911162112887652, -8.222748654368896],
              [-39.91116211705701, -8.222748679467381],
              [-39.911162121297188, -8.222748704554128],
              [-39.911162125608186, -8.222748729628877],
              [-39.911162129989961, -8.222748754691422],
              [-39.911162134442499, -8.222748779741606],
              [-39.911162138965722, -8.222748804779233],
              [-39.911162143559622, -8.222748829804027],
              [-39.911162148224157, -8.222748854815945],
              [-39.911162152959292, -8.222748879814631],
              [-39.911162157765013, -8.222748904799982],
              [-39.911162162641219, -8.222748929771697],
              [-39.911162167587946, -8.222748954729669],
              [-39.911162172605096, -8.222748979673739],
              [-39.911162177692653, -8.222749004603651],
              [-39.911162182850582, -8.222749029519116],
              [-39.911162188078819, -8.222749054420085],
              [-39.911162193377372, -8.222749079306274],
              [-39.911162198746155, -8.222749104177563],
              [-39.911162204185132, -8.222749129033696],
              [-39.911162209694261, -8.222749153874531],
              [-39.911162215273507, -8.222749178699772],
              [-39.911162220922833, -8.222749203509256],
              [-39.911162226642169, -8.222749228302778],
              [-39.911162232431479, -8.222749253080341],
              [-39.911162238290743, -8.222749277841418],
              [-39.911162244219859, -8.222749302586084],
              [-39.911162250218844, -8.222749327313945],
              [-39.911162256287618, -8.222749352024922],
              [-39.911162262426139, -8.222749376718804],
              [-39.911162268634349, -8.222749401395451],
              [-39.911162274912208, -8.222749426054587],
              [-39.911162281259649, -8.222749450696016],
              [-39.91116228767666, -8.222749475319558],
              [-39.911162294163155, -8.22274949992492],
              [-39.911162300719106, -8.222749524512148],
              [-39.911162307344426, -8.222749549080858],
              [-39.911162314039103, -8.222749573630862],
              [-39.911162320803065, -8.222749598162094],
              [-39.911162327636269, -8.222749622674167],
              [-39.911162334538638, -8.222749647167095],
              [-39.911162341510135, -8.222749671640596],
              [-39.911162348550704, -8.222749696094398],
              [-39.911162352273728, -8.222749660375536],
              [-39.911386633497607, -8.223524527397899],
              [-39.91138660230758, -8.223524476145178],
              [-39.911386647768119, -8.223524638902294],
              [-39.911386690169245, -8.223524802476899],
              [-39.911386729496179, -8.223524966812043],
              [-39.91138676573518, -8.223525131850336],
              [-39.911386798873636, -8.223525297534081],
              [-39.911386828899964, -8.223525463805645],
              [-39.911386855803691, -8.223525630606948],
              [-39.911386879575417, -8.223525797879859],
              [-39.911386900206885, -8.223525965565816],
              [-39.911386917690855, -8.223526133606487],
              [-39.911386932021266, -8.223526301943274],
              [-39.911386943193094, -8.223526470517205],
              [-39.911386951202417, -8.223526639269668],
              [-39.911386956046485, -8.223526808141774],
              [-39.911386957723586, -8.223526977074496],
              [-39.911386956233152, -8.223527146008957],
              [-39.911386951575672, -8.22352731488631],
              [-39.911386943752795, -8.223527483647473],
              [-39.911386932767243, -8.22352765223356],
              [-39.911386918622853, -8.223527820585856],
              [-39.911386901324555, -8.223527988645669],
              [-39.911386880878389, -8.22352815635421],
              [-39.911386857291497, -8.223528323652975],
              [-39.911386830572084, -8.223528490483686],
              [-39.911386800729481, -8.223528656788119],
              [-39.91138676777414, -8.223528822508095],
              [-39.911386731717499, -8.223528987586029],
              [-39.911386692572158, -8.223529151964192],
              [-39.911386650351801, -8.223529315585287],
              [-39.911386605071129, -8.223529478392177],
              [-39.91138655674596, -8.223529640328014],
              [-39.911386505393153, -8.223529801336381],
              [-39.911386451030602, -8.223529961361001],
              [-39.911386393677319, -8.223530120346256],
              [-39.911386333353271, -8.223530278236355],
              [-39.911386270079525, -8.22353043497648],
              [-39.911386203878166, -8.223530590511833],
              [-39.911386134772286, -8.223530744788187],
              [-39.911386062785986, -8.223530897751607],
              [-39.911385987944392, -8.223531049348818],
              [-39.911385910273616, -8.223531199526905],
              [-39.911385829800757, -8.223531348233552],
              [-39.91138574655389, -8.223531495416736],
              [-39.911385660562068, -8.223531641025103],
              [-39.911385571855291, -8.223531785007937],
              [-39.91138548046451, -8.223531927314973],
              [-39.911385386421614, -8.223532067896647],
              [-39.911385289759423, -8.223532206703695],
              [-39.911385190511638, -8.223532343687854],
              [-39.911385088712912, -8.223532478801266],
              [-39.911384984398751, -8.223532611996834],
              [-39.911384877605556, -8.223532743227965],
              [-39.911384768370596, -8.223532872449031],
              [-39.911384656731968, -8.223532999614855],
              [-39.911384542728634, -8.223533124681149],
              [-39.911384426400367, -8.223533247604156],
              [-39.911384307787763, -8.223533368341062],
              [-39.911384186932203, -8.223533486849735],
              [-39.91138406387585, -8.223533603088747],
              [-39.911383938661636, -8.223533717017665],
              [-39.911383811333259, -8.223533828596766],
              [-39.911383681935142, -8.223533937786959],
              [-39.911383550512461, -8.223534044550179],
              [-39.911383417111011, -8.223534148849208],
              [-39.911383281777383, -8.223534250647742],
              [-39.911383144558776, -8.223534349910109],
              [-39.91138300550309, -8.223534446601837],
              [-39.911382864658805, -8.22353454068905],
              [-39.911382722075089, -8.223534632138993],
              [-39.91138257780171, -8.223534720919696],
              [-39.911382431888967, -8.223534807000233],
              [-39.911382284387791, -8.223534890350598],
              [-39.911382135349641, -8.223534970941669],
              [-39.911381984826527, -8.223535048745314],
              [-39.911381832870958, -8.223535123734425],
              [-39.911381679535957, -8.223535195882851],
              [-39.911381524875019, -8.223535265165319],
              [-39.911381368942124, -8.223535331557763],
              [-39.911381211791635, -8.223535395036979],
              [-39.911381053478443, -8.223535455580752],
              [-39.911380894057757, -8.223535513168075],
              [-39.911380733585197, -8.223535567778772],
              [-39.911380572116734, -8.223535619393818],
              [-39.911380409708762, -8.223535667995217],
              [-39.911380246417885, -8.223535713566058],
              [-39.911380082301136, -8.223535756090348],
              [-39.911379917415715, -8.223535795553175],
              [-39.911379751819204, -8.223535831940994],
              [-39.911379585569357, -8.223535865241013],
              [-39.911379418724188, -8.223535895441527],
              [-39.911379251341899, -8.223535922532012],
              [-39.911379083480917, -8.223535946503173],
              [-39.911378915199776, -8.223535967346487],
              [-39.911378746557219, -8.223535985054776],
              [-39.911378577612069, -8.223535999621728],
              [-39.911378408423282, -8.223536011042452],
              [-39.91137823904991, -8.223536019312881],
              [-39.911378069550999, -8.223536024430031],
              [-39.911377899985446, -8.223536026392464],
              [-39.911377853709908, -8.223536033671007],
              [-39.910715677186516, -8.223537534117263],
              [-39.910715684479023, -8.223537526927032],
              [-39.910715605446171, -8.223537526763371],
              [-39.910715526417789, -8.223537525914233],
              [-39.910715447399802, -8.223537524379676],
              [-39.910715368398215, -8.223537522159887],
              [-39.910715289419031, -8.223537519255038],
              [-39.910715210468204, -8.223537515665369],
              [-39.910715131551761, -8.223537511390967],
              [-39.910715052675641, -8.223537506432358],
              [-39.910714973845842, -8.223537500789881],
              [-39.910714895068359, -8.223537494463928],
              [-39.910714816349113, -8.223537487455037],
              [-39.9107147376941, -8.223537479763623],
              [-39.910714659109274, -8.223537471390294],
              [-39.910714580600612, -8.223537462335839],
              [-39.910714502174024, -8.223537452600706],
              [-39.910714423835472, -8.223537442185856],
              [-39.910714345590911, -8.223537431091934],
              [-39.910714267446266, -8.223537419319836],
              [-39.910714189407443, -8.223537406870424],
              [-39.91071411148036, -8.223537393744708],
              [-39.910714033670935, -8.223537379943524],
              [-39.910713955985038, -8.223537365468102],
              [-39.910713878428588, -8.223537350319507],
              [-39.910713801007446, -8.223537334498838],
              [-39.910713723727476, -8.223537318007248],
              [-39.910713646594559, -8.223537300846111],
              [-39.910713569614529, -8.223537283016618],
              [-39.910713492793199, -8.223537264520106],
              [-39.910713416136403, -8.223537245358106],
              [-39.910713339649973, -8.223537225531954],
              [-39.910713263339673, -8.223537205043268],
              [-39.9107131872113, -8.223537183893477],
              [-39.910713111270653, -8.223537162084206],
              [-39.910713035523429, -8.223537139617115],
              [-39.91071295997542, -8.223537116493983],
              [-39.910712884632304, -8.223537092716493],
              [-39.910712809499834, -8.223537068286481],
              [-39.910712734583669, -8.22353704320571],
              [-39.910712659889526, -8.223537017476236],
              [-39.910712585423056, -8.223536991099913],
              [-39.910712511189871, -8.223536964078761],
              [-39.910712437195613, -8.223536936414742],
              [-39.910712363445903, -8.223536908110074],
              [-39.910712289946332, -8.223536879166899],
              [-39.91071221670245, -8.223536849587296],
              [-39.910712143719827, -8.223536819373606],
              [-39.910712071003978, -8.223536788528039],
              [-39.910711998560423, -8.223536757053019],
              [-39.910711926394654, -8.223536724950947],
              [-39.91071185451213, -8.223536692224179],
              [-39.910711782918305, -8.223536658875204],
              [-39.91071171161861, -8.223536624906597],
              [-39.910711640618452, -8.223536590320833],
              [-39.910711569923194, -8.223536555120624],
              [-39.910711499538202, -8.223536519308682],
              [-39.910711429468826, -8.22353648288763],
              [-39.910711359720352, -8.223536445860205],
              [-39.910711290298082, -8.223536408229261],
              [-39.910711221207272, -8.223536369997749],
              [-39.910711152453153, -8.223536331168352],
              [-39.91071108404094, -8.223536291744146],
              [-39.910711015975828, -8.223536251728209],
              [-39.91071094826296, -8.223536211123305],
              [-39.910710880907487, -8.223536169932798],
              [-39.910710813914477, -8.223536128159628],
              [-39.910710747289059, -8.223536085807067],
              [-39.910710681036242, -8.223536042878195],
              [-39.910710615161051, -8.223535999376375],
              [-39.910710549668494, -8.223535955304902],
              [-39.910710484563538, -8.223535910667104],
              [-39.910710419851092, -8.2235358654663],
              [-39.910710355536089, -8.223535819706022],
              [-39.910710291623381, -8.223535773389703],
              [-39.910710228117821, -8.223535726520733],
              [-39.910710165024227, -8.223535679102843],
              [-39.910710102347359, -8.223535631139537],
              [-39.910710040092013, -8.223535582634335],
              [-39.910709978262851, -8.223535533591205],
              [-39.910709916864604, -8.223535484013572],
              [-39.910709855901892, -8.223535433905408],
              [-39.910709795379361, -8.223535383270313],
              [-39.910709735301573, -8.223535332112361],
              [-39.910709675673104, -8.223535280435243],
              [-39.910709616498465, -8.223535228242916],
              [-39.910709557782134, -8.223535175539352],
              [-39.910709499528579, -8.223535122328542],
              [-39.910709441742192, -8.223535068614515],
              [-39.910709384427378, -8.223535014401454],
              [-39.910709327588442, -8.223534959693215],
              [-39.910709271229727, -8.223534904494224],
              [-39.910709215355496, -8.223534848808372],
              [-39.910709159969976, -8.223534792640182],
              [-39.910709105077359, -8.223534735993743],
              [-39.910709050681831, -8.223534678873351],
              [-39.910708996787513, -8.223534621283333],
              [-39.91070894339844, -8.22353456322811],
              [-39.910708890518713, -8.223534504711999],
              [-39.910708838152317, -8.223534445739533],
              [-39.910708786303381, -8.223534386314686],
              [-39.910708770398159, -8.223534384225722],
              [-39.910040197492492, -8.222761336455465],
              [-39.910040205573182, -8.222761351068227],
              [-39.910040086380008, -8.222761215992232],
              [-39.910039964514404, -8.222761083304118],
              [-39.910039840024531, -8.222760953056417],
              [-39.910039712959637, -8.222760825300558],
              [-39.910039583369972, -8.222760700087074],
              [-39.910039451306766, -8.222760577465605],
              [-39.910039316822235, -8.222760457484435],
              [-39.910039179969587, -8.222760340191158],
              [-39.9100390408029, -8.222760225632106],
              [-39.910038899377227, -8.222760113852575],
              [-39.910038755748502, -8.222760004896802],
              [-39.910038609973483, -8.222759898807791],
              [-39.91003846210986, -8.222759795627562],
              [-39.910038312216074, -8.222759695396968],
              [-39.910038160351398, -8.222759598155447],
              [-39.910038006575881, -8.222759503941695],
              [-39.910037850950339, -8.222759412792843],
              [-39.910037693536317, -8.222759324744811],
              [-39.910037534396047, -8.222759239832655],
              [-39.910037373592459, -8.222759158089792],
              [-39.910037211189163, -8.222759079548641],
              [-39.910037047250334, -8.222759004240237],
              [-39.910036881840838, -8.222758932194203],
              [-39.91003671502606, -8.222758863439251],
              [-39.910036546871979, -8.222758798002451],
              [-39.910036377445074, -8.222758735909785],
              [-39.910036206812343, -8.22275867718561],
              [-39.910036035041266, -8.222758621853394],
              [-39.91003586219977, -8.222758569934769],
              [-39.910035688356189, -8.222758521450361],
              [-39.910035513579274, -8.222758476419425],
              [-39.910035337938133, -8.222758434859729],
              [-39.910035161502222, -8.222758396787629],
              [-39.910034984341301, -8.222758362218258],
              [-39.910034806525431, -8.222758331165277],
              [-39.910034628124926, -8.222758303640971],
              [-39.91003444921035, -8.222758279656185],
              [-39.910034269852403, -8.222758259220411],
              [-39.910034090122046, -8.222758242341722],
              [-39.910033910090341, -8.222758229026837],
              [-39.910033729828477, -8.222758219280983],
              [-39.910033549407743, -8.222758213108023],
              [-39.910033368899462, -8.222758210510412],
              [-39.910033188375024, -8.222758211489145],
              [-39.91003300790581, -8.222758216043811],
              [-39.910032827563192, -8.2227582241728],
              [-39.910032647418468, -8.222758235872563],
              [-39.910032467542884, -8.222758251138828],
              [-39.910032288007564, -8.22275826996527],
              [-39.910032108883513, -8.222758292344665],
              [-39.910031930241537, -8.222758318267996],
              [-39.910031752152307, -8.222758347725081],
              [-39.910031574686229, -8.222758380704301],
              [-39.910031397913464, -8.222758417192628],
              [-39.910031221903928, -8.2227584571755],
              [-39.910031046727212, -8.22275850063731],
              [-39.910030872452602, -8.222758547560622],
              [-39.910030699148983, -8.222758597927069],
              [-39.910030526884931, -8.222758651716706],
              [-39.910030355728509, -8.222758708908332],
              [-39.910030185747431, -8.222758769479064],
              [-39.910030017008928, -8.222758833405255],
              [-39.910029849579672, -8.222758900661477],
              [-39.910029683525913, -8.222758971221133],
              [-39.910029518913277, -8.222759045056282],
              [-39.910029355806898, -8.22275912213793],
              [-39.910029194271253, -8.222759202435389],
              [-39.910029034370211, -8.222759285916966],
              [-39.910028876167011, -8.222759372549662],
              [-39.91002871972421, -8.222759462299312],
              [-39.910028565103651, -8.222759555130223],
              [-39.910028412366515, -8.222759651005809],
              [-39.910028261573174, -8.222759749888237],
              [-39.910028112783245, -8.222759851738177],
              [-39.910027966055594, -8.222759956515638],
              [-39.91002782144821, -8.22276006417898],
              [-39.910027679018292, -8.222760174685614],
              [-39.91002753882217, -8.222760287992054],
              [-39.910027400915254, -8.222760404053266],
              [-39.910027265352099, -8.222760522823501],
              [-39.910027132186315, -8.222760644255738],
              [-39.910027001470525, -8.222760768302031],
              [-39.910026873256449, -8.222760894913174],
              [-39.910026747594785, -8.222761024039201],
              [-39.910026624535213, -8.222761155629078],
              [-39.910026504126392, -8.222761289630682],
              [-39.910026386415936, -8.222761425991118],
              [-39.910026271450413, -8.22276156465645],
              [-39.910026159275247, -8.222761705571825],
              [-39.910026049934842, -8.222761848681463],
              [-39.910025943472405, -8.222761993928803],
              [-39.910025839930007, -8.222762141256554],
              [-39.910025739348647, -8.22276229060633],
              [-39.91002564176808, -8.222762441918983],
              [-39.910025547226859, -8.222762595134856],
              [-39.910025455762401, -8.222762750193233],
              [-39.91002536741086, -8.222762907032914],
              [-39.910025282207187, -8.222763065591751],
              [-39.910025200185068, -8.222763225807181],
              [-39.910025222532866, -8.222763269668754],
              [-39.90969410102732, -8.22342624924744],
              [-39.909694066416364, -8.223426240587379],
              [-39.909694056426609, -8.223426260651717],
              [-39.909694046486727, -8.223426280740552],
              [-39.909694036596825, -8.223426300853895],
              [-39.909694026756959, -8.223426320991608],
              [-39.909694016967173, -8.223426341153456],
              [-39.909694007227543, -8.223426361339515],
              [-39.909693997538092, -8.22342638154945],
              [-39.909693987898912, -8.223426401783296],
              [-39.909693978310059, -8.223426422040829],
              [-39.909693968771577, -8.223426442321971],
              [-39.909693959283572, -8.223426462626463],
              [-39.909693949846037, -8.223426482954396],
              [-39.909693940459078, -8.22342650330561],
              [-39.909693931122717, -8.223426523679793],
              [-39.909693921837039, -8.223426544077007],
              [-39.90969391260208, -8.223426564497082],
              [-39.90969390341791, -8.223426584939837],
              [-39.909693894284594, -8.223426605405272],
              [-39.909693885202167, -8.223426625893095],
              [-39.909693876170685, -8.223426646403347],
              [-39.909693867190228, -8.223426666935721],
              [-39.909693858260837, -8.223426687490228],
              [-39.909693849382556, -8.223426708066675],
              [-39.909693840555434, -8.223426728665011],
              [-39.909693831779556, -8.223426749285009],
              [-39.909693823054937, -8.223426769926673],
              [-39.909693814381697, -8.223426790589684],
              [-39.909693805759808, -8.223426811274095],
              [-39.909693797189377, -8.223426831979749],
              [-39.909693788670431, -8.223426852706408],
              [-39.909693780203035, -8.223426873454066],
              [-39.909693771787225, -8.223426894222577],
              [-39.909693763423078, -8.223426915011776],
              [-39.909693755110631, -8.223426935821463],
              [-39.909693746849946, -8.223426956651668],
              [-39.909693738641039, -8.223426977502193],
              [-39.909693730483994, -8.22342699837286],
              [-39.909693722378847, -8.223427019263584],
              [-39.909693714325662, -8.223427040174265],
              [-39.909693706324468, -8.22342706110471],
              [-39.909693698375335, -8.223427082054982],
              [-39.909693690478292, -8.223427103024612],
              [-39.909693682633424, -8.223427124013803],
              [-39.909693674840717, -8.223427145022177],
              [-39.909693667100257, -8.223427166049738],
              [-39.909693659412106, -8.223427187096338],
              [-39.909693651776294, -8.223427208161908],
              [-39.909693644192849, -8.223427229246175],
              [-39.909693636661849, -8.22342725034914],
              [-39.909693629183323, -8.223427271470518],
              [-39.90969362175732, -8.223427292610396],
              [-39.909693614383883, -8.223427313768445],
              [-39.909693607063048, -8.223427334944658],
              [-39.909693599794899, -8.22342735613889],
              [-39.909693592579451, -8.22342737735093],
              [-39.909693585416747, -8.223427398580759],
              [-39.909693578306822, -8.223427419828189],
              [-39.909693571249747, -8.223427441093056],
              [-39.909693564245536, -8.223427462375259],
              [-39.909693557294261, -8.223427483674719],
              [-39.909693550395957, -8.223427504991301],
              [-39.909693543550631, -8.223427526324791],
              [-39.909693536758368, -8.223427547675076],
              [-39.909693530019219, -8.22342756904207],
              [-39.909693523333182, -8.223427590425636],
              [-39.909693516700308, -8.223427611825661],
              [-39.909693510120668, -8.223427633241929],
              [-39.909693503594262, -8.22342765467436],
              [-39.909693497121147, -8.223427676122862],
              [-39.909693490701386, -8.223427697587248],
              [-39.909693484335001, -8.223427719067431],
              [-39.909693478022014, -8.223427740563244],
              [-39.90969347176248, -8.223427762074589],
              [-39.90969346555643, -8.223427783601242],
              [-39.909693459403911, -8.223427805143155],
              [-39.909693453304961, -8.223427826700247],
              [-39.909693447259606, -8.223427848272259],
              [-39.909693441267883, -8.22342786985911],
              [-39.909693435329856, -8.223427891460696],
              [-39.909693429445539, -8.223427913076904],
              [-39.909693423614961, -8.223427934707543],
              [-39.90969341783817, -8.223427956352449],
              [-39.909693412115196, -8.223427978011616],
              [-39.909693406446081, -8.223427999684766],
              [-39.909693400830861, -8.223428021371912],
              [-39.909693395269557, -8.22342804307276],
              [-39.909693389762204, -8.223428064787326],
              [-39.909693384308859, -8.223428086515444],
              [-39.90969337890953, -8.223428108256901],
              [-39.909693373564274, -8.223428130011529],
              [-39.909693368273089, -8.223428151779324],
              [-39.909693363036027, -8.223428173560123],
              [-39.909693357853129, -8.223428195353815],
              [-39.90969335272441, -8.223428217160162],
              [-39.909693347649934, -8.223428238979203],
              [-39.909693342629687, -8.223428260810602],
              [-39.909693337663718, -8.223428282654298],
              [-39.909693332752063, -8.223428304510236],
              [-39.909693327894765, -8.223428326378247],
              [-39.909693288715665, -8.223428331295317],
              [-39.909471432595325, -8.224433122413567],
              [-39.909471447556982, -8.2244331418435],
              [-39.90947142010566, -8.22443327061989],
              [-39.90947139453867, -8.224433399780732],
              [-39.909471370861489, -8.224433529298654],
              [-39.909471349079084, -8.224433659146152],
              [-39.909471329196094, -8.224433789295823],
              [-39.909471311216734, -8.224433919719976],
              [-39.909471295144819, -8.224434050391098],
              [-39.90947128098373, -8.22443418128128],
              [-39.909471268736496, -8.224434312363039],
              [-39.909471258405688, -8.224434443608505],
              [-39.909471249993508, -8.224434574989909],
              [-39.909471243501741, -8.224434706479302],
              [-39.909471238931758, -8.224434838049021],
              [-39.90947123628451, -8.224434969670989],
              [-39.909471235560588, -8.224435101317448],
              [-39.909471236760119, -8.224435232960365],
              [-39.909471239882869, -8.224435364571992],
              [-39.909471244928156, -8.224435496124471],
              [-39.909471251894949, -8.224435627589765],
              [-39.909471260781721, -8.224435758940155],
              [-39.909471271586632, -8.224435890147744],
              [-39.909471284307372, -8.224436021184692],
              [-39.909471298941249, -8.22443615202339],
              [-39.909471315485163, -8.224436282636002],
              [-39.909471333935592, -8.224436412994772],
              [-39.909471354288662, -8.224436543072297],
              [-39.90947137654004, -8.224436672840914],
              [-39.909471400685014, -8.22443680227315],
              [-39.909471426718454, -8.224436931341376],
              [-39.909471454634883, -8.224437060018548],
              [-39.909471484428344, -8.224437188277324],
              [-39.909471516092538, -8.224437316090354],
              [-39.909471549620775, -8.224437443430803],
              [-39.909471585005917, -8.224437570271444],
              [-39.909471622240503, -8.224437696585666],
              [-39.909471661316623, -8.224437822346477],
              [-39.909471702226014, -8.22443794752736],
              [-39.909471744959966, -8.224438072101773],
              [-39.909471789509496, -8.224438196043335],
              [-39.909471835865091, -8.224438319325708],
              [-39.909471884016966, -8.224438441922864],
              [-39.909471933954933, -8.224438563808732],
              [-39.909471985668389, -8.224438684957629],
              [-39.909472039146387, -8.224438805343805],
              [-39.90947209437757, -8.22443892494168],
              [-39.909472151350286, -8.224439043725956],
              [-39.909472210052421, -8.224439161671548],
              [-39.909472270471561, -8.224439278753398],
              [-39.909472332594888, -8.224439394946767],
              [-39.909472396409257, -8.224439510226972],
              [-39.909472461901132, -8.224439624569573],
              [-39.909472529056643, -8.224439737950336],
              [-39.90947259786158, -8.224439850345338],
              [-39.909472668301333, -8.224439961730726],
              [-39.909472740360982, -8.224440072082745],
              [-39.909472814025285, -8.224440181378284],
              [-39.909472889278632, -8.224440289594057],
              [-39.909472966105049, -8.224440396706978],
              [-39.90947304448828, -8.224440502694643],
              [-39.909473124411718, -8.224440607534424],
              [-39.909473205858426, -8.22444071120413],
              [-39.909473288811157, -8.22444081368173],
              [-39.909473373252297, -8.224440914945648],
              [-39.909473459163998, -8.22444101497439],
              [-39.909473546528048, -8.224441113746762],
              [-39.909473635325938, -8.224441211241814],
              [-39.909473725538831, -8.224441307438861],
              [-39.909473817147642, -8.224441402317556],
              [-39.909473910132952, -8.224441495857802],
              [-39.909474004475058, -8.224441588039745],
              [-39.909474100153972, -8.224441678843867],
              [-39.909474197149422, -8.224441768250985],
              [-39.909474295440852, -8.224441856242093],
              [-39.909474395007457, -8.224441942798592],
              [-39.909474495828114, -8.224442027902093],
              [-39.909474597881484, -8.224442111534643],
              [-39.909474701145918, -8.224442193678323],
              [-39.909474805599579, -8.22444227431601],
              [-39.909474911220286, -8.224442353430495],
              [-39.909475017985713, -8.224442431004954],
              [-39.909475125873186, -8.224442507023046],
              [-39.909475234859897, -8.224442581468667],
              [-39.909475344922733, -8.224442654325875],
              [-39.909475456038351, -8.224442725579433],
              [-39.909475568183247, -8.22444279521415],
              [-39.909475681333646, -8.224442863215286],
              [-39.909475795465568, -8.224442929568488],
              [-39.909475910554832, -8.224442994259578],
              [-39.909476026577053, -8.224443057275021],
              [-39.909476143507668, -8.224443118601256],
              [-39.909476261321885, -8.224443178225446],
              [-39.909476379994743, -8.224443236134944],
              [-39.909476499501103, -8.224443292317458],
              [-39.909476619815649, -8.224443346761062],
              [-39.909476740912865, -8.224443399454183],
              [-39.909476862767143, -8.224443450385731],
              [-39.909476985352612, -8.224443499544931],
              [-39.90947710864333, -8.224443546921249],
              [-39.909477232613156, -8.224443592504796],
              [-39.909477266683112, -8.224443600709082],
              [-39.910714220753036, -8.224888226361617],
              [-39.910714947644735, -8.224888496046502],
              [-39.91240500987459, -8.225333084261411],
              [-39.912404996923463, -8.225333096359494],
              [-39.912405112895776, -8.225333127687609],
              [-39.91240522844172, -8.225333160542203],
              [-39.912405343541053, -8.225333194917459],
              [-39.912405458173616, -8.225333230807392],
              [-39.912405572319344, -8.225333268205707],
              [-39.912405685958213, -8.225333307105789],
              [-39.912405799070321, -8.225333347500932],
              [-39.912405911635858, -8.225333389384035],
              [-39.912406023635121, -8.225333432747703],
              [-39.912406135048464, -8.225333477584304],
              [-39.912406245856367, -8.225333523886128],
              [-39.91240635603944, -8.225333571644953],
              [-39.912406465578371, -8.225333620852515],
              [-39.912406574453961, -8.225333671500096],
              [-39.912406682647152, -8.225333723578744],
              [-39.912406790138981, -8.225333777079614],
              [-39.912406896910618, -8.225333831993014],
              [-39.912407002943347, -8.225333888309621],
              [-39.912407108218623, -8.225333946019335],
              [-39.91240721271798, -8.225334005112131],
              [-39.912407316423092, -8.225334065577695],
              [-39.912407419315834, -8.225334127405349],
              [-39.912407521378171, -8.225334190584418],
              [-39.912407622592198, -8.225334255103592],
              [-39.912407722940202, -8.225334320951738],
              [-39.912407822404596, -8.225334388117309],
              [-39.912407920967965, -8.225334456588538],
              [-39.91240801861305, -8.225334526353409],
              [-39.912408115322727, -8.225334597399621],
              [-39.912408211080042, -8.22533466971486],
              [-39.912408305868247, -8.225334743286362],
              [-39.912408399670731, -8.225334818101381],
              [-39.912408492471037, -8.225334894146608],
              [-39.912408584252958, -8.225334971408875],
              [-39.912408675000378, -8.225335049874502],
              [-39.912408764697389, -8.225335129529849],
              [-39.912408853328294, -8.225335210361072],
              [-39.912408940877583, -8.225335292353817],
              [-39.912409027329893, -8.225335375493728],
              [-39.912409112670097, -8.225335459766359],
              [-39.912409196883218, -8.225335545156865],
              [-39.912409279954531, -8.225335631650333],
              [-39.912409361869472, -8.225335719231554],
              [-39.912409442613701, -8.225335807885266],
              [-39.91240952217305, -8.225335897595814],
              [-39.912409600533607, -8.225335988347643],
              [-39.912409677681616, -8.225336080124656],
              [-39.912409753603576, -8.225336172911023],
              [-39.912409828286194, -8.225336266690281],
              [-39.912409901716387, -8.225336361446107],
              [-39.912409973881267, -8.225336457161783],
              [-39.912410044768237, -8.225336553820691],
              [-39.912410114364825, -8.225336651405879],
              [-39.912410182658888, -8.225336749900194],
              [-39.912410249638434, -8.225336849286323],
              [-39.91241031529173, -8.225336949547012],
              [-39.91241037960728, -8.2253370506645],
              [-39.912410442573801, -8.22533715262122],
              [-39.912410504180308, -8.22533725539936],
              [-39.912410564415943, -8.225337358980745],
              [-39.912410623270198, -8.225337463347348],
              [-39.912410680732755, -8.225337568480823],
              [-39.912410736793561, -8.225337674362795],
              [-39.912410791442753, -8.225337780974728],
              [-39.912410844670788, -8.225337888297824],
              [-39.912410896468344, -8.225337996313389],
              [-39.91241094682632, -8.22533810500259],
              [-39.912410995735932, -8.225338214346225],
              [-39.912411043188584, -8.225338324325126],
              [-39.912411089175983, -8.225338434920209],
              [-39.912411133690043, -8.225338546111951],
              [-39.912411176723005, -8.225338657880892],
              [-39.912411218267295, -8.225338770207443],
              [-39.912411258315657, -8.225338883072029],
              [-39.912411296861059, -8.225338996454717],
              [-39.91241133389677, -8.22533911033578],
              [-39.912411369416269, -8.225339224695166],
              [-39.912411403413365, -8.22533933951286],
              [-39.912411435882099, -8.225339454768868],
              [-39.912411466816756, -8.225339570442843],
              [-39.912411496211945, -8.225339686514587],
              [-39.91241152406252, -8.225339802963827],
              [-39.912411550363558, -8.225339919770006],
              [-39.912411575110504, -8.225340036912831],
              [-39.912411598298988, -8.225340154371667],
              [-39.912411619924967, -8.225340272125925],
              [-39.912411639984647, -8.225340390155051],
              [-39.912411658474497, -8.225340508438482],
              [-39.912411675391311, -8.225340626955168],
              [-39.912411690732092, -8.225340745684667],
              [-39.91241170449419, -8.225340864605936],
              [-39.912411716675159, -8.225340983698299],
              [-39.912411727272875, -8.225341102940869],
              [-39.912411736285485, -8.225341222312768],
              [-39.912411743711417, -8.225341341792978],
              [-39.912411749549378, -8.22534146136066],
              [-39.912411753798281, -8.225341580994883],
              [-39.91241175645748, -8.225341700674562],
              [-39.912411757526627, -8.22534182037861],
              [-39.91241174796162, -8.225341842019466],
              [-39.912415585644204, -8.227019747296449],
              [-39.912415595150236, -8.227019699865052],
              [-39.912415595030019, -8.227019771377146],
              [-39.912415594342214, -8.227019842885944],
              [-39.912415593086855, -8.227019914387238],
              [-39.912415591264022, -8.227019985876344],
              [-39.912415588873827, -8.227020057348849],
              [-39.912415585916435, -8.227020128800284],
              [-39.912415582392015, -8.227020200226152],
              [-39.912415578300781, -8.227020271622091],
              [-39.912415573643017, -8.227020342983522],
              [-39.912415568418972, -8.227020414306073],
              [-39.912415562629015, -8.22702048558525],
              [-39.912415556273501, -8.227020556816553],
              [-39.912415549352801, -8.227020627995534],
              [-39.912415541867361, -8.227020699117828],
              [-39.912415533817651, -8.227020770178905],
              [-39.912415525204182, -8.227020841174415],
              [-39.912415516027494, -8.227020912099773],
              [-39.912415506288127, -8.227020982950666],
              [-39.912415495986743, -8.227021053722545],
              [-39.912415485123944, -8.227021124411152],
              [-39.91241547370042, -8.227021195011943],
              [-39.912415461716883, -8.227021265520543],
              [-39.912415449174105, -8.227021335932571],
              [-39.912415436072841, -8.227021406243489],
              [-39.912415422413936, -8.227021476449139],
              [-39.912415408198221, -8.227021546544879],
              [-39.912415393426599, -8.227021616526487],
              [-39.912415378099979, -8.227021686389525],
              [-39.912415362219335, -8.227021756129727],
              [-39.912415345785647, -8.227021825742565],
              [-39.912415328799959, -8.227021895223793],
              [-39.912415311263317, -8.227021964568985],
              [-39.912415293176835, -8.22702203377397],
              [-39.912415274541615, -8.227022102834225],
              [-39.912415255358859, -8.227022171745588],
              [-39.912415235629744, -8.227022240503622],
              [-39.912415215355495, -8.227022309104104],
              [-39.912415194537409, -8.227022377542797],
              [-39.912415173176761, -8.227022445815235],
              [-39.912415151274899, -8.227022513917422],
              [-39.912415128833182, -8.227022581844839],
              [-39.912415105853029, -8.227022649593408],
              [-39.912415082335855, -8.22702271715878],
              [-39.912415058283159, -8.227022784536771],
              [-39.912415033696419, -8.227022851723168],
              [-39.912415008577192, -8.227022918713788],
              [-39.912414982927046, -8.227022985504483],
              [-39.912414956747575, -8.227023052091059],
              [-39.912414930040413, -8.227023118469264],
              [-39.912414902807228, -8.227023184635044],
              [-39.912414875049748, -8.22702325058413],
              [-39.912414846769678, -8.227023316312568],
              [-39.912414817968816, -8.227023381816224],
              [-39.912414788648938, -8.227023447090806],
              [-39.912414758811877, -8.227023512132495],
              [-39.912414728459517, -8.227023576937132],
              [-39.912414697593761, -8.227023641500544],
              [-39.912414666216499, -8.227023705818844],
              [-39.912414634329735, -8.227023769887928],
              [-39.912414601935424, -8.227023833703885],
              [-39.91241456903564, -8.227023897262548],
              [-39.912414535632387, -8.227023960560174],
              [-39.912414501727795, -8.227024023592579],
              [-39.91241446732397, -8.227024086356053],
              [-39.912414432423063, -8.227024148846446],
              [-39.912414397027248, -8.227024211060028],
              [-39.912414361138758, -8.227024272992791],
              [-39.912414324759808, -8.227024334640888],
              [-39.912414287892702, -8.227024396000482],
              [-39.912414250539726, -8.227024457067781],
              [-39.912414212703219, -8.227024517838895],
              [-39.91241417438556, -8.227024578310015],
              [-39.912414135589131, -8.227024638477424],
              [-39.912414096316354, -8.227024698337358],
              [-39.912414056569695, -8.227024757886014],
              [-39.912414016351619, -8.227024817119736],
              [-39.912413975664684, -8.227024876034749],
              [-39.912413934511392, -8.227024934627449],
              [-39.912413892894342, -8.227024992894098],
              [-39.912413850816122, -8.227025050831031],
              [-39.912413808279382, -8.22702510843472],
              [-39.912413765286736, -8.227025165701541],
              [-39.912413721840913, -8.227025222627894],
              [-39.912413677944627, -8.227025279210244],
              [-39.912413633600615, -8.227025335444985],
              [-39.912413588811631, -8.227025391328683],
              [-39.912413543580506, -8.227025446857741],
              [-39.912413497910059, -8.227025502028846],
              [-39.912413451803147, -8.227025556838445],
              [-39.912413405262633, -8.227025611282974],
              [-39.912413358291452, -8.227025665359339],
              [-39.912413310892525, -8.227025719063901],
              [-39.912413263068849, -8.22702577239348],
              [-39.912413214823339, -8.227025825344619],
              [-39.912413166159105, -8.227025877914025],
              [-39.912413117079126, -8.227025930098476],
              [-39.912413067586471, -8.227025981894677],
              [-39.912413017684273, -8.227026033299357],
              [-39.912412967375843, -8.22702608430863],
              [-39.912412948191367, -8.227026103785596],
              [-39.912308259428272, -8.227130784635246],
              [-39.912308296833757, -8.227130754070696],
              [-39.912308203734568, -8.227130845835518],
              [-39.912308109316328, -8.227130936252758],
              [-39.912308013598661, -8.227131025303702],
              [-39.912307916601513, -8.227131112969797],
              [-39.912307818345056, -8.227131199232813],
              [-39.912307718849739, -8.227131284074769],
              [-39.912307618136282, -8.227131367477941],
              [-39.91230751622561, -8.227131449425153],
              [-39.912307413138961, -8.227131529899154],
              [-39.91230730889778, -8.227131608883386],
              [-39.912307203523753, -8.227131686361226],
              [-39.912307097038827, -8.227131762316718],
              [-39.912306989465137, -8.227131836733973],
              [-39.912306880825099, -8.227131909597466],
              [-39.912306771141303, -8.227131980892059],
              [-39.912306660436577, -8.227132050602966],
              [-39.912306548733945, -8.227132118715668],
              [-39.912306436056689, -8.227132185215931],
              [-39.912306322428215, -8.227132250090051],
              [-39.91230620787222, -8.227132313324372],
              [-39.912306092412493, -8.227132374905672],
              [-39.912305976073085, -8.22713243482141],
              [-39.91230585887822, -8.227132493058912],
              [-39.912305740852283, -8.227132549606115],
              [-39.912305622019801, -8.227132604451267],
              [-39.912305502405552, -8.227132657582862],
              [-39.912305382034383, -8.227132708989956],
              [-39.91230526093139, -8.227132758661844],
              [-39.912305139121756, -8.227132806588148],
              [-39.912305016630803, -8.227132852758851],
              [-39.912304893484063, -8.227132897164424],
              [-39.912304769707148, -8.227132939795585],
              [-39.91230464532579, -8.227132980643457],
              [-39.912304520365922, -8.227133019699609],
              [-39.912304394853521, -8.227133056955736],
              [-39.912304268814708, -8.227133092404289],
              [-39.912304142275737, -8.227133126037906],
              [-39.912304015262912, -8.227133157849389],
              [-39.912303887802636, -8.227133187832237],
              [-39.912303759921521, -8.227133215980134],
              [-39.912303631646125, -8.227133242287382],
              [-39.912303503003145, -8.227133266748353],
              [-39.912303374019345, -8.227133289358003],
              [-39.912303244721585, -8.22713331011164],
              [-39.91230311513678, -8.227133329004904],
              [-39.91230298529188, -8.227133346033954],
              [-39.912302855213916, -8.227133361195172],
              [-39.912302724929937, -8.227133374485394],
              [-39.912302594467079, -8.227133385901942],
              [-39.912302463852484, -8.227133395442415],
              [-39.912302333113367, -8.227133403104665],
              [-39.912302202276848, -8.227133408887237],
              [-39.912302071370249, -8.227133412789039],
              [-39.912301940420761, -8.227133414808995],
              [-39.912301809455663, -8.227133414946934],
              [-39.912301678502175, -8.227133413202665],
              [-39.912301547587575, -8.227133409576584],
              [-39.912301416739112, -8.227133404069518],
              [-39.912301285983993, -8.227133396682431],
              [-39.912301155349454, -8.227133387417135],
              [-39.912301024862678, -8.227133376275356],
              [-39.91230089455081, -8.227133363259501],
              [-39.912300764440957, -8.22713334837221],
              [-39.912300634560211, -8.227133331616656],
              [-39.912300504935587, -8.227133312996211],
              [-39.9123003755941, -8.227133292514875],
              [-39.912300246562616, -8.227133270176937],
              [-39.912300117868035, -8.227133245986879],
              [-39.912299989537111, -8.227133219949829],
              [-39.912299861596537, -8.227133192071294],
              [-39.91229973407296, -8.227133162356894],
              [-39.912299606992939, -8.227133130812899],
              [-39.91229948038287, -8.227133097445964],
              [-39.912299354269145, -8.227133062262837],
              [-39.912299228677959, -8.227133025271039],
              [-39.912299103635519, -8.227132986478075],
              [-39.912298979167787, -8.227132945892224],
              [-39.912298855300705, -8.227132903521815],
              [-39.912298732060009, -8.227132859375688],
              [-39.912298609471392, -8.227132813462944],
              [-39.912298487560335, -8.227132765793275],
              [-39.912298366352218, -8.227132716376513],
              [-39.91229824587225, -8.227132665223058],
              [-39.912298126145537, -8.22713261234342],
              [-39.912298007196981, -8.227132557748609],
              [-39.91229788905131, -8.227132501450043],
              [-39.912297771733151, -8.227132443459473],
              [-39.912297655266883, -8.227132383788897],
              [-39.912297539676757, -8.227132322450791],
              [-39.912297424986875, -8.227132259457809],
              [-39.91229731122101, -8.227132194823232],
              [-39.912297198402911, -8.227132128560342],
              [-39.91229708655603, -8.227132060683012],
              [-39.912296975703654, -8.227131991205356],
              [-39.912296865868818, -8.227131920141845],
              [-39.912296757074444, -8.2271318475073],
              [-39.912296649343105, -8.227131773316714],
              [-39.912296542697256, -8.22713169758566],
              [-39.912296437158709, -8.227131620330404],
              [-39.912296459302986, -8.22713162541625],
              [-39.911852583748157, -8.226801789192262],
              [-39.911852621398431, -8.226801801392684],
              [-39.911852564822553, -8.226801759687691],
              [-39.911852507923825, -8.2268017184209],
              [-39.911852450705723, -8.226801677594739],
              [-39.911852393171621, -8.226801637211771],
              [-39.91185233532503, -8.226801597274289],
              [-39.911852277169388, -8.226801557784881],
              [-39.911852218708198, -8.226801518745713],
              [-39.911852159945006, -8.226801480159285],
              [-39.911852100883308, -8.226801442027732],
              [-39.91185204152665, -8.226801404353605],
              [-39.91185198187862, -8.22680136713895],
              [-39.911851921942798, -8.22680133038612],
              [-39.911851861722774, -8.22680129409728],
              [-39.911851801222191, -8.226801258274676],
              [-39.911851740444668, -8.226801222920287],
              [-39.911851679393855, -8.226801188036484],
              [-39.911851618073413, -8.226801153625113],
              [-39.911851556487072, -8.226801119688465],
              [-39.911851494638476, -8.226801086228413],
              [-39.911851432531385, -8.226801053247028],
              [-39.911851370169501, -8.226801020746379],
              [-39.911851307556596, -8.226800988728275],
              [-39.911851244696415, -8.22680095719473],
              [-39.911851181592745, -8.226800926147552],
              [-39.91185111824938, -8.226800895588793],
              [-39.911851054670123, -8.22680086552004],
              [-39.911850990858809, -8.226800835943289],
              [-39.911850926819248, -8.226800806860238],
              [-39.911850862555298, -8.226800778272608],
              [-39.911850798070816, -8.22680075018221],
              [-39.911850733369697, -8.226800722590726],
              [-39.91185066845582, -8.226800695499671],
              [-39.911850603333072, -8.226800668910814],
              [-39.911850538005396, -8.226800642825705],
              [-39.911850472476679, -8.22680061724594],
              [-39.911850406750901, -8.226800592172991],
              [-39.911850340831982, -8.226800567608432],
              [-39.911850274723896, -8.226800543553761],
              [-39.911850208430614, -8.226800520010235],
              [-39.911850141956116, -8.226800496979591],
              [-39.9118500753044, -8.22680047446288],
              [-39.911850008479476, -8.226800452461738],
              [-39.911849941485364, -8.226800430977292],
              [-39.911849874326087, -8.226800410010876],
              [-39.911849807005659, -8.226800389563833],
              [-39.911849739528158, -8.226800369637308],
              [-39.911849671897613, -8.226800350232461],
              [-39.911849604118125, -8.226800331350612],
              [-39.911849536193714, -8.226800312992749],
              [-39.911849468128501, -8.226800295160064],
              [-39.911849399926574, -8.226800277853506],
              [-39.911849331592038, -8.226800261074269],
              [-39.911849263128971, -8.226800244823359],
              [-39.911849194541496, -8.226800229101604],
              [-39.911849125833747, -8.22680021391005],
              [-39.911849057009846, -8.226800199249583],
              [-39.911848988073949, -8.22680018512108],
              [-39.911848919030191, -8.226800171525435],
              [-39.911848849882674, -8.226800158463487],
              [-39.911848780635623, -8.226800145935879],
              [-39.911848711293167, -8.226800133943495],
              [-39.911848641859464, -8.226800122487086],
              [-39.911848572338698, -8.226800111567233],
              [-39.911848502735054, -8.226800101184521],
              [-39.911848433052711, -8.226800091339836],
              [-39.911848363295853, -8.22680008203346],
              [-39.911848293468672, -8.226800073266206],
              [-39.911848223575376, -8.22680006503851],
              [-39.911848153620134, -8.226800057350831],
              [-39.911848083607175, -8.226800050203634],
              [-39.91184801354072, -8.226800043597459],
              [-39.911847943424966, -8.226800037532572],
              [-39.911847873264108, -8.226800032009383],
              [-39.911847803062393, -8.22680002702822],
              [-39.911847732824015, -8.226800022589339],
              [-39.911847662553214, -8.226800018693105],
              [-39.911847592254205, -8.226800015339695],
              [-39.911847521931207, -8.226800012529294],
              [-39.911847451588464, -8.226800010262087],
              [-39.911847381230189, -8.226800008538243],
              [-39.911847310860601, -8.226800007357905],
              [-39.911847240483965, -8.226800006720952],
              [-39.91184717010448, -8.226800006627672],
              [-39.91184709972638, -8.226800007077854],
              [-39.9118470293539, -8.226800008071589],
              [-39.911846958991255, -8.226800009608867],
              [-39.9118468886427, -8.226800011689408],
              [-39.911846818312455, -8.226800014313291],
              [-39.911846748004727, -8.226800017480251],
              [-39.911846677723766, -8.22680002119006],
              [-39.91184660747377, -8.226800025442573],
              [-39.911846537259002, -8.226800030237561],
              [-39.91184646708362, -8.226800035574659],
              [-39.911846396951915, -8.226800041453544],
              [-39.911846326868044, -8.226800047873967],
              [-39.911846256836242, -8.226800054835373],
              [-39.911846186860721, -8.226800062337508],
              [-39.911846116945654, -8.226800070379836],
              [-39.91184604709531, -8.226800078961862],
              [-39.911846043339388, -8.226800085598164],
              [-39.910946262078902, -8.22691418595878],
              [-39.910946254921541, -8.226914158317014],
              [-39.91094623698455, -8.226914160609176],
              [-39.910946219052171, -8.226914162936964],
              [-39.910946201124496, -8.226914165300366],
              [-39.910946183201588, -8.226914167699219],
              [-39.910946165283505, -8.226914170133774],
              [-39.910946147370311, -8.226914172603784],
              [-39.910946129462111, -8.226914175109414],
              [-39.91094611155895, -8.226914177650492],
              [-39.910946093660897, -8.226914180227084],
              [-39.910946075768038, -8.226914182839314],
              [-39.910946057880444, -8.226914185486995],
              [-39.910946039998166, -8.226914188170108],
              [-39.910946022121294, -8.226914190888737],
              [-39.910946004249894, -8.226914193642832],
              [-39.91094598638405, -8.226914196432361],
              [-39.910945968523798, -8.226914199257422],
              [-39.910945950669223, -8.226914202117849],
              [-39.910945932820432, -8.226914205013674],
              [-39.910945914977447, -8.226914207945063],
              [-39.910945897140365, -8.226914210911714],
              [-39.910945879309246, -8.226914213913767],
              [-39.910945861484173, -8.226914216951199],
              [-39.910945843665218, -8.22691422002408],
              [-39.910945825852409, -8.226914223132201],
              [-39.910945808045881, -8.226914226275774],
              [-39.910945790245648, -8.226914229454612],
              [-39.910945772451825, -8.226914232668772],
              [-39.910945754664446, -8.226914235918303],
              [-39.910945736883626, -8.22691423920309],
              [-39.910945719109385, -8.226914242523122],
              [-39.910945701341845, -8.226914245878435],
              [-39.910945683581026, -8.22691424926899],
              [-39.910945665827015, -8.226914252694808],
              [-39.91094564807991, -8.226914256155867],
              [-39.910945630339754, -8.226914259652103],
              [-39.910945612606618, -8.226914263183517],
              [-39.910945594880602, -8.226914266750187],
              [-39.910945577161719, -8.226914270352017],
              [-39.910945559450077, -8.226914273988911],
              [-39.910945541745754, -8.226914277661059],
              [-39.910945524048806, -8.226914281368217],
              [-39.910945506359319, -8.226914285110615],
              [-39.910945488677328, -8.226914288888022],
              [-39.910945471002947, -8.226914292700551],
              [-39.91094545333619, -8.226914296548173],
              [-39.910945435677192, -8.226914300430783],
              [-39.910945418025975, -8.226914304348485],
              [-39.91094540038263, -8.22691430830116],
              [-39.910945382747236, -8.226914312288786],
              [-39.910945365119851, -8.226914316311522],
              [-39.910945347500522, -8.226914320369142],
              [-39.910945329889358, -8.226914324461738],
              [-39.910945312286394, -8.226914328589316],
              [-39.910945294691729, -8.226914332751804],
              [-39.910945277105434, -8.226914336949177],
              [-39.910945259527573, -8.22691434118145],
              [-39.910945241958181, -8.226914345448582],
              [-39.910945224397359, -8.226914349750633],
              [-39.910945206845177, -8.226914354087498],
              [-39.910945189301721, -8.226914358459117],
              [-39.910945171767011, -8.226914362865571],
              [-39.910945154241169, -8.226914367306842],
              [-39.910945136724244, -8.226914371782884],
              [-39.910945119216272, -8.22691437629374],
              [-39.910945101717381, -8.226914380839183],
              [-39.910945084227613, -8.226914385419455],
              [-39.910945066747033, -8.22691439003443],
              [-39.910945049275711, -8.226914394684037],
              [-39.910945031813725, -8.226914399368242],
              [-39.910945014361147, -8.226914404087266],
              [-39.910944996918033, -8.226914408840818],
              [-39.910944979484448, -8.226914413628984],
              [-39.910944962060491, -8.226914418451665],
              [-39.910944944646189, -8.226914423308994],
              [-39.910944927241644, -8.226914428200901],
              [-39.910944909846918, -8.226914433127257],
              [-39.910944892462076, -8.226914438088141],
              [-39.910944875087182, -8.226914443083571],
              [-39.910944857722313, -8.226914448113412],
              [-39.910944840367549, -8.226914453177695],
              [-39.910944823022923, -8.226914458276431],
              [-39.910944805688537, -8.226914463409605],
              [-39.910944788364446, -8.226914468577192],
              [-39.910944771050723, -8.226914473779054],
              [-39.910944753747437, -8.226914479015326],
              [-39.910944736454653, -8.226914484285876],
              [-39.910944719172448, -8.226914489590833],
              [-39.910944701900888, -8.226914494929963],
              [-39.910944684640015, -8.226914500303373],
              [-39.910944667389941, -8.226914505711106],
              [-39.910944650150711, -8.226914511153012],
              [-39.910944632922387, -8.226914516629158],
              [-39.910944615705056, -8.226914522139381],
              [-39.910944598498766, -8.226914527683855],
              [-39.91094458130361, -8.226914533262404],
              [-39.910944564119625, -8.226914538875123],
              [-39.910944546946915, -8.226914544521902],
              [-39.910944529785517, -8.226914550202752],
              [-39.910944512635524, -8.226914555917583],
              [-39.91094453799959, -8.226914551642002],
              [-39.910269975631387, -8.227140109520121],
              [-39.910269949404217, -8.227140084809767],
              [-39.910269900124931, -8.227140101129329],
              [-39.910269850752762, -8.227140117167796],
              [-39.910269801289296, -8.227140132924662],
              [-39.91026975173618, -8.227140148399453],
              [-39.910269702095015, -8.22714016359156],
              [-39.91026965236744, -8.227140178500475],
              [-39.910269602555083, -8.227140193125786],
              [-39.910269552659528, -8.227140207467015],
              [-39.910269502682496, -8.22714022152371],
              [-39.910269452625535, -8.227140235295343],
              [-39.910269402490322, -8.227140248781399],
              [-39.910269352278505, -8.227140261981683],
              [-39.910269301991718, -8.227140274895513],
              [-39.910269251631611, -8.227140287522628],
              [-39.910269201199824, -8.227140299862493],
              [-39.910269150697999, -8.227140311914791],
              [-39.910269100127799, -8.227140323679071],
              [-39.910269049490886, -8.227140335155026],
              [-39.910268998788915, -8.227140346342143],
              [-39.910268948023543, -8.227140357240252],
              [-39.91026889719641, -8.22714036784881],
              [-39.910268846309208, -8.227140378167544],
              [-39.910268795363585, -8.227140388196181],
              [-39.910268744361218, -8.22714039793426],
              [-39.910268693303777, -8.22714040738161],
              [-39.910268642192925, -8.227140416537758],
              [-39.910268591030324, -8.227140425402595],
              [-39.910268539817672, -8.227140433975697],
              [-39.910268488556639, -8.227140442256797],
              [-39.910268437248874, -8.227140450245644],
              [-39.91026838589611, -8.227140457941939],
              [-39.910268334499968, -8.227140465345512],
              [-39.910268283062159, -8.227140472456078],
              [-39.910268231584389, -8.227140479273377],
              [-39.910268180068307, -8.227140485797193],
              [-39.910268128515604, -8.227140492027397],
              [-39.910268076927949, -8.227140497963637],
              [-39.91026802530709, -8.227140503605748],
              [-39.910267973654676, -8.227140508953724],
              [-39.910267921972405, -8.227140514007225],
              [-39.910267870261933, -8.227140518766085],
              [-39.910267818525007, -8.227140523230254],
              [-39.91026776676329, -8.227140527399465],
              [-39.910267714978488, -8.22714053127368],
              [-39.910267663172277, -8.227140534852662],
              [-39.910267611346363, -8.227140538136501],
              [-39.910267559502451, -8.227140541124767],
              [-39.910267507642224, -8.227140543817612],
              [-39.910267455767389, -8.227140546214937],
              [-39.910267403879637, -8.22714054831652],
              [-39.910267351980664, -8.227140550122472],
              [-39.910267300072164, -8.227140551632507],
              [-39.910267248155861, -8.227140552846819],
              [-39.910267196233427, -8.227140553765219],
              [-39.910267144306552, -8.227140554387692],
              [-39.910267092376976, -8.227140554714246],
              [-39.910267040446371, -8.227140554744858],
              [-39.91026698851644, -8.227140554479648],
              [-39.910266936588876, -8.227140553918392],
              [-39.910266884665376, -8.227140553061302],
              [-39.910266832747645, -8.22714055190824],
              [-39.910266780837411, -8.227140550459341],
              [-39.910266728936321, -8.227140548714763],
              [-39.91026667704611, -8.227140546674306],
              [-39.910266625168461, -8.227140544338265],
              [-39.910266573305066, -8.227140541706623],
              [-39.910266521457643, -8.22714053877946],
              [-39.910266469627842, -8.22714053555687],
              [-39.910266417817425, -8.227140532038954],
              [-39.910266366028047, -8.227140528225926],
              [-39.910266314261413, -8.227140524117759],
              [-39.910266262519222, -8.227140519714679],
              [-39.910266210803137, -8.227140515016796],
              [-39.91026615911489, -8.227140510024253],
              [-39.910266107456138, -8.227140504737267],
              [-39.910266055828593, -8.227140499156008],
              [-39.910266004233954, -8.227140493280618],
              [-39.910265952673882, -8.227140487111217],
              [-39.910265901150076, -8.22714048064825],
              [-39.910265849664228, -8.22714047389163],
              [-39.910265798217992, -8.22714046684176],
              [-39.91026574681311, -8.227140459498875],
              [-39.910265695451223, -8.227140451863114],
              [-39.910265644134, -8.227140443934848],
              [-39.910265592863141, -8.227140435714279],
              [-39.910265541640335, -8.227140427201601],
              [-39.910265490467246, -8.227140418397164],
              [-39.910265439345544, -8.227140409301287],
              [-39.910265388276891, -8.227140399914234],
              [-39.910265337262992, -8.227140390236286],
              [-39.910265286305489, -8.227140380267796],
              [-39.910265235406051, -8.227140370009131],
              [-39.910265184566349, -8.227140359460556],
              [-39.910265133788045, -8.22714034862242],
              [-39.910265083072822, -8.227140337494969],
              [-39.910265032422288, -8.227140326078864],
              [-39.910264981838147, -8.227140314374196],
              [-39.910264931322033, -8.227140302381487],
              [-39.910264880875737, -8.227140290100646],
              [-39.910264892108316, -8.227140301921768],
              [-39.909363434779685, -8.226918132231036],
              [-39.909363438405975, -8.226918123345087],
              [-39.909363416608571, -8.226918118001533],
              [-39.909363394797978, -8.226918112711532],
              [-39.909363372974319, -8.226918107475299],
              [-39.909363351137749, -8.226918102292919],
              [-39.909363329288361, -8.22691809716429],
              [-39.909363307426311, -8.226918092089498],
              [-39.909363285551734, -8.226918087068658],
              [-39.909363263664773, -8.226918082101522],
              [-39.90936324176554, -8.226918077188543],
              [-39.909363219854171, -8.226918072329395],
              [-39.909363197930837, -8.226918067524167],
              [-39.909363175995615, -8.226918062773075],
              [-39.909363154048691, -8.226918058076054],
              [-39.909363132090157, -8.226918053432987],
              [-39.909363110120175, -8.226918048844121],
              [-39.909363088138875, -8.226918044309375],
              [-39.909363066146369, -8.226918039828833],
              [-39.909363044142829, -8.226918035402392],
              [-39.909363022128368, -8.226918031030223],
              [-39.909363000103113, -8.226918026712273],
              [-39.909362978067215, -8.226918022448626],
              [-39.909362956020814, -8.226918018239264],
              [-39.909362933964012, -8.226918014084172],
              [-39.909362911896977, -8.22691800998345],
              [-39.909362889819832, -8.226918005937126],
              [-39.909362867732703, -8.226918001945196],
              [-39.909362845635741, -8.226917998007631],
              [-39.909362823529072, -8.226917994124518],
              [-39.909362801412819, -8.226917990295895],
              [-39.90936277928715, -8.226917986521837],
              [-39.909362757152188, -8.226917982802131],
              [-39.909362735008038, -8.226917979137099],
              [-39.909362712854879, -8.226917975526614],
              [-39.909362690692809, -8.226917971970686],
              [-39.909362668522, -8.22691796846941],
              [-39.909362646342558, -8.226917965022681],
              [-39.909362624154625, -8.226917961630624],
              [-39.90936260195835, -8.226917958293186],
              [-39.909362579753861, -8.226917955010482],
              [-39.909362557541279, -8.226917951782534],
              [-39.909362535320774, -8.226917948609231],
              [-39.909362513092447, -8.226917945490769],
              [-39.909362490856452, -8.226917942427004],
              [-39.909362468612926, -8.226917939418009],
              [-39.909362446361982, -8.226917936463799],
              [-39.909362424103783, -8.226917933564456],
              [-39.909362401838465, -8.226917930719898],
              [-39.90936237956614, -8.226917927930304],
              [-39.909362357286959, -8.226917925195485],
              [-39.909362335001084, -8.22691792251563],
              [-39.909362312708609, -8.226917919890656],
              [-39.909362290409675, -8.226917917320486],
              [-39.909362268104445, -8.22691791480535],
              [-39.909362245793041, -8.226917912345209],
              [-39.90936222347559, -8.22691790993999],
              [-39.909362201152256, -8.226917907589771],
              [-39.909362178823137, -8.226917905294567],
              [-39.909362156488399, -8.226917903054343],
              [-39.909362134148161, -8.226917900869122],
              [-39.909362111802579, -8.226917898738915],
              [-39.909362089451776, -8.226917896663874],
              [-39.909362067095898, -8.226917894643767],
              [-39.909362044735055, -8.226917892678824],
              [-39.909362022369422, -8.226917890768981],
              [-39.909361999999099, -8.226917888914235],
              [-39.909361977624258, -8.226917887114555],
              [-39.909361955244997, -8.226917885370005],
              [-39.909361932861493, -8.226917883680587],
              [-39.909361910473862, -8.226917882046301],
              [-39.909361888082252, -8.226917880467159],
              [-39.909361865686769, -8.226917878943215],
              [-39.909361843287591, -8.226917877474452],
              [-39.909361820884826, -8.226917876060851],
              [-39.909361798478635, -8.226917874702451],
              [-39.909361776069119, -8.226917873399211],
              [-39.909361753656448, -8.226917872151171],
              [-39.909361731240743, -8.226917870958371],
              [-39.909361708822154, -8.22691786982087],
              [-39.909361686400793, -8.226917868738512],
              [-39.909361663976838, -8.226917867711354],
              [-39.909361641550397, -8.226917866739537],
              [-39.909361619121604, -8.226917865822902],
              [-39.909361596690609, -8.226917864961539],
              [-39.909361574257538, -8.226917864155423],
              [-39.909361551822542, -8.226917863404601],
              [-39.909361529385762, -8.226917862708939],
              [-39.909361506947313, -8.226917862068621],
              [-39.909361484507336, -8.22691786148358],
              [-39.909361462065974, -8.226917860953815],
              [-39.909361439623368, -8.226917860479377],
              [-39.909361417179682, -8.22691786006023],
              [-39.909361394734987, -8.226917859696327],
              [-39.909361372289467, -8.226917859387713],
              [-39.909361349843266, -8.226917859134325],
              [-39.909361327396503, -8.22691785893633],
              [-39.909361304949293, -8.226917858793554],
              [-39.909361282501813, -8.226917858706139],
              [-39.90936126005419, -8.226917858674016],
              [-39.909361237606532, -8.226917858697213],
              [-39.909361255332136, -8.226917865830716],
              [-39.908240610772708, -8.226920399885842],
              [-39.908240620371117, -8.22692039269025],
              [-39.90824047633523, -8.226920394154282],
              [-39.908240332340796, -8.226920397894659],
              [-39.90824018842406, -8.226920403910714],
              [-39.908240044621245, -8.226920412200727],
              [-39.908239900968546, -8.226920422762666],
              [-39.908239757502145, -8.226920435593966],
              [-39.908239614258157, -8.226920450691285],
              [-39.908239471272623, -8.226920468050931],
              [-39.908239328581551, -8.226920487668446],
              [-39.908239186220889, -8.226920509538893],
              [-39.908239044226434, -8.226920533656786],
              [-39.90823890263394, -8.226920560016099],
              [-39.908238761479055, -8.226920588610113],
              [-39.908238620797341, -8.22692061943167],
              [-39.908238480624206, -8.22692065247311],
              [-39.908238340994906, -8.226920687725851],
              [-39.908238201944606, -8.226920725181277],
              [-39.908238063508314, -8.226920764829851],
              [-39.908237925720897, -8.226920806661621],
              [-39.908237788617022, -8.226920850666048],
              [-39.908237652231207, -8.226920896832034],
              [-39.908237516597808, -8.226920945148009],
              [-39.908237381750922, -8.226920995601706],
              [-39.908237247724543, -8.226921048180525],
              [-39.908237114552399, -8.22692110287119],
              [-39.908236982267994, -8.226921159659916],
              [-39.90823685090465, -8.226921218532476],
              [-39.908236720495431, -8.226921279473943],
              [-39.908236591073177, -8.226921342469083],
              [-39.908236462670466, -8.226921407501919],
              [-39.908236335319607, -8.226921474556242],
              [-39.908236209052674, -8.226921543614978],
              [-39.908236083901457, -8.226921614660943],
              [-39.908235959897461, -8.226921687676089],
              [-39.908235837071892, -8.226921762642148],
              [-39.908235715455682, -8.22692183954015],
              [-39.908235595079404, -8.226921918350776],
              [-39.908235475973449, -8.226921999054191],
              [-39.90823535816773, -8.226922081630043],
              [-39.908235241691941, -8.226922166057598],
              [-39.908235126575384, -8.226922252315662],
              [-39.908235012847044, -8.226922340382357],
              [-39.90823490053554, -8.226922430235627],
              [-39.908234789669152, -8.226922521852808],
              [-39.908234680275832, -8.226922615210853],
              [-39.908234572383073, -8.226922710286305],
              [-39.908234466018023, -8.226922807055104],
              [-39.908234361207498, -8.226922905492975],
              [-39.908234257977867, -8.226923005575157],
              [-39.908234156355114, -8.226923107276438],
              [-39.90823405636484, -8.226923210571156],
              [-39.908233958032184, -8.22692331543343],
              [-39.908233861381923, -8.226923421836748],
              [-39.908233766438414, -8.226923529754327],
              [-39.90823367322551, -8.226923639159038],
              [-39.908233581766694, -8.226923750023337],
              [-39.908233492084989, -8.226923862319401],
              [-39.908233404202981, -8.226923976018721],
              [-39.908233318142806, -8.226924091092906],
              [-39.908233233926097, -8.226924207512893],
              [-39.908233151574066, -8.226924325249406],
              [-39.908233071107468, -8.226924444272736],
              [-39.908232992546516, -8.226924564553102],
              [-39.908232915911029, -8.22692468605994],
              [-39.908232841220268, -8.226924808762851],
              [-39.908232768493072, -8.226924932630959],
              [-39.908232697747714, -8.226925057632901],
              [-39.908232629002008, -8.226925183737418],
              [-39.908232562273305, -8.226925310912684],
              [-39.908232497578339, -8.226925439126704],
              [-39.908232434933424, -8.226925568347148],
              [-39.908232374354355, -8.226925698541566],
              [-39.908232315856345, -8.226925829676984],
              [-39.908232259454152, -8.226925961720648],
              [-39.908232205161944, -8.226926094639209],
              [-39.908232152993428, -8.226926228399115],
              [-39.908232102961669, -8.226926362966855],
              [-39.908232055079345, -8.226926498308456],
              [-39.908232009358457, -8.226926634389867],
              [-39.908231965810515, -8.226926771176819],
              [-39.908231924446511, -8.226926908634915],
              [-39.908231885276862, -8.226927046729564],
              [-39.908231848311402, -8.226927185425801],
              [-39.908231813559425, -8.226927324689026],
              [-39.90823178102972, -8.226927464484024],
              [-39.908231750730444, -8.226927604775446],
              [-39.908231722669235, -8.22692774552827],
              [-39.908231696853164, -8.226927886706889],
              [-39.908231673288746, -8.226928028275818],
              [-39.908231651981858, -8.226928170199349],
              [-39.908231632937934, -8.22692831244183],
              [-39.908231616161693, -8.226928454967421],
              [-39.908231601657413, -8.226928597740224],
              [-39.908231589428738, -8.226928740724329],
              [-39.908231579478738, -8.226928883883689],
              [-39.908231571809921, -8.226929027182338],
              [-39.908231566424213, -8.226929170584199],
              [-39.908231563322964, -8.226929314053152],
              [-39.908231562506977, -8.22692945755292],
              [-39.908231552908816, -8.226929464748242],
              [-39.908232793278344, -8.227474205733836],
              [-39.908232802876505, -8.227474198538513],
              [-39.908232802060539, -8.227474342038381],
              [-39.908232798959311, -8.227474485507248],
              [-39.90823279357361, -8.227474628909002],
              [-39.908232785904801, -8.227474772207648],
              [-39.908232775954808, -8.227474915367022],
              [-39.908232763726133, -8.227475058351125],
              [-39.908232749221867, -8.227475201123907],
              [-39.908232732445647, -8.227475343649417],
              [-39.908232713401702, -8.227475485891894],
              [-39.908232692094828, -8.227475627815426],
              [-39.908232668530381, -8.227475769384272],
              [-39.908232642714303, -8.227475910562887],
              [-39.908232614653102, -8.227476051315715],
              [-39.908232584353811, -8.227476191607137],
              [-39.908232551824071, -8.227476331402054],
              [-39.908232517072086, -8.227476470665176],
              [-39.908232480106591, -8.227476609361519],
              [-39.908232440936899, -8.22747674745607],
              [-39.908232399572874, -8.22747688491415],
              [-39.908232356024925, -8.227477021701104],
              [-39.908232310304001, -8.227477157782554],
              [-39.908232262421627, -8.227477293124041],
              [-39.908232212389855, -8.227477427691781],
              [-39.908232160221274, -8.227477561451746],
              [-39.908232105929031, -8.227477694370258],
              [-39.908232049526767, -8.227477826413825],
              [-39.908231991028707, -8.227477957549386],
              [-39.908231930449595, -8.227478087743689],
              [-39.908231867804616, -8.227478216964137],
              [-39.908231803109608, -8.227478345178099],
              [-39.908231736380813, -8.22747847235334],
              [-39.90823166763505, -8.227478598457795],
              [-39.908231596889642, -8.227478723459827],
              [-39.90823152416236, -8.227478847327859],
              [-39.908231449471536, -8.227478970030765],
              [-39.908231372835957, -8.227479091537619],
              [-39.908231294274955, -8.227479211817897],
              [-39.908231213808257, -8.227479330841248],
              [-39.908231131456148, -8.227479448577759],
              [-39.908231047239354, -8.227479564997655],
              [-39.908230961179072, -8.227479680071847],
              [-39.908230873296986, -8.227479793771259],
              [-39.908230783615195, -8.227479906067284],
              [-39.908230692156273, -8.227480016931491],
              [-39.908230598943284, -8.227480126336198],
              [-39.908230503999647, -8.227480234253871],
              [-39.908230407349301, -8.227480340657118],
              [-39.908230309016574, -8.227480445519383],
              [-39.908230209026151, -8.227480548814128],
              [-39.908230107403305, -8.227480650515353],
              [-39.908230004173575, -8.227480750597531],
              [-39.908229899362929, -8.227480849035397],
              [-39.908229792997766, -8.22748094580423],
              [-39.908229685104878, -8.227481040879674],
              [-39.908229575711424, -8.227481134237751],
              [-39.908229464844929, -8.227481225854858],
              [-39.908229352533318, -8.227481315708143],
              [-39.908229238804829, -8.227481403774952],
              [-39.908229123688159, -8.227481490032913],
              [-39.908229007212235, -8.227481574460498],
              [-39.908228889406381, -8.22748165703635],
              [-39.908228770300269, -8.22748173773981],
              [-39.908228649923892, -8.227481816550448],
              [-39.908228528307532, -8.227481893448351],
              [-39.908228405481829, -8.227481968414423],
              [-39.908228281477676, -8.227482041429584],
              [-39.908228156326309, -8.227482112475478],
              [-39.90822803005922, -8.227482181534331],
              [-39.908227902708212, -8.227482248588583],
              [-39.908227774305367, -8.227482313621433],
              [-39.908227644882956, -8.227482376616658],
              [-39.908227514473595, -8.227482437558139],
              [-39.908227383110102, -8.227482496430611],
              [-39.908227250825554, -8.227482553219389],
              [-39.908227117653247, -8.227482607910137],
              [-39.90822698362669, -8.227482660488871],
              [-39.908226848779663, -8.227482710942597],
              [-39.908226713146085, -8.227482759258578],
              [-39.908226576760107, -8.227482805424598],
              [-39.908226439656055, -8.227482849429034],
              [-39.908226301868488, -8.227482891260818],
              [-39.908226163432026, -8.227482930909394],
              [-39.90822602438157, -8.227482968364756],
              [-39.908225884752085, -8.227483003617712],
              [-39.908225744578779, -8.227483036659052],
              [-39.908225603896867, -8.227483067480623],
              [-39.908225462741839, -8.227483096074669],
              [-39.908225321149182, -8.227483122433997],
              [-39.908225179154556, -8.227483146551913],
              [-39.908225036793695, -8.227483168422358],
              [-39.908224894102446, -8.227483188039907],
              [-39.908224751116741, -8.227483205399469],
              [-39.908224607872576, -8.227483220496804],
              [-39.908224464405997, -8.227483233328137],
              [-39.908224320753128, -8.227483243890106],
              [-39.908224176950121, -8.227483252180187],
              [-39.9082240330332, -8.227483258196173],
              [-39.908223889038588, -8.227483261936669],
              [-39.908223745001216, -8.227483263401965],
              [-39.908223735403055, -8.227483270597302],
              [-39.907561188607403, -8.227484767381791],
              [-39.907561236068453, -8.227484760101094],
              [-39.907561117561158, -8.22748475959804],
              [-39.907560999070554, -8.227484757554079],
              [-39.907560880616849, -8.227484753969255],
              [-39.907560762220193, -8.22748474884439],
              [-39.90756064390078, -8.227484742180295],
              [-39.907560525678761, -8.227484733978136],
              [-39.90756040757428, -8.227484724239217],
              [-39.907560289607467, -8.22748471296519],
              [-39.907560171798444, -8.227484700158087],
              [-39.907560054167249, -8.2274846858201],
              [-39.907559936733954, -8.227484669953528],
              [-39.907559819518553, -8.227484652561136],
              [-39.907559702541029, -8.227484633645931],
              [-39.907559585821303, -8.227484613211169],
              [-39.907559469379279, -8.227484591260207],
              [-39.907559353234788, -8.227484567796887],
              [-39.907559237407639, -8.227484542825255],
              [-39.907559121917551, -8.227484516349435],
              [-39.907559006784219, -8.227484488373964],
              [-39.907558892027225, -8.2274844589036],
              [-39.907558777666168, -8.227484427943487],
              [-39.907558663720494, -8.227484395498754],
              [-39.907558550209671, -8.227484361575037],
              [-39.907558437152993, -8.227484326178066],
              [-39.907558324569749, -8.227484289313814],
              [-39.90755821247911, -8.227484250988708],
              [-39.907558100900197, -8.227484211209102],
              [-39.907557989852016, -8.227484169981931],
              [-39.907557879353462, -8.227484127314112],
              [-39.90755776942342, -8.22748408321301],
              [-39.907557660080563, -8.227484037686098],
              [-39.907557551343544, -8.227483990741103],
              [-39.907557443230921, -8.227483942386044],
              [-39.907557335761076, -8.227483892629213],
              [-39.907557228952335, -8.227483841478914],
              [-39.907557122822915, -8.227483788944063],
              [-39.907557017390872, -8.227483735033587],
              [-39.907556912674188, -8.227483679756569],
              [-39.907556808690728, -8.227483623122442],
              [-39.907556705458148, -8.227483565141062],
              [-39.907556602994099, -8.227483505821962],
              [-39.907556501316016, -8.227483445175555],
              [-39.907556400441237, -8.227483383211931],
              [-39.907556300386936, -8.227483319941886],
              [-39.907556201170173, -8.227483255376072],
              [-39.907556102807867, -8.227483189525378],
              [-39.907556005316735, -8.22748312240128],
              [-39.907555908713441, -8.227483054015078],
              [-39.907555813014405, -8.227482984378382],
              [-39.907555718235955, -8.227482913503163],
              [-39.907555624394249, -8.227482841401377],
              [-39.907555531505281, -8.227482768085409],
              [-39.907555439584819, -8.227482693567804],
              [-39.907555348648614, -8.227482617861076],
              [-39.907555258712087, -8.227482540978215],
              [-39.907555169790619, -8.227482462932411],
              [-39.907555081899346, -8.22748238373692],
              [-39.907554995053225, -8.227482303405068],
              [-39.907554909267077, -8.227482221950787],
              [-39.907554824555497, -8.227482139387758],
              [-39.907554740932937, -8.227482055730166],
              [-39.907554658413659, -8.227481970992283],
              [-39.907554577011688, -8.227481885188512],
              [-39.907554496740921, -8.227481798333409],
              [-39.907554417615053, -8.227481710441859],
              [-39.907554339647511, -8.227481621528852],
              [-39.90755426285164, -8.227481531609438],
              [-39.907554187240471, -8.227481440699004],
              [-39.907554112826944, -8.227481348813154],
              [-39.907554039623705, -8.227481255967282],
              [-39.907553967643224, -8.227481162177414],
              [-39.907553896897795, -8.22748106745953],
              [-39.90755382739944, -8.227480971829575],
              [-39.907553759160031, -8.227480875304121],
              [-39.907553692191179, -8.227480777899393],
              [-39.907553626504296, -8.227480679632118],
              [-39.907553562110593, -8.227480580518975],
              [-39.907553499021006, -8.227480480576872],
              [-39.907553437246314, -8.22748037982285],
              [-39.907553376797033, -8.227480278274019],
              [-39.90755331768348, -8.227480175947795],
              [-39.907553259915701, -8.227480072861601],
              [-39.907553203503575, -8.227479969032908],
              [-39.907553148456664, -8.227479864479447],
              [-39.907553094784411, -8.227479759218967],
              [-39.907553042495898, -8.227479653269516],
              [-39.90755299160007, -8.227479546649191],
              [-39.907552942105617, -8.227479439376051],
              [-39.907552894020924, -8.227479331468425],
              [-39.907552847354218, -8.227479222944574],
              [-39.907552802113457, -8.227479113823193],
              [-39.907552758306331, -8.227479004122799],
              [-39.907552715940305, -8.227478893861967],
              [-39.907552675022608, -8.227478783059672],
              [-39.907552635560208, -8.227478671734618],
              [-39.907552597559835, -8.227478559905904],
              [-39.907552561027956, -8.227478447592638],
              [-39.907552525970807, -8.227478334813812],
              [-39.907552492394245, -8.227478221588713],
              [-39.90755245825185, -8.227478184689559],
              [-39.907327815156549, -8.226702049719863],
              [-39.907327849510125, -8.226702080215057],
              [-39.907327823284497, -8.226701991318292],
              [-39.907327796146078, -8.226701902693913],
              [-39.907327768097772, -8.226701814351081],
              [-39.907327739142488, -8.226701726299273],
              [-39.907327709283265, -8.226701638547491],
              [-39.907327678523259, -8.226701551105082],
              [-39.907327646865681, -8.226701463981248],
              [-39.907327614313836, -8.226701377185107],
              [-39.907327580871176, -8.226701290725634],
              [-39.907327546541175, -8.226701204612132],
              [-39.907327511327466, -8.226701118853425],
              [-39.907327475233728, -8.226701033458664],
              [-39.907327438263749, -8.226700948436793],
              [-39.907327400421423, -8.226700863796575],
              [-39.907327361710728, -8.226700779547132],
              [-39.9073273221357, -8.226700695697149],
              [-39.907327281700503, -8.226700612255577],
              [-39.907327240409387, -8.226700529230936],
              [-39.907327198266678, -8.226700446632071],
              [-39.907327155276811, -8.226700364467689],
              [-39.907327111444282, -8.22670028274641],
              [-39.907327066773703, -8.226700201476712],
              [-39.907327021269751, -8.226700120667248],
              [-39.90732697493722, -8.226700040326367],
              [-39.90732692778095, -8.226699960462687],
              [-39.907326879805915, -8.226699881084368],
              [-39.907326831017123, -8.226699802199915],
              [-39.907326781419712, -8.226699723817545],
              [-39.907326731018905, -8.22669964594543],
              [-39.907326679819953, -8.226699568591835],
              [-39.90732662782824, -8.226699491764894],
              [-39.90732657504924, -8.226699415472494],
              [-39.907326521488493, -8.226699339722819],
              [-39.907326467151584, -8.226699264523804],
              [-39.907326412044263, -8.226699189883217],
              [-39.907326356172284, -8.22669911580898],
              [-39.907326299541523, -8.226699042308971],
              [-39.907326242157907, -8.22669896939068],
              [-39.90732618402749, -8.226698897061947],
              [-39.907326125156345, -8.226698825330276],
              [-39.90732606555067, -8.226698754203175],
              [-39.907326005216696, -8.226698683688204],
              [-39.907325944160782, -8.226698613792673],
              [-39.90732588238933, -8.226698544523922],
              [-39.907325819908799, -8.226698475889329],
              [-39.907325756725768, -8.226698407895924],
              [-39.907325692846882, -8.226698340550964],
              [-39.907325628278805, -8.226698273861535],
              [-39.907325563028351, -8.226698207834534],
              [-39.907325497102349, -8.226698142476986],
              [-39.90732543050774, -8.226698077795646],
              [-39.907325363251488, -8.226698013797472],
              [-39.907325295340662, -8.226697950488969],
              [-39.907325226782362, -8.226697887877],
              [-39.907325157583827, -8.226697825967943],
              [-39.907325087752312, -8.226697764768502],
              [-39.907325017295122, -8.226697704284867],
              [-39.907324946219688, -8.226697644523451],
              [-39.90732487453343, -8.226697585490591],
              [-39.907324802243892, -8.226697527192616],
              [-39.907324729358656, -8.226697469635329],
              [-39.907324655885375, -8.226697412825008],
              [-39.907324581831773, -8.226697356767483],
              [-39.907324507205587, -8.22669730146872],
              [-39.907324432014683, -8.226697246934473],
              [-39.907324356266933, -8.226697193170541],
              [-39.907324279970297, -8.226697140182496],
              [-39.907324203132795, -8.226697087975914],
              [-39.90732412576245, -8.226697036556322],
              [-39.907324047867434, -8.226696985929024],
              [-39.907323969455867, -8.226696936099437],
              [-39.907323890536034, -8.226696887072674],
              [-39.907323811116186, -8.226696838853979],
              [-39.907323731204663, -8.226696791448314],
              [-39.907323650809836, -8.226696744860803],
              [-39.907323569940175, -8.226696699096236],
              [-39.907323488604156, -8.226696654159442],
              [-39.907323406810306, -8.226696610055056],
              [-39.90732332456723, -8.226696566787837],
              [-39.907323241883532, -8.226696524362284],
              [-39.907323158767888, -8.226696482782707],
              [-39.90732307522908, -8.226696442053738],
              [-39.907322991275798, -8.226696402179476],
              [-39.907322906916896, -8.226696363164072],
              [-39.907322822161227, -8.226696325011781],
              [-39.907322737017665, -8.226696287726435],
              [-39.907322651495171, -8.226696251312021],
              [-39.907322565602705, -8.226696215772396],
              [-39.907322479349283, -8.226696181111249],
              [-39.907322392743957, -8.226696147332175],
              [-39.907322305795837, -8.226696114438877],
              [-39.907322218514018, -8.226696082434682],
              [-39.90732213090768, -8.226696051322969],
              [-39.907322042986003, -8.226696021106983],
              [-39.907321954758224, -8.226695991789907],
              [-39.907321866233616, -8.226695963374855],
              [-39.907321777421465, -8.226695935864729],
              [-39.907321688331066, -8.226695909262533],
              [-39.907321598971791, -8.226695883570905],
              [-39.907321627818632, -8.226695913499046],
              [-39.906536378875188, -8.226474469710741],
              [-39.906536389087385, -8.226474462284289],
              [-39.906536351155189, -8.226474451498978],
              [-39.906536313270408, -8.22647444054968],
              [-39.906536275433723, -8.226474429436392],
              [-39.906536237645881, -8.226474418159446],
              [-39.906536199907585, -8.226474406719063],
              [-39.906536162219538, -8.22647439511541],
              [-39.906536124582438, -8.226474383348688],
              [-39.906536086997036, -8.226474371419192],
              [-39.906536049464002, -8.226474359327151],
              [-39.90653601198408, -8.226474347072655],
              [-39.90653597455794, -8.226474334656141],
              [-39.90653593718632, -8.226474322077715],
              [-39.906535899869915, -8.226474309337602],
              [-39.906535862609402, -8.226474296436047],
              [-39.906535825405548, -8.226474283373342],
              [-39.906535788259006, -8.226474270149694],
              [-39.90653575117048, -8.226474256765403],
              [-39.906535714140674, -8.226474243220702],
              [-39.906535677170304, -8.226474229515775],
              [-39.906535640260053, -8.226474215651004],
              [-39.906535603410624, -8.226474201626633],
              [-39.906535566622715, -8.226474187442854],
              [-39.906535529896985, -8.226474173099943],
              [-39.906535493234188, -8.226474158598158],
              [-39.906535456634991, -8.226474143937857],
              [-39.906535420100063, -8.226474129119222],
              [-39.906535383630107, -8.22647411414254],
              [-39.906535347225827, -8.226474099008145],
              [-39.906535310887897, -8.226474083716324],
              [-39.906535274617006, -8.226474068267455],
              [-39.906535238413824, -8.226474052661585],
              [-39.906535202279052, -8.226474036899155],
              [-39.906535166213381, -8.226474020980502],
              [-39.906535130217478, -8.226474004905828],
              [-39.906535094292025, -8.226473988675536],
              [-39.906535058437683, -8.226473972289982],
              [-39.906535022655163, -8.226473955749242],
              [-39.906534986945104, -8.226473939053772],
              [-39.906534951308224, -8.22647392220391],
              [-39.906534915745141, -8.226473905199972],
              [-39.906534880256565, -8.226473888042193],
              [-39.906534844843165, -8.226473870731024],
              [-39.906534809505601, -8.226473853266672],
              [-39.906534774244541, -8.226473835649548],
              [-39.906534739060639, -8.226473817879894],
              [-39.906534703954591, -8.226473799958159],
              [-39.906534668927023, -8.226473781884595],
              [-39.906534633978609, -8.226473763659621],
              [-39.906534599110024, -8.226473745283522],
              [-39.906534564321923, -8.226473726756655],
              [-39.90653452961493, -8.226473708079283],
              [-39.906534494989749, -8.226473689251961],
              [-39.906534460446998, -8.226473670274874],
              [-39.906534425987353, -8.22647365114851],
              [-39.906534391611437, -8.226473631873114],
              [-39.906534357319927, -8.226473612449013],
              [-39.906534323113441, -8.22647359287676],
              [-39.906534288992667, -8.226473573156598],
              [-39.906534254958203, -8.226473553288839],
              [-39.906534221010709, -8.226473533274083],
              [-39.906534187150847, -8.226473513112534],
              [-39.906534153379233, -8.226473492804525],
              [-39.906534119696495, -8.226473472350527],
              [-39.906534086103285, -8.226473451750973],
              [-39.906534052600264, -8.22647343100625],
              [-39.906534019188015, -8.226473410116615],
              [-39.906533985867185, -8.226473389082486],
              [-39.906533952638448, -8.226473367904378],
              [-39.906533919502351, -8.226473346582697],
              [-39.906533886459584, -8.226473325117716],
              [-39.90653385351073, -8.2264733035099],
              [-39.906533820656442, -8.226473281759686],
              [-39.906533787897324, -8.226473259867378],
              [-39.906533755234001, -8.226473237833542],
              [-39.906533722667078, -8.226473215658523],
              [-39.906533690197165, -8.226473193342716],
              [-39.906533657824909, -8.226473170886591],
              [-39.906533625550892, -8.226473148290507],
              [-39.906533593375734, -8.226473125555014],
              [-39.906533561300044, -8.226473102680364],
              [-39.90653352932442, -8.226473079667146],
              [-39.90653349744948, -8.226473056515641],
              [-39.9065334656758, -8.226473033226444],
              [-39.906533434004004, -8.226473009799905],
              [-39.906533402434675, -8.226472986236574],
              [-39.906533370968418, -8.226472962536711],
              [-39.906533339605829, -8.226472938700857],
              [-39.906533308347477, -8.226472914729552],
              [-39.906533277193986, -8.226472890623064],
              [-39.906533246145933, -8.226472866381974],
              [-39.906533215203872, -8.226472842006732],
              [-39.906533184368442, -8.22647281749779],
              [-39.90653315364019, -8.226472792855555],
              [-39.906533123019713, -8.22647276808058],
              [-39.906533092507573, -8.226472743173298],
              [-39.906533062104344, -8.2264727181342],
              [-39.906533031810618, -8.226472692963609],
              [-39.906533001626961, -8.226472667662259],
              [-39.906532971554057, -8.226472642229696],
              [-39.906532924953041, -8.226472668621513],
              [-39.905741933117618, -8.225800734598417],
              [-39.905741940039157, -8.225800737688107],
              [-39.905741929083455, -8.2258007283972],
              [-39.905741918112966, -8.225800719123585],
              [-39.905741907127762, -8.225800709867285],
              [-39.905741896127829, -8.225800700628382],
              [-39.905741885113216, -8.225800691406782],
              [-39.905741874083951, -8.225800682202685],
              [-39.905741863040063, -8.225800673015991],
              [-39.905741851981581, -8.225800663846664],
              [-39.905741840908519, -8.225800654694853],
              [-39.905741829820897, -8.225800645560616],
              [-39.905741818718781, -8.225800636443765],
              [-39.905741807602169, -8.225800627344615],
              [-39.905741796471098, -8.225800618262916],
              [-39.905741785325588, -8.225800609198807],
              [-39.905741774165669, -8.225800600152267],
              [-39.905741762991376, -8.225800591123416],
              [-39.905741751802729, -8.225800582112214],
              [-39.90574174059978, -8.2258005731187],
              [-39.905741729382534, -8.225800564142961],
              [-39.905741718151027, -8.225800555184753],
              [-39.905741706905268, -8.22580054624442],
              [-39.905741695645297, -8.225800537321842],
              [-39.905741684371165, -8.225800528417018],
              [-39.905741673082865, -8.225800519530065],
              [-39.905741661780461, -8.225800510660854],
              [-39.905741650463952, -8.225800501809578],
              [-39.905741639133375, -8.225800492976159],
              [-39.905741627788764, -8.225800484160578],
              [-39.905741616430149, -8.225800475363053],
              [-39.905741605057557, -8.225800466583349],
              [-39.905741593670989, -8.225800457821689],
              [-39.905741582270515, -8.225800449077981],
              [-39.905741570856144, -8.225800440352264],
              [-39.905741559427902, -8.225800431644608],
              [-39.905741547985812, -8.225800422954986],
              [-39.905741536529938, -8.225800414283455],
              [-39.905741525060279, -8.22580040563005],
              [-39.905741513576864, -8.225800396994684],
              [-39.905741502079728, -8.225800388377543],
              [-39.905741490568886, -8.225800379778596],
              [-39.905741479044394, -8.225800371197771],
              [-39.905741467506253, -8.225800362635139],
              [-39.905741455954534, -8.225800354090763],
              [-39.905741444389214, -8.22580034556456],
              [-39.905741432810345, -8.225800337056736],
              [-39.905741421217954, -8.225800328567136],
              [-39.905741409612084, -8.225800320095846],
              [-39.905741397992735, -8.225800311642947],
              [-39.905741386359963, -8.225800303208423],
              [-39.905741374713806, -8.225800294792194],
              [-39.905741363054254, -8.225800286394371],
              [-39.905741351381373, -8.225800278014944],
              [-39.905741339695162, -8.225800269653975],
              [-39.905741327995663, -8.225800261311603],
              [-39.905741316282935, -8.225800252987517],
              [-39.905741304556962, -8.225800244682098],
              [-39.905741292817787, -8.225800236395139],
              [-39.905741281065445, -8.225800228126689],
              [-39.90574126929998, -8.225800219876797],
              [-39.905741257521385, -8.225800211645556],
              [-39.905741245729729, -8.225800203432902],
              [-39.905741233924999, -8.225800195238895],
              [-39.905741222107274, -8.22580018706347],
              [-39.905741210276553, -8.225800178906786],
              [-39.905741198432878, -8.225800170768682],
              [-39.90574118657625, -8.225800162649403],
              [-39.905741174706719, -8.225800154548821],
              [-39.905741162824334, -8.225800146466934],
              [-39.905741150929117, -8.22580013840383],
              [-39.90574113902106, -8.225800130359586],
              [-39.905741127100242, -8.225800122334155],
              [-39.90574111516667, -8.225800114327486],
              [-39.905741103220379, -8.225800106339744],
              [-39.905741091261376, -8.225800098370733],
              [-39.905741079289733, -8.225800090420771],
              [-39.905741067305456, -8.225800082489638],
              [-39.905741055308567, -8.225800074577453],
              [-39.905741043299116, -8.225800066684263],
              [-39.905741031277117, -8.225800058809934],
              [-39.905741019242598, -8.225800050954708],
              [-39.905741007195623, -8.225800043118406],
              [-39.905740995136178, -8.225800035301241],
              [-39.905740983064327, -8.225800027503006],
              [-39.905740970980077, -8.225800019723934],
              [-39.905740958883456, -8.22580001196391],
              [-39.905740946774529, -8.225800004222986],
              [-39.905740934653309, -8.225799996501193],
              [-39.905740922519797, -8.225799988798578],
              [-39.905740910374043, -8.225799981115111],
              [-39.905740898216109, -8.225799973450798],
              [-39.905740886045969, -8.225799965805713],
              [-39.905740873863699, -8.225799958179914],
              [-39.905740861669308, -8.225799950573307],
              [-39.905740849462845, -8.225799942985923],
              [-39.905740837244316, -8.225799935417836],
              [-39.905740825013766, -8.225799927869096],
              [-39.905740812771228, -8.225799920339693],
              [-39.90574080051671, -8.225799912829538],
              [-39.90574078825027, -8.225799905338786],
              [-39.905740751072649, -8.225799923266004],
              [-39.904273637892686, -8.224905569455121],
              [-39.904273679339475, -8.224905573074233],
              [-39.904273599722082, -8.224905525097084],
              [-39.904273519615302, -8.224905477935339],
              [-39.904273439027527, -8.22490543159412],
              [-39.904273357967227, -8.224905386078177],
              [-39.904273276442936, -8.224905341392292],
              [-39.904273194463187, -8.224905297541229],
              [-39.90427311203662, -8.224905254529586],
              [-39.904273029171861, -8.224905212361755],
              [-39.904272945877651, -8.224905171042368],
              [-39.904272862162735, -8.224905130575534],
              [-39.904272778035903, -8.224905090965693],
              [-39.904272693505966, -8.224905052216938],
              [-39.904272608581834, -8.224905014333363],
              [-39.904272523272432, -8.224904977318833],
              [-39.904272437586691, -8.224904941177376],
              [-39.904272351533642, -8.22490490591281],
              [-39.904272265122302, -8.224904871528668],
              [-39.904272178361772, -8.224904838028779],
              [-39.904272091261149, -8.224904805416463],
              [-39.90427200382959, -8.224904773695215],
              [-39.904271916076276, -8.224904742868457],
              [-39.904271828010437, -8.224904712939326],
              [-39.904271739641302, -8.224904683910964],
              [-39.904271650978167, -8.224904655786437],
              [-39.904271562030374, -8.224904628568803],
              [-39.904271472807196, -8.224904602260757],
              [-39.904271383318104, -8.224904576865155],
              [-39.9042712935724, -8.224904552384592],
              [-39.904271203579576, -8.224904528821789],
              [-39.904271113349068, -8.224904506179167],
              [-39.904271022890335, -8.224904484458913],
              [-39.904270932212931, -8.224904463663515],
              [-39.904270841326323, -8.224904443795175],
              [-39.904270750240087, -8.224904424855778],
              [-39.904270658963796, -8.224904406847472],
              [-39.904270567507027, -8.224904389772245],
              [-39.904270475879407, -8.224904373631652],
              [-39.904270384090523, -8.224904358427544],
              [-39.904270292150059, -8.224904344161452],
              [-39.90427020006765, -8.224904330834905],
              [-39.904270107852987, -8.224904318449308],
              [-39.904270015515735, -8.224904307006007],
              [-39.904269923065605, -8.224904296506054],
              [-39.904269830512298, -8.224904286950681],
              [-39.904269737865576, -8.224904278340837],
              [-39.904269645135123, -8.224904270677417],
              [-39.904269552330717, -8.224904263961353],
              [-39.904269459462057, -8.224904258193215],
              [-39.904269366538955, -8.224904253373584],
              [-39.904269273571146, -8.224904249503185],
              [-39.904269180568413, -8.224904246582122],
              [-39.904269087540513, -8.224904244610876],
              [-39.904268994497208, -8.224904243589698],
              [-39.904268901448276, -8.224904243518559],
              [-39.904268808403515, -8.224904244397511],
              [-39.90426871537268, -8.224904246226542],
              [-39.904268622365556, -8.224904249005354],
              [-39.904268529391899, -8.224904252733758],
              [-39.904268436461471, -8.224904257411261],
              [-39.904268343584071, -8.224904263037422],
              [-39.904268250769412, -8.224904269611624],
              [-39.904268158027257, -8.224904277133261],
              [-39.904268065367383, -8.224904285601466],
              [-39.90426797279946, -8.224904295015341],
              [-39.904267880333286, -8.224904305373851],
              [-39.904267787978505, -8.224904316676037],
              [-39.904267695744878, -8.224904328920678],
              [-39.904267603642047, -8.224904342106436],
              [-39.904267511679706, -8.224904356231979],
              [-39.904267419867523, -8.224904371295763],
              [-39.904267328215141, -8.224904387296208],
              [-39.904267236732181, -8.224904404231648],
              [-39.904267145428257, -8.224904422100407],
              [-39.90426705431296, -8.224904440900417],
              [-39.904266963395862, -8.22490446062983],
              [-39.904266872686527, -8.224904481286634],
              [-39.904266782194462, -8.224904502868544],
              [-39.90426669192918, -8.224904525373208],
              [-39.904266601900162, -8.22490454879849],
              [-39.904266512116862, -8.224904573141789],
              [-39.904266422588741, -8.224904598400521],
              [-39.904266333325168, -8.224904624572108],
              [-39.904266244335531, -8.224904651653794],
              [-39.904266155629188, -8.224904679642702],
              [-39.904266067215467, -8.224904708535943],
              [-39.904265979103613, -8.22490473833046],
              [-39.904265891302941, -8.224904769023071],
              [-39.904265803822618, -8.224904800610583],
              [-39.904265716671851, -8.224904833089681],
              [-39.904265629859829, -8.2249048664569],
              [-39.904265543395645, -8.22490490070887],
              [-39.904265457288368, -8.224904935841966],
              [-39.904265371547076, -8.224904971852341],
              [-39.904265286180753, -8.224905008736286],
              [-39.904265201198342, -8.224905046490081],
              [-39.904265116608848, -8.224905085109606],
              [-39.904265032421101, -8.22490512459078],
              [-39.904264948643927, -8.224905164929439],
              [-39.904264865286187, -8.224905206121592],
              [-39.904264831004177, -8.224905227489517],
              [-39.903822575470386, -8.225126543761469],
              [-39.903822613048845, -8.225126569833174],
              [-39.903822522558769, -8.225126615759988],
              [-39.903822432590054, -8.22512666269254],
              [-39.903822343153948, -8.225126710624661],
              [-39.903822254261627, -8.225126759550527],
              [-39.90382216592424, -8.225126809464044],
              [-39.903822078152849, -8.225126860358797],
              [-39.903821990958406, -8.225126912228568],
              [-39.903821904351837, -8.225126965066867],
              [-39.903821818343985, -8.225127018867033],
              [-39.903821732945609, -8.225127073622268],
              [-39.90382164816738, -8.225127129325777],
              [-39.903821564019928, -8.225127185970663],
              [-39.903821480513777, -8.225127243549725],
              [-39.903821397659378, -8.22512730205583],
              [-39.903821315467106, -8.225127361481711],
              [-39.90382123394722, -8.225127421819712],
              [-39.903821153109952, -8.225127483062556],
              [-39.903821072965393, -8.225127545202257],
              [-39.903820993523588, -8.225127608231373],
              [-39.90382091479448, -8.225127672141889],
              [-39.903820836787908, -8.225127736925725],
              [-39.903820759513636, -8.225127802574812],
              [-39.903820682981348, -8.22512786908101],
              [-39.903820607200622, -8.225127936435946],
              [-39.903820532180895, -8.225128004631287],
              [-39.903820457931609, -8.225128073658261],
              [-39.903820384462058, -8.2251281435084],
              [-39.903820311781374, -8.225128214173044],
              [-39.903820239898714, -8.225128285643189],
              [-39.903820168823053, -8.225128357909925],
              [-39.903820098563287, -8.225128430964256],
              [-39.903820029128191, -8.225128504796968],
              [-39.903819960526484, -8.225128579398939],
              [-39.903819892766712, -8.225128654760725],
              [-39.903819825857404, -8.225128730872918],
              [-39.903819759806893, -8.225128807725987],
              [-39.903819694623465, -8.22512888531045],
              [-39.903819630315255, -8.22512896361636],
              [-39.903819566890348, -8.225129042634109],
              [-39.903819504356633, -8.225129122353744],
              [-39.903819442721975, -8.225129202765247],
              [-39.903819381994069, -8.225129283858646],
              [-39.903819322180517, -8.225129365623724],
              [-39.903819263288796, -8.225129448050298],
              [-39.9038192053263, -8.225129531127969],
              [-39.903819148300258, -8.225129614846393],
              [-39.903819092217809, -8.2251296991952],
              [-39.903819037085988, -8.225129784163666],
              [-39.903818982911659, -8.225129869741174],
              [-39.903818929701643, -8.225129955917154],
              [-39.903818877462584, -8.225130042680643],
              [-39.903818826200997, -8.225130130020963],
              [-39.903818775923334, -8.225130217927067],
              [-39.903818726635862, -8.225130306387973],
              [-39.903818678344741, -8.225130395392565],
              [-39.903818631056033, -8.225130484929878],
              [-39.903818584775671, -8.22513057498848],
              [-39.903818539509409, -8.225130665557284],
              [-39.903818495262918, -8.225130756624823],
              [-39.903818452041762, -8.225130848179838],
              [-39.903818409851318, -8.225130940210786],
              [-39.903818368696896, -8.225131032706104],
              [-39.903818328583618, -8.225131125654272],
              [-39.903818289516522, -8.225131219043719],
              [-39.903818251500489, -8.225131312862683],
              [-39.90381821454028, -8.225131407099456],
              [-39.90381817864052, -8.225131501742233],
              [-39.903818143805694, -8.225131596779066],
              [-39.90381811004017, -8.225131692198284],
              [-39.903818077348163, -8.225131787987779],
              [-39.903818045733772, -8.225131884135555],
              [-39.903818015200969, -8.225131980629735],
              [-39.903817985753548, -8.225132077458136],
              [-39.903817957395184, -8.225132174608548],
              [-39.90381793012947, -8.225132272069043],
              [-39.903817903959784, -8.225132369827209],
              [-39.903817878889406, -8.225132467870974],
              [-39.903817854921499, -8.22513256618794],
              [-39.903817832059019, -8.225132664765892],
              [-39.903817810304858, -8.22513276359242],
              [-39.903817789661723, -8.2251328626553],
              [-39.903817770132214, -8.225132961941972],
              [-39.903817751718741, -8.225133061440033],
              [-39.903817734423676, -8.225133161137098],
              [-39.903817718249087, -8.225133261020742],
              [-39.903817703197085, -8.225133361078349],
              [-39.903817689269509, -8.225133461297377],
              [-39.903817676468101, -8.22513356166537],
              [-39.903817664794481, -8.225133662169831],
              [-39.903817654250098, -8.225133762798032],
              [-39.90381764483628, -8.225133863537453],
              [-39.903817636554201, -8.225133964375445],
              [-39.903817629404891, -8.225134065299537],
              [-39.903817623389259, -8.225134166296865],
              [-39.90381761850805, -8.225134267354958],
              [-39.90381761476187, -8.225134368461138],
              [-39.903817612151194, -8.225134469602772],
              [-39.903817610676349, -8.225134570767153],
              [-39.903817610337505, -8.225134671941648],
              [-39.903817600775014, -8.225134694910503],
              [-39.903818863325014, -8.225692008270634],
              [-39.903821404060132, -8.226813418212155],
              [-39.903821413641374, -8.226813403511771],
              [-39.903821414199037, -8.226813485401298],
              [-39.903821415500957, -8.226813567282457],
              [-39.903821417546993, -8.226813649148454],
              [-39.903821420336975, -8.226813730992696],
              [-39.903821423870674, -8.226813812808411],
              [-39.903821428147801, -8.226813894588911],
              [-39.903821433168034, -8.226813976327477],
              [-39.903821438930919, -8.226814058017355],
              [-39.903821445436002, -8.226814139651925],
              [-39.903821452682763, -8.226814221224501],
              [-39.903821460670585, -8.226814302728394],
              [-39.903821469398828, -8.226814384156816],
              [-39.903821478866767, -8.226814465503169],
              [-39.903821489073628, -8.226814546760762],
              [-39.90382150001858, -8.226814627923082],
              [-39.903821511700734, -8.226814708983273],
              [-39.903821524119103, -8.226814789934727],
              [-39.903821537272698, -8.226814870770918],
              [-39.903821551160434, -8.226814951485071],
              [-39.903821565781158, -8.226815032070691],
              [-39.903821581133691, -8.22681511252109],
              [-39.903821597216734, -8.226815192829736],
              [-39.903821614029042, -8.226815272990054],
              [-39.903821631569166, -8.226815352995418],
              [-39.903821649835699, -8.226815432839244],
              [-39.903821668827149, -8.226815512515044],
              [-39.903821688541932, -8.226815592016274],
              [-39.903821708978469, -8.226815671336436],
              [-39.903821730135057, -8.226815750469058],
              [-39.903821752009961, -8.226815829407471],
              [-39.903821774601418, -8.226815908145388],
              [-39.903821797907554, -8.22681598667622],
              [-39.903821821926442, -8.226816064993725],
              [-39.903821846656136, -8.226816143091302],
              [-39.903821872094596, -8.226816220962618],
              [-39.903821898239734, -8.226816298601264],
              [-39.903821925089446, -8.226816376000908],
              [-39.903821952641479, -8.226816453155152],
              [-39.903821980893596, -8.226816530057727],
              [-39.903822009843488, -8.226816606702323],
              [-39.903822039488773, -8.226816683082719],
              [-39.903822069827022, -8.226816759192486],
              [-39.903822100855727, -8.22681683502546],
              [-39.90382213257238, -8.226816910575456],
              [-39.903822164974393, -8.226816985836168],
              [-39.903822198059046, -8.226817060801617],
              [-39.903822231823668, -8.226817135465613],
              [-39.903822266265479, -8.226817209821956],
              [-39.90382230138168, -8.226817283864447],
              [-39.903822337169387, -8.226817357587267],
              [-39.903822373625616, -8.226817430984253],
              [-39.903822410747445, -8.226817504049354],
              [-39.903822448531763, -8.22681757677659],
              [-39.90382248697555, -8.226817649160125],
              [-39.903822526075608, -8.226817721193779],
              [-39.903822565828726, -8.226817792871872],
              [-39.903822606231643, -8.226817864188462],
              [-39.903822647281082, -8.226817935137573],
              [-39.903822688973662, -8.226818005713589],
              [-39.903822731305944, -8.226818075910575],
              [-39.903822774274481, -8.226818145722817],
              [-39.903822817875735, -8.226818215144734],
              [-39.903822862106146, -8.226818284170378],
              [-39.903822906962063, -8.226818352794291],
              [-39.90382295243986, -8.226818421010826],
              [-39.903822998535752, -8.22681848881424],
              [-39.903823045245986, -8.226818556199085],
              [-39.903823092566718, -8.226818623159918],
              [-39.903823140494097, -8.226818689691095],
              [-39.90382318902418, -8.226818755787193],
              [-39.903823238152967, -8.226818821442887],
              [-39.903823287876456, -8.226818886652737],
              [-39.903823338190577, -8.226818951411307],
              [-39.903823389091166, -8.226819015713433],
              [-39.903823440574094, -8.226819079553803],
              [-39.903823492635127, -8.226819142927186],
              [-39.903823545269979, -8.226819205828336],
              [-39.903823598474339, -8.226819268252108],
              [-39.903823652243865, -8.226819330193356],
              [-39.903823706574144, -8.226819391647121],
              [-39.903823761460707, -8.226819452608343],
              [-39.903823816899063, -8.226819513071892],
              [-39.903823872884665, -8.226819573032879],
              [-39.903823929412908, -8.226819632486507],
              [-39.903823986479217, -8.226819691427737],
              [-39.903824044078839, -8.226819749851682],
              [-39.903824102207082, -8.226819807753774],
              [-39.903824160859195, -8.226819865129238],
              [-39.903824220030359, -8.226819921973121],
              [-39.903824279715721, -8.226819978280989],
              [-39.903824339910379, -8.226820034048203],
              [-39.903824400609416, -8.226820089270195],
              [-39.903824461807844, -8.226820143942325],
              [-39.903824523500646, -8.226820198060306],
              [-39.903824585682756, -8.22682025161947],
              [-39.903824648349108, -8.226820304615547],
              [-39.903824711494529, -8.226820357044204],
              [-39.903824775113868, -8.226820408901069],
              [-39.903824839201889, -8.226820460182047],
              [-39.903824869861673, -8.226820465053128],
              [-39.904387295980115, -8.227266355104442],
              [-39.90438729788896, -8.227266360165785],
              [-39.904387309053661, -8.227266369002514],
              [-39.904387320232267, -8.227266377821758],
              [-39.904387331424779, -8.227266386623498],
              [-39.904387342631154, -8.227266395407772],
              [-39.904387353851355, -8.227266404174461],
              [-39.904387365085384, -8.227266412923566],
              [-39.904387376333169, -8.2272664216551],
              [-39.904387387594724, -8.227266430369053],
              [-39.904387398869993, -8.227266439065351],
              [-39.904387410158961, -8.227266447744046],
              [-39.9043874214616, -8.227266456405028],
              [-39.904387432777888, -8.227266465048285],
              [-39.904387444107776, -8.227266473673824],
              [-39.90438745545125, -8.227266482281593],
              [-39.904387466808295, -8.227266490871692],
              [-39.904387478178847, -8.227266499443973],
              [-39.904387489562907, -8.227266507998445],
              [-39.904387500960453, -8.227266516535002],
              [-39.904387512371436, -8.227266525053803],
              [-39.904387523795833, -8.227266533554769],
              [-39.904387535233617, -8.227266542037883],
              [-39.90438754668476, -8.227266550502957],
              [-39.904387558149246, -8.227266558950205],
              [-39.904387569627033, -8.227266567379491],
              [-39.90438758111808, -8.227266575790749],
              [-39.904387592622406, -8.227266584184004],
              [-39.904387604139906, -8.227266592559289],
              [-39.904387615670636, -8.227266600916566],
              [-39.904387627214504, -8.227266609255738],
              [-39.90438763877151, -8.22726661757684],
              [-39.90438765034164, -8.227266625879855],
              [-39.904387661924822, -8.227266634164826],
              [-39.904387673521057, -8.227266642431607],
              [-39.90438768513031, -8.227266650680253],
              [-39.904387696752558, -8.227266658910606],
              [-39.90438770838778, -8.227266667122938],
              [-39.904387720035913, -8.227266675316983],
              [-39.90438773169695, -8.227266683492781],
              [-39.904387743370869, -8.227266691650328],
              [-39.904387755057662, -8.227266699789531],
              [-39.904387766757246, -8.227266707910548],
              [-39.904387778469619, -8.227266716013171],
              [-39.904387790194747, -8.227266724097502],
              [-39.904387801932621, -8.227266732163448],
              [-39.904387813683186, -8.227266740210997],
              [-39.904387825446435, -8.227266748240154],
              [-39.904387837222309, -8.227266756250957],
              [-39.904387849010831, -8.227266764243337],
              [-39.904387860811909, -8.227266772217222],
              [-39.904387872625563, -8.227266780172563],
              [-39.904387884451729, -8.227266788109514],
              [-39.904387896290395, -8.227266796027807],
              [-39.904387908141537, -8.227266803927707],
              [-39.904387920005135, -8.227266811809033],
              [-39.904387931881125, -8.227266819671714],
              [-39.904387943769493, -8.227266827515837],
              [-39.904387955670224, -8.227266835341354],
              [-39.904387967583283, -8.227266843148222],
              [-39.904387979508613, -8.227266850936466],
              [-39.904387991446235, -8.2272668587061],
              [-39.904388003396058, -8.227266866456906],
              [-39.904388015358116, -8.227266874189139],
              [-39.904388027332331, -8.227266881902546],
              [-39.904388039318711, -8.227266889597285],
              [-39.904388051317206, -8.227266897273203],
              [-39.904388063327765, -8.227266904930344],
              [-39.904388075350397, -8.227266912568655],
              [-39.904388087385058, -8.227266920188239],
              [-39.904388099431713, -8.227266927788856],
              [-39.904388111490348, -8.227266935370656],
              [-39.904388123560906, -8.227266942933621],
              [-39.90438813564338, -8.227266950477729],
              [-39.90438814773772, -8.227266958002836],
              [-39.90438815984394, -8.227266965509003],
              [-39.904388171961941, -8.22726697299624],
              [-39.904388184091758, -8.227266980464563],
              [-39.904388196233327, -8.227266987913843],
              [-39.904388208386628, -8.227266995344126],
              [-39.904388220551624, -8.227267002755363],
              [-39.904388232728294, -8.227267010147502],
              [-39.904388244916596, -8.227267017520647],
              [-39.90438825711653, -8.227267024874731],
              [-39.904388269328003, -8.227267032209719],
              [-39.904388281551064, -8.227267039525527],
              [-39.904388293785615, -8.227267046822202],
              [-39.904388306031677, -8.227267054099801],
              [-39.904388318289193, -8.227267061358168],
              [-39.904388330558128, -8.227267068597339],
              [-39.904388342838473, -8.227267075817309],
              [-39.90438835513018, -8.227267083018088],
              [-39.904388367433214, -8.227267090199666],
              [-39.90438837974758, -8.227267097361846],
              [-39.904388392073209, -8.227267104504849],
              [-39.904388404410078, -8.227267111628551],
              [-39.90438841675816, -8.227267118732891],
              [-39.904388429117439, -8.227267125818029],
              [-39.904388441487868, -8.227267132883691],
              [-39.904388453869416, -8.227267139930033],
              [-39.904388466262084, -8.22726714695693],
              [-39.90438847802745, -8.227267166440587],
              [-39.905180143230375, -8.227715252595818],
              [-39.905180506780205, -8.227715432664903],
              [-39.906419412723778, -8.228496427621415],
              [-39.906419443730201, -8.228496453103601],
              [-39.906419552462076, -8.228496522731156],
              [-39.906419660187865, -8.228496593893906],
              [-39.906419766885755, -8.228496666577445],
              [-39.906419872534123, -8.228496740766863],
              [-39.906419977111504, -8.228496816447414],
              [-39.906420080596789, -8.228496893603486],
              [-39.906420182968894, -8.228496972219551],
              [-39.906420284207137, -8.228497052279769],
              [-39.906420384290968, -8.228497133767704],
              [-39.906420483200122, -8.228497216666954],
              [-39.906420580914528, -8.228497300960752],
              [-39.906420677414403, -8.228497386631906],
              [-39.90642077268015, -8.228497473663143],
              [-39.906420866692478, -8.228497562036772],
              [-39.906420959432332, -8.228497651734818],
              [-39.906421050880894, -8.228497742739176],
              [-39.906421141019642, -8.228497835031513],
              [-39.906421229830322, -8.228497928592976],
              [-39.906421317294907, -8.228498023404498],
              [-39.906421403395662, -8.228498119447135],
              [-39.906421488115122, -8.228498216701128],
              [-39.906421571436155, -8.228498315146997],
              [-39.906421653341859, -8.228498414764646],
              [-39.906421733815613, -8.228498515533909],
              [-39.906421812841117, -8.228498617434449],
              [-39.906421890402356, -8.228498720445499],
              [-39.906421966483585, -8.228498824546218],
              [-39.906422041069391, -8.228498929715524],
              [-39.906422114144689, -8.228499035932076],
              [-39.906422185694609, -8.22849914317425],
              [-39.906422255704683, -8.228499251420468],
              [-39.906422324160722, -8.228499360648719],
              [-39.906422391048821, -8.228499470836928],
              [-39.90642245635545, -8.228499581962575],
              [-39.906422520067352, -8.228499694003355],
              [-39.906422582171622, -8.228499806936385],
              [-39.90642264265567, -8.228499920738807],
              [-39.906422701507232, -8.228500035387649],
              [-39.906422758714349, -8.228500150859551],
              [-39.906422814265504, -8.228500267131158],
              [-39.906422868149363, -8.228500384178924],
              [-39.906422920355034, -8.228500501979161],
              [-39.90642297087193, -8.228500620507857],
              [-39.906423019689797, -8.228500739741065],
              [-39.906423066798752, -8.228500859654599],
              [-39.906423112189245, -8.228500980224149],
              [-39.906423155852089, -8.228501101425346],
              [-39.906423197778395, -8.228501223233543],
              [-39.9064232379597, -8.228501345624068],
              [-39.906423276387848, -8.228501468572054],
              [-39.906423313055022, -8.228501592052668],
              [-39.906423347953854, -8.228501716040846],
              [-39.906423381077204, -8.228501840511488],
              [-39.906423412418384, -8.228501965439282],
              [-39.906423441971043, -8.228502090798976],
              [-39.906423469729184, -8.228502216565115],
              [-39.906423495687186, -8.228502342712183],
              [-39.906423519839798, -8.228502469214693],
              [-39.906423542182111, -8.228502596046992],
              [-39.90642356270957, -8.22850272318329],
              [-39.906423581418053, -8.228502850597829],
              [-39.906423598303761, -8.228502978264849],
              [-39.906423613363259, -8.228503106158445],
              [-39.906423626593501, -8.228503234252631],
              [-39.906423637991807, -8.228503362521522],
              [-39.906423647555854, -8.228503490938989],
              [-39.906423655283717, -8.228503619479181],
              [-39.906423661173825, -8.228503748116042],
              [-39.90642366522497, -8.228503876823256],
              [-39.906423667436371, -8.228504005574946],
              [-39.906423667807552, -8.228504134344979],
              [-39.906423666338412, -8.228504263107167],
              [-39.90642366302928, -8.228504391835481],
              [-39.906423657880843, -8.228504520503844],
              [-39.906423650894112, -8.22850464908611],
              [-39.906423642070514, -8.22850477755626],
              [-39.906423631411862, -8.2285049058883],
              [-39.906423618920265, -8.228505034056059],
              [-39.906423604598288, -8.228505162033677],
              [-39.906423588448838, -8.228505289795216],
              [-39.906423570475191, -8.228505417314745],
              [-39.906423550680969, -8.22850554456641],
              [-39.906423529070182, -8.228505671524363],
              [-39.906423505647254, -8.228505798162953],
              [-39.906423480416869, -8.228505924456529],
              [-39.906423453384186, -8.228506050379455],
              [-39.906423424554703, -8.228506175906144],
              [-39.906423393934212, -8.228506301011251],
              [-39.906423361528951, -8.228506425669318],
              [-39.906423327345472, -8.228506549855116],
              [-39.906423291390716, -8.228506673543508],
              [-39.906423253671989, -8.228506796709398],
              [-39.906423214196899, -8.22850691932773],
              [-39.906423172973469, -8.22850704137379],
              [-39.906423130010062, -8.228507162822778],
              [-39.90642308531536, -8.228507283650062],
              [-39.90642303889846, -8.22850740383123],
              [-39.906422990768739, -8.22850752334173],
              [-39.9064229409367, -8.228507642156673],
              [-39.906422888042478, -8.228507634833916],
              [-39.906091285251996, -8.229282854460157],
              [-39.906091286565442, -8.229282841349825],
              [-39.906091237992172, -8.229282952801871],
              [-39.906091187920417, -8.229283063593707],
              [-39.90609113635918, -8.229283173705415],
              [-39.906091083317769, -8.229283283117189],
              [-39.906091028805797, -8.229283391809158],
              [-39.906090972833049, -8.229283499761818],
              [-39.906090915409663, -8.229283606955567],
              [-39.906090856545973, -8.229283713371208],
              [-39.906090796252634, -8.229283818989396],
              [-39.906090734540506, -8.229283923791172],
              [-39.906090671420735, -8.229284027757538],
              [-39.906090606904712, -8.229284130869743],
              [-39.906090541004097, -8.229284233109286],
              [-39.906090473730735, -8.229284334457638],
              [-39.906090405096819, -8.229284434896465],
              [-39.906090335114712, -8.229284534407686],
              [-39.90609026379704, -8.229284632973247],
              [-39.906090191156693, -8.229284730575579],
              [-39.906090117206766, -8.22928482719685],
              [-39.906090041960617, -8.229284922819724],
              [-39.906089965431804, -8.229285017426921],
              [-39.906089887634167, -8.229285111001396],
              [-39.906089808581719, -8.229285203526238],
              [-39.906089728288755, -8.229285294984701],
              [-39.906089646769743, -8.229285385360361],
              [-39.906089564039412, -8.229285474636796],
              [-39.906089480112684, -8.229285562798017],
              [-39.906089395004706, -8.229285649828133],
              [-39.906089308730834, -8.229285735711313],
              [-39.906089221306665, -8.229285820432093],
              [-39.906089132747923, -8.229285903975233],
              [-39.906089043070644, -8.229285986325591],
              [-39.906088952291, -8.229286067468392],
              [-39.906088860425356, -8.229286147388853],
              [-39.906088767490303, -8.229286226072652],
              [-39.906088673502616, -8.229286303505642],
              [-39.906088578479256, -8.229286379673757],
              [-39.906088482437369, -8.229286454563217],
              [-39.906088385394305, -8.229286528160591],
              [-39.906088287367545, -8.229286600452568],
              [-39.906088188374802, -8.229286671426056],
              [-39.906088088433954, -8.229286741068256],
              [-39.906087987563012, -8.229286809366778],
              [-39.906087885780217, -8.229286876309059],
              [-39.906087783103885, -8.229286941883052],
              [-39.906087679552584, -8.229287006077037],
              [-39.906087575145001, -8.229287068879398],
              [-39.906087469899973, -8.229287130278774],
              [-39.906087363836498, -8.229287190264021],
              [-39.906087256973706, -8.229287248824431],
              [-39.906087149330894, -8.229287305949342],
              [-39.906087040927488, -8.229287361628408],
              [-39.906086931783044, -8.229287415851759],
              [-39.90608682191727, -8.229287468609462],
              [-39.906086711350007, -8.229287519892075],
              [-39.90608660010119, -8.229287569690234],
              [-39.906086488190894, -8.229287617995032],
              [-39.906086375639326, -8.229287664797749],
              [-39.906086262466793, -8.229287710090002],
              [-39.906086148693738, -8.229287753863437],
              [-39.906086034340667, -8.229287796110306],
              [-39.906085919428229, -8.229287836822923],
              [-39.906085803977177, -8.229287875994007],
              [-39.906085688008325, -8.229287913616352],
              [-39.906085571542619, -8.229287949683275],
              [-39.906085454601097, -8.229287984188325],
              [-39.906085337204793, -8.229288017125105],
              [-39.90608521937498, -8.22928804848779],
              [-39.906085101132859, -8.229288078270754],
              [-39.906084982499806, -8.229288106468443],
              [-39.906084863497234, -8.229288133075915],
              [-39.90608474414659, -8.229288158088348],
              [-39.906084624469436, -8.229288181501239],
              [-39.906084504487403, -8.229288203310324],
              [-39.906084384222062, -8.229288223511608],
              [-39.906084263695185, -8.229288242101598],
              [-39.906084142928499, -8.229288259076823],
              [-39.906084021943819, -8.229288274434303],
              [-39.906083900762951, -8.229288288171215],
              [-39.906083779407815, -8.229288300285045],
              [-39.906083657900233, -8.229288310773686],
              [-39.906083536262209, -8.229288319635174],
              [-39.906083414515649, -8.229288326867927],
              [-39.906083292682567, -8.229288332470727],
              [-39.906083170784925, -8.229288336442416],
              [-39.9060830488447, -8.229288338782455],
              [-39.906082926883947, -8.229288339490152],
              [-39.906082804924644, -8.229288338565647],
              [-39.906082682988817, -8.22928833600894],
              [-39.906082561098479, -8.229288331820619],
              [-39.906082439275622, -8.229288326001381],
              [-39.906082317542207, -8.229288318552197],
              [-39.906082195920227, -8.229288309474523],
              [-39.906082074431652, -8.229288298770019],
              [-39.906081953098379, -8.2292882864405],
              [-39.906081831942295, -8.229288272488319],
              [-39.906081710985298, -8.229288256915835],
              [-39.906081590249222, -8.229288239726001],
              [-39.90608146975525, -8.229288220921397],
              [-39.906081492579155, -8.229288212738201],
              [-39.904373546625067, -8.229009965836532],
              [-39.901088326704844, -8.228947433449294],
              [-39.899673568181662, -8.229329835909251],
              [-39.899494638035328, -8.229373921039613],
              [-39.899309068067495, -8.22941250386412],
              [-39.899132645760972, -8.229441479056749],
              [-39.898951190873504, -8.229453009714872],
              [-39.898773902309642, -8.229460189653279],
              [-39.898561020465067, -8.229505345217586],
              [-39.898366172980488, -8.229496012967694],
              [-39.898071446878994, -8.229480391906101],
              [-39.897911906175565, -8.23253070647892],
              [-39.897824991013415, -8.234249339678071],
              [-39.898010334655226, -8.235922141833697],
              [-39.928849190181765, -8.223596895308386],
              [-39.933412898160242, -8.226317714528403],
              [-39.928238548110734, -8.234270630713644],
            ],
          ],
          [
            [
              [-40.00326088164919, -8.247647373349308],
              [-40.007099803158077, -8.242258207344147],
              [-40.006631672521159, -8.242781771552437],
              [-40.005229804016871, -8.244312028064064],
              [-40.005229831957529, -8.244312028722099],
              [-40.005229750066455, -8.244312116930272],
              [-40.005229667006311, -8.244312204046935],
              [-40.005229582791785, -8.244312290056694],
              [-40.005229497437718, -8.24431237494432],
              [-40.005229410959203, -8.244312458694951],
              [-40.005229323371466, -8.244312541293572],
              [-40.005229234689992, -8.244312622725925],
              [-40.005229144930425, -8.244312702977368],
              [-40.005229054108611, -8.244312782033946],
              [-40.005228962240558, -8.2443128598816],
              [-40.005228869342488, -8.24431293650653],
              [-40.005228775430815, -8.244313011895334],
              [-40.005228680522094, -8.244313086034564],
              [-40.00522858463308, -8.24431315891127],
              [-40.005228487780712, -8.244313230512624],
              [-40.005228389982065, -8.24431330082585],
              [-40.005228291254397, -8.244313369838604],
              [-40.005228191615139, -8.244313437538699],
              [-40.005228091081882, -8.244313503914114],
              [-40.005227989672363, -8.244313568953281],
              [-40.005227887404459, -8.244313632644667],
              [-40.005227784296252, -8.244313694977036],
              [-40.005227680365934, -8.244313755939299],
              [-40.005227575631821, -8.244313815520792],
              [-40.005227470112438, -8.24431387371096],
              [-40.005227363826357, -8.244313930499542],
              [-40.005227256792395, -8.244313985876525],
              [-40.005227149029388, -8.244314039832156],
              [-40.005227040556399, -8.244314092356783],
              [-40.005226931392542, -8.244314143441331],
              [-40.005226821557095, -8.244314193076637],
              [-40.00522671106944, -8.244314241254028],
              [-40.005226599949069, -8.244314287964961],
              [-40.005226488215619, -8.244314333201276],
              [-40.005226375888768, -8.244314376954826],
              [-40.00522626298838, -8.244314419217909],
              [-40.005226149534373, -8.244314459983304],
              [-40.005226035546748, -8.244314499243488],
              [-40.005225921045621, -8.244314536991705],
              [-40.00522580605125, -8.244314573221381],
              [-40.00522569058387, -8.244314607925951],
              [-40.005225574663903, -8.244314641099431],
              [-40.005225458311763, -8.244314672735721],
              [-40.005225341548041, -8.244314702829518],
              [-40.005225224393293, -8.244314731375409],
              [-40.005225106868224, -8.244314758368359],
              [-40.005224988993561, -8.244314783803597],
              [-40.005224870790137, -8.244314807676592],
              [-40.005224752278771, -8.244314829983178],
              [-40.005224633480402, -8.24431485071943],
              [-40.005224514416, -8.244314869881624],
              [-40.005224395106573, -8.244314887466453],
              [-40.005224275573163, -8.244314903470784],
              [-40.005224155836885, -8.244314917891817],
              [-40.005224035918864, -8.24431493072689],
              [-40.005223915840268, -8.244314941973961],
              [-40.005223795622285, -8.24431495163085],
              [-40.005223675286132, -8.244314959695826],
              [-40.005223554853046, -8.244314966167671],
              [-40.005223434344295, -8.244314971045034],
              [-40.005223313781123, -8.244314974327272],
              [-40.00522319318484, -8.244314976013555],
              [-40.005223072576676, -8.244314976103784],
              [-40.005222951977977, -8.24431497459787],
              [-40.005222831410023, -8.244314971496058],
              [-40.005222710894039, -8.244314966798889],
              [-40.005222590451332, -8.244314960507269],
              [-40.00522247010317, -8.244314952622291],
              [-40.005222349870756, -8.244314943145246],
              [-40.005222229775335, -8.24431493207784],
              [-40.005222109838122, -8.244314919422136],
              [-40.005221990080223, -8.244314905180307],
              [-40.005221870522853, -8.244314889354792],
              [-40.005221751187037, -8.24431487194844],
              [-40.005221632093878, -8.244314852964377],
              [-40.005221513264381, -8.244314832405898],
              [-40.005221394719527, -8.244314810276634],
              [-40.005221276480235, -8.244314786580439],
              [-40.005221158567373, -8.244314761321618],
              [-40.005221041001747, -8.244314734504499],
              [-40.005220923804103, -8.24431470613397],
              [-40.005220806995133, -8.244314676214831],
              [-40.005220690595465, -8.244314644752567],
              [-40.005220574625618, -8.244314611752625],
              [-40.005220459106042, -8.244314577220832],
              [-40.005220344057186, -8.244314541163209],
              [-40.005220229499301, -8.244314503586322],
              [-40.00522011545263, -8.2443144644966],
              [-40.005220001937289, -8.244314423901168],
              [-40.005219888973301, -8.244314381806896],
              [-40.005219776580645, -8.244314338221443],
              [-40.005219664779119, -8.244314293152369],
              [-40.005219553588468, -8.24431424660777],
              [-40.005219443028309, -8.244314198595692],
              [-40.005219333118163, -8.244314149124742],
              [-40.005219223877432, -8.244314098203501],
              [-40.005219115325367, -8.244314045841163],
              [-40.005219007481166, -8.244313992046827],
              [-40.005218900363268, -8.244313936830745],
              [-40.00521891449948, -8.244313954626215],
              [-40.004141761050754, -8.243749585211001],
              [-40.004141743488312, -8.243749614539698],
              [-40.004141617164542, -8.243749547084944],
              [-40.004141491925459, -8.243749477651999],
              [-40.004141367802255, -8.243749406258001],
              [-40.004141244825938, -8.243749332920846],
              [-40.004141123027118, -8.243749257658857],
              [-40.004141002436157, -8.243749180490653],
              [-40.004140883083103, -8.243749101435704],
              [-40.004140764997729, -8.243749020513359],
              [-40.004140648209443, -8.243748937744138],
              [-40.00414053274735, -8.243748853148418],
              [-40.004140418640254, -8.243748766747435],
              [-40.004140305916565, -8.243748678562749],
              [-40.004140194604396, -8.243748588616164],
              [-40.004140084731489, -8.24374849693025],
              [-40.004139976325234, -8.243748403527801],
              [-40.004139869412626, -8.243748308432064],
              [-40.00413976402033, -8.243748211666778],
              [-40.004139660174616, -8.243748113256132],
              [-40.004139557901361, -8.243748013224556],
              [-40.004139457226053, -8.243747911597016],
              [-40.004139358173809, -8.243747808398791],
              [-40.004139260769264, -8.243747703655728],
              [-40.004139165036754, -8.243747597393808],
              [-40.004139071000097, -8.243747489639551],
              [-40.004138978682747, -8.24374738041984],
              [-40.004138888107718, -8.243747269761791],
              [-40.004138799297579, -8.243747157693241],
              [-40.004138712274454, -8.243747044241845],
              [-40.004138627060037, -8.243746929436039],
              [-40.004138543675595, -8.243746813304318],
              [-40.004138462141881, -8.243746695875766],
              [-40.004138382479226, -8.24374657717952],
              [-40.004138304707475, -8.243746457245246],
              [-40.004138228846031, -8.243746336102893],
              [-40.004138154913782, -8.243746213782515],
              [-40.004138082929167, -8.243746090314652],
              [-40.004138012910119, -8.243745965730129],
              [-40.004137944874095, -8.243745840059875],
              [-40.004137878838058, -8.243745713335311],
              [-40.004137814818449, -8.243745585588004],
              [-40.004137752831255, -8.243745456849849],
              [-40.004137692891888, -8.243745327152798],
              [-40.004137635015319, -8.243745196529245],
              [-40.00413757921595, -8.243745065011835],
              [-40.004137525507694, -8.243744932633243],
              [-40.00413747390396, -8.243744799426457],
              [-40.004137424417593, -8.243744665424771],
              [-40.004137377060907, -8.243744530661449],
              [-40.004137331845726, -8.243744395170209],
              [-40.004137288783305, -8.243744258984716],
              [-40.004137247884422, -8.243744122139002],
              [-40.004137209159211, -8.243743984667077],
              [-40.004137172617369, -8.243743846603257],
              [-40.004137138267957, -8.243743707981974],
              [-40.004137106119586, -8.243743568837791],
              [-40.004137076180236, -8.243743429205372],
              [-40.00413704845738, -8.243743289119458],
              [-40.004137022957934, -8.243743148615028],
              [-40.004136999688242, -8.24374300772706],
              [-40.00413697865411, -8.243742866490731],
              [-40.00413695986078, -8.2437427249411],
              [-40.004136943312922, -8.243742583113617],
              [-40.004136929014706, -8.243742441043546],
              [-40.004136916969628, -8.243742298766341],
              [-40.00413690718073, -8.243742156317436],
              [-40.004136899650462, -8.243742013732369],
              [-40.004136894380679, -8.243741871046586],
              [-40.004136891372696, -8.243741728295735],
              [-40.004136890627272, -8.243741585515314],
              [-40.004136892144601, -8.243741442741008],
              [-40.004136895924269, -8.243741300008327],
              [-40.004136901965374, -8.24374115735295],
              [-40.004136910266382, -8.243741014810372],
              [-40.004136920825246, -8.243740872415945],
              [-40.004136933639323, -8.243740730205477],
              [-40.004136948705401, -8.243740588214191],
              [-40.004136966019772, -8.243740446477585],
              [-40.004136985578086, -8.243740305030906],
              [-40.004137007375462, -8.243740163909372],
              [-40.004137031406472, -8.243740023148344],
              [-40.004137057665169, -8.243739882782757],
              [-40.004137086144958, -8.243739742847614],
              [-40.004137116838756, -8.243739603377811],
              [-40.004137149738902, -8.243739464408078],
              [-40.004137184837219, -8.243739325973163],
              [-40.004137222124953, -8.24373918810743],
              [-40.004137261592795, -8.243739050845271],
              [-40.004137303230934, -8.243738914220891],
              [-40.004137347028959, -8.243738778268529],
              [-40.004137392975977, -8.243738643021823],
              [-40.004137441060514, -8.24373850851452],
              [-40.00413749127064, -8.24373837478015],
              [-40.004137543593764, -8.243738241852229],
              [-40.004137598016911, -8.243738109763742],
              [-40.004137654526474, -8.243737978547584],
              [-40.004137713108378, -8.243737848236444],
              [-40.004137773748042, -8.243737718862903],
              [-40.004137836430331, -8.243737590459205],
              [-40.004137901138591, -8.243737463058316],
              [-40.004137917473258, -8.243737475683405],
              [-40.004832678742453, -8.242395936830491],
              [-40.004383952440023, -8.241255961160938],
              [-40.004383769044409, -8.241255238088643],
              [-40.00373770101438, -8.239151920735948],
              [-40.003737752685382, -8.239151951264674],
              [-40.003737746287776, -8.239151930524089],
              [-40.00373773984024, -8.239151909798725],
              [-40.003737733342817, -8.23915188908895],
              [-40.003737726795528, -8.239151868394682],
              [-40.003737720198437, -8.2391518477163],
              [-40.00373771355158, -8.239151827053561],
              [-40.003737706854942, -8.239151806406783],
              [-40.003737700108623, -8.23915178577613],
              [-40.003737693312644, -8.239151765161711],
              [-40.003737686467034, -8.239151744563516],
              [-40.003737679571863, -8.239151723981722],
              [-40.003737672627111, -8.239151703416505],
              [-40.003737665632855, -8.239151682867973],
              [-40.003737658589166, -8.239151662336111],
              [-40.003737651496024, -8.239151641821181],
              [-40.003737644353507, -8.239151621323307],
              [-40.003737637161635, -8.239151600842556],
              [-40.003737629920479, -8.239151580379025],
              [-40.003737622630055, -8.239151559932901],
              [-40.003737615290405, -8.239151539504233],
              [-40.003737607901591, -8.239151519093161],
              [-40.003737600463637, -8.239151498699847],
              [-40.003737592976599, -8.239151478324278],
              [-40.003737585440511, -8.239151457966752],
              [-40.003737577855418, -8.239151437627259],
              [-40.003737570221361, -8.239151417305981],
              [-40.003737562538404, -8.239151397002985],
              [-40.003737554806563, -8.239151376718409],
              [-40.003737547025885, -8.239151356452378],
              [-40.003737539196436, -8.239151336205024],
              [-40.003737531318251, -8.239151315976416],
              [-40.003737523391379, -8.239151295766668],
              [-40.003737515415843, -8.239151275575908],
              [-40.003737507391726, -8.239151255404387],
              [-40.00373749931903, -8.23915123525196],
              [-40.003737491197839, -8.239151215119003],
              [-40.003737483028196, -8.239151195005446],
              [-40.003737474810123, -8.239151174911468],
              [-40.00373746654369, -8.239151154837216],
              [-40.003737458228933, -8.239151134782814],
              [-40.003737449865909, -8.239151114748237],
              [-40.00373744145466, -8.239151094733767],
              [-40.003737432995244, -8.239151074739492],
              [-40.003737424487682, -8.239151054765442],
              [-40.003737415932058, -8.239151034811838],
              [-40.003737407328408, -8.239151014878708],
              [-40.003737398676769, -8.239150994966131],
              [-40.003737389977189, -8.239150975074395],
              [-40.003737381229755, -8.239150955203398],
              [-40.003737372434507, -8.239150935353425],
              [-40.003737363591448, -8.239150915524529],
              [-40.003737354700675, -8.239150895716845],
              [-40.003737345762218, -8.239150875930436],
              [-40.00373733677614, -8.239150856165475],
              [-40.003737327742485, -8.239150836422095],
              [-40.003737318661308, -8.239150816700244],
              [-40.003737309532681, -8.239150797000224],
              [-40.003737300356619, -8.239150777322044],
              [-40.00373729113322, -8.239150757665858],
              [-40.003737281862485, -8.239150738031862],
              [-40.003737272544505, -8.23915071841995],
              [-40.003737263179318, -8.239150698830409],
              [-40.003737253766971, -8.239150679263325],
              [-40.003737244307551, -8.239150659718819],
              [-40.003737234801086, -8.239150640196895],
              [-40.003737225247633, -8.239150620697819],
              [-40.003737215647234, -8.239150601221652],
              [-40.003737205999968, -8.239150581768392],
              [-40.003737196305899, -8.239150562338313],
              [-40.003737186565047, -8.239150542931432],
              [-40.003737176777506, -8.239150523547881],
              [-40.003737166943296, -8.23915050418786],
              [-40.003737157062503, -8.239150484851342],
              [-40.003737147135148, -8.239150465538486],
              [-40.003737137161323, -8.239150446249402],
              [-40.003737127141086, -8.239150426984169],
              [-40.003737117074465, -8.239150407742954],
              [-40.003737106961566, -8.23915038852593],
              [-40.003737096802396, -8.239150369333162],
              [-40.003737086597027, -8.239150350164632],
              [-40.003737076345537, -8.239150331020561],
              [-40.003737066047975, -8.239150311901032],
              [-40.003737055704384, -8.239150292806226],
              [-40.003737045314857, -8.2391502737362],
              [-40.003737034879421, -8.239150254690989],
              [-40.00373702439817, -8.239150235670856],
              [-40.003737013871117, -8.239150216675698],
              [-40.003737003298376, -8.239150197705909],
              [-40.003736992679961, -8.239150178761372],
              [-40.003736982015958, -8.239150159842264],
              [-40.003736971306431, -8.239150140948674],
              [-40.003736960551436, -8.239150122080803],
              [-40.003736949751023, -8.239150103238655],
              [-40.003736938905277, -8.239150084422377],
              [-40.003736928014241, -8.239150065632085],
              [-40.003736917077987, -8.239150046867866],
              [-40.00373690609657, -8.239150028129806],
              [-40.003736895070048, -8.23915000941809],
              [-40.003736883998549, -8.239149990732795],
              [-40.003736879164556, -8.239150023521004],
              [-40.003096761656167, -8.238072657306498],
              [-40.003096793431119, -8.238072666953201],
              [-40.003096737393051, -8.238072573914168],
              [-40.003096680238606, -8.238072481551475],
              [-40.003096621975992, -8.238072389878479],
              [-40.003096562613628, -8.238072298908522],
              [-40.003096502160041, -8.238072208654511],
              [-40.003096440623914, -8.238072119129457],
              [-40.003096378014128, -8.238072030346338],
              [-40.003096314339686, -8.238071942317896],
              [-40.003096249609747, -8.23807185505677],
              [-40.003096183833648, -8.238071768575514],
              [-40.003096117020824, -8.238071682886668],
              [-40.003096049180897, -8.238071598002433],
              [-40.003095980323664, -8.238071513935084],
              [-40.00309591045901, -8.238071430696651],
              [-40.003095839596988, -8.23807134829932],
              [-40.003095767747801, -8.238071266754774],
              [-40.003095694921811, -8.238071186074762],
              [-40.003095621129461, -8.238071106270999],
              [-40.003095546381402, -8.238071027354863],
              [-40.003095470688365, -8.238070949337734],
              [-40.003095394061262, -8.238070872230864],
              [-40.00309531651115, -8.238070796045358],
              [-40.003095238049134, -8.238070720792177],
              [-40.003095158686548, -8.238070646482161],
              [-40.003095078434797, -8.238070573125894],
              [-40.003094997305453, -8.23807050073413],
              [-40.003094915310157, -8.238070429317101],
              [-40.003094832460754, -8.238070358885247],
              [-40.003094748769094, -8.238070289448519],
              [-40.003094664247321, -8.23807022101715],
              [-40.003094578907536, -8.23807015360075],
              [-40.003094492762038, -8.238070087209273],
              [-40.003094405823219, -8.238070021851943],
              [-40.003094318103606, -8.238069957538475],
              [-40.003094229615812, -8.238069894278031],
              [-40.003094140372582, -8.238069832079622],
              [-40.003094050386764, -8.238069770952267],
              [-40.003093959671276, -8.238069710904895],
              [-40.003093868239212, -8.238069651945862],
              [-40.003093776103732, -8.238069594083912],
              [-40.003093683278095, -8.238069537327165],
              [-40.003093589775617, -8.238069481684011],
              [-40.003093495609811, -8.238069427162319],
              [-40.003093400794221, -8.238069373769884],
              [-40.00309330534246, -8.238069321514558],
              [-40.003093209268279, -8.238069270403734],
              [-40.003093112585525, -8.238069220444782],
              [-40.003093015308117, -8.238069171645053],
              [-40.00309291745004, -8.238069124011297],
              [-40.003092819025376, -8.238069077550598],
              [-40.003092720048294, -8.238069032269472],
              [-40.003092620533046, -8.238068988174568],
              [-40.003092520493951, -8.238068945272166],
              [-40.003092419945403, -8.238068903568452],
              [-40.003092318901871, -8.238068863069515],
              [-40.003092217377926, -8.238068823781054],
              [-40.003092115388128, -8.238068785708718],
              [-40.003092012947221, -8.238068748858122],
              [-40.003091910069898, -8.238068713234458],
              [-40.003091806770982, -8.23806867884284],
              [-40.003091703065344, -8.238068645688291],
              [-40.003091598967927, -8.238068613775576],
              [-40.003091494493681, -8.23806858310928],
              [-40.003091389657662, -8.238068553693775],
              [-40.003091284474941, -8.238068525533297],
              [-40.00309117896068, -8.238068498632011],
              [-40.003091073130058, -8.238068472993632],
              [-40.003090966998307, -8.238068448621929],
              [-40.003090860580684, -8.238068425520455],
              [-40.003090753892543, -8.23806840369242],
              [-40.003090646949204, -8.238068383141085],
              [-40.00309053976607, -8.238068363869264],
              [-40.003090432358576, -8.238068345879897],
              [-40.003090324742175, -8.238068329175423],
              [-40.003090216932371, -8.238068313758395],
              [-40.00309010894464, -8.238068299630983],
              [-40.003090000794586, -8.238068286795105],
              [-40.003089892497698, -8.23806827525271],
              [-40.003089784069651, -8.238068265005504],
              [-40.003089675525977, -8.238068256054863],
              [-40.003089566882331, -8.23806824840204],
              [-40.003089458154356, -8.238068242048334],
              [-40.00308934935768, -8.238068236994465],
              [-40.003089240507975, -8.238068233241236],
              [-40.003089131620904, -8.238068230789219],
              [-40.003089022712153, -8.238068229638673],
              [-40.003088913797377, -8.238068229789882],
              [-40.003088804892258, -8.238068231242767],
              [-40.003088696012455, -8.238068233997044],
              [-40.003088587173686, -8.23806823805252],
              [-40.003088478391568, -8.238068243408343],
              [-40.003088369681777, -8.238068250063922],
              [-40.003088261059979, -8.238068258018275],
              [-40.003088152541764, -8.238068267270199],
              [-40.003088044142771, -8.238068277818408],
              [-40.003087935878625, -8.238068289661383],
              [-40.003087827764887, -8.238068302797465],
              [-40.003087719817096, -8.238068317224643],
              [-40.003087612050827, -8.238068332940966],
              [-40.003087581480898, -8.238068339059041],
              [-40.001814261280764, -8.238261808225088],
              [-40.001814283413879, -8.238261808269399],
              [-40.001814236584245, -8.238261815509528],
              [-40.001814189793173, -8.238261822993058],
              [-40.001814143041955, -8.238261830719726],
              [-40.00181409633187, -8.23826183868951],
              [-40.001814049664198, -8.238261846902024],
              [-40.001814003040145, -8.238261855357164],
              [-40.001813956461071, -8.238261864054691],
              [-40.001813909928188, -8.238261872994261],
              [-40.001813863442791, -8.238261882175692],
              [-40.001813817006138, -8.238261891598702],
              [-40.001813770619485, -8.238261901263094],
              [-40.001813724284098, -8.238261911168555],
              [-40.001813678001255, -8.238261921314804],
              [-40.001813631772208, -8.238261931701697],
              [-40.001813585598235, -8.238261942328842],
              [-40.001813539480551, -8.238261953195956],
              [-40.001813493420457, -8.238261964302692],
              [-40.00181344741916, -8.238261975648866],
              [-40.001813401477975, -8.238261987234104],
              [-40.001813355598102, -8.238261999058045],
              [-40.0018133097808, -8.238262011120401],
              [-40.001813264027334, -8.238262023420926],
              [-40.00181321833896, -8.238262035959146],
              [-40.001813172716901, -8.238262048734782],
              [-40.001813127162393, -8.238262061747491],
              [-40.001813081676694, -8.238262074996967],
              [-40.001813036261048, -8.238262088482784],
              [-40.001812990916676, -8.238262102204557],
              [-40.001812945644843, -8.238262116161939],
              [-40.001812900446744, -8.238262130354626],
              [-40.001812855323621, -8.238262144782126],
              [-40.001812810276718, -8.238262159444105],
              [-40.001812765307278, -8.238262174340116],
              [-40.001812720416495, -8.238262189469747],
              [-40.001812675605578, -8.238262204832605],
              [-40.00181263087579, -8.238262220428336],
              [-40.001812586228318, -8.238262236256473],
              [-40.001812541664414, -8.238262252316538],
              [-40.001812497185256, -8.238262268608207],
              [-40.001812452792059, -8.238262285130823],
              [-40.00181240848606, -8.238262301884131],
              [-40.001812364268446, -8.238262318867672],
              [-40.001812320140409, -8.238262336080869],
              [-40.001812276103202, -8.238262353523279],
              [-40.001812232157967, -8.238262371194496],
              [-40.001812188305919, -8.238262389093983],
              [-40.001812144548289, -8.238262407221344],
              [-40.00181210088622, -8.23826242557592],
              [-40.001812057320919, -8.238262444157373],
              [-40.001812013853588, -8.238262462965025],
              [-40.001811970485385, -8.238262481998539],
              [-40.001811927217517, -8.238262501257296],
              [-40.001811884051143, -8.238262520740784],
              [-40.001811840987465, -8.238262540448487],
              [-40.001811798027632, -8.238262560379878],
              [-40.00181175517281, -8.238262580534403],
              [-40.001811712424193, -8.238262600911503],
              [-40.001811669782917, -8.238262621510634],
              [-40.001811627250177, -8.238262642331287],
              [-40.001811584827095, -8.238262663372756],
              [-40.001811542514865, -8.238262684634559],
              [-40.001811500314602, -8.238262706116091],
              [-40.001811458227493, -8.238262727816776],
              [-40.001811416254647, -8.238262749736041],
              [-40.001811374397228, -8.238262771873343],
              [-40.001811332656395, -8.238262794228033],
              [-40.001811291033263, -8.238262816799407],
              [-40.001811249528949, -8.238262839586925],
              [-40.001811208144623, -8.238262862590005],
              [-40.001811166881389, -8.238262885807973],
              [-40.001811125740375, -8.238262909240222],
              [-40.001811084722718, -8.238262932885968],
              [-40.001811043829505, -8.238262956744778],
              [-40.001811003061874, -8.238262980815957],
              [-40.001810962420905, -8.238263005098737],
              [-40.001810921907762, -8.23826302959267],
              [-40.001810881523483, -8.238263054296818],
              [-40.001810841269219, -8.238263079210627],
              [-40.00181080114605, -8.238263104333489],
              [-40.001810761155078, -8.238263129664693],
              [-40.00181072129736, -8.238263155203445],
              [-40.001810681574021, -8.238263180949096],
              [-40.001810641986125, -8.238263206901044],
              [-40.001810602534754, -8.238263233058507],
              [-40.001810563220978, -8.238263259420764],
              [-40.001810524045872, -8.238263285987047],
              [-40.001810485010502, -8.238263312756752],
              [-40.001810446115947, -8.23826333972905],
              [-40.001810407363244, -8.238263366903228],
              [-40.001810368753453, -8.238263394278643],
              [-40.001810330287633, -8.238263421854388],
              [-40.001810291966834, -8.238263449629832],
              [-40.001810253792087, -8.238263477604132],
              [-40.001810215764465, -8.238263505776596],
              [-40.001810177884984, -8.238263534146496],
              [-40.001810140154632, -8.2382635627129],
              [-40.001810102574503, -8.238263591475102],
              [-40.001810065145612, -8.238263620432475],
              [-40.001810027868927, -8.238263649583969],
              [-40.001809998950776, -8.238263627694158],
              [-40.000031667344025, -8.239661857272502],
              [-40.000031674008532, -8.239661858567201],
              [-40.0000315270538, -8.239661971697053],
              [-40.000031377806827, -8.239662081808598],
              [-40.000031226330087, -8.239662188855686],
              [-40.000031072686973, -8.239662292793453],
              [-40.000030916941768, -8.239662393578541],
              [-40.000030759159678, -8.23966249116873],
              [-40.000030599406699, -8.239662585523099],
              [-40.0000304377497, -8.239662676602237],
              [-40.000030274256346, -8.239662764368065],
              [-40.000030108995034, -8.239662848783675],
              [-40.000029942034935, -8.239662929813974],
              [-40.000029773445924, -8.239663007424857],
              [-40.00002960329855, -8.239663081584039],
              [-40.000029431664018, -8.239663152260334],
              [-40.000029258614141, -8.239663219424163],
              [-40.00002908422136, -8.239663283047516],
              [-40.00002890855864, -8.239663343103645],
              [-40.000028731699494, -8.239663399567592],
              [-40.000028553717961, -8.239663452415543],
              [-40.000028374688497, -8.239663501625422],
              [-40.000028194686038, -8.239663547176757],
              [-40.000028013785872, -8.239663589050377],
              [-40.000027832063786, -8.239663627228802],
              [-40.000027649595744, -8.239663661696053],
              [-40.00002746645815, -8.239663692437659],
              [-40.000027282727636, -8.239663719440749],
              [-40.000027098481091, -8.239663742694082],
              [-40.000026913795629, -8.239663762187963],
              [-40.00002672874853, -8.239663777914151],
              [-40.000026543417228, -8.23966378986613],
              [-40.000026357879285, -8.239663798038704],
              [-40.000026172212358, -8.23966380242873],
              [-40.000025986494144, -8.239663803034288],
              [-40.000025800802341, -8.239663799854991],
              [-40.000025615214675, -8.2396637928923],
              [-40.000025429808815, -8.239663782149051],
              [-40.000025244662375, -8.239663767629867],
              [-40.000025059852767, -8.239663749340625],
              [-40.000024875457399, -8.239663727289186],
              [-40.000024691553421, -8.239663701484647],
              [-40.000024508217756, -8.239663671937858],
              [-40.000024325527178, -8.239663638661142],
              [-40.000024143558129, -8.239663601668443],
              [-40.000023962386734, -8.23966356097527],
              [-40.000023782088846, -8.239663516598601],
              [-40.00002360273988, -8.239663468557149],
              [-40.000023424414934, -8.239663416870842],
              [-40.000023247188608, -8.239663361561378],
              [-40.000023071135075, -8.239663302651836],
              [-40.000022896328005, -8.239663240166994],
              [-40.000022722840555, -8.239663174132955],
              [-40.000022550745335, -8.239663104577364],
              [-40.00002238011438, -8.239663031529284],
              [-40.000022211019058, -8.239662955019272],
              [-40.000022043530151, -8.239662875079386],
              [-40.000021877717749, -8.239662791743099],
              [-40.000021713651257, -8.239662705045296],
              [-40.000021551399321, -8.239662615022187],
              [-40.00002139102984, -8.239662521711406],
              [-40.000021232609932, -8.23966242515219],
              [-40.000021076205883, -8.23966232538479],
              [-40.000020921883163, -8.239662222451091],
              [-40.000020769706367, -8.239662116393999],
              [-40.000020619739125, -8.239662007258037],
              [-40.000020472044248, -8.239661895088803],
              [-40.000020326683526, -8.239661779933277],
              [-40.000020183717787, -8.239661661839554],
              [-40.000020043206874, -8.239661540857197],
              [-40.00001990520957, -8.239661417036748],
              [-40.000019769783627, -8.239661290430037],
              [-40.000019636985741, -8.239661161090014],
              [-40.00001950687146, -8.239661029070893],
              [-40.000019379495257, -8.23966089442794],
              [-40.00001925491042, -8.23966075721736],
              [-40.000019133169125, -8.239660617496732],
              [-40.000019014322255, -8.239660475324387],
              [-40.000018898419597, -8.239660330759905],
              [-40.00001878550961, -8.239660183863739],
              [-40.000018675639609, -8.239660034697431],
              [-40.000018568855495, -8.239659883323363],
              [-40.000018465202025, -8.239659729804817],
              [-40.000018364722543, -8.239659574206172],
              [-40.00001826745909, -8.239659416592444],
              [-40.000018173452403, -8.239659257029643],
              [-40.000018082741803, -8.23965909558458],
              [-40.000017995365241, -8.239658932324662],
              [-40.000017911359286, -8.239658767318343],
              [-40.000017830759127, -8.239658600634622],
              [-40.000017753598449, -8.239658432343408],
              [-40.000017679909561, -8.239658262514904],
              [-40.000017609723315, -8.239658091220209],
              [-40.00001754306907, -8.239657918531158],
              [-40.000017479974701, -8.239657744519949],
              [-40.000017420466648, -8.239657569259323],
              [-40.000017364569793, -8.239657392822782],
              [-40.000017312307527, -8.239657215283961],
              [-40.000017263701729, -8.239657036717386],
              [-40.000017218772747, -8.239656857197614],
              [-40.000017177539497, -8.239656676799783],
              [-40.000017128566874, -8.239656647893725],
              [-39.999826550945841, -8.238781286772047],
              [-39.999826523581675, -8.238781287526662],
              [-39.999826508725526, -8.238781217996582],
              [-39.999826494418407, -8.23878114835237],
              [-39.999826480661213, -8.238781078598249],
              [-39.99982646745481, -8.238781008738545],
              [-39.999826454799987, -8.238780938777639],
              [-39.99982644269754, -8.238780868719855],
              [-39.999826431148229, -8.238780798569342],
              [-39.99982642015275, -8.238780728330637],
              [-39.999826409711801, -8.238780658008054],
              [-39.999826399825992, -8.238780587605863],
              [-39.999826390495976, -8.238780517128394],
              [-39.999826381722286, -8.238780446579998],
              [-39.99982637350552, -8.23878037596516],
              [-39.99982636584614, -8.238780305288184],
              [-39.999826358744613, -8.238780234553371],
              [-39.999826352201417, -8.23878016376516],
              [-39.999826346216928, -8.238780092927898],
              [-39.999826340791536, -8.238780022045935],
              [-39.999826335925562, -8.23877995112368],
              [-39.999826331619303, -8.238779880165563],
              [-39.999826327873024, -8.238779809175847],
              [-39.999826324686971, -8.238779738159064],
              [-39.999826322061338, -8.238779667119438],
              [-39.999826319996281, -8.2387795960615],
              [-39.999826318491913, -8.238779524989599],
              [-39.999826317548361, -8.238779453908082],
              [-39.999826317165649, -8.238779382821443],
              [-39.999826317343832, -8.238779311733962],
              [-39.999826318082874, -8.238779240650038],
              [-39.999826319382755, -8.238779169574165],
              [-39.999826321243368, -8.238779098510491],
              [-39.999826323664607, -8.238779027463819],
              [-39.999826326646335, -8.238778956438169],
              [-39.999826330188341, -8.238778885438077],
              [-39.999826334290439, -8.238778814467892],
              [-39.999826338952353, -8.238778743532022],
              [-39.99982634417379, -8.23877867263492],
              [-39.999826349954461, -8.238778601780833],
              [-39.999826356293966, -8.238778530974272],
              [-39.999826363191929, -8.23877846021942],
              [-39.999826370647952, -8.238778389520876],
              [-39.999826378661531, -8.238778318882797],
              [-39.999826387232197, -8.238778248309725],
              [-39.99982639635941, -8.238778177805894],
              [-39.999826406042622, -8.238778107375753],
              [-39.999826416281223, -8.238778037023639],
              [-39.999826427074566, -8.238777966753792],
              [-39.999826438422005, -8.238777896570642],
              [-39.999826450322843, -8.238777826478563],
              [-39.999826462776319, -8.238777756481786],
              [-39.999826475781681, -8.238777686584745],
              [-39.999826489338133, -8.238777616791598],
              [-39.999826503444822, -8.238777547106883],
              [-39.999826518100889, -8.238777477534667],
              [-39.999826533305423, -8.23877740807937],
              [-39.999826549057452, -8.238777338745351],
              [-39.999826565356088, -8.238777269536785],
              [-39.999826582200228, -8.238777200457974],
              [-39.999826599588886, -8.238777131513217],
              [-39.999826617520981, -8.238777062706651],
              [-39.999826635995376, -8.238776994042706],
              [-39.999826655010978, -8.238776925525469],
              [-39.999826674566592, -8.23877685715938],
              [-39.999826694660996, -8.23877678894841],
              [-39.999826715292954, -8.238776720896896],
              [-39.999826736461195, -8.238776653009138],
              [-39.999826758164424, -8.238776585289214],
              [-39.999826780401271, -8.23877651774117],
              [-39.999826803170393, -8.23877645036951],
              [-39.999826826470361, -8.238776383178145],
              [-39.99982685029974, -8.238776316171235],
              [-39.999826874657074, -8.238776249353121],
              [-39.999826899540835, -8.238776182727655],
              [-39.999826924949502, -8.238776116299245],
              [-39.999826950881499, -8.238776050071751],
              [-39.999826977335239, -8.238775984049353],
              [-39.99982700430904, -8.238775918236117],
              [-39.999827031801289, -8.238775852636191],
              [-39.999827059810258, -8.238775787253587],
              [-39.999827088334229, -8.238775722092269],
              [-39.999827117371431, -8.238775657156317],
              [-39.999827146920083, -8.23877559244975],
              [-39.999827176978357, -8.23877552797647],
              [-39.999827207544385, -8.238775463740607],
              [-39.99982723861627, -8.238775399746162],
              [-39.999827270192121, -8.238775335996886],
              [-39.999827302269978, -8.238775272496808],
              [-39.999827334847829, -8.238775209249978],
              [-39.999827367923714, -8.238775146260137],
              [-39.999827401495573, -8.238775083531218],
              [-39.999827435561293, -8.238775021067068],
              [-39.999827470118795, -8.238774958871666],
              [-39.999827505165953, -8.238774896948808],
              [-39.999827540700615, -8.238774835302204],
              [-39.999827576720548, -8.238774773935837],
              [-39.999827613223538, -8.23877471285337],
              [-39.999827650207358, -8.238774652058531],
              [-39.999827687669679, -8.238774591555279],
              [-39.999827725607709, -8.238774531348493],
              [-39.999827714121579, -8.238774500757698],
              [-40.000713547081034, -8.237381030815781],
              [-40.000713512627314, -8.237380983439797],
              [-40.000713600206588, -8.237380842827081],
              [-40.000713685188693, -8.237380700641472],
              [-40.000713767545243, -8.237380556930777],
              [-40.000713847248626, -8.237380411742935],
              [-40.000713924272155, -8.237380265126733],
              [-40.000713998590086, -8.237380117131089],
              [-40.000714070177487, -8.237379967805627],
              [-40.000714139010427, -8.237379817200329],
              [-40.000714205065826, -8.237379665365619],
              [-40.000714268321616, -8.237379512352385],
              [-40.000714328756601, -8.237379358211694],
              [-40.000714386350531, -8.237379202995305],
              [-40.000714441084135, -8.23737904675513],
              [-40.00071449293911, -8.237378889543374],
              [-40.00071454189807, -8.237378731412905],
              [-40.000714587944621, -8.237378572416379],
              [-40.000714631063367, -8.237378412607331],
              [-40.000714671239855, -8.237378252039024],
              [-40.000714708460649, -8.237378090765331],
              [-40.000714742713278, -8.237377928840141],
              [-40.000714773986289, -8.237377766317714],
              [-40.000714802269201, -8.237377603252586],
              [-40.000714827552514, -8.237377439699156],
              [-40.000714849827837, -8.237377275712301],
              [-40.000714869087652, -8.237377111346786],
              [-40.000714885325543, -8.237376946657903],
              [-40.00071489853606, -8.23737678170049],
              [-40.000714908714777, -8.237376616529904],
              [-40.000714915858296, -8.237376451201461],
              [-40.000714919964203, -8.237376285770534],
              [-40.000714921031154, -8.237376120292369],
              [-40.000714919058773, -8.237375954822502],
              [-40.000714914047741, -8.237375789416278],
              [-40.000714905999715, -8.237375624129163],
              [-40.000714894917373, -8.237375459016285],
              [-40.000714880804452, -8.237375294133079],
              [-40.000714863665692, -8.237375129534719],
              [-40.000714843506806, -8.237374965276341],
              [-40.000714820334551, -8.237374801412836],
              [-40.000714794156679, -8.237374637999181],
              [-40.000714764981943, -8.237374475089974],
              [-40.000714732820143, -8.237374312739899],
              [-40.000714697682014, -8.237374151003095],
              [-40.000714659579337, -8.237373989933845],
              [-40.00071461852486, -8.237373829586138],
              [-40.000714574532346, -8.237373670013525],
              [-40.000714527616481, -8.237373511269499],
              [-40.000714477793025, -8.237373353407193],
              [-40.00071442507862, -8.237373196479485],
              [-40.000714369490929, -8.237373040538868],
              [-40.000714311048554, -8.237372885637473],
              [-40.000714249771079, -8.237372731827371],
              [-40.000714185678994, -8.237372579159754],
              [-40.000714118793773, -8.23737242768607],
              [-40.000714049137784, -8.237372277456799],
              [-40.000713976734389, -8.237372128522336],
              [-40.000713901607803, -8.237371980932492],
              [-40.00071382378318, -8.237371834736736],
              [-40.000713743286553, -8.237371689983917],
              [-40.00071366014491, -8.237371546722562],
              [-40.000713574386062, -8.237371405000548],
              [-40.00071348603872, -8.237371264865512],
              [-40.000713395132465, -8.237371126364195],
              [-40.000713301697743, -8.237370989543033],
              [-40.000713205765805, -8.237370854447866],
              [-40.000713107368803, -8.237370721123785],
              [-40.000713006539669, -8.237370589615596],
              [-40.000712903312127, -8.237370459967154],
              [-40.000712797720766, -8.237370332222049],
              [-40.000712689800949, -8.237370206422924],
              [-40.000712579588757, -8.237370082611962],
              [-40.000712467121147, -8.237369960830517],
              [-40.000712352435734, -8.237369841119378],
              [-40.000712235570916, -8.237369723518762],
              [-40.000712116565829, -8.237369608067858],
              [-40.000711995460314, -8.237369494805552],
              [-40.000711872294893, -8.237369383769506],
              [-40.000711747110834, -8.237369274996983],
              [-40.000711619950032, -8.237369168524346],
              [-40.000711490855032, -8.23736906438746],
              [-40.000711359869115, -8.237368962620971],
              [-40.000711227036071, -8.237368863259016],
              [-40.000711092400401, -8.237368766334807],
              [-40.000710956007161, -8.237368671880924],
              [-40.000710817902032, -8.237368579928866],
              [-40.000710678131227, -8.237368490509505],
              [-40.000710536741579, -8.237368403652662],
              [-40.000710393780402, -8.237368319387539],
              [-40.000710249295544, -8.237368237742324],
              [-40.000710103335386, -8.237368158744273],
              [-40.000709955948786, -8.237368082419882],
              [-40.000709807185096, -8.237368008794725],
              [-40.00070965709412, -8.237367937893316],
              [-40.000709505726114, -8.237367869739634],
              [-40.000709353131739, -8.237367804356319],
              [-40.000709199362085, -8.237367741765325],
              [-40.000709044468643, -8.237367681987497],
              [-40.00070888850324, -8.237367625043072],
              [-40.000708731518117, -8.237367570950893],
              [-40.000708701651405, -8.237367567106741],
              [-39.998908830841032, -8.236765729842512],
              [-39.998908869526282, -8.236765698962587],
              [-39.998908773751012, -8.236765666342283],
              [-39.998908678346794, -8.236765632660159],
              [-39.998908583325431, -8.236765597920575],
              [-39.998908488698774, -8.236765562127685],
              [-39.998908394478555, -8.236765525285966],
              [-39.998908300676533, -8.236765487400131],
              [-39.998908207304297, -8.236765448474797],
              [-39.998908114373528, -8.236765408514776],
              [-39.998908021895716, -8.236765367525061],
              [-39.998907929882392, -8.236765325510746],
              [-39.998907838344984, -8.236765282477075],
              [-39.998907747294872, -8.236765238429427],
              [-39.998907656743398, -8.236765193373182],
              [-39.998907566701767, -8.236765147313973],
              [-39.998907477181199, -8.2367651002576],
              [-39.998907388192819, -8.236765052209861],
              [-39.998907299747721, -8.236765003176663],
              [-39.998907211856839, -8.236764953164206],
              [-39.998907124531144, -8.236764902178605],
              [-39.998907037781478, -8.236764850226258],
              [-39.998906951618608, -8.236764797313713],
              [-39.998906866053261, -8.236764743447374],
              [-39.998906781096082, -8.236764688633992],
              [-39.998906696757622, -8.23676463288048],
              [-39.998906613048355, -8.236764576193751],
              [-39.998906529978683, -8.236764518580667],
              [-39.998906447558959, -8.236764460048557],
              [-39.998906365799421, -8.236764400604759],
              [-39.998906284710209, -8.236764340256419],
              [-39.998906204301427, -8.23676427901129],
              [-39.99890612458308, -8.236764216876777],
              [-39.998906045565029, -8.23676415386079],
              [-39.998905967257137, -8.236764089970986],
              [-39.998905889669118, -8.236764025215431],
              [-39.998905812810634, -8.236763959602211],
              [-39.998905736691242, -8.236763893139349],
              [-39.998905661320379, -8.236763825835135],
              [-39.99890558670743, -8.23676375769799],
              [-39.998905512861676, -8.236763688736378],
              [-39.998905439792267, -8.236763618958852],
              [-39.998905367508343, -8.236763548374084],
              [-39.99890529601884, -8.236763476990909],
              [-39.998905225332628, -8.236763404818086],
              [-39.998905155458559, -8.236763331864637],
              [-39.998905086405252, -8.236763258139655],
              [-39.998905018181347, -8.236763183652281],
              [-39.998904950795293, -8.23676310841171],
              [-39.998904884255474, -8.236763032427504],
              [-39.998904818570168, -8.236762955708883],
              [-39.998904753747524, -8.236762878265486],
              [-39.998904689795616, -8.23676280010695],
              [-39.998904626722378, -8.236762721242886],
              [-39.998904564535671, -8.23676264168329],
              [-39.998904503243189, -8.236762561437908],
              [-39.998904442852592, -8.236762480516679],
              [-39.998904383371361, -8.236762398929823],
              [-39.998904324806894, -8.236762316687299],
              [-39.998904267166481, -8.236762233799464],
              [-39.99890421045729, -8.236762150276592],
              [-39.998904154686336, -8.236762066128925],
              [-39.998904099860603, -8.236761981367195],
              [-39.998904045986869, -8.236761896001662],
              [-39.998903993071856, -8.236761810043157],
              [-39.998903941122101, -8.236761723502239],
              [-39.998903890144121, -8.236761636389678],
              [-39.998903840144209, -8.236761548716391],
              [-39.998903791128569, -8.236761460493211],
              [-39.998903743103327, -8.236761371731072],
              [-39.998903696074471, -8.236761282441076],
              [-39.998903650047787, -8.23676119263428],
              [-39.998903605029028, -8.23676110232182],
              [-39.998903561023788, -8.236761011514901],
              [-39.998903518037558, -8.23676092022488],
              [-39.998903476075647, -8.236760828463082],
              [-39.998903435143269, -8.236760736241015],
              [-39.99890339524557, -8.236760643570038],
              [-39.998903356387437, -8.236760550461632],
              [-39.998903318573724, -8.236760456927355],
              [-39.998903281809177, -8.236760362978982],
              [-39.998903246098322, -8.236760268628117],
              [-39.998903211445572, -8.236760173886452],
              [-39.99890317785529, -8.23676007876584],
              [-39.998903145331631, -8.23675998327799],
              [-39.998903113878612, -8.236759887434856],
              [-39.998903083500174, -8.236759791248321],
              [-39.998903054200113, -8.236759694730372],
              [-39.998903025982024, -8.236759597893007],
              [-39.998902998849431, -8.236759500748249],
              [-39.998902972805716, -8.236759403308115],
              [-39.998902947854113, -8.236759305584792],
              [-39.998902923997704, -8.236759207590406],
              [-39.998902901239489, -8.236759109337161],
              [-39.998902879582282, -8.236759010837263],
              [-39.99890285902876, -8.236758912102902],
              [-39.998902839581483, -8.236758813146469],
              [-39.998902821242886, -8.23675871398007],
              [-39.998902804015223, -8.23675861461629],
              [-39.99890278790069, -8.236758515067208],
              [-39.99890277290141, -8.236758415345006],
              [-39.998902729918044, -8.236758419255278],
              [-39.998552128180563, -8.234335185256263],
              [-39.998552137597159, -8.23433514316727],
              [-39.998552120683144, -8.234335031236743],
              [-39.998552102364293, -8.234334919525791],
              [-39.998552082643535, -8.234334808051997],
              [-39.99855206152386, -8.234334696832773],
              [-39.998552039008651, -8.234334585885712],
              [-39.998552015101417, -8.234334475227993],
              [-39.998551989805883, -8.234334364876984],
              [-39.99855196312604, -8.23433425485003],
              [-39.99855193506604, -8.23433414516429],
              [-39.998551905630286, -8.234334035837069],
              [-39.998551874823399, -8.234333926885311],
              [-39.998551842650201, -8.234333818326322],
              [-39.998551809115725, -8.234333710176871],
              [-39.998551774225241, -8.234333602454065],
              [-39.998551737984194, -8.234333495174631],
              [-39.998551700398252, -8.234333388355497],
              [-39.998551661473336, -8.234333282013365],
              [-39.998551621215519, -8.234333176164848],
              [-39.998551579631126, -8.234333070826512],
              [-39.998551536726652, -8.234332966015051],
              [-39.998551492508803, -8.234332861746598],
              [-39.998551446984543, -8.234332758037601],
              [-39.998551400160984, -8.234332654904426],
              [-39.99855135204546, -8.234332552363069],
              [-39.998551302645502, -8.234332450429573],
              [-39.998551251968877, -8.234332349120143],
              [-39.998551200023478, -8.234332248450395],
              [-39.998551146817476, -8.234332148436145],
              [-39.998551092359179, -8.234332049093174],
              [-39.998551036657148, -8.234331950436935],
              [-39.998550979720093, -8.234331852482896],
              [-39.998550921556919, -8.234331755246378],
              [-39.998550862176771, -8.234331658742756],
              [-39.998550801588891, -8.234331562987002],
              [-39.998550739802837, -8.234331467994146],
              [-39.998550676828252, -8.234331373779098],
              [-39.99855061267499, -8.234331280356567],
              [-39.998550547353133, -8.234331187741221],
              [-39.998550480872886, -8.234331095947628],
              [-39.998550413244672, -8.234331004990034],
              [-39.998550344479078, -8.234330914882692],
              [-39.99855027458689, -8.234330825639859],
              [-39.998550203579036, -8.234330737275458],
              [-39.998550131466658, -8.234330649803217],
              [-39.998550058261024, -8.234330563237028],
              [-39.998549983973632, -8.234330477590227],
              [-39.998549908616113, -8.234330392876469],
              [-39.998549832200233, -8.23433030910882],
              [-39.998549754738015, -8.234330226300459],
              [-39.998549676241559, -8.234330144464478],
              [-39.998549596723187, -8.234330063613491],
              [-39.998549516195325, -8.234329983760315],
              [-39.998549434670608, -8.234329904917411],
              [-39.998549352161781, -8.234329827097147],
              [-39.998549268681799, -8.234329750311673],
              [-39.998549184243707, -8.234329674573099],
              [-39.998549098860771, -8.234329599893204],
              [-39.99854901254632, -8.234329526283659],
              [-39.998548925313905, -8.234329453756095],
              [-39.998548837177189, -8.234329382321839],
              [-39.998548748149943, -8.23432931199202],
              [-39.998548658246165, -8.23432924277771],
              [-39.998548567479894, -8.23432917468973],
              [-39.998548475865377, -8.234329107738811],
              [-39.998548383416939, -8.234329041935405],
              [-39.998548290149074, -8.234328977289708],
              [-39.998548196076392, -8.23432891381194],
              [-39.998548101213643, -8.234328851512073],
              [-39.998548005575664, -8.234328790399822],
              [-39.998547909177418, -8.234328730484833],
              [-39.998547812034047, -8.234328671776357],
              [-39.998547714160736, -8.234328614283719],
              [-39.998547615572853, -8.234328558015777],
              [-39.998547516285782, -8.234328502981489],
              [-39.998547416315134, -8.234328449189453],
              [-39.998547315676532, -8.234328396648008],
              [-39.998547214385752, -8.234328345365501],
              [-39.998547112458674, -8.23432829534983],
              [-39.998547009911221, -8.234328246608957],
              [-39.998546906759501, -8.234328199150402],
              [-39.998546803019643, -8.234328152981725],
              [-39.998546698707912, -8.234328108110066],
              [-39.998546593840636, -8.234328064542487],
              [-39.998546488434236, -8.234328022285752],
              [-39.998546382505268, -8.234327981346595],
              [-39.998546276070272, -8.234327941731314],
              [-39.998546169145918, -8.234327903446193],
              [-39.998546061749011, -8.234327866497264],
              [-39.998545953896311, -8.23432783089015],
              [-39.998545845604738, -8.234327796630627],
              [-39.998545736891259, -8.234327763723881],
              [-39.998545627772913, -8.234327732175204],
              [-39.998545518266759, -8.234327701989452],
              [-39.998545408389958, -8.234327673171393],
              [-39.998545298159748, -8.234327645725539],
              [-39.998545187593386, -8.234327619656169],
              [-39.998545076708155, -8.234327594967413],
              [-39.998544965521475, -8.234327571663083],
              [-39.998544854050721, -8.234327549746832],
              [-39.998544846209434, -8.234327515828255],
              [-39.996563895436445, -8.233950863731049],
              [-39.99656391446279, -8.233950887380443],
              [-39.996563892371675, -8.233950883151602],
              [-39.996563870291148, -8.23395087886832],
              [-39.996563848221349, -8.23395087453043],
              [-39.996563826162401, -8.23395087013817],
              [-39.996563804114452, -8.233950865691469],
              [-39.996563782077629, -8.233950861190344],
              [-39.996563760052069, -8.233950856634761],
              [-39.996563738037906, -8.23395085202489],
              [-39.996563716035268, -8.233950847360731],
              [-39.996563694044305, -8.233950842642178],
              [-39.996563672065164, -8.23395083786942],
              [-39.996563650097947, -8.233950833042369],
              [-39.996563628142788, -8.233950828161115],
              [-39.996563606199857, -8.233950823225738],
              [-39.996563584269275, -8.233950818236169],
              [-39.996563562351142, -8.23395081319241],
              [-39.996563540445649, -8.233950808094677],
              [-39.99656351855289, -8.233950802942859],
              [-39.996563496673019, -8.233950797737013],
              [-39.996563474806173, -8.233950792477126],
              [-39.996563452952451, -8.2339507871633],
              [-39.996563431112023, -8.233950781795553],
              [-39.99656340928501, -8.23395077637386],
              [-39.996563387471554, -8.233950770898435],
              [-39.996563365671804, -8.233950765369046],
              [-39.996563343885825, -8.233950759785902],
              [-39.99656332211385, -8.233950754149003],
              [-39.996563300355938, -8.23395074845836],
              [-39.996563278612257, -8.233950742714034],
              [-39.996563256882915, -8.233950736916048],
              [-39.996563235168075, -8.233950731064491],
              [-39.996563213467844, -8.233950725159257],
              [-39.996563191782379, -8.233950719200571],
              [-39.99656317011182, -8.233950713188264],
              [-39.996563148456239, -8.23395070712256],
              [-39.996563126815836, -8.233950701003373],
              [-39.99656310519071, -8.233950694830792],
              [-39.996563083581002, -8.233950688604908],
              [-39.996563061986862, -8.233950682325615],
              [-39.996563040408397, -8.233950675993121],
              [-39.996563018845748, -8.233950669607301],
              [-39.996562997299044, -8.233950663168363],
              [-39.996562975768434, -8.233950656676194],
              [-39.996562954254024, -8.233950650130911],
              [-39.996562932755957, -8.233950643532486],
              [-39.996562911274388, -8.233950636881023],
              [-39.996562889809425, -8.233950630176604],
              [-39.996562868361188, -8.233950623419249],
              [-39.996562846929841, -8.233950616608857],
              [-39.99656282551549, -8.233950609745689],
              [-39.996562804118263, -8.233950602829653],
              [-39.996562782738337, -8.233950595860779],
              [-39.996562761375777, -8.23395058883918],
              [-39.996562740030775, -8.233950581764848],
              [-39.996562718703416, -8.233950574637827],
              [-39.996562697393848, -8.233950567458235],
              [-39.996562676102194, -8.233950560226095],
              [-39.996562654828615, -8.233950552941309],
              [-39.99656263357322, -8.233950545604092],
              [-39.99656261233612, -8.233950538214504],
              [-39.996562591117495, -8.233950530772359],
              [-39.996562569917437, -8.233950523277915],
              [-39.996562548736058, -8.233950515731252],
              [-39.996562527573538, -8.233950508132265],
              [-39.996562506429974, -8.233950500481033],
              [-39.996562485305517, -8.233950492777675],
              [-39.996562464200281, -8.23395048502217],
              [-39.996562443114392, -8.233950477214506],
              [-39.996562422047994, -8.2339504693549],
              [-39.996562401001206, -8.233950461443335],
              [-39.996562379974165, -8.233950453479787],
              [-39.996562358966997, -8.233950445464393],
              [-39.996562337979832, -8.233950437397194],
              [-39.996562317012803, -8.233950429278124],
              [-39.996562296066017, -8.233950421107393],
              [-39.99656227513961, -8.233950412885044],
              [-39.996562254233737, -8.233950404610891],
              [-39.996562233348506, -8.233950396285223],
              [-39.996562212484044, -8.23395038790806],
              [-39.9965621916405, -8.233950379479476],
              [-39.996562170817967, -8.233950370999361],
              [-39.996562150016587, -8.233950362467848],
              [-39.99656212923648, -8.233950353885083],
              [-39.996562108477789, -8.233950345251074],
              [-39.996562087740649, -8.233950336565814],
              [-39.996562067025181, -8.233950327829326],
              [-39.996562046331512, -8.233950319041856],
              [-39.996562025659742, -8.233950310203218],
              [-39.996562005010027, -8.233950301313635],
              [-39.996561984382481, -8.233950292373057],
              [-39.996561963777253, -8.233950283381597],
              [-39.996561943194429, -8.233950274339362],
              [-39.996561922634172, -8.233950265246342],
              [-39.996561902096595, -8.23395025610248],
              [-39.996561881581833, -8.233950246908137],
              [-39.996561861089987, -8.233950237662985],
              [-39.996561840621204, -8.2339502283674],
              [-39.996561820175607, -8.23395021902129],
              [-39.996561799753174, -8.233950209625563],
              [-39.996561805825287, -8.233950235825283],
              [-39.995601924729698, -8.233507101070412],
              [-39.995601943028483, -8.233507127501831],
              [-39.995601906681749, -8.233507110820511],
              [-39.995601870261588, -8.233507094298977],
              [-39.995601833768674, -8.233507077937457],
              [-39.995601797203705, -8.233507061736473],
              [-39.995601760567396, -8.233507045696205],
              [-39.995601723860503, -8.233507029816895],
              [-39.995601687083685, -8.233507014099024],
              [-39.995601650237681, -8.233506998542778],
              [-39.995601613323196, -8.233506983148368],
              [-39.995601576340974, -8.23350696791641],
              [-39.995601539291684, -8.233506952846856],
              [-39.995601502176086, -8.23350693794027],
              [-39.995601464994898, -8.23350692319673],
              [-39.995601427748831, -8.233506908616706],
              [-39.995601390438601, -8.233506894200247],
              [-39.995601353064963, -8.233506879947825],
              [-39.995601315628591, -8.233506865859706],
              [-39.995601278130266, -8.233506851936088],
              [-39.995601240570707, -8.233506838177309],
              [-39.995601202950631, -8.23350682458357],
              [-39.99560116527077, -8.23350681115517],
              [-39.995601127531849, -8.23350679789238],
              [-39.99560108973462, -8.233506784795495],
              [-39.99560105187981, -8.233506771864624],
              [-39.995601013968141, -8.23350675910021],
              [-39.995600976000361, -8.233506746502343],
              [-39.995600937977215, -8.233506734071405],
              [-39.995600899899429, -8.233506721807547],
              [-39.995600861767741, -8.233506709710948],
              [-39.995600823582912, -8.233506697782],
              [-39.995600785345665, -8.233506686020753],
              [-39.995600747056741, -8.233506674427582],
              [-39.995600708716914, -8.233506663002768],
              [-39.995600670326894, -8.233506651746296],
              [-39.99560063188742, -8.233506640658547],
              [-39.99560059339931, -8.233506629739749],
              [-39.995600554863231, -8.233506618990114],
              [-39.995600516279936, -8.233506608409719],
              [-39.995600477650221, -8.233506597998923],
              [-39.995600438974847, -8.233506587757898],
              [-39.995600400254496, -8.233506577686761],
              [-39.995600361489984, -8.233506567785755],
              [-39.995600322682016, -8.233506558055074],
              [-39.995600283831379, -8.233506548494896],
              [-39.995600244938807, -8.233506539105466],
              [-39.995600206005079, -8.233506529886911],
              [-39.995600167030943, -8.233506520839375],
              [-39.995600128017152, -8.233506511963142],
              [-39.995600088964444, -8.233506503258214],
              [-39.99560004987363, -8.233506494724955],
              [-39.995600010745413, -8.233506486363432],
              [-39.995599971580596, -8.233506478173847],
              [-39.995599932379896, -8.233506470156282],
              [-39.995599893144146, -8.233506462311004],
              [-39.995599853874033, -8.233506454638013],
              [-39.995599814570348, -8.233506447137572],
              [-39.99559977523387, -8.233506439809881],
              [-39.995599735865341, -8.233506432654893],
              [-39.99559969646554, -8.233506425672925],
              [-39.995599657035228, -8.233506418864039],
              [-39.995599617575166, -8.233506412228337],
              [-39.995599578086114, -8.233506405766088],
              [-39.995599538568882, -8.233506399477285],
              [-39.995599499024188, -8.233506393361997],
              [-39.995599459452841, -8.233506387420462],
              [-39.995599419855552, -8.233506381652772],
              [-39.995599380233159, -8.233506376058994],
              [-39.995599340586395, -8.233506370639278],
              [-39.995599300916027, -8.233506365393827],
              [-39.995599261222836, -8.23350636032252],
              [-39.99559922150759, -8.233506355425641],
              [-39.995599181771063, -8.233506350703101],
              [-39.995599142014029, -8.233506346155236],
              [-39.995599102237243, -8.233506341781984],
              [-39.995599062441507, -8.233506337583334],
              [-39.995599022627587, -8.233506333559641],
              [-39.995598982796245, -8.233506329710718],
              [-39.995598942948263, -8.233506326036849],
              [-39.995598903084414, -8.233506322537831],
              [-39.995598863205451, -8.233506319214049],
              [-39.995598823312172, -8.233506316065382],
              [-39.995598783405363, -8.233506313091947],
              [-39.995598743485779, -8.233506310293814],
              [-39.995598703554187, -8.233506307670991],
              [-39.99559866361141, -8.233506305223541],
              [-39.995598623658168, -8.233506302951509],
              [-39.995598583695248, -8.233506300855044],
              [-39.995598543723467, -8.233506298934024],
              [-39.995598503743558, -8.23350629718851],
              [-39.995598463756316, -8.233506295618652],
              [-39.995598423762516, -8.233506294224357],
              [-39.995598383762946, -8.2335062930057],
              [-39.995598343758381, -8.233506291962792],
              [-39.99559830374956, -8.233506291095447],
              [-39.9955982637373, -8.233506290403913],
              [-39.995598223722375, -8.233506289888027],
              [-39.995598183705567, -8.233506289547877],
              [-39.99559814368763, -8.233506289383453],
              [-39.995598103669352, -8.233506289394855],
              [-39.995598109778683, -8.233506296553156],
              [-39.993038914702332, -8.233512633115389],
              [-39.993038964823896, -8.233512625817999],
              [-39.993038900724677, -8.233512625751031],
              [-39.99303883662752, -8.23351262523332],
              [-39.993038772535634, -8.233512624264632],
              [-39.993038708452218, -8.23351262284519],
              [-39.993038644380448, -8.23351262097502],
              [-39.993038580323535, -8.233512618654254],
              [-39.993038516284663, -8.233512615882956],
              [-39.993038452267044, -8.233512612661281],
              [-39.993038388273831, -8.233512608989455],
              [-39.993038324308259, -8.233512604867485],
              [-39.99303826037346, -8.23351260029577],
              [-39.993038196472703, -8.233512595274396],
              [-39.993038132609072, -8.233512589803702],
              [-39.993038068785836, -8.233512583883975],
              [-39.993038005006149, -8.233512577515466],
              [-39.993037941273201, -8.23351257069838],
              [-39.993037877590133, -8.233512563433273],
              [-39.993037813960157, -8.233512555720401],
              [-39.993037750386421, -8.233512547560077],
              [-39.993037686872107, -8.233512538952848],
              [-39.993037623420364, -8.233512529899116],
              [-39.993037560034395, -8.23351252039925],
              [-39.993037496717328, -8.233512510453723],
              [-39.993037433472324, -8.2335125000631],
              [-39.993037370302524, -8.233512489227875],
              [-39.993037307211097, -8.233512477948581],
              [-39.99303724420119, -8.233512466225848],
              [-39.993037181275945, -8.233512454060161],
              [-39.99303711843848, -8.233512441452115],
              [-39.993037055691936, -8.233512428402461],
              [-39.99303699303946, -8.233512414911784],
              [-39.993036930484131, -8.233512400980675],
              [-39.993036868029115, -8.233512386609977],
              [-39.993036805677512, -8.233512371800286],
              [-39.993036743432405, -8.233512356552382],
              [-39.993036681296935, -8.233512340867058],
              [-39.993036619274172, -8.233512324745039],
              [-39.993036557367205, -8.233512308187196],
              [-39.993036495579155, -8.23351229119428],
              [-39.993036433913048, -8.233512273767246],
              [-39.993036372372018, -8.233512255906842],
              [-39.993036310959106, -8.233512237614022],
              [-39.993036249677345, -8.233512218889629],
              [-39.99303618852985, -8.23351219973468],
              [-39.993036127519616, -8.23351218015015],
              [-39.993036066649701, -8.233512160136952],
              [-39.993036005923145, -8.233512139696082],
              [-39.993035945343003, -8.23351211882856],
              [-39.993035884912231, -8.233512097535442],
              [-39.993035824633893, -8.23351207581776],
              [-39.993035764510978, -8.23351205367671],
              [-39.993035704546479, -8.233512031113175],
              [-39.993035644743401, -8.233512008128514],
              [-39.993035585104693, -8.233511984723776],
              [-39.993035525633353, -8.233511960900097],
              [-39.993035466332337, -8.233511936658759],
              [-39.993035407204616, -8.233511912000793],
              [-39.99303534825313, -8.233511886927644],
              [-39.993035289480808, -8.233511861440434],
              [-39.993035230890605, -8.233511835540479],
              [-39.993035172485399, -8.233511809228975],
              [-39.993035114268146, -8.233511782507328],
              [-39.993035056241709, -8.233511755376862],
              [-39.99303499840903, -8.233511727838936],
              [-39.993034940772944, -8.233511699894899],
              [-39.99303488333635, -8.23351167154617],
              [-39.993034826102097, -8.233511642794133],
              [-39.993034769073049, -8.233511613640202],
              [-39.993034712252047, -8.233511584085912],
              [-39.993034655641921, -8.233511554132612],
              [-39.993034599245497, -8.233511523781935],
              [-39.993034543065583, -8.23351149303522],
              [-39.993034487104978, -8.23351146189418],
              [-39.993034431366482, -8.23351143036035],
              [-39.993034375852844, -8.233511398435157],
              [-39.993034320566892, -8.23351136612032],
              [-39.993034265511319, -8.233511333417406],
              [-39.993034210688897, -8.233511300328042],
              [-39.99303415610234, -8.233511266853837],
              [-39.993034101754439, -8.233511232996566],
              [-39.993034047647797, -8.233511198757846],
              [-39.99303399378519, -8.233511164139387],
              [-39.993033940169283, -8.233511129142999],
              [-39.993033886802721, -8.233511093770211],
              [-39.993033833688202, -8.233511058023019],
              [-39.993033780828355, -8.233511021903103],
              [-39.993033728225825, -8.233510985412293],
              [-39.993033675883211, -8.233510948552384],
              [-39.993033623803164, -8.233510911325256],
              [-39.993033571988249, -8.233510873732639],
              [-39.993033520441053, -8.233510835776512],
              [-39.99303346916416, -8.233510797458839],
              [-39.993033418160095, -8.233510758781371],
              [-39.993033367431437, -8.233510719746151],
              [-39.993033316980686, -8.23351068035498],
              [-39.9930332668104, -8.233510640610044],
              [-39.993033216923017, -8.23351060051313],
              [-39.993033167321066, -8.233510560066325],
              [-39.993033118007418, -8.233510519270618],
              [-39.993033099354541, -8.233510567335134],
              [-39.990796532090599, -8.231646930575945],
              [-39.990796519345118, -8.231646963636139],
              [-39.990796482017224, -8.231646932702576],
              [-39.990796444523802, -8.231646901968256],
              [-39.990796406865883, -8.231646871434235],
              [-39.99079636904461, -8.231646841101218],
              [-39.990796331061006, -8.231646810970201],
              [-39.990796292916201, -8.231646781041986],
              [-39.990796254611269, -8.231646751317479],
              [-39.990796216147331, -8.231646721797473],
              [-39.990796177525425, -8.231646692482839],
              [-39.99079613874671, -8.231646663374383],
              [-39.990796099812258, -8.231646634472906],
              [-39.990796060723234, -8.231646605779391],
              [-39.990796021480719, -8.231646577294507],
              [-39.990795982085835, -8.231646549019125],
              [-39.990795942539727, -8.231646520954016],
              [-39.990795902843523, -8.231646493100106],
              [-39.990795862998326, -8.231646465458013],
              [-39.990795823005307, -8.231646438028633],
              [-39.990795782865604, -8.231646410812816],
              [-39.990795742580381, -8.231646383811118],
              [-39.990795702150749, -8.231646357024596],
              [-39.99079566157792, -8.231646330453772],
              [-39.990795620863018, -8.231646304099632],
              [-39.990795580007202, -8.231646277962716],
              [-39.99079553901165, -8.231646252043832],
              [-39.990795497877535, -8.231646226343697],
              [-39.990795456606051, -8.231646200863237],
              [-39.990795415198356, -8.231646175603002],
              [-39.990795373655658, -8.231646150563733],
              [-39.990795331979101, -8.231646125746202],
              [-39.990795290169913, -8.231646101151048],
              [-39.99079524822929, -8.231646076779072],
              [-39.990795206158438, -8.231646052630865],
              [-39.990795163958531, -8.231646028707111],
              [-39.990795121630782, -8.231646005008599],
              [-39.990795079176422, -8.231645981535973],
              [-39.990795036596658, -8.231645958289926],
              [-39.990794993892713, -8.231645935271018],
              [-39.990794951065773, -8.231645912479959],
              [-39.990794908117117, -8.231645889917477],
              [-39.990794865047931, -8.23164586758411],
              [-39.990794821859474, -8.231645845480502],
              [-39.99079477855296, -8.231645823607314],
              [-39.990794735129647, -8.231645801965259],
              [-39.990794691590771, -8.231645780554931],
              [-39.990794647937562, -8.231645759376779],
              [-39.990794604171285, -8.231645738431579],
              [-39.990794560293196, -8.23164571771982],
              [-39.99079451630454, -8.231645697242261],
              [-39.990794472206566, -8.231645676999245],
              [-39.990794428000584, -8.231645656991482],
              [-39.990794383687785, -8.231645637219595],
              [-39.990794339269478, -8.23164561768411],
              [-39.990794294746934, -8.231645598385555],
              [-39.990794250121411, -8.231645579324498],
              [-39.990794205394216, -8.231645560501454],
              [-39.990794160566594, -8.231645541917066],
              [-39.990794115639844, -8.23164552357176],
              [-39.990794070615252, -8.231645505466052],
              [-39.99079402549409, -8.231645487600563],
              [-39.99079398027768, -8.231645469975705],
              [-39.990793934967293, -8.23164545259203],
              [-39.990793889564223, -8.231645435449986],
              [-39.990793844069778, -8.231645418550157],
              [-39.990793798485257, -8.231645401892953],
              [-39.990793752811953, -8.231645385478899],
              [-39.990793707051189, -8.231645369308337],
              [-39.990793661204265, -8.231645353381921],
              [-39.990793615272494, -8.231645337699979],
              [-39.9907935692572, -8.231645322263066],
              [-39.990793523159695, -8.231645307071469],
              [-39.990793476981281, -8.231645292125702],
              [-39.990793430723294, -8.231645277426233],
              [-39.990793384387032, -8.2316452629734],
              [-39.990793337973884, -8.231645248767768],
              [-39.990793291485097, -8.23164523480947],
              [-39.990793244922045, -8.231645221099161],
              [-39.99079319828607, -8.231645207637145],
              [-39.990793151578487, -8.231645194423811],
              [-39.99079310480063, -8.23164518145947],
              [-39.990793057953837, -8.231645168744656],
              [-39.990793011039457, -8.23164515627963],
              [-39.990792964058855, -8.231645144064627],
              [-39.990792917013302, -8.231645132100255],
              [-39.990792869904219, -8.231645120386604],
              [-39.990792822732935, -8.2316451089242],
              [-39.990792775500765, -8.231645097713287],
              [-39.990792728209094, -8.231645086754117],
              [-39.990792680859272, -8.231645076047174],
              [-39.990792633452621, -8.231645065592691],
              [-39.99079258599054, -8.231645055390915],
              [-39.990792538474381, -8.231645045442086],
              [-39.990792490905477, -8.231645035746627],
              [-39.990792443285208, -8.2316450263048],
              [-39.990792395614911, -8.231645017116861],
              [-39.99079234789599, -8.231645008182934],
              [-39.99079230012979, -8.231644999503509],
              [-39.990792252317675, -8.231644991078625],
              [-39.990792204461002, -8.23164498290863],
              [-39.990792169539134, -8.231644951616749],
              [-39.989522025340889, -8.231431655269807],
              [-39.989522073678408, -8.23143162850767],
              [-39.989522054620942, -8.231431625285612],
              [-39.989522035570403, -8.231431622023191],
              [-39.989522016526877, -8.231431618720251],
              [-39.989521997490449, -8.231431615377035],
              [-39.989521978461198, -8.231431611993372],
              [-39.989521959439251, -8.231431608569375],
              [-39.989521940424645, -8.231431605105085],
              [-39.989521921417506, -8.231431601600374],
              [-39.989521902417884, -8.231431598055387],
              [-39.989521883425873, -8.231431594470152],
              [-39.989521864441585, -8.23143159084462],
              [-39.989521845465092, -8.231431587178736],
              [-39.989521826496457, -8.231431583472691],
              [-39.989521807535795, -8.23143157972647],
              [-39.989521788583183, -8.231431575939979],
              [-39.989521769638721, -8.231431572113239],
              [-39.989521750702465, -8.231431568246386],
              [-39.989521731774524, -8.231431564339353],
              [-39.989521712854966, -8.231431560392252],
              [-39.989521693943885, -8.231431556404871],
              [-39.989521675041388, -8.231431552377526],
              [-39.989521656147545, -8.231431548310068],
              [-39.989521637262399, -8.231431544202509],
              [-39.989521618386107, -8.231431540054873],
              [-39.98952159951871, -8.231431535867252],
              [-39.989521580660323, -8.23143153163962],
              [-39.989521561810996, -8.231431527372004],
              [-39.989521542970827, -8.231431523064391],
              [-39.989521524139931, -8.23143151871686],
              [-39.989521505318365, -8.231431514329335],
              [-39.989521486506206, -8.231431509901894],
              [-39.989521467703554, -8.231431505434641],
              [-39.989521448910502, -8.231431500927402],
              [-39.98952143012712, -8.231431496380418],
              [-39.9895214113535, -8.231431491793535],
              [-39.989521392589729, -8.231431487166937],
              [-39.989521373835885, -8.231431482500394],
              [-39.989521355092052, -8.231431477794134],
              [-39.989521336358344, -8.231431473048158],
              [-39.989521317634789, -8.231431468262473],
              [-39.989521298921517, -8.231431463437],
              [-39.989521280218611, -8.231431458571947],
              [-39.989521261526136, -8.231431453667119],
              [-39.989521242844184, -8.231431448722772],
              [-39.98952122417284, -8.23143144373878],
              [-39.989521205512204, -8.231431438715134],
              [-39.989521186862355, -8.231431433651945],
              [-39.989521168223334, -8.231431428549211],
              [-39.989521149595284, -8.231431423406926],
              [-39.989521130978268, -8.231431418225192],
              [-39.989521112372373, -8.231431413003914],
              [-39.989521093777668, -8.231431407743221],
              [-39.989521075194247, -8.231431402443095],
              [-39.989521056622209, -8.231431397103623],
              [-39.989521038061611, -8.231431391724655],
              [-39.989521019512566, -8.231431386306422],
              [-39.989521000975138, -8.231431380848811],
              [-39.989520982449413, -8.231431375351834],
              [-39.989520963935476, -8.23143136981566],
              [-39.989520945433419, -8.23143136424012],
              [-39.989520926943321, -8.231431358625485],
              [-39.989520908465266, -8.231431352971573],
              [-39.989520889999334, -8.231431347278479],
              [-39.989520871545601, -8.231431341546152],
              [-39.989520853104175, -8.231431335774847],
              [-39.989520834675133, -8.23143132996436],
              [-39.989520816258533, -8.231431324114732],
              [-39.989520797854496, -8.231431318226123],
              [-39.989520779463056, -8.231431312298499],
              [-39.989520761084371, -8.231431306331844],
              [-39.989520742718433, -8.231431300326276],
              [-39.989520724365399, -8.231431294281716],
              [-39.989520706025324, -8.231431288198221],
              [-39.989520687698295, -8.231431282075887],
              [-39.989520669384383, -8.23143127591465],
              [-39.989520651083694, -8.231431269714573],
              [-39.989520632796278, -8.231431263475733],
              [-39.989520614522242, -8.231431257198077],
              [-39.989520596261691, -8.23143125088168],
              [-39.989520578014655, -8.231431244526638],
              [-39.989520559781248, -8.231431238132764],
              [-39.989520541561554, -8.231431231700313],
              [-39.98952052335563, -8.231431225229219],
              [-39.989520505163604, -8.23143121871956],
              [-39.989520486985526, -8.231431212171239],
              [-39.989520468821475, -8.23143120558446],
              [-39.989520450671549, -8.231431198959122],
              [-39.989520432535819, -8.231431192295299],
              [-39.989520414414379, -8.231431185593005],
              [-39.989520396307299, -8.231431178852327],
              [-39.989520378214671, -8.231431172073279],
              [-39.989520360136574, -8.231431165255831],
              [-39.989520342073092, -8.231431158400008],
              [-39.989520324024291, -8.231431151505895],
              [-39.989520305990283, -8.231431144573564],
              [-39.989520287971111, -8.231431137602966],
              [-39.989520269966903, -8.231431130594151],
              [-39.989520251977702, -8.231431123547226],
              [-39.989520234003656, -8.231431116461453],
              [-39.989520208324947, -8.231431117094788],
              [-39.98824525878284, -8.230926967253289],
              [-39.988245269934303, -8.230926977042731],
              [-39.988245145218272, -8.230926928784552],
              [-39.988245019801894, -8.230926882362777],
              [-39.988244893712441, -8.230926837787317],
              [-39.988244766977274, -8.230926795067944],
              [-39.988244639623929, -8.230926754213803],
              [-39.988244511680058, -8.230926715233926],
              [-39.988244383173459, -8.230926678136663],
              [-39.988244254132042, -8.230926642930168],
              [-39.988244124583829, -8.230926609621989],
              [-39.98824399455696, -8.23092657821943],
              [-39.988243864079671, -8.2309265487293],
              [-39.988243733180326, -8.230926521157997],
              [-39.988243601887312, -8.230926495511444],
              [-39.988243470229186, -8.23092647179527],
              [-39.988243338234525, -8.230926450014744],
              [-39.988243205931987, -8.230926430174392],
              [-39.98824307335034, -8.230926412278693],
              [-39.988242940518361, -8.23092639633135],
              [-39.988242807464886, -8.230926382336017],
              [-39.988242674218846, -8.230926370295585],
              [-39.988242540809139, -8.230926360212726],
              [-39.988242407264778, -8.230926352089718],
              [-39.988242273614759, -8.230926345928149],
              [-39.988242139888115, -8.230926341729553],
              [-39.988242006113865, -8.230926339494653],
              [-39.98824187232109, -8.230926339224093],
              [-39.988241738538854, -8.230926340917812],
              [-39.988241604796173, -8.230926344575474],
              [-39.988241471122151, -8.230926350196329],
              [-39.988241337545787, -8.230926357779184],
              [-39.988241204096106, -8.230926367322237],
              [-39.988241070802076, -8.230926378823582],
              [-39.98824093769268, -8.23092639228064],
              [-39.988240804796774, -8.230926407690532],
              [-39.988240672143284, -8.230926425049827],
              [-39.988240539760973, -8.230926444354765],
              [-39.988240407678632, -8.230926465601295],
              [-39.98824027592493, -8.230926488784718],
              [-39.98824014452849, -8.23092651389989],
              [-39.988240013517824, -8.230926540941454],
              [-39.988239882921427, -8.230926569903589],
              [-39.98823975276764, -8.23092660078],
              [-39.988239623084745, -8.230926633563858],
              [-39.988239493900906, -8.230926668248106],
              [-39.988239365244169, -8.230926704825258],
              [-39.988239237142487, -8.230926743287299],
              [-39.988239109623684, -8.230926783625916],
              [-39.988238982715451, -8.230926825832373],
              [-39.988238856445363, -8.230926869897367],
              [-39.988238730840834, -8.23092691581153],
              [-39.988238605929155, -8.230926963564757],
              [-39.98823848173744, -8.230927013146703],
              [-39.988238358292669, -8.23092706454652],
              [-39.988238235621658, -8.230927117753282],
              [-39.988238113751052, -8.230927172755205],
              [-39.988237992707333, -8.230927229540461],
              [-39.988237872516763, -8.230927288096591],
              [-39.988237753205489, -8.230927348410992],
              [-39.988237634799354, -8.230927410470535],
              [-39.988237517324137, -8.230927474261687],
              [-39.988237400805332, -8.230927539770718],
              [-39.988237285268248, -8.230927606983318],
              [-39.988237170737996, -8.230927675884882],
              [-39.988237057239417, -8.230927746460429],
              [-39.988236944797187, -8.230927818694683],
              [-39.988236833435728, -8.23092789257189],
              [-39.988236723179213, -8.230927968076106],
              [-39.988236614051608, -8.230928045190842],
              [-39.988236506076611, -8.23092812389943],
              [-39.988236399277646, -8.230928204184643],
              [-39.988236293677943, -8.230928286029144],
              [-39.988236189300416, -8.230928369415162],
              [-39.98823608616776, -8.230928454324511],
              [-39.988235984302356, -8.230928540738777],
              [-39.988235883726333, -8.230928628639232],
              [-39.988235784461537, -8.230928718006787],
              [-39.988235686529514, -8.230928808822043],
              [-39.988235589951564, -8.230928901065155],
              [-39.988235494748629, -8.230928994716253],
              [-39.988235400941406, -8.230929089754891],
              [-39.988235308550273, -8.230929186160436],
              [-39.98823521759531, -8.230929283912007],
              [-39.988235128096228, -8.230929382988304],
              [-39.98823504007251, -8.230929483367865],
              [-39.988234953543234, -8.230929585028843],
              [-39.988234868527236, -8.230929687949075],
              [-39.988234785042948, -8.230929792106418],
              [-39.988234703108525, -8.230929897478104],
              [-39.988234622741736, -8.23093000404131],
              [-39.98823454396009, -8.230930111772816],
              [-39.988234466780625, -8.230930220649265],
              [-39.988234391220146, -8.230930330646997],
              [-39.98823431729506, -8.230930441742197],
              [-39.988234245021445, -8.230930553910676],
              [-39.98823417441497, -8.230930667128064],
              [-39.98823410549096, -8.230930781369732],
              [-39.988234038264416, -8.230930896610944],
              [-39.988233972749903, -8.230931012826581],
              [-39.988233908961682, -8.230931129991529],
              [-39.98823392117378, -8.230931155472225],
              [-39.987283727415353, -8.232707510105985],
              [-39.987283747667526, -8.232707468707027],
              [-39.987283690676755, -8.232707573579699],
              [-39.987283632302884, -8.232707677694616],
              [-39.987283572556066, -8.232707781033682],
              [-39.987283511446726, -8.232707883578922],
              [-39.987283448985437, -8.23270798531254],
              [-39.987283385183105, -8.232708086216924],
              [-39.987283320050793, -8.232708186274358],
              [-39.98728325359982, -8.232708285467496],
              [-39.987283185841768, -8.232708383779162],
              [-39.987283116788412, -8.232708481192207],
              [-39.987283046451715, -8.23270857768969],
              [-39.987282974843964, -8.232708673254892],
              [-39.987282901977586, -8.232708767871078],
              [-39.987282827865236, -8.232708861522001],
              [-39.987282752519839, -8.232708954191134],
              [-39.987282675954454, -8.232709045862595],
              [-39.987282598182418, -8.232709136520297],
              [-39.987282519217239, -8.232709226148554],
              [-39.987282439072629, -8.232709314731631],
              [-39.98728235776256, -8.232709402254288],
              [-39.987282275301148, -8.232709488701341],
              [-39.987282191702754, -8.232709574057576],
              [-39.987282106981873, -8.232709658308371],
              [-39.987282021153284, -8.232709741438898],
              [-39.987281934231859, -8.232709823434712],
              [-39.987281846232747, -8.232709904281689],
              [-39.987281757171218, -8.232709983965742],
              [-39.987281667062781, -8.232710062472814],
              [-39.987281575923106, -8.232710139789519],
              [-39.987281483768015, -8.2327102159023],
              [-39.987281390613568, -8.232710290797959],
              [-39.987281296475921, -8.232710364463387],
              [-39.987281201371459, -8.232710436885835],
              [-39.987281105316718, -8.232710508052737],
              [-39.987281008328402, -8.232710577951678],
              [-39.987280910423365, -8.232710646570515],
              [-39.98728081161866, -8.232710713897353],
              [-39.987280711931412, -8.232710779920451],
              [-39.987280611378992, -8.232710844628318],
              [-39.987280509978866, -8.232710908009684],
              [-39.987280407748699, -8.232710970053663],
              [-39.987280304706189, -8.232711030749329],
              [-39.987280200869328, -8.23271109008607],
              [-39.98728009625615, -8.232711148053754],
              [-39.987279990884822, -8.232711204642214],
              [-39.98727988477367, -8.2327112598417],
              [-39.987279777941175, -8.232711313642383],
              [-39.987279670405854, -8.23271136603516],
              [-39.987279562186473, -8.232711417010776],
              [-39.987279453301788, -8.232711466560412],
              [-39.987279343770766, -8.232711514675428],
              [-39.987279233612433, -8.23271156134745],
              [-39.987279122845955, -8.232711606568389],
              [-39.987279011490585, -8.232711650330501],
              [-39.987278899565666, -8.232711692625973],
              [-39.987278787090695, -8.232711733447598],
              [-39.987278674085204, -8.232711772788113],
              [-39.987278560568846, -8.23271181064093],
              [-39.987278446561355, -8.232711846999239],
              [-39.98727833208256, -8.232711881856751],
              [-39.98727821715233, -8.232711915207515],
              [-39.987278101790714, -8.23271194704572],
              [-39.98727798601773, -8.232711977365794],
              [-39.987277869853493, -8.23271200616233],
              [-39.987277753318217, -8.232712033430612],
              [-39.987277636432168, -8.232712059165584],
              [-39.987277519215667, -8.232712083363042],
              [-39.987277401689084, -8.232712106018633],
              [-39.987277283872885, -8.232712127128435],
              [-39.987277165787496, -8.232712146688742],
              [-39.98727704745351, -8.232712164696254],
              [-39.987276928891454, -8.232712181147715],
              [-39.987276810121969, -8.232712196040417],
              [-39.987276691165697, -8.232712209371712],
              [-39.987276572043314, -8.232712221139144],
              [-39.987276452775539, -8.232712231340837],
              [-39.987276333383129, -8.232712239974958],
              [-39.987276213886787, -8.232712247039995],
              [-39.987276094307354, -8.23271225253481],
              [-39.987275974665572, -8.232712256458276],
              [-39.987275854982279, -8.23271225880984],
              [-39.987275735278232, -8.232712259589064],
              [-39.987275615574312, -8.232712258795877],
              [-39.987275495891289, -8.232712256430306],
              [-39.987275376249961, -8.232712252492769],
              [-39.987275256671161, -8.232712246983976],
              [-39.987275137175686, -8.232712239904945],
              [-39.98727501778427, -8.232712231256768],
              [-39.987274898517704, -8.232712221041114],
              [-39.987274779396728, -8.232712209259736],
              [-39.987274660442019, -8.232712195914477],
              [-39.987274541674296, -8.232712181007944],
              [-39.987274423114179, -8.232712164542571],
              [-39.987274304782304, -8.232712146521225],
              [-39.987274186699238, -8.232712126947019],
              [-39.987274068885512, -8.232712105823449],
              [-39.987273951361615, -8.232712083154039],
              [-39.98727383414797, -8.232712058942948],
              [-39.98727371726455, -8.232712033194684],
              [-39.98727375239762, -8.232712056722605],
              [-39.98562000301262, -8.232336352821376],
              [-39.98561997915602, -8.232336309352434],
              [-39.985619855747885, -8.232336280409658],
              [-39.985619732757492, -8.23233624975278],
              [-39.985619610208836, -8.232336217387942],
              [-39.985619488125792, -8.232336183321271],
              [-39.985619366532219, -8.232336147559456],
              [-39.985619245451787, -8.232336110109514],
              [-39.985619124908119, -8.232336070978777],
              [-39.985619004924743, -8.232336030174759],
              [-39.985618885525028, -8.232335987705476],
              [-39.985618766732301, -8.232335943579256],
              [-39.985618648569684, -8.232335897804701],
              [-39.985618531060275, -8.232335850390653],
              [-39.985618414226941, -8.232335801346455],
              [-39.985618298092483, -8.232335750681566],
              [-39.985618182679559, -8.232335698406024],
              [-39.98561806801068, -8.232335644529849],
              [-39.985617954108207, -8.232335589063698],
              [-39.985617840994337, -8.232335532018231],
              [-39.98561772869116, -8.232335473404813],
              [-39.985617617220548, -8.23233541323461],
              [-39.98561750660425, -8.232335351519488],
              [-39.985617396863866, -8.232335288271495],
              [-39.985617288020755, -8.232335223502963],
              [-39.985617180096192, -8.23233515722643],
              [-39.985617073111179, -8.232335089454912],
              [-39.985616967086607, -8.232335020201614],
              [-39.985616862043116, -8.232334949480046],
              [-39.985616758001271, -8.23233487730403],
              [-39.985616654981307, -8.232334803687504],
              [-39.985616553003325, -8.232334728644965],
              [-39.985616452087172, -8.232334652191019],
              [-39.985616352252606, -8.23233457434061],
              [-39.985616253519048, -8.23233449510883],
              [-39.985616155905781, -8.232334414511124],
              [-39.985616059431813, -8.232334332563338],
              [-39.985615964115986, -8.232334249281315],
              [-39.985615869976868, -8.232334164681399],
              [-39.985615777032834, -8.232334078780079],
              [-39.985615685302008, -8.232333991594011],
              [-39.985615594802269, -8.232333903140303],
              [-39.98561550555128, -8.232333813436153],
              [-39.985615417566457, -8.23233372249908],
              [-39.985615330864917, -8.232333630346833],
              [-39.985615245463599, -8.232333536997313],
              [-39.985615161379165, -8.232333442468805],
              [-39.985615078627987, -8.232333346779738],
              [-39.985614997226243, -8.232333249948736],
              [-39.9856149171898, -8.232333151994757],
              [-39.985614838534218, -8.232333052936719],
              [-39.985614761274896, -8.232332952794142],
              [-39.985614685426881, -8.232332851586479],
              [-39.985614611004955, -8.232332749333526],
              [-39.98561453802364, -8.232332646055134],
              [-39.985614466497189, -8.23233254177144],
              [-39.985614396439502, -8.232332436502858],
              [-39.985614327864276, -8.232332330269873],
              [-39.985614260784885, -8.232332223093175],
              [-39.98561419521441, -8.232332114993786],
              [-39.98561413116564, -8.232332005992667],
              [-39.985614068651067, -8.23233189611115],
              [-39.985614007682862, -8.232331785370594],
              [-39.985613948272956, -8.232331673792638],
              [-39.985613890432909, -8.2323315613991],
              [-39.985613834174004, -8.232331448211776],
              [-39.985613779507183, -8.23233133425278],
              [-39.985613726443162, -8.232331219544431],
              [-39.985613674992273, -8.232331104108956],
              [-39.98561362516454, -8.232330987969036],
              [-39.985613576969683, -8.232330871147141],
              [-39.985613530417083, -8.23233075366616],
              [-39.985613485515849, -8.232330635549026],
              [-39.98561344227474, -8.232330516818781],
              [-39.985613400702164, -8.232330397498401],
              [-39.985613360806234, -8.232330277611419],
              [-39.985613322594752, -8.232330157181007],
              [-39.98561328607515, -8.232330036230753],
              [-39.985613251254556, -8.232329914784223],
              [-39.985613218139761, -8.232329792865119],
              [-39.985613186737233, -8.232329670497124],
              [-39.985613157053074, -8.232329547704252],
              [-39.985613129093082, -8.232329424510475],
              [-39.985613102862729, -8.232329300939565],
              [-39.985613078367109, -8.232329177015838],
              [-39.985613055611005, -8.232329052763435],
              [-39.985613034598863, -8.232328928206552],
              [-39.985613015334756, -8.232328803369464],
              [-39.985612997822471, -8.232328678276545],
              [-39.985612982065412, -8.232328552952236],
              [-39.985612968066668, -8.232328427420917],
              [-39.985612955828913, -8.23232830170703],
              [-39.985612945354589, -8.23232817583523],
              [-39.985612936645737, -8.232328049829956],
              [-39.98561292970404, -8.232327923715795],
              [-39.985612924530848, -8.232327797517346],
              [-39.985612921127178, -8.232327671259197],
              [-39.985612919493661, -8.232327544966045],
              [-39.985612919630675, -8.232327418662482],
              [-39.985612921538163, -8.232327292373242],
              [-39.985612925216117, -8.232327166122209],
              [-39.985612899069345, -8.232327145174622],
              [-39.985672548735025, -8.230676613903318],
              [-39.985672585301351, -8.23067663263433],
              [-39.985672586556632, -8.230676599586225],
              [-39.985672587933223, -8.230676566542904],
              [-39.985672589431076, -8.230676533504813],
              [-39.985672591050211, -8.23067650047247],
              [-39.985672592790557, -8.230676467446166],
              [-39.985672594652115, -8.230676434426455],
              [-39.985672596634863, -8.230676401413723],
              [-39.98567259873878, -8.230676368408508],
              [-39.985672600963831, -8.230676335411136],
              [-39.985672603309986, -8.230676302422141],
              [-39.985672605777189, -8.230676269441798],
              [-39.985672608365455, -8.230676236470753],
              [-39.985672611074719, -8.230676203509272],
              [-39.985672613904939, -8.230676170557986],
              [-39.985672616856093, -8.230676137617165],
              [-39.985672619928152, -8.230676104687342],
              [-39.985672623121033, -8.230676071768892],
              [-39.985672626434749, -8.230676038862258],
              [-39.985672629869207, -8.230676005968016],
              [-39.985672633424379, -8.230675973086479],
              [-39.985672637100222, -8.230675940218125],
              [-39.985672640896695, -8.23067590736332],
              [-39.985672644813718, -8.230675874522591],
              [-39.985672648851263, -8.23067584169641],
              [-39.985672653009289, -8.230675808885108],
              [-39.985672657287701, -8.230675776089178],
              [-39.985672661686479, -8.230675743309043],
              [-39.985672666205538, -8.230675710545171],
              [-39.985672670844835, -8.230675677797898],
              [-39.985672675604299, -8.230675645067885],
              [-39.985672680483837, -8.2306756123553],
              [-39.985672685483465, -8.230675579660803],
              [-39.985672690603032, -8.230675546984706],
              [-39.985672695842503, -8.230675514327404],
              [-39.985672701201807, -8.230675481689531],
              [-39.985672706680901, -8.230675449071347],
              [-39.985672712279651, -8.230675416473337],
              [-39.985672717998021, -8.230675383895973],
              [-39.985672723835947, -8.230675351339574],
              [-39.985672729793315, -8.2306753188047],
              [-39.985672735870061, -8.230675286291767],
              [-39.985672742066122, -8.230675253801166],
              [-39.985672748381376, -8.230675221333403],
              [-39.985672754815774, -8.230675188888798],
              [-39.985672761369216, -8.230675156467854],
              [-39.985672768041624, -8.230675124071132],
              [-39.985672774832892, -8.230675091698798],
              [-39.985672781742942, -8.23067505935142],
              [-39.985672788771673, -8.230675027029429],
              [-39.985672795918994, -8.230674994733272],
              [-39.985672803184812, -8.230674962463443],
              [-39.985672810569028, -8.230674930220262],
              [-39.985672818071556, -8.230674898004093],
              [-39.985672825692276, -8.230674865815605],
              [-39.985672833431074, -8.230674833655005],
              [-39.985672841287879, -8.230674801522907],
              [-39.985672849262585, -8.230674769419547],
              [-39.985672857355063, -8.230674737345463],
              [-39.985672865565213, -8.230674705301125],
              [-39.985672873892938, -8.23067467328689],
              [-39.985672882338108, -8.23067464130321],
              [-39.985672890900624, -8.230674609350546],
              [-39.985672899580358, -8.230674577429211],
              [-39.985672908377204, -8.230674545539731],
              [-39.985672917291041, -8.23067451368258],
              [-39.985672926321747, -8.230674481857987],
              [-39.985672935469204, -8.230674450066568],
              [-39.985672944733288, -8.230674418308723],
              [-39.985672954113895, -8.230674386584825],
              [-39.985672963610874, -8.230674354895289],
              [-39.985672973224105, -8.230674323240581],
              [-39.98567298295346, -8.23067429162101],
              [-39.985672992798818, -8.230674260037175],
              [-39.985673002760016, -8.230674228489409],
              [-39.985673012836976, -8.23067419697815],
              [-39.985673023029499, -8.230674165503885],
              [-39.98567303333752, -8.230674134066874],
              [-39.985673043760819, -8.230674102667665],
              [-39.985673054299319, -8.230674071306586],
              [-39.985673064952856, -8.23067403998412],
              [-39.985673075721301, -8.230674008700726],
              [-39.985673086604493, -8.230673977456654],
              [-39.985673097602294, -8.230673946252562],
              [-39.985673108714558, -8.230673915088673],
              [-39.98567311994114, -8.230673883965508],
              [-39.985673131281878, -8.230673852883383],
              [-39.98567314273663, -8.230673821842842],
              [-39.985673154305232, -8.230673790844271],
              [-39.985673165987542, -8.230673759888026],
              [-39.985673177783418, -8.230673728974457],
              [-39.985673189692662, -8.23067369810421],
              [-39.985673201715144, -8.230673667277415],
              [-39.985673213850689, -8.230673636494702],
              [-39.985673226099145, -8.230673605756371],
              [-39.985673238460343, -8.230673575062907],
              [-39.985673250934127, -8.230673544414651],
              [-39.985673263520312, -8.230673513812061],
              [-39.985673276218755, -8.230673483255519],
              [-39.985673289029215, -8.230673452745993],
              [-39.98567326717636, -8.230673446671828],
              [-39.986372291741588, -8.229017276333083],
              [-39.986372266813142, -8.229017241233308],
              [-39.986372294816036, -8.229017174127543],
              [-39.986372322276779, -8.229017106799713],
              [-39.986372349193573, -8.22901703925427],
              [-39.986372375564713, -8.229016971495549],
              [-39.986372401388472, -8.229016903527862],
              [-39.986372426663195, -8.229016835355701],
              [-39.986372451387233, -8.229016766983314],
              [-39.986372475559016, -8.229016698415281],
              [-39.986372499176973, -8.229016629655971],
              [-39.986372522239556, -8.229016560709827],
              [-39.986372544745301, -8.229016491581238],
              [-39.986372566692765, -8.229016422274812],
              [-39.986372588080513, -8.22901635279492],
              [-39.986372608907146, -8.229016283145985],
              [-39.986372629171363, -8.229016213332597],
              [-39.986372648871807, -8.229016143359297],
              [-39.986372668007249, -8.229016073230492],
              [-39.986372686576431, -8.229016002950777],
              [-39.986372704578173, -8.229015932524694],
              [-39.986372722011275, -8.229015861956784],
              [-39.986372738874643, -8.229015791251596],
              [-39.986372755167181, -8.229015720413711],
              [-39.986372770887826, -8.229015649447675],
              [-39.986372786035588, -8.229015578358039],
              [-39.986372800609466, -8.229015507149519],
              [-39.986372814608522, -8.229015435826575],
              [-39.986372828031861, -8.229015364393863],
              [-39.986372840878609, -8.229015292856049],
              [-39.986372853147962, -8.229015221217715],
              [-39.986372864839076, -8.229015149483496],
              [-39.986372875951233, -8.229015077657897],
              [-39.986372886483714, -8.229015005745731],
              [-39.986372896435824, -8.229014933751568],
              [-39.986372905806938, -8.229014861680094],
              [-39.986372914596451, -8.229014789535874],
              [-39.986372922803767, -8.229014717323613],
              [-39.986372930428388, -8.229014645048043],
              [-39.98637293746981, -8.229014572713762],
              [-39.98637294392757, -8.229014500325373],
              [-39.986372949801265, -8.22901442788768],
              [-39.986372955090502, -8.229014355405312],
              [-39.986372959794956, -8.229014282882938],
              [-39.986372963914299, -8.229014210325209],
              [-39.986372967448304, -8.229014137736874],
              [-39.986372970396701, -8.229014065122589],
              [-39.98637297275932, -8.229013992487065],
              [-39.986372974536017, -8.229013919834955],
              [-39.986372975726667, -8.229013847170974],
              [-39.986372976331182, -8.229013774499876],
              [-39.986372976349536, -8.229013701826188],
              [-39.986372975781734, -8.229013629154718],
              [-39.986372974627777, -8.229013556490157],
              [-39.9863729728878, -8.229013483837097],
              [-39.98637297056186, -8.229013411200397],
              [-39.986372967650141, -8.229013338584696],
              [-39.986372964152821, -8.229013265994537],
              [-39.986372960070121, -8.229013193434859],
              [-39.98637295540231, -8.229013120910093],
              [-39.986372950149672, -8.229013048425101],
              [-39.986372944312585, -8.229012975984418],
              [-39.986372937891375, -8.229012903592826],
              [-39.986372930886489, -8.229012831255043],
              [-39.986372923298383, -8.229012758975582],
              [-39.986372915127539, -8.229012686759306],
              [-39.986372906374477, -8.229012614610697],
              [-39.986372897039779, -8.229012542534457],
              [-39.98637288712402, -8.229012470535283],
              [-39.986372876627868, -8.22901239861787],
              [-39.986372865551992, -8.229012326786757],
              [-39.986372853897116, -8.229012255046632],
              [-39.98637284166395, -8.229012183402078],
              [-39.986372828853348, -8.229012111857863],
              [-39.98637281546609, -8.229012040418478],
              [-39.986372801503059, -8.229011969088544],
              [-39.986372786965141, -8.229011897872637],
              [-39.986372771853311, -8.229011826775437],
              [-39.986372756168507, -8.229011755801562],
              [-39.986372739911744, -8.229011684955449],
              [-39.986372723084116, -8.229011614241854],
              [-39.986372705686641, -8.22901154366515],
              [-39.986372687720483, -8.229011473230051],
              [-39.9863726691868, -8.229011402941023],
              [-39.986372650086793, -8.229011332802701],
              [-39.986372630421677, -8.229011262819458],
              [-39.986372610192753, -8.229011192995914],
              [-39.986372589401284, -8.229011123336591],
              [-39.986372568048637, -8.229011053845959],
              [-39.986372546136202, -8.229010984528511],
              [-39.986372523665366, -8.229010915388629],
              [-39.986372500637614, -8.229010846430908],
              [-39.986372477054395, -8.229010777659772],
              [-39.986372452917252, -8.229010709079647],
              [-39.986372428227739, -8.229010640694879],
              [-39.986372402987463, -8.229010572510076],
              [-39.986372377198037, -8.229010504529485],
              [-39.986372350861139, -8.229010436757527],
              [-39.986372323978465, -8.229010369198544],
              [-39.986372296551728, -8.229010301857052],
              [-39.986372268582734, -8.22901023473722],
              [-39.986372274292577, -8.229010221903639],
              [-39.985730118289709, -8.227486493948973],
              [-39.985730096857601, -8.227486464967797],
              [-39.985730072445698, -8.227486407596553],
              [-39.985730047637567, -8.227486350394203],
              [-39.985730022434439, -8.227486293363391],
              [-39.985729996837485, -8.227486236507024],
              [-39.985729970847942, -8.227486179827714],
              [-39.985729944467025, -8.227486123328079],
              [-39.985729917695991, -8.227486067010906],
              [-39.985729890536106, -8.227486010878831],
              [-39.985729862988677, -8.22748595493449],
              [-39.985729835054997, -8.227485899180554],
              [-39.985729806736408, -8.227485843619736],
              [-39.985729778034205, -8.227485788254489],
              [-39.985729748949822, -8.227485733087626],
              [-39.985729719484588, -8.227485678121763],
              [-39.985729689639925, -8.227485623359405],
              [-39.985729659417231, -8.227485568803193],
              [-39.985729628817978, -8.227485514455751],
              [-39.985729597843573, -8.227485460319569],
              [-39.985729566495529, -8.227485406397406],
              [-39.985729534775288, -8.227485352691568],
              [-39.985729502684386, -8.227485299204709],
              [-39.985729470224342, -8.227485245939523],
              [-39.985729437396699, -8.227485192898264],
              [-39.985729404203006, -8.227485140083644],
              [-39.985729370644847, -8.227485087498197],
              [-39.985729336723807, -8.227485035144261],
              [-39.985729302441491, -8.227484983024379],
              [-39.985729267799563, -8.227484931141074],
              [-39.985729232799621, -8.227484879496846],
              [-39.985729197443369, -8.22748482809399],
              [-39.985729161732444, -8.227484776935126],
              [-39.985729125668563, -8.227484726022473],
              [-39.985729089253432, -8.227484675358689],
              [-39.9857290524888, -8.227484624945996],
              [-39.985729015376371, -8.227484574786862],
              [-39.985728977917951, -8.227484524883625],
              [-39.985728940115273, -8.227484475238729],
              [-39.98572890197017, -8.227484425854485],
              [-39.985728863484461, -8.227484376733184],
              [-39.985728824659923, -8.227484327877269],
              [-39.985728785498438, -8.227484279288968],
              [-39.985728746001854, -8.227484230970656],
              [-39.98572870617204, -8.22748418292459],
              [-39.985728666010893, -8.227484135153061],
              [-39.985728625520331, -8.227484087658299],
              [-39.985728584702251, -8.227484040442553],
              [-39.985728543558608, -8.227483993508143],
              [-39.985728502091362, -8.227483946857236],
              [-39.985728460302447, -8.227483900492121],
              [-39.985728418193901, -8.227483854414933],
              [-39.985728375767664, -8.227483808627836],
              [-39.985728333025797, -8.227483763133018],
              [-39.985728289970304, -8.22748371793263],
              [-39.98572824660323, -8.227483673028956],
              [-39.985728202926644, -8.227483628423915],
              [-39.985728158942621, -8.227483584119826],
              [-39.985728114653227, -8.227483540118639],
              [-39.985728070060603, -8.227483496422449],
              [-39.985728025166821, -8.227483453033413],
              [-39.985727979974044, -8.227483409953606],
              [-39.985727934484402, -8.227483367184941],
              [-39.985727888700048, -8.227483324729539],
              [-39.985727842623177, -8.227483282589423],
              [-39.985727796255965, -8.227483240766556],
              [-39.985727749600613, -8.227483199263038],
              [-39.985727702659339, -8.227483158080634],
              [-39.985727655434374, -8.227483117221462],
              [-39.985727607927956, -8.22748307668747],
              [-39.985727560142323, -8.22748303648042],
              [-39.985727512079777, -8.227482996602395],
              [-39.985727463742585, -8.227482957055075],
              [-39.985727415133013, -8.227482917840629],
              [-39.9857273662534, -8.22748287896075],
              [-39.985727317106075, -8.227482840417268],
              [-39.985727267693356, -8.227482802212016],
              [-39.98572721801758, -8.22748276434686],
              [-39.985727168081112, -8.227482726823576],
              [-39.985727117886334, -8.227482689643976],
              [-39.985727067435612, -8.227482652809758],
              [-39.985727016731346, -8.227482616322758],
              [-39.98572696577596, -8.227482580184628],
              [-39.985726914571856, -8.22748254439704],
              [-39.98572686312145, -8.227482508961778],
              [-39.985726811427199, -8.227482473880567],
              [-39.985726759491591, -8.227482439155027],
              [-39.985726707317028, -8.227482404786688],
              [-39.985726654906031, -8.22748237077743],
              [-39.985726602261067, -8.227482337128613],
              [-39.985726549384658, -8.227482303841965],
              [-39.985726496279291, -8.227482270919076],
              [-39.985726442947495, -8.227482238361493],
              [-39.985726389391814, -8.227482206170745],
              [-39.985726335614757, -8.227482174348289],
              [-39.985726281618909, -8.227482142895807],
              [-39.985726227406822, -8.227482111814702],
              [-39.985726172981074, -8.227482081106333],
              [-39.985726118344239, -8.227482050772384],
              [-39.985726063498902, -8.227482020814108],
              [-39.985726008447699, -8.227481991233009],
              [-39.985726021894315, -8.2274819818981],
              [-39.984064047043653, -8.226596286427473],
              [-39.98406401750011, -8.226596284616356],
              [-39.984063938629333, -8.2265962430796],
              [-39.984063859352659, -8.226596202317042],
              [-39.98406377967774, -8.22659616233252],
              [-39.984063699612257, -8.226596123130019],
              [-39.984063619163912, -8.226596084713213],
              [-39.984063538340557, -8.226596047085772],
              [-39.984063457149922, -8.226596010251535],
              [-39.984063375599874, -8.226595974213852],
              [-39.984063293698306, -8.226595938976377],
              [-39.984063211453098, -8.226595904542405],
              [-39.984063128872201, -8.226595870915173],
              [-39.98406304596358, -8.226595838098113],
              [-39.984062962735287, -8.226595806094288],
              [-39.984062879195299, -8.226595774906896],
              [-39.984062795351733, -8.226595744538765],
              [-39.984062711212658, -8.226595714993007],
              [-39.984062626786191, -8.226595686272331],
              [-39.984062542080515, -8.226595658379685],
              [-39.984062457103782, -8.226595631317538],
              [-39.984062371864233, -8.226595605088702],
              [-39.984062286370055, -8.226595579695619],
              [-39.984062200629545, -8.22659555514074],
              [-39.98406211465096, -8.226595531426458],
              [-39.984062028442601, -8.226595508555059],
              [-39.984061942012808, -8.226595486528696],
              [-39.984061855369916, -8.226595465349606],
              [-39.984061768522317, -8.226595445019772],
              [-39.984061681478352, -8.226595425541127],
              [-39.984061594246455, -8.226595406915539],
              [-39.984061506835062, -8.226595389144977],
              [-39.984061419252583, -8.226595372230969],
              [-39.984061331507519, -8.226595356175192],
              [-39.984061243608281, -8.22659534097923],
              [-39.984061155563424, -8.226595326644594],
              [-39.984061067381411, -8.226595313172508],
              [-39.984060979070776, -8.226595300564471],
              [-39.984060890640038, -8.226595288821567],
              [-39.984060802097723, -8.226595277944963],
              [-39.984060713452408, -8.226595267935776],
              [-39.984060624712633, -8.226595258794868],
              [-39.984060535886989, -8.226595250523159],
              [-39.984060446984039, -8.226595243121496],
              [-39.984060358012364, -8.226595236590498],
              [-39.984060268980571, -8.226595230930883],
              [-39.984060179897256, -8.226595226143168],
              [-39.984060090771003, -8.226595222227745],
              [-39.984060001610437, -8.226595219185102],
              [-39.984059912424179, -8.226595217015548],
              [-39.984059823220804, -8.226595215719152],
              [-39.984059734008945, -8.226595215296181],
              [-39.984059644797249, -8.226595215746608],
              [-39.984059555594293, -8.226595217070399],
              [-39.984059466408681, -8.226595219267377],
              [-39.984059377249068, -8.226595222337396],
              [-39.984059288124023, -8.226595226280207],
              [-39.9840591990422, -8.226595231095287],
              [-39.984059110012161, -8.226595236782291],
              [-39.984059021042512, -8.226595243340647],
              [-39.984058932141878, -8.22659525076971],
              [-39.984058843318785, -8.226595259068679],
              [-39.984058754581859, -8.226595268236879],
              [-39.984058665939635, -8.226595278273368],
              [-39.98405857740071, -8.22659528917719],
              [-39.984058488973602, -8.226595300947269],
              [-39.984058400666875, -8.226595313582502],
              [-39.984058312489033, -8.226595327081654],
              [-39.984058224448603, -8.226595341443396],
              [-39.984058136554111, -8.226595356666323],
              [-39.984058048813999, -8.226595372749046],
              [-39.984057961236786, -8.226595389690036],
              [-39.984057873830892, -8.226595407487544],
              [-39.984057786604787, -8.226595426139921],
              [-39.984057699566868, -8.226595445645225],
              [-39.984057612725543, -8.226595466001855],
              [-39.984057526089217, -8.226595487207563],
              [-39.984057439666245, -8.226595509260479],
              [-39.984057353464998, -8.226595532158385],
              [-39.98405726749376, -8.226595555899101],
              [-39.984057181760846, -8.22659558048035],
              [-39.98405709627454, -8.226595605899744],
              [-39.984057011043113, -8.226595632154824],
              [-39.984056926074786, -8.226595659243021],
              [-39.98405684137775, -8.226595687161806],
              [-39.9840567569602, -8.226595715908379],
              [-39.984056672830263, -8.226595745480083],
              [-39.984056588996104, -8.226595775873934],
              [-39.984056505465794, -8.226595807087024],
              [-39.984056422247406, -8.226595839116509],
              [-39.984056339348982, -8.226595871959036],
              [-39.98405625677848, -8.226595905611516],
              [-39.984056174543966, -8.226595940070826],
              [-39.98405609265329, -8.22659597533357],
              [-39.984056011114419, -8.226596011396337],
              [-39.984055929935209, -8.226596048255528],
              [-39.984055849123479, -8.226596085907742],
              [-39.984055768687071, -8.226596124349303],
              [-39.984055688633745, -8.226596163576524],
              [-39.98405560897119, -8.226596203585519],
              [-39.984055529707149, -8.226596244372431],
              [-39.984055513265531, -8.226596216880008],
              [-39.982459778260711, -8.227427303884001],
              [-39.982459792188727, -8.227427280212948],
              [-39.9824597774805, -8.227427287855752],
              [-39.982459762758246, -8.227427295471673],
              [-39.98245974802208, -8.227427303060841],
              [-39.982459733271988, -8.22742731062309],
              [-39.982459718508053, -8.227427318158519],
              [-39.982459703730342, -8.227427325667133],
              [-39.982459688938881, -8.227427333148718],
              [-39.9824596741337, -8.227427340603493],
              [-39.982459659314877, -8.22742734803116],
              [-39.982459644482475, -8.227427355431903],
              [-39.982459629636502, -8.227427362805642],
              [-39.982459614777035, -8.227427370152396],
              [-39.982459599904104, -8.227427377471921],
              [-39.9824595850178, -8.227427384764461],
              [-39.982459570118124, -8.227427392029979],
              [-39.98245955520516, -8.227427399268239],
              [-39.982459540278946, -8.227427406479357],
              [-39.982459525339529, -8.227427413663317],
              [-39.982459510386946, -8.227427420820074],
              [-39.982459495421267, -8.227427427949566],
              [-39.982459480442543, -8.227427435051837],
              [-39.982459465450809, -8.227427442126746],
              [-39.982459450446136, -8.227427449174463],
              [-39.982459435428552, -8.227427456194823],
              [-39.982459420398115, -8.227427463187807],
              [-39.982459405354874, -8.227427470153424],
              [-39.982459390298878, -8.227427477091634],
              [-39.982459375230199, -8.227427484002416],
              [-39.982459360148866, -8.227427490885804],
              [-39.98245934505492, -8.227427497741763],
              [-39.982459329948441, -8.227427504570123],
              [-39.982459314829448, -8.22742751137104],
              [-39.982459299698007, -8.227427518144427],
              [-39.982459284554167, -8.227427524890231],
              [-39.982459269397985, -8.227427531608457],
              [-39.982459254229511, -8.227427538299068],
              [-39.982459239048779, -8.227427544962033],
              [-39.982459223855862, -8.227427551597449],
              [-39.982459208650788, -8.227427558205118],
              [-39.982459193433634, -8.227427564785051],
              [-39.982459178204422, -8.227427571337353],
              [-39.982459162963238, -8.227427577861903],
              [-39.982459147710081, -8.227427584358724],
              [-39.982459132445051, -8.227427590827707],
              [-39.98245911716819, -8.227427597268875],
              [-39.982459101879535, -8.227427603682308],
              [-39.982459086579134, -8.227427610067805],
              [-39.982459071267051, -8.227427616425485],
              [-39.982459055943337, -8.227427622755346],
              [-39.982459040608013, -8.227427629057205],
              [-39.982459025261186, -8.22742763533121],
              [-39.982459009902861, -8.227427641577213],
              [-39.982458994533111, -8.22742764779521],
              [-39.982458979151978, -8.227427653985307],
              [-39.98245896375952, -8.227427660147365],
              [-39.982458948355806, -8.227427666281351],
              [-39.982458932940823, -8.227427672387311],
              [-39.982458917514698, -8.22742767846527],
              [-39.982458902077433, -8.227427684515042],
              [-39.982458886629118, -8.22742769053669],
              [-39.982458871169776, -8.227427696530246],
              [-39.982458855699456, -8.227427702495701],
              [-39.982458840218221, -8.227427708432892],
              [-39.98245882472613, -8.227427714341962],
              [-39.982458809223239, -8.227427720222757],
              [-39.982458793709561, -8.227427726075382],
              [-39.982458778185183, -8.227427731899693],
              [-39.982458762650161, -8.227427737695731],
              [-39.982458747104531, -8.227427743463577],
              [-39.982458731548334, -8.227427749202981],
              [-39.982458715981636, -8.227427754914155],
              [-39.982458700404493, -8.227427760596987],
              [-39.982458684816955, -8.227427766251429],
              [-39.982458669219064, -8.227427771877426],
              [-39.982458653610905, -8.227427777475127],
              [-39.982458637992487, -8.22742778304435],
              [-39.982458622363879, -8.227427788585128],
              [-39.982458606725132, -8.227427794097428],
              [-39.982458591076295, -8.22742779958128],
              [-39.982458575417439, -8.227427805036555],
              [-39.982458559748608, -8.227427810463386],
              [-39.982458544069836, -8.22742781586177],
              [-39.982458528381187, -8.227427821231453],
              [-39.982458512682719, -8.227427826572612],
              [-39.982458496974509, -8.227427831885217],
              [-39.982458481256536, -8.227427837169177],
              [-39.982458465528914, -8.227427842424571],
              [-39.982458449791672, -8.227427847651315],
              [-39.982458434044879, -8.227427852849349],
              [-39.98245841828858, -8.227427858018716],
              [-39.982458402522816, -8.227427863159429],
              [-39.982458386747666, -8.227427868271414],
              [-39.982458370963158, -8.227427873354763],
              [-39.982458355169349, -8.227427878409282],
              [-39.98245833936631, -8.227427883435029],
              [-39.982458323554056, -8.227427888431976],
              [-39.982458307732671, -8.227427893400208],
              [-39.982458291902198, -8.22742789833957],
              [-39.98245827606268, -8.22742790325065],
              [-39.982458236513658, -8.227427940747605],
              [-39.980796349222778, -8.227941460504052],
              [-39.980796367414953, -8.227941465133847],
              [-39.980796310613094, -8.227941482483734],
              [-39.980796253698458, -8.227941499462993],
              [-39.980796196673516, -8.227941516070796],
              [-39.980796139540708, -8.227941532306533],
              [-39.980796082302454, -8.227941548169369],
              [-39.980796024961215, -8.227941563658637],
              [-39.980795967519477, -8.227941578773734],
              [-39.980795909979669, -8.22794159351411],
              [-39.980795852344258, -8.227941607878915],
              [-39.980795794615709, -8.227941621867707],
              [-39.980795736796523, -8.227941635479773],
              [-39.980795678889152, -8.227941648714637],
              [-39.980795620896089, -8.227941661571663],
              [-39.9807955628198, -8.227941674050294],
              [-39.980795504662787, -8.227941686150105],
              [-39.980795446427521, -8.227941697870426],
              [-39.980795388116526, -8.227941709210786],
              [-39.980795329732281, -8.227941720170838],
              [-39.98079527127728, -8.227941730749867],
              [-39.980795212754053, -8.227941740947653],
              [-39.980795154165072, -8.22794175076368],
              [-39.980795095512896, -8.227941760197476],
              [-39.980795036799989, -8.227941769248655],
              [-39.980794978028861, -8.22794177791687],
              [-39.980794919202054, -8.227941786201745],
              [-39.980794860322099, -8.227941794102833],
              [-39.980794801391497, -8.227941801619933],
              [-39.98079474241279, -8.227941808752693],
              [-39.980794683388481, -8.227941815500726],
              [-39.980794624321099, -8.227941821863769],
              [-39.980794565213188, -8.227941827841622],
              [-39.980794506067276, -8.227941833434013],
              [-39.980794446885902, -8.227941838640577],
              [-39.98079438767158, -8.227941843461227],
              [-39.980794328426889, -8.227941847895741],
              [-39.980794269154295, -8.227941851943905],
              [-39.980794209856384, -8.227941855605449],
              [-39.980794150535708, -8.227941858880428],
              [-39.980794091194788, -8.227941861768452],
              [-39.980794031836155, -8.227941864269615],
              [-39.980793972462358, -8.227941866383725],
              [-39.980793913075964, -8.227941868110696],
              [-39.980793853679486, -8.227941869450394],
              [-39.980793794275492, -8.227941870402857],
              [-39.980793734866495, -8.227941870968072],
              [-39.980793675455075, -8.227941871145836],
              [-39.980793616043755, -8.22794187093629],
              [-39.980793556635078, -8.227941870339411],
              [-39.980793497231581, -8.227941869355243],
              [-39.980793437835835, -8.227941867983699],
              [-39.980793378450386, -8.227941866225008],
              [-39.980793319077748, -8.227941864079231],
              [-39.980793259720478, -8.227941861546325],
              [-39.980793200381108, -8.227941858626522],
              [-39.980793141062193, -8.227941855319882],
              [-39.980793081766286, -8.227941851626619],
              [-39.980793022495888, -8.227941847546761],
              [-39.980792963253577, -8.227941843080638],
              [-39.980792904041849, -8.227941838228345],
              [-39.980792844863295, -8.227941832990146],
              [-39.980792785720389, -8.227941827366136],
              [-39.980792726615718, -8.227941821356742],
              [-39.980792667551775, -8.227941814962065],
              [-39.980792608531111, -8.227941808182536],
              [-39.980792549556227, -8.227941801018346],
              [-39.980792490629689, -8.22794179346973],
              [-39.980792431753997, -8.227941785537087],
              [-39.980792372931674, -8.227941777220826],
              [-39.980792314165235, -8.227941768521232],
              [-39.980792255457196, -8.227941759438684],
              [-39.980792196810093, -8.227941749973564],
              [-39.980792138226406, -8.227941740126253],
              [-39.980792079708685, -8.227941729897239],
              [-39.980792021259397, -8.227941719286909],
              [-39.98079196288105, -8.227941708295774],
              [-39.980791904576165, -8.227941696924207],
              [-39.98079184634723, -8.227941685172793],
              [-39.980791788196726, -8.227941673041865],
              [-39.980791730127173, -8.227941660532261],
              [-39.980791672141031, -8.227941647644224],
              [-39.980791614240793, -8.227941634378457],
              [-39.98079155642894, -8.22794162073542],
              [-39.980791498707937, -8.227941606715859],
              [-39.980791441080271, -8.227941592320168],
              [-39.980791383548421, -8.22794157754911],
              [-39.980791326114804, -8.227941562403291],
              [-39.980791268781921, -8.227941546883329],
              [-39.980791211552216, -8.227941530989973],
              [-39.98079115442814, -8.227941514723707],
              [-39.980791097412158, -8.227941498085483],
              [-39.980791040506688, -8.227941481075806],
              [-39.980790983714165, -8.227941463695508],
              [-39.980790927037013, -8.227941445945273],
              [-39.980790870477698, -8.227941427825943],
              [-39.98079081403862, -8.227941409338246],
              [-39.98079075772219, -8.227941390483023],
              [-39.980790701530822, -8.227941371260924],
              [-39.980790645466946, -8.227941351672969],
              [-39.980790589532468, -8.227941331721297],
              [-39.980790538672657, -8.227941293801683],
              [-39.978792897419737, -8.227221361031919],
              [-39.978791534124383, -8.22722073125718],
              [-39.97761570219366, -8.22655151737602],
              [-39.977615726994564, -8.226551471786474],
              [-39.977615584459912, -8.226551392344051],
              [-39.97761544051378, -8.226551315469091],
              [-39.977615295202739, -8.226551241186344],
              [-39.977615148573854, -8.226551169519958],
              [-39.977615000674547, -8.226551100493118],
              [-39.977614851552701, -8.226551034128079],
              [-39.977614701256599, -8.226550970446475],
              [-39.977614549834861, -8.226550909468854],
              [-39.977614397336517, -8.226550851214851],
              [-39.977614243810912, -8.226550795703497],
              [-39.977614089307735, -8.226550742952616],
              [-39.977613933877009, -8.226550692979304],
              [-39.977613777569012, -8.226550645799794],
              [-39.977613620434376, -8.226550601429329],
              [-39.977613462523919, -8.226550559882369],
              [-39.977613303888781, -8.22655052117215],
              [-39.977613144580303, -8.226550485311305],
              [-39.977612984650015, -8.226550452311464],
              [-39.977612824149723, -8.226550422183282],
              [-39.977612663131346, -8.22655039493657],
              [-39.977612501647016, -8.226550370580066],
              [-39.977612339749015, -8.226550349121693],
              [-39.977612177489696, -8.226550330568283],
              [-39.977612014921625, -8.226550314926053],
              [-39.97761185209739, -8.226550302200007],
              [-39.977611689069704, -8.226550292394219],
              [-39.977611525891312, -8.226550285511749],
              [-39.977611362615065, -8.226550281554943],
              [-39.97761119929379, -8.226550280525156],
              [-39.977611035980345, -8.226550282422563],
              [-39.977610872727588, -8.226550287246626],
              [-39.977610709588369, -8.226550294995844],
              [-39.977610546615487, -8.226550305667644],
              [-39.977610383861666, -8.226550319258555],
              [-39.977610221379621, -8.226550335764191],
              [-39.977610059221931, -8.226550355179187],
              [-39.977609897441091, -8.226550377497349],
              [-39.977609736089413, -8.226550402711425],
              [-39.977609575219176, -8.226550430813118],
              [-39.97760941488243, -8.226550461793552],
              [-39.977609255131064, -8.226550495642451],
              [-39.977609096016785, -8.226550532349121],
              [-39.977608937591086, -8.226550571901512],
              [-39.977608779905275, -8.226550614286793],
              [-39.977608623010347, -8.226550659491423],
              [-39.977608466957115, -8.226550707500582],
              [-39.977608311796075, -8.226550758298869],
              [-39.977608157577457, -8.226550811869801],
              [-39.977608004351147, -8.226550868195909],
              [-39.977607852166763, -8.226550927259217],
              [-39.977607701073559, -8.226550989040364],
              [-39.977607551120457, -8.226551053519518],
              [-39.97760740235595, -8.226551120675747],
              [-39.977607254828214, -8.226551190487333],
              [-39.977607108585005, -8.226551262931636],
              [-39.977606963673637, -8.226551337985258],
              [-39.977606820141027, -8.226551415623936],
              [-39.977606678033617, -8.226551495822394],
              [-39.977606537397406, -8.226551578554837],
              [-39.977606398277928, -8.226551663794398],
              [-39.977606260720208, -8.226551751513549],
              [-39.977606124768748, -8.22655184168384],
              [-39.97760599046758, -8.226551934276094],
              [-39.977605857860141, -8.226552029260414],
              [-39.97760572698936, -8.226552126605906],
              [-39.977605597897615, -8.226552226281216],
              [-39.977605470626656, -8.226552328254096],
              [-39.977605345217718, -8.226552432491372],
              [-39.977605221711364, -8.226552538959389],
              [-39.977605100147564, -8.226552647623748],
              [-39.977604980565694, -8.226552758449303],
              [-39.977604863004423, -8.226552871399983],
              [-39.977604747501807, -8.226552986439421],
              [-39.97760463409525, -8.226553103530287],
              [-39.977604522821437, -8.226553222634733],
              [-39.977604413716392, -8.226553343714224],
              [-39.977604306815451, -8.2265534667295],
              [-39.977604202153174, -8.226553591640803],
              [-39.97760409976344, -8.226553718407651],
              [-39.977603999679417, -8.226553846989127],
              [-39.977603901933492, -8.226553977343478],
              [-39.977603806557283, -8.226554109428644],
              [-39.977603713581679, -8.226554243201747],
              [-39.977603623036771, -8.226554378619481],
              [-39.977603534951847, -8.226554515638149],
              [-39.977603449355442, -8.226554654213306],
              [-39.977603366275247, -8.226554794300114],
              [-39.977603285738134, -8.226554935853349],
              [-39.977603207770215, -8.226555078826948],
              [-39.977603132396681, -8.226555223174911],
              [-39.97760305964195, -8.226555368850271],
              [-39.977602989529579, -8.226555515806085],
              [-39.977602922082227, -8.226555663994663],
              [-39.977602857321749, -8.226555813368082],
              [-39.977602795269107, -8.226555963877935],
              [-39.977602735944387, -8.226556115475592],
              [-39.977602679366782, -8.226556268111954],
              [-39.977602625554582, -8.226556421737632],
              [-39.977602574525271, -8.226556576302956],
              [-39.97760255111946, -8.226556614127299],
              [-39.977034058692659, -8.22833229165251],
              [-39.977034050135984, -8.22833226177822],
              [-39.97703403612406, -8.228332305163857],
              [-39.977034021892877, -8.228332348478677],
              [-39.977034007442782, -8.228332391721407],
              [-39.977033992774132, -8.228332434891136],
              [-39.977033977887295, -8.2283324779866],
              [-39.977033962782677, -8.228332521006802],
              [-39.977033947460626, -8.228332563950683],
              [-39.977033931921547, -8.228332606816991],
              [-39.977033916165851, -8.228332649604802],
              [-39.977033900193895, -8.228332692313018],
              [-39.977033884006154, -8.228332734940441],
              [-39.97703386760297, -8.228332777486099],
              [-39.977033850984789, -8.228332819948886],
              [-39.977033834152031, -8.228332862327612],
              [-39.977033817105131, -8.228332904621418],
              [-39.977033799844513, -8.228332946828985],
              [-39.977033782370619, -8.228332988949353],
              [-39.977033764683888, -8.228333030981609],
              [-39.97703374678477, -8.228333072924491],
              [-39.977033728673725, -8.228333114776889],
              [-39.977033710351186, -8.228333156537836],
              [-39.977033691817667, -8.228333198206389],
              [-39.977033673073606, -8.228333239781321],
              [-39.977033654119481, -8.228333281261612],
              [-39.977033634955788, -8.228333322646192],
              [-39.977033615582997, -8.228333363934059],
              [-39.977033596001604, -8.228333405124205],
              [-39.977033576212115, -8.228333446215364],
              [-39.977033556215041, -8.228333487206827],
              [-39.97703353601085, -8.228333528097311],
              [-39.977033515600084, -8.228333568885823],
              [-39.977033494983282, -8.228333609571386],
              [-39.977033474160919, -8.228333650152903],
              [-39.977033453133551, -8.228333690629359],
              [-39.977033431901724, -8.228333730999775],
              [-39.977033410465943, -8.228333771263044],
              [-39.977033388826769, -8.228333811418215],
              [-39.977033366984791, -8.228333851464246],
              [-39.977033344940487, -8.228333891400156],
              [-39.977033322694474, -8.228333931224801],
              [-39.9770333002473, -8.228333970937303],
              [-39.977033277599539, -8.228334010536615],
              [-39.977033254751746, -8.228334050021724],
              [-39.977033231704532, -8.228334089391687],
              [-39.977033208458451, -8.228334128645322],
              [-39.977033185014122, -8.228334167781785],
              [-39.977033161372127, -8.228334206800081],
              [-39.977033137533077, -8.228334245699182],
              [-39.977033113497541, -8.228334284478127],
              [-39.977033089266165, -8.228334323135902],
              [-39.97703306483956, -8.228334361671616],
              [-39.977033040218345, -8.228334400084165],
              [-39.977033015403144, -8.22833443837256],
              [-39.977032990394584, -8.228334476535956],
              [-39.977032965193288, -8.228334514573305],
              [-39.977032939799926, -8.228334552483597],
              [-39.977032914215108, -8.228334590265954],
              [-39.977032888439524, -8.228334627919397],
              [-39.977032862473784, -8.228334665442995],
              [-39.977032836318593, -8.228334702835742],
              [-39.977032809974567, -8.22833474009672],
              [-39.977032783442411, -8.228334777224925],
              [-39.977032756722785, -8.228334814219483],
              [-39.977032729816379, -8.228334851079428],
              [-39.977032702723861, -8.228334887803841],
              [-39.977032675445912, -8.228334924391731],
              [-39.977032647983258, -8.22833496084227],
              [-39.977032620336573, -8.228334997154429],
              [-39.977032592506553, -8.228335033327376],
              [-39.977032564493918, -8.228335069360051],
              [-39.977032536299362, -8.228335105251798],
              [-39.977032507923631, -8.228335141001452],
              [-39.977032479367431, -8.2283351766082],
              [-39.977032450631484, -8.228335212071132],
              [-39.977032421716522, -8.228335247389333],
              [-39.977032392623265, -8.228335282562004],
              [-39.977032363352471, -8.228335317588133],
              [-39.977032333904887, -8.228335352466818],
              [-39.977032304281238, -8.228335387197237],
              [-39.977032274482298, -8.228335421778521],
              [-39.977032244508806, -8.228335456209788],
              [-39.977032214361536, -8.228335490490101],
              [-39.977032184041256, -8.228335524618696],
              [-39.97703215354872, -8.228335558594615],
              [-39.977032122884729, -8.228335592416993],
              [-39.977032092050031, -8.228335626084974],
              [-39.977032061045421, -8.228335659597789],
              [-39.977032029871687, -8.228335692954449],
              [-39.977031998529633, -8.228335726154263],
              [-39.977031967020025, -8.228335759196298],
              [-39.97703193534371, -8.228335792079658],
              [-39.977031903501441, -8.228335824803686],
              [-39.977031871494056, -8.228335857367307],
              [-39.977031839322365, -8.228335889769909],
              [-39.977031806987185, -8.228335922010645],
              [-39.977031774489319, -8.228335954088534],
              [-39.977031741829606, -8.228335986002909],
              [-39.977031709008891, -8.228336017752918],
              [-39.97703167602819, -8.228336049336894],
              [-39.977031707638261, -8.228336095941412],
              [-39.975694495094444, -8.229610148885557],
              [-39.975694516182607, -8.229610159224855],
              [-39.975694438019055, -8.229610232824164],
              [-39.97569435898577, -8.229610305495937],
              [-39.975694279093844, -8.229610377229744],
              [-39.975694198354489, -8.229610448015643],
              [-39.975694116779053, -8.229610517843664],
              [-39.975694034378961, -8.229610586704075],
              [-39.975693951165802, -8.229610654587079],
              [-39.975693867151243, -8.229610721483199],
              [-39.975693782347065, -8.229610787383054],
              [-39.975693696765198, -8.22961085227745],
              [-39.975693610417636, -8.229610916157224],
              [-39.975693523316487, -8.229610979013408],
              [-39.975693435473993, -8.229611040837179],
              [-39.97569334690251, -8.229611101619945],
              [-39.975693257614395, -8.229611161353048],
              [-39.975693167622239, -8.229611220028159],
              [-39.975693076938668, -8.229611277637092],
              [-39.975692985576373, -8.229611334171613],
              [-39.975692893548214, -8.229611389623985],
              [-39.975692800867101, -8.229611443986302],
              [-39.975692707546031, -8.22961149725093],
              [-39.97569261359812, -8.229611549410361],
              [-39.975692519036521, -8.229611600457412],
              [-39.975692423874548, -8.229611650384884],
              [-39.975692328125511, -8.2296116991857],
              [-39.97569223180291, -8.229611746852946],
              [-39.975692134920216, -8.229611793380068],
              [-39.975692037491008, -8.229611838760567],
              [-39.975691939529021, -8.229611882987966],
              [-39.975691841047961, -8.229611926056043],
              [-39.975691742061656, -8.229611967958762],
              [-39.97569164258401, -8.229612008690365],
              [-39.975691542628986, -8.229612048245011],
              [-39.975691442210611, -8.229612086617193],
              [-39.975691341342973, -8.229612123801518],
              [-39.975691240040241, -8.229612159792779],
              [-39.975691138316613, -8.229612194585906],
              [-39.975691036186362, -8.229612228175963],
              [-39.975690933663863, -8.229612260558397],
              [-39.975690830763469, -8.229612291728493],
              [-39.975690727499632, -8.229612321682003],
              [-39.975690623886848, -8.229612350414614],
              [-39.975690519939654, -8.229612377922329],
              [-39.975690415672659, -8.229612404201376],
              [-39.975690311100479, -8.229612429247895],
              [-39.975690206237779, -8.229612453058532],
              [-39.975690101099303, -8.229612475629928],
              [-39.975689995699796, -8.229612496958767],
              [-39.975689890054042, -8.229612517042179],
              [-39.975689784176879, -8.229612535877422],
              [-39.975689678083164, -8.229612553461658],
              [-39.97568957178779, -8.229612569792513],
              [-39.975689465305678, -8.229612584867757],
              [-39.975689358651771, -8.229612598685183],
              [-39.975689251841033, -8.229612611242869],
              [-39.97568914488847, -8.229612622539054],
              [-39.975689037809055, -8.229612632572175],
              [-39.975688930617885, -8.2296126413408],
              [-39.975688823329946, -8.229612648843769],
              [-39.975688715960295, -8.229612655079928],
              [-39.975688608524045, -8.22961266004844],
              [-39.975688501036238, -8.22961266374862],
              [-39.975688393511973, -8.229612666180003],
              [-39.975688285966349, -8.229612667342083],
              [-39.975688178414444, -8.229612667234825],
              [-39.975688070871371, -8.229612665858294],
              [-39.975687963352222, -8.229612663212603],
              [-39.975687855872053, -8.229612659298017],
              [-39.975687748445992, -8.229612654115309],
              [-39.975687641089102, -8.229612647665052],
              [-39.975687533816462, -8.22961263994825],
              [-39.975687426643105, -8.229612630965828],
              [-39.975687319584068, -8.229612620719218],
              [-39.9756872126544, -8.229612609209779],
              [-39.975687105869113, -8.229612596439086],
              [-39.97568699924318, -8.229612582409031],
              [-39.975686892791558, -8.229612567121482],
              [-39.975686786529224, -8.229612550578741],
              [-39.97568668047105, -8.229612532782912],
              [-39.975686574631943, -8.229612513736701],
              [-39.975686469026762, -8.229612493442691],
              [-39.975686363670313, -8.229612471903604],
              [-39.975686258577397, -8.229612449122767],
              [-39.975686153762759, -8.229612425103054],
              [-39.975686049241091, -8.229612399847959],
              [-39.975685945027088, -8.229612373361107],
              [-39.975685841135373, -8.229612345646164],
              [-39.975685737580513, -8.229612316707003],
              [-39.975685634377072, -8.229612286547654],
              [-39.975685531539504, -8.229612255172439],
              [-39.975685429082262, -8.229612222585601],
              [-39.975685327019725, -8.229612188791963],
              [-39.975685225366213, -8.229612153796003],
              [-39.975685124135978, -8.229612117602823],
              [-39.975685023343246, -8.229612080217457],
              [-39.975684923002177, -8.229612041645131],
              [-39.975684823126812, -8.229612001891237],
              [-39.975684723731213, -8.229611960961412],
              [-39.975684624829569, -8.229611918860792],
              [-39.975684603906934, -8.229611891308378],
              [-39.975229437354741, -8.229414954086165],
              [-40.00326088164919, -8.247647373349308],
            ],
          ],
          [
            [
              [-39.962480248029955, -8.228677180961059],
              [-39.962480431343323, -8.228677904062486],
              [-39.962904303272545, -8.230031808509532],
              [-39.96290428010083, -8.230031824590787],
              [-39.962904299909468, -8.230031888706897],
              [-39.962904319240195, -8.230031952967586],
              [-39.962904338091946, -8.230032017369313],
              [-39.962904356463675, -8.230032081908607],
              [-39.962904374354402, -8.230032146581909],
              [-39.962904391763111, -8.230032211385589],
              [-39.962904408688857, -8.230032276316065],
              [-39.962904425130702, -8.230032341369888],
              [-39.962904441087765, -8.230032406543453],
              [-39.962904456559109, -8.230032471833123],
              [-39.962904471543965, -8.230032537235296],
              [-39.962904486041438, -8.230032602746437],
              [-39.962904500050769, -8.230032668362959],
              [-39.962904513571189, -8.230032734081101],
              [-39.962904526601939, -8.230032799897305],
              [-39.962904539142315, -8.230032865808012],
              [-39.962904551191599, -8.23003293180963],
              [-39.962904562749145, -8.230032997898434],
              [-39.962904573814328, -8.23003306407089],
              [-39.962904584386536, -8.230033130323203],
              [-39.962904594465172, -8.230033196651865],
              [-39.962904604049697, -8.230033263053073],
              [-39.962904613139564, -8.230033329523387],
              [-39.962904621734317, -8.230033396059008],
              [-39.962904629833417, -8.230033462656328],
              [-39.96290463743648, -8.23003352931164],
              [-39.962904644543052, -8.230033596021313],
              [-39.962904651152748, -8.230033662781651],
              [-39.962904657265227, -8.230033729589039],
              [-39.962904662880113, -8.230033796439679],
              [-39.962904667997137, -8.230033863330007],
              [-39.962904672615991, -8.230033930256404],
              [-39.962904676736422, -8.230033997214873],
              [-39.962904680358214, -8.230034064202163],
              [-39.962904683481163, -8.230034131214238],
              [-39.962904686105112, -8.230034198247585],
              [-39.962904688229884, -8.230034265298491],
              [-39.962904689855385, -8.230034332363276],
              [-39.962904690981517, -8.230034399438203],
              [-39.96290469160823, -8.230034466519569],
              [-39.962904691735488, -8.230034533603762],
              [-39.962904691363278, -8.230034600687064],
              [-39.962904690491598, -8.230034667765702],
              [-39.962904689120535, -8.230034734836119],
              [-39.962904687250152, -8.230034801894545],
              [-39.962904684880542, -8.230034868937334],
              [-39.962904682011832, -8.230034935960704],
              [-39.9629046786442, -8.230035002961092],
              [-39.962904674777803, -8.230035069934678],
              [-39.96290467041289, -8.230035136877939],
              [-39.962904665549672, -8.230035203787088],
              [-39.962904660188421, -8.230035270658398],
              [-39.962904654329449, -8.230035337488243],
              [-39.962904647973062, -8.230035404273055],
              [-39.962904641119636, -8.230035471008961],
              [-39.96290463376949, -8.230035537692434],
              [-39.962904625923073, -8.230035604319717],
              [-39.962904617580833, -8.230035670887274],
              [-39.96290460874318, -8.230035737391331],
              [-39.962904599410642, -8.230035803828196],
              [-39.962904589583708, -8.230035870194374],
              [-39.962904579262904, -8.230035936485987],
              [-39.962904568448856, -8.230036002699599],
              [-39.962904557142103, -8.230036068831428],
              [-39.962904545343299, -8.230036134877976],
              [-39.962904533053063, -8.23003620083545],
              [-39.962904520272097, -8.230036266700226],
              [-39.962904507001099, -8.230036332468858],
              [-39.962904493240806, -8.230036398137532],
              [-39.962904478991952, -8.23003646370287],
              [-39.962904464255331, -8.230036529160966],
              [-39.962904449031775, -8.230036594508393],
              [-39.96290443332208, -8.230036659741568],
              [-39.962904417127149, -8.23003672485679],
              [-39.962904400447847, -8.230036789850647],
              [-39.962904383285107, -8.230036854719371],
              [-39.962904365639879, -8.230036919459584],
              [-39.96290434751311, -8.230036984067549],
              [-39.962904328905822, -8.2300370485399],
              [-39.962904309819017, -8.230037112872862],
              [-39.962904290253768, -8.230037177063087],
              [-39.962904270211141, -8.230037241107015],
              [-39.962904249692215, -8.230037305000973],
              [-39.962904228698171, -8.230037368741577],
              [-39.962904207230103, -8.230037432325316],
              [-39.962904185289233, -8.23003749574867],
              [-39.962904162876754, -8.230037559008093],
              [-39.962904139993917, -8.230037622100246],
              [-39.962904116641944, -8.230037685021598],
              [-39.962904092822171, -8.230037747768531],
              [-39.96290406853587, -8.230037810337826],
              [-39.962904043784398, -8.230037872725928],
              [-39.962904018569084, -8.230037934929378],
              [-39.962903992891349, -8.230037996944823],
              [-39.962903966752599, -8.23003805876882],
              [-39.962903940154249, -8.230038120397916],
              [-39.962903913097797, -8.230038181828801],
              [-39.962903885584709, -8.230038243058104],
              [-39.962903857616304, -8.230038304082974],
              [-39.962903865078829, -8.230038321423837],
              [-39.962679165714889, -8.230523812928169],
              [-39.962679178661652, -8.230523760847293],
              [-39.962679142012668, -8.230523841058965],
              [-39.962679106150006, -8.230523921622604],
              [-39.962679071077019, -8.230524002530592],
              [-39.962679036797084, -8.230524083775219],
              [-39.962679003313433, -8.230524165348747],
              [-39.96267897062927, -8.230524247243435],
              [-39.962678938747651, -8.230524329451493],
              [-39.962678907671638, -8.230524411965201],
              [-39.96267887740418, -8.230524494776587],
              [-39.962678847948148, -8.230524577877913],
              [-39.96267881930634, -8.230524661261281],
              [-39.962678791481473, -8.230524744918698],
              [-39.962678764476166, -8.230524828842222],
              [-39.962678738293022, -8.230524913023901],
              [-39.962678712934526, -8.230524997455836],
              [-39.962678688403059, -8.230525082129894],
              [-39.962678664700967, -8.230525167038028],
              [-39.962678641830472, -8.230525252172253],
              [-39.962678619793785, -8.230525337524451],
              [-39.962678598592987, -8.230525423086542],
              [-39.962678578230069, -8.230525508850377],
              [-39.962678558707012, -8.23052559480775],
              [-39.9626785400256, -8.230525680950606],
              [-39.962678522187687, -8.230525767270766],
              [-39.962678505194908, -8.230525853759961],
              [-39.962678489048891, -8.230525940409974],
              [-39.962678473751197, -8.230526027212704],
              [-39.962678459303227, -8.230526114159737],
              [-39.962678445706388, -8.23052620124284],
              [-39.96267843296198, -8.230526288453868],
              [-39.962678421071182, -8.230526375784596],
              [-39.962678410035139, -8.230526463226498],
              [-39.962678399854887, -8.23052655077135],
              [-39.96267839053143, -8.230526638410836],
              [-39.962678382065626, -8.230526726136807],
              [-39.962678374458257, -8.230526813940667],
              [-39.962678367710069, -8.230526901814294],
              [-39.962678361821716, -8.23052698974924],
              [-39.962678356793731, -8.230527077737118],
              [-39.962678352626625, -8.230527165769612],
              [-39.962678349320747, -8.230527253838467],
              [-39.962678346876451, -8.230527341935211],
              [-39.962678345293938, -8.230527430051486],
              [-39.962678344573398, -8.23052751817894],
              [-39.962678344714867, -8.230527606309243],
              [-39.962678345718331, -8.230527694433908],
              [-39.962678347583704, -8.230527782544716],
              [-39.962678350310824, -8.230527870633248],
              [-39.962678353899406, -8.23052795869107],
              [-39.962678358349123, -8.230528046709875],
              [-39.962678363659549, -8.230528134681311],
              [-39.962678369830186, -8.230528222597053],
              [-39.962678376860417, -8.23052831044869],
              [-39.96267838474963, -8.230528398227879],
              [-39.962678393497029, -8.230528485926344],
              [-39.962678403101819, -8.230528573535763],
              [-39.962678413563069, -8.230528661047813],
              [-39.962678424879762, -8.230528748454022],
              [-39.962678437050855, -8.230528835746357],
              [-39.962678450075188, -8.230528922916335],
              [-39.962678463951541, -8.230529009955752],
              [-39.962678478678534, -8.230529096856321],
              [-39.962678494254845, -8.230529183609741],
              [-39.962678510678941, -8.230529270207947],
              [-39.962678527949301, -8.230529356642487],
              [-39.962678546064254, -8.230529442905347],
              [-39.962678565022095, -8.230529528988288],
              [-39.962678584821035, -8.230529614882999],
              [-39.962678605459161, -8.230529700581595],
              [-39.96267862693454, -8.230529786075694],
              [-39.962678649245134, -8.230529871357248],
              [-39.962678672388812, -8.23052995641811],
              [-39.962678696363369, -8.230530041250331],
              [-39.962678721166547, -8.230530125845766],
              [-39.962678746795987, -8.230530210196497],
              [-39.962678773249252, -8.230530294294354],
              [-39.962678800523832, -8.230530378131411],
              [-39.962678828617129, -8.230530461699766],
              [-39.962678857526484, -8.23053054499132],
              [-39.962678887249176, -8.230530627998396],
              [-39.962678917782313, -8.230530710712921],
              [-39.96267894912306, -8.230530793127162],
              [-39.962678981268439, -8.23053087523323],
              [-39.962679014215354, -8.230530957023364],
              [-39.962679047960712, -8.230531038489806],
              [-39.962679082501296, -8.230531119624729],
              [-39.962679117833829, -8.230531200420527],
              [-39.962679153954966, -8.230531280869476],
              [-39.962679190861252, -8.230531360964001],
              [-39.962679228549206, -8.230531440696506],
              [-39.962679267015261, -8.230531520059237],
              [-39.96267930625573, -8.230531599044868],
              [-39.962679346266903, -8.230531677645819],
              [-39.962679387044965, -8.230531755854573],
              [-39.962679428586085, -8.230531833663811],
              [-39.962679470886258, -8.230531911066082],
              [-39.962679513941531, -8.230531988054013],
              [-39.962679557747776, -8.230532064620403],
              [-39.962679602300852, -8.230532140757852],
              [-39.962679548941118, -8.230532132725392],
              [-39.963595492803172, -8.232080031334254],
              [-39.963595521729431, -8.232079979891882],
              [-39.963595635215988, -8.232080177309459],
              [-39.963595743682362, -8.232080377508119],
              [-39.96359584705997, -8.232080580361357],
              [-39.963595945283366, -8.232080785740816],
              [-39.963596038290419, -8.232080993516369],
              [-39.963596126022281, -8.232081203556776],
              [-39.963596208423432, -8.232081415729118],
              [-39.963596285441731, -8.232081629899058],
              [-39.963596357028457, -8.232081845930994],
              [-39.963596423138306, -8.232082063688535],
              [-39.963596483729475, -8.232082283033623],
              [-39.963596538763575, -8.232082503827556],
              [-39.963596588205839, -8.232082725930679],
              [-39.963596632024952, -8.232082949202384],
              [-39.963596670193198, -8.232083173501431],
              [-39.963596702686409, -8.232083398685953],
              [-39.963596729484053, -8.23208362461339],
              [-39.963596750569153, -8.232083851140862],
              [-39.963596765928386, -8.232084078124961],
              [-39.963596775552027, -8.232084305422177],
              [-39.963596779433992, -8.232084532888555],
              [-39.963596777571794, -8.232084760380303],
              [-39.963596769966657, -8.232084987753321],
              [-39.963596756623375, -8.232085214864],
              [-39.963596737550354, -8.232085441568396],
              [-39.963596712759724, -8.232085667723071],
              [-39.963596682267131, -8.232085893185138],
              [-39.963596646091879, -8.232086117811772],
              [-39.963596604256843, -8.232086341460843],
              [-39.963596556788538, -8.23208656399091],
              [-39.96359650371695, -8.232086785261174],
              [-39.963596445075673, -8.232087005131641],
              [-39.963596380901826, -8.232087223463123],
              [-39.963596311235996, -8.232087440117509],
              [-39.963596236122257, -8.232087654957761],
              [-39.963596155608158, -8.232087867847834],
              [-39.963596069744639, -8.23208807865311],
              [-39.963595978586014, -8.23208828724027],
              [-39.963595882189978, -8.232088493477068],
              [-39.963595780617489, -8.232088697233337],
              [-39.963595673932879, -8.232088898380075],
              [-39.963595562203587, -8.232089096789815],
              [-39.96359544550031, -8.232089292337232],
              [-39.963595323896939, -8.232089484898532],
              [-39.963595197470383, -8.232089674351796],
              [-39.963595066300648, -8.232089860577208],
              [-39.963594930470698, -8.232090043457069],
              [-39.963594790066516, -8.232090222875472],
              [-39.963594645176926, -8.23209039871896],
              [-39.963594495893602, -8.232090570876249],
              [-39.963594342310977, -8.232090739238613],
              [-39.963594184526279, -8.232090903699138],
              [-39.963594022639292, -8.232091064154076],
              [-39.963593856752468, -8.232091220501859],
              [-39.963593686970789, -8.232091372643451],
              [-39.963593513401655, -8.232091520482591],
              [-39.963593336154865, -8.232091663925855],
              [-39.9635931553426, -8.232091802882408],
              [-39.963592971079279, -8.232091937264343],
              [-39.963592783481459, -8.232092066986677],
              [-39.963592592667865, -8.232092191967221],
              [-39.963592398759204, -8.232092312126976],
              [-39.963592201878186, -8.232092427389858],
              [-39.96359200214939, -8.232092537683018],
              [-39.963591799699145, -8.232092642936655],
              [-39.963591594655604, -8.232092743084106],
              [-39.963591387148469, -8.232092838061989],
              [-39.963591177309048, -8.232092927810333],
              [-39.9635909652701, -8.232093012272342],
              [-39.963590751165825, -8.232093091394454],
              [-39.963590535131637, -8.232093165126599],
              [-39.963590317304273, -8.232093233422221],
              [-39.96359009782153, -8.232093296238141],
              [-39.963589876822311, -8.232093353534543],
              [-39.963589654446416, -8.232093405275151],
              [-39.963589430834553, -8.232093451427229],
              [-39.963589206128241, -8.232093491961681],
              [-39.963588980469616, -8.23209352685274],
              [-39.963588754001485, -8.232093556078361],
              [-39.963588526867113, -8.232093579620017],
              [-39.963588299210251, -8.232093597462903],
              [-39.963588071174911, -8.232093609595733],
              [-39.96358784290544, -8.232093616010745],
              [-39.963587614546171, -8.232093616703967],
              [-39.96358738624167, -8.232093611674854],
              [-39.963587158136342, -8.232093600926612],
              [-39.963586930374582, -8.232093584466156],
              [-39.963586703100439, -8.232093562303822],
              [-39.963586476457721, -8.232093534453622],
              [-39.963586250589863, -8.232093500933109],
              [-39.963586025639763, -8.232093461763629],
              [-39.963585801749751, -8.232093416969903],
              [-39.963585579061501, -8.232093366580253],
              [-39.963585357715885, -8.232093310626626],
              [-39.963585137852995, -8.232093249144356],
              [-39.963584919611911, -8.232093182172411],
              [-39.963584703130728, -8.232093109753077],
              [-39.963584488546417, -8.232093031932237],
              [-39.963584275994108, -8.232092948759398],
              [-39.963584266812269, -8.232092991547908],
              [-39.962872542153761, -8.23180410646974],
              [-39.961142773440542, -8.231107319223453],
              [-39.961142797969778, -8.231107320624581],
              [-39.961142741215689, -8.231107297540353],
              [-39.961142684619332, -8.231107274074887],
              [-39.961142628183318, -8.23110725022914],
              [-39.961142571910209, -8.231107226004353],
              [-39.961142515802592, -8.231107201401596],
              [-39.961142459863019, -8.231107176421906],
              [-39.961142404094012, -8.231107151066444],
              [-39.961142348498143, -8.23110712533645],
              [-39.961142293077941, -8.231107099233025],
              [-39.961142237835951, -8.23110707275748],
              [-39.961142182774644, -8.231107045910749],
              [-39.961142127896565, -8.231107018694329],
              [-39.961142073204222, -8.231106991109442],
              [-39.961142018700102, -8.231106963157179],
              [-39.961141964386655, -8.231106934838904],
              [-39.961141910266392, -8.231106906155977],
              [-39.961141856341797, -8.231106877109518],
              [-39.961141802615302, -8.231106847701],
              [-39.961141749089357, -8.23110681793176],
              [-39.961141695766401, -8.231106787803157],
              [-39.961141642648897, -8.231106757316494],
              [-39.961141589739206, -8.231106726473216],
              [-39.961141537039808, -8.231106695274741],
              [-39.96114148455306, -8.231106663722491],
              [-39.961141432281387, -8.231106631817811],
              [-39.961141380227147, -8.231106599562246],
              [-39.961141328392706, -8.231106566957216],
              [-39.961141276780452, -8.231106534004288],
              [-39.96114122539273, -8.231106500704939],
              [-39.961141174231898, -8.231106467060657],
              [-39.961141123300251, -8.231106433072975],
              [-39.961141072600142, -8.231106398743385],
              [-39.961141022133866, -8.231106364073549],
              [-39.961140971903745, -8.23110632906508],
              [-39.961140921912055, -8.231106293719401],
              [-39.961140872161067, -8.231106258038345],
              [-39.961140822653078, -8.231106222023323],
              [-39.961140773390312, -8.231106185676147],
              [-39.961140724375028, -8.231106148998382],
              [-39.961140675609471, -8.231106111991684],
              [-39.961140627095865, -8.231106074657905],
              [-39.9611405788364, -8.231106036998554],
              [-39.961140530833283, -8.231105999015448],
              [-39.961140483088727, -8.2311059607103],
              [-39.961140435604889, -8.231105922084787],
              [-39.961140388383932, -8.231105883140753],
              [-39.961140341427999, -8.231105843879885],
              [-39.961140294739273, -8.231105804304132],
              [-39.961140248319843, -8.231105764415233],
              [-39.961140202171855, -8.231105724214908],
              [-39.961140156297382, -8.231105683705072],
              [-39.961140110698523, -8.231105642887536],
              [-39.961140065377371, -8.231105601764268],
              [-39.961140020335996, -8.231105560337001],
              [-39.961139975576408, -8.2311055186077],
              [-39.96113993110071, -8.231105476578257],
              [-39.961139886910914, -8.231105434250594],
              [-39.961139843009001, -8.23110539162656],
              [-39.961139799397003, -8.231105348708267],
              [-39.961139756076896, -8.231105305497508],
              [-39.961139713050642, -8.231105261996383],
              [-39.96113967032025, -8.231105218206723],
              [-39.961139627887611, -8.231105174130668],
              [-39.961139585754701, -8.231105129770174],
              [-39.96113954392343, -8.231105085127226],
              [-39.961139502395696, -8.231105040203936],
              [-39.961139461173403, -8.231104995002323],
              [-39.961139420258419, -8.23110494952437],
              [-39.961139379652614, -8.231104903772303],
              [-39.961139339357864, -8.231104857748061],
              [-39.961139299375958, -8.231104811453836],
              [-39.961139259708759, -8.231104764891679],
              [-39.961139220358056, -8.231104718063778],
              [-39.961139181325656, -8.231104670972188],
              [-39.961139142613348, -8.231104623619194],
              [-39.961139104222845, -8.231104576006755],
              [-39.961139066155958, -8.231104528137244],
              [-39.961139028414394, -8.231104480012613],
              [-39.961138990999878, -8.231104431635329],
              [-39.961138953914109, -8.231104383007349],
              [-39.961138917158799, -8.231104334131052],
              [-39.961138880735611, -8.231104285008584],
              [-39.961138844646193, -8.231104235642309],
              [-39.961138808892208, -8.231104186034282],
              [-39.961138773475277, -8.231104136186991],
              [-39.961138738397025, -8.231104086102501],
              [-39.961138703659024, -8.231104035783172],
              [-39.961138669262908, -8.23110398523132],
              [-39.961138635210204, -8.231103934449237],
              [-39.961138601502483, -8.231103883439259],
              [-39.961138568141266, -8.231103832203623],
              [-39.961138535128093, -8.231103780744835],
              [-39.961138502464451, -8.231103729065067],
              [-39.961138470151845, -8.231103677166772],
              [-39.96113843819176, -8.231103625052301],
              [-39.96113840658559, -8.231103572723985],
              [-39.961138375334848, -8.231103520184373],
              [-39.96113834444094, -8.23110346743557],
              [-39.961138313904925, -8.231103414481302],
              [-39.961138315641634, -8.231103440857941],
              [-39.960110939210111, -8.229307807852097],
              [-39.960110970634013, -8.229307764192521],
              [-39.960110948439656, -8.229307725620391],
              [-39.960110926055222, -8.229307687157407],
              [-39.960110903481272, -8.229307648804513],
              [-39.960110880718346, -8.22930761056258],
              [-39.960110857766999, -8.229307572432703],
              [-39.960110834627777, -8.229307534415607],
              [-39.960110811301234, -8.229307496512265],
              [-39.960110787787954, -8.229307458723708],
              [-39.960110764088498, -8.229307421050734],
              [-39.960110740203419, -8.229307383494227],
              [-39.960110716133329, -8.229307346055203],
              [-39.960110691878789, -8.229307308734551],
              [-39.960110667440361, -8.229307271533083],
              [-39.960110642818691, -8.229307234451731],
              [-39.960110618014326, -8.22930719749135],
              [-39.960110593027885, -8.229307160652979],
              [-39.960110567859992, -8.229307123937412],
              [-39.960110542511217, -8.229307087345513],
              [-39.960110516982184, -8.229307050878248],
              [-39.960110491273518, -8.22930701453641],
              [-39.960110465385839, -8.22930697832089],
              [-39.960110439319763, -8.229306942232686],
              [-39.960110413075952, -8.229306906272535],
              [-39.960110386654996, -8.229306870441304],
              [-39.96011036005757, -8.22930683473991],
              [-39.960110333284291, -8.229306799169191],
              [-39.960110306335793, -8.229306763730019],
              [-39.960110279212778, -8.229306728423225],
              [-39.960110251915864, -8.229306693249727],
              [-39.960110224445728, -8.229306658210385],
              [-39.960110196803029, -8.229306623305852],
              [-39.9601101689884, -8.229306588537211],
              [-39.960110141002573, -8.229306553905278],
              [-39.960110112846188, -8.229306519410713],
              [-39.96011008451994, -8.229306485054526],
              [-39.960110056024511, -8.229306450837402],
              [-39.960110027360557, -8.229306416760355],
              [-39.960109998528822, -8.229306382824035],
              [-39.960109969529981, -8.229306349029217],
              [-39.960109940364717, -8.229306315376967],
              [-39.960109911033761, -8.229306281867929],
              [-39.960109881537811, -8.229306248502917],
              [-39.960109851877583, -8.229306215282806],
              [-39.960109822053802, -8.22930618220825],
              [-39.960109792067165, -8.229306149280239],
              [-39.960109761918403, -8.229306116499416],
              [-39.960109731608277, -8.229306083866748],
              [-39.960109701137469, -8.229306051382798],
              [-39.96010967050676, -8.229306019048535],
              [-39.960109639716862, -8.229305986864622],
              [-39.960109608768526, -8.229305954831927],
              [-39.960109577662507, -8.22930592295112],
              [-39.960109546399543, -8.229305891223062],
              [-39.960109514980424, -8.229305859648512],
              [-39.960109483405844, -8.229305828228259],
              [-39.960109451676637, -8.229305796962954],
              [-39.960109419793525, -8.229305765853489],
              [-39.960109387757278, -8.229305734900471],
              [-39.96010935556869, -8.229305704104654],
              [-39.960109323228551, -8.229305673466929],
              [-39.960109290737599, -8.229305642987935],
              [-39.960109258096665, -8.229305612668465],
              [-39.96010922530651, -8.229305582509172],
              [-39.960109192367931, -8.22930555251086],
              [-39.960109159281728, -8.229305522674256],
              [-39.9601091260487, -8.229305492999977],
              [-39.96010909266964, -8.229305463488744],
              [-39.960109059145367, -8.229305434141489],
              [-39.96010902547669, -8.229305404958714],
              [-39.960108991664413, -8.229305375941165],
              [-39.960108957709366, -8.229305347089575],
              [-39.960108923612367, -8.229305318404514],
              [-39.960108889374233, -8.229305289886872],
              [-39.960108854995809, -8.229305261537315],
              [-39.960108820477906, -8.229305233356419],
              [-39.960108785821355, -8.229305205344895],
              [-39.960108751027001, -8.229305177503353],
              [-39.960108716095682, -8.229305149832653],
              [-39.96010868102826, -8.22930512233336],
              [-39.960108645825571, -8.22930509500608],
              [-39.960108610488454, -8.229305067851559],
              [-39.960108575017784, -8.229305040870488],
              [-39.960108539414392, -8.229305014063382],
              [-39.960108503679166, -8.229304987430952],
              [-39.960108467812944, -8.229304960973863],
              [-39.960108431816622, -8.229304934692772],
              [-39.960108395691051, -8.22930490858821],
              [-39.960108359437086, -8.229304882660937],
              [-39.960108323055643, -8.229304856911524],
              [-39.960108286547559, -8.229304831340544],
              [-39.96010824991378, -8.229304805948708],
              [-39.960108213155102, -8.229304780736578],
              [-39.960108176272485, -8.229304755704835],
              [-39.960108139266815, -8.22930473085391],
              [-39.960108102138939, -8.229304706184603],
              [-39.960108064889802, -8.229304681697377],
              [-39.96010802752027, -8.229304657392865],
              [-39.960107990031268, -8.22930463327171],
              [-39.960107952423691, -8.229304609334406],
              [-39.960107935724373, -8.229304649537182],
              [-39.95953994096498, -8.228945049816396],
              [-39.959539905659362, -8.228945001123824],
              [-39.959539779646335, -8.228944922784038],
              [-39.959539652363617, -8.228944846508634],
              [-39.959539523845294, -8.2289447723181],
              [-39.959539394125748, -8.228944700232283],
              [-39.959539263239748, -8.228944630270387],
              [-39.959539131222272, -8.228944562451268],
              [-39.959538998108719, -8.228944496792964],
              [-39.959538863934682, -8.228944433313149],
              [-39.959538728736106, -8.228944372028749],
              [-39.959538592549173, -8.228944312956187],
              [-39.959538455410339, -8.228944256111347],
              [-39.959538317356298, -8.228944201509407],
              [-39.959538178424033, -8.228944149164894],
              [-39.959538038650727, -8.228944099091979],
              [-39.959537898073805, -8.228944051303891],
              [-39.959537756730896, -8.228944005813535],
              [-39.95953761465983, -8.228943962633094],
              [-39.959537471898621, -8.228943921774043],
              [-39.959537328485517, -8.228943883247453],
              [-39.959537184458895, -8.228943847063492],
              [-39.95953703985731, -8.228943813231856],
              [-39.959536894719484, -8.228943781761789],
              [-39.959536749084251, -8.228943752661515],
              [-39.959536602990603, -8.228943725938864],
              [-39.959536456477686, -8.228943701601082],
              [-39.959536309584657, -8.228943679654616],
              [-39.959536162350865, -8.228943660105331],
              [-39.959536014815747, -8.228943642958528],
              [-39.959535867018758, -8.228943628218696],
              [-39.959535718999497, -8.228943615889811],
              [-39.95953557079757, -8.228943605975221],
              [-39.959535422452653, -8.228943598477557],
              [-39.959535274004473, -8.22894359339878],
              [-39.959535125492749, -8.228943590740279],
              [-39.959534976957244, -8.228943590502869],
              [-39.959534828437718, -8.228943592686486],
              [-39.959534679973942, -8.228943597290481],
              [-39.959534531605627, -8.228943604313828],
              [-39.959534383372542, -8.228943613754588],
              [-39.959534235314322, -8.228943625610082],
              [-39.95953408747063, -8.228943639877254],
              [-39.95953393988102, -8.2289436565523],
              [-39.959533792585013, -8.228943675630783],
              [-39.959533645622031, -8.228943697107432],
              [-39.959533499031437, -8.228943720976684],
              [-39.959533352852468, -8.228943747232091],
              [-39.959533207124238, -8.228943775866481],
              [-39.959533061885786, -8.228943806872458],
              [-39.959532917175949, -8.228943840241497],
              [-39.959532773033509, -8.228943875964687],
              [-39.959532629497048, -8.228943914032559],
              [-39.959532486604928, -8.228943954434957],
              [-39.959532344395498, -8.228943997160902],
              [-39.959532202906729, -8.228944042199082],
              [-39.959532062176578, -8.228944089537483],
              [-39.959531922242682, -8.228944139163247],
              [-39.959531783142516, -8.228944191063308],
              [-39.959531644913284, -8.228944245223566],
              [-39.959531507592025, -8.228944301629625],
              [-39.95953137121549, -8.228944360266441],
              [-39.959531235820165, -8.228944421118277],
              [-39.95953110144233, -8.228944484168787],
              [-39.959530968117932, -8.228944549401042],
              [-39.959530835882674, -8.228944616797808],
              [-39.959530704771943, -8.228944686340784],
              [-39.95953057482086, -8.228944758011531],
              [-39.959530446064178, -8.228944831790827],
              [-39.959530318536402, -8.228944907658892],
              [-39.959530192271643, -8.228944985595366],
              [-39.959530067303696, -8.228945065579531],
              [-39.959529943666062, -8.228945147589759],
              [-39.959529821391783, -8.228945231604291],
              [-39.959529700513613, -8.228945317600576],
              [-39.959529581063926, -8.228945405555523],
              [-39.959529463074681, -8.228945495445714],
              [-39.959529346577462, -8.228945587246994],
              [-39.959529231603462, -8.22894568093481],
              [-39.959529118183461, -8.228945776484073],
              [-39.959529006347807, -8.22894587386936],
              [-39.95952889612645, -8.228945973064317],
              [-39.959528787548898, -8.228946074042478],
              [-39.959528680644219, -8.228946176776931],
              [-39.959528575441013, -8.228946281240143],
              [-39.959528471967467, -8.228946387404102],
              [-39.959528370251263, -8.2289464952404],
              [-39.959528270319645, -8.228946604720212],
              [-39.959528172199335, -8.228946715814077],
              [-39.95952807591663, -8.228946828492454],
              [-39.959527981497295, -8.228946942725141],
              [-39.959527888966612, -8.228947058481417],
              [-39.959527798349328, -8.228947175730429],
              [-39.959527709669722, -8.228947294440758],
              [-39.959527622951541, -8.22894741458056],
              [-39.959527538217976, -8.22894753611777],
              [-39.959527455491717, -8.2289476590199],
              [-39.959527374794924, -8.22894778325384],
              [-39.959527296149183, -8.228947908786511],
              [-39.959527219575541, -8.228948035584247],
              [-39.959527145094512, -8.228948163613046],
              [-39.959527147862481, -8.228948155417232],
              [-39.959277815509139, -8.229384865233742],
              [-39.959277864130222, -8.229384859600122],
              [-39.959277836954946, -8.229384907545345],
              [-39.959277810073168, -8.22938495565454],
              [-39.959277783485895, -8.229385003925868],
              [-39.95927775719408, -8.22938505235752],
              [-39.959277731198704, -8.229385100947761],
              [-39.959277705500767, -8.229385149694739],
              [-39.959277680101188, -8.229385198596662],
              [-39.959277655000911, -8.229385247651797],
              [-39.959277630200852, -8.229385296858164],
              [-39.959277605701985, -8.229385346214114],
              [-39.959277581505155, -8.229385395717692],
              [-39.959277557611294, -8.229385445367058],
              [-39.959277534021275, -8.229385495160471],
              [-39.959277510735973, -8.229385545096083],
              [-39.959277487756275, -8.229385595171866],
              [-39.959277465082991, -8.229385645386204],
              [-39.959277442716989, -8.229385695737086],
              [-39.959277420659099, -8.229385746222723],
              [-39.959277398910125, -8.2293857968412],
              [-39.959277377470876, -8.229385847590624],
              [-39.959277356342142, -8.229385898469118],
              [-39.959277335524725, -8.229385949474809],
              [-39.959277315019385, -8.229386000605839],
              [-39.959277294826883, -8.229386051860317],
              [-39.959277274947951, -8.229386103236298],
              [-39.959277255383363, -8.229386154731827],
              [-39.959277236133822, -8.229386206345101],
              [-39.959277217200025, -8.229386258074161],
              [-39.959277198582697, -8.229386309917084],
              [-39.959277180282527, -8.229386361872022],
              [-39.959277162300204, -8.229386413936915],
              [-39.959277144636346, -8.229386466109958],
              [-39.959277127291642, -8.229386518389175],
              [-39.959277110266754, -8.229386570772585],
              [-39.959277093562271, -8.229386623258327],
              [-39.959277077178839, -8.229386675844307],
              [-39.959277061117078, -8.229386728528747],
              [-39.959277045377561, -8.229386781309557],
              [-39.959277029960859, -8.229386834184924],
              [-39.95927701486756, -8.229386887152849],
              [-39.959277000098226, -8.229386940211272],
              [-39.959276985653403, -8.22938699335827],
              [-39.959276971533626, -8.229387046591926],
              [-39.959276957739412, -8.229387099910216],
              [-39.959276944271281, -8.229387153311265],
              [-39.959276931129722, -8.229387206792868],
              [-39.959276918315254, -8.229387260353258],
              [-39.95927690582829, -8.229387313990388],
              [-39.959276893669362, -8.229387367702257],
              [-39.959276881838854, -8.229387421486861],
              [-39.959276870337263, -8.229387475342227],
              [-39.959276859164973, -8.229387529266257],
              [-39.959276848322432, -8.229387583257157],
              [-39.959276837810016, -8.229387637312838],
              [-39.959276827628123, -8.229387691431251],
              [-39.959276817777145, -8.229387745610314],
              [-39.959276808257428, -8.229387799848221],
              [-39.959276799069322, -8.229387854142869],
              [-39.959276790213174, -8.229387908492146],
              [-39.959276781689326, -8.229387962894183],
              [-39.959276773498075, -8.229388017346977],
              [-39.959276765639736, -8.229388071848353],
              [-39.959276758114591, -8.229388126396403],
              [-39.959276750922911, -8.229388180989092],
              [-39.959276744065008, -8.229388235624262],
              [-39.959276737541089, -8.229388290300175],
              [-39.959276731351395, -8.229388345014547],
              [-39.959276725496181, -8.229388399765549],
              [-39.959276719975655, -8.229388454550943],
              [-39.959276714790022, -8.229388509368894],
              [-39.959276709939473, -8.229388564217178],
              [-39.959276705424188, -8.229388619093973],
              [-39.959276701244328, -8.229388673997072],
              [-39.959276697400057, -8.229388728924537],
              [-39.95927669389151, -8.229388783874331],
              [-39.959276690718838, -8.229388838844315],
              [-39.95927668788211, -8.229388893832484],
              [-39.959276685381475, -8.229388948836961],
              [-39.95927668321702, -8.229389003855445],
              [-39.959276681388836, -8.229389058886166],
              [-39.959276679896938, -8.229389113926846],
              [-39.959276678741439, -8.229389168975692],
              [-39.959276677922354, -8.229389224030383],
              [-39.959276677439732, -8.22938927908919],
              [-39.959276677293559, -8.229389334149705],
              [-39.959276677483864, -8.229389389210258],
              [-39.959276678010646, -8.229389444268545],
              [-39.959276678873856, -8.229389499322588],
              [-39.959276680073494, -8.229389554370389],
              [-39.959276681609495, -8.229389609409992],
              [-39.959276683481811, -8.229389664439204],
              [-39.959276685690369, -8.229389719456027],
              [-39.959276688235107, -8.229389774458397],
              [-39.959276691115903, -8.229389829444315],
              [-39.959276694332651, -8.229389884411797],
              [-39.959276697885244, -8.229389939358763],
              [-39.959276701773533, -8.22938999428313],
              [-39.959276705997404, -8.229390049182909],
              [-39.959276710556686, -8.229390104056028],
              [-39.959276738722565, -8.229390113478434],
              [-39.959408328713664, -8.230917375560196],
              [-39.959408306509822, -8.230917352145918],
              [-39.959408309082413, -8.23091738144314],
              [-39.959408311750636, -8.23091741073185],
              [-39.959408314514413, -8.230917440011805],
              [-39.959408317373779, -8.230917469282668],
              [-39.959408320328649, -8.230917498544091],
              [-39.959408323379016, -8.230917527795784],
              [-39.959408326524851, -8.230917557037492],
              [-39.95940832976612, -8.230917586268838],
              [-39.959408333102758, -8.230917615489457],
              [-39.959408336534771, -8.230917644699199],
              [-39.959408340062097, -8.230917673897547],
              [-39.959408343684714, -8.230917703084479],
              [-39.959408347402587, -8.230917732259414],
              [-39.959408351215657, -8.230917761422186],
              [-39.959408355123898, -8.230917790572441],
              [-39.959408359127259, -8.230917819709795],
              [-39.959408363225698, -8.23091784883419],
              [-39.959408367419194, -8.230917877945048],
              [-39.959408371707674, -8.230917907042201],
              [-39.959408376091105, -8.230917936125298],
              [-39.959408380569457, -8.230917965194067],
              [-39.95940838514263, -8.230917994248189],
              [-39.95940838981064, -8.230918023287295],
              [-39.959408394573401, -8.23091805231113],
              [-39.959408399430849, -8.230918081319395],
              [-39.959408404382998, -8.230918110311761],
              [-39.959408409429706, -8.230918139287981],
              [-39.959408414570994, -8.230918168247626],
              [-39.95940841980677, -8.230918197190499],
              [-39.959408425136999, -8.230918226116211],
              [-39.959408430561588, -8.230918255024619],
              [-39.95940843608053, -8.230918283915182],
              [-39.959408441693718, -8.230918312787743],
              [-39.959408447401152, -8.230918341642024],
              [-39.959408453202705, -8.23091837047763],
              [-39.959408459098356, -8.230918399294284],
              [-39.959408465088025, -8.230918428091705],
              [-39.959408471171656, -8.23091845686948],
              [-39.9594084773492, -8.230918485627489],
              [-39.959408483620571, -8.230918514365282],
              [-39.959408489985698, -8.230918543082698],
              [-39.959408496444553, -8.230918571779272],
              [-39.959408502997015, -8.230918600454698],
              [-39.959408509643048, -8.230918629108841],
              [-39.959408516382567, -8.230918657741334],
              [-39.959408523215515, -8.230918686351808],
              [-39.959408530141808, -8.230918714939971],
              [-39.959408537161366, -8.230918743505505],
              [-39.95940854427414, -8.230918772048193],
              [-39.959408551480017, -8.230918800567784],
              [-39.959408558778961, -8.23091882906372],
              [-39.959408566170886, -8.230918857536013],
              [-39.959408573655679, -8.230918885984115],
              [-39.959408581233298, -8.230918914407855],
              [-39.959408588903671, -8.230918942806877],
              [-39.959408596666663, -8.230918971180964],
              [-39.95940860452226, -8.230918999529697],
              [-39.959408612470327, -8.230919027852774],
              [-39.959408620510793, -8.23091905615005],
              [-39.959408628643594, -8.230919084421069],
              [-39.959408636868623, -8.230919112665617],
              [-39.959408645185796, -8.230919140883366],
              [-39.95940865359502, -8.23091916907404],
              [-39.95940866209623, -8.23091919723737],
              [-39.959408670689321, -8.230919225372954],
              [-39.959408679374192, -8.23091925348049],
              [-39.959408688150745, -8.230919281559842],
              [-39.95940869701893, -8.230919309610508],
              [-39.95940870597861, -8.230919337632448],
              [-39.959408715029703, -8.230919365625164],
              [-39.959408724172128, -8.23091939358831],
              [-39.959408733405766, -8.230919421521795],
              [-39.959408742730531, -8.230919449425198],
              [-39.959408752146317, -8.23091947729819],
              [-39.959408761653052, -8.230919505140557],
              [-39.959408771250594, -8.230919532952081],
              [-39.959408780938872, -8.230919560732225],
              [-39.959408790717774, -8.230919588480884],
              [-39.959408800587184, -8.230919616197793],
              [-39.959408810547025, -8.230919643882512],
              [-39.959408820597169, -8.230919671534837],
              [-39.959408830737523, -8.230919699154502],
              [-39.959408840967953, -8.230919726741103],
              [-39.95940885128838, -8.230919754294465],
              [-39.959408861698698, -8.230919781814146],
              [-39.959408872198779, -8.230919809300087],
              [-39.959408882788516, -8.230919836751868],
              [-39.95940889346781, -8.230919864169069],
              [-39.959408904236518, -8.230919891551622],
              [-39.959408915094563, -8.230919918899204],
              [-39.959408926041803, -8.230919946211403],
              [-39.959408937078145, -8.230919973487959],
              [-39.959408948203439, -8.230920000728625],
              [-39.959408959417594, -8.230920027933198],
              [-39.959408970720496, -8.230920055101203],
              [-39.959408982112009, -8.230920082232453],
              [-39.959408993592014, -8.230920109326703],
              [-39.959409005160389, -8.230920136383631],
              [-39.959409016817027, -8.230920163402898],
              [-39.959408970949617, -8.230920177748271],
              [-39.959988583211292, -8.232257524756296],
              [-39.959988856871455, -8.232258066757961],
              [-39.960812391163039, -8.233825815635255],
              [-39.970916936010916, -8.228429350554485],
              [-39.969603282974127, -8.228606963877056],
              [-39.969603261616157, -8.22860696615566],
              [-39.969603153533122, -8.228606979869639],
              [-39.969603045292651, -8.228606992290148],
              [-39.969602936910306, -8.228607003415394],
              [-39.969602828401719, -8.228607013243646],
              [-39.969602719782536, -8.228607021773611],
              [-39.969602611068389, -8.228607029004056],
              [-39.969602502274974, -8.228607034933889],
              [-39.969602393417944, -8.228607039562222],
              [-39.96960228451298, -8.228607042888546],
              [-39.969602175575787, -8.228607044912255],
              [-39.969602066622052, -8.228607045632991],
              [-39.969601957667493, -8.228607045050827],
              [-39.969601848727791, -8.228607043165717],
              [-39.969601739818657, -8.228607039977987],
              [-39.969601630955772, -8.22860703548816],
              [-39.969601522154854, -8.2286070296967],
              [-39.969601413431533, -8.22860702260458],
              [-39.969601304801493, -8.228607014212775],
              [-39.969601196280401, -8.228607004522594],
              [-39.969601087883888, -8.22860699353526],
              [-39.969600979627572, -8.228606981252499],
              [-39.969600871527042, -8.228606967675976],
              [-39.96960076359791, -8.228606952807748],
              [-39.969600655855672, -8.228606936649888],
              [-39.969600548315903, -8.228606919204745],
              [-39.969600440994085, -8.228606900474881],
              [-39.969600333905667, -8.228606880462896],
              [-39.969600227066103, -8.228606859171727],
              [-39.969600120490746, -8.228606836604412],
              [-39.969600014194995, -8.228606812764268],
              [-39.969599908194155, -8.2286067876547],
              [-39.96959980250351, -8.228606761279268],
              [-39.969599697138271, -8.228606733641849],
              [-39.969599592113646, -8.228606704746465],
              [-39.969599487444718, -8.228606674597136],
              [-39.969599383146637, -8.228606643198297],
              [-39.969599279234387, -8.228606610554476],
              [-39.969599175722948, -8.228606576670344],
              [-39.969599072627226, -8.228606541550779],
              [-39.969598969962114, -8.228606505200913],
              [-39.969598867742377, -8.228606467625877],
              [-39.969598765982738, -8.228606428831151],
              [-39.969598664697884, -8.228606388822355],
              [-39.969598563902387, -8.228606347605256],
              [-39.969598463610772, -8.22860630518567],
              [-39.96959836383752, -8.228606261569839],
              [-39.969598264596982, -8.228606216763955],
              [-39.969598165903456, -8.228606170774562],
              [-39.96959806777118, -8.228606123608287],
              [-39.969597970214295, -8.228606075271804],
              [-39.969597873246812, -8.228606025772191],
              [-39.969597776882765, -8.228605975116619],
              [-39.969597681136015, -8.2286059233122],
              [-39.969597586020363, -8.228605870366524],
              [-39.969597491549514, -8.22860581628724],
              [-39.969597397737068, -8.228605761082161],
              [-39.96959730459654, -8.228605704759131],
              [-39.96959721214138, -8.228605647326344],
              [-39.96959712038489, -8.228605588792105],
              [-39.969597029340292, -8.228605529164698],
              [-39.969596939020711, -8.228605468452866],
              [-39.969596849439171, -8.22860540666532],
              [-39.969596760608553, -8.228605343810935],
              [-39.96959667254167, -8.228605279898877],
              [-39.969596585251224, -8.228605214938192],
              [-39.969596498749787, -8.228605148938302],
              [-39.969596413049807, -8.228605081908782],
              [-39.969596328163661, -8.228605013859196],
              [-39.969596244103563, -8.228604944799383],
              [-39.969596160881629, -8.22860487473932],
              [-39.969596078509852, -8.228604803689169],
              [-39.969595997000084, -8.228604731659017],
              [-39.969595916364099, -8.228604658659249],
              [-39.969595836613507, -8.228604584700536],
              [-39.969595757759791, -8.228604509793477],
              [-39.969595679814304, -8.228604433948675],
              [-39.96959560278831, -8.228604357177343],
              [-39.969595526692885, -8.228604279490382],
              [-39.969595451539007, -8.228604200898985],
              [-39.969595377337498, -8.228604121414559],
              [-39.969595304099037, -8.228604041048598],
              [-39.969595231834205, -8.228603959812501],
              [-39.969595160553382, -8.22860387771809],
              [-39.969595090266878, -8.228603794777088],
              [-39.969595020984791, -8.228603711001696],
              [-39.969594952717109, -8.228603626403702],
              [-39.969594885473683, -8.228603540995431],
              [-39.969594819264181, -8.228603454789223],
              [-39.969594754098168, -8.22860336779744],
              [-39.969594689985016, -8.228603280032559],
              [-39.969594626933976, -8.228603191507389],
              [-39.969594564954107, -8.228603102234512],
              [-39.969594504054378, -8.228603012226946],
              [-39.96959444424354, -8.228602921497592],
              [-39.969594385530222, -8.228602830059465],
              [-39.969594327922877, -8.22860273792582],
              [-39.969594271429798, -8.228602645109916],
              [-39.969594216059143, -8.228602551625091],
              [-39.969594161819856, -8.228602457483481],
              [-39.969594172286648, -8.228602465968892],
              [-39.969018136833299, -8.227588820396177],
              [-39.969018171027891, -8.227588845873717],
              [-39.969018161376567, -8.227588828846589],
              [-39.969018151762199, -8.227588811798732],
              [-39.969018142184879, -8.227588794730305],
              [-39.969018132644621, -8.227588777641198],
              [-39.969018123141467, -8.227588760531587],
              [-39.969018113675482, -8.227588743401533],
              [-39.969018104246651, -8.227588726251248],
              [-39.969018094855109, -8.227588709080484],
              [-39.969018085500835, -8.227588691889654],
              [-39.969018076183879, -8.227588674678751],
              [-39.969018066904319, -8.227588657447779],
              [-39.969018057662169, -8.227588640196908],
              [-39.969018048457457, -8.227588622926119],
              [-39.96901803929029, -8.227588605635541],
              [-39.969018030160626, -8.227588588325249],
              [-39.969018021068585, -8.227588570995328],
              [-39.969018012014146, -8.227588553645859],
              [-39.969018002997416, -8.227588536276974],
              [-39.969017994018373, -8.227588518888691],
              [-39.969017985077095, -8.227588501481165],
              [-39.969017976173618, -8.227588484054296],
              [-39.969017967307977, -8.227588466608413],
              [-39.969017958480229, -8.22758844914337],
              [-39.969017949690397, -8.227588431659434],
              [-39.969017940938528, -8.227588414156557],
              [-39.969017932224659, -8.227588396634891],
              [-39.969017923548869, -8.227588379094515],
              [-39.969017914911142, -8.227588361535485],
              [-39.969017906311528, -8.227588343957834],
              [-39.969017897750099, -8.227588326361724],
              [-39.969017889226897, -8.227588308747261],
              [-39.96901788074193, -8.227588291114415],
              [-39.969017872295211, -8.227588273463413],
              [-39.969017863886855, -8.227588255794213],
              [-39.969017855516874, -8.227588238106925],
              [-39.969017847185263, -8.22758822040174],
              [-39.969017838892121, -8.22758820267854],
              [-39.969017830637462, -8.227588184937522],
              [-39.969017822421307, -8.227588167178876],
              [-39.969017814243742, -8.227588149402516],
              [-39.969017806104738, -8.227588131608574],
              [-39.96901779800438, -8.227588113797088],
              [-39.969017789942725, -8.22758809596824],
              [-39.969017781919774, -8.227588078122119],
              [-39.969017773935548, -8.227588060258636],
              [-39.969017765990117, -8.227588042378143],
              [-39.969017758083517, -8.227588024480545],
              [-39.969017750215777, -8.227588006565856],
              [-39.969017742386924, -8.22758798863434],
              [-39.96901773459701, -8.227587970686008],
              [-39.969017726846062, -8.227587952720937],
              [-39.969017719134136, -8.227587934739226],
              [-39.969017711461255, -8.22758791674093],
              [-39.969017703827433, -8.227587898726098],
              [-39.969017696232733, -8.227587880694982],
              [-39.969017688677184, -8.227587862647399],
              [-39.969017681160814, -8.227587844583715],
              [-39.969017673683666, -8.22758782650387],
              [-39.969017666245769, -8.227587808407884],
              [-39.969017658847179, -8.227587790295976],
              [-39.969017651487903, -8.227587772168151],
              [-39.969017644167991, -8.227587754024499],
              [-39.969017636887479, -8.227587735865098],
              [-39.969017629646359, -8.227587717690142],
              [-39.969017622444724, -8.227587699499539],
              [-39.969017615282588, -8.227587681293546],
              [-39.969017608159973, -8.22758766307221],
              [-39.969017601076921, -8.227587644835435],
              [-39.96901759403346, -8.227587626583503],
              [-39.969017587029626, -8.227587608316531],
              [-39.969017580065461, -8.227587590034366],
              [-39.969017573140988, -8.22758757173737],
              [-39.969017566256227, -8.227587553425439],
              [-39.969017559411235, -8.227587535098765],
              [-39.969017552606033, -8.22758751675733],
              [-39.969017545840636, -8.227587498401254],
              [-39.969017539115107, -8.227587480030699],
              [-39.969017532429476, -8.227587461645747],
              [-39.969017525783741, -8.22758744324641],
              [-39.96901751917796, -8.227587424832791],
              [-39.969017512612133, -8.227587406404945],
              [-39.969017506086338, -8.227587387963103],
              [-39.969017499600575, -8.227587369507136],
              [-39.969017493154887, -8.227587351037304],
              [-39.969017486749287, -8.227587332553563],
              [-39.969017480383819, -8.227587314056148],
              [-39.969017474058511, -8.22758729554509],
              [-39.969017467773384, -8.227587277020341],
              [-39.969017461528495, -8.227587258482119],
              [-39.969017455323836, -8.227587239930491],
              [-39.969017449159452, -8.227587221365676],
              [-39.969017443035398, -8.227587202787474],
              [-39.969017436951646, -8.227587184196205],
              [-39.969017430908266, -8.227587165591848],
              [-39.969017424905275, -8.227587146974439],
              [-39.969017418942713, -8.227587128344279],
              [-39.969017413020588, -8.227587109701286],
              [-39.969017407138935, -8.227587091045516],
              [-39.969017401297812, -8.227587072376972],
              [-39.969017406393355, -8.227587103751612],
              [-39.968242891085509, -8.225102165380026],
              [-39.968242857694989, -8.225102153827434],
              [-39.968242847536345, -8.225102121450309],
              [-39.968242837255843, -8.225102089111463],
              [-39.968242826853718, -8.225102056811167],
              [-39.968242816330033, -8.225102024549978],
              [-39.968242805684966, -8.225101992328383],
              [-39.96824279491868, -8.225101960146725],
              [-39.96824278403129, -8.225101928005618],
              [-39.968242773023007, -8.225101895905381],
              [-39.968242761893904, -8.225101863846506],
              [-39.968242750644215, -8.225101831829333],
              [-39.968242739274068, -8.225101799854528],
              [-39.968242727783618, -8.225101767922432],
              [-39.968242716173016, -8.225101736033487],
              [-39.968242704442453, -8.225101704188122],
              [-39.968242692592071, -8.225101672386845],
              [-39.968242680622055, -8.225101640630095],
              [-39.968242668532582, -8.225101608918294],
              [-39.968242656323774, -8.225101577251822],
              [-39.968242643995843, -8.225101545631244],
              [-39.968242631548961, -8.225101514056941],
              [-39.96824261898329, -8.225101482529348],
              [-39.968242606299022, -8.22510145104898],
              [-39.968242593496299, -8.225101419616131],
              [-39.968242580575364, -8.225101388231479],
              [-39.968242567536343, -8.225101356895225],
              [-39.968242554379444, -8.225101325607991],
              [-39.968242541104836, -8.225101294370056],
              [-39.968242527712739, -8.225101263181987],
              [-39.968242514203297, -8.225101232044134],
              [-39.968242500576736, -8.225101200956999],
              [-39.968242486833233, -8.225101169920984],
              [-39.968242472972968, -8.225101138936639],
              [-39.968242458996158, -8.225101108004218],
              [-39.968242444902998, -8.225101077124235],
              [-39.968242430693678, -8.225101046297194],
              [-39.968242416368398, -8.22510101552343],
              [-39.968242401927363, -8.22510098480338],
              [-39.968242387370772, -8.225100954137519],
              [-39.968242372698839, -8.225100923526293],
              [-39.968242357911762, -8.225100892970167],
              [-39.968242343009742, -8.225100862469382],
              [-39.968242327993003, -8.225100832024571],
              [-39.968242312861761, -8.225100801636035],
              [-39.9682422976162, -8.225100771304287],
              [-39.968242282256575, -8.225100741029724],
              [-39.968242266783058, -8.225100710812677],
              [-39.968242251195896, -8.225100680653787],
              [-39.968242235495303, -8.225100650553344],
              [-39.9682422196815, -8.225100620511638],
              [-39.968242203754706, -8.22510059052941],
              [-39.968242187715148, -8.225100560606831],
              [-39.968242171563048, -8.225100530744312],
              [-39.968242155298633, -8.225100500942492],
              [-39.968242138922143, -8.225100471201637],
              [-39.968242122433793, -8.225100441522084],
              [-39.968242105833816, -8.225100411904393],
              [-39.968242089122469, -8.225100382348925],
              [-39.968242072299944, -8.22510035285613],
              [-39.968242055366524, -8.225100323426465],
              [-39.968242038322423, -8.225100294060278],
              [-39.96824202116786, -8.225100264757932],
              [-39.968242003903129, -8.225100235519902],
              [-39.96824198652844, -8.22510020634652],
              [-39.96824196904403, -8.225100177238332],
              [-39.968241951450175, -8.225100148195716],
              [-39.96824193374708, -8.225100119218931],
              [-39.968241915935032, -8.225100090308628],
              [-39.968241898014263, -8.225100061465065],
              [-39.968241879985051, -8.225100032688573],
              [-39.968241861847602, -8.22510000397973],
              [-39.968241843602193, -8.225099975338878],
              [-39.968241825249109, -8.225099946766317],
              [-39.968241806788555, -8.225099918262661],
              [-39.968241788220837, -8.225099889828078],
              [-39.968241769546204, -8.225099861463123],
              [-39.968241750764903, -8.225099833168157],
              [-39.968241731877214, -8.225099804943557],
              [-39.968241712883398, -8.225099776789632],
              [-39.968241693783732, -8.225099748706983],
              [-39.968241674578451, -8.225099720695933],
              [-39.968241655267867, -8.225099692756798],
              [-39.968241635852245, -8.225099664889969],
              [-39.968241616331817, -8.225099637095934],
              [-39.968241596706903, -8.225099609374956],
              [-39.968241576977753, -8.225099581727578],
              [-39.968241557144665, -8.22509955415415],
              [-39.968241537207902, -8.225099526654967],
              [-39.968241517167748, -8.225099499230472],
              [-39.968241497024501, -8.225099471881125],
              [-39.968241476778417, -8.225099444607139],
              [-39.968241456429801, -8.225099417409043],
              [-39.968241435978932, -8.22509939028715],
              [-39.968241415426093, -8.225099363241849],
              [-39.968241394771574, -8.225099336273546],
              [-39.96824137401569, -8.225099309382658],
              [-39.968241353158696, -8.225099282569543],
              [-39.968241332200918, -8.225099255834413],
              [-39.96824131114262, -8.225099229177891],
              [-39.968241289984128, -8.225099202600251],
              [-39.968241249755806, -8.225099184732924],
              [-39.967022330122148, -8.22357391232774],
              [-39.967022329922749, -8.223573914981523],
              [-39.967022294649546, -8.223573870559092],
              [-39.967022259656311, -8.223573825917542],
              [-39.967022224944373, -8.223573781058455],
              [-39.96702219051511, -8.223573735983717],
              [-39.967022156369886, -8.223573690694918],
              [-39.967022122510009, -8.223573645194085],
              [-39.967022088936837, -8.223573599482911],
              [-39.967022055651668, -8.22357355356311],
              [-39.967022022655833, -8.223573507436502],
              [-39.967021989950588, -8.223573461105071],
              [-39.967021957537227, -8.223573414570362],
              [-39.967021925417065, -8.223573367834343],
              [-39.967021893591294, -8.223573320898881],
              [-39.967021862061209, -8.223573273765744],
              [-39.967021830828038, -8.22357322643683],
              [-39.967021799892997, -8.223573178913991],
              [-39.9670217692573, -8.223573131198957],
              [-39.967021738922156, -8.223573083293836],
              [-39.967021708888744, -8.223573035200406],
              [-39.96702167915825, -8.223572986920484],
              [-39.967021649731826, -8.223572938455954],
              [-39.967021620610666, -8.223572889808802],
              [-39.967021591795863, -8.223572840980966],
              [-39.967021563288561, -8.223572791974391],
              [-39.967021535089913, -8.223572742790763],
              [-39.96702150720099, -8.223572693432132],
              [-39.9670214796229, -8.223572643900569],
              [-39.967021452356697, -8.22357259419794],
              [-39.967021425403466, -8.223572544326046],
              [-39.967021398764302, -8.223572494287094],
              [-39.967021372440207, -8.22357244408278],
              [-39.967021346432219, -8.223572393715274],
              [-39.96702132074136, -8.223572343186545],
              [-39.967021295368639, -8.22357229249843],
              [-39.967021270315065, -8.223572241653079],
              [-39.967021245581613, -8.223572190652392],
              [-39.967021221169233, -8.223572139498359],
              [-39.9670211970789, -8.223572088193064],
              [-39.967021173311565, -8.223572036738394],
              [-39.967021149868145, -8.223571985136481],
              [-39.967021126749579, -8.223571933389286],
              [-39.967021103956746, -8.223571881498971],
              [-39.96702108149055, -8.223571829467389],
              [-39.967021059351907, -8.223571777296661],
              [-39.967021037541635, -8.223571724988743],
              [-39.96702101606062, -8.223571672545901],
              [-39.967020994909689, -8.223571619969958],
              [-39.967020974089685, -8.223571567263168],
              [-39.967020953601413, -8.223571514427508],
              [-39.967020933445674, -8.223571461464964],
              [-39.96702091362328, -8.223571408377827],
              [-39.967020894134983, -8.223571355167936],
              [-39.967020874981557, -8.223571301837517],
              [-39.967020856163757, -8.22357124838874],
              [-39.967020837682313, -8.223571194823535],
              [-39.967020819537964, -8.223571141144014],
              [-39.967020801731415, -8.22357108735244],
              [-39.967020784263347, -8.22357103345079],
              [-39.967020767134464, -8.223570979441165],
              [-39.967020750345455, -8.223570925325809],
              [-39.967020733896952, -8.223570871106716],
              [-39.96702071778958, -8.223570816786031],
              [-39.967020702024016, -8.223570762365954],
              [-39.967020686600861, -8.223570707848667],
              [-39.967020671520714, -8.223570653236091],
              [-39.967020656784172, -8.223570598530541],
              [-39.967020642391809, -8.223570543734088],
              [-39.967020628344194, -8.223570488848889],
              [-39.967020614641889, -8.223570433877244],
              [-39.967020601285384, -8.223570378821057],
              [-39.967020588275275, -8.223570323682656],
              [-39.967020575612018, -8.223570268464208],
              [-39.967020563296117, -8.223570213167788],
              [-39.967020551328076, -8.223570157795598],
              [-39.967020539708344, -8.223570102349898],
              [-39.967020528437402, -8.223570046832702],
              [-39.96702051751565, -8.223569991246308],
              [-39.967020506943555, -8.223569935592851],
              [-39.967020496721531, -8.223569879874519],
              [-39.96702048684994, -8.223569824093602],
              [-39.967020477329214, -8.22356976825202],
              [-39.967020468159703, -8.223569712352267],
              [-39.967020459341775, -8.223569656396329],
              [-39.967020450875765, -8.223569600386565],
              [-39.967020442762021, -8.22356954432499],
              [-39.967020435000848, -8.223569488213915],
              [-39.967020427592558, -8.223569432055431],
              [-39.967020420537438, -8.223569375851959],
              [-39.967020413835769, -8.22356931960549],
              [-39.967020407487801, -8.22356926331836],
              [-39.967020401493798, -8.223569206992678],
              [-39.967020395853972, -8.223569150630787],
              [-39.967020390568585, -8.223569094234755],
              [-39.967020385637824, -8.223569037806751],
              [-39.967020381061857, -8.223568981349242],
              [-39.967020376840907, -8.223568924864276],
              [-39.967020372975099, -8.223568868354032],
              [-39.96702036946462, -8.223568811820778],
              [-39.967020366309832, -8.223568755265967],
              [-39.967020320419444, -8.223568761940179],
              [-39.966953515886161, -8.222299652794861],
              [-39.966953565882505, -8.222299635411291],
              [-39.96695356112749, -8.222299552374709],
              [-39.966953555606217, -8.222299469385186],
              [-39.966953549319122, -8.222299386449688],
              [-39.966953542266737, -8.222299303575349],
              [-39.966953534449701, -8.222299220769159],
              [-39.966953525868625, -8.222299138038064],
              [-39.966953516524271, -8.222299055389115],
              [-39.966953506417411, -8.222298972829257],
              [-39.966953495548886, -8.22229889036549],
              [-39.966953483919667, -8.222298808004764],
              [-39.966953471530651, -8.222298725754014],
              [-39.966953458382974, -8.222298643620352],
              [-39.966953444477717, -8.222298561610579],
              [-39.966953429816037, -8.222298479731599],
              [-39.966953414399185, -8.222298397990434],
              [-39.966953398228455, -8.222298316394006],
              [-39.966953381305252, -8.22229823494915],
              [-39.966953363630964, -8.222298153662765],
              [-39.966953345207124, -8.222298072541777],
              [-39.966953326035252, -8.222297991592974],
              [-39.96695330611697, -8.222297910823356],
              [-39.966953285453997, -8.222297830239535],
              [-39.966953264048065, -8.222297749848373],
              [-39.966953241900981, -8.222297669656843],
              [-39.966953219014606, -8.222297589671507],
              [-39.966953195390907, -8.222297509899329],
              [-39.966953171031854, -8.222297430346877],
              [-39.96695314593952, -8.222297351021],
              [-39.966953120116031, -8.222297271928246],
              [-39.966953093563532, -8.22229719307555],
              [-39.966953066284333, -8.222297114469447],
              [-39.966953038280678, -8.222297036116506],
              [-39.966953009554985, -8.222296958023529],
              [-39.96695298010966, -8.222296880196982],
              [-39.966952949947192, -8.222296802643498],
              [-39.966952919070138, -8.222296725369562],
              [-39.966952887481099, -8.222296648381889],
              [-39.966952855182768, -8.222296571686767],
              [-39.966952822177852, -8.222296495290916],
              [-39.96695278846915, -8.222296419200529],
              [-39.96695275405952, -8.222296343422231],
              [-39.96695271895188, -8.222296267962456],
              [-39.966952683149174, -8.222296192827464],
              [-39.966952646654448, -8.222296118023644],
              [-39.966952609470773, -8.22229604355736],
              [-39.966952571601304, -8.222295969434906],
              [-39.966952533049252, -8.222295895662437],
              [-39.966952493817871, -8.22229582224633],
              [-39.966952453910459, -8.222295749192796],
              [-39.966952413330418, -8.222295676507906],
              [-39.966952372081167, -8.222295604197873],
              [-39.966952330166208, -8.222295532268843],
              [-39.966952287589045, -8.222295460726883],
              [-39.966952244353337, -8.222295389577942],
              [-39.966952200462686, -8.222295318828147],
              [-39.966952155920865, -8.22229524848345],
              [-39.96695211073159, -8.222295178549775],
              [-39.966952064898692, -8.222295109033121],
              [-39.966952018426049, -8.222295039939297],
              [-39.966951971317613, -8.222294971274167],
              [-39.966951923577312, -8.222294903043473],
              [-39.966951875209261, -8.222294835253045],
              [-39.966951826217489, -8.222294767908648],
              [-39.966951776606166, -8.222294701015931],
              [-39.966951726379477, -8.222294634580546],
              [-39.966951675541694, -8.222294568608143],
              [-39.966951624097092, -8.22229450310425],
              [-39.966951572050014, -8.222294438074471],
              [-39.966951519404866, -8.222294373524276],
              [-39.966951466166137, -8.222294309459111],
              [-39.966951412338283, -8.222294245884386],
              [-39.966951357925872, -8.222294182805507],
              [-39.966951302933502, -8.222294120227795],
              [-39.966951247365827, -8.222294058156514],
              [-39.966951191227565, -8.222293996597063],
              [-39.966951134523427, -8.22229393555441],
              [-39.966951077258258, -8.222293875033927],
              [-39.966951019436848, -8.222293815040546],
              [-39.966950961064121, -8.22229375557955],
              [-39.966950902144994, -8.222293696655761],
              [-39.966950842684454, -8.222293638274309],
              [-39.96695078268754, -8.222293580440013],
              [-39.966950722159325, -8.222293523157804],
              [-39.966950661104917, -8.222293466432623],
              [-39.966950599529476, -8.222293410269181],
              [-39.966950537438223, -8.222293354672239],
              [-39.966950474836395, -8.222293299646493],
              [-39.966950411729322, -8.222293245196708],
              [-39.966950348122296, -8.222293191327255],
              [-39.966950284020704, -8.222293138042835],
              [-39.966950219429989, -8.222293085347957],
              [-39.966950154355587, -8.222293033247093],
              [-39.966950088803031, -8.222292981744541],
              [-39.966950022777851, -8.222292930844752],
              [-39.96694995628561, -8.222292880552075],
              [-39.966949889331957, -8.222292830870696],
              [-39.966949821922562, -8.222292781804688],
              [-39.966949754063094, -8.222292733358504],
              [-39.966949685759324, -8.222292685535892],
              [-39.96694968617053, -8.222292697932509],
              [-39.965030412637162, -8.220962014877577],
              [-39.965030435424033, -8.220962015088139],
              [-39.965030366629982, -8.220961967857537],
              [-39.965030297402528, -8.220961921259722],
              [-39.965030227747576, -8.220961875298558],
              [-39.965030157671002, -8.220961829977998],
              [-39.965030087178746, -8.220961785301865],
              [-39.965030016276778, -8.220961741273905],
              [-39.965029944971086, -8.220961697897819],
              [-39.965029873267746, -8.220961655177417],
              [-39.965029801172797, -8.220961613116197],
              [-39.965029728692372, -8.220961571717824],
              [-39.965029655832581, -8.220961530985649],
              [-39.96502958259962, -8.220961490923296],
              [-39.965029508999692, -8.220961451533988],
              [-39.965029435039, -8.220961412821241],
              [-39.965029360723847, -8.220961374788201],
              [-39.965029286060499, -8.220961337438208],
              [-39.965029211055295, -8.220961300774213],
              [-39.965029135714595, -8.220961264799568],
              [-39.965029060044749, -8.220961229517167],
              [-39.965028984052196, -8.220961194929991],
              [-39.96502890774336, -8.220961161041107],
              [-39.965028831124712, -8.220961127853323],
              [-39.96502875420272, -8.220961095369379],
              [-39.965028676983927, -8.220961063591968],
              [-39.965028599474856, -8.220961032524039],
              [-39.965028521682072, -8.22096100216802],
              [-39.965028443612177, -8.220960972526557],
              [-39.965028365271756, -8.220960943602007],
              [-39.965028286667476, -8.22096091539702],
              [-39.965028207805993, -8.220960887913929],
              [-39.965028128693959, -8.220960861155088],
              [-39.965028049338073, -8.220960835122696],
              [-39.96502796974508, -8.220960809818971],
              [-39.965027889921728, -8.220960785246072],
              [-39.965027809874762, -8.220960761406053],
              [-39.965027729610952, -8.220960738301008],
              [-39.965027649137106, -8.220960715932785],
              [-39.965027568460052, -8.220960694303312],
              [-39.965027487586603, -8.220960673414483],
              [-39.965027406523625, -8.220960653268014],
              [-39.965027325277973, -8.220960633865689],
              [-39.965027243856518, -8.220960615209027],
              [-39.965027162266196, -8.220960597299729],
              [-39.965027080513877, -8.220960580139247],
              [-39.965026998606497, -8.220960563728974],
              [-39.965026916551004, -8.220960548070478],
              [-39.96502683435434, -8.220960533164858],
              [-39.965026752023455, -8.220960519013477],
              [-39.965026669565347, -8.220960505617617],
              [-39.96502658698698, -8.220960492978321],
              [-39.965026504295359, -8.220960481096659],
              [-39.965026421497463, -8.22096046997371],
              [-39.965026338600332, -8.220960459610335],
              [-39.965026255610987, -8.22096045000745],
              [-39.965026172536447, -8.220960441165909],
              [-39.965026089383755, -8.220960433086377],
              [-39.965026006159945, -8.220960425769523],
              [-39.96502592287208, -8.220960419216057],
              [-39.965025839527186, -8.220960413426514],
              [-39.965025756132356, -8.220960408401334],
              [-39.965025672694622, -8.220960404140955],
              [-39.965025589221071, -8.220960400645881],
              [-39.965025505718785, -8.220960397916098],
              [-39.965025422194799, -8.220960395952144],
              [-39.965025338656218, -8.220960394754103],
              [-39.965025255110106, -8.220960394321937],
              [-39.965025171563539, -8.220960394655823],
              [-39.965025088023609, -8.220960395755736],
              [-39.965025004497342, -8.220960397621475],
              [-39.965024920991866, -8.220960400252926],
              [-39.965024837514243, -8.220960403650016],
              [-39.96502475407155, -8.220960407812244],
              [-39.965024670670793, -8.220960412739322],
              [-39.965024587319107, -8.220960418430899],
              [-39.965024504023553, -8.220960424886393],
              [-39.965024420791117, -8.220960432105329],
              [-39.965024337628918, -8.220960440087156],
              [-39.965024254543955, -8.220960448831072],
              [-39.96502417154327, -8.220960458336366],
              [-39.965024088633932, -8.220960468602248],
              [-39.965024005822926, -8.220960479627891],
              [-39.965023923117293, -8.220960491412267],
              [-39.965023840524012, -8.220960503954455],
              [-39.96502375805008, -8.220960517253353],
              [-39.96502367570249, -8.22096053130787],
              [-39.965023593488219, -8.220960546116812],
              [-39.965023511414238, -8.220960561678908],
              [-39.965023429487474, -8.220960577992816],
              [-39.965023347714876, -8.220960595057223],
              [-39.965023266103394, -8.220960612870561],
              [-39.965023184659898, -8.220960631431549],
              [-39.965023103391317, -8.220960650738336],
              [-39.965023022304536, -8.220960670789408],
              [-39.965022941406403, -8.220960691583171],
              [-39.965022860703769, -8.220960713117753],
              [-39.965022780203498, -8.220960735391312],
              [-39.965022699912389, -8.220960758401976],
              [-39.965022619837214, -8.220960782147896],
              [-39.965022539984794, -8.22096080662685],
              [-39.96502251178385, -8.22096085808249],
              [-39.963169495073195, -8.221538163429832],
              [-39.963169483086425, -8.221538175569931],
              [-39.963169472542191, -8.221538178862248],
              [-39.963169462001986, -8.221538182167379],
              [-39.963169451465845, -8.221538185485249],
              [-39.96316944093374, -8.221538188815948],
              [-39.963169430405742, -8.221538192159407],
              [-39.963169419881808, -8.22153819551559],
              [-39.963169409361996, -8.221538198884531],
              [-39.963169398846304, -8.221538202266304],
              [-39.963169388334748, -8.221538205660815],
              [-39.963169377827356, -8.221538209067967],
              [-39.963169367324127, -8.221538212487969],
              [-39.963169356825091, -8.221538215920626],
              [-39.963169346330247, -8.221538219366094],
              [-39.96316933583963, -8.221538222824234],
              [-39.963169325353242, -8.221538226295102],
              [-39.963169314871102, -8.221538229778627],
              [-39.963169304393226, -8.221538233274909],
              [-39.963169293919648, -8.221538236783921],
              [-39.963169283450327, -8.221538240305588],
              [-39.963169272985347, -8.221538243839985],
              [-39.96316926252468, -8.221538247387031],
              [-39.963169252068361, -8.221538250946827],
              [-39.963169241616392, -8.221538254519276],
              [-39.963169231168806, -8.221538258104284],
              [-39.963169220725582, -8.221538261702037],
              [-39.963169210286786, -8.221538265312562],
              [-39.963169199852416, -8.221538268935593],
              [-39.963169189422459, -8.221538272571303],
              [-39.963169178996957, -8.221538276219748],
              [-39.963169168575924, -8.221538279880766],
              [-39.963169158159374, -8.221538283554414],
              [-39.963169147747315, -8.221538287240634],
              [-39.963169137339776, -8.221538290939595],
              [-39.963169126936762, -8.221538294651113],
              [-39.963169116538282, -8.221538298375201],
              [-39.963169106144356, -8.221538302111929],
              [-39.963169095755021, -8.221538305861319],
              [-39.963169085370247, -8.221538309623263],
              [-39.963169074990098, -8.221538313397765],
              [-39.963169064614569, -8.221538317184839],
              [-39.963169054243671, -8.221538320984557],
              [-39.963169043877414, -8.221538324796839],
              [-39.96316903351584, -8.221538328621664],
              [-39.963169023158926, -8.221538332459083],
              [-39.963169012806723, -8.22153833630904],
              [-39.963169002459232, -8.221538340171449],
              [-39.963168992116458, -8.221538344046536],
              [-39.963168981778445, -8.221538347934173],
              [-39.963168971445171, -8.221538351834285],
              [-39.963168961116665, -8.221538355746867],
              [-39.963168950792969, -8.22153835967209],
              [-39.963168940474056, -8.221538363609783],
              [-39.963168930159959, -8.221538367559949],
              [-39.96316891985073, -8.221538371522671],
              [-39.963168909546326, -8.221538375497881],
              [-39.963168899246774, -8.221538379485544],
              [-39.963168888952126, -8.221538383485683],
              [-39.963168878662351, -8.221538387498372],
              [-39.963168868377487, -8.221538391523451],
              [-39.963168858097561, -8.221538395561083],
              [-39.963168847822573, -8.221538399611189],
              [-39.963168837552537, -8.221538403673684],
              [-39.963168827287483, -8.221538407748627],
              [-39.963168817027402, -8.221538411836029],
              [-39.96316880677233, -8.221538415935916],
              [-39.963168796522282, -8.221538420048191],
              [-39.963168786277251, -8.22153842417292],
              [-39.963168776037271, -8.221538428310037],
              [-39.963168765802358, -8.221538432459637],
              [-39.963168755572525, -8.221538436621579],
              [-39.963168745347765, -8.221538440795925],
              [-39.963168735128136, -8.221538444982652],
              [-39.963168724913615, -8.221538449181853],
              [-39.963168714704238, -8.221538453393405],
              [-39.963168704500021, -8.221538457617262],
              [-39.963168694300968, -8.221538461853482],
              [-39.963168684107082, -8.221538466102183],
              [-39.96316867391841, -8.221538470363178],
              [-39.963168663734955, -8.221538474636546],
              [-39.963168653556714, -8.221538478922199],
              [-39.963168643383725, -8.221538483220218],
              [-39.963168633215993, -8.221538487530642],
              [-39.963168623053527, -8.221538491853336],
              [-39.963168612896361, -8.221538496188312],
              [-39.96316860274451, -8.221538500535697],
              [-39.963168592597953, -8.221538504895245],
              [-39.963168582456738, -8.221538509267178],
              [-39.963168572320896, -8.221538513651415],
              [-39.963168562190397, -8.221538518047934],
              [-39.963168552065277, -8.221538522456722],
              [-39.963168541945542, -8.221538526877795],
              [-39.963168531831229, -8.22153853131117],
              [-39.963168521722338, -8.221538535756746],
              [-39.963168511618896, -8.221538540214606],
              [-39.96316850152089, -8.221538544684751],
              [-39.963168491428362, -8.221538549167095],
              [-39.963168481341313, -8.221538553661626],
              [-39.96316847125977, -8.221538558168454],
              [-39.963168461183727, -8.221538562687485],
              [-39.963168406563163, -8.221538527807203],
              [-39.961317448457002, -8.22237005451327],
              [-39.961317480206823, -8.22237005753329],
              [-39.961317380406619, -8.222370103090412],
              [-39.961317281166679, -8.222370149846562],
              [-39.961317182501496, -8.222370197794715],
              [-39.961317084425509, -8.222370246928071],
              [-39.961316986953037, -8.222370297239269],
              [-39.961316890098374, -8.222370348721116],
              [-39.961316793875646, -8.222370401365975],
              [-39.961316698298951, -8.222370455166134],
              [-39.961316603382244, -8.222370510113794],
              [-39.961316509139422, -8.222370566200786],
              [-39.961316415584243, -8.222370623419172],
              [-39.961316322730411, -8.222370681760262],
              [-39.961316230591464, -8.222370741215791],
              [-39.96131613918093, -8.222370801776961],
              [-39.96131604851216, -8.222370863434863],
              [-39.961315958598384, -8.22237092618052],
              [-39.961315869452768, -8.222370990004871],
              [-39.961315781088345, -8.222371054898435],
              [-39.961315693518024, -8.222371120851751],
              [-39.961315606754653, -8.222371187855193],
              [-39.961315520810885, -8.222371255898969],
              [-39.961315435699284, -8.222371324973158],
              [-39.961315351432305, -8.222371395067587],
              [-39.961315268022261, -8.222371466172099],
              [-39.961315185481375, -8.222371538276194],
              [-39.96131510382169, -8.222371611369445],
              [-39.961315023055135, -8.22237168544101],
              [-39.961314943193564, -8.222371760480142],
              [-39.961314864248628, -8.222371836475968],
              [-39.961314786231874, -8.22237191341714],
              [-39.961314709154699, -8.222371991292649],
              [-39.961314633028401, -8.222372070090953],
              [-39.961314557864085, -8.222372149800616],
              [-39.961314483672751, -8.222372230410011],
              [-39.961314410465256, -8.22237231190719],
              [-39.961314338252301, -8.222372394280354],
              [-39.961314267044436, -8.222372477517395],
              [-39.961314196852079, -8.222372561606313],
              [-39.961314127685505, -8.22237264653463],
              [-39.961314059554802, -8.222372732289879],
              [-39.961313992469961, -8.222372818859695],
              [-39.961313926440759, -8.222372906231314],
              [-39.961313861476903, -8.222372994392021],
              [-39.961313797587842, -8.222373083328939],
              [-39.961313734782927, -8.222373173028959],
              [-39.961313673071373, -8.222373263479055],
              [-39.961313612462163, -8.222373354665924],
              [-39.961313552964185, -8.222373446576309],
              [-39.96131349458615, -8.222373539196798],
              [-39.961313437336564, -8.222373632513703],
              [-39.961313381223817, -8.222373726513545],
              [-39.961313326256089, -8.222373821182446],
              [-39.961313272441473, -8.222373916506566],
              [-39.961313219787783, -8.222374012472027],
              [-39.961313168302731, -8.222374109064905],
              [-39.961313117993861, -8.222374206270889],
              [-39.96131306886852, -8.222374304075855],
              [-39.961313020933908, -8.222374402465366],
              [-39.961312974197, -8.222374501425255],
              [-39.961312928664654, -8.22237460094092],
              [-39.961312884343528, -8.222374700997884],
              [-39.961312841240087, -8.222374801581365],
              [-39.961312799360648, -8.222374902676838],
              [-39.961312758711351, -8.222375004269319],
              [-39.961312719298107, -8.222375106344163],
              [-39.961312681126685, -8.222375208886353],
              [-39.961312644202678, -8.222375311880826],
              [-39.961312608531493, -8.222375415312664],
              [-39.961312574118345, -8.22237551916669],
              [-39.96131254096823, -8.222375623427633],
              [-39.961312509086028, -8.222375728080246],
              [-39.961312478476401, -8.222375833109435],
              [-39.961312449143819, -8.222375938499567],
              [-39.961312421092586, -8.222376044235483],
              [-39.961312394326754, -8.222376150301512],
              [-39.961312368850272, -8.222376256682274],
              [-39.961312344666901, -8.222376363362102],
              [-39.96131232178012, -8.222376470325553],
              [-39.961312300193292, -8.222376577556847],
              [-39.961312279909592, -8.22237668504035],
              [-39.961312260931969, -8.222376792760311],
              [-39.961312243263201, -8.222376900701082],
              [-39.961312226905868, -8.222377008846822],
              [-39.961312211862371, -8.222377117181699],
              [-39.961312198134898, -8.222377225689877],
              [-39.961312185725461, -8.222377334355533],
              [-39.961312174635879, -8.222377443162678],
              [-39.961312164867785, -8.222377552095498],
              [-39.961312156422572, -8.22237766113796],
              [-39.96131214930152, -8.22237777027429],
              [-39.96131214350563, -8.222377879488416],
              [-39.961312139035769, -8.222377988764297],
              [-39.961312135892598, -8.222378098086109],
              [-39.961312134076564, -8.222378207437769],
              [-39.961312133587917, -8.222378316803272],
              [-39.961312134426748, -8.222378426166678],
              [-39.961312136592952, -8.222378535512069],
              [-39.961312140086179, -8.222378644823291],
              [-39.961312144905946, -8.222378754084442],
              [-39.961312113222888, -8.222378749760534],
              [-39.961445712307174, -8.225040247147378],
              [-39.961445740163171, -8.225040258633861],
              [-39.961445741740206, -8.225040289026076],
              [-39.961445743419894, -8.225040319412926],
              [-39.961445745202191, -8.2250403497939],
              [-39.96144574708709, -8.225040380168769],
              [-39.961445749074578, -8.225040410537138],
              [-39.961445751164632, -8.225040440898679],
              [-39.961445753357204, -8.225040471253001],
              [-39.961445755652306, -8.22504050159983],
              [-39.961445758049898, -8.225040531938836],
              [-39.961445760549928, -8.225040562269529],
              [-39.961445763152398, -8.225040592591757],
              [-39.961445765857256, -8.225040622905059],
              [-39.96144576866449, -8.225040653209055],
              [-39.961445771574056, -8.225040683503611],
              [-39.961445774585947, -8.225040713788127],
              [-39.961445777700099, -8.225040744062484],
              [-39.961445780916478, -8.225040774326065],
              [-39.96144578423506, -8.225040804578839],
              [-39.961445787655819, -8.225040834820234],
              [-39.96144579117869, -8.225040865049994],
              [-39.961445794803652, -8.225040895267872],
              [-39.96144579853064, -8.225040925473342],
              [-39.961445802359634, -8.225040955666206],
              [-39.961445806290591, -8.225040985845968],
              [-39.96144581032344, -8.225041016012463],
              [-39.961445814458187, -8.225041046165243],
              [-39.961445818694735, -8.225041076303945],
              [-39.961445823033038, -8.225041106428305],
              [-39.961445827473099, -8.225041136538032],
              [-39.961445832014803, -8.225041166632575],
              [-39.961445836658136, -8.225041196711782],
              [-39.961445841403041, -8.22504122677528],
              [-39.961445846249447, -8.225041256822633],
              [-39.961445851197347, -8.22504128685364],
              [-39.961445856246613, -8.225041316867927],
              [-39.961445861397245, -8.225041346864975],
              [-39.96144586664915, -8.225041376844697],
              [-39.961445872002294, -8.225041406806623],
              [-39.961445877456605, -8.225041436750402],
              [-39.961445883012033, -8.225041466675759],
              [-39.961445888668472, -8.225041496582328],
              [-39.961445894425907, -8.22504152646977],
              [-39.961445900284261, -8.225041556337702],
              [-39.961445906243469, -8.225041586185828],
              [-39.961445912303454, -8.22504161601384],
              [-39.961445918464143, -8.225041645821362],
              [-39.961445924725467, -8.225041675608106],
              [-39.961445931087383, -8.22504170537362],
              [-39.961445937549783, -8.225041735117632],
              [-39.961445944112633, -8.22504176483981],
              [-39.961445950775797, -8.225041794539862],
              [-39.96144595753924, -8.225041824217387],
              [-39.961445964402898, -8.225041853872101],
              [-39.961445971366686, -8.225041883503577],
              [-39.96144597843049, -8.225041913111685],
              [-39.961445985594281, -8.22504194269575],
              [-39.961445992857946, -8.22504197225579],
              [-39.9614460002214, -8.225042001791181],
              [-39.9614460076846, -8.225042031301824],
              [-39.961446015247404, -8.225042060787262],
              [-39.961446022909769, -8.225042090247138],
              [-39.961446030671581, -8.225042119681275],
              [-39.961446038532777, -8.225042149089115],
              [-39.961446046493236, -8.225042178470416],
              [-39.961446054552901, -8.225042207824895],
              [-39.961446062711666, -8.225042237152241],
              [-39.96144607096943, -8.225042266452055],
              [-39.961446079326116, -8.225042295723954],
              [-39.961446087781624, -8.22504232496777],
              [-39.961446096335855, -8.225042354182952],
              [-39.961446104988703, -8.225042383369406],
              [-39.961446113740095, -8.22504241252658],
              [-39.96144612258994, -8.225042441654415],
              [-39.961446131538089, -8.225042470752259],
              [-39.961446140584449, -8.225042499820001],
              [-39.961446149728971, -8.225042528857317],
              [-39.961446158971505, -8.225042557863743],
              [-39.961446168311944, -8.22504258683907],
              [-39.961446177750204, -8.225042615782815],
              [-39.961446187286178, -8.225042644694822],
              [-39.961446196919745, -8.225042673574771],
              [-39.961446206650791, -8.225042702422197],
              [-39.961446216479246, -8.225042731236858],
              [-39.961446226404931, -8.225042760018319],
              [-39.961446236427783, -8.225042788766377],
              [-39.961446246547688, -8.225042817480727],
              [-39.961446256764511, -8.225042846160973],
              [-39.961446267078152, -8.225042874806837],
              [-39.961446277488477, -8.225042903417934],
              [-39.961446287995386, -8.225042931993915],
              [-39.961446298598744, -8.225042960534553],
              [-39.961446309298459, -8.225042989039521],
              [-39.961446320094367, -8.225043017508407],
              [-39.961446330986384, -8.225043045941016],
              [-39.961446341974366, -8.225043074336938],
              [-39.9614463530582, -8.225043102695803],
              [-39.961446364237752, -8.225043131017381],
              [-39.961446375512899, -8.225043159301279],
              [-39.961446386883509, -8.225043187547247],
              [-39.961446354978698, -8.225043230216704],
              [-39.96196144265592, -8.226316424608775],
              [-39.961961441314905, -8.226316435682399],
              [-39.961961447047578, -8.226316449888536],
              [-39.961961452756086, -8.226316464104334],
              [-39.961961458440449, -8.226316478329775],
              [-39.961961464100611, -8.226316492564759],
              [-39.961961469736586, -8.226316506809217],
              [-39.961961475348346, -8.226316521063238],
              [-39.961961480935869, -8.226316535326614],
              [-39.961961486499163, -8.226316549599462],
              [-39.961961492038178, -8.226316563881703],
              [-39.96196149755292, -8.226316578173197],
              [-39.961961503043376, -8.226316592473983],
              [-39.961961508509511, -8.226316606784062],
              [-39.96196151395133, -8.226316621103225],
              [-39.961961519368799, -8.226316635431681],
              [-39.961961524761932, -8.226316649769153],
              [-39.961961530130672, -8.226316664115732],
              [-39.961961535475041, -8.226316678471315],
              [-39.961961540795002, -8.226316692836003],
              [-39.961961546090535, -8.226316707209527],
              [-39.961961551361647, -8.226316721592056],
              [-39.961961556608301, -8.226316735983316],
              [-39.961961561830499, -8.226316750383512],
              [-39.961961567028226, -8.226316764792447],
              [-39.961961572201453, -8.226316779210213],
              [-39.961961577350174, -8.226316793636544],
              [-39.961961582474387, -8.226316808071612],
              [-39.961961587574031, -8.226316822515331],
              [-39.961961592649132, -8.2263168369676],
              [-39.961961597699691, -8.226316851428448],
              [-39.961961602725644, -8.226316865897751],
              [-39.961961607727005, -8.226316880375444],
              [-39.961961612703774, -8.22631689486159],
              [-39.961961617655909, -8.226316909356253],
              [-39.961961622583409, -8.226316923859057],
              [-39.961961627486261, -8.226316938370276],
              [-39.961961632364442, -8.226316952889659],
              [-39.961961637217939, -8.226316967417288],
              [-39.961961642046752, -8.226316981953079],
              [-39.961961646850853, -8.226316996496982],
              [-39.961961651630226, -8.22631701104903],
              [-39.961961656384865, -8.226317025609053],
              [-39.961961661114763, -8.226317040177021],
              [-39.961961665819899, -8.226317054752998],
              [-39.961961670500251, -8.226317069336924],
              [-39.961961675155806, -8.226317083928652],
              [-39.961961679786583, -8.226317098528343],
              [-39.961961684392534, -8.226317113135673],
              [-39.961961688973645, -8.226317127750846],
              [-39.961961693529936, -8.226317142373677],
              [-39.961961698061351, -8.226317157004251],
              [-39.961961702567898, -8.226317171642412],
              [-39.961961707049582, -8.226317186288092],
              [-39.961961711506362, -8.226317200941285],
              [-39.961961715938244, -8.226317215602048],
              [-39.96196172034518, -8.226317230270245],
              [-39.961961724727203, -8.226317244945868],
              [-39.96196172908428, -8.226317259628862],
              [-39.961961733416409, -8.226317274319157],
              [-39.961961737723549, -8.226317289016743],
              [-39.961961742005727, -8.226317303721645],
              [-39.961961746262901, -8.226317318433617],
              [-39.961961750495064, -8.226317333152824],
              [-39.96196175470223, -8.226317347879137],
              [-39.961961758884335, -8.22631736261248],
              [-39.961961763041415, -8.226317377352924],
              [-39.961961767173442, -8.226317392100405],
              [-39.961961771280393, -8.226317406854733],
              [-39.961961775362262, -8.226317421615995],
              [-39.961961779419063, -8.226317436384093],
              [-39.961961783450754, -8.226317451159014],
              [-39.961961787457319, -8.226317465940811],
              [-39.961961791438767, -8.22631748072925],
              [-39.961961795395098, -8.226317495524452],
              [-39.961961799326261, -8.22631751032622],
              [-39.961961803232271, -8.22631752513467],
              [-39.961961807113113, -8.226317539949576],
              [-39.961961810968766, -8.226317554771168],
              [-39.961961814799238, -8.22631756959912],
              [-39.961961818604507, -8.226317584433584],
              [-39.961961822384559, -8.226317599274324],
              [-39.96196182613938, -8.226317614121559],
              [-39.961961829868969, -8.226317628975007],
              [-39.961961833573312, -8.226317643834761],
              [-39.96196183725241, -8.226317658700813],
              [-39.961961840906227, -8.226317673572966],
              [-39.96196184453477, -8.226317688451251],
              [-39.961961848138031, -8.226317703335656],
              [-39.961961851715984, -8.226317718226108],
              [-39.96196185526864, -8.226317733122563],
              [-39.961961858795966, -8.226317748025059],
              [-39.961961862297976, -8.226317762933411],
              [-39.961961865774633, -8.226317777847688],
              [-39.961961869225945, -8.226317792767821],
              [-39.961961872651919, -8.226317807693723],
              [-39.961961876052499, -8.226317822625365],
              [-39.961961879427705, -8.226317837562689],
              [-39.961961882777523, -8.226317852505758],
              [-39.961961886101953, -8.226317867454446],
              [-39.961961889401096, -8.226317882407503],
              [-39.961961900060658, -8.226317870595528],
              [-39.962480248029955, -8.228677180961059],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0230000',
        uf: 'PE',
        nome_proje: 'PA MARIA BONITA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '463.3514',
        capacidade: 10.0,
        num_famili: 7.0,
        fase: 3.0,
        data_de_cr: '11/05/2007',
        forma_obte: 'Desapropriação',
        data_obten: '01/11/2006',
        area_calc_: 463.3971,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.948056252435912, -8.569739822608717],
              [-39.949021423101733, -8.572347229200068],
              [-39.949948644692562, -8.574778816601043],
              [-40.035200281247619, -8.59187564667992],
              [-40.035204787841842, -8.591871556142298],
              [-40.03603035485731, -8.590916381417401],
              [-40.036328641385239, -8.590571278072813],
              [-40.037179341540778, -8.589414798411955],
              [-40.006031769480877, -8.582478966076872],
              [-40.000977851977211, -8.579639908366747],
              [-39.948056252435912, -8.569739822608717],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0275000',
        uf: 'PE',
        nome_proje: 'PA DENIS SANTANA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1001.1240',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '05/11/2008',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2007',
        area_calc_: 1001.2163,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.825355404166238, -8.597733964746096],
              [-39.778591884484271, -8.526692037186779],
              [-39.777081527696474, -8.529374150275718],
              [-39.765482794540851, -8.524652508832569],
              [-39.817976748076504, -8.602941936984053],
              [-39.81974391804431, -8.601514222203992],
              [-39.821536657411933, -8.600066276784032],
              [-39.822396009422924, -8.59937200093068],
              [-39.82355698859994, -8.59872944556883],
              [-39.825355404166238, -8.597733964746096],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0043000',
        uf: 'PE',
        nome_proje: 'PA ALEGRE',
        municipio: 'OROCO',
        area_hecta: '3105.1140',
        capacidade: 100.0,
        num_famili: 96.0,
        fase: 5.0,
        data_de_cr: '25/05/2000',
        forma_obte: 'Desapropriação',
        data_obten: '29/11/1999',
        area_calc_: 3037.2073,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.67329415105776, -8.568990596827904],
              [-39.673194778340694, -8.568650868948239],
              [-39.673195764935194, -8.568126815407801],
              [-39.673196823731267, -8.567540443515833],
              [-39.673214682963092, -8.557951997933246],
              [-39.673214825600645, -8.557878011730992],
              [-39.673223798136888, -8.553052627984801],
              [-39.673225225897866, -8.552313127555223],
              [-39.6732289977584, -8.550268828706546],
              [-39.673233557191573, -8.547792551391941],
              [-39.67323388252553, -8.547615454771689],
              [-39.673102363738536, -8.547542059179575],
              [-39.671584443276728, -8.546694578820928],
              [-39.667190250101548, -8.544241186202139],
              [-39.663499980566549, -8.54218079580829],
              [-39.663407119330031, -8.542128856965967],
              [-39.659447334928871, -8.542100522095811],
              [-39.65873086819937, -8.54209549979157],
              [-39.655769591763608, -8.542074202064025],
              [-39.654763179424172, -8.541376561661671],
              [-39.654746870645369, -8.541403904444342],
              [-39.649788113003794, -8.549735080219653],
              [-39.655636531124507, -8.553858319532054],
              [-39.655949440080306, -8.553625700484293],
              [-39.658432397978416, -8.554389384646891],
              [-39.658434510731148, -8.554402676857096],
              [-39.658637084681466, -8.555691211092848],
              [-39.658721239631859, -8.556227241446285],
              [-39.65886345850933, -8.557873146214172],
              [-39.658628336074109, -8.558690560087376],
              [-39.657707507410862, -8.558438240470952],
              [-39.656570217129989, -8.558123063135989],
              [-39.65539429193548, -8.557797365394993],
              [-39.655350916217728, -8.558097453875227],
              [-39.652545723636379, -8.558614673291366],
              [-39.652509206421456, -8.558621428030362],
              [-39.644089486464573, -8.560173878021356],
              [-39.644080313219035, -8.561257549257295],
              [-39.644073856625582, -8.562011262097252],
              [-39.644062168226462, -8.563382290281909],
              [-39.644033613222092, -8.569473367193943],
              [-39.64399909435361, -8.571532288658494],
              [-39.643989921128153, -8.572074990889563],
              [-39.643966636378799, -8.573465664925783],
              [-39.643420241964812, -8.574176138942228],
              [-39.64271173858139, -8.576297144724421],
              [-39.642154970247461, -8.576894393896994],
              [-39.641837091768558, -8.577539092086473],
              [-39.641889854646728, -8.580333299456045],
              [-39.642314284382572, -8.581663080392481],
              [-39.643878927212519, -8.583868302275832],
              [-39.643853568900461, -8.588070818850438],
              [-39.643850091886044, -8.588649599799005],
              [-39.643625519828738, -8.589500002775125],
              [-39.643664758557321, -8.590404957377297],
              [-39.643673361525209, -8.59060266177176],
              [-39.643297114279406, -8.59139742119771],
              [-39.643111628702961, -8.5922551754167],
              [-39.642450634429963, -8.593517662415366],
              [-39.641189317649889, -8.596895093255272],
              [-39.641044301638232, -8.597233428379566],
              [-39.638883714437824, -8.601687682828297],
              [-39.637271033335473, -8.604918256986185],
              [-39.635864600635259, -8.607046378504487],
              [-39.634556146862991, -8.609026271047076],
              [-39.631310226449528, -8.615733708105546],
              [-39.629652553553143, -8.618757476439598],
              [-39.629166708929851, -8.619643756908282],
              [-39.628100144558708, -8.621589317391225],
              [-39.628063090014258, -8.62221030254015],
              [-39.626997113911472, -8.625346696202518],
              [-39.626722431894237, -8.626154753708413],
              [-39.628862608458604, -8.628431985652806],
              [-39.628610428093509, -8.630094920113484],
              [-39.628589929775785, -8.630229992279052],
              [-39.634148514772129, -8.631739914519692],
              [-39.634718828873559, -8.632126449252356],
              [-39.638512199968751, -8.634507247575185],
              [-39.642727465706777, -8.637344419518453],
              [-39.646527593544377, -8.639685322380901],
              [-39.646994606345373, -8.639973061680941],
              [-39.649142254900511, -8.641296022320404],
              [-39.649212402578115, -8.641179678559981],
              [-39.650733350142026, -8.638659314773667],
              [-39.651452278419512, -8.63739725702348],
              [-39.652616194464109, -8.635403445330152],
              [-39.654078156067563, -8.633382711988002],
              [-39.654375047930941, -8.632890352818951],
              [-39.654856454162179, -8.632092418677177],
              [-39.655341307536624, -8.631291855183836],
              [-39.655830798260205, -8.630493725344955],
              [-39.65631400929027, -8.629691716504469],
              [-39.656795497352689, -8.62889305667176],
              [-39.657282706014122, -8.628092577605491],
              [-39.657764006531863, -8.627292831741913],
              [-39.658249940151507, -8.626493077444627],
              [-39.658747491986858, -8.625685886031309],
              [-39.659215441111272, -8.624894392312729],
              [-39.659990269501797, -8.623614045731685],
              [-39.660639870876807, -8.622551077308531],
              [-39.661125154963997, -8.621750326262619],
              [-39.661608262059708, -8.620953105915326],
              [-39.662093999473974, -8.620153981149935],
              [-39.662583912955746, -8.619353401528375],
              [-39.663073266717717, -8.618545677032502],
              [-39.663559543196421, -8.617746368943989],
              [-39.664034198219269, -8.616954678012329],
              [-39.664519380240996, -8.61615537083444],
              [-39.665001749332411, -8.615359685919703],
              [-39.665486645482666, -8.614555041862971],
              [-39.666398764611692, -8.613073382864711],
              [-39.662379139753142, -8.612659207365201],
              [-39.662232345483666, -8.612646074062116],
              [-39.660811595502452, -8.612477121141445],
              [-39.660961027200308, -8.611863088591722],
              [-39.661806143824435, -8.608581652564302],
              [-39.661910757097317, -8.60822203479835],
              [-39.66273460675945, -8.606254291090126],
              [-39.666728575775558, -8.596713341124126],
              [-39.667235546244058, -8.595502097266767],
              [-39.668551375667285, -8.592358671583186],
              [-39.669560315577336, -8.589948398828749],
              [-39.669772255998247, -8.58944224932875],
              [-39.67351677741253, -8.580496280308154],
              [-39.673365619357476, -8.572682531630759],
              [-39.673349629042256, -8.57185804185476],
              [-39.67329415105776, -8.568990596827904],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0059000',
        uf: 'PE',
        nome_proje: 'PA CARAÍBAS II',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '381.3078',
        capacidade: 40.0,
        num_famili: 40.0,
        fase: 3.0,
        data_de_cr: '24/10/2001',
        forma_obte: 'Desapropriação',
        data_obten: '30/01/2001',
        area_calc_: 383.0057,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.770692042090644, -8.711445250347964],
              [-39.76883807815009, -8.715483476383518],
              [-39.766926333904181, -8.719645812432164],
              [-39.765004582639591, -8.723779038450571],
              [-39.764997531257961, -8.724378882945102],
              [-39.764991091736093, -8.724922017267346],
              [-39.764944458428523, -8.728851668381882],
              [-39.764942008357352, -8.729032382100478],
              [-39.764872132189133, -8.729672420157474],
              [-39.764763616429427, -8.730667532049749],
              [-39.764655009414625, -8.731662644091939],
              [-39.764546492957258, -8.732657755917561],
              [-39.764442793040992, -8.733607635589566],
              [-39.764339183713339, -8.734557515047968],
              [-39.764240481893843, -8.735462162175246],
              [-39.764141779785554, -8.736366809274982],
              [-39.764047985242783, -8.737226224049099],
              [-39.764037544043362, -8.73732202614957],
              [-39.764044552999415, -8.738085456680158],
              [-39.764052393529774, -8.738944666551241],
              [-39.764060234266729, -8.739803876380531],
              [-39.764068075210304, -8.740663086167968],
              [-39.76407591636049, -8.741522295913549],
              [-39.764083757717316, -8.742381505617242],
              [-39.764091599280746, -8.743240715279024],
              [-39.76409944105086, -8.744099924899007],
              [-39.764107283027585, -8.744959134477099],
              [-39.764115125210964, -8.745818344013243],
              [-39.764122967601018, -8.746677553507604],
              [-39.76413081019772, -8.747536762960003],
              [-39.764138653001091, -8.748395972370538],
              [-39.764167208110059, -8.751520871362159],
              [-39.762081937931413, -8.752176730164706],
              [-39.762048958227759, -8.752187197718014],
              [-39.761890807401734, -8.752875258258019],
              [-39.761734062265603, -8.753584208671452],
              [-39.761578044126125, -8.754293248024947],
              [-39.761420206159464, -8.755001748330773],
              [-39.761258729189265, -8.755709261023958],
              [-39.761096705875367, -8.756416593882644],
              [-39.760930133115146, -8.757122307842335],
              [-39.760763651299513, -8.757828292906551],
              [-39.760598078646034, -8.758534547429887],
              [-39.760471350962561, -8.759074305398027],
              [-39.760076350507205, -8.760740007097803],
              [-39.759898408506231, -8.761489609339828],
              [-39.759721375972042, -8.76223966192455],
              [-39.75954470650683, -8.762989713726608],
              [-39.759367399871529, -8.763739676310975],
              [-39.759188363986581, -8.764489009201776],
              [-39.759000053588082, -8.765282859497527],
              [-39.758812106369973, -8.766076799448609],
              [-39.758713931020047, -8.766486620997094],
              [-39.758431413307555, -8.767585820950821],
              [-39.758386672983065, -8.767760288299396],
              [-39.7583048602433, -8.768079722596665],
              [-39.757915805333838, -8.769815867400959],
              [-39.757792427469525, -8.770351819390468],
              [-39.756938116104266, -8.773957666886327],
              [-39.756778859477038, -8.774645999605317],
              [-39.756748306234869, -8.774778200677053],
              [-39.757780532666828, -8.775336254681577],
              [-39.759577838808376, -8.775851885900062],
              [-39.761328727617034, -8.776475050040366],
              [-39.761368193987089, -8.7763451823166],
              [-39.761574648377845, -8.775666521175841],
              [-39.762654643970407, -8.772140616527633],
              [-39.763334615892468, -8.769962149099046],
              [-39.763387902510694, -8.769788839864388],
              [-39.764040329269044, -8.767659629164337],
              [-39.764702977969293, -8.765508418985196],
              [-39.766114241694879, -8.760930599058126],
              [-39.766641133969635, -8.759222297855235],
              [-39.766958855452287, -8.758191489049885],
              [-39.767423964941038, -8.756682381095615],
              [-39.769160045324725, -8.751064866494739],
              [-39.769179558159173, -8.746840971018639],
              [-39.76920016251804, -8.742393945400172],
              [-39.769222089554518, -8.737924938049199],
              [-39.769249691907142, -8.733251151334542],
              [-39.769264771301422, -8.729207427039601],
              [-39.769319781977529, -8.728237024268003],
              [-39.769329502220174, -8.728056205508727],
              [-39.769371062435546, -8.727176365162137],
              [-39.769401730487594, -8.726526547880541],
              [-39.769577202587499, -8.722809636045223],
              [-39.769728378969951, -8.722455509698234],
              [-39.770003318591556, -8.721811528243302],
              [-39.771908870736105, -8.717346834915821],
              [-39.770692042090644, -8.711445250347964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0287000',
        uf: 'PE',
        nome_proje: 'PA PROGRESSO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '669.2962',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '20/08/2010',
        forma_obte: 'Desapropriação',
        data_obten: '30/04/2009',
        area_calc_: 700.747,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.858923468359208, -8.756324076992815],
              [-39.861700720678208, -8.765019153723882],
              [-39.860491615513006, -8.76535066740696],
              [-39.862569604667499, -8.769958862320328],
              [-39.862737771816029, -8.769909187087386],
              [-39.865542191682458, -8.776031514309109],
              [-39.868170101444512, -8.776501387383451],
              [-39.864340776856892, -8.756337514661807],
              [-39.865430138122605, -8.749915140326088],
              [-39.865497513529888, -8.749644743904142],
              [-39.866294482437517, -8.746446047984627],
              [-39.854807183773005, -8.690121574448542],
              [-39.853396250449961, -8.692303601686429],
              [-39.850305832704279, -8.692839694271047],
              [-39.853215621334236, -8.718858086760838],
              [-39.856517396870302, -8.749872009748023],
              [-39.858885741958673, -8.756205863609406],
              [-39.858923468359208, -8.756324076992815],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0068000',
        uf: 'PE',
        nome_proje: 'PA MARISTELA MEDRADO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '336.6273',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 4.0,
        data_de_cr: '19/11/2002',
        forma_obte: 'Arrecadação',
        data_obten: '15/10/2002',
        area_calc_: 336.8426,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.742776298065259, -8.685063797256321],
              [-39.74361505440298, -8.69754535119764],
              [-39.744844352754846, -8.697811571400692],
              [-39.745894973108641, -8.693358878988272],
              [-39.751042466977069, -8.69351473305198],
              [-39.750304764706804, -8.698963163280958],
              [-39.750277487555863, -8.699142117776105],
              [-39.750054457973903, -8.700759447296571],
              [-39.750002397182669, -8.701137249302503],
              [-39.750455167110893, -8.701136447347897],
              [-39.750500948031075, -8.70116575179231],
              [-39.750544171526229, -8.701188910996176],
              [-39.750597274594739, -8.701197760362808],
              [-39.750663129701842, -8.701219699134635],
              [-39.750718607331173, -8.70123415140622],
              [-39.750758635672824, -8.701250443046755],
              [-39.750790207851914, -8.701265485129035],
              [-39.750840426842672, -8.701286731147903],
              [-39.750863802583545, -8.701294011100748],
              [-39.750963901557832, -8.701303219929542],
              [-39.750988550613975, -8.701310859143176],
              [-39.751047221461278, -8.701331184005138],
              [-39.751114965668734, -8.701343169990428],
              [-39.751131622613599, -8.701354352329972],
              [-39.751225650096416, -8.701372979374293],
              [-39.75159777233786, -8.701635711710601],
              [-39.751646310844535, -8.701634711406975],
              [-39.751711026927623, -8.701632412913877],
              [-39.751788256999227, -8.701614985339772],
              [-39.751838979974735, -8.701615427820686],
              [-39.75190804334229, -8.701605161527413],
              [-39.751978397887761, -8.70160420852895],
              [-39.752053029218224, -8.701605508197645],
              [-39.752090525278696, -8.701628226303821],
              [-39.752134512847988, -8.701624250238371],
              [-39.752245718617608, -8.701641938622199],
              [-39.752301746262354, -8.701658650695784],
              [-39.752356599261304, -8.701678892446044],
              [-39.752400837616783, -8.701709465925516],
              [-39.752479670024442, -8.701720615732066],
              [-39.752510292438991, -8.701760712863578],
              [-39.752558485554793, -8.70181434203232],
              [-39.75257823874135, -8.70182832198234],
              [-39.752660272503299, -8.701894908331319],
              [-39.752705555066498, -8.701947638781542],
              [-39.752749032677009, -8.701961390911389],
              [-39.752835211734201, -8.702009879977942],
              [-39.752883381795783, -8.702051932158033],
              [-39.752945408540334, -8.702113945307692],
              [-39.752985267907874, -8.702136568195803],
              [-39.753030080140924, -8.702181340353613],
              [-39.75305791947175, -8.702238808416697],
              [-39.75309116436884, -8.702317520402049],
              [-39.753124888150793, -8.702362947603104],
              [-39.753120540369721, -8.702416137982125],
              [-39.753114032690753, -8.70248018605872],
              [-39.753111967308641, -8.702492852473229],
              [-39.753095993080755, -8.702595901161898],
              [-39.753043225235473, -8.702663929919737],
              [-39.753012943916993, -8.702703604832834],
              [-39.752951413520968, -8.702753561878449],
              [-39.752929532553964, -8.702812032767159],
              [-39.75293008338965, -8.702860238967896],
              [-39.752957253208166, -8.702901066441779],
              [-39.752943448937678, -8.70295282840663],
              [-39.752915468819843, -8.703006879547607],
              [-39.752942800565584, -8.703037757729479],
              [-39.752948463303312, -8.703096716793697],
              [-39.753003641694683, -8.703188680824761],
              [-39.753067632335259, -8.703278094941215],
              [-39.753072585399821, -8.703300334657007],
              [-39.753085154423196, -8.70340368863868],
              [-39.753115219537285, -8.703437726976373],
              [-39.753151094111949, -8.703467683779714],
              [-39.753181160322086, -8.703502264780154],
              [-39.753211056672626, -8.703542815496343],
              [-39.753238773602334, -8.703584455865874],
              [-39.753278841170371, -8.703620192847172],
              [-39.753309711759044, -8.703647898408702],
              [-39.75335888181182, -8.703689858078446],
              [-39.753392139866314, -8.703729588115129],
              [-39.753435093470415, -8.703753923270101],
              [-39.753508715178071, -8.703750159686214],
              [-39.753548092314233, -8.703804258374218],
              [-39.753576468939357, -8.703857564835763],
              [-39.753625880989297, -8.703884148306107],
              [-39.753721974490631, -8.703935511730824],
              [-39.753772067740613, -8.703984524234659],
              [-39.753782690630743, -8.704069159875679],
              [-39.753800550466003, -8.70409083133754],
              [-39.75384240695864, -8.704157406510054],
              [-39.753842377053004, -8.704187886592765],
              [-39.753947642151068, -8.704234528631375],
              [-39.753989074984332, -8.704271076826718],
              [-39.754040775729578, -8.704305705320534],
              [-39.754089584158116, -8.704348570048824],
              [-39.754143934423787, -8.704389886218232],
              [-39.754135347620434, -8.704459636460385],
              [-39.754113772601869, -8.704534658264103],
              [-39.754089288228748, -8.704609414497531],
              [-39.754096619265148, -8.704638885092402],
              [-39.754057034968191, -8.704663021957431],
              [-39.753581980146528, -8.70465862686631],
              [-39.753571328002195, -8.704650236561989],
              [-39.753516360914723, -8.70466409308929],
              [-39.75344159277499, -8.704594688809358],
              [-39.753381344979161, -8.704604304850228],
              [-39.753351596757419, -8.704592424987347],
              [-39.753276009081347, -8.704613196140983],
              [-39.753256901241926, -8.704603827670258],
              [-39.753209793019451, -8.704547483132426],
              [-39.753184818026156, -8.704513344291186],
              [-39.75316629875384, -8.704480097121193],
              [-39.753124780492655, -8.704446262356614],
              [-39.753077741561896, -8.704424558168434],
              [-39.753028688594071, -8.704441206719851],
              [-39.752972986498179, -8.704405681549003],
              [-39.752946193895262, -8.704371727176593],
              [-39.752905200276452, -8.704327490150003],
              [-39.752855623761121, -8.704309589651194],
              [-39.752808868586534, -8.704293402022239],
              [-39.752761687471327, -8.704291505570696],
              [-39.752723718348662, -8.704305147463442],
              [-39.752682683377934, -8.704285692475587],
              [-39.752629927361603, -8.704268793132544],
              [-39.752558359010052, -8.704253920783374],
              [-39.752521381491391, -8.704217906280503],
              [-39.752492841786022, -8.704173915909305],
              [-39.752459301501297, -8.704129392752744],
              [-39.752422521521112, -8.704101246580208],
              [-39.752383337102195, -8.704098068033954],
              [-39.75236292184529, -8.704071246174909],
              [-39.752339322019843, -8.704042893042425],
              [-39.752324403758976, -8.704037586270928],
              [-39.752264410500452, -8.703856000461236],
              [-39.75228002999841, -8.703802787800974],
              [-39.752189006624313, -8.703786687601752],
              [-39.7521509050924, -8.703779527298863],
              [-39.752113535382499, -8.703820211061586],
              [-39.752072165523522, -8.703860631391555],
              [-39.752075461138261, -8.703917786250827],
              [-39.752028561350258, -8.703965724459184],
              [-39.751973668394506, -8.703971169209305],
              [-39.751938999470354, -8.703998733064548],
              [-39.751876022362282, -8.704007178468167],
              [-39.751873944197769, -8.704059007681781],
              [-39.751858339435721, -8.704119636808651],
              [-39.751836013479732, -8.704182992571774],
              [-39.751869043501713, -8.704245153613609],
              [-39.751882991374735, -8.704310789276736],
              [-39.751824630520098, -8.7043549514134],
              [-39.751775467891875, -8.704407778109269],
              [-39.751773963455605, -8.70447380608754],
              [-39.751739092345126, -8.704536734407025],
              [-39.751759951512113, -8.704603803543314],
              [-39.751776146799379, -8.704656862884713],
              [-39.751762924469446, -8.704681490107257],
              [-39.751708118924171, -8.704958541629951],
              [-39.751756639159836, -8.704993719397557],
              [-39.751770477739896, -8.705050129866073],
              [-39.751808570094582, -8.705098171449551],
              [-39.751857146775627, -8.705116074055441],
              [-39.751880750252383, -8.705146236110645],
              [-39.75191829632633, -8.70519382653441],
              [-39.751960920188559, -8.705235075753301],
              [-39.752000100140307, -8.705281487150282],
              [-39.752048371941726, -8.705328785022781],
              [-39.752102269282823, -8.70537091639077],
              [-39.752153258546734, -8.705413415279997],
              [-39.752206944668352, -8.705440804480949],
              [-39.752270411186259, -8.705449723514425],
              [-39.752337152287353, -8.705459721404077],
              [-39.752378748316232, -8.705486953596285],
              [-39.752428121654454, -8.705539585502104],
              [-39.752468848266062, -8.705586626900162],
              [-39.752500668816765, -8.705634771250917],
              [-39.752539221789419, -8.705685796527415],
              [-39.752580235048164, -8.705739801623476],
              [-39.752623686545547, -8.705785752268442],
              [-39.752673056343099, -8.705836575238745],
              [-39.752727875279128, -8.705839451470265],
              [-39.752801974510902, -8.705846992692624],
              [-39.75282426645348, -8.705857621113161],
              [-39.752883203212626, -8.705874146396292],
              [-39.752901338688488, -8.705897264473046],
              [-39.752944028092386, -8.70588026673691],
              [-39.752997259467456, -8.705907475824953],
              [-39.753041187174944, -8.705918784969032],
              [-39.753093521968658, -8.705906742608985],
              [-39.753115965881591, -8.705902447255912],
              [-39.753244424408656, -8.705955736480796],
              [-39.753265019287262, -8.705981382133318],
              [-39.753299046816871, -8.705996690436379],
              [-39.753321505118585, -8.706045034134466],
              [-39.753354702711157, -8.706099868616478],
              [-39.753359823272582, -8.706160366297727],
              [-39.753442571870288, -8.706174764093355],
              [-39.753408224971899, -8.706227018981673],
              [-39.753421885743947, -8.706239835141361],
              [-39.753435691630195, -8.706325188040861],
              [-39.753451537391832, -8.706385121805711],
              [-39.75348245763638, -8.706437518762529],
              [-39.753523812094585, -8.706480217414709],
              [-39.753556586616327, -8.706505296249892],
              [-39.753604304313463, -8.706548072731954],
              [-39.753660578301748, -8.706551217272725],
              [-39.753714599505535, -8.706564224791624],
              [-39.753759629155454, -8.706581048815826],
              [-39.753815545499911, -8.706632582203854],
              [-39.753821534421675, -8.706672727988161],
              [-39.75386335195585, -8.706719676602502],
              [-39.753921657288167, -8.706738554534493],
              [-39.753954578710015, -8.706791580594038],
              [-39.753959612287979, -8.706853977776969],
              [-39.753967559217401, -8.706918630313996],
              [-39.753987765974259, -8.706977379614617],
              [-39.754041094813296, -8.707007753923211],
              [-39.754102708122083, -8.707044352515702],
              [-39.754134342860702, -8.707090326383216],
              [-39.754155282837516, -8.70715205890793],
              [-39.75419420052053, -8.707203445038664],
              [-39.754213566580646, -8.707250890345113],
              [-39.754245032230834, -8.707303195696229],
              [-39.754272405724457, -8.707354695152247],
              [-39.754305121357, -8.707395692374336],
              [-39.754373526404478, -8.707419343790104],
              [-39.754417783375381, -8.707458780543833],
              [-39.754408535109754, -8.707515960219306],
              [-39.754429717782351, -8.707562678346227],
              [-39.754434581371513, -8.707585551321516],
              [-39.754439265334213, -8.707609509994636],
              [-39.755018264075481, -8.707452614605931],
              [-39.75508088873498, -8.707449505604021],
              [-39.755135941058718, -8.707432663964987],
              [-39.755273976155252, -8.707456176973103],
              [-39.755305218256623, -8.707487589841435],
              [-39.755350378345383, -8.707524039995093],
              [-39.755369399831665, -8.707580892205979],
              [-39.755396152793509, -8.707640171079056],
              [-39.75542735536947, -8.707697179983885],
              [-39.755449654026613, -8.707756377252931],
              [-39.755472225938412, -8.707815845312576],
              [-39.755486796947167, -8.707874515256101],
              [-39.755506552578801, -8.707934802912261],
              [-39.755494135283378, -8.707998139163164],
              [-39.755447122101351, -8.708034862818922],
              [-39.755470856147085, -8.708084560494877],
              [-39.75553660006949, -8.708186271109362],
              [-39.755698947842468, -8.707913527877162],
              [-39.757144124537234, -8.705486363436785],
              [-39.760768502305567, -8.699493389890748],
              [-39.760877308733356, -8.699313367677645],
              [-39.764424024041077, -8.693448230830803],
              [-39.752152763227187, -8.682694563228035],
              [-39.749249855049882, -8.684534056338682],
              [-39.7472790357332, -8.684708955690294],
              [-39.743567031358182, -8.685032409159261],
              [-39.742776298065259, -8.685063797256321],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0055000',
        uf: 'PE',
        nome_proje: 'PA SÃO MIGUEL II',
        municipio: 'CABROBO',
        area_hecta: '389.0011',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 4.0,
        data_de_cr: '28/06/2001',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2000',
        area_calc_: 392.4454,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.272140453990993, -8.555196311486219],
              [-39.272784117808548, -8.555866110613263],
              [-39.27511181991386, -8.558312556338947],
              [-39.275311870862282, -8.558496936854665],
              [-39.278836858538611, -8.554706719294819],
              [-39.28117490029085, -8.552376422061103],
              [-39.280982023244889, -8.552184622205493],
              [-39.278594939857669, -8.549800937263335],
              [-39.278214919079005, -8.549421672259236],
              [-39.275470827056303, -8.546760714872606],
              [-39.274829286938356, -8.546122305061571],
              [-39.274195930198452, -8.545488139573566],
              [-39.273551118345338, -8.544843126759098],
              [-39.272925115837772, -8.544194390982682],
              [-39.272302645463519, -8.54352611389257],
              [-39.2716936223228, -8.542852941850866],
              [-39.271082416784061, -8.542174524045929],
              [-39.270474854059366, -8.541504514726675],
              [-39.269858384467021, -8.540828902513965],
              [-39.2692381938189, -8.540156638558711],
              [-39.268627185757374, -8.539488347069334],
              [-39.268013900884846, -8.538809654063703],
              [-39.26739779815307, -8.538126439315791],
              [-39.266790157118315, -8.537455971484064],
              [-39.264547114415784, -8.534967801750488],
              [-39.262252372381781, -8.532441934081199],
              [-39.262070322005492, -8.532241523705197],
              [-39.261078317159367, -8.531140027581365],
              [-39.260484322276973, -8.530595088017266],
              [-39.257293827500732, -8.527302660105017],
              [-39.252930229470735, -8.522333162593865],
              [-39.251495615609578, -8.526554345884335],
              [-39.249922756534545, -8.531182288173357],
              [-39.252925731440264, -8.534423069890625],
              [-39.253391228982942, -8.534956527096154],
              [-39.254076771398879, -8.535710724920303],
              [-39.254456871699553, -8.536128909564811],
              [-39.254708211270859, -8.536405529593084],
              [-39.259168204948196, -8.541208862865522],
              [-39.260817710264838, -8.543047470849865],
              [-39.261425445856517, -8.543724822228564],
              [-39.262027636281466, -8.544395844640094],
              [-39.262636284289776, -8.545072379256734],
              [-39.263245574504914, -8.545754701355065],
              [-39.263852226710583, -8.546429788002689],
              [-39.264461971116766, -8.547105595121939],
              [-39.26470277908399, -8.547374707724574],
              [-39.265076904866568, -8.547792432846858],
              [-39.265081270043076, -8.547797404746486],
              [-39.265700183365979, -8.548454027534522],
              [-39.266306397181339, -8.549139693229725],
              [-39.26693030803461, -8.549789707410415],
              [-39.267562846922445, -8.550430036123569],
              [-39.26818886182857, -8.551094066783342],
              [-39.268820142421404, -8.551748142915924],
              [-39.269448333788944, -8.552399687413804],
              [-39.269511262227851, -8.552465040722019],
              [-39.269574099754017, -8.552530303630069],
              [-39.269637028172248, -8.552595566463657],
              [-39.270076345806878, -8.553051592740541],
              [-39.270709358886123, -8.553704940702245],
              [-39.272140453990993, -8.555196311486219],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0031000',
        uf: 'PE',
        nome_proje: 'PA SÃO MIGUEL',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '746.8242',
        capacidade: 32.0,
        num_famili: 31.0,
        fase: 5.0,
        data_de_cr: '15/12/1998',
        forma_obte: 'Desapropriação',
        data_obten: '23/06/1998',
        area_calc_: 607.4871,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.963855291420934, -8.745572173506766],
              [-39.964204869188201, -8.750360091679601],
              [-39.964213984964871, -8.750546465663692],
              [-39.964521908831927, -8.754756394080653],
              [-39.964782841427493, -8.758365375424832],
              [-39.965116347286873, -8.762968378120309],
              [-39.965381960749106, -8.766727655760175],
              [-39.968585563661634, -8.766307232029133],
              [-39.968450771094226, -8.762841735738842],
              [-39.96827292139286, -8.758227217697483],
              [-39.968101844596127, -8.753485612398775],
              [-39.96793019094946, -8.749009177223085],
              [-39.967921625897091, -8.748825515300302],
              [-39.967792665212592, -8.745388940276225],
              [-39.967784923084004, -8.745206994559679],
              [-39.967608433447651, -8.740469739533179],
              [-39.967457552510488, -8.736458651942945],
              [-39.96956057986835, -8.733494893195585],
              [-39.972900569027239, -8.730567297188983],
              [-39.976302296149619, -8.727596287763886],
              [-39.976800291391271, -8.727157186462682],
              [-39.980556024100089, -8.723877467104959],
              [-39.979694211319746, -8.72367186105029],
              [-39.979211105886009, -8.721868017000865],
              [-39.979167839083388, -8.721728941522263],
              [-39.977896575781983, -8.717066961939791],
              [-39.976743398405006, -8.712849816034254],
              [-39.975555852498367, -8.708476741967143],
              [-39.974368584263509, -8.704092897161763],
              [-39.974351158074825, -8.700447390213869],
              [-39.974338001413415, -8.696588251956316],
              [-39.975777127064504, -8.693465648626438],
              [-39.977237547759806, -8.690221164477229],
              [-39.978855156529058, -8.686439059166583],
              [-39.97911055888062, -8.68594803716195],
              [-39.977628659396643, -8.685310257575127],
              [-39.977549158088834, -8.685180679812333],
              [-39.975538645188813, -8.681904568211019],
              [-39.97539449341965, -8.68179885049269],
              [-39.975371625749929, -8.680933124843868],
              [-39.975401616385021, -8.680896781638051],
              [-39.975312584649842, -8.680841931180776],
              [-39.973321904730788, -8.677767343278632],
              [-39.972800675294621, -8.676962668433719],
              [-39.972275499753394, -8.676142356482634],
              [-39.97173994261005, -8.675313206805136],
              [-39.971204580399743, -8.674488215848394],
              [-39.970663644926631, -8.673650938134434],
              [-39.970115509598166, -8.672804995376666],
              [-39.969559622919157, -8.671947947043263],
              [-39.969539539406874, -8.671913540241638],
              [-39.969003351700415, -8.671081673645265],
              [-39.960089087676117, -8.657285032910291],
              [-39.959448659263266, -8.656293781572623],
              [-39.95940959524718, -8.656228400457481],
              [-39.958758250842685, -8.655231748459888],
              [-39.958125954234923, -8.654254672889046],
              [-39.957457061154322, -8.653216006823355],
              [-39.956796460286796, -8.65218446323739],
              [-39.956114279800111, -8.651135426457735],
              [-39.955420327499525, -8.650065796869386],
              [-39.954713063853887, -8.64897765836232],
              [-39.953999404975107, -8.6478745208008],
              [-39.95326606890545, -8.64675108118022],
              [-39.952534145156712, -8.645609186162888],
              [-39.951785009371392, -8.644451323929097],
              [-39.951024947990412, -8.643283628731268],
              [-39.9502625422706, -8.642085096884331],
              [-39.949465201770288, -8.640869917904258],
              [-39.948668866080951, -8.639618286407522],
              [-39.94783484468838, -8.638339884804013],
              [-39.947733945743416, -8.638185479992826],
              [-39.945363854125176, -8.634524123746688],
              [-39.943300408735624, -8.631328218141256],
              [-39.941544350759507, -8.628609977735131],
              [-39.941475865016287, -8.628624616382186],
              [-39.939330852942255, -8.629021755654014],
              [-39.940414616059449, -8.630837785781614],
              [-39.94148521578682, -8.632618390919509],
              [-39.942489969694655, -8.634301658015712],
              [-39.943449808139363, -8.635899564965332],
              [-39.944350760485264, -8.637422637847941],
              [-39.94522693193921, -8.638881465177171],
              [-39.94605994205908, -8.640267319798236],
              [-39.946146844121323, -8.640421216958647],
              [-39.94693261918836, -8.641740620841107],
              [-39.947706324492323, -8.643028307483405],
              [-39.948454687444766, -8.644282862804507],
              [-39.949192277555085, -8.645515827351481],
              [-39.949922287664485, -8.646732077109167],
              [-39.950634531571417, -8.647928743319779],
              [-39.951335370961871, -8.64910590074752],
              [-39.952031976229264, -8.650259914009551],
              [-39.952709468583748, -8.65140122089255],
              [-39.953392041962452, -8.652537448729468],
              [-39.954705366659653, -8.654725375924613],
              [-39.955347897441428, -8.655799294562046],
              [-39.955974305700366, -8.656857424825983],
              [-39.956601036435181, -8.657897826432812],
              [-39.956650046543132, -8.657979010123915],
              [-39.957220121903589, -8.658932457518771],
              [-39.957253011707806, -8.659000205774435],
              [-39.957870325665354, -8.660015666363755],
              [-39.964548965367904, -8.671193248403794],
              [-39.965069143444083, -8.672058987499167],
              [-39.965589757461153, -8.672916494405083],
              [-39.966093717506048, -8.673764636643337],
              [-39.966597024770849, -8.674605363472244],
              [-39.967090381566884, -8.675428116922468],
              [-39.967583933529554, -8.676255210116439],
              [-39.968074915337596, -8.677071546515668],
              [-39.968559980741745, -8.677883646339385],
              [-39.970531660510687, -8.681182603550214],
              [-39.970587433931527, -8.681275886512276],
              [-39.970893748672893, -8.685417996987336],
              [-39.971285518770607, -8.690692019196016],
              [-39.972782846159028, -8.693429804802815],
              [-39.97228612715007, -8.694984926473076],
              [-39.973118118106498, -8.699791687701632],
              [-39.973851545566539, -8.704117916148935],
              [-39.974638912338989, -8.708731059075582],
              [-39.975406443720694, -8.71318652064677],
              [-39.976165122249391, -8.717619028681868],
              [-39.977101850022372, -8.723047722699016],
              [-39.974454861491814, -8.724981621465639],
              [-39.970084524593588, -8.728174679665072],
              [-39.966403299807354, -8.730896555299237],
              [-39.962752516622068, -8.733600235147103],
              [-39.963209783223654, -8.736550411509262],
              [-39.963537321213572, -8.741050699905937],
              [-39.963855291420934, -8.745572173506766],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0035000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DA CONCEIÇÃO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '3443.4000',
        capacidade: 140.0,
        num_famili: 139.0,
        fase: 5.0,
        data_de_cr: '13/08/1999',
        forma_obte: 'Desapropriação',
        data_obten: '24/11/1998',
        area_calc_: 3475.5182,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.924511759641092, -8.826113635780249],
              [-39.928530547530606, -8.824089783239822],
              [-39.931695735420519, -8.822485303585132],
              [-39.93224368428595, -8.82217264711495],
              [-39.935736815102899, -8.820356505520348],
              [-39.939829233798768, -8.818234232616275],
              [-39.943545215559013, -8.816305141218116],
              [-39.946619279459235, -8.814712624897647],
              [-39.948603410190046, -8.813683567515023],
              [-39.949090020053433, -8.81343109621413],
              [-39.952671608474901, -8.811573530013625],
              [-39.956705508409996, -8.809474573968151],
              [-39.960874567918744, -8.807308760530047],
              [-39.962670840519657, -8.806376344546337],
              [-39.963171321857132, -8.806116586218225],
              [-39.966601940852684, -8.804335799769669],
              [-39.970901823199966, -8.802108956041769],
              [-39.976709750434445, -8.799097755530871],
              [-39.97881464044697, -8.798005929235666],
              [-39.979403803700968, -8.797700249081963],
              [-39.984701506611522, -8.7949521780355],
              [-39.988884222534097, -8.792788131493117],
              [-39.989214691277368, -8.792612083017692],
              [-39.992336276689969, -8.790996136006463],
              [-39.994029151936431, -8.790117308132508],
              [-39.99488521028519, -8.789672982540413],
              [-39.995772225146631, -8.789212384820779],
              [-39.999212500115824, -8.787427870723656],
              [-40.002368119226823, -8.785790777533144],
              [-40.007694232271461, -8.78302588271935],
              [-40.011082267602475, -8.781266191964274],
              [-40.016079796454925, -8.778675986091471],
              [-40.020622572819228, -8.776307983084466],
              [-40.014947816267274, -8.776356113752867],
              [-40.013632640012077, -8.776372399695395],
              [-40.010320463965925, -8.776411395861494],
              [-40.005997442663876, -8.776453781998798],
              [-40.003436788866324, -8.776478233591281],
              [-39.998111028208854, -8.776532870914123],
              [-39.993429830698105, -8.776589083576773],
              [-39.993950295962961, -8.773237208916303],
              [-39.994596438061166, -8.769013529975245],
              [-39.994597504919781, -8.769004483236102],
              [-39.994961104993152, -8.766597207337595],
              [-39.995544753122786, -8.762739971491527],
              [-39.994600626295338, -8.762862740996281],
              [-39.993607786563295, -8.762991243491795],
              [-39.992706100912947, -8.76310964618914],
              [-39.992165738926992, -8.763178894399569],
              [-39.99110647690857, -8.763320497674835],
              [-39.990301846391617, -8.763426159672845],
              [-39.989423873220716, -8.76354006110339],
              [-39.987758901363605, -8.763758938097869],
              [-39.986200886915753, -8.76396251594546],
              [-39.984852253823398, -8.764138771919445],
              [-39.983522902109009, -8.764318952384661],
              [-39.982191972514016, -8.764487465694046],
              [-39.980878871264558, -8.764660450157107],
              [-39.979543036487463, -8.764831770751007],
              [-39.975511371172864, -8.765357795828869],
              [-39.970417367672859, -8.768848054807641],
              [-39.967202857148628, -8.771032827717956],
              [-39.962724123576386, -8.774081159128654],
              [-39.961350290863095, -8.775014655652742],
              [-39.961053263933216, -8.775220982355339],
              [-39.959333937381501, -8.776396374920475],
              [-39.955752014747837, -8.778835591429386],
              [-39.952305262525265, -8.781179294085044],
              [-39.948692931857359, -8.783639421022572],
              [-39.944080553828805, -8.786776980783543],
              [-39.940700093239528, -8.789078280188221],
              [-39.936911520781351, -8.791656683517457],
              [-39.933127073499826, -8.794234048682329],
              [-39.92919608835615, -8.796915568442289],
              [-39.925389464714037, -8.799504225806849],
              [-39.923808649816678, -8.800569975120592],
              [-39.923690114672354, -8.800653200333789],
              [-39.922123224093689, -8.801728496058404],
              [-39.920553862562059, -8.802801078919382],
              [-39.9204339529592, -8.802880598521302],
              [-39.918876096740668, -8.803940394963915],
              [-39.917294178759484, -8.805017066484961],
              [-39.917175085528967, -8.805096402307527],
              [-39.915608240868522, -8.806169232381183],
              [-39.914033917503424, -8.807236467586556],
              [-39.913914193022642, -8.807318517275938],
              [-39.912338335828025, -8.808394703690228],
              [-39.910757732585459, -8.809467368629459],
              [-39.910179432411176, -8.809862730648518],
              [-39.90900392913008, -8.811068080796151],
              [-39.905322742166128, -8.814883185426512],
              [-39.904250697340984, -8.815983814510568],
              [-39.903938152546893, -8.816304728785049],
              [-39.902159103944712, -8.818131397835815],
              [-39.898974803541506, -8.821355670937558],
              [-39.895731171607196, -8.824545424334831],
              [-39.895666563339567, -8.824599933810227],
              [-39.895562300173346, -8.824687639458055],
              [-39.899784945030454, -8.830173577576025],
              [-39.904897516221965, -8.835384718194836],
              [-39.905016637180879, -8.835311806432301],
              [-39.905150920848094, -8.835229723475759],
              [-39.907653733132634, -8.833852043540013],
              [-39.910977869891781, -8.832211161142071],
              [-39.911734201442968, -8.831837791782458],
              [-39.912014374445825, -8.831699549907343],
              [-39.912148174195728, -8.831643784243797],
              [-39.916772640374816, -8.829716249083233],
              [-39.917207177881771, -8.829530597206007],
              [-39.920202421294078, -8.828287389436678],
              [-39.924511759641092, -8.826113635780249],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0026000',
        uf: 'PE',
        nome_proje: 'PA BOQUEIRÃO',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '1102.0000',
        capacidade: 105.0,
        num_famili: 102.0,
        fase: 5.0,
        data_de_cr: '30/06/1997',
        forma_obte: 'Desapropriação',
        data_obten: '30/06/1997',
        area_calc_: 1641.5168,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.915386760978194, -8.836255260999254],
              [-39.913478801009319, -8.836883956793555],
              [-39.912516070255307, -8.837338506210326],
              [-39.908736502135142, -8.838748709320123],
              [-39.908736593507506, -8.838748889980899],
              [-39.906583791523929, -8.83955061777532],
              [-39.90681119023602, -8.840436029519326],
              [-39.907015845582912, -8.84087046591811],
              [-39.907541670197631, -8.841826060462038],
              [-39.908213514509534, -8.843071254337763],
              [-39.908418954738963, -8.843824583098385],
              [-39.908701486010649, -8.845348735112374],
              [-39.908891499260342, -8.846781042043471],
              [-39.909038605376161, -8.847476538801677],
              [-39.909472035050683, -8.849311259476172],
              [-39.911691540187647, -8.848584775947558],
              [-39.912106961241541, -8.848454705042521],
              [-39.913088240705633, -8.848135770295821],
              [-39.916613578731095, -8.846996647127932],
              [-39.918294456778732, -8.846449884620174],
              [-39.921928784777208, -8.845274270992368],
              [-39.922192257961704, -8.845183181717328],
              [-39.929551669973272, -8.842795449199055],
              [-39.938337389123674, -8.83994276727508],
              [-39.946768635473781, -8.837208360154396],
              [-39.95311934790702, -8.835193545149906],
              [-39.95399152749193, -8.834910963413733],
              [-39.961641365587191, -8.832494770456499],
              [-39.973906231869591, -8.828592317111976],
              [-39.980292950687172, -8.82656793445315],
              [-39.988414762071834, -8.823987207486672],
              [-40.003794994088004, -8.81910792988155],
              [-40.024207375051745, -8.812631717046923],
              [-40.031874262576515, -8.810187010810116],
              [-40.029991056460169, -8.803192258358798],
              [-40.023258857129697, -8.804947039859922],
              [-40.016770603689835, -8.806167461131174],
              [-40.008459901049328, -8.805909469527522],
              [-40.008586133320598, -8.805520244476988],
              [-40.000310750385374, -8.808255506004761],
              [-39.979281035121275, -8.815211379376107],
              [-39.977373227136653, -8.815804202309328],
              [-39.969651859186278, -8.818364745494428],
              [-39.968789295628866, -8.818653393245301],
              [-39.967887309621098, -8.818957334595343],
              [-39.967001955638494, -8.819259241444069],
              [-39.966106189899484, -8.819543899124474],
              [-39.965234068681433, -8.819830845491408],
              [-39.96435768520444, -8.820122684663326],
              [-39.963480483485931, -8.820415157107281],
              [-39.962616710373318, -8.820697373521188],
              [-39.961752310761305, -8.820984021240617],
              [-39.96085028388665, -8.821277184598316],
              [-39.959968880323345, -8.821564595505478],
              [-39.959108834333861, -8.821848874982225],
              [-39.958249798895963, -8.822137762482816],
              [-39.957384217277728, -8.822427388360326],
              [-39.956519619382441, -8.822711221710124],
              [-39.955670915639388, -8.822988772389893],
              [-39.954805865242896, -8.823274592888799],
              [-39.953938356511728, -8.823559694252191],
              [-39.953081024217823, -8.823842597351943],
              [-39.952227962721615, -8.82412503559296],
              [-39.951371633408371, -8.824410193509696],
              [-39.950491769311391, -8.82470173999608],
              [-39.949490155800397, -8.825029316353433],
              [-39.948139970924395, -8.825478960584274],
              [-39.946357957310639, -8.826063812035132],
              [-39.944829136871242, -8.826565808144139],
              [-39.94162507330595, -8.82763463425888],
              [-39.940762013238377, -8.82792620773416],
              [-39.934320655758732, -8.830040518662345],
              [-39.933039661973822, -8.83046877677908],
              [-39.931722294165176, -8.830897121136518],
              [-39.930404945552795, -8.831334505181326],
              [-39.929023989824316, -8.831790130550473],
              [-39.927733866893526, -8.832209350268997],
              [-39.92769754127395, -8.832227528239665],
              [-39.926352903716626, -8.832664966457433],
              [-39.925035518207849, -8.833093288882992],
              [-39.923699966239496, -8.833530695791525],
              [-39.922373503447062, -8.833968075896939],
              [-39.921119711970732, -8.834378140888882],
              [-39.919784149473223, -8.834815534769412],
              [-39.918484909834774, -8.835234747056081],
              [-39.91843039777585, -8.835252968740882],
              [-39.917985208274374, -8.835398764210549],
              [-39.915386760978194, -8.836255260999254],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0143000',
        uf: 'PE',
        nome_proje: 'PA NOVA ESPERANÇA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '278.9809',
        capacidade: 25.0,
        num_famili: 24.0,
        fase: 3.0,
        data_de_cr: '07/07/2005',
        forma_obte: 'Doação',
        data_obten: '07/07/2005',
        area_calc_: 279.7489,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.109207448806742, -8.684527872535774],
              [-39.105648590768865, -8.696203865042694],
              [-39.106700836326311, -8.696239571835632],
              [-39.106700748009203, -8.696239548657696],
              [-39.106700764851013, -8.696239549244277],
              [-39.106700781691686, -8.696239549861927],
              [-39.106700798531179, -8.696239550510642],
              [-39.106700815369443, -8.696239551190411],
              [-39.106700832206414, -8.696239551901231],
              [-39.106700849042014, -8.696239552643116],
              [-39.106700865876213, -8.696239553416069],
              [-39.106700882708942, -8.69623955422006],
              [-39.106700899540151, -8.696239555055085],
              [-39.106700916369768, -8.696239555921176],
              [-39.106700933197743, -8.696239556818306],
              [-39.10670095002402, -8.696239557746484],
              [-39.106700966848535, -8.696239558705663],
              [-39.106700983671239, -8.696239559695876],
              [-39.106701000492066, -8.696239560717128],
              [-39.106701017310961, -8.696239561769426],
              [-39.106701034127873, -8.696239562852728],
              [-39.106701050942732, -8.696239563967048],
              [-39.10670106775548, -8.696239565112384],
              [-39.106701084566083, -8.696239566288739],
              [-39.106701101374455, -8.696239567496093],
              [-39.106701118180538, -8.696239568734434],
              [-39.106701134984291, -8.696239570003772],
              [-39.106701151785664, -8.696239571304133],
              [-39.106701168584578, -8.696239572635461],
              [-39.106701185380977, -8.696239573997767],
              [-39.106701202174818, -8.696239575391079],
              [-39.10670121896603, -8.696239576815358],
              [-39.106701235754549, -8.696239578270623],
              [-39.106701252540333, -8.696239579756819],
              [-39.106701269323324, -8.696239581273982],
              [-39.106701286103473, -8.696239582822118],
              [-39.106701302880687, -8.696239584401182],
              [-39.106701319654945, -8.696239586011215],
              [-39.106701336426163, -8.696239587652165],
              [-39.106701353194296, -8.69623958932403],
              [-39.106701369959296, -8.696239591026849],
              [-39.106701386721078, -8.696239592760568],
              [-39.106701403479619, -8.696239594525222],
              [-39.106701420234842, -8.696239596320773],
              [-39.106701436986683, -8.696239598147224],
              [-39.106701453735084, -8.69623960000458],
              [-39.106701470480004, -8.696239601892815],
              [-39.106701487221386, -8.69623960381195],
              [-39.106701503959158, -8.696239605761955],
              [-39.106701520693264, -8.696239607742839],
              [-39.106701537423653, -8.696239609754562],
              [-39.10670155415027, -8.696239611797161],
              [-39.106701570873042, -8.696239613870603],
              [-39.106701587591928, -8.696239615974905],
              [-39.106701604306863, -8.696239618110024],
              [-39.106701621017798, -8.696239620275973],
              [-39.106701637724669, -8.696239622472742],
              [-39.106701654427418, -8.696239624700343],
              [-39.106701671125975, -8.696239626958727],
              [-39.106701687820319, -8.696239629247911],
              [-39.106701704510343, -8.696239631567893],
              [-39.106701721196032, -8.696239633918642],
              [-39.106701737877309, -8.69623963630019],
              [-39.106701754554123, -8.696239638712486],
              [-39.106701771226405, -8.696239641155534],
              [-39.106701787894117, -8.696239643629346],
              [-39.106701804557183, -8.696239646133886],
              [-39.106701821215559, -8.69623964866916],
              [-39.106701837869196, -8.696239651235153],
              [-39.106701854518001, -8.696239653831878],
              [-39.106701871161953, -8.696239656459316],
              [-39.106701887800973, -8.69623965911742],
              [-39.10670190443502, -8.696239661806223],
              [-39.106701921064015, -8.696239664525709],
              [-39.10670193768793, -8.696239667275853],
              [-39.106701954306693, -8.696239670056668],
              [-39.106701970920234, -8.696239672868128],
              [-39.106701987528531, -8.696239675710219],
              [-39.106702004131478, -8.696239678582947],
              [-39.106702020729067, -8.696239681486301],
              [-39.106702037321185, -8.69623968442027],
              [-39.106702053907838, -8.69623968738482],
              [-39.106702070488936, -8.696239690379969],
              [-39.106702087064427, -8.696239693405696],
              [-39.106702103634248, -8.696239696461989],
              [-39.106702120198335, -8.696239699548844],
              [-39.106702136756653, -8.696239702666249],
              [-39.106702153309129, -8.696239705814198],
              [-39.106702169855723, -8.696239708992666],
              [-39.106702186396355, -8.696239712201626],
              [-39.106702202931004, -8.696239715441113],
              [-39.106702219459557, -8.696239718711086],
              [-39.106702235981999, -8.696239722011537],
              [-39.106702252498266, -8.696239725342483],
              [-39.106702269008295, -8.696239728703876],
              [-39.10670228551205, -8.696239732095718],
              [-39.106702302009438, -8.696239735518004],
              [-39.106702318500432, -8.696239738970702],
              [-39.106702334984959, -8.696239742453811],
              [-39.106702351462978, -8.696239745967336],
              [-39.10670236793441, -8.696239749511218],
              [-39.106702384399213, -8.696239753085504],
              [-39.10670240085728, -8.696239756690364],
              [-39.106702472683189, -8.696239752282354],
              [-39.107653953344837, -8.696449063153421],
              [-39.110213262568017, -8.688166853821928],
              [-39.110244954692014, -8.688052693464622],
              [-39.112674525339827, -8.680072614631019],
              [-39.098154865112392, -8.614137050605134],
              [-39.098114646280344, -8.613954164921129],
              [-39.098024381722176, -8.61354443588406],
              [-39.096651356481601, -8.614223271487869],
              [-39.096729994168768, -8.61466402915033],
              [-39.096763305925499, -8.61485035393161],
              [-39.109207448806742, -8.684527872535774],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0025000',
        uf: 'PE',
        nome_proje: 'PA VITORIA',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '4163.2000',
        capacidade: 264.0,
        num_famili: 259.0,
        fase: 5.0,
        data_de_cr: '27/05/1997',
        forma_obte: 'Desapropriação',
        data_obten: '19/08/1996',
        area_calc_: 4494.515,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.016166160091942, -8.872441861269701],
              [-40.012328662460881, -8.874541630366902],
              [-40.012730261511983, -8.875313969995979],
              [-40.010300692278456, -8.876665732365771],
              [-40.004326080884447, -8.879978337562223],
              [-39.996932667904403, -8.884080926365094],
              [-39.99536323570598, -8.884952424956404],
              [-39.988976329220563, -8.888483236769174],
              [-39.980821324815189, -8.893011177573994],
              [-39.975901815008044, -8.895743509080782],
              [-39.975450039609434, -8.895993309595863],
              [-39.974947682574175, -8.896271006841616],
              [-39.971128399463957, -8.89838252080037],
              [-39.971818917527642, -8.899377993725816],
              [-39.972137269116672, -8.899838481040049],
              [-39.973576930513602, -8.901920894318014],
              [-39.974642698663757, -8.903466696196928],
              [-39.973323633392681, -8.904355909300874],
              [-39.972001933177083, -8.905248561926618],
              [-39.970682334211261, -8.906147354874818],
              [-39.970141451987622, -8.906514049626949],
              [-39.969505065080682, -8.906945475768403],
              [-39.969364260766426, -8.907040894317515],
              [-39.968048194280598, -8.9079393082168],
              [-39.966736654325175, -8.908832370399745],
              [-39.965403650850241, -8.909727835703949],
              [-39.964097101928786, -8.910621781261353],
              [-39.962772039880939, -8.911533496901143],
              [-39.961458297626265, -8.912429261838204],
              [-39.960134823840754, -8.913327489745344],
              [-39.958818695830971, -8.914220268248455],
              [-39.957491810608182, -8.915105835245559],
              [-39.956165667932339, -8.915999716654484],
              [-39.954846700544493, -8.916892490242869],
              [-39.953538237671999, -8.917770310269624],
              [-39.953071854328606, -8.918085332173371],
              [-39.952187839248026, -8.918682510378359],
              [-39.950859929028226, -8.919571496107395],
              [-39.949539657369144, -8.920462628736537],
              [-39.948217930397412, -8.921356293311087],
              [-39.946891816034409, -8.922244086389062],
              [-39.94556763029464, -8.923141728373441],
              [-39.944249517000124, -8.924033743539782],
              [-39.942923584203761, -8.924929211202153],
              [-39.941596266047085, -8.925819070977653],
              [-39.940272954103918, -8.926713257587416],
              [-39.938948982604849, -8.927601110956033],
              [-39.937624017143712, -8.928494117795209],
              [-39.936295942951865, -8.929383329869109],
              [-39.934971684071613, -8.930273342143305],
              [-39.933646332377442, -8.931165433163864],
              [-39.932320507265302, -8.932052818355103],
              [-39.930990594343342, -8.932944822110592],
              [-39.929661565641723, -8.933829493830416],
              [-39.928335815985683, -8.934718675189796],
              [-39.927835422047984, -8.935053741848613],
              [-39.927352601451084, -8.935468803589258],
              [-39.927070111938413, -8.935689008119857],
              [-39.92578368188213, -8.936630538633219],
              [-39.919553504755811, -8.941218974623103],
              [-39.914372719389505, -8.945010031433272],
              [-39.912012379407933, -8.946712693500281],
              [-39.908177723353226, -8.94952577230233],
              [-39.904594213896686, -8.952147373358331],
              [-39.90430205182772, -8.952366049071152],
              [-39.903748279241832, -8.952762807888497],
              [-39.90257090678702, -8.953606326441438],
              [-39.89979138885063, -8.955597815412172],
              [-39.898690508174823, -8.956410025641867],
              [-39.899372293585863, -8.958168618859919],
              [-39.900683133796406, -8.961392175993922],
              [-39.90253398717558, -8.964928056441423],
              [-39.905917378378462, -8.968636982104682],
              [-39.909470468907593, -8.966053958269175],
              [-39.912206164187445, -8.964065035495006],
              [-39.912996359394967, -8.963490507078101],
              [-39.913650881903095, -8.964097826119682],
              [-39.914599722320986, -8.964873720382283],
              [-39.91422474846604, -8.965151214392467],
              [-39.913487146783822, -8.965697036442208],
              [-39.910280133244747, -8.968070326259314],
              [-39.907062181144354, -8.970451577604798],
              [-39.908000587369159, -8.972077564416175],
              [-39.911226262790109, -8.969726854138326],
              [-39.914686057269165, -8.967205331440747],
              [-39.91541995775961, -8.966670459368499],
              [-39.91624392465453, -8.966069884033558],
              [-39.916537237411113, -8.966273009598142],
              [-39.91827424093524, -8.967508726533922],
              [-39.916669287587723, -8.968583885029629],
              [-39.915914482053253, -8.96908950885733],
              [-39.912637245988741, -8.97128482650545],
              [-39.909122950928698, -8.973638972941282],
              [-39.909847939505347, -8.974812552475008],
              [-39.913500801534809, -8.972141333833573],
              [-39.916407634543717, -8.97001566166086],
              [-39.91715097534518, -8.969471989807024],
              [-39.919071739113264, -8.96806738398106],
              [-39.919947472914302, -8.968845343235506],
              [-39.917665195579261, -8.970491241778742],
              [-39.916919110350342, -8.971029133395573],
              [-39.914237925894049, -8.972962698963352],
              [-39.910327914486651, -8.975782248178955],
              [-39.910342958155773, -8.975869124372354],
              [-39.910579509038115, -8.977236814674701],
              [-39.91132199346611, -8.976668645242187],
              [-39.912875143274988, -8.975482120480477],
              [-39.912853080588242, -8.975425921084753],
              [-39.915028154958939, -8.973884590838367],
              [-39.917500310802062, -8.972132690377682],
              [-39.918247963501479, -8.971602752330796],
              [-39.920556103262079, -8.969967095629396],
              [-39.920968859780388, -8.969667162370186],
              [-39.923968173862157, -8.967521761572614],
              [-39.931203691539906, -8.962315877910468],
              [-39.932379083124204, -8.961463866408964],
              [-39.933410394000795, -8.960720471861126],
              [-39.934443964338051, -8.959973089761712],
              [-39.935465158835051, -8.959225284255179],
              [-39.936500912722749, -8.958481509264725],
              [-39.937528747766841, -8.957737299628162],
              [-39.938568109224093, -8.956984918707704],
              [-39.939608466562419, -8.956232442297663],
              [-39.940657270357327, -8.955476776504119],
              [-39.941703700603838, -8.95471948626456],
              [-39.942748035534009, -8.953962651005565],
              [-39.943806286077326, -8.95320722476767],
              [-39.944853418857676, -8.952445403064109],
              [-39.945919366630775, -8.951680274917873],
              [-39.946980924965928, -8.950907829732245],
              [-39.948044600389665, -8.950146862340212],
              [-39.949106522222877, -8.949377847676445],
              [-39.950163438316757, -8.948609385865586],
              [-39.951221426489411, -8.947835221011079],
              [-39.952274055232515, -8.947065498870554],
              [-39.953337876228851, -8.946299362941701],
              [-39.954385670760182, -8.945527929726257],
              [-39.955443458848748, -8.944753845455409],
              [-39.956505499447204, -8.943972964654293],
              [-39.957577876912445, -8.943181653998462],
              [-39.958650388099976, -8.942408518656972],
              [-39.95972684350189, -8.941615112008391],
              [-39.960799500379949, -8.940831751292221],
              [-39.961881043585166, -8.940039863558475],
              [-39.962965926778097, -8.939240186660946],
              [-39.964054270783912, -8.938444115617056],
              [-39.965141922778258, -8.937628870506703],
              [-39.966242240211741, -8.936824261506768],
              [-39.967348691566634, -8.936002269378895],
              [-39.968474325679843, -8.935179139077608],
              [-39.969596298119292, -8.934349051600655],
              [-39.970735876769005, -8.933506434554831],
              [-39.97187064280498, -8.932668801271003],
              [-39.973013120127398, -8.93182580889242],
              [-39.974161132547515, -8.930979995365057],
              [-39.975314295288193, -8.930123493458657],
              [-39.975887708765676, -8.929701806516594],
              [-39.976333857906759, -8.929373877080696],
              [-39.97647991113859, -8.92926641309923],
              [-39.9776424240249, -8.928407438561592],
              [-39.978799392553661, -8.927551912211021],
              [-39.979963878449091, -8.926686685973618],
              [-39.981128734073529, -8.925825796711102],
              [-39.982289836541746, -8.924957950460779],
              [-39.983453626679584, -8.924076980364742],
              [-39.984625861953326, -8.923193271617205],
              [-39.985807360070858, -8.922306550661704],
              [-39.987002841038411, -8.921379544100628],
              [-39.992542918729974, -8.929413370138207],
              [-39.994241564584769, -8.928127852447378],
              [-40.000447612318709, -8.923461017823319],
              [-40.006166676026176, -8.919066276749751],
              [-40.006335900474497, -8.917760793954949],
              [-40.010956782778344, -8.914477828084712],
              [-40.011223614975734, -8.91424539982334],
              [-40.013329953826151, -8.912410825268253],
              [-40.01512775947743, -8.910796938062385],
              [-40.018140289685796, -8.908525040364964],
              [-40.018576910007916, -8.908195736117341],
              [-40.021299802067112, -8.906142203527551],
              [-40.024165900517069, -8.901670584040652],
              [-40.025962879271297, -8.89613906947425],
              [-40.019966957798069, -8.886802791926005],
              [-40.015895164620403, -8.879267923552915],
              [-40.01322566170758, -8.875306928383626],
              [-40.013062798239787, -8.875009647356476],
              [-40.012966026944831, -8.874815296333063],
              [-40.012898206946574, -8.874679089608717],
              [-40.016521467266131, -8.872701217384744],
              [-40.016474245826494, -8.872614341955178],
              [-40.016331359648589, -8.872351467435537],
              [-40.016166160091942, -8.872441861269701],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0052000',
        uf: 'PE',
        nome_proje: 'PA TUCUTÚ/JIBOIA',
        municipio: 'CABROBO',
        area_hecta: '893.7455',
        capacidade: 50.0,
        num_famili: 43.0,
        fase: 4.0,
        data_de_cr: '15/01/2001',
        forma_obte: 'Desapropriação',
        data_obten: '15/01/2001',
        area_calc_: 924.773,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.407922127642358, -8.490605358078685],
              [-39.410696577423991, -8.489850974030906],
              [-39.41495533997837, -8.488537632049864],
              [-39.417452954656696, -8.487809183877346],
              [-39.418274746503258, -8.487572767782963],
              [-39.418202350699794, -8.487334596466281],
              [-39.421274790085995, -8.485779596173307],
              [-39.422256821521202, -8.486024833235099],
              [-39.422287101351927, -8.486380907424786],
              [-39.423235689343542, -8.486117932037429],
              [-39.423377458968034, -8.484991206680542],
              [-39.423384124359011, -8.484938466310926],
              [-39.423549491966561, -8.48362475231348],
              [-39.423719292864476, -8.481216933784536],
              [-39.423338569468832, -8.480754778727638],
              [-39.422952296599185, -8.480035656908768],
              [-39.422744922719829, -8.479907531089811],
              [-39.422585192591072, -8.479813363287525],
              [-39.421622722113923, -8.479245645889179],
              [-39.421432450960836, -8.477719484044449],
              [-39.42172154770563, -8.477475586224948],
              [-39.422698568847693, -8.476979308202592],
              [-39.422960510649581, -8.476828603850224],
              [-39.42318627047824, -8.47665695382913],
              [-39.423201521033519, -8.476313222027734],
              [-39.423154354478932, -8.47571810300491],
              [-39.423132731782395, -8.475219377459018],
              [-39.423092978446306, -8.474673636814234],
              [-39.42299189736012, -8.474207740708989],
              [-39.422921131714801, -8.473881649596407],
              [-39.422646835729708, -8.472617254944415],
              [-39.422615516389662, -8.472472837927208],
              [-39.421959729415804, -8.469778546669412],
              [-39.422444640132561, -8.467737528866538],
              [-39.423627769947757, -8.466490551184485],
              [-39.424044403293365, -8.464996022749363],
              [-39.424767553234645, -8.464742516429089],
              [-39.425087580638355, -8.464630370638247],
              [-39.425822988991072, -8.464372597882111],
              [-39.426220607108249, -8.459721876239632],
              [-39.426664442292548, -8.455220981222219],
              [-39.426732184300839, -8.454533476372973],
              [-39.426759586945884, -8.454255760549556],
              [-39.426859403092323, -8.453243138174441],
              [-39.426902169978135, -8.452810643141234],
              [-39.427129921617833, -8.4505002645116],
              [-39.427277202526156, -8.449033342898087],
              [-39.427308023838847, -8.448725774282604],
              [-39.427584880387506, -8.445968059028653],
              [-39.423338946999081, -8.44358067901444],
              [-39.420960269621787, -8.442214166335038],
              [-39.420731859499547, -8.446213003825648],
              [-39.421851438243323, -8.448232849078153],
              [-39.424501509465543, -8.452755093278608],
              [-39.42245381290747, -8.455562480576784],
              [-39.422582614487339, -8.455795525464165],
              [-39.422720882886253, -8.456045655404273],
              [-39.425090589747342, -8.460332477205691],
              [-39.424204109057818, -8.460590502612821],
              [-39.423937192870675, -8.460668218441972],
              [-39.422094841149111, -8.461204417329965],
              [-39.42065148513678, -8.458806927441428],
              [-39.420125884807625, -8.458510087682257],
              [-39.419301372141121, -8.457600849061501],
              [-39.419688402522624, -8.456949725921929],
              [-39.419690425975794, -8.453613328840257],
              [-39.4186234487726, -8.450842674028291],
              [-39.418661399140944, -8.448209678941828],
              [-39.417816347666133, -8.444819460875765],
              [-39.407011038511172, -8.449804772150079],
              [-39.407008038340734, -8.449889257144712],
              [-39.407008027469416, -8.449889264763263],
              [-39.406909816422484, -8.452645150625765],
              [-39.406794625919524, -8.457153019615786],
              [-39.406671313018435, -8.461192808879423],
              [-39.406556410127358, -8.464070194816131],
              [-39.40654366324334, -8.464389864119806],
              [-39.406490815617005, -8.465714130839098],
              [-39.406360469449289, -8.469806025956952],
              [-39.406191960761767, -8.47475951133765],
              [-39.406018270508071, -8.479278562131201],
              [-39.405990772275032, -8.479653422010985],
              [-39.405934422291935, -8.482662713421275],
              [-39.406511323939881, -8.485068214089631],
              [-39.407144234007148, -8.487506398250812],
              [-39.407170054233582, -8.487609395563311],
              [-39.407922127642358, -8.490605358078685],
            ],
          ],
          [
            [
              [-39.4108585288269, -8.498863204070965],
              [-39.417308850537033, -8.496798758054258],
              [-39.417297888488712, -8.496659836509927],
              [-39.417262703341898, -8.496217476594094],
              [-39.417349179729342, -8.492417160096496],
              [-39.417515415155322, -8.488606536512844],
              [-39.410738030359369, -8.490471065579733],
              [-39.41079365996108, -8.494425725284904],
              [-39.410853603666666, -8.498247958696078],
              [-39.410857378142033, -8.498721106103082],
              [-39.4108585288269, -8.498863204070965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0307000',
        uf: 'PE',
        nome_proje: 'PA BARRA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '226.5043',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 3.0,
        data_de_cr: '26/12/2012',
        forma_obte: 'Desapropriação',
        data_obten: '09/11/2009',
        area_calc_: 226.6729,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.038619446801313, -8.686812540134799],
              [-39.038922852308019, -8.687660634261952],
              [-39.038963297415236, -8.687773688120037],
              [-39.039266636486708, -8.688621591916871],
              [-39.039684969714784, -8.689790924540834],
              [-39.03968496998943, -8.689790925308506],
              [-39.039870324096114, -8.690309027965247],
              [-39.039685031691036, -8.690380233610041],
              [-39.039870383930605, -8.69087529342678],
              [-39.04071762129481, -8.693138173253589],
              [-39.040765527467762, -8.693266124606875],
              [-39.04095810241428, -8.693780467079121],
              [-39.040991035305389, -8.69386842635717],
              [-39.041054334717103, -8.694037490383293],
              [-39.040977385519241, -8.694058547015937],
              [-39.041231244861592, -8.694700822022698],
              [-39.0427948828033, -8.698656840216884],
              [-39.043577137968221, -8.698511977258073],
              [-39.043924977929485, -8.698453575294014],
              [-39.044253120502091, -8.698403053349082],
              [-39.045160479550596, -8.698331228106893],
              [-39.045141197849858, -8.698277233792117],
              [-39.044182269294112, -8.695550034446139],
              [-39.044223233523063, -8.695257496572134],
              [-39.044270464550962, -8.694917008999832],
              [-39.044315520838254, -8.694592163739363],
              [-39.04432887649714, -8.694495872464662],
              [-39.044501277689015, -8.694485314622606],
              [-39.044673669768649, -8.694474765749822],
              [-39.044830998886454, -8.694464824603399],
              [-39.045130421470944, -8.69444649970997],
              [-39.045175792108708, -8.694443726479161],
              [-39.045120199517484, -8.693955758727862],
              [-39.045044924183372, -8.693299714087265],
              [-39.044958592096783, -8.692542580761771],
              [-39.044757372797072, -8.692458700516083],
              [-39.044574999044052, -8.6923821174833],
              [-39.044382152628963, -8.69230172751181],
              [-39.043929368945442, -8.692113050370297],
              [-39.043753259258494, -8.692039731562382],
              [-39.043526840402123, -8.691945469567134],
              [-39.043178984967859, -8.69179983502848],
              [-39.042986111908768, -8.69171952576653],
              [-39.042818393505563, -8.691649697051966],
              [-39.042278852521292, -8.690144231769711],
              [-39.042078701190313, -8.689585752134844],
              [-39.041983475740196, -8.689320045163759],
              [-39.041940250931852, -8.689199435116786],
              [-39.041843156299137, -8.688928511990737],
              [-39.041366812455941, -8.68759936378499],
              [-39.040266894830843, -8.684530211156343],
              [-39.040067134228465, -8.683972803836644],
              [-39.040037588703832, -8.683890360533848],
              [-39.039926793088341, -8.683581198126456],
              [-39.039926318273437, -8.683579873208934],
              [-39.039815997655282, -8.683272035681018],
              [-39.03975012169461, -8.683088215810391],
              [-39.039684245798419, -8.682904395926313],
              [-39.039474053879651, -8.682317875794702],
              [-39.03926592051085, -8.681737097907206],
              [-39.039214377551737, -8.681593271508151],
              [-39.039189022199842, -8.681522519435276],
              [-39.039185037791448, -8.681511401261085],
              [-39.039063183084956, -8.681171375055859],
              [-39.039047104261144, -8.681126508286091],
              [-39.038926513200401, -8.680790007460216],
              [-39.036927730265212, -8.675212450770518],
              [-39.036571448434572, -8.674218236037323],
              [-39.034476325448345, -8.668371620649982],
              [-39.033516619396984, -8.665693415720424],
              [-39.032246223300739, -8.662215114632732],
              [-39.03164080811402, -8.662223713013967],
              [-39.031630515045691, -8.661312211109436],
              [-39.031588054745768, -8.65616565286579],
              [-39.02894429112289, -8.653825311401476],
              [-39.027245114243776, -8.649431214548484],
              [-39.025551147461023, -8.650279481456147],
              [-39.032144073130539, -8.668711273582904],
              [-39.034468258185328, -8.675208518360249],
              [-39.036118267827533, -8.679820963667771],
              [-39.038619446801313, -8.686812540134799],
            ],
          ],
          [
            [
              [-39.044309317787928, -8.702691208926138],
              [-39.044986422191215, -8.704497615336118],
              [-39.045174280631372, -8.704998788314706],
              [-39.045550010761829, -8.706001165270584],
              [-39.045550025777459, -8.706001156996509],
              [-39.045550026123003, -8.706001156806082],
              [-39.045784999925495, -8.705871678946139],
              [-39.045955749876391, -8.705777590275201],
              [-39.045985786453244, -8.705761039150786],
              [-39.046163450596751, -8.705663140388948],
              [-39.046197572790454, -8.705644337926053],
              [-39.046257357518016, -8.705611394542053],
              [-39.046339856605506, -8.705565934761267],
              [-39.046363445409533, -8.705552936529051],
              [-39.046367432394831, -8.70555073956449],
              [-39.046374091476345, -8.705547070184021],
              [-39.046488375599971, -8.705484095714219],
              [-39.046564874896859, -8.705441941948262],
              [-39.046758959230246, -8.705334994637182],
              [-39.047513194780642, -8.704919382727629],
              [-39.047412351051712, -8.704637001737996],
              [-39.047390793694134, -8.704576637112259],
              [-39.046367002106763, -8.70170980544745],
              [-39.046230755230624, -8.701328281996071],
              [-39.045198294520858, -8.69843712086981],
              [-39.044265804140842, -8.698510935513983],
              [-39.044017620364599, -8.698549146760641],
              [-39.043942394899318, -8.698560728704269],
              [-39.043758907874349, -8.698591536022775],
              [-39.043596194982939, -8.698618855303412],
              [-39.04353332228002, -8.698631088268819],
              [-39.043496647082037, -8.698638224054015],
              [-39.043394814381131, -8.698658037322646],
              [-39.043335157810596, -8.698669644500489],
              [-39.043324204950281, -8.698671775560515],
              [-39.043296568012217, -8.698677152782126],
              [-39.043259752793773, -8.698684315786062],
              [-39.04321602618198, -8.698692823509827],
              [-39.043205034680298, -8.698694962084458],
              [-39.043202458287034, -8.698695463363455],
              [-39.043198119858928, -8.698696307474757],
              [-39.043190099444267, -8.698697867975941],
              [-39.043188949374318, -8.698698091740589],
              [-39.043188470359937, -8.698698184940572],
              [-39.043182150750205, -8.698699414522522],
              [-39.042838164651336, -8.69876634239338],
              [-39.043864349025668, -8.701504093072046],
              [-39.044080131875802, -8.702079773245629],
              [-39.044309317787928, -8.702691208926138],
            ],
          ],
          [
            [
              [-39.025279762273499, -8.649596823977834],
              [-39.026943595405847, -8.648753399600951],
              [-39.016708529053993, -8.628163804405848],
              [-39.015057102345608, -8.62891365616742],
              [-39.025279762273499, -8.649596823977834],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0099000',
        uf: 'PE',
        nome_proje: 'PA SÃO LOURENÇO',
        municipio: 'SERRA TALHADA',
        area_hecta: '972.7594',
        capacidade: 24.0,
        num_famili: 24.0,
        fase: 4.0,
        data_de_cr: '27/08/2004',
        forma_obte: 'Desapropriação',
        data_obten: '06/11/2003',
        area_calc_: 978.349,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.506632021805522, -7.786606752984357],
              [-38.512339595106205, -7.789803022376181],
              [-38.516287910491457, -7.791743617298601],
              [-38.519692839373874, -7.794690402108075],
              [-38.524881930256207, -7.795470719214403],
              [-38.530538285657549, -7.794083041828931],
              [-38.535107149294753, -7.795327208336839],
              [-38.530372306346003, -7.785047872582042],
              [-38.529933827967419, -7.784610856361496],
              [-38.529670337249875, -7.784304377852322],
              [-38.529204957986039, -7.783836848163765],
              [-38.529010560578058, -7.783220822324783],
              [-38.528975656360679, -7.783046388481655],
              [-38.528962676233263, -7.782812279414586],
              [-38.528966872522446, -7.782790484687276],
              [-38.529002703759161, -7.782703146014558],
              [-38.529039373584723, -7.782677045550243],
              [-38.529274844362654, -7.782246203637269],
              [-38.529389316652498, -7.782150901769915],
              [-38.529611677639565, -7.782008231198917],
              [-38.529866207100795, -7.781727834855125],
              [-38.530046208665972, -7.781589368427993],
              [-38.530209428462101, -7.781290779920369],
              [-38.530341315708597, -7.781112098539687],
              [-38.53049315544996, -7.780852030664919],
              [-38.530533124022277, -7.780795360281274],
              [-38.530599688941884, -7.780718095821095],
              [-38.53076790199556, -7.780577445107991],
              [-38.530808492588982, -7.780450673594791],
              [-38.530876364874715, -7.780176220982586],
              [-38.530936659160602, -7.779865397498318],
              [-38.530955535506941, -7.779691385011879],
              [-38.531017130885736, -7.779597109637356],
              [-38.531040749255048, -7.779481354710542],
              [-38.53119990702556, -7.77924571714999],
              [-38.531339030897314, -7.779001373818775],
              [-38.531533192096326, -7.778605942651049],
              [-38.531641597918558, -7.778582001533501],
              [-38.531657088725318, -7.778352808347174],
              [-38.531586558484292, -7.778244366705259],
              [-38.531615434128867, -7.778131059783141],
              [-38.531651070248472, -7.778055208247511],
              [-38.531660922153876, -7.778085611640816],
              [-38.53169120301866, -7.778013643711986],
              [-38.531710901208982, -7.777997745549256],
              [-38.531807089003394, -7.777705142691321],
              [-38.53190534405686, -7.777429276059143],
              [-38.532023877476405, -7.777022997153089],
              [-38.532041760133019, -7.777008544232756],
              [-38.532037796280839, -7.776984479113175],
              [-38.532586916318891, -7.775946039410223],
              [-38.532909144831955, -7.775458575766865],
              [-38.53331049152127, -7.774939901705988],
              [-38.533481332626657, -7.774716034922185],
              [-38.533927078197635, -7.774232957362702],
              [-38.534065151917474, -7.774033205555546],
              [-38.534612018893732, -7.773714593008777],
              [-38.534668810329968, -7.773451524558627],
              [-38.535818306414889, -7.773401947199979],
              [-38.53375444770699, -7.767693848516611],
              [-38.533855325398477, -7.767667908404974],
              [-38.533964281130352, -7.767306935460638],
              [-38.533994047129376, -7.767042661462959],
              [-38.533845468771005, -7.766243246875145],
              [-38.533857463682331, -7.765645811236063],
              [-38.533811723749537, -7.765509899147021],
              [-38.533503731528079, -7.765021109633288],
              [-38.53307041415124, -7.76452413191822],
              [-38.53312282013556, -7.763221470694783],
              [-38.532206642120279, -7.760697430099808],
              [-38.532037615066969, -7.7603518907606],
              [-38.532203279887803, -7.758980337673417],
              [-38.529766911116752, -7.757582670637396],
              [-38.529290827857764, -7.757305445566923],
              [-38.529121035629537, -7.75724347766621],
              [-38.529110318809202, -7.757257395724549],
              [-38.528988003802212, -7.757235008694745],
              [-38.528483170596225, -7.757204238070878],
              [-38.52828407736304, -7.757231334558666],
              [-38.528133484994221, -7.757274495082378],
              [-38.527778038266611, -7.757224803431463],
              [-38.527547920207475, -7.757260006005186],
              [-38.527313116876833, -7.757267795694829],
              [-38.527106148124261, -7.757363085171773],
              [-38.526703655122802, -7.757375391769696],
              [-38.526120184012058, -7.75735393809622],
              [-38.525807893555317, -7.757315056879944],
              [-38.525543024964392, -7.757364873177123],
              [-38.525240011372475, -7.757464125064316],
              [-38.524509082610898, -7.757197645853122],
              [-38.523986387896556, -7.757163684073834],
              [-38.523641061369077, -7.75706696427805],
              [-38.523361674496861, -7.757043314376626],
              [-38.522940806699026, -7.756863834541581],
              [-38.522948023211015, -7.756817620677311],
              [-38.522490031086512, -7.756747728952077],
              [-38.522577448380005, -7.756110488720656],
              [-38.52266529595682, -7.755815345647652],
              [-38.522697721916252, -7.75561005160653],
              [-38.522463942723299, -7.754951737153857],
              [-38.522387765781083, -7.754789467820257],
              [-38.522171926313376, -7.754468204668064],
              [-38.521903769008823, -7.754138832278263],
              [-38.521628345013653, -7.753739802060465],
              [-38.521253191050711, -7.753076353238438],
              [-38.520575560631919, -7.752441599428258],
              [-38.520227523819642, -7.752099653533876],
              [-38.520070461290608, -7.751851271056984],
              [-38.519915110238237, -7.751533240954398],
              [-38.519634194493548, -7.751101820925625],
              [-38.51948033716198, -7.750666654572572],
              [-38.51937124337195, -7.7505035335627],
              [-38.518910979583794, -7.750204606585744],
              [-38.518608878730035, -7.749982834167925],
              [-38.513580722343953, -7.765359333059461],
              [-38.506632021805522, -7.786606752984357],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0098000',
        uf: 'PE',
        nome_proje: 'PA PARAISO',
        municipio: 'SERRA TALHADA',
        area_hecta: '916.2794',
        capacidade: 28.0,
        num_famili: 25.0,
        fase: 4.0,
        data_de_cr: '27/08/2004',
        forma_obte: 'Desapropriação',
        data_obten: '03/01/2004',
        area_calc_: 916.9352,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.552583269370707, -7.924558081983008],
              [-38.558924140887413, -7.919236033898453],
              [-38.555939683935868, -7.918365959718781],
              [-38.554823251648486, -7.918005668922139],
              [-38.5531651289213, -7.915013680762422],
              [-38.552861977172647, -7.915222484825479],
              [-38.552583269370707, -7.924558081983008],
            ],
          ],
          [
            [
              [-38.552583269370707, -7.924558081983008],
              [-38.506712662736412, -7.874125392756608],
              [-38.506308616849886, -7.875431785246865],
              [-38.506133628380013, -7.876197717777473],
              [-38.506028176138123, -7.876998556572038],
              [-38.506166722085602, -7.87759064544182],
              [-38.506052762028588, -7.878757447726143],
              [-38.505667004718092, -7.879782008382981],
              [-38.505751242830463, -7.880807123056865],
              [-38.506391237758763, -7.881576638865203],
              [-38.50692711496233, -7.882327578857079],
              [-38.50730917034268, -7.883128991179001],
              [-38.507447966718594, -7.883512133137833],
              [-38.507703106143708, -7.884087986613247],
              [-38.508522331723356, -7.884886202926564],
              [-38.509106897535091, -7.885050518217626],
              [-38.509621304270752, -7.885167986283641],
              [-38.510159198680533, -7.885215379966952],
              [-38.510696928413012, -7.885403065709904],
              [-38.510953843934317, -7.885707288562869],
              [-38.51117043509025, -7.886128329771323],
              [-38.511177902498254, -7.886258500666123],
              [-38.510981374400792, -7.8872152662253],
              [-38.510489124348574, -7.888079785526975],
              [-38.509645965977754, -7.889107432612603],
              [-38.50933029956051, -7.889620022756838],
              [-38.510368014694791, -7.891936929761018],
              [-38.526575012907024, -7.913840310732704],
              [-38.527868182058739, -7.912882970363331],
              [-38.54610732071815, -7.932201545231966],
              [-38.554981844831353, -7.941551808310335],
              [-38.557581549122062, -7.939744696130282],
              [-38.556721601225632, -7.937135371565723],
              [-38.556865910704872, -7.937087856209297],
              [-38.555469611961996, -7.932850969790975],
              [-38.552583269370707, -7.924558081983008],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0062000',
        uf: 'PE',
        nome_proje: 'PA POLDRINHO',
        municipio: 'SERRA TALHADA',
        area_hecta: '381.3078',
        capacidade: 40.0,
        num_famili: 38.0,
        fase: 4.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Desapropriação',
        data_obten: '26/11/2001',
        area_calc_: 1401.4643,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.471607481797435, -8.02618722032846],
              [-38.468916902178179, -8.025640593854135],
              [-38.467361091736215, -8.025325348754201],
              [-38.463720888643195, -8.024618445000039],
              [-38.463523890123945, -8.024539585632818],
              [-38.461880901464703, -8.02420937264368],
              [-38.459618410620195, -8.026061397655859],
              [-38.459868874250247, -8.026472287530762],
              [-38.459605838302551, -8.02664814264951],
              [-38.458462525389841, -8.027462878072118],
              [-38.457797299841793, -8.028114974437125],
              [-38.457166371934662, -8.028769195198146],
              [-38.455769769482615, -8.029858109482452],
              [-38.454741006688792, -8.030708984665729],
              [-38.454584963780199, -8.03083545378494],
              [-38.454104628369819, -8.031224801587056],
              [-38.452986306697568, -8.032131180872907],
              [-38.452696868713232, -8.032365699191443],
              [-38.452712523811819, -8.032398825368597],
              [-38.452540576541843, -8.032451058613706],
              [-38.45248510687766, -8.032467808822748],
              [-38.452401579882562, -8.032564300140326],
              [-38.452332444442121, -8.032959572792338],
              [-38.452622786948069, -8.033066511426496],
              [-38.452446425291626, -8.033162155586659],
              [-38.452707489971353, -8.034056980577439],
              [-38.452591522319906, -8.034117881195696],
              [-38.45232673774985, -8.034102875520432],
              [-38.452153398685063, -8.034245015745897],
              [-38.451507901616182, -8.034448938204166],
              [-38.451432106950406, -8.034868985488393],
              [-38.450676418719389, -8.034480843384445],
              [-38.448290918201998, -8.035950927619906],
              [-38.446760014978466, -8.037333776347909],
              [-38.446624144263168, -8.037883630069921],
              [-38.446019574490862, -8.038215949296442],
              [-38.445646497397753, -8.038487705842243],
              [-38.445067125303154, -8.038774650803724],
              [-38.441631498188798, -8.04158304259518],
              [-38.438998956990957, -8.043673771821547],
              [-38.438560333794058, -8.04402213458639],
              [-38.438151327178829, -8.044346839039138],
              [-38.438763121358747, -8.045041167124319],
              [-38.438815402936008, -8.045100574749991],
              [-38.440705524508985, -8.046316724938597],
              [-38.441539888151638, -8.046853530545979],
              [-38.442177581418839, -8.04726387143651],
              [-38.442249268655239, -8.04720219039535],
              [-38.444750584742735, -8.045050570709957],
              [-38.448083152586619, -8.042158709421409],
              [-38.450129883904452, -8.045364438227139],
              [-38.453131115754843, -8.047666642397187],
              [-38.453449535910835, -8.047754985411917],
              [-38.453527003818543, -8.047848163337292],
              [-38.453421405476995, -8.048023951382895],
              [-38.453193682694483, -8.048179134689713],
              [-38.452941892604564, -8.048482988404144],
              [-38.452225129805669, -8.048756553690039],
              [-38.454078549689513, -8.051134736700169],
              [-38.456781060266245, -8.054682673908994],
              [-38.459723678311619, -8.058012832955704],
              [-38.462684605814943, -8.061327801566184],
              [-38.465984610600159, -8.05920161072784],
              [-38.470317003023823, -8.056095683852677],
              [-38.473447114806682, -8.053769037591602],
              [-38.473882422527446, -8.053445506498807],
              [-38.474008765085387, -8.053364352129064],
              [-38.476294365884783, -8.051897158721131],
              [-38.476752955414419, -8.051602869999197],
              [-38.477417178183877, -8.051176420784815],
              [-38.478396109634744, -8.050553184930093],
              [-38.481190645712715, -8.048565875548748],
              [-38.481558698526669, -8.048310632314646],
              [-38.482609251580307, -8.049022278023608],
              [-38.4826800636081, -8.049074015420317],
              [-38.483283373403047, -8.048654082269582],
              [-38.487014637738717, -8.046072009192066],
              [-38.492947224141929, -8.041945163818999],
              [-38.495833320164905, -8.039861527163406],
              [-38.500422085856314, -8.036332159301546],
              [-38.499859573908218, -8.03533061947131],
              [-38.497706798820161, -8.031427959502182],
              [-38.494808278638523, -8.030845696448351],
              [-38.494663298552233, -8.030816483208303],
              [-38.494183812057145, -8.030719833647533],
              [-38.49030020822795, -8.029936699131408],
              [-38.487870667448803, -8.029451752966068],
              [-38.486388123691015, -8.029155845060234],
              [-38.485055823242114, -8.028889969192592],
              [-38.48067822305525, -8.028039735721807],
              [-38.480429274166497, -8.027990215559116],
              [-38.479894633156135, -8.027883801740126],
              [-38.476070230631301, -8.027122862210307],
              [-38.471607481797435, -8.02618722032846],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0264000',
        uf: 'PE',
        nome_proje: 'PA JOÃO PEDRO TEIXEIRA',
        municipio: 'SERRA TALHADA',
        area_hecta: '542.9830',
        capacidade: 14.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '16/09/2008',
        forma_obte: 'Desapropriação',
        data_obten: '20/06/2007',
        area_calc_: 543.39,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.282218934767322, -8.093841105225565],
              [-38.282251340473501, -8.09388973310438],
              [-38.284268088674928, -8.094821638191283],
              [-38.284887516007068, -8.095751088818233],
              [-38.286595334601301, -8.095754076771508],
              [-38.290010793502034, -8.095914788789292],
              [-38.290941266452258, -8.096535256583911],
              [-38.291168821707778, -8.096523712542782],
              [-38.311272128960866, -8.061753709039641],
              [-38.302206621616357, -8.053844878545869],
              [-38.282218934767322, -8.093841105225565],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0132000',
        uf: 'PE',
        nome_proje: 'PA LAJINHA',
        municipio: 'SERRA TALHADA',
        area_hecta: '736.0269',
        capacidade: 22.0,
        num_famili: 22.0,
        fase: 4.0,
        data_de_cr: '23/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '19/05/2004',
        area_calc_: 738.8234,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.36757759181215, -7.902284330620505],
              [-38.367461058816147, -7.901185265091773],
              [-38.346762449198813, -7.896137582523434],
              [-38.332333348566195, -7.892808294711656],
              [-38.332359882322798, -7.892723856345079],
              [-38.332386178319631, -7.892674512363002],
              [-38.332392891112541, -7.892618082004001],
              [-38.332393068624093, -7.892563992929997],
              [-38.332394795698008, -7.892505293355923],
              [-38.332382190475116, -7.892446118665235],
              [-38.332357796909442, -7.892384030753955],
              [-38.332351528701651, -7.892333006711128],
              [-38.332346284634873, -7.892265612774168],
              [-38.332355619456067, -7.892214613623486],
              [-38.332329071675488, -7.892081699587209],
              [-38.310618598649683, -7.890736150491512],
              [-38.301414875714947, -7.908704488943421],
              [-38.302118698177324, -7.908148132980327],
              [-38.302803575728163, -7.907743699743189],
              [-38.334244247791716, -7.91100905200168],
              [-38.334454968686352, -7.910617293352877],
              [-38.334455002475806, -7.910617311020327],
              [-38.334455008134121, -7.910617300516847],
              [-38.334455013806298, -7.91061729002082],
              [-38.334455019492374, -7.910617279532245],
              [-38.334455025192291, -7.91061726905114],
              [-38.334455030906071, -7.910617258577567],
              [-38.334455036633692, -7.910617248111479],
              [-38.334455042375133, -7.910617237652963],
              [-38.334455048130401, -7.910617227201968],
              [-38.334455053899497, -7.910617216758593],
              [-38.334455059682384, -7.910617206322768],
              [-38.334455065479055, -7.910617195894548],
              [-38.334455071289511, -7.910617185473982],
              [-38.334455077113745, -7.910617175061035],
              [-38.334455082951735, -7.910617164655779],
              [-38.334455088803487, -7.910617154258177],
              [-38.334455094668968, -7.910617143868293],
              [-38.334455100548183, -7.910617133486114],
              [-38.334455106441126, -7.910617123111687],
              [-38.334455112347769, -7.910617112745003],
              [-38.334455118268117, -7.910617102386107],
              [-38.334455124202158, -7.910617092034982],
              [-38.334455130149877, -7.910617081691661],
              [-38.334455136111259, -7.910617071356178],
              [-38.334455142086306, -7.910617061028523],
              [-38.334455148074987, -7.910617050708753],
              [-38.33445515407734, -7.910617040396842],
              [-38.334455160093292, -7.910617030092851],
              [-38.334455166122879, -7.910617019796748],
              [-38.334455172166052, -7.910617009508591],
              [-38.334455178222839, -7.910616999228389],
              [-38.334455184293212, -7.91061698895614],
              [-38.334455190377156, -7.910616978691886],
              [-38.334455196474657, -7.910616968435636],
              [-38.334455202585723, -7.910616958187413],
              [-38.334455208710324, -7.910616947947227],
              [-38.334455214848461, -7.910616937715121],
              [-38.334455221000127, -7.910616927491071],
              [-38.334455227165293, -7.910616917275115],
              [-38.334455233343967, -7.91061690706728],
              [-38.334455239536126, -7.910616896867572],
              [-38.334455245741772, -7.910616886675987],
              [-38.334455251960883, -7.910616876492597],
              [-38.334455258193451, -7.910616866317365],
              [-38.33445526443947, -7.910616856150342],
              [-38.334455270698911, -7.910616845991546],
              [-38.334455276971795, -7.910616835840978],
              [-38.334455283258087, -7.910616825698664],
              [-38.334455289557781, -7.910616815564632],
              [-38.33445529587086, -7.910616805438859],
              [-38.334455302197334, -7.910616795321414],
              [-38.33445530853718, -7.910616785212278],
              [-38.33445531489037, -7.910616775111481],
              [-38.334455321256918, -7.91061676501905],
              [-38.334455327636803, -7.910616754935017],
              [-38.334455334030004, -7.910616744859356],
              [-38.334455340436534, -7.910616734792111],
              [-38.334455346856366, -7.91061672473329],
              [-38.334455353289485, -7.910616714682914],
              [-38.334455359735891, -7.910616704641003],
              [-38.334455366195563, -7.910616694607586],
              [-38.334455372668494, -7.910616684582646],
              [-38.334455379154676, -7.910616674566239],
              [-38.334455385654088, -7.910616664558359],
              [-38.334455392166738, -7.910616654559008],
              [-38.334455398692604, -7.910616644568251],
              [-38.334455405231651, -7.910616634586079],
              [-38.334455411783907, -7.910616624612499],
              [-38.334455418349336, -7.910616614647549],
              [-38.334455424927931, -7.91061660469125],
              [-38.334455431519707, -7.910616594743563],
              [-38.334455438124593, -7.910616584804573],
              [-38.334455444742638, -7.910616574874246],
              [-38.334455451373799, -7.910616564952649],
              [-38.334455458018077, -7.910616555039752],
              [-38.33445546467545, -7.910616545135632],
              [-38.334455471345905, -7.91061653524025],
              [-38.334455478029447, -7.910616525353662],
              [-38.334455484726043, -7.910616515475858],
              [-38.334455491435705, -7.910616505606848],
              [-38.334455498158398, -7.910616495746658],
              [-38.334455504894123, -7.910616485895332],
              [-38.334455511642872, -7.910616476052873],
              [-38.334455518404617, -7.910616466219278],
              [-38.334455525179365, -7.910616456394582],
              [-38.334455531967095, -7.910616446578784],
              [-38.334455538767799, -7.910616436771907],
              [-38.334455545581456, -7.910616426974014],
              [-38.334455552408059, -7.910616417185068],
              [-38.334455559247608, -7.910616407405103],
              [-38.334455566100075, -7.910616397634125],
              [-38.334455572965439, -7.910616387872179],
              [-38.334455579843727, -7.910616378119237],
              [-38.334455586734904, -7.910616368375377],
              [-38.334455593638936, -7.910616358640564],
              [-38.334455600555849, -7.910616348914826],
              [-38.334455607485602, -7.910616339198201],
              [-38.334455614428208, -7.910616329490696],
              [-38.334455621383626, -7.91061631979229],
              [-38.33445562835184, -7.91061631010297],
              [-38.334455605316997, -7.9106162994133],
              [-38.334698003085073, -7.91027975798671],
              [-38.33469799646965, -7.910279769278415],
              [-38.334698024233475, -7.910279730511775],
              [-38.334698051792152, -7.910279691599905],
              [-38.3346980791449, -7.910279652543864],
              [-38.334698106290986, -7.910279613344727],
              [-38.334698133229629, -7.910279574003603],
              [-38.334698159960105, -7.910279534521587],
              [-38.334698186481646, -7.910279494899756],
              [-38.334698212793541, -7.910279455139203],
              [-38.334698238895058, -7.910279415241046],
              [-38.334698264785473, -7.910279375206385],
              [-38.33469829046404, -7.910279335036339],
              [-38.334698315930076, -7.910279294732014],
              [-38.334698341182865, -7.910279254294523],
              [-38.33469836622173, -7.91027921372501],
              [-38.334698391045933, -7.910279173024567],
              [-38.334698415654813, -7.91027913219436],
              [-38.334698440047696, -7.910279091235497],
              [-38.33469846422387, -7.910279050149123],
              [-38.334698488182688, -7.910279008936364],
              [-38.334698511923499, -7.910278967598386],
              [-38.334698535445618, -7.910278926136311],
              [-38.334698558748414, -7.910278884551318],
              [-38.334698581831226, -7.910278842844526],
              [-38.334698604693401, -7.910278801017119],
              [-38.334698627334348, -7.910278759070232],
              [-38.334698649753399, -7.910278717005055],
              [-38.334698671949958, -7.910278674822741],
              [-38.334698693923379, -7.910278632524477],
              [-38.334698715673085, -7.91027859011141],
              [-38.334698737198458, -7.910278547584719],
              [-38.334698758498902, -7.910278504945583],
              [-38.334698779573813, -7.910278462195216],
              [-38.334698800422636, -7.910278419334792],
              [-38.334698821044775, -7.910278376365454],
              [-38.334698841439646, -7.910278333288455],
              [-38.334698861606711, -7.910278290104969],
              [-38.334698881545393, -7.910278246816158],
              [-38.334698901255152, -7.910278203423269],
              [-38.33469892073542, -7.910278159927513],
              [-38.334698939985671, -7.910278116330051],
              [-38.334698959005372, -7.910278072632112],
              [-38.334698977793984, -7.910278028834929],
              [-38.334698996351008, -7.910277984939677],
              [-38.334699014675905, -7.910277940947601],
              [-38.334699032768171, -7.910277896859901],
              [-38.334699050627314, -7.910277852677817],
              [-38.334699068252839, -7.910277808402565],
              [-38.334699085644253, -7.910277764035388],
              [-38.334699102801082, -7.910277719577503],
              [-38.334699119722821, -7.910277675030152],
              [-38.33469913640905, -7.91027763039458],
              [-38.334699152859265, -7.910277585672007],
              [-38.334699169073012, -7.910277540863653],
              [-38.334699185049864, -7.910277495970799],
              [-38.334699200789359, -7.910277450994695],
              [-38.334699216291064, -7.910277405936537],
              [-38.334699231554559, -7.910277360797634],
              [-38.334699246579419, -7.910277315579203],
              [-38.334699261365209, -7.910277270282504],
              [-38.334699275911539, -7.910277224908796],
              [-38.334699290218005, -7.910277179459335],
              [-38.334699304284179, -7.910277133935391],
              [-38.334699318109728, -7.910277088338202],
              [-38.334699331694225, -7.910277042669073],
              [-38.334699345037301, -7.910276996929242],
              [-38.334699358138586, -7.91027695111998],
              [-38.334699370997726, -7.910276905242555],
              [-38.334699383614371, -7.910276859298263],
              [-38.334699395988139, -7.910276813288351],
              [-38.334699408118709, -7.910276767214114],
              [-38.334699420005741, -7.910276721076801],
              [-38.334699431648914, -7.91027667487772],
              [-38.334699443047889, -7.910276628618154],
              [-38.334699454202358, -7.910276582299354],
              [-38.334699465112017, -7.910276535922626],
              [-38.334699475776546, -7.910276489489253],
              [-38.334699486195689, -7.910276443000548],
              [-38.334699496369105, -7.910276396457779],
              [-38.334699506296538, -7.910276349862217],
              [-38.334699515977718, -7.910276303215204],
              [-38.334699525412368, -7.91027625651799],
              [-38.334699534600226, -7.910276209771866],
              [-38.334699543541028, -7.910276162978154],
              [-38.334699552234554, -7.910276116138143],
              [-38.334699560680548, -7.910276069253142],
              [-38.334699568878754, -7.910276022324445],
              [-38.334699576828989, -7.91027597535335],
              [-38.334699584530995, -7.910275928341139],
              [-38.334699591984567, -7.910275881289161],
              [-38.334699599189491, -7.910275834198699],
              [-38.334699606145598, -7.910275787071052],
              [-38.334699612852681, -7.910275739907537],
              [-38.334699619310527, -7.910275692709453],
              [-38.334699625518986, -7.910275645478116],
              [-38.334699631477889, -7.910275598214849],
              [-38.334699637187043, -7.910275550920924],
              [-38.334699642646314, -7.91027550359769],
              [-38.334699647855544, -7.910275456246425],
              [-38.334699652814585, -7.910275408868465],
              [-38.334699642990373, -7.910275418980577],
              [-38.334798170135095, -7.909309384783519],
              [-38.334798185629374, -7.909309405125044],
              [-38.334798187970286, -7.909309381876634],
              [-38.334798190250972, -7.909309358622246],
              [-38.334798192471382, -7.909309335362069],
              [-38.334798194631539, -7.909309312096253],
              [-38.334798196731391, -7.909309288824948],
              [-38.334798198770955, -7.909309265548341],
              [-38.3347982007502, -7.909309242266537],
              [-38.33479820266912, -7.909309218979697],
              [-38.334798204527694, -7.909309195688014],
              [-38.334798206325907, -7.909309172391597],
              [-38.334798208063781, -7.909309149090657],
              [-38.334798209741265, -7.909309125785303],
              [-38.334798211358361, -7.909309102475714],
              [-38.334798212915061, -7.909309079162032],
              [-38.33479821441135, -7.90930905584441],
              [-38.334798215847222, -7.909309032523008],
              [-38.334798217222669, -7.909309009198019],
              [-38.334798218537678, -7.909308985869531],
              [-38.334798219792233, -7.909308962537764],
              [-38.334798220986343, -7.909308939202814],
              [-38.334798222119979, -7.909308915864876],
              [-38.334798223193161, -7.90930889252409],
              [-38.334798224205848, -7.90930886918063],
              [-38.334798225158075, -7.909308845834632],
              [-38.334798226049784, -7.909308822486262],
              [-38.33479822688102, -7.909308799135678],
              [-38.334798227651739, -7.909308775783037],
              [-38.334798228361961, -7.909308752428485],
              [-38.334798229011675, -7.909308729072181],
              [-38.334798229600871, -7.909308705714283],
              [-38.334798230129536, -7.909308682354943],
              [-38.334798230597684, -7.909308658994328],
              [-38.334798231005301, -7.909308635632586],
              [-38.334798231352394, -7.909308612269853],
              [-38.334798231638956, -7.90930858890632],
              [-38.33479823186498, -7.909308565542132],
              [-38.334798232030472, -7.909308542177411],
              [-38.334798232135419, -7.909308518812357],
              [-38.334798232179843, -7.909308495447103],
              [-38.334798232163735, -7.909308472081825],
              [-38.334798232087088, -7.909308448716665],
              [-38.33479823194989, -7.909308425351781],
              [-38.33479823175216, -7.909308401987321],
              [-38.334798231493906, -7.909308378623444],
              [-38.3347982311751, -7.909308355260309],
              [-38.334798230795776, -7.909308331898106],
              [-38.334798230355922, -7.909308308536917],
              [-38.334798229855551, -7.909308285176945],
              [-38.334798229294655, -7.909308261818365],
              [-38.334798228673243, -7.909308238461286],
              [-38.334798227991314, -7.909308215105894],
              [-38.334798227248875, -7.909308191752345],
              [-38.334798226445926, -7.909308168400774],
              [-38.334798225582489, -7.909308145051344],
              [-38.334798224658556, -7.90930812170421],
              [-38.334798223674127, -7.909308098359541],
              [-38.334798222629232, -7.90930807501749],
              [-38.334798221523855, -7.909308051678222],
              [-38.33479822035801, -7.909308028341872],
              [-38.334798219131706, -7.909308005008597],
              [-38.334798217844963, -7.909307981678542],
              [-38.334798216497781, -7.909307958351912],
              [-38.334798215090146, -7.909307935028807],
              [-38.334798213622108, -7.909307911709432],
              [-38.334798212093638, -7.909307888393887],
              [-38.334798210504786, -7.909307865082354],
              [-38.334798208855531, -7.909307841775006],
              [-38.334798207145894, -7.909307818471993],
              [-38.334798205375876, -7.909307795173431],
              [-38.334798203545525, -7.909307771879526],
              [-38.33479820165482, -7.909307748590408],
              [-38.334798199703769, -7.909307725306233],
              [-38.334798197692415, -7.909307702027149],
              [-38.334798195620735, -7.90930767875335],
              [-38.334798193488773, -7.909307655484941],
              [-38.334798191296528, -7.909307632222097],
              [-38.334798189044022, -7.909307608965003],
              [-38.33479818673127, -7.909307585713779],
              [-38.334798184358277, -7.909307562468573],
              [-38.334798181925066, -7.909307539229591],
              [-38.334798179431658, -7.909307515996931],
              [-38.334798176878067, -7.909307492770798],
              [-38.334798174264307, -7.909307469551289],
              [-38.334798171590386, -7.909307446338577],
              [-38.334798168856345, -7.909307423132859],
              [-38.334798166062193, -7.90930739993424],
              [-38.334798163207928, -7.909307376742905],
              [-38.334798160293595, -7.909307353559007],
              [-38.334798157319199, -7.90930733038266],
              [-38.334798154284776, -7.909307307214069],
              [-38.33479815119032, -7.909307284053386],
              [-38.33479814803588, -7.909307260900742],
              [-38.334798144821463, -7.909307237756311],
              [-38.334798141547097, -7.909307214620206],
              [-38.334798138212804, -7.909307191492617],
              [-38.334798134818584, -7.909307168373712],
              [-38.334798131364472, -7.909307145263621],
              [-38.334798127850519, -7.90930712216248],
              [-38.334798124276702, -7.909307099070498],
              [-38.334798083039317, -7.909307123384745],
              [-38.334705771515274, -7.908715611613673],
              [-38.333461842613168, -7.907917031724133],
              [-38.332790906018992, -7.906210247341257],
              [-38.332524935080798, -7.904055113562521],
              [-38.332548184840405, -7.90285315551198],
              [-38.334055974738497, -7.902363329515306],
              [-38.334168020903242, -7.90275353022661],
              [-38.349487642895276, -7.898124961323286],
              [-38.360238814739645, -7.900597017096139],
              [-38.36757759181215, -7.902284330620505],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0166000',
        uf: 'PE',
        nome_proje: 'PA BELA VISTA',
        municipio: 'SERRA TALHADA',
        area_hecta: '780.6010',
        capacidade: 18.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '15/02/2006',
        forma_obte: 'Desapropriação',
        data_obten: '15/02/2006',
        area_calc_: 780.9769,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.569377343652981, -8.071460770259403],
              [-38.586951303288217, -8.068330285548655],
              [-38.588599947910147, -8.068036524046626],
              [-38.58781348321974, -8.063243424021215],
              [-38.586443117057492, -8.054420668791801],
              [-38.562382323615303, -8.061408450317355],
              [-38.5624878963783, -8.061471246992495],
              [-38.562673013543062, -8.061579535958941],
              [-38.562913667763596, -8.061693311250593],
              [-38.563244302704824, -8.06185340381831],
              [-38.563593426503374, -8.062037485887133],
              [-38.563992265035417, -8.062155589789587],
              [-38.564383839828047, -8.062276941908095],
              [-38.564804844849789, -8.062459923481132],
              [-38.565159319342598, -8.062648894424033],
              [-38.565478978569494, -8.062975226456706],
              [-38.565742240270829, -8.063337317946315],
              [-38.56602850405271, -8.063576598302276],
              [-38.566284938057187, -8.063703594578865],
              [-38.566581311474977, -8.063909960334936],
              [-38.566817946754853, -8.0640502319667],
              [-38.567012397327083, -8.064174448697415],
              [-38.567286488665502, -8.064254608311403],
              [-38.567538614759528, -8.064336824990677],
              [-38.567706811419868, -8.064485074236902],
              [-38.567789529521157, -8.064619755756333],
              [-38.567817864977059, -8.064772560990745],
              [-38.567745610620406, -8.064954566944866],
              [-38.567639411731314, -8.065137803410551],
              [-38.567662590012041, -8.065360975746975],
              [-38.567697311531084, -8.065653537812427],
              [-38.567773500092358, -8.065954646213822],
              [-38.567818903026904, -8.066273631849779],
              [-38.567910441187344, -8.066478514287612],
              [-38.567947365213371, -8.06666289670896],
              [-38.568017079110064, -8.06690791730048],
              [-38.568119901795519, -8.067169706615912],
              [-38.568272321656913, -8.067476141575078],
              [-38.568456532902118, -8.067843665841357],
              [-38.568626385030292, -8.068315376921493],
              [-38.568769347898098, -8.068810939258139],
              [-38.568942374105298, -8.069285638519972],
              [-38.56909706342195, -8.069678548795579],
              [-38.569252750452748, -8.07015802371615],
              [-38.569294899390236, -8.070551539163416],
              [-38.56930458961557, -8.070828426382301],
              [-38.569324615130661, -8.07103015791966],
              [-38.569377343652981, -8.071460770259403],
            ],
          ],
          [
            [
              [-38.565987424889791, -8.074944609552963],
              [-38.568688472547741, -8.074468606145684],
              [-38.568775744901409, -8.073991015241756],
              [-38.56887591225226, -8.073763178917426],
              [-38.568949863539032, -8.073608310617004],
              [-38.569005073701859, -8.073410005073521],
              [-38.5690578594889, -8.073187003265438],
              [-38.56912678216456, -8.072895454966927],
              [-38.569262149263402, -8.071481362859659],
              [-38.569323695169757, -8.071470392318583],
              [-38.569270516839644, -8.071036070923661],
              [-38.569250312325401, -8.070831987415479],
              [-38.569240621753991, -8.070555462009452],
              [-38.569199191078951, -8.070169364471573],
              [-38.569045675018856, -8.069696766256284],
              [-38.568891347820134, -8.069304760876246],
              [-38.568717598097052, -8.068827709052728],
              [-38.568574453772946, -8.068332146510302],
              [-38.568406412055303, -8.067864959975761],
              [-38.568223649897121, -8.06750033171671],
              [-38.568070143279954, -8.067191724720258],
              [-38.567965328953065, -8.066925229730966],
              [-38.567894349284913, -8.066675685140753],
              [-38.567858147306652, -8.066495102509014],
              [-38.567766066163415, -8.066288772237934],
              [-38.56771994220788, -8.065965082274877],
              [-38.5676435727475, -8.06566343095599],
              [-38.567608492466725, -8.06536688857161],
              [-38.567583518102381, -8.065125804617969],
              [-38.567696445516219, -8.064930816358631],
              [-38.567761419933959, -8.064767074268879],
              [-38.567737777154697, -8.064639419953778],
              [-38.567664662770895, -8.064520306496176],
              [-38.567510973559358, -8.064384916888901],
              [-38.567270278762372, -8.064306511293966],
              [-38.566989655021054, -8.064224354796686],
              [-38.566789400043469, -8.064096332887587],
              [-38.566551676245517, -8.063955517368766],
              [-38.566257116596269, -8.063750419854058],
              [-38.565998505570356, -8.0636223358165],
              [-38.565702086108843, -8.063374542084919],
              [-38.565437193122364, -8.063010277966439],
              [-38.565126237053605, -8.062692819541089],
              [-38.564781014470356, -8.062508742860336],
              [-38.564364726418226, -8.062327937148833],
              [-38.563976417842376, -8.062207493015412],
              [-38.56357267997813, -8.062087936629732],
              [-38.563219746520822, -8.061901860514544],
              [-38.562890018531334, -8.0617423116115],
              [-38.562647550138209, -8.061627629838673],
              [-38.562460256367523, -8.06151789128762],
              [-38.562310422320202, -8.061429268221188],
              [-38.562244331160016, -8.061448464160645],
              [-38.560771622573974, -8.062549240462292],
              [-38.561088300309493, -8.064711498373823],
              [-38.557504032111176, -8.063213631166594],
              [-38.557424971844775, -8.061971625756469],
              [-38.553236128818199, -8.06005807166542],
              [-38.553179460296263, -8.059098936872667],
              [-38.55079192050907, -8.058305769485967],
              [-38.549987549096372, -8.059543912192783],
              [-38.535006304142009, -8.054572103351012],
              [-38.533158367606546, -8.059717490306353],
              [-38.531024913990741, -8.065131816045309],
              [-38.536583849742733, -8.066967366633673],
              [-38.536967728593126, -8.06443557551521],
              [-38.543001694976851, -8.066445065162789],
              [-38.542617850743504, -8.068976863694074],
              [-38.554196162561176, -8.072799779384804],
              [-38.554579940263622, -8.070267966695274],
              [-38.562197447377919, -8.072802082453537],
              [-38.566558713507028, -8.072003228373276],
              [-38.565987424889791, -8.074944609552963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0110000',
        uf: 'PE',
        nome_proje: 'PA POÇO DO SERROTE',
        municipio: 'SERRA TALHADA',
        area_hecta: '1324.2334',
        capacidade: 57.0,
        num_famili: 56.0,
        fase: 3.0,
        data_de_cr: '23/11/2004',
        forma_obte: 'Desapropriação',
        data_obten: '03/01/2004',
        area_calc_: 1323.3407,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.418968908629488, -7.999805626163819],
              [-38.420697232390737, -7.998528618305937],
              [-38.417284333644091, -7.996784799507016],
              [-38.41512941208137, -7.997125108075822],
              [-38.4183767145974, -7.994888939283159],
              [-38.417621582980082, -7.993863513577868],
              [-38.413477264988636, -7.996648800944585],
              [-38.412827286852291, -7.99640130743972],
              [-38.411483070509298, -7.995450379872646],
              [-38.410165306550141, -7.993586470678274],
              [-38.410267573861752, -7.993520225347599],
              [-38.408196476256677, -7.990104357600548],
              [-38.402511097723398, -7.993967944740406],
              [-38.401539213052232, -7.993143975746525],
              [-38.403208796830107, -7.992089829220552],
              [-38.399921749779693, -7.991745336498947],
              [-38.39920144695084, -7.991511288241175],
              [-38.396805421544343, -7.992058465706063],
              [-38.395139921757554, -7.992732606236324],
              [-38.390218024418679, -7.994724794022418],
              [-38.39042392110175, -7.994718133044349],
              [-38.391142766186043, -7.994833341860664],
              [-38.391839710474706, -7.994970949075125],
              [-38.396837421359564, -7.995971878659113],
              [-38.401011839007246, -7.996814171767496],
              [-38.408619512562296, -7.998349053687044],
              [-38.410157743078919, -7.998661862635821],
              [-38.411421935600742, -7.998914939576699],
              [-38.412432586322652, -7.999115190408491],
              [-38.413072345237524, -7.99921442055361],
              [-38.413717685295843, -7.999281728331172],
              [-38.416472356690683, -7.999555174253416],
              [-38.417221315590602, -7.999628321449214],
              [-38.418007556059983, -7.999710745882662],
              [-38.41892390638565, -7.999802035247834],
              [-38.418968908629488, -7.999805626163819],
            ],
          ],
          [
            [
              [-38.388180177105504, -8.001990056912636],
              [-38.396169894319044, -7.996559921077365],
              [-38.39168976023069, -7.995616729407009],
              [-38.391004970467243, -7.995481491505054],
              [-38.3903440020677, -7.995375593760907],
              [-38.389734335853063, -7.995368814556007],
              [-38.389178976242803, -7.995454103351147],
              [-38.388654006571805, -7.995605375083684],
              [-38.388205370966062, -7.995741382483062],
              [-38.387406011699426, -7.996070796355951],
              [-38.386602386509438, -7.996400202248866],
              [-38.381996304848457, -7.998312162568481],
              [-38.381118761217863, -7.998673556548858],
              [-38.380089785759218, -7.999085554648285],
              [-38.379189978395615, -7.999406388904417],
              [-38.37821750428504, -7.999835652608799],
              [-38.37752582749512, -8.000112565873055],
              [-38.376854840224659, -8.000328635780045],
              [-38.376199946422624, -8.000463232750809],
              [-38.375569483273317, -8.000523786458249],
              [-38.374803082170871, -8.000530857957585],
              [-38.374592379626058, -8.000533614252598],
              [-38.374360184135647, -8.000944813192794],
              [-38.379203803762181, -7.999924511720114],
              [-38.38081407183428, -7.999926928967707],
              [-38.382297780066366, -8.000216151136994],
              [-38.383967552051068, -8.000838776189708],
              [-38.384932402916597, -8.001437642302447],
              [-38.386727989232838, -8.001670419985674],
              [-38.388180177105504, -8.001990056912636],
            ],
          ],
          [
            [
              [-38.401967564785139, -8.009224727615393],
              [-38.402193809644928, -8.009208231615181],
              [-38.402439304106359, -8.009183351419082],
              [-38.402811677234354, -8.009144182579462],
              [-38.403062623028404, -8.009114606439574],
              [-38.403368472698126, -8.009081762858031],
              [-38.403562698776128, -8.009056084226563],
              [-38.403817547725204, -8.009025608750953],
              [-38.404027771085076, -8.008981591432965],
              [-38.404301943327205, -8.008954399806681],
              [-38.404458851153329, -8.008944586260212],
              [-38.404560797460512, -8.008915246336308],
              [-38.404758255469325, -8.008913450898433],
              [-38.404949966775916, -8.008869768194065],
              [-38.405183275548303, -8.008862778090826],
              [-38.405360177194247, -8.008831917903155],
              [-38.405599964062027, -8.008800515085204],
              [-38.405799462691355, -8.008768149590603],
              [-38.406008059759571, -8.008719063630348],
              [-38.406198640050356, -8.00870432307593],
              [-38.406311482205275, -8.008670204475326],
              [-38.407265543891974, -8.008609798091573],
              [-38.418106701227956, -8.00043883841477],
              [-38.413627642763203, -7.999936468133756],
              [-38.412964881896116, -7.999867507261438],
              [-38.412296091598321, -7.999763713008241],
              [-38.411271832252687, -7.999560728803677],
              [-38.410006186574719, -7.999307287395142],
              [-38.408467409391768, -7.998994476944103],
              [-38.400380467850219, -7.997376585599633],
              [-38.392347547269843, -8.0032650571132],
              [-38.395563664345261, -8.003213246391795],
              [-38.395759221496633, -8.003206206120879],
              [-38.396097046331839, -8.003208780794241],
              [-38.396411216568737, -8.0032534707933],
              [-38.396740978348205, -8.003309037466709],
              [-38.397050967977457, -8.00342002149972],
              [-38.397335239186603, -8.00355746992642],
              [-38.397578879404151, -8.003742526587544],
              [-38.39771422969271, -8.003828471682626],
              [-38.397874166990086, -8.00391707568104],
              [-38.39806195191322, -8.004136150661772],
              [-38.398254122463776, -8.004334880473014],
              [-38.39839635454468, -8.004554702976581],
              [-38.398516301456432, -8.0047492571191],
              [-38.398579929228944, -8.004922563626497],
              [-38.398581998104817, -8.004934958437241],
              [-38.398618426860807, -8.004968568860674],
              [-38.398706448584264, -8.005152493668856],
              [-38.398768383964658, -8.005304270334422],
              [-38.398836011826077, -8.005472607829825],
              [-38.39892283700177, -8.005668199047509],
              [-38.39899510491982, -8.005890180765087],
              [-38.399064882517095, -8.006077787440629],
              [-38.399119575264073, -8.006218337587541],
              [-38.399180463915251, -8.006341530143844],
              [-38.399255528922552, -8.006512591946628],
              [-38.399316008441971, -8.00666735131575],
              [-38.399341780888051, -8.00672808149826],
              [-38.399407144630771, -8.006831743110538],
              [-38.399528573112846, -8.007130679715628],
              [-38.399628673588218, -8.00737024935837],
              [-38.399705623068051, -8.007555966913925],
              [-38.39978484883887, -8.007736441607411],
              [-38.399867351312878, -8.007910137224462],
              [-38.399968541127762, -8.008087658950423],
              [-38.400050394713958, -8.008208801519526],
              [-38.400143704733139, -8.008314312695159],
              [-38.400164917660035, -8.008390503343159],
              [-38.400223455467533, -8.008446306307775],
              [-38.400362173358822, -8.008588787488563],
              [-38.40050772107643, -8.008714816456065],
              [-38.400621026989576, -8.008798196077834],
              [-38.40077941101957, -8.008895841967355],
              [-38.400951797308018, -8.00897442295352],
              [-38.401121437351122, -8.009069461983986],
              [-38.401244082697993, -8.009095689916832],
              [-38.401414862228989, -8.009156178170436],
              [-38.401516553576549, -8.009177310360293],
              [-38.401752337346721, -8.009215460903755],
              [-38.401967564785139, -8.009224727615393],
            ],
          ],
          [
            [
              [-38.365585768073863, -8.04188757232315],
              [-38.385728005824227, -8.016400481221687],
              [-38.388973512843265, -8.016848966632878],
              [-38.391488055320337, -8.013225605281324],
              [-38.395194359151944, -8.017349200267867],
              [-38.406762282323079, -8.008989150629361],
              [-38.406385194260615, -8.009025964558393],
              [-38.406266178655571, -8.009062064213179],
              [-38.406064163364682, -8.009077692836147],
              [-38.405870453088284, -8.009123182203977],
              [-38.405652801377698, -8.009158596907691],
              [-38.405415192639119, -8.00918964107565],
              [-38.405220137662575, -8.009223731345331],
              [-38.404996266403259, -8.009230373261593],
              [-38.404800742440038, -8.00927495498571],
              [-38.40461381062071, -8.009276584720704],
              [-38.404521305362309, -8.009303224744425],
              [-38.404331364058947, -8.009315161436342],
              [-38.404083147807512, -8.009339677024776],
              [-38.403876555008274, -8.009382975994633],
              [-38.40360827361112, -8.009415060192095],
              [-38.403411687536803, -8.00944109722411],
              [-38.403103296550604, -8.009474118043324],
              [-38.4028519875665, -8.009503693666032],
              [-38.402476709829976, -8.009543220115367],
              [-38.402225406854221, -8.009568634607756],
              [-38.401973025112738, -8.009587082646116],
              [-38.401715332737759, -8.009575945317581],
              [-38.401450517846612, -8.009533230063381],
              [-38.401316531948957, -8.009505357604695],
              [-38.401144845400047, -8.009444506180948],
              [-38.400991538601566, -8.009411721213979],
              [-38.400787081745918, -8.009297275022014],
              [-38.4006078037187, -8.009215427708748],
              [-38.400417507220006, -8.009098017034896],
              [-38.400280633463296, -8.008997417391731],
              [-38.40011242879487, -8.008851817967697],
              [-38.399967730941611, -8.008703177334558],
              [-38.399841774813481, -8.008583236909939],
              [-38.399816229631256, -8.008491120558965],
              [-38.399762598964472, -8.008430530772516],
              [-38.399659738593137, -8.008278423319307],
              [-38.399544972299019, -8.008077183587858],
              [-38.399454509827805, -8.007886290626749],
              [-38.399371666701143, -8.007697489115774],
              [-38.399293451211328, -8.007508875230998],
              [-38.39919280805028, -8.007268219317329],
              [-38.39908277240643, -8.006997158149195],
              [-38.399018857145577, -8.006895850327627],
              [-38.398979701383638, -8.006803442763486],
              [-38.398919944729002, -8.006650855232266],
              [-38.398851210801674, -8.006494093868582],
              [-38.398786884135006, -8.006364021980691],
              [-38.398725502084474, -8.006206095447368],
              [-38.398652108766512, -8.00600907654627],
              [-38.398583273807333, -8.005797592107792],
              [-38.398501514105966, -8.005613043241706],
              [-38.398431716005796, -8.005439637278204],
              [-38.398374301877816, -8.00529872128852],
              [-38.398320837538456, -8.005187026743624],
              [-38.398243992610716, -8.005116272438011],
              [-38.398227354261785, -8.005014852540965],
              [-38.398187948109907, -8.004907791482427],
              [-38.39808918456999, -8.004747458645232],
              [-38.397968319174197, -8.004560591393842],
              [-38.397793000747562, -8.004379433544527],
              [-38.397640725779794, -8.004201655945399],
              [-38.397528479404528, -8.004139533167182],
              [-38.397371008726715, -8.004039626254272],
              [-38.397144046166602, -8.00386725696954],
              [-38.396910009910762, -8.003753941771485],
              [-38.396648631649789, -8.003660395267886],
              [-38.396355338386996, -8.003611032499082],
              [-38.39606983613902, -8.003570364223656],
              [-38.395579127339239, -8.003557616085612],
              [-38.387502538070926, -8.008847762057572],
              [-38.384130287201593, -8.004742334554779],
              [-38.387768250967866, -8.002269934598012],
              [-38.3866653895955, -8.002027065309766],
              [-38.384807384417371, -8.001786234578441],
              [-38.383806451822707, -8.001165063389607],
              [-38.3821987103792, -8.00056550492228],
              [-38.380778681824339, -8.000288679103818],
              [-38.379203256442935, -8.00028631398752],
              [-38.37412298421318, -8.001364687639622],
              [-38.363600233798714, -8.019994244420584],
              [-38.351597835258637, -8.037534352529089],
              [-38.360967985500608, -8.040450578762815],
              [-38.380517660677548, -8.015096795861645],
              [-38.381470924178409, -8.015274063460478],
              [-38.383222211465799, -8.015736177300081],
              [-38.379311059061081, -8.020655618471855],
              [-38.36305546183663, -8.041100177266486],
              [-38.365585768073863, -8.04188757232315],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0130000',
        uf: 'PE',
        nome_proje: 'PA VIRGULINO FERREIRA',
        municipio: 'SERRA TALHADA',
        area_hecta: '5428.3445',
        capacidade: 300.0,
        num_famili: 292.0,
        fase: 4.0,
        data_de_cr: '16/11/2004',
        forma_obte: 'Doação',
        data_obten: '22/01/2003',
        area_calc_: 5424.7036,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.467952972524337, -8.116263125620424],
              [-38.468137853554339, -8.116065097135863],
              [-38.468181053811421, -8.11565468403769],
              [-38.468138858753719, -8.115300691692108],
              [-38.468040008960827, -8.114890002629251],
              [-38.467941049897959, -8.114493514310981],
              [-38.467828005901232, -8.114040384848641],
              [-38.467686496395118, -8.113629639984735],
              [-38.467658608016734, -8.113233244470319],
              [-38.467658978266698, -8.112951849907313],
              [-38.465446664000964, -8.1151972182551],
              [-38.465638516955693, -8.11542486532263],
              [-38.465879844699494, -8.115510115923025],
              [-38.466064536720673, -8.115524468536798],
              [-38.466391353565299, -8.115482475041079],
              [-38.46651943348764, -8.115270262438475],
              [-38.466633372254641, -8.115043920815534],
              [-38.466747121788522, -8.115029868848223],
              [-38.466974364569836, -8.115058477647175],
              [-38.467173383844987, -8.114945492611591],
              [-38.467201458220742, -8.11520033155802],
              [-38.467186890762079, -8.115441004267545],
              [-38.467371098037667, -8.115823493761269],
              [-38.467669113782755, -8.116078685623746],
              [-38.467952972524337, -8.116263125620424],
            ],
          ],
          [
            [
              [-38.471733704421503, -8.117929011493578],
              [-38.47179706760285, -8.117923395240657],
              [-38.47184926460455, -8.11791912126926],
              [-38.471911531958057, -8.117918568870845],
              [-38.47196808556415, -8.117914481437769],
              [-38.472029267841279, -8.117910761805408],
              [-38.472084918258417, -8.117903236020995],
              [-38.472136846851001, -8.117895976758904],
              [-38.472232795923006, -8.117890131325725],
              [-38.472355609821989, -8.117886129717622],
              [-38.472400640667544, -8.117878409224364],
              [-38.472484619632063, -8.117863955310714],
              [-38.472556638044161, -8.117833566389358],
              [-38.472620294273206, -8.11781203088926],
              [-38.472673968782573, -8.117788402062965],
              [-38.472733551076637, -8.117758720616226],
              [-38.47278904033346, -8.117735546382283],
              [-38.472836637646779, -8.11770865340365],
              [-38.472872924509794, -8.117654519817906],
              [-38.472874454494828, -8.117594914133548],
              [-38.47285727199634, -8.117546319331053],
              [-38.472835188648887, -8.117497265924742],
              [-38.47284544634357, -8.117426907787959],
              [-38.472840787400614, -8.117380138231495],
              [-38.472846404514385, -8.117318547900659],
              [-38.472849929023326, -8.117260753831475],
              [-38.472861266600475, -8.117197542774703],
              [-38.472875871680849, -8.117134426398636],
              [-38.472903816727829, -8.117073498129631],
              [-38.472939378677786, -8.117018368625144],
              [-38.472982262240755, -8.116986675581757],
              [-38.473033320514709, -8.116950651430585],
              [-38.473094814311331, -8.116916721164099],
              [-38.473148942747066, -8.116892911948907],
              [-38.473193268424609, -8.116868999593189],
              [-38.473239047552291, -8.11684418459531],
              [-38.473290997908897, -8.116820101187505],
              [-38.473335682634833, -8.116799264637395],
              [-38.473403271927879, -8.116754487999884],
              [-38.473463423706505, -8.116705540955268],
              [-38.47349789528063, -8.116651224049722],
              [-38.473463913729312, -8.116608034651476],
              [-38.47341446477045, -8.116593227085653],
              [-38.473346835861683, -8.116598747636953],
              [-38.473262772423368, -8.116608588601672],
              [-38.473182402749551, -8.116639690476887],
              [-38.473115559626422, -8.116669091254201],
              [-38.473083301987394, -8.116626446767222],
              [-38.473100077996762, -8.116569031643856],
              [-38.473116679654808, -8.116506098740039],
              [-38.473099877792329, -8.116444027135413],
              [-38.473063626650031, -8.116401196577378],
              [-38.473008124854559, -8.116364491821606],
              [-38.47298994889379, -8.11631209676708],
              [-38.47297132582019, -8.116254545386481],
              [-38.472972487988649, -8.116198557288493],
              [-38.472992711021824, -8.116142774759558],
              [-38.473009940972844, -8.116085269773778],
              [-38.473034706003993, -8.116026689116239],
              [-38.473073540990313, -8.115967312595366],
              [-38.473117717201418, -8.115918706732428],
              [-38.47315661960242, -8.11587723972637],
              [-38.473183551933218, -8.115827164320828],
              [-38.473212962535669, -8.11575592641951],
              [-38.47324346963547, -8.115679081927581],
              [-38.473253765539447, -8.11564897482552],
              [-38.473269255305894, -8.115603407198437],
              [-38.473287224401332, -8.115535863012017],
              [-38.473301469333926, -8.115470394397468],
              [-38.473309106292064, -8.115391078125633],
              [-38.473313273607424, -8.115327495963767],
              [-38.473310532385121, -8.11527177416674],
              [-38.473306782479654, -8.115223920363857],
              [-38.473311679696401, -8.11515744469056],
              [-38.473317846469875, -8.115091875175635],
              [-38.473315486451739, -8.11502222430763],
              [-38.473309949945936, -8.114952297976998],
              [-38.473294248494, -8.114881634888283],
              [-38.47328134915891, -8.114819930141838],
              [-38.473276168306683, -8.114755702728155],
              [-38.473258909342569, -8.114696163184652],
              [-38.47322966468748, -8.114640045305499],
              [-38.47318996571947, -8.114596305788355],
              [-38.473152887805398, -8.114561162570268],
              [-38.473102104230648, -8.114525911169183],
              [-38.473044146318834, -8.114493544935886],
              [-38.472989630130002, -8.114466881605317],
              [-38.472935569314608, -8.114439042984841],
              [-38.472861083275291, -8.11441325831535],
              [-38.47277606924613, -8.114386736392996],
              [-38.472722996045029, -8.114367220580851],
              [-38.472779659840917, -8.114347846807098],
              [-38.472827530692008, -8.114319687830305],
              [-38.472855792504433, -8.114294126571654],
              [-38.472909771350437, -8.114245533402782],
              [-38.472964028611578, -8.114192237097093],
              [-38.473024004396137, -8.114138767277597],
              [-38.473071268822764, -8.114088356364013],
              [-38.47312271439506, -8.114033428266444],
              [-38.473185694033972, -8.113973359329934],
              [-38.47324849353425, -8.113912204728937],
              [-38.473320463861157, -8.113848438873983],
              [-38.473388168251859, -8.113784577038407],
              [-38.473443703891043, -8.113725312499023],
              [-38.47350078442954, -8.113664602720073],
              [-38.473561582732216, -8.113606701739435],
              [-38.473610479415576, -8.113557378283755],
              [-38.473651129038721, -8.113498275380353],
              [-38.473686701606283, -8.113434643326734],
              [-38.473702299405311, -8.113375688954594],
              [-38.473699754013445, -8.113308932295185],
              [-38.473702516497212, -8.11320880580679],
              [-38.473683274189526, -8.113138861766584],
              [-38.47367056648519, -8.113069378426415],
              [-38.473659768645469, -8.112997003098085],
              [-38.473658226043604, -8.11292690101947],
              [-38.473673482524696, -8.112851212636052],
              [-38.473686547074479, -8.112785923368248],
              [-38.47369053156816, -8.112723245470139],
              [-38.473702598150268, -8.112657593104894],
              [-38.473699971283637, -8.112583690651864],
              [-38.473683095277643, -8.112508865274865],
              [-38.473667947249396, -8.11243141902947],
              [-38.473650170768309, -8.112351074932869],
              [-38.473623588433504, -8.112271895331951],
              [-38.473586194448856, -8.112200751965167],
              [-38.473538536828691, -8.11213502243781],
              [-38.473484060339025, -8.112078057911647],
              [-38.473410974786269, -8.112022607009026],
              [-38.473331705613745, -8.111976012370702],
              [-38.47325215321117, -8.111937829361979],
              [-38.473167583010792, -8.111919358326141],
              [-38.473080649364555, -8.111903597768476],
              [-38.47300242751389, -8.111889205235972],
              [-38.472949336241093, -8.111883799915546],
              [-38.472990129372512, -8.111853822707895],
              [-38.473016790420431, -8.111802751968302],
              [-38.473032711841441, -8.111774099363974],
              [-38.473053302146475, -8.111714880115191],
              [-38.473060559715819, -8.111648045678988],
              [-38.473060203527908, -8.111573060741637],
              [-38.473071270088596, -8.111508673426595],
              [-38.473069088328508, -8.111441555427783],
              [-38.473035491610673, -8.111381994749124],
              [-38.472981945568407, -8.111307664637362],
              [-38.472959029935119, -8.111270640245777],
              [-38.473008560638618, -8.111291870006742],
              [-38.473047474073503, -8.111311276997922],
              [-38.473083484646907, -8.111329413907924],
              [-38.473143987108301, -8.1113590698506],
              [-38.473210765626561, -8.111378603305132],
              [-38.473279085644876, -8.111399314614097],
              [-38.473295060594296, -8.111399244811164],
              [-38.473345915973006, -8.111379049354797],
              [-38.473397271686409, -8.111392864387597],
              [-38.473454074428531, -8.111405872387103],
              [-38.473520958095406, -8.111414370817238],
              [-38.473592034890991, -8.111409125990482],
              [-38.473661202538715, -8.111406230430255],
              [-38.473723475491838, -8.11140061240183],
              [-38.473765253302631, -8.111381128781852],
              [-38.473800060821546, -8.111347254331093],
              [-38.473834700562435, -8.111302796807523],
              [-38.473855116836198, -8.111237697920625],
              [-38.473867562977397, -8.111159292336779],
              [-38.473880095175161, -8.111084504932505],
              [-38.47389344085142, -8.111012341677789],
              [-38.473906876229876, -8.110940992603595],
              [-38.473940078715977, -8.110884955389487],
              [-38.473975189159042, -8.110827473406944],
              [-38.473995664993666, -8.110786344295985],
              [-38.47402803222181, -8.110744416465812],
              [-38.474056679332421, -8.110701488862393],
              [-38.474094413726299, -8.110650341875241],
              [-38.474129872615777, -8.110604076341536],
              [-38.474161156029936, -8.110557986320085],
              [-38.474197970398755, -8.110516335567061],
              [-38.474232880673689, -8.11047314467408],
              [-38.474265347077008, -8.110424704423792],
              [-38.4742948211357, -8.110373999016748],
              [-38.474317572521237, -8.110327897974582],
              [-38.474348407701179, -8.110277465673514],
              [-38.474371608322492, -8.11023489282484],
              [-38.474416014346176, -8.110148478224986],
              [-38.474435577155035, -8.110111418236722],
              [-38.47444122859612, -8.110092973368111],
              [-38.474456702123071, -8.110059616626808],
              [-38.474474906490101, -8.110020203138811],
              [-38.474521710062334, -8.109974675805594],
              [-38.474573332194907, -8.109923003959342],
              [-38.474626778200403, -8.109864731478797],
              [-38.4746710001289, -8.109780216093084],
              [-38.47470796007547, -8.109696234052189],
              [-38.47472825964153, -8.109650943886034],
              [-38.47473639636312, -8.109605818947449],
              [-38.474743900422361, -8.109558612802804],
              [-38.474746382753821, -8.109464274822521],
              [-38.474746859317889, -8.109376808590239],
              [-38.474732344526238, -8.109300900863053],
              [-38.47471854506535, -8.109233315618745],
              [-38.474723352088731, -8.109166116159749],
              [-38.474739087051326, -8.10914108130091],
              [-38.474747909389954, -8.109127163099476],
              [-38.474794641422406, -8.109066711092504],
              [-38.474797916846533, -8.10906065504521],
              [-38.474828942312037, -8.109003348606464],
              [-38.474835469750438, -8.108939317172219],
              [-38.474819584785294, -8.108870101111226],
              [-38.474801348605318, -8.108794188590208],
              [-38.474788379412679, -8.108716292916236],
              [-38.474765607316236, -8.108638565514346],
              [-38.474746189794629, -8.108563736890257],
              [-38.474736181959258, -8.108511985588809],
              [-38.474714459514473, -8.108464741779626],
              [-38.474700175028417, -8.1084212160766],
              [-38.474669159858642, -8.108330724349958],
              [-38.474635592353835, -8.108248641345568],
              [-38.474606732688152, -8.108175790485866],
              [-38.474588037814293, -8.10810367633986],
              [-38.474564341342969, -8.108038882338969],
              [-38.47449467757265, -8.107934953924721],
              [-38.470047927973631, -8.112416776920293],
              [-38.46912608513184, -8.111462759021066],
              [-38.469049648945962, -8.111540357413295],
              [-38.469453453774058, -8.111966458475241],
              [-38.469454652868684, -8.112021092844339],
              [-38.469456367029743, -8.112098250349391],
              [-38.46947287691242, -8.112174793957601],
              [-38.469492036690937, -8.112237863716985],
              [-38.469517645265313, -8.112297595160138],
              [-38.469554248776483, -8.112348024394414],
              [-38.469548767464865, -8.112374971855518],
              [-38.469534640471188, -8.112419365232265],
              [-38.469529997684276, -8.112499047106681],
              [-38.46951828069696, -8.112574468541728],
              [-38.469507646377544, -8.112654866227212],
              [-38.469497746603906, -8.112728842800003],
              [-38.469493204293713, -8.112801107766385],
              [-38.469484029575604, -8.112875899347044],
              [-38.469488018231971, -8.112948989486137],
              [-38.469488565474997, -8.113016195785582],
              [-38.469485386194627, -8.113087286653821],
              [-38.46947985113647, -8.113155208645811],
              [-38.469479847728522, -8.113227027262328],
              [-38.469484491793438, -8.113284831922767],
              [-38.469491587384049, -8.113342006613813],
              [-38.469496138312273, -8.113401620185909],
              [-38.469502963029285, -8.113457709103971],
              [-38.469524295919506, -8.113524852016662],
              [-38.469559560923678, -8.113557822340621],
              [-38.469596835130289, -8.113581388304778],
              [-38.469672244445491, -8.113594963776645],
              [-38.469738485754057, -8.113608979559997],
              [-38.469802180744651, -8.113626700537235],
              [-38.469851813802144, -8.113639157008851],
              [-38.469814576405234, -8.113656746622954],
              [-38.469786967964815, -8.113668469407596],
              [-38.46972866300127, -8.113693448629572],
              [-38.469663628144041, -8.113728459216279],
              [-38.469605835864378, -8.113777770584361],
              [-38.469570563407231, -8.113819694230003],
              [-38.469558968450187, -8.113871236579611],
              [-38.46955052909091, -8.113938973885057],
              [-38.469552712269028, -8.114004554182237],
              [-38.469561699149359, -8.114073037791838],
              [-38.469571310724426, -8.114149662864028],
              [-38.469572200992879, -8.114232155937716],
              [-38.469571050119335, -8.114279189302435],
              [-38.46957343914611, -8.114326227276518],
              [-38.469575780850185, -8.114409355401122],
              [-38.469584929465725, -8.114492944647402],
              [-38.469597426372445, -8.114584226644318],
              [-38.469611025174949, -8.11466583174805],
              [-38.469619188292839, -8.11473956047926],
              [-38.469630246344082, -8.11482061956405],
              [-38.469643391205409, -8.114902314527303],
              [-38.469660257985822, -8.114983652529546],
              [-38.469677304764019, -8.115066166637167],
              [-38.469688996895322, -8.115148221514195],
              [-38.469696791627484, -8.115226020088603],
              [-38.469706772357227, -8.115298032602732],
              [-38.469712399834826, -8.115367325899182],
              [-38.46971795433474, -8.115423051351819],
              [-38.469729757999467, -8.115489277332703],
              [-38.469740768955788, -8.115537050143111],
              [-38.469750944038381, -8.115599294130213],
              [-38.469751054303998, -8.115653655719477],
              [-38.46974842578728, -8.115720043813189],
              [-38.469745902029203, -8.11577575874764],
              [-38.469744471506267, -8.115828399748874],
              [-38.469755728090313, -8.115896524501142],
              [-38.469766907428998, -8.115954337663849],
              [-38.469776724315615, -8.116012872665715],
              [-38.46978344872015, -8.116076288030632],
              [-38.469785362351232, -8.11613978758391],
              [-38.469783032103081, -8.116186367152363],
              [-38.469786137801719, -8.116240642188526],
              [-38.469796951710919, -8.116300263907657],
              [-38.469801961031216, -8.116356440906674],
              [-38.469805332274532, -8.116415872032475],
              [-38.469811971848102, -8.116474764703232],
              [-38.469825981115257, -8.116520280123586],
              [-38.469853600603344, -8.116569521779423],
              [-38.469885571825614, -8.116622658519685],
              [-38.469914834148106, -8.11666502798494],
              [-38.469951628615583, -8.116708583123648],
              [-38.469980347532996, -8.116750047360938],
              [-38.470011325205711, -8.116799474281841],
              [-38.470052454476779, -8.11685985906211],
              [-38.47009803052692, -8.116920882786856],
              [-38.470131540229126, -8.116977549127272],
              [-38.470174038274031, -8.117032418127009],
              [-38.470224699227124, -8.117092001228924],
              [-38.470275360431991, -8.117151403422318],
              [-38.470315877982671, -8.117193787504],
              [-38.470348949775321, -8.117238332737333],
              [-38.470382113290178, -8.117282154474317],
              [-38.470418899807349, -8.117331860278728],
              [-38.470463037662817, -8.117382208801869],
              [-38.470501370205604, -8.117429655318718],
              [-38.470543873557773, -8.117480544418681],
              [-38.470580579884697, -8.117522199908347],
              [-38.470622914155236, -8.11756359135685],
              [-38.470659721448349, -8.117597468128622],
              [-38.470714217626217, -8.117639598992056],
              [-38.470782253557125, -8.117669988730604],
              [-38.470849309285398, -8.117686447628431],
              [-38.470921531664402, -8.117708340328821],
              [-38.470970417106713, -8.11773761973352],
              [-38.47101595244262, -8.117760563163992],
              [-38.471058133883126, -8.117780065069525],
              [-38.471102141562547, -8.117791247789809],
              [-38.471150312009399, -8.117812023788817],
              [-38.471192861027632, -8.117828088995187],
              [-38.47128431623053, -8.117857604551544],
              [-38.471336946366677, -8.117869160250175],
              [-38.471382953670428, -8.117878265148994],
              [-38.471432680434702, -8.117888912549507],
              [-38.471485052583482, -8.117889523242356],
              [-38.471535774721715, -8.11790252366602],
              [-38.471580952952039, -8.117920943989139],
              [-38.471630412626311, -8.117927611145946],
              [-38.471684328066424, -8.11792804291281],
              [-38.471733704421503, -8.117929011493578],
            ],
          ],
          [
            [
              [-38.505345708517723, -8.140668373481098],
              [-38.505436961689512, -8.140647137951907],
              [-38.505533914812546, -8.140641195736247],
              [-38.505615703167095, -8.140639848088426],
              [-38.505686503649805, -8.140642014677967],
              [-38.505753325861214, -8.140631332223762],
              [-38.505820071597753, -8.140608981352328],
              [-38.505877364841467, -8.140596478239708],
              [-38.505935926910595, -8.140585604799234],
              [-38.505990782240673, -8.140562515815208],
              [-38.506053276578044, -8.140527948706188],
              [-38.506099169733297, -8.140484949355534],
              [-38.506137549319568, -8.140425116781266],
              [-38.506161599932888, -8.140354502983977],
              [-38.506179302295017, -8.140279087506375],
              [-38.506200812464535, -8.140207475643534],
              [-38.506215432959522, -8.140128438333679],
              [-38.506232138236207, -8.140051845762821],
              [-38.506246661188456, -8.13997832590683],
              [-38.506248928787933, -8.139905605217963],
              [-38.506250648979268, -8.139835145164453],
              [-38.506235657757223, -8.139772172312762],
              [-38.506217383650849, -8.139721496947368],
              [-38.506180316676655, -8.139673150483603],
              [-38.506141256030766, -8.139622088031123],
              [-38.506105461113556, -8.139572748135732],
              [-38.506063944190117, -8.139526114839807],
              [-38.506022605608727, -8.139482104866373],
              [-38.505985538568446, -8.139433848838856],
              [-38.505948851361424, -8.139371935011621],
              [-38.505935229992893, -8.139302270366093],
              [-38.505921146785305, -8.139239117705545],
              [-38.505883172271979, -8.139190679662159],
              [-38.505842008749823, -8.139152006556374],
              [-38.505802298265117, -8.139112792502665],
              [-38.505757521030397, -8.139060094925068],
              [-38.505714549506379, -8.139015359320503],
              [-38.505664043521449, -8.138970885897637],
              [-38.505614619169549, -8.138932654976713],
              [-38.505567917565344, -8.138894789172909],
              [-38.505533019861119, -8.138854133721505],
              [-38.505500042365142, -8.138802083647999],
              [-38.505466701121208, -8.138750575841566],
              [-38.505435067040828, -8.138713361534052],
              [-38.50539844557192, -8.138671980357222],
              [-38.505378165408537, -8.138628810037067],
              [-38.505357913205444, -8.138562845835681],
              [-38.505368784151635, -8.138506597904467],
              [-38.505407409332108, -8.138468474177991],
              [-38.505460117348939, -8.13841969425302],
              [-38.505500270120471, -8.138394235662634],
              [-38.50554522196974, -8.138378461278876],
              [-38.505592895996031, -8.13836350427178],
              [-38.505642560918496, -8.138353524544506],
              [-38.505697040001699, -8.138340927560344],
              [-38.505748975121335, -8.138330226966056],
              [-38.505799009001585, -8.138315453709573],
              [-38.505846865001139, -8.138300135084853],
              [-38.505892187010893, -8.138278572180338],
              [-38.505936053995356, -8.138259178349855],
              [-38.505982468812569, -8.138234722298245],
              [-38.506056341806918, -8.138173666551296],
              [-38.506089152353304, -8.138139696477259],
              [-38.506118883272869, -8.138100295532476],
              [-38.506150247981608, -8.138060987023579],
              [-38.50620407059867, -8.137991404375169],
              [-38.506239840760706, -8.137912573665197],
              [-38.506261280036746, -8.137824589874361],
              [-38.506274045318008, -8.137777932128914],
              [-38.506286764442486, -8.137694822147548],
              [-38.506281778615644, -8.137615218277041],
              [-38.506260894438974, -8.137546358877705],
              [-38.506229669078436, -8.137471878880206],
              [-38.506181458992003, -8.137405790275331],
              [-38.50613259169733, -8.13735751992167],
              [-38.506034891645541, -8.137306928884909],
              [-38.505997700450543, -8.137286079686955],
              [-38.505983917340899, -8.137274394610479],
              [-38.505950298180146, -8.137227499560495],
              [-38.505951301439815, -8.137223611342277],
              [-38.505953125195788, -8.137216829656099],
              [-38.505980877452139, -8.137162411268848],
              [-38.505986387045944, -8.137110769844924],
              [-38.505991285602001, -8.137039228225303],
              [-38.506013040110908, -8.136990320116345],
              [-38.506030519898474, -8.136948281164379],
              [-38.506053085256049, -8.136904348901565],
              [-38.506070704206785, -8.136822782566901],
              [-38.506074331708376, -8.136751420302888],
              [-38.506099457830608, -8.136691842943199],
              [-38.50610426779977, -8.136618492170397],
              [-38.506100831670842, -8.136533553508739],
              [-38.506090220331608, -8.136452043302128],
              [-38.506064365695472, -8.136365268343711],
              [-38.50602589008659, -8.136281462956944],
              [-38.505990500745099, -8.136197661319759],
              [-38.505966499300627, -8.136154215146366],
              [-38.505943311154567, -8.13611375487875],
              [-38.505889393813455, -8.136038523648914],
              [-38.505848998871457, -8.135965479708947],
              [-38.505829208202478, -8.135893274895027],
              [-38.505811047891044, -8.135823966529207],
              [-38.505778629286247, -8.135760429751322],
              [-38.505728776038417, -8.135702117981864],
              [-38.505680007414171, -8.135647606513098],
              [-38.505640299930619, -8.135606221612218],
              [-38.505590160993535, -8.135558854179026],
              [-38.50554001818842, -8.135514652557521],
              [-38.505483617894534, -8.135465739807779],
              [-38.505433025355543, -8.135418190900831],
              [-38.50537451837188, -8.135384742874521],
              [-38.505306643923539, -8.135365936668613],
              [-38.505298475669989, -8.135364931751239],
              [-38.505272065142897, -8.135361552869435],
              [-38.505235943462267, -8.135356986284528],
              [-38.505199184049722, -8.135354318412992],
              [-38.505168233597161, -8.135352019420688],
              [-38.505101782225822, -8.135357094256323],
              [-38.505041397259319, -8.135374658853767],
              [-38.504976558803605, -8.135396831070839],
              [-38.504842613371167, -8.135442979311835],
              [-38.504791682098137, -8.135449610693442],
              [-38.504771528396027, -8.135451485623395],
              [-38.504780082829988, -8.135433676996172],
              [-38.504800467811322, -8.135391279830106],
              [-38.504821396864592, -8.135349335586003],
              [-38.504855313511499, -8.135301527783966],
              [-38.504885584844722, -8.13526547430321],
              [-38.504932483299704, -8.135216506435089],
              [-38.504987022672189, -8.135154432321883],
              [-38.505040729324641, -8.135105201377348],
              [-38.505096162071624, -8.135054796650344],
              [-38.505161858568208, -8.134999067742912],
              [-38.505210783443694, -8.134926042059195],
              [-38.505250373888138, -8.134840703523686],
              [-38.505272661557505, -8.134801112658401],
              [-38.505291322675738, -8.134757989746298],
              [-38.505306897438309, -8.134714953527716],
              [-38.505319300729887, -8.134667390844669],
              [-38.505321492385264, -8.134582639933916],
              [-38.505314376862621, -8.134537405242632],
              [-38.505303202894972, -8.134470728472683],
              [-38.505291503373911, -8.134388493308439],
              [-38.505253970079103, -8.134276739321855],
              [-38.505244470659839, -8.134250948919895],
              [-38.505266153668003, -8.134260291879942],
              [-38.505310886030912, -8.134275090021378],
              [-38.505353346222371, -8.134292237145953],
              [-38.505413518316686, -8.13430008926094],
              [-38.505485660194651, -8.134317815208012],
              [-38.505547263252275, -8.134343126292974],
              [-38.505603691776656, -8.13436888333403],
              [-38.505671278802538, -8.134399900161213],
              [-38.505735160596394, -8.134417525565489],
              [-38.50578935840381, -8.134411802549987],
              [-38.505859153216925, -8.134418942686571],
              [-38.505905358681993, -8.134416828019525],
              [-38.505995594523107, -8.134409792011338],
              [-38.506069900598597, -8.134365108560367],
              [-38.506127797792416, -8.13430340016396],
              [-38.506185326236398, -8.134246304365632],
              [-38.506246131962705, -8.134181433661658],
              [-38.506287187098629, -8.13415995594816],
              [-38.506337304126347, -8.134150971624623],
              [-38.506385962056164, -8.134147503098953],
              [-38.506481289676913, -8.134133417901641],
              [-38.506528230685674, -8.134123615628377],
              [-38.506533496990826, -8.134122355694167],
              [-38.50657326659357, -8.134112906515615],
              [-38.506660359968031, -8.134077555039026],
              [-38.506733572452561, -8.134036307309438],
              [-38.506801435807816, -8.133989806853419],
              [-38.50687346812127, -8.133948648116528],
              [-38.506946930000069, -8.133926124229435],
              [-38.507017565132998, -8.133914089341259],
              [-38.507096270098266, -8.133909300395068],
              [-38.50716807100622, -8.133908935213256],
              [-38.507232958139191, -8.133920953571531],
              [-38.507299838166368, -8.133936321063105],
              [-38.507362101654891, -8.133941190505412],
              [-38.507415932326566, -8.133938723097847],
              [-38.507474044168752, -8.133924049839568],
              [-38.507526742714823, -8.133882324837797],
              [-38.507553658137624, -8.133843824862369],
              [-38.507563976699899, -8.133793546002746],
              [-38.507557507150224, -8.133739447812799],
              [-38.507540234348781, -8.133686512409248],
              [-38.507513331375939, -8.133640439694309],
              [-38.507476805299035, -8.133595440740978],
              [-38.50744199516793, -8.133557589579562],
              [-38.507402478613891, -8.133508516651904],
              [-38.507353975025623, -8.133459975539603],
              [-38.507319798551407, -8.133423572370411],
              [-38.50730048917525, -8.133403106789965],
              [-38.507286437922573, -8.133388074708591],
              [-38.507236202083583, -8.133345772677735],
              [-38.507191040953764, -8.133310441605445],
              [-38.507134100969559, -8.133257548539536],
              [-38.507087866288636, -8.133209371958362],
              [-38.507054716739567, -8.133149723827929],
              [-38.507063777687115, -8.133088679658163],
              [-38.507058778119635, -8.133020201363756],
              [-38.50703308123569, -8.132952873843417],
              [-38.506993321865288, -8.132879740330919],
              [-38.506968408572384, -8.132839730288183],
              [-38.506921749495135, -8.132767492921031],
              [-38.506873093243442, -8.132695434030287],
              [-38.506832973934856, -8.132619767409226],
              [-38.506785037917545, -8.132552593795998],
              [-38.506738650555135, -8.132480899452855],
              [-38.506685627176374, -8.132417156834885],
              [-38.506644775788061, -8.132346373722093],
              [-38.506608010667854, -8.132274329236585],
              [-38.50656923366504, -8.132214493337582],
              [-38.506520518485495, -8.132190735752197],
              [-38.506566657141157, -8.132168811906695],
              [-38.506649552719523, -8.132151364821919],
              [-38.506710643190189, -8.1321502630864],
              [-38.506777092921347, -8.132146092473587],
              [-38.506834272714158, -8.132151136716276],
              [-38.506899611642588, -8.132164602893244],
              [-38.506975669978523, -8.13217120770291],
              [-38.50705362026325, -8.132189483112807],
              [-38.507132565173762, -8.132210925537393],
              [-38.50721525115096, -8.132216452913962],
              [-38.507288955084128, -8.132218441754723],
              [-38.507350706470746, -8.132196446290486],
              [-38.507411724395531, -8.13218041976603],
              [-38.507473815235421, -8.132177781442557],
              [-38.50753299918815, -8.132176948629716],
              [-38.50758981150225, -8.132185610405664],
              [-38.507636625851852, -8.132205023863353],
              [-38.507700326307265, -8.132222286917212],
              [-38.507772363604943, -8.132251137926016],
              [-38.507839303935768, -8.132291289263886],
              [-38.507904713189077, -8.132321579458758],
              [-38.507974755537312, -8.132348799880383],
              [-38.508044895612613, -8.13237032192618],
              [-38.508116854539239, -8.132389042146078],
              [-38.508184380779404, -8.132395365051702],
              [-38.508256269114113, -8.132397532426648],
              [-38.508311824015308, -8.132395247878033],
              [-38.508318087565634, -8.132394984098404],
              [-38.508376177121995, -8.13239840108981],
              [-38.508451237058189, -8.132405004419683],
              [-38.508526650170133, -8.132419748852834],
              [-38.50858298721591, -8.13244604808003],
              [-38.508637396928975, -8.132489711775312],
              [-38.508707778115308, -8.132536922401341],
              [-38.50878306779677, -8.132578530917147],
              [-38.508827073187909, -8.132593960955603],
              [-38.508902284228611, -8.132625619622946],
              [-38.508976946802015, -8.132660443438411],
              [-38.50905196521262, -8.132701237510361],
              [-38.509126163286062, -8.132744834594135],
              [-38.509197565172101, -8.132773865497809],
              [-38.509268713561539, -8.13278742877119],
              [-38.509331267374236, -8.132777373653996],
              [-38.509372885868274, -8.132740248072693],
              [-38.509409436110055, -8.132690814880835],
              [-38.509416042894635, -8.132632209880908],
              [-38.509404305946639, -8.132580095292905],
              [-38.509383957488247, -8.132518291933325],
              [-38.50936315660833, -8.132455312149284],
              [-38.509348168947049, -8.132389173557321],
              [-38.509363415449052, -8.132391724620557],
              [-38.509416487203701, -8.132416029847066],
              [-38.50947373444609, -8.132440340106029],
              [-38.509535710268672, -8.132457691249545],
              [-38.509601236619929, -8.132466453715915],
              [-38.509666793633137, -8.132449980065953],
              [-38.509705868158299, -8.132414750871959],
              [-38.50974377923972, -8.132365861999382],
              [-38.509765629394138, -8.132312340693927],
              [-38.509763964944298, -8.132262770920851],
              [-38.509743235449477, -8.132215710813222],
              [-38.509707354958358, -8.132162210328952],
              [-38.509677931102637, -8.132098948800635],
              [-38.509662854715287, -8.132031091519456],
              [-38.509663929236858, -8.131968499943827],
              [-38.50971417659315, -8.131926681217388],
              [-38.509756049807891, -8.131903937783756],
              [-38.509813269613083, -8.131876057080758],
              [-38.509865402818768, -8.131850974252034],
              [-38.509923995928332, -8.131813416810356],
              [-38.509982508907378, -8.131767085406027],
              [-38.510042475529445, -8.13171967031893],
              [-38.510104892959056, -8.131672258175628],
              [-38.510156335845963, -8.131617596626675],
              [-38.51018774013891, -8.13154491095082],
              [-38.510212150434143, -8.131476015834737],
              [-38.510227484378177, -8.131406476616391],
              [-38.510250724728976, -8.131329258490359],
              [-38.51026742384672, -8.1312567359934],
              [-38.510279130001017, -8.13118465974107],
              [-38.510277575019536, -8.131119622780217],
              [-38.510260868958021, -8.131048235917616],
              [-38.510226362126808, -8.130984787397697],
              [-38.510180594983368, -8.130925214750507],
              [-38.510149259231994, -8.130866654461093],
              [-38.510116658183982, -8.130803570038093],
              [-38.510077521527343, -8.130740568189148],
              [-38.510058585526366, -8.130711600685341],
              [-38.510042458157805, -8.130686978268223],
              [-38.509994849196268, -8.130649202348186],
              [-38.509938527333269, -8.130610511399137],
              [-38.509888093401507, -8.130581867728093],
              [-38.509834577988698, -8.130549330894228],
              [-38.509772452529852, -8.130505929415641],
              [-38.50971719875902, -8.130484696987953],
              [-38.509648593104941, -8.130470503716134],
              [-38.509566641389625, -8.130458555637315],
              [-38.50947595699234, -8.130461973868623],
              [-38.509392619869878, -8.130469652195838],
              [-38.509311643887621, -8.130476428834264],
              [-38.50922567759423, -8.130481480841986],
              [-38.509148703995798, -8.130481297465231],
              [-38.509068454287068, -8.130487984479497],
              [-38.508987210167362, -8.130491323548249],
              [-38.508903982291592, -8.130483805949527],
              [-38.508820486399266, -8.13047276037741],
              [-38.508751308866451, -8.13048099839143],
              [-38.508700363288845, -8.130499931776178],
              [-38.508652382761682, -8.130543833522772],
              [-38.508624463033122, -8.130587397701085],
              [-38.508611057175173, -8.130638758301929],
              [-38.508593747393121, -8.130690747348146],
              [-38.508566841942475, -8.130646755096574],
              [-38.508520995551613, -8.130577956066123],
              [-38.508479930849056, -8.130533132623476],
              [-38.508429853185817, -8.130510187692101],
              [-38.508373936231948, -8.130511838669598],
              [-38.508327544491941, -8.130518204668814],
              [-38.508273714055704, -8.130520853129024],
              [-38.508226784189262, -8.130521881790994],
              [-38.508169258593689, -8.130502817260274],
              [-38.508108247121463, -8.130513778605831],
              [-38.508060849624677, -8.130526113196904],
              [-38.508044525085701, -8.130514425121358],
              [-38.508028787720555, -8.130467823240382],
              [-38.507984373273167, -8.130415849980944],
              [-38.507935869298542, -8.130367851660637],
              [-38.507864311970863, -8.130317835484187],
              [-38.507821944600003, -8.130299241515463],
              [-38.507736384441046, -8.130269017378508],
              [-38.507657998940047, -8.130235726540702],
              [-38.507571888888208, -8.130209843405723],
              [-38.507487861611146, -8.130188033120971],
              [-38.507408477228765, -8.130155102835431],
              [-38.50736711102585, -8.130134339183831],
              [-38.507283724582365, -8.130108097481738],
              [-38.507199241393721, -8.1300880051768],
              [-38.5071172096876, -8.130067373114002],
              [-38.507037802611684, -8.130053075864449],
              [-38.506965282521264, -8.130048284421612],
              [-38.506909095313866, -8.130048306735537],
              [-38.506856351871356, -8.130052855823626],
              [-38.506815282276591, -8.130086544640893],
              [-38.506815588123928, -8.130133399198675],
              [-38.506803846208307, -8.130159977872111],
              [-38.506753142695558, -8.130129343554687],
              [-38.506706054217162, -8.130111648267102],
              [-38.506658125374187, -8.130113127799012],
              [-38.506612456183426, -8.13012247939956],
              [-38.506568537282831, -8.130110848233125],
              [-38.506515914895324, -8.130090613549454],
              [-38.506455671750871, -8.130067113333205],
              [-38.506404124801293, -8.130058186452699],
              [-38.506356906648314, -8.130072330105024],
              [-38.506312323498726, -8.130084306101075],
              [-38.506262380889979, -8.130099531858979],
              [-38.506283586556755, -8.130053698399671],
              [-38.506283673165647, -8.129982874517278],
              [-38.506258781456076, -8.129925407212559],
              [-38.50624294104945, -8.12988902623573],
              [-38.506223770338337, -8.129829575930703],
              [-38.506196619556356, -8.129763784286999],
              [-38.506167651820498, -8.129699256764262],
              [-38.506138412887871, -8.129633824389533],
              [-38.506095823710055, -8.129574074278445],
              [-38.506047333236893, -8.129515311967534],
              [-38.505985572561549, -8.129471005863456],
              [-38.505919436648171, -8.129441619029015],
              [-38.505855376291763, -8.129422184436432],
              [-38.505784959675509, -8.129404641602353],
              [-38.505703100213758, -8.129391788354701],
              [-38.505626769125406, -8.129385997075117],
              [-38.505577220236304, -8.129376258456199],
              [-38.505533027700395, -8.129365802728335],
              [-38.50552703894666, -8.129364076856026],
              [-38.505478765615088, -8.129350450336961],
              [-38.50542949059777, -8.129339445704373],
              [-38.505425407029861, -8.129338536216734],
              [-38.505353707141616, -8.129331212834209],
              [-38.505289179461577, -8.129322812740423],
              [-38.505222741612712, -8.129317666587092],
              [-38.505164742213971, -8.129315153802592],
              [-38.505102470169703, -8.129317882030188],
              [-38.505044192917047, -8.129319891494275],
              [-38.504979285088247, -8.129325420508883],
              [-38.504921177841744, -8.129336837178183],
              [-38.504866881625254, -8.129349343904513],
              [-38.50481930287178, -8.12936149699568],
              [-38.504778975333252, -8.129382161407571],
              [-38.50479636653877, -8.129338132427717],
              [-38.504816975420944, -8.129260911453715],
              [-38.504838360520097, -8.129216887334991],
              [-38.504802466748458, -8.129174964313146],
              [-38.504833132510832, -8.129112951533166],
              [-38.504867236672737, -8.129059807240456],
              [-38.504896171010941, -8.129003309922783],
              [-38.50491465564528, -8.128955845087738],
              [-38.504928234677173, -8.128911630553841],
              [-38.504940464347527, -8.128857464647821],
              [-38.504950252638018, -8.128795607342182],
              [-38.504936271824931, -8.128723319110394],
              [-38.504905319277889, -8.128649110721852],
              [-38.504861838097973, -8.128576786570472],
              [-38.50480245448027, -8.128518824931135],
              [-38.504764997928262, -8.128493000475345],
              [-38.5046869944306, -8.128444784968927],
              [-38.504610534491967, -8.128396209520554],
              [-38.504537523734136, -8.128347728713242],
              [-38.504485730719765, -8.128317635518762],
              [-38.504427207055954, -8.128298659721372],
              [-38.504321955890376, -8.128263978754857],
              [-38.504250810395085, -8.128248876987413],
              [-38.504191188403873, -8.128237316888123],
              [-38.504163240810989, -8.128229413483599],
              [-38.50418549452899, -8.128217229586273],
              [-38.504213567650645, -8.128196821643751],
              [-38.504244817840878, -8.128176327120054],
              [-38.504262078077105, -8.128165041651645],
              [-38.504296699642879, -8.128134058792778],
              [-38.504317807978097, -8.128093652444441],
              [-38.5043226744259, -8.128048341886776],
              [-38.504322012768334, -8.127995788425077],
              [-38.504295757232313, -8.127940490205777],
              [-38.504260044166493, -8.127899381432403],
              [-38.504226138850413, -8.127864516053013],
              [-38.504186164373316, -8.127819512639782],
              [-38.504146090027987, -8.127781926171215],
              [-38.504104032740408, -8.127732940320085],
              [-38.504062065677743, -8.127684406835511],
              [-38.504024717461874, -8.127644381474589],
              [-38.503982928958585, -8.127598290406439],
              [-38.503935675479177, -8.127567479068729],
              [-38.503873509812578, -8.127557724867811],
              [-38.503799437701268, -8.127560619420944],
              [-38.50373797441587, -8.127570222799145],
              [-38.503672974649263, -8.127576927385931],
              [-38.503659082924941, -8.127580076255107],
              [-38.503618134134392, -8.127589342841965],
              [-38.503562930647149, -8.127601667395229],
              [-38.503561478102242, -8.127601846526371],
              [-38.503502102075885, -8.127611814224242],
              [-38.503451892276345, -8.127623059433494],
              [-38.503397871128577, -8.127633485914004],
              [-38.50334619320595, -8.127657573510163],
              [-38.503313826074034, -8.127700589164453],
              [-38.503282156961347, -8.127692681157392],
              [-38.503240145947245, -8.127679966556663],
              [-38.503143454385757, -8.127623406355946],
              [-38.503078645495634, -8.127622513106836],
              [-38.503028991883092, -8.127624351928505],
              [-38.502983134698496, -8.127639130028003],
              [-38.502928042556491, -8.127634720998948],
              [-38.502890838928302, -8.127624816252482],
              [-38.502850009506687, -8.127610836728014],
              [-38.50281789702882, -8.12759442565044],
              [-38.50280546931382, -8.127588078813716],
              [-38.502799028642485, -8.127584814665969],
              [-38.502805201903108, -8.127584098596383],
              [-38.502857402752682, -8.127577649860571],
              [-38.502890910295989, -8.127566836570953],
              [-38.502892726589501, -8.127566115174536],
              [-38.502945588209123, -8.127539044165555],
              [-38.502996189696432, -8.127504553318397],
              [-38.503044344611503, -8.127466622296676],
              [-38.503046706971958, -8.127464725689988],
              [-38.503090962769292, -8.127423533623642],
              [-38.503114143757507, -8.127395431353941],
              [-38.503125597928296, -8.127381515730081],
              [-38.503157060689034, -8.127335785423064],
              [-38.50316446870297, -8.127290568441534],
              [-38.503134381441974, -8.127251185039604],
              [-38.503075769238173, -8.127230580814269],
              [-38.503014780991236, -8.127222998774133],
              [-38.5029575872789, -8.127229893691807],
              [-38.502910121436933, -8.127224227654542],
              [-38.50289133392635, -8.127222667007182],
              [-38.50289145015703, -8.127128235199487],
              [-38.502901799945789, -8.127126619712012],
              [-38.502964199483571, -8.127093771421757],
              [-38.503023232172964, -8.127067702911962],
              [-38.503050664087532, -8.127051997771082],
              [-38.503075643540043, -8.1270376464128],
              [-38.503117701442235, -8.12701219031341],
              [-38.503137230015554, -8.127001631278608],
              [-38.50316357067851, -8.126987552933631],
              [-38.50320977550868, -8.126985257618006],
              [-38.503269575371846, -8.126999622080584],
              [-38.503314399854581, -8.127012611466508],
              [-38.50336012736927, -8.127029400937033],
              [-38.503405377460211, -8.127065365657845],
              [-38.503419799775536, -8.127073614404559],
              [-38.503473702944206, -8.12708525807683],
              [-38.503542866713566, -8.127087784710866],
              [-38.503601606604178, -8.127078358938201],
              [-38.503647287723389, -8.127059057945104],
              [-38.503685894555815, -8.127035044806608],
              [-38.503714552358424, -8.12698198440985],
              [-38.503733772628443, -8.126926832098036],
              [-38.50374925976481, -8.126881082245655],
              [-38.503756553371346, -8.126855040947605],
              [-38.503795694776898, -8.126913068478881],
              [-38.5038396584495, -8.126961785325921],
              [-38.503886830415794, -8.126984998597466],
              [-38.503954454194975, -8.126984990624146],
              [-38.504017461588496, -8.126974484569583],
              [-38.504077857417492, -8.126947060782618],
              [-38.504145425624401, -8.126918379401044],
              [-38.504228331621853, -8.126891616196906],
              [-38.5043075263631, -8.126856436408847],
              [-38.504383451262655, -8.126822971209679],
              [-38.504451119521754, -8.126786691921744],
              [-38.50449891635639, -8.126744509029232],
              [-38.504552440565512, -8.126695096940125],
              [-38.504610772463018, -8.126648223356961],
              [-38.504667462279549, -8.1266080412191],
              [-38.504735221026912, -8.126571942894275],
              [-38.504775733921278, -8.126547931966018],
              [-38.504835608639532, -8.126501241129999],
              [-38.504903661728257, -8.126447143167544],
              [-38.50497171391217, -8.126393768808137],
              [-38.505027780794457, -8.126343364770948],
              [-38.505065675287618, -8.126307953661362],
              [-38.505101573169277, -8.12627226876236],
              [-38.505144560624721, -8.126228813604589],
              [-38.50519200875808, -8.126174780972775],
              [-38.505235839490631, -8.126109799231248],
              [-38.505267512368917, -8.126040460996856],
              [-38.505292480122627, -8.125961436230424],
              [-38.505314997081243, -8.125882408481255],
              [-38.50534340841682, -8.125808000954562],
              [-38.505361294436028, -8.1257303243514],
              [-38.505376722576109, -8.12565843369255],
              [-38.505381531980532, -8.125585444696403],
              [-38.505372703663966, -8.125530619990126],
              [-38.505345177011655, -8.125475591643614],
              [-38.505313721129106, -8.125441905212957],
              [-38.505278910096472, -8.125405501136898],
              [-38.505233774590948, -8.125350089560984],
              [-38.505196063597225, -8.125309882963615],
              [-38.505154900815675, -8.125271752562544],
              [-38.505119824262152, -8.125229830573408],
              [-38.505076916150401, -8.125208612584183],
              [-38.505115506987977, -8.125197443470748],
              [-38.505133328307963, -8.125172590822661],
              [-38.505145083129285, -8.125135429309092],
              [-38.505150374248231, -8.125113817693739],
              [-38.505162055941327, -8.125136354475357],
              [-38.505196213605934, -8.12518741100514],
              [-38.505237290045073, -8.125221923212907],
              [-38.50527691362295, -8.125256885910606],
              [-38.505321274066027, -8.125278105645595],
              [-38.50537151117382, -8.125318417914379],
              [-38.505409861339757, -8.12535554990224],
              [-38.505444586926444, -8.125387612162887],
              [-38.505480941501148, -8.12542365629352],
              [-38.50552700888349, -8.125459350418785],
              [-38.505568281421496, -8.125482013608963],
              [-38.505620622002539, -8.1255094842075],
              [-38.505666173900124, -8.12552138878859],
              [-38.505687683224536, -8.125523947584366],
              [-38.505647240444766, -8.125564963694433],
              [-38.505619214624062, -8.125621100391447],
              [-38.50560351289068, -8.125694076154954],
              [-38.505605496643717, -8.125779013089108],
              [-38.505606027194119, -8.125864671873535],
              [-38.505594304261443, -8.125949953956511],
              [-38.505570965773423, -8.126032779713483],
              [-38.505557565919595, -8.126079074900971],
              [-38.505547976735393, -8.126126731501252],
              [-38.5055305870981, -8.126169675109844],
              [-38.50551256372875, -8.126211261165501],
              [-38.505499052383733, -8.126274470756609],
              [-38.505517678340254, -8.126333829975623],
              [-38.505548209444129, -8.126381535345157],
              [-38.505596105178434, -8.126406739246855],
              [-38.505631877050497, -8.12639981791324],
              [-38.505683175558346, -8.126388845100976],
              [-38.505656816747802, -8.126417848191359],
              [-38.50561743421347, -8.126482473577459],
              [-38.505580163733619, -8.126527202064546],
              [-38.505541698951809, -8.126583597416341],
              [-38.505522484811635, -8.126633955876381],
              [-38.505499267152828, -8.126692178800177],
              [-38.505490583095693, -8.126741916895012],
              [-38.505504945978878, -8.126798738272749],
              [-38.5055315784318, -8.126842820820844],
              [-38.505554579203512, -8.126887622569422],
              [-38.505577480130455, -8.12693984126728],
              [-38.505601105230348, -8.126993688982138],
              [-38.505615479097401, -8.127041555612587],
              [-38.50561523212145, -8.127095012500899],
              [-38.505584494258407, -8.127142010226658],
              [-38.505596237677352, -8.127188335974415],
              [-38.505614320390485, -8.127246609104168],
              [-38.505644836400435, -8.127306706387794],
              [-38.505678910278696, -8.127352245206342],
              [-38.505716990006562, -8.127388020064545],
              [-38.505763715375799, -8.127405443650229],
              [-38.505806179201009, -8.127419063064558],
              [-38.505852910443863, -8.127431692686439],
              [-38.505891995306783, -8.127462041624822],
              [-38.505930985200614, -8.127495827624049],
              [-38.505966609523078, -8.127535398465964],
              [-38.506003596622271, -8.127573975987286],
              [-38.506056915393927, -8.127618271823101],
              [-38.506118147335307, -8.127649371255618],
              [-38.506178834333475, -8.127680741373924],
              [-38.506230718620834, -8.127710472658729],
              [-38.50628096551344, -8.127743096416724],
              [-38.506330402890427, -8.127769658895442],
              [-38.506376757845068, -8.127793142255843],
              [-38.506430732752399, -8.127820524299395],
              [-38.506469462244226, -8.127844631559999],
              [-38.506526793012206, -8.127874188514497],
              [-38.506586478069124, -8.127908451827093],
              [-38.506650890815465, -8.127936479671376],
              [-38.506721923504237, -8.127969671305609],
              [-38.506801764900501, -8.127999617334892],
              [-38.506881881160687, -8.128027483283953],
              [-38.506964452869184, -8.128051553206125],
              [-38.507044847977987, -8.12807408278783],
              [-38.50712535936853, -8.128075718057422],
              [-38.507206412623113, -8.128079615270527],
              [-38.507297435496142, -8.128095916485677],
              [-38.507386565441465, -8.128101361134231],
              [-38.507472621665329, -8.128096580951784],
              [-38.507518373107224, -8.12809401324736],
              [-38.507597425676934, -8.128100983332113],
              [-38.507677381652933, -8.128111482127965],
              [-38.50774942098932, -8.128138071803152],
              [-38.507823304736085, -8.128140874790679],
              [-38.507897478431566, -8.128129205776405],
              [-38.507962150884737, -8.128093193632116],
              [-38.508022114730359, -8.128047768759846],
              [-38.508079177108037, -8.127999717256699],
              [-38.508137509925959, -8.127951938637532],
              [-38.508195840306868, -8.127906149952874],
              [-38.508257345101342, -8.127862445496216],
              [-38.508322571353808, -8.127818835980591],
              [-38.508389781435362, -8.127785992655026],
              [-38.508469785819507, -8.127756692414119],
              [-38.508552056257166, -8.127730018006833],
              [-38.508639581564722, -8.127711400170853],
              [-38.508690156771458, -8.127698164807738],
              [-38.508783946424714, -8.127678559533971],
              [-38.508828709687748, -8.127667487984532],
              [-38.508876565956783, -8.127650812132757],
              [-38.508958760757416, -8.127611655153952],
              [-38.509037773196852, -8.127576926470645],
              [-38.509116874304731, -8.127543916468824],
              [-38.509190802157768, -8.127510357494925],
              [-38.509261101279122, -8.127475075537742],
              [-38.509337392650579, -8.127438624918957],
              [-38.509403962698222, -8.127409489182375],
              [-38.509468436782761, -8.127387134817296],
              [-38.509522749323374, -8.127360878745069],
              [-38.50956452634577, -8.127342205529724],
              [-38.509617403794763, -8.1273017467265],
              [-38.50965411810391, -8.127266243297672],
              [-38.509687922490613, -8.12723507806013],
              [-38.509719195299922, -8.127195678622286],
              [-38.509705842464037, -8.12712881838792],
              [-38.509674857497558, -8.127080660492311],
              [-38.509633520681952, -8.127035836843719],
              [-38.509580286951234, -8.126995973714845],
              [-38.509518786891412, -8.126961256383684],
              [-38.509445028156783, -8.126930413701341],
              [-38.509362078691218, -8.1269184644181],
              [-38.509285664897156, -8.126906342099051],
              [-38.509212970483382, -8.126896033296802],
              [-38.50913638527301, -8.126875589146259],
              [-38.509056074760331, -8.126858215858073],
              [-38.508979490776362, -8.12683677670678],
              [-38.50890616748454, -8.126821130415379],
              [-38.508823587633898, -8.126803844794571],
              [-38.508741924403076, -8.126779233641374],
              [-38.50866625585676, -8.126751463888871],
              [-38.508592502076006, -8.126716641169583],
              [-38.508520460611706, -8.12669195114581],
              [-38.508437787066086, -8.126677107543449],
              [-38.50836001257592, -8.126664350245342],
              [-38.508293327118402, -8.126639214395709],
              [-38.508239074406625, -8.12661635487038],
              [-38.508187360287486, -8.126595940615154],
              [-38.508130114932328, -8.126570816154143],
              [-38.508111799112228, -8.126554603073728],
              [-38.508161654964347, -8.126535668413974],
              [-38.508231463247689, -8.126530687499242],
              [-38.508311527326711, -8.126526713943335],
              [-38.508393588454823, -8.126522652333058],
              [-38.508476645190164, -8.126520943664847],
              [-38.508556622789889, -8.126513442322576],
              [-38.508639420529029, -8.12650078859933],
              [-38.508711706439506, -8.126473740272576],
              [-38.508773831714528, -8.126442066602694],
              [-38.508820812145679, -8.126399068117486],
              [-38.508863344949283, -8.126355973803888],
              [-38.508906973801423, -8.12630727277562],
              [-38.508952617615186, -8.126243740022929],
              [-38.508988914972051, -8.126177844226472],
              [-38.509030383012458, -8.126114577778953],
              [-38.509070758502965, -8.126054023572086],
              [-38.509098930805067, -8.126026379214876],
              [-38.509129670600615, -8.12597739124714],
              [-38.509163312605942, -8.125930396725696],
              [-38.509195328439162, -8.125877068588519],
              [-38.509215641768456, -8.125818118296175],
              [-38.509219983339165, -8.125756073360273],
              [-38.509212800439364, -8.125691300955804],
              [-38.509207227659893, -8.125646068157867],
              [-38.509199204426807, -8.125600561047001],
              [-38.509185918636398, -8.125553328995732],
              [-38.509175355502101, -8.125506462037761],
              [-38.509164069069392, -8.125457242450933],
              [-38.509148897235136, -8.125393455379697],
              [-38.509133990460306, -8.125335638469727],
              [-38.509107356590349, -8.125292460656844],
              [-38.509054825768693, -8.125271955080304],
              [-38.509039138328774, -8.125259001491184],
              [-38.50905462831868, -8.125210356928664],
              [-38.509065402128179, -8.125158540848838],
              [-38.50905640364499, -8.125093947155053],
              [-38.509049938250307, -8.125036321333516],
              [-38.509053093669749, -8.124979340286975],
              [-38.509062417465678, -8.124925622961234],
              [-38.509043046956599, -8.124881006675754],
              [-38.509047778188965, -8.124871786265913],
              [-38.509092208168113, -8.124835930375847],
              [-38.509134286482237, -8.124793197279512],
              [-38.509159863843003, -8.124734705596122],
              [-38.509164564303013, -8.124676098272168],
              [-38.509159086042089, -8.124627790213303],
              [-38.509163407927048, -8.124581936185795],
              [-38.509142218952711, -8.124539850371049],
              [-38.509140885213249, -8.124516964367198],
              [-38.509189347577497, -8.124524078101139],
              [-38.509225062970188, -8.124488754392921],
              [-38.509229666932718, -8.124434850462354],
              [-38.509221557228642, -8.124385815612134],
              [-38.509252469732481, -8.124343973551634],
              [-38.509299603383859, -8.1243240404741],
              [-38.50934310141772, -8.124308082901344],
              [-38.509391130893519, -8.124297919726011],
              [-38.509440867525306, -8.124302140496241],
              [-38.509455681349486, -8.124361766329391],
              [-38.509464957028527, -8.124422380458634],
              [-38.509461634194381, -8.124467783435515],
              [-38.50945947357166, -8.124527841063012],
              [-38.509457033939668, -8.124593415935397],
              [-38.509456892430208, -8.124635204661271],
              [-38.509452570687252, -8.124680968241689],
              [-38.509449319287384, -8.12474229088336],
              [-38.50946032014248, -8.124802454831348],
              [-38.509481760385668, -8.124861907748679],
              [-38.509509293731419, -8.124911870534747],
              [-38.509545652384006, -8.124944838953278],
              [-38.509586106437489, -8.124968947994264],
              [-38.509636916800375, -8.124986014246078],
              [-38.509692101237029, -8.124988975226747],
              [-38.50965429890217, -8.125023663294455],
              [-38.509621106929082, -8.125073914686418],
              [-38.50963111593726, -8.125128650305971],
              [-38.509656914264006, -8.125187203947117],
              [-38.509688612958513, -8.125245493341767],
              [-38.50970689816144, -8.125286942467435],
              [-38.509720006419435, -8.125330918013674],
              [-38.509731022036036, -8.12537896138473],
              [-38.50974357799641, -8.125429267915704],
              [-38.509756767701369, -8.125480931992529],
              [-38.509776764045, -8.125533508792955],
              [-38.509804570985978, -8.125582476918213],
              [-38.509830569671628, -8.125625563472703],
              [-38.509859017062233, -8.125670371567205],
              [-38.509880657051667, -8.12571490010181],
              [-38.509908287525256, -8.125759707209124],
              [-38.509937101085335, -8.125801983079636],
              [-38.509967918610684, -8.125838472424336],
              [-38.509999466908731, -8.125871163656614],
              [-38.510045975453636, -8.125917712086117],
              [-38.510100656370049, -8.125961737692728],
              [-38.510147103235354, -8.125984316215161],
              [-38.510194282736364, -8.126001558937277],
              [-38.510240375415691, -8.126016810398085],
              [-38.510291735055688, -8.126030168699204],
              [-38.510341736878964, -8.126040449986117],
              [-38.510391824870283, -8.126054530361529],
              [-38.510432108947981, -8.126069232096956],
              [-38.510439276701177, -8.126071773384052],
              [-38.510486193672776, -8.126080965515035],
              [-38.510530755342145, -8.12608626536357],
              [-38.510533659650541, -8.12608654021475],
              [-38.510592915088438, -8.126100902953535],
              [-38.510654441751448, -8.126113640277232],
              [-38.510711705081178, -8.126124020707364],
              [-38.510756444903301, -8.126132124763032],
              [-38.510811611328997, -8.126150100639423],
              [-38.510861332987787, -8.126166894083179],
              [-38.510914863921904, -8.126186224762012],
              [-38.510977214001308, -8.126193626350187],
              [-38.511037664374847, -8.126195688967289],
              [-38.511102819569018, -8.126210330082039],
              [-38.5111638003359, -8.126224152100409],
              [-38.511224676108604, -8.126249732766238],
              [-38.511269963314021, -8.126255576120164],
              [-38.511317697479903, -8.126264588235005],
              [-38.511378858819313, -8.126279224508512],
              [-38.511445293902582, -8.126286359576726],
              [-38.511490216505315, -8.126293468799108],
              [-38.511537770017199, -8.126301757054307],
              [-38.511588593109892, -8.126308511542842],
              [-38.511638057826431, -8.126312641281196],
              [-38.511686167227637, -8.126311613612264],
              [-38.511735552219591, -8.12630660757298],
              [-38.511784210941521, -8.126301691108168],
              [-38.511830973440993, -8.126288541213317],
              [-38.511874107543541, -8.126273397010806],
              [-38.511916157649779, -8.126253909798022],
              [-38.511971748323489, -8.12622132326003],
              [-38.512032689061627, -8.12619317528884],
              [-38.512091439185248, -8.126175064872953],
              [-38.512152464998209, -8.126151620498593],
              [-38.512197596888505, -8.126135755038582],
              [-38.512242087924754, -8.126124411414281],
              [-38.512305017597853, -8.126102868794776],
              [-38.512354421670594, -8.126082033568389],
              [-38.512388950060959, -8.126052406641817],
              [-38.512440255706643, -8.126035372675714],
              [-38.512482489159289, -8.126014347933722],
              [-38.512520552976923, -8.12598852422418],
              [-38.512554087026302, -8.125955458907486],
              [-38.512586186323858, -8.1259078290656],
              [-38.51261056198765, -8.125866973881077],
              [-38.512624961471651, -8.125819051471421],
              [-38.512611406669322, -8.125768743811971],
              [-38.512584873963107, -8.125716611558596],
              [-38.512559703138578, -8.125664209579011],
              [-38.512526350919245, -8.125621837987699],
              [-38.512479558550332, -8.12558460606207],
              [-38.5124551184906, -8.125528405960653],
              [-38.512456376880174, -8.12546346279497],
              [-38.512447537989047, -8.125416597966201],
              [-38.51242319559033, -8.125354699492148],
              [-38.512399561997498, -8.125307183765106],
              [-38.512364780108115, -8.125246086845156],
              [-38.512334612307896, -8.125197568308371],
              [-38.512304536048823, -8.125148416714085],
              [-38.512275809439551, -8.125109126024791],
              [-38.512240554733829, -8.125063767210142],
              [-38.512211283190702, -8.125024747220039],
              [-38.512174848056532, -8.124979748793828],
              [-38.512145846868563, -8.124942357263146],
              [-38.512115677696087, -8.124895014589072],
              [-38.512080612868139, -8.124842781623514],
              [-38.512042550793595, -8.124792082747673],
              [-38.512002405834302, -8.124737401473984],
              [-38.511966069713075, -8.124685619235818],
              [-38.511927736888197, -8.124633653695138],
              [-38.511886394141847, -8.1245937146812],
              [-38.511834609731736, -8.124556386248544],
              [-38.51177747429854, -8.124515433301072],
              [-38.511724055728777, -8.124478374250877],
              [-38.511670634545112, -8.124443486043534],
              [-38.511624836591814, -8.124409782858764],
              [-38.51159147035591, -8.124379169967236],
              [-38.511554309957241, -8.124333537455485],
              [-38.51151722863473, -8.124297583419384],
              [-38.511475770148657, -8.124278357778815],
              [-38.511462432169807, -8.124274090510905],
              [-38.511509577420874, -8.12424447880055],
              [-38.511555714687098, -8.124222735214383],
              [-38.511599320571214, -8.124192576530662],
              [-38.511622076470843, -8.124140051125281],
              [-38.511640482973021, -8.124081279296776],
              [-38.511648709623401, -8.124033801772276],
              [-38.511652851645934, -8.12398631934493],
              [-38.511652732860227, -8.123934399646242],
              [-38.511652437126898, -8.123878680743111],
              [-38.511651860666973, -8.123829926321459],
              [-38.511649469693218, -8.123780446104423],
              [-38.511642721460561, -8.12373123201418],
              [-38.511625820067835, -8.123671241927083],
              [-38.511633047589584, -8.123624305915527],
              [-38.5116421700057, -8.123586688753456],
              [-38.511662526503258, -8.123566723250713],
              [-38.511679337856044, -8.123551004745311],
              [-38.511711965046132, -8.123517124328298],
              [-38.511739970973053, -8.123476635353065],
              [-38.511766446056434, -8.123426013893184],
              [-38.511780569292128, -8.123381347455878],
              [-38.51179269647384, -8.123335955005393],
              [-38.511800831442514, -8.123289200981766],
              [-38.511801800334176, -8.123238548915918],
              [-38.511811228362191, -8.12317334419545],
              [-38.511826446386799, -8.123124066006708],
              [-38.511840216470162, -8.123071167992302],
              [-38.511888734889403, -8.123031789036695],
              [-38.511928088665385, -8.12298986642109],
              [-38.511945841542008, -8.12294610885054],
              [-38.5119557907745, -8.122900261519316],
              [-38.511953491405272, -8.122850057792233],
              [-38.511956463094791, -8.122794523707126],
              [-38.511961160599114, -8.122737996717271],
              [-38.511958888757448, -8.122664999068997],
              [-38.51196212412097, -8.122616611023068],
              [-38.511963736890259, -8.122558904454577],
              [-38.511963804835673, -8.122502643268469],
              [-38.511958608062677, -8.122446466220673],
              [-38.511946236489798, -8.122393627299621],
              [-38.511938126915894, -8.122344230673198],
              [-38.511940010026144, -8.122288152567942],
              [-38.511942981599532, -8.122232708933325],
              [-38.511942320661703, -8.122178708177367],
              [-38.511933126559747, -8.122125420805352],
              [-38.511915394927392, -8.122076464899697],
              [-38.511879045817572, -8.122035627362266],
              [-38.511850771005406, -8.12199823669024],
              [-38.511819245231273, -8.12194700293044],
              [-38.511779898799013, -8.121907789929269],
              [-38.511757259221518, -8.121864164809944],
              [-38.511748963113078, -8.12181892875784],
              [-38.511741307197106, -8.121769713576784],
              [-38.511723842444368, -8.121725280594305],
              [-38.511694753697462, -8.121685446727195],
              [-38.511650604636451, -8.121639534490315],
              [-38.511614790149174, -8.121607019175883],
              [-38.511581697642008, -8.121575411647166],
              [-38.511533088576449, -8.121539715144822],
              [-38.511486019420317, -8.121506643599147],
              [-38.511448382254855, -8.121480186380731],
              [-38.511426348048388, -8.121461436326291],
              [-38.511452483692814, -8.121466261665701],
              [-38.511519799990012, -8.121494654001694],
              [-38.511586119588181, -8.121521597895798],
              [-38.511631937445166, -8.121538567437614],
              [-38.511692454077711, -8.121560348576633],
              [-38.511757704906103, -8.121570376600229],
              [-38.511825857612344, -8.121582669399276],
              [-38.511875956375071, -8.121587433002066],
              [-38.511927693349229, -8.121588580475219],
              [-38.511975340807595, -8.121593793387232],
              [-38.512040504704373, -8.121600655434861],
              [-38.51208606306151, -8.121607041708051],
              [-38.512142773224177, -8.12162402425186],
              [-38.512177939274302, -8.121667393009702],
              [-38.512217728544172, -8.121715742177299],
              [-38.512268961480906, -8.121758497018787],
              [-38.51231132155381, -8.121782608067637],
              [-38.512353774038033, -8.121805362439957],
              [-38.51240094291299, -8.121831107381558],
              [-38.512451823502033, -8.121864997463248],
              [-38.512502883455703, -8.121900696798038],
              [-38.512556845408511, -8.121938570455669],
              [-38.512603995659134, -8.121979782680787],
              [-38.512640608640325, -8.122027675745965],
              [-38.51268076805782, -8.122070236374453],
              [-38.512712834532813, -8.12212481745329],
              [-38.512725380650295, -8.122183535959735],
              [-38.512762537187491, -8.122232334188311],
              [-38.512796894722122, -8.122268827562324],
              [-38.512797009414292, -8.122324274904697],
              [-38.512821992225504, -8.122381922871016],
              [-38.512859691879953, -8.122431988073116],
              [-38.512911663230447, -8.122464703529872],
              [-38.512953122266723, -8.122483386306373],
              [-38.512998467266321, -8.122516274726614],
              [-38.513037546912962, -8.122551055141436],
              [-38.513100510625982, -8.122576095291029],
              [-38.513150600490874, -8.122588366267793],
              [-38.513198064591037, -8.122595206964622],
              [-38.513244351090869, -8.122600056298804],
              [-38.513292091750806, -8.12260336967924],
              [-38.513338925155907, -8.122606320160264],
              [-38.51340572832413, -8.122608842253733],
              [-38.51347027097659, -8.122604125456158],
              [-38.513516392604444, -8.122595135373048],
              [-38.51356142576271, -8.122585510817972],
              [-38.513622984024046, -8.122571654754321],
              [-38.513676281525882, -8.122558421974659],
              [-38.513735356360563, -8.12257169859218],
              [-38.513798332750348, -8.122586246193844],
              [-38.513866124046366, -8.122597362353456],
              [-38.513914865243599, -8.122598958264044],
              [-38.513983750780369, -8.12260591490905],
              [-38.514030952711032, -8.122604343139621],
              [-38.514078875683154, -8.12260702348029],
              [-38.514126428292961, -8.122615763672323],
              [-38.51419596074895, -8.122613133118071],
              [-38.51424326225721, -8.122604234812444],
              [-38.514291288938686, -8.122596151436751],
              [-38.514358004829774, -8.122595778798564],
              [-38.514425353714543, -8.122597396853809],
              [-38.514489167709954, -8.122594759425734],
              [-38.51454001616397, -8.122580076373955],
              [-38.514582339048388, -8.122559775107744],
              [-38.514621671760885, -8.122535128563186],
              [-38.514680063495121, -8.122512585145827],
              [-38.514730302182635, -8.122476554614588],
              [-38.514779456518745, -8.122436452433083],
              [-38.514821536281907, -8.122391819198894],
              [-38.514847178787363, -8.122353859829975],
              [-38.514874848503077, -8.122290666701403],
              [-38.514886704298263, -8.122244188383915],
              [-38.514890030509633, -8.122195438592582],
              [-38.514886818848275, -8.122148761409292],
              [-38.514883699802255, -8.122100546648268],
              [-38.514879489573467, -8.122053958726813],
              [-38.514863661123357, -8.12200717605133],
              [-38.514838656316293, -8.121967618615308],
              [-38.514807926223327, -8.121933752843383],
              [-38.514770484331478, -8.121895627812014],
              [-38.514731145094544, -8.121850173884054],
              [-38.514688811032237, -8.121804264120247],
              [-38.514655451595971, -8.121767952992268],
              [-38.514616739728353, -8.12172919326807],
              [-38.514567679443893, -8.121691054363358],
              [-38.514529498841881, -8.121663420890226],
              [-38.514493410060446, -8.121632352724237],
              [-38.514451434777058, -8.121590061455549],
              [-38.514409008918058, -8.121545056078308],
              [-38.514365503079468, -8.121492360968544],
              [-38.514333234446703, -8.121454784787073],
              [-38.51429978549789, -8.121417478551409],
              [-38.514254276900864, -8.121369665461659],
              [-38.514209855693096, -8.121323391353155],
              [-38.514178041584863, -8.121285272989798],
              [-38.514133711490061, -8.121238727625908],
              [-38.514090648798508, -8.121194987789902],
              [-38.514053668281861, -8.121150712498107],
              [-38.51401778798386, -8.121097302841653],
              [-38.51399426324059, -8.121034772260376],
              [-38.513977704861858, -8.120991244965104],
              [-38.513960691119678, -8.120948983456737],
              [-38.513938596068627, -8.120905268644197],
              [-38.513911964758471, -8.120859739368711],
              [-38.513884337173245, -8.120812399857227],
              [-38.513859428305615, -8.120768681680474],
              [-38.513831070665731, -8.120724597574302],
              [-38.513803985970277, -8.120678705942286],
              [-38.513782170219379, -8.120629202513333],
              [-38.513760619815976, -8.120585488343133],
              [-38.513741254394809, -8.120536349648326],
              [-38.513711376458176, -8.120473359202713],
              [-38.513687099418242, -8.12043262669539],
              [-38.513657922942258, -8.120390169740732],
              [-38.513636459305737, -8.120349802401163],
              [-38.513619084616344, -8.120305821857167],
              [-38.513597288606228, -8.120239946589871],
              [-38.513581369923955, -8.120192892408589],
              [-38.513561723962837, -8.120150627740902],
              [-38.513534818149509, -8.12010699761314],
              [-38.513499015494958, -8.120064623182312],
              [-38.513452217328329, -8.120032637612963],
              [-38.51341231300885, -8.120004459272664],
              [-38.513397849702088, -8.119954874162641],
              [-38.513395934127509, -8.119887304057924],
              [-38.513387094991899, -8.119840620141748],
              [-38.5133679102046, -8.119792295718112],
              [-38.513342460433229, -8.119745591944262],
              [-38.513317552338421, -8.119701331026802],
              [-38.513280318790088, -8.11964122623186],
              [-38.513256495355847, -8.119600765601655],
              [-38.513226139645006, -8.119557764497126],
              [-38.513188988177454, -8.119504895971286],
              [-38.513183189343003, -8.119496296075294],
              [-38.513152472861115, -8.11945139503773],
              [-38.513121288490979, -8.119418161775924],
              [-38.513108234501701, -8.11940430697277],
              [-38.513067797314299, -8.119366449577251],
              [-38.51303073654389, -8.119313671599439],
              [-38.513000936602253, -8.119261445017829],
              [-38.512947580794176, -8.119248084710824],
              [-38.512910048117391, -8.119210321228195],
              [-38.512920373166502, -8.119153710488057],
              [-38.512925107725458, -8.119141595554288],
              [-38.512944319353061, -8.119092231623039],
              [-38.512952092309362, -8.119044391694207],
              [-38.512939440204548, -8.118998336374505],
              [-38.512935644170419, -8.118984854451218],
              [-38.512925069933253, -8.118946942317475],
              [-38.512913606976163, -8.118893290432652],
              [-38.512911979357831, -8.118888132708431],
              [-38.51289778321928, -8.118842889610335],
              [-38.51286960418696, -8.1188014287752],
              [-38.512838879288303, -8.118763582981098],
              [-38.512803368309299, -8.118705289254605],
              [-38.512763206902264, -8.118664628144263],
              [-38.512706611214234, -8.118628289067727],
              [-38.512682794325372, -8.118582491735294],
              [-38.512664418085187, -8.118541223525181],
              [-38.512660737471975, -8.118507299535057],
              [-38.512685580387412, -8.11853021368348],
              [-38.512722677891645, -8.118552418879565],
              [-38.512781574496586, -8.118562439108899],
              [-38.512832321143826, -8.118556529986011],
              [-38.512890217667554, -8.118567905786625],
              [-38.512948820182977, -8.118596016086068],
              [-38.513004233614922, -8.118634253212562],
              [-38.513051560292048, -8.118679445481936],
              [-38.513090096398663, -8.118713049347894],
              [-38.51314687574186, -8.118747670000777],
              [-38.513204680446769, -8.118759950171166],
              [-38.513263332452091, -8.118746995173717],
              [-38.513301575524174, -8.118722437884895],
              [-38.513333872742209, -8.118660607164797],
              [-38.513358341886715, -8.118617038436069],
              [-38.513384631834306, -8.118568949269998],
              [-38.513403297242156, -8.118520850989379],
              [-38.513415522236642, -8.118469307825663],
              [-38.513426742028209, -8.118423371498601],
              [-38.513440231131277, -8.118377347430631],
              [-38.513460180229956, -8.118318486863901],
              [-38.513469500083133, -8.118267392486896],
              [-38.513462938875278, -8.118213475113309],
              [-38.513458829715532, -8.11815847524273],
              [-38.51345399419349, -8.118103655408612],
              [-38.513454243538902, -8.118047303962403],
              [-38.513459294234615, -8.11799873714595],
              [-38.513466721661118, -8.117936243524431],
              [-38.513473057799594, -8.117875467191043],
              [-38.513488104951982, -8.117816962571551],
              [-38.51350415696924, -8.11775312246955],
              [-38.513523904898463, -8.117710543058493],
              [-38.513552363401729, -8.117670597167391],
              [-38.513582098962921, -8.117625406569465],
              [-38.513604940389222, -8.117576680096278],
              [-38.51362987883094, -8.117520177233363],
              [-38.513659894290981, -8.117468655305622],
              [-38.513690636104798, -8.117416953338033],
              [-38.51371466445066, -8.117362439330646],
              [-38.513725076830553, -8.11730854222739],
              [-38.513737218693329, -8.117250576837794],
              [-38.513744364725113, -8.117195590406007],
              [-38.513751689353803, -8.117143046397764],
              [-38.513760367342151, -8.117097287923555],
              [-38.513759612859467, -8.117045367433393],
              [-38.513762404306981, -8.116988476303515],
              [-38.513754296318226, -8.116937632459646],
              [-38.513753329085525, -8.116911671509925],
              [-38.513751726042543, -8.116886071609949],
              [-38.513780448567033, -8.116928437556766],
              [-38.513801250690406, -8.116990422183118],
              [-38.513813718591173, -8.117038738568844],
              [-38.513820007750091, -8.117092474712233],
              [-38.513831928655463, -8.11714278039387],
              [-38.513849478590679, -8.117191916916239],
              [-38.513858769514336, -8.117240319960203],
              [-38.513869599907252, -8.11729170976597],
              [-38.513871445140921, -8.117342365226852],
              [-38.513870749522994, -8.117392474940775],
              [-38.513870145107475, -8.117442222954566],
              [-38.513870972101472, -8.117509339508214],
              [-38.513877995462828, -8.1175563830607],
              [-38.513874310152559, -8.117601785688198],
              [-38.513867267075327, -8.117646641593238],
              [-38.513865031985461, -8.11769376454442],
              [-38.513873417630514, -8.117740176557353],
              [-38.513899038776152, -8.117795292562182],
              [-38.513946564073585, -8.11782628396408],
              [-38.513998381354632, -8.117835752849112],
              [-38.514055482103558, -8.117829489342421],
              [-38.514113315734896, -8.117817618662814],
              [-38.514174433860141, -8.117791731795183],
              [-38.514228290095737, -8.117766198063432],
              [-38.514282865733115, -8.117746273221817],
              [-38.514336690885258, -8.117746608781557],
              [-38.514362960509146, -8.117790871264209],
              [-38.514391588045569, -8.117836855150225],
              [-38.514441409638003, -8.117845326624728],
              [-38.514496863200471, -8.11785018671854],
              [-38.514547891606036, -8.117836317912717],
              [-38.514582789888301, -8.117800269069045],
              [-38.51462287540955, -8.11775301032586],
              [-38.514654059460391, -8.117710896746509],
              [-38.514681428422662, -8.117671130375827],
              [-38.514715609544027, -8.117627573126979],
              [-38.514763659610303, -8.117599499737988],
              [-38.514821139129317, -8.117580120995211],
              [-38.514878136479936, -8.117584349709011],
              [-38.514926696742478, -8.117584950273759],
              [-38.514979520717638, -8.117587545861104],
              [-38.515025622758252, -8.117594384691966],
              [-38.515069571967146, -8.117579874220255],
              [-38.515118794180857, -8.117558314746317],
              [-38.515171120397952, -8.117521834338714],
              [-38.515211105405001, -8.117482625663092],
              [-38.515255362682552, -8.117438266289939],
              [-38.515303146459331, -8.11740512718978],
              [-38.515356821260355, -8.117379412180702],
              [-38.515401267743108, -8.117328630904183],
              [-38.515431726837718, -8.117285430967849],
              [-38.515456285247396, -8.117242857173474],
              [-38.515483491312807, -8.117187442305402],
              [-38.515514962381296, -8.117132937032023],
              [-38.515539438673386, -8.117083126951936],
              [-38.515562746136617, -8.117023998895752],
              [-38.515573341856168, -8.116968383354592],
              [-38.5155792176405, -8.116912762198163],
              [-38.515579400693255, -8.116911496084082],
              [-38.51558627481409, -8.116855966567144],
              [-38.515587325810301, -8.116812098466065],
              [-38.515587515905629, -8.116804952962822],
              [-38.515592222861486, -8.11674001383118],
              [-38.515592624720973, -8.116707632396041],
              [-38.515603396544194, -8.116656539675617],
              [-38.515611843805956, -8.116651665300651],
              [-38.515643543890306, -8.116633250741595],
              [-38.515677721667792, -8.11666811551526],
              [-38.515727528964639, -8.116688436056908],
              [-38.515780555597594, -8.116673303129277],
              [-38.515817350373169, -8.116645125768462],
              [-38.515819530751216, -8.116643500219908],
              [-38.515858238468432, -8.116609626636025],
              [-38.515889235676227, -8.116571763982074],
              [-38.515919964472367, -8.116530644724385],
              [-38.515958860851292, -8.116490801503652],
              [-38.515998376467763, -8.116464436404026],
              [-38.516022214900559, -8.116416615479627],
              [-38.516024545362697, -8.116365422259616],
              [-38.516009811695305, -8.116314027855736],
              [-38.515989551155755, -8.116254305273319],
              [-38.515979083905904, -8.116202644582168],
              [-38.515958004841977, -8.116144277809854],
              [-38.515938745685432, -8.116082114203927],
              [-38.515936172747672, -8.116032543297118],
              [-38.515935966266376, -8.115977548066105],
              [-38.515929606201553, -8.115906987729009],
              [-38.515915141181821, -8.115858669019083],
              [-38.515881512708624, -8.115819915471667],
              [-38.515834280785377, -8.11577137690433],
              [-38.515800466352047, -8.115736331673292],
              [-38.51576185163529, -8.115692597313744],
              [-38.51573659168276, -8.115638929079484],
              [-38.515723118098457, -8.115596490940796],
              [-38.515723539868304, -8.115547466307499],
              [-38.515733943800065, -8.115500353040854],
              [-38.51575243297588, -8.115447460440645],
              [-38.515716239882167, -8.115428060620209],
              [-38.515755363745683, -8.115425574473589],
              [-38.515810673908575, -8.11539832364093],
              [-38.515845939046017, -8.115358837915528],
              [-38.515882040896649, -8.115302800413625],
              [-38.515898866889103, -8.115274418396156],
              [-38.515919248943341, -8.115232563214169],
              [-38.515944175864988, -8.115185286295157],
              [-38.515966825365993, -8.115144881041291],
              [-38.515992017327036, -8.115103664736507],
              [-38.516017757784084, -8.11505919279927],
              [-38.516044224808169, -8.115014359914136],
              [-38.516072506851408, -8.114969800540122],
              [-38.516113869360559, -8.114916935092795],
              [-38.516151868160769, -8.114868497806917],
              [-38.516189505180158, -8.114818974659695],
              [-38.516226047896403, -8.114773701466254],
              [-38.516273022910809, -8.114733415533815],
              [-38.516322179146385, -8.11469078042594],
              [-38.516378222665359, -8.114657379622273],
              [-38.516438436070203, -8.114628506375155],
              [-38.516481570705025, -8.114611733453209],
              [-38.516546484759274, -8.114598966277105],
              [-38.516610667698593, -8.114590359028348],
              [-38.516676205554376, -8.114587270966895],
              [-38.516723136738236, -8.114583618106083],
              [-38.516768703492943, -8.114582405833117],
              [-38.516816719131128, -8.114582734148916],
              [-38.516862748041682, -8.114574557586742],
              [-38.516915704979809, -8.114541514853693],
              [-38.516947154896279, -8.114504376255923],
              [-38.516976157759601, -8.114464159375336],
              [-38.517018355366211, -8.114395917924337],
              [-38.517052451553681, -8.114347023655418],
              [-38.517086706915691, -8.114316853198146],
              [-38.517119495958646, -8.114298439798667],
              [-38.517082503542206, -8.114340365813275],
              [-38.517047487698264, -8.114399299199965],
              [-38.51701438746273, -8.114450275051846],
              [-38.516990374542885, -8.114492397350611],
              [-38.51698944451212, -8.114511119873736],
              [-38.51703632271667, -8.114475808608383],
              [-38.51707884764204, -8.114437507250214],
              [-38.517108632409808, -8.11442659783159],
              [-38.517099528568863, -8.114449290566764],
              [-38.517065166770458, -8.114492576481684],
              [-38.517022915111973, -8.114530064091177],
              [-38.51697984567496, -8.114568274344528],
              [-38.516925527334628, -8.114601225019134],
              [-38.516879667101151, -8.114620165611722],
              [-38.51682754769255, -8.114635932944021],
              [-38.516768449561461, -8.114643008570043],
              [-38.516706623867059, -8.114653879947538],
              [-38.51664099469253, -8.114657420166365],
              [-38.516572272506558, -8.11466674565045],
              [-38.516506547048785, -8.114674898800006],
              [-38.516446800994295, -8.114692466075393],
              [-38.516390492526639, -8.11471971581817],
              [-38.516347157307415, -8.114752408094374],
              [-38.516308080472221, -8.114791527534825],
              [-38.516273632986476, -8.114830471564089],
              [-38.516239275504276, -8.114870048862898],
              [-38.51619782592676, -8.114919838843148],
              [-38.516167095342247, -8.11496258627275],
              [-38.516131002759998, -8.115010935371602],
              [-38.516102078962888, -8.115060830672279],
              [-38.516076419759166, -8.115113262512923],
              [-38.516053578562754, -8.115162170060655],
              [-38.516033466772299, -8.11520574416496],
              [-38.516006180660618, -8.115252385120572],
              [-38.515981336174505, -8.11530662696714],
              [-38.515953784674608, -8.115347478656824],
              [-38.515924423013473, -8.115383986483307],
              [-38.515895689033606, -8.115426917165362],
              [-38.515862692001782, -8.115467310113759],
              [-38.515834121787158, -8.115525075159102],
              [-38.515821159914147, -8.115585934134542],
              [-38.515816193385319, -8.115640290050134],
              [-38.51585643910618, -8.115686378102755],
              [-38.515895982080075, -8.11571292762412],
              [-38.515938981050546, -8.115733782755726],
              [-38.515981681635317, -8.11577643652808],
              [-38.51600750712543, -8.115812648131117],
              [-38.516032041848746, -8.115865591870808],
              [-38.516044959827269, -8.115917255473491],
              [-38.516046803065052, -8.115969810438836],
              [-38.516052278960267, -8.116020741579682],
              [-38.516062650902732, -8.11607620115217],
              [-38.51607584043601, -8.11612849824265],
              [-38.516083308789234, -8.116183140293938],
              [-38.516101398164409, -8.116236799989959],
              [-38.516112413392385, -8.116285657309238],
              [-38.516130234151362, -8.116336241307543],
              [-38.516148874746676, -8.1163843840677],
              [-38.516197726332024, -8.11636897476512],
              [-38.516234981923652, -8.116334918512068],
              [-38.516271687276479, -8.116305565120253],
              [-38.516304316619859, -8.116269061153403],
              [-38.516340569420926, -8.116238621789753],
              [-38.516351190765612, -8.116237368071635],
              [-38.516357519739564, -8.11625808916058],
              [-38.516398334958055, -8.116283464268712],
              [-38.516452622424666, -8.116276563903847],
              [-38.516500131718914, -8.116245052463011],
              [-38.516537299534491, -8.116208463411734],
              [-38.516570568329733, -8.116168522993771],
              [-38.516600827403316, -8.11614060915441],
              [-38.516654496986725, -8.116119054757334],
              [-38.516699632017975, -8.116099480175217],
              [-38.516749222122272, -8.116073669666237],
              [-38.516799079444553, -8.116052110725498],
              [-38.51684774003251, -8.116044570463631],
              [-38.516898574722987, -8.11604065086639],
              [-38.51694494288742, -8.116052555116175],
              [-38.516982123311116, -8.116081363042836],
              [-38.516996807594829, -8.116098204584608],
              [-38.516947281133916, -8.116070738800195],
              [-38.516890099936354, -8.116068590571476],
              [-38.516828354525863, -8.116088055017114],
              [-38.516785843336727, -8.11611468800565],
              [-38.516743783062772, -8.11614376373627],
              [-38.516696195461002, -8.116164873101415],
              [-38.51664577837861, -8.116199094684156],
              [-38.516604552186287, -8.116213789475545],
              [-38.516575202514957, -8.116240257157703],
              [-38.516528143172884, -8.116275025424082],
              [-38.516491438489034, -8.116303836127416],
              [-38.516442536522476, -8.116361396170849],
              [-38.516391941645047, -8.116392451678193],
              [-38.516340340741777, -8.116429837643775],
              [-38.516299802036954, -8.11647682468311],
              [-38.516272527328766, -8.1165138777223],
              [-38.516249057940513, -8.116556724225445],
              [-38.516224586638181, -8.116602464009654],
              [-38.516202201151529, -8.116649743958614],
              [-38.516180719009654, -8.116700643070297],
              [-38.516155877584218, -8.116752261807475],
              [-38.516125419256674, -8.11679491909311],
              [-38.516090694614284, -8.116837752210863],
              [-38.5160583145597, -8.116893432334676],
              [-38.516015421653577, -8.116935532119482],
              [-38.515984609643915, -8.116970319624254],
              [-38.515958580829952, -8.117028087689686],
              [-38.515940190159647, -8.117074467845859],
              [-38.51591790187598, -8.117116320753778],
              [-38.515895347455789, -8.117153017564204],
              [-38.515891436744425, -8.117159435026448],
              [-38.515865132675877, -8.117219644968003],
              [-38.515852760180344, -8.117243057396621],
              [-38.515840842498015, -8.117265656294991],
              [-38.515824901780377, -8.117312672522877],
              [-38.515813319115267, -8.117358698956815],
              [-38.515788394737598, -8.11740371455044],
              [-38.515757035463444, -8.117440672237706],
              [-38.515724390590023, -8.117490020354115],
              [-38.515684853402455, -8.117534294942645],
              [-38.515645680795302, -8.117577303628583],
              [-38.515610694671949, -8.117611000706939],
              [-38.515605424055515, -8.117616059764003],
              [-38.515568983252066, -8.117651745057664],
              [-38.515525458737741, -8.117690587748706],
              [-38.515489742439641, -8.117727630680335],
              [-38.515474159422098, -8.11777907947665],
              [-38.515468030267449, -8.117818871179846],
              [-38.51541486670569, -8.117796647108461],
              [-38.515356319752463, -8.117797843759227],
              [-38.515288681458301, -8.117811150182469],
              [-38.51524137717135, -8.11782285266221],
              [-38.515186174645422, -8.117835721611604],
              [-38.515134502745383, -8.117856373651939],
              [-38.515081178573084, -8.117892400601781],
              [-38.515034205232219, -8.117930877319314],
              [-38.514983246980876, -8.117961932198899],
              [-38.51492411915396, -8.117993339148015],
              [-38.51486189923174, -8.118029717277876],
              [-38.514805853379379, -8.118064565066458],
              [-38.514747729293802, -8.118091541023837],
              [-38.514683231560198, -8.118135062137737],
              [-38.514620364817311, -8.118180936943409],
              [-38.514552870945522, -8.11822508762892],
              [-38.514490923912668, -8.118260923309595],
              [-38.514467485728893, -8.118277538574356],
              [-38.514436325618696, -8.118299662216081],
              [-38.514377104436107, -8.118333149370292],
              [-38.514314788649216, -8.118373688099087],
              [-38.514255287643572, -8.118413416105502],
              [-38.514194608269555, -8.118451785912756],
              [-38.514135918068547, -8.118496580193383],
              [-38.514081768985776, -8.118539118578379],
              [-38.514036334515936, -8.118580943739248],
              [-38.513996792907776, -8.118628655324546],
              [-38.51396532162191, -8.118683070005581],
              [-38.51394764689276, -8.118737682059097],
              [-38.51393033762416, -8.11879030459844],
              [-38.513909305109017, -8.118844369927164],
              [-38.513901878595441, -8.118906139940187],
              [-38.513894995384121, -8.11896899602829],
              [-38.513888298057161, -8.119028234248294],
              [-38.513881878905998, -8.119082588379237],
              [-38.513866835884784, -8.119137655830114],
              [-38.513865740573181, -8.119142719847567],
              [-38.513855335655045, -8.119190375752448],
              [-38.513849916253484, -8.119243645648986],
              [-38.513854664219821, -8.119295842259866],
              [-38.513878195164217, -8.119353126624999],
              [-38.513902908280009, -8.119408603355852],
              [-38.513929533412941, -8.119459197947508],
              [-38.513949973680326, -8.119520277612992],
              [-38.513964441393576, -8.119566244623199],
              [-38.51398045413184, -8.119610585337279],
              [-38.514000644291173, -8.119653212445547],
              [-38.51401593273674, -8.119696014605665],
              [-38.514030124025126, -8.119745418469],
              [-38.514053306404527, -8.119790672262006],
              [-38.514074124892211, -8.119839179512669],
              [-38.514096758429808, -8.119887960285732],
              [-38.514123295347716, -8.119936474360005],
              [-38.514154008822793, -8.119983998442979],
              [-38.514186638446439, -8.120023203204765],
              [-38.514222261157826, -8.120064311033053],
              [-38.514253799179812, -8.120105504436687],
              [-38.514297315080739, -8.120149697053945],
              [-38.514339652520221, -8.120192621929686],
              [-38.514376457536315, -8.120231831659456],
              [-38.514408639442827, -8.120265970545784],
              [-38.514412174965486, -8.120269683305242],
              [-38.514449707231414, -8.120307989374229],
              [-38.514478991179786, -8.120336607212117],
              [-38.514483977698831, -8.120341407128562],
              [-38.514531325054499, -8.120369684698744],
              [-38.514583865349735, -8.120382048861787],
              [-38.514642767602503, -8.120387636685837],
              [-38.514696404621773, -8.12039376093761],
              [-38.51474850715347, -8.120392737625744],
              [-38.5148016324612, -8.120371454223545],
              [-38.514852572693073, -8.120355685796737],
              [-38.514906779182724, -8.120341097135],
              [-38.514951186016219, -8.120323692768981],
              [-38.514990068871917, -8.120295517998617],
              [-38.515028679307775, -8.120267433351854],
              [-38.515063926631932, -8.120243234141292],
              [-38.515052434003842, -8.120289712905738],
              [-38.515035474416017, -8.120353551987876],
              [-38.515028404595348, -8.120420749585085],
              [-38.515022168619943, -8.1204738376246],
              [-38.515010208569386, -8.120531622360613],
              [-38.514999981586385, -8.12058235388559],
              [-38.514990389113429, -8.120633809784128],
              [-38.514997135340067, -8.120685013793933],
              [-38.515010685970012, -8.120738939478558],
              [-38.515037849316315, -8.120795052235827],
              [-38.515071376252379, -8.120843031870848],
              [-38.515109173019525, -8.12088794121375],
              [-38.515145799131659, -8.120925070265827],
              [-38.515184152896033, -8.120959668709791],
              [-38.515226045675107, -8.12099508543573],
              [-38.515272736193069, -8.121041362141257],
              [-38.515309733870076, -8.121071436347012],
              [-38.515365802990956, -8.121093211651505],
              [-38.515416098914777, -8.121084949928239],
              [-38.515472013722849, -8.121084021519938],
              [-38.515519099557153, -8.121103434351294],
              [-38.515543368693201, -8.121150950670312],
              [-38.51556200636945, -8.12120162611434],
              [-38.515606375009213, -8.121216151280791],
              [-38.51560610475444, -8.121441648421369],
              [-38.515631458593468, -8.121492965021723],
              [-38.515662612438597, -8.121551976964176],
              [-38.51568643676741, -8.121591985210102],
              [-38.515710987035895, -8.121632175223095],
              [-38.515737986988071, -8.121673272672114],
              [-38.51575472300388, -8.121720237303787],
              [-38.515769639524777, -8.121770636955569],
              [-38.515781826367537, -8.121826550946595],
              [-38.515797186119912, -8.12188581544552],
              [-38.515811005186499, -8.121943088161501],
              [-38.515818832197766, -8.122001439175289],
              [-38.515826115788315, -8.122058794567637],
              [-38.515832763888469, -8.122116239656053],
              [-38.515838773680422, -8.122176126195658],
              [-38.515848329537675, -8.122230951627751],
              [-38.515856794217015, -8.122287403902035],
              [-38.515862447763809, -8.122341591525583],
              [-38.51586709364026, -8.122403466391489],
              [-38.515872011174913, -8.122465884293893],
              [-38.515881009206204, -8.122531744235868],
              [-38.515889198660652, -8.122590638392582],
              [-38.515889573945998, -8.122656216719662],
              [-38.515886592503108, -8.122720343817205],
              [-38.515878525813761, -8.122786273909739],
              [-38.515870917627211, -8.122848315100583],
              [-38.515866304173528, -8.122910902567343],
              [-38.515863885652927, -8.122959743903584],
              [-38.515888601948738, -8.123012959230124],
              [-38.515947757633299, -8.123034647689428],
              [-38.516016670491808, -8.123018900514392],
              [-38.516064172949427, -8.122993811284024],
              [-38.516095333138416, -8.122971868443079],
              [-38.516113593146038, -8.122959045937295],
              [-38.516164732318892, -8.122928986144959],
              [-38.516181262183025, -8.122920774643532],
              [-38.5162172279339, -8.122903179214147],
              [-38.516269352885942, -8.122883703493281],
              [-38.516323109947884, -8.122865676941391],
              [-38.516376777102131, -8.122846926657163],
              [-38.516424452289911, -8.122829164220049],
              [-38.516467494237034, -8.122814743005108],
              [-38.516506996721461, -8.122799865318941],
              [-38.516542502542457, -8.122787334639174],
              [-38.516583277414405, -8.122771101672976],
              [-38.516612901948236, -8.12274291575949],
              [-38.51659561359255, -8.122778171603468],
              [-38.516577966052161, -8.122810170738468],
              [-38.516567658266979, -8.122852671053973],
              [-38.516587382991112, -8.122905337699764],
              [-38.516649783569186, -8.12294611533944],
              [-38.516676279871561, -8.122953111634731],
              [-38.516634793977246, -8.122956680448633],
              [-38.516560725693928, -8.122957044722112],
              [-38.516498087740011, -8.122962940151046],
              [-38.516446755789474, -8.123002406791812],
              [-38.516418567621308, -8.123043890879334],
              [-38.516384933288144, -8.123085368491898],
              [-38.516350484514085, -8.123124764731402],
              [-38.516317496704303, -8.123156926523919],
              [-38.516274428944733, -8.123192875325506],
              [-38.516233183278558, -8.123223218248658],
              [-38.516185126872394, -8.123256085732462],
              [-38.516130996926698, -8.123282252521467],
              [-38.516079046197326, -8.123307788728772],
              [-38.516023264730535, -8.123348697254897],
              [-38.515982188759132, -8.123388899647113],
              [-38.515947110494132, -8.123423320225847],
              [-38.515910033129138, -8.123459547467705],
              [-38.515870959264149, -8.123495410521363],
              [-38.51583242773264, -8.123533173713227],
              [-38.515792712289283, -8.123574191772869],
              [-38.515750263998633, -8.123623347277608],
              [-38.515717435415254, -8.123674051914783],
              [-38.5156935017961, -8.123725128945065],
              [-38.51566439929875, -8.123772039017718],
              [-38.515628760091175, -8.123820026733176],
              [-38.515603216457535, -8.123851202347224],
              [-38.515596398878358, -8.123859425384698],
              [-38.515563207611329, -8.123909767765831],
              [-38.515536726229342, -8.123965997528568],
              [-38.515515605664248, -8.124017711060469],
              [-38.515513601034044, -8.124024130782367],
              [-38.515497654434164, -8.124075760021524],
              [-38.515488977636736, -8.124120523546685],
              [-38.515486785825992, -8.124131646561811],
              [-38.515481008010887, -8.124181117055112],
              [-38.51548431181461, -8.124226708916778],
              [-38.51550556814454, -8.124288874916054],
              [-38.515547174764691, -8.124336140527042],
              [-38.515599685892568, -8.124373288466451],
              [-38.51560258835071, -8.124375100966789],
              [-38.515639593826926, -8.124398933961601],
              [-38.515688052976408, -8.124408850942825],
              [-38.515737051650596, -8.124423019815803],
              [-38.515780596021116, -8.124443966109672],
              [-38.515789118608346, -8.124452116954247],
              [-38.515814595819251, -8.124476388474966],
              [-38.515853780656251, -8.124499500427044],
              [-38.515871469136229, -8.124509199865305],
              [-38.51589831949736, -8.124523794625381],
              [-38.51594149820825, -8.124546911321737],
              [-38.515995119334633, -8.124566784155851],
              [-38.516053400056663, -8.124561335897857],
              [-38.516134935789545, -8.124540900216697],
              [-38.516139930178099, -8.124539187563803],
              [-38.516186143624132, -8.124529473679479],
              [-38.516230802287048, -8.124529526783499],
              [-38.516194652248842, -8.124549292839131],
              [-38.51616277076382, -8.124567074018218],
              [-38.516130144160726, -8.124600683451067],
              [-38.516088537747841, -8.124628945517957],
              [-38.516027067725489, -8.124644701542122],
              [-38.51601989648865, -8.124645054820339],
              [-38.515993300435476, -8.124645475442318],
              [-38.515944661659745, -8.124633930141481],
              [-38.515876693719363, -8.124618381936363],
              [-38.515806809648964, -8.124611153045789],
              [-38.515752073813736, -8.124612535136368],
              [-38.515704220207958, -8.124627493247626],
              [-38.515651266689837, -8.124656465378447],
              [-38.515602208885383, -8.124691592892278],
              [-38.515569034247328, -8.124728005643478],
              [-38.515530402792308, -8.124773276212609],
              [-38.515497493290603, -8.124815659120248],
              [-38.515460316087029, -8.124859303273261],
              [-38.515422593714561, -8.124903399034119],
              [-38.51538688313267, -8.124935105231485],
              [-38.515343454839652, -8.124968701728207],
              [-38.515302839967177, -8.125002663380673],
              [-38.515257231775379, -8.125037433149117],
              [-38.51520508685141, -8.125073280549202],
              [-38.515156478030839, -8.125112388436515],
              [-38.515118025535088, -8.125159739575381],
              [-38.515077218227667, -8.125202746196123],
              [-38.515060678149702, -8.125219369690026],
              [-38.515040321287515, -8.125239697174569],
              [-38.515003061804379, -8.125276195455825],
              [-38.514990699845868, -8.12529056262186],
              [-38.514968793699644, -8.125316044032093],
              [-38.514949762208936, -8.125366493682563],
              [-38.514940352314284, -8.125416954804214],
              [-38.514981227275911, -8.125468832664405],
              [-38.515014318520102, -8.12550197763087],
              [-38.515045876238027, -8.125527160970876],
              [-38.515046692080801, -8.125528066465712],
              [-38.515086222023257, -8.125566193974796],
              [-38.515129379402168, -8.125607129823013],
              [-38.515169637690427, -8.125643449148322],
              [-38.515218967221287, -8.12568457324843],
              [-38.515261126049005, -8.125725598346195],
              [-38.515297211550298, -8.125759741834232],
              [-38.515335929276638, -8.125793978907701],
              [-38.515373194365267, -8.125828485603932],
              [-38.515412091691388, -8.125864351024894],
              [-38.515450533655731, -8.125901482231219],
              [-38.515481719130683, -8.125934353536417],
              [-38.515520426908687, -8.125976912187156],
              [-38.51556340684953, -8.12601450104974],
              [-38.515598225093761, -8.126045748532954],
              [-38.515600582401284, -8.126048012645894],
              [-38.515638571526729, -8.126084329226797],
              [-38.515679370949755, -8.126123634074181],
              [-38.515722711073181, -8.126163665560522],
              [-38.515758803267509, -8.126192291433231],
              [-38.515775485756194, -8.126208321337577],
              [-38.515797699036717, -8.126229513602276],
              [-38.515842399866408, -8.126270270314558],
              [-38.515849382978608, -8.126275434403405],
              [-38.515879583053909, -8.126297450240248],
              [-38.515917311408764, -8.126324178550417],
              [-38.515974550017432, -8.126355362233415],
              [-38.516024160482523, -8.126389431304155],
              [-38.516082116925475, -8.126427580649038],
              [-38.516140002330232, -8.126449267594875],
              [-38.51618816006691, -8.126483696727773],
              [-38.516241946423989, -8.12651740893174],
              [-38.516293006546128, -8.126553741001839],
              [-38.516340250217354, -8.126593505713084],
              [-38.516388138169567, -8.126625854101739],
              [-38.516433844073042, -8.126661184814878],
              [-38.516524372023, -8.126711945683802],
              [-38.516524647365038, -8.126709413348379],
              [-38.516590974676319, -8.126730929363246],
              [-38.516653501664223, -8.126742400652994],
              [-38.516714852432408, -8.126750704707339],
              [-38.516772213212938, -8.126755747731529],
              [-38.516823127475234, -8.126762320778019],
              [-38.516824286386949, -8.126779960342065],
              [-38.516821607523369, -8.126818851624167],
              [-38.516851517747511, -8.12685561076486],
              [-38.516893152044403, -8.126880082324117],
              [-38.516940154228294, -8.126894158246506],
              [-38.516988067146407, -8.126905702582304],
              [-38.517024429106712, -8.126936589977097],
              [-38.517060067290508, -8.126965486560151],
              [-38.517110874810207, -8.126985446377953],
              [-38.517169025422831, -8.127013193799829],
              [-38.51721138818889, -8.127035766695977],
              [-38.517253572554743, -8.127055716260998],
              [-38.51729955716683, -8.127085800983657],
              [-38.517339729464176, -8.127118411467096],
              [-38.517381354632406, -8.127150571409581],
              [-38.517426358841341, -8.127165549430089],
              [-38.517478263754377, -8.127178726587561],
              [-38.517523164557815, -8.127204287388272],
              [-38.517560787322751, -8.127243588293458],
              [-38.517600151368256, -8.127268961614218],
              [-38.517636060206485, -8.127299305707348],
              [-38.517686316372902, -8.127324963294898],
              [-38.517735124973683, -8.12734663926109],
              [-38.517781392119623, -8.127368221756106],
              [-38.517830756044056, -8.127380943596645],
              [-38.517881303225231, -8.127390953266415],
              [-38.517929310136246, -8.127399874492053],
              [-38.517983590133021, -8.127400481539329],
              [-38.51803714580295, -8.127399550032608],
              [-38.518082355029769, -8.127394900086738],
              [-38.518140469503919, -8.1273769689438],
              [-38.518188595463258, -8.127362191788551],
              [-38.518234909897679, -8.127344156200348],
              [-38.518279866549868, -8.127322953169339],
              [-38.518325101333808, -8.12729686604048],
              [-38.518366708105248, -8.127268422851133],
              [-38.518404868081134, -8.127237895173865],
              [-38.518429059028392, -8.127199300701877],
              [-38.518420684802145, -8.127142758122913],
              [-38.518387233506111, -8.127106718513831],
              [-38.518344603095436, -8.127080165519654],
              [-38.518290713189735, -8.127056945919961],
              [-38.518239816766112, -8.127035357999738],
              [-38.518191096891343, -8.127015400792512],
              [-38.518133024127756, -8.126998417423691],
              [-38.518089197976444, -8.126985069017296],
              [-38.518009240749755, -8.126975476786393],
              [-38.518078996093088, -8.126938835825738],
              [-38.51811851577488, -8.126909847473753],
              [-38.518160668650076, -8.126880138618876],
              [-38.518196019905339, -8.126845265923603],
              [-38.518223935444439, -8.126804143211524],
              [-38.518260665933148, -8.126754437972561],
              [-38.518283686235357, -8.126707972774149],
              [-38.518279659401522, -8.126659485592695],
              [-38.518298689585166, -8.126609849838021],
              [-38.51831008967978, -8.126564727659467],
              [-38.518313595468278, -8.126517334825335],
              [-38.518298577574129, -8.126475437642824],
              [-38.518294253618215, -8.126447754129689],
              [-38.518317439149371, -8.126414947414908],
              [-38.518333472068811, -8.126366845796269],
              [-38.518329547002068, -8.126309132603902],
              [-38.518298641439962, -8.126269658818888],
              [-38.518266283347288, -8.126230364216124],
              [-38.518236572803374, -8.126178318977582],
              [-38.518212493568704, -8.126123295482287],
              [-38.518169043319347, -8.126099274167355],
              [-38.518117693386507, -8.126077504796495],
              [-38.518141939662463, -8.126068578744777],
              [-38.518170645104767, -8.126050070033539],
              [-38.518204524866327, -8.126031205639604],
              [-38.51823146726759, -8.126044895841614],
              [-38.518260919839392, -8.126084910623584],
              [-38.518291457450339, -8.126128454326999],
              [-38.518323278899608, -8.126161054824548],
              [-38.518371181103788, -8.126181553756707],
              [-38.518428565239461, -8.126166968429924],
              [-38.518468538022582, -8.126138523287093],
              [-38.518519761862912, -8.126113800000409],
              [-38.518568610260324, -8.12610200856988],
              [-38.518622008043636, -8.126081086837919],
              [-38.518673765386723, -8.126065680745922],
              [-38.518728074093737, -8.126042046509111],
              [-38.518773591034986, -8.126007547591538],
              [-38.518813654549398, -8.125979102519583],
              [-38.518871696227315, -8.125945884737188],
              [-38.518924100784766, -8.12592052962713],
              [-38.518975950052464, -8.125904128628516],
              [-38.51903188407325, -8.125887642000302],
              [-38.519075921730312, -8.125875754365994],
              [-38.519118615602586, -8.125848940512531],
              [-38.51915640627589, -8.125823477642681],
              [-38.519193666536196, -8.125786074441395],
              [-38.519231185750435, -8.12575988762484],
              [-38.51926580505787, -8.125729717477174],
              [-38.519307224894376, -8.125705615664945],
              [-38.519343566345036, -8.125677527947484],
              [-38.51939133005898, -8.125661845683783],
              [-38.519435918651112, -8.125644621974626],
              [-38.519478607419352, -8.125622059331535],
              [-38.519528834820512, -8.125595525687224],
              [-38.519574975632651, -8.125570615351272],
              [-38.519625938001013, -8.125536665474835],
              [-38.519673987550163, -8.125509586525839],
              [-38.519726926193229, -8.125493005808016],
              [-38.519774959816765, -8.125479313766013],
              [-38.519823080336657, -8.125468878103016],
              [-38.519880434335434, -8.125479528714839],
              [-38.519937515168017, -8.125490902614709],
              [-38.519987170713563, -8.125487252665271],
              [-38.520044645581507, -8.125472576755849],
              [-38.520089586569931, -8.125464308164558],
              [-38.520135530968311, -8.125451879946459],
              [-38.520183473483634, -8.125438459104497],
              [-38.520232418009996, -8.125422054512793],
              [-38.520279549605341, -8.125403567374297],
              [-38.520330863592662, -8.125379205762524],
              [-38.520376918612719, -8.125350043972009],
              [-38.520424707166512, -8.125313376678994],
              [-38.520482082530449, -8.125306027223944],
              [-38.520532092543121, -8.125309613807959],
              [-38.520578970374544, -8.125275478086641],
              [-38.520623111900228, -8.125252464767708],
              [-38.520665527076581, -8.125230896646425],
              [-38.520709036208672, -8.125205349908061],
              [-38.520753286302373, -8.125167321620197],
              [-38.52079218354254, -8.125127116166455],
              [-38.520817105011098, -8.125084542487459],
              [-38.520842667012552, -8.12503762785037],
              [-38.520878744145108, -8.125002665298961],
              [-38.520929967278569, -8.12497830349915],
              [-38.520973925819121, -8.124956465803329],
              [-38.521025229523424, -8.124940697054601],
              [-38.521070633187442, -8.124924921353601],
              [-38.521110601597321, -8.124899913114252],
              [-38.521148949226166, -8.124863867781551],
              [-38.521176041610687, -8.124827628293566],
              [-38.521203227332784, -8.124789218057876],
              [-38.521250823918358, -8.124761233854443],
              [-38.521305046689598, -8.124733257441486],
              [-38.521354627551098, -8.124715948932312],
              [-38.521403932884809, -8.124701353662092],
              [-38.521447525292736, -8.124682048145639],
              [-38.521473595494122, -8.124665797393858],
              [-38.52149133169744, -8.124635335825388],
              [-38.52150593080939, -8.124571222306269],
              [-38.521521934381653, -8.124624155730501],
              [-38.521521871712963, -8.124677070259491],
              [-38.521528101509411, -8.124705208255234],
              [-38.521536036114078, -8.124750081933589],
              [-38.52157482805071, -8.124798700444616],
              [-38.521611283974472, -8.124827054887445],
              [-38.521656481755585, -8.124831811551942],
              [-38.521715300573888, -8.124831880704539],
              [-38.521764389894116, -8.124846410782133],
              [-38.521807478669167, -8.124869164961645],
              [-38.521853865351375, -8.124866415459863],
              [-38.521908602961133, -8.124863585314063],
              [-38.521960242196123, -8.124871063084365],
              [-38.522012600421483, -8.124884601998273],
              [-38.522056324069574, -8.124907809159927],
              [-38.522106251527518, -8.12490443062293],
              [-38.522156618390731, -8.124913263658994],
              [-38.522209817627299, -8.124906451763929],
              [-38.522258361931002, -8.124921342952545],
              [-38.522311355670666, -8.124934792122884],
              [-38.522360193359198, -8.124931864536812],
              [-38.522407218431319, -8.124926583056613],
              [-38.522443296281502, -8.124890896740276],
              [-38.522466769312068, -8.124844884060389],
              [-38.522491961403588, -8.12480357691727],
              [-38.52253965124585, -8.12477342181171],
              [-38.522581723082176, -8.12473502894599],
              [-38.522587164779154, -8.124738834329223],
              [-38.522607084320946, -8.124781008487336],
              [-38.522630446812869, -8.124828523371633],
              [-38.522682622345165, -8.124843056961527],
              [-38.522721681889578, -8.124818952014571],
              [-38.522757398013361, -8.124782089363439],
              [-38.522781420001458, -8.124732368761318],
              [-38.522801346980572, -8.124691417230052],
              [-38.522813615855995, -8.124678768294304],
              [-38.522825475392274, -8.124705284736466],
              [-38.522831521391119, -8.12473541245307],
              [-38.522851001211613, -8.124765646377458],
              [-38.522894712509469, -8.124799345906844],
              [-38.522941361559518, -8.124805008654542],
              [-38.522997456757203, -8.124805526689467],
              [-38.523038802184459, -8.124767313827189],
              [-38.523052060553006, -8.124762264039884],
              [-38.52308616020094, -8.124787540214724],
              [-38.523130424789137, -8.12481391372973],
              [-38.523180518400778, -8.124823560390384],
              [-38.523225472722366, -8.124803894466288],
              [-38.523268813997191, -8.124766588441615],
              [-38.523320232280383, -8.124730558208096],
              [-38.523373326946277, -8.124735414387143],
              [-38.523433309981016, -8.124748871600195],
              [-38.523494415436929, -8.124734199442431],
              [-38.523553363748576, -8.124701434281468],
              [-38.523601308856577, -8.124685661266925],
              [-38.523659418789087, -8.124671076028148],
              [-38.523735228183021, -8.124657054214712],
              [-38.523803148918731, -8.124635967870917],
              [-38.523869465853345, -8.12458937207793],
              [-38.523909708832406, -8.124562283468819],
              [-38.523947408761238, -8.124536458212647],
              [-38.523988113891342, -8.124502314853052],
              [-38.524025730392864, -8.124470248279518],
              [-38.524064438575891, -8.124436102575212],
              [-38.524099246105159, -8.124399871900286],
              [-38.524133778016385, -8.124366444922325],
              [-38.524166684802687, -8.124325598947992],
              [-38.524194324277055, -8.124287008119421],
              [-38.524239891173885, -8.124209543742049],
              [-38.524265682302158, -8.124121835120492],
              [-38.52427676234705, -8.124039536437225],
              [-38.524284477307638, -8.123962841864518],
              [-38.524285378793358, -8.12389102375205],
              [-38.524285002581529, -8.123825083548326],
              [-38.524291789604533, -8.123765573836225],
              [-38.524296745579392, -8.123719358478677],
              [-38.524291034833269, -8.123635683376648],
              [-38.524273976087052, -8.123553532697013],
              [-38.524256733145386, -8.123473643110183],
              [-38.524225422748955, -8.123392199384625],
              [-38.524183758253677, -8.123316170690927],
              [-38.524132998399445, -8.123255779613995],
              [-38.52409527367265, -8.123225705314358],
              [-38.524074612328818, -8.12319673639715],
              [-38.524055691161919, -8.123154563471104],
              [-38.524042927830109, -8.123124699266715],
              [-38.524015020070848, -8.123082877637229],
              [-38.523959971730982, -8.123041476518338],
              [-38.523893304386625, -8.123000514060195],
              [-38.523823994085113, -8.122968593734031],
              [-38.523749601455833, -8.122936034282095],
              [-38.523687473259962, -8.122894534390509],
              [-38.523666296027223, -8.122841233169394],
              [-38.523637592727653, -8.122781320116019],
              [-38.523594450074306, -8.122727360011924],
              [-38.523547041807447, -8.122672942647851],
              [-38.523509692948423, -8.122632104909462],
              [-38.523471152149753, -8.12260139643503],
              [-38.523416186701375, -8.122566779271471],
              [-38.523376299312538, -8.122523405883316],
              [-38.523339327964834, -8.122470357506316],
              [-38.523295636180329, -8.122420286181498],
              [-38.52325147882236, -8.122380164064968],
              [-38.523210392366877, -8.122352980227118],
              [-38.523165042964649, -8.122323077820226],
              [-38.523112318419642, -8.122312523567663],
              [-38.523055848106708, -8.122322497600232],
              [-38.523004828643245, -8.122328498110713],
              [-38.522947751130395, -8.122314591998331],
              [-38.522905849382298, -8.122286050390374],
              [-38.522865969552583, -8.122236345301308],
              [-38.522838533657179, -8.122179418612152],
              [-38.522814811081979, -8.122129461105589],
              [-38.522786729242078, -8.12208148843766],
              [-38.522757634100564, -8.122046087455004],
              [-38.522720906983764, -8.122016918731655],
              [-38.522675840754722, -8.121977880919788],
              [-38.522662712025777, -8.121950096653542],
              [-38.522659498394994, -8.121904324000823],
              [-38.522667918810285, -8.121845358951727],
              [-38.522693992589872, -8.12190273631489],
              [-38.522710304008193, -8.121925639886483],
              [-38.522744775025906, -8.121943680326973],
              [-38.522792039797693, -8.121965715669356],
              [-38.522842641247536, -8.12200620719625],
              [-38.522874259242968, -8.122057440290545],
              [-38.522895890072931, -8.12211083253073],
              [-38.522929975188056, -8.122148229305363],
              [-38.522974677850662, -8.122187718926808],
              [-38.523034301902264, -8.122197376758999],
              [-38.523088346407036, -8.122166234037323],
              [-38.523129406319413, -8.122138965544201],
              [-38.523198928160106, -8.122145288213089],
              [-38.523280316868316, -8.1221718860966],
              [-38.52335997723916, -8.122201647772275],
              [-38.523428661053558, -8.122225878985978],
              [-38.523488663240506, -8.122222783414236],
              [-38.523529730172669, -8.122189545032759],
              [-38.523566988843633, -8.122152864999114],
              [-38.523593568197981, -8.122089489017121],
              [-38.52360900127406, -8.122010542181128],
              [-38.523613177688503, -8.12193266759585],
              [-38.523618172835242, -8.121853256278516],
              [-38.523628613060133, -8.121774755857595],
              [-38.523635232875186, -8.121703125343078],
              [-38.523626510578914, -8.121633285917959],
              [-38.523620662829835, -8.12158895742059],
              [-38.523619073231856, -8.121551417833608],
              [-38.523634194416495, -8.121506028451121],
              [-38.523637142140451, -8.121546464103041],
              [-38.523666673798012, -8.121596609270448],
              [-38.523683140043481, -8.121642216527663],
              [-38.523697331734859, -8.121692343740047],
              [-38.523702355748895, -8.121742460223288],
              [-38.523706999524919, -8.121807139090109],
              [-38.52371218066795, -8.121877969345629],
              [-38.523717442880802, -8.121957030859848],
              [-38.523724789055024, -8.122039260645021],
              [-38.523730415519971, -8.122117327609189],
              [-38.523757372965271, -8.122195238630995],
              [-38.523796499359797, -8.12226818901151],
              [-38.523846257813162, -8.122330930713028],
              [-38.523891491352423, -8.122382179666575],
              [-38.523950273499018, -8.122413092685347],
              [-38.524020334057198, -8.122424481179205],
              [-38.52408258942107, -8.122435046462245],
              [-38.52413948120001, -8.1224525702956],
              [-38.524179109886497, -8.122484455861645],
              [-38.524231796046429, -8.122527663234431],
              [-38.524262856015284, -8.122590473481052],
              [-38.524296991183228, -8.122662603913192],
              [-38.524363390149759, -8.122700219254142],
              [-38.524419434397615, -8.122743792339307],
              [-38.524467649820778, -8.122806622621171],
              [-38.524530215121899, -8.122862414380968],
              [-38.524571209251633, -8.12289104522268],
              [-38.524643681106745, -8.122936084709641],
              [-38.524680647351786, -8.122993655591086],
              [-38.524716236320096, -8.123064611810223],
              [-38.524754542745306, -8.123140365175578],
              [-38.524810730448728, -8.123216410776667],
              [-38.524864919542658, -8.123293901275039],
              [-38.524916654051971, -8.123374645186315],
              [-38.524963680901969, -8.123444891122039],
              [-38.524996197722174, -8.123503994474248],
              [-38.525020023004544, -8.1235440926826],
              [-38.525052270615063, -8.123600572598235],
              [-38.525080888621261, -8.123656053299291],
              [-38.525111239549084, -8.123704480736873],
              [-38.525154659815989, -8.123754189757818],
              [-38.525215242987485, -8.123797587136492],
              [-38.525275296751921, -8.123828049201089],
              [-38.525340091053828, -8.123841059481135],
              [-38.525412711627204, -8.123836983382617],
              [-38.525469629482743, -8.123832527148659],
              [-38.525533602193384, -8.123849516344292],
              [-38.525580899746011, -8.123843963448072],
              [-38.525626067464792, -8.123796980878582],
              [-38.52566534884771, -8.123738323085862],
              [-38.525659840127787, -8.123791593109207],
              [-38.525654345529269, -8.123832832983762],
              [-38.525643934184423, -8.123887001814689],
              [-38.525641138565284, -8.123948777522733],
              [-38.525677654166941, -8.124003815132296],
              [-38.525745696472868, -8.124033653276941],
              [-38.525774271397147, -8.124048611223687],
              [-38.52583820583618, -8.124098253634672],
              [-38.525908132470015, -8.12414672713785],
              [-38.525981545972101, -8.124240521329872],
              [-38.526007638833704, -8.124282159819124],
              [-38.526031100757976, -8.124322619361187],
              [-38.526061545271631, -8.124368785535344],
              [-38.52608926818511, -8.124413682204166],
              [-38.526122706310275, -8.12446138955155],
              [-38.526159325310282, -8.124505753863424],
              [-38.526191965511188, -8.124537269301863],
              [-38.52622496815993, -8.124569327874275],
              [-38.526278100283101, -8.124619681262192],
              [-38.526339249007464, -8.124645440931438],
              [-38.52638588337193, -8.12466367614876],
              [-38.526470534070739, -8.124695794823545],
              [-38.526551191017688, -8.124727818381521],
              [-38.526635123534831, -8.124753152259389],
              [-38.526718061727813, -8.124774957321138],
              [-38.526798819865284, -8.12479811661647],
              [-38.52682631814811, -8.124802399861046],
              [-38.526838025233126, -8.124804312976746],
              [-38.526878682708059, -8.124810691926697],
              [-38.52692616539953, -8.12480215416644],
              [-38.526955671847681, -8.124796851785121],
              [-38.527030776312714, -8.124764647604133],
              [-38.527104692711973, -8.124739316408677],
              [-38.52717924692621, -8.124711905535703],
              [-38.527225098073473, -8.124701375890874],
              [-38.527278317661654, -8.124677106039847],
              [-38.527326459107925, -8.124648579019563],
              [-38.527377057258064, -8.124614989513367],
              [-38.527430733109071, -8.124588639766237],
              [-38.527487669472407, -8.124568444560774],
              [-38.527545057563913, -8.124550058919317],
              [-38.527599176774686, -8.124532664450232],
              [-38.527656296143007, -8.124511203099173],
              [-38.527705517159845, -8.124490908452174],
              [-38.527756562554465, -8.124462927463147],
              [-38.527806241215593, -8.124439377048335],
              [-38.527856008949684, -8.124417273968771],
              [-38.527909949551194, -8.124397346582162],
              [-38.527955541865992, -8.124375238644873],
              [-38.52800340796383, -8.12434924388702],
              [-38.528048001589944, -8.124327315684944],
              [-38.528096322581824, -8.124300416919088],
              [-38.528139193368709, -8.124276949017441],
              [-38.528210511941055, -8.124221946211856],
              [-38.528298137468553, -8.124194007565949],
              [-38.528310577989743, -8.124189680273414],
              [-38.528341633906784, -8.124178861987687],
              [-38.528418016747004, -8.124139965541229],
              [-38.528454395362779, -8.124079404594347],
              [-38.528493959139844, -8.124011792047094],
              [-38.528522594127956, -8.123975372910651],
              [-38.5285704138063, -8.123911297564657],
              [-38.528616697983047, -8.123840707859914],
              [-38.528667710288872, -8.123763158790458],
              [-38.528679650173729, -8.123721112251941],
              [-38.528666567502484, -8.123653438697078],
              [-38.52864387507605, -8.123575623340294],
              [-38.528621094209463, -8.123495817927013],
              [-38.528588600586573, -8.123416363069921],
              [-38.528544662415563, -8.123343407524249],
              [-38.528517110693151, -8.123307194638597],
              [-38.528508772983933, -8.123295968879274],
              [-38.528311196517841, -8.123115558708752],
              [-38.528289791497521, -8.123101332868682],
              [-38.528213235494178, -8.12305547520022],
              [-38.528169875676689, -8.123031455039737],
              [-38.528131512409658, -8.123003822572718],
              [-38.528067408865496, -8.122943416481313],
              [-38.528035320537235, -8.122905660614126],
              [-38.528006595015277, -8.122864200096695],
              [-38.527980047014083, -8.122823556174811],
              [-38.527954769155805, -8.122783456439256],
              [-38.527926404720297, -8.12274371493125],
              [-38.527868941467744, -8.122671286345524],
              [-38.527829418968558, -8.122625923837075],
              [-38.527786898129939, -8.122583090513245],
              [-38.527751535922796, -8.122550667518784],
              [-38.527701285646565, -8.122519855386198],
              [-38.52765512972487, -8.12248072637936],
              [-38.527611054739644, -8.122447388736745],
              [-38.527574689549397, -8.122418854017154],
              [-38.527519265664559, -8.122387854952823],
              [-38.527460669939742, -8.122352510483303],
              [-38.527411228384572, -8.122328754543322],
              [-38.527354266102357, -8.122293773765868],
              [-38.5272940305288, -8.122263583155876],
              [-38.527258705513816, -8.122277019529676],
              [-38.52723003617718, -8.12226522741401],
              [-38.527204744988374, -8.12223661507557],
              [-38.527191334735122, -8.122216428611774],
              [-38.527214979929205, -8.122177832909093],
              [-38.527221746165019, -8.122135689953184],
              [-38.527204193756198, -8.122087096625659],
              [-38.527185116687797, -8.122022762809378],
              [-38.527168046177671, -8.121950200155506],
              [-38.527166977265857, -8.121932832053625],
              [-38.527166578449375, -8.121885796344378],
              [-38.527192030835678, -8.121931775731623],
              [-38.527224732490218, -8.121988256016859],
              [-38.527252537588247, -8.122040479349314],
              [-38.527275082521022, -8.122088897569478],
              [-38.527301346247441, -8.12213967187026],
              [-38.527333252696963, -8.122177608483055],
              [-38.527355649748337, -8.122197262665392],
              [-38.527398564993639, -8.122213232126201],
              [-38.527447026898834, -8.122220886402687],
              [-38.527501307980664, -8.122220044904271],
              [-38.527561029508973, -8.122224094143943],
              [-38.52762103814878, -8.122215480372157],
              [-38.527681957122105, -8.122204606337823],
              [-38.527733812061513, -8.122182686582885],
              [-38.527786830191225, -8.1221751501027],
              [-38.527817592200435, -8.122182693344799],
              [-38.527847719037403, -8.122190054942966],
              [-38.527914686682678, -8.122207318588396],
              [-38.527917953425593, -8.122208136449466],
              [-38.527982283063409, -8.122230281453852],
              [-38.52803887988965, -8.122267342127563],
              [-38.528085855603422, -8.122304029827681],
              [-38.528173459646837, -8.122371880256971],
              [-38.528217524747909, -8.122413720355743],
              [-38.528268757995924, -8.122457830069571],
              [-38.528311936855083, -8.122481307292826],
              [-38.528324908741602, -8.122488287163415],
              [-38.528406277119764, -8.122532703145104],
              [-38.528431953447381, -8.122542501759501],
              [-38.528474872577178, -8.122555214828745],
              [-38.528559171459662, -8.122577473336387],
              [-38.52862033038766, -8.122594368331683],
              [-38.528684501589908, -8.122596794420877],
              [-38.528740509456973, -8.122594145711554],
              [-38.528800063762837, -8.12258598350363],
              [-38.52885190414684, -8.122576546022261],
              [-38.528905834965805, -8.122564759191272],
              [-38.528960578278124, -8.122556772294962],
              [-38.529015330971134, -8.122540735136859],
              [-38.529067547810143, -8.122519810606105],
              [-38.529122035880469, -8.12249717010042],
              [-38.529174894839009, -8.12247045734337],
              [-38.529221221032387, -8.122441475711328],
              [-38.529268826629746, -8.122405078455058],
              [-38.529311163488849, -8.122372202743195],
              [-38.529355224852956, -8.122339419473354],
              [-38.529399647391315, -8.122308264759527],
              [-38.529438078411275, -8.122277736279594],
              [-38.529498963563384, -8.122217927170645],
              [-38.52954679192495, -8.122146072785597],
              [-38.529589407780918, -8.12210740840894],
              [-38.529645702837897, -8.122091915658217],
              [-38.529691201295421, -8.122072159158652],
              [-38.529732707900969, -8.122050679445078],
              [-38.529797499526495, -8.121987889870281],
              [-38.529858568961892, -8.121925638698622],
              [-38.529895643949565, -8.121890314625603],
              [-38.529929445557649, -8.121859871199309],
              [-38.529959899850823, -8.121819835946141],
              [-38.529983907841178, -8.121781240484669],
              [-38.530007338611277, -8.121692624159675],
              [-38.530002671312872, -8.121647302101145],
              [-38.529991742099874, -8.121600977834357],
              [-38.529944965718244, -8.121470853215229],
              [-38.529941833928817, -8.121432045504509],
              [-38.529979927508819, -8.12145741617924],
              [-38.530016484855231, -8.121476905668478],
              [-38.530042245199944, -8.121492493233015],
              [-38.530096137461591, -8.121513721327741],
              [-38.530154125592375, -8.121525275733426],
              [-38.530215684003856, -8.121510783950512],
              [-38.530304302023502, -8.121487368595618],
              [-38.53038939023206, -8.121455175261506],
              [-38.530433801351599, -8.121433698821345],
              [-38.530473314926709, -8.121408779533486],
              [-38.530504307862472, -8.12137381019185],
              [-38.530549505950852, -8.121299962682604],
              [-38.530605254140966, -8.121208127298376],
              [-38.530649856739153, -8.121178058070996],
              [-38.530638644244405, -8.121219291454988],
              [-38.530635397369018, -8.121279529037437],
              [-38.530627350023373, -8.121330896744235],
              [-38.530619291330311, -8.121392033301275],
              [-38.530617093752696, -8.121408493111247],
              [-38.530585604850657, -8.121479733318541],
              [-38.530546670134008, -8.121553316697646],
              [-38.53050238054653, -8.121626441632586],
              [-38.530458638815368, -8.121696763167964],
              [-38.530414273244823, -8.121757134209702],
              [-38.530376967584495, -8.121834789807583],
              [-38.530356397820889, -8.12188288676751],
              [-38.530334292017855, -8.121924921640165],
              [-38.530304285080177, -8.121970565489999],
              [-38.53028126747386, -8.12201612695163],
              [-38.530252617120667, -8.122066023626852],
              [-38.530227238555007, -8.122112396431374],
              [-38.530209023712509, -8.122164837816507],
              [-38.530191174781308, -8.122214837407125],
              [-38.53017260053209, -8.122264112539835],
              [-38.530153752813305, -8.122314382332311],
              [-38.530138084014546, -8.122362937197622],
              [-38.5301221404847, -8.122413572150732],
              [-38.530113189977577, -8.122460958889889],
              [-38.530101871681325, -8.122515036374443],
              [-38.530090191986986, -8.122567666202137],
              [-38.530072520772869, -8.122620922277484],
              [-38.530058123142929, -8.122669116795985],
              [-38.530040633563473, -8.122722282626436],
              [-38.530020062236801, -8.122771645894719],
              [-38.529993498648956, -8.122822268574515],
              [-38.529975281740455, -8.122876428537618],
              [-38.529970305910688, -8.122940282203579],
              [-38.52996152544128, -8.122997528447396],
              [-38.529944583500942, -8.123048071783632],
              [-38.529929720941936, -8.123105763262506],
              [-38.529926755287754, -8.123158222243234],
              [-38.529914711206658, -8.123211937070121],
              [-38.529898946623248, -8.123264743073845],
              [-38.529876009978075, -8.123318626219531],
              [-38.529860974277504, -8.123369081300124],
              [-38.529853917770353, -8.123426872245444],
              [-38.529859216764635, -8.123475360866626],
              [-38.529870598585632, -8.123522771086762],
              [-38.529896870623396, -8.123566761317925],
              [-38.529938143224385, -8.123590507534209],
              [-38.529990774342821, -8.123603683933387],
              [-38.530043941035188, -8.123624639853039],
              [-38.530094018094772, -8.123648577128021],
              [-38.530144539331893, -8.123680836533154],
              [-38.530188163136216, -8.123712183444248],
              [-38.530231782530549, -8.123747329347761],
              [-38.530265602805379, -8.123779026742278],
              [-38.530299421508282, -8.123812080918816],
              [-38.530338956918769, -8.123846679382792],
              [-38.530383216669975, -8.123877574746441],
              [-38.530434825303949, -8.123911463516887],
              [-38.530483802045957, -8.123944987434353],
              [-38.530534141392884, -8.123977608394295],
              [-38.53059501623504, -8.124004904809727],
              [-38.530661984530184, -8.124021987120756],
              [-38.5307304967573, -8.124038347582363],
              [-38.530806916670869, -8.124045491003475],
              [-38.530874261190554, -8.124051267160123],
              [-38.530941880122178, -8.124055234573394],
              [-38.531004695924679, -8.124052593411355],
              [-38.531068788592009, -8.124044707469945],
              [-38.531125986012157, -8.124034009546916],
              [-38.531178280290241, -8.124024662752618],
              [-38.531234921438461, -8.124024004393595],
              [-38.531207571099898, -8.124048666397613],
              [-38.531152612693354, -8.124085417213799],
              [-38.531118987686668, -8.124120021748752],
              [-38.53107972844078, -8.124160227838084],
              [-38.531021037528284, -8.124206381386244],
              [-38.530942497530461, -8.124227094908806],
              [-38.530861610324934, -8.124236770512509],
              [-38.530779457276935, -8.124242193375839],
              [-38.530719428202318, -8.12426817444253],
              [-38.530651863127197, -8.124295955855256],
              [-38.530574763747843, -8.124326711186043],
              [-38.530528817209706, -8.124341311465042],
              [-38.530481153085262, -8.124349849443936],
              [-38.530427220482061, -8.124362993256256],
              [-38.530379639868784, -8.124377772536736],
              [-38.530329334370826, -8.124394086357128],
              [-38.530282120721076, -8.124405519309684],
              [-38.530233262800408, -8.124425905148001],
              [-38.530189491560535, -8.124443221471672],
              [-38.53010874523396, -8.124487811719536],
              [-38.530055317240482, -8.124535327983468],
              [-38.530008156246488, -8.12457950473962],
              [-38.529974540258912, -8.124606239821277],
              [-38.529943733606842, -8.124636867599783],
              [-38.529906778386319, -8.124647046024677],
              [-38.529859582802047, -8.124642921130793],
              [-38.52979652744586, -8.124617340679427],
              [-38.52973592530526, -8.124589682648725],
              [-38.529668251656268, -8.124554689788896],
              [-38.529591781983434, -8.12451226965047],
              [-38.529507681975581, -8.124474725105131],
              [-38.529460691344511, -8.124450519967485],
              [-38.529413881521371, -8.124426948200709],
              [-38.529363988376119, -8.124400930647059],
              [-38.529315452147621, -8.124378894563543],
              [-38.529267282270794, -8.124354054873564],
              [-38.529221021200222, -8.124326956076667],
              [-38.529174389783854, -8.12430609806249],
              [-38.529125309275813, -8.124283789970336],
              [-38.529077227029838, -8.124261663932739],
              [-38.529027605815926, -8.124236008464381],
              [-38.528984335748525, -8.124212712105431],
              [-38.528940342059457, -8.124187244046528],
              [-38.528898066778282, -8.124167114664896],
              [-38.528815242330623, -8.124125591554417],
              [-38.528732776759341, -8.124087686939799],
              [-38.528661461631984, -8.12406200630128],
              [-38.52860851102615, -8.124089261584171],
              [-38.528610619003778, -8.124149686232505],
              [-38.52864380241563, -8.124182558899374],
              [-38.528672432127905, -8.124150662366802],
              [-38.528711557253921, -8.12414781321128],
              [-38.52876454176787, -8.124169311801278],
              [-38.528831673496938, -8.124201861961486],
              [-38.528910414166894, -8.12424265672184],
              [-38.528987700006539, -8.124285620641352],
              [-38.529068529047038, -8.124325875075906],
              [-38.529149174034828, -8.124368300140071],
              [-38.529215760052566, -8.124401844585112],
              [-38.529274728164054, -8.124429591251895],
              [-38.529324717028871, -8.124451357663723],
              [-38.529375334755308, -8.124478732846669],
              [-38.529435384858431, -8.124512721959332],
              [-38.529502969288437, -8.124546357967022],
              [-38.529566732655482, -8.124587497096238],
              [-38.529626051578312, -8.124625827052885],
              [-38.529662681630725, -8.124661055390302],
              [-38.529671003604065, -8.124685848972677],
              [-38.529625229585704, -8.124708137799933],
              [-38.529575985137605, -8.124748603544104],
              [-38.529542524210171, -8.124798042322439],
              [-38.52950659931976, -8.12485878479956],
              [-38.529466941579621, -8.124929201363408],
              [-38.52942718831401, -8.125003688170409],
              [-38.529392785296452, -8.125082613329596],
              [-38.529351400354621, -8.12515519873889],
              [-38.529330746571013, -8.125197235214253],
              [-38.529296433337855, -8.125276974538151],
              [-38.529264935435982, -8.125355450782019],
              [-38.529240701441466, -8.125431945475444],
              [-38.52924360841471, -8.125507838408323],
              [-38.52927594944321, -8.125562689990526],
              [-38.529321379496139, -8.125601998901137],
              [-38.529363287967549, -8.125625474561604],
              [-38.529423816109173, -8.125638841087673],
              [-38.529469371829826, -8.125648210381076],
              [-38.52955277351051, -8.12566205560686],
              [-38.52963054345085, -8.125679331495656],
              [-38.529698036265401, -8.125713871899007],
              [-38.529763076298806, -8.125750128052271],
              [-38.529807418655047, -8.12578816932095],
              [-38.529864004878647, -8.125834908167498],
              [-38.529938828858562, -8.125887728393447],
              [-38.529984817423276, -8.125915188614233],
              [-38.530029537738216, -8.125940476506862],
              [-38.530080067664002, -8.125965499749],
              [-38.530133693566597, -8.12598211448671],
              [-38.530191863347049, -8.125994121392315],
              [-38.530252670409915, -8.126001880071717],
              [-38.530309758107762, -8.126007734946841],
              [-38.530366576546989, -8.126010966383037],
              [-38.53042294029558, -8.126014920905778],
              [-38.530476030642262, -8.126023756073174],
              [-38.530516598493215, -8.126029320494011],
              [-38.530551541328258, -8.126032526656383],
              [-38.530640579506503, -8.126038961071673],
              [-38.530702300175228, -8.126041655403181],
              [-38.530756120057468, -8.126047596898704],
              [-38.530809128893758, -8.126048472115956],
              [-38.530862314438565, -8.126053508341606],
              [-38.530915595692228, -8.126054293406771],
              [-38.530969872897749, -8.126057250471561],
              [-38.531016796564678, -8.126061194027915],
              [-38.531067716641004, -8.126062971327652],
              [-38.531117821256451, -8.12606348134709],
              [-38.531163750653938, -8.126063805643385],
              [-38.531216121375664, -8.126067031835433],
              [-38.531265316506634, -8.126069078479956],
              [-38.531317329817206, -8.126067419816001],
              [-38.531410920909828, -8.126061467326497],
              [-38.531498357568012, -8.126040040361165],
              [-38.531545211766115, -8.126025621932875],
              [-38.531624031222528, -8.12599902922714],
              [-38.531704756842906, -8.125972438699959],
              [-38.531778502901545, -8.125937428045884],
              [-38.531844637149113, -8.125891282967887],
              [-38.531892243056888, -8.12585479496812],
              [-38.531928599957403, -8.125812685967649],
              [-38.531964689151174, -8.125766596748001],
              [-38.531996785080253, -8.125719960215953],
              [-38.532027880120467, -8.125675402935526],
              [-38.532058248783315, -8.125631025721583],
              [-38.532078359266926, -8.125587631682466],
              [-38.532091677286715, -8.125531023772833],
              [-38.532098633645042, -8.125481102026091],
              [-38.532112673732229, -8.125428022590908],
              [-38.532119722950753, -8.125376291901285],
              [-38.532151509697613, -8.125283071989411],
              [-38.532185372930897, -8.125199532873202],
              [-38.532232659214614, -8.125125416241382],
              [-38.5322760386018, -8.125054551399254],
              [-38.532318497289289, -8.124994901596427],
              [-38.532363921531982, -8.124961034144583],
              [-38.53238426698357, -8.124949841426769],
              [-38.532427313295749, -8.124931438602728],
              [-38.532440668307096, -8.124921232824704],
              [-38.532461976620333, -8.124862372768625],
              [-38.53247475548568, -8.124800970240708],
              [-38.532507741599254, -8.124769349788249],
              [-38.53257137015504, -8.124770417874483],
              [-38.53265526561912, -8.124749710191177],
              [-38.532701852552734, -8.124730768688471],
              [-38.532745804749034, -8.124713904560609],
              [-38.532796747060786, -8.124696324835378],
              [-38.532840699249874, -8.124679460696816],
              [-38.532888107802876, -8.124656449754667],
              [-38.532930795271028, -8.12463433791139],
              [-38.532999576976415, -8.124574808699089],
              [-38.533052287901171, -8.124519150485392],
              [-38.533079108810924, -8.124481281686897],
              [-38.533111057878536, -8.124404433761208],
              [-38.533128213647849, -8.124325669355448],
              [-38.533192524872163, -8.124285763159255],
              [-38.533270355455599, -8.124250485536509],
              [-38.533311136277241, -8.124228733271982],
              [-38.533377364081325, -8.12417978399656],
              [-38.533405091799594, -8.12414263983271],
              [-38.533454172343198, -8.124086525133906],
              [-38.533500977885161, -8.124035382705614],
              [-38.533531913642804, -8.123971287374745],
              [-38.533581117599525, -8.123886861177281],
              [-38.533622474517472, -8.123837792893244],
              [-38.53361923357577, -8.123893236541349],
              [-38.533617624086645, -8.123950762465988],
              [-38.533639135200069, -8.124030566198817],
              [-38.53367564087317, -8.124095191101857],
              [-38.533732632599992, -8.124105387093417],
              [-38.533779950373514, -8.124082285483624],
              [-38.533805053762315, -8.124038173442649],
              [-38.533811655770798, -8.123980472331349],
              [-38.533828781952671, -8.123927215448608],
              [-38.533853114940463, -8.123842760718802],
              [-38.533873874402808, -8.12378733713625],
              [-38.533899333744976, -8.123749376263087],
              [-38.533905647452251, -8.123784207697238],
              [-38.53390558526408, -8.12383811729921],
              [-38.533912234505472, -8.123896828570031],
              [-38.533927432172632, -8.123941619952262],
              [-38.533957882395683, -8.123983624777994],
              [-38.534004938796819, -8.124029718982284],
              [-38.534073343942318, -8.124060370256634],
              [-38.534151868659158, -8.124052771709927],
              [-38.534242640723789, -8.124050885673798],
              [-38.534292294186557, -8.124048862102919],
              [-38.534348308621936, -8.124040695037429],
              [-38.534429091841503, -8.124042415626727],
              [-38.534467632934337, -8.124073756283059],
              [-38.534503720643691, -8.124107355441112],
              [-38.534554345906045, -8.124128488784869],
              [-38.534626423997736, -8.124122601369262],
              [-38.534702892267568, -8.124087864620126],
              [-38.534760195394867, -8.124064141144817],
              [-38.534833388641786, -8.124035732298733],
              [-38.53489067540734, -8.124026209825187],
              [-38.534854047703874, -8.124067414285777],
              [-38.53482693082605, -8.124125996492808],
              [-38.534807916927036, -8.124163331628861],
              [-38.534781985563136, -8.124217121205861],
              [-38.534754328732021, -8.124271632427432],
              [-38.534719218141291, -8.12433536127563],
              [-38.534678761493588, -8.124390943282632],
              [-38.534637230494909, -8.124433589351723],
              [-38.534596059649438, -8.124478768497255],
              [-38.53457910934803, -8.124537000502634],
              [-38.534587033119209, -8.124592547387847],
              [-38.534599680678568, -8.124644843387317],
              [-38.534545699990183, -8.124620992633014],
              [-38.53449479089408, -8.124609808732828],
              [-38.534441139733872, -8.124615084039217],
              [-38.534346217319055, -8.124673859968285],
              [-38.534287495498383, -8.124747059257002],
              [-38.53422887568874, -8.124810489785723],
              [-38.534163812028183, -8.124873189306673],
              [-38.534100837412808, -8.124934715326205],
              [-38.53402642836901, -8.124993966926406],
              [-38.533954473536923, -8.125050236391674],
              [-38.53389494499168, -8.125114479897151],
              [-38.533837591985382, -8.12518125855458],
              [-38.533784965947603, -8.125320675948283],
              [-38.53378797827726, -8.125463322935188],
              [-38.533861021959964, -8.125564803850523],
              [-38.533942158204212, -8.125654173395551],
              [-38.533981770488026, -8.125701254057974],
              [-38.534032626697822, -8.125758387820143],
              [-38.534086663633516, -8.125812268930915],
              [-38.534149500805292, -8.125870049569208],
              [-38.534185669812452, -8.125911970479944],
              [-38.534221030969348, -8.1259460210982],
              [-38.534256121594581, -8.125978533712017],
              [-38.53428966735904, -8.12601258224627],
              [-38.53432239598861, -8.126046810747537],
              [-38.53435711760644, -8.126084478721545],
              [-38.534420131604051, -8.126146420337786],
              [-38.534480970679134, -8.126205284072126],
              [-38.53454689113525, -8.126265691304642],
              [-38.534578711687388, -8.126300280557997],
              [-38.534610442412607, -8.126334055634059],
              [-38.534672549931258, -8.126395001200372],
              [-38.534713450140288, -8.126427339519228],
              [-38.534758349980223, -8.126454707523129],
              [-38.534801349587674, -8.126476917558307],
              [-38.534844798051637, -8.126503469821033],
              [-38.534892978556996, -8.126519625456005],
              [-38.534948429337554, -8.126528281842633],
              [-38.534997711903159, -8.126533313071416],
              [-38.535050624763358, -8.126538800706347],
              [-38.535100449455612, -8.126546003401888],
              [-38.535148001872486, -8.126555826616373],
              [-38.53523975799456, -8.126566966670016],
              [-38.535285235396557, -8.126565752295688],
              [-38.535368032106597, -8.126553545345216],
              [-38.535439543431707, -8.126567285349957],
              [-38.535511583573609, -8.126594774723202],
              [-38.535580260646228, -8.126626240153429],
              [-38.535646846995753, -8.126660326307762],
              [-38.53568958694909, -8.126671591205993],
              [-38.53573768100599, -8.126684038093341],
              [-38.535791403973548, -8.126695496421988],
              [-38.535829496662402, -8.126722404281699],
              [-38.535796084010173, -8.126730325968888],
              [-38.535774569035944, -8.126732381821199],
              [-38.535706572237942, -8.126740806742893],
              [-38.535645843204847, -8.126743903253862],
              [-38.535585379306163, -8.12675324127961],
              [-38.535541686189532, -8.12678150301509],
              [-38.535516038174308, -8.126825614536404],
              [-38.535509074920768, -8.126881868026098],
              [-38.535485682736137, -8.126937650490559],
              [-38.535434080495257, -8.126976395667281],
              [-38.535388643143627, -8.127021660457315],
              [-38.535351105309545, -8.127064763406768],
              [-38.535315916468242, -8.127117456998162],
              [-38.535278732540291, -8.127168520161892],
              [-38.535255597212689, -8.127237689869197],
              [-38.535252611681969, -8.127307968029401],
              [-38.535255801251878, -8.127375901481248],
              [-38.535272337319164, -8.127441046156786],
              [-38.535311375643865, -8.12751390500283],
              [-38.535361321645283, -8.127573570246113],
              [-38.535383986713484, -8.127597566044603],
              [-38.53541735376519, -8.127629352979906],
              [-38.535368074492609, -8.127621336866724],
              [-38.535322892491664, -8.127602380679795],
              [-38.535278637976965, -8.127566239578192],
              [-38.535245550019376, -8.127528573540586],
              [-38.535213206657595, -8.127474898263968],
              [-38.535179775411038, -8.127420045859694],
              [-38.535152605986212, -8.127366376491979],
              [-38.535131081102591, -8.12729815072646],
              [-38.535104018080027, -8.127230913606859],
              [-38.535062867627424, -8.12717930865985],
              [-38.535015894128883, -8.127139636778265],
              [-38.534956114505242, -8.127106462827498],
              [-38.534890144480109, -8.1270889300667],
              [-38.534808380401792, -8.127071379231637],
              [-38.534758193508409, -8.127063362010679],
              [-38.534710367691162, -8.127054442959922],
              [-38.534654462576832, -8.127046147827492],
              [-38.534600279602245, -8.127040025517136],
              [-38.534537750022693, -8.127030185096864],
              [-38.534475668043562, -8.127025772327348],
              [-38.53437547822756, -8.12700765760248],
              [-38.534331008013801, -8.12700127501842],
              [-38.534289439952474, -8.126997066611635],
              [-38.534223908185297, -8.126993102116353],
              [-38.534157282584438, -8.126993116266107],
              [-38.534088839488739, -8.126994937372203],
              [-38.534025481206875, -8.126995317052774],
              [-38.53396312275698, -8.126994521987218],
              [-38.533901667419244, -8.126997707855184],
              [-38.533838221813163, -8.126995102475433],
              [-38.533776853486991, -8.127001544712639],
              [-38.533724372933925, -8.127014690611094],
              [-38.533676980534906, -8.127023500618465],
              [-38.533629070563144, -8.127008882841073],
              [-38.533593885689278, -8.126979354995592],
              [-38.533536168551507, -8.126968163154842],
              [-38.533458293489595, -8.12696291804639],
              [-38.533411643452638, -8.126957708744383],
              [-38.533355284849904, -8.126949141557919],
              [-38.533304191874336, -8.126939766333631],
              [-38.533250199475006, -8.126925865153307],
              [-38.533199926784441, -8.12691359637912],
              [-38.533142390060782, -8.126903399667542],
              [-38.533086568462444, -8.126901436089678],
              [-38.533026027850347, -8.1268985625618],
              [-38.532968204598824, -8.126900667029659],
              [-38.532904656431327, -8.126908282514107],
              [-38.532844007892855, -8.126920243036199],
              [-38.532788709363921, -8.126936913221618],
              [-38.532735401710248, -8.126958831928121],
              [-38.532689803465821, -8.126986005726728],
              [-38.532644290752543, -8.127017702240106],
              [-38.532602683253991, -8.12704759418691],
              [-38.532532171597893, -8.127111282122172],
              [-38.532500816953636, -8.127144803938945],
              [-38.532469091055134, -8.127185380616419],
              [-38.532445444140009, -8.127225604768739],
              [-38.532432318321852, -8.127272986757138],
              [-38.532423008002162, -8.127317659558212],
              [-38.532407031534198, -8.127396787073556],
              [-38.53241192243383, -8.127484441108221],
              [-38.532421670565533, -8.127532030297301],
              [-38.532437312082244, -8.127585415217395],
              [-38.532458399521815, -8.127639077754971],
              [-38.532482026238839, -8.12769473316426],
              [-38.532507919294702, -8.127752923845783],
              [-38.532529006960758, -8.127806405475569],
              [-38.532548550065954, -8.127861151663309],
              [-38.532570906751154, -8.12791617244141],
              [-38.532595170881635, -8.127970109981424],
              [-38.5326248818849, -8.128023511065336],
              [-38.53265424258386, -8.128065876547504],
              [-38.532700810150004, -8.128142452821436],
              [-38.532726632169307, -8.128183638351144],
              [-38.532742487314806, -8.128209345060958],
              [-38.532750551044344, -8.128222198572075],
              [-38.532781001123304, -8.128264474845389],
              [-38.532812811304154, -8.128308019013373],
              [-38.532848253052897, -8.128350934183821],
              [-38.53288133131732, -8.12839683156713],
              [-38.532905425692249, -8.128440638223083],
              [-38.532935413265996, -8.128490421507504],
              [-38.532964495963881, -8.128537761534808],
              [-38.53299539481614, -8.128584470478932],
              [-38.533031736679767, -8.128633989697057],
              [-38.533067443784148, -8.128682965468727],
              [-38.533100518752775, -8.128731757310661],
              [-38.533136684643132, -8.128776482339088],
              [-38.533169686320264, -8.128810168534288],
              [-38.533231325685051, -8.12888359622181],
              [-38.533277530993743, -8.128959629307509],
              [-38.533300084847362, -8.129001263316402],
              [-38.533318880300477, -8.129074732270178],
              [-38.533332893023072, -8.129123864027617],
              [-38.533367326067626, -8.129175732792193],
              [-38.533407542433238, -8.129250130848451],
              [-38.533432091185453, -8.129293576189049],
              [-38.533451567116721, -8.129328060916153],
              [-38.533476738001873, -8.129382994428351],
              [-38.533487874821496, -8.12940724846027],
              [-38.533514277007214, -8.129496374406235],
              [-38.533528334346833, -8.129585486188475],
              [-38.533534091858563, -8.129630357204645],
              [-38.533546336710863, -8.129716934238548],
              [-38.533560771880914, -8.129793292658816],
              [-38.533576302787175, -8.129864044284238],
              [-38.533592303227486, -8.129921228581624],
              [-38.533619102506549, -8.129980957933867],
              [-38.533643107844846, -8.130023407663671],
              [-38.533685669656776, -8.130110562181356],
              [-38.53371871942457, -8.130181333894546],
              [-38.533771007570579, -8.130256559836752],
              [-38.53380118913983, -8.130295760352933],
              [-38.533841038947422, -8.13037332378803],
              [-38.533874995220884, -8.130445181959049],
              [-38.533907762916783, -8.130524455865288],
              [-38.533944801049962, -8.130600116566994],
              [-38.533966358556704, -8.130640030805896],
              [-38.534007487473254, -8.130710449950284],
              [-38.53404132417019, -8.130728669673775],
              [-38.534067813094978, -8.130742810613693],
              [-38.534131578745345, -8.130783225478103],
              [-38.534188528525355, -8.130830597361966],
              [-38.534237668542744, -8.13088094522185],
              [-38.534289801730182, -8.130933286458905],
              [-38.534349739387501, -8.130987264773331],
              [-38.534408494842687, -8.131043141227078],
              [-38.534459615610366, -8.13110760191273],
              [-38.53449937307483, -8.131186702877427],
              [-38.534530866811011, -8.131268869760236],
              [-38.534556741805041, -8.131343160844935],
              [-38.534579362281292, -8.131405960741485],
              [-38.534594353999971, -8.131472189096128],
              [-38.534622224424709, -8.131547839248579],
              [-38.534647280526237, -8.131623847987438],
              [-38.53469313404873, -8.131690563929414],
              [-38.534748626151377, -8.13174272805456],
              [-38.534817470229534, -8.131787490351796],
              [-38.534890226670967, -8.13182429729917],
              [-38.534934416876219, -8.131837825273529],
              [-38.535006189543672, -8.131861877280173],
              [-38.53508334347471, -8.131863593673927],
              [-38.535150629455686, -8.131842595203254],
              [-38.535223807455864, -8.131828658749221],
              [-38.535292809961348, -8.131814717508218],
              [-38.535361419910551, -8.131826373851251],
              [-38.535415318316566, -8.131843711874009],
              [-38.535486264289887, -8.131876265383516],
              [-38.535531547842254, -8.131886266897975],
              [-38.535576288120751, -8.131895091904882],
              [-38.535625389702609, -8.13190021333857],
              [-38.535716950004769, -8.131924830542069],
              [-38.535798539282084, -8.13193740616186],
              [-38.535870545262199, -8.131916503413208],
              [-38.535909700564176, -8.131888055593237],
              [-38.535948493294526, -8.131859155092945],
              [-38.535989924320972, -8.131825101809799],
              [-38.53602690628766, -8.13179212887345],
              [-38.536062525843768, -8.131759877998181],
              [-38.536128496760959, -8.131698355156852],
              [-38.536191380491694, -8.131637642853399],
              [-38.536252176147563, -8.131577199514284],
              [-38.536282177498677, -8.131536530128676],
              [-38.536310363206411, -8.131496039574317],
              [-38.536340999737867, -8.131455551813005],
              [-38.536371995089418, -8.131418773009409],
              [-38.536405717526151, -8.131378560119154],
              [-38.536435893014392, -8.13134431304408],
              [-38.536499413912821, -8.131281973268385],
              [-38.536572643386798, -8.131223081743018],
              [-38.536609715399386, -8.131190651564498],
              [-38.536669600511836, -8.131132558880964],
              [-38.536701591490768, -8.131098223400148],
              [-38.536737676103286, -8.131056113668485],
              [-38.536769848924429, -8.131021507031285],
              [-38.536832095600687, -8.130962060224338],
              [-38.536891168167053, -8.130900077121586],
              [-38.536938183068784, -8.130825055118519],
              [-38.536960067994016, -8.130738064760488],
              [-38.53698135599474, -8.130696661770282],
              [-38.53703345269787, -8.130622821422953],
              [-38.537102408775745, -8.130569985239342],
              [-38.537192841021138, -8.130549103217138],
              [-38.537281450472086, -8.130534641226083],
              [-38.537349090822623, -8.130520969426229],
              [-38.537308928041888, -8.130557466524181],
              [-38.537288298703622, -8.130578608927364],
              [-38.537251496626524, -8.13061321034165],
              [-38.537212155124486, -8.130645909359423],
              [-38.537173439163773, -8.130687202070863],
              [-38.53714414536617, -8.130744244237004],
              [-38.537121104513822, -8.130810248315447],
              [-38.537098147268118, -8.13088249370832],
              [-38.537084913024991, -8.130945614470097],
              [-38.537073869840967, -8.130997792975986],
              [-38.537054004402961, -8.131065157451445],
              [-38.537026145887779, -8.131137035446482],
              [-38.536989217586608, -8.131202480983099],
              [-38.536954539363805, -8.13128466278658],
              [-38.536936059741279, -8.131330862952545],
              [-38.536923028787996, -8.131374988917113],
              [-38.536908539469664, -8.131424359463326],
              [-38.536888919520209, -8.131515060944757],
              [-38.536869578828586, -8.131599702426712],
              [-38.536847334477123, -8.13168343607399],
              [-38.536840836559975, -8.131729740336826],
              [-38.536840962680181, -8.131778042101111],
              [-38.536839727849518, -8.131825799599817],
              [-38.536845204509312, -8.131878449178947],
              [-38.536857312580715, -8.131926674142376],
              [-38.536865882742362, -8.131972995572765],
              [-38.536889930430398, -8.132058048294859],
              [-38.536922341673822, -8.132132165794715],
              [-38.536982360165815, -8.132195279538832],
              [-38.537053736233943, -8.132248727795835],
              [-38.537109871188207, -8.132294741582141],
              [-38.537153850115921, -8.132334319393928],
              [-38.537187761278453, -8.132367011350119],
              [-38.537221126779265, -8.132400607207209],
              [-38.537244350019485, -8.132413116088498],
              [-38.53731440875864, -8.132428030048775],
              [-38.537396533400695, -8.132448837140613],
              [-38.537484105183616, -8.132468926794823],
              [-38.537566978299758, -8.13247028742189],
              [-38.537644316519199, -8.13246974223415],
              [-38.537688966874569, -8.132477933744685],
              [-38.537672612839636, -8.132491121205513],
              [-38.537615305113007, -8.132517920404124],
              [-38.537546651846554, -8.13254389261364],
              [-38.537478614071993, -8.132587232387005],
              [-38.537428075635809, -8.132647687581743],
              [-38.537396396248916, -8.132726887896352],
              [-38.537380984696647, -8.132788920753514],
              [-38.537353831389751, -8.132799834596412],
              [-38.537302194173797, -8.132789373782034],
              [-38.537252273224084, -8.132786241565618],
              [-38.537191907920693, -8.132787981884219],
              [-38.537125555103664, -8.132786730443616],
              [-38.537049214591512, -8.132787909832947],
              [-38.536962346837434, -8.132787087260946],
              [-38.536868759067623, -8.132788789682392],
              [-38.53678996169819, -8.132795212466611],
              [-38.536748968884837, -8.132763778741063],
              [-38.536739082861075, -8.132756712180962],
              [-38.536685630443472, -8.132746068315569],
              [-38.53660990571651, -8.132764343840726],
              [-38.536555789337058, -8.132778573629283],
              [-38.536505392726745, -8.132794435796686],
              [-38.536455899571052, -8.132814007538952],
              [-38.536409954094601, -8.132826980291533],
              [-38.536318793152887, -8.132849579872374],
              [-38.536275375975606, -8.132874133408597],
              [-38.536233689485627, -8.132893442671969],
              [-38.536190455297685, -8.13291673007288],
              [-38.536146581336105, -8.132943815742058],
              [-38.536106517393137, -8.132972986161249],
              [-38.536044089265673, -8.133031980358814],
              [-38.536012100150991, -8.1330645067261],
              [-38.535981734940187, -8.133105718380367],
              [-38.535957720215549, -8.133150012666063],
              [-38.535935884633865, -8.13319376672333],
              [-38.535912958432753, -8.133238695415685],
              [-38.535897746794895, -8.13328453744036],
              [-38.535891338881228, -8.13333138449447],
              [-38.535903955303382, -8.133411087513602],
              [-38.535949282278189, -8.133462425812162],
              [-38.536004956223401, -8.133514680443762],
              [-38.536064801116694, -8.133570919737712],
              [-38.536103259095398, -8.133596471229664],
              [-38.536143621522534, -8.133623562583539],
              [-38.536179989469431, -8.133651101637231],
              [-38.536254095689252, -8.13369858329289],
              [-38.53630162870725, -8.133726044624124],
              [-38.536353610182438, -8.133752968310475],
              [-38.536399784439936, -8.133777985864395],
              [-38.536452216879596, -8.133807442722084],
              [-38.536505378723774, -8.133834096378964],
              [-38.53656108437287, -8.133858853428487],
              [-38.536619246378329, -8.133878819291112],
              [-38.536676232923277, -8.133894803897148],
              [-38.536740845568858, -8.133909711759152],
              [-38.536803554291431, -8.13392262748693],
              [-38.536867804274394, -8.133937173106043],
              [-38.536928607416478, -8.133949543928606],
              [-38.536986323690876, -8.133962453940073],
              [-38.537048299095083, -8.133981610015519],
              [-38.537102376300183, -8.134001661610279],
              [-38.537156085442952, -8.134026054497289],
              [-38.537206979206466, -8.13405171050599],
              [-38.537249790736361, -8.134080432689473],
              [-38.537315709428846, -8.134143824472975],
              [-38.537351161281876, -8.134178779487605],
              [-38.537394602226151, -8.13421274861666],
              [-38.537444127189914, -8.134244734712253],
              [-38.537493476734269, -8.134271383906736],
              [-38.537545820521323, -8.134299121932695],
              [-38.53759915565724, -8.134333102300783],
              [-38.537651949382401, -8.134364278019627],
              [-38.537710373372164, -8.134393379730289],
              [-38.53777115837908, -8.134421670046189],
              [-38.537829586426938, -8.134447244098618],
              [-38.537888105565507, -8.134472546889294],
              [-38.537943986207956, -8.134503183367746],
              [-38.538001138808831, -8.134532826307845],
              [-38.538054386350638, -8.134564092945736],
              [-38.538106455312054, -8.13459409190267],
              [-38.538160980721408, -8.134619209211529],
              [-38.538213598886401, -8.134645228870728],
              [-38.538262045780606, -8.134667535232097],
              [-38.538305410885364, -8.134688569479598],
              [-38.538364102736509, -8.134721651314349],
              [-38.53841136950652, -8.134744046770653],
              [-38.538455101648573, -8.134761644225675],
              [-38.538499927303185, -8.134775534366335],
              [-38.538552916773341, -8.134794408659468],
              [-38.538603819935226, -8.134812014242222],
              [-38.538657352187656, -8.134832517283169],
              [-38.538711163277384, -8.1348473221275],
              [-38.538772332611238, -8.134857160430409],
              [-38.538833147336824, -8.134859581218096],
              [-38.538904046570778, -8.13485432497883],
              [-38.538966330810382, -8.134841732301503],
              [-38.539027262659879, -8.134821087808962],
              [-38.539085560868429, -8.134801525749483],
              [-38.539139324798555, -8.134778159534443],
              [-38.539213832929107, -8.134712665975345],
              [-38.539280428051015, -8.134661002660568],
              [-38.539344928786399, -8.134615125919099],
              [-38.539394451061582, -8.134570136713938],
              [-38.539419483211574, -8.134509019201644],
              [-38.539429578045421, -8.134571804672976],
              [-38.539442142934782, -8.134617768779925],
              [-38.53944871975073, -8.134661193430127],
              [-38.539447034436265, -8.134706236861165],
              [-38.539437602713441, -8.134778226354882],
              [-38.539427729515033, -8.134839361048607],
              [-38.539414953562044, -8.134898773854266],
              [-38.53940163059621, -8.134960266446301],
              [-38.539404920609442, -8.135020330629127],
              [-38.539476204973596, -8.135075316086681],
              [-38.539505858718975, -8.135100585938561],
              [-38.539561465922183, -8.135132397777628],
              [-38.53962516229663, -8.13515508307287],
              [-38.539671712819391, -8.135168884590437],
              [-38.539750312689804, -8.135176842993777],
              [-38.539829105250426, -8.135175032730928],
              [-38.539909831776249, -8.13514889292124],
              [-38.539986666116548, -8.135113070271389],
              [-38.54006068804842, -8.135075887635386],
              [-38.540132613234881, -8.135046571979567],
              [-38.540203442959125, -8.135022682220823],
              [-38.540261286876031, -8.135003481287258],
              [-38.540293979232707, -8.13499094537657],
              [-38.54033422361897, -8.134962679270899],
              [-38.540304829966828, -8.13502777182012],
              [-38.540278800755395, -8.135087712380363],
              [-38.54025449662263, -8.135147293080237],
              [-38.54023291462223, -8.135207781383089],
              [-38.540223044105943, -8.13526665479147],
              [-38.540221791499583, -8.135330331933316],
              [-38.540222460471114, -8.135380533827057],
              [-38.540223379082612, -8.13545063555277],
              [-38.540232341019234, -8.135472173388289],
              [-38.540248907431724, -8.135511629422748],
              [-38.540294063041465, -8.135554735903343],
              [-38.540337129864078, -8.135598744541344],
              [-38.540390456147513, -8.135640865278054],
              [-38.5404462447352, -8.135672948565746],
              [-38.540514370765003, -8.135711739283575],
              [-38.540540410618725, -8.13572217076824],
              [-38.540570714720225, -8.135734416123132],
              [-38.540631590818037, -8.135762796586793],
              [-38.540647921111713, -8.135770503512108],
              [-38.540721127658429, -8.135811742158394],
              [-38.540794521942921, -8.135847553854235],
              [-38.54087791758468, -8.135868994890959],
              [-38.540961883394914, -8.135868004333917],
              [-38.541004468567586, -8.135856745881023],
              [-38.541007828076694, -8.135855935603663],
              [-38.541052137101602, -8.135845402713056],
              [-38.541140373948849, -8.135840256127892],
              [-38.541228597320611, -8.135846958786829],
              [-38.54130992620162, -8.135849945090445],
              [-38.541378274306467, -8.135853188083361],
              [-38.541434551724457, -8.135854608391346],
              [-38.541471732167025, -8.1358865800893],
              [-38.541533633581324, -8.135891443926193],
              [-38.541607536087184, -8.135879497126266],
              [-38.541693976500426, -8.135857614678958],
              [-38.541738917528917, -8.135850067358358],
              [-38.541824633393674, -8.135826736825019],
              [-38.541839887798758, -8.135822683663426],
              [-38.541910348941329, -8.135803677630225],
              [-38.541936317787474, -8.135796742064226],
              [-38.541955204220528, -8.135791698015101],
              [-38.542038552174304, -8.135775239157207],
              [-38.542118728525359, -8.135753982724074],
              [-38.542198544857946, -8.135730012293374],
              [-38.542269734100479, -8.135709288418369],
              [-38.542338096665873, -8.135699777459452],
              [-38.542402189843749, -8.13569297525634],
              [-38.542466292354632, -8.135677941873686],
              [-38.542521590853006, -8.135662084495372],
              [-38.542571801678498, -8.135650110840203],
              [-38.54265398041106, -8.13562406256877],
              [-38.542733715965738, -8.135591227590925],
              [-38.542814629192797, -8.135560474329971],
              [-38.542895913594009, -8.135522575719845],
              [-38.542938235284559, -8.135503447392496],
              [-38.54301796905316, -8.135472150047216],
              [-38.543093984008046, -8.135438315832847],
              [-38.543155841113403, -8.135402113919705],
              [-38.543213523393092, -8.135365274135404],
              [-38.543263653939611, -8.135343983689996],
              [-38.543311127713537, -8.135344217955716],
              [-38.543385220124989, -8.135324763476614],
              [-38.543431709526089, -8.135312514171714],
              [-38.543475295026006, -8.135299537978323],
              [-38.543521333936994, -8.135284303222893],
              [-38.543602340073306, -8.135251559946513],
              [-38.543681421255933, -8.135235367306528],
              [-38.543727175970226, -8.13523062470259],
              [-38.543809787757603, -8.13522284808813],
              [-38.543899754765988, -8.135212727946687],
              [-38.543946057996038, -8.135204548736786],
              [-38.543990098150488, -8.135190849376555],
              [-38.544073289722554, -8.135151957682446],
              [-38.54414801665834, -8.135133227392497],
              [-38.544231902607542, -8.135122467186013],
              [-38.544313623398395, -8.135099855249782],
              [-38.544387453112272, -8.135071807295729],
              [-38.544454377478097, -8.135049631002062],
              [-38.544499776633884, -8.135038194421961],
              [-38.544585851916715, -8.135017848624315],
              [-38.54463134102167, -8.135007135750296],
              [-38.544675108817948, -8.134993436008038],
              [-38.544717519006589, -8.134976207090105],
              [-38.54476201879298, -8.134957352362592],
              [-38.544808326369285, -8.13494528359676],
              [-38.544895047943129, -8.134915260031887],
              [-38.544970071093246, -8.134875273566585],
              [-38.545041104223998, -8.134831754969444],
              [-38.545106064262001, -8.13478054109277],
              [-38.545160035328095, -8.134734199343175],
              [-38.545203851404516, -8.134677625080714],
              [-38.545240686667405, -8.134613354532746],
              [-38.545275335432805, -8.134556136833837],
              [-38.545316407920424, -8.134517649999635],
              [-38.545367373149489, -8.134480440564527],
              [-38.545424412263905, -8.134450202764446],
              [-38.545476090407561, -8.134424752945733],
              [-38.545524147333538, -8.134390706076276],
              [-38.545588261871103, -8.134364727435321],
              [-38.545662539835291, -8.134341473723943],
              [-38.545737445661949, -8.134324914190499],
              [-38.545797565181047, -8.134299835575071],
              [-38.545865037955309, -8.134274131999723],
              [-38.545933690556687, -8.134248610636092],
              [-38.545995453010669, -8.134215483546631],
              [-38.546047245674536, -8.134168867876848],
              [-38.546086994318145, -8.134096731121121],
              [-38.546127748867555, -8.1340179924458],
              [-38.54614703865051, -8.133977038971487],
              [-38.546189242681166, -8.133900834579155],
              [-38.546228454063247, -8.133822094167607],
              [-38.546252336436496, -8.1337331154953],
              [-38.546260472106695, -8.133682471134192],
              [-38.546265971926438, -8.13363489921756],
              [-38.546272923690871, -8.133587690732053],
              [-38.546277609304546, -8.133537675686245],
              [-38.546278025579653, -8.13349036945136],
              [-38.54628107546376, -8.133441980725557],
              [-38.546275460452556, -8.133349803277],
              [-38.546261805337004, -8.133304019026465],
              [-38.546237752532306, -8.133221680324114],
              [-38.546212246469722, -8.133140154071262],
              [-38.546177303592259, -8.133055541892441],
              [-38.546134274194486, -8.132977885529749],
              [-38.546089151463569, -8.132905201717294],
              [-38.546061689896362, -8.132867542774324],
              [-38.546009388885359, -8.132801363522722],
              [-38.545958092690825, -8.132729577327657],
              [-38.545907613460905, -8.132657792039506],
              [-38.545857676131597, -8.13258844957099],
              [-38.545806195494563, -8.132519286277573],
              [-38.545764431631838, -8.132446425281531],
              [-38.545748140247824, -8.132404165720946],
              [-38.545717088291013, -8.132330593070979],
              [-38.545683400298543, -8.132260273765613],
              [-38.545665880684297, -8.132180203651439],
              [-38.545647456820745, -8.132097057138168],
              [-38.545630742862151, -8.13202702815942],
              [-38.545611674648725, -8.131951840751425],
              [-38.545586437531789, -8.131873480606265],
              [-38.545551581479778, -8.131792577034167],
              [-38.545531385432731, -8.131751669889624],
              [-38.54549325367843, -8.131677817952129],
              [-38.545463994842024, -8.131624146858005],
              [-38.545428923065323, -8.131573635102155],
              [-38.545384868271221, -8.131520128362711],
              [-38.545336918155584, -8.131459652413758],
              [-38.545295579706242, -8.131412209026355],
              [-38.545252782094472, -8.131370824325698],
              [-38.54521425197705, -8.131328358964053],
              [-38.545180446471853, -8.131281918971696],
              [-38.545138015029366, -8.131237821092357],
              [-38.545097929079461, -8.131206660552085],
              [-38.54503677262565, -8.131185697568817],
              [-38.544986945794413, -8.1311796719326],
              [-38.544943557012431, -8.131179623366911],
              [-38.544900163437916, -8.131183826062118],
              [-38.544838515406056, -8.131196329951635],
              [-38.544768422559898, -8.131211537952357],
              [-38.544806316712183, -8.131173952114287],
              [-38.544823868556414, -8.131144755585648],
              [-38.544844343851388, -8.131098919055562],
              [-38.544864194143216, -8.131043855661824],
              [-38.544873877413139, -8.13098932360016],
              [-38.544878133156466, -8.13091814218812],
              [-38.544872192923599, -8.130873180608216],
              [-38.544870303141757, -8.130858615627988],
              [-38.544854661789408, -8.130803512498845],
              [-38.544825318343158, -8.130744504567778],
              [-38.544791253058605, -8.130687029041843],
              [-38.544771148727179, -8.130645398346555],
              [-38.544758323653816, -8.130588217958552],
              [-38.544752135399079, -8.130521728386478],
              [-38.544761928410296, -8.130450372270055],
              [-38.544762641938647, -8.130381086395857],
              [-38.544755639904544, -8.130311791882329],
              [-38.544750628210934, -8.130248379014679],
              [-38.544744621706123, -8.130181708740185],
              [-38.544728974578284, -8.130131761397141],
              [-38.544713693599121, -8.130079100886284],
              [-38.544699584999456, -8.130033225631067],
              [-38.544654114134545, -8.129947787477406],
              [-38.544606707811873, -8.129888307041075],
              [-38.544562743258155, -8.129835523945568],
              [-38.544515778062326, -8.129787350565829],
              [-38.544471532849435, -8.129741984258217],
              [-38.544435897946038, -8.12970811507053],
              [-38.544399082308992, -8.129674877725037],
              [-38.544357911908214, -8.129639645539811],
              [-38.544321637950361, -8.129609031919486],
              [-38.544283821603294, -8.12957778339949],
              [-38.544227596794485, -8.129530504136939],
              [-38.544194774507744, -8.129497813966029],
              [-38.544147530239734, -8.129455700556925],
              [-38.544111347085746, -8.129425087021176],
              [-38.544074169336433, -8.129391035169407],
              [-38.544037808737492, -8.129356803325738],
              [-38.544005259100032, -8.129323842088839],
              [-38.543938802055131, -8.129254571339983],
              [-38.543903714420431, -8.129218441409137],
              [-38.543866446141045, -8.129184208533374],
              [-38.543824009437003, -8.129145085417521],
              [-38.543786562592906, -8.129108229210248],
              [-38.543744852072805, -8.129069106901563],
              [-38.543699239633987, -8.129028894779685],
              [-38.543664240664647, -8.128994664433415],
              [-38.543624614629621, -8.128958438934879],
              [-38.54357774338397, -8.128907642437257],
              [-38.543575228993021, -8.128883579231937],
              [-38.543621051876947, -8.128898193525712],
              [-38.543670136488792, -8.128918148176407],
              [-38.543730015699076, -8.128944446613552],
              [-38.543788430215848, -8.128981688160044],
              [-38.54384792817136, -8.129024086710569],
              [-38.543912321848964, -8.129071736993271],
              [-38.543980706607222, -8.129121924414445],
              [-38.544047182432976, -8.129174551903807],
              [-38.544116473813183, -8.129225735298586],
              [-38.544196302415955, -8.129270056098651],
              [-38.544239574794538, -8.129292627293621],
              [-38.544313317474582, -8.129340740258662],
              [-38.544379989417124, -8.129380523651417],
              [-38.544451367489529, -8.129432704309281],
              [-38.544518932785358, -8.129485242494898],
              [-38.544569886572944, -8.129538123874676],
              [-38.544633818301818, -8.129593100192363],
              [-38.544671457523101, -8.129620459022108],
              [-38.544709369369066, -8.129647546796143],
              [-38.544777843262892, -8.129699362338695],
              [-38.544814928903236, -8.129734680363272],
              [-38.544868967554493, -8.129788831498335],
              [-38.544908601329972, -8.129818363402283],
              [-38.544950511833719, -8.129841294812344],
              [-38.545010662259955, -8.129868769258728],
              [-38.545057762023291, -8.129878138577832],
              [-38.545105583780469, -8.129891217255294],
              [-38.545151046915777, -8.129903026952569],
              [-38.545203396949944, -8.129925246395453],
              [-38.545260550734284, -8.129953802885424],
              [-38.545332325522402, -8.129976315394796],
              [-38.545396757625333, -8.129990136233959],
              [-38.545454464411847, -8.130011276204042],
              [-38.545526509092824, -8.130035959844024],
              [-38.545592657519506, -8.13005719968362],
              [-38.545654639738238, -8.130070203671695],
              [-38.545678599282013, -8.130073848548625],
              [-38.545713721657201, -8.13007931494864],
              [-38.545769190130308, -8.130072954787432],
              [-38.545830842444779, -8.130056470836045],
              [-38.545892762286798, -8.13004423844564],
              [-38.545964829281793, -8.130049112916963],
              [-38.546028824247131, -8.130048098944277],
              [-38.546080737074405, -8.130055574016808],
              [-38.546124551736703, -8.130080406940792],
              [-38.546178336401319, -8.130118457067574],
              [-38.546242914829278, -8.130163664989103],
              [-38.546319210902475, -8.13020164976874],
              [-38.546362671048321, -8.130218884254827],
              [-38.546434973917364, -8.130256231390216],
              [-38.546488490386331, -8.130290663075408],
              [-38.546552885303676, -8.130337679797938],
              [-38.546587981508374, -8.13036648286532],
              [-38.54665455303256, -8.130415220599593],
              [-38.546717306081966, -8.130469381222456],
              [-38.546780974173437, -8.130517030269271],
              [-38.54684136131074, -8.130576434487814],
              [-38.546872818781999, -8.130612288779583],
              [-38.546924656378948, -8.130686879494881],
              [-38.546967691296466, -8.130759560890704],
              [-38.546992204791458, -8.130835839748936],
              [-38.547015531972988, -8.130917725345263],
              [-38.547032913003463, -8.130959714718289],
              [-38.547063602941449, -8.131032472803128],
              [-38.547088846497182, -8.131105315270908],
              [-38.54712089965178, -8.131176627629207],
              [-38.547153325644572, -8.131239256950895],
              [-38.547179488104589, -8.131302150651353],
              [-38.54720638536908, -8.131357356697746],
              [-38.54723728014153, -8.13140940135383],
              [-38.547280882946119, -8.131461460160393],
              [-38.547320885125345, -8.131486560077871],
              [-38.547367817901645, -8.131483175138825],
              [-38.547378319011919, -8.131427558480333],
              [-38.547370212045585, -8.131371830650451],
              [-38.547364552160758, -8.131319452291281],
              [-38.547362704581175, -8.131267168629392],
              [-38.547354326622859, -8.13121026461342],
              [-38.54734866643696, -8.131158157611342],
              [-38.547331022382409, -8.131107846317535],
              [-38.547299216758788, -8.131058604684823],
              [-38.547264603617066, -8.131003661409386],
              [-38.547230540693171, -8.130943743850978],
              [-38.547216724991451, -8.130879235757901],
              [-38.547198650339901, -8.130808210333406],
              [-38.547175220787963, -8.13073663622826],
              [-38.547159135916282, -8.130672035153385],
              [-38.547153128096134, -8.130606178965953],
              [-38.547162727478359, -8.130545224603004],
              [-38.547165704097353, -8.130481097021903],
              [-38.547169663676975, -8.13043080978383],
              [-38.5471639064587, -8.130384310732536],
              [-38.547169403468303, -8.130339090552466],
              [-38.547168635123462, -8.130295762898708],
              [-38.547129215440968, -8.130237015251344],
              [-38.547071360060514, -8.13018638779806],
              [-38.546999788079034, -8.130144790315246],
              [-38.546928031829459, -8.130105634835392],
              [-38.546859351158183, -8.130075980294631],
              [-38.546788571995123, -8.130055911379481],
              [-38.546720336263391, -8.130034217141027],
              [-38.546649017465008, -8.130009715423357],
              [-38.546579426072633, -8.12998277339967],
              [-38.546512552849435, -8.129960266573596],
              [-38.546435597115675, -8.129943899266374],
              [-38.546369076830125, -8.129930438070952],
              [-38.546303457858414, -8.129922676384146],
              [-38.546234204888663, -8.129917714661747],
              [-38.546187210294335, -8.129895501326029],
              [-38.54612798436618, -8.129853012950447],
              [-38.546087627982786, -8.129820224060314],
              [-38.546048170877008, -8.129794943735879],
              [-38.546000277953318, -8.129764407738143],
              [-38.545993927427148, -8.129761325259066],
              [-38.545958909170722, -8.129744009710995],
              [-38.545906198469275, -8.129719619099211],
              [-38.54585004182691, -8.129692239696487],
              [-38.545792075975925, -8.12965934065722],
              [-38.545721948120857, -8.129625252138721],
              [-38.545669894833658, -8.129581324472685],
              [-38.545629807775661, -8.129551249418787],
              [-38.545591713107967, -8.129525246954326],
              [-38.545511568906079, -8.129519187510235],
              [-38.545441391610503, -8.129529058848195],
              [-38.545359121379406, -8.129557007170096],
              [-38.545267880685365, -8.129570834839113],
              [-38.545185714758148, -8.129586753047597],
              [-38.545116540412522, -8.129592645536615],
              [-38.545026327338782, -8.129580604861538],
              [-38.544954216060212, -8.12953457425453],
              [-38.544908178643901, -8.129468582798374],
              [-38.54489118126844, -8.129408593712483],
              [-38.54488452191309, -8.129357390097502],
              [-38.54487703909404, -8.129311974525995],
              [-38.544872643512683, -8.129265657883991],
              [-38.54486801997011, -8.1291799941134],
              [-38.544881528614788, -8.129113888395118],
              [-38.544897580882278, -8.129045976471152],
              [-38.544911746016012, -8.128961147800752],
              [-38.544923820147943, -8.128879211271812],
              [-38.54492390343286, -8.128805311603335],
              [-38.5449220010711, -8.128721369475446],
              [-38.544920055894998, -8.128675417385256],
              [-38.544904183781057, -8.12858349979577],
              [-38.544882196176836, -8.128521605619966],
              [-38.544839521480363, -8.128451999819688],
              [-38.544778678143032, -8.128395217944959],
              [-38.544738681632133, -8.128365504723114],
              [-38.544657399987436, -8.128321906027764],
              [-38.54457709990664, -8.128293323545124],
              [-38.544493501988761, -8.128291420864924],
              [-38.544443396476893, -8.128291364739244],
              [-38.544371118077287, -8.128313263742724],
              [-38.544314999231631, -8.128333100431254],
              [-38.544272395440267, -8.128361635705053],
              [-38.544222358538349, -8.128381207838048],
              [-38.544185940632516, -8.128397810294715],
              [-38.544123737054925, -8.128420353712761],
              [-38.544079778316352, -8.128443008028606],
              [-38.544005496989314, -8.128470150968246],
              [-38.543946305891069, -8.128477592155864],
              [-38.543882665280577, -8.128486566037921],
              [-38.54382502739977, -8.128485054145091],
              [-38.543773464424696, -8.12848951892922],
              [-38.543714376050225, -8.128486377247054],
              [-38.543664364663705, -8.128483336194813],
              [-38.543599208239335, -8.128468157494849],
              [-38.543541585803389, -8.128452987241323],
              [-38.543494585302959, -8.128436291210122],
              [-38.543449122695975, -8.128424209981263],
              [-38.543393141001232, -8.128402981227243],
              [-38.54334649623236, -8.128392798163798],
              [-38.543341776863485, -8.1283921596963],
              [-38.543286960060222, -8.12838431921619],
              [-38.543241839678522, -8.128390690672667],
              [-38.543187277591137, -8.128398408306595],
              [-38.543150384404655, -8.128433914720317],
              [-38.543149342738552, -8.128391853107331],
              [-38.543068507763124, -8.128354767189888],
              [-38.543019496416917, -8.128350370394676],
              [-38.542935749322432, -8.128319884216532],
              [-38.542892839389935, -8.12829803691929],
              [-38.542848211369936, -8.128270398722364],
              [-38.542808211132076, -8.128244122511962],
              [-38.542777103162386, -8.128220750777551],
              [-38.542767761654147, -8.128213775427568],
              [-38.542730220433043, -8.128180265769887],
              [-38.542692491325901, -8.128152363956428],
              [-38.542653219427855, -8.128124189047126],
              [-38.542616123780483, -8.128098006538165],
              [-38.542578032118321, -8.128069652044351],
              [-38.54253621947467, -8.128040750646241],
              [-38.542463241310038, -8.12803931176048],
              [-38.542389640665895, -8.128026475138991],
              [-38.54230832000146, -8.128017609728746],
              [-38.542231826186949, -8.127994639136871],
              [-38.542150813636319, -8.127954206100732],
              [-38.542106807565716, -8.127938508264295],
              [-38.54205799505997, -8.12791891555116],
              [-38.542012175736751, -8.127901316159386],
              [-38.541961549775252, -8.127880002794496],
              [-38.541914907881569, -8.127867377356115],
              [-38.541863639249463, -8.12785248538685],
              [-38.54181708998054, -8.127838231895996],
              [-38.541766830200281, -8.127814205343798],
              [-38.541719832731602, -8.127794976446953],
              [-38.541671661305131, -8.127770409521519],
              [-38.541623303826128, -8.127749822293129],
              [-38.54157077079779, -8.127729320805106],
              [-38.54151741827171, -8.127711079700399],
              [-38.541463874144839, -8.127701702722632],
              [-38.541408514504496, -8.127692414143334],
              [-38.541351699043553, -8.127686199301426],
              [-38.541296798296266, -8.127672479049993],
              [-38.541284457491301, -8.127668937481706],
              [-38.541244349902442, -8.127657404769348],
              [-38.541190275142434, -8.127635725611608],
              [-38.541143375176006, -8.127610617345265],
              [-38.541095481980285, -8.127580894873281],
              [-38.541048034010537, -8.127558770912122],
              [-38.541013922031759, -8.127543084126668],
              [-38.540999134230013, -8.127536193044493],
              [-38.54095776481951, -8.127516789509636],
              [-38.540913853527066, -8.127497654459223],
              [-38.540844985561428, -8.127473878150573],
              [-38.540755950481646, -8.127464189655406],
              [-38.540705106281059, -8.127475257902976],
              [-38.540658893231701, -8.127484703228784],
              [-38.540671738697817, -8.127443561831079],
              [-38.540653411642964, -8.127356163979805],
              [-38.540596835817574, -8.127298934074336],
              [-38.540518196173998, -8.127247468198743],
              [-38.540475379017415, -8.127224083070304],
              [-38.540429478240121, -8.127198433139794],
              [-38.540380402352426, -8.127171151470741],
              [-38.540333320643974, -8.127146314268298],
              [-38.540282151796355, -8.127123643301967],
              [-38.540234064004487, -8.127105498439047],
              [-38.540182444301919, -8.127080203827553],
              [-38.540135360969416, -8.127056813842085],
              [-38.540083286706206, -8.127032151872408],
              [-38.54003538809912, -8.127007313713456],
              [-38.539992849284722, -8.126978682604673],
              [-38.53994731432892, -8.12695068127057],
              [-38.539869117151646, -8.12690934646084],
              [-38.539792829778172, -8.126864847959194],
              [-38.539737225409461, -8.126831498510342],
              [-38.539681519455499, -8.126807646455084],
              [-38.539628801097898, -8.12679067216621],
              [-38.539582958099686, -8.126794057472436],
              [-38.539539992944441, -8.126820963688662],
              [-38.5395384078519, -8.126778177848401],
              [-38.539513017164069, -8.12667657091454],
              [-38.539473803603109, -8.126597561461297],
              [-38.539425057412245, -8.126520078903994],
              [-38.539394966202074, -8.126480969338305],
              [-38.539364144089561, -8.126446019759141],
              [-38.539329877247511, -8.126407086365422],
              [-38.539299146432363, -8.126371684622638],
              [-38.539264063288719, -8.126332207584227],
              [-38.539204504767852, -8.126264119819231],
              [-38.53914621374895, -8.126198927963216],
              [-38.539116208715143, -8.126163888840351],
              [-38.539083570109241, -8.126129932161673],
              [-38.539026904323187, -8.126072340143955],
              [-38.538970238450048, -8.126014838571418],
              [-38.538921098368021, -8.125964491271574],
              [-38.538914570756134, -8.125957609480768],
              [-38.538872413904841, -8.125912606789917],
              [-38.538827899411821, -8.125865521014116],
              [-38.538783372226192, -8.125829560879918],
              [-38.538711767220832, -8.125818354027919],
              [-38.538674362656941, -8.125824643287016],
              [-38.538604366104728, -8.125835689560567],
              [-38.538559796166311, -8.125837176698083],
              [-38.538596410869921, -8.12580718799328],
              [-38.53864892864101, -8.125761207224723],
              [-38.538704659199816, -8.125683843066581],
              [-38.538746776792109, -8.125604202151271],
              [-38.538782638868312, -8.125517860651318],
              [-38.538812597564721, -8.125434406935424],
              [-38.53882109236811, -8.125387833515108],
              [-38.5388434320353, -8.125299396269217],
              [-38.538860514047464, -8.125204802289744],
              [-38.538870057385346, -8.125114360580096],
              [-38.538864305507325, -8.125064062455142],
              [-38.538856599847598, -8.124975952958806],
              [-38.538861729621836, -8.124855566458399],
              [-38.538821661111101, -8.12481011387087],
              [-38.538881860308337, -8.124793810198295],
              [-38.53890690423286, -8.124721838369235],
              [-38.538931727897257, -8.124683785987122],
              [-38.538961821479148, -8.124641126495927],
              [-38.538991271201297, -8.124605883380102],
              [-38.539024995155955, -8.124563589807147],
              [-38.539051367048032, -8.124521016543834],
              [-38.539079371864375, -8.124479259201086],
              [-38.539111185090434, -8.124440943366487],
              [-38.539141545785412, -8.124402806788799],
              [-38.539172362490696, -8.124362771221671],
              [-38.539202451689469, -8.124323910710876],
              [-38.539237170146663, -8.124285055439897],
              [-38.539265985047955, -8.124249268866707],
              [-38.539295079267262, -8.124207331834876],
              [-38.539337655478953, -8.124123259182822],
              [-38.539367705744418, -8.124038900986665],
              [-38.539381323463196, -8.123957328213496],
              [-38.539365594997534, -8.123899873045341],
              [-38.539349762256862, -8.123854267041224],
              [-38.539325508039958, -8.123791013256922],
              [-38.539311960330743, -8.12373157060019],
              [-38.539313940234017, -8.123666899276387],
              [-38.539318006396179, -8.123603496650002],
              [-38.539314840164039, -8.123514487766121],
              [-38.539317921778498, -8.123438872931558],
              [-38.539320808342332, -8.123375107158562],
              [-38.539332939592811, -8.123323472491832],
              [-38.539347424457787, -8.123277448546473],
              [-38.539355559155631, -8.123228161120252],
              [-38.539373451880515, -8.123138814263475],
              [-38.53938303476113, -8.123093146577062],
              [-38.539375504147642, -8.123010645333791],
              [-38.539334550324696, -8.122945654048213],
              [-38.539245850894247, -8.122881332321667],
              [-38.53916956422853, -8.122836924193113],
              [-38.539136269439666, -8.122821419102912],
              [-38.539166421643515, -8.122806799938104],
              [-38.539238429334944, -8.122782821100261],
              [-38.5393042603583, -8.122762634263415],
              [-38.539366178077742, -8.122751397787773],
              [-38.539426287722975, -8.122733737125444],
              [-38.539491679829098, -8.122700434141404],
              [-38.539550263591885, -8.122667666158025],
              [-38.539614647423491, -8.122642955004114],
              [-38.539673037162302, -8.122621131540797],
              [-38.539720166736132, -8.122603456150246],
              [-38.539773728579945, -8.122596642334427],
              [-38.539833363121822, -8.122597614297673],
              [-38.539894285945387, -8.122583210775076],
              [-38.539938801932273, -8.122549070038989],
              [-38.53998142503481, -8.122503258778581],
              [-38.540034681747478, -8.12244551979494],
              [-38.540091847399687, -8.12238262942534],
              [-38.54014547570128, -8.122317383282986],
              [-38.540168220349585, -8.122271459084788],
              [-38.540188964462843, -8.122228698464152],
              [-38.540219969427469, -8.122182512237577],
              [-38.540223898141825, -8.122239773144559],
              [-38.540223550401649, -8.12230607447561],
              [-38.540211039037068, -8.122372814326761],
              [-38.540199612476215, -8.122443444862574],
              [-38.540217951721061, -8.122519897992289],
              [-38.540238746174239, -8.122592464435256],
              [-38.540258092746583, -8.122661139781725],
              [-38.540290875585228, -8.122727749897409],
              [-38.540341648402446, -8.122778912944854],
              [-38.540410953451264, -8.122816709938682],
              [-38.540467573401457, -8.122834593034352],
              [-38.540514236061234, -8.122828404499067],
              [-38.540593003693559, -8.122846403037361],
              [-38.540672485358151, -8.122875075768734],
              [-38.540727021124638, -8.122889881089746],
              [-38.540811349777734, -8.122886719963859],
              [-38.540854482087269, -8.122872024865075],
              [-38.540917439774503, -8.122824246490188],
              [-38.540991100858967, -8.122782992762264],
              [-38.541011178297417, -8.122767728924925],
              [-38.541005330517457, -8.122801642040589],
              [-38.541008928012914, -8.122830952728943],
              [-38.540999897439548, -8.122870018050445],
              [-38.540987072932445, -8.122892888083378],
              [-38.540960975614311, -8.122933743203587],
              [-38.540923886946459, -8.122981822126992],
              [-38.540872834443626, -8.123016950575293],
              [-38.540800032168988, -8.123021300602908],
              [-38.540748660468452, -8.123017805431525],
              [-38.540664386673768, -8.123052534547737],
              [-38.54060150251803, -8.123115418539481],
              [-38.540554613548537, -8.123160953689665],
              [-38.54048938724533, -8.123208096272776],
              [-38.540451507492939, -8.123233380210785],
              [-38.540381191068327, -8.123286667800167],
              [-38.540324933431528, -8.123349197418769],
              [-38.54027147306919, -8.123426293049592],
              [-38.540222369324042, -8.123503664952471],
              [-38.54018758865076, -8.123597334493001],
              [-38.540167388151048, -8.123640909794158],
              [-38.540142554476859, -8.123687917054587],
              [-38.540123264444702, -8.123729051162988],
              [-38.540105238184559, -8.123776337471888],
              [-38.540102869896934, -8.123863350146475],
              [-38.540093697474049, -8.123947189290629],
              [-38.540081934008931, -8.123994754033836],
              [-38.54006132447288, -8.124078670700582],
              [-38.540013588844268, -8.124150164700319],
              [-38.539969178279662, -8.124171280385376],
              [-38.539927122577424, -8.124196921355177],
              [-38.539891053252873, -8.12422645854965],
              [-38.539853077343359, -8.124256355394435],
              [-38.539786294195061, -8.124315345370343],
              [-38.539736311189863, -8.124367570399196],
              [-38.539715182702565, -8.124428873319671],
              [-38.53972710817191, -8.124478183445696],
              [-38.539753108344151, -8.124522805961339],
              [-38.539785929560431, -8.124555767813527],
              [-38.539827108727565, -8.124582497891335],
              [-38.539893346180364, -8.124604190962181],
              [-38.539974929122103, -8.124621197841909],
              [-38.540058889905787, -8.124622559103559],
              [-38.540143583542978, -8.12461804176089],
              [-38.54023217905295, -8.12461461424043],
              [-38.540279099204653, -8.124621632106829],
              [-38.541017218190291, -8.124642274588815],
              [-38.541049991294742, -8.124637788932464],
              [-38.541096112107844, -8.124629338416694],
              [-38.541147050919832, -8.124614561650439],
              [-38.541190097156928, -8.124595886517078],
              [-38.541239768982436, -8.124577761565323],
              [-38.541288798019629, -8.124566148466805],
              [-38.541342458684817, -8.124552460174714],
              [-38.541388134053427, -8.124536592012802],
              [-38.541430730734461, -8.124514117338558],
              [-38.541512198613908, -8.124472600975002],
              [-38.541589004881182, -8.124459571899344],
              [-38.541664511530392, -8.124471958515496],
              [-38.541737110673381, -8.124486874514869],
              [-38.541797722954684, -8.124506390082916],
              [-38.541806979337423, -8.124508300011261],
              [-38.54185453172451, -8.124518122438472],
              [-38.541903165764396, -8.124534639568061],
              [-38.541912965127331, -8.124537997346563],
              [-38.541971407047662, -8.124550364692992],
              [-38.542025105247269, -8.124583621234756],
              [-38.542015149898454, -8.12463779111661],
              [-38.541948393335936, -8.12467353518042],
              [-38.541907424574987, -8.124701257991221],
              [-38.541825036058761, -8.124754170419633],
              [-38.54177345796014, -8.124772383748487],
              [-38.541716611706654, -8.124793666523399],
              [-38.54166094278996, -8.124817302383066],
              [-38.541605548845659, -8.124838586777996],
              [-38.541543614422856, -8.12486429597018],
              [-38.541487679234116, -8.124882594803868],
              [-38.541427929644968, -8.124902698381273],
              [-38.54136754486526, -8.124922620328372],
              [-38.541306354241151, -8.124932772481348],
              [-38.54123699153557, -8.124945357628929],
              [-38.541170266397451, -8.12495333265869],
              [-38.541098461832647, -8.124958045655907],
              [-38.541029916151572, -8.124970541236522],
              [-38.541016843617996, -8.124971973729304],
              [-38.540963101161999, -8.124977702057505],
              [-38.540895461325043, -8.124991826783086],
              [-38.540819382951746, -8.125003228396356],
              [-38.540730023866224, -8.12503948945413],
              [-38.540689878708193, -8.125061243196665],
              [-38.540660721868662, -8.125078486714298],
              [-38.540615933851647, -8.125112174943386],
              [-38.540576760406495, -8.125157447433706],
              [-38.540542306380985, -8.125203267965052],
              [-38.540503494558692, -8.125249807191263],
              [-38.540463319117507, -8.125298153923753],
              [-38.540430419684157, -8.125333845514941],
              [-38.540382077176389, -8.125380193056015],
              [-38.540356343940111, -8.125419963101169],
              [-38.540340227292972, -8.125464176185023],
              [-38.540316750538089, -8.125515164892031],
              [-38.540293726598584, -8.125567058634307],
              [-38.540260175368452, -8.125617131426901],
              [-38.540228538540305, -8.125659970176491],
              [-38.540192725760477, -8.125703256465734],
              [-38.540160454556279, -8.125745189966686],
              [-38.540132001152877, -8.125782333795893],
              [-38.540103818098217, -8.125821196526688],
              [-38.5400541712375, -8.125896939622747],
              [-38.540035876461502, -8.12594078841777],
              [-38.540015671694348, -8.125987981795774],
              [-38.539999368396593, -8.126036626824918],
              [-38.539978799817568, -8.12608445295669],
              [-38.539943682846385, -8.126154333052051],
              [-38.539905372003957, -8.126239043746738],
              [-38.539886708343218, -8.126287957457246],
              [-38.539878938253949, -8.126335888511367],
              [-38.539868082588278, -8.126383273359474],
              [-38.539885049325626, -8.126469403364331],
              [-38.539938347825107, -8.126534860833091],
              [-38.53999338210528, -8.126590551384844],
              [-38.540031747089039, -8.126617730531503],
              [-38.540076198331718, -8.126640846192585],
              [-38.540119196531791, -8.126664593374015],
              [-38.540165645970418, -8.126686625854553],
              [-38.540207556789127, -8.126708743652175],
              [-38.540252096557978, -8.126733849350911],
              [-38.540296461380478, -8.126753165884926],
              [-38.540347815327003, -8.126772671217694],
              [-38.540393635162381, -8.126789637619835],
              [-38.540442449192341, -8.126807692830477],
              [-38.540486913447737, -8.126819411443545],
              [-38.54055651474863, -8.12683676650432],
              [-38.540619130021106, -8.126851219182164],
              [-38.540658604688197, -8.126860489923001],
              [-38.540742540542894, -8.126884192818853],
              [-38.540844249739258, -8.12692347359966],
              [-38.540888159321973, -8.12694405589332],
              [-38.540934332928892, -8.126969253817586],
              [-38.540970972039247, -8.126997606815705],
              [-38.54101268330546, -8.127035643956876],
              [-38.541014496865763, -8.127037274149227],
              [-38.541048590198827, -8.127069332822368],
              [-38.541083226413384, -8.127102929798687],
              [-38.541121855099234, -8.12713779761245],
              [-38.541162937268759, -8.127170316424392],
              [-38.541206468811779, -8.12720410433117],
              [-38.541245557432873, -8.127233635952573],
              [-38.54129272587447, -8.127262091252677],
              [-38.541336996341045, -8.12728466386384],
              [-38.541384077825228, -8.127309772311571],
              [-38.541428443078587, -8.127328817370802],
              [-38.54147897067363, -8.127356733722554],
              [-38.541528504727829, -8.127380307225993],
              [-38.5415763172265, -8.127401165206756],
              [-38.541625668519202, -8.127425823923545],
              [-38.541673571183495, -8.127447224710323],
              [-38.54172401194117, -8.127471703738392],
              [-38.541774010859505, -8.127485599314033],
              [-38.541827372400078, -8.127495880569752],
              [-38.541873206546946, -8.127500364376132],
              [-38.541920132610372, -8.127502497640917],
              [-38.541991757419972, -8.127496608437738],
              [-38.542048117682626, -8.127503998560014],
              [-38.542119989166089, -8.127520722751072],
              [-38.542167542221001, -8.127530273803073],
              [-38.542213923462363, -8.127532587326016],
              [-38.542304353280571, -8.127513060897771],
              [-38.542370209561341, -8.127471165017246],
              [-38.542420000758732, -8.1274280751679],
              [-38.542466070657298, -8.127384709767799],
              [-38.54251141479093, -8.127340981735273],
              [-38.542553307685132, -8.127298968414047],
              [-38.542591595664454, -8.127234066535218],
              [-38.542644725142068, -8.127208799564416],
              [-38.542683638582993, -8.127232813249993],
              [-38.542694630762604, -8.12730509720847],
              [-38.542688224380136, -8.127351401718171],
              [-38.542680445477068, -8.127407383108581],
              [-38.5426674889572, -8.127466614968853],
              [-38.542654258584619, -8.12752720330873],
              [-38.542645748077753, -8.127587977860664],
              [-38.542631435209628, -8.127642595112698],
              [-38.542621668964969, -8.127690071724718],
              [-38.542614048330357, -8.127766586021577],
              [-38.542632593326147, -8.127822235202878],
              [-38.542682765569438, -8.127843457523097],
              [-38.542713322403685, -8.12779238618473],
              [-38.542736359496921, -8.127728733482684],
              [-38.542752850134534, -8.127674751842951],
              [-38.542764338958307, -8.127629086214823],
              [-38.542782463357149, -8.127575196862518],
              [-38.54279896135403, -8.127514702643429],
              [-38.542810453955731, -8.12746569027243],
              [-38.542835117511828, -8.127408551974913],
              [-38.54285812681497, -8.127369411876767],
              [-38.542887858258155, -8.127325756703724],
              [-38.542906700395307, -8.127279285260618],
              [-38.542925996278491, -8.127232904778776],
              [-38.542945881819598, -8.12714672582838],
              [-38.542928982959573, -8.127080224200514],
              [-38.542902839021359, -8.127001772443235],
              [-38.542894688028554, -8.126905340844909],
              [-38.542901291887979, -8.126844925950435],
              [-38.542930962608239, -8.126774677647058],
              [-38.542962161574721, -8.126717546680622],
              [-38.5430065167237, -8.126665314931515],
              [-38.543060218455572, -8.12661553589621],
              [-38.543089419622341, -8.126559126294211],
              [-38.54308150518856, -8.126494262909235],
              [-38.543040073550173, -8.126449623248027],
              [-38.542955217227394, -8.126437135907949],
              [-38.542870533647942, -8.126432427665737],
              [-38.542821698447263, -8.126433005948346],
              [-38.542732764943892, -8.126413910942414],
              [-38.542653257028881, -8.126407670779919],
              [-38.542596719458707, -8.12639666244675],
              [-38.542540485140805, -8.126358337771784],
              [-38.54255081625918, -8.126292952184382],
              [-38.542575334593018, -8.1262037030726],
              [-38.542608104207666, -8.126122242157315],
              [-38.542660730081643, -8.126060612675841],
              [-38.542721245891627, -8.126005233283335],
              [-38.542783955734571, -8.125936107555953],
              [-38.542836571013076, -8.125883794651443],
              [-38.542886636316723, -8.125838895984854],
              [-38.542925792288898, -8.125808909728367],
              [-38.54296331572089, -8.125777745750188],
              [-38.542998661888767, -8.12574549389125],
              [-38.543032645780933, -8.125713873666662],
              [-38.543067630698431, -8.125679993248886],
              [-38.543102528727466, -8.1256426755322],
              [-38.543137700902129, -8.125603729973639],
              [-38.543172055524202, -8.125565326208948],
              [-38.543203140059362, -8.12552899917824],
              [-38.543237946167487, -8.125492676323443],
              [-38.543302292261998, -8.12542129102793],
              [-38.543366810569765, -8.125358137098932],
              [-38.543405600210285, -8.12533095440998],
              [-38.543448567215812, -8.125302057806064],
              [-38.543487806812273, -8.125278312811705],
              [-38.543531498741395, -8.125250502442762],
              [-38.543568472651295, -8.125223589057461],
              [-38.543602545079466, -8.12519386838321],
              [-38.543661621387955, -8.125126909288509],
              [-38.543694429966791, -8.125091036412101],
              [-38.543727347902923, -8.12503870128443],
              [-38.543750253333094, -8.125010958028733],
              [-38.543781516964138, -8.124976711558149],
              [-38.543851375854764, -8.124926588704678],
              [-38.543898887519923, -8.124892179536007],
              [-38.543935426208932, -8.1248487128018],
              [-38.543933496864454, -8.124788831024054],
              [-38.543891526491919, -8.124739035048677],
              [-38.54385434488227, -8.124708782197899],
              [-38.543815615387338, -8.1246827788801],
              [-38.543767533622251, -8.124659388196628],
              [-38.543724346906345, -8.124641791963116],
              [-38.543673805166407, -8.124626539243055],
              [-38.543577276038633, -8.12458201874313],
              [-38.543509797727467, -8.124533641356198],
              [-38.543473821115356, -8.124481319393878],
              [-38.543442694162721, -8.124394630888018],
              [-38.543441171001859, -8.124296669027521],
              [-38.543459282259334, -8.124254176634508],
              [-38.543510176172411, -8.124198695849804],
              [-38.543566770182686, -8.124159502918335],
              [-38.543632444748809, -8.124117154322926],
              [-38.543693107649894, -8.124091895635249],
              [-38.543780087379247, -8.124072364960661],
              [-38.543876768451661, -8.124062614022767],
              [-38.543975157405711, -8.124067699201273],
              [-38.544039152124576, -8.124066052321936],
              [-38.544088386256306, -8.12403345410554],
              [-38.54412117817153, -8.124012234487912],
              [-38.544116228629534, -8.124054741662524],
              [-38.544253818109077, -8.124151046912292],
              [-38.544331122254675, -8.124179354705692],
              [-38.544403358798995, -8.124193908015469],
              [-38.544451544069453, -8.124205992164349],
              [-38.54449982398814, -8.124214639214816],
              [-38.544553542458388, -8.124230076292646],
              [-38.544601815653863, -8.124244693195417],
              [-38.54465298992357, -8.124262479180461],
              [-38.544700085258214, -8.124275195249099],
              [-38.544752534800686, -8.124289002734585],
              [-38.544798543756094, -8.124299184903684],
              [-38.544852099154667, -8.1242982498395],
              [-38.544916080974964, -8.124308090290457],
              [-38.544999669112094, -8.124317952657933],
              [-38.545093147412949, -8.124331172813067],
              [-38.545181629808475, -8.12434755320359],
              [-38.545252154314483, -8.124350978781139],
              [-38.545316063811192, -8.124344447167005],
              [-38.545399761059834, -8.124338028105241],
              [-38.54548273367331, -8.124330251427484],
              [-38.545565251112038, -8.124323650108163],
              [-38.545635436242392, -8.124305999781241],
              [-38.54568345030399, -8.124308947881605],
              [-38.545749762782286, -8.124344931610755],
              [-38.545820263504467, -8.124369522975483],
              [-38.545872715647064, -8.124381069006763],
              [-38.545938331374067, -8.12439091112268],
              [-38.546015376705171, -8.12440736902289],
              [-38.546098951996292, -8.12442871863524],
              [-38.546169456516814, -8.124449963198629],
              [-38.546261186398382, -8.12448443750443],
              [-38.546343107750189, -8.124523694842587],
              [-38.546390633569459, -8.12455730575541],
              [-38.546430263519632, -8.124589912881742],
              [-38.546492663966838, -8.124633942417477],
              [-38.546551989920644, -8.124667566466002],
              [-38.546591625478285, -8.124695198689713],
              [-38.546635518302871, -8.124730795484762],
              [-38.546671880465638, -8.124763489400449],
              [-38.546711592399944, -8.124803965971029],
              [-38.546764111451651, -8.124836858785555],
              [-38.546811289585079, -8.124856810918896],
              [-38.546862549537956, -8.124879300266892],
              [-38.546922707707928, -8.124899357297501],
              [-38.546992127987409, -8.124916258791004],
              [-38.547049397549451, -8.124922021071843],
              [-38.547115727395891, -8.124942718114374],
              [-38.547145759535375, -8.124954148576094],
              [-38.547224519124143, -8.124980015236954],
              [-38.547309269148393, -8.125006250359332],
              [-38.547398745009062, -8.125027334922191],
              [-38.547486496044343, -8.125048598452617],
              [-38.547574427110682, -8.125071218954268],
              [-38.547667076211468, -8.125095653735912],
              [-38.547738475886739, -8.125128296064741],
              [-38.547799702528728, -8.125166987400538],
              [-38.547888458064563, -8.12518291525781],
              [-38.547985031484956, -8.125188449729238],
              [-38.548079067694559, -8.125190091895904],
              [-38.548174556735255, -8.125191283391249],
              [-38.548273672843678, -8.125195825639386],
              [-38.548372881141553, -8.125199101628731],
              [-38.548472912642424, -8.125196770443893],
              [-38.548572394367334, -8.125199051709091],
              [-38.548673776812265, -8.125206219501605],
              [-38.548773421999066, -8.125224691923098],
              [-38.548876881060878, -8.125241811760127],
              [-38.548979893047786, -8.125252870748152],
              [-38.549074726331064, -8.125272332741515],
              [-38.549164581191903, -8.125279306730263],
              [-38.549271571750758, -8.125304118857619],
              [-38.549360769942439, -8.125330177585939],
              [-38.549440612958762, -8.125361472188201],
              [-38.549524365262492, -8.12538725349237],
              [-38.549608578992668, -8.125406251341403],
              [-38.549666289827677, -8.12542341078278],
              [-38.549737062917956, -8.125448363602525],
              [-38.549815108023274, -8.125464007833955],
              [-38.549902863944055, -8.125481110037619],
              [-38.549990088350697, -8.125486452788941],
              [-38.550077097873917, -8.125521735114983],
              [-38.550117636237104, -8.125554071548338],
              [-38.550171962730914, -8.125594473511592],
              [-38.55019500912627, -8.125602820639877],
              [-38.550225223659105, -8.125613617914215],
              [-38.550305233142389, -8.125658570889165],
              [-38.550370538091975, -8.125621738388084],
              [-38.550414283383866, -8.125545625592475],
              [-38.550416192278469, -8.125543185480179],
              [-38.550448099868014, -8.125500798441589],
              [-38.550476007921176, -8.125463472329931],
              [-38.550476826061661, -8.12546238780196],
              [-38.550503839289419, -8.125413482757816],
              [-38.550528485539061, -8.125370816330348],
              [-38.550555853784324, -8.125329147887603],
              [-38.550604172947878, -8.125302789087916],
              [-38.550663632398567, -8.125298422585626],
              [-38.550742882883512, -8.125291273898002],
              [-38.550808404427535, -8.125304461887087],
              [-38.550854139063851, -8.125316723493789],
              [-38.550908942384119, -8.125336502678485],
              [-38.550959029476381, -8.12535283944875],
              [-38.551001303190915, -8.125375589729289],
              [-38.551073505573356, -8.125421348013052],
              [-38.551156967610879, -8.125463410148294],
              [-38.551239845721554, -8.125459159853291],
              [-38.551265692912345, -8.125397590092625],
              [-38.551258031293663, -8.125349099006893],
              [-38.551232340758133, -8.125269653217938],
              [-38.551212788141882, -8.125221239466779],
              [-38.551192501730924, -8.12517969930872],
              [-38.551160066484051, -8.125125211013703],
              [-38.551135589778724, -8.125097053226172],
              [-38.551107494630592, -8.12505776576555],
              [-38.551068422123244, -8.125013219934853],
              [-38.551031084611964, -8.12495935938988],
              [-38.551015702535722, -8.124915382414123],
              [-38.551011580889288, -8.124866985684074],
              [-38.5510299691367, -8.124819608774889],
              [-38.551065234395033, -8.124777858553989],
              [-38.551098676403804, -8.124743161626613],
              [-38.551166351308439, -8.124697195878802],
              [-38.551220851066446, -8.12466333624492],
              [-38.551270080221265, -8.124634807498174],
              [-38.551315668820699, -8.124615048640212],
              [-38.551366430207345, -8.12459629046038],
              [-38.55141101068542, -8.124585213937005],
              [-38.55145177540237, -8.124577208592971],
              [-38.551496633910396, -8.124560976563524],
              [-38.551541583185163, -8.124544744628961],
              [-38.551594624447787, -8.124516400948099],
              [-38.55165032142407, -8.124466984710869],
              [-38.5516898650271, -8.124414203916958],
              [-38.55171433326197, -8.124368190460102],
              [-38.551726639809168, -8.124320806811731],
              [-38.551730784576172, -8.124266630218978],
              [-38.551743110586472, -8.124201698769726],
              [-38.551751244714012, -8.124151596942308],
              [-38.551760837180204, -8.12409606955981],
              [-38.551773154248281, -8.124039188387991],
              [-38.551776517194668, -8.123953352491238],
              [-38.551789650582705, -8.123897014933625],
              [-38.551806329713735, -8.12383552547753],
              [-38.551828809687187, -8.123781640435759],
              [-38.551851204903826, -8.123722328137463],
              [-38.551871148122125, -8.123664098569217],
              [-38.551877024508478, -8.123603592190488],
              [-38.551870916058114, -8.123545967098615],
              [-38.551853548834771, -8.123491314545639],
              [-38.551822474364158, -8.123437551421622],
              [-38.551790745649946, -8.123400792680886],
              [-38.551753568844617, -8.123365746537525],
              [-38.551695540875926, -8.12330734062523],
              [-38.551661735918714, -8.123260267981207],
              [-38.551625572158748, -8.123212107303292],
              [-38.551592393772928, -8.123172995181346],
              [-38.551576290953925, -8.123124132983802],
              [-38.551596873078331, -8.123062738287802],
              [-38.551633958430486, -8.123016557826386],
              [-38.551671500533132, -8.122967754736244],
              [-38.551695429574174, -8.122916765780554],
              [-38.551742582916098, -8.122877289898366],
              [-38.551797087091487, -8.122839178896879],
              [-38.551848965455413, -8.122795185570611],
              [-38.551900017251917, -8.122759874785599],
              [-38.551950622813202, -8.122717689097367],
              [-38.552006025018486, -8.12268826251308],
              [-38.55206560733383, -8.122654589245128],
              [-38.552115850688701, -8.122611589061581],
              [-38.552167282959871, -8.122560539889236],
              [-38.552183680838418, -8.122507009935843],
              [-38.552187211274109, -8.122433747133559],
              [-38.552180281250443, -8.122380281961778],
              [-38.552173343510148, -8.122333781639266],
              [-38.552165239999844, -8.122274435750276],
              [-38.552158307474791, -8.1222232318928],
              [-38.552140864926294, -8.122154649550627],
              [-38.552132749795021, -8.122105796161476],
              [-38.552111763433288, -8.122040647120864],
              [-38.552088513052524, -8.121970792047168],
              [-38.552056897547338, -8.121913862501737],
              [-38.552033639462309, -8.121850972274855],
              [-38.552011559382109, -8.121789892397567],
              [-38.551979492760815, -8.121730520127398],
              [-38.551956849727468, -8.12168599247218],
              [-38.551927249239945, -8.121612783650017],
              [-38.551904982232941, -8.121556588007898],
              [-38.551878075561035, -8.121509794337143],
              [-38.551855719702075, -8.121451879993561],
              [-38.551828930591846, -8.121380935578401],
              [-38.551806843443416, -8.121326277825535],
              [-38.551800683964515, -8.121314693097132],
              [-38.551776226989169, -8.121268897097915],
              [-38.551766385892108, -8.121221308139688],
              [-38.551764399599314, -8.121211717966009],
              [-38.551736413864923, -8.121155787376088],
              [-38.551704977498858, -8.1211011193218],
              [-38.551663018321491, -8.121040650701861],
              [-38.551615248716949, -8.120981261109646],
              [-38.551568650095334, -8.120930013545523],
              [-38.551526670381463, -8.120888087693745],
              [-38.551497586336197, -8.120840387075006],
              [-38.551487091929111, -8.120808988424669],
              [-38.551473172782579, -8.120755515541692],
              [-38.551447547017467, -8.120699677987721],
              [-38.551392031380736, -8.120667958361956],
              [-38.551374035968543, -8.12060697340965],
              [-38.551358391123451, -8.120554493601103],
              [-38.551329408331362, -8.120497386002793],
              [-38.551298252810135, -8.120435120203913],
              [-38.551270361373042, -8.120376114298317],
              [-38.55125074760943, -8.120301197841776],
              [-38.551237841027316, -8.12023506269046],
              [-38.551214315767929, -8.120167739946771],
              [-38.551189512316846, -8.120107109292437],
              [-38.551155088454358, -8.120045473049583],
              [-38.551126833584433, -8.119986828545702],
              [-38.551092855450172, -8.119932519458299],
              [-38.551047899895543, -8.119873132910998],
              [-38.551007290618323, -8.119823339138904],
              [-38.550963510363076, -8.119768205156308],
              [-38.550923182456899, -8.119710270944291],
              [-38.550880665755656, -8.119661650941827],
              [-38.550833070121911, -8.119609316770946],
              [-38.550792277549931, -8.11956115092592],
              [-38.550750309112274, -8.119509275219023],
              [-38.550710709524225, -8.119449442285434],
              [-38.550678455897696, -8.119395315976009],
              [-38.550641763349319, -8.119333315380322],
              [-38.550609610564223, -8.119270143907862],
              [-38.55057854701549, -8.119206973635698],
              [-38.550567680088342, -8.119184167560089],
              [-38.550549568746803, -8.119145976528321],
              [-38.550529480806972, -8.119089511869154],
              [-38.550495966725606, -8.119026157980725],
              [-38.550462539098483, -8.118966693651275],
              [-38.550425207616833, -8.118907948630056],
              [-38.550389241701481, -8.118845587006183],
              [-38.550361168758663, -8.118786761751624],
              [-38.550326744222275, -8.118725939521001],
              [-38.550295588075706, -8.118664487729133],
              [-38.550263801084661, -8.118598964867031],
              [-38.550226023065079, -8.118533706742683],
              [-38.550193228984689, -8.118475961694983],
              [-38.550153444817049, -8.11841902299631],
              [-38.550112569725059, -8.118363620784455],
              [-38.550078230492524, -8.118307773527874],
              [-38.550037818022538, -8.118244502436267],
              [-38.550006841981514, -8.118184407614635],
              [-38.549972781527003, -8.118122771685975],
              [-38.549934442774585, -8.11807207580175],
              [-38.549905373506157, -8.118011440364834],
              [-38.549877494911357, -8.117941218257036],
              [-38.549853345087762, -8.11786448767595],
              [-38.549839686965555, -8.117821417094637],
              [-38.549807548112618, -8.117745944016617],
              [-38.549779295398473, -8.11768567128555],
              [-38.549752862293012, -8.11762160155177],
              [-38.549720807893415, -8.117551827080842],
              [-38.549696272582956, -8.117495267016004],
              [-38.549660490348195, -8.117431186938131],
              [-38.549630232508875, -8.117378329101454],
              [-38.549596355222803, -8.117315336557837],
              [-38.549569629107395, -8.117269899744313],
              [-38.549540478044541, -8.117201304357692],
              [-38.549518870845603, -8.117123672045526],
              [-38.549484808028929, -8.117064297396356],
              [-38.54945056549915, -8.117003294397451],
              [-38.549422946248292, -8.116944831399895],
              [-38.549420682279766, -8.116940125354866],
              [-38.549392518729434, -8.116881390395147],
              [-38.549377485754185, -8.116850348487352],
              [-38.54937187101811, -8.116838764329747],
              [-38.549316686139051, -8.116755215426135],
              [-38.549280903218829, -8.116691858940095],
              [-38.549249015684026, -8.116635381167473],
              [-38.549230748743916, -8.116574124476644],
              [-38.5492238347992, -8.116506639116178],
              [-38.549232617311553, -8.116444960101891],
              [-38.549239039140701, -8.116383911643002],
              [-38.549256529650535, -8.116327398069185],
              [-38.549259966707289, -8.116256848773164],
              [-38.549254404202081, -8.116198410177157],
              [-38.549231779396209, -8.116138053189916],
              [-38.549210932052105, -8.11611170838149],
              [-38.549165596259918, -8.116068331358388],
              [-38.549121617663808, -8.116028845299093],
              [-38.549065018975433, -8.115992330201161],
              [-38.54901394999299, -8.115962243360293],
              [-38.548952612820209, -8.115942366295609],
              [-38.548903616185427, -8.115926030545518],
              [-38.548827072963228, -8.115867603774507],
              [-38.548780645500386, -8.115826486819618],
              [-38.548697087402473, -8.115709800722913],
              [-38.548663137690873, -8.115630617000441],
              [-38.548648131159275, -8.115575966946938],
              [-38.548648238456174, -8.115479815857892],
              [-38.548670988701403, -8.115427649866776],
              [-38.548678558781617, -8.115395095287024],
              [-38.548688023886591, -8.115372492602605],
              [-38.548693629602099, -8.115392127047702],
              [-38.54871150857911, -8.115394227269942],
              [-38.548737201275038, -8.115389461744128],
              [-38.548814731787189, -8.115376431995649],
              [-38.548839228858554, -8.115385685305959],
              [-38.548848620909226, -8.11542857028509],
              [-38.548850568772373, -8.115472532450637],
              [-38.548843119134851, -8.115478494076454],
              [-38.548797079561815, -8.115496533614301],
              [-38.548758732865544, -8.115534662166951],
              [-38.54874560807513, -8.115583220719969],
              [-38.548763341396125, -8.115634527042115],
              [-38.548790056825638, -8.115689370969356],
              [-38.548828396833606, -8.115738619705814],
              [-38.548892676754292, -8.11580562589851],
              [-38.548949812443695, -8.115848925564922],
              [-38.549013961094388, -8.115870886154447],
              [-38.549073379220914, -8.11590233902934],
              [-38.549153943711659, -8.115936167075382],
              [-38.549226260009604, -8.115959493464578],
              [-38.549266870308735, -8.116007840149152],
              [-38.549296135604521, -8.116055269748324],
              [-38.54931876888304, -8.116108028715585],
              [-38.549324304081665, -8.116190979961143],
              [-38.549326140967715, -8.116253122994365],
              [-38.549332563389129, -8.116354437117979],
              [-38.549335402355169, -8.11641332496273],
              [-38.549337218175722, -8.116494372584787],
              [-38.549340950713678, -8.116565924794907],
              [-38.549354308622895, -8.116633960001371],
              [-38.549384736623054, -8.116696948745394],
              [-38.549419064646919, -8.116762655383434],
              [-38.549457668971705, -8.11681923102817],
              [-38.549500549193851, -8.116867037488733],
              [-38.549537803500385, -8.116913300026315],
              [-38.549567236219616, -8.116973483624026],
              [-38.549576833185718, -8.116995474243671],
              [-38.549596298620081, -8.117040179404272],
              [-38.549617396177162, -8.117086424064977],
              [-38.549626813122657, -8.117107057694128],
              [-38.549656957207624, -8.117180538618948],
              [-38.549681477789484, -8.117250304762679],
              [-38.54971353850658, -8.117314380723359],
              [-38.549752663865505, -8.117392213302798],
              [-38.549793407361584, -8.117484158288239],
              [-38.549819127006131, -8.117536830191526],
              [-38.549849372395421, -8.117600904136003],
              [-38.549878975466534, -8.117671218604412],
              [-38.549911754238579, -8.117742622013591],
              [-38.549951699611022, -8.117817741902181],
              [-38.549979576802272, -8.117889230341765],
              [-38.549997553882953, -8.117966406358113],
              [-38.550027702521369, -8.118035907345936],
              [-38.550064662642249, -8.118101978656521],
              [-38.55010316946511, -8.118164795376243],
              [-38.550141765256775, -8.11822924033957],
              [-38.550186357428522, -8.118288626573396],
              [-38.550225503491546, -8.118348006786711],
              [-38.550274726602716, -8.118405860431762],
              [-38.550314328142811, -8.118463793897481],
              [-38.550359278510825, -8.118527612692125],
              [-38.550398961690021, -8.118593777436082],
              [-38.55043455123532, -8.118668078399086],
              [-38.550453628618442, -8.118735396269981],
              [-38.550495042573807, -8.118796045302433],
              [-38.550528820396821, -8.118867087960943],
              [-38.55056142738669, -8.118929898129258],
              [-38.550600453737374, -8.11901541900807],
              [-38.550627159466302, -8.119079398542763],
              [-38.550653948230902, -8.119150343025211],
              [-38.550669613547733, -8.119184280076469],
              [-38.55068192856082, -8.11921088676114],
              [-38.55071915707407, -8.119280666876763],
              [-38.550762271306986, -8.119363297767311],
              [-38.550795604119465, -8.119426470531115],
              [-38.550840028643272, -8.119473554950279],
              [-38.55088044778676, -8.119531037006441],
              [-38.550927494684757, -8.119587079127085],
              [-38.55097238092295, -8.119627108732049],
              [-38.551014998849226, -8.119666321756428],
              [-38.551067399746934, -8.119723636105153],
              [-38.551119806183529, -8.119775975555433],
              [-38.551164050751595, -8.119821702953518],
              [-38.55121972118372, -8.119877392740795],
              [-38.55125243077353, -8.119929710460537],
              [-38.551292289140292, -8.120001845199361],
              [-38.551330890206344, -8.120061857887142],
              [-38.551371029439466, -8.120126304449284],
              [-38.551410252762743, -8.120198167120073],
              [-38.551446663862073, -8.120268579445426],
              [-38.551474721501293, -8.1203414247866],
              [-38.55150268476789, -8.120417526319399],
              [-38.551532284599361, -8.120491187430662],
              [-38.551558704809267, -8.120567287260087],
              [-38.551587210814084, -8.12064510798715],
              [-38.551615630775856, -8.120718677340731],
              [-38.551643321984585, -8.12079459766016],
              [-38.55168018782269, -8.120864286846988],
              [-38.551712608786644, -8.120931438459801],
              [-38.551748843275568, -8.120997508837814],
              [-38.55178743456856, -8.121066476294775],
              [-38.551821848579003, -8.121137157750207],
              [-38.551853357379805, -8.12120837872058],
              [-38.551884404421692, -8.121286744943818],
              [-38.551914181906774, -8.121364024335024],
              [-38.551941961472799, -8.121442206051881],
              [-38.551954359480291, -8.121475958558078],
              [-38.551971101487304, -8.121521384244437],
              [-38.551992470834193, -8.121568533625178],
              [-38.55200614363202, -8.121598669425209],
              [-38.552036206201628, -8.121664461629413],
              [-38.552076254625931, -8.121729179389556],
              [-38.552115214931455, -8.121792900969043],
              [-38.552146455129929, -8.121860865327005],
              [-38.552176600090398, -8.121934255639898],
              [-38.55219847420878, -8.122017405742318],
              [-38.55222908406094, -8.12208084676614],
              [-38.552258119272317, -8.122172778656871],
              [-38.552256113798485, -8.122262415078286],
              [-38.552289413996519, -8.122355437091867],
              [-38.552281143016302, -8.122447237484703],
              [-38.552261882588589, -8.122544724306266],
              [-38.552233754283229, -8.122617326876114],
              [-38.552192405266048, -8.122661331842099],
              [-38.552142336233992, -8.122710844821219],
              [-38.552089277905083, -8.122754836888662],
              [-38.552034945178946, -8.122802083842656],
              [-38.551986608456865, -8.122844633849652],
              [-38.551990188904178, -8.122890045045009],
              [-38.552040288083006, -8.122895256001891],
              [-38.552110839629172, -8.122874167729044],
              [-38.552215789388185, -8.122856283140621],
              [-38.552291670077615, -8.122858899306923],
              [-38.552369632561067, -8.122866854458408],
              [-38.552424515490507, -8.122896492859599],
              [-38.552447133592075, -8.122963633640412],
              [-38.552447501585249, -8.123041152009908],
              [-38.552447511207149, -8.123114418708028],
              [-38.552477799066182, -8.123140864207556],
              [-38.552531868918486, -8.123167064499336],
              [-38.552585976736012, -8.123158983252049],
              [-38.552649885287117, -8.1231530836369],
              [-38.55268345118958, -8.123170216097266],
              [-38.552701233661189, -8.123177833671757],
              [-38.552765732652624, -8.123212547951622],
              [-38.55279410356291, -8.1232487602141],
              [-38.552816106575399, -8.123297628855349],
              [-38.552817780650464, -8.123343309310602],
              [-38.552808002307202, -8.123402906896153],
              [-38.552813475101402, -8.123460893093622],
              [-38.552803696052969, -8.123521123846583],
              [-38.552823347971596, -8.123561939613193],
              [-38.552864468036304, -8.123560808916208],
              [-38.55289628854522, -8.12351471300288],
              [-38.552950459752466, -8.123449375216778],
              [-38.55300783101557, -8.123445186966821],
              [-38.553019995179163, -8.123444295802077],
              [-38.553107682678522, -8.123440954913017],
              [-38.553112947531268, -8.123440779789805],
              [-38.553192281401252, -8.123439962383754],
              [-38.553237813774714, -8.123470856752034],
              [-38.553276985162903, -8.123508166274465],
              [-38.553300635905515, -8.123544554227928],
              [-38.553321560655235, -8.123583562316202],
              [-38.553344630890635, -8.123652422152691],
              [-38.553403054829467, -8.123681340695555],
              [-38.553459523823115, -8.123672266946752],
              [-38.553507891102008, -8.12368416932885],
              [-38.553555242924546, -8.12371144755202],
              [-38.553622334536605, -8.123782255180188],
              [-38.553641852314144, -8.123862417694086],
              [-38.553632451943884, -8.12390862871708],
              [-38.553614144919223, -8.123964960671987],
              [-38.553625569180937, -8.124058682213354],
              [-38.553666486075343, -8.124077179453222],
              [-38.553714788716313, -8.124065473584848],
              [-38.553725689910841, -8.124057525703153],
              [-38.553755849933431, -8.12403548832067],
              [-38.553809602763714, -8.124020170309567],
              [-38.553857984678572, -8.124018866572497],
              [-38.553906733168837, -8.124014397386562],
              [-38.553975074001599, -8.124022613052601],
              [-38.553951411087731, -8.123997169899633],
              [-38.55391822881095, -8.123961314246804],
              [-38.553899612082709, -8.123887122610352],
              [-38.553998406069219, -8.123854396578365],
              [-38.554038185183479, -8.123834631034756],
              [-38.554081039691752, -8.123824637750852],
              [-38.554116622419087, -8.123823862670887],
              [-38.554118437918341, -8.123823774207679],
              [-38.554165801778723, -8.123840198049759],
              [-38.554200616132533, -8.123877683620268],
              [-38.554202973357739, -8.123880218879052],
              [-38.554212272068831, -8.12392608859496],
              [-38.554218471528024, -8.123983804221101],
              [-38.554260429325488, -8.123963860137581],
              [-38.554313300072913, -8.123925294743151],
              [-38.554351931794301, -8.123875949872341],
              [-38.554363178308506, -8.123801790959662],
              [-38.554362387829471, -8.123695508147359],
              [-38.554414363300673, -8.123645635166236],
              [-38.554465682225263, -8.123614937435924],
              [-38.55452862527693, -8.123579187072407],
              [-38.554553897733513, -8.123626702419466],
              [-38.554488221006324, -8.123672037782049],
              [-38.554429987001768, -8.123717743094232],
              [-38.554436803028423, -8.123792554958646],
              [-38.554439200553269, -8.123840859337134],
              [-38.554423812746258, -8.123884078886896],
              [-38.55439042733687, -8.123950072811125],
              [-38.554351795512616, -8.123999508137873],
              [-38.554346220729222, -8.124033783612413],
              [-38.554366948801437, -8.124086630703403],
              [-38.554322316983935, -8.124062069130872],
              [-38.554284208140686, -8.124048821289181],
              [-38.554219452786924, -8.124081675136525],
              [-38.554169916603783, -8.124059640850552],
              [-38.55413965541193, -8.124008863717085],
              [-38.55410804485566, -8.123947049871543],
              [-38.554069487272521, -8.123929188430331],
              [-38.554035079454231, -8.123934577881922],
              [-38.554004751592323, -8.1239442230804],
              [-38.554037398440883, -8.123971756489798],
              [-38.554067568148128, -8.124023166698885],
              [-38.554033815296172, -8.124093049653691],
              [-38.553977251487595, -8.124105741484163],
              [-38.553908916732773, -8.124092008208965],
              [-38.553863350178077, -8.12409195824797],
              [-38.553815062620465, -8.124090005791048],
              [-38.553763025220611, -8.124113647333262],
              [-38.553710412521369, -8.124165057219704],
              [-38.553624306427146, -8.124133575672046],
              [-38.553582469012973, -8.124044524304219],
              [-38.553618282699382, -8.12383471330765],
              [-38.55359000154435, -8.123799315270441],
              [-38.553374933033489, -8.123748154380703],
              [-38.553326885558619, -8.123693287297264],
              [-38.55328760160964, -8.12359356528656],
              [-38.553259771349865, -8.123560881304597],
              [-38.553220398915762, -8.123541209837756],
              [-38.553174556516844, -8.123544415797548],
              [-38.55306300031377, -8.123544293280538],
              [-38.553025307293105, -8.123565508262885],
              [-38.552974947716692, -8.123631483414886],
              [-38.552896486378806, -8.123664050634135],
              [-38.552842769605085, -8.123646805592211],
              [-38.552796368789572, -8.123580452766705],
              [-38.552787830505018, -8.123339748739813],
              [-38.552747965259314, -8.12327331264026],
              [-38.552512992020816, -8.123246099401538],
              [-38.552461543664627, -8.123229670881623],
              [-38.552328030303798, -8.123139433130104],
              [-38.552319193587849, -8.12308641812672],
              [-38.552320620772619, -8.12302717317883],
              [-38.552244584706322, -8.122918998545927],
              [-38.552186574519432, -8.122926442285982],
              [-38.551943474792687, -8.12302458722329],
              [-38.551890894423309, -8.12304678068773],
              [-38.551845395394977, -8.123067806059009],
              [-38.551767128271671, -8.123088976237051],
              [-38.551731000052932, -8.123172424274216],
              [-38.551765246318631, -8.123230803985333],
              [-38.551847450541402, -8.123342694056189],
              [-38.551884408998937, -8.123410936089055],
              [-38.551926930096236, -8.123619747728384],
              [-38.551913328042829, -8.123689471774991],
              [-38.551868319567696, -8.123841020917194],
              [-38.551832901199724, -8.123939123075395],
              [-38.551770846175906, -8.124237819947997],
              [-38.551762623285434, -8.124286112624052],
              [-38.551667243536322, -8.124513767396602],
              [-38.551624356684599, -8.124552795694875],
              [-38.551440538070274, -8.124642593492773],
              [-38.551391499404673, -8.124663072182113],
              [-38.55129025083103, -8.12469887024201],
              [-38.551241565687448, -8.124727942306894],
              [-38.551170879794512, -8.124787924910699],
              [-38.551135985063169, -8.124822982051965],
              [-38.551180486643503, -8.125046358808268],
              [-38.551230960041693, -8.125123480169211],
              [-38.551407090858618, -8.125335966858355],
              [-38.551443178560646, -8.125371011837162],
              [-38.551537030406365, -8.125538814576025],
              [-38.551563491260715, -8.12557855247189],
              [-38.551561091264617, -8.125696319213986],
              [-38.551503628605133, -8.125700778498929],
              [-38.551425241584077, -8.125665958241104],
              [-38.551374335790797, -8.12565115831802],
              [-38.55120530328989, -8.1255855745869],
              [-38.55114949240081, -8.125573935068722],
              [-38.550933013646201, -8.125484419369689],
              [-38.550877865023487, -8.125448629683195],
              [-38.55076690143509, -8.12540490897579],
              [-38.550713815405281, -8.125391734713386],
              [-38.550624214270762, -8.125401404635433],
              [-38.550571596187297, -8.125457155804586],
              [-38.550468309816068, -8.125610992121878],
              [-38.550432862679429, -8.125652922986481],
              [-38.550400963532049, -8.125687712008311],
              [-38.550334269731962, -8.12574905565041],
              [-38.550238036435843, -8.125763964405726],
              [-38.5501888435344, -8.125759749191277],
              [-38.549968523245219, -8.125696550462035],
              [-38.549885566713243, -8.125689674730967],
              [-38.549508222927088, -8.12569956863349],
              [-38.549425189156665, -8.125680572067838],
              [-38.549111665205189, -8.125601802236154],
              [-38.549032898893714, -8.125581724890573],
              [-38.548416707664067, -8.125537804972561],
              [-38.547219860049452, -8.125249558885997],
              [-38.546679642810439, -8.124964393326559],
              [-38.546600451747992, -8.124918626504524],
              [-38.545923554733889, -8.124616393003246],
              [-38.543650295408831, -8.124224993831852],
              [-38.543596699461091, -8.124261838370538],
              [-38.543562787297773, -8.124310463820592],
              [-38.543528981756651, -8.12442539113351],
              [-38.543567500544363, -8.124477173227653],
              [-38.543633195638698, -8.124497056039488],
              [-38.543709795807111, -8.124505734950237],
              [-38.543792822877393, -8.124530159803983],
              [-38.543869488603157, -8.124561180548666],
              [-38.543942422458024, -8.124601332808066],
              [-38.544012082073024, -8.124647270351169],
              [-38.544110629369115, -8.12475348168126],
              [-38.544136705721705, -8.12481149101262],
              [-38.544131830402144, -8.124868651588459],
              [-38.544105336079056, -8.124939717637735],
              [-38.544064415321053, -8.125005430814102],
              [-38.5440170698811, -8.125053408076786],
              [-38.54390740189406, -8.125149345792757],
              [-38.54384198447692, -8.125204991241645],
              [-38.543782921872833, -8.125259829732064],
              [-38.543746129002237, -8.125287195603436],
              [-38.543654653448314, -8.125349053021868],
              [-38.543622676388388, -8.125371992106587],
              [-38.543494786000998, -8.125447919236114],
              [-38.543408306927581, -8.125506073645639],
              [-38.543405218388791, -8.125508150588452],
              [-38.543370692068095, -8.125537870734018],
              [-38.543258565025553, -8.125640860798161],
              [-38.543191227252663, -8.125708895978873],
              [-38.54305928150049, -8.125838999498757],
              [-38.54302393328198, -8.125873060411838],
              [-38.542920144849937, -8.12598673314224],
              [-38.542884695600364, -8.126029839185247],
              [-38.542855154809139, -8.126065534753952],
              [-38.542820436212843, -8.126104661698518],
              [-38.542757954528504, -8.126212772744058],
              [-38.542813368434935, -8.126254171854541],
              [-38.542893628179883, -8.126237709233656],
              [-38.542969181983366, -8.126208758854155],
              [-38.543037926362274, -8.126181248051672],
              [-38.543077563222631, -8.126127382842895],
              [-38.543127257164592, -8.126089810388834],
              [-38.543172979741051, -8.126112565336205],
              [-38.543204064871219, -8.126156017481343],
              [-38.543224219717466, -8.126232924803647],
              [-38.543240461215213, -8.126318872988262],
              [-38.543270604550919, -8.126472766666183],
              [-38.543249950873225, -8.126515889352852],
              [-38.543132045737863, -8.126671244912014],
              [-38.543100230948596, -8.126711098760087],
              [-38.543040895705403, -8.126846168245457],
              [-38.543037263961011, -8.126927209668406],
              [-38.543034113591332, -8.127064151319949],
              [-38.543034059453021, -8.127112000668392],
              [-38.542987995445685, -8.127310673204864],
              [-38.54296951680795, -8.127356783250297],
              [-38.542875338955909, -8.127560105231645],
              [-38.542854496671325, -8.127609378453032],
              [-38.542787709848497, -8.127831816684155],
              [-38.542783474794156, -8.127884455314744],
              [-38.542833981001124, -8.128011597944122],
              [-38.542889115775495, -8.128059237967571],
              [-38.542960592274639, -8.128104273225537],
              [-38.543380042232428, -8.128186694428523],
              [-38.543428142584517, -8.128193984638937],
              [-38.544019043487175, -8.128209843644177],
              [-38.54404328808215, -8.128202091905969],
              [-38.54410313934855, -8.128172671465453],
              [-38.544144027367878, -8.128136264911147],
              [-38.544184724343374, -8.128108270227989],
              [-38.544214634798863, -8.128066786017266],
              [-38.544240007320973, -8.128024754002887],
              [-38.544272637213176, -8.127986529127529],
              [-38.544310358836086, -8.127940711937626],
              [-38.544308255151826, -8.127874407838284],
              [-38.544261289998914, -8.127826415343696],
              [-38.54422083891253, -8.127797425186429],
              [-38.544181837305217, -8.127770969322258],
              [-38.544139479432445, -8.127742519735875],
              [-38.544075188647462, -8.12768428665961],
              [-38.544010259013852, -8.127629128245413],
              [-38.543969009210151, -8.12758403661036],
              [-38.543976783855342, -8.127531673284697],
              [-38.544026370687263, -8.127508663473453],
              [-38.544104516579388, -8.127515535021368],
              [-38.544192430284028, -8.127553804547393],
              [-38.544235704627425, -8.127574476232672],
              [-38.544280433245866, -8.127593430944046],
              [-38.544356287238529, -8.127620380350029],
              [-38.544369171575866, -8.127624917413797],
              [-38.544455211490458, -8.127634511317005],
              [-38.544457661993448, -8.127634785419513],
              [-38.544520810147709, -8.127659911512248],
              [-38.544578888104283, -8.12767354443962],
              [-38.544643798856313, -8.127665024125477],
              [-38.544698811213294, -8.127660382181025],
              [-38.544745059006019, -8.1277005949017],
              [-38.544725740460215, -8.127767236832963],
              [-38.544701975262214, -8.127832878806386],
              [-38.544682375313151, -8.127907570699582],
              [-38.544677933363815, -8.127982912730415],
              [-38.544698194736419, -8.128046161771755],
              [-38.544736817007127, -8.128087089578409],
              [-38.544799955666235, -8.128120718164514],
              [-38.544868555877514, -8.128140784965542],
              [-38.544924544149154, -8.12815622456066],
              [-38.54497453981773, -8.128173195137339],
              [-38.54504088524768, -8.128180415119667],
              [-38.545102972398638, -8.128180575026125],
              [-38.545170069367146, -8.12816527313313],
              [-38.545197181420427, -8.128109946468756],
              [-38.545172933894335, -8.128039728140463],
              [-38.545139240916029, -8.127974202781466],
              [-38.545106452803175, -8.127911211104786],
              [-38.545073209012216, -8.127849847062672],
              [-38.54504568083312, -8.127791112537166],
              [-38.545026496843796, -8.127738357215904],
              [-38.54501158091756, -8.127683978522033],
              [-38.545052274835406, -8.12765860686746],
              [-38.545115208789696, -8.127712767912511],
              [-38.545147482726058, -8.127748713688176],
              [-38.54520949188683, -8.127818069721142],
              [-38.545238226594854, -8.127853287911849],
              [-38.545272399508164, -8.127895657936136],
              [-38.545304487653944, -8.127935402501778],
              [-38.545337213543746, -8.127973067368538],
              [-38.54536839643778, -8.128010640055402],
              [-38.545414619800965, -8.128072651752882],
              [-38.545468388254335, -8.128124812545884],
              [-38.545510551389604, -8.128165111072871],
              [-38.545561520527123, -8.128204334005096],
              [-38.545569500218875, -8.128211579129575],
              [-38.545616380811914, -8.128254234685329],
              [-38.545672418892842, -8.128306126631792],
              [-38.545730094629029, -8.12835467365441],
              [-38.545776524860628, -8.128394253301872],
              [-38.545816065668852, -8.128425684516248],
              [-38.545846345205575, -8.128459457151862],
              [-38.545891784202688, -8.1284926135458],
              [-38.545962828643177, -8.128518652769175],
              [-38.546038430451944, -8.128527963337154],
              [-38.546128187040217, -8.128542174130146],
              [-38.546163665055886, -8.12855415346899],
              [-38.546210961897394, -8.12854959316763],
              [-38.546257803257255, -8.128546479594135],
              [-38.546340681592433, -8.128542592145473],
              [-38.546421313513662, -8.128518350338238],
              [-38.546437568926812, -8.128511765428723],
              [-38.546464086229079, -8.128500940691016],
              [-38.546533635043119, -8.128484736781806],
              [-38.546556061352533, -8.12847951553753],
              [-38.546602813659355, -8.128474864130085],
              [-38.546650205931542, -8.128466143063315],
              [-38.546695690692665, -8.128458595748496],
              [-38.546742631949705, -8.128447341494921],
              [-38.546793476150775, -8.128436272490724],
              [-38.546836429370195, -8.128419767530163],
              [-38.546882020930958, -8.128397928800558],
              [-38.546923708424693, -8.128376899789487],
              [-38.546967666506006, -8.128354697419422],
              [-38.547010803078628, -8.128336564496813],
              [-38.547059383973576, -8.128320156232816],
              [-38.547106506545376, -8.128309083042941],
              [-38.547159524354001, -8.128302358137553],
              [-38.547208178566123, -8.128301507786574],
              [-38.54725638723955, -8.128293239819341],
              [-38.547347174352986, -8.128278868463385],
              [-38.547391662429199, -8.128269691812317],
              [-38.54743270100726, -8.128260692222726],
              [-38.547482637258831, -8.128249803024756],
              [-38.547573435403081, -8.128225572294012],
              [-38.547613387762929, -8.128213857898688],
              [-38.547619017381201, -8.128212236013923],
              [-38.547667057465013, -8.12819238987394],
              [-38.54771209704036, -8.128177063022015],
              [-38.547760226468654, -8.128158483306597],
              [-38.547800823835601, -8.128138176610319],
              [-38.547847773133213, -8.12811968602441],
              [-38.547937579028805, -8.128089846043551],
              [-38.548027375699732, -8.12806823722079],
              [-38.548071864449668, -8.128058427325508],
              [-38.548139792520416, -8.12803037203841],
              [-38.548182856052065, -8.127996319242833],
              [-38.548236339704616, -8.127978830848461],
              [-38.548293149497788, -8.127990290982543],
              [-38.548354493486613, -8.128005645607445],
              [-38.54836311428857, -8.128007826044341],
              [-38.548444408247711, -8.128040750621063],
              [-38.548447492939452, -8.128042110835555],
              [-38.548514177153571, -8.128071129713819],
              [-38.548565344919112, -8.128095337368404],
              [-38.548620225500748, -8.128127418516758],
              [-38.548681652107909, -8.128150099848975],
              [-38.548739094304842, -8.12816427420346],
              [-38.548804530104562, -8.128173301618634],
              [-38.548864706147796, -8.128177891009575],
              [-38.548922892507953, -8.128175965594938],
              [-38.548986977662302, -8.128175222598204],
              [-38.549037818045321, -8.128167500051408],
              [-38.549093289155763, -8.128158516295542],
              [-38.549155563637029, -8.128153519990423],
              [-38.549213934464468, -8.128148971611886],
              [-38.549273110618969, -8.128154826171057],
              [-38.549330733878968, -8.128169452906977],
              [-38.549418185342624, -8.128215861561968],
              [-38.549427982746323, -8.128221118670272],
              [-38.549378659627386, -8.128251998823272],
              [-38.549335854947294, -8.12829817268964],
              [-38.54933888190412, -8.128351271743943],
              [-38.549326859661385, -8.128387529929521],
              [-38.549339945233605, -8.128455836174028],
              [-38.549290790384646, -8.128417248873701],
              [-38.549197726135141, -8.128438673447057],
              [-38.549134339130376, -8.128464472629355],
              [-38.54906186936573, -8.128495327077333],
              [-38.549019003653441, -8.128514817311366],
              [-38.548942622536309, -8.128552994062797],
              [-38.548889411043689, -8.128570573301673],
              [-38.548865075429084, -8.12857859658941],
              [-38.548790091327184, -8.128585025986419],
              [-38.548775566657177, -8.128586185754374],
              [-38.548728180771292, -8.12858920856182],
              [-38.548682798518733, -8.128586263713752],
              [-38.548593486250837, -8.128580466067163],
              [-38.548504440130273, -8.12858018630701],
              [-38.548425634035738, -8.128595204392688],
              [-38.548353156612222, -8.128632842648559],
              [-38.548304249480786, -8.128697461955056],
              [-38.548256782627739, -8.128772846717125],
              [-38.548227242277925, -8.128808361781246],
              [-38.54819969686136, -8.128845688111914],
              [-38.54814542801298, -8.128915819048787],
              [-38.548108430953334, -8.128963356015891],
              [-38.548118610542922, -8.129032654037179],
              [-38.54808224434742, -8.129003126067964],
              [-38.548050750636712, -8.128999653866382],
              [-38.547978326470137, -8.128989623576816],
              [-38.547913146978374, -8.128994797369288],
              [-38.547856490849206, -8.129008121364265],
              [-38.547811718635039, -8.129027790249822],
              [-38.547762919058599, -8.129077304015212],
              [-38.547747453125346, -8.129108402514925],
              [-38.547711259466254, -8.129167970533542],
              [-38.5476784144983, -8.129236316177053],
              [-38.547653314248421, -8.129278620079413],
              [-38.547632387438128, -8.129322737675205],
              [-38.547612363064516, -8.129371559809536],
              [-38.54759171068627, -8.129413778202979],
              [-38.547562436361375, -8.12945481111749],
              [-38.547504897631129, -8.129526656917623],
              [-38.547444374177772, -8.129588911409449],
              [-38.547381138115398, -8.129641755799955],
              [-38.547339326576548, -8.129692362716366],
              [-38.547329620453745, -8.129767608482034],
              [-38.547323178845907, -8.129845752365034],
              [-38.54734024131146, -8.129928987761561],
              [-38.547359680163218, -8.129997662932968],
              [-38.547365348353814, -8.130042624189771],
              [-38.547359669017993, -8.1300885677924],
              [-38.547352510465444, -8.130158479689149],
              [-38.54735027617663, -8.130208226136222],
              [-38.547355293553707, -8.13026693546472],
              [-38.547360757791033, -8.130331886520443],
              [-38.547373031301667, -8.130395578820281],
              [-38.547371779677341, -8.130459436967577],
              [-38.547371245400271, -8.130531165290137],
              [-38.547381876715754, -8.130602272874897],
              [-38.547398852603251, -8.130681799615012],
              [-38.547410470243165, -8.130763762609803],
              [-38.547424541048855, -8.130843647925161],
              [-38.547440439558606, -8.130912590509306],
              [-38.547463345730144, -8.130965259424389],
              [-38.547497441846083, -8.130995598999842],
              [-38.547543793337383, -8.131024866781324],
              [-38.547586887502277, -8.131044814314331],
              [-38.547574289969972, -8.131108207572433],
              [-38.547538838585652, -8.131153213515367],
              [-38.54751916329392, -8.131214428030756],
              [-38.54751227837086, -8.131283164347634],
              [-38.547510023606584, -8.13135118219688],
              [-38.54751138986969, -8.131427977979106],
              [-38.547516657892466, -8.13150604444049],
              [-38.54752664877897, -8.131581673938223],
              [-38.54753191609533, -8.131660373566543],
              [-38.547545446980621, -8.131736097456441],
              [-38.547547263253314, -8.13181633093726],
              [-38.547551453886129, -8.131883813243665],
              [-38.547548385621361, -8.131948754797337],
              [-38.547555949307409, -8.132003306135557],
              [-38.547610357296165, -8.132052482454318],
              [-38.547651451248043, -8.13207541269535],
              [-38.547707782087976, -8.132109756914826],
              [-38.547757027267508, -8.132148977684333],
              [-38.54780408542188, -8.132195794034105],
              [-38.547835601143007, -8.13226086451602],
              [-38.54786692671761, -8.132333623255793],
              [-38.547890089829977, -8.132400131684841],
              [-38.547908800671479, -8.132470615075389],
              [-38.547926876926375, -8.132540374138536],
              [-38.547958218847313, -8.132598570021086],
              [-38.547990842321163, -8.132647179350657],
              [-38.548023564678147, -8.13268855257942],
              [-38.548066656409233, -8.132710851835792],
              [-38.548124646990694, -8.13272222288653],
              [-38.548208234644335, -8.132734165097913],
              [-38.548256982293815, -8.132731415249353],
              [-38.548325701988169, -8.132726697632044],
              [-38.548385259387565, -8.132716904476656],
              [-38.5484466258469, -8.132712811836626],
              [-38.54850146593683, -8.132700480750119],
              [-38.548545865637067, -8.13268958530173],
              [-38.548586765216868, -8.132724093172275],
              [-38.548614298166413, -8.132779299843049],
              [-38.548653918457177, -8.132821404306194],
              [-38.548702242949915, -8.132872382786651],
              [-38.548736146952344, -8.132912400485298],
              [-38.548771402528921, -8.1329613744906],
              [-38.54879077514272, -8.13300843135622],
              [-38.548782541448901, -8.133065859624587],
              [-38.548772221412229, -8.133122019239748],
              [-38.548759180942717, -8.133175733613397],
              [-38.548746325047517, -8.133226734612888],
              [-38.548723762003412, -8.133272840392527],
              [-38.548689946761471, -8.133315767834405],
              [-38.548661221119943, -8.133353002410701],
              [-38.548635654588558, -8.133406250604962],
              [-38.548633327015011, -8.133458348722911],
              [-38.548644162302736, -8.133509647385733],
              [-38.54867023789653, -8.133569375065841],
              [-38.54868915777574, -8.133615436454379],
              [-38.548724245817496, -8.133651927818978],
              [-38.548766428048467, -8.133675944597345],
              [-38.548817602287194, -8.133694996460857],
              [-38.548862789412986, -8.133710604472888],
              [-38.5489087029218, -8.133726032381123],
              [-38.548959436759723, -8.133733053541244],
              [-38.549019991983528, -8.133723985028816],
              [-38.548956142373967, -8.133756838921926],
              [-38.548891277577056, -8.133804616358301],
              [-38.548834785141572, -8.133833317577402],
              [-38.548779475163833, -8.133859849238387],
              [-38.548736321164391, -8.133893087882619],
              [-38.548688160626938, -8.133938893874515],
              [-38.548640563732057, -8.13396769539585],
              [-38.548580438345908, -8.133998201594368],
              [-38.548527478692179, -8.134033419279627],
              [-38.548489768051354, -8.134069287067998],
              [-38.548437275299051, -8.134092836873265],
              [-38.548379977687503, -8.134110954175574],
              [-38.548313136332602, -8.13414036743729],
              [-38.548246015968395, -8.134175750248994],
              [-38.548201488662983, -8.134219389354202],
              [-38.548152595063257, -8.134271254785707],
              [-38.548103689700518, -8.134333612698885],
              [-38.548071392609224, -8.134398702653595],
              [-38.54806107202505, -8.134455224049583],
              [-38.548080169868058, -8.134504451501813],
              [-38.548122134537557, -8.134560669231821],
              [-38.548169384270459, -8.134598802314764],
              [-38.548211909901539, -8.134640457791711],
              [-38.548285685630034, -8.13466034892738],
              [-38.548347930444336, -8.134682759906822],
              [-38.548405634927725, -8.134706703525564],
              [-38.548458970097478, -8.134741587046102],
              [-38.548505495325109, -8.134778272045734],
              [-38.548541667956535, -8.134819015905924],
              [-38.548576030814615, -8.134854692406932],
              [-38.548609303701745, -8.134891000862783],
              [-38.548661617045198, -8.134946777774767],
              [-38.54868326086001, -8.134992209021204],
              [-38.548704807634451, -8.135043248220235],
              [-38.548727540626921, -8.135088771126879],
              [-38.548741732718732, -8.135141701660897],
              [-38.548752116214764, -8.135191190766005],
              [-38.548769129340528, -8.135237792749784],
              [-38.548791506745722, -8.135276621765888],
              [-38.548742150796414, -8.135254858331141],
              [-38.548666991896084, -8.135254141687097],
              [-38.548601256636424, -8.135268360177262],
              [-38.54853624178341, -8.135287735255089],
              [-38.548475206134924, -8.135320320823512],
              [-38.548410809971173, -8.135354621246631],
              [-38.548345859340039, -8.135397694944537],
              [-38.548279450461422, -8.135446013260598],
              [-38.548242565526181, -8.135473922104898],
              [-38.548174970603768, -8.135527575786757],
              [-38.548102472629367, -8.135582399886927],
              [-38.548062689128948, -8.135604787881443],
              [-38.548019813277705, -8.135632599587446],
              [-38.547979392738704, -8.135656434106037],
              [-38.547938962668098, -8.13568877115333],
              [-38.547881349916729, -8.135744878107689],
              [-38.547814023468881, -8.135801878768323],
              [-38.547775503042395, -8.135831142534885],
              [-38.54773080581495, -8.13586419843052],
              [-38.547688838755725, -8.135891016131687],
              [-38.54764396524341, -8.135919368286213],
              [-38.547570109799764, -8.13597039176272],
              [-38.547499614142623, -8.136020333535592],
              [-38.547440917288306, -8.136071735679099],
              [-38.547391658339528, -8.136125319189098],
              [-38.547345388887628, -8.13618424272528],
              [-38.547301124947026, -8.136235570473175],
              [-38.547244428791345, -8.136283628068494],
              [-38.54717447899619, -8.136332394503833],
              [-38.54709111005625, -8.136367578080252],
              [-38.547010471292076, -8.136396523455131],
              [-38.546934917719291, -8.136423755885282],
              [-38.546848226424117, -8.136426644135479],
              [-38.546800660607367, -8.136427405151776],
              [-38.546754811342247, -8.136435404283329],
              [-38.546691005543465, -8.136428730057903],
              [-38.546617931985075, -8.136430005306591],
              [-38.546538415191243, -8.136429826109451],
              [-38.546462883041954, -8.136437972981378],
              [-38.546386352390584, -8.136446118724269],
              [-38.546313994229962, -8.136456982689031],
              [-38.54624335657639, -8.136471557117538],
              [-38.546165717411455, -8.136496797115086],
              [-38.546086156336614, -8.136535964644954],
              [-38.54600731837504, -8.136577846543332],
              [-38.545932113751029, -8.136617561624783],
              [-38.545895147250235, -8.136637058060421],
              [-38.545866718374391, -8.136652131860636],
              [-38.5458223083996, -8.136671710421181],
              [-38.545804507060652, -8.136680464418381],
              [-38.54575809641257, -8.136703297026957],
              [-38.545689260811159, -8.136729722655659],
              [-38.545629055746517, -8.136749826245847],
              [-38.545572566348291, -8.136775270683833],
              [-38.545508803095352, -8.136811380377884],
              [-38.54544821738871, -8.136847041353729],
              [-38.545400972520845, -8.136885159467639],
              [-38.545362987897711, -8.136922021602999],
              [-38.545314826516851, -8.136967917599778],
              [-38.545282381059678, -8.137002976875131],
              [-38.545280688417598, -8.137055075650954],
              [-38.545301147204484, -8.137104666549456],
              [-38.545245001334735, -8.137066884988313],
              [-38.545170576582805, -8.137058841844732],
              [-38.54510330228355, -8.137069078109826],
              [-38.545043014327476, -8.137082126216805],
              [-38.544975189467259, -8.137097608091503],
              [-38.544894835602747, -8.137115156327397],
              [-38.544819745332411, -8.137133795875238],
              [-38.544767329996226, -8.137168651824837],
              [-38.544743203218601, -8.137232393832122],
              [-38.544738456486449, -8.137255906174362],
              [-38.542327597038799, -8.137273549017374],
              [-38.553366796468197, -8.137256529402093],
              [-38.553928700235161, -8.136008628753899],
              [-38.563097527435026, -8.13345597419651],
              [-38.56392438198192, -8.13092698799297],
              [-38.561323543893188, -8.127375817849542],
              [-38.562412610907685, -8.126457086053893],
              [-38.563993601575497, -8.127937235106319],
              [-38.56570757474303, -8.128497705729609],
              [-38.569004180011106, -8.128862572183223],
              [-38.571508408682597, -8.130376505625888],
              [-38.573584842667401, -8.131035829129702],
              [-38.576717601792126, -8.13048046558645],
              [-38.582945678489111, -8.133871020574171],
              [-38.587790568472968, -8.135748794068769],
              [-38.59342733351594, -8.137167170224167],
              [-38.594156174246443, -8.133718107468443],
              [-38.584134482744354, -8.13206525880107],
              [-38.583181443335796, -8.129008779595365],
              [-38.575405929036265, -8.123678121489437],
              [-38.560472899845493, -8.121395336902186],
              [-38.567142851009869, -8.112137213605182],
              [-38.550174816618615, -8.101966599477251],
              [-38.546989081207855, -8.120427724192897],
              [-38.542735909657836, -8.120587140064602],
              [-38.540759367600124, -8.119106558003898],
              [-38.537099626072248, -8.11913515707384],
              [-38.536670714913235, -8.119397614659277],
              [-38.536767167852048, -8.121566142730904],
              [-38.521635743181498, -8.12003727130293],
              [-38.52619187785173, -8.114621606414918],
              [-38.52339439754833, -8.110478517846232],
              [-38.520887593683639, -8.111329810627048],
              [-38.516866730246583, -8.110175233457813],
              [-38.508640301414921, -8.124358702023002],
              [-38.49641215486929, -8.121255357674167],
              [-38.502452724847842, -8.115578867737456],
              [-38.496888393989352, -8.109526760392431],
              [-38.501247506401675, -8.103651101399437],
              [-38.486385485158912, -8.085316040458711],
              [-38.483860135864276, -8.100886153157207],
              [-38.481816553711944, -8.100554951127013],
              [-38.475810315627072, -8.106608906100949],
              [-38.476220529734597, -8.106222390082511],
              [-38.476235505954875, -8.106222409320351],
              [-38.476284794493161, -8.106220030432251],
              [-38.476358421495036, -8.106207280839282],
              [-38.476423252477908, -8.106188188312645],
              [-38.476472026827402, -8.106162291275195],
              [-38.476524162280654, -8.106134227703469],
              [-38.476595086656282, -8.106105735984357],
              [-38.476669092822469, -8.10608050447221],
              [-38.476734024823685, -8.106053452265352],
              [-38.476788688458427, -8.106035612955212],
              [-38.476804034277379, -8.106030567345311],
              [-38.476878309442, -8.106007868788954],
              [-38.476883849608299, -8.106005162342599],
              [-38.476939250683458, -8.105978550132848],
              [-38.476988193773913, -8.105962512492495],
              [-38.477043851011992, -8.105948382944918],
              [-38.477106299380871, -8.105946834898761],
              [-38.477155044932601, -8.105943188881437],
              [-38.477222567496, -8.105948340757235],
              [-38.477298470090503, -8.105930528605775],
              [-38.477359951330243, -8.105904738196742],
              [-38.47742051942739, -8.105883197842598],
              [-38.477472819625277, -8.105868068962543],
              [-38.477503332157134, -8.105856077971254],
              [-38.47753793132533, -8.105842373629308],
              [-38.477583786739572, -8.105827598283996],
              [-38.477668762172783, -8.105812601691019],
              [-38.477713791012981, -8.105805332772094],
              [-38.477791053405006, -8.10578878859287],
              [-38.477865955087971, -8.105772874538522],
              [-38.477938874164479, -8.105745922810291],
              [-38.478021978252784, -8.10570396910458],
              [-38.478065030534268, -8.105680958999381],
              [-38.478105268750333, -8.105658216643107],
              [-38.478176677890765, -8.105605574620833],
              [-38.478197309252756, -8.105584073488972],
              [-38.478226120520659, -8.105553989899787],
              [-38.478265748532529, -8.105511709152161],
              [-38.478275019131544, -8.105501952216745],
              [-38.478323565506635, -8.10544150205679],
              [-38.478379093068419, -8.105387030640427],
              [-38.478444137523432, -8.105342973347311],
              [-38.478510266250375, -8.105302716406024],
              [-38.478577656587902, -8.105269516308706],
              [-38.47864973268274, -8.105262734123649],
              [-38.478721092204587, -8.105248533959772],
              [-38.478784463023977, -8.10523540899448],
              [-38.478871909786349, -8.105204224420875],
              [-38.478933928550212, -8.105183771105436],
              [-38.478979242633436, -8.105166371800069],
              [-38.478963305849113, -8.105207507021518],
              [-38.478955808208724, -8.105250190706158],
              [-38.478947571763058, -8.105302732696074],
              [-38.478934884580184, -8.105357801649754],
              [-38.478926828960127, -8.105410886580684],
              [-38.478935552845755, -8.10547330949092],
              [-38.478969072530155, -8.105522558116919],
              [-38.479006954987149, -8.105567108819393],
              [-38.479039496404283, -8.10560070802372],
              [-38.479079477172874, -8.105636939832509],
              [-38.479120460550782, -8.105669916652527],
              [-38.479162883260763, -8.105712935460613],
              [-38.47921236598404, -8.105771249641336],
              [-38.479260950842487, -8.105821964716146],
              [-38.479299105886639, -8.10586633483906],
              [-38.479336966198701, -8.105928161786368],
              [-38.479377731465988, -8.105989630632415],
              [-38.47942222892361, -8.106042692219226],
              [-38.479470184880299, -8.10608843161833],
              [-38.4795318488042, -8.106132289021424],
              [-38.479578452841238, -8.106170609632777],
              [-38.479638250011838, -8.106183891917755],
              [-38.479705996623139, -8.106156028751846],
              [-38.47972492734516, -8.106186535176684],
              [-38.479762817500713, -8.106225206456882],
              [-38.479806565196334, -8.106226076338418],
              [-38.479851855665501, -8.106227129086951],
              [-38.479913677916734, -8.106218434118677],
              [-38.479978139901895, -8.106203863137821],
              [-38.480047160459073, -8.106173468887109],
              [-38.480097486433884, -8.106140699039912],
              [-38.48013239454324, -8.106098412085338],
              [-38.480172674964507, -8.106042745103265],
              [-38.480209869597466, -8.105986893278963],
              [-38.480247049475793, -8.105942528816218],
              [-38.480286775988588, -8.10589409726218],
              [-38.480316491997826, -8.105866185514927],
              [-38.48030736071258, -8.105908867149106],
              [-38.480282516405317, -8.105959850325606],
              [-38.480256028918184, -8.106018158005178],
              [-38.480221917859907, -8.106075913253362],
              [-38.48019043944123, -8.106133310046618],
              [-38.480149714128679, -8.106182011670651],
              [-38.480108457687123, -8.106220220198912],
              [-38.480053775329651, -8.10625271313614],
              [-38.479994932523688, -8.106273713375671],
              [-38.47992983040939, -8.106291992067456],
              [-38.479895753902213, -8.106322792679082],
              [-38.479878172231039, -8.106372609215331],
              [-38.47987392070474, -8.106432030650154],
              [-38.479878822159328, -8.106502589336833],
              [-38.479890736113106, -8.106554795245597],
              [-38.479913178009184, -8.10660764774631],
              [-38.479922277089095, -8.106660754582073],
              [-38.479931204283368, -8.106706353695824],
              [-38.479928616504743, -8.106742440678437],
              [-38.479913002836184, -8.106814691780386],
              [-38.479874092373059, -8.106863667039534],
              [-38.479827500094196, -8.106886763268431],
              [-38.479816510187405, -8.106892447712681],
              [-38.479777714178034, -8.106922970932922],
              [-38.479743454795077, -8.106954766263648],
              [-38.479715987918979, -8.106998148094068],
              [-38.479697928072639, -8.107066868440731],
              [-38.479666551769455, -8.107115310580308],
              [-38.479660052339241, -8.107158176453989],
              [-38.479660979150403, -8.107213895965764],
              [-38.479676215370183, -8.107294146186739],
              [-38.479692259782297, -8.10731053844507],
              [-38.479728069289237, -8.107343870419152],
              [-38.479789935062385, -8.107372079884545],
              [-38.479856556662682, -8.107372255340074],
              [-38.479884256225048, -8.107359717874211],
              [-38.479930656802324, -8.107344581159431],
              [-38.479985489601503, -8.107336419986138],
              [-38.480037240561423, -8.107324998608659],
              [-38.480102794112163, -8.107308710426787],
              [-38.480149810755059, -8.1073085894771],
              [-38.480195464293416, -8.10730973310973],
              [-38.480261089095386, -8.107308640901849],
              [-38.480316816187148, -8.107310882785056],
              [-38.480385970504486, -8.107317845270078],
              [-38.480446032511303, -8.107337097607148],
              [-38.480501288722557, -8.107352635286288],
              [-38.480577860160587, -8.107379597048176],
              [-38.48065115895362, -8.107410534508571],
              [-38.480694865251174, -8.107443786010794],
              [-38.480744901462991, -8.107495497747641],
              [-38.480788786640353, -8.107530829861012],
              [-38.480839411889811, -8.107547899280133],
              [-38.480904043883214, -8.107542464018067],
              [-38.480960137324416, -8.107542173630176],
              [-38.481026205793427, -8.107549041628992],
              [-38.481071491321075, -8.107554074126439],
              [-38.481117365377365, -8.107524826116475],
              [-38.481157434503217, -8.107492404898343],
              [-38.481191336333069, -8.107456267301513],
              [-38.481224505775806, -8.107425013169602],
              [-38.481262234802493, -8.107377121695123],
              [-38.481299324407203, -8.107332395217878],
              [-38.481344037360536, -8.107288402049784],
              [-38.481399189585282, -8.107243427184896],
              [-38.481445270716065, -8.107194189536825],
              [-38.481485541353457, -8.107146120367172],
              [-38.481528622923946, -8.107100225612067],
              [-38.481571070054493, -8.107053606430103],
              [-38.481608519992335, -8.107010960771063],
              [-38.481641068600489, -8.10696830887648],
              [-38.481676242792652, -8.106930816072788],
              [-38.481708690317198, -8.10689621425907],
              [-38.481766453337428, -8.106867885799495],
              [-38.481826114779132, -8.106845529567011],
              [-38.481861745200412, -8.106806137836079],
              [-38.48190864114418, -8.106758438845237],
              [-38.481955544946146, -8.106704589131351],
              [-38.481986900122671, -8.106672428104918],
              [-38.481986825832003, -8.106730498109089],
              [-38.481986579962253, -8.106780789034525],
              [-38.481990240351088, -8.106828461818759],
              [-38.481982813846535, -8.106886703384969],
              [-38.481972681278528, -8.106931735540076],
              [-38.481953917679256, -8.106983269262237],
              [-38.481939239899255, -8.10703372275252],
              [-38.481913301784829, -8.107088413170636],
              [-38.481889288252809, -8.107128633734167],
              [-38.481858826677943, -8.107171740560789],
              [-38.481826821855236, -8.107215026326909],
              [-38.481802807727796, -8.107255699142648],
              [-38.481787866535761, -8.107299187496888],
              [-38.481809414381296, -8.107341636808613],
              [-38.481846291498037, -8.107391974914533],
              [-38.48188624225952, -8.107451995273111],
              [-38.481927408977917, -8.107483886644255],
              [-38.481977595496929, -8.107489196624929],
              [-38.482042485308547, -8.10749506799136],
              [-38.482110819346303, -8.107504742701224],
              [-38.482196311509156, -8.107511725637108],
              [-38.482272619025935, -8.1075322646715],
              [-38.482358372046562, -8.107548202640125],
              [-38.482442767179066, -8.107561334859335],
              [-38.482525168601526, -8.107572022330194],
              [-38.48259449149355, -8.107589205720993],
              [-38.48266401996333, -8.107587575371792],
              [-38.482740734036263, -8.107574104932368],
              [-38.482820909552089, -8.107550960518784],
              [-38.482865674553615, -8.107537178167943],
              [-38.482926337121675, -8.107512742654214],
              [-38.482993104177865, -8.107470134045196],
              [-38.482988321775871, -8.107519152897746],
              [-38.482972292436521, -8.107561916307107],
              [-38.482948469400164, -8.107595172383986],
              [-38.482919829787264, -8.107633125847578],
              [-38.482877937035234, -8.107672147920216],
              [-38.482830591765129, -8.107716318827865],
              [-38.48278554381524, -8.107738422386808],
              [-38.482743848055996, -8.107765324143015],
              [-38.482689808772548, -8.107791757972123],
              [-38.48264621432547, -8.107812868388416],
              [-38.482597908741802, -8.107827188930221],
              [-38.482532624586661, -8.107845739152692],
              [-38.482476056219923, -8.107862491391554],
              [-38.482415865326345, -8.107872907392318],
              [-38.48235251510367, -8.107869661140423],
              [-38.482279726203515, -8.107865679277154],
              [-38.482214195300507, -8.107864239271763],
              [-38.48215717749008, -8.107877553723721],
              [-38.482102144019272, -8.107900639489598],
              [-38.482052097202711, -8.107927982821018],
              [-38.482004225278395, -8.107958042465139],
              [-38.481962520073679, -8.107992270725241],
              [-38.48192897808152, -8.108030941496063],
              [-38.481901875685075, -8.108073328974255],
              [-38.481871419474885, -8.108112184564936],
              [-38.481850304115696, -8.108157293204068],
              [-38.481851335301172, -8.108202610895887],
              [-38.481862792248485, -8.108257529736353],
              [-38.481881142863308, -8.108315984961086],
              [-38.481899954140516, -8.108369104110945],
              [-38.48191268725612, -8.108419773334921],
              [-38.481915435584391, -8.108470882127849],
              [-38.48193197831165, -8.108523546133977],
              [-38.481955334480531, -8.108571515302065],
              [-38.481967076015657, -8.108616846604871],
              [-38.481979089386918, -8.108662540060287],
              [-38.482021884455769, -8.108698775186612],
              [-38.482067685329113, -8.108726783008885],
              [-38.482124196321244, -8.108754985337306],
              [-38.482177179148209, -8.10877404753842],
              [-38.482215268433379, -8.108799331992863],
              [-38.482259148561603, -8.108838824741492],
              [-38.482309403116112, -8.108862044246727],
              [-38.482362034586565, -8.108871970340775],
              [-38.482415877124247, -8.108857566414709],
              [-38.48247147993861, -8.108815215090255],
              [-38.482508108330002, -8.108776276839805],
              [-38.482556790505988, -8.108751464378061],
              [-38.48261817269627, -8.108732276045838],
              [-38.48266568276825, -8.108701311355638],
              [-38.482723355576837, -8.108672711304665],
              [-38.482772566026647, -8.108660653199417],
              [-38.482840034690525, -8.108637402220964],
              [-38.482892979001903, -8.10861558003581],
              [-38.482941114233029, -8.108592575868947],
              [-38.482990345230775, -8.108564417329031],
              [-38.483044402853707, -8.108523692093511],
              [-38.483089013569561, -8.108488653338862],
              [-38.483127187197987, -8.108447907948685],
              [-38.48315456179553, -8.108405520728608],
              [-38.483152627890512, -8.108356222004417],
              [-38.483122728614305, -8.108314395327563],
              [-38.483074113858272, -8.108286474505327],
              [-38.483030035652902, -8.108259916208928],
              [-38.483007592106041, -8.108207968369918],
              [-38.483002777979692, -8.10813967109927],
              [-38.483007118346613, -8.108081425596549],
              [-38.483012274676938, -8.108023904743291],
              [-38.483043745365919, -8.10797238708386],
              [-38.483125853814698, -8.107928260230215],
              [-38.483186522087486, -8.107899392546123],
              [-38.483249730629915, -8.107871432591212],
              [-38.483352517742951, -8.107839723808063],
              [-38.483424234326158, -8.107830498146697],
              [-38.483473259432756, -8.107821334174108],
              [-38.483446990599283, -8.107850788203301],
              [-38.483418274441895, -8.107877616024842],
              [-38.483381922094161, -8.107913750710711],
              [-38.483312722339811, -8.107942245803979],
              [-38.483269568916789, -8.107973397005338],
              [-38.483234757366802, -8.108011252215364],
              [-38.483203393743935, -8.108050016311738],
              [-38.483171388174611, -8.108093935348306],
              [-38.483167970613195, -8.10814033282834],
              [-38.483176516892755, -8.108200222805927],
              [-38.48321740110498, -8.108240254367336],
              [-38.483255295950968, -8.108275669089908],
              [-38.483290290808782, -8.108307552510151],
              [-38.483320648548137, -8.108345761681687],
              [-38.483347912760678, -8.108389846302563],
              [-38.483363368912599, -8.108440609395542],
              [-38.483376655236071, -8.108484676299092],
              [-38.483386854608561, -8.108529462905025],
              [-38.483394319933666, -8.10858292942612],
              [-38.483407600377404, -8.108631609367199],
              [-38.483445384657749, -8.108682581657627],
              [-38.483490282251786, -8.108707241461868],
              [-38.483536374229921, -8.108720596289924],
              [-38.483603617144261, -8.108731987967108],
              [-38.48366570280411, -8.1087305289337],
              [-38.483717898931417, -8.108725982001202],
              [-38.483766280017356, -8.108723691527423],
              [-38.483825821009567, -8.108724761899543],
              [-38.483904870619419, -8.108730560461526],
              [-38.483975003752782, -8.108753171771372],
              [-38.484044866442296, -8.108774335497023],
              [-38.484099927189767, -8.108801088494484],
              [-38.484137900023221, -8.108846633852041],
              [-38.484161155914656, -8.108902291193735],
              [-38.484151047892894, -8.108999423728177],
              [-38.484139546055474, -8.109050695391439],
              [-38.484127581394716, -8.10910902171533],
              [-38.484113239644152, -8.109180822360836],
              [-38.484098445672878, -8.109251356106521],
              [-38.484078476201653, -8.109323330530193],
              [-38.484050613903072, -8.109392310048998],
              [-38.484027848661796, -8.109450170436],
              [-38.484011894309276, -8.109505325887048],
              [-38.483992585421454, -8.109557492178132],
              [-38.483959929797919, -8.109612897837325],
              [-38.483969760888421, -8.109661844752235],
              [-38.484015467559459, -8.109692746710298],
              [-38.484072172011558, -8.109711813388758],
              [-38.484143382524024, -8.109743833039202],
              [-38.484212513962682, -8.109769156607374],
              [-38.484256957434702, -8.109794177568928],
              [-38.484309183154387, -8.109837751009293],
              [-38.484318704239172, -8.109845089658769],
              [-38.484345816623971, -8.109866018347807],
              [-38.484352980101221, -8.109871544976013],
              [-38.484396871278179, -8.109902625501057],
              [-38.484426523849351, -8.109924552369437],
              [-38.484450826418836, -8.109942402133472],
              [-38.484501610638006, -8.10997756170231],
              [-38.484558132587757, -8.109997442152885],
              [-38.484634949710738, -8.109974654968559],
              [-38.484674032498688, -8.10993273471049],
              [-38.484700150607758, -8.10987912976276],
              [-38.484722821908292, -8.109823711417294],
              [-38.48475529476687, -8.109769209980062],
              [-38.484789921704014, -8.109733796611684],
              [-38.484814023708005, -8.109695294571923],
              [-38.484848836770126, -8.109656263357307],
              [-38.484881560138774, -8.109618857632212],
              [-38.484925440794697, -8.109586893117811],
              [-38.48498200978743, -8.109569778742143],
              [-38.485042271981847, -8.109574829739104],
              [-38.485128081479374, -8.109618174153018],
              [-38.485175247514142, -8.109643560263908],
              [-38.485222514674312, -8.109660805826614],
              [-38.485182194148933, -8.109676855336762],
              [-38.485118690351463, -8.10972272468238],
              [-38.485051835796902, -8.109762619958426],
              [-38.485003968566069, -8.109788971430826],
              [-38.484969793284172, -8.109826103974839],
              [-38.48494641499601, -8.109866506428364],
              [-38.484940076524737, -8.109925925312186],
              [-38.484960252179981, -8.109977146580421],
              [-38.485002129188963, -8.110021882734312],
              [-38.485038846242396, -8.110055848589235],
              [-38.485045918046481, -8.110062098705324],
              [-38.485090705913734, -8.110101863674757],
              [-38.485132592500342, -8.110139092321434],
              [-38.485179114142902, -8.110171623320717],
              [-38.48523963641933, -8.110186352975443],
              [-38.485305054888173, -8.110205159140227],
              [-38.485365115294343, -8.110226219826421],
              [-38.485429800288614, -8.110250813967186],
              [-38.485504175982889, -8.110292153933427],
              [-38.485576815296717, -8.110342808240878],
              [-38.485649995317033, -8.110396538583494],
              [-38.485688350042615, -8.110427521538787],
              [-38.485726073319597, -8.110455428329338],
              [-38.485774683243001, -8.110487509640985],
              [-38.485823572827151, -8.110513802378334],
              [-38.485875453561945, -8.110543355152215],
              [-38.485928607212792, -8.110571190939366],
              [-38.485980040680964, -8.110595587377984],
              [-38.486035826793113, -8.110623245568494],
              [-38.486090794640006, -8.110651988142795],
              [-38.486143764721767, -8.110681451806389],
              [-38.486198546229595, -8.110713993112171],
              [-38.486249881044856, -8.110744630575832],
              [-38.48629940433424, -8.1107722808371],
              [-38.48634774284907, -8.110803728579549],
              [-38.486406755389275, -8.110863863024393],
              [-38.486443991755863, -8.110917909741671],
              [-38.48648132911331, -8.110963906363233],
              [-38.486537880336179, -8.111032449717365],
              [-38.486571054747863, -8.111068762731982],
              [-38.486635789073695, -8.111126190799006],
              [-38.486669788199883, -8.111156354115364],
              [-38.486704059515176, -8.111186608223932],
              [-38.486741503868345, -8.11121985123952],
              [-38.486744767782085, -8.111222749811734],
              [-38.486780308230244, -8.111254271839799],
              [-38.486817661715058, -8.11128760518652],
              [-38.486859095673829, -8.111324109485533],
              [-38.48690515722933, -8.111361795482376],
              [-38.486949949441616, -8.111398394453451],
              [-38.486987485289234, -8.111431094852236],
              [-38.48702156801032, -8.111466956717875],
              [-38.487075778616607, -8.111520391589048],
              [-38.487135165669216, -8.111571662123021],
              [-38.487175249997833, -8.11159884814681],
              [-38.487217141406141, -8.11163254897904],
              [-38.487259212978906, -8.111667335457049],
              [-38.487307910732468, -8.111701949364425],
              [-38.487361600644775, -8.111736569547064],
              [-38.487413839679427, -8.111770102474921],
              [-38.487462085002086, -8.111803630373119],
              [-38.487514229965662, -8.11183978627642],
              [-38.487566647581623, -8.111875671160625],
              [-38.487613259363798, -8.111909016084111],
              [-38.487661590676652, -8.111946343049288],
              [-38.487709468853502, -8.111983126728008],
              [-38.48775008938842, -8.112017006815945],
              [-38.487791705040692, -8.112053511256914],
              [-38.48783994596478, -8.112090566733364],
              [-38.48788574158376, -8.112123367890737],
              [-38.487939146140945, -8.112168389615659],
              [-38.487973774409497, -8.112203618931403],
              [-38.48800221934632, -8.112247704763336],
              [-38.488032480878147, -8.112290797904999],
              [-38.48806575201619, -8.112322588335459],
              [-38.488100477254676, -8.112352933359487],
              [-38.4881373822681, -8.112382195696171],
              [-38.48817655770879, -8.112410465910646],
              [-38.48831214649384, -8.112495389669748],
              [-38.488328927439561, -8.1125040036772],
              [-38.488389804463239, -8.112525698134668],
              [-38.488439947938559, -8.112565650396739],
              [-38.48847893170872, -8.112601970565379],
              [-38.48852373906896, -8.112626810646312],
              [-38.488576521890174, -8.112661158186555],
              [-38.488613514990291, -8.112692591440409],
              [-38.48865677670787, -8.112719509962089],
              [-38.488720611350402, -8.112699238388164],
              [-38.488776287621711, -8.112670544537361],
              [-38.488808893400638, -8.112654304100102],
              [-38.488861565564093, -8.112632571266625],
              [-38.488895520495731, -8.112626101323666],
              [-38.48887905175107, -8.112657738841358],
              [-38.488843857544026, -8.112711151781951],
              [-38.488848229231834, -8.112771217351058],
              [-38.488890177050571, -8.112832325032192],
              [-38.488929612093436, -8.112870726121933],
              [-38.488955452367804, -8.112893100163236],
              [-38.488997078389076, -8.112921554282419],
              [-38.488956491869168, -8.112932448058869],
              [-38.488906092949961, -8.112950927484425],
              [-38.488844118664197, -8.113007382192555],
              [-38.488811850895843, -8.113043432028055],
              [-38.488805421685456, -8.113103212688356],
              [-38.488818888552984, -8.113148636455584],
              [-38.488844049816258, -8.113205562290629],
              [-38.488877564025294, -8.11326059911347],
              [-38.48892234303181, -8.113307961651399],
              [-38.488970848855558, -8.113351077618875],
              [-38.489019709874079, -8.113400435210139],
              [-38.489065468041879, -8.113463085338971],
              [-38.489087655985308, -8.113502640660958],
              [-38.489134034236962, -8.113577412119989],
              [-38.489175152483696, -8.11364864935347],
              [-38.489218192836297, -8.113707225722521],
              [-38.489251624248062, -8.113756021231481],
              [-38.489270261811519, -8.113803893671781],
              [-38.489271652417663, -8.113852739460267],
              [-38.489262505729293, -8.113908175028939],
              [-38.489251370160169, -8.113957276468087],
              [-38.4892759906961, -8.114011216689093],
              [-38.489315699100068, -8.114048894475724],
              [-38.48933361009049, -8.114097127810602],
              [-38.489325630698623, -8.114162605008252],
              [-38.489316653209592, -8.114227809598313],
              [-38.489307570676239, -8.114304320547861],
              [-38.489286073502193, -8.114364625035849],
              [-38.489250982742035, -8.114407907514749],
              [-38.489206716626036, -8.114457419667831],
              [-38.489164838312497, -8.114484864538799],
              [-38.489112085315575, -8.114498547072705],
              [-38.489062391202822, -8.114533760995558],
              [-38.489007908253079, -8.114551692585989],
              [-38.488950827794433, -8.114542123523989],
              [-38.488892212756916, -8.114525859085942],
              [-38.488846413703463, -8.114495500221715],
              [-38.488789732676132, -8.114457167926643],
              [-38.488732306775255, -8.114433668805049],
              [-38.488694043691332, -8.114401510344893],
              [-38.48863693927229, -8.11433911728669],
              [-38.488598406877152, -8.114304606730313],
              [-38.488532757241671, -8.114252604902159],
              [-38.488497121628747, -8.11422424870506],
              [-38.488432842788747, -8.114164921977668],
              [-38.488396585434913, -8.114125801213033],
              [-38.488339928986157, -8.114068112179993],
              [-38.488282000672889, -8.114011326061515],
              [-38.488223254100859, -8.113955624330671],
              [-38.488150182059172, -8.113887512679806],
              [-38.488115458523403, -8.113855720425454],
              [-38.488077288828862, -8.113821572087899],
              [-38.488042926769396, -8.113791046609027],
              [-38.488005482077078, -8.113757803695696],
              [-38.487967038154558, -8.113725192686754],
              [-38.487921515113626, -8.113692030064534],
              [-38.48787953530632, -8.113656248786317],
              [-38.487845265302447, -8.113624728436973],
              [-38.487804195925264, -8.113586777450223],
              [-38.487769108803136, -8.113555436971559],
              [-38.487730213490551, -8.11352092588451],
              [-38.487692859845183, -8.113487502155756],
              [-38.487653875726323, -8.113451453267855],
              [-38.487613894669408, -8.113414227247791],
              [-38.487576090303179, -8.113378360740764],
              [-38.487537557747508, -8.11334412144909],
              [-38.487502380702658, -8.113312147673096],
              [-38.487460136693542, -8.113270034389403],
              [-38.487418253697257, -8.113229549690342],
              [-38.487379099211267, -8.113184726728875],
              [-38.487335859430878, -8.113140531786705],
              [-38.487290803982582, -8.113096605911144],
              [-38.487253813479704, -8.113063182603103],
              [-38.487211197972364, -8.113027762270059],
              [-38.48716503798731, -8.112995955547765],
              [-38.487115886609061, -8.112961069689892],
              [-38.487063923882012, -8.112924280797007],
              [-38.487010958832549, -8.112890566000825],
              [-38.486956265097454, -8.112860105290162],
              [-38.486901662022809, -8.112829735138636],
              [-38.486847597820329, -8.112803888254273],
              [-38.486794081551828, -8.112775418948161],
              [-38.486740022757061, -8.11274532081759],
              [-38.486688411225629, -8.112717939320762],
              [-38.486633167546657, -8.112691729115951],
              [-38.486580830700824, -8.112663713529058],
              [-38.486528946432692, -8.112636693477135],
              [-38.486476345567688, -8.112602165007821],
              [-38.486424829892066, -8.112570803716736],
              [-38.486377844628777, -8.112545689311952],
              [-38.486332860828448, -8.112516959349305],
              [-38.486300682004426, -8.112482818412733],
              [-38.486271425974238, -8.112434299307067],
              [-38.48624553244575, -8.112382528170979],
              [-38.486212919791768, -8.112332648050741],
              [-38.486166496513263, -8.112293876095604],
              [-38.486111620245012, -8.112264319568212],
              [-38.486054376129829, -8.112241001228188],
              [-38.485992500979023, -8.112219214720618],
              [-38.485934789255964, -8.112206750002283],
              [-38.485876359450259, -8.112187952737148],
              [-38.485817938615796, -8.112162100227428],
              [-38.485763054231633, -8.11213896572983],
              [-38.485701629842637, -8.112119621949986],
              [-38.485644925429142, -8.112100193667583],
              [-38.485586401438482, -8.112084109800392],
              [-38.485525699403944, -8.112067751819328],
              [-38.485472522360851, -8.112058187223814],
              [-38.485418531528765, -8.112046179392637],
              [-38.485370710630235, -8.112035897933229],
              [-38.485321262727091, -8.112020277751759],
              [-38.485240088127817, -8.111971964352698],
              [-38.485162186086782, -8.111919765642225],
              [-38.485121735357211, -8.111895292520765],
              [-38.485080658476221, -8.11186358245336],
              [-38.485045754749962, -8.111831066116814],
              [-38.485009579724874, -8.111799000430171],
              [-38.484973676083598, -8.111767658698781],
              [-38.484929061527993, -8.11173422556014],
              [-38.484879722525264, -8.111704404519902],
              [-38.484832372443435, -8.1116808271687],
              [-38.484784028889997, -8.111653359125143],
              [-38.484738772319147, -8.111625171364652],
              [-38.484699604353295, -8.111591292830569],
              [-38.484662070187277, -8.111557416359311],
              [-38.484624805216079, -8.111525982426244],
              [-38.484582279032601, -8.111492009182664],
              [-38.484543105793108, -8.111462291414732],
              [-38.484498220968156, -8.111427320208804],
              [-38.484449161364545, -8.11139198190903],
              [-38.484399554060616, -8.111359085111577],
              [-38.484350769884877, -8.111321304948612],
              [-38.484302070375492, -8.111288318836989],
              [-38.484254724874511, -8.111261213805758],
              [-38.484203292018449, -8.11123636489272],
              [-38.484155848270113, -8.111215139098377],
              [-38.484103961019017, -8.111190741857669],
              [-38.484059874708436, -8.111170243918231],
              [-38.484053615646609, -8.111167251081676],
              [-38.484000463909787, -8.111137967826236],
              [-38.483971527489281, -8.111123820692162],
              [-38.48394358903748, -8.111110036627549],
              [-38.483895875558311, -8.111086820521738],
              [-38.483843084947857, -8.111059075386221],
              [-38.483791202814153, -8.111030698231966],
              [-38.48374557882164, -8.111006037526387],
              [-38.483695339955361, -8.110970245389042],
              [-38.483648096726824, -8.110934185686011],
              [-38.483603032586409, -8.110897586028065],
              [-38.48356214334936, -8.110861263011959],
              [-38.483525971186964, -8.110827116815239],
              [-38.483486357884345, -8.110786725075249],
              [-38.483455262748322, -8.110757198353118],
              [-38.483453268203256, -8.110755386787615],
              [-38.483417636846028, -8.110724226179425],
              [-38.483345917661957, -8.110663984768875],
              [-38.48330120613501, -8.110635616647617],
              [-38.483252861546987, -8.110609143404277],
              [-38.483202242963173, -8.110586466250259],
              [-38.483155429050953, -8.110569673286811],
              [-38.483107257002438, -8.110550255491466],
              [-38.483062711094554, -8.110534369913136],
              [-38.483014810429026, -8.110515676066546],
              [-38.482971174269345, -8.110498163499068],
              [-38.482927456939287, -8.110473143319551],
              [-38.482869152290682, -8.110427752956744],
              [-38.482822014531699, -8.110380296364669],
              [-38.482769167080605, -8.110326139083503],
              [-38.482718765816273, -8.11027551252368],
              [-38.482687137857923, -8.110236849433843],
              [-38.482674220756884, -8.110187989032687],
              [-38.482720947518757, -8.110130882766189],
              [-38.482779018390225, -8.110075057216108],
              [-38.482795467251037, -8.110059249014634],
              [-38.482846176585873, -8.110010831164351],
              [-38.482899986278909, -8.109951201209249],
              [-38.482957887122836, -8.109886330227729],
              [-38.482993330302442, -8.109851551221093],
              [-38.483024597783555, -8.109817128721842],
              [-38.483058769189505, -8.109783162162307],
              [-38.48310056931156, -8.10974576810362],
              [-38.483139373575291, -8.109708822500284],
              [-38.483177726659093, -8.10966979592766],
              [-38.483209448055064, -8.109635283535757],
              [-38.48324008060208, -8.109600498404861],
              [-38.48327371684536, -8.109559114092708],
              [-38.483304168311193, -8.109523966919181],
              [-38.483337796228575, -8.109489095125049],
              [-38.483373056423709, -8.109455401271111],
              [-38.483405684289579, -8.109421794529251],
              [-38.48345685018613, -8.109371115873845],
              [-38.483495841914682, -8.109329466971705],
              [-38.483521386683428, -8.109298474347719],
              [-38.483491014431117, -8.109271571653482],
              [-38.483459753150058, -8.109301200252947],
              [-38.483420040720169, -8.109338597000091],
              [-38.483368959239847, -8.109394250611022],
              [-38.483321440934205, -8.109431637452985],
              [-38.483281815665116, -8.109471838303421],
              [-38.483240105776957, -8.10950968475772],
              [-38.483195576135259, -8.109552231129118],
              [-38.483150321974612, -8.109593510250093],
              [-38.483109335993724, -8.109633076192779],
              [-38.483066803288899, -8.109675534628833],
              [-38.483047625587389, -8.109695952427307],
              [-38.483028811293927, -8.109716099330095],
              [-38.482985643557484, -8.10975828559539],
              [-38.482977101283367, -8.109766324975404],
              [-38.482940932561149, -8.109800650801334],
              [-38.482898675932141, -8.109840124656015],
              [-38.482856510060799, -8.109879598620894],
              [-38.482815792043617, -8.109922511588918],
              [-38.482775530390214, -8.109963435190437],
              [-38.482733363338085, -8.11000381365791],
              [-38.482697005758986, -8.11004383768686],
              [-38.482683280566647, -8.110059106630187],
              [-38.482666555704313, -8.110077808936103],
              [-38.482635014718909, -8.11011313557578],
              [-38.482591566506969, -8.110161653067472],
              [-38.482557116834805, -8.110200232274316],
              [-38.482522595587774, -8.110223796378428],
              [-38.482471137303186, -8.110219027540346],
              [-38.482398108070988, -8.110189718847296],
              [-38.482367812950905, -8.110173489488052],
              [-38.482352030550786, -8.110164966967968],
              [-38.482298963365032, -8.110140748916152],
              [-38.482277191225968, -8.110131585621735],
              [-38.482243263048311, -8.110117251127576],
              [-38.482197471267888, -8.110082007179605],
              [-38.482173266854822, -8.110058458944781],
              [-38.4821561332747, -8.110041884485776],
              [-38.482114978673323, -8.11000031479157],
              [-38.482077098828718, -8.109953141240187],
              [-38.482046372366355, -8.109919363651084],
              [-38.482021893127531, -8.109897804999738],
              [-38.481964340494912, -8.109832335161968],
              [-38.481924365195844, -8.109791309660688],
              [-38.481879586493839, -8.109744670034827],
              [-38.481827370098941, -8.109694041067222],
              [-38.481772164003907, -8.109639066603387],
              [-38.481714791821489, -8.109574501482316],
              [-38.481670275735191, -8.10953546012524],
              [-38.481625842546322, -8.109502569595026],
              [-38.481592919589168, -8.109482990122126],
              [-38.481598353080869, -8.109421851582782],
              [-38.481545836080812, -8.109393382903725],
              [-38.481502395547814, -8.109365016216429],
              [-38.481465126860037, -8.109336838280399],
              [-38.481434147317948, -8.109288135773268],
              [-38.48139884104274, -8.109216000730523],
              [-38.481345553203667, -8.109151621663768],
              [-38.481286706420008, -8.109104602271298],
              [-38.4812144094047, -8.109070771721775],
              [-38.481135199834171, -8.109048148389546],
              [-38.481088740678878, -8.109038049091779],
              [-38.481001077159313, -8.109025364674526],
              [-38.480913957776465, -8.109013042739047],
              [-38.480869045940572, -8.108999598669127],
              [-38.480824321787814, -8.108981360885982],
              [-38.48077796200127, -8.108964568244081],
              [-38.480728690176136, -8.1089536512569],
              [-38.480680866890978, -8.108945630568353],
              [-38.480633493026325, -8.108941047616794],
              [-38.480584488979474, -8.108933658575483],
              [-38.480499646088624, -8.108915640988648],
              [-38.480415247166057, -8.108905312356471],
              [-38.480334122314183, -8.108889922578513],
              [-38.480287667499383, -8.108876476479361],
              [-38.480242294790962, -8.108868549316755],
              [-38.48016307196766, -8.108856327745016],
              [-38.480077571103699, -8.108855947340295],
              [-38.479998146192472, -8.108859735453068],
              [-38.479949940121116, -8.108867091002919],
              [-38.479862346070014, -8.108871049570116],
              [-38.479857534446253, -8.108871857496647],
              [-38.479813503474176, -8.108879308809639],
              [-38.479760384779105, -8.108895431894421],
              [-38.479728695254231, -8.108904979341276],
              [-38.479648964899994, -8.108934545765329],
              [-38.479605832853778, -8.108948782089566],
              [-38.479520199227842, -8.108981054505312],
              [-38.479435828863657, -8.109019117432021],
              [-38.479392513991257, -8.109034348469134],
              [-38.479346651046171, -8.109054732000365],
              [-38.479259827884896, -8.109094419888201],
              [-38.479178072106258, -8.109146144323141],
              [-38.479100507940828, -8.109185120390919],
              [-38.479028381902452, -8.109230163665346],
              [-38.47895824733795, -8.109279370255109],
              [-38.478881310452593, -8.109324588254962],
              [-38.478835362741819, -8.109340268122152],
              [-38.4787941286719, -8.109360747957496],
              [-38.478728723893113, -8.109402181696863],
              [-38.478674755233676, -8.109443901407763],
              [-38.478646741952744, -8.109488458317909],
              [-38.478631160194219, -8.109535654230305],
              [-38.478596511330402, -8.109587710147871],
              [-38.478584694769729, -8.109600900995042],
              [-38.478546795106318, -8.109639927688672],
              [-38.478502611736289, -8.109695137211423],
              [-38.478475419505799, -8.109736438892531],
              [-38.478452585076987, -8.10977693208169],
              [-38.478424484827805, -8.109818503952043],
              [-38.478367031075983, -8.109888078311197],
              [-38.478316127134271, -8.109946264117703],
              [-38.478271954075247, -8.109993423413435],
              [-38.478222046198688, -8.110053419517318],
              [-38.478216955381178, -8.110059563722126],
              [-38.478166233394191, -8.110117387932448],
              [-38.478143849592122, -8.110160323875769],
              [-38.478128223192343, -8.110242072291848],
              [-38.478125388948079, -8.110328359677514],
              [-38.478129739206587, -8.110403802047104],
              [-38.478154799498249, -8.110467331298702],
              [-38.478183326747214, -8.110517025863322],
              [-38.478209606661039, -8.110549803062669],
              [-38.478227078081083, -8.11058582524759],
              [-38.478164660594842, -8.110562861023325],
              [-38.478105617903317, -8.11052714740862],
              [-38.478036667034864, -8.11050281802202],
              [-38.477977939594055, -8.110504190029518],
              [-38.477966591166016, -8.110506255884102],
              [-38.477916748754183, -8.110515418118165],
              [-38.477872531846174, -8.110526125233854],
              [-38.47786908075593, -8.110527658492847],
              [-38.477802694659623, -8.110555613489264],
              [-38.477743644023818, -8.110596422029207],
              [-38.477695474321379, -8.110645746973612],
              [-38.477646302095089, -8.110698236439077],
              [-38.47759077623558, -8.110750717752911],
              [-38.477521082338392, -8.110809783874126],
              [-38.47749690739704, -8.110833903505833],
              [-38.477462917225303, -8.110867688891622],
              [-38.477437903308434, -8.110908902830987],
              [-38.477420501236843, -8.110959804870641],
              [-38.477403302885435, -8.110993521331359],
              [-38.477373109214135, -8.111039432115774],
              [-38.477326988247761, -8.111118789628859],
              [-38.477282130861212, -8.1112036663149],
              [-38.4772325592253, -8.111284195261101],
              [-38.477189972588519, -8.111367808522067],
              [-38.477173578823873, -8.111411023439299],
              [-38.477149192725129, -8.111458027075264],
              [-38.477104344844435, -8.111535486702019],
              [-38.477073119121741, -8.111607355794057],
              [-38.477067371923809, -8.111699971038014],
              [-38.477060770946785, -8.111750886916811],
              [-38.477053935598995, -8.111842686697184],
              [-38.477064719071784, -8.111926639784508],
              [-38.477093935934619, -8.112004556258956],
              [-38.477133862763601, -8.112082848282496],
              [-38.477162679580132, -8.112119156422594],
              [-38.477199394590528, -8.112154298822048],
              [-38.477246532426385, -8.112201303773727],
              [-38.477286059455189, -8.11223780655059],
              [-38.477321317551976, -8.112276565142182],
              [-38.477346468460198, -8.112340185005056],
              [-38.477364454673911, -8.112399273087595],
              [-38.477397525753396, -8.112444722299887],
              [-38.47742543507141, -8.112480938808096],
              [-38.477436663819887, -8.112501304859919],
              [-38.47736916820368, -8.112474444560915],
              [-38.477296150687529, -8.112436089791656],
              [-38.477230184448096, -8.112419814290623],
              [-38.477161727666875, -8.112434379638524],
              [-38.477106954161641, -8.11246632927608],
              [-38.477072596072006, -8.11250373221475],
              [-38.477051243757948, -8.112520890646289],
              [-38.476994857599713, -8.112536466426731],
              [-38.476959454728977, -8.11253949634048],
              [-38.476898720782494, -8.11254810173944],
              [-38.476810087279887, -8.112583173672498],
              [-38.476756403393864, -8.112614762851763],
              [-38.476707607316243, -8.112656850706216],
              [-38.476657447860184, -8.112700384031125],
              [-38.47660926372577, -8.112760563011557],
              [-38.476581893646689, -8.112798698494993],
              [-38.47652833671355, -8.112872438326185],
              [-38.476471515795367, -8.112943369949924],
              [-38.476420867564016, -8.113013857238334],
              [-38.47637437818517, -8.113097013110741],
              [-38.476352995372714, -8.113137688919888],
              [-38.476326890120411, -8.113180348596172],
              [-38.476286838686967, -8.113268487577576],
              [-38.476266358954852, -8.113312692160207],
              [-38.476242615251536, -8.113354088540737],
              [-38.476223225528493, -8.11339766135956],
              [-38.476203929144013, -8.113439244358375],
              [-38.476183497413523, -8.113516463888224],
              [-38.476175318934288, -8.113593880075486],
              [-38.476142474412676, -8.113653897807353],
              [-38.476105362325683, -8.113715085920443],
              [-38.476067680206953, -8.113795901326705],
              [-38.476048016852737, -8.113840468751039],
              [-38.476026074485802, -8.113892631189957],
              [-38.476009130022106, -8.113940367920106],
              [-38.475988915023493, -8.113990361735988],
              [-38.475969609912951, -8.114038638137593],
              [-38.475947218377115, -8.114087181922645],
              [-38.475921012171646, -8.114137620285046],
              [-38.47589880297771, -8.114185531138807],
              [-38.475880665391472, -8.114243396922021],
              [-38.475870607214738, -8.114300368582226],
              [-38.475858645627625, -8.114355257402444],
              [-38.475863067472154, -8.114445172129605],
              [-38.475874043632366, -8.114520351655182],
              [-38.475883564361965, -8.114597971504873],
              [-38.475873026203786, -8.114675113285539],
              [-38.475866069321363, -8.114720330207213],
              [-38.475871879977795, -8.114789171463197],
              [-38.475882230973014, -8.114856480881539],
              [-38.475883948331258, -8.114932100751064],
              [-38.475877128323042, -8.115011779963311],
              [-38.475855149995205, -8.115091620572388],
              [-38.47583671403121, -8.115169566251376],
              [-38.475850230032229, -8.115246015368838],
              [-38.475877368120976, -8.115317054910607],
              [-38.47590451571206, -8.11538076787188],
              [-38.475939113474269, -8.115438882409702],
              [-38.475976534516825, -8.115489673986161],
              [-38.476014500639664, -8.115540104453896],
              [-38.476052671154662, -8.1155728970911],
              [-38.47609156207146, -8.115610122787364],
              [-38.476126094066565, -8.115648971004996],
              [-38.476163343574989, -8.11569207394737],
              [-38.476185795476098, -8.115737147793752],
              [-38.476211241408222, -8.1157832204589],
              [-38.476246129938204, -8.11582713442399],
              [-38.476287101045038, -8.115870151690718],
              [-38.476322458651595, -8.115902307526074],
              [-38.476372521010788, -8.115934120457872],
              [-38.476433931614906, -8.115964138937947],
              [-38.476491187662241, -8.11597814210929],
              [-38.476537835662512, -8.115983086463311],
              [-38.476589583482081, -8.115975012314108],
              [-38.47664012698101, -8.115985750574936],
              [-38.476696040141377, -8.115985370163671],
              [-38.476724495273828, -8.116020773360528],
              [-38.476763761301847, -8.116048773401477],
              [-38.476808005723534, -8.116087453141377],
              [-38.4768645953122, -8.116125515572499],
              [-38.476918560032892, -8.116157876164433],
              [-38.47697233492277, -8.116196658580558],
              [-38.477014967560862, -8.116218331299789],
              [-38.477091808924115, -8.116248188597883],
              [-38.477175287767388, -8.116269190130984],
              [-38.47725912618192, -8.116292905661773],
              [-38.47730649008377, -8.116305810604301],
              [-38.477395598041987, -8.11632564344549],
              [-38.477439054870302, -8.116341709170944],
              [-38.477489769694323, -8.116360407305251],
              [-38.477516351882016, -8.116370210200314],
              [-38.477577691391879, -8.116385122981065],
              [-38.477633485223883, -8.116406902973548],
              [-38.477708798233245, -8.11642545172904],
              [-38.477774322165885, -8.116433314609841],
              [-38.477852536121219, -8.116454671064135],
              [-38.477932656198881, -8.116476029947453],
              [-38.478003968121691, -8.116500271982078],
              [-38.478015309948319, -8.116503452331548],
              [-38.478075920911408, -8.116520263592911],
              [-38.478149061971067, -8.116533834638433],
              [-38.478217579855176, -8.116542786707278],
              [-38.478283831827326, -8.11654920321226],
              [-38.478351617046215, -8.116563219619573],
              [-38.478424492006788, -8.116571996331785],
              [-38.478486941909381, -8.116570538630274],
              [-38.478538687799045, -8.11656400191357],
              [-38.478604335548489, -8.11654626696888],
              [-38.478653399576068, -8.116507797326152],
              [-38.478675688521996, -8.116468298432705],
              [-38.478700071757018, -8.116423646467066],
              [-38.478723823113079, -8.1163762801374],
              [-38.478737227460293, -8.116328538755202],
              [-38.478755653497942, -8.116257919499173],
              [-38.478775533669456, -8.11618585487429],
              [-38.478787311653242, -8.11613232249379],
              [-38.478799013670113, -8.116067302639067],
              [-38.478829371266599, -8.11610569304395],
              [-38.478863091802026, -8.116140560130743],
              [-38.47890270755223, -8.116179052829464],
              [-38.478963927897183, -8.116216035485484],
              [-38.47901656358232, -8.116223338965948],
              [-38.479078270403235, -8.116234995728416],
              [-38.479126541809634, -8.116248082526825],
              [-38.479174173189215, -8.116264786572573],
              [-38.479186330528279, -8.116269053354019],
              [-38.479245218452583, -8.116284686365585],
              [-38.4793059184667, -8.116302854335801],
              [-38.479371073652295, -8.116315148626574],
              [-38.479432976006642, -8.11631595137202],
              [-38.479499509472269, -8.116315222346591],
              [-38.479570494725508, -8.116311242734794],
              [-38.47962187948243, -8.116303167735232],
              [-38.479604325432746, -8.116331094908301],
              [-38.479580950401427, -8.116368240724453],
              [-38.479573891015647, -8.116422864584914],
              [-38.479578458807048, -8.116470538515834],
              [-38.47958111978685, -8.116518752720678],
              [-38.479582223405203, -8.116578090505365],
              [-38.479593692077771, -8.116623783315271],
              [-38.479608517981141, -8.116670384932998],
              [-38.479634704707166, -8.116705423238226],
              [-38.479677862028332, -8.116742744520597],
              [-38.479738455772981, -8.116773032835685],
              [-38.479802598414736, -8.116796360887669],
              [-38.47987028843869, -8.116813904546138],
              [-38.479950685324255, -8.116831916680212],
              [-38.480013823965315, -8.116859585104159],
              [-38.480077973035179, -8.116877938274003],
              [-38.480147502505048, -8.116876760714486],
              [-38.480226654367982, -8.116874781358939],
              [-38.480291960896146, -8.116840040767256],
              [-38.480322414357438, -8.116803989363422],
              [-38.480341087346069, -8.116752907925415],
              [-38.480365542356815, -8.116793551989547],
              [-38.480393540090667, -8.116832029729638],
              [-38.480444789396671, -8.116858778400076],
              [-38.480488442366422, -8.116863628037629],
              [-38.480553720988567, -8.11685059579939],
              [-38.480612613940771, -8.116862429651967],
              [-38.480663400136734, -8.116896413849618],
              [-38.480713639762257, -8.11693193502505],
              [-38.48075752207513, -8.116970252094561],
              [-38.480790882687849, -8.117002857280893],
              [-38.480835380066246, -8.117057004193022],
              [-38.48088087090575, -8.117115041795033],
              [-38.480928277280064, -8.117165755238025],
              [-38.480969891612133, -8.117203164877997],
              [-38.481017942899619, -8.117246462085458],
              [-38.481079436844809, -8.117282721177704],
              [-38.481134325430574, -8.117302871422799],
              [-38.481201752474853, -8.117313268840309],
              [-38.481267943703394, -8.117296438677659],
              [-38.481339484410277, -8.117284137908122],
              [-38.481409581881138, -8.117264780046465],
              [-38.481475965648684, -8.11723935717187],
              [-38.481516218338321, -8.117206122158906],
              [-38.481546597773693, -8.117156955051675],
              [-38.481556374256733, -8.117106766741511],
              [-38.481559628234429, -8.11704662042718],
              [-38.481567426845558, -8.116981595500308],
              [-38.481600784885153, -8.116945457240694],
              [-38.481619633229315, -8.116899079452557],
              [-38.48164457956652, -8.116839774815423],
              [-38.481675778993512, -8.116788166539694],
              [-38.481702614505139, -8.116742070267867],
              [-38.481722936318924, -8.116679141665564],
              [-38.481767377908497, -8.116635238638461],
              [-38.481811623697986, -8.116602460930624],
              [-38.481843735509486, -8.1165471452753],
              [-38.481874309829301, -8.116487485973161],
              [-38.481901880010703, -8.116434697184623],
              [-38.481927639936245, -8.116377926210589],
              [-38.481970289411336, -8.116315840060807],
              [-38.481992856243572, -8.116271728275105],
              [-38.482020141927578, -8.11622843655598],
              [-38.482023166616536, -8.116276379870424],
              [-38.48202157491589, -8.116314367612537],
              [-38.482029320317096, -8.116361683768222],
              [-38.482041415369686, -8.116414613469036],
              [-38.482056941744595, -8.116481477114654],
              [-38.482068209818877, -8.116542275071813],
              [-38.482073573034853, -8.116606954947311],
              [-38.482073672901109, -8.116670723610477],
              [-38.482079126190705, -8.116735946311199],
              [-38.482098013060885, -8.116801186096463],
              [-38.482117538204577, -8.116864165397123],
              [-38.482124813801157, -8.116923963305258],
              [-38.482129450887619, -8.116988642256315],
              [-38.482147082320253, -8.117041940802403],
              [-38.482174772733551, -8.117107915394685],
              [-38.48220953885199, -8.117177155248285],
              [-38.482244589213018, -8.117237078922988],
              [-38.48228817752328, -8.117292490858949],
              [-38.482323791385404, -8.117337671596852],
              [-38.48236603070859, -8.1173833130135],
              [-38.482411001983373, -8.117421993108298],
              [-38.482448632322331, -8.11745189004108],
              [-38.482493595393855, -8.117496992196809],
              [-38.48253366860007, -8.117533042951356],
              [-38.482577644936384, -8.117569008210289],
              [-38.482629239973896, -8.117609505736562],
              [-38.482688287109212, -8.117642867030089],
              [-38.48273435668132, -8.117674493245087],
              [-38.482807997063901, -8.117723792582545],
              [-38.482792616143556, -8.117755973897046],
              [-38.48277124119965, -8.117790770699862],
              [-38.482760137114546, -8.117814545425427],
              [-38.482740481427683, -8.117853505194249],
              [-38.482725082445086, -8.117899796967542],
              [-38.482714485814846, -8.117952607372649],
              [-38.482715214431394, -8.1180216230425],
              [-38.482724313118879, -8.118075453438854],
              [-38.482738131614909, -8.118129380282952],
              [-38.482755940770957, -8.118185754395682],
              [-38.482782735824301, -8.11824223037455],
              [-38.482820336762302, -8.118295192456809],
              [-38.482858765147867, -8.118339924470771],
              [-38.482891029198264, -8.118378497928108],
              [-38.482927105618181, -8.118416985773091],
              [-38.482977228501063, -8.118473129559131],
              [-38.483032622986883, -8.118523762471778],
              [-38.48309292958912, -8.118566080045751],
              [-38.483154793595652, -8.118597183561546],
              [-38.48322203499783, -8.118611017594246],
              [-38.483284944055285, -8.118605670329254],
              [-38.483343054436546, -8.118590909974436],
              [-38.483401881942171, -8.118583205763976],
              [-38.483469603077268, -8.118576779153809],
              [-38.483526811663545, -8.118557404584356],
              [-38.483581939169802, -8.118532871612134],
              [-38.483640076332563, -8.118497126421161],
              [-38.483700633376976, -8.118485534930697],
              [-38.48376627757446, -8.118470693615002],
              [-38.483830045089583, -8.118432784689867],
              [-38.483927437438659, -8.118362988875425],
              [-38.483971786270416, -8.118320623171718],
              [-38.483995692947381, -8.118293427465566],
              [-38.484046765896338, -8.118316466957662],
              [-38.484091064967359, -8.118313085921788],
              [-38.484139562959136, -8.118362353175019],
              [-38.484185496778792, -8.118358250585683],
              [-38.484223015384217, -8.11833360476165],
              [-38.484244739119042, -8.118309933896631],
              [-38.484289714058534, -8.11827480509668],
              [-38.484340961080704, -8.118232538538757],
              [-38.484398024265815, -8.118184761776078],
              [-38.484453995802518, -8.118138883111079],
              [-38.484518499610807, -8.118092924787863],
              [-38.484559395489192, -8.118053449101696],
              [-38.484539566359878, -8.118014981962006],
              [-38.484522177469252, -8.117984749029311],
              [-38.48455834153777, -8.11795458389274],
              [-38.484582807864733, -8.117915539663564],
              [-38.484618352112804, -8.117873162736009],
              [-38.484676848937802, -8.117839950491524],
              [-38.484735079559393, -8.117801943952824],
              [-38.4847830586084, -8.11775985409998],
              [-38.48481433351435, -8.117720004402763],
              [-38.484859052745357, -8.117671759688909],
              [-38.484901783344561, -8.117688275984561],
              [-38.484932233144058, -8.117654847315277],
              [-38.484955070552722, -8.117611911569467],
              [-38.484980625340533, -8.117573411400024],
              [-38.484990021283082, -8.1176078854428],
              [-38.484991243068698, -8.117646329028664],
              [-38.484998234355295, -8.117715895351397],
              [-38.484989348901124, -8.117779471770282],
              [-38.484985903169346, -8.117848029921324],
              [-38.484985359698584, -8.117918400780097],
              [-38.484985441595207, -8.117925365676825],
              [-38.484986184194661, -8.1179837080649],
              [-38.484983317973949, -8.118024860035021],
              [-38.48498193272421, -8.118043491365636],
              [-38.484975404223334, -8.118109422512211],
              [-38.484970148312009, -8.118173908038324],
              [-38.48496107392922, -8.118243273134176],
              [-38.48495163428214, -8.118314356352602],
              [-38.484940191631807, -8.118390230984833],
              [-38.484935111367477, -8.118459329775909],
              [-38.48493555964351, -8.118534948094224],
              [-38.484955074703656, -8.118606248843703],
              [-38.484987575915568, -8.118672591167632],
              [-38.484994769713765, -8.11872569550653],
              [-38.485002142399246, -8.118780880464334],
              [-38.485024198060835, -8.118852908039692],
              [-38.485050532496132, -8.118914991322052],
              [-38.485091305380763, -8.118972118012319],
              [-38.485136810421523, -8.119019662786851],
              [-38.485145150016692, -8.119028356711604],
              [-38.485150437135516, -8.119081910896549],
              [-38.485157954521689, -8.119094764566675],
              [-38.485169012090338, -8.119107441809883],
              [-38.485193211783773, -8.119135241131552],
              [-38.485222756427113, -8.119171187877592],
              [-38.485264913453413, -8.119210587739037],
              [-38.485266817508773, -8.119212218279884],
              [-38.485314248531807, -8.119244207752651],
              [-38.48536769201467, -8.119259109419627],
              [-38.485425532777974, -8.11924226803548],
              [-38.485463275699018, -8.119255340807548],
              [-38.485498715808681, -8.119294822609037],
              [-38.48552853486742, -8.11932914154823],
              [-38.485571061919146, -8.119363114736538],
              [-38.485626472864119, -8.119401174533571],
              [-38.485691410669979, -8.119442231270952],
              [-38.485759795720668, -8.119484830034953],
              [-38.485829267012193, -8.119529781908545],
              [-38.48590282219422, -8.119575281639742],
              [-38.485959671113982, -8.119624649693742],
              [-38.486016696178865, -8.11967826920058],
              [-38.486066302304671, -8.119712974894183],
              [-38.486115914993171, -8.119742524834093],
              [-38.48616744566246, -8.119762760647085],
              [-38.486214250644984, -8.119787513082795],
              [-38.486253428923582, -8.119813974473972],
              [-38.486292935222075, -8.119868024095341],
              [-38.486321626553455, -8.119932733386801],
              [-38.486361739286913, -8.120009577638537],
              [-38.486393190346035, -8.120045436261943],
              [-38.486433082396189, -8.120081667340106],
              [-38.486458287006094, -8.120104493009173],
              [-38.486499456372798, -8.120135569936384],
              [-38.486546528439511, -8.120164483460265],
              [-38.486595155521186, -8.120183991945794],
              [-38.486651499403116, -8.120202334283261],
              [-38.48670595392619, -8.120207468257412],
              [-38.486760588609499, -8.120213687874033],
              [-38.486810694642593, -8.120212213364802],
              [-38.486862877782123, -8.12021906304029],
              [-38.486913325764178, -8.120234232094472],
              [-38.486972394210746, -8.120251401948682],
              [-38.48702811123848, -8.120263050006582],
              [-38.487081366967097, -8.120283016528461],
              [-38.487137269097381, -8.120291860796964],
              [-38.487197980228608, -8.12030206789445],
              [-38.487253415673159, -8.120321042169884],
              [-38.487306214712603, -8.120343269385099],
              [-38.48736137396353, -8.120365318662454],
              [-38.487414090849718, -8.120380761869873],
              [-38.487504546372058, -8.120412805281299],
              [-38.487583203403453, -8.120443748398436],
              [-38.487648161996241, -8.120468704434824],
              [-38.48770067536163, -8.120501514112108],
              [-38.487725436433379, -8.120516288932228],
              [-38.487742760119353, -8.120526622248885],
              [-38.487820796599955, -8.120545896248192],
              [-38.487837311282647, -8.120549987362944],
              [-38.487896384062239, -8.120563810373559],
              [-38.487951920088612, -8.120575096273868],
              [-38.488011445458326, -8.120589914808356],
              [-38.488066257865803, -8.120599209841744],
              [-38.488125518384535, -8.120608148654053],
              [-38.488172705911104, -8.120617705440342],
              [-38.488259434784631, -8.120653904791507],
              [-38.488336997784607, -8.120688735824908],
              [-38.488378447454878, -8.120713662197188],
              [-38.488389780910957, -8.12072362615077],
              [-38.488430586280074, -8.120755606955935],
              [-38.488503159914451, -8.120788170391474],
              [-38.488552789293479, -8.12080478545175],
              [-38.488599969188286, -8.12082040245784],
              [-38.48865840551656, -8.120835129080946],
              [-38.488724674135668, -8.120829152049826],
              [-38.488794302100501, -8.120822455611851],
              [-38.488862218388931, -8.120805535947673],
              [-38.488925684046087, -8.120790962432986],
              [-38.488987343021769, -8.120769512295487],
              [-38.489043376979581, -8.12074597469336],
              [-38.489100404467649, -8.120726327761878],
              [-38.489156356447616, -8.12069582524396],
              [-38.489204957893762, -8.120663956714953],
              [-38.489249115681524, -8.120628916787284],
              [-38.489293369601469, -8.120589625735219],
              [-38.489336440438635, -8.120552775395035],
              [-38.489381964984752, -8.120513576378178],
              [-38.489425313823567, -8.120472203780739],
              [-38.489464212262575, -8.120432996442112],
              [-38.489506382695197, -8.12039035603013],
              [-38.489541102805333, -8.120353495192592],
              [-38.489571025208633, -8.120306316817713],
              [-38.489597231814436, -8.120254611185375],
              [-38.489621067702174, -8.120211405058173],
              [-38.489655165540611, -8.120164141458817],
              [-38.489685811435621, -8.120119044374457],
              [-38.489711015794555, -8.120070322389404],
              [-38.489738129363396, -8.120019160593829],
              [-38.48976115355871, -8.119971792650603],
              [-38.489791082299391, -8.11991954895611],
              [-38.489818919709833, -8.119870197099056],
              [-38.489852192590583, -8.119829264079574],
              [-38.489881382835399, -8.119786788258246],
              [-38.489905947433805, -8.119741502628848],
              [-38.489932062389919, -8.119690430017306],
              [-38.48994674111924, -8.119638890815427],
              [-38.489959164220558, -8.119576585008161],
              [-38.489967948260869, -8.119520877631045],
              [-38.489978096216788, -8.11946327247184],
              [-38.489988144044091, -8.119413084244126],
              [-38.490002455916098, -8.119364529492254],
              [-38.490018262748528, -8.119282147593722],
              [-38.49003069814723, -8.119210072989686],
              [-38.490054564954896, -8.119142263944138],
              [-38.490070303996937, -8.119113610398797],
              [-38.490097779153288, -8.119063534446243],
              [-38.490120349063879, -8.119016437261436],
              [-38.49013374900229, -8.118971228081248],
              [-38.490154736408627, -8.118883425545635],
              [-38.490167538488564, -8.118808456931443],
              [-38.490178960679728, -8.118748049348406],
              [-38.490194235591296, -8.118727535888429],
              [-38.490213238489034, -8.118701871335935],
              [-38.490243416792424, -8.118667537389749],
              [-38.490279044247011, -8.118630768061031],
              [-38.490282134348746, -8.118627606112234],
              [-38.4903210402748, -8.118582247950586],
              [-38.490335951644838, -8.118562095837001],
              [-38.490357863888228, -8.118532635937376],
              [-38.490396431122136, -8.118467920630275],
              [-38.490410970384204, -8.118382914013361],
              [-38.490401179400024, -8.118301404569229],
              [-38.490404920240906, -8.118214032685827],
              [-38.49043370662082, -8.118131757455391],
              [-38.49043948598073, -8.118083372904509],
              [-38.490437785641035, -8.11799210477658],
              [-38.490440025964951, -8.117942992180696],
              [-38.490444403303691, -8.117854716571836],
              [-38.490438071524878, -8.11776525168573],
              [-38.490439816367136, -8.117677153685552],
              [-38.490446633599639, -8.117597474062432],
              [-38.490440206493808, -8.117511627133428],
              [-38.490433777223835, -8.117427498788263],
              [-38.49043079530324, -8.117344821987828],
              [-38.490431169962207, -8.117263596618377],
              [-38.490454773384002, -8.117188551047336],
              [-38.490499144346749, -8.117128003768826],
              [-38.4905508498098, -8.117081485710873],
              [-38.490610162762877, -8.117048454500823],
              [-38.490674635789013, -8.117026283968311],
              [-38.490699752010805, -8.116975210035058],
              [-38.490699469593672, -8.116911169707709],
              [-38.490707062424157, -8.116864234646219],
              [-38.490727086762654, -8.11682020959336],
              [-38.490772742169902, -8.116748719200226],
              [-38.490770727619818, -8.116834826930443],
              [-38.49077500792265, -8.116895615988112],
              [-38.490786375956986, -8.116950172724648],
              [-38.490801820449398, -8.117011156646081],
              [-38.490802928332549, -8.117068414110646],
              [-38.490766192494043, -8.11712055894319],
              [-38.490727850167723, -8.117150993296864],
              [-38.490677355909121, -8.117172729061295],
              [-38.490615064245041, -8.117192550570975],
              [-38.490564290722006, -8.117219803539575],
              [-38.490544523604527, -8.117275768558788],
              [-38.490547521844618, -8.117345510753523],
              [-38.4905589633033, -8.117413816280393],
              [-38.490568955786514, -8.11747949688802],
              [-38.490573323636674, -8.11754281870817],
              [-38.490579330776491, -8.117601800886151],
              [-38.490589867074597, -8.117668115337032],
              [-38.4906077679845, -8.117724579739948],
              [-38.490639468106899, -8.11777907146185],
              [-38.490674256393021, -8.117831938909731],
              [-38.490705586625289, -8.117891857280846],
              [-38.490730837359827, -8.117950230363085],
              [-38.490736840879556, -8.118012106996773],
              [-38.490744016635659, -8.118080135828095],
              [-38.490762076675622, -8.118154419455987],
              [-38.490794396390733, -8.118221484762161],
              [-38.490833434306587, -8.118287473044454],
              [-38.49086375760487, -8.118354174041604],
              [-38.490905707064719, -8.118414648390727],
              [-38.490943127706544, -8.118467519110864],
              [-38.490975820681257, -8.118526534649193],
              [-38.491001166129429, -8.118581832470129],
              [-38.491032235659503, -8.118632614848689],
              [-38.491060743312623, -8.118699584919714],
              [-38.491098967704609, -8.118762949057238],
              [-38.491133936868991, -8.118816449861798],
              [-38.491161003532291, -8.118874463383888],
              [-38.491194694448374, -8.118933932414347],
              [-38.491251190578048, -8.118975610908976],
              [-38.491312067486341, -8.11899829996271],
              [-38.491382765218049, -8.119006800322293],
              [-38.491453663490418, -8.119000195445878],
              [-38.491519756401132, -8.118989333314019],
              [-38.491586582619476, -8.118972773614493],
              [-38.491654082991843, -8.118925370632114],
              [-38.491706359110019, -8.118858230106213],
              [-38.491762812472174, -8.118789557103836],
              [-38.491803418859902, -8.118763286275479],
              [-38.491882334000628, -8.118733354725379],
              [-38.491967121317607, -8.118725500762611],
              [-38.492041821679081, -8.11872695074813],
              [-38.492114779610617, -8.118742599502879],
              [-38.492195453397677, -8.118757805608999],
              [-38.492276318382949, -8.118765323521727],
              [-38.492365167323577, -8.118775745836112],
              [-38.492410629723146, -8.118785661781921],
              [-38.49249675847129, -8.118793819377697],
              [-38.492600922409366, -8.118751708143844],
              [-38.492673987631221, -8.118681879831604],
              [-38.492657632102812, -8.118767969716425],
              [-38.492639142175257, -8.118818961579981],
              [-38.492605326519531, -8.118858718299469],
              [-38.492549307766019, -8.118870497687038],
              [-38.492483037330217, -8.118878193949234],
              [-38.49242593469215, -8.118885720726324],
              [-38.492369190695364, -8.118896775568766],
              [-38.492317338061632, -8.118915886726491],
              [-38.492260596216838, -8.118925222968873],
              [-38.492195873825146, -8.118928941228162],
              [-38.492123716520254, -8.118926228113652],
              [-38.492113729762266, -8.118928024694375],
              [-38.492058712164848, -8.118937634415021],
              [-38.492029098027885, -8.118956501923952],
              [-38.492012383408536, -8.118967063946755],
              [-38.491991630099243, -8.119013349449355],
              [-38.491997736975399, -8.119065366940397],
              [-38.492041877387493, -8.119116075108325],
              [-38.492046499854986, -8.1191214175408],
              [-38.492096379124249, -8.11915585151276],
              [-38.492104089640151, -8.119159660114045],
              [-38.492155160494292, -8.119184869466832],
              [-38.492184007823731, -8.119198382791671],
              [-38.492229728140785, -8.119219786479656],
              [-38.492273459516028, -8.119234856045608],
              [-38.492355322593632, -8.119242737000899],
              [-38.492438834264583, -8.119238861241318],
              [-38.492500061758101, -8.119271590727584],
              [-38.492541477510095, -8.119323652228745],
              [-38.492567637523813, -8.119381031376365],
              [-38.492607959649753, -8.119436619134834],
              [-38.492645483865338, -8.119479540129008],
              [-38.492688355466463, -8.119528799420861],
              [-38.492624658900176, -8.119510629647644],
              [-38.492577850217621, -8.119488501028867],
              [-38.492540212305435, -8.119463851182017],
              [-38.492511662569619, -8.119430167465858],
              [-38.49246950931078, -8.119387150237475],
              [-38.492435873730734, -8.119355721474973],
              [-38.492381425561263, -8.119345522968228],
              [-38.492320785510401, -8.119350964920606],
              [-38.492269677590805, -8.119355242876837],
              [-38.492200422062595, -8.119354704237239],
              [-38.492124458896733, -8.119346830610011],
              [-38.492049594612546, -8.119331269924123],
              [-38.49197362281717, -8.119330270606472],
              [-38.491903455181813, -8.119333258404311],
              [-38.491835646835519, -8.119336791846655],
              [-38.491763938870243, -8.119337606850923],
              [-38.491690589686598, -8.119344299169692],
              [-38.491610332909389, -8.119358309456329],
              [-38.491539033732529, -8.119394943900112],
              [-38.491485326498676, -8.119445168096387],
              [-38.491440324802859, -8.119501915699647],
              [-38.491422266223438, -8.119570636613714],
              [-38.491450962476748, -8.11963208932953],
              [-38.491493306397004, -8.119667780288951],
              [-38.491532015614261, -8.119706632522323],
              [-38.491587416750519, -8.119753013110598],
              [-38.491649708664823, -8.119805462577041],
              [-38.491694469698082, -8.119867930310473],
              [-38.49170039250312, -8.119921937515164],
              [-38.491699587651667, -8.119984529253498],
              [-38.491689535579759, -8.120038245154488],
              [-38.491676043067422, -8.120084991959336],
              [-38.491661088021353, -8.120139877611596],
              [-38.491628244785041, -8.120200168039455],
              [-38.491586419340734, -8.120257371879388],
              [-38.491537166694521, -8.120301993617463],
              [-38.491465141876006, -8.120338084418103],
              [-38.491422974602067, -8.120378373299612],
              [-38.491383065261566, -8.120427619739045],
              [-38.491346048889689, -8.120486095886235],
              [-38.491318105135186, -8.120547930088872],
              [-38.491305322542914, -8.120607521938254],
              [-38.491318871669485, -8.120660091443986],
              [-38.49135866198403, -8.120705457586952],
              [-38.491440724391197, -8.120699409393835],
              [-38.491502521084165, -8.120712692581423],
              [-38.491558672502492, -8.120739988758311],
              [-38.491599565385307, -8.120774773380374],
              [-38.491535853438613, -8.120768723969114],
              [-38.491503974707918, -8.120783789618351],
              [-38.491435671191496, -8.120819975508422],
              [-38.491388607485845, -8.120856187927314],
              [-38.491343255624159, -8.120902533095883],
              [-38.491295907186739, -8.120948604407529],
              [-38.491258073378361, -8.121007441327652],
              [-38.491222506039016, -8.12106845192285],
              [-38.491194925066729, -8.12113046746906],
              [-38.491183129955331, -8.121198743930314],
              [-38.491169601354436, -8.121274073473993],
              [-38.491145813278926, -8.121351470584363],
              [-38.491131777064936, -8.121397402614603],
              [-38.491125547744275, -8.121442711241588],
              [-38.491099213571594, -8.121523723241138],
              [-38.491058999417618, -8.12159865760241],
              [-38.491026501494716, -8.121672877993076],
              [-38.490988008612142, -8.121750437603511],
              [-38.490949251497085, -8.121821574792058],
              [-38.49092456123735, -8.121894538610283],
              [-38.490916014240376, -8.121978286398722],
              [-38.490922544306017, -8.122054545537051],
              [-38.490948782927738, -8.122121512779875],
              [-38.490979045232642, -8.122164786650606],
              [-38.491007955923564, -8.122200189511693],
              [-38.491063072140953, -8.122256790893623],
              [-38.490986841402901, -8.122244755900473],
              [-38.490930212395867, -8.122236182586606],
              [-38.490882945907593, -8.12221703810304],
              [-38.490840209885135, -8.122204230916747],
              [-38.490772439125131, -8.122177553257886],
              [-38.490704652188086, -8.12216371974036],
              [-38.490626045191604, -8.122164616331625],
              [-38.490553389139095, -8.122197088075922],
              [-38.49050375481599, -8.122256181492006],
              [-38.490490810112831, -8.122300215368119],
              [-38.490474098382286, -8.122380334840747],
              [-38.490461753013548, -8.122452952270276],
              [-38.490468231686833, -8.122497914982967],
              [-38.490498808457296, -8.12257972179609],
              [-38.490524034095543, -8.122658175187164],
              [-38.49053495897013, -8.12270431934037],
              [-38.490543615423007, -8.12274982749009],
              [-38.490547994792138, -8.122804013668654],
              [-38.490548872943386, -8.122899441536052],
              [-38.490533149956946, -8.12298725065841],
              [-38.490516921580159, -8.123043943688954],
              [-38.490480498612236, -8.122991435975926],
              [-38.490452587630408, -8.122955039358921],
              [-38.490421497117815, -8.122920719152605],
              [-38.490380794459519, -8.122878969849852],
              [-38.490352528974526, -8.122835788889542],
              [-38.490327271874349, -8.122782300177736],
              [-38.490288396368449, -8.122731236608576],
              [-38.490270904673295, -8.12271004895206],
              [-38.490240996814101, -8.122673649821142],
              [-38.490209898809539, -8.122645299417167],
              [-38.490197115120914, -8.12263361510618],
              [-38.490189317786047, -8.122626640540929],
              [-38.490153594457666, -8.122595208973692],
              [-38.490100197119268, -8.122543222675407],
              [-38.490037517404701, -8.122510219639576],
              [-38.489960737710753, -8.122501620937816],
              [-38.489882050163089, -8.122494376608822],
              [-38.48983603733766, -8.122488801362262],
              [-38.489788838580871, -8.12248792812275],
              [-38.489738732938285, -8.122488860271055],
              [-38.489654657370096, -8.122507478386661],
              [-38.489571942527775, -8.122526731354403],
              [-38.489485434699269, -8.122530693175568],
              [-38.489396670107062, -8.122524792889688],
              [-38.489316699006167, -8.122527677453753],
              [-38.489307893173404, -8.122528661374009],
              [-38.489242439481259, -8.122535996284427],
              [-38.489209748529568, -8.122547171288055],
              [-38.489168703185605, -8.12256123026258],
              [-38.489094062509551, -8.122583839986483],
              [-38.489040209947042, -8.122604847664121],
              [-38.489015036328297, -8.122629057162717],
              [-38.488976334717627, -8.122655963228503],
              [-38.488945651989248, -8.122658185994389],
              [-38.488880847269201, -8.122654757884876],
              [-38.488871307960366, -8.122661529795197],
              [-38.488832607138647, -8.122687802684291],
              [-38.488815877408918, -8.122710123286177],
              [-38.488788681452974, -8.122754410548445],
              [-38.488765395647199, -8.12279309470372],
              [-38.488747189689747, -8.12283431789225],
              [-38.488729892561643, -8.122874637702665],
              [-38.488692016403334, -8.122823032537184],
              [-38.488644693768478, -8.122776571255985],
              [-38.488585283662168, -8.122742124880155],
              [-38.488525952886619, -8.12271672378453],
              [-38.488465995438958, -8.122684447549615],
              [-38.488394150376031, -8.122649714160676],
              [-38.488307866211102, -8.122620389717225],
              [-38.488259767324969, -8.122613545343569],
              [-38.488213851818301, -8.122602814279267],
              [-38.488167033667054, -8.122588102191855],
              [-38.488119762362885, -8.122572846817881],
              [-38.48806877022902, -8.122557044046737],
              [-38.488018954541467, -8.122544046757739],
              [-38.487968863227437, -8.122533672220479],
              [-38.487920951055195, -8.12252275770811],
              [-38.48787402095072, -8.122524779044621],
              [-38.487783431169611, -8.122526654972814],
              [-38.487738132839461, -8.122530306481284],
              [-38.487646265185084, -8.122537788786451],
              [-38.487599971378565, -8.122539087277321],
              [-38.487517642933696, -8.12253988812555],
              [-38.48743034285696, -8.122524401358211],
              [-38.487348766729369, -8.122504580093468],
              [-38.487268172082238, -8.12249814692623],
              [-38.487183223751181, -8.122489175604558],
              [-38.487095640719616, -8.122482100433942],
              [-38.487008411007558, -8.122482714098672],
              [-38.48694021345549, -8.122506416965452],
              [-38.486899500232418, -8.122544807676841],
              [-38.486875574060498, -8.122587289884938],
              [-38.486868441835306, -8.122628436489716],
              [-38.486862580486672, -8.122669494244947],
              [-38.486821422789902, -8.122629010361845],
              [-38.486772427164176, -8.122613571756046],
              [-38.486716323083868, -8.122620556240367],
              [-38.486654507116995, -8.122622377755464],
              [-38.486604582552623, -8.122623671552184],
              [-38.486541140907363, -8.122619068917039],
              [-38.486475159002197, -8.122613468096105],
              [-38.486405277949963, -8.122604786980423],
              [-38.486343829546172, -8.122603081289888],
              [-38.486288812619435, -8.12261169522051],
              [-38.486205116316732, -8.12261746892738],
              [-38.486122317003584, -8.122631655772011],
              [-38.486040518213152, -8.122644215729711],
              [-38.485966169363657, -8.122651357970078],
              [-38.485885481541246, -8.122646823899663],
              [-38.485811133265763, -8.122653513853049],
              [-38.485728437137141, -8.122657931946945],
              [-38.485653441619064, -8.122673937593463],
              [-38.485588582379648, -8.122713383067216],
              [-38.485518730267373, -8.122753274476665],
              [-38.485456141029736, -8.12279208963461],
              [-38.485388561913751, -8.122828998983142],
              [-38.485331776558979, -8.122871982244648],
              [-38.485288694292763, -8.122917334578036],
              [-38.485250789183219, -8.122960070351796],
              [-38.485203078953795, -8.123004421850315],
              [-38.485169611245354, -8.123055213442646],
              [-38.485149660486364, -8.123111991955252],
              [-38.485142322104032, -8.123172314046245],
              [-38.485148889803099, -8.123218181437732],
              [-38.48517104993627, -8.123279626273202],
              [-38.485198646301583, -8.123348856843425],
              [-38.485199023281417, -8.123409188696025],
              [-38.485184876649193, -8.123469954432588],
              [-38.485168572647055, -8.123514164752434],
              [-38.485147376271243, -8.123479947138966],
              [-38.485119558374848, -8.123442103072572],
              [-38.48507857057065, -8.123410845322748],
              [-38.485027949044827, -8.123389163271654],
              [-38.484963153734313, -8.123378317494652],
              [-38.484892714725532, -8.12338003736801],
              [-38.484911819789183, -8.123345870759682],
              [-38.484928051374446, -8.123287278517784],
              [-38.484919403970729, -8.123235167315178],
              [-38.484886247004965, -8.123184562767792],
              [-38.484843003284105, -8.123142900179944],
              [-38.484800933916766, -8.123105671214658],
              [-38.484757952716443, -8.123071697356869],
              [-38.484736735982729, -8.123053489673479],
              [-38.48469465498453, -8.123025396318537],
              [-38.48465673990065, -8.123004725285648],
              [-38.484607041828653, -8.122970742906626],
              [-38.484544343167812, -8.122952934932059],
              [-38.484481636089285, -8.122941729921111],
              [-38.484422708876593, -8.122955946653565],
              [-38.484363306463145, -8.122986896363363],
              [-38.484303806692324, -8.123023001694587],
              [-38.48424648836567, -8.12305675803381],
              [-38.484173141696608, -8.123060916299766],
              [-38.484107706583949, -8.123053597201086],
              [-38.4840415597602, -8.123034970711712],
              [-38.483972328765866, -8.123014802619934],
              [-38.483903264414181, -8.123006303013026],
              [-38.483825559579003, -8.12301145065935],
              [-38.483751738042741, -8.12303207047837],
              [-38.483702311248003, -8.123069997533285],
              [-38.483682186030023, -8.123121077275298],
              [-38.48368520548469, -8.123173271805337],
              [-38.483704092738314, -8.123238601982372],
              [-38.483698108044102, -8.123304895571085],
              [-38.483686648276809, -8.123322790490198],
              [-38.483630089416977, -8.123330407125055],
              [-38.483570004208936, -8.12332734596227],
              [-38.483507080076976, -8.123343909230938],
              [-38.483436412829882, -8.123382171095063],
              [-38.483370008471645, -8.123422971009203],
              [-38.48330486723173, -8.123469742335105],
              [-38.483245161934498, -8.123524480312392],
              [-38.483196536566744, -8.123574438406672],
              [-38.483154359023899, -8.123621872033972],
              [-38.483113638981834, -8.123665237176324],
              [-38.483071648743667, -8.123708148441164],
              [-38.483035286521954, -8.12375079549628],
              [-38.483001471843195, -8.123788742290623],
              [-38.482946684768869, -8.123830370955318],
              [-38.482882560976165, -8.123862218951002],
              [-38.48282018297315, -8.123877516474968],
              [-38.482772828635262, -8.123856290559448],
              [-38.482742901722432, -8.123835267697444],
              [-38.482702995895842, -8.123809800008555],
              [-38.482651101848418, -8.123789563277882],
              [-38.482607272116908, -8.123780371914959],
              [-38.482542200819744, -8.123772419859389],
              [-38.482508298222498, -8.123808105202963],
              [-38.482496722711929, -8.123845447073435],
              [-38.482490956416605, -8.12388279633203],
              [-38.482460606426308, -8.12390862694321],
              [-38.482400065337337, -8.123907102703672],
              [-38.482337086580969, -8.123895354300847],
              [-38.48228482077559, -8.12388190093561],
              [-38.482195345240889, -8.123864058525877],
              [-38.482140278822115, -8.123840290074044],
              [-38.482095653744189, -8.123814273610693],
              [-38.482046859675684, -8.123783276987211],
              [-38.481966004447266, -8.123767616345175],
              [-38.481881329853394, -8.123757468383179],
              [-38.481835131454602, -8.12375505780642],
              [-38.481770383700052, -8.12377795010056],
              [-38.481709152027946, -8.123819479930946],
              [-38.481655908596494, -8.12386056766888],
              [-38.481610464210689, -8.123907182894584],
              [-38.481582603356991, -8.123973810363058],
              [-38.481539336710298, -8.124020971064747],
              [-38.481478744076234, -8.124059607213436],
              [-38.481416805219816, -8.124086302001182],
              [-38.481356327601176, -8.124106033854467],
              [-38.481286927087048, -8.124147191394785],
              [-38.481229209690781, -8.124137982177663],
              [-38.481172709696295, -8.124099649032139],
              [-38.481145570047062, -8.124028700229221],
              [-38.481120334581412, -8.123959291533492],
              [-38.481078296634699, -8.123897821164032],
              [-38.481023896225452, -8.123850264596696],
              [-38.480960126638124, -8.123818253776854],
              [-38.480884904132701, -8.123798710681063],
              [-38.480800686789358, -8.123785939977358],
              [-38.480714383858491, -8.123771538462845],
              [-38.480630713351289, -8.123757049838817],
              [-38.480552209937649, -8.123747813997268],
              [-38.480452898570114, -8.123755556504898],
              [-38.48038172558023, -8.123763334923359],
              [-38.480306094438284, -8.123779248294156],
              [-38.480232909748281, -8.123798511491138],
              [-38.480181398821983, -8.123833360068357],
              [-38.480145746623947, -8.123888309192695],
              [-38.480163188930341, -8.123947486932376],
              [-38.480189002239584, -8.123990936758826],
              [-38.4801538115534, -8.124039916655711],
              [-38.480108737159362, -8.12408101461638],
              [-38.480077708212988, -8.124140582679564],
              [-38.48005321276699, -8.124201606317188],
              [-38.480006677773787, -8.124249124473073],
              [-38.479944548650572, -8.124282512215766],
              [-38.479858387602512, -8.124298864295115],
              [-38.479807912889925, -8.124304317321291],
              [-38.479737924803992, -8.124308207709454],
              [-38.479655411166782, -8.124311629807728],
              [-38.479582411155612, -8.124328360466317],
              [-38.479523809398714, -8.124371521443132],
              [-38.479472277014551, -8.124422922564804],
              [-38.479444423002299, -8.124483941861278],
              [-38.479398873080392, -8.124541772700683],
              [-38.479350421563474, -8.124597248075188],
              [-38.479300706143157, -8.124647385172215],
              [-38.479252702149871, -8.124707745500402],
              [-38.479212135850865, -8.12477227612335],
              [-38.479166785358736, -8.12481617761796],
              [-38.479118162850654, -8.124863421632243],
              [-38.479084497772952, -8.124925790233537],
              [-38.479076771921626, -8.125004292456763],
              [-38.479103994558415, -8.125081030393945],
              [-38.479148585854517, -8.125133187692031],
              [-38.479223519401266, -8.125165755799555],
              [-38.479283080191465, -8.125223721158653],
              [-38.479252519216928, -8.125272164176682],
              [-38.479243262318867, -8.125341347913574],
              [-38.479257800271391, -8.12540025061049],
              [-38.479263424760248, -8.125473161917951],
              [-38.479254518924655, -8.125551662633487],
              [-38.479227920870187, -8.125624080432589],
              [-38.479199148421785, -8.125693329634259],
              [-38.479162579124626, -8.125755332710876],
              [-38.479113488769187, -8.125813339873744],
              [-38.479055969674832, -8.125861386544033],
              [-38.478977234087573, -8.125891134779261],
              [-38.478883722209524, -8.125906029984137],
              [-38.478788302300501, -8.125922369950107],
              [-38.478697163606938, -8.125927047105669],
              [-38.478604204036571, -8.125935973138354],
              [-38.478510796338213, -8.125940466440044],
              [-38.478418125072551, -8.125937000912005],
              [-38.478324256401692, -8.12594701113526],
              [-38.478246602625148, -8.125982459070991],
              [-38.478182004094485, -8.126029953818447],
              [-38.478125110551836, -8.126085508643669],
              [-38.478076454458389, -8.126158531212573],
              [-38.478033968918794, -8.126232918468313],
              [-38.477997841787186, -8.1263037862609],
              [-38.477961166518071, -8.126377366897552],
              [-38.477920500415131, -8.126448590662614],
              [-38.477912129157616, -8.126464408969508],
              [-38.477884467402852, -8.126516835462812],
              [-38.477870076821802, -8.126554716262309],
              [-38.477855777475142, -8.126592235371261],
              [-38.47783632580353, -8.126683295284305],
              [-38.477836487957973, -8.126768501013782],
              [-38.477847634171482, -8.12685299723017],
              [-38.477856240241749, -8.126936404764621],
              [-38.477836534131676, -8.127013625235342],
              [-38.477829703975942, -8.127101173731209],
              [-38.477825871321173, -8.127187097943871],
              [-38.477827568716627, -8.127278365907385],
              [-38.477830818669879, -8.127362309274542],
              [-38.477845422210692, -8.127440568759804],
              [-38.477862297456397, -8.127516931674959],
              [-38.477880009866261, -8.127577647516993],
              [-38.477905179787662, -8.127627247370738],
              [-38.47787469928398, -8.127683378757272],
              [-38.477841494273306, -8.127740592063436],
              [-38.477810454746646, -8.127807848287135],
              [-38.477795293851457, -8.127879828385943],
              [-38.477776685917391, -8.127950085474732],
              [-38.477766164917902, -8.12801384042333],
              [-38.477759282898617, -8.128071268424378],
              [-38.477760299496701, -8.128127440244295],
              [-38.47776327455032, -8.128143182662972],
              [-38.477769945202866, -8.128179010107791],
              [-38.477761571556975, -8.12826682796849],
              [-38.477764377229462, -8.128413544249995],
              [-38.477788483557887, -8.128513524349293],
              [-38.477790296852341, -8.128515154808685],
              [-38.477828558982495, -8.128548309268377],
              [-38.477861027744382, -8.128569154853192],
              [-38.477882703766262, -8.128583112248245],
              [-38.477941125860127, -8.128609327799841],
              [-38.478013533094121, -8.128630495999355],
              [-38.478074152274061, -8.128642332530111],
              [-38.478137209974804, -8.128663669611711],
              [-38.478210985093085, -8.128679955139903],
              [-38.478294747260406, -8.12869444443357],
              [-38.478370424707563, -8.128713717280872],
              [-38.478435569331282, -8.128735690156882],
              [-38.478507335387796, -8.128761380034979],
              [-38.478579587190943, -8.128762377203618],
              [-38.478658914155574, -8.128767182402585],
              [-38.478733539995446, -8.128757328380612],
              [-38.478814145799348, -8.12875589401966],
              [-38.478889603974139, -8.128734101407519],
              [-38.478957801470557, -8.128711575863885],
              [-38.479034611029043, -8.128697654255735],
              [-38.479111975821283, -8.128675502236518],
              [-38.479175731865062, -8.128648086572186],
              [-38.479247016331769, -8.128624931769602],
              [-38.47931141521434, -8.128591727995147],
              [-38.479380508355902, -8.128578520052933],
              [-38.479458396742274, -8.12857264994782],
              [-38.479516881208923, -8.128550564131901],
              [-38.479591333034513, -8.128534830362971],
              [-38.479661798666555, -8.128513121664771],
              [-38.479736702117115, -8.128499107029315],
              [-38.479813508471182, -8.128487627462651],
              [-38.479889042876472, -8.128477050771805],
              [-38.47993938398541, -8.128434783738765],
              [-38.479989994831335, -8.128394506981996],
              [-38.480042232539269, -8.128359659403042],
              [-38.480104996529356, -8.128327177019422],
              [-38.480170829223681, -8.128308266310054],
              [-38.48023539939868, -8.128282931901897],
              [-38.480298236555214, -8.128264107794159],
              [-38.480356087061182, -8.128240845168699],
              [-38.480420719588707, -8.128237581036434],
              [-38.480482634369437, -8.128230152641347],
              [-38.480499804323216, -8.128218958558257],
              [-38.480532327083395, -8.128197924844818],
              [-38.480575838124814, -8.128172925286396],
              [-38.480584104357455, -8.128168141901458],
              [-38.480627882932957, -8.128146851200652],
              [-38.480639599586098, -8.128141167703564],
              [-38.480697720855169, -8.128119081245618],
              [-38.480746337907682, -8.128076269191871],
              [-38.480781616305379, -8.128029912483591],
              [-38.48081980607698, -8.127978313286448],
              [-38.480852457362467, -8.12792788288775],
              [-38.480896178265397, -8.127880813330529],
              [-38.480928837206264, -8.127824413118109],
              [-38.48094181511798, -8.127755505441327],
              [-38.480969127687104, -8.127692043163437],
              [-38.481006485128844, -8.127652382514665],
              [-38.48106135753973, -8.127615548215564],
              [-38.481118308001307, -8.127586224055902],
              [-38.481176869071803, -8.127574992295134],
              [-38.481271441598395, -8.127582620425143],
              [-38.48132104948553, -8.127616331751373],
              [-38.481368733906507, -8.127663517932259],
              [-38.481372359938341, -8.127667231078656],
              [-38.481417233060597, -8.127712423730587],
              [-38.481443797390021, -8.127736789127491],
              [-38.481457578035382, -8.127749560397415],
              [-38.481509431632027, -8.127801636274688],
              [-38.481562546847293, -8.127860859443944],
              [-38.481600255575017, -8.127901068097552],
              [-38.481653561412173, -8.127953236261355],
              [-38.481689985795633, -8.128004026127501],
              [-38.481762234371175, -8.128078198200317],
              [-38.481806204177389, -8.128120042947284],
              [-38.481843201997421, -8.128148401493066],
              [-38.482184043259849, -8.128148835626885],
              [-38.482200664140329, -8.12814107793716],
              [-38.482254547213486, -8.128097096525256],
              [-38.482311782386063, -8.128058094220309],
              [-38.482377891786498, -8.1280356558772],
              [-38.482447538310268, -8.128015483318727],
              [-38.482497045837889, -8.127985968588137],
              [-38.482553370028235, -8.127949497737278],
              [-38.482604792400608, -8.127913201545239],
              [-38.482663016367034, -8.127881707942707],
              [-38.482726143174894, -8.127849225597764],
              [-38.482789438654258, -8.127826783604863],
              [-38.482867326423467, -8.127821274640885],
              [-38.482941195300391, -8.127835298138335],
              [-38.483019614258666, -8.127840282227906],
              [-38.483084723500639, -8.127819108818681],
              [-38.483126358869207, -8.127770136866303],
              [-38.483153033516771, -8.127708392194036],
              [-38.483156293925354, -8.127643180596465],
              [-38.483180432095658, -8.127577452818972],
              [-38.483180527127793, -8.12750319201902],
              [-38.483190144194516, -8.127435817654744],
              [-38.483225614799366, -8.127380868036091],
              [-38.483278861464015, -8.127337518827726],
              [-38.48333110351664, -8.127298962280463],
              [-38.483369121197953, -8.127239674196536],
              [-38.483397297604256, -8.12713921804105],
              [-38.483418081037854, -8.12707050107324],
              [-38.483434470149241, -8.127030994453321],
              [-38.483458541211995, -8.127088642818943],
              [-38.483487786273884, -8.127146207302291],
              [-38.483522207419284, -8.127202059772712],
              [-38.483569045646277, -8.127272309843343],
              [-38.483603559086298, -8.12732698655171],
              [-38.483637993098952, -8.12737279888044],
              [-38.483690594962866, -8.127407780054835],
              [-38.483745282576919, -8.127444301551098],
              [-38.483801959854318, -8.12748652402931],
              [-38.483861548252982, -8.12752368489376],
              [-38.483922236188548, -8.127552796935053],
              [-38.483990645120322, -8.127577577077403],
              [-38.484067616243017, -8.127579031505382],
              [-38.484090043335883, -8.127573632847003],
              [-38.484134443555106, -8.127562925402358],
              [-38.484184938177307, -8.127542185531857],
              [-38.484202011808435, -8.127535242396586],
              [-38.48426123315285, -8.127504473433081],
              [-38.484327796683687, -8.127481673528713],
              [-38.484395101609586, -8.127446934914165],
              [-38.484444079906439, -8.127405208267554],
              [-38.48450023178119, -8.127361319863269],
              [-38.484551935806984, -8.127317516265288],
              [-38.484595475039946, -8.127270174692697],
              [-38.484694953160854, -8.127203547303759],
              [-38.484784686343282, -8.127162414955905],
              [-38.484846853874821, -8.127098996526859],
              [-38.484843328015245, -8.127159052066933],
              [-38.484825998451235, -8.127224336327723],
              [-38.484825921724173, -8.127284486687788],
              [-38.484831444458479, -8.127366804834956],
              [-38.484842618116268, -8.127430949324847],
              [-38.48487358999229, -8.127486978227674],
              [-38.484927341310112, -8.127546020888175],
              [-38.484975663290896, -8.127591850701867],
              [-38.485028803469355, -8.127631897695084],
              [-38.485085402948698, -8.127664079753671],
              [-38.485148079986061, -8.12769943531222],
              [-38.485210582528261, -8.127729273080011],
              [-38.485283442190827, -8.127751797349589],
              [-38.485344764858226, -8.127781543152633],
              [-38.485402364468776, -8.127812460109769],
              [-38.485461405690067, -8.127851790902575],
              [-38.48551965108102, -8.1278745679972],
              [-38.485576344287026, -8.127904488814512],
              [-38.485621767738117, -8.127945611396559],
              [-38.485662930077751, -8.127982748704655],
              [-38.485688202721448, -8.128023755342742],
              [-38.485713570171804, -8.128061596285528],
              [-38.485755547734051, -8.128100091393627],
              [-38.48578890595244, -8.128135590689137],
              [-38.485805040328437, -8.128153430099051],
              [-38.485825978607693, -8.128176702691709],
              [-38.485837442600477, -8.128226827496995],
              [-38.485847072537794, -8.128291603178846],
              [-38.485849232247425, -8.128306349556816],
              [-38.485871290088369, -8.128377110771112],
              [-38.485877798932478, -8.128398013371477],
              [-38.485902235805824, -8.128453853040927],
              [-38.485943840903246, -8.128499764711256],
              [-38.486031136308547, -8.128519864905712],
              [-38.485955179603927, -8.128577024903038],
              [-38.485922089991455, -8.128615696454473],
              [-38.485899067797661, -8.128660712358119],
              [-38.485874579456201, -8.128716580625268],
              [-38.485842918536342, -8.128773796598747],
              [-38.485804017877115, -8.128813817568375],
              [-38.485763104254268, -8.128866408791296],
              [-38.485709411491605, -8.128903697489557],
              [-38.485650004113637, -8.128937903589984],
              [-38.485594866613418, -8.128969220624363],
              [-38.485552597847814, -8.129016744806963],
              [-38.485508141020645, -8.129071773716802],
              [-38.485475208544635, -8.129129530757831],
              [-38.485442365215363, -8.129188554233696],
              [-38.485413338146948, -8.129244507173974],
              [-38.485396181111504, -8.129316846912459],
              [-38.485379840540752, -8.129389549490185],
              [-38.485374315927132, -8.129451049731154],
              [-38.485371210290275, -8.129537517733654],
              [-38.48533729853412, -8.129580168066656],
              [-38.485309169553311, -8.129643629632946],
              [-38.485318534031691, -8.129702977875009],
              [-38.485349335542956, -8.129750413612285],
              [-38.485371847901327, -8.129820632712596],
              [-38.485397453147733, -8.129885609521381],
              [-38.485420966646977, -8.129953749494913],
              [-38.485450388186827, -8.130015655769277],
              [-38.485476733568412, -8.130069688841381],
              [-38.485511336533683, -8.130125722301278],
              [-38.485537593695213, -8.130177765318603],
              [-38.485574661887746, -8.13022249541676],
              [-38.48561919147842, -8.130252943565456],
              [-38.485670809933389, -8.130276526339534],
              [-38.48571926606003, -8.130288617726944],
              [-38.485777559046653, -8.130274309620351],
              [-38.485842388082048, -8.130259557511739],
              [-38.485904917443058, -8.130268681764301],
              [-38.485981610462332, -8.130275110359873],
              [-38.486048160101909, -8.130263526206983],
              [-38.486120672499638, -8.130274019823533],
              [-38.486181012097802, -8.130292005544854],
              [-38.48624988251958, -8.130311268362743],
              [-38.486311504541703, -8.130320120051811],
              [-38.486375941949511, -8.130327799414696],
              [-38.486444839197773, -8.130325986959461],
              [-38.486507011028571, -8.130330768981642],
              [-38.486567645974105, -8.130330664654942],
              [-38.486627993980747, -8.130270680847675],
              [-38.486640303553472, -8.130227008158228],
              [-38.486673660723788, -8.130192135869791],
              [-38.486723183431209, -8.130150681002997],
              [-38.486777772143341, -8.130122709846875],
              [-38.486839074989803, -8.130097008452909],
              [-38.486897201321973, -8.130070941233125],
              [-38.486958410509864, -8.130047500997739],
              [-38.487022769419923, -8.13004550181109],
              [-38.487090487412125, -8.130043054592626],
              [-38.487158224936636, -8.130025230575489],
              [-38.487209099253377, -8.129991556207594],
              [-38.487273037845434, -8.129963144514257],
              [-38.487329164886511, -8.129938883745202],
              [-38.487386845584517, -8.129906303357675],
              [-38.487435637430451, -8.129868465549039],
              [-38.487479166993516, -8.129828721612045],
              [-38.487515180054416, -8.129775219516016],
              [-38.487550452821345, -8.129732842059612],
              [-38.48759334627681, -8.12969364001794],
              [-38.487626364116679, -8.129639953231749],
              [-38.487586705522851, -8.129562024217966],
              [-38.487542796770576, -8.129542973994019],
              [-38.487499270851941, -8.129508366531223],
              [-38.487465278813183, -8.129471419333914],
              [-38.487441291038564, -8.12941910793208],
              [-38.487478506237906, -8.129348150146145],
              [-38.487516031464558, -8.129318981497944],
              [-38.487594317033142, -8.12928633659067],
              [-38.487649420466582, -8.129281792982013],
              [-38.487698646896646, -8.129259061140417],
              [-38.487742902413778, -8.129219408534935],
              [-38.487781526836621, -8.129182371934217],
              [-38.487824433862791, -8.129132406109347],
              [-38.487846560107997, -8.129077981965521],
              [-38.487864251453338, -8.129013421627343],
              [-38.487860137972746, -8.128964572448053],
              [-38.487858127466382, -8.128903695821212],
              [-38.487880978363151, -8.128850448460602],
              [-38.487918514526577, -8.128812596406142],
              [-38.487950078316459, -8.128760174079094],
              [-38.487964765724783, -8.128702303388232],
              [-38.487991528772795, -8.128641915248938],
              [-38.488027267278184, -8.128590040881543],
              [-38.488042224455448, -8.12853416046673],
              [-38.488041757011977, -8.128473285780656],
              [-38.488045918500276, -8.128412688277452],
              [-38.488057245779856, -8.128356079676417],
              [-38.488040866783564, -8.128244712823744],
              [-38.488032520345577, -8.128169627276607],
              [-38.488079118484606, -8.128215183220455],
              [-38.488131266221444, -8.128250796440527],
              [-38.488195112534015, -8.12829492685222],
              [-38.488245522362959, -8.128341030286981],
              [-38.48828593740361, -8.128395080902973],
              [-38.488328538044065, -8.128443526250301],
              [-38.48838112936982, -8.128487280666517],
              [-38.488440902893487, -8.128522089377457],
              [-38.488505603451848, -8.128536823964573],
              [-38.488578101886731, -8.12855817133441],
              [-38.488591913313684, -8.12861852009509],
              [-38.488600715086108, -8.128692520786293],
              [-38.488614957116809, -8.128771231815524],
              [-38.488646182348688, -8.12884272805228],
              [-38.488682682115503, -8.128906452059393],
              [-38.488720449012014, -8.128973072116572],
              [-38.488754860512579, -8.12903724575189],
              [-38.488794273779803, -8.129094008620269],
              [-38.488838868178924, -8.129145079531186],
              [-38.488884183370288, -8.12920040258126],
              [-38.488926047927706, -8.129256806712279],
              [-38.48897026931995, -8.129315746453761],
              [-38.489000996735868, -8.129350337681915],
              [-38.489067713174073, -8.129421968944042],
              [-38.489130261408484, -8.129487896492016],
              [-38.489185205662849, -8.129537442691113],
              [-38.489232635668444, -8.129571421723453],
              [-38.489288884942255, -8.129593924441286],
              [-38.48935821156828, -8.129611378270775],
              [-38.489414094571146, -8.129636413164253],
              [-38.489469508174373, -8.129673748915074],
              [-38.489522208167138, -8.129703573747005],
              [-38.489584350352146, -8.129731782292049],
              [-38.489636052687068, -8.129760972694543],
              [-38.489690660141612, -8.129789804929608],
              [-38.489741633584977, -8.129821165249624],
              [-38.489798339635378, -8.129841407176944],
              [-38.489864138053861, -8.129849539953614],
              [-38.489962125485668, -8.129885029565724],
              [-38.489989336520139, -8.129900983228904],
              [-38.490010016994653, -8.129913039268496],
              [-38.490058278675711, -8.129935441414043],
              [-38.490068891968875, -8.129940881837543],
              [-38.490107807493899, -8.129960739603254],
              [-38.490153162931328, -8.129984404419686],
              [-38.49018002768873, -8.129987061210951],
              [-38.490203897362512, -8.129989442889324],
              [-38.490229859098235, -8.129988390018079],
              [-38.490251009882812, -8.129987421565398],
              [-38.490299869995212, -8.129967221599236],
              [-38.490331864031802, -8.129933342251542],
              [-38.490371044916181, -8.129886808646024],
              [-38.490375591090135, -8.129880754068367],
              [-38.490420780853363, -8.12981993659171],
              [-38.490428962981888, -8.12980981623558],
              [-38.490434417657916, -8.129803129632526],
              [-38.49047322881497, -8.129761841761148],
              [-38.490510676581039, -8.129721999407192],
              [-38.49054285257769, -8.129687758460763],
              [-38.490574035572095, -8.129649174576755],
              [-38.490617226731771, -8.129589620896592],
              [-38.490652015711056, -8.129498760333666],
              [-38.490670501664113, -8.129451567605916],
              [-38.490687170265247, -8.129405910282699],
              [-38.490711919821472, -8.129358363590411],
              [-38.490735934196003, -8.129317961674444],
              [-38.490765488979598, -8.129274853138641],
              [-38.49079294805383, -8.129238073607972],
              [-38.49083970655218, -8.129156815912294],
              [-38.490892172042017, -8.129084700995993],
              [-38.490947360507647, -8.129012679932254],
              [-38.491003622804016, -8.128952871209064],
              [-38.49106423726856, -8.128896866903602],
              [-38.491128023773946, -8.128844755988837],
              [-38.491176930894369, -8.128787108879433],
              [-38.491219487844923, -8.128726468905448],
              [-38.491258028898457, -8.128683281118102],
              [-38.491283809288838, -8.128609956881395],
              [-38.491307682241555, -8.128537625227109],
              [-38.491331722255097, -8.12847678117077],
              [-38.491352207184967, -8.128427872316413],
              [-38.491401680714858, -8.128352859117429],
              [-38.491398681513637, -8.128427930443362],
              [-38.491401332619205, -8.128485099359638],
              [-38.491404347147558, -8.128541997374633],
              [-38.491424260884585, -8.128586253258625],
              [-38.491457059554335, -8.128634052879589],
              [-38.49148195313397, -8.128688264697857],
              [-38.491498046334499, -8.12873902833803],
              [-38.491493985466356, -8.12879220897698],
              [-38.491471316122066, -8.128845818604546],
              [-38.491455455795496, -8.128898441774927],
              [-38.491435584255314, -8.128964808616121],
              [-38.49142696412499, -8.129034445796266],
              [-38.491442126955619, -8.129103208202235],
              [-38.491479439234986, -8.129170822433469],
              [-38.491536822824102, -8.129229687926482],
              [-38.491591674540437, -8.129280861835245],
              [-38.491649526134616, -8.129328511863626],
              [-38.491719023566482, -8.129354558438262],
              [-38.491794190896229, -8.129347144884662],
              [-38.491869814124975, -8.129338103752508],
              [-38.491938540509231, -8.129327697210092],
              [-38.492011089374834, -8.129309245211841],
              [-38.492072103569555, -8.129296205906122],
              [-38.492124143894927, -8.129273296124877],
              [-38.492170377868909, -8.129248027335448],
              [-38.492218961856679, -8.12923072124291],
              [-38.492271982669394, -8.129222104067553],
              [-38.492314543848501, -8.129230207429043],
              [-38.492371828933841, -8.129223133247093],
              [-38.49243332106392, -8.129190918677271],
              [-38.49249508685994, -8.129157619016189],
              [-38.492563390952945, -8.129122066206548],
              [-38.492644094656121, -8.129115111660598],
              [-38.49272905445018, -8.129116212628457],
              [-38.492798316997394, -8.129112500047562],
              [-38.492858796405564, -8.129091590636603],
              [-38.49291827722061, -8.129070770422864],
              [-38.492974946761933, -8.129047866300528],
              [-38.493030109315768, -8.128996196580724],
              [-38.493072361122465, -8.128961515718634],
              [-38.493155042496049, -8.128897578823796],
              [-38.493206484559863, -8.128844999920551],
              [-38.493260652329489, -8.128790344012245],
              [-38.493318451349182, -8.128735330812576],
              [-38.493385498138721, -8.128688922057767],
              [-38.493458338891436, -8.128654731518154],
              [-38.493534089272018, -8.128616564703755],
              [-38.493609578671283, -8.128569352356349],
              [-38.493673547039542, -8.128516698533359],
              [-38.49371900972141, -8.128454886015412],
              [-38.493746418758249, -8.128385362602673],
              [-38.493751517878117, -8.128372615228141],
              [-38.493771180595552, -8.128327685095147],
              [-38.493781830528263, -8.128303909494074],
              [-38.493803130729901, -8.128256086935423],
              [-38.493849056168102, -8.128187129272456],
              [-38.4938652428445, -8.128163541463465],
              [-38.493887704221819, -8.128130735365341],
              [-38.493894433341879, -8.128121065382702],
              [-38.493942787665453, -8.128069567953103],
              [-38.493947694579731, -8.128065322818088],
              [-38.49399376433643, -8.128026033550141],
              [-38.494058426772028, -8.127998887967024],
              [-38.494117931392964, -8.127958891781923],
              [-38.494180438326545, -8.12791338175313],
              [-38.494227883302436, -8.127863149481618],
              [-38.494278058051684, -8.127807583933645],
              [-38.494325075881562, -8.127736004459516],
              [-38.494346774061803, -8.127660232709829],
              [-38.494349918794271, -8.127613472965134],
              [-38.494364995620394, -8.127533803547511],
              [-38.494372540341821, -8.12745267753357],
              [-38.494351657861728, -8.127384450805748],
              [-38.494305446264079, -8.127319629758421],
              [-38.494250706949636, -8.127251089572816],
              [-38.494213483645254, -8.127184651558194],
              [-38.49419049117931, -8.127134241232479],
              [-38.494236565940298, -8.127163333603242],
              [-38.494280649545786, -8.127187810444113],
              [-38.494318195795735, -8.127213816835622],
              [-38.494369172379251, -8.127242734382945],
              [-38.494417020597986, -8.127232844150697],
              [-38.494455104495636, -8.127191916758385],
              [-38.49449455163267, -8.127149634277043],
              [-38.494543252312532, -8.127111343176875],
              [-38.494600676968716, -8.127065012687369],
              [-38.494659643342167, -8.127019769527724],
              [-38.494718686214398, -8.126985923399472],
              [-38.494772454353395, -8.12696012093369],
              [-38.494830403104793, -8.126930162862962],
              [-38.494891166053556, -8.126899936922392],
              [-38.494950129204852, -8.126857226367617],
              [-38.495007733623325, -8.126812252817535],
              [-38.495056720886524, -8.126762474607306],
              [-38.49510127194857, -8.126703464783946],
              [-38.495143079891015, -8.126660461546507],
              [-38.495185521517527, -8.126618815870106],
              [-38.495228967368234, -8.126572558384861],
              [-38.495273780746487, -8.126521508637362],
              [-38.495314793875224, -8.126460775809031],
              [-38.495338570905496, -8.126391880914932],
              [-38.495344648502609, -8.126322421348338],
              [-38.495320625351575, -8.12622506523174],
              [-38.495288842707431, -8.12616297575167],
              [-38.495352630428179, -8.126181778449082],
              [-38.495385037818863, -8.126179738257845],
              [-38.495427471572164, -8.126144333726383],
              [-38.495461839806715, -8.12609851721022],
              [-38.495520905524735, -8.12604621877912],
              [-38.495573059628768, -8.126004404195976],
              [-38.495554196315183, -8.126063626842031],
              [-38.495533544373984, -8.126101410152685],
              [-38.495515503399346, -8.126156292121554],
              [-38.495494741812855, -8.126209180771699],
              [-38.495472436795971, -8.126262338861853],
              [-38.49546282722347, -8.126325371961581],
              [-38.495440609010267, -8.126381695975363],
              [-38.495418180776021, -8.12646081362157],
              [-38.495404672268016, -8.126520495159228],
              [-38.495384168264472, -8.126585142875305],
              [-38.495356571775751, -8.126659731506104],
              [-38.495346208889558, -8.126744472136071],
              [-38.495329774927548, -8.126820793200132],
              [-38.495339123448474, -8.12689407088685],
              [-38.495355928891868, -8.126956322686326],
              [-38.49535039510878, -8.127026416088942],
              [-38.495313753685629, -8.127075305134102],
              [-38.495285344963136, -8.127146093765347],
              [-38.495284609762955, -8.127225961931666],
              [-38.495295172087538, -8.12727219598907],
              [-38.495320671109198, -8.127350739929788],
              [-38.495371875569326, -8.127415295710696],
              [-38.495434342647137, -8.127474166985259],
              [-38.495503528420336, -8.127531689806048],
              [-38.49554688418192, -8.127557612844733],
              [-38.495589067733839, -8.127577293239993],
              [-38.495637142757943, -8.127603855286459],
              [-38.495686780872028, -8.12761440926862],
              [-38.495742779669214, -8.127619544013726],
              [-38.495801432481706, -8.127607315264605],
              [-38.495851545341239, -8.127601136202243],
              [-38.495909742604923, -8.127590263651845],
              [-38.495957678673683, -8.127582725098724],
              [-38.496008616473802, -8.127570124949527],
              [-38.496089888507647, -8.127543904132331],
              [-38.496161001058454, -8.127512243594404],
              [-38.496200329984376, -8.127491850169386],
              [-38.496273357939913, -8.127452774921753],
              [-38.496329598166881, -8.127409879887802],
              [-38.496400731543076, -8.127361485716163],
              [-38.496456964639478, -8.127324289128286],
              [-38.496494375861282, -8.12731311939968],
              [-38.496560168999032, -8.127325321409419],
              [-38.496505575522249, -8.127357906991355],
              [-38.496438165984358, -8.127404496753243],
              [-38.496396895942432, -8.12745292791022],
              [-38.496393093048582, -8.127518139072571],
              [-38.496403750225113, -8.127561207407004],
              [-38.496435458839251, -8.127610090733098],
              [-38.496462166874032, -8.127665570859829],
              [-38.496486442167289, -8.127706575657703],
              [-38.496432182523272, -8.127689774862976],
              [-38.49639700275948, -8.127658434920532],
              [-38.496351931545924, -8.127625092782733],
              [-38.496269252993677, -8.127614588410763],
              [-38.496240156253442, -8.12765489394916],
              [-38.496210960048842, -8.127702164163312],
              [-38.496174125269903, -8.127760550504009],
              [-38.496133488518254, -8.127810610549229],
              [-38.496094949175287, -8.127852894319355],
              [-38.496045984805491, -8.127884310931325],
              [-38.495980977936291, -8.12789662229085],
              [-38.495908979280514, -8.127910914916608],
              [-38.495827912754997, -8.127917869679058],
              [-38.495741025758427, -8.127933771952334],
              [-38.495657839433122, -8.127966412408357],
              [-38.495579715886215, -8.128015340478518],
              [-38.495537836389701, -8.128042876378883],
              [-38.495495508347915, -8.128066160476124],
              [-38.495462894403119, -8.128088099841062],
              [-38.495453900586135, -8.12809414896395],
              [-38.495412930809294, -8.128120057843839],
              [-38.495386590282706, -8.128133773857888],
              [-38.495368151958274, -8.128143338885939],
              [-38.4953279131934, -8.128165359229541],
              [-38.495324279899705, -8.128167344663707],
              [-38.495284580324864, -8.128193707365639],
              [-38.49524415560105, -8.12821925509569],
              [-38.495204366941991, -8.128244260899256],
              [-38.495200551654371, -8.128246607914098],
              [-38.495157672506551, -8.128275047050634],
              [-38.495130599354404, -8.128293917898592],
              [-38.49511760790751, -8.128302946964805],
              [-38.495047094906312, -8.128363100414337],
              [-38.495004061281286, -8.128442463788243],
              [-38.49498516336098, -8.128529183738205],
              [-38.494988874241358, -8.128610323743663],
              [-38.494997853644747, -8.128688394925394],
              [-38.494996936712397, -8.128768353307827],
              [-38.494997059089748, -8.128815659827969],
              [-38.494993461014843, -8.128862057208071],
              [-38.494981007195683, -8.128949237417435],
              [-38.494958123942439, -8.129029078064814],
              [-38.494936873301505, -8.129109915709302],
              [-38.49493497677139, -8.129174767397702],
              [-38.494941633136783, -8.129223257907583],
              [-38.494955640888641, -8.129272571615664],
              [-38.494972286868972, -8.12931718509898],
              [-38.495033566793175, -8.129381934345705],
              [-38.495075368303851, -8.129416900706772],
              [-38.495118896043991, -8.129450602880096],
              [-38.495164700404267, -8.129478428499279],
              [-38.495211410779497, -8.129507612017585],
              [-38.495251584556563, -8.1295376919361],
              [-38.495295745683173, -8.129573023013304],
              [-38.495337100846342, -8.12960201896704],
              [-38.495379369938277, -8.129626041194037],
              [-38.495431001509822, -8.129639582609219],
              [-38.495484356481548, -8.12965412112796],
              [-38.495540156158576, -8.129673547078282],
              [-38.495481857302465, -8.129692831461268],
              [-38.495422379893981, -8.129710938497658],
              [-38.495350742283335, -8.129726588229993],
              [-38.49527910319788, -8.129743413822808],
              [-38.495198492413827, -8.12974855996319],
              [-38.495114531376338, -8.12974709893542],
              [-38.495028010552126, -8.129760197474047],
              [-38.494957976241757, -8.129800271151215],
              [-38.494885756635227, -8.129845769217015],
              [-38.494813444431493, -8.129892714384848],
              [-38.494773566730693, -8.129916182339121],
              [-38.49469971808157, -8.129957788911554],
              [-38.494625324681152, -8.129999485244337],
              [-38.494583092473036, -8.130018518107057],
              [-38.494537600900308, -8.130030853469927],
              [-38.49449292093739, -8.130047441077497],
              [-38.494418442960139, -8.130084162408513],
              [-38.494348500894148, -8.130122969760631],
              [-38.494282996717558, -8.130169651937939],
              [-38.494240453979153, -8.130219166677122],
              [-38.494210152686321, -8.130278736804264],
              [-38.494187377641289, -8.130344467023106],
              [-38.494161140496622, -8.130420414000467],
              [-38.494140516842556, -8.130507764902658],
              [-38.494119987236182, -8.130592492814086],
              [-38.494087752731787, -8.130673588081207],
              [-38.494060872553291, -8.1307555945272],
              [-38.494042624767005, -8.130830285107974],
              [-38.494030452832803, -8.130909596297238],
              [-38.494040077558509, -8.130979618065275],
              [-38.494074299473354, -8.13105102722343],
              [-38.494093944252739, -8.131092930911674],
              [-38.494129259856372, -8.131160813801971],
              [-38.494143803343754, -8.131217183448271],
              [-38.494156443140454, -8.131271560782896],
              [-38.494165010605876, -8.131316073790298],
              [-38.49418553715978, -8.131378692062794],
              [-38.49420642430966, -8.131443300725463],
              [-38.494249632948879, -8.131514449697082],
              [-38.494315365936629, -8.131575134261769],
              [-38.494358361781231, -8.131598795697684],
              [-38.494442923468604, -8.131628297748811],
              [-38.494521603634254, -8.13164295835336],
              [-38.494607370168964, -8.131652652897223],
              [-38.494693953987131, -8.131662077083435],
              [-38.494779918603115, -8.131658565855627],
              [-38.494871064520204, -8.131649181672355],
              [-38.494956322771749, -8.131629840440942],
              [-38.495013696571583, -8.131624665506038],
              [-38.49507558289416, -8.131640300113098],
              [-38.495199255056207, -8.131679348041642],
              [-38.495201070475375, -8.131679350296043],
              [-38.495255602670674, -8.13169633252318],
              [-38.495324386817558, -8.131712608821987],
              [-38.495408798277246, -8.131717145755857],
              [-38.495503206643228, -8.131712016721698],
              [-38.495588341788157, -8.131718544459281],
              [-38.495672665874814, -8.131720367671864],
              [-38.495724857603079, -8.131721698747576],
              [-38.495771966383387, -8.131722842607868],
              [-38.495855110112501, -8.131724935674452],
              [-38.495928005307533, -8.131720141667879],
              [-38.49598583127699, -8.131716323935706],
              [-38.496043389224269, -8.131709068688934],
              [-38.496094330147997, -8.131694388171665],
              [-38.496146802658494, -8.131688935644943],
              [-38.49620082132266, -8.131681042824935],
              [-38.496257649257146, -8.131676862008881],
              [-38.49631638947622, -8.131667799148911],
              [-38.496366051354755, -8.131659720004301],
              [-38.496416894721229, -8.131650466441755],
              [-38.496507235416104, -8.131631673888416],
              [-38.496584870199442, -8.131611237444577],
              [-38.496648443711038, -8.131584180581696],
              [-38.496686849074251, -8.131576811074185],
              [-38.496733233032863, -8.13157686850122],
              [-38.496768352597485, -8.131583967231268],
              [-38.496821696267226, -8.131607822129137],
              [-38.496875035884919, -8.131634933285879],
              [-38.496931798992122, -8.131682762171522],
              [-38.49700110305043, -8.131718938265232],
              [-38.497078711009621, -8.131720029260334],
              [-38.497126378289714, -8.131710048063454],
              [-38.497206836767127, -8.131681745672186],
              [-38.49737791621061, -8.131628228772071],
              [-38.497456285798307, -8.131600828266127],
              [-38.497541459595752, -8.131576330617714],
              [-38.497623361689122, -8.131554994726512],
              [-38.497701827084491, -8.131523523951028],
              [-38.497776929779256, -8.131495395728185],
              [-38.497833441411309, -8.131453586277699],
              [-38.497868088938162, -8.13140234280722],
              [-38.497883580641059, -8.131353608328405],
              [-38.497893940813817, -8.131270767116188],
              [-38.497900641906824, -8.131210624829496],
              [-38.497930623846699, -8.131261495869115],
              [-38.497954279503766, -8.131290107908086],
              [-38.497978478552263, -8.131319715588665],
              [-38.498028095712407, -8.131347545615668],
              [-38.498087372949321, -8.131344543439909],
              [-38.498156823501304, -8.131335945791777],
              [-38.498230287872381, -8.131311343085518],
              [-38.498274881126406, -8.131291498689118],
              [-38.498319737808366, -8.131278800319084],
              [-38.498371680576035, -8.131261316732727],
              [-38.498418987841895, -8.131248802279162],
              [-38.498466216358864, -8.131226609360628],
              [-38.49850963283032, -8.131204140377758],
              [-38.498556781016056, -8.131173535314451],
              [-38.49860264492068, -8.131153782899332],
              [-38.498651961597098, -8.131131230725314],
              [-38.498697554078753, -8.131110754348283],
              [-38.498782110396881, -8.131071692897274],
              [-38.498861454245201, -8.131063650034246],
              [-38.498907580668579, -8.131051767229675],
              [-38.498953260420357, -8.131034094941841],
              [-38.49899531752876, -8.131009815188857],
              [-38.499067912250993, -8.130954457575442],
              [-38.499133880570241, -8.1308991822359],
              [-38.499209366567854, -8.130854591944026],
              [-38.499259124016568, -8.130842532666158],
              [-38.499230126158778, -8.130876325994295],
              [-38.49920030361595, -8.130916359491666],
              [-38.499169021327063, -8.130962361019668],
              [-38.49913610279858, -8.131010260020679],
              [-38.499096013587597, -8.131057969279301],
              [-38.499053024862121, -8.131101514168751],
              [-38.499013212098276, -8.131145877034896],
              [-38.498970299642977, -8.131201090314963],
              [-38.498920129993699, -8.131252676565007],
              [-38.498856451297122, -8.131291492424557],
              [-38.498793393834369, -8.131341705989527],
              [-38.498745133704666, -8.13139058100997],
              [-38.498700213666311, -8.131454294268202],
              [-38.498660996793944, -8.131530406475838],
              [-38.498641247589333, -8.131572170935456],
              [-38.498614643480515, -8.131651826319803],
              [-38.498591395191319, -8.131733656688331],
              [-38.498579356898929, -8.131779139185943],
              [-38.498564550413448, -8.131860889512682],
              [-38.49856390982545, -8.131938134714108],
              [-38.498576263434266, -8.132004179898889],
              [-38.498615194774843, -8.132084911087741],
              [-38.498643166307055, -8.132146724291761],
              [-38.498575306035697, -8.132117243693134],
              [-38.498505779978267, -8.132113449390681],
              [-38.498443323390894, -8.132118256737416],
              [-38.498393454871987, -8.132146506670727],
              [-38.498354101855675, -8.132185985620151],
              [-38.498318020077356, -8.132222664591451],
              [-38.498282462905735, -8.132275444658196],
              [-38.498274754813885, -8.132342460057471],
              [-38.498288110351496, -8.132405702473116],
              [-38.498318532448444, -8.132467699625622],
              [-38.498353589169064, -8.132525451253267],
              [-38.49837757012596, -8.132584545989248],
              [-38.498396453961639, -8.13265485047871],
              [-38.498402099029462, -8.132714013036981],
              [-38.498409200597742, -8.132769830669485],
              [-38.498427281892951, -8.132828556314522],
              [-38.498463052126439, -8.132896529888249],
              [-38.49843447996998, -8.132879761014955],
              [-38.498388942357401, -8.132855915948394],
              [-38.498337244896952, -8.132821932653101],
              [-38.498300158693411, -8.132790319138731],
              [-38.498265884155373, -8.132760518131477],
              [-38.49822826019544, -8.132723386376886],
              [-38.498177935356033, -8.132680540464225],
              [-38.498118886255391, -8.132645914902948],
              [-38.498059021461707, -8.132610293354606],
              [-38.497985072292295, -8.132586594062795],
              [-38.49791446906405, -8.132573029511702],
              [-38.497845294659932, -8.132578371175212],
              [-38.497781566639141, -8.132583629104456],
              [-38.49772244082002, -8.132610691634603],
              [-38.497665851070835, -8.132642189436277],
              [-38.497623232536895, -8.1326797647798],
              [-38.497581786139058, -8.132723673204646],
              [-38.497543337772974, -8.132765504935564],
              [-38.497508162456633, -8.132803089466664],
              [-38.497470882838932, -8.132853967830879],
              [-38.49743069808067, -8.132905204406661],
              [-38.497395329934513, -8.132951833883935],
              [-38.497355052132242, -8.13300487937493],
              [-38.497317786896751, -8.133044179889364],
              [-38.49727852423463, -8.133083839737061],
              [-38.497243878281481, -8.133133635910617],
              [-38.497224370960033, -8.133199641708464],
              [-38.497206671846286, -8.133271438666945],
              [-38.497198446416895, -8.133316383113428],
              [-38.497188460717425, -8.133389908199796],
              [-38.497185267540573, -8.133475743155174],
              [-38.497192616804981, -8.133551189178327],
              [-38.497205415174122, -8.133624380643797],
              [-38.497200978130607, -8.13368859604233],
              [-38.497172047106481, -8.133741293737561],
              [-38.497148828928275, -8.133798611548499],
              [-38.497146214402584, -8.13385713072722],
              [-38.497166304807585, -8.133905999640323],
              [-38.49719348745321, -8.133945198968441],
              [-38.497221489526893, -8.133982409365785],
              [-38.497253841426065, -8.134025414067684],
              [-38.497302348297133, -8.134070338242298],
              [-38.497373738619416, -8.134108054558668],
              [-38.497453218211923, -8.134137278382841],
              [-38.497499954518055, -8.134146200467471],
              [-38.497553596784826, -8.134149251708099],
              [-38.497641085016753, -8.134161842238152],
              [-38.497726298674806, -8.134178681180511],
              [-38.497809861905345, -8.134208814504058],
              [-38.497901592789944, -8.134240405134031],
              [-38.497949593845931, -8.134254213136893],
              [-38.497995958991574, -8.134269647248566],
              [-38.498052496971866, -8.134279938158688],
              [-38.498105682707013, -8.134285159611011],
              [-38.498204339385047, -8.134294959804949],
              [-38.498278141972733, -8.134290799687712],
              [-38.498229436325012, -8.134332618811786],
              [-38.498189544624417, -8.134367122204123],
              [-38.498165916446915, -8.134389253757488],
              [-38.498118308247975, -8.134424471228485],
              [-38.498074593994623, -8.134467110564],
              [-38.498035394346459, -8.134529021738741],
              [-38.498000909072751, -8.134595551782832],
              [-38.497962696859368, -8.134666328460783],
              [-38.497927649147961, -8.134747149211526],
              [-38.497885349140446, -8.134820363032199],
              [-38.497849126769665, -8.134896840628377],
              [-38.497821437603058, -8.134972786054281],
              [-38.497791475866499, -8.135051351775406],
              [-38.497765512353851, -8.135126485260896],
              [-38.497739366949403, -8.135201889874846],
              [-38.497697530962611, -8.135266873115174],
              [-38.497651442651588, -8.135320635050745],
              [-38.497601731564693, -8.135367879960283],
              [-38.497559560160305, -8.135410611588693],
              [-38.497514828097223, -8.135468626425034],
              [-38.497491965391355, -8.135532004967958],
              [-38.497501586277288, -8.135605644754024],
              [-38.497525920435855, -8.135672699743322],
              [-38.497570148517994, -8.135727839674811],
              [-38.497620372626933, -8.135778916678229],
              [-38.497656686118646, -8.135848066867721],
              [-38.4976794765169, -8.135915662652414],
              [-38.497698991360892, -8.135989495571145],
              [-38.497727772272995, -8.136057460567471],
              [-38.497754655990995, -8.136118187060703],
              [-38.497761470657714, -8.136185672640487],
              [-38.497785342246345, -8.136259782297365],
              [-38.497811303851947, -8.136332085497443],
              [-38.497841448637061, -8.136398243134057],
              [-38.497869782268431, -8.136460961356031],
              [-38.497893300371707, -8.136527382154966],
              [-38.497929006935507, -8.136573737682532],
              [-38.497972095653026, -8.136596313466239],
              [-38.498023790451654, -8.136559834754097],
              [-38.498038762388177, -8.13649129067227],
              [-38.498045995058519, -8.136441731937827],
              [-38.498067400659941, -8.136382150546341],
              [-38.498101349001281, -8.136309559566564],
              [-38.498140914055497, -8.136245930264721],
              [-38.498187903837454, -8.136197234701443],
              [-38.498229562697595, -8.136128723569534],
              [-38.498244737610058, -8.13604290340237],
              [-38.498256964952205, -8.135991541790048],
              [-38.498261205933304, -8.135938903987267],
              [-38.49827388724168, -8.135887452482555],
              [-38.498289561658147, -8.135837904164225],
              [-38.498310775969891, -8.135786101386481],
              [-38.498331166130328, -8.13574008651505],
              [-38.49838853373943, -8.135667343514069],
              [-38.49842760861258, -8.135632839102643],
              [-38.49846150419679, -8.13560257953614],
              [-38.498517306379767, -8.135547744070475],
              [-38.498586638951018, -8.135488583599075],
              [-38.498624527236601, -8.135459323916095],
              [-38.498681961922308, -8.135405575862983],
              [-38.498734238555876, -8.135338977257994],
              [-38.498785257176067, -8.135262065568821],
              [-38.498829193778441, -8.135186592368425],
              [-38.498873210687698, -8.135119531294359],
              [-38.498914576216322, -8.13506775332794],
              [-38.498947204393808, -8.135034688141047],
              [-38.498990928846517, -8.134983817594078],
              [-38.499049804083619, -8.134939478262208],
              [-38.499092583964234, -8.134918274785818],
              [-38.499079734953206, -8.134958690978973],
              [-38.499068422321407, -8.135004536183988],
              [-38.499048596344089, -8.135034903605408],
              [-38.499036851746901, -8.135063110141758],
              [-38.499013484653972, -8.13509428726303],
              [-38.498991458580171, -8.135142018747187],
              [-38.498966977057044, -8.135193455732795],
              [-38.498949297459937, -8.135249695058532],
              [-38.498936143193589, -8.135316613295995],
              [-38.498942055839635, -8.135379665610692],
              [-38.49898418297601, -8.135445566592384],
              [-38.49903397696793, -8.135477647985985],
              [-38.498959985990631, -8.135487144661784],
              [-38.498881707948733, -8.135513369650893],
              [-38.498835297230684, -8.13553447816963],
              [-38.498801488517707, -8.13556790369546],
              [-38.498762313469619, -8.135610005986933],
              [-38.49872104020784, -8.135660517718271],
              [-38.498683648210999, -8.135728672366723],
              [-38.498647614520358, -8.135799451793867],
              [-38.498638159462075, -8.135884374521396],
              [-38.498638336569172, -8.13596098755346],
              [-38.498649498996741, -8.136036076457515],
              [-38.498656305270707, -8.136110436370222],
              [-38.498647120832885, -8.136196716209714],
              [-38.498646609419175, -8.136243026983577],
              [-38.498646186641281, -8.136291056454027],
              [-38.498651485874682, -8.136336379426535],
              [-38.498661500759958, -8.136385326296439],
              [-38.498669430250139, -8.136432371101655],
              [-38.498690469314376, -8.136521582697641],
              [-38.498703392511928, -8.136567638692563],
              [-38.498720041752527, -8.136610171658182],
              [-38.49873886806629, -8.13665388318485],
              [-38.498766178187708, -8.136736861329625],
              [-38.498776335639597, -8.136817195203962],
              [-38.498774150999957, -8.136895162099611],
              [-38.498779055918426, -8.136965630229101],
              [-38.498793209938263, -8.137043888638408],
              [-38.498794745760726, -8.137049769910732],
              [-38.498812813668252, -8.137119440211926],
              [-38.498822235212522, -8.137134557312914],
              [-38.498856478681127, -8.137189684802289],
              [-38.498879231164132, -8.137214948967054],
              [-38.498913495771468, -8.137253071509532],
              [-38.498984693802591, -8.137300013442493],
              [-38.499025783536489, -8.137324305243627],
              [-38.499070690317126, -8.137344893217275],
              [-38.499116685696137, -8.137366025240281],
              [-38.499161043023392, -8.137390501961429],
              [-38.49920785446519, -8.137412358596519],
              [-38.49925348149295, -8.137437741393112],
              [-38.499297201297487, -8.137463935901788],
              [-38.499339465270324, -8.137492932622743],
              [-38.499376103048796, -8.137520565626458],
              [-38.499416638542371, -8.137552635576613],
              [-38.499454821033048, -8.137579004149824],
              [-38.499525199543427, -8.137628115831845],
              [-38.49959168439797, -8.137669534288667],
              [-38.499652818296696, -8.137707237613633],
              [-38.499709887195657, -8.13772901637676],
              [-38.499770133695684, -8.137750346785475],
              [-38.499823682113828, -8.137756292102884],
              [-38.499933296423215, -8.137714457343947],
              [-38.499994604222834, -8.137685135920902],
              [-38.499980909874665, -8.137748435446088],
              [-38.49998467477154, -8.137786791717327],
              [-38.500012008987568, -8.137850594004773],
              [-38.500064503206914, -8.137901492625113],
              [-38.500124716908218, -8.137949325371899],
              [-38.500172767900537, -8.137996509980651],
              [-38.500242790770841, -8.138039741730816],
              [-38.500288878077463, -8.138060059673998],
              [-38.500331702062368, -8.138076755518897],
              [-38.500372523454246, -8.138097971495078],
              [-38.500420395413833, -8.138143165913139],
              [-38.500458849562712, -8.138170167896412],
              [-38.500498304678814, -8.138195181163569],
              [-38.500542840931978, -8.138221919262721],
              [-38.50058666265938, -8.138239249472932],
              [-38.50063484337533, -8.138255047353971],
              [-38.500717501639897, -8.138283822244253],
              [-38.500780273903857, -8.138318090248699],
              [-38.50084250926799, -8.138346116397468],
              [-38.500973359059884, -8.13838200571257],
              [-38.501053503721756, -8.138388164452749],
              [-38.501140369136053, -8.138391436968085],
              [-38.501225312892387, -8.13840718947937],
              [-38.501277135297642, -8.138414308367723],
              [-38.501326517806234, -8.138412559959201],
              [-38.501379802863809, -8.138411359047327],
              [-38.501464221791394, -8.138411100862896],
              [-38.501528225166382, -8.138404124159402],
              [-38.501537651426844, -8.138415532678462],
              [-38.501523165151355, -8.13845875095234],
              [-38.501503226592206, -8.138506756489422],
              [-38.501512040691836, -8.138572797260137],
              [-38.501518941413742, -8.138644624609748],
              [-38.501517784950742, -8.138698984836498],
              [-38.501509287083586, -8.138744290853952],
              [-38.501520555179056, -8.138807801980214],
              [-38.501550081327721, -8.138861023986859],
              [-38.501587324055542, -8.138913531845301],
              [-38.501619757293589, -8.138964857923241],
              [-38.501631888306122, -8.138991194338022],
              [-38.501640036051995, -8.139008842473805],
              [-38.501664386509049, -8.139063414902443],
              [-38.501682833615867, -8.13912051274245],
              [-38.50169819322501, -8.139178601765522],
              [-38.50170774259982, -8.139237316823523],
              [-38.501740905300373, -8.139286020680707],
              [-38.501790637598766, -8.139295126890014],
              [-38.501851731691296, -8.139291945558696],
              [-38.501925085357236, -8.139284528009496],
              [-38.501994277548576, -8.139265708392875],
              [-38.502051887901622, -8.139216844501874],
              [-38.502097887035916, -8.139161725179557],
              [-38.502112209938062, -8.139103763008794],
              [-38.502132059809327, -8.139054038744064],
              [-38.502151905543855, -8.139007661196754],
              [-38.502197549811008, -8.138945847977572],
              [-38.502266402732552, -8.138907671166402],
              [-38.50234805057061, -8.138872766292318],
              [-38.502423092524694, -8.138821210139874],
              [-38.502466511653203, -8.138797112645131],
              [-38.502538642940671, -8.138750346860174],
              [-38.502611212122694, -8.138716516234153],
              [-38.502661798252554, -8.138695683765958],
              [-38.502753315364714, -8.138680961687491],
              [-38.502818861573211, -8.138674258026787],
              [-38.502877232059056, -8.138671344557096],
              [-38.50288004623382, -8.138671167097453],
              [-38.502938748929552, -8.138693309231973],
              [-38.503001084767519, -8.138713646763797],
              [-38.503064901506846, -8.138710830383692],
              [-38.503113517475271, -8.138668196512594],
              [-38.503153262787833, -8.138605200081257],
              [-38.503168323396729, -8.138537741302672],
              [-38.50315851342269, -8.138469347581294],
              [-38.503142802342076, -8.138401670257277],
              [-38.503153720387701, -8.138307432614818],
              [-38.503152863695981, -8.138266004544679],
              [-38.503184503316298, -8.138298515518336],
              [-38.503214334175226, -8.138325597156665],
              [-38.503242537816448, -8.138347249682077],
              [-38.503312669256502, -8.138376370543671],
              [-38.503374997829127, -8.13840258738883],
              [-38.503420103673839, -8.138409245533683],
              [-38.503502420046168, -8.138421014473188],
              [-38.503577172795275, -8.138456743937168],
              [-38.503641242220709, -8.138469847327999],
              [-38.503708962812752, -8.138466492902586],
              [-38.503787672231269, -8.138458810193686],
              [-38.503866763542689, -8.138435841544112],
              [-38.503941149245101, -8.138401017916568],
              [-38.504015711029965, -8.138370626638798],
              [-38.504098083249978, -8.13833698860951],
              [-38.504158704209956, -8.13834936409225],
              [-38.504172833930582, -8.138374436552208],
              [-38.504203807993001, -8.138431549590395],
              [-38.504242348990836, -8.138461988514671],
              [-38.504296409555124, -8.138494526772883],
              [-38.504352280022822, -8.138531589834447],
              [-38.504395468341777, -8.138547471639665],
              [-38.504426771339851, -8.138558544981633],
              [-38.504395412876448, -8.13859260713437],
              [-38.504358602379533, -8.138631275684615],
              [-38.504326965917052, -8.138669950545365],
              [-38.504303475085614, -8.138728082530388],
              [-38.504272018821077, -8.138767843030148],
              [-38.504237303072891, -8.138800815646558],
              [-38.504196242460225, -8.138826272996306],
              [-38.504142732303869, -8.138862840741604],
              [-38.504096929921147, -8.138905839983888],
              [-38.504047483160399, -8.138959779464942],
              [-38.504011729941773, -8.139024409000475],
              [-38.503976617460651, -8.139084697617461],
              [-38.503944859889152, -8.139147975244967],
              [-38.503908380453936, -8.139212603881797],
              [-38.503885862990586, -8.1392910887289],
              [-38.503884961464237, -8.139359831161793],
              [-38.503907840190948, -8.139430411683561],
              [-38.503930091526591, -8.139494478892587],
              [-38.503907073253984, -8.139537325032276],
              [-38.50388150409988, -8.139587675571638],
              [-38.503839507275657, -8.13963619699884],
              [-38.503802328749714, -8.139678754475041],
              [-38.503762245594793, -8.139721217946022],
              [-38.503715430765247, -8.139775431948101],
              [-38.503672420591911, -8.139835982234636],
              [-38.503644547438498, -8.139914460515607],
              [-38.503632420748978, -8.139958224467811],
              [-38.503621473770856, -8.140002261217456],
              [-38.503600856307116, -8.140085723222832],
              [-38.50358777221215, -8.140169827599401],
              [-38.503600824500005, -8.140259029237285],
              [-38.503614246009008, -8.14034325646548],
              [-38.503644649319355, -8.140421625070005],
              [-38.503699585749686, -8.140480214667027],
              [-38.503763061655214, -8.140533297120328],
              [-38.503829001585181, -8.140575709237865],
              [-38.503893583717023, -8.140615044318112],
              [-38.503974082868005, -8.14062862000827],
              [-38.504045248195162, -8.140629340096808],
              [-38.504118968741757, -8.140619028147341],
              [-38.504189351480456, -8.140591797581594],
              [-38.504254097715744, -8.140571524927282],
              [-38.504328551104997, -8.140555786712314],
              [-38.504405636575029, -8.14054032305174],
              [-38.50448361431949, -8.140537433295973],
              [-38.504565842505194, -8.140547483350113],
              [-38.504647515834108, -8.140565854297146],
              [-38.504733725159568, -8.140586401609124],
              [-38.504780103656614, -8.140591794841686],
              [-38.504826942574709, -8.140591851960984],
              [-38.504918149102458, -8.14060860634318],
              [-38.504965787212875, -8.140623136745306],
              [-38.505012884907984, -8.140634229306219],
              [-38.505061073659874, -8.140643875959203],
              [-38.50511489834102, -8.140647016929202],
              [-38.50516191204251, -8.140652591797018],
              [-38.50520774125512, -8.140661783297398],
              [-38.505297055234436, -8.140667409690424],
              [-38.505345708517723, -8.140668373481098],
            ],
          ],
          [
            [
              [-38.551566467377306, -8.18466433108161],
              [-38.552329787655665, -8.180262676789711],
              [-38.551210238677825, -8.178815733878626],
              [-38.551113840729045, -8.176450205009369],
              [-38.559951938169739, -8.175769857664756],
              [-38.558511445798267, -8.166240376666025],
              [-38.566391639938068, -8.166774514167566],
              [-38.566362266595782, -8.163456135772913],
              [-38.564194492533311, -8.155634355679293],
              [-38.557529976331935, -8.159274003823972],
              [-38.549850878876242, -8.155947203957858],
              [-38.54343957756118, -8.168720597910339],
              [-38.540306711679889, -8.169078499157822],
              [-38.535663906693145, -8.163454934702518],
              [-38.53695668579617, -8.157542550039375],
              [-38.543487375840044, -8.155644397790963],
              [-38.542901613430232, -8.148908376397802],
              [-38.527896534115889, -8.151059694512234],
              [-38.526546684953679, -8.149448251580139],
              [-38.52539482543655, -8.147607016192392],
              [-38.530016509509935, -8.142815618651051],
              [-38.532456636686874, -8.142555581659423],
              [-38.541750409050216, -8.14634455933747],
              [-38.542838655787428, -8.146312862097657],
              [-38.543927762062474, -8.145361351448692],
              [-38.540930610827253, -8.142368156338662],
              [-38.540969350080886, -8.137283413527285],
              [-38.540964720666395, -8.137283408296206],
              [-38.540938941864972, -8.138319602966972],
              [-38.540906024189894, -8.138290620964494],
              [-38.54085832029449, -8.138253029259008],
              [-38.54081306336689, -8.138218877509331],
              [-38.540766969921499, -8.138201910782838],
              [-38.540715708494538, -8.138179601510771],
              [-38.540660336945827, -8.138179538897555],
              [-38.540582363428364, -8.138178998451142],
              [-38.540636226758657, -8.138148395974794],
              [-38.540663872749271, -8.138103653255421],
              [-38.540664045211159, -8.138031924624272],
              [-38.54067130661759, -8.137952605999638],
              [-38.540668759286994, -8.137877889360078],
              [-38.540661924511177, -8.137821710633128],
              [-38.540652317000145, -8.13780903641983],
              [-38.54062376534722, -8.13777228041994],
              [-38.540614076337398, -8.137751465389298],
              [-38.540642827748734, -8.137692522877868],
              [-38.54063463993473, -8.13762883506331],
              [-38.540596213623587, -8.137574881921422],
              [-38.540559766295843, -8.137536760201002],
              [-38.540517510541321, -8.137497727382646],
              [-38.540465267306637, -8.137460854122029],
              [-38.540451115602046, -8.137453059198586],
              [-38.540403036078573, -8.137426592677354],
              [-38.540362664523634, -8.137406918809054],
              [-38.540333270405768, -8.137392594055552],
              [-38.540260049604015, -8.137363656851567],
              [-38.540179832190461, -8.137340952937151],
              [-38.540095340729017, -8.137325028105263],
              [-38.540007580215629, -8.137310184984331],
              [-38.539961932006477, -8.137300907138739],
              [-38.539877255407568, -8.137288147889711],
              [-38.539832515036075, -8.137278780605714],
              [-38.539753104715409, -8.137264760961942],
              [-38.539707734823786, -8.137250237159643],
              [-38.53967579445932, -8.137239979837043],
              [-38.53963096415206, -8.137229888809539],
              [-38.539550570114287, -8.137202933307275],
              [-38.539500846345135, -8.137185600520809],
              [-38.539444773752429, -8.137163647454194],
              [-38.539385616750828, -8.137140062738311],
              [-38.539315569399641, -8.137114385257398],
              [-38.539243339172195, -8.137092504293159],
              [-38.539165731866447, -8.137089431332331],
              [-38.539119351633808, -8.137085308355275],
              [-38.539039010502336, -8.137091548873938],
              [-38.538962951403185, -8.137084045459909],
              [-38.53889509132393, -8.13705077238278],
              [-38.538820343179182, -8.137008174870651],
              [-38.538740141032989, -8.136972264635999],
              [-38.538696221097631, -8.136959913229976],
              [-38.53869132112866, -8.136958369973879],
              [-38.538610381753223, -8.13693213729208],
              [-38.538528628435508, -8.136903280548516],
              [-38.538446599707335, -8.136877137050266],
              [-38.5383696373896, -8.136865743045522],
              [-38.538345218082654, -8.136866981631377],
              [-38.538289117341606, -8.136869721902656],
              [-38.538245361998747, -8.136872204840964],
              [-38.538211683047173, -8.136874156513661],
              [-38.538155581476772, -8.136877620385196],
              [-38.538109273104581, -8.136890050177612],
              [-38.538054703480967, -8.136903284643003],
              [-38.537997213380869, -8.136930264554245],
              [-38.537932461663019, -8.136957055292649],
              [-38.53787351897698, -8.136984214438108],
              [-38.537818204187133, -8.137014000823434],
              [-38.537773241834714, -8.137039999976531],
              [-38.537719397845677, -8.137053687483021],
              [-38.537659676225921, -8.137047649656784],
              [-38.537599151236797, -8.137029761635263],
              [-38.537540438908131, -8.137014317884864],
              [-38.537483559773626, -8.136983408843065],
              [-38.537418341279576, -8.136942269170435],
              [-38.537354019529793, -8.136910718469519],
              [-38.537288698668362, -8.136879709335751],
              [-38.537222557542641, -8.136851593736193],
              [-38.537147069642792, -8.136821025261147],
              [-38.537067487227361, -8.136798954637664],
              [-38.536989345867781, -8.136786744958741],
              [-38.536913740125861, -8.136779784397518],
              [-38.536841763320737, -8.136774546556191],
              [-38.536768590997035, -8.136782784754006],
              [-38.536705293257768, -8.136808129712572],
              [-38.536660861266789, -8.136846521330328],
              [-38.536627574670412, -8.13690202116797],
              [-38.536613239193855, -8.136975271298951],
              [-38.536611179598694, -8.137108776761171],
              [-38.536610885830818, -8.137206465068056],
              [-38.536601217677195, -8.137246705377997],
              [-38.536597204555214, -8.137263344050197],
              [-38.536584056065408, -8.137330535217995],
              [-38.536582337045772, -8.137404613809416],
              [-38.536578803191887, -8.137478147615564],
              [-38.536566323713956, -8.137595088388926],
              [-38.536544658561546, -8.137648340160801],
              [-38.53650109489805, -8.13772092376759],
              [-38.536456512735093, -8.137731908093508],
              [-38.536400772991499, -8.137736367104898],
              [-38.536311631532321, -8.137738436218477],
              [-38.536242462601756, -8.13773835725636],
              [-38.536166669748567, -8.137736099860527],
              [-38.536115101706507, -8.137743910336166],
              [-38.536110197994759, -8.137745623332465],
              [-38.536048649928638, -8.137749080685301],
              [-38.536000977104969, -8.13776386043264],
              [-38.535963446177675, -8.1378000889873],
              [-38.53596384353569, -8.137849205113589],
              [-38.535992303665445, -8.137886232670706],
              [-38.536046010635538, -8.137912887033041],
              [-38.536097445064989, -8.137942342818839],
              [-38.536146970566151, -8.137974148181939],
              [-38.536176537159776, -8.137996252338688],
              [-38.536215345642738, -8.138033110793049],
              [-38.536255056454216, -8.138074673800922],
              [-38.536198714016066, -8.13805063957594],
              [-38.536174669795869, -8.138041385972565],
              [-38.536129403746386, -8.138015555337372],
              [-38.536092021526962, -8.138001402065695],
              [-38.536046168919093, -8.13801202308345],
              [-38.535986951563281, -8.138040990673295],
              [-38.535978871106714, -8.138042428681524],
              [-38.535922852650998, -8.138052404904707],
              [-38.535879020249652, -8.138042857318377],
              [-38.535875117950098, -8.138042038787706],
              [-38.535828652823895, -8.138032759545233],
              [-38.535782203628429, -8.138009641093559],
              [-38.535756347190521, -8.137997581369568],
              [-38.535724034111205, -8.137995735389048],
              [-38.535667556378215, -8.138010414578451],
              [-38.535696283106766, -8.137973542823536],
              [-38.535717398545096, -8.137924813098197],
              [-38.535744503323578, -8.137877537455557],
              [-38.535749824892626, -8.137828246961861],
              [-38.535718471927169, -8.137780904505389],
              [-38.535704015945321, -8.13772209390137],
              [-38.535694627836804, -8.137676676049564],
              [-38.535672276290292, -8.137616409180913],
              [-38.535638017369216, -8.137570148826663],
              [-38.535601207756748, -8.137531754915504],
              [-38.535565044079931, -8.137484226044188],
              [-38.535539308138731, -8.137446387503008],
              [-38.535506142374309, -8.137396781648816],
              [-38.535476334481423, -8.137347812799037],
              [-38.535441980665112, -8.137305170418896],
              [-38.535401535600755, -8.137270752241024],
              [-38.535366980457312, -8.137245295586826],
              [-38.535351652782175, -8.137234061954006],
              [-38.53530585599659, -8.137196290906171],
              [-38.535247257033916, -8.137161309232834],
              [-38.535208612968368, -8.137139465985273],
              [-38.535189381553586, -8.137128680142522],
              [-38.535148561659405, -8.137104482636886],
              [-38.535124346609273, -8.137086002632218],
              [-38.535112012257521, -8.137076671917285],
              [-38.535058945043545, -8.137046400074972],
              [-38.535007587713068, -8.137028974427967],
              [-38.534948615179125, -8.137003127981549],
              [-38.534903985571781, -8.136976574328427],
              [-38.534847820747686, -8.136956158228054],
              [-38.53477894191343, -8.136940792896592],
              [-38.534707512581882, -8.136933113088515],
              [-38.534620730984919, -8.136935546352987],
              [-38.534511590711197, -8.136962737943831],
              [-38.534453471248462, -8.136984289473572],
              [-38.534492453855506, -8.136948695890689],
              [-38.534554358759195, -8.136871882262128],
              [-38.534595297454821, -8.136793054651042],
              [-38.534620959828196, -8.136737184451228],
              [-38.534663180166447, -8.136570257637915],
              [-38.534674689608941, -8.13650776819482],
              [-38.534684381300771, -8.136447266622532],
              [-38.534689322352044, -8.136413171717088],
              [-38.534751600511996, -8.136170016463515],
              [-38.534751850965776, -8.135952840470301],
              [-38.534692577261637, -8.135794752199862],
              [-38.534613522734325, -8.135630128691071],
              [-38.534455262006503, -8.135432489742222],
              [-38.534316721408423, -8.135294119678747],
              [-38.534178120127443, -8.135208392814048],
              [-38.534013088928198, -8.135135841649843],
              [-38.533828117952154, -8.135115820402932],
              [-38.533603539769317, -8.135122165719604],
              [-38.533382608035929, -8.135193550385649],
              [-38.533345386595172, -8.135197668451452],
              [-38.533298720443902, -8.135205574667363],
              [-38.533251510285304, -8.135212937538475],
              [-38.533201393633703, -8.135221834755987],
              [-38.533155450620754, -8.135232364905871],
              [-38.533108137543344, -8.135250220121483],
              [-38.533047391211831, -8.135267155369991],
              [-38.53300432453149, -8.13530220141079],
              [-38.532942760819118, -8.135319497513116],
              [-38.532898180526082, -8.135329034222757],
              [-38.532849684455194, -8.13534942071438],
              [-38.532808269351982, -8.135369182174884],
              [-38.532768647274807, -8.135408302507592],
              [-38.532745540408534, -8.135452054900853],
              [-38.532710453266453, -8.135494346276182],
              [-38.532661504248317, -8.135513918150968],
              [-38.5326089160544, -8.135540631573376],
              [-38.532562227322202, -8.1355679849423],
              [-38.532523970769716, -8.135603669628042],
              [-38.532490798300785, -8.135638636540415],
              [-38.532462508246596, -8.135690252309663],
              [-38.532437767217822, -8.135734183706472],
              [-38.532411387353399, -8.135782364478208],
              [-38.532384296924455, -8.13581706702238],
              [-38.532363657936358, -8.135846078488632],
              [-38.532328444296908, -8.135919123496031],
              [-38.532313930076135, -8.135832724748331],
              [-38.532289640719561, -8.135800224377551],
              [-38.532262374754275, -8.135751439147128],
              [-38.532242007285319, -8.135702842761747],
              [-38.532217099315567, -8.135655869291293],
              [-38.532197913994366, -8.135605465217036],
              [-38.532188986160769, -8.135554892047319],
              [-38.532184683522097, -8.135507942298592],
              [-38.532171123996477, -8.13545935374848],
              [-38.532156845112986, -8.135404884963826],
              [-38.53216761990776, -8.135349992763594],
              [-38.532200879269965, -8.135318372716171],
              [-38.532231067260895, -8.135273904889145],
              [-38.53224338600841, -8.135218290843991],
              [-38.532251436569219, -8.135164300028057],
              [-38.532255669241039, -8.135115008341758],
              [-38.532259633579436, -8.135062279155107],
              [-38.532259786846765, -8.135008369700056],
              [-38.532258299188712, -8.134960518667002],
              [-38.532251910566586, -8.134912028825521],
              [-38.532242068513504, -8.134867062652324],
              [-38.532233776509969, -8.134816128404628],
              [-38.532215219914349, -8.134771514008737],
              [-38.532189764115302, -8.134727343931619],
              [-38.532166045472195, -8.13467241201726],
              [-38.532138691021117, -8.134621727178571],
              [-38.532111334583831, -8.134572760931663],
              [-38.532085073921294, -8.134518187895948],
              [-38.532054185043179, -8.134462614552913],
              [-38.532023284004559, -8.134417533672368],
              [-38.531994285619099, -8.134375530362705],
              [-38.531980369248579, -8.134321604704278],
              [-38.531965364456248, -8.134266954172748],
              [-38.531943645265578, -8.134209944144889],
              [-38.531918474016223, -8.134155281902382],
              [-38.531900562539697, -8.134102346616432],
              [-38.531882109478573, -8.134046787586323],
              [-38.531855760309725, -8.133990314935636],
              [-38.531827036385266, -8.133946502883967],
              [-38.531797952737328, -8.133899795939247],
              [-38.53177068086147, -8.133856256914129],
              [-38.531740412467983, -8.133813618958834],
              [-38.531710973308869, -8.133760399026198],
              [-38.531701913226243, -8.133745644845096],
              [-38.531684880751349, -8.133717494521795],
              [-38.531646354653368, -8.13367267618702],
              [-38.531611818012671, -8.133631751900008],
              [-38.531571750173804, -8.133585755900816],
              [-38.531528957507895, -8.133541203995639],
              [-38.531497953327076, -8.13350688679691],
              [-38.531456162948608, -8.133459079753823],
              [-38.531415277147573, -8.133413987321763],
              [-38.53137357167828, -8.133371245703225],
              [-38.531328685527605, -8.133331666246859],
              [-38.531291412241899, -8.133302407138912],
              [-38.531241436162126, -8.133268610757138],
              [-38.531187201066658, -8.133228568241581],
              [-38.531148652792744, -8.133202925746605],
              [-38.531093504638037, -8.133167495238286],
              [-38.531037624222925, -8.133137310117014],
              [-38.530984466548929, -8.13310748993999],
              [-38.530945940692042, -8.133062581092954],
              [-38.530907686843456, -8.133017943913728],
              [-38.530858445417849, -8.132976912144592],
              [-38.530806561737705, -8.13294437984473],
              [-38.530754036248958, -8.132917364393577],
              [-38.530698787616174, -8.132890345791763],
              [-38.530652438405014, -8.132860081173456],
              [-38.530615894016996, -8.132828561539942],
              [-38.530575542063254, -8.132792876695955],
              [-38.530537366953595, -8.132758641600839],
              [-38.530507745431578, -8.132706325904643],
              [-38.530496636704981, -8.132658011496543],
              [-38.530478365438746, -8.132602452623221],
              [-38.530453195217461, -8.132547157136763],
              [-38.530427115181865, -8.132493669645655],
              [-38.530392593814419, -8.132439810591141],
              [-38.530351710761117, -8.132392547208042],
              [-38.530308736497304, -8.13234826633362],
              [-38.530255500107266, -8.132308224858017],
              [-38.530199071983574, -8.13228084302134],
              [-38.530155974652196, -8.132264330872935],
              [-38.530092990770981, -8.132254308302741],
              [-38.530031710172999, -8.132262649526696],
              [-38.529973142186499, -8.132280039116493],
              [-38.529931901241206, -8.132306313069511],
              [-38.529882115436052, -8.132343160064236],
              [-38.529842581964353, -8.132384179720686],
              [-38.529804132896544, -8.132429361436866],
              [-38.529774137592874, -8.13246424135715],
              [-38.529740599556384, -8.132501378485749],
              [-38.529718048041516, -8.132535814751362],
              [-38.529729727725098, -8.132483546788295],
              [-38.529743052254311, -8.132421873679831],
              [-38.529753647308496, -8.132365624555799],
              [-38.529757976064928, -8.132311991300933],
              [-38.529755040460685, -8.132260610951461],
              [-38.529745297307002, -8.132208951369108],
              [-38.52972701762652, -8.132160719100987],
              [-38.529709912078019, -8.132117553524035],
              [-38.529709156260914, -8.132065090267513],
              [-38.529713480275255, -8.132015527364203],
              [-38.529730244830091, -8.131961818043733],
              [-38.529737761965187, -8.131898419619107],
              [-38.529732824122185, -8.131851559571608],
              [-38.529719895757374, -8.131806951589388],
              [-38.529706785852277, -8.131762343396829],
              [-38.52968850376967, -8.131716191529383],
              [-38.529656532596491, -8.131655099179197],
              [-38.529631708869125, -8.131614185978828],
              [-38.52960398372943, -8.131570465395903],
              [-38.529572986187318, -8.131530720929257],
              [-38.529542804644905, -8.131491791476352],
              [-38.529497659010381, -8.131441538154379],
              [-38.529453143936401, -8.131395446367346],
              [-38.529407265720565, -8.131350709783684],
              [-38.529370627103802, -8.131322355773147],
              [-38.529330540236174, -8.131293093243475],
              [-38.529282382637, -8.131257037436491],
              [-38.529242486396733, -8.131219996214659],
              [-38.529190966900138, -8.131186830980749],
              [-38.529125461562792, -8.131159709850859],
              [-38.529078271037953, -8.131150609950526],
              [-38.529020824942059, -8.13114014137776],
              [-38.528973796861841, -8.131147413534084],
              [-38.52890958323767, -8.13118017334078],
              [-38.528859077338176, -8.131211863594888],
              [-38.528819103754323, -8.131241123835647],
              [-38.528774409844843, -8.13127055950523],
              [-38.528737714048901, -8.131291230561162],
              [-38.528696473874398, -8.131316871225486],
              [-38.528711021470798, -8.131296174497418],
              [-38.528739393445186, -8.131252247534604],
              [-38.52876194644071, -8.131216544990252],
              [-38.528779496240624, -8.131190062787342],
              [-38.528806965976941, -8.131141431249361],
              [-38.528837265240973, -8.1310794160367],
              [-38.528842054813474, -8.13101990392386],
              [-38.528836764024206, -8.130964450484969],
              [-38.528820320725423, -8.130898401192404],
              [-38.528801694487825, -8.13083614836896],
              [-38.528781976783741, -8.13077579377965],
              [-38.528759267014301, -8.130712541245279],
              [-38.528749712104165, -8.130655273815215],
              [-38.52874515837437, -8.130590504634645],
              [-38.528737692682967, -8.130531973292616],
              [-38.528715876177429, -8.130481113767452],
              [-38.528685600960479, -8.130444807268191],
              [-38.528679615556733, -8.130440096805923],
              [-38.528649235423742, -8.130415910801247],
              [-38.528603236535638, -8.130396952923311],
              [-38.528591349637587, -8.13039341149809],
              [-38.528554327800556, -8.130382423832611],
              [-38.528504410762714, -8.130376305637922],
              [-38.528452417612527, -8.130360235267966],
              [-38.528409693897132, -8.130334949501384],
              [-38.528361986335923, -8.130302331306824],
              [-38.528326074057652, -8.130273978052729],
              [-38.528282262797724, -8.130247243773926],
              [-38.528240166681684, -8.130228652194356],
              [-38.528231910741205, -8.130225024519769],
              [-38.5281844601937, -8.130205522197979],
              [-38.528132288709628, -8.130186738010778],
              [-38.528089373764836, -8.130169773608669],
              [-38.52800972107719, -8.130131781605787],
              [-38.527971792418114, -8.130120069217693],
              [-38.527928327890798, -8.130107355422568],
              [-38.527899380387666, -8.130100085621779],
              [-38.527834225711267, -8.130083818988512],
              [-38.52778178533385, -8.130062139970009],
              [-38.527735780288914, -8.130048518680697],
              [-38.527684699688521, -8.130028378919839],
              [-38.527634801233404, -8.130006431478918],
              [-38.527574273578445, -8.129992160132957],
              [-38.527524637497415, -8.129978715506237],
              [-38.527471476277363, -8.129952512993416],
              [-38.527422748021195, -8.129938978957354],
              [-38.527366847906507, -8.12992625065016],
              [-38.527315021159538, -8.129923205473849],
              [-38.527255291617593, -8.12992503552994],
              [-38.527202735420808, -8.129924793514309],
              [-38.527142377262095, -8.129920833871779],
              [-38.527082835832104, -8.129917056074945],
              [-38.527034461779088, -8.129911120403458],
              [-38.526987982049803, -8.129915317594211],
              [-38.526917350240247, -8.129925456534053],
              [-38.526863338954229, -8.129927564542882],
              [-38.526812690329315, -8.129926148816057],
              [-38.52675403965047, -8.129936844384689],
              [-38.526704285481046, -8.129946736229737],
              [-38.526650182467272, -8.129949658174437],
              [-38.526596181678613, -8.129942811376738],
              [-38.526536359456273, -8.129946269361271],
              [-38.52647408047568, -8.129954789807407],
              [-38.526411525784788, -8.12996620439797],
              [-38.526352420494959, -8.129977532544698],
              [-38.526290770748801, -8.129991299931518],
              [-38.526236012165327, -8.130011407003016],
              [-38.52618532447233, -8.130043277615945],
              [-38.526148161334852, -8.130075435341588],
              [-38.526095466578091, -8.130115896576246],
              [-38.526053229380828, -8.130140269476255],
              [-38.525999734339926, -8.130166528746292],
              [-38.525946603332407, -8.130191974362223],
              [-38.52590399001528, -8.130150135691721],
              [-38.525873720397598, -8.130109306387466],
              [-38.525841183529018, -8.130066755842703],
              [-38.525814181467368, -8.130026111249892],
              [-38.525785098690164, -8.12997949437406],
              [-38.525753007833487, -8.129943637816629],
              [-38.525711201406587, -8.129910393041694],
              [-38.525665037767702, -8.129877324084344],
              [-38.525620695082139, -8.129839553724908],
              [-38.525572992710615, -8.1298027744166],
              [-38.525521015078311, -8.129773678563076],
              [-38.525465295327471, -8.129762035637558],
              [-38.525409736070088, -8.12976830245506],
              [-38.52535817541159, -8.129770594029798],
              [-38.525292189511575, -8.129766627545193],
              [-38.52523648370353, -8.129743135348861],
              [-38.525182325794844, -8.129715574597665],
              [-38.525139156706928, -8.129683323151966],
              [-38.525093732609861, -8.129638948455197],
              [-38.52505511240323, -8.129597928437786],
              [-38.525027928804192, -8.129557374038296],
              [-38.524996390443022, -8.129514915050967],
              [-38.524963396561851, -8.129475167931082],
              [-38.524927039801852, -8.129439125425504],
              [-38.524884873820291, -8.129402985676835],
              [-38.524826727813732, -8.129370535795804],
              [-38.524763766065284, -8.129342241085883],
              [-38.52470434449495, -8.12931386005241],
              [-38.52464792294942, -8.129281593067606],
              [-38.524588772324819, -8.129254388215209],
              [-38.524531340389146, -8.12923225069477],
              [-38.5244728237912, -8.129206222447912],
              [-38.524415301205281, -8.1291839943535],
              [-38.524357928628568, -8.129188540315894],
              [-38.524347142340886, -8.129252477494681],
              [-38.524368601428257, -8.129298000117224],
              [-38.524399768098149, -8.129347694900488],
              [-38.524427843346416, -8.129401818230114],
              [-38.524461542133778, -8.129459566228023],
              [-38.524498052696998, -8.129519126558344],
              [-38.524535476092346, -8.129574346241366],
              [-38.52456816758837, -8.129639600597098],
              [-38.524594227918456, -8.129709008004486],
              [-38.52463154981637, -8.129773363246505],
              [-38.524675786397452, -8.12982406826743],
              [-38.524725936730796, -8.1298632022985],
              [-38.524788524931552, -8.129900451362177],
              [-38.52484957152803, -8.129936432282591],
              [-38.524897003233924, -8.129971764119061],
              [-38.524936006231172, -8.12999605091775],
              [-38.524993522045826, -8.130024158339543],
              [-38.525037710812761, -8.130038410978475],
              [-38.525100071125657, -8.130037941107568],
              [-38.525157724645005, -8.130026159188242],
              [-38.525215929275952, -8.13000886031169],
              [-38.525278913283451, -8.130018340898342],
              [-38.525319825266195, -8.130040278129842],
              [-38.525354006481273, -8.130073875851505],
              [-38.525379829218863, -8.130113885929298],
              [-38.525405553534654, -8.13016040845906],
              [-38.525422111044485, -8.130206015764172],
              [-38.52541125559469, -8.130251681523202],
              [-38.525346242893278, -8.130269153382681],
              [-38.525280527949143, -8.13026627263605],
              [-38.525228336190928, -8.130264854908027],
              [-38.525191807081718, -8.130298098708838],
              [-38.525211425257787, -8.130365418155673],
              [-38.525250405230636, -8.130409333056603],
              [-38.525300188779987, -8.130451722878751],
              [-38.525353605842653, -8.130491855628948],
              [-38.525407931796884, -8.13053099445791],
              [-38.525458804737951, -8.13057329508149],
              [-38.525508404129816, -8.130617945973668],
              [-38.525561544776608, -8.130661606017133],
              [-38.52561824368545, -8.130689802856462],
              [-38.525669697430509, -8.130701350338432],
              [-38.525723155051608, -8.130707020749703],
              [-38.525776252883794, -8.130709886711895],
              [-38.525826423516641, -8.130731925149133],
              [-38.525860516610109, -8.130763351873767],
              [-38.525887609067851, -8.130804358379883],
              [-38.525903529557546, -8.130851412163064],
              [-38.525930244600474, -8.130904629292546],
              [-38.525960232087392, -8.130953870333405],
              [-38.525986861364693, -8.131002836099714],
              [-38.526024291681964, -8.13105244762389],
              [-38.526061368056062, -8.131094279831025],
              [-38.526099989248138, -8.131134666598472],
              [-38.52614205773866, -8.131176775975389],
              [-38.526183301333766, -8.131225668312586],
              [-38.526213393022047, -8.131263512463333],
              [-38.526243124815437, -8.131298642622371],
              [-38.526275392690629, -8.131338479256639],
              [-38.526306842144287, -8.131379581267842],
              [-38.526334388755728, -8.131420407372287],
              [-38.526360486958183, -8.13145788505091],
              [-38.526401193408432, -8.131500445078469],
              [-38.52645352082876, -8.131541119140659],
              [-38.526496524910982, -8.131559531060031],
              [-38.526544516117603, -8.131582471400767],
              [-38.526592335016382, -8.131597542181366],
              [-38.526643873742024, -8.131614154977937],
              [-38.526695689240903, -8.131626969091933],
              [-38.526747776633343, -8.131640145325408],
              [-38.52679849768716, -8.131657390317594],
              [-38.526852216852056, -8.131672377484504],
              [-38.526902850536125, -8.131686727887212],
              [-38.526953759082389, -8.13169890774725],
              [-38.527002755285139, -8.131716331612283],
              [-38.527050476983433, -8.131736919821067],
              [-38.527092563335394, -8.13176392358554],
              [-38.527137282700025, -8.131790387694688],
              [-38.527184726061044, -8.131816131348842],
              [-38.527229904168415, -8.131838435173737],
              [-38.527275915855355, -8.131846538943828],
              [-38.527328554918384, -8.131853745891556],
              [-38.527381010004881, -8.131863033022773],
              [-38.527428196961885, -8.131875208498398],
              [-38.527475201637934, -8.131888016923254],
              [-38.527529477138444, -8.131893054891165],
              [-38.527587577004091, -8.131887876180121],
              [-38.527642848346488, -8.131895176599295],
              [-38.527685498226084, -8.131906080440338],
              [-38.527640277213287, -8.131920409819319],
              [-38.527594874972202, -8.131934467625435],
              [-38.527529844811866, -8.13196668353654],
              [-38.52748225885864, -8.131985170956755],
              [-38.527428587294594, -8.13200699803337],
              [-38.527377922092676, -8.132019512005593],
              [-38.527318635186795, -8.132030659160389],
              [-38.527268888181752, -8.132034219403412],
              [-38.527206427554397, -8.132042558827747],
              [-38.527150960885137, -8.132047016927036],
              [-38.527095579051888, -8.132056540449005],
              [-38.527040290955604, -8.132063531406581],
              [-38.526980991422462, -8.132085442326016],
              [-38.526924408667014, -8.132112783539151],
              [-38.526872271268743, -8.13214221032222],
              [-38.526828837111282, -8.132180873368641],
              [-38.526787104167759, -8.132239528358832],
              [-38.526756190734723, -8.132283090399156],
              [-38.526722382151519, -8.132318508354725],
              [-38.526688019174557, -8.132362247277083],
              [-38.526682608814063, -8.132409004838671],
              [-38.526716326722521, -8.132450923535641],
              [-38.526772488547707, -8.132472968845384],
              [-38.526797655596297, -8.132530525836449],
              [-38.526817207750462, -8.132577041103602],
              [-38.526838849246289, -8.132622202016989],
              [-38.526867936775773, -8.132665019835994],
              [-38.526921459053121, -8.132693212814011],
              [-38.526971037212533, -8.132678888592606],
              [-38.527019646171851, -8.132717025598156],
              [-38.527062363463727, -8.132747919555211],
              [-38.527113474039595, -8.132742732786721],
              [-38.527143740945107, -8.132708667464453],
              [-38.527155991723319, -8.132711576191607],
              [-38.527165595182936, -8.132727235619747],
              [-38.527184512199206, -8.13277356922811],
              [-38.527227503838525, -8.132802744893308],
              [-38.527276330508847, -8.132810037872281],
              [-38.527221644335121, -8.132845522038451],
              [-38.527193083436686, -8.132895508963871],
              [-38.527197816239159, -8.132962177849652],
              [-38.527190841507078, -8.13302711452145],
              [-38.527198594254195, -8.133072892427784],
              [-38.527234216674948, -8.133116350991973],
              [-38.527275764481693, -8.133138288786521],
              [-38.527329587663971, -8.133142150383479],
              [-38.527378593590619, -8.133151433512639],
              [-38.527417500979496, -8.133180242601412],
              [-38.527473200404749, -8.133209704376423],
              [-38.527426068793694, -8.13322764959471],
              [-38.527350061169493, -8.133254606462152],
              [-38.527362598145977, -8.13332318392157],
              [-38.527326489946908, -8.133306950958987],
              [-38.527270346621322, -8.133268986126925],
              [-38.527254045711317, -8.133314012435129],
              [-38.527243110822006, -8.133350090201979],
              [-38.527216022833834, -8.13330501342358],
              [-38.527174008120411, -8.133294200717682],
              [-38.527124696587947, -8.133313319244017],
              [-38.527070796606417, -8.133297517819916],
              [-38.527013700430288, -8.133298084543526],
              [-38.526974896574821, -8.133335848462348],
              [-38.526958982465842, -8.133360613881992],
              [-38.526928170650741, -8.133394859456276],
              [-38.526910060623869, -8.133434456501654],
              [-38.526891954833118, -8.133393008252824],
              [-38.526877329019044, -8.133325423327586],
              [-38.526860510159253, -8.133270227819748],
              [-38.526858215947648, -8.133214054244586],
              [-38.526817142909664, -8.133174297849697],
              [-38.526766392606383, -8.13318183675784],
              [-38.526711179424019, -8.133202214694842],
              [-38.526671858715822, -8.133216279468893],
              [-38.526690249450034, -8.133169537026529],
              [-38.526719172562252, -8.133120364634534],
              [-38.526720781765185, -8.133064014699977],
              [-38.526695412788563, -8.133023824318702],
              [-38.526649991444444, -8.132976645752967],
              [-38.526613806434767, -8.132948563384076],
              [-38.526564538214402, -8.132930777339579],
              [-38.526501203893098, -8.132909989975674],
              [-38.526456018178898, -8.132894108173645],
              [-38.52640918000413, -8.132894053600433],
              [-38.526362610960135, -8.132896712905502],
              [-38.526315061368969, -8.132884084617658],
              [-38.526262421348932, -8.132877601150023],
              [-38.52620940592687, -8.132881609709003],
              [-38.526164714309957, -8.132908783762733],
              [-38.526131906446885, -8.13294221287746],
              [-38.526102432383063, -8.132996721270102],
              [-38.526090120107852, -8.133046184340499],
              [-38.526078438663831, -8.133099537596063],
              [-38.526066924951017, -8.133164649849871],
              [-38.526054054544346, -8.133225599713516],
              [-38.526048632833472, -8.133281945193803],
              [-38.526035387115769, -8.133353296636622],
              [-38.526019511667194, -8.133422383703282],
              [-38.52603486531865, -8.13348843181036],
              [-38.526061028785819, -8.133547708598057],
              [-38.526095638712505, -8.133603015331143],
              [-38.526132065040812, -8.133657510108627],
              [-38.526169772061529, -8.133703594309539],
              [-38.526219652925185, -8.13374082837111],
              [-38.526265191190369, -8.133765755847788],
              [-38.526274381855863, -8.133823746506007],
              [-38.526271603908882, -8.133870416671106],
              [-38.526297051663711, -8.133920828283111],
              [-38.526327310759889, -8.133970883692328],
              [-38.526367383846036, -8.134011905304272],
              [-38.526403111671755, -8.134042881639887],
              [-38.52644228342244, -8.134078475054777],
              [-38.526465239378361, -8.134086461609702],
              [-38.526444852649675, -8.134132297186781],
              [-38.526440649223133, -8.134156081252073],
              [-38.526387388812999, -8.134136662385162],
              [-38.52632528679424, -8.134148529712721],
              [-38.526276307414143, -8.134193879690176],
              [-38.526247853423278, -8.13422993699975],
              [-38.526239589564746, -8.134233002745017],
              [-38.526188172527483, -8.134189797038486],
              [-38.526138176836575, -8.134173005063259],
              [-38.526084806122817, -8.134170229257245],
              [-38.526027869275019, -8.134189610105135],
              [-38.525977825564844, -8.134213702506271],
              [-38.525929581135514, -8.134251545753436],
              [-38.525896298023767, -8.134303064750355],
              [-38.525875260405336, -8.134362015121859],
              [-38.52585966123484, -8.134427665309142],
              [-38.525841981955296, -8.134486800500929],
              [-38.525828747841771, -8.134548202186467],
              [-38.525815323144414, -8.134617292096257],
              [-38.525798642872616, -8.134675976188966],
              [-38.525781318155047, -8.134742347975708],
              [-38.525765908043354, -8.134801576262316],
              [-38.525765847552648, -8.134853043562526],
              [-38.525801476973932, -8.134890532403636],
              [-38.525854353739781, -8.134927498653404],
              [-38.525878537917869, -8.134971758057508],
              [-38.525904342926658, -8.135027144987118],
              [-38.52592605012358, -8.135093652770063],
              [-38.525949660779339, -8.135162514533036],
              [-38.525977635201926, -8.135225682889359],
              [-38.526001463015824, -8.13526424337523],
              [-38.526044438241648, -8.135307529715348],
              [-38.526080889777596, -8.135340677782251],
              [-38.52612578876569, -8.135369041724978],
              [-38.526174337344777, -8.135381490309948],
              [-38.526227085984679, -8.135373049299544],
              [-38.526282743900154, -8.135360722224023],
              [-38.526340309834225, -8.135346859675359],
              [-38.526394690880572, -8.135339686878241],
              [-38.526450070844305, -8.135332153428593],
              [-38.526508887730508, -8.135335206898779],
              [-38.526566607497429, -8.135344952554457],
              [-38.526627043748114, -8.135360399863666],
              [-38.526682027304048, -8.135381267952255],
              [-38.526736459336185, -8.13540801479286],
              [-38.526791353724107, -8.135427525978473],
              [-38.526844880169563, -8.135452462703912],
              [-38.52690457584653, -8.135480210628115],
              [-38.526961637227885, -8.135509583619141],
              [-38.527019695522171, -8.135540314547985],
              [-38.527077032206648, -8.135567155178729],
              [-38.527135191998376, -8.135588750524304],
              [-38.527190091838854, -8.135603648595549],
              [-38.527249265188146, -8.135612400873031],
              [-38.527300725016147, -8.135619335121564],
              [-38.527354189783878, -8.135619487770503],
              [-38.527405764756885, -8.135605618102002],
              [-38.527444191307467, -8.135580155235536],
              [-38.52748163334185, -8.135542751511379],
              [-38.527526577795122, -8.135532401757024],
              [-38.52756724027445, -8.135535433965373],
              [-38.527621526325632, -8.135531878987475],
              [-38.527713869249887, -8.135508468724435],
              [-38.527790789985225, -8.13547726161538],
              [-38.527861350406802, -8.13545138378884],
              [-38.527925279365981, -8.135429839957579],
              [-38.527973244891299, -8.135397604194562],
              [-38.528023224116502, -8.135351079293542],
              [-38.528063918959717, -8.135326433070441],
              [-38.528101104361532, -8.135275551586211],
              [-38.528085280268513, -8.135223342203114],
              [-38.528060819596028, -8.135182429332239],
              [-38.528101423647385, -8.135157782998709],
              [-38.528158315384189, -8.135177024964255],
              [-38.528211179703618, -8.13514723707193],
              [-38.528223131251124, -8.135095602656087],
              [-38.528234497867551, -8.135078429906391],
              [-38.5282675589254, -8.135061372794866],
              [-38.528317574707458, -8.135061159499601],
              [-38.528364440953951, -8.135037424930712],
              [-38.528387020749271, -8.134979018917862],
              [-38.528373468151763, -8.134924912680757],
              [-38.528400752971088, -8.13487944681386],
              [-38.528447459423468, -8.134837078863116],
              [-38.528434443842798, -8.134789485820253],
              [-38.528380302795476, -8.134746729475724],
              [-38.52833257654963, -8.134729669038853],
              [-38.52828048542267, -8.134719297003855],
              [-38.528251194061461, -8.134695293126343],
              [-38.52828626613605, -8.134666208182789],
              [-38.528335131754432, -8.134640395531143],
              [-38.528365309316001, -8.13460515413885],
              [-38.528363826876983, -8.134553232766534],
              [-38.528327575044813, -8.134504436871003],
              [-38.528287314236685, -8.134468480553343],
              [-38.528264134121343, -8.134419247564407],
              [-38.528239582067641, -8.134378877312441],
              [-38.528235324771821, -8.134371183920001],
              [-38.528234536482223, -8.134346670416861],
              [-38.528273818449748, -8.134365801464739],
              [-38.528313272872076, -8.134392711611701],
              [-38.528353363655519, -8.134418808422501],
              [-38.52841134055145, -8.134441488797139],
              [-38.528443413944231, -8.134415113928576],
              [-38.528467146982685, -8.134379593691513],
              [-38.528481168045992, -8.134343519469896],
              [-38.528484916227697, -8.134320820277116],
              [-38.528493149422772, -8.134343985633558],
              [-38.528504091193852, -8.134379636557831],
              [-38.528518568636827, -8.134419090583091],
              [-38.528539734467017, -8.134483155408953],
              [-38.528545572507973, -8.134536348168364],
              [-38.528556681776422, -8.134584029449284],
              [-38.528579775180432, -8.134629825133455],
              [-38.528623199951674, -8.134677001186768],
              [-38.528674702921414, -8.134724638867535],
              [-38.528718703618289, -8.13474504168148],
              [-38.528766518544174, -8.134763911223988],
              [-38.528816418170265, -8.134785406296835],
              [-38.528867870327701, -8.134798852903781],
              [-38.528915271008813, -8.134783802313844],
              [-38.528951887545475, -8.134753543220512],
              [-38.528988435688973, -8.134704108143445],
              [-38.52900755051482, -8.134658994570104],
              [-38.52901204870895, -8.13461585400662],
              [-38.529005927836877, -8.13457161570679],
              [-38.529029220679249, -8.134602125149971],
              [-38.52909028568979, -8.134623271320018],
              [-38.52913139752583, -8.134630102887499],
              [-38.529165737659334, -8.134605901443688],
              [-38.52917738688155, -8.134579864659795],
              [-38.529176249594599, -8.134621019168222],
              [-38.529178826047094, -8.134668961906831],
              [-38.529179412373978, -8.134711022935782],
              [-38.529214385183678, -8.13476696301664],
              [-38.529259854204163, -8.134773709167696],
              [-38.529213627556707, -8.134793916943599],
              [-38.529171372713492, -8.134833124312504],
              [-38.529130937738167, -8.13486853478601],
              [-38.529095298266895, -8.134917156857417],
              [-38.529071095656398, -8.134966153989341],
              [-38.529043169163714, -8.13501704630294],
              [-38.529016973986977, -8.135062242122075],
              [-38.529008281576033, -8.135121387890772],
              [-38.529012857847583, -8.135166890732593],
              [-38.52899382785403, -8.135217069734212],
              [-38.528967719311574, -8.135265793291156],
              [-38.528947768384342, -8.13532727776707],
              [-38.52894380477202, -8.13537901192138],
              [-38.528959082976634, -8.135432396524974],
              [-38.528988984390985, -8.135478200081659],
              [-38.529024790550622, -8.135520121029355],
              [-38.529058152095125, -8.13555670245159],
              [-38.529091514067957, -8.135592922062388],
              [-38.529127241510658, -8.135624531341719],
              [-38.529166869423975, -8.13565849689944],
              [-38.529206033243973, -8.135701235794768],
              [-38.529240765903701, -8.135729678079736],
              [-38.529250287816389, -8.135737558467339],
              [-38.529304078980324, -8.135769188660708],
              [-38.529360231394733, -8.135799826606553],
              [-38.52943661550335, -8.135839533213332],
              [-38.529489321647645, -8.135867544032426],
              [-38.529530050382071, -8.135891651524462],
              [-38.529580043388428, -8.135911066216179],
              [-38.529643405565757, -8.13590851645441],
              [-38.529695175977622, -8.135882707007044],
              [-38.529723154112098, -8.135865372537333],
              [-38.529766479310354, -8.135842447780925],
              [-38.529800728111326, -8.135818788896975],
              [-38.529776260902636, -8.135861182741502],
              [-38.529763605378498, -8.135893911851475],
              [-38.529746125104225, -8.135938484636778],
              [-38.529729923019964, -8.135976727234823],
              [-38.52971606762393, -8.136026459998693],
              [-38.529736333584808, -8.136084011160643],
              [-38.529747341606594, -8.13614082798734],
              [-38.529755077922033, -8.136200987764601],
              [-38.529785510516561, -8.136258460239112],
              [-38.529841580908993, -8.136281680934541],
              [-38.529887232606811, -8.136287613165102],
              [-38.529935419927831, -8.136298704109196],
              [-38.529986786922883, -8.136307537417059],
              [-38.530039504548981, -8.136325779327143],
              [-38.530086321863578, -8.13634410485858],
              [-38.530140033993611, -8.136365785096471],
              [-38.530182048592565, -8.136377049778121],
              [-38.530141094081465, -8.136390841628774],
              [-38.530097684917436, -8.13640788692137],
              [-38.530053094443069, -8.136426016270972],
              [-38.530011861915206, -8.136444692214365],
              [-38.529953557723609, -8.136468865996479],
              [-38.52989452102095, -8.136498466064181],
              [-38.529850202424363, -8.136516957514486],
              [-38.529809144992754, -8.136541151232953],
              [-38.529770171634247, -8.136568513191925],
              [-38.529720013635796, -8.136612686339538],
              [-38.52968273657504, -8.136664381938349],
              [-38.529658336763227, -8.136726765820406],
              [-38.529643490211839, -8.136770165765286],
              [-38.52963615057012, -8.136836911110086],
              [-38.529631830152113, -8.136883308168962],
              [-38.52963912324752, -8.136934331744468],
              [-38.52964814830149, -8.136979116112219],
              [-38.529659167401938, -8.137026435454503],
              [-38.529678999043988, -8.137067252429265],
              [-38.5297110885828, -8.13710500821365],
              [-38.529742722155305, -8.137144572515092],
              [-38.529775450172615, -8.137179705915523],
              [-38.529821872111874, -8.137225799898951],
              [-38.529834201908358, -8.137238929761763],
              [-38.529867927913692, -8.137274516572301],
              [-38.529917879046678, -8.137330021675691],
              [-38.529950054974101, -8.137371576542318],
              [-38.529997564225376, -8.137419390360595],
              [-38.530041266618646, -8.137462405794473],
              [-38.530076447026822, -8.137496275677067],
              [-38.530123869644434, -8.137540561739486],
              [-38.530170840199794, -8.137583309583786],
              [-38.530207570825048, -8.137611120940608],
              [-38.530245917773101, -8.137654039708389],
              [-38.530271627297495, -8.137714129809071],
              [-38.53027300708392, -8.137776453084086],
              [-38.530279740359802, -8.137840591596813],
              [-38.53029791891452, -8.137897959416623],
              [-38.530250946413922, -8.137856839725131],
              [-38.530215666443432, -8.137830477286712],
              [-38.530173850835304, -8.137804107286351],
              [-38.53012949327816, -8.137778005698639],
              [-38.530083142940654, -8.137748283706603],
              [-38.530020987710792, -8.137727498207767],
              [-38.529958183164332, -8.137718651660087],
              [-38.529894572524704, -8.137700487576845],
              [-38.529841964270211, -8.137666145245978],
              [-38.529806529159323, -8.137617169504804],
              [-38.529776815798229, -8.137565577265745],
              [-38.529716571376916, -8.13754090448303],
              [-38.529655049605651, -8.13752183825896],
              [-38.529612951201784, -8.137504513112575],
              [-38.5295493451504, -8.13748245953178],
              [-38.529489743762866, -8.137451274891829],
              [-38.529439865828458, -8.137410875366768],
              [-38.529410841143488, -8.137391484940334],
              [-38.529385625854417, -8.137374722043404],
              [-38.529331024382735, -8.137337211509108],
              [-38.529278327353353, -8.13730124086473],
              [-38.529230076927945, -8.137266360795239],
              [-38.529194708417513, -8.137238189124393],
              [-38.529192259772486, -8.137236286787118],
              [-38.529151184731674, -8.137197706478364],
              [-38.529115198141177, -8.137154699898828],
              [-38.52908331047702, -8.137099577452686],
              [-38.529063322272009, -8.137037413515378],
              [-38.529052481724932, -8.136992717486075],
              [-38.529042277433021, -8.136947298575242],
              [-38.529031075172654, -8.136901426245485],
              [-38.529024414923065, -8.136852664705794],
              [-38.529013578080466, -8.136804802847456],
              [-38.529003919910423, -8.136758118235988],
              [-38.528989008446317, -8.13670156822724],
              [-38.528978811032054, -8.136650269919944],
              [-38.528965069161373, -8.136602676049479],
              [-38.528927454122673, -8.13655505449802],
              [-38.528876940946716, -8.136514654174841],
              [-38.528855802679551, -8.136504589450537],
              [-38.528820330176785, -8.136487814627037],
              [-38.52879338659347, -8.136474215524993],
              [-38.528765442612816, -8.136462243403267],
              [-38.528752287152869, -8.136456620098311],
              [-38.528695940580633, -8.136436745671443],
              [-38.528641593968111, -8.136414340891614],
              [-38.528599593897866, -8.136390684097732],
              [-38.528538238895599, -8.136384462143365],
              [-38.528482930974221, -8.136407915568286],
              [-38.528439158447071, -8.136425141162551],
              [-38.528381239790853, -8.136430048811508],
              [-38.528316434376237, -8.136424727341531],
              [-38.528251712755299, -8.136425375813479],
              [-38.528232913015636, -8.136433766051619],
              [-38.528193043164187, -8.136451448412155],
              [-38.52815486645914, -8.13649608753345],
              [-38.52814173057665, -8.136551338659102],
              [-38.528139305423615, -8.13660696403363],
              [-38.528121619481091, -8.136671978726206],
              [-38.528118388692207, -8.136717924763666],
              [-38.528125036979709, -8.136776816960671],
              [-38.528139442404061, -8.136877868958962],
              [-38.528143026964479, -8.13691749124817],
              [-38.528141250969519, -8.13696135857116],
              [-38.528141101580083, -8.137011468994912],
              [-38.528145765995696, -8.137059142796582],
              [-38.528138888110099, -8.137118923805232],
              [-38.528131663532157, -8.137164684298041],
              [-38.528129522643908, -8.13721008888627],
              [-38.528129218899672, -8.137236952878554],
              [-38.528128806817669, -8.137278831833379],
              [-38.528130656116218, -8.137327497340483],
              [-38.528131417412332, -8.137375076155536],
              [-38.528136898253749, -8.137423203166703],
              [-38.528132281739218, -8.137489770725994],
              [-38.528128232821139, -8.137536710786755],
              [-38.528127090216543, -8.137582297438293],
              [-38.52812903081599, -8.137630510789133],
              [-38.528139779653095, -8.137675930349074],
              [-38.52815368564621, -8.137738358615742],
              [-38.528162966357812, -8.137797253868264],
              [-38.528162450699213, -8.137850077434795],
              [-38.528152761060959, -8.137907955675596],
              [-38.5281450901143, -8.137947293531667],
              [-38.528170850744409, -8.138041032076902],
              [-38.528095031855173, -8.137983506766361],
              [-38.528058658518432, -8.137960760971223],
              [-38.527999139177375, -8.13793708375492],
              [-38.527943143362606, -8.137927611641675],
              [-38.527885417150245, -8.137923021935734],
              [-38.52782768066843, -8.137927206086081],
              [-38.527773285411925, -8.137946228295007],
              [-38.527753622989849, -8.137993602458211],
              [-38.527743844422403, -8.138049852440023],
              [-38.527726812497221, -8.138098857801459],
              [-38.527688384019058, -8.138125767914172],
              [-38.527639011004212, -8.138119378838047],
              [-38.527588386877213, -8.138096164167573],
              [-38.527531301779419, -8.138086690730102],
              [-38.527482351270287, -8.138107076015929],
              [-38.527447548815331, -8.138138151129988],
              [-38.52740096665741, -8.138151483879208],
              [-38.527378640457577, -8.138148111166174],
              [-38.527352593101305, -8.138144281860278],
              [-38.527306027901624, -8.138143142245967],
              [-38.527296678510218, -8.138142950461065],
              [-38.527278794986856, -8.138144015076669],
              [-38.527244934381855, -8.138145965619715],
              [-38.527198747331092, -8.138131982203131],
              [-38.527167389871501, -8.138089342657436],
              [-38.527135875581479, -8.138025627535491],
              [-38.527079207688715, -8.137970023836088],
              [-38.527038863004556, -8.137928006984257],
              [-38.527000767812581, -8.1379029073328],
              [-38.526939337442379, -8.137883388546625],
              [-38.526891080802606, -8.13785393533986],
              [-38.526857380812253, -8.137796368411257],
              [-38.526838015073906, -8.137745511649152],
              [-38.526813381337703, -8.137697362319388],
              [-38.526785472254375, -8.137655631287593],
              [-38.526756832855995, -8.137617427043821],
              [-38.526706689032835, -8.137571780653284],
              [-38.526669778292749, -8.137542973808234],
              [-38.526636138135117, -8.137511819010911],
              [-38.526582166173988, -8.137479555088694],
              [-38.526532337488355, -8.137474793493949],
              [-38.526474209412974, -8.137503308672031],
              [-38.526429590319957, -8.137545588342496],
              [-38.52639542303659, -8.137576845010557],
              [-38.526369320712114, -8.137619869880037],
              [-38.526343756857614, -8.13766814160962],
              [-38.526324457909134, -8.137715063857847],
              [-38.526313960513399, -8.137764890842591],
              [-38.526299362087194, -8.137828552248539],
              [-38.526278255424643, -8.137868869366434],
              [-38.526260933273321, -8.137932979855254],
              [-38.526244090266303, -8.137975563251061],
              [-38.526225340814072, -8.138018325326895],
              [-38.526198510983178, -8.138062434761004],
              [-38.526172317193605, -8.138106002221049],
              [-38.526148664081163, -8.138150386713672],
              [-38.526124100896837, -8.138196760093649],
              [-38.526093734939636, -8.138237428205894],
              [-38.526066088229427, -8.138281446223836],
              [-38.526049334682966, -8.138325115140974],
              [-38.526035299960967, -8.138372495774357],
              [-38.526014714066825, -8.138433074799181],
              [-38.525971366916494, -8.138474270465496],
              [-38.525929824051751, -8.138524965724692],
              [-38.525913162634396, -8.13856745886183],
              [-38.52589394479098, -8.138622521884569],
              [-38.525874015007531, -8.138665463465857],
              [-38.525836939127281, -8.138699882512851],
              [-38.525805578959442, -8.138736840893722],
              [-38.525771125910389, -8.138779403703383],
              [-38.5257100028305, -8.138807282093572],
              [-38.525652137782131, -8.138843485890861],
              [-38.525606539739229, -8.138868940183615],
              [-38.525563121093931, -8.13889367339825],
              [-38.525522871197758, -8.138925465589123],
              [-38.525488879843685, -8.13896133544114],
              [-38.525453348961058, -8.138994128112859],
              [-38.525409735015799, -8.139030529427927],
              [-38.525373842731874, -8.139061874432871],
              [-38.525339035779723, -8.139096567437653],
              [-38.52529160774823, -8.139134321065555],
              [-38.525248823296096, -8.139160049960113],
              [-38.525188067477572, -8.139184310603435],
              [-38.525122518680632, -8.139193279195426],
              [-38.525082822626786, -8.139217202636679],
              [-38.525042287452273, -8.139259939169271],
              [-38.524999666619088, -8.139300864212187],
              [-38.524955426992101, -8.139328943131162],
              [-38.524910918662968, -8.139353946352704],
              [-38.524862952105316, -8.139386453063347],
              [-38.524818803872556, -8.139413989359534],
              [-38.524771292338755, -8.139445501615628],
              [-38.524725242762031, -8.139468874870735],
              [-38.524676289613254, -8.139491249746778],
              [-38.524626597585552, -8.139524387573795],
              [-38.524578897546718, -8.139561778983534],
              [-38.524537904473178, -8.139607861658581],
              [-38.524517043512155, -8.139670520668112],
              [-38.524523057588404, -8.139727964909705],
              [-38.524519185281584, -8.139778704107126],
              [-38.524499520571311, -8.139827615768098],
              [-38.524473049679187, -8.139874981769387],
              [-38.524465092995747, -8.139925354373775],
              [-38.524476111327132, -8.139972854723942],
              [-38.524482945772007, -8.140027405452738],
              [-38.524502844632913, -8.140087850877055],
              [-38.524508774443042, -8.140139777430045],
              [-38.524524507234574, -8.140192167722365],
              [-38.524546693358893, -8.140237419839583],
              [-38.524569601194997, -8.140286471796582],
              [-38.52460704129691, -8.140328304582722],
              [-38.524626412291546, -8.140374548375743],
              [-38.524639522490126, -8.140418613984286],
              [-38.524655351839307, -8.140466119962166],
              [-38.524657276234713, -8.140527720218916],
              [-38.524639955717134, -8.14059011201784],
              [-38.524627828578616, -8.140636138032427],
              [-38.524621148053441, -8.140681989518118],
              [-38.52462598998887, -8.140732738916007],
              [-38.524635914809402, -8.140783856073302],
              [-38.524644480270261, -8.140833072141236],
              [-38.524659400619498, -8.140881662482126],
              [-38.524674956706029, -8.14092998220999],
              [-38.524693509919416, -8.140976948661438],
              [-38.524722233923157, -8.141020399378183],
              [-38.524764381318683, -8.141073272841115],
              [-38.524809803180354, -8.141120542090906],
              [-38.524845074837529, -8.141153869806955],
              [-38.524879809321071, -8.14118086523195],
              [-38.524927157916196, -8.141210769886065],
              [-38.524969703408694, -8.141233975446951],
              [-38.525014150567927, -8.141261072676368],
              [-38.525055787270311, -8.141285181688442],
              [-38.525105053429428, -8.141305500623993],
              [-38.525145701860723, -8.141320925052202],
              [-38.525214032070728, -8.14133990948541],
              [-38.525274402227112, -8.141335095649593],
              [-38.525339761519547, -8.141333182110321],
              [-38.525404934686343, -8.141335157791328],
              [-38.525453856302526, -8.141339737584918],
              [-38.525508870286728, -8.141335369715565],
              [-38.525563071140205, -8.141327744606164],
              [-38.52562598518778, -8.14132103419206],
              [-38.525673390579648, -8.141302727758069],
              [-38.52572561707332, -8.141275833981908],
              [-38.525778748422987, -8.141251383467308],
              [-38.525828322578093, -8.141241401158279],
              [-38.525885515537809, -8.141236673972319],
              [-38.525941789487668, -8.141241533648543],
              [-38.525988258728241, -8.141247376846376],
              [-38.526044524706641, -8.14125902042143],
              [-38.526086455487267, -8.141264677406044],
              [-38.526134817369751, -8.141282100690377],
              [-38.52617220054784, -8.141295350352058],
              [-38.526227436036429, -8.14133421884164],
              [-38.526171165576081, -8.1413263742824],
              [-38.526132578205726, -8.141333655886566],
              [-38.526095082562257, -8.141338858358472],
              [-38.526046588431342, -8.141356711310113],
              [-38.525996172099838, -8.141388129856036],
              [-38.525952558460531, -8.141424078952243],
              [-38.525908853508113, -8.14146048019804],
              [-38.525856361807278, -8.141481313383611],
              [-38.525802238623797, -8.141500064253929],
              [-38.525757723071855, -8.141531037397948],
              [-38.525742237401232, -8.141577059526449],
              [-38.525763411604203, -8.141633888297395],
              [-38.525774521537649, -8.141680846012855],
              [-38.525756129403383, -8.14172831198325],
              [-38.525741022566692, -8.141760947614348],
              [-38.525741498362386, -8.141819470801826],
              [-38.525737540613335, -8.141865777751164],
              [-38.525742453038632, -8.141933894063511],
              [-38.525761185837872, -8.141982669729011],
              [-38.525699771104193, -8.141949401998017],
              [-38.525670647331879, -8.141936976010726],
              [-38.525620648170012, -8.141922354776277],
              [-38.525570727544206, -8.141918135640607],
              [-38.525526596207897, -8.141931109198961],
              [-38.525499940858211, -8.141980917257454],
              [-38.525482642858577, -8.142024223685755],
              [-38.525459174478847, -8.142065623402994],
              [-38.525427085892005, -8.142104209021605],
              [-38.525374787984973, -8.142114549899661],
              [-38.525322677161427, -8.142120187470804],
              [-38.525266040752747, -8.14211469411269],
              [-38.52520852004595, -8.14208930021058],
              [-38.525149177160372, -8.142069602663245],
              [-38.52508929219627, -8.142047733618728],
              [-38.525027865791451, -8.142024415525889],
              [-38.524970250929805, -8.142001915954276],
              [-38.524916541688363, -8.141977430994828],
              [-38.524861376343708, -8.141956110154338],
              [-38.524803674460294, -8.141930444628494],
              [-38.524750232015627, -8.141910663478688],
              [-38.524699955670329, -8.141900202613192],
              [-38.524638507448763, -8.141895427154756],
              [-38.524590192929011, -8.141914817823594],
              [-38.524544158627755, -8.141924985024636],
              [-38.524498757286302, -8.141937233363894],
              [-38.524450348994399, -8.141959156569163],
              [-38.524411459234919, -8.141991854738768],
              [-38.524379921390569, -8.142025194682768],
              [-38.524357893860994, -8.142076274410906],
              [-38.524338680630621, -8.142127086077762],
              [-38.524326997237729, -8.142181615108695],
              [-38.524323664170623, -8.14223687754072],
              [-38.524327414122482, -8.142289886964608],
              [-38.524347597219737, -8.142340292525882],
              [-38.524360248020216, -8.1423890611209],
              [-38.524375162511483, -8.142442626337903],
              [-38.524394440978384, -8.142490407721581],
              [-38.524430791646893, -8.142532510610536],
              [-38.524438992944901, -8.142582630775275],
              [-38.524447014190869, -8.14263148439724],
              [-38.524479191628245, -8.142671592444113],
              [-38.524519457012779, -8.142704021518581],
              [-38.524554805893317, -8.142748927244876],
              [-38.524580545554308, -8.142782967442852],
              [-38.524635865552753, -8.142827172932003],
              [-38.524578686764031, -8.142819688888027],
              [-38.524524220204199, -8.142821524596599],
              [-38.524477457044981, -8.142833861784482],
              [-38.524424517631637, -8.142849267108687],
              [-38.524381104348357, -8.142869025295381],
              [-38.524339408826819, -8.142894664887276],
              [-38.524300161846554, -8.14292229729363],
              [-38.524258008174911, -8.142951554430288],
              [-38.52421829033851, -8.142993568186442],
              [-38.524215770364968, -8.143052087848636],
              [-38.524213803148186, -8.143103733786416],
              [-38.524192515906989, -8.143065899789878],
              [-38.524163518973928, -8.14302290097643],
              [-38.524132247360249, -8.142984422107979],
              [-38.524101879112884, -8.142949652839231],
              [-38.524066792444529, -8.142913340348326],
              [-38.524021334170023, -8.142896824739971],
              [-38.523969981472959, -8.142875327340525],
              [-38.523924446107607, -8.142847233741847],
              [-38.523889181976614, -8.142807483842649],
              [-38.523872629016985, -8.1427578061373],
              [-38.523856528434457, -8.142709395293604],
              [-38.523834429731387, -8.142666947263448],
              [-38.523797632407231, -8.142618783498767],
              [-38.52375510661772, -8.142578844166945],
              [-38.523717286111676, -8.142551573667768],
              [-38.523684747804964, -8.142509656096541],
              [-38.523658921633768, -8.142472088106908],
              [-38.523626374788009, -8.142437406702289],
              [-38.523590928211817, -8.142398470636374],
              [-38.523547935126892, -8.142369927721273],
              [-38.52349576630877, -8.142347796142934],
              [-38.523440694020223, -8.14232457572292],
              [-38.523389799255071, -8.142299732059328],
              [-38.52335771144898, -8.142260709465528],
              [-38.523327070466792, -8.142226392085501],
              [-38.523293704460151, -8.142193609192228],
              [-38.523289044340885, -8.142142769551704],
              [-38.523312881169872, -8.142097028697165],
              [-38.523349595877029, -8.142061433612779],
              [-38.523379151435158, -8.142015699467274],
              [-38.523369975187791, -8.141945769098415],
              [-38.523328815393107, -8.1419022132403],
              [-38.523289551692407, -8.141867252569229],
              [-38.523254548689735, -8.141837090867424],
              [-38.52321574240019, -8.141799145802388],
              [-38.523174207560459, -8.141765720142519],
              [-38.523126193991764, -8.141760869782606],
              [-38.523102177864118, -8.141804710918915],
              [-38.523071361627593, -8.141841850598631],
              [-38.523027409427961, -8.14185681404134],
              [-38.522981386342302, -8.141857483589753],
              [-38.52293163618608, -8.141862671366338],
              [-38.522911450721615, -8.141891140107521],
              [-38.522905330565585, -8.141923514822681],
              [-38.522935863909566, -8.141972033109086],
              [-38.522981291312746, -8.142014508591933],
              [-38.523024273936571, -8.142051825419529],
              [-38.523036022074614, -8.142096160826021],
              [-38.52302407656893, -8.142141915633028],
              [-38.52299625690037, -8.142178154302396],
              [-38.522960358580825, -8.142214202572816],
              [-38.522921106337442, -8.142246266999308],
              [-38.522878966993197, -8.142263403407229],
              [-38.522822939587449, -8.142280252130712],
              [-38.522778805141506, -8.142295758042264],
              [-38.52272847376922, -8.142331789324482],
              [-38.522679232636911, -8.142367007811506],
              [-38.522647151375935, -8.142399171092432],
              [-38.522613885766312, -8.142434951067663],
              [-38.522576807759933, -8.142470636104298],
              [-38.522543363804658, -8.142503702295892],
              [-38.522513178485539, -8.142544551210072],
              [-38.522491707872156, -8.142585048562932],
              [-38.522473671014183, -8.142638755981144],
              [-38.522455460854516, -8.142685498371403],
              [-38.522437057247735, -8.142742280732067],
              [-38.522418110599851, -8.142797705668972],
              [-38.522405603852299, -8.142857389436193],
              [-38.522389034143821, -8.142822003115372],
              [-38.522373381607991, -8.142778658075695],
              [-38.522361531393571, -8.142743910472149],
              [-38.522334528921597, -8.142703265644622],
              [-38.52230171414476, -8.142664965734657],
              [-38.522273805446716, -8.142623053505814],
              [-38.522246897088401, -8.142579604764441],
              [-38.522213628675217, -8.142541123409817],
              [-38.522195218110539, -8.142527172097061],
              [-38.522168195512002, -8.142503532257635],
              [-38.522135193965276, -8.142469664275621],
              [-38.522089408338921, -8.142423117928578],
              [-38.522048692217588, -8.142388245884115],
              [-38.522004715864213, -8.142346947888338],
              [-38.521955297923931, -8.142301573130075],
              [-38.521897805855367, -8.142252208959757],
              [-38.521842122408373, -8.142208635855257],
              [-38.521783804605391, -8.142166687781057],
              [-38.521731650946762, -8.142131892687352],
              [-38.521677132137825, -8.142101346056045],
              [-38.521634491055266, -8.142082300846864],
              [-38.521588589210012, -8.142057281943574],
              [-38.521544582237858, -8.142041853205425],
              [-38.521472355617036, -8.142017165075023],
              [-38.521395861141357, -8.141993738232534],
              [-38.521323810287221, -8.141973934704481],
              [-38.521247942866587, -8.141957563848399],
              [-38.521172257644785, -8.141940650480011],
              [-38.521101464857217, -8.141931612212764],
              [-38.5210363914562, -8.141922037967081],
              [-38.520956985286453, -8.141904487004226],
              [-38.520883374445361, -8.141898882559179],
              [-38.520805138668827, -8.141889473648808],
              [-38.520721286332275, -8.141870470157494],
              [-38.520640521597436, -8.141850384868002],
              [-38.520559839254553, -8.141837354933662],
              [-38.520488510793207, -8.141820808394151],
              [-38.52043072503325, -8.141789624578868],
              [-38.520375125058301, -8.141752383038948],
              [-38.520328055978979, -8.141716689258281],
              [-38.520310956494555, -8.141669000735718],
              [-38.520303667982326, -8.141615082600513],
              [-38.520278218308704, -8.141566570141292],
              [-38.520256580913305, -8.141518243089568],
              [-38.5202605552129, -8.141458549319008],
              [-38.520292215101946, -8.141399159614991],
              [-38.520336452138771, -8.14137370434959],
              [-38.520394907142126, -8.141376396515117],
              [-38.520431909800358, -8.141404118601487],
              [-38.520484801806603, -8.141428512717827],
              [-38.520539651159183, -8.141409944331754],
              [-38.520575264271471, -8.141384840664859],
              [-38.520585308297854, -8.141335284704118],
              [-38.52055304306537, -8.141293095869091],
              [-38.520505788385542, -8.141260839075816],
              [-38.520451178158886, -8.141231015800399],
              [-38.52036733394128, -8.14120531878908],
              [-38.520312439574937, -8.141185444904913],
              [-38.520263987868098, -8.141167478122313],
              [-38.520221278249551, -8.141129889986665],
              [-38.520167580426495, -8.141096178307611],
              [-38.520105982526715, -8.141064809042975],
              [-38.520043515668384, -8.14107712716512],
              [-38.520000633357853, -8.14110837294333],
              [-38.519952754194421, -8.141143230897836],
              [-38.519890192023119, -8.141159347875863],
              [-38.519820121830485, -8.141153476076258],
              [-38.519742053989802, -8.14115564505266],
              [-38.519668798326173, -8.141156824732228],
              [-38.519605328517436, -8.141172850139681],
              [-38.519541929859621, -8.141205337923997],
              [-38.519475523378468, -8.14124813369053],
              [-38.519411470847501, -8.141296088007502],
              [-38.519358865678775, -8.141335734260888],
              [-38.519311779994943, -8.141390221203606],
              [-38.519265051244361, -8.141449864338577],
              [-38.519222500649533, -8.141507341561519],
              [-38.519193469038846, -8.141570081016406],
              [-38.519170427608387, -8.141633551180107],
              [-38.519154554366999, -8.141699472040759],
              [-38.519148683904412, -8.141750570585032],
              [-38.51915424810403, -8.141804034428768],
              [-38.519122889973637, -8.141762479727166],
              [-38.519073092518887, -8.141731214733889],
              [-38.519039986504424, -8.14170910518037],
              [-38.519008604111733, -8.141687902188234],
              [-38.518957359263801, -8.141652474665655],
              [-38.518902478087291, -8.141621565438506],
              [-38.518851051062818, -8.141586680400769],
              [-38.518793002332139, -8.141547807612223],
              [-38.518739215108184, -8.141513010216229],
              [-38.518692043883583, -8.141486994519839],
              [-38.518639174347449, -8.141443876596776],
              [-38.518578670238689, -8.141408799861129],
              [-38.518513175004863, -8.141372541319882],
              [-38.518445750096284, -8.141355908607341],
              [-38.518371237300897, -8.141345599130327],
              [-38.518301978562953, -8.141344341092811],
              [-38.518232980905417, -8.141352490381683],
              [-38.518172501897162, -8.141372408553007],
              [-38.518105133506694, -8.141384449197474],
              [-38.518042934334069, -8.141400566315079],
              [-38.517979912788626, -8.141421205056288],
              [-38.517906913727209, -8.141435500287365],
              [-38.517837636698012, -8.141449528568385],
              [-38.517772256215984, -8.141469169500894],
              [-38.517697512317731, -8.141500286728151],
              [-38.517693063118827, -8.141501366869383],
              [-38.517622511348044, -8.141518649877112],
              [-38.517619060981772, -8.141519459847624],
              [-38.51759654017544, -8.141526940622635],
              [-38.517563757851683, -8.141537846386928],
              [-38.517502829142337, -8.141554236285204],
              [-38.517449322851078, -8.14158818271954],
              [-38.517459554332092, -8.14153401402011],
              [-38.517458598695939, -8.141498193818814],
              [-38.517455013985419, -8.141459566475131],
              [-38.517444894669197, -8.14142002684345],
              [-38.517428245729612, -8.141375414128683],
              [-38.517409516475198, -8.141324557739976],
              [-38.51737900292197, -8.141260119515954],
              [-38.517356027252127, -8.141192614875651],
              [-38.517337483459166, -8.141138502425916],
              [-38.517307490421473, -8.141094235653497],
              [-38.517264509867069, -8.141055742378935],
              [-38.517218710256152, -8.141021497001111],
              [-38.517161192874582, -8.140993931146662],
              [-38.517096126037771, -8.140979110064109],
              [-38.517026248760708, -8.140963830988818],
              [-38.516946752983117, -8.140945645989651],
              [-38.516871345373708, -8.140924661821924],
              [-38.51681461247238, -8.140924232507819],
              [-38.516758974380672, -8.140919191427537],
              [-38.516689623771072, -8.140918927984032],
              [-38.516622889214084, -8.14093232592173],
              [-38.516557317895732, -8.140959745320515],
              [-38.51649274616048, -8.140986261376671],
              [-38.516424815911776, -8.141012682970116],
              [-38.516355082123106, -8.141029152664334],
              [-38.516293057119441, -8.141051420468584],
              [-38.516233754767597, -8.141074143767483],
              [-38.516168444976813, -8.141110518176962],
              [-38.51611774328326, -8.14115224666191],
              [-38.516081475090537, -8.141193540081083],
              [-38.516050005611284, -8.141245060310995],
              [-38.516008362335334, -8.141302538254134],
              [-38.515968809985239, -8.141357395571822],
              [-38.515923367777212, -8.141403562455025],
              [-38.515862989650977, -8.141414977891991],
              [-38.51581767795669, -8.141428039425326],
              [-38.515773969653772, -8.141466519923872],
              [-38.515717725044766, -8.141512945255249],
              [-38.515663753236844, -8.141556478819949],
              [-38.515615330336345, -8.141590250163103],
              [-38.515578415117659, -8.141641130688326],
              [-38.515547201880501, -8.14170594764583],
              [-38.515521803572412, -8.141766248930882],
              [-38.515518346243915, -8.141772847813035],
              [-38.51549259809515, -8.141822113514689],
              [-38.515477399998531, -8.141854477250835],
              [-38.515458559216611, -8.141896605472116],
              [-38.515427850351621, -8.141994890317651],
              [-38.515407051665299, -8.142079980973298],
              [-38.51539191485751, -8.142136857312494],
              [-38.515381498707235, -8.142193377476971],
              [-38.515380344724448, -8.142247195132278],
              [-38.515384896756942, -8.14231160250079],
              [-38.515388801413486, -8.142385958833463],
              [-38.515396529112088, -8.142451545870278],
              [-38.515413078713053, -8.142503123352984],
              [-38.515400582805142, -8.142552947574645],
              [-38.515354993101575, -8.142570621775526],
              [-38.51530894909078, -8.142588657237168],
              [-38.515248726758657, -8.142621329012702],
              [-38.515178244704124, -8.142655888021586],
              [-38.515110847203317, -8.142691897936079],
              [-38.515046620785924, -8.142732886494867],
              [-38.514978842861467, -8.142783277823046],
              [-38.514910335791448, -8.142836110475468],
              [-38.514842838101814, -8.142879899106907],
              [-38.514779338036426, -8.14292070758348],
              [-38.514726834433823, -8.142950855841493],
              [-38.514682600493686, -8.142973325546409],
              [-38.514613604792402, -8.143582619132957],
              [-38.514647251303948, -8.14360807641587],
              [-38.514687347342281, -8.143629742417271],
              [-38.514763468326628, -8.143661672572398],
              [-38.51482771252887, -8.143681467931369],
              [-38.514908544091227, -8.143721453931404],
              [-38.514981492922487, -8.143749309910165],
              [-38.515051675332067, -8.143737634983989],
              [-38.515126959577842, -8.143710589274805],
              [-38.515192612637826, -8.143691130028596],
              [-38.515259439661222, -8.143676737493594],
              [-38.515318119759954, -8.143643430720676],
              [-38.515322296230813, -8.143642712090282],
              [-38.515362110202254, -8.143672518383818],
              [-38.515364829939926, -8.143675416099759],
              [-38.515419308873831, -8.143663360551127],
              [-38.515460284889556, -8.143632655734077],
              [-38.51550897581258, -8.143602683737905],
              [-38.515516151192713, -8.143599164668052],
              [-38.51555012042585, -8.143582742918253],
              [-38.51561058911691, -8.143571779917087],
              [-38.515652666909247, -8.143606201944282],
              [-38.515692201299728, -8.143642068167463],
              [-38.515744883899423, -8.143689618400755],
              [-38.515798308894404, -8.143723692142341],
              [-38.515869361756934, -8.143743043200802],
              [-38.515813957587781, -8.143769660503251],
              [-38.515779787999953, -8.143801730258312],
              [-38.515761482334895, -8.143851728461987],
              [-38.515747889087613, -8.143908244841354],
              [-38.51573592226908, -8.143971004358839],
              [-38.515716600359447, -8.144035745047647],
              [-38.515701177813639, -8.144103746664937],
              [-38.515679036895229, -8.144172644786311],
              [-38.515650737246339, -8.144229957681478],
              [-38.515616733715987, -8.144274871822573],
              [-38.515581650891349, -8.14431146307448],
              [-38.515554826654068, -8.144349601866466],
              [-38.515570023534487, -8.144393851105082],
              [-38.515613636302177, -8.14443514933507],
              [-38.515677306680836, -8.144479365963969],
              [-38.515756518796259, -8.144507500643492],
              [-38.515841470666722, -8.144518184889618],
              [-38.515887671657488, -8.144520772658479],
              [-38.515934507090314, -8.144524175248186],
              [-38.515984510276418, -8.144535360495372],
              [-38.516008652286366, -8.144538645561774],
              [-38.516034700336476, -8.144542113803727],
              [-38.516035698526132, -8.144542386350409],
              [-38.516090056270222, -8.144555747628827],
              [-38.516105935663319, -8.14456083188146],
              [-38.516139872161538, -8.144571726598258],
              [-38.516196583309302, -8.14459069870203],
              [-38.516248118490026, -8.144611202315772],
              [-38.516292927377215, -8.144639205436219],
              [-38.516366120146344, -8.144690940840198],
              [-38.516416434582545, -8.144745362538465],
              [-38.516472106989383, -8.14479789111898],
              [-38.516515434985628, -8.144849681370198],
              [-38.516562125111612, -8.144898581153026],
              [-38.516629167014067, -8.144932218755022],
              [-38.51669407330477, -8.14493003474773],
              [-38.516763988990405, -8.144913836609321],
              [-38.516828927922091, -8.144884426518114],
              [-38.516890879211253, -8.144848409851747],
              [-38.516963709734497, -8.14482380309588],
              [-38.517035187850581, -8.144791506282981],
              [-38.517093769298882, -8.14476480214682],
              [-38.517160508821753, -8.144747786088892],
              [-38.517224189865068, -8.144707520174338],
              [-38.517282427261904, -8.144664896018302],
              [-38.517326140778614, -8.144622254581321],
              [-38.517366960886882, -8.144570112220562],
              [-38.517398075407343, -8.144511898019292],
              [-38.517421943150858, -8.144441011894886],
              [-38.517422038896946, -8.144361052288172],
              [-38.51744414272688, -8.1443987066717],
              [-38.51748603532225, -8.144436384579439],
              [-38.517548648793984, -8.14445373537448],
              [-38.517612857725503, -8.144427399655875],
              [-38.517670076175101, -8.144401779237025],
              [-38.517645975994533, -8.144439378702138],
              [-38.517633057485384, -8.144463242723706],
              [-38.517627813744774, -8.144521397240267],
              [-38.517637640029982, -8.144578303336152],
              [-38.51764400812926, -8.144642531948127],
              [-38.517667800978749, -8.144710037545856],
              [-38.517687506722595, -8.14477943778124],
              [-38.517692508562725, -8.14484755421236],
              [-38.517699318743979, -8.144921642635646],
              [-38.517707495520575, -8.144991571882663],
              [-38.517709402920076, -8.145066468548901],
              [-38.517732371147169, -8.14514039526793],
              [-38.517753709774155, -8.145210701962473],
              [-38.517767244521742, -8.145278738079245],
              [-38.517796490919793, -8.145340370759454],
              [-38.517844723886135, -8.145389724499697],
              [-38.517889060925448, -8.145432922858788],
              [-38.517931308432267, -8.145477565966262],
              [-38.517982638905238, -8.145517606797029],
              [-38.518043127324752, -8.14556625145441],
              [-38.518096261301174, -8.145616334605217],
              [-38.51815838606867, -8.145662991241986],
              [-38.518233699422773, -8.145687683647735],
              [-38.518308399856544, -8.145693561259488],
              [-38.518379880848066, -8.145658912460524],
              [-38.51844356365968, -8.145617199124899],
              [-38.518500073551067, -8.14557692450296],
              [-38.51856274971432, -8.145541993866379],
              [-38.518617982042798, -8.145507325746403],
              [-38.518687641222883, -8.145477920955209],
              [-38.518759645835978, -8.145460820429916],
              [-38.518834826358919, -8.145444718630898],
              [-38.518892561565039, -8.145442525773817],
              [-38.518950484798637, -8.145434906000153],
              [-38.519038848540234, -8.145401272064165],
              [-38.518974964727832, -8.145459357106901],
              [-38.518942217055638, -8.145517026786152],
              [-38.518931153335238, -8.145584038741974],
              [-38.518934877095361, -8.145658394853449],
              [-38.518928084734945, -8.14572125107116],
              [-38.518924034720087, -8.145768100502437],
              [-38.518922285275316, -8.14578881197912],
              [-38.518935540461456, -8.145863088936636],
              [-38.518959065155826, -8.145927337874602],
              [-38.518996130375491, -8.145978939555171],
              [-38.519042276079077, -8.146028019333633],
              [-38.519082440391507, -8.146068951322142],
              [-38.519127044352089, -8.146116853386459],
              [-38.519173108595666, -8.146158154166955],
              [-38.519225991085769, -8.146190960515018],
              [-38.519279802482181, -8.146206039328122],
              [-38.51934126031113, -8.146203217658037],
              [-38.519406163365296, -8.146203927694094],
              [-38.519452643284012, -8.146201178720403],
              [-38.519477880445372, -8.146199670918204],
              [-38.519479968583859, -8.146199402034256],
              [-38.519551873683547, -8.146189718336233],
              [-38.51955278153698, -8.146189628958938],
              [-38.519632302422629, -8.146187733151745],
              [-38.519712812771765, -8.146193436484296],
              [-38.519779246167239, -8.146204912083107],
              [-38.51986780288938, -8.146161780721359],
              [-38.519801796319406, -8.146249169891606],
              [-38.519786064612262, -8.146272397489225],
              [-38.519763244615561, -8.146302219707767],
              [-38.519726681424771, -8.146362417596427],
              [-38.519692843617669, -8.146420809662992],
              [-38.519660636292919, -8.146482098126196],
              [-38.519666806357243, -8.146560437028079],
              [-38.519699117368823, -8.146640615916164],
              [-38.519743609666484, -8.146706155964418],
              [-38.519799925220141, -8.146752805492241],
              [-38.519854600482404, -8.146804789751414],
              [-38.51991227660578, -8.146852345396836],
              [-38.51993341040793, -8.146866390490935],
              [-38.519964521455478, -8.146887140850009],
              [-38.520019215938973, -8.146923024620879],
              [-38.52006481798125, -8.146971470485635],
              [-38.520116964004835, -8.147013049718398],
              [-38.520183547319974, -8.147051208834121],
              [-38.520250224869642, -8.147086473580654],
              [-38.520317326796444, -8.147146522720748],
              [-38.520451420770542, -8.147282721331937],
              [-38.520562553935491, -8.147338299862492],
              [-38.520422695658077, -8.147316516503095],
              [-38.520268904367327, -8.147332073379973],
              [-38.520138789460042, -8.147360954672411],
              [-38.520029895480164, -8.147406956490238],
              [-38.519958495800161, -8.147449384552006],
              [-38.5199025306338, -8.147489479106877],
              [-38.519837241196448, -8.147508125449658],
              [-38.519754813743369, -8.147511284172461],
              [-38.519673840952457, -8.147512545104604],
              [-38.519591610904932, -8.147502407556303],
              [-38.519529931255065, -8.147462716462966],
              [-38.519470892071759, -8.147416335026271],
              [-38.519413949571195, -8.147362538985954],
              [-38.519350459644748, -8.147318504018196],
              [-38.519275152710833, -8.14728811332046],
              [-38.519198560584542, -8.147269751226723],
              [-38.519121843789549, -8.147279790956185],
              [-38.519062082766411, -8.147305860805261],
              [-38.519013284861195, -8.147349220007664],
              [-38.5189685686804, -8.14739520715591],
              [-38.518933905705204, -8.147460291627912],
              [-38.518909759854864, -8.147536061923608],
              [-38.518905844800322, -8.14762189639789],
              [-38.518908281284929, -8.147709999707521],
              [-38.518927876604231, -8.14779613341487],
              [-38.518957734250606, -8.147878299393305],
              [-38.518988783107318, -8.147951150207975],
              [-38.519034740560102, -8.148006018711571],
              [-38.519099404472662, -8.148055210897194],
              [-38.519158626868872, -8.148100235823822],
              [-38.519211849168343, -8.148152761162851],
              [-38.519278875327856, -8.148200237530085],
              [-38.519348176707148, -8.148242832164852],
              [-38.519420579776188, -8.148272586253039],
              [-38.519494061798838, -8.148311025016202],
              [-38.519563995302377, -8.1483564243826],
              [-38.519627396793361, -8.148398650176988],
              [-38.519693976288124, -8.148440156107172],
              [-38.519758734364245, -8.148486634741097],
              [-38.519815225237103, -8.148538711615679],
              [-38.519869631316126, -8.148588253354534],
              [-38.519935387208619, -8.148635366308687],
              [-38.520003782493852, -8.14867669343595],
              [-38.52006319396515, -8.148715567715049],
              [-38.520122410750943, -8.148765476922726],
              [-38.520176273002448, -8.148814384815125],
              [-38.520229250463998, -8.148844025338422],
              [-38.520278584430152, -8.148884154000575],
              [-38.520328554047992, -8.148924102504445],
              [-38.520385235331815, -8.148968852903879],
              [-38.52044465431549, -8.149001485938895],
              [-38.520500718933576, -8.14903049691307],
              [-38.52055749813357, -8.149069277561269],
              [-38.520612744823879, -8.149099101623937],
              [-38.52067306158802, -8.149140147742326],
              [-38.520708695462488, -8.149174471227337],
              [-38.520748383770716, -8.149234397470419],
              [-38.52077443707298, -8.149310046283974],
              [-38.520793233418814, -8.149381435270124],
              [-38.520817112757555, -8.149453192068849],
              [-38.520832196152206, -8.149517159587438],
              [-38.520842743689599, -8.149578679538411],
              [-38.520862370917143, -8.149638401170364],
              [-38.520875010383754, -8.14969648640821],
              [-38.52089228370199, -8.149750959031341],
              [-38.520900938204463, -8.149801351125223],
              [-38.520867195215992, -8.149856215749624],
              [-38.520847616411046, -8.149908383536983],
              [-38.520853720696671, -8.149965918341902],
              [-38.520861006786127, -8.150022007307667],
              [-38.520868115738345, -8.150074387524196],
              [-38.520884668568463, -8.150124065330196],
              [-38.520905851274321, -8.150173477247817],
              [-38.520933117556581, -8.150221539567157],
              [-38.520953852205658, -8.150266066535121],
              [-38.521002196383783, -8.150299048228277],
              [-38.521040484174883, -8.150315465289649],
              [-38.521089763981209, -8.150325020959748],
              [-38.521040445339473, -8.15034811848532],
              [-38.52100901753856, -8.15036445321865],
              [-38.520972057270107, -8.150376711066782],
              [-38.52094733221972, -8.150405355209257],
              [-38.52094867114922, -8.150424442203011],
              [-38.520969157261071, -8.150448978942627],
              [-38.52100540012632, -8.150505644817954],
              [-38.520959597424074, -8.150472847030141],
              [-38.520894621614104, -8.150456398436436],
              [-38.520828246830582, -8.150471335089483],
              [-38.520803514552874, -8.150506039510272],
              [-38.520804918377948, -8.150470583910822],
              [-38.520779004364833, -8.150430392521592],
              [-38.520731299818301, -8.150393883922625],
              [-38.520688384636031, -8.150375652320362],
              [-38.520646021862909, -8.150350908809047],
              [-38.520603201125972, -8.150329601935443],
              [-38.52052708850858, -8.15028917029221],
              [-38.52047010293132, -8.15027110293904],
              [-38.520416920558695, -8.150260909415076],
              [-38.520362738904694, -8.15025134786797],
              [-38.520305928535691, -8.150238617378402],
              [-38.520245948895088, -8.150219280123094],
              [-38.520184799525261, -8.150191258065972],
              [-38.5201372683158, -8.150161714424481],
              [-38.520084952207213, -8.150110185236246],
              [-38.520052137739434, -8.150071342423404],
              [-38.520031135941821, -8.150022563846536],
              [-38.520005516245682, -8.149964010968501],
              [-38.519965617002882, -8.149928777852768],
              [-38.519925820618553, -8.149883414210963],
              [-38.519875385515085, -8.149853143487197],
              [-38.519817877381129, -8.149816623184016],
              [-38.519757178098345, -8.149791857878302],
              [-38.519698215097627, -8.149757416236802],
              [-38.519637974995092, -8.149728219301087],
              [-38.519569925632076, -8.149701183959893],
              [-38.519503777812751, -8.149678130755989],
              [-38.519443263252988, -8.149650742511151],
              [-38.51936783887971, -8.149642331548053],
              [-38.519300012432495, -8.14965672352295],
              [-38.519229115383958, -8.149657905829576],
              [-38.51915641449208, -8.14964931715101],
              [-38.519083538101846, -8.14963566292991],
              [-38.519018294081974, -8.149615957450791],
              [-38.5189515783254, -8.149612441154483],
              [-38.518885924969616, -8.149631448697209],
              [-38.518828605107657, -8.14966539072207],
              [-38.51879433968665, -8.149701440488538],
              [-38.518766497801153, -8.149755497853736],
              [-38.518757974199218, -8.149823688674317],
              [-38.518771964077239, -8.14989091123115],
              [-38.518825243487356, -8.150123707860846],
              [-38.518832972899446, -8.150264370146779],
              [-38.518827964463391, -8.150353550039322],
              [-38.518778337850009, -8.150254446043563],
              [-38.518691499275107, -8.150150323018272],
              [-38.518593136700048, -8.150042477760476],
              [-38.518490745994697, -8.149963120130513],
              [-38.518432610450475, -8.149919995884847],
              [-38.518376010852883, -8.149882933749939],
              [-38.518310873729739, -8.149849750912074],
              [-38.51825009470052, -8.149815849623042],
              [-38.518177776932376, -8.149790527574366],
              [-38.51810626554564, -8.149774070782751],
              [-38.518029027525209, -8.149764119729754],
              [-38.51793471291893, -8.149763193583548],
              [-38.517860803816212, -8.149778120797471],
              [-38.517792848085058, -8.149824622876617],
              [-38.517735466264561, -8.149910212845116],
              [-38.517743087053191, -8.149838041088197],
              [-38.517731085851054, -8.149777966590127],
              [-38.517696938938137, -8.149714971291623],
              [-38.517665425602821, -8.149651074600708],
              [-38.517591749759028, -8.149547057464238],
              [-38.517544857616706, -8.149514710276462],
              [-38.517518683373765, -8.1494647495572],
              [-38.517512229797845, -8.149396088698111],
              [-38.517518319134375, -8.149314146291381],
              [-38.517513503630838, -8.149242050198477],
              [-38.517480177671366, -8.149175799583535],
              [-38.51744256849139, -8.149123563954776],
              [-38.517403769814834, -8.14907919624503],
              [-38.517362530947359, -8.149025870865696],
              [-38.517318655817341, -8.148975708171266],
              [-38.517259235599766, -8.148944341021627],
              [-38.517201991718359, -8.148914875948972],
              [-38.517143839443733, -8.148885952500432],
              [-38.517079058424727, -8.148858830195939],
              [-38.517007248358595, -8.148864533642447],
              [-38.516943138556201, -8.148883271354473],
              [-38.516895796978581, -8.148923013923207],
              [-38.516858247715241, -8.148972265623682],
              [-38.516815716334115, -8.149012737527782],
              [-38.516757763909936, -8.149044236294756],
              [-38.516711152838134, -8.149080361623998],
              [-38.516662713663386, -8.149126977217639],
              [-38.516598516721473, -8.149142639395002],
              [-38.516590803436301, -8.149140459352012],
              [-38.516545431370517, -8.149127470624849],
              [-38.516491139561055, -8.149134280279918],
              [-38.516483059267003, -8.149135356074495],
              [-38.516416139497316, -8.149150653168526],
              [-38.516340564284292, -8.149116824420744],
              [-38.516334749379766, -8.149045631655204],
              [-38.51633429661829, -8.148969108606465],
              [-38.516324766126544, -8.148892755638547],
              [-38.516327139015573, -8.148806557579427],
              [-38.516315157817083, -8.148730020784797],
              [-38.516293280401833, -8.148654919424532],
              [-38.516261584732277, -8.148591926942764],
              [-38.516216069864669, -8.148546737050953],
              [-38.516152621803172, -8.148543586010845],
              [-38.516099574514918, -8.148572376971501],
              [-38.516046522328992, -8.148605238264945],
              [-38.516002636676937, -8.148639557721388],
              [-38.515945591241234, -8.14867169061937],
              [-38.51589100835595, -8.148694148069055],
              [-38.515830971132047, -8.148723111554963],
              [-38.515773753364471, -8.148747555790274],
              [-38.515729044826401, -8.148786758647514],
              [-38.515687150307805, -8.148825964858707],
              [-38.515644980518999, -8.14886761294394],
              [-38.51561151697446, -8.148916055329636],
              [-38.515609898740436, -8.148978465366501],
              [-38.515629530483118, -8.14903375505849],
              [-38.515651889945154, -8.149085339469247],
              [-38.515663089396377, -8.149132478400219],
              [-38.51565649821999, -8.149178510664168],
              [-38.51561169166618, -8.149223683227618],
              [-38.515562296691016, -8.149234659401792],
              [-38.515509357890416, -8.149248616257424],
              [-38.515506724539392, -8.149249336729275],
              [-38.515445866964711, -8.149281012735036],
              [-38.515389272224041, -8.149315678743879],
              [-38.515331704198864, -8.149329358688545],
              [-38.515270633166971, -8.149312099812809],
              [-38.515203750023964, -8.149296914380884],
              [-38.515139745015176, -8.149303893148183],
              [-38.515079068303415, -8.149336112025589],
              [-38.515022928818127, -8.149369602647607],
              [-38.514979401959963, -8.149407359591695],
              [-38.514941688194305, -8.149442229014031],
              [-38.514902337308484, -8.14947971958601],
              [-38.51486471746432, -8.149511966001194],
              [-38.51477752811622, -8.149549308891203],
              [-38.514697404509043, -8.149598871250404],
              [-38.514653496182873, -8.149651823654617],
              [-38.514600157571991, -8.149696171817844],
              [-38.514567868132723, -8.149749409471292],
              [-38.514547921073294, -8.149805194552236],
              [-38.514526606272277, -8.149866043312917],
              [-38.514520357879775, -8.149928809599308],
              [-38.514524283410914, -8.149985708675265],
              [-38.514550646265839, -8.150029519049356],
              [-38.514581002370214, -8.150074057817815],
              [-38.514610816445341, -8.150116425084972],
              [-38.514645346704256, -8.150162235173919],
              [-38.514672255434668, -8.15020505122104],
              [-38.514709420592688, -8.150248874511522],
              [-38.514740515903775, -8.150282650352679],
              [-38.514786400094408, -8.150322685079411],
              [-38.514826121372252, -8.150354571739223],
              [-38.51491120967556, -8.150403698558053],
              [-38.514957832520736, -8.150433422609343],
              [-38.514978422861077, -8.150446472336881],
              [-38.515000464411855, -8.150460518772054],
              [-38.515055259676721, -8.150488081734332],
              [-38.515066236319299, -8.15049406469802],
              [-38.515095174633458, -8.150509928416495],
              [-38.515144522185423, -8.150538479827691],
              [-38.515193877258255, -8.150560790046175],
              [-38.515243060911963, -8.150574688007161],
              [-38.515294791835608, -8.150583975948546],
              [-38.515341707197535, -8.150596966665971],
              [-38.515392163133789, -8.15060969025218],
              [-38.515460471917883, -8.150647309493806],
              [-38.515465578971202, -8.150703124552788],
              [-38.515478500557251, -8.150752436394782],
              [-38.515506496327546, -8.150797243637951],
              [-38.515507492029037, -8.150799596582509],
              [-38.515534830394955, -8.150862674366843],
              [-38.515518250369226, -8.150911589157955],
              [-38.515514828336165, -8.150964228203392],
              [-38.515528198915675, -8.151017610925678],
              [-38.51557417774459, -8.151054570312148],
              [-38.515620003864143, -8.151067559699612],
              [-38.515670660132976, -8.151064725729313],
              [-38.515725130826141, -8.151060358621342],
              [-38.51577795156863, -8.151069285997925],
              [-38.515834023269697, -8.151092327779907],
              [-38.515889289958949, -8.151105418860046],
              [-38.515946029503766, -8.151101506691482],
              [-38.515993149985533, -8.151094959923789],
              [-38.516049905291425, -8.151077932201156],
              [-38.516129779353093, -8.151085082771225],
              [-38.516147787509517, -8.151131868005436],
              [-38.516183667385043, -8.151187900676641],
              [-38.516224638639777, -8.151237336413208],
              [-38.516268884674034, -8.151281077568129],
              [-38.516317863004467, -8.151314874628664],
              [-38.516374455419083, -8.151357906880783],
              [-38.516424527421485, -8.151388087148414],
              [-38.516498531305643, -8.151445702938329],
              [-38.516483494879417, -8.151494348256765],
              [-38.516468638371386, -8.151544350570797],
              [-38.516457136759698, -8.15159752270856],
              [-38.51645925889305, -8.151644560345565],
              [-38.516418412264194, -8.151718049219596],
              [-38.516413090344948, -8.151765439787964],
              [-38.516418748600266, -8.151815918822118],
              [-38.516440286754758, -8.151871301206985],
              [-38.516460021782152, -8.151916369898437],
              [-38.516476383878654, -8.151973283795902],
              [-38.516503459374199, -8.152028672780418],
              [-38.516528185892149, -8.152074742393731],
              [-38.516553456418997, -8.152121355367132],
              [-38.516577192558714, -8.15216063988764],
              [-38.516580363523538, -8.152165799439086],
              [-38.516607722015465, -8.152212324446573],
              [-38.516620565819494, -8.152250962815479],
              [-38.51663639447959, -8.15229855950265],
              [-38.51661509862101, -8.152343760180848],
              [-38.516586897814555, -8.152393927492879],
              [-38.516559616598812, -8.152434236617077],
              [-38.516511226480162, -8.152515224026843],
              [-38.516486126260929, -8.152553545798723],
              [-38.51646755169417, -8.152600377849971],
              [-38.516460005837679, -8.152685484300145],
              [-38.516429961435733, -8.152758986047106],
              [-38.516424623525658, -8.152819673055463],
              [-38.51642852314702, -8.152898371052563],
              [-38.516469362644536, -8.152982087969219],
              [-38.516516340316507, -8.153019048454206],
              [-38.516398412658482, -8.153026867606158],
              [-38.516351347629147, -8.153062630508868],
              [-38.516295375668541, -8.153107427991872],
              [-38.516258323488174, -8.153120318441339],
              [-38.516207400501258, -8.153118267737559],
              [-38.516154206482696, -8.153117571099182],
              [-38.516094938078652, -8.153110535556026],
              [-38.516031309381162, -8.153106027459216],
              [-38.515967575010286, -8.15311391116607],
              [-38.515907663347896, -8.153113387380206],
              [-38.515858575141415, -8.153095328799154],
              [-38.515821492659875, -8.153057927796635],
              [-38.515774240004092, -8.153023228208962],
              [-38.51570980544119, -8.1530094929848],
              [-38.515641537939132, -8.15301266771678],
              [-38.515570269602847, -8.153020180553378],
              [-38.515504801460921, -8.153035569641508],
              [-38.515502712958721, -8.153036109858597],
              [-38.515434239839486, -8.153059274225685],
              [-38.515371665414357, -8.153083892864817],
              [-38.515313265482938, -8.153109511457124],
              [-38.515261305417326, -8.153139479440743],
              [-38.51522667083033, -8.153180051028349],
              [-38.515206105440036, -8.153221543955848],
              [-38.51517214617617, -8.153304357478104],
              [-38.515150206930251, -8.153355979394675],
              [-38.515147662366566, -8.153358328106496],
              [-38.515099405773476, -8.153403496448421],
              [-38.515079401794047, -8.153431060415276],
              [-38.51505530580306, -8.153464498873316],
              [-38.515034253916753, -8.153533036386223],
              [-38.515018640746597, -8.153608092954265],
              [-38.514991585520164, -8.15368666346555],
              [-38.514986753886795, -8.153779009279551],
              [-38.515006354677894, -8.153860077808645],
              [-38.515041843607221, -8.153939265848081],
              [-38.515084067754451, -8.154003718248509],
              [-38.51512611188771, -8.154066904100471],
              [-38.51517414912098, -8.154128559429921],
              [-38.515236002776518, -8.154174944870256],
              [-38.515298318823852, -8.154214275590714],
              [-38.515364900608382, -8.154254244566337],
              [-38.515448204415243, -8.154278313948387],
              [-38.515503287463332, -8.154293485282349],
              [-38.515525157373553, -8.154299481257034],
              [-38.515576212631899, -8.154342597468455],
              [-38.515599808050617, -8.154423399391929],
              [-38.515627484931791, -8.154507100658172],
              [-38.515664165557659, -8.154576792603798],
              [-38.515706922991328, -8.154650833507223],
              [-38.515751323883713, -8.154717007036693],
              [-38.515794728208135, -8.154781551233862],
              [-38.515842299449972, -8.154853427024056],
              [-38.515891243452742, -8.154916078334011],
              [-38.515925243726826, -8.154950128928451],
              [-38.515970768596361, -8.154987540009104],
              [-38.516049344060882, -8.155018387539789],
              [-38.516102873160527, -8.155042602154507],
              [-38.516164276880183, -8.15508591155645],
              [-38.516206984671442, -8.155125851909174],
              [-38.516255865493143, -8.15516561872024],
              [-38.5163100187563, -8.155199241073568],
              [-38.516370809586419, -8.155223826128273],
              [-38.516579435943193, -8.15520589410732],
              [-38.516745087944756, -8.155218754919771],
              [-38.516781001686233, -8.155246928341128],
              [-38.516837713800214, -8.155266352694916],
              [-38.516923573776936, -8.155278666061083],
              [-38.516989013264123, -8.155287427444907],
              [-38.517038944721932, -8.155283868853413],
              [-38.517090581832598, -8.155296231860952],
              [-38.517148859853151, -8.155296753544716],
              [-38.517213214588487, -8.155301714611564],
              [-38.517264862123348, -8.155305394205909],
              [-38.517325420517849, -8.155296782915618],
              [-38.517379352003012, -8.155288344630414],
              [-38.517432191456052, -8.155282166340937],
              [-38.517522916729135, -8.155249349762608],
              [-38.51759396233804, -8.155200771073455],
              [-38.517629409428444, -8.15516390878792],
              [-38.517646720498867, -8.155110924440164],
              [-38.517656224132175, -8.155058473514154],
              [-38.517668857306454, -8.154970026364101],
              [-38.517690710872259, -8.154913972045527],
              [-38.517720826934394, -8.154856389869037],
              [-38.517750406998879, -8.154791570881553],
              [-38.517762825751014, -8.154730530466448],
              [-38.517769896166151, -8.154663242491358],
              [-38.517782230310743, -8.154597046202708],
              [-38.517805188325255, -8.154528329890052],
              [-38.517813978603293, -8.154465114304596],
              [-38.517833944775312, -8.154393228605969],
              [-38.517853537866074, -8.154329664058386],
              [-38.517874946043868, -8.154266463476381],
              [-38.517893531155934, -8.154210767062713],
              [-38.51791030907733, -8.154148103684392],
              [-38.517912364655302, -8.154099261975359],
              [-38.517909417894046, -8.154058464558007],
              [-38.517910473740095, -8.154010616633499],
              [-38.51791352774719, -8.153961866563504],
              [-38.517911604007253, -8.153900628179482],
              [-38.517914655737734, -8.153853777601265],
              [-38.517917707358841, -8.15380701747498],
              [-38.517913785998722, -8.153746228975903],
              [-38.517889875995238, -8.153700341311104],
              [-38.517943760030334, -8.153731340013007],
              [-38.517991768745588, -8.153741346827097],
              [-38.518039332029588, -8.153744297839266],
              [-38.518101685669834, -8.153752422197938],
              [-38.518153964449162, -8.153759449147922],
              [-38.518204066765961, -8.15376466446209],
              [-38.518264077551216, -8.153758313669284],
              [-38.51831617824638, -8.153762445918343],
              [-38.518372992165318, -8.153772553601286],
              [-38.518426651210056, -8.153764024387465],
              [-38.518484574897251, -8.153757037917735],
              [-38.518551769768521, -8.153740022261022],
              [-38.518610335807644, -8.153727247598404],
              [-38.518674423511946, -8.153727595043767],
              [-38.518739242293186, -8.15372387300012],
              [-38.518803607192631, -8.153720150407333],
              [-38.518865076657299, -8.153708555031237],
              [-38.518919391983594, -8.15368256926479],
              [-38.518975972385583, -8.153660204264026],
              [-38.519027027968264, -8.153627340251239],
              [-38.519112135863224, -8.153585109597918],
              [-38.519133278279298, -8.153516210132667],
              [-38.51916206051493, -8.153587068304859],
              [-38.519221180064193, -8.153642856941415],
              [-38.519315528914461, -8.153691903427713],
              [-38.519330700910793, -8.153757589680398],
              [-38.519338523705372, -8.153820191846442],
              [-38.519359960785202, -8.153884619195514],
              [-38.519379779881639, -8.15393565770662],
              [-38.519413846868297, -8.153990059836275],
              [-38.519445754021483, -8.154028720728235],
              [-38.519497870183116, -8.154095988451569],
              [-38.519483093798577, -8.154155126648284],
              [-38.519463870123197, -8.154213626407554],
              [-38.519452898129295, -8.154279643475375],
              [-38.519443836215125, -8.15434249698127],
              [-38.519453197372911, -8.154409171315422],
              [-38.519454034620395, -8.154468237564505],
              [-38.519461581224725, -8.154534095678047],
              [-38.51946015089716, -8.154591621552351],
              [-38.519469878622544, -8.154655401851427],
              [-38.519494418574219, -8.154706084145191],
              [-38.519525313425888, -8.15475632170898],
              [-38.519558038067075, -8.15479462175502],
              [-38.519593940108116, -8.154832744659867],
              [-38.519637565824453, -8.154864454611269],
              [-38.519684927201908, -8.154884591108685],
              [-38.519736568032791, -8.15489387841213],
              [-38.51975626491928, -8.154895258529466],
              [-38.519785401701043, -8.15489737344576],
              [-38.519841597872869, -8.154892826952254],
              [-38.519856033821931, -8.154890763651339],
              [-38.519895528407119, -8.154885111942159],
              [-38.519982382769335, -8.15490095347314],
              [-38.519991130811121, -8.154948993928159],
              [-38.520001506791644, -8.155002101631512],
              [-38.520008612984441, -8.155056743149666],
              [-38.520017718852294, -8.155109216183607],
              [-38.520048261587597, -8.155150317627053],
              [-38.520092524860935, -8.155180309696805],
              [-38.520138883228455, -8.155204243947505],
              [-38.52017988513505, -8.155228714565821],
              [-38.520191085252428, -8.155275853398427],
              [-38.520197207704115, -8.155318101803948],
              [-38.520199999565868, -8.155336738253403],
              [-38.520211822400306, -8.155394551177396],
              [-38.520228717731868, -8.155461596225134],
              [-38.520245352814882, -8.1555185103224],
              [-38.520269058132655, -8.15558411619411],
              [-38.520282145710411, -8.155647086386253],
              [-38.520296773477007, -8.155712591061221],
              [-38.520320673411611, -8.155767252449509],
              [-38.52035401855332, -8.155818216475001],
              [-38.520385549246235, -8.155868273829546],
              [-38.520412536796876, -8.155921943892949],
              [-38.520426024053464, -8.155954251272009],
              [-38.520436886330728, -8.155980223893767],
              [-38.520457520489892, -8.156033072368128],
              [-38.520461951846087, -8.156047097695621],
              [-38.52047533572312, -8.156089987853754],
              [-38.520484707531601, -8.156147888323524],
              [-38.520488265098521, -8.156209580905207],
              [-38.520490464677877, -8.156268196506728],
              [-38.52048948976195, -8.156324547048513],
              [-38.52049477801804, -8.156381266808546],
              [-38.520508420766021, -8.156435463791812],
              [-38.520521250081842, -8.156486674891143],
              [-38.520531833903938, -8.156517802954445],
              [-38.520537634788084, -8.156525226893867],
              [-38.52056657961667, -8.156612366563103],
              [-38.520593654672595, -8.156668840737069],
              [-38.520624542259768, -8.156725409871184],
              [-38.520655165282477, -8.156775466134878],
              [-38.520690324790742, -8.156827517709148],
              [-38.520731024397257, -8.156877314528328],
              [-38.520771720135556, -8.156930367617017],
              [-38.520804784368096, -8.156988657904028],
              [-38.520821860651338, -8.157056336310321],
              [-38.520814512206847, -8.157128779801731],
              [-38.520773682753372, -8.157187977687748],
              [-38.520733786484584, -8.157225829963018],
              [-38.520687803295928, -8.157267745381912],
              [-38.520655333706046, -8.157319897872815],
              [-38.520634930133035, -8.157378215379715],
              [-38.520648388570123, -8.157434763896633],
              [-38.520687917284604, -8.157476870903553],
              [-38.520730989439905, -8.157516358985104],
              [-38.520799428354202, -8.15759857047721],
              [-38.520834044469574, -8.157649626425799],
              [-38.520860751992593, -8.157709899141256],
              [-38.520874656385338, -8.157773141639108],
              [-38.520882303079226, -8.157831401869311],
              [-38.520900579325627, -8.1578824384941],
              [-38.520960414744245, -8.157947634785508],
              [-38.52102885119055, -8.157955765923269],
              [-38.52109867384668, -8.157943275595422],
              [-38.521161887739368, -8.157915671935401],
              [-38.521222901120254, -8.157906427453904],
              [-38.521296434544688, -8.15790325804811],
              [-38.52135534282845, -8.157908483405613],
              [-38.52142115007581, -8.157913807354653],
              [-38.521491859403454, -8.157919046629397],
              [-38.521566041286327, -8.157904933228329],
              [-38.521637320336964, -8.157889097795548],
              [-38.521707230197386, -8.157879592385576],
              [-38.521771504449028, -8.157876321512271],
              [-38.521835303744218, -8.157890778693472],
              [-38.521893648671643, -8.157911741985947],
              [-38.521955808076598, -8.157931172083284],
              [-38.522018343062307, -8.157940110162608],
              [-38.522089500600089, -8.157950143827545],
              [-38.522160296429718, -8.157959001175424],
              [-38.522236460473074, -8.15795746284024],
              [-38.522299396813615, -8.157934200375657],
              [-38.522354901333394, -8.157900888949561],
              [-38.522422815053282, -8.157890566992871],
              [-38.522488616846942, -8.157900503823766],
              [-38.522550984780835, -8.157897230580325],
              [-38.522593968887698, -8.157858115428725],
              [-38.522584697828243, -8.157791531702637],
              [-38.522555897437286, -8.157735236516247],
              [-38.522560681608262, -8.157681513558318],
              [-38.522612459915045, -8.157651815790524],
              [-38.522655277779947, -8.15759976577241],
              [-38.522684680143222, -8.157531237651021],
              [-38.522704376101608, -8.157456999607492],
              [-38.522731515454268, -8.157383041684874],
              [-38.522746498066645, -8.157302828242909],
              [-38.522762387262972, -8.157223610841383],
              [-38.522762124141344, -8.157139309095895],
              [-38.522761396834646, -8.157063690213095],
              [-38.522768844824441, -8.156983558347683],
              [-38.522780098150399, -8.156909581711076],
              [-38.522784065389573, -8.156855767331958],
              [-38.52277687531717, -8.156794974996718],
              [-38.522755496661617, -8.156680708711926],
              [-38.522821938354575, -8.156763279338131],
              [-38.522901757004107, -8.15681827778508],
              [-38.522972426070318, -8.156857345862967],
              [-38.5230360222629, -8.156889893047861],
              [-38.523103255228868, -8.15691765053673],
              [-38.523185220022079, -8.156923535917214],
              [-38.523261579040629, -8.156910510178996],
              [-38.523338749047902, -8.156902550702732],
              [-38.523409758642792, -8.156884091512827],
              [-38.523467604921663, -8.156866159546082],
              [-38.52352986049744, -8.156880976449266],
              [-38.523609475693519, -8.156877904214113],
              [-38.523692272581926, -8.156871036710186],
              [-38.523767276561443, -8.156852401246383],
              [-38.523845730061325, -8.156833769820965],
              [-38.523916193893541, -8.15681621442115],
              [-38.523962769884164, -8.156810027928755],
              [-38.523983672282995, -8.156866766009687],
              [-38.523989036084579, -8.156936782313187],
              [-38.523972604084392, -8.157014642357682],
              [-38.523958166069271, -8.157095127856795],
              [-38.523944449876581, -8.157179322742847],
              [-38.523939630904373, -8.157262623557379],
              [-38.523934444860892, -8.157349270671499],
              [-38.523934887746648, -8.157435291232229],
              [-38.523939051318315, -8.157522311137523],
              [-38.523936134269803, -8.157609322724701],
              [-38.523941298424113, -8.157694625213043],
              [-38.523949095975397, -8.157779207177155],
              [-38.523965248246455, -8.157861085389433],
              [-38.52397731233556, -8.157945672364757],
              [-38.52398465804319, -8.158028535205293],
              [-38.524001994179137, -8.158107248979947],
              [-38.524019790414314, -8.158180717067053],
              [-38.524047671072346, -8.15824732263151],
              [-38.524085359331004, -8.158310864338674],
              [-38.524123601680664, -8.158366446900192],
              [-38.524168835717404, -8.158420499981593],
              [-38.524207279647541, -8.158459168212087],
              [-38.524249067801449, -8.158510141864557],
              [-38.524302485741913, -8.158552445755461],
              [-38.524327412525146, -8.158583771478655],
              [-38.524347528536509, -8.158614729744457],
              [-38.524274411745139, -8.158649287099371],
              [-38.524206745974055, -8.158680142301165],
              [-38.524155502351036, -8.158718252952404],
              [-38.524093543876063, -8.158758974125581],
              [-38.5240191640737, -8.158786836485811],
              [-38.523957123253489, -8.158820411816635],
              [-38.523897523673547, -8.158862221155266],
              [-38.523848811919201, -8.158908656340945],
              [-38.523801916774204, -8.158954189133206],
              [-38.523752930202306, -8.159002704383282],
              [-38.523714462137022, -8.159061091279755],
              [-38.523681971561125, -8.159131153531003],
              [-38.52365010923986, -8.159207276813959],
              [-38.523624334572688, -8.15927870373732],
              [-38.523604176108016, -8.159360267906706],
              [-38.523587373298795, -8.159444459133464],
              [-38.523586823532, -8.159525413192073],
              [-38.523594348260119, -8.159610356641132],
              [-38.523606958467447, -8.159693677930365],
              [-38.523619298167091, -8.159775461213922],
              [-38.523648444491272, -8.159846500448381],
              [-38.523689313145859, -8.159907422818367],
              [-38.523725096974935, -8.159969424633237],
              [-38.523764420610881, -8.160031882867459],
              [-38.523805653375675, -8.160091991585503],
              [-38.523841644385371, -8.160132375562878],
              [-38.523895425596479, -8.16017467994085],
              [-38.523973740049342, -8.160197385015595],
              [-38.524060318500936, -8.160217386218775],
              [-38.524138803506382, -8.160249408038309],
              [-38.524210382350574, -8.160287391576913],
              [-38.524288879043823, -8.160309554093761],
              [-38.524362550938157, -8.160343198359426],
              [-38.524431683205663, -8.16037747044852],
              [-38.524503725508858, -8.160407404236889],
              [-38.524566853418968, -8.160452704423967],
              [-38.52461645490915, -8.160498440990237],
              [-38.524660138959383, -8.160558462054169],
              [-38.524708186667155, -8.160613151554287],
              [-38.52473634056215, -8.160679214686768],
              [-38.524754037263982, -8.160760280613806],
              [-38.524794533340135, -8.160829433596767],
              [-38.524849577624288, -8.160878523253682],
              [-38.524903260145877, -8.160927701758915],
              [-38.524960207161655, -8.160979869008324],
              [-38.525006089427748, -8.161023430317597],
              [-38.525062229295791, -8.161067275005468],
              [-38.525111826218108, -8.161116991408369],
              [-38.52514824560307, -8.161179174761822],
              [-38.525177388553338, -8.16125337972521],
              [-38.525223167495412, -8.161307614250543],
              [-38.525252997380854, -8.161338222055448],
              [-38.52526913633416, -8.161354793722445],
              [-38.525333455164002, -8.161391140429259],
              [-38.525336629940753, -8.161393224550116],
              [-38.525391691768327, -8.161427570452297],
              [-38.52541160274113, -8.161478608821419],
              [-38.525392911678615, -8.161547692401133],
              [-38.525349371533309, -8.161596304664945],
              [-38.525301659747932, -8.16164138439879],
              [-38.525255856001124, -8.16168501912748],
              [-38.525205863314511, -8.161739774560093],
              [-38.525175912934408, -8.161811196684999],
              [-38.525154036460002, -8.161887150881954],
              [-38.525155291218852, -8.161977694998582],
              [-38.525178345244299, -8.162057681764265],
              [-38.52520267551327, -8.162133147415187],
              [-38.52523462881382, -8.162210611948899],
              [-38.525273676392295, -8.16227659736718],
              [-38.525319359659775, -8.162335083027179],
              [-38.525368492408603, -8.162393663177157],
              [-38.525412991528142, -8.162455584623476],
              [-38.525438323839289, -8.162528156966616],
              [-38.525465105943645, -8.162602992311941],
              [-38.525469648107503, -8.162677168433156],
              [-38.525465304773356, -8.162742198471612],
              [-38.525491018048854, -8.162799665742039],
              [-38.525516006918657, -8.162855594474975],
              [-38.525515379098877, -8.162926055998362],
              [-38.525535194889642, -8.162980893243359],
              [-38.525487325731959, -8.163005349703605],
              [-38.525429544531036, -8.163044357345729],
              [-38.525398060027499, -8.163107998793032],
              [-38.52539279351361, -8.163186052862001],
              [-38.525408504834722, -8.163257347600736],
              [-38.525431395883118, -8.163321685933978],
              [-38.525440765957441, -8.163381757168883],
              [-38.52543587248833, -8.163451399621092],
              [-38.525452402219656, -8.16352151943884],
              [-38.525462938649348, -8.163593260372334],
              [-38.525465298711225, -8.163670328403654],
              [-38.525474648191292, -8.163747856885907],
              [-38.525482181568023, -8.16382583550074],
              [-38.525489531254401, -8.163905622943803],
              [-38.525516051891536, -8.163971322308953],
              [-38.525544032040756, -8.164031053538283],
              [-38.525580007019002, -8.16408545745348],
              [-38.525617252801617, -8.164139953306949],
              [-38.525652766754966, -8.164200416973166],
              [-38.525649873531094, -8.164267438656363],
              [-38.525637797672125, -8.164346298818709],
              [-38.525643035195785, -8.164369732071298],
              [-38.52710620707456, -8.164371443503732],
              [-38.52709300625807, -8.164326473339811],
              [-38.52707386373924, -8.164239255055138],
              [-38.527086743554698, -8.164171250037429],
              [-38.5271252079568, -8.164116028658286],
              [-38.527182214923336, -8.164040658083332],
              [-38.527194829609876, -8.163966502000278],
              [-38.527201360525709, -8.163893695596323],
              [-38.527210611712817, -8.163823515481965],
              [-38.527202798323572, -8.163751777756186],
              [-38.527178908188006, -8.163687619245112],
              [-38.527146477744445, -8.163629511058909],
              [-38.527125759129611, -8.163570240667857],
              [-38.527106236174561, -8.163497765649344],
              [-38.527099488956658, -8.163445747748872],
              [-38.527106081397804, -8.163397815775053],
              [-38.527111141772593, -8.16334038452975],
              [-38.52713990544342, -8.163274388001316],
              [-38.527170088053431, -8.163236885573976],
              [-38.527224979106826, -8.163185210992005],
              [-38.527349837282465, -8.163153246196563],
              [-38.527327042768356, -8.163083752290973],
              [-38.527220947092268, -8.163062914891329],
              [-38.527151556178488, -8.163093587630987],
              [-38.527079724555598, -8.163115483643326],
              [-38.527003819598789, -8.163127425141854],
              [-38.526959344317497, -8.163122126966419],
              [-38.526931297706454, -8.163118747473716],
              [-38.526859487250704, -8.163122643481014],
              [-38.526849137866066, -8.163123174102937],
              [-38.526771083389818, -8.163110329140864],
              [-38.526700036429645, -8.163082567739361],
              [-38.526623543411503, -8.163054257247],
              [-38.526540499338715, -8.163038692843147],
              [-38.526454283247624, -8.163018692555518],
              [-38.526373437158234, -8.162986758839322],
              [-38.526293111456276, -8.162975448818605],
              [-38.526210708087874, -8.162955452940228],
              [-38.526133224672165, -8.162920266832325],
              [-38.526070906702401, -8.16288048539519],
              [-38.526008228297378, -8.162838442222323],
              [-38.525941374206568, -8.162796303700388],
              [-38.52589004269948, -8.162754635600939],
              [-38.525840351613546, -8.162707542284014],
              [-38.525798575643726, -8.162645714520842],
              [-38.525779134997848, -8.162580656627592],
              [-38.52578168735333, -8.162494730027413],
              [-38.525828790198986, -8.162427217383128],
              [-38.525880413599161, -8.162375267802572],
              [-38.525951262041225, -8.162340255278465],
              [-38.52602166798809, -8.162295563837599],
              [-38.526081003768326, -8.162246789081291],
              [-38.526142235218835, -8.162207061753689],
              [-38.526209914364443, -8.16216526155773],
              [-38.526273701007511, -8.162114140219577],
              [-38.526323876440799, -8.162058299437787],
              [-38.526352470829273, -8.161981900761338],
              [-38.526377259766221, -8.161899346881942],
              [-38.526378634634945, -8.161811067133376],
              [-38.526351033160715, -8.161738130377264],
              [-38.526325252905181, -8.161660401782182],
              [-38.526295663706989, -8.161579502903869],
              [-38.526259888716226, -8.161509541506602],
              [-38.526219672111445, -8.161434057326737],
              [-38.526193356926989, -8.161348096947933],
              [-38.526171850005248, -8.161264674853964],
              [-38.526142439008773, -8.161186670645458],
              [-38.526127836135622, -8.161099095824138],
              [-38.526147345329321, -8.161028927731268],
              [-38.526169391290317, -8.160963013857936],
              [-38.526198959560197, -8.160907510785444],
              [-38.526220899490063, -8.160854531450351],
              [-38.526286173092593, -8.160773924412926],
              [-38.526320347782274, -8.160738145298536],
              [-38.526269852235806, -8.160680558643444],
              [-38.526223852659335, -8.160659429482587],
              [-38.526151432348712, -8.160641977953912],
              [-38.526092792380567, -8.160639738505184],
              [-38.526037731870687, -8.16060430727474],
              [-38.525982582191915, -8.160567519149764],
              [-38.525933255314392, -8.160519702662276],
              [-38.525862869370698, -8.160470685643743],
              [-38.525811720777369, -8.160428113238682],
              [-38.525754139553129, -8.160374859936862],
              [-38.525694472907503, -8.160319523785123],
              [-38.525636896728273, -8.160262019220246],
              [-38.525596381777369, -8.160208785880975],
              [-38.525558032914191, -8.160166228435113],
              [-38.525506909467573, -8.160102399755971],
              [-38.52544082029943, -8.160027880180632],
              [-38.525413130139199, -8.159953405579202],
              [-38.525383334490748, -8.159893853123505],
              [-38.525357825575796, -8.159817210221346],
              [-38.525355729735878, -8.159747016854446],
              [-38.525398576316391, -8.159670544482198],
              [-38.525439191107871, -8.159562139860885],
              [-38.5254947821347, -8.15953244619493],
              [-38.525548086216055, -8.15951758400514],
              [-38.525676208561535, -8.159487975243509],
              [-38.525747755097193, -8.15947639066256],
              [-38.525856635119361, -8.159444588464877],
              [-38.525954830867285, -8.159389437064839],
              [-38.526033900116822, -8.159310836141417],
              [-38.526102245006491, -8.15924289601878],
              [-38.526179203834303, -8.159183378012939],
              [-38.526246687685287, -8.15915279362269],
              [-38.52626501940594, -8.159080272376473],
              [-38.526259209620356, -8.159003200291979],
              [-38.526253945246872, -8.158925495679428],
              [-38.526175307822797, -8.158791715346856],
              [-38.526060165858546, -8.15874517869659],
              [-38.525898016653329, -8.158685471445029],
              [-38.525750823278969, -8.158644867053367],
              [-38.525585281588221, -8.158613829062372],
              [-38.525503005970023, -8.158640235222379],
              [-38.525409857535635, -8.15864926180585],
              [-38.525336055793424, -8.158648904001719],
              [-38.52527528122976, -8.158609576544473],
              [-38.525272442635028, -8.158553402396519],
              [-38.525282087294549, -8.158457624805351],
              [-38.525307420639315, -8.158375252519491],
              [-38.525336561606338, -8.158297135945306],
              [-38.52539300328133, -8.158238769927097],
              [-38.525452527101848, -8.158184025597979],
              [-38.525512142963684, -8.158128195940225],
              [-38.52556541804978, -8.158060780965309],
              [-38.525632478576291, -8.158004145933704],
              [-38.525692278500387, -8.157946145608335],
              [-38.525747443328456, -8.157892752911589],
              [-38.525801348773754, -8.157829589893012],
              [-38.525848370907035, -8.157753031883633],
              [-38.525871170849882, -8.157663420408818],
              [-38.525895415649487, -8.157580232729888],
              [-38.52590486919145, -8.157492414681585],
              [-38.525894907391475, -8.157395257340212],
              [-38.525870049296451, -8.157305590105571],
              [-38.525848457908936, -8.157217012118224],
              [-38.525804157009645, -8.15714134222673],
              [-38.525753224601395, -8.157069734920954],
              [-38.525703564902457, -8.156996591411207],
              [-38.525651809564906, -8.156930048455605],
              [-38.525598506662213, -8.156867212223645],
              [-38.525537845077295, -8.156809251790824],
              [-38.52546764456293, -8.156757521384369],
              [-38.525385077590286, -8.156723414667951],
              [-38.52534743959351, -8.156694154523484],
              [-38.525319558914596, -8.156627458585126],
              [-38.525289859717219, -8.156563293177273],
              [-38.525269994425514, -8.156473722212592],
              [-38.525245038803732, -8.156389934226569],
              [-38.525200716225719, -8.15633280695698],
              [-38.525141503152206, -8.156278285352187],
              [-38.525099549766615, -8.156213562862101],
              [-38.525050584369893, -8.156167646212882],
              [-38.524991009941814, -8.156111676929278],
              [-38.524933160610871, -8.156055438302932],
              [-38.524867590779436, -8.156002989612123],
              [-38.524789289992924, -8.155969340046601],
              [-38.524748818587383, -8.155956538833005],
              [-38.524696460014255, -8.155939834231205],
              [-38.524652444472864, -8.155930285132039],
              [-38.524602620820197, -8.155919372432933],
              [-38.524527715518168, -8.155931495620681],
              [-38.524482530320661, -8.155836286886725],
              [-38.524436404668442, -8.155768755421876],
              [-38.524390641176353, -8.155702038447327],
              [-38.524354863462442, -8.155635061829432],
              [-38.524313556825135, -8.155560932995741],
              [-38.524274981843668, -8.155479752091983],
              [-38.524245762757623, -8.155393607294384],
              [-38.524227963366535, -8.155322852774386],
              [-38.524225614293407, -8.155313442987302],
              [-38.524202301164365, -8.155222692056206],
              [-38.524175988993818, -8.155134832074312],
              [-38.524154841796992, -8.155054847496384],
              [-38.524114016405946, -8.154957653896799],
              [-38.524092764334625, -8.154889608884874],
              [-38.524053538403322, -8.15482163322212],
              [-38.524010658103663, -8.154773371847202],
              [-38.523990695542828, -8.154689589660265],
              [-38.523958562945694, -8.15461085850551],
              [-38.523928773003853, -8.154546964262241],
              [-38.523877640432445, -8.154491637911743],
              [-38.523830744193809, -8.154461824054442],
              [-38.523789096838485, -8.154445674641433],
              [-38.523706947339861, -8.154442864568509],
              [-38.523793231755178, -8.154403438316802],
              [-38.523878485148188, -8.154314714379739],
              [-38.523924128742387, -8.154252355956237],
              [-38.523966594077763, -8.154190898316802],
              [-38.523993370163524, -8.154116668489156],
              [-38.52403176251461, -8.154045075411032],
              [-38.524053715451331, -8.153980970563317],
              [-38.524068703692109, -8.154049189128417],
              [-38.524113859539312, -8.154092206937522],
              [-38.52418001201228, -8.154112184070556],
              [-38.524254611616186, -8.154128010303735],
              [-38.524334249570693, -8.154105219337968],
              [-38.524439616778167, -8.154048991216484],
              [-38.524508102461468, -8.154014971057903],
              [-38.524576457081736, -8.154015051220744],
              [-38.524528579247942, -8.154047738777075],
              [-38.524466801985163, -8.154166249180399],
              [-38.524442311956122, -8.154226461631632],
              [-38.524390055655346, -8.15427705357224],
              [-38.524337887873223, -8.154329545105695],
              [-38.524301131980017, -8.154399059732608],
              [-38.524287234964497, -8.154482802177386],
              [-38.524276882395469, -8.154563021143979],
              [-38.524312117810567, -8.1546279167289],
              [-38.524361627813676, -8.154673653190013],
              [-38.524394747354158, -8.154685450835489],
              [-38.524436759150284, -8.154700515189777],
              [-38.524494866288634, -8.154691990390798],
              [-38.524521377633093, -8.154688132041333],
              [-38.524603905580484, -8.15467800772892],
              [-38.524691699631987, -8.154666985051636],
              [-38.52477162848048, -8.154628546111974],
              [-38.524851373412233, -8.154592096888969],
              [-38.5249454010957, -8.154529523694054],
              [-38.525052321519162, -8.154387910335208],
              [-38.525030170238821, -8.154543281305967],
              [-38.525060237278986, -8.154603286357046],
              [-38.525100133274101, -8.15464204664778],
              [-38.525177285434729, -8.15464883048597],
              [-38.525235429471437, -8.154609009152196],
              [-38.525366947158609, -8.154470047626194],
              [-38.525338397472481, -8.154585883504632],
              [-38.525342124858916, -8.154658159199277],
              [-38.525328694729396, -8.15473104796969],
              [-38.525300469932581, -8.154802291274889],
              [-38.525330342890641, -8.154872969444089],
              [-38.525378387819863, -8.154929558340459],
              [-38.525434713592013, -8.154969061454976],
              [-38.525522203695523, -8.154984902556468],
              [-38.525605616001975, -8.154994678567915],
              [-38.525690563308352, -8.155011421180204],
              [-38.525778503480687, -8.155030519034334],
              [-38.525868892180881, -8.155051700135044],
              [-38.525876152607182, -8.155053155862666],
              [-38.525962188194661, -8.155070804214551],
              [-38.526060584965968, -8.15507526097457],
              [-38.526154993638244, -8.155074557271547],
              [-38.526246700093161, -8.155055940845447],
              [-38.526301849423142, -8.155015301786893],
              [-38.526343007964407, -8.154984143858032],
              [-38.526379591036218, -8.155061251909693],
              [-38.526436031868258, -8.155080222367314],
              [-38.526503972497288, -8.155046563045833],
              [-38.526578841079527, -8.15498830879333],
              [-38.526545861298573, -8.155089124525531],
              [-38.526539339437534, -8.155154332974119],
              [-38.526536525904149, -8.15523103318807],
              [-38.52656993653067, -8.155304157620922],
              [-38.526592787044649, -8.155325621489162],
              [-38.526619627374586, -8.155350798557434],
              [-38.52669311683799, -8.155384623058914],
              [-38.526788325777005, -8.15539821160122],
              [-38.526878353750888, -8.155417492565824],
              [-38.526969836607535, -8.15543469480702],
              [-38.527067137156692, -8.155445119897202],
              [-38.527156281726491, -8.155443686205983],
              [-38.527222581689941, -8.155415723342538],
              [-38.527266818478566, -8.155468779952786],
              [-38.52729269468044, -8.155541985998966],
              [-38.527333467844976, -8.155607068712799],
              [-38.527395243178795, -8.155644316618055],
              [-38.527464095185429, -8.155684919497569],
              [-38.527521045886481, -8.155733649196272],
              [-38.527601632658715, -8.155753371262106],
              [-38.527687758524628, -8.155771652530701],
              [-38.527774832393405, -8.155755653481702],
              [-38.527835434445002, -8.155709774334236],
              [-38.527887335491833, -8.155652307153876],
              [-38.527933432962982, -8.155589586976225],
              [-38.527966104686705, -8.155518981811639],
              [-38.52800195570655, -8.155446571300459],
              [-38.528037535655571, -8.155373075042373],
              [-38.528065367007443, -8.155327338602687],
              [-38.528075553622912, -8.155310616794225],
              [-38.528074995929629, -8.155244314638539],
              [-38.528078016697648, -8.155222880969717],
              [-38.528076884221896, -8.155182266586527],
              [-38.528097810262118, -8.155219285922449],
              [-38.528133717963691, -8.155253337772697],
              [-38.528181576069017, -8.155314810564951],
              [-38.528186644720535, -8.155327479781135],
              [-38.528210721349531, -8.155387206296538],
              [-38.528212531582817, -8.155391731016518],
              [-38.528251939843315, -8.15545970652968],
              [-38.528299163400924, -8.155520454953173],
              [-38.528350745212599, -8.155580394372693],
              [-38.528394356169862, -8.155625399889244],
              [-38.528459848376066, -8.155666993670934],
              [-38.528541969920759, -8.155693953518213],
              [-38.528615093870833, -8.155730038564633],
              [-38.528700129303019, -8.155749313327197],
              [-38.528741977211801, -8.155749542887614],
              [-38.528790088722538, -8.155749779725202],
              [-38.528841656424888, -8.1557443220817],
              [-38.528882057199091, -8.155740027332056],
              [-38.528971313059898, -8.155721136086363],
              [-38.529057476150427, -8.155707668363478],
              [-38.529137854600719, -8.155672756713969],
              [-38.529212972849841, -8.155633678133736],
              [-38.529272303220615, -8.1555883399976],
              [-38.52932093365856, -8.15553294922517],
              [-38.529367482351937, -8.155472309797032],
              [-38.529413028256101, -8.155415287289479],
              [-38.529457220956118, -8.155351027022984],
              [-38.529470954048904, -8.155328972608682],
              [-38.52948569266357, -8.155300949510643],
              [-38.5294974508707, -8.155259897824608],
              [-38.529522654447291, -8.155287605474655],
              [-38.529554508307719, -8.155295149983367],
              [-38.529614305549465, -8.155316294758432],
              [-38.529645880847156, -8.155329175621466],
              [-38.529688071953657, -8.155346410508542],
              [-38.529726826124495, -8.155352877582764],
              [-38.529778014387944, -8.155361349027496],
              [-38.529826674113295, -8.155358510997871],
              [-38.529870976236751, -8.155355939265343],
              [-38.529947713553206, -8.155329525738427],
              [-38.529948984956, -8.155329074951318],
              [-38.530015286527941, -8.155299573932048],
              [-38.530085855442515, -8.155269354229986],
              [-38.530153335298095, -8.15524139224164],
              [-38.530213558496399, -8.155208718312769],
              [-38.530238865268679, -8.155148054155827],
              [-38.530314116637854, -8.155072523240991],
              [-38.530340791157819, -8.155006614427958],
              [-38.530344522918469, -8.154920327267238],
              [-38.530327798159689, -8.154861242545035],
              [-38.530346372959066, -8.154812962542325],
              [-38.530351016394306, -8.15480111867188],
              [-38.530378415306714, -8.154736748382346],
              [-38.53038333645226, -8.154720291766248],
              [-38.530399740302776, -8.154665406227814],
              [-38.530412520787991, -8.154603913473947],
              [-38.530452783781932, -8.154561990246847],
              [-38.530482134902236, -8.15453606443347],
              [-38.530514841270552, -8.154513127429118],
              [-38.530537536364832, -8.154512339640659],
              [-38.530562292630535, -8.154534438668183],
              [-38.530561875248424, -8.154503141691027],
              [-38.530575337503862, -8.154479730135439],
              [-38.530643385163806, -8.154431778755312],
              [-38.530681178693371, -8.154405681795714],
              [-38.530743304868416, -8.154375723549355],
              [-38.53078930569928, -8.154395404941457],
              [-38.530845552162006, -8.154425500199855],
              [-38.530897619854073, -8.154458213732189],
              [-38.530970960894884, -8.154463816181245],
              [-38.531044878002454, -8.154442464497931],
              [-38.531109910906153, -8.154410881415243],
              [-38.531174322270921, -8.154367357898758],
              [-38.531231656106378, -8.154321203070756],
              [-38.531283176228108, -8.154278478699828],
              [-38.531327439272111, -8.154231404221616],
              [-38.531370057165823, -8.154193915780597],
              [-38.531412328266732, -8.154142406826246],
              [-38.531457853412732, -8.154102841332042],
              [-38.531511392808291, -8.154119003714966],
              [-38.531580262386001, -8.154144681296319],
              [-38.531646330425268, -8.15415959180519],
              [-38.531726401729969, -8.154154166708411],
              [-38.531808299505386, -8.154139336664613],
              [-38.531878124946715, -8.154123678593715],
              [-38.531950772027621, -8.154101511201477],
              [-38.532015978688158, -8.1540765311854],
              [-38.532078385254216, -8.154039517768645],
              [-38.532120477852501, -8.153985294958638],
              [-38.532134539539328, -8.153914667927179],
              [-38.532153959659226, -8.153841785770261],
              [-38.532169647530488, -8.153777854084517],
              [-38.532193412605572, -8.15371555985922],
              [-38.532234124587333, -8.153677616858237],
              [-38.532301587331361, -8.153664036386168],
              [-38.532368689592744, -8.153648194178746],
              [-38.532435609771305, -8.153632804011592],
              [-38.532501629429483, -8.153611171584457],
              [-38.532562470730227, -8.153593060816631],
              [-38.532622598206942, -8.153564275842248],
              [-38.532673291336643, -8.153529962392394],
              [-38.532725894331605, -8.153492575757774],
              [-38.532764261384614, -8.153441423956872],
              [-38.532796188345351, -8.153385923019119],
              [-38.532816410397402, -8.153325885975072],
              [-38.53285286576628, -8.153279345029928],
              [-38.532890129507301, -8.153240222103245],
              [-38.532926572266298, -8.153204535415368],
              [-38.532964353052797, -8.153189202039341],
              [-38.532993519216063, -8.153165989384664],
              [-38.533043542207629, -8.153161524380895],
              [-38.533102271121649, -8.153164486487116],
              [-38.533148216101736, -8.153154046920433],
              [-38.533197262052902, -8.153131038044101],
              [-38.533241237530305, -8.153096807235237],
              [-38.533275955496812, -8.153061118527765],
              [-38.533305865774608, -8.153022439356294],
              [-38.533334774676618, -8.152986201242776],
              [-38.533365954657455, -8.152948518503049],
              [-38.533387699772739, -8.152905759572407],
              [-38.533403186354384, -8.152858651726158],
              [-38.533432006031596, -8.152821056716542],
              [-38.533482433694743, -8.152780682543172],
              [-38.533530022551481, -8.152761651849119],
              [-38.533567461758551, -8.152727684838071],
              [-38.533613432456022, -8.152694993968858],
              [-38.53364395652298, -8.152674948656662],
              [-38.533686568279599, -8.152642434819853],
              [-38.533710762205324, -8.152601487731433],
              [-38.533732049612105, -8.152561984536469],
              [-38.533740526948641, -8.152610024478198],
              [-38.533751277986809, -8.152654358488101],
              [-38.533774468196363, -8.152696264587206],
              [-38.533808921784441, -8.152732032875692],
              [-38.533873364313571, -8.152739524053326],
              [-38.53391288862165, -8.152707911166486],
              [-38.533944525759601, -8.152667334428065],
              [-38.533980793105414, -8.152626220294135],
              [-38.534028322763305, -8.152579872862589],
              [-38.534074743102508, -8.152550800548397],
              [-38.534123246263256, -8.152526072334211],
              [-38.534175560133733, -8.152502976633782],
              [-38.534225783635179, -8.152482049381478],
              [-38.534264667330859, -8.152454867886304],
              [-38.534305917479024, -8.152422261963842],
              [-38.534348165739765, -8.152390018992332],
              [-38.534368913751791, -8.152345902070163],
              [-38.534360710973139, -8.152295872500678],
              [-38.534380824173184, -8.15225121213431],
              [-38.534403726637656, -8.152227992176703],
              [-38.534404884970257, -8.152246807595864],
              [-38.534405903887432, -8.152307683198714],
              [-38.53444297568214, -8.152355846413732],
              [-38.534482244206529, -8.152388363890642],
              [-38.534539080011974, -8.152379836161053],
              [-38.534581885455061, -8.152336739520972],
              [-38.534620406845924, -8.152308833954713],
              [-38.53466420359679, -8.152271979655003],
              [-38.534696111385919, -8.152232759946967],
              [-38.534729193509222, -8.152198516462969],
              [-38.534773326899888, -8.152184546982662],
              [-38.534823892537133, -8.152181710516158],
              [-38.534874647354307, -8.152172271238088],
              [-38.534930672198762, -8.152158496284375],
              [-38.534983416877139, -8.152155300487355],
              [-38.535033706317435, -8.152155810414968],
              [-38.535086265578684, -8.15215596112923],
              [-38.535137459125814, -8.152159637913174],
              [-38.535190917482332, -8.152167297190637],
              [-38.535252352967497, -8.152184644006567],
              [-38.535297815097657, -8.152199168485454],
              [-38.535344087326791, -8.152219663743718],
              [-38.535387177699242, -8.152244225707655],
              [-38.535431537377484, -8.152270145907028],
              [-38.535475174087644, -8.152293261250225],
              [-38.535522163885602, -8.152321264857566],
              [-38.535568064689429, -8.152348995855341],
              [-38.53561696570366, -8.152372750380041],
              [-38.535664325302513, -8.152394965443795],
              [-38.53572905955852, -8.152385632519133],
              [-38.535776830975784, -8.152365606785587],
              [-38.535819886408241, -8.152341776648202],
              [-38.535877382438848, -8.152311721820777],
              [-38.53592135323246, -8.152281289696505],
              [-38.535963891200396, -8.152233850881695],
              [-38.536003888052136, -8.152185866439838],
              [-38.536044404327093, -8.152159772061848],
              [-38.536087276607041, -8.152137298469853],
              [-38.53605618489815, -8.152177423752853],
              [-38.536021271621003, -8.152225323564499],
              [-38.535996346734407, -8.15227061168328],
              [-38.535993874929716, -8.15228869932918],
              [-38.535995458759018, -8.152332208732272],
              [-38.535961907338802, -8.152301145175759],
              [-38.535907493742677, -8.152334640733478],
              [-38.535863432791523, -8.152364530038355],
              [-38.535823550770594, -8.152391620098227],
              [-38.535778404977783, -8.152417618699435],
              [-38.535735721192417, -8.152434032163072],
              [-38.535684597200955, -8.152448717362669],
              [-38.535617450892516, -8.152424308784591],
              [-38.535569728814572, -8.152401550585974],
              [-38.535517376740586, -8.15237914888807],
              [-38.535465481058239, -8.152354576849756],
              [-38.535415309085622, -8.152330911303631],
              [-38.535371488944087, -8.152309423886361],
              [-38.535328847644543, -8.152289023243762],
              [-38.535285121212006, -8.15226500325879],
              [-38.535234766770152, -8.152242151553576],
              [-38.535183498725267, -8.152224364127457],
              [-38.535130589195568, -8.152213087383331],
              [-38.535078488193975, -8.152209138200615],
              [-38.535021026806554, -8.152209162768653],
              [-38.534964106942041, -8.15221190152039],
              [-38.53490908971866, -8.152217808278833],
              [-38.534843170790609, -8.152231119617831],
              [-38.534780869839231, -8.152255741641282],
              [-38.534743426802322, -8.152293145958412],
              [-38.534711435658167, -8.152325943457168],
              [-38.534681876438512, -8.152375206072371],
              [-38.534686451034581, -8.152422789260509],
              [-38.534636564946489, -8.152465968238007],
              [-38.5345893246364, -8.152497662796527],
              [-38.534540093308308, -8.152524108827169],
              [-38.534489682720213, -8.152549829878838],
              [-38.53444000053203, -8.152573652260774],
              [-38.534389043600939, -8.152600819909486],
              [-38.53435705250822, -8.152633526924587],
              [-38.534322695265182, -8.152671387003359],
              [-38.534275889640263, -8.15271963480134],
              [-38.534236252291841, -8.152770513945319],
              [-38.534195263441148, -8.152812617654485],
              [-38.53415709094827, -8.152852734642922],
              [-38.534115292840397, -8.152888143939578],
              [-38.534070877497406, -8.152910344181668],
              [-38.534055701844139, -8.152924165951861],
              [-38.534048502566947, -8.152948308454953],
              [-38.534053795448997, -8.153002676396344],
              [-38.534017176586779, -8.152955327738182],
              [-38.53396850165705, -8.152971553218322],
              [-38.533922807261433, -8.153000807255724],
              [-38.533886102644267, -8.153027538956476],
              [-38.533845485110632, -8.153062497318141],
              [-38.533794694024948, -8.153103142475381],
              [-38.533754446076173, -8.153132493206749],
              [-38.533701655693541, -8.153175035551255],
              [-38.533640083258341, -8.15319748736804],
              [-38.533582610423153, -8.153207280563777],
              [-38.533537738725656, -8.153231560622256],
              [-38.533481961074656, -8.153266863312345],
              [-38.533420930756762, -8.153291486574131],
              [-38.533359810284608, -8.153315567008482],
              [-38.533323322147595, -8.153390419544913],
              [-38.533318004688638, -8.153435639591242],
              [-38.533319870411638, -8.153470646791757],
              [-38.533305057293184, -8.153484921209868],
              [-38.533242289595407, -8.153520396722049],
              [-38.533182166045499, -8.153545835059493],
              [-38.533118591411615, -8.153572626198532],
              [-38.533062987257104, -8.153614803402487],
              [-38.533022633214635, -8.15365726952022],
              [-38.532975744205295, -8.153698914034853],
              [-38.532933116011499, -8.153745447877663],
              [-38.532894284359671, -8.153805825295393],
              [-38.532847017832815, -8.15385986132673],
              [-38.532802945161869, -8.153899519112674],
              [-38.532743175816172, -8.153932555783191],
              [-38.532678786311294, -8.153957446409818],
              [-38.532619930698857, -8.153985328331952],
              [-38.532559800846528, -8.154016103250219],
              [-38.532505579231604, -8.154040191500137],
              [-38.532444171872918, -8.154076663423256],
              [-38.532387662337079, -8.154116668626051],
              [-38.532342860754774, -8.154158586824497],
              [-38.532304936529421, -8.154219146128796],
              [-38.532269196500174, -8.154274913975913],
              [-38.532223937536074, -8.154319816558035],
              [-38.532183764993171, -8.154362192339093],
              [-38.532138503801811, -8.154408994406415],
              [-38.532085618733575, -8.154454611788461],
              [-38.53202439745553, -8.154487103954628],
              [-38.531963529072002, -8.154528370392613],
              [-38.531914820534404, -8.154573268953577],
              [-38.531876996869677, -8.154625325809798],
              [-38.531823391738392, -8.154665605636231],
              [-38.531778867101451, -8.154703453730557],
              [-38.531775777118149, -8.15470652554013],
              [-38.531730879894631, -8.154752513906002],
              [-38.531709151440985, -8.154780709926422],
              [-38.531685786688975, -8.154810894005923],
              [-38.531646320466045, -8.154870275576316],
              [-38.531604306483835, -8.15493480997975],
              [-38.531576809917524, -8.155005240553319],
              [-38.531543783751857, -8.155068971263603],
              [-38.531507304163817, -8.155136044714476],
              [-38.53149460143748, -8.155208844137304],
              [-38.531483889371579, -8.155287163450577],
              [-38.531468678265099, -8.155331286592714],
              [-38.5314586589122, -8.155360400652869],
              [-38.531458037063871, -8.155426701471212],
              [-38.531436617636246, -8.155501299860553],
              [-38.531342268190528, -8.155528959642492],
              [-38.531311322013153, -8.15559938620274],
              [-38.531278374309068, -8.155673609441731],
              [-38.531258500462769, -8.15574612920272],
              [-38.531247710302246, -8.155813503686019],
              [-38.531243926499769, -8.155866685260799],
              [-38.531277191051487, -8.155910140831677],
              [-38.531301185725034, -8.155962721362989],
              [-38.531331528153089, -8.156021007727743],
              [-38.531357419253958, -8.156082092965066],
              [-38.531377142690374, -8.156138738907432],
              [-38.531324249536858, -8.156113079749844],
              [-38.53124227173479, -8.156118321635256],
              [-38.531192709100544, -8.156116907527933],
              [-38.53113575137985, -8.15615166577799],
              [-38.531083161905279, -8.156177022034548],
              [-38.5310444418838, -8.156141158128966],
              [-38.53103267321999, -8.156035496233915],
              [-38.531019758633022, -8.155978405894164],
              [-38.530992414949139, -8.155917499861978],
              [-38.530933432841053, -8.155897803461881],
              [-38.530869855061042, -8.155926855519853],
              [-38.530821686568629, -8.155975463099161],
              [-38.530791563056376, -8.156040915687523],
              [-38.530782755768108, -8.156120413054989],
              [-38.530757348315035, -8.156189579642788],
              [-38.530720525737557, -8.156239195317417],
              [-38.530675807557792, -8.156287083252082],
              [-38.530644269121915, -8.156320333173166],
              [-38.530652545912758, -8.156384382977132],
              [-38.530650392632566, -8.15644046090158],
              [-38.53062735715325, -8.156499499568515],
              [-38.53061101912099, -8.156575731924441],
              [-38.530573273522933, -8.156638371641829],
              [-38.530535269827837, -8.156688799997582],
              [-38.530527267832319, -8.156700459073139],
              [-38.530495805563355, -8.156746191482922],
              [-38.530480414948585, -8.156788414869755],
              [-38.530471490191211, -8.156812917099185],
              [-38.530444816505451, -8.156878011839966],
              [-38.530408787066364, -8.156947980161233],
              [-38.530395626170197, -8.157024216184787],
              [-38.530388359238565, -8.157106067078519],
              [-38.530383004430135, -8.157182945311911],
              [-38.530369840351703, -8.157261894897742],
              [-38.530346241110728, -8.157337214298344],
              [-38.530342060585696, -8.157419068765879],
              [-38.530320822381874, -8.157493667281605],
              [-38.530307747048752, -8.157574335559124],
              [-38.530301020945352, -8.157659443356321],
              [-38.530293659185752, -8.157744731320662],
              [-38.530282764075942, -8.157824045338545],
              [-38.530279211682199, -8.157912141737668],
              [-38.53028574127552, -8.157995365399834],
              [-38.530286002901612, -8.158082290339518],
              [-38.530299529432114, -8.158159461809133],
              [-38.530305513310225, -8.158243589360621],
              [-38.530301521175993, -8.158319564643683],
              [-38.53030414290992, -8.158406582770285],
              [-38.530332208630448, -8.158471107772879],
              [-38.530344713324013, -8.158490479053311],
              [-38.530367638528247, -8.158526053368034],
              [-38.53041424779282, -8.158569253120614],
              [-38.530477405175688, -8.158589678070379],
              [-38.530552827450109, -8.158601614706441],
              [-38.530615583831789, -8.158576722580028],
              [-38.530667099731083, -8.158538159130106],
              [-38.530688143404404, -8.158474686004427],
              [-38.530699840896112, -8.158407855318464],
              [-38.530718056211867, -8.15835658997217],
              [-38.530766074528415, -8.158359178243948],
              [-38.530805880574064, -8.15839802836549],
              [-38.530869203898455, -8.158431840383674],
              [-38.530943001234355, -8.158435996168695],
              [-38.531027154690555, -8.158433741814225],
              [-38.531102861098006, -8.158435548020373],
              [-38.531179499946681, -8.158416189456553],
              [-38.53124190822669, -8.158378362149588],
              [-38.531298780229818, -8.158339533453384],
              [-38.531371423159101, -8.158321617466299],
              [-38.531438805520608, -8.158299805934488],
              [-38.531506361635778, -8.158284688061288],
              [-38.531577998729354, -8.158273012081208],
              [-38.531610765699895, -8.158276215788064],
              [-38.531579879674915, -8.158294813257482],
              [-38.531531718441748, -8.158336998800443],
              [-38.531482824974631, -8.158384339270814],
              [-38.531431212175058, -8.158428239403079],
              [-38.531384052834397, -8.158467531611803],
              [-38.531345393482582, -8.158535235663429],
              [-38.531319333645129, -8.158618873886327],
              [-38.531321693803001, -8.158696846486226],
              [-38.531324542096463, -8.158745422657891],
              [-38.531318452248712, -8.158829988500184],
              [-38.531309866045071, -8.15887556651982],
              [-38.531280455518413, -8.158952236035427],
              [-38.53125712402305, -8.159031535696972],
              [-38.531243001004619, -8.159076564594862],
              [-38.531221298958371, -8.159159755586341],
              [-38.531227648884119, -8.159241531795844],
              [-38.531235002741596, -8.159318696100193],
              [-38.531242647906936, -8.159379579330714],
              [-38.531264552656431, -8.159434056946496],
              [-38.531281655151517, -8.15948084056172],
              [-38.531302934057834, -8.159527086294075],
              [-38.531328205502739, -8.159574783880574],
              [-38.531356109402601, -8.159622574962908],
              [-38.53137684955891, -8.159663754741008],
              [-38.53140530193027, -8.159708290172778],
              [-38.531431855350185, -8.15974649174672],
              [-38.531494043776519, -8.159819649109997],
              [-38.53156898609722, -8.159854198086423],
              [-38.531628703143049, -8.159866930447938],
              [-38.531722995269533, -8.15988938116022],
              [-38.531768228835759, -8.159866910821671],
              [-38.531754660930076, -8.159902985602459],
              [-38.531754636501361, -8.159923970504389],
              [-38.53179536941898, -8.159946630651794],
              [-38.531841997425708, -8.159974001126365],
              [-38.531882256147632, -8.160014118008807],
              [-38.531931220498734, -8.160062023842464],
              [-38.531985165961444, -8.160119975629],
              [-38.532046546701572, -8.160185262632815],
              [-38.532104394910128, -8.16024385207896],
              [-38.532168498289195, -8.160309775376613],
              [-38.532232874548988, -8.160375246718642],
              [-38.532282360828418, -8.160442781263239],
              [-38.532291958677327, -8.160463958180003],
              [-38.532285368600597, -8.160510443048274],
              [-38.532264330519645, -8.160569212746086],
              [-38.532229492075039, -8.160629775553057],
              [-38.532204803922163, -8.160704822443552],
              [-38.532188284833779, -8.160780692836246],
              [-38.532166771473641, -8.160857823791865],
              [-38.532148621627229, -8.16093088827737],
              [-38.532139006910811, -8.16100224402604],
              [-38.532126051921402, -8.161057676313819],
              [-38.532137042245374, -8.16113041264459],
              [-38.532177257126527, -8.161208338507933],
              [-38.532213618432934, -8.161243385534551],
              [-38.532254347615975, -8.161269392373313],
              [-38.532317833189083, -8.1613202998616],
              [-38.532354475477234, -8.161347930113546],
              [-38.532421145778009, -8.161392419151104],
              [-38.532483384367467, -8.161422792506587],
              [-38.532543823804936, -8.161439053219715],
              [-38.532618793011459, -8.161450808075042],
              [-38.532693955235992, -8.161452703839821],
              [-38.532766493932549, -8.161446817667434],
              [-38.532846583186789, -8.161427191441563],
              [-38.532938292459463, -8.161407216788918],
              [-38.53301419823638, -8.161394188722747],
              [-38.533093728519788, -8.161386863314476],
              [-38.533171449117695, -8.161374470476169],
              [-38.533215229703515, -8.161352541021648],
              [-38.533258652840907, -8.161325726726236],
              [-38.533305979747823, -8.161298645566504],
              [-38.533350037585976, -8.161272465157889],
              [-38.533394916829458, -8.16124248669365],
              [-38.533439609473326, -8.161216849719599],
              [-38.533488840283418, -8.161191851132832],
              [-38.533534257042753, -8.161168024027598],
              [-38.533572596355988, -8.161141384746246],
              [-38.533638197102022, -8.161090626082613],
              [-38.533691070021163, -8.161056134170686],
              [-38.533684302520356, -8.161099181677001],
              [-38.533649286871402, -8.16115612631765],
              [-38.533604471684683, -8.161209170222572],
              [-38.533566386762161, -8.161251277143167],
              [-38.533537380485747, -8.161292670895959],
              [-38.53355148711362, -8.161339903241631],
              [-38.533577220378902, -8.1613810886707],
              [-38.53360232176869, -8.161419197992501],
              [-38.533632772048215, -8.161463373775661],
              [-38.53366132543205, -8.161499406665705],
              [-38.533695768181985, -8.161545124730232],
              [-38.533733135034375, -8.161574293386861],
              [-38.533782159359781, -8.161570822176699],
              [-38.533840922536555, -8.161545291806508],
              [-38.533908194431383, -8.16154084660524],
              [-38.533972449890335, -8.161554307478772],
              [-38.534019080755066, -8.161579506867069],
              [-38.533994377445154, -8.161589518653269],
              [-38.53394399563755, -8.161589370234928],
              [-38.533895784330362, -8.161596279587664],
              [-38.533851545140934, -8.161622278941936],
              [-38.533808131443486, -8.161640952604603],
              [-38.533759010458709, -8.161649489035112],
              [-38.533694666208319, -8.161634309431328],
              [-38.533641871042185, -8.161601685820729],
              [-38.533606416935889, -8.161567001767986],
              [-38.533565159220473, -8.161526974343824],
              [-38.53353070389737, -8.161492110530608],
              [-38.533490247356994, -8.16146574231939],
              [-38.533438328530821, -8.161460255383172],
              [-38.533390547511431, -8.16148751693904],
              [-38.533350194239198, -8.161528626247705],
              [-38.533299850527257, -8.161573884846735],
              [-38.533254055058251, -8.16161109842543],
              [-38.533198722932639, -8.161652552265897],
              [-38.533150297186957, -8.161687772849945],
              [-38.533087341138504, -8.161727951554544],
              [-38.533018477664314, -8.161774002836992],
              [-38.532948254706923, -8.16181815304097],
              [-38.532887923203617, -8.161865209112499],
              [-38.532837849947128, -8.161912005649031],
              [-38.532796044123856, -8.161953113220774],
              [-38.532766400061426, -8.161996405672074],
              [-38.532725670318385, -8.162048911465131],
              [-38.532700905355789, -8.162111928138149],
              [-38.532672047055399, -8.162182085816712],
              [-38.532636479766744, -8.162244818663437],
              [-38.532618889842055, -8.162304768231067],
              [-38.532613544689411, -8.162373596251969],
              [-38.532622470771599, -8.162426068877755],
              [-38.532636067870939, -8.162442999145547],
              [-38.532665871729485, -8.162496581289288],
              [-38.532667575389162, -8.162514764164399],
              [-38.53268575394187, -8.162573217316035],
              [-38.532699303114356, -8.16263139376866],
              [-38.532723748752986, -8.162686959676639],
              [-38.532736749794424, -8.162748120421243],
              [-38.532755649128148, -8.162811277920582],
              [-38.532797514585809, -8.162875185531972],
              [-38.532807660757697, -8.162893287693542],
              [-38.532833207538097, -8.162938724210266],
              [-38.53285358268068, -8.162981803000827],
              [-38.532860011982301, -8.162995559164237],
              [-38.53286148733045, -8.163053993042286],
              [-38.532857700254972, -8.163110069090598],
              [-38.532862881024265, -8.163182708251172],
              [-38.532872323238699, -8.163259784488886],
              [-38.532889669499362, -8.163331442706175],
              [-38.532893326584464, -8.163387255983686],
              [-38.532869400840219, -8.163431097743702],
              [-38.53284637982911, -8.163477563661726],
              [-38.532841772268121, -8.163536623687248],
              [-38.532849234483358, -8.163598953918255],
              [-38.532882746606738, -8.163664389576084],
              [-38.532929334339656, -8.163726855387818],
              [-38.532984021985243, -8.16377160188871],
              [-38.533052865974398, -8.163820796868693],
              [-38.533133150515866, -8.163867924625263],
              [-38.533202632302221, -8.163915130366536],
              [-38.533245450041811, -8.163940868171508],
              [-38.533289367107407, -8.163958014272021],
              [-38.533339637849586, -8.163975891308457],
              [-38.533384379491096, -8.163986525784606],
              [-38.533427118019056, -8.164002313728396],
              [-38.533510169248295, -8.164012178259092],
              [-38.533598587657686, -8.164012370559822],
              [-38.533690823287671, -8.164008677788452],
              [-38.533775802385321, -8.164000001765251],
              [-38.53385243245615, -8.163989054805732],
              [-38.533900360810613, -8.163991552187682],
              [-38.533865066497015, -8.164053742738856],
              [-38.533846306392491, -8.164105188497754],
              [-38.53382472710561, -8.164160972719767],
              [-38.533793535118321, -8.164208243357521],
              [-38.533763789395501, -8.164260852342721],
              [-38.53373886008319, -8.16430903470901],
              [-38.533709679266771, -8.164344187047689],
              [-38.533689213529222, -8.16437907806357],
              [-38.533683301153047, -8.164389201911169],
              [-38.533688959471959, -8.164441580302936],
              [-38.533720208516449, -8.164501676608927],
              [-38.533763627081328, -8.164557354763351],
              [-38.533812865892322, -8.164604084838109],
              [-38.533883339218768, -8.164657532821888],
              [-38.533923973039975, -8.164687881113753],
              [-38.533966060693437, -8.164717055195316],
              [-38.534009691490155, -8.164746321501006],
              [-38.534052960435751, -8.164774501958448],
              [-38.534092691943926, -8.164800145676951],
              [-38.534133873404762, -8.164827961915151],
              [-38.534177696894687, -8.164847640482542],
              [-38.534226606840853, -8.164864882684755],
              [-38.534274068205363, -8.164878776480121],
              [-38.534322806137361, -8.164887787314413],
              [-38.534372910680737, -8.164892548456603],
              [-38.534419201670687, -8.164898028825929],
              [-38.534490155633584, -8.164928502379894],
              [-38.534559280517705, -8.164970642165722],
              [-38.534633400356142, -8.165010978635584],
              [-38.534715159048233, -8.165039745976644],
              [-38.534741656887398, -8.16504809803776],
              [-38.534793745093175, -8.165064529755359],
              [-38.534839676449266, -8.165067024738855],
              [-38.534866817700788, -8.165068503156318],
              [-38.534937175939703, -8.165064694528402],
              [-38.534987113293703, -8.16505706343869],
              [-38.535060546822933, -8.165062936717574],
              [-38.535107657472601, -8.165066156643348],
              [-38.535159117357765, -8.165076436836763],
              [-38.535206036412049, -8.165088339952563],
              [-38.535261122340259, -8.16510305645879],
              [-38.535314213957342, -8.165115328456618],
              [-38.535363492963008, -8.165127505620092],
              [-38.535409050449459, -8.165139316698152],
              [-38.535452694261672, -8.165157457237141],
              [-38.535533454690416, -8.165186042367662],
              [-38.535615782827627, -8.165193463440305],
              [-38.535644920605762, -8.165195486810008],
              [-38.535695934465494, -8.165198982501884],
              [-38.535759323632959, -8.165177075277711],
              [-38.535799782462398, -8.16512330254022],
              [-38.535806709463564, -8.165177853226284],
              [-38.535832066601301, -8.165230706447538],
              [-38.535883666941004, -8.165276624923205],
              [-38.535951299442758, -8.165274893399197],
              [-38.536026043929802, -8.165246124771954],
              [-38.536107055502825, -8.165214468836771],
              [-38.536191956682714, -8.165194847498466],
              [-38.536276018930643, -8.1651942201678],
              [-38.53635744065739, -8.165200464172162],
              [-38.536439219036559, -8.165212316612447],
              [-38.536509732889783, -8.165231030517823],
              [-38.536579091440885, -8.165228215436439],
              [-38.536660358211215, -8.165211393860881],
              [-38.53674300594659, -8.165177840168866],
              [-38.536825743926904, -8.165144738824006],
              [-38.536891423352095, -8.165104743573425],
              [-38.536934859085562, -8.165067165085041],
              [-38.536920811381805, -8.165125943030745],
              [-38.536906514990996, -8.165164188013055],
              [-38.536917174089574, -8.165210059533711],
              [-38.536961426036996, -8.165251989571514],
              [-38.536985262282499, -8.165285574644095],
              [-38.536945867385953, -8.165282725568407],
              [-38.536889684819251, -8.165274249242891],
              [-38.536842459265031, -8.165291923877874],
              [-38.536801223668121, -8.165310871692499],
              [-38.536748995039801, -8.165337404919928],
              [-38.536690586857389, -8.165369629565198],
              [-38.5366329015622, -8.165404749503049],
              [-38.536580021710932, -8.165444940262381],
              [-38.536523699125738, -8.165479157219993],
              [-38.536457415026149, -8.165492377826114],
              [-38.536379697968016, -8.16550106271335],
              [-38.536296824861743, -8.165493822072474],
              [-38.536219855742488, -8.165483693703729],
              [-38.536147140303648, -8.16548478628104],
              [-38.536077587727931, -8.165498364888876],
              [-38.53599549530685, -8.165522602486078],
              [-38.535913569918684, -8.16555941312888],
              [-38.535837896056684, -8.165606813841801],
              [-38.535767679060456, -8.165645446922568],
              [-38.535725256461653, -8.165670272661528],
              [-38.535678300437169, -8.165690299224075],
              [-38.535615648389715, -8.165702709783611],
              [-38.53554638106089, -8.165705072538081],
              [-38.53547485575146, -8.165697482936343],
              [-38.535400328773605, -8.165695045658831],
              [-38.535325793641121, -8.165699663638073],
              [-38.535257360791853, -8.165686921766138],
              [-38.53518402702359, -8.165673179280944],
              [-38.53510833697851, -8.165655996889614],
              [-38.535030659216417, -8.165630671494611],
              [-38.534954697582535, -8.165612855597326],
              [-38.534885605340705, -8.16562082648303],
              [-38.53482784300828, -8.165643915908467],
              [-38.534781168184246, -8.165656254242528],
              [-38.534712609195125, -8.165674085011181],
              [-38.534638874636691, -8.165692904794176],
              [-38.534574118201384, -8.16571951378204],
              [-38.534522001431029, -8.165727685023622],
              [-38.534469648254507, -8.165704830849943],
              [-38.53442765312716, -8.165674028779534],
              [-38.534379936091383, -8.165645662336427],
              [-38.534332581759962, -8.165617658114376],
              [-38.534274786997194, -8.165590365495591],
              [-38.534208920971842, -8.16555609875679],
              [-38.534141684255907, -8.165529609327578],
              [-38.534084156824733, -8.165506658700433],
              [-38.53402301333611, -8.165470678773998],
              [-38.533961793761712, -8.165422035430918],
              [-38.53391415487922, -8.165404613752809],
              [-38.533872053524789, -8.165387198444645],
              [-38.533824773256327, -8.165373666617572],
              [-38.533776757631387, -8.165368003287263],
              [-38.533721723899937, -8.165386573081463],
              [-38.53367949179767, -8.165403619923786],
              [-38.5336253656384, -8.165422371654159],
              [-38.533577696649772, -8.165430909707904],
              [-38.533519322970506, -8.165433194214854],
              [-38.533456666926369, -8.165449041621056],
              [-38.533390008617822, -8.165471758778011],
              [-38.533320180280242, -8.165488231062161],
              [-38.533259983449256, -8.165497025993822],
              [-38.53320150690206, -8.165509712347795],
              [-38.533157087139585, -8.165534445054867],
              [-38.533118834467544, -8.165564250196081],
              [-38.533088274268565, -8.165614506419326],
              [-38.533063426001625, -8.165670919982322],
              [-38.533035049950854, -8.165716475201144],
              [-38.53298284816195, -8.165719671269684],
              [-38.532967049333493, -8.165722457065364],
              [-38.532919017287853, -8.165730904167571],
              [-38.532870430789366, -8.165747762685704],
              [-38.532856354345121, -8.16575263086655],
              [-38.53280767685235, -8.165769670176319],
              [-38.532766530871022, -8.165789431740253],
              [-38.532705298715776, -8.16582979323379],
              [-38.532640254033744, -8.165869969411602],
              [-38.532584732527354, -8.165917573659341],
              [-38.532545898178498, -8.165979126816078],
              [-38.532522037757566, -8.166044677158951],
              [-38.53250398414751, -8.166112857321208],
              [-38.532489836563023, -8.166178871138351],
              [-38.532467439845824, -8.166234744774149],
              [-38.532429256053902, -8.166283273541085],
              [-38.532397156252749, -8.166330271642828],
              [-38.532374836926962, -8.166397632798791],
              [-38.532371571669124, -8.16647324711972],
              [-38.532394535107848, -8.166554499778313],
              [-38.532430065039712, -8.166602299612984],
              [-38.532470766035345, -8.166652999889621],
              [-38.532511841100821, -8.166694293559123],
              [-38.532558179378995, -8.166737311895464],
              [-38.532621657612509, -8.166795274640455],
              [-38.532717057187092, -8.166881676237692],
              [-38.532758588722295, -8.166920799556852],
              [-38.53280558690436, -8.166942833703008],
              [-38.532861399632388, -8.166957189573376],
              [-38.532930365919889, -8.166979701317764],
              [-38.5330036876313, -8.167003936668596],
              [-38.533068121690192, -8.167020654216328],
              [-38.53313011206567, -8.167031037276718],
              [-38.533180379498852, -8.167052080176671],
              [-38.533213469765052, -8.167090198742454],
              [-38.533251555008952, -8.167126514019952],
              [-38.533298625431485, -8.167164558246991],
              [-38.533332172871653, -8.167199873310603],
              [-38.533370603084776, -8.167251837218762],
              [-38.533404676311619, -8.1673034342923],
              [-38.533421406563811, -8.167358539011456],
              [-38.533439142010963, -8.167407946395381],
              [-38.533440227794777, -8.167411023024195],
              [-38.533456693487821, -8.16745943396894],
              [-38.533489225379931, -8.16750940111891],
              [-38.533539836902143, -8.167546906688443],
              [-38.533592558725381, -8.167565148349494],
              [-38.533658305278998, -8.167546138672851],
              [-38.53370398797022, -8.167528462656167],
              [-38.53375048620444, -8.167512053905407],
              [-38.533799978271524, -8.167497276738221],
              [-38.533882255811598, -8.167470416715224],
              [-38.533964356607683, -8.167439395666451],
              [-38.534018326748836, -8.167398844727561],
              [-38.534064585129258, -8.167354395455911],
              [-38.534106374015934, -8.167328302847162],
              [-38.534167269772645, -8.167343297559103],
              [-38.534219148482151, -8.167384060786874],
              [-38.534260225485127, -8.167423907057177],
              [-38.534304324741136, -8.167440872373072],
              [-38.534364775429822, -8.167448268552658],
              [-38.534420542157861, -8.167424091488339],
              [-38.534494719628206, -8.167415041121004],
              [-38.534566529704101, -8.167412138773319],
              [-38.534632256817069, -8.167409862585297],
              [-38.534691384044322, -8.167384242109346],
              [-38.534756684900991, -8.1673580860064],
              [-38.534827251485837, -8.167331483682416],
              [-38.53487512967196, -8.167299247233375],
              [-38.534918846438771, -8.16725452358064],
              [-38.534948948991953, -8.167207432521909],
              [-38.534972417203647, -8.16716675595171],
              [-38.534985413847423, -8.167153655299824],
              [-38.535034108701332, -8.167121691124798],
              [-38.535083872601078, -8.16710727591658],
              [-38.53519043902935, -8.167114905838648],
              [-38.535261849011306, -8.167143932565651],
              [-38.535328727141106, -8.167166803322095],
              [-38.53540515228957, -8.167176569442336],
              [-38.535478132003519, -8.167182713502498],
              [-38.535520228916205, -8.167204199004706],
              [-38.535560058436417, -8.167223872855107],
              [-38.535608533860582, -8.167224652090239],
              [-38.535658131247516, -8.167197211488585],
              [-38.535693958673129, -8.167145061607538],
              [-38.535718269320768, -8.167082315594071],
              [-38.535739767364184, -8.16701820956818],
              [-38.53576452928116, -8.166957725375914],
              [-38.535777857577699, -8.166893248168272],
              [-38.535769308027533, -8.166828745870619],
              [-38.535748870869405, -8.166760340490663],
              [-38.535727078191698, -8.166686596869145],
              [-38.535709915165036, -8.166612949008874],
              [-38.535707161610809, -8.166560483514008],
              [-38.535751359355288, -8.166570755313515],
              [-38.535793256275646, -8.166608160161619],
              [-38.535837044423424, -8.166658682758669],
              [-38.53589091390058, -8.166705056103629],
              [-38.535948590799059, -8.166756046874305],
              [-38.536012895550115, -8.166806231163585],
              [-38.536058811979444, -8.166821841587421],
              [-38.536106544210099, -8.166837273182503],
              [-38.536156994503628, -8.166857320954387],
              [-38.536206722533549, -8.166873930704995],
              [-38.536264627084883, -8.166885032225801],
              [-38.536319357281208, -8.166893597437731],
              [-38.53636682689811, -8.16690061663726],
              [-38.536455130056559, -8.166922607224389],
              [-38.536511309605295, -8.166933887639917],
              [-38.53645907312697, -8.166967023825071],
              [-38.536419994238344, -8.167004878591708],
              [-38.536390355886873, -8.167043196428041],
              [-38.536365339353004, -8.167088393931154],
              [-38.536352004149506, -8.167158931459239],
              [-38.5363517416097, -8.167229122151006],
              [-38.536367460755528, -8.167295441698885],
              [-38.536395724597796, -8.167346941435415],
              [-38.536405548508675, -8.167408550709908],
              [-38.536409746070696, -8.167468434946324],
              [-38.536409217772849, -8.167532926837813],
              [-38.536407692359447, -8.167596151254694],
              [-38.53642993012847, -8.16767767425412],
              [-38.536444644255418, -8.167749600691268],
              [-38.536469988684594, -8.167813669951146],
              [-38.536507309987037, -8.167882999158833],
              [-38.536556174301602, -8.167940220971182],
              [-38.536612241518867, -8.167970315331901],
              [-38.536670400641249, -8.167996974892134],
              [-38.536741273832149, -8.168019397738105],
              [-38.53681331684578, -8.168050776689729],
              [-38.536885204061036, -8.16805981372956],
              [-38.536962193080818, -8.168053117890885],
              [-38.53704261859739, -8.168057822959776],
              [-38.537126408488746, -8.168057737887697],
              [-38.537211571640263, -8.168047614161324],
              [-38.537294631960684, -8.168050422694275],
              [-38.537375965073295, -8.16805530963936],
              [-38.537452309885118, -8.168056210965441],
              [-38.537529114947013, -8.168051595211491],
              [-38.537595482108976, -8.168045429828689],
              [-38.537641594462798, -8.1680487387912],
              [-38.537610966410327, -8.168079367139221],
              [-38.537566905658224, -8.168107628377035],
              [-38.537515580021889, -8.168138052164151],
              [-38.537457624332554, -8.168171181936975],
              [-38.537392144119316, -8.168195438773328],
              [-38.537318042112709, -8.16821769579161],
              [-38.537260531129085, -8.168258785846751],
              [-38.537225703202509, -8.168310213393609],
              [-38.53721401129981, -8.168372793027119],
              [-38.537214683365605, -8.168419828999509],
              [-38.537233245617919, -8.168461186932191],
              [-38.537251857714409, -8.16853809268104],
              [-38.537271503708489, -8.168584336276437],
              [-38.53722377830573, -8.168562754056657],
              [-38.537166272441986, -8.168520537519239],
              [-38.537111813715754, -8.168512515433759],
              [-38.537061795283144, -8.168511372795908],
              [-38.537011125133247, -8.168524339967037],
              [-38.536966804565076, -8.168541656107037],
              [-38.53688789468783, -8.168560832162134],
              [-38.536805085754665, -8.168576114289783],
              [-38.536720658735646, -8.168577555344752],
              [-38.536670726072074, -8.168580754467092],
              [-38.536615712866784, -8.168581234197122],
              [-38.536562054160719, -8.16858795667976],
              [-38.536510666514197, -8.168593324971466],
              [-38.536455731878938, -8.168604387689257],
              [-38.536408518119309, -8.168611479342191],
              [-38.53635649490559, -8.168616937338292],
              [-38.53626335051176, -8.168620539165804],
              [-38.536241381790973, -8.168620513992792],
              [-38.536217506798089, -8.168620396181801],
              [-38.53614160942444, -8.168625012720216],
              [-38.536128082288826, -8.168625811286875],
              [-38.536037923497204, -8.168638190357324],
              [-38.535993517009864, -8.168651255031072],
              [-38.535912152153657, -8.168673774812508],
              [-38.535870457038762, -8.168697244595698],
              [-38.535836919462497, -8.168731668458095],
              [-38.535796200970438, -8.168774134335219],
              [-38.535750301850584, -8.168821930950367],
              [-38.535719646842459, -8.168875714899501],
              [-38.535749638861482, -8.168924322187827],
              [-38.535778907365454, -8.168970576884673],
              [-38.535805977615802, -8.169033743637415],
              [-38.535817142574331, -8.16911317355866],
              [-38.535829528117411, -8.169157599841494],
              [-38.535864838674385, -8.16923868559663],
              [-38.535862217106356, -8.16930769769081],
              [-38.535872402413808, -8.169370754621594],
              [-38.535920376973472, -8.169412417696346],
              [-38.53596037293066, -8.169445388196632],
              [-38.536002459342306, -8.169476190235031],
              [-38.536050993545516, -8.169504919258923],
              [-38.53608918217332, -8.169530741943872],
              [-38.536141966969026, -8.169573405483993],
              [-38.536188769481328, -8.169608102359028],
              [-38.536227866346501, -8.169633564263572],
              [-38.536305424944707, -8.169684215977453],
              [-38.536376642004555, -8.169723553874613],
              [-38.536441503428243, -8.169763789002243],
              [-38.536507990037038, -8.169811714427139],
              [-38.536567949570994, -8.169851763016657],
              [-38.536618361971094, -8.169905006701232],
              [-38.536589178978367, -8.169941877820621],
              [-38.536549460265533, -8.169983259486045],
              [-38.536512015327105, -8.170020935207173],
              [-38.536470300791592, -8.170061138697864],
              [-38.536434654011195, -8.170113922004456],
              [-38.536429501119024, -8.17017388597464],
              [-38.536466575214433, -8.170221596807293],
              [-38.536527280872328, -8.170244460323127],
              [-38.53659455349375, -8.170240647922663],
              [-38.536657450198582, -8.170252840557369],
              [-38.53672513103362, -8.170288646710457],
              [-38.536763043580052, -8.17031781575638],
              [-38.536802321277392, -8.170344001435755],
              [-38.5368716162683, -8.170397085803261],
              [-38.53693292803684, -8.170445457503851],
              [-38.536970297183146, -8.170473540482163],
              [-38.537007486442256, -8.170500176014736],
              [-38.537054937734858, -8.170523476550027],
              [-38.537102579661834, -8.170538907947686],
              [-38.537193599980355, -8.170567052281006],
              [-38.537241788892835, -8.170580494337687],
              [-38.537286716803074, -8.17058778190289],
              [-38.537332645694647, -8.170592990204582],
              [-38.537415890082215, -8.170594080357759],
              [-38.537490683363465, -8.17060257792304],
              [-38.537540696124694, -8.170608876297278],
              [-38.537534414789114, -8.170624065105967],
              [-38.537498055593581, -8.170664907990684],
              [-38.537458882304676, -8.170705657202449],
              [-38.537425982537776, -8.170738001517513],
              [-38.537380547995234, -8.17077648225483],
              [-38.537340012249047, -8.170817863061938],
              [-38.537298292078027, -8.17086295105447],
              [-38.53723768207832, -8.170914530003516],
              [-38.537184967871923, -8.170967927020371],
              [-38.537136416312322, -8.171032816234026],
              [-38.53708941571756, -8.171091013744991],
              [-38.537060023695702, -8.171151673609883],
              [-38.537048152705303, -8.171211991711488],
              [-38.537048795963976, -8.171283992478797],
              [-38.537062323376347, -8.171361796935601],
              [-38.537095000054912, -8.171444326821929],
              [-38.537124320994543, -8.171524139297695],
              [-38.537136757288863, -8.171603480193092],
              [-38.537139908964996, -8.171625735064621],
              [-38.537146752519234, -8.171674134874616],
              [-38.537151959419006, -8.171724794119488],
              [-38.537154204124903, -8.171746324334856],
              [-38.537166400921258, -8.171797172480886],
              [-38.537193031832473, -8.171848037139089],
              [-38.537232017873464, -8.171891137030999],
              [-38.537279735429593, -8.171919864990647],
              [-38.537319726013017, -8.171957810242613],
              [-38.537375424880231, -8.171992969433044],
              [-38.537449932013963, -8.172013677753094],
              [-38.537529072925913, -8.17203095416945],
              [-38.537574177774566, -8.172042402719519],
              [-38.537628088034879, -8.17205413268562],
              [-38.537682356007046, -8.172070566573362],
              [-38.537731176416827, -8.172087627395486],
              [-38.537781177916301, -8.172103875490599],
              [-38.537828371985739, -8.172114240972453],
              [-38.537876110845879, -8.172124516618981],
              [-38.537922310288153, -8.172131443766336],
              [-38.537969420660531, -8.172135658379625],
              [-38.53801616843181, -8.172139420311815],
              [-38.538099670719546, -8.172153354864296],
              [-38.53818009801644, -8.172157245683055],
              [-38.538258814002063, -8.172149375732049],
              [-38.538332630117232, -8.172139510223175],
              [-38.538417785911264, -8.172136532014878],
              [-38.53849866087851, -8.172145850419886],
              [-38.538580094979139, -8.172142596574895],
              [-38.538640148378938, -8.172101418810179],
              [-38.538664455283424, -8.172041838453005],
              [-38.538658990289989, -8.171978606051331],
              [-38.538617932543417, -8.171921212459601],
              [-38.538567246349132, -8.171868782759677],
              [-38.538505748235899, -8.17182421007225],
              [-38.538449418808781, -8.171784708602486],
              [-38.538420069414023, -8.171729408753363],
              [-38.53839645521095, -8.171660185789575],
              [-38.538391006998403, -8.171582390582051],
              [-38.538391242199722, -8.171535717455528],
              [-38.538380990418318, -8.171451132843126],
              [-38.538369375269646, -8.171367993911854],
              [-38.538367569301208, -8.171280614913544],
              [-38.538383363616376, -8.171202934394158],
              [-38.538395344626757, -8.171125701784494],
              [-38.538379087725417, -8.171052959573272],
              [-38.538349568782557, -8.170987257510506],
              [-38.538345912070817, -8.170930449282615],
              [-38.538377658458231, -8.170874766875801],
              [-38.538427328627265, -8.17086324564518],
              [-38.538486507765626, -8.17087172521741],
              [-38.538553676931038, -8.170879038013489],
              [-38.538628500637273, -8.170861123329304],
              [-38.53867830344312, -8.170813149932686],
              [-38.538683741652157, -8.170741608345816],
              [-38.53867028518777, -8.170680809042407],
              [-38.538671717322828, -8.170619484000174],
              [-38.538706434564666, -8.170585423055504],
              [-38.538750217991179, -8.170561684007509],
              [-38.538810748830322, -8.170578848494509],
              [-38.538856476045389, -8.170601604145613],
              [-38.538912826951808, -8.170622291494665],
              [-38.5389758125105, -8.170636292923623],
              [-38.53903628964737, -8.170621165835804],
              [-38.539107492557292, -8.170594111248842],
              [-38.539174444555663, -8.170553303053277],
              [-38.539239497520605, -8.170506070567876],
              [-38.539312713228163, -8.170465359932724],
              [-38.539390736231688, -8.170428182396732],
              [-38.539411447619329, -8.170416447169609],
              [-38.5394605915887, -8.17038873424047],
              [-38.539498833623632, -8.170368245084592],
              [-38.539521179257527, -8.170356330806731],
              [-38.539588927672725, -8.170333433006494],
              [-38.539652665371868, -8.170324822100799],
              [-38.539715199191818, -8.170336923400695],
              [-38.539766639169443, -8.170365293493875],
              [-38.539791375865498, -8.170405753823959],
              [-38.539805124005646, -8.170449819748912],
              [-38.539835477070056, -8.17050077893551],
              [-38.539893746632366, -8.170510794956106],
              [-38.53997183323866, -8.170497315891065],
              [-38.540044392184271, -8.170475147102506],
              [-38.540128031289683, -8.170448649181161],
              [-38.540170808587419, -8.170431421399069],
              [-38.540247653854749, -8.170392071498474],
              [-38.540323764792099, -8.170359776031074],
              [-38.540404499199404, -8.170333003393202],
              [-38.540448092772479, -8.170316409673948],
              [-38.540492770221796, -8.170304611155089],
              [-38.540537625711863, -8.170295888213102],
              [-38.54058429225411, -8.170291147225571],
              [-38.540633489989567, -8.170295725698116],
              [-38.540682785849675, -8.170293882159307],
              [-38.540731263239472, -8.170293304018312],
              [-38.540780743968853, -8.170288566202178],
              [-38.540832859909969, -8.170281570061407],
              [-38.540885418276297, -8.170284614626937],
              [-38.540902482280046, -8.170286985749172],
              [-38.54093415953092, -8.170291453853912],
              [-38.5409858024491, -8.170301190842036],
              [-38.541036542941136, -8.170306223277734],
              [-38.541122158044971, -8.170298179666805],
              [-38.541161078125491, -8.170240334294858],
              [-38.541180587749416, -8.17016799453258],
              [-38.541197387284733, -8.170083983341954],
              [-38.541207245782623, -8.170036507032384],
              [-38.541219097310645, -8.169992651076356],
              [-38.541229407948151, -8.169946712968434],
              [-38.541248120249264, -8.169857095896054],
              [-38.541261515855709, -8.169812518069447],
              [-38.541314303229427, -8.169694447126368],
              [-38.541329978755634, -8.169640736192287],
              [-38.541355523817913, -8.169688885801234],
              [-38.541368259384114, -8.169745342508278],
              [-38.541392879308432, -8.169808687063197],
              [-38.541413956334722, -8.169874198458421],
              [-38.541418756946612, -8.169883610944289],
              [-38.541442487978294, -8.169930401707392],
              [-38.541472039685786, -8.169967792019458],
              [-38.541476753442915, -8.169973767216602],
              [-38.541526372540183, -8.170006838550272],
              [-38.541591742039053, -8.170000128682318],
              [-38.541621257169169, -8.169990031452876],
              [-38.541670377625167, -8.169982760450964],
              [-38.541708709473959, -8.169962994798277],
              [-38.541695869117063, -8.169998347129603],
              [-38.541665737068605, -8.170071760319576],
              [-38.541641072773999, -8.170126817870583],
              [-38.541611235339232, -8.170180693678612],
              [-38.541588025372072, -8.170234124730335],
              [-38.541569440134964, -8.170291993185515],
              [-38.541573284231596, -8.170344098100069],
              [-38.541570858500677, -8.170401442148011],
              [-38.541533778119557, -8.170438214246097],
              [-38.541494162490054, -8.170469194516558],
              [-38.541455099383512, -8.170493301029772],
              [-38.54140276695771, -8.170530869901032],
              [-38.541347447342815, -8.170560927833289],
              [-38.541286406182387, -8.170593059675303],
              [-38.541216094052736, -8.170635130754629],
              [-38.541179206750179, -8.170661772372162],
              [-38.541110260953339, -8.170699774625154],
              [-38.54103415155523, -8.170730713456399],
              [-38.540965950065626, -8.170752887366167],
              [-38.540901483383777, -8.170763849410427],
              [-38.540784319922622, -8.170813374792191],
              [-38.54070530457728, -8.170845124331855],
              [-38.540635742484653, -8.170866301663469],
              [-38.540582079887741, -8.170876190493235],
              [-38.540526997496819, -8.170857494762375],
              [-38.540465449318347, -8.17085661079264],
              [-38.540413718463114, -8.170844340969692],
              [-38.540350381487393, -8.170820027793875],
              [-38.540273857244308, -8.170816775020951],
              [-38.540200765470701, -8.170828541222852],
              [-38.540169128750506, -8.170867942490206],
              [-38.540201474711651, -8.170922702915782],
              [-38.540188068177279, -8.170976687727446],
              [-38.540129411599004, -8.170987475343622],
              [-38.540057775799518, -8.170996167793074],
              [-38.539984163545867, -8.170986767514972],
              [-38.539909914693872, -8.170978361475447],
              [-38.53983485079079, -8.170968235901135],
              [-38.539756706369317, -8.170952860573486],
              [-38.539686812502481, -8.170946630303513],
              [-38.539612731232332, -8.170950435465848],
              [-38.539538728714156, -8.170964733174714],
              [-38.539468525902073, -8.170990432188486],
              [-38.539399310671868, -8.17102572026085],
              [-38.539349329781643, -8.171070708619341],
              [-38.539319757466316, -8.171130554390958],
              [-38.539308973791769, -8.171192954219162],
              [-38.539306099944994, -8.171244961035645],
              [-38.539321806737178, -8.171322587017846],
              [-38.53933732709789, -8.171404464046578],
              [-38.539351131970328, -8.171478198412069],
              [-38.539371923525572, -8.171554925658633],
              [-38.539394802003528, -8.171632559803482],
              [-38.53941377982909, -8.171707656841335],
              [-38.539419481994592, -8.171722497514674],
              [-38.539441476020762, -8.171779778876671],
              [-38.539458957574048, -8.171813899304931],
              [-38.539477797727947, -8.17185073484892],
              [-38.539522109179565, -8.171920795389285],
              [-38.539566242195761, -8.171988142152438],
              [-38.539611469156469, -8.172051510253862],
              [-38.539659608784653, -8.172108188192883],
              [-38.539703123276411, -8.172160790509372],
              [-38.539753722197936, -8.17221032550245],
              [-38.53978672864271, -8.172243287700335],
              [-38.539835516530751, -8.172288840721329],
              [-38.539903851092802, -8.172309270220612],
              [-38.539946461235033, -8.172279650315581],
              [-38.539985444494192, -8.172246227283514],
              [-38.540042449183424, -8.172251176526846],
              [-38.540091442073717, -8.172276287524349],
              [-38.540131253689104, -8.172312242359352],
              [-38.540168165402903, -8.172343761730087],
              [-38.540276224060648, -8.172397341874422],
              [-38.540377839906796, -8.172369326663889],
              [-38.540437625384371, -8.172324168417317],
              [-38.540491972388232, -8.172271677344265],
              [-38.540547694436988, -8.172207519472948],
              [-38.540576697456238, -8.172169110167074],
              [-38.540604337311073, -8.172131965643601],
              [-38.540661614697129, -8.17205722659639],
              [-38.540719066692681, -8.171988548044807],
              [-38.540746887255395, -8.171952127333736],
              [-38.540789458229526, -8.171877281117716],
              [-38.540836830219256, -8.171811485579523],
              [-38.540891183097656, -8.17175374821557],
              [-38.540900816180979, -8.171744713912718],
              [-38.540960068091678, -8.171689695668029],
              [-38.541033658356653, -8.171639035392747],
              [-38.541109146004629, -8.171596246609422],
              [-38.541153827613044, -8.171580920388006],
              [-38.541229570429664, -8.17155314696037],
              [-38.541305227207616, -8.17152121261225],
              [-38.54134972878942, -8.171504529378897],
              [-38.541424851090113, -8.171463639618418],
              [-38.541483917301647, -8.171412148721444],
              [-38.541531449622269, -8.171364805553399],
              [-38.541589601806798, -8.171318740744809],
              [-38.541629033478578, -8.171289931118601],
              [-38.541649706146799, -8.17131211536619],
              [-38.541657636618588, -8.171363139479567],
              [-38.541659383487364, -8.171423382727902],
              [-38.541659219422954, -8.171487603714912],
              [-38.541654887709228, -8.171544583791748],
              [-38.541643472940848, -8.171603455343165],
              [-38.541638504932806, -8.17166115831721],
              [-38.541635522018822, -8.171729446465568],
              [-38.54162373988602, -8.171792026145763],
              [-38.541600269552006, -8.171834873968574],
              [-38.541566628683583, -8.171880423835558],
              [-38.541537521058018, -8.171931044168232],
              [-38.541500800962936, -8.171970168422154],
              [-38.541458461133693, -8.172001688305127],
              [-38.541404415961416, -8.172028491392163],
              [-38.541348737143188, -8.172054930810058],
              [-38.541296683346971, -8.172086801471071],
              [-38.541239905537161, -8.172121651695353],
              [-38.541174245572684, -8.172143918961009],
              [-38.541116014943533, -8.172179038876079],
              [-38.541058692628432, -8.172213707550855],
              [-38.541008444583873, -8.172254173193494],
              [-38.540967992370575, -8.172302067036885],
              [-38.540929878217412, -8.17236941078264],
              [-38.540912004955203, -8.172439581517589],
              [-38.540925645540028, -8.172498481472578],
              [-38.540953744053262, -8.172536593795074],
              [-38.540987479354833, -8.172567385872098],
              [-38.54103446586705, -8.172600635194536],
              [-38.541095168867884, -8.172626392528038],
              [-38.541152626297091, -8.172632427560215],
              [-38.541206809625969, -8.172643705107086],
              [-38.541268611830851, -8.172660870689228],
              [-38.541341782201464, -8.172660049134853],
              [-38.541426328887731, -8.172634094711114],
              [-38.541500562282387, -8.172576832066945],
              [-38.541557646244371, -8.172512404237626],
              [-38.541576852614384, -8.172467199827974],
              [-38.541592698439629, -8.17242343884657],
              [-38.541610633147741, -8.172378866160139],
              [-38.541627479515014, -8.17233338771581],
              [-38.541644867878667, -8.172290261645815],
              [-38.541666935293698, -8.172203995103713],
              [-38.541664653961888, -8.172134796468955],
              [-38.541693132625987, -8.17207847691675],
              [-38.541732470491617, -8.172052380749461],
              [-38.541779886387872, -8.172027560060808],
              [-38.541830294016506, -8.172006270396322],
              [-38.541890682664807, -8.171989243288435],
              [-38.541952157581704, -8.171974930971832],
              [-38.542028441295102, -8.17195050474586],
              [-38.542075941961798, -8.171930930353497],
              [-38.542118808789006, -8.171914878352782],
              [-38.542161859752028, -8.171896565246616],
              [-38.542164584408937, -8.171895482901782],
              [-38.542207000831354, -8.171876355001281],
              [-38.542250785557272, -8.171851439693148],
              [-38.542316929217513, -8.171802941608618],
              [-38.542370888264657, -8.171771977488303],
              [-38.542426561109416, -8.17175069370159],
              [-38.542457757907712, -8.171778588312968],
              [-38.542462646666593, -8.171790352649218],
              [-38.542440628806737, -8.171833292620715],
              [-38.542419237131952, -8.171884374011645],
              [-38.542392660441578, -8.171944675677151],
              [-38.542372331222765, -8.172019366333009],
              [-38.542354814548332, -8.172095507406683],
              [-38.542327043149868, -8.172168561497335],
              [-38.542308161552342, -8.172247505046599],
              [-38.542290822085441, -8.172327445315021],
              [-38.542280676765053, -8.172387675107668],
              [-38.542267175026502, -8.172445730245311],
              [-38.542234978523318, -8.172498427538468],
              [-38.542213655705183, -8.172568684891317],
              [-38.542218199639393, -8.172644036847586],
              [-38.542237453988669, -8.172715968260887],
              [-38.542273612140669, -8.172771637376666],
              [-38.542303875319142, -8.172822234487644],
              [-38.542322316373891, -8.172890908692404],
              [-38.54234464297361, -8.172975416455184],
              [-38.54236718897311, -8.173026728449823],
              [-38.542405979816181, -8.173082400533854],
              [-38.542456119832934, -8.173136728666872],
              [-38.542494489770029, -8.173163455512524],
              [-38.542450357451585, -8.173174621686918],
              [-38.542399694983914, -8.173180443779872],
              [-38.542339501237166, -8.173185621916014],
              [-38.542291290780746, -8.173190632698308],
              [-38.542228371747512, -8.173197435876331],
              [-38.542153816456945, -8.173218517345646],
              [-38.542109319350942, -8.173231130306718],
              [-38.542063371706902, -8.173242023025804],
              [-38.542017057429355, -8.173255990704437],
              [-38.541975363280109, -8.173278466130281],
              [-38.54193539055985, -8.17330392842851],
              [-38.541894963207476, -8.173330023374097],
              [-38.541857983153719, -8.17335820262392],
              [-38.541785664107195, -8.173408412211829],
              [-38.541743697277354, -8.173431158660142],
              [-38.54173988179172, -8.173433506098471],
              [-38.541703907122276, -8.173455716617065],
              [-38.541628145692108, -8.173499500157543],
              [-38.541553115450938, -8.173538942798945],
              [-38.541509615852114, -8.173552461344997],
              [-38.541487187879383, -8.173556868086363],
              [-38.5414318897393, -8.173567750130962],
              [-38.541387325864761, -8.173559287537353],
              [-38.541361821770337, -8.173554555097223],
              [-38.541314102571, -8.173526822568867],
              [-38.541273310737168, -8.173474585306854],
              [-38.541228784802662, -8.17343292672996],
              [-38.541218265368755, -8.173423145945662],
              [-38.541177803506308, -8.173399944250944],
              [-38.541133426697151, -8.173386687868668],
              [-38.541096672908822, -8.173375701438781],
              [-38.541036591204218, -8.173362246323524],
              [-38.541013357272107, -8.173356973724204],
              [-38.540922047438706, -8.173343030893859],
              [-38.540898990314524, -8.173341919296277],
              [-38.540870395862015, -8.17334053005516],
              [-38.540819559453261, -8.17333965827731],
              [-38.540764723614075, -8.173343214116878],
              [-38.540709348054399, -8.173342427624005],
              [-38.540705988744968, -8.17334278561898],
              [-38.540659684867144, -8.173347617464781],
              [-38.540607566867749, -8.173356060795419],
              [-38.540559352034094, -8.173364870361976],
              [-38.540512852722024, -8.173381822581542],
              [-38.540464723696253, -8.173394973945967],
              [-38.540410418248698, -8.173411012761397],
              [-38.54036328449434, -8.173427059718835],
              [-38.540354836272549, -8.173431934544537],
              [-38.540284798265382, -8.173471925331688],
              [-38.540219658690006, -8.173515177958002],
              [-38.540193977040623, -8.173586244294921],
              [-38.540190076302643, -8.173663033887543],
              [-38.540192259963938, -8.173738021350044],
              [-38.540196045734582, -8.173761633711365],
              [-38.540204068178625, -8.173811482063989],
              [-38.54022816519204, -8.173855831097429],
              [-38.540240122919613, -8.173878005510463],
              [-38.540283339229752, -8.17395340142232],
              [-38.540334549677965, -8.174024102894496],
              [-38.540414491395133, -8.174056032960447],
              [-38.540462044449491, -8.174070288008361],
              [-38.540539349808249, -8.174105471339027],
              [-38.540624306254209, -8.174118412072215],
              [-38.540674234430952, -8.174119735110075],
              [-38.540750940561317, -8.174123259404613],
              [-38.540808551311095, -8.174154169062442],
              [-38.540863348578277, -8.174184442352306],
              [-38.540898013327578, -8.174196421410986],
              [-38.540916525444445, -8.174202774087682],
              [-38.540983190871032, -8.1741748095291],
              [-38.541020260186968, -8.174148077706471],
              [-38.541092114389407, -8.174107636594689],
              [-38.541176288455212, -8.174090274791817],
              [-38.541254666576513, -8.174060604878884],
              [-38.541319356472201, -8.17401364302995],
              [-38.541388832957622, -8.173988395159126],
              [-38.541449457183639, -8.174003840793953],
              [-38.541486626522236, -8.174048475937628],
              [-38.541531872602157, -8.174095562446864],
              [-38.541577101653154, -8.174157573568879],
              [-38.541638755398395, -8.174225482703235],
              [-38.541674759697116, -8.174256910468749],
              [-38.541711494070967, -8.174284992321867],
              [-38.541751854329753, -8.174317781802142],
              [-38.541786674774031, -8.17435246450154],
              [-38.541821678541446, -8.174385609716078],
              [-38.541856864289933, -8.174418393324574],
              [-38.541894227658439, -8.174452174372689],
              [-38.541933040024468, -8.174489032438494],
              [-38.541976939655683, -8.174522820882572],
              [-38.542027104954848, -8.174555078727563],
              [-38.542069190317356, -8.174587689227337],
              [-38.54210837223355, -8.174618939650561],
              [-38.542152098583351, -8.174645491692958],
              [-38.54219574364641, -8.174663722022778],
              [-38.542245107934605, -8.174681958821623],
              [-38.542295026917429, -8.174691422364436],
              [-38.542346305972671, -8.174702334677923],
              [-38.542430622206105, -8.174719616029599],
              [-38.5425091579217, -8.174711021452442],
              [-38.542586352525198, -8.174684334872429],
              [-38.542656696382231, -8.174694635542728],
              [-38.542717059658038, -8.174700221393621],
              [-38.542791230139429, -8.174698405752048],
              [-38.542839851321681, -8.174731475826166],
              [-38.542843383347197, -8.174738896911961],
              [-38.542864847640764, -8.174783604638401],
              [-38.542901391216198, -8.174819917338555],
              [-38.542911728650694, -8.174830150138737],
              [-38.542950625856321, -8.174872344898194],
              [-38.542985265098523, -8.174906846392128],
              [-38.543019064046703, -8.174961789166627],
              [-38.54303306648788, -8.175022136711226],
              [-38.543047618047979, -8.175078595424502],
              [-38.543059710484037, -8.175142106642559],
              [-38.543065906558283, -8.175201721746681],
              [-38.543071101773322, -8.175263325671517],
              [-38.543081753552812, -8.17531643324007],
              [-38.543100297556187, -8.175374615057372],
              [-38.543126365206035, -8.175442664680926],
              [-38.543156698255785, -8.175511895002495],
              [-38.543192921100108, -8.175590810377587],
              [-38.543208668194168, -8.175633793035281],
              [-38.543229183795518, -8.175714499711457],
              [-38.543237756171322, -8.175760097382057],
              [-38.543242566368036, -8.175840967221779],
              [-38.543244918156077, -8.175928256401546],
              [-38.543246913610226, -8.176009575323025],
              [-38.543237577212025, -8.17607695178129],
              [-38.543257646132986, -8.176151326286389],
              [-38.543299871442507, -8.176220750936512],
              [-38.543353727828972, -8.176280510316444],
              [-38.543403410229182, -8.176338546381698],
              [-38.543442107442139, -8.176397112752031],
              [-38.543478628991423, -8.176452963091936],
              [-38.543513622382399, -8.176495515207344],
              [-38.543550447872001, -8.176523597011164],
              [-38.543622864430795, -8.176547648629429],
              [-38.543673578509747, -8.176576650622279],
              [-38.543690589847024, -8.176625604546322],
              [-38.543715404409021, -8.176678094917802],
              [-38.543768268589901, -8.176732335534194],
              [-38.543832381913411, -8.176793463215276],
              [-38.543873201337902, -8.176822001759815],
              [-38.543911118423104, -8.176848185265264],
              [-38.543982224863917, -8.176906788146827],
              [-38.544052512953577, -8.176966565974624],
              [-38.544115813842083, -8.177023712794329],
              [-38.544177471225026, -8.177089179369272],
              [-38.544226051314133, -8.177158792002565],
              [-38.54428262998217, -8.177219820672116],
              [-38.544326087805693, -8.177243387268309],
              [-38.54436964524691, -8.177259175069187],
              [-38.544420188893874, -8.177278498380096],
              [-38.544466200431565, -8.177291665817721],
              [-38.544510753097825, -8.177310439653317],
              [-38.544577256722448, -8.177345338732556],
              [-38.544587536173431, -8.177406767467916],
              [-38.544566771486473, -8.177465266757347],
              [-38.544563790613672, -8.177532107696845],
              [-38.54459777237134, -8.177586326942787],
              [-38.544664174697047, -8.177630542489654],
              [-38.544708458655869, -8.177646150170238],
              [-38.544789426544661, -8.177654743868317],
              [-38.544847467522182, -8.177627221244636],
              [-38.544887102418031, -8.177579687919025],
              [-38.544905785132165, -8.177515849577944],
              [-38.544893512222259, -8.17745107186661],
              [-38.54488461404226, -8.177372368288999],
              [-38.544904580512132, -8.177297224845693],
              [-38.544933821289355, -8.17720924760307],
              [-38.544946409899417, -8.177155713968586],
              [-38.544955632398455, -8.17710850821093],
              [-38.54498442354604, -8.17701673145679],
              [-38.545031900869461, -8.176938091321006],
              [-38.545082459117054, -8.176864429525413],
              [-38.545123125717346, -8.176787590760847],
              [-38.545129848320791, -8.176703296710608],
              [-38.545131212644115, -8.176620896131666],
              [-38.545120687940425, -8.176535497248921],
              [-38.545096176192281, -8.176455962034298],
              [-38.545087906074997, -8.176383771734088],
              [-38.545099056476232, -8.176317573139455],
              [-38.545131001411846, -8.176246513508886],
              [-38.54517003970583, -8.176164517116081],
              [-38.545189972026883, -8.176119403799367],
              [-38.545236630521309, -8.176042209945312],
              [-38.545282883555586, -8.17600237246165],
              [-38.545352330882004, -8.176002993265634],
              [-38.545408315077651, -8.176027840160598],
              [-38.545468766220644, -8.176036229735816],
              [-38.545505294333992, -8.176006331055838],
              [-38.545512036547123, -8.175984810966858],
              [-38.545528168231598, -8.176009070336564],
              [-38.545567324969447, -8.176062933511929],
              [-38.545625454980517, -8.176116908456994],
              [-38.545678255096114, -8.176147721595621],
              [-38.545759828709862, -8.176182366052272],
              [-38.545809378716982, -8.176197255903748],
              [-38.545853663697841, -8.176211868478731],
              [-38.545894763956085, -8.176232990044296],
              [-38.545954172858792, -8.176280001547845],
              [-38.546005310615101, -8.17633586809051],
              [-38.546053361807104, -8.176391731162179],
              [-38.546092144479388, -8.176455362729497],
              [-38.546086805085565, -8.176520844264035],
              [-38.546091717278031, -8.176592307164464],
              [-38.546129323083889, -8.176653042931294],
              [-38.546188937261789, -8.176679160133004],
              [-38.546236205430716, -8.17670499211378],
              [-38.546290274061661, -8.176738067911547],
              [-38.546316368000561, -8.176783413838649],
              [-38.546335724870765, -8.176845757254201],
              [-38.546354452202863, -8.176902853724041],
              [-38.546373345667625, -8.176973608690265],
              [-38.546403480144278, -8.177058486839829],
              [-38.546422585341062, -8.177102558586892],
              [-38.546453459583425, -8.177175497847834],
              [-38.546461356403356, -8.177256913856331],
              [-38.546486147966831, -8.177330117648832],
              [-38.546531024833499, -8.177383444443301],
              [-38.546566753273403, -8.17741858004276],
              [-38.546614447364739, -8.177469286869174],
              [-38.546675128706283, -8.177515214276493],
              [-38.546718479895269, -8.177552981530207],
              [-38.546757379710101, -8.177593457360961],
              [-38.546794472778593, -8.177626061804951],
              [-38.546836574591943, -8.177644742191912],
              [-38.546896029968906, -8.177650869141797],
              [-38.546973039663712, -8.177627437821643],
              [-38.547014826136028, -8.17760378611689],
              [-38.547050625789275, -8.177575876437654],
              [-38.547110339580996, -8.177513892991506],
              [-38.547147597956851, -8.177480467340612],
              [-38.547188297571559, -8.177454553089103],
              [-38.547192021571405, -8.177452838664509],
              [-38.547236980892627, -8.177433170393879],
              [-38.547283025512257, -8.177417121429352],
              [-38.54728511451772, -8.177416219244561],
              [-38.547330253966493, -8.177397817498454],
              [-38.547371943492848, -8.177379592789471],
              [-38.547418729962438, -8.177349705422172],
              [-38.547459796448614, -8.177320444813317],
              [-38.547503047304161, -8.177286211762636],
              [-38.547570480200825, -8.177221794665996],
              [-38.547633094294568, -8.177163884738164],
              [-38.5476984870363, -8.1771375457971],
              [-38.547770422793214, -8.177105244304741],
              [-38.54781421859694, -8.177070740470134],
              [-38.547856378077633, -8.177038224753327],
              [-38.547902166381739, -8.177007883947178],
              [-38.547943149555664, -8.176971929713693],
              [-38.547983220727275, -8.176939683004738],
              [-38.548027107276987, -8.176905179246267],
              [-38.548067725876244, -8.176870490924969],
              [-38.548138431138632, -8.176802187927681],
              [-38.5481950495153, -8.176747798847162],
              [-38.548239790627115, -8.176679919077888],
              [-38.548266736048085, -8.176614190279031],
              [-38.548258282061212, -8.176543537499022],
              [-38.548228226393896, -8.176468971129873],
              [-38.548214974828021, -8.176386011460464],
              [-38.54819973309327, -8.176296808350266],
              [-38.548190073915322, -8.176248405515985],
              [-38.548179596889604, -8.176200725386948],
              [-38.548173294704029, -8.176154044899208],
              [-38.54816490769705, -8.17610455805694],
              [-38.548155974678259, -8.176056246485697],
              [-38.548143685997942, -8.176005036687245],
              [-38.548135204651594, -8.175958715573415],
              [-38.548121190107011, -8.175908408369828],
              [-38.548110164231858, -8.175864436174818],
              [-38.548098141695718, -8.175818744269787],
              [-38.548078258328701, -8.175740028448002],
              [-38.548059196076025, -8.175657695446429],
              [-38.548046536450407, -8.175613450066697],
              [-38.548029568090897, -8.175525873164039],
              [-38.548017363499859, -8.175480633315507],
              [-38.547991763268541, -8.175399830694598],
              [-38.547967705313788, -8.175319934320399],
              [-38.547952541814396, -8.175241947388265],
              [-38.54795697469158, -8.175175017555814],
              [-38.547953414928664, -8.175111515980614],
              [-38.547950136676711, -8.175039874002419],
              [-38.547928237539615, -8.174977708712813],
              [-38.547883994366615, -8.174926010923555],
              [-38.547837202602381, -8.17488028013987],
              [-38.547784789024867, -8.174828663658023],
              [-38.547731013434536, -8.174777317004075],
              [-38.547678596521379, -8.174728685435564],
              [-38.547618557656307, -8.174676789048279],
              [-38.547561783877278, -8.174627609877904],
              [-38.547503916964523, -8.174581776216417],
              [-38.547454946838194, -8.174535771597663],
              [-38.547418804721886, -8.174465087820217],
              [-38.547398336273957, -8.174422371375963],
              [-38.54737423188844, -8.174383811672634],
              [-38.547346680878213, -8.17434253453826],
              [-38.547323394451382, -8.174303161675994],
              [-38.54728418121492, -8.174218816136557],
              [-38.547266164905992, -8.174175197907115],
              [-38.547237299527374, -8.174091949375761],
              [-38.547210433386155, -8.174006894032539],
              [-38.547174565944246, -8.173934220590722],
              [-38.547118171158473, -8.173871112117698],
              [-38.547056410507572, -8.173816681060101],
              [-38.546988090059031, -8.173783137153606],
              [-38.546936317661064, -8.173807139505294],
              [-38.546897227162241, -8.17385548775516],
              [-38.546837385740908, -8.173870164455785],
              [-38.54675968595145, -8.173862208033933],
              [-38.546678559151943, -8.173834167322051],
              [-38.546602960617953, -8.173814454412931],
              [-38.546538694310129, -8.173808412508238],
              [-38.546485321128593, -8.173802925522104],
              [-38.546431667227417, -8.173804855311241],
              [-38.546379464904675, -8.173807600792875],
              [-38.546334691408177, -8.173823832008177],
              [-38.54629226206989, -8.173854357327373],
              [-38.546326443231891, -8.173892747487074],
              [-38.546348636994246, -8.173934923199893],
              [-38.546371459280614, -8.173983250379976],
              [-38.546347220194853, -8.173983675455426],
              [-38.546300845014471, -8.173971231458037],
              [-38.546237955534622, -8.173951804093679],
              [-38.546192688545752, -8.173922718085878],
              [-38.54612818740955, -8.173883479821235],
              [-38.546054039556843, -8.173865396581615],
              [-38.545977331430031, -8.173863591886311],
              [-38.545896478878227, -8.173833923185214],
              [-38.545849843255425, -8.173810986367302],
              [-38.545845670494664, -8.173808177657643],
              [-38.545808386971096, -8.173783804097702],
              [-38.545760939036285, -8.173756705547685],
              [-38.5457179355964, -8.173732958722912],
              [-38.545632095863169, -8.173698219035801],
              [-38.545592178995072, -8.17367447566563],
              [-38.545514877315895, -8.17363567518901],
              [-38.545437918502699, -8.173614784657365],
              [-38.545362481060842, -8.173613252633379],
              [-38.545281307818797, -8.173626367434348],
              [-38.545214259439554, -8.173672151413429],
              [-38.545168813464542, -8.173721035054321],
              [-38.545120553366324, -8.173769825072171],
              [-38.545056596320215, -8.173811361236913],
              [-38.545018726858089, -8.173823439259479],
              [-38.545030934266173, -8.173785734346042],
              [-38.545034417461572, -8.173756160333609],
              [-38.545038466864725, -8.173707591956729],
              [-38.545036277070082, -8.17363721753617],
              [-38.545005307114778, -8.173569072051636],
              [-38.544950520245742, -8.173529392273194],
              [-38.544889194486835, -8.173492056892664],
              [-38.54483250287884, -8.173450927717925],
              [-38.544815996519489, -8.173436979475539],
              [-38.544774095569174, -8.173401746342583],
              [-38.544713959742751, -8.173355819299738],
              [-38.544641122282229, -8.173303094021035],
              [-38.544573263741732, -8.173262766312556],
              [-38.544528069888379, -8.173249418925105],
              [-38.544494044664631, -8.173233822800505],
              [-38.544529552854357, -8.17322255623718],
              [-38.544619896037695, -8.1732084569311],
              [-38.544671806657462, -8.173222716392972],
              [-38.544728897130135, -8.17323227815443],
              [-38.5447932699768, -8.173224300340497],
              [-38.544870270707563, -8.173208105557656],
              [-38.54495690805399, -8.173179710528396],
              [-38.545049337028431, -8.173167512971604],
              [-38.545129497247174, -8.173167060407822],
              [-38.545214106554148, -8.173165889211234],
              [-38.545262036043631, -8.173168475763536],
              [-38.545312967041724, -8.173166000350083],
              [-38.545367353451425, -8.173158463479899],
              [-38.545424204256648, -8.173139170560461],
              [-38.545474432652846, -8.173115799833619],
              [-38.54551722550076, -8.173084913197078],
              [-38.54556056077606, -8.173056017117787],
              [-38.545604161530825, -8.173033181642213],
              [-38.545650223088536, -8.17300175594797],
              [-38.545694737270054, -8.172973946606069],
              [-38.545743791515626, -8.172945237833398],
              [-38.545784771571469, -8.172911816463305],
              [-38.545850029201972, -8.172843869528926],
              [-38.545880752837284, -8.172808808489689],
              [-38.545906400407546, -8.172766957819404],
              [-38.545923065266592, -8.172721026706048],
              [-38.545940776879313, -8.1726324936848],
              [-38.545936235516869, -8.172554247258693],
              [-38.54590509598502, -8.172475428261764],
              [-38.545861338647477, -8.172395961931185],
              [-38.54580577389023, -8.172321728579337],
              [-38.545762370401562, -8.172250493805331],
              [-38.545707522617526, -8.172184492415139],
              [-38.545643309801314, -8.172131415193766],
              [-38.545569994468174, -8.172099945861996],
              [-38.54551009258671, -8.172088029305435],
              [-38.545443635864515, -8.172092567711838],
              [-38.545382146383041, -8.172119815241309],
              [-38.545325552910221, -8.172152495407488],
              [-38.545287954010476, -8.172166292364688],
              [-38.545251899098112, -8.172179457887111],
              [-38.545191967717031, -8.172193591540209],
              [-38.545169629708923, -8.172198812665121],
              [-38.545121140079594, -8.172209974242222],
              [-38.54507591763327, -8.172221953558966],
              [-38.545028062132403, -8.172234291718828],
              [-38.544981759637402, -8.172238038642289],
              [-38.544891780523052, -8.172251505285081],
              [-38.544846116463802, -8.172252629791721],
              [-38.544756532147922, -8.172238147059675],
              [-38.544714066437798, -8.172220732410723],
              [-38.54467442088275, -8.1721980746931],
              [-38.544633137894472, -8.172178400057485],
              [-38.544557185077267, -8.172151269291852],
              [-38.544500495636647, -8.172108421487021],
              [-38.544446002284488, -8.172050289694278],
              [-38.544387786323526, -8.171992696416748],
              [-38.544340474529257, -8.17192588926993],
              [-38.544323754708309, -8.171860111552654],
              [-38.544330990929389, -8.171803225151706],
              [-38.544378853372393, -8.171784736317846],
              [-38.544426225485772, -8.171798357523908],
              [-38.544475140771368, -8.171812070914489],
              [-38.54453460067073, -8.171813404193434],
              [-38.544598143649765, -8.171816551107655],
              [-38.544681833376153, -8.171825509639815],
              [-38.544766075275334, -8.171827865751524],
              [-38.544847535469188, -8.17180154530754],
              [-38.544903968887731, -8.171749870024266],
              [-38.544935007589658, -8.171677090760641],
              [-38.54493746104135, -8.171594872293067],
              [-38.544930014383844, -8.171517074856942],
              [-38.544908364450585, -8.171475894664521],
              [-38.544880811938768, -8.171436335974379],
              [-38.544850085699608, -8.17139360787829],
              [-38.544813275528917, -8.171352320173741],
              [-38.544778998610248, -8.171318633317561],
              [-38.544710979323234, -8.171260214985843],
              [-38.544624505664814, -8.171224660328852],
              [-38.544578493574484, -8.171212578370497],
              [-38.544533015503475, -8.171209904057061],
              [-38.544486987211016, -8.171212113548203],
              [-38.54443769492282, -8.171210701263252],
              [-38.544364715484484, -8.171203473344534],
              [-38.54430454749378, -8.171186219627392],
              [-38.544245210593765, -8.171156574860504],
              [-38.544181620978712, -8.17111462376938],
              [-38.5441741482976, -8.171059891655609],
              [-38.544217830648527, -8.171045016222079],
              [-38.544270695576898, -8.171018030506387],
              [-38.544330028486371, -8.170971152541783],
              [-38.54436528362907, -8.170942699741776],
              [-38.544430610591398, -8.170893567201279],
              [-38.544483315363436, -8.170847676725268],
              [-38.544531575881997, -8.170798253595402],
              [-38.544578749382232, -8.170746748830544],
              [-38.544625823271531, -8.170703022853274],
              [-38.544660899064979, -8.170672579870182],
              [-38.544701685919343, -8.170649289049461],
              [-38.54474979953384, -8.170649343186501],
              [-38.544814171348463, -8.170641908059146],
              [-38.544866470187515, -8.170633735722198],
              [-38.544924533165918, -8.17066600208385],
              [-38.54496497850247, -8.170703766221528],
              [-38.544997978212209, -8.17074296922107],
              [-38.545046213367847, -8.170796028566778],
              [-38.545063932986004, -8.170860812409311],
              [-38.545060787135697, -8.170913180811739],
              [-38.545068521931611, -8.170977048902973],
              [-38.545064907015906, -8.17104280373281],
              [-38.545097323971881, -8.171115654363049],
              [-38.545157080321154, -8.171175781938448],
              [-38.545216402448851, -8.171218542156829],
              [-38.545270773643402, -8.17122421131825],
              [-38.545327711063393, -8.171208355684712],
              [-38.545381274652684, -8.171205702302702],
              [-38.545427908723028, -8.171229724587002],
              [-38.545458448847306, -8.171276522791166],
              [-38.545503968590822, -8.171322614201856],
              [-38.545577012051858, -8.171353540519059],
              [-38.545624939693262, -8.171357574253484],
              [-38.545673872674833, -8.171355639252454],
              [-38.545727533918665, -8.171346925624022],
              [-38.545801195525868, -8.17131290776774],
              [-38.545836135651427, -8.171241851398365],
              [-38.545857154741157, -8.171198729193602],
              [-38.545881667663529, -8.171116535468332],
              [-38.545871676824227, -8.171040634683356],
              [-38.545844801430306, -8.170964172231615],
              [-38.545821061737549, -8.170924527428745],
              [-38.545768565812722, -8.170866126694072],
              [-38.545714229538468, -8.170829613369683],
              [-38.545638076295987, -8.170819306737801],
              [-38.54556100870456, -8.170814788018708],
              [-38.545498488094061, -8.170790747906361],
              [-38.545476031551473, -8.170740340690426],
              [-38.545513210384037, -8.170696513036507],
              [-38.545562605987001, -8.170686799657219],
              [-38.54561201499142, -8.170665237021424],
              [-38.545665238814301, -8.170641869614672],
              [-38.545705312463269, -8.170606999980249],
              [-38.545732590365034, -8.17056831697472],
              [-38.545749077464457, -8.170518948468574],
              [-38.545741149650027, -8.170465120384433],
              [-38.545717870442971, -8.17041968713936],
              [-38.545673459980428, -8.170356049232915],
              [-38.545613167167723, -8.170288684938596],
              [-38.545582069417229, -8.17025337357671],
              [-38.545539367140528, -8.170204481318097],
              [-38.545559180373992, -8.170184061325774],
              [-38.545606650189086, -8.170191441280089],
              [-38.545658191821381, -8.170210584614276],
              [-38.545699745884477, -8.170231254419818],
              [-38.545753362894004, -8.170261525718953],
              [-38.545817229090645, -8.170299768327915],
              [-38.545888465481582, -8.170323004098361],
              [-38.545934853011516, -8.17032414158418],
              [-38.545983349549054, -8.170306648233879],
              [-38.546053691931732, -8.170237350152426],
              [-38.546083791731633, -8.170191795901536],
              [-38.546114516460413, -8.170155558947785],
              [-38.546180235538323, -8.170080466700556],
              [-38.546220850907631, -8.17004813028397],
              [-38.546262731575531, -8.170020770164701],
              [-38.546305971694643, -8.169995401518221],
              [-38.546349577789528, -8.169967500609285],
              [-38.546389643243877, -8.169939776631191],
              [-38.546427177466377, -8.169902642759697],
              [-38.546457085834639, -8.169865771694596],
              [-38.546491718956354, -8.169825197372733],
              [-38.546523625601012, -8.169787333564742],
              [-38.546556081208834, -8.169745671367235],
              [-38.546613160705391, -8.169684137222728],
              [-38.546643249273451, -8.16964844222511],
              [-38.546672169839226, -8.169601982077801],
              [-38.546696913387109, -8.169556512225711],
              [-38.546720745861776, -8.169513935828833],
              [-38.546764407591063, -8.169436647907375],
              [-38.54678392250014, -8.16935869977099],
              [-38.546772100504356, -8.16929645526074],
              [-38.546738500265107, -8.169226226544893],
              [-38.546688095616588, -8.169164933793937],
              [-38.54664629825028, -8.169118575330199],
              [-38.546595062930407, -8.169069402263428],
              [-38.54652613286197, -8.169013696789062],
              [-38.546485133866447, -8.168983891998591],
              [-38.54644321747854, -8.168962588704156],
              [-38.546397033718549, -8.168941913789508],
              [-38.546350207150084, -8.168927750723833],
              [-38.546301019997934, -8.168913856363814],
              [-38.546252918532396, -8.168903219502495],
              [-38.546201996861406, -8.168897916166275],
              [-38.546156064211004, -8.168896055610476],
              [-38.54610377859445, -8.168892740685651],
              [-38.546054853066359, -8.168888344094057],
              [-38.546004382541767, -8.168885483454517],
              [-38.545911970115263, -8.168883932537366],
              [-38.545864045216568, -8.168877727997145],
              [-38.545816842469868, -8.168875142358573],
              [-38.545768102292556, -8.168867399201122],
              [-38.545720903332274, -8.168861466816187],
              [-38.54567215814739, -8.16885815581017],
              [-38.545622146032812, -8.16885131573159],
              [-38.5455748576274, -8.168844207348068],
              [-38.545523490978738, -8.168831034067562],
              [-38.545476116504993, -8.168819764764999],
              [-38.545382917785638, -8.168791077120924],
              [-38.545340088462126, -8.168774385763738],
              [-38.545306880387692, -8.168758971545589],
              [-38.545279933063604, -8.168746277931575],
              [-38.54531117898329, -8.168730755222322],
              [-38.545385825404757, -8.168708497333649],
              [-38.545397402151863, -8.168666359512761],
              [-38.545439173727175, -8.168655099879398],
              [-38.545490927310375, -8.168647198191199],
              [-38.54554685902081, -8.168637311234713],
              [-38.545593996632192, -8.168617193273585],
              [-38.54565711304496, -8.16859573645881],
              [-38.545724943157055, -8.168580345236702],
              [-38.545789046583224, -8.168569110631839],
              [-38.54585560331406, -8.168555888816295],
              [-38.545923073360406, -8.168537783583533],
              [-38.545999347522354, -8.168521045001681],
              [-38.546060727842125, -8.168509535940883],
              [-38.546119274669771, -8.168515119201004],
              [-38.546175459382056, -8.168522327947635],
              [-38.546242275667282, -8.168520412911493],
              [-38.546306733818717, -8.16851659571987],
              [-38.546378925522312, -8.168497500725897],
              [-38.546440232408237, -8.168470614611751],
              [-38.546500817232499, -8.168440019129264],
              [-38.546566762554534, -8.168405449735914],
              [-38.546631540586041, -8.168359482017324],
              [-38.546679709860825, -8.168309968040374],
              [-38.546720069604262, -8.168262616169104],
              [-38.54675200119528, -8.168202501061966],
              [-38.546780304132348, -8.16814012057757],
              [-38.546800982777, -8.168076646124272],
              [-38.54681704480253, -8.168001498136407],
              [-38.546821486188726, -8.167927060755865],
              [-38.546820471297799, -8.167861029342239],
              [-38.546821273124394, -8.167794004985756],
              [-38.546808086500562, -8.167734382066691],
              [-38.54678327007359, -8.167683610464049],
              [-38.546753185672635, -8.167635275176925],
              [-38.546736723238617, -8.16758252297284],
              [-38.546741766931561, -8.167537483308038],
              [-38.546745825206884, -8.167480774175468],
              [-38.546746159268423, -8.167426050826016],
              [-38.546732517338199, -8.167367693728899],
              [-38.546715792881535, -8.167305805534541],
              [-38.546712487189083, -8.167258676113549],
              [-38.546723801714293, -8.167207402252298],
              [-38.546734426930094, -8.167123383836428],
              [-38.546765281202994, -8.167052503673544],
              [-38.546783328502109, -8.166987850386988],
              [-38.546770785317619, -8.166921172896313],
              [-38.546735874554358, -8.166886219166285],
              [-38.546700688397749, -8.16685397869823],
              [-38.546658066281054, -8.166814674609727],
              [-38.546589802541668, -8.166731924627893],
              [-38.546569942538042, -8.1667135405383],
              [-38.546600442116166, -8.166715655106049],
              [-38.546645807704117, -8.166737143142234],
              [-38.546695534694479, -8.166755108412392],
              [-38.546744018115071, -8.166748831030704],
              [-38.546746652660225, -8.16674711538419],
              [-38.546787896870022, -8.166720297187803],
              [-38.546822798746057, -8.166682617593947],
              [-38.54682488912318, -8.166680449075544],
              [-38.546847433248075, -8.166653157675471],
              [-38.54684610500712, -8.166623487788161],
              [-38.546822370442797, -8.166579410880233],
              [-38.546844995448581, -8.16656089345714],
              [-38.546887437612085, -8.166518247412714],
              [-38.546923976142672, -8.166478217876758],
              [-38.546960513748303, -8.166439002407964],
              [-38.546990784300647, -8.166402674400668],
              [-38.547013491810581, -8.166391483707327],
              [-38.547011928373983, -8.166409391541729],
              [-38.54700387351285, -8.166468176616572],
              [-38.546994378003397, -8.166516467595416],
              [-38.546981609331169, -8.166569277533126],
              [-38.546969572703098, -8.166616932500109],
              [-38.546959078916096, -8.166664951002899],
              [-38.546942872438947, -8.16670726460783],
              [-38.546926050589605, -8.166731758392226],
              [-38.546938362448685, -8.16676207373963],
              [-38.546972266488233, -8.166804262521479],
              [-38.547012908308005, -8.166828639709578],
              [-38.547046905995018, -8.16686829592213],
              [-38.547059202677524, -8.166912088663242],
              [-38.547054339469476, -8.166958123514478],
              [-38.547048103351685, -8.167014106597712],
              [-38.547054672052376, -8.167065762291751],
              [-38.547067126954659, -8.167130268818568],
              [-38.54707350349743, -8.167191331351104],
              [-38.547090681292914, -8.167253762755617],
              [-38.547105864386751, -8.167314021069142],
              [-38.547121046873087, -8.167374822096148],
              [-38.547136869612302, -8.167431372574249],
              [-38.547148246289773, -8.167486018576406],
              [-38.547171680468232, -8.167555060005599],
              [-38.54721283026096, -8.167612091076778],
              [-38.547251630287938, -8.167659802914567],
              [-38.547291983668373, -8.16769856169468],
              [-38.547344796200164, -8.167717525320041],
              [-38.547412242823015, -8.167720042957816],
              [-38.547471189049091, -8.167693334941744],
              [-38.547513648386818, -8.167635492845395],
              [-38.547550619681594, -8.167614277849466],
              [-38.547587579770969, -8.167603012606351],
              [-38.54756048442853, -8.167641153252289],
              [-38.547549007682271, -8.167675331447599],
              [-38.547541240988863, -8.16772009672558],
              [-38.547535556117786, -8.167770291477137],
              [-38.54753330702605, -8.167832791601523],
              [-38.547523530134086, -8.167889132544097],
              [-38.547520825917417, -8.167952717588404],
              [-38.54753056851952, -8.168007452207632],
              [-38.547565645323516, -8.168056335737928],
              [-38.547622335992429, -8.168097916775908],
              [-38.547662618018776, -8.168119489478451],
              [-38.547709535023678, -8.168133923848615],
              [-38.54779665723715, -8.168157538832538],
              [-38.547871698510789, -8.168187562426592],
              [-38.547947014171129, -8.168215777264805],
              [-38.548020621507035, -8.168229336885306],
              [-38.548091425251251, -8.168233486307706],
              [-38.548151418560586, -8.168244407582799],
              [-38.548157667523938, -8.168257620616693],
              [-38.548168501408611, -8.168310728299019],
              [-38.548160365544838, -8.168360920325362],
              [-38.548156219215571, -8.168415368070375],
              [-38.548172115522753, -8.168487385965863],
              [-38.5481807524882, -8.168556682183304],
              [-38.548187207074584, -8.168629232252187],
              [-38.548172610261858, -8.168693256289316],
              [-38.548152928847387, -8.168758088720718],
              [-38.548144768962985, -8.168829627495617],
              [-38.548152496194518, -8.168900641293391],
              [-38.548152970482853, -8.16896323491552],
              [-38.548161250942286, -8.169026651325126],
              [-38.548179064941394, -8.169088631139138],
              [-38.548194966867072, -8.169155674154801],
              [-38.54820644477924, -8.169201184548873],
              [-38.548238676153218, -8.169278014656991],
              [-38.548262868480933, -8.169318926168245],
              [-38.548292511283989, -8.169356587474132],
              [-38.548330151085651, -8.169386840603078],
              [-38.548370699925734, -8.169413478877548],
              [-38.548411706129023, -8.169437042275344],
              [-38.548454620652677, -8.16945888920206],
              [-38.548536845848808, -8.169478247313513],
              [-38.548623714766059, -8.169485218602755],
              [-38.548671109748412, -8.169478397078297],
              [-38.548718231169659, -8.169472660672534],
              [-38.548766631335006, -8.169459960848322],
              [-38.548808863828853, -8.169442460161726],
              [-38.5488552770829, -8.169420622413552],
              [-38.54893112839553, -8.169376294812576],
              [-38.548995984834058, -8.169341271546024],
              [-38.549060845221078, -8.169302720627016],
              [-38.549128624771718, -8.169251509605326],
              [-38.549179621449539, -8.169190058747919],
              [-38.549223888001812, -8.169139635586482],
              [-38.549276929061712, -8.169116991096965],
              [-38.549298366258327, -8.169105346604608],
              [-38.549334730351973, -8.169058984989423],
              [-38.549361204175142, -8.169008361091866],
              [-38.549386496937316, -8.168958549949213],
              [-38.549407241356974, -8.168917055366842],
              [-38.549440073011063, -8.16886354406272],
              [-38.549488412121519, -8.168824251054724],
              [-38.549544104203385, -8.168785147129459],
              [-38.549593537116351, -8.168741875416776],
              [-38.549632531126854, -8.168697868461264],
              [-38.549675069705827, -8.16865015689452],
              [-38.549698449638754, -8.168606042105147],
              [-38.549719737166377, -8.168565904894695],
              [-38.549740673023315, -8.168515546162796],
              [-38.549748172019484, -8.168466438815759],
              [-38.549749956538164, -8.168413164297743],
              [-38.549748380880189, -8.168361061923532],
              [-38.549739457216404, -8.168304338298817],
              [-38.549725547652685, -8.168241367906711],
              [-38.54971924606982, -8.16819405425527],
              [-38.549704315329237, -8.168151253626833],
              [-38.549689133234786, -8.168089729057302],
              [-38.549683195486686, -8.168041782642836],
              [-38.549683882946809, -8.167995381291378],
              [-38.549686033932971, -8.167939122248969],
              [-38.549698615680143, -8.167890834628437],
              [-38.549677606250221, -8.167844861399866],
              [-38.549641241450814, -8.167810991716076],
              [-38.549672960405807, -8.167778192761116],
              [-38.549720834107866, -8.167749029873924],
              [-38.549754920218035, -8.167710082777136],
              [-38.549801538696137, -8.167666898350232],
              [-38.549860931479643, -8.167646612599427],
              [-38.54990570232922, -8.167632009086059],
              [-38.549962292579075, -8.167601318168838],
              [-38.55000700414125, -8.167558583858414],
              [-38.550052507696357, -8.167538192184107],
              [-38.550116960522459, -8.167538987445473],
              [-38.550181874031836, -8.167533722892616],
              [-38.55024043487974, -8.167526732673462],
              [-38.550295026013309, -8.16749775808319],
              [-38.550348899908926, -8.16746073241775],
              [-38.550386436779931, -8.167420703666201],
              [-38.550444660191914, -8.167390738111642],
              [-38.550502287520665, -8.167406721746628],
              [-38.550542916784892, -8.167442586045432],
              [-38.550573084128693, -8.167498338270466],
              [-38.550615710462331, -8.167534295232484],
              [-38.550675701026719, -8.167547658345836],
              [-38.550742593488245, -8.167558677346502],
              [-38.550787994400686, -8.167548868414221],
              [-38.550832253390446, -8.167504867170443],
              [-38.55083530058306, -8.167458830237191],
              [-38.550816941750028, -8.167396669007559],
              [-38.550799216459346, -8.167336227077527],
              [-38.550790914469282, -8.167291715241818],
              [-38.550789249207241, -8.167238436880103],
              [-38.550755796389254, -8.167198238851007],
              [-38.550737422410734, -8.167149645474217],
              [-38.550749830634395, -8.167094030967181],
              [-38.550760873620327, -8.167041580781207],
              [-38.550766015217775, -8.166989757212916],
              [-38.550758902471813, -8.166937105972378],
              [-38.550749444522708, -8.166870974708583],
              [-38.550746516540336, -8.166810368293202],
              [-38.550751563007708, -8.166762434075741],
              [-38.550767955506998, -8.166715778784164],
              [-38.550797598181632, -8.166672394484662],
              [-38.550839226179527, -8.166626852621217],
              [-38.550860605776656, -8.166585358650014],
              [-38.550876365931792, -8.166535898623884],
              [-38.550889219520208, -8.166487882612065],
              [-38.550898624797, -8.166438686891714],
              [-38.550910207263627, -8.166390850373546],
              [-38.550926333553811, -8.166338677175679],
              [-38.550942459738458, -8.166286594429222],
              [-38.550956771036248, -8.166233876501224],
              [-38.550970899760458, -8.166182062894812],
              [-38.550988293307782, -8.166133147388418],
              [-38.55100704985594, -8.166083057510155],
              [-38.551017545267939, -8.166033139373853],
              [-38.551020871830076, -8.165980771069471],
              [-38.551023383801173, -8.165926231001595],
              [-38.551020904116392, -8.16587059996785],
              [-38.551019514896012, -8.16581397516588],
              [-38.551014853778454, -8.165760783930033],
              [-38.551015002755804, -8.165708683458414],
              [-38.551016333784077, -8.16565486570007],
              [-38.551013303462653, -8.165604570752272],
              [-38.551003196395257, -8.165550649856472],
              [-38.550991728549754, -8.165495913378807],
              [-38.55097708141038, -8.165442982424777],
              [-38.550975089317781, -8.165438457590705],
              [-38.550955802434665, -8.165394476287281],
              [-38.550937064778559, -8.165346425229936],
              [-38.55093443955603, -8.165339819286048],
              [-38.550916792973922, -8.165290231745665],
              [-38.550889619121222, -8.165236563274366],
              [-38.550861258980269, -8.165188411087751],
              [-38.550830089439934, -8.165136004515478],
              [-38.55080452911573, -8.165100699685736],
              [-38.550791856412729, -8.165068122727822],
              [-38.550787618294166, -8.165042610422264],
              [-38.550786195847699, -8.165015744453097],
              [-38.550790508259475, -8.16497477425726],
              [-38.550787743127835, -8.164930901729234],
              [-38.550776618326637, -8.164894165673537],
              [-38.550765792348585, -8.164833731395305],
              [-38.550751612801072, -8.164768589866114],
              [-38.550739512686441, -8.164711048653345],
              [-38.550739022497538, -8.164662294214139],
              [-38.550739711122844, -8.16461471696525],
              [-38.550741490714316, -8.16456569368669],
              [-38.550734660038664, -8.164504359315266],
              [-38.550726812599109, -8.16445930526489],
              [-38.550721224933383, -8.164422937161435],
              [-38.550716259580462, -8.164398328572899],
              [-38.551150274318012, -8.164398809905457],
              [-38.551147174094645, -8.164411108009205],
              [-38.551137651568233, -8.164484092622235],
              [-38.551129310984066, -8.164555450399932],
              [-38.551123705586939, -8.16461622826662],
              [-38.55112046559622, -8.164672395677714],
              [-38.551104154416969, -8.164727644072467],
              [-38.551093105494886, -8.164785521422882],
              [-38.55107724749525, -8.164841403485754],
              [-38.551072376297626, -8.164895036416848],
              [-38.551069868892952, -8.164945506131073],
              [-38.55105936443146, -8.165003564988092],
              [-38.551050406344956, -8.165058821531629],
              [-38.55108620655956, -8.165110419150134],
              [-38.551122383637043, -8.16514971564385],
              [-38.551157204284742, -8.165184307101002],
              [-38.551198919112629, -8.165223428822445],
              [-38.551238360528622, -8.165266075667622],
              [-38.551257648548756, -8.165309061982912],
              [-38.55125827875289, -8.165313766211396],
              [-38.551264943406835, -8.165361261160136],
              [-38.551264794767413, -8.165413090277388],
              [-38.551264697424529, -8.165418969581948],
              [-38.551260628814347, -8.165485538111534],
              [-38.551255645845032, -8.165557985039793],
              [-38.551251837046777, -8.165635769966483],
              [-38.551258932979948, -8.165703526752356],
              [-38.5512622222397, -8.165765852168914],
              [-38.551254426112905, -8.165837391452868],
              [-38.551245732489278, -8.165899703586872],
              [-38.551256288443589, -8.165958147600229],
              [-38.551272396386992, -8.166003663060224],
              [-38.551275410785827, -8.16606824948375],
              [-38.551276447728682, -8.166115195478577],
              [-38.551296727900265, -8.166163971852026],
              [-38.551271440410538, -8.166209441428917],
              [-38.551248267000176, -8.166231486151027],
              [-38.551233272528286, -8.166245670571278],
              [-38.551235663684409, -8.166299311551343],
              [-38.551261890679903, -8.166306757728478],
              [-38.551297192799787, -8.166316746632528],
              [-38.551347327321594, -8.16629509359837],
              [-38.551393578511686, -8.166255345755133],
              [-38.551436538109954, -8.166236669690779],
              [-38.551495992419561, -8.166242343618103],
              [-38.551551269865229, -8.166248917434849],
              [-38.551606480550554, -8.166233963475316],
              [-38.551650639745063, -8.166197831383768],
              [-38.55168726897697, -8.166157439680832],
              [-38.551716567965371, -8.166096507139439],
              [-38.551733414422436, -8.166049671397559],
              [-38.551752170169017, -8.166000214646097],
              [-38.551769650055981, -8.165955188653413],
              [-38.551781882888427, -8.165893875400037],
              [-38.55179610439103, -8.165840162357828],
              [-38.551830197096663, -8.16579497384787],
              [-38.551868891407139, -8.165775026676792],
              [-38.55192528073735, -8.165761340309141],
              [-38.55197992226848, -8.165768275170571],
              [-38.552026009771623, -8.165793833776066],
              [-38.552067907920623, -8.165831508377368],
              [-38.552079878605809, -8.165842466375034],
              [-38.552060716992969, -8.16584886730244],
              [-38.552014412435767, -8.165855147742411],
              [-38.551954068022489, -8.165833643720392],
              [-38.5519060378506, -8.165840645858289],
              [-38.55187992002282, -8.165898144742707],
              [-38.551892295692646, -8.165952972658411],
              [-38.551881260453833, -8.16599863895823],
              [-38.551859240696594, -8.166044474003352],
              [-38.551863722890019, -8.166095403724213],
              [-38.551911714571823, -8.166122954406811],
              [-38.551969693818066, -8.166148887992289],
              [-38.55199886169563, -8.166205543538894],
              [-38.551982364868579, -8.166264500315172],
              [-38.551960516682954, -8.166319290352696],
              [-38.551927517230816, -8.166360952433353],
              [-38.551868025981612, -8.166388384143021],
              [-38.551810989302517, -8.166412652723482],
              [-38.551777991340479, -8.166452958004655],
              [-38.55175542515174, -8.166500330128633],
              [-38.551720618748547, -8.166534211274312],
              [-38.551665686720298, -8.166543467048399],
              [-38.551619337718734, -8.166508139240072],
              [-38.551566362424083, -8.166472261371615],
              [-38.55152290173109, -8.166451861414497],
              [-38.551475220970751, -8.166471074970978],
              [-38.551475603097238, -8.166535387119453],
              [-38.551493802043929, -8.166578191313709],
              [-38.551504819212603, -8.166630123252858],
              [-38.551503943348585, -8.166682856087792],
              [-38.551479106311568, -8.166731401559925],
              [-38.551436491001503, -8.166766902171215],
              [-38.551408382350701, -8.166818609850113],
              [-38.551434557390181, -8.166872638994956],
              [-38.551489184422159, -8.166892689525508],
              [-38.551542666045776, -8.166881623121565],
              [-38.551588061618162, -8.166876517618046],
              [-38.551633444878235, -8.166882447300353],
              [-38.551669359106334, -8.166913421832188],
              [-38.551671396282778, -8.166958921694455],
              [-38.551663255440296, -8.167013998248553],
              [-38.551646499809259, -8.167060743630815],
              [-38.551624030603989, -8.167102507796105],
              [-38.551599650377717, -8.16714843065766],
              [-38.551560027632796, -8.167186738621739],
              [-38.551514692117664, -8.16721934174489],
              [-38.551465991890169, -8.16725718737875],
              [-38.551426832511275, -8.167286993307517],
              [-38.551389671795441, -8.167315444659842],
              [-38.551345223853048, -8.167366139244221],
              [-38.551326744868277, -8.167411525912179],
              [-38.551328590346856, -8.167466070809697],
              [-38.551336245428523, -8.167520893504198],
              [-38.551332644221468, -8.167575341911608],
              [-38.551307260303567, -8.167625786263159],
              [-38.551269616154713, -8.167680649192759],
              [-38.55124487091291, -8.167728199767755],
              [-38.551236193696198, -8.167775768156089],
              [-38.551212179127731, -8.167819339629],
              [-38.551183719169671, -8.167860373501203],
              [-38.551143542439895, -8.167906911979259],
              [-38.551133586508016, -8.167961443787542],
              [-38.551156596807765, -8.168004253331281],
              [-38.551175971976129, -8.16805058648988],
              [-38.551192983032038, -8.168100354220631],
              [-38.55120945082669, -8.168148855013609],
              [-38.551229361797105, -8.16820314858286],
              [-38.551247545933265, -8.168259249286388],
              [-38.551268268478474, -8.168318428188089],
              [-38.551285175947264, -8.168379683266496],
              [-38.551290735619126, -8.168441197120714],
              [-38.551290178716975, -8.168452141254059],
              [-38.55128767078795, -8.16850306322301],
              [-38.551280536107654, -8.168551266487459],
              [-38.551277883385417, -8.168569263590911],
              [-38.551277915778876, -8.168621545163164],
              [-38.551288872787907, -8.168646069934717],
              [-38.551313554149559, -8.168655775714299],
              [-38.551290117849121, -8.16866922715128],
              [-38.551278629763267, -8.168713807489105],
              [-38.551276029938116, -8.168765724332568],
              [-38.551279780272267, -8.168821628125945],
              [-38.551283069231332, -8.168884224891423],
              [-38.551288175207198, -8.168945557336599],
              [-38.55129609072096, -8.169011053710847],
              [-38.551301099454236, -8.16907817500676],
              [-38.551313736246328, -8.169143043447795],
              [-38.551321746284039, -8.16920519318429],
              [-38.551328033621679, -8.169265441508513],
              [-38.551327878092593, -8.16932342137733],
              [-38.551344704420217, -8.169376083377108],
              [-38.551362349895008, -8.169426846782228],
              [-38.551372183426473, -8.169481943245557],
              [-38.551380657276731, -8.169535229151673],
              [-38.551393305707307, -8.169589695567696],
              [-38.551407227791572, -8.169641721178172],
              [-38.551418069611614, -8.169688135319891],
              [-38.551436077218582, -8.169739803648547],
              [-38.551438941992394, -8.169775806911883],
              [-38.551411904413399, -8.169762389972973],
              [-38.551380720234896, -8.169722737215821],
              [-38.551346728136927, -8.169677473289573],
              [-38.551307005878954, -8.169642062327709],
              [-38.551274347110741, -8.169622217021409],
              [-38.551239729118556, -8.169649676186975],
              [-38.551186223672822, -8.169681727478142],
              [-38.551133021181201, -8.169686371996827],
              [-38.551076558280648, -8.169684138503106],
              [-38.551018729330487, -8.169685792942472],
              [-38.550966872669058, -8.169704639971208],
              [-38.550926263859488, -8.169731459293317],
              [-38.550879485120227, -8.169755467736504],
              [-38.550822200336476, -8.16975757501349],
              [-38.550762470149195, -8.169754614229044],
              [-38.550695384535324, -8.169753635223435],
              [-38.55062647643684, -8.169758804950986],
              [-38.550559015762104, -8.169768498897858],
              [-38.55050017273426, -8.169783991364024],
              [-38.550440338508146, -8.169792879689922],
              [-38.550381119799162, -8.169819587827096],
              [-38.550327256816239, -8.169846573264111],
              [-38.550278843479525, -8.16987103208101],
              [-38.550231981402362, -8.169888346871089],
              [-38.550136658743298, -8.169891225841829],
              [-38.550117121732896, -8.169908390090109],
              [-38.550066589430806, -8.169960524978778],
              [-38.550018955297851, -8.170018723398215],
              [-38.549966083148554, -8.170052584273183],
              [-38.549910959926088, -8.170069980288751],
              [-38.549864368531118, -8.170088742574455],
              [-38.549809587033437, -8.170125224425025],
              [-38.549756890939818, -8.170163969899447],
              [-38.549697934641017, -8.170199452111357],
              [-38.549651332473537, -8.170227802319637],
              [-38.549593954512474, -8.170231989721616],
              [-38.549548305677945, -8.170219727825401],
              [-38.5494974028447, -8.170197419852132],
              [-38.549440695755621, -8.1701700400723],
              [-38.549384346317119, -8.170147545115748],
              [-38.549332711476374, -8.170130392097377],
              [-38.549288159029182, -8.170111890174551],
              [-38.549217465503467, -8.17009010283792],
              [-38.549141320090598, -8.170072832037526],
              [-38.54907442097786, -8.170067420798192],
              [-38.549011930907646, -8.170096929108105],
              [-38.548953700903418, -8.170132321565715],
              [-38.548907724915892, -8.170168903557002],
              [-38.548888334563287, -8.170217093095857],
              [-38.54885940058513, -8.170275674024611],
              [-38.548833636902167, -8.170340499704501],
              [-38.548819036659985, -8.17040759914112],
              [-38.54880733874419, -8.170477053576723],
              [-38.548793010223122, -8.170544696029111],
              [-38.54878113784887, -8.170607818597309],
              [-38.548764552506888, -8.170664151975336],
              [-38.548730093466872, -8.170711510626012],
              [-38.548707976194152, -8.170762772494022],
              [-38.548726811587244, -8.170804582531389],
              [-38.548783070699969, -8.170826715671218],
              [-38.548846229543727, -8.170848494687252],
              [-38.548926200462382, -8.170854553711155],
              [-38.549022160431861, -8.170850228509552],
              [-38.549076086894694, -8.170847755943779],
              [-38.549130100990446, -8.170848087494365],
              [-38.549182845783847, -8.170846970384343],
              [-38.549237760986266, -8.170853272786282],
              [-38.549283138249251, -8.170864901250743],
              [-38.549332418062399, -8.170877438581648],
              [-38.549384250286288, -8.170880571694296],
              [-38.549436904507481, -8.170879273545536],
              [-38.549488202194695, -8.17087336080435],
              [-38.549541852363689, -8.170874415514582],
              [-38.549590499868295, -8.170884148084456],
              [-38.549640416376036, -8.170895600657726],
              [-38.549692336548127, -8.170901266497797],
              [-38.549738900873812, -8.170906745466279],
              [-38.549832932266668, -8.170922227014575],
              [-38.549881396501767, -8.170933497037987],
              [-38.54992777511859, -8.170942684329185],
              [-38.54997805851702, -8.170950790528845],
              [-38.550027615065545, -8.170959438628779],
              [-38.550072269669755, -8.170967900366763],
              [-38.550152025654313, -8.171003808223563],
              [-38.550225597231929, -8.171049839866246],
              [-38.55030024958613, -8.171103561156864],
              [-38.55033852729624, -8.171131915320519],
              [-38.550285963717698, -8.171133304138211],
              [-38.550239307359078, -8.171128910558256],
              [-38.550183925734117, -8.171133733423531],
              [-38.550129436009144, -8.171152758309123],
              [-38.550083724337291, -8.171196486469922],
              [-38.55008104063613, -8.171242071524908],
              [-38.550112840296677, -8.171299544197478],
              [-38.550140758179403, -8.171337565273106],
              [-38.550187798569993, -8.171404371489755],
              [-38.550195440979977, -8.171470410281811],
              [-38.550202092401534, -8.171529664037456],
              [-38.550207023144573, -8.171585207329102],
              [-38.550231899003201, -8.171664561791124],
              [-38.550252187897634, -8.171705649762478],
              [-38.550298589641301, -8.171775349736988],
              [-38.550342194514094, -8.171829579227634],
              [-38.550375840045348, -8.171860099101032],
              [-38.550401215825062, -8.171898388693124],
              [-38.550437009427355, -8.171956318044479],
              [-38.550491435068658, -8.17199463991205],
              [-38.550558406183164, -8.172017146529795],
              [-38.55063677854195, -8.171991725996145],
              [-38.550679203322751, -8.171964818283726],
              [-38.55076176483395, -8.171927553104757],
              [-38.550845661034032, -8.171914440198041],
              [-38.55092775425608, -8.171890018713999],
              [-38.550968268566983, -8.171866726947528],
              [-38.55100300655436, -8.171894534409955],
              [-38.551001906972218, -8.171903668888731],
              [-38.550969538152792, -8.171949130553767],
              [-38.550948437267955, -8.171984654951753],
              [-38.550937240587778, -8.172012049620609],
              [-38.550929130756494, -8.172039176358098],
              [-38.550922752029841, -8.172060696964161],
              [-38.550901643649979, -8.172102914833133],
              [-38.550864108084305, -8.172141405912281],
              [-38.550859745547307, -8.172145923692092],
              [-38.550812490456238, -8.172189740670186],
              [-38.550756796278151, -8.172230382434451],
              [-38.550685592832615, -8.17225825316782],
              [-38.550622556921525, -8.172288575177392],
              [-38.550558439627721, -8.172311750230842],
              [-38.550484530244397, -8.172323969645538],
              [-38.550412798484025, -8.172337186443617],
              [-38.550361762224014, -8.172352687543444],
              [-38.550281423087242, -8.172350879642904],
              [-38.550236231845034, -8.172335090674583],
              [-38.550177154479677, -8.172316301330149],
              [-38.550096113830087, -8.172292422214257],
              [-38.550013259601101, -8.172266732016318],
              [-38.549968519852001, -8.172253114380197],
              [-38.549888302542243, -8.172223628087851],
              [-38.549819978241899, -8.172193521847833],
              [-38.549767254258491, -8.172175915397352],
              [-38.549722338545102, -8.172157322654353],
              [-38.549676968830646, -8.172138819853616],
              [-38.549591683870531, -8.172095036376572],
              [-38.549510940911048, -8.172048634819255],
              [-38.54947057075757, -8.172024258154504],
              [-38.549386993935791, -8.171995401192591],
              [-38.549299505636945, -8.17197359514731],
              [-38.54921338078578, -8.17195025291117],
              [-38.549166909161805, -8.171942964930839],
              [-38.549085035992007, -8.171933014367863],
              [-38.549036375580599, -8.17193467872511],
              [-38.548987434497946, -8.171943759863801],
              [-38.548939860406968, -8.171948138993638],
              [-38.548887290630852, -8.17195495476315],
              [-38.548838533644961, -8.171961774777786],
              [-38.548786228282111, -8.171975646119222],
              [-38.5487371911249, -8.1719894306468],
              [-38.548686243299706, -8.17200701203917],
              [-38.548640381632843, -8.172022428241002],
              [-38.548591890268696, -8.172035218382451],
              [-38.54850580234308, -8.172059815829829],
              [-38.548417536279466, -8.172083868112241],
              [-38.548368232045441, -8.172092948768988],
              [-38.5483225632113, -8.172098324928619],
              [-38.54827198997603, -8.172105685560277],
              [-38.548222133421262, -8.172121549514504],
              [-38.548176627217465, -8.172143749991333],
              [-38.548133387679115, -8.172168485662752],
              [-38.548087695323879, -8.172194756280469],
              [-38.548025897504765, -8.172254114488574],
              [-38.547974362277955, -8.172309594699065],
              [-38.547926979333603, -8.17238606450559],
              [-38.547885407261823, -8.172461726727812],
              [-38.547869623967628, -8.172531176551512],
              [-38.547838250592896, -8.172579081271035],
              [-38.547805233173392, -8.172635848487502],
              [-38.547777188934234, -8.172710169030717],
              [-38.547753859893554, -8.172789288821171],
              [-38.547738067596718, -8.172866698439892],
              [-38.547714837264593, -8.172938763047917],
              [-38.547704033322219, -8.173020248600649],
              [-38.547706158275666, -8.173068009855649],
              [-38.547721086712095, -8.173112800496574],
              [-38.54776984529844, -8.173185307402022],
              [-38.54783042193597, -8.173243174220834],
              [-38.547864974067522, -8.17327469028726],
              [-38.54789907372502, -8.173304939511263],
              [-38.547939529894208, -8.173333567693129],
              [-38.547986528719896, -8.17335650467991],
              [-38.548036981202046, -8.173375917875951],
              [-38.548088609364285, -8.173399312286236],
              [-38.548135245286225, -8.173422067946165],
              [-38.548163548015474, -8.17344073276292],
              [-38.548182234974895, -8.173453145619463],
              [-38.548226781112064, -8.173477527078784],
              [-38.548273048534263, -8.173505076290752],
              [-38.548318225572331, -8.1735335288044],
              [-38.548358954546089, -8.173561795441712],
              [-38.548400405400457, -8.173593952335647],
              [-38.548437319646517, -8.17362393330264],
              [-38.548502431437164, -8.173685423188493],
              [-38.54854911497879, -8.173746621139172],
              [-38.548589265442651, -8.173805007768456],
              [-38.548637041537084, -8.173863402904063],
              [-38.54868546480516, -8.173911396727293],
              [-38.548725812725586, -8.173955672985612],
              [-38.548764522425508, -8.174003655962279],
              [-38.548803329725679, -8.17404557873161],
              [-38.548851667132531, -8.174089230724247],
              [-38.548882852687221, -8.17412770779247],
              [-38.548930562468094, -8.174164394239172],
              [-38.548972815120045, -8.17421021029074],
              [-38.548954324847017, -8.174265094296866],
              [-38.548939531807676, -8.174342052818385],
              [-38.548949530798133, -8.17441126007985],
              [-38.548961306152897, -8.174434609939462],
              [-38.54898313598386, -8.174477780093801],
              [-38.549009958183099, -8.174521498528877],
              [-38.549018204247943, -8.174534894684001],
              [-38.549049181570211, -8.174597160498609],
              [-38.549065854456003, -8.174624676628275],
              [-38.549087518287401, -8.174654007370586],
              [-38.54911425704136, -8.174691213131487],
              [-38.549123955411886, -8.174704791808674],
              [-38.549171670258772, -8.174737046067136],
              [-38.549264254477116, -8.174748546271037],
              [-38.549308188810954, -8.174752032426767],
              [-38.549276387501763, -8.17477678095266],
              [-38.54926779566199, -8.174828781526127],
              [-38.549255755141239, -8.174879873731207],
              [-38.549221566284125, -8.174928860842623],
              [-38.549208444907691, -8.174972353836537],
              [-38.549186221718358, -8.175036912100108],
              [-38.549157089581882, -8.175109965188087],
              [-38.549133036930982, -8.175186822938368],
              [-38.549102021929642, -8.175238888957955],
              [-38.549064194750962, -8.175294113213512],
              [-38.549028533414173, -8.175360827338435],
              [-38.548995594901562, -8.175428087210696],
              [-38.548967925772409, -8.175491734859582],
              [-38.548944977580405, -8.175555116414507],
              [-38.548922393726592, -8.175617412944813],
              [-38.548901343903296, -8.175687942355287],
              [-38.548887466632458, -8.175757756146211],
              [-38.548887108601946, -8.175834007140534],
              [-38.548890018811434, -8.175910171328118],
              [-38.548900043533216, -8.175956494152626],
              [-38.548922372485464, -8.176040278006196],
              [-38.548946520535608, -8.176120988505472],
              [-38.548991193493578, -8.176194395250883],
              [-38.549040591486786, -8.176263917807509],
              [-38.549102343535836, -8.176326579750986],
              [-38.549172277949658, -8.176378577422886],
              [-38.549250758259653, -8.176419549414321],
              [-38.549292040299825, -8.176440670847645],
              [-38.549362805605298, -8.17648036788467],
              [-38.549429131450623, -8.176512190601827],
              [-38.549487118578888, -8.176532245201898],
              [-38.549560906415621, -8.17654815658493],
              [-38.549605652607056, -8.176556437601821],
              [-38.549652311916255, -8.176558750884304],
              [-38.549705779963006, -8.176560981291727],
              [-38.549794365960743, -8.176576275945775],
              [-38.549868879973815, -8.176592278534589],
              [-38.54994531643532, -8.176593991765319],
              [-38.550020399587133, -8.176588195923545],
              [-38.550133636746615, -8.176559558053773],
              [-38.550180855856297, -8.176548394485234],
              [-38.550157216499791, -8.176580569253311],
              [-38.550140739272969, -8.176621344969707],
              [-38.550132584369898, -8.176688542056025],
              [-38.550115379360136, -8.176730673747635],
              [-38.550089462025724, -8.176770353527951],
              [-38.550064078731154, -8.176819531406087],
              [-38.550041145019883, -8.176870068794884],
              [-38.549996776534513, -8.176929718021329],
              [-38.549965958676353, -8.176967854646399],
              [-38.549939126899581, -8.177013412805229],
              [-38.549914024638689, -8.177054812075394],
              [-38.549904608069738, -8.177113686125033],
              [-38.549879393917692, -8.1771738993732],
              [-38.549845476051594, -8.177223972256405],
              [-38.549818364896765, -8.177275771311566],
              [-38.549794238683887, -8.177337252098134],
              [-38.549771289788062, -8.177401266861679],
              [-38.549749073634629, -8.177459493484086],
              [-38.549723130845052, -8.177521786313431],
              [-38.549693200335433, -8.177578104838735],
              [-38.549657828577914, -8.177629351964251],
              [-38.549621099241925, -8.177676889024296],
              [-38.549581558393918, -8.17772198073264],
              [-38.549562625575497, -8.177766643136003],
              [-38.549543315005224, -8.177824330266112],
              [-38.549538528651389, -8.177882847650672],
              [-38.549549820171372, -8.177932970874403],
              [-38.549576093180654, -8.177980939932015],
              [-38.549584432040675, -8.177992617585444],
              [-38.549612259012477, -8.178031090867959],
              [-38.549636627234214, -8.178077610561203],
              [-38.549646501274339, -8.178096526117594],
              [-38.549676381131206, -8.178166298221605],
              [-38.549701452789947, -8.178233170490339],
              [-38.549729334949653, -8.178303392627852],
              [-38.549766653496455, -8.178378057442423],
              [-38.549816227658063, -8.178452735902569],
              [-38.549869615808234, -8.178526423627678],
              [-38.549924910100962, -8.178600384826051],
              [-38.549984849829457, -8.178660511968291],
              [-38.550058141976955, -8.1787140509379],
              [-38.550131712068293, -8.178762615320943],
              [-38.550212192786248, -8.178801237614149],
              [-38.550291960641971, -8.178827919378531],
              [-38.550365381347078, -8.178847810135405],
              [-38.550404087435034, -8.17889959197705],
              [-38.550399673941705, -8.17894951680872],
              [-38.550393701742394, -8.179013188675441],
              [-38.550425041910266, -8.179076178423713],
              [-38.55049191630512, -8.179105016669572],
              [-38.550569422077203, -8.179125092840078],
              [-38.550634647315945, -8.179167406641694],
              [-38.550710062176123, -8.179190103582144],
              [-38.550768944114289, -8.179222189187723],
              [-38.550812732587275, -8.179275333428947],
              [-38.550863790146792, -8.179322696787505],
              [-38.550953169858595, -8.179359429328896],
              [-38.550992933989939, -8.179358207168827],
              [-38.550966840658766, -8.179392821464107],
              [-38.550947830683668, -8.179425363220975],
              [-38.550894314355048, -8.1794659169043],
              [-38.550885807095028, -8.179523525650769],
              [-38.550888463751313, -8.179583227207516],
              [-38.550896468375988, -8.179650261353411],
              [-38.550917733630385, -8.179711611729804],
              [-38.550941726821328, -8.179768985229003],
              [-38.55095845792026, -8.1798258983968],
              [-38.550975008976756, -8.179881454577458],
              [-38.551000099977152, -8.1799313217416],
              [-38.551021017717026, -8.179978742052857],
              [-38.551046996717574, -8.180046338877494],
              [-38.551067807695127, -8.180108050552215],
              [-38.551051628345064, -8.180207077992225],
              [-38.551042873029303, -8.180242977881395],
              [-38.551029286097489, -8.180215736606684],
              [-38.550995818680164, -8.180187659173884],
              [-38.55095944764053, -8.180158312178442],
              [-38.55090765145674, -8.180121892750918],
              [-38.550854028188496, -8.180095692411253],
              [-38.550794067157717, -8.180054289012881],
              [-38.550733649086879, -8.180015689123374],
              [-38.550676039007996, -8.179982700397554],
              [-38.550622242516518, -8.179949082737147],
              [-38.550583071957313, -8.17990661699562],
              [-38.550553546344176, -8.179844533799574],
              [-38.550524389442806, -8.179777476128159],
              [-38.550499048760685, -8.179707437769054],
              [-38.550461714763188, -8.179646340859897],
              [-38.550414019005025, -8.179596448523149],
              [-38.55035324601927, -8.179550611992736],
              [-38.550287111103096, -8.17950992527626],
              [-38.550212806893882, -8.179468234482238],
              [-38.55013805872624, -8.179417678843725],
              [-38.550063205088946, -8.17938023867606],
              [-38.549993326241193, -8.179359085469809],
              [-38.549937367836776, -8.179391495585177],
              [-38.549912986092991, -8.179437870527849],
              [-38.549883609278346, -8.179486048954578],
              [-38.549876104133965, -8.179540402500876],
              [-38.549891306988933, -8.17958374614012],
              [-38.549922311683687, -8.179622042039586],
              [-38.54996829357065, -8.179662073209133],
              [-38.550009643123829, -8.179704270074696],
              [-38.550060875404142, -8.179757694038218],
              [-38.550102839460692, -8.179818615230394],
              [-38.550122019338332, -8.179877521105476],
              [-38.55012940468724, -8.179929991730013],
              [-38.550135881806206, -8.179982823153134],
              [-38.550148975711885, -8.180044888087773],
              [-38.550157149917233, -8.180122505362611],
              [-38.550158342989334, -8.180191974149242],
              [-38.550163803656872, -8.180260724066235],
              [-38.550173977823938, -8.18033590135096],
              [-38.550176528784178, -8.180408808840268],
              [-38.550179718570014, -8.180478732110515],
              [-38.550195802239458, -8.18054622751615],
              [-38.550221513243173, -8.180609844181824],
              [-38.550243877156881, -8.180662874188156],
              [-38.550202591495456, -8.180644556851604],
              [-38.55016140848651, -8.18061565669236],
              [-38.550117963218348, -8.180580241436839],
              [-38.550084594433002, -8.180545199204458],
              [-38.550055045627701, -8.180503829553238],
              [-38.550019425648799, -8.180452412922204],
              [-38.549983357446884, -8.180395930453935],
              [-38.54995228199688, -8.180339724899781],
              [-38.549919565973674, -8.180289306471286],
              [-38.549883755027849, -8.180246301691685],
              [-38.549861928978224, -8.180199423031505],
              [-38.549837007596757, -8.180160319830442],
              [-38.549813828850269, -8.180105389396257],
              [-38.549777422037124, -8.180027107502365],
              [-38.549744557077311, -8.179947653669938],
              [-38.5497000621165, -8.17987696076259],
              [-38.549649825231597, -8.179826432341697],
              [-38.549585505695113, -8.179786018892344],
              [-38.549504382570184, -8.179753456064693],
              [-38.549419515233048, -8.179740607673503],
              [-38.54933801142699, -8.179723692656495],
              [-38.549262873682878, -8.179696744505133],
              [-38.549220981855342, -8.179652466557119],
              [-38.549234850501861, -8.179590431669917],
              [-38.549289801844616, -8.179565618536365],
              [-38.549336143271667, -8.179527951552471],
              [-38.549372601489843, -8.179479509707017],
              [-38.549366321380226, -8.179413020181251],
              [-38.549326042521713, -8.17938755814478],
              [-38.54929294249078, -8.179355862892884],
              [-38.5492789213028, -8.179311254218447],
              [-38.549256098288076, -8.179263017631914],
              [-38.549220839410985, -8.179213591294419],
              [-38.549182390659681, -8.17917528704327],
              [-38.549145227706639, -8.179123778170721],
              [-38.549101628260757, -8.179064211850639],
              [-38.549071381354416, -8.178997966943605],
              [-38.549045675912026, -8.178929646695684],
              [-38.549005699762908, -8.178877230149268],
              [-38.548967796672571, -8.178838112417017],
              [-38.548922364047385, -8.178794192301535],
              [-38.548875100940549, -8.178763476190595],
              [-38.548825263801561, -8.178761340163158],
              [-38.548783255603382, -8.178739946505347],
              [-38.548742164997137, -8.178709870436409],
              [-38.548705711311676, -8.178673558324189],
              [-38.548660825157391, -8.178628101100621],
              [-38.548612304976373, -8.178585082029597],
              [-38.5485637724939, -8.178553007680948],
              [-38.54851842502957, -8.1785141529535],
              [-38.548453007884262, -8.178481336114867],
              [-38.548376316349, -8.178463974032063],
              [-38.548292822976528, -8.178440544023447],
              [-38.54824735422374, -8.178428824853354],
              [-38.548163303647954, -8.178416519841807],
              [-38.548080157233336, -8.178407472110313],
              [-38.547995102970965, -8.178399688562328],
              [-38.547919018726226, -8.178407382388643],
              [-38.547851629799951, -8.17843263369196],
              [-38.547781251270344, -8.178452364042858],
              [-38.547728309545022, -8.178466234484079],
              [-38.547680715505116, -8.17848761845082],
              [-38.547648632475102, -8.178520326318708],
              [-38.547625519648726, -8.178568330677273],
              [-38.547607840291413, -8.17862828083115],
              [-38.54760695054776, -8.178692772393882],
              [-38.547613411822191, -8.178759352588417],
              [-38.547629224081916, -8.178825581437886],
              [-38.547645946004664, -8.178890183161688],
              [-38.547680008884697, -8.17895344750378],
              [-38.547716253507595, -8.179014091165094],
              [-38.547750332005492, -8.179063516302879],
              [-38.547787055839692, -8.179101728300443],
              [-38.547835967246392, -8.179119783044085],
              [-38.547902878125569, -8.179116058886446],
              [-38.547973871357335, -8.179114871950107],
              [-38.548041864288898, -8.179117661552123],
              [-38.548115483182052, -8.179122718742287],
              [-38.548180560285992, -8.17913509301585],
              [-38.548234737501872, -8.179152791788827],
              [-38.548284996418339, -8.179183511321796],
              [-38.548329078780583, -8.179217208876427],
              [-38.548380159640736, -8.179243587601469],
              [-38.548423535811672, -8.179259465233985],
              [-38.548471088282135, -8.179275076170068],
              [-38.548548047031034, -8.179297142066186],
              [-38.548636174061009, -8.179317411371484],
              [-38.548721389858194, -8.179343195004533],
              [-38.548784365498108, -8.179367597007628],
              [-38.548797245516504, -8.179377470698036],
              [-38.548785666105623, -8.179421960356205],
              [-38.548769294649951, -8.179449168254486],
              [-38.548733831818446, -8.179500415177694],
              [-38.54868765318745, -8.179554725514114],
              [-38.548650113632505, -8.179595929892912],
              [-38.548616022786746, -8.179638042643205],
              [-38.548576663903177, -8.179682501268962],
              [-38.548541753964237, -8.179726422145229],
              [-38.548502297563601, -8.179776850512219],
              [-38.548465918190622, -8.179835784838044],
              [-38.548446052576743, -8.179902426063849],
              [-38.548450330942821, -8.17997280281139],
              [-38.548407433828224, -8.180015177059024],
              [-38.54838278963485, -8.179971913275345],
              [-38.548353511973055, -8.179931810146744],
              [-38.548306046650119, -8.179919365124828],
              [-38.548276225881096, -8.179958497685353],
              [-38.548277619541977, -8.180010780729246],
              [-38.548288895672961, -8.180074471820284],
              [-38.548271050859533, -8.180120130336736],
              [-38.548262598403859, -8.180128713867244],
              [-38.548222608536896, -8.180169101389675],
              [-38.548187809567729, -8.180195022327153],
              [-38.548178723667625, -8.180201796099645],
              [-38.548142180366519, -8.180244991490325],
              [-38.548108270466784, -8.180287556655001],
              [-38.548064472893905, -8.18032332683018],
              [-38.5480140665221, -8.180342808178381],
              [-38.547960754387745, -8.180362919436668],
              [-38.547911896917171, -8.180377427622428],
              [-38.547861945668487, -8.180395824031221],
              [-38.547819792650685, -8.18042227942145],
              [-38.547784353160338, -8.180452631771843],
              [-38.547756351420013, -8.180488690942287],
              [-38.547726621846728, -8.180527371296183],
              [-38.547701424059944, -8.1805725692913],
              [-38.547684934267849, -8.18062410869773],
              [-38.547694396395073, -8.180686260078492],
              [-38.547710381114825, -8.180760629831312],
              [-38.547709872850092, -8.180808840385286],
              [-38.547711079667813, -8.18086573628783],
              [-38.547707315663686, -8.180902908006471],
              [-38.547673753985109, -8.180878086489223],
              [-38.547612309461506, -8.180864359401427],
              [-38.547553424710905, -8.180834806002839],
              [-38.547493094076941, -8.180799371571986],
              [-38.54743430023013, -8.180769727806295],
              [-38.547362307486615, -8.180771456226898],
              [-38.547302370168111, -8.180789750925053],
              [-38.547256674199382, -8.18081828268792],
              [-38.547221122718582, -8.180867358501734],
              [-38.547231048490644, -8.180920826979918],
              [-38.54727584260678, -8.180967460140064],
              [-38.54730765112118, -8.18101733503528],
              [-38.547306778756294, -8.181066359249071],
              [-38.547286298734214, -8.181114095164327],
              [-38.547252492007722, -8.181145534719288],
              [-38.547220862532242, -8.181178333496209],
              [-38.547197465192056, -8.181236920422778],
              [-38.54718460192155, -8.181292624675141],
              [-38.547187710814271, -8.181353321705235],
              [-38.547211422416446, -8.181418473987309],
              [-38.54726018979629, -8.181483925697748],
              [-38.547306442421814, -8.181525314252818],
              [-38.547338716428555, -8.181564968549349],
              [-38.547351117352299, -8.181597183487874],
              [-38.547316365665083, -8.181581044035308],
              [-38.547269092983427, -8.181558649337934],
              [-38.547212208142049, -8.181526655912858],
              [-38.547168943602095, -8.181492145076755],
              [-38.547120233730759, -8.181456180895649],
              [-38.547067080920272, -8.181415417753316],
              [-38.547014852597556, -8.181359911903284],
              [-38.546951358840488, -8.181311810512458],
              [-38.546876953075625, -8.181279706947427],
              [-38.546820335137689, -8.181252598198059],
              [-38.54676490259618, -8.181220877699545],
              [-38.546728530673171, -8.181192525311477],
              [-38.546669280568082, -8.181164961327745],
              [-38.546614800048175, -8.181174488159973],
              [-38.54657479447701, -8.181228533793441],
              [-38.546540044995098, -8.181290816485989],
              [-38.546482098244979, -8.181314902241276],
              [-38.546429796387407, -8.181324521944484],
              [-38.546388455523271, -8.181355138894512],
              [-38.54636406281633, -8.181410739719713],
              [-38.546361535477288, -8.18147812389731],
              [-38.546373804622654, -8.181546519661097],
              [-38.546385252941043, -8.181618261240676],
              [-38.546376640691761, -8.181687809443931],
              [-38.546364228136852, -8.181746227739104],
              [-38.546354530473941, -8.181812247080288],
              [-38.546370699405735, -8.18188408441155],
              [-38.546393402058087, -8.181958280838501],
              [-38.546411757873521, -8.182022974886323],
              [-38.546437557185534, -8.182088310455255],
              [-38.546473260037018, -8.182146420949383],
              [-38.546497800675077, -8.182200900818245],
              [-38.546530240365328, -8.182254575479543],
              [-38.546567939142363, -8.182313864087115],
              [-38.546568506832685, -8.182373834643666],
              [-38.546580885322562, -8.182425858645823],
              [-38.54654614474579, -8.182399859829959],
              [-38.54647560731167, -8.182398876138853],
              [-38.546431835546009, -8.182411490336699],
              [-38.546390673472089, -8.182444459241463],
              [-38.546350790538845, -8.182470193388648],
              [-38.546306200486427, -8.182483892081946],
              [-38.546293940470647, -8.182487677314663],
              [-38.546259045686774, -8.182517758766821],
              [-38.546238562491858, -8.182568117729801],
              [-38.546254021327712, -8.182625662792963],
              [-38.546276551839604, -8.182691537414154],
              [-38.54629916843502, -8.182761572939638],
              [-38.546336232044894, -8.182820589497711],
              [-38.546356690730285, -8.182872170315573],
              [-38.546340477201859, -8.18292000141034],
              [-38.546304495745908, -8.182947639430052],
              [-38.546257618150769, -8.18297716470895],
              [-38.54619534086747, -8.182977094763649],
              [-38.546125709223482, -8.18297783061827],
              [-38.546060139483536, -8.182999194162326],
              [-38.546012331202064, -8.183048889241512],
              [-38.546012543042941, -8.183102346822706],
              [-38.546022924655105, -8.18315400678407],
              [-38.546039105034161, -8.183215713472519],
              [-38.546045894531851, -8.18323272614443],
              [-38.546066082382929, -8.183282949885408],
              [-38.546086916368296, -8.18332376730635],
              [-38.546103855070051, -8.183357163256749],
              [-38.546120783128679, -8.183399966239778],
              [-38.546152192712455, -8.183481499099532],
              [-38.546196983281618, -8.183531388662914],
              [-38.546254218958168, -8.18357441782031],
              [-38.546315093757968, -8.183610576677724],
              [-38.546388983328214, -8.183617805384854],
              [-38.5464654237351, -8.183617167586872],
              [-38.546535963224095, -8.183616523150993],
              [-38.546633631396475, -8.183629657906732],
              [-38.5466554079525, -8.183639813008551],
              [-38.546633063894355, -8.183649828146658],
              [-38.546591279002094, -8.183671489819812],
              [-38.546547408746868, -8.183690797386387],
              [-38.546506435987041, -8.183716801675116],
              [-38.546475435688187, -8.18375493777096],
              [-38.546444057212284, -8.183806369936267],
              [-38.546439327575563, -8.183814233981529],
              [-38.546429032325456, -8.18384669481968],
              [-38.546357233186079, -8.183837297608479],
              [-38.551566467377306, -8.18466433108161],
            ],
          ],
          [
            [
              [-38.532523263050891, -8.226894179657066],
              [-38.538494959986878, -8.22473267327965],
              [-38.53497891972988, -8.213820826793647],
              [-38.538702743396556, -8.216124856583725],
              [-38.547119103264698, -8.209990628515969],
              [-38.546567381707703, -8.202137665986601],
              [-38.560259929875023, -8.195581826207764],
              [-38.558516712195491, -8.191407350318539],
              [-38.552677489727515, -8.193602261072765],
              [-38.550633254220578, -8.193369878920169],
              [-38.546417178450355, -8.183972953003151],
              [-38.546381572414404, -8.183989646709628],
              [-38.546375983774503, -8.184034595255971],
              [-38.54636911065888, -8.184091482070819],
              [-38.546379639116019, -8.184173895990645],
              [-38.546384232956861, -8.184205830831035],
              [-38.546357564199859, -8.184186715436521],
              [-38.546323544746173, -8.184165149571658],
              [-38.546260191589845, -8.184152957802521],
              [-38.546196286265094, -8.184147368425322],
              [-38.546153869396846, -8.184165863506294],
              [-38.546079859975045, -8.184184232632523],
              [-38.546010765655232, -8.184191300724461],
              [-38.545932967180015, -8.184188590167071],
              [-38.545853894152778, -8.184189043994012],
              [-38.545770472227616, -8.184181804465847],
              [-38.545685590290354, -8.184181166300128],
              [-38.545622494543942, -8.184182180764211],
              [-38.54560616366458, -8.184173207612734],
              [-38.545592966672793, -8.184122539453638],
              [-38.545575335336679, -8.18405947433441],
              [-38.545533292077238, -8.183988602850519],
              [-38.545494240174392, -8.183921714631495],
              [-38.545445012751642, -8.183861689338432],
              [-38.545367722920275, -8.183810768147488],
              [-38.545284975235361, -8.183769428748246],
              [-38.545204577678369, -8.183737137212935],
              [-38.545126986930157, -8.183711451843768],
              [-38.545046028076115, -8.183693812925682],
              [-38.544972160081151, -8.183667589005937],
              [-38.544903755314479, -8.183627079757589],
              [-38.544875808621029, -8.183614204042541],
              [-38.54483334408966, -8.18359470894838],
              [-38.544784255870304, -8.183572131007011],
              [-38.544760664525697, -8.183561250143299],
              [-38.544713789307792, -8.183508554058367],
              [-38.54466445288913, -8.183464719523858],
              [-38.544612122378673, -8.183419343919176],
              [-38.544561076096294, -8.183362301404275],
              [-38.544531290780149, -8.183289725050837],
              [-38.544504492178675, -8.183225202320939],
              [-38.544467787734348, -8.18316989455926],
              [-38.54443317655376, -8.183109976085692],
              [-38.544411086684327, -8.183056041583558],
              [-38.544411846650121, -8.183026374073581],
              [-38.54443842530894, -8.182964806000859],
              [-38.544460175500291, -8.182918157117792],
              [-38.5444769425768, -8.182862728738067],
              [-38.544492810445497, -8.18279970134685],
              [-38.544507668703289, -8.182746351216835],
              [-38.544526517203025, -8.182696714130133],
              [-38.544552535920637, -8.182648532362105],
              [-38.54456657465105, -8.182597352161213],
              [-38.54457009054952, -8.182539104815991],
              [-38.544560359819805, -8.182474058613575],
              [-38.544533016356993, -8.18240971617618],
              [-38.544495773030839, -8.182349342480022],
              [-38.544458975310107, -8.18229629592296],
              [-38.5444251801162, -8.182237373345961],
              [-38.544389094706226, -8.18219662910483],
              [-38.544332440915596, -8.182121218419594],
              [-38.544294439526062, -8.182088884077908],
              [-38.544260890927617, -8.182052665304457],
              [-38.544212195145832, -8.182004489734078],
              [-38.544173193417294, -8.18197369194332],
              [-38.544130466921196, -8.181945241707734],
              [-38.544128651570539, -8.181944968302709],
              [-38.544079183908948, -8.18193695268533],
              [-38.544029232826553, -8.181954896337578],
              [-38.543984436522848, -8.181990303102017],
              [-38.543952352500504, -8.182023372447857],
              [-38.543917537476105, -8.182063041728886],
              [-38.543878448033375, -8.182109399654211],
              [-38.543842355037562, -8.182155218243974],
              [-38.543808320416005, -8.182227089426183],
              [-38.543770473820473, -8.182298142229474],
              [-38.543739622032362, -8.182365223024215],
              [-38.543723837751429, -8.182434491687086],
              [-38.543709359309084, -8.182473098482882],
              [-38.54370216573556, -8.182492175801229],
              [-38.543706916632182, -8.182545457589953],
              [-38.543738543852804, -8.182594789826481],
              [-38.543765084037709, -8.182646920338993],
              [-38.54376618715699, -8.182714670381182],
              [-38.543773649108104, -8.18277889996248],
              [-38.543781040237342, -8.182825581711365],
              [-38.543776677399194, -8.182830189853901],
              [-38.543751773801709, -8.182855669289992],
              [-38.543678348240078, -8.182839757210729],
              [-38.54361508257513, -8.18283073097191],
              [-38.543553433511185, -8.182837083445493],
              [-38.543490873731713, -8.182845967545674],
              [-38.543433227668871, -8.18284481698046],
              [-38.543379675189286, -8.182836344394952],
              [-38.543323402307735, -8.182825155157854],
              [-38.543264322568497, -8.182807450172753],
              [-38.543207880181271, -8.182785677806329],
              [-38.543153338888878, -8.182768611102773],
              [-38.543083536969633, -8.182759486938936],
              [-38.543026259414866, -8.182753633212313],
              [-38.542974165157403, -8.182740187345724],
              [-38.542944125428313, -8.182731831751658],
              [-38.542910546293086, -8.182722477175821],
              [-38.542849202167226, -8.182700246953903],
              [-38.542840127567985, -8.182696980402842],
              [-38.542764424666501, -8.182687939960365],
              [-38.542692340511593, -8.182689938782675],
              [-38.542618072405041, -8.182696457735627],
              [-38.542541437540095, -8.182708582038845],
              [-38.542479329722298, -8.182719185088132],
              [-38.542422490727184, -8.182726809168258],
              [-38.542370407333749, -8.182703865744283],
              [-38.542307896895622, -8.182669513510866],
              [-38.542268887623187, -8.182645409004385],
              [-38.542227604442864, -8.182625100915246],
              [-38.542183698365584, -8.182596196880411],
              [-38.542131805553176, -8.182565384291976],
              [-38.542081993324359, -8.182541086621699],
              [-38.542035893602659, -8.182525205218466],
              [-38.541971444863115, -8.182518800502834],
              [-38.541921053037349, -8.182525075037308],
              [-38.541857937428574, -8.182543727104738],
              [-38.541794088730249, -8.182568348182992],
              [-38.541737033046694, -8.182606725706012],
              [-38.541680800911536, -8.182639405659238],
              [-38.541631653362558, -8.182669018262894],
              [-38.54158884966489, -8.182708226002086],
              [-38.541545950316618, -8.182751684886023],
              [-38.541490614086499, -8.182794767842026],
              [-38.541430919737849, -8.182838298104924],
              [-38.541370130704088, -8.182886440183021],
              [-38.541315353927935, -8.182916589068716],
              [-38.541273115148712, -8.182938068761338],
              [-38.541223414351734, -8.182975097771731],
              [-38.541185972168975, -8.183009608032192],
              [-38.541147424852795, -8.1830578657831],
              [-38.541118132857697, -8.183110113942943],
              [-38.541088668913403, -8.183153949841168],
              [-38.541045166378318, -8.183169096411531],
              [-38.540995070517056, -8.183154657585501],
              [-38.540941097175491, -8.183117329917048],
              [-38.540892550954851, -8.183097375249607],
              [-38.540883204636941, -8.183093565632486],
              [-38.540827093285657, -8.183099923978261],
              [-38.54077533196498, -8.183112890277968],
              [-38.5407261977603, -8.183130743981131],
              [-38.540682386818503, -8.183177367558894],
              [-38.540672695224373, -8.183237235957808],
              [-38.540665924435707, -8.183283358928676],
              [-38.540674128836628, -8.183333026564455],
              [-38.540695315759514, -8.1833822566982],
              [-38.540715236797432, -8.183427053229703],
              [-38.540730344048406, -8.183473834238489],
              [-38.540697882425647, -8.183519204384895],
              [-38.540632410908799, -8.183533783216921],
              [-38.540569205757322, -8.183551168636388],
              [-38.540504723209871, -8.183574160634624],
              [-38.540447305167952, -8.183611633024192],
              [-38.540380618305164, -8.18365687375662],
              [-38.540313655967438, -8.183704827732269],
              [-38.54026213050976, -8.183749542980982],
              [-38.540226772560111, -8.183787221344618],
              [-38.540209196624971, -8.183834960146951],
              [-38.540220018138072, -8.183898198609242],
              [-38.540234745412413, -8.183959632470735],
              [-38.540250480433052, -8.184013107677732],
              [-38.540263942140008, -8.184069836579241],
              [-38.540250908060756, -8.184115228791732],
              [-38.540247545756877, -8.184118119438203],
              [-38.540215831344767, -8.184145128575741],
              [-38.540162424465535, -8.184167952213626],
              [-38.540124807981293, -8.18419594960015],
              [-38.540087640740929, -8.184228017846904],
              [-38.540068434711472, -8.184272227145355],
              [-38.540078536789295, -8.184329494939957],
              [-38.54011380569861, -8.184369515000773],
              [-38.540167254124853, -8.18438965625166],
              [-38.540226084085155, -8.184387733264325],
              [-38.540291657425229, -8.184363657133401],
              [-38.540362860990342, -8.184338140161044],
              [-38.540434435766045, -8.184305568319777],
              [-38.540496838502015, -8.184275518696197],
              [-38.540569229478521, -8.184243671376478],
              [-38.540637886512336, -8.184222131357863],
              [-38.5406976199327, -8.184224008323788],
              [-38.540760434707849, -8.184230501850598],
              [-38.540812523576754, -8.184248741973315],
              [-38.540871167705703, -8.184250527223396],
              [-38.540891232190404, -8.184249374147463],
              [-38.540932813694532, -8.184247069643364],
              [-38.540980448181251, -8.184270550915874],
              [-38.541018420599492, -8.184328121718854],
              [-38.541046336353808, -8.184367952444024],
              [-38.541074969893103, -8.184415201070834],
              [-38.541098528258743, -8.184454845937838],
              [-38.541144594103542, -8.184500486222101],
              [-38.541197306092947, -8.184529581280705],
              [-38.541256016941517, -8.1845524419372],
              [-38.54131965069557, -8.184557308169014],
              [-38.541388311061041, -8.184532873554215],
              [-38.541456887461536, -8.184502468980487],
              [-38.541522479062301, -8.184462382611107],
              [-38.541589786747572, -8.184429986622993],
              [-38.54163328971493, -8.184414568644849],
              [-38.541718926962517, -8.184389791425239],
              [-38.54180781793653, -8.184377681199525],
              [-38.541858843725237, -8.184372945100501],
              [-38.541943743423133, -8.184358026296453],
              [-38.542030355915699, -8.18435369233546],
              [-38.542111980996907, -8.184344377825838],
              [-38.542184727159871, -8.184319404988285],
              [-38.542243792476285, -8.184270356321132],
              [-38.542300412200184, -8.184216420449072],
              [-38.5423387154365, -8.184143378380572],
              [-38.542380099720631, -8.184075224222259],
              [-38.542402975014404, -8.183997551601028],
              [-38.542416119861109, -8.183934430778166],
              [-38.542433702251962, -8.183880812473195],
              [-38.542479314612201, -8.183846582707039],
              [-38.542547441236593, -8.183812378429774],
              [-38.542615764168225, -8.183765239681856],
              [-38.542695341535989, -8.18372046542143],
              [-38.542742027065202, -8.183700437851371],
              [-38.542784809216293, -8.183680134501099],
              [-38.542822785518162, -8.183654850830177],
              [-38.542899916538389, -8.183605822499512],
              [-38.542974412144524, -8.18355914293187],
              [-38.543047985884996, -8.183524763822206],
              [-38.543116912663713, -8.183505304076089],
              [-38.543188799843968, -8.183516963263678],
              [-38.543242872487561, -8.183547054571852],
              [-38.543284139130499, -8.183582015821685],
              [-38.543335648022712, -8.183631280112232],
              [-38.543340182348196, -8.183635536496851],
              [-38.543383349073807, -8.183676107929163],
              [-38.543412640167588, -8.183704181254797],
              [-38.543424519913302, -8.183715501221419],
              [-38.543454690632785, -8.183768359474863],
              [-38.543466893666753, -8.183814684854832],
              [-38.543480715874139, -8.183874127651016],
              [-38.543474942875655, -8.183921246787497],
              [-38.543461356015854, -8.183974055589543],
              [-38.543454113281946, -8.1840362786021],
              [-38.543461395688198, -8.184098698934319],
              [-38.543482033868308, -8.184151817770461],
              [-38.543509678338822, -8.184190924413105],
              [-38.543522600449883, -8.184243582266536],
              [-38.543541682250073, -8.18430818678627],
              [-38.54354986612433, -8.184376216179613],
              [-38.543547060151077, -8.184448665318557],
              [-38.543525558741372, -8.184515847096783],
              [-38.543541367608952, -8.184584608724634],
              [-38.543571985732072, -8.18464325642559],
              [-38.543612249261315, -8.18468228686862],
              [-38.543651979008317, -8.184711728758884],
              [-38.543702441397002, -8.184723454114343],
              [-38.543772085366143, -8.184712135786146],
              [-38.54381901090413, -8.184720600848893],
              [-38.543861624035891, -8.184769131416113],
              [-38.543889626985738, -8.184812399247559],
              [-38.543922787838078, -8.184870416629783],
              [-38.543939782733382, -8.184934114254308],
              [-38.543956971956931, -8.184986596009347],
              [-38.543986520927888, -8.185027513820017],
              [-38.544040606074269, -8.185046841220853],
              [-38.544101073129163, -8.185042386856226],
              [-38.544151396167543, -8.185017026547348],
              [-38.544195625591442, -8.185001428206958],
              [-38.54424026595661, -8.185023639398048],
              [-38.54426040964654, -8.185032707358769],
              [-38.544313072799532, -8.185025168778028],
              [-38.544401345135014, -8.184998313557667],
              [-38.544453649565916, -8.184986885103401],
              [-38.544502770504558, -8.184980789739813],
              [-38.544567494927875, -8.184984661719886],
              [-38.544627137317761, -8.184986899818435],
              [-38.544684154275238, -8.18498271283315],
              [-38.544732181494396, -8.184980415206576],
              [-38.544777576706387, -8.18497748144174],
              [-38.544829884412088, -8.184963158466955],
              [-38.544876351162188, -8.184975964604615],
              [-38.544913342231354, -8.185018880689162],
              [-38.544941506351897, -8.185080239102851],
              [-38.544968489676876, -8.185142138898685],
              [-38.545001187764115, -8.185208115485564],
              [-38.545028712216158, -8.185273181719227],
              [-38.545049074716935, -8.185329466007882],
              [-38.545069613146133, -8.185390725372258],
              [-38.545080261693293, -8.185447089170815],
              [-38.5451047155331, -8.185498041377322],
              [-38.545122266425246, -8.185551970735492],
              [-38.545130544034926, -8.185617648453295],
              [-38.545158153980999, -8.185687418297176],
              [-38.545192488813775, -8.185751135405662],
              [-38.545221297025797, -8.185804986984097],
              [-38.545253926682015, -8.185851335319574],
              [-38.545278013737807, -8.185905543388193],
              [-38.545291292697222, -8.185963990547297],
              [-38.545323923502103, -8.186009343903466],
              [-38.545338985318935, -8.186016687498542],
              [-38.545380631944553, -8.186037176607343],
              [-38.545431178370741, -8.186055052611158],
              [-38.545446514696643, -8.18606049701226],
              [-38.54551920983856, -8.18608065924102],
              [-38.545603263433776, -8.186091608097886],
              [-38.545679623156339, -8.186082467863338],
              [-38.545748814329457, -8.186070334624283],
              [-38.545799675655374, -8.186050673215563],
              [-38.545842824443639, -8.186027385033896],
              [-38.545899140067355, -8.186001307628974],
              [-38.545953307628238, -8.186028232874198],
              [-38.546008097676385, -8.186066646259972],
              [-38.546078923871868, -8.186053700730509],
              [-38.546118460950076, -8.186013041608971],
              [-38.54615699868075, -8.185973014526555],
              [-38.546187087871914, -8.185937952826135],
              [-38.546217370296063, -8.185892579772691],
              [-38.546255189411561, -8.185845767946009],
              [-38.546278020789266, -8.185806356371254],
              [-38.546285612656902, -8.185756163846241],
              [-38.546300098975792, -8.185710501682944],
              [-38.546333187172905, -8.185672639315468],
              [-38.546372344166457, -8.185646904375847],
              [-38.546370475070752, -8.185694118403338],
              [-38.546407154109701, -8.185772310427744],
              [-38.546430792210963, -8.185822266671293],
              [-38.546469955894409, -8.185870883562483],
              [-38.546521756757002, -8.185903414120931],
              [-38.546565486966664, -8.185927885353163],
              [-38.546607944979961, -8.185953440581001],
              [-38.546694778120944, -8.185995146131079],
              [-38.546749419701811, -8.186004343143143],
              [-38.546711076067979, -8.186033335315917],
              [-38.546662362300466, -8.186080677672164],
              [-38.546650945272667, -8.186141720194104],
              [-38.546651434643437, -8.186190655465426],
              [-38.546683557023641, -8.1862040784697],
              [-38.546726280471432, -8.186235784744593],
              [-38.546670719463386, -8.186236807811445],
              [-38.546578478613384, -8.1863208249494],
              [-38.546527417710926, -8.186356405845489],
              [-38.546479443455148, -8.186391990200731],
              [-38.546433651690812, -8.186424320716394],
              [-38.546393126824107, -8.186455390809204],
              [-38.546356139457018, -8.186489178441624],
              [-38.546313411399922, -8.18654177370917],
              [-38.546279137916379, -8.18658406690273],
              [-38.546256967571921, -8.186600866130311],
              [-38.5462233486085, -8.186626335917824],
              [-38.546175378492194, -8.18665821169194],
              [-38.546168746137262, -8.186662726855968],
              [-38.546121230444726, -8.186694150872016],
              [-38.546107466240258, -8.186743431928925],
              [-38.546127924760697, -8.186795284114359],
              [-38.546158731033671, -8.186848595165495],
              [-38.546183006798216, -8.186896652640021],
              [-38.546210725197362, -8.18695104564573],
              [-38.546239834646855, -8.186979570842897],
              [-38.546198883944726, -8.186985766039026],
              [-38.546099990954339, -8.187011343362601],
              [-38.546029081486566, -8.187017504867006],
              [-38.545964423930954, -8.187034437216434],
              [-38.545903219753413, -8.187048207606644],
              [-38.545838850629295, -8.187050939242695],
              [-38.545766939657867, -8.187059632239585],
              [-38.545700833435667, -8.187073125727574],
              [-38.545630557803207, -8.187080735111492],
              [-38.545558922765117, -8.187086262549606],
              [-38.545494454339646, -8.187096501562191],
              [-38.545422809143943, -8.187110983745344],
              [-38.545362344446659, -8.187113086533493],
              [-38.545303143955877, -8.187121160589866],
              [-38.54526153763041, -8.187145083607884],
              [-38.545223090890495, -8.187184748884841],
              [-38.545167482960544, -8.187227017946643],
              [-38.545116413098476, -8.187270287087562],
              [-38.545074140565646, -8.187321254580029],
              [-38.545034939901612, -8.18738525066331],
              [-38.544987289927697, -8.187454845265519],
              [-38.544962277141487, -8.187496334697704],
              [-38.544937899120782, -8.187538458009527],
              [-38.544929845006394, -8.187595795706613],
              [-38.544921332860504, -8.18765675098],
              [-38.544899757943519, -8.187708736750706],
              [-38.544878991555116, -8.187768230974015],
              [-38.544857227559959, -8.187826819547956],
              [-38.544840278104786, -8.187882790429994],
              [-38.544833036917325, -8.187943747132257],
              [-38.544832422367165, -8.188005254017927],
              [-38.544826193053254, -8.188054543510859],
              [-38.544814249459037, -8.188099303945625],
              [-38.544807470195913, -8.188153205886124],
              [-38.544799870686766, -8.188209910923435],
              [-38.544803159446829, -8.18827178401774],
              [-38.544790027940586, -8.188323327035596],
              [-38.544770173966413, -8.188378751923153],
              [-38.54474659998229, -8.188432273116286],
              [-38.544727118688819, -8.18847928635553],
              [-38.54473231832894, -8.18853727215402],
              [-38.544760308877564, -8.188591756009707],
              [-38.544795823347634, -8.188656107649033],
              [-38.544835177382204, -8.188697036463847],
              [-38.54487732972121, -8.188752078991193],
              [-38.544913036884559, -8.188806661988226],
              [-38.544950835798645, -8.188857991056608],
              [-38.544995908472892, -8.188899649921147],
              [-38.545040619763874, -8.188939770684332],
              [-38.54507562656201, -8.188971558894853],
              [-38.545111907942704, -8.189000182707488],
              [-38.545186657042855, -8.189050829767147],
              [-38.545236370348888, -8.189083177245491],
              [-38.545279010675181, -8.189108280566268],
              [-38.54530395283426, -8.189129112691694],
              [-38.545291096276763, -8.189178394727239],
              [-38.545305320110025, -8.189204280110843],
              [-38.54535566647445, -8.189238799144533],
              [-38.54536979686155, -8.189267036181306],
              [-38.545336669298287, -8.189259219975668],
              [-38.545297366329812, -8.189253205858714],
              [-38.545235194978687, -8.189238934818071],
              [-38.545173846134539, -8.189219870721768],
              [-38.545114223333506, -8.189199813585642],
              [-38.545050336024204, -8.189177671232217],
              [-38.544993182596883, -8.189141606801229],
              [-38.544948831244142, -8.189104290459751],
              [-38.544902575272488, -8.189065343825101],
              [-38.544847316640585, -8.189039593074824],
              [-38.544815958702344, -8.188992432065319],
              [-38.544770975281473, -8.188952130066719],
              [-38.544713068304397, -8.188940125065038],
              [-38.544650965614203, -8.18894539171535],
              [-38.544617629187776, -8.18896163553284],
              [-38.544583744865243, -8.188980682751099],
              [-38.544534196031456, -8.18896344091759],
              [-38.544476576630466, -8.18893804926439],
              [-38.544422630153697, -8.188955988405366],
              [-38.5443309828906, -8.188996045806705],
              [-38.544275453362438, -8.189048897732043],
              [-38.544208885236763, -8.189069083903588],
              [-38.544150686774884, -8.18907390262153],
              [-38.544114323319242, -8.189037680639467],
              [-38.544078595361995, -8.189001459372021],
              [-38.54407658027025, -8.188937326410359],
              [-38.544081086229973, -8.188886768658497],
              [-38.544036317808278, -8.188817160277535],
              [-38.543974814180928, -8.188774668682763],
              [-38.543908916371272, -8.188764282684277],
              [-38.543863468022074, -8.188813527848131],
              [-38.543815996664122, -8.188885383796597],
              [-38.543806213019543, -8.188946608952286],
              [-38.543775374255311, -8.189001750011167],
              [-38.543708175053212, -8.189018045937127],
              [-38.543657762233899, -8.18904204927126],
              [-38.54360597816418, -8.189074372660336],
              [-38.543565812965589, -8.189108065962531],
              [-38.543530543327897, -8.18914773464722],
              [-38.543506076945569, -8.189187505995966],
              [-38.543481063687004, -8.189229176222845],
              [-38.543435421915774, -8.189288732674976],
              [-38.543412397866831, -8.189337460425463],
              [-38.543405349020958, -8.189388738906361],
              [-38.543389004791237, -8.18947103201161],
              [-38.543351743612249, -8.189505633096235],
              [-38.543297418827578, -8.189536777686724],
              [-38.543257610612706, -8.189575808042868],
              [-38.543233852927187, -8.189630957063288],
              [-38.543268988007242, -8.189709418954132],
              [-38.543349673341979, -8.1897290479255],
              [-38.543376412882999, -8.189765892255474],
              [-38.543415031727122, -8.189814599270454],
              [-38.54341911518474, -8.189816232030156],
              [-38.543461038149132, -8.189833465378907],
              [-38.543456912957332, -8.189868465752395],
              [-38.54347666971691, -8.18989860870969],
              [-38.543483376144344, -8.189908837403152],
              [-38.543552506420632, -8.189950794986608],
              [-38.543629381847559, -8.18996779648068],
              [-38.543712839821609, -8.189944373223105],
              [-38.543756904309546, -8.189914664219645],
              [-38.54374422992737, -8.189963222779918],
              [-38.543759973853327, -8.190009461698448],
              [-38.543801204314555, -8.190076804804894],
              [-38.543856899225588, -8.190118113989707],
              [-38.543908990037231, -8.190135449235807],
              [-38.543959810586969, -8.190152149874303],
              [-38.544007427529486, -8.190191821772421],
              [-38.544067221174657, -8.19022137676064],
              [-38.544130013403915, -8.190248583366863],
              [-38.544189524938652, -8.190286730987989],
              [-38.544233959924284, -8.19033037916963],
              [-38.544287658370131, -8.19037105287857],
              [-38.544346899435446, -8.190407572031308],
              [-38.544399681621698, -8.190455661781042],
              [-38.544448566651454, -8.190497958178943],
              [-38.544507445639425, -8.190533572377996],
              [-38.544570864706088, -8.190568468072792],
              [-38.54463265212263, -8.190601191062152],
              [-38.544694707896127, -8.190637441985247],
              [-38.544757140385698, -8.190661753619464],
              [-38.54481858329094, -8.190678104325377],
              [-38.544900530859962, -8.19070569422693],
              [-38.544961972649006, -8.190723039885762],
              [-38.544900493465619, -8.190738618825536],
              [-38.544856455571811, -8.190744900834336],
              [-38.544802889264091, -8.190747644453172],
              [-38.54474142517892, -8.19074992689594],
              [-38.544692934708408, -8.190759821957091],
              [-38.544638899363669, -8.190775861514016],
              [-38.544580332888501, -8.190784750219079],
              [-38.544524415831489, -8.190778988631671],
              [-38.544440812964552, -8.190770029960881],
              [-38.544391612970621, -8.190765180459149],
              [-38.544329250909051, -8.190758778404248],
              [-38.544260082550828, -8.19075010734776],
              [-38.544197639175792, -8.190735383569288],
              [-38.544133641965594, -8.190729884161144],
              [-38.54409133340458, -8.190732278589998],
              [-38.544064517986129, -8.190762007108962],
              [-38.54403297989338, -8.190793358432462],
              [-38.543951173693898, -8.190801135365351],
              [-38.543863020061877, -8.190802302091102],
              [-38.543795525236582, -8.190838768542035],
              [-38.543731592436679, -8.190856424930002],
              [-38.543684576023004, -8.19084741700452],
              [-38.543605502939869, -8.1908467848929],
              [-38.543529890299681, -8.190836840092812],
              [-38.543472330781853, -8.190838403138372],
              [-38.543425683701592, -8.190823968463437],
              [-38.543363314335927, -8.190823988364034],
              [-38.543292313492934, -8.19083005879251],
              [-38.543215402140071, -8.190844444130404],
              [-38.543139854722583, -8.190856931499058],
              [-38.543052127469203, -8.190882249290024],
              [-38.54297693337999, -8.190903329994565],
              [-38.542901724631228, -8.19093725489256],
              [-38.542829716668329, -8.190950741184908],
              [-38.542759906223118, -8.190947858090233],
              [-38.542678920866038, -8.190952288957591],
              [-38.542625861039511, -8.190988500203037],
              [-38.542599833962768, -8.191043284792524],
              [-38.542612759840786, -8.191092686384893],
              [-38.542685790357339, -8.191137814369927],
              [-38.542734351979171, -8.191145196021616],
              [-38.542804510529606, -8.19116128555002],
              [-38.542859844914645, -8.191200333157381],
              [-38.542914888897904, -8.191255209578349],
              [-38.54295216512925, -8.191287181445825],
              [-38.543010415913592, -8.191316373037404],
              [-38.543052229864465, -8.191349706818094],
              [-38.543089320419732, -8.191385296555044],
              [-38.543145930187372, -8.191420546573632],
              [-38.543189644047509, -8.191459671437967],
              [-38.543231541492304, -8.191499427408296],
              [-38.543262750636465, -8.191517824540137],
              [-38.543289786555327, -8.191533684283579],
              [-38.543350582993384, -8.191559712879423],
              [-38.543414208106533, -8.191573171798415],
              [-38.543484370475056, -8.191586004935736],
              [-38.543553528029449, -8.191604264062176],
              [-38.543619419489531, -8.191620619985787],
              [-38.543669590909275, -8.191649440542058],
              [-38.543704413053135, -8.191684032686419],
              [-38.543746500487941, -8.191716462186028],
              [-38.543796761993526, -8.191745915995361],
              [-38.543846949404802, -8.19176071644366],
              [-38.543896416511046, -8.191770269838969],
              [-38.54398164299316, -8.19178863750561],
              [-38.544022496323763, -8.191788683662327],
              [-38.543995232973543, -8.191813074979612],
              [-38.543972126280146, -8.191854566474968],
              [-38.543963132241494, -8.19193985282501],
              [-38.543958720600159, -8.191987244841645],
              [-38.543951398990728, -8.192038884827525],
              [-38.543948516486424, -8.192098760987855],
              [-38.544006818100172, -8.192163228896302],
              [-38.544083998133054, -8.192152280911319],
              [-38.544156179518467, -8.192146121237032],
              [-38.544205100426076, -8.192157030764735],
              [-38.544162859137082, -8.192179957939587],
              [-38.544139748732015, -8.192224705722046],
              [-38.544149211714846, -8.192285771708988],
              [-38.544163638045418, -8.192373074467005],
              [-38.544172295390112, -8.19242409933884],
              [-38.544222604786, -8.192491362213492],
              [-38.544311021374959, -8.192498788694094],
              [-38.544362765111927, -8.192502284304235],
              [-38.544420672378948, -8.192514470286037],
              [-38.544479867409301, -8.192511913988936],
              [-38.544532259708468, -8.192503922867742],
              [-38.544611653194266, -8.1924625853105],
              [-38.544636662138615, -8.192424804468722],
              [-38.544678382156995, -8.192381253532004],
              [-38.544725057942152, -8.192370632818404],
              [-38.544776125543493, -8.192329986889165],
              [-38.544765714805031, -8.192224055501256],
              [-38.544750784569118, -8.192180621555227],
              [-38.544790256123747, -8.192198304276634],
              [-38.544828433873676, -8.192236065946226],
              [-38.544905945298403, -8.192253067938985],
              [-38.544929737662024, -8.192167345983695],
              [-38.544927049550395, -8.192136136907848],
              [-38.544952897154062, -8.192159231389573],
              [-38.544970558778033, -8.192195794033735],
              [-38.54500917660409, -8.192245767244371],
              [-38.545059007254522, -8.192255049554563],
              [-38.5450821554615, -8.192176924856104],
              [-38.54510807692084, -8.192134984203703],
              [-38.545116109156602, -8.192176963126954],
              [-38.545137866815082, -8.192204123341387],
              [-38.545152821741077, -8.192225848749679],
              [-38.54517457170293, -8.192259792876692],
              [-38.545214102034997, -8.192305696743867],
              [-38.54529022139738, -8.192269420686523],
              [-38.545345337315929, -8.19226134207344],
              [-38.545316064411843, -8.192296585495088],
              [-38.545299596441581, -8.192328134796835],
              [-38.545283561451399, -8.192378136856229],
              [-38.545266609568444, -8.192436097684466],
              [-38.545264737084182, -8.192486115700113],
              [-38.545309146245273, -8.192552829087095],
              [-38.545364471634237, -8.192600197973933],
              [-38.54543914735946, -8.192636101206746],
              [-38.545486483147364, -8.192683913340222],
              [-38.545517031752127, -8.192724560831207],
              [-38.545549209677837, -8.192769461413331],
              [-38.545581126540824, -8.1928044119453],
              [-38.545611043256976, -8.192841892887518],
              [-38.545645502841708, -8.192876213111724],
              [-38.545679330627181, -8.192907276338831],
              [-38.545723693531805, -8.192934823782775],
              [-38.545769976681065, -8.192950252773988],
              [-38.545866208351477, -8.192951175150711],
              [-38.545914065723885, -8.192939379751373],
              [-38.545968096915281, -8.192927229483109],
              [-38.546021122595761, -8.192921138381301],
              [-38.546115002859743, -8.192914098249428],
              [-38.546165434289072, -8.192873994139985],
              [-38.546227257024093, -8.19279582240736],
              [-38.546261249196064, -8.192761941009005],
              [-38.546254380354348, -8.192814847891468],
              [-38.546257478444147, -8.192884951920336],
              [-38.546268775503322, -8.192930100328256],
              [-38.546278413326107, -8.19299740768796],
              [-38.546275969406366, -8.193071123577768],
              [-38.546267332091425, -8.193162470705158],
              [-38.546289619329414, -8.193203108853755],
              [-38.54631826002278, -8.193245110929025],
              [-38.546349816410569, -8.193277799691808],
              [-38.546410666424919, -8.193337114361993],
              [-38.546456947499934, -8.193354442774284],
              [-38.546546302408686, -8.193335186398041],
              [-38.546598651099764, -8.193285586896764],
              [-38.546654553926508, -8.193224051674493],
              [-38.546669951139933, -8.193175948337693],
              [-38.546697874089411, -8.193130210830789],
              [-38.54672352422179, -8.193087274790514],
              [-38.546730941303785, -8.193031202682318],
              [-38.546756885171604, -8.19296936243385],
              [-38.546810870586818, -8.192917412978062],
              [-38.546862144804507, -8.192854515738629],
              [-38.54689414896113, -8.192812400890668],
              [-38.546915812778167, -8.192762133721214],
              [-38.546941666631326, -8.192699569737883],
              [-38.546979860438377, -8.192643079910564],
              [-38.546975754125938, -8.192661618027879],
              [-38.546965335951704, -8.192722299843446],
              [-38.546962829609846, -8.192771050833359],
              [-38.546952602923469, -8.192822958987419],
              [-38.546933072332344, -8.192913570290564],
              [-38.546927014813107, -8.192971633882001],
              [-38.546934805938136, -8.193066436664976],
              [-38.546941282341535, -8.193119629902592],
              [-38.546946214266953, -8.193173816381577],
              [-38.546955591820485, -8.193230540516629],
              [-38.546965247154702, -8.193282470990125],
              [-38.54701037949701, -8.193352260398004],
              [-38.547068304542698, -8.193429209894289],
              [-38.54713970944357, -8.193467641821137],
              [-38.547203758781627, -8.193507784076496],
              [-38.547267398062729, -8.193509121814389],
              [-38.547278019672426, -8.193509405088172],
              [-38.547314050008794, -8.193519666622922],
              [-38.5473165898593, -8.193521568971027],
              [-38.547358139722228, -8.193548118112743],
              [-38.547359590656761, -8.19354956697755],
              [-38.54739677167521, -8.193585970518509],
              [-38.547465856371637, -8.193588852023186],
              [-38.547509630270518, -8.19357542371508],
              [-38.547554954469767, -8.193555846381869],
              [-38.547642339476603, -8.193512346329385],
              [-38.547719192313536, -8.193469738975535],
              [-38.547771594092424, -8.1934534258334],
              [-38.54782682962103, -8.193419929916343],
              [-38.547862265101664, -8.193394190707115],
              [-38.547862228346702, -8.193426753506076],
              [-38.547855837518028, -8.193458495114282],
              [-38.547823387541364, -8.193493463805977],
              [-38.547779092436699, -8.193566499651869],
              [-38.547730550305388, -8.193621892527142],
              [-38.547700351560913, -8.193673145150116],
              [-38.547709813479003, -8.193735658316225],
              [-38.547744036298852, -8.193819093723528],
              [-38.547779402889411, -8.19385440977023],
              [-38.547734628690186, -8.193869284222394],
              [-38.547695183164031, -8.193908677223847],
              [-38.547652459129203, -8.193957111778431],
              [-38.547629987247852, -8.193999418275157],
              [-38.547642962017484, -8.194086538413208],
              [-38.547685448703085, -8.194167902685889],
              [-38.547737954014252, -8.19422078570115],
              [-38.547821830372108, -8.194229472679242],
              [-38.547895409226143, -8.194192017413425],
              [-38.547969106755531, -8.194210280902524],
              [-38.548023786275095, -8.194267327125827],
              [-38.548096478956126, -8.194291107059234],
              [-38.54815660272628, -8.194270279904572],
              [-38.548165931957705, -8.194209054121968],
              [-38.548195431848924, -8.194133469004088],
              [-38.548253932668857, -8.194102690259058],
              [-38.548284677522815, -8.194050171872414],
              [-38.548329315237744, -8.193995317273806],
              [-38.548360802983431, -8.19392805598069],
              [-38.548382314081209, -8.193852100093862],
              [-38.548402747571508, -8.193765650527761],
              [-38.548387813826089, -8.19372493026173],
              [-38.548427291827664, -8.193737185509567],
              [-38.548466411583412, -8.193745098640495],
              [-38.54852202268151, -8.193700567862541],
              [-38.548528980198007, -8.193649289170002],
              [-38.548524040612755, -8.193601705712169],
              [-38.548552610566801, -8.193626159808773],
              [-38.548585633404961, -8.19364672943434],
              [-38.548659590490672, -8.193676299627258],
              [-38.548728352944707, -8.19364290916708],
              [-38.54873307503167, -8.193561235982038],
              [-38.548763281586815, -8.193502927999971],
              [-38.548794107737727, -8.193458731273592],
              [-38.548821713279757, -8.193372199245834],
              [-38.548814844036798, -8.193345146314146],
              [-38.54879989650761, -8.19331663711233],
              [-38.548838018813136, -8.193323463662177],
              [-38.548875685460459, -8.193331827388956],
              [-38.548918668195221, -8.193295332690894],
              [-38.548958568592113, -8.193254945091656],
              [-38.549012389010059, -8.193188070506936],
              [-38.549042103076104, -8.193163952936773],
              [-38.549036805743292, -8.193192168149748],
              [-38.549031380761271, -8.193253126969998],
              [-38.549069401588994, -8.193269450881107],
              [-38.549032711995579, -8.19328026416224],
              [-38.548993643847624, -8.193307175299427],
              [-38.548958013630724, -8.193344673199809],
              [-38.548910288753696, -8.193400067141219],
              [-38.548890619166073, -8.193453231132121],
              [-38.548901791526774, -8.193528771330536],
              [-38.548937763041465, -8.193591314112663],
              [-38.548983927106562, -8.193632069269185],
              [-38.549031566665001, -8.193652474291827],
              [-38.548984059893215, -8.193675396091344],
              [-38.548917183171589, -8.193727240982222],
              [-38.548884464162668, -8.193759314986806],
              [-38.5488526526998, -8.1937916613601],
              [-38.548830543788952, -8.19383423969408],
              [-38.548827038710591, -8.19388326094502],
              [-38.548850231460456, -8.193926251740123],
              [-38.548911358289075, -8.193982038724302],
              [-38.548970936378723, -8.194042617943085],
              [-38.54903042522826, -8.194101840268912],
              [-38.549072612096793, -8.194127214074022],
              [-38.549140806059874, -8.194114988760218],
              [-38.54921379894008, -8.194114075339371],
              [-38.549271982237769, -8.194123366477561],
              [-38.549345255823084, -8.194115036254138],
              [-38.549400543443681, -8.194115912071339],
              [-38.549459015587523, -8.194110550224709],
              [-38.549418128511924, -8.194140263390928],
              [-38.549371349818472, -8.194161557904334],
              [-38.54929232934051, -8.194194213394221],
              [-38.549250816719798, -8.194215061513514],
              [-38.549172783667061, -8.194257667839905],
              [-38.549104635152588, -8.194310144501943],
              [-38.549088573143237, -8.19438447837914],
              [-38.549110858496995, -8.194427106337745],
              [-38.549138503478865, -8.194466845809405],
              [-38.549198720737195, -8.194524259880941],
              [-38.549242812127694, -8.194551444845436],
              [-38.549291994658738, -8.194572303828519],
              [-38.549340907381982, -8.194590901195813],
              [-38.549393371953911, -8.194599552766059],
              [-38.549455733919942, -8.194606677692068],
              [-38.549516190198958, -8.194613076861932],
              [-38.549575379038288, -8.194616308775732],
              [-38.549634842779554, -8.194617279679038],
              [-38.54969212298198, -8.194622408944641],
              [-38.549753851695186, -8.194627543166478],
              [-38.549811683835678, -8.194626250914251],
              [-38.549864804767338, -8.194616360424144],
              [-38.549926454996566, -8.194610549797357],
              [-38.549999449395962, -8.194608369907863],
              [-38.550066711378882, -8.194617671061641],
              [-38.550122977501857, -8.194636547893516],
              [-38.550173154066592, -8.194661568688787],
              [-38.550216970544788, -8.194691105005758],
              [-38.550262910858912, -8.194688804456153],
              [-38.550305695196748, -8.194667324483918],
              [-38.550362831215509, -8.194558302618322],
              [-38.550390011227243, -8.194527126843631],
              [-38.550394052422092, -8.194566478122972],
              [-38.55042776761006, -8.194617440370134],
              [-38.550496095762306, -8.194566591821534],
              [-38.550530166356168, -8.194543564425983],
              [-38.550512327149185, -8.194583795852946],
              [-38.550491211625939, -8.194631169365119],
              [-38.550476720119072, -8.194681535183861],
              [-38.550471942093324, -8.194732454538563],
              [-38.55047878679828, -8.194781487343249],
              [-38.550540284037723, -8.194831123749532],
              [-38.550587223216553, -8.194828643371814],
              [-38.550675637773629, -8.194838510702148],
              [-38.550742832672078, -8.194826736270938],
              [-38.550795192643818, -8.194766824654733],
              [-38.550824374899854, -8.194731218913445],
              [-38.55085555728126, -8.194693082730113],
              [-38.550871588146357, -8.19464624625105],
              [-38.550900237335121, -8.194600237887872],
              [-38.550902903200935, -8.194651708250733],
              [-38.550905585700974, -8.194688344444225],
              [-38.550900641270211, -8.194725786219395],
              [-38.550890412894901, -8.194779593990493],
              [-38.55091194986413, -8.194842120547113],
              [-38.550982698086926, -8.194819043485227],
              [-38.551049836055711, -8.194777057848729],
              [-38.551116415609002, -8.194747282648512],
              [-38.551172043366073, -8.194768781745939],
              [-38.551243821704361, -8.194798620417915],
              [-38.551306236534224, -8.194839664758177],
              [-38.55134904991602, -8.19487327019846],
              [-38.551395041422246, -8.194906336454041],
              [-38.55144584216216, -8.194941578908463],
              [-38.551502200609434, -8.194959189322972],
              [-38.551558124071803, -8.194959884653006],
              [-38.551609244890194, -8.194952524376685],
              [-38.551652926723861, -8.194940271398966],
              [-38.551790082810236, -8.194878554369852],
              [-38.551832181295445, -8.194820892520836],
              [-38.551852644574453, -8.194788352396136],
              [-38.551856688951439, -8.194824990100425],
              [-38.55186090629784, -8.194869316448647],
              [-38.551861009429821, -8.194939417153702],
              [-38.551873412699074, -8.194970184737954],
              [-38.551896996412964, -8.19498866321692],
              [-38.551958900324721, -8.194999586252344],
              [-38.552016375759322, -8.194992594790715],
              [-38.552063862202559, -8.194987853539979],
              [-38.55212636839272, -8.194947309815744],
              [-38.5521818011524, -8.194899702944435],
              [-38.552246465753541, -8.19487743297644],
              [-38.552308489667901, -8.194862215353217],
              [-38.552375859537854, -8.194856320246602],
              [-38.552438664533739, -8.194873304517458],
              [-38.552497288730784, -8.194894444951212],
              [-38.552565276220513, -8.19490456057002],
              [-38.552645432675071, -8.194911433385011],
              [-38.552722674283508, -8.194926986381697],
              [-38.552793465850264, -8.194946331214627],
              [-38.552871717227148, -8.194951935540175],
              [-38.552940965141779, -8.194971549994237],
              [-38.553006314918477, -8.194986094784213],
              [-38.553065682513534, -8.194991949507589],
              [-38.553131512369383, -8.194983067640587],
              [-38.553196351750586, -8.194966858022109],
              [-38.553257735204731, -8.194956071709717],
              [-38.553318499497202, -8.194930631414785],
              [-38.553377983599148, -8.194913420860837],
              [-38.553438375152979, -8.194896573111835],
              [-38.553507761272407, -8.194873674997135],
              [-38.5535433759025, -8.194849925429212],
              [-38.553528897671121, -8.194888713485097],
              [-38.553515339800953, -8.194916286461844],
              [-38.553495684240147, -8.194957330096395],
              [-38.553473119185753, -8.195002259962486],
              [-38.553454658660137, -8.195030008415246],
              [-38.553431453858295, -8.195078736570794],
              [-38.553424907580457, -8.195087412754487],
              [-38.553409510372184, -8.19513614955047],
              [-38.553396369093285, -8.195197190368159],
              [-38.55340096861957, -8.195224602534463],
              [-38.553379107937822, -8.195207754189774],
              [-38.553330558272727, -8.195188976795952],
              [-38.553252458846217, -8.195209875275216],
              [-38.553206772361989, -8.195228729231397],
              [-38.553149914543503, -8.195251731618697],
              [-38.553065893408785, -8.195291075788788],
              [-38.553031271604645, -8.195319982196461],
              [-38.553023991453813, -8.195335441488837],
              [-38.553018472077987, -8.195318792134556],
              [-38.552973099528145, -8.195300380027593],
              [-38.552936363171689, -8.195271665913902],
              [-38.552892272037795, -8.195243938622523],
              [-38.552835287268692, -8.195218006083481],
              [-38.552775756255215, -8.19519586971434],
              [-38.552713750183571, -8.195195077355228],
              [-38.552652830918454, -8.195196637953726],
              [-38.5525962501846, -8.195215389306885],
              [-38.552537391506888, -8.195241555220612],
              [-38.552487796134756, -8.195264927376858],
              [-38.552433587548862, -8.195273188861519],
              [-38.552344460496492, -8.1952508386904],
              [-38.552330683056269, -8.195231104784099],
              [-38.552310889267567, -8.195233253676875],
              [-38.55227973660277, -8.195244887462927],
              [-38.552262099633225, -8.195266757365252],
              [-38.552258524188446, -8.195297869015134],
              [-38.552200298857542, -8.195325935080628],
              [-38.552151057700158, -8.195357267396096],
              [-38.552114878605437, -8.195398563968039],
              [-38.552094934738108, -8.195453717774996],
              [-38.55209851104685, -8.195503018289989],
              [-38.552136281762508, -8.195580939718047],
              [-38.552168702582648, -8.195653066252234],
              [-38.552156360064117, -8.195730298878848],
              [-38.552133265474801, -8.195761479309315],
              [-38.552128760459979, -8.195730810952959],
              [-38.552109016420033, -8.19568845732152],
              [-38.552087198264111, -8.195633618958787],
              [-38.552060199536797, -8.195583930628127],
              [-38.551999254898952, -8.195526968410695],
              [-38.551950613876301, -8.195508733465223],
              [-38.551861000281441, -8.195515508309725],
              [-38.551810891080528, -8.195592337159285],
              [-38.551799932965316, -8.195649309978712],
              [-38.551780921024402, -8.195601801361311],
              [-38.551734668591202, -8.195558423284835],
              [-38.551650863169883, -8.195567104035359],
              [-38.551625870449783, -8.195590774779289],
              [-38.551608135250312, -8.195619247563702],
              [-38.551597286489667, -8.195578531939535],
              [-38.55155375472637, -8.195537779986106],
              [-38.551466252505193, -8.195523753044665],
              [-38.551419662710742, -8.195538444978601],
              [-38.551328333493686, -8.195536896178652],
              [-38.55132724416157, -8.195536804514767],
              [-38.551280512113642, -8.195516400756402],
              [-38.551207234033967, -8.195528530313675],
              [-38.551163523434013, -8.195566381225587],
              [-38.551106934173077, -8.195592639899672],
              [-38.551027221719693, -8.195594541146656],
              [-38.550977648841638, -8.195597742258414],
              [-38.550929171482593, -8.195595517441873],
              [-38.550884133650158, -8.195602522589398],
              [-38.55083138832903, -8.195601197533266],
              [-38.550800759739872, -8.195550238752102],
              [-38.550760867728485, -8.195502164129083],
              [-38.550694676640347, -8.195509236152272],
              [-38.550613015980289, -8.195548311067213],
              [-38.550572227433896, -8.195571059623324],
              [-38.550494990681905, -8.195551164505533],
              [-38.550445798621048, -8.195538627266506],
              [-38.550388875222396, -8.195539016091949],
              [-38.550325857372897, -8.195549890589284],
              [-38.550264741051386, -8.195565199361726],
              [-38.55020398380632, -8.195584126619069],
              [-38.550163822812841, -8.195613931115508],
              [-38.550135629426364, -8.195658130877016],
              [-38.550113780292079, -8.195711925660326],
              [-38.550078837314778, -8.195784067665869],
              [-38.550089822577455, -8.195864763413253],
              [-38.550111100422221, -8.19591544045903],
              [-38.550128833748737, -8.195969550740283],
              [-38.550143033835106, -8.196017054059284],
              [-38.550169772991133, -8.196055164315922],
              [-38.550231897955861, -8.196111856757765],
              [-38.55027081182962, -8.196141658966257],
              [-38.550305539789953, -8.19618013993054],
              [-38.550358671874591, -8.196241344946849],
              [-38.550391273986321, -8.19631347180947],
              [-38.550401786813026, -8.196329855402288],
              [-38.550413733229867, -8.196363155182318],
              [-38.5503867959033, -8.196339788452347],
              [-38.550331121887787, -8.196278490153231],
              [-38.55026863983386, -8.196216370185967],
              [-38.550217821777984, -8.196196504462549],
              [-38.550176642209891, -8.196163172080889],
              [-38.550124197634979, -8.196136520606299],
              [-38.550088463425794, -8.196104822431323],
              [-38.550050472982676, -8.196061182026851],
              [-38.550008950728547, -8.196009668325173],
              [-38.549972225515553, -8.195971185109231],
              [-38.549937495470971, -8.195934603615473],
              [-38.549899217858048, -8.195904168920379],
              [-38.549887226461991, -8.195830165521009],
              [-38.549888735569048, -8.195779875699733],
              [-38.549893876527896, -8.195729228121206],
              [-38.549869051146864, -8.195684697866776],
              [-38.549827499567527, -8.195659324844494],
              [-38.549770936147866, -8.195662518006191],
              [-38.549719546113209, -8.195666983267163],
              [-38.549710015095165, -8.195665615843685],
              [-38.549663540327487, -8.195658689588324],
              [-38.549610155846544, -8.195660619943276],
              [-38.549602166362135, -8.195660882380503],
              [-38.549546872523514, -8.195665343260943],
              [-38.549480871212502, -8.195665088654454],
              [-38.549409243401421, -8.195662837801256],
              [-38.549333343248968, -8.195665376136777],
              [-38.549263868040306, -8.195686464366304],
              [-38.549197021244311, -8.195711444971254],
              [-38.549134633043352, -8.195727475766104],
              [-38.549076513619809, -8.195741883180524],
              [-38.549025654537232, -8.195758560011173],
              [-38.548979460808418, -8.195824538574369],
              [-38.548963069888806, -8.19586838963164],
              [-38.548950113251927, -8.195926083735191],
              [-38.548941904288824, -8.195959994182367],
              [-38.548918793548495, -8.195924691930314],
              [-38.548878478377105, -8.19584948095841],
              [-38.548865914331031, -8.195800441742662],
              [-38.548838544096604, -8.195758531702673],
              [-38.548806351094292, -8.195726475571675],
              [-38.548753899439305, -8.195706246034019],
              [-38.548696061439024, -8.195712603450234],
              [-38.548643585120317, -8.195714263335541],
              [-38.548586025594147, -8.195715193907457],
              [-38.548517290298548, -8.195724162221364],
              [-38.548447463017105, -8.195735390609411],
              [-38.54837581072676, -8.195754848103979],
              [-38.548297721184788, -8.19576670038213],
              [-38.548222443458101, -8.195780817102007],
              [-38.548149150059984, -8.195806423475608],
              [-38.548084839377815, -8.19583656251455],
              [-38.548029250384708, -8.195861193722482],
              [-38.547983638060799, -8.19589451952446],
              [-38.547940937013458, -8.195922421443774],
              [-38.547891705176902, -8.195945160256628],
              [-38.547854445977826, -8.195977771785682],
              [-38.547823987361156, -8.196017808024129],
              [-38.547786353666659, -8.196060459333998],
              [-38.547749452791727, -8.196097322512891],
              [-38.547705104171392, -8.196137071811711],
              [-38.547660756359676, -8.196176097487459],
              [-38.547617408925127, -8.19621358659024],
              [-38.547578874992531, -8.196249452944761],
              [-38.547543520002463, -8.196284056528381],
              [-38.547503979741634, -8.196326705669899],
              [-38.547471235152969, -8.196381121231656],
              [-38.547440774217606, -8.196423147385099],
              [-38.547422453385465, -8.196487528878377],
              [-38.547409383634438, -8.196564760490762],
              [-38.547403938497297, -8.196643266989668],
              [-38.547394718774719, -8.196687759182971],
              [-38.547403154843643, -8.196774331512412],
              [-38.547416053965698, -8.196848064620882],
              [-38.547406818288444, -8.19690666735668],
              [-38.547385956836919, -8.196969779662227],
              [-38.54737156289125, -8.197013723333674],
              [-38.547358434941408, -8.197062100588406],
              [-38.547329871863724, -8.197111726852501],
              [-38.547312182083978, -8.197179998493537],
              [-38.547294520729686, -8.197223124423077],
              [-38.547252349890591, -8.197263690175918],
              [-38.547212808679511, -8.197307062897417],
              [-38.547207301751222, -8.197359880863319],
              [-38.547180568127182, -8.197317066909244],
              [-38.54713356017372, -8.197299828193364],
              [-38.54708719294814, -8.197277977124084],
              [-38.547021615123455, -8.197224174811268],
              [-38.546982281965469, -8.197163889400082],
              [-38.546994720617356, -8.197082858103586],
              [-38.547000508318625, -8.197022623367307],
              [-38.547038355995042, -8.196951479905412],
              [-38.547005142181767, -8.196858819442506],
              [-38.54697586485284, -8.196817540309292],
              [-38.546920482821676, -8.196739327311484],
              [-38.546892115317995, -8.196696330600947],
              [-38.546875291398997, -8.19664122624711],
              [-38.546839938377239, -8.196593789523119],
              [-38.546797131776103, -8.19655439467749],
              [-38.546742431156197, -8.196515890986113],
              [-38.546685461914123, -8.196476480215315],
              [-38.546635933163216, -8.196440605439406],
              [-38.546581864391491, -8.196405358720803],
              [-38.546519420143575, -8.196390544800623],
              [-38.546463212080496, -8.196400521813754],
              [-38.546411082634037, -8.196416473257104],
              [-38.546360310468849, -8.19643640612124],
              [-38.546294116317455, -8.196446010070634],
              [-38.546237542637513, -8.196458157495478],
              [-38.546181876407154, -8.196470667742458],
              [-38.546135000960462, -8.196496846169737],
              [-38.546103457315319, -8.196532810691698],
              [-38.546075990434026, -8.196576377791585],
              [-38.546065148376016, -8.196530234918738],
              [-38.54600956684169, -8.196468031638569],
              [-38.545983576124691, -8.196490796364946],
              [-38.545963139983122, -8.196498914069617],
              [-38.545907967472012, -8.196476329342701],
              [-38.545868067163354, -8.196516083434316],
              [-38.545796368887501, -8.196495831846597],
              [-38.545723036056302, -8.196475940214947],
              [-38.545671671207522, -8.196538113543513],
              [-38.545623132726881, -8.196589707130123],
              [-38.545570795786176, -8.196628452205433],
              [-38.545507129746866, -8.196650269930187],
              [-38.545484600883313, -8.196662455604681],
              [-38.545472320892046, -8.196603466874159],
              [-38.545459124621438, -8.196551894198613],
              [-38.545445199188691, -8.196502853365082],
              [-38.545417554457764, -8.196463113656625],
              [-38.545366336220305, -8.196396121239266],
              [-38.545347320201664, -8.196352863612125],
              [-38.545338303406474, -8.196298401170601],
              [-38.545338183661507, -8.196243948756806],
              [-38.54531789448869, -8.196202589191628],
              [-38.545271270032934, -8.196167622052453],
              [-38.545208479789892, -8.196137792463707],
              [-38.545154118800347, -8.196120183428997],
              [-38.545097588093114, -8.196094521686822],
              [-38.545039955216261, -8.196080074777694],
              [-38.544989495170157, -8.196065002785176],
              [-38.544939941139518, -8.196051559949646],
              [-38.544887204559807, -8.196042545679779],
              [-38.544831905510435, -8.196051528516632],
              [-38.544790119817939, -8.196072647200987],
              [-38.544749333797355, -8.196092862486188],
              [-38.544702010448795, -8.196113703554543],
              [-38.544661576353491, -8.196143778526347],
              [-38.544624317505551, -8.196175847028343],
              [-38.544576997844729, -8.196193431803154],
              [-38.544525949361571, -8.19621680130788],
              [-38.544487063959075, -8.196242174490859],
              [-38.544441103917066, -8.196261660282058],
              [-38.544394783196005, -8.196278974805784],
              [-38.544346291321837, -8.196289502950545],
              [-38.54429381349933, -8.196292428595163],
              [-38.544242413889165, -8.196305214749263],
              [-38.544192286724481, -8.196316736002649],
              [-38.544138902889841, -8.196318032462049],
              [-38.544088059627242, -8.19632059811768],
              [-38.544037946361904, -8.196319908310205],
              [-38.543990294522487, -8.196310266503808],
              [-38.543935015820622, -8.196301339687336],
              [-38.54387872580034, -8.19630353734949],
              [-38.543822799233801, -8.196305464057978],
              [-38.543772953590597, -8.196308935324714],
              [-38.543729534699644, -8.196329147524711],
              [-38.543692917142593, -8.196356060881424],
              [-38.543649891971434, -8.196429097642929],
              [-38.543630042121166, -8.196480361622626],
              [-38.543627533160759, -8.196531012054454],
              [-38.543627556008374, -8.196590620125143],
              [-38.543640112928607, -8.196645357954003],
              [-38.543652129463915, -8.196696296175912],
              [-38.543664052947726, -8.196749224242078],
              [-38.543658993208751, -8.196807560230599],
              [-38.543629718055996, -8.196844341182139],
              [-38.543630110954233, -8.196897889368771],
              [-38.543633628630651, -8.196918064204251],
              [-38.543555472834299, -8.196908116472889],
              [-38.543476759123806, -8.196909745985122],
              [-38.543446980897116, -8.196909983637655],
              [-38.543412472910674, -8.196917994828844],
              [-38.543402876702537, -8.196973793012962],
              [-38.543382256442257, -8.196984081228422],
              [-38.543344292377242, -8.196997515636712],
              [-38.543327043897968, -8.197076280029227],
              [-38.543358581228489, -8.197125702669961],
              [-38.543390487282508, -8.197170241303731],
              [-38.543420762489028, -8.197211883616729],
              [-38.543465846819423, -8.197243954750251],
              [-38.543523735032608, -8.197273417254152],
              [-38.543575187626942, -8.197293917693525],
              [-38.54361764432835, -8.197321463224318],
              [-38.543656640532227, -8.197358321418985],
              [-38.543691732101401, -8.197395898810937],
              [-38.543763482542573, -8.197450070433623],
              [-38.543816832207085, -8.197478894598229],
              [-38.543870911778093, -8.197504553752266],
              [-38.543921366903888, -8.197524058046737],
              [-38.543971911275925, -8.19754491922003],
              [-38.544022726982831, -8.197566685216762],
              [-38.544062006836235, -8.197593684398447],
              [-38.5440954691488, -8.197627008659467],
              [-38.54412864417322, -8.197673448172354],
              [-38.544160552519308, -8.197716087255916],
              [-38.54418983176992, -8.197755467084169],
              [-38.544215297167561, -8.197795566218742],
              [-38.544252577693115, -8.197824372169428],
              [-38.544304036164753, -8.19783980720049],
              [-38.544349051059832, -8.197853244995468],
              [-38.544375450286601, -8.197870551203508],
              [-38.544321149318201, -8.197879806439628],
              [-38.544272940108456, -8.19788110875208],
              [-38.544222111990159, -8.197870197042725],
              [-38.544171937609548, -8.197843276014302],
              [-38.544113395526651, -8.19782982290975],
              [-38.544057401343117, -8.197811216897849],
              [-38.544005944328703, -8.197794515505979],
              [-38.543947397829136, -8.197784951820376],
              [-38.543881499581531, -8.197773661219214],
              [-38.543816494155855, -8.197775577648056],
              [-38.543754108345276, -8.197789074919649],
              [-38.543696270724006, -8.197794798432447],
              [-38.543633906147612, -8.197789662542917],
              [-38.543569250357429, -8.197803428573694],
              [-38.543514569737845, -8.197827155632913],
              [-38.543460610350223, -8.197855315661592],
              [-38.543426525164314, -8.1978905534581],
              [-38.543396061531219, -8.197934478768392],
              [-38.543377115406031, -8.197989271387272],
              [-38.543350181190384, -8.198043059985304],
              [-38.543323897744294, -8.198083462383801],
              [-38.543306864001671, -8.198133101384851],
              [-38.543304062497135, -8.198201389666705],
              [-38.543306354639306, -8.198261271656442],
              [-38.543315433389019, -8.198340789471011],
              [-38.543336629796286, -8.198382602415808],
              [-38.543362443740627, -8.198435455763432],
              [-38.543386908168642, -8.198477543763111],
              [-38.543340630174214, -8.198457139605084],
              [-38.543297829321141, -8.198412859984042],
              [-38.543234489706634, -8.198466516889734],
              [-38.543219429399763, -8.198537052590654],
              [-38.543192745585664, -8.198450911819721],
              [-38.543170188629219, -8.198408011899881],
              [-38.543137363288032, -8.198373421954011],
              [-38.543096739901344, -8.198330411111446],
              [-38.543040118147765, -8.198304839423088],
              [-38.542987392276139, -8.198286236965446],
              [-38.542935951364306, -8.198255424903381],
              [-38.54287897278639, -8.198224335201697],
              [-38.542826883163222, -8.198205100279392],
              [-38.542776982341522, -8.198177184416002],
              [-38.542712013430346, -8.198147080607811],
              [-38.542653757984198, -8.19812114520631],
              [-38.542595863935446, -8.198096747894867],
              [-38.542529956798276, -8.198093235955552],
              [-38.542474665491248, -8.198095163183645],
              [-38.542421900183832, -8.198111113368373],
              [-38.542378569647539, -8.198133225004367],
              [-38.542331244496218, -8.198155332102251],
              [-38.54228791322663, -8.198178076892798],
              [-38.542251974845023, -8.198245965739341],
              [-38.542233370078584, -8.198319663200779],
              [-38.542246296095833, -8.198369064797632],
              [-38.542281023428345, -8.198407546417062],
              [-38.542313850865995, -8.198440236933999],
              [-38.542360889420955, -8.198510028995521],
              [-38.542369990009497, -8.198570280517307],
              [-38.542388628374788, -8.19862602024955],
              [-38.542423084416356, -8.198663597028025],
              [-38.542458176798505, -8.198700450907737],
              [-38.542488172724489, -8.198748153272186],
              [-38.542520351467537, -8.198792420925596],
              [-38.542553813739367, -8.198825745311028],
              [-38.542587276016796, -8.198859069694004],
              [-38.542628513717233, -8.198920623993837],
              [-38.542698880143817, -8.198994331924329],
              [-38.542759683533653, -8.199015204888392],
              [-38.542698828166472, -8.199039829345162],
              [-38.542620942535009, -8.19911092693054],
              [-38.54260690792897, -8.199157674822363],
              [-38.542590242568174, -8.199202610684388],
              [-38.542573570794701, -8.199253154577301],
              [-38.542590308479511, -8.199303826819914],
              [-38.542610941941966, -8.199361558755886],
              [-38.54263186141349, -8.199407351319181],
              [-38.542657043843185, -8.199456676354091],
              [-38.542685049767385, -8.199498044792316],
              [-38.542705874540019, -8.199547274430168],
              [-38.542658330304626, -8.199522617498658],
              [-38.542618502962583, -8.199497969315088],
              [-38.542566962564543, -8.199474664624196],
              [-38.542516849641444, -8.199473341441342],
              [-38.542458295708478, -8.199470109177433],
              [-38.542402019825417, -8.199459552858846],
              [-38.542393212358846, -8.199460537838569],
              [-38.542337733911225, -8.199467168341634],
              [-38.542295502281846, -8.199480869148076],
              [-38.542279517787989, -8.199486097233899],
              [-38.542218407133966, -8.199495706250879],
              [-38.542167999503555, -8.199513920373317],
              [-38.542127468189641, -8.19954915072438],
              [-38.54209474744134, -8.199581857277998],
              [-38.542058128024131, -8.199610036789254],
              [-38.541982902833283, -8.199656805607649],
              [-38.541928868353722, -8.199670945228119],
              [-38.541838521682351, -8.199682782277621],
              [-38.541792513619974, -8.199664458647881],
              [-38.541721803024473, -8.199653524021826],
              [-38.541639453993923, -8.199658043485396],
              [-38.541582266203164, -8.199651013652554],
              [-38.541488033307076, -8.199647469326237],
              [-38.541445252456683, -8.199665058862116],
              [-38.541397995889888, -8.199706341786424],
              [-38.541362985187064, -8.199756683856229],
              [-38.541316424732443, -8.199824289159285],
              [-38.54129820524566, -8.199878449316175],
              [-38.541315929420279, -8.199939615183261],
              [-38.541334027320765, -8.199991555347744],
              [-38.541302893265126, -8.200065600302496],
              [-38.54131295536542, -8.200158144374784],
              [-38.541327465206003, -8.200251417124084],
              [-38.541346472856432, -8.200301730182987],
              [-38.541380019297847, -8.200340843703],
              [-38.541430183514969, -8.200376719840945],
              [-38.541493898542299, -8.20039207872804],
              [-38.541557259544376, -8.200399477406856],
              [-38.541625442210893, -8.2003981981437],
              [-38.541693267218925, -8.200392124494401],
              [-38.541754001925071, -8.200393821666832],
              [-38.541818986540115, -8.200410448277482],
              [-38.541879788638219, -8.200432587711676],
              [-38.541932865763442, -8.200461773634666],
              [-38.541989212785943, -8.200489606482112],
              [-38.542046470896452, -8.200514636337719],
              [-38.54210499196239, -8.200546722893964],
              [-38.542126037400742, -8.200561761865194],
              [-38.542152616407158, -8.200580696565279],
              [-38.542195062370766, -8.200617830179409],
              [-38.542252674454751, -8.200650910657941],
              [-38.542308377224863, -8.200686431171595],
              [-38.54236980973397, -8.200713093862682],
              [-38.542436428910804, -8.200729450876377],
              [-38.542502316729497, -8.200750239208993],
              [-38.542575644246654, -8.20077528722716],
              [-38.54263807730905, -8.200800322873823],
              [-38.542693056009242, -8.200833943018594],
              [-38.542749759262769, -8.200867926921198],
              [-38.542806017063477, -8.200894493227571],
              [-38.542862998896808, -8.200923050296627],
              [-38.542923791633299, -8.200953511175552],
              [-38.542983954033453, -8.200979448719259],
              [-38.543040208148597, -8.201009271270935],
              [-38.543078206286616, -8.20104585704334],
              [-38.543136153571972, -8.201103540791991],
              [-38.543201192504583, -8.201152277796341],
              [-38.543250661461599, -8.201161198164291],
              [-38.543306319544307, -8.201156467248591],
              [-38.543367077918681, -8.201137541099563],
              [-38.543424942446272, -8.20110866191675],
              [-38.543476355084302, -8.201085021649575],
              [-38.543539650326231, -8.201070620958435],
              [-38.543600124969139, -8.201061644198969],
              [-38.543656066125315, -8.201047506520522],
              [-38.543726904021945, -8.201026692227186],
              [-38.543784936679586, -8.201009571973152],
              [-38.5438398041507, -8.200981593859021],
              [-38.543883686459608, -8.200953512857005],
              [-38.543919730752542, -8.20095201594537],
              [-38.543891451484264, -8.200990968874111],
              [-38.543858335638184, -8.20105171532086],
              [-38.543827786055836, -8.201091208389039],
              [-38.543787350654448, -8.201121916399549],
              [-38.543746195445451, -8.201146925099279],
              [-38.543742107542016, -8.20114909132694],
              [-38.543702499733996, -8.201170574135444],
              [-38.543669218359497, -8.201217209825742],
              [-38.543651395680627, -8.201242064019914],
              [-38.543611914656871, -8.201311577105383],
              [-38.543631613869856, -8.201392644618911],
              [-38.543670598795508, -8.201439723917469],
              [-38.54368408526792, -8.201475558272866],
              [-38.543648065890643, -8.201455165745312],
              [-38.543558045679738, -8.201419244743928],
              [-38.543495039976534, -8.201418540237754],
              [-38.543437470782784, -8.201427248912024],
              [-38.543380532041098, -8.201440390451927],
              [-38.543326238619713, -8.201442590258832],
              [-38.543264499499038, -8.201445776597605],
              [-38.543197948234891, -8.201449319283128],
              [-38.543129398305737, -8.201454035571748],
              [-38.543063138096599, -8.201441206709424],
              [-38.543002041718566, -8.201438062079554],
              [-38.542939046246033, -8.201428402726341],
              [-38.542826798921745, -8.201378707624999],
              [-38.54278906177224, -8.201352071871074],
              [-38.542739876536345, -8.201333202021596],
              [-38.542683602658514, -8.201320655781837],
              [-38.542622218952722, -8.201330626343747],
              [-38.542570730029048, -8.201341512645683],
              [-38.542488739869739, -8.201349469856218],
              [-38.542440524455948, -8.201355837244471],
              [-38.542385517885528, -8.201346548682189],
              [-38.542333405133263, -8.201347213143103],
              [-38.542258806508421, -8.20132216363352],
              [-38.542206394765337, -8.201266747356078],
              [-38.542122101690772, -8.201224410438069],
              [-38.542078283617066, -8.201196230026371],
              [-38.542022022205963, -8.201172829443115],
              [-38.541962558296767, -8.201171224200721],
              [-38.541916893744414, -8.201169905985967],
              [-38.541851657425866, -8.201214424803648],
              [-38.541809544720302, -8.201282668374157],
              [-38.54178067928418, -8.201357620460993],
              [-38.541795922914886, -8.201444471922315],
              [-38.541794322669894, -8.201494128374543],
              [-38.541790184728086, -8.201539982948864],
              [-38.541794399948266, -8.201585213851811],
              [-38.54179251449635, -8.201646086055177],
              [-38.541831488965904, -8.201702120278911],
              [-38.541900346794819, -8.201745706051899],
              [-38.541936447154136, -8.201774601333129],
              [-38.54189188005936, -8.201766229085335],
              [-38.541854396145382, -8.201756327194955],
              [-38.541835486783206, -8.201778647403605],
              [-38.541821934083899, -8.20180079279497],
              [-38.54175055528269, -8.20181807848852],
              [-38.541703696436763, -8.201828969937988],
              [-38.541645487295092, -8.201841476616631],
              [-38.541596614955985, -8.201866476310343],
              [-38.541541939572873, -8.201885047290517],
              [-38.541483733424919, -8.201894930834172],
              [-38.541427234199226, -8.201920554996775],
              [-38.541382550915678, -8.201934343353535],
              [-38.541319235569198, -8.201966110496375],
              [-38.541270018815325, -8.201974737905489],
              [-38.541185827939103, -8.202001596850835],
              [-38.541151435599133, -8.202066683298941],
              [-38.541154730842266, -8.202122405613375],
              [-38.541146770199369, -8.202176577427704],
              [-38.541173478662536, -8.202241009804718],
              [-38.541209206005668, -8.20227849768559],
              [-38.541278643839021, -8.20229133047258],
              [-38.541328202837555, -8.202300974801481],
              [-38.541397708037586, -8.202334249845695],
              [-38.541421119093286, -8.202344859388802],
              [-38.541483819285702, -8.202374599040752],
              [-38.541413174652355, -8.20238492068234],
              [-38.541366883266832, -8.202375913246593],
              [-38.541278437324237, -8.202392274908382],
              [-38.541229270961068, -8.202436088277654],
              [-38.54128721514811, -8.202496395395308],
              [-38.541329039600996, -8.202521407799843],
              [-38.541388940430558, -8.202538028710947],
              [-38.54144966446988, -8.202549313869239],
              [-38.541512119799876, -8.202555083402647],
              [-38.541578026200625, -8.202559861876868],
              [-38.541639121172018, -8.202564363512598],
              [-38.541692132733964, -8.202571750419441],
              [-38.541736877447022, -8.202583650531954],
              [-38.541785989394249, -8.202587324452278],
              [-38.541825111702863, -8.202593610126275],
              [-38.541793766777374, -8.202613835793441],
              [-38.541691301788667, -8.202663106214635],
              [-38.541636629925094, -8.202678511377735],
              [-38.541584220079322, -8.202700612569757],
              [-38.541532172342109, -8.2027236186886],
              [-38.541489111745527, -8.202747358641252],
              [-38.541442855331383, -8.202787014543008],
              [-38.541409407223874, -8.202820353356518],
              [-38.541388636723234, -8.202882199038548],
              [-38.541439784050105, -8.202931915495254],
              [-38.541506629691476, -8.202909378484232],
              [-38.541581898058581, -8.202904760579663],
              [-38.541652422151245, -8.202920489014623],
              [-38.541696270836219, -8.202922076561949],
              [-38.541669554288248, -8.202943845167635],
              [-38.54157397579764, -8.203004701266922],
              [-38.541594011794615, -8.203108472744443],
              [-38.541625467669391, -8.203149935632595],
              [-38.541575363624027, -8.203140471619069],
              [-38.541532598460911, -8.203144041066009],
              [-38.541444492383732, -8.203180573984806],
              [-38.541412044047213, -8.20321291895926],
              [-38.541371919556894, -8.203288853161938],
              [-38.541350433711735, -8.203341110090649],
              [-38.541316056100086, -8.203393261894554],
              [-38.541277242219472, -8.203434644832848],
              [-38.541234514290814, -8.203484887628194],
              [-38.54118507417396, -8.203529786097087],
              [-38.541144867637165, -8.203598031741029],
              [-38.541130237214936, -8.203689010003812],
              [-38.541141799960435, -8.203739676403762],
              [-38.541166630704495, -8.203779051382931],
              [-38.541208453100403, -8.203805963299223],
              [-38.541250913195391, -8.203830976440919],
              [-38.541321404350207, -8.203875559158012],
              [-38.54139181873181, -8.203907930728093],
              [-38.541425647052343, -8.203938994328105],
              [-38.541451674149826, -8.203964350560389],
              [-38.541395822684926, -8.203979030719896],
              [-38.541317337024132, -8.204018107207768],
              [-38.541276875309322, -8.204071337505098],
              [-38.541245694284378, -8.204106849729751],
              [-38.541284547924413, -8.204189205462731],
              [-38.541281133901272, -8.204237050793566],
              [-38.541308325486227, -8.204276157096059],
              [-38.541315535733446, -8.204322205475183],
              [-38.541270662299866, -8.204343229763175],
              [-38.541210377828996, -8.204344246564741],
              [-38.541146373333305, -8.204343269173206],
              [-38.541095794150408, -8.204352166349363],
              [-38.541043366412104, -8.204389644309716],
              [-38.541016809302313, -8.204430588911686],
              [-38.54102065724728, -8.204479347030682],
              [-38.541053937222784, -8.204513575864636],
              [-38.541111849296747, -8.204522958389713],
              [-38.541158484670895, -8.204548880820496],
              [-38.541168417404023, -8.204595927275074],
              [-38.5412100382839, -8.204640296240413],
              [-38.541261591897268, -8.204652565976726],
              [-38.541306813272051, -8.204644838561999],
              [-38.541355694273165, -8.204612602759694],
              [-38.54140038947606, -8.204588683790472],
              [-38.541450887124675, -8.204571736238275],
              [-38.541492130713394, -8.204549260566171],
              [-38.541544178488628, -8.204526435371946],
              [-38.541583701967063, -8.204499706479233],
              [-38.541621854291648, -8.20448111672156],
              [-38.541648722262011, -8.204485760343564],
              [-38.541675749216452, -8.204510122726285],
              [-38.541694865593364, -8.204544968567362],
              [-38.541684954047909, -8.20455861557892],
              [-38.541663743949606, -8.204607887910333],
              [-38.54165042608539, -8.204662686801457],
              [-38.541632282456277, -8.204729691250845],
              [-38.541661824544732, -8.20477757406983],
              [-38.541696199136823, -8.204807371941286],
              [-38.541722655582504, -8.204854256272816],
              [-38.541722835915174, -8.204855341904391],
              [-38.541737975675503, -8.204953680671622],
              [-38.541739374399214, -8.205000717410398],
              [-38.541739038510478, -8.205056164231715],
              [-38.54173207798636, -8.205109070857356],
              [-38.541728292041093, -8.205164604206907],
              [-38.541731127414693, -8.205225572218803],
              [-38.541741587029421, -8.205288267493081],
              [-38.541767043595314, -8.205336236111926],
              [-38.541796320422691, -8.205377967871229],
              [-38.541829411709244, -8.20541852808717],
              [-38.541874386421135, -8.205467694771109],
              [-38.541922194227062, -8.205500583273885],
              [-38.541986085755234, -8.205521098100849],
              [-38.542063704443009, -8.205525799382103],
              [-38.542124707740683, -8.205531476742852],
              [-38.542187615316607, -8.205539146206608],
              [-38.542242543154593, -8.205538394539925],
              [-38.542307191238137, -8.205532498130655],
              [-38.542366851036419, -8.205522163887212],
              [-38.542413989397893, -8.205505393308467],
              [-38.542452244060549, -8.205476763396597],
              [-38.542493501662349, -8.205442076591874],
              [-38.542546833680369, -8.205407765298993],
              [-38.542597787403722, -8.205389009080168],
              [-38.54270347154101, -8.205383340088995],
              [-38.542772925686691, -8.205382423929453],
              [-38.542868144053145, -8.205398722908756],
              [-38.543000593571534, -8.205408099271528],
              [-38.54307347897435, -8.205423558804034],
              [-38.543170970379229, -8.20543696581929],
              [-38.543261122196171, -8.205437882085091],
              [-38.543332613044299, -8.205402505840176],
              [-38.543380057798331, -8.205355886258022],
              [-38.543429047799847, -8.205307640280326],
              [-38.543479199040704, -8.205276038821443],
              [-38.543532520222989, -8.205251134411624],
              [-38.543508957966594, -8.205293258458198],
              [-38.543476403952212, -8.205338628596291],
              [-38.543452119508224, -8.205377133731483],
              [-38.543424550677848, -8.205429655238547],
              [-38.543401427471061, -8.205484804895361],
              [-38.543379301069969, -8.205541583819947],
              [-38.543343384857693, -8.205589573249295],
              [-38.543286794103565, -8.205615468899163],
              [-38.543223494304513, -8.205633216235345],
              [-38.543158668869275, -8.205635404049275],
              [-38.54310094248779, -8.205622223024804],
              [-38.543049660633145, -8.205610315631755],
              [-38.542970916582249, -8.205637000182852],
              [-38.542980130376016, -8.205677804584493],
              [-38.543013584551026, -8.205718726930838],
              [-38.543054302109688, -8.205759386155025],
              [-38.543094045701118, -8.20577842619034],
              [-38.543127734168273, -8.205773218161006],
              [-38.543180555419028, -8.205788654927158],
              [-38.543207291079355, -8.205829750546126],
              [-38.543232021446798, -8.205877808711421],
              [-38.543255576942542, -8.205921162028069],
              [-38.543286028538674, -8.205967869892458],
              [-38.543347970496022, -8.206026191330075],
              [-38.543418710893185, -8.206091578002711],
              [-38.543467173012246, -8.206108185673155],
              [-38.543485780095473, -8.206112186653618],
              [-38.543524981284641, -8.206129236085527],
              [-38.543580539981853, -8.206132645757734],
              [-38.543634923798017, -8.206131350575848],
              [-38.543714813383481, -8.206135149596161],
              [-38.543803318380121, -8.206147279960422],
              [-38.543863471393927, -8.206182172171154],
              [-38.54390781381823, -8.206228805266164],
              [-38.543932003895726, -8.206273063788142],
              [-38.543953828011439, -8.206322113599013],
              [-38.544012852516417, -8.206391285906799],
              [-38.544087720540773, -8.206419863084008],
              [-38.54414368961735, -8.206461534438803],
              [-38.544196910411308, -8.206524820760418],
              [-38.544236557782412, -8.206548745000964],
              [-38.544279740448381, -8.206577467204886],
              [-38.54434750805941, -8.206622679515656],
              [-38.544420730624807, -8.206661204518914],
              [-38.544485543043315, -8.206670594381752],
              [-38.54457950611387, -8.206673414173553],
              [-38.54469047146717, -8.206654454169197],
              [-38.544732812710834, -8.206624833683149],
              [-38.544717049448522, -8.206674745505074],
              [-38.544726402592303, -8.206752816354417],
              [-38.544774937210946, -8.206785614906513],
              [-38.544810962469946, -8.206801303847081],
              [-38.544779627946383, -8.206812213164127],
              [-38.544783125560123, -8.206850116603242],
              [-38.544751374982084, -8.206827739024742],
              [-38.544710348524198, -8.206818918797058],
              [-38.544661407600096, -8.206824019264671],
              [-38.544617187785178, -8.206829034610386],
              [-38.544568597137179, -8.206845441991744],
              [-38.544555155170443, -8.206850039860241],
              [-38.544493026609608, -8.206875567603394],
              [-38.544441617279986, -8.206895680323516],
              [-38.544384938392831, -8.206919133809992],
              [-38.544339607355475, -8.206943233288467],
              [-38.544282112595539, -8.206965600410246],
              [-38.544218899589204, -8.206986694723136],
              [-38.544146076695156, -8.206995748005967],
              [-38.544061365128641, -8.207000717470885],
              [-38.543995151337391, -8.207025969154056],
              [-38.543935574393167, -8.207043087638771],
              [-38.543876283738328, -8.207047995388223],
              [-38.543808461942838, -8.20705027035425],
              [-38.54373565420277, -8.207046027102111],
              [-38.543656665509161, -8.207048198943401],
              [-38.543591635037401, -8.20707073833219],
              [-38.543542756264756, -8.207100893996879],
              [-38.543515659017565, -8.207137858252462],
              [-38.543501894171925, -8.207186686854145],
              [-38.543506009529011, -8.207240058326692],
              [-38.543536701605625, -8.207314806636608],
              [-38.543578575468359, -8.207376632945802],
              [-38.543601227014705, -8.207416638489494],
              [-38.54357879270286, -8.207424934677046],
              [-38.543514710942205, -8.207411656045821],
              [-38.543457346621686, -8.207399199087178],
              [-38.54340077203355, -8.207410712853239],
              [-38.543350431082146, -8.207449188437737],
              [-38.543301185887685, -8.207482147672419],
              [-38.54324978985823, -8.207490501459585],
              [-38.543199040094414, -8.207489448942459],
              [-38.54313977127876, -8.207475180734802],
              [-38.543080151715685, -8.20745005785526],
              [-38.54301880283338, -8.207428731998494],
              [-38.542942997669378, -8.207425661078519],
              [-38.542875793445518, -8.207443584816854],
              [-38.542821299016332, -8.207462065680822],
              [-38.542771973644363, -8.207485708179831],
              [-38.542736621352859, -8.207516693161905],
              [-38.542710047634237, -8.207572110201747],
              [-38.542710719120123, -8.207620050634231],
              [-38.54272010167243, -8.207672071304234],
              [-38.542753375638831, -8.207711908044605],
              [-38.542786198253538, -8.207749482965038],
              [-38.542847773620672, -8.207810969896574],
              [-38.542923240935977, -8.207871387155807],
              [-38.54299218057637, -8.207923837201966],
              [-38.543046598982706, -8.207971929059005],
              [-38.543101565236078, -8.208017307964315],
              [-38.543089739015997, -8.208038008106778],
              [-38.543046851221, -8.208068984566681],
              [-38.543015400438875, -8.208102054397289],
              [-38.542983675320599, -8.208136842505864],
              [-38.542944867423223, -8.20817270800174],
              [-38.542894079728647, -8.208204760906684],
              [-38.542849282852181, -8.208237906030066],
              [-38.542795138331556, -8.208267874711147],
              [-38.542738726045833, -8.208296122218218],
              [-38.542685943757157, -8.208325730621269],
              [-38.54263753753937, -8.208339424397721],
              [-38.5425896063728, -8.208334666458169],
              [-38.542516244790662, -8.208338201234497],
              [-38.542440250329449, -8.2083417330067],
              [-38.542382878982821, -8.208335336179715],
              [-38.542351960134411, -8.20830020559929],
              [-38.54234534434724, -8.208289886532031],
              [-38.542381065277716, -8.208254198495068],
              [-38.542402281615658, -8.208199589467618],
              [-38.542352121435478, -8.208159281253687],
              [-38.542299130658336, -8.208133080361044],
              [-38.542257124304783, -8.208107796465956],
              [-38.542205389672169, -8.208094622105797],
              [-38.5421537499751, -8.208077829759045],
              [-38.542097833366256, -8.208069625503159],
              [-38.542042262821674, -8.208076346245669],
              [-38.54199385423378, -8.208092120343222],
              [-38.541938913818697, -8.208103545301894],
              [-38.54188933978859, -8.208106383397189],
              [-38.541832244275597, -8.208097001885271],
              [-38.541778324198539, -8.208089523473577],
              [-38.541721125669298, -8.208090815187846],
              [-38.54166860865665, -8.208126574506146],
              [-38.541639502303674, -8.208173576527983],
              [-38.541624369964502, -8.208227107006635],
              [-38.541614391018364, -8.208299457404618],
              [-38.541614518361634, -8.208346764045615],
              [-38.541610453651977, -8.208407814649087],
              [-38.541608400400598, -8.208456566043386],
              [-38.541611506770067, -8.208518710233362],
              [-38.541622342956529, -8.20856973760022],
              [-38.5416652367757, -8.208612841652778],
              [-38.54172257465661, -8.208648454603145],
              [-38.54177665409663, -8.208675380428614],
              [-38.541771080514245, -8.20870630873765],
              [-38.541763670831571, -8.208754963587099],
              [-38.541752848590527, -8.208770961312897],
              [-38.541716497634077, -8.208801764152598],
              [-38.541688928632382, -8.208854014150281],
              [-38.54168341285542, -8.208913706319361],
              [-38.541698503909046, -8.208975411884111],
              [-38.541724138525339, -8.209026636990721],
              [-38.541738684439458, -8.209088703742612],
              [-38.541723196069846, -8.209135992615327],
              [-38.541676224204537, -8.209165517033616],
              [-38.541624916234376, -8.209176041551906],
              [-38.541559891640546, -8.209193063008792],
              [-38.541493138625519, -8.209213067409461],
              [-38.541424934742523, -8.209231532459496],
              [-38.541364081865922, -8.209252538529597],
              [-38.541301324487726, -8.209271733377538],
              [-38.541236376124779, -8.209301418172856],
              [-38.541194563926922, -8.209344244879453],
              [-38.541157824284269, -8.209397208004344],
              [-38.541145871003735, -8.209449385294858],
              [-38.541157984425979, -8.209494986994274],
              [-38.541177169439912, -8.209549280158754],
              [-38.54118946029412, -8.209598500146743],
              [-38.541187635997858, -8.209605915147373],
              [-38.541177967185014, -8.209645250830961],
              [-38.541152940459902, -8.209697232322567],
              [-38.541124646873612, -8.209747853307253],
              [-38.541085850284752, -8.209773587972686],
              [-38.541038538819407, -8.20978248882559],
              [-38.541002629302398, -8.209824055903555],
              [-38.540987041300433, -8.209878942609274],
              [-38.540956574761879, -8.209924314877085],
              [-38.540921112521403, -8.209971671395319],
              [-38.540875214804061, -8.210014402952478],
              [-38.540861671306942, -8.210028226695059],
              [-38.540835947603718, -8.210054609402547],
              [-38.540782878137215, -8.210096790058174],
              [-38.540735266721541, -8.210129660356582],
              [-38.540679298212972, -8.210166410560934],
              [-38.540633735435932, -8.210233655007755],
              [-38.540598729674812, -8.210278750709906],
              [-38.540554623630271, -8.210263413976906],
              [-38.540501712896649, -8.210246439054407],
              [-38.540448895972794, -8.210226841119361],
              [-38.540388636202934, -8.210205606544456],
              [-38.540335522583938, -8.210207174063621],
              [-38.540287269874241, -8.210245018443427],
              [-38.540278582473299, -8.210299279826341],
              [-38.540279604327964, -8.210358074900796],
              [-38.540279429172294, -8.210431431407399],
              [-38.54024466340141, -8.210425693232276],
              [-38.540207111357546, -8.210395439336361],
              [-38.540164113831707, -8.210363551080391],
              [-38.540113950407282, -8.210326046602852],
              [-38.540059023484325, -8.210325441162038],
              [-38.540045260797832, -8.210371917855358],
              [-38.540046832661979, -8.210426100496329],
              [-38.540052369075141, -8.210506518792252],
              [-38.540045482330697, -8.210573897787572],
              [-38.54003423654688, -8.210642266777354],
              [-38.539997948909061, -8.210696677529109],
              [-38.539953620249776, -8.21071697862642],
              [-38.539897765910304, -8.21073328665503],
              [-38.539885780040336, -8.210734720194905],
              [-38.539836088755671, -8.210760261376679],
              [-38.539790938290551, -8.210784631863152],
              [-38.53973988504837, -8.210810261927957],
              [-38.539695090438357, -8.21084096446069],
              [-38.539651482439204, -8.21086615076304],
              [-38.539598534477207, -8.210881467097094],
              [-38.539534070317778, -8.210884559214712],
              [-38.539480585324789, -8.21089318145934],
              [-38.539435111897788, -8.210882637003294],
              [-38.539388566615798, -8.210856804902372],
              [-38.539341656827069, -8.210832148257424],
              [-38.53927704060257, -8.210809642201376],
              [-38.539221452443812, -8.21083128711533],
              [-38.539170935244051, -8.210864425247447],
              [-38.539127040451369, -8.210902093559522],
              [-38.539089955277298, -8.210939407851907],
              [-38.539055216540355, -8.210988935866339],
              [-38.539029922508782, -8.211036213385379],
              [-38.539012973384445, -8.211090193914588],
              [-38.53900445036119, -8.211159199145508],
              [-38.538996605322623, -8.211191391128473],
              [-38.53898547297586, -8.211239951186023],
              [-38.538945847422973, -8.211275724867168],
              [-38.538878897863029, -8.211308301429504],
              [-38.538866461884083, -8.211385081058683],
              [-38.538872762782269, -8.211432213841508],
              [-38.53884265581631, -8.211401696989784],
              [-38.538790011856577, -8.211389516108191],
              [-38.538750832912456, -8.211431621929609],
              [-38.53871947031967, -8.211466410077533],
              [-38.538666964293519, -8.211492038332677],
              [-38.538610946182395, -8.211492788217642],
              [-38.538546484640534, -8.211493528418668],
              [-38.538502466629843, -8.21148054331543],
              [-38.538469003296377, -8.211447580382652],
              [-38.538446101536159, -8.211388669789814],
              [-38.538427362834909, -8.211341251389518],
              [-38.538413227244014, -8.211317355821253],
              [-38.538387225178504, -8.211270109997482],
              [-38.538345966510185, -8.211226283862638],
              [-38.538300801707905, -8.211184352740219],
              [-38.538252280629649, -8.211139885104632],
              [-38.538190141766556, -8.211094859133715],
              [-38.538120015743552, -8.211047834045679],
              [-38.538069408530902, -8.211001645406402],
              [-38.538024157907607, -8.210955462906579],
              [-38.538004116335294, -8.210935630896055],
              [-38.537991644581581, -8.210886139269167],
              [-38.537977436121544, -8.21084677628863],
              [-38.53797282049856, -8.210834107693955],
              [-38.537957776639594, -8.210810572877953],
              [-38.537953698644053, -8.210804055645225],
              [-38.537935344618973, -8.210738094978904],
              [-38.537932326581611, -8.210678574010673],
              [-38.537937544969417, -8.210641042361887],
              [-38.537935149178026, -8.210593009528692],
              [-38.53793603476911, -8.210533764395539],
              [-38.537908940093907, -8.210489321307461],
              [-38.537881578912526, -8.210439812592373],
              [-38.537842957352055, -8.210392461804611],
              [-38.537790345670572, -8.210352512032049],
              [-38.537726748598935, -8.21031245919113],
              [-38.537658697424888, -8.210277104738383],
              [-38.537570099214349, -8.210266510566266],
              [-38.537484021235969, -8.210274823764962],
              [-38.537407121556747, -8.21027573041332],
              [-38.537357993978354, -8.210284809644087],
              [-38.537345274579081, -8.210292483462974],
              [-38.537290844677344, -8.210333124387054],
              [-38.537243476118597, -8.210312808682449],
              [-38.537198748203892, -8.21028580254962],
              [-38.537139950260944, -8.210256790294366],
              [-38.537072245236232, -8.21023617984029],
              [-38.536996277766931, -8.210216102588792],
              [-38.536928462269216, -8.210212496983864],
              [-38.536901769490889, -8.210213099455077],
              [-38.53684502745628, -8.210211948776973],
              [-38.536810658151929, -8.210177537441597],
              [-38.536803399203677, -8.210173911007765],
              [-38.536763202937493, -8.210153693950183],
              [-38.536688290062699, -8.210163738412161],
              [-38.536635952149851, -8.210201034923527],
              [-38.536601118206349, -8.210254180690233],
              [-38.536550325998611, -8.210289489021189],
              [-38.536499391639062, -8.210290787184462],
              [-38.53644393606708, -8.210276341468363],
              [-38.536397217145598, -8.210243905830682],
              [-38.536332347304409, -8.210205479450874],
              [-38.536265208899067, -8.210166055475634],
              [-38.536193723853401, -8.210116948107585],
              [-38.536119968255321, -8.210068561729251],
              [-38.53605066386649, -8.210018009615366],
              [-38.53598107904331, -8.209974421980125],
              [-38.535916757068541, -8.209933373057321],
              [-38.535869852977775, -8.209904102973027],
              [-38.535793744819237, -8.209848929919046],
              [-38.53574176233225, -8.209814679101125],
              [-38.535703662925954, -8.209787047286255],
              [-38.535662208318918, -8.20975597441946],
              [-38.535616763094154, -8.209721369315016],
              [-38.535564510794238, -8.209684947313933],
              [-38.535509353357227, -8.20964843150397],
              [-38.535452473985551, -8.209609290589574],
              [-38.535436052718723, -8.209599321916299],
              [-38.535388331286846, -8.209570322193104],
              [-38.535323015597292, -8.209525111238181],
              [-38.53525606729665, -8.209478541607909],
              [-38.535188936171807, -8.20943305718151],
              [-38.535120712333232, -8.209390375498739],
              [-38.535054037183237, -8.20934317298755],
              [-38.534983362597266, -8.209300036193373],
              [-38.534913234760431, -8.209255181428821],
              [-38.5348436520948, -8.209209965473976],
              [-38.534774071655669, -8.209162850016321],
              [-38.534704490812238, -8.209116096355412],
              [-38.534639907380161, -8.209065911277262],
              [-38.534575140701776, -8.209017173211372],
              [-38.534506648954157, -8.208970782585483],
              [-38.534436429599566, -8.208926651254314],
              [-38.534365844155502, -8.208885052147219],
              [-38.53429689408614, -8.208842459946187],
              [-38.534228126031202, -8.208799506136204],
              [-38.534161176509649, -8.208754202663789],
              [-38.534092682327618, -8.208709982819512],
              [-38.534027184194137, -8.208665766430224],
              [-38.533968222452252, -8.208621919401995],
              [-38.53390362812371, -8.20858123167138],
              [-38.533836128670977, -8.208540450116887],
              [-38.533766545097251, -8.208496228959877],
              [-38.533698233637779, -8.208451104743247],
              [-38.533634369896525, -8.20840679973422],
              [-38.533569774583292, -8.208367016473183],
              [-38.53351053339501, -8.208329319801098],
              [-38.533446304534372, -8.208286732931047],
              [-38.533382260221003, -8.208241613606667],
              [-38.533319937952825, -8.208199028926385],
              [-38.533257975052074, -8.208159700928627],
              [-38.533198918882078, -8.208119109961151],
              [-38.533142046934572, -8.208073998912594],
              [-38.533083541119929, -8.208028614606562],
              [-38.533020586354979, -8.207983496491481],
              [-38.532957626955827, -8.207942358253733],
              [-38.532896301132368, -8.207901764613432],
              [-38.5328378808765, -8.207860902977727],
              [-38.532774647670649, -8.207821030724819],
              [-38.532710868477295, -8.207782243254298],
              [-38.532652171346804, -8.207745270711817],
              [-38.532591934838322, -8.207704859196081],
              [-38.53252943074142, -8.207662635998886],
              [-38.53250911090209, -8.207648230537664],
              [-38.5324686526994, -8.207619510275073],
              [-38.532426013354765, -8.207592686970813],
              [-38.532407868959083, -8.207581268948438],
              [-38.532348446742361, -8.207543300550004],
              [-38.532290750049491, -8.20750488188628],
              [-38.532232414356145, -8.207469447391491],
              [-38.532173896143874, -8.207434826745562],
              [-38.532117920974457, -8.207399394976397],
              [-38.532061314635051, -8.207360253931608],
              [-38.532001260459367, -8.207319299832571],
              [-38.53194783004809, -8.207281609695626],
              [-38.531893313361238, -8.207241204727532],
              [-38.531837706376194, -8.207201522102189],
              [-38.531779375641257, -8.207161926756225],
              [-38.531723854701063, -8.207126314559591],
              [-38.531665341670369, -8.207087352150833],
              [-38.531607282063995, -8.207048842521605],
              [-38.531552941617669, -8.207013050771312],
              [-38.531501411596828, -8.206980699461063],
              [-38.531445165565977, -8.206944091412481],
              [-38.531386379377103, -8.206905852264725],
              [-38.531329134801034, -8.206869152587474],
              [-38.53126970919309, -8.206834259406429],
              [-38.531212464532501, -8.206797650165603],
              [-38.531151956590101, -8.206756695420294],
              [-38.531093532020954, -8.206719813434294],
              [-38.531035562889798, -8.206681665641559],
              [-38.530978048772702, -8.206642613850045],
              [-38.530917357816762, -8.206602925187621],
              [-38.530857481952374, -8.206564956055075],
              [-38.530797882709564, -8.20652336915367],
              [-38.530735651678903, -8.206480874658132],
              [-38.530674506194217, -8.206441728144476],
              [-38.530613720053232, -8.206405838315181],
              [-38.53059666437634, -8.206395145101249],
              [-38.530555295162841, -8.206369318063951],
              [-38.53051347604832, -8.206340053319938],
              [-38.530497691838072, -8.206328999775501],
              [-38.530435730793258, -8.206288585948665],
              [-38.530377221096806, -8.206247000258982],
              [-38.530321700096202, -8.206211659237519],
              [-38.530267540929614, -8.206176319796551],
              [-38.530209214119317, -8.206133648872559],
              [-38.53015106815436, -8.206091611316054],
              [-38.530090559157109, -8.206051741845254],
              [-38.530035038529078, -8.206016129431115],
              [-38.530026056978414, -8.206010601379473],
              [-38.529970355848711, -8.205974084225829],
              [-38.529909933636361, -8.205937652009077],
              [-38.529866838371532, -8.205912817864402],
              [-38.52982219716084, -8.205890152760828],
              [-38.529776205936905, -8.20585744589874],
              [-38.529731755141704, -8.205827273487278],
              [-38.529680856691357, -8.205798540778197],
              [-38.529635048768334, -8.205764386882235],
              [-38.52959214926571, -8.2057275228152],
              [-38.529543348648041, -8.205690561402022],
              [-38.529494181056357, -8.205656855828813],
              [-38.529443471351982, -8.205622063024474],
              [-38.529385954995469, -8.205585181867251],
              [-38.529331341314773, -8.205550474942202],
              [-38.529279725035082, -8.205514595635055],
              [-38.529225203545842, -8.20547871292754],
              [-38.529175673601202, -8.205444373730314],
              [-38.529126595901879, -8.205411482288705],
              [-38.529076614909449, -8.205374700347715],
              [-38.529029716673122, -8.205341359181988],
              [-38.528979371694334, -8.205305300420768],
              [-38.528928478481134, -8.205272225930406],
              [-38.528882483355794, -8.205242956146558],
              [-38.528833224449954, -8.205209793103617],
              [-38.528781878919872, -8.20517536128631],
              [-38.528730891648607, -8.205145090676004],
              [-38.528677001620601, -8.205112736267475],
              [-38.528626014045955, -8.205082737000467],
              [-38.528574936011026, -8.205052466265499],
              [-38.528522951810125, -8.205020747231426],
              [-38.528473960528608, -8.204991654802416],
              [-38.528427059723654, -8.204960574869206],
              [-38.528385509656765, -8.20493438560341],
              [-38.528345685099794, -8.204907746093003],
              [-38.5283020479879, -8.204880649855987],
              [-38.528253694709647, -8.204849477751686],
              [-38.528199171380599, -8.204815313500909],
              [-38.528157349407493, -8.20478876208659],
              [-38.528150817691895, -8.204784503192744],
              [-38.528108453345482, -8.204755961193715],
              [-38.52805168574384, -8.204700266710708],
              [-38.52801758076405, -8.204673724320413],
              [-38.528021146572485, -8.204652472267444],
              [-38.528045235351662, -8.204627083456257],
              [-38.528061832060331, -8.204564962341227],
              [-38.528051519060583, -8.204533744309773],
              [-38.528049825786226, -8.204506878070768],
              [-38.52807938028635, -8.204465847454545],
              [-38.52810793163254, -8.204428614646851],
              [-38.528113302626558, -8.204416319458305],
              [-38.528110739348868, -8.204357341725556],
              [-38.528051225222377, -8.204321091217473],
              [-38.527977434047415, -8.204304632976239],
              [-38.527967378694214, -8.204285897626317],
              [-38.52796303443472, -8.204274405128807],
              [-38.527957290084089, -8.204218408587362],
              [-38.527977547608415, -8.204208392137883],
              [-38.528037817147343, -8.204219950133055],
              [-38.528108987094221, -8.20422654602076],
              [-38.528158013997, -8.204225337095814],
              [-38.528177443118551, -8.204224907582702],
              [-38.528249343105799, -8.204228338478947],
              [-38.528306981091518, -8.204238807929441],
              [-38.528349703646143, -8.204271511119408],
              [-38.528355999497975, -8.204321809824789],
              [-38.528388369906999, -8.204356490840732],
              [-38.528440518906422, -8.204325345908588],
              [-38.528476274752087, -8.204260714531836],
              [-38.528497785751107, -8.204188558965585],
              [-38.528504033414777, -8.204125702101802],
              [-38.528538681122484, -8.204076898529445],
              [-38.528580395770163, -8.204040404698604],
              [-38.528620747101861, -8.204005175597157],
              [-38.528671535073954, -8.203973305422741],
              [-38.528716411818642, -8.203949930821874],
              [-38.528779778677929, -8.203952537573938],
              [-38.528839803650193, -8.203940487176764],
              [-38.528870198445659, -8.203879648477178],
              [-38.528901234177781, -8.203814197469038],
              [-38.528943786730139, -8.203759975973428],
              [-38.528990239307561, -8.203709015304431],
              [-38.529039686375704, -8.203659324457123],
              [-38.529095948681793, -8.20360439534665],
              [-38.529149480103811, -8.203555613776848],
              [-38.529200283732173, -8.203510356643131],
              [-38.529250109545785, -8.203447369756342],
              [-38.52929358425417, -8.203380938266797],
              [-38.529340777515145, -8.203317676936035],
              [-38.52938470485487, -8.203252331387997],
              [-38.529428803435401, -8.203195759884212],
              [-38.529475340076836, -8.203150407276906],
              [-38.529528403094574, -8.203113836131809],
              [-38.529569850720144, -8.20307263836721],
              [-38.529592618862004, -8.20301206205553],
              [-38.529594420502043, -8.202946848218112],
              [-38.529587512070179, -8.202876920707777],
              [-38.529583869224659, -8.20280934876212],
              [-38.529586307758194, -8.202742959790474],
              [-38.529603446096196, -8.202683190974511],
              [-38.529638728733403, -8.202634659386874],
              [-38.529670389433477, -8.20257798288317],
              [-38.529670741858446, -8.202509872886003],
              [-38.529667672523289, -8.202495035165233],
              [-38.529653139534659, -8.202423741976677],
              [-38.529641638668501, -8.202398944689721],
              [-38.529634031772872, -8.202382563988792],
              [-38.529579545753109, -8.202316832204021],
              [-38.529511976585681, -8.202259406733019],
              [-38.529457126176702, -8.202194759932834],
              [-38.529431022926715, -8.202157734565928],
              [-38.529384359786938, -8.202079167695823],
              [-38.529331883085554, -8.202003307595348],
              [-38.5292804799967, -8.201940926100551],
              [-38.52921815414323, -8.201902592093768],
              [-38.529129649316864, -8.201891634476057],
              [-38.529078262759668, -8.201892388518521],
              [-38.529030778884511, -8.201894142086688],
              [-38.528981472130049, -8.201901953807624],
              [-38.528934808001551, -8.20190144702064],
              [-38.528853478576409, -8.201887784165599],
              [-38.528770889853114, -8.201864170095625],
              [-38.528684565332384, -8.201852039053191],
              [-38.528611666676774, -8.201848968898188],
              [-38.528548772738382, -8.201830533580711],
              [-38.528490683709393, -8.201817892805458],
              [-38.528427951210311, -8.201816553097343],
              [-38.528370924769604, -8.201826707466379],
              [-38.528331480134561, -8.201863113495213],
              [-38.528304461299541, -8.201909755141742],
              [-38.528254478394437, -8.201952028214432],
              [-38.528182461257728, -8.201971300669799],
              [-38.528160662710413, -8.201979415840418],
              [-38.528114522395832, -8.201996638173485],
              [-38.528046134817025, -8.202017542989243],
              [-38.527982836441829, -8.2020341120538],
              [-38.527937237530111, -8.202054139013162],
              [-38.52789246615842, -8.2020653026355],
              [-38.527831484949971, -8.202041623227442],
              [-38.527803124565267, -8.201994102682294],
              [-38.527755245030271, -8.201946468817486],
              [-38.527689666688417, -8.201894834321191],
              [-38.527615734975988, -8.201844004094959],
              [-38.527539898032352, -8.201792086198536],
              [-38.527501344965316, -8.2017653576592],
              [-38.527457889927668, -8.201738171110129],
              [-38.527417153812323, -8.201714967634066],
              [-38.527373965378843, -8.201692665799502],
              [-38.527294830666989, -8.20166570875779],
              [-38.52721876883848, -8.201650514070458],
              [-38.527184274619493, -8.201646312821159],
              [-38.527137435159943, -8.20164055940379],
              [-38.527084411055554, -8.201644205738202],
              [-38.527059170184231, -8.201645894716025],
              [-38.526981353583359, -8.201655934045057],
              [-38.526898545394644, -8.20166452026834],
              [-38.526817475422909, -8.201661892461887],
              [-38.52674405778626, -8.201637112873833],
              [-38.526661844608952, -8.201603187290013],
              [-38.526619925328319, -8.201582515004345],
              [-38.526574095783211, -8.201567265244554],
              [-38.526527899347407, -8.201555180870077],
              [-38.526443116978498, -8.201544227042572],
              [-38.526365138406391, -8.20153771334077],
              [-38.526319117452999, -8.201530784914889],
              [-38.52626754081659, -8.201538774540454],
              [-38.526213614314521, -8.201537806646691],
              [-38.526186154749553, -8.201496437795985],
              [-38.526168979091665, -8.201434005707856],
              [-38.526143449738257, -8.201372649224636],
              [-38.52608730671291, -8.201326814503025],
              [-38.526020076211232, -8.20129064510596],
              [-38.525960364228766, -8.201268685507555],
              [-38.525907455176949, -8.201251889666313],
              [-38.525879645695611, -8.20119939478603],
              [-38.525849845550383, -8.201141289537272],
              [-38.525806329494927, -8.201089137988006],
              [-38.525748728596554, -8.20104782410959],
              [-38.525707353504082, -8.20102769508663],
              [-38.52564336054423, -8.201017579623146],
              [-38.525571347982982, -8.201033052635591],
              [-38.525498773105902, -8.201063359104923],
              [-38.525430902197229, -8.201107962517321],
              [-38.52536820356157, -8.201154742855227],
              [-38.525307593550593, -8.201201073381625],
              [-38.525275944068099, -8.201247890261126],
              [-38.525298007810783, -8.201322358254007],
              [-38.52533687704797, -8.201388524447212],
              [-38.525381943853084, -8.201434436681076],
              [-38.525420560276487, -8.201484140301833],
              [-38.525418046698455, -8.201536689966852],
              [-38.52539820081757, -8.201582435325633],
              [-38.525356933880765, -8.201624175573826],
              [-38.525310594012552, -8.201656502833913],
              [-38.525247016162439, -8.201679040970062],
              [-38.525165559992637, -8.201695859560683],
              [-38.525094284863272, -8.20170183588651],
              [-38.52502663241598, -8.201715233533093],
              [-38.524969802108743, -8.201713086174228],
              [-38.52491352425767, -8.201704426913448],
              [-38.524860859531024, -8.201711239197673],
              [-38.524806353817063, -8.201739305527706],
              [-38.524778555965668, -8.201753564177324],
              [-38.524728008772868, -8.201735595088913],
              [-38.524652853209098, -8.201721848222761],
              [-38.524562162619695, -8.201716856866517],
              [-38.524515310632047, -8.201721686016361],
              [-38.524464277703728, -8.201730490117125],
              [-38.524416518917143, -8.201734413665545],
              [-38.524367576241175, -8.201741411179665],
              [-38.524274603823791, -8.201746638143076],
              [-38.524186062015517, -8.201766975799016],
              [-38.524141289519996, -8.20177904351951],
              [-38.524064172899607, -8.201811153394617],
              [-38.523986816939242, -8.20181549420726],
              [-38.52390374999441, -8.201812592100151],
              [-38.523827043213934, -8.201805446244824],
              [-38.523744248205169, -8.201802906234288],
              [-38.523693767299484, -8.201805560152257],
              [-38.523646381879672, -8.201800891109933],
              [-38.523598444738468, -8.201802100789847],
              [-38.523550964871895, -8.201800506992617],
              [-38.523473712088197, -8.201794445849142],
              [-38.523399895323017, -8.201800057060538],
              [-38.523324434905263, -8.201813626090033],
              [-38.523250880798578, -8.201827378263582],
              [-38.52317949954427, -8.201846198305043],
              [-38.523109570232172, -8.201865562763043],
              [-38.523027470793522, -8.201888802244552],
              [-38.522959808344375, -8.201910521109562],
              [-38.522905945435788, -8.201932346746876],
              [-38.522853814165835, -8.201948566403187],
              [-38.522793517544514, -8.201960163335148],
              [-38.522727580784895, -8.201981251038688],
              [-38.522653454933526, -8.202017615419221],
              [-38.522577320634305, -8.202063293960691],
              [-38.522514884397765, -8.202118033910628],
              [-38.522448627738832, -8.202178920058508],
              [-38.522381280317013, -8.202240890325424],
              [-38.522344287031963, -8.202276755927482],
              [-38.522288581516847, -8.202321011384315],
              [-38.52223968395662, -8.202366179421352],
              [-38.522190588716676, -8.202424824561858],
              [-38.522165934694982, -8.202467217313821],
              [-38.522163042080628, -8.202532701114801],
              [-38.522167161262622, -8.202580464640537],
              [-38.522138690918375, -8.202625566425025],
              [-38.522096588418194, -8.202682410801192],
              [-38.522052024814116, -8.202747483383446],
              [-38.52200510418097, -8.202809658701476],
              [-38.521951756215934, -8.202856268651253],
              [-38.521896050143802, -8.202900885852776],
              [-38.521834452281851, -8.202937626735027],
              [-38.521779751658649, -8.2029765466377],
              [-38.521729481552192, -8.203030577268031],
              [-38.521676113628565, -8.203093830315472],
              [-38.521638726877498, -8.203154931474661],
              [-38.521610147040917, -8.20321559082295],
              [-38.521588020137727, -8.203270378450236],
              [-38.52156581033686, -8.203318562984085],
              [-38.521529260538628, -8.203363021962655],
              [-38.521507603563833, -8.20340442325142],
              [-38.52152179413627, -8.203457173592316],
              [-38.521529197930931, -8.203491282771706],
              [-38.521488573058207, -8.203527053518977],
              [-38.521476029446283, -8.203539520997094],
              [-38.521481051339933, -8.203591536837447],
              [-38.521490347607376, -8.203637407019057],
              [-38.52148743915933, -8.203640117124843],
              [-38.521447544787627, -8.203672361107463],
              [-38.521415912512879, -8.203704253088906],
              [-38.521407773011056, -8.203753901549577],
              [-38.521408215911741, -8.203763128175449],
              [-38.521411051969558, -8.203820930345438],
              [-38.521441236094851, -8.203861217309667],
              [-38.521451750688087, -8.20387524984854],
              [-38.521510610299217, -8.203926786899165],
              [-38.521570022115654, -8.203972083412184],
              [-38.521617268615472, -8.204017184573091],
              [-38.521662328434637, -8.204068795674845],
              [-38.521697865075389, -8.204112435697354],
              [-38.521732405762727, -8.204153813235958],
              [-38.521736562531402, -8.204170099522162],
              [-38.521733972893799, -8.204209895312291],
              [-38.521723829706872, -8.204264878067585],
              [-38.521713729611349, -8.204359750188328],
              [-38.521696978918925, -8.204397991487509],
              [-38.521646889932967, -8.204376314017509],
              [-38.521606051350112, -8.204362969102919],
              [-38.521599220280244, -8.20430543354745],
              [-38.521564249131735, -8.204244427411421],
              [-38.521494487371264, -8.204199209059201],
              [-38.521418449481295, -8.204163842506265],
              [-38.521352048303427, -8.204117452246182],
              [-38.521287731793969, -8.204073958914812],
              [-38.521215567505322, -8.204063923474513],
              [-38.521178385439605, -8.204105577651859],
              [-38.521197092203778, -8.204177690085457],
              [-38.521241515494665, -8.204230114543233],
              [-38.521273503369578, -8.204280443829832],
              [-38.521291233249293, -8.204334374256366],
              [-38.52133093127744, -8.204390411170777],
              [-38.521357843395123, -8.20443376962219],
              [-38.521390671116627, -8.204465104987383],
              [-38.521414703900859, -8.204486841998229],
              [-38.521430165411672, -8.204539684302384],
              [-38.521491200557165, -8.204594118405677],
              [-38.521478190094626, -8.204617349109871],
              [-38.521448619458823, -8.204671313813703],
              [-38.521403353993684, -8.204715671968072],
              [-38.521351043543319, -8.204729539440338],
              [-38.521299534267797, -8.204756704294461],
              [-38.521269591677303, -8.20481799514925],
              [-38.521254974144973, -8.204894409684023],
              [-38.52123437976369, -8.204958153843386],
              [-38.521204633230809, -8.205007324362731],
              [-38.521187772399188, -8.205061665951712],
              [-38.521178159652443, -8.205128498521356],
              [-38.521167228720813, -8.205158877387968],
              [-38.521148851661891, -8.205190513733099],
              [-38.521145444753905, -8.205230398994541],
              [-38.5211220652184, -8.205193828623269],
              [-38.521081903894071, -8.205145570004692],
              [-38.52106372451447, -8.205087930506494],
              [-38.521044197726425, -8.205018259296859],
              [-38.521014405741951, -8.204953731646214],
              [-38.520977979537612, -8.204894804128397],
              [-38.52092156127771, -8.204851501027957],
              [-38.520852696212835, -8.204815600180828],
              [-38.520773191430209, -8.20479433988535],
              [-38.520699647221953, -8.204799317728536],
              [-38.520654956198598, -8.204818802191326],
              [-38.52060889728299, -8.204843350330579],
              [-38.52055929134805, -8.204873140456893],
              [-38.520503779967164, -8.204906451175667],
              [-38.520455539304955, -8.204933348450609],
              [-38.520414190163123, -8.2049672187207],
              [-38.520367396019978, -8.204999183014555],
              [-38.520319694316214, -8.205030874866253],
              [-38.520275968829772, -8.205077948275209],
              [-38.520257573806397, -8.205124509124625],
              [-38.520240928720845, -8.205074559837414],
              [-38.52024023181869, -8.205050046532236],
              [-38.520257998485121, -8.204997515107326],
              [-38.520252355766488, -8.204933377964242],
              [-38.520210468305557, -8.204886202633887],
              [-38.520162650783234, -8.204863080454411],
              [-38.520212781616195, -8.20484984370837],
              [-38.520268791860914, -8.204779086520912],
              [-38.520290298707813, -8.204711634975128],
              [-38.520314069384256, -8.204649070527099],
              [-38.520346015182291, -8.20458298818169],
              [-38.520375332353957, -8.204513375078713],
              [-38.520394214346929, -8.204439226956877],
              [-38.520383125429987, -8.204374178812131],
              [-38.520370407434449, -8.204304877486951],
              [-38.520344786853016, -8.204244515547551],
              [-38.520312709073394, -8.20419355292321],
              [-38.520296983929178, -8.204133564567034],
              [-38.520278011693421, -8.204055843764211],
              [-38.520265271703664, -8.204004904154706],
              [-38.520253549688846, -8.203937865310525],
              [-38.520231472357572, -8.20387542718815],
              [-38.520198142035554, -8.203809086234712],
              [-38.52014717593304, -8.203761900094049],
              [-38.520093030758474, -8.203715976489264],
              [-38.520059123626716, -8.203676408614195],
              [-38.520024852700303, -8.203637383015002],
              [-38.520015569733992, -8.203580568139763],
              [-38.520031060913219, -8.203532827962107],
              [-38.519986093678341, -8.203479950474897],
              [-38.519952358491864, -8.203448433019815],
              [-38.519900743600061, -8.203412371667531],
              [-38.519845739293096, -8.203401542362977],
              [-38.519784955337407, -8.203440726088134],
              [-38.519736504126769, -8.203416246333447],
              [-38.519694953966301, -8.203390779825822],
              [-38.519644216681527, -8.203379955579141],
              [-38.519572533341602, -8.203347578798169],
              [-38.519520419263273, -8.203349235262669],
              [-38.519464025787983, -8.203361198139165],
              [-38.519404718526346, -8.203380303236059],
              [-38.519351581005431, -8.203402581499333],
              [-38.519295464772007, -8.203410474345061],
              [-38.519225737043392, -8.203412833378669],
              [-38.51917979857263, -8.203412778578315],
              [-38.519216598920927, -8.203386681869304],
              [-38.51924551325336, -8.203350173777959],
              [-38.519277334409736, -8.203312312372171],
              [-38.519310600878093, -8.203280512966751],
              [-38.519347954080722, -8.203247633008568],
              [-38.519385945071249, -8.203212854316396],
              [-38.519409780494094, -8.203171998462116],
              [-38.519432260076059, -8.203126166133027],
              [-38.519460042043328, -8.203049767368789],
              [-38.519447319566559, -8.202984355449324],
              [-38.519410342737814, -8.202930673363186],
              [-38.519379280571258, -8.202865782286434],
              [-38.519352102385618, -8.202817448543733],
              [-38.519355934796195, -8.202801352668768],
              [-38.519406565499928, -8.202749674530683],
              [-38.519425017928654, -8.202730882527964],
              [-38.51946601581519, -8.202686971763224],
              [-38.519467381210028, -8.202683988476961],
              [-38.519495886392129, -8.202610123222957],
              [-38.519500776981019, -8.202544460943789],
              [-38.519501921066137, -8.202498964981293],
              [-38.519497948693349, -8.202480417598107],
              [-38.51948855819569, -8.202437622635955],
              [-38.519476185950253, -8.202382974914785],
              [-38.519465636730914, -8.20232226908648],
              [-38.519455261810464, -8.202267623745779],
              [-38.519436620149413, -8.202217219790867],
              [-38.519422609726846, -8.202165916837126],
              [-38.519452310180718, -8.202155098011437],
              [-38.519533254792577, -8.202186490870442],
              [-38.519582176071083, -8.202197403410636],
              [-38.519636553490258, -8.2022009053875],
              [-38.519689476035168, -8.202206305114366],
              [-38.519739587760526, -8.202208716571421],
              [-38.519791697999928, -8.202210135431617],
              [-38.519873084056144, -8.202176312896402],
              [-38.519946211935526, -8.202139586047082],
              [-38.520021039261351, -8.202124117415362],
              [-38.520089467432626, -8.202145364642991],
              [-38.520168345253552, -8.202159026334179],
              [-38.520245344854601, -8.202149077815381],
              [-38.52031191866984, -8.202126815409692],
              [-38.520354542761645, -8.202089419020108],
              [-38.520363433256456, -8.202019238882039],
              [-38.52034944494563, -8.201949393329377],
              [-38.52032318660207, -8.20189102057442],
              [-38.520292370474351, -8.201848019226569],
              [-38.520297697936549, -8.201796467952128],
              [-38.520347239921072, -8.201743974316203],
              [-38.520386034429166, -8.201720322060776],
              [-38.520445926340315, -8.201667659820872],
              [-38.520474749211694, -8.201631513323919],
              [-38.520521506557195, -8.201554142062767],
              [-38.520564543239736, -8.201475409590747],
              [-38.520595049340891, -8.201398290347324],
              [-38.520581792827414, -8.201323832619773],
              [-38.520521293688532, -8.201277177911084],
              [-38.520440150683875, -8.201259895524656],
              [-38.520351182332327, -8.201257347481247],
              [-38.520266827205269, -8.201268915413038],
              [-38.520192998271582, -8.201284747096643],
              [-38.520130147837968, -8.20130619985555],
              [-38.520084743152125, -8.201315462358632],
              [-38.520030449689173, -8.201317749473647],
              [-38.519995185721918, -8.201274200098997],
              [-38.519955940371354, -8.201219610842655],
              [-38.519916244568684, -8.201162126584089],
              [-38.519897084538961, -8.20108974220569],
              [-38.519872300390446, -8.201013461694725],
              [-38.519829356149764, -8.200939420853004],
              [-38.51978167363842, -8.200879846675917],
              [-38.519717901558742, -8.200837167842115],
              [-38.519641856541448, -8.200808403976932],
              [-38.519555079787395, -8.200795365948689],
              [-38.519478895457915, -8.2008070339088],
              [-38.519403605090154, -8.200830280769162],
              [-38.519332665316, -8.200859502630211],
              [-38.519267361926929, -8.200882399562879],
              [-38.519198612974677, -8.200901674297651],
              [-38.519130039612541, -8.200925924086649],
              [-38.519063924881515, -8.200944026064251],
              [-38.518995252020822, -8.200975511866005],
              [-38.518923033348685, -8.201011244665601],
              [-38.518847001286019, -8.201047244256108],
              [-38.51878703502129, -8.201086157470108],
              [-38.518736249215323, -8.201115945987913],
              [-38.518691359659677, -8.201149811875325],
              [-38.518642678694533, -8.201165673295122],
              [-38.518598686618667, -8.20113269626256],
              [-38.51855378189255, -8.201103698020844],
              [-38.518508686022287, -8.201082659316468],
              [-38.518459018375381, -8.20108856982308],
              [-38.518434144382631, -8.201087183333236],
              [-38.518453139100416, -8.201070381961951],
              [-38.518484435002584, -8.201016238632766],
              [-38.518519627769095, -8.200967888878292],
              [-38.51857025158256, -8.200921818845439],
              [-38.518619323302943, -8.200882983107252],
              [-38.518664668943309, -8.200847308731818],
              [-38.518702930913477, -8.200813525385175],
              [-38.518747290174005, -8.200767628752798],
              [-38.51876506083407, -8.200711750665953],
              [-38.51877503186715, -8.200649169824878],
              [-38.518790171003744, -8.200592203171855],
              [-38.518797213032705, -8.200549427808737],
              [-38.518790785003532, -8.200534586018502],
              [-38.51879411727824, -8.200481404254321],
              [-38.518828123313433, -8.200438389715064],
              [-38.518857310572564, -8.200401248798284],
              [-38.518900756137278, -8.200360145007142],
              [-38.51895777981192, -8.200352253274101],
              [-38.519030678672962, -8.200354782444276],
              [-38.519112481814872, -8.200351352393131],
              [-38.519189562086979, -8.200349544828359],
              [-38.519271998019747, -8.200348376798038],
              [-38.519318487704204, -8.200342733752295],
              [-38.519405951667558, -8.200312627064958],
              [-38.51944764680664, -8.200292686880866],
              [-38.519488985527609, -8.200267138246552],
              [-38.519547511935677, -8.200217459417768],
              [-38.519597951902043, -8.200173107610125],
              [-38.519646496191349, -8.20011970834166],
              [-38.519677246592444, -8.200066016513908],
              [-38.519714519084758, -8.200024453014098],
              [-38.519760240594998, -8.199978015167845],
              [-38.519800882813797, -8.199927591380629],
              [-38.51982627556982, -8.199874888129958],
              [-38.519823024611142, -8.199860411945423],
              [-38.519811735850382, -8.199810740377474],
              [-38.519798991141265, -8.199763871085983],
              [-38.519795465979414, -8.199750932257931],
              [-38.51976410555929, -8.199707749325638],
              [-38.519700854068027, -8.199685332355505],
              [-38.519636216794069, -8.199682903607199],
              [-38.519578113663236, -8.199682563028684],
              [-38.519527524977597, -8.199699598170993],
              [-38.519478554702118, -8.199729750768576],
              [-38.519433035290191, -8.199759093405023],
              [-38.519369631549161, -8.19978787200685],
              [-38.519299865236391, -8.199822884197589],
              [-38.519227108166476, -8.199853551115883],
              [-38.519155343219701, -8.199889646320459],
              [-38.51908666870348, -8.19992266983119],
              [-38.519022541446574, -8.199949095761751],
              [-38.518973021927692, -8.199982956168355],
              [-38.518934471394452, -8.200030306995203],
              [-38.518908252952073, -8.200090064448577],
              [-38.518889032148437, -8.200143950873038],
              [-38.51885965811941, -8.200185433264231],
              [-38.518811421751963, -8.200209164561722],
              [-38.518754073668141, -8.20018485499663],
              [-38.518703821315846, -8.200148614237593],
              [-38.518667922958429, -8.200104340385588],
              [-38.518683149064486, -8.200050539656491],
              [-38.51868005343713, -8.199982425649077],
              [-38.518648801208663, -8.1999248609067],
              [-38.518617090595122, -8.199871004144555],
              [-38.518594021694838, -8.199803047194909],
              [-38.518548335066946, -8.199744922500594],
              [-38.518495548160978, -8.199702527960754],
              [-38.518457827711785, -8.199663588581316],
              [-38.518432746851985, -8.19960784023316],
              [-38.518411943090399, -8.199543684961498],
              [-38.518389208002439, -8.199500150424669],
              [-38.518348481175892, -8.199469890831775],
              [-38.518287116628791, -8.199463847701111],
              [-38.518241164138352, -8.199475822910605],
              [-38.518181553391365, -8.199445812095741],
              [-38.518124943837385, -8.199411463166069],
              [-38.518061060913482, -8.199385788921978],
              [-38.517998807145936, -8.199364277404634],
              [-38.517937535806439, -8.199356244390817],
              [-38.51789647141387, -8.199380165060125],
              [-38.517863629044335, -8.199436929607762],
              [-38.517829425956023, -8.199492788005598],
              [-38.517796968071359, -8.199531824427423],
              [-38.517747075774608, -8.199573644005261],
              [-38.51770203199677, -8.199584896623007],
              [-38.517685960261154, -8.199586957793878],
              [-38.517639833363475, -8.199592962896972],
              [-38.517585001198285, -8.199590455098557],
              [-38.517573018020848, -8.199589898050379],
              [-38.517532307061245, -8.199546522867392],
              [-38.517526940656403, -8.199479491566933],
              [-38.517517033180155, -8.19941390206372],
              [-38.517508672784899, -8.1993452390457],
              [-38.517486860393284, -8.199289494557281],
              [-38.517452600549333, -8.199241785394447],
              [-38.517412415598983, -8.19921396856266],
              [-38.517370506801292, -8.19918524514463],
              [-38.517314318569817, -8.199178032189387],
              [-38.517260116299056, -8.199180228604286],
              [-38.517212799510965, -8.199194282457947],
              [-38.517226714413646, -8.199249203436667],
              [-38.517258629925159, -8.199283522925523],
              [-38.517294259945523, -8.199324268945436],
              [-38.517269420132529, -8.199369917432831],
              [-38.517232701893121, -8.199403431139137],
              [-38.517197334099379, -8.199446353458882],
              [-38.517170044666393, -8.19949064222693],
              [-38.517146907622887, -8.199554654484501],
              [-38.517119171118161, -8.199593244241621],
              [-38.517103391813031, -8.199653918585575],
              [-38.517086347164721, -8.199709887913018],
              [-38.517043910084382, -8.199742761586398],
              [-38.516994027838031, -8.199776169048075],
              [-38.516953408222847, -8.199807597657356],
              [-38.516931191872608, -8.199860937674908],
              [-38.516925220980191, -8.199918729298256],
              [-38.516944960292314, -8.19996198896435],
              [-38.516974960796709, -8.200003361431783],
              [-38.517002242945651, -8.20004038894888],
              [-38.517043063451645, -8.200068206584788],
              [-38.517072355033449, -8.200095196341319],
              [-38.517115030452274, -8.200165799950797],
              [-38.517114064636068, -8.200213828764065],
              [-38.517110098709523, -8.200265291157136],
              [-38.517116121944497, -8.200314594667468],
              [-38.517118112159622, -8.200320476425818],
              [-38.517103057990361, -8.200532568147699],
              [-38.51708887047274, -8.200553083740528],
              [-38.517041845384718, -8.200626203015435],
              [-38.517003906059323, -8.200693453793704],
              [-38.517003396669899, -8.200739402757334],
              [-38.517026134394072, -8.200780676070385],
              [-38.517056771999869, -8.200820782970434],
              [-38.517095212918001, -8.200864517291764],
              [-38.517126665108819, -8.200906705556902],
              [-38.517155112062014, -8.200956578633743],
              [-38.517174119037719, -8.201004902721069],
              [-38.517114564436746, -8.201078730610419],
              [-38.517058013891955, -8.201070612652122],
              [-38.516995302175012, -8.201052266238239],
              [-38.516934664478832, -8.201045590596975],
              [-38.51687357066664, -8.20104072343843],
              [-38.516814835241398, -8.201037577683577],
              [-38.516769503952169, -8.201061221752852],
              [-38.51671872161883, -8.201088025099297],
              [-38.516671307353654, -8.201107415419996],
              [-38.516631951817232, -8.201144453519642],
              [-38.516608738213669, -8.201196525986841],
              [-38.516594331048687, -8.201248518559996],
              [-38.51656259110667, -8.201294249166187],
              [-38.516544466910325, -8.201341895587861],
              [-38.516531143164364, -8.201398864311775],
              [-38.516526811808845, -8.20145213529104],
              [-38.51653681135997, -8.201516549041322],
              [-38.516559723982226, -8.201563340154955],
              [-38.516583005002779, -8.201605790017691],
              [-38.51660410702948, -8.201648327718273],
              [-38.516607098631702, -8.201651949381828],
              [-38.516636287704777, -8.201688707856045],
              [-38.516647858190758, -8.201730329606916],
              [-38.516666195428826, -8.201806964359108],
              [-38.516616101154504, -8.201865336226355],
              [-38.516613414568695, -8.201909835342974],
              [-38.516583306465805, -8.201882301859175],
              [-38.516518346502188, -8.201846133644294],
              [-38.516447832907694, -8.201822893380363],
              [-38.51636038022955, -8.201843230600284],
              [-38.516340396746791, -8.201776634023757],
              [-38.516310453410007, -8.201763030311831],
              [-38.516247315794644, -8.201721437127949],
              [-38.516180998349128, -8.201681739608505],
              [-38.516116673529012, -8.201645933902357],
              [-38.516077718449388, -8.201576962793473],
              [-38.51605833979621, -8.201535783915872],
              [-38.51601749275919, -8.201454780428001],
              [-38.515976092044831, -8.201381193327753],
              [-38.515925623261488, -8.201298821501187],
              [-38.515895772545825, -8.201208695564381],
              [-38.515879264894608, -8.201120756494287],
              [-38.515886775622498, -8.201066042113204],
              [-38.515886469373591, -8.201019006756798],
              [-38.515890725775243, -8.200952710623978],
              [-38.515895690853775, -8.200900978098032],
              [-38.515902203574541, -8.200845719805905],
              [-38.51591225019677, -8.200796073774505],
              [-38.515908045251308, -8.200744782496777],
              [-38.515885310492799, -8.20070115738144],
              [-38.515830902299982, -8.200648268136119],
              [-38.515755898649836, -8.200584499920177],
              [-38.51571998472712, -8.200553341329906],
              [-38.515693956062108, -8.200530877993549],
              [-38.51567790354769, -8.20051701957005],
              [-38.515649627549095, -8.200476101343732],
              [-38.515607723907152, -8.200443126510839],
              [-38.515523328126122, -8.200413356885644],
              [-38.515513435982484, -8.200410360086327],
              [-38.515498824679476, -8.20040591038558],
              [-38.515452335945106, -8.200410738921383],
              [-38.515350237281588, -8.200530464971262],
              [-38.515341960567994, -8.20054293738384],
              [-38.515300151759362, -8.200581600535635],
              [-38.515254084289239, -8.200613384218423],
              [-38.515201848458695, -8.20064090923935],
              [-38.515158508852437, -8.200669168566559],
              [-38.515103476261544, -8.200682127455929],
              [-38.515052799242753, -8.200696900614362],
              [-38.514996950879436, -8.20070877308482],
              [-38.514941660499346, -8.200709701539902],
              [-38.514896865976965, -8.200739858576631],
              [-38.514861499243608, -8.200781604794322],
              [-38.514830942560778, -8.200824713575127],
              [-38.514783866282663, -8.200864817559751],
              [-38.514746503860472, -8.200905204585668],
              [-38.514713493826008, -8.200950028989027],
              [-38.514693083197081, -8.201011330799142],
              [-38.514679574559686, -8.201070831878626],
              [-38.514661160901419, -8.201132407445757],
              [-38.514647915509656, -8.201199416346661],
              [-38.514637128491181, -8.201260548833917],
              [-38.514615000011275, -8.201315969196974],
              [-38.514598412382426, -8.20136904448073],
              [-38.514593896564222, -8.201424486047802],
              [-38.51459536873584, -8.201483191092276],
              [-38.514605474998859, -8.201534218122545],
              [-38.514616761056388, -8.201585608380649],
              [-38.514628863980278, -8.201637180525665],
              [-38.51464587401486, -8.201684959599351],
              [-38.514659706945302, -8.201732373039929],
              [-38.514668992297764, -8.201786564896391],
              [-38.514679828931293, -8.201834246084656],
              [-38.514697835113864, -8.201884106749102],
              [-38.514703026607599, -8.201945258471218],
              [-38.514703766462247, -8.202008937486713],
              [-38.514691987017883, -8.202064913020227],
              [-38.514692737072444, -8.202120180022611],
              [-38.514708746718092, -8.202169405119845],
              [-38.514706766028752, -8.202230548206799],
              [-38.514686464979043, -8.202276292417482],
              [-38.514669603853541, -8.202330271889506],
              [-38.514639292283377, -8.202395722553863],
              [-38.514618242205636, -8.202460189396726],
              [-38.514581086715346, -8.202404697653014],
              [-38.514535432935155, -8.202394602514383],
              [-38.514489130476612, -8.202395360814638],
              [-38.514459088256238, -8.202388450286312],
              [-38.514427610456003, -8.20236751798563],
              [-38.514471386784635, -8.202353641127385],
              [-38.514522210901568, -8.202292647323944],
              [-38.514519796385287, -8.20218753933343],
              [-38.514494706183086, -8.202139840975413],
              [-38.514467711981304, -8.202090059929523],
              [-38.514424211544345, -8.202026238958446],
              [-38.514354929122185, -8.201961120597556],
              [-38.514312020640354, -8.201933209724158],
              [-38.51426856864996, -8.201904303220912],
              [-38.514244649283278, -8.20186438512148],
              [-38.51420565289952, -8.201829695060928],
              [-38.514165654899827, -8.201797445990511],
              [-38.514124843227869, -8.201762391927529],
              [-38.5141201258159, -8.201759491781758],
              [-38.514034485346862, -8.201707921435329],
              [-38.513976423281925, -8.201673570177965],
              [-38.513918900675449, -8.20164356125313],
              [-38.513862173953044, -8.201631010491688],
              [-38.513797173527472, -8.20162839944984],
              [-38.513716413425733, -8.20159510620333],
              [-38.513673380508642, -8.201520250595424],
              [-38.513652186922165, -8.201478617167504],
              [-38.513620460402528, -8.201438327809077],
              [-38.51359472373958, -8.201399764260991],
              [-38.513566909536692, -8.201352514825206],
              [-38.513537820804501, -8.20130815830972],
              [-38.513504091168983, -8.201272660475228],
              [-38.513448503226549, -8.201219588596084],
              [-38.513425224218892, -8.20117578179617],
              [-38.513406502708015, -8.201117055937505],
              [-38.513387496523123, -8.201068460343818],
              [-38.513371211308943, -8.201022038878609],
              [-38.513345931929479, -8.200980762312643],
              [-38.513302406598036, -8.200937654681965],
              [-38.513252986622177, -8.200889203270252],
              [-38.513166508797866, -8.200854546242459],
              [-38.513116510014676, -8.200834134195018],
              [-38.513058871827184, -8.20082465759068],
              [-38.512990866884401, -8.200829188495069],
              [-38.512929568936798, -8.200842953580791],
              [-38.512868917943678, -8.200847312444834],
              [-38.51280709134587, -8.200847870900809],
              [-38.512747064516383, -8.200861547042418],
              [-38.512685693173118, -8.200861020607547],
              [-38.512622845888309, -8.200879668173428],
              [-38.512555477655219, -8.200883204802718],
              [-38.512493985883935, -8.200907009742689],
              [-38.51243485897151, -8.200927199458489],
              [-38.512385429273031, -8.20096133047975],
              [-38.512343888543334, -8.201003249896386],
              [-38.512316043614156, -8.201055859194451],
              [-38.512279219485364, -8.201101311931035],
              [-38.51222353218283, -8.201129917796862],
              [-38.512174611396446, -8.201118913930582],
              [-38.512124722061628, -8.201083215530813],
              [-38.512071641079601, -8.201059091099603],
              [-38.512000026460257, -8.201045707998638],
              [-38.511933590337442, -8.201029346243335],
              [-38.51186839555816, -8.201037317527749],
              [-38.511805198308387, -8.201045110316681],
              [-38.511758948954984, -8.201076893360121],
              [-38.511731203782062, -8.201122085682206],
              [-38.511716523525841, -8.201174168166249],
              [-38.511704470456564, -8.201230866875548],
              [-38.511693973122505, -8.201277436861766],
              [-38.511689768398483, -8.201375210214065],
              [-38.511680159942514, -8.20143733899331],
              [-38.511672997984526, -8.201503360164818],
              [-38.511650227618681, -8.20156366396219],
              [-38.511632365593314, -8.201619179756154],
              [-38.511627758574249, -8.201674621155039],
              [-38.511528237257927, -8.201689877390645],
              [-38.511472068155371, -8.201666834558806],
              [-38.51142225116682, -8.201646241617805],
              [-38.511370534836303, -8.201619766999158],
              [-38.51128978225767, -8.201580503465861],
              [-38.511215463054334, -8.201625820186369],
              [-38.511193623022869, -8.201667672906339],
              [-38.511175398555309, -8.201722555073527],
              [-38.511156542661787, -8.201774089755801],
              [-38.511130237364874, -8.201829595283684],
              [-38.511116272135069, -8.201890814240553],
              [-38.511107030258408, -8.201950048984553],
              [-38.511076373904601, -8.20200003166851],
              [-38.511053614912917, -8.20205092844698],
              [-38.511019336014201, -8.20209358013309],
              [-38.510987678437282, -8.202145641986347],
              [-38.510964737961572, -8.202196448086271],
              [-38.510938166879576, -8.20224652616502],
              [-38.510916568075977, -8.202313796122306],
              [-38.510880774274973, -8.20225884843043],
              [-38.510823050255624, -8.202170949657235],
              [-38.510696786664496, -8.202079168711112],
              [-38.510650095781322, -8.202100639569277],
              [-38.510617636672009, -8.202139946709885],
              [-38.510593700479937, -8.202188309377851],
              [-38.510571755623751, -8.202241558860413],
              [-38.510544643308663, -8.202288741794478],
              [-38.510527502854906, -8.202347966927809],
              [-38.5105278849787, -8.202406670626821],
              [-38.510513994596145, -8.202480824256538],
              [-38.510475477224453, -8.20242587322417],
              [-38.510423311025285, -8.202396051218939],
              [-38.510381867124131, -8.202358553826246],
              [-38.510329830139071, -8.202297345183913],
              [-38.510301739378903, -8.202253803732322],
              [-38.510270106710124, -8.202211252946011],
              [-38.510232576319332, -8.202165890985439],
              [-38.510160869812346, -8.202079151011112],
              [-38.510113170188376, -8.202034681201519],
              [-38.510081800061251, -8.202000180933346],
              [-38.510019691566512, -8.201934256520207],
              [-38.510015427344982, -8.201931990041599],
              [-38.509987741177227, -8.201928790574692],
              [-38.509930190176298, -8.201922208082971],
              [-38.509887867467761, -8.201935181691091],
              [-38.509872246226536, -8.201939956644789],
              [-38.509824269787735, -8.201973275031081],
              [-38.509759969182284, -8.201992282171911],
              [-38.509699572303404, -8.202011384502022],
              [-38.509637990088677, -8.202034646164323],
              [-38.509574154820086, -8.202044337303468],
              [-38.509523851919916, -8.202049884114309],
              [-38.509468717234775, -8.20207179681746],
              [-38.509394238135158, -8.202099203530485],
              [-38.509441175189743, -8.202025090165991],
              [-38.509471548514867, -8.201983971551702],
              [-38.509488465617167, -8.201958936984267],
              [-38.50953373299285, -8.201913766155689],
              [-38.509583273666685, -8.201863444766452],
              [-38.509633460810612, -8.201804169426895],
              [-38.509677030464701, -8.201736745367581],
              [-38.509720501559123, -8.201675652809945],
              [-38.509773214736647, -8.201629405583878],
              [-38.509828657161229, -8.201578548625871],
              [-38.509876844704074, -8.201521079856073],
              [-38.50992050141641, -8.201456640781338],
              [-38.509963520279925, -8.201394100413896],
              [-38.510007083629546, -8.201331741606191],
              [-38.510049466846603, -8.201269290906829],
              [-38.510090311102658, -8.201203220258897],
              [-38.510137232195724, -8.201141950936437],
              [-38.510185604645848, -8.201081678340916],
              [-38.510220821183047, -8.201014696321105],
              [-38.510252137638595, -8.200944634197533],
              [-38.510287541037975, -8.20087331070922],
              [-38.510315869474617, -8.200796732417681],
              [-38.510341021912417, -8.200718883940381],
              [-38.510371075827798, -8.200641855479605],
              [-38.510397862736561, -8.200563737626352],
              [-38.510411710341607, -8.200524498346534],
              [-38.510425375374503, -8.200486072910785],
              [-38.510447352889443, -8.200406049724117],
              [-38.510474395087485, -8.200341861755563],
              [-38.510510992996544, -8.200258780951918],
              [-38.510553028685827, -8.200183395150786],
              [-38.510591333258645, -8.200115240962168],
              [-38.510619011028616, -8.200050963300148],
              [-38.510644676222434, -8.19999916554659],
              [-38.510669160520173, -8.199947909068801],
              [-38.510659327705696, -8.199896430039647],
              [-38.510633960990205, -8.199852620564085],
              [-38.510572051961539, -8.19984648514993],
              [-38.510517548274812, -8.19987237866645],
              [-38.510461772794862, -8.199898903793949],
              [-38.510409194090165, -8.199909694175568],
              [-38.510352692810322, -8.199861595642286],
              [-38.51038050022165, -8.199765569560197],
              [-38.510379317396009, -8.199693478010838],
              [-38.51036677895317, -8.199627342493633],
              [-38.510310436714789, -8.199597696323936],
              [-38.510234971657127, -8.199615152326398],
              [-38.510176263798904, -8.199663925005023],
              [-38.510128791979618, -8.199730258980376],
              [-38.510075688942571, -8.199798576047224],
              [-38.510019326982899, -8.199859200740008],
              [-38.50996551544668, -8.199912954184327],
              [-38.509914060802998, -8.199969604943723],
              [-38.509843605714323, -8.200047488777686],
              [-38.50980258263386, -8.200111388347633],
              [-38.509753562671683, -8.200181519365161],
              [-38.50970671891212, -8.200253823865641],
              [-38.509662690629867, -8.200325227265123],
              [-38.509617115189741, -8.20039970413897],
              [-38.509565010183969, -8.200468384147284],
              [-38.509509562790903, -8.200523401856472],
              [-38.509440593705364, -8.200573880514197],
              [-38.509370718398813, -8.200623001278922],
              [-38.509295224722912, -8.200663612721495],
              [-38.509219369771017, -8.200702686028379],
              [-38.509191027668244, -8.200716219299245],
              [-38.509141883084141, -8.20073976739342],
              [-38.509101004194314, -8.200759978828419],
              [-38.50906603010138, -8.200777212539361],
              [-38.509011762715474, -8.20083232206148],
              [-38.508961036886951, -8.200886712274313],
              [-38.508896801683441, -8.200926523282462],
              [-38.508854174391111, -8.200965817892577],
              [-38.508845001372315, -8.200968701175567],
              [-38.508819588309855, -8.20088880126692],
              [-38.508751643307164, -8.200844487548624],
              [-38.508651613836442, -8.200830074267888],
              [-38.508589788051211, -8.20082999891526],
              [-38.508635589335142, -8.20079360265963],
              [-38.508667709907279, -8.200734305418663],
              [-38.508700164012488, -8.200625169633664],
              [-38.508710961343702, -8.200556348963184],
              [-38.508709913541693, -8.200522428257546],
              [-38.508708515584075, -8.20047792426333],
              [-38.508712789703296, -8.200398150974236],
              [-38.508707172960726, -8.200314385753179],
              [-38.50869602156623, -8.200227900233299],
              [-38.508680853246332, -8.200159590632481],
              [-38.508632385350545, -8.200075682740318],
              [-38.508597407359495, -8.200022183092459],
              [-38.508554904754604, -8.199959990896994],
              [-38.508510404969591, -8.199897705811381],
              [-38.508462708341121, -8.199851155439376],
              [-38.508408827130573, -8.199813642703248],
              [-38.508357490730518, -8.199773871768102],
              [-38.508306606051072, -8.199735910413921],
              [-38.508258600323629, -8.199719299191486],
              [-38.508254941419267, -8.199667737198631],
              [-38.508295939908777, -8.199624008519899],
              [-38.50835798563056, -8.19959269739692],
              [-38.508417690446294, -8.199545463891321],
              [-38.508458993477696, -8.199475504539812],
              [-38.508485787991205, -8.199391417015775],
              [-38.50850596430255, -8.199299813921973],
              [-38.508522599600958, -8.199208477865829],
              [-38.508531246578222, -8.199116408457886],
              [-38.508510010958069, -8.199035609105994],
              [-38.508469238179323, -8.198969529585575],
              [-38.50841963859073, -8.198920172887307],
              [-38.508360962938013, -8.198868724726859],
              [-38.508289202184599, -8.198826938947771],
              [-38.508209715499518, -8.19879256078347],
              [-38.508141225527979, -8.198748789028478],
              [-38.50809427459307, -8.198686591367014],
              [-38.508062320855949, -8.198610211061139],
              [-38.508017907183373, -8.198551905914879],
              [-38.507936960104352, -8.1985239479977],
              [-38.50784427270186, -8.198519493207506],
              [-38.507758256658441, -8.198553126749895],
              [-38.507686838609118, -8.198601883545846],
              [-38.5076273050868, -8.19865752917557],
              [-38.507572217070873, -8.19871562242245],
              [-38.507518204675478, -8.198784932997881],
              [-38.507465011897516, -8.198852164174319],
              [-38.50741028964751, -8.198908086999149],
              [-38.507356468232267, -8.198969709379352],
              [-38.50731370647906, -8.199044551226402],
              [-38.507281113226561, -8.199119043685229],
              [-38.507236468200482, -8.199174978795114],
              [-38.507188031181151, -8.199213813875431],
              [-38.507147770384321, -8.199248226804478],
              [-38.507129116853037, -8.199282575687674],
              [-38.507102919805085, -8.199323608781903],
              [-38.50706730168374, -8.199347806323873],
              [-38.507065554906461, -8.199291814527912],
              [-38.507076790470059, -8.199235929053284],
              [-38.507113360661329, -8.19917582331065],
              [-38.507146651952489, -8.199124306477053],
              [-38.507149921799282, -8.199049325934293],
              [-38.507162995341737, -8.1989753523424],
              [-38.507175342476764, -8.198901468313649],
              [-38.507191780779941, -8.198822885790067],
              [-38.507222011128519, -8.198750380389205],
              [-38.507248881372, -8.198678684945719],
              [-38.507250780601709, -8.198610848416587],
              [-38.507210348730261, -8.198563040462096],
              [-38.50717200662173, -8.198513878280854],
              [-38.507142947989927, -8.198445823015916],
              [-38.507104169343052, -8.198382549814918],
              [-38.507065294018915, -8.198324070437884],
              [-38.507029775009109, -8.198267856455209],
              [-38.506989801918188, -8.198216159614837],
              [-38.506919653081439, -8.198192646839354],
              [-38.506835857473845, -8.19819254437988],
              [-38.506755399779848, -8.198209631826499],
              [-38.506684641596031, -8.198238580306338],
              [-38.506623490236407, -8.198280927391369],
              [-38.506555095816452, -8.198307165185174],
              [-38.506501236987283, -8.198325460996347],
              [-38.506482472443942, -8.198228925972774],
              [-38.506503168316165, -8.198158037093284],
              [-38.506519591546827, -8.19809175602547],
              [-38.506490494363575, -8.198055087445162],
              [-38.506453060253406, -8.198005926313328],
              [-38.506426451218438, -8.197939321230626],
              [-38.506389750663978, -8.197884281625015],
              [-38.5063370562711, -8.197841795239059],
              [-38.506270829414817, -8.197803633966839],
              [-38.506201693067453, -8.19776899674191],
              [-38.506117270312558, -8.197762199938072],
              [-38.50603393788677, -8.197754680837861],
              [-38.50594870520937, -8.197742094092737],
              [-38.505873028402981, -8.197710614614254],
              [-38.505798803850368, -8.197679408257079],
              [-38.505714388496607, -8.197666641561689],
              [-38.505628510520935, -8.197662013716606],
              [-38.505539174636986, -8.197663894142334],
              [-38.505467139215838, -8.197697906143706],
              [-38.50542013435259, -8.197753295446621],
              [-38.505391351543189, -8.197829691903102],
              [-38.505359483672557, -8.197904727796198],
              [-38.505330246350084, -8.197981575947891],
              [-38.50530209666033, -8.198059872659019],
              [-38.505288193000197, -8.198144156659822],
              [-38.505277286616725, -8.198227358914972],
              [-38.505257218652019, -8.19830421830587],
              [-38.505247763492932, -8.198388507760541],
              [-38.505241311874563, -8.198466650178576],
              [-38.50522787094534, -8.19854378905162],
              [-38.505186217187607, -8.198603345663955],
              [-38.505142663950949, -8.198657201478548],
              [-38.505118624486997, -8.198715332468055],
              [-38.505122839890312, -8.198757035907629],
              [-38.505131835991399, -8.198823800354401],
              [-38.50514439288694, -8.198874287851684],
              [-38.505138878730378, -8.198928461695765],
              [-38.505102799355505, -8.198885091021394],
              [-38.505068451525645, -8.198836385814879],
              [-38.505030110898439, -8.198786228476722],
              [-38.504999412766516, -8.198722784106186],
              [-38.504973984495081, -8.198655909035361],
              [-38.504945564090072, -8.198585954929918],
              [-38.50491305865156, -8.198515724452617],
              [-38.50488609643822, -8.198441249545372],
              [-38.504858497947964, -8.198367407017843],
              [-38.504832980723585, -8.198299084602143],
              [-38.504803193986241, -8.198232837333304],
              [-38.504779865827317, -8.19815628649167],
              [-38.504752083675392, -8.198084162316206],
              [-38.504721480114831, -8.198017733135833],
              [-38.504695145970466, -8.197949319257836],
              [-38.504662181431954, -8.197883339432982],
              [-38.504617493754751, -8.197826842642424],
              [-38.504571521318098, -8.197781469836793],
              [-38.504503919433297, -8.197754160807657],
              [-38.504418954478879, -8.197745373045178],
              [-38.504337996431694, -8.197726640495162],
              [-38.504256581102958, -8.197710620920144],
              [-38.504172615596374, -8.197701201172748],
              [-38.504089920762489, -8.197692054325719],
              [-38.504003497768799, -8.197687696820516],
              [-38.503923592203641, -8.197698814564236],
              [-38.503870709698674, -8.197735111123402],
              [-38.503834679302649, -8.197798925741665],
              [-38.503797822739706, -8.197870065931774],
              [-38.503760785824305, -8.197940210926273],
              [-38.503722749127483, -8.198011259203607],
              [-38.503680002866936, -8.198073166053367],
              [-38.503647695085881, -8.198136623424524],
              [-38.503623823559856, -8.198205608732698],
              [-38.503611480964175, -8.198275331843261],
              [-38.503590616468635, -8.198335366125827],
              [-38.503549692379664, -8.198392028998828],
              [-38.503519856192661, -8.198439027179882],
              [-38.503509489264339, -8.198379768519809],
              [-38.503512997583307, -8.198332647476262],
              [-38.503518852093535, -8.198297016693376],
              [-38.503528289999501, -8.198226928203901],
              [-38.503536733741363, -8.198153220419865],
              [-38.503538546394658, -8.198082489380297],
              [-38.503529004844779, -8.198016538306371],
              [-38.50349783625245, -8.197966661020358],
              [-38.503462374585006, -8.197937853705252],
              [-38.503389131685267, -8.197920487240047],
              [-38.503325732288886, -8.197945192948538],
              [-38.503262772000873, -8.1979818388185],
              [-38.503209335481962, -8.198025551645804],
              [-38.503165158680751, -8.198069637679568],
              [-38.503116080881441, -8.198112541796874],
              [-38.503054735386058, -8.19816474732124],
              [-38.503002209039543, -8.198206561752807],
              [-38.503019808683483, -8.19814380992951],
              [-38.503016596808763, -8.198025223731964],
              [-38.503003248284358, -8.197954474016711],
              [-38.502990071678873, -8.197891503363159],
              [-38.502989252528913, -8.197820045473486],
              [-38.502958524249841, -8.197781203805858],
              [-38.50289335911247, -8.197765656243179],
              [-38.50281564361071, -8.197767731289439],
              [-38.502745456717676, -8.197775152255796],
              [-38.502739373157631, -8.197775868369197],
              [-38.502672534557398, -8.19779197681833],
              [-38.502604411028535, -8.197746214679544],
              [-38.502566877442305, -8.197704470135982],
              [-38.502501445148717, -8.197684761401945],
              [-38.502437178016223, -8.197677174628307],
              [-38.502405274757344, -8.197634170692405],
              [-38.502355400498367, -8.19758743605982],
              [-38.502329932487292, -8.197552942522298],
              [-38.502373607266627, -8.197547569306444],
              [-38.502426353893355, -8.197547634382094],
              [-38.502482195222782, -8.197541281194578],
              [-38.502530948841979, -8.197540075006522],
              [-38.502558548664581, -8.197539385433631],
              [-38.502630915054141, -8.197531424474688],
              [-38.502635091768816, -8.19753097736656],
              [-38.502715715631346, -8.197526282837682],
              [-38.502745773526463, -8.197520078726175],
              [-38.502799623512594, -8.197508929096509],
              [-38.502884892502628, -8.197492119725531],
              [-38.502966619151131, -8.197476662747009],
              [-38.503050244551879, -8.19746744926174],
              [-38.503133338367903, -8.197447652249236],
              [-38.503216980595887, -8.197424870986755],
              [-38.50329762867571, -8.197400638790999],
              [-38.503348515350844, -8.197363163976474],
              [-38.503387703538415, -8.197315272799448],
              [-38.503457218576422, -8.197263891335245],
              [-38.503508847658473, -8.197213392359377],
              [-38.503557749899258, -8.197165513120677],
              [-38.503607466173179, -8.197120077074574],
              [-38.503646398992245, -8.197058436886893],
              [-38.503658301506427, -8.196977497208779],
              [-38.50364196492086, -8.196900321754635],
              [-38.503595662751884, -8.196828174721743],
              [-38.503552234454141, -8.196781267262228],
              [-38.5035269767777, -8.196723527952697],
              [-38.503603046275039, -8.196730857714602],
              [-38.503675402529225, -8.196730946752455],
              [-38.503743229585446, -8.196722889546541],
              [-38.503827305834079, -8.196716028190112],
              [-38.503918284264337, -8.196707185365797],
              [-38.504005649300652, -8.196683775343365],
              [-38.504093558818312, -8.196660546874361],
              [-38.504179924633029, -8.196637587843341],
              [-38.504265460712759, -8.196624848823443],
              [-38.504348909947545, -8.196611112251322],
              [-38.504430007928036, -8.196589955727619],
              [-38.504499231213941, -8.196554040961596],
              [-38.50456147831229, -8.196506539787492],
              [-38.504611016626811, -8.196458028025885],
              [-38.504654580192685, -8.196395669798887],
              [-38.504692514808234, -8.196333485558052],
              [-38.504734347378118, -8.19627600959099],
              [-38.504775454097945, -8.196218170920536],
              [-38.504830785406917, -8.196183595813293],
              [-38.50490679816491, -8.19616333746241],
              [-38.504987534117923, -8.196141185405239],
              [-38.505063738271126, -8.196112967501824],
              [-38.505139123331524, -8.196086376710626],
              [-38.505217847333746, -8.196076071321269],
              [-38.505302561620404, -8.196067039587396],
              [-38.505385296049447, -8.196043894929209],
              [-38.505464491617623, -8.196019298686567],
              [-38.50553778990885, -8.195991619838395],
              [-38.505614808279162, -8.195965664119539],
              [-38.505696820630597, -8.19593908134212],
              [-38.505776831893066, -8.195915571456656],
              [-38.50586355302223, -8.195898763206547],
              [-38.505947368001543, -8.195882765444315],
              [-38.506031996698269, -8.195869482214565],
              [-38.506116719857403, -8.195853214173118],
              [-38.506190302817252, -8.195815495400764],
              [-38.506269135826265, -8.19579035583965],
              [-38.506334977009772, -8.195772888767447],
              [-38.506350839398792, -8.195793259833438],
              [-38.506370603005458, -8.195815716064104],
              [-38.506500157505684, -8.195813070565908],
              [-38.506584671985856, -8.195818781957271],
              [-38.506663595760941, -8.19579364242877],
              [-38.506711797697392, -8.195724234386093],
              [-38.50673771871579, -8.19568600496712],
              [-38.506715037497351, -8.195599957566294],
              [-38.50668077560384, -8.195555232478721],
              [-38.506679667929824, -8.195496346998485],
              [-38.506679463096681, -8.195441532953341],
              [-38.506614669876896, -8.195419021669682],
              [-38.506579483548947, -8.195387772763095],
              [-38.506517421154008, -8.195359385437255],
              [-38.50644455206271, -8.195333607983946],
              [-38.506359872061068, -8.195314961758818],
              [-38.506285458521347, -8.195290086906086],
              [-38.506201493780488, -8.195280396252247],
              [-38.506132256034014, -8.195254713640971],
              [-38.506109919814264, -8.195183500730799],
              [-38.506121093576624, -8.19510409772875],
              [-38.5061417900979, -8.195032575689407],
              [-38.506140695996386, -8.194962745559488],
              [-38.506102819871465, -8.194904176878566],
              [-38.506057587018738, -8.194848131838457],
              [-38.506013522450594, -8.194801766566913],
              [-38.505960920499376, -8.194758285294638],
              [-38.505889060462316, -8.194724187418757],
              [-38.505819653539362, -8.194688554854876],
              [-38.505747703077525, -8.194654185488954],
              [-38.505663373449551, -8.19464603196489],
              [-38.505572951606808, -8.19464564981215],
              [-38.505481902867913, -8.194638302082636],
              [-38.505387482901156, -8.194640809445398],
              [-38.505296856254006, -8.194659240952442],
              [-38.50521311442192, -8.194689710970017],
              [-38.505138989340693, -8.194725438968629],
              [-38.505059784180268, -8.194757994908363],
              [-38.50497714012112, -8.194781682337206],
              [-38.50489477970283, -8.194796234466198],
              [-38.504825166356866, -8.194780772230468],
              [-38.504754669994227, -8.1947452286031],
              [-38.504677417943761, -8.194740068469466],
              [-38.50461309299051, -8.194706160526964],
              [-38.504561400410033, -8.194661594776818],
              [-38.50448990456038, -8.194626773492448],
              [-38.504424408499332, -8.194585537489189],
              [-38.504347005364473, -8.194555774224673],
              [-38.504256940656362, -8.194560276612993],
              [-38.504181191803696, -8.194587590421792],
              [-38.504126123621518, -8.194629763724892],
              [-38.50408485860207, -8.194668878625595],
              [-38.504028394231135, -8.194738818892644],
              [-38.503997542394295, -8.194799835913035],
              [-38.503963695639541, -8.194860035182288],
              [-38.503925614263167, -8.194967445066348],
              [-38.503889319414355, -8.195025108667636],
              [-38.503878353679639, -8.195082803413193],
              [-38.503816923234531, -8.195057491822697],
              [-38.503751739326681, -8.195057411650147],
              [-38.503707718614571, -8.195049126390206],
              [-38.50366055539159, -8.195012616307714],
              [-38.503587313344923, -8.194994978552508],
              [-38.503530350142384, -8.194954566971557],
              [-38.503483934536568, -8.194900871954106],
              [-38.503435148318871, -8.19485540512499],
              [-38.503363814038707, -8.194836774709975],
              [-38.503297880106494, -8.19485586931704],
              [-38.503262048420176, -8.194906025931317],
              [-38.503236846577259, -8.194949773555104],
              [-38.503206567803005, -8.19491464098807],
              [-38.503164414445024, -8.194864750158905],
              [-38.503116167932106, -8.194823354287486],
              [-38.503066058932212, -8.194746499026072],
              [-38.503022481665518, -8.194673541230721],
              [-38.50300410107775, -8.194634172073311],
              [-38.502968858117889, -8.19457569682114],
              [-38.502927710646539, -8.194520018296624],
              [-38.502873291476234, -8.194478072084044],
              [-38.502793059330443, -8.194460244675774],
              [-38.502749562870171, -8.194468512633975],
              [-38.502704598233613, -8.194489351572189],
              [-38.502630029739201, -8.194516485635789],
              [-38.502586335074206, -8.194538140191447],
              [-38.502590033997706, -8.194483783245833],
              [-38.502608353722387, -8.19442591670577],
              [-38.502633856817916, -8.194358923391782],
              [-38.502644388096769, -8.194285670449293],
              [-38.502650834037901, -8.194212412470375],
              [-38.502666817247444, -8.194135276820814],
              [-38.502650389142374, -8.194058915286639],
              [-38.502617693769018, -8.193996011012123],
              [-38.50256335751105, -8.193960486939824],
              [-38.502489828304086, -8.193954969180076],
              [-38.502419529609206, -8.193979847181165],
              [-38.502368549259096, -8.194019944890217],
              [-38.502319136522502, -8.194040506946614],
              [-38.502268361104839, -8.194061790929688],
              [-38.502222056074551, -8.194065442329078],
              [-38.502264241414252, -8.194016379067051],
              [-38.502305688661423, -8.193976812323768],
              [-38.502319737340343, -8.19392228724328],
              [-38.502323527299033, -8.193867749509014],
              [-38.502390925800142, -8.19383888806982],
              [-38.502461774799492, -8.193809488162843],
              [-38.502510037627125, -8.193764502694],
              [-38.502511208608382, -8.19369883616065],
              [-38.5025330795903, -8.193632381081402],
              [-38.502580250384, -8.193589474646307],
              [-38.502633504438414, -8.193546032992487],
              [-38.502683856954384, -8.193499693298577],
              [-38.502718501855597, -8.193454781468807],
              [-38.502704488459955, -8.193407819734116],
              [-38.502653213985404, -8.193319294717401],
              [-38.502629473357445, -8.19328299429972],
              [-38.502602565817625, -8.193238187511666],
              [-38.502600667757129, -8.193231401289829],
              [-38.502573369535291, -8.193135669676247],
              [-38.502572827296724, -8.193133679069046],
              [-38.502618324099046, -8.193049705460668],
              [-38.502649360590695, -8.19298624657481],
              [-38.502650693681154, -8.192936228392073],
              [-38.502718682522193, -8.192870463293408],
              [-38.502751582800343, -8.192841378365234],
              [-38.502751756264352, -8.192701630599649],
              [-38.502728585358923, -8.192718335625571],
              [-38.502676253651316, -8.192750200611322],
              [-38.502609840849338, -8.192789646183003],
              [-38.502513313070651, -8.192808431617241],
              [-38.502502964944632, -8.192807333439207],
              [-38.502403659567051, -8.192796537696278],
              [-38.502402570373491, -8.192796355449735],
              [-38.502372897321436, -8.192785374197484],
              [-38.502328360277176, -8.192754475225467],
              [-38.502282111476461, -8.192712991281757],
              [-38.502236782334521, -8.192662011032706],
              [-38.502163462594339, -8.192634242352947],
              [-38.502071125218833, -8.192641545478583],
              [-38.501993356857632, -8.192686946751325],
              [-38.50192158095323, -8.192731812693646],
              [-38.501847992823166, -8.192773872380618],
              [-38.501773406944821, -8.192815207207277],
              [-38.501710696316543, -8.19287066715588],
              [-38.501661851114321, -8.192945953149726],
              [-38.501609286525941, -8.193018973248888],
              [-38.501550471265169, -8.193081131409176],
              [-38.501493295855418, -8.193138678546322],
              [-38.501433498811366, -8.193187267712183],
              [-38.501379315219594, -8.193248074790224],
              [-38.501347357148383, -8.193322386635245],
              [-38.501333543560946, -8.193406761043338],
              [-38.501294244044637, -8.193471295021434],
              [-38.501263013231394, -8.19354479369008],
              [-38.50121729813808, -8.193585078662139],
              [-38.501141867279031, -8.193575759338064],
              [-38.501069881072176, -8.193570605039289],
              [-38.500989495432528, -8.193603339619575],
              [-38.500937524141086, -8.193637194747534],
              [-38.500856426062221, -8.193658802854909],
              [-38.500815033203303, -8.193727494969947],
              [-38.500803677897075, -8.193806083480904],
              [-38.500791370430669, -8.19392021834501],
              [-38.500820335873563, -8.193989087950786],
              [-38.500838427514552, -8.19404157233885],
              [-38.500773105072113, -8.194007119864162],
              [-38.500705858113776, -8.193987137275482],
              [-38.500577190362669, -8.194007239227306],
              [-38.500518038275985, -8.194048050190643],
              [-38.500473125904719, -8.194099732981389],
              [-38.500446971072328, -8.194179479026371],
              [-38.500420726458913, -8.19425841089196],
              [-38.500412814460311, -8.194342159396358],
              [-38.500425599000224, -8.194427923464593],
              [-38.500424404426262, -8.194512222996087],
              [-38.500438554247957, -8.194595184750597],
              [-38.500451407696275, -8.194698496538166],
              [-38.500473112561743, -8.194765548141717],
              [-38.500415046204338, -8.194736441240799],
              [-38.500356904755584, -8.194694761448956],
              [-38.500304024349354, -8.194656706234396],
              [-38.500264601499573, -8.19460157230607],
              [-38.500219276040781, -8.194547697387041],
              [-38.500165590114399, -8.194500415082157],
              [-38.500099542962936, -8.19446469527629],
              [-38.50004721387208, -8.194421394513904],
              [-38.499971801416848, -8.194397240904117],
              [-38.499889366549851, -8.194398676413629],
              [-38.499818503613987, -8.194439020491631],
              [-38.499755160923279, -8.194491313507278],
              [-38.499689005569458, -8.194542246248274],
              [-38.499614235773521, -8.19458502763919],
              [-38.499534298437744, -8.194622013695547],
              [-38.499460705202296, -8.194667690970249],
              [-38.499389559421267, -8.194716356173776],
              [-38.499323673062236, -8.194769912282812],
              [-38.499254342500123, -8.194818941519637],
              [-38.499185834870573, -8.194863268276029],
              [-38.499127309535567, -8.194910863674103],
              [-38.499101552023447, -8.194963022332006],
              [-38.499075966571503, -8.195022779144798],
              [-38.499074073932249, -8.195084283955987],
              [-38.49903238283229, -8.19502815213087],
              [-38.499012480329235, -8.194971956907013],
              [-38.498982505416876, -8.194911950202618],
              [-38.498955231212797, -8.194870218095128],
              [-38.498902146271021, -8.194850614597645],
              [-38.498844130973474, -8.1948533465404],
              [-38.498790650184965, -8.194859883079653],
              [-38.49872692175402, -8.194857542612699],
              [-38.498666365713731, -8.194859276404291],
              [-38.498600900474386, -8.19486643118797],
              [-38.498555396668174, -8.194882655941166],
              [-38.498562811543394, -8.194833730786341],
              [-38.498579223102801, -8.194777671127831],
              [-38.498641476245531, -8.194653106044438],
              [-38.49869011121416, -8.194601337650278],
              [-38.498764055318311, -8.194565520192302],
              [-38.498849059132546, -8.194542560599599],
              [-38.498934248469055, -8.194516435409097],
              [-38.499005025239306, -8.194472473317258],
              [-38.49905992295988, -8.194421798070936],
              [-38.499111923346462, -8.194364878051278],
              [-38.499160537787503, -8.194256848603597],
              [-38.499151545348262, -8.194188003688247],
              [-38.499109833961761, -8.194148153156471],
              [-38.499053513082281, -8.194102766928587],
              [-38.499113542397524, -8.194086560144049],
              [-38.499189928972939, -8.19405761996148],
              [-38.499281418315832, -8.19400273886369],
              [-38.499345924003592, -8.193964196024186],
              [-38.499398819364721, -8.193917498114832],
              [-38.499451182663144, -8.193860668945296],
              [-38.499501738270382, -8.193797415453689],
              [-38.499541591286224, -8.193726098477651],
              [-38.499573007835586, -8.193649524834225],
              [-38.49958907820551, -8.193575736159735],
              [-38.499575266864291, -8.193512674182196],
              [-38.499525197066411, -8.193477788172666],
              [-38.499478041551036, -8.193435579182804],
              [-38.499438058056711, -8.19339337908476],
              [-38.499378905835442, -8.193361647602034],
              [-38.499337158668084, -8.193350470246347],
              [-38.499277262996266, -8.193332486488243],
              [-38.499250322259492, -8.193314543617504],
              [-38.499283306924902, -8.193290795745805],
              [-38.499331369231562, -8.193261187216866],
              [-38.499404564144221, -8.193243459035559],
              [-38.499489372173777, -8.193231624606669],
              [-38.499569537173954, -8.193230186349783],
              [-38.499651628768305, -8.193212650058951],
              [-38.499732262375701, -8.193199453626354],
              [-38.49980946151269, -8.193173951485495],
              [-38.499877161991762, -8.19312139249095],
              [-38.499928807417717, -8.193057688026057],
              [-38.499978553231969, -8.192988463644182],
              [-38.500015958949781, -8.192913887314093],
              [-38.500024233672306, -8.192830501071084],
              [-38.500036681782404, -8.192749290839417],
              [-38.500075359129092, -8.192674173365942],
              [-38.500112039195514, -8.192599053413693],
              [-38.500149190890134, -8.192509733119062],
              [-38.500161821997786, -8.192427347234201],
              [-38.500176926066722, -8.192327507226301],
              [-38.5001699469885, -8.192245639763103],
              [-38.500165423725356, -8.192160066819925],
              [-38.500133729102672, -8.192095897285373],
              [-38.500076408500227, -8.192051504936678],
              [-38.499995000742643, -8.192030509738851],
              [-38.499904680970054, -8.192021624014311],
              [-38.499834000609845, -8.191988792900357],
              [-38.499766231920219, -8.19195071918319],
              [-38.499684270571649, -8.191936868925247],
              [-38.499601032144483, -8.191927901460906],
              [-38.499515528287382, -8.191915584456458],
              [-38.499439612120241, -8.191931681167521],
              [-38.499374095803873, -8.191907449294666],
              [-38.499330846270631, -8.191936249745355],
              [-38.499261914292177, -8.191957329931133],
              [-38.499294583971221, -8.191894958773098],
              [-38.499331509890425, -8.191841095353773],
              [-38.499394031401465, -8.191791786264835],
              [-38.499461632539443, -8.191745920630506],
              [-38.499525609962788, -8.191693899772821],
              [-38.4996098343589, -8.191640275875248],
              [-38.499616285258746, -8.191636123093348],
              [-38.4996897006232, -8.191587008363284],
              [-38.499692880680051, -8.191584931915427],
              [-38.499742763520011, -8.191551255249792],
              [-38.499798114879006, -8.191500399525388],
              [-38.499838738130698, -8.191466349564239],
              [-38.499857732080066, -8.191450363144103],
              [-38.499927323821005, -8.191409746091695],
              [-38.499999009235616, -8.191364609033183],
              [-38.500089847227358, -8.191321847427979],
              [-38.500145534454724, -8.191292790935851],
              [-38.500211950038448, -8.191251084476457],
              [-38.500251418755163, -8.191196410043368],
              [-38.500294690029627, -8.191150061873518],
              [-38.500337696836432, -8.191097381748968],
              [-38.500373360356576, -8.191036461420969],
              [-38.500390143301175, -8.190973437329758],
              [-38.500370965692348, -8.190918328480462],
              [-38.500326996956943, -8.190868796950463],
              [-38.500285562862622, -8.190825147917263],
              [-38.500229598427218, -8.190785370315007],
              [-38.500151915892715, -8.190762118480999],
              [-38.500063112648085, -8.190774943103543],
              [-38.499982559263294, -8.190797004002963],
              [-38.499909892031894, -8.190828752992196],
              [-38.499828721768594, -8.190835888558999],
              [-38.499772849068691, -8.19079538739585],
              [-38.49973843017154, -8.190731938073769],
              [-38.499685833185517, -8.19068547114494],
              [-38.499625696142722, -8.19064297473955],
              [-38.499554284799565, -8.190614122560223],
              [-38.499485387295771, -8.19060779596734],
              [-38.499433979981802, -8.190626455730406],
              [-38.499375028184751, -8.190652613623488],
              [-38.499342377278417, -8.190700060286247],
              [-38.499288453326336, -8.190771088459591],
              [-38.49923294282673, -8.190804034478381],
              [-38.499169169358147, -8.190838236560802],
              [-38.499108208724806, -8.190873617995363],
              [-38.499054503707271, -8.190914706883538],
              [-38.49897359401227, -8.190931249579107],
              [-38.498884043165759, -8.190961077933665],
              [-38.498815625498374, -8.191006580656277],
              [-38.498741039228548, -8.191048276733419],
              [-38.498678684961057, -8.191109073301229],
              [-38.498607848211726, -8.191128884692727],
              [-38.498552263466436, -8.191148534116266],
              [-38.498499564685098, -8.191183473457738],
              [-38.498433805956196, -8.1912079946158],
              [-38.498383991650101, -8.191259399737564],
              [-38.498372678436461, -8.191304068824873],
              [-38.498347503322158, -8.191253746383044],
              [-38.49831151569213, -8.191210737089493],
              [-38.49824988733009, -8.191199263568363],
              [-38.498198783396028, -8.191193049321711],
              [-38.498239055949917, -8.191149139854437],
              [-38.498276862818237, -8.191116805137142],
              [-38.498334107850027, -8.191076263469284],
              [-38.498402265568593, -8.191020901272303],
              [-38.498471061589676, -8.190963278562695],
              [-38.498541214715601, -8.190909366046453],
              [-38.498605648068548, -8.190855717767104],
              [-38.498658297885598, -8.190787311210569],
              [-38.498697594918752, -8.190724857876678],
              [-38.498747795336037, -8.190655001036587],
              [-38.498801722643606, -8.190581349844011],
              [-38.498855107487707, -8.19050588893484],
              [-38.498910385547177, -8.190441013219207],
              [-38.498966864028048, -8.190359948125451],
              [-38.499018327527473, -8.190296333981971],
              [-38.499071069904446, -8.190226389796647],
              [-38.499120007013722, -8.190150290163995],
              [-38.499185104933844, -8.190073215604546],
              [-38.499213720814595, -8.189985512924874],
              [-38.49924051421705, -8.189903235085275],
              [-38.499254777927298, -8.189822117573847],
              [-38.499280192368332, -8.189753586682992],
              [-38.499298343054335, -8.189685860846359],
              [-38.499278000713431, -8.189618539525474],
              [-38.499235943352872, -8.189565301739371],
              [-38.499194518779014, -8.189514235576008],
              [-38.499149643404131, -8.189463979193404],
              [-38.499089232098328, -8.18942320096237],
              [-38.499044590883294, -8.189403517545315],
              [-38.498990036799292, -8.189397751388567],
              [-38.498924295698259, -8.189408433554899],
              [-38.4988527566314, -8.189409339726268],
              [-38.498794687538769, -8.189383127089156],
              [-38.498743610294817, -8.189355747252788],
              [-38.498686091013418, -8.189325464955738],
              [-38.498613386708371, -8.189314430010706],
              [-38.49853486544076, -8.18930881493101],
              [-38.498447897188335, -8.189306445604579],
              [-38.498365375995839, -8.189305167205706],
              [-38.498284666748326, -8.189306875948631],
              [-38.498200500687119, -8.189314188385538],
              [-38.498112585633706, -8.189342933201768],
              [-38.498045139343077, -8.189337874505048],
              [-38.497981673501627, -8.189344036759953],
              [-38.497913028664023, -8.189353629729153],
              [-38.497853258444032, -8.189380962261279],
              [-38.497787217884493, -8.189413261828328],
              [-38.497722536808013, -8.18944737210953],
              [-38.497659121368756, -8.189485735185055],
              [-38.497611673704981, -8.189532258775007],
              [-38.497565570667611, -8.189592442240556],
              [-38.497529079500545, -8.189661592382805],
              [-38.497502748653204, -8.189736724980488],
              [-38.497465728862814, -8.189793030314302],
              [-38.497431068314519, -8.189850243098187],
              [-38.497410553291331, -8.189920860342225],
              [-38.497377254825821, -8.189977803460595],
              [-38.49734351173322, -8.190027328981916],
              [-38.497319809139576, -8.190105992452093],
              [-38.497307761746832, -8.190156901760503],
              [-38.497275521327303, -8.19009408812747],
              [-38.49723973409391, -8.190036244918781],
              [-38.497206850941765, -8.189979219389818],
              [-38.497179963183058, -8.189919306871547],
              [-38.49714854280905, -8.189854142507041],
              [-38.497111304997937, -8.189794759802819],
              [-38.497076426902765, -8.189735922745555],
              [-38.497064893492258, -8.189666622371572],
              [-38.497077872915327, -8.189596448018087],
              [-38.497072861428613, -8.189538733586968],
              [-38.497043881142687, -8.189482436522779],
              [-38.496990910837937, -8.18944447126321],
              [-38.49692912427556, -8.189414635664736],
              [-38.496858164336665, -8.189388135337886],
              [-38.496775290805978, -8.189378263262494],
              [-38.496706998972442, -8.189395906665904],
              [-38.496647553057826, -8.189381812539805],
              [-38.496608014747302, -8.189347120240674],
              [-38.496508923627189, -8.189311177800354],
              [-38.496445924884384, -8.189306847998219],
              [-38.496486554416855, -8.189267823538859],
              [-38.496564490924648, -8.189233006375693],
              [-38.496631782520716, -8.189216628071714],
              [-38.496699716642269, -8.189194642551591],
              [-38.496760394741536, -8.189167311324656],
              [-38.496800660981002, -8.189128376829677],
              [-38.496850913786488, -8.189089183420837],
              [-38.496911588340659, -8.189064656167892],
              [-38.496973171748898, -8.189039315972417],
              [-38.497025149648671, -8.18899994378598],
              [-38.497069415332518, -8.188957034368666],
              [-38.497104793264093, -8.188906877738672],
              [-38.49712195381332, -8.188832638260301],
              [-38.497126860803043, -8.188756574484444],
              [-38.497109251012105, -8.188682382149075],
              [-38.49708045983396, -8.18862011551116],
              [-38.497057483255325, -8.188554328497979],
              [-38.497023610470478, -8.188490246492533],
              [-38.496997638950589, -8.188423732131904],
              [-38.496956394649793, -8.188374022760133],
              [-38.496904612403299, -8.188329908260297],
              [-38.496841655250378, -8.188292563707044],
              [-38.496758421081466, -8.188280882157617],
              [-38.496691857508921, -8.18829599507675],
              [-38.496629098103099, -8.188318077508486],
              [-38.496575334008817, -8.188334020429744],
              [-38.49657288669777, -8.188258671108834],
              [-38.496574246623126, -8.188187849119698],
              [-38.496545177756815, -8.188129923784805],
              [-38.49650428168006, -8.188092154430326],
              [-38.496500084608343, -8.188036611850343],
              [-38.496550064946504, -8.187997418130699],
              [-38.496592967328823, -8.187955682932344],
              [-38.496638233939557, -8.187910875317952],
              [-38.496684328269183, -8.187857566269086],
              [-38.496722529519765, -8.187800177049471],
              [-38.496725795414136, -8.187729719238984],
              [-38.496693820910899, -8.187672242551258],
              [-38.496640132666371, -8.187627854292309],
              [-38.4965687275471, -8.187594659950921],
              [-38.496484318232064, -8.187579087465119],
              [-38.49640699765785, -8.187557463538406],
              [-38.49632277913193, -8.187534564669589],
              [-38.496255736784853, -8.187497305390284],
              [-38.496210488984723, -8.187454917544807],
              [-38.496158430267947, -8.187414239778992],
              [-38.496091025960126, -8.187376075505687],
              [-38.496029890732665, -8.187333939170848],
              [-38.495960757697731, -8.187298848077466],
              [-38.495888893094104, -8.187270175633527],
              [-38.49581002634006, -8.187250901411554],
              [-38.495725794265262, -8.18723885661057],
              [-38.495642015886474, -8.187226993262255],
              [-38.495573110641665, -8.187227359454601],
              [-38.495502636693217, -8.187247894403248],
              [-38.495434520718554, -8.187270331770987],
              [-38.495375197643199, -8.187303182059404],
              [-38.495325214784529, -8.187344365567339],
              [-38.495271054165016, -8.187386900621881],
              [-38.495216255771389, -8.187431243905037],
              [-38.495166907568262, -8.187473061346351],
              [-38.495123454362201, -8.187519589639193],
              [-38.495081985851904, -8.187576070093973],
              [-38.495046144430617, -8.187633733452312],
              [-38.495015954599353, -8.187673132633936],
              [-38.49497803630441, -8.187721929122336],
              [-38.494957098296489, -8.187767761939227],
              [-38.494933194447597, -8.187717712259355],
              [-38.494909468980367, -8.187670195448671],
              [-38.494884294856483, -8.187619601467352],
              [-38.494850516931166, -8.187552624966759],
              [-38.494821285375338, -8.187479955695419],
              [-38.49478914727456, -8.18740845865781],
              [-38.494766092095865, -8.187333354914934],
              [-38.494756399654911, -8.187244338330803],
              [-38.494740443157596, -8.187155313908768],
              [-38.494727118474202, -8.187067016392644],
              [-38.494716426172978, -8.18697899352472],
              [-38.494678553607102, -8.186919519379135],
              [-38.494604509119355, -8.186891838956409],
              [-38.494518909261195, -8.186884676589168],
              [-38.494435822140829, -8.186900672980133],
              [-38.494359897092878, -8.186924457165889],
              [-38.494320418365611, -8.186986819391949],
              [-38.49428510893172, -8.18705452348347],
              [-38.494231219688849, -8.187097782348276],
              [-38.494157473966666, -8.187121116963811],
              [-38.494087811665864, -8.187145903907879],
              [-38.494011433664141, -8.187168873391217],
              [-38.49394230577154, -8.187201801629321],
              [-38.493892679186146, -8.187248322047298],
              [-38.493841873203358, -8.18729420781824],
              [-38.493792879109712, -8.187343080758614],
              [-38.493755305449668, -8.187406440276924],
              [-38.493725257099918, -8.187477497617691],
              [-38.493707723273204, -8.187559334394862],
              [-38.493672595821138, -8.187626586391588],
              [-38.493614453320454, -8.18765898563103],
              [-38.49358218344409, -8.187691960020027],
              [-38.49355342669493, -8.187746647212201],
              [-38.493524645937207, -8.187820329221303],
              [-38.493486585972676, -8.187765558223688],
              [-38.493453107859409, -8.187748873121576],
              [-38.493417384164715, -8.187713190350189],
              [-38.493383033547332, -8.187668554583901],
              [-38.49335579480779, -8.187599596240666],
              [-38.493325740529784, -8.187531629331424],
              [-38.493296229476186, -8.187464838973591],
              [-38.493249994371169, -8.187413766216601],
              [-38.493192224284833, -8.187367020657803],
              [-38.493125002093159, -8.187328675231727],
              [-38.493036400384575, -8.187326212264768],
              [-38.49294906415534, -8.187328092547062],
              [-38.49285927883215, -8.187328341604783],
              [-38.492785951299886, -8.187308078788485],
              [-38.492718992812073, -8.187276607954772],
              [-38.492637931874782, -8.187269631791091],
              [-38.492568709762089, -8.187305182765748],
              [-38.492517174957833, -8.187352966929337],
              [-38.492467902972002, -8.187406090577122],
              [-38.492413749050598, -8.187443107687361],
              [-38.49238735563106, -8.187495355564762],
              [-38.492356461682306, -8.187445387416419],
              [-38.492323059023576, -8.187369185133299],
              [-38.492293292985146, -8.18728882664414],
              [-38.49226269237851, -8.187222306206445],
              [-38.492213184078906, -8.187175299548418],
              [-38.492153592986504, -8.187132893239188],
              [-38.492087274651226, -8.18709780504417],
              [-38.4920041330858, -8.187085037233663],
              [-38.491920317638673, -8.187102569860196],
              [-38.491864970802979, -8.187149625528651],
              [-38.491811146862361, -8.187212783497859],
              [-38.491787204537005, -8.187265034417448],
              [-38.491746693715065, -8.18721026018725],
              [-38.491671056484662, -8.187150105091368],
              [-38.491615444672178, -8.187119733783771],
              [-38.491542771094032, -8.187084999308155],
              [-38.491466351199257, -8.187069345398687],
              [-38.491410390166436, -8.18702802897614],
              [-38.491350611078722, -8.186990778061134],
              [-38.491269831198643, -8.186977018108688],
              [-38.491182047113796, -8.18697419396355],
              [-38.491088085283472, -8.186974889627987],
              [-38.490997102882631, -8.186988523609719],
              [-38.490911917748292, -8.187012295461949],
              [-38.490832987812617, -8.187043039958089],
              [-38.490769566200093, -8.187086105416379],
              [-38.490722775978789, -8.187114990932479],
              [-38.490644526076288, -8.187182097802214],
              [-38.490604718687948, -8.187216871466807],
              [-38.490539693959967, -8.187235512923783],
              [-38.490471485253188, -8.187259306095148],
              [-38.490395580759149, -8.187266717798551],
              [-38.490326048123897, -8.187260750689303],
              [-38.490278440532798, -8.187218449690839],
              [-38.490186451827036, -8.18716668569118],
              [-38.490140057478854, -8.187169792923946],
              [-38.490073659139561, -8.187197749087781],
              [-38.490048856397152, -8.18721255183322],
              [-38.490009153779887, -8.187236290470354],
              [-38.489963255328675, -8.187277749795342],
              [-38.489930442113248, -8.187309456867968],
              [-38.489875229208508, -8.187322412156574],
              [-38.489847316025212, -8.187284568117706],
              [-38.48984057161497, -8.18723399714284],
              [-38.489822569027503, -8.187184226009244],
              [-38.489808868100468, -8.187178419796107],
              [-38.489747798384684, -8.187156724699287],
              [-38.489698789856526, -8.187145265867805],
              [-38.489641332833358, -8.18713822848253],
              [-38.489584039874089, -8.187144939942003],
              [-38.489526923642593, -8.18715545058445],
              [-38.489472975957739, -8.187172929998651],
              [-38.489425216055622, -8.187179020325797],
              [-38.489361575163734, -8.187180296630316],
              [-38.489308729430519, -8.187187918189757],
              [-38.489250442223259, -8.18719119118259],
              [-38.489200956164765, -8.187198274267303],
              [-38.48915186044119, -8.187184011259916],
              [-38.489110502866254, -8.187152843585872],
              [-38.489081066741058, -8.187099078088961],
              [-38.489049336483269, -8.187064575880081],
              [-38.48900190416834, -8.187027340252016],
              [-38.488960651356756, -8.186985228052112],
              [-38.488915853160336, -8.186946910328972],
              [-38.488871582719803, -8.186921889655414],
              [-38.488827672073626, -8.186899492529284],
              [-38.488769661526682, -8.186899419095859],
              [-38.488714457534286, -8.186905409464881],
              [-38.488673574002121, -8.186929689182634],
              [-38.4886315887195, -8.186963645822336],
              [-38.488584175122284, -8.186982942423169],
              [-38.488537121422922, -8.187004772118884],
              [-38.488492962398951, -8.187034746119325],
              [-38.488467466422058, -8.187094773686763],
              [-38.488453385596934, -8.18717326783875],
              [-38.48839794102264, -8.187083198258946],
              [-38.488356266218339, -8.187016120825001],
              [-38.488312292288661, -8.18697219608349],
              [-38.488264044855384, -8.186933512114958],
              [-38.488201884950506, -8.186912539022627],
              [-38.488139707844709, -8.186905043187144],
              [-38.488076249380029, -8.186905686361612],
              [-38.488017325461009, -8.18690995333877],
              [-38.487965746209937, -8.186921103940961],
              [-38.487910427855475, -8.186945546179127],
              [-38.48785874568118, -8.186966194053889],
              [-38.487802695269693, -8.186995157927669],
              [-38.48775145602081, -8.187024399250797],
              [-38.487701581289173, -8.187051471459787],
              [-38.487644534781765, -8.18707844411148],
              [-38.487590584150588, -8.187098184555879],
              [-38.48753809529849, -8.187110690720784],
              [-38.487474713481049, -8.187122368989469],
              [-38.487415856024604, -8.187145630793806],
              [-38.487352559199032, -8.187161831729796],
              [-38.487297430095843, -8.187180213863602],
              [-38.487241653124848, -8.1872082734863],
              [-38.487188154062011, -8.187229823479573],
              [-38.487145271326717, -8.187255547721167],
              [-38.4870924859054, -8.187286957829645],
              [-38.487047332763119, -8.187313312337254],
              [-38.487013975091209, -8.18734465666083],
              [-38.486960729711825, -8.187380769648394],
              [-38.486905603110316, -8.187397161794044],
              [-38.486844580908901, -8.187409385667436],
              [-38.486784292035637, -8.187416092917244],
              [-38.486723815339971, -8.187427684304216],
              [-38.486668159081482, -8.187432317038574],
              [-38.486607438644043, -8.187421566559358],
              [-38.486560789407619, -8.187411014865827],
              [-38.486508404598041, -8.187413119093804],
              [-38.486494185494287, -8.187457512734069],
              [-38.486496651999538, -8.187516399843561],
              [-38.486495592434231, -8.187563976022622],
              [-38.486468669548586, -8.187603740475852],
              [-38.486432315722837, -8.18763526183954],
              [-38.486401219335932, -8.187673031044884],
              [-38.486375652969997, -8.187716867536654],
              [-38.486368597505574, -8.187767692343716],
              [-38.486364086063254, -8.187816892258168],
              [-38.486349128372424, -8.187870872817268],
              [-38.486305876935461, -8.187900847716108],
              [-38.486298306779659, -8.187928244910108],
              [-38.486298077453505, -8.187965510663817],
              [-38.486249540955448, -8.187939941570351],
              [-38.486211090477987, -8.187907330083039],
              [-38.486183994786579, -8.187869396399066],
              [-38.486184147136584, -8.187821366811628],
              [-38.486196475720014, -8.187764578915782],
              [-38.486189470086018, -8.187705686031922],
              [-38.486174109360661, -8.187649134257089],
              [-38.486147848364197, -8.187597452995499],
              [-38.486113675996201, -8.187556073142989],
              [-38.486067028384426, -8.187544255072529],
              [-38.486016390827807, -8.187528904307207],
              [-38.48596520381934, -8.187517261350447],
              [-38.485912383988577, -8.187504621341468],
              [-38.485857568666496, -8.187490622011541],
              [-38.485791396951583, -8.187483211177339],
              [-38.485732829114909, -8.187492905361465],
              [-38.485703772618436, -8.18749739093367],
              [-38.485651934705658, -8.187497777166429],
              [-38.4855972813465, -8.18749906432158],
              [-38.485536287310651, -8.187489308301073],
              [-38.48548554890148, -8.18748182662782],
              [-38.485439524149449, -8.187479868499777],
              [-38.485396377528147, -8.18749889879544],
              [-38.485349697781849, -8.187512135683688],
              [-38.485292133778209, -8.187517760762381],
              [-38.485236388113357, -8.187521307764996],
              [-38.485180157862899, -8.187548733352106],
              [-38.48513110263287, -8.187573725887503],
              [-38.48507996112091, -8.187597358982886],
              [-38.485036165624862, -8.187626790339023],
              [-38.484989093783732, -8.187662549119869],
              [-38.484950472001273, -8.187692710676846],
              [-38.484914303109939, -8.187721518585327],
              [-38.484880507020961, -8.187740651201587],
              [-38.484899894808422, -8.187701058155753],
              [-38.484906213611325, -8.187658463536723],
              [-38.484882401262226, -8.187608865711855],
              [-38.484850680531821, -8.187567217533148],
              [-38.484819311549245, -8.187534434052802],
              [-38.484781491020783, -8.187506255367675],
              [-38.484738857117229, -8.187479517762268],
              [-38.484696780826333, -8.187442740742407],
              [-38.48469012366732, -8.187395426088038],
              [-38.484666217668988, -8.187348089421555],
              [-38.484633974571274, -8.187359716522222],
              [-38.484614668256938, -8.187406545783077],
              [-38.484609787310255, -8.187460539126359],
              [-38.484606078754261, -8.187520594219272],
              [-38.484588570418531, -8.187581174407477],
              [-38.484585681117828, -8.187639511965981],
              [-38.484565913537871, -8.187692039081114],
              [-38.484530920037088, -8.187724556967282],
              [-38.48448541658788, -8.187740780154686],
              [-38.484441093669261, -8.187756733489335],
              [-38.48439640517023, -8.187774585834491],
              [-38.484348990370911, -8.187794605529893],
              [-38.48430610764224, -8.18782014855141],
              [-38.484259950185901, -8.187850752671606],
              [-38.484210378682022, -8.187853583802672],
              [-38.484155657080393, -8.187837323073957],
              [-38.484108637759832, -8.187832197717766],
              [-38.484072503260116, -8.187834231935369],
              [-38.484081502232932, -8.187825379182692],
              [-38.484136266550443, -8.187808444261501],
              [-38.484186275903511, -8.187818096010774],
              [-38.484241845978602, -8.187809935903511],
              [-38.484289552219636, -8.187775082540828],
              [-38.484345776854433, -8.187752089201989],
              [-38.484398459532351, -8.18773017675079],
              [-38.484447688594756, -8.187711244783815],
              [-38.484458745983055, -8.187654817138712],
              [-38.484432030599258, -8.187603677908069],
              [-38.484402126284586, -8.187561489306891],
              [-38.484358047315183, -8.18752896091863],
              [-38.484307161808985, -8.187494705254286],
              [-38.484270896277827, -8.1874574833554],
              [-38.484249442409535, -8.187409426186601],
              [-38.48424696192091, -8.187361664604779],
              [-38.484246479713732, -8.18731309151123],
              [-38.484293630254321, -8.187286739878854],
              [-38.484328269325857, -8.187247437693042],
              [-38.484276924819895, -8.187217161308471],
              [-38.484214039084954, -8.187196005782456],
              [-38.484169304086237, -8.187179486461245],
              [-38.484108417924908, -8.187156524442797],
              [-38.484045349013329, -8.187136634979115],
              [-38.484006168576357, -8.187107097715808],
              [-38.484017123112906, -8.187060076912569],
              [-38.48408051115684, -8.187043605268455],
              [-38.484143957272963, -8.187052550571337],
              [-38.484204038009537, -8.187066466400706],
              [-38.484255499457078, -8.187076391345574],
              [-38.484305702624219, -8.187076455458969],
              [-38.484354294030751, -8.187059150811653],
              [-38.4843929358004, -8.187013431669694],
              [-38.484402176923673, -8.186957453962192],
              [-38.484409588186146, -8.186912509006513],
              [-38.484410920341901, -8.186864933194318],
              [-38.484394013556667, -8.186810731132931],
              [-38.484380012420743, -8.186756080523095],
              [-38.484362559664369, -8.186702872730498],
              [-38.484340384207577, -8.186651196583339],
              [-38.48431811680949, -8.186600424833284],
              [-38.484292576335257, -8.18655344786743],
              [-38.484270846316612, -8.186508375248781],
              [-38.484244842628492, -8.186468633812485],
              [-38.484214844719247, -8.186428706370501],
              [-38.484194925025278, -8.186387796831557],
              [-38.484158117460417, -8.18634858430098],
              [-38.484116304609856, -8.186318862786081],
              [-38.484075761216168, -8.186290318758873],
              [-38.484046030215119, -8.186254642869324],
              [-38.484024847382329, -8.186207671456042],
              [-38.484018917510696, -8.186159634110638],
              [-38.484061808102986, -8.186127849961364],
              [-38.484116355353564, -8.186138321568212],
              [-38.484164463059393, -8.186144081462734],
              [-38.484209884976558, -8.186120531620666],
              [-38.484228825573368, -8.186075691842367],
              [-38.484187478412913, -8.186036835324263],
              [-38.484144304543435, -8.186006569366102],
              [-38.484105662966414, -8.185981645834218],
              [-38.484059126644723, -8.185954089118916],
              [-38.484011592298238, -8.185926078865837],
              [-38.483975045033894, -8.18589627361454],
              [-38.483929605113445, -8.185863291193536],
              [-38.483896234417571, -8.185904675358968],
              [-38.483867834541989, -8.185963342118088],
              [-38.483835277734478, -8.186007169509116],
              [-38.48380028771124, -8.186037064249721],
              [-38.483753252302172, -8.186044602059908],
              [-38.483709557345193, -8.186066525929558],
              [-38.483660437415644, -8.186071347517704],
              [-38.483633531111288, -8.186027625024987],
              [-38.483593070877056, -8.186005050860651],
              [-38.483592901454465, -8.186066105432237],
              [-38.483612705473682, -8.186126281026596],
              [-38.483636873261098, -8.186181668267889],
              [-38.483659130415887, -8.186240309320635],
              [-38.48368992759665, -8.186293986455693],
              [-38.483724899761057, -8.186348482989596],
              [-38.483751451232102, -8.186385782964631],
              [-38.483794792349677, -8.186427174718029],
              [-38.483843770766121, -8.186461789806668],
              [-38.483868775215385, -8.186501982246991],
              [-38.483882062817337, -8.186546682289162],
              [-38.483891076741948, -8.186596713510198],
              [-38.483889833680429, -8.18664555575301],
              [-38.483881510925123, -8.186693303529347],
              [-38.483843330269764, -8.186733415215407],
              [-38.483786844122619, -8.186748086616593],
              [-38.483742348462741, -8.186757255794268],
              [-38.483695331373156, -8.186750502272913],
              [-38.483647226051851, -8.186742842839408],
              [-38.483594129170456, -8.186734001147512],
              [-38.483547571246746, -8.186723177877424],
              [-38.48349974177826, -8.186712805233405],
              [-38.483434658957819, -8.186705938125041],
              [-38.483365118088351, -8.186706572780398],
              [-38.483307196770376, -8.186707764999495],
              [-38.48325565056345, -8.186693226801429],
              [-38.483206931698895, -8.186668561643696],
              [-38.483171669158942, -8.18662808468377],
              [-38.483169560326473, -8.186573811067126],
              [-38.483210818562178, -8.186540758605213],
              [-38.483257703910752, -8.186579260499949],
              [-38.483286792732883, -8.186620091359739],
              [-38.483340323339583, -8.186644581760698],
              [-38.483386610412872, -8.186654228837503],
              [-38.483433634243603, -8.186655736213519],
              [-38.483484468289006, -8.186659328842566],
              [-38.48353756935218, -8.186664914298259],
              [-38.483586306997864, -8.186675016744202],
              [-38.483637946912886, -8.186687293732145],
              [-38.48368696040891, -8.186694682972805],
              [-38.483734612508393, -8.186701799112662],
              [-38.483782392197561, -8.186680332724157],
              [-38.483807431417489, -8.186623018454535],
              [-38.483820484105415, -8.186568131054065],
              [-38.483789149060243, -8.186509116599051],
              [-38.483742731557392, -8.186459761176749],
              [-38.483706921366512, -8.186421544849399],
              [-38.483670034948176, -8.18637310611955],
              [-38.483635682194766, -8.186331092685352],
              [-38.483598889838362, -8.186280121427043],
              [-38.48356272295274, -8.186236929797714],
              [-38.483539917618479, -8.186181001585611],
              [-38.483537730768361, -8.186116778197961],
              [-38.483529271730845, -8.186058878397013],
              [-38.483527720596257, -8.185994474918907],
              [-38.483551738586087, -8.185954706955242],
              [-38.483583558669004, -8.185919019285098],
              [-38.483633232131673, -8.185907324095945],
              [-38.483683139968129, -8.185925116402011],
              [-38.483737770623861, -8.185941286611472],
              [-38.483779644314659, -8.185923702083883],
              [-38.483742839278349, -8.185882590040501],
              [-38.48369045595836, -8.185883698949297],
              [-38.483648537148888, -8.18586582640647],
              [-38.483599611741276, -8.185860608116629],
              [-38.483564436187926, -8.185893577951806],
              [-38.483531510746552, -8.185941656067714],
              [-38.483496404989154, -8.185990907262005],
              [-38.483468578881059, -8.186026961838525],
              [-38.48344074973793, -8.186065368148817],
              [-38.483392826178843, -8.186128080216886],
              [-38.483364633422589, -8.186166847861873],
              [-38.483335987910927, -8.186204710409969],
              [-38.483304528234193, -8.18624247890377],
              [-38.483270883927894, -8.186284767176176],
              [-38.483240781671668, -8.186325884107474],
              [-38.483204765097327, -8.186377395394103],
              [-38.48317175053721, -8.186424116587498],
              [-38.48314700026777, -8.186468496614502],
              [-38.483125793917715, -8.186510258080864],
              [-38.483096326178106, -8.186552099423363],
              [-38.483069044109598, -8.186588606920839],
              [-38.483045676443318, -8.186616797886135],
              [-38.483024809841623, -8.186606278797735],
              [-38.483013929273895, -8.186595862943467],
              [-38.483041504808163, -8.186542984099221],
              [-38.483055442567967, -8.186505826362016],
              [-38.483003539680432, -8.186486312843133],
              [-38.482942935614879, -8.18645593399456],
              [-38.482886137917269, -8.186430625296712],
              [-38.482849250435301, -8.186383091011784],
              [-38.482834111705543, -8.186365976287586],
              [-38.482808199657242, -8.186325692175982],
              [-38.482773109418574, -8.186292451527608],
              [-38.482717411422804, -8.186259184482099],
              [-38.482678678553057, -8.186234712958512],
              [-38.482633591806028, -8.186209509679891],
              [-38.482586319267092, -8.186189730687669],
              [-38.482539039261553, -8.186175740578049],
              [-38.482475494728803, -8.18617285515916],
              [-38.482429083093187, -8.186189438764028],
              [-38.482401511011247, -8.186239604022797],
              [-38.482430599719827, -8.186280434937926],
              [-38.482468949712235, -8.186320192301112],
              [-38.482498953153005, -8.186355687744417],
              [-38.482528489023849, -8.186401765414717],
              [-38.482552484328075, -8.186450006820786],
              [-38.482569854335814, -8.1864967925129],
              [-38.482568740188967, -8.18651596680805],
              [-38.482531489256409, -8.18646870338503],
              [-38.482496051543237, -8.186423341764375],
              [-38.482458977173003, -8.186379967977114],
              [-38.482421264876727, -8.186338492851208],
              [-38.482382551521724, -8.186298915921187],
              [-38.482351575057919, -8.186243610339913],
              [-38.482369979923952, -8.18619198611138],
              [-38.48240798201617, -8.186149613052526],
              [-38.482445241372254, -8.186119992702263],
              [-38.482502911616095, -8.186102247651345],
              [-38.482550905384947, -8.186125916992106],
              [-38.482605431688789, -8.186152579596554],
              [-38.482658138370368, -8.186182315215005],
              [-38.482683988905926, -8.186199895923615],
              [-38.482718910983749, -8.186222734438124],
              [-38.482747783156697, -8.186220329225852],
              [-38.482734298138183, -8.186188020760135],
              [-38.482705748383303, -8.186151622680214],
              [-38.482676386137676, -8.186111695948268],
              [-38.482638865561754, -8.186062351805269],
              [-38.482604334998939, -8.186017534065867],
              [-38.482571252769752, -8.185975974434267],
              [-38.48253690063575, -8.185933599109569],
              [-38.482507717886925, -8.185895300724388],
              [-38.482467449809967, -8.185864586060909],
              [-38.482414250619769, -8.185864789243324],
              [-38.482366951274138, -8.185865814043982],
              [-38.482327307364429, -8.185843873964819],
              [-38.482297038516876, -8.185803041531161],
              [-38.48228798918305, -8.185780597954432],
              [-38.482305402581083, -8.185793735745447],
              [-38.482354842639609, -8.185822200891362],
              [-38.482406046466082, -8.185820547934906],
              [-38.482463694717524, -8.185819807745061],
              [-38.482519318192246, -8.185840411512441],
              [-38.482553673235316, -8.185880525553856],
              [-38.482595186603348, -8.185931141140037],
              [-38.482625731925822, -8.185968808104274],
              [-38.482656370487966, -8.186004575703917],
              [-38.482687004970771, -8.186043509099692],
              [-38.482719902734026, -8.186087329774587],
              [-38.482755610578479, -8.186134410301054],
              [-38.482786236912432, -8.186179675286578],
              [-38.482817587650977, -8.186226388422281],
              [-38.482845947921298, -8.186268936956321],
              [-38.482879306751983, -8.186307240667213],
              [-38.482909126680617, -8.186344273523185],
              [-38.482946839847294, -8.186385115447067],
              [-38.482995919926786, -8.18641122832029],
              [-38.483053190556099, -8.186421794009101],
              [-38.483105776967143, -8.186404042365925],
              [-38.48313759840844, -8.186367359771237],
              [-38.483174345219425, -8.186312774073455],
              [-38.483199813928209, -8.186274455215626],
              [-38.483236468539644, -8.186220954811331],
              [-38.483259387615604, -8.186188602487725],
              [-38.483288206538916, -8.186157071774643],
              [-38.483320841843806, -8.186122832395448],
              [-38.483350663217657, -8.186088318058989],
              [-38.483379129833573, -8.186048374894918],
              [-38.48341386769976, -8.186002831754516],
              [-38.483436346118523, -8.185960167380184],
              [-38.483472985170089, -8.185918696984418],
              [-38.483427320723322, -8.185919090839107],
              [-38.483388148483286, -8.185953774124938],
              [-38.483340678862575, -8.185946025022456],
              [-38.483321674221983, -8.185899599073235],
              [-38.483320009140982, -8.185853195305642],
              [-38.483343307287839, -8.185808451599527],
              [-38.483373313114527, -8.185771676206858],
              [-38.483398525734437, -8.185720693789474],
              [-38.483421374610799, -8.18567233144147],
              [-38.483364837680668, -8.185656068302109],
              [-38.483304349647092, -8.185676613875598],
              [-38.48325221655611, -8.185694908840825],
              [-38.483201298032455, -8.185686522150483],
              [-38.483171838454226, -8.185651660614134],
              [-38.483186249551167, -8.185599398067591],
              [-38.483205549282566, -8.18555772460482],
              [-38.483210999989893, -8.185554837129883],
              [-38.483254796537366, -8.18552450144904],
              [-38.483306361362885, -8.185524476965414],
              [-38.483349869700731, -8.185506442314002],
              [-38.483364730370866, -8.185457527040919],
              [-38.48335762145544, -8.185408674117538],
              [-38.483355596617024, -8.185359646794081],
              [-38.483379344213816, -8.18531834081619],
              [-38.48342530564495, -8.185298771616258],
              [-38.483471879364672, -8.18529720305026],
              [-38.483506383620501, -8.185292000983875],
              [-38.483539004153293, -8.185269158455441],
              [-38.483555930078822, -8.185237883864048],
              [-38.483559519454161, -8.185270451008197],
              [-38.483538946833093, -8.18531366054015],
              [-38.483507695147246, -8.185330987274716],
              [-38.483467349205242, -8.185360694237763],
              [-38.483485616275914, -8.185415893036929],
              [-38.483506164226363, -8.185462320952823],
              [-38.483545694087347, -8.185502351080419],
              [-38.483603041294984, -8.185523861419036],
              [-38.483653946530772, -8.18554255951668],
              [-38.48366885920754, -8.185594226410837],
              [-38.483640583189732, -8.18562720507496],
              [-38.483606666973365, -8.18566922167968],
              [-38.483570576152289, -8.185707979245093],
              [-38.483541113647746, -8.185745840767135],
              [-38.483571074015785, -8.185744341400389],
              [-38.483633195094001, -8.18572461193847],
              [-38.48366576754988, -8.185739125826776],
              [-38.483660105573499, -8.185765078180509],
              [-38.483715004362708, -8.185784504990098],
              [-38.483763396012769, -8.185781220138423],
              [-38.483774798856366, -8.185738541585176],
              [-38.483786298419489, -8.185691250126371],
              [-38.483828638339709, -8.185664078324074],
              [-38.483875664271679, -8.185663867065093],
              [-38.483934102897443, -8.185683841077461],
              [-38.483977911731735, -8.185714469672913],
              [-38.484012006908394, -8.18574499540291],
              [-38.484050443379104, -8.185788189892522],
              [-38.484078708086223, -8.185834537189862],
              [-38.484120409037374, -8.185880630293084],
              [-38.484167760890003, -8.185909363912854],
              [-38.484223481312881, -8.18592526409485],
              [-38.484278126422019, -8.185930218259037],
              [-38.484334679721968, -8.185933818078992],
              [-38.484383684885898, -8.185947629279932],
              [-38.484431766637137, -8.185973559804077],
              [-38.484455089508543, -8.185980102087525],
              [-38.484475621948043, -8.185968098240062],
              [-38.484499613097313, -8.185949134036607],
              [-38.484497305142668, -8.185978980102599],
              [-38.484518678659065, -8.186018896516659],
              [-38.484537771787593, -8.186067222013573],
              [-38.48457122737036, -8.186101003139802],
              [-38.484614676075687, -8.18612936992294],
              [-38.484646185948201, -8.186123078523051],
              [-38.484654235763394, -8.186146334843702],
              [-38.484670015164681, -8.186159470453157],
              [-38.484716355225508, -8.186198514198622],
              [-38.484755816702098, -8.186221267884104],
              [-38.484819821124653, -8.186219540503046],
              [-38.484859585641232, -8.186218415355071],
              [-38.484922394079376, -8.186228987843741],
              [-38.484982389522706, -8.186238561768526],
              [-38.485039684595328, -8.186230132381615],
              [-38.485100320519713, -8.18623581768872],
              [-38.485149679940704, -8.186256594022026],
              [-38.485197575454791, -8.186286232736295],
              [-38.485247777136856, -8.186287382143423],
              [-38.485296153286392, -8.186296217600377],
              [-38.485346421138289, -8.186316542810571],
              [-38.485410302753422, -8.18633977981121],
              [-38.485461924470833, -8.186366257438966],
              [-38.485500287325998, -8.186396245780402],
              [-38.48553519322995, -8.186431837711771],
              [-38.485580177930615, -8.186465995253576],
              [-38.485615358634455, -8.186499688045826],
              [-38.485660350080281, -8.186528599397359],
              [-38.485724419001585, -8.186547404463136],
              [-38.485785506701539, -8.1865548088258],
              [-38.485831892164548, -8.186558576398737],
              [-38.485899250522223, -8.186560832988599],
              [-38.485963791122558, -8.186565799527909],
              [-38.486030969888475, -8.186566518190615],
              [-38.486076442191006, -8.186574445345764],
              [-38.486143885425818, -8.186581315033179],
              [-38.486193361700558, -8.186581830241098],
              [-38.486259278779613, -8.186575311135131],
              [-38.486305128709489, -8.18657193229854],
              [-38.486369512785231, -8.186557361034341],
              [-38.486432630179053, -8.186539441440441],
              [-38.486479669439227, -8.186528918421722],
              [-38.486527978156154, -8.186519572881885],
              [-38.486572931020085, -8.186507780877218],
              [-38.486617878901072, -8.186499878278839],
              [-38.48666455502017, -8.18648935477637],
              [-38.486706430281615, -8.186470503621337],
              [-38.486762575407305, -8.186438464665978],
              [-38.486803283908777, -8.186409119634135],
              [-38.4868446238346, -8.18638294120546],
              [-38.486891123694349, -8.18636825667879],
              [-38.486941162821694, -8.186354571610202],
              [-38.486986706116085, -8.186377965973451],
              [-38.487020709766156, -8.186409305413793],
              [-38.487058962935095, -8.186383213493084],
              [-38.487103587469214, -8.186344195083361],
              [-38.487108095090605, -8.18629788959641],
              [-38.487093819507109, -8.186244595483172],
              [-38.487083177335705, -8.186189677855507],
              [-38.487081060217925, -8.186141464471726],
              [-38.487079941367533, -8.186093523710451],
              [-38.487098345398636, -8.186042080130747],
              [-38.487134607314744, -8.186011282188643],
              [-38.487175487246802, -8.185989806620242],
              [-38.487216641490598, -8.185966793718665],
              [-38.48727667063136, -8.185950045934069],
              [-38.487340948761741, -8.185947233078499],
              [-38.487400066826581, -8.185933016763203],
              [-38.487454851350591, -8.185899981008449],
              [-38.487502189109982, -8.185868925731514],
              [-38.487551173327482, -8.185828194216983],
              [-38.487595526905501, -8.185787999530723],
              [-38.487643880014694, -8.185743830042396],
              [-38.487683519790529, -8.18569820226972],
              [-38.487709808442169, -8.185657261041536],
              [-38.487732921993739, -8.185614506752298],
              [-38.487752772205177, -8.185567949355555],
              [-38.487769631541404, -8.185517498745726],
              [-38.487776863138592, -8.185470744440764],
              [-38.487777103982054, -8.185424343076424],
              [-38.487767634860653, -8.185374944495262],
              [-38.487746200070866, -8.185311691644378],
              [-38.487732547081777, -8.185268438465137],
              [-38.487733800176819, -8.185211365095794],
              [-38.487755098038825, -8.18516860849995],
              [-38.487796165889023, -8.185142067809302],
              [-38.487840033331636, -8.185127018017171],
              [-38.487864683791145, -8.185089240503379],
              [-38.487849745277984, -8.185057382599098],
              [-38.487817003384443, -8.185033280957443],
              [-38.487767459497157, -8.185014766005699],
              [-38.487727641054235, -8.184987127770921],
              [-38.487702830103352, -8.184937347924656],
              [-38.48769380936632, -8.184892291587332],
              [-38.487699683373833, -8.184842279302787],
              [-38.48770440997535, -8.184837672265749],
              [-38.487724951511538, -8.184818432126553],
              [-38.487786512908393, -8.184810188647278],
              [-38.487853159413135, -8.184800956638172],
              [-38.487901829411719, -8.184792877699172],
              [-38.487948320043095, -8.184785248249513],
              [-38.488000706129348, -8.184781877492451],
              [-38.488052004322306, -8.184777329478882],
              [-38.488103488467956, -8.184769344533352],
              [-38.488162229235158, -8.184766072259725],
              [-38.488214349088743, -8.184757907202165],
              [-38.488266919507311, -8.18475236580518],
              [-38.488322483219179, -8.184748818128263],
              [-38.488408925211267, -8.184735812133361],
              [-38.48845722929422, -8.184729903501454],
              [-38.488505521953883, -8.184732949558745],
              [-38.488559366095878, -8.1847252388974],
              [-38.488618096821469, -8.184729835834458],
              [-38.488651464995939, -8.184761174334731],
              [-38.488688989118309, -8.184808256673987],
              [-38.488726254163495, -8.184844936746682],
              [-38.488777149907641, -8.184871232132071],
              [-38.488825151099611, -8.18488929275145],
              [-38.488839760454738, -8.184894557432926],
              [-38.48884605748971, -8.184939881660576],
              [-38.488842872363414, -8.184945937888118],
              [-38.488808891543265, -8.184967874627553],
              [-38.488850690224552, -8.185008902105077],
              [-38.488898042466985, -8.185037544734595],
              [-38.488952302287572, -8.185059864485449],
              [-38.489011270723395, -8.185091687605798],
              [-38.489058525635279, -8.185125485836673],
              [-38.489107593448189, -8.185161457194344],
              [-38.489156568067145, -8.185199327912581],
              [-38.489205093850643, -8.185233218186616],
              [-38.489246714261107, -8.185271712755872],
              [-38.489284430666061, -8.185310473739873],
              [-38.489325791530426, -8.185338927843963],
              [-38.489366432126801, -8.18536267754925],
              [-38.489420973583577, -8.185377761467391],
              [-38.489460071224549, -8.185401237858461],
              [-38.489507082192745, -8.185412784633563],
              [-38.489567819590924, -8.185410057393822],
              [-38.489622392034306, -8.185400809838091],
              [-38.489679397312372, -8.185405947156831],
              [-38.489719065110506, -8.185409434435195],
              [-38.489766562807908, -8.185395203082342],
              [-38.489805156862801, -8.185386387574765],
              [-38.48987565935051, -8.185414697530947],
              [-38.489938752275485, -8.185415772178592],
              [-38.489999688843653, -8.185399206026231],
              [-38.490051171551052, -8.18539239670446],
              [-38.490063520110368, -8.185390784165453],
              [-38.490121829714653, -8.185369692095938],
              [-38.490175790010007, -8.185342082010447],
              [-38.490227759397257, -8.185309494564791],
              [-38.490270201047622, -8.185273367474524],
              [-38.490303839961349, -8.185234696625605],
              [-38.490336838903964, -8.185199552579654],
              [-38.490386254036828, -8.18517664021344],
              [-38.490454337120006, -8.18517998236524],
              [-38.490509896293467, -8.18518005244956],
              [-38.490558746032093, -8.185173420643059],
              [-38.490623114944853, -8.185170516918889],
              [-38.490670602592168, -8.185164154735755],
              [-38.490736787737376, -8.185160801025432],
              [-38.490806060421768, -8.185156727583273],
              [-38.490852912699978, -8.185150274125858],
              [-38.490913942047932, -8.18513207981794],
              [-38.490958166898622, -8.185121462260943],
              [-38.49102471792461, -8.18511593812446],
              [-38.491072197066529, -8.185116269304309],
              [-38.491118401459339, -8.185119583776904],
              [-38.491183946755704, -8.185119666348987],
              [-38.491245879785076, -8.185104729387334],
              [-38.491288415667796, -8.185065798300514],
              [-38.491322881958133, -8.185018897301418],
              [-38.491355994298445, -8.184965843881754],
              [-38.491361154096857, -8.184905971387487],
              [-38.491358782469277, -8.184843285408219],
              [-38.491329895010807, -8.184786264492708],
              [-38.491294198271014, -8.184729687258418],
              [-38.491249774910855, -8.184682144195612],
              [-38.491218317097626, -8.184647552044844],
              [-38.491186776540843, -8.184606628172322],
              [-38.491154608430094, -8.184559462343367],
              [-38.491123880684952, -8.184521886201397],
              [-38.491091969325197, -8.184487022115647],
              [-38.491045092239411, -8.18444155632938],
              [-38.491007366201679, -8.184410302977367],
              [-38.490973894331368, -8.184389004663876],
              [-38.49089017634239, -8.184330196044378],
              [-38.490856152438937, -8.184314685931335],
              [-38.490806525616861, -8.184289930080748],
              [-38.490769328228936, -8.184271340604678],
              [-38.49072287892939, -8.184246498298252],
              [-38.490683881288284, -8.184215786025023],
              [-38.490637347996511, -8.184185606956557],
              [-38.490605528063597, -8.184150290693001],
              [-38.490572913037816, -8.184097697160958],
              [-38.490539648292838, -8.184056318810869],
              [-38.49050673933089, -8.184020729812119],
              [-38.490470016122629, -8.183986221419804],
              [-38.490422306376942, -8.183953146392449],
              [-38.490376040656393, -8.183926766602983],
              [-38.490342399595896, -8.183895789702175],
              [-38.490319851958454, -8.183850716339155],
              [-38.490297134486305, -8.183796416693541],
              [-38.49027866874372, -8.183753971576278],
              [-38.490268198543966, -8.183706290351529],
              [-38.490250831626817, -8.18365642958522],
              [-38.490241923131443, -8.183594278068075],
              [-38.490239838622969, -8.183520105093182],
              [-38.490235895235202, -8.183479939589247],
              [-38.49025306199411, -8.183472996473423],
              [-38.490309910737338, -8.183458234129064],
              [-38.490373228371283, -8.183425118253316],
              [-38.490463306394851, -8.183408136511003],
              [-38.49050958217574, -8.183426556555906],
              [-38.490553571507149, -8.183458270104397],
              [-38.490571887757717, -8.183475479013305],
              [-38.490590454988379, -8.183509512243845],
              [-38.490612104924239, -8.1835466247166],
              [-38.490641558261991, -8.183586822382896],
              [-38.490678094372576, -8.183625672202055],
              [-38.490723705651291, -8.183666975653072],
              [-38.490761436537618, -8.183694339612513],
              [-38.490802804247188, -8.183717366473706],
              [-38.490849161561975, -8.183743113168775],
              [-38.490896705487529, -8.183763796061877],
              [-38.49093997838542, -8.18378782027888],
              [-38.490989692839477, -8.183815018415425],
              [-38.491036232203022, -8.183840403513072],
              [-38.491074055288145, -8.183866682141717],
              [-38.491128747085405, -8.183906278408637],
              [-38.491192910016316, -8.18392245961997],
              [-38.491259092432479, -8.183921095744125],
              [-38.491306023411497, -8.183924049295683],
              [-38.491352856496064, -8.183932610721019],
              [-38.491401593495894, -8.183943164474421],
              [-38.491451692812632, -8.183953267678797],
              [-38.491499890627161, -8.183959479062432],
              [-38.491546465020079, -8.18395727639315],
              [-38.491610446643875, -8.18397327640816],
              [-38.491670325968279, -8.184002748548242],
              [-38.491713241658417, -8.184022159202378],
              [-38.491757892885538, -8.184033160031444],
              [-38.491809988757296, -8.184043808414435],
              [-38.491830275712928, -8.184010366820537],
              [-38.49181905682385, -8.183980232305244],
              [-38.491850840514537, -8.183972583895123],
              [-38.491912442438377, -8.184003867200424],
              [-38.491961701244655, -8.184032511876129],
              [-38.492012050711352, -8.184060162949454],
              [-38.492065956292713, -8.184075517062059],
              [-38.492124352502039, -8.184057500149693],
              [-38.492166678445422, -8.184040819802043],
              [-38.492224991994647, -8.18401638070244],
              [-38.492273888526157, -8.183972663553787],
              [-38.492294559183257, -8.183922941116258],
              [-38.492303431749015, -8.183870309397038],
              [-38.492314119278625, -8.183818222668245],
              [-38.492317366748289, -8.183762870329161],
              [-38.492315701991458, -8.183715381111821],
              [-38.492319857056657, -8.183660210816214],
              [-38.492344878615462, -8.183615740038359],
              [-38.492380768553296, -8.183591453636911],
              [-38.492425187818078, -8.183570524659922],
              [-38.492395181926724, -8.183608114854083],
              [-38.492373809768722, -8.183638298857433],
              [-38.492372663018202, -8.18368361370233],
              [-38.492388758790575, -8.183733834602734],
              [-38.49241119733324, -8.183793560897316],
              [-38.492433456720732, -8.183851387480694],
              [-38.492454267254161, -8.183906317789416],
              [-38.492478625530332, -8.183955554099221],
              [-38.492501983599681, -8.184006055474658],
              [-38.492541988794621, -8.184029804066906],
              [-38.492597824752714, -8.18402625613547],
              [-38.492658302644678, -8.184013216600535],
              [-38.492719764841389, -8.184011484751228],
              [-38.492782107180275, -8.184031643302378],
              [-38.492837282265008, -8.184048174779308],
              [-38.492895734111727, -8.184057926486794],
              [-38.492913337386, -8.184064732459191],
              [-38.492955540269399, -8.184073559244649],
              [-38.492994208917146, -8.184077406747976],
              [-38.493005191800158, -8.184078867758776],
              [-38.493054222145936, -8.184072869027888],
              [-38.493103534442547, -8.18405927270395],
              [-38.493157742716896, -8.184050657358689],
              [-38.493239943163751, -8.184017474265055],
              [-38.493300792398848, -8.183997922577484],
              [-38.49336826156901, -8.183984077638474],
              [-38.493414493060534, -8.183965864375768],
              [-38.493473757160857, -8.183979415988954],
              [-38.493548004529657, -8.183989730117441],
              [-38.49362280802066, -8.183990999761228],
              [-38.493703329683647, -8.183993181074618],
              [-38.493770426578074, -8.183986571735771],
              [-38.493843519127353, -8.183976804088903],
              [-38.493903764279011, -8.184004286429007],
              [-38.493970503863814, -8.183993063573144],
              [-38.494015934950497, -8.183961643290896],
              [-38.494081622560444, -8.183920750944054],
              [-38.494137033797259, -8.183894046633311],
              [-38.494201431033133, -8.183868529437335],
              [-38.494264911423066, -8.183850156766558],
              [-38.494328123679516, -8.183828437037134],
              [-38.494379915554568, -8.183792230732861],
              [-38.494433970602465, -8.183761092548492],
              [-38.494490657770278, -8.183730409908392],
              [-38.494539804152836, -8.183704330861602],
              [-38.494602627367939, -8.183703143135551],
              [-38.494672199730083, -8.183677180076312],
              [-38.494740228550405, -8.18365139597744],
              [-38.494807790004437, -8.183636284582905],
              [-38.494867836543058, -8.183605244280978],
              [-38.494946949368355, -8.183572690126661],
              [-38.494989104073326, -8.183547687701878],
              [-38.495048503832919, -8.183525601281607],
              [-38.495110813527504, -8.183499629065553],
              [-38.49519226218554, -8.183486524896709],
              [-38.495236710567475, -8.183442349548491],
              [-38.495253354694839, -8.183489947941837],
              [-38.495323158423311, -8.183568637681539],
              [-38.495385309952638, -8.183596212636736],
              [-38.49546237272272, -8.183605625411744],
              [-38.495532606771768, -8.183630949146396],
              [-38.495614567837222, -8.18364353381938],
              [-38.495689728851218, -8.183649235657251],
              [-38.495771607560009, -8.183655126769212],
              [-38.4958462213825, -8.183662817833001],
              [-38.49591068641918, -8.183655481235073],
              [-38.495968995850916, -8.183634116930792],
              [-38.496039336410242, -8.183646958375336],
              [-38.496117215575367, -8.183656914755606],
              [-38.496192024495791, -8.183653842244647],
              [-38.496265756179824, -8.183641090042451],
              [-38.496339217556539, -8.183626709358448],
              [-38.496404157622294, -8.183602549252708],
              [-38.496465467963262, -8.183577118287783],
              [-38.496528872543792, -8.18354671507818],
              [-38.496582296130349, -8.183512229093262],
              [-38.496634623958599, -8.183482807032107],
              [-38.496701827195608, -8.183463714992397],
              [-38.496762951507058, -8.183441811365439],
              [-38.49681346763289, -8.1834092212125],
              [-38.496889093997368, -8.183333426396194],
              [-38.496936352268236, -8.183364962446595],
              [-38.496985947639537, -8.183415044006734],
              [-38.49704608731097, -8.183454374932264],
              [-38.497106964938006, -8.18348439023964],
              [-38.49717567290601, -8.183496053582296],
              [-38.497237131190651, -8.183497396393848],
              [-38.497293118366329, -8.183517998607822],
              [-38.497362027524247, -8.183514014019437],
              [-38.497428554569247, -8.183527393189392],
              [-38.497520358653297, -8.183581778425456],
              [-38.497567752435664, -8.183577676589945],
              [-38.497636379625362, -8.183581380002385],
              [-38.497710811699058, -8.183589251400514],
              [-38.497780904442628, -8.183582554660477],
              [-38.497852976963365, -8.183589789937271],
              [-38.497921891116327, -8.183581825383252],
              [-38.497986995790242, -8.183571142531331],
              [-38.498054275963241, -8.183563085472244],
              [-38.498130810989608, -8.183559019775634],
              [-38.49819229476023, -8.183540010831205],
              [-38.498256864571488, -8.183521457968538],
              [-38.498325334865115, -8.183505442587617],
              [-38.498365682770647, -8.183473201413852],
              [-38.498344136454669, -8.183425235232754],
              [-38.498333319582272, -8.183363624159654],
              [-38.498315867885566, -8.183308155566518],
              [-38.498312887743431, -8.183223036769284],
              [-38.498341270415551, -8.183176308457726],
              [-38.498330253993899, -8.183129078964875],
              [-38.49828789438304, -8.183100353159753],
              [-38.498250899777638, -8.183064578776891],
              [-38.498223552914887, -8.183009007439841],
              [-38.49822779963776, -8.182952480370295],
              [-38.49826571163819, -8.182908025191265],
              [-38.49831939411164, -8.182884122138056],
              [-38.498353684070281, -8.18283224540844],
              [-38.498347054742275, -8.182761413455333],
              [-38.498342967213027, -8.182690675109637],
              [-38.498346236120923, -8.182617503700142],
              [-38.498366822873791, -8.182561630076194],
              [-38.498408816751045, -8.182519803048342],
              [-38.498463397940554, -8.182503137228087],
              [-38.49853475296198, -8.182503225793454],
              [-38.498589375867816, -8.182453183309134],
              [-38.498614519322032, -8.182528470372549],
              [-38.49863605081859, -8.182588285702236],
              [-38.498683572458489, -8.182627058019715],
              [-38.498721194518467, -8.182669255216963],
              [-38.498774522527562, -8.182711019636457],
              [-38.498841962752316, -8.182720058017461],
              [-38.498906072164814, -8.182706569768159],
              [-38.498974274921942, -8.182686574057527],
              [-38.49904827058252, -8.182680334185278],
              [-38.499116264203714, -8.182682408420927],
              [-38.499185403891573, -8.18271180049849],
              [-38.499248003069418, -8.182744531173926],
              [-38.499316094826234, -8.18274072613351],
              [-38.499377226131749, -8.182713033190879],
              [-38.499437184819485, -8.182679278517265],
              [-38.499492054033183, -8.182650040117954],
              [-38.499521390329733, -8.182639222245143],
              [-38.499612574680107, -8.18260831022628],
              [-38.499692594461891, -8.182576027584947],
              [-38.499743924932069, -8.182545337539151],
              [-38.49976613824267, -8.182495345211031],
              [-38.499725496569482, -8.182472139238609],
              [-38.499669861802488, -8.182460130716613],
              [-38.499618424359319, -8.182431032000475],
              [-38.499615804305549, -8.182420898157389],
              [-38.499603608026483, -8.182373305421622],
              [-38.499612577938684, -8.182314613330661],
              [-38.499616183221754, -8.182262698478587],
              [-38.499631662383244, -8.182225722872882],
              [-38.499693573549358, -8.182154975797443],
              [-38.499703958731523, -8.18212613454039],
              [-38.499722261158027, -8.18208192628386],
              [-38.499785032284287, -8.182049531808977],
              [-38.499825828877157, -8.182021270905087],
              [-38.499907995614059, -8.182014407819056],
              [-38.4999715306515, -8.182024617046261],
              [-38.500030371615047, -8.182013473835909],
              [-38.500048385081541, -8.181982561615815],
              [-38.50007593835921, -8.181945510476652],
              [-38.5001288196841, -8.181908671572158],
              [-38.500185704213663, -8.181864782372882],
              [-38.5002439557624, -8.181816643621708],
              [-38.500300295798056, -8.181772572827589],
              [-38.500346539267831, -8.181744318602592],
              [-38.50039666744545, -8.181731265074706],
              [-38.500431308452804, -8.18168879556384],
              [-38.500448622197815, -8.181636535836335],
              [-38.500488728825125, -8.181579058079739],
              [-38.500549059842839, -8.181537705733858],
              [-38.500618996766804, -8.181510023469267],
              [-38.500689762881066, -8.181472573423273],
              [-38.500738306803775, -8.181419447749517],
              [-38.500769335593638, -8.181361596937945],
              [-38.500807353255304, -8.181304840178267],
              [-38.500824682014262, -8.181240459908173],
              [-38.500832861910325, -8.181159877465126],
              [-38.500825526265174, -8.181072672875576],
              [-38.500808201095595, -8.180988259951896],
              [-38.500772884410338, -8.180917211667788],
              [-38.500735275558569, -8.180864341359651],
              [-38.500713643806442, -8.180811943046956],
              [-38.500692118151498, -8.180747243420466],
              [-38.500757019701716, -8.180753474323598],
              [-38.50084336665153, -8.180742998122888],
              [-38.500900237995694, -8.180709510746357],
              [-38.500931445340605, -8.180654011882895],
              [-38.500940258532964, -8.180575148799591],
              [-38.500950340869508, -8.180497734511508],
              [-38.500957790747279, -8.180420136068532],
              [-38.500969219954506, -8.18035466307942],
              [-38.500997182506651, -8.180280346227027],
              [-38.501023483637049, -8.180228007108029],
              [-38.501032680181787, -8.180278852373313],
              [-38.501043451068966, -8.180304734885496],
              [-38.501076804633044, -8.180348373829034],
              [-38.501111984979552, -8.180383060296922],
              [-38.501132435024225, -8.18043672345765],
              [-38.501124178375854, -8.18050608979614],
              [-38.501098596858142, -8.180563766465257],
              [-38.501091890806627, -8.180627255349432],
              [-38.501088815811272, -8.180690929619441],
              [-38.50108736195677, -8.180765007847212],
              [-38.501107975132108, -8.180833505305442],
              [-38.501132237483105, -8.180887625431604],
              [-38.501155028093443, -8.180957210997278],
              [-38.501193191263141, -8.181002393551219],
              [-38.501255824324858, -8.181007807510813],
              [-38.501303441743076, -8.180969605163734],
              [-38.501337741608282, -8.180909316138345],
              [-38.501371843147972, -8.180862504182965],
              [-38.501424014482247, -8.180812367800694],
              [-38.501473822096571, -8.180764942048951],
              [-38.50151909194237, -8.180716425271637],
              [-38.501574438477647, -8.180668011368063],
              [-38.50163386322739, -8.180625210497112],
              [-38.501682495008403, -8.180574255646011],
              [-38.501732492327356, -8.180520227110325],
              [-38.501807243931459, -8.180489746553313],
              [-38.501896041139815, -8.180479906305642],
              [-38.501970814006533, -8.180505415414357],
              [-38.502028325827972, -8.180540943392616],
              [-38.502085579345504, -8.180565164568458],
              [-38.502137038418688, -8.180576805796498],
              [-38.502195299754646, -8.180593611150321],
              [-38.502154213721965, -8.180635891988208],
              [-38.502117856544409, -8.180671575664928],
              [-38.502067894728874, -8.180697021521208],
              [-38.502005493399608, -8.180724351530595],
              [-38.501955063063065, -8.180761555529953],
              [-38.50193491554537, -8.180829731363957],
              [-38.501963713749703, -8.180886570591204],
              [-38.502013411059202, -8.180927968311169],
              [-38.502070383638753, -8.180959153935104],
              [-38.502138333574841, -8.180996232443023],
              [-38.502207811669585, -8.181045523817177],
              [-38.502255600354125, -8.181088728194823],
              [-38.502184510257891, -8.181094791350906],
              [-38.502108975649058, -8.181097773656887],
              [-38.502036335179902, -8.181109533340512],
              [-38.501969940376306, -8.181136044352641],
              [-38.501903626576237, -8.181170424760404],
              [-38.501858631986643, -8.181216590171847],
              [-38.501816891843454, -8.181273794709893],
              [-38.501787940190503, -8.181340512449152],
              [-38.501769508598258, -8.181415836077695],
              [-38.501770691926097, -8.181486389943696],
              [-38.501807944022538, -8.181534284876255],
              [-38.501844649636702, -8.18158362636092],
              [-38.501874266713486, -8.181639019375487],
              [-38.501843423999297, -8.181693342888261],
              [-38.501808855605624, -8.18175055626244],
              [-38.501783428696527, -8.181829941595673],
              [-38.501758275696503, -8.181908060938984],
              [-38.501725694708469, -8.181973146060836],
              [-38.50169410872023, -8.182041126863885],
              [-38.501671329852066, -8.182108123555659],
              [-38.501661080862661, -8.182173598018387],
              [-38.501626078490105, -8.182214710416204],
              [-38.501573462402931, -8.182257338764105],
              [-38.501510013964882, -8.182323471207624],
              [-38.501484014879736, -8.182351840995331],
              [-38.501435288690494, -8.18240550924128],
              [-38.501420196873376, -8.182423128719057],
              [-38.501387649741815, -8.182460897406218],
              [-38.50133755796525, -8.182517639314387],
              [-38.501294437422835, -8.182589676172775],
              [-38.501290808958224, -8.182660404995774],
              [-38.501277916734068, -8.182734288196587],
              [-38.501242972886253, -8.182801269839336],
              [-38.50119561082181, -8.182852949794656],
              [-38.501142723156661, -8.182894854127852],
              [-38.501096450572042, -8.182946444963271],
              [-38.501045555263445, -8.182992241169361],
              [-38.500978610325056, -8.183022821644975],
              [-38.500910124548554, -8.183051410266163],
              [-38.500848539368654, -8.183079102882724],
              [-38.500760428424172, -8.183120601835602],
              [-38.500722451441739, -8.183144524628563],
              [-38.500684808870609, -8.183191513035283],
              [-38.500652138165478, -8.183255512517837],
              [-38.500624087230293, -8.183327658367453],
              [-38.500597316928356, -8.18339202694432],
              [-38.50057272585304, -8.183456036405447],
              [-38.500542601190674, -8.183516692307634],
              [-38.500508861994376, -8.183563866423029],
              [-38.500466608702204, -8.183595381850175],
              [-38.500406592294233, -8.183602543778498],
              [-38.50034096087726, -8.183598844540148],
              [-38.500276713180739, -8.183577508906772],
              [-38.500207847746061, -8.183546398759285],
              [-38.50014126012028, -8.183508688439456],
              [-38.500088012041459, -8.183475426752903],
              [-38.500036937231528, -8.183446599886002],
              [-38.499988041213953, -8.183417775709698],
              [-38.499922980922328, -8.183392911397892],
              [-38.499854530435094, -8.183393098019447],
              [-38.49990923754693, -8.183421115338566],
              [-38.499948072016146, -8.183437625629743],
              [-38.500001317258253, -8.183473148624653],
              [-38.500046664526245, -8.183508571387543],
              [-38.500098198218964, -8.183533418943375],
              [-38.500145462875288, -8.183560070246559],
              [-38.500189823505011, -8.183585903885799],
              [-38.500260132902781, -8.183623890157612],
              [-38.500306765370283, -8.183647917559533],
              [-38.500349223076263, -8.183670944823639],
              [-38.500396759326279, -8.183698229597496],
              [-38.50043402687642, -8.18373373277975],
              [-38.500438943876205, -8.183794793808767],
              [-38.500438575305992, -8.183872039169371],
              [-38.500425960292915, -8.183941852339942],
              [-38.500415065866818, -8.184015104804899],
              [-38.500390300026993, -8.184073596474716],
              [-38.500391584241562, -8.184135828883329],
              [-38.50035688981086, -8.18422099154369],
              [-38.500338498248261, -8.184263842935902],
              [-38.500371846400547, -8.184312004525891],
              [-38.500420852379968, -8.184325542448859],
              [-38.50047362136204, -8.184306251025482],
              [-38.500529287618861, -8.184293204349576],
              [-38.500577827972663, -8.184316148662651],
              [-38.500634538456772, -8.184339374425278],
              [-38.50070331553092, -8.184368675364071],
              [-38.500643958142206, -8.184429747393727],
              [-38.500562703344308, -8.184505807335158],
              [-38.500496553077838, -8.184554117241623],
              [-38.500430431868295, -8.184579090614886],
              [-38.500373015560889, -8.184612305854911],
              [-38.500318403710885, -8.184653393859325],
              [-38.500258981591344, -8.184693480935056],
              [-38.500209633103715, -8.1847362035667],
              [-38.50017225725194, -8.184787533924515],
              [-38.500130890996701, -8.184835603076465],
              [-38.500085078279916, -8.184882129040433],
              [-38.500073943546369, -8.184929602436856],
              [-38.50006108212753, -8.184978520923973],
              [-38.500058395738598, -8.185021572639366],
              [-38.500101833351373, -8.185059706593309],
              [-38.500173743485881, -8.185124649499638],
              [-38.500211925848134, -8.185154636280743],
              [-38.500273790205618, -8.185194602052979],
              [-38.500308353769832, -8.185214544202308],
              [-38.50038842447244, -8.185214643250907],
              [-38.500437567434936, -8.185191186573602],
              [-38.500497599969655, -8.185171270975479],
              [-38.500559550210276, -8.185142312562203],
              [-38.500622498493129, -8.185113807631566],
              [-38.500695102439543, -8.185131987741526],
              [-38.5007744269329, -8.185148095754251],
              [-38.50084358113596, -8.185166271574499],
              [-38.500922001865597, -8.185179122178194],
              [-38.500999516008179, -8.185190976677397],
              [-38.501075594508485, -8.185189261621993],
              [-38.50115056391882, -8.185203374243718],
              [-38.501225714226386, -8.18521802978595],
              [-38.501283508087909, -8.185246231651934],
              [-38.501356475565103, -8.185264140739532],
              [-38.501435261942987, -8.185275001757796],
              [-38.50151289400862, -8.18526505742609],
              [-38.501590608871766, -8.185261535258196],
              [-38.501664524894572, -8.185246701915196],
              [-38.501746237247254, -8.185240742450643],
              [-38.501817517966558, -8.185227986219408],
              [-38.501885629924558, -8.185208261279737],
              [-38.501958104072827, -8.185184833183602],
              [-38.502025777604146, -8.185152625331719],
              [-38.502085641643404, -8.185122126425215],
              [-38.502140882024641, -8.185086556509958],
              [-38.502194225496169, -8.185043295846256],
              [-38.502249385740861, -8.184999132895573],
              [-38.502311160789084, -8.184964927784357],
              [-38.502377558018672, -8.184936969531256],
              [-38.502449667277745, -8.184914897669412],
              [-38.502503705088252, -8.184897326127167],
              [-38.502595343688739, -8.184866414014538],
              [-38.502650749066987, -8.184844140642245],
              [-38.50270262680052, -8.184811460961848],
              [-38.502763211173843, -8.184785666328853],
              [-38.50281852361887, -8.184765111402115],
              [-38.502851402107034, -8.184753031327068],
              [-38.502883188281423, -8.184743301651787],
              [-38.502966013244091, -8.18471852932703],
              [-38.503040755929646, -8.184695827432916],
              [-38.503113370921341, -8.184705142846939],
              [-38.503177867227862, -8.184745925491976],
              [-38.503187161176363, -8.184791705537673],
              [-38.503213364981484, -8.184817968789805],
              [-38.503252263964619, -8.184855915936154],
              [-38.503269616080075, -8.184845806672365],
              [-38.503316921228283, -8.184840075926276],
              [-38.503372412422706, -8.184821782437542],
              [-38.503439781408076, -8.184815443181474],
              [-38.503503334031599, -8.18481190322937],
              [-38.50356234716552, -8.18480862904368],
              [-38.503617902256998, -8.184812044034018],
              [-38.503579273208338, -8.184849353161143],
              [-38.503466158906171, -8.184921394677405],
              [-38.503415368146953, -8.184956065734674],
              [-38.503345309067392, -8.185008713003805],
              [-38.50331483824263, -8.185055891380859],
              [-38.503226040492414, -8.185138636036417],
              [-38.503113080608969, -8.185159210557073],
              [-38.503019934310082, -8.185161357256469],
              [-38.502979339225341, -8.185173427848222],
              [-38.502914687347086, -8.185258101628072],
              [-38.502849044900962, -8.185336261646706],
              [-38.50276081850302, -8.18539738891271],
              [-38.502759092052131, -8.185398653112602],
              [-38.502690426557585, -8.185498698715298],
              [-38.502636225070077, -8.185574973292507],
              [-38.502552920633192, -8.185620187052331],
              [-38.502505296348936, -8.185663545256304],
              [-38.502425612433967, -8.185717446819469],
              [-38.502371406861975, -8.185723802114836],
              [-38.502330810131866, -8.185737138959647],
              [-38.502268399591621, -8.185771252830751],
              [-38.502220611211577, -8.185800500316484],
              [-38.502174646014922, -8.185823689773322],
              [-38.502136481099029, -8.185852587302749],
              [-38.502097680245448, -8.18588184585135],
              [-38.502066402379739, -8.185920792011952],
              [-38.502035389218584, -8.1859658892177],
              [-38.502014172559043, -8.186017511028471],
              [-38.502043953542525, -8.186087376527187],
              [-38.502153778797968, -8.186105692730175],
              [-38.502251025767997, -8.186164425351917],
              [-38.502347094604609, -8.18622152836782],
              [-38.502446738370658, -8.186250957518423],
              [-38.5025514100194, -8.186325528282701],
              [-38.502637391097771, -8.18639139263232],
              [-38.502675504094725, -8.1864775496751],
              [-38.502657826922615, -8.186603255898682],
              [-38.502662291588436, -8.186663321387222],
              [-38.502714064884529, -8.186715033116512],
              [-38.502759148487037, -8.186743942750983],
              [-38.502824644473314, -8.186784093533756],
              [-38.502879158136082, -8.18682224084821],
              [-38.502959446254131, -8.186866932408815],
              [-38.503059131568776, -8.186936250712874],
              [-38.503162272635095, -8.187000779300448],
              [-38.503272581154434, -8.187068934751718],
              [-38.503394717574501, -8.187116119902782],
              [-38.503528160704327, -8.18712333922292],
              [-38.503680590826171, -8.187119908522769],
              [-38.503834056064548, -8.187086991747838],
              [-38.503968564525493, -8.187040302953728],
              [-38.504115137773034, -8.187001226879994],
              [-38.504246369803909, -8.186961136948559],
              [-38.504308228446405, -8.18685945459274],
              [-38.504304124125376, -8.186728023069573],
              [-38.504398980380273, -8.18666482321815],
              [-38.504526747076824, -8.186637211284983],
              [-38.504554804540689, -8.186632904019786],
              [-38.504651234600104, -8.18661845956645],
              [-38.504819687463574, -8.186578595993023],
              [-38.504854579599971, -8.186553312264055],
              [-38.504907163673629, -8.186536824047895],
              [-38.504948947861294, -8.186517609031432],
              [-38.504991183617143, -8.186500294052172],
              [-38.505035065867467, -8.186472940933708],
              [-38.505101454704239, -8.186451856566768],
              [-38.505189787676834, -8.186451422091784],
              [-38.505282637053675, -8.186469445302794],
              [-38.505304811477075, -8.186524195821466],
              [-38.505320183729538, -8.18657360134933],
              [-38.505349453895995, -8.186616511367998],
              [-38.505403157106848, -8.18664950160785],
              [-38.505465350948576, -8.186643788855221],
              [-38.505534463858609, -8.186622255494212],
              [-38.50558378941777, -8.186597893889934],
              [-38.505653533449227, -8.186579888901827],
              [-38.505706277766144, -8.186580677076227],
              [-38.505771833930055, -8.186572254835788],
              [-38.505840762571445, -8.186552892039165],
              [-38.505905132189753, -8.186549624079454],
              [-38.50600133125814, -8.186575249180851],
              [-38.506055983034287, -8.186575225576247],
              [-38.506074296600069, -8.186521610034823],
              [-38.506034934794059, -8.186490536911975],
              [-38.505969865445003, -8.186472276499822],
              [-38.505924368165225, -8.186410532688864],
              [-38.505870982438573, -8.186340909912095],
              [-38.505861690272788, -8.186293411328943],
              [-38.505842057606593, -8.186238754398369],
              [-38.50583759380099, -8.186177603495162],
              [-38.50582740629465, -8.186119973210056],
              [-38.50585288082759, -8.18607523071997],
              [-38.505915492998803, -8.186024654292426],
              [-38.506003517251031, -8.185979988306554],
              [-38.506058259617973, -8.185980055259593],
              [-38.506152153679928, -8.185960632482262],
              [-38.50619909825712, -8.185952911022712],
              [-38.506283199623461, -8.185923797893883],
              [-38.506357493926998, -8.185896662700896],
              [-38.506406462118697, -8.185867506602559],
              [-38.506472953936608, -8.185836381970354],
              [-38.506529729479411, -8.18580723539605],
              [-38.506582790355971, -8.18577202400137],
              [-38.506603032298024, -8.185700501317397],
              [-38.506599952832964, -8.185620899928407],
              [-38.506585153087705, -8.185548791725154],
              [-38.50658915761332, -8.185466847241489],
              [-38.506599876422598, -8.185387895870461],
              [-38.506572629809924, -8.185323551320835],
              [-38.506532292613173, -8.185273663072898],
              [-38.506484867323373, -8.185229464627607],
              [-38.506443349408727, -8.18518002718845],
              [-38.506398549280377, -8.185141982667442],
              [-38.506356384767685, -8.185101589614892],
              [-38.506314489940259, -8.185063367732342],
              [-38.506264235831857, -8.185031014998106],
              [-38.506210328908523, -8.185016114999053],
              [-38.506152518203109, -8.185001300672029],
              [-38.506091979440392, -8.184990191526884],
              [-38.506032340935917, -8.184985234199223],
              [-38.505967060729098, -8.184990943280232],
              [-38.505899344645854, -8.184984257468502],
              [-38.505841543211055, -8.184961935606314],
              [-38.505785474689098, -8.184933103323965],
              [-38.50573594880327, -8.1848992137369],
              [-38.505693791187099, -8.184853303066211],
              [-38.505654072346459, -8.184817435530547],
              [-38.505632253727043, -8.184768655286064],
              [-38.505640967886229, -8.184695852219365],
              [-38.50563207827031, -8.184616424623762],
              [-38.505601831338083, -8.184555875323658],
              [-38.505577184523545, -8.184518398095488],
              [-38.505624091010702, -8.184467802473595],
              [-38.505680852025009, -8.184450324278854],
              [-38.505745444342352, -8.184413408511523],
              [-38.505792431992809, -8.184370591825777],
              [-38.505845232084937, -8.18432588275544],
              [-38.505902045896441, -8.184265530417436],
              [-38.505939260155266, -8.184197375241078],
              [-38.505996002315818, -8.184121465082065],
              [-38.506008951431362, -8.184074445955977],
              [-38.506057026514767, -8.184033168245996],
              [-38.506097868364307, -8.183968002398],
              [-38.506135712916702, -8.183904008754753],
              [-38.506169010627502, -8.183846250726745],
              [-38.506205492076852, -8.183783340831207],
              [-38.506233349143336, -8.183720420390667],
              [-38.506251310733838, -8.183583860044967],
              [-38.506257100985287, -8.18352624929066],
              [-38.506297896240042, -8.183572610495544],
              [-38.506387675491197, -8.183650508795441],
              [-38.506428668591887, -8.18368366425929],
              [-38.506481482615492, -8.183701276440859],
              [-38.506522499658594, -8.183714984775966],
              [-38.50659093791338, -8.183724837168771],
              [-38.506671106410188, -8.183719055703262],
              [-38.506719973955263, -8.183697678283897],
              [-38.506813867342082, -8.183678345768593],
              [-38.506897968184497, -8.18364923247745],
              [-38.506976251797383, -8.183625900988124],
              [-38.507075842131634, -8.183624756187236],
              [-38.5071275299169, -8.183598497756904],
              [-38.507205831958601, -8.183560151234349],
              [-38.507272331441257, -8.18352242346425],
              [-38.50734000053292, -8.183493290036143],
              [-38.507417465160344, -8.183471495140932],
              [-38.507485040007886, -8.183445165580405],
              [-38.507540301816384, -8.183391594986102],
              [-38.507585590274957, -8.183327610262157],
              [-38.507630869502265, -8.183271133026404],
              [-38.507672433396515, -8.183209133692875],
              [-38.507719337139427, -8.183160437286192],
              [-38.507762527104518, -8.183104952457933],
              [-38.507793208578235, -8.183033532855536],
              [-38.507812174298856, -8.182965988408487],
              [-38.507840560435014, -8.182915550840221],
              [-38.507872848554335, -8.182866474800392],
              [-38.507920693025341, -8.182864904940471],
              [-38.50795132131293, -8.182910801344976],
              [-38.507990105515375, -8.182968556875638],
              [-38.508020021203642, -8.183003326826679],
              [-38.508062548373431, -8.183044081955705],
              [-38.508106627622034, -8.183077693271835],
              [-38.508173255382346, -8.183083291950142],
              [-38.508229083659309, -8.183085892560568],
              [-38.508288969326941, -8.183111201523896],
              [-38.508352403623832, -8.183129911809576],
              [-38.50841592050606, -8.1831553156255],
              [-38.508467808667781, -8.183187670079445],
              [-38.508545221648617, -8.183207935024562],
              [-38.508611751898123, -8.183219051078584],
              [-38.508684193115485, -8.183221943188466],
              [-38.508759628172058, -8.183226467059582],
              [-38.50883124736373, -8.183233428478729],
              [-38.508906157886294, -8.183221489441065],
              [-38.508961197055037, -8.183201295116017],
              [-38.509020515410221, -8.183170975371569],
              [-38.509068394686174, -8.183141003525657],
              [-38.509117282196762, -8.183103163583942],
              [-38.509149190557629, -8.183067564305146],
              [-38.509189563402067, -8.183014246734919],
              [-38.509228293181685, -8.182968253767621],
              [-38.509269847176547, -8.182914123555623],
              [-38.509299158110764, -8.182849666953135],
              [-38.509347847792903, -8.182750862160264],
              [-38.509369510718798, -8.182704577094762],
              [-38.50940006022298, -8.18274079494987],
              [-38.509437482997647, -8.182798458248206],
              [-38.509468904057918, -8.18286434537978],
              [-38.509495449298612, -8.182908608586061],
              [-38.509542171979561, -8.182933539578716],
              [-38.509603897593799, -8.182939041618106],
              [-38.509674970403012, -8.18294735900103],
              [-38.509718234622135, -8.182979431477143],
              [-38.50976175991957, -8.183020730359885],
              [-38.50977775682545, -8.183079362602651],
              [-38.509797107059867, -8.183142611960598],
              [-38.509836818842608, -8.183184448920715],
              [-38.509895537617112, -8.183198992460282],
              [-38.509955137099162, -8.183161436763195],
              [-38.510020707569396, -8.183140802804296],
              [-38.510099170954454, -8.183118737237603],
              [-38.510166751446647, -8.183087613270153],
              [-38.510227258936972, -8.183049877727289],
              [-38.510291953687329, -8.183002921155307],
              [-38.510360271892289, -8.182962391048838],
              [-38.510464442417351, -8.182927512390084],
              [-38.510518931373774, -8.18291174932269],
              [-38.510495003080301, -8.182954684991078],
              [-38.510459326712514, -8.183027998265144],
              [-38.51042974160935, -8.183094082763521],
              [-38.510404338135082, -8.183155559278793],
              [-38.510362335535483, -8.183205437830919],
              [-38.510317339964125, -8.183252961000614],
              [-38.510277875838284, -8.183305646628396],
              [-38.510272510921958, -8.183387137293636],
              [-38.510291126701659, -8.183457169635094],
              [-38.510333630970358, -8.183516919413071],
              [-38.510375059439461, -8.18356554229864],
              [-38.510423484273709, -8.183609198804064],
              [-38.510464645714038, -8.183653479666258],
              [-38.510496345206484, -8.183714573115035],
              [-38.510520176069861, -8.183751506368322],
              [-38.510475873564204, -8.183751814495722],
              [-38.510376099935854, -8.183754316690107],
              [-38.510292018056575, -8.183768053917619],
              [-38.510207739804002, -8.183793911444441],
              [-38.510128087051399, -8.183823392638034],
              [-38.51004662382293, -8.18384862038004],
              [-38.509964157772856, -8.183877284060843],
              [-38.509886864460491, -8.183907491681996],
              [-38.509809495169343, -8.183925578641023],
              [-38.509733658566766, -8.183952350827063],
              [-38.509665071320285, -8.183989986024933],
              [-38.509597938026808, -8.184026447100708],
              [-38.509535698263036, -8.184070059805535],
              [-38.509475636281927, -8.184114489211282],
              [-38.509432641269633, -8.184159120236989],
              [-38.509383924926539, -8.184205372443085],
              [-38.509334949393079, -8.184240860552626],
              [-38.509300407133864, -8.184277361159047],
              [-38.509268048974022, -8.184309522724638],
              [-38.50917368688382, -8.18441505588822],
              [-38.509139477722393, -8.184476069345086],
              [-38.509091022472383, -8.18453145746876],
              [-38.509047555406113, -8.184590831531223],
              [-38.509007267507279, -8.18464876222219],
              [-38.50899120318212, -8.184719385617633],
              [-38.509049040418269, -8.184786752119702],
              [-38.509099480627476, -8.184815577121759],
              [-38.509144019939612, -8.184844394947197],
              [-38.509182544873745, -8.184891748096799],
              [-38.509213915216897, -8.18492498205584],
              [-38.509266701760026, -8.184965297278994],
              [-38.509304150383116, -8.185001975787841],
              [-38.509389498453132, -8.185066571656876],
              [-38.50943011587777, -8.185110309251316],
              [-38.509457930663885, -8.185155840331806],
              [-38.509504458198535, -8.185192077590209],
              [-38.509547279228698, -8.185215556626682],
              [-38.509578365329737, -8.185258559019124],
              [-38.509600185792571, -8.185306253658258],
              [-38.509638528483428, -8.185354239710946],
              [-38.509668987528038, -8.185390366992294],
              [-38.509725785012307, -8.185417300150027],
              [-38.509793430502754, -8.185407613449883],
              [-38.509871743306888, -8.185434844055109],
              [-38.509904672765657, -8.185455235688533],
              [-38.509923724322853, -8.185465841676528],
              [-38.509980995208743, -8.185476855840903],
              [-38.510044201276699, -8.185459837109645],
              [-38.510100703300886, -8.185431413297957],
              [-38.510155221897804, -8.185391680585434],
              [-38.510198035076549, -8.185347320623659],
              [-38.510242477704359, -8.185306761612226],
              [-38.510292545239764, -8.185269194327605],
              [-38.510365432599457, -8.185278780139273],
              [-38.510374523840795, -8.185342559749211],
              [-38.510369082357471, -8.185412382019379],
              [-38.510385988144861, -8.185470291728384],
              [-38.510423435485151, -8.185508146014344],
              [-38.510471417563238, -8.185542847248966],
              [-38.510477060825139, -8.1856051754477],
              [-38.510450123798556, -8.185658509419969],
              [-38.510486384683553, -8.185701518021327],
              [-38.510531472432795, -8.185727441907868],
              [-38.510600811493198, -8.185743445466818],
              [-38.510691298008453, -8.185763635422056],
              [-38.510664548635994, -8.185811994781998],
              [-38.510610856681645, -8.185843859248418],
              [-38.510556912042908, -8.185933974424257],
              [-38.510502774429206, -8.185959054442177],
              [-38.510447448230813, -8.185991007355964],
              [-38.510399547487815, -8.186038346085628],
              [-38.510379322981095, -8.186096120329905],
              [-38.510418316124422, -8.186131986550818],
              [-38.510467687525427, -8.186144166950797],
              [-38.510520133972371, -8.186165848521028],
              [-38.51057650277513, -8.186172248467141],
              [-38.510613397545725, -8.186216614606151],
              [-38.510617688355133, -8.186271343204323],
              [-38.510652409192666, -8.186311726822495],
              [-38.510684143344157, -8.186344599311784],
              [-38.510729053363427, -8.186367447600409],
              [-38.510782881806215, -8.186372578115298],
              [-38.510831726096697, -8.186370375982134],
              [-38.5108836514733, -8.186447142084711],
              [-38.510950499135149, -8.18649624796984],
              [-38.510911594232105, -8.18653681375992],
              [-38.510883114753874, -8.186589422275121],
              [-38.510818239110421, -8.186635383714163],
              [-38.510756498442298, -8.186641731007999],
              [-38.510685937082719, -8.186660097707408],
              [-38.510622440274659, -8.186692040739501],
              [-38.510559598598391, -8.186707884117375],
              [-38.510489949584162, -8.186722543359293],
              [-38.510422572143639, -8.186735577208674],
              [-38.510355456223557, -8.186757475649117],
              [-38.51029678449747, -8.18677829889484],
              [-38.51023121199627, -8.186800108733488],
              [-38.5101675526859, -8.186816403316255],
              [-38.510100263684521, -8.186831336707225],
              [-38.510039050990088, -8.186851342764539],
              [-38.509987540086072, -8.186881219840181],
              [-38.509929865286821, -8.186903401018276],
              [-38.509882430895743, -8.18694042872553],
              [-38.509834540034134, -8.186979536265993],
              [-38.509799181509699, -8.187015221841451],
              [-38.509762548357557, -8.187053890778142],
              [-38.509727460511797, -8.187090933453272],
              [-38.509693734193341, -8.187128158681753],
              [-38.509658998958763, -8.187173794707007],
              [-38.509621086729538, -8.187219065063191],
              [-38.50958536403413, -8.187255564241795],
              [-38.509533769345033, -8.18727965223192],
              [-38.509498315003178, -8.187319407995586],
              [-38.509510599610607, -8.187370075969612],
              [-38.509555366095782, -8.1874360697628],
              [-38.509576102233666, -8.187479692753184],
              [-38.50956823584773, -8.187527894051279],
              [-38.509579253701276, -8.187575123313669],
              [-38.509638361733806, -8.187643034006108],
              [-38.509685998328678, -8.187663443524544],
              [-38.509731294241845, -8.187667749760928],
              [-38.509787871571852, -8.187652260725987],
              [-38.50983718984476, -8.187633777959139],
              [-38.509883674241017, -8.187631301729747],
              [-38.509929241841895, -8.187636150984957],
              [-38.509978253146748, -8.187645979267659],
              [-38.510028544714658, -8.187648211139734],
              [-38.510083489693365, -8.18763127285159],
              [-38.510137714145436, -8.187609630183541],
              [-38.510186863763224, -8.18758056429736],
              [-38.51023673798661, -8.187552856061899],
              [-38.510285347971156, -8.187519628721443],
              [-38.510327237850184, -8.187488111811538],
              [-38.510365864037283, -8.187452882411989],
              [-38.510410567094645, -8.187422273422552],
              [-38.510471406123777, -8.187410950242034],
              [-38.5105177730929, -8.187430272701562],
              [-38.510525245410598, -8.187481658433903],
              [-38.510558048832706, -8.187531084928397],
              [-38.510598871162834, -8.187556008676379],
              [-38.510661230455931, -8.187563591756501],
              [-38.510721343673289, -8.187551905852102],
              [-38.51076664831303, -8.187549066283108],
              [-38.51081636989877, -8.187572010850097],
              [-38.510874014403299, -8.187574703783664],
              [-38.510931681532156, -8.18755885409751],
              [-38.510987915978191, -8.187526721326158],
              [-38.511031174073274, -8.187489869334978],
              [-38.511080148558953, -8.187455466461195],
              [-38.511125303169017, -8.18742675742604],
              [-38.511203142570906, -8.187395917104583],
              [-38.511243566926808, -8.187374981193088],
              [-38.511279200582159, -8.187336944069695],
              [-38.511308234585208, -8.187276285980285],
              [-38.511334530843961, -8.187227292870677],
              [-38.511374540286681, -8.1871743364682],
              [-38.511408457399547, -8.187129422909585],
              [-38.511462975567071, -8.187090142288618],
              [-38.511521274675871, -8.187077097286188],
              [-38.511584855434663, -8.187050762232586],
              [-38.511639980191298, -8.187035180719704],
              [-38.51168188159825, -8.186994075770974],
              [-38.511700377228522, -8.186939465182181],
              [-38.511693923436837, -8.186871618446006],
              [-38.511673109307033, -8.18681732213186],
              [-38.511636326411683, -8.186755498997718],
              [-38.511603172887781, -8.186695308384362],
              [-38.511566650132174, -8.186643525721323],
              [-38.511547117800077, -8.186580366664872],
              [-38.511566790306212, -8.186528471061727],
              [-38.511606047371266, -8.186496860369649],
              [-38.511657480109754, -8.186531022935879],
              [-38.511696384504035, -8.186565351251838],
              [-38.511739364471943, -8.186607644260038],
              [-38.511788992689823, -8.186632668985911],
              [-38.511852525782643, -8.18664540905346],
              [-38.5119187071314, -8.186645398591946],
              [-38.511985974911077, -8.186647831634231],
              [-38.512053455194888, -8.1866248479337],
              [-38.512118109952688, -8.186611086903438],
              [-38.51219402583331, -8.186594083195931],
              [-38.512263491562244, -8.186580780212525],
              [-38.512338401661502, -8.186569654637175],
              [-38.512408043449526, -8.186560874437014],
              [-38.512476703444889, -8.18653825389546],
              [-38.512543526865755, -8.186532816990223],
              [-38.512629288755768, -8.186556437996725],
              [-38.512607344373436, -8.186610320859856],
              [-38.512600461376003, -8.186671367600905],
              [-38.512608914319941, -8.186737769513872],
              [-38.51262780628479, -8.186805088544206],
              [-38.512657705288881, -8.186854149568569],
              [-38.512678957751639, -8.186921380993564],
              [-38.512698030203126, -8.186989604758148],
              [-38.51271938412706, -8.187048062467364],
              [-38.512756088309125, -8.187100116610409],
              [-38.512784985100659, -8.18715216133104],
              [-38.51281814367821, -8.187208371979855],
              [-38.512851953909049, -8.187251286979762],
              [-38.51289284670856, -8.187293034645611],
              [-38.512914563810142, -8.187351492784236],
              [-38.512930011341659, -8.187414465955852],
              [-38.512941289307115, -8.187472097434375],
              [-38.512954739272956, -8.187535339551577],
              [-38.512964933223522, -8.187588537577755],
              [-38.512999454386403, -8.187644116694059],
              [-38.513033001660141, -8.187679342950418],
              [-38.513077210781923, -8.187756280398165],
              [-38.513115720531623, -8.187816748719337],
              [-38.513156525376957, -8.187856234958078],
              [-38.513210498398585, -8.187892028539197],
              [-38.513309981840521, -8.187905083084965],
              [-38.51338870169144, -8.187896765929743],
              [-38.513456074211852, -8.187887892374633],
              [-38.513550425902118, -8.187865573973527],
              [-38.513622380962168, -8.187820977398092],
              [-38.513680890745363, -8.187709158830444],
              [-38.513667483457859, -8.187610640528854],
              [-38.513637354175422, -8.187526755299475],
              [-38.513620924039046, -8.187450484531018],
              [-38.513620296102523, -8.187369438842998],
              [-38.513646408990489, -8.187321711673357],
              [-38.51372993795858, -8.187315299726986],
              [-38.513805810151773, -8.187334385990418],
              [-38.513880484504064, -8.187368033559736],
              [-38.513966611226266, -8.187390478859969],
              [-38.514046752478727, -8.187407761178958],
              [-38.514129529867802, -8.187422242644189],
              [-38.514201916453921, -8.187396008219284],
              [-38.514271130640246, -8.187365609175677],
              [-38.514354026758589, -8.187357025492371],
              [-38.514443799543848, -8.187367354516676],
              [-38.514514400084423, -8.187391590071442],
              [-38.514586623461064, -8.187425144115359],
              [-38.514679967721335, -8.187484954596194],
              [-38.514716876111187, -8.187518556625166],
              [-38.514778551304985, -8.187566389367799],
              [-38.514860491017245, -8.187597965098004],
              [-38.514935278191984, -8.187613522225464],
              [-38.514982469050722, -8.1876274180586],
              [-38.51508238925117, -8.187654673648204],
              [-38.515156245961897, -8.187688953172282],
              [-38.515222026967891, -8.187719876266154],
              [-38.515300211580886, -8.187778763892219],
              [-38.515346007687619, -8.187819974500234],
              [-38.515384447026065, -8.187863889832817],
              [-38.515436799850406, -8.18796344981074],
              [-38.515463898216247, -8.188001562601801],
              [-38.515467342758001, -8.188005908429021],
              [-38.515499885543001, -8.188046198598004],
              [-38.515539596660211, -8.188089482279882],
              [-38.515586225623771, -8.18811766878123],
              [-38.515650849933735, -8.18812923370429],
              [-38.515710234131319, -8.188119807478371],
              [-38.515770820481947, -8.188092201841783],
              [-38.515814083148413, -8.188051278984798],
              [-38.515829489226107, -8.187998654418502],
              [-38.51582261149747, -8.187980555779815],
              [-38.515806593654723, -8.18793838595985],
              [-38.515784775040558, -8.187888430314908],
              [-38.515779161734493, -8.187875760309339],
              [-38.515757918316787, -8.18780056925649],
              [-38.515715681644345, -8.187743714773056],
              [-38.515653003091288, -8.187699679944716],
              [-38.515581051152523, -8.187666759573355],
              [-38.515502189594415, -8.187642152479373],
              [-38.515467788522358, -8.187637136344501],
              [-38.515418682980361, -8.187630022166001],
              [-38.515340818969989, -8.187606320758054],
              [-38.51526667218544, -8.187586694135772],
              [-38.515202795469683, -8.187557582375319],
              [-38.515100718354077, -8.187512233835976],
              [-38.515061353843237, -8.187482699228514],
              [-38.514986947769799, -8.187452308463307],
              [-38.514917449397288, -8.187417491434969],
              [-38.514853766236101, -8.187378520592114],
              [-38.514797900127796, -8.187331599361],
              [-38.514758668704523, -8.18726715043401],
              [-38.51471534676854, -8.18720703828153],
              [-38.514666843929966, -8.187152075659439],
              [-38.51461397746192, -8.187102082644341],
              [-38.514575798145394, -8.187068479080201],
              [-38.514518471358109, -8.187028339954228],
              [-38.514466318815472, -8.186988568849564],
              [-38.514415251195686, -8.186952507572951],
              [-38.514361292203951, -8.186905136320238],
              [-38.514317781347046, -8.186851174634752],
              [-38.514276459830228, -8.186788532191716],
              [-38.514232760415304, -8.186740359195687],
              [-38.514178487470154, -8.18672735928166],
              [-38.514125270591862, -8.186666963813297],
              [-38.51417099800323, -8.186614828054646],
              [-38.51421671170241, -8.186573998766587],
              [-38.514254442353639, -8.186528275465944],
              [-38.514302082981523, -8.18647053397078],
              [-38.514349350928697, -8.186420661340165],
              [-38.514400882623946, -8.186373326484546],
              [-38.514460226841337, -8.186321749772452],
              [-38.514512209580829, -8.186276767193785],
              [-38.514542335710217, -8.186213396590425],
              [-38.514538714237652, -8.186130266895209],
              [-38.514521278231591, -8.186059874319344],
              [-38.514532330719817, -8.186003988299298],
              [-38.514574235019296, -8.185960169470096],
              [-38.514613961053968, -8.185915895758328],
              [-38.514613230072015, -8.185844799645231],
              [-38.514575365709383, -8.185776101106933],
              [-38.51452077705882, -8.185724025622502],
              [-38.514467634823134, -8.185677017179456],
              [-38.514414856609932, -8.185629285552027],
              [-38.514350729390735, -8.185582444791986],
              [-38.514282241407258, -8.185538221884936],
              [-38.514214752385001, -8.185493728812682],
              [-38.514145894503699, -8.185455113460032],
              [-38.514078221313895, -8.185412790991652],
              [-38.514006089669252, -8.185378784727254],
              [-38.513932316152527, -8.185351198563032],
              [-38.513863179504753, -8.185317919494118],
              [-38.513797947538457, -8.185283831046602],
              [-38.513752870354075, -8.185249043237068],
              [-38.513714345041919, -8.185201509586856],
              [-38.513673539186335, -8.185163018381862],
              [-38.513734850918112, -8.185135866162293],
              [-38.513802932891231, -8.185140470714995],
              [-38.513853378893927, -8.185164863006717],
              [-38.513892378532617, -8.185195573153417],
              [-38.513950845603723, -8.185193472666315],
              [-38.514010632206542, -8.185151303545513],
              [-38.514072296088301, -8.185133196891854],
              [-38.514127974954505, -8.185109565460248],
              [-38.514185457534758, -8.185095795452606],
              [-38.514243575265688, -8.185082297556217],
              [-38.514296124755163, -8.185093938598282],
              [-38.5143691834908, -8.185112026380736],
              [-38.514435152594089, -8.185137160880167],
              [-38.514505942748663, -8.18515443184401],
              [-38.51457860683027, -8.185123584607631],
              [-38.514622517565968, -8.185072079760912],
              [-38.514645763975004, -8.184992238616745],
              [-38.514627337692907, -8.184914970504668],
              [-38.514599477626717, -8.18483135941862],
              [-38.514562157613213, -8.184763023343169],
              [-38.51456058790108, -8.184709835706176],
              [-38.514599687191534, -8.184658234624271],
              [-38.514666525068506, -8.184640586350664],
              [-38.514737605559148, -8.184642933045385],
              [-38.514808717132816, -8.184619591408234],
              [-38.514892895238091, -8.184601421211832],
              [-38.514976292041382, -8.1846287473966],
              [-38.515027257928438, -8.184673672762548],
              [-38.515089389176076, -8.184719425593881],
              [-38.515138085397965, -8.184764438674362],
              [-38.515215369269285, -8.184816993572978],
              [-38.515292332778643, -8.184834181354919],
              [-38.515377049600517, -8.184820986563739],
              [-38.515443187554126, -8.184781538397358],
              [-38.51547127334053, -8.184753622429266],
              [-38.515494450928308, -8.184730584978254],
              [-38.515544450209148, -8.184673931561674],
              [-38.515589452576364, -8.184620437964597],
              [-38.515636356024622, -8.184571378787858],
              [-38.51568344670833, -8.184517616326827],
              [-38.51571664209559, -8.184468359799833],
              [-38.515778028523343, -8.184379157290669],
              [-38.515810306226705, -8.184338040331982],
              [-38.515851233763243, -8.184275858426966],
              [-38.515891163764643, -8.184212770801704],
              [-38.515932651040792, -8.184138107184578],
              [-38.515973326275216, -8.184059281798064],
              [-38.515999761873047, -8.183969404204737],
              [-38.516025920087642, -8.183883687067381],
              [-38.516034922594628, -8.183796049883977],
              [-38.516016956328599, -8.183713536154793],
              [-38.515976014664766, -8.183637055181434],
              [-38.515954171777587, -8.183607360761636],
              [-38.515926528392107, -8.183569699616347],
              [-38.515891352975459, -8.1835295872405],
              [-38.515873130717324, -8.183508761449295],
              [-38.515807460765764, -8.183461557268659],
              [-38.515729061126095, -8.183430980983442],
              [-38.515638275807099, -8.183432862094193],
              [-38.51554592237698, -8.183455273902464],
              [-38.515472809672232, -8.183482050460087],
              [-38.515459458681235, -8.18348691885434],
              [-38.515383963203639, -8.183532235191747],
              [-38.515291571478059, -8.183586305083677],
              [-38.51520753427517, -8.183638304583221],
              [-38.515163274862196, -8.183678050335921],
              [-38.515101927243826, -8.183735142351052],
              [-38.515049391245554, -8.183787541431052],
              [-38.514977886181256, -8.183836118746932],
              [-38.51489958079911, -8.183877723082029],
              [-38.514815848227386, -8.183902949077389],
              [-38.514732119586235, -8.183924918788874],
              [-38.514650097314053, -8.183962176914017],
              [-38.514567074224324, -8.184001242858065],
              [-38.514511323370243, -8.184009497442167],
              [-38.51444323062163, -8.184013938202089],
              [-38.514384642270024, -8.1840413651729],
              [-38.514382004211967, -8.183970809474738],
              [-38.514386614555974, -8.183912021244005],
              [-38.514387502795358, -8.183853228538807],
              [-38.514375991333715, -8.183763395906439],
              [-38.51434358961027, -8.183681950193208],
              [-38.514290107416436, -8.183616036870815],
              [-38.514233975334747, -8.183564321313307],
              [-38.514163937155551, -8.183526066367506],
              [-38.514090353415774, -8.183491967935938],
              [-38.514035400130737, -8.183441429646185],
              [-38.513956727534307, -8.183411485860281],
              [-38.513874466178287, -8.183421336601118],
              [-38.513798199779579, -8.183428480981133],
              [-38.513730090678848, -8.183446489399227],
              [-38.513746065417045, -8.183374147064894],
              [-38.513734818998884, -8.183290465472925],
              [-38.513727614274522, -8.183242698170373],
              [-38.513702295317614, -8.183159994610902],
              [-38.513661880649387, -8.183098529087873],
              [-38.513597011415349, -8.183065074226233],
              [-38.513519757772301, -8.183062900817923],
              [-38.513449649308626, -8.183082715823497],
              [-38.513355114116798, -8.18310756670407],
              [-38.513374168715579, -8.183040836116861],
              [-38.513407386847881, -8.182972946711056],
              [-38.513465447517525, -8.182931408735714],
              [-38.513513356633219, -8.182876562091094],
              [-38.513561721294899, -8.182820359209138],
              [-38.513623860349739, -8.182784434139736],
              [-38.513696427015901, -8.182758742681884],
              [-38.513766192128259, -8.182722555412081],
              [-38.513805903542618, -8.18269013094813],
              [-38.513856302854215, -8.182602995905755],
              [-38.513876738156874, -8.182520528307631],
              [-38.513894540424012, -8.182438328895776],
              [-38.513894905637414, -8.182436610747949],
              [-38.513907993665661, -8.182349068997603],
              [-38.513928249897567, -8.182264520784029],
              [-38.513944146362654, -8.182181776363306],
              [-38.513951515613478, -8.182093775470353],
              [-38.513925931444774, -8.182005192225358],
              [-38.513897794523508, -8.181925560652136],
              [-38.513862677223152, -8.181837780002972],
              [-38.513836807146753, -8.181760412452411],
              [-38.513814667822281, -8.181675903684315],
              [-38.51378162046673, -8.181603050093269],
              [-38.513742309790722, -8.181529646251507],
              [-38.513693726051756, -8.181467175934145],
              [-38.513642506237751, -8.181407506449769],
              [-38.51358929383192, -8.181344035578846],
              [-38.513545498463124, -8.181300656372715],
              [-38.513511221653779, -8.181268504664249],
              [-38.513451368899148, -8.181215970469886],
              [-38.513390870699652, -8.181171666618329],
              [-38.513328374204526, -8.181128264871395],
              [-38.513280852531544, -8.181088408777807],
              [-38.513210238787735, -8.181076022258141],
              [-38.513147199504225, -8.18110588591454],
              [-38.5130838746907, -8.181146603450893],
              [-38.513019821629307, -8.181188948234034],
              [-38.512962672078721, -8.181227954604285],
              [-38.512906780731001, -8.18127745490855],
              [-38.5128450789664, -8.181327219555477],
              [-38.512800266230514, -8.181374110092023],
              [-38.512750543982413, -8.181426964531743],
              [-38.512691109319306, -8.181478721828867],
              [-38.512641490463473, -8.181521174404175],
              [-38.512574630954468, -8.181557003185025],
              [-38.512511400204147, -8.181594916726585],
              [-38.512437598574095, -8.181591119157213],
              [-38.512361008089165, -8.181566423810388],
              [-38.51228632767468, -8.181538745840804],
              [-38.512219006367516, -8.181506373240248],
              [-38.512151778934111, -8.181471468088024],
              [-38.512082548359032, -8.181441444911385],
              [-38.512010130948049, -8.181419196741665],
              [-38.51193416664934, -8.181402190488056],
              [-38.511858907374922, -8.18140264229584],
              [-38.511796408021731, -8.181436305365745],
              [-38.511751318626558, -8.181486632617229],
              [-38.511717646557898, -8.181554430882292],
              [-38.511686783228107, -8.181626755134481],
              [-38.511662464262486, -8.18169248430126],
              [-38.511638281392926, -8.181646415041982],
              [-38.511605284592392, -8.181606938119893],
              [-38.511557853170345, -8.181567624651903],
              [-38.511497346003893, -8.18153082802605],
              [-38.511422925563828, -8.181513371282984],
              [-38.511342315289156, -8.181509565282958],
              [-38.511257342060986, -8.181510186134563],
              [-38.511189230116308, -8.181530726788687],
              [-38.51111487170477, -8.181536878017553],
              [-38.511034138676735, -8.181559302865665],
              [-38.510978800132982, -8.181601929208719],
              [-38.510931168712204, -8.181652253284966],
              [-38.510864860246606, -8.181682745776163],
              [-38.510858138362394, -8.181611551976507],
              [-38.51085441924814, -8.18153466334026],
              [-38.510842618515213, -8.181459393055718],
              [-38.510860762408171, -8.18139528460464],
              [-38.510901593287031, -8.181338258861604],
              [-38.510943059740832, -8.181281143430846],
              [-38.510979532601915, -8.181224474211827],
              [-38.510979365441372, -8.181138182872013],
              [-38.510961755285393, -8.181062362847268],
              [-38.510965036971875, -8.180976799295973],
              [-38.510944443027306, -8.180891116399676],
              [-38.510915221909123, -8.180807774811987],
              [-38.510876096936727, -8.180731476486187],
              [-38.510828327044138, -8.180672082203598],
              [-38.510783639018015, -8.180616581079533],
              [-38.510743778234001, -8.180548151169587],
              [-38.510678742227149, -8.180503117824665],
              [-38.51059260902197, -8.180487365346011],
              [-38.510508302945055, -8.180462208062314],
              [-38.51043527704622, -8.180418069522359],
              [-38.510372244265241, -8.180368154153905],
              [-38.510325275191775, -8.180322147672122],
              [-38.510284042405168, -8.180262218540376],
              [-38.510242828952549, -8.180186460342203],
              [-38.510206061297687, -8.180112787922338],
              [-38.510160197117393, -8.180054119493844],
              [-38.510103150190638, -8.180009005294105],
              [-38.510028545006129, -8.179994442550287],
              [-38.509964691523578, -8.180021952971018],
              [-38.509915413765299, -8.180008054077438],
              [-38.509884186211771, -8.179932488859532],
              [-38.509843142166197, -8.179866770993947],
              [-38.50979701257274, -8.179802494187426],
              [-38.509747156953196, -8.179741469125869],
              [-38.509687202000237, -8.179698883998375],
              [-38.509619794647151, -8.179663073714632],
              [-38.509553388340535, -8.179625274692853],
              [-38.509486630308061, -8.179578158686827],
              [-38.509434936344654, -8.179535764453592],
              [-38.50938885706362, -8.179430422498024],
              [-38.509364698694434, -8.179364634633183],
              [-38.509428494270217, -8.179384430583491],
              [-38.509476950362014, -8.179402037062745],
              [-38.509556200325207, -8.179404304076705],
              [-38.509610846178283, -8.179408350263394],
              [-38.509689901649757, -8.17942119988961],
              [-38.509772876773653, -8.179420938726418],
              [-38.509859926036633, -8.179429727678592],
              [-38.509943366341659, -8.179420240948701],
              [-38.510025312272518, -8.179445033668141],
              [-38.510115716064071, -8.179457444709088],
              [-38.510199783454375, -8.179454833032565],
              [-38.510267097252331, -8.179418552923707],
              [-38.510310461940243, -8.179368042836742],
              [-38.510340595857798, -8.17929834090809],
              [-38.510354307954472, -8.179220659314117],
              [-38.510352952902949, -8.179140788621281],
              [-38.510357680566401, -8.179060653940642],
              [-38.510355598816673, -8.178981144174541],
              [-38.510373304854603, -8.17890382922363],
              [-38.510396998400566, -8.17882986824484],
              [-38.51042404468356, -8.178760976632534],
              [-38.510435924331595, -8.178697041505805],
              [-38.510476446298277, -8.178744216035751],
              [-38.510520228254684, -8.178798087952481],
              [-38.510572900993239, -8.178856764582283],
              [-38.510585961651827, -8.178940991143612],
              [-38.510598483360127, -8.179020513551508],
              [-38.510636699539802, -8.179097443961158],
              [-38.510683819858393, -8.179168234415481],
              [-38.510728152864658, -8.179216951219852],
              [-38.510777390530713, -8.179263684013671],
              [-38.510835336546172, -8.179315944908838],
              [-38.510923336136749, -8.179364352729012],
              [-38.510968064180098, -8.179386929378611],
              [-38.511048555574703, -8.179413438720756],
              [-38.511132058874381, -8.179426745707755],
              [-38.51122737275886, -8.179432740352954],
              [-38.511313881018374, -8.179438543425738],
              [-38.511396516166293, -8.179419286611083],
              [-38.511453682877871, -8.179366079518926],
              [-38.51149778424449, -8.179306886799509],
              [-38.511541992455086, -8.179234488219937],
              [-38.511578853150901, -8.179157286803195],
              [-38.511611083295634, -8.179080622498763],
              [-38.511634782904778, -8.179001505681958],
              [-38.511647598699781, -8.178914144586928],
              [-38.51163262501408, -8.178835161948596],
              [-38.511632094005982, -8.178749322411008],
              [-38.511651534328678, -8.178664049712426],
              [-38.51167151526019, -8.178582124387171],
              [-38.511691672793361, -8.178504269611036],
              [-38.511702758748392, -8.178420976758638],
              [-38.511729261631096, -8.178350818059462],
              [-38.511762234206053, -8.178410013458507],
              [-38.511795206680318, -8.178469299307132],
              [-38.511823925845384, -8.178517544879199],
              [-38.511866876079473, -8.178583626668434],
              [-38.51190019311683, -8.178658199305179],
              [-38.511923788262195, -8.178739544097514],
              [-38.511956827805903, -8.178818367636968],
              [-38.511993500945387, -8.178895296070005],
              [-38.512016732443676, -8.178977092678533],
              [-38.512045777730314, -8.179055820937956],
              [-38.512084808925984, -8.179134651701572],
              [-38.512130944219621, -8.179194496127119],
              [-38.512193915856734, -8.17922007957768],
              [-38.512258948361755, -8.179193293825815],
              [-38.512300682449101, -8.179139706174885],
              [-38.512323645120844, -8.179069271808604],
              [-38.512370152382786, -8.178972453873692],
              [-38.512410954983018, -8.179013025569605],
              [-38.512473733840011, -8.179047834843063],
              [-38.512548739121684, -8.179031915304494],
              [-38.512584328285861, -8.179104500689663],
              [-38.512631914187253, -8.179165974946592],
              [-38.512674066149835, -8.179216769316346],
              [-38.512731731838436, -8.179275813512442],
              [-38.512780788854187, -8.179322003146428],
              [-38.512835743304258, -8.179371003893628],
              [-38.512899134451992, -8.179424989659019],
              [-38.512964795276339, -8.179478887698236],
              [-38.513035185893564, -8.179525283913353],
              [-38.513117862390644, -8.179546730175449],
              [-38.513216370739542, -8.179538708148659],
              [-38.513297665065302, -8.179502173001428],
              [-38.513377604887062, -8.179459666376534],
              [-38.513454468085875, -8.179408924902413],
              [-38.513515727404943, -8.179349390791451],
              [-38.513570905082759, -8.179289216187396],
              [-38.513625625983686, -8.179231392776275],
              [-38.513689430432734, -8.179169148138822],
              [-38.513757589215231, -8.179109531835207],
              [-38.513818681455291, -8.17903796735891],
              [-38.513868431088312, -8.178962137988139],
              [-38.513922898729433, -8.178888485130699],
              [-38.513979722624882, -8.178818181820649],
              [-38.514034274215916, -8.178750137068873],
              [-38.514079822068787, -8.178695558829952],
              [-38.514112018849936, -8.178646029848045],
              [-38.514140763943324, -8.178598124850005],
              [-38.514171146167939, -8.178547689161526],
              [-38.514211796908604, -8.178488853776184],
              [-38.514296281735668, -8.178366211956568],
              [-38.514329273167235, -8.178335136112688],
              [-38.514359653267434, -8.178286418992007],
              [-38.514407033948686, -8.178217551497784],
              [-38.514461154892977, -8.178130149435532],
              [-38.514484904546762, -8.178009153203121],
              [-38.514487821199289, -8.177924493647215],
              [-38.514485315790374, -8.177819294978237],
              [-38.514489350595902, -8.177710847891658],
              [-38.514488481332045, -8.177603932598769],
              [-38.514479433192129, -8.177504062738223],
              [-38.514468842097401, -8.17740391966835],
              [-38.514443990102166, -8.177310543370199],
              [-38.514440567145442, -8.177213393823726],
              [-38.514433594191715, -8.177124109336384],
              [-38.514410918127965, -8.177033087401329],
              [-38.514393505426547, -8.176943699922628],
              [-38.514369358710979, -8.176867691249475],
              [-38.514333863672668, -8.176792211655725],
              [-38.514296825254185, -8.176716820657388],
              [-38.514253236033028, -8.176653451900762],
              [-38.514205539223539, -8.176608620862551],
              [-38.514167828700757, -8.176563530462563],
              [-38.514173977611513, -8.17650854304199],
              [-38.514152717788114, -8.176447552856875],
              [-38.51412130020914, -8.176378500237879],
              [-38.514072334807544, -8.176331768169929],
              [-38.514025280970905, -8.176280787150661],
              [-38.513979761337964, -8.176237315485439],
              [-38.513924094367241, -8.176177188451989],
              [-38.513885973494205, -8.176096188096972],
              [-38.513844129729705, -8.176015906878904],
              [-38.513809919270031, -8.175929303196106],
              [-38.513758801614117, -8.175860769578026],
              [-38.513707664581439, -8.175808245932378],
              [-38.51365588957367, -8.175757801909283],
              [-38.513598026762857, -8.175711873156683],
              [-38.513526257134302, -8.17568021907991],
              [-38.513455911732436, -8.175671903316237],
              [-38.513401792364711, -8.175683235146062],
              [-38.513349483792432, -8.175698549034987],
              [-38.513301798913567, -8.17571893379279],
              [-38.513251272396616, -8.175761928119051],
              [-38.513197574341795, -8.175800124665441],
              [-38.51313245353743, -8.175825372818142],
              [-38.513052842300233, -8.175822111137759],
              [-38.51298288536141, -8.175792901352258],
              [-38.512917662771279, -8.175752390761026],
              [-38.512869418900436, -8.175709729766359],
              [-38.512813004739058, -8.175667058923054],
              [-38.512749235673191, -8.175625735989954],
              [-38.512673367480424, -8.17560520242059],
              [-38.512594936999285, -8.175601489815845],
              [-38.512519575943607, -8.175611891403602],
              [-38.512449550867288, -8.175638852121086],
              [-38.512387422860172, -8.175666545964301],
              [-38.512334181725414, -8.175702210287989],
              [-38.512293718852952, -8.175755889957417],
              [-38.512285201372116, -8.175817477474165],
              [-38.512286380768856, -8.175892825384897],
              [-38.512270137981005, -8.175961730192117],
              [-38.512229248862518, -8.175992615443],
              [-38.512159531989731, -8.175989998684249],
              [-38.512090572150363, -8.175962056277033],
              [-38.512022068755932, -8.175932034014336],
              [-38.511923795859623, -8.175896458239684],
              [-38.511870921189832, -8.175929318929436],
              [-38.511868819761837, -8.175865728663984],
              [-38.511809112350647, -8.175769144341727],
              [-38.511755156983661, -8.175720235140151],
              [-38.511667544285181, -8.175652833088437],
              [-38.511701667072707, -8.175587206171105],
              [-38.511739417681234, -8.175524387641161],
              [-38.51178669368894, -8.175467007724619],
              [-38.511790820161799, -8.175358379899741],
              [-38.511776811690467, -8.175306895820842],
              [-38.511759750218353, -8.175227820207006],
              [-38.511755140927711, -8.175136277255614],
              [-38.511745712106602, -8.175051421930513],
              [-38.511717651082812, -8.174984724953566],
              [-38.511668030098683, -8.174954906342684],
              [-38.51160129565212, -8.174963418690719],
              [-38.511557884533595, -8.17497801947931],
              [-38.511489263932795, -8.174969886369702],
              [-38.511405753829976, -8.17496291113539],
              [-38.511327708753079, -8.174941017914614],
              [-38.511259928867524, -8.174913438611753],
              [-38.511218210714617, -8.17487964961242],
              [-38.511168853426291, -8.17485697696625],
              [-38.511124800672292, -8.174876642242165],
              [-38.511149012494556, -8.17482402847118],
              [-38.511184270176102, -8.174795578721907],
              [-38.511228150936617, -8.174768043910579],
              [-38.511277283990722, -8.174751188772321],
              [-38.511331951152343, -8.17473723477757],
              [-38.51140323814775, -8.174717602381078],
              [-38.511477071183805, -8.174694535872568],
              [-38.511548371040746, -8.174664320585425],
              [-38.511589907965813, -8.17462321510717],
              [-38.511596964770419, -8.174568409739488],
              [-38.511594111056716, -8.174526255678177],
              [-38.511598604759612, -8.174488904443868],
              [-38.511610540399033, -8.174453280783533],
              [-38.51161413982382, -8.174404802877236],
              [-38.511620554992689, -8.174355062043396],
              [-38.511608176224343, -8.174307198005927],
              [-38.511552905241878, -8.174220568728746],
              [-38.511515292073177, -8.174170503365623],
              [-38.511492920396101, -8.174128416181684],
              [-38.511450683918568, -8.174073008563278],
              [-38.511410983259417, -8.174022578862225],
              [-38.511386277608516, -8.173958870834479],
              [-38.511453673093605, -8.174003816421457],
              [-38.511521971889927, -8.174052471619564],
              [-38.511561512130747, -8.174085534339518],
              [-38.511604852847483, -8.174128732265389],
              [-38.511660813530213, -8.174170769549265],
              [-38.511708600654977, -8.174215510517122],
              [-38.511733660365202, -8.174286726468958],
              [-38.511778596681708, -8.174361584492745],
              [-38.511812943441207, -8.174410289111497],
              [-38.511890995329018, -8.174501197094415],
              [-38.511943669841052, -8.174558154951788],
              [-38.511981752615178, -8.174595376666398],
              [-38.512033626394285, -8.174638946655637],
              [-38.512107933176367, -8.174674222121041],
              [-38.512185883076924, -8.174699733154947],
              [-38.512268287405561, -8.174720184236083],
              [-38.512350522486216, -8.17473050447588],
              [-38.512428774855664, -8.174731231988417],
              [-38.512507363003152, -8.174754482432286],
              [-38.512590344002703, -8.174748612619535],
              [-38.512658834090786, -8.174714685215054],
              [-38.512723065150077, -8.17467442102833],
              [-38.512773507380906, -8.174625999540856],
              [-38.512801352186443, -8.174571761898061],
              [-38.512830029047691, -8.17450513333379],
              [-38.512853527220557, -8.174441935704284],
              [-38.51288591685902, -8.174382909573083],
              [-38.5129180352424, -8.174322978591986],
              [-38.512946260464673, -8.174254449981046],
              [-38.512954434643078, -8.174176399763661],
              [-38.512968343146227, -8.174085874077136],
              [-38.512959635826249, -8.174004728162631],
              [-38.513025676381027, -8.174044244747476],
              [-38.513070757780667, -8.174074509987159],
              [-38.513129539845011, -8.174110761546002],
              [-38.513196237504047, -8.174132459854501],
              [-38.513271310905871, -8.174134449698903],
              [-38.513350305642597, -8.174121791025755],
              [-38.513426033911436, -8.174107771627277],
              [-38.513503120818804, -8.174096286504279],
              [-38.513585389677729, -8.174078656863035],
              [-38.513656871614359, -8.174047808242854],
              [-38.513736065961758, -8.174020225153628],
              [-38.513814275181915, -8.173981515268686],
              [-38.513889032748281, -8.173944429355142],
              [-38.513929547568146, -8.173922498211784],
              [-38.514013214981702, -8.173874387837671],
              [-38.514089257233138, -8.173825906476448],
              [-38.514130625393967, -8.173773946272767],
              [-38.514165190572918, -8.173717364840604],
              [-38.514204477212893, -8.173660065459584],
              [-38.51423569552248, -8.173593530279941],
              [-38.514242764963086, -8.173527961068416],
              [-38.514245304231508, -8.173455059803793],
              [-38.514239205552357, -8.173393545117721],
              [-38.514238487951381, -8.173386398547718],
              [-38.514217959971262, -8.173320886644305],
              [-38.514191628823738, -8.173249759746138],
              [-38.514147322474216, -8.173179153968409],
              [-38.514135011883297, -8.173149742280724],
              [-38.514122015542426, -8.173086862527981],
              [-38.514108835564613, -8.173025701142391],
              [-38.514100705481795, -8.172992495491355],
              [-38.514091209752259, -8.172962544471821],
              [-38.514084619657318, -8.172932054230488],
              [-38.514071842260066, -8.172913315316839],
              [-38.514024602092945, -8.172866585332194],
              [-38.513989793938336, -8.17282394061953],
              [-38.51396587736911, -8.172783027578872],
              [-38.513935604638696, -8.172743373227574],
              [-38.513870112269004, -8.172701053442653],
              [-38.513823851353493, -8.172670334617841],
              [-38.51375599827842, -8.172628373786406],
              [-38.513713997270536, -8.172603268094385],
              [-38.513771668914082, -8.172581809842287],
              [-38.513928463672855, -8.172492993504305],
              [-38.513984346610052, -8.172449281878009],
              [-38.514013012599861, -8.172391336582319],
              [-38.513988115746919, -8.172335316881068],
              [-38.513924871159233, -8.172310999756899],
              [-38.513864709827693, -8.172289399888498],
              [-38.513845969313714, -8.172247226734259],
              [-38.513832522860568, -8.172181271064659],
              [-38.513821046861793, -8.172137388051494],
              [-38.513789739030337, -8.17205295870621],
              [-38.513818220717802, -8.171997274501784],
              [-38.513871958388478, -8.172000866706959],
              [-38.513947750202036, -8.172009008002997],
              [-38.513971799431225, -8.172015278084723],
              [-38.514014620813306, -8.17203758072151],
              [-38.514057332705654, -8.172075350515588],
              [-38.514098417892015, -8.172107058067228],
              [-38.51412650928355, -8.172073805468095],
              [-38.514149529227524, -8.172030416151138],
              [-38.514187980505589, -8.171988130787868],
              [-38.514227868944189, -8.171958510427235],
              [-38.514282450986386, -8.171939490589189],
              [-38.514341468242613, -8.171931239857114],
              [-38.514395215099739, -8.171927234034605],
              [-38.514447326161246, -8.17192467347378],
              [-38.514500980268849, -8.171922295662082],
              [-38.51455559316458, -8.171952843526574],
              [-38.514592224898699, -8.171988887382533],
              [-38.514649651672869, -8.172019709967449],
              [-38.514696173449927, -8.172059926472624],
              [-38.514749988919128, -8.172074282445243],
              [-38.51478607340605, -8.172037330851806],
              [-38.514809829246239, -8.171985892146171],
              [-38.514812902468378, -8.17192176535203],
              [-38.514806166391651, -8.171861787586099],
              [-38.514801792024031, -8.171800636776201],
              [-38.514802951428607, -8.17174265842352],
              [-38.514827704300103, -8.171692487238968],
              [-38.51486115185601, -8.171659060102169],
              [-38.514900234444873, -8.171620122145791],
              [-38.514936511031841, -8.171574396919295],
              [-38.514973954209083, -8.171539889138222],
              [-38.515021735402186, -8.171514438947943],
              [-38.51507540703777, -8.171497498302045],
              [-38.515133258598659, -8.171477215933338],
              [-38.515185853200087, -8.171450052902774],
              [-38.515239255864799, -8.171430307899094],
              [-38.515272965881692, -8.171405021721352],
              [-38.515335605632096, -8.171403920888464],
              [-38.515372689875463, -8.171441141094304],
              [-38.515396861618946, -8.17149634578395],
              [-38.515411409791831, -8.171552262561875],
              [-38.515442571734042, -8.171607385169779],
              [-38.515487097803572, -8.171646423319959],
              [-38.51549045318113, -8.171649321803212],
              [-38.515545066342291, -8.171679688632965],
              [-38.515588259635209, -8.171694755389643],
              [-38.515648417933207, -8.171718887655635],
              [-38.515708670618288, -8.171740035109169],
              [-38.515769029248524, -8.171748609848686],
              [-38.515820323375038, -8.171745957679342],
              [-38.515871099640414, -8.171721053683974],
              [-38.515916076755516, -8.171687187991738],
              [-38.515953331327992, -8.171658468820372],
              [-38.515980706435116, -8.171616803185033],
              [-38.515979689994026, -8.171556289558579],
              [-38.515968943282722, -8.171509693904103],
              [-38.515952112228305, -8.17146480955862],
              [-38.515925652371273, -8.171424979005858],
              [-38.515922751874236, -8.17142126700203],
              [-38.515897100977305, -8.171388130866033],
              [-38.515856673956065, -8.171337519804712],
              [-38.515819235167214, -8.171293153506186],
              [-38.515794589410909, -8.171254591445374],
              [-38.515768856369178, -8.171214309493264],
              [-38.515746393162438, -8.171172584220905],
              [-38.515726834815439, -8.1711309528764],
              [-38.515693043296174, -8.171073294484479],
              [-38.515667600502368, -8.171018178741065],
              [-38.515622983481954, -8.170979321407641],
              [-38.515592898483789, -8.170934511634234],
              [-38.515558103400465, -8.170881012824436],
              [-38.515499678671389, -8.170849736916905],
              [-38.515488062313963, -8.170846828541713],
              [-38.515431613807095, -8.170832921781109],
              [-38.515370709551831, -8.170825250865898],
              [-38.515312542343786, -8.170806276716863],
              [-38.515256092212731, -8.17079372671091],
              [-38.51520652848793, -8.170791858287595],
              [-38.515146258176976, -8.170785454427106],
              [-38.515110966684588, -8.1707672312779],
              [-38.515025928061803, -8.170748677145358],
              [-38.514994419197606, -8.170755423279074],
              [-38.514939018579696, -8.170775889476493],
              [-38.514896875229724, -8.170793567548088],
              [-38.514848408407651, -8.170785459206552],
              [-38.514817859342713, -8.170749151314533],
              [-38.514789403577495, -8.170708413781604],
              [-38.514746951742445, -8.170680865457019],
              [-38.514715508553572, -8.170633249983064],
              [-38.514686880863223, -8.170584552459729],
              [-38.514666695499685, -8.170536045965109],
              [-38.514647329455499, -8.170485640959926],
              [-38.514622782619114, -8.170440475956523],
              [-38.514582229661137, -8.170419171083799],
              [-38.514536280354882, -8.170431236528332],
              [-38.514484101337359, -8.170415073462339],
              [-38.514441999992307, -8.170398017963091],
              [-38.514393555645029, -8.170371366933356],
              [-38.514363192586337, -8.17033144115298],
              [-38.514324843305943, -8.170289515840471],
              [-38.514285670343021, -8.170253107109131],
              [-38.514254126836875, -8.170213451260459],
              [-38.514222304653231, -8.170179041292135],
              [-38.514187217352365, -8.170142185196937],
              [-38.514153306586422, -8.17010831542817],
              [-38.514115495792431, -8.170071546509314],
              [-38.514075421088627, -8.170030161815882],
              [-38.514036414086682, -8.170006687900147],
              [-38.513991857919173, -8.169992795229723],
              [-38.513943494363801, -8.169974465832453],
              [-38.513895773167285, -8.169950438724435],
              [-38.513859498883193, -8.169919279654254],
              [-38.513813062956302, -8.169883404867447],
              [-38.513760986646638, -8.169857473019659],
              [-38.513709367746671, -8.169828647250762],
              [-38.513664719299243, -8.169816020763138],
              [-38.51360924407502, -8.169823190247792],
              [-38.513563291201095, -8.169838240496404],
              [-38.513501621585988, -8.169863040661472],
              [-38.51345410047044, -8.16989871206964],
              [-38.513415578053433, -8.169924896831724],
              [-38.513368586971303, -8.169897704721246],
              [-38.513314828659873, -8.169911388773743],
              [-38.513261379178338, -8.169894952672358],
              [-38.513224385508181, -8.169858184658594],
              [-38.513191108994107, -8.169825401001832],
              [-38.513176635302777, -8.169783232955076],
              [-38.513203217780969, -8.169796470926935],
              [-38.513241789003352, -8.16980492936203],
              [-38.513270212176913, -8.169797456034335],
              [-38.513307929515726, -8.169761411048739],
              [-38.513352085857584, -8.169730620019292],
              [-38.513393421007926, -8.169705614520611],
              [-38.513443289233123, -8.169680800181339],
              [-38.513487275607524, -8.169640421018451],
              [-38.513522178958993, -8.16960419172349],
              [-38.513555907205316, -8.16956380022002],
              [-38.513586003378975, -8.169524127963648],
              [-38.513612924336506, -8.169482733300061],
              [-38.513636871457351, -8.169423244657567],
              [-38.513639650364738, -8.169377388826794],
              [-38.513612672035883, -8.169316210841568],
              [-38.5135998354151, -8.169271512118671],
              [-38.51357783092579, -8.169226078759827],
              [-38.513555015447551, -8.169175669564551],
              [-38.513525213117674, -8.16912262886097],
              [-38.513489412000879, -8.169075641228943],
              [-38.513453968753147, -8.169032995720897],
              [-38.513420512038849, -8.168999126443705],
              [-38.513389869169586, -8.16896553190344],
              [-38.513362774488371, -8.168925519534872],
              [-38.513317073392059, -8.168882680777315],
              [-38.51326317332456, -8.168863621033065],
              [-38.513205643942186, -8.1688430192323],
              [-38.513162546107893, -8.168824424728427],
              [-38.513105910721826, -8.168815220942548],
              [-38.513048177104409, -8.168813342440147],
              [-38.513071543629131, -8.168783521391818],
              [-38.513117238195889, -8.168756802572474],
              [-38.513119755665599, -8.16870199168349],
              [-38.513126901575369, -8.168648271786502],
              [-38.513162093643928, -8.168598475063524],
              [-38.513166656511785, -8.168578762013915],
              [-38.513178947512053, -8.16862418370785],
              [-38.513154300008374, -8.168662324807542],
              [-38.513167497270366, -8.168709013919999],
              [-38.513204590710799, -8.168738274537745],
              [-38.513253956601723, -8.168753348947114],
              [-38.513305349120174, -8.168744094196724],
              [-38.513360178004092, -8.168745788265108],
              [-38.513406009962779, -8.168755521738158],
              [-38.513465427424421, -8.168791231266518],
              [-38.513506048047063, -8.168831440792223],
              [-38.513559385924758, -8.168864972151155],
              [-38.513614925297453, -8.168879692130245],
              [-38.513657110876302, -8.168901994023779],
              [-38.513700005837173, -8.168938226375992],
              [-38.513736729652685, -8.168972913624957],
              [-38.513787968582633, -8.16901557808737],
              [-38.51384375033625, -8.169055082180508],
              [-38.513879210483033, -8.169083798066509],
              [-38.513933815840488, -8.169120225366401],
              [-38.513987250372885, -8.169148962831816],
              [-38.514031251923683, -8.169170995513737],
              [-38.514087870322697, -8.169194309668693],
              [-38.514126502100638, -8.169227732847776],
              [-38.51415887150835, -8.169259972628943],
              [-38.514195504723389, -8.169294569278845],
              [-38.51425612500303, -8.169311647026344],
              [-38.514298107653509, -8.169276602031758],
              [-38.51433301221455, -8.16923928723736],
              [-38.514372364317396, -8.169202520488751],
              [-38.514414259665102, -8.16916458091119],
              [-38.514466871268354, -8.169123126556904],
              [-38.514503762132108, -8.169094768879432],
              [-38.514551285576069, -8.169057016941908],
              [-38.514607155165237, -8.169023888062075],
              [-38.514660667971349, -8.168987861882574],
              [-38.514710728410925, -8.168954092856383],
              [-38.514765953558516, -8.16892838024655],
              [-38.514821066948386, -8.168920034284584],
              [-38.514869104440585, -8.1689076094915],
              [-38.514921685388394, -8.168891481605074],
              [-38.514976013529036, -8.168856813138834],
              [-38.515030972423169, -8.168826034857952],
              [-38.515078921521024, -8.168811619988203],
              [-38.515136852878705, -8.168800111537195],
              [-38.51519597942962, -8.168776122128408],
              [-38.51524686423766, -8.168736203280437],
              [-38.515293579831258, -8.168690761857869],
              [-38.515335214595275, -8.168642962591655],
              [-38.515372847957487, -8.168601037911612],
              [-38.515412111961943, -8.168561919193142],
              [-38.515446021929229, -8.168521165923593],
              [-38.515469318973963, -8.168473525589995],
              [-38.515479370274264, -8.16841917594658],
              [-38.515479556599566, -8.168339940181506],
              [-38.515471632901033, -8.168286111730874],
              [-38.515463949584458, -8.168258786016093],
              [-38.51544716178725, -8.168178173148799],
              [-38.515451311980151, -8.168124178248421],
              [-38.51546272081827, -8.168073267406957],
              [-38.515475944696391, -8.168022810997867],
              [-38.515489006861415, -8.167955892122565],
              [-38.515491568207075, -8.167939704272914],
              [-38.515496232856393, -8.16791076520278],
              [-38.5155056378821, -8.167865369534942],
              [-38.515526933259792, -8.167821435331719],
              [-38.515571563729672, -8.167773549166128],
              [-38.515573018162371, -8.167771922769983],
              [-38.515612548295017, -8.167737869657902],
              [-38.515644813673774, -8.167705797793145],
              [-38.515677643673449, -8.167657173876504],
              [-38.515693056568445, -8.167598127131706],
              [-38.515692852689696, -8.167541323001897],
              [-38.515664312256781, -8.167495610568086],
              [-38.515618868236565, -8.167465345204899],
              [-38.515567136790573, -8.167454700406294],
              [-38.515513899285637, -8.167413209650864],
              [-38.515492226263603, -8.16739391742351],
              [-38.515477263930869, -8.167380422158777],
              [-38.51543699697482, -8.167347992124423],
              [-38.515398364379422, -8.167315292686865],
              [-38.515350363636642, -8.16729741616474],
              [-38.515297256567848, -8.167298347547778],
              [-38.515248314107147, -8.167308600471605],
              [-38.515198556813296, -8.167317043372631],
              [-38.515142717197683, -8.167325569433981],
              [-38.515054537129764, -8.167353142123613],
              [-38.515005040703294, -8.167371082776988],
              [-38.514975432139991, -8.167383077420691],
              [-38.514925394743308, -8.167397942046403],
              [-38.514871564486718, -8.16739633984896],
              [-38.514827030955836, -8.167363904643077],
              [-38.51482663439127, -8.167316416848108],
              [-38.514839948004763, -8.167266865089061],
              [-38.51483684749843, -8.167203273590069],
              [-38.514838632799872, -8.167153165300645],
              [-38.514839420730326, -8.16710206084319],
              [-38.514846833139565, -8.167052954270185],
              [-38.514858697439479, -8.167000777663041],
              [-38.514873093289282, -8.166957106580895],
              [-38.514890122932634, -8.166912443681852],
              [-38.514906698130268, -8.166868232497407],
              [-38.514918468142874, -8.166818950239621],
              [-38.514925159651675, -8.166765410649777],
              [-38.514915155223143, -8.166705700314141],
              [-38.514890518983428, -8.166659540250556],
              [-38.514869325803986, -8.166618811434381],
              [-38.51485213367841, -8.166572479385803],
              [-38.514834301844516, -8.166529674199527],
              [-38.514811656648618, -8.166488581832365],
              [-38.514809279757301, -8.166427162047681],
              [-38.514787291643998, -8.166368160962859],
              [-38.51476593527704, -8.166312236003611],
              [-38.514748474332222, -8.166263009163888],
              [-38.514707034517713, -8.166224698276379],
              [-38.51465594024274, -8.166212787806423],
              [-38.514602924209427, -8.166213628763622],
              [-38.51456515828491, -8.166215121164822],
              [-38.514594150216098, -8.166187477604025],
              [-38.514588182070348, -8.166168204163561],
              [-38.51462558585758, -8.166165897257404],
              [-38.514667171048913, -8.16615898230655],
              [-38.51469269501905, -8.166146530523285],
              [-38.514724932701554, -8.166137343059619],
              [-38.514772973680302, -8.166121752439981],
              [-38.51481891046982, -8.166119636647094],
              [-38.514866474184451, -8.166123402181407],
              [-38.514901512582654, -8.16612534366091],
              [-38.514954615455295, -8.166127759041581],
              [-38.515041588934892, -8.166121893402613],
              [-38.515109760685654, -8.166124688622],
              [-38.515171109065292, -8.166139867588933],
              [-38.515223022979583, -8.166149608126604],
              [-38.51527498925816, -8.166115931737009],
              [-38.515269884561839, -8.166058126765822],
              [-38.515291087878296, -8.16601518742946],
              [-38.51532389813579, -8.165982844877378],
              [-38.515378685620924, -8.165943292466764],
              [-38.515419843057991, -8.165914668432046],
              [-38.515456096681568, -8.165887485756521],
              [-38.515494074336978, -8.165860938305006],
              [-38.515516240752724, -8.165847397024272],
              [-38.515547218610877, -8.165828981875418],
              [-38.515604896808668, -8.165801191657575],
              [-38.515645999716781, -8.165817793560128],
              [-38.515665461177456, -8.165788962637283],
              [-38.515654957961935, -8.165766427510173],
              [-38.515685875763694, -8.165722504798364],
              [-38.515718598456523, -8.16568744854419],
              [-38.515754959927513, -8.16564606499532],
              [-38.515796133107813, -8.165604325389173],
              [-38.515841813409736, -8.165589003169163],
              [-38.515901373181798, -8.165581747773079],
              [-38.515962728161099, -8.165591409049689],
              [-38.516017526043854, -8.165618519726275],
              [-38.516074855506972, -8.165654135915583],
              [-38.516120293923272, -8.165688923816928],
              [-38.516168743403888, -8.165710961571234],
              [-38.516222335515707, -8.165683980447216],
              [-38.516236742704066, -8.165630721397385],
              [-38.516198036292053, -8.165584001881212],
              [-38.516147340870972, -8.165542423801412],
              [-38.516143714942253, -8.165538077769108],
              [-38.516108634913337, -8.165495342469896],
              [-38.516084351259799, -8.165457866292014],
              [-38.516081904804238, -8.165454064381262],
              [-38.516040573028043, -8.165401371877595],
              [-38.515988320573946, -8.165371279330445],
              [-38.515945454119112, -8.16538687628919],
              [-38.515899029747651, -8.165417031770749],
              [-38.515855755038949, -8.16539472881856],
              [-38.515829111383681, -8.16535706890064],
              [-38.515792772159543, -8.165304653700344],
              [-38.515766768544047, -8.165263195557861],
              [-38.515735410183261, -8.165220736036654],
              [-38.515701152901443, -8.165173298182507],
              [-38.51567097653119, -8.16512930238583],
              [-38.51563907166512, -8.165088379889291],
              [-38.515608796681242, -8.165050896518704],
              [-38.515569343238745, -8.165021542849781],
              [-38.515527796004214, -8.164997071082967],
              [-38.51549512873828, -8.164986177746862],
              [-38.51548015624202, -8.164981184966907],
              [-38.515430784600582, -8.164971176156417],
              [-38.515381494798881, -8.16496858450647],
              [-38.515332475807547, -8.164967259503488],
              [-38.515286908481151, -8.164964220037744],
              [-38.515240705922778, -8.16496099890225],
              [-38.515194691686368, -8.164952169959767],
              [-38.515128974976861, -8.164946211883974],
              [-38.51506380545203, -8.16493817405588],
              [-38.515005423708985, -8.164947239785608],
              [-38.514952471224682, -8.164970784338809],
              [-38.514913788897118, -8.164979511845562],
              [-38.514865485877912, -8.164986690136397],
              [-38.514819548999789, -8.164988986846074],
              [-38.514754361133654, -8.164996144890086],
              [-38.514778569310096, -8.164945611207461],
              [-38.514776173421446, -8.164899930052201],
              [-38.514769873721249, -8.164854515574717],
              [-38.514760220014622, -8.164805117187464],
              [-38.514732943890223, -8.1647651046933],
              [-38.51470132153279, -8.164715860879486],
              [-38.514660372023087, -8.164647520501724],
              [-38.514643803649129, -8.164610958014768],
              [-38.51462969973413, -8.16456327287961],
              [-38.514625955615642, -8.164506464505758],
              [-38.514606786669781, -8.164443396450386],
              [-38.514574248701528, -8.164400664078004],
              [-38.514529807385124, -8.164367324445163],
              [-38.514508406140173, -8.164348665664489],
              [-38.51449471299307, -8.164336709576407],
              [-38.514463734339806, -8.164280682614939],
              [-38.51443538805453, -8.164224839712105],
              [-38.514395234229106, -8.164174228866477],
              [-38.514347627504769, -8.164131116598652],
              [-38.514310549678932, -8.164089192831055],
              [-38.514288292800188, -8.16402738738811],
              [-38.514259833264482, -8.163990177460189],
              [-38.514208772621721, -8.163950679116359],
              [-38.514166774971798, -8.163923493118135],
              [-38.514107168052043, -8.163894838754063],
              [-38.514053004132165, -8.163869266282976],
              [-38.513993742192156, -8.163855627356034],
              [-38.513940430845601, -8.163875734174766],
              [-38.513884393186878, -8.163898279918421],
              [-38.513855761110428, -8.163853652690085],
              [-38.513851194518232, -8.163801456380449],
              [-38.513843557319312, -8.163736140838425],
              [-38.513831719595416, -8.16369126241451],
              [-38.51378793388713, -8.163641370770879],
              [-38.513742010182547, -8.163632903590074],
              [-38.513681295944984, -8.16361917242399],
              [-38.513628049395436, -8.163585731673702],
              [-38.513572149270189, -8.163569654520741],
              [-38.513497035105217, -8.163527956351311],
              [-38.513473378176236, -8.163497988305988],
              [-38.513435948114711, -8.163447199742864],
              [-38.513398125829674, -8.163420470947027],
              [-38.513345842420556, -8.163416337779077],
              [-38.513299847684152, -8.163391589098344],
              [-38.5132776434505, -8.16336144188452],
              [-38.513248173010865, -8.163334542209361],
              [-38.513191650193789, -8.163307971822194],
              [-38.513127406736089, -8.163285100678884],
              [-38.513061338518028, -8.163269825302562],
              [-38.512990091887673, -8.163257890413529],
              [-38.512918564051567, -8.163253281788439],
              [-38.512848216883896, -8.163248222310923],
              [-38.512782045521149, -8.163243167845126],
              [-38.512720525121075, -8.163220571271333],
              [-38.512661093835305, -8.163196982230259],
              [-38.512585935104582, -8.1631921882739],
              [-38.512518938636838, -8.163193826222681],
              [-38.512403113975729, -8.163186721979296],
              [-38.512392456325991, -8.163142025847153],
              [-38.512399710737654, -8.16307365285085],
              [-38.512415685233215, -8.163000858203453],
              [-38.51243202831931, -8.162923541397229],
              [-38.512440649546377, -8.162850918799124],
              [-38.512466514460662, -8.162781754135635],
              [-38.512482030542216, -8.162712667464492],
              [-38.512503810640737, -8.162643316976087],
              [-38.512524585701662, -8.162579301945815],
              [-38.51248048577056, -8.162414445267698],
              [-38.512352291425515, -8.162352331300191],
              [-38.512292930333615, -8.162345837730353],
              [-38.512223670810329, -8.162342317147827],
              [-38.51215531906994, -8.162338797647022],
              [-38.512085429371886, -8.162330934584762],
              [-38.512035625564565, -8.162303377169389],
              [-38.511982827624408, -8.162274549812212],
              [-38.511930117725534, -8.162247983855195],
              [-38.511878944143923, -8.162227118220603],
              [-38.511829756155521, -8.162215752435268],
              [-38.511781271442615, -8.162223291964176],
              [-38.511711585462947, -8.162271961596925],
              [-38.511668982094108, -8.162295337305572],
              [-38.511622925308195, -8.162321874665986],
              [-38.511570398331592, -8.162368936826045],
              [-38.511534225137041, -8.162404712200061],
              [-38.511500411727496, -8.16244085222475],
              [-38.511476389052191, -8.162487948749684],
              [-38.511449648521861, -8.16253051939359],
              [-38.511397875639872, -8.162554607626104],
              [-38.511337322114841, -8.162558243102287],
              [-38.511242202898146, -8.162544741427185],
              [-38.511213480765264, -8.162499842566822],
              [-38.511199294571902, -8.162445554238735],
              [-38.511189202852464, -8.162383491978856],
              [-38.511173305846533, -8.162317714179675],
              [-38.511141233524903, -8.162265756014696],
              [-38.511089618084846, -8.162235030480087],
              [-38.511032097679916, -8.162207915890871],
              [-38.510969479302233, -8.162192644352075],
              [-38.510903855902974, -8.162185057595886],
              [-38.510840199255505, -8.16220243795885],
              [-38.51079549794003, -8.162234042172182],
              [-38.510758768800038, -8.162279042907139],
              [-38.510724053608328, -8.162310206914112],
              [-38.510680189498771, -8.162399791860381],
              [-38.510627459865589, -8.162389507075099],
              [-38.510572575671738, -8.162359410690495],
              [-38.510529317686249, -8.162323901229275],
              [-38.510467348855819, -8.162297052541508],
              [-38.510390183974394, -8.162299853732019],
              [-38.510321525685249, -8.162324464124872],
              [-38.510268104665535, -8.16236012807421],
              [-38.510205903833075, -8.162374706082437],
              [-38.510147851682071, -8.16241154029024],
              [-38.51009007416927, -8.16244647532934],
              [-38.510038374040441, -8.162485307147509],
              [-38.509997565511711, -8.162525599367774],
              [-38.509964994635062, -8.162584896469566],
              [-38.509958654882645, -8.162647300675495],
              [-38.509969476387646, -8.162706288469826],
              [-38.509985617982259, -8.162794679599125],
              [-38.509983003219958, -8.162854555655896],
              [-38.509991912768371, -8.162918154187906],
              [-38.51000563444628, -8.162981034925943],
              [-38.510015998766349, -8.163042735725652],
              [-38.510022636110648, -8.163108592807717],
              [-38.510027008078339, -8.163171100424915],
              [-38.510033741053519, -8.163232977734536],
              [-38.510063077052216, -8.163295515553822],
              [-38.510127067448124, -8.163376909307333],
              [-38.51017150837631, -8.163410158945192],
              [-38.510215858533677, -8.163443408468739],
              [-38.510275014600843, -8.163469258829318],
              [-38.510339841564978, -8.163460111116017],
              [-38.510412265709448, -8.163474128288335],
              [-38.510411201942134, -8.163527674584628],
              [-38.510409661371874, -8.163600034319666],
              [-38.510403671448181, -8.163673383646952],
              [-38.510400587641776, -8.16374574151552],
              [-38.510405234428035, -8.163806259518903],
              [-38.510386103988282, -8.163861231202326],
              [-38.510386572541279, -8.163923734097521],
              [-38.510382959258258, -8.163983608949293],
              [-38.510379523454652, -8.164046830739162],
              [-38.510392730082003, -8.164160002152377],
              [-38.510409437080064, -8.164231479427986],
              [-38.510426864363289, -8.164307841981628],
              [-38.510439711183935, -8.164343766955458],
              [-38.510449844233442, -8.164371819324513],
              [-38.510487922647108, -8.164411754689157],
              [-38.510523258658694, -8.164467334930434],
              [-38.510546056774736, -8.164531492953092],
              [-38.510563046829887, -8.164594196722302],
              [-38.510591346333712, -8.164688029646795],
              [-38.51060336427917, -8.164733722429879],
              [-38.51061755375666, -8.164785297216822],
              [-38.510650258557241, -8.164839788844812],
              [-38.510681073199727, -8.164880981743048],
              [-38.510708797520451, -8.164925336724558],
              [-38.510746479208358, -8.164992949899338],
              [-38.510710175993687, -8.16498612213077],
              [-38.510660548685152, -8.164962544637286],
              [-38.510605461668483, -8.164949633874082],
              [-38.510543356944659, -8.164959508522413],
              [-38.510482516620932, -8.164974721357181],
              [-38.510419968793883, -8.164975731155321],
              [-38.510352525639696, -8.164971217452429],
              [-38.510288792512306, -8.164976477040417],
              [-38.510235023217092, -8.164999658164509],
              [-38.510196044448371, -8.165028103389071],
              [-38.510150237652709, -8.165072640802469],
              [-38.510119478174772, -8.165135196361643],
              [-38.510108311567038, -8.165210348450925],
              [-38.510111030573576, -8.165288411804401],
              [-38.510106849843631, -8.165366919068871],
              [-38.510101576899586, -8.165447776762955],
              [-38.510094398589999, -8.165527818083065],
              [-38.510103108332295, -8.165606431396323],
              [-38.510129254887822, -8.16567909597695],
              [-38.510154142122765, -8.165742261574255],
              [-38.510171747986739, -8.165821066553734],
              [-38.510149147853497, -8.165892405824092],
              [-38.510134253113719, -8.165972618710471],
              [-38.510127434162392, -8.16605573583116],
              [-38.510141591754667, -8.166133451214922],
              [-38.510166941177964, -8.166189833472302],
              [-38.510166410337803, -8.166252968313387],
              [-38.510162416446846, -8.166327134105776],
              [-38.510156605871352, -8.166402202219404],
              [-38.51014354763489, -8.166465231448987],
              [-38.510108164701293, -8.166522535187259],
              [-38.510094636790249, -8.1665984984789],
              [-38.510097893515464, -8.166682260952905],
              [-38.510114953511213, -8.166762060244213],
              [-38.510136470287904, -8.166834809673796],
              [-38.510156433684003, -8.16691587880476],
              [-38.510177766270772, -8.166990798857155],
              [-38.510199551768267, -8.167066533524387],
              [-38.510233600727183, -8.167135499130486],
              [-38.510273203650563, -8.167190994108745],
              [-38.510314800971237, -8.167248752795812],
              [-38.510329939272509, -8.167266680601024],
              [-38.510370710008146, -8.167332307605394],
              [-38.510414050124091, -8.167375324718909],
              [-38.510454109635788, -8.1674286493864],
              [-38.510498333213668, -8.167491657448046],
              [-38.510548369211428, -8.167552592138442],
              [-38.510596602196898, -8.167603213117413],
              [-38.51063994291674, -8.167645777950115],
              [-38.510684554886232, -8.16768807295945],
              [-38.510732893772207, -8.167726302130216],
              [-38.510787040561013, -8.167766166451182],
              [-38.510737451711975, -8.167785282332421],
              [-38.51073367469472, -8.167830503757964],
              [-38.510720101768683, -8.167868929443122],
              [-38.510695359212953, -8.167835884559272],
              [-38.510653014176164, -8.16779567268671],
              [-38.51060749760051, -8.167750753472378],
              [-38.510567953409321, -8.167721489667885],
              [-38.510521498980481, -8.167701262701247],
              [-38.510476681889273, -8.167678324150424],
              [-38.510431686109989, -8.16765303362728],
              [-38.510382249315796, -8.16762185834882],
              [-38.510320718607055, -8.167607311597894],
              [-38.510266150348031, -8.167615386256077],
              [-38.510206505724589, -8.167617484933675],
              [-38.510150139519013, -8.167611085084298],
              [-38.510093966893891, -8.167594826196225],
              [-38.510039430947117, -8.167576398434386],
              [-38.509978101672822, -8.167545570520987],
              [-38.509940464796927, -8.167515585351914],
              [-38.509924950482073, -8.167508239958405],
              [-38.509855445801513, -8.167481743823634],
              [-38.509783847801927, -8.167459677288871],
              [-38.509707425821226, -8.167448006875237],
              [-38.509636533541595, -8.167442765236359],
              [-38.509568074530804, -8.167452089300271],
              [-38.509512210534574, -8.167480513980156],
              [-38.50947249043886, -8.167521078779936],
              [-38.50946048582847, -8.167538883269192],
              [-38.509405594324022, -8.167588655775567],
              [-38.50936262305045, -8.167615377471865],
              [-38.509316394541848, -8.167633230922084],
              [-38.509253598493181, -8.167614159878829],
              [-38.50924507319688, -8.167607637001089],
              [-38.509186293317242, -8.167570661328416],
              [-38.5091246045357, -8.16753693839077],
              [-38.509063902291572, -8.167513075904628],
              [-38.508999659886534, -8.167489028211564],
              [-38.508940688100118, -8.167460554756866],
              [-38.508881630517628, -8.167428010851737],
              [-38.508818467207156, -8.167412376472738],
              [-38.508762271122983, -8.167415383643061],
              [-38.508699933126714, -8.167392966350665],
              [-38.508637967544772, -8.167362951536756],
              [-38.508573920245603, -8.167327868876955],
              [-38.508505496640964, -8.167308248178246],
              [-38.50844489875, -8.167273350591406],
              [-38.508380842299111, -8.167245775403774],
              [-38.508330594248044, -8.1672100763033],
              [-38.508292777967966, -8.167178462636398],
              [-38.508242166816899, -8.167142763084701],
              [-38.508174107375552, -8.167122328707405],
              [-38.508100206635199, -8.167127575574712],
              [-38.508042070790125, -8.16715789677885],
              [-38.508008697487512, -8.167204710326967],
              [-38.508008180471954, -8.167256267309638],
              [-38.507994048131408, -8.167306541415797],
              [-38.507962574860088, -8.167358513029237],
              [-38.50794406849554, -8.167422349608037],
              [-38.507948062519944, -8.167496705964046],
              [-38.507975954295269, -8.167552458269002],
              [-38.508051118140656, -8.167627805650122],
              [-38.508094450051374, -8.167677335529172],
              [-38.508100181531923, -8.167741382474027],
              [-38.508102908550335, -8.167812571471304],
              [-38.508090655988397, -8.167884556330913],
              [-38.508070510382595, -8.16795264216281],
              [-38.508054894655238, -8.1680281505585],
              [-38.508027302692234, -8.168098217279002],
              [-38.507989012598706, -8.168156964498923],
              [-38.507944001940444, -8.168218055294401],
              [-38.507903431925357, -8.168285302215276],
              [-38.507874213752523, -8.168348944856739],
              [-38.507842449093211, -8.168416383380304],
              [-38.507814402390572, -8.168487082691813],
              [-38.507794253599961, -8.168557701153954],
              [-38.507810673966354, -8.168640575101156],
              [-38.507843914468694, -8.16870230374845],
              [-38.50789413805775, -8.168758083216284],
              [-38.507938846930251, -8.168795222873557],
              [-38.507994171254161, -8.168837078932786],
              [-38.50805393780589, -8.16888382478983],
              [-38.508089305718059, -8.168913355113972],
              [-38.508139187731231, -8.168951586462144],
              [-38.508202338514899, -8.168977622916866],
              [-38.508283933939808, -8.168990294885429],
              [-38.508367820110202, -8.168985602846988],
              [-38.50845462350852, -8.168970874168172],
              [-38.508544310493541, -8.16897360619814],
              [-38.508622368738017, -8.168983650697019],
              [-38.508693588324967, -8.169018380261393],
              [-38.508759079632675, -8.169060610372528],
              [-38.508805510338561, -8.169100284684825],
              [-38.508849492105625, -8.169138237433048],
              [-38.508906356402761, -8.169182899254617],
              [-38.508956687454933, -8.169225201390779],
              [-38.509007095258895, -8.16927899101233],
              [-38.509050245568652, -8.169328791937758],
              [-38.509101161935739, -8.169412159954737],
              [-38.509128952161731, -8.169476957264171],
              [-38.509072209230617, -8.169481501480529],
              [-38.508993011197674, -8.169438350264887],
              [-38.508933052719108, -8.169399925883392],
              [-38.508863459778993, -8.169371168159644],
              [-38.508801035565433, -8.169344770876572],
              [-38.508729339450767, -8.169328492946997],
              [-38.508647714181215, -8.169340152601544],
              [-38.508563193142784, -8.169344301211144],
              [-38.508493706112652, -8.169377412685666],
              [-38.508451610620881, -8.169430095040257],
              [-38.508430827532209, -8.169499808244646],
              [-38.508435166834438, -8.169588818229196],
              [-38.508453500488685, -8.169666176911788],
              [-38.508500429319142, -8.169743932134025],
              [-38.508473912930405, -8.169825216224147],
              [-38.50844005842152, -8.169894189933309],
              [-38.508399476421125, -8.169822774050887],
              [-38.508332202871593, -8.169753225226053],
              [-38.508285790078816, -8.169698897666226],
              [-38.508230280284941, -8.169660116780594],
              [-38.508172776006425, -8.169619162617247],
              [-38.508114090496562, -8.169579111529876],
              [-38.508055857567946, -8.169540146408073],
              [-38.507980809034237, -8.169518618057882],
              [-38.507903289516086, -8.169513368038221],
              [-38.5078210574368, -8.169501599732815],
              [-38.507738702332603, -8.169516152774559],
              [-38.5076503461218, -8.169538567816222],
              [-38.507560994541038, -8.169558358520712],
              [-38.507476720515349, -8.169583130241016],
              [-38.507397876957555, -8.169621204987511],
              [-38.507320213521446, -8.169659281154541],
              [-38.507250533248353, -8.169701799171253],
              [-38.507190561729296, -8.169748127979682],
              [-38.507143100375558, -8.169808672967726],
              [-38.507106791486272, -8.169880176216761],
              [-38.507102140745118, -8.169971255640746],
              [-38.507106381535579, -8.170066325779628],
              [-38.507141227658579, -8.170151212129475],
              [-38.507188345936832, -8.170222364730723],
              [-38.507236741806132, -8.170288091767588],
              [-38.507275145695203, -8.170358871939923],
              [-38.507331270861712, -8.170414025503488],
              [-38.507393579390403, -8.170460774558679],
              [-38.507457603435533, -8.17051512365521],
              [-38.507514278651406, -8.170565936172711],
              [-38.507572403809952, -8.170618830841949],
              [-38.507624533185222, -8.170675245832616],
              [-38.507668943398237, -8.170734003174854],
              [-38.507712165059878, -8.170799633413388],
              [-38.50775827728463, -8.170877025915416],
              [-38.507777455137074, -8.170932134471929],
              [-38.50777737899012, -8.170994274854978],
              [-38.507693324266469, -8.17091394172774],
              [-38.507654418571001, -8.170882145760226],
              [-38.507587827987585, -8.170847873895339],
              [-38.507512606999519, -8.170818747275176],
              [-38.507428944375526, -8.170788886749518],
              [-38.507340368998541, -8.170767703610245],
              [-38.507263431215719, -8.170732062316668],
              [-38.507182131683223, -8.170699943321623],
              [-38.507107466756231, -8.170661591210436],
              [-38.507036166805605, -8.170618268330294],
              [-38.506965673787242, -8.170583177546378],
              [-38.506878352304184, -8.170575925433315],
              [-38.506794533592981, -8.170599340771595],
              [-38.506730294896535, -8.170645935653139],
              [-38.506687186132979, -8.170710284861645],
              [-38.50667483564434, -8.1707877870957],
              [-38.50668046424596, -8.170861512269816],
              [-38.506689556595276, -8.170923754273609],
              [-38.506671492291467, -8.170996907847885],
              [-38.506641616137564, -8.171078549497656],
              [-38.506607659980766, -8.171156025380679],
              [-38.50658367228025, -8.171247623915372],
              [-38.506559591676307, -8.171340940920619],
              [-38.50653543794887, -8.171419875980197],
              [-38.506506572536985, -8.171491569137517],
              [-38.506473702231027, -8.171571850337884],
              [-38.506443738320087, -8.171650959205417],
              [-38.50641259636668, -8.171728348046175],
              [-38.506390884865404, -8.171814431772978],
              [-38.506376459224761, -8.171881439035264],
              [-38.506361951692234, -8.171941210044038],
              [-38.506342629802312, -8.172003417378614],
              [-38.506330565987255, -8.172069161193082],
              [-38.506323314965364, -8.172133735005239],
              [-38.506268062161617, -8.172107346071025],
              [-38.506234494050489, -8.172090571498892],
              [-38.506186541933566, -8.17203307600985],
              [-38.506149213559887, -8.171973603542664],
              [-38.506099152613167, -8.171933110427959],
              [-38.506051623176127, -8.171901032425424],
              [-38.505984422931753, -8.171845774710803],
              [-38.505946734377112, -8.171783950037916],
              [-38.505874786892861, -8.171750394965716],
              [-38.505925214044098, -8.171714547147975],
              [-38.505949576275718, -8.171687622232522],
              [-38.505985485824922, -8.171645877303764],
              [-38.506012673690087, -8.17160963928823],
              [-38.506038444999035, -8.171544183575946],
              [-38.506065294870481, -8.171487503012855],
              [-38.506072249438503, -8.171442556906603],
              [-38.506077149542982, -8.171370382268298],
              [-38.506081200035162, -8.171324709000823],
              [-38.506101518726695, -8.171263950124459],
              [-38.506121193387806, -8.171210155257215],
              [-38.506162437952469, -8.171111613022484],
              [-38.506174303627517, -8.171059346302348],
              [-38.50617764176372, -8.171002365674358],
              [-38.506169899183, -8.170949894117371],
              [-38.506162339387032, -8.170896427812329],
              [-38.506145700486705, -8.170843854944636],
              [-38.506087253194742, -8.170757763836443],
              [-38.506036301172848, -8.170703792298758],
              [-38.505996478717044, -8.170679412117494],
              [-38.505948562727241, -8.170666509442345],
              [-38.505901369308489, -8.170656592557004],
              [-38.505821984862024, -8.170617510837664],
              [-38.505758497593391, -8.170569765139238],
              [-38.505722497606072, -8.170537520285984],
              [-38.505762667933922, -8.170500484073912],
              [-38.505788065843454, -8.170443349491491],
              [-38.505817900718505, -8.170395355968399],
              [-38.505860167748914, -8.170351176605077],
              [-38.505907331428624, -8.170311435359505],
              [-38.50595141559387, -8.170265991877526],
              [-38.505978795821711, -8.170220889804309],
              [-38.505964701472152, -8.170166149191198],
              [-38.505943975419427, -8.170115289912788],
              [-38.505969199948034, -8.17005146166194],
              [-38.505982417516158, -8.170007156362955],
              [-38.505975337348744, -8.16993279624867],
              [-38.505957965148909, -8.169886011401443],
              [-38.505949036578187, -8.169838332343664],
              [-38.505955092999827, -8.169785515821998],
              [-38.505968779807162, -8.169728728731583],
              [-38.505994813757816, -8.169671052194444],
              [-38.505995614069597, -8.169610812197993],
              [-38.5059925050723, -8.169555270928416],
              [-38.506027321276399, -8.169516600010517],
              [-38.506064133402376, -8.169478926496412],
              [-38.50609079788596, -8.169425230604537],
              [-38.50609450012567, -8.169367436349697],
              [-38.506082040482923, -8.169312155022874],
              [-38.506035501802131, -8.169286681231419],
              [-38.505975515292796, -8.169271321632911],
              [-38.505920616226881, -8.169252892871967],
              [-38.505864183147146, -8.169226954722101],
              [-38.50584617174674, -8.169183244455562],
              [-38.505884811238843, -8.169135985284937],
              [-38.505923821069928, -8.169082847187708],
              [-38.505962742335427, -8.16902789993958],
              [-38.505989870816727, -8.16896597349435],
              [-38.506035498179124, -8.168920441422872],
              [-38.506084010126777, -8.168891465566769],
              [-38.506139048740728, -8.168870186081998],
              [-38.506183015586686, -8.168846179522236],
              [-38.506216930360473, -8.168802261270859],
              [-38.506241319173661, -8.168753537442482],
              [-38.506217707324083, -8.168687116925872],
              [-38.506187342119119, -8.168649723234221],
              [-38.506139824720663, -8.168608147822297],
              [-38.506092021762655, -8.168577335835197],
              [-38.506034055854094, -8.168542983814062],
              [-38.50598052947236, -8.168515692453749],
              [-38.505928018688614, -8.168474563183365],
              [-38.505877772728788, -8.168437235652339],
              [-38.505828613127477, -8.168402351643604],
              [-38.50577437256004, -8.168365290578972],
              [-38.50573120619525, -8.168328966647231],
              [-38.505695779228105, -8.168273838165398],
              [-38.505624600902266, -8.16820591229304],
              [-38.505577711280246, -8.16817066922153],
              [-38.505520017814618, -8.168136317464903],
              [-38.505469031786639, -8.168110295471967],
              [-38.505405853059742, -8.168107233359148],
              [-38.505351431602818, -8.168143438068334],
              [-38.505314965289578, -8.16819522244392],
              [-38.505271240628716, -8.168243741674587],
              [-38.50521935984947, -8.16828085398865],
              [-38.505162667420855, -8.16831823176833],
              [-38.505125382270393, -8.168371462353347],
              [-38.505072951771723, -8.168412644310269],
              [-38.505019513775167, -8.168461061179864],
              [-38.504956563097139, -8.168493908678563],
              [-38.50489605018987, -8.168537522925471],
              [-38.504848512594663, -8.16858612789189],
              [-38.504803056337458, -8.168640072060724],
              [-38.504751868142243, -8.168704411177997],
              [-38.504717374206415, -8.168776187774156],
              [-38.504698956028754, -8.168841652408178],
              [-38.50467048172861, -8.168890552035629],
              [-38.504574745955267, -8.168934394471709],
              [-38.504538416872087, -8.168948370040715],
              [-38.504480757060328, -8.168960329547541],
              [-38.504423112084048, -8.16896025896208],
              [-38.504461579747385, -8.168905220861987],
              [-38.504495259625209, -8.16883109154543],
              [-38.504490562763841, -8.16873828214929],
              [-38.504484923751981, -8.168673330783481],
              [-38.504509412240012, -8.168617551945003],
              [-38.50454851586985, -8.168562062352226],
              [-38.504589149913038, -8.168516976596591],
              [-38.504630965974748, -8.168470354600624],
              [-38.504654188647123, -8.168410413414328],
              [-38.504647723340696, -8.168352968544808],
              [-38.504629454936932, -8.168296956459296],
              [-38.504606272856314, -8.168250345356489],
              [-38.504582998551733, -8.168204910014374],
              [-38.504546459877659, -8.168168051364358],
              [-38.504482666003028, -8.168148526108741],
              [-38.50440923112189, -8.168143913601506],
              [-38.50433335457447, -8.168131700131523],
              [-38.504264552903365, -8.168124289263641],
              [-38.504201935388153, -8.168107840770132],
              [-38.504142021612424, -8.168107315113122],
              [-38.504061603439212, -8.168097266874398],
              [-38.50400642135444, -8.168087701805941],
              [-38.504042411370889, -8.168054188256189],
              [-38.504100065032389, -8.168047113199234],
              [-38.504136741089013, -8.168046163163007],
              [-38.504207294111097, -8.168032048645911],
              [-38.504269244522924, -8.168000556815691],
              [-38.504315406251258, -8.167963437621939],
              [-38.504358310182234, -8.167917992858367],
              [-38.504402659654779, -8.167878157877318],
              [-38.504463803500528, -8.167838162548639],
              [-38.504519582214478, -8.167805577697987],
              [-38.504574635131945, -8.167772630143233],
              [-38.504587327935134, -8.167712223808556],
              [-38.50456824623096, -8.167653225812501],
              [-38.504530549557813, -8.167598456269207],
              [-38.504448837155152, -8.167533773580065],
              [-38.504423732598212, -8.167500275640842],
              [-38.504465767810906, -8.167496709028066],
              [-38.504554577820585, -8.167474114315301],
              [-38.504634202513664, -8.167464985675061],
              [-38.504711899771777, -8.167472950070543],
              [-38.504789421383137, -8.167476120285464],
              [-38.504864063250608, -8.167458844831922],
              [-38.504917755520573, -8.167424991041912],
              [-38.50498241200242, -8.167407431996562],
              [-38.50505549991064, -8.167398837989429],
              [-38.505122151631085, -8.167382819043301],
              [-38.505191351399581, -8.167361737893694],
              [-38.50525818444526, -8.167345900049884],
              [-38.505314600600109, -8.16731159727401],
              [-38.505354764165325, -8.167279807282656],
              [-38.50539394117709, -8.16723824727463],
              [-38.505421235580009, -8.167188984331046],
              [-38.505414328855935, -8.167121317859294],
              [-38.505392167575657, -8.167056617661551],
              [-38.505356558532384, -8.167002031644893],
              [-38.505314966320491, -8.166940744872052],
              [-38.505281181897089, -8.166878834474312],
              [-38.505233130033723, -8.166829208096539],
              [-38.505161458154966, -8.166793753791309],
              [-38.505079208807508, -8.166796547701201],
              [-38.504991144589873, -8.16680340481976],
              [-38.504906078863428, -8.166808004288482],
              [-38.50483410325625, -8.166798056994191],
              [-38.504756765115872, -8.166793439789366],
              [-38.504668713829581, -8.16678980443208],
              [-38.504598128573953, -8.166756612657656],
              [-38.50452816462132, -8.16673490902406],
              [-38.504444291639949, -8.16672928879095],
              [-38.504377463470824, -8.166741237079005],
              [-38.504355378990155, -8.16668802432009],
              [-38.504384777564788, -8.166625648500514],
              [-38.504435850549839, -8.166580937333492],
              [-38.504507691828913, -8.166552532923157],
              [-38.504577075600096, -8.166529371702529],
              [-38.504649997401415, -8.166508114288936],
              [-38.504721558737849, -8.166485860226208],
              [-38.504779792393521, -8.166449931608213],
              [-38.504828227487778, -8.166409468400282],
              [-38.504867117080686, -8.166380119096193],
              [-38.504893596811222, -8.166328955699923],
              [-38.504911466579095, -8.166266384830843],
              [-38.504903104143281, -8.166201430130393],
              [-38.504864313854824, -8.166150186906682],
              [-38.504805897848406, -8.166113120624324],
              [-38.504741751306014, -8.1660853638706],
              [-38.504669976786801, -8.166059678168113],
              [-38.504604354835955, -8.166050552681805],
              [-38.504546200056247, -8.166022531874672],
              [-38.504533013025387, -8.165968425494178],
              [-38.504505950110101, -8.165903538353666],
              [-38.504456704839548, -8.165864855114149],
              [-38.504397191873203, -8.16583402861585],
              [-38.504352379724089, -8.165807561798943],
              [-38.504350732447378, -8.165744695706389],
              [-38.504365432148916, -8.165676332059954],
              [-38.504347900487353, -8.165611908838256],
              [-38.504338892214847, -8.165555455819014],
              [-38.504342322969521, -8.16549711853806],
              [-38.504348642691298, -8.165451719416602],
              [-38.50438554813703, -8.165411784851601],
              [-38.504431585198624, -8.165401891494289],
              [-38.504482426838287, -8.165397431126566],
              [-38.504532798305711, -8.165406176156127],
              [-38.504592679019254, -8.165433204101666],
              [-38.504656203262137, -8.165450196334154],
              [-38.504723732473288, -8.165458510072616],
              [-38.504773638472052, -8.165476661502675],
              [-38.504799455243557, -8.165521466778296],
              [-38.504837616813468, -8.165567191670002],
              [-38.504880953114387, -8.165612651531205],
              [-38.504933648548935, -8.165650886679504],
              [-38.504999986539126, -8.165668244109124],
              [-38.505071231377798, -8.165681718094312],
              [-38.505137382366037, -8.165703507417369],
              [-38.505202803902826, -8.165727918944226],
              [-38.505276507135655, -8.16573542607208],
              [-38.5053583033745, -8.165731817488245],
              [-38.505423769895586, -8.165719686458912],
              [-38.505486701062203, -8.165702306110393],
              [-38.505548560014105, -8.165670994845966],
              [-38.505598548412344, -8.165622211854245],
              [-38.505633942113185, -8.165556586976495],
              [-38.505646929476534, -8.165477909659243],
              [-38.50563985952423, -8.165395318418003],
              [-38.505626143706849, -8.16532818634102],
              [-38.505640293051847, -8.165264254105665],
              [-38.505700156572743, -8.16523149314548],
              [-38.505774157866966, -8.165218015694499],
              [-38.505848040468436, -8.165227241519245],
              [-38.50592527699299, -8.165240451308463],
              [-38.506010342387775, -8.165235851604718],
              [-38.506080999869326, -8.165210159006362],
              [-38.506146669516895, -8.165180389974205],
              [-38.506200831600175, -8.165133240124741],
              [-38.506243200669417, -8.165079382442629],
              [-38.506271521925989, -8.165006874526476],
              [-38.506292499880558, -8.164926397882962],
              [-38.506298505024617, -8.164841289932312],
              [-38.506311490228128, -8.164764240714351],
              [-38.506315029679982, -8.164691069411901],
              [-38.506331787361958, -8.164646768393611],
              [-38.506323685940473, -8.164590859218018],
              [-38.506281067133571, -8.164552455631966],
              [-38.506236716655096, -8.164519657950317],
              [-38.506186923584778, -8.164483506905054],
              [-38.506124589756787, -8.164458194790805],
              [-38.506033175367072, -8.1644584450903],
              [-38.505951612632742, -8.164419993961506],
              [-38.505924489208695, -8.164404222230203],
              [-38.505863713432632, -8.164366881871278],
              [-38.505809094036117, -8.16434302635556],
              [-38.505796300276934, -8.164338126336673],
              [-38.505765098172674, -8.164317193857119],
              [-38.505721217893502, -8.164271190714375],
              [-38.50565309177334, -8.164231670507199],
              [-38.505572911941343, -8.164249843903351],
              [-38.505513382415252, -8.164306394119603],
              [-38.505503448956532, -8.164337768808297],
              [-38.505494791067747, -8.164365346073428],
              [-38.505474641792766, -8.164436235777858],
              [-38.505419753055904, -8.164483746452385],
              [-38.505356161923821, -8.164521206324038],
              [-38.505280814656167, -8.164522018791665],
              [-38.505234782498619, -8.164528022826374],
              [-38.505178319719342, -8.164526777955228],
              [-38.505102751339102, -8.16448598223476],
              [-38.505035560097838, -8.16442448325096],
              [-38.504959341154901, -8.164395988171606],
              [-38.504868300603114, -8.164387555281536],
              [-38.504800357896016, -8.164346587928392],
              [-38.504783031133748, -8.164336888382788],
              [-38.504733228309, -8.164308787376255],
              [-38.504680086303622, -8.164264762741761],
              [-38.504618776481188, -8.164218919070075],
              [-38.504552375167471, -8.164179491246481],
              [-38.504473154490398, -8.164155967262634],
              [-38.504430526326708, -8.16412534234739],
              [-38.504361057763411, -8.164070353010946],
              [-38.504297463493039, -8.164036717506477],
              [-38.504234879730674, -8.16399340487494],
              [-38.504166739537972, -8.163965462256064],
              [-38.504088957882715, -8.163952884644065],
              [-38.504021128933118, -8.16396700252162],
              [-38.503973412822283, -8.164013526809093],
              [-38.503947180930169, -8.164084589876804],
              [-38.503936924575157, -8.164156848388114],
              [-38.503925114267723, -8.164237788378239],
              [-38.503910131397319, -8.164314925500381],
              [-38.503903388008986, -8.164335811629524],
              [-38.503886256362328, -8.164388705006106],
              [-38.503860387428865, -8.164459858961198],
              [-38.503839251693279, -8.164520707196409],
              [-38.503802878561658, -8.164570682528806],
              [-38.503760353360136, -8.164530198412455],
              [-38.503753935108939, -8.164508301185498],
              [-38.503722809261767, -8.164425680448273],
              [-38.50369613179322, -8.16434288425752],
              [-38.503693054371467, -8.164335553881317],
              [-38.503667891335674, -8.164276096136891],
              [-38.503642089619376, -8.164219260713484],
              [-38.503594393116956, -8.164176328041759],
              [-38.503524705802803, -8.164151549235962],
              [-38.503466470538818, -8.164115477973544],
              [-38.503418287328735, -8.164099137545303],
              [-38.503321447361849, -8.164082375626144],
              [-38.503227236847508, -8.164068149563304],
              [-38.503144343565836, -8.164078268926202],
              [-38.503092230856652, -8.164082998931073],
              [-38.503046836193242, -8.164087375366272],
              [-38.503000509927766, -8.164111016907018],
              [-38.50294463067889, -8.164151832576156],
              [-38.502908736197973, -8.164181728091346],
              [-38.502861946290942, -8.164213238360698],
              [-38.502868727647368, -8.164161689106066],
              [-38.502871276979519, -8.164082185019769],
              [-38.50285236582009, -8.164032413260031],
              [-38.502885645390215, -8.163988223139341],
              [-38.502943402560824, -8.163970384563969],
              [-38.503010134594369, -8.163962416251985],
              [-38.50307931931814, -8.163953094160206],
              [-38.503145154071909, -8.163936622245991],
              [-38.503192773448887, -8.163894982345061],
              [-38.503226323629484, -8.163852149303736],
              [-38.503287974848433, -8.163841913413895],
              [-38.503324233390238, -8.163811204240549],
              [-38.50335203200153, -8.163794685636867],
              [-38.503385379483554, -8.163768947741879],
              [-38.503420544873485, -8.163741312583159],
              [-38.50345391524796, -8.163697032069512],
              [-38.503474410961907, -8.163639891596226],
              [-38.503480000826627, -8.163597476506276],
              [-38.503462304132754, -8.163519847072786],
              [-38.503417063657288, -8.163472937512319],
              [-38.503368364681542, -8.163433259858333],
              [-38.503301865921692, -8.163399439741465],
              [-38.503235896755569, -8.163377831270875],
              [-38.503155758819744, -8.163362175232551],
              [-38.50307018358955, -8.163338914547037],
              [-38.502984975731955, -8.163312217122682],
              [-38.502919627062489, -8.163302729918463],
              [-38.50282494037657, -8.163306684020121],
              [-38.502788169840272, -8.163310980567186],
              [-38.502739959573219, -8.163316619842691],
              [-38.502664948704073, -8.163339231156611],
              [-38.502595740582272, -8.163367548052774],
              [-38.50254195713638, -8.163402034613123],
              [-38.50249578047211, -8.163451545525135],
              [-38.502407927642608, -8.163434884890965],
              [-38.502345839618457, -8.163358014940881],
              [-38.502286790936552, -8.163319228964086],
              [-38.502228750680722, -8.163272484452301],
              [-38.502169507587418, -8.16324410018802],
              [-38.502111626735314, -8.163215265330823],
              [-38.502061838701785, -8.163175495737827],
              [-38.5020238528448, -8.163134926584723],
              [-38.501978327593022, -8.163098237588184],
              [-38.501928897881932, -8.163062357852031],
              [-38.501883461072204, -8.163027568444116],
              [-38.501827107475279, -8.163011670047206],
              [-38.501758007063884, -8.163026328698294],
              [-38.501711026802859, -8.163064984303734],
              [-38.501659771889059, -8.163110147178715],
              [-38.501611064581979, -8.163150609683781],
              [-38.501556119587754, -8.163170079668362],
              [-38.501506836252396, -8.163162601953548],
              [-38.501460818352633, -8.163157208645984],
              [-38.501396651893771, -8.163146094520908],
              [-38.501352060172103, -8.163088693126205],
              [-38.501334885807296, -8.163029425998019],
              [-38.501295531077005, -8.162994734468102],
              [-38.501222197072138, -8.162982794961129],
              [-38.501160236473922, -8.163022969731946],
              [-38.501130975062466, -8.163047807954467],
              [-38.501111342749148, -8.163067321375818],
              [-38.50108842625967, -8.16309985582045],
              [-38.501083219267649, -8.16305299533375],
              [-38.501091161408183, -8.163017186174768],
              [-38.50111464287432, -8.162968280635791],
              [-38.501148284746179, -8.162924814723059],
              [-38.501195631518726, -8.162883446079825],
              [-38.501257935457367, -8.162859191255498],
              [-38.50132431138897, -8.162845433853146],
              [-38.501381510880556, -8.162837996773643],
              [-38.501441685746876, -8.162846935139404],
              [-38.501501963954169, -8.162845743012655],
              [-38.501553892261192, -8.162843183822149],
              [-38.501603309838444, -8.16281556636315],
              [-38.501634599712823, -8.16276540407188],
              [-38.501669065129114, -8.162716602463183],
              [-38.501729180816888, -8.16270003328075],
              [-38.501740169330859, -8.162696519176174],
              [-38.501783306158529, -8.162682642653065],
              [-38.501838863997392, -8.162681354215936],
              [-38.501860923844561, -8.162680748186206],
              [-38.501920213891061, -8.162671142754569],
              [-38.501976050000771, -8.162665060690548],
              [-38.50203153621527, -8.162648304865396],
              [-38.502090105406381, -8.162634356831228],
              [-38.502171440392956, -8.16263617540184],
              [-38.502242495629432, -8.162655619441546],
              [-38.502293843305743, -8.162682094498827],
              [-38.502339309447351, -8.162693185500761],
              [-38.502424522833607, -8.162715270011644],
              [-38.502506990282178, -8.162755622415272],
              [-38.50254718179972, -8.162774395326551],
              [-38.502592943904041, -8.162766401314027],
              [-38.502649356241186, -8.162734812429701],
              [-38.50269532797499, -8.162704115235915],
              [-38.502733058810918, -8.162657307480062],
              [-38.50277377547016, -8.162618463153095],
              [-38.502789045529546, -8.162602833721079],
              [-38.502811041661822, -8.162580338200865],
              [-38.502845783507865, -8.162528099649325],
              [-38.50286111506702, -8.162462721743211],
              [-38.50287276081842, -8.162441570292529],
              [-38.50294682482901, -8.162376988083103],
              [-38.502970274617319, -8.162353499365798],
              [-38.503013786111268, -8.162330216168364],
              [-38.503099468316712, -8.162266733607058],
              [-38.503156612916236, -8.162230170688277],
              [-38.503223723636196, -8.162209358616735],
              [-38.50328100396333, -8.162209881128488],
              [-38.503333017717637, -8.162211573048815],
              [-38.503385213922357, -8.162212541570876],
              [-38.503455744370193, -8.16221588431346],
              [-38.503523100941791, -8.162216509595893],
              [-38.503588990550959, -8.162228891817925],
              [-38.503657881084941, -8.162237026467034],
              [-38.503734132563139, -8.162238748037939],
              [-38.503809588693969, -8.162222920948327],
              [-38.503879970516529, -8.162199580119665],
              [-38.503938572844952, -8.162158677179221],
              [-38.503975042303885, -8.162103998433023],
              [-38.504010598729863, -8.162053569805016],
              [-38.504068468107555, -8.162018093061109],
              [-38.504113911944195, -8.161973646355632],
              [-38.504141587505508, -8.161909549825271],
              [-38.504165993269375, -8.161846896520409],
              [-38.504200188091247, -8.161796375753621],
              [-38.504227852028059, -8.161741686201118],
              [-38.504229295544704, -8.16167529625573],
              [-38.504218375322374, -8.161623453936947],
              [-38.504176317195707, -8.161572478022803],
              [-38.504115840219214, -8.161514152950458],
              [-38.504064769014008, -8.161484331741788],
              [-38.503998639413318, -8.161445808703599],
              [-38.503924785458096, -8.161413969637708],
              [-38.503888669626996, -8.161402799819207],
              [-38.503803198925212, -8.161368685206048],
              [-38.503734963846661, -8.161344450951297],
              [-38.503661450941038, -8.161330521737113],
              [-38.503591137257374, -8.161298777402859],
              [-38.503522355417338, -8.161276261029988],
              [-38.503455122391003, -8.161249223948618],
              [-38.503371254375622, -8.161240528200793],
              [-38.503307923580159, -8.161214490851187],
              [-38.503239979218279, -8.161175422758838],
              [-38.503168945299343, -8.161138883519946],
              [-38.503093348704567, -8.161121604929505],
              [-38.503009110687024, -8.161118516673216],
              [-38.502915001042496, -8.161170139690837],
              [-38.502868241689235, -8.161177228008466],
              [-38.502790822728123, -8.161165464691774],
              [-38.502788644398656, -8.161165190662238],
              [-38.502706881087398, -8.161142929576139],
              [-38.502620112819713, -8.161130521586301],
              [-38.502544259362814, -8.161100760216661],
              [-38.502465596509495, -8.161067196402838],
              [-38.502393192293248, -8.161037710598384],
              [-38.50235320623549, -8.160999762129931],
              [-38.502353466024488, -8.160936536569189],
              [-38.502320127566676, -8.16088177221074],
              [-38.502264625608866, -8.160838106202164],
              [-38.502197758212731, -8.160809169880098],
              [-38.50212998640977, -8.160777518879032],
              [-38.502055036132319, -8.160751557513107],
              [-38.501998531861119, -8.160710965603096],
              [-38.501944026388585, -8.160669019364779],
              [-38.501892866367257, -8.160637841009086],
              [-38.501837448322959, -8.160599783066992],
              [-38.50179875386339, -8.160545192987922],
              [-38.501801106548022, -8.16047799012159],
              [-38.501802998061081, -8.160416666061163],
              [-38.501768845075134, -8.160360001171377],
              [-38.501735420671537, -8.160301437685353],
              [-38.501701453629494, -8.160241245395397],
              [-38.501643868653289, -8.160193777767798],
              [-38.501575996583441, -8.160169905421416],
              [-38.501532735862789, -8.160137470416766],
              [-38.50152602440459, -8.160059311721289],
              [-38.50152630180078, -8.159981975691675],
              [-38.501542093501278, -8.159911623546115],
              [-38.50151285445336, -8.159845376782755],
              [-38.501469076348684, -8.159790961325905],
              [-38.501427757877302, -8.159729584095647],
              [-38.501376078803091, -8.159677872463766],
              [-38.501302647215198, -8.159671902709873],
              [-38.5012394219914, -8.159707462926734],
              [-38.50117347556715, -8.159741301196656],
              [-38.501104982766286, -8.159778844846967],
              [-38.501121037101569, -8.159716362359015],
              [-38.501136658073086, -8.159637326639995],
              [-38.50115607732706, -8.159569873438672],
              [-38.501162246676166, -8.159499509461831],
              [-38.501161420338079, -8.1594338403698],
              [-38.50113298177817, -8.159380981449042],
              [-38.501093837902104, -8.159322953586583],
              [-38.501042150059206, -8.159278387605381],
              [-38.500986002697829, -8.159243042218668],
              [-38.500925131226914, -8.15921067591818],
              [-38.500885315258223, -8.159182224978414],
              [-38.500871967318275, -8.159112017867976],
              [-38.500857890246643, -8.159044161608017],
              [-38.500828468243071, -8.158979180903998],
              [-38.500803392440766, -8.158923160289982],
              [-38.500786042469059, -8.158859279878955],
              [-38.500775132516125, -8.158799568188996],
              [-38.500765751184886, -8.158751617127969],
              [-38.500776713920992, -8.158695640914077],
              [-38.500768069359282, -8.15863918828291],
              [-38.500743154371619, -8.158599901464134],
              [-38.500736875295999, -8.158539200508104],
              [-38.50073907605956, -8.158521384197032],
              [-38.500745954496473, -8.158464860239425],
              [-38.500756990155061, -8.158423265965709],
              [-38.500762462482648, -8.158402559224569],
              [-38.500808448198121, -8.15836028439251],
              [-38.500881554468286, -8.158335590628782],
              [-38.500948030977028, -8.158313330887353],
              [-38.501014875635491, -8.158287001252658],
              [-38.501075372175464, -8.158255598483917],
              [-38.501152368590475, -8.158241582782848],
              [-38.501225558197213, -8.158222858882333],
              [-38.501307719810853, -8.158216176112772],
              [-38.501386231060508, -8.158225046561732],
              [-38.501438916130333, -8.158270608693513],
              [-38.501486595361271, -8.158326747533273],
              [-38.501533456715265, -8.158383608977177],
              [-38.501572860690537, -8.158451496375474],
              [-38.501624086430418, -8.158502664708672],
              [-38.501689315273005, -8.158534945809048],
              [-38.501741372596037, -8.158574356403662],
              [-38.501782445114145, -8.158614386649081],
              [-38.50184881432989, -8.158678779554222],
              [-38.50188658574141, -8.158745579495955],
              [-38.501921462262835, -8.158804325656435],
              [-38.501936172822674, -8.158873901255268],
              [-38.501930110416104, -8.158931330763489],
              [-38.501944399794844, -8.158974493887618],
              [-38.501999372035613, -8.159005857816513],
              [-38.502075783718333, -8.15902431346319],
              [-38.502151845956774, -8.159031733533578],
              [-38.502234894910934, -8.159041694826723],
              [-38.502319679827799, -8.159042613044281],
              [-38.502392186101048, -8.159062691969888],
              [-38.502471959594985, -8.159078709470526],
              [-38.502551756137585, -8.159076093889974],
              [-38.502632921495881, -8.159067691051346],
              [-38.502702107164602, -8.159056921743252],
              [-38.502759857918278, -8.159043696188407],
              [-38.502793441020735, -8.159047355477414],
              [-38.502850804628402, -8.159053576596579],
              [-38.502914797658832, -8.15905763499488],
              [-38.502981530659603, -8.159048219408332],
              [-38.503048073276041, -8.159045949278754],
              [-38.503136034738823, -8.159047413933171],
              [-38.503215915577329, -8.159050044536022],
              [-38.503290014260841, -8.159030326409745],
              [-38.503358671440886, -8.159006440811147],
              [-38.503411462474276, -8.158966435312486],
              [-38.503457182364777, -8.158918732726987],
              [-38.503491928697194, -8.158862514207858],
              [-38.503518796604943, -8.158790728300058],
              [-38.503531055295021, -8.158714130537465],
              [-38.503521707455874, -8.158638772627642],
              [-38.503518984340594, -8.158565050969354],
              [-38.503527428819659, -8.15848971485924],
              [-38.503518446882893, -8.158412096098946],
              [-38.50348731031832, -8.158338520529284],
              [-38.503459361120463, -8.158256717737839],
              [-38.503434133904655, -8.15817600370355],
              [-38.503400374104551, -8.158094917403686],
              [-38.503364256013242, -8.158012290528417],
              [-38.50332323184734, -8.157933004358739],
              [-38.503292915917214, -8.157856535320754],
              [-38.503274860357948, -8.157775649164893],
              [-38.503289741644089, -8.157707104843126],
              [-38.503291379656012, -8.157630313162647],
              [-38.503302807725476, -8.157564749520526],
              [-38.503334265438006, -8.157525170130135],
              [-38.50340337032123, -8.157506079007968],
              [-38.503482532680756, -8.157502196121031],
              [-38.503550581643751, -8.157529595973324],
              [-38.503608734260169, -8.157558340471812],
              [-38.50368437614577, -8.157611980664429],
              [-38.503726880344395, -8.15766892698552],
              [-38.503756472251055, -8.157743676522358],
              [-38.503776787625966, -8.15783243474813],
              [-38.503797202263925, -8.157914318747387],
              [-38.503820164019849, -8.157991864171793],
              [-38.503879746178008, -8.158039243484971],
              [-38.50395333812375, -8.158062217917244],
              [-38.504023899412786, -8.158039962658478],
              [-38.504088596423308, -8.157988303330363],
              [-38.504157735582147, -8.157941443378984],
              [-38.504232123406396, -8.157907886271479],
              [-38.504317001565788, -8.157906633324778],
              [-38.504403768772704, -8.157919402721776],
              [-38.504498641651878, -8.157910654449129],
              [-38.504592580362136, -8.157923342123087],
              [-38.504686618765042, -8.157928793741748],
              [-38.504779565064396, -8.157936505302793],
              [-38.504866632235498, -8.157926842885093],
              [-38.504953423177213, -8.157920345929179],
              [-38.505035209697887, -8.157923340296936],
              [-38.505076204075159, -8.157953329962968],
              [-38.505106556407426, -8.158000492499436],
              [-38.505139986932967, -8.158054352237533],
              [-38.50519240132877, -8.158098918603272],
              [-38.505255630664138, -8.158133367548404],
              [-38.505315223367283, -8.158172334642492],
              [-38.505359909514013, -8.158226841268684],
              [-38.505422143339011, -8.158258846771641],
              [-38.505495766003172, -8.158256946680652],
              [-38.505527148466392, -8.158204613294092],
              [-38.505579663525218, -8.158167320714453],
              [-38.505661187779637, -8.158162173962715],
              [-38.505741510262354, -8.158174663860823],
              [-38.50583047346074, -8.158173415582754],
              [-38.50591235882505, -8.158169897356037],
              [-38.505988616121357, -8.15816628179947],
              [-38.506071965244729, -8.158153539224541],
              [-38.50615567558463, -8.15814233475578],
              [-38.506230853320368, -8.158130758064551],
              [-38.506295790781373, -8.158104877472098],
              [-38.506335236750807, -8.158065217115313],
              [-38.506345296586346, -8.158004626551934],
              [-38.506377400587581, -8.157956002500942],
              [-38.50641314487202, -8.157899875367393],
              [-38.506465114042506, -8.157863486520133],
              [-38.506530598216401, -8.157835887964685],
              [-38.506592825391365, -8.157799330687201],
              [-38.506653864579839, -8.157769194043238],
              [-38.50672507078221, -8.157739069765382],
              [-38.506795455990066, -8.15771220074625],
              [-38.506860749585357, -8.157691928512605],
              [-38.506927413918852, -8.157664240873777],
              [-38.506996249021839, -8.157642977954684],
              [-38.507068710099318, -8.157625880225106],
              [-38.507130713095009, -8.157624056147718],
              [-38.507199412980057, -8.157639064273113],
              [-38.507251117082937, -8.157670785338446],
              [-38.507285904551772, -8.157728716904648],
              [-38.507297629132275, -8.157791143027426],
              [-38.507324795445818, -8.157845718601425],
              [-38.507341800941148, -8.157895216510124],
              [-38.507358267537356, -8.157940010260029],
              [-38.50741099914282, -8.157948124589165],
              [-38.507416250067593, -8.157885538199945],
              [-38.507418046923185, -8.157826656143078],
              [-38.507447069681348, -8.157773686557974],
              [-38.507448502869337, -8.1577152563183],
              [-38.507417419515356, -8.1576718015846],
              [-38.507472074363015, -8.15759190846045],
              [-38.507509701198714, -8.157555049779296],
              [-38.507532903570834, -8.157510756502775],
              [-38.507509373529082, -8.157451934117239],
              [-38.507459041271566, -8.157411893169549],
              [-38.507401002952825, -8.157363702170745],
              [-38.507353958177454, -8.157307745657805],
              [-38.507291286499374, -8.157262624379639],
              [-38.507228530040557, -8.157212618582369],
              [-38.507173226495603, -8.157155114324674],
              [-38.507118554748033, -8.157100595744055],
              [-38.507056708764175, -8.157048420182166],
              [-38.507025219268598, -8.156966070576285],
              [-38.507010625351477, -8.156874696322422],
              [-38.506994023822685, -8.156791912564227],
              [-38.506979611137709, -8.156700809885667],
              [-38.506960103988987, -8.156618655755494],
              [-38.506938150863213, -8.15653242830968],
              [-38.506914748311161, -8.156443756895959],
              [-38.506907770583666, -8.156359808966808],
              [-38.50687763682923, -8.156282345407989],
              [-38.506861037229591, -8.156198023963595],
              [-38.506863681836613, -8.156114087740686],
              [-38.50688809376301, -8.156045735741071],
              [-38.506878020107131, -8.155969562914724],
              [-38.506818533515087, -8.155918204263871],
              [-38.506761392717188, -8.155878607217421],
              [-38.506683353364231, -8.155855266085913],
              [-38.506589589493345, -8.155848368059363],
              [-38.506510035287043, -8.155875949516151],
              [-38.506448716955504, -8.155911874744113],
              [-38.506397471707281, -8.155950344884481],
              [-38.506352022281746, -8.156000128573787],
              [-38.506313455000083, -8.156063669340119],
              [-38.506263375277854, -8.156113990089789],
              [-38.506215227490323, -8.156143418779926],
              [-38.506167001684148, -8.156162445391752],
              [-38.506149961564681, -8.15606735960503],
              [-38.50615667938672, -8.155993106718954],
              [-38.506124528294087, -8.15593228381824],
              [-38.50607928951144, -8.155884379599796],
              [-38.506045042362459, -8.155830247585584],
              [-38.506012720404328, -8.155760741079584],
              [-38.505995303459251, -8.155676870873203],
              [-38.505993949629648, -8.155596547870113],
              [-38.506012813640375, -8.15553687255645],
              [-38.506046909330003, -8.155492502186707],
              [-38.506035984105537, -8.155444639778212],
              [-38.505991279718508, -8.155405148237451],
              [-38.505939227223195, -8.15536175830127],
              [-38.505894166634263, -8.155316658294275],
              [-38.505864420950488, -8.155293014069811],
              [-38.505840388742719, -8.15527380895522],
              [-38.505789061825794, -8.155230962597743],
              [-38.505724485898014, -8.15518457245269],
              [-38.505662811274654, -8.155141080299217],
              [-38.505590239277396, -8.155101012021097],
              [-38.505507129235092, -8.155067443417874],
              [-38.505427650595337, -8.155033607872941],
              [-38.505404961173888, -8.155029781207437],
              [-38.505340795184161, -8.155019210484927],
              [-38.505305122411301, -8.155017267461934],
              [-38.505238043022167, -8.155013657969505],
              [-38.50513455731361, -8.155014074362487],
              [-38.505051276023288, -8.155045992682219],
              [-38.504976139228113, -8.155098543977509],
              [-38.504917427263855, -8.155155728368079],
              [-38.504863979312177, -8.15521373324647],
              [-38.504792221667181, -8.155249645470832],
              [-38.504707221006775, -8.155277129468633],
              [-38.504664810614045, -8.155291549960072],
              [-38.504567498305931, -8.155291431036014],
              [-38.504561236515471, -8.155289976151387],
              [-38.5045131643299, -8.155257987853078],
              [-38.504470562790715, -8.155206468614487],
              [-38.504438869462462, -8.155142751693345],
              [-38.504412081185563, -8.155076508112131],
              [-38.504370128367164, -8.155014225874746],
              [-38.504332978686364, -8.154958462047425],
              [-38.504308183840493, -8.154895205812354],
              [-38.504300837100523, -8.154816503627625],
              [-38.504288214866719, -8.154746297475478],
              [-38.504273046110868, -8.154679977643113],
              [-38.504198034864771, -8.154630589593605],
              [-38.504142880344865, -8.154600130277947],
              [-38.50408574090384, -8.154559809264182],
              [-38.504023689638665, -8.154527532457429],
              [-38.503954286959342, -8.15449407077125],
              [-38.503891671882833, -8.154477350991277],
              [-38.503880056318195, -8.15447426141011],
              [-38.503793545919336, -8.154474698242508],
              [-38.503791820836796, -8.154474967486898],
              [-38.503702301217402, -8.154486435754322],
              [-38.503625921612709, -8.154516191384211],
              [-38.503567679281083, -8.154560531973152],
              [-38.503535931437085, -8.154614764262412],
              [-38.503512169662848, -8.154670815368283],
              [-38.503484137714999, -8.154729846156304],
              [-38.503443692842701, -8.154769866828909],
              [-38.503397805514723, -8.154806534107953],
              [-38.503339586555271, -8.154831879780144],
              [-38.503283381076628, -8.154843931475295],
              [-38.503217110814532, -8.154846744730362],
              [-38.503162752567015, -8.154833110320995],
              [-38.503117122469547, -8.154808451466286],
              [-38.503085317619018, -8.154761739280493],
              [-38.503052514460229, -8.154714844964822],
              [-38.503021426340993, -8.154675822068102],
              [-38.5029734518372, -8.154638406601132],
              [-38.50291864857131, -8.154617625912396],
              [-38.502850738119051, -8.154625502406972],
              [-38.502798889359617, -8.15463801163833],
              [-38.502779638987569, -8.154642691528435],
              [-38.502725954919583, -8.154671208491765],
              [-38.502682594413642, -8.154719908893661],
              [-38.502642144996678, -8.154763547553413],
              [-38.502607215597529, -8.154821213031196],
              [-38.502594023862613, -8.154844804799545],
              [-38.502573098814857, -8.154882407127685],
              [-38.502549796027729, -8.154934207489969],
              [-38.502545699914783, -8.154943247656655],
              [-38.502537807292526, -8.155012705054579],
              [-38.50254889731702, -8.155073773717548],
              [-38.502572333597797, -8.155134314818953],
              [-38.502607125537224, -8.155187905054444],
              [-38.502644025014924, -8.155225578333166],
              [-38.502678025244094, -8.155258725455301],
              [-38.502706854578051, -8.155289152673204],
              [-38.502797915324336, -8.155426479958955],
              [-38.50283607073856, -8.155476275321664],
              [-38.502872700615981, -8.155511777404753],
              [-38.502918402334529, -8.155551994110493],
              [-38.502953041400133, -8.155582428437315],
              [-38.503017968643753, -8.155638135988214],
              [-38.503089963850449, -8.155704073263001],
              [-38.503151825167883, -8.155743224331362],
              [-38.503223976063495, -8.155756609199866],
              [-38.503291583227693, -8.155773968373019],
              [-38.503341362482878, -8.155820250311324],
              [-38.503378684381595, -8.155883521945722],
              [-38.503404920280815, -8.155955915640934],
              [-38.503397021301467, -8.156030619264364],
              [-38.503379227448633, -8.156105491667418],
              [-38.503378711949019, -8.156155420505323],
              [-38.503415045942802, -8.156210097992979],
              [-38.503454938314718, -8.156250126623219],
              [-38.503493281019189, -8.156295399563987],
              [-38.503506911397167, -8.156357737646543],
              [-38.503502832632336, -8.156426295219168],
              [-38.503485047035781, -8.156494474192264],
              [-38.503454413668997, -8.156528175236145],
              [-38.503399106008125, -8.15654809735088],
              [-38.503340623391232, -8.156566206528401],
              [-38.503273966402162, -8.15658792375622],
              [-38.503222371747867, -8.156614996099171],
              [-38.503163536952513, -8.156624240529057],
              [-38.503120217485552, -8.156639564251266],
              [-38.503070085965732, -8.156657954982148],
              [-38.503027031114094, -8.156679520201651],
              [-38.502987876081235, -8.156703984660451],
              [-38.502943164670306, -8.156743819128664],
              [-38.502876650746408, -8.15679665192663],
              [-38.502851298337887, -8.156817424771956],
              [-38.502797957075913, -8.156862133040347],
              [-38.502796141988249, -8.156861769005889],
              [-38.502738693057459, -8.156851387003501],
              [-38.502690338347833, -8.156827629267509],
              [-38.502637948773049, -8.156836791075104],
              [-38.50258198837868, -8.156870641868425],
              [-38.502524654694028, -8.156913897967678],
              [-38.502474124332792, -8.156961142294454],
              [-38.502413151164426, -8.157011177802477],
              [-38.502348734280844, -8.157056505571846],
              [-38.502295844020615, -8.157103475620199],
              [-38.502245576876092, -8.157158046843662],
              [-38.502186231813383, -8.157212787812952],
              [-38.502138961394444, -8.157266458183171],
              [-38.502086968105175, -8.157322112686545],
              [-38.502033243324817, -8.157383192169506],
              [-38.501971632249401, -8.157435126309336],
              [-38.501907839602161, -8.157489409507168],
              [-38.501840329565539, -8.157540070047622],
              [-38.501772996361737, -8.157594529773379],
              [-38.501708479912303, -8.15764682210443],
              [-38.501636096105464, -8.157674682733878],
              [-38.501566973646824, -8.157707884023569],
              [-38.501502123968216, -8.15773602525017],
              [-38.501445177477244, -8.157759653593699],
              [-38.501384464420461, -8.157746101561894],
              [-38.501334304583949, -8.157713929415378],
              [-38.501271248717813, -8.157686354439306],
              [-38.501223911279681, -8.157647492312746],
              [-38.501189284520599, -8.157607108144346],
              [-38.501161761578011, -8.157548009174064],
              [-38.501132962383977, -8.157493250322457],
              [-38.501096362445438, -8.157433597463054],
              [-38.50106556101818, -8.157382996929909],
              [-38.501008785650377, -8.157341861863815],
              [-38.50095236330484, -8.157308867896061],
              [-38.500893124756224, -8.157277589038744],
              [-38.500836401934357, -8.157267298112462],
              [-38.50078310035375, -8.157279805271076],
              [-38.500729082993743, -8.157283899514242],
              [-38.500670194666569, -8.157263023025084],
              [-38.500616577798738, -8.157236997265452],
              [-38.500551070189978, -8.157210142777993],
              [-38.500502446397192, -8.157183851801703],
              [-38.50045393023224, -8.157143993168537],
              [-38.500399039376241, -8.157120498464373],
              [-38.500344610497841, -8.157090491786358],
              [-38.500288157467381, -8.157082281511723],
              [-38.500227276713602, -8.157057693976828],
              [-38.500169028605526, -8.157033019228233],
              [-38.500106238184884, -8.157011142877453],
              [-38.500043090508754, -8.156984562578382],
              [-38.499979750048027, -8.156967027220908],
              [-38.499912877058087, -8.156943246314729],
              [-38.499852803560358, -8.15692652903631],
              [-38.49977564122117, -8.156928152390313],
              [-38.499712000113377, -8.156933410496281],
              [-38.499645156625277, -8.156958925860572],
              [-38.499590373834245, -8.156994586716207],
              [-38.499538492176562, -8.157033236058457],
              [-38.499482727249259, -8.15705568971072],
              [-38.499430381112717, -8.157102840941079],
              [-38.499378031362752, -8.157152886619585],
              [-38.499324875076148, -8.157194519269252],
              [-38.499283859791177, -8.157254709546958],
              [-38.499280221230322, -8.15733366954092],
              [-38.499308017331998, -8.157391864441077],
              [-38.499361347388401, -8.157429467856083],
              [-38.499412135816435, -8.157466977673158],
              [-38.49945348323827, -8.157504656727181],
              [-38.499477397013074, -8.157545932320625],
              [-38.499547310131291, -8.157606983231409],
              [-38.499609170340079, -8.157646948899433],
              [-38.499670576902034, -8.157686733094813],
              [-38.499728071410289, -8.157733477246978],
              [-38.499784735782654, -8.157790803237235],
              [-38.4998340496022, -8.157846220658399],
              [-38.499893718638802, -8.157896314192824],
              [-38.499951747634782, -8.157951290097156],
              [-38.500008412069697, -8.158008616059316],
              [-38.500049992931821, -8.15807768215017],
              [-38.500093111059861, -8.158151544083676],
              [-38.500145683160362, -8.158214834804784],
              [-38.500187541678642, -8.158279649987323],
              [-38.500227127750321, -8.158346904564278],
              [-38.500251549797667, -8.158417577607901],
              [-38.500250526530948, -8.158437475760799],
              [-38.500247828939415, -8.158489844075637],
              [-38.500248133395004, -8.158537060335354],
              [-38.50024837988299, -8.158557864572957],
              [-38.500228419247065, -8.158622603503728],
              [-38.500182516803093, -8.158670848191029],
              [-38.500115748423902, -8.158708755616095],
              [-38.500044635545436, -8.158736346154241],
              [-38.499971059196028, -8.158773973798006],
              [-38.499890142591894, -8.158801371305346],
              [-38.499809148471265, -8.158818095378615],
              [-38.499726797524552, -8.158830928329182],
              [-38.49963964776051, -8.158833805630517],
              [-38.499558301206875, -8.158841936289207],
              [-38.499474333818078, -8.158840656699018],
              [-38.499392185012638, -8.158836937137732],
              [-38.49932495169022, -8.158810713474633],
              [-38.499248829762855, -8.158778418540596],
              [-38.499175605171388, -8.15875218744948],
              [-38.499105206393018, -8.158716552844455],
              [-38.499022809507068, -8.158693385751613],
              [-38.498945008353431, -8.158697450337622],
              [-38.498887057751006, -8.158725328299608],
              [-38.498815047732599, -8.158744324580024],
              [-38.49874003947135, -8.158765397531015],
              [-38.498657866123601, -8.158781396297972],
              [-38.498582847364034, -8.158810881229096],
              [-38.498506205430715, -8.158831680757965],
              [-38.498440339917579, -8.158873387991228],
              [-38.498384902821776, -8.158923881953662],
              [-38.498340907016988, -8.158971676517572],
              [-38.498294452885865, -8.159025347402695],
              [-38.49825261066561, -8.159093224934287],
              [-38.498219576074852, -8.159159304331126],
              [-38.498198689934533, -8.159237971578321],
              [-38.498190590059437, -8.159327327978971],
              [-38.498198926503207, -8.159412182143214],
              [-38.498209985928902, -8.159497311035317],
              [-38.498216879983701, -8.159574113197525],
              [-38.49821768135466, -8.159659410287945],
              [-38.498216312531724, -8.159737920801129],
              [-38.498206953496094, -8.159817868647325],
              [-38.498188707415181, -8.159890478885169],
              [-38.498167114187673, -8.159953768434725],
              [-38.498133085015667, -8.160016590318129],
              [-38.498096817097476, -8.160054806521119],
              [-38.498058538185852, -8.160104145806319],
              [-38.498044856782791, -8.160156048216455],
              [-38.498044157337759, -8.160207333548044],
              [-38.498064168354219, -8.160248061673684],
              [-38.498109556426542, -8.160321383904009],
              [-38.49811957313058, -8.160369154889674],
              [-38.498097007947642, -8.160411096591528],
              [-38.498109561680714, -8.160462669698134],
              [-38.4981408255126, -8.160506306220814],
              [-38.498175641297962, -8.160540630601012],
              [-38.498210176405912, -8.160581648067636],
              [-38.498242525525612, -8.160628542194379],
              [-38.498265352151492, -8.160668097925026],
              [-38.498223984116073, -8.160646790502099],
              [-38.498127049181576, -8.160634278519073],
              [-38.498072661367807, -8.160643889482795],
              [-38.498030599200838, -8.16066961614095],
              [-38.497980454779785, -8.160697774978235],
              [-38.497950247989671, -8.16075273226493],
              [-38.497936520033996, -8.160696454203642],
              [-38.49791020517138, -8.160615195832413],
              [-38.497877039998905, -8.160567577055234],
              [-38.497843568076142, -8.160474822425028],
              [-38.497821751461515, -8.16042613228899],
              [-38.497817644700746, -8.160370861121109],
              [-38.497821895338163, -8.160310896816959],
              [-38.497833244681999, -8.160236469016525],
              [-38.497841696709607, -8.160155977350271],
              [-38.497845878356081, -8.160078555753016],
              [-38.497851066568558, -8.159994713321813],
              [-38.497853520729784, -8.159919460426817],
              [-38.497851171142891, -8.159838231755561],
              [-38.497846542186053, -8.159764960021535],
              [-38.497819759728181, -8.159694736191318],
              [-38.497790602275714, -8.159636358607738],
              [-38.497751010526464, -8.159573897754445],
              [-38.497713590327905, -8.1595171380563],
              [-38.497680084743678, -8.159451428475833],
              [-38.497644405260594, -8.159381917220051],
              [-38.497607884393595, -8.159331942517188],
              [-38.497571196554723, -8.15927029931623],
              [-38.497526967440812, -8.159214073870949],
              [-38.497490702437936, -8.159177305445635],
              [-38.497452436501838, -8.159143609898381],
              [-38.497408097429599, -8.159102761118824],
              [-38.497362413548728, -8.159048433341688],
              [-38.497329594151424, -8.159014834992421],
              [-38.497295423840576, -8.158972642039554],
              [-38.497247897316917, -8.158940020407289],
              [-38.497181123225396, -8.158909636180846],
              [-38.497114789009231, -8.158890468518269],
              [-38.497028103311628, -8.158885205166161],
              [-38.496938776032387, -8.158887174663114],
              [-38.496852807393424, -8.158889238762912],
              [-38.496777522845747, -8.158913386362052],
              [-38.496700355961273, -8.158918355819189],
              [-38.496613375073935, -8.158931363282363],
              [-38.496532482248789, -8.158939584344166],
              [-38.49645003919057, -8.158953411476723],
              [-38.496370843947389, -8.158983343055139],
              [-38.496311335099222, -8.159023067888214],
              [-38.496240944989296, -8.15905319128921],
              [-38.496168395476673, -8.159067935181248],
              [-38.496098104284457, -8.159091546147701],
              [-38.496038233533035, -8.159130275517665],
              [-38.495985935466592, -8.159138532032902],
              [-38.495926861187414, -8.159121182244657],
              [-38.495856424467483, -8.159116029306571],
              [-38.495795073627356, -8.159104375131806],
              [-38.49573352094118, -8.159108911572972],
              [-38.49566752482351, -8.159109733949185],
              [-38.495615344410751, -8.159096553481215],
              [-38.495545906931468, -8.159090859022614],
              [-38.495485991525165, -8.15909277436181],
              [-38.495426979919316, -8.159097856631305],
              [-38.495369317374731, -8.159112980724117],
              [-38.495315401200642, -8.159108571882429],
              [-38.495248321208422, -8.159105051153109],
              [-38.495188876372509, -8.159093670617551],
              [-38.495133791680686, -8.159080034211044],
              [-38.49506561294772, -8.159084019583217],
              [-38.495003873372241, -8.15909280691554],
              [-38.494938518948906, -8.159088655123176],
              [-38.494810937667843, -8.159119882847646],
              [-38.49477271004325, -8.159128156748364],
              [-38.49471660840986, -8.159201443205761],
              [-38.494685861990881, -8.15925214832524],
              [-38.494652768765803, -8.159292177201456],
              [-38.494621490395836, -8.159332751049069],
              [-38.494596379640605, -8.159377493370553],
              [-38.494567270932322, -8.159425215612215],
              [-38.494488810288004, -8.159520544378859],
              [-38.494426080557396, -8.159594817488088],
              [-38.494367819468401, -8.159652724386778],
              [-38.494372381592598, -8.159562006922968],
              [-38.494378445768461, -8.159504034885636],
              [-38.494382983556235, -8.159432674076259],
              [-38.494389242697373, -8.159364028968708],
              [-38.494404494345886, -8.159290962943924],
              [-38.494410200858894, -8.159228648771883],
              [-38.494407287861314, -8.159162886675183],
              [-38.494423779114641, -8.159114696441506],
              [-38.494459616688296, -8.159057937424238],
              [-38.494494548071785, -8.158999910947189],
              [-38.494527275790844, -8.15896178111478],
              [-38.494558561525714, -8.15891532791426],
              [-38.494587128032016, -8.158865615053733],
              [-38.494609258677478, -8.158808477114995],
              [-38.494619574323394, -8.158762450010801],
              [-38.494611674234797, -8.158692159101513],
              [-38.494578490068228, -8.158660097770015],
              [-38.494521825799211, -8.158603223350079],
              [-38.494456184852986, -8.158538197060889],
              [-38.494406479413023, -8.158506024651981],
              [-38.494342408594072, -8.158491834220477],
              [-38.494274975620804, -8.158480353137081],
              [-38.494224725769136, -8.158447999124039],
              [-38.49416847679889, -8.158421878790785],
              [-38.494095412146159, -8.158413194654225],
              [-38.494027963496812, -8.158414195860068],
              [-38.493957785508925, -8.158419987591646],
              [-38.493895224591995, -8.158432301354447],
              [-38.493837389309007, -8.158440460227625],
              [-38.49377495660864, -8.158423015489673],
              [-38.493721140667894, -8.15841118952005],
              [-38.493672691158054, -8.15839095823185],
              [-38.493620231528702, -8.158383385178489],
              [-38.493572657574902, -8.158388752831467],
              [-38.493528702855748, -8.15840353198932],
              [-38.493466473607903, -8.158440810795284],
              [-38.493417214065886, -8.158487150985112],
              [-38.493359147436678, -8.158534836933303],
              [-38.49332361251232, -8.158567445598035],
              [-38.493287619254062, -8.158603581305945],
              [-38.493236093208957, -8.158647566890059],
              [-38.493218807520719, -8.158677756169183],
              [-38.493211404028571, -8.158645817427457],
              [-38.493207391532955, -8.158588285070032],
              [-38.493174850339209, -8.158550345068385],
              [-38.493128491792, -8.158527674133046],
              [-38.493065148724654, -8.158512308539111],
              [-38.493010687329047, -8.1585084414256],
              [-38.492957112158493, -8.15852167080169],
              [-38.492916069109825, -8.158531026426969],
              [-38.492944889302109, -8.158496238554289],
              [-38.492995821296333, -8.158419418265046],
              [-38.493039639843559, -8.158368639183765],
              [-38.49309479238952, -8.158328276231495],
              [-38.493147488819744, -8.158291980529331],
              [-38.493197740986552, -8.158250345106834],
              [-38.493244537924085, -8.158213227946186],
              [-38.493298595410181, -8.15817738618348],
              [-38.493342946138284, -8.158136466976023],
              [-38.493382124625256, -8.158093913164347],
              [-38.493427191756268, -8.15806077369651],
              [-38.493481968360932, -8.158030540817295],
              [-38.493538284406874, -8.158003294763507],
              [-38.493589066017307, -8.157973690038316],
              [-38.493615268496413, -8.157927049654106],
              [-38.493619780769428, -8.157876040477881],
              [-38.493592061347996, -8.157829694517474],
              [-38.493555609710832, -8.157797357671344],
              [-38.493525072008005, -8.157754354901394],
              [-38.493530956530059, -8.157694935423907],
              [-38.493567865988162, -8.157652016946582],
              [-38.493622734099525, -8.157621150995565],
              [-38.493680231542101, -8.157592820972045],
              [-38.49373066443674, -8.157551728410194],
              [-38.49376096400065, -8.157495414788389],
              [-38.493794166704468, -8.157440461567116],
              [-38.493817663799163, -8.157379526400682],
              [-38.493815106262574, -8.157319915467056],
              [-38.493812281516682, -8.157256233868297],
              [-38.493797193156475, -8.157199501740507],
              [-38.493831629494416, -8.157174127794885],
              [-38.493829708415895, -8.157113703589328],
              [-38.493808370805553, -8.157045566729121],
              [-38.493769324588591, -8.156983106212325],
              [-38.49370874702484, -8.156934367469388],
              [-38.493683356543954, -8.156912898672051],
              [-38.493664570791637, -8.1569088953232],
              [-38.493618831555551, -8.156899159833433],
              [-38.493571847860125, -8.15686843796521],
              [-38.49355207483913, -8.156855478649142],
              [-38.493527812058012, -8.156803528994374],
              [-38.493500265614102, -8.156764057583468],
              [-38.493451374155384, -8.156734509191892],
              [-38.493416474252307, -8.156695480844174],
              [-38.493359230112141, -8.156666916971702],
              [-38.493306516607618, -8.156644871285481],
              [-38.49324807222628, -8.156632315866196],
              [-38.493193964275839, -8.156636318540555],
              [-38.493152614259657, -8.156601261985482],
              [-38.493133165549338, -8.156547057035718],
              [-38.493125159242432, -8.156489248323437],
              [-38.493095616231948, -8.15644941258398],
              [-38.493048572050931, -8.156394720857762],
              [-38.493001622305769, -8.1563371347873],
              [-38.49306645483059, -8.156322834044879],
              [-38.493135504856021, -8.156347432840089],
              [-38.493200471087597, -8.156371121999568],
              [-38.493273702772427, -8.15639102250727],
              [-38.493345845374968, -8.156410740737066],
              [-38.493417353005235, -8.156430096353644],
              [-38.493497224605804, -8.156439512711968],
              [-38.493578477210171, -8.156433553973415],
              [-38.493662814962505, -8.156428594042545],
              [-38.493731655648681, -8.156403172628192],
              [-38.493796324021154, -8.156375032436419],
              [-38.493862808256274, -8.156346623145948],
              [-38.493912239470646, -8.156307881044729],
              [-38.493951878531689, -8.156259810174422],
              [-38.493982720000048, -8.156205577593051],
              [-38.494029880750666, -8.156167646707202],
              [-38.494083570801394, -8.156134970170383],
              [-38.494135173781395, -8.156101657858319],
              [-38.494176625709088, -8.156055669617087],
              [-38.494204202590211, -8.155998719387048],
              [-38.494252357858059, -8.155963955526822],
              [-38.494317806061765, -8.155965303510412],
              [-38.494387157197856, -8.155967018159298],
              [-38.494447158477861, -8.155968630682782],
              [-38.494515708648301, -8.155957590604098],
              [-38.494559681355334, -8.155928248598512],
              [-38.494588524726915, -8.155874737086744],
              [-38.494608292950829, -8.155819224316895],
              [-38.494638858105432, -8.155768066694531],
              [-38.494662875428098, -8.15572639834761],
              [-38.494693251292723, -8.155681391208105],
              [-38.494724352452643, -8.155637108584553],
              [-38.494726431069694, -8.155572166765635],
              [-38.494724956235991, -8.15551771292871],
              [-38.494702688176616, -8.155467574859532],
              [-38.494716566690755, -8.155403461815879],
              [-38.494710742091122, -8.155343032736003],
              [-38.494717267266694, -8.155279272332642],
              [-38.494718737402131, -8.155265163680363],
              [-38.494718761817232, -8.15524571656824],
              [-38.494734415077914, -8.155214077940489],
              [-38.494770595462612, -8.155173148358481],
              [-38.494782476875997, -8.155109032821231],
              [-38.494807417230341, -8.155055154614772],
              [-38.494833355888851, -8.155001458553841],
              [-38.494855493632912, -8.154938441216007],
              [-38.494880885916878, -8.154886101247547],
              [-38.494892222871648, -8.154821804122562],
              [-38.494919519171802, -8.15477127555967],
              [-38.494952354625553, -8.15471930666614],
              [-38.494992799604191, -8.1546798296016],
              [-38.495032435533361, -8.15463401989641],
              [-38.495056464429567, -8.154583034992839],
              [-38.495084580059462, -8.154530607948049],
              [-38.495117757585767, -8.154495373053479],
              [-38.495156485834592, -8.154449471751542],
              [-38.495195021178375, -8.154412615388754],
              [-38.495248798841516, -8.154382200080068],
              [-38.495311710061678, -8.154379564848453],
              [-38.495381341886038, -8.154374405320928],
              [-38.495454871524792, -8.154373682772537],
              [-38.495527598690067, -8.154361381376621],
              [-38.495590981924153, -8.154344274394065],
              [-38.495636747116471, -8.154333115304627],
              [-38.495630097125186, -8.154279288185107],
              [-38.49558403435114, -8.154238165739255],
              [-38.495533875102254, -8.154206264279077],
              [-38.495475896878872, -8.154184121881576],
              [-38.495415995584544, -8.154175363954968],
              [-38.495358459913191, -8.154162267276044],
              [-38.495299750962552, -8.15414374201816],
              [-38.495242408421809, -8.154121419476159],
              [-38.49519378719063, -8.154093680675654],
              [-38.495139088512396, -8.154062316228089],
              [-38.49508965371583, -8.154031862847642],
              [-38.495033055340322, -8.153995249814285],
              [-38.494977253191855, -8.153975190451259],
              [-38.494913660432417, -8.153942277214732],
              [-38.494864585480919, -8.153914447360036],
              [-38.494826788549553, -8.153869264800651],
              [-38.494792069494359, -8.153830869961011],
              [-38.494750177557741, -8.153793732505587],
              [-38.494697100661107, -8.153772138821383],
              [-38.494646387123986, -8.153748015425053],
              [-38.494607751786454, -8.153719927198358],
              [-38.494546520976989, -8.153685660074849],
              [-38.494458306677622, -8.153669721019108],
              [-38.494385527356002, -8.153651358994248],
              [-38.494342726468453, -8.153615215336275],
              [-38.494313451762466, -8.15357863628585],
              [-38.494278275380339, -8.153543044842348],
              [-38.494207918543381, -8.153475118207831],
              [-38.494172745021011, -8.153437265463998],
              [-38.494124048406633, -8.153397405904885],
              [-38.494072975715788, -8.153370206631093],
              [-38.494021278629155, -8.153334232745003],
              [-38.493935383291642, -8.153278949931531],
              [-38.49387575780365, -8.153267569028355],
              [-38.493810154878801, -8.153245235983659],
              [-38.493753256086542, -8.15323114491243],
              [-38.493688649661465, -8.15321044122576],
              [-38.493629464418667, -8.153209915055598],
              [-38.493568576739136, -8.15319165819376],
              [-38.493508665749324, -8.153190769292353],
              [-38.493457662964474, -8.153180213166683],
              [-38.493411730194794, -8.153180336683862],
              [-38.493357899933073, -8.153180450327547],
              [-38.493311884357439, -8.1531742421028],
              [-38.493290101815468, -8.153171320423331],
              [-38.493230660651562, -8.153157768814065],
              [-38.493162235424748, -8.153142035122597],
              [-38.493101432292498, -8.153128753147826],
              [-38.4930337531028, -8.153097281752501],
              [-38.492968883643201, -8.15306925102888],
              [-38.492899469139857, -8.15304618946073],
              [-38.492819247433168, -8.153027003790907],
              [-38.492748180634933, -8.153018502872257],
              [-38.492669371914651, -8.153030615266788],
              [-38.49262665841664, -8.153069365645868],
              [-38.492608056488216, -8.153135733994553],
              [-38.492608340586926, -8.153198417452158],
              [-38.492596810722709, -8.153271488083009],
              [-38.49253252265467, -8.153286332173241],
              [-38.492465261560831, -8.153283263074151],
              [-38.492410339930565, -8.153285003357558],
              [-38.492314468497817, -8.153294561667565],
              [-38.492240199074885, -8.153306227399318],
              [-38.492183447093552, -8.153319724093569],
              [-38.492124781285703, -8.153339188201171],
              [-38.492081709394249, -8.153374139092575],
              [-38.492046520153316, -8.15342085858825],
              [-38.491999466126472, -8.153518216220981],
              [-38.491973923441357, -8.153617590740724],
              [-38.491957138170015, -8.153754875533799],
              [-38.491949267514386, -8.153805518676343],
              [-38.491953057745057, -8.15389516115464],
              [-38.491960751455586, -8.153984718072323],
              [-38.491970464272079, -8.154056820323563],
              [-38.491991786597836, -8.154136715976422],
              [-38.492005412606552, -8.154201044275997],
              [-38.492024931753377, -8.154271168874216],
              [-38.492059978399254, -8.154337513951306],
              [-38.492079497560894, -8.154407638547386],
              [-38.492104843630145, -8.154464112221936],
              [-38.492120407919266, -8.154503116444408],
              [-38.492136392651695, -8.154568623568801],
              [-38.49216238718062, -8.154614786548235],
              [-38.492188736374828, -8.154667643403316],
              [-38.492212642266466, -8.154714436926254],
              [-38.49224571917545, -8.154759161543749],
              [-38.492278080151706, -8.154795744600701],
              [-38.492316436465828, -8.154829078899098],
              [-38.492387883412277, -8.154896283603133],
              [-38.49232786166845, -8.154911042534078],
              [-38.492279475470738, -8.154912971874372],
              [-38.492224721819312, -8.154925295189374],
              [-38.492158242976089, -8.1549495434445],
              [-38.492104192469476, -8.154980138885499],
              [-38.492050951862851, -8.155016343344586],
              [-38.492003248137003, -8.155052916541942],
              [-38.491959538897277, -8.155089223383916],
              [-38.491923447998772, -8.155130966876687],
              [-38.491921374988841, -8.155191114717876],
              [-38.491933368638023, -8.155254174647036],
              [-38.491937970935403, -8.155275798392807],
              [-38.491946905600564, -8.155317146058374],
              [-38.491954903731141, -8.155381286400642],
              [-38.491963530903391, -8.155450492831161],
              [-38.491983249111598, -8.155506778556205],
              [-38.49199372310008, -8.155551203510866],
              [-38.491909344888022, -8.155516454719329],
              [-38.491852354172721, -8.155503086866638],
              [-38.491787109103008, -8.155484733808974],
              [-38.491728767719678, -8.155462680874633],
              [-38.491660984012682, -8.155441882415976],
              [-38.491640748447502, -8.155435887220921],
              [-38.49159528645329, -8.155422443339665],
              [-38.491543738568232, -8.155412067180256],
              [-38.491531032978521, -8.155409609044595],
              [-38.491470393643347, -8.155410166137299],
              [-38.491408581075603, -8.15540529464209],
              [-38.491347126013309, -8.155404855723367],
              [-38.491277839612721, -8.155423854099432],
              [-38.491220892144618, -8.155448114153845],
              [-38.491159887985638, -8.155450027518452],
              [-38.491200609493134, -8.155407566298962],
              [-38.491192873177347, -8.155351838293127],
              [-38.491192406050686, -8.15529051140129],
              [-38.49118843168003, -8.155274858254968],
              [-38.491176961078445, -8.155228984805223],
              [-38.491168324210307, -8.15516755720642],
              [-38.491160504558593, -8.155105949729649],
              [-38.491158592106245, -8.155039013012763],
              [-38.491167746567761, -8.154978240908283],
              [-38.491169637334295, -8.154918726010759],
              [-38.491173261193516, -8.154852610308433],
              [-38.491160088498376, -8.154788825269225],
              [-38.491150186947934, -8.154722511685906],
              [-38.491129835630609, -8.15466450654896],
              [-38.491095220926027, -8.154615619135768],
              [-38.49105779537436, -8.154564195541367],
              [-38.491045256535394, -8.154501677619859],
              [-38.490992427557309, -8.154427712188776],
              [-38.490975747303771, -8.154409872244722],
              [-38.490933865067156, -8.154365317379794],
              [-38.490906575924392, -8.154338057126481],
              [-38.490888987689587, -8.154320396942541],
              [-38.490851811406948, -8.154287244903852],
              [-38.490820623827901, -8.15425609032282],
              [-38.490771747411969, -8.154215054277678],
              [-38.490734661924812, -8.154181902343279],
              [-38.49067807929918, -8.154133168206451],
              [-38.490624118086735, -8.154093211178285],
              [-38.490562248732992, -8.154061656221526],
              [-38.490487940110867, -8.15403243736902],
              [-38.490427707950758, -8.153998351800759],
              [-38.490367206565516, -8.153961823687705],
              [-38.490296988848996, -8.153927906452367],
              [-38.490231110747246, -8.153907924234762],
              [-38.490148070854318, -8.153891990754682],
              [-38.490091162622008, -8.153885406657116],
              [-38.49006838119746, -8.15388275490271],
              [-38.490015181601109, -8.153886577412575],
              [-38.489957711755416, -8.153893379451622],
              [-38.489925571246275, -8.153897952056457],
              [-38.489819998890596, -8.153897819219885],
              [-38.489762631512221, -8.153895395279864],
              [-38.489720316534857, -8.153905924879743],
              [-38.489659756634211, -8.153915617443266],
              [-38.489583477707001, -8.153936958485426],
              [-38.489554134842393, -8.153954469184322],
              [-38.489495498547527, -8.153950505928584],
              [-38.48945048116196, -8.153944569876236],
              [-38.489383051656858, -8.153931098096248],
              [-38.489327308148056, -8.153936635894445],
              [-38.489274525354318, -8.15396967474917],
              [-38.489217733606331, -8.154014376822232],
              [-38.489141424909953, -8.154059144745842],
              [-38.489063217365704, -8.154098030896876],
              [-38.48900066781966, -8.154101841487586],
              [-38.488934134008979, -8.154097868195318],
              [-38.488901478911814, -8.154078741709318],
              [-38.488853213922262, -8.154056791536055],
              [-38.488816494593109, -8.154021378604236],
              [-38.488779509145985, -8.153981080939237],
              [-38.488755698156346, -8.153931392902527],
              [-38.488702739962001, -8.153888090181409],
              [-38.488628514949092, -8.153864660015367],
              [-38.488583578484764, -8.153866502820525],
              [-38.488536627482297, -8.153882091745103],
              [-38.488493550299594, -8.153920931650472],
              [-38.488464177622767, -8.153961869232612],
              [-38.48844155649617, -8.153975951162622],
              [-38.488428212403086, -8.15404739120444],
              [-38.488401546202681, -8.154101538150822],
              [-38.48836788702954, -8.154158208918656],
              [-38.488345857054334, -8.154207115509866],
              [-38.488309482404468, -8.154257632125859],
              [-38.488290565016271, -8.154285919644273],
              [-38.488249183529142, -8.154347555498326],
              [-38.488249253333692, -8.154292651378746],
              [-38.488260807364192, -8.154201219251233],
              [-38.488267779010563, -8.154144062549475],
              [-38.488265573663497, -8.154093678144587],
              [-38.488261010903145, -8.154041119922081],
              [-38.488242274456127, -8.1539984935003],
              [-38.488192279599716, -8.153980611395864],
              [-38.488136145714549, -8.154007585599022],
              [-38.488088975744262, -8.1540525710091],
              [-38.488051161893956, -8.154092683833715],
              [-38.488011349585349, -8.154133879551317],
              [-38.487979980579894, -8.154174181410728],
              [-38.487949327419614, -8.154222624827865],
              [-38.487924570430366, -8.154274422404066],
              [-38.487889469808955, -8.154322407939153],
              [-38.487852296753765, -8.154358270319742],
              [-38.487808325968381, -8.154385893000157],
              [-38.487741934087488, -8.154413035086392],
              [-38.487769417956343, -8.154358436965349],
              [-38.487819991250049, -8.15427845107506],
              [-38.487868752111787, -8.154196020690463],
              [-38.487905769029211, -8.15414016827563],
              [-38.487940611341777, -8.154081147299102],
              [-38.487978717548735, -8.154025296251843],
              [-38.487991966341767, -8.153957474178764],
              [-38.488003215989913, -8.153891277711447],
              [-38.488003587146707, -8.153884946558298],
              [-38.48800480887629, -8.153780838146856],
              [-38.488018940563371, -8.153732554762582],
              [-38.488046783650695, -8.153680941976885],
              [-38.488074195857237, -8.153611238296058],
              [-38.488076342019575, -8.15356538197196],
              [-38.488060165393101, -8.153508557851625],
              [-38.48802146740946, -8.153458760528597],
              [-38.487994293008484, -8.153412867179474],
              [-38.487978850197557, -8.153350074168147],
              [-38.487980648105314, -8.153292549129915],
              [-38.488008487827315, -8.153243559440579],
              [-38.488053280858317, -8.153211686534688],
              [-38.488111682301302, -8.15318607197451],
              [-38.488173991922174, -8.153156663365452],
              [-38.488238666531672, -8.153123458757925],
              [-38.488303697795395, -8.153095319887372],
              [-38.488365203398473, -8.153055689184766],
              [-38.488424356868308, -8.15300972388177],
              [-38.48848295440802, -8.152972622139595],
              [-38.488548089622675, -8.152934081406709],
              [-38.488616213642601, -8.152900881085477],
              [-38.488687947998883, -8.152883966620626],
              [-38.488754613502003, -8.152855558378713],
              [-38.488811126826747, -8.152815559501597],
              [-38.48885656781183, -8.152773737623017],
              [-38.488899466381454, -8.152732455244662],
              [-38.488946809110899, -8.152765710711925],
              [-38.488978084103316, -8.152799307731257],
              [-38.489043738644448, -8.152780666955552],
              [-38.489099419684969, -8.152752697071108],
              [-38.489122405658378, -8.152737168329512],
              [-38.489165823832089, -8.152715605059857],
              [-38.48923258831573, -8.15268068433314],
              [-38.489293456505195, -8.152642499904786],
              [-38.489337913691593, -8.152589008450995],
              [-38.489382461182736, -8.15253587891228],
              [-38.489423105198156, -8.152482834904525],
              [-38.489456857372403, -8.152424174205221],
              [-38.489471809824956, -8.152372725975098],
              [-38.4894829641526, -8.152309876039579],
              [-38.489459802007779, -8.152249606000877],
              [-38.489424366939581, -8.152203702339525],
              [-38.489379579480428, -8.152159776837829],
              [-38.489346060121214, -8.152106458537816],
              [-38.489294644810371, -8.152063519650843],
              [-38.489254587342714, -8.152012002145396],
              [-38.489228159511647, -8.15195000939902],
              [-38.489176481087483, -8.151899834026047],
              [-38.48912488748374, -8.151854362245746],
              [-38.489088552997245, -8.151801944898397],
              [-38.489061225944042, -8.151733257579378],
              [-38.489073482814867, -8.151660007092046],
              [-38.489038976714184, -8.151597461447095],
              [-38.488977480271004, -8.151558580152241],
              [-38.488909624514818, -8.151523670711891],
              [-38.488839322544919, -8.151485049655346],
              [-38.488771281473753, -8.151453124867741],
              [-38.48870651211535, -8.151418219286125],
              [-38.4886347421577, -8.151391897784814],
              [-38.48859985432162, -8.151344095262832],
              [-38.488582606307752, -8.151273159332479],
              [-38.488567709265304, -8.151209462507042],
              [-38.488537648509883, -8.151149093273217],
              [-38.488495045959013, -8.151100376496727],
              [-38.488453074823212, -8.151054826324213],
              [-38.488413380661569, -8.151003309203956],
              [-38.488375135465468, -8.150954507460973],
              [-38.488323811640207, -8.150911206771996],
              [-38.488260943055707, -8.150880735660124],
              [-38.488198259365397, -8.150847641671323],
              [-38.488136294758753, -8.150820156589852],
              [-38.488058260082816, -8.150794912509005],
              [-38.488007826929568, -8.150765090218826],
              [-38.488060681297156, -8.150746885682986],
              [-38.488108897504397, -8.150735459167798],
              [-38.488164012505429, -8.150724131804733],
              [-38.488221294611847, -8.150721852344276],
              [-38.488307617869133, -8.150725036621662],
              [-38.488377517836774, -8.150722682606283],
              [-38.488453937758671, -8.150732819145379],
              [-38.488525449393265, -8.150748014779218],
              [-38.488595496110506, -8.15077306779569],
              [-38.488664626356396, -8.150804993979172],
              [-38.48874612110135, -8.150821920745058],
              [-38.488826893556563, -8.150835771224582],
              [-38.488905694794688, -8.150829267556682],
              [-38.48898459116819, -8.150819326826088],
              [-38.489059675440295, -8.15080901947028],
              [-38.489134676622491, -8.150792651727157],
              [-38.489194989317077, -8.150762788156616],
              [-38.489241433479911, -8.150717078051601],
              [-38.48930085102257, -8.150677263641715],
              [-38.489364439737244, -8.150640982093167],
              [-38.489431476863956, -8.150605518940845],
              [-38.489490980102197, -8.150569684486841],
              [-38.489543944654969, -8.150536103088543],
              [-38.489592463969856, -8.15050034524605],
              [-38.489630731306065, -8.150460142359778],
              [-38.4896701796279, -8.150419217341025],
              [-38.48968586939673, -8.150358905745163],
              [-38.489706638896742, -8.150301494996143],
              [-38.489751704361389, -8.150269169953907],
              [-38.489800775151643, -8.150227985669826],
              [-38.489844120572698, -8.150191949929576],
              [-38.489878202807063, -8.150159158798427],
              [-38.489909375079222, -8.15013043433464],
              [-38.48998753141781, -8.15005961841689],
              [-38.490051855682673, -8.150015830176413],
              [-38.490096099647168, -8.149987031678734],
              [-38.490113361098935, -8.149975837356346],
              [-38.490168692887508, -8.149936560380818],
              [-38.490208588451971, -8.149900791616298],
              [-38.490298253998979, -8.149845095545713],
              [-38.490364097564232, -8.149820123132853],
              [-38.490426838865005, -8.149807719610601],
              [-38.490486406929229, -8.149792236731532],
              [-38.490571013205674, -8.149789177185765],
              [-38.490658721502768, -8.149773729621471],
              [-38.490724561034142, -8.149751922955751],
              [-38.490715282242689, -8.149695740740295],
              [-38.49075973581224, -8.149644691285811],
              [-38.490823188775465, -8.149571957243731],
              [-38.490894077140233, -8.149505654599846],
              [-38.49095384407223, -8.149476423224025],
              [-38.491006710388966, -8.149448539957026],
              [-38.49108647166868, -8.149400429221821],
              [-38.491157763325823, -8.149374106730205],
              [-38.491216085592704, -8.149338722778234],
              [-38.4912556297908, -8.149293546472087],
              [-38.491250699783699, -8.149243972699578],
              [-38.491200743968889, -8.149195518328362],
              [-38.491172536874465, -8.149176578520786],
              [-38.491138887863677, -8.149154013812801],
              [-38.491077716597246, -8.149145253700695],
              [-38.491060744789614, -8.149142790209378],
              [-38.490973932043914, -8.149168007788678],
              [-38.490908602056699, -8.149217674296388],
              [-38.490858698405177, -8.149271611389304],
              [-38.490811776588586, -8.149336406434063],
              [-38.490734476850797, -8.149376108187266],
              [-38.490678243506508, -8.14941059017192],
              [-38.490615017168579, -8.149447686468779],
              [-38.490538466931731, -8.149468936956993],
              [-38.490456193749246, -8.149493436506001],
              [-38.49036864680015, -8.149524894199859],
              [-38.490277918938766, -8.149559332785993],
              [-38.490174130122817, -8.149585342918765],
              [-38.490096582004234, -8.149606049355512],
              [-38.49007433458997, -8.14961199120672],
              [-38.489976557284443, -8.149621908426305],
              [-38.489893995981831, -8.149587161575226],
              [-38.489797633585745, -8.14955502045129],
              [-38.489767419178321, -8.149544218684607],
              [-38.489703100685958, -8.149511846489233],
              [-38.489639614422551, -8.1494674452443],
              [-38.489554948969008, -8.149445630285038],
              [-38.489463623594915, -8.149450128394774],
              [-38.489377322259578, -8.149429849020587],
              [-38.48929144131737, -8.149435982077074],
              [-38.489203385447645, -8.149439217919602],
              [-38.489120858125027, -8.149449335040838],
              [-38.489039699537045, -8.149453845859011],
              [-38.488979813943686, -8.149433418771286],
              [-38.488935276998447, -8.149406950744108],
              [-38.488862381481667, -8.149337934646471],
              [-38.488810686545143, -8.149301055633218],
              [-38.488776084150736, -8.14924303244066],
              [-38.488751196737049, -8.149183393357681],
              [-38.488740288648501, -8.149123771892155],
              [-38.488752712436693, -8.149062008989377],
              [-38.488780193006953, -8.149009581593457],
              [-38.48882100310977, -8.148968477463596],
              [-38.488867536834505, -8.148923581566235],
              [-38.488922045297954, -8.148889278567578],
              [-38.488966930367987, -8.148855958407776],
              [-38.488963011332878, -8.148796798008977],
              [-38.488905508133485, -8.148758916724125],
              [-38.488831022499426, -8.148726983850661],
              [-38.48873435547528, -8.14872053041033],
              [-38.488658782580558, -8.14875851535656],
              [-38.48860279737351, -8.148811992233785],
              [-38.488544809824198, -8.148869627359259],
              [-38.488487187769508, -8.148925363448877],
              [-38.488432106473049, -8.148981826347434],
              [-38.488373558600387, -8.149051762178745],
              [-38.488318366961217, -8.149123601720587],
              [-38.488255929177313, -8.149182949768816],
              [-38.488181518410094, -8.14923495610674],
              [-38.488099772337563, -8.149273204505302],
              [-38.488011142770993, -8.149299323662289],
              [-38.487923898506615, -8.149306811485346],
              [-38.487835298713875, -8.149309503634786],
              [-38.487760546055441, -8.14934477588363],
              [-38.487693311358392, -8.149392901762081],
              [-38.487642236640056, -8.149439419908457],
              [-38.487595612064524, -8.149484225085734],
              [-38.4875454515171, -8.149525679074829],
              [-38.487493948443024, -8.149552116369779],
              [-38.487513488670899, -8.149462232014438],
              [-38.487542609704761, -8.149404831940863],
              [-38.487578447721901, -8.149347711704861],
              [-38.487614956018405, -8.149263185429499],
              [-38.487652073488533, -8.149199282919625],
              [-38.487697909007039, -8.149132496959226],
              [-38.487735208805958, -8.149067961507685],
              [-38.487778596942547, -8.148998368440918],
              [-38.487823081031564, -8.148923530549938],
              [-38.487862660623428, -8.148850766851748],
              [-38.487877089282279, -8.148782946245561],
              [-38.487870809805976, -8.148724144672984],
              [-38.487863441034811, -8.148665341725108],
              [-38.487822829185816, -8.148621873608205],
              [-38.487744440479304, -8.148589845162244],
              [-38.487644331102807, -8.148577869561201],
              [-38.48754375842433, -8.148573310394694],
              [-38.487447930201839, -8.14854976232866],
              [-38.487346827738122, -8.148533534168067],
              [-38.487242084972145, -8.148524627973798],
              [-38.487145253079817, -8.148505148898368],
              [-38.487061967235135, -8.148469496064129],
              [-38.486972595820554, -8.148436729974012],
              [-38.486888584732078, -8.148400352574704],
              [-38.486804102961848, -8.148377180518896],
              [-38.4867074207543, -8.148382756665704],
              [-38.486617704556956, -8.148406884212536],
              [-38.486537059238557, -8.148436178969018],
              [-38.486455685290743, -8.148467372273032],
              [-38.486375672719774, -8.148498748186908],
              [-38.486312017333866, -8.148516215228206],
              [-38.486236668398654, -8.148520823303265],
              [-38.486174252611853, -8.148491618794365],
              [-38.48609545586153, -8.148494956152213],
              [-38.486021634517606, -8.148511777107139],
              [-38.485952233998056, -8.148549859808087],
              [-38.48588509451946, -8.148594457886627],
              [-38.485834163137959, -8.148599729991203],
              [-38.485722602383731, -8.148598593613389],
              [-38.485663385561637, -8.148623392767126],
              [-38.485616032141969, -8.148670186724935],
              [-38.485560623333498, -8.148698518308464],
              [-38.485501150454454, -8.148710563418254],
              [-38.485438201905474, -8.148743136653026],
              [-38.485391493460675, -8.148782423906649],
              [-38.48534015260897, -8.148823966570459],
              [-38.485286198815004, -8.148850219569995],
              [-38.485218748717507, -8.148853661600921],
              [-38.485170834946068, -8.148841661169461],
              [-38.485113381396197, -8.148836251600299],
              [-38.485051659125858, -8.148832645638999],
              [-38.484995629755751, -8.148849308081411],
              [-38.484934508440048, -8.148872657478117],
              [-38.484882016034184, -8.148891857043095],
              [-38.484826072797198, -8.148912137639266],
              [-38.484722547878377, -8.148944659225418],
              [-38.484679749947254, -8.148978252899967],
              [-38.484635580319548, -8.149019714126153],
              [-38.484572155908808, -8.149069381988463],
              [-38.484493805631637, -8.149078418052685],
              [-38.484410292655497, -8.149078492820163],
              [-38.484363344291779, -8.149092362710052],
              [-38.484316202910676, -8.149115187068441],
              [-38.484279502124423, -8.149136848822277],
              [-38.484243895032584, -8.149155074798042],
              [-38.484201383149824, -8.149177905028102],
              [-38.484160057126367, -8.149196214175946],
              [-38.48417381102842, -8.149159598723219],
              [-38.484217530781343, -8.149115061621268],
              [-38.484255151940275, -8.149083541806769],
              [-38.484317209673158, -8.149037852134549],
              [-38.484374617387068, -8.149008166494596],
              [-38.484497376278263, -8.148983719644987],
              [-38.484430981413809, -8.148942841544203],
              [-38.484482276755429, -8.148936936920274],
              [-38.48455237090679, -8.148924453201021],
              [-38.484610576695495, -8.148909512169356],
              [-38.484663741569697, -8.148861368945141],
              [-38.484699907615109, -8.148831927626402],
              [-38.484744863755779, -8.148814256192205],
              [-38.484796245804112, -8.148811517447481],
              [-38.484857730100025, -8.148788258984156],
              [-38.484915329585931, -8.148750523302994],
              [-38.484954223238439, -8.14871764825755],
              [-38.485024791452446, -8.148689336003644],
              [-38.485094800324752, -8.148672510395834],
              [-38.485155262362866, -8.148667793191061],
              [-38.485222782973992, -8.148680180310306],
              [-38.48527908151582, -8.148665960372501],
              [-38.485335943771403, -8.148636816606709],
              [-38.485396625024826, -8.148602612380035],
              [-38.485455402543259, -8.148566415790858],
              [-38.485512180006118, -8.148532568401002],
              [-38.485567424457038, -8.1484907593063],
              [-38.48561676473615, -8.148451927624436],
              [-38.485658845233111, -8.148411368148995],
              [-38.485691867665615, -8.148355601294227],
              [-38.485684492635542, -8.148301954066428],
              [-38.485638051285711, -8.14827430740965],
              [-38.485577873645092, -8.148269618070827],
              [-38.485515731515271, -8.148310513904784],
              [-38.48547048511309, -8.148342295532233],
              [-38.485397968479937, -8.148332615671755],
              [-38.48542128129796, -8.148274303875949],
              [-38.485503026342656, -8.14823687008283],
              [-38.485552311861639, -8.148169817396743],
              [-38.485577890544661, -8.148114222014719],
              [-38.485615447643937, -8.148061626724962],
              [-38.485646297699198, -8.148000972723626],
              [-38.485684579588522, -8.1479494637657],
              [-38.485707693585255, -8.147904629005192],
              [-38.485752308440162, -8.147940866252013],
              [-38.485795190274757, -8.147983613823037],
              [-38.485825917340094, -8.148019290750241],
              [-38.485859824058608, -8.148052981766792],
              [-38.485930266003315, -8.148052347415421],
              [-38.485991867561509, -8.148008194564975],
              [-38.48603939963688, -8.147963571622409],
              [-38.486086759961239, -8.147911260058008],
              [-38.48612415804272, -8.147840935972592],
              [-38.486154103490819, -8.147777748131691],
              [-38.486201714758586, -8.147742170441544],
              [-38.48624699955662, -8.147751363412752],
              [-38.486315619850195, -8.147755520626998],
              [-38.486386960035112, -8.147762304373641],
              [-38.486460570507056, -8.14776836736738],
              [-38.486525649335917, -8.147773515033057],
              [-38.486588298467908, -8.147762016491992],
              [-38.486663204453507, -8.147748724414109],
              [-38.486733381981047, -8.147741848414588],
              [-38.48680257216526, -8.147726197333954],
              [-38.486874935763666, -8.147713444710794],
              [-38.486956195945318, -8.147700160613766],
              [-38.487047523448041, -8.147693673109911],
              [-38.487132671492525, -8.147692514427737],
              [-38.487220631818531, -8.147692716058298],
              [-38.487308593642332, -8.147691741798855],
              [-38.487394832939138, -8.147689046760558],
              [-38.487485257296939, -8.14767893994601],
              [-38.487572231877202, -8.14766910010932],
              [-38.487638975946737, -8.147649646819795],
              [-38.487716980872314, -8.147626408763097],
              [-38.487783285777965, -8.147595377068367],
              [-38.487839789450398, -8.147562433502905],
              [-38.487902185977674, -8.147535376731641],
              [-38.487974423267453, -8.147479116447718],
              [-38.488026512873617, -8.147419484011632],
              [-38.488047044702455, -8.147334847061623],
              [-38.488041266554653, -8.147238689538545],
              [-38.488020239928794, -8.147141246449992],
              [-38.487994299083816, -8.147053565950822],
              [-38.487968904159395, -8.146964891169739],
              [-38.487940241600988, -8.14687603136022],
              [-38.487918029659618, -8.146782747552928],
              [-38.487909514979314, -8.146697078979765],
              [-38.487884742289289, -8.146618806933342],
              [-38.487848530679216, -8.146541605869654],
              [-38.487800315042655, -8.146481575487959],
              [-38.487754581717134, -8.146396854901358],
              [-38.48772243106594, -8.146338472922858],
              [-38.487690000510696, -8.146286060405679],
              [-38.487666839735908, -8.146225157123476],
              [-38.487661004861401, -8.146173682699304],
              [-38.487649417749644, -8.146148522477676],
              [-38.487647465115941, -8.146113605628978],
              [-38.487672763947195, -8.14613498418762],
              [-38.48769027345886, -8.146142785145422],
              [-38.487773805808629, -8.146198427981629],
              [-38.487777705876148, -8.146200965554167],
              [-38.487822335699747, -8.146225443864704],
              [-38.4879036988184, -8.146273757344533],
              [-38.487938348675186, -8.146294152718859],
              [-38.488030077661428, -8.14632864013371],
              [-38.4881593148937, -8.146348612105195],
              [-38.488233627681609, -8.146373308715651],
              [-38.488293305734146, -8.146413815919788],
              [-38.488340912210511, -8.146453222473466],
              [-38.488363448822753, -8.146505351114007],
              [-38.488375411410885, -8.14659229035624],
              [-38.488395730286676, -8.146675260255575],
              [-38.488429959912011, -8.146740700047099],
              [-38.488466451554729, -8.146811931601642],
              [-38.48851964900846, -8.146880561110436],
              [-38.488571674910908, -8.146942495704748],
              [-38.488621247856074, -8.147006055333783],
              [-38.488684359049174, -8.147059501418566],
              [-38.488762458700073, -8.147104554364097],
              [-38.488847289557249, -8.147138671109643],
              [-38.488943586224984, -8.147150551141197],
              [-38.489041518558984, -8.147161076433257],
              [-38.489139104089155, -8.147158757111645],
              [-38.489229982638086, -8.147148288662864],
              [-38.489303179332595, -8.147122692489889],
              [-38.489390598587008, -8.147119998504207],
              [-38.489483086763109, -8.147128707803057],
              [-38.489579028151873, -8.147134436515881],
              [-38.489678419428735, -8.147139807737995],
              [-38.489774555090008, -8.147135496500017],
              [-38.489864983648324, -8.147121951980669],
              [-38.489961312420483, -8.147108505309246],
              [-38.490045491277613, -8.147084098677068],
              [-38.490099789337727, -8.147072317729622],
              [-38.490134383752249, -8.147064944154144],
              [-38.490228632821669, -8.147045072726673],
              [-38.490317441487285, -8.147020400497352],
              [-38.490404156155179, -8.147000610015503],
              [-38.490491962281112, -8.146979102300797],
              [-38.490573879961893, -8.146948180185721],
              [-38.490664859969023, -8.146929209067711],
              [-38.490750496745854, -8.146900371975509],
              [-38.490833876727883, -8.146861582333427],
              [-38.490919070466411, -8.146824151725932],
              [-38.490957029326481, -8.146812169256647],
              [-38.491007701729082, -8.146796132400043],
              [-38.491052651347985, -8.146783254172494],
              [-38.491097056204318, -8.146770465708292],
              [-38.491170184232537, -8.146727230996543],
              [-38.491253291362924, -8.14668880272845],
              [-38.49134320346554, -8.146652825171712],
              [-38.491429124339753, -8.146614581296333],
              [-38.491508963323191, -8.14657632978043],
              [-38.491597235280857, -8.146545234507482],
              [-38.491691036809335, -8.146520206413376],
              [-38.491787286493782, -8.146497352206767],
              [-38.491883437637561, -8.146480648577509],
              [-38.491961999557908, -8.146447279767505],
              [-38.49203448540846, -8.14640929029221],
              [-38.492110045825498, -8.146380621189811],
              [-38.492172902288175, -8.14634840855299],
              [-38.492216239892066, -8.146318070970043],
              [-38.492231803204781, -8.14628561823703],
              [-38.492228927611912, -8.14626245896978],
              [-38.492218709796944, -8.146230968946577],
              [-38.492199873109797, -8.146195759612791],
              [-38.492172775177885, -8.146161173102829],
              [-38.492107064235469, -8.146081493253952],
              [-38.492057933172738, -8.146027070209616],
              [-38.492006532085711, -8.145973187029341],
              [-38.491967549845761, -8.145932977616873],
              [-38.491915155970347, -8.145874570591847],
              [-38.491853868872489, -8.145814072033183],
              [-38.491795393621942, -8.145755295572165],
              [-38.491719755358616, -8.145701834265674],
              [-38.491647541881463, -8.145667914830772],
              [-38.491575473786412, -8.145662668794795],
              [-38.491507535071968, -8.145694060909266],
              [-38.491435455331221, -8.145698040919648],
              [-38.491370465100573, -8.145694793656835],
              [-38.491299191820772, -8.145707096221249],
              [-38.491333286673225, -8.145663902986778],
              [-38.491387030497656, -8.145587629007473],
              [-38.491368292678359, -8.145546088136397],
              [-38.491313689306772, -8.145512281167633],
              [-38.4912599790675, -8.145490053144886],
              [-38.491288719950916, -8.14544549642393],
              [-38.491365977874437, -8.145438266661165],
              [-38.491445149127706, -8.145425431267874],
              [-38.491518332488901, -8.145410055705426],
              [-38.491585618845257, -8.145392049638941],
              [-38.49164946867883, -8.145363818199886],
              [-38.491676057406991, -8.14529827392794],
              [-38.491668431303673, -8.145227169238407],
              [-38.491645381847846, -8.145149532698392],
              [-38.491613242227359, -8.1450820153756],
              [-38.491569833300311, -8.145025066797336],
              [-38.491527138250483, -8.144977887906872],
              [-38.491497705100855, -8.144923670385868],
              [-38.491477635478716, -8.144858519923925],
              [-38.491484077877992, -8.144788608732407],
              [-38.491478336491504, -8.144734601797213],
              [-38.491465325780503, -8.144686465122675],
              [-38.491441918772651, -8.144604486437572],
              [-38.491404771940644, -8.144548631113091],
              [-38.491359820399488, -8.144491318775815],
              [-38.491299422030146, -8.144446107619604],
              [-38.491259630004443, -8.144400379572852],
              [-38.491241351025003, -8.144354949844208],
              [-38.491244133270932, -8.14430818973679],
              [-38.491249032085662, -8.144238638421623],
              [-38.491258647363111, -8.14417207792788],
              [-38.491265899666146, -8.144107775767575],
              [-38.49126154024183, -8.144037670136353],
              [-38.49125472821661, -8.143968918208476],
              [-38.491232307577761, -8.143896619095388],
              [-38.491206613614487, -8.143828657566219],
              [-38.491177021760606, -8.143756439913659],
              [-38.491144987822558, -8.143677254407169],
              [-38.491122122370754, -8.143597809037127],
              [-38.491108520398782, -8.143514757206018],
              [-38.491095186298026, -8.143435233331736],
              [-38.491076379636262, -8.143376416064932],
              [-38.491123241961198, -8.143358113092425],
              [-38.491201941240973, -8.143359297163233],
              [-38.491278485474915, -8.143341845440711],
              [-38.491350368977251, -8.143349171665699],
              [-38.491431961712983, -8.143359314049105],
              [-38.491501915813721, -8.143384999552849],
              [-38.491584045216975, -8.143401474207144],
              [-38.491670918352092, -8.143399321707015],
              [-38.491725788005326, -8.143365742329838],
              [-38.49174593005236, -8.143301456292079],
              [-38.491783394664623, -8.143249222034575],
              [-38.491826669227251, -8.143196633238704],
              [-38.491858705426075, -8.143130100786486],
              [-38.491856528566643, -8.143056741618434],
              [-38.491861348788937, -8.142977421387014],
              [-38.491858352002495, -8.142906412939993],
              [-38.4918590451136, -8.142860645175453],
              [-38.491918136354045, -8.142934527839557],
              [-38.491959439437458, -8.143005674679735],
              [-38.492007644625112, -8.143074207050008],
              [-38.492057135931667, -8.143130620477475],
              [-38.492108801712988, -8.143190292886569],
              [-38.49216610150765, -8.143245178390284],
              [-38.492235422621867, -8.143268963488847],
              [-38.492305756182532, -8.143209624595237],
              [-38.492347084560244, -8.143188691452039],
              [-38.492416772644376, -8.143209401615783],
              [-38.492511433703733, -8.143222002322263],
              [-38.492600212026552, -8.143220666069046],
              [-38.492687183987158, -8.143212091390756],
              [-38.492769600536924, -8.143216807423988],
              [-38.492854207262511, -8.143212300087752],
              [-38.492930573965268, -8.143191591558987],
              [-38.493006940205944, -8.14317124482222],
              [-38.493084675777666, -8.14314474905416],
              [-38.493159400801041, -8.143130189157228],
              [-38.493224505534243, -8.143114260458674],
              [-38.493280984847281, -8.143099858664492],
              [-38.493358157558475, -8.143087834437335],
              [-38.493437314551358, -8.143085943279935],
              [-38.49351811365073, -8.143077541621633],
              [-38.49359411868447, -8.143055656638852],
              [-38.493669216203095, -8.143033589605682],
              [-38.493746406048672, -8.143007907092663],
              [-38.493824674603481, -8.142990637933122],
              [-38.493897684082604, -8.142968749156267],
              [-38.493969516905928, -8.142944145343005],
              [-38.494034996940627, -8.142918448176291],
              [-38.494094318335357, -8.142881617743326],
              [-38.494154636865446, -8.14284587396584],
              [-38.494206865879462, -8.142818622576263],
              [-38.494258551040417, -8.142790737338721],
              [-38.494344464929739, -8.142757196261499],
              [-38.494401144428217, -8.142727869995481],
              [-38.494462182858342, -8.142697101918952],
              [-38.494517315903032, -8.142670396814301],
              [-38.494573369604019, -8.142633381333535],
              [-38.49462579167831, -8.142596904034898],
              [-38.494672502276131, -8.142554630699234],
              [-38.49472465779364, -8.142513540008741],
              [-38.49477299583058, -8.142476424442902],
              [-38.49481743672488, -8.142434419619681],
              [-38.494860250716791, -8.142386804749767],
              [-38.494902980838631, -8.142333672205293],
              [-38.494935909354858, -8.142278989780024],
              [-38.494957204937393, -8.142235418462553],
              [-38.495010610475802, -8.142210881980851],
              [-38.495054858212882, -8.142178102980164],
              [-38.495069347818088, -8.142132895056728],
              [-38.495033356249039, -8.142096579085738],
              [-38.49498945811861, -8.142067670332017],
              [-38.494968451293445, -8.142025674525717],
              [-38.494965983296964, -8.141966877728558],
              [-38.494966052645871, -8.141911521259205],
              [-38.494983020208245, -8.141844698419225],
              [-38.494999418424378, -8.141797593381613],
              [-38.49499234693571, -8.141718077375216],
              [-38.494976607464871, -8.141674098179646],
              [-38.49495251403053, -8.14163236988945],
              [-38.494919552252512, -8.141568741209012],
              [-38.494888301235406, -8.141516240236903],
              [-38.494846645993903, -8.141436048040653],
              [-38.494803043842104, -8.14138832564635],
              [-38.494753979330049, -8.141353350167147],
              [-38.494702107888628, -8.141312763172273],
              [-38.494653135188365, -8.14127697372906],
              [-38.494605711875927, -8.141236211355203],
              [-38.494561011085509, -8.141195995076529],
              [-38.494522302121219, -8.141155153089469],
              [-38.494465800395282, -8.141115283917836],
              [-38.494415993201983, -8.141093513451525],
              [-38.494390000770757, -8.141046084299093],
              [-38.494358693573531, -8.14096608584866],
              [-38.494303940402389, -8.140907223938482],
              [-38.494254055178573, -8.140875322746076],
              [-38.494216429651679, -8.140839185580237],
              [-38.494159298037061, -8.140794973894854],
              [-38.494107061253331, -8.140756285865157],
              [-38.494040018155751, -8.140725810501918],
              [-38.493971511916875, -8.140703926233442],
              [-38.49391553723725, -8.140678168141408],
              [-38.493876000532047, -8.14064600844743],
              [-38.493805099203769, -8.140579709294508],
              [-38.493789518508926, -8.140554001538341],
              [-38.493762157663411, -8.140512088209713],
              [-38.493738976920319, -8.140466562015819],
              [-38.493743682997547, -8.140405512864964],
              [-38.493736137912911, -8.140341915787861],
              [-38.493716509248692, -8.140286896574109],
              [-38.493692427721861, -8.140235851690502],
              [-38.493650536548493, -8.140199256899914],
              [-38.493595742915979, -8.140172776626269],
              [-38.493538947516882, -8.140150092827886],
              [-38.493498416255022, -8.140114856496936],
              [-38.493434806431196, -8.140097319942361],
              [-38.493382839030041, -8.140133345412675],
              [-38.493325607931531, -8.140168459789404],
              [-38.493258962023646, -8.140183210737842],
              [-38.49319646221732, -8.14022139388428],
              [-38.493140685617284, -8.140254791462814],
              [-38.493078379388955, -8.140283386933023],
              [-38.493011196324304, -8.140292167345113],
              [-38.492946943126739, -8.140280961541398],
              [-38.492898587582879, -8.140259916326643],
              [-38.492859484845717, -8.140243676610877],
              [-38.492880513439232, -8.140195944285718],
              [-38.492904072518989, -8.140157079401973],
              [-38.492937169679124, -8.140112708862201],
              [-38.492944428086389, -8.140043250889754],
              [-38.492920169677056, -8.139988587670773],
              [-38.492894371207903, -8.139931480327149],
              [-38.492857480948949, -8.139888288718685],
              [-38.492817525925979, -8.139827997876505],
              [-38.492778920559388, -8.139777206162879],
              [-38.492727514198734, -8.139728026590186],
              [-38.492659482672572, -8.139689770918803],
              [-38.492593077898334, -8.139657215736792],
              [-38.492524689567666, -8.13961398474572],
              [-38.492457298499488, -8.139571749960863],
              [-38.492393533685011, -8.139533228224391],
              [-38.492323779708485, -8.139493251762007],
              [-38.492257389232087, -8.139449390063461],
              [-38.4921972551357, -8.139411053741059],
              [-38.492128222939549, -8.139374605772392],
              [-38.492063280082263, -8.139334544832654],
              [-38.492021374913406, -8.139309256351972],
              [-38.491957961424205, -8.139280141984425],
              [-38.491906444513546, -8.13924679125345],
              [-38.49184294813513, -8.139211435587045],
              [-38.491787442948471, -8.139173557258971],
              [-38.491755273623063, -8.139129919208166],
              [-38.49171983158962, -8.13909016648933],
              [-38.49168812161323, -8.139042187319609],
              [-38.491646150354988, -8.138997361119356],
              [-38.491569720092002, -8.138925084867616],
              [-38.491516466949278, -8.138900958007069],
              [-38.491578903053203, -8.138841247492463],
              [-38.491623967612, -8.138808469877279],
              [-38.491655623029793, -8.138755685613589],
              [-38.491655346931239, -8.138686670500498],
              [-38.49165622727385, -8.138636380369286],
              [-38.491663739592063, -8.138581666397071],
              [-38.491653911291749, -8.138529372922198],
              [-38.491636633155025, -8.138482225878979],
              [-38.491600292356225, -8.138435416782722],
              [-38.49157382762894, -8.138403001891382],
              [-38.491517880688356, -8.138355625535191],
              [-38.491477622787734, -8.138319846652115],
              [-38.491437997335638, -8.13828642030556],
              [-38.49138775494329, -8.138250267105594],
              [-38.491352558787639, -8.138159590272759],
              [-38.491337010850344, -8.138108103696512],
              [-38.49132072658503, -8.138064666412905],
              [-38.491325594443289, -8.138019537032614],
              [-38.491343725439357, -8.137966193142049],
              [-38.491347884567439, -8.137907223739409],
              [-38.491362298795444, -8.137849985763111],
              [-38.4913915765189, -8.137811128123937],
              [-38.491441786251286, -8.137801150831894],
              [-38.491503256533782, -8.137787388651819],
              [-38.491554543784396, -8.137786729236065],
              [-38.491615256189412, -8.137798111707474],
              [-38.491668885852043, -8.13781147524759],
              [-38.491721064073872, -8.137824113349739],
              [-38.49177993357754, -8.137857292390201],
              [-38.491871883524816, -8.137859306911947],
              [-38.491948132291547, -8.137787312149108],
              [-38.491904163946352, -8.137670574247947],
              [-38.491966628248896, -8.137660340861563],
              [-38.492014459419273, -8.137665013724476],
              [-38.492066102798319, -8.137669872252442],
              [-38.492112239708639, -8.137650392336504],
              [-38.492172131510564, -8.137592578018216],
              [-38.492211805455639, -8.137515381717002],
              [-38.492218492292579, -8.137467360132305],
              [-38.492208654144932, -8.137422845513914],
              [-38.4921930040733, -8.137380313571271],
              [-38.492145956393777, -8.137330144419913],
              [-38.492099425113608, -8.13730240797306],
              [-38.492048703284844, -8.137286605928255],
              [-38.49198272228049, -8.137278292300108],
              [-38.491929061571682, -8.137289622125838],
              [-38.491880456414222, -8.137322938071597],
              [-38.49183548350387, -8.137355173132329],
              [-38.491792857215948, -8.137398084443454],
              [-38.491775986406211, -8.137459932412682],
              [-38.491760128445684, -8.137510384712956],
              [-38.491685150021354, -8.137510743156446],
              [-38.491639889795081, -8.137482827344686],
              [-38.491589365471334, -8.137454362228683],
              [-38.491545347564525, -8.137449060927429],
              [-38.491447733233116, -8.137476074293421],
              [-38.491427949339325, -8.137472521902751],
              [-38.491379405597762, -8.137457084309023],
              [-38.491337878239008, -8.137420308856752],
              [-38.491317781793093, -8.13737668589601],
              [-38.491283897106854, -8.137325809519036],
              [-38.491248176678845, -8.137291031271463],
              [-38.491198751372842, -8.137254879078254],
              [-38.491155220103856, -8.137223618662603],
              [-38.491111335795104, -8.137184398037066],
              [-38.491106711551126, -8.137180321910662],
              [-38.491061748143267, -8.137133049713155],
              [-38.491021309541772, -8.137096727854994],
              [-38.490976303806868, -8.137083013234484],
              [-38.490916239722296, -8.137061500879424],
              [-38.490854620987882, -8.137049122204663],
              [-38.490773108780232, -8.137048115518299],
              [-38.490706304852168, -8.137044594603335],
              [-38.490632171572088, -8.137022702761787],
              [-38.490588621755705, -8.13700618591764],
              [-38.49057129262517, -8.136999561202247],
              [-38.490512065633737, -8.136962130313549],
              [-38.490454572006421, -8.136917827250338],
              [-38.490404693417958, -8.136881312590912],
              [-38.490346475123587, -8.136835832730608],
              [-38.490286425172314, -8.136803194726934],
              [-38.490222014515766, -8.136773445702103],
              [-38.490155972259146, -8.136741885583094],
              [-38.490112869038036, -8.136731158169409],
              [-38.490100981561689, -8.136728248790355],
              [-38.49003892631648, -8.136702211219875],
              [-38.489980757808418, -8.136689203585304],
              [-38.489910847690723, -8.136701507716509],
              [-38.489844627536087, -8.136738871629214],
              [-38.489788566057335, -8.136782399013851],
              [-38.489722718349327, -8.136812346321946],
              [-38.489709909995383, -8.136819837736455],
              [-38.489657041533839, -8.136850705854471],
              [-38.489632697918175, -8.136863881241167],
              [-38.489591005051537, -8.136886351368496],
              [-38.489523967318249, -8.136924166457016],
              [-38.489457372901008, -8.136970303659632],
              [-38.489395949843882, -8.137018527742317],
              [-38.489344971058621, -8.137062423255554],
              [-38.489294523725299, -8.137116721390569],
              [-38.489250871688277, -8.137180977763382],
              [-38.48925032568124, -8.137182062498917],
              [-38.489217563879535, -8.137248232047565],
              [-38.489183341464972, -8.137320912287798],
              [-38.489165184105673, -8.137394879052239],
              [-38.48914441508078, -8.137452470749626],
              [-38.489116193565259, -8.137517289219186],
              [-38.489090811642249, -8.137561850048002],
              [-38.489043064092847, -8.137562875413838],
              [-38.489001804455455, -8.137529899048628],
              [-38.488951609720452, -8.137456389020533],
              [-38.488916718593345, -8.137412023729341],
              [-38.488861513080963, -8.137352979679418],
              [-38.488800692534504, -8.137355435798098],
              [-38.488754559534812, -8.137371749521613],
              [-38.488710944533196, -8.13740669948446],
              [-38.488701439218914, -8.137457973704517],
              [-38.488723417630574, -8.137520503577388],
              [-38.488754939052257, -8.137573638470176],
              [-38.488788091744922, -8.137628855805788],
              [-38.488812344527439, -8.137687408656722],
              [-38.48884776998127, -8.137739915293601],
              [-38.488910775881784, -8.137732125263357],
              [-38.488959926520209, -8.13769835811862],
              [-38.489022466405608, -8.137700155377878],
              [-38.489070164358999, -8.137738295594211],
              [-38.489101137887204, -8.137794052877156],
              [-38.489133110147598, -8.137849630510607],
              [-38.489155549494839, -8.137906643382559],
              [-38.489172263549534, -8.137968895259426],
              [-38.489220426291915, -8.137998443121255],
              [-38.489285175801129, -8.137975730675784],
              [-38.489322902373239, -8.138003185012304],
              [-38.489345543340924, -8.138044369058786],
              [-38.489383755812348, -8.138118225789649],
              [-38.48939448637848, -8.138174590768568],
              [-38.489409752472902, -8.138233403648083],
              [-38.489374097794588, -8.138290162575702],
              [-38.489362552182868, -8.138303987190877],
              [-38.489328369891922, -8.138344918894671],
              [-38.489298279452427, -8.138380247726685],
              [-38.489282916126484, -8.138398228323057],
              [-38.489253606777282, -8.138461869554932],
              [-38.489245539194151, -8.138524723429297],
              [-38.489245086067704, -8.138595817994522],
              [-38.489247892844354, -8.138673157834821],
              [-38.489247160640524, -8.138749588706352],
              [-38.489258045824045, -8.13882721006035],
              [-38.48928437768506, -8.138892730293197],
              [-38.489307460757644, -8.138943231436656],
              [-38.489332092580895, -8.138989302386927],
              [-38.48935643582832, -8.139048217132343],
              [-38.489385470966646, -8.139129479376155],
              [-38.489409053577312, -8.139215619162],
              [-38.48942118659663, -8.13931142289807],
              [-38.489439952090741, -8.139402441025013],
              [-38.489457808720594, -8.139494362526841],
              [-38.489478023156124, -8.139588096028458],
              [-38.489494150310016, -8.139683814331391],
              [-38.489518087014076, -8.139777100251214],
              [-38.489546480064412, -8.139863698336304],
              [-38.489580157638009, -8.139934745348048],
              [-38.489602883433733, -8.139980723437771],
              [-38.489622070127268, -8.1400258830121],
              [-38.489640053882091, -8.140089131438749],
              [-38.489663417162589, -8.140133482194049],
              [-38.489696096848533, -8.140204075684627],
              [-38.489724033350925, -8.140292844029677],
              [-38.489742544026285, -8.140370113147652],
              [-38.489754249605419, -8.140445112418552],
              [-38.489764881709341, -8.140507627988571],
              [-38.489720867740033, -8.140570708055092],
              [-38.489661035131164, -8.140581125274943],
              [-38.489610340949767, -8.140543252699155],
              [-38.489566197653602, -8.140493358259656],
              [-38.489533402348691, -8.140442392659468],
              [-38.489490167488356, -8.140391956643743],
              [-38.489451103263946, -8.140345415295929],
              [-38.489411127884146, -8.140301586353658],
              [-38.489366998269723, -8.140240928145676],
              [-38.489318332205507, -8.140178545644094],
              [-38.489270751075829, -8.140119601671119],
              [-38.489222529623774, -8.140064546315989],
              [-38.4891644234662, -8.140001971020933],
              [-38.489121452765396, -8.139958047833735],
              [-38.489074754203401, -8.139919004350563],
              [-38.489000223845686, -8.139851976231432],
              [-38.488937462781692, -8.139809565805015],
              [-38.488879432573462, -8.139758749304836],
              [-38.488816045072227, -8.139709282831129],
              [-38.48875492128419, -8.139664251336916],
              [-38.488697064408072, -8.139619947561808],
              [-38.488617860488773, -8.139587827894456],
              [-38.48853080608172, -8.139590431821102],
              [-38.488463220965919, -8.139558417209052],
              [-38.48842008559852, -8.139501287782467],
              [-38.488355959965631, -8.139461317752421],
              [-38.488291926266221, -8.13942044331062],
              [-38.488242064353656, -8.139370722440191],
              [-38.48818502039898, -8.139329675886879],
              [-38.488108445886837, -8.139300001638038],
              [-38.488019761888189, -8.139299256687968],
              [-38.487932152460395, -8.139310090904432],
              [-38.48785234544976, -8.139324100754042],
              [-38.487771989433192, -8.139341547063697],
              [-38.487698147433441, -8.139376006512579],
              [-38.487628834857205, -8.139417707805592],
              [-38.487565688941984, -8.139464029908465],
              [-38.487512433778548, -8.139513078035797],
              [-38.487448011270359, -8.139563921097302],
              [-38.487389225043081, -8.139608168280695],
              [-38.487336868711374, -8.139664182304921],
              [-38.487283699666392, -8.139716848578892],
              [-38.487230524511489, -8.139774308782329],
              [-38.487183147634752, -8.139840550123363],
              [-38.487131227390591, -8.139910494243326],
              [-38.487073784489993, -8.139969034454412],
              [-38.487020164728477, -8.140019167469239],
              [-38.486966169088539, -8.140079340149635],
              [-38.486915425483033, -8.14015190882537],
              [-38.486870030890756, -8.140229640004197],
              [-38.486816937056119, -8.140294426841606],
              [-38.486764474584156, -8.14036238028126],
              [-38.486712914295261, -8.140434676538739],
              [-38.486666253416821, -8.140508788017545],
              [-38.486622762660069, -8.14058833060432],
              [-38.486578823895229, -8.14066325957789],
              [-38.486530159510117, -8.140742433803561],
              [-38.486479951044501, -8.140822329683328],
              [-38.486429920417336, -8.140905120237191],
              [-38.486373097585776, -8.140975510298444],
              [-38.486297797851883, -8.141013857046751],
              [-38.486225123719009, -8.141057543757027],
              [-38.4861661440669, -8.14111083567121],
              [-38.486100455501145, -8.141157606557222],
              [-38.486015737691211, -8.141178393685813],
              [-38.485942700984943, -8.141221627623693],
              [-38.48588770075088, -8.141286321454102],
              [-38.485836778132054, -8.141356628448808],
              [-38.485807079538326, -8.141440168272238],
              [-38.485786278189046, -8.141522543487461],
              [-38.485712572176574, -8.141592278897216],
              [-38.485648881296058, -8.141638419069073],
              [-38.485611499358285, -8.141696532194594],
              [-38.485581308219992, -8.141739187175412],
              [-38.485540384842089, -8.141798290775323],
              [-38.485499802264819, -8.141874851991684],
              [-38.485458213316512, -8.141957562647018],
              [-38.485407011402366, -8.142033205875533],
              [-38.485356796887864, -8.14211753371646],
              [-38.485306860099037, -8.142197610669074],
              [-38.485264911447025, -8.142277607290131],
              [-38.485243190190232, -8.142369388280926],
              [-38.485248780811133, -8.142469706364732],
              [-38.485269268413191, -8.142562083581861],
              [-38.485288293833541, -8.142662147343302],
              [-38.485320402860786, -8.142752639805588],
              [-38.485351780979776, -8.142846839861138],
              [-38.48537371637385, -8.142942836979465],
              [-38.48540257018459, -8.143023466068723],
              [-38.48544414301923, -8.143095699265313],
              [-38.485474506728124, -8.143131285290796],
              [-38.485498246985586, -8.143164511174065],
              [-38.485518439694772, -8.143203521472275],
              [-38.485536015333231, -8.143230498369695],
              [-38.485564287692455, -8.14326888574325],
              [-38.485513740631561, -8.14325805792455],
              [-38.48543870888308, -8.14322829464788],
              [-38.485355711606736, -8.14318088316792],
              [-38.485298946591278, -8.14313449990213],
              [-38.48523703696231, -8.14306504490896],
              [-38.485206346229042, -8.143001237506171],
              [-38.485168396409712, -8.142935250053601],
              [-38.485100702138332, -8.142917435655459],
              [-38.485017541737641, -8.142926737151551],
              [-38.484960298361507, -8.142970624080036],
              [-38.484914858168715, -8.143012445585827],
              [-38.484853265161711, -8.14305044761509],
              [-38.484784602572297, -8.14308002864956],
              [-38.48468155778874, -8.143164198880642],
              [-38.484650371156768, -8.143275324481699],
              [-38.48464085797653, -8.143332206550031],
              [-38.484598053146691, -8.14337167961558],
              [-38.484555787049914, -8.143415766398842],
              [-38.484531421592166, -8.14344558453355],
              [-38.484528204415021, -8.143405962586186],
              [-38.484457280944255, -8.143286928481231],
              [-38.484431281616004, -8.143245739919934],
              [-38.484372409363225, -8.143214640198153],
              [-38.484321262000236, -8.143176404594238],
              [-38.484294659219721, -8.143110250578983],
              [-38.484253513960702, -8.14305873126834],
              [-38.484240529515105, -8.142990785516803],
              [-38.484216298555019, -8.142915498885197],
              [-38.484211397689315, -8.142843854882038],
              [-38.484219856818491, -8.142759474157724],
              [-38.484223045190433, -8.142679699774989],
              [-38.484218521652359, -8.142596930686627],
              [-38.484220805529397, -8.142514622505383],
              [-38.484239695464808, -8.142436586652249],
              [-38.484247771576499, -8.142367763137591],
              [-38.484259304009335, -8.142293516908621],
              [-38.484248955125935, -8.142223132307306],
              [-38.484235531299106, -8.142143879528733],
              [-38.484217744000844, -8.142069596052139],
              [-38.484225376076012, -8.141993083574318],
              [-38.484235368132417, -8.141916664545603],
              [-38.484235560393635, -8.14183733861157],
              [-38.48420515726864, -8.141761772783473],
              [-38.484177558513181, -8.141693718010957],
              [-38.484135609292935, -8.14163215752979],
              [-38.48407803485491, -8.141580165075263],
              [-38.484016085267562, -8.141542277524996],
              [-38.483963848519991, -8.141504311858739],
              [-38.483903445107224, -8.141464074512339],
              [-38.48383922704101, -8.141425550891405],
              [-38.483763534645092, -8.141415866776837],
              [-38.483824678020738, -8.141374427163207],
              [-38.483874186811676, -8.141345183735385],
              [-38.483929239577797, -8.141310610694724],
              [-38.48398247004517, -8.141281371982899],
              [-38.484020127494695, -8.141220907622989],
              [-38.484036268158974, -8.141163219921674],
              [-38.484042794991367, -8.141099188381084],
              [-38.484055693518414, -8.141021416271242],
              [-38.484064952906131, -8.140950061610306],
              [-38.484105266055224, -8.141013248148537],
              [-38.48418477078188, -8.141093941658594],
              [-38.484216011298606, -8.141153498592091],
              [-38.484260510807019, -8.141208278422464],
              [-38.484314823988207, -8.141255110958772],
              [-38.484389592796312, -8.14127718568613],
              [-38.484475459394652, -8.14128091278566],
              [-38.484551818656371, -8.141265994737676],
              [-38.484636530297642, -8.141250092307013],
              [-38.484715880548478, -8.141238705642049],
              [-38.484790354022984, -8.141207775194617],
              [-38.48485685079342, -8.141167970340922],
              [-38.484912824939428, -8.141122634570053],
              [-38.484965074725402, -8.141079374456956],
              [-38.485015239611549, -8.141033850398248],
              [-38.485063491208386, -8.140993841463384],
              [-38.485113473256511, -8.1409493121285],
              [-38.485161369942659, -8.140902880655625],
              [-38.48519685325391, -8.140838795345454],
              [-38.485231698025331, -8.140777151419384],
              [-38.485254917856118, -8.140720196244956],
              [-38.485264597399905, -8.140675073085877],
              [-38.485245384151483, -8.140579983928488],
              [-38.485233043776269, -8.140505164677725],
              [-38.485217782392873, -8.140443095422047],
              [-38.485260250188823, -8.140382908381808],
              [-38.485322653458248, -8.14034979168078],
              [-38.485385055678883, -8.140317489034073],
              [-38.485434095479725, -8.140299913083146],
              [-38.485502568148348, -8.140276482382482],
              [-38.485568687065097, -8.140248073840823],
              [-38.485623574153252, -8.140200837092959],
              [-38.4856689543209, -8.140134593424369],
              [-38.485709342666333, -8.140067800717144],
              [-38.485743382100239, -8.139996929635368],
              [-38.48576817701165, -8.139914830824161],
              [-38.485785430168526, -8.139838692278097],
              [-38.485795961528765, -8.139766163290719],
              [-38.485832706221487, -8.139709315648199],
              [-38.485870080315074, -8.139657172290708],
              [-38.485908373540937, -8.139596075366656],
              [-38.485917303504031, -8.139569222493288],
              [-38.485931882352773, -8.139525914546727],
              [-38.48594129984442, -8.139472650370893],
              [-38.485945322779159, -8.139449952067018],
              [-38.485968199164425, -8.139377438696838],
              [-38.485994155004988, -8.139310265876906],
              [-38.486023298711487, -8.139234594621763],
              [-38.486040912452921, -8.139160356001353],
              [-38.486038466964331, -8.139084916122474],
              [-38.486021121878132, -8.139019587987528],
              [-38.48603700516751, -8.138949869765149],
              [-38.486048434376016, -8.138885030301381],
              [-38.486070654780228, -8.138828616507309],
              [-38.486075555856459, -8.138757889415732],
              [-38.486109658674962, -8.138708364988878],
              [-38.486108847880786, -8.138632294013954],
              [-38.486055803894658, -8.138586820103219],
              [-38.485993519630576, -8.138598047759624],
              [-38.485922330330787, -8.138616590729587],
              [-38.485857187006367, -8.138591995841274],
              [-38.485793574596414, -8.138576990769675],
              [-38.485748113436841, -8.138564541319917],
              [-38.485676147671327, -8.138551334689634],
              [-38.485601284579133, -8.138532335463804],
              [-38.485547594991672, -8.138494910881155],
              [-38.485506802241474, -8.138451894618251],
              [-38.485430682443933, -8.138421858654269],
              [-38.485355598024938, -8.138434064966669],
              [-38.485282682903396, -8.138453510155589],
              [-38.48522962163554, -8.138492789431648],
              [-38.485176733588936, -8.138538581446987],
              [-38.485133297866, -8.138574978452702],
              [-38.485081198800721, -8.138571565692008],
              [-38.485045471793029, -8.138542304476424],
              [-38.485000771726071, -8.138502539475637],
              [-38.48493335039732, -8.138484815889507],
              [-38.484876161164053, -8.138486823754493],
              [-38.484821699144959, -8.13848566925866],
              [-38.484744267448711, -8.138487832333023],
              [-38.484664039262128, -8.138476333628239],
              [-38.48458307692637, -8.138471075147832],
              [-38.48450082667032, -8.138479201877514],
              [-38.484445738037678, -8.138471172202387],
              [-38.484381017595616, -8.13847090914415],
              [-38.484311503222095, -8.138457343580626],
              [-38.484245450463348, -8.138434194514636],
              [-38.484173678247053, -8.138411671337243],
              [-38.484096609658614, -8.138413834749903],
              [-38.484018439998955, -8.138425222828131],
              [-38.48395379008744, -8.138440788848861],
              [-38.483900093501887, -8.138408972058169],
              [-38.483852750523312, -8.138377525141712],
              [-38.483834593731622, -8.138308396929562],
              [-38.483832147609881, -8.138233771124789],
              [-38.483830628774051, -8.138143860151807],
              [-38.483826015231372, -8.138060548223875],
              [-38.483824315660982, -8.137970003858328],
              [-38.483819346786326, -8.137880631210731],
              [-38.483832159997974, -8.137798517299434],
              [-38.483849417103492, -8.137719484393452],
              [-38.483864197889851, -8.137660347725937],
              [-38.483855103865189, -8.137602537498731],
              [-38.483797964884793, -8.137565379672923],
              [-38.483714185460165, -8.13756274055199],
              [-38.483634387389216, -8.137569965715999],
              [-38.483565032163504, -8.137574038329152],
              [-38.483493531532552, -8.137552239001627],
              [-38.483424210483214, -8.137529628367897],
              [-38.483344060103725, -8.137528441014165],
              [-38.483275761473983, -8.137558022315167],
              [-38.483220961573657, -8.1376081533238],
              [-38.483163168729291, -8.137656290577649],
              [-38.483116613650346, -8.137718462142542],
              [-38.483063606198392, -8.137786504853306],
              [-38.483011773085536, -8.137858981185797],
              [-38.482962657669681, -8.137935712201497],
              [-38.482919264942353, -8.138009284683827],
              [-38.482891739478596, -8.138096806928692],
              [-38.482873284499917, -8.138189586919514],
              [-38.482846529726544, -8.138242376672414],
              [-38.482792177790479, -8.138226207058347],
              [-38.482734216198203, -8.138193480172369],
              [-38.482674003524657, -8.138146549488811],
              [-38.48259743678063, -8.138111356691594],
              [-38.482521666575522, -8.138092084400897],
              [-38.482445960803155, -8.138093344722432],
              [-38.482369615910905, -8.138097498670774],
              [-38.482294476135024, -8.138082116566428],
              [-38.482210052866428, -8.138085988833376],
              [-38.482131365551531, -8.138076391087909],
              [-38.482056276838321, -8.138091943496836],
              [-38.481977846044835, -8.1380946474839],
              [-38.481937936246275, -8.138071260038526],
              [-38.481869633461251, -8.138033273645069],
              [-38.481802949016632, -8.138007409836646],
              [-38.481728262914054, -8.137992209112063],
              [-38.481656564627819, -8.137982982000631],
              [-38.481590722475183, -8.138008043553866],
              [-38.4815400081105, -8.138057365466583],
              [-38.481484665048484, -8.138106138753155],
              [-38.481435773429602, -8.13814976451957],
              [-38.481384153515833, -8.138197366671539],
              [-38.481335420969891, -8.13825844980917],
              [-38.481294408354728, -8.13831619608488],
              [-38.481278877256969, -8.138393783824464],
              [-38.481295491524428, -8.138462096088606],
              [-38.481299454128845, -8.13855707545199],
              [-38.48129173246447, -8.138632321419145],
              [-38.481292983414072, -8.138718342598885],
              [-38.481313028895563, -8.13880076971189],
              [-38.481357048529489, -8.138875267687903],
              [-38.481412593482062, -8.138951951217226],
              [-38.481464324152995, -8.139030077097662],
              [-38.481517342054651, -8.139095450923604],
              [-38.481569094275351, -8.139156843252207],
              [-38.481616045454231, -8.139210541051021],
              [-38.481668172769112, -8.13926261732029],
              [-38.481732384229979, -8.139305844735825],
              [-38.481779792681216, -8.13935692000142],
              [-38.481832285102165, -8.139407459038249],
              [-38.481871087156385, -8.139445317362327],
              [-38.481820985144672, -8.139441816274468],
              [-38.481738212818307, -8.139432213225646],
              [-38.48165165246747, -8.139403610464621],
              [-38.481569904002491, -8.139374290214683],
              [-38.481484603211513, -8.1393544628438],
              [-38.481397392834531, -8.139337256116818],
              [-38.481308697065408, -8.139345735761125],
              [-38.48123713518553, -8.139371423097234],
              [-38.481220969420654, -8.139377824519439],
              [-38.48116538837737, -8.139399733283552],
              [-38.481128333181559, -8.139415243637465],
              [-38.481091913623338, -8.139430573896623],
              [-38.481023614055715, -8.139460516591821],
              [-38.480960199545862, -8.139503400112385],
              [-38.480905218251365, -8.139552987827773],
              [-38.48085378510725, -8.139596338902674],
              [-38.480810228550446, -8.139655709991848],
              [-38.480776646596681, -8.139723144029926],
              [-38.480755504434278, -8.139787518611969],
              [-38.480758673711541, -8.139864044805583],
              [-38.480784278499016, -8.139929202769517],
              [-38.480817961242529, -8.139995004221589],
              [-38.480866974441348, -8.140068694567553],
              [-38.480901741823082, -8.140138115468392],
              [-38.480936411224398, -8.140213144247285],
              [-38.480951566954239, -8.140286248596494],
              [-38.480879542812971, -8.14024780500813],
              [-38.480820913094341, -8.140240403466684],
              [-38.480765828171847, -8.14022938838125],
              [-38.480703118348316, -8.140218363538144],
              [-38.480652970457065, -8.140179947885809],
              [-38.480598269029201, -8.140153104221376],
              [-38.480536020777642, -8.140136110131564],
              [-38.480484560142706, -8.14013025539224],
              [-38.4804366285708, -8.140132998072959],
              [-38.480383429154379, -8.140138085751715],
              [-38.480336767116412, -8.140141825013641],
              [-38.480283835586164, -8.140150350186401],
              [-38.480237253475998, -8.140162501546673],
              [-38.480175596455872, -8.140179789351345],
              [-38.480110295696086, -8.140207022167662],
              [-38.480041986716394, -8.140244110355662],
              [-38.479977397654551, -8.140282560070348],
              [-38.479927601611763, -8.140323290011846],
              [-38.479878706402005, -8.140369357750117],
              [-38.479828749097237, -8.140394348877779],
              [-38.47975966325393, -8.140400320638784],
              [-38.479696085831144, -8.140428550560669],
              [-38.479627503643655, -8.140466271485595],
              [-38.479559290722527, -8.140499198932046],
              [-38.479518117904988, -8.140540030322224],
              [-38.47945991436989, -8.140554066178316],
              [-38.479410269744754, -8.140547761370135],
              [-38.47935990968503, -8.140533134084],
              [-38.479305282026573, -8.140519496288201],
              [-38.479314708825406, -8.14045971981469],
              [-38.479348623224759, -8.140416256040666],
              [-38.479318087606629, -8.140373795049845],
              [-38.479245003444198, -8.140383108308505],
              [-38.479158748242604, -8.140399369439963],
              [-38.479098713256107, -8.140425794775712],
              [-38.479074774128556, -8.140476778813003],
              [-38.479059095195154, -8.140527863896988],
              [-38.47901638690437, -8.140562723452438],
              [-38.478966429177518, -8.140587985823464],
              [-38.47890611902757, -8.140616581615111],
              [-38.478864844373142, -8.140665915260632],
              [-38.478827845966116, -8.14070756599749],
              [-38.478797214155989, -8.140739546573402],
              [-38.47876667323068, -8.140771436811582],
              [-38.478720146093096, -8.140740985323518],
              [-38.478662377643708, -8.140699303390944],
              [-38.478630289588004, -8.140664076492676],
              [-38.47866896320339, -8.14059049848545],
              [-38.47865607847227, -8.140446211527008],
              [-38.478597470307925, -8.140422166585461],
              [-38.478516757829837, -8.140434093015013],
              [-38.47843222177967, -8.140454607425808],
              [-38.478356512556331, -8.140458218695519],
              [-38.478299450823464, -8.140431643051839],
              [-38.478257173926281, -8.140413679305203],
              [-38.478191866813646, -8.140445705732475],
              [-38.478145687208738, -8.140497475192989],
              [-38.478133153945585, -8.140572714870695],
              [-38.47813695508578, -8.140651593606822],
              [-38.478124335373487, -8.140723486460635],
              [-38.478086334524463, -8.140768120741265],
              [-38.478041509901352, -8.140824957220616],
              [-38.478002214090644, -8.140888222869522],
              [-38.477973260276293, -8.14095620529597],
              [-38.477952736611421, -8.141033243724006],
              [-38.477952008442735, -8.14110488050208],
              [-38.477964716762735, -8.141174906425091],
              [-38.47797433798921, -8.141245561540506],
              [-38.47797842605452, -8.141313134185513],
              [-38.477985325737947, -8.14138251947824],
              [-38.477976710867985, -8.141446005466483],
              [-38.477948224689008, -8.141503315192193],
              [-38.47790851955574, -8.141532118217464],
              [-38.477859273301291, -8.141568597359488],
              [-38.477815045676238, -8.141584912230405],
              [-38.477767074563808, -8.141618046289038],
              [-38.477729818401706, -8.141648299671475],
              [-38.477693649400237, -8.14168018257849],
              [-38.477620593166385, -8.141737796745243],
              [-38.477595573283985, -8.141781995411272],
              [-38.477606304826416, -8.141836370661547],
              [-38.477638925206051, -8.141881095747298],
              [-38.477671197032961, -8.141914604377472],
              [-38.477705550550851, -8.141952819169312],
              [-38.477728537173832, -8.142006667472378],
              [-38.477733103788388, -8.142055155432343],
              [-38.477726962816014, -8.142100825616875],
              [-38.477695301251075, -8.142156774458357],
              [-38.47769010755011, -8.142101863624463],
              [-38.477628495916626, -8.142013774901073],
              [-38.47757617414895, -8.141971828465055],
              [-38.47751794382323, -8.141936296486476],
              [-38.477474788743955, -8.14189544725471],
              [-38.477455263845307, -8.141832105971645],
              [-38.477451994349181, -8.141763358510282],
              [-38.477469502599085, -8.14170178346996],
              [-38.477504411700004, -8.141661486959334],
              [-38.477549764957125, -8.141617133560173],
              [-38.47759964841206, -8.141579298502904],
              [-38.477654163034934, -8.141540112622042],
              [-38.477703495388369, -8.141507251682649],
              [-38.477758458583772, -8.141472136687932],
              [-38.477806172824884, -8.141427062680181],
              [-38.477823502152518, -8.141363407001093],
              [-38.47779978889605, -8.141309919575244],
              [-38.477779157119279, -8.141260144629397],
              [-38.477785322553189, -8.141195570076334],
              [-38.477812548805446, -8.141129846736854],
              [-38.477833332052292, -8.141062667882764],
              [-38.477854665433995, -8.140991238506373],
              [-38.477858475559167, -8.140922952403574],
              [-38.477867109524297, -8.140844722820814],
              [-38.477881988298492, -8.14078079262924],
              [-38.477901671815133, -8.140721662552385],
              [-38.477929513627998, -8.140671226322521],
              [-38.47795608168768, -8.140623049744217],
              [-38.477960611645962, -8.140559648953145],
              [-38.477884722615705, -8.140561903127328],
              [-38.477840371884632, -8.140603272960197],
              [-38.477791660187691, -8.140647622084096],
              [-38.477752762245601, -8.140684024077181],
              [-38.477707232145029, -8.140724849666801],
              [-38.477679526421248, -8.140740281263209],
              [-38.477638484249596, -8.140750178150501],
              [-38.477568586421178, -8.140752259067089],
              [-38.477625190812255, -8.140711447740598],
              [-38.477657459428947, -8.140677298524482],
              [-38.477687378026204, -8.140635367440053],
              [-38.477711198910562, -8.140605729935615],
              [-38.477754572242709, -8.140548077562086],
              [-38.477805107584786, -8.140497399170872],
              [-38.477856104508241, -8.140440751556397],
              [-38.477911459208158, -8.140383566827177],
              [-38.477979424031339, -8.140332006302936],
              [-38.478054642570406, -8.140286696254368],
              [-38.478125211786725, -8.140256756998893],
              [-38.478186820956402, -8.14020636414037],
              [-38.478248788519743, -8.140159589798698],
              [-38.47832706623516, -8.140135177961293],
              [-38.478415841623772, -8.14013547289743],
              [-38.478508862153333, -8.140152145018181],
              [-38.478598973874057, -8.140171979191793],
              [-38.478689257443953, -8.140199321055492],
              [-38.478782451130343, -8.140222505858002],
              [-38.478865765456284, -8.140234190598804],
              [-38.478927687599239, -8.140222511341154],
              [-38.478974874883455, -8.140233787894937],
              [-38.47903894892287, -8.140242734363783],
              [-38.479101969099368, -8.140224272612135],
              [-38.479161380446747, -8.140188710854206],
              [-38.479217240553012, -8.140161737442359],
              [-38.479285077967461, -8.14013839744781],
              [-38.479333309888233, -8.140113946884778],
              [-38.479390091363676, -8.140076391782021],
              [-38.479433618509113, -8.140039995552312],
              [-38.479474873062244, -8.140006129051605],
              [-38.479485789310317, -8.139917590843412],
              [-38.479462256277486, -8.139865008250375],
              [-38.479463864798475, -8.13981435736809],
              [-38.47949153368117, -8.139757046463064],
              [-38.479526285498821, -8.139697664244245],
              [-38.479572022415795, -8.139637029775308],
              [-38.479619281907979, -8.139592316748443],
              [-38.479668004020574, -8.139539645840575],
              [-38.479720268464916, -8.139485260881827],
              [-38.479769082840683, -8.139431414206117],
              [-38.479803123809859, -8.139360091424413],
              [-38.479828276066556, -8.139283330152805],
              [-38.479832566403914, -8.139194331181308],
              [-38.479844842726905, -8.139106880115985],
              [-38.479852588473641, -8.139013182080417],
              [-38.479859363727989, -8.138968145813214],
              [-38.479881893345592, -8.138883783233441],
              [-38.479892143139807, -8.13881921384815],
              [-38.479906823277211, -8.138768217889838],
              [-38.479918601262312, -8.138715228277151],
              [-38.479921920389089, -8.138675433780451],
              [-38.47991698565059, -8.138630653871482],
              [-38.479911051367843, -8.138586686747757],
              [-38.479892537999064, -8.13851303535964],
              [-38.479857955263121, -8.138441443781902],
              [-38.479828457651074, -8.138368411486777],
              [-38.479797969487763, -8.138289227205968],
              [-38.479776391362158, -8.138198928776237],
              [-38.479736192346223, -8.138118375278852],
              [-38.479701399419753, -8.138069034538455],
              [-38.479655066417493, -8.138028995537269],
              [-38.479599650723863, -8.137993196124732],
              [-38.479541599496692, -8.137959835524025],
              [-38.479481726649929, -8.137931266521123],
              [-38.479413134550505, -8.13790666620992],
              [-38.479341081559824, -8.137891016169091],
              [-38.479254951422206, -8.137881136977624],
              [-38.479170819678238, -8.137870174910121],
              [-38.47908531677902, -8.137866628105701],
              [-38.479012161110326, -8.137861378537801],
              [-38.478961713312849, -8.137844670720671],
              [-38.478912702331606, -8.13783999481511],
              [-38.47887788744481, -8.137807749351799],
              [-38.478814834377225, -8.13778170882197],
              [-38.478737161517685, -8.137759900751513],
              [-38.478662037066698, -8.137732849739722],
              [-38.478584354872197, -8.137718277763797],
              [-38.47850268491112, -8.137698906714572],
              [-38.478422919066972, -8.137681166224665],
              [-38.47834661555413, -8.137653390029273],
              [-38.478277765071745, -8.137618115903198],
              [-38.478203453886785, -8.137593960308134],
              [-38.478117143013478, -8.137583537938728],
              [-38.478032647352421, -8.137573298786956],
              [-38.477955784040098, -8.137557280519328],
              [-38.477872195759772, -8.137547313855949],
              [-38.477796295587694, -8.137558613202046],
              [-38.47772304255831, -8.137558519026555],
              [-38.477658113264184, -8.137579510780355],
              [-38.477613215313468, -8.137553041157123],
              [-38.477545890938799, -8.137530974818127],
              [-38.477479828240796, -8.137515965321448],
              [-38.477416680682474, -8.137492909366129],
              [-38.477403800900845, -8.137485294856612],
              [-38.477365887383421, -8.137462723614574],
              [-38.477337522276528, -8.137426777807953],
              [-38.477267706494779, -8.137365994906343],
              [-38.477222482705557, -8.137380861234584],
              [-38.477150522354137, -8.137433954200034],
              [-38.477113540982629, -8.13746257977707],
              [-38.4770700928648, -8.137508111486644],
              [-38.477004538347344, -8.137591242621269],
              [-38.476883366112361, -8.137654583661016],
              [-38.476733440456947, -8.137701425422165],
              [-38.476540359539626, -8.13777787965862],
              [-38.476429510883428, -8.137790038222523],
              [-38.476542412284161, -8.137734917768043],
              [-38.476684209677408, -8.137656317076646],
              [-38.476813673872307, -8.137568564830072],
              [-38.476856910007335, -8.137476540724037],
              [-38.476846517948147, -8.137370517994995],
              [-38.476810465435697, -8.137312853856175],
              [-38.476781185983263, -8.137282062581258],
              [-38.476703265914118, -8.137241078064026],
              [-38.476632947701546, -8.137217831851482],
              [-38.476577254107518, -8.13718664472526],
              [-38.47655349874104, -8.13716590068695],
              [-38.47652774874242, -8.137143345043869],
              [-38.476470331322815, -8.137111522521129],
              [-38.476434786889094, -8.13708207992441],
              [-38.47639815531123, -8.137051007793865],
              [-38.476336786811906, -8.136986165306878],
              [-38.476280762481224, -8.136930103511698],
              [-38.476189600296898, -8.136881051634113],
              [-38.476169546999159, -8.136875417774789],
              [-38.476090131494921, -8.13686771742759],
              [-38.476015758800699, -8.136891138937267],
              [-38.475961788616139, -8.136930868048422],
              [-38.475911193315362, -8.136958028726305],
              [-38.475864739155917, -8.136941777949099],
              [-38.475812479832832, -8.136922263416992],
              [-38.475753959255577, -8.136901203120596],
              [-38.47570589484674, -8.136866769467058],
              [-38.47565637127768, -8.136837580120469],
              [-38.47559320113357, -8.136832071483962],
              [-38.47554552145931, -8.136850823876332],
              [-38.475529756878217, -8.136967576597021],
              [-38.475547203054127, -8.137023588687518],
              [-38.475505193903864, -8.137009514447536],
              [-38.475438490220469, -8.136998935931381],
              [-38.475385856180885, -8.136988375573941],
              [-38.475327974435338, -8.136964692944472],
              [-38.475296842215087, -8.136892924584508],
              [-38.475317267580898, -8.136821855974908],
              [-38.475338680135025, -8.136759471996161],
              [-38.47536400480984, -8.136690218742929],
              [-38.475394943210638, -8.13663182693354],
              [-38.475435932334037, -8.136592623847164],
              [-38.475479272106881, -8.136560659922534],
              [-38.475524050579871, -8.136539190240995],
              [-38.475564796756679, -8.136547202585737],
              [-38.475632918387454, -8.136584194787977],
              [-38.475688248967927, -8.136615200663192],
              [-38.475748658193446, -8.136649740699232],
              [-38.475810615016755, -8.136680845561097],
              [-38.475870349563877, -8.136675766887134],
              [-38.475864166107797, -8.136614342239334],
              [-38.475841732389377, -8.136554524755525],
              [-38.475807316334169, -8.136495053617118],
              [-38.475771175860601, -8.136435399347878],
              [-38.475749292672219, -8.136371059987825],
              [-38.47574631398723, -8.136288292884755],
              [-38.475764469592733, -8.136217492669875],
              [-38.475780536267024, -8.136147594274759],
              [-38.475813744089777, -8.136089024450426],
              [-38.475868090821102, -8.136108722572642],
              [-38.475896238086627, -8.136172436846529],
              [-38.475939817373266, -8.136235537863254],
              [-38.47599367302854, -8.136283908510737],
              [-38.476045994035843, -8.136325764653613],
              [-38.476094223160345, -8.136373133064575],
              [-38.476158343812742, -8.13641554712008],
              [-38.476233103800027, -8.136443321714422],
              [-38.476316421472106, -8.136451750671963],
              [-38.476390779981479, -8.13643918328129],
              [-38.47646712865739, -8.136431864637567],
              [-38.476544108508506, -8.136427803052349],
              [-38.476623069612771, -8.136435864527368],
              [-38.476699047338236, -8.136434605623256],
              [-38.476763131264782, -8.136435411775294],
              [-38.476824948948007, -8.136433863255606],
              [-38.476890922896942, -8.136447154151167],
              [-38.476944558340151, -8.136455454309569],
              [-38.477004172762001, -8.136472988227787],
              [-38.477105900723608, -8.136494103957713],
              [-38.477168336236005, -8.136506123931774],
              [-38.477217242418966, -8.136521473198975],
              [-38.477267861432146, -8.136545960283023],
              [-38.477314126100786, -8.136568361370552],
              [-38.477363465766416, -8.136599449769395],
              [-38.477404915322396, -8.136625100912994],
              [-38.477410175986549, -8.136628273488544],
              [-38.477452253335514, -8.136659804793831],
              [-38.477520312054224, -8.136675450015646],
              [-38.477545894380597, -8.136617232037954],
              [-38.477579282957187, -8.136559114547907],
              [-38.477621633100114, -8.136520184332372],
              [-38.477634979013722, -8.136518302007122],
              [-38.477690178103039, -8.136510775035742],
              [-38.477723939472639, -8.136515250571138],
              [-38.477764689100603, -8.136520639603596],
              [-38.477819927444095, -8.136552911403083],
              [-38.477889529166909, -8.13656864899777],
              [-38.47795962747913, -8.136551372355999],
              [-38.478029809769758, -8.13653934200708],
              [-38.478095749303449, -8.136509125407409],
              [-38.478149438145834, -8.136476179510121],
              [-38.478229729781802, -8.136438112025814],
              [-38.478297467174983, -8.136421465453022],
              [-38.478360106489525, -8.136416299686877],
              [-38.478418111998174, -8.136414565126117],
              [-38.478431148847299, -8.13637089369823],
              [-38.478443036064974, -8.136303703355095],
              [-38.478468080152716, -8.136240419323133],
              [-38.478508809384579, -8.136191356337234],
              [-38.478546995127495, -8.136143737309068],
              [-38.478586463238862, -8.136087165205057],
              [-38.478619671247799, -8.13602814286735],
              [-38.478656603807991, -8.135966864013147],
              [-38.478689364833492, -8.135902504444724],
              [-38.478719640419285, -8.135864915385227],
              [-38.478764575782272, -8.135862349935607],
              [-38.478785632094016, -8.135934738302939],
              [-38.478802955087438, -8.136016167049652],
              [-38.478809302187685, -8.136091792807497],
              [-38.478835628185173, -8.136160388961569],
              [-38.478867302947208, -8.136234238173349],
              [-38.47889899127334, -8.136297595004061],
              [-38.478928064401714, -8.136347652079591],
              [-38.478962356738748, -8.136432992007609],
              [-38.479036481435735, -8.136460855735095],
              [-38.479111329958464, -8.136420248443809],
              [-38.479139590351011, -8.136396767234919],
              [-38.479200818997988, -8.136359308273756],
              [-38.479283285193759, -8.136324228266965],
              [-38.479370702850318, -8.136320903134395],
              [-38.479437698607633, -8.136316195041381],
              [-38.479516022864303, -8.136325431012486],
              [-38.479603967534317, -8.136335764711971],
              [-38.479692837660309, -8.136332350918742],
              [-38.479774005248323, -8.136318796652219],
              [-38.479843753198203, -8.136291569541054],
              [-38.479916864825618, -8.136260366848031],
              [-38.479978091754006, -8.136224174085401],
              [-38.480023616790191, -8.13618669489332],
              [-38.480083562402626, -8.136158641127439],
              [-38.480139779372188, -8.136136009683929],
              [-38.480207338957648, -8.13611637765033],
              [-38.480271361002991, -8.136094751142149],
              [-38.480336299730837, -8.136066161013906],
              [-38.480398691133196, -8.136041999750971],
              [-38.480456701723, -8.136036285026202],
              [-38.480502345459456, -8.136047559403329],
              [-38.480533792586627, -8.136086946108565],
              [-38.480550419699846, -8.136145127821338],
              [-38.48056631237435, -8.136209730666465],
              [-38.480593658485667, -8.136261775365176],
              [-38.48065982459336, -8.136266925245701],
              [-38.480719128024447, -8.136244116763011],
              [-38.480781861451852, -8.136236327640189],
              [-38.480838938974351, -8.136250330144977],
              [-38.480894419970276, -8.136235295599429],
              [-38.48088876745809, -8.136183911799334],
              [-38.48084970139621, -8.136139902345958],
              [-38.48080138826549, -8.13608683590037],
              [-38.480756868351207, -8.136048698831367],
              [-38.48071072968996, -8.135998800965391],
              [-38.480706456358938, -8.135933760716751],
              [-38.480702352929306, -8.135877765857181],
              [-38.480702243570768, -8.135821685650622],
              [-38.480746312657743, -8.135786918060685],
              [-38.480814231173206, -8.135770452189352],
              [-38.480881340071079, -8.135748286813339],
              [-38.480926865555126, -8.135710355252639],
              [-38.480967201569875, -8.135684447136514],
              [-38.48100690799582, -8.135654015625475],
              [-38.481061292314138, -8.135644678109271],
              [-38.481128671051692, -8.135624412521068],
              [-38.481200504223338, -8.135598996860423],
              [-38.481266795199801, -8.135577553986973],
              [-38.481327316159863, -8.135596083401527],
              [-38.481384834104034, -8.135620488336937],
              [-38.481461479996277, -8.135664274347027],
              [-38.481518811998725, -8.135692116192985],
              [-38.481585946885239, -8.135720332346073],
              [-38.4816572842401, -8.135727569047505],
              [-38.481702954478138, -8.135718220328087],
              [-38.481770210363784, -8.135722919154764],
              [-38.481876490639181, -8.13573345664758],
              [-38.481939393555614, -8.13573498408476],
              [-38.482003689985135, -8.135711639060467],
              [-38.482046225592214, -8.135669271420495],
              [-38.482078889721969, -8.135609253102988],
              [-38.482109285131088, -8.13554877963165],
              [-38.482142238041838, -8.135475917528254],
              [-38.482160900441365, -8.135433700349576],
              [-38.482182653018434, -8.135388411748524],
              [-38.482195168479308, -8.135326287350365],
              [-38.482201336890114, -8.13525872775849],
              [-38.482240500182286, -8.135227029101397],
              [-38.482288117874702, -8.135185481971831],
              [-38.482355673734752, -8.135168563111483],
              [-38.482392215568566, -8.135199091900681],
              [-38.482439856834375, -8.135209916343904],
              [-38.482507649392474, -8.135220766446359],
              [-38.482573890022181, -8.135238488894446],
              [-38.482625972958985, -8.135254112915302],
              [-38.482646270650839, -8.135281545639828],
              [-38.48268980796162, -8.135307379819965],
              [-38.482753229825235, -8.13532889762493],
              [-38.482814922745618, -8.135353759934596],
              [-38.482876258268362, -8.13537418964455],
              [-38.482938870976071, -8.135389646124459],
              [-38.48300666798707, -8.13539705898525],
              [-38.483077100855034, -8.135401761635556],
              [-38.483149342553183, -8.135411622324808],
              [-38.483226128514055, -8.135417056644867],
              [-38.483300561597488, -8.135416879949382],
              [-38.483374000131697, -8.13541362661606],
              [-38.483443067730548, -8.135421221923769],
              [-38.483514225743534, -8.135426739486595],
              [-38.483581661195899, -8.135432975920015],
              [-38.483652027505244, -8.135418773968341],
              [-38.483711052333376, -8.135400668167561],
              [-38.483777521703267, -8.135381576846621],
              [-38.483839729776939, -8.135358590675223],
              [-38.483900763482424, -8.135331170865694],
              [-38.483962524972632, -8.135302485645955],
              [-38.484009142171367, -8.13526238426212],
              [-38.48405604358932, -8.135212966700928],
              [-38.484095779360814, -8.135159379254171],
              [-38.484143402302806, -8.135113580668255],
              [-38.48419410846072, -8.135070137735768],
              [-38.484249342779762, -8.135034841203334],
              [-38.484301136556752, -8.134992665961384],
              [-38.484337588430044, -8.134951647116081],
              [-38.484377493659679, -8.134907466836946],
              [-38.484413572375743, -8.134874316814779],
              [-38.484457737494132, -8.134835116779362],
              [-38.484493175480807, -8.134806126718019],
              [-38.48453432987165, -8.134778862488501],
              [-38.484572123054129, -8.134753674381827],
              [-38.484620560412019, -8.134709776253176],
              [-38.484662633936374, -8.134673558448828],
              [-38.484708072235449, -8.134632460510241],
              [-38.484759497630527, -8.134594355065991],
              [-38.484811378374687, -8.134555074318712],
              [-38.484849370846234, -8.134515956861652],
              [-38.484878931381424, -8.134469140310431],
              [-38.484896514468652, -8.134418871406588],
              [-38.484913016838334, -8.134361907699628],
              [-38.484913899016668, -8.134310803559119],
              [-38.484895529576683, -8.134266006641456],
              [-38.484853874850934, -8.134187532142244],
              [-38.484824107608667, -8.134111876714748],
              [-38.484811365201757, -8.134067720089849],
              [-38.484770255254425, -8.133989155822354],
              [-38.484736801057402, -8.133957455282575],
              [-38.48469063426144, -8.133929356692144],
              [-38.484645915769995, -8.133904425745934],
              [-38.484598936319557, -8.133872979399261],
              [-38.484541056118289, -8.133847941298225],
              [-38.484479260164115, -8.133832757466383],
              [-38.484391244488663, -8.133807228857682],
              [-38.484310753941067, -8.133788674566418],
              [-38.484230359490979, -8.133765959599696],
              [-38.484148697721878, -8.133740529455746],
              [-38.484067950383796, -8.133709854253802],
              [-38.483987552496053, -8.133689852789709],
              [-38.483902707982942, -8.133668941145702],
              [-38.483809057628221, -8.133648832365992],
              [-38.483720926971579, -8.13364229834294],
              [-38.483630724784057, -8.13362309842104],
              [-38.483560964305362, -8.133589723528326],
              [-38.483481570860334, -8.133565110203179],
              [-38.483415087894741, -8.133524141503587],
              [-38.483369136139359, -8.13346999293532],
              [-38.483338346309829, -8.133413421439769],
              [-38.483290755600294, -8.133363250658011],
              [-38.483231992985147, -8.133318583224101],
              [-38.483190487210038, -8.1332658875193],
              [-38.483148166822239, -8.133211381739587],
              [-38.483078834073694, -8.133127625680896],
              [-38.483039228853407, -8.133079364516306],
              [-38.483005630292659, -8.133018809548927],
              [-38.482976395242055, -8.132953194831105],
              [-38.482972650065165, -8.132900728043365],
              [-38.482937855744311, -8.132852472990267],
              [-38.482901421063069, -8.1328092811465],
              [-38.482851559811145, -8.132760192852086],
              [-38.482781883829723, -8.132731883243521],
              [-38.48270182158943, -8.132733499938222],
              [-38.482637005517795, -8.132737759124986],
              [-38.482555236487116, -8.132725444047161],
              [-38.482474245894593, -8.132743159928268],
              [-38.482385543819461, -8.132757700160699],
              [-38.482305551348198, -8.132775688633739],
              [-38.482229920985702, -8.132789702770102],
              [-38.48215816719587, -8.132824435282892],
              [-38.482102370409486, -8.132873750847271],
              [-38.48205610538249, -8.132922445387559],
              [-38.481979848180352, -8.132929765246695],
              [-38.481913179645076, -8.132892142794162],
              [-38.481841126483531, -8.132877216855929],
              [-38.481754525687805, -8.132881176771717],
              [-38.481683600866127, -8.132906503272297],
              [-38.481613047020709, -8.132925679514299],
              [-38.481533531353435, -8.132925758993581],
              [-38.481455730574794, -8.13293343859174],
              [-38.4813877147024, -8.132955422035652],
              [-38.481322046315981, -8.132987177251588],
              [-38.481273070145477, -8.13302646125498],
              [-38.481226723536182, -8.133067919449925],
              [-38.481184991126206, -8.133121142255073],
              [-38.4811683853207, -8.133187603085412],
              [-38.481151867140376, -8.133256506223287],
              [-38.481116236368415, -8.133294179109704],
              [-38.481070036426935, -8.133292130185144],
              [-38.480992334487887, -8.133293478194243],
              [-38.480932022212087, -8.133324697470679],
              [-38.480871185546057, -8.13334017746603],
              [-38.480787763389316, -8.133342784453092],
              [-38.480710777253378, -8.133352183526263],
              [-38.480627159816827, -8.133365463534773],
              [-38.480544097131727, -8.133370874934345],
              [-38.480465658183803, -8.13338063391042],
              [-38.480383761428165, -8.133396991426315],
              [-38.480309944812255, -8.133412183385071],
              [-38.480235127029644, -8.133429454439334],
              [-38.480162118372547, -8.133451612186455],
              [-38.480090304182795, -8.133462555434404],
              [-38.480021284898541, -8.133488245875945],
              [-38.479954440500421, -8.133516743091477],
              [-38.479881698765915, -8.133543061906895],
              [-38.479812113492869, -8.133585304251248],
              [-38.479747697964854, -8.13363080946241],
              [-38.479688184183296, -8.1336762304863],
              [-38.479626311387193, -8.133720834414564],
              [-38.479559176880869, -8.13376307984972],
              [-38.479499952056401, -8.133795566618334],
              [-38.479430700616412, -8.133860422247176],
              [-38.479425092995811, -8.133774395486144],
              [-38.47942325097641, -8.133724644684904],
              [-38.479417522948737, -8.133661592504051],
              [-38.479407629716924, -8.133590575237063],
              [-38.479416454946517, -8.133504386059204],
              [-38.479415211110499, -8.133413118687235],
              [-38.479412505718386, -8.133328995128105],
              [-38.479398270648346, -8.133246213575918],
              [-38.479358873444156, -8.133177781613652],
              [-38.479319810629455, -8.133131601198032],
              [-38.47927394372973, -8.133082517606917],
              [-38.479231624964839, -8.133027197389305],
              [-38.479190491137054, -8.132968079714271],
              [-38.479147368530178, -8.1329026278672],
              [-38.479108965794886, -8.132837362968615],
              [-38.479074530375975, -8.132792726141821],
              [-38.479024488429516, -8.13274336581989],
              [-38.478964542769283, -8.132701500288844],
              [-38.478886881604907, -8.132671370759374],
              [-38.478804045224472, -8.132642229548413],
              [-38.478727737471566, -8.132618433344913],
              [-38.478650520500238, -8.132595811833518],
              [-38.478585121286415, -8.132559637712363],
              [-38.478508259004826, -8.132543529152866],
              [-38.478426965553915, -8.132514570751132],
              [-38.478354671995461, -8.132475131486673],
              [-38.478261279851608, -8.1324664187072],
              [-38.478165437249928, -8.132457431405452],
              [-38.478064687060673, -8.132453050816164],
              [-38.477973195856435, -8.132448410738599],
              [-38.477880704420741, -8.132445126131117],
              [-38.477789292876039, -8.132449079025662],
              [-38.477714160570372, -8.132428902215471],
              [-38.477631956222609, -8.132402475136535],
              [-38.477606097178274, -8.132394210799106],
              [-38.477548753758356, -8.132375775403599],
              [-38.477521625799127, -8.13236606220296],
              [-38.47746945656062, -8.132347452544153],
              [-38.477410487008861, -8.132323045227844],
              [-38.477391888822851, -8.132315423374246],
              [-38.47734141262287, -8.132251137773217],
              [-38.477275772558166, -8.132190812540797],
              [-38.477225734078878, -8.132139009823099],
              [-38.477171523202237, -8.132084849989296],
              [-38.477122527480581, -8.132068957921494],
              [-38.477057950595054, -8.132028804751059],
              [-38.477023573723329, -8.131939213354164],
              [-38.47700384382911, -8.131894323933238],
              [-38.476966683262006, -8.131851492472794],
              [-38.476912892318936, -8.131823564137575],
              [-38.476843840882495, -8.131804118614291],
              [-38.476759145935354, -8.131808532188227],
              [-38.476683060251112, -8.131823720578147],
              [-38.476616749065897, -8.131861082200773],
              [-38.476568560933501, -8.131922436844157],
              [-38.476545499277677, -8.13199693933179],
              [-38.476512189782156, -8.132064282937103],
              [-38.476474890466314, -8.132128455591371],
              [-38.476445851164137, -8.132192729332221],
              [-38.476394680082613, -8.132244401778372],
              [-38.476340701194722, -8.132291367112726],
              [-38.476288727233602, -8.132332184308305],
              [-38.476235387356503, -8.132376346448458],
              [-38.476184852325275, -8.132427476974161],
              [-38.47613675776325, -8.132486570385174],
              [-38.47608620814632, -8.132548916885154],
              [-38.476034110828749, -8.132614788997587],
              [-38.475985020912574, -8.132671438909142],
              [-38.475941179722703, -8.132740397008119],
              [-38.475900959018396, -8.132817319516484],
              [-38.475866088263395, -8.132898500147],
              [-38.475836937826962, -8.132978331375595],
              [-38.475811681249581, -8.133065313304616],
              [-38.475784518116477, -8.133152564126874],
              [-38.475759358513073, -8.133234752237472],
              [-38.475741637776778, -8.133320296652762],
              [-38.475719385293978, -8.133400589024072],
              [-38.475700039121264, -8.133479618819353],
              [-38.475687317585169, -8.133559923482808],
              [-38.475674057292416, -8.133635704866993],
              [-38.475675429362653, -8.133697485121226],
              [-38.47569325040088, -8.133744362072871],
              [-38.475700536818316, -8.133795205306237],
              [-38.475696984567051, -8.133874436377528],
              [-38.475671689890085, -8.133920805444587],
              [-38.47562093619063, -8.133860589606051],
              [-38.475527599902257, -8.133808730814009],
              [-38.475475845639707, -8.133750141797824],
              [-38.475462133547495, -8.133684637100275],
              [-38.475455238227845, -8.133612266874607],
              [-38.475423335472613, -8.133504950031385],
              [-38.475399455859133, -8.133440336724817],
              [-38.475379664948079, -8.133372653338585],
              [-38.475359244760298, -8.133300265653061],
              [-38.475338899122512, -8.133240360391703],
              [-38.47531519707956, -8.13317882266675],
              [-38.475292768306872, -8.13311538709962],
              [-38.475262258924054, -8.133053478777176],
              [-38.475230490870629, -8.132982252306993],
              [-38.475207063992904, -8.132918544092364],
              [-38.475182366093684, -8.132855015138826],
              [-38.475149044258416, -8.132792017757959],
              [-38.475110171879756, -8.132739415147356],
              [-38.475072286344215, -8.132695768521966],
              [-38.475033303212918, -8.132658542543505],
              [-38.474972087459356, -8.132616493906223],
              [-38.474905232745527, -8.132583030882763],
              [-38.474843552453237, -8.132549212727854],
              [-38.474778333991686, -8.132513942765788],
              [-38.474725105651451, -8.132472266221612],
              [-38.474664336985775, -8.132435464314975],
              [-38.474575165306042, -8.132392294069765],
              [-38.474537159180343, -8.132371621962703],
              [-38.474590840646357, -8.13234410360243],
              [-38.474611664257786, -8.132245900025133],
              [-38.474643314302632, -8.132128715591492],
              [-38.474684575556438, -8.132089331996704],
              [-38.474743880053097, -8.13206543894583],
              [-38.474790750245511, -8.13204053485175],
              [-38.474834095672691, -8.132003867482721],
              [-38.474856315636217, -8.131948630227393],
              [-38.474860575818703, -8.131883510538326],
              [-38.47489453919804, -8.131802147921693],
              [-38.474828548278737, -8.131732776701133],
              [-38.474764370820246, -8.131734683719387],
              [-38.474692994933164, -8.131757747112481],
              [-38.474635043211045, -8.131788425781304],
              [-38.474568285310276, -8.131820178478504],
              [-38.474518487154413, -8.13186298819007],
              [-38.474457291684381, -8.131875210501549],
              [-38.474374689754669, -8.131875555957786],
              [-38.474295253962374, -8.131884227043589],
              [-38.474207736629268, -8.13189514895161],
              [-38.474127122580086, -8.131902461705312],
              [-38.474106607811308, -8.131902887423365],
              [-38.474052330465256, -8.131900103649693],
              [-38.473988152041315, -8.131902734155371],
              [-38.473920040532867, -8.131928334278173],
              [-38.47386897186292, -8.131970870905526],
              [-38.473792467214629, -8.132028841829602],
              [-38.473752468927991, -8.132004549008956],
              [-38.473740871892694, -8.131918333581224],
              [-38.473760732548165, -8.131862641081984],
              [-38.473791236007337, -8.131789595643214],
              [-38.473805404862958, -8.131713363249705],
              [-38.473803152480009, -8.131630777984093],
              [-38.473784003908541, -8.131558030075842],
              [-38.473746133839107, -8.131502715098367],
              [-38.473689453273721, -8.13146347615676],
              [-38.473621224921018, -8.131439508582673],
              [-38.473555083002971, -8.131416538667718],
              [-38.473478027858469, -8.131409474096303],
              [-38.473405421892146, -8.131401329854075],
              [-38.473339915152785, -8.131378541631305],
              [-38.473267578557355, -8.131372659004978],
              [-38.473206175154225, -8.131405142027621],
              [-38.47316082951771, -8.131444248820783],
              [-38.473117022486228, -8.13148679476453],
              [-38.47308800337985, -8.131535239227208],
              [-38.473061806657014, -8.131577174828346],
              [-38.473012112735717, -8.131609582545147],
              [-38.472950711875512, -8.131640075592692],
              [-38.472863158904836, -8.131678313558298],
              [-38.472813645390794, -8.131642068696383],
              [-38.472759818172179, -8.131642179788777],
              [-38.472737041627802, -8.131706370902599],
              [-38.472688428739389, -8.131675554291748],
              [-38.472635115048377, -8.131629987962008],
              [-38.472597134512057, -8.131589868616933],
              [-38.472623617420453, -8.131537350579871],
              [-38.472674320590009, -8.131496713122868],
              [-38.472732654267958, -8.131451653426218],
              [-38.472779192956445, -8.131402598551555],
              [-38.472823385656064, -8.13134277688434],
              [-38.47286749017033, -8.131280965161119],
              [-38.472890822509768, -8.131208181851461],
              [-38.47291007701687, -8.131130147057748],
              [-38.472926242838611, -8.131054007742209],
              [-38.472896999673942, -8.130995990330229],
              [-38.472852857167481, -8.130947903276844],
              [-38.472795360900335, -8.130907849097447],
              [-38.472745221895231, -8.130864095935555],
              [-38.472704796283544, -8.130819631753523],
              [-38.472655201685598, -8.130776060191003],
              [-38.472599421947635, -8.130742249375364],
              [-38.472544017828113, -8.13069885116715],
              [-38.472479334810316, -8.130670998592548],
              [-38.472415386239724, -8.130636815347275],
              [-38.472351623122535, -8.130599647429522],
              [-38.472290133060483, -8.130559588000878],
              [-38.472210172399912, -8.130553333493662],
              [-38.472136070186082, -8.130578925549234],
              [-38.472073337373068, -8.130656270682968],
              [-38.472009163531702, -8.130655554183836],
              [-38.471925463881291, -8.130662591152216],
              [-38.471952566936096, -8.130621742222814],
              [-38.471981236269386, -8.130563166807411],
              [-38.472011279807113, -8.130494914850384],
              [-38.472046944707877, -8.130431554577642],
              [-38.472076894023104, -8.130365925588743],
              [-38.472098958492793, -8.130290969841504],
              [-38.472116762308161, -8.130211757334045],
              [-38.47212958297488, -8.130125483128438],
              [-38.472136141433253, -8.130038477178605],
              [-38.472143873514732, -8.12995635713968],
              [-38.472127190058245, -8.129872939079467],
              [-38.472087327800388, -8.129814274670297],
              [-38.4720263812018, -8.129775210882869],
              [-38.47195642364256, -8.12975503977434],
              [-38.471888749661517, -8.129723926973647],
              [-38.471820268344459, -8.129685486532056],
              [-38.471747155158994, -8.129649029997699],
              [-38.471659947047044, -8.129632092686576],
              [-38.47157282841151, -8.129616150441258],
              [-38.471491716953103, -8.129587733662479],
              [-38.471413235117453, -8.129561400673925],
              [-38.471339560438516, -8.129537877921166],
              [-38.471281395379258, -8.129523601388225],
              [-38.471223525852849, -8.12949159671475],
              [-38.471152138816016, -8.129454056904006],
              [-38.4710682058492, -8.12943151572965],
              [-38.470984534892693, -8.129416844169727],
              [-38.470899307339273, -8.129412482051279],
              [-38.470829173942022, -8.129388059301311],
              [-38.47076032117559, -8.129356130723027],
              [-38.470683102158652, -8.129335949952116],
              [-38.470603693402083, -8.129324359218908],
              [-38.470525248487, -8.129339181594863],
              [-38.470453862042831, -8.129370475340174],
              [-38.470392363239846, -8.129406575885813],
              [-38.470334030107239, -8.129451273447549],
              [-38.470297124047526, -8.129491928592223],
              [-38.470230549444651, -8.1295224144993],
              [-38.470178329804853, -8.129542788520796],
              [-38.470128725400173, -8.129576190973776],
              [-38.470057236125669, -8.129616620122002],
              [-38.470050701032321, -8.129616340249708],
              [-38.470058298112839, -8.129568229900404],
              [-38.470067017673465, -8.129494794565678],
              [-38.47008899013786, -8.129421014695778],
              [-38.470106336406118, -8.129344877053484],
              [-38.470092899510099, -8.129277925357673],
              [-38.470111204830587, -8.129231818895889],
              [-38.470125180960657, -8.129164541116609],
              [-38.470133287064478, -8.129074371431777],
              [-38.470140213272217, -8.128984109757159],
              [-38.470134344826519, -8.128890846507066],
              [-38.470105125952465, -8.128814738634155],
              [-38.47005108784073, -8.128768356992738],
              [-38.469975605836126, -8.128738861844925],
              [-38.469896764287334, -8.128710176368658],
              [-38.469814279365124, -8.12869107399718],
              [-38.469731421632019, -8.12867938815441],
              [-38.469656336497657, -8.128693310234304],
              [-38.469605835869281, -8.128718028106533],
              [-38.469562058507151, -8.12873796080749],
              [-38.46955331442296, -8.128691819087328],
              [-38.46950998510485, -8.128647079476263],
              [-38.469448582604308, -8.128609914215648],
              [-38.469386276220014, -8.128569853316039],
              [-38.469329513824164, -8.128524462998257],
              [-38.469274752301324, -8.128476090381991],
              [-38.469216813014633, -8.128428346772537],
              [-38.469148512900141, -8.128390539305968],
              [-38.469072394938784, -8.128361585877956],
              [-38.468995001691795, -8.128336067931476],
              [-38.468915417251196, -8.128320225429356],
              [-38.468833196328895, -8.128307545273936],
              [-38.46875706377309, -8.12828971731531],
              [-38.468678566925448, -8.128275142510915],
              [-38.46859036818983, -8.12825259527447],
              [-38.468534027164388, -8.128231989138078],
              [-38.468456432678941, -8.128221485792487],
              [-38.468380376760052, -8.128214421603717],
              [-38.468280212286665, -8.128179828599267],
              [-38.4682352403807, -8.128141870584438],
              [-38.468251496388426, -8.128135650681385],
              [-38.468266930227315, -8.128133409572067],
              [-38.468271016296299, -8.128132329495745],
              [-38.468327313442884, -8.128117297686483],
              [-38.468366638544559, -8.128100796457291],
              [-38.468437652887246, -8.128076286454924],
              [-38.46849306541904, -8.128043705858293],
              [-38.468565441529556, -8.12801901670905],
              [-38.468625854592204, -8.127980201467919],
              [-38.468687168200709, -8.12794681448891],
              [-38.46876761957742, -8.127924578044446],
              [-38.468827723060613, -8.127914073714301],
              [-38.468932480966437, -8.127906883946377],
              [-38.469018181983877, -8.12789605120669],
              [-38.469080179017993, -8.127895137179671],
              [-38.469149236473136, -8.127909156872287],
              [-38.469211436924681, -8.127891599997369],
              [-38.469282442629471, -8.127873602345392],
              [-38.469334065051079, -8.127893116801499],
              [-38.469350501350149, -8.127956906623931],
              [-38.469362922984807, -8.128036520236936],
              [-38.469370621385522, -8.128118569879769],
              [-38.469372131906177, -8.128143355588083],
              [-38.469375506399807, -8.128200254045103],
              [-38.469379749845864, -8.128286640855979],
              [-38.469379003922548, -8.128370759866524],
              [-38.4693819843163, -8.128451265675094],
              [-38.469390148695958, -8.128524089862033],
              [-38.469430233803259, -8.128550915835838],
              [-38.469435220232008, -8.128486339902533],
              [-38.469430683667497, -8.128415781739056],
              [-38.469434232036761, -8.128340349737474],
              [-38.469425147117406, -8.128277293121853],
              [-38.46941905796043, -8.128213969059853],
              [-38.469420149125824, -8.128143418238059],
              [-38.469420542747429, -8.12812017269118],
              [-38.469423242572937, -8.128068799706403],
              [-38.469428314413875, -8.128008294204658],
              [-38.469430835549318, -8.127954750147966],
              [-38.469467874494327, -8.127881984931356],
              [-38.469519536968605, -8.127871017227825],
              [-38.469582920021857, -8.127851471902007],
              [-38.469616787465803, -8.127843646782392],
              [-38.469669901772761, -8.127833042758938],
              [-38.46977350470813, -8.127806765977706],
              [-38.469845244136295, -8.127782980278726],
              [-38.469906822691676, -8.127755111000491],
              [-38.469967038506269, -8.127728144452384],
              [-38.4700388683246, -8.127704630190166],
              [-38.470106791715189, -8.127683643470041],
              [-38.470178431435563, -8.127666641450375],
              [-38.470248800493643, -8.127649547310964],
              [-38.470316438270032, -8.127638690762897],
              [-38.47039577857241, -8.127633005184613],
              [-38.470467664847661, -8.12763563143389],
              [-38.470546711732439, -8.127646045831366],
              [-38.470611427917952, -8.127648120011381],
              [-38.470676135213253, -8.127656978040893],
              [-38.470745668479246, -8.127654264533451],
              [-38.470811119360476, -8.127649827121434],
              [-38.470884275592446, -8.127653088111195],
              [-38.470951637732327, -8.127644763743284],
              [-38.471022548537221, -8.127629841011306],
              [-38.471091104774693, -8.127610754429869],
              [-38.471159840769076, -8.12759302484454],
              [-38.471232388848293, -8.1275754811067],
              [-38.471299565524653, -8.127570141342375],
              [-38.471370195947287, -8.127561368894243],
              [-38.471431567385864, -8.127552946206881],
              [-38.471497590921572, -8.127527072390956],
              [-38.471556712639888, -8.127503722241807],
              [-38.47160695679343, -8.127466702353749],
              [-38.471646234020227, -8.127417276338356],
              [-38.471673001709476, -8.127355080388416],
              [-38.471715185674931, -8.127304030011329],
              [-38.47176098527158, -8.127264562134219],
              [-38.471810423569842, -8.127219038717232],
              [-38.471835017480323, -8.127153221865189],
              [-38.471846641384872, -8.127079699779912],
              [-38.471831194778758, -8.127022333384424],
              [-38.47181929954511, -8.126956288243266],
              [-38.47180695330588, -8.126888071676749],
              [-38.471780971057534, -8.126835575986137],
              [-38.471747720629118, -8.126788136146383],
              [-38.471708559187391, -8.126749100628944],
              [-38.471673931495722, -8.126713689062532],
              [-38.471630322363126, -8.126674647761108],
              [-38.471583259383529, -8.126639129582252],
              [-38.47154427394905, -8.126604345506049],
              [-38.471505925682855, -8.126568205479849],
              [-38.471450403294199, -8.126546334492042],
              [-38.47138779829001, -8.126526444228428],
              [-38.471328109517302, -8.126497693486106],
              [-38.471268877679222, -8.12646659158699],
              [-38.471203372220543, -8.12644353171903],
              [-38.471134782859529, -8.126418749250305],
              [-38.471063462725759, -8.126399842575356],
              [-38.470992958576467, -8.126381660562574],
              [-38.470915275593839, -8.126369619910854],
              [-38.470845381604114, -8.126370704862943],
              [-38.470789567776777, -8.126363486572595],
              [-38.470726424267703, -8.126338982477419],
              [-38.470672271086073, -8.126311324265517],
              [-38.470617210336805, -8.126283574414053],
              [-38.47056742417864, -8.126248233484604],
              [-38.470521636031656, -8.126209731946961],
              [-38.470467393298982, -8.126181169076791],
              [-38.470414427274868, -8.126148085281024],
              [-38.470379438216284, -8.12611149727219],
              [-38.470342732611719, -8.126068756315519],
              [-38.470315395707786, -8.126011193489944],
              [-38.470286146487709, -8.125958331656802],
              [-38.470248896527835, -8.125915409080694],
              [-38.470204200527938, -8.125874738094431],
              [-38.470157236372799, -8.125833250084895],
              [-38.470096724705002, -8.125809201585101],
              [-38.470033112886618, -8.125795822328469],
              [-38.4699668376687, -8.125806137914962],
              [-38.469904027823716, -8.125804156586787],
              [-38.469840119251622, -8.125809409945987],
              [-38.469776959509723, -8.125797388013234],
              [-38.469725168728374, -8.125768014181709],
              [-38.469675656211187, -8.125731768991741],
              [-38.469621502595473, -8.125704562906275],
              [-38.469565530989598, -8.125679344378856],
              [-38.469504283134469, -8.125662983221195],
              [-38.469441028620075, -8.125654036469315],
              [-38.469369223860191, -8.125658736747464],
              [-38.469300129817867, -8.125672847516274],
              [-38.469241547294828, -8.125700449277073],
              [-38.469181870422325, -8.12573193901993],
              [-38.469120023875533, -8.125756732503181],
              [-38.469060711965334, -8.12578677547768],
              [-38.468996889406789, -8.125795646880219],
              [-38.46892719407645, -8.12578379718911],
              [-38.468856944977603, -8.125778911536392],
              [-38.468786425000872, -8.12577294009871],
              [-38.468724183824662, -8.125752688108884],
              [-38.468699870039949, -8.125743249387787],
              [-38.468662401379454, -8.125728728193662],
              [-38.468593240337214, -8.125724748433317],
              [-38.46852478926602, -8.125733071005833],
              [-38.468458337615417, -8.125739496695731],
              [-38.46839578642183, -8.125747917406891],
              [-38.468333147314247, -8.125754167155208],
              [-38.468268501518715, -8.125767831301163],
              [-38.468207562849862, -8.125792444925315],
              [-38.468152789813942, -8.125822131889963],
              [-38.468093842218224, -8.125851180227118],
              [-38.468038801385404, -8.12587742966482],
              [-38.467947713352089, -8.125912586655259],
              [-38.467905261810998, -8.125890913199555],
              [-38.467857464540295, -8.125861906168216],
              [-38.467815281742531, -8.125842946602837],
              [-38.467761871211984, -8.125802987575726],
              [-38.46771652628145, -8.125772807867063],
              [-38.467673257726283, -8.125751223771232],
              [-38.46763018454245, -8.125719147543192],
              [-38.467569305578159, -8.125698444905623],
              [-38.467508413147193, -8.125687963270288],
              [-38.467436449738535, -8.125675296294471],
              [-38.467387629739221, -8.125664739994622],
              [-38.46730804220411, -8.125651701207971],
              [-38.467220275187813, -8.125646430529152],
              [-38.467136315708579, -8.125644782882073],
              [-38.467047736191134, -8.125636164393686],
              [-38.467000454989432, -8.125628866320936],
              [-38.466910874035037, -8.125622507780308],
              [-38.466861771366439, -8.12561982033491],
              [-38.466814576881362, -8.12561559770794],
              [-38.466764111363844, -8.125613903431852],
              [-38.466715373446917, -8.125609950125009],
              [-38.466634856405555, -8.125613462627237],
              [-38.466584659256533, -8.125614753582576],
              [-38.466520126191362, -8.125611774507867],
              [-38.466473840958223, -8.125606377163697],
              [-38.466427730813344, -8.125605864428534],
              [-38.466334139786163, -8.125611801914756],
              [-38.46625079886234, -8.125622908567488],
              [-38.466166457083354, -8.125635822920568],
              [-38.466095900978047, -8.125657257796012],
              [-38.466028872309941, -8.125688194702724],
              [-38.46595784261261, -8.125724553438614],
              [-38.465890887471076, -8.125768515441894],
              [-38.465824391065581, -8.125808769521717],
              [-38.465766068423072, -8.125845597165357],
              [-38.465713552727756, -8.125884151006563],
              [-38.465665401124987, -8.125917283479618],
              [-38.465601444776851, -8.125958626274922],
              [-38.465528778636902, -8.125996882262662],
              [-38.465453558495838, -8.126044541841399],
              [-38.465387698825666, -8.126083711261856],
              [-38.465329843960674, -8.126109956621073],
              [-38.465265644698761, -8.126129047959205],
              [-38.465194717606508, -8.126156361532066],
              [-38.465126395625774, -8.126204211018072],
              [-38.465057795691145, -8.126256311348444],
              [-38.465020267836181, -8.126286563260273],
              [-38.464962013420141, -8.126340305306748],
              [-38.464896043835189, -8.126393856291218],
              [-38.464843964459078, -8.126445526056477],
              [-38.464789953264273, -8.126516549901464],
              [-38.464750814322898, -8.12659808568711],
              [-38.464726148814343, -8.126648615637734],
              [-38.464697916440009, -8.126719402036201],
              [-38.464653594130063, -8.126808076658712],
              [-38.464627026273831, -8.126855709648307],
              [-38.464590084075638, -8.126923409219742],
              [-38.464585989378392, -8.12693100175845],
              [-38.464568142568126, -8.126973219130948],
              [-38.464542707565187, -8.127056401056864],
              [-38.464530133169113, -8.127092746018377],
              [-38.464514916981194, -8.127136233172173],
              [-38.464495600006757, -8.127054982263875],
              [-38.464459584555108, -8.127039829437903],
              [-38.464423637326213, -8.127041681586606],
              [-38.464381929156637, -8.1270755459872],
              [-38.464395249033011, -8.127024639316311],
              [-38.464386360497308, -8.126951000065409],
              [-38.464384453413466, -8.126883249357457],
              [-38.464372281108439, -8.126821726286916],
              [-38.464352376853014, -8.126772675345071],
              [-38.464332205812092, -8.126719463280624],
              [-38.464311206454958, -8.126674843019964],
              [-38.464282752016416, -8.126639258092665],
              [-38.464276591216027, -8.126630566631384],
              [-38.464254847357985, -8.126599784471859],
              [-38.464214972548035, -8.126551702186848],
              [-38.464182888432347, -8.126515298408572],
              [-38.464149838739061, -8.126454109636862],
              [-38.464119142697456, -8.126397717894864],
              [-38.46408915577716, -8.126354171202243],
              [-38.464051538961208, -8.126314594322075],
              [-38.464021812839718, -8.126279731315568],
              [-38.463981105800471, -8.126243225709679],
              [-38.463944750772228, -8.126210434348003],
              [-38.463900870304123, -8.126171301458463],
              [-38.463860978169215, -8.126136334591701],
              [-38.463825532173679, -8.126102549451769],
              [-38.463770941318842, -8.126063131077316],
              [-38.463708812305484, -8.126026959012892],
              [-38.463649745076403, -8.126009152636085],
              [-38.463590950761251, -8.125990894353283],
              [-38.463531425973542, -8.125975891353793],
              [-38.463465645684145, -8.12595518164918],
              [-38.463395227160703, -8.125941249679935],
              [-38.463339941383211, -8.125946694288686],
              [-38.463283473682075, -8.125953584554731],
              [-38.463225479610344, -8.125947900037668],
              [-38.463170936934311, -8.12594059193982],
              [-38.463095702369372, -8.125930633430489],
              [-38.46304365146834, -8.125892484637737],
              [-38.463031373951829, -8.125841996485185],
              [-38.46301620445, -8.125782007105514],
              [-38.462984317795943, -8.125733754354092],
              [-38.462929813638141, -8.125697501791942],
              [-38.462858959739982, -8.125669730077199],
              [-38.462788366130596, -8.125651003845745],
              [-38.462717034344863, -8.12564132177878],
              [-38.462643597907977, -8.125644390582501],
              [-38.462569154466316, -8.125653880099762],
              [-38.462500595971171, -8.125674683817664],
              [-38.462424408528712, -8.125698733696302],
              [-38.462347681278764, -8.125719164786364],
              [-38.462260673748979, -8.125757129864031],
              [-38.462190986012139, -8.125807961934266],
              [-38.462125651083277, -8.125861875097874],
              [-38.462063312363355, -8.12591515905161],
              [-38.462004794727619, -8.12596184508862],
              [-38.461955544693694, -8.126001669060587],
              [-38.461913570043365, -8.126031100727474],
              [-38.461865779891419, -8.12606522817409],
              [-38.461825445099812, -8.126090139425468],
              [-38.461784092076293, -8.126129883370798],
              [-38.461740090103341, -8.126182106112546],
              [-38.461688122846525, -8.12621749432944],
              [-38.461623489478882, -8.126221660009401],
              [-38.461571514029721, -8.126195088926423],
              [-38.461534260566452, -8.12615551231071],
              [-38.461505912743505, -8.126108259104646],
              [-38.461473825662637, -8.126074297316098],
              [-38.461432325001709, -8.12602051418501],
              [-38.461388352477123, -8.125982556788417],
              [-38.461429997926395, -8.125995817846519],
              [-38.46145713140227, -8.126000738150672],
              [-38.461496308482374, -8.12602720185231],
              [-38.461558762492601, -8.126091776441397],
              [-38.461597757808875, -8.126118420796537],
              [-38.461649235047823, -8.12611016739077],
              [-38.461700392482278, -8.126069441468042],
              [-38.461737923919486, -8.126036657239691],
              [-38.461766315141269, -8.125983237972129],
              [-38.461805505356963, -8.125931732471187],
              [-38.461847131869504, -8.125891084368693],
              [-38.461907195237742, -8.125842410466351],
              [-38.461978596302671, -8.125800083154706],
              [-38.462016118296241, -8.125774354101862],
              [-38.462081713365635, -8.12572957690141],
              [-38.462145312222596, -8.125684254340984],
              [-38.46221263368335, -8.125638122627324],
              [-38.462276861755065, -8.125597413902856],
              [-38.462336355012766, -8.125567824444857],
              [-38.462408646946287, -8.12553807098832],
              [-38.462471492625191, -8.125513008515078],
              [-38.462531323541107, -8.125502504741606],
              [-38.462591497796353, -8.125506835460607],
              [-38.462648845457522, -8.125520750297397],
              [-38.462711365638803, -8.125535666924726],
              [-38.462772081269428, -8.125542440523375],
              [-38.462835050494242, -8.125560975785797],
              [-38.462899568452855, -8.125575261862389],
              [-38.462977072894439, -8.125584680691077],
              [-38.463050564382435, -8.125608475999636],
              [-38.463107708299169, -8.125639124035542],
              [-38.463150140771859, -8.125674908385941],
              [-38.463194836951736, -8.125714856489466],
              [-38.463240181865835, -8.125744765325841],
              [-38.463288300818213, -8.125736326372969],
              [-38.463331082293919, -8.125714403096117],
              [-38.463373511983626, -8.125683976907258],
              [-38.463397807303423, -8.125638873646414],
              [-38.463387521141271, -8.125592548898434],
              [-38.463378242573341, -8.125539351163667],
              [-38.463360250293533, -8.125486051482502],
              [-38.463332071211255, -8.125448296023938],
              [-38.463284169885227, -8.125429599815003],
              [-38.463218755779351, -8.125406629271474],
              [-38.463154084061756, -8.125371448742335],
              [-38.463093943475982, -8.125341791726457],
              [-38.463030258217088, -8.12531565763271],
              [-38.462961848166309, -8.125293135352939],
              [-38.462894621488772, -8.125268081982005],
              [-38.462828391355266, -8.125244477140598],
              [-38.462752358895976, -8.125220316634858],
              [-38.462666585285874, -8.12521785161249],
              [-38.462712546417201, -8.125193852213743],
              [-38.462760861162657, -8.125174649847793],
              [-38.462812813039385, -8.125150658348121],
              [-38.462865930595946, -8.125137432111051],
              [-38.462928763440544, -8.125121957404744],
              [-38.462979340660468, -8.125107823283559],
              [-38.463036288419516, -8.125081124803062],
              [-38.463087069889944, -8.12504989560005],
              [-38.46312660609447, -8.125011053622517],
              [-38.463170320429249, -8.124970136768853],
              [-38.463220284474332, -8.124939268274963],
              [-38.463279953221537, -8.124914111023584],
              [-38.463330376503841, -8.124879172802347],
              [-38.463361386754492, -8.124835525621325],
              [-38.46337236325251, -8.124772133580278],
              [-38.463374908928877, -8.124700680232538],
              [-38.463390709387653, -8.124627344885003],
              [-38.463411152268328, -8.124544065991186],
              [-38.463451908267899, -8.124475195677256],
              [-38.463490672502978, -8.124402433316048],
              [-38.463486967396854, -8.124458417926636],
              [-38.463501424955254, -8.124507733074681],
              [-38.463530330625865, -8.124545127672006],
              [-38.463585830149704, -8.124583642749823],
              [-38.463648578228664, -8.124631936141942],
              [-38.463696821064957, -8.124666823577996],
              [-38.463725744414731, -8.124690921811229],
              [-38.463787331739837, -8.124724741408643],
              [-38.463836506777895, -8.124741177945465],
              [-38.463899748126657, -8.124759803849001],
              [-38.463967563693501, -8.124751481212764],
              [-38.464035281409458, -8.124748495076677],
              [-38.464109446120169, -8.124743708390547],
              [-38.464192235199782, -8.124737938082298],
              [-38.464248865068349, -8.124745520137555],
              [-38.464272279379735, -8.124748626322624],
              [-38.464346777903891, -8.124765910208364],
              [-38.464354490866739, -8.12476772939417],
              [-38.464433144266664, -8.124800485948333],
              [-38.464478412562478, -8.124819721264583],
              [-38.464522600147326, -8.124832533094128],
              [-38.464567789790429, -8.124842632691582],
              [-38.464592838432175, -8.124845379203428],
              [-38.464652374132861, -8.124852060512621],
              [-38.464730590749582, -8.124871972317605],
              [-38.46477043126476, -8.124877361374921],
              [-38.464836765902213, -8.124890654565126],
              [-38.464920062846154, -8.12491265340312],
              [-38.464993753258881, -8.124923333148017],
              [-38.465062480964306, -8.124911664821791],
              [-38.465129541298261, -8.124856758450417],
              [-38.465161361097636, -8.124818539277026],
              [-38.465191817799713, -8.124781494178997],
              [-38.465226275753928, -8.124738846341515],
              [-38.465260191960397, -8.124694026951209],
              [-38.465295197745689, -8.12464893763495],
              [-38.465333924091745, -8.124604576816862],
              [-38.46536728022015, -8.124571424930492],
              [-38.465413432008354, -8.124540370239972],
              [-38.465488842614299, -8.124485746127903],
              [-38.465533221364566, -8.124422669241598],
              [-38.465543222863595, -8.124341275977326],
              [-38.465547731800882, -8.124294970702175],
              [-38.465549639143667, -8.124294068692057],
              [-38.465579545969646, -8.124329564987661],
              [-38.465615718007435, -8.124363441382203],
              [-38.46566802059656, -8.124417238302076],
              [-38.465725071531764, -8.124449604431536],
              [-38.465746295308641, -8.12446184326247],
              [-38.465788379312329, -8.124486682250206],
              [-38.465823682680359, -8.124491070278248],
              [-38.465880507490986, -8.124488521787155],
              [-38.465927992907545, -8.12447854400016],
              [-38.465988276500049, -8.124468763908965],
              [-38.466052109999794, -8.124451480987602],
              [-38.466119740250441, -8.124445961741648],
              [-38.466191273324135, -8.124440628508118],
              [-38.466260707718291, -8.124443614052991],
              [-38.466331419330928, -8.124441716876726],
              [-38.466400676012853, -8.12444180771446],
              [-38.466465400222226, -8.124437370018113],
              [-38.466534214287115, -8.124428957804852],
              [-38.466594225914818, -8.124418905911099],
              [-38.466653971203684, -8.124404331080477],
              [-38.46670944079861, -8.12439698676244],
              [-38.466765371041241, -8.124384487300524],
              [-38.466809599493061, -8.124366364497888],
              [-38.466852929681686, -8.12434109483852],
              [-38.466902129464948, -8.124270064366337],
              [-38.466913028706358, -8.124195998804234],
              [-38.466921027635955, -8.124118673184404],
              [-38.466945147613103, -8.124068504158835],
              [-38.466968263518318, -8.124022585040187],
              [-38.466990119871049, -8.123968161821148],
              [-38.467006884454022, -8.123920334895947],
              [-38.46703027312649, -8.123874054323512],
              [-38.46704848200703, -8.123832289543673],
              [-38.467067333542623, -8.12378500805786],
              [-38.467080915355055, -8.123741609087027],
              [-38.467101673622402, -8.123693877838324],
              [-38.467112989429701, -8.123648033705258],
              [-38.467122227573512, -8.123594588917552],
              [-38.467127737158776, -8.123546475889961],
              [-38.467132519041932, -8.123499537779697],
              [-38.467125501230683, -8.123452403313472],
              [-38.467116846840639, -8.123407347090334],
              [-38.46709400881101, -8.123311438500807],
              [-38.467079478799491, -8.123247922458605],
              [-38.467068923524963, -8.123198883875304],
              [-38.467112721122461, -8.123232046514593],
              [-38.467149791349811, -8.123273160079634],
              [-38.467175965765641, -8.123316972922058],
              [-38.467217817288869, -8.123380434284488],
              [-38.467264130086527, -8.123433590003245],
              [-38.467307430595632, -8.123499585899189],
              [-38.467353177820243, -8.123568660348148],
              [-38.467385982090441, -8.123609677858584],
              [-38.467417520969775, -8.123646623388991],
              [-38.467448961217649, -8.123689538592973],
              [-38.467473413826433, -8.123731268779078],
              [-38.467505943669558, -8.12377391405054],
              [-38.467540027197579, -8.123808511161505],
              [-38.467580469076552, -8.123839679429654],
              [-38.467627995576706, -8.123867329351818],
              [-38.467677448964132, -8.123879243212926],
              [-38.467731536568827, -8.123887364163737],
              [-38.467792541668942, -8.123881021900889],
              [-38.467852264925284, -8.123883089950539],
              [-38.46791761823151, -8.123883356321995],
              [-38.467977065134114, -8.123888499346235],
              [-38.468042519572052, -8.123880896541092],
              [-38.468100900351743, -8.123868490543588],
              [-38.468162738915495, -8.123849576542101],
              [-38.468221408899097, -8.123824326777761],
              [-38.468272365313432, -8.123797710150209],
              [-38.468326048950992, -8.123767931273482],
              [-38.468370473926669, -8.123738321195324],
              [-38.468404560418378, -8.123701732831529],
              [-38.468431763827937, -8.123653467215677],
              [-38.468454248088264, -8.123604562271305],
              [-38.468475737337229, -8.123553032929031],
              [-38.468493949387408, -8.123508735432711],
              [-38.468514621588028, -8.123457295471889],
              [-38.468529660531118, -8.123410099377239],
              [-38.468550241119992, -8.123359292455326],
              [-38.468560561391136, -8.123311095228152],
              [-38.468572703100051, -8.123258287347406],
              [-38.468583927364271, -8.123212895299632],
              [-38.468601731457163, -8.123133682942823],
              [-38.46860418410445, -8.123063224343388],
              [-38.468595466856776, -8.122996821479431],
              [-38.468591629823919, -8.122946615826168],
              [-38.468601988258648, -8.122869383679552],
              [-38.468633924632691, -8.12281117454673],
              [-38.468641839121027, -8.122866812620915],
              [-38.468668267076843, -8.122925007513746],
              [-38.468689186221255, -8.122961848632638],
              [-38.46872233011667, -8.123020775904186],
              [-38.468762270372792, -8.1230883049491],
              [-38.468818731370597, -8.123155222395678],
              [-38.468873731527829, -8.123228650444597],
              [-38.468914837317719, -8.123307216094654],
              [-38.468968109815876, -8.123382993619666],
              [-38.469026752070974, -8.123447833500775],
              [-38.46908367603762, -8.123507877196896],
              [-38.469123573879926, -8.123538682801438],
              [-38.469167382746775, -8.123563433246582],
              [-38.469226457287505, -8.123575630826499],
              [-38.469282908804921, -8.123580769750802],
              [-38.469336533781636, -8.123595583304018],
              [-38.469390344220045, -8.123607412188903],
              [-38.469450414619565, -8.123621600971351],
              [-38.469505771222607, -8.123631080115302],
              [-38.46956222156637, -8.123637123516266],
              [-38.469613957753467, -8.123638728635211],
              [-38.469663886163886, -8.123634542487734],
              [-38.469758941733332, -8.123618927787749],
              [-38.469806150756916, -8.123611934078498],
              [-38.469853445682183, -8.123608648992121],
              [-38.469941590829443, -8.123602160858967],
              [-38.470020752001574, -8.123594032875825],
              [-38.470089570394485, -8.123582182890926],
              [-38.470142614825569, -8.123555206931865],
              [-38.470183966209099, -8.123515914318837],
              [-38.470234798567489, -8.123445337781275],
              [-38.47026277722761, -8.12335944515873],
              [-38.470284937902626, -8.123280238380085],
              [-38.470298214797886, -8.123261441724422],
              [-38.470335859439245, -8.123210837813376],
              [-38.470340901158337, -8.12317312604549],
              [-38.470354853856684, -8.123123486260665],
              [-38.470355740724138, -8.123070120951938],
              [-38.47037461341791, -8.123006377114786],
              [-38.470424803266603, -8.123010422307278],
              [-38.470481251886866, -8.123017731897004],
              [-38.47057353765706, -8.123037660883622],
              [-38.470638145748374, -8.123052398101901],
              [-38.470711015452686, -8.123065879733364],
              [-38.470777020979391, -8.123053211901411],
              [-38.470843500719042, -8.123024986993068],
              [-38.470915946902899, -8.123015312417287],
              [-38.47098603274371, -8.123005906114733],
              [-38.471047480877516, -8.123007704575725],
              [-38.471102229653098, -8.122996107476849],
              [-38.471157681965202, -8.123001787548727],
              [-38.471217963483333, -8.12299336343192],
              [-38.471265757744085, -8.12295525494131],
              [-38.471274660652206, -8.122880101191154],
              [-38.47123878420917, -8.122828135330519],
              [-38.471217086971429, -8.122761444273966],
              [-38.471236696433394, -8.122689470243932],
              [-38.471257290968488, -8.122627718528356],
              [-38.47129704298947, -8.122561921376766],
              [-38.471340878398578, -8.122497034041379],
              [-38.471390599786794, -8.12244282764051],
              [-38.471439789800584, -8.122378489957576],
              [-38.471465950503557, -8.122294313447261],
              [-38.471504797331121, -8.122157148740621],
              [-38.471528793990728, -8.122061934311681],
              [-38.471544866225607, -8.121987784825794],
              [-38.471587259284483, -8.122054050481951],
              [-38.471619039423146, -8.122114965707741],
              [-38.471645534185818, -8.122191793560829],
              [-38.471674974819337, -8.122237057609441],
              [-38.471700570664431, -8.122307190870162],
              [-38.471725547691726, -8.122364660094659],
              [-38.471728697827437, -8.122454573128735],
              [-38.471773283122154, -8.122510349222811],
              [-38.471771797212355, -8.122605502434814],
              [-38.47176269020391, -8.122628555785329],
              [-38.471727077819558, -8.122652298341595],
              [-38.471602742407498, -8.122708126488563],
              [-38.471522211967418, -8.122722313275169],
              [-38.471445311912021, -8.122736776115756],
              [-38.471366423580477, -8.122744633388315],
              [-38.471325620786423, -8.122781032395162],
              [-38.471382299463428, -8.122820633396152],
              [-38.471434404913488, -8.122817444819868],
              [-38.471480895603342, -8.122804389714739],
              [-38.471556432958778, -8.12279082961509],
              [-38.471653395724971, -8.122774221963219],
              [-38.471723042452474, -8.122753508503166],
              [-38.471785615606393, -8.122727901459788],
              [-38.471830849466748, -8.122704171386586],
              [-38.471873332602264, -8.122701603425527],
              [-38.471891877870277, -8.122749295515726],
              [-38.471910353177933, -8.122781068023032],
              [-38.471927297360082, -8.122803702925486],
              [-38.471963742853546, -8.12283703642812],
              [-38.472016896165634, -8.12286550721671],
              [-38.472061691679222, -8.122899394255079],
              [-38.472103289027665, -8.122949558438528],
              [-38.472146075240801, -8.122992940286041],
              [-38.472192682018125, -8.123029995468999],
              [-38.472248464947732, -8.123060550028606],
              [-38.472296077640117, -8.123091908050707],
              [-38.472353114222393, -8.123135579713516],
              [-38.472412269321637, -8.123155646232078],
              [-38.472482577761937, -8.123184320109528],
              [-38.472567884304986, -8.123196732116416],
              [-38.472637205641675, -8.123216721328948],
              [-38.472699804905801, -8.123240681692609],
              [-38.472756762588944, -8.12327530803374],
              [-38.47281716029007, -8.123316994054141],
              [-38.472859050143569, -8.123351781763551],
              [-38.472946167040419, -8.12336808546098],
              [-38.47300624098483, -8.123379650630357],
              [-38.473065889516654, -8.1233694163879],
              [-38.47312859800121, -8.123379266369335],
              [-38.473206796969343, -8.123343277398705],
              [-38.473238890935463, -8.123302977501394],
              [-38.473308830984493, -8.123266163752978],
              [-38.473351000736535, -8.123225515080476],
              [-38.473385274778884, -8.123183951667977],
              [-38.473419577103613, -8.123120679886632],
              [-38.473445691846301, -8.123071507968351],
              [-38.473482221411452, -8.123040439859082],
              [-38.473527912835081, -8.123013815731861],
              [-38.473573729076236, -8.122961051225595],
              [-38.473611923682107, -8.122906015562382],
              [-38.473654106325576, -8.122855417185376],
              [-38.473700642437443, -8.12280744753245],
              [-38.473739013011823, -8.122756663311463],
              [-38.473786555919787, -8.122702272879669],
              [-38.473826838738397, -8.122646787634041],
              [-38.473872280871525, -8.122602434611048],
              [-38.473919359655604, -8.122555912857647],
              [-38.473962167830777, -8.122512822741728],
              [-38.474011966826041, -8.122468475332788],
              [-38.474078616040615, -8.12244929526269],
              [-38.474128587960529, -8.122411551034769],
              [-38.474173580899489, -8.122363579325887],
              [-38.474207152171374, -8.12230392458655],
              [-38.474247447953545, -8.122238399171165],
              [-38.474280393589972, -8.122171236125395],
              [-38.47431994978524, -8.122116021238339],
              [-38.474370303420791, -8.122063895650003],
              [-38.474414653030522, -8.122021983341998],
              [-38.474453940768548, -8.121963783186883],
              [-38.474484251943863, -8.121898244848712],
              [-38.474510021275719, -8.121835323742316],
              [-38.474539613104696, -8.121764538271236],
              [-38.474554497734154, -8.121695814198564],
              [-38.474566030629767, -8.121621930050889],
              [-38.4745645744689, -8.121555084366012],
              [-38.474578009847527, -8.1214840066759],
              [-38.474579096255979, -8.12141635020755],
              [-38.474575637905758, -8.121283743551727],
              [-38.474574726468376, -8.121216808117289],
              [-38.474609213608012, -8.121150732443363],
              [-38.474667737166541, -8.121097893738142],
              [-38.474792883566224, -8.121045955062373],
              [-38.474840669518201, -8.121013815914445],
              [-38.474855281521528, -8.120945272374993],
              [-38.47485329205378, -8.120799823465969],
              [-38.474844031301522, -8.120731791835095],
              [-38.474851202532136, -8.120661520111684],
              [-38.474863821025131, -8.120590350898382],
              [-38.474916287145966, -8.120449042563695],
              [-38.474954235028846, -8.12030409742437],
              [-38.474982322128696, -8.12020364180214],
              [-38.475003747588708, -8.120060665280956],
              [-38.474987937346704, -8.119933650687782],
              [-38.47497215608724, -8.119854213731527],
              [-38.474945726122691, -8.119727094988212],
              [-38.474921953889186, -8.119650270823193],
              [-38.474919400568609, -8.119589483979498],
              [-38.474946090543455, -8.119446514246647],
              [-38.474979438703457, -8.119348688501837],
              [-38.475008786364199, -8.119256013341678],
              [-38.475022138077719, -8.119179327505085],
              [-38.475033579902046, -8.119105533659544],
              [-38.475039415353123, -8.119085279999833],
              [-38.475053376472133, -8.119028584775917],
              [-38.47507204506428, -8.118981483502797],
              [-38.475097072095622, -8.118930772357714],
              [-38.475125015784997, -8.118870748443793],
              [-38.475127477909609, -8.118792058623216],
              [-38.475099605399436, -8.118727349710534],
              [-38.475057747853342, -8.118667868964515],
              [-38.475010158092985, -8.11861905412608],
              [-38.474963732819717, -8.11858218041097],
              [-38.474920462810665, -8.118561953876226],
              [-38.474865937257071, -8.118541984174906],
              [-38.474798939639079, -8.118550400216744],
              [-38.474714323328129, -8.118566301008684],
              [-38.474648509915959, -8.118571462291509],
              [-38.474537576247691, -8.118583258759708],
              [-38.474478211808083, -8.118584991172771],
              [-38.474413222474396, -8.118584635926592],
              [-38.474367648260603, -8.118591360969384],
              [-38.474299181645186, -8.118612619173703],
              [-38.474237694375383, -8.118641393870604],
              [-38.474177124420926, -8.118662752702537],
              [-38.474101766378944, -8.118678665321182],
              [-38.474055913259058, -8.118690455263247],
              [-38.473968644863611, -8.11872154835638],
              [-38.473919339501492, -8.118735685566216],
              [-38.473867309220523, -8.118751266475712],
              [-38.473820720056729, -8.118770653381896],
              [-38.473771039134043, -8.118794377967772],
              [-38.473729713196164, -8.118814766634472],
              [-38.473686384358246, -8.118839765743992],
              [-38.473605084085492, -8.118888142744551],
              [-38.473529964248321, -8.118930196101104],
              [-38.473473190091909, -8.118964132516989],
              [-38.473422500902977, -8.118995363243956],
              [-38.473372178783649, -8.119023519081326],
              [-38.473315864096968, -8.119053114386571],
              [-38.473246204530454, -8.119084230095941],
              [-38.473215046420208, -8.11910318463528],
              [-38.473172260596556, -8.119129360260345],
              [-38.473127669548674, -8.119147754699139],
              [-38.473089423049721, -8.11917321257604],
              [-38.47304664947255, -8.119189981227223],
              [-38.473000414333796, -8.119216242819869],
              [-38.472958170733975, -8.119244228156347],
              [-38.472922098398868, -8.119272945091486],
              [-38.472886384530632, -8.119305190102882],
              [-38.472828490154129, -8.11936282331221],
              [-38.472795587361681, -8.119397152343426],
              [-38.472725432097668, -8.119460287210369],
              [-38.472690534046556, -8.119493347324013],
              [-38.47262600435409, -8.119558027136767],
              [-38.472557660971965, -8.119623787416794],
              [-38.472521585152428, -8.119655127406148],
              [-38.472480147645307, -8.119691344834562],
              [-38.472443060381892, -8.119732633189836],
              [-38.472408219880236, -8.119791110271505],
              [-38.472368376386257, -8.119857811967179],
              [-38.47235414999453, -8.119908808271983],
              [-38.472346324821153, -8.11992345130029],
              [-38.472291603414241, -8.119914425650585],
              [-38.472210532369388, -8.119925988812534],
              [-38.47211944577748, -8.11996132775981],
              [-38.472076492435171, -8.119976648840806],
              [-38.472030901147278, -8.11999630817585],
              [-38.471942891421932, -8.120038706302299],
              [-38.471901196764811, -8.120063255066746],
              [-38.471856951380417, -8.12009485574716],
              [-38.471784175516845, -8.120148851414529],
              [-38.471726125689166, -8.120186313524353],
              [-38.47166638424217, -8.120198537415346],
              [-38.471599925957008, -8.120210752578471],
              [-38.471546271756878, -8.12021855222169],
              [-38.471540204842839, -8.120207418791054],
              [-38.471515317778099, -8.120150673289317],
              [-38.471484971748637, -8.120103779929781],
              [-38.47146078789774, -8.120064582960596],
              [-38.471391938035282, -8.120031569155323],
              [-38.471338230351208, -8.120010876430195],
              [-38.471279519431249, -8.119998589204165],
              [-38.471211996566595, -8.119991988977723],
              [-38.471135208151864, -8.119990894253727],
              [-38.471047055276649, -8.120003895206576],
              [-38.471000484197297, -8.120009261788136],
              [-38.470953646140138, -8.120010557692899],
              [-38.470903366121313, -8.120006331567666],
              [-38.47085689847767, -8.120002019940239],
              [-38.470805346525822, -8.119998696666165],
              [-38.470750160776973, -8.119997720404131],
              [-38.470700877553178, -8.119994761873105],
              [-38.470648064684198, -8.119983929452795],
              [-38.470597332848222, -8.119978165024568],
              [-38.470541248902421, -8.119969951434406],
              [-38.470485339741771, -8.119966893807916],
              [-38.470427892859327, -8.119959763849169],
              [-38.470379065025448, -8.119955630000765],
              [-38.470328417922545, -8.119954478683646],
              [-38.470281951124896, -8.119949533830912],
              [-38.47019255167563, -8.119944080840023],
              [-38.470105538993216, -8.119918188865304],
              [-38.470062086993117, -8.119898413842995],
              [-38.470022724061195, -8.119875206977431],
              [-38.469982731450919, -8.119847657609785],
              [-38.469909927231946, -8.11978469892173],
              [-38.469867035640391, -8.119752894541568],
              [-38.469827590863623, -8.119722903679065],
              [-38.46978642933265, -8.119686940768878],
              [-38.469749438267591, -8.119654691804341],
              [-38.469705277850998, -8.119621438529661],
              [-38.469663742353127, -8.119593796671754],
              [-38.469621755513835, -8.119564254737544],
              [-38.469583397377697, -8.119536255205142],
              [-38.469507491148015, -8.119485503380844],
              [-38.469465405610926, -8.119462021562784],
              [-38.469422958432119, -8.119437453849663],
              [-38.469383686826788, -8.119413885239439],
              [-38.469344688716987, -8.119389412465884],
              [-38.469307054029109, -8.119363494240091],
              [-38.469264701557421, -8.119335941731162],
              [-38.469220077402682, -8.119310285740632],
              [-38.469167367674977, -8.119290136752314],
              [-38.469113925277675, -8.119274961641739],
              [-38.469055400363686, -8.119259418089372],
              [-38.46900031572023, -8.119250662888337],
              [-38.468945237258154, -8.1192372042033],
              [-38.468892516148223, -8.119225738522333],
              [-38.468837715345131, -8.119208209862141],
              [-38.468786818749898, -8.119189781773031],
              [-38.468747621930461, -8.119178424172238],
              [-38.468654731372894, -8.119135971584868],
              [-38.468581965831845, -8.119112901895411],
              [-38.468519266006751, -8.11909708146414],
              [-38.468499936400981, -8.119094071325627],
              [-38.468449841287345, -8.119087402953507],
              [-38.468371509677077, -8.119086577059326],
              [-38.468307429555068, -8.119085046149802],
              [-38.468238188997987, -8.119073377908441],
              [-38.46817113734209, -8.119053752772411],
              [-38.468100913702095, -8.119030414965289],
              [-38.468012924830639, -8.118987878189975],
              [-38.467933272560757, -8.118956025582614],
              [-38.467849360413489, -8.118919463908366],
              [-38.4677561608042, -8.118905050721592],
              [-38.467675392922331, -8.118893276865617],
              [-38.467627111420654, -8.118887967540092],
              [-38.467553248204943, -8.118871408753675],
              [-38.467472671881424, -8.118852037173516],
              [-38.467388898441094, -8.118848038168878],
              [-38.467302466879332, -8.118863663668115],
              [-38.467256619072124, -8.118871292063327],
              [-38.46717617769206, -8.118887287179923],
              [-38.467097198842879, -8.118895505356566],
              [-38.467034369365066, -8.118909262206936],
              [-38.46696185230801, -8.118904373333498],
              [-38.466880931311358, -8.118871071639706],
              [-38.466838930332585, -8.118852474060233],
              [-38.466798932688171, -8.118828994713935],
              [-38.466759845349699, -8.118803526621061],
              [-38.466721486066369, -8.118776612252731],
              [-38.466669510806909, -8.118750313205998],
              [-38.466617802606933, -8.118727994373266],
              [-38.466566356810596, -8.118713183360722],
              [-38.466507925730454, -8.118695468737585],
              [-38.466444058625541, -8.118670149048386],
              [-38.466392360835819, -8.118639960913118],
              [-38.466351094045955, -8.118615303991437],
              [-38.466290487434314, -8.118595415648743],
              [-38.466235250446857, -8.118564680145756],
              [-38.466189706133861, -8.118548700953832],
              [-38.46614241598148, -8.118548819851277],
              [-38.466073223487101, -8.118569623435658],
              [-38.466015111561752, -8.118585195348315],
              [-38.46595436450469, -8.118602934634055],
              [-38.465912399525443, -8.118625854289949],
              [-38.465868058093363, -8.118661253142655],
              [-38.465842200025541, -8.118721821781795],
              [-38.465837022032275, -8.118793724000337],
              [-38.465816812458961, -8.118838471019991],
              [-38.465807377165056, -8.118835021479688],
              [-38.465790370932275, -8.118791039694388],
              [-38.465766664033787, -8.118734838151827],
              [-38.465736517302631, -8.118675100545639],
              [-38.465717365053457, -8.11860660356184],
              [-38.465712648441063, -8.118535411973637],
              [-38.465696849819643, -8.118470537453417],
              [-38.465683202011405, -8.118426560071846],
              [-38.465656592139013, -8.118369178843878],
              [-38.465621611816516, -8.118326801591891],
              [-38.465577927622896, -8.11827699588288],
              [-38.465554562927224, -8.118236623807004],
              [-38.465537555308785, -8.118193727430349],
              [-38.465513114564189, -8.118143404265656],
              [-38.465491207662055, -8.118098873327558],
              [-38.465467668265447, -8.11805334527784],
              [-38.465418482223903, -8.11797712046843],
              [-38.46537672047284, -8.117915015849364],
              [-38.465330520459879, -8.117845850175229],
              [-38.46528746849453, -8.117798487462318],
              [-38.465216732842485, -8.117750817032416],
              [-38.46518128956891, -8.117715403911536],
              [-38.465152553396564, -8.117687326176007],
              [-38.46509943478641, -8.117633528160145],
              [-38.46503669820153, -8.117577184857835],
              [-38.464960693565175, -8.11753294462496],
              [-38.464918873878574, -8.11751470890519],
              [-38.464873792676748, -8.117491855864557],
              [-38.464833156685067, -8.117470817693132],
              [-38.464789167694285, -8.117445704789723],
              [-38.464741817445052, -8.117422758301441],
              [-38.464695186155531, -8.11740523984775],
              [-38.464650090729535, -8.117393150502741],
              [-38.464602729716781, -8.117378344626781],
              [-38.464557286798126, -8.117354496110167],
              [-38.464506853121485, -8.117329555606847],
              [-38.464444144424782, -8.117320789785236],
              [-38.46437283428557, -8.117295821808497],
              [-38.464305889732273, -8.117264256598215],
              [-38.464291097063473, -8.117262428106137],
              [-38.464230202411073, -8.117254659606315],
              [-38.464191250428755, -8.117264286672439],
              [-38.464154295924139, -8.117273464105295],
              [-38.464072122652908, -8.117295154792021],
              [-38.464001474660279, -8.117318488760597],
              [-38.463948538990721, -8.117332258167711],
              [-38.463907845869144, -8.117354365229797],
              [-38.463899671896741, -8.117358062982609],
              [-38.46386050862219, -8.117390121725851],
              [-38.463839969908264, -8.11740917996315],
              [-38.463781027028276, -8.117435423753408],
              [-38.46373010201323, -8.117438793848638],
              [-38.463697673493591, -8.117457293709876],
              [-38.46371960947355, -8.117411282754777],
              [-38.463695108946538, -8.11733780381752],
              [-38.463662950266261, -8.117289731681682],
              [-38.463662125372231, -8.117227319016653],
              [-38.463658069038587, -8.117206057556615],
              [-38.463578524896711, -8.117093250132669],
              [-38.456646788285404, -8.124128328228984],
              [-38.45426647649149, -8.12882327797654],
              [-38.458219156407189, -8.131654175628595],
              [-38.46442074098195, -8.129362392566874],
              [-38.46863581253762, -8.1333106162321],
              [-38.465763880888574, -8.135935100986481],
              [-38.470504783858424, -8.141198171248977],
              [-38.470165460317205, -8.148458544582015],
              [-38.47801507556526, -8.146727436003038],
              [-38.480705319968607, -8.154777467019869],
              [-38.481221041355873, -8.154196704154108],
              [-38.481865832254563, -8.155811457566283],
              [-38.488536842551817, -8.158141193047509],
              [-38.488815178520738, -8.158273241986675],
              [-38.491843058080825, -8.162646684670776],
              [-38.499816538653846, -8.167617713593472],
              [-38.504027143933868, -8.175639463905418],
              [-38.495450587603919, -8.178322958788623],
              [-38.49403258113572, -8.178321096010473],
              [-38.484442859570819, -8.173807927300238],
              [-38.480316047451367, -8.177613911982805],
              [-38.474835838842992, -8.173410712058059],
              [-38.47199949306578, -8.176571554121201],
              [-38.468304077460367, -8.18472757742474],
              [-38.478747532302513, -8.192067847666271],
              [-38.483825448673755, -8.192370060085439],
              [-38.486201246389321, -8.191124589037468],
              [-38.488575688075585, -8.190930510560099],
              [-38.488771711260824, -8.192409190016575],
              [-38.490485314952736, -8.193232838550646],
              [-38.494699589729358, -8.198527659405785],
              [-38.501588393173606, -8.201197412135231],
              [-38.50801283202253, -8.206002142598022],
              [-38.51724510439545, -8.207031888639712],
              [-38.524532409647605, -8.207500503287184],
              [-38.526740581500839, -8.208718687974695],
              [-38.532523263050891, -8.226894179657066],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0393000',
        uf: 'PE',
        nome_proje: 'PA JATOBA',
        municipio: 'SERTANIA',
        area_hecta: '1628.9974',
        capacidade: 31.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '21/10/2011',
        forma_obte: 'Desapropriação',
        data_obten: '23/04/2008',
        area_calc_: 1633.3709,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.508115678150546, -8.42547141197139],
              [-37.508768123981284, -8.42514953836519],
              [-37.509373983398412, -8.424827396215949],
              [-37.509885610225169, -8.424829338076409],
              [-37.510018801340557, -8.424837167864457],
              [-37.513864129342096, -8.412008467315905],
              [-37.521063790581451, -8.388263257087022],
              [-37.524211134164361, -8.377238842466927],
              [-37.527361754408247, -8.366949536966146],
              [-37.5273947563661, -8.366841692072944],
              [-37.531604335097754, -8.353084825652198],
              [-37.535075244672115, -8.341784699557074],
              [-37.535654462248331, -8.339894861243046],
              [-37.538097598143509, -8.331888544478767],
              [-37.542565930171499, -8.317244537593295],
              [-37.536384918936392, -8.317947138986048],
              [-37.537164592744389, -8.315209198968395],
              [-37.538587744185961, -8.310211735786874],
              [-37.540600380092691, -8.303143971804023],
              [-37.541089099891423, -8.301563576393523],
              [-37.540703850784844, -8.301331307353816],
              [-37.540471782934375, -8.301214530862344],
              [-37.540193404910404, -8.301051376817643],
              [-37.53979865400094, -8.300934003802578],
              [-37.539288037996045, -8.300700460243991],
              [-37.538846972879369, -8.300513469363159],
              [-37.538545184878181, -8.30039643620394],
              [-37.538196996666962, -8.300232934470712],
              [-37.537825744784286, -8.300046198777007],
              [-37.537565316841764, -8.299915206040085],
              [-37.53738519557993, -8.299720223649301],
              [-37.537316674933059, -8.299395523635253],
              [-37.537248068699185, -8.299093972363751],
              [-37.537155530804803, -8.298954647890719],
              [-37.536900396649465, -8.298791486934133],
              [-37.536776730580947, -8.298551223142255],
              [-37.536686235239522, -8.29886976296374],
              [-37.532866920923119, -8.311270607910618],
              [-37.531045083330575, -8.317185544322841],
              [-37.527358025825073, -8.329155594318872],
              [-37.525375950043241, -8.33559002362737],
              [-37.524781373739742, -8.337520141212071],
              [-37.521267179276812, -8.348720321303608],
              [-37.518485910070851, -8.357902899852755],
              [-37.516607243939738, -8.364120532278289],
              [-37.516057633002966, -8.365939477503463],
              [-37.515662838802378, -8.367242976617414],
              [-37.515629212874074, -8.367346929360934],
              [-37.515434430395693, -8.36795141138496],
              [-37.514549085014345, -8.370905463006938],
              [-37.510547168645971, -8.384190376954203],
              [-37.50672434542242, -8.39710978068293],
              [-37.498570120382198, -8.424779724399659],
              [-37.499550843124545, -8.425066304928125],
              [-37.500814759846641, -8.42507286503422],
              [-37.501419286987129, -8.425121560207332],
              [-37.502163386797648, -8.425124399367174],
              [-37.502814231422022, -8.425219566254579],
              [-37.503697285867794, -8.425408301827463],
              [-37.504628169755328, -8.425226478611171],
              [-37.505093301024587, -8.425228249732605],
              [-37.505930661099484, -8.425185049206322],
              [-37.506581863386302, -8.425187526519599],
              [-37.507232177223301, -8.425421756796554],
              [-37.508115678150546, -8.42547141197139],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0017000',
        uf: 'PE',
        nome_proje: 'PA BARRA DA FORQUILHA',
        municipio: 'FLORESTA',
        area_hecta: '750.0000',
        capacidade: 30.0,
        num_famili: 30.0,
        fase: 5.0,
        data_de_cr: '25/07/1996',
        forma_obte: 'Adjudicação',
        data_obten: '25/07/1996',
        area_calc_: 633.5287,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.503792598483301, -8.492239890828237],
              [-38.507494915531915, -8.49152044062502],
              [-38.507713018840811, -8.491478385339548],
              [-38.507712927986908, -8.49147838522479],
              [-38.507764818353387, -8.49146841075844],
              [-38.508253369443857, -8.491374325793972],
              [-38.509617423602037, -8.491110300786453],
              [-38.50998840477687, -8.490159861109218],
              [-38.509896923366824, -8.490010050503583],
              [-38.508985089984733, -8.489458149927929],
              [-38.508089832828091, -8.489090335021464],
              [-38.508892453596594, -8.488930797248717],
              [-38.508892544334962, -8.48893088781351],
              [-38.510654545543595, -8.488580798542424],
              [-38.512579121432509, -8.488198342192801],
              [-38.519461602259568, -8.48683076906797],
              [-38.519461602147125, -8.48683085951847],
              [-38.520080191909614, -8.486707884784952],
              [-38.520080282650241, -8.486707975347292],
              [-38.524641950760554, -8.48580154787583],
              [-38.524641950871775, -8.485801457425254],
              [-38.524910668599034, -8.485748057121771],
              [-38.524133505993042, -8.483243885931302],
              [-38.52374642662889, -8.480922447904177],
              [-38.523063585986357, -8.478121168845629],
              [-38.522831806319793, -8.477104762006531],
              [-38.522565903464454, -8.477164134401667],
              [-38.521038187583002, -8.477505161770127],
              [-38.519804646141615, -8.47777192315904],
              [-38.517704415205024, -8.478232712799736],
              [-38.516659891580296, -8.478458180638878],
              [-38.514742321391161, -8.47887395475022],
              [-38.510990865031459, -8.479689026404953],
              [-38.50987572126084, -8.479931663201826],
              [-38.507300647505438, -8.480489304519597],
              [-38.506055815663842, -8.480762429093717],
              [-38.503991987564078, -8.481223189138193],
              [-38.503991987448089, -8.481223279588509],
              [-38.503460080761137, -8.481341997734495],
              [-38.503260240801211, -8.481386606820564],
              [-38.502611025150181, -8.481520189203641],
              [-38.499839089401938, -8.482123206506847],
              [-38.498932630087296, -8.482287839071111],
              [-38.497935387962805, -8.482468814645266],
              [-38.495561079791891, -8.482947038482859],
              [-38.495279649400963, -8.483064259959276],
              [-38.494360599437684, -8.483266764058682],
              [-38.491680544571459, -8.483830495091061],
              [-38.489653324200319, -8.48427331584694],
              [-38.48630682193555, -8.484999761032974],
              [-38.486149647622412, -8.484179441831685],
              [-38.485624096981745, -8.484294706357664],
              [-38.484998488713508, -8.484431907917257],
              [-38.484998579444934, -8.48443199848723],
              [-38.484035538316476, -8.484643102878822],
              [-38.48074739167388, -8.485340180440286],
              [-38.476659624130299, -8.486252694869389],
              [-38.473173175469817, -8.486977932493419],
              [-38.473651882807694, -8.489308662251954],
              [-38.474116371405188, -8.491876713168562],
              [-38.474495454042916, -8.493649502268704],
              [-38.47450303740554, -8.493685059353796],
              [-38.474506377832846, -8.493700621262194],
              [-38.474851534117484, -8.495837335165854],
              [-38.474851443263027, -8.495837335043372],
              [-38.47490649206695, -8.49617804400541],
              [-38.475005006519474, -8.496625180759521],
              [-38.475005097251106, -8.496625271331814],
              [-38.475493428964214, -8.498841321415464],
              [-38.477165006099618, -8.498423790591275],
              [-38.47852366227184, -8.498150010898947],
              [-38.478633375629059, -8.497439491673955],
              [-38.478633466483956, -8.497439491795562],
              [-38.478662796812308, -8.497249405026476],
              [-38.480112541812055, -8.496933229834273],
              [-38.480112632666838, -8.496933229955527],
              [-38.480965829075231, -8.496747226537693],
              [-38.482758445002837, -8.496304415547678],
              [-38.48692441681353, -8.495481310800015],
              [-38.489430603884784, -8.494962162915606],
              [-38.489849558382467, -8.494875336806027],
              [-38.489971877016892, -8.494853879346087],
              [-38.493046014103399, -8.49431528033695],
              [-38.494928358590734, -8.493952305722667],
              [-38.497269544985109, -8.493496297961753],
              [-38.501503783074341, -8.492675925689205],
              [-38.503792598483301, -8.492239890828237],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0021000',
        uf: 'PE',
        nome_proje: 'PA VARZEA DO EXU',
        municipio: 'FLORESTA',
        area_hecta: '451.2547',
        capacidade: 15.0,
        num_famili: 15.0,
        fase: 5.0,
        data_de_cr: '12/08/1996',
        forma_obte: 'Adjudicação',
        data_obten: '12/08/1996',
        area_calc_: 431.0415,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.466718991583051, -8.622749246940868],
              [-38.465847668294757, -8.62683417306471],
              [-38.464958797944298, -8.630714565009285],
              [-38.464659796195114, -8.632058675910315],
              [-38.464119136177324, -8.634488743966786],
              [-38.463493592537134, -8.637230831957933],
              [-38.463281188821767, -8.6381940899201],
              [-38.463161539796467, -8.638736617510114],
              [-38.463150654260801, -8.638785987520686],
              [-38.462712760074702, -8.640772180577997],
              [-38.462080011785446, -8.643643324978274],
              [-38.461936757235151, -8.644293091365107],
              [-38.461317717938172, -8.647101752199239],
              [-38.459937449295694, -8.653359879959964],
              [-38.45923083147985, -8.656561411800114],
              [-38.459110163299947, -8.657108277028041],
              [-38.458769015440666, -8.658654111196117],
              [-38.458657494526939, -8.659159382534584],
              [-38.458299051564914, -8.660783520258189],
              [-38.456186589232594, -8.670337630356737],
              [-38.455707447471582, -8.672505460916367],
              [-38.455537088943537, -8.673275933289926],
              [-38.455491982765274, -8.673480193069119],
              [-38.45409916878674, -8.679776883377935],
              [-38.453598958721393, -8.682037660048588],
              [-38.452428511523593, -8.687327870696684],
              [-38.452406917478683, -8.687425524389552],
              [-38.451398944007003, -8.69197916619591],
              [-38.450987090544615, -8.693839827550994],
              [-38.450572120859761, -8.695714864888711],
              [-38.45024370546335, -8.697202182170269],
              [-38.450236750746434, -8.697233829184604],
              [-38.450117577681773, -8.697670253113115],
              [-38.45485181516068, -8.697956094624086],
              [-38.454910375941836, -8.697691073048944],
              [-38.455129246869767, -8.696699977014173],
              [-38.455970241729823, -8.69287845032281],
              [-38.457309508124069, -8.686798397796444],
              [-38.45782344455273, -8.684464281321185],
              [-38.457903503533103, -8.684100609662378],
              [-38.457974503753945, -8.683778441142714],
              [-38.458123824743922, -8.683099924739036],
              [-38.45831783466005, -8.682218834895394],
              [-38.458581389704257, -8.681021606127091],
              [-38.459277190572386, -8.677864839520447],
              [-38.459607290821708, -8.676367381906104],
              [-38.459667034604813, -8.676096119598041],
              [-38.45971040118188, -8.675899364038367],
              [-38.459756328984227, -8.675691305973155],
              [-38.460195571861398, -8.673698703261543],
              [-38.460293648859604, -8.673253380663832],
              [-38.460427130937632, -8.672648013336394],
              [-38.46048778952126, -8.672372905492464],
              [-38.460581472331036, -8.671947703937734],
              [-38.460672136960525, -8.671536560399909],
              [-38.461896325831283, -8.665978385434233],
              [-38.463480392606961, -8.659087036028229],
              [-38.463626223697695, -8.658422303097421],
              [-38.463636647135885, -8.658374697189599],
              [-38.463720393896701, -8.65799305138226],
              [-38.463779139631221, -8.657725272803024],
              [-38.463921526585871, -8.657075795133602],
              [-38.464039447817093, -8.656538330831069],
              [-38.464052712450204, -8.656478110303032],
              [-38.464105220003802, -8.656238901595886],
              [-38.464229822415568, -8.65567073818451],
              [-38.464314900475536, -8.655283101916902],
              [-38.464421480916272, -8.654794373583952],
              [-38.464763705559633, -8.653239127276267],
              [-38.465569614914124, -8.649585112488424],
              [-38.466964724440416, -8.643230739521112],
              [-38.468054222011943, -8.63823402838281],
              [-38.469119684188229, -8.633373584983332],
              [-38.470320670487922, -8.627894649403565],
              [-38.471178055386801, -8.623983175292532],
              [-38.470161936167244, -8.624081085864896],
              [-38.466718991583051, -8.622749246940868],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0015000',
        uf: 'PE',
        nome_proje: 'PA PEDRO JORGE DE ALBUQUERQUE',
        municipio: 'FLORESTA',
        area_hecta: '1108.7191',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 5.0,
        data_de_cr: '21/10/1992',
        forma_obte: 'Adjudicação',
        data_obten: '29/11/1989',
        area_calc_: 1106.2727,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.237698408679492, -8.661775886767304],
              [-38.241690244090158, -8.643588758614849],
              [-38.244884011211923, -8.630086675717319],
              [-38.245931134903579, -8.623960327069625],
              [-38.247693140870012, -8.61669783686979],
              [-38.244988916029641, -8.615653132524155],
              [-38.242581474355092, -8.614721509544404],
              [-38.246175917716627, -8.598228851796534],
              [-38.244109194005631, -8.599214727519984],
              [-38.24322549719151, -8.59918242092415],
              [-38.24256825684374, -8.599152457242781],
              [-38.240411609511142, -8.600885848195768],
              [-38.238314787317918, -8.602003862095982],
              [-38.237271416260299, -8.602108250188001],
              [-38.232411656255664, -8.624017650386724],
              [-38.228147200205726, -8.623194461140331],
              [-38.225681758898375, -8.634139867632109],
              [-38.2208160610897, -8.655642973420552],
              [-38.225063306154503, -8.656468589572802],
              [-38.223911167381999, -8.662384789295601],
              [-38.226120822597053, -8.661569294736738],
              [-38.226889269758637, -8.661670791130618],
              [-38.228000589052648, -8.662022425846668],
              [-38.228671477999271, -8.662453393840611],
              [-38.231474866048849, -8.663315554738412],
              [-38.233680779940656, -8.662099878857617],
              [-38.234170025188355, -8.662062691394112],
              [-38.235510184136729, -8.662294833031119],
              [-38.236504839672321, -8.661911886142871],
              [-38.237698408679492, -8.661775886767304],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0008000',
        uf: 'PE',
        nome_proje: 'PA VÁRZEA COMPRIDA',
        municipio: 'FLORESTA',
        area_hecta: '729.3000',
        capacidade: 16.0,
        num_famili: 14.0,
        fase: 5.0,
        data_de_cr: '31/01/1990',
        forma_obte: 'Adjudicação',
        data_obten: '31/01/1990',
        area_calc_: 457.4894,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.379197061991569, -8.698727197448598],
              [-38.395993138990654, -8.621631583691238],
              [-38.394500528743222, -8.621424910748809],
              [-38.39196516917162, -8.621533422695476],
              [-38.37529115519186, -8.692613427208265],
              [-38.373268646943359, -8.700533671238345],
              [-38.378653830821314, -8.701541583447675],
              [-38.379197061991569, -8.698727197448598],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0261000',
        uf: 'PE',
        nome_proje: 'PA MARIA BONITA I',
        municipio: 'FLORESTA',
        area_hecta: '1185.5221',
        capacidade: 30.0,
        num_famili: 23.0,
        fase: 3.0,
        data_de_cr: '25/08/2008',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2007',
        area_calc_: 1262.3318,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.57832708586141, -8.749636475840717],
              [-38.576527113145445, -8.747469724359984],
              [-38.582640015573233, -8.728116181695802],
              [-38.581245937745742, -8.722688081904185],
              [-38.579936401163209, -8.722016579954728],
              [-38.623897450730546, -8.676285035563259],
              [-38.623513620071314, -8.675973867711479],
              [-38.622367027514528, -8.676963896506518],
              [-38.620980727848362, -8.676049148965951],
              [-38.621308856412554, -8.675522507510179],
              [-38.620504673800674, -8.674886743415085],
              [-38.620108944253886, -8.674571037919366],
              [-38.617223317662933, -8.673265572308482],
              [-38.569667210195135, -8.723617446834989],
              [-38.568940525502519, -8.72373131333816],
              [-38.565865463886738, -8.726979219592069],
              [-38.565571846052244, -8.729316475590391],
              [-38.564023304059631, -8.741644938180773],
              [-38.563716347089361, -8.742295734422909],
              [-38.563621517973992, -8.742925247619187],
              [-38.56298544287354, -8.750286237204977],
              [-38.562579902589867, -8.753212276549938],
              [-38.561953131038635, -8.755689427073351],
              [-38.562250264442042, -8.755910921006391],
              [-38.562133341266701, -8.759877737515961],
              [-38.561528337036414, -8.762619295979428],
              [-38.564118437040321, -8.764272097200257],
              [-38.564226733341393, -8.764106879654619],
              [-38.564356309171508, -8.763859829437694],
              [-38.564438219317594, -8.763632985025128],
              [-38.564482635247607, -8.763514456377104],
              [-38.564510044797252, -8.763321558310235],
              [-38.564455840227609, -8.763101340798141],
              [-38.564384558893252, -8.762945413484305],
              [-38.564371149839339, -8.76282690865874],
              [-38.564289657906905, -8.762694757888305],
              [-38.564225132570193, -8.762593017826621],
              [-38.564208241036198, -8.762498206856897],
              [-38.564204853203094, -8.762440586362755],
              [-38.564103011168996, -8.76229493506329],
              [-38.563987566935971, -8.762118695985682],
              [-38.563875400063132, -8.76201690092682],
              [-38.563790378469051, -8.761949146318884],
              [-38.563664479106848, -8.762006527183374],
              [-38.56354525234886, -8.762111401903359],
              [-38.563436421926966, -8.762111276256233],
              [-38.563361702035351, -8.762019745126921],
              [-38.563307387679487, -8.761894318841572],
              [-38.563266711137359, -8.761768998752109],
              [-38.563222615290144, -8.761691070454738],
              [-38.563304339801299, -8.761623417878841],
              [-38.563409825802587, -8.761606625564303],
              [-38.563508437573049, -8.761637221032148],
              [-38.563637712865926, -8.761647500636377],
              [-38.563770375797432, -8.761637523326854],
              [-38.563862263439432, -8.761586796482169],
              [-38.563991662979937, -8.761490435668842],
              [-38.564093863826969, -8.761405892390901],
              [-38.564185810463947, -8.761304423130923],
              [-38.564219987690656, -8.761155491519004],
              [-38.564260960226399, -8.761026828512366],
              [-38.564318922797526, -8.760908315465224],
              [-38.564383719942924, -8.760776333242903],
              [-38.564414477868731, -8.760674793419879],
              [-38.564448595883171, -8.760576604110033],
              [-38.564557516481919, -8.760498852169366],
              [-38.564717513797746, -8.760438073125806],
              [-38.564925089203733, -8.760353650874068],
              [-38.56508876246923, -8.760259047624356],
              [-38.565245316560684, -8.760188043546025],
              [-38.565367882593762, -8.760103523299245],
              [-38.565480297060269, -8.759991946816562],
              [-38.565538231570343, -8.75989713139999],
              [-38.565623415532016, -8.759746539663073],
              [-38.565742746941567, -8.759550309896229],
              [-38.56580071667215, -8.759425012842472],
              [-38.565855310517207, -8.759309932744966],
              [-38.565886115009093, -8.759167780800372],
              [-38.565889640119941, -8.759028853739046],
              [-38.565828598501035, -8.758824005007531],
              [-38.56576071324546, -8.758718914694345],
              [-38.565716621180094, -8.75863754957442],
              [-38.565723530413052, -8.758559680108952],
              [-38.565767756034759, -8.758525902606776],
              [-38.565873256813589, -8.758495542081077],
              [-38.565984803392233, -8.75842620453661],
              [-38.566076737025078, -8.758334865120704],
              [-38.566131255417503, -8.758206217386624],
              [-38.566178990783612, -8.758125048085279],
              [-38.566233529390239, -8.758057363620598],
              [-38.566298317474825, -8.757932164730704],
              [-38.566342597976622, -8.757850900992979],
              [-38.566363121334319, -8.757793307902205],
              [-38.566370010818268, -8.757732352532749],
              [-38.566393921606242, -8.757654502533271],
              [-38.566441534603776, -8.757600377586124],
              [-38.566489214940709, -8.75756660394905],
              [-38.566570800784227, -8.757617439863493],
              [-38.566611477962759, -8.757742850106794],
              [-38.566652116009728, -8.757902088584213],
              [-38.566713242127285, -8.758034215540746],
              [-38.566781151094617, -8.758118954480883],
              [-38.566845719658595, -8.758183338344823],
              [-38.5668796407843, -8.758254561302955],
              [-38.566855734028202, -8.75832897423992],
              [-38.566750135277687, -8.758444086539674],
              [-38.566698985017638, -8.758569301130123],
              [-38.566692036724838, -8.758681089309919],
              [-38.566746430340068, -8.758738677789536],
              [-38.566831411750123, -8.758762473012318],
              [-38.566848354698088, -8.758813325289198],
              [-38.566841491603654, -8.758930088317038],
              [-38.56677317019205, -8.759123030259099],
              [-38.566664081745955, -8.759346497558163],
              [-38.566517428846467, -8.759661276206563],
              [-38.566404900985908, -8.759871081526247],
              [-38.566254926729151, -8.760148681311012],
              [-38.566036581167992, -8.760661462846477],
              [-38.56588991144514, -8.760989808650526],
              [-38.565770576182111, -8.761267352911908],
              [-38.56576009797098, -8.76144344688765],
              [-38.565780350548771, -8.761619485703706],
              [-38.565814165644802, -8.761782153503406],
              [-38.565820831367233, -8.761914218049656],
              [-38.565773087425022, -8.762002261447401],
              [-38.565708451663589, -8.761995403465235],
              [-38.565640514780625, -8.761934362175687],
              [-38.56557244772786, -8.761907148996402],
              [-38.565538314112196, -8.76201890587941],
              [-38.565578893161344, -8.762228886774865],
              [-38.565609316681545, -8.762415248568358],
              [-38.565666992132158, -8.762543934618419],
              [-38.565711122507878, -8.762670977002852],
              [-38.565758445394138, -8.762867488588588],
              [-38.56577056427647, -8.763001277940266],
              [-38.565985313149312, -8.762612318521381],
              [-38.5665294864828, -8.762673906087036],
              [-38.568796562311178, -8.762599344806837],
              [-38.569159767251065, -8.760878496059396],
              [-38.569050836850153, -8.760254809845527],
              [-38.569062923861011, -8.760259346118014],
              [-38.56906301477995, -8.760259346221625],
              [-38.569223937894051, -8.760341477242177],
              [-38.569524535200472, -8.760483192854656],
              [-38.569873393805366, -8.760639706482392],
              [-38.56827389115444, -8.767870721343831],
              [-38.570268306635754, -8.76874736656946],
              [-38.570343727941733, -8.768784989075336],
              [-38.570482128653111, -8.768847828256561],
              [-38.570614150708451, -8.768923051782325],
              [-38.570695958059709, -8.768941958339601],
              [-38.570802953184014, -8.76887939809054],
              [-38.570897393904289, -8.768823155047142],
              [-38.571048435332344, -8.768804512975706],
              [-38.57116814016986, -8.768679375679763],
              [-38.571237582468797, -8.768460203800419],
              [-38.571263040536209, -8.768222168403039],
              [-38.57125708299224, -8.767946560620054],
              [-38.571288997758074, -8.767548707457761],
              [-38.571232691703713, -8.767254228946273],
              [-38.571182627994503, -8.767066307565551],
              [-38.571113432686829, -8.766991065118358],
              [-38.57102535221145, -8.766972151547336],
              [-38.570912170504371, -8.766959450501805],
              [-38.570666847706249, -8.766896580487701],
              [-38.570578760114387, -8.766883907853485],
              [-38.570572551299527, -8.766827550475126],
              [-38.570604060176706, -8.766783718050878],
              [-38.570667120999488, -8.766658516509317],
              [-38.570686063750472, -8.766552078428436],
              [-38.570755424261982, -8.76648323434943],
              [-38.570837147438972, -8.766495809260919],
              [-38.570868548426148, -8.766545954198449],
              [-38.570893704653734, -8.766571037403404],
              [-38.57098184250836, -8.766539841786789],
              [-38.571095107855733, -8.766558783948266],
              [-38.571290153873207, -8.766540191675722],
              [-38.571560905455236, -8.766390170883101],
              [-38.571825367883491, -8.766174204728916],
              [-38.57221575097779, -8.76596163712594],
              [-38.572455000347873, -8.765849207184985],
              [-38.572624946911873, -8.765755421869772],
              [-38.572738433960232, -8.765580167662042],
              [-38.572738555669858, -8.765473617714722],
              [-38.572629726558553, -8.765312222343574],
              [-38.572561451722201, -8.765227122148438],
              [-38.572498879043685, -8.765164640888258],
              [-38.57236827806743, -8.764881022891595],
              [-38.572551050697598, -8.76438348334282],
              [-38.572642328689298, -8.764229369316563],
              [-38.572745067676827, -8.764070745718175],
              [-38.57281919308371, -8.763968711449397],
              [-38.572973100383884, -8.763827150199477],
              [-38.573104316420576, -8.763651554055967],
              [-38.573229830284113, -8.763453248471569],
              [-38.573486300673167, -8.763226779756499],
              [-38.573588858104273, -8.763226895450247],
              [-38.573625674608067, -8.76323218307618],
              [-38.573708294781106, -8.763334756110728],
              [-38.573719556921844, -8.763504814825909],
              [-38.573679481631814, -8.763646504794387],
              [-38.573605330747455, -8.763771151720126],
              [-38.573462730416935, -8.763963830250436],
              [-38.57329169799722, -8.764133683223534],
              [-38.573114892153711, -8.7643432371391],
              [-38.572983662854305, -8.764530229953152],
              [-38.573009927995187, -8.764778635308998],
              [-38.573116585353496, -8.765010669568108],
              [-38.573179450067251, -8.765136013805961],
              [-38.573254756545403, -8.765273944631707],
              [-38.573355183708955, -8.765468254148814],
              [-38.573443121116931, -8.76561244024886],
              [-38.573450380299214, -8.765625111441002],
              [-38.573842753178674, -8.764143530781721],
              [-38.574332847137661, -8.762635513568901],
              [-38.574332891384614, -8.762635557687057],
              [-38.574332982510001, -8.762635376889591],
              [-38.574394289699541, -8.762531428388154],
              [-38.57832708586141, -8.749636475840717],
            ],
            [
              [-38.574332847137661, -8.762635513568901],
              [-38.574332528117942, -8.762635195477962],
              [-38.574332891590423, -8.76263537678722],
              [-38.574332847137661, -8.762635513568901],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0385000',
        uf: 'PE',
        nome_proje: 'PA CARRAPICHO',
        municipio: 'PESQUEIRA',
        area_hecta: '373.2317',
        capacidade: 28.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '29/07/2009',
        forma_obte: 'Desapropriação',
        data_obten: '29/11/2006',
        area_calc_: 369.3083,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.703303888319034, -8.464658375253629],
              [-36.712028578293783, -8.457090257153077],
              [-36.720689800315505, -8.449576527536705],
              [-36.716827022513904, -8.444034952165966],
              [-36.698809946705701, -8.448896267597309],
              [-36.695895183200371, -8.449480894436519],
              [-36.694706623072491, -8.449163972305769],
              [-36.694084236163953, -8.452955857885492],
              [-36.694418443831864, -8.456198697374404],
              [-36.694632466890404, -8.456875754057238],
              [-36.695832113056021, -8.458706486706935],
              [-36.696550364380037, -8.458536730634904],
              [-36.696567512134941, -8.459302107088403],
              [-36.696592380401043, -8.459455724443245],
              [-36.696722755895493, -8.459605624963588],
              [-36.697124689770888, -8.459749080952973],
              [-36.697782667814252, -8.460450082927686],
              [-36.699720657180841, -8.46229482941076],
              [-36.703303888319034, -8.464658375253629],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0029000',
        uf: 'PE',
        nome_proje: 'PA NEWTON  HOLANDA',
        municipio: 'FLORESTA',
        area_hecta: '396.0100',
        capacidade: 13.0,
        num_famili: 12.0,
        fase: 5.0,
        data_de_cr: '19/12/1997',
        forma_obte: 'Adjudicação',
        data_obten: '12/12/1996',
        area_calc_: 314.316,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.465660795216053, -8.697808788548921],
              [-38.466760521110828, -8.692868650512885],
              [-38.467658692055288, -8.688828283107458],
              [-38.468080284392222, -8.686896156868023],
              [-38.46986257301743, -8.678728645697745],
              [-38.470494739716642, -8.675830358077837],
              [-38.472349749462275, -8.667313972308675],
              [-38.472397685539818, -8.667093885189351],
              [-38.472472792680875, -8.666748111200739],
              [-38.472911359858315, -8.664726815037241],
              [-38.473923984707525, -8.659845399046565],
              [-38.472665573958189, -8.659576478781887],
              [-38.472379319852017, -8.660902973954457],
              [-38.471613861124055, -8.664449424791485],
              [-38.471502565050471, -8.665390032860593],
              [-38.471422987615, -8.665747197060071],
              [-38.471260081653753, -8.666478615066096],
              [-38.470466032278061, -8.670042568859587],
              [-38.469176479697744, -8.675877550602502],
              [-38.46852609802351, -8.678793809431033],
              [-38.466185797585801, -8.689302346731841],
              [-38.464854510441185, -8.695295889218283],
              [-38.464360204207914, -8.697522318511446],
              [-38.465660795216053, -8.697808788548921],
            ],
          ],
          [
            [
              [-38.456996619494845, -8.698300603349397],
              [-38.457230606594898, -8.697224595934923],
              [-38.458709534848097, -8.690484190300252],
              [-38.46108252863521, -8.679701155422494],
              [-38.462445002747231, -8.673501441693629],
              [-38.46313086477695, -8.670395753797525],
              [-38.463264430438883, -8.669791470563823],
              [-38.463964456534839, -8.666621852176943],
              [-38.466038433640847, -8.657518429087316],
              [-38.466464482742282, -8.655493686204444],
              [-38.466612536688906, -8.654790469526752],
              [-38.467529398992006, -8.650433271713263],
              [-38.469175077544804, -8.642639375789599],
              [-38.47104108474965, -8.633864213629874],
              [-38.472294926948877, -8.628133803634389],
              [-38.47379341571731, -8.621266319525086],
              [-38.471626311584345, -8.620850081013604],
              [-38.4722653652601, -8.618064394312587],
              [-38.470796271728119, -8.61823232799544],
              [-38.470820872408467, -8.618449078620971],
              [-38.470291122155501, -8.618506869329632],
              [-38.470261788032985, -8.618295720023509],
              [-38.468520912305443, -8.618491490252978],
              [-38.468997042114744, -8.616348769920105],
              [-38.469131118040679, -8.615745567365286],
              [-38.469500602751694, -8.614082532433766],
              [-38.469625988628749, -8.613518391687895],
              [-38.469898708928369, -8.612290917224277],
              [-38.470050249431004, -8.611609137593975],
              [-38.47289465868068, -8.598807119643723],
              [-38.471986728065957, -8.598711717717014],
              [-38.469473183147144, -8.610149121485327],
              [-38.469211694598862, -8.611338803448096],
              [-38.468876485364042, -8.612863860009798],
              [-38.46873800999915, -8.613494281786092],
              [-38.468144318813849, -8.616195183488259],
              [-38.4679727302739, -8.616976337982086],
              [-38.467614002794591, -8.618608542314828],
              [-38.466718993013885, -8.622749246809711],
              [-38.470161938858226, -8.624080181241593],
              [-38.471178056817564, -8.623983175160982],
              [-38.471178077064614, -8.623983082790836],
              [-38.47117807693882, -8.623983173240024],
              [-38.470320671918529, -8.627894649271587],
              [-38.469119685618615, -8.633373584850785],
              [-38.468054223442145, -8.638234028249707],
              [-38.466964725870426, -8.643230739387446],
              [-38.466368396421132, -8.645946912933635],
              [-38.466340678849164, -8.646073322293628],
              [-38.465569616343892, -8.649585112354089],
              [-38.464763706989267, -8.653239127141498],
              [-38.464421482345827, -8.65479437344905],
              [-38.464314901905098, -8.655283101781933],
              [-38.464271264583218, -8.655482300105353],
              [-38.464216192764781, -8.655733038406145],
              [-38.464148678650723, -8.656040923108412],
              [-38.464105221433321, -8.656238901460803],
              [-38.464103486491808, -8.656246812451492],
              [-38.463779141060662, -8.657725272667788],
              [-38.463763535957632, -8.657796452947027],
              [-38.463720395326135, -8.657993051246994],
              [-38.463626225127129, -8.658422302962121],
              [-38.463622651386636, -8.658438624939919],
              [-38.463480394036345, -8.659087035892844],
              [-38.461896327260426, -8.665978385298082],
              [-38.460776984004617, -8.671060675219797],
              [-38.460672138389434, -8.671536560263206],
              [-38.460572232994977, -8.671989930428866],
              [-38.460487790950125, -8.67237290535566],
              [-38.460392732176828, -8.672804350891459],
              [-38.458581391132789, -8.681021605989338],
              [-38.45834387141791, -8.682100595770381],
              [-38.458317836088568, -8.682218834757526],
              [-38.457974505182364, -8.683778441004673],
              [-38.457309509552402, -8.686798397658073],
              [-38.4559702431579, -8.69287845018377],
              [-38.455129248297688, -8.696699976874747],
              [-38.454910377369721, -8.697691072909388],
              [-38.454851816588558, -8.697956094484494],
              [-38.45531248534072, -8.698225115301558],
              [-38.456996619494845, -8.698300603349397],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0167000',
        uf: 'PE',
        nome_proje: 'PA MULUNGÚ',
        municipio: 'IBIMIRIM',
        area_hecta: '1289.9170',
        capacidade: 28.0,
        num_famili: 26.0,
        fase: 3.0,
        data_de_cr: '21/03/2006',
        forma_obte: 'Desapropriação',
        data_obten: '21/03/2006',
        area_calc_: 1293.0122,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.539280105328196, -8.426604746407055],
              [-37.539028521500967, -8.426782129667954],
              [-37.537412248507337, -8.427209702280958],
              [-37.537871159792822, -8.430247270740994],
              [-37.534756138984498, -8.42918545074712],
              [-37.533891124848161, -8.429838980463316],
              [-37.533016458800006, -8.433629057725623],
              [-37.531833563801449, -8.433877461588134],
              [-37.531732728104949, -8.43388567457581],
              [-37.53094408428796, -8.43364689261684],
              [-37.530643133795159, -8.433716297191172],
              [-37.530112869056865, -8.433547114233946],
              [-37.529781575157848, -8.434340168305809],
              [-37.52927955566183, -8.434386573384183],
              [-37.528988626335952, -8.434471476829922],
              [-37.528141725029755, -8.434340981085963],
              [-37.52790346160424, -8.433420010558324],
              [-37.527466585339084, -8.433315195749461],
              [-37.527142871940427, -8.433376283438765],
              [-37.527346048250692, -8.434425254865507],
              [-37.527335989025879, -8.43461149295543],
              [-37.527470587310653, -8.434824587885823],
              [-37.52724249958996, -8.435653291981069],
              [-37.529858049692258, -8.436451704102101],
              [-37.537233850836614, -8.469234107778679],
              [-37.537260466387188, -8.469353207270927],
              [-37.541047933046372, -8.486131628291117],
              [-37.541760329966301, -8.486435138859346],
              [-37.543056396377935, -8.487001436235909],
              [-37.543596236889428, -8.487229155421685],
              [-37.544050221401115, -8.487439191626251],
              [-37.544310858144584, -8.487594430970415],
              [-37.544503175018257, -8.487730154453525],
              [-37.544754023092622, -8.487928218758277],
              [-37.544918398873683, -8.488079119713465],
              [-37.545245157737881, -8.488379557655],
              [-37.545696961733682, -8.488783456759871],
              [-37.546148602051346, -8.489182833594512],
              [-37.546681550179628, -8.489674747430128],
              [-37.547001551440097, -8.489961414419019],
              [-37.559950048647195, -8.483062930187881],
              [-37.558270530828928, -8.478830297316527],
              [-37.564132620580068, -8.475319040707507],
              [-37.554385364013328, -8.458724517274597],
              [-37.554323080196951, -8.45862174386661],
              [-37.539280105328196, -8.426604746407055],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0197000',
        uf: 'PE',
        nome_proje: 'PA SÃO FRANCISCO I',
        municipio: 'IBIMIRIM',
        area_hecta: '309.2971',
        capacidade: 23.0,
        num_famili: 17.0,
        fase: 3.0,
        data_de_cr: '07/11/2006',
        forma_obte: 'Doação',
        data_obten: '31/10/2006',
        area_calc_: 306.8102,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.692348507710811, -8.413970305431031],
              [-37.688892785569152, -8.417625446098178],
              [-37.689186241707958, -8.417466789342937],
              [-37.690275827124161, -8.416964549310521],
              [-37.689829469055603, -8.417313573217703],
              [-37.689814927095796, -8.417722093122014],
              [-37.689574726519325, -8.417772928531466],
              [-37.688701405087897, -8.418373824796651],
              [-37.688690785499645, -8.418399019667662],
              [-37.688685096118107, -8.418740288048829],
              [-37.687046678459154, -8.419004754272356],
              [-37.685992004286135, -8.419385561154936],
              [-37.685082545406686, -8.419820205214432],
              [-37.685319038132377, -8.419872813586885],
              [-37.687968098664534, -8.419386015051808],
              [-37.690419404043233, -8.420019343695207],
              [-37.691771560287748, -8.420108944011959],
              [-37.692073915067411, -8.420156341889241],
              [-37.690558923672697, -8.420930903652268],
              [-37.689295658430375, -8.420387721068865],
              [-37.689040044909859, -8.420349339297506],
              [-37.684876148067126, -8.421048563026494],
              [-37.685503966882756, -8.422691264815978],
              [-37.685814712376931, -8.423777839361277],
              [-37.686198104466577, -8.424571208030782],
              [-37.686701792566154, -8.424838036825843],
              [-37.687600394291621, -8.424852616229296],
              [-37.687672203817378, -8.425534526150962],
              [-37.686913163760977, -8.425823628924139],
              [-37.686211444976898, -8.425865954566719],
              [-37.683882643759496, -8.426625281688395],
              [-37.684165656876893, -8.427908542017784],
              [-37.686112648176689, -8.427395446554458],
              [-37.687695767842186, -8.427569486308654],
              [-37.688923781863799, -8.427852569656832],
              [-37.688437811056247, -8.428228677215536],
              [-37.687733632063043, -8.428164287998197],
              [-37.686248987072332, -8.428519780280881],
              [-37.686207672850863, -8.428893845786718],
              [-37.686229609174205, -8.429309811715436],
              [-37.685664318100905, -8.430662847531146],
              [-37.683971649614733, -8.431136097219918],
              [-37.684911305979504, -8.431556496175496],
              [-37.685574571977796, -8.432226642204531],
              [-37.687468699058748, -8.43215268028897],
              [-37.687658538953656, -8.432252879950715],
              [-37.688510146557107, -8.433469675032363],
              [-37.688646964307054, -8.433699737058522],
              [-37.688574269970218, -8.43376478532733],
              [-37.686124423512105, -8.43273010492476],
              [-37.683446557757392, -8.43212030465353],
              [-37.683075069514452, -8.432049697424018],
              [-37.682418168127256, -8.432719665130765],
              [-37.682572772131934, -8.433676762659154],
              [-37.682714057986757, -8.433793169565172],
              [-37.683883889622685, -8.434096965185619],
              [-37.684183202821252, -8.434482210529881],
              [-37.683749893973449, -8.435046492853552],
              [-37.685349164420913, -8.435448306028773],
              [-37.685229848979198, -8.436757437497157],
              [-37.68484743666118, -8.436907717535716],
              [-37.684275397747278, -8.43740425415368],
              [-37.683719668846734, -8.437804264206612],
              [-37.683587986691869, -8.438177030629754],
              [-37.682983817058648, -8.438532565554429],
              [-37.683584775004775, -8.439339871284181],
              [-37.685595774379095, -8.439441661847708],
              [-37.686431159161245, -8.439037522181238],
              [-37.686826255392511, -8.439784088032294],
              [-37.686463541327832, -8.440420230766168],
              [-37.686142998371771, -8.440644329745114],
              [-37.686473016099661, -8.442184843167587],
              [-37.688543566832323, -8.443080534205832],
              [-37.688947908576537, -8.441735458412595],
              [-37.688670080081707, -8.440740238941263],
              [-37.689185066004057, -8.440625847903934],
              [-37.689088461391421, -8.439539808754818],
              [-37.689982092909204, -8.439799801329279],
              [-37.690807252872617, -8.439248847784524],
              [-37.690242762975075, -8.440901671753272],
              [-37.691510141870573, -8.44172566352251],
              [-37.692201830735677, -8.440867338187628],
              [-37.69307081812569, -8.440598309799613],
              [-37.694111660307087, -8.441890967295164],
              [-37.694982133194287, -8.441477611381796],
              [-37.695707617953865, -8.441047131441859],
              [-37.695601256018513, -8.440670854385125],
              [-37.694996796969562, -8.440058973553803],
              [-37.695598229239671, -8.439976150686409],
              [-37.695661163341406, -8.439436665771282],
              [-37.695735206203373, -8.439078080481924],
              [-37.695999814540308, -8.438280180394429],
              [-37.69568540931072, -8.437651360094522],
              [-37.696572916203692, -8.438384363909703],
              [-37.696742049440999, -8.438323523219449],
              [-37.696783779315801, -8.438933892997634],
              [-37.69632603649719, -8.439664503609183],
              [-37.696853935862229, -8.440952916648214],
              [-37.696911630994116, -8.442543977449645],
              [-37.697462846981267, -8.442866027520266],
              [-37.697839441447329, -8.442555652415574],
              [-37.697867767754239, -8.442206500395995],
              [-37.698511672404841, -8.441510870253769],
              [-37.699239119777133, -8.441307191847843],
              [-37.69934877136118, -8.441570078303418],
              [-37.699623113067652, -8.441227620273162],
              [-37.700594283954899, -8.441444621226303],
              [-37.701121948353958, -8.441422677174002],
              [-37.701871806296516, -8.441714904164053],
              [-37.701971802504453, -8.441606174887024],
              [-37.701518937444988, -8.440769901167167],
              [-37.701326546441038, -8.439582440154272],
              [-37.701326836545043, -8.439553952772769],
              [-37.701307543020214, -8.439452969652415],
              [-37.7011737348639, -8.43943371659919],
              [-37.701481502876675, -8.438436733448059],
              [-37.702305647991714, -8.435110222830204],
              [-37.701888756442187, -8.434681011460613],
              [-37.699629857199838, -8.433998367622195],
              [-37.698839393821338, -8.433686380376695],
              [-37.696747177407723, -8.434111154887571],
              [-37.694866194321762, -8.432753766299038],
              [-37.69270667006294, -8.433398766901325],
              [-37.691761632800564, -8.432656431549892],
              [-37.689729445316623, -8.429955343288519],
              [-37.691923728120763, -8.428664250837654],
              [-37.692573607068169, -8.428510150757404],
              [-37.69249921789099, -8.427133719501709],
              [-37.690936069957679, -8.425421801408365],
              [-37.689648790009755, -8.424588977025357],
              [-37.689878637155481, -8.423756691488762],
              [-37.690184922551403, -8.423284215144557],
              [-37.692779788723207, -8.424366099111838],
              [-37.694525632444389, -8.424112187679308],
              [-37.695942063450396, -8.420661236946707],
              [-37.696307549219938, -8.416878254687388],
              [-37.692348507710811, -8.413970305431031],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0205000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA SANTANA',
        municipio: 'IBIMIRIM',
        area_hecta: '327.8165',
        capacidade: 36.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '28/11/2006',
        forma_obte: 'Doação',
        data_obten: '28/11/2006',
        area_calc_: 327.8927,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.618475586689485, -8.375749069260271],
              [-37.617928923303459, -8.372665883309855],
              [-37.622578039613749, -8.372578398284112],
              [-37.621125198668025, -8.362605611414274],
              [-37.617782144476365, -8.364153117873601],
              [-37.612641770881858, -8.364841275095706],
              [-37.61546678256942, -8.360757373477245],
              [-37.609745085527692, -8.358643522935232],
              [-37.60852342932872, -8.360289912257199],
              [-37.604904766516484, -8.357908756744994],
              [-37.604567384470791, -8.348956234506826],
              [-37.602993229337265, -8.343333000661669],
              [-37.603713908502087, -8.34107546081766],
              [-37.602730924297539, -8.338526256257181],
              [-37.601817353658035, -8.338726591197139],
              [-37.601010942709891, -8.335754259750257],
              [-37.599538810234641, -8.336285758440228],
              [-37.599275620348457, -8.336380864189339],
              [-37.599378558724887, -8.33728017576564],
              [-37.599933672063059, -8.338583851587433],
              [-37.599931024807006, -8.339327705875581],
              [-37.600301543551218, -8.340072877451156],
              [-37.60055010038257, -8.340135788550834],
              [-37.600861072382664, -8.340136885859589],
              [-37.600923707744862, -8.340013129531682],
              [-37.600676473944326, -8.3395782914212],
              [-37.600801964810501, -8.339268836123834],
              [-37.600988547614513, -8.339269494351901],
              [-37.601360390910131, -8.339642737907715],
              [-37.601669820303741, -8.340077704608433],
              [-37.601790463251461, -8.341131892321556],
              [-37.601415532277365, -8.341626479354357],
              [-37.601039497558602, -8.342431050460567],
              [-37.601098604974176, -8.34329910019173],
              [-37.60090871171856, -8.344228214511187],
              [-37.601213511126765, -8.345964972497789],
              [-37.601395685896463, -8.347205298078988],
              [-37.602012569621515, -8.348633169106606],
              [-37.602818158474072, -8.34944190912986],
              [-37.603020455535798, -8.350387057034148],
              [-37.603154778533408, -8.351348332751057],
              [-37.603151849623757, -8.352171944450319],
              [-37.602919221116025, -8.353040412069205],
              [-37.602961909657566, -8.353955607700382],
              [-37.602593654708159, -8.354228848680934],
              [-37.602269877216877, -8.35491405707476],
              [-37.601945772893139, -8.355690686912979],
              [-37.601759317363822, -8.356467892972903],
              [-37.6015744190262, -8.356833203226214],
              [-37.601755895056016, -8.357428682035009],
              [-37.601295838178181, -8.357701596735682],
              [-37.601201594858722, -8.358387524142397],
              [-37.601981360284405, -8.358573306728804],
              [-37.602576716236563, -8.35898722044943],
              [-37.603354855783955, -8.359630472113789],
              [-37.60413283486897, -8.360319569278886],
              [-37.604728195973244, -8.360733480534668],
              [-37.605646596730949, -8.36064520499335],
              [-37.605874675428829, -8.361057729676075],
              [-37.605965101614593, -8.36146985964646],
              [-37.606652824683771, -8.36170106677679],
              [-37.607203636460213, -8.361748763440696],
              [-37.607707113780528, -8.362162347492303],
              [-37.608165549806053, -8.362346988316576],
              [-37.608716272208795, -8.362394682731093],
              [-37.609082910415786, -8.362578909261204],
              [-37.609035505245721, -8.362990554340945],
              [-37.61004288347597, -8.363726206102104],
              [-37.61017916900532, -8.364138406615796],
              [-37.610317075736226, -8.364093134383513],
              [-37.610593446360888, -8.36381965457581],
              [-37.61036633406389, -8.363132503247565],
              [-37.609909192898328, -8.362581814165029],
              [-37.609819014980189, -8.362124018832606],
              [-37.609866581218988, -8.361666617175811],
              [-37.610097096425669, -8.361392885883365],
              [-37.610603007617222, -8.361120121752624],
              [-37.61087865676717, -8.361075332897334],
              [-37.611108199123116, -8.361076138873202],
              [-37.61124416111732, -8.361579942215435],
              [-37.611978643315773, -8.361582520154412],
              [-37.612483673738346, -8.361584291955792],
              [-37.612436434199864, -8.361950181703588],
              [-37.611931079524894, -8.3620399224717],
              [-37.611561778404358, -8.362633375780295],
              [-37.611514051839599, -8.363136534266191],
              [-37.6111915749117, -8.363455700331851],
              [-37.611190602830398, -8.363730238343289],
              [-37.611326889632359, -8.364142438658718],
              [-37.610958142310167, -8.364552955664081],
              [-37.610543863994529, -8.364871798424117],
              [-37.610036883934974, -8.365419008736794],
              [-37.610127061918419, -8.365876894538552],
              [-37.610355147570971, -8.366289508228915],
              [-37.610995822189672, -8.366886508856108],
              [-37.611500768428201, -8.366888282709354],
              [-37.61209760576331, -8.366890378540276],
              [-37.612924060264781, -8.36684752226912],
              [-37.613429097668856, -8.366849293970168],
              [-37.613659775837711, -8.366529894812601],
              [-37.614027067319704, -8.366531182625502],
              [-37.614209859395231, -8.366760517751977],
              [-37.614622359196424, -8.366944991361404],
              [-37.615263850109962, -8.367313294686427],
              [-37.61576879781439, -8.367315063178692],
              [-37.616227567806305, -8.367408183361993],
              [-37.616320175715771, -8.367179722572757],
              [-37.616642491276224, -8.366906308633368],
              [-37.616917983195904, -8.366907272569522],
              [-37.617376753218352, -8.367000391387258],
              [-37.617607106323575, -8.366772411895775],
              [-37.617883152315358, -8.366590439422197],
              [-37.61820578893807, -8.366225511013132],
              [-37.618620638327407, -8.365769390354531],
              [-37.618988574449801, -8.365587647515939],
              [-37.619402526024736, -8.365360307901401],
              [-37.619678248175632, -8.365269846856748],
              [-37.619815671969533, -8.365361750267599],
              [-37.619538982975222, -8.365726840787122],
              [-37.618941178601922, -8.365999295359908],
              [-37.61866391395224, -8.366501654154749],
              [-37.618984545906493, -8.366731469107604],
              [-37.619167179865364, -8.367006649336325],
              [-37.619028789063883, -8.367189194087809],
              [-37.618339342755348, -8.367415570129308],
              [-37.618382869592565, -8.368102077698135],
              [-37.618564536385072, -8.368651706596726],
              [-37.619068771342945, -8.368882253852657],
              [-37.61948127633319, -8.369066723105748],
              [-37.619847765533549, -8.36929678834951],
              [-37.620350623282206, -8.369893295464822],
              [-37.620532706000176, -8.370351501736247],
              [-37.620485149278686, -8.370808906338061],
              [-37.620301385332809, -8.370854021802323],
              [-37.61988362640502, -8.370613740047078],
              [-37.619469203679138, -8.370612292411892],
              [-37.619055589413904, -8.370381428987395],
              [-37.61859513024654, -8.370379819591946],
              [-37.618319627582409, -8.370149347856342],
              [-37.617997046354425, -8.370240005176743],
              [-37.617673241278538, -8.370651861286191],
              [-37.617534483177067, -8.370834946455179],
              [-37.616797549714789, -8.370878305346823],
              [-37.616476011188716, -8.370647671294147],
              [-37.616293159844375, -8.370279980451757],
              [-37.615924774869441, -8.370278690355621],
              [-37.61578617846282, -8.370415837538109],
              [-37.615785205904459, -8.37069118983838],
              [-37.61578358490025, -8.371150110330014],
              [-37.615644086500396, -8.371516762521196],
              [-37.615273521093258, -8.372157870040843],
              [-37.615225862172444, -8.372616629031571],
              [-37.615547472743906, -8.372801326650725],
              [-37.61596189709563, -8.372802778774888],
              [-37.616145650036792, -8.372941055141911],
              [-37.616190552293091, -8.373262505986107],
              [-37.615821678260438, -8.373398846257079],
              [-37.615314205815913, -8.37367242334888],
              [-37.615174219174328, -8.374176705986846],
              [-37.615355701213538, -8.374957471221576],
              [-37.615538877179858, -8.375233468984826],
              [-37.615491055210249, -8.375738165174031],
              [-37.615812759068085, -8.375922863304716],
              [-37.616503249452144, -8.375971130327368],
              [-37.616687165865585, -8.376063559852875],
              [-37.616547341514227, -8.376521996229886],
              [-37.616499034460261, -8.377164233199874],
              [-37.616543450663599, -8.377623314994869],
              [-37.616541666873786, -8.378128172734225],
              [-37.616264305514747, -8.378448403782501],
              [-37.616216321345291, -8.37899894689312],
              [-37.615847279170161, -8.379181223517159],
              [-37.615845981418033, -8.379548269335633],
              [-37.616120677023943, -8.380008158489652],
              [-37.616211292920134, -8.380421464401978],
              [-37.616441482758894, -8.380422271356004],
              [-37.616580498275745, -8.380193340781886],
              [-37.617108634019402, -8.380190670032844],
              [-37.617373190036297, -8.380153978433688],
              [-37.6177894677325, -8.37996716353147],
              [-37.61824405123734, -8.379629918172947],
              [-37.618660593858266, -8.379367775869698],
              [-37.618925415064993, -8.379255756786248],
              [-37.61907584964996, -8.37944455600104],
              [-37.618998971932271, -8.379820832923704],
              [-37.618884983507705, -8.380008707031193],
              [-37.61850621532777, -8.380270891263109],
              [-37.618240196894362, -8.38072183307267],
              [-37.617936312465183, -8.381172551568577],
              [-37.61721770173294, -8.381395925631759],
              [-37.617065271826021, -8.381771937063796],
              [-37.617366672892665, -8.381998884316257],
              [-37.617667632984862, -8.382376484264366],
              [-37.618006634651351, -8.382678890084923],
              [-37.618346343608209, -8.382755406915663],
              [-37.618950435893524, -8.382870467294529],
              [-37.619138513890995, -8.383097016837327],
              [-37.618836313543099, -8.383095959153616],
              [-37.618307638197891, -8.383018781038256],
              [-37.61766537205493, -8.383016531534603],
              [-37.617249223768987, -8.38316572782526],
              [-37.617097059143561, -8.383466412875796],
              [-37.61683196829086, -8.383653756406245],
              [-37.616529144647252, -8.383803258610312],
              [-37.616377378591643, -8.383990999113911],
              [-37.61648950572134, -8.38433022892346],
              [-37.616866282914778, -8.384632768293978],
              [-37.616827309499925, -8.384971558722597],
              [-37.61659990212749, -8.385158943598231],
              [-37.616335209289751, -8.385233342433549],
              [-37.615881816041039, -8.385231751880655],
              [-37.615767691154893, -8.385457242426527],
              [-37.615993409891757, -8.385721634219365],
              [-37.616483114169668, -8.386137515825915],
              [-37.616784209177915, -8.386477408419829],
              [-37.617463457356415, -8.38670577145251],
              [-37.617878055292039, -8.386970734065683],
              [-37.618255900126719, -8.386972057976456],
              [-37.618520859776986, -8.386822422130983],
              [-37.61886239159066, -8.386409454124012],
              [-37.619202369867459, -8.386410644446253],
              [-37.61973131587019, -8.386412495794369],
              [-37.620335812763358, -8.386414610707485],
              [-37.620524249507895, -8.386565833922395],
              [-37.62059913635305, -8.386754368820595],
              [-37.620409902753252, -8.386829034237989],
              [-37.620032281559212, -8.386790004349086],
              [-37.619578619945713, -8.386863744072693],
              [-37.619464496386122, -8.387089235939067],
              [-37.619009504850958, -8.387539516008715],
              [-37.618781256459705, -8.387990499169723],
              [-37.618553581434796, -8.388253301721829],
              [-37.618175826121416, -8.388251978213676],
              [-37.617797447899456, -8.38840121611385],
              [-37.617268499640907, -8.388399361736518],
              [-37.616852968870745, -8.388397904478659],
              [-37.616626091553343, -8.388434817431643],
              [-37.616663067992775, -8.388660838184942],
              [-37.616699511506894, -8.389037511380074],
              [-37.616963365481283, -8.389226709624907],
              [-37.617265703671094, -8.389190061178915],
              [-37.617643017948133, -8.389342038522869],
              [-37.6179445578246, -8.389531277907579],
              [-37.618434933625004, -8.389758978083904],
              [-37.618774340461499, -8.389948440058955],
              [-37.618773542452409, -8.390174328478597],
              [-37.618356854251068, -8.390474086827437],
              [-37.618091492620827, -8.390736666298027],
              [-37.618127804425178, -8.391150957492226],
              [-37.618315884761287, -8.391377598331257],
              [-37.618616718689168, -8.391792816621624],
              [-37.619068965128633, -8.392095619657418],
              [-37.619446150144007, -8.392285213755246],
              [-37.619406911324354, -8.392699240415153],
              [-37.6194054483678, -8.393113399378224],
              [-37.619630774880413, -8.393490643907921],
              [-37.619856325130065, -8.393830361180532],
              [-37.620195512439771, -8.39405744020195],
              [-37.620194581709882, -8.394321037080074],
              [-37.620042679429794, -8.394546396460242],
              [-37.619702470272379, -8.394582823332312],
              [-37.619439101305666, -8.394280682271511],
              [-37.619213375599344, -8.394016381811626],
              [-37.618950182758738, -8.393638913945933],
              [-37.618724191821578, -8.393449849151493],
              [-37.618421892720001, -8.393448789535427],
              [-37.61815706114654, -8.393560806620485],
              [-37.617854895071183, -8.39352212864946],
              [-37.617628505329073, -8.39344600745841],
              [-37.617401849212492, -8.393445212377133],
              [-37.616948446238325, -8.393443621505201],
              [-37.616721166148267, -8.393593478121858],
              [-37.616796142961185, -8.393781923489467],
              [-37.617135860803835, -8.393858442848263],
              [-37.617362074929979, -8.394009890885112],
              [-37.617663484479309, -8.394236839332669],
              [-37.617662818486849, -8.394425109695549],
              [-37.617661752930744, -8.394726324186816],
              [-37.617396787192206, -8.394875958474378],
              [-37.617093687702393, -8.395100785863875],
              [-37.616715900505092, -8.395081012333305],
              [-37.616450800723221, -8.395268354192295],
              [-37.616525153340611, -8.395607451687521],
              [-37.616675991502071, -8.395683308421965],
              [-37.616978425586275, -8.395646661341608],
              [-37.617507649797318, -8.395573281905129],
              [-37.617696264753981, -8.395649180397085],
              [-37.61811149439994, -8.395763672907917],
              [-37.618450548923626, -8.396028371428642],
              [-37.618751785101701, -8.396330645951819],
              [-37.619204792586473, -8.396445179486046],
              [-37.619317722440478, -8.396558520864382],
              [-37.619128217388614, -8.396708511090642],
              [-37.61878783084407, -8.396820263425935],
              [-37.618447353338894, -8.396932015116352],
              [-37.617955594495321, -8.397118562946645],
              [-37.617577739902394, -8.397117237158289],
              [-37.617011403384829, -8.397002303870808],
              [-37.616634615697244, -8.396699762870075],
              [-37.616369781450949, -8.396811778295122],
              [-37.616217342342992, -8.397187788005835],
              [-37.616140722539065, -8.397488736860115],
              [-37.615913173021873, -8.397713828283164],
              [-37.615912105693361, -8.398015042500582],
              [-37.616176098148458, -8.398166533812939],
              [-37.616553862722782, -8.398167860791052],
              [-37.616856031705126, -8.398206630740701],
              [-37.617043581142966, -8.398583744073871],
              [-37.617042381049878, -8.398922666732991],
              [-37.61692705153196, -8.399486989127995],
              [-37.617038781110075, -8.39993925381914],
              [-37.617339485429625, -8.400392091643104],
              [-37.617451749010641, -8.400693703917764],
              [-37.617676943133638, -8.401108658143482],
              [-37.617978714824964, -8.401260371720218],
              [-37.618393193596965, -8.401563044488007],
              [-37.618732609730479, -8.40175250804667],
              [-37.618958072224373, -8.402092135595007],
              [-37.618919496249845, -8.402317891377031],
              [-37.61865505796635, -8.402316963663427],
              [-37.618276665028425, -8.402466290170665],
              [-37.617936539743262, -8.402502714677825],
              [-37.61789760561755, -8.402803796140056],
              [-37.617896139273384, -8.403217954625957],
              [-37.617820052215372, -8.40336825133376],
              [-37.61732748411039, -8.403780775512569],
              [-37.617288282707946, -8.404157092552525],
              [-37.617286681885609, -8.404608959206465],
              [-37.617285481450651, -8.404947791369205],
              [-37.617283880472314, -8.405399658004521],
              [-37.617585922164075, -8.405475955750001],
              [-37.617622498760056, -8.405815011067302],
              [-37.617772274197314, -8.406191991919096],
              [-37.617884405504398, -8.406531312617654],
              [-37.618149204126006, -8.406456915429997],
              [-37.61826333586869, -8.406231425199326],
              [-37.618151204257991, -8.405892104564401],
              [-37.617774141248745, -8.405664889445298],
              [-37.617964451582765, -8.405289012558949],
              [-37.618116360123977, -8.405063654970538],
              [-37.618381466709984, -8.404876313028469],
              [-37.618798704889912, -8.404425995323184],
              [-37.61925292007421, -8.40420169782794],
              [-37.619479582609785, -8.404202492854701],
              [-37.619630158495667, -8.404353584883246],
              [-37.619402563435287, -8.404616386581003],
              [-37.619175501073656, -8.404728535616345],
              [-37.619098523686674, -8.405104810981067],
              [-37.619247991677959, -8.405594735933107],
              [-37.619586787802199, -8.405934851528555],
              [-37.620115627299093, -8.405974324555871],
              [-37.620417270703193, -8.406163654910726],
              [-37.621021132994947, -8.406354044230124],
              [-37.621511839257877, -8.406468709101697],
              [-37.622040281230603, -8.406621123959679],
              [-37.622455125299908, -8.406848558208432],
              [-37.622907747645428, -8.407076033878726],
              [-37.62347449988561, -8.407078016930981],
              [-37.623814496809509, -8.407079206180235],
              [-37.624153920699861, -8.407268666210442],
              [-37.62430423407605, -8.407495083302042],
              [-37.624190370682797, -8.407645249387924],
              [-37.623850282400525, -8.407644060066325],
              [-37.623585308459973, -8.40779378781879],
              [-37.623319446410669, -8.408169403688577],
              [-37.623034387500461, -8.408657807376658],
              [-37.622957635928501, -8.408996466259463],
              [-37.623297501686395, -8.40903527418781],
              [-37.623465604804942, -8.409147781380598],
              [-37.62369337646075, -8.408977518832499],
              [-37.626461007585078, -8.407449626735815],
              [-37.625506609427134, -8.406020228569894],
              [-37.621182466473151, -8.403203525702226],
              [-37.620707270557759, -8.401604435458026],
              [-37.619309223433774, -8.398962993284286],
              [-37.620317696381484, -8.398981538441163],
              [-37.622015235669963, -8.394650058181561],
              [-37.620730523299798, -8.390181812621869],
              [-37.623952630359, -8.384541799821834],
              [-37.620980605112777, -8.384594360928459],
              [-37.620842664829297, -8.376554745435822],
              [-37.618466784359612, -8.377497752070381],
              [-37.618475586689485, -8.375749069260271],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0189000',
        uf: 'PE',
        nome_proje: 'PA CASA NOVA',
        municipio: 'IBIMIRIM',
        area_hecta: '115.8518',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '04/10/2006',
        forma_obte: 'Doação',
        data_obten: '04/10/2006',
        area_calc_: 115.8876,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.730116304803161, -8.476743894849498],
              [-37.731181312117819, -8.477328603864757],
              [-37.730871097983304, -8.478364260280541],
              [-37.732207177964717, -8.481733916415314],
              [-37.7326092614271, -8.481772842708084],
              [-37.732609143487565, -8.481772921750435],
              [-37.732609261130726, -8.481772933139574],
              [-37.732315313975818, -8.48196984425293],
              [-37.731570390393223, -8.481082813280233],
              [-37.729964352180723, -8.480118825029194],
              [-37.72981874313232, -8.48070073536649],
              [-37.72821241880245, -8.481869856580555],
              [-37.728480400816501, -8.482221878662509],
              [-37.729143633947054, -8.482370268844351],
              [-37.728647025571476, -8.483260674863796],
              [-37.728592301002124, -8.483632715792453],
              [-37.72920137269265, -8.484369553546838],
              [-37.729848831617339, -8.485477288202359],
              [-37.729825111420517, -8.48553635368139],
              [-37.728881829786587, -8.485250046230393],
              [-37.726743921232583, -8.484019343199705],
              [-37.726158772074619, -8.483770371786234],
              [-37.72609848145656, -8.483735810643767],
              [-37.724691873560211, -8.484052790855758],
              [-37.724450001681156, -8.484294900664363],
              [-37.72276175752544, -8.482674439433447],
              [-37.722240862077413, -8.48401076654387],
              [-37.721894964578517, -8.484623981295858],
              [-37.722148805974278, -8.485040034016805],
              [-37.72315619511987, -8.485907864380291],
              [-37.723156086071093, -8.485907861127385],
              [-37.723156194821129, -8.485907954811427],
              [-37.72230143854889, -8.485882366209463],
              [-37.720023929368828, -8.486026822925655],
              [-37.71891485300965, -8.486024266048698],
              [-37.719381286682768, -8.487422158055457],
              [-37.719381264266651, -8.487422182199872],
              [-37.719381286383083, -8.48742224848645],
              [-37.718351815476822, -8.488530997471099],
              [-37.720760024844573, -8.488970268084008],
              [-37.722570329403773, -8.492277065032372],
              [-37.722855339120137, -8.492204568434076],
              [-37.723167002117236, -8.492477609435317],
              [-37.723676688326677, -8.492695502347031],
              [-37.725386463853809, -8.492096923861872],
              [-37.725116157971314, -8.487385156110671],
              [-37.725593897878795, -8.485609906641654],
              [-37.727308880416402, -8.486538277939381],
              [-37.727865870034819, -8.486926964686834],
              [-37.730997103654012, -8.487790487948844],
              [-37.732214096144567, -8.486881170571253],
              [-37.732286790880075, -8.485266736420279],
              [-37.734148817653342, -8.486011979844685],
              [-37.735542238208822, -8.484201881857846],
              [-37.735299245477876, -8.482678029620558],
              [-37.735299258646549, -8.48267801992233],
              [-37.735299245773675, -8.482677939189012],
              [-37.736686346776914, -8.481656476275285],
              [-37.737144224464288, -8.481294613160804],
              [-37.736186810066883, -8.480532250896415],
              [-37.731887618840389, -8.477108977963981],
              [-37.730116304803161, -8.476743894849498],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0217000',
        uf: 'PE',
        nome_proje: 'PA ESPERANÇA I',
        municipio: 'IBIMIRIM',
        area_hecta: '249.9908',
        capacidade: 29.0,
        num_famili: 27.0,
        fase: 3.0,
        data_de_cr: '12/02/2007',
        forma_obte: 'Doação',
        data_obten: '12/02/2007',
        area_calc_: 250.0597,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.626722789565854, -8.450692489186965],
              [-37.627143376884263, -8.452390409773555],
              [-37.627358119415099, -8.45237994967526],
              [-37.627636397603027, -8.452380925394358],
              [-37.627998141132736, -8.452382193469928],
              [-37.628248627898046, -8.452383071344135],
              [-37.628414872628774, -8.45257771433138],
              [-37.628386297040429, -8.4527994363144],
              [-37.62810733236352, -8.452992519090079],
              [-37.627967604676606, -8.453158418486231],
              [-37.627910852157456, -8.453463236184415],
              [-37.627993239448109, -8.453768451215213],
              [-37.627992455037308, -8.453990270530639],
              [-37.62807523415934, -8.454184711546553],
              [-37.62807454792771, -8.454378769539847],
              [-37.627907126168793, -8.454516810068743],
              [-37.627878255724397, -8.454821725463136],
              [-37.62801690545249, -8.454960839065224],
              [-37.628377965301119, -8.455156165183594],
              [-37.62857229690573, -8.455267712131372],
              [-37.629128563739791, -8.455352855887238],
              [-37.629684830942402, -8.455437998890629],
              [-37.63026879223294, -8.455550999775189],
              [-37.63074159443557, -8.455635758750466],
              [-37.631158044258051, -8.4558867998362],
              [-37.631575368052651, -8.455916021254009],
              [-37.631992789890511, -8.455917480867951],
              [-37.632382817943636, -8.455780216572037],
              [-37.632772936916822, -8.455642861731727],
              [-37.633023335466312, -8.455643736568653],
              [-37.633163257349501, -8.455422493275467],
              [-37.632885562727381, -8.455255133714754],
              [-37.632330558065497, -8.454837311163361],
              [-37.631914212957419, -8.454530838742603],
              [-37.631469685481086, -8.454335223827691],
              [-37.630968897565289, -8.454305710598026],
              [-37.630330242375209, -8.453915264368451],
              [-37.630080733331823, -8.453637135886272],
              [-37.629858715239621, -8.453470059866445],
              [-37.629636893440541, -8.453247461059973],
              [-37.62977701236273, -8.452970606],
              [-37.630055878371685, -8.452805283248958],
              [-37.630418111948302, -8.452667923234086],
              [-37.630752553775437, -8.45253046562781],
              [-37.631114786936735, -8.452393104885878],
              [-37.631420857110172, -8.452394175103478],
              [-37.631615963705947, -8.452311662490983],
              [-37.631756283064163, -8.451951702854616],
              [-37.631701775468123, -8.451646495206223],
              [-37.631368899187031, -8.451340314492262],
              [-37.631341596593913, -8.451201591351213],
              [-37.631398255058848, -8.450896772321038],
              [-37.631510384532262, -8.450675341864709],
              [-37.631567035672255, -8.450398284457648],
              [-37.631317234121695, -8.450203350613464],
              [-37.631012436923683, -8.449841834977756],
              [-37.630706466744634, -8.449813003399441],
              [-37.630373005660537, -8.44967320948337],
              [-37.630150989409735, -8.449506133938142],
              [-37.629928386434244, -8.44950535513509],
              [-37.629649326976669, -8.449726200892224],
              [-37.629481907944232, -8.449864242536723],
              [-37.629147174480771, -8.450084892973416],
              [-37.628785335166498, -8.450111297212858],
              [-37.628646490734091, -8.450027616330425],
              [-37.628814203961795, -8.449806381510497],
              [-37.629037877019186, -8.449529909573682],
              [-37.629205687501134, -8.449281003592947],
              [-37.629345608783268, -8.449059671148387],
              [-37.629402065294776, -8.448838046510447],
              [-37.629208232985121, -8.448560112874121],
              [-37.629013813694762, -8.448448475966311],
              [-37.628986609722276, -8.448282081891351],
              [-37.628932405413472, -8.447865918964148],
              [-37.628933384452047, -8.447588667230121],
              [-37.629073402806107, -8.447339663872866],
              [-37.629407742356101, -8.447229877645659],
              [-37.629769481355851, -8.447231143318694],
              [-37.629770264115429, -8.447009323760994],
              [-37.629520269920192, -8.446869821506786],
              [-37.629270758084722, -8.446619454894263],
              [-37.629578193456851, -8.446232319011703],
              [-37.629857445685545, -8.445956040584905],
              [-37.629970363382668, -8.445485119585291],
              [-37.629971830715888, -8.445069151322908],
              [-37.630000788081595, -8.444764325993587],
              [-37.630167813481506, -8.444737148309127],
              [-37.630446281977989, -8.444682688852398],
              [-37.63058483217489, -8.444849472123559],
              [-37.631002046240006, -8.444906453642398],
              [-37.631280612669983, -8.444824232217661],
              [-37.631587360589272, -8.444631242980439],
              [-37.631922192536329, -8.444355066341025],
              [-37.632090577592422, -8.443967532648529],
              [-37.632258676587803, -8.443635340427976],
              [-37.632482154698856, -8.443386626555625],
              [-37.632677744237427, -8.443165486503295],
              [-37.632675301622619, -8.442724093508728],
              [-37.632424542047445, -8.442544349728417],
              [-37.632065440781062, -8.442543096396168],
              [-37.631670465779749, -8.442541717477738],
              [-37.631382537586326, -8.44275539061702],
              [-37.63102293218283, -8.442897193291195],
              [-37.630628461238814, -8.442752756296263],
              [-37.630485631996734, -8.442537578653017],
              [-37.630486262498621, -8.442358712235668],
              [-37.630738317010433, -8.442144914445826],
              [-37.630918749986286, -8.441895056354047],
              [-37.631063092036953, -8.441680881986901],
              [-37.631064100367929, -8.441394677550916],
              [-37.630777847035176, -8.441107469707493],
              [-37.630562423541988, -8.44110671709651],
              [-37.630418081509276, -8.441320891275232],
              [-37.630201648973987, -8.441606342781265],
              [-37.629950008547965, -8.441676992559186],
              [-37.629806927469204, -8.441533433672552],
              [-37.629664008250764, -8.44131825554347],
              [-37.629700982326902, -8.441032086578593],
              [-37.630097342824484, -8.440639924979322],
              [-37.63060116197537, -8.440319667637587],
              [-37.630925556823797, -8.439963063430667],
              [-37.631284438598513, -8.440000126580889],
              [-37.631500708300024, -8.439786203022971],
              [-37.631573967102632, -8.4393571014814],
              [-37.631754775879678, -8.43899990466622],
              [-37.632042917325627, -8.438750511997087],
              [-37.6324382661914, -8.438644551881449],
              [-37.632941074468206, -8.438610495484022],
              [-37.633264333876291, -8.438575812561391],
              [-37.633481013845646, -8.438218830106226],
              [-37.633625443762419, -8.43800465462976],
              [-37.633626198344686, -8.437789978425167],
              [-37.633519367882272, -8.437539117357103],
              [-37.633196235244519, -8.437537991128664],
              [-37.632477915814619, -8.437571296517261],
              [-37.632082945797002, -8.437569918820076],
              [-37.63158089465837, -8.437389298296296],
              [-37.631114682411621, -8.437244521797931],
              [-37.630899800120758, -8.437064902899897],
              [-37.630612758452969, -8.437028090703539],
              [-37.630145951960934, -8.437026460270921],
              [-37.630072979909187, -8.437348223151236],
              [-37.630071845145736, -8.437670236984395],
              [-37.629926999927505, -8.438027558569461],
              [-37.62996186454118, -8.438313888272704],
              [-37.629782063898652, -8.438384789306845],
              [-37.629243509200514, -8.438382906792226],
              [-37.628956754711197, -8.43823875504749],
              [-37.628671136123252, -8.437772679917327],
              [-37.628384887078354, -8.437485470983322],
              [-37.628422239631789, -8.437092054733029],
              [-37.628423502013831, -8.436734231679646],
              [-37.628172622231091, -8.436590205059318],
              [-37.627813527106461, -8.436588948711456],
              [-37.627454648954057, -8.436551973360977],
              [-37.627310469538685, -8.436694527287694],
              [-37.627165748905888, -8.437016038107181],
              [-37.626949696283553, -8.437194150074342],
              [-37.626806653540179, -8.437014780774527],
              [-37.626555991363041, -8.436835034528515],
              [-37.626089185899247, -8.436833399304676],
              [-37.62569421769193, -8.436832015293531],
              [-37.625405441786654, -8.437260359405972],
              [-37.6250465639297, -8.437223291361699],
              [-37.624796064830349, -8.436972015452502],
              [-37.624437223266469, -8.436899137209661],
              [-37.624150056367931, -8.436898129767521],
              [-37.623898925017606, -8.436825719381519],
              [-37.623719884401268, -8.436681942250404],
              [-37.623540590365295, -8.436609783779096],
              [-37.623289550187394, -8.43653737336852],
              [-37.623002419704171, -8.436500555457508],
              [-37.622894962936151, -8.436428648960836],
              [-37.623003560161983, -8.436178542563908],
              [-37.623147143271623, -8.436179046688284],
              [-37.623398527257343, -8.436179929178049],
              [-37.624008914570105, -8.4361820712874],
              [-37.624368008743438, -8.436183331058199],
              [-37.624655771993417, -8.436041281916667],
              [-37.625122485406379, -8.436042918375604],
              [-37.62587654699157, -8.436045561203402],
              [-37.626056220688042, -8.43601038086523],
              [-37.626416235259292, -8.43572543445881],
              [-37.626488957047364, -8.435475201055612],
              [-37.6262379167134, -8.435402792558431],
              [-37.626022911778549, -8.435258890467065],
              [-37.626095507152684, -8.435044466559694],
              [-37.626239812735712, -8.434866103637004],
              [-37.626599194304376, -8.434760023050451],
              [-37.627389126878583, -8.434762788178611],
              [-37.62774822025289, -8.43476404464505],
              [-37.62800027038714, -8.434550247989014],
              [-37.628108485183525, -8.434407477416954],
              [-37.628109494807106, -8.434121273230097],
              [-37.627895085172689, -8.433834315606948],
              [-37.627716801434175, -8.43347586454427],
              [-37.627860853008706, -8.433369119662228],
              [-37.628148108567991, -8.433370124287938],
              [-37.628326807058798, -8.43358533714197],
              [-37.628793356564145, -8.43365858796947],
              [-37.629116233257832, -8.433731245782225],
              [-37.629223024954705, -8.43401782681719],
              [-37.62950855109969, -8.434483991407211],
              [-37.629723809608961, -8.43455627281555],
              [-37.629867770700422, -8.434449436432596],
              [-37.630192034546191, -8.434128551091293],
              [-37.630336086177017, -8.434021714805457],
              [-37.630443669843942, -8.434057900343753],
              [-37.630693954699638, -8.434344982119963],
              [-37.631016293393479, -8.434596595787671],
              [-37.631841848510845, -8.434671005647347],
              [-37.632308526565446, -8.434708443106546],
              [-37.632595818748491, -8.434673634816827],
              [-37.632669039417898, -8.434280342434223],
              [-37.632670800416108, -8.433779461534892],
              [-37.632672561305974, -8.433278580622384],
              [-37.632530865550493, -8.43274138975708],
              [-37.631884266155389, -8.432810664919188],
              [-37.631633225899883, -8.432738259981063],
              [-37.631382095262353, -8.432665764152182],
              [-37.63149030801307, -8.432523082779189],
              [-37.631705852799655, -8.432488024630224],
              [-37.631850029054966, -8.432345468550514],
              [-37.631851035993833, -8.432059173455736],
              [-37.631528159764322, -8.431986518028774],
              [-37.631205318880475, -8.431878052553513],
              [-37.630774608103046, -8.431840739837526],
              [-37.630775363699009, -8.431626063822218],
              [-37.631027123270826, -8.431519602933236],
              [-37.63131466434853, -8.431413357100606],
              [-37.631494587361679, -8.431306645289295],
              [-37.631854055643906, -8.431200559429668],
              [-37.632249270944946, -8.431130317321616],
              [-37.632393607388551, -8.43091614159537],
              [-37.632538285882525, -8.430630437518909],
              [-37.632395870867185, -8.430272203502378],
              [-37.632038255672271, -8.429877319046481],
              [-37.631464000382586, -8.429803787547703],
              [-37.630745912540661, -8.429801282868235],
              [-37.63024437564895, -8.429477514794277],
              [-37.629669869806897, -8.429475509321012],
              [-37.629131362489275, -8.429437909277642],
              [-37.628665791016402, -8.429114265078551],
              [-37.628199625256634, -8.428933677052767],
              [-37.627733207457723, -8.428824707417066],
              [-37.627374588984509, -8.428716114262071],
              [-37.627339382606586, -8.428501403183356],
              [-37.627770936887551, -8.428323953142026],
              [-37.628201769228887, -8.428325459047201],
              [-37.628454283503338, -8.428004323717847],
              [-37.628921491991768, -8.427862897139791],
              [-37.629424159650988, -8.427864652520588],
              [-37.629820126946314, -8.427579826831774],
              [-37.630322794429844, -8.427581581003583],
              [-37.630682636879939, -8.427368157671907],
              [-37.630862809894495, -8.427189826881655],
              [-37.63064852695873, -8.426867061465737],
              [-37.630146489799621, -8.426686441323795],
              [-37.629716071701495, -8.426541880494055],
              [-37.629321617390254, -8.426397354369055],
              [-37.629250628802005, -8.426182427953405],
              [-37.629682718292166, -8.425826198841772],
              [-37.629828926079426, -8.42549284492252],
              [-37.6298299031222, -8.425215231055939],
              [-37.629335120240199, -8.425090068582454],
              [-37.628716030664933, -8.425087907099938],
              [-37.628344377564403, -8.425148372071142],
              [-37.628002497132002, -8.42554813992979],
              [-37.627691584130758, -8.425948015609316],
              [-37.627288418673267, -8.426162460271467],
              [-37.626174327585417, -8.426096891013858],
              [-37.625802673809389, -8.426157262929705],
              [-37.625679545229652, -8.425971814374266],
              [-37.625557070284991, -8.425601260079606],
              [-37.625185634786277, -8.425599959719206],
              [-37.624845276356567, -8.425567931594179],
              [-37.62462771905939, -8.425813859607233],
              [-37.624626302127737, -8.426214906760597],
              [-37.624563821090952, -8.426368869128872],
              [-37.62434691695816, -8.426429781687977],
              [-37.624317038867275, -8.426121224105136],
              [-37.624007975282112, -8.425996796114378],
              [-37.623636539648764, -8.425995494228196],
              [-37.623079277242148, -8.426024376589542],
              [-37.622831277748304, -8.426146851710708],
              [-37.622583077870964, -8.426300252600498],
              [-37.622305110795246, -8.426114169554815],
              [-37.622088843213909, -8.426020901971606],
              [-37.621686439779062, -8.426019489555454],
              [-37.621562567465197, -8.426019054686751],
              [-37.621345098585365, -8.426265071247107],
              [-37.621220352449122, -8.4265113228512],
              [-37.621033869611409, -8.426726611771077],
              [-37.620755775159388, -8.426602290084531],
              [-37.620757086568915, -8.426232169840578],
              [-37.620510963169153, -8.425799417503677],
              [-37.620512493211336, -8.425367625650068],
              [-37.620234837016547, -8.425119960516373],
              [-37.619863840373327, -8.424995221534555],
              [-37.619616843376591, -8.424809336199427],
              [-37.61933842276845, -8.424777521197317],
              [-37.619090769998699, -8.424776650386397],
              [-37.618781272185117, -8.42477556189283],
              [-37.618502432706897, -8.424836253366923],
              [-37.618223574549241, -8.424927780735583],
              [-37.618099702764205, -8.424927344830675],
              [-37.618007875148976, -8.424649405706173],
              [-37.617946596038365, -8.424464173026122],
              [-37.617668285477521, -8.424401521119815],
              [-37.617669161588751, -8.424154744443724],
              [-37.618072328805127, -8.423940219730817],
              [-37.618351514475833, -8.423756184984493],
              [-37.618352608904274, -8.42344773664631],
              [-37.617981504902843, -8.423353922443948],
              [-37.617641478723726, -8.423229290706461],
              [-37.617301342995098, -8.42313558491446],
              [-37.617022285330435, -8.423258037470719],
              [-37.616742990044635, -8.423472907002816],
              [-37.616525758091285, -8.423626413040983],
              [-37.616309293633122, -8.423563887725473],
              [-37.616248434996756, -8.423286147956588],
              [-37.616063376916067, -8.423100388598595],
              [-37.615908538054192, -8.423099842977161],
              [-37.615753241641436, -8.423253566830985],
              [-37.615444293974562, -8.423098206707262],
              [-37.615104159194601, -8.423004499115157],
              [-37.614670902013913, -8.422972135076803],
              [-37.614577541084188, -8.423125986420008],
              [-37.614391276453738, -8.42327960040932],
              [-37.614174592918737, -8.423278835903815],
              [-37.613989645739338, -8.423062239994122],
              [-37.613618983241466, -8.422844988547242],
              [-37.613247552248772, -8.422843677321692],
              [-37.612938056738791, -8.422842584472544],
              [-37.612627811003236, -8.423026595789308],
              [-37.612349063390248, -8.423087283266211],
              [-37.612038817238407, -8.423271294038601],
              [-37.61182167511727, -8.423424707086989],
              [-37.611666487466017, -8.423547502908489],
              [-37.61138665861445, -8.423885801019237],
              [-37.611137577077514, -8.424285879759257],
              [-37.610920213707345, -8.424501053963944],
              [-37.610887925375529, -8.424871063147354],
              [-37.610639943318816, -8.424962694062273],
              [-37.610300359139977, -8.424714803821111],
              [-37.609712793832841, -8.42455845326352],
              [-37.609032416268434, -8.424401863593939],
              [-37.608383026914481, -8.424214456164158],
              [-37.608042673435115, -8.42418241388695],
              [-37.607640164034116, -8.424211823036341],
              [-37.607267940791118, -8.424457281851314],
              [-37.606989321734432, -8.424456293612726],
              [-37.606710793526162, -8.42445530549889],
              [-37.606928158811826, -8.424240133998516],
              [-37.607269154385797, -8.424117999333541],
              [-37.607310002674502, -8.42401055593019],
              [-37.602842969447664, -8.424704709238563],
              [-37.60148743366085, -8.42557052515461],
              [-37.625585901838555, -8.429266897132173],
              [-37.626987890811463, -8.431752270380905],
              [-37.617990598196926, -8.436953153916415],
              [-37.62793421815617, -8.43985882987984],
              [-37.626722789565854, -8.450692489186965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0196000',
        uf: 'PE',
        nome_proje: 'PA MELANCIA',
        municipio: 'IBIMIRIM',
        area_hecta: '325.1498',
        capacidade: 29.0,
        num_famili: 21.0,
        fase: 3.0,
        data_de_cr: '31/10/2006',
        forma_obte: 'Cessão',
        data_obten: '31/10/2006',
        area_calc_: 325.0998,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.714317995324812, -8.448373560959354],
              [-37.715567591044149, -8.447515392105709],
              [-37.716103939340236, -8.446326070423783],
              [-37.716131591313307, -8.446038858497305],
              [-37.716115618827097, -8.445183319135872],
              [-37.716811088189175, -8.445285253762],
              [-37.717017252106871, -8.444627673969588],
              [-37.716118438012586, -8.44402814955299],
              [-37.717230686284815, -8.443170879042158],
              [-37.715243855611156, -8.440248751434719],
              [-37.715463120884948, -8.439199101924688],
              [-37.712354870196108, -8.438820214426441],
              [-37.711246266828319, -8.436114014537958],
              [-37.711512821396056, -8.435266910282433],
              [-37.711760971240508, -8.435067689975101],
              [-37.711330747319309, -8.429447650713158],
              [-37.710765729746178, -8.429399674871396],
              [-37.710110396198139, -8.430925549401724],
              [-37.706611239401866, -8.430355254053168],
              [-37.704957264344102, -8.431131491244768],
              [-37.702305647991714, -8.435110222830204],
              [-37.701502157280608, -8.438452627375911],
              [-37.701331683097358, -8.439078029974297],
              [-37.701326836545043, -8.439553952772769],
              [-37.701435097809451, -8.440120596186029],
              [-37.702366793890832, -8.441415315734886],
              [-37.702401525976548, -8.441404036344013],
              [-37.702805370059806, -8.440492827900314],
              [-37.70293671968841, -8.440432492516035],
              [-37.703928351369356, -8.438027487456711],
              [-37.70395183369731, -8.43803968291744],
              [-37.705015225144649, -8.437972207574294],
              [-37.705571578684335, -8.437597667892048],
              [-37.705588870405705, -8.437177850559054],
              [-37.705861494987587, -8.436175410453238],
              [-37.706545266703316, -8.435910530828105],
              [-37.706648014551774, -8.436065869696479],
              [-37.708024515676058, -8.434534241736609],
              [-37.70803371920799, -8.434525048006353],
              [-37.708981506902091, -8.433685162988473],
              [-37.708283265789397, -8.434807926674926],
              [-37.708605763093466, -8.435002603106652],
              [-37.708207148225959, -8.435521454248606],
              [-37.708105624433507, -8.435762934489858],
              [-37.706703530238492, -8.436823328240834],
              [-37.706496183260519, -8.437123601195683],
              [-37.706554203933379, -8.43802883342385],
              [-37.706493358982911, -8.438327238291503],
              [-37.706785743996249, -8.438837695216588],
              [-37.705980456749067, -8.439290361818637],
              [-37.705425561840357, -8.439771615963529],
              [-37.705275054838751, -8.439966541782203],
              [-37.704635391841023, -8.440865037497661],
              [-37.704807881899249, -8.44173185239754],
              [-37.706992003228279, -8.442549694142382],
              [-37.707557249531796, -8.443353236166608],
              [-37.708879581366837, -8.4432898619045],
              [-37.708488594138473, -8.444106709766904],
              [-37.709741233126465, -8.444354279119535],
              [-37.709652423317692, -8.44462284044033],
              [-37.708052613749615, -8.444742454648955],
              [-37.706815790638444, -8.444082111277655],
              [-37.706377398414617, -8.443995929646423],
              [-37.704531720141226, -8.443165999509153],
              [-37.704300687321236, -8.443077607412146],
              [-37.704050505593159, -8.44342042030407],
              [-37.703204487268657, -8.443065661630694],
              [-37.70200449181997, -8.443266606615513],
              [-37.700357131680938, -8.443240618497974],
              [-37.699359196709018, -8.443420702501191],
              [-37.704024991514807, -8.446412446937446],
              [-37.703790478792108, -8.447121197362659],
              [-37.701302604532394, -8.446146966856414],
              [-37.700391098011103, -8.446064183320546],
              [-37.698223470723612, -8.445690829247258],
              [-37.698133730929833, -8.445638352108492],
              [-37.696815574256547, -8.445295213510136],
              [-37.696847084102593, -8.446271434518081],
              [-37.694869831901102, -8.445545832523409],
              [-37.69371822488386, -8.445264370301269],
              [-37.6928029465329, -8.445467591045254],
              [-37.692607389649581, -8.445363666019215],
              [-37.692312265884674, -8.447937076650039],
              [-37.693071631589497, -8.449182950305026],
              [-37.69031505995514, -8.449196891009635],
              [-37.690915142971818, -8.450461045031892],
              [-37.695218567418522, -8.45158066095566],
              [-37.695099661516288, -8.450801922569722],
              [-37.695746557224929, -8.450871178108033],
              [-37.696435178194299, -8.451389382234442],
              [-37.697717304168172, -8.452352764385138],
              [-37.69813980099088, -8.452684606000238],
              [-37.696014755432081, -8.452036012916043],
              [-37.69631319563711, -8.453931904771194],
              [-37.696029929503254, -8.454115260107264],
              [-37.695992837413876, -8.454460127747428],
              [-37.696009593469512, -8.454463895453774],
              [-37.695776094695219, -8.45649584635555],
              [-37.697510276341511, -8.457179029882221],
              [-37.698908031399533, -8.455763907894209],
              [-37.698690942269572, -8.454712192371028],
              [-37.69987638861285, -8.455123435623307],
              [-37.702383291726512, -8.45357325954444],
              [-37.701100133403045, -8.451773395748182],
              [-37.699497584424897, -8.450777941505979],
              [-37.698475703309633, -8.448694261728695],
              [-37.701308834725019, -8.448954886053937],
              [-37.703347002491299, -8.451194214703927],
              [-37.705329835194689, -8.451514212841888],
              [-37.707120279308462, -8.447942018526588],
              [-37.709745649066228, -8.448547074087879],
              [-37.712701544865382, -8.44849404177811],
              [-37.714317995324812, -8.448373560959354],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0209000',
        uf: 'PE',
        nome_proje: 'PA FREI DAMIÃO',
        municipio: 'IBIMIRIM',
        area_hecta: '316.0138',
        capacidade: 36.0,
        num_famili: 35.0,
        fase: 3.0,
        data_de_cr: '13/12/2006',
        forma_obte: 'Doação',
        data_obten: '13/12/2006',
        area_calc_: 316.0859,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.626722789565854, -8.450692489186965],
              [-37.62554469653513, -8.45085591976073],
              [-37.620944398743653, -8.446318490821096],
              [-37.612200482283015, -8.444925577943494],
              [-37.615268201442994, -8.45094196700609],
              [-37.613825450380105, -8.450292197466188],
              [-37.612321967874522, -8.451465062396606],
              [-37.600919496971663, -8.446562259666237],
              [-37.58710337030157, -8.448030581938907],
              [-37.587866371326172, -8.453117977344268],
              [-37.599285616137195, -8.451978836132728],
              [-37.603335990047583, -8.456144856939288],
              [-37.595585258680792, -8.461110348806361],
              [-37.601645825837501, -8.463181706362111],
              [-37.603962448873951, -8.462108647881887],
              [-37.613653316042338, -8.459906112005026],
              [-37.614355354515553, -8.460525230904832],
              [-37.620367345203121, -8.462044345175025],
              [-37.62036893486647, -8.461597724045784],
              [-37.620159339244481, -8.46133465120819],
              [-37.620160552741766, -8.460993739775557],
              [-37.620213886319696, -8.460784133666726],
              [-37.620319524461131, -8.460679518793695],
              [-37.620556483065158, -8.460680354705861],
              [-37.621004157256841, -8.460629484971024],
              [-37.621399060498319, -8.460604652981171],
              [-37.621872887031863, -8.460606323019384],
              [-37.622162339397264, -8.460633567241821],
              [-37.622320188433008, -8.460660347659669],
              [-37.62242638484733, -8.460398478653371],
              [-37.62240051194479, -8.460267265963193],
              [-37.621979456560659, -8.460239558269199],
              [-37.621663945411953, -8.460133549210733],
              [-37.621321813483895, -8.460132343280474],
              [-37.620769531017373, -8.459973050228561],
              [-37.620375655294275, -8.459709327687142],
              [-37.620008211893889, -8.459419564232975],
              [-37.619772280976747, -8.45913026461189],
              [-37.619509822761742, -8.458919453694323],
              [-37.619167601393535, -8.458918245731356],
              [-37.618851809586751, -8.458917130793113],
              [-37.618562265684481, -8.458916108301979],
              [-37.618430009017139, -8.459099211071942],
              [-37.618428513619541, -8.459518884533319],
              [-37.618427672605883, -8.45975489998585],
              [-37.618164282578533, -8.459780193754156],
              [-37.617953847803584, -8.459753225941386],
              [-37.617876046876475, -8.459412035587391],
              [-37.617614337692814, -8.458991432088109],
              [-37.617194313579652, -8.458675256323202],
              [-37.616642500770475, -8.458411062672974],
              [-37.616116840566619, -8.458199318944072],
              [-37.61567038649433, -8.457909272599316],
              [-37.615170600507675, -8.457802606718646],
              [-37.614591421622805, -8.457826780240413],
              [-37.614249573878446, -8.457746896766691],
              [-37.613987216705283, -8.457483634654631],
              [-37.613513955159895, -8.45735083656368],
              [-37.613066472799488, -8.457349250416721],
              [-37.612750123240907, -8.457479249832474],
              [-37.612565187359088, -8.457662253893622],
              [-37.612366472416852, -8.458015575877811],
              [-37.611997817430719, -8.458066716403669],
              [-37.611681936455973, -8.458065595467668],
              [-37.611102100324018, -8.458273330740084],
              [-37.610522827168495, -8.458323812360756],
              [-37.610208262296688, -8.457955465878861],
              [-37.610009499197695, -8.457713497204585],
              [-37.609603105514722, -8.457549824831275],
              [-37.609305541918708, -8.457278386859187],
              [-37.609007390754201, -8.457196212812857],
              [-37.608627281012268, -8.45727597521495],
              [-37.608301392077244, -8.457355930109102],
              [-37.607947570764651, -8.457652089425538],
              [-37.60786473834446, -8.458057274418424],
              [-37.608025939269645, -8.458490456272767],
              [-37.608323605860036, -8.458707819543402],
              [-37.608702450012046, -8.45900649502423],
              [-37.60916373850452, -8.459008135559978],
              [-37.609162962617148, -8.45922443699838],
              [-37.608837072015987, -8.45930439208407],
              [-37.608700606635253, -8.459520210835406],
              [-37.608320694848537, -8.459518859219509],
              [-37.607941068098476, -8.459463432227757],
              [-37.607778799132241, -8.459327664610459],
              [-37.607453393882423, -8.459272430182926],
              [-37.607181724278199, -8.459352576867339],
              [-37.607072608326824, -8.45951441629283],
              [-37.606990745433436, -8.459649314739231],
              [-37.606989580011927, -8.459973676157471],
              [-37.606988899971803, -8.460162939731232],
              [-37.606663202743356, -8.460188817501791],
              [-37.60655476657427, -8.460161393165775],
              [-37.606446816357128, -8.459998780566512],
              [-37.606230138590611, -8.459889856478846],
              [-37.606067390523542, -8.459862238471153],
              [-37.60582325887993, -8.459861368285551],
              [-37.605579425414987, -8.459752347074032],
              [-37.605390292780584, -8.459535459282977],
              [-37.605309408384066, -8.459372942975449],
              [-37.605038704813118, -8.45920974972357],
              [-37.604740261781579, -8.459208685223556],
              [-37.60441495479273, -8.459126410728247],
              [-37.604007888149084, -8.459124958092564],
              [-37.603763757184694, -8.459124086697047],
              [-37.603681899904679, -8.459231946339337],
              [-37.603544841124133, -8.45963702645529],
              [-37.603438984699359, -8.459876915579047],
              [-37.603194372852101, -8.459984193866012],
              [-37.602869163211892, -8.459874880429243],
              [-37.602706019215006, -8.459982449407066],
              [-37.602461212238879, -8.460143802393032],
              [-37.602162768817884, -8.460142735809855],
              [-37.601918839090622, -8.46006075004995],
              [-37.601674616932968, -8.460059876930249],
              [-37.601293918659138, -8.46030185701588],
              [-37.600831849852007, -8.460516417176992],
              [-37.600451443527518, -8.460677283547442],
              [-37.599964404558577, -8.460914993118852],
              [-37.599716426401912, -8.461048661836596],
              [-37.599446420300509, -8.461070030565557],
              [-37.599469591880002, -8.46089088563707],
              [-37.599965618442667, -8.460578877226235],
              [-37.60048367300962, -8.460378986988292],
              [-37.600979283717166, -8.460156680006397],
              [-37.601340265050325, -8.459866612516732],
              [-37.601543355444178, -8.459688020245968],
              [-37.6019033562403, -8.459644455058164],
              [-37.602218642053877, -8.459533541781697],
              [-37.602511090723283, -8.459534586828108],
              [-37.602668819213456, -8.459467962414786],
              [-37.602781499996993, -8.459401086579017],
              [-37.602782307483928, -8.459177009029913],
              [-37.602670030868801, -8.459131755680691],
              [-37.602422539608433, -8.459130871442357],
              [-37.602220096318504, -8.459130148035271],
              [-37.6023108286075, -8.458928727573094],
              [-37.602468799337785, -8.458794825690729],
              [-37.602784165141095, -8.458661485973975],
              [-37.603031968956103, -8.458550330819998],
              [-37.603549293198363, -8.458552178011475],
              [-37.603886617637876, -8.458575808255443],
              [-37.604336622477476, -8.458532561843629],
              [-37.604494269631537, -8.458488362431025],
              [-37.604674430914116, -8.458421726684117],
              [-37.604854440912966, -8.458422368751418],
              [-37.60496687905264, -8.458422769758553],
              [-37.605146798252079, -8.458423411367562],
              [-37.605326395155792, -8.45851366592956],
              [-37.60557348310256, -8.458626677606411],
              [-37.605865276926465, -8.458784519950139],
              [-37.606180158655036, -8.458785642117176],
              [-37.606473412551068, -8.458562606200543],
              [-37.606631543114183, -8.458383850619965],
              [-37.606744222730221, -8.458317063903403],
              [-37.606745994359464, -8.457823965831011],
              [-37.606612481767556, -8.457420090596596],
              [-37.60652344321938, -8.457150840327715],
              [-37.606388884071677, -8.457038320634387],
              [-37.606232294898398, -8.456813591609381],
              [-37.606232938964617, -8.456634365452627],
              [-37.60639081703772, -8.456500370848469],
              [-37.606459022305366, -8.456298959146311],
              [-37.606706914648782, -8.456187801558002],
              [-37.607022277286255, -8.456054457825475],
              [-37.607314633111571, -8.456055498532534],
              [-37.607449755552189, -8.456011127107129],
              [-37.60760820617584, -8.455742757732258],
              [-37.607698773801985, -8.455586187186618],
              [-37.607676893611973, -8.455406790336664],
              [-37.607564938886476, -8.455271925332873],
              [-37.60734085833122, -8.4550246208301],
              [-37.607206541083507, -8.454844823823937],
              [-37.607116858576461, -8.454754890355195],
              [-37.606757023291543, -8.454753609417939],
              [-37.606442144758205, -8.454752488245788],
              [-37.605969781628552, -8.454750805851114],
              [-37.605767340317065, -8.45475008465041],
              [-37.605700746158952, -8.454503250044002],
              [-37.605633909935001, -8.454323783483993],
              [-37.605476667331189, -8.454255944728702],
              [-37.605252034964622, -8.45418795618466],
              [-37.604959589982819, -8.454186913841426],
              [-37.604779189190069, -8.454320737229711],
              [-37.604710983613948, -8.454522148573597],
              [-37.604553337831781, -8.454566438774933],
              [-37.604260983473637, -8.454565396187308],
              [-37.604036260768069, -8.45449731620092],
              [-37.604127071066145, -8.454273559509527],
              [-37.604352670612542, -8.454072619194964],
              [-37.604510799517847, -8.453893954720426],
              [-37.60451136381878, -8.453737064105285],
              [-37.604512331073671, -8.453468134598294],
              [-37.604130538146904, -8.453309880538859],
              [-37.603680781592224, -8.453285850138265],
              [-37.603388337486891, -8.453284806731656],
              [-37.603208582267484, -8.453239312998759],
              [-37.603366943277365, -8.45297094527329],
              [-37.603569867366119, -8.452837202860536],
              [-37.603683039908361, -8.452658287873312],
              [-37.603684249370176, -8.452322171303063],
              [-37.603685378416543, -8.452008390157289],
              [-37.603349350351749, -8.451626127847316],
              [-37.603012838595639, -8.451378420459021],
              [-37.602541852026086, -8.450995766471033],
              [-37.602024941639328, -8.450881880800178],
              [-37.60153151442384, -8.450476629155329],
              [-37.600764245034732, -8.450428673879983],
              [-37.600084316323205, -8.450086867537811],
              [-37.599616659089421, -8.449915552532506],
              [-37.599277920084468, -8.449405322507474],
              [-37.598895668960481, -8.449149400578332],
              [-37.598342302753345, -8.449147420220523],
              [-37.59753346015254, -8.449144524170663],
              [-37.597149983978468, -8.449228062235525],
              [-37.596807876168114, -8.449650942668772],
              [-37.596593551176056, -8.450074371135997],
              [-37.596379901668101, -8.450285748773604],
              [-37.595954227571575, -8.450284222527856],
              [-37.595357344939913, -8.450536545191326],
              [-37.594889690214309, -8.450365224793899],
              [-37.594208539339768, -8.450362779703333],
              [-37.5936122705656, -8.450445459460308],
              [-37.593228946889027, -8.450486492900755],
              [-37.593102637667293, -8.450104253638097],
              [-37.592763446464183, -8.449721339767246],
              [-37.592465466403887, -8.449720268730406],
              [-37.591996429957803, -8.44993063507499],
              [-37.591612799130495, -8.450056486937127],
              [-37.591144377689922, -8.450097212254024],
              [-37.590333995046002, -8.45051849093978],
              [-37.58990708885009, -8.450856328036611],
              [-37.589715725496497, -8.450932140410133],
              [-37.589565329042763, -8.450781217713242],
              [-37.589781482165122, -8.450631615436825],
              [-37.590040930645536, -8.450439215867453],
              [-37.590451451918369, -8.45016145384067],
              [-37.590667760347465, -8.449968898442766],
              [-37.590883366733976, -8.449969674437076],
              [-37.591185161217631, -8.449970760433724],
              [-37.591487968986456, -8.449692609537696],
              [-37.591704276696085, -8.449500053435264],
              [-37.592006148655123, -8.44947970768229],
              [-37.592243357239816, -8.449459038793588],
              [-37.592502479301046, -8.449331110964451],
              [-37.592696561210879, -8.449331808533898],
              [-37.592976428948511, -8.449418720502202],
              [-37.59316965460976, -8.449655702149057],
              [-37.593492117305523, -8.449893147996054],
              [-37.594095940065792, -8.449830932062067],
              [-37.594505538186205, -8.449832402544335],
              [-37.595066079236993, -8.449834414226709],
              [-37.595389631809255, -8.449771100080058],
              [-37.595735887165205, -8.449407194901285],
              [-37.596017478121091, -8.449043057466682],
              [-37.596277143126429, -8.448764657073303],
              [-37.59664477876737, -8.44846530242296],
              [-37.596903743909024, -8.448380323961729],
              [-37.597399528949246, -8.448382099953113],
              [-37.597593610794192, -8.448382795018349],
              [-37.597830120705879, -8.448555454725813],
              [-37.598412107320684, -8.448579059929441],
              [-37.598907892936218, -8.448580834053081],
              [-37.599360630032315, -8.448582453583287],
              [-37.599662424539716, -8.448583532871286],
              [-37.600027875897119, -8.448864080802675],
              [-37.600458611422106, -8.448972958373886],
              [-37.600845446599457, -8.44931805721416],
              [-37.601146854754518, -8.449426562439433],
              [-37.601276027890314, -8.449469977141629],
              [-37.601944141739551, -8.449536747836246],
              [-37.602202952384566, -8.449494718530071],
              [-37.602440780007854, -8.449302232439843],
              [-37.602677911500351, -8.449303078701389],
              [-37.602828158809686, -8.449496949851135],
              [-37.603042515426658, -8.449820000005625],
              [-37.603408370997506, -8.450014549748536],
              [-37.603687624475683, -8.450273355972978],
              [-37.603859088103704, -8.450553209138228],
              [-37.604030860935666, -8.450747156744947],
              [-37.604353809670151, -8.450877168059103],
              [-37.604978619304276, -8.450965301538735],
              [-37.605626427778702, -8.450997179284593],
              [-37.60583983223443, -8.45128278776531],
              [-37.605838424681401, -8.451674607651588],
              [-37.606159856600705, -8.451746919159524],
              [-37.606516815934775, -8.451890704667999],
              [-37.606694784367001, -8.452105019778184],
              [-37.606836713997716, -8.452390373504251],
              [-37.606907678557029, -8.452533140786132],
              [-37.607157635272323, -8.452605197229781],
              [-37.607408139943892, -8.452499292898585],
              [-37.607587003753416, -8.452464300504882],
              [-37.607909458718765, -8.452251765665553],
              [-37.608160053840791, -8.452145861103858],
              [-37.608624694449603, -8.452147512902442],
              [-37.60894587220865, -8.452291078779973],
              [-37.609159279636181, -8.452576776370675],
              [-37.609408726310562, -8.452791344497397],
              [-37.609980627875494, -8.452793375856194],
              [-37.610195056941066, -8.452794137279515],
              [-37.610409066809147, -8.452937321589893],
              [-37.610730282816803, -8.453045257605639],
              [-37.611195015832877, -8.453046906850409],
              [-37.611480411608014, -8.453190434371038],
              [-37.611478500056748, -8.453724587476026],
              [-37.611512371801439, -8.454258957951595],
              [-37.611761220485427, -8.454615947375183],
              [-37.611939193209629, -8.454830260742257],
              [-37.612260374375602, -8.454973914864464],
              [-37.612618104178907, -8.454903925789392],
              [-37.613083894691073, -8.454585098855341],
              [-37.613263050643489, -8.45444321866864],
              [-37.6135853777634, -8.454266307258122],
              [-37.613835973319759, -8.454160399031473],
              [-37.613837500507032, -8.453732949531515],
              [-37.61391088535828, -8.453199049019016],
              [-37.613948068343937, -8.45280744495633],
              [-37.61409178447645, -8.452594271563978],
              [-37.614377690255196, -8.45259528374303],
              [-37.61462726757096, -8.452774220669673],
              [-37.61469721832156, -8.453201832891709],
              [-37.614982452700303, -8.45341652466953],
              [-37.615304015767869, -8.453453291245493],
              [-37.615983089313723, -8.453455693130289],
              [-37.616268868894679, -8.453492332395363],
              [-37.616375621423821, -8.453635134629428],
              [-37.616374605291156, -8.453920071129961],
              [-37.616480723014163, -8.454240924764552],
              [-37.616801525255276, -8.454491370041099],
              [-37.617265535765839, -8.454671063499054],
              [-37.617944485081075, -8.454709090712184],
              [-37.618516391547054, -8.454711110056326],
              [-37.61894534419492, -8.454712624096528],
              [-37.619160282073253, -8.454570957581787],
              [-37.619267578904882, -8.454535707304665],
              [-37.619268719786433, -8.454215142054894],
              [-37.619126399725594, -8.454036586074373],
              [-37.61905593704693, -8.453751397050963],
              [-37.61898598136365, -8.453323784830459],
              [-37.61884404184773, -8.453038434026594],
              [-37.618666572782445, -8.452681609689497],
              [-37.618381300371254, -8.452502549067503],
              [-37.618167413935211, -8.452323740282898],
              [-37.617917418453182, -8.452287228855726],
              [-37.617595855737768, -8.452250464541253],
              [-37.617596363097412, -8.452108041419265],
              [-37.617597251197047, -8.451858733132738],
              [-37.617776150618177, -8.451788107172883],
              [-37.618134130619232, -8.45164694604424],
              [-37.618277210250021, -8.451611822229102],
              [-37.618384850436513, -8.451505406017439],
              [-37.61838535778319, -8.451362892414979],
              [-37.618349827555633, -8.451291599754734],
              [-37.618099868725544, -8.451219459829231],
              [-37.61795729672825, -8.451112070022523],
              [-37.61799447514916, -8.450720464844533],
              [-37.617995869949915, -8.450328733339198],
              [-37.617996884454719, -8.450043796590563],
              [-37.617569459140768, -8.449614922501524],
              [-37.617141618242911, -8.44932847152527],
              [-37.616821453857632, -8.448899975635607],
              [-37.616393650180598, -8.448577985778279],
              [-37.616323189930725, -8.448292796668362],
              [-37.616038302778207, -8.448006849878967],
              [-37.615717759540523, -8.447685238532554],
              [-37.615540168194066, -8.447364041905335],
              [-37.615719156451902, -8.447293416917462],
              [-37.616039953084112, -8.447543861899971],
              [-37.616467918177172, -8.447794685279542],
              [-37.616574415661262, -8.44800874401809],
              [-37.617002888992751, -8.448117143830665],
              [-37.617431834078822, -8.448118658356437],
              [-37.617860779244545, -8.448120172412782],
              [-37.618147062607434, -8.448014296099839],
              [-37.618290267315658, -8.447943634079868],
              [-37.61843220513569, -8.448228984791442],
              [-37.618574142816989, -8.448514425908492],
              [-37.618787520625112, -8.448835747843152],
              [-37.619323635277304, -8.44883763840361],
              [-37.619717304906132, -8.448696510688965],
              [-37.619932745159574, -8.448412419837215],
              [-37.62014814960024, -8.448163867137843],
              [-37.620327173540865, -8.448057611214585],
              [-37.620218992631081, -8.448342170708244],
              [-37.620146251125405, -8.44869802234515],
              [-37.62014498508497, -8.449054216235851],
              [-37.620107302957514, -8.449588245317912],
              [-37.620105783754276, -8.450015605617466],
              [-37.620247216928504, -8.450443469700378],
              [-37.620675152586863, -8.45072991815236],
              [-37.621066799307528, -8.451158663539342],
              [-37.621173715326073, -8.451230297883408],
              [-37.621316253017959, -8.451373225004989],
              [-37.621530935102498, -8.451302813624853],
              [-37.621746502788696, -8.450983002951062],
              [-37.621997979840494, -8.450627780014141],
              [-37.622355831654986, -8.450522152631386],
              [-37.622678405145933, -8.450273975570397],
              [-37.622928526704364, -8.450274855352262],
              [-37.62310713643776, -8.450311112417705],
              [-37.623249204723251, -8.450560924009158],
              [-37.623175580743975, -8.451166085436263],
              [-37.62313824537344, -8.451628949193973],
              [-37.623208584638185, -8.451949766404468],
              [-37.623565588864942, -8.452057818183667],
              [-37.623958250421786, -8.452201714368899],
              [-37.624279435941709, -8.452345268581139],
              [-37.624491670432057, -8.452452991650361],
              [-37.624518962931695, -8.452619476488721],
              [-37.624407123974201, -8.452757710767598],
              [-37.624267296912549, -8.452951279459681],
              [-37.624099579757342, -8.453172511664468],
              [-37.623903586545261, -8.453504600417595],
              [-37.623763857403397, -8.453670407563544],
              [-37.623902603217601, -8.453781851480496],
              [-37.624320021412046, -8.453783318910611],
              [-37.624541938350134, -8.453978159002753],
              [-37.624624715622865, -8.454172600471946],
              [-37.624679505178584, -8.454422285984114],
              [-37.624790066935788, -8.454644496287276],
              [-37.624984102116777, -8.454839238251882],
              [-37.625039284880735, -8.454978059458183],
              [-37.62548420144234, -8.455062816954273],
              [-37.625901621293124, -8.455064282918274],
              [-37.626069436537151, -8.454815379054818],
              [-37.626237538983162, -8.454510952793893],
              [-37.626238029860026, -8.454372327131662],
              [-37.626460831533798, -8.454317676293769],
              [-37.626600953049611, -8.45404082280969],
              [-37.626601737940497, -8.453819094052463],
              [-37.62638001642383, -8.453568732363385],
              [-37.626102228401258, -8.453429129954122],
              [-37.625935893871343, -8.453234485800772],
              [-37.625937072296111, -8.452901711888089],
              [-37.626132376153727, -8.4527637700574],
              [-37.626633749621163, -8.452626902095346],
              [-37.626829347525863, -8.452405766341194],
              [-37.627143376564355, -8.452390500201266],
              [-37.626722789565854, -8.450692489186965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0340000',
        uf: 'PE',
        nome_proje: 'PA BRABINHO',
        municipio: 'ITAIBA',
        area_hecta: '494.6963',
        capacidade: 24.0,
        num_famili: 20.0,
        fase: 4.0,
        data_de_cr: '06/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '16/09/2004',
        area_calc_: 494.6454,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.254045640306003, -8.918415120543711],
              [-37.253729300252139, -8.919932545470466],
              [-37.248051623509696, -8.918920332301857],
              [-37.246519713221062, -8.925567359212486],
              [-37.246156338778796, -8.92550235514252],
              [-37.244131602052946, -8.934416341857863],
              [-37.263116133051078, -8.938330143485087],
              [-37.263383664747828, -8.937517690820941],
              [-37.264524139595089, -8.936691241753],
              [-37.273712260437271, -8.929898961925755],
              [-37.2671638978874, -8.920664501961712],
              [-37.254045640306003, -8.918415120543711],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0190000',
        uf: 'PE',
        nome_proje: 'PA SANTA HELENA',
        municipio: 'IBIMIRIM',
        area_hecta: '159.3797',
        capacidade: 21.0,
        num_famili: 18.0,
        fase: 3.0,
        data_de_cr: '04/10/2006',
        forma_obte: 'Doação',
        data_obten: '04/10/2006',
        area_calc_: 159.3998,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.695992837413876, -8.454460127747428],
              [-37.695909578900114, -8.454441406526326],
              [-37.695517821980459, -8.454259690493071],
              [-37.695067198008225, -8.454135653860858],
              [-37.694978466572621, -8.453837025243173],
              [-37.694651460144698, -8.453739082867125],
              [-37.694108146939612, -8.453737270445723],
              [-37.693840078836992, -8.453884502281909],
              [-37.692956899715959, -8.453881553848333],
              [-37.692740002353197, -8.454018013562743],
              [-37.692583883682644, -8.45423045750881],
              [-37.692600691332586, -8.454687191134393],
              [-37.692789984436779, -8.454845354606231],
              [-37.692788098816202, -8.455404936494242],
              [-37.692406843447642, -8.455530085475241],
              [-37.69168986525743, -8.455690917185652],
              [-37.691587583243511, -8.455775218742838],
              [-37.691365533233579, -8.456092432060217],
              [-37.691921521338138, -8.456699637283165],
              [-37.691955722067789, -8.457573497391502],
              [-37.691817178277212, -8.45774494374265],
              [-37.692662076525792, -8.458355827320233],
              [-37.692998624388963, -8.458749694541096],
              [-37.692660896349651, -8.459217722826725],
              [-37.692131592998841, -8.459314794587575],
              [-37.691920586920595, -8.459453895518307],
              [-37.69142131864767, -8.459855367406115],
              [-37.691036244207133, -8.460007269135478],
              [-37.690613449116199, -8.460005853887324],
              [-37.690188707772222, -8.459962200351885],
              [-37.690085872789702, -8.459833353392902],
              [-37.689765200686963, -8.459849009017526],
              [-37.689650010084058, -8.459963018398202],
              [-37.689275874470454, -8.459995585944489],
              [-37.689040147537021, -8.460112175361255],
              [-37.688576554664124, -8.460142543288109],
              [-37.688039485257988, -8.46011849601118],
              [-37.687822682534986, -8.460225471992189],
              [-37.687657626022059, -8.46033985595578],
              [-37.687657504802424, -8.460375666219448],
              [-37.687916256901097, -8.460540847520853],
              [-37.688105562347481, -8.460883311776284],
              [-37.688218588889974, -8.46116746341111],
              [-37.688411196817313, -8.461446727397945],
              [-37.688607019706765, -8.461500648023705],
              [-37.689020824283396, -8.461502035543623],
              [-37.68933262927289, -8.461745345514259],
              [-37.68962458880128, -8.461867772906544],
              [-37.690031008084269, -8.461931441667739],
              [-37.690352337928076, -8.462124864924112],
              [-37.69079945636954, -8.462293750182223],
              [-37.691029443619762, -8.462370934456953],
              [-37.691263084223792, -8.462388355879531],
              [-37.691536040794659, -8.46208659647832],
              [-37.691798603711639, -8.461715622236564],
              [-37.692482683058984, -8.461590854481205],
              [-37.692538546644982, -8.461535606974726],
              [-37.692606667699053, -8.461400821024315],
              [-37.692578286399247, -8.460659552396093],
              [-37.693051142868178, -8.46006392546041],
              [-37.693894463423071, -8.460066742666744],
              [-37.694124088496125, -8.460574918963694],
              [-37.694201151575143, -8.460886169217517],
              [-37.694649969200448, -8.460928361642175],
              [-37.695356870926894, -8.460930720592584],
              [-37.695558733821549, -8.461268431275123],
              [-37.695583742159826, -8.461718138901425],
              [-37.695999497471277, -8.461950034880452],
              [-37.696527389056392, -8.462030741487046],
              [-37.697173802728116, -8.462032895805089],
              [-37.69809648173112, -8.461936675375776],
              [-37.698472694151754, -8.462314212655132],
              [-37.698243832658505, -8.462766602243901],
              [-37.697887212381957, -8.462883608483978],
              [-37.697666405893166, -8.462992837535813],
              [-37.697398563634216, -8.463125150583286],
              [-37.697310755352518, -8.463199554280068],
              [-37.697436544310143, -8.46355319809251],
              [-37.697374467001126, -8.463916886842696],
              [-37.696986042128231, -8.464172959839219],
              [-37.69663094136255, -8.464053763236103],
              [-37.696443016450679, -8.463892168968693],
              [-37.695719566053256, -8.463839476195],
              [-37.695508653609089, -8.463680427361433],
              [-37.695502659024179, -8.463680407358758],
              [-37.695417420834303, -8.463665744359135],
              [-37.694801775992396, -8.464019988460393],
              [-37.694455694516918, -8.464405245375856],
              [-37.694606881223059, -8.464983967419924],
              [-37.694697804727348, -8.465090527728702],
              [-37.695101353521018, -8.465252842709701],
              [-37.695407822564469, -8.465437531550158],
              [-37.695735069393621, -8.46560185206576],
              [-37.696222755730297, -8.465755494091118],
              [-37.696617481070582, -8.466003145385248],
              [-37.696616157349446, -8.466396607432159],
              [-37.696275394287056, -8.466685664748493],
              [-37.695901622432842, -8.46682395308604],
              [-37.695584806529361, -8.466717543361375],
              [-37.694999694176673, -8.466715589962016],
              [-37.694761574453864, -8.466813545623257],
              [-37.694615508331921, -8.467006218790832],
              [-37.694282964868869, -8.467335272100119],
              [-37.693674781618327, -8.467333239489964],
              [-37.693392335012, -8.467485576009507],
              [-37.693033928732724, -8.467618848563193],
              [-37.692764368176114, -8.467801431655328],
              [-37.692752744957495, -8.467935140412402],
              [-37.692788621756272, -8.467989157389631],
              [-37.693379552483648, -8.468393823755205],
              [-37.693614083119215, -8.468498603796155],
              [-37.694139426002671, -8.46869098883421],
              [-37.694061428482819, -8.469222372995107],
              [-37.693801234828527, -8.469428228813735],
              [-37.693388880911797, -8.469613318970058],
              [-37.692411023147798, -8.469636905355483],
              [-37.692204818497352, -8.469724114166807],
              [-37.691780645697342, -8.469750908492342],
              [-37.691207770245825, -8.470131513124846],
              [-37.69084648321514, -8.470309989052691],
              [-37.690455710291914, -8.47034412824207],
              [-37.689880130646522, -8.47034219831597],
              [-37.68977413896004, -8.470420879539692],
              [-37.689678364349433, -8.470647540134335],
              [-37.689693567122262, -8.470852146077517],
              [-37.690300893062954, -8.471350649193031],
              [-37.690572895399853, -8.4718974028837],
              [-37.690839575853666, -8.472164797149341],
              [-37.691208216212601, -8.472364167101498],
              [-37.691528485892043, -8.47260506342465],
              [-37.691990621339343, -8.472687999462917],
              [-37.692193388376182, -8.47275849132784],
              [-37.692826367443068, -8.472978368619959],
              [-37.693143920623676, -8.473217265257439],
              [-37.693784364375617, -8.473217328057107],
              [-37.693808881246177, -8.473219489977945],
              [-37.694058250056841, -8.473880742676775],
              [-37.694276460493391, -8.474139382056997],
              [-37.694983022901987, -8.474141744124147],
              [-37.695462131072574, -8.474364087624592],
              [-37.695915505714673, -8.474783755999702],
              [-37.696119762704932, -8.475194453294391],
              [-37.696296655654528, -8.475639332593421],
              [-37.696440286290319, -8.475927926075919],
              [-37.696651840299097, -8.476087158360848],
              [-37.696842508225984, -8.476056143946654],
              [-37.697001234555479, -8.475961992249545],
              [-37.697109671284359, -8.475561924600724],
              [-37.697440171507765, -8.475194067877778],
              [-37.697635855779524, -8.475129791029296],
              [-37.697739705447773, -8.475039887020097],
              [-37.697740991363098, -8.474657728752886],
              [-37.698035682123702, -8.474269043111331],
              [-37.698582610538395, -8.474149959733523],
              [-37.69807128286223, -8.473487383331996],
              [-37.697891270530988, -8.472943291113747],
              [-37.697627563196122, -8.472546322768846],
              [-37.69793447766979, -8.472141490502954],
              [-37.698188375372169, -8.471890214402844],
              [-37.698523914162585, -8.471724396428341],
              [-37.698964801095343, -8.471725051395655],
              [-37.698972390047977, -8.471710155505308],
              [-37.699143272682768, -8.471998205886019],
              [-37.699331293723745, -8.472267774877317],
              [-37.699535880572569, -8.472473011858092],
              [-37.699611184710236, -8.472498312084429],
              [-37.69969175026565, -8.472498580331438],
              [-37.699811043945218, -8.472380421989792],
              [-37.700345937398453, -8.472382202335204],
              [-37.700445744257024, -8.47254919947842],
              [-37.700900307632764, -8.472561834850421],
              [-37.701076428197702, -8.472426321400341],
              [-37.701420285754565, -8.472054977895148],
              [-37.701811395411809, -8.471839875679485],
              [-37.701830207666085, -8.471836773106665],
              [-37.702258083137409, -8.471519243313741],
              [-37.70261781452848, -8.471560318627475],
              [-37.703235543650138, -8.471562369984891],
              [-37.703244140351309, -8.471544854819564],
              [-37.703253217937636, -8.471193920392547],
              [-37.703570928141581, -8.471089441458467],
              [-37.704046781702289, -8.471091020514617],
              [-37.704099923359571, -8.471360592282277],
              [-37.704442670355498, -8.471428648595051],
              [-37.704999976484686, -8.471708573685509],
              [-37.705161473127326, -8.472251607913512],
              [-37.704783736581284, -8.472732174571222],
              [-37.703804231422644, -8.47272892482124],
              [-37.703762184333065, -8.472753834787126],
              [-37.703230871381528, -8.473363930257122],
              [-37.702833226004941, -8.473685902036125],
              [-37.702332986756211, -8.473777565236544],
              [-37.701909470970939, -8.474013087913596],
              [-37.701665273605641, -8.474188798155538],
              [-37.701630870271508, -8.474290690391117],
              [-37.701810006487577, -8.474447822785821],
              [-37.701843243683363, -8.474720674437426],
              [-37.701998273536098, -8.474942837255302],
              [-37.70232760876933, -8.475273192815132],
              [-37.702325627087326, -8.475864155604992],
              [-37.701760209478067, -8.475862275758134],
              [-37.701213482646942, -8.475758269805828],
              [-37.700866554152782, -8.475555091798189],
              [-37.700821246972808, -8.475549876874259],
              [-37.700423285846085, -8.47596462706073],
              [-37.70033517404957, -8.476101518103809],
              [-37.700829087102122, -8.476623504227966],
              [-37.700924297933462, -8.477726812529955],
              [-37.700846992310822, -8.478216060209771],
              [-37.700562276323566, -8.478582354109953],
              [-37.700561345881461, -8.478859342244718],
              [-37.700773560166034, -8.479093452121729],
              [-37.701050832413813, -8.479320996121071],
              [-37.701593113309158, -8.479533234594063],
              [-37.702012543930365, -8.479921585873742],
              [-37.70205593308107, -8.479875971910062],
              [-37.70238993779887, -8.479600724341266],
              [-37.703976271136291, -8.478530856323854],
              [-37.703979542165989, -8.478532756348674],
              [-37.704030424242191, -8.478498571251071],
              [-37.703795222448683, -8.478347312207847],
              [-37.702946920532668, -8.475942996576803],
              [-37.705427410437871, -8.474319935451769],
              [-37.705904202118184, -8.471243587279124],
              [-37.705217917992947, -8.469931140702453],
              [-37.703897906687175, -8.468894216574915],
              [-37.703854310784202, -8.468459277251421],
              [-37.703041207008035, -8.468296063254707],
              [-37.701842637270602, -8.469754902003205],
              [-37.700017946953444, -8.470083430570456],
              [-37.695682295910153, -8.470160947363174],
              [-37.698260802672003, -8.467313003590084],
              [-37.697852287099636, -8.465195278521037],
              [-37.699229014303398, -8.464480119229192],
              [-37.701743171710291, -8.462465796717005],
              [-37.700430367810533, -8.460508116764441],
              [-37.698603846559912, -8.46015071714209],
              [-37.696805733713397, -8.459746741578126],
              [-37.695382072988309, -8.458947556792737],
              [-37.693860346797024, -8.456604921999164],
              [-37.69543887801499, -8.456425166032879],
              [-37.695770351848807, -8.456545821247417],
              [-37.695776094695219, -8.45649584635555],
              [-37.695773974104839, -8.456495010939815],
              [-37.695992837413876, -8.454460127747428],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0348000',
        uf: 'PE',
        nome_proje: 'PA AVELOZINHO',
        municipio: 'JATAUBA',
        area_hecta: '127.1907',
        capacidade: 7.0,
        num_famili: 7.0,
        fase: 3.0,
        data_de_cr: '16/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/12/2004',
        area_calc_: 127.1701,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.433530532977073, -7.944180393414075],
              [-36.429003995923495, -7.945118351716105],
              [-36.428730793314188, -7.94763665186361],
              [-36.428794601767372, -7.950344098385997],
              [-36.424184147179538, -7.94977232613906],
              [-36.424073493414284, -7.94979757515287],
              [-36.421526878967562, -7.946161122169896],
              [-36.415485928945792, -7.947159819410966],
              [-36.415555826808188, -7.947230378851101],
              [-36.414984160715541, -7.948920111846052],
              [-36.417763779812098, -7.949338000319336],
              [-36.420341549586205, -7.949537556464267],
              [-36.420170131500242, -7.952540290350758],
              [-36.420836846947815, -7.956161373434621],
              [-36.420735951905797, -7.958002697013253],
              [-36.420510249379646, -7.958421680882045],
              [-36.421249525529312, -7.959630418079709],
              [-36.422681151123328, -7.95952283349648],
              [-36.423348667697816, -7.959393598919331],
              [-36.42523476476827, -7.959291448719077],
              [-36.424567350328317, -7.956966492942983],
              [-36.424165572968377, -7.956246030961815],
              [-36.424224605618939, -7.95299109772399],
              [-36.429775253859809, -7.953028214904932],
              [-36.429311194458343, -7.951521985604557],
              [-36.432854602003658, -7.95190520154535],
              [-36.43295952449246, -7.950703144384179],
              [-36.433221953234508, -7.947611473781076],
              [-36.433530532977073, -7.944180393414075],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0195000',
        uf: 'PE',
        nome_proje: 'PA NOVO HORIZONTE',
        municipio: 'IBIMIRIM',
        area_hecta: '333.6942',
        capacidade: 36.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '31/10/2006',
        forma_obte: 'Dação',
        data_obten: '31/10/2006',
        area_calc_: 333.4664,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.665824495150147, -8.490729279647791],
              [-37.665427918579972, -8.490518395355881],
              [-37.665199557358704, -8.49041506562587],
              [-37.665168133236527, -8.490422817574405],
              [-37.660689293880822, -8.49291273009435],
              [-37.666742459088248, -8.495885309440171],
              [-37.670833975274263, -8.497506600195308],
              [-37.67392361004751, -8.499763422785858],
              [-37.673923474356535, -8.499763414323757],
              [-37.673923609736732, -8.499763513215116],
              [-37.662315606505857, -8.499039338637036],
              [-37.656247222440328, -8.500437375375851],
              [-37.642528859851232, -8.507636144325698],
              [-37.644001370678282, -8.510875029519893],
              [-37.650079382881124, -8.510411838132333],
              [-37.650515542588586, -8.509204491041185],
              [-37.654898235517273, -8.506686016843979],
              [-37.657563275735214, -8.508204856277327],
              [-37.662111286559387, -8.506145703527059],
              [-37.66744659023081, -8.506305085478182],
              [-37.663748541065047, -8.509497057281253],
              [-37.673252417996871, -8.508821889311093],
              [-37.67544664242471, -8.506961438968197],
              [-37.679105301803517, -8.509455291549271],
              [-37.679450607753985, -8.50485879705826],
              [-37.680719357008591, -8.504320155881469],
              [-37.685361436311076, -8.504392295249529],
              [-37.686388141639355, -8.505280717848928],
              [-37.692687888091179, -8.506627834485577],
              [-37.692038387700975, -8.504112937205432],
              [-37.693883681248167, -8.504321338922836],
              [-37.697169848374067, -8.507674327404565],
              [-37.700893283208892, -8.506903465265587],
              [-37.700527007315912, -8.506773286993656],
              [-37.699655394880466, -8.506697939122388],
              [-37.698856697027772, -8.506550488286848],
              [-37.698457837002358, -8.506332028854173],
              [-37.698169326012177, -8.505715860416155],
              [-37.697626441300429, -8.505135012982123],
              [-37.697047132994541, -8.504553952621796],
              [-37.696684733068395, -8.504299441442051],
              [-37.696103682681972, -8.50426132246062],
              [-37.695777405208219, -8.504043104027449],
              [-37.695527802650119, -8.503956900467722],
              [-37.695162102529764, -8.503389576004302],
              [-37.694946145246099, -8.502809821846984],
              [-37.694620450462466, -8.502446824560241],
              [-37.694112839342992, -8.502155561607527],
              [-37.693604861260972, -8.501972904512746],
              [-37.693132695938381, -8.501971319484499],
              [-37.69269677424505, -8.501969855615281],
              [-37.692441697683272, -8.502186123572439],
              [-37.69200562109495, -8.502257093566193],
              [-37.691460818826656, -8.502219089977284],
              [-37.691207919892811, -8.501820072296814],
              [-37.691063933195608, -8.501421511201196],
              [-37.69062911799471, -8.501094316691624],
              [-37.690083671414968, -8.501273433837095],
              [-37.689865542656328, -8.501308962532889],
              [-37.689610556119796, -8.501525228772833],
              [-37.689573238556584, -8.501814572362983],
              [-37.689644800751047, -8.50214054528179],
              [-37.689643817148401, -8.50243010161908],
              [-37.689642710630721, -8.502755829885743],
              [-37.689678061999899, -8.503045327687637],
              [-37.689859396983486, -8.503118463731575],
              [-37.689967540308089, -8.503372034227892],
              [-37.690039348648071, -8.503625663267297],
              [-37.68978485198955, -8.503697241642755],
              [-37.689566845032751, -8.503696507594],
              [-37.689312594444033, -8.503695651355477],
              [-37.689057760937295, -8.503839572768516],
              [-37.688766929854943, -8.503910937594528],
              [-37.688512925212521, -8.503837736981449],
              [-37.688258920997349, -8.503764445787089],
              [-37.688041160226369, -8.503691367060505],
              [-37.687786909796991, -8.503690509825894],
              [-37.687423902525637, -8.503616850556302],
              [-37.686843011658297, -8.503506373945598],
              [-37.686538442979227, -8.503317250144802],
              [-37.686019096831956, -8.502926283091631],
              [-37.685620373091353, -8.502671549818084],
              [-37.685293948369143, -8.502525667726612],
              [-37.684822030604572, -8.502451728787266],
              [-37.68409531206197, -8.50248544473552],
              [-37.683005679400893, -8.502445586943459],
              [-37.682206721511541, -8.502406710404047],
              [-37.681552795720648, -8.502404495799274],
              [-37.680753623977118, -8.502401787808617],
              [-37.6800996656441, -8.50243574286743],
              [-37.679736166559479, -8.502506854413001],
              [-37.679408753642143, -8.502650522882661],
              [-37.679118046678973, -8.502685708584105],
              [-37.67882696770117, -8.502829499817368],
              [-37.678499215576622, -8.503045510658938],
              [-37.678208384064241, -8.503116957817506],
              [-37.677953390812249, -8.503333215220941],
              [-37.677662062978278, -8.50354934895195],
              [-37.677661721855706, -8.503648843891909],
              [-37.677660698463157, -8.503947328708669],
              [-37.677660698153119, -8.503947419138047],
              [-37.677659457599155, -8.504309227130516],
              [-37.677659457289096, -8.504309317559878],
              [-37.677659178149156, -8.504390726617945],
              [-37.677658340712753, -8.504634953790081],
              [-37.677658340402658, -8.504635044219491],
              [-37.677511637631589, -8.50503262042489],
              [-37.677511116423567, -8.505184596042183],
              [-37.677510768946199, -8.50528591311968],
              [-37.677510768636054, -8.505286003549049],
              [-37.677473441373145, -8.505575344485997],
              [-37.677341775797906, -8.505667135943371],
              [-37.67717376715985, -8.505681847492969],
              [-37.677082469293261, -8.505683707399873],
              [-37.676795084683313, -8.505253004820027],
              [-37.676533296178064, -8.504934161152203],
              [-37.676327596275605, -8.504709013200539],
              [-37.676027806046932, -8.5045584214038],
              [-37.675917303427447, -8.504545023427287],
              [-37.675539525518737, -8.504594107461768],
              [-37.675388923972427, -8.504724537983439],
              [-37.675126328513976, -8.504667486714661],
              [-37.674788418890685, -8.504666336030786],
              [-37.674375002257115, -8.504777151858912],
              [-37.674017865834806, -8.504925597156658],
              [-37.67375436990848, -8.505130428061562],
              [-37.673696893932203, -8.505466813722961],
              [-37.673658580158417, -8.505672412041852],
              [-37.673545235663589, -8.505877754546157],
              [-37.673526201742057, -8.505971194572544],
              [-37.673244609617335, -8.50597023438595],
              [-37.672962759825538, -8.506044149381077],
              [-37.672943377518848, -8.506212374000453],
              [-37.672810740900623, -8.50658594111572],
              [-37.672584887180115, -8.506753551669842],
              [-37.672415287665764, -8.506939982676483],
              [-37.672020671329697, -8.507050859680829],
              [-37.671832848377129, -8.507068847076201],
              [-37.671796234865219, -8.506806926562071],
              [-37.671458323825114, -8.50680577269892],
              [-37.671064094338433, -8.506804426154515],
              [-37.670556905521074, -8.506896198003458],
              [-37.67021847836034, -8.507044703218929],
              [-37.669918109285611, -8.507062304930901],
              [-37.669730221484393, -8.50709910054452],
              [-37.669279544254906, -8.5071349971846],
              [-37.669280125449191, -8.506966618258271],
              [-37.669655680893413, -8.506930464692362],
              [-37.669993914361982, -8.506838116452599],
              [-37.67031414465329, -8.506540068140506],
              [-37.670615545391691, -8.506223145665285],
              [-37.670860073461448, -8.506093038233553],
              [-37.671143083429975, -8.505682547558894],
              [-37.671388293507825, -8.505327994241119],
              [-37.671539438371425, -8.505066624401058],
              [-37.67187783635692, -8.504899579385494],
              [-37.672141424053926, -8.504694659759037],
              [-37.672480105965889, -8.504471367474745],
              [-37.672538033397167, -8.504004040630827],
              [-37.672539256300041, -8.503648744288247],
              [-37.672502229600539, -8.503480327259652],
              [-37.671995882277116, -8.503329028072297],
              [-37.671301483146642, -8.503289219776926],
              [-37.670851583739122, -8.503100673814693],
              [-37.67010073844304, -8.503098108769263],
              [-37.669687387547818, -8.50319020085146],
              [-37.669386376077796, -8.503394900402769],
              [-37.669085725319022, -8.503468658054082],
              [-37.668916771442134, -8.503468080207218],
              [-37.668617088892468, -8.50326132652431],
              [-37.668110318502258, -8.503259592506488],
              [-37.66777279869757, -8.503146213493247],
              [-37.667304228263717, -8.50292016174159],
              [-37.666909146520368, -8.502930202854149],
              [-37.666494733490737, -8.50293538453332],
              [-37.666392954297685, -8.502947695993221],
              [-37.666214478906781, -8.503073867304828],
              [-37.666023415188732, -8.503136513480307],
              [-37.665921109851062, -8.503300926321353],
              [-37.665806131271694, -8.503452635468653],
              [-37.665792888166081, -8.503604693375028],
              [-37.665792274712459, -8.503782024557173],
              [-37.665816612459196, -8.504098974816772],
              [-37.665841345010186, -8.504301803735602],
              [-37.665586783067305, -8.504339001900382],
              [-37.665294201952754, -8.504337998452757],
              [-37.665180407151716, -8.504147524249582],
              [-37.664722505763912, -8.504145953271829],
              [-37.664251665125938, -8.504182317928031],
              [-37.663997809045242, -8.504042003447548],
              [-37.663781661636875, -8.504003280722017],
              [-37.662840335092888, -8.504000047282661],
              [-37.662166246323288, -8.503997730401224],
              [-37.661975313544801, -8.504022394311832],
              [-37.661771314724824, -8.504173795888827],
              [-37.661463432875955, -8.504210717505604],
              [-37.661259609891232, -8.504311388217934],
              [-37.660839859875857, -8.50430994389343],
              [-37.660419977694019, -8.504346569621664],
              [-37.660177824806723, -8.504472428109276],
              [-37.659808237999755, -8.50467389900342],
              [-37.659476404995637, -8.504989621962245],
              [-37.659043100180178, -8.505228943398265],
              [-37.658711045912199, -8.505607965603277],
              [-37.658506866214168, -8.505784684843643],
              [-37.658150744018215, -8.50577079659487],
              [-37.657972706335087, -8.505770182512297],
              [-37.65775700044162, -8.505604675420708],
              [-37.657082863531755, -8.50558968897894],
              [-37.656586811063143, -8.505587976479321],
              [-37.656370667121379, -8.505574479511884],
              [-37.656104668235464, -8.505244125497248],
              [-37.655825555370576, -8.505027757873423],
              [-37.655431813873754, -8.504861634612716],
              [-37.655190896944944, -8.504632648032262],
              [-37.654975151102519, -8.504505210406547],
              [-37.654822457238581, -8.504504682573547],
              [-37.654516055155156, -8.504820398190517],
              [-37.654197159017684, -8.505098089833684],
              [-37.653891419191261, -8.505223814504792],
              [-37.653446235585896, -8.505222274057729],
              [-37.652975527363608, -8.505220644735147],
              [-37.652809986095633, -8.505283372282484],
              [-37.652542440880531, -8.505396567911509],
              [-37.652300019670115, -8.505598471308613],
              [-37.652044927843285, -8.505812990748934],
              [-37.651770516201694, -8.506097254613628],
              [-37.651503238454531, -8.506159719362767],
              [-37.651235604090168, -8.506298143457482],
              [-37.650942890009524, -8.506335199342056],
              [-37.650649821449356, -8.506473534782609],
              [-37.650420117520788, -8.506700891533596],
              [-37.650342972697253, -8.506941437560149],
              [-37.650011885556985, -8.507041660133998],
              [-37.649743673085204, -8.507344843455876],
              [-37.649628554392848, -8.507534526515869],
              [-37.649601655324574, -8.507952669265508],
              [-37.649626645842737, -8.508079357233662],
              [-37.649804461625948, -8.508143455996011],
              [-37.650032526978762, -8.50841029105543],
              [-37.650032493265535, -8.508410342396761],
              [-37.650032526662073, -8.508410381483797],
              [-37.649924581583683, -8.50857476925014],
              [-37.649738594792638, -8.508613098694019],
              [-37.649523848401927, -8.508485661471976],
              [-37.649162026450014, -8.50846496266128],
              [-37.648663246830502, -8.50846323001883],
              [-37.648105787380373, -8.508461292779693],
              [-37.647695096395658, -8.508440332325362],
              [-37.64753844957977, -8.508478762703563],
              [-37.647449916603982, -8.50862467910175],
              [-37.647312924514182, -8.508653411354754],
              [-37.647146159457442, -8.50877952282565],
              [-37.646970119583173, -8.508778910443072],
              [-37.646902074098143, -8.508651982219288],
              [-37.64673622078071, -8.508544246425634],
              [-37.646570436145097, -8.508416978080154],
              [-37.646306080660864, -8.508474475430303],
              [-37.646012030668096, -8.508658651096153],
              [-37.645728338754893, -8.508686872036654],
              [-37.645580885893395, -8.508900675977447],
              [-37.645375266162326, -8.508968233955311],
              [-37.645101499144367, -8.508937981228865],
              [-37.644945742489149, -8.508723121228448],
              [-37.644858407764438, -8.508527941883237],
              [-37.644956683507154, -8.508401502456458],
              [-37.645279833313964, -8.508285793523237],
              [-37.645603188851609, -8.50811148682817],
              [-37.645985086712926, -8.50797644907205],
              [-37.646259401347017, -8.507850621994583],
              [-37.646416252746953, -8.507753865912072],
              [-37.646729352772255, -8.507706123504896],
              [-37.646964003374038, -8.5077264725393],
              [-37.647110258561362, -8.507853582340184],
              [-37.647374168713569, -8.507922864762957],
              [-37.64788273529701, -8.507905100229575],
              [-37.648293585084218, -8.507906528204938],
              [-37.648516599536229, -8.507825916691218],
              [-37.648744803857568, -8.50750857888498],
              [-37.648864349007567, -8.506885392933402],
              [-37.64911967818027, -8.506603778163356],
              [-37.649355284533641, -8.506351212968882],
              [-37.649502461298731, -8.506215356316837],
              [-37.649698405384221, -8.506108787138208],
              [-37.650132698922803, -8.506081537677867],
              [-37.650343134677364, -8.505986955106927],
              [-37.650611977011579, -8.505607179550788],
              [-37.650861143531621, -8.505398789808604],
              [-37.651110086730142, -8.505228198619895],
              [-37.651473929299158, -8.504982044684438],
              [-37.651933220983508, -8.504793372495076],
              [-37.652392288732599, -8.504642679327276],
              [-37.653029774458979, -8.504383364293259],
              [-37.653291946108602, -8.504169953621094],
              [-37.653579195415382, -8.503980502907282],
              [-37.653789562472056, -8.50390508899498],
              [-37.654019295088645, -8.503772590425102],
              [-37.654306343758172, -8.503640470756224],
              [-37.654664563264859, -8.503573163648095],
              [-37.654842158110526, -8.503413897956602],
              [-37.655110264050087, -8.503243550820473],
              [-37.655282944586908, -8.503034712439227],
              [-37.655589200694969, -8.502864496713487],
              [-37.655838073657996, -8.502713072757961],
              [-37.656067829656976, -8.50259956307012],
              [-37.656431202773597, -8.502486695363382],
              [-37.656488586253559, -8.502467722353911],
              [-37.656564978250948, -8.502467986045165],
              [-37.656584939902949, -8.50223962950651],
              [-37.656623222729806, -8.502201781163002],
              [-37.656833613136598, -8.502145355680305],
              [-37.657024572306398, -8.502165004856868],
              [-37.657195793282526, -8.502375030856257],
              [-37.657366815509739, -8.502642207712643],
              [-37.657672234375489, -8.502738393295694],
              [-37.657882426167532, -8.502739118186259],
              [-37.658074445318618, -8.502454203126211],
              [-37.658380104346499, -8.502455256867952],
              [-37.658781122903655, -8.50251379061608],
              [-37.659067268203657, -8.502667060353378],
              [-37.659487031905208, -8.502820790164622],
              [-37.65992622671434, -8.502898444568183],
              [-37.660365686223152, -8.502899957488831],
              [-37.660538495984319, -8.502653136224028],
              [-37.660711372073088, -8.50238714394521],
              [-37.660921803343363, -8.502292735774477],
              [-37.661399503817066, -8.502294378987253],
              [-37.661686153698021, -8.502276374458594],
              [-37.66195405887165, -8.502162992161541],
              [-37.662260377958283, -8.501973780558025],
              [-37.662356860569886, -8.501707524757682],
              [-37.662549007798098, -8.501384626767409],
              [-37.662548419766225, -8.501004005195849],
              [-37.662548420213319, -8.501004001302285],
              [-37.662548420079716, -8.501003914767013],
              [-37.662568089074625, -8.500832708064461],
              [-37.662854870196156, -8.500776541517981],
              [-37.663103038878013, -8.50085353588514],
              [-37.663217078681882, -8.501025201836862],
              [-37.66329208510102, -8.501425340646929],
              [-37.663463310283113, -8.501635183516399],
              [-37.663769060173259, -8.501636233238367],
              [-37.664189813710237, -8.501504564474557],
              [-37.664572680617333, -8.501296442319084],
              [-37.664916976531714, -8.50118331980549],
              [-37.665547460462172, -8.50118548169716],
              [-37.666292578048768, -8.501188035344486],
              [-37.666846735894673, -8.501170943298783],
              [-37.667286062856242, -8.501210609150512],
              [-37.667706027090198, -8.501307179216045],
              [-37.667896486674564, -8.501498095999267],
              [-37.668527037429179, -8.501481263041171],
              [-37.668985572210993, -8.501482831230042],
              [-37.669386199468242, -8.501655475664805],
              [-37.669787023582906, -8.501771149400154],
              [-37.670070250058714, -8.501982096108328],
              [-37.670322107526097, -8.501753987321072],
              [-37.670666337536709, -8.50165984964641],
              [-37.671315964498355, -8.501643077335936],
              [-37.671831816937036, -8.501644837848371],
              [-37.672271277157925, -8.501646337108602],
              [-37.672596038189546, -8.501666435101141],
              [-37.672767465165194, -8.501819304260833],
              [-37.673092291750876, -8.501820411726346],
              [-37.67339808276369, -8.501783473316106],
              [-37.67376171490789, -8.501594266222872],
              [-37.674420677004939, -8.501663067598926],
              [-37.675184964441179, -8.501665669639483],
              [-37.675796024198753, -8.50174389132027],
              [-37.676483527586122, -8.501860533648816],
              [-37.677133091016309, -8.501862741809711],
              [-37.677439143829403, -8.501749477761699],
              [-37.677440057147436, -8.501483072765909],
              [-37.67702080686783, -8.501177078357845],
              [-37.676563576589302, -8.500794902490584],
              [-37.676221373131725, -8.50029881332968],
              [-37.676337443773441, -8.499880334308145],
              [-37.676530007008175, -8.499424204144974],
              [-37.676263180765837, -8.499232961761473],
              [-37.675881260728346, -8.499193591909307],
              [-37.675576425898839, -8.49892605673779],
              [-37.675576948534669, -8.498773773731189],
              [-37.675233179622559, -8.498734533086024],
              [-37.674622033954513, -8.498656310948816],
              [-37.6741265281658, -8.498311983439899],
              [-37.673593214598384, -8.497815241973784],
              [-37.673098666785592, -8.497166437837077],
              [-37.672565577870124, -8.496631624980992],
              [-37.671918029302724, -8.496020279043483],
              [-37.671384288504882, -8.495675818500688],
              [-37.670774066860062, -8.495331096386373],
              [-37.670163977538046, -8.494948302802245],
              [-37.669667956150128, -8.494756253572035],
              [-37.669019103257206, -8.494525610537552],
              [-37.668331576114682, -8.494447118725565],
              [-37.667720702908071, -8.494292745720221],
              [-37.66738511722771, -8.494147542731046],
              [-37.667332457607259, -8.493877338243088],
              [-37.66691318631316, -8.493503422190839],
              [-37.666586970756136, -8.493222876855029],
              [-37.666119796308067, -8.493174706248189],
              [-37.665746330282715, -8.493057043994339],
              [-37.665513502352368, -8.492800148673751],
              [-37.665163149851175, -8.492775708057271],
              [-37.664997784981701, -8.492579179595822],
              [-37.665141083095065, -8.492379820113532],
              [-37.665562131648038, -8.492264788353934],
              [-37.665795753244723, -8.492265588345006],
              [-37.666123414740305, -8.492127086061666],
              [-37.666614173271824, -8.492105434704007],
              [-37.667081346288697, -8.492153694959265],
              [-37.667455213645205, -8.492154973652612],
              [-37.667875217992268, -8.492342514968016],
              [-37.668388919473081, -8.49243741389262],
              [-37.66901976377342, -8.492462900226819],
              [-37.669533877638507, -8.492464655999486],
              [-37.670018703597776, -8.492529611829388],
              [-37.670002575439604, -8.492070533404654],
              [-37.670004098492591, -8.491628244352762],
              [-37.669560669756038, -8.491463775453232],
              [-37.669094057966539, -8.491252655378416],
              [-37.668838365931428, -8.490855969122704],
              [-37.668418614142197, -8.490621767896975],
              [-37.668068092038766, -8.490620570135889],
              [-37.66762370207227, -8.490735434771159],
              [-37.667342740366479, -8.490897429254234],
              [-37.666968472602228, -8.491012532838958],
              [-37.666314447873305, -8.490940483157846],
              [-37.665924911068807, -8.491031840749491],
              [-37.665824495150147, -8.490729279647791],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0194000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA APARECIDA',
        municipio: 'IBIMIRIM',
        area_hecta: '308.2662',
        capacidade: 41.0,
        num_famili: 31.0,
        fase: 3.0,
        data_de_cr: '31/10/2006',
        forma_obte: 'Doação',
        data_obten: '31/10/2006',
        area_calc_: 433.0596,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.688035955647869, -8.417952093442631],
              [-37.688821643113336, -8.417700694248296],
              [-37.688892787006786, -8.417625446029131],
              [-37.688845786508253, -8.417650856764924],
              [-37.688869023414675, -8.417573525165551],
              [-37.692348509148673, -8.413970305362289],
              [-37.689738696491503, -8.413028485024041],
              [-37.682744007971998, -8.412062341742267],
              [-37.682466024008697, -8.412023974640906],
              [-37.681878436682993, -8.412886887692755],
              [-37.681253453340702, -8.413877272107674],
              [-37.681181923863342, -8.414672734219346],
              [-37.681720669623566, -8.415068277540923],
              [-37.68335034754886, -8.41609569999506],
              [-37.684589374893505, -8.415848359372566],
              [-37.685120951735854, -8.415759344995678],
              [-37.685388688873509, -8.41505623431412],
              [-37.685903946616584, -8.415362709843679],
              [-37.685842550467726, -8.416333644375934],
              [-37.686771261758622, -8.417136249968509],
              [-37.687151629964404, -8.417694305303474],
              [-37.688035955647869, -8.417952093442631],
            ],
          ],
          [
            [
              [-37.680481073911253, -8.46107478404797],
              [-37.680783547428369, -8.461015577672063],
              [-37.681026555405154, -8.460655036081784],
              [-37.681207934547345, -8.46065564777248],
              [-37.681479957873151, -8.460656564997848],
              [-37.681963605431086, -8.460658195323944],
              [-37.682296425922409, -8.460568976513091],
              [-37.682298370880787, -8.459996736968455],
              [-37.682300929731767, -8.459243818729805],
              [-37.682303078726058, -8.458611443347248],
              [-37.6824551447909, -8.458310730533015],
              [-37.682667586137264, -8.45807053829391],
              [-37.682849986972919, -8.457770017821897],
              [-37.68306233724163, -8.457529734543359],
              [-37.683064688742931, -8.456837223089023],
              [-37.6829143740364, -8.456595809354628],
              [-37.682764161768176, -8.456324282465184],
              [-37.682250467917179, -8.456292439287317],
              [-37.681797070409793, -8.456290912046528],
              [-37.681797889424153, -8.456049916724343],
              [-37.681919493460995, -8.455839532173183],
              [-37.682161666596379, -8.455750007482024],
              [-37.68216228041932, -8.455569328781353],
              [-37.682072569974075, -8.4552678016068],
              [-37.681770919749333, -8.455086104813129],
              [-37.68119745527752, -8.454843265002106],
              [-37.680925436059724, -8.454842348205762],
              [-37.680804958324508, -8.454721488307689],
              [-37.681077182196603, -8.454662178964597],
              [-37.681409690366067, -8.454663299451113],
              [-37.681772352292107, -8.454664521227814],
              [-37.682558256221526, -8.454667167717119],
              [-37.682678734572455, -8.454788027156782],
              [-37.683040987898494, -8.454909700290415],
              [-37.683524628595613, -8.454911327588635],
              [-37.683978024807146, -8.45491285258101],
              [-37.684431216865569, -8.454974603339338],
              [-37.684793573249877, -8.455066161810215],
              [-37.685185970342623, -8.45521804790028],
              [-37.685517356793888, -8.455550500136992],
              [-37.685728683653771, -8.455611436978945],
              [-37.686031152604599, -8.455552225752362],
              [-37.68630378476049, -8.455372460072004],
              [-37.686576315030564, -8.455222716902492],
              [-37.687091016766971, -8.454983535946035],
              [-37.687394300265808, -8.454683418104494],
              [-37.687305298880034, -8.454171099255783],
              [-37.687246530785536, -8.453689085769181],
              [-37.687035725440936, -8.453447380054971],
              [-37.686764827653342, -8.453115222525938],
              [-37.68628219694827, -8.452842581339905],
              [-37.685980240046845, -8.452751227259945],
              [-37.685980852177444, -8.452570457823306],
              [-37.686192687362308, -8.4524808283341],
              [-37.686404613287827, -8.45239119901969],
              [-37.686767477633573, -8.452332189488157],
              [-37.686979505275708, -8.452212446648513],
              [-37.68743372479944, -8.451942948175697],
              [-37.687676505892128, -8.4516726499184],
              [-37.687617239098053, -8.45131108886984],
              [-37.687316403487976, -8.450888400466233],
              [-37.686744264587176, -8.450254097804665],
              [-37.686412869668104, -8.449951760532549],
              [-37.685989926899261, -8.449890114399103],
              [-37.685990640442647, -8.449679322192754],
              [-37.686142280220785, -8.449529263445847],
              [-37.686263527107791, -8.449369336110111],
              [-37.686324378947575, -8.44928896624881],
              [-37.686626639041954, -8.449289980137127],
              [-37.687050498295179, -8.449080516395327],
              [-37.687322921435658, -8.448960975559048],
              [-37.687263644005505, -8.44862952801588],
              [-37.6869925450479, -8.448357597422758],
              [-37.686570520923979, -8.448024843095661],
              [-37.686238629105674, -8.447843048793775],
              [-37.686118558894343, -8.447601737860138],
              [-37.685787279507068, -8.447239173787267],
              [-37.685512827862922, -8.447072854574381],
              [-37.685089684511837, -8.447071434293036],
              [-37.68493794320527, -8.447251696328747],
              [-37.684424271834544, -8.447189744341063],
              [-37.684122524184339, -8.447038072804942],
              [-37.683458529909736, -8.446764820151611],
              [-37.682975614286747, -8.446552402344048],
              [-37.682340946199417, -8.44655026792333],
              [-37.682129534047213, -8.446489329756783],
              [-37.682130351892184, -8.446248334281591],
              [-37.682281991386112, -8.446098277027486],
              [-37.68255431109278, -8.44600885253208],
              [-37.683128581415893, -8.44601078344335],
              [-37.68330985177132, -8.4460415062559],
              [-37.683581150545557, -8.446253212490468],
              [-37.683822500344711, -8.446404681393167],
              [-37.684366731660909, -8.446346282642622],
              [-37.684730202468401, -8.446106504759522],
              [-37.684912390857413, -8.445866208371331],
              [-37.685094782916892, -8.445565685525091],
              [-37.684914634420778, -8.445203628293861],
              [-37.68443273948364, -8.44469008083092],
              [-37.684162155492508, -8.444267492727164],
              [-37.684145814256382, -8.444265448367023],
              [-37.681364247259964, -8.442237680591452],
              [-37.678165917051189, -8.440560448539303],
              [-37.674840576428707, -8.439570399624577],
              [-37.674418712097228, -8.439569696813864],
              [-37.674178961613094, -8.439484333437681],
              [-37.676134681389584, -8.438134482561949],
              [-37.675682381487832, -8.437337436568425],
              [-37.675923887529443, -8.435840358354659],
              [-37.675098987156311, -8.435459753276044],
              [-37.674478005074327, -8.435977902507803],
              [-37.674060432740781, -8.435571994810209],
              [-37.672987004186069, -8.435737107824586],
              [-37.672973669486005, -8.43528002655272],
              [-37.67093101411988, -8.433768075101705],
              [-37.67178196192679, -8.433832269113353],
              [-37.674232308629705, -8.434866764804235],
              [-37.674636572847362, -8.434274092124951],
              [-37.675935389377372, -8.432381965851626],
              [-37.674933595456267, -8.432129718617405],
              [-37.67427326018192, -8.431591867303483],
              [-37.674425478142048, -8.431324164259168],
              [-37.675017178164786, -8.431268106176208],
              [-37.67570590879464, -8.431041641496291],
              [-37.675792298466511, -8.42970147873771],
              [-37.675948316296349, -8.428957036911532],
              [-37.676962611538841, -8.430017954330513],
              [-37.677875934460069, -8.43054028575575],
              [-37.678431935272719, -8.430272132205923],
              [-37.678760691515329, -8.430302086592651],
              [-37.680014964412223, -8.428787159443793],
              [-37.67882486516163, -8.427985374760993],
              [-37.678508732689146, -8.427046543146639],
              [-37.677994051910801, -8.426140321530545],
              [-37.676344641602753, -8.425648427902633],
              [-37.676079376806513, -8.426177819399864],
              [-37.675560455761236, -8.425238664504393],
              [-37.674416984799713, -8.424671784635418],
              [-37.675882242850278, -8.424886346440665],
              [-37.677265376561664, -8.425162300178297],
              [-37.677088726542323, -8.424042081692571],
              [-37.677125517498858, -8.42329072585699],
              [-37.67645578544483, -8.42231389724452],
              [-37.675807154769288, -8.42111495074554],
              [-37.67857291360388, -8.422693148039398],
              [-37.679212030190953, -8.423755056825648],
              [-37.679989348579852, -8.423914115610508],
              [-37.67997225143047, -8.422661771324817],
              [-37.67997885107421, -8.421652403968661],
              [-37.681117644555187, -8.421509369123505],
              [-37.680110799353443, -8.420181174944535],
              [-37.68036107453063, -8.419864603140244],
              [-37.679668725628282, -8.41907507635921],
              [-37.67920635974933, -8.418972692097302],
              [-37.678695206388149, -8.419277624709474],
              [-37.678314676140978, -8.418770926990032],
              [-37.680046585007219, -8.415786198086057],
              [-37.677795637169709, -8.419124754919689],
              [-37.676907099451931, -8.417756078299529],
              [-37.677024957082125, -8.417281170764911],
              [-37.676472719959243, -8.416714209534918],
              [-37.676208846532262, -8.416756185103619],
              [-37.675990716745972, -8.416859807499588],
              [-37.675441838331068, -8.416400830756389],
              [-37.675265979716002, -8.416064740051027],
              [-37.676369611560219, -8.415332984244712],
              [-37.677392486348893, -8.415730614876717],
              [-37.67775920440576, -8.415515809276842],
              [-37.677718341960279, -8.415059267406903],
              [-37.678709870916165, -8.415595419798956],
              [-37.679174896183738, -8.414509731975375],
              [-37.679060821499192, -8.413869369332486],
              [-37.678983913922529, -8.413865403282193],
              [-37.678306642907089, -8.413266193097314],
              [-37.679280676533317, -8.412451427938985],
              [-37.678979714168449, -8.411995640092368],
              [-37.678864246703505, -8.411980964133756],
              [-37.678559623568361, -8.411484311983806],
              [-37.676983869483749, -8.411266625990718],
              [-37.673813022455967, -8.410828605298317],
              [-37.673908820297974, -8.413048640647631],
              [-37.673569002270462, -8.413178166872234],
              [-37.673420722790063, -8.413278406449157],
              [-37.67315533250963, -8.413606678700846],
              [-37.673036721471682, -8.413795188116387],
              [-37.672956850909856, -8.414021447393807],
              [-37.672845207113838, -8.414190718497538],
              [-37.67256597151615, -8.414504112803833],
              [-37.672526056576807, -8.414677876186392],
              [-37.672482814972589, -8.415108451378153],
              [-37.672246709666354, -8.415530326853531],
              [-37.672132358366973, -8.415614402824653],
              [-37.671886747198698, -8.415947352551408],
              [-37.67173366525202, -8.416070634834876],
              [-37.671710940026152, -8.416130151822927],
              [-37.671636139739661, -8.416227292829296],
              [-37.671511100669974, -8.41635618603091],
              [-37.671435577773764, -8.416452148943636],
              [-37.671375275714809, -8.416558743666078],
              [-37.671326088303395, -8.416761594108026],
              [-37.671198323808092, -8.41713039032954],
              [-37.670880947695657, -8.417681667217716],
              [-37.670611147583621, -8.418049801868795],
              [-37.672011513271144, -8.420908074489647],
              [-37.671963556167285, -8.421682179234763],
              [-37.671393240349246, -8.423513911659894],
              [-37.671419256156639, -8.42371457468712],
              [-37.67080515257264, -8.424803360546615],
              [-37.671503806026458, -8.425205608755329],
              [-37.671744183382948, -8.425290884093959],
              [-37.673100637426039, -8.426358934318724],
              [-37.673297599831351, -8.428577139754735],
              [-37.673292704388054, -8.428814322728034],
              [-37.668368938412875, -8.433885627257849],
              [-37.669051779073079, -8.436947568219871],
              [-37.680183016333693, -8.447153772520911],
              [-37.679192992641745, -8.453306326676808],
              [-37.670271173235946, -8.455902669192229],
              [-37.67156545294921, -8.45695813844144],
              [-37.672212408894232, -8.45722204035258],
              [-37.672454390350531, -8.457162634843396],
              [-37.672635767228527, -8.457163250256972],
              [-37.672817556308061, -8.457043413584772],
              [-37.672998533234328, -8.457134367521331],
              [-37.673179395038474, -8.457285547704265],
              [-37.673480938759887, -8.457497363755801],
              [-37.673631246983177, -8.457738870685045],
              [-37.674084438768425, -8.45780063367754],
              [-37.674234348128877, -8.458132388758811],
              [-37.674263254186108, -8.458524050900085],
              [-37.674443808316532, -8.45876556971734],
              [-37.674775084096666, -8.459128052640386],
              [-37.675107401525153, -8.459159291788454],
              [-37.675499888037585, -8.459311187926916],
              [-37.675862141777493, -8.459432958415197],
              [-37.676284782990258, -8.4595849559363],
              [-37.676676871386299, -8.459827189850381],
              [-37.676918237701166, -8.459948460009763],
              [-37.677221224122157, -8.459738691084937],
              [-37.677432950155037, -8.459709293696031],
              [-37.677614123176724, -8.45977013309521],
              [-37.677824834173734, -8.46001175270151],
              [-37.67809572694167, -8.460344006302778],
              [-37.678275873739508, -8.460705976073482],
              [-37.678577525873727, -8.460887675932213],
              [-37.678850062194321, -8.460738029669441],
              [-37.679153151082488, -8.460498146117224],
              [-37.679365915395216, -8.46013741315682],
              [-37.679699761147461, -8.4597470657757],
              [-37.679972193763199, -8.459627531616441],
              [-37.680244216231792, -8.459628449590475],
              [-37.680455634771903, -8.459689389789524],
              [-37.680605857876991, -8.459930804074624],
              [-37.68045296970579, -8.460472420537876],
              [-37.680451534730011, -8.460894003773102],
              [-37.680481073911253, -8.46107478404797],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0214000',
        uf: 'PE',
        nome_proje: 'PA VALE DO PIUTÁ',
        municipio: 'IBIMIRIM',
        area_hecta: '491.2413',
        capacidade: 39.0,
        num_famili: 36.0,
        fase: 3.0,
        data_de_cr: '12/02/2007',
        forma_obte: 'Doação',
        data_obten: '12/02/2007',
        area_calc_: 492.0814,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.60977756340283, -8.383147160004878],
              [-37.610011421972708, -8.383163537600844],
              [-37.610104512902019, -8.383159434525272],
              [-37.610350091237571, -8.383148634213073],
              [-37.610528266080777, -8.382940371715348],
              [-37.610754749304967, -8.382732188756901],
              [-37.610836582123632, -8.382394997432764],
              [-37.610449857048927, -8.382313244860301],
              [-37.610030919687226, -8.382199276341915],
              [-37.609741164242166, -8.382069666263549],
              [-37.609144566479436, -8.382019365623817],
              [-37.608951518589627, -8.381890095856818],
              [-37.6087317267095, -8.381671930949986],
              [-37.608433123887103, -8.381476999481931],
              [-37.608224592050099, -8.381207872411483],
              [-37.608000494692185, -8.381102637194072],
              [-37.608001131135083, -8.380923681493586],
              [-37.607852439961647, -8.380654765355295],
              [-37.607643060378003, -8.380624185389522],
              [-37.60738882348852, -8.380593447049147],
              [-37.607209477917294, -8.38051821075239],
              [-37.60724029212642, -8.380279769246092],
              [-37.607345361056069, -8.380175695114923],
              [-37.60734615697298, -8.37995197793434],
              [-37.607217411970169, -8.379921682291338],
              [-37.607229832553131, -8.379723054816372],
              [-37.607265223413997, -8.379653007209656],
              [-37.607511658051017, -8.379630546074258],
              [-37.607559261452607, -8.379420286967909],
              [-37.607641854692716, -8.379303744722511],
              [-37.607817825480616, -8.379280944385556],
              [-37.607970077494691, -8.379339988528676],
              [-37.608133960667111, -8.379422313751524],
              [-37.608379564241758, -8.379633606763816],
              [-37.608543530568021, -8.37969260158958],
              [-37.608731132053485, -8.37969326284599],
              [-37.608837028173213, -8.379611798268487],
              [-37.608896268975371, -8.379448421867194],
              [-37.608897224275161, -8.379179581267529],
              [-37.608933071373436, -8.378981036142417],
              [-37.609132726371676, -8.378911567093494],
              [-37.609331833750694, -8.378970685372199],
              [-37.609401957652459, -8.379041104934917],
              [-37.609412641190154, -8.379356738143571],
              [-37.609376910967796, -8.379496866924256],
              [-37.609265358775261, -8.379509043493099],
              [-37.609098430906727, -8.37990019168871],
              [-37.609304861121842, -8.380045242959394],
              [-37.609492328209974, -8.379674784713565],
              [-37.609679739262226, -8.379345561532702],
              [-37.60974263710866, -8.379098370559106],
              [-37.60974103039166, -8.378451258229109],
              [-37.609387419867048, -8.377893153916499],
              [-37.608880992810114, -8.37748643097062],
              [-37.608272687173645, -8.3771298979925],
              [-37.608096063383435, -8.376800206128193],
              [-37.60766429272315, -8.376798683908078],
              [-37.607562233461294, -8.376924923888724],
              [-37.607231261721012, -8.377151545907234],
              [-37.606849710573911, -8.377327349195136],
              [-37.606238974904279, -8.377654262856167],
              [-37.605653753524919, -8.377955945559984],
              [-37.605576758006983, -8.378183462757615],
              [-37.605575495873879, -8.378537847172931],
              [-37.605245513614442, -8.378486041618331],
              [-37.605195565773926, -8.378258076067727],
              [-37.604814553321965, -8.37825672979433],
              [-37.604255747824979, -8.378280074576594],
              [-37.603849310988856, -8.378303957416033],
              [-37.603746438170994, -8.378657891784608],
              [-37.603365426048427, -8.378681863977624],
              [-37.603263094324291, -8.378883970917895],
              [-37.603110454517129, -8.378959390607834],
              [-37.602831051182093, -8.378958401840483],
              [-37.60265388959894, -8.378780625783389],
              [-37.602476095843514, -8.37877999642761],
              [-37.601866621134214, -8.378777838396521],
              [-37.60168828517034, -8.37892903579236],
              [-37.601559803945953, -8.379333608770674],
              [-37.60143096122485, -8.379864689474232],
              [-37.601024613522426, -8.379863249523586],
              [-37.600567415691209, -8.379861628878286],
              [-37.600339314860491, -8.379734220653349],
              [-37.600416856509291, -8.379354878026161],
              [-37.600544705012808, -8.379102222750944],
              [-37.600799588032679, -8.37884992724674],
              [-37.601003530210498, -8.378648181239461],
              [-37.601258502771188, -8.378370656178332],
              [-37.601539351430098, -8.377941393524422],
              [-37.601794232712308, -8.377689187560868],
              [-37.602023960300265, -8.377386252489019],
              [-37.602125478517479, -8.377386611912046],
              [-37.602379365315556, -8.377412830596626],
              [-37.602810954988783, -8.377464997911298],
              [-37.603039598115501, -8.377465806932042],
              [-37.602989380173241, -8.377288480211551],
              [-37.602787156341606, -8.377034565441337],
              [-37.602508657674036, -8.37678047109558],
              [-37.601924610250656, -8.37675317415791],
              [-37.601645751097976, -8.37660026726309],
              [-37.601391955371426, -8.376548728611681],
              [-37.60101085510442, -8.376598018432093],
              [-37.600832429542201, -8.376774535148565],
              [-37.600553028038796, -8.376773545025538],
              [-37.599740339427449, -8.376770663954209],
              [-37.599384934992315, -8.376718854133175],
              [-37.599385840231349, -8.376465748893134],
              [-37.599513778895023, -8.376213094192348],
              [-37.599818513926117, -8.376214174812899],
              [-37.600148673856786, -8.376215345327251],
              [-37.600478381747621, -8.376343023033714],
              [-37.6008845447884, -8.376395102112571],
              [-37.600784021643065, -8.376116317434146],
              [-37.600606410183332, -8.376065048274034],
              [-37.600023269493903, -8.375784552973007],
              [-37.599515498852497, -8.375732112810155],
              [-37.599211397565391, -8.375528565594109],
              [-37.59880559791295, -8.375375297155491],
              [-37.598679470535288, -8.375147061132489],
              [-37.598426582106782, -8.37484241563304],
              [-37.598147906874011, -8.374638958017133],
              [-37.597741474524959, -8.374662835132042],
              [-37.597360648256732, -8.374636163018048],
              [-37.597208734756059, -8.374483704293914],
              [-37.597310977153541, -8.374306918751564],
              [-37.597489040444358, -8.37423159145124],
              [-37.597717590358982, -8.374232402919725],
              [-37.597794135875915, -8.374131485596893],
              [-37.597642222503957, -8.374004346913694],
              [-37.597490308909755, -8.373877298614513],
              [-37.597287183900406, -8.373876577336684],
              [-37.596957026346736, -8.373875404755397],
              [-37.596855056024268, -8.373976231574661],
              [-37.596575384865538, -8.374051197585317],
              [-37.596143619367353, -8.374049663270247],
              [-37.595939405678322, -8.374352685245489],
              [-37.595658827072732, -8.374680664966617],
              [-37.595480148710109, -8.375256598750008],
              [-37.595441024498278, -8.375633001923498],
              [-37.595383283316266, -8.375934012312056],
              [-37.595174918622313, -8.37610273324187],
              [-37.594834051180925, -8.37632750006224],
              [-37.594436455167944, -8.376570783195126],
              [-37.594058015830399, -8.376738897980994],
              [-37.593943882695669, -8.376964380652865],
              [-37.593999326868023, -8.377303502104567],
              [-37.594338167770893, -8.377643632605466],
              [-37.594526092540434, -8.377907808756095],
              [-37.594373846762977, -8.378227381801363],
              [-37.594203231582085, -8.37837733688799],
              [-37.593938812280648, -8.378376395246931],
              [-37.593712346614531, -8.378300352568116],
              [-37.593505129852552, -8.378148961442475],
              [-37.593279273586063, -8.377903368337332],
              [-37.593167033437112, -8.377601752963233],
              [-37.593149093441959, -8.377338091610177],
              [-37.593149837417315, -8.377131014287885],
              [-37.592999529611703, -8.376885780732824],
              [-37.592565037566906, -8.376884232283313],
              [-37.592243958441593, -8.376883087709412],
              [-37.592054523884116, -8.377014165740626],
              [-37.591997050648118, -8.377239849680729],
              [-37.591957854668109, -8.377635151072653],
              [-37.591843246248096, -8.377992384621148],
              [-37.591729291259213, -8.378142631013548],
              [-37.591407872560289, -8.378235619790328],
              [-37.591256075522118, -8.378404539981512],
              [-37.591274420865865, -8.378555167830525],
              [-37.591444292055094, -8.378612291366265],
              [-37.591481321230454, -8.378819503297304],
              [-37.591329523950776, -8.378988423479665],
              [-37.591159201303853, -8.379081951065089],
              [-37.590932194159642, -8.379156467243199],
              [-37.590610842220215, -8.379230646449594],
              [-37.590364812373899, -8.379342712326423],
              [-37.590194014922943, -8.379567991062769],
              [-37.590098471422841, -8.379850055873208],
              [-37.589890034795182, -8.380037581999675],
              [-37.589662484161103, -8.380262748075525],
              [-37.589340792291175, -8.380430969733244],
              [-37.589075602725927, -8.380618292471372],
              [-37.588885577075658, -8.380937727404946],
              [-37.588695664074308, -8.381200645249598],
              [-37.588317082697777, -8.381406370796023],
              [-37.587900726568094, -8.381611960750661],
              [-37.587636237926368, -8.381629823247682],
              [-37.587352839179836, -8.381628808912639],
              [-37.587126102047407, -8.381627997233169],
              [-37.587051096830031, -8.381477166596838],
              [-37.586767698265852, -8.381476151860037],
              [-37.586446140327148, -8.381606753353058],
              [-37.586314043365398, -8.38154976289411],
              [-37.586333678508574, -8.381342753882452],
              [-37.586277560823248, -8.381191990808986],
              [-37.586107916397069, -8.381097247902957],
              [-37.585843405380658, -8.381096300210514],
              [-37.585805290940911, -8.381190298816438],
              [-37.585596851813499, -8.381377822202751],
              [-37.585369911050158, -8.381433435646635],
              [-37.585200198823607, -8.381357501071811],
              [-37.585106216484483, -8.381206602162514],
              [-37.585125920527211, -8.380980694183339],
              [-37.58523987841442, -8.380830540702744],
              [-37.585409930649725, -8.380812341282018],
              [-37.585601819957013, -8.380706595660797],
              [-37.585484161375994, -8.380549101202263],
              [-37.585267981136283, -8.380391253603005],
              [-37.584854473931188, -8.380311189654012],
              [-37.584499582603826, -8.380368875887962],
              [-37.584222869494738, -8.380603537721518],
              [-37.583926096739987, -8.380936241251051],
              [-37.583630175912766, -8.381033383727937],
              [-37.583274786397276, -8.381228516807701],
              [-37.58284121915257, -8.381246582963319],
              [-37.582525949229229, -8.381245450760979],
              [-37.582190334906947, -8.381420940569758],
              [-37.581677451682062, -8.381576260656141],
              [-37.581165137906829, -8.38157441911722],
              [-37.580612988192613, -8.38169026060412],
              [-37.580316639268446, -8.381905135343269],
              [-37.580183552537747, -8.381946072305047],
              [-37.580195527929618, -8.381523548008941],
              [-37.580706312189143, -8.381245964471972],
              [-37.581253607339256, -8.381066353925712],
              [-37.581921758182915, -8.38103846199418],
              [-37.582438523884498, -8.380889033188724],
              [-37.58283366606409, -8.380799662974953],
              [-37.583228936618418, -8.380649706338419],
              [-37.583685592278513, -8.380348774639666],
              [-37.584081299809789, -8.380077826479464],
              [-37.584537188467344, -8.379988672262849],
              [-37.585083624613191, -8.380020834227473],
              [-37.585356614644319, -8.380112602041708],
              [-37.585781100154044, -8.380295701871786],
              [-37.58605376265924, -8.380478257453353],
              [-37.586417392004769, -8.380691431964166],
              [-37.586871988458405, -8.380935225190569],
              [-37.587479374103395, -8.380937399449376],
              [-37.587965392341218, -8.380878551999595],
              [-37.588593328606471, -8.380520262611089],
              [-37.589023090393219, -8.380172386281574],
              [-37.589453362244988, -8.379707949759691],
              [-37.589766678000558, -8.379243185219863],
              [-37.589767377356587, -8.379049039229333],
              [-37.589650793314497, -8.378970945255237],
              [-37.589378111033092, -8.378969970972799],
              [-37.588911404542941, -8.378735361076696],
              [-37.58879552058518, -8.378463120977573],
              [-37.588796499999781, -8.378191389035015],
              [-37.58906960137498, -8.378075894149347],
              [-37.589537146408119, -8.378077564745301],
              [-37.589692405254418, -8.378233383918861],
              [-37.590004181307989, -8.378195703990679],
              [-37.590122303097083, -8.377846712739267],
              [-37.59055187200088, -8.377576420398956],
              [-37.590280029204955, -8.377342507810107],
              [-37.590046771015302, -8.377186319934944],
              [-37.590086703826877, -8.376914726922491],
              [-37.59039856995129, -8.376876956397588],
              [-37.590944770027562, -8.376645963717413],
              [-37.591452105975016, -8.376414831547919],
              [-37.592076444326111, -8.376145231796897],
              [-37.593206630619484, -8.376032788440398],
              [-37.593713128439092, -8.376034592457584],
              [-37.594142692993373, -8.375764385872982],
              [-37.594611120874546, -8.375494226594579],
              [-37.595001590828637, -8.375262673369679],
              [-37.595159636298156, -8.374641995086153],
              [-37.595278030697223, -8.374215324608198],
              [-37.595513426436433, -8.373750276445147],
              [-37.594968903563952, -8.373515306956778],
              [-37.594853203508208, -8.373165481553272],
              [-37.59458066381854, -8.373125718411305],
              [-37.594424151138966, -8.373319220043438],
              [-37.594422061397786, -8.373901659349148],
              [-37.594343177636013, -8.374173205189498],
              [-37.594030895860932, -8.374327358580295],
              [-37.593836542831198, -8.374210195483297],
              [-37.593565535999225, -8.373743345717298],
              [-37.593022178807679, -8.373158964292898],
              [-37.592789569940329, -8.372847515772763],
              [-37.592674241842353, -8.372420013791752],
              [-37.593064339532646, -8.372266138425985],
              [-37.593376062393368, -8.372267248408285],
              [-37.593377038221966, -8.371995425415927],
              [-37.593494736317567, -8.371762901982319],
              [-37.593729156861279, -8.371569587549212],
              [-37.593457315373726, -8.37133567745502],
              [-37.592950823684063, -8.371333874114438],
              [-37.59267870425623, -8.371177549769753],
              [-37.592172212941072, -8.371175745465097],
              [-37.591704675611929, -8.371174079352354],
              [-37.591080715686815, -8.371366003618816],
              [-37.590496177195256, -8.371402712366393],
              [-37.590637097285331, -8.371000358899737],
              [-37.590987591053384, -8.3710016088909],
              [-37.591557412091902, -8.370916287066681],
              [-37.592214946948509, -8.370831367319424],
              [-37.592215731435694, -8.370612986665934],
              [-37.591821380731446, -8.370611581532263],
              [-37.591427277879944, -8.37056650021289],
              [-37.591385080776185, -8.370129763894528],
              [-37.591386650195588, -8.369693093152758],
              [-37.591387905280079, -8.369343865064304],
              [-37.591782320753126, -8.369301593969988],
              [-37.592176579331706, -8.36930299860091],
              [-37.592175324631192, -8.369652317235833],
              [-37.59248079706812, -8.370002638036564],
              [-37.592785708428153, -8.370484077215284],
              [-37.593355306007695, -8.370486105234225],
              [-37.593793645796652, -8.370400311920998],
              [-37.594143826151182, -8.370488911359397],
              [-37.594142573023518, -8.370838230269824],
              [-37.594097083669133, -8.37131833151782],
              [-37.594314217863456, -8.371843043915444],
              [-37.594751553852724, -8.372062892887225],
              [-37.594926018401864, -8.372281897068548],
              [-37.59518035797381, -8.372788384667968],
              [-37.595335492467136, -8.373257715240081],
              [-37.59564856313704, -8.373415088012035],
              [-37.596276480822453, -8.373208973565644],
              [-37.596642413797973, -8.373210273719884],
              [-37.597217373619365, -8.373212315858733],
              [-37.597425467730254, -8.373473578150968],
              [-37.597790655409391, -8.373683221034053],
              [-37.598313314020054, -8.373685076026304],
              [-37.598731459155374, -8.373686559586959],
              [-37.598835041262411, -8.373947360131542],
              [-37.599513585336332, -8.374210199706928],
              [-37.60050520431448, -8.374630498083265],
              [-37.601027583549353, -8.374736522029],
              [-37.601341311483353, -8.3746855464692],
              [-37.60160459239988, -8.374165521496474],
              [-37.60150147532201, -8.373748462985546],
              [-37.601346147310402, -8.373331129188827],
              [-37.601294865688963, -8.373070514235476],
              [-37.601503335001809, -8.373227512345348],
              [-37.601920364632214, -8.373541599062253],
              [-37.60260011656181, -8.373491917614587],
              [-37.602724162546551, -8.373591465839819],
              [-37.602860703233823, -8.373701186200337],
              [-37.602878849601673, -8.373756140369283],
              [-37.602925294044262, -8.373896649382507],
              [-37.602982518738756, -8.374069750716064],
              [-37.603015847612888, -8.374170515309192],
              [-37.602752659213067, -8.374690541851537],
              [-37.602437539564704, -8.375106120509962],
              [-37.602488078293675, -8.375575170037143],
              [-37.602800039575293, -8.376045054161711],
              [-37.603320658801344, -8.376619940070158],
              [-37.603737041299858, -8.377142370216276],
              [-37.60420573210304, -8.377612807856258],
              [-37.605198850741502, -8.377616317452508],
              [-37.605670417010678, -8.377305462500248],
              [-37.605932120463152, -8.377202213046344],
              [-37.606560132975666, -8.376995992283602],
              [-37.606665389107711, -8.376788016588041],
              [-37.606090421709219, -8.376785987338232],
              [-37.605410754243564, -8.376835674242567],
              [-37.605463708136654, -8.376627514236942],
              [-37.605830943735327, -8.376264203635579],
              [-37.60640683734217, -8.376005712091974],
              [-37.606618460720107, -8.375277243871986],
              [-37.606202166249311, -8.374754816732615],
              [-37.605577030157654, -8.374179565333186],
              [-37.604689732723912, -8.373811824692776],
              [-37.603958139598788, -8.373757153012949],
              [-37.603227289736743, -8.37349413577293],
              [-37.603228218576938, -8.373233615044988],
              [-37.603803180057895, -8.373235647605812],
              [-37.604482284341657, -8.373342220734266],
              [-37.605213134842302, -8.373605326172376],
              [-37.605787170498651, -8.373867786515294],
              [-37.606530468789046, -8.374256990996823],
              [-37.606965092727101, -8.37429795088088],
              [-37.607439447065275, -8.374299623560631],
              [-37.607953580988791, -8.374222582217874],
              [-37.608150838989644, -8.374341467420512],
              [-37.608229228265593, -8.374538787363591],
              [-37.60795148092356, -8.374813525049213],
              [-37.607989671177329, -8.375207566183285],
              [-37.608106719820114, -8.375641402514082],
              [-37.608303327969026, -8.375917811919669],
              [-37.608539946253622, -8.37607626261287],
              [-37.60889543408603, -8.376156278282357],
              [-37.609015002889606, -8.375880892450454],
              [-37.609331319797477, -8.375842670064026],
              [-37.609568498129015, -8.37584350520733],
              [-37.609804977381188, -8.376041291151095],
              [-37.610159766680376, -8.376318256688345],
              [-37.610594205278979, -8.376437975644679],
              [-37.610711956117754, -8.376674770270764],
              [-37.610671107821496, -8.377029197045873],
              [-37.610433369358852, -8.37718597749102],
              [-37.610392611810248, -8.377540314070655],
              [-37.610668032854093, -8.377895853788536],
              [-37.61094382460599, -8.37817263138845],
              [-37.611219246885057, -8.378528080437516],
              [-37.61117811916791, -8.378961269602074],
              [-37.610742789713832, -8.379117354866224],
              [-37.610701192747747, -8.379708159233433],
              [-37.610699515230181, -8.380180823495145],
              [-37.611094926374221, -8.380142878615212],
              [-37.611175324035372, -8.379749164124833],
              [-37.611492573415575, -8.379474563381207],
              [-37.611927993931701, -8.379318477627988],
              [-37.612284651504531, -8.379044014666235],
              [-37.61260120197533, -8.378966273521646],
              [-37.613233932226272, -8.378889733339518],
              [-37.613551458871413, -8.378536277745859],
              [-37.613631992828367, -8.378103226275428],
              [-37.613277896657699, -8.377629312002805],
              [-37.612883604311492, -8.377352119541364],
              [-37.612765851916102, -8.377115325323514],
              [-37.612649634790365, -8.376445202336948],
              [-37.612334992067616, -8.376010762675675],
              [-37.612415665710365, -8.375538375557765],
              [-37.612259670773042, -8.374946876183349],
              [-37.612300007619339, -8.374710637402909],
              [-37.612498383811378, -8.374514380711302],
              [-37.612262462100119, -8.374158981105291],
              [-37.612263438824151, -8.373883267555447],
              [-37.612106606967693, -8.373528145782235],
              [-37.611712738107379, -8.373132764140164],
              [-37.611713715096776, -8.372857050640514],
              [-37.611675520875885, -8.372463009246271],
              [-37.611637138710819, -8.372147640113198],
              [-37.611516808895423, -8.371793098686444],
              [-37.611278268432315, -8.371613573339426],
              [-37.611279113195124, -8.37137520643037],
              [-37.611637782617159, -8.371376466885335],
              [-37.611878222363266, -8.371019847188002],
              [-37.612058190123925, -8.370841701911596],
              [-37.612296941312998, -8.370961725448895],
              [-37.612773931786172, -8.371320864945609],
              [-37.613132692189062, -8.371322124354528],
              [-37.613493048274933, -8.370846739269885],
              [-37.613375238872116, -8.370369676117315],
              [-37.612957575063845, -8.370129840263015],
              [-37.61259860482312, -8.370188172637981],
              [-37.612119716831508, -8.370365268508872],
              [-37.611867800214462, -8.370168153461858],
              [-37.612004393042966, -8.369853489347513],
              [-37.612276393544732, -8.369584334126365],
              [-37.611825610263857, -8.369312640550339],
              [-37.611238235074666, -8.369355520003797],
              [-37.610514813422803, -8.369533111047517],
              [-37.610017945359544, -8.369531363801691],
              [-37.609521237161985, -8.369484583062233],
              [-37.609341340004107, -8.36925887335993],
              [-37.609657991148254, -8.369124887144771],
              [-37.609704168485173, -8.368854939401782],
              [-37.610338107678977, -8.36840701525264],
              [-37.610519122221156, -8.368317584731649],
              [-37.610203908535105, -8.368046366256275],
              [-37.609707840633028, -8.367819544972349],
              [-37.609528742550964, -8.367368671073203],
              [-37.60867096716224, -8.36723064308223],
              [-37.608310696577938, -8.366914231431023],
              [-37.608131280729992, -8.366553422908442],
              [-37.607724764063882, -8.366551991473781],
              [-37.607816390009674, -8.366192137319151],
              [-37.608223385523246, -8.366058560469416],
              [-37.608539714530458, -8.366014640485773],
              [-37.608269312800417, -8.365833555062679],
              [-37.607727801179038, -8.365696638746103],
              [-37.607367532703634, -8.365380226566844],
              [-37.606735904609927, -8.365152924849774],
              [-37.60633050957717, -8.364836352917587],
              [-37.605789549958871, -8.364519302719403],
              [-37.605745702484256, -8.364158971563677],
              [-37.605340538768999, -8.363752332976057],
              [-37.604665228222025, -8.363119755149869],
              [-37.604034658178783, -8.362622253589594],
              [-37.602906500324565, -8.362303127347293],
              [-37.602321061304508, -8.361805873430884],
              [-37.601825878689226, -8.361308937896798],
              [-37.601601343611541, -8.360947968213244],
              [-37.601376166825574, -8.360767129394526],
              [-37.601284858389043, -8.361036915929633],
              [-37.601193068452467, -8.361441710174908],
              [-37.600921228769813, -8.361665824832992],
              [-37.60051503937872, -8.361574411819438],
              [-37.600516484763993, -8.361169207816442],
              [-37.600518090375928, -8.36071906151615],
              [-37.600157190404779, -8.360582684808277],
              [-37.599615117765964, -8.360580766350676],
              [-37.599435872918889, -8.360175013291604],
              [-37.599165480005205, -8.359993922942834],
              [-37.599166122724384, -8.359813882603122],
              [-37.59952750369348, -8.35981516195093],
              [-37.599798378771233, -8.359861153949417],
              [-37.600204887125194, -8.359862592390385],
              [-37.600115505406599, -8.359592166901633],
              [-37.600026605730925, -8.359186643279902],
              [-37.59975685545394, -8.358825512999225],
              [-37.599667313501435, -8.358600120189736],
              [-37.599848324770811, -8.358510694167942],
              [-37.599714458747421, -8.35806006859047],
              [-37.599367850215494, -8.357736284389146],
              [-37.598870363871619, -8.357734523345055],
              [-37.598434320079988, -8.357918899699353],
              [-37.598060915617722, -8.357979610786602],
              [-37.597750151493116, -8.357916475994406],
              [-37.597565110950853, -8.35748176498878],
              [-37.597566882700413, -8.356985863091653],
              [-37.598064679836533, -8.356925683401156],
              [-37.598500501405283, -8.35680324984177],
              [-37.598875453258827, -8.356308668933973],
              [-37.599497110669709, -8.356372812418851],
              [-37.59974744593876, -8.355939823266752],
              [-37.600183487322653, -8.355755354555566],
              [-37.600618202357325, -8.355942902660065],
              [-37.60099204661465, -8.355758213102488],
              [-37.601242158887757, -8.355387255709413],
              [-37.600931837588206, -8.355200147996369],
              [-37.600622179221482, -8.354827121823394],
              [-37.601121961400565, -8.354209001976356],
              [-37.601312083852001, -8.353217855658629],
              [-37.601750769456693, -8.352289620652478],
              [-37.601816937515672, -8.351174058648384],
              [-37.601387963863004, -8.349374918637004],
              [-37.600768433167929, -8.348690901074901],
              [-37.600149346539141, -8.347882816779501],
              [-37.599467624759598, -8.347198578138505],
              [-37.599408080061188, -8.346454504468147],
              [-37.599783018862574, -8.345959920629396],
              [-37.599847422138225, -8.345340262041839],
              [-37.599788317638719, -8.344472303043627],
              [-37.599790746226198, -8.343790391619015],
              [-37.600103486621663, -8.343295587220329],
              [-37.600169212238029, -8.34230400122879],
              [-37.599922639894643, -8.341683334692325],
              [-37.599490147311073, -8.340875909967597],
              [-37.599244459930794, -8.340007201522004],
              [-37.598750087040642, -8.339137704923509],
              [-37.598380124868761, -8.338237359489566],
              [-37.598072467531814, -8.337306488525657],
              [-37.597783483360956, -8.336919609640482],
              [-37.596043516464348, -8.33754780951721],
              [-37.598281656651523, -8.342530636946874],
              [-37.597527725475018, -8.346642184136748],
              [-37.59999593499019, -8.352790090201781],
              [-37.59557075534665, -8.356246588336022],
              [-37.59604733174298, -8.358666598512295],
              [-37.600541688561314, -8.364590560265446],
              [-37.601699074422186, -8.363512679940138],
              [-37.608902855636508, -8.370076703326196],
              [-37.6101241047228, -8.37357326217232],
              [-37.608919807014054, -8.372565989618865],
              [-37.603499497271365, -8.371385766693866],
              [-37.599572119492244, -8.371145886147984],
              [-37.599568916487883, -8.37204210868043],
              [-37.59629438584426, -8.370923105869966],
              [-37.595286638348327, -8.369027950717291],
              [-37.592693592604924, -8.367486152907976],
              [-37.589127421850826, -8.367541894562796],
              [-37.588945196460877, -8.373111597108135],
              [-37.591045143753675, -8.373177780175013],
              [-37.591813927623768, -8.374403287509294],
              [-37.589171875037117, -8.375270375754827],
              [-37.587369438464115, -8.377053859179314],
              [-37.580250084689006, -8.37960053354314],
              [-37.580124008802677, -8.384041513579398],
              [-37.585080425721031, -8.382764396617885],
              [-37.58710838674692, -8.38361716205659],
              [-37.589744448861012, -8.382791673228095],
              [-37.595099954443107, -8.378293537208837],
              [-37.596413921189345, -8.377323938938796],
              [-37.599552239309752, -8.382621060799558],
              [-37.605459042377994, -8.380129970116228],
              [-37.60633875668892, -8.383370685441719],
              [-37.608447508312324, -8.386496373147789],
              [-37.609258381655017, -8.39018238536978],
              [-37.60825020000965, -8.395725617139991],
              [-37.612148966073924, -8.396910781106879],
              [-37.611329163771217, -8.397874301799167],
              [-37.607720292652857, -8.397672386430163],
              [-37.607436264269552, -8.403445957054201],
              [-37.609034880713644, -8.408444341923689],
              [-37.609675023761781, -8.405154196227004],
              [-37.609934089395473, -8.403629673917074],
              [-37.609776477072188, -8.403594211935614],
              [-37.609488710362236, -8.403437568209448],
              [-37.609363416684957, -8.40325201862438],
              [-37.609267528531142, -8.403173278438791],
              [-37.609267963131671, -8.403051292031611],
              [-37.609434206973297, -8.403034426604309],
              [-37.609626541832554, -8.403035105941944],
              [-37.609871304802887, -8.40302728919203],
              [-37.610054921965329, -8.403027937535828],
              [-37.610347892002835, -8.402998407001686],
              [-37.610540350837361, -8.402964271366384],
              [-37.610402178711418, -8.402493284357968],
              [-37.610089045701557, -8.402039132423596],
              [-37.609915416238287, -8.401707460869716],
              [-37.609688649787572, -8.401532495012193],
              [-37.609461850689911, -8.401392253459626],
              [-37.609462347291576, -8.401252814562259],
              [-37.60955010670461, -8.401166041885805],
              [-37.609690697351567, -8.400957467750166],
              [-37.609534284648205, -8.400712939518673],
              [-37.609377470871756, -8.400555582851469],
              [-37.609413239045807, -8.400329185920977],
              [-37.609466938681209, -8.399963502063327],
              [-37.609415534656861, -8.399684529665642],
              [-37.609538193262395, -8.399615242418468],
              [-37.609747899941482, -8.399633345025219],
              [-37.609887063209896, -8.399825544599205],
              [-37.61006140311143, -8.399982962912935],
              [-37.610218217016211, -8.400140319319588],
              [-37.610655280838564, -8.400141861531321],
              [-37.610918123828085, -8.400003438444717],
              [-37.611320412019168, -8.39995258954805],
              [-37.611722605169597, -8.399954007576989],
              [-37.611932559817333, -8.399902479966316],
              [-37.612055341915365, -8.399798377322261],
              [-37.612474970364822, -8.399799856004471],
              [-37.612719791499458, -8.399800718496056],
              [-37.612895156161407, -8.399644442631351],
              [-37.613192992515863, -8.399471326084978],
              [-37.613351107115392, -8.399262721494058],
              [-37.613474878420078, -8.398879830433691],
              [-37.613563440568647, -8.398566535807019],
              [-37.613791353395023, -8.398393082060895],
              [-37.614071876996846, -8.39818499824757],
              [-37.6142474874108, -8.397959092387385],
              [-37.614282572575917, -8.397924310364404],
              [-37.61425630310584, -8.397445127066829],
              [-37.614327646998461, -8.397044688751246],
              [-37.614696050369382, -8.396714925124529],
              [-37.614801861004985, -8.396453867936733],
              [-37.615012398367291, -8.396262989788083],
              [-37.615187575195414, -8.396158979588787],
              [-37.615223524518925, -8.395880314345911],
              [-37.615049274530286, -8.395722898564557],
              [-37.61492754439184, -8.39553085231829],
              [-37.614945782534384, -8.395304392623304],
              [-37.615016351354754, -8.395147837365354],
              [-37.614964605702319, -8.3949384939721],
              [-37.614720157975356, -8.394833099143021],
              [-37.614458089950602, -8.394779909987131],
              [-37.614282604839104, -8.394971001635247],
              [-37.614177074709026, -8.395127433762866],
              [-37.613984557969111, -8.395179024377123],
              [-37.613809999904802, -8.395108689853322],
              [-37.613775409686681, -8.395004032675711],
              [-37.613618224949384, -8.394951211926701],
              [-37.613425893743482, -8.394950535185632],
              [-37.6132160366245, -8.394949796670373],
              [-37.613180457112485, -8.395123927366519],
              [-37.613057396682187, -8.395332565144328],
              [-37.612882747980123, -8.395262229906564],
              [-37.612690725867857, -8.395174471144577],
              [-37.612656569080528, -8.394947827283239],
              [-37.61265743529642, -8.39470385401405],
              [-37.612780710065849, -8.39446040210496],
              [-37.612676322107632, -8.394320593733417],
              [-37.612484795580578, -8.394093395821958],
              [-37.612468073974554, -8.393866813352361],
              [-37.612188419280564, -8.393831013629036],
              [-37.611733836364238, -8.393829412529074],
              [-37.611244292616931, -8.393827687706215],
              [-37.610876943017757, -8.393861207995117],
              [-37.610736665689842, -8.393982701576624],
              [-37.610561923113686, -8.393964632815383],
              [-37.610422174470614, -8.39391187239775],
              [-37.610423041651217, -8.393667989775139],
              [-37.610423723287632, -8.393476283744091],
              [-37.610476891280385, -8.393284853193052],
              [-37.610687399968178, -8.393076434159337],
              [-37.610950114051569, -8.392972824774457],
              [-37.611212489154973, -8.392939024829117],
              [-37.611702031782691, -8.392940749510259],
              [-37.612051731096997, -8.392941981144716],
              [-37.61226149622663, -8.392942719784728],
              [-37.612676552201371, -8.39325814905186],
              [-37.612875220008846, -8.393724736463097],
              [-37.61313415155422, -8.394098484896174],
              [-37.613579883425594, -8.394163443754145],
              [-37.614103299379536, -8.394165284865631],
              [-37.614403064798502, -8.393805257368976],
              [-37.614404753107415, -8.393329066015363],
              [-37.614389915637872, -8.39285245483855],
              [-37.613918832235896, -8.392587470234343],
              [-37.613394964398822, -8.392585627624063],
              [-37.612700106428854, -8.392583182512896],
              [-37.612280444697554, -8.392542097468981],
              [-37.61194889033672, -8.392366674144416],
              [-37.611599315538314, -8.392330537516843],
              [-37.611337497274796, -8.39220771751131],
              [-37.611075588712261, -8.392084806610544],
              [-37.610953457189069, -8.392032108548902],
              [-37.610761127637595, -8.392031430741373],
              [-37.610621503672014, -8.391943765525804],
              [-37.610670529295533, -8.391716872303936],
              [-37.610761245563587, -8.391717192026984],
              [-37.611018567110861, -8.391597557585078],
              [-37.611200336743877, -8.391477747207878],
              [-37.611502847309644, -8.391418587449214],
              [-37.611533995638119, -8.391162603486858],
              [-37.611413201027005, -8.391117054166063],
              [-37.611217641687425, -8.390875373198057],
              [-37.610946172851456, -8.390708751688972],
              [-37.611007560066177, -8.390452874349014],
              [-37.611144032696814, -8.390302791611054],
              [-37.611416196724974, -8.390273637577607],
              [-37.611521505211414, -8.390409560880052],
              [-37.61176332373951, -8.390410412454823],
              [-37.612005089000654, -8.390426274837889],
              [-37.612125524977735, -8.390547240131172],
              [-37.612306850202664, -8.390577991142479],
              [-37.612548882646863, -8.390518617558397],
              [-37.612655474548006, -8.390293011593302],
              [-37.6128680162915, -8.390022654476983],
              [-37.613065286192828, -8.389782356205396],
              [-37.613112094400456, -8.389360852188798],
              [-37.612991925248643, -8.389164651526338],
              [-37.612977866561145, -8.388878395488694],
              [-37.612978667792525, -8.388652507609867],
              [-37.61296430433304, -8.388426566388706],
              [-37.61275272543557, -8.388425822127941],
              [-37.612585711085856, -8.388636023635481],
              [-37.612329086687019, -8.388559884043335],
              [-37.612163194368243, -8.388453860486008],
              [-37.611815641205368, -8.38843753557215],
              [-37.611528832274018, -8.388346187672528],
              [-37.611559819484562, -8.388135417347705],
              [-37.61162077769815, -8.388000079532748],
              [-37.611878283885709, -8.387827634432249],
              [-37.61213693834241, -8.387331549220773],
              [-37.612470111642111, -8.387151954738229],
              [-37.612486254018698, -8.386850793824557],
              [-37.612290267967246, -8.386729653404199],
              [-37.612018747874551, -8.38657804395385],
              [-37.611777840261887, -8.386321192788191],
              [-37.61153671919039, -8.386124566205574],
              [-37.611113992155531, -8.386002536675067],
              [-37.610751527853331, -8.385941034824095],
              [-37.610298188189986, -8.385924426772171],
              [-37.610147622756443, -8.385773242351474],
              [-37.610193737326163, -8.385547514356842],
              [-37.610390363829481, -8.385487981661731],
              [-37.610632714253164, -8.385338181354687],
              [-37.610965832566478, -8.385173689342603],
              [-37.611408063003793, -8.384968526510661],
              [-37.611714440899704, -8.384969604817849],
              [-37.611924110917798, -8.384970342621362],
              [-37.612198823251369, -8.384810527064085],
              [-37.612280256595533, -8.384585827135462],
              [-37.612490952359217, -8.38429728704962],
              [-37.612717720344712, -8.384008803311232],
              [-37.612863316937641, -8.383896822006422],
              [-37.613008879703081, -8.383768744216381],
              [-37.612993912631623, -8.383431211899405],
              [-37.612995222963725, -8.383061544199895],
              [-37.612948167887438, -8.382707802786777],
              [-37.61280342641011, -8.382578704451584],
              [-37.612384464657062, -8.382496840357732],
              [-37.612030031442877, -8.38239919695333],
              [-37.611675507969835, -8.382301462511892],
              [-37.611417702205067, -8.382252356987015],
              [-37.611078999933326, -8.38225116501955],
              [-37.611030144536969, -8.382379582500391],
              [-37.611207406311259, -8.382428404826133],
              [-37.61140085452773, -8.382445181888533],
              [-37.611609724953972, -8.382670903181838],
              [-37.611608926720422, -8.382895886722928],
              [-37.611430809193443, -8.383088144145947],
              [-37.611123577122775, -8.383328145434113],
              [-37.610848295126225, -8.383648649904833],
              [-37.610638397615546, -8.383712205545637],
              [-37.61018682333151, -8.383710615131211],
              [-37.609735511516369, -8.383660736293038],
              [-37.609704041149996, -8.383419633386547],
              [-37.609639797975774, -8.383355112334348],
              [-37.609430529116437, -8.383241791367748],
              [-37.609125009457259, -8.382999722564382],
              [-37.608722406974486, -8.38283752136735],
              [-37.608432446401245, -8.382740102140469],
              [-37.608013566798554, -8.38261003543834],
              [-37.607949895660987, -8.382384824812508],
              [-37.608015281824912, -8.382127876906278],
              [-37.608241513286416, -8.382016181818262],
              [-37.608483417093332, -8.382017034877244],
              [-37.608628237259737, -8.382097936433594],
              [-37.608724235301196, -8.382323260982552],
              [-37.609030039622191, -8.382485030473006],
              [-37.609190166541026, -8.382807068225491],
              [-37.609302580106103, -8.382936053772992],
              [-37.60951167782099, -8.383097482145866],
              [-37.609769574486137, -8.383146589286206],
              [-37.60977756340283, -8.383147160004878],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0218000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOAQUIM I',
        municipio: 'IBIMIRIM',
        area_hecta: '336.6628',
        capacidade: 30.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '12/02/2007',
        forma_obte: 'Doação',
        data_obten: '12/02/2007',
        area_calc_: 363.7391,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.567038576795689, -8.402024830144649],
              [-37.56703853998286, -8.40202487213123],
              [-37.567024901037861, -8.403118173433421],
              [-37.567024940539618, -8.403118158852838],
              [-37.567038576795689, -8.402024830144649],
            ],
          ],
          [
            [
              [-37.569189836599293, -8.41171026004246],
              [-37.569056762967747, -8.411585181515036],
              [-37.569157218471666, -8.411580211823535],
              [-37.569510231846586, -8.411423520223568],
              [-37.569757800138952, -8.411196092678377],
              [-37.569987273584637, -8.411074036923484],
              [-37.570252265249209, -8.410899391228309],
              [-37.570393808217247, -8.410759382280308],
              [-37.570570301289941, -8.410672219189538],
              [-37.570799993938301, -8.410514987577601],
              [-37.570941509717152, -8.410357525873248],
              [-37.570924504244964, -8.410190535926722],
              [-37.570801570555716, -8.410084741869321],
              [-37.570908174009254, -8.409839258294337],
              [-37.571155611615445, -8.409647005169047],
              [-37.571437768477153, -8.409595401458457],
              [-37.57152630426048, -8.409472742279473],
              [-37.571756124295987, -8.409280424710532],
              [-37.571914742142411, -8.409263457729658],
              [-37.572126366219884, -8.409229140244166],
              [-37.57217973179521, -8.409088810369763],
              [-37.572550423572494, -8.408914546344848],
              [-37.572850132748805, -8.40888054803424],
              [-37.573132482183894, -8.408776134280931],
              [-37.573309616121918, -8.408513362689719],
              [-37.573328324802979, -8.408214930822416],
              [-37.573311606920555, -8.407968999032494],
              [-37.573171762439628, -8.407669992208557],
              [-37.573120131749036, -8.407336129150623],
              [-37.573103734720561, -8.407002484291089],
              [-37.572963093736085, -8.406896536322972],
              [-37.572981802393599, -8.406598104479745],
              [-37.572983279299144, -8.406194262623981],
              [-37.572807431061129, -8.406105820252574],
              [-37.572808844231801, -8.405719430606206],
              [-37.572827809748212, -8.405350737877304],
              [-37.57296938708339, -8.405175641910983],
              [-37.573163969889521, -8.40498319518508],
              [-37.573322714580264, -8.404931051666304],
              [-37.573657654438726, -8.404897179926722],
              [-37.573958002169327, -8.404687573139691],
              [-37.573959028722072, -8.404406619870636],
              [-37.574100952696959, -8.404161262746678],
              [-37.574242336774184, -8.404038794025739],
              [-37.574471574796554, -8.404004538572316],
              [-37.574683041080299, -8.403987761489905],
              [-37.574877494391401, -8.403830398929445],
              [-37.57510660421412, -8.40383122834367],
              [-37.575406181461176, -8.403832312660105],
              [-37.575635200529398, -8.403833141438287],
              [-37.575846693575535, -8.403833906674642],
              [-37.576241978935961, -8.403861379643834],
              [-37.576221687249152, -8.403673488984431],
              [-37.575949899956811, -8.40354735463324],
              [-37.575699546590641, -8.403296056047804],
              [-37.575532629169111, -8.403149321788947],
              [-37.575408092549139, -8.402835902739822],
              [-37.575199297930155, -8.402668218601354],
              [-37.575137387105656, -8.402438490426546],
              [-37.574886120905326, -8.402437580961596],
              [-37.574550658111569, -8.402540628973062],
              [-37.574278506032499, -8.402539643486529],
              [-37.574027149053236, -8.402538733133817],
              [-37.573671729184454, -8.402412294518532],
              [-37.573400477807596, -8.402140030447567],
              [-37.573065868400192, -8.402034465006803],
              [-37.572794693762958, -8.401741402678548],
              [-37.572460390100595, -8.401552373742339],
              [-37.572124698632635, -8.401718084817716],
              [-37.571788639898514, -8.402008945229401],
              [-37.571453023920128, -8.402153857678181],
              [-37.570971240308054, -8.402214684734357],
              [-37.570824676272998, -8.402214152723458],
              [-37.570719287096665, -8.402401586983062],
              [-37.57059276851615, -8.402630631316077],
              [-37.570236495353825, -8.40271280167212],
              [-37.569879763274081, -8.402920211337719],
              [-37.569502909790664, -8.402918842135055],
              [-37.568958439290178, -8.402937661491073],
              [-37.568644410550689, -8.402915721646449],
              [-37.568434471691639, -8.403060997705085],
              [-37.56812044328592, -8.40303896700512],
              [-37.567890766520371, -8.402871203529278],
              [-37.567681363316147, -8.402870441705602],
              [-37.567430097658615, -8.402869527436351],
              [-37.567303883123046, -8.40301519791536],
              [-37.567024941973692, -8.403118158791308],
              [-37.566998890814638, -8.40520675769387],
              [-37.570895719224971, -8.404487835448252],
              [-37.571267213077611, -8.407714443478433],
              [-37.566786241293961, -8.40945143176995],
              [-37.569189836599293, -8.41171026004246],
            ],
          ],
          [
            [
              [-37.611389507541617, -8.413046877975088],
              [-37.611856861414594, -8.41288566643183],
              [-37.612242152655561, -8.412817306015892],
              [-37.6126159444023, -8.412713908796926],
              [-37.612966521160146, -8.412575614327119],
              [-37.613223660330142, -8.412460230139432],
              [-37.613515844617446, -8.412321729184725],
              [-37.613878052941757, -8.412206714939069],
              [-37.614321810233371, -8.412091987676545],
              [-37.614730282432618, -8.412093426742445],
              [-37.614999127400957, -8.411954842340903],
              [-37.615233216088946, -8.41175798975112],
              [-37.615327685559869, -8.411444354245081],
              [-37.615387150893042, -8.411142260749463],
              [-37.615329502196353, -8.410932715558149],
              [-37.615120115214687, -8.410745966708561],
              [-37.614793787884572, -8.41062852640756],
              [-37.614583782031595, -8.410616121471847],
              [-37.614198740798557, -8.410614765053586],
              [-37.614152642012847, -8.410451831204579],
              [-37.614200723878355, -8.410056646884899],
              [-37.614317912332481, -8.409917528429679],
              [-37.614318614917416, -8.409719763600053],
              [-37.614097894376037, -8.40945158873606],
              [-37.613936012519019, -8.409032334111179],
              [-37.613831728950551, -8.408811049688167],
              [-37.613484523750621, -8.408717227326505],
              [-37.613228006751875, -8.408556174241385],
              [-37.612939091907378, -8.408491132334509],
              [-37.612674883401993, -8.408322455833012],
              [-37.612377216397661, -8.408216056861061],
              [-37.612195243078531, -8.408033382422333],
              [-37.611993813410919, -8.407908151815789],
              [-37.611830683370876, -8.407840478350064],
              [-37.611686850599916, -8.407734621664341],
              [-37.611639254776541, -8.407609933688365],
              [-37.611726252060755, -8.407456964355864],
              [-37.611832183031645, -8.407418996412039],
              [-37.612120541015919, -8.407410518638022],
              [-37.612630114431852, -8.40740273028047],
              [-37.612956942509101, -8.407403882526928],
              [-37.613139563052613, -8.407404526245289],
              [-37.613419885676976, -8.407306223632169],
              [-37.613628697282273, -8.407168603704479],
              [-37.613687486425064, -8.406903493412157],
              [-37.613433918746871, -8.406602738887036],
              [-37.613134292783506, -8.406255703377537],
              [-37.6129267114109, -8.406047347823893],
              [-37.612799886935733, -8.405908545123864],
              [-37.612522220913419, -8.405872932148604],
              [-37.612256005893691, -8.405860418637564],
              [-37.612070515071387, -8.405952092014045],
              [-37.61167136542403, -8.405910262555457],
              [-37.611221812472905, -8.405945842477839],
              [-37.610897309623056, -8.405980687722661],
              [-37.610585007197727, -8.405955621696986],
              [-37.610272795663278, -8.4059305557505],
              [-37.610141044713451, -8.405798426656194],
              [-37.610249924722147, -8.405595256728809],
              [-37.610418147876089, -8.40558391417866],
              [-37.610671015730873, -8.405417152560506],
              [-37.610947777509551, -8.405298402167018],
              [-37.611116298889591, -8.405203233144203],
              [-37.611189083776992, -8.405011962457149],
              [-37.61114262166933, -8.404568789358768],
              [-37.611143303206788, -8.404377264229764],
              [-37.610927638388773, -8.404220875783837],
              [-37.610616061924254, -8.403992258083957],
              [-37.610100595472396, -8.403667156599854],
              [-37.60993409083126, -8.403629673853477],
              [-37.609675025197504, -8.405154196163222],
              [-37.609034882149217, -8.408444341859552],
              [-37.604411018907761, -8.406131451703173],
              [-37.601985725292721, -8.406865529631656],
              [-37.598256698786095, -8.403463564383642],
              [-37.591883260887585, -8.402155386868301],
              [-37.586689128378367, -8.403206443490967],
              [-37.583631724299764, -8.401258317719428],
              [-37.583703923743329, -8.403684656097662],
              [-37.578330915133371, -8.402329767249373],
              [-37.577717968661553, -8.400131256277113],
              [-37.572213933585751, -8.398621914981167],
              [-37.567064518245843, -8.399944877783483],
              [-37.567038578229806, -8.402024830083304],
              [-37.567140492608807, -8.401908592484894],
              [-37.56745504162015, -8.401763697718712],
              [-37.56772758933942, -8.401681225271679],
              [-37.568082790110594, -8.401891222483403],
              [-37.568312007522771, -8.402184134922845],
              [-37.568627641892192, -8.401767962416086],
              [-37.569025853185416, -8.401665057232082],
              [-37.569277194458714, -8.401645172435865],
              [-37.569549436126067, -8.401646161604425],
              [-37.569905402599147, -8.401647454696542],
              [-37.570240392528298, -8.401648671294874],
              [-37.570429444573456, -8.401503227765968],
              [-37.57065996216626, -8.401441489183721],
              [-37.571015775723275, -8.40148455795898],
              [-37.571163026820791, -8.401297275459862],
              [-37.571373498520472, -8.401005869490419],
              [-37.571772166517086, -8.400777811924566],
              [-37.57217075804283, -8.400570551819124],
              [-37.572526647581896, -8.400592730751688],
              [-37.572819316908735, -8.400719033081389],
              [-37.572776539341994, -8.400969270586931],
              [-37.572768653942092, -8.401238443381377],
              [-37.573012763268558, -8.4013089569823],
              [-37.573169225013487, -8.401483505691559],
              [-37.573378210276964, -8.401623430246211],
              [-37.573604540350274, -8.401763327123907],
              [-37.573848105498314, -8.402007820058596],
              [-37.574074590206578, -8.402130355281994],
              [-37.574388913241229, -8.402096679123028],
              [-37.574633531635222, -8.402028026337277],
              [-37.574808636130911, -8.401872130853324],
              [-37.574965797726634, -8.401855247277357],
              [-37.575239439746582, -8.401895754269685],
              [-37.575591502586946, -8.401897028129856],
              [-37.575991372217793, -8.401687869672241],
              [-37.576178912728572, -8.40178214005414],
              [-37.576107762996877, -8.401969067031462],
              [-37.575708319983086, -8.402061214349214],
              [-37.575636743377835, -8.402365061979602],
              [-37.575800508751655, -8.402529418047841],
              [-37.575988044194894, -8.40260026791713],
              [-37.576223215665848, -8.4025075263467],
              [-37.576528928469536, -8.402344868167532],
              [-37.576763673120631, -8.402369137496006],
              [-37.576927007069862, -8.402627083782837],
              [-37.5768090003547, -8.40281384153055],
              [-37.576573572896599, -8.402976754025842],
              [-37.576478994791671, -8.403163505913266],
              [-37.576642419585582, -8.403421452660613],
              [-37.576782069312671, -8.403749484925394],
              [-37.576989018815183, -8.403825830263866],
              [-37.578095946223591, -8.403987445048152],
              [-37.578886119222283, -8.404226856402452],
              [-37.579675433224949, -8.404702640218332],
              [-37.580544417246429, -8.405021095240549],
              [-37.581650204784211, -8.405498107403583],
              [-37.582677382532239, -8.405817128358315],
              [-37.5836263260245, -8.405899307104391],
              [-37.584259176220804, -8.405901584271472],
              [-37.584653598062879, -8.406218323662806],
              [-37.585467126189108, -8.406481047027082],
              [-37.586172080163273, -8.406583955028491],
              [-37.586642413373532, -8.406552186402847],
              [-37.586978711883638, -8.406419561319129],
              [-37.587147224825401, -8.406252784913082],
              [-37.586980408721594, -8.405950971692125],
              [-37.586544281973104, -8.405815482282756],
              [-37.585805729682363, -8.405712454421266],
              [-37.585605315439672, -8.405410520083839],
              [-37.585607620318235, -8.404774551525753],
              [-37.586077587289303, -8.404843246921587],
              [-37.586680892008438, -8.405180086225883],
              [-37.587216968626628, -8.405550141647735],
              [-37.587584654868991, -8.406053515456799],
              [-37.587986940748586, -8.406255798825816],
              [-37.588523383298707, -8.40652547952363],
              [-37.588959632846141, -8.406627509181494],
              [-37.589328562859976, -8.406762664870545],
              [-37.589831770416282, -8.406931850159761],
              [-37.590403266315683, -8.406799974399188],
              [-37.590638977124115, -8.406633527424271],
              [-37.590908680476218, -8.406333188105743],
              [-37.591211227581333, -8.406267354806657],
              [-37.591345021691019, -8.40643521551168],
              [-37.591579765827504, -8.406536430553238],
              [-37.592016379266781, -8.406537993003257],
              [-37.592285115354258, -8.406505496174745],
              [-37.592420600409625, -8.406204765878805],
              [-37.592589352649398, -8.405971071038167],
              [-37.592590318334921, -8.405703317800818],
              [-37.592255711651177, -8.405367447820289],
              [-37.59198809333482, -8.405065185144517],
              [-37.591753953838364, -8.404796590836721],
              [-37.591721199788303, -8.404562175305848],
              [-37.591587375751736, -8.404427863383351],
              [-37.59155474253263, -8.404159990016737],
              [-37.5917903307964, -8.404026909402393],
              [-37.592126143239966, -8.404028110644395],
              [-37.592461925887726, -8.404062769777441],
              [-37.592628745729151, -8.404364671695621],
              [-37.592728216708458, -8.404733158930579],
              [-37.593029223900857, -8.405068908385987],
              [-37.5933304426852, -8.405371290579362],
              [-37.593698770597385, -8.40567382199691],
              [-37.593866286232988, -8.40580825376068],
              [-37.594168380383508, -8.405842791334049],
              [-37.594336980957422, -8.40567610204614],
              [-37.594304948753482, -8.405240848784327],
              [-37.594306274705581, -8.404872721705891],
              [-37.59460906178613, -8.404739879316759],
              [-37.5949109143735, -8.404841331969422],
              [-37.59507758643084, -8.40521014930545],
              [-37.595177089543448, -8.405545178108863],
              [-37.595478431319847, -8.405814010665315],
              [-37.59578061663268, -8.40584854734897],
              [-37.596251551846997, -8.405649477436697],
              [-37.596588088595773, -8.405449837219203],
              [-37.596958315424125, -8.40525031676018],
              [-37.597227291280973, -8.405150900501269],
              [-37.597361449098891, -8.405218295342342],
              [-37.597394086352963, -8.405486168884064],
              [-37.597225125826832, -8.405753323894004],
              [-37.596955427419651, -8.406053577991541],
              [-37.597189691904596, -8.406288711983493],
              [-37.597524544707397, -8.406557662738434],
              [-37.597859969724773, -8.406692781901132],
              [-37.598363183033165, -8.406861866443959],
              [-37.598798716070583, -8.407164723731825],
              [-37.599100091518167, -8.407400005415123],
              [-37.599468516941492, -8.407702623200066],
              [-37.599669664855142, -8.407803714276124],
              [-37.600073041552847, -8.407704774568149],
              [-37.600105800688318, -8.407939190235671],
              [-37.599903211338123, -8.408239685215607],
              [-37.599868890637026, -8.408440403782144],
              [-37.600002238515081, -8.408708635795865],
              [-37.600270106741156, -8.408943797628954],
              [-37.60077344403885, -8.409079512180092],
              [-37.60097471305162, -8.40914714478714],
              [-37.601277142029957, -8.409114761804263],
              [-37.601748439857133, -8.408815221168558],
              [-37.602084108871061, -8.408883330879474],
              [-37.602352127314724, -8.409051665649347],
              [-37.602452327599089, -8.409219404120513],
              [-37.602720557437564, -8.409354190604851],
              [-37.602888946082381, -8.409220955000443],
              [-37.602923864875763, -8.408852855534144],
              [-37.602924823805061, -8.408585101146146],
              [-37.602992742942732, -8.408384501369289],
              [-37.603362520047199, -8.408285438941805],
              [-37.60376577720546, -8.408219953384979],
              [-37.604102164986195, -8.408087222845424],
              [-37.604236473903946, -8.408087699339946],
              [-37.604336914616965, -8.408188430971343],
              [-37.60433583667794, -8.408489733969541],
              [-37.604536747264397, -8.408657738861807],
              [-37.604939406234543, -8.408759632680212],
              [-37.605308915465081, -8.408760942811371],
              [-37.605509946279874, -8.408895489263688],
              [-37.605408308577871, -8.409129428586185],
              [-37.605239800702329, -8.409296213871524],
              [-37.605205722093707, -8.40942992684719],
              [-37.605238484359127, -8.409664342595464],
              [-37.605304607467232, -8.409965793533122],
              [-37.605505399260117, -8.410167346567338],
              [-37.606008860577916, -8.410269596928201],
              [-37.606259064180669, -8.410189007680531],
              [-37.606351923939833, -8.410149729110515],
              [-37.606590906252748, -8.410058067618261],
              [-37.606869289693009, -8.410045851132525],
              [-37.606988190897667, -8.410165185450834],
              [-37.607026550974005, -8.410561578147441],
              [-37.606893258746773, -8.410759234481699],
              [-37.606694095041121, -8.410864239686395],
              [-37.606626906184204, -8.411114940174656],
              [-37.606864476733804, -8.41139321577443],
              [-37.60698342544098, -8.411499347787901],
              [-37.607009140351764, -8.411723972344596],
              [-37.607247274192467, -8.411870134041671],
              [-37.607405860691941, -8.411989608874633],
              [-37.607750161005548, -8.412083245682563],
              [-37.608081906483235, -8.412005204787567],
              [-37.608109696512088, -8.411648653764047],
              [-37.608190850523229, -8.411199783374363],
              [-37.608429836679754, -8.411081715735214],
              [-37.608615785094074, -8.410989955814165],
              [-37.608815136893426, -8.410832140059481],
              [-37.609040342563439, -8.410885746735074],
              [-37.609145707123268, -8.411057842845457],
              [-37.609237762842803, -8.411269499512564],
              [-37.609436027852027, -8.411441924078904],
              [-37.609661095799879, -8.41150882295481],
              [-37.60976678725671, -8.411614816955984],
              [-37.609939383111552, -8.411549414296889],
              [-37.610606398112118, -8.411321450062607],
              [-37.610780674855853, -8.411421446550582],
              [-37.610854529557287, -8.411695163038024],
              [-37.610903057389244, -8.412068080497795],
              [-37.610901640608695, -8.412465779788372],
              [-37.611063594286179, -8.412813144940907],
              [-37.611156286442693, -8.412999483654408],
              [-37.611389507541617, -8.413046877975088],
            ],
          ],
          [
            [
              [-37.605006736394181, -8.418849852118115],
              [-37.605015070594995, -8.418839541376846],
              [-37.605014946811984, -8.418839482022515],
              [-37.607343459563438, -8.415958919197204],
              [-37.607071053816796, -8.415821949854626],
              [-37.60671847765888, -8.415679993936608],
              [-37.606435960007168, -8.415678992487134],
              [-37.605974763469966, -8.416275540076875],
              [-37.605585350826729, -8.416555661906038],
              [-37.605338158795583, -8.416554784886142],
              [-37.605091974316906, -8.416272408265771],
              [-37.605093107701222, -8.415955732688406],
              [-37.604987381326147, -8.415885004344428],
              [-37.604704954547387, -8.415884001949202],
              [-37.60431658482365, -8.415847446643836],
              [-37.604317593146625, -8.415565856854847],
              [-37.60431860111084, -8.415284357488641],
              [-37.604319734988366, -8.414967682000141],
              [-37.604320490990077, -8.414756534862557],
              [-37.604214981844066, -8.414650630587685],
              [-37.603791252358263, -8.414649125983038],
              [-37.603438083971945, -8.414647871582172],
              [-37.603155658276741, -8.414646868220448],
              [-37.602942820001999, -8.414892438193927],
              [-37.602659889799121, -8.415032138820502],
              [-37.602482250522279, -8.415313100537277],
              [-37.602305459255767, -8.415382825075339],
              [-37.602094982600249, -8.41499495395486],
              [-37.602095992099869, -8.414713454848533],
              [-37.602273792393319, -8.414362231226105],
              [-37.602416862035497, -8.413870087425991],
              [-37.602701017499932, -8.413413621444517],
              [-37.6029491251401, -8.413133000242565],
              [-37.602950511912191, -8.412745972702066],
              [-37.602599327818538, -8.412216895845317],
              [-37.602176988980943, -8.411828272074779],
              [-37.601789345329678, -8.411615834856294],
              [-37.60140123318439, -8.411508834814136],
              [-37.601259011879463, -8.411789831664461],
              [-37.601363509436084, -8.412177325975152],
              [-37.601150889128235, -8.412387628865105],
              [-37.60072690986668, -8.412456563925211],
              [-37.600585697972377, -8.412456061425971],
              [-37.600478710557255, -8.412737183219365],
              [-37.600407265765114, -8.41298325495285],
              [-37.599877435324949, -8.413016545260758],
              [-37.599418382694338, -8.413014910449366],
              [-37.599100794930628, -8.412943426132577],
              [-37.59896046807512, -8.412696600305148],
              [-37.598749237259526, -8.412519874783817],
              [-37.598643603958074, -8.412449145385539],
              [-37.598361089624831, -8.41244813851764],
              [-37.598149226600732, -8.412447383314523],
              [-37.597901658494258, -8.412552030123463],
              [-37.597760229422157, -8.412586702303907],
              [-37.597654722839664, -8.412480796644564],
              [-37.597655608730989, -8.412234474061464],
              [-37.597762380552211, -8.411988529041338],
              [-37.598009948298667, -8.41188388226456],
              [-37.598257642704382, -8.41174396885021],
              [-37.598504830935305, -8.41174484982216],
              [-37.598928682689667, -8.411711183563392],
              [-37.599247027890129, -8.411571521141369],
              [-37.599353545521033, -8.411396018013034],
              [-37.599354177547035, -8.411220047336727],
              [-37.599142694171064, -8.41111376463704],
              [-37.598966157329542, -8.411113135812819],
              [-37.598683264729353, -8.411217657455216],
              [-37.598435823969254, -8.411287128722465],
              [-37.598082370559055, -8.411391488853276],
              [-37.597799730578174, -8.41142565773408],
              [-37.597517181392035, -8.411459826727359],
              [-37.597199468914916, -8.411423517151192],
              [-37.596846431016871, -8.411387081297621],
              [-37.596422833022707, -8.411350393329371],
              [-37.596246296280547, -8.411349763266951],
              [-37.596068999719996, -8.411560189125021],
              [-37.59589233592768, -8.411594825311958],
              [-37.595573736903731, -8.411804746373951],
              [-37.595397453428667, -8.411733763918248],
              [-37.595221550507134, -8.411557163006679],
              [-37.595364282484319, -8.411135465078276],
              [-37.595471653100567, -8.410748726784556],
              [-37.595614764691923, -8.410221410243299],
              [-37.595721409974004, -8.410010732297895],
              [-37.595862965093467, -8.40994079429661],
              [-37.595899303825504, -8.409659421964442],
              [-37.59575901584887, -8.409377419157824],
              [-37.595441903219594, -8.409200314382961],
              [-37.595195223654841, -8.409058727787869],
              [-37.594842695517073, -8.408881496142961],
              [-37.594454299262644, -8.408880108680394],
              [-37.594207113277939, -8.40887922546171],
              [-37.59392422136316, -8.408983834174991],
              [-37.593535444670124, -8.409087973686631],
              [-37.593322695621112, -8.409333538347521],
              [-37.593074874755636, -8.409508624588231],
              [-37.592756874934231, -8.409577839932648],
              [-37.592509815619117, -8.40954177962541],
              [-37.592227558048585, -8.40947041680819],
              [-37.592051403712475, -8.40936416674862],
              [-37.591557158682392, -8.409327221100659],
              [-37.59113331023498, -8.409360879738131],
              [-37.590709080189193, -8.40950015609978],
              [-37.590355409952735, -8.409639594384553],
              [-37.589825930650001, -8.409602520265675],
              [-37.589437952537587, -8.409460423796173],
              [-37.589085009406574, -8.409423891281286],
              [-37.58880300747596, -8.409282174335859],
              [-37.588768827947007, -8.408965374145769],
              [-37.5888046624721, -8.408824706861873],
              [-37.588981451978661, -8.40875498828105],
              [-37.589369938000949, -8.4087563814508],
              [-37.589687682929387, -8.40875752064707],
              [-37.589793658455605, -8.408757900539221],
              [-37.590075315098758, -8.408970058702769],
              [-37.590428476313434, -8.408971324257287],
              [-37.590676551875973, -8.40872588797345],
              [-37.590889174897924, -8.408515500883418],
              [-37.59113699581065, -8.408340416069262],
              [-37.591172702065208, -8.408235014772808],
              [-37.590925516839221, -8.40823412956],
              [-37.590677823333813, -8.408373947887087],
              [-37.590465200306745, -8.408584334858276],
              [-37.590182562642553, -8.408618498493418],
              [-37.589900306415991, -8.40854713418293],
              [-37.589618522983208, -8.408370151863366],
              [-37.589619667845994, -8.408053478266305],
              [-37.589514710250114, -8.407771600678176],
              [-37.589338557218738, -8.407665440069541],
              [-37.589091372549589, -8.407664553764203],
              [-37.588702851226017, -8.407698336719632],
              [-37.588384889211284, -8.407732372434667],
              [-37.588031056064786, -8.407942251289603],
              [-37.587641934347964, -8.408116736322333],
              [-37.587288737435642, -8.408150735019689],
              [-37.58725252033112, -8.408396929704654],
              [-37.586898558987734, -8.408641892772829],
              [-37.586686608268906, -8.408641131410974],
              [-37.586511093465319, -8.408358999875713],
              [-37.586299743630086, -8.408217535228419],
              [-37.585981617109226, -8.408321920926102],
              [-37.585945526672653, -8.408532939498192],
              [-37.585802950738696, -8.408884280515249],
              [-37.585449753235217, -8.408918277418106],
              [-37.585096556050615, -8.408952183567175],
              [-37.58474250197618, -8.409197234480118],
              [-37.584495189180913, -8.409231520955117],
              [-37.584106794316355, -8.409230123221045],
              [-37.58403716588117, -8.408948371931862],
              [-37.584221943479491, -8.40857945990733],
              [-37.584399243922192, -8.408369040191522],
              [-37.584611741916937, -8.408193832850893],
              [-37.584824202936403, -8.408053801524021],
              [-37.58507193446944, -8.407878811029047],
              [-37.585319246396807, -8.4078445240386],
              [-37.585495999065579, -8.407809983222981],
              [-37.58539053450037, -8.407668808412701],
              [-37.585037466044888, -8.40766753885825],
              [-37.584755339634221, -8.407560995286389],
              [-37.584508538711781, -8.407454488193338],
              [-37.583978845733228, -8.407452582277305],
              [-37.583696683002529, -8.407381214164687],
              [-37.583449754544837, -8.407309972714785],
              [-37.582920062005989, -8.407308065406268],
              [-37.582567505749502, -8.407166000055199],
              [-37.582144592655382, -8.406918152147496],
              [-37.581827617701663, -8.406705861933647],
              [-37.581545365330769, -8.40663449210269],
              [-37.581263240993074, -8.406527946302727],
              [-37.580980823880253, -8.406526927990351],
              [-37.580662991159016, -8.406525781737418],
              [-37.580275495858999, -8.406278059862791],
              [-37.580452669243989, -8.406102727427429],
              [-37.58031222906871, -8.405891072986726],
              [-37.580065558471595, -8.405749478133531],
              [-37.579501568753649, -8.40546594262902],
              [-37.579254899108214, -8.405324256932099],
              [-37.578691513968998, -8.404900017800669],
              [-37.578198047805827, -8.404651911640117],
              [-37.57777497518213, -8.404474411714267],
              [-37.577068883880578, -8.404436683535542],
              [-37.576397989199975, -8.404434257589193],
              [-37.575656445583739, -8.40443157484694],
              [-37.575161569643413, -8.404570488350254],
              [-37.574948813626648, -8.404816041618577],
              [-37.574524328974078, -8.405025651782029],
              [-37.574099586875285, -8.405305612760541],
              [-37.573921254294525, -8.40579761340272],
              [-37.573602265600158, -8.406113132975511],
              [-37.573424703844196, -8.406393988801765],
              [-37.573423159914547, -8.406816277608479],
              [-37.57342161590455, -8.407238566405919],
              [-37.573597119890842, -8.407520702140442],
              [-37.573701679432936, -8.407908109053317],
              [-37.573877183862621, -8.40819024473771],
              [-37.574052688525121, -8.408472380382339],
              [-37.573804733045975, -8.408682628807878],
              [-37.573449735199617, -8.409209164444722],
              [-37.57298966247852, -8.409488995171685],
              [-37.572671957170982, -8.409452666416401],
              [-37.572283049072873, -8.409591959535295],
              [-37.571963926536114, -8.409942743040908],
              [-37.571574630971909, -8.410187652642378],
              [-37.571431746375396, -8.410644513370571],
              [-37.571041714207517, -8.411065390451524],
              [-37.570351205097467, -8.41155552698133],
              [-37.569890353335836, -8.412046497235082],
              [-37.56961013257672, -8.412105238086186],
              [-37.571041238690327, -8.41345012831602],
              [-37.576158596110908, -8.409142567398007],
              [-37.575457173298581, -8.407376608432862],
              [-37.575889592554176, -8.406834887384585],
              [-37.583901050443373, -8.411121486551126],
              [-37.58918445999042, -8.410550343443143],
              [-37.590360024486934, -8.411503506421111],
              [-37.593423148242842, -8.411343836321418],
              [-37.593073394899662, -8.412738066846741],
              [-37.597351923602709, -8.41438232049121],
              [-37.599521510300974, -8.414718579857299],
              [-37.601323608402524, -8.417097014123675],
              [-37.605006736394181, -8.418849852118115],
            ],
          ],
          [
            [
              [-37.56918983516546, -8.411710260105156],
              [-37.569610074003158, -8.41210525012678],
              [-37.569610131142888, -8.412105238148985],
              [-37.56918983516546, -8.411710260105156],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0304000',
        uf: 'PE',
        nome_proje: 'PA SANTA ANGELA',
        municipio: 'AGUAS BELAS',
        area_hecta: '823.4300',
        capacidade: 34.0,
        num_famili: 33.0,
        fase: 4.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '08/11/2002',
        area_calc_: 823.1942,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.075540504051986, -8.937623303131131],
              [-37.075387456682122, -8.937803314062128],
              [-37.075345794185921, -8.937858784989917],
              [-37.072186255366546, -8.942065433340963],
              [-37.072067965905333, -8.942498659522803],
              [-37.072055028821431, -8.9425460556561],
              [-37.071768915924991, -8.943593484178177],
              [-37.07149818010469, -8.944590229196836],
              [-37.070502828112978, -8.945347410136188],
              [-37.070138816251415, -8.945170675813378],
              [-37.069866513716121, -8.945551116783868],
              [-37.069594210572966, -8.945931557426974],
              [-37.069366159415125, -8.94612690360521],
              [-37.067392434419467, -8.946483991578139],
              [-37.065340579741871, -8.946855202488095],
              [-37.065251131783192, -8.946871384662609],
              [-37.064794453104966, -8.946954002766208],
              [-37.06338410064582, -8.947827825506264],
              [-37.061380958992686, -8.949068754779038],
              [-37.060749258496337, -8.949460120531185],
              [-37.059245655778597, -8.950343410995655],
              [-37.057165826669547, -8.951565186403965],
              [-37.054990694289941, -8.952838452054245],
              [-37.052754241648159, -8.954139584719503],
              [-37.052700692737105, -8.954170738318115],
              [-37.05204573886391, -8.954439219107231],
              [-37.050266105734238, -8.955043761648799],
              [-37.047813422652524, -8.955876921208292],
              [-37.045409579146209, -8.956693469500369],
              [-37.04500851463272, -8.956829702880098],
              [-37.043057059456203, -8.95750475155538],
              [-37.04154687500948, -8.958029586014753],
              [-37.040509111595817, -8.958377440002545],
              [-37.039286927313867, -8.958787106204898],
              [-37.038156895218911, -8.959178159392044],
              [-37.035942761868242, -8.959941080776931],
              [-37.035118018263745, -8.960223778621069],
              [-37.034691592173793, -8.96010796993083],
              [-37.033737074300944, -8.960381767082204],
              [-37.033693394370765, -8.960394296308968],
              [-37.034106960661511, -8.961768773781801],
              [-37.035360131394796, -8.965232078199588],
              [-37.035622291161779, -8.965878969341922],
              [-37.035656572111051, -8.965964045982071],
              [-37.036983968809196, -8.969258084758236],
              [-37.037242273123191, -8.969915991520773],
              [-37.037812580744053, -8.971368571114851],
              [-37.040578075808931, -8.970466469072507],
              [-37.041125574692288, -8.970287872238574],
              [-37.042760907410489, -8.969754412383434],
              [-37.045041637754387, -8.969010402501109],
              [-37.046960891654585, -8.968359934247104],
              [-37.048858120461688, -8.967687993768697],
              [-37.050793726595998, -8.967002447731042],
              [-37.052737617641355, -8.966313953669985],
              [-37.054574854509909, -8.965663222133056],
              [-37.056391293934333, -8.965019844793204],
              [-37.05694646681652, -8.964823201848947],
              [-37.058456671301059, -8.964231545716288],
              [-37.061156698973093, -8.963173728234484],
              [-37.063598255473813, -8.962217150687458],
              [-37.066356905102126, -8.961168149718851],
              [-37.068958317761172, -8.960180022202904],
              [-37.069953076833116, -8.959802163550833],
              [-37.072911664338051, -8.958678323862184],
              [-37.072979304138208, -8.958649286388686],
              [-37.076706374531305, -8.956948591484966],
              [-37.079788874742469, -8.955656896782147],
              [-37.08091076870074, -8.955195720199919],
              [-37.083558645120299, -8.954134192566613],
              [-37.086328070640164, -8.953023907230675],
              [-37.0899892909285, -8.951256899356503],
              [-37.090704683490713, -8.950896044818542],
              [-37.091216941087055, -8.950637548826545],
              [-37.091248535722436, -8.950471911827513],
              [-37.091326848449242, -8.950378838396272],
              [-37.091423345088842, -8.950285768269504],
              [-37.091508805370999, -8.950181973615365],
              [-37.091608457590233, -8.950111430142288],
              [-37.091736253223822, -8.950014001046283],
              [-37.09192031106047, -8.949937293006048],
              [-37.091941922635833, -8.94985531802871],
              [-37.091943794001246, -8.949717817129946],
              [-37.091940961825664, -8.949690778773578],
              [-37.091960553418218, -8.949595142458177],
              [-37.091952189100176, -8.949461885825817],
              [-37.091914373953557, -8.949192428276866],
              [-37.091765975668743, -8.949089007442769],
              [-37.091715752156361, -8.949063122269633],
              [-37.091658783973507, -8.948985985760352],
              [-37.091631392011465, -8.948953525365321],
              [-37.091575015536428, -8.948904823783872],
              [-37.091458011144127, -8.948781000887477],
              [-37.091377268836759, -8.948764673746227],
              [-37.091275640763271, -8.948673656105372],
              [-37.091149936390586, -8.948701394855114],
              [-37.091019423565314, -8.948639970996865],
              [-37.090899143961323, -8.948687581568674],
              [-37.090794487448491, -8.948762302470222],
              [-37.090686569380118, -8.948767973430138],
              [-37.090589547114874, -8.948816120697206],
              [-37.090530457670397, -8.948919147101748],
              [-37.090468288179345, -8.948950286909644],
              [-37.090451963639495, -8.949047024764177],
              [-37.090343370189721, -8.949018982142055],
              [-37.090220350853969, -8.949037422834989],
              [-37.09006810972074, -8.949010601486096],
              [-37.089916310217419, -8.94895141795217],
              [-37.089820332497567, -8.94887534567445],
              [-37.089733861365005, -8.948824364148713],
              [-37.089649385126414, -8.948722134197501],
              [-37.08960851996504, -8.948617064034341],
              [-37.089521096359384, -8.948356514069488],
              [-37.089425302740594, -8.948192932248686],
              [-37.089374440385988, -8.94790410238236],
              [-37.088949816423728, -8.948105800334854],
              [-37.087747953221267, -8.948676792069055],
              [-37.087740147177747, -8.94870911612648],
              [-37.084767494688585, -8.950160996460117],
              [-37.082342244358571, -8.946415161928263],
              [-37.080817834705392, -8.943904190699891],
              [-37.079409084603427, -8.941466105206368],
              [-37.079372280034704, -8.941402419033015],
              [-37.077577745077363, -8.937762695846182],
              [-37.075540504051986, -8.937623303131131],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0210000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DA CONCEIÇÃO I',
        municipio: 'IBIMIRIM',
        area_hecta: '171.4201',
        capacidade: 25.0,
        num_famili: 22.0,
        fase: 3.0,
        data_de_cr: '13/12/2006',
        forma_obte: 'Doação',
        data_obten: '13/12/2006',
        area_calc_: 171.3301,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.629485226247795, -8.422823445207921],
              [-37.629803235925699, -8.422754110451214],
              [-37.630015726124235, -8.422578967062474],
              [-37.630122945173241, -8.422227390941373],
              [-37.630124430189625, -8.421805182627381],
              [-37.629984574827766, -8.421417567903427],
              [-37.629773108252486, -8.421276122690658],
              [-37.629809796934353, -8.42088912368895],
              [-37.629704773644008, -8.420642428528705],
              [-37.629140468743998, -8.420429308008542],
              [-37.628717223803456, -8.420287123365643],
              [-37.628329306344014, -8.420144971220752],
              [-37.627834788763138, -8.420178511784966],
              [-37.627587840271254, -8.420107205045753],
              [-37.627271568682872, -8.41968388711644],
              [-37.627060352415597, -8.419471997412348],
              [-37.626848606969681, -8.419436080608692],
              [-37.626141940100723, -8.419574317893973],
              [-37.62561160042079, -8.41974843793593],
              [-37.625400475986019, -8.419536547796961],
              [-37.624941534847316, -8.419499765123467],
              [-37.624623685566256, -8.419498652669157],
              [-37.624269854849828, -8.419708565466761],
              [-37.624127429673543, -8.420024748757202],
              [-37.623774344177541, -8.420023512155577],
              [-37.623775587153879, -8.419671657724329],
              [-37.623777700505379, -8.419073387620145],
              [-37.623779440325976, -8.418580827561136],
              [-37.623604017228637, -8.418263440790147],
              [-37.62353535292479, -8.417700190067848],
              [-37.623642575162471, -8.417348706782075],
              [-37.623890887878154, -8.417032894284498],
              [-37.623997770318624, -8.416751763309595],
              [-37.623715995475131, -8.416539625292389],
              [-37.623221698464747, -8.416537894218845],
              [-37.622725942977091, -8.416923192927717],
              [-37.622512582615464, -8.417344747950262],
              [-37.622052400669091, -8.417659816575982],
              [-37.621733682023816, -8.417905027070619],
              [-37.621309945842697, -8.417903540773702],
              [-37.621204339806205, -8.417797639871651],
              [-37.621275988734304, -8.417516386376857],
              [-37.621700844869203, -8.417201104421245],
              [-37.621736668928399, -8.417060522804741],
              [-37.621454895383494, -8.416848383331468],
              [-37.620926019252074, -8.416635376795019],
              [-37.620432254479397, -8.416457760092458],
              [-37.619725626550654, -8.416560809694255],
              [-37.619266815107665, -8.416488844697403],
              [-37.61887890616935, -8.416346684357096],
              [-37.618703238476812, -8.416099739259682],
              [-37.618774673154824, -8.415853662452367],
              [-37.619022614274286, -8.415643382502266],
              [-37.6194105226297, -8.415785542601061],
              [-37.619729115913252, -8.415575510269401],
              [-37.619977430035568, -8.415259700546365],
              [-37.620260322550223, -8.41515516314715],
              [-37.620613652392073, -8.415085959043378],
              [-37.621073333791834, -8.4149116876645],
              [-37.621462735972671, -8.414631548392583],
              [-37.621958489486225, -8.41424616053758],
              [-37.622278323769542, -8.413684271383778],
              [-37.622562118464252, -8.413298230140018],
              [-37.622740240615336, -8.412876551232214],
              [-37.622742229259728, -8.41231354800904],
              [-37.622249711935368, -8.411783989526175],
              [-37.621827101498553, -8.411465826954936],
              [-37.621333840363839, -8.411147325955545],
              [-37.620911107051185, -8.410864338995548],
              [-37.620558561619063, -8.410687218680005],
              [-37.61978138358193, -8.410790022630238],
              [-37.619145417817727, -8.410893320772573],
              [-37.618684369436188, -8.411454801580325],
              [-37.618470760085799, -8.411946616544011],
              [-37.618468391137057, -8.412615238191421],
              [-37.618996761917515, -8.412968952347045],
              [-37.619242706730162, -8.413321764716393],
              [-37.619241336032189, -8.413708794931154],
              [-37.619239964948576, -8.414095915565596],
              [-37.618991526583514, -8.414446901202988],
              [-37.618567046395292, -8.414656560979017],
              [-37.618178391065669, -8.414725548657255],
              [-37.617435816133032, -8.4150044423961],
              [-37.617046910504129, -8.415143871910866],
              [-37.617045412516603, -8.415566078037568],
              [-37.617044038945366, -8.415953198275258],
              [-37.616831423313869, -8.416163510650549],
              [-37.616443640509353, -8.41598626222898],
              [-37.616233307261105, -8.415528043440045],
              [-37.616022690246517, -8.415175444235668],
              [-37.615599208244525, -8.415103509261003],
              [-37.61517435081069, -8.415418784223517],
              [-37.614643514488627, -8.415733594704895],
              [-37.614219781941259, -8.415732101147167],
              [-37.613973590535117, -8.415449729118057],
              [-37.61397534230418, -8.414957080586015],
              [-37.613799553470976, -8.414745310144674],
              [-37.613375947276978, -8.414708639597002],
              [-37.61309292859157, -8.414848438473797],
              [-37.612810501587276, -8.414847442022966],
              [-37.612528484944811, -8.414705649654344],
              [-37.612281293354613, -8.414704777190471],
              [-37.612245215594449, -8.414915800346661],
              [-37.612279539498232, -8.415197425373929],
              [-37.61234931477636, -8.415443998865911],
              [-37.612207133246876, -8.415689824119553],
              [-37.612170052900403, -8.416182347538065],
              [-37.612133063493822, -8.416674780819188],
              [-37.612344303504969, -8.41685150046747],
              [-37.612414363884866, -8.416992544953885],
              [-37.612095356030629, -8.417343275758032],
              [-37.611812209657856, -8.417518249535489],
              [-37.611565518008483, -8.417376581014128],
              [-37.611248550084504, -8.417129134068054],
              [-37.611072511086554, -8.416987805370489],
              [-37.610825318202494, -8.416986931756265],
              [-37.610577247112609, -8.417232381872797],
              [-37.610152384222133, -8.417547559909071],
              [-37.609904312362943, -8.417793009466982],
              [-37.609761842636068, -8.418144362322044],
              [-37.609407505276906, -8.418494965404326],
              [-37.609089910050123, -8.418423488152373],
              [-37.608701627523352, -8.418386937028346],
              [-37.608066277335254, -8.418314333873919],
              [-37.607996127684387, -8.418173288516854],
              [-37.608315265116758, -8.417787385592503],
              [-37.60870564787588, -8.417260937590948],
              [-37.608777088904553, -8.417014863673518],
              [-37.608389561821056, -8.416767164987723],
              [-37.607825620963432, -8.416483664581605],
              [-37.607564555894193, -8.416070025057664],
              [-37.607343458128227, -8.415958919262222],
              [-37.605015069159982, -8.418839541442141],
              [-37.60901908939649, -8.420759453706074],
              [-37.602842969447664, -8.424704709238563],
              [-37.607310002674502, -8.42401055593019],
              [-37.609120251128651, -8.423729237342263],
              [-37.610528518287296, -8.423510381767535],
              [-37.610583400579046, -8.423451164482231],
              [-37.610677624258699, -8.423081374415633],
              [-37.611018527300836, -8.422959235809687],
              [-37.611451893453818, -8.42296076812532],
              [-37.611761828877384, -8.422838428909806],
              [-37.612102731508138, -8.422716289271275],
              [-37.612442974768335, -8.422779163592161],
              [-37.612536207450148, -8.422686984630715],
              [-37.612600249670209, -8.422070307878872],
              [-37.612848998601088, -8.421762734859417],
              [-37.61303526257506, -8.421609211998627],
              [-37.613005393339421, -8.42130065498794],
              [-37.612851634856149, -8.421022496664541],
              [-37.612636050990311, -8.420713374308143],
              [-37.612636600432658, -8.420559105276338],
              [-37.612822204975608, -8.420590596768649],
              [-37.613068536603315, -8.420961590244373],
              [-37.613470604053539, -8.421055517838324],
              [-37.613780098213802, -8.421056610037892],
              [-37.614151527586614, -8.421057920483937],
              [-37.61427585561389, -8.420904178405348],
              [-37.614245747066221, -8.420688128874074],
              [-37.614370513657867, -8.420411043762167],
              [-37.614680994663367, -8.420134523008299],
              [-37.614835960738006, -8.420073397101538],
              [-37.614990159355983, -8.42022812139343],
              [-37.615050339682981, -8.420721892619774],
              [-37.615234958087413, -8.421030904806241],
              [-37.615729180668431, -8.421310262316718],
              [-37.616131139672525, -8.421435113540051],
              [-37.616533427897764, -8.421467366653632],
              [-37.616812064638381, -8.421437511682345],
              [-37.617030061847835, -8.421068064382986],
              [-37.617308789131215, -8.421038209325983],
              [-37.617525362265468, -8.421069807685594],
              [-37.617802904209817, -8.421348400255889],
              [-37.618154306736649, -8.421516929536411],
              [-37.618719262542086, -8.421518916439538],
              [-37.619178578698026, -8.421450087254442],
              [-37.619390198823453, -8.421521275012037],
              [-37.619600572329389, -8.421944226016471],
              [-37.61981153569171, -8.422226471943938],
              [-37.620305091188044, -8.422439356962721],
              [-37.620692972812449, -8.422616693583212],
              [-37.621222603912692, -8.422618553087728],
              [-37.621822979779942, -8.422620660109569],
              [-37.622070177391436, -8.422621527384093],
              [-37.622316163923017, -8.422939071973245],
              [-37.622597568638888, -8.423256740680603],
              [-37.622702553952429, -8.423538613796488],
              [-37.623161623530855, -8.423540223426121],
              [-37.623479882484858, -8.423400631717234],
              [-37.623833310439132, -8.423331516604074],
              [-37.6241157449113, -8.423332506126732],
              [-37.624220607119504, -8.423649555415851],
              [-37.62436107854284, -8.423861198922001],
              [-37.624714382979882, -8.423827169038585],
              [-37.624855600443894, -8.423827663537415],
              [-37.62489192161302, -8.423546285525598],
              [-37.62492824269723, -8.423264907498025],
              [-37.625140610787199, -8.42312494356328],
              [-37.625458835901149, -8.423020436388372],
              [-37.625917069540748, -8.423233191366425],
              [-37.626304955353312, -8.423410522974086],
              [-37.626834588787311, -8.423412375086013],
              [-37.627399640227978, -8.423414350266285],
              [-37.627823631396872, -8.423345478051665],
              [-37.628142104175353, -8.423170615698426],
              [-37.628460857548333, -8.422890223329638],
              [-37.628708799525846, -8.422679937217662],
              [-37.628991324920534, -8.422680923476262],
              [-37.629202948648391, -8.422752015909021],
              [-37.629485226247795, -8.422823445207921],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0418000',
        uf: 'PE',
        nome_proje: 'PA ORLANDO ANDRE',
        municipio: 'JATAUBA',
        area_hecta: '940.2790',
        capacidade: 24.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '14/08/2017',
        forma_obte: 'Desapropriação',
        data_obten: '30/12/2014',
        area_calc_: 939.3691,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.580344022856508, -7.913139262251693],
              [-36.577616439434706, -7.913373075627888],
              [-36.575471923493453, -7.914567622980369],
              [-36.574725656527043, -7.914608084800041],
              [-36.56937141345805, -7.917637541801808],
              [-36.56802918268869, -7.917915253344563],
              [-36.564302705460349, -7.921130546556348],
              [-36.560788684096543, -7.922929290721127],
              [-36.559658397925503, -7.923636757741762],
              [-36.558025357638932, -7.924615440605805],
              [-36.556932248100523, -7.925090838383161],
              [-36.557626154339587, -7.930466615272601],
              [-36.559551302917868, -7.934167771732923],
              [-36.559565009628962, -7.934934301203881],
              [-36.560034770067929, -7.935824339390243],
              [-36.561908567296442, -7.93778961694855],
              [-36.557295873485891, -7.939488790200925],
              [-36.557665972169815, -7.940585099462193],
              [-36.557694699381621, -7.940994719430881],
              [-36.564965832677487, -7.956482373664825],
              [-36.566494332629127, -7.959398103191861],
              [-36.57706654685618, -7.949358415586854],
              [-36.571475847815968, -7.944829601356142],
              [-36.566204821687059, -7.937786536194755],
              [-36.566710780874466, -7.93755516652687],
              [-36.566353372333538, -7.937076796747623],
              [-36.567172554761271, -7.936652278780972],
              [-36.567372353217685, -7.937804446703925],
              [-36.567400550126727, -7.937842271820188],
              [-36.568114913707909, -7.939890648110381],
              [-36.569219561234249, -7.941658733560872],
              [-36.569943517954755, -7.941671099939263],
              [-36.570568567115728, -7.938678758831323],
              [-36.569771777145171, -7.937084030538555],
              [-36.568774999829436, -7.937352537070314],
              [-36.568392084353995, -7.936128731786538],
              [-36.568235782821766, -7.935798763407354],
              [-36.568181101867061, -7.935135869655729],
              [-36.568967391644904, -7.935008802970118],
              [-36.569139874013615, -7.933858357799542],
              [-36.570563616273958, -7.933343540520015],
              [-36.57105448204296, -7.933155727398942],
              [-36.571155616370149, -7.933087196945906],
              [-36.571656864055591, -7.935369289940563],
              [-36.571081290530699, -7.93594440932011],
              [-36.571306406338259, -7.936268615326687],
              [-36.570569223863089, -7.936436633554741],
              [-36.574454379896949, -7.939296875887354],
              [-36.575734687739633, -7.939921947308429],
              [-36.575734687739633, -7.939921947308429],
              [-36.573688727029911, -7.935016081883656],
              [-36.578777982972412, -7.931554137720362],
              [-36.58178412210399, -7.929197730135482],
              [-36.585101931155755, -7.926488823297439],
              [-36.594053982291065, -7.917508413695526],
              [-36.593066029893187, -7.915848839806411],
              [-36.592350356930417, -7.915637849490545],
              [-36.589010778415648, -7.916294083801335],
              [-36.588600734165531, -7.914397845409069],
              [-36.588730942301702, -7.913702483207173],
              [-36.587433877871952, -7.910923188640871],
              [-36.587021580115447, -7.910564062335677],
              [-36.586140295226869, -7.908025970965439],
              [-36.585315820727885, -7.907736980369438],
              [-36.581818849417267, -7.912656309399869],
              [-36.580895868674013, -7.913028811959243],
              [-36.580344022856508, -7.913139262251693],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0306000',
        uf: 'PE',
        nome_proje: 'PA SANTO AMARO',
        municipio: 'ALTINHO',
        area_hecta: '1197.3406',
        capacidade: 50.0,
        num_famili: 42.0,
        fase: 4.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '06/11/2002',
        area_calc_: 1191.8415,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.971991493943335, -8.499645871897275],
              [-35.970419007948209, -8.502021811887607],
              [-35.973345596521931, -8.50380617969908],
              [-35.972203894072493, -8.505197056895538],
              [-35.971336975540268, -8.506920078701096],
              [-35.970871477056519, -8.507745700037505],
              [-35.970299459280668, -8.508879280279263],
              [-35.970570568530412, -8.508980824326043],
              [-35.971107674138402, -8.509050433265182],
              [-35.971561229019741, -8.509064400553592],
              [-35.972060491784966, -8.509051368472157],
              [-35.972376662812636, -8.509206768356799],
              [-35.972794364515231, -8.509395899781508],
              [-35.973217616768338, -8.509504679041465],
              [-35.973433126587416, -8.509550818136065],
              [-35.97379189771911, -8.509605707501857],
              [-35.973975164834016, -8.509624630721058],
              [-35.974196137315865, -8.509626101361881],
              [-35.974317079806177, -8.509611896273025],
              [-35.974852691941621, -8.509523422349728],
              [-35.975721797452991, -8.50933067566322],
              [-35.975589146583616, -8.516742366604639],
              [-35.975001263160806, -8.516748014776883],
              [-35.974252629100199, -8.516773533536021],
              [-35.973423976280792, -8.516860279379257],
              [-35.972588997723392, -8.517032801317706],
              [-35.971989730476032, -8.517258261493888],
              [-35.971270445762293, -8.517547694388183],
              [-35.970597245477045, -8.517929459086087],
              [-35.970237306126862, -8.518182806848623],
              [-35.969854260495993, -8.518299561446002],
              [-35.969395006326799, -8.518395217831042],
              [-35.969028220954904, -8.518561261598338],
              [-35.968870952554873, -8.518807547982693],
              [-35.968818645283342, -8.519084920543225],
              [-35.968757451045732, -8.519210233960202],
              [-35.968687797054443, -8.51932115819335],
              [-35.968688407842912, -8.519388544758636],
              [-35.968667834812734, -8.519521316486989],
              [-35.968609926932722, -8.519625646650834],
              [-35.968469799993983, -8.519776677356624],
              [-35.968389389007093, -8.519869526164062],
              [-35.968207435832177, -8.520009855604959],
              [-35.968013407375317, -8.520172951883476],
              [-35.967811450216693, -8.520319938448882],
              [-35.967655791542768, -8.520445703150571],
              [-35.967411380404066, -8.520595374501923],
              [-35.966822277947102, -8.523009603735181],
              [-35.964084788667421, -8.525387781996589],
              [-35.96397863124686, -8.52589619745363],
              [-35.963420542251669, -8.526215828596488],
              [-35.962432363945176, -8.526237198684344],
              [-35.96265764708653, -8.526984734975795],
              [-35.962359831888456, -8.527022964026637],
              [-35.962429387636831, -8.528087960522184],
              [-35.962815540156676, -8.527948691609726],
              [-35.963536157431228, -8.527698913753266],
              [-35.964628848340894, -8.527520367211784],
              [-35.96544320828518, -8.527564910525959],
              [-35.966090869879835, -8.527727984399803],
              [-35.967001908178148, -8.527847125743737],
              [-35.96785448039595, -8.528139436184686],
              [-35.968326920397196, -8.528222001507833],
              [-35.968568924258292, -8.52825429631185],
              [-35.968810600727984, -8.528291200630621],
              [-35.969324242349728, -8.528373991185768],
              [-35.969744927989744, -8.528441232284552],
              [-35.970336817400408, -8.528503457703874],
              [-35.971099951633079, -8.528577739930993],
              [-35.971353016819272, -8.52858528620998],
              [-35.971593863004863, -8.528585247353197],
              [-35.972082915816443, -8.528506865757823],
              [-35.972694983816879, -8.528488729616752],
              [-35.973257335791025, -8.528521907819016],
              [-35.973775209863298, -8.528518478700883],
              [-35.974005247152, -8.528505512813926],
              [-35.974190877209111, -8.528424627407954],
              [-35.974184340968442, -8.528628893679684],
              [-35.974140335454656, -8.528840889188242],
              [-35.974128935552571, -8.529041753950484],
              [-35.974128657718161, -8.529337015470244],
              [-35.974295633785111, -8.529571331729212],
              [-35.974450992008208, -8.529746747377754],
              [-35.974582767646737, -8.529923698888531],
              [-35.974636562298379, -8.530061862008465],
              [-35.974673618341335, -8.530288502407945],
              [-35.974669399192159, -8.53049368844783],
              [-35.974680156599732, -8.530722698682764],
              [-35.974738672247405, -8.530861096518668],
              [-35.974875640003809, -8.531052190699077],
              [-35.974959940730159, -8.531180453597115],
              [-35.975092273619161, -8.531358936120339],
              [-35.975161430836479, -8.53148867019274],
              [-35.975297392626558, -8.531760713043282],
              [-35.975363725957202, -8.53193590781861],
              [-35.97538456639775, -8.532236386746389],
              [-35.975382334988971, -8.532463780106886],
              [-35.975398886000434, -8.532538541767799],
              [-35.97542627561932, -8.53277067622135],
              [-35.975450966099167, -8.53300581247583],
              [-35.975525828024239, -8.533297836676194],
              [-35.97553289093743, -8.533377820315957],
              [-35.975566438792868, -8.533537999817723],
              [-35.975593168054957, -8.533684861904288],
              [-35.975596134238444, -8.533763160602208],
              [-35.975601006052841, -8.533841444667679],
              [-35.975601604506075, -8.533918677509956],
              [-35.975577459017664, -8.534316071110101],
              [-35.975580049671159, -8.534392746637954],
              [-35.975594215507023, -8.534616400587721],
              [-35.975632236782474, -8.534783807064731],
              [-35.975710749921099, -8.534898980630169],
              [-35.975776012632025, -8.534935878620031],
              [-35.975842059579314, -8.534956871407484],
              [-35.97593241327634, -8.534940910690617],
              [-35.976128574301768, -8.534935790679391],
              [-35.976273271279318, -8.534952927205033],
              [-35.976429908388624, -8.534953350111289],
              [-35.976636561222108, -8.534967119831833],
              [-35.976882762084642, -8.534966764191307],
              [-35.97711848551252, -8.534996209456926],
              [-35.977333999771865, -8.535087780373676],
              [-35.97748029629539, -8.535170759085695],
              [-35.977938752273822, -8.535379977215356],
              [-35.978161611479862, -8.535494165409965],
              [-35.978423203127235, -8.535612647561644],
              [-35.97863244425033, -8.535707804122064],
              [-35.97864220856529, -8.53573817226836],
              [-35.978672114348633, -8.535856318795982],
              [-35.978685273368434, -8.535908305104297],
              [-35.978668526686718, -8.536035510022248],
              [-35.97866503257238, -8.536167270102265],
              [-35.978686573787449, -8.536309838328854],
              [-35.978824577680676, -8.536785931619722],
              [-35.97905952462326, -8.537370043670281],
              [-35.979264995377712, -8.537761337417564],
              [-35.979435961244754, -8.538002119432505],
              [-35.979659677778692, -8.538320006835171],
              [-35.979700393422576, -8.538432884438516],
              [-35.979844151659684, -8.538212081761426],
              [-35.981545667451634, -8.53863820048868],
              [-35.982013115399461, -8.537446055586026],
              [-35.983231699255356, -8.537936048146095],
              [-35.983269647420748, -8.538019677626336],
              [-35.983550562749251, -8.538340644242577],
              [-35.984355416143231, -8.538818280701612],
              [-35.986084928443951, -8.539506675565468],
              [-35.986677205816505, -8.540235179376545],
              [-35.98645306670258, -8.542300527594129],
              [-35.989523698499603, -8.545246323430183],
              [-35.990752167778027, -8.545080011105041],
              [-35.991640683932175, -8.54513493339522],
              [-35.995997903141571, -8.544915321060936],
              [-35.996872443664081, -8.544947484724537],
              [-35.998893222603293, -8.532933085939398],
              [-35.999171775247142, -8.532985853778973],
              [-35.999630206277949, -8.532913019606903],
              [-35.999936463177043, -8.532876231862989],
              [-36.00034030352279, -8.532796863742185],
              [-36.00057653267843, -8.532810301450873],
              [-36.000752467090706, -8.532958171446095],
              [-36.000898757269937, -8.533028583451349],
              [-36.001142613499226, -8.533112241682415],
              [-36.00140633521665, -8.53317162666289],
              [-36.001497470109655, -8.533211256623122],
              [-36.001032337846574, -8.534271179658811],
              [-36.001755983402582, -8.534460154528128],
              [-36.001357373097626, -8.535498923011826],
              [-36.000631928317283, -8.544468854243792],
              [-36.003508547369108, -8.547948518827191],
              [-36.004343045924777, -8.547190281539022],
              [-36.003196692585846, -8.545846881105508],
              [-36.005387185511665, -8.541998007473232],
              [-36.007211317843009, -8.543339390311372],
              [-36.0089414734652, -8.540461470430756],
              [-36.009176053205962, -8.540044294193596],
              [-36.009226054616484, -8.540032613969787],
              [-36.009741869699809, -8.538412991551745],
              [-36.009647752099305, -8.538226280931474],
              [-36.009306540866255, -8.53785594500739],
              [-36.009042749978939, -8.537602892414334],
              [-36.008726375978391, -8.537263618062324],
              [-36.008526160011442, -8.537018317899317],
              [-36.008306687832444, -8.536617650042666],
              [-36.008288168716447, -8.536199910689366],
              [-36.008380373266718, -8.535830001400932],
              [-36.008432904818889, -8.535492739607815],
              [-36.00859988854944, -8.535088555336285],
              [-36.00891264708536, -8.534596247987171],
              [-36.00906253399058, -8.534129595419067],
              [-36.009202750206597, -8.533737452820233],
              [-36.009358692612373, -8.533244556619501],
              [-36.009471637868685, -8.532958057439124],
              [-36.009545141893142, -8.532776897644379],
              [-36.009659113838275, -8.5325953830699],
              [-36.009711462036428, -8.532427189891054],
              [-36.009822831942842, -8.532268151160864],
              [-36.009934318126604, -8.532100800846976],
              [-36.010058512790323, -8.531818176685976],
              [-36.010194579660613, -8.531579904405977],
              [-36.010282618911354, -8.531361878458048],
              [-36.010367586584856, -8.531064563618667],
              [-36.010387030981903, -8.530720332568032],
              [-36.010424547030127, -8.530250397722504],
              [-36.010395957047109, -8.529937072365055],
              [-36.010332590413718, -8.52956313226108],
              [-36.010227210929436, -8.529224206318318],
              [-36.010218236186731, -8.52880838077041],
              [-36.010216374702289, -8.528547602534788],
              [-36.010215117910441, -8.528143912072089],
              [-36.010276341178198, -8.527838922742564],
              [-36.010550923455334, -8.527304456275203],
              [-36.010600098409796, -8.527071646622998],
              [-36.010661916523524, -8.5267962821283],
              [-36.01070698253497, -8.526571815073158],
              [-36.010862921219328, -8.526012015127135],
              [-36.010646249450502, -8.525966239531027],
              [-36.009656036939859, -8.525953960417246],
              [-36.009069186541097, -8.525965107845186],
              [-36.008475906025005, -8.525977930271148],
              [-36.007872438169457, -8.525983071062205],
              [-36.007324164976211, -8.525953980263617],
              [-36.00606418434063, -8.524598171112837],
              [-36.002784320335664, -8.522462871702359],
              [-36.001656346756896, -8.522251273796099],
              [-36.001132848645589, -8.522114665439251],
              [-36.000805992599439, -8.522020452287524],
              [-35.999242757986053, -8.521371357656374],
              [-35.998433497838633, -8.521290461069757],
              [-35.997462379163395, -8.521236174085105],
              [-35.997348329689018, -8.521110225296402],
              [-35.997266239064182, -8.520916549753881],
              [-35.997209817496604, -8.520779586682242],
              [-35.997162682813439, -8.520546043901405],
              [-35.997131050917972, -8.520371973235829],
              [-35.997077788397981, -8.520209511367476],
              [-35.997078418877365, -8.519964338582721],
              [-35.997057223224751, -8.519806236142484],
              [-35.997019791538563, -8.519614112599257],
              [-35.996971857625468, -8.51947166378678],
              [-35.996881406585146, -8.519311295629793],
              [-35.99670102716658, -8.519150808654887],
              [-35.996530840807523, -8.518959167766601],
              [-35.996338344194541, -8.518792450626625],
              [-35.996044700325371, -8.518503004558507],
              [-35.995978966470915, -8.518427291420235],
              [-35.995938764279536, -8.518310437659819],
              [-35.995858093398041, -8.518182333344079],
              [-35.995795491506541, -8.518090584757855],
              [-35.995663352952171, -8.517972091646428],
              [-35.995532193900978, -8.517844988060766],
              [-35.995355799473074, -8.517701202245748],
              [-35.995326834318803, -8.517628977301033],
              [-35.995192990930597, -8.517547895638113],
              [-35.995034669313455, -8.517458059600681],
              [-35.994855431410777, -8.517385729076659],
              [-35.994508070228555, -8.517223366714052],
              [-35.994064853539065, -8.517057136035815],
              [-35.993697846105768, -8.516900524980596],
              [-35.99346093916391, -8.516798827767518],
              [-35.993193352643559, -8.516637745863076],
              [-35.992925816296861, -8.516471423887372],
              [-35.992646673703518, -8.516271225174473],
              [-35.992197291266727, -8.515953728213038],
              [-35.992021136303578, -8.515809084635324],
              [-35.991705765909366, -8.515596265741756],
              [-35.991336085988941, -8.515515234499365],
              [-35.990934581515347, -8.515427227068066],
              [-35.989477724229836, -8.515922460289874],
              [-35.988361345220724, -8.51625553711871],
              [-35.988439106533576, -8.514980940913471],
              [-35.986536529263098, -8.514344438819897],
              [-35.985024462328397, -8.514051903070808],
              [-35.984114140600731, -8.513949411299853],
              [-35.984022403786213, -8.514195919212101],
              [-35.98286854327722, -8.514095927836085],
              [-35.982789246447389, -8.514085967528185],
              [-35.982530939591037, -8.514053522026614],
              [-35.982316785571513, -8.514150210762601],
              [-35.981911940966704, -8.514602558770713],
              [-35.981659239647634, -8.513937640174277],
              [-35.981523113232583, -8.513596583499043],
              [-35.981237532814951, -8.513091630217545],
              [-35.98116230022594, -8.512856521446977],
              [-35.981074149580337, -8.512546894368363],
              [-35.981199570979086, -8.512311403985906],
              [-35.981388145774567, -8.511987293508984],
              [-35.981584634637869, -8.511636833203827],
              [-35.981641945299934, -8.51154062270593],
              [-35.981840145720724, -8.511264809801919],
              [-35.981947340973043, -8.511100963478651],
              [-35.982047723909133, -8.51094575445121],
              [-35.982097636064125, -8.510857981181156],
              [-35.982169918528278, -8.510729400595791],
              [-35.982251503153044, -8.510607563941212],
              [-35.982291430832021, -8.510338147337292],
              [-35.982118659501012, -8.510045701445952],
              [-35.981922575400752, -8.509770529189181],
              [-35.981870496932345, -8.509684295255393],
              [-35.98180894391492, -8.509282753451634],
              [-35.981883740333323, -8.508863924925448],
              [-35.982046303032789, -8.508268021631139],
              [-35.982072156675379, -8.508059575012773],
              [-35.981975472177197, -8.507796084675064],
              [-35.98192409204627, -8.507664588624415],
              [-35.981884759046167, -8.507530290062819],
              [-35.981866813074092, -8.507462675899204],
              [-35.981822910866761, -8.507253885346886],
              [-35.98177589619462, -8.506971223972448],
              [-35.981739139492284, -8.506771141133695],
              [-35.98167214933671, -8.50646342922732],
              [-35.981147117412995, -8.506721931888301],
              [-35.98081079245307, -8.506689474097714],
              [-35.980832194713265, -8.506469690041092],
              [-35.981654554292078, -8.506370789313841],
              [-35.9816775588734, -8.506216969672392],
              [-35.981681796191303, -8.505892523329646],
              [-35.981666714062044, -8.505690738487175],
              [-35.981637413431748, -8.505480842120912],
              [-35.98161318644329, -8.505269913137733],
              [-35.981605008105667, -8.50512769695869],
              [-35.981611466536265, -8.50466897086139],
              [-35.979442683586285, -8.505253127778765],
              [-35.978819061009247, -8.504473066761211],
              [-35.978194922681823, -8.503837274537812],
              [-35.977558491400636, -8.503371858331786],
              [-35.975568291531623, -8.502590415706745],
              [-35.975185468806025, -8.501910853496291],
              [-35.974959520298505, -8.501604986514552],
              [-35.97477055867644, -8.501412027875334],
              [-35.974413106883318, -8.501021346435277],
              [-35.974084067805215, -8.5007142793879],
              [-35.973295345963564, -8.500126617306691],
              [-35.971991493943335, -8.499645871897275],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0310000',
        uf: 'PE',
        nome_proje: 'PA SERROTE REDONDO',
        municipio: 'ARCOVERDE',
        area_hecta: '462.4678',
        capacidade: 27.0,
        num_famili: 25.0,
        fase: 5.0,
        data_de_cr: '27/11/2003',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 456.2274,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.963305551329753, -8.327854599184198],
              [-36.961507473114651, -8.332955853374957],
              [-36.960603691666051, -8.33542762977325],
              [-36.959802416970618, -8.337521085534647],
              [-36.95954770799154, -8.338163784597539],
              [-36.958395949531266, -8.341037604847751],
              [-36.958192914405451, -8.341479837845052],
              [-36.954769976651804, -8.349263169138315],
              [-36.955280129058409, -8.349869936267803],
              [-36.955977593485457, -8.350467780471368],
              [-36.95677267589641, -8.351035319880994],
              [-36.957293115049673, -8.351325020624252],
              [-36.959821493378072, -8.352724438515532],
              [-36.959543768598358, -8.353022877538038],
              [-36.958610275213118, -8.354862178151203],
              [-36.958477010483342, -8.355158296448879],
              [-36.958316364329235, -8.355928278365266],
              [-36.958296480238062, -8.356011351510332],
              [-36.958066460958804, -8.357087715574703],
              [-36.957579151204982, -8.359243528675947],
              [-36.959067202765588, -8.360029279055491],
              [-36.962674962014439, -8.361928571568866],
              [-36.962541767741598, -8.361066421003361],
              [-36.962636572667449, -8.360478530753502],
              [-36.963200803279449, -8.359258584718473],
              [-36.963640259148278, -8.358396688412732],
              [-36.964138252921508, -8.357397866832414],
              [-36.964153909107075, -8.357364951796523],
              [-36.96473112235158, -8.357589841787677],
              [-36.965169113964272, -8.357851232305059],
              [-36.966637470470623, -8.357678272991734],
              [-36.96828836980832, -8.358104262618365],
              [-36.969030615768233, -8.358541826988411],
              [-36.969214802410072, -8.358625343141222],
              [-36.970063587159423, -8.358861437855403],
              [-36.970617188819119, -8.357736800881135],
              [-36.971197674688149, -8.356652211462052],
              [-36.973180329425816, -8.352943323152628],
              [-36.973219492866896, -8.352859642067719],
              [-36.973542403841499, -8.352212914422459],
              [-36.973830809523214, -8.351659916600928],
              [-36.975256187346588, -8.34900026912579],
              [-36.975290496078195, -8.348914357057165],
              [-36.976429003203435, -8.34682070859767],
              [-36.976414207788416, -8.346626861479008],
              [-36.974500333671401, -8.344207662575901],
              [-36.97279865989892, -8.342091099398839],
              [-36.971528409184266, -8.340567679026579],
              [-36.971198000425069, -8.34017141539055],
              [-36.971156937811315, -8.340092196226063],
              [-36.970045929444282, -8.33877799745788],
              [-36.968531382239256, -8.336871549974559],
              [-36.967876387855604, -8.335982589781041],
              [-36.96693841573363, -8.334656464153182],
              [-36.965530190457926, -8.33264847824255],
              [-36.963979238186674, -8.329579459525105],
              [-36.963737984643899, -8.32812848439764],
              [-36.963305551329753, -8.327854599184198],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0254000',
        uf: 'PE',
        nome_proje: 'PA PEDRA VERMELHA',
        municipio: 'ARCOVERDE',
        area_hecta: '465.6860',
        capacidade: 28.0,
        num_famili: 27.0,
        fase: 4.0,
        data_de_cr: '02/07/2002',
        forma_obte: 'Compra e Venda',
        data_obten: '29/05/2002',
        area_calc_: 475.6891,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.04389917001172, -8.427042219999711],
              [-37.026536940012264, -8.427493889999679],
              [-37.025436940012291, -8.430498059999687],
              [-37.024944170012311, -8.430500559999684],
              [-37.025268060012309, -8.431104719999697],
              [-37.024959170012316, -8.431903889999685],
              [-37.024917220012313, -8.432037499999694],
              [-37.024855830012314, -8.432090559999679],
              [-37.023080280012373, -8.437315829999685],
              [-37.020625560012448, -8.442632779999682],
              [-37.038983060011859, -8.4429213899997],
              [-37.043720560011707, -8.442969169999706],
              [-37.048878610011556, -8.442994169999711],
              [-37.048012220011579, -8.441889999999697],
              [-37.045676110011655, -8.440458889999698],
              [-37.046282500011635, -8.437268059999703],
              [-37.04629944001163, -8.437184169999711],
              [-37.046779440011619, -8.434463889999707],
              [-37.050830000011501, -8.434195829999709],
              [-37.05038750001151, -8.432068889999718],
              [-37.05042944001152, -8.431997499999715],
              [-37.050893890011501, -8.432104719999716],
              [-37.05048222001151, -8.43083721999972],
              [-37.050774720011496, -8.430693609999711],
              [-37.050956670011502, -8.430538329999703],
              [-37.051225830011489, -8.430153059999711],
              [-37.050816110011496, -8.429930279999702],
              [-37.050841390011506, -8.429573609999704],
              [-37.053145280011435, -8.429650279999716],
              [-37.052812220011447, -8.427559999999717],
              [-37.050631940011513, -8.427294169999714],
              [-37.046033330011653, -8.427111109999712],
              [-37.04389917001172, -8.427042219999711],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0364000',
        uf: 'PE',
        nome_proje: 'PA BARAÚNAS',
        municipio: 'BREJO DA MADRE DE DEUS',
        area_hecta: '967.2790',
        capacidade: 50.0,
        num_famili: 35.0,
        fase: 4.0,
        data_de_cr: '29/11/2006',
        forma_obte: 'Desapropriação',
        data_obten: '18/05/2006',
        area_calc_: 932.2282,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.267083403453931, -7.951812560097033],
              [-36.266215849656845, -7.952213462846132],
              [-36.26782676949383, -7.963119411375412],
              [-36.262128581887076, -7.962036040695004],
              [-36.261926375815449, -7.970861936137117],
              [-36.259835974126979, -7.970733772695264],
              [-36.25713790223989, -7.96645129004663],
              [-36.254849156422232, -7.965532202184059],
              [-36.251039027449423, -7.965310854933886],
              [-36.250990215801686, -7.965302680264065],
              [-36.251243929120683, -7.962236900556269],
              [-36.251213167646164, -7.956831281248338],
              [-36.251080097631707, -7.956722078285028],
              [-36.250508526104483, -7.95688470714777],
              [-36.248487461685606, -7.956564923161337],
              [-36.248194186794414, -7.95640630883136],
              [-36.248175907660126, -7.956333424470643],
              [-36.248190440798055, -7.954952137001438],
              [-36.245756139378685, -7.95540811451393],
              [-36.245563399532479, -7.957292674681872],
              [-36.245574528296949, -7.957366532887471],
              [-36.24528199209999, -7.960717268623966],
              [-36.244901599240052, -7.965099107749322],
              [-36.24390337798404, -7.964980495811206],
              [-36.236772264857173, -7.962810463416346],
              [-36.236291128563352, -7.962769480338601],
              [-36.236180442098835, -7.965038301226797],
              [-36.237408798647536, -7.965181208424761],
              [-36.236072338420151, -7.974868825043573],
              [-36.235191358384213, -7.974375278183664],
              [-36.234764830705387, -7.978459903522759],
              [-36.236329887018272, -7.979974911122223],
              [-36.236731117576497, -7.980362576905372],
              [-36.238281104694089, -7.981258485227826],
              [-36.238699675251532, -7.980125342498943],
              [-36.239092900558866, -7.980247949185273],
              [-36.239247229060609, -7.980028036687282],
              [-36.239332394674214, -7.979959884873046],
              [-36.240088217298791, -7.979734409280889],
              [-36.241702997067101, -7.979915042080189],
              [-36.243055542959496, -7.979744902172357],
              [-36.243093233560167, -7.978177673154068],
              [-36.243705977886592, -7.97606948354314],
              [-36.249352119956889, -7.977101087360599],
              [-36.249688609500154, -7.973316219508115],
              [-36.249409596880575, -7.968947665079518],
              [-36.249475152777251, -7.967958298285795],
              [-36.249671220602131, -7.967073929112343],
              [-36.249692288268193, -7.96688148872254],
              [-36.249867292642278, -7.966972010518459],
              [-36.250720841033726, -7.967192556078352],
              [-36.250801202896866, -7.967298598791782],
              [-36.250673085337247, -7.970052082855575],
              [-36.253019079929565, -7.971591528374357],
              [-36.253085391033075, -7.97174955835846],
              [-36.252644399150455, -7.984900030007791],
              [-36.254443358076912, -7.985517408653748],
              [-36.254589659854858, -7.984870964754679],
              [-36.255043822617822, -7.984859915051668],
              [-36.255722500465737, -7.984614919580413],
              [-36.25586304962421, -7.98452989666597],
              [-36.256567000683063, -7.984434857498016],
              [-36.256567000736197, -7.984434857490826],
              [-36.256776208064991, -7.982851423467098],
              [-36.258858153581194, -7.983190656309922],
              [-36.259329208300315, -7.983291239145557],
              [-36.259376451880513, -7.984903502157459],
              [-36.259243486361278, -7.985534588177174],
              [-36.261552262165118, -7.987866335802784],
              [-36.261380288598694, -7.988804812080556],
              [-36.261961487136332, -7.98902957803095],
              [-36.262350499729941, -7.988941647263342],
              [-36.26281229689674, -7.988643570404162],
              [-36.262973534995623, -7.988368735512978],
              [-36.263277717241031, -7.988280480980232],
              [-36.26390673154318, -7.987973955201697],
              [-36.264056437255903, -7.987650663572111],
              [-36.264912070932603, -7.987657523198575],
              [-36.265393031259237, -7.987704691891339],
              [-36.266496076953459, -7.987600406458173],
              [-36.266400588752958, -7.994297355051883],
              [-36.267559447349363, -7.9937826027094],
              [-36.267710352484059, -7.987528009063093],
              [-36.267922204938124, -7.987439676084066],
              [-36.271042600213995, -7.986480613995576],
              [-36.273938815676644, -7.988660670772187],
              [-36.273999542516677, -7.981034022142059],
              [-36.275699417126738, -7.981154843179437],
              [-36.276359031493861, -7.981405380226148],
              [-36.277781130291686, -7.98185412990721],
              [-36.278159523967403, -7.971520274175706],
              [-36.272124938803984, -7.967017438709326],
              [-36.272200485166067, -7.963494562509624],
              [-36.270947481498425, -7.96311825656638],
              [-36.270848366155477, -7.963571489175623],
              [-36.270804038511571, -7.963610956852043],
              [-36.269509321061115, -7.964197832012047],
              [-36.269319515386854, -7.963559972779522],
              [-36.269433285467485, -7.963176663318666],
              [-36.269617139741356, -7.962016315946213],
              [-36.269420329656505, -7.961906999739661],
              [-36.268593884820191, -7.961748990929162],
              [-36.267083403453931, -7.951812560097033],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0372000',
        uf: 'PE',
        nome_proje: 'PA MIGUEL VELHO',
        municipio: 'BREJO DA MADRE DE DEUS',
        area_hecta: '1133.9576',
        capacidade: 75.0,
        num_famili: 50.0,
        fase: 4.0,
        data_de_cr: '19/10/2007',
        forma_obte: 'Desapropriação',
        data_obten: '12/09/2006',
        area_calc_: 1177.0422,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.23690932206371, -8.000469599833268],
              [-36.236770502809655, -8.000573549823256],
              [-36.236333352882248, -8.004264443484926],
              [-36.236148990698652, -8.004884139169924],
              [-36.235705439465612, -8.006288231378107],
              [-36.235643881519373, -8.006549727720005],
              [-36.235803990858429, -8.006866148328509],
              [-36.235814212813793, -8.008652942228178],
              [-36.235966691759813, -8.009220076122984],
              [-36.233033316820631, -8.013717842623901],
              [-36.232055860352695, -8.015323344321624],
              [-36.230192184600476, -8.01475265412823],
              [-36.228677444064317, -8.014241608762219],
              [-36.227552892001505, -8.016908604703216],
              [-36.227640625644803, -8.017416887278165],
              [-36.227624526607777, -8.017437840511338],
              [-36.227974891403434, -8.018035572458336],
              [-36.227981970541173, -8.018038190318888],
              [-36.228043835329963, -8.019789476834379],
              [-36.22829021514999, -8.03067984722119],
              [-36.230011562061343, -8.030887896889624],
              [-36.231027968689681, -8.032573582498554],
              [-36.231154278695556, -8.033372186498964],
              [-36.231068102584977, -8.034229134843285],
              [-36.231986338871195, -8.036348515870607],
              [-36.23305523368731, -8.035939587852324],
              [-36.236297932829778, -8.03528828979719],
              [-36.236074168599224, -8.042581765416651],
              [-36.247944337283904, -8.050261758017953],
              [-36.250912180827733, -8.049916692334259],
              [-36.250793819930983, -8.048927620739954],
              [-36.25082544528675, -8.047299125314906],
              [-36.251246228977912, -8.046180342917879],
              [-36.250970395371354, -8.043792715842594],
              [-36.251056587261665, -8.043455322360595],
              [-36.251127470139259, -8.039121126209135],
              [-36.252981030016038, -8.039402821122735],
              [-36.254027686799695, -8.037881510019155],
              [-36.256243104644412, -8.037001928188809],
              [-36.258725616720817, -8.036523506751042],
              [-36.258758596227615, -8.036608239486855],
              [-36.25932135791745, -8.036590660434046],
              [-36.260815647790572, -8.03769718133943],
              [-36.26115380529842, -8.037693448480262],
              [-36.261157993986764, -8.037628010052934],
              [-36.261627751462967, -8.037516210098575],
              [-36.261485580144438, -8.035868762871145],
              [-36.261654863645624, -8.033482723102914],
              [-36.264726008145281, -8.034452353630614],
              [-36.265082166243658, -8.02309693839471],
              [-36.259837015604845, -8.02210798999648],
              [-36.2592468298621, -8.021679059575824],
              [-36.25619277948303, -8.014824660043262],
              [-36.256524338236517, -8.014093040515579],
              [-36.254499582184124, -8.014287001007224],
              [-36.254055010492948, -8.015356440175161],
              [-36.249667474075657, -8.01310630649675],
              [-36.248776604927329, -8.018355052409481],
              [-36.247579668508074, -8.017433638809294],
              [-36.247007015031862, -8.016569398506835],
              [-36.24559676125839, -8.014292585302591],
              [-36.245416424406159, -8.014099266015696],
              [-36.244464213671037, -8.013181563639144],
              [-36.244210560153356, -8.012811172806474],
              [-36.243941412478641, -8.012360893066356],
              [-36.243333988275843, -8.011079251912971],
              [-36.242718879923672, -8.009687110030431],
              [-36.24253159650015, -8.009380922008919],
              [-36.242361806393227, -8.009147172219324],
              [-36.242316726904946, -8.009104936922878],
              [-36.242262944593897, -8.00906835357058],
              [-36.241760795454532, -8.00881205242373],
              [-36.241509457887688, -8.008629398760702],
              [-36.2412502763476, -8.008364484378967],
              [-36.240705774535385, -8.007926835061467],
              [-36.24062540781555, -8.007829900511174],
              [-36.240424386607316, -8.007505004037126],
              [-36.239771360812419, -8.00622566383459],
              [-36.23972130673328, -8.006063473787023],
              [-36.239666817237371, -8.005851638859285],
              [-36.239477036120434, -8.005623243779688],
              [-36.23923942820285, -8.005042352754248],
              [-36.238849206923263, -8.004218396166078],
              [-36.23875392265731, -8.003954495632556],
              [-36.238694585940706, -8.003507902730139],
              [-36.238116049962457, -8.002531311180864],
              [-36.237809729191149, -8.002017965061492],
              [-36.237763243904062, -8.001876073833301],
              [-36.237701178827351, -8.001620614072149],
              [-36.237648827625975, -8.001512736763285],
              [-36.237517335161421, -8.001342325492907],
              [-36.237386604494461, -8.001178637329666],
              [-36.23690932206371, -8.000469599833268],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0208000',
        uf: 'PE',
        nome_proje: 'PA CABANAS',
        municipio: 'CACHOEIRINHA',
        area_hecta: '2046.2550',
        capacidade: 114.0,
        num_famili: 113.0,
        fase: 5.0,
        data_de_cr: '20/01/2000',
        forma_obte: 'Desapropriação',
        data_obten: '25/08/1999',
        area_calc_: 2043.4507,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.315394987489348, -8.392888065556647],
              [-36.309226851832307, -8.393842900718655],
              [-36.306508797325435, -8.394277557633425],
              [-36.304429561382953, -8.394642834865296],
              [-36.302248697223796, -8.394968911405229],
              [-36.300296863033012, -8.39516254486629],
              [-36.298363485581369, -8.395337861761424],
              [-36.296360335695198, -8.395525791345307],
              [-36.294361078650269, -8.395702080803455],
              [-36.292534574515351, -8.395893191215047],
              [-36.291291481479391, -8.395395270145572],
              [-36.288429372304421, -8.394231569017562],
              [-36.28394591702785, -8.398991271232649],
              [-36.284228662319393, -8.399707397528475],
              [-36.284170745867257, -8.399724165590296],
              [-36.28189647568653, -8.400249337871088],
              [-36.281736247873511, -8.40195370119079],
              [-36.280805888901057, -8.401722829039537],
              [-36.280806386518947, -8.402758130961885],
              [-36.283565258998237, -8.403594730144555],
              [-36.28316882360722, -8.404780265977145],
              [-36.282687920597212, -8.405989884256938],
              [-36.283703123364475, -8.406443527148886],
              [-36.28437745179879, -8.406658352337912],
              [-36.283952335015265, -8.408052865051255],
              [-36.283933606246329, -8.408110202203018],
              [-36.283516160675788, -8.407999343006045],
              [-36.283151179206492, -8.409657024375621],
              [-36.282095364268827, -8.409392121755582],
              [-36.2820696429755, -8.409515279906509],
              [-36.281727009110412, -8.40951544105263],
              [-36.281050976558518, -8.409401616401972],
              [-36.281145671874107, -8.40770231437288],
              [-36.280856563309072, -8.407834403566721],
              [-36.280341145939815, -8.409294062539576],
              [-36.280786100598874, -8.409363642158043],
              [-36.280806196729621, -8.410099007302367],
              [-36.281067215009735, -8.410156742958582],
              [-36.280677903966044, -8.411111642166087],
              [-36.280147248076013, -8.410982918230602],
              [-36.279082944970405, -8.410634278824499],
              [-36.280264662786593, -8.407250315725717],
              [-36.280286112122177, -8.407167246287228],
              [-36.280918035144843, -8.40729495189138],
              [-36.281355304450251, -8.403806529686635],
              [-36.279198959511369, -8.40379975461658],
              [-36.279050282915627, -8.406798177931034],
              [-36.27904193691564, -8.406888927773934],
              [-36.278082197886071, -8.406576251529836],
              [-36.278169306960152, -8.406836265739077],
              [-36.277074965836441, -8.406827967519913],
              [-36.276939867250206, -8.405526814202656],
              [-36.275280975737907, -8.406412104904772],
              [-36.275486952001209, -8.407918584220869],
              [-36.27496378997408, -8.409159515824305],
              [-36.27510423794746, -8.410433959821562],
              [-36.276126165614663, -8.41031409250186],
              [-36.276450418960344, -8.411437923220241],
              [-36.277119049221255, -8.411338375512381],
              [-36.277409729103525, -8.412657413259209],
              [-36.277422449249698, -8.412745959578087],
              [-36.277719043762865, -8.414322461996969],
              [-36.277763379767507, -8.41474319416975],
              [-36.277410299975699, -8.415497476425957],
              [-36.278049972164943, -8.415348755774509],
              [-36.278531112476635, -8.416448013580492],
              [-36.27777006167733, -8.416851419266008],
              [-36.277097425407618, -8.4163283870969],
              [-36.276693230011809, -8.416281582133355],
              [-36.275954874401378, -8.415995818643038],
              [-36.275273961805809, -8.418109300628892],
              [-36.275760531392848, -8.418314620423603],
              [-36.275729563923058, -8.418564600077161],
              [-36.275889051056012, -8.418621636408094],
              [-36.275763082355894, -8.419675030929906],
              [-36.276273819799016, -8.419733600285113],
              [-36.275975020628607, -8.421326935392207],
              [-36.279125288115651, -8.42094091373226],
              [-36.279144548397355, -8.421028511580523],
              [-36.279592683203461, -8.423319425458848],
              [-36.280078726587426, -8.423237859191424],
              [-36.280180003905478, -8.423924813470917],
              [-36.28254229128315, -8.423408346391465],
              [-36.282300583314637, -8.422533201665365],
              [-36.283285871120526, -8.422194680654362],
              [-36.283331401171445, -8.422028921284081],
              [-36.283883363212993, -8.422552739780118],
              [-36.284316606140955, -8.422222322981083],
              [-36.284615154616262, -8.422390733767692],
              [-36.285129305398186, -8.421961122184403],
              [-36.285567745999757, -8.422511712579755],
              [-36.284487404573376, -8.423428945751562],
              [-36.285460137597589, -8.424695510593597],
              [-36.285383989194251, -8.424736638107346],
              [-36.284015975251144, -8.425420927562294],
              [-36.28434442957635, -8.426180294556397],
              [-36.286833049138608, -8.424817949444405],
              [-36.286736298206208, -8.423388928178925],
              [-36.287648805105121, -8.422973270390822],
              [-36.287719270649688, -8.422406319233286],
              [-36.287815775755938, -8.422425870459904],
              [-36.289180734642301, -8.422907772321821],
              [-36.289061262828262, -8.423462639566729],
              [-36.288592673239584, -8.423265949879189],
              [-36.288507093983981, -8.423644855741172],
              [-36.289374844510931, -8.423831381456417],
              [-36.289319799462724, -8.424217004226854],
              [-36.289680912437419, -8.424888136216815],
              [-36.289802650490842, -8.425284738464629],
              [-36.290219661610323, -8.425291593801573],
              [-36.290792792468515, -8.423754699864769],
              [-36.29066448327881, -8.423141738714337],
              [-36.290548291580762, -8.421726585176776],
              [-36.290709330216345, -8.420779132961872],
              [-36.290814573894025, -8.420794767977688],
              [-36.292945992551829, -8.42197534184497],
              [-36.292587463235989, -8.422471457929138],
              [-36.293582626069146, -8.42269626054196],
              [-36.294656577397966, -8.421690762603776],
              [-36.295069787365406, -8.421748094923506],
              [-36.295665368708363, -8.422017756942752],
              [-36.296183017014506, -8.422363685003463],
              [-36.297434347242294, -8.423216778606855],
              [-36.298239390975127, -8.421914762334705],
              [-36.29920400333468, -8.422214608521205],
              [-36.298426153800499, -8.423952426514907],
              [-36.298369430102852, -8.424032092509421],
              [-36.298009214578727, -8.423712820904338],
              [-36.296367057986679, -8.425810083936026],
              [-36.296323716318838, -8.425883516623268],
              [-36.296542850562311, -8.426877959396821],
              [-36.295969826415693, -8.427826824881498],
              [-36.295457960585161, -8.428162406132079],
              [-36.296221191353794, -8.429001481058149],
              [-36.297571739361665, -8.427867138101412],
              [-36.298707610699751, -8.429759449155723],
              [-36.29735188143674, -8.43035884556571],
              [-36.298763928807894, -8.43203737907856],
              [-36.299250183746111, -8.432593714207679],
              [-36.299906355505037, -8.432373695467435],
              [-36.300328315022874, -8.433224603524097],
              [-36.300414932407449, -8.433296491781103],
              [-36.300892181887122, -8.433765027122599],
              [-36.300331343809773, -8.434761420340344],
              [-36.30060894892479, -8.43521719650891],
              [-36.300525610619793, -8.435483176498579],
              [-36.300622386196657, -8.435764944375389],
              [-36.300644549453779, -8.435853376046289],
              [-36.300716476942384, -8.436367913841854],
              [-36.303303284082965, -8.436608541879616],
              [-36.303978850261998, -8.436340942396491],
              [-36.304933587619708, -8.43766483131308],
              [-36.306957619208916, -8.439106306977788],
              [-36.308118298055646, -8.437572959694533],
              [-36.308756842400804, -8.436269957810326],
              [-36.309706216355707, -8.43703448777835],
              [-36.310260629244326, -8.437501648649294],
              [-36.311347916147014, -8.4394507226269],
              [-36.312403351324136, -8.439643559295336],
              [-36.313163768890888, -8.440412843147357],
              [-36.313313169772385, -8.43903120736525],
              [-36.314154919260972, -8.439797703956408],
              [-36.314033837610872, -8.441244412206082],
              [-36.314560907354512, -8.441848456269222],
              [-36.315902527345919, -8.442875628798472],
              [-36.316626676983738, -8.437442461972502],
              [-36.316881676295033, -8.436352316834384],
              [-36.317679069471474, -8.435236697070181],
              [-36.318303595357925, -8.434382292970669],
              [-36.317291291494861, -8.433029387567979],
              [-36.318699996978175, -8.431748883803303],
              [-36.319339295116784, -8.432225287725455],
              [-36.321135612065554, -8.433389004653247],
              [-36.323435850731066, -8.430548107884029],
              [-36.323863767761473, -8.430805659348309],
              [-36.326946766085484, -8.431105441729688],
              [-36.328324401398241, -8.428900846579817],
              [-36.328498710905258, -8.428823963271045],
              [-36.329995286244817, -8.426766277760143],
              [-36.330068715202934, -8.42680382475411],
              [-36.33025061727048, -8.426836959316658],
              [-36.331087195810134, -8.427792147650537],
              [-36.335124329634979, -8.427308334439076],
              [-36.335952495036537, -8.423702819061527],
              [-36.332677192068509, -8.422296718983619],
              [-36.332566327991827, -8.422742884205954],
              [-36.332262455692529, -8.422790426715123],
              [-36.331317408547513, -8.422382355417582],
              [-36.331234168818725, -8.422346368646968],
              [-36.332348961314814, -8.418774079927607],
              [-36.331013959822705, -8.418582303050018],
              [-36.330783461144399, -8.417907920021452],
              [-36.329823339404307, -8.417622454757115],
              [-36.329911778959307, -8.41495365817846],
              [-36.329096528324541, -8.414345329982218],
              [-36.327876815950169, -8.413382482149597],
              [-36.326418872593457, -8.412332260984579],
              [-36.324870861318907, -8.411515453805267],
              [-36.323285330244353, -8.409609200037506],
              [-36.322984749326189, -8.40953595065478],
              [-36.322134875565681, -8.408978070219776],
              [-36.323298228989778, -8.405443196961942],
              [-36.322702766685531, -8.405207554482081],
              [-36.322600728419516, -8.405174421115051],
              [-36.322078993072985, -8.404892744907512],
              [-36.321447195926616, -8.403989011356302],
              [-36.320911444933714, -8.403475920324054],
              [-36.320760153128397, -8.403294804976358],
              [-36.320625636101198, -8.402785168886501],
              [-36.320374397380284, -8.402160885936937],
              [-36.319706341897557, -8.401160221312393],
              [-36.318314641827719, -8.3991419773504],
              [-36.317802941980233, -8.398352280681923],
              [-36.317498558289188, -8.396998351724177],
              [-36.316470320533824, -8.395054690598045],
              [-36.315394987489348, -8.392888065556647],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0184000',
        uf: 'PE',
        nome_proje: 'PA BRABOS',
        municipio: 'CAPOEIRAS',
        area_hecta: '270.0000',
        capacidade: 15.0,
        num_famili: 14.0,
        fase: 5.0,
        data_de_cr: '03/02/1999',
        forma_obte: 'Desapropriação',
        data_obten: '05/11/1998',
        area_calc_: 262.8146,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.614955768523039, -8.68191869471864],
              [-36.614245190971886, -8.682485703016413],
              [-36.612643615166483, -8.683682299677018],
              [-36.607612873227147, -8.68744070247365],
              [-36.607433929168891, -8.68757179983375],
              [-36.606979678355373, -8.687904696091859],
              [-36.605222893971693, -8.689192186874171],
              [-36.604766725386412, -8.689526151891361],
              [-36.604076947261177, -8.690031627233957],
              [-36.602431218699316, -8.691820392837487],
              [-36.601509209283385, -8.692822528257279],
              [-36.599209499024411, -8.695322052237112],
              [-36.599568246785466, -8.695629660342652],
              [-36.599735364608691, -8.695774628617642],
              [-36.599777224330133, -8.695810050097569],
              [-36.599795157844902, -8.695824863466997],
              [-36.599887140882451, -8.695896103753682],
              [-36.600078149554982, -8.696023839762793],
              [-36.600858618309182, -8.696546008791504],
              [-36.601094000851681, -8.696681171378399],
              [-36.60141888760279, -8.696860947528288],
              [-36.601501931196964, -8.69690842226864],
              [-36.601548865749251, -8.696936261870343],
              [-36.601679477830174, -8.697016590689206],
              [-36.602095516304836, -8.697278377999131],
              [-36.602135878542924, -8.69730162282451],
              [-36.602347947074094, -8.69740925276102],
              [-36.602409516959263, -8.697447858663944],
              [-36.602615624597753, -8.697609558916655],
              [-36.60273490880288, -8.69771355310365],
              [-36.602783249786967, -8.697763561569195],
              [-36.602823144900832, -8.697813078293292],
              [-36.60284890831759, -8.697849331231657],
              [-36.602878281510911, -8.697895982503772],
              [-36.602907209117255, -8.69794781876683],
              [-36.602943081858896, -8.698016419162535],
              [-36.602990178489073, -8.698113156618872],
              [-36.603073348732693, -8.698294096454401],
              [-36.603269456930263, -8.698764472930472],
              [-36.603323433317875, -8.698888275066514],
              [-36.603353922116142, -8.698954154347325],
              [-36.603429686993678, -8.699112588854939],
              [-36.603710229532844, -8.699596326684359],
              [-36.603955839246453, -8.700174801784062],
              [-36.603976845272648, -8.700233679396346],
              [-36.604026486107749, -8.700381620435152],
              [-36.604041915408672, -8.700423835836981],
              [-36.604051345053954, -8.700448061421287],
              [-36.604125374026928, -8.700627376170894],
              [-36.604253550814619, -8.700927209789668],
              [-36.604403298627133, -8.70126494944707],
              [-36.604429493481987, -8.701321881659272],
              [-36.604444415528548, -8.701353923537599],
              [-36.604463771104783, -8.701395127974148],
              [-36.604481741069321, -8.701433029990007],
              [-36.604489372248388, -8.701448100281501],
              [-36.604496119843752, -8.701460552446029],
              [-36.604503057830101, -8.701472557941619],
              [-36.604514248866444, -8.701491140287844],
              [-36.604553542726798, -8.70155565677527],
              [-36.604561328098143, -8.701570074896866],
              [-36.604569366533426, -8.701585987505156],
              [-36.604588259862361, -8.701626153336251],
              [-36.604597166928471, -8.701644086820062],
              [-36.604605065065257, -8.70165846832945],
              [-36.604612682363218, -8.701669824961765],
              [-36.604617145866648, -8.701675749996799],
              [-36.604622736046998, -8.701681458192626],
              [-36.604628405042455, -8.701686569727494],
              [-36.604635496509381, -8.701692138087665],
              [-36.604649020434195, -8.701700732739644],
              [-36.604668314009892, -8.701710408748106],
              [-36.604843214318713, -8.701786289404557],
              [-36.605703475579745, -8.700614189896321],
              [-36.607207448106031, -8.699899193488562],
              [-36.608850443368276, -8.699118003759608],
              [-36.609037846916266, -8.699028895062888],
              [-36.612660754175216, -8.701816273194037],
              [-36.612537560159296, -8.702078406455191],
              [-36.617071087637619, -8.701670041388091],
              [-36.616869093370504, -8.701337543561477],
              [-36.616675998131832, -8.701019832948791],
              [-36.616708884575189, -8.700861065510491],
              [-36.618483035198139, -8.697579911546827],
              [-36.617730111149264, -8.696938129840065],
              [-36.616982816363475, -8.696699284184341],
              [-36.61637599043668, -8.69658269357579],
              [-36.615230087723624, -8.696064342561264],
              [-36.61444615362489, -8.695731271078781],
              [-36.613819025883146, -8.695464795033297],
              [-36.614088644538008, -8.693981228722684],
              [-36.614446252116423, -8.692012887915418],
              [-36.614409628338677, -8.691929873083069],
              [-36.616681226273357, -8.688543834510481],
              [-36.618183588150039, -8.686304371526449],
              [-36.619999691236281, -8.683597165282876],
              [-36.619662773568265, -8.683429263770021],
              [-36.61953231821682, -8.683369927012183],
              [-36.619413212310711, -8.683319021396569],
              [-36.619344395305397, -8.683287539080208],
              [-36.619281135940199, -8.683255046429004],
              [-36.618914412508126, -8.683052486703119],
              [-36.618365203872195, -8.682751887531074],
              [-36.618315058059807, -8.682726721966027],
              [-36.618272264143194, -8.682708390399211],
              [-36.618211389962653, -8.682685238534393],
              [-36.618053588315973, -8.682637379883037],
              [-36.61798664046362, -8.682618822718535],
              [-36.617889727626498, -8.682593658791923],
              [-36.617763137156672, -8.682563905304818],
              [-36.617739985231402, -8.682559431189791],
              [-36.617721545982043, -8.682557076595666],
              [-36.617692602217183, -8.682555066903877],
              [-36.617649104373122, -8.682553190190841],
              [-36.617569946919488, -8.682549597704917],
              [-36.617176480969825, -8.682538255659376],
              [-36.617048079169621, -8.682534947748564],
              [-36.616969779940085, -8.68253206963591],
              [-36.616908000695965, -8.682526607548301],
              [-36.616873067876426, -8.682522656899106],
              [-36.616794675188743, -8.68251082072482],
              [-36.616702225183936, -8.682491730696979],
              [-36.616649941047029, -8.682479460561067],
              [-36.616582685289444, -8.682462170220502],
              [-36.616555801102557, -8.682454761535404],
              [-36.616496721846765, -8.682437522303641],
              [-36.616412862101924, -8.6824123650066],
              [-36.616047433811815, -8.682296213177946],
              [-36.615997349891188, -8.6822800378451],
              [-36.61593073039964, -8.682257969420206],
              [-36.61587375438085, -8.682239376318094],
              [-36.61585846379559, -8.682234289369866],
              [-36.615838019845768, -8.682227452183582],
              [-36.615770419310238, -8.68220464047759],
              [-36.61523654661233, -8.682017449859259],
              [-36.614955768523039, -8.68191869471864],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0042000',
        uf: 'PE',
        nome_proje: 'PA BORBOREMA',
        municipio: 'CARNAIBA',
        area_hecta: '557.4455',
        capacidade: 22.0,
        num_famili: 19.0,
        fase: 5.0,
        data_de_cr: '12/11/1991',
        forma_obte: 'Desapropriação',
        data_obten: '30/03/1988',
        area_calc_: 557.0908,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.912008330000603, -7.73687833],
              [-37.91118306000061, -7.73705194],
              [-37.910633890000611, -7.737494719999989],
              [-37.908783330000617, -7.737940829999985],
              [-37.904522220000629, -7.7385225],
              [-37.904492500000636, -7.7384925],
              [-37.903538890000632, -7.73865056],
              [-37.90166333000063, -7.73990722],
              [-37.898904170000641, -7.740463889999985],
              [-37.897626110000644, -7.7409625],
              [-37.897596110000656, -7.74097361],
              [-37.894663330000661, -7.741867219999985],
              [-37.894534440000655, -7.74234583],
              [-37.894184170000656, -7.74333722],
              [-37.894185560000658, -7.74435417],
              [-37.894256110000661, -7.74498083],
              [-37.894528330000661, -7.74614917],
              [-37.894906940000652, -7.746806669999986],
              [-37.894922220000652, -7.74690583],
              [-37.895793610000652, -7.748506389999986],
              [-37.896094170000652, -7.750284169999989],
              [-37.896999170000647, -7.751520279999989],
              [-37.897908610000648, -7.752019169999989],
              [-37.899795830000649, -7.75379861],
              [-37.900004720000645, -7.75389667],
              [-37.901992220000629, -7.754296389999989],
              [-37.905188060000626, -7.75453806],
              [-37.905851110000626, -7.75454528],
              [-37.905944170000623, -7.75458722],
              [-37.906311940000627, -7.75513667],
              [-37.906855830000623, -7.75595611],
              [-37.906589170000622, -7.756295],
              [-37.906237500000628, -7.75680639],
              [-37.906106390000616, -7.7570825],
              [-37.905886940000627, -7.75789111],
              [-37.906004170000628, -7.75801306],
              [-37.906343060000623, -7.75833222],
              [-37.907349170000622, -7.759323329999988],
              [-37.90801778000062, -7.760093609999988],
              [-37.90905500000062, -7.76071611],
              [-37.909141670000608, -7.76073],
              [-37.909093890000619, -7.761209439999985],
              [-37.909077780000615, -7.76203528],
              [-37.909679720000611, -7.76299333],
              [-37.910230280000611, -7.76308056],
              [-37.912691110000601, -7.76369722],
              [-37.913002500000601, -7.763755],
              [-37.915036670000596, -7.76399472],
              [-37.915355280000604, -7.76393028],
              [-37.9159786100006, -7.76341667],
              [-37.917850280000593, -7.76215083],
              [-37.919396390000585, -7.76110833],
              [-37.918653060000587, -7.76009972],
              [-37.917435280000596, -7.759383889999986],
              [-37.9170322200006, -7.75899444],
              [-37.915908890000594, -7.75809833],
              [-37.916284440000588, -7.757769439999986],
              [-37.917106670000592, -7.757044719999984],
              [-37.917370000000588, -7.75681222],
              [-37.916829170000589, -7.75568833],
              [-37.91747333000059, -7.75518944],
              [-37.918131390000589, -7.75441417],
              [-37.919098890000591, -7.75275222],
              [-37.919318060000585, -7.75231139],
              [-37.919364170000591, -7.75219361],
              [-37.919526670000593, -7.7518425],
              [-37.920871390000578, -7.74988167],
              [-37.92056333000059, -7.74891361],
              [-37.920081390000583, -7.747731389999988],
              [-37.918996390000579, -7.744865],
              [-37.920787500000593, -7.74323417],
              [-37.921874720000581, -7.74260139],
              [-37.921603890000583, -7.741996389999989],
              [-37.921556670000584, -7.74191056],
              [-37.921012500000586, -7.74105611],
              [-37.920889170000585, -7.74067556],
              [-37.920791670000582, -7.740261939999985],
              [-37.920112780000586, -7.74039028],
              [-37.919930000000591, -7.74037444],
              [-37.918977780000588, -7.74016389],
              [-37.918515830000594, -7.73946806],
              [-37.917411390000588, -7.73947083],
              [-37.916661110000597, -7.739707219999986],
              [-37.916450000000587, -7.73969278],
              [-37.915768060000595, -7.73949611],
              [-37.9153877800006, -7.73943611],
              [-37.914216110000595, -7.739288329999986],
              [-37.913610560000606, -7.73914194],
              [-37.912473890000605, -7.738826389999986],
              [-37.911905000000608, -7.738593609999988],
              [-37.911713060000608, -7.738431939999988],
              [-37.911921940000603, -7.73742444],
              [-37.912008330000603, -7.73687833],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0329000',
        uf: 'PE',
        nome_proje: 'PA PEREIROS',
        municipio: 'IBIRAJUBA',
        area_hecta: '871.0685',
        capacidade: 45.0,
        num_famili: 32.0,
        fase: 4.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '03/02/2004',
        area_calc_: 871.6905,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.196537078083381, -8.693700122438122],
              [-36.185579930887691, -8.696457187996158],
              [-36.185398522830759, -8.696516379400856],
              [-36.185222300599264, -8.696610664040112],
              [-36.185062465102909, -8.696760001296454],
              [-36.185020830371421, -8.69680287959077],
              [-36.185104029415356, -8.696957447831643],
              [-36.185181483372283, -8.697066709286483],
              [-36.18529457455702, -8.697145877460422],
              [-36.185451180273411, -8.697185795100266],
              [-36.185552332334318, -8.697210124080801],
              [-36.18580688503679, -8.697265854143502],
              [-36.18601899596537, -8.69731449409556],
              [-36.186235422006462, -8.697368948124565],
              [-36.186455311392741, -8.697470318258326],
              [-36.186641457560448, -8.697579208736844],
              [-36.186818648772636, -8.697683515548414],
              [-36.18700296863306, -8.697757337380198],
              [-36.187202402307491, -8.697837866281532],
              [-36.18741479432223, -8.697921924119639],
              [-36.187638456927033, -8.697992332270005],
              [-36.187862021858685, -8.698075840063773],
              [-36.188027103571898, -8.698245469000595],
              [-36.188130934706891, -8.698421873082609],
              [-36.188285092353645, -8.698583109225687],
              [-36.188422305334875, -8.69875180936492],
              [-36.188554018279028, -8.698915319003216],
              [-36.188690012493993, -8.699064856306149],
              [-36.188852288202689, -8.69917257590863],
              [-36.189030895281384, -8.69928204240933],
              [-36.189179433485776, -8.699381167694133],
              [-36.189328038525126, -8.699471348968141],
              [-36.189488972891652, -8.699539847379796],
              [-36.189639786631012, -8.69960176590275],
              [-36.18977986795187, -8.699641740828747],
              [-36.1899059425416, -8.699672667770217],
              [-36.190007187358937, -8.699696906192642],
              [-36.190090116170254, -8.699717666415477],
              [-36.190257448635101, -8.699768413230224],
              [-36.1902981813911, -8.699773412195572],
              [-36.190349396714808, -8.699785174351504],
              [-36.190400209091649, -8.6998022640729],
              [-36.190453274548851, -8.699821629136089],
              [-36.190513099236668, -8.699847820234424],
              [-36.19055152667368, -8.69988189429418],
              [-36.190596595774061, -8.699914210437889],
              [-36.19063014040821, -8.699945537984158],
              [-36.19067335584505, -8.699982899936934],
              [-36.190728148645235, -8.700038507393264],
              [-36.190681690303236, -8.700119387395128],
              [-36.18982733460242, -8.701640322985776],
              [-36.192767574051061, -8.704689160552126],
              [-36.188367891095716, -8.708602512015652],
              [-36.188401166639657, -8.70866988682468],
              [-36.189513659243538, -8.710923357956466],
              [-36.189561068106549, -8.711019387351508],
              [-36.189361965651095, -8.710990810047342],
              [-36.189231709868501, -8.710972138191966],
              [-36.188209256985985, -8.710825617324524],
              [-36.187480034427111, -8.711089095571277],
              [-36.187372412956364, -8.711127961628005],
              [-36.185080032450131, -8.711956278239885],
              [-36.185583521434353, -8.714919352657498],
              [-36.185597176489161, -8.714999682745153],
              [-36.185654928227152, -8.715339366928598],
              [-36.185751976013393, -8.715427362179827],
              [-36.185839140135535, -8.715513477217309],
              [-36.185941194560563, -8.715624096518381],
              [-36.186067378463903, -8.715750163410435],
              [-36.186455916712212, -8.71612870902795],
              [-36.187127121866943, -8.716763678136758],
              [-36.187422717118295, -8.717124730078414],
              [-36.187605777327143, -8.717331627807722],
              [-36.187939254548233, -8.717775628674962],
              [-36.188334094275035, -8.718319828202779],
              [-36.188532477736807, -8.718590625030089],
              [-36.188631879543472, -8.718704025239381],
              [-36.188824011468157, -8.718827327677801],
              [-36.189555770129175, -8.71935613280719],
              [-36.190356734556318, -8.719871626170725],
              [-36.190411138471589, -8.71990663224458],
              [-36.190659065449623, -8.720061246216254],
              [-36.190836213237297, -8.720184888875684],
              [-36.19095258321714, -8.720239417008706],
              [-36.191190763096181, -8.720313187292462],
              [-36.191235173938807, -8.720336645108114],
              [-36.191354114503262, -8.720399594561412],
              [-36.191521433968298, -8.720477538408245],
              [-36.191862016568201, -8.720579351291894],
              [-36.191918296237183, -8.720606420451263],
              [-36.191967125066029, -8.720646263936652],
              [-36.192004716377376, -8.720695239802783],
              [-36.192030536833741, -8.720751808185177],
              [-36.192068099704471, -8.720877489555672],
              [-36.192195631308849, -8.721285452283299],
              [-36.192764490017566, -8.722580015940231],
              [-36.192773509159515, -8.722600411046571],
              [-36.192828230981277, -8.72269017041863],
              [-36.192946556836155, -8.722835512841776],
              [-36.193211398145294, -8.723193896439533],
              [-36.193649535437743, -8.723705708605657],
              [-36.19381037054135, -8.723898256222389],
              [-36.193996222565239, -8.724109148889871],
              [-36.19421104808935, -8.724331459023539],
              [-36.194273288203952, -8.72442380372601],
              [-36.194518063321098, -8.725280491257077],
              [-36.194834842469859, -8.725895125760657],
              [-36.194876406101024, -8.726032401470716],
              [-36.194893245706794, -8.726087819291262],
              [-36.194910881623855, -8.726146043805192],
              [-36.195010947675435, -8.726389821325641],
              [-36.19520329719132, -8.726849491500372],
              [-36.195216910163985, -8.726935694314605],
              [-36.19520193139752, -8.727020149755873],
              [-36.195113761396271, -8.727153123136059],
              [-36.194938748118808, -8.727399473963551],
              [-36.19479525671143, -8.727559232910862],
              [-36.194610565785609, -8.727812468704151],
              [-36.194550530082154, -8.727935440581277],
              [-36.19449128845185, -8.728207312579316],
              [-36.194483255264743, -8.728395177949217],
              [-36.194510695717952, -8.728830408819379],
              [-36.194495977937009, -8.729062134076578],
              [-36.194451869381503, -8.729508309786274],
              [-36.194456281631481, -8.73004781306153],
              [-36.195346425914501, -8.730906656626063],
              [-36.198834734708747, -8.734272280805421],
              [-36.199034531737276, -8.734465116335288],
              [-36.201166950620035, -8.736522486894035],
              [-36.207721818993569, -8.726201450274287],
              [-36.206355250995237, -8.723549301290477],
              [-36.206113556374213, -8.723080234768553],
              [-36.205681819516123, -8.722242318146309],
              [-36.205752271467013, -8.72221997856445],
              [-36.208483880946261, -8.721352851219583],
              [-36.209097194470125, -8.721158231681477],
              [-36.211194001466474, -8.720492589614068],
              [-36.211017363243819, -8.718622859483437],
              [-36.210917259681253, -8.718321440172241],
              [-36.210894433572442, -8.718252516338644],
              [-36.209903796438887, -8.71526948100138],
              [-36.209711675418475, -8.714690825587228],
              [-36.209683340532216, -8.714605598398016],
              [-36.209708056206857, -8.714578946143423],
              [-36.209732771877881, -8.714552293886101],
              [-36.209749340462373, -8.714534436035398],
              [-36.210554704606793, -8.713665766973438],
              [-36.211733103499569, -8.713531214951848],
              [-36.212793767471176, -8.70970283418362],
              [-36.211329119873341, -8.709475339179459],
              [-36.208764440803272, -8.69662270851201],
              [-36.205089798735699, -8.695234172893437],
              [-36.204579651559186, -8.695041325195019],
              [-36.202816686335375, -8.694375148162871],
              [-36.202782893917494, -8.69437706821302],
              [-36.202700230818536, -8.69438161063723],
              [-36.201407774223036, -8.694453424399574],
              [-36.201304319523707, -8.694237632904704],
              [-36.20101113569622, -8.694223551071186],
              [-36.200914466394735, -8.694218955211618],
              [-36.200518622885951, -8.694199962025893],
              [-36.200508243440218, -8.694215967817218],
              [-36.200505672284379, -8.69421983391576],
              [-36.200481054976997, -8.694257870503924],
              [-36.200477105768087, -8.694263894843612],
              [-36.200469298146203, -8.69427594419053],
              [-36.200422453745169, -8.694348059577031],
              [-36.200412625779862, -8.694363165928971],
              [-36.200385345153606, -8.6944052486222],
              [-36.200375517856052, -8.694420264628103],
              [-36.200350166093365, -8.694459379985581],
              [-36.200328672742749, -8.694492470335284],
              [-36.200320865110015, -8.694504519676027],
              [-36.200307179387821, -8.694525560682564],
              [-36.200293493663871, -8.694546601688069],
              [-36.199828535824722, -8.695262720706911],
              [-36.199803919703733, -8.695300576518148],
              [-36.198988632021774, -8.696556119170744],
              [-36.19894950256527, -8.696616364924036],
              [-36.198887685661546, -8.696711499044694],
              [-36.198814754004609, -8.696823807967064],
              [-36.196537078083381, -8.693700122438122],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0031000',
        uf: 'PE',
        nome_proje: 'PA RAMADA DA QUIXABEIRA',
        municipio: 'IGUARACI',
        area_hecta: '672.2394',
        capacidade: 13.0,
        num_famili: 11.0,
        fase: 3.0,
        data_de_cr: '31/01/1990',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/1988',
        area_calc_: 672.2423,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.344960730262834, -7.886395265697163],
              [-37.339158018020136, -7.919412215051524],
              [-37.340056625157175, -7.919808484973315],
              [-37.34230867405396, -7.920801661319209],
              [-37.342599342095461, -7.920846127895593],
              [-37.344825113864182, -7.91849863249259],
              [-37.345230987981125, -7.91805845232849],
              [-37.3457464947925, -7.91762105733543],
              [-37.346472333885856, -7.917246252046977],
              [-37.346627311681431, -7.917226521933762],
              [-37.34719394407098, -7.917044763264894],
              [-37.34751516995496, -7.916976322277531],
              [-37.347711171995051, -7.916994007226211],
              [-37.347792933870672, -7.916981491470667],
              [-37.347882478976466, -7.916927503813021],
              [-37.347742261066109, -7.917794978937536],
              [-37.346230297208614, -7.927853609689128],
              [-37.34620279853695, -7.92799455530491],
              [-37.346189521585778, -7.928048935273242],
              [-37.346090860284399, -7.92874965812847],
              [-37.345521742149174, -7.933088356938253],
              [-37.344943903454897, -7.937493204218338],
              [-37.343136007798897, -7.946449569460349],
              [-37.344142199866312, -7.946199771711302],
              [-37.345258050371896, -7.946514264609417],
              [-37.34614312585537, -7.947224959678795],
              [-37.346478070843929, -7.947315265465296],
              [-37.347192025116414, -7.947136994938879],
              [-37.347966939765946, -7.946842233508491],
              [-37.349849549617552, -7.934251515725729],
              [-37.349987792924502, -7.93367663165603],
              [-37.354565845554063, -7.917881812656129],
              [-37.354606302137711, -7.917765420694121],
              [-37.354995904944424, -7.915366302054045],
              [-37.358105817273668, -7.896616511653304],
              [-37.357112310626142, -7.896098298729532],
              [-37.356149219639526, -7.895525860879415],
              [-37.35529595978501, -7.894791006328357],
              [-37.353518915130877, -7.89379753061273],
              [-37.352995334382641, -7.893765178740945],
              [-37.352949026176653, -7.89352809557377],
              [-37.344960730262834, -7.886395265697163],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0032000',
        uf: 'PE',
        nome_proje: 'PA CALDEIRÕES',
        municipio: 'IGUARACI',
        area_hecta: '1153.2593',
        capacidade: 39.0,
        num_famili: 20.0,
        fase: 6.0,
        data_de_cr: '31/01/1990',
        forma_obte: 'Desapropriação',
        data_obten: '01/07/1988',
        area_calc_: 1133.802,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.311238537105844, -7.908957863482536],
              [-37.311142545163179, -7.908968958336482],
              [-37.311084296767689, -7.908975684930009],
              [-37.310976892606028, -7.909785488290515],
              [-37.310842556472849, -7.9101935447654],
              [-37.309602524343788, -7.910655083271088],
              [-37.309079890270333, -7.910833983718027],
              [-37.308878900470411, -7.911597112338765],
              [-37.308787717059893, -7.913698693059244],
              [-37.308727921081633, -7.914218264469152],
              [-37.308029977881475, -7.916532126994968],
              [-37.308304377540821, -7.917804879860804],
              [-37.309017361485409, -7.918763671471829],
              [-37.309124155937468, -7.919728775360725],
              [-37.30989608223625, -7.92042324201209],
              [-37.310291182942038, -7.921476768274484],
              [-37.309983595468402, -7.921646863848],
              [-37.309219230267026, -7.921899376429611],
              [-37.309166038540859, -7.922799996258834],
              [-37.308526872611488, -7.923440185066336],
              [-37.308458912199889, -7.926157785803511],
              [-37.308323551222799, -7.92745780944572],
              [-37.308124069298223, -7.928003395523445],
              [-37.30878327009016, -7.928721731385843],
              [-37.308988466038237, -7.928997525861391],
              [-37.309264366105076, -7.929259773351646],
              [-37.310316098070999, -7.930396709393628],
              [-37.313037642902842, -7.933338831379463],
              [-37.313782211924305, -7.934143767659115],
              [-37.314400371165291, -7.934811930871123],
              [-37.31512706656526, -7.9355976247027],
              [-37.315820987922265, -7.936347741167122],
              [-37.316767921230145, -7.937371401585959],
              [-37.318342601990182, -7.939073657494253],
              [-37.318244053638068, -7.939332398362236],
              [-37.31954517127118, -7.940643390211841],
              [-37.320691048636611, -7.94130192542054],
              [-37.32696923229625, -7.943893315570009],
              [-37.33077232828137, -7.945967171504223],
              [-37.331198773580461, -7.947271007862975],
              [-37.331790529723328, -7.947729276424011],
              [-37.332838701781526, -7.948247605740973],
              [-37.333623988128373, -7.948396149626528],
              [-37.335028397613101, -7.94682821478967],
              [-37.336073009821945, -7.944673549235531],
              [-37.339541988037368, -7.945160494031149],
              [-37.340900611866836, -7.946155469129083],
              [-37.343136007798897, -7.946449569460349],
              [-37.344943903454897, -7.937493204218338],
              [-37.346090860284399, -7.92874965812847],
              [-37.346189521585778, -7.928048935273242],
              [-37.34620280575983, -7.927992746938901],
              [-37.346230297208614, -7.927853609689128],
              [-37.347742261066109, -7.917794978937536],
              [-37.347882478976466, -7.916927503813021],
              [-37.347792933870672, -7.916981491470667],
              [-37.347711171995051, -7.916994007226211],
              [-37.34751516995496, -7.916976322277531],
              [-37.347193944070987, -7.917044763264858],
              [-37.346627311681431, -7.917226521933762],
              [-37.346472333885856, -7.917246252046977],
              [-37.3457464947925, -7.91762105733543],
              [-37.345230987981125, -7.91805845232849],
              [-37.344825113864182, -7.91849863249259],
              [-37.342599342095461, -7.920846127895593],
              [-37.34230867405396, -7.920801661319209],
              [-37.340056625157175, -7.919808484973315],
              [-37.339158018020136, -7.919412215051524],
              [-37.338654169727917, -7.919156310220369],
              [-37.338305268386264, -7.918979144720608],
              [-37.33835286255411, -7.918847954943297],
              [-37.337303874783416, -7.918366359609784],
              [-37.337225481588781, -7.918330331412076],
              [-37.336126492944231, -7.920048798136483],
              [-37.335616745642, -7.91999766603193],
              [-37.334946268788478, -7.919930520255991],
              [-37.333381220765261, -7.919773629367296],
              [-37.332277693028132, -7.919662974727516],
              [-37.332164984137307, -7.919613155081422],
              [-37.332070904505883, -7.919689182952351],
              [-37.331800853728602, -7.919795701427686],
              [-37.330923511103322, -7.92031499369854],
              [-37.330456521354549, -7.920591433938733],
              [-37.33018107278707, -7.920754441448003],
              [-37.329191852618877, -7.920904371549718],
              [-37.328875421693397, -7.920952381684742],
              [-37.328778644696762, -7.920932463262178],
              [-37.328627081265971, -7.920869918688464],
              [-37.328305575371921, -7.920737160518399],
              [-37.328177054937477, -7.920762685851803],
              [-37.325762313266289, -7.918725262721725],
              [-37.325237285853262, -7.918282362736702],
              [-37.324752862621075, -7.917352087110358],
              [-37.323241698750039, -7.917264095545709],
              [-37.322463767699546, -7.917218832429639],
              [-37.322161428306472, -7.917146999353035],
              [-37.321787151435629, -7.917057968357551],
              [-37.321200955776177, -7.916762020054266],
              [-37.320816132625801, -7.916567698544313],
              [-37.320176083788674, -7.91313337291226],
              [-37.31973060447924, -7.913048394366127],
              [-37.3176803701597, -7.912657302412993],
              [-37.317558036056582, -7.912633933376545],
              [-37.316326969035217, -7.911935549521347],
              [-37.315293603639162, -7.911349356342027],
              [-37.314614467139229, -7.910964054497363],
              [-37.313952613399685, -7.910588586787935],
              [-37.313145180847449, -7.910130520628256],
              [-37.312531826610154, -7.909782553484439],
              [-37.311736521504116, -7.909331406032594],
              [-37.311579541775991, -7.909242342172463],
              [-37.311238537105844, -7.908957863482536],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0402000',
        uf: 'PE',
        nome_proje: 'PA CEDRO BRANCO',
        municipio: 'IGUARACI',
        area_hecta: '786.8260',
        capacidade: 30.0,
        num_famili: 22.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/2008',
        area_calc_: 782.8169,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.47398100574685, -7.838445663012878],
              [-37.470928802945487, -7.838559742845884],
              [-37.471065579966577, -7.839717955653287],
              [-37.470780248748447, -7.839907084258519],
              [-37.470374153294941, -7.839966556154371],
              [-37.470041216616082, -7.839956756334875],
              [-37.469996335515198, -7.839955417752253],
              [-37.468749954949985, -7.840898092681604],
              [-37.468181404329613, -7.84104513606257],
              [-37.466897299419664, -7.841367349860537],
              [-37.463711446332894, -7.842166850918755],
              [-37.463893516568362, -7.842541151707664],
              [-37.463929669471284, -7.842870069231794],
              [-37.463510012227381, -7.843659033716192],
              [-37.463312709279407, -7.844124183375466],
              [-37.463154354438501, -7.84529262022077],
              [-37.467915011418, -7.845399770198354],
              [-37.471684357660997, -7.845484549839961],
              [-37.473165667275396, -7.845942867755721],
              [-37.475306843399551, -7.846605402288933],
              [-37.477535925212955, -7.847384984741656],
              [-37.478837731019759, -7.847885587691207],
              [-37.481786482176346, -7.848763060539448],
              [-37.481895458337135, -7.848807775280349],
              [-37.481978290731107, -7.851406446524575],
              [-37.481964672020574, -7.85176068559498],
              [-37.481874507473087, -7.851967887267647],
              [-37.481530004240838, -7.852418139244822],
              [-37.478322167422313, -7.85240862253034],
              [-37.475783196100366, -7.852401144747367],
              [-37.470406108023752, -7.85238514551892],
              [-37.468933547898786, -7.852380780896487],
              [-37.46889129125141, -7.852380536604412],
              [-37.466622286287695, -7.85237389601872],
              [-37.466593919808673, -7.852394047809101],
              [-37.467127837960113, -7.85294189134267],
              [-37.467541239231593, -7.853440917703733],
              [-37.467836397589728, -7.853963294740588],
              [-37.468057261723914, -7.854344428058402],
              [-37.468267853233101, -7.854707890980975],
              [-37.468918548172013, -7.855733332672134],
              [-37.468797722774617, -7.855644366214638],
              [-37.46889122023768, -7.855791738251068],
              [-37.469818694233162, -7.8572097288243],
              [-37.469918877864636, -7.857215338273887],
              [-37.470045373540941, -7.857291394362782],
              [-37.470151375369191, -7.858209416027656],
              [-37.469941833530235, -7.858648029433316],
              [-37.469803911691457, -7.858794739478658],
              [-37.469667566822082, -7.859327842320477],
              [-37.469595923096541, -7.859378490762633],
              [-37.469592793692861, -7.859440601510614],
              [-37.469733639302682, -7.85946127939393],
              [-37.469794909968954, -7.859494507786902],
              [-37.469729923420637, -7.85983200940873],
              [-37.469609472328408, -7.86045731335255],
              [-37.469997752364058, -7.861008875066699],
              [-37.470148329813846, -7.861615996214725],
              [-37.470339755118651, -7.861964649881219],
              [-37.470893943947829, -7.862974096150856],
              [-37.471307186199233, -7.86376772636834],
              [-37.471555857774895, -7.864803639751631],
              [-37.472336614186808, -7.865928207754181],
              [-37.472977048731693, -7.866839674525264],
              [-37.47394710859767, -7.868152562315073],
              [-37.474482126372308, -7.86887845336466],
              [-37.474930393111272, -7.86950844907664],
              [-37.475378661113737, -7.870138444550487],
              [-37.476130982426248, -7.871083411129798],
              [-37.476138934588825, -7.871091126180898],
              [-37.477108475755095, -7.872029646650509],
              [-37.477766833691739, -7.872781211888972],
              [-37.478456008809218, -7.872667967860215],
              [-37.480097482552523, -7.873698532084031],
              [-37.480549770725553, -7.873664181615996],
              [-37.481078580721444, -7.873759145220517],
              [-37.481632362039129, -7.873944895574523],
              [-37.482159412001522, -7.874100075072374],
              [-37.48246257734246, -7.872754552450526],
              [-37.483161905108048, -7.869650053145311],
              [-37.483863335852774, -7.866536786594473],
              [-37.483992191064146, -7.865964856625728],
              [-37.485051773079761, -7.861261292331123],
              [-37.485706068904065, -7.85835663976003],
              [-37.486283117879232, -7.855794872375488],
              [-37.486866976310779, -7.853203015241783],
              [-37.487498748644775, -7.850398374695046],
              [-37.489854161297259, -7.85043649825188],
              [-37.490588924092741, -7.850445465643438],
              [-37.499689563908618, -7.850479384045133],
              [-37.501929375844064, -7.850516123539321],
              [-37.505617260457278, -7.850512875872758],
              [-37.506380625178593, -7.849598931274358],
              [-37.506982557380034, -7.84889239338506],
              [-37.507466232364045, -7.848416203971757],
              [-37.507572397005625, -7.848016261034088],
              [-37.507613389019916, -7.846922060904852],
              [-37.507445524779733, -7.846823804521821],
              [-37.50614997997998, -7.84557204092779],
              [-37.505543219347906, -7.845041884857149],
              [-37.505469239423512, -7.844935822852094],
              [-37.505061972365517, -7.84473760786635],
              [-37.504787262264955, -7.844519427033784],
              [-37.504441506794642, -7.844084692929591],
              [-37.504323371377012, -7.843977027086963],
              [-37.50344431295931, -7.843595647817068],
              [-37.502101403488446, -7.843093344042719],
              [-37.501004196561944, -7.842705125564196],
              [-37.500453541111348, -7.842539854156442],
              [-37.499745943175512, -7.842418241457271],
              [-37.499750967324182, -7.84248390902833],
              [-37.499133042729277, -7.842441014676771],
              [-37.498844633190394, -7.842453731244682],
              [-37.498679812378221, -7.842342461490668],
              [-37.498272123099014, -7.842238554282645],
              [-37.498106468831274, -7.842258580615186],
              [-37.497852352665824, -7.842191029726855],
              [-37.497462216799178, -7.841996938829131],
              [-37.497007870769551, -7.841375896091547],
              [-37.496750034138898, -7.84110722301959],
              [-37.496142547024107, -7.840532383448286],
              [-37.496271298694033, -7.840283718471726],
              [-37.49656533822013, -7.839358982608413],
              [-37.496573703110556, -7.839226447329617],
              [-37.49392198003283, -7.8394978375573],
              [-37.492531220638803, -7.839172029511902],
              [-37.492402563507916, -7.839973378617442],
              [-37.49237907032218, -7.840025741742004],
              [-37.485315924264334, -7.839380798340285],
              [-37.482646485721318, -7.839137076945445],
              [-37.482411613935227, -7.839115613627851],
              [-37.481955309951722, -7.839042442397605],
              [-37.47945906997316, -7.838875373782442],
              [-37.476329893655524, -7.838634429722151],
              [-37.47398100574685, -7.838445663012878],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0217000',
        uf: 'PE',
        nome_proje: 'PA CAMARÇO',
        municipio: 'JABOATAO DOS GUARARAPES',
        area_hecta: '391.6000',
        capacidade: 50.0,
        num_famili: 50.0,
        fase: 4.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '04/11/1999',
        area_calc_: 381.6998,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.049630725071637, -8.163253296227834],
              [-35.048524940702876, -8.163195794759055],
              [-35.047606515209651, -8.164529313070467],
              [-35.047541543281596, -8.164954247918448],
              [-35.047422662943077, -8.165731922913167],
              [-35.046839321908372, -8.166948486629156],
              [-35.046986939339511, -8.16762609740536],
              [-35.046934257223057, -8.167756629552882],
              [-35.046298547906325, -8.168952304953887],
              [-35.045751743788564, -8.169488156488253],
              [-35.043820286873931, -8.171437986161809],
              [-35.041129171251718, -8.173857205354789],
              [-35.040463326611999, -8.174965884526308],
              [-35.040165617259163, -8.175192301823433],
              [-35.038283567426461, -8.176787681481871],
              [-35.037742983809217, -8.17733777895706],
              [-35.036148754209286, -8.17895999680545],
              [-35.034833518644199, -8.180298305936057],
              [-35.038034525289838, -8.181825115662161],
              [-35.041753337911878, -8.183598779513941],
              [-35.043982868098688, -8.185344235491431],
              [-35.044522738225382, -8.185633040474551],
              [-35.04627837770753, -8.186769777900295],
              [-35.048751467986698, -8.186315271931194],
              [-35.051282654271304, -8.185921295568098],
              [-35.053381967451855, -8.185777646575614],
              [-35.054615169856469, -8.185581896272158],
              [-35.054796573779939, -8.185553130173512],
              [-35.054936732014525, -8.185513816473659],
              [-35.055974417830818, -8.18521383313565],
              [-35.055578215469161, -8.183023043808976],
              [-35.055526018981794, -8.182646165133004],
              [-35.055084514282768, -8.179456605403692],
              [-35.054793580330447, -8.177355404675019],
              [-35.0545244137259, -8.175304806015049],
              [-35.055944210502183, -8.174577650128551],
              [-35.057397844387125, -8.173833142496672],
              [-35.058634840204562, -8.173199658905657],
              [-35.059779531518217, -8.172613288404927],
              [-35.059907207815854, -8.172458688970304],
              [-35.059769938276951, -8.16969987616965],
              [-35.05804877199791, -8.16862113888936],
              [-35.05730942874893, -8.168157808394209],
              [-35.05584334256698, -8.168415846961913],
              [-35.055709656982813, -8.168424662202387],
              [-35.054977252555794, -8.16763251019562],
              [-35.055061564130789, -8.166662371703348],
              [-35.055153928470872, -8.165528298937499],
              [-35.055095702371148, -8.165478965557952],
              [-35.052114219099501, -8.164705181052593],
              [-35.052005145069863, -8.164736469787393],
              [-35.051270087680244, -8.163919461190559],
              [-35.049630725071637, -8.163253296227834],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0387000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DOS NEGROS',
        municipio: 'SAO JOSE DO EGITO',
        area_hecta: '1197.8700',
        capacidade: 41.0,
        num_famili: 41.0,
        fase: 3.0,
        data_de_cr: '07/10/2009',
        forma_obte: 'Desapropriação',
        data_obten: '21/12/2006',
        area_calc_: 1194.5579,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.198018354072964, -7.651883365442743],
              [-37.197319629759036, -7.652162717110741],
              [-37.197166180581902, -7.652296611444903],
              [-37.196973383425956, -7.652283328134164],
              [-37.195587868840747, -7.65280509679824],
              [-37.194711103749171, -7.653203587004856],
              [-37.193297463689241, -7.653236723791534],
              [-37.189033986423041, -7.655653942672423],
              [-37.188362230228329, -7.655875887351354],
              [-37.185658131435254, -7.65548558935286],
              [-37.185757258835245, -7.65728957224623],
              [-37.185563493290651, -7.658126164715932],
              [-37.185395295590418, -7.658852831573292],
              [-37.185160634690725, -7.659158977114068],
              [-37.184555202921132, -7.659104619268875],
              [-37.184433335415925, -7.659783290224091],
              [-37.184312020225519, -7.660459793446481],
              [-37.183506350317664, -7.664947853974478],
              [-37.181786372726087, -7.666258002210567],
              [-37.181567430981957, -7.666424793555329],
              [-37.180072385500878, -7.667563736161549],
              [-37.177350424135945, -7.668462312064925],
              [-37.17681496142572, -7.668639060986007],
              [-37.175839147656539, -7.668961224881839],
              [-37.174626709964635, -7.6703228524025],
              [-37.174561598290481, -7.670395991263525],
              [-37.1743297464458, -7.670656300157434],
              [-37.17470602200634, -7.672445538081461],
              [-37.176077712316555, -7.673995247121473],
              [-37.17636468631035, -7.676261124855845],
              [-37.176711104310868, -7.678997040173009],
              [-37.174709748690674, -7.681787811791251],
              [-37.174395220379132, -7.683918316226523],
              [-37.174210270952166, -7.684186959731881],
              [-37.17364375212636, -7.684075784189962],
              [-37.173492801772838, -7.685168411935355],
              [-37.171467236531932, -7.686297089792218],
              [-37.169036651494878, -7.687884860187941],
              [-37.16947254422184, -7.688404059421667],
              [-37.167123535774358, -7.688803330721978],
              [-37.168548843010392, -7.690975877287019],
              [-37.168599718254136, -7.691053397046144],
              [-37.169044449267119, -7.691731127950661],
              [-37.16987962922115, -7.691098459239636],
              [-37.171173020471763, -7.692059182634281],
              [-37.171657102356576, -7.691253138843773],
              [-37.172335127561936, -7.690872859303806],
              [-37.172475511297364, -7.690938102281334],
              [-37.172669950254715, -7.691905079337165],
              [-37.17336527007366, -7.69180415644901],
              [-37.174616394937281, -7.692351053737365],
              [-37.176249808398708, -7.691245381860583],
              [-37.178732545476286, -7.689564845893273],
              [-37.181074377448965, -7.687979622019047],
              [-37.181709189144577, -7.687549866229268],
              [-37.182447639384847, -7.687897377189961],
              [-37.183524847595493, -7.690425548483478],
              [-37.183830459196656, -7.688082610354915],
              [-37.184125288363006, -7.685822805515599],
              [-37.184414956035823, -7.683603211590667],
              [-37.184656655369629, -7.681750762719804],
              [-37.187050059760416, -7.682372336278762],
              [-37.187809987616127, -7.679876427499894],
              [-37.1879697300418, -7.679351528290031],
              [-37.188060354365085, -7.679053817543571],
              [-37.188087467937287, -7.678964784280162],
              [-37.189031198214913, -7.675864959879221],
              [-37.199433316001382, -7.679501706194648],
              [-37.206401176968896, -7.681890480216715],
              [-37.206678922711795, -7.681987569417203],
              [-37.208724417445843, -7.682702692732765],
              [-37.211113475319969, -7.672180536397537],
              [-37.211843938587101, -7.67238176281882],
              [-37.212770180025807, -7.672688321088717],
              [-37.213204140484123, -7.672857933886578],
              [-37.213640584988987, -7.673018786421678],
              [-37.21410992032385, -7.673181764306607],
              [-37.214611738773719, -7.673357715512211],
              [-37.214857526782367, -7.673435000735654],
              [-37.215333235136889, -7.673591358464553],
              [-37.215768276967708, -7.673741308384523],
              [-37.215973398548542, -7.673812185699866],
              [-37.21676623910075, -7.674031384983373],
              [-37.216787726924693, -7.673964205502053],
              [-37.21670075909379, -7.673280670752862],
              [-37.216778842912809, -7.672442126187664],
              [-37.217029328117953, -7.671794348130859],
              [-37.218060583137593, -7.670737332434852],
              [-37.218255349992837, -7.670388866602028],
              [-37.218289124525739, -7.670004291231926],
              [-37.217464094313954, -7.667395208379308],
              [-37.21724834695204, -7.666753183105765],
              [-37.216917980258486, -7.665516657669013],
              [-37.216205576394756, -7.662851261636281],
              [-37.215757036291627, -7.661396166520523],
              [-37.215490866818797, -7.660871652610436],
              [-37.215419329838838, -7.660748392122018],
              [-37.21536018439194, -7.660631059934454],
              [-37.215281587809642, -7.660353160947429],
              [-37.215239052639639, -7.660186259883037],
              [-37.215170265981847, -7.660120785817376],
              [-37.215040324059672, -7.659941859057834],
              [-37.214966521261495, -7.659818679579342],
              [-37.214642320446316, -7.659214450529392],
              [-37.21402693794218, -7.658195095887109],
              [-37.213651466114499, -7.657085055133676],
              [-37.208915410869359, -7.656632310033321],
              [-37.206586765463037, -7.656341798067427],
              [-37.204299522016285, -7.656402615676832],
              [-37.202421193847435, -7.654430084907955],
              [-37.198018354072964, -7.651883365442743],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0243000',
        uf: 'PE',
        nome_proje: 'PA SÍTIO JORGE',
        municipio: 'INGAZEIRA',
        area_hecta: '1168.9879',
        capacidade: 41.0,
        num_famili: 40.0,
        fase: 3.0,
        data_de_cr: '05/12/2001',
        forma_obte: 'Compra e Venda',
        data_obten: '07/10/1984',
        area_calc_: 1170.6601,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.444139568596768, -7.701636286245549],
              [-37.444030434180547, -7.702286236751601],
              [-37.443865871980925, -7.703017170047008],
              [-37.442674910001337, -7.708748983917457],
              [-37.443830488585817, -7.709591216299892],
              [-37.443949012139335, -7.709726081199798],
              [-37.444090645722888, -7.709763259858613],
              [-37.444426413122549, -7.709839553736482],
              [-37.444643884815164, -7.7099581920419],
              [-37.445135019192684, -7.710385102403208],
              [-37.44526313137542, -7.71053047727693],
              [-37.445470655670036, -7.710737246982839],
              [-37.445819565914853, -7.711049283946695],
              [-37.446201300964738, -7.711479288038391],
              [-37.446389602351566, -7.711678131132623],
              [-37.446892190467992, -7.711964973367656],
              [-37.447740791746334, -7.712399289488192],
              [-37.44797630596198, -7.71249049404912],
              [-37.447884899379993, -7.712598408294439],
              [-37.447790014563537, -7.712700199244871],
              [-37.447805521559197, -7.713007533824041],
              [-37.443773812958653, -7.718511667245461],
              [-37.443295396911843, -7.718673167004199],
              [-37.443148765296847, -7.718805321186021],
              [-37.443131949243245, -7.719095951537456],
              [-37.443093932226098, -7.71920143977245],
              [-37.442924213143911, -7.720381046840472],
              [-37.442339447906292, -7.722100368555468],
              [-37.441745608041494, -7.722951073091791],
              [-37.441060102283409, -7.724694466319726],
              [-37.445526258442236, -7.727972073866248],
              [-37.445426105345788, -7.728077336060783],
              [-37.444124614834195, -7.729547005276881],
              [-37.444040223281327, -7.729649705587483],
              [-37.443446114965248, -7.730331930991134],
              [-37.441179069869719, -7.733534356854907],
              [-37.442465443622417, -7.734760742531103],
              [-37.438175145724337, -7.737554634727972],
              [-37.438069421938827, -7.737626702046993],
              [-37.433335668525402, -7.740691267406491],
              [-37.433582473820806, -7.740926995822343],
              [-37.433788111019339, -7.74117217529672],
              [-37.434046734154215, -7.741525794284352],
              [-37.434265936365641, -7.741889743738337],
              [-37.434382468073522, -7.742090948652915],
              [-37.434431052307318, -7.742206355587811],
              [-37.434537178763982, -7.742381333976726],
              [-37.434723627796394, -7.742608983897455],
              [-37.434931068273606, -7.742840202617344],
              [-37.435175275901749, -7.743068937421372],
              [-37.435485996990856, -7.743297916269381],
              [-37.435747898661177, -7.743475212112676],
              [-37.435891977200093, -7.743563908162422],
              [-37.436022188231256, -7.743615889647777],
              [-37.436148435260989, -7.743674840107161],
              [-37.43627421255399, -7.743742518252361],
              [-37.436451361287503, -7.74388196623226],
              [-37.436608342483076, -7.744031815514205],
              [-37.43675225407906, -7.744165903827391],
              [-37.436906706498291, -7.744289555436906],
              [-37.437079598855341, -7.744396688688301],
              [-37.437304101005395, -7.744510994680128],
              [-37.437561953314273, -7.7446001074537],
              [-37.437888201850036, -7.744891120998701],
              [-37.438200402966054, -7.745194304121796],
              [-37.438508965560864, -7.745535010394147],
              [-37.438709183102596, -7.745827307603185],
              [-37.438902540894958, -7.746081170058298],
              [-37.439057693075995, -7.746252835676096],
              [-37.439282731353302, -7.746459675056667],
              [-37.439589857034996, -7.746715699927766],
              [-37.439809914629762, -7.74684919347796],
              [-37.440161935502836, -7.747034673872442],
              [-37.441606987312774, -7.747720423343471],
              [-37.442612908307119, -7.747990786982776],
              [-37.44445432767219, -7.748467160426119],
              [-37.446073050463269, -7.749530208149105],
              [-37.447762516058134, -7.750636283570177],
              [-37.449664084498018, -7.751542784442797],
              [-37.451056134472729, -7.752132726692968],
              [-37.452679756763459, -7.752822150172574],
              [-37.452834900027604, -7.752758988621573],
              [-37.455038984948523, -7.751956893816408],
              [-37.456594529069356, -7.751395989425713],
              [-37.457321311056212, -7.751538295385837],
              [-37.458101003481588, -7.751932202095265],
              [-37.460289882653903, -7.7535048916312],
              [-37.461344469600306, -7.754241112660885],
              [-37.462245577555606, -7.749038070754018],
              [-37.462481571172319, -7.747918049349941],
              [-37.462510090404095, -7.747776733676273],
              [-37.463224048037134, -7.744213289846754],
              [-37.463811655451963, -7.74073406342012],
              [-37.464230049196786, -7.738301771111372],
              [-37.464260272711904, -7.73817268253638],
              [-37.465170665170255, -7.732792440676041],
              [-37.465174686402563, -7.732648417402165],
              [-37.465352910268287, -7.730554836606194],
              [-37.465628761334287, -7.72953796108103],
              [-37.465465962667359, -7.729058123092804],
              [-37.46509319468025, -7.712309988027046],
              [-37.464981197932268, -7.712304348559506],
              [-37.46429095838927, -7.718059018710872],
              [-37.463719250498194, -7.720548381273503],
              [-37.463440139776239, -7.722590094455569],
              [-37.463361854378626, -7.724879139008011],
              [-37.462993775192018, -7.727199003304239],
              [-37.462382879021796, -7.72817538716662],
              [-37.461761731040696, -7.728840087794474],
              [-37.461367114256703, -7.729300459130028],
              [-37.461249457692986, -7.729406535619272],
              [-37.459631853336134, -7.731418972680164],
              [-37.459109897131135, -7.732963962726714],
              [-37.458729112204004, -7.732746095395752],
              [-37.458316520605955, -7.732490575927754],
              [-37.457732093732886, -7.732197771299825],
              [-37.457409797025875, -7.732021143153723],
              [-37.457087476836328, -7.731851061875575],
              [-37.456691848216693, -7.731624409931579],
              [-37.45797494897397, -7.728703344114947],
              [-37.458348363930114, -7.727695121694968],
              [-37.4583978727429, -7.72755737375171],
              [-37.458526791010406, -7.727241829876894],
              [-37.459632187517691, -7.723117618100249],
              [-37.460738373490095, -7.719009114596973],
              [-37.461528492646849, -7.715416256261011],
              [-37.462756551814039, -7.710259756858749],
              [-37.462946189469704, -7.709605720915552],
              [-37.461957746176942, -7.708996339483349],
              [-37.461497634342685, -7.708455199768814],
              [-37.460831866170359, -7.707672821377693],
              [-37.460512644014017, -7.707497082744028],
              [-37.459947765633423, -7.707614646252017],
              [-37.45904754934638, -7.70777814178651],
              [-37.457921443907431, -7.707245950375162],
              [-37.455996882452098, -7.706327651910778],
              [-37.454095571526537, -7.705395463043648],
              [-37.453086864947124, -7.704950980190745],
              [-37.453003834812591, -7.704921872894931],
              [-37.452107426006833, -7.704520568353529],
              [-37.451820862235898, -7.704390335869537],
              [-37.450088613643715, -7.703779116545719],
              [-37.448119719025279, -7.703029980826122],
              [-37.445990219942587, -7.702649512535661],
              [-37.444139568596768, -7.701636286245549],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0235000',
        uf: 'PE',
        nome_proje: 'PA CACIMBA DA FURNA',
        municipio: 'ITAIBA',
        area_hecta: '860.2739',
        capacidade: 32.0,
        num_famili: 28.0,
        fase: 4.0,
        data_de_cr: '07/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '27/12/2000',
        area_calc_: 860.2732,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.267610464735732, -9.09670310139828],
              [-37.267496362294885, -9.096704123224319],
              [-37.264967782033246, -9.099029296393025],
              [-37.263241823878005, -9.100561389836221],
              [-37.261573830074809, -9.102077167525533],
              [-37.258578060159607, -9.10487610969513],
              [-37.256235489726279, -9.107044585367335],
              [-37.250752162284321, -9.112928250573532],
              [-37.250477323136046, -9.113306869915801],
              [-37.250776162562978, -9.115060279236578],
              [-37.250807838100101, -9.115073333096012],
              [-37.250878298128669, -9.115231257174768],
              [-37.249358394055065, -9.116184212640993],
              [-37.246077258745942, -9.118039662657209],
              [-37.23944694659081, -9.122102280409155],
              [-37.242478689753, -9.133597268690028],
              [-37.243236254328799, -9.133262476058322],
              [-37.244447094281142, -9.132960612666279],
              [-37.244803946195816, -9.132791390437742],
              [-37.245709961006575, -9.133036187039281],
              [-37.247084538933166, -9.132829963618844],
              [-37.247456129375081, -9.132721818445452],
              [-37.249811488325193, -9.131788806808668],
              [-37.250233721515599, -9.13155582889067],
              [-37.251143210937023, -9.131323741393713],
              [-37.252567609501497, -9.130695301732523],
              [-37.252833788573206, -9.130577403232166],
              [-37.253422803091631, -9.130121348834692],
              [-37.254005555600486, -9.129585249828699],
              [-37.255269529817603, -9.128573634890795],
              [-37.25654740919569, -9.128083948979098],
              [-37.256804621851238, -9.128013433337953],
              [-37.257548156348847, -9.127959598371568],
              [-37.258450022527022, -9.127886413494128],
              [-37.259069141554129, -9.127637858073207],
              [-37.259919632763662, -9.12748635347833],
              [-37.260138808373526, -9.127389723296272],
              [-37.261139096454272, -9.127008728909049],
              [-37.26213467477848, -9.127179169881533],
              [-37.263701362460999, -9.126272225636002],
              [-37.265324425170334, -9.12515683746455],
              [-37.270100179239556, -9.122225106841656],
              [-37.270979705521235, -9.121693640313092],
              [-37.27230617971194, -9.120856218620972],
              [-37.278305741567827, -9.116894125101419],
              [-37.278192436541076, -9.116734880195651],
              [-37.278513961898462, -9.116558589396002],
              [-37.274741093518756, -9.115903367989102],
              [-37.275054124728761, -9.115656510118749],
              [-37.27731979986153, -9.11406699668273],
              [-37.281560788738922, -9.110942013088998],
              [-37.27842504752774, -9.109228901803036],
              [-37.275201586671123, -9.10757564864174],
              [-37.274425778250617, -9.107140474762645],
              [-37.274268477861781, -9.107161003104446],
              [-37.273300803883785, -9.105721088413272],
              [-37.269917644152891, -9.100486508358715],
              [-37.269799797543861, -9.100219660309913],
              [-37.269592268538567, -9.099956418733166],
              [-37.267610464735732, -9.09670310139828],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0357000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA APARECIDA',
        municipio: 'PESQUEIRA',
        area_hecta: '3380.9732',
        capacidade: 130.0,
        num_famili: 122.0,
        fase: 4.0,
        data_de_cr: '19/07/2006',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 3120.6294,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.856263414216322, -8.398413808323525],
              [-36.856301782385799, -8.399163101893544],
              [-36.8563128648195, -8.399194709302092],
              [-36.875998545839678, -8.402159563674857],
              [-36.876034905180248, -8.402017118391344],
              [-36.876689597335748, -8.399260473267693],
              [-36.876427149284332, -8.399263577502706],
              [-36.875979236684081, -8.399435531125944],
              [-36.874732002535218, -8.400482882409634],
              [-36.873940121278679, -8.401158276994966],
              [-36.873607363012724, -8.401570302015475],
              [-36.86500485768839, -8.400358385733115],
              [-36.856263414216322, -8.398413808323525],
            ],
          ],
          [
            [
              [-36.856481596917213, -8.399677240554208],
              [-36.856539242255543, -8.399841888434517],
              [-36.857481294200241, -8.402819131709114],
              [-36.857498533242634, -8.403101703049741],
              [-36.857421809795007, -8.403270410260806],
              [-36.857278288037769, -8.403486481121668],
              [-36.857202762604217, -8.403701475870509],
              [-36.857198003796512, -8.403939906102231],
              [-36.857359133617834, -8.40478224991829],
              [-36.857880712286807, -8.405843408884408],
              [-36.858004813417537, -8.405973707880975],
              [-36.858274207516708, -8.406127667257005],
              [-36.860246030658132, -8.407833807044439],
              [-36.860592368851584, -8.408457775323106],
              [-36.860703880505227, -8.408616298389276],
              [-36.86118931891825, -8.409085999166956],
              [-36.862130789907532, -8.4097104009461],
              [-36.862425566440088, -8.410285547295373],
              [-36.862441287033135, -8.410431617528243],
              [-36.862356319459053, -8.410564123785806],
              [-36.862072859086915, -8.410954797994661],
              [-36.862103197925052, -8.412143088879215],
              [-36.862193789483314, -8.412557671973477],
              [-36.862608676680935, -8.413213650501991],
              [-36.862875504332344, -8.413389921471714],
              [-36.863849314730899, -8.414343709771986],
              [-36.86376977256004, -8.41472835208598],
              [-36.845579822676079, -8.418033134497163],
              [-36.844980183443916, -8.418140937461102],
              [-36.839130070771702, -8.43163699989343],
              [-36.838666521603677, -8.433437758453358],
              [-36.839338445112432, -8.43465334174134],
              [-36.839171230280833, -8.438130599387362],
              [-36.838441606707534, -8.438784717307257],
              [-36.838210163186609, -8.439477105540156],
              [-36.837964939777677, -8.440671542352547],
              [-36.83706298120201, -8.441916497950308],
              [-36.836741621229457, -8.44318571790842],
              [-36.836728250715829, -8.443679181391978],
              [-36.837546504486419, -8.446071119644264],
              [-36.837623116305458, -8.446186972636166],
              [-36.838516217722166, -8.447333910100571],
              [-36.839227094961679, -8.449088537889317],
              [-36.84040202587839, -8.451634122517488],
              [-36.841430821209542, -8.45339348755504],
              [-36.841720365713606, -8.454220451411325],
              [-36.842105558905089, -8.454614243512827],
              [-36.842060991213906, -8.457043455601731],
              [-36.841783470941472, -8.457024478663444],
              [-36.841278887618934, -8.459810547555739],
              [-36.841193489332767, -8.459886185795259],
              [-36.839034325170424, -8.461362282885409],
              [-36.838920146039534, -8.461503114383522],
              [-36.838313460808358, -8.461868737856634],
              [-36.838411423047724, -8.462112432664924],
              [-36.839805050096572, -8.463343767574296],
              [-36.841154013655284, -8.464054649077974],
              [-36.841736066852832, -8.464802600959201],
              [-36.84208067916159, -8.464529351963192],
              [-36.84327107797094, -8.465054862857754],
              [-36.843977849629184, -8.465433346828618],
              [-36.844210658355649, -8.465396034101394],
              [-36.84441300005173, -8.465205067859246],
              [-36.844998483423716, -8.465028056051613],
              [-36.845585314146277, -8.46418432019223],
              [-36.845972884117259, -8.464005944086031],
              [-36.846041333441093, -8.463843615945764],
              [-36.846138728074941, -8.46402710596737],
              [-36.846975359608436, -8.464204274470257],
              [-36.847220733238785, -8.464356400093788],
              [-36.853999953082344, -8.459601265691839],
              [-36.858186033065252, -8.456689351690233],
              [-36.863177874128304, -8.464582373930329],
              [-36.869442212383234, -8.461294175441845],
              [-36.869672522159739, -8.459151121666531],
              [-36.871847312186389, -8.457541035792058],
              [-36.872511257016953, -8.457307998193356],
              [-36.873859291242901, -8.454740650485912],
              [-36.873939716937109, -8.454709811414089],
              [-36.89379018385462, -8.458354599469354],
              [-36.89638531226511, -8.459009919140234],
              [-36.899672664509986, -8.464334135042851],
              [-36.9008213579882, -8.464184104627137],
              [-36.908219460328368, -8.4575552140164],
              [-36.907897965626091, -8.456378353943176],
              [-36.907116673667936, -8.454400933564209],
              [-36.907615760751433, -8.452664576143212],
              [-36.907740158299831, -8.451920925364377],
              [-36.907561979478082, -8.451728244036049],
              [-36.907844418099948, -8.451190726074559],
              [-36.907933584162009, -8.451070795988009],
              [-36.905059885649798, -8.450070115948298],
              [-36.90410664155948, -8.449575793949824],
              [-36.90320041547735, -8.448647011584459],
              [-36.901903763425224, -8.446818964561988],
              [-36.90330158672834, -8.443564179198624],
              [-36.902716529946261, -8.442627355363779],
              [-36.893092945864659, -8.442130343010593],
              [-36.893837355594918, -8.439072767570467],
              [-36.889071640162705, -8.438008495651362],
              [-36.887131671806635, -8.437571653102392],
              [-36.88510194275117, -8.437114702171431],
              [-36.883561312518353, -8.436767770899346],
              [-36.882328935396828, -8.436747375717932],
              [-36.8810706571279, -8.436665639950908],
              [-36.884559499219485, -8.425265703815713],
              [-36.884539958065147, -8.425136334746389],
              [-36.885085957685241, -8.42334151551718],
              [-36.88621236938836, -8.423241820014843],
              [-36.887171450733454, -8.420117799270797],
              [-36.88933908109675, -8.412851156242983],
              [-36.888499547683324, -8.413204609956034],
              [-36.882870499065092, -8.412590838272621],
              [-36.882864871933002, -8.4125906271791],
              [-36.883744151010482, -8.408911976694307],
              [-36.877620613655829, -8.407417398928976],
              [-36.877575098554509, -8.407474011114811],
              [-36.874893680359776, -8.406865556075923],
              [-36.875857185755279, -8.402713999755347],
              [-36.875886402697262, -8.40259962844984],
              [-36.856481596917213, -8.399677240554208],
            ],
            [
              [-36.874926717491654, -8.412496542515497],
              [-36.879841061879972, -8.412769581776729],
              [-36.874794847891053, -8.432580315361994],
              [-36.870820133755345, -8.431513900247181],
              [-36.874926717491654, -8.412496542515497],
            ],
            [
              [-36.860272798850481, -8.400635238927794],
              [-36.86057145763229, -8.400683053005325],
              [-36.860356290400198, -8.401963836593692],
              [-36.860155062184809, -8.401943669716244],
              [-36.860272798850481, -8.400635238927794],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0285000',
        uf: 'PE',
        nome_proje: 'PA MANUINO',
        municipio: 'ITAIBA',
        area_hecta: '1218.5755',
        capacidade: 60.0,
        num_famili: 60.0,
        fase: 5.0,
        data_de_cr: '13/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 1210.6411,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.407778204704726, -8.81387517197202],
              [-37.407612336986773, -8.813890382844102],
              [-37.407401529876047, -8.813934879569068],
              [-37.407153868967882, -8.814094593688356],
              [-37.406974546657985, -8.814470787361932],
              [-37.406294178416687, -8.815589360010396],
              [-37.404439010202367, -8.81829366976992],
              [-37.404462505319991, -8.818601372078477],
              [-37.403490454862968, -8.819012803511766],
              [-37.403128483431601, -8.819118862335802],
              [-37.403007034882847, -8.819098634849308],
              [-37.402905525978227, -8.819155528306174],
              [-37.402347104370001, -8.818968248567813],
              [-37.402016482097665, -8.81884450654865],
              [-37.401878792590537, -8.820043225467805],
              [-37.401186884972518, -8.820098057751624],
              [-37.401392442420686, -8.820556267015307],
              [-37.401931163048765, -8.821858228435531],
              [-37.402131069014047, -8.822341549740909],
              [-37.402751096595111, -8.823677492327958],
              [-37.403230723130896, -8.824708038073238],
              [-37.402019115520375, -8.827424102491749],
              [-37.401355028107368, -8.828720308990132],
              [-37.400527006834878, -8.830128565691007],
              [-37.400464552277498, -8.830234811721557],
              [-37.400272799210711, -8.830707151020377],
              [-37.399747684120634, -8.831351580056761],
              [-37.399190467086193, -8.831998493131156],
              [-37.39850424540051, -8.832858059416985],
              [-37.39737572089053, -8.834422891302463],
              [-37.396163131139573, -8.835854082695647],
              [-37.396304918622491, -8.836049087300049],
              [-37.397020721727898, -8.836845292938309],
              [-37.397687418280135, -8.837541014499079],
              [-37.398740630161079, -8.838578176818816],
              [-37.399107011693957, -8.839202991948836],
              [-37.399143650130178, -8.839370692674803],
              [-37.399053939378675, -8.83983062657763],
              [-37.398705084766114, -8.84123034076309],
              [-37.398582410613848, -8.841408120374602],
              [-37.39817405549578, -8.842460193982888],
              [-37.398137635057275, -8.84281004543633],
              [-37.398122779066121, -8.843365689487626],
              [-37.398138944615546, -8.843789998750369],
              [-37.398166099527003, -8.844069505752582],
              [-37.397988243124999, -8.844920118689833],
              [-37.398295771273048, -8.845764217858678],
              [-37.398458009900779, -8.846823070758241],
              [-37.398339823907044, -8.847854503102205],
              [-37.398228738344486, -8.847958550820694],
              [-37.398261323055998, -8.848096577279307],
              [-37.400861947342378, -8.847738065321471],
              [-37.402897255628361, -8.847579659838782],
              [-37.403135653108606, -8.847508251816297],
              [-37.404569571466695, -8.850184498445911],
              [-37.405556530487274, -8.851967949827015],
              [-37.406460876282907, -8.852860791959449],
              [-37.405852544271504, -8.853379732330627],
              [-37.405572638587032, -8.853673664254611],
              [-37.405103427782755, -8.854185419792669],
              [-37.404947384256396, -8.854701312667839],
              [-37.404930304746664, -8.855141575506144],
              [-37.404878624677586, -8.855742092320346],
              [-37.404892123399691, -8.856006079995511],
              [-37.404818762121167, -8.856722239313076],
              [-37.404666023157851, -8.857799551055805],
              [-37.404688327165836, -8.860385328859859],
              [-37.404792413867078, -8.86074536518443],
              [-37.406651144331377, -8.859935188103396],
              [-37.4072531440818, -8.859707186659346],
              [-37.408976293063098, -8.859325181548975],
              [-37.409584887369149, -8.859698575253987],
              [-37.410408631625359, -8.860525516373837],
              [-37.411387435533733, -8.86170050203555],
              [-37.4116172235805, -8.861582487955008],
              [-37.414075996505559, -8.860172634411397],
              [-37.415136520245433, -8.860228578934064],
              [-37.415745676202526, -8.860133417991058],
              [-37.416108870740054, -8.860090922804787],
              [-37.41663593864839, -8.8601005692918],
              [-37.417756075491724, -8.860354052022714],
              [-37.418302563958157, -8.860945983345554],
              [-37.418817074115857, -8.861213990074088],
              [-37.419879525236368, -8.861628801316758],
              [-37.42032982930305, -8.861838576604136],
              [-37.422033452600985, -8.860515892980599],
              [-37.42248478648596, -8.8597808849023],
              [-37.422589417993969, -8.859058066957132],
              [-37.422707151432093, -8.858844361574215],
              [-37.423899931371885, -8.859673828660174],
              [-37.424470601445108, -8.860076427036685],
              [-37.424799444105062, -8.85966803439935],
              [-37.424953454525209, -8.859239917083139],
              [-37.425682542773536, -8.857965007056897],
              [-37.425386115181432, -8.857593222805903],
              [-37.425917385759163, -8.855717097391056],
              [-37.423639028228244, -8.85523181100789],
              [-37.423342823887403, -8.854125645486672],
              [-37.423028008641353, -8.85294968867832],
              [-37.425407011539527, -8.852446669542413],
              [-37.425004962601236, -8.850875035200501],
              [-37.424537066908634, -8.849139556101187],
              [-37.424327273159868, -8.848321377311967],
              [-37.424135894177596, -8.847747768559564],
              [-37.4237246580036, -8.846734612678702],
              [-37.423677073460802, -8.846551495106571],
              [-37.423619071241504, -8.846381082736565],
              [-37.423519793673286, -8.845399439822717],
              [-37.423464759876957, -8.845065562733334],
              [-37.423150079941102, -8.843260111550853],
              [-37.423167732037669, -8.842978621661949],
              [-37.423597062769417, -8.841596206507132],
              [-37.424137146992742, -8.839735759314351],
              [-37.424163347823196, -8.839645631452276],
              [-37.424482523789024, -8.8392392746367],
              [-37.424836942285665, -8.838732700551887],
              [-37.424892300650541, -8.838626690981844],
              [-37.424915585548838, -8.838559517166265],
              [-37.424913300593531, -8.838498203544576],
              [-37.424888883050272, -8.83842540413276],
              [-37.424809549665419, -8.838290256153538],
              [-37.42443406399849, -8.837571927451629],
              [-37.423992533978094, -8.836971679261952],
              [-37.423809424964539, -8.836741697584827],
              [-37.422637941973719, -8.835280394335452],
              [-37.422303019414521, -8.834879786140506],
              [-37.422129638664323, -8.834628596485096],
              [-37.421991629640118, -8.834439402101392],
              [-37.421696247431541, -8.833997276917476],
              [-37.421121599851709, -8.833235430635391],
              [-37.420764260075238, -8.832741595924306],
              [-37.418523894764249, -8.829690019421964],
              [-37.418425410651622, -8.829335343893199],
              [-37.41678979503294, -8.830094743301597],
              [-37.415470503819748, -8.830709625548216],
              [-37.415388070672684, -8.830748066906407],
              [-37.415050819201973, -8.831257506854014],
              [-37.413983826360067, -8.832075081849723],
              [-37.411861268550787, -8.831844213902617],
              [-37.411556675193054, -8.831603677084516],
              [-37.412594230476877, -8.829167676021314],
              [-37.413756148296024, -8.827152098585639],
              [-37.414060219674184, -8.826599389526592],
              [-37.415374089895046, -8.824247727244613],
              [-37.415260414463248, -8.823917671701324],
              [-37.414994278813381, -8.82314392156837],
              [-37.414516332674012, -8.821754250126755],
              [-37.413724198021661, -8.819648221995402],
              [-37.413069586209652, -8.817078113759452],
              [-37.41228984155596, -8.815501085700808],
              [-37.412255159953986, -8.815427248096148],
              [-37.412210524989042, -8.81534269905233],
              [-37.411656294628777, -8.815833589443445],
              [-37.410861563326982, -8.815872002458047],
              [-37.409166049559445, -8.814730891252564],
              [-37.408801686269491, -8.814483681995185],
              [-37.407778204704726, -8.81387517197202],
            ],
          ],
          [
            [
              [-37.42985694193905, -8.832762863980543],
              [-37.428507860717978, -8.83448810611147],
              [-37.427935303688095, -8.834923786472533],
              [-37.427071892825367, -8.835669558331636],
              [-37.426459684259385, -8.836365385706319],
              [-37.425836095650865, -8.837492914373479],
              [-37.425474261720595, -8.838076586071677],
              [-37.425343002692912, -8.838116813618759],
              [-37.42498622083729, -8.838195606614812],
              [-37.424951489021225, -8.83821824623724],
              [-37.424926278094652, -8.838246622255211],
              [-37.424920572697857, -8.838284302965542],
              [-37.424972222646751, -8.838387868819513],
              [-37.425003640596117, -8.838481946088702],
              [-37.425008043703343, -8.838601046297642],
              [-37.42491492379348, -8.838779503587141],
              [-37.424555748646824, -8.839293020014605],
              [-37.424245804535552, -8.839687570841305],
              [-37.423684133169068, -8.841622161354705],
              [-37.423280056954233, -8.842923396428786],
              [-37.423253650127691, -8.843061806920497],
              [-37.423241554845234, -8.843255161864636],
              [-37.423554459797451, -8.845050569085904],
              [-37.423609934749265, -8.845387612691383],
              [-37.423708516849381, -8.846361838351299],
              [-37.423764183597235, -8.846525640309787],
              [-37.423811233509966, -8.846706223900473],
              [-37.424221300041282, -8.847716571816282],
              [-37.424414563608842, -8.848295975282271],
              [-37.42462498352797, -8.849116597997774],
              [-37.425092879257598, -8.850852167473311],
              [-37.425471321199169, -8.852331384797237],
              [-37.425506249533221, -8.852433885906713],
              [-37.425735953057846, -8.853338317657419],
              [-37.428491385309265, -8.850251873274068],
              [-37.432064405049907, -8.847747055135791],
              [-37.432292767405293, -8.847597463394823],
              [-37.432381901038212, -8.847544579669391],
              [-37.434183890275314, -8.846475406866301],
              [-37.435487952033775, -8.845537060335641],
              [-37.435993686007734, -8.845212939617932],
              [-37.439680880202161, -8.844155943203852],
              [-37.440008785861316, -8.844027376100744],
              [-37.440494214410165, -8.843752984858863],
              [-37.440739603197109, -8.843595862578413],
              [-37.441282371950159, -8.843607796280432],
              [-37.440710635309294, -8.841859596347696],
              [-37.440683794116083, -8.84177864956852],
              [-37.440330128065483, -8.84074892660977],
              [-37.440273793239761, -8.840655740849385],
              [-37.439137687440912, -8.840423695754641],
              [-37.438422191660571, -8.840542781160364],
              [-37.438070749228906, -8.840648463519326],
              [-37.437705815520964, -8.840201988229559],
              [-37.436854305088552, -8.83997998412304],
              [-37.436178079017154, -8.840574021156609],
              [-37.435566824378505, -8.841174205106835],
              [-37.435171951961287, -8.841683063761673],
              [-37.434909420763958, -8.842024566002337],
              [-37.43487046181415, -8.842510045344552],
              [-37.432822102789665, -8.843005750611773],
              [-37.431395832437332, -8.84317844971836],
              [-37.431212239104234, -8.842588600180441],
              [-37.43118515376802, -8.842501413121639],
              [-37.43058343569119, -8.840567721232606],
              [-37.430271627108439, -8.840093975301022],
              [-37.430200022382671, -8.839936436510706],
              [-37.430290397587292, -8.83958987688065],
              [-37.430824549446996, -8.838800403678567],
              [-37.431169348982536, -8.838760525515241],
              [-37.431407169206729, -8.838673181350437],
              [-37.431568781319619, -8.838613819329817],
              [-37.431745727469284, -8.838324947060721],
              [-37.431694950240555, -8.837971557245435],
              [-37.43183584143263, -8.836470283766397],
              [-37.432209980268595, -8.833467217650799],
              [-37.430353912971015, -8.832959254974945],
              [-37.42985694193905, -8.832762863980543],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0347000',
        uf: 'PE',
        nome_proje: 'PA SANTA IZABEL',
        municipio: 'SAO CAETANO',
        area_hecta: '914.7041',
        capacidade: 34.0,
        num_famili: 19.0,
        fase: 3.0,
        data_de_cr: '16/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/2004',
        area_calc_: 865.5967,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.112835280091026, -8.325866389996712],
              [-36.112803330091033, -8.326192499996717],
              [-36.111440280091188, -8.340104169996708],
              [-36.111568890091171, -8.341316939996705],
              [-36.119569720089821, -8.341787779996769],
              [-36.120029720089789, -8.332497779996764],
              [-36.118481670090056, -8.332531109996756],
              [-36.118368890090068, -8.33286721999675],
              [-36.113351940090922, -8.330699439996719],
              [-36.113641390090883, -8.326637499996714],
              [-36.113670830090889, -8.326228329996722],
              [-36.112835280091026, -8.325866389996712],
            ],
          ],
          [
            [
              [-36.108263610091853, -8.315956109996687],
              [-36.108198060091851, -8.318320279996696],
              [-36.108858330091728, -8.319391939996683],
              [-36.10957778009162, -8.319458889996685],
              [-36.109568060091618, -8.319534169996693],
              [-36.109547780091617, -8.3198133299967],
              [-36.109565000091614, -8.320324169996699],
              [-36.109577500091596, -8.323780279996692],
              [-36.1101025000915, -8.323937499996704],
              [-36.110701110091398, -8.324133609996698],
              [-36.111083890091336, -8.324281669996708],
              [-36.111790830091209, -8.324588059996719],
              [-36.113999170090842, -8.325535829996722],
              [-36.119530280089897, -8.327898609996765],
              [-36.119692780089878, -8.326339719996765],
              [-36.11988694008987, -8.321002219996775],
              [-36.119616110089922, -8.320661939996764],
              [-36.119197500089989, -8.320490279996767],
              [-36.11841306009012, -8.320529999996753],
              [-36.118419440090115, -8.320264439996764],
              [-36.11838750009013, -8.31968388999676],
              [-36.118340280090138, -8.31912749999676],
              [-36.11609417009052, -8.31888582999674],
              [-36.115522780090615, -8.318821389996744],
              [-36.115393610090635, -8.31880805999673],
              [-36.1114991700913, -8.318312499996718],
              [-36.111079440091366, -8.318114719996704],
              [-36.10951139009164, -8.316730829996686],
              [-36.109106390091711, -8.316466669996696],
              [-36.108263610091853, -8.315956109996687],
            ],
          ],
          [
            [
              [-36.111367780091442, -8.293296939996718],
              [-36.110025000091674, -8.293509719996703],
              [-36.108665280091898, -8.293515559996699],
              [-36.108200280091971, -8.293735279996696],
              [-36.108063890091998, -8.293949719996693],
              [-36.107744440092041, -8.295197779996696],
              [-36.107634170092062, -8.295222219996685],
              [-36.105749170092388, -8.294444999996676],
              [-36.103545830092763, -8.294802499996655],
              [-36.103094720092841, -8.294833059996655],
              [-36.102043890093022, -8.294640559996646],
              [-36.101683060093087, -8.294689439996652],
              [-36.101007780093205, -8.294974439996633],
              [-36.099706670093425, -8.295465559996638],
              [-36.097933060093723, -8.296064999996624],
              [-36.097418060093815, -8.296183059996617],
              [-36.096572780093958, -8.296713889996616],
              [-36.096096940094043, -8.297103329996611],
              [-36.095670830094114, -8.297430279996593],
              [-36.094500830094312, -8.298082779996593],
              [-36.094416940094327, -8.298182499996585],
              [-36.094165830094362, -8.300633059996589],
              [-36.093741940094432, -8.300955279996579],
              [-36.092927500094525, -8.310631389996582],
              [-36.09694250009381, -8.312339169996598],
              [-36.097509170093716, -8.312538329996611],
              [-36.108296390091851, -8.315879439996692],
              [-36.109154720091702, -8.316390279996693],
              [-36.109566670091624, -8.316658889996694],
              [-36.111130000091357, -8.318038609996707],
              [-36.111525000091284, -8.318224719996707],
              [-36.115402780090633, -8.318718329996733],
              [-36.115532500090616, -8.318731669996739],
              [-36.116104170090509, -8.318796109996743],
              [-36.118362220090141, -8.31905027999675],
              [-36.118667500090126, -8.311677219996765],
              [-36.117170830090366, -8.311113889996751],
              [-36.117262780090364, -8.310082499996751],
              [-36.118743330090119, -8.309450559996769],
              [-36.119372780090018, -8.309416389996766],
              [-36.119425000090004, -8.309764719996773],
              [-36.120472500089825, -8.309984999996773],
              [-36.120142220089875, -8.310936669996774],
              [-36.120456110089826, -8.311047499996775],
              [-36.12229694008952, -8.311336669996786],
              [-36.122661940089472, -8.30914332999679],
              [-36.122616110089496, -8.305449169996795],
              [-36.125313610089052, -8.304998329996813],
              [-36.125075000089112, -8.300195559996812],
              [-36.124557500089203, -8.299270559996804],
              [-36.123587220089348, -8.302004719996805],
              [-36.123239170089406, -8.30199082999679],
              [-36.117813890090325, -8.299673609996761],
              [-36.117979440090316, -8.296471939996755],
              [-36.117507500090397, -8.296442499996751],
              [-36.117333890090421, -8.29640499999676],
              [-36.116617780090543, -8.296065279996744],
              [-36.116435560090579, -8.295953059996759],
              [-36.116210830090616, -8.295756939996757],
              [-36.116001670090654, -8.295603889996745],
              [-36.115226110090781, -8.295305279996748],
              [-36.114855000090841, -8.29494555999675],
              [-36.114101940090976, -8.294408609996736],
              [-36.113315830091103, -8.293806389996735],
              [-36.112898060091183, -8.293553609996721],
              [-36.111825280091367, -8.293393889996718],
              [-36.111367780091442, -8.293296939996718],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0240000',
        uf: 'PE',
        nome_proje: 'PA SANTA RITA',
        municipio: 'SAO BENTO DO UNA',
        area_hecta: '606.9058',
        capacidade: 42.0,
        num_famili: 39.0,
        fase: 4.0,
        data_de_cr: '27/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 607.7441,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.344742623316861, -8.53981179675279],
              [-36.34478268262518, -8.539920647949883],
              [-36.345426357957784, -8.540188378047674],
              [-36.349880924326619, -8.532889461973886],
              [-36.357640887316286, -8.53308034295344],
              [-36.364863030897119, -8.530584357267699],
              [-36.366186463625304, -8.529792194324582],
              [-36.362324387123252, -8.506553864303035],
              [-36.339607690907599, -8.513037862825811],
              [-36.340570393273708, -8.516282262475267],
              [-36.342481452443849, -8.522981553482026],
              [-36.348606746810916, -8.531424231311989],
              [-36.349296889001302, -8.532392019989238],
              [-36.344742623316861, -8.53981179675279],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0380000',
        uf: 'PE',
        nome_proje: 'PA AÇUDE NOVO',
        municipio: 'SAO BENTO DO UNA',
        area_hecta: '319.1700',
        capacidade: 22.0,
        num_famili: 22.0,
        fase: 4.0,
        data_de_cr: '07/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 296.0164,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.517658429881521, -8.504338055238813],
              [-36.51560618690835, -8.508519732233035],
              [-36.515537816009662, -8.50878941650762],
              [-36.515413912227594, -8.510519153332147],
              [-36.515438534541538, -8.510666156037873],
              [-36.512788314297659, -8.514129061906726],
              [-36.523973608091737, -8.515196048876781],
              [-36.53018538396082, -8.515767553579327],
              [-36.530254443053742, -8.515801718914398],
              [-36.530592258402137, -8.513093452828391],
              [-36.532098317844351, -8.501408960939496],
              [-36.531564217001296, -8.501450029566028],
              [-36.530828249729971, -8.501138201792344],
              [-36.529595687692122, -8.500982418665668],
              [-36.522432007370263, -8.498216846357442],
              [-36.516111157432697, -8.498620283022085],
              [-36.515974085997968, -8.498718729625223],
              [-36.516075612746889, -8.499459884426354],
              [-36.516052615855052, -8.500183435275524],
              [-36.515980694046867, -8.500568050519231],
              [-36.515927257989944, -8.501310023520173],
              [-36.516044316645626, -8.501669185514668],
              [-36.516276287104795, -8.501973954328378],
              [-36.517991469546246, -8.503266366520814],
              [-36.518049266105329, -8.503567230043968],
              [-36.517658429881521, -8.504338055238813],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0367000',
        uf: 'PE',
        nome_proje: 'PA SOCORRO',
        municipio: 'IGUARACI',
        area_hecta: '3287.4061',
        capacidade: 65.0,
        num_famili: 52.0,
        fase: 3.0,
        data_de_cr: '12/03/2007',
        forma_obte: 'Desapropriação',
        data_obten: '29/12/2004',
        area_calc_: 3287.1244,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.247210008266727, -7.777428028784874],
              [-37.246916848054823, -7.779387846330187],
              [-37.246799456709638, -7.780171537471931],
              [-37.246651571852119, -7.781159351679293],
              [-37.246710698501495, -7.78250742413118],
              [-37.24628950435735, -7.785779004315659],
              [-37.24597056480593, -7.787197392687729],
              [-37.245387628231754, -7.789791447600947],
              [-37.245352719557189, -7.790184701708009],
              [-37.245341782118494, -7.79030762174858],
              [-37.245329320002078, -7.790448347362687],
              [-37.245887317718683, -7.791037274898855],
              [-37.246833697239452, -7.792236488250778],
              [-37.24835879010913, -7.794169287000318],
              [-37.248870044676877, -7.794489755381922],
              [-37.249503372265465, -7.79488672246175],
              [-37.249625444473665, -7.794963221583765],
              [-37.249751387930424, -7.795002620964133],
              [-37.249812434686788, -7.795038316296449],
              [-37.249923249108562, -7.795053240817484],
              [-37.24992496897778, -7.795053880836428],
              [-37.249936107494875, -7.795056910592839],
              [-37.249953771874075, -7.795060328981787],
              [-37.250122552252158, -7.795110574368879],
              [-37.250178244484047, -7.79512581354],
              [-37.250288923232667, -7.795151587320147],
              [-37.250371249601351, -7.795149757496328],
              [-37.250422985957691, -7.79515702369405],
              [-37.250443730088584, -7.795161087699927],
              [-37.250449623226821, -7.795161021631835],
              [-37.250482506502252, -7.795167305770945],
              [-37.250528701467587, -7.795177261539743],
              [-37.250560670281395, -7.795185440629467],
              [-37.250583930680889, -7.795194849563308],
              [-37.250617373837578, -7.795219128772747],
              [-37.250634095229707, -7.795231313583957],
              [-37.25064556071699, -7.795242934186468],
              [-37.250657500523907, -7.795249493457027],
              [-37.250691863787701, -7.795270521490433],
              [-37.250712281881505, -7.795287604030179],
              [-37.250721311624936, -7.795296321259996],
              [-37.250849021947943, -7.795346939230328],
              [-37.250894709382266, -7.795391793416181],
              [-37.25094224490477, -7.795428246551381],
              [-37.250972376937675, -7.795442114232447],
              [-37.251004646842787, -7.795465122751026],
              [-37.25103766529385, -7.795482528571849],
              [-37.251085965151233, -7.795509581595477],
              [-37.251112289935911, -7.795523343128069],
              [-37.251124244402163, -7.795526376227696],
              [-37.251180685096365, -7.795557893246575],
              [-37.251202518659568, -7.795561600056655],
              [-37.251227571236996, -7.795576079656921],
              [-37.251257427565115, -7.795590850342725],
              [-37.251347820745224, -7.795633176642991],
              [-37.251683303151374, -7.795863314322126],
              [-37.251821326151656, -7.795941280263257],
              [-37.252026293418155, -7.796032090359573],
              [-37.252116710437946, -7.796068720423706],
              [-37.252150187915653, -7.796084771832665],
              [-37.252183846337473, -7.796100914402407],
              [-37.252245863365225, -7.796143213833415],
              [-37.252299363413762, -7.79618430269464],
              [-37.252348032698748, -7.796231610315151],
              [-37.252391240739577, -7.796284139518495],
              [-37.252418676883948, -7.796335970709499],
              [-37.2524490272299, -7.796384649368524],
              [-37.252472010625823, -7.796438993828053],
              [-37.252486907332369, -7.796453973952178],
              [-37.252491891522979, -7.796476327274955],
              [-37.252511475072865, -7.796497927110879],
              [-37.252527252102482, -7.796519239987564],
              [-37.252568642523237, -7.796572846673415],
              [-37.252592014595884, -7.79659907335965],
              [-37.2526238829075, -7.796653364071171],
              [-37.252631721033509, -7.796665240916672],
              [-37.252676636275218, -7.796721484209344],
              [-37.252699422018935, -7.796757925481411],
              [-37.25271685329615, -7.796795700895157],
              [-37.252717818649479, -7.79680339023965],
              [-37.253374955210425, -7.797669574882385],
              [-37.253385284555883, -7.797692854420928],
              [-37.253421235025755, -7.797724738776108],
              [-37.253431357657909, -7.797732285071311],
              [-37.253480182780407, -7.797763951293414],
              [-37.253497722917977, -7.797775506495683],
              [-37.253525464349714, -7.79779750162884],
              [-37.253558822868079, -7.797820424094889],
              [-37.253591623187802, -7.79784678006586],
              [-37.253606188473803, -7.79785425427028],
              [-37.253649016281067, -7.797889241130364],
              [-37.253728515845573, -7.797935229194109],
              [-37.254001174055418, -7.798230929408051],
              [-37.254098125721399, -7.798331600711999],
              [-37.254102443971917, -7.798339665552568],
              [-37.254124826484968, -7.798364079791315],
              [-37.254215263596755, -7.798439769376119],
              [-37.254233678059897, -7.798480984634907],
              [-37.254241802108723, -7.798489517251554],
              [-37.254258219902709, -7.798509386082631],
              [-37.254260659442075, -7.798511385297518],
              [-37.254265085226926, -7.798515381857735],
              [-37.254296079969627, -7.798539922026437],
              [-37.254324609308014, -7.798568701581437],
              [-37.254389628042603, -7.798608752796778],
              [-37.254412846009977, -7.798628468836114],
              [-37.2544496972316, -7.798661803508777],
              [-37.254468910471175, -7.79868530050645],
              [-37.254494728253349, -7.798733960410177],
              [-37.25451117112511, -7.798769652153556],
              [-37.25452281851009, -7.798781183027389],
              [-37.254655811750204, -7.799351081542715],
              [-37.254657072354178, -7.79935316631112],
              [-37.255137846333007, -7.800249635179991],
              [-37.255468738884808, -7.800866733121792],
              [-37.256175780204273, -7.80218511193993],
              [-37.256342127430727, -7.802735979817382],
              [-37.256401795185909, -7.804161663691291],
              [-37.256401900434177, -7.804164178476621],
              [-37.256402017341088, -7.804166971832002],
              [-37.256443433324613, -7.804477179529999],
              [-37.256508744068228, -7.804972025096372],
              [-37.256478859376614, -7.805073258405295],
              [-37.255283643693218, -7.806214805591083],
              [-37.255168681868, -7.806324638885378],
              [-37.254828210398784, -7.806639057233435],
              [-37.25454137733449, -7.806786698165414],
              [-37.254735100398634, -7.80694491214582],
              [-37.258686866038069, -7.812290970302096],
              [-37.259087406160148, -7.813190996458419],
              [-37.261563277432025, -7.81614306750758],
              [-37.261760004151952, -7.816519195471325],
              [-37.266494875123279, -7.82279558660086],
              [-37.266497327907032, -7.822798837879564],
              [-37.266484961015699, -7.823422209145286],
              [-37.267178716743238, -7.824898758304164],
              [-37.267910695083742, -7.825726905972171],
              [-37.268304218911517, -7.826246880026949],
              [-37.268316486710226, -7.826262120380624],
              [-37.268323880968765, -7.826271915730241],
              [-37.268341850242685, -7.826289711154562],
              [-37.26836234060972, -7.826311766508913],
              [-37.268383207076781, -7.826330568408369],
              [-37.268401171130662, -7.826349629642418],
              [-37.268409847772013, -7.826356265673834],
              [-37.268421133559052, -7.826367794913255],
              [-37.268431682079949, -7.826382214457859],
              [-37.268451850474079, -7.826394413076295],
              [-37.268485213113649, -7.826417063766976],
              [-37.268521939337624, -7.826437558263757],
              [-37.268563490990047, -7.826453190067414],
              [-37.268609732250127, -7.826474899030911],
              [-37.268651464495818, -7.826490712401913],
              [-37.268699253424082, -7.826510890627154],
              [-37.26874142333358, -7.826530503285722],
              [-37.268788755959655, -7.826551402962954],
              [-37.268835900543841, -7.826573929363581],
              [-37.268845495793705, -7.826577675826096],
              [-37.268935349085581, -7.826621082908829],
              [-37.268976158291451, -7.826640870797556],
              [-37.269005478717645, -7.826654372787055],
              [-37.269037767605084, -7.826673583205501],
              [-37.269067168628354, -7.826689526771873],
              [-37.269161271692909, -7.826735754172224],
              [-37.26920811000597, -7.826766597615242],
              [-37.269255088235667, -7.826785506629876],
              [-37.269298929747414, -7.826817513185224],
              [-37.269347713467461, -7.826838328349743],
              [-37.269390391260949, -7.826866713456817],
              [-37.269434171223388, -7.826891667249597],
              [-37.269467426142363, -7.826918476606518],
              [-37.269510734137128, -7.826947949290445],
              [-37.269544918102866, -7.82696933745466],
              [-37.2695733047183, -7.827011407250234],
              [-37.269595423470363, -7.827056344664025],
              [-37.269619733122958, -7.827097674398981],
              [-37.269642420867676, -7.827136556229391],
              [-37.269665017950345, -7.827175437687454],
              [-37.26968221500227, -7.827204622407441],
              [-37.269699251238833, -7.827228833550271],
              [-37.269719595860174, -7.827264269933094],
              [-37.269736071322455, -7.827292547525303],
              [-37.26975479627373, -7.827324993512729],
              [-37.26975811398232, -7.827333958374316],
              [-37.270077007595006, -7.82772559533],
              [-37.270100150229759, -7.827764117350163],
              [-37.270125538631831, -7.827807711916257],
              [-37.270149033869352, -7.827848676632575],
              [-37.27016694548935, -7.827880486360142],
              [-37.270180611476434, -7.827908481172191],
              [-37.270247482474439, -7.828006495899309],
              [-37.270270157636929, -7.828048451833536],
              [-37.270288062933204, -7.82808179861745],
              [-37.270301524481489, -7.828115398425383],
              [-37.270323367252594, -7.828161329279784],
              [-37.270336551213816, -7.82819628419966],
              [-37.270348127053985, -7.828225445855036],
              [-37.270373490146234, -7.828275188648832],
              [-37.270409265235827, -7.828306529137597],
              [-37.270442783002579, -7.828335599948976],
              [-37.270480469546534, -7.828365139939041],
              [-37.270518978056103, -7.828393236630454],
              [-37.270562198222073, -7.828422075975657],
              [-37.270607249239077, -7.82844667324032],
              [-37.270640180093146, -7.828464168294771],
              [-37.270663863758834, -7.82848144459307],
              [-37.270702931948463, -7.828505746065447],
              [-37.270739368963092, -7.82853048882926],
              [-37.270775410883303, -7.828563096225369],
              [-37.27080005013304, -7.828590503109563],
              [-37.270819269925092, -7.82861291485086],
              [-37.270856079016582, -7.828657369979418],
              [-37.270872671734246, -7.82867922844695],
              [-37.27089389625381, -7.828699207155062],
              [-37.270914470828146, -7.8287228902833],
              [-37.270946595123071, -7.828760092860445],
              [-37.270969866201767, -7.828789483297498],
              [-37.271006781646292, -7.828830141352689],
              [-37.272865103264728, -7.831252337615918],
              [-37.272900464197228, -7.831274272938407],
              [-37.272928934240532, -7.831318241729169],
              [-37.272957616166039, -7.831354797208832],
              [-37.272996974056895, -7.831396911849889],
              [-37.273028822254446, -7.831435198217883],
              [-37.273047844190607, -7.831461677854147],
              [-37.273077790628832, -7.831499413928593],
              [-37.273115891872415, -7.831538449243958],
              [-37.273149095251497, -7.831577916577472],
              [-37.273180952786682, -7.831613942557337],
              [-37.273221269160388, -7.831643493175169],
              [-37.273244617056406, -7.83167631970511],
              [-37.273272181607524, -7.831720013525794],
              [-37.273295756448341, -7.831763781419934],
              [-37.273321451639625, -7.831799058817507],
              [-37.273344896921159, -7.831830258241241],
              [-37.273372845013604, -7.831868890288161],
              [-37.273406447976797, -7.831899407982252],
              [-37.273425233705311, -7.831917206627367],
              [-37.273463542419364, -7.831949913597205],
              [-37.273491195265514, -7.831972178984138],
              [-37.273524916097237, -7.831996096726136],
              [-37.273565898660529, -7.832040026328964],
              [-37.273598391678412, -7.832075874060803],
              [-37.273623573445199, -7.832103735165399],
              [-37.273648454542048, -7.832138557134414],
              [-37.273671596585807, -7.832177350321382],
              [-37.273693269715572, -7.832220567917174],
              [-37.273710661019607, -7.832268740902829],
              [-37.27372638159472, -7.83232631039766],
              [-37.273760057413291, -7.832361168383116],
              [-37.273764650152089, -7.832412815209952],
              [-37.273766630674338, -7.832460292163844],
              [-37.273779022269963, -7.832511609263384],
              [-37.273798478267906, -7.832542792338658],
              [-37.273809100091498, -7.832583523419614],
              [-37.273833980108869, -7.832618616632432],
              [-37.273844053837934, -7.832660340054153],
              [-37.273851378883826, -7.832664981312098],
              [-37.273852554577445, -7.83266570946176],
              [-37.273887552642094, -7.83268773366727],
              [-37.273916953178841, -7.83270394826914],
              [-37.273945522076154, -7.832723956971448],
              [-37.273977359088683, -7.832742984470315],
              [-37.274001576341803, -7.832762884932596],
              [-37.27402705958643, -7.832783604329935],
              [-37.274027780083458, -7.832784782699468],
              [-37.274030672868761, -7.832786874133071],
              [-37.274059670541192, -7.83281285209822],
              [-37.274085682158031, -7.832837371164847],
              [-37.27410759476814, -7.832866484705369],
              [-37.274122919578588, -7.832888066670274],
              [-37.27414115430296, -7.832907580962661],
              [-37.274165508977369, -7.832938151171272],
              [-37.27419255909971, -7.832974699929067],
              [-37.274222224056153, -7.833014785650579],
              [-37.274253981064824, -7.833053252433734],
              [-37.274283826249622, -7.833093610141784],
              [-37.274316566462595, -7.833135516789273],
              [-37.274350752890449, -7.833178514359404],
              [-37.274380248824748, -7.833215615627004],
              [-37.274406843039351, -7.833252795429835],
              [-37.274434372222302, -7.833283107377806],
              [-37.27445576581421, -7.833306160854385],
              [-37.274487225710203, -7.833328713042618],
              [-37.274510893062157, -7.833350057906096],
              [-37.274532797322486, -7.833359189272319],
              [-37.274552613630611, -7.833368945007531],
              [-37.274575483221966, -7.833385856175452],
              [-37.274588778186967, -7.833393957707105],
              [-37.274600979189323, -7.833403501429844],
              [-37.274615539403342, -7.833412602726399],
              [-37.274665292001991, -7.833462626108543],
              [-37.274683864879442, -7.833488109286224],
              [-37.274697198780672, -7.833508778921555],
              [-37.274711638034034, -7.833547265207672],
              [-37.274714025382714, -7.833562012932797],
              [-37.274740086880577, -7.833640511060034],
              [-37.274765448728296, -7.83371276755828],
              [-37.2747787431856, -7.833743021221552],
              [-37.274869368015217, -7.833885889220531],
              [-37.274883698904766, -7.833906653352404],
              [-37.27491374005276, -7.833943485583668],
              [-37.27493712094234, -7.833968355520777],
              [-37.274936752317721, -7.833969800682097],
              [-37.274958851584046, -7.833997649129743],
              [-37.274959832550337, -7.834001631488499],
              [-37.27511092907762, -7.834189684193324],
              [-37.27513961918288, -7.834224340880985],
              [-37.275165555402268, -7.834267214233789],
              [-37.275218727104722, -7.834323671179119],
              [-37.275252370078377, -7.83436657607301],
              [-37.27528636382084, -7.834412375742194],
              [-37.275323175707157, -7.834456378606073],
              [-37.275382431112163, -7.834510690433468],
              [-37.275451985429214, -7.834574086095711],
              [-37.275499319254365, -7.834617047009157],
              [-37.277205742400788, -7.836837073220069],
              [-37.277761657346502, -7.83756024109114],
              [-37.278949431102724, -7.839105350312309],
              [-37.279098241692381, -7.839298908416669],
              [-37.280949093941324, -7.841706680908064],
              [-37.28095633569793, -7.841731665606906],
              [-37.280990276385246, -7.841790665746379],
              [-37.281009873489793, -7.841853947342653],
              [-37.281028882317749, -7.841905924394604],
              [-37.281055172264779, -7.841973302089352],
              [-37.281069811146416, -7.842029601321751],
              [-37.281092252037304, -7.842173184952244],
              [-37.281102530905812, -7.842209303360696],
              [-37.281113966662403, -7.842272822887091],
              [-37.281114929472992, -7.842369573161069],
              [-37.281119934288803, -7.842409377138743],
              [-37.281133162075356, -7.842522271742867],
              [-37.281142130821777, -7.842590392471666],
              [-37.281144844395222, -7.842636154625812],
              [-37.28114361982108, -7.842691484917149],
              [-37.28114874389334, -7.84272432726226],
              [-37.281144586909235, -7.842743026637189],
              [-37.281130425820386, -7.842769099368898],
              [-37.281127535982129, -7.842788346420206],
              [-37.281125684720074, -7.842797561409499],
              [-37.28112520723954, -7.842803436574038],
              [-37.281133128994021, -7.842817393160232],
              [-37.281122815541075, -7.842878020949587],
              [-37.281123132182195, -7.842889233968011],
              [-37.281121987712922, -7.842902972700233],
              [-37.281115832278573, -7.8429225680852],
              [-37.281110919487091, -7.842948678578172],
              [-37.28110812736886, -7.842966208102136],
              [-37.281102433077649, -7.842983906609616],
              [-37.281100262280241, -7.843004693687488],
              [-37.281096748221223, -7.843043468290892],
              [-37.281084896184943, -7.843059242893015],
              [-37.281082158274742, -7.843173971061205],
              [-37.281070730693891, -7.843218861713452],
              [-37.281049203493772, -7.843272300749443],
              [-37.281039467649428, -7.843390978690229],
              [-37.281027690809772, -7.843454674676309],
              [-37.281016510711261, -7.843505533865163],
              [-37.281014918148536, -7.843540066706352],
              [-37.281031028544611, -7.843591308596807],
              [-37.281045516169598, -7.843640283431875],
              [-37.281056393341494, -7.843685265168563],
              [-37.281077823492659, -7.843721700374144],
              [-37.281117255593792, -7.843724121834336],
              [-37.281154244508649, -7.843747419672956],
              [-37.281203906416387, -7.843775832620002],
              [-37.281254744441078, -7.843804883285205],
              [-37.281299344073204, -7.84382956844922],
              [-37.281338338885384, -7.843850161952047],
              [-37.281423318356701, -7.843911450096926],
              [-37.281512006324, -7.843952861597215],
              [-37.2815222264095, -7.84395914210842],
              [-37.282349998238153, -7.844128074870698],
              [-37.282375550610752, -7.844154309718111],
              [-37.282417623481052, -7.84417609112317],
              [-37.282467039143576, -7.844198264157924],
              [-37.282496187025359, -7.844210046921051],
              [-37.282545580064969, -7.844237735305204],
              [-37.282600203776752, -7.844272316736047],
              [-37.282639548781589, -7.844295985754025],
              [-37.282686216547567, -7.844324928846373],
              [-37.282733061950914, -7.844354776831762],
              [-37.282768799832162, -7.844373639010768],
              [-37.282821799684079, -7.84440622461601],
              [-37.282850923852315, -7.844423793964572],
              [-37.282877363904305, -7.844432581890785],
              [-37.282934724099796, -7.844463196096111],
              [-37.282973897821329, -7.844484423126687],
              [-37.283014896448705, -7.844502854665482],
              [-37.283054620134827, -7.844522637258221],
              [-37.283090162984742, -7.844544844057572],
              [-37.283111143721911, -7.844580373199437],
              [-37.283136640165083, -7.844620260789111],
              [-37.283156261969616, -7.844655513134919],
              [-37.283180542988248, -7.844704347071117],
              [-37.283205528601989, -7.844758247246102],
              [-37.283229324010428, -7.844814945499812],
              [-37.283250977091583, -7.844863407046344],
              [-37.283281376799671, -7.84490159670746],
              [-37.28329948393695, -7.844930332832143],
              [-37.283337040296388, -7.844970088777216],
              [-37.283375507444958, -7.845008853845911],
              [-37.283411360740487, -7.845043810726782],
              [-37.283449114037758, -7.845079769945331],
              [-37.28348829878076, -7.845120436696876],
              [-37.283531196933154, -7.845162113181987],
              [-37.283569849166483, -7.845199974822579],
              [-37.283612212213768, -7.845239479106276],
              [-37.283654932016987, -7.845280431518335],
              [-37.28369658010142, -7.845317401197864],
              [-37.2837392337251, -7.845352385796144],
              [-37.283780613521735, -7.845388450203627],
              [-37.283802740613623, -7.845409788497311],
              [-37.283804828256372, -7.845409254507599],
              [-37.283839969979795, -7.845440863025789],
              [-37.283853158534271, -7.84545294230523],
              [-37.285210286038748, -7.847080109164724],
              [-37.287981488872219, -7.846219860913017],
              [-37.290372575032883, -7.847537020417846],
              [-37.292385020838282, -7.847678649913468],
              [-37.295532966076991, -7.846752155516965],
              [-37.297107116916266, -7.84628880866173],
              [-37.298440614300006, -7.846495835176998],
              [-37.299236308475415, -7.846195880761479],
              [-37.30158924025168, -7.845308979806257],
              [-37.301400235298644, -7.84435900818844],
              [-37.30176835230867, -7.841233203439651],
              [-37.301894724018382, -7.840993381828557],
              [-37.304361408741542, -7.839033019409667],
              [-37.304622965828251, -7.838032419245255],
              [-37.304670602712527, -7.837850152650381],
              [-37.304713497554914, -7.837686029690569],
              [-37.306946831004261, -7.828143307317789],
              [-37.307076457053043, -7.82758938216756],
              [-37.307240956967924, -7.826886406504029],
              [-37.307293639498532, -7.826660752773212],
              [-37.309713157625374, -7.816321331634175],
              [-37.309738619302948, -7.816212569601436],
              [-37.310395269748014, -7.813406614952148],
              [-37.310929322255127, -7.811120978442273],
              [-37.312208933057718, -7.805643462910337],
              [-37.312935121525001, -7.802645091175346],
              [-37.313824534590871, -7.798978990200164],
              [-37.310024935767352, -7.797465419147946],
              [-37.306794452643842, -7.796178424035261],
              [-37.304004266854122, -7.795066775161483],
              [-37.298997274739698, -7.793071960460997],
              [-37.297472827520892, -7.792483452998393],
              [-37.29738810060239, -7.792450833210385],
              [-37.296821718800032, -7.792232095566198],
              [-37.295902613599552, -7.793192164903454],
              [-37.295548327704026, -7.794711479606304],
              [-37.294727372426955, -7.795432060773607],
              [-37.293032642588024, -7.794647638909515],
              [-37.291316174484386, -7.794105895657757],
              [-37.288441662087067, -7.793194271369872],
              [-37.286361135361155, -7.791979146332543],
              [-37.282370247718113, -7.789648389040966],
              [-37.280785551835095, -7.786505023638337],
              [-37.279926738896485, -7.785793934037122],
              [-37.279786908759831, -7.785713437809697],
              [-37.276123480916411, -7.784249804137993],
              [-37.271155678159936, -7.781928740098525],
              [-37.269559015890984, -7.781310288105792],
              [-37.268351857814302, -7.781611061189691],
              [-37.268128212734361, -7.78158736311339],
              [-37.267971715453733, -7.781570810781235],
              [-37.260610121390393, -7.780791497587585],
              [-37.256325803941706, -7.778634209289838],
              [-37.254670274542143, -7.778363207208007],
              [-37.25438662576174, -7.778469454882834],
              [-37.250291720050726, -7.778682608397181],
              [-37.250221888850696, -7.778688920814457],
              [-37.250185464923831, -7.778684159402537],
              [-37.250083812007482, -7.778674643298765],
              [-37.249856945409157, -7.778636240365334],
              [-37.249804327368501, -7.778623455528558],
              [-37.249750967602061, -7.778614645927794],
              [-37.249703855487127, -7.778607670409546],
              [-37.247210008266727, -7.777428028784874],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0255000',
        uf: 'PE',
        nome_proje: 'PA LIBERTAÇÃO',
        municipio: 'ITAIBA',
        area_hecta: '4373.9934',
        capacidade: 330.0,
        num_famili: 228.0,
        fase: 4.0,
        data_de_cr: '24/07/2002',
        forma_obte: 'Desapropriação',
        data_obten: '18/04/2001',
        area_calc_: 4028.5112,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.414255673105529, -8.884272993447032],
              [-37.411763225310921, -8.884655437158454],
              [-37.411738432640696, -8.885176775119245],
              [-37.411171822146571, -8.885135933689698],
              [-37.411521255721929, -8.888125391763701],
              [-37.411076556531334, -8.888508136853453],
              [-37.411521958317735, -8.890367863502963],
              [-37.411710420179396, -8.893599228410331],
              [-37.40874367328243, -8.893895710001331],
              [-37.408375647645968, -8.893953994916789],
              [-37.406131084667102, -8.894060677207223],
              [-37.406131462064188, -8.894015469705282],
              [-37.406128911663785, -8.892566144886542],
              [-37.405430005940957, -8.892713791470173],
              [-37.404998686654551, -8.892712397547939],
              [-37.404673432562184, -8.892803955041664],
              [-37.404297612700212, -8.892688780599586],
              [-37.404497178437843, -8.893949612323043],
              [-37.404503988515053, -8.893993675132847],
              [-37.404568544781455, -8.894853918755953],
              [-37.404475958703621, -8.895532381761075],
              [-37.403952080078405, -8.895866492963533],
              [-37.403842681722445, -8.895997130485126],
              [-37.403487263225706, -8.89618792662397],
              [-37.403226948150198, -8.895791321998335],
              [-37.402927612494715, -8.895796910492539],
              [-37.403047956441419, -8.896392287087165],
              [-37.403517104123736, -8.8971066121173],
              [-37.403514741465614, -8.898176067354425],
              [-37.402031444608987, -8.898470526281503],
              [-37.401910258446222, -8.898216293224797],
              [-37.398390229832728, -8.897967621469569],
              [-37.398125340838988, -8.899000048898078],
              [-37.39811276743125, -8.899089689337549],
              [-37.398100930811395, -8.89917716291594],
              [-37.398011128160611, -8.89981865299964],
              [-37.397908291990106, -8.900279251187035],
              [-37.397355401211698, -8.902554858440707],
              [-37.397542931289024, -8.902982347574861],
              [-37.397791152363446, -8.903670953415386],
              [-37.398614764694798, -8.903244832403558],
              [-37.399008030073659, -8.903197156077782],
              [-37.399115483833036, -8.903493646462552],
              [-37.39948278344766, -8.903855451111745],
              [-37.400305912873392, -8.904021380710819],
              [-37.400450315472547, -8.904059253575356],
              [-37.401510080358008, -8.904987517147864],
              [-37.401588445537165, -8.904989933378651],
              [-37.402735100051601, -8.903999896792484],
              [-37.403036808353299, -8.903637620540506],
              [-37.403176389360425, -8.903571400486783],
              [-37.403339269576954, -8.903520832234394],
              [-37.403823854511351, -8.903423722137459],
              [-37.404496007176739, -8.903181118953501],
              [-37.405007358345387, -8.902991895412175],
              [-37.405628097898777, -8.902758653916713],
              [-37.406764634251132, -8.903451241494597],
              [-37.407014034559673, -8.903556561249045],
              [-37.407243944967476, -8.903606371073163],
              [-37.407362138014612, -8.903565917402421],
              [-37.407788162793565, -8.90313382297037],
              [-37.40799647242379, -8.902985433465181],
              [-37.408022433035541, -8.902806245068694],
              [-37.407426854870337, -8.901889387914347],
              [-37.406933683330458, -8.901113749691746],
              [-37.406260918580983, -8.899839499735531],
              [-37.405863634755775, -8.89869491295264],
              [-37.408297467093902, -8.897549778199899],
              [-37.408627117446919, -8.897388614800015],
              [-37.409545208388771, -8.898365076480644],
              [-37.411417628858842, -8.900225109461751],
              [-37.411680390363209, -8.900478950040389],
              [-37.414570544381057, -8.901470682488906],
              [-37.415030694344225, -8.90128294374858],
              [-37.41605753296826, -8.901778920143572],
              [-37.416859939239458, -8.901522470320604],
              [-37.41687582044603, -8.901550658058831],
              [-37.416778520538756, -8.905548556385655],
              [-37.416785860806449, -8.906061442070152],
              [-37.416684204543841, -8.909463552374209],
              [-37.417426047256882, -8.910544228434201],
              [-37.417787006351965, -8.911329332259559],
              [-37.418457500831529, -8.912087813278953],
              [-37.418872443731196, -8.913873448106495],
              [-37.418321943329573, -8.914325279311459],
              [-37.417885360852942, -8.917420078692627],
              [-37.41777331964542, -8.918269265400451],
              [-37.417153797378859, -8.921169063374698],
              [-37.420523661922743, -8.920663951605786],
              [-37.419842668485025, -8.923842436887778],
              [-37.419861067882344, -8.923856168396645],
              [-37.419963100837208, -8.923663377811353],
              [-37.420194402676998, -8.924006234896105],
              [-37.419948554230793, -8.924719496482435],
              [-37.419804832150014, -8.925682928717206],
              [-37.419362347301721, -8.928940546743759],
              [-37.419244586827062, -8.930166393009682],
              [-37.419244656638135, -8.930255726903745],
              [-37.419244214545202, -8.930358350153947],
              [-37.420479564237993, -8.930575822838858],
              [-37.421666744838838, -8.931295543590165],
              [-37.4233027693396, -8.930871472499231],
              [-37.423370836896794, -8.93090178074419],
              [-37.424624257654266, -8.930448680563293],
              [-37.424743071931324, -8.930371334301197],
              [-37.425194537742257, -8.930160223556989],
              [-37.425524951718629, -8.929869753364461],
              [-37.426010936072451, -8.929666563905128],
              [-37.426223572977122, -8.92959314130319],
              [-37.426271481167703, -8.931228480053635],
              [-37.426181548528952, -8.931462193173839],
              [-37.425878478347258, -8.932118795439228],
              [-37.424626033902939, -8.93494747714519],
              [-37.424489375570118, -8.935283435537093],
              [-37.423891483379812, -8.935160547951456],
              [-37.423236438918508, -8.935027651290625],
              [-37.423124022615063, -8.9349911867247],
              [-37.421918725518061, -8.934695726216333],
              [-37.42150455972736, -8.934647398327618],
              [-37.420965909987657, -8.934704510778827],
              [-37.420546237984261, -8.934857972834202],
              [-37.421505562119485, -8.937921101577018],
              [-37.422421879712218, -8.940795795301694],
              [-37.422851562385873, -8.9421633984823],
              [-37.422665880874362, -8.942479345034849],
              [-37.422716121818951, -8.9427010846928],
              [-37.42280521822255, -8.942684193897078],
              [-37.422959270026979, -8.943188752975455],
              [-37.423350064776471, -8.943281919557281],
              [-37.423849814838526, -8.943265509560971],
              [-37.425772325226049, -8.94216632180142],
              [-37.426827484705854, -8.941199070283934],
              [-37.428390951750174, -8.939765690311873],
              [-37.428798852724576, -8.939707561930572],
              [-37.431907745732687, -8.939263746689555],
              [-37.432908151427981, -8.944928756926741],
              [-37.434200010506281, -8.944513678934916],
              [-37.434717842648141, -8.944352388900031],
              [-37.437203526561284, -8.943479303392806],
              [-37.43918019231581, -8.941508082352897],
              [-37.43926913865959, -8.941419574621007],
              [-37.439389829173152, -8.9386380583483],
              [-37.439406737898643, -8.938233321779192],
              [-37.4401062149186, -8.930809391057069],
              [-37.441372282209088, -8.929495580284751],
              [-37.441517883004167, -8.92876270834426],
              [-37.444540034056438, -8.928038193971355],
              [-37.445438120011765, -8.929377545746249],
              [-37.446829286942695, -8.928901523187143],
              [-37.451681853053856, -8.930617443016628],
              [-37.452192904188117, -8.929277019024568],
              [-37.451629586115516, -8.928888937829562],
              [-37.451833678373482, -8.92855541611463],
              [-37.450766140097215, -8.928212791396735],
              [-37.450858239534377, -8.927418836631382],
              [-37.45072700012399, -8.926692578539722],
              [-37.451573336928206, -8.92648751053572],
              [-37.451516681500316, -8.927092455087305],
              [-37.453066699356086, -8.926959670342157],
              [-37.452348434153876, -8.924868336238475],
              [-37.452522456666898, -8.924786236767694],
              [-37.451928963934854, -8.922562686114217],
              [-37.446724350826223, -8.92249719375463],
              [-37.446685820670851, -8.919932727209332],
              [-37.44686616572676, -8.918978376706352],
              [-37.447018357719948, -8.918983624247431],
              [-37.447201435121698, -8.918415106150571],
              [-37.447091546765307, -8.917371925478282],
              [-37.450703429826163, -8.917643522228515],
              [-37.450667115832815, -8.916637810263801],
              [-37.443222815375847, -8.916174587041903],
              [-37.443254417495744, -8.914445171736821],
              [-37.437451656806559, -8.913969814272358],
              [-37.437447399407191, -8.906753780572625],
              [-37.438519470378083, -8.907081260031307],
              [-37.438360079969215, -8.907530789799358],
              [-37.438617893246843, -8.9075855811257],
              [-37.438663942133623, -8.908754265909645],
              [-37.439006789372826, -8.908847751913132],
              [-37.439296676473951, -8.908865334261185],
              [-37.439576770618437, -8.909240936757449],
              [-37.440181770999189, -8.908880982245041],
              [-37.440578723366215, -8.908651895789475],
              [-37.440967245847474, -8.908824146176947],
              [-37.440560506527355, -8.909131224137617],
              [-37.439946032376746, -8.910438465350003],
              [-37.44015924140205, -8.910741541866336],
              [-37.440384852904494, -8.911829331452743],
              [-37.441228316675264, -8.911800678761646],
              [-37.440962153471268, -8.9121918031693],
              [-37.440508370900204, -8.912634583774668],
              [-37.441864423751475, -8.912962705314122],
              [-37.44251159967525, -8.913143995718407],
              [-37.443339907933648, -8.913238155587758],
              [-37.444112403576121, -8.913178185743188],
              [-37.444052926622476, -8.912600606894507],
              [-37.444933691363104, -8.912847159044221],
              [-37.444844726304794, -8.913286226964248],
              [-37.445587063082982, -8.91334855626849],
              [-37.44612108825504, -8.9131738370164],
              [-37.446414416329134, -8.913540178060146],
              [-37.447161183541326, -8.912984412758455],
              [-37.446171886732806, -8.911481028704817],
              [-37.445645546434264, -8.911731281111264],
              [-37.444911039165696, -8.910828804720905],
              [-37.444796740300326, -8.91039982748419],
              [-37.445324515870404, -8.910282860811749],
              [-37.445285117411103, -8.91000971877539],
              [-37.447807891528207, -8.909464625625747],
              [-37.447701236965251, -8.908473088081257],
              [-37.447277087515587, -8.908446904503409],
              [-37.447331976540227, -8.907656954266171],
              [-37.445481698615339, -8.907492620223913],
              [-37.445365895712271, -8.907871356974955],
              [-37.445624336047132, -8.90842381691432],
              [-37.445285182055507, -8.908789775216469],
              [-37.445068627176965, -8.90940634674079],
              [-37.444175079820532, -8.909281628582329],
              [-37.443584693094202, -8.909476183197235],
              [-37.443139432254199, -8.908680525141436],
              [-37.442784231871535, -8.90873401350729],
              [-37.442367947936383, -8.908589859584993],
              [-37.441981574763211, -8.908725769793961],
              [-37.441016184156943, -8.908604812451165],
              [-37.440652726076578, -8.908504279544321],
              [-37.440646541344627, -8.907861189377179],
              [-37.440945237130343, -8.907859819125585],
              [-37.440908150398755, -8.907449610723011],
              [-37.442092625763522, -8.907602047204817],
              [-37.443021144132793, -8.907518950620013],
              [-37.443068587903255, -8.907480539959941],
              [-37.443713038852252, -8.907379796247],
              [-37.444712274029854, -8.907431174044223],
              [-37.444817593335955, -8.907403132090908],
              [-37.444639605947891, -8.905800872912069],
              [-37.437644347537422, -8.905339361765529],
              [-37.437737821567431, -8.903260828977739],
              [-37.437797318446549, -8.903124635662738],
              [-37.439107155352573, -8.903171362306111],
              [-37.440827625444939, -8.902376641666748],
              [-37.441282964231497, -8.901884857321809],
              [-37.441495713376469, -8.89980437760965],
              [-37.439709621479615, -8.899072997850574],
              [-37.439328809096743, -8.898714425419874],
              [-37.438411770826846, -8.897532233230129],
              [-37.438309107011563, -8.897384961385637],
              [-37.438555924856708, -8.8934680255388],
              [-37.438579539722738, -8.893087638874364],
              [-37.43860289263219, -8.891438483343052],
              [-37.43890058678825, -8.888406966110619],
              [-37.438818556890581, -8.888368646360107],
              [-37.438055808751052, -8.887943191925757],
              [-37.436971316670828, -8.887748765750214],
              [-37.435922656155874, -8.887852690449142],
              [-37.435842699031944, -8.887690232836889],
              [-37.430358258652582, -8.887295831181456],
              [-37.427979789938512, -8.886998548691185],
              [-37.423979878042843, -8.886592770747249],
              [-37.422714159507493, -8.886472044167936],
              [-37.419205969958355, -8.886169836818851],
              [-37.419461369849444, -8.886910455028996],
              [-37.416786040136238, -8.887340890557226],
              [-37.41643700919564, -8.886428530322341],
              [-37.4144384490615, -8.886571307659628],
              [-37.414255673105529, -8.884272993447032],
            ],
          ],
          [
            [
              [-37.370175720447783, -8.948313571520512],
              [-37.369982127069285, -8.948578724768971],
              [-37.369914595955876, -8.948673726486474],
              [-37.369311410411065, -8.949497205996023],
              [-37.369504092792454, -8.952254308518162],
              [-37.369559408428955, -8.952819564304109],
              [-37.369619950856411, -8.954824181942785],
              [-37.367836677309079, -8.9548982394275],
              [-37.366053495522294, -8.954972288587694],
              [-37.365425593855015, -8.953649710335235],
              [-37.365230648251789, -8.953238992695441],
              [-37.364495451231157, -8.953645963478158],
              [-37.364141958297523, -8.953938434857113],
              [-37.363931627234123, -8.954121863812174],
              [-37.363176243896831, -8.954446644314924],
              [-37.361899037324108, -8.954862604085108],
              [-37.361117328703877, -8.955318728185043],
              [-37.359949095053103, -8.955847821141358],
              [-37.360844427066496, -8.958202694773879],
              [-37.363878137674419, -8.958233934847001],
              [-37.366562415004132, -8.957750676087695],
              [-37.366724759415476, -8.958294704107242],
              [-37.366870839772375, -8.958774736026252],
              [-37.367130981872407, -8.9596556346981],
              [-37.362083635305275, -8.962679799355993],
              [-37.362599558163801, -8.963624038691176],
              [-37.363322514259202, -8.965631131229035],
              [-37.363649427928763, -8.966951839689639],
              [-37.363740209817159, -8.966945822025346],
              [-37.364219747445972, -8.967000566681403],
              [-37.364221368091656, -8.967065130962483],
              [-37.36431845904464, -8.967965562693362],
              [-37.36856557356127, -8.96790802320325],
              [-37.369095733508424, -8.967847885337505],
              [-37.372657517743335, -8.967777418241159],
              [-37.376357142108191, -8.967712766250756],
              [-37.37881936636618, -8.96767230817342],
              [-37.379428119401801, -8.967648124041004],
              [-37.381598483765615, -8.967679062648299],
              [-37.377538372271786, -8.973394764421235],
              [-37.377304091686931, -8.974709207123084],
              [-37.377886284537844, -8.980438574982569],
              [-37.376802370775415, -8.98560663319253],
              [-37.376794477823026, -8.987522525615079],
              [-37.376890534883266, -8.989417085028828],
              [-37.377089690559551, -8.9914205981542],
              [-37.372258623467914, -8.99151611719277],
              [-37.372267994709439, -8.993876567800548],
              [-37.377322339114308, -8.993949307779614],
              [-37.37743647759131, -8.995112296038107],
              [-37.379526924922985, -8.99456091109008],
              [-37.381686123428167, -8.994172926863218],
              [-37.386770158452833, -8.993420975373382],
              [-37.395799620970919, -8.991700411632239],
              [-37.397468664898263, -8.99086588448699],
              [-37.398089986887285, -8.990019655079539],
              [-37.398316299844872, -8.989492238524081],
              [-37.398900026803901, -8.988135346444958],
              [-37.39951262046641, -8.987887368089108],
              [-37.400712958192251, -8.987955686689492],
              [-37.400771600655837, -8.987959376853437],
              [-37.402916930916383, -8.988049597224204],
              [-37.403342278096673, -8.988028111188546],
              [-37.405438719476336, -8.987257780185658],
              [-37.407531257599068, -8.986213452702188],
              [-37.408241213598735, -8.985785133166436],
              [-37.412744859422055, -8.982831501333592],
              [-37.412819093268034, -8.982784079874389],
              [-37.415776881661948, -8.980803700796965],
              [-37.415867630006332, -8.980743690911806],
              [-37.418204566260002, -8.980117620575603],
              [-37.420803892168173, -8.979653511109536],
              [-37.421340761983906, -8.979560958525889],
              [-37.423727943691482, -8.979174403147697],
              [-37.425837284754131, -8.977061006332853],
              [-37.427525970081781, -8.971131305618439],
              [-37.427725282393702, -8.969447922996874],
              [-37.425570679575486, -8.967750089723754],
              [-37.425618490511489, -8.963976848746238],
              [-37.426154670992346, -8.958693611880971],
              [-37.425409929795784, -8.955642878853785],
              [-37.421024455085259, -8.956183614119281],
              [-37.419059822075582, -8.954677534355074],
              [-37.418343463263241, -8.951765440133627],
              [-37.417072981746266, -8.951329347550072],
              [-37.41521362399417, -8.951729903960851],
              [-37.41151110471408, -8.950033253313002],
              [-37.409506405293854, -8.951404338647567],
              [-37.407709444664938, -8.952469696478818],
              [-37.405635422740097, -8.953695608218515],
              [-37.403629214197046, -8.954882557029038],
              [-37.401815347800174, -8.955897263743513],
              [-37.401734047077355, -8.955939861076429],
              [-37.401270368356329, -8.956189038777328],
              [-37.399236479826513, -8.957191296811764],
              [-37.395507411922395, -8.958698138046692],
              [-37.391725493276844, -8.960223694127242],
              [-37.389270687052758, -8.962242427367384],
              [-37.388837820944808, -8.960118359702948],
              [-37.388758166781585, -8.959745493880767],
              [-37.388294632038793, -8.958122836946471],
              [-37.387044197395518, -8.958592258589748],
              [-37.386991765907339, -8.956748153321175],
              [-37.386989982151498, -8.956657728404343],
              [-37.38691832322047, -8.954162354944806],
              [-37.386257901888634, -8.952194802520165],
              [-37.386157669836827, -8.951869948959699],
              [-37.383567965560815, -8.951868959038961],
              [-37.381606549359653, -8.950628640467682],
              [-37.380746405152564, -8.950629581742641],
              [-37.377583558420625, -8.950732284343342],
              [-37.377150588713619, -8.948422137699461],
              [-37.370175720447783, -8.948313571520512],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0325000',
        uf: 'PE',
        nome_proje: 'PA ROSÁRIO',
        municipio: 'IGUARACI',
        area_hecta: '1751.7885',
        capacidade: 200.0,
        num_famili: 163.0,
        fase: 3.0,
        data_de_cr: '10/11/2005',
        forma_obte: 'Doação',
        data_obten: '10/11/2005',
        area_calc_: 2891.1069,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.46749580765325, -7.798467892886737],
              [-37.47334452900698, -7.785902244745784],
              [-37.473540276990541, -7.78526302319292],
              [-37.473662957379759, -7.784607069716331],
              [-37.473743367795542, -7.783572183962888],
              [-37.474497968123025, -7.771241014127812],
              [-37.46205647763847, -7.768227090375186],
              [-37.446227870244371, -7.789917325651539],
              [-37.436729547413258, -7.781974022256839],
              [-37.424125881961395, -7.785483365297472],
              [-37.420591173592229, -7.78388219841238],
              [-37.420012288080528, -7.783508329134564],
              [-37.41437958082436, -7.780918259469319],
              [-37.412064889474166, -7.779836813792305],
              [-37.411689036425635, -7.779421348591605],
              [-37.410813505065775, -7.778900497526759],
              [-37.409865403821762, -7.778859304001792],
              [-37.408099435789673, -7.778019861995497],
              [-37.405758799586572, -7.776947701162051],
              [-37.405423868156632, -7.776941737765281],
              [-37.405305910539575, -7.776945999778378],
              [-37.404553744878385, -7.776242099244756],
              [-37.404266790236797, -7.776024581708796],
              [-37.398218776762192, -7.773536288411738],
              [-37.393303002721083, -7.771352174030786],
              [-37.380897441181808, -7.774573974873843],
              [-37.391581610193093, -7.782139759640722],
              [-37.40569036816261, -7.785847719341111],
              [-37.40539384467634, -7.791765219077988],
              [-37.413176800707248, -7.791511071906997],
              [-37.427011893777106, -7.801537802297048],
              [-37.416897336126112, -7.816560601668943],
              [-37.429635406155391, -7.819984069822057],
              [-37.444938221105744, -7.802896961774715],
              [-37.446249088032154, -7.816420814411539],
              [-37.450703454019916, -7.8268763253591],
              [-37.454824054834575, -7.827149141480428],
              [-37.450623390985648, -7.836507089932693],
              [-37.45826497012483, -7.847621611799201],
              [-37.461209052647739, -7.846400399952159],
              [-37.457931220638301, -7.833481771584195],
              [-37.465097430698883, -7.836993392034292],
              [-37.466485059851934, -7.827856015920327],
              [-37.459314992420936, -7.819645009536947],
              [-37.467848688254612, -7.813629726563756],
              [-37.464824001506777, -7.804671585419666],
              [-37.459865211926008, -7.798889555261183],
              [-37.467343783022834, -7.798638863959049],
              [-37.46749580765325, -7.798467892886737],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0087000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA BARRA VERDE',
        municipio: 'ITAIBA',
        area_hecta: '3475.7400',
        capacidade: 124.0,
        num_famili: 95.0,
        fase: 6.0,
        data_de_cr: '02/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '24/07/1996',
        area_calc_: 3598.4683,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.304644178130758, -8.907961242416864],
              [-37.302728942654689, -8.909985584350499],
              [-37.300836463698197, -8.911890719319658],
              [-37.299404853668662, -8.913304880790598],
              [-37.298151596089419, -8.914572408300513],
              [-37.297154201427169, -8.91554860428001],
              [-37.29611235342874, -8.916530562095033],
              [-37.295367203995227, -8.917218991631367],
              [-37.29178212135524, -8.920351947498862],
              [-37.288930918874868, -8.922631044777395],
              [-37.286575067539367, -8.924494547716677],
              [-37.283578360460659, -8.926809929581934],
              [-37.281123830762475, -8.928677642637274],
              [-37.277542872686318, -8.931172979685062],
              [-37.275619606977784, -8.930637555893728],
              [-37.273669487951175, -8.929942021686971],
              [-37.272774519050849, -8.931072018507255],
              [-37.270823737333579, -8.933574798637979],
              [-37.27026790737991, -8.934326721864908],
              [-37.268960548029085, -8.935065586048582],
              [-37.267241904398908, -8.935691495049818],
              [-37.265914445487347, -8.936235654984836],
              [-37.264879139471304, -8.936567477630891],
              [-37.264566487171386, -8.93667107323399],
              [-37.264324219498455, -8.93675756396704],
              [-37.264130384016383, -8.936865665690114],
              [-37.263941611219614, -8.936987569695493],
              [-37.2637570421494, -8.937126092802002],
              [-37.263583425705349, -8.937278825509111],
              [-37.263418195501494, -8.937446253073549],
              [-37.263311485629337, -8.93763221448879],
              [-37.262617990925833, -8.940066802137718],
              [-37.262144623420255, -8.941779544481749],
              [-37.261715112232793, -8.943484134070903],
              [-37.259333465831418, -8.943899156291319],
              [-37.254415365550081, -8.944711708548368],
              [-37.249929796915431, -8.945459379887627],
              [-37.248096196138086, -8.945756335883685],
              [-37.242552894957214, -8.946744838780536],
              [-37.239010286719058, -8.947392978150804],
              [-37.237772734279957, -8.947578105158879],
              [-37.236616823186871, -8.9477636165094],
              [-37.235583454848779, -8.947932994224734],
              [-37.2340717694251, -8.94819798245647],
              [-37.232687532517637, -8.948425369805827],
              [-37.230492972976819, -8.948753937432723],
              [-37.229120832823952, -8.94896226718587],
              [-37.225814449675937, -8.949420389280501],
              [-37.221987926251643, -8.949932092594205],
              [-37.218223889686044, -8.950432116771223],
              [-37.214942839328316, -8.950863994416812],
              [-37.216622901479298, -8.954607548860224],
              [-37.2170231195513, -8.955510810250807],
              [-37.217308079399523, -8.956166992700517],
              [-37.217934624593745, -8.957563197547405],
              [-37.218204167823899, -8.958180506469459],
              [-37.218737786756854, -8.959428826780705],
              [-37.219551589920336, -8.961329724569611],
              [-37.220392017789457, -8.963188966437352],
              [-37.220956448624655, -8.96439982901879],
              [-37.221415313395838, -8.965345155256266],
              [-37.221613126461747, -8.965777076282789],
              [-37.22247955519039, -8.967478260726796],
              [-37.222816284554121, -8.968109024020828],
              [-37.223170705685696, -8.968805532466703],
              [-37.225147798238005, -8.969025146758934],
              [-37.226791065326047, -8.969127945067521],
              [-37.229528341193877, -8.969321338275995],
              [-37.230205937763685, -8.969363383705723],
              [-37.232960216235846, -8.969642789148724],
              [-37.237224368570359, -8.97005353981818],
              [-37.241411701431161, -8.970462683513308],
              [-37.244859392355558, -8.970874834382313],
              [-37.247865549096495, -8.971220393428759],
              [-37.249034416550728, -8.971353974362744],
              [-37.249850280785566, -8.971485581080156],
              [-37.25076337424062, -8.971623615703631],
              [-37.251845836574105, -8.971748123671601],
              [-37.252882810557018, -8.971843761310476],
              [-37.253802310608833, -8.97189825027429],
              [-37.256110124441761, -8.971897213880315],
              [-37.257528136775477, -8.971908680727193],
              [-37.258854856553825, -8.971928068754275],
              [-37.260757730376511, -8.971989562039596],
              [-37.26079166297415, -8.971924060357104],
              [-37.261028956450623, -8.971262593729255],
              [-37.261244282282455, -8.97042314050465],
              [-37.261878744587982, -8.967799666219351],
              [-37.262004509460326, -8.967107827319099],
              [-37.262075350989704, -8.966603163777394],
              [-37.262564438087807, -8.963489525231637],
              [-37.263070487291557, -8.960088246662117],
              [-37.263096296822745, -8.959963013974251],
              [-37.263131258069954, -8.959933331981219],
              [-37.263220141810777, -8.959926586266684],
              [-37.264365107180382, -8.960032243864019],
              [-37.264735877226954, -8.96008412510014],
              [-37.264921704041249, -8.960143495392638],
              [-37.265140883594377, -8.960259132889229],
              [-37.265332419467796, -8.960380609871811],
              [-37.265666014769657, -8.960675860918958],
              [-37.265887228253078, -8.960869107742784],
              [-37.266142723314239, -8.960922834939213],
              [-37.266906937939474, -8.961056546387981],
              [-37.267163560535593, -8.961125798249736],
              [-37.267443024442677, -8.961189187711296],
              [-37.268050607571915, -8.961183676623893],
              [-37.268141791328098, -8.961198429827467],
              [-37.268455815228812, -8.961316897489418],
              [-37.26893569719914, -8.961401519658086],
              [-37.269080826929347, -8.961434432903923],
              [-37.269228408921094, -8.961456612922557],
              [-37.269374925885337, -8.961450135495179],
              [-37.269797887613407, -8.961384065076508],
              [-37.269988948405228, -8.961352724928009],
              [-37.270066975719338, -8.961356671477091],
              [-37.270717785211993, -8.961605051596251],
              [-37.271338671831103, -8.961833592306276],
              [-37.271678943526503, -8.961987995760367],
              [-37.271845488733248, -8.962062792905892],
              [-37.272015734823299, -8.962116714875368],
              [-37.272308538298837, -8.962152704684469],
              [-37.272499458506786, -8.962151208918458],
              [-37.272625620702165, -8.962132696631967],
              [-37.272902325374929, -8.962018185386116],
              [-37.273010516731091, -8.961991232058072],
              [-37.27311621648105, -8.9619833687],
              [-37.27326011824421, -8.962022243942862],
              [-37.273353467216538, -8.962087147856877],
              [-37.273600926684566, -8.962317522938871],
              [-37.27393578298426, -8.962602029257152],
              [-37.274115170307333, -8.962754485906952],
              [-37.274262404079394, -8.962850681335429],
              [-37.274452975445612, -8.962923201897793],
              [-37.274659274193432, -8.962970724840922],
              [-37.274884913629762, -8.962990879282851],
              [-37.275204437859934, -8.962962523540979],
              [-37.27549510611842, -8.962942389912703],
              [-37.275677610949927, -8.96294324103701],
              [-37.276187802824872, -8.962967109252444],
              [-37.276490466825507, -8.962949418463507],
              [-37.276885955417121, -8.962853365589597],
              [-37.277358535581399, -8.962705141786881],
              [-37.277483497353614, -8.962686622238573],
              [-37.277682828785643, -8.962683969166653],
              [-37.278294025160228, -8.962677264628125],
              [-37.278859671332576, -8.962654230005008],
              [-37.278942496518113, -8.962659390935025],
              [-37.279045654772652, -8.962681360463142],
              [-37.279288833118045, -8.962801877769534],
              [-37.279739175197363, -8.963041550579566],
              [-37.280078291572536, -8.963187584772292],
              [-37.280384899668043, -8.963352569223066],
              [-37.28061525324631, -8.963391843898915],
              [-37.280790593933673, -8.963384302225396],
              [-37.281119982821977, -8.963301069779245],
              [-37.281184837159749, -8.96329778969511],
              [-37.281700581963719, -8.96571655439201],
              [-37.281145737475015, -8.966250016654003],
              [-37.28095302950539, -8.966375669311562],
              [-37.280659703773352, -8.966449517980386],
              [-37.280087662827071, -8.96655430370836],
              [-37.279825594557714, -8.966619940108664],
              [-37.27960180262626, -8.966716794726581],
              [-37.279252327699787, -8.966984979326547],
              [-37.279014838464938, -8.967185635195216],
              [-37.278602868015362, -8.967848097220349],
              [-37.277864729919116, -8.969055227263141],
              [-37.277483194934135, -8.969628289960538],
              [-37.27615055322908, -8.97073534375062],
              [-37.275280287839216, -8.971440430933889],
              [-37.27487110780811, -8.971636699974111],
              [-37.274442817452261, -8.971811389862388],
              [-37.272917597342527, -8.972377311065857],
              [-37.272738402223936, -8.972437359621464],
              [-37.272325016005929, -8.972507057454411],
              [-37.272073777631476, -8.972566768685949],
              [-37.271392848284229, -8.972842943812715],
              [-37.269881599942991, -8.973499648749399],
              [-37.267256291782054, -8.974611946644497],
              [-37.265906778983343, -8.975090314289099],
              [-37.265216485912418, -8.975313903010344],
              [-37.264034517048323, -8.975656951450931],
              [-37.263565871244296, -8.975731154062064],
              [-37.26280643427372, -8.975849354206517],
              [-37.26206334112878, -8.976065524911544],
              [-37.260698295745392, -8.976470890439458],
              [-37.26260021787072, -8.979560075634488],
              [-37.264657831524346, -8.982863812215093],
              [-37.266670414047852, -8.986082616291471],
              [-37.268579042803609, -8.989176611104051],
              [-37.268648340588165, -8.989290855333396],
              [-37.269631455001914, -8.990884367446057],
              [-37.269679383576928, -8.99092184206534],
              [-37.270409865242513, -8.990479859334878],
              [-37.27089452614549, -8.990324544657287],
              [-37.271561030598789, -8.990059053785641],
              [-37.27194164420866, -8.989909655556064],
              [-37.272246029819129, -8.989691573991097],
              [-37.272692048207681, -8.989328345514584],
              [-37.273619091427484, -8.988562653236002],
              [-37.274151726658715, -8.98816520626686],
              [-37.274595339885792, -8.987801963509696],
              [-37.27507844774042, -8.987466363378566],
              [-37.276087962016099, -8.986779843011103],
              [-37.276424544772759, -8.986576670318298],
              [-37.276931371508191, -8.986303258342055],
              [-37.277066553979651, -8.98622002164146],
              [-37.277478508308768, -8.985821407842483],
              [-37.277939935557107, -8.985371688654599],
              [-37.278366657970722, -8.985023881243597],
              [-37.27892536153125, -8.984443887300264],
              [-37.279148803983915, -8.984169149205915],
              [-37.279543497583944, -8.983737620690222],
              [-37.281139880008297, -8.98209515254703],
              [-37.284495792451018, -8.982257613142023],
              [-37.289406078518965, -8.9824803817742],
              [-37.291540976723951, -8.983015572232912],
              [-37.29425937344422, -8.983635527732945],
              [-37.294343557127952, -8.98360815959694],
              [-37.295837342666083, -8.982581175542988],
              [-37.29678303895853, -8.981922946741275],
              [-37.297677349921685, -8.981210753869687],
              [-37.298750110724939, -8.980340596522648],
              [-37.298993677599817, -8.98012443539886],
              [-37.299185266445718, -8.979982054295826],
              [-37.300055953306455, -8.979312724248434],
              [-37.300691372051126, -8.97875333830242],
              [-37.300863074819077, -8.978626384808186],
              [-37.300955599731417, -8.97853159058522],
              [-37.30228197331617, -8.977427702491077],
              [-37.302731914453602, -8.976986845777549],
              [-37.302984165211718, -8.976709833727513],
              [-37.303146697784406, -8.976359583085012],
              [-37.303393049439627, -8.976059859977426],
              [-37.303853577885775, -8.97566561089296],
              [-37.305475810412382, -8.974246084519942],
              [-37.306552139230234, -8.975381319438686],
              [-37.307004428910631, -8.975921232605574],
              [-37.307596669654771, -8.976642658588879],
              [-37.307680633461572, -8.976921215669764],
              [-37.307898404880476, -8.976769632036964],
              [-37.312414181799333, -8.973141285354835],
              [-37.311341283676825, -8.971325572448883],
              [-37.309466511392202, -8.97095346370196],
              [-37.309229251783044, -8.970847317098677],
              [-37.309071767433252, -8.970740718077492],
              [-37.309168404496049, -8.970710105514735],
              [-37.308538874858428, -8.970321223287808],
              [-37.307128184133063, -8.969517858397593],
              [-37.306255413706111, -8.969001145034643],
              [-37.305688455339222, -8.968623532505775],
              [-37.305233170526229, -8.968310886876544],
              [-37.304781173003839, -8.967925011126944],
              [-37.303823462045528, -8.967299501425433],
              [-37.303426935838225, -8.967025211037202],
              [-37.302727836153046, -8.966550305439286],
              [-37.301951481174818, -8.965579909904838],
              [-37.301562076952813, -8.965041969995006],
              [-37.301376905312125, -8.964941506473952],
              [-37.299917344594363, -8.963250171163372],
              [-37.299685806127435, -8.962982496135734],
              [-37.299595457546523, -8.962768206419359],
              [-37.299550438507012, -8.962310953068991],
              [-37.29934338340653, -8.961214261674691],
              [-37.299356586805281, -8.960909624839163],
              [-37.299446590980097, -8.960564324142666],
              [-37.299606930405204, -8.960301380713442],
              [-37.299700304965576, -8.960181688794906],
              [-37.299641782879768, -8.960093526228878],
              [-37.299825367286516, -8.959901004317953],
              [-37.299830043703217, -8.959528943120793],
              [-37.29978754829844, -8.959162524680327],
              [-37.29973527351077, -8.958998216454569],
              [-37.299789318932682, -8.958781660759914],
              [-37.300105164085643, -8.958027227570222],
              [-37.300403138020378, -8.957313728766261],
              [-37.300937158117534, -8.956530998557971],
              [-37.301375853749747, -8.955970493447214],
              [-37.302404718873085, -8.955225188778678],
              [-37.303066420672707, -8.954864929523284],
              [-37.303835145973828, -8.95430007393262],
              [-37.304231198734193, -8.954038207479341],
              [-37.304604031919609, -8.953700059516656],
              [-37.304628635445376, -8.953477507957224],
              [-37.304712087296039, -8.953272804675802],
              [-37.304820658009, -8.952734219961945],
              [-37.304823290570404, -8.95216585212088],
              [-37.304759987769827, -8.951837425400109],
              [-37.304796783828465, -8.95152703578991],
              [-37.304916243895811, -8.951181867121221],
              [-37.304906194564914, -8.950806807537482],
              [-37.30437718679508, -8.950511405946672],
              [-37.303137036647485, -8.949802574707931],
              [-37.302179099241187, -8.949235664049104],
              [-37.301485122331968, -8.948933643169916],
              [-37.300685781687001, -8.94847878877016],
              [-37.299768925673078, -8.947950148866667],
              [-37.299633892759879, -8.947841126857112],
              [-37.299441114834828, -8.947476948276476],
              [-37.299349277875649, -8.946949165111914],
              [-37.299285572019244, -8.946708630155101],
              [-37.298958456658674, -8.946086013547838],
              [-37.298488570236415, -8.945434908155477],
              [-37.297985924673121, -8.944541944750702],
              [-37.297223870090221, -8.943041323864165],
              [-37.297160315673949, -8.942768561948435],
              [-37.296983349867972, -8.942173002696739],
              [-37.296779635510255, -8.941627126848649],
              [-37.29670965762427, -8.941468596959259],
              [-37.296589468722672, -8.941336204353989],
              [-37.295934428128753, -8.940902515259754],
              [-37.295508899789667, -8.940535800840944],
              [-37.295265386412169, -8.940312017596982],
              [-37.29515991664983, -8.940182622150935],
              [-37.294940451715782, -8.939856407049344],
              [-37.294820250350874, -8.939726943787987],
              [-37.294333730241824, -8.939170977088777],
              [-37.294113816621525, -8.938941442394121],
              [-37.293869241503273, -8.938946176456907],
              [-37.293663269123357, -8.938886632904525],
              [-37.293498750428142, -8.938783333386008],
              [-37.293269848500238, -8.938585984506185],
              [-37.292982347181209, -8.938318051054091],
              [-37.292624256688896, -8.938023424385635],
              [-37.292477444103405, -8.937914346520037],
              [-37.292101212306868, -8.937719248351733],
              [-37.291957127970811, -8.937657059324115],
              [-37.291783389113029, -8.937635750487539],
              [-37.291123339521008, -8.937647357844559],
              [-37.290775643555378, -8.937651614733952],
              [-37.290675372236379, -8.937671660902119],
              [-37.290081614314992, -8.937841779789013],
              [-37.289435831786626, -8.937953062134325],
              [-37.28914080767737, -8.938035199404942],
              [-37.288857746634442, -8.938079304586717],
              [-37.288208921604578, -8.938211079005177],
              [-37.287778508999011, -8.93826182738751],
              [-37.28735105641065, -8.938309659149439],
              [-37.287044581872728, -8.938319962362032],
              [-37.286475869128459, -8.938331983168863],
              [-37.286020679724025, -8.938325484445503],
              [-37.285695129479649, -8.938319584617574],
              [-37.285617116510458, -8.938305307497846],
              [-37.285523018563097, -8.938265320509064],
              [-37.285215201363329, -8.938090308248094],
              [-37.284921177422554, -8.937958573643396],
              [-37.28452839662328, -8.937838100998034],
              [-37.284175233990247, -8.937751503615203],
              [-37.283972149458116, -8.937705152388704],
              [-37.28382648421627, -8.937666391239029],
              [-37.28362946393343, -8.937583445854253],
              [-37.283347815605161, -8.937483262430321],
              [-37.283031370629097, -8.937263529820358],
              [-37.282673126158237, -8.937004051614561],
              [-37.282258970696695, -8.936729665342234],
              [-37.282259190310981, -8.93668279015656],
              [-37.282302200236622, -8.936621464373829],
              [-37.283085980340502, -8.935983475886795],
              [-37.283885051532714, -8.935225435602622],
              [-37.284853360723361, -8.934189847747685],
              [-37.285648546890627, -8.933631008469563],
              [-37.286372650012019, -8.933150942427591],
              [-37.287187188064387, -8.93254824181353],
              [-37.287855403441654, -8.932047405658301],
              [-37.288953152336155, -8.931050487652005],
              [-37.290515645587135, -8.929586942395849],
              [-37.291357436265727, -8.928823217595998],
              [-37.291953006009955, -8.928418719911122],
              [-37.292428678106333, -8.928136720149617],
              [-37.293154937460436, -8.927505763370675],
              [-37.29388755670935, -8.926773756474313],
              [-37.295275745561334, -8.925721017435274],
              [-37.295997440138756, -8.925119330128439],
              [-37.296282183705664, -8.924710466988584],
              [-37.296880685345855, -8.923353792137403],
              [-37.297139426447458, -8.922833476738321],
              [-37.297733480893413, -8.921799055480244],
              [-37.299011465249883, -8.919724760073116],
              [-37.299956644524237, -8.917938984256452],
              [-37.300768651730692, -8.917553036157328],
              [-37.30103131043132, -8.917457554024319],
              [-37.302526305600558, -8.917188985649975],
              [-37.303573153000428, -8.916988680426147],
              [-37.304616986002209, -8.916803007277492],
              [-37.305350981318014, -8.916721390994079],
              [-37.305772733515077, -8.916626630627889],
              [-37.306085651796465, -8.916493286274168],
              [-37.306446222100021, -8.916248826334206],
              [-37.306674105190723, -8.916027199835746],
              [-37.307214734378, -8.915389500946098],
              [-37.307740961164008, -8.914679955172211],
              [-37.308358716391943, -8.914247691250539],
              [-37.310583952806326, -8.912787048082972],
              [-37.310690116080671, -8.912765556932804],
              [-37.310627223498621, -8.912644496896398],
              [-37.310139491024181, -8.912187413040932],
              [-37.30992561416096, -8.911964379717586],
              [-37.309753816947079, -8.911728404956492],
              [-37.309503749400818, -8.911412681484409],
              [-37.30925832249487, -8.911132198388639],
              [-37.309117188394424, -8.911016347518734],
              [-37.308877695185267, -8.910881544216],
              [-37.308329237360375, -8.9105769987942],
              [-37.307825107049133, -8.910292950325259],
              [-37.307371731157623, -8.910067632238357],
              [-37.306884757393206, -8.909838579111968],
              [-37.306732117859703, -8.909744761872991],
              [-37.306581394651943, -8.909625881925301],
              [-37.306425995851356, -8.909479521507675],
              [-37.306200051601529, -8.909270758257765],
              [-37.305993831168507, -8.9090805898159],
              [-37.305754670569151, -8.908874153935427],
              [-37.305391205068915, -8.908547764140266],
              [-37.305015520385844, -8.908267879532136],
              [-37.304644178130758, -8.907961242416864],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0239000',
        uf: 'PE',
        nome_proje: 'PA SANTA LUZIA',
        municipio: 'ITAIBA',
        area_hecta: '883.2822',
        capacidade: 27.0,
        num_famili: 25.0,
        fase: 5.0,
        data_de_cr: '27/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 889.8791,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.298135135548812, -9.02578667144634],
              [-37.292511798290576, -9.023087026750588],
              [-37.285445985788385, -9.018923375856943],
              [-37.286364352897408, -9.017528280616444],
              [-37.287534798556926, -9.016289479359768],
              [-37.286998010896539, -9.016002130243157],
              [-37.286919383055903, -9.015627060436389],
              [-37.285182639363022, -9.014277889755265],
              [-37.283473763109569, -9.010684197832218],
              [-37.282456511690391, -9.010925408407877],
              [-37.280091101613934, -9.011969581421916],
              [-37.271477231441096, -9.014495308878569],
              [-37.266739848932481, -9.014733860742654],
              [-37.266545545807276, -9.016873519702031],
              [-37.265163443928884, -9.019455039914865],
              [-37.262781109090653, -9.021835042868448],
              [-37.267084244813034, -9.025503775756738],
              [-37.268515653055644, -9.02614995667698],
              [-37.271830970531063, -9.028765133823338],
              [-37.272444056827013, -9.03050335994792],
              [-37.27253535983602, -9.031689193739419],
              [-37.272878841952398, -9.033401487692212],
              [-37.273798133046249, -9.034207151562086],
              [-37.274433373200722, -9.034393390915994],
              [-37.282494707917415, -9.035998556060774],
              [-37.290162081520918, -9.030789139964162],
              [-37.294135441609541, -9.039257855737411],
              [-37.303825137726463, -9.04218309958763],
              [-37.307242366800565, -9.042744250500842],
              [-37.310791055772384, -9.042577822184978],
              [-37.312088957903917, -9.043249143224157],
              [-37.313831270317827, -9.043543207582431],
              [-37.31515115220089, -9.03785547157565],
              [-37.306468929302639, -9.030811500875483],
              [-37.30369528016233, -9.028914728101384],
              [-37.298135135548812, -9.02578667144634],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0396000',
        uf: 'PE',
        nome_proje: 'PA SAO JOSE',
        municipio: 'PEDRA',
        area_hecta: '706.9315',
        capacidade: 27.0,
        num_famili: 27.0,
        fase: 3.0,
        data_de_cr: '16/10/2012',
        forma_obte: 'Desapropriação',
        data_obten: '18/11/2008',
        area_calc_: 706.5553,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.883100908128242, -8.874612790219924],
              [-36.882503510889052, -8.874751499131422],
              [-36.881403937697996, -8.875018426347182],
              [-36.880805296582409, -8.875183700588751],
              [-36.881133571137454, -8.872779765655896],
              [-36.881425476091351, -8.871200907419354],
              [-36.881914025702336, -8.870151146949251],
              [-36.88010021975294, -8.869459476622948],
              [-36.879411372354369, -8.869301718114981],
              [-36.878575022955737, -8.869142397576129],
              [-36.877912536954888, -8.869095065059334],
              [-36.877862172117567, -8.869666872523309],
              [-36.877661309433748, -8.871343504547097],
              [-36.87753392897649, -8.87253749675698],
              [-36.87753263882643, -8.872588018767095],
              [-36.877111953597399, -8.875710942781607],
              [-36.874067932698509, -8.876063320649603],
              [-36.870760622100484, -8.876444090003265],
              [-36.870365132023842, -8.877110733968848],
              [-36.87467383229221, -8.881203398681155],
              [-36.869031788446449, -8.887571766391119],
              [-36.873667466595606, -8.889923304637104],
              [-36.873858843974148, -8.890041183161408],
              [-36.874167865294154, -8.890437869315964],
              [-36.874393330701942, -8.891028060245876],
              [-36.874523475408203, -8.891582183323553],
              [-36.87450343760915, -8.892771087158451],
              [-36.874523031113831, -8.892793706587341],
              [-36.874565225155301, -8.89286915357275],
              [-36.874992791227228, -8.893294537614807],
              [-36.87552920714559, -8.892387438190598],
              [-36.876936368773919, -8.890110071802575],
              [-36.878417245042328, -8.887718678705758],
              [-36.878530888462578, -8.887382252330005],
              [-36.878578475511958, -8.887294571365565],
              [-36.878747316960009, -8.886226822916431],
              [-36.878919397336489, -8.885780811539938],
              [-36.879784172224724, -8.886329983373818],
              [-36.880443930442539, -8.886509457967115],
              [-36.880513372284959, -8.886525309891248],
              [-36.880554461347138, -8.886476912331153],
              [-36.880986013484566, -8.886810202176784],
              [-36.881217054355815, -8.887064795280077],
              [-36.881399958082959, -8.887328967513945],
              [-36.881574736480808, -8.887567783622824],
              [-36.881678792157466, -8.887681456060067],
              [-36.882018015715367, -8.887284119687434],
              [-36.882300151590307, -8.887631564671288],
              [-36.881946942706975, -8.888010924092105],
              [-36.88208166557429, -8.888213265066128],
              [-36.88233061911518, -8.888451146598422],
              [-36.88257739306227, -8.888625469652942],
              [-36.882837736047996, -8.888858624875233],
              [-36.883074053627972, -8.889096976687734],
              [-36.883279292295256, -8.889397522820882],
              [-36.88343063901651, -8.889775862083669],
              [-36.883722274108806, -8.89040068531844],
              [-36.883907271970735, -8.890395679591697],
              [-36.883992050850246, -8.890384229177492],
              [-36.885336922809927, -8.890510097189125],
              [-36.885272495940526, -8.891314950125258],
              [-36.885255945908078, -8.891601310787323],
              [-36.885256413003525, -8.892311800357804],
              [-36.885289675882312, -8.892423443408825],
              [-36.885281785231854, -8.892467962239483],
              [-36.885149342761778, -8.892469560653861],
              [-36.88498777273719, -8.892525862045742],
              [-36.884703687333229, -8.892627839209858],
              [-36.884445113734934, -8.892719113870925],
              [-36.884257185407655, -8.892743537384195],
              [-36.884200217597481, -8.892866147889686],
              [-36.884316165152491, -8.893790077210445],
              [-36.884474550186795, -8.894605053264971],
              [-36.884496147561556, -8.894911968699461],
              [-36.884484177787805, -8.8952710308367],
              [-36.884508531572081, -8.896079189338591],
              [-36.884215364555324, -8.896922876041092],
              [-36.883882938973713, -8.897717346249939],
              [-36.883661400190412, -8.898038156048283],
              [-36.882998372244955, -8.898565804802489],
              [-36.882630291112676, -8.898826302059442],
              [-36.881555562842969, -8.899469658348982],
              [-36.880834063715199, -8.899984946976156],
              [-36.880801584544095, -8.900053189164993],
              [-36.880544497345532, -8.900358879001363],
              [-36.879255503342414, -8.901935754019824],
              [-36.878778570106505, -8.902377223940334],
              [-36.878698018573814, -8.903247514073506],
              [-36.878671646838235, -8.90400638805486],
              [-36.877991068227324, -8.905225695677442],
              [-36.87740933700843, -8.906452615589149],
              [-36.876259102562017, -8.908895768685868],
              [-36.875472225013688, -8.910904939418094],
              [-36.875450449877441, -8.910992314555951],
              [-36.875415952123412, -8.911299360200363],
              [-36.875326507198409, -8.91133093826039],
              [-36.875468009960542, -8.911650107880162],
              [-36.875512415920007, -8.912145258431254],
              [-36.875460228703616, -8.912493240972195],
              [-36.875690511106278, -8.914138608723025],
              [-36.875641406437687, -8.914550787244616],
              [-36.87439878293366, -8.917462893139719],
              [-36.875077168609842, -8.917887548588068],
              [-36.878561015738796, -8.919996513104383],
              [-36.88042860408985, -8.91953496518169],
              [-36.880565048921085, -8.919392742998618],
              [-36.880049375264846, -8.917312222984028],
              [-36.879851297522649, -8.91368943339603],
              [-36.879773898224578, -8.913507936048095],
              [-36.879987649651916, -8.913342200558102],
              [-36.880469986628313, -8.912943789801549],
              [-36.881311124262396, -8.91197370249535],
              [-36.883955026798318, -8.909903132460066],
              [-36.886012230889072, -8.908356553522182],
              [-36.887014226729868, -8.908131294064274],
              [-36.887015240561325, -8.908097221750454],
              [-36.887145624656419, -8.908059364751681],
              [-36.88804336245326, -8.907121124017932],
              [-36.889597660813067, -8.905420886892085],
              [-36.889798979669528, -8.905170826873885],
              [-36.8900081056928, -8.905366267403203],
              [-36.891727453921789, -8.905316536045449],
              [-36.891752006388032, -8.903791834866693],
              [-36.891347815970107, -8.903024909944422],
              [-36.891313144263471, -8.902873214760763],
              [-36.891271151789546, -8.90228587408996],
              [-36.891581751292321, -8.902142462638075],
              [-36.89201291918134, -8.902008140345961],
              [-36.893030245794598, -8.901912118442452],
              [-36.893405645318829, -8.898085008500138],
              [-36.893795399522865, -8.898150217520191],
              [-36.893884937746392, -8.898165186924141],
              [-36.897674199184792, -8.894667676962163],
              [-36.898686029262052, -8.893779123984318],
              [-36.899484752603549, -8.893153224307804],
              [-36.902118988700508, -8.891382521851513],
              [-36.90167591634787, -8.890608969068175],
              [-36.897219771666016, -8.8882234968587],
              [-36.896863500738881, -8.888010418180315],
              [-36.895408692425768, -8.889690663532708],
              [-36.895132741362751, -8.890151466761617],
              [-36.894915977544706, -8.890609530613196],
              [-36.894685032924698, -8.891035876440419],
              [-36.894544294736299, -8.891487137916393],
              [-36.894267607365748, -8.891153740732712],
              [-36.894174329351145, -8.890998189177393],
              [-36.894090132360198, -8.890796407601247],
              [-36.894090223768373, -8.890796317724609],
              [-36.89399674735413, -8.890818658915165],
              [-36.89386283439611, -8.890886691284468],
              [-36.893672083583702, -8.890975644712762],
              [-36.893448090013074, -8.891092070406764],
              [-36.893196178557353, -8.891226687897433],
              [-36.892884686790367, -8.891384922434904],
              [-36.89251321536603, -8.891573008705949],
              [-36.8921177884164, -8.891769817518265],
              [-36.891727711322204, -8.891968825519445],
              [-36.891310559340546, -8.892181600308756],
              [-36.8909069467406, -8.892394993524372],
              [-36.890497985615397, -8.892605824903869],
              [-36.890107250621753, -8.892808352399348],
              [-36.889749660572797, -8.893032219095742],
              [-36.889469472927267, -8.893358215963358],
              [-36.889276264581731, -8.893606063487256],
              [-36.88909754642436, -8.893736210955893],
              [-36.889039112136921, -8.89373425274564],
              [-36.889034370488723, -8.893689300583336],
              [-36.888835697684719, -8.892257525717454],
              [-36.888799557233703, -8.891981622472898],
              [-36.888486466174164, -8.891514505472694],
              [-36.888382684838128, -8.890575096500022],
              [-36.888253644495208, -8.890268566021808],
              [-36.888108814943344, -8.890306433424467],
              [-36.888088836190107, -8.888796306704055],
              [-36.888642395668469, -8.88874583997891],
              [-36.888619294745673, -8.887859587115218],
              [-36.888521532164482, -8.88553557315166],
              [-36.887970656628383, -8.885419823427721],
              [-36.886679271969889, -8.885083024025263],
              [-36.886137255403526, -8.88491263442674],
              [-36.885882330132283, -8.884844208035023],
              [-36.885029823140144, -8.884721772453906],
              [-36.884589871121428, -8.884711502960565],
              [-36.883262262472044, -8.884796349982874],
              [-36.882526900504843, -8.884708651899679],
              [-36.882229728653598, -8.884653090204701],
              [-36.88151189669842, -8.884441109635592],
              [-36.880617115472148, -8.884147130501084],
              [-36.880731686862568, -8.883980736289043],
              [-36.881439904453579, -8.88341356997495],
              [-36.882665981626857, -8.882394305696135],
              [-36.88323578402315, -8.881982276135298],
              [-36.883795774571077, -8.881474192689264],
              [-36.884159430293053, -8.880935707209455],
              [-36.884965898614688, -8.87968535788686],
              [-36.885637276803685, -8.878819223019423],
              [-36.886316091038282, -8.877542835133903],
              [-36.887265014310536, -8.875712780728252],
              [-36.887515834395806, -8.875274619593005],
              [-36.88762520575581, -8.874395624860783],
              [-36.887635619775885, -8.874307008312879],
              [-36.887780371460202, -8.873201146443758],
              [-36.888201272738847, -8.872439977987304],
              [-36.888380009501731, -8.871859400354477],
              [-36.888521338623136, -8.871510378641993],
              [-36.889071360974249, -8.8717561032998],
              [-36.891245734497787, -8.872707664568557],
              [-36.892097418985649, -8.872906728924148],
              [-36.892489069233427, -8.872651048065025],
              [-36.893641633057896, -8.870517762148975],
              [-36.89396610771341, -8.869991875962048],
              [-36.894028828250526, -8.869910966048508],
              [-36.893711587327417, -8.869630494489559],
              [-36.89338306583609, -8.869033130673598],
              [-36.891861488997755, -8.865464861225698],
              [-36.891836773958154, -8.86541590944808],
              [-36.891828908415576, -8.86537672480863],
              [-36.891340316159287, -8.864735520775049],
              [-36.890281201556022, -8.863588419901149],
              [-36.889926417587304, -8.863390083126813],
              [-36.889894322402618, -8.863392161749507],
              [-36.888999834917399, -8.863864747437697],
              [-36.888836847295671, -8.863964795792688],
              [-36.888762214479797, -8.864014994285389],
              [-36.888624551065639, -8.864120880344247],
              [-36.887925651965183, -8.864704666376063],
              [-36.887840770058858, -8.86476700974651],
              [-36.887585240163006, -8.864933786675609],
              [-36.887467113473178, -8.864960779131165],
              [-36.886948020382562, -8.864934703545515],
              [-36.88652527416599, -8.864923544666455],
              [-36.886134355866204, -8.864909401611778],
              [-36.885518204251042, -8.864865871544325],
              [-36.885365701355397, -8.865579564093951],
              [-36.885357597802866, -8.865677413693149],
              [-36.885266934492272, -8.868004606688501],
              [-36.884317260311406, -8.868334586461122],
              [-36.882963505896797, -8.868678453715388],
              [-36.885407997299922, -8.873449494522152],
              [-36.885649025461909, -8.873864226102233],
              [-36.885690745172226, -8.873943375337163],
              [-36.885653509756139, -8.873954101978642],
              [-36.883773197152266, -8.874442596194525],
              [-36.883609697236949, -8.874488221505667],
              [-36.883100908128242, -8.874612790219924],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0383000',
        uf: 'PE',
        nome_proje: 'PA RIACHO SECO',
        municipio: 'SERTANIA',
        area_hecta: '2649.0560',
        capacidade: 76.0,
        num_famili: 66.0,
        fase: 3.0,
        data_de_cr: '29/06/2009',
        forma_obte: 'Compra e Venda',
        data_obten: '12/12/2008',
        area_calc_: 2648.5879,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.241730835066271, -8.337850445522964],
              [-37.238892937954823, -8.365087088269496],
              [-37.239681784092539, -8.365510387955879],
              [-37.240764377860053, -8.367297785911639],
              [-37.239699913241374, -8.377317376520564],
              [-37.23821248174761, -8.389583439335347],
              [-37.239434689468901, -8.388924909135916],
              [-37.240611653174305, -8.388292392082763],
              [-37.241894612743394, -8.387609350546215],
              [-37.243193921930548, -8.386923845182203],
              [-37.244580473720767, -8.386178507944555],
              [-37.246229383480404, -8.385296722870542],
              [-37.246428421164381, -8.38519019721892],
              [-37.248314036708798, -8.384182061879063],
              [-37.251062585263504, -8.382712385188162],
              [-37.251565081144875, -8.382394187892094],
              [-37.251932367119529, -8.382080905983944],
              [-37.252520461906776, -8.381379824458973],
              [-37.254365127313307, -8.378838965048283],
              [-37.255401588730891, -8.377412212474027],
              [-37.25545457162589, -8.377339302142362],
              [-37.256196351072397, -8.376334197924963],
              [-37.256912286802162, -8.375300768404017],
              [-37.25764708761583, -8.374291108358561],
              [-37.258421635576973, -8.373243828521236],
              [-37.259254486762693, -8.372293907459765],
              [-37.260188375790847, -8.371345242493881],
              [-37.261129936830656, -8.370446606927597],
              [-37.262064622119922, -8.369521085079697],
              [-37.262993304923441, -8.368617413856194],
              [-37.263934053829843, -8.367673739211211],
              [-37.264854825595052, -8.366770931185775],
              [-37.265806964187981, -8.365836432018533],
              [-37.266861047268129, -8.364800389515302],
              [-37.267875965432999, -8.363812271753989],
              [-37.2681264452101, -8.363565996025482],
              [-37.268933086419224, -8.362784824093035],
              [-37.270059311454951, -8.361773225961743],
              [-37.271366140119248, -8.360877329245193],
              [-37.272008612812293, -8.360449945460887],
              [-37.27197686462916, -8.360052164806758],
              [-37.272597633794462, -8.359604974523414],
              [-37.27239138009282, -8.357684307098193],
              [-37.272310993617168, -8.355878653784281],
              [-37.272263359313193, -8.3540363447808],
              [-37.272229774961126, -8.353230335458232],
              [-37.272081761108346, -8.353238188399748],
              [-37.272071747019609, -8.353100082049345],
              [-37.272218057821092, -8.353087248881558],
              [-37.272214614487808, -8.35221916603855],
              [-37.272122630695705, -8.35044881401951],
              [-37.272041097989522, -8.346755937384954],
              [-37.264703772294098, -8.344587385122644],
              [-37.241730835066271, -8.337850445522964],
            ],
          ],
          [
            [
              [-37.268853896241701, -8.363777495648026],
              [-37.268396987616406, -8.364204057741448],
              [-37.267775432996032, -8.364826367511668],
              [-37.267099459962573, -8.365497622583122],
              [-37.266462570818668, -8.366117059371243],
              [-37.26575718986647, -8.366806174611385],
              [-37.265090304234747, -8.367453414580364],
              [-37.264420806031552, -8.368095578312001],
              [-37.263703177293642, -8.368801632402608],
              [-37.263063964744077, -8.369451249474738],
              [-37.26239175967418, -8.3701085859758],
              [-37.261692477613657, -8.37079084633578],
              [-37.260912560369981, -8.371557738873975],
              [-37.260183208279692, -8.372263551476054],
              [-37.259415409253961, -8.3730398058074],
              [-37.258749683103858, -8.373911623159808],
              [-37.258111841639263, -8.374779945217229],
              [-37.25741448823058, -8.375718887218914],
              [-37.256573127622929, -8.376843895247912],
              [-37.255781432602454, -8.377966949983177],
              [-37.255731160803748, -8.378042675148256],
              [-37.25380477517578, -8.380669707558598],
              [-37.252782006021924, -8.382096694279856],
              [-37.252385413878727, -8.382527746214462],
              [-37.251944214252752, -8.38290417114302],
              [-37.251403568934371, -8.383246430500934],
              [-37.246932911202293, -8.38566412450364],
              [-37.246887572458874, -8.385691951005271],
              [-37.246337384540276, -8.38595369173018],
              [-37.246324086253502, -8.386023973894524],
              [-37.246299090508607, -8.386273221733292],
              [-37.2461926399642, -8.387153370662022],
              [-37.243831569602897, -8.407919094229303],
              [-37.250917772621882, -8.408972423548281],
              [-37.250748464839859, -8.410383109306499],
              [-37.25285547309997, -8.410795356632145],
              [-37.254857404283626, -8.391106801580801],
              [-37.255153649701327, -8.388241195502909],
              [-37.255242968534816, -8.387452281919996],
              [-37.256650560481411, -8.387931370005486],
              [-37.256509281500406, -8.389358011880212],
              [-37.256287349649291, -8.391604707979763],
              [-37.256175423042862, -8.392759786558958],
              [-37.257992208673741, -8.392594318356924],
              [-37.258102608490411, -8.392758364369151],
              [-37.25882481495313, -8.393769124364756],
              [-37.259926502428108, -8.394218283378299],
              [-37.261793303342131, -8.394914661724451],
              [-37.261696474304962, -8.39604648465799],
              [-37.261443894754265, -8.398818175240566],
              [-37.26026069099315, -8.412250120141243],
              [-37.266442283948791, -8.413360851723704],
              [-37.266527149517636, -8.413388259657131],
              [-37.274507736341583, -8.414944314678243],
              [-37.276463419787426, -8.394005802096357],
              [-37.275369004067414, -8.394268458811299],
              [-37.275463549404456, -8.393151171170716],
              [-37.275887192474961, -8.388643972281109],
              [-37.27637234192408, -8.387851984244787],
              [-37.277041087690066, -8.387509885688372],
              [-37.277104519874996, -8.386878347067627],
              [-37.277204007889722, -8.385811349293956],
              [-37.277311723637432, -8.384648819234341],
              [-37.2773918728154, -8.3838493633165],
              [-37.278107774858292, -8.375737518891626],
              [-37.278214100641797, -8.374537458889952],
              [-37.278323074054406, -8.373230449843881],
              [-37.278432614225636, -8.371938723068595],
              [-37.278612563390624, -8.369912503912133],
              [-37.278805356856623, -8.369839475105605],
              [-37.280839651563952, -8.369242824564362],
              [-37.281024957504577, -8.367150712362779],
              [-37.279736827375281, -8.366514737494224],
              [-37.278974133996378, -8.366158891105311],
              [-37.277593150876079, -8.36549872725975],
              [-37.274930598309943, -8.364965969198913],
              [-37.272207015852722, -8.364440341876339],
              [-37.269399143219388, -8.363885034105682],
              [-37.268853896241701, -8.363777495648026],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0196000',
        uf: 'PE',
        nome_proje: 'PA CAPIM',
        municipio: 'SERTANIA',
        area_hecta: '621.0000',
        capacidade: 18.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '26/11/1999',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/1999',
        area_calc_: 670.1204,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.449370285010126, -7.97298480709274],
              [-37.447326816591477, -7.974217136144563],
              [-37.44714495843224, -7.974190502775664],
              [-37.444228863929276, -7.974633578165156],
              [-37.442956805639753, -7.975084262378293],
              [-37.442371341878449, -7.97552613160543],
              [-37.442378524635977, -7.976432384554265],
              [-37.442934471515315, -7.977606818324444],
              [-37.443079373118337, -7.978003418944897],
              [-37.443291776430357, -7.978396113887813],
              [-37.44354048767088, -7.978734419808075],
              [-37.444186476975155, -7.981021584135926],
              [-37.444552410998838, -7.981305262450666],
              [-37.444677489022311, -7.981715442461303],
              [-37.444847807592744, -7.98191591899197],
              [-37.445076338346531, -7.982530302862293],
              [-37.445455658122846, -7.982920821489796],
              [-37.44570968099363, -7.98358277351578],
              [-37.445866840870089, -7.984696932640176],
              [-37.445568049251854, -7.985367206446452],
              [-37.445005397948016, -7.985865406255738],
              [-37.441145858439377, -7.989842084468217],
              [-37.439786003196758, -7.990992747983864],
              [-37.439287278534479, -7.992066452500596],
              [-37.438518854536092, -7.993071861894019],
              [-37.436771510794948, -7.99468645644029],
              [-37.436856506162556, -7.995758837279994],
              [-37.437044468512475, -7.996471451514193],
              [-37.437811925007701, -7.996795809618117],
              [-37.438240020004628, -7.997027375080406],
              [-37.43850441349349, -7.997250273690605],
              [-37.438784405696836, -7.997569622513667],
              [-37.439165140745381, -7.998447716675114],
              [-37.439220108117212, -7.998448828642663],
              [-37.439817586849088, -8.004422831774512],
              [-37.439893663472752, -8.004431257480595],
              [-37.44114001757665, -8.004730928580706],
              [-37.44146822088463, -8.004682887386537],
              [-37.443371680639913, -8.004081882477076],
              [-37.446074402746298, -8.004178060342342],
              [-37.448158705999148, -8.003322446062144],
              [-37.449112881608073, -8.002685741242741],
              [-37.449883988793204, -8.002578682240607],
              [-37.451966834424134, -8.003451765245547],
              [-37.453295699285619, -8.003572761069005],
              [-37.456570895415787, -8.004545597058673],
              [-37.456942873894675, -8.004652874723677],
              [-37.45715453028744, -8.00476814304011],
              [-37.457445905736073, -8.005009037155341],
              [-37.457747655513408, -8.00386647993354],
              [-37.458203416067647, -8.000170646370588],
              [-37.459024212819756, -7.993665152668476],
              [-37.459464671713498, -7.989854235003674],
              [-37.460345830770393, -7.982955516372523],
              [-37.460698829092607, -7.980083232840909],
              [-37.461487207416283, -7.973428573547119],
              [-37.449370285010126, -7.97298480709274],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0321000',
        uf: 'PE',
        nome_proje: 'PA MALHADA DOS CAVALOS',
        municipio: 'TACAIMBO',
        area_hecta: '519.9284',
        capacidade: 25.0,
        num_famili: 24.0,
        fase: 4.0,
        data_de_cr: '04/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/2004',
        area_calc_: 511.7166,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.250379016791364, -8.287849737434328],
              [-36.248056926562342, -8.292032989905239],
              [-36.249595173430649, -8.295858322876105],
              [-36.249798518401363, -8.296563324752023],
              [-36.24925537107633, -8.300380527274445],
              [-36.248807528426077, -8.303317696835018],
              [-36.248445846868755, -8.305881737962693],
              [-36.248033876328236, -8.308914981081683],
              [-36.247818293778849, -8.310724801614061],
              [-36.248112414760826, -8.311147081896149],
              [-36.248447355611184, -8.311637507051044],
              [-36.253144993238671, -8.313513628442925],
              [-36.253041644372672, -8.313819316451452],
              [-36.25323501459475, -8.314268504975063],
              [-36.25319352445797, -8.314293061618786],
              [-36.253654110036756, -8.314523312499437],
              [-36.254116707272303, -8.314688687449459],
              [-36.254455419979109, -8.314850122904959],
              [-36.254686676687363, -8.315039051002625],
              [-36.25500124951305, -8.315410453132529],
              [-36.255330658307344, -8.315749082020934],
              [-36.255744053771316, -8.31620086660234],
              [-36.256321902175365, -8.316074635141229],
              [-36.256878209088278, -8.315800127812906],
              [-36.257217173252194, -8.315574235522828],
              [-36.258053184273678, -8.314798155109147],
              [-36.261875080658868, -8.313663245423863],
              [-36.262289901046408, -8.313167958766028],
              [-36.262209602414117, -8.312922369002978],
              [-36.26631472302337, -8.313405420419588],
              [-36.266652346366619, -8.311627616424948],
              [-36.266961973869506, -8.309916115616744],
              [-36.267574690990791, -8.306742643257905],
              [-36.267984985150669, -8.304547171360777],
              [-36.268359740041127, -8.302710696376424],
              [-36.268387903235755, -8.302529814906023],
              [-36.268613883668472, -8.301566632070465],
              [-36.268809177405011, -8.300690240950033],
              [-36.269265488497027, -8.298275496133494],
              [-36.269521639173959, -8.296759044544665],
              [-36.268856133856382, -8.29653812413177],
              [-36.268190632124892, -8.296317203680319],
              [-36.267087378148879, -8.296057071217545],
              [-36.265457853862387, -8.295771409667108],
              [-36.264222842825994, -8.29540175574512],
              [-36.262987837105854, -8.295032099059725],
              [-36.262919112413087, -8.295005530353516],
              [-36.262237151914938, -8.294617152035968],
              [-36.261927907980549, -8.294341529696935],
              [-36.261279758616965, -8.293872007959017],
              [-36.260469190759849, -8.293176011474035],
              [-36.259853449160005, -8.292824205986022],
              [-36.259637533813155, -8.292604755192741],
              [-36.25925946003634, -8.292373470345238],
              [-36.254979744376591, -8.289444989941448],
              [-36.253460249791523, -8.288405227598336],
              [-36.25250442336727, -8.287981969128527],
              [-36.251722580221937, -8.287859000256219],
              [-36.251050798312406, -8.28785436940947],
              [-36.250379016791364, -8.287849737434328],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0401000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DE FATIMA JATOBA E JATOBA',
        municipio: 'SERTANIA',
        area_hecta: '976.6654',
        capacidade: 23.0,
        num_famili: 21.0,
        fase: 3.0,
        data_de_cr: '05/08/2013',
        forma_obte: 'Desapropriação',
        data_obten: '07/10/2009',
        area_calc_: 977.6479,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.52732135693708, -8.2931032316986],
              [-37.525864272894218, -8.29798228243428],
              [-37.524730048143851, -8.301795403699508],
              [-37.523586510827748, -8.30563940831278],
              [-37.522433566077183, -8.309515199982981],
              [-37.521270846640377, -8.313423681400295],
              [-37.519931558659671, -8.317925682689921],
              [-37.518747352318265, -8.321906045198499],
              [-37.517552632365962, -8.325921806786468],
              [-37.515915209219727, -8.331425569424812],
              [-37.514980932535579, -8.33456552377058],
              [-37.513751445727827, -8.338697866889222],
              [-37.51230940132276, -8.343544303145615],
              [-37.511061909989401, -8.347736872327422],
              [-37.50979369305584, -8.351998710028942],
              [-37.507004221974285, -8.361183039022139],
              [-37.505604477494657, -8.365786696425074],
              [-37.50557144961558, -8.36589987361881],
              [-37.496649461439077, -8.394953973377159],
              [-37.488050005460124, -8.422951881827125],
              [-37.48909290193415, -8.423296340448404],
              [-37.489606293830761, -8.42349724738593],
              [-37.491190245087722, -8.423982581975563],
              [-37.491934254843095, -8.424148202680133],
              [-37.492693956818606, -8.424060695545581],
              [-37.49402547551442, -8.423767405154994],
              [-37.494406943986867, -8.423657555966711],
              [-37.496667891109801, -8.416051519898733],
              [-37.497813411248124, -8.412197593756929],
              [-37.498965802471311, -8.408320446882302],
              [-37.500125155772281, -8.404419898633886],
              [-37.501291654674468, -8.400495316598542],
              [-37.502237804253056, -8.397311729979132],
              [-37.511358927358074, -8.366618702319009],
              [-37.51340095737401, -8.359559406983438],
              [-37.514744506258992, -8.354897618238082],
              [-37.517697855192495, -8.345409615926426],
              [-37.520168308750449, -8.337472409809896],
              [-37.532999411716496, -8.296241056224995],
              [-37.532874747725316, -8.296295033814701],
              [-37.532651037344365, -8.296276125109102],
              [-37.532556532074111, -8.296373979783166],
              [-37.532531127247658, -8.296419008805781],
              [-37.532490302053773, -8.296436129853021],
              [-37.532452417115699, -8.296443766995523],
              [-37.532401947042963, -8.296442315198362],
              [-37.532310110772627, -8.296408067302048],
              [-37.53209415204153, -8.296208515776938],
              [-37.532056643965376, -8.29615131886802],
              [-37.532019135899247, -8.296094121957321],
              [-37.531947417781069, -8.296020612803764],
              [-37.531817685067999, -8.295948246345516],
              [-37.531777510209395, -8.295912470545126],
              [-37.531728212191098, -8.295888778211582],
              [-37.531671763366511, -8.295883868125722],
              [-37.531606350778411, -8.295897010196972],
              [-37.531481535016944, -8.295991859296667],
              [-37.531443437781689, -8.296032229749583],
              [-37.531230319695759, -8.295947981370899],
              [-37.531153741637958, -8.295887565952851],
              [-37.531123752551331, -8.295846944632892],
              [-37.531084190745815, -8.295817229570023],
              [-37.53100204198433, -8.295814575756777],
              [-37.530970598242376, -8.295823412038658],
              [-37.530927179616405, -8.29580552854199],
              [-37.530894517581309, -8.295775748466818],
              [-37.530868464729338, -8.295726370328207],
              [-37.530863873655534, -8.295667034014865],
              [-37.530848895509038, -8.295617877545455],
              [-37.530855533150465, -8.295566178393074],
              [-37.530952198212006, -8.295400150983401],
              [-37.530927529504098, -8.295320304421173],
              [-37.530878066182403, -8.295292270950402],
              [-37.530824309931553, -8.295271274873182],
              [-37.530776745984774, -8.295245147336232],
              [-37.530730664111921, -8.295211067778094],
              [-37.530696614464254, -8.295163830383952],
              [-37.530655863289077, -8.295063307433475],
              [-37.530621018306533, -8.294961359437522],
              [-37.530579914346141, -8.294931367382922],
              [-37.530513340833913, -8.294914935749937],
              [-37.53045829154339, -8.294899902977361],
              [-37.530405558833927, -8.29487221911354],
              [-37.530371034177229, -8.294830676782237],
              [-37.530297217818386, -8.294735638322866],
              [-37.530263356879075, -8.294686502658612],
              [-37.530176503715865, -8.294606245966801],
              [-37.530124674850242, -8.294579650518374],
              [-37.530028643790303, -8.294526216448624],
              [-37.5299285350304, -8.294495192939729],
              [-37.5298730917862, -8.294488477827016],
              [-37.529771229638463, -8.294465134014967],
              [-37.529721809908601, -8.294449850639213],
              [-37.529679371248704, -8.294412619520399],
              [-37.529645403163997, -8.29436791429274],
              [-37.529612848392922, -8.29429712641547],
              [-37.529580141985946, -8.294218290076577],
              [-37.529544598310984, -8.294109014971108],
              [-37.529530367117687, -8.294054345264271],
              [-37.529483456682634, -8.29399901246984],
              [-37.52944899238733, -8.293965699061907],
              [-37.529398696859921, -8.293941821972133],
              [-37.52934066106338, -8.293924336560876],
              [-37.529286322762111, -8.293913465868465],
              [-37.529235163153629, -8.29390224519188],
              [-37.529181929029399, -8.293887399822335],
              [-37.529137872557328, -8.29387023723274],
              [-37.52910008302095, -8.293852283926951],
              [-37.529035264557315, -8.293827810662767],
              [-37.528997203696143, -8.293809585070107],
              [-37.528524577556361, -8.293812995294145],
              [-37.528295676733343, -8.29384768776397],
              [-37.527995784821975, -8.293711394089524],
              [-37.527810326322992, -8.293611150536879],
              [-37.52759604916843, -8.29354624739708],
              [-37.52732135693708, -8.2931032316986],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0381000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA CACHOEIRA',
        municipio: 'PESQUEIRA',
        area_hecta: '457.9689',
        capacidade: 32.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '16/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '18/05/2006',
        area_calc_: 466.8311,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.645075704624809, -8.366531926508985],
              [-36.644288190954278, -8.369105157911672],
              [-36.640158147710473, -8.369464174549234],
              [-36.63976486847374, -8.371790563255187],
              [-36.639652216210926, -8.372517631883369],
              [-36.637543194790702, -8.372406145187002],
              [-36.637585230589913, -8.374118557761776],
              [-36.642474905972769, -8.37332866534055],
              [-36.642654451916492, -8.374665198628028],
              [-36.638614653720154, -8.375385338444367],
              [-36.637940766716063, -8.37611157859161],
              [-36.637628865391697, -8.376197380878988],
              [-36.637671425190554, -8.379175569986591],
              [-36.637033872658932, -8.382103953210121],
              [-36.637256578773346, -8.383323068240397],
              [-36.636704378007884, -8.385055598814207],
              [-36.63598282602743, -8.38539028921479],
              [-36.634143111003787, -8.385083894354446],
              [-36.634521307715559, -8.387302729844965],
              [-36.6332838026321, -8.390857803201813],
              [-36.630484108499807, -8.390387363863935],
              [-36.629625747998418, -8.393882240686937],
              [-36.628590993555996, -8.393688746823807],
              [-36.628302220833717, -8.397884591947285],
              [-36.627669426253547, -8.398573899644406],
              [-36.626606449112749, -8.400587652340894],
              [-36.626425916567165, -8.401066749116977],
              [-36.626221716891074, -8.401068641832103],
              [-36.625960636901254, -8.401132812115659],
              [-36.628107259422094, -8.402964277877492],
              [-36.62965216651164, -8.403807066306213],
              [-36.63284731124233, -8.405339347408381],
              [-36.636344105089741, -8.40703420781225],
              [-36.636236967490134, -8.406111779237285],
              [-36.636089055106744, -8.405807123564868],
              [-36.636802013206498, -8.405684513590829],
              [-36.637784501194226, -8.403139257378578],
              [-36.639703882765666, -8.39723494116077],
              [-36.640709196341206, -8.396055719152884],
              [-36.649980509040631, -8.372646831995766],
              [-36.65029152170635, -8.37186146476086],
              [-36.65046800102796, -8.371414315589487],
              [-36.648266371691349, -8.370324235127679],
              [-36.649444911785068, -8.36666422603577],
              [-36.645075704624809, -8.366531926508985],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0302000',
        uf: 'PE',
        nome_proje: 'PA NOSSA SENHORA DE FATIMA',
        municipio: 'PESQUEIRA',
        area_hecta: '702.3272',
        capacidade: 43.0,
        num_famili: 38.0,
        fase: 4.0,
        data_de_cr: '11/09/2003',
        forma_obte: 'Desapropriação',
        data_obten: '08/11/2002',
        area_calc_: 710.6668,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.612633550293552, -8.429633540429617],
              [-36.610791048857791, -8.429637959234922],
              [-36.608963220516905, -8.429795468909665],
              [-36.608805124463792, -8.43293649952226],
              [-36.608768999032229, -8.433571094868194],
              [-36.60868350901589, -8.434247776421357],
              [-36.608493985733276, -8.435177333743898],
              [-36.608472560542239, -8.435266496568604],
              [-36.60815211727941, -8.437071198530086],
              [-36.607896008401191, -8.438391865886343],
              [-36.607671786399223, -8.439707666033286],
              [-36.607452908486877, -8.441024853867853],
              [-36.607255258764383, -8.44234352643365],
              [-36.606819441271327, -8.445588956046388],
              [-36.606344695332041, -8.449492185924695],
              [-36.605931368995066, -8.452632727863296],
              [-36.605777106689828, -8.453786360509175],
              [-36.605739917710807, -8.454164183283174],
              [-36.605684816446526, -8.454723284640664],
              [-36.605284637134218, -8.457950755753414],
              [-36.606152742352414, -8.457120618525334],
              [-36.606351712062121, -8.457003712583141],
              [-36.607330788618192, -8.456650632537343],
              [-36.607756133577958, -8.456506189629224],
              [-36.608168355588425, -8.456384802672185],
              [-36.608835050679829, -8.456286842551522],
              [-36.609897038273708, -8.45638407343052],
              [-36.610121379831973, -8.45637731031713],
              [-36.610344877878816, -8.456301221923589],
              [-36.610600326811209, -8.456136938785118],
              [-36.611038889167531, -8.455703452897046],
              [-36.611470182320865, -8.455565729587349],
              [-36.611773886997497, -8.45545126732474],
              [-36.612174864702382, -8.455077199728979],
              [-36.61289611833319, -8.454668663606283],
              [-36.613292151909079, -8.454655173519642],
              [-36.613309252495178, -8.454855917644169],
              [-36.614006809456043, -8.454905362199664],
              [-36.614403236451736, -8.454754227208271],
              [-36.615069109475577, -8.454774917054419],
              [-36.615545242797992, -8.454873440005539],
              [-36.616267090513141, -8.454722298087837],
              [-36.616639426715075, -8.454591710409092],
              [-36.617109546702579, -8.454339435804382],
              [-36.618046864329486, -8.454251331123135],
              [-36.618447828817203, -8.454055843137388],
              [-36.618518283273076, -8.453979540584486],
              [-36.618656579974356, -8.454222777894588],
              [-36.618497652765377, -8.454367952661842],
              [-36.618371955875396, -8.454615367087674],
              [-36.617965187313374, -8.455415723297609],
              [-36.617923657469937, -8.455497172617694],
              [-36.617612413889944, -8.456129553715234],
              [-36.617337744972843, -8.456659487087309],
              [-36.616982395112565, -8.457348988761503],
              [-36.616036563091377, -8.459026974446749],
              [-36.615458879292923, -8.459764464258267],
              [-36.615860593558317, -8.460510638264733],
              [-36.616124962350199, -8.460682161958674],
              [-36.616537698941833, -8.460743515064816],
              [-36.616891225934012, -8.460676078982759],
              [-36.61737117379257, -8.460509815966745],
              [-36.617762519591089, -8.460388834260138],
              [-36.618018016500301, -8.460394187018938],
              [-36.618952860778748, -8.460489537621973],
              [-36.619371835825028, -8.460629012940375],
              [-36.619850553243133, -8.460884717107946],
              [-36.620523490453664, -8.461442383530214],
              [-36.620806939302412, -8.461670778767482],
              [-36.622142006178315, -8.462344542851136],
              [-36.622668803422265, -8.462650426249667],
              [-36.623088714976156, -8.463052274112606],
              [-36.624014926488805, -8.463313678600899],
              [-36.624105275721966, -8.46311674992641],
              [-36.624956178607512, -8.462987709217265],
              [-36.6250292080761, -8.46241750005542],
              [-36.625472442469629, -8.458524766567894],
              [-36.625495944007376, -8.458437331996612],
              [-36.626062642004591, -8.454524556888551],
              [-36.626252195186098, -8.453349965364497],
              [-36.626314140486073, -8.452928451497975],
              [-36.626362261314576, -8.452600305721688],
              [-36.626405735744335, -8.452304577832171],
              [-36.626483024286827, -8.451778678626626],
              [-36.626658147077983, -8.450469333623598],
              [-36.626747660929929, -8.449799534276261],
              [-36.626993080110609, -8.448156663652089],
              [-36.627473980210326, -8.4446236725489],
              [-36.627863477612131, -8.441696659746615],
              [-36.628008106832958, -8.440665856139129],
              [-36.627964849339648, -8.440644445516307],
              [-36.627876162585274, -8.440600526560926],
              [-36.629384296213971, -8.438297478970998],
              [-36.629436131030957, -8.438222235349246],
              [-36.630249400039176, -8.437080961752775],
              [-36.632364212656192, -8.434672201852791],
              [-36.633423575892429, -8.433480163494723],
              [-36.631489006154681, -8.432811424560047],
              [-36.62946251261414, -8.432125221297913],
              [-36.629043585863883, -8.431981335690395],
              [-36.627619448290943, -8.431814741043119],
              [-36.625627354392421, -8.431547817314367],
              [-36.623660070059579, -8.430979801183211],
              [-36.622735775514613, -8.430832305577784],
              [-36.621756995645498, -8.430703909112326],
              [-36.619855031120686, -8.430425484640779],
              [-36.618004467998453, -8.430173121694484],
              [-36.616440049379712, -8.429958369616836],
              [-36.616235925748818, -8.429926852513628],
              [-36.614432977043876, -8.429726640493488],
              [-36.612633550293552, -8.429633540429617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0259000',
        uf: 'PE',
        nome_proje: 'PA ALBUQUERQUE',
        municipio: 'ALIANCA',
        area_hecta: '853.0000',
        capacidade: 84.0,
        num_famili: 81.0,
        fase: 4.0,
        data_de_cr: '25/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/06/2001',
        area_calc_: 862.9327,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.211119664253367, -7.614788742055802],
              [-35.204320448942752, -7.620241708166385],
              [-35.201066498163939, -7.623588154973934],
              [-35.198903666588166, -7.625104283910174],
              [-35.195900501612897, -7.626175871988707],
              [-35.194004556914095, -7.627445935406809],
              [-35.192782566541283, -7.628838313238719],
              [-35.192731143179131, -7.630499459080698],
              [-35.198114712255148, -7.635442191000614],
              [-35.200030445692775, -7.636479549401498],
              [-35.199733925160096, -7.642095174024784],
              [-35.199248888666773, -7.652311758047812],
              [-35.21628172437503, -7.651955250287456],
              [-35.227386528974662, -7.652923595995202],
              [-35.227944799718117, -7.650038682254526],
              [-35.228167207968681, -7.648446108141489],
              [-35.228257971209025, -7.647796269890086],
              [-35.228654146370644, -7.645014969850799],
              [-35.229008862866209, -7.64288686949317],
              [-35.228064562930001, -7.642230534468811],
              [-35.225006056105883, -7.640938982224287],
              [-35.222581394760276, -7.638278145545861],
              [-35.217793694707368, -7.634698389287389],
              [-35.21268868215197, -7.63287740246376],
              [-35.212925895840144, -7.632194812784867],
              [-35.212655827955764, -7.631316138589322],
              [-35.211474752244804, -7.630149887549385],
              [-35.212922850793518, -7.621802636188444],
              [-35.211457596132533, -7.619540559967033],
              [-35.211119664253367, -7.614788742055802],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0007000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA CALDEIRÃO',
        municipio: 'PEDRA',
        area_hecta: '814.6312',
        capacidade: 32.0,
        num_famili: 30.0,
        fase: 7.0,
        data_de_cr: '03/10/1986',
        forma_obte: 'Desapropriação',
        data_obten: '18/12/1985',
        area_calc_: 818.0802,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.983293563551797, -8.543416380878115],
              [-36.98137348091371, -8.544260560768215],
              [-36.980782436073774, -8.544501531565073],
              [-36.979695047443428, -8.545022202611662],
              [-36.9797602863054, -8.545077651514342],
              [-36.980637637347108, -8.546038192655237],
              [-36.981156664271843, -8.546600982363792],
              [-36.981219567042842, -8.546671039583694],
              [-36.981269882049268, -8.546771397106864],
              [-36.981328744383426, -8.546965146369473],
              [-36.981359515181616, -8.547129507870908],
              [-36.981559567055626, -8.548189985250442],
              [-36.981594786195728, -8.548367865943174],
              [-36.981909910315572, -8.549031936743367],
              [-36.982097933603974, -8.549372565942381],
              [-36.982316917713021, -8.549841568371882],
              [-36.982381489034935, -8.550024100786489],
              [-36.982461221149443, -8.550331550090803],
              [-36.982391899211322, -8.55040766591244],
              [-36.981524579913348, -8.550758537020522],
              [-36.980439490957771, -8.551267971572786],
              [-36.979447484396665, -8.551694663431741],
              [-36.977943451989759, -8.552372850260696],
              [-36.978175976457287, -8.553701166973031],
              [-36.978251239114506, -8.553998471182089],
              [-36.978372655137825, -8.554970814031645],
              [-36.978333106528723, -8.555613914149777],
              [-36.977764719913822, -8.556267745148402],
              [-36.977170996308971, -8.556584043877029],
              [-36.976536787571462, -8.556864140467724],
              [-36.975560580867892, -8.557290900631061],
              [-36.975115946492437, -8.557476341282401],
              [-36.974681539094924, -8.55771816678347],
              [-36.974200510581106, -8.558089033783014],
              [-36.973872053295651, -8.558569704579519],
              [-36.97387700857643, -8.559520529715494],
              [-36.973881982558076, -8.559838593128836],
              [-36.973962049125618, -8.560449568572293],
              [-36.9741811443294, -8.560896081956557],
              [-36.97433722982386, -8.561292780082329],
              [-36.974522881295087, -8.562081014638492],
              [-36.974750635864915, -8.562599551623604],
              [-36.974888477954622, -8.563027644643659],
              [-36.974981792031677, -8.563329542443698],
              [-36.975132956988368, -8.563701353161202],
              [-36.975303890276322, -8.564489629912716],
              [-36.975432429989311, -8.566249271494916],
              [-36.975528763827711, -8.567261970238013],
              [-36.975695453956128, -8.568003994782581],
              [-36.975760182948711, -8.568369848527816],
              [-36.975912405248664, -8.569070184893585],
              [-36.976167203782943, -8.570156973140476],
              [-36.976462098344669, -8.571421356423631],
              [-36.978004844939036, -8.570702908480349],
              [-36.978250569149246, -8.572082741349085],
              [-36.978914046460247, -8.575962304775368],
              [-36.979275260559966, -8.577898398899036],
              [-36.979474138708198, -8.579251632170873],
              [-36.979741603900038, -8.580600554202038],
              [-36.979985079199515, -8.58181144193949],
              [-36.980104713965524, -8.58247899326272],
              [-36.980459051901192, -8.584490620830763],
              [-36.980802050343328, -8.586296376111925],
              [-36.982142945505075, -8.585353888396178],
              [-36.984006920671177, -8.584091486273154],
              [-36.986472364914476, -8.585198001374568],
              [-36.988037899560929, -8.583595652531947],
              [-36.989062591954116, -8.582557306792253],
              [-36.988962289117019, -8.580446658197975],
              [-36.988915747837687, -8.579170943058118],
              [-36.988834576606948, -8.577574962882665],
              [-36.986125974241382, -8.576426549580638],
              [-36.984447197660401, -8.575717737194323],
              [-36.984249057924401, -8.575579493036644],
              [-36.983804451053061, -8.575039580420471],
              [-36.983102554892461, -8.574007969298881],
              [-36.982804553070189, -8.573522806215065],
              [-36.981834502311933, -8.571918463368977],
              [-36.982801755204378, -8.571482650376966],
              [-36.983761257171444, -8.571016428013383],
              [-36.984462348345978, -8.570699559027885],
              [-36.984976361108636, -8.570504300803911],
              [-36.985625865459767, -8.570327743332728],
              [-36.98754053881067, -8.569786640487298],
              [-36.988739723727555, -8.569507222773733],
              [-36.989589819440674, -8.570506981699012],
              [-36.989716528021333, -8.570692087171855],
              [-36.989933386993847, -8.571138585242775],
              [-36.990186107576264, -8.571641503462132],
              [-36.990329357439599, -8.571904297180494],
              [-36.990393733240566, -8.572125067519913],
              [-36.99047642619108, -8.572730570182925],
              [-36.991267606876534, -8.57300967003353],
              [-36.992176247593797, -8.573418474582619],
              [-36.993219154952655, -8.573841326845663],
              [-36.994228329975257, -8.574245335501246],
              [-36.994844103366958, -8.574468971021894],
              [-36.996020528990805, -8.574869841761579],
              [-36.997213582457135, -8.575332652812259],
              [-36.997720652999043, -8.575600707274715],
              [-36.998345813234579, -8.57494796551973],
              [-36.999011833189819, -8.574249077076118],
              [-36.99935988917651, -8.573886458455393],
              [-37.000015645793582, -8.573210301535768],
              [-37.000502022072482, -8.572681974270644],
              [-37.000774610776752, -8.572413463853465],
              [-37.001962820872784, -8.571208338588031],
              [-37.002771951426219, -8.570324029831417],
              [-37.003503173823312, -8.569544764689404],
              [-37.003913775839237, -8.569127653092771],
              [-37.004422002792531, -8.569607143620688],
              [-37.005141010506264, -8.570402632984996],
              [-37.005794855990104, -8.571126930249402],
              [-37.006003787265769, -8.571364192143273],
              [-37.006610654561534, -8.571997146971343],
              [-37.007782032601682, -8.573152617709997],
              [-37.008638399775165, -8.574045535067762],
              [-37.0091623409443, -8.574547596737915],
              [-37.009783058529237, -8.575126635484802],
              [-37.011509360991681, -8.573450644712851],
              [-37.012806631986933, -8.57211681673534],
              [-37.013903400996135, -8.57103953702657],
              [-37.014274240940836, -8.57062966209028],
              [-37.014771511426439, -8.570175596892271],
              [-37.01523950233063, -8.569702260623632],
              [-37.015550911525807, -8.569354083743022],
              [-37.016260765903354, -8.568651427802108],
              [-37.016832116517556, -8.568074023733528],
              [-37.016756263247125, -8.567666497523176],
              [-37.016679571584824, -8.567202732670943],
              [-37.016475588672073, -8.566009517085124],
              [-37.016321949403, -8.565131472423312],
              [-37.016148654237774, -8.564121738542811],
              [-37.015899914806127, -8.562625752721601],
              [-37.014871484000388, -8.562665458670203],
              [-37.013715294745005, -8.562720250783535],
              [-37.010930999572032, -8.562755415913681],
              [-37.009257740529925, -8.562727680257945],
              [-37.006485336688371, -8.562650406392324],
              [-37.004038203104464, -8.56260178525072],
              [-37.002520558508301, -8.562635556929871],
              [-37.001307858727301, -8.562694518084164],
              [-36.999585467368632, -8.562773330788335],
              [-36.998551353963656, -8.562820836062558],
              [-36.997195338147122, -8.562845301489658],
              [-36.996481351051081, -8.563039543045203],
              [-36.995870229301133, -8.563225319239233],
              [-36.992760510373166, -8.564129159788182],
              [-36.991026684238868, -8.564664489137611],
              [-36.990961075573736, -8.564248019999651],
              [-36.990795922542652, -8.563314809666965],
              [-36.990697338625175, -8.562722720576341],
              [-36.990497003392235, -8.561386319203974],
              [-36.990286368766711, -8.560294670498466],
              [-36.990211839713652, -8.559855661726004],
              [-36.989985587323673, -8.558314239022318],
              [-36.989244315369241, -8.554106943349927],
              [-36.989022612837068, -8.552861906377238],
              [-36.988928951242443, -8.552193365839853],
              [-36.988729524529759, -8.550794365130052],
              [-36.98861988171609, -8.550049264108567],
              [-36.988481994661917, -8.54919604807642],
              [-36.988195454205581, -8.547758253800485],
              [-36.988005625632638, -8.54682964999356],
              [-36.987803825984493, -8.545798271857617],
              [-36.987809127934561, -8.545586284354723],
              [-36.984768195451494, -8.544758805551011],
              [-36.984694542846931, -8.544841245050144],
              [-36.984518816262828, -8.544714439786667],
              [-36.983293563551797, -8.543416380878115],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0218000',
        uf: 'PE',
        nome_proje: 'PA SANTA TEREZA',
        municipio: 'AGUA PRETA',
        area_hecta: '852.1900',
        capacidade: 100.0,
        num_famili: 98.0,
        fase: 4.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '18/11/1999',
        area_calc_: 859.3107,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.571446093245946, -8.808819879004224],
              [-35.55365018785745, -8.821858006260547],
              [-35.555639344126043, -8.83059349561896],
              [-35.557141277885314, -8.831498931441203],
              [-35.557395028005807, -8.828968410614266],
              [-35.561065962772069, -8.828916072148846],
              [-35.561514404553854, -8.827810643476335],
              [-35.562091337703293, -8.828354678349548],
              [-35.563429616106497, -8.827230247780898],
              [-35.563696259780002, -8.827006308069508],
              [-35.563595430252327, -8.826784789155104],
              [-35.563932017487218, -8.826703961659277],
              [-35.564140308951892, -8.826489457109181],
              [-35.564670614822006, -8.826044308417314],
              [-35.565354582567458, -8.825433463609892],
              [-35.567311157267127, -8.827185179218448],
              [-35.568960405152438, -8.828661654859015],
              [-35.569276159685423, -8.828944321850729],
              [-35.570450233687666, -8.829995441971194],
              [-35.57150400599123, -8.830938582513827],
              [-35.573863522101682, -8.833051109803819],
              [-35.574841982798105, -8.833926979789279],
              [-35.575321935811864, -8.834356618353709],
              [-35.575279074283671, -8.843159663616651],
              [-35.577114429148146, -8.843371954018629],
              [-35.580445496436219, -8.844248497730648],
              [-35.581535683585408, -8.84227204437256],
              [-35.582481468440427, -8.840630480800485],
              [-35.582637482327279, -8.840333554015565],
              [-35.583552748506754, -8.838498731897248],
              [-35.584393369364655, -8.836826266138443],
              [-35.585492998059792, -8.83472350947641],
              [-35.585601570281973, -8.834515827806161],
              [-35.586100233203958, -8.833562395077118],
              [-35.587060750694647, -8.831693465245252],
              [-35.587491000258446, -8.830857615778632],
              [-35.588410371764773, -8.829069664627413],
              [-35.589658508342858, -8.826642382209361],
              [-35.589901370137738, -8.826307264821535],
              [-35.589899375192672, -8.825500514326082],
              [-35.588813916454278, -8.82531760947489],
              [-35.589306843793423, -8.821706899994924],
              [-35.589555338483272, -8.81988662183875],
              [-35.58987954797157, -8.817511623048844],
              [-35.589980965975684, -8.817112420972748],
              [-35.590379303110652, -8.815545212348898],
              [-35.590883832709252, -8.813560335245125],
              [-35.58904185925136, -8.813013321941906],
              [-35.588745045455262, -8.812984011000873],
              [-35.586593447962429, -8.812459268128338],
              [-35.584332563544059, -8.811907972940183],
              [-35.582159679682555, -8.811378287069413],
              [-35.58103685107374, -8.811080118424472],
              [-35.580546322926189, -8.810993755565178],
              [-35.578653088872258, -8.810545984031469],
              [-35.576815364344611, -8.810111466085193],
              [-35.574849400604883, -8.80965540604547],
              [-35.574434679063728, -8.80954863475346],
              [-35.572496758588372, -8.808996220666689],
              [-35.571446093245946, -8.808819879004224],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0048000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOÃO',
        municipio: 'PESQUEIRA',
        area_hecta: '969.3284',
        capacidade: 36.0,
        num_famili: 36.0,
        fase: 7.0,
        data_de_cr: '24/11/1992',
        forma_obte: 'Compra e Venda',
        data_obten: '17/06/1992',
        area_calc_: 967.1859,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.83293396768908, -8.256418934155064],
              [-36.832743465191207, -8.256446126067237],
              [-36.832563625288898, -8.256466319813661],
              [-36.832405072092449, -8.256479572937099],
              [-36.832109136912521, -8.256520305229323],
              [-36.832016907347892, -8.256548028942721],
              [-36.831897294753531, -8.256564138660838],
              [-36.831838833316155, -8.256569113879282],
              [-36.831670796506636, -8.256535570695849],
              [-36.831479943066583, -8.256464860939685],
              [-36.83139497437547, -8.256459990792113],
              [-36.831242854576224, -8.256430061569439],
              [-36.831089743541213, -8.256419530350358],
              [-36.83100205144904, -8.256426993001963],
              [-36.83092135059141, -8.256451251016665],
              [-36.830714584565648, -8.256538328012574],
              [-36.830589573283568, -8.256570283596819],
              [-36.830422777562163, -8.256633763729292],
              [-36.830276306111855, -8.256704409725531],
              [-36.830135146667359, -8.256775084448256],
              [-36.829933673163779, -8.256865717533469],
              [-36.829772367598324, -8.256896594091256],
              [-36.829643069230244, -8.256902948869165],
              [-36.829511064246979, -8.256918549645166],
              [-36.829400394196377, -8.256918390549272],
              [-36.829169668875416, -8.256934337866904],
              [-36.829007903760925, -8.25696829850607],
              [-36.828809526321855, -8.257059388711038],
              [-36.828699387294328, -8.257124057273275],
              [-36.828646218617337, -8.257132588592658],
              [-36.828577325928237, -8.257102227819303],
              [-36.828428656144162, -8.257089073707201],
              [-36.827048803565333, -8.257171546813581],
              [-36.82681688698603, -8.257210842969842],
              [-36.82646908656772, -8.257297167238917],
              [-36.826017148015737, -8.25737056247659],
              [-36.825713927244074, -8.257448292899948],
              [-36.825311857350776, -8.257469039906418],
              [-36.824968519360667, -8.257435423475625],
              [-36.824436339911003, -8.257610689321664],
              [-36.824300109355434, -8.257670363798027],
              [-36.823761561944565, -8.25771506245278],
              [-36.823311979555513, -8.257843149297802],
              [-36.823068690802295, -8.257970593689461],
              [-36.822976199747394, -8.258045939986484],
              [-36.822779824147617, -8.258175403250958],
              [-36.82253675749547, -8.258262277854657],
              [-36.822141692776931, -8.258297171028618],
              [-36.82184502172192, -8.258310550068385],
              [-36.821733111271762, -8.258698007181811],
              [-36.82165721886475, -8.258975414193035],
              [-36.821174209788893, -8.25890222738466],
              [-36.820578747954755, -8.258992473698463],
              [-36.820100954619612, -8.25893695339305],
              [-36.819755870532859, -8.258898912923531],
              [-36.819362016281296, -8.258713317915078],
              [-36.818921088383128, -8.258716207009561],
              [-36.818688114586415, -8.258577350464298],
              [-36.818544825400821, -8.258551874406962],
              [-36.818384275180115, -8.258445163280244],
              [-36.818410304806186, -8.258219521692903],
              [-36.818014158176631, -8.258129165565682],
              [-36.81756655568757, -8.258057931777145],
              [-36.817200404421371, -8.257987141737924],
              [-36.81676305806733, -8.257982992457015],
              [-36.816452676555443, -8.258074787655167],
              [-36.816205830789613, -8.25811434633184],
              [-36.81601426727336, -8.258091139250105],
              [-36.815877198557708, -8.258073411638794],
              [-36.815680372734043, -8.258123491498297],
              [-36.815241936196365, -8.258098675001706],
              [-36.814907119625303, -8.258147872913417],
              [-36.814665369281762, -8.258224334388025],
              [-36.814393810807026, -8.258114518089904],
              [-36.814236714064151, -8.258131051405377],
              [-36.813604720433787, -8.25800676505469],
              [-36.812998721231374, -8.258069222611349],
              [-36.81268652027137, -8.258043640030531],
              [-36.812204704491386, -8.258076285182405],
              [-36.811082128176132, -8.257422786924975],
              [-36.811059344388539, -8.257701362496094],
              [-36.810903504288191, -8.258346988683293],
              [-36.810878871116643, -8.258479148311809],
              [-36.810864375214948, -8.25853904246642],
              [-36.810846381723152, -8.258590979826311],
              [-36.810771119159391, -8.258752849213698],
              [-36.810631592203727, -8.259008736970303],
              [-36.81057089836596, -8.259097483112829],
              [-36.810140431968939, -8.259772474629976],
              [-36.809902763147626, -8.260164528903864],
              [-36.80968257674791, -8.260597248845841],
              [-36.808277598486683, -8.25977815141716],
              [-36.807929253390746, -8.259249623813847],
              [-36.807175438630921, -8.259917636740647],
              [-36.806485301079981, -8.260478308556559],
              [-36.804572140526247, -8.261900117005494],
              [-36.806002580331629, -8.265060015655676],
              [-36.806775177897173, -8.266981705814894],
              [-36.806897635112705, -8.267252257238152],
              [-36.806885690564343, -8.267811354233435],
              [-36.806717897499134, -8.268268487046969],
              [-36.806292257056974, -8.269453834664585],
              [-36.806062230101062, -8.270062887949594],
              [-36.806100541795139, -8.270179516872153],
              [-36.806804598784836, -8.271268191330796],
              [-36.807503649379825, -8.272302156495339],
              [-36.806575846452375, -8.273567086065206],
              [-36.806076937970467, -8.274125272630409],
              [-36.805369433120276, -8.274618811208764],
              [-36.803997111751343, -8.275570839601757],
              [-36.803378618113499, -8.275978430857389],
              [-36.803285845651331, -8.276103158573873],
              [-36.803212228571788, -8.276286200802511],
              [-36.803169633988141, -8.276622874221781],
              [-36.803200433231893, -8.277455611375492],
              [-36.80327136213743, -8.278076899544589],
              [-36.803360722208453, -8.27856775945037],
              [-36.803534198183527, -8.279217834487863],
              [-36.802833475002139, -8.279603803871249],
              [-36.802099121057132, -8.279986940640629],
              [-36.80196560988766, -8.280113207302389],
              [-36.801648555864105, -8.280446604888235],
              [-36.801479188725985, -8.280652049905418],
              [-36.801403994567188, -8.280799804400882],
              [-36.801363257707578, -8.280912455685357],
              [-36.80129324435309, -8.281086577038163],
              [-36.801238627229047, -8.28124163550085],
              [-36.80108450096143, -8.281572401906818],
              [-36.800689813251672, -8.282173485041429],
              [-36.800421970235135, -8.282573298776883],
              [-36.800354132579194, -8.282671703904519],
              [-36.800127663156793, -8.282955327198239],
              [-36.799948476373949, -8.283174828218055],
              [-36.801134448716148, -8.284536049047468],
              [-36.802201054048304, -8.28570963599268],
              [-36.802325925510424, -8.286183052584958],
              [-36.803023554529624, -8.286197478706274],
              [-36.803973471771684, -8.286062477795316],
              [-36.805169629636858, -8.285949719350498],
              [-36.806043251105415, -8.285482953866421],
              [-36.807517084413902, -8.284753737957692],
              [-36.808489731759714, -8.283391161429465],
              [-36.809647414859072, -8.281869961340147],
              [-36.810173703105441, -8.281165516352788],
              [-36.811008705310044, -8.280033247805054],
              [-36.811185166032367, -8.280786528731593],
              [-36.811549502637718, -8.282150275556413],
              [-36.811611213246017, -8.282519274015636],
              [-36.812203107952101, -8.283241318379504],
              [-36.812634113321941, -8.283756983220949],
              [-36.813307968273264, -8.284549151214765],
              [-36.813976386805813, -8.285364219982284],
              [-36.814320531204636, -8.285736530094786],
              [-36.8150574110213, -8.286661336652312],
              [-36.815915732415505, -8.286759525283609],
              [-36.816724384716551, -8.286873315683719],
              [-36.817846256000017, -8.287027624645564],
              [-36.81876469056396, -8.287143775679379],
              [-36.819376381677074, -8.287088910405037],
              [-36.820062307604928, -8.286979326942481],
              [-36.820678598211636, -8.286893174867215],
              [-36.820840964703642, -8.287294706521587],
              [-36.820988536811065, -8.287599471047724],
              [-36.82136323378873, -8.288706711525334],
              [-36.821470334200143, -8.28919590645113],
              [-36.821532728827457, -8.289442316175446],
              [-36.825346831609714, -8.287735355845173],
              [-36.825263589870595, -8.287092828211545],
              [-36.827183367188347, -8.284805756876574],
              [-36.828144188166902, -8.283652077953661],
              [-36.829555692650587, -8.282012233931912],
              [-36.830206990309115, -8.281110871420182],
              [-36.832575405222734, -8.278052705630264],
              [-36.832983344756592, -8.277084278610817],
              [-36.833513162218566, -8.275605023501345],
              [-36.833667222470631, -8.275281292427453],
              [-36.833859294598646, -8.275130634411473],
              [-36.833915864267723, -8.27498629730893],
              [-36.833965477536196, -8.274656707749934],
              [-36.834119806754643, -8.274283587163042],
              [-36.834131770982246, -8.27403846299071],
              [-36.834385825552999, -8.271293369623466],
              [-36.834707787997218, -8.267460284931943],
              [-36.834855126658852, -8.265771217526243],
              [-36.834992598290256, -8.264267311138946],
              [-36.835057897318308, -8.263333652159394],
              [-36.835213220160057, -8.261477933292861],
              [-36.835289914860809, -8.260402337413485],
              [-36.835311316156314, -8.260130804539109],
              [-36.835373941572989, -8.259362060071997],
              [-36.835430348783696, -8.258434526148356],
              [-36.835523709016648, -8.257549526802897],
              [-36.835537120510189, -8.257038053023514],
              [-36.835379920026966, -8.257087755369234],
              [-36.835081096285656, -8.257159112879224],
              [-36.834804797847042, -8.257161541480412],
              [-36.834573822479712, -8.257081036304983],
              [-36.834343981629011, -8.256832398921155],
              [-36.833987049585126, -8.256648897310766],
              [-36.833793791669258, -8.256650599508509],
              [-36.833498258424164, -8.256686580983471],
              [-36.833386734072306, -8.256680685545973],
              [-36.833301923948085, -8.256646711464782],
              [-36.833195023570845, -8.256604680025632],
              [-36.833017853888876, -8.256459959093149],
              [-36.832969328451739, -8.256428827294837],
              [-36.83293396768908, -8.256418934155064],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0043000',
        uf: 'PE',
        nome_proje: 'PA PAU FERRO II',
        municipio: 'PESQUEIRA',
        area_hecta: '362.7355',
        capacidade: 16.0,
        num_famili: 12.0,
        fase: 5.0,
        data_de_cr: '24/11/1992',
        forma_obte: 'Compra e Venda',
        data_obten: '20/02/1984',
        area_calc_: 364.7023,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.844366240508172, -8.249843949700555],
              [-36.844146803858976, -8.249934833857631],
              [-36.843926564208232, -8.250014821236521],
              [-36.843715720926433, -8.249971762394082],
              [-36.843502031327837, -8.249849558453551],
              [-36.843325257883777, -8.249827084428881],
              [-36.843076878293886, -8.249935845453646],
              [-36.842804117106894, -8.249905794749798],
              [-36.842644259517265, -8.249988293979733],
              [-36.842202363029116, -8.250016439725753],
              [-36.841691132393045, -8.250512715721122],
              [-36.841059250914263, -8.250596855984993],
              [-36.840956072895658, -8.250578266627926],
              [-36.840513455603457, -8.250498504992972],
              [-36.840017417304999, -8.250502872339819],
              [-36.839610799402422, -8.250508720708998],
              [-36.838480923337137, -8.25053790505182],
              [-36.838172885304544, -8.250525659133102],
              [-36.837620514980827, -8.25050856629414],
              [-36.837329934313857, -8.250542276805994],
              [-36.837123635324822, -8.250706974818028],
              [-36.837100928623947, -8.250974973381618],
              [-36.83702898871779, -8.251179203714401],
              [-36.836673223735801, -8.25147715480341],
              [-36.83641571311059, -8.251941447869809],
              [-36.836262139608245, -8.25235880513258],
              [-36.836191809816626, -8.252737545500395],
              [-36.836511633149826, -8.253187317664816],
              [-36.836517678783629, -8.253377857374884],
              [-36.83641652353257, -8.253744213324492],
              [-36.836310642439805, -8.254978408949661],
              [-36.836302138093821, -8.255239428153637],
              [-36.836367464498437, -8.25559962776021],
              [-36.836321990103627, -8.25614620767087],
              [-36.836278667420622, -8.256297673542852],
              [-36.836228627019658, -8.256382072854304],
              [-36.835860372624516, -8.256939109048],
              [-36.835537120510189, -8.257038053023514],
              [-36.835523709016648, -8.257549526802897],
              [-36.835430348783696, -8.258434526148356],
              [-36.835373941572989, -8.259362060071997],
              [-36.835311316156314, -8.260130804539109],
              [-36.835289914860809, -8.260402337413485],
              [-36.835213220160057, -8.261477933292861],
              [-36.835057897318308, -8.263333652159394],
              [-36.834992598290256, -8.264267311138946],
              [-36.834855126658852, -8.265771217526243],
              [-36.834707787997218, -8.267460284931943],
              [-36.834385825552999, -8.271293369623466],
              [-36.834131770982246, -8.27403846299071],
              [-36.834119806754643, -8.274283587163042],
              [-36.833965477536196, -8.274656707749934],
              [-36.833915864267723, -8.27498629730893],
              [-36.833859294598646, -8.275130634411473],
              [-36.833667222470631, -8.275281292427453],
              [-36.833513162218566, -8.275605023501345],
              [-36.832983344756592, -8.277084278610817],
              [-36.833563002563878, -8.277258555131416],
              [-36.833507966732512, -8.277446117126562],
              [-36.833367859105408, -8.277808730440666],
              [-36.833272252195641, -8.278129250342575],
              [-36.833175221577221, -8.278386260305407],
              [-36.83305468376377, -8.279055906548633],
              [-36.833017762882584, -8.279330882095785],
              [-36.832984499266956, -8.279584710096039],
              [-36.833038327592639, -8.280103603053739],
              [-36.833074872559955, -8.280544788486429],
              [-36.833059181501852, -8.280823407129136],
              [-36.832977406171302, -8.281528542439828],
              [-36.832784754088735, -8.282668518436161],
              [-36.832538925622707, -8.283675841521205],
              [-36.832455523869072, -8.284127954737725],
              [-36.832244459250155, -8.285387937347206],
              [-36.832149945102238, -8.286106169158074],
              [-36.832054651750923, -8.286728938700508],
              [-36.831660272169906, -8.288281021144671],
              [-36.831553783168644, -8.288803798199769],
              [-36.831500613187025, -8.289023449552621],
              [-36.831438770628793, -8.289244505719704],
              [-36.831373894210891, -8.289733116948108],
              [-36.831331026632917, -8.290485737491014],
              [-36.831306284576137, -8.29079958521667],
              [-36.831303875521087, -8.290915992514577],
              [-36.831361067060918, -8.291173810425258],
              [-36.8313895979405, -8.291269247887714],
              [-36.831487992514241, -8.291733700777622],
              [-36.831641250634505, -8.292527427995399],
              [-36.831555364038415, -8.293980451961188],
              [-36.831514055408249, -8.294731667973956],
              [-36.831511172071615, -8.294934506006939],
              [-36.831489051151166, -8.29509314072164],
              [-36.83153261631616, -8.295399645586855],
              [-36.831672708492547, -8.295833233235854],
              [-36.831901944244201, -8.29665824322328],
              [-36.831879805511335, -8.296812427083974],
              [-36.832110170876796, -8.296793436120733],
              [-36.832413849816071, -8.296799498200153],
              [-36.832584414199694, -8.296859518288434],
              [-36.832782035396903, -8.296991125331452],
              [-36.833140138372954, -8.297245317494498],
              [-36.833511247009106, -8.297388451482879],
              [-36.834514916283105, -8.297792560449766],
              [-36.835478172013637, -8.298139119445343],
              [-36.835907885868885, -8.298252582892001],
              [-36.836622146236557, -8.298470782451968],
              [-36.836642814216113, -8.297930243738307],
              [-36.836674199931288, -8.296725635627526],
              [-36.836651213529159, -8.295748281925652],
              [-36.836645703593284, -8.294813357907271],
              [-36.836670138546665, -8.294231386644176],
              [-36.836561026797881, -8.291434277811391],
              [-36.836640504178042, -8.291061116009704],
              [-36.836722438086198, -8.290497385304189],
              [-36.836777075418702, -8.289573371049217],
              [-36.836814688248161, -8.288524023764158],
              [-36.836864471000858, -8.287839880434404],
              [-36.836912344391919, -8.28750498918518],
              [-36.83706838409082, -8.286358945451514],
              [-36.837149017031564, -8.286036938489747],
              [-36.837173594993104, -8.28571423025228],
              [-36.837244933697868, -8.284973756915306],
              [-36.837267301315016, -8.284769259656555],
              [-36.837251882156224, -8.284352438907364],
              [-36.837169330613676, -8.283597030150606],
              [-36.837098830198066, -8.28319236524462],
              [-36.837014125041208, -8.282304775508445],
              [-36.836997484255065, -8.28164560120287],
              [-36.837000728994283, -8.281375734041625],
              [-36.837009113761994, -8.280965868472673],
              [-36.836889945978974, -8.27984444280389],
              [-36.836829285677219, -8.279593687947088],
              [-36.836787485169687, -8.279424873903794],
              [-36.836772044504471, -8.279092934640994],
              [-36.836797028960383, -8.278955990316998],
              [-36.836827056364349, -8.278873495992478],
              [-36.836893781884193, -8.278690538967039],
              [-36.837670816829267, -8.277368961347905],
              [-36.838236041165736, -8.276076011075782],
              [-36.838370230362038, -8.276130591063708],
              [-36.8404656489489, -8.276701929560231],
              [-36.840991779600394, -8.276829133155417],
              [-36.841390678422584, -8.276907139613954],
              [-36.841618903200448, -8.276946298227399],
              [-36.841713677158815, -8.275802001522607],
              [-36.841896639527938, -8.273378595873261],
              [-36.842041185496207, -8.271916005898618],
              [-36.842087918858674, -8.271141334573921],
              [-36.842203266690333, -8.269722051920231],
              [-36.8423097868627, -8.268943520228694],
              [-36.842460470553043, -8.26789965931823],
              [-36.842576802361727, -8.267356058014435],
              [-36.842819964887546, -8.26645304936828],
              [-36.842799355490946, -8.26465248060765],
              [-36.842764971364794, -8.263118632571336],
              [-36.842768967012212, -8.262421181337245],
              [-36.842845555348795, -8.261706935303659],
              [-36.842838538351792, -8.261183946277894],
              [-36.842839361460697, -8.260276356209902],
              [-36.842859680039545, -8.259863178780313],
              [-36.842877398779116, -8.259777343339819],
              [-36.842997989378944, -8.259356113260893],
              [-36.843040168139623, -8.259077678752783],
              [-36.84305695042525, -8.258861714198268],
              [-36.843126205495572, -8.257606267629919],
              [-36.843128811438312, -8.257201996882225],
              [-36.843121234713436, -8.256857413752302],
              [-36.843152699000754, -8.256055560780158],
              [-36.843200127921946, -8.25579613268587],
              [-36.843315602495778, -8.255074412466898],
              [-36.843368010982033, -8.254702988715307],
              [-36.843379006087822, -8.254569241090566],
              [-36.843406187193828, -8.254404890993696],
              [-36.843621712539189, -8.253484967420144],
              [-36.843745213244055, -8.252864459470826],
              [-36.843904593578777, -8.25210666923061],
              [-36.844048874383446, -8.251449890433914],
              [-36.844262166336215, -8.250648435208673],
              [-36.844356268909614, -8.250274981397988],
              [-36.84435140395447, -8.250080282617871],
              [-36.844366240508172, -8.249843949700555],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0186000',
        uf: 'PE',
        nome_proje: 'PA CANZANZA',
        municipio: 'MORENO',
        area_hecta: '718.7500',
        capacidade: 76.0,
        num_famili: 72.0,
        fase: 5.0,
        data_de_cr: '03/02/1999',
        forma_obte: 'Desapropriação',
        data_obten: '10/02/1998',
        area_calc_: 646.6512,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.092122717593838, -8.162746739591222],
              [-35.087847120331432, -8.162831459236758],
              [-35.086713925783471, -8.162853935353818],
              [-35.085331993044704, -8.162881305654569],
              [-35.08395749708454, -8.162908452194248],
              [-35.083036087529614, -8.167086013608037],
              [-35.082311187925548, -8.17037296738417],
              [-35.081883849583512, -8.172310388580668],
              [-35.081352888670523, -8.174717866357488],
              [-35.080761116662494, -8.177401097967655],
              [-35.080411423793308, -8.17898674705285],
              [-35.080230682709065, -8.179806404807982],
              [-35.079796598580693, -8.181774416939769],
              [-35.07964501902385, -8.18246167368976],
              [-35.079668403547764, -8.183349163206106],
              [-35.079710172568667, -8.184929542702461],
              [-35.079788301874203, -8.187886212212582],
              [-35.079840913265762, -8.189876215141934],
              [-35.079877150647931, -8.19124599703092],
              [-35.079910901840677, -8.192521145798215],
              [-35.079947707392698, -8.193912438993516],
              [-35.080009698920747, -8.19625656849783],
              [-35.083707100285757, -8.196296958631807],
              [-35.084498861673318, -8.19630561200197],
              [-35.084531797444981, -8.196305712914741],
              [-35.089374651415362, -8.196326748369811],
              [-35.093255185045322, -8.196343592616454],
              [-35.09673440120136, -8.19635859951304],
              [-35.096979975248196, -8.194568653311828],
              [-35.09720929320742, -8.192897030076621],
              [-35.097383990519404, -8.191623524552213],
              [-35.097610163690923, -8.189975149870989],
              [-35.097757421815189, -8.188902105156165],
              [-35.097926177139804, -8.187672111471963],
              [-35.098236944106404, -8.18540679595524],
              [-35.098580045664278, -8.182905740497953],
              [-35.098850873189413, -8.180931437700284],
              [-35.098925945440769, -8.180384693459082],
              [-35.099529298327553, -8.179955882843613],
              [-35.092122717593838, -8.162746739591222],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0045000',
        uf: 'PE',
        nome_proje: 'PA TIOGÓ',
        municipio: 'PESQUEIRA',
        area_hecta: '997.7397',
        capacidade: 48.0,
        num_famili: 48.0,
        fase: 7.0,
        data_de_cr: '24/11/1992',
        forma_obte: 'Compra e Venda',
        data_obten: '27/02/1984',
        area_calc_: 997.9955,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.825169129225735, -8.231508770208663],
              [-36.824915510317361, -8.23168729423625],
              [-36.82431577783327, -8.231979529306752],
              [-36.823067929281322, -8.232547809710887],
              [-36.822045653595971, -8.23298677771076],
              [-36.821250979465717, -8.233425293261378],
              [-36.82090050272248, -8.23355384297423],
              [-36.820565972667012, -8.233995161772986],
              [-36.820084036026927, -8.234077708967511],
              [-36.819376551848997, -8.233938042862334],
              [-36.81913344156041, -8.233874102306666],
              [-36.818776812286643, -8.233846587408708],
              [-36.818430990074233, -8.233785616172941],
              [-36.818069456452278, -8.233845388796659],
              [-36.817438912767869, -8.233882531193281],
              [-36.817111376118859, -8.234523703908906],
              [-36.816938446812266, -8.234738845203324],
              [-36.816436571030337, -8.23566641652276],
              [-36.816080585090504, -8.236191186302685],
              [-36.816034638192932, -8.23714345968634],
              [-36.8159796247039, -8.237811690536287],
              [-36.816001055529107, -8.238101092075357],
              [-36.816523484837447, -8.239052931552706],
              [-36.81658538790623, -8.239388415715723],
              [-36.816965011324541, -8.240551149890765],
              [-36.816969307506348, -8.241059185995985],
              [-36.816887318272236, -8.241480319665698],
              [-36.816772200193775, -8.241807784467227],
              [-36.816623294871356, -8.242484323863037],
              [-36.816498558749046, -8.242789686914236],
              [-36.81656214437988, -8.243463855356742],
              [-36.816620380141472, -8.243822250784428],
              [-36.816797159718135, -8.24419716719504],
              [-36.816737731796152, -8.244701328262728],
              [-36.81683704949176, -8.245156081801476],
              [-36.817427688659535, -8.246102120450166],
              [-36.814416387686769, -8.24631325834298],
              [-36.812938802763362, -8.245975652652175],
              [-36.812507795846621, -8.245885097256529],
              [-36.811861042216414, -8.24570781788354],
              [-36.811467268787929, -8.24556381784145],
              [-36.811139720282569, -8.245352865135491],
              [-36.809966377159967, -8.245407005122859],
              [-36.809366471452591, -8.24534375361827],
              [-36.809053112918228, -8.245334985282719],
              [-36.808827173255345, -8.245354213950069],
              [-36.808489701507519, -8.245317640989366],
              [-36.807578450778948, -8.245125151821963],
              [-36.807352272784328, -8.244992025895408],
              [-36.807117786192237, -8.244863364914627],
              [-36.806778263737918, -8.24481051818875],
              [-36.805978096834501, -8.244606051259405],
              [-36.805762004412983, -8.244587387011432],
              [-36.805201252360128, -8.244495454608922],
              [-36.804708734152463, -8.244545675385174],
              [-36.804363146372147, -8.244601992051274],
              [-36.80389851728652, -8.244615336534322],
              [-36.803658257974298, -8.24445449040992],
              [-36.803489996152052, -8.244422040377865],
              [-36.802893993435852, -8.244343018281144],
              [-36.802504256493734, -8.244440302342589],
              [-36.801969457307266, -8.244375679113432],
              [-36.801005103434065, -8.244282838346088],
              [-36.801146959756778, -8.244726359049819],
              [-36.801276104442508, -8.245707804087031],
              [-36.801716424946818, -8.246840262407229],
              [-36.801807375021674, -8.247170857209968],
              [-36.801877059621383, -8.248041000990522],
              [-36.801940764203621, -8.248791245967407],
              [-36.801969480066973, -8.249041879483336],
              [-36.80265923542283, -8.250469143136209],
              [-36.802655023539735, -8.251231131762237],
              [-36.803364410183541, -8.25231101178627],
              [-36.80359914036044, -8.252770917519854],
              [-36.804114268240724, -8.253758008252086],
              [-36.804514251058492, -8.254434018038799],
              [-36.8051175348972, -8.255490384774168],
              [-36.805668315689346, -8.256435336681253],
              [-36.805752888046193, -8.256511648773609],
              [-36.806167450022969, -8.257113653548517],
              [-36.807926395299354, -8.259245287360962],
              [-36.807929253390746, -8.259249623813847],
              [-36.808277598486683, -8.25977815141716],
              [-36.80968257674791, -8.260597248845841],
              [-36.809902763147626, -8.260164528903864],
              [-36.810140431968939, -8.259772474629976],
              [-36.81057089836596, -8.259097483112829],
              [-36.810631592203727, -8.259008736970303],
              [-36.810771119159391, -8.258752849213698],
              [-36.810846381723152, -8.258590979826311],
              [-36.810864375214948, -8.25853904246642],
              [-36.810878871116643, -8.258479148311809],
              [-36.810903504288191, -8.258346988683293],
              [-36.811059344388539, -8.257701362496094],
              [-36.811082128176132, -8.257422786924975],
              [-36.812204704491386, -8.258076285182405],
              [-36.81268652027137, -8.258043640030531],
              [-36.812998721231374, -8.258069222611349],
              [-36.813604720433787, -8.25800676505469],
              [-36.814236714064151, -8.258131051405377],
              [-36.814393810807026, -8.258114518089904],
              [-36.814665369281762, -8.258224334388025],
              [-36.814907119625303, -8.258147872913417],
              [-36.815241936196365, -8.258098675001706],
              [-36.815680372734043, -8.258123491498297],
              [-36.815877198557708, -8.258073411638794],
              [-36.81601426727336, -8.258091139250105],
              [-36.816205830789613, -8.25811434633184],
              [-36.816452676555443, -8.258074787655167],
              [-36.81676305806733, -8.257982992457015],
              [-36.817200404421371, -8.257987141737924],
              [-36.81756655568757, -8.258057931777145],
              [-36.818014158176631, -8.258129165565682],
              [-36.818410304806186, -8.258219521692903],
              [-36.818384275180115, -8.258445163280244],
              [-36.818544825400821, -8.258551874406962],
              [-36.818688114586415, -8.258577350464298],
              [-36.818921088383128, -8.258716207009561],
              [-36.819362016281296, -8.258713317915078],
              [-36.819755870532859, -8.258898912923531],
              [-36.820100954619612, -8.25893695339305],
              [-36.820578747954755, -8.258992473698463],
              [-36.821174209788893, -8.25890222738466],
              [-36.82165721886475, -8.258975414193035],
              [-36.821733111271762, -8.258698007181811],
              [-36.82184502172192, -8.258310550068385],
              [-36.822141692776931, -8.258297171028618],
              [-36.82253675749547, -8.258262277854657],
              [-36.822779824147617, -8.258175403250958],
              [-36.822976199747394, -8.258045939986484],
              [-36.823068690802295, -8.257970593689461],
              [-36.823311979555513, -8.257843149297802],
              [-36.823761561944565, -8.25771506245278],
              [-36.824300109355434, -8.257670363798027],
              [-36.824436339911003, -8.257610689321664],
              [-36.824968519360667, -8.257435423475625],
              [-36.825311857350776, -8.257469039906418],
              [-36.825713927244074, -8.257448292899948],
              [-36.826017148015737, -8.25737056247659],
              [-36.82646908656772, -8.257297167238917],
              [-36.82681688698603, -8.257210842969842],
              [-36.827048803565333, -8.257171546813581],
              [-36.828428656144162, -8.257089073707201],
              [-36.828577325928237, -8.257102227819303],
              [-36.828646218617337, -8.257132588592658],
              [-36.828699387294328, -8.257124057273275],
              [-36.828809526321855, -8.257059388711038],
              [-36.829007903760925, -8.25696829850607],
              [-36.829169668875416, -8.256934337866904],
              [-36.829400394196377, -8.256918390549272],
              [-36.829511064246979, -8.256918549645166],
              [-36.829643069230244, -8.256902948869165],
              [-36.829772367598324, -8.256896594091256],
              [-36.829933673163779, -8.256865717533469],
              [-36.830135146667359, -8.256775084448256],
              [-36.830276306111855, -8.256704409725531],
              [-36.830422777562163, -8.256633763729292],
              [-36.830589573283568, -8.256570283596819],
              [-36.830714584565648, -8.256538328012574],
              [-36.83092135059141, -8.256451251016665],
              [-36.83100205144904, -8.256426993001963],
              [-36.831089743541213, -8.256419530350358],
              [-36.831242854576224, -8.256430061569439],
              [-36.83139497437547, -8.256459990792113],
              [-36.831479943066583, -8.256464860939685],
              [-36.831670796506636, -8.256535570695849],
              [-36.831838833316155, -8.256569113879282],
              [-36.831897294753531, -8.256564138660838],
              [-36.832016907347892, -8.256548028942721],
              [-36.832109136912521, -8.256520305229323],
              [-36.832405072092449, -8.256479572937099],
              [-36.832563625288898, -8.256466319813661],
              [-36.832743465191207, -8.256446126067237],
              [-36.83293396768908, -8.256418934155064],
              [-36.832969328451739, -8.256428827294837],
              [-36.833017853888876, -8.256459959093149],
              [-36.833195023570845, -8.256604680025632],
              [-36.833301923948085, -8.256646711464782],
              [-36.833386734072306, -8.256680685545973],
              [-36.833498258424164, -8.256686580983471],
              [-36.833793791669258, -8.256650599508509],
              [-36.833987049585126, -8.256648897310766],
              [-36.834343981629011, -8.256832398921155],
              [-36.834573822479712, -8.257081036304983],
              [-36.834804797847042, -8.257161541480412],
              [-36.835081096285656, -8.257159112879224],
              [-36.835379920026966, -8.257087755369234],
              [-36.835537120510189, -8.257038053023514],
              [-36.835860372624516, -8.256939109048],
              [-36.836228627019658, -8.256382072854304],
              [-36.836278667420622, -8.256297673542852],
              [-36.836321990103627, -8.25614620767087],
              [-36.836367464498437, -8.25559962776021],
              [-36.836302138093821, -8.255239428153637],
              [-36.836310642439805, -8.254978408949661],
              [-36.83641652353257, -8.253744213324492],
              [-36.836517678783629, -8.253377857374884],
              [-36.836511633149826, -8.253187317664816],
              [-36.836191809816626, -8.252737545500395],
              [-36.836262139608245, -8.25235880513258],
              [-36.83641571311059, -8.251941447869809],
              [-36.836673223735801, -8.25147715480341],
              [-36.83702898871779, -8.251179203714401],
              [-36.837100928623947, -8.250974973381618],
              [-36.837123635324822, -8.250706974818028],
              [-36.837329934313857, -8.250542276805994],
              [-36.837620514980827, -8.25050856629414],
              [-36.838172885304544, -8.250525659133102],
              [-36.838480923337137, -8.25053790505182],
              [-36.839610799402422, -8.250508720708998],
              [-36.840017417304999, -8.250502872339819],
              [-36.840513455603457, -8.250498504992972],
              [-36.840956072895658, -8.250578266627926],
              [-36.841059250914263, -8.250596855984993],
              [-36.841691132393045, -8.250512715721122],
              [-36.842202363029116, -8.250016439725753],
              [-36.842644259517265, -8.249988293979733],
              [-36.842804117106894, -8.249905794749798],
              [-36.843076878293886, -8.249935845453646],
              [-36.843325257883777, -8.249827084428881],
              [-36.843502031327837, -8.249849558453551],
              [-36.843715720926433, -8.249971762394082],
              [-36.843926564208232, -8.250014821236521],
              [-36.844146803858976, -8.249934833857631],
              [-36.844366240508172, -8.249843949700555],
              [-36.844895602096749, -8.249803788744673],
              [-36.845577190687692, -8.25011131867687],
              [-36.845737868705946, -8.250148647137371],
              [-36.846130288051015, -8.250002892635671],
              [-36.846205700925658, -8.250031428286066],
              [-36.846863677363565, -8.250098384619495],
              [-36.847248374539014, -8.250098260450597],
              [-36.847342363458765, -8.250072305987059],
              [-36.847432887427573, -8.250032221148199],
              [-36.847471819173826, -8.249709624420417],
              [-36.847573702350189, -8.249533775108072],
              [-36.847954244590376, -8.248582394989006],
              [-36.848129693308792, -8.248227897780568],
              [-36.848111690527254, -8.24762805213636],
              [-36.848092537746631, -8.247567974393641],
              [-36.848035217607823, -8.247034948405837],
              [-36.848002858429744, -8.246470305044221],
              [-36.84803943585041, -8.246255297390965],
              [-36.848299631081687, -8.245946235957174],
              [-36.847989651882173, -8.245965739416741],
              [-36.847818012857424, -8.245943650243344],
              [-36.847247427868552, -8.245693630791438],
              [-36.847048117358192, -8.245549679021138],
              [-36.847030501753409, -8.245205610931405],
              [-36.847007865148626, -8.245134930690684],
              [-36.846926940296846, -8.245037477887637],
              [-36.846735046959964, -8.244831827088083],
              [-36.84659752832389, -8.244721722498157],
              [-36.84657168030742, -8.244262952466611],
              [-36.846600478449773, -8.244176672812443],
              [-36.84664164245239, -8.244095751470628],
              [-36.846729034131371, -8.243979799081028],
              [-36.846840522771679, -8.243664647864737],
              [-36.846952230700914, -8.243308926514306],
              [-36.847026287257329, -8.243039437278995],
              [-36.847033040264499, -8.242773114563988],
              [-36.847198153001287, -8.242364760750361],
              [-36.847575226557396, -8.242052798768677],
              [-36.847832341018183, -8.241985384020763],
              [-36.847956743480573, -8.241901381196689],
              [-36.848224017579916, -8.24159235704094],
              [-36.848473966308617, -8.241212680912545],
              [-36.848834227628963, -8.240734813935031],
              [-36.848936802824099, -8.240430197449788],
              [-36.849044177200163, -8.239892762698139],
              [-36.849200448045146, -8.238498300857229],
              [-36.849256766637048, -8.23823400735837],
              [-36.849369317721617, -8.237721295537435],
              [-36.845403529301521, -8.236645166739601],
              [-36.843688616550381, -8.236159684641635],
              [-36.842875303315253, -8.235940110032949],
              [-36.842093613741476, -8.235766567050289],
              [-36.841287002961444, -8.235617584110312],
              [-36.840688376522657, -8.235481184563838],
              [-36.838693131867068, -8.235103540805902],
              [-36.83703542487072, -8.234802805402847],
              [-36.835696725893584, -8.234538803183263],
              [-36.834242740137491, -8.234266367100499],
              [-36.832622591507281, -8.233943638404117],
              [-36.83106270450191, -8.233610645739558],
              [-36.829838885411334, -8.233342966788825],
              [-36.829221568991883, -8.233226736302596],
              [-36.828649277869381, -8.233133679812648],
              [-36.828299795973457, -8.233093864431629],
              [-36.827342684088499, -8.232623003792744],
              [-36.826798877833966, -8.232339593108637],
              [-36.825915931933729, -8.231906174225092],
              [-36.825169129225735, -8.231508770208663],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0044000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA NOVA',
        municipio: 'PESQUEIRA',
        area_hecta: '151.1581',
        capacidade: 6.0,
        num_famili: 6.0,
        fase: 7.0,
        data_de_cr: '24/11/1992',
        forma_obte: 'Compra e Venda',
        data_obten: '16/05/1984',
        area_calc_: 150.7282,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.79877362267252, -8.24368225087748],
              [-36.798168040199563, -8.243970858071597],
              [-36.798185895245261, -8.245595797526583],
              [-36.797544452959606, -8.245861057036352],
              [-36.797618755692973, -8.24606924469537],
              [-36.797810201282516, -8.246455439198447],
              [-36.79671315968924, -8.246810246329398],
              [-36.796770141228272, -8.24709202468761],
              [-36.796847659123934, -8.247307768627763],
              [-36.797874259733909, -8.250681330094485],
              [-36.798350593680148, -8.25235127128856],
              [-36.799533073946407, -8.256171001614891],
              [-36.799688360089945, -8.256659015662933],
              [-36.800279381305913, -8.258687239838858],
              [-36.800955747589818, -8.261017265720001],
              [-36.800824550534372, -8.261087283892468],
              [-36.801251916463002, -8.262564141233488],
              [-36.801404897259673, -8.263235686022018],
              [-36.801763486830041, -8.264404098754238],
              [-36.801886051934879, -8.264815600116421],
              [-36.802084280439722, -8.265556865707921],
              [-36.802319070415408, -8.266381527092518],
              [-36.801331639986387, -8.266667709083201],
              [-36.800586094413262, -8.266808556143873],
              [-36.799752000055314, -8.266952773322112],
              [-36.799708972702682, -8.267063734307374],
              [-36.799707411288942, -8.267423803284004],
              [-36.799675927647108, -8.267671895156004],
              [-36.799680400182268, -8.268502201491032],
              [-36.799713818754348, -8.269127250778867],
              [-36.800536198268219, -8.269031303391458],
              [-36.80159002355343, -8.268983144072669],
              [-36.802289195610335, -8.268976696593553],
              [-36.803054391465693, -8.268864258637572],
              [-36.804027874725406, -8.268454491104807],
              [-36.804715017927577, -8.268132347045819],
              [-36.806886193369067, -8.267226978380091],
              [-36.806775177897173, -8.266981705814894],
              [-36.806002580331629, -8.265060015655676],
              [-36.804572140526247, -8.261900117005494],
              [-36.806485301079981, -8.260478308556559],
              [-36.807175438630921, -8.259917636740647],
              [-36.807929253390746, -8.259249623813847],
              [-36.807926395299354, -8.259245287360962],
              [-36.806167450022969, -8.257113653548517],
              [-36.805752888046193, -8.256511648773609],
              [-36.805668315689346, -8.256435336681253],
              [-36.8051175348972, -8.255490384774168],
              [-36.804514251058492, -8.254434018038799],
              [-36.804114268240724, -8.253758008252086],
              [-36.80359914036044, -8.252770917519854],
              [-36.803364410183541, -8.25231101178627],
              [-36.802655023539735, -8.251231131762237],
              [-36.80265923542283, -8.250469143136209],
              [-36.801969480066973, -8.249041879483336],
              [-36.801940764203621, -8.248791245967407],
              [-36.801877059621383, -8.248041000990522],
              [-36.801807375021674, -8.247170857209968],
              [-36.801716424946818, -8.246840262407229],
              [-36.801276104442508, -8.245707804087031],
              [-36.801146959756778, -8.244726359049819],
              [-36.801005103434065, -8.244282838346088],
              [-36.800501890191292, -8.244242844675624],
              [-36.799949177240251, -8.244141196259953],
              [-36.79877362267252, -8.24368225087748],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0245000',
        uf: 'PE',
        nome_proje: 'PA SANTO IZIDRO',
        municipio: 'TUPARETAMA',
        area_hecta: '250.2370',
        capacidade: 8.0,
        num_famili: 6.0,
        fase: 7.0,
        data_de_cr: '05/12/2001',
        forma_obte: 'Compra e Venda',
        data_obten: '11/10/1984',
        area_calc_: 250.2112,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.271828937264189, -7.650398898683211],
              [-37.267368047193521, -7.652118802788092],
              [-37.265427032178941, -7.652905490636318],
              [-37.265426387587688, -7.65290576833485],
              [-37.265426215065702, -7.652905849015831],
              [-37.264300023628799, -7.65349824759642],
              [-37.264299724154093, -7.653498345849022],
              [-37.264299479129335, -7.653498426237511],
              [-37.262860261990596, -7.654152932822408],
              [-37.262835746286378, -7.654164226520827],
              [-37.262835383041939, -7.654164405890202],
              [-37.260381180541394, -7.655452075934934],
              [-37.260381153242932, -7.655452102949694],
              [-37.260380944321362, -7.655452219647905],
              [-37.260380726300298, -7.655452345351003],
              [-37.26038051734205, -7.655452471090702],
              [-37.260380317409968, -7.655452605908474],
              [-37.260380117477879, -7.655452740726244],
              [-37.26037991750912, -7.655452884585555],
              [-37.260379726566519, -7.655453037522935],
              [-37.260379535623912, -7.655453190460302],
              [-37.260379353707457, -7.655453352475739],
              [-37.260379171791016, -7.655453514491226],
              [-37.260378998900713, -7.655453685584768],
              [-37.260378826010417, -7.655453856678329],
              [-37.260378662146273, -7.655454036849942],
              [-37.26037849828213, -7.655454217021571],
              [-37.260378343444138, -7.655454406271304],
              [-37.260378188606147, -7.655454595521024],
              [-37.260378042794308, -7.655454793848832],
              [-37.260377896982469, -7.655454992176622],
              [-37.260377769259613, -7.655455199619115],
              [-37.260377632473919, -7.655455407025011],
              [-37.260377513813879, -7.655455614504069],
              [-37.260377541112348, -7.655455587489294],
              [-37.260310819583559, -7.655571774654915],
              [-37.2603107834056, -7.655571756425569],
              [-37.260310664745482, -7.655571963904592],
              [-37.260310555111523, -7.655572180461706],
              [-37.260310445440894, -7.655572406060326],
              [-37.260310344869751, -7.65557262265401],
              [-37.260310253324768, -7.655572848325799],
              [-37.260310161779785, -7.655573073997568],
              [-37.260310079260954, -7.655573308747463],
              [-37.260310005804939, -7.655573543533928],
              [-37.260309932385603, -7.65557376927888],
              [-37.260309867955748, -7.655574013143418],
              [-37.260309812625387, -7.655574248003044],
              [-37.260309757295026, -7.65557448286266],
              [-37.260309710990818, -7.655574726800379],
              [-37.260309673786097, -7.655574961733177],
              [-37.260309645607535, -7.655575205744046],
              [-37.26030961742898, -7.655575449754935],
              [-37.260309598313242, -7.655575693802404],
              [-37.260309588260327, -7.65557593788644],
              [-37.260309587270235, -7.655576182007084],
              [-37.260309586280151, -7.655576426127718],
              [-37.260309594352876, -7.655576670284908],
              [-37.260309611488438, -7.65557691447873],
              [-37.260309628623993, -7.655577158672535],
              [-37.260309654822365, -7.655577402902909],
              [-37.260309618681084, -7.655577375632054],
              [-37.260430420362319, -7.656517824488698],
              [-37.260430411372816, -7.6565178063691],
              [-37.260430446634047, -7.656518050636086],
              [-37.260430481931948, -7.656518285861551],
              [-37.260430535318875, -7.656518530201706],
              [-37.260430588742466, -7.656518765500342],
              [-37.260430651192223, -7.656519009877084],
              [-37.260430713678659, -7.656519245212317],
              [-37.26043078526461, -7.656519471542638],
              [-37.260430865876735, -7.656519706951046],
              [-37.260430955588376, -7.656519933354533],
              [-37.260431045300024, -7.65652015975802],
              [-37.260431144074502, -7.656520386198076],
              [-37.260431242885659, -7.656520603596641],
              [-37.260431224649956, -7.656520630647975],
              [-37.260990646922309, -7.657669460945085],
              [-37.260990610707488, -7.657669451757262],
              [-37.260990709555756, -7.657669660114316],
              [-37.260990799304494, -7.65766987747627],
              [-37.260990889089882, -7.65767008579675],
              [-37.260990969775747, -7.657670303122107],
              [-37.260991050461612, -7.657670520447515],
              [-37.260991122047933, -7.657670746777837],
              [-37.260991184608052, -7.657670964030071],
              [-37.2609912471315, -7.657671190323811],
              [-37.260991300592082, -7.657671416580957],
              [-37.260991344989797, -7.657671642801524],
              [-37.260991389387506, -7.657671869022125],
              [-37.260991424722349, -7.657672095206128],
              [-37.260991460057184, -7.65767232139013],
              [-37.260991450847648, -7.657672357519551],
              [-37.261459158637514, -7.661547311012042],
              [-37.261459157353897, -7.661547627464821],
              [-37.261459156437027, -7.661547853502521],
              [-37.261495241647637, -7.662125308919006],
              [-37.261495250600575, -7.66212533608011],
              [-37.261495267809586, -7.662125562190973],
              [-37.261495294044892, -7.662125797379967],
              [-37.261495320316882, -7.662126023527438],
              [-37.261495355651832, -7.662126249711497],
              [-37.261495400049753, -7.662126475932148],
              [-37.261495444447668, -7.662126702152781],
              [-37.261495497945234, -7.662126919368508],
              [-37.261495551406121, -7.66212714562575],
              [-37.261495613966652, -7.662127362878052],
              [-37.261495685590155, -7.662127580166962],
              [-37.261495685626826, -7.662127571125469],
              [-37.261682966214778, -7.662699488646697],
              [-37.261682957298504, -7.662699452444069],
              [-37.261683019895827, -7.662699660654885],
              [-37.261683082456479, -7.66269987790721],
              [-37.261683135954151, -7.662700095122952],
              [-37.261683189451823, -7.662700312338675],
              [-37.261683233886515, -7.662700529517839],
              [-37.261683278284529, -7.6627007557385],
              [-37.261683313656235, -7.662700972881066],
              [-37.261683339964961, -7.662701189987018],
              [-37.261683321765645, -7.662701207996861],
              [-37.261760885210691, -7.66335333416697],
              [-37.261760867011347, -7.66335335217681],
              [-37.261760893210045, -7.663353596407322],
              [-37.261760937571417, -7.663353831669472],
              [-37.26176098189611, -7.663354075973178],
              [-37.261761035320475, -7.66335431127193],
              [-37.261761088744841, -7.663354546570718],
              [-37.2617611512322, -7.663354781906064],
              [-37.26176122278256, -7.663355017277996],
              [-37.261761303432586, -7.663355243645024],
              [-37.26176138404594, -7.66335547905355],
              [-37.261761473758959, -7.663355705457185],
              [-37.261761572534979, -7.663355931897397],
              [-37.261761671347671, -7.663356149296081],
              [-37.261761779223363, -7.66335636673139],
              [-37.261761896162042, -7.663356584203267],
              [-37.261762013137407, -7.663356792633624],
              [-37.261762139139087, -7.663357010142112],
              [-37.261762265214116, -7.663357209567548],
              [-37.261762400315476, -7.663357418071098],
              [-37.261762544553179, -7.663357608528222],
              [-37.261762688754203, -7.663357808026868],
              [-37.2617628420549, -7.663357998520579],
              [-37.261762995355596, -7.663358189014287],
              [-37.261763157755965, -7.663358370503079],
              [-37.261763320193012, -7.66335854295038],
              [-37.26176349169306, -7.663358715434251],
              [-37.261763672256095, -7.66335888795471],
              [-37.261763852855815, -7.663359051433664],
              [-37.261764033455528, -7.663359214912629],
              [-37.261764223191591, -7.663359360345168],
              [-37.261764421953977, -7.66335951485579],
              [-37.261764611690033, -7.663359660288327],
              [-37.261764819588763, -7.663359796752533],
              [-37.261765018461183, -7.663359924138624],
              [-37.261765226396591, -7.663360051561321],
              [-37.261765434332005, -7.663360178984019],
              [-37.261765651367085, -7.663360297401779],
              [-37.261765868438843, -7.663360406778063],
              [-37.261766085547279, -7.663360507112801],
              [-37.261766311718709, -7.66336060748415],
              [-37.261766537926825, -7.663360698813985],
              [-37.261766764171604, -7.663360781102313],
              [-37.261766999479384, -7.663360863427242],
              [-37.261767225760849, -7.663360936674077],
              [-37.261767461105308, -7.663361009957464],
              [-37.261767470021596, -7.663361046160114],
              [-37.262725276961355, -7.663623684393115],
              [-37.262725267898354, -7.663623684356543],
              [-37.26272549421688, -7.66362374856141],
              [-37.262725711472399, -7.663623812729718],
              [-37.262725928691268, -7.663623885939526],
              [-37.262726145873472, -7.663623968190846],
              [-37.262726363019013, -7.663624059483686],
              [-37.262726571101567, -7.663624150739934],
              [-37.262726779184106, -7.663624241996233],
              [-37.262726987229989, -7.663624342293994],
              [-37.262727195239222, -7.663624451633305],
              [-37.262727177259833, -7.663624415394106],
              [-37.264106077297917, -7.664370562224249],
              [-37.26410610445037, -7.664370571375406],
              [-37.264106312387156, -7.664370698797214],
              [-37.2641065294236, -7.664370817214083],
              [-37.26410673732375, -7.664370953677413],
              [-37.264106936160871, -7.664371090104197],
              [-37.264107134961364, -7.664371235572499],
              [-37.264107333761856, -7.664371381040843],
              [-37.264107523462691, -7.664371535514142],
              [-37.264107713163519, -7.664371689987421],
              [-37.264107893764688, -7.664371853465695],
              [-37.264108074329229, -7.66437202598547],
              [-37.264108245830741, -7.664372198468703],
              [-37.264108417295617, -7.664372379993453],
              [-37.264108579697471, -7.66437256148166],
              [-37.264108733036295, -7.664372742933336],
              [-37.264108886338491, -7.664372933426519],
              [-37.264109039604044, -7.664373132961226],
              [-37.264109183806575, -7.66437333245938],
              [-37.264109318946076, -7.66437353192099],
              [-37.264109454048949, -7.664373740424124],
              [-37.264109580088792, -7.664373948890712],
              [-37.264109697028978, -7.664374166362276],
              [-37.264109814005792, -7.664374374792316],
              [-37.264109921846313, -7.664374601268855],
              [-37.264110029723469, -7.664374818703855],
              [-37.264110128500967, -7.664375045143864],
              [-37.264110218215428, -7.664375271547294],
              [-37.264110298830232, -7.664375506955716],
              [-37.264110379481679, -7.664375733322618],
              [-37.264110451033453, -7.664375968694498],
              [-37.264110513522205, -7.664376204029809],
              [-37.264110575974321, -7.664376448406645],
              [-37.264110629400044, -7.664376683705432],
              [-37.264110673726108, -7.664376928009193],
              [-37.264110718088801, -7.664377163271415],
              [-37.264110753351837, -7.664377407538649],
              [-37.264110779551842, -7.6643776517693],
              [-37.264110796688826, -7.66437789596341],
              [-37.264110813825802, -7.664378140157535],
              [-37.264110812836726, -7.664378384278549],
              [-37.264110820910673, -7.66437862843613],
              [-37.26411081082194, -7.664378881562138],
              [-37.264110800769835, -7.664379125646627],
              [-37.264110781654701, -7.664379369694535],
              [-37.264110753476537, -7.664379613705947],
              [-37.264110716235344, -7.664379857680799],
              [-37.264110679030786, -7.664380092614095],
              [-37.26411063272657, -7.664380336552416],
              [-37.264110577359325, -7.66438058045416],
              [-37.264110595668548, -7.664380535319665],
              [-37.26400002416289, -7.664850436937445],
              [-37.2640000603784, -7.664850446125166],
              [-37.264000014147285, -7.664850671980388],
              [-37.26399996791617, -7.664850897835612],
              [-37.263999930748092, -7.664851123727384],
              [-37.26399990264305, -7.664851349655671],
              [-37.263999874538015, -7.66485157558401],
              [-37.263999846432974, -7.664851801512333],
              [-37.263999836454005, -7.664852027513756],
              [-37.2639998264384, -7.664852262556702],
              [-37.263999825522475, -7.664852488594657],
              [-37.263999824606543, -7.664852714632629],
              [-37.263999832717019, -7.664852949748655],
              [-37.26399984992716, -7.66485317585973],
              [-37.263999831911001, -7.664853148662085],
              [-37.264046304170726, -7.665484806074691],
              [-37.264046340569486, -7.665484770054815],
              [-37.264046357706334, -7.665485014248935],
              [-37.264046383869591, -7.665485267521122],
              [-37.264046419132534, -7.66548551178833],
              [-37.264046463458527, -7.665485756092101],
              [-37.264046516884214, -7.665485991390906],
              [-37.264046570273258, -7.665486235731215],
              [-37.26404663276201, -7.665486471066575],
              [-37.264046695214105, -7.665486715443417],
              [-37.264046775828945, -7.665486950851897],
              [-37.264046856443784, -7.66548718626034],
              [-37.264046937095266, -7.665487412627281],
              [-37.264047035872849, -7.665487639067312],
              [-37.264047134650433, -7.665487865507372],
              [-37.26404724249106, -7.665488091983972],
              [-37.264047350368337, -7.665488309419032],
              [-37.264047467308657, -7.665488526890681],
              [-37.264047593312036, -7.665488744398862],
              [-37.2640477284151, -7.66548895290206],
              [-37.264047863518165, -7.665489161405289],
              [-37.264047998657873, -7.665489360866967],
              [-37.264048151923681, -7.665489560401781],
              [-37.26404829612644, -7.665489759900014],
              [-37.264048458491935, -7.665489950429858],
              [-37.264048620857437, -7.665490140959688],
              [-37.264048792322626, -7.665490322484532],
              [-37.264048963824457, -7.66549049496789],
              [-37.264049135326282, -7.665490667451246],
              [-37.264049315891164, -7.665490839971126],
              [-37.264049505555732, -7.665491003486085],
              [-37.264049695256944, -7.665491157959489],
              [-37.264049894021205, -7.665491312469447],
              [-37.264050092822103, -7.665491457937883],
              [-37.264050291659643, -7.665491594364833],
              [-37.264050499560241, -7.665491730828301],
              [-37.264050707460832, -7.665491867291789],
              [-37.264050924497752, -7.665491985708784],
              [-37.264051141534672, -7.66549210412578],
              [-37.264051358571592, -7.665492222542778],
              [-37.264051584744841, -7.665492322913284],
              [-37.26405181091809, -7.665492423283793],
              [-37.264052037127982, -7.665492514612779],
              [-37.264052272400932, -7.66549260597832],
              [-37.26405250771051, -7.665492688302357],
              [-37.26405274305673, -7.665492761584838],
              [-37.264052978402951, -7.665492834867353],
              [-37.264053222885501, -7.665492890103383],
              [-37.264053458305, -7.66549294530284],
              [-37.26405370278755, -7.665493000538867],
              [-37.264053947343378, -7.665493037691836],
              [-37.264054191899213, -7.665493074844824],
              [-37.264054436491676, -7.665493102956268],
              [-37.264054681120783, -7.665493122026201],
              [-37.264054925749889, -7.665493141096161],
              [-37.264055179478689, -7.665493151161136],
              [-37.264055424181073, -7.66549315214804],
              [-37.264055668920093, -7.665493144093428],
              [-37.264055922722171, -7.665493136075365],
              [-37.264055877296997, -7.665493163017161],
              [-37.264825258215673, -7.665446988201161],
              [-37.264825475728962, -7.665446989078016],
              [-37.26482580199891, -7.665446990393295],
              [-37.267547054880744, -7.66510026291207],
              [-37.267547055026995, -7.665100226745922],
              [-37.2675472998393, -7.665100200606195],
              [-37.267547535588548, -7.665100174429977],
              [-37.267547780364296, -7.66510015733177],
              [-37.267548016040415, -7.665100149238626],
              [-37.267548260743027, -7.665100150223496],
              [-37.267548505409081, -7.665100160249902],
              [-37.267548741012071, -7.665100170239832],
              [-37.267548985678118, -7.665100180266235],
              [-37.267549221207986, -7.66510020833924],
              [-37.267549465800911, -7.665100236448705],
              [-37.267549701294207, -7.665100273563244],
              [-37.267549936750953, -7.665100319719321],
              [-37.267550172207692, -7.665100365875382],
              [-37.267550407627866, -7.665100421072997],
              [-37.267550643011475, -7.665100485312115],
              [-37.267550869332034, -7.665100549514791],
              [-37.267551104679079, -7.665100622795461],
              [-37.267551330926509, -7.665100705081192],
              [-37.267551557173931, -7.665100787366945],
              [-37.267551774321731, -7.665100878657738],
              [-37.267551765112422, -7.665100914787409],
              [-37.268272223549452, -7.665408880342737],
              [-37.268272214522923, -7.665408871264737],
              [-37.268272431671107, -7.665408962555252],
              [-37.268272657918899, -7.66540904484067],
              [-37.268272875140184, -7.665409118048085],
              [-37.268273101424533, -7.665409191291965],
              [-37.268273327708883, -7.665409264535842],
              [-37.268273563129398, -7.665409319733103],
              [-37.268273789486848, -7.665409374893933],
              [-37.26827402490737, -7.665409430091194],
              [-37.268274260400986, -7.665409467205392],
              [-37.268274495894602, -7.665409504319568],
              [-37.268274486758436, -7.665409522366203],
              [-37.269887793617336, -7.665640876965342],
              [-37.26988782979663, -7.665640895194141],
              [-37.269888029074892, -7.665640923120259],
              [-37.269888237452761, -7.665640942041229],
              [-37.269888445830624, -7.665640960962214],
              [-37.269888654208486, -7.665640979883198],
              [-37.269888862659393, -7.665640980721087],
              [-37.269888880822066, -7.665640971752398],
              [-37.271369919063964, -7.665682094592133],
              [-37.271369909891426, -7.665682121680365],
              [-37.271370163621476, -7.665682131741108],
              [-37.271370408288426, -7.665682141765433],
              [-37.271370652882418, -7.665682169872872],
              [-37.271370906539495, -7.665682198016708],
              [-37.271371151097, -7.665682235165682],
              [-37.271371395618011, -7.665682281356231],
              [-37.271371631075937, -7.665682327510377],
              [-37.271371875560469, -7.665682382742466],
              [-37.271372120008522, -7.665682447016084],
              [-37.271372355356981, -7.665682520294916],
              [-37.271372590705454, -7.665682593573714],
              [-37.271372825980947, -7.66568268493562],
              [-37.271373052229848, -7.665682767219574],
              [-37.271373278405768, -7.665682867586638],
              [-37.271373504581696, -7.665682967953702],
              [-37.27137373072113, -7.665683077362321],
              [-37.271373947797478, -7.665683186734539],
              [-37.271374164800854, -7.665683314189867],
              [-37.271374381804236, -7.665683441645182],
              [-37.271374589744518, -7.665683569064131],
              [-37.271374797648313, -7.665683705524601],
              [-37.27137500551563, -7.665683851026626],
              [-37.271374987462423, -7.665683832870733],
              [-37.273436531106505, -7.667183437973123],
              [-37.273436494999771, -7.667183401661432],
              [-37.273436702868622, -7.667183547162768],
              [-37.273436901710795, -7.667183683586196],
              [-37.273437109652548, -7.667183811004425],
              [-37.273437326657429, -7.667183938459008],
              [-37.273437543698762, -7.667184056871997],
              [-37.273437760740102, -7.667184175285038],
              [-37.273437977817878, -7.667184284656495],
              [-37.273438203995248, -7.66718438502275],
              [-37.273438430209055, -7.66718447634744],
              [-37.273438656422869, -7.667184567672116],
              [-37.273438891736269, -7.66718464999162],
              [-37.273439127086114, -7.667184723269563],
              [-37.273439362435958, -7.667184796547488],
              [-37.273439597822247, -7.667184860783846],
              [-37.273439833244993, -7.667184915978659],
              [-37.273440077730875, -7.667184971209813],
              [-37.273440313190065, -7.667185017363059],
              [-37.273440557748835, -7.667185054511103],
              [-37.273440802344055, -7.667185082617584],
              [-37.273441046939283, -7.667185110724046],
              [-37.273441291570954, -7.667185129788958],
              [-37.273441536239076, -7.667185139812309],
              [-37.273441790006785, -7.667185140830457],
              [-37.273442034711351, -7.667185141812241],
              [-37.273442279452368, -7.667185133752462],
              [-37.273442524229843, -7.667185116651119],
              [-37.273442769043761, -7.667185090508224],
              [-37.27344301385768, -7.667185064365314],
              [-37.273443050000864, -7.667185091635458],
              [-37.273513145920163, -7.667175969484881],
              [-37.273513118876551, -7.667175933209539],
              [-37.273513372753605, -7.667175907102949],
              [-37.273513617567524, -7.667175880960012],
              [-37.273513862308548, -7.667175872900192],
              [-37.2735141161127, -7.667175864876729],
              [-37.273514360853724, -7.667175856816906],
              [-37.273514614584982, -7.667175866876574],
              [-37.273514859253112, -7.667175876899881],
              [-37.27351510388479, -7.667175895964755],
              [-37.273515357543154, -7.667175924107534],
              [-37.273515602138389, -7.667175952213972],
              [-37.273515846697173, -7.667175989361974],
              [-37.273516091219513, -7.66717603555154],
              [-37.273516335705402, -7.667176090782654],
              [-37.273516571128162, -7.667176145977423],
              [-37.273516589181526, -7.667176164133259],
              [-37.273553800387113, -7.667185807216343],
              [-37.273553809486693, -7.667185798211137],
              [-37.273554044873016, -7.667185862447439],
              [-37.273554289395364, -7.667185908636985],
              [-37.273554524854589, -7.667185954790171],
              [-37.273554769376936, -7.667186000979699],
              [-37.273555013972185, -7.6671860290861],
              [-37.273555258567434, -7.667186057192533],
              [-37.273555503199127, -7.667186076257365],
              [-37.273555747867263, -7.667186086280656],
              [-37.2735559925354, -7.667186096303939],
              [-37.273556237276438, -7.667186088244093],
              [-37.273556481981025, -7.667186089225813],
              [-37.273556726758507, -7.667186072124387],
              [-37.273556971572432, -7.667186045981424],
              [-37.273557207323229, -7.667186019802106],
              [-37.273557452137155, -7.667185993659129],
              [-37.27355742491131, -7.667186002591615],
              [-37.274361196553883, -7.667063637053507],
              [-37.274361214498008, -7.667063682334037],
              [-37.274361459384778, -7.667063638107438],
              [-37.274361695208412, -7.667063593844513],
              [-37.274361940131605, -7.667063540576328],
              [-37.274362176028099, -7.667063478230281],
              [-37.274362411924592, -7.667063415884202],
              [-37.274362647893938, -7.667063335455],
              [-37.274362883826853, -7.667063264067368],
              [-37.274363110769499, -7.667063174560241],
              [-37.274363337712145, -7.667063085053122],
              [-37.274363564691214, -7.667062986504459],
              [-37.274363782643583, -7.667062878877868],
              [-37.274364009659088, -7.667062771287597],
              [-37.274364227647887, -7.667062654619448],
              [-37.274364436573549, -7.667062537914944],
              [-37.274364645535634, -7.667062412168886],
              [-37.274364854534156, -7.667062277381224],
              [-37.274761404459355, -7.666800238028795],
              [-37.27476140460503, -7.666800201862526],
              [-37.274761604540195, -7.666800067038298],
              [-37.274761804511776, -7.666799923172518],
              [-37.274761995420228, -7.666799779270384],
              [-37.274762177265551, -7.666799635331937],
              [-37.274762359183711, -7.66679947331033],
              [-37.274762541065442, -7.666799320330293],
              [-37.274762713956889, -7.666799149230799],
              [-37.274762886811914, -7.666798987172876],
              [-37.274763050676654, -7.666798806995474],
              [-37.274763205441843, -7.666798635823296],
              [-37.274763369306577, -7.666798455645897],
              [-37.274763515081467, -7.666798266354265],
              [-37.274763660856365, -7.666798077062629],
              [-37.274763806667679, -7.666797878729406],
              [-37.27476394337944, -7.666797689401451],
              [-37.274764071100911, -7.666797481953993],
              [-37.274764198785967, -7.666797283548138],
              [-37.274764317444301, -7.666797076064362],
              [-37.27476442707593, -7.666796859502662],
              [-37.274764536671142, -7.666796651982552],
              [-37.274764637239642, -7.666796435384542],
              [-37.274764737844556, -7.666796209744973],
              [-37.274764774206339, -7.666796182765587],
              [-37.275104391225007, -7.666002234646228],
              [-37.275104354863323, -7.666002261625626],
              [-37.275104446331873, -7.666002054032726],
              [-37.275104546863531, -7.666001846476123],
              [-37.275104647395189, -7.666001638919516],
              [-37.275104756953553, -7.666001440440802],
              [-37.275104866511924, -7.666001241962073],
              [-37.275104985133396, -7.666001043519685],
              [-37.275105112817997, -7.666000845113615],
              [-37.275105240466182, -7.666000655749119],
              [-37.275105377141074, -7.666000475462515],
              [-37.275105513852381, -7.666000286134326],
              [-37.275105650527273, -7.666000105847742],
              [-37.275105805291993, -7.665999934675341],
              [-37.275105950993598, -7.665999763466637],
              [-37.275105941784844, -7.665999799596604],
              [-37.275238949126866, -7.665852500530914],
              [-37.275239222476444, -7.665852139957576],
              [-37.276832885211654, -7.664235081359876],
              [-37.276832876003112, -7.664235117489927],
              [-37.276833039829569, -7.66423494635289],
              [-37.276833203692384, -7.664234766174252],
              [-37.276833358455754, -7.664234595000937],
              [-37.276833504228762, -7.664234405708181],
              [-37.27683365000177, -7.664234216415405],
              [-37.276833795774778, -7.664234027122668],
              [-37.27683392342162, -7.664233837757342],
              [-37.276834060167907, -7.664233639386723],
              [-37.276834178788022, -7.664233440943555],
              [-37.276834297444495, -7.664233233458801],
              [-37.276834416100975, -7.664233025974051],
              [-37.276834525694362, -7.664232818453021],
              [-37.276834525657996, -7.664232827494604],
              [-37.277514280333747, -7.662874776576776],
              [-37.277514380390841, -7.662874686559754],
              [-37.277514462321811, -7.662874596470222],
              [-37.277535557533213, -7.66283498766587],
              [-37.277535812788905, -7.66283461797607],
              [-37.277535922236119, -7.662834446621039],
              [-37.278093912153984, -7.66196261413892],
              [-37.278093893882591, -7.661962650232813],
              [-37.278094003401961, -7.661962460794356],
              [-37.278094122020711, -7.661962262350531],
              [-37.278094231540088, -7.66196207291209],
              [-37.278094332032737, -7.661961874395789],
              [-37.278094432525393, -7.661961675879501],
              [-37.278094523955005, -7.66196147732699],
              [-37.278094615420933, -7.661961269732877],
              [-37.278094697823832, -7.661961062102502],
              [-37.278094734003353, -7.661961080330626],
              [-37.27889947178047, -7.659902790460611],
              [-37.278899489906479, -7.659902790533038],
              [-37.278899572380993, -7.659902564819165],
              [-37.278899654891809, -7.65990233006368],
              [-37.278899719276616, -7.659902095235768],
              [-37.278899792724424, -7.659901860444071],
              [-37.278899848046223, -7.659901625579948],
              [-37.278899903404323, -7.659901381674243],
              [-37.278899949663121, -7.659901146773902],
              [-37.27889999595822, -7.659900902831975],
              [-37.278900024127303, -7.659900658817633],
              [-37.278900052260092, -7.659900423844869],
              [-37.278900080429175, -7.659900179830509],
              [-37.278900090472256, -7.659899935743741],
              [-37.278900100515337, -7.659899691656972],
              [-37.27890010149541, -7.65989944753396],
              [-37.278900093412474, -7.659899203374768],
              [-37.278900085329546, -7.659898959215553],
              [-37.27890006818361, -7.659898715020148],
              [-37.278900041974666, -7.65989847078851],
              [-37.278900006702727, -7.659898226520657],
              [-37.278899971430789, -7.659897982252824],
              [-37.278899927059534, -7.659897746990337],
              [-37.278899873661587, -7.659897502650075],
              [-37.278899820227331, -7.659897267351408],
              [-37.278899757730073, -7.659897032016512],
              [-37.278899686169815, -7.659896796645404],
              [-37.278899605510254, -7.659896570279688],
              [-37.278899524886988, -7.659896334872368],
              [-37.278899435164419, -7.659896108470424],
              [-37.27889934544185, -7.659895882068496],
              [-37.278899237556978, -7.659895664635703],
              [-37.278899138771408, -7.659895438197565],
              [-37.278899021823527, -7.659895220728576],
              [-37.278898904839352, -7.659895012301165],
              [-37.278898778792168, -7.659894803837571],
              [-37.278898643681984, -7.65989459533775],
              [-37.278898508571793, -7.65989438683793],
              [-37.278898373425307, -7.659894187379701],
              [-37.278898229179518, -7.659893996926854],
              [-37.278898075870728, -7.659893806437776],
              [-37.27889791349893, -7.659893615912518],
              [-37.278897751090838, -7.659893434428822],
              [-37.278897588682739, -7.65989325294514],
              [-37.278897417175344, -7.659893080466824],
              [-37.278897236604941, -7.659892907952312],
              [-37.278897055998236, -7.659892744479374],
              [-37.278896875391531, -7.659892581006456],
              [-37.278896685685531, -7.659892426538915],
              [-37.278896495943229, -7.65989228111297],
              [-37.278896297137919, -7.659892135650796],
              [-37.278896098296315, -7.659891999230211],
              [-37.278895890391709, -7.659891862773433],
              [-37.278895682450795, -7.659891735358231],
              [-37.278895465410585, -7.659891616948395],
              [-37.278895257433376, -7.659891498574803],
              [-37.278895040356858, -7.659891389206576],
              [-37.278894814217345, -7.659891279802133],
              [-37.278894588005237, -7.65989118848088],
              [-37.278894361829416, -7.659891088118031],
              [-37.278894352693818, -7.65989110616499],
              [-37.278317216699556, -7.659664112590735],
              [-37.278317180447573, -7.65966411244584],
              [-37.278316954235756, -7.659664021124349],
              [-37.278316728060254, -7.65966392076125],
              [-37.278316510984055, -7.659663811392791],
              [-37.278316284844863, -7.659663701988086],
              [-37.278316076867974, -7.65966358361426],
              [-37.278315859828083, -7.659663465204224],
              [-37.278315859791775, -7.659663474245797],
              [-37.277536569612927, -7.65920004421289],
              [-37.277536587884192, -7.659200008119033],
              [-37.277536379944124, -7.659199880703337],
              [-37.277536181103365, -7.659199744282291],
              [-37.277535982262599, -7.659199607861259],
              [-37.277535783458163, -7.659199462398643],
              [-37.277535593716713, -7.65919931697223],
              [-37.27753540401158, -7.659199162504263],
              [-37.277535223369433, -7.659199008072518],
              [-37.277535042763617, -7.659198844599221],
              [-37.277534862194116, -7.659198672084302],
              [-37.277534690687602, -7.659198499605618],
              [-37.277534528244075, -7.659198327163209],
              [-37.277534365836871, -7.659198145679179],
              [-37.277534212528977, -7.659197955189816],
              [-37.277534059221082, -7.659197764700455],
              [-37.277533914976161, -7.659197574247329],
              [-37.277533770767576, -7.659197374752603],
              [-37.277533635621964, -7.659197175294146],
              [-37.277533509575669, -7.659196966830308],
              [-37.277533500549012, -7.659196957752483],
              [-37.276826309425523, -7.658047918590863],
              [-37.276826273101037, -7.658047936529056],
              [-37.276826147019008, -7.658047737106752],
              [-37.276826011837684, -7.658047546689787],
              [-37.276825876656368, -7.658047356272822],
              [-37.276825732412092, -7.658047165819608],
              [-37.276825588131487, -7.658046984407977],
              [-37.276825434787924, -7.658046802960084],
              [-37.27682528144436, -7.658046621512205],
              [-37.276825119001508, -7.65804644906966],
              [-37.276824956558656, -7.658046276627086],
              [-37.276824785016515, -7.658046113189898],
              [-37.276824613438045, -7.658045958794278],
              [-37.27682459545747, -7.658045922555425],
              [-37.27595315751865, -7.657262135438415],
              [-37.275953121194206, -7.657262153376524],
              [-37.275952949652726, -7.657261989939094],
              [-37.275952787174184, -7.657261826537943],
              [-37.275952624695627, -7.65726166313679],
              [-37.275952462253429, -7.657261490694057],
              [-37.275952308874167, -7.65726131828757],
              [-37.27595215553125, -7.657261136839506],
              [-37.275952011251263, -7.657260955427716],
              [-37.275951876070565, -7.657260765010627],
              [-37.275951740889866, -7.657260574593506],
              [-37.275951605709167, -7.657260384176397],
              [-37.27595147962775, -7.657260184753975],
              [-37.275951362609277, -7.657259985367816],
              [-37.27595124559079, -7.657259785981655],
              [-37.275951137671598, -7.657259577590176],
              [-37.275951173850636, -7.657259595818374],
              [-37.275520313791596, -7.656425942708815],
              [-37.275520215299203, -7.656425643937805],
              [-37.275520134714512, -7.656425399488809],
              [-37.275281502854007, -7.655884843533546],
              [-37.275281512062335, -7.65588480740352],
              [-37.275281422305859, -7.655884590042977],
              [-37.275281332585735, -7.655884363640832],
              [-37.27528125196487, -7.655884128233434],
              [-37.275281171307654, -7.65588390186758],
              [-37.275281099749691, -7.655883666496418],
              [-37.275281037254636, -7.655883431161543],
              [-37.275280983822469, -7.655883195862907],
              [-37.275280930390316, -7.655882960564301],
              [-37.275280886057416, -7.655882716260371],
              [-37.275280841688165, -7.655882480998019],
              [-37.275280815481068, -7.655882236766649],
              [-37.275280789273978, -7.655881992535277],
              [-37.275280772129783, -7.655881748340144],
              [-37.275280754949236, -7.65588151318662],
              [-37.275280746867949, -7.655881269027792],
              [-37.275280747849564, -7.655881024905199],
              [-37.275280757894073, -7.655880780818911],
              [-37.27528076793859, -7.655880536732603],
              [-37.275280796108909, -7.655880292718853],
              [-37.275280815216327, -7.655880048668802],
              [-37.275280852413196, -7.655879813732902],
              [-37.27528088964641, -7.655879569755425],
              [-37.275280935906174, -7.65587933485578],
              [-37.275280991265198, -7.655879090950823],
              [-37.275281046587871, -7.655878856087447],
              [-37.275281110973438, -7.655878621260363],
              [-37.275281184385562, -7.655878395511121],
              [-37.275281266896933, -7.655878160756557],
              [-37.275281349371951, -7.655877935043571],
              [-37.275281440909872, -7.655877709366855],
              [-37.275281532447792, -7.655877483690139],
              [-37.275281633048607, -7.655877258049692],
              [-37.275281742675979, -7.655877041487113],
              [-37.275281861366246, -7.655876824960768],
              [-37.275281980020168, -7.655876617476038],
              [-37.275282107736977, -7.65587641002756],
              [-37.275282235453794, -7.655876202579064],
              [-37.275282262787925, -7.655876166521578],
              [-37.27614826801377, -7.654560443583883],
              [-37.276148724064207, -7.654559722069686],
              [-37.276365711256013, -7.654149100801614],
              [-37.276365666014307, -7.654149082537264],
              [-37.276365775640556, -7.654148865974137],
              [-37.276365885266799, -7.654148649411027],
              [-37.276365976803625, -7.654148423733871],
              [-37.276366068304135, -7.65414820709828],
              [-37.276366077403324, -7.654148198092935],
              [-37.276493609700296, -7.653828450011591],
              [-37.276493591501925, -7.653828468022289],
              [-37.276493673939399, -7.653828251350407],
              [-37.276493756376873, -7.653828034678524],
              [-37.276493820688621, -7.653827817934167],
              [-37.276493894099552, -7.653827592184482],
              [-37.276493949348435, -7.653827375403873],
              [-37.276494004633633, -7.653827149581714],
              [-37.276494050855973, -7.653826923723305],
              [-37.276494097078313, -7.653826697864893],
              [-37.276494134237787, -7.653826471970248],
              [-37.27649416237071, -7.653826236997785],
              [-37.276494190467318, -7.65382601106692],
              [-37.276494163278727, -7.653826010958214],
              [-37.276534502919354, -7.653417585559497],
              [-37.276534539316046, -7.653417549538085],
              [-37.276534558349702, -7.653417323570955],
              [-37.276534577419675, -7.653417088562238],
              [-37.27653457832762, -7.653416862522644],
              [-37.276534588334734, -7.653416627477712],
              [-37.276534580179828, -7.653416401401885],
              [-37.27653457206123, -7.653416166284469],
              [-37.276534554843465, -7.653415940172426],
              [-37.2765345376257, -7.653415714060348],
              [-37.276534502318533, -7.653415478834237],
              [-37.276534476037909, -7.65341525268596],
              [-37.276534431631575, -7.653415026465185],
              [-37.276534387225233, -7.653414800244442],
              [-37.276534342818898, -7.653414574023685],
              [-37.276534333647085, -7.653414601112209],
              [-37.276522033554592, -7.653363104500206],
              [-37.276522078832549, -7.653363113722949],
              [-37.276522016336827, -7.653362878388159],
              [-37.276521953841112, -7.653362643053335],
              [-37.276521882246215, -7.653362416723895],
              [-37.276521801588459, -7.653362190358211],
              [-37.27652172093071, -7.653361963992521],
              [-37.276521631210109, -7.6533617375906],
              [-37.276521532426649, -7.653361511152446],
              [-37.276521433606874, -7.653361293755895],
              [-37.276521325724246, -7.653361076323097],
              [-37.276521208742437, -7.653360867895651],
              [-37.276521091760628, -7.653360659468203],
              [-37.276520965715974, -7.653360451004525],
              [-37.276520839671313, -7.65336024254084],
              [-37.276520704527478, -7.6533600430825],
              [-37.276520560284482, -7.653359852629539],
              [-37.276520416041478, -7.653359662176565],
              [-37.276520262735616, -7.653359471687341],
              [-37.276520109429761, -7.653359281198116],
              [-37.276519946988415, -7.653359108755862],
              [-37.276519784583371, -7.653358927272009],
              [-37.276519613079174, -7.653358754793506],
              [-37.27651944153866, -7.653358591356573],
              [-37.276519260935281, -7.653358427883405],
              [-37.276519080295586, -7.653358273451841],
              [-37.276518890593039, -7.653358118984042],
              [-37.276518700854176, -7.653357973557813],
              [-37.276518700999446, -7.653357937391473],
              [-37.275848876062817, -7.652856426949332],
              [-37.275848867108962, -7.652856399788343],
              [-37.275848668271408, -7.652856263367253],
              [-37.275848469470176, -7.65285611790458],
              [-37.275848270596285, -7.652855990525091],
              [-37.275848062659556, -7.652855863109338],
              [-37.275847845623659, -7.65285574469894],
              [-37.275847637650607, -7.65285562632477],
              [-37.275847420578373, -7.652855516955937],
              [-37.275847203469816, -7.652855416628687],
              [-37.275846977298414, -7.652855316265193],
              [-37.275846760153534, -7.652855224979525],
              [-37.275846524882965, -7.652855133621351],
              [-37.275846298602573, -7.652855060382605],
              [-37.275846072322189, -7.652854987143855],
              [-37.275845836978959, -7.652854913868861],
              [-37.275845601563077, -7.65285485867703],
              [-37.275845366147188, -7.652854803485186],
              [-37.275845130731298, -7.652854748293355],
              [-37.275844886179911, -7.652854711148414],
              [-37.275844650691369, -7.652854674039749],
              [-37.275844406103651, -7.652854645936404],
              [-37.275844170578779, -7.652854617869306],
              [-37.275843925918402, -7.652854607849141],
              [-37.275843681258031, -7.652854597828961],
              [-37.275843445660492, -7.652854587845025],
              [-37.275843200927454, -7.65285459590801],
              [-37.275843173738934, -7.652854595799274],
              [-37.275220303457161, -7.652870006958514],
              [-37.275220267096785, -7.652870033938221],
              [-37.275220031426649, -7.652870042037069],
              [-37.275219795829194, -7.652870032052828],
              [-37.275219560195403, -7.652870031110129],
              [-37.275219324634293, -7.652870012084277],
              [-37.275219089073182, -7.652869993058419],
              [-37.275218853548417, -7.652869964990987],
              [-37.275218618059988, -7.652869927881989],
              [-37.275218382571566, -7.652869890772957],
              [-37.275218147119489, -7.65286984462238],
              [-37.275217920766593, -7.652869789466466],
              [-37.275217694413691, -7.652869734310569],
              [-37.275217459034302, -7.652869670076834],
              [-37.275217241816925, -7.652869596874035],
              [-37.275217015536711, -7.65286952363496],
              [-37.275216789292841, -7.652869441354322],
              [-37.275216572148153, -7.652869350068368],
              [-37.275216355003458, -7.652869258782408],
              [-37.275216318715771, -7.652869267678957],
              [-37.274362670409211, -7.652489896965891],
              [-37.274362715577901, -7.652489933313558],
              [-37.274362489407196, -7.652489832949369],
              [-37.274362272335679, -7.652489723579948],
              [-37.274362055300521, -7.652489605168899],
              [-37.274361838265364, -7.652489486757884],
              [-37.274361630329388, -7.652489359341551],
              [-37.274361422393419, -7.65248923192522],
              [-37.274361223556632, -7.652489095503581],
              [-37.274361024756196, -7.65248895004039],
              [-37.274360825955775, -7.652488804577179],
              [-37.274360636254528, -7.652488650108667],
              [-37.274360446589647, -7.652488486598616],
              [-37.274360265987589, -7.652488323124803],
              [-37.274360085385531, -7.652488159650989],
              [-37.274359913882655, -7.652487987171892],
              [-37.274359742416145, -7.652487805651218],
              [-37.274359580012458, -7.652487624166818],
              [-37.274359426671602, -7.652487442718678],
              [-37.274359273367097, -7.652487252228977],
              [-37.274359120098957, -7.652487052697684],
              [-37.274358975893641, -7.6524868532027],
              [-37.274358840751148, -7.652486653743971],
              [-37.274358705645021, -7.652486445243669],
              [-37.27435857960171, -7.652486236779637],
              [-37.274358462621237, -7.652486028351884],
              [-37.274358345677122, -7.652485810882555],
              [-37.274358237795823, -7.652485593449511],
              [-37.27435812995089, -7.652485366974882],
              [-37.274358031168781, -7.652485140536524],
              [-37.274357941449502, -7.652484914134439],
              [-37.274357860793046, -7.652484687768631],
              [-37.274357780172949, -7.652484452361245],
              [-37.274357708579323, -7.652484226031725],
              [-37.274357646084873, -7.652483990696888],
              [-37.274357583626788, -7.652483746320493],
              [-37.274357530195175, -7.652483511021927],
              [-37.274357485862744, -7.652483266718064],
              [-37.274357441493947, -7.652483031455756],
              [-37.27435740622434, -7.652482787188201],
              [-37.274357380017555, -7.652482542956884],
              [-37.274357362873602, -7.652482298761837],
              [-37.274357345729648, -7.652482054566812],
              [-37.274357337648517, -7.652481810408042],
              [-37.274357338630217, -7.652481566285562],
              [-37.274357348674734, -7.652481322199342],
              [-37.274357358719257, -7.652481078113137],
              [-37.274357377826604, -7.652480834063189],
              [-37.274357405996788, -7.652480590049515],
              [-37.274357434166959, -7.652480346035857],
              [-37.274357471399959, -7.652480102058457],
              [-37.274357517659425, -7.652479867158908],
              [-37.274357544956978, -7.652479840143019],
              [-37.274494458929425, -7.651810487085641],
              [-37.274494495180669, -7.651810487230711],
              [-37.274494541476258, -7.651810243289528],
              [-37.274494578709046, -7.651809999312097],
              [-37.27449460687901, -7.65180975529838],
              [-37.274494635048981, -7.651809511284662],
              [-37.274494654156136, -7.651809267234695],
              [-37.274494664200482, -7.651809023148441],
              [-37.274494674244828, -7.651808779062202],
              [-37.27449467522635, -7.651808534939684],
              [-37.274494667181422, -7.651808281739334],
              [-37.274494650037333, -7.651808037544276],
              [-37.274494632893237, -7.651807793349222],
              [-37.274494606686332, -7.651807549117914],
              [-37.274494571416618, -7.651807304850304],
              [-37.274494527084087, -7.651807060546478],
              [-37.274494482715198, -7.651806825284199],
              [-37.274494429319859, -7.651806580944052],
              [-37.274494366825351, -7.651806345609247],
              [-37.274494295268028, -7.651806110238175],
              [-37.274494223710704, -7.651805874867092],
              [-37.274494143090571, -7.651805639459734],
              [-37.274494062434094, -7.651805413093972],
              [-37.274493972714794, -7.651805186691926],
              [-37.274493873932677, -7.651804960253613],
              [-37.274493766087758, -7.651804733779014],
              [-37.274493658206481, -7.651804516346038],
              [-37.274493541262395, -7.651804298876764],
              [-37.274493415255499, -7.651804081371208],
              [-37.274493289212245, -7.651803872907243],
              [-37.274493154106175, -7.651803664407024],
              [-37.27449301896376, -7.651803464948346],
              [-37.274492874758529, -7.651803265453422],
              [-37.274492721454138, -7.651803074963824],
              [-37.274492568149746, -7.651802884474189],
              [-37.274492405782539, -7.651802693948304],
              [-37.274492243378972, -7.651802512463993],
              [-37.274492071912604, -7.651802330943401],
              [-37.274491900409878, -7.651802158464398],
              [-37.27449171980799, -7.651801994990691],
              [-37.274491530143287, -7.651801831480729],
              [-37.274491340442239, -7.651801677012346],
              [-37.274491150741184, -7.651801522543943],
              [-37.274490951940962, -7.651801377080866],
              [-37.274490753140739, -7.651801231617756],
              [-37.274490545241363, -7.651801095159988],
              [-37.274490337341973, -7.65180095870222],
              [-37.27449012936988, -7.651800840327588],
              [-37.274489912334978, -7.651800721916683],
              [-37.274489695300076, -7.651800603505784],
              [-37.274489478228823, -7.651800494136476],
              [-37.274489252058402, -7.65180039377246],
              [-37.274489025851629, -7.651800302450003],
              [-37.274488799644857, -7.651800211127558],
              [-37.274488564338917, -7.65180012881041],
              [-37.274488329032984, -7.651800046493277],
              [-37.274488093654334, -7.651799982259278],
              [-37.274487858275684, -7.651799918025314],
              [-37.27448762286069, -7.651799862832893],
              [-37.274487378382887, -7.65179980760422],
              [-37.274487133868725, -7.65179976141712],
              [-37.274486889318212, -7.651799724271564],
              [-37.274486653794149, -7.651799696203884],
              [-37.274486409207285, -7.651799668099907],
              [-37.274486155521252, -7.651799649001269],
              [-37.274486164402298, -7.651799694245416],
              [-37.273822282835447, -7.651759604276399],
              [-37.273822255465184, -7.6517596493754],
              [-37.273822028967714, -7.651759630385212],
              [-37.273821793334704, -7.651759629441887],
              [-37.273821557701694, -7.651759628498562],
              [-37.273821322032319, -7.651759636596809],
              [-37.27382109538938, -7.651759653772913],
              [-37.273820859683639, -7.651759670912716],
              [-37.27382083242248, -7.651759688887016],
              [-37.273510580576378, -7.651788917305066],
              [-37.273510562414394, -7.651788926274055],
              [-37.27351032670866, -7.651788943413687],
              [-37.273510091002926, -7.651788960553331],
              [-37.273509846307128, -7.651788959573544],
              [-37.273509610637767, -7.651788967671632],
              [-37.273509375041151, -7.651788957686544],
              [-37.273509139444535, -7.651788947701476],
              [-37.273508903884299, -7.651788928674832],
              [-37.273508668360435, -7.651788900606635],
              [-37.273508432836564, -7.651788872538402],
              [-37.273508197349074, -7.651788835428637],
              [-37.273507961861583, -7.651788798318869],
              [-37.273507735509639, -7.651788743162229],
              [-37.273507500058521, -7.651788697010891],
              [-37.273507273742958, -7.651788632812697],
              [-37.273507047427394, -7.651788568614482],
              [-37.273507047536519, -7.651788541489771],
              [-37.272655907659171, -7.651527805478976],
              [-37.272655889388012, -7.651527841572618],
              [-37.272655654046396, -7.651527768296146],
              [-37.272655427803969, -7.651527686014444],
              [-37.272655201561548, -7.651527603732722],
              [-37.272654975355508, -7.65152751240942],
              [-37.272654749185868, -7.651527412044568],
              [-37.272654532115403, -7.651527302674472],
              [-37.272654315044946, -7.651527193304353],
              [-37.272654097974488, -7.651527083934239],
              [-37.272653890039614, -7.651526956517294],
              [-37.272653682104732, -7.651526829100328],
              [-37.272653483269039, -7.651526692678138],
              [-37.272653284433353, -7.651526556255947],
              [-37.272653085634055, -7.651526410792187],
              [-37.272652895897551, -7.651526265364718],
              [-37.272652706197441, -7.651526110895666],
              [-37.272652516533718, -7.651525947385093],
              [-37.272652335932797, -7.651525783910794],
              [-37.272652164394671, -7.651525620472815],
              [-37.272651992892939, -7.651525447993271],
              [-37.272651830490382, -7.651525266508461],
              [-37.272651668087839, -7.651525085023636],
              [-37.272651659061431, -7.651525075945763],
              [-37.272498849313159, -7.651347065398831],
              [-37.272498867402348, -7.651347074513011],
              [-37.272498714062728, -7.651346893064481],
              [-37.272498569785895, -7.651346711652224],
              [-37.272498425545457, -7.651346521198455],
              [-37.272498290404208, -7.651346321739402],
              [-37.272498155262952, -7.651346122280355],
              [-37.272498029184483, -7.651345922857614],
              [-37.272497903106029, -7.651345723434857],
              [-37.272497786126749, -7.651345515006878],
              [-37.272497678210279, -7.651345306615183],
              [-37.272497570330188, -7.651345089181925],
              [-37.272497471512892, -7.651344871784974],
              [-37.272497381758392, -7.65134465442431],
              [-37.272497292003884, -7.651344437063702],
              [-37.272497211348572, -7.651344210697799],
              [-37.272497229656125, -7.65134416556258],
              [-37.272388539321405, -7.651037586831637],
              [-37.272388575572542, -7.651037586976872],
              [-37.272388494917315, -7.651037360610972],
              [-37.272388405199294, -7.651037134208761],
              [-37.272388306418492, -7.651036907770242],
              [-37.272388198574902, -7.651036681295435],
              [-37.272388090694918, -7.651036463862159],
              [-37.272387973752146, -7.65103624639259],
              [-37.27238785677298, -7.651036037964606],
              [-37.272387730731033, -7.651035829500278],
              [-37.272387595626299, -7.651035620999678],
              [-37.272387460485177, -7.651035421540608],
              [-37.272387316281261, -7.651035222045263],
              [-37.272387163014557, -7.651035022513579],
              [-37.272387009711466, -7.651034832023491],
              [-37.272386847309193, -7.65103465053863],
              [-37.272386829038041, -7.651034686632276],
              [-37.271828937264189, -7.650398898683211],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0408000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA DO GUILHERME',
        municipio: 'SERTANIA',
        area_hecta: '1025.9125',
        capacidade: 29.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '25/02/2014',
        forma_obte: 'Desapropriação',
        data_obten: '22/09/2010',
        area_calc_: 1069.0422,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.404820599786049, -7.990603609517247],
              [-37.404726790351866, -7.990724683773695],
              [-37.402889101701433, -7.993039613399223],
              [-37.402723045007022, -7.993031466599463],
              [-37.401891462902668, -7.993044798664555],
              [-37.399289134790095, -7.992108088061371],
              [-37.399102053859174, -7.992048408682676],
              [-37.398527171318484, -7.991645433907654],
              [-37.393455065926915, -7.994115406078197],
              [-37.391349600170471, -7.995324643516786],
              [-37.390627391560486, -7.995158259084993],
              [-37.38901237848598, -7.996598790387691],
              [-37.388385286481999, -7.996765250485114],
              [-37.388277357543494, -7.996761393370218],
              [-37.388195712536152, -7.99685547422637],
              [-37.38817242186385, -7.996896163174751],
              [-37.388159185637839, -7.997055975703717],
              [-37.38818267707272, -7.997403012432486],
              [-37.388153547001387, -7.997406153904635],
              [-37.388121550850563, -7.997399970835305],
              [-37.387966146008715, -7.997358765330631],
              [-37.387648981012724, -7.997046931546492],
              [-37.385552790753458, -7.995775745686468],
              [-37.385526065801642, -7.995767051265461],
              [-37.3854949548805, -7.995766477574251],
              [-37.382995726523966, -7.996023895645645],
              [-37.382823454499601, -7.995636491971188],
              [-37.382756169124491, -7.995516149892609],
              [-37.382733295448894, -7.995473924306567],
              [-37.382508856455935, -7.995319240042504],
              [-37.382355739146071, -7.995227406063572],
              [-37.382328755754664, -7.99521527445797],
              [-37.382132122944839, -7.995163235953997],
              [-37.382000408644799, -7.995141561695468],
              [-37.381787958124903, -7.995121289162921],
              [-37.380820383945846, -7.995255479994964],
              [-37.380198313408592, -7.995666896440115],
              [-37.378170114936722, -7.996320636039064],
              [-37.378107660302838, -7.996378440775214],
              [-37.379037256696975, -7.998212562752224],
              [-37.381612733663779, -8.003371032770183],
              [-37.381128617323043, -8.005299613617845],
              [-37.381062801894238, -8.005771712487883],
              [-37.382514448175705, -8.006439013601907],
              [-37.384377634570377, -8.007687519200438],
              [-37.384925428458487, -8.007782978525839],
              [-37.385071360821129, -8.007857785299594],
              [-37.386000121610294, -8.008442735541163],
              [-37.385926542262645, -8.009021592669537],
              [-37.386685949209763, -8.010758021076855],
              [-37.386727947166285, -8.011104044946075],
              [-37.386995308743579, -8.012040494175777],
              [-37.387165035765307, -8.012316037438426],
              [-37.387888230461947, -8.012883905814775],
              [-37.388234396005934, -8.013043947971013],
              [-37.388597607063339, -8.013529029123614],
              [-37.38879748439026, -8.013957862853504],
              [-37.38877037961737, -8.014621807796386],
              [-37.394012513086047, -8.014301198854056],
              [-37.394737812877601, -8.014243261249465],
              [-37.394849469710358, -8.013922158535676],
              [-37.398515035322447, -8.013941389685954],
              [-37.406385564034231, -8.013282338312422],
              [-37.406577127842908, -8.013288413701689],
              [-37.406692319922946, -8.013292114126928],
              [-37.406837443939942, -8.013296743996017],
              [-37.418357873070271, -8.01272741171052],
              [-37.418806564638373, -8.012581383081056],
              [-37.419018401965133, -8.01262324716653],
              [-37.420674001036431, -8.012582754152572],
              [-37.420653827224712, -8.012356621278231],
              [-37.421781653069807, -8.012268528624682],
              [-37.421188094044687, -8.009141805065267],
              [-37.418665706745074, -8.006840391743641],
              [-37.414957883887979, -8.004313967744748],
              [-37.414417986154014, -8.003909787056868],
              [-37.415047849295135, -8.002770357013887],
              [-37.414917868966128, -8.002486203958492],
              [-37.415439511431828, -8.001416614966333],
              [-37.415479987240893, -8.001036093436166],
              [-37.415969803379717, -8.000103008709576],
              [-37.416502943106501, -7.99986028106516],
              [-37.417694279956031, -7.997948079075694],
              [-37.41854241656344, -7.997856474735953],
              [-37.418872651108259, -7.998032615696767],
              [-37.419544196598068, -7.999442427521682],
              [-37.419854021031512, -7.999666142417555],
              [-37.420393455367602, -8.000073388603926],
              [-37.420655791769768, -8.0003564192518],
              [-37.421947456687747, -8.001600053705614],
              [-37.422457886363581, -8.001766663692919],
              [-37.423069621407564, -8.002410457341488],
              [-37.423772625333399, -8.002319826506406],
              [-37.425024721019696, -8.001659186906233],
              [-37.42628053996895, -8.000996567613035],
              [-37.426371978859123, -8.001681957180327],
              [-37.426557239140415, -8.003070196994861],
              [-37.439648363521087, -8.002730821382828],
              [-37.439220104464702, -7.998448828756848],
              [-37.439165137315811, -7.998447716783216],
              [-37.438784403981082, -7.997569626130546],
              [-37.438504413008921, -7.997250269513998],
              [-37.438240020634851, -7.99702737176203],
              [-37.437811927417776, -7.996795807161575],
              [-37.437044465024677, -7.996471450198021],
              [-37.436856503575441, -7.995758838834899],
              [-37.436771508795886, -7.994686453312097],
              [-37.438015265038757, -7.99353719651296],
              [-37.40794447521246, -7.993422435232318],
              [-37.407803307536042, -7.993264828709703],
              [-37.407476558224438, -7.99294103520159],
              [-37.407059017459076, -7.992568425528973],
              [-37.406586712415802, -7.99214306941486],
              [-37.40610013499299, -7.991725976697916],
              [-37.405640924671189, -7.991339461263724],
              [-37.405284276785871, -7.991007413340814],
              [-37.404820599786049, -7.990603609517247],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0231000',
        uf: 'PE',
        nome_proje: 'PA MILHARAL',
        municipio: 'AGUA PRETA',
        area_hecta: '122.0407',
        capacidade: 13.0,
        num_famili: 12.0,
        fase: 5.0,
        data_de_cr: '15/08/2001',
        forma_obte: 'Dação',
        data_obten: '20/09/2000',
        area_calc_: 122.7434,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.470463287056717, -8.648007973886111],
              [-35.469097300820593, -8.648011909629888],
              [-35.469097300820593, -8.648011909629888],
              [-35.469061040896833, -8.648900049917003],
              [-35.469061040896833, -8.648900049917003],
              [-35.468623097184654, -8.651750954411877],
              [-35.468623097184654, -8.651750954411877],
              [-35.468392177818338, -8.653482168000053],
              [-35.468392177818338, -8.653482168000053],
              [-35.468241261656033, -8.654209463208225],
              [-35.468241261656033, -8.654209463208225],
              [-35.467247093303257, -8.65699401151177],
              [-35.467247093303257, -8.65699401151177],
              [-35.466516657715786, -8.659264809632079],
              [-35.466516657715786, -8.659264809632079],
              [-35.465790977141026, -8.661499609661703],
              [-35.465790977141026, -8.661499609661703],
              [-35.465220490177096, -8.663078209494973],
              [-35.465220490177096, -8.663078209494973],
              [-35.464706918958498, -8.664346555574717],
              [-35.464706918958498, -8.664346555574717],
              [-35.466769410547528, -8.671937251108446],
              [-35.466769410547528, -8.671937251108446],
              [-35.471982804707274, -8.667496535723886],
              [-35.471982804707274, -8.667496535723886],
              [-35.470626921448002, -8.666499741955224],
              [-35.470626921448002, -8.666499741955224],
              [-35.469997032459084, -8.665905192212712],
              [-35.469997032459084, -8.665905192212712],
              [-35.469937728999348, -8.665835718169031],
              [-35.469937728999348, -8.665835718169031],
              [-35.468430573263092, -8.664660864382075],
              [-35.468430573263092, -8.664660864382075],
              [-35.468614493218382, -8.66341073314023],
              [-35.468614493218382, -8.66341073314023],
              [-35.468919208346975, -8.661688802144727],
              [-35.468919208346975, -8.661688802144727],
              [-35.469173912708698, -8.660375043915225],
              [-35.469173912708698, -8.660375043915225],
              [-35.469505157425466, -8.658501930464508],
              [-35.469505157425466, -8.658501930464508],
              [-35.469810131058544, -8.656569341629888],
              [-35.469810131058544, -8.656569341629888],
              [-35.471511765186399, -8.656490475787418],
              [-35.471511765186399, -8.656490475787418],
              [-35.475170758439262, -8.656263106885953],
              [-35.475170758439262, -8.656263106885953],
              [-35.475306082723236, -8.656264670824251],
              [-35.475306082723236, -8.656264670824251],
              [-35.475421236157487, -8.656250821377899],
              [-35.475421236157487, -8.656250821377899],
              [-35.476349375430921, -8.656155523780745],
              [-35.476349375430921, -8.656155523780745],
              [-35.476386683471418, -8.655930798987818],
              [-35.476386683471418, -8.655930798987818],
              [-35.476423023613719, -8.652738908334618],
              [-35.476423023613719, -8.652738908334618],
              [-35.473559352533478, -8.65170460694487],
              [-35.473559352533478, -8.65170460694487],
              [-35.473104906981703, -8.651382658424296],
              [-35.473104906981703, -8.651382658424296],
              [-35.472037494063819, -8.650605219644584],
              [-35.472037494063819, -8.650605219644584],
              [-35.471762069803518, -8.650412428388286],
              [-35.471762069803518, -8.650412428388286],
              [-35.470855742682801, -8.648893435699449],
              [-35.470855742682801, -8.648893435699449],
              [-35.470463287056717, -8.648007973886111],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0147000',
        uf: 'PE',
        nome_proje: 'PA DOIS BRAÇOS',
        municipio: 'AGUA PRETA',
        area_hecta: '227.4000',
        capacidade: 27.0,
        num_famili: 27.0,
        fase: 5.0,
        data_de_cr: '14/10/1998',
        forma_obte: 'Desapropriação',
        data_obten: '01/10/1997',
        area_calc_: 243.3906,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.518012863116951, -8.560764084804134],
              [-35.516924548977713, -8.560734549409018],
              [-35.515953129036461, -8.560710240055791],
              [-35.515863238727043, -8.56070883716527],
              [-35.515863057156842, -8.560708838347361],
              [-35.514375277328341, -8.560644000858826],
              [-35.514375277328341, -8.560644000858826],
              [-35.514374825586771, -8.560643992374795],
              [-35.514374373980573, -8.560644006271938],
              [-35.51437392362822, -8.560644042515838],
              [-35.512220573868426, -8.560871161727681],
              [-35.512220210726852, -8.560871164088486],
              [-35.511461283922173, -8.560920377323914],
              [-35.511461283922173, -8.560920377323932],
              [-35.51146066630065, -8.560920438528418],
              [-35.511460054323692, -8.560920541593905],
              [-35.511459450851568, -8.560920686038656],
              [-35.511458858704799, -8.560920871187573],
              [-35.511458280650956, -8.560921096175308],
              [-35.510763263884016, -8.561219256581406],
              [-35.510763263884016, -8.561219256581406],
              [-35.510762771235363, -8.561219451158369],
              [-35.510762268018915, -8.561219616788186],
              [-35.510761755949737, -8.561219752906359],
              [-35.510761236773085, -8.561219859048954],
              [-35.510760712258424, -8.56121993485424],
              [-35.510760184193401, -8.561219980063827],
              [-35.507562456979812, -8.561400347318886],
              [-35.507561730693055, -8.561400352032031],
              [-35.507484421637642, -8.561398974799472],
              [-35.507484421637642, -8.56139897479949],
              [-35.507483903458414, -8.56139898035091],
              [-35.507483386441514, -8.561399015321438],
              [-35.507482872271346, -8.56139907959715],
              [-35.506378392854437, -8.561569158609357],
              [-35.506378211282502, -8.561569159787108],
              [-35.506196296345912, -8.561601155812237],
              [-35.504513446077766, -8.56182428256442],
              [-35.504511013788019, -8.561831568586403],
              [-35.504509644683687, -8.561824786580271],
              [-35.504238475817587, -8.561860739792028],
              [-35.503557906522921, -8.562054023199231],
              [-35.503570818815042, -8.563014933414552],
              [-35.503471463187275, -8.564661936739782],
              [-35.503340731983357, -8.567432932956603],
              [-35.50334073198335, -8.567432932956603],
              [-35.50334072204609, -8.567433388349855],
              [-35.503340735175421, -8.567433843663025],
              [-35.503340771337982, -8.567434297739275],
              [-35.503505185144249, -8.568997517213603],
              [-35.503505185144249, -8.568997517213603],
              [-35.503505222700518, -8.568997997754552],
              [-35.503505234462729, -8.568998479605066],
              [-35.503505220397429, -8.568998961394446],
              [-35.503505180544629, -8.568999441752091],
              [-35.503385058771464, -8.570091379802756],
              [-35.503032181263102, -8.573105969094836],
              [-35.503032184812902, -8.573106511291394],
              [-35.5030031440211, -8.573607710553052],
              [-35.503003145204417, -8.573607891285249],
              [-35.502985946570526, -8.574110983259812],
              [-35.502985946570526, -8.574110983259812],
              [-35.502985946643228, -8.574111599859474],
              [-35.502985988959949, -8.574112215018882],
              [-35.502986073323683, -8.574112825874089],
              [-35.502986199341649, -8.574113429581212],
              [-35.502986366427173, -8.57411402332961],
              [-35.502986573802374, -8.574114604355044],
              [-35.502986820501789, -8.574115169952467],
              [-35.502987105376874, -8.574115717488713],
              [-35.502987427101374, -8.574116244414659],
              [-35.502987784177471, -8.574116748277138],
              [-35.502988174942757, -8.574117226730381],
              [-35.502988597577982, -8.574117677546884],
              [-35.502989050115531, -8.574118098627833],
              [-35.502989530448552, -8.574118488012829],
              [-35.502990036340826, -8.574118843889082],
              [-35.502990565437102, -8.574119164599747],
              [-35.502991115274135, -8.574119448651711],
              [-35.502991683292088, -8.574119694722574],
              [-35.502992266846498, -8.57411990166672],
              [-35.50299286322057, -8.57412006852068],
              [-35.506698061812749, -8.575022139958483],
              [-35.508113053024417, -8.575369795308278],
              [-35.508113053024417, -8.57536979530826],
              [-35.50811362443379, -8.575369916428368],
              [-35.508114202486453, -8.575370000719017],
              [-35.508114784788894, -8.575370047831193],
              [-35.508115368929971, -8.575370057569819],
              [-35.508115952490954, -8.575370029894573],
              [-35.5081165330555, -8.575369964920057],
              [-35.508117108219672, -8.575369862915313],
              [-35.508117675601916, -8.57536972430268],
              [-35.508118232852873, -8.575369549656147],
              [-35.508118777665146, -8.575369339698826],
              [-35.508308191111261, -8.575289277689251],
              [-35.508308191111254, -8.575289277689251],
              [-35.508308776721229, -8.575289053602269],
              [-35.508309376481385, -8.575288870305121],
              [-35.508309987527703, -8.575288728673071],
              [-35.508310606942288, -8.575288629382491],
              [-35.508311231767266, -8.575288572907496],
              [-35.508311859018946, -8.575288559517752],
              [-35.508312485702035, -8.575288589277219],
              [-35.508313108823963, -8.5752886620438],
              [-35.50831372540916, -8.575288777469988],
              [-35.508314332513265, -8.575288935004611],
              [-35.510729458140915, -8.57600517191435],
              [-35.510730004062751, -8.576005349100136],
              [-35.513679408041135, -8.576677514270312],
              [-35.513679408041135, -8.576677514270296],
              [-35.513679935486905, -8.576677651183989],
              [-35.513680453727233, -8.576677819355702],
              [-35.513680960894085, -8.576678018179251],
              [-35.513681455159357, -8.576678246937952],
              [-35.513681934741449, -8.57667850480725],
              [-35.515871603673013, -8.577941821240644],
              [-35.515871603673013, -8.577941821240644],
              [-35.515872161695349, -8.577942117830981],
              [-35.515872739112851, -8.577942375025453],
              [-35.515873333122265, -8.577942591575432],
              [-35.515873940839803, -8.577942766429651],
              [-35.515874559315122, -8.577942898739181],
              [-35.515875185545632, -8.577942987861702],
              [-35.515875816491118, -8.577943033364539],
              [-35.515876449088466, -8.577943035026797],
              [-35.515877080266534, -8.577942992840395],
              [-35.515877706961085, -8.577942907010152],
              [-35.515878326129638, -8.577942777952737],
              [-35.515878934766263, -8.577942606294723],
              [-35.515879529916141, -8.577942392869456],
              [-35.515880108689949, -8.57794213871308],
              [-35.515880668277831, -8.577941845059463],
              [-35.515881205963126, -8.57794151333424],
              [-35.515881719135464, -8.577941145147891],
              [-35.515882205303498, -8.577940742287872],
              [-35.515882662106989, -8.577940306709968],
              [-35.515883087328234, -8.577939840528856],
              [-35.515883478902879, -8.577939346007742],
              [-35.515883834929902, -8.577938825547422],
              [-35.515884153680858, -8.577938281674612],
              [-35.515884433608292, -8.577937717029737],
              [-35.515884673353199, -8.577937134354025],
              [-35.515884871751666, -8.577936536476246],
              [-35.515885027840504, -8.577935926298979],
              [-35.515885140861947, -8.577935306784497],
              [-35.515885210267285, -8.577934680940444],
              [-35.5160489916289, -8.575768268036523],
              [-35.516049077658401, -8.575767544523005],
              [-35.516072668277793, -8.575613492167582],
              [-35.516402797121863, -8.573366572108926],
              [-35.516402882556633, -8.573365758230437],
              [-35.51646047309351, -8.572326963993509],
              [-35.51646047309351, -8.572326963993509],
              [-35.516460520811926, -8.572326411618704],
              [-35.516460602471831, -8.57232586319603],
              [-35.516488799353112, -8.572169886702879],
              [-35.51648879697413, -8.572169525242247],
              [-35.516568026776319, -8.571629302642226],
              [-35.516568026776319, -8.571629302642226],
              [-35.516568082939244, -8.5716288343542],
              [-35.516568114480428, -8.571628363787459],
              [-35.516568121313959, -8.57162789222369],
              [-35.516566898316192, -8.571523664440328],
              [-35.516566898316192, -8.571523664440328],
              [-35.51656690740041, -8.571523141209548],
              [-35.516566946897939, -8.571522619379463],
              [-35.516567016676341, -8.571522100699985],
              [-35.51704657138027, -8.568602999380971],
              [-35.517046569001764, -8.568602637920408],
              [-35.517524028975352, -8.564655099238854],
              [-35.517524027786429, -8.564654918508593],
              [-35.517673855880417, -8.563609818651956],
              [-35.517673854691566, -8.563609637921726],
              [-35.51802162748227, -8.560774213237437],
              [-35.51802162748227, -8.560774213237437],
              [-35.518021682067896, -8.560773588793403],
              [-35.51802169303452, -8.560772962085112],
              [-35.518021660329403, -8.56077233612768],
              [-35.51802158410986, -8.560771713932668],
              [-35.518021464742624, -8.560771098493479],
              [-35.518021302801948, -8.560770492771047],
              [-35.518021099066964, -8.560769899679544],
              [-35.518020854517843, -8.560769322072362],
              [-35.518020570331132, -8.560768762728413],
              [-35.518020247874077, -8.560768224338782],
              [-35.518019888698035, -8.560767709493637],
              [-35.518019494531032, -8.560767220669996],
              [-35.518019067269442, -8.560766760219584],
              [-35.518018608968838, -8.560766330357659],
              [-35.518018121834146, -8.560765933152348],
              [-35.518017608209007, -8.560765570514624],
              [-35.518017070564497, -8.560765244189154],
              [-35.518016511487282, -8.560764955745924],
              [-35.518015933667108, -8.560764706572645],
              [-35.518015339883917, -8.560764497868121],
              [-35.518014732994445, -8.560764330636424],
              [-35.518014115918469, -8.560764205682132],
              [-35.518013491624799, -8.560764123606415],
              [-35.518012863116951, -8.560764084804134],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0094000',
        uf: 'PE',
        nome_proje: 'PA PRIVILÉGIO',
        municipio: 'AGUA PRETA',
        area_hecta: '546.0000',
        capacidade: 56.0,
        num_famili: 55.0,
        fase: 5.0,
        data_de_cr: '30/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '17/09/1996',
        area_calc_: 552.9651,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.504789496389748, -8.706089719367048],
              [-35.504519355679236, -8.706725555272383],
              [-35.503767035626538, -8.70761114498403],
              [-35.50261563539118, -8.709264780436239],
              [-35.502560129497233, -8.70934733851627],
              [-35.501229403156657, -8.711320650116617],
              [-35.50103148192381, -8.711615498171206],
              [-35.500801298937041, -8.711939179224487],
              [-35.500626416508418, -8.71226029467177],
              [-35.500289483602728, -8.712724846146477],
              [-35.499482616539943, -8.713840491581861],
              [-35.498725400910779, -8.714880955765269],
              [-35.498077780157125, -8.715765119377249],
              [-35.497936788646427, -8.715970061116172],
              [-35.497266734283208, -8.716919685875792],
              [-35.497019918101067, -8.717294112423467],
              [-35.496026758363122, -8.718793324767736],
              [-35.495974166046835, -8.718870726413702],
              [-35.49544991677616, -8.719674820339989],
              [-35.495391487140104, -8.719761800598986],
              [-35.495211957878283, -8.720050656155909],
              [-35.494566464283317, -8.721035343978551],
              [-35.49407286586284, -8.721790800915063],
              [-35.493899040532725, -8.722050264223837],
              [-35.493489553122224, -8.722587576825141],
              [-35.493151698353643, -8.723026814360633],
              [-35.492933157967897, -8.723328401752417],
              [-35.492891564716196, -8.723396190778301],
              [-35.49235576105081, -8.724239254676775],
              [-35.49182621490624, -8.725080809617671],
              [-35.491599885441339, -8.72543161712885],
              [-35.491302256939001, -8.725931134152864],
              [-35.49144321372404, -8.726053498208932],
              [-35.491629342715726, -8.726202718589901],
              [-35.493869027860299, -8.727916256411969],
              [-35.495491654248205, -8.728898503405651],
              [-35.495803486281581, -8.729093861040882],
              [-35.497303161868771, -8.729721349338034],
              [-35.497944348033528, -8.729696577818807],
              [-35.498238167163649, -8.729734270805977],
              [-35.501233593076059, -8.730951815975889],
              [-35.501368209680571, -8.7311182484688],
              [-35.50151454920352, -8.731383491460445],
              [-35.50179376672407, -8.731775549466549],
              [-35.502075415225818, -8.731868358870354],
              [-35.502352653019585, -8.731963398736795],
              [-35.503082781820602, -8.73227927594386],
              [-35.503413133107273, -8.732540917569537],
              [-35.503557971379976, -8.732801949415268],
              [-35.503804425811744, -8.733257883871275],
              [-35.503900568248476, -8.733572075077054],
              [-35.504001402892456, -8.734036676683187],
              [-35.504411363303866, -8.73451097731958],
              [-35.504901003452197, -8.734877240021367],
              [-35.507173802058126, -8.736122246567167],
              [-35.507905611014849, -8.736246562846727],
              [-35.509160020657738, -8.736536204557673],
              [-35.509704428603705, -8.736653502024204],
              [-35.510167149106778, -8.73679885946258],
              [-35.510566225183453, -8.736912898638838],
              [-35.512721520485385, -8.737680901563783],
              [-35.513577732667123, -8.737987110391611],
              [-35.514677887648077, -8.73842709388258],
              [-35.516647561923655, -8.739231893787782],
              [-35.517846666814023, -8.739522603297074],
              [-35.518191867886038, -8.739631854108314],
              [-35.518331522326754, -8.739338852166012],
              [-35.518481569633366, -8.738616477225779],
              [-35.518673255207275, -8.738327899431249],
              [-35.518974258403468, -8.7379520001522],
              [-35.519101582241049, -8.737525519069544],
              [-35.51928606214328, -8.73731734599148],
              [-35.519527889775119, -8.736752139297714],
              [-35.519672350141519, -8.736515978995916],
              [-35.519674724760065, -8.736210680665275],
              [-35.519684765410368, -8.735619128972965],
              [-35.51974858386599, -8.735237101454691],
              [-35.519799349621231, -8.734998261924158],
              [-35.519787196626325, -8.734836161389051],
              [-35.519255411355992, -8.734318845037452],
              [-35.519146268729081, -8.734047678123444],
              [-35.51923791030466, -8.733744721736144],
              [-35.519082941698272, -8.7334045102636],
              [-35.519029934183763, -8.732979961842132],
              [-35.518799261188185, -8.732569803304211],
              [-35.518403969121913, -8.732249534366998],
              [-35.518023903584016, -8.731670113480169],
              [-35.518402791987754, -8.730865128327741],
              [-35.518482025193144, -8.729977006714046],
              [-35.518336844052932, -8.729556005396727],
              [-35.518228928723282, -8.728972574886853],
              [-35.518133569703508, -8.72872149642971],
              [-35.518149497069963, -8.728511875545802],
              [-35.517118288370867, -8.726634184576621],
              [-35.517002869320223, -8.726333703411109],
              [-35.51690568319291, -8.726139876935445],
              [-35.516487592817484, -8.725883233247686],
              [-35.516435572499311, -8.725109728928683],
              [-35.516425762985989, -8.724966692399438],
              [-35.516353760418376, -8.724513281016675],
              [-35.516120506382201, -8.724156706953917],
              [-35.515731965934336, -8.723686313953978],
              [-35.515538737606121, -8.723358828039819],
              [-35.514867713916573, -8.722394220024377],
              [-35.514082869110183, -8.72128395958233],
              [-35.513607330636454, -8.720597651520713],
              [-35.513362302370446, -8.720134009271993],
              [-35.512533532319395, -8.718519874246283],
              [-35.511721038495971, -8.716911865456003],
              [-35.511439292941169, -8.716361134409015],
              [-35.510635689817882, -8.715530584110535],
              [-35.509881747008379, -8.71506736590273],
              [-35.509836314695271, -8.714669914972553],
              [-35.509321221600416, -8.714850912084398],
              [-35.508510306542014, -8.71480269700197],
              [-35.508407576111516, -8.71466100682369],
              [-35.507625924191437, -8.713913228386716],
              [-35.506787979534522, -8.712797421225345],
              [-35.50642483801478, -8.712316203414183],
              [-35.506274466680061, -8.712108779105124],
              [-35.505646260776665, -8.711253571898752],
              [-35.505213779088784, -8.710659794735735],
              [-35.505147234049375, -8.710413656186416],
              [-35.504939285466051, -8.709091141711298],
              [-35.504963887471192, -8.708908982019437],
              [-35.505089017415735, -8.708095039302512],
              [-35.505034126705965, -8.706829490738876],
              [-35.504789496389748, -8.706089719367048],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0274000',
        uf: 'PE',
        nome_proje: 'PA MASCATE',
        municipio: 'BARREIROS',
        area_hecta: '641.9042',
        capacidade: 74.0,
        num_famili: 66.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 641.8902,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.178294964796393, -8.755258466031867],
              [-35.174898111450581, -8.757283023976049],
              [-35.174535697568921, -8.757589459930482],
              [-35.173584344194204, -8.757730587307758],
              [-35.174224890955024, -8.758404186290544],
              [-35.173078071450064, -8.759660600795765],
              [-35.172047913521503, -8.760749103698503],
              [-35.171196468018429, -8.761629151544412],
              [-35.170748798605302, -8.761263230174587],
              [-35.170041288597936, -8.762328210166904],
              [-35.169486505446663, -8.76315590669329],
              [-35.168847417781237, -8.764119446068351],
              [-35.168717178335442, -8.764318238403384],
              [-35.168413807675442, -8.764967118760318],
              [-35.168417296468597, -8.765017211029116],
              [-35.168484868188472, -8.765732966048455],
              [-35.168750091937135, -8.76611090113285],
              [-35.169181926687074, -8.766415187436337],
              [-35.17036959452404, -8.767054554330292],
              [-35.17115283755605, -8.767661278794275],
              [-35.171744977858459, -8.768606118858679],
              [-35.171932989273557, -8.769381975031177],
              [-35.171964197807441, -8.770407079180943],
              [-35.171960580107843, -8.770514691096299],
              [-35.172127405844954, -8.771067197718125],
              [-35.173318682050905, -8.77287477291722],
              [-35.173541754401214, -8.773865932311077],
              [-35.173547469833807, -8.774190733217095],
              [-35.173561452730937, -8.775302194055207],
              [-35.173597198855703, -8.775445970056184],
              [-35.173719375180575, -8.776201946256947],
              [-35.173809976338177, -8.776437992411049],
              [-35.174011273813271, -8.777037346032445],
              [-35.175145242408874, -8.779860377979627],
              [-35.175399677687679, -8.780351425769734],
              [-35.175412925871889, -8.780398243515354],
              [-35.17563865545965, -8.780751657529571],
              [-35.176023404708431, -8.781199930616642],
              [-35.176142520656093, -8.781293917358012],
              [-35.176775420678588, -8.78181866404228],
              [-35.177454200468468, -8.782184745656382],
              [-35.17904979947523, -8.783148733366284],
              [-35.179465296519105, -8.783402929273239],
              [-35.180257227168966, -8.783749187801263],
              [-35.180550390093224, -8.783822162949603],
              [-35.181301060206472, -8.783998924430838],
              [-35.181986069441031, -8.784080414363553],
              [-35.182480580157289, -8.784094725832773],
              [-35.183083981459475, -8.784337190220192],
              [-35.183458532059504, -8.784610482070635],
              [-35.18368476789486, -8.78493845935739],
              [-35.183847664047583, -8.785443577459187],
              [-35.183955820958495, -8.786907119439894],
              [-35.184145671625835, -8.787575513449429],
              [-35.184730621447784, -8.787313311623228],
              [-35.185296908508143, -8.786817263267379],
              [-35.185958417911436, -8.786305395435773],
              [-35.186694331459641, -8.785644305903913],
              [-35.186754511729845, -8.785576282535486],
              [-35.187794224402147, -8.784643871145288],
              [-35.187942995704482, -8.784496713222685],
              [-35.189780673099179, -8.78338308241668],
              [-35.190642994856681, -8.782838650835766],
              [-35.191464121645787, -8.7823167471504],
              [-35.191571679598106, -8.782243078893536],
              [-35.19203007741077, -8.781996184077828],
              [-35.193546575171553, -8.780984810990748],
              [-35.19479347113689, -8.780193607381335],
              [-35.195607559362699, -8.779371693704835],
              [-35.196036356861661, -8.778860753201604],
              [-35.196600587329669, -8.778274950543041],
              [-35.197077589531837, -8.777786506659163],
              [-35.197304518671451, -8.777618653371354],
              [-35.198421654058464, -8.776440214959884],
              [-35.199656202824656, -8.773137562285589],
              [-35.199705619540843, -8.773024560097356],
              [-35.197574423414856, -8.771136268567894],
              [-35.196514115245201, -8.770443808397651],
              [-35.195981954954526, -8.77029632573835],
              [-35.194633818689645, -8.769930570184359],
              [-35.19385488658142, -8.769712306903251],
              [-35.193391312131737, -8.769492689125123],
              [-35.192385211927167, -8.76893804958028],
              [-35.191819959643183, -8.768616464625007],
              [-35.190697697923873, -8.767963888396467],
              [-35.188922857951724, -8.766812916655518],
              [-35.188881609828471, -8.766737228991902],
              [-35.188744289868438, -8.765271500120052],
              [-35.188965258499117, -8.764452010038593],
              [-35.189725587677877, -8.761344724514212],
              [-35.188836534582229, -8.760157083586483],
              [-35.186685710106843, -8.760111520624502],
              [-35.185175103856153, -8.759288070374859],
              [-35.182133708597767, -8.760390419715229],
              [-35.179921311487405, -8.759565681921677],
              [-35.179780543424748, -8.759451563429717],
              [-35.178530534115616, -8.755413709107067],
              [-35.178294964796393, -8.755258466031867],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0066000',
        uf: 'PE',
        nome_proje: 'PA CAMURIM GRANDE, CONSTITUINTE E VOLTA DO UNA',
        municipio: 'AGUA PRETA',
        area_hecta: '1768.8000',
        capacidade: 158.0,
        num_famili: 157.0,
        fase: 5.0,
        data_de_cr: '31/08/1995',
        forma_obte: 'Desapropriação',
        data_obten: '24/11/1993',
        area_calc_: 1910.8668,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.452305522562426, -8.670866183098767],
              [-35.449281605562668, -8.671051975698779],
              [-35.449067214562682, -8.671085433798789],
              [-35.442765638063193, -8.672685665098808],
              [-35.442463712063223, -8.672750136198809],
              [-35.438507075363532, -8.673507377698821],
              [-35.438293001163544, -8.673548329398814],
              [-35.432279647264025, -8.674696322798834],
              [-35.432066037664029, -8.674739256698839],
              [-35.422798523164758, -8.676693562298867],
              [-35.423413325864708, -8.677319661098862],
              [-35.423490677964701, -8.67734339019886],
              [-35.42359538246469, -8.677454337998869],
              [-35.423800834064679, -8.677852037998855],
              [-35.424036415164665, -8.67818935659886],
              [-35.424274281964649, -8.678472344998855],
              [-35.424470166864637, -8.678624646798864],
              [-35.425196312364577, -8.679602224098856],
              [-35.425294265164567, -8.679821302398857],
              [-35.425268622564573, -8.679844872598851],
              [-35.426413735164488, -8.68121679759885],
              [-35.426489866364477, -8.681304789998862],
              [-35.426549598964478, -8.681413221098857],
              [-35.426591799164477, -8.681521131098849],
              [-35.426564560164472, -8.681762605098857],
              [-35.426575643964483, -8.681918521398856],
              [-35.426613085764473, -8.682091531498859],
              [-35.426611866664473, -8.682269216098847],
              [-35.426631432264479, -8.682472976998845],
              [-35.426667000364475, -8.682721823398857],
              [-35.42666410676447, -8.683189983098845],
              [-35.426659338864475, -8.683310121498858],
              [-35.426629535964473, -8.683378815298854],
              [-35.42656667276448, -8.683433349998861],
              [-35.426498091064488, -8.683460175998853],
              [-35.42658191816448, -8.683685579298858],
              [-35.426641615664472, -8.68390146599886],
              [-35.42667694496447, -8.684098981098861],
              [-35.426678827064471, -8.684208051398857],
              [-35.426675991964473, -8.684346252398846],
              [-35.426673524464476, -8.68451337109885],
              [-35.426700479664476, -8.684764531598857],
              [-35.426721623964468, -8.68493122879886],
              [-35.42672368166447, -8.685067591198857],
              [-35.426691534564476, -8.685266078098849],
              [-35.426665855764483, -8.685397013898843],
              [-35.426668403764474, -8.685524877898864],
              [-35.426729847864472, -8.685743916498852],
              [-35.426783021964468, -8.68586332439885],
              [-35.426881177064459, -8.686042725798858],
              [-35.426810437664464, -8.686101287298861],
              [-35.426720629864477, -8.688038499498857],
              [-35.426696946664478, -8.68825374229885],
              [-35.426524984564495, -8.68923748219885],
              [-35.426572555064489, -8.68930459869885],
              [-35.426509563164494, -8.689536901598851],
              [-35.42642579586451, -8.689617597598861],
              [-35.426314037064515, -8.689766252898862],
              [-35.426211847564517, -8.689877612898854],
              [-35.426121339164524, -8.689983475998851],
              [-35.426071366164528, -8.690079410798853],
              [-35.426019640664535, -8.690185297998859],
              [-35.425896530864541, -8.690362584098864],
              [-35.425750506364558, -8.69053576839886],
              [-35.425592616264574, -8.690685801998864],
              [-35.425183953064604, -8.69103336509886],
              [-35.424978202764613, -8.691254918698858],
              [-35.424856701564629, -8.691414119398848],
              [-35.424778617064632, -8.69153120009886],
              [-35.424724153764643, -8.691663403798856],
              [-35.424669744064651, -8.691846307398864],
              [-35.42458803136465, -8.69204890579886],
              [-35.424451853564655, -8.692270468298862],
              [-35.42419292026468, -8.692569269298858],
              [-35.424029086764683, -8.692713375698867],
              [-35.42580669896455, -8.693651081098853],
              [-35.425908760764543, -8.693689111198854],
              [-35.431565134164117, -8.695793005098841],
              [-35.431723200864106, -8.695923941998833],
              [-35.432608455264038, -8.697395363598838],
              [-35.432726435564035, -8.697577527098838],
              [-35.434352540063912, -8.699913966598826],
              [-35.434468532263907, -8.700097406998818],
              [-35.435544940063821, -8.701980331198833],
              [-35.435650981463809, -8.702169799398829],
              [-35.436918550963711, -8.704480497498814],
              [-35.43703960546371, -8.704659746698823],
              [-35.437212182363702, -8.704870477398828],
              [-35.437336766463687, -8.705047715598822],
              [-35.43972880226351, -8.709049582898819],
              [-35.439850859063505, -8.709228824098815],
              [-35.440482355263448, -8.710048255598808],
              [-35.440608845663441, -8.710224756598812],
              [-35.442448853163306, -8.712990388598797],
              [-35.442563588063294, -8.713174645698803],
              [-35.444217854863176, -8.716020532298796],
              [-35.444336027963161, -8.716202416598803],
              [-35.444978813563111, -8.717092072398803],
              [-35.445113263063099, -8.71726255479879],
              [-35.445835674863048, -8.718104610898793],
              [-35.445997372863033, -8.718246901498798],
              [-35.446389355863005, -8.718504922498793],
              [-35.446494722662997, -8.7184773123988],
              [-35.448997399962799, -8.717821065198793],
              [-35.449210095662778, -8.717774416398782],
              [-35.449595957062741, -8.717706226098787],
              [-35.449703286462736, -8.717687187998788],
              [-35.453484745562434, -8.716667406898765],
              [-35.455556705362262, -8.716114128398761],
              [-35.455662068362258, -8.716086244998769],
              [-35.456618950162181, -8.71583333929876],
              [-35.459992613961901, -8.71489611999875],
              [-35.460097604361884, -8.714866972698738],
              [-35.464215814161555, -8.713880706198744],
              [-35.468427850961206, -8.712871920998726],
              [-35.469735924161093, -8.712547028098722],
              [-35.469539262061105, -8.712392143898715],
              [-35.469203864261132, -8.711982047098719],
              [-35.469275871961123, -8.711536316898723],
              [-35.469279195061127, -8.711295363798719],
              [-35.469495722261115, -8.710726873198713],
              [-35.469611008861101, -8.709609940998725],
              [-35.470343467761033, -8.708049881598713],
              [-35.470261303961031, -8.70787536529871],
              [-35.469615075161087, -8.707198612098715],
              [-35.469413942661113, -8.707164763998726],
              [-35.467053737261296, -8.705812758498723],
              [-35.466881584561314, -8.70577971479873],
              [-35.466184223561378, -8.705005050298727],
              [-35.465948837261386, -8.704442293198735],
              [-35.465799872361401, -8.704260707398733],
              [-35.465717494361414, -8.703942047098741],
              [-35.465749918861405, -8.702679789798735],
              [-35.465597954461408, -8.702289644198736],
              [-35.465570526961407, -8.702137364098737],
              [-35.465658241861405, -8.701759220698731],
              [-35.466352536961338, -8.700458241298733],
              [-35.466609046061322, -8.700252698898735],
              [-35.466774343561312, -8.699599953998733],
              [-35.466652095761319, -8.697880422998731],
              [-35.466533986561323, -8.697333804598729],
              [-35.466512554361323, -8.697209320298732],
              [-35.466494469361322, -8.697166058898736],
              [-35.466532787261322, -8.696900839498728],
              [-35.466607492961316, -8.696686083098726],
              [-35.466776140361304, -8.696420199798732],
              [-35.468571459661149, -8.695030839198726],
              [-35.469074050261113, -8.694526738298714],
              [-35.469364032961082, -8.69437122509872],
              [-35.470046766561019, -8.694233678298714],
              [-35.470579294260979, -8.694306767898711],
              [-35.472269090160843, -8.6947515431987],
              [-35.473016264560776, -8.694856672498705],
              [-35.473440558160739, -8.695183139998699],
              [-35.473669666060729, -8.695287476498708],
              [-35.474089756560694, -8.695374756598708],
              [-35.474357811460671, -8.695407355498704],
              [-35.474674283860644, -8.695358214698713],
              [-35.474910074060631, -8.695249139498697],
              [-35.475203345560601, -8.695055554598694],
              [-35.476093724860519, -8.694258290598691],
              [-35.476405409660494, -8.694282470998697],
              [-35.477365094260414, -8.694841229498699],
              [-35.477594763360393, -8.695058523998696],
              [-35.478010166160367, -8.69578665349869],
              [-35.47845713936033, -8.696343232498682],
              [-35.478741147560299, -8.696522848598685],
              [-35.478972780260278, -8.69649992209869],
              [-35.47953169716024, -8.696083465398697],
              [-35.479745325560216, -8.695629494398688],
              [-35.479788873060215, -8.695056256898679],
              [-35.479569743260228, -8.694080318898688],
              [-35.478838125060285, -8.69273547769869],
              [-35.477936181860365, -8.691882368098691],
              [-35.477279238760417, -8.6912713030987],
              [-35.47720898896042, -8.691029654998689],
              [-35.477213308860421, -8.690857740398689],
              [-35.4773912138604, -8.690661561598699],
              [-35.478152234560341, -8.690221653198694],
              [-35.478268137260329, -8.690195956498691],
              [-35.479014750160275, -8.690272612798687],
              [-35.479674805360212, -8.690403963498692],
              [-35.479988236860187, -8.690583840098677],
              [-35.48029742796016, -8.690878425098681],
              [-35.482158809660014, -8.69301207029868],
              [-35.482224730460004, -8.69280261209868],
              [-35.482257199459994, -8.692699106298672],
              [-35.482257199459994, -8.692699106298672],
              [-35.482275114059988, -8.692592170898688],
              [-35.482605518159964, -8.690618119598669],
              [-35.482623432159961, -8.690511093798673],
              [-35.482595586059958, -8.690406264098675],
              [-35.48251865785997, -8.690116312798683],
              [-35.482480474259965, -8.689903556998685],
              [-35.482219244759989, -8.687167375698669],
              [-35.48219076585999, -8.686952477898684],
              [-35.482128218659987, -8.686584171098691],
              [-35.482120265359995, -8.686369229998689],
              [-35.48213384556, -8.686224638098675],
              [-35.482135784259988, -8.686008547998689],
              [-35.482055396859998, -8.684957693698674],
              [-35.482048062859995, -8.684850651598675],
              [-35.481986965260006, -8.684785439998681],
              [-35.481965489460002, -8.684696202798683],
              [-35.481992945360005, -8.68442427789868],
              [-35.481992821160006, -8.683991129798686],
              [-35.481928009560008, -8.683609733598688],
              [-35.481910786160007, -8.683269417598678],
              [-35.481909336960008, -8.683159535698673],
              [-35.481930548460006, -8.683098125898679],
              [-35.477965759960341, -8.684716536198692],
              [-35.477756389660357, -8.684767782398698],
              [-35.473676439060696, -8.685146464398704],
              [-35.47284687916077, -8.685480168698712],
              [-35.472509793460809, -8.685528716598716],
              [-35.472509793460809, -8.685528716598716],
              [-35.472341930760813, -8.685552850498716],
              [-35.471963700760853, -8.685628866798709],
              [-35.471917946760847, -8.685631965098722],
              [-35.469873459261017, -8.683371734198722],
              [-35.469727815161029, -8.68321036989872],
              [-35.468196446361155, -8.681512256598721],
              [-35.467975848361171, -8.681277180898734],
              [-35.466604651661278, -8.679807384698734],
              [-35.466459190961295, -8.679645836398731],
              [-35.462765454661586, -8.675391749098742],
              [-35.460933416961737, -8.676770184898743],
              [-35.460749895561761, -8.676885870898754],
              [-35.454228749062288, -8.680278371598769],
              [-35.454036415962307, -8.680380465798772],
              [-35.450509488262597, -8.682292310398781],
              [-35.45032018436261, -8.682399715698779],
              [-35.448689743962738, -8.683367245698786],
              [-35.448471718862763, -8.682477564898779],
              [-35.448455834462763, -8.682339034198783],
              [-35.448444884362758, -8.682247104498787],
              [-35.44844056066276, -8.682210621398795],
              [-35.448445134062766, -8.681865546598788],
              [-35.44847070686275, -8.681607456798783],
              [-35.448501403162751, -8.681411149398787],
              [-35.448502118162757, -8.68125534119878],
              [-35.448491869762755, -8.681005524698779],
              [-35.448574390762744, -8.680817831498793],
              [-35.448597979462747, -8.680743935398793],
              [-35.448601979862737, -8.680618561798777],
              [-35.448585040362737, -8.680107247798798],
              [-35.448611672062746, -8.679858369298785],
              [-35.448653945162739, -8.679682592798782],
              [-35.448884685762721, -8.678934446498786],
              [-35.448943743562708, -8.678714008198781],
              [-35.448929718562709, -8.678455991998778],
              [-35.448949269862709, -8.678207701298788],
              [-35.449114639662696, -8.677911028198794],
              [-35.450461643062582, -8.674387218498785],
              [-35.450658287562561, -8.673927853598782],
              [-35.450803320662544, -8.673604651198779],
              [-35.450949802862539, -8.673210857898772],
              [-35.451138264062529, -8.672890178098784],
              [-35.451336030662503, -8.672645984598772],
              [-35.451587812962494, -8.672398371498785],
              [-35.451724644662477, -8.672127095898778],
              [-35.451933543862452, -8.671821105898776],
              [-35.452066266262449, -8.671448458098773],
              [-35.452173148962437, -8.671236569398776],
              [-35.452305522562426, -8.670866183098767],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0092000',
        uf: 'PE',
        nome_proje: 'PA OURIVES/PALMEIRA',
        municipio: 'AGUA PRETA',
        area_hecta: '461.7000',
        capacidade: 50.0,
        num_famili: 50.0,
        fase: 5.0,
        data_de_cr: '17/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '02/07/1996',
        area_calc_: 468.8704,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.536967873065024, -8.699928567373854],
              [-35.534883678882252, -8.700301489534244],
              [-35.533008646102076, -8.703470242523952],
              [-35.530346356410483, -8.702818703417092],
              [-35.526597722690177, -8.702840281867784],
              [-35.524796661031196, -8.702859472176833],
              [-35.522859283730426, -8.705482521396178],
              [-35.522435788695681, -8.706257075128729],
              [-35.522988308291893, -8.706710219516228],
              [-35.523549606637054, -8.706467741506348],
              [-35.523815032228704, -8.707069547646329],
              [-35.523686804673986, -8.707166640919652],
              [-35.524432755013969, -8.708536456186673],
              [-35.524432030880263, -8.708536822468247],
              [-35.524425831812756, -8.710888600148071],
              [-35.523401798377527, -8.713232500471632],
              [-35.523328743341771, -8.713254854810188],
              [-35.522440324319184, -8.711797153163204],
              [-35.519586372876503, -8.712236935139419],
              [-35.517680470650646, -8.713359023173755],
              [-35.517707223043814, -8.714180385174535],
              [-35.518133740601073, -8.716146775345218],
              [-35.516278489405323, -8.717294914525931],
              [-35.515498471338923, -8.718787820242291],
              [-35.515795442193223, -8.719298422714893],
              [-35.515624198624721, -8.720460070248491],
              [-35.513828114854, -8.720600112219769],
              [-35.513643165338515, -8.72078650245529],
              [-35.518182301008324, -8.728689129226463],
              [-35.519561833422024, -8.727284499856328],
              [-35.520417976417875, -8.726823175231237],
              [-35.521768341675021, -8.727501901153616],
              [-35.522499049746735, -8.727568252094715],
              [-35.526519754779009, -8.723842284385972],
              [-35.527692504099605, -8.72235542514631],
              [-35.530232669832365, -8.720994641488938],
              [-35.533035029275652, -8.720617552539824],
              [-35.532953315041127, -8.716686845593664],
              [-35.538673405772919, -8.71105248909331],
              [-35.539968737364511, -8.708457168959157],
              [-35.542895276529187, -8.704875523361158],
              [-35.541350464876928, -8.703809220685221],
              [-35.540803185889217, -8.702990086971397],
              [-35.539333936028392, -8.702271812985598],
              [-35.536967873065024, -8.699928567373854],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0277000',
        uf: 'PE',
        nome_proje: 'PA UNA',
        municipio: 'BARREIROS',
        area_hecta: '666.1257',
        capacidade: 60.0,
        num_famili: 59.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 634.6798,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.178268609887844, -8.79004173676913],
              [-35.176969203295734, -8.790363757820961],
              [-35.174960903168348, -8.790854043020445],
              [-35.173661481923993, -8.791171850930168],
              [-35.171832380708146, -8.791570968680951],
              [-35.169344293362144, -8.792223422949785],
              [-35.168569134809381, -8.792412029171748],
              [-35.165916228286719, -8.793057105512217],
              [-35.165473673250936, -8.793164132354757],
              [-35.158759688623213, -8.794761091088812],
              [-35.158186968642497, -8.794901107865057],
              [-35.155124355851953, -8.795633856680382],
              [-35.153338746689137, -8.79605042508677],
              [-35.152245564371206, -8.796307275586866],
              [-35.151984554696988, -8.796383032778579],
              [-35.152410990570139, -8.79810788400443],
              [-35.152694007421211, -8.799275299963394],
              [-35.153789737325127, -8.800345323725088],
              [-35.15436594826145, -8.802052110638103],
              [-35.154633425709108, -8.802835884912946],
              [-35.154973203148522, -8.803213489563655],
              [-35.155036407113322, -8.803284771210187],
              [-35.155081705077656, -8.803335421339277],
              [-35.157312172129835, -8.805829400634011],
              [-35.157357470731732, -8.805880050333514],
              [-35.160449908484637, -8.807195078255999],
              [-35.16123218366932, -8.807543177787528],
              [-35.16442411613415, -8.808941514911226],
              [-35.164471705718483, -8.808962362875828],
              [-35.165260114096142, -8.809986842390357],
              [-35.165798712223513, -8.810237283964065],
              [-35.1661314417548, -8.810526694024347],
              [-35.167114532602959, -8.811061559951957],
              [-35.168326583600198, -8.81171452500695],
              [-35.16933869980268, -8.811603741978312],
              [-35.171249020874129, -8.811290258013974],
              [-35.173085686241947, -8.810910031390202],
              [-35.17252722354722, -8.809564916333015],
              [-35.171788243382331, -8.807865004783437],
              [-35.173114110391154, -8.806840677351845],
              [-35.174285994271962, -8.806910183278637],
              [-35.175475343103301, -8.807010804958329],
              [-35.176857122225407, -8.808058024308114],
              [-35.177478272729878, -8.808747154161582],
              [-35.178194091557323, -8.809431151182602],
              [-35.178872916300541, -8.809919125350696],
              [-35.180006904754599, -8.810483196563258],
              [-35.181533533527713, -8.811103701704383],
              [-35.181724540540408, -8.810700451629454],
              [-35.182065783657173, -8.810131550047533],
              [-35.183448652849904, -8.81068971755999],
              [-35.184294738515455, -8.808540502903327],
              [-35.184509251510939, -8.808083424746165],
              [-35.184535883842209, -8.807945426748594],
              [-35.18468428449826, -8.807783193022216],
              [-35.185072625689166, -8.807028294428871],
              [-35.185497064804771, -8.806356416183917],
              [-35.186143005290752, -8.805502092823506],
              [-35.186699346675915, -8.804766889218939],
              [-35.187026096148216, -8.804336085132045],
              [-35.187239107544436, -8.804055734740585],
              [-35.187844815122205, -8.803232656594725],
              [-35.189343291497636, -8.801292737677191],
              [-35.189528861094132, -8.800974348149781],
              [-35.1896546146981, -8.800849512641825],
              [-35.189751653856625, -8.80070918887326],
              [-35.189937950714402, -8.800253440323722],
              [-35.190022975133402, -8.799618380407672],
              [-35.190176375992699, -8.796731619313919],
              [-35.190049451909324, -8.796185722672236],
              [-35.189827341280584, -8.79582454663233],
              [-35.189630961457588, -8.795651482464939],
              [-35.189332005702461, -8.795421690039278],
              [-35.188430571769317, -8.795012642202908],
              [-35.188013357297194, -8.794761616389025],
              [-35.187615721131948, -8.794333494778552],
              [-35.187312729187177, -8.793832141065764],
              [-35.186779005401377, -8.793027796149103],
              [-35.186028815548966, -8.791853297874924],
              [-35.185909751893682, -8.79153835041685],
              [-35.183873143464304, -8.792958606849673],
              [-35.183033482890842, -8.793258866539116],
              [-35.181735652690747, -8.793741925015413],
              [-35.181286803030453, -8.793684958561808],
              [-35.180719234735285, -8.793992586353834],
              [-35.180387687115939, -8.793608453346598],
              [-35.180085406014584, -8.793505080532601],
              [-35.179818388711368, -8.793573875841654],
              [-35.179634029991867, -8.793886153483832],
              [-35.179627172803123, -8.795245920359564],
              [-35.179412224736083, -8.795703721299482],
              [-35.179357853867039, -8.795822446092441],
              [-35.178803691310414, -8.796123667250249],
              [-35.17871420682453, -8.796173628099444],
              [-35.177608746227165, -8.795898731610526],
              [-35.177055295013382, -8.795886656412959],
              [-35.176698280080174, -8.795902550201918],
              [-35.17533397032372, -8.792239532341178],
              [-35.175229494588187, -8.791978367356094],
              [-35.175828663204989, -8.791619766000453],
              [-35.178717508060501, -8.790645663198227],
              [-35.178493276152068, -8.790323365739384],
              [-35.178268609887844, -8.79004173676913],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0278000',
        uf: 'PE',
        nome_proje: 'PA ILHETAS',
        municipio: 'TAMANDARE',
        area_hecta: '1509.0000',
        capacidade: 145.0,
        num_famili: 135.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 1546.653,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.153642148427771, -8.755680097432405],
              [-35.152232849497437, -8.757718457875406],
              [-35.151488160688146, -8.758785587267745],
              [-35.151435264714891, -8.758861862033966],
              [-35.149882705571429, -8.761159942012029],
              [-35.147615564728447, -8.764364856241921],
              [-35.147425525372498, -8.764674369721975],
              [-35.147106969609617, -8.765119622087521],
              [-35.145748441110307, -8.766437365404654],
              [-35.144022088271718, -8.768153443502744],
              [-35.141546455804189, -8.770589271688676],
              [-35.142500980223843, -8.783891979173928],
              [-35.127987005059595, -8.79782505080329],
              [-35.130555944311432, -8.800004237542668],
              [-35.136819326308711, -8.802481943815964],
              [-35.141081264837005, -8.803338588761184],
              [-35.143387584483555, -8.800561684303466],
              [-35.144323886035089, -8.799869393999534],
              [-35.145685726443794, -8.799107642225421],
              [-35.147524722374541, -8.797410833925074],
              [-35.149228944915016, -8.79703112815166],
              [-35.149523959687656, -8.79695703149102],
              [-35.149611696198313, -8.796935424211714],
              [-35.151700475364279, -8.79645930685119],
              [-35.151984462780163, -8.796383011736783],
              [-35.152245472454403, -8.79630725454513],
              [-35.153338654772405, -8.796050404045303],
              [-35.155124263935328, -8.79563383563935],
              [-35.158186876716989, -8.794901086824821],
              [-35.158759596706773, -8.794761070039623],
              [-35.165473581334936, -8.793164111307222],
              [-35.165916136361709, -8.793057084473864],
              [-35.168569042884549, -8.792412008134043],
              [-35.169344201446407, -8.792223401903174],
              [-35.171832288792636, -8.791570947643983],
              [-35.173661389999502, -8.791171829893699],
              [-35.174960811253037, -8.790854021984243],
              [-35.176969111380508, -8.790363736776204],
              [-35.178268517963666, -8.790041715733778],
              [-35.178617659331664, -8.789962305462508],
              [-35.18069703789741, -8.789454833420139],
              [-35.184070207119426, -8.788649307139947],
              [-35.184287643333256, -8.788578899067835],
              [-35.183816222384401, -8.787746490035804],
              [-35.183812383020978, -8.787697557521144],
              [-35.183595973034265, -8.786970184513409],
              [-35.183593706921179, -8.786868699648002],
              [-35.183524616517509, -8.785619795028866],
              [-35.183488282204983, -8.785513084148896],
              [-35.183354880095919, -8.785099413437406],
              [-35.183187835356257, -8.784841668987779],
              [-35.182870415089162, -8.784601716054544],
              [-35.182408347894189, -8.784451687803459],
              [-35.181418063816487, -8.784337852509355],
              [-35.181243362827203, -8.784357067792822],
              [-35.180140620911658, -8.784093227683359],
              [-35.179269641612187, -8.783641842112859],
              [-35.178980842696028, -8.783595722463851],
              [-35.178972653241374, -8.783487926148418],
              [-35.177156498168301, -8.782435048400755],
              [-35.176565663354573, -8.782115407021161],
              [-35.175929070342114, -8.781540189706865],
              [-35.175677524887718, -8.781337848588244],
              [-35.175509778883956, -8.781103595343822],
              [-35.175102384369218, -8.780589377308662],
              [-35.174813030887826, -8.780007969172672],
              [-35.17367062746235, -8.777163905454488],
              [-35.173366338405209, -8.776293062478967],
              [-35.173198077730653, -8.775471583791312],
              [-35.173200599794292, -8.774947841649023],
              [-35.173134134350065, -8.774040611185017],
              [-35.173144028139824, -8.773834284640332],
              [-35.172978399479518, -8.773018041881924],
              [-35.171798963683614, -8.77122147902544],
              [-35.171598356735274, -8.770572159995712],
              [-35.171570526401489, -8.769429321346079],
              [-35.171405370116844, -8.768747808979517],
              [-35.170878518980651, -8.767907147043898],
              [-35.170170035669102, -8.767358321159833],
              [-35.168989640361787, -8.766722880152622],
              [-35.168488276351361, -8.766369594643558],
              [-35.16813173752994, -8.765861551653209],
              [-35.168014231669225, -8.76520098595943],
              [-35.168042815992216, -8.764903221392572],
              [-35.168305729126665, -8.76433297778347],
              [-35.168397396784179, -8.764144801772346],
              [-35.169180803382524, -8.762972266030859],
              [-35.169563748046137, -8.762386319424349],
              [-35.17046661681686, -8.761034965265695],
              [-35.168724859549286, -8.759676402753565],
              [-35.165826843593834, -8.760526958972811],
              [-35.163605735132855, -8.76078131118434],
              [-35.162136794057581, -8.760012665992351],
              [-35.161333557870002, -8.75957945294892],
              [-35.160197847214292, -8.75785496425131],
              [-35.158873744814635, -8.755872268352933],
              [-35.156931294141181, -8.755431352900715],
              [-35.153642148427771, -8.755680097432405],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0395000',
        uf: 'PE',
        nome_proje: 'PA GENERAL ABREU E LIMA',
        municipio: 'AGUA PRETA',
        area_hecta: '385.6824',
        capacidade: 35.0,
        num_famili: 35.0,
        fase: 3.0,
        data_de_cr: '03/01/2012',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 380.7823,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.361579276681034, -8.748413355367573],
              [-35.363001238841207, -8.75497796621797],
              [-35.370769882350004, -8.751936753553768],
              [-35.372065239812507, -8.753902120225462],
              [-35.378129834353274, -8.749843543043177],
              [-35.380648574608848, -8.74637075450293],
              [-35.380734692033734, -8.745898123354435],
              [-35.381385811106284, -8.745251464777793],
              [-35.381876331666724, -8.743432262943314],
              [-35.381795774238491, -8.742769555160098],
              [-35.382165718423707, -8.741510264795911],
              [-35.38174946622938, -8.740846204756508],
              [-35.38090276671614, -8.73851916434627],
              [-35.381052997511432, -8.738538722904334],
              [-35.381334495416112, -8.738570677118776],
              [-35.381513533468052, -8.736280479118701],
              [-35.380500466649274, -8.734543065567804],
              [-35.380300201592242, -8.734700773024128],
              [-35.379589978531243, -8.735819438214271],
              [-35.378835964065367, -8.73645726535141],
              [-35.377526696932357, -8.73423667812521],
              [-35.377262155350877, -8.734088150556367],
              [-35.377096418553805, -8.734213626238786],
              [-35.376769112478058, -8.734498741058404],
              [-35.376665794197528, -8.734722892432451],
              [-35.376593112512872, -8.735051147296966],
              [-35.376445204263938, -8.735320315148353],
              [-35.376236864204749, -8.735485475920823],
              [-35.37622395260663, -8.735813356048565],
              [-35.376135810773924, -8.736067236852888],
              [-35.375943877593173, -8.736470710589707],
              [-35.375691516467853, -8.736785179834028],
              [-35.37556185454423, -8.736966476682065],
              [-35.375183592011403, -8.737511113322251],
              [-35.375038596235029, -8.737623638541145],
              [-35.374829782769609, -8.737714330863971],
              [-35.374741360112914, -8.737938388844185],
              [-35.374444497593721, -8.738297873607104],
              [-35.374252184774285, -8.738641790754757],
              [-35.37405921135656, -8.738881416261615],
              [-35.373808505652342, -8.739062692200019],
              [-35.373052284807258, -8.73973311056392],
              [-35.372328989379469, -8.74005016161772],
              [-35.372175900447481, -8.739830159967052],
              [-35.371153912617288, -8.738160321786452],
              [-35.370846133567426, -8.736420034622226],
              [-35.369585455834539, -8.735213478988561],
              [-35.369530929357595, -8.734764222964008],
              [-35.369146994928208, -8.734146475428162],
              [-35.368536543872999, -8.733928905262784],
              [-35.368187442437332, -8.733965002267665],
              [-35.367555224260201, -8.734559933814719],
              [-35.365816173588208, -8.734891093260805],
              [-35.364280106005545, -8.737046077435268],
              [-35.364180458865754, -8.737493709979939],
              [-35.363174891726644, -8.738695436937443],
              [-35.36264830819723, -8.738860297860359],
              [-35.362436255241093, -8.738783714173538],
              [-35.361807451424255, -8.738819755960348],
              [-35.360729579720406, -8.739500299346131],
              [-35.360373913611653, -8.740839122880145],
              [-35.360437873033597, -8.742824717505929],
              [-35.360729576025456, -8.744490454510927],
              [-35.361579276681034, -8.748413355367573],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0351000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOSÉ DO ESPALHADO I',
        municipio: 'AGUA PRETA',
        area_hecta: '432.1647',
        capacidade: 38.0,
        num_famili: 37.0,
        fase: 3.0,
        data_de_cr: '13/02/2006',
        forma_obte: 'Desapropriação',
        data_obten: '09/06/2005',
        area_calc_: 423.7922,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499162955574896, -8.52667339256929],
              [-35.491259596678795, -8.534646857839364],
              [-35.489650722325003, -8.550681614547283],
              [-35.501377360756493, -8.550430297725487],
              [-35.50369786197605, -8.561925934181554],
              [-35.506535481434078, -8.553425700369516],
              [-35.507613833766982, -8.551021676129421],
              [-35.501378020551783, -8.550429607170901],
              [-35.499630106349365, -8.54114725486372],
              [-35.502642087551287, -8.54048468321421],
              [-35.505343214118831, -8.540822332080038],
              [-35.508832247547844, -8.540903104885333],
              [-35.510701532405108, -8.541144296828611],
              [-35.512619304700927, -8.535009307944906],
              [-35.503558216179712, -8.529075954264608],
              [-35.499162955574896, -8.52667339256929],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0275000',
        uf: 'PE',
        nome_proje: 'PA PIABA DE BAIXO',
        municipio: 'BARREIROS',
        area_hecta: '581.5000',
        capacidade: 53.0,
        num_famili: 52.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 563.8006,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.220423534477092, -8.75764588732592],
              [-35.215596948536948, -8.757963161495191],
              [-35.213835505387209, -8.760185488408386],
              [-35.212861441707872, -8.76111120212212],
              [-35.212546414742533, -8.761590419477871],
              [-35.212175756453114, -8.762102068085715],
              [-35.210749010098667, -8.764053490423137],
              [-35.209192574102701, -8.766111947223761],
              [-35.208750556786107, -8.76684198206889],
              [-35.208446132803225, -8.767238133184296],
              [-35.208189735164268, -8.767418835206652],
              [-35.207052825505649, -8.768254563494958],
              [-35.206057929974818, -8.768908340576886],
              [-35.205979360558707, -8.768963113683421],
              [-35.205776050100674, -8.769107023910459],
              [-35.201433114038736, -8.772186395092321],
              [-35.20129739676986, -8.772220349976749],
              [-35.200761279095275, -8.772478153853697],
              [-35.199705677328772, -8.773024575695963],
              [-35.199656260621694, -8.773137577884148],
              [-35.198421711846954, -8.776440230558586],
              [-35.197304576460134, -8.777618668970193],
              [-35.197077647329635, -8.777786522257964],
              [-35.196600645127546, -8.778274966141918],
              [-35.19603641465055, -8.778860768800586],
              [-35.195607617151673, -8.779371709303886],
              [-35.194793528935065, -8.780193622980413],
              [-35.194987613084585, -8.780235744393721],
              [-35.19749827013888, -8.780846638971402],
              [-35.199140077604888, -8.781262978237606],
              [-35.199802580956963, -8.7814416984635],
              [-35.200438578387136, -8.781604708907835],
              [-35.201376126721122, -8.781847289114399],
              [-35.202098856874166, -8.782035595865821],
              [-35.203077812204235, -8.782290457264313],
              [-35.204505519421701, -8.78265613830499],
              [-35.206192083986352, -8.783112177905313],
              [-35.20719380553043, -8.78334740638906],
              [-35.208692413337396, -8.783753880310204],
              [-35.20893748407223, -8.783375844082366],
              [-35.209499715393314, -8.782730462620806],
              [-35.209677617361095, -8.782588324914041],
              [-35.209907069546993, -8.782397834946563],
              [-35.210565718826857, -8.781953144626284],
              [-35.210794776032785, -8.781812695208396],
              [-35.211213120842245, -8.781627053751162],
              [-35.212099545536191, -8.781313850259377],
              [-35.212190619279305, -8.781299070929718],
              [-35.212889030686632, -8.781186463036546],
              [-35.21370455538878, -8.781124877153614],
              [-35.215396494540478, -8.781013382158447],
              [-35.215941256276636, -8.780969552414772],
              [-35.219090517349123, -8.780729124745415],
              [-35.219381820447971, -8.780705166284164],
              [-35.222221252296421, -8.780473731433418],
              [-35.222204838518095, -8.779827489181192],
              [-35.222134320988886, -8.779125326886565],
              [-35.222107393980224, -8.778799369378996],
              [-35.221750889201523, -8.774112165385052],
              [-35.221656491237923, -8.772834386728695],
              [-35.221574600618169, -8.77185513676109],
              [-35.221451705570132, -8.771184383813614],
              [-35.221224148463669, -8.770103500631365],
              [-35.221259351136979, -8.768890552486335],
              [-35.221244882644278, -8.768804290851413],
              [-35.221239140843089, -8.768350337589172],
              [-35.22121199696327, -8.767734970458635],
              [-35.22120893820685, -8.767273770428073],
              [-35.221113827783846, -8.765730735628317],
              [-35.220892682870307, -8.762784802203679],
              [-35.220423534477092, -8.75764588732592],
            ],
          ],
          [
            [
              [-35.222217382544521, -8.781980791746532],
              [-35.222262952301577, -8.781528266209296],
              [-35.222234516161571, -8.781139571351815],
              [-35.222298499253398, -8.780831215550728],
              [-35.220728964266343, -8.780958660349754],
              [-35.219119286536547, -8.781089552874896],
              [-35.216004770753635, -8.781327400147806],
              [-35.215456250729225, -8.781370348308522],
              [-35.213186394526105, -8.781524699378624],
              [-35.212474400515724, -8.781613055331322],
              [-35.212229823414091, -8.78167504988304],
              [-35.211351658394641, -8.781962345535014],
              [-35.210977357362921, -8.782185348276942],
              [-35.210720830750333, -8.782293292819377],
              [-35.211075549250232, -8.783026723842724],
              [-35.213336016170473, -8.784425701372616],
              [-35.212857767834137, -8.784907490330353],
              [-35.21314479317568, -8.785725312171973],
              [-35.212295061225554, -8.786361973617067],
              [-35.211983842906236, -8.7868959025586],
              [-35.21214278025937, -8.787549971767932],
              [-35.212407250154008, -8.787585866729314],
              [-35.214845157772068, -8.787836769697511],
              [-35.216667619145262, -8.788025827556336],
              [-35.217297027149563, -8.788093223102662],
              [-35.217691330280317, -8.7881320560102],
              [-35.218875988776787, -8.788246282151173],
              [-35.21908966169147, -8.788198944533086],
              [-35.219503236115976, -8.788098773197643],
              [-35.220114686061812, -8.78795047060331],
              [-35.223703021398791, -8.787056112390871],
              [-35.223836627942831, -8.786410486340245],
              [-35.223967437871352, -8.786065413013626],
              [-35.221963942280212, -8.784732802469115],
              [-35.221930615118715, -8.784377545978128],
              [-35.222217382544521, -8.781980791746532],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0204000',
        uf: 'PE',
        nome_proje: 'PA COCAL GRANDE',
        municipio: 'TAMANDARE',
        area_hecta: '360.5600',
        capacidade: 50.0,
        num_famili: 49.0,
        fase: 5.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '16/09/2004',
        area_calc_: 363.0862,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.31921917900776, -8.773762252667265],
              [-35.31533329723063, -8.77515648121698],
              [-35.313212945972289, -8.777062711078676],
              [-35.313029985749857, -8.777227242220054],
              [-35.311910041187581, -8.777245857079588],
              [-35.309014062952656, -8.777556430147376],
              [-35.308184490957366, -8.780719907933152],
              [-35.307817522983896, -8.782144307633702],
              [-35.307438283204554, -8.783523872108553],
              [-35.306905426351832, -8.78559751346209],
              [-35.30690568372998, -8.786902269483287],
              [-35.306904882588917, -8.788815480677885],
              [-35.306906344592569, -8.789727150649501],
              [-35.307201531751652, -8.791441607179319],
              [-35.307280605510961, -8.791900984071097],
              [-35.308047633287835, -8.791960274459329],
              [-35.308177316960311, -8.791920977193337],
              [-35.308625654840462, -8.791957454818927],
              [-35.308806709157565, -8.791938630191773],
              [-35.308979381731504, -8.791872135469767],
              [-35.309788222969644, -8.791903780343306],
              [-35.309900733121161, -8.791937616009138],
              [-35.310027896780227, -8.79203417681207],
              [-35.310369688576955, -8.792046270134328],
              [-35.310454367862462, -8.792031560791967],
              [-35.310643682459421, -8.792099450699608],
              [-35.310811583950205, -8.792186723101326],
              [-35.311155073939567, -8.792194286182456],
              [-35.311354963215209, -8.792266358705012],
              [-35.311387006423537, -8.79240806970572],
              [-35.31157297517359, -8.792551719096133],
              [-35.312075652001006, -8.792559840604765],
              [-35.312473850498243, -8.792481475493169],
              [-35.31258019677211, -8.792415115332899],
              [-35.31300491078089, -8.792231925487338],
              [-35.313742232404266, -8.791763822511662],
              [-35.314640146816551, -8.791466103277234],
              [-35.315194677978383, -8.791439828635527],
              [-35.316077669672445, -8.791621488638308],
              [-35.318905053555099, -8.792699244997726],
              [-35.319939495761936, -8.792746198555477],
              [-35.320395863518641, -8.792875975419751],
              [-35.320703339323664, -8.793169987185584],
              [-35.32106939654031, -8.793518769710445],
              [-35.321526524582282, -8.793681447769671],
              [-35.321753183607441, -8.79369107673624],
              [-35.322075587820571, -8.793639379362187],
              [-35.322670504276914, -8.79338851029191],
              [-35.322866305953546, -8.792951848886396],
              [-35.322908257966084, -8.79288967941527],
              [-35.323348214367073, -8.791872082107416],
              [-35.323763520829381, -8.790939224061546],
              [-35.323893692777368, -8.790234103592093],
              [-35.32392296927069, -8.790063989511104],
              [-35.324069742942733, -8.789268901343522],
              [-35.324236937894213, -8.788659880171368],
              [-35.324317021171012, -8.788361528119021],
              [-35.324731969352975, -8.786831221482579],
              [-35.324900088918781, -8.785844585135344],
              [-35.32516260496083, -8.784290671977406],
              [-35.325301111459943, -8.782825464173007],
              [-35.326339903969433, -8.780297884926421],
              [-35.326825787649817, -8.779115414097625],
              [-35.327016593864599, -8.778651218189619],
              [-35.327098525757037, -8.778454496891996],
              [-35.327379051863154, -8.777769219345052],
              [-35.327475682923399, -8.777005532704848],
              [-35.326856372425425, -8.776695010120934],
              [-35.324906317372246, -8.775717637127167],
              [-35.324444872771494, -8.77545466202058],
              [-35.32040840658501, -8.773082147545425],
              [-35.320137862722696, -8.773026147858808],
              [-35.319424574785877, -8.773597672103238],
              [-35.31921917900776, -8.773762252667265],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0188000',
        uf: 'PE',
        nome_proje: 'PA BARRINHA/FIRMATIVO',
        municipio: 'VICENCIA',
        area_hecta: '374.5500',
        capacidade: 36.0,
        num_famili: 35.0,
        fase: 5.0,
        data_de_cr: '13/08/1999',
        forma_obte: 'Desapropriação',
        data_obten: '24/11/1998',
        area_calc_: 361.544,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.264536498083025, -7.652842990239954],
              [-35.263792395826762, -7.65299820928636],
              [-35.260640769423603, -7.653071695596881],
              [-35.259675911880834, -7.653149246881959],
              [-35.259546571183321, -7.653483278599253],
              [-35.260768922311655, -7.65359554879757],
              [-35.260859742982873, -7.653963767681429],
              [-35.260685984056188, -7.654190018117648],
              [-35.260489467746112, -7.654447571999061],
              [-35.260680832172717, -7.654708062540421],
              [-35.260951764262188, -7.654748762220586],
              [-35.261023807982937, -7.654923557393298],
              [-35.260830870288693, -7.655447468022244],
              [-35.261020430338107, -7.65581236655308],
              [-35.260973541004795, -7.656576486953393],
              [-35.261078835792226, -7.656734024055265],
              [-35.260947607841601, -7.657036430028238],
              [-35.260727457808784, -7.657279465398646],
              [-35.260879917854297, -7.657498400042258],
              [-35.261112399267148, -7.657892810804022],
              [-35.260777311014458, -7.658039643401541],
              [-35.260726553222845, -7.658241928418887],
              [-35.260931159426718, -7.658332779712869],
              [-35.261145428171226, -7.658211438077496],
              [-35.261470113878154, -7.658479995407681],
              [-35.262149081573391, -7.658324306033485],
              [-35.262407936673313, -7.658410984899934],
              [-35.26242076569303, -7.658695912574063],
              [-35.262832780449045, -7.658910590175553],
              [-35.263041897005017, -7.658705846152227],
              [-35.263306359478527, -7.658996501701465],
              [-35.263648249908904, -7.658731041756875],
              [-35.263838685123254, -7.658952034879708],
              [-35.264068531036294, -7.658949742019519],
              [-35.264190479946748, -7.658829336268754],
              [-35.264585051202758, -7.658880500586472],
              [-35.264795294444781, -7.659112145191237],
              [-35.265061330406972, -7.659031656296674],
              [-35.265129266236613, -7.659234401675593],
              [-35.26720014727232, -7.659385932613561],
              [-35.271181802858216, -7.661038035093871],
              [-35.270958124903572, -7.661400676539732],
              [-35.270101909912157, -7.661995064270982],
              [-35.269878866198368, -7.662785508946994],
              [-35.270247395661499, -7.663546892626488],
              [-35.270179175035992, -7.663769787813652],
              [-35.269797570520694, -7.663652940013051],
              [-35.268889207809686, -7.66600049890552],
              [-35.267436711593405, -7.665806408643872],
              [-35.267318240862366, -7.665967743126898],
              [-35.268147374662334, -7.667305908927108],
              [-35.268465608454107, -7.667397692445781],
              [-35.268643423680189, -7.667783435255649],
              [-35.267509227318932, -7.669661987029191],
              [-35.266783875059559, -7.670043362133932],
              [-35.26697570827244, -7.67259636047762],
              [-35.275343554525932, -7.672519527687629],
              [-35.275558044200537, -7.672712364504021],
              [-35.276164259531534, -7.670409060772545],
              [-35.283154738864205, -7.670415843586437],
              [-35.283317827188895, -7.666101156630826],
              [-35.283244430664219, -7.663516109196916],
              [-35.283183203795033, -7.661360607381543],
              [-35.282309486664026, -7.661301064719327],
              [-35.28229950935841, -7.66048238924908],
              [-35.282297800094447, -7.659957247956444],
              [-35.282280580135158, -7.659258554793559],
              [-35.279498018905088, -7.659170882583776],
              [-35.278827157886418, -7.659144785707991],
              [-35.278824619295648, -7.658582046312672],
              [-35.278898056029988, -7.654005517125725],
              [-35.277340190608619, -7.65392173761862],
              [-35.27722827152067, -7.653916544206737],
              [-35.276502813213668, -7.653878527430218],
              [-35.276503722332265, -7.653418178070151],
              [-35.276498693292638, -7.651667937122925],
              [-35.276477025167054, -7.648915384112396],
              [-35.276475704031434, -7.648546699579326],
              [-35.27647361514515, -7.647964884844826],
              [-35.27647091087568, -7.647574514542935],
              [-35.275991116972953, -7.647885720799307],
              [-35.275465941152298, -7.647155989268889],
              [-35.275273448864041, -7.647281468693293],
              [-35.275334465906873, -7.647563247126391],
              [-35.274980592701915, -7.647855258668232],
              [-35.27507051073642, -7.648102808510042],
              [-35.2750106385026, -7.648430237822791],
              [-35.274662809131677, -7.648939690597454],
              [-35.273998451136364, -7.649779191775239],
              [-35.273500938677316, -7.65001311786505],
              [-35.273179566933749, -7.64995913329899],
              [-35.273052494467656, -7.64981256127339],
              [-35.273008246090974, -7.649549223334142],
              [-35.273111288500644, -7.649178632003992],
              [-35.272900603152365, -7.648999780152919],
              [-35.27249915481741, -7.648948205417178],
              [-35.272264166935287, -7.648799399629754],
              [-35.272282218794615, -7.648598281657687],
              [-35.272694264959213, -7.648409911229027],
              [-35.272036909038107, -7.648159655764681],
              [-35.271765594082837, -7.648370875557767],
              [-35.271400015454986, -7.648437609344438],
              [-35.27112077013431, -7.648297351527625],
              [-35.271179413772579, -7.648644675113767],
              [-35.271416603071806, -7.649808708330296],
              [-35.271488309624324, -7.650176119437608],
              [-35.272017729789603, -7.652887952257992],
              [-35.271678896054816, -7.654070747730039],
              [-35.271703799263499, -7.654376399029762],
              [-35.272214305079913, -7.655106121215346],
              [-35.272296124352067, -7.655432261425672],
              [-35.272126233480087, -7.65637011890618],
              [-35.271580729676067, -7.656012166591203],
              [-35.271329343917245, -7.655847268357464],
              [-35.270495469332708, -7.655166341154914],
              [-35.269889869293586, -7.654117866239814],
              [-35.269501179646817, -7.653927296242852],
              [-35.266824620865179, -7.654196910599689],
              [-35.26496177366748, -7.654283354598538],
              [-35.264536498083025, -7.652842990239954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0281000',
        uf: 'PE',
        nome_proje: 'PA CANGAUZINHO',
        municipio: 'ALIANCA',
        area_hecta: '234.0000',
        capacidade: 21.0,
        num_famili: 21.0,
        fase: 4.0,
        data_de_cr: '25/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/06/2001',
        area_calc_: 215.4075,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.230607456696212, -7.609482095839523],
              [-35.230495400311241, -7.609690201606177],
              [-35.228368205117164, -7.612237808935731],
              [-35.228251698404236, -7.612449030787676],
              [-35.228133133978609, -7.612587477481213],
              [-35.227750032579301, -7.612491898157844],
              [-35.227374558237763, -7.612464927401731],
              [-35.22713707688532, -7.61226973270504],
              [-35.227021627387956, -7.612274793132509],
              [-35.226465210229463, -7.612356876351149],
              [-35.22638587064754, -7.61236858054764],
              [-35.22631791446755, -7.612378605448768],
              [-35.225867250352742, -7.612501554257643],
              [-35.22581562192692, -7.612526136524559],
              [-35.225472701474771, -7.612692854220173],
              [-35.225370065022226, -7.612711201616718],
              [-35.225255331361303, -7.612691582756449],
              [-35.225154818714344, -7.612675267414708],
              [-35.22498076103745, -7.612637369453441],
              [-35.224900077916388, -7.612620630817284],
              [-35.224785046041895, -7.61260143608199],
              [-35.224669308449364, -7.612587072492364],
              [-35.224562965464372, -7.612573675389401],
              [-35.22445734619545, -7.612555449336623],
              [-35.224137443075065, -7.612474069403151],
              [-35.223669432066835, -7.612311793177264],
              [-35.223552984700753, -7.612210468191189],
              [-35.223057157775486, -7.613103254118904],
              [-35.222921335735641, -7.613271545136354],
              [-35.223714342505488, -7.614959008978268],
              [-35.223892707370354, -7.616546879414087],
              [-35.22400925954048, -7.619095414382251],
              [-35.224217296407367, -7.622710348974024],
              [-35.224356353420674, -7.624476047714452],
              [-35.224205456787487, -7.624984631086891],
              [-35.223349695700605, -7.625460253231129],
              [-35.223613298362928, -7.627450162038008],
              [-35.224140665116956, -7.628076604724582],
              [-35.224070732228157, -7.628762417894466],
              [-35.223991634658788, -7.629538107744875],
              [-35.223912105822663, -7.630318028146435],
              [-35.223895116625862, -7.630484637263565],
              [-35.223534552282473, -7.63181359490154],
              [-35.223332709031162, -7.63255824769218],
              [-35.223136537648038, -7.633295481803247],
              [-35.222535218637745, -7.635879019034567],
              [-35.224652919079013, -7.635604663739525],
              [-35.227381480672506, -7.63644879017626],
              [-35.22943614845687, -7.636379714667364],
              [-35.230780818101117, -7.636820140033634],
              [-35.230725103104859, -7.634720645520989],
              [-35.230745715439546, -7.632891594691176],
              [-35.23082798197099, -7.631142612815711],
              [-35.230882460436618, -7.627478414648677],
              [-35.230977381122116, -7.626812888700737],
              [-35.230974822525901, -7.626721648807814],
              [-35.230929561638455, -7.625107600684771],
              [-35.230926149736014, -7.624985926036525],
              [-35.230989541775031, -7.621108216052735],
              [-35.231025501556516, -7.620139147720203],
              [-35.231048206787122, -7.618278689747967],
              [-35.230955045421695, -7.617437970833852],
              [-35.230992866874175, -7.615753793832613],
              [-35.231083676606403, -7.615115270098694],
              [-35.231117535639086, -7.613384303894712],
              [-35.231187068822805, -7.612109167935027],
              [-35.231159499374677, -7.611534216375239],
              [-35.231234609812297, -7.611152400134455],
              [-35.231258871971299, -7.610865574868006],
              [-35.231233350943612, -7.610605404400537],
              [-35.231340443454229, -7.61024412531065],
              [-35.231357264188645, -7.609986367922926],
              [-35.231334379404665, -7.609607989983716],
              [-35.230607456696212, -7.609482095839523],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0135000',
        uf: 'PE',
        nome_proje: 'PA CÍCERO GOMES',
        municipio: 'AMARAJI',
        area_hecta: '351.2500',
        capacidade: 38.0,
        num_famili: 36.0,
        fase: 6.0,
        data_de_cr: '03/06/1998',
        forma_obte: 'Desapropriação',
        data_obten: '04/08/1997',
        area_calc_: 348.9426,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.439437553585762, -8.252458382936299],
              [-35.438783328786094, -8.253077983788332],
              [-35.438185932093383, -8.253922293450479],
              [-35.438151958521949, -8.254610076174952],
              [-35.437265628178011, -8.255487505368309],
              [-35.436461339616159, -8.255674959789532],
              [-35.435543970310768, -8.256063282648794],
              [-35.434511226472374, -8.256692975867594],
              [-35.433520416608829, -8.257341300875281],
              [-35.432713836223748, -8.256903995917735],
              [-35.430978320422774, -8.255055238536855],
              [-35.42965867360806, -8.254226730094929],
              [-35.428125944641714, -8.253802584949858],
              [-35.427392116876078, -8.254075089821445],
              [-35.425143833380609, -8.255082969669282],
              [-35.427380146293487, -8.25724985199672],
              [-35.42898164339887, -8.25880594069428],
              [-35.430630849596319, -8.26043285659471],
              [-35.432458917696501, -8.262304230095971],
              [-35.43266111428877, -8.262539247899431],
              [-35.433977970191442, -8.263752967992598],
              [-35.435245948187841, -8.264909318193165],
              [-35.436825320790078, -8.266412712199779],
              [-35.439621244288823, -8.269260538693766],
              [-35.439873312885375, -8.269519919793048],
              [-35.443394986091953, -8.27330328450018],
              [-35.446150106486805, -8.276846633597321],
              [-35.449327618587475, -8.273635261897002],
              [-35.451456113787174, -8.271473312197761],
              [-35.452691732583048, -8.270210085599762],
              [-35.453746515187021, -8.26913404559768],
              [-35.454690312678473, -8.268200210699936],
              [-35.453938341387271, -8.267581858994397],
              [-35.453638848482264, -8.266885274496556],
              [-35.451046081084684, -8.264435063396999],
              [-35.450395914184647, -8.263596558297571],
              [-35.448754066089549, -8.262189950294431],
              [-35.446117613588342, -8.259485368398918],
              [-35.443773061185489, -8.256933162997049],
              [-35.441956548586376, -8.255068466493416],
              [-35.439437553585762, -8.252458382936299],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0397000',
        uf: 'PE',
        nome_proje: 'PA DOM HELDER CAMARA',
        municipio: 'ALIANCA',
        area_hecta: '283.0273',
        capacidade: 34.0,
        num_famili: 33.0,
        fase: 3.0,
        data_de_cr: '05/11/2012',
        forma_obte: 'Desapropriação',
        data_obten: '08/09/2008',
        area_calc_: 321.7443,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.188549674870963, -7.573738260614695],
              [-35.186092036160133, -7.575937309216335],
              [-35.182475159251084, -7.576191746376698],
              [-35.178644528161989, -7.57987984913898],
              [-35.178680052318001, -7.583430259732304],
              [-35.178721501315785, -7.58756196405104],
              [-35.178588595197787, -7.592580041716626],
              [-35.17835785805228, -7.601392916967733],
              [-35.178431120923342, -7.603740271289312],
              [-35.182381089808622, -7.603574729444248],
              [-35.183560092646914, -7.600615841706523],
              [-35.187041180900628, -7.597838661123918],
              [-35.187280060537688, -7.59744319737769],
              [-35.187520031647551, -7.595890374971367],
              [-35.188288745019435, -7.595601780342794],
              [-35.188384803309418, -7.594666112775069],
              [-35.188338777929189, -7.594359251014964],
              [-35.188410701222089, -7.593750811843146],
              [-35.188341211670178, -7.593139514643312],
              [-35.188443376666704, -7.592302245805199],
              [-35.189120977143197, -7.591842408264379],
              [-35.191460997263569, -7.59003255773378],
              [-35.191435564530636, -7.589960807141705],
              [-35.191359907548211, -7.589801233734789],
              [-35.191292527912928, -7.589738523770348],
              [-35.191098954906529, -7.589599612278226],
              [-35.190858331059893, -7.589435266001006],
              [-35.190610664337605, -7.589243209233985],
              [-35.190392081498928, -7.589143931515398],
              [-35.190153955422311, -7.589058931545422],
              [-35.189981316181765, -7.588803477834233],
              [-35.190080435770135, -7.588501858561658],
              [-35.190205998628507, -7.588324662425335],
              [-35.190423362792927, -7.588236679547831],
              [-35.190712056238326, -7.588108980999005],
              [-35.190937500381025, -7.588003830778373],
              [-35.191198054362843, -7.587823245025025],
              [-35.191349800952693, -7.58759373831395],
              [-35.191386562114197, -7.587391711120058],
              [-35.191323313184135, -7.587238347928966],
              [-35.191157794259226, -7.587025180214075],
              [-35.190968417654616, -7.58663538368107],
              [-35.190765430181202, -7.586235567000643],
              [-35.190713319080317, -7.585845757993706],
              [-35.190643442543774, -7.585456696991837],
              [-35.190514392771178, -7.585258700246228],
              [-35.190326831040572, -7.585174812835537],
              [-35.190103897492769, -7.584954714959471],
              [-35.190101879632039, -7.584555821274538],
              [-35.190083865864842, -7.584259452354967],
              [-35.18998302518213, -7.584060826937783],
              [-35.189720755861899, -7.583881663450374],
              [-35.189424783370491, -7.583755491765091],
              [-35.189035708132423, -7.583678866454908],
              [-35.189044556866286, -7.583542404939028],
              [-35.189441248538991, -7.577424950413388],
              [-35.189389201907225, -7.577148273814187],
              [-35.189293903458285, -7.576831264222693],
              [-35.188549674870963, -7.573738260614695],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0296000',
        uf: 'PE',
        nome_proje: 'PA MANHOSO',
        municipio: 'AMARAJI',
        area_hecta: '172.4163',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 4.0,
        data_de_cr: '14/08/2003',
        forma_obte: 'Desapropriação',
        data_obten: '06/09/2001',
        area_calc_: 170.7788,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.45305352129612, -8.29698910657905],
              [-35.452797080833783, -8.297382848808903],
              [-35.451363111297617, -8.299455012557274],
              [-35.451307114917988, -8.299528696012571],
              [-35.450558189856594, -8.302247212028599],
              [-35.450579744791874, -8.302319821697834],
              [-35.450974070229307, -8.302362162991654],
              [-35.45108955955056, -8.302383390633761],
              [-35.451641256722866, -8.302541589027003],
              [-35.451824225347146, -8.302623189289029],
              [-35.45225340094315, -8.302739221679824],
              [-35.452465799829454, -8.302897203870403],
              [-35.452706461911376, -8.303188266096342],
              [-35.452806376135015, -8.303495587101251],
              [-35.452834201242275, -8.303839803822205],
              [-35.45280580254277, -8.304210731138664],
              [-35.452522106722917, -8.305763170331536],
              [-35.452560350326159, -8.306298036870022],
              [-35.452688434303504, -8.306530389275551],
              [-35.453108398508213, -8.306986917792772],
              [-35.453179377514317, -8.307182424045203],
              [-35.453254809024507, -8.307476029214451],
              [-35.453302241638148, -8.307687884302402],
              [-35.453307363501516, -8.308261581800048],
              [-35.453382309325804, -8.309054624948066],
              [-35.453469700256989, -8.309040810009202],
              [-35.453732943036954, -8.308869382372045],
              [-35.454323826606171, -8.308454354355119],
              [-35.454814443301643, -8.308696521283988],
              [-35.455374956891312, -8.309420591369339],
              [-35.455540091205513, -8.3097571461839],
              [-35.455806339518162, -8.310038201463605],
              [-35.456081493017855, -8.310796529819584],
              [-35.456333273760158, -8.312141421978732],
              [-35.456649941475582, -8.312986205239332],
              [-35.457397158326735, -8.314359130272898],
              [-35.457796868560074, -8.314880990519647],
              [-35.458138094200876, -8.315318784464425],
              [-35.458567673940927, -8.315768900649278],
              [-35.458588314331486, -8.316034955902616],
              [-35.458747620760604, -8.316663256580517],
              [-35.458695610293063, -8.316846765707778],
              [-35.458492913263392, -8.317104568787158],
              [-35.458418203800655, -8.317532695472689],
              [-35.458447744132243, -8.317859378533319],
              [-35.458444369209396, -8.318124136326686],
              [-35.458531760782357, -8.318150933742279],
              [-35.459057571149408, -8.318512437913853],
              [-35.460774141724393, -8.319816323433511],
              [-35.461669207865086, -8.320422020539485],
              [-35.461817483960601, -8.320665236953451],
              [-35.461811956427432, -8.3208472973587],
              [-35.461797128130669, -8.32133570014688],
              [-35.462112667611045, -8.321739973696015],
              [-35.463407643870113, -8.32191176089723],
              [-35.463883159369935, -8.321364534488369],
              [-35.464100286864074, -8.321345405153714],
              [-35.464736228620836, -8.321394463804804],
              [-35.465154481408739, -8.320518988203295],
              [-35.464986377581837, -8.320193033278878],
              [-35.465330403562504, -8.319511336120184],
              [-35.465386036645349, -8.318689262104513],
              [-35.465631454569468, -8.318179479643712],
              [-35.465935060206526, -8.317739267027441],
              [-35.46629131834306, -8.317690586278291],
              [-35.466540007306499, -8.317394986221821],
              [-35.466666178190934, -8.317220071890306],
              [-35.466290828340767, -8.317262698823814],
              [-35.466219600732714, -8.317127725463385],
              [-35.465989745785734, -8.316778828574384],
              [-35.466042360911594, -8.316605843876292],
              [-35.465966669027324, -8.316388133541064],
              [-35.465822418999018, -8.315973230436956],
              [-35.465500404994138, -8.315585926270826],
              [-35.465316423363753, -8.315364640750129],
              [-35.465176478858247, -8.315165123730427],
              [-35.46496690399141, -8.315030827074835],
              [-35.464951806078105, -8.314778290879504],
              [-35.464715921959851, -8.314423871923184],
              [-35.464535177852838, -8.314348265392681],
              [-35.464464027826118, -8.314177575314581],
              [-35.464304410650293, -8.314173729748415],
              [-35.464225501061613, -8.313888006127385],
              [-35.463829995437749, -8.313810603288276],
              [-35.463639600950664, -8.31357720063861],
              [-35.463484144457418, -8.313635153403913],
              [-35.463307442454457, -8.313382922206278],
              [-35.463215013176232, -8.313114434774761],
              [-35.463101403775639, -8.312876001159683],
              [-35.462980019750887, -8.312607232042032],
              [-35.462737528867187, -8.312366894313747],
              [-35.46274295695472, -8.312168120204399],
              [-35.462357364971233, -8.312158190098678],
              [-35.462125966939453, -8.311783361425523],
              [-35.461935493840691, -8.311334112788733],
              [-35.461776752816753, -8.311044516537383],
              [-35.461439795942425, -8.310910958954443],
              [-35.461474781462947, -8.310194241174898],
              [-35.461349306619546, -8.309765552538922],
              [-35.461169972979938, -8.309310464929244],
              [-35.461068264263574, -8.309033438008514],
              [-35.461001401408154, -8.308851321422189],
              [-35.460869092228215, -8.30859330423961],
              [-35.46057079969188, -8.307774209866162],
              [-35.460297729960459, -8.307285504924549],
              [-35.460151290890195, -8.307023426426564],
              [-35.460098659765947, -8.306929233640846],
              [-35.459787098470258, -8.306843769299185],
              [-35.459458556039401, -8.306409866666231],
              [-35.459371744061208, -8.306295214716542],
              [-35.45920971942958, -8.305812216216735],
              [-35.459236694176134, -8.305507108281711],
              [-35.459184247546688, -8.305023600360881],
              [-35.45925636255302, -8.304934114619453],
              [-35.459283897196144, -8.304779670653506],
              [-35.459218351352, -8.304575772341702],
              [-35.458979861761868, -8.304308818879585],
              [-35.458642142401828, -8.30408120511785],
              [-35.458398043098228, -8.303627458826519],
              [-35.458172939336386, -8.303421201754357],
              [-35.458247251031601, -8.303196313842093],
              [-35.45787717646197, -8.302734989694812],
              [-35.457527897212238, -8.302299586778927],
              [-35.456538035419335, -8.301382489617382],
              [-35.456471266476697, -8.301271228262166],
              [-35.455715700695428, -8.300012174017589],
              [-35.455633323141761, -8.299968084484188],
              [-35.455392467404046, -8.299839175185957],
              [-35.45517817802731, -8.299589157926603],
              [-35.455119719289527, -8.299357656713406],
              [-35.455042752990046, -8.299052863383968],
              [-35.454978145096327, -8.298894549236858],
              [-35.454460479736753, -8.298494336960815],
              [-35.454246593242054, -8.297975669990993],
              [-35.453742756268419, -8.297710652638893],
              [-35.453438750967209, -8.297413339121412],
              [-35.453234433280706, -8.297097540617482],
              [-35.45305352129612, -8.29698910657905],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0023000',
        uf: 'PE',
        nome_proje: 'PA GURJAU',
        municipio: 'CORTES',
        area_hecta: '478.9881',
        capacidade: 38.0,
        num_famili: 35.0,
        fase: 7.0,
        data_de_cr: '07/08/1989',
        forma_obte: 'Desapropriação',
        data_obten: '02/05/1986',
        area_calc_: 479.5174,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.534893841308367, -8.394573593746744],
              [-35.534749606903041, -8.394763676435783],
              [-35.533847118646626, -8.395726419705596],
              [-35.533572174861355, -8.396071990848652],
              [-35.532890060151466, -8.396908380535564],
              [-35.532744252974446, -8.396936822642243],
              [-35.532424677787468, -8.397245353471325],
              [-35.532208387555791, -8.397661272123957],
              [-35.531974507532247, -8.398102843536295],
              [-35.53165568063357, -8.398222769064507],
              [-35.531149261844988, -8.398620907329912],
              [-35.529172441593673, -8.400876440055461],
              [-35.528357515582009, -8.401462909535516],
              [-35.527179840435075, -8.402750265895433],
              [-35.525507591939721, -8.40422480323419],
              [-35.524958084415651, -8.404725542968452],
              [-35.524213003281425, -8.405430062436043],
              [-35.521392978572251, -8.408169961280279],
              [-35.51946895748317, -8.409970300384183],
              [-35.518871831711593, -8.410506928257114],
              [-35.518387579303607, -8.410981535784536],
              [-35.517861393397588, -8.411533958076507],
              [-35.515420220903181, -8.413727411525743],
              [-35.515401841544538, -8.413818989875894],
              [-35.51489503448704, -8.414278365368986],
              [-35.514335639706353, -8.414730409608413],
              [-35.512680031630453, -8.416369663794875],
              [-35.512024805167698, -8.416951450269123],
              [-35.511427701677462, -8.417521076911802],
              [-35.510590446220021, -8.41832995663534],
              [-35.510894798239825, -8.4187150402998],
              [-35.511359540484733, -8.419189470233629],
              [-35.511902140421576, -8.419804853473867],
              [-35.512764944416354, -8.420673058127196],
              [-35.513660811411242, -8.421750352927633],
              [-35.514436707382181, -8.422438891888794],
              [-35.514708009126615, -8.422898837336941],
              [-35.515252644061171, -8.423481315951429],
              [-35.516999537879428, -8.425420946558887],
              [-35.517697693105958, -8.426195841284319],
              [-35.518225683238931, -8.42677907798832],
              [-35.519579291518447, -8.425446294738965],
              [-35.523109008873377, -8.422154095608706],
              [-35.523259632407736, -8.422020239645466],
              [-35.527648720297634, -8.418604629996677],
              [-35.528514733739264, -8.416762164660813],
              [-35.529661837609872, -8.415344252276716],
              [-35.529831067437271, -8.415067810564777],
              [-35.530312553444958, -8.414785766656395],
              [-35.530641472361651, -8.414617876369896],
              [-35.53201103494051, -8.413758388586238],
              [-35.534332891184434, -8.412195331809841],
              [-35.537887796056175, -8.408644483485494],
              [-35.539386047933213, -8.407119655632137],
              [-35.540959308022551, -8.405737240469987],
              [-35.541390343808239, -8.405276710621708],
              [-35.541666986307497, -8.405041139274214],
              [-35.542775432858441, -8.40410735022072],
              [-35.543240466298791, -8.403695062746056],
              [-35.541567290685691, -8.401851319338633],
              [-35.541252898517939, -8.401446293571615],
              [-35.539482485522655, -8.399671536383037],
              [-35.538790456724477, -8.399001990398791],
              [-35.537861716379417, -8.397889217317354],
              [-35.53700261373767, -8.396946589581816],
              [-35.536227185980756, -8.396002838513231],
              [-35.535653286444933, -8.39542600073424],
              [-35.535112264400887, -8.394839158913062],
              [-35.534893841308367, -8.394573593746744],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0354000',
        uf: 'PE',
        nome_proje: 'PA NATAL BELO HORIZONTE',
        municipio: 'ALIANCA',
        area_hecta: '639.3966',
        capacidade: 59.0,
        num_famili: 54.0,
        fase: 5.0,
        data_de_cr: '13/04/2006',
        forma_obte: 'Desapropriação',
        data_obten: '24/05/2004',
        area_calc_: 640.145,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.157918626018883, -7.549304018571469],
              [-35.157751320847282, -7.549132006987509],
              [-35.157356462215354, -7.549374669361871],
              [-35.157256984663171, -7.549490627080854],
              [-35.157098608614049, -7.549940607542756],
              [-35.156892310540876, -7.550757104561425],
              [-35.156794020714237, -7.551851608795954],
              [-35.155101496894531, -7.552763040869965],
              [-35.154755824445786, -7.552972484300202],
              [-35.153853016629363, -7.553359184566703],
              [-35.153079468977943, -7.553446724263243],
              [-35.152860930718667, -7.553520317137041],
              [-35.152775027161418, -7.553595280771445],
              [-35.15272262657863, -7.55378813557475],
              [-35.152638181168626, -7.5545764743717],
              [-35.152764772184888, -7.555053002118706],
              [-35.152859628432971, -7.555633261295605],
              [-35.152897396539544, -7.555917178616646],
              [-35.152671742554041, -7.556001048638978],
              [-35.152136543345883, -7.555828944715959],
              [-35.15199581383083, -7.555854099632159],
              [-35.151792431354586, -7.55581282445606],
              [-35.151596515187855, -7.555729849105828],
              [-35.151422254306667, -7.555631390635415],
              [-35.151021657917298, -7.555312596720263],
              [-35.150436477214697, -7.554958101492026],
              [-35.150365688169167, -7.554904140602783],
              [-35.149738972022575, -7.554496081459879],
              [-35.149764675071168, -7.5573243593896],
              [-35.149768417660987, -7.557412512767947],
              [-35.148870811003682, -7.55794051394132],
              [-35.148607663035399, -7.558163613908713],
              [-35.14854696580111, -7.558221558084552],
              [-35.148251920082373, -7.558509602321042],
              [-35.148056901804139, -7.558726249372011],
              [-35.147829850088094, -7.558863168335598],
              [-35.147410420751036, -7.559068849919189],
              [-35.147003483727545, -7.559324455059207],
              [-35.146586954928871, -7.559299067451264],
              [-35.146162818990859, -7.559569801985266],
              [-35.145881530196924, -7.560638226341339],
              [-35.145727211996807, -7.560790308029169],
              [-35.145493671386291, -7.56109224881602],
              [-35.145502649359095, -7.561239491456989],
              [-35.145320076426138, -7.56154370703461],
              [-35.145006160042101, -7.561762511436326],
              [-35.14526010899845, -7.562521733706379],
              [-35.145777057150738, -7.563075904015246],
              [-35.145888607620122, -7.563318832393469],
              [-35.145962247513417, -7.56346661044701],
              [-35.146255592764163, -7.563784822985156],
              [-35.146449183983663, -7.564125395072775],
              [-35.14666121984844, -7.56435835589512],
              [-35.146824739820495, -7.564511154932339],
              [-35.146765733326539, -7.564580352850047],
              [-35.145871553511185, -7.565333665752411],
              [-35.145965933490011, -7.565540636920254],
              [-35.146257871176999, -7.565856937604313],
              [-35.146490061177623, -7.566219047721583],
              [-35.146865266564987, -7.567104237847794],
              [-35.147159189398963, -7.567622823654088],
              [-35.14725309393927, -7.567905436931236],
              [-35.147412313446623, -7.56861143589324],
              [-35.14735093435069, -7.568685618744301],
              [-35.147215567273292, -7.569337595552869],
              [-35.146803292038705, -7.57010853277248],
              [-35.147310018960468, -7.57001916618766],
              [-35.148529586097368, -7.57003398847837],
              [-35.148246737846264, -7.570343559968469],
              [-35.148049672878621, -7.570763587629304],
              [-35.147920955624976, -7.571208001762312],
              [-35.146319422615107, -7.574603039163015],
              [-35.146821883947297, -7.575005725590858],
              [-35.146737707399119, -7.575345946802622],
              [-35.146470641530485, -7.57590457119432],
              [-35.145981578879528, -7.576822806362345],
              [-35.145748140455105, -7.577080311440883],
              [-35.145550528848361, -7.57717920001059],
              [-35.144971443812246, -7.577380593346156],
              [-35.144350414248542, -7.577687428688725],
              [-35.14433498433538, -7.577796856495014],
              [-35.144283373564562, -7.578358803091355],
              [-35.142643147189546, -7.57923526085745],
              [-35.142982326063276, -7.579585067759138],
              [-35.143383663297804, -7.57980346696136],
              [-35.14343107863732, -7.580480723010939],
              [-35.143521472373685, -7.58072241191251],
              [-35.143744162386909, -7.580940958390982],
              [-35.144189294456154, -7.581199728276453],
              [-35.144380560244976, -7.581801083488542],
              [-35.143810010317146, -7.582891086045964],
              [-35.143257723981669, -7.583121325222716],
              [-35.142044353563911, -7.583226196078812],
              [-35.142848335986599, -7.583818687117137],
              [-35.143113688261408, -7.584093562401756],
              [-35.143365643962817, -7.584220806301341],
              [-35.143709159191289, -7.584262950727268],
              [-35.143703662179874, -7.584356673072097],
              [-35.144908774270597, -7.585488360721547],
              [-35.144998875719232, -7.585473091320305],
              [-35.145042630053339, -7.585703174513658],
              [-35.145233307899566, -7.585946412106455],
              [-35.145557582960251, -7.586202595700933],
              [-35.145838142145458, -7.586322320427188],
              [-35.146399244244748, -7.586471804772931],
              [-35.146521824690367, -7.586517021082289],
              [-35.146760534887576, -7.586776088241164],
              [-35.147020107662662, -7.58728000328281],
              [-35.147419244939755, -7.587834757219444],
              [-35.147715898303545, -7.58813963304744],
              [-35.148040668975447, -7.588306068948128],
              [-35.148332682336772, -7.588286396321089],
              [-35.148559028723831, -7.588163138693622],
              [-35.148933855613969, -7.587905011514122],
              [-35.149221156674152, -7.587812167330076],
              [-35.149462439436867, -7.587835644503926],
              [-35.149611976779759, -7.587900368568409],
              [-35.149576765258317, -7.587984746070592],
              [-35.149455079400511, -7.589489338373541],
              [-35.149431336703572, -7.590678626031831],
              [-35.149328528245057, -7.591921728563946],
              [-35.149516409513524, -7.592033596157273],
              [-35.149766455085391, -7.592102105728316],
              [-35.149966175648899, -7.592299806928843],
              [-35.151407019876295, -7.589189392615737],
              [-35.151445741370573, -7.589104066591791],
              [-35.152212406327195, -7.58751964653209],
              [-35.152436451062066, -7.587056625168287],
              [-35.153508724897883, -7.586138476263928],
              [-35.153838448378664, -7.586027310987011],
              [-35.154254701758511, -7.585977095086442],
              [-35.154921570609908, -7.585704503035178],
              [-35.155014968176246, -7.58567819613344],
              [-35.155865454880662, -7.585248300196651],
              [-35.156318677755692, -7.585016196147029],
              [-35.156694926357801, -7.584491946127667],
              [-35.156789754008877, -7.584056295962962],
              [-35.157908365227314, -7.583605135443316],
              [-35.15828954883203, -7.582986524213079],
              [-35.158360303966909, -7.582830212537739],
              [-35.158528545458203, -7.582458534436809],
              [-35.158463896334212, -7.581945484224493],
              [-35.158597624559576, -7.58165019635454],
              [-35.158638064360844, -7.581261098524966],
              [-35.158732928666915, -7.580873167927177],
              [-35.158707288195664, -7.580503570779441],
              [-35.158221656616355, -7.579706951529498],
              [-35.158037421418719, -7.579404735044279],
              [-35.158151401063783, -7.578809443043578],
              [-35.157920378447322, -7.578431351394329],
              [-35.157679294055889, -7.578036791563131],
              [-35.157929346969681, -7.577946268609978],
              [-35.159128969399141, -7.577804663053096],
              [-35.160781589732572, -7.577780705307435],
              [-35.161071842526646, -7.577772875688136],
              [-35.162269142720604, -7.577740576279695],
              [-35.161793407957155, -7.576945561006862],
              [-35.161715080881898, -7.576712273006192],
              [-35.161631108493964, -7.576199061315386],
              [-35.16159565400843, -7.575716372344089],
              [-35.161647168108424, -7.575286830821062],
              [-35.161722131088915, -7.574980297086677],
              [-35.16175728767783, -7.574579251321057],
              [-35.161641977247548, -7.574228372549415],
              [-35.161835528528357, -7.573629405893785],
              [-35.161896318712905, -7.573262819446859],
              [-35.162039336696928, -7.572910469785782],
              [-35.162032728690654, -7.572834504705291],
              [-35.161770487906701, -7.571833335458598],
              [-35.161500982091319, -7.571385457290018],
              [-35.161430683987561, -7.570738891021225],
              [-35.161446873716599, -7.569970428457608],
              [-35.161601716507818, -7.569524146303811],
              [-35.161817650158497, -7.569118752733659],
              [-35.161784787747393, -7.568902585697463],
              [-35.161856898310937, -7.568664438089026],
              [-35.161806513771282, -7.568417115788009],
              [-35.161927374076967, -7.567930001036957],
              [-35.16103385774079, -7.568017560147067],
              [-35.160295914480315, -7.567864112794153],
              [-35.159931736935881, -7.567582114117369],
              [-35.15966999375415, -7.566904256074207],
              [-35.159098065878702, -7.567804964996392],
              [-35.158506263252931, -7.5677356440244],
              [-35.158148380687948, -7.567577128085933],
              [-35.158323999722583, -7.567220598846864],
              [-35.158454845184323, -7.566920426433713],
              [-35.159196437581713, -7.566318905914575],
              [-35.159321649322742, -7.565730805849689],
              [-35.159593600052297, -7.565401161105703],
              [-35.159901075406907, -7.565251039922843],
              [-35.160430170651665, -7.565096364538767],
              [-35.161333931904565, -7.565071150053846],
              [-35.161935105648482, -7.56489036479592],
              [-35.16199659008506, -7.564368752974473],
              [-35.162299143067727, -7.563744991479558],
              [-35.162037262313191, -7.562233240846672],
              [-35.162075615885634, -7.561875805378111],
              [-35.162442433852988, -7.561682258913942],
              [-35.162536384858406, -7.561544381807185],
              [-35.162444632990017, -7.56081717522211],
              [-35.162242668171174, -7.56042550536589],
              [-35.162097972375399, -7.559888303404988],
              [-35.161948454413704, -7.559553700751998],
              [-35.161799992270943, -7.558732745919155],
              [-35.161826178586864, -7.558533013541871],
              [-35.162088713968643, -7.558042918448204],
              [-35.162436608177586, -7.557870311801517],
              [-35.162743964876796, -7.55765974913313],
              [-35.163197188032562, -7.557454423729997],
              [-35.16368748270726, -7.557139633006355],
              [-35.163892006884232, -7.557070522338849],
              [-35.16424014199918, -7.557071909908674],
              [-35.164709975037034, -7.556946466921952],
              [-35.165338887810556, -7.556860702855171],
              [-35.165829050219543, -7.55652129788791],
              [-35.165779423078618, -7.555592842097424],
              [-35.16577058204593, -7.554644559780263],
              [-35.165921806618428, -7.554461251028731],
              [-35.167153546354669, -7.553566598461178],
              [-35.167489388778385, -7.552751688143675],
              [-35.167529606519153, -7.552283532947694],
              [-35.167738596915846, -7.551628033626889],
              [-35.167491408918259, -7.55151385390631],
              [-35.168055454608812, -7.550483105618258],
              [-35.167188777646153, -7.550175274577029],
              [-35.166571780624437, -7.550416480190189],
              [-35.165257116694335, -7.55066582699676],
              [-35.165356842462259, -7.551453637352929],
              [-35.165252584519386, -7.551614939309675],
              [-35.16527252633891, -7.554114912855167],
              [-35.165048041307386, -7.553975959699005],
              [-35.16480460917866, -7.553633947312449],
              [-35.164558222838323, -7.553402610525056],
              [-35.164319287910857, -7.553239602112318],
              [-35.163943214940737, -7.553043809104268],
              [-35.16365072677938, -7.552644321739836],
              [-35.16332018359396, -7.552328484065105],
              [-35.163006169438219, -7.552159473756682],
              [-35.162745772202378, -7.551884625491848],
              [-35.162574173208412, -7.551629151782179],
              [-35.162463768021041, -7.551465351673603],
              [-35.162224822621525, -7.551052110613512],
              [-35.162124039980284, -7.550870500107315],
              [-35.161978537131503, -7.550563516306418],
              [-35.161721579607324, -7.550265258527223],
              [-35.161281727522635, -7.549864223443681],
              [-35.160928633143868, -7.549587072640086],
              [-35.160734029577917, -7.549265752425145],
              [-35.160651773006293, -7.549196569573255],
              [-35.160298610352868, -7.549337429736035],
              [-35.159930334729488, -7.549398235468401],
              [-35.159418811030555, -7.549603709907],
              [-35.158959561585306, -7.549537545024807],
              [-35.158517136006473, -7.54962417605171],
              [-35.157918626018883, -7.549304018571469],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0102000',
        uf: 'PE',
        nome_proje: 'PA NORMANDIA',
        municipio: 'CARUARU',
        area_hecta: '556.8933',
        capacidade: 41.0,
        num_famili: 40.0,
        fase: 6.0,
        data_de_cr: '19/05/1997',
        forma_obte: 'Desapropriação',
        data_obten: '01/08/1996',
        area_calc_: 554.1749,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.98270647912841, -8.181554162236658],
              [-35.982513534687776, -8.181529118946107],
              [-35.982349629653946, -8.181507564852483],
              [-35.982274852206444, -8.181498451180673],
              [-35.982198752464853, -8.181494406127616],
              [-35.982071055256498, -8.181489296773137],
              [-35.981993965619928, -8.181486252692615],
              [-35.981839858237677, -8.181489829999343],
              [-35.981737500736706, -8.181490585963935],
              [-35.981619449955616, -8.181490283422098],
              [-35.981526728826466, -8.181494491304226],
              [-35.981479792733175, -8.181498451385501],
              [-35.981400353955657, -8.181508704064029],
              [-35.981325792103995, -8.181516391282846],
              [-35.981060711534575, -8.181544817374455],
              [-35.980975270864114, -8.18155267519087],
              [-35.980842920243489, -8.181568015960474],
              [-35.980540866775549, -8.181599063466951],
              [-35.980424075052454, -8.181609591720976],
              [-35.980180552953364, -8.181635328693362],
              [-35.980142467490928, -8.181695593587024],
              [-35.980078220299362, -8.18181016346858],
              [-35.979969069783351, -8.182007000466381],
              [-35.979879957556243, -8.182167374070753],
              [-35.979832449863864, -8.182253002894235],
              [-35.979758726640739, -8.182385710127964],
              [-35.979720878134358, -8.182453471252717],
              [-35.979625736367524, -8.182619941965637],
              [-35.979379662805876, -8.182595289434683],
              [-35.979300116170599, -8.182591088663708],
              [-35.979300116170599, -8.182591088663708],
              [-35.979025340153171, -8.182559511103063],
              [-35.978952992789822, -8.182548030159694],
              [-35.978870962652657, -8.18253905974675],
              [-35.978728769517431, -8.182523306295396],
              [-35.978579269651952, -8.182500379723972],
              [-35.978523767399437, -8.18248633529813],
              [-35.978523767399437, -8.18248633529813],
              [-35.978451519413269, -8.1824760279077],
              [-35.978399589250955, -8.182466835314857],
              [-35.978369451006394, -8.182461908454918],
              [-35.978258332300868, -8.182442854087174],
              [-35.978166547545825, -8.182426728511906],
              [-35.978094096088441, -8.182413441421035],
              [-35.978018022144532, -8.182400723060136],
              [-35.97777776099808, -8.182365186129671],
              [-35.977662385847282, -8.182346795333565],
              [-35.977583973468136, -8.182336623578186],
              [-35.977414889788704, -8.182313750633282],
              [-35.977305080625015, -8.182300106547613],
              [-35.97723383931578, -8.182291056205935],
              [-35.977148173243194, -8.182280847509933],
              [-35.977047523007002, -8.182267406819317],
              [-35.976953497107161, -8.182266925806751],
              [-35.97686963414025, -8.18226754422402],
              [-35.976797911240276, -8.18226689871307],
              [-35.976703159350834, -8.182266332667554],
              [-35.976432966399152, -8.182265885752363],
              [-35.976347353972784, -8.182262903467075],
              [-35.976246001776396, -8.182264825073426],
              [-35.975999907569594, -8.182261851439959],
              [-35.975794900981917, -8.18226092354957],
              [-35.975568135963563, -8.182260246266836],
              [-35.975401043779598, -8.182261387489124],
              [-35.975056053697863, -8.182261490929577],
              [-35.974913695626839, -8.182260191237681],
              [-35.974896009196165, -8.182479209111706],
              [-35.974869283852343, -8.182775261080975],
              [-35.974849203227265, -8.183001523615024],
              [-35.974813481274275, -8.183380300633257],
              [-35.974642093952369, -8.183364760933557],
              [-35.974342728798938, -8.183330922326991],
              [-35.974109753692545, -8.183312132367453],
              [-35.973914196291084, -8.18328954343067],
              [-35.973672745214593, -8.183264853368739],
              [-35.973474776917655, -8.183235054959123],
              [-35.973279075193936, -8.183205239736399],
              [-35.973129428841041, -8.18318710009177],
              [-35.972974851152237, -8.183164208812963],
              [-35.97285510622627, -8.18314350005115],
              [-35.972700546618213, -8.183123047643603],
              [-35.97260876080383, -8.183106830446171],
              [-35.972533892621385, -8.183097805994832],
              [-35.972495180973212, -8.183086076161507],
              [-35.972367202437233, -8.183067686309933],
              [-35.972263394588623, -8.183056435788554],
              [-35.97217888059452, -8.183042694394162],
              [-35.972091935436126, -8.183031229320266],
              [-35.97202432030744, -8.183022151359465],
              [-35.971910775972759, -8.183006184529058],
              [-35.971833514359005, -8.182992299319748],
              [-35.971756288251889, -8.182983201732821],
              [-35.971679584012868, -8.182971119140783],
              [-35.971509312841462, -8.182947168382748],
              [-35.971413918574484, -8.182933416569517],
              [-35.971330735468293, -8.182927976245026],
              [-35.971259511881712, -8.182921363830308],
              [-35.971228133008964, -8.182920330069301],
              [-35.971050635543179, -8.182900045792556],
              [-35.970973489788264, -8.182901787981095],
              [-35.970898692555878, -8.182902338475733],
              [-35.970823734092995, -8.182905600271466],
              [-35.970744230397102, -8.182907269424609],
              [-35.970751262411682, -8.182975241993681],
              [-35.970760380525434, -8.183067771065778],
              [-35.970772651932599, -8.18318204832816],
              [-35.970778010258442, -8.183256627862018],
              [-35.970750265301859, -8.183415555457522],
              [-35.970727058293917, -8.183538404897496],
              [-35.970715553229077, -8.183613108542621],
              [-35.970701581508621, -8.183685300890168],
              [-35.970671513874059, -8.183861138745348],
              [-35.970757747095043, -8.184021124551487],
              [-35.970777334375846, -8.18405819952622],
              [-35.970781157163415, -8.184084640345151],
              [-35.97073304753043, -8.184260611007277],
              [-35.970704884684174, -8.184363170955228],
              [-35.970673996622978, -8.184428351136701],
              [-35.97064201029999, -8.184504379935646],
              [-35.970637194832968, -8.184515255904939],
              [-35.970615631549208, -8.184688592074062],
              [-35.970607708180033, -8.184757216739403],
              [-35.970606125629558, -8.18487394475644],
              [-35.970598992320802, -8.184963521964772],
              [-35.97059387389767, -8.185080276001484],
              [-35.970583966137802, -8.185211428991696],
              [-35.970579714221572, -8.185286079270261],
              [-35.970567512613407, -8.185438026831774],
              [-35.970547788484936, -8.185700422485676],
              [-35.970529074222064, -8.185940045615242],
              [-35.970232206238329, -8.185925428063335],
              [-35.970164634734473, -8.185922311918095],
              [-35.970075376425349, -8.185916916264835],
              [-35.969728044635147, -8.185907548001058],
              [-35.969291281775661, -8.185893959256008],
              [-35.968837708132128, -8.185887630398927],
              [-35.968403410313343, -8.185876371326453],
              [-35.967983546029807, -8.185867444742238],
              [-35.967422733140928, -8.185851965834663],
              [-35.967424360221152, -8.186059369889758],
              [-35.967423141576631, -8.186225329738706],
              [-35.967425124767992, -8.186505272579639],
              [-35.967425712343818, -8.18658458501079],
              [-35.967423996852908, -8.186671231749967],
              [-35.967423544586701, -8.186940622958083],
              [-35.967419327638424, -8.187020061063002],
              [-35.967424703065461, -8.187096989491835],
              [-35.967424001925011, -8.187332776790468],
              [-35.967424363486934, -8.18745500142216],
              [-35.967426076898263, -8.187527168865511],
              [-35.967426279032097, -8.187713534644439],
              [-35.967426295501447, -8.187887073781887],
              [-35.967426416402766, -8.188074704881686],
              [-35.967427078486239, -8.188323129465621],
              [-35.967424371241684, -8.18843498780295],
              [-35.967420278886486, -8.188531227850739],
              [-35.967381419651474, -8.188817794772987],
              [-35.967328855978813, -8.189212687425778],
              [-35.967299003446215, -8.189417703243992],
              [-35.967278600385463, -8.189588682480075],
              [-35.967262251848886, -8.189670558633534],
              [-35.96725326526726, -8.18975481974973],
              [-35.967246493380898, -8.189819822580652],
              [-35.967225965821584, -8.189973999873301],
              [-35.967186444939287, -8.190183513408101],
              [-35.967179726628864, -8.190255742900009],
              [-35.96715194584371, -8.190409973559198],
              [-35.967130608908185, -8.190540578606418],
              [-35.967119067249165, -8.190610404448002],
              [-35.967105158648742, -8.190691178606212],
              [-35.967092584293241, -8.190780705739131],
              [-35.96707874828126, -8.19085904024214],
              [-35.967061381767245, -8.190962605024454],
              [-35.967049962252212, -8.191036675866147],
              [-35.967036053644009, -8.191117450030838],
              [-35.967024538777949, -8.191190889204018],
              [-35.967001452804929, -8.191330179583012],
              [-35.96699219681787, -8.191390322459606],
              [-35.966978396293371, -8.191473444620581],
              [-35.966941286945968, -8.191690257862518],
              [-35.966909057082724, -8.191892581123454],
              [-35.966881425897384, -8.192054760483314],
              [-35.96684908891288, -8.192242630458864],
              [-35.966835809607851, -8.192322677310592],
              [-35.966827922050342, -8.192396180155699],
              [-35.966820219689566, -8.19250690089889],
              [-35.966813555928674, -8.192574251747761],
              [-35.96680690929454, -8.192656146896017],
              [-35.966797923166581, -8.192752694022639],
              [-35.966790053032085, -8.192828545529522],
              [-35.966790266635108, -8.192857361785936],
              [-35.966784675072844, -8.192910341006503],
              [-35.966774633499796, -8.193023518092117],
              [-35.96678253763816, -8.193111268485341],
              [-35.966792698660356, -8.19318545155747],
              [-35.966800593443217, -8.193271937289868],
              [-35.96681735410764, -8.193416896796654],
              [-35.96681735410764, -8.193416896796654],
              [-35.966829908937079, -8.19354490371073],
              [-35.966845503729729, -8.193691588219574],
              [-35.966862173146637, -8.193836458044414],
              [-35.966872523767577, -8.193936205368527],
              [-35.966876427008685, -8.193973486244646],
              [-35.966884242185316, -8.194049222311419],
              [-35.966893210592161, -8.194121517051903],
              [-35.966907526789669, -8.194266946214698],
              [-35.96691396552044, -8.194328238330881],
              [-35.966921780735007, -8.194403974390749],
              [-35.966929595966782, -8.194479710458152],
              [-35.966960575580892, -8.194781392031308],
              [-35.966975937288225, -8.194908836217179],
              [-35.967007019623594, -8.195187661497542],
              [-35.967025025667155, -8.195341555228241],
              [-35.967038172834101, -8.195488257679679],
              [-35.9670562919259, -8.195669613321272],
              [-35.967069153782596, -8.195777833889087],
              [-35.967079692263113, -8.195902874417033],
              [-35.967097697794699, -8.196056677785663],
              [-35.967110971280569, -8.1962081672011],
              [-35.967115892069366, -8.196248150700553],
              [-35.967115892069366, -8.196248150700553],
              [-35.967138016537291, -8.196407163334793],
              [-35.967174690047408, -8.196791823447535],
              [-35.967210579141664, -8.197070703537857],
              [-35.967241715071502, -8.197368860583527],
              [-35.967283123417324, -8.197743909915603],
              [-35.967294076903322, -8.19785151210513],
              [-35.967312593051346, -8.198061863202355],
              [-35.967322855142562, -8.198149596118686],
              [-35.967333324526713, -8.198253046318545],
              [-35.967347249992009, -8.198357916472125],
              [-35.96736531036106, -8.198519036712792],
              [-35.967384586491484, -8.19867292096294],
              [-35.967397533936094, -8.198792613724464],
              [-35.967411476433114, -8.198887546562867],
              [-35.967426933416718, -8.199027727550622],
              [-35.967438545404484, -8.199126291066657],
              [-35.967448837797399, -8.1992180889496],
              [-35.967461530633926, -8.19930345514881],
              [-35.967473223101692, -8.199412858611769],
              [-35.967487174404795, -8.199508965763469],
              [-35.967494919130331, -8.199575126421967],
              [-35.967506349540926, -8.199649209675975],
              [-35.967522990221404, -8.199777818658616],
              [-35.967542653115082, -8.199910470587907],
              [-35.967552996565956, -8.200009133751978],
              [-35.967561955553535, -8.200080073370714],
              [-35.967573531494651, -8.200173758869756],
              [-35.967586367251741, -8.200278365977763],
              [-35.967605684018416, -8.20043767011717],
              [-35.967613391125539, -8.200510967743211],
              [-35.967622548098291, -8.20059635995681],
              [-35.967649256818852, -8.200798700816904],
              [-35.967666004994769, -8.200941763035617],
              [-35.967680199391566, -8.201058375051682],
              [-35.967698057241925, -8.201192124276643],
              [-35.967709723191547, -8.201297914360877],
              [-35.967719887813985, -8.201372458587278],
              [-35.967734270034263, -8.201514363819987],
              [-35.967761790211355, -8.201728261898118],
              [-35.967768336876411, -8.201804007162336],
              [-35.967779723160398, -8.201884324001501],
              [-35.968017233761692, -8.201840657036923],
              [-35.968125719695912, -8.201821789999089],
              [-35.968196813453254, -8.201810425537449],
              [-35.968475142616313, -8.201756701024317],
              [-35.968627014037352, -8.201731551810768],
              [-35.968899385078181, -8.201681394144506],
              [-35.968981332036037, -8.20166633603241],
              [-35.969048899038583, -8.201656262159453],
              [-35.969315203184891, -8.201607233019631],
              [-35.969416426734959, -8.201587244823147],
              [-35.969722490182434, -8.201532050364131],
              [-35.969840577835875, -8.201511937736431],
              [-35.970055174169318, -8.201471871509151],
              [-35.970180497042072, -8.201449266323106],
              [-35.97037743522327, -8.20141231130585],
              [-35.97037743522327, -8.20141231130585],
              [-35.970429315911623, -8.2014022625671],
              [-35.970533065954704, -8.201234192018502],
              [-35.970569965255777, -8.201172580590738],
              [-35.970750016155804, -8.200876390517781],
              [-35.970869228596648, -8.200678213832294],
              [-35.971063589242469, -8.200355449302984],
              [-35.971187534975961, -8.200147571588843],
              [-35.971237556130156, -8.200058129738727],
              [-35.971368504723479, -8.199816504445801],
              [-35.971444715379072, -8.199676280362899],
              [-35.97150476000013, -8.199630126648916],
              [-35.971737735888219, -8.199476821682497],
              [-35.971910722156537, -8.19936488209798],
              [-35.972078847205232, -8.199257675885304],
              [-35.97222776818689, -8.199165155657408],
              [-35.97229364100415, -8.199573808501571],
              [-35.972394941033492, -8.200222949459802],
              [-35.972446021086, -8.200593137170852],
              [-35.972526804569782, -8.201104664556308],
              [-35.972541972169267, -8.201205640326682],
              [-35.972537918308632, -8.201306757944378],
              [-35.972493798902264, -8.201860671974366],
              [-35.972468066212926, -8.202143618381326],
              [-35.972763037282839, -8.202206393927939],
              [-35.97298737499213, -8.202257043382092],
              [-35.973271911031581, -8.202221246999935],
              [-35.973328973220248, -8.202261748611077],
              [-35.973413940928047, -8.202323725173757],
              [-35.973464626854764, -8.202360298976853],
              [-35.973560179055092, -8.202382448869066],
              [-35.973638731421097, -8.202398671635711],
              [-35.973873886161961, -8.202452944487989],
              [-35.974015388034822, -8.202484421054182],
              [-35.974170054155913, -8.202518149110261],
              [-35.974287320933726, -8.202546191043757],
              [-35.974427272054371, -8.202576414168503],
              [-35.97454206393504, -8.20260086079888],
              [-35.974638794836999, -8.202623001677411],
              [-35.974831521910581, -8.202666024072801],
              [-35.974974211230652, -8.202698665864588],
              [-35.975114443788613, -8.202730151349943],
              [-35.975207439196318, -8.202749880570519],
              [-35.975294550635503, -8.202770917946905],
              [-35.975411262685469, -8.20279751819726],
              [-35.975482266514121, -8.202774138261759],
              [-35.975550949281541, -8.202755653666996],
              [-35.975647312380126, -8.202728382497552],
              [-35.9757509120398, -8.202698799413797],
              [-35.975913128974533, -8.202650625362145],
              [-35.975964707779653, -8.202770573445255],
              [-35.976029754736537, -8.202921407663155],
              [-35.976069042168184, -8.203010189858977],
              [-35.976121817938804, -8.203132568153631],
              [-35.976180289115916, -8.203264841429773],
              [-35.976214681131644, -8.203341554572754],
              [-35.976250333226808, -8.203429189202778],
              [-35.976277264847326, -8.203490329132769],
              [-35.97628223891703, -8.203500771482075],
              [-35.97628223891703, -8.203500771482075],
              [-35.976295153671281, -8.203530396976996],
              [-35.976307427695254, -8.203559214175549],
              [-35.976316369074596, -8.203578841598404],
              [-35.976326154720667, -8.20360225695311],
              [-35.976353523145647, -8.2036246389713],
              [-35.976380165563683, -8.203646936007232],
              [-35.976380165563683, -8.203646936007232],
              [-35.976383541109982, -8.203649711514492],
              [-35.976387190012154, -8.203652665674708],
              [-35.976388923459723, -8.20365409825005],
              [-35.97639193431953, -8.203656605442532],
              [-35.976394124466303, -8.203658486335001],
              [-35.976396769975892, -8.203660634871817],
              [-35.976400784237548, -8.203663947678335],
              [-35.976405527872487, -8.203667797114058],
              [-35.976409816153762, -8.20367137890528],
              [-35.976412827013817, -8.203673886097512],
              [-35.976416293909374, -8.203676751256859],
              [-35.976420216831499, -8.203679974401433],
              [-35.976423137024547, -8.20368248226375],
              [-35.976450506805058, -8.203705044925961],
              [-35.976473040939702, -8.203723578166413],
              [-35.976490013408181, -8.203737996998472],
              [-35.976507987252795, -8.203752950456701],
              [-35.976524407647929, -8.203766289316995],
              [-35.976732216187756, -8.203752376961338],
              [-35.976947534605308, -8.203736331216348],
              [-35.977090282123569, -8.203728048884905],
              [-35.977223345681395, -8.203722186872515],
              [-35.977361250938664, -8.203721167226185],
              [-35.977559319002651, -8.203714282429285],
              [-35.977556842480546, -8.203832190697668],
              [-35.977551953391789, -8.20389401744962],
              [-35.977551953391789, -8.20389401744962],
              [-35.977515973606948, -8.204553563888275],
              [-35.977515973606948, -8.204553563888275],
              [-35.977500839990789, -8.204761631880336],
              [-35.977487646191456, -8.204937977199714],
              [-35.977467574728173, -8.205176977008385],
              [-35.977459277034257, -8.205353286122127],
              [-35.977450851673581, -8.205524627635214],
              [-35.977444670423367, -8.205668580401678],
              [-35.977440674052247, -8.205777195192185],
              [-35.977438675639178, -8.205837555184972],
              [-35.977429872730603, -8.205958220364256],
              [-35.977425498775169, -8.206016158810021],
              [-35.977414033124035, -8.206108026139857],
              [-35.977410120916254, -8.206227932453404],
              [-35.977396926596349, -8.206404187458601],
              [-35.977389358276191, -8.206532341494389],
              [-35.977389358276191, -8.206532341494389],
              [-35.97750691557161, -8.206514036887055],
              [-35.977598114679552, -8.206499450381191],
              [-35.977638961567408, -8.206493185987357],
              [-35.977669843033503, -8.206488079341131],
              [-35.977701813183202, -8.206483054984806],
              [-35.977723650724677, -8.206481086704018],
              [-35.977764169932904, -8.206479522248408],
              [-35.977825620674778, -8.206476086541972],
              [-35.977909638329351, -8.206471219160131],
              [-35.977973817851606, -8.20646893762961],
              [-35.978027017787895, -8.206465562935433],
              [-35.978080589135175, -8.206463359863136],
              [-35.978108410756676, -8.206461347288709],
              [-35.978156814026654, -8.206459182443293],
              [-35.978197329175288, -8.206457075955413],
              [-35.978234221639482, -8.206455538287525],
              [-35.97827951996517, -8.206450415273844],
              [-35.97834122853196, -8.206445080505375],
              [-35.97838444620978, -8.206440605241331],
              [-35.978447329406237, -8.206434719746321],
              [-35.978521079293465, -8.206426947091153],
              [-35.978570728998228, -8.206421701520735],
              [-35.978626367500326, -8.206417043984528],
              [-35.978722136890781, -8.206407391956766],
              [-35.978804418290885, -8.206400730503647],
              [-35.978869762962916, -8.206396633471956],
              [-35.978962825083293, -8.206388717829709],
              [-35.979027899760453, -8.206384893781395],
              [-35.979078737538146, -8.206380904071539],
              [-35.979124053322636, -8.206378129608952],
              [-35.979174261136357, -8.206374776906037],
              [-35.979218394196643, -8.20637146916744],
              [-35.979263165968334, -8.2063686987072],
              [-35.979286177437146, -8.206366089301456],
              [-35.979299614468673, -8.206368428952391],
              [-35.97931286613273, -8.206370227946445],
              [-35.979319219620955, -8.2063710842953],
              [-35.979336738064397, -8.206373574409064],
              [-35.979347155451237, -8.206372232591548],
              [-35.979354129466493, -8.206371187270161],
              [-35.97936690219624, -8.206369557000013],
              [-35.979383025590685, -8.206367359918485],
              [-35.979396793641591, -8.206365451279751],
              [-35.979413460375639, -8.206363159829856],
              [-35.979429402433937, -8.206360964088853],
              [-35.979449695211294, -8.206358555470663],
              [-35.979468534604678, -8.206355796254163],
              [-35.979485293342577, -8.206353684802687],
              [-35.979501054064052, -8.206351490392334],
              [-35.979516272801192, -8.206349571011872],
              [-35.979528862171925, -8.206347671084641],
              [-35.979544806248228, -8.206345746334186],
              [-35.979553772943035, -8.206344415249985],
              [-35.979566454318757, -8.206342695322776],
              [-35.979579135703226, -8.206340975386102],
              [-35.979592451759828, -8.206339250760109],
              [-35.979592451759828, -8.206339250760109],
              [-35.979606763811049, -8.206337338088018],
              [-35.979618448517137, -8.206335715864427],
              [-35.979631583237989, -8.206333992579555],
              [-35.979640913948728, -8.206332839472115],
              [-35.979654863328946, -8.206330929474046],
              [-35.979673975392323, -8.206328258575997],
              [-35.979690914121619, -8.206325965111276],
              [-35.979710753544509, -8.206323559827942],
              [-35.979722618236352, -8.206321755605236],
              [-35.979735299617737, -8.206320035664112],
              [-35.979746532331617, -8.206318597465129],
              [-35.979761660386501, -8.206316678747491],
              [-35.979773253748697, -8.206314966868517],
              [-35.979785663800413, -8.206313339271032],
              [-35.979805319864568, -8.206310664340238],
              [-35.979818454581562, -8.206308941040806],
              [-35.979833039303792, -8.206307116679403],
              [-35.97983738597884, -8.206306361809665],
              [-35.979852687291185, -8.206303357759055],
              [-35.979868535302522, -8.206300711006872],
              [-35.979881664628664, -8.206298265062394],
              [-35.979901315973777, -8.206294957797693],
              [-35.979917346666078, -8.206292490364447],
              [-35.979929300673938, -8.20629050480203],
              [-35.979939898712338, -8.206289071296698],
              [-35.980072321041185, -8.206270113946069],
              [-35.980262162726888, -8.206242149453491],
              [-35.980340699630709, -8.206231901952082],
              [-35.980418094602165, -8.206214435946809],
              [-35.980461551810642, -8.206205622524434],
              [-35.980571596600825, -8.206189179463582],
              [-35.980688884687126, -8.206171327702753],
              [-35.980796499595712, -8.206157341672489],
              [-35.980878012298113, -8.206144903956918],
              [-35.981069150043311, -8.206120542999871],
              [-35.981173128078595, -8.206105228765276],
              [-35.981235997545866, -8.206097536229986],
              [-35.981313355645376, -8.206087297286986],
              [-35.981387096463315, -8.206078349840309],
              [-35.981475244676822, -8.206068121297458],
              [-35.981605888369664, -8.206053874174714],
              [-35.981668232676491, -8.206048714894727],
              [-35.981745608926715, -8.206040914841045],
              [-35.981838743135697, -8.206030558955096],
              [-35.981925811365919, -8.206021422357155],
              [-35.982095116113101, -8.20599966176747],
              [-35.982175820726376, -8.2059883138448],
              [-35.982231436699216, -8.205980674902868],
              [-35.982443064930436, -8.205956161543421],
              [-35.982530124318529, -8.20594585051821],
              [-35.982606313025336, -8.205936884728986],
              [-35.982796829090539, -8.205914334360603],
              [-35.982949215202282, -8.205897576982963],
              [-35.982968517942716, -8.205896169237594],
              [-35.983038767729433, -8.205893299938623],
              [-35.983118618150385, -8.205889004428293],
              [-35.983212985483995, -8.205885956382232],
              [-35.983396661153968, -8.205845931121551],
              [-35.983468708351914, -8.205828775242313],
              [-35.983544860538665, -8.205814931377178],
              [-35.983720257806112, -8.205795564102941],
              [-35.983830346553837, -8.205785082133453],
              [-35.983900559834126, -8.205777334781216],
              [-35.984082040496453, -8.205759096647046],
              [-35.984263412221445, -8.205738420149423],
              [-35.984333589035593, -8.205725794826531],
              [-35.984524608130869, -8.2056978197809],
              [-35.98458265370143, -8.205687723435689],
              [-35.984686505675711, -8.205655606708019],
              [-35.984756519571242, -8.205633316517565],
              [-35.984923204176788, -8.205588628789194],
              [-35.985093562226332, -8.205538041900269],
              [-35.985175707918316, -8.205513312944268],
              [-35.985250491325822, -8.205486199477983],
              [-35.985277075285254, -8.205476336344327],
              [-35.985366582300102, -8.205466006614483],
              [-35.985445148803571, -8.205605445576047],
              [-35.985481902278927, -8.205670305630113],
              [-35.98552610848332, -8.205749654583091],
              [-35.985584875243255, -8.205848227541981],
              [-35.985651202066272, -8.205975652092466],
              [-35.985697819928134, -8.206050104949499],
              [-35.985739515444429, -8.206121071172454],
              [-35.985805788381626, -8.206241269151814],
              [-35.985835252848183, -8.206301395360851],
              [-35.985879297218034, -8.206359064675508],
              [-35.985918498889554, -8.206423906478069],
              [-35.985977301869347, -8.206515161793881],
              [-35.986102215799669, -8.206717040489284],
              [-35.986148259469971, -8.206787432344001],
              [-35.986180126831982, -8.206841488136186],
              [-35.986365188747442, -8.206829274805097],
              [-35.986482549068285, -8.206821177160963],
              [-35.986555137212825, -8.206815760430676],
              [-35.9866277712689, -8.206816486211848],
              [-35.986648788554646, -8.206813981524302],
              [-35.986779377675973, -8.206792506194956],
              [-35.986884633917242, -8.206778445712697],
              [-35.986909966768266, -8.206771030819169],
              [-35.986983643728877, -8.20675359120095],
              [-35.986983643728877, -8.20675359120095],
              [-35.987057401899328, -8.20673488624748],
              [-35.987091257460222, -8.2067274081052],
              [-35.987165069470031, -8.206728034725511],
              [-35.987240151496543, -8.206728742233494],
              [-35.987339359123666, -8.206730354729517],
              [-35.987417446665219, -8.2067328466339],
              [-35.987474385747646, -8.20673242404315],
              [-35.987468509611801, -8.206759026532479],
              [-35.987447570759493, -8.206869030920911],
              [-35.98742767482689, -8.206960869970876],
              [-35.987395395085841, -8.207021454222327],
              [-35.987356075697285, -8.207086426866452],
              [-35.987333398063775, -8.207133660406848],
              [-35.987376973294658, -8.207128549167976],
              [-35.987449579670212, -8.207125571203685],
              [-35.987534173201283, -8.207112928598788],
              [-35.987632203051469, -8.207102534992957],
              [-35.98772035907897, -8.207081283962639],
              [-35.98784319814515, -8.207054084282165],
              [-35.987909621659497, -8.207036698325808],
              [-35.987980977012228, -8.207024063576204],
              [-35.988055941494238, -8.2070090532712],
              [-35.988128222414893, -8.206998941040503],
              [-35.988197148642719, -8.206988853704043],
              [-35.98826115990498, -8.206976273434694],
              [-35.988333693892464, -8.206963629870316],
              [-35.988409855230763, -8.206951049707278],
              [-35.988483016435062, -8.206937407777128],
              [-35.988543518675897, -8.206928466964801],
              [-35.988582197793953, -8.206923391961846],
              [-35.988672919251968, -8.206917840209101],
              [-35.988745489671359, -8.206910074468794],
              [-35.988842286491668, -8.206904567911705],
              [-35.988920930932125, -8.206896666701663],
              [-35.988971773629508, -8.206893398407452],
              [-35.988971773629508, -8.206893398407452],
              [-35.989070908845967, -8.206885345012575],
              [-35.989072354669503, -8.206751365684065],
              [-35.989073100276194, -8.206681349588807],
              [-35.989076185669703, -8.206608967378632],
              [-35.98907956434649, -8.20657578890186],
              [-35.989082820712859, -8.206526260475243],
              [-35.989087093685427, -8.206442938762605],
              [-35.989091013808867, -8.206324569223574],
              [-35.989095332060856, -8.206247299700022],
              [-35.989095939057052, -8.206170870766689],
              [-35.989101282488463, -8.206085192366837],
              [-35.989101937588103, -8.206003071885796],
              [-35.989108323992902, -8.205887123118663],
              [-35.989093284815965, -8.205816049849593],
              [-35.989077070353254, -8.20574543699272],
              [-35.989068259261842, -8.205694823805702],
              [-35.989054624448073, -8.2056539124002],
              [-35.989029838668635, -8.205576858966074],
              [-35.988991503246432, -8.205470275846283],
              [-35.98896678101584, -8.205401713528694],
              [-35.988943237588558, -8.205321037411991],
              [-35.988926991993402, -8.205258374340124],
              [-35.988903280235533, -8.205167310793067],
              [-35.988875875074186, -8.20505522631912],
              [-35.988858369767868, -8.204981732246605],
              [-35.988840864481745, -8.204908238179748],
              [-35.988827035189132, -8.204841311351736],
              [-35.988813354526577, -8.204794257395934],
              [-35.988802149568642, -8.204750888845844],
              [-35.988793103345856, -8.204680945422147],
              [-35.988785462240216, -8.204629149145923],
              [-35.988765173552721, -8.204486568493763],
              [-35.988760860557335, -8.204394818887511],
              [-35.988759759476949, -8.204247578992566],
              [-35.988776362207503, -8.204200390578697],
              [-35.988800056140292, -8.204131468822021],
              [-35.988831002724652, -8.204062402901902],
              [-35.988869197902865, -8.20399265081519],
              [-35.988897822793263, -8.203940495009251],
              [-35.988945571330937, -8.203850888384004],
              [-35.988966574668318, -8.203810171504001],
              [-35.988990267889882, -8.203741159433285],
              [-35.989006708468452, -8.203672291529383],
              [-35.989017450793995, -8.203641678150037],
              [-35.989033900142523, -8.203573984548122],
              [-35.989068076130842, -8.203451776973436],
              [-35.989065176907609, -8.203391453894428],
              [-35.989063447710031, -8.203317842713414],
              [-35.98905806484872, -8.203240645203419],
              [-35.989052167126921, -8.20310672037119],
              [-35.989043828340861, -8.202961611954041],
              [-35.98904205460466, -8.2028820389105],
              [-35.989035104585135, -8.202765014750264],
              [-35.989026831376968, -8.202628668457457],
              [-35.989014629625089, -8.202451971027013],
              [-35.989007498659852, -8.202310738098166],
              [-35.988999288340253, -8.202182792595204],
              [-35.98900714300644, -8.202093391780577],
              [-35.989012667204804, -8.202019726756406],
              [-35.989018073724203, -8.201942449155821],
              [-35.989030489899534, -8.201820403109997],
              [-35.989043778801999, -8.201657428271227],
              [-35.989050514096668, -8.201588090398237],
              [-35.989059683190575, -8.2015168374114],
              [-35.989106988571329, -8.201367973598321],
              [-35.989129413121894, -8.201299061314952],
              [-35.989135334439261, -8.201278511053356],
              [-35.989145856928253, -8.201242750158054],
              [-35.989167157025044, -8.201168968071185],
              [-35.989211521872505, -8.201111907684428],
              [-35.989339937186102, -8.200969759188411],
              [-35.98940187782749, -8.200898656589834],
              [-35.989446242615948, -8.20084159619933],
              [-35.989527737137401, -8.20072996819923],
              [-35.989637955598361, -8.20057946312768],
              [-35.989657123704468, -8.200547974214857],
              [-35.989692830763623, -8.200472910849182],
              [-35.989692830763623, -8.200472910849182],
              [-35.989733288556565, -8.200378389946643],
              [-35.989788163692424, -8.200271837664168],
              [-35.989693545691672, -8.200241102909226],
              [-35.989586905463618, -8.20022744054854],
              [-35.989378538509833, -8.20020242801878],
              [-35.989295528642572, -8.200111352756222],
              [-35.989239264336106, -8.20003209371724],
              [-35.989205710354788, -8.199958538012025],
              [-35.989161450610176, -8.199871962977078],
              [-35.989061017141253, -8.199718233226127],
              [-35.989021833677462, -8.199655740268263],
              [-35.988982631997693, -8.199590808352212],
              [-35.98891196985452, -8.199538305237661],
              [-35.988753817132427, -8.199426016217554],
              [-35.988595835692173, -8.199312370786005],
              [-35.988403449324068, -8.199168988813378],
              [-35.988257463368271, -8.199071063056302],
              [-35.988152802273284, -8.198994601779079],
              [-35.988166316617246, -8.198861707172171],
              [-35.988175490039175, -8.198790996148299],
              [-35.988184361771516, -8.198679907034162],
              [-35.988216466630874, -8.198438199998986],
              [-35.988220676643067, -8.198358492158434],
              [-35.988236910279099, -8.198261892588604],
              [-35.988252980604592, -8.198143432859034],
              [-35.988271246260211, -8.198003276131214],
              [-35.988280406874772, -8.197930848820512],
              [-35.988298992648517, -8.197821404065085],
              [-35.988310221724284, -8.19771029749017],
              [-35.98835775288206, -8.197591604447426],
              [-35.988407534245297, -8.197446426193482],
              [-35.988428716874388, -8.197369031577436],
              [-35.988457315589258, -8.197301247954506],
              [-35.988555223216629, -8.197117048879223],
              [-35.988685330300967, -8.197043634290743],
              [-35.988791410871151, -8.196982502926769],
              [-35.988969384009948, -8.196883800472349],
              [-35.989065835549518, -8.196832406463571],
              [-35.989203052048062, -8.196727592369296],
              [-35.989354702625242, -8.196613005206382],
              [-35.989412512352132, -8.196571563750989],
              [-35.989465425493179, -8.196530068279376],
              [-35.989465425493179, -8.196530068279376],
              [-35.989472217936125, -8.196529024198178],
              [-35.989480591709594, -8.196521193166204],
              [-35.989565224536257, -8.196526256551286],
              [-35.989675877497291, -8.196531036547398],
              [-35.989753488879785, -8.196555032236953],
              [-35.98982435935077, -8.196574832121128],
              [-35.989896338727277, -8.196597424180322],
              [-35.989967673320201, -8.196618665962488],
              [-35.990026283795459, -8.196635846666917],
              [-35.990088440312903, -8.196654356104101],
              [-35.99012306261416, -8.19666466855513],
              [-35.990165453538253, -8.19668323428246],
              [-35.990236851617873, -8.196712967138465],
              [-35.990303977654861, -8.196741286297923],
              [-35.990344730544074, -8.196759051133951],
              [-35.990355921103848, -8.196654991197752],
              [-35.990366433620643, -8.19655725983044],
              [-35.990371755717682, -8.196517201435995],
              [-35.990382441094013, -8.196442594838889],
              [-35.990389734848627, -8.19639945038246],
              [-35.990389734848627, -8.19639945038246],
              [-35.990398839016585, -8.196368036189364],
              [-35.990420599368967, -8.196270944058648],
              [-35.990439728904583, -8.196185795810418],
              [-35.990458720507348, -8.196094325069716],
              [-35.99047512855234, -8.196021121395724],
              [-35.990488603605073, -8.195943693681045],
              [-35.99049078425093, -8.195859393897202],
              [-35.99049977495244, -8.195727888117572],
              [-35.990502123729996, -8.195666080777089],
              [-35.990501545777377, -8.195588757338964],
              [-35.990502906033996, -8.19552813170648],
              [-35.990502906033996, -8.19552813170648],
              [-35.990503976649542, -8.195343838061349],
              [-35.990504009471138, -8.195154131945719],
              [-35.990499212863234, -8.195082530982996],
              [-35.990503196616068, -8.195045373268712],
              [-35.990502136000856, -8.194903463079521],
              [-35.990501296165952, -8.194791091163914],
              [-35.990503581527527, -8.194720792701006],
              [-35.990500120137924, -8.194633734341448],
              [-35.990496644579252, -8.194544779036228],
              [-35.99049166912431, -8.194449240323703],
              [-35.990486847803218, -8.194362192050505],
              [-35.990480627833797, -8.194282110047483],
              [-35.990469842211027, -8.19416132030827],
              [-35.990459036355823, -8.194037820626535],
              [-35.99045299396132, -8.193981495702849],
              [-35.990434744613189, -8.193796170977921],
              [-35.990415265408309, -8.193640395293084],
              [-35.990411887825289, -8.193564537981217],
              [-35.990423982091137, -8.193484320121867],
              [-35.990452583488761, -8.193356282331122],
              [-35.990463372032423, -8.193307601461534],
              [-35.990499430159581, -8.193218894925106],
              [-35.990525661040031, -8.19314977388294],
              [-35.990562935797833, -8.19305401210398],
              [-35.990612534309804, -8.192945150515605],
              [-35.990638807692982, -8.19288172034884],
              [-35.990666520886386, -8.192816834113476],
              [-35.99069982418581, -8.192747750961081],
              [-35.990756905466455, -8.19265744311808],
              [-35.990807068392307, -8.192575587851836],
              [-35.99086158002585, -8.192517549057396],
              [-35.990913312796707, -8.192463776679421],
              [-35.990964233633747, -8.192410552336792],
              [-35.991094420146275, -8.192287361870996],
              [-35.991172740702694, -8.192212253595038],
              [-35.991230168119145, -8.192168285590267],
              [-35.991296138843502, -8.192127054623656],
              [-35.991397098029623, -8.192060269981333],
              [-35.991531727554872, -8.191973542265846],
              [-35.99159759673973, -8.191930866645876],
              [-35.991763096739426, -8.191825661975887],
              [-35.991892073203879, -8.191734730346662],
              [-35.991965168182958, -8.191700492670254],
              [-35.992043772690884, -8.191663413681013],
              [-35.992248668498689, -8.191552225412794],
              [-35.992350292182294, -8.191501605845401],
              [-35.992419024407866, -8.191465955112253],
              [-35.992452726407159, -8.191450257561625],
              [-35.992560966931542, -8.191411513211554],
              [-35.99268648767076, -8.191367762454934],
              [-35.99268648767076, -8.191367762454934],
              [-35.992538431206768, -8.191210773225073],
              [-35.992448682641779, -8.191115502399988],
              [-35.991971919926293, -8.190650737942654],
              [-35.991766892517752, -8.190440962947232],
              [-35.991637557686033, -8.190496033652984],
              [-35.991637557686033, -8.190496033652984],
              [-35.991377515319904, -8.190628769171873],
              [-35.991312743442826, -8.190648400849724],
              [-35.991239307012428, -8.190636930839293],
              [-35.991021246753505, -8.19060015529203],
              [-35.99080809176867, -8.190564607942223],
              [-35.990453966716018, -8.190495236024873],
              [-35.990140803080081, -8.190435226035081],
              [-35.990081337265394, -8.190534043012205],
              [-35.990038663573621, -8.190611054814511],
              [-35.989764472133473, -8.190646150633009],
              [-35.989769044961221, -8.190505734267141],
              [-35.989772340282627, -8.190461354760281],
              [-35.989776249604482, -8.190341359309084],
              [-35.989774754780122, -8.190141185357978],
              [-35.989774754780122, -8.190141185357978],
              [-35.989774091691011, -8.190052389783645],
              [-35.989772586802786, -8.189850860867521],
              [-35.989778245967592, -8.189637354465441],
              [-35.989776311966992, -8.189621198615301],
              [-35.989780687458584, -8.189563622014997],
              [-35.989801967047455, -8.189353522947153],
              [-35.989808656228711, -8.189277952295726],
              [-35.989810566566227, -8.189205940182049],
              [-35.989813831209815, -8.188999588087977],
              [-35.989813831209815, -8.188999588087977],
              [-35.989815877891516, -8.188885116946816],
              [-35.989854675904375, -8.188871911300152],
              [-35.989922416633917, -8.188849186491213],
              [-35.989943397182294, -8.18884189442721],
              [-35.9899662991917, -8.188836936849331],
              [-35.990047307730968, -8.188818269146418],
              [-35.990066487827825, -8.188812706803278],
              [-35.990191181931635, -8.188755412715278],
              [-35.990275130647468, -8.188717662246951],
              [-35.990273521661244, -8.18866003969686],
              [-35.990271697238484, -8.188585706557888],
              [-35.990269954705539, -8.188510198437658],
              [-35.990268930106922, -8.188372985389314],
              [-35.990266656431253, -8.188299197584536],
              [-35.990264832035706, -8.18822486444334],
              [-35.990263098299167, -8.188150530620629],
              [-35.990260328946903, -8.188095355752724],
              [-35.990261122432379, -8.188043767913319],
              [-35.990264605958522, -8.188024590821593],
              [-35.990273947097009, -8.187988477451931],
              [-35.990275954781154, -8.187978073913257],
              [-35.990287743813312, -8.187941942398611],
              [-35.990308892876058, -8.187872226776662],
              [-35.990330014954765, -8.187798897917183],
              [-35.990352789197352, -8.187728266902651],
              [-35.990352789197352, -8.187728266902651],
              [-35.990318424172223, -8.187545861887777],
              [-35.990300952828825, -8.187452403112767],
              [-35.990285874193667, -8.18737581946545],
              [-35.990272864046311, -8.187321262506483],
              [-35.990264102235038, -8.187264957668749],
              [-35.990257776947637, -8.187231399497849],
              [-35.990239072806304, -8.187142827995459],
              [-35.990217805077926, -8.187026632621302],
              [-35.990202870435297, -8.186957184443294],
              [-35.990182807373429, -8.186856608289869],
              [-35.990165354356762, -8.186765588432712],
              [-35.99016004092659, -8.186746115188015],
              [-35.990155319501959, -8.186672345517804],
              [-35.990149864020033, -8.186597406912941],
              [-35.990144513360029, -8.186524364590351],
              [-35.990136401569295, -8.186397231352771],
              [-35.99012978098434, -8.186324108103117],
              [-35.990119250575724, -8.186164471745789],
              [-35.99011915212315, -8.186151283399042],
              [-35.99006073951788, -8.186136088082758],
              [-35.989957096772841, -8.186110477903572],
              [-35.989926299499203, -8.186102304844011],
              [-35.989874496489094, -8.186085886152284],
              [-35.989782789506137, -8.186055941674997],
              [-35.989714070026608, -8.186032511740049],
              [-35.989644189465409, -8.186011439133361],
              [-35.989617644566948, -8.186002060163254],
              [-35.989550156964739, -8.185985757622648],
              [-35.989477864258042, -8.185969490678417],
              [-35.989371773658874, -8.185943898455575],
              [-35.989293479023225, -8.185925236844165],
              [-35.989221168795218, -8.185906621226044],
              [-35.988951309106135, -8.185841410026926],
              [-35.988871709490304, -8.185817970166779],
              [-35.988806687998988, -8.185804088299053],
              [-35.988543900694388, -8.185738914719213],
              [-35.98844994070808, -8.185710793287814],
              [-35.988382434940824, -8.185692051581826],
              [-35.988382434940824, -8.185692051581826],
              [-35.988203164324425, -8.185640622715628],
              [-35.988029531268992, -8.185603515493225],
              [-35.987575938745479, -8.185496392506133],
              [-35.987177957041638, -8.185405659851479],
              [-35.986981694093664, -8.185373507445624],
              [-35.986827406811955, -8.185353059014087],
              [-35.986740661357317, -8.185344035038531],
              [-35.986369456447044, -8.18530595013585],
              [-35.986198305896352, -8.18528562623125],
              [-35.985904326813788, -8.185256635399252],
              [-35.985776601833031, -8.185247914413663],
              [-35.985633487544611, -8.185230273589085],
              [-35.985438292047384, -8.185219702894933],
              [-35.985108120313285, -8.185188540107879],
              [-35.984773089563824, -8.185150186039321],
              [-35.984580270539659, -8.185130021654357],
              [-35.984240434614421, -8.185091702645437],
              [-35.984068576800887, -8.185073822081231],
              [-35.983784187704764, -8.185054244036785],
              [-35.983625095152007, -8.185033829795275],
              [-35.98354079815666, -8.185024877321444],
              [-35.983355195740316, -8.184999780762784],
              [-35.983159874912026, -8.184972407198673],
              [-35.982926113068032, -8.184945317669055],
              [-35.982750139714298, -8.184922679064892],
              [-35.982490958751129, -8.184903455759907],
              [-35.982322236494561, -8.184892687820987],
              [-35.982086151737583, -8.184882417586619],
              [-35.981792386654995, -8.184882239412849],
              [-35.981522592699378, -8.184874566650453],
              [-35.98133358528996, -8.184854914460711],
              [-35.981266715156167, -8.184970316983698],
              [-35.98124806666403, -8.185047331445443],
              [-35.981229096498787, -8.185410083899081],
              [-35.981213524598985, -8.185681209630937],
              [-35.981213995183481, -8.185744441986097],
              [-35.981213995183481, -8.185744441986097],
              [-35.981199732580841, -8.185972196340936],
              [-35.981030936518678, -8.185963777318353],
              [-35.980877925240733, -8.185956506340979],
              [-35.980851451590226, -8.185956701906342],
              [-35.980742921560598, -8.185932299605188],
              [-35.980586104945033, -8.185901026987944],
              [-35.980522987161422, -8.185887129620852],
              [-35.980385606816114, -8.185872516023206],
              [-35.980227782890367, -8.185852091119919],
              [-35.980131318521863, -8.185840788725523],
              [-35.980057566265415, -8.185823356337185],
              [-35.979992417612898, -8.185804595672611],
              [-35.979854653564409, -8.185738402285718],
              [-35.97973627203875, -8.18568164143054],
              [-35.979707171745751, -8.185657826658568],
              [-35.979707171745751, -8.185657826658568],
              [-35.979464887485861, -8.185460241771493],
              [-35.979401864350947, -8.185410299016548],
              [-35.979336448382625, -8.185355586035506],
              [-35.979336680077076, -8.185069757908119],
              [-35.979361380862635, -8.18481898057648],
              [-35.979372081424849, -8.184636330405956],
              [-35.979378672816061, -8.184559495250555],
              [-35.979385300458858, -8.184475342534306],
              [-35.97939841270415, -8.184299991866723],
              [-35.979404933557781, -8.184201476345018],
              [-35.979422452607565, -8.183960327779426],
              [-35.979426273729374, -8.183852346808118],
              [-35.979580456220397, -8.183834315615352],
              [-35.979671898706265, -8.183828762375779],
              [-35.97971221946586, -8.183825212580674],
              [-35.979936446554625, -8.183789409788892],
              [-35.980104901241866, -8.18376422676482],
              [-35.980244360599315, -8.183741516185826],
              [-35.980367048000389, -8.183719019750237],
              [-35.980439091843202, -8.183689670283343],
              [-35.980746308771543, -8.18354810208565],
              [-35.980897469245576, -8.183477336001996],
              [-35.98113728785566, -8.183331477480882],
              [-35.981199488158168, -8.183283049199131],
              [-35.981506401844527, -8.183088635778837],
              [-35.981568636997238, -8.183044904733945],
              [-35.981636949966465, -8.183013775945843],
              [-35.981826437099897, -8.182903068657469],
              [-35.981881590519862, -8.182870230308337],
              [-35.981948766396393, -8.182832515299772],
              [-35.982014772382101, -8.182795983300538],
              [-35.982259466393728, -8.1826596640798],
              [-35.982328990661827, -8.18262075730007],
              [-35.982389011605463, -8.182596284276713],
              [-35.982513876711188, -8.182549741674315],
              [-35.982747459558347, -8.182540788898036],
              [-35.983188151716568, -8.182523078587446],
              [-35.983621591358677, -8.18250551174034],
              [-35.984050225024355, -8.182487889630458],
              [-35.984287545480804, -8.182481708795597],
              [-35.98432846218769, -8.182473005000617],
              [-35.984467618157183, -8.18239753883838],
              [-35.984534784824902, -8.1823586491986],
              [-35.984728725160743, -8.18221303807551],
              [-35.984762273141996, -8.182188850832377],
              [-35.984819739417873, -8.182137927775795],
              [-35.984879563560519, -8.182087077617993],
              [-35.985080596943014, -8.181907808755026],
              [-35.985210902131428, -8.181800519076234],
              [-35.98499591543014, -8.181713217463111],
              [-35.984875453887334, -8.181705706794782],
              [-35.984794729158445, -8.181701515770815],
              [-35.984664557369186, -8.181692812113482],
              [-35.984521867495395, -8.181683297576001],
              [-35.984439955395999, -8.181677940884875],
              [-35.984280953088437, -8.181669450211922],
              [-35.984082115204785, -8.18165655641659],
              [-35.984007364708091, -8.181651056351386],
              [-35.983931545092943, -8.181648093607524],
              [-35.983864947438242, -8.181641629900286],
              [-35.983654150872518, -8.18162999864386],
              [-35.983570968797927, -8.181624560808268],
              [-35.983501132851124, -8.181621553758413],
              [-35.983444432803587, -8.181617094575895],
              [-35.983213119672691, -8.181602001217803],
              [-35.983138477956032, -8.181598939278535],
              [-35.983029993088302, -8.181592604250868],
              [-35.982957237111911, -8.181587089252474],
              [-35.982864391716319, -8.181574586056529],
              [-35.98277880802312, -8.181563203575577],
              [-35.98270647912841, -8.181554162236658],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0352000',
        uf: 'PE',
        nome_proje: 'PA OLINDA',
        municipio: 'CABO DE SANTO AGOSTINHO',
        area_hecta: '145.7211',
        capacidade: 17.0,
        num_famili: 16.0,
        fase: 4.0,
        data_de_cr: '13/02/2006',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 145.5029,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.126492135193573, -8.304670133746264],
              [-35.121862412677146, -8.310089003425963],
              [-35.12412813189222, -8.311436920162826],
              [-35.124098569832867, -8.315436234559106],
              [-35.12838478224635, -8.317468607654382],
              [-35.132687673663639, -8.321335834537155],
              [-35.133028101588025, -8.323698280028585],
              [-35.138192824979775, -8.324070555038292],
              [-35.134819927458764, -8.319933470137006],
              [-35.135853728879987, -8.319073804593168],
              [-35.135865822305547, -8.319064627931228],
              [-35.135771568455368, -8.318906448689106],
              [-35.135625069103959, -8.318718584711926],
              [-35.135286498820896, -8.318370689438073],
              [-35.134700176643953, -8.317089346430027],
              [-35.133119962134579, -8.315806429549781],
              [-35.131726507241837, -8.315254547309198],
              [-35.132749117940534, -8.309906975813576],
              [-35.130575737958246, -8.308163046357672],
              [-35.128101326427952, -8.305937065863448],
              [-35.126492135193573, -8.304670133746264],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0253000',
        uf: 'PE',
        nome_proje: 'PA GUTIÚBA',
        municipio: 'ITAQUITINGA',
        area_hecta: '445.0000',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 4.0,
        data_de_cr: '31/05/2002',
        forma_obte: 'Desapropriação',
        data_obten: '19/06/2001',
        area_calc_: 379.314,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.098787046636289, -7.661916470239857],
              [-35.098542484789668, -7.661982093921098],
              [-35.09813118837738, -7.662070007227753],
              [-35.097811187306085, -7.662117205559092],
              [-35.096873114650862, -7.662285382605869],
              [-35.091274442579319, -7.663309504662676],
              [-35.090477617240246, -7.663336655091673],
              [-35.089606419338544, -7.66348048737513],
              [-35.089331362802952, -7.663363696952256],
              [-35.088637140839062, -7.663377804269161],
              [-35.088449373721481, -7.663502210035753],
              [-35.086420393966627, -7.663517417656498],
              [-35.086274045963741, -7.663578024582729],
              [-35.086060428522927, -7.663648026450411],
              [-35.085802331455355, -7.66480368715581],
              [-35.085594603108007, -7.66525034977638],
              [-35.08521412723907, -7.66559046237887],
              [-35.083830922545829, -7.666697879653097],
              [-35.083359429540408, -7.666780072562703],
              [-35.082986361267629, -7.666787878310534],
              [-35.082033949596678, -7.666835442349924],
              [-35.081743658752998, -7.666906648489761],
              [-35.081362473215414, -7.667101789487192],
              [-35.080986043133976, -7.667168042474838],
              [-35.080522769697801, -7.667084372819192],
              [-35.080280499308017, -7.667069436034431],
              [-35.079979547922413, -7.667162170111007],
              [-35.079786341692923, -7.667275860730036],
              [-35.079465113230349, -7.667626422260135],
              [-35.079299785315818, -7.667761521784408],
              [-35.079057428390293, -7.667864730505601],
              [-35.079072054876811, -7.668702195830887],
              [-35.079233603740271, -7.671609276846902],
              [-35.079206762908463, -7.672731604821721],
              [-35.079099292621891, -7.673598382770222],
              [-35.079201247452218, -7.674085032437518],
              [-35.079261418395362, -7.674287562646012],
              [-35.07963551974435, -7.674479050372653],
              [-35.079993946402602, -7.674398565623267],
              [-35.080160060330847, -7.674616116093162],
              [-35.0804372835929, -7.674808071717478],
              [-35.080867967109221, -7.674852842900834],
              [-35.081505640228478, -7.675048995731721],
              [-35.083667185864698, -7.675840347194666],
              [-35.08417570980879, -7.675911710301757],
              [-35.084450787906675, -7.676031186779203],
              [-35.084879535999015, -7.676311001525514],
              [-35.085202876625118, -7.676385429040172],
              [-35.085501798205534, -7.676332141276072],
              [-35.086129584533623, -7.675934447707948],
              [-35.086379853985122, -7.675933232947853],
              [-35.086678994801822, -7.676020374957027],
              [-35.086901654708221, -7.676127523307188],
              [-35.086965886271472, -7.676211748092088],
              [-35.087117820436752, -7.67646396601112],
              [-35.087240560938213, -7.676616097897909],
              [-35.087510771638328, -7.676840894443747],
              [-35.08806226874907, -7.677109068638753],
              [-35.088222590912032, -7.677200960451461],
              [-35.088364169451609, -7.677291750078421],
              [-35.088523878881453, -7.677421428952894],
              [-35.088920814227919, -7.677756574647649],
              [-35.089220918525108, -7.677979434578203],
              [-35.089923371783762, -7.678298775246958],
              [-35.090433284012036, -7.678451885312731],
              [-35.091737599485327, -7.678838811853485],
              [-35.092061474557255, -7.679030530706921],
              [-35.093306339640648, -7.679442287409369],
              [-35.094072172172382, -7.679664755372229],
              [-35.094509576954501, -7.679958606700467],
              [-35.094994711341592, -7.680107253138465],
              [-35.095862550310514, -7.680507731962598],
              [-35.096026056538427, -7.68051163233133],
              [-35.09618710017547, -7.680424937523432],
              [-35.09628007409308, -7.680451330388107],
              [-35.096347655807136, -7.680650336250189],
              [-35.096505558048619, -7.680746213204563],
              [-35.09659552294795, -7.680570600371345],
              [-35.096734578991402, -7.680527638337189],
              [-35.097073536531504, -7.680501821841976],
              [-35.097151746860469, -7.680398752009309],
              [-35.097272074952613, -7.680241112311056],
              [-35.097447993728885, -7.680306698589166],
              [-35.097497174023836, -7.680457470221615],
              [-35.097636278840952, -7.680696541423974],
              [-35.097805006658348, -7.680806985398883],
              [-35.09799878417094, -7.680810065983072],
              [-35.098228041341009, -7.680639813091099],
              [-35.098377495008897, -7.680659217934698],
              [-35.098657148419825, -7.680887389457103],
              [-35.09881127228806, -7.680830258742573],
              [-35.098899583580568, -7.680729152820096],
              [-35.099284566909908, -7.68041719440212],
              [-35.099708371613779, -7.680407069136594],
              [-35.099786082045512, -7.68056916758987],
              [-35.099529750517689, -7.680818693648827],
              [-35.099552661440775, -7.680963049393514],
              [-35.099675025849947, -7.681220177650444],
              [-35.099676390701831, -7.681498032227047],
              [-35.099613604794186, -7.681864793852596],
              [-35.099707554989109, -7.682089845126484],
              [-35.099818116752566, -7.682409449022605],
              [-35.099984350666482, -7.68255763423022],
              [-35.10021390432815, -7.682447783400372],
              [-35.100424185059026, -7.682523267478976],
              [-35.100727644808565, -7.682666754175449],
              [-35.100794200502527, -7.682803355664915],
              [-35.102168597026079, -7.68109556145985],
              [-35.10288466894287, -7.680287334184965],
              [-35.103388668087838, -7.679654982165373],
              [-35.103565802746587, -7.679557467705568],
              [-35.10389964185341, -7.679175620424913],
              [-35.104557387670837, -7.678307944319394],
              [-35.105542047417202, -7.677059141154498],
              [-35.101881834762089, -7.675058272151637],
              [-35.098181823006499, -7.672969586587154],
              [-35.100943005062092, -7.669870368478646],
              [-35.100633744232525, -7.669458042641966],
              [-35.100387866262288, -7.669118963884382],
              [-35.100155681921663, -7.66869122457255],
              [-35.100086302920296, -7.668445470301736],
              [-35.100081312163994, -7.668273788892931],
              [-35.099827012893613, -7.668275030108211],
              [-35.099772682234075, -7.667896759480667],
              [-35.099754430282715, -7.666584675313241],
              [-35.099990081340771, -7.662427064421028],
              [-35.09989941196762, -7.662389585940846],
              [-35.099786352146403, -7.66238208338305],
              [-35.099584437993826, -7.662364275483781],
              [-35.099414696999304, -7.662322148743748],
              [-35.099239679308951, -7.662301524875073],
              [-35.098994604571331, -7.662262449883249],
              [-35.098886374413496, -7.662142168099648],
              [-35.098804213116935, -7.6619711233237],
              [-35.098787046636289, -7.661916470239857],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0295000',
        uf: 'PE',
        nome_proje: 'PA RAIZ DE DENTRO',
        municipio: 'AMARAJI',
        area_hecta: '439.8677',
        capacidade: 36.0,
        num_famili: 32.0,
        fase: 4.0,
        data_de_cr: '13/05/2003',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/2002',
        area_calc_: 461.8268,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.44694630585078, -8.418295171282928],
              [-35.446805059693553, -8.418576556355802],
              [-35.446790369655147, -8.419101985807666],
              [-35.446627672890187, -8.419542690413053],
              [-35.446505725912274, -8.419782118576231],
              [-35.4463079914876, -8.420124010624598],
              [-35.446389949408626, -8.420522594405478],
              [-35.446128969929347, -8.421013719474415],
              [-35.446204697799203, -8.421499991554784],
              [-35.445965679790255, -8.421907901340322],
              [-35.445358262508819, -8.422016603400094],
              [-35.44489508880833, -8.422200970857897],
              [-35.444656358412601, -8.422939904091718],
              [-35.444457672407978, -8.423402673822869],
              [-35.444343054647355, -8.423811019483805],
              [-35.444122421888032, -8.424723226866876],
              [-35.443950311230708, -8.425010435427909],
              [-35.44377159701699, -8.425471354792469],
              [-35.443687368905906, -8.425439380615172],
              [-35.442556486767032, -8.424651586036092],
              [-35.442230425542775, -8.424304525474174],
              [-35.441857754799479, -8.424087017069278],
              [-35.441673136031653, -8.423868483126277],
              [-35.441489564697513, -8.423773484500693],
              [-35.441182065243524, -8.423511702049751],
              [-35.440745527322804, -8.423179931128333],
              [-35.44053367648177, -8.423328323816305],
              [-35.440348600790578, -8.42352603911959],
              [-35.439462655952774, -8.424248243759184],
              [-35.440245265396207, -8.425091067841599],
              [-35.440636328724132, -8.425868151709469],
              [-35.441022526778397, -8.426326125324548],
              [-35.441068448666542, -8.426798806754725],
              [-35.441030213580369, -8.426888173722533],
              [-35.440919370433832, -8.427510061468531],
              [-35.441033627930054, -8.428590857823535],
              [-35.44106408092361, -8.428867139534118],
              [-35.441230946998935, -8.429062771757639],
              [-35.441787698954009, -8.429497631719364],
              [-35.441792042826876, -8.429593330619763],
              [-35.443109044879535, -8.430786711235102],
              [-35.443576484461104, -8.431159440433991],
              [-35.44425322271988, -8.431714039711919],
              [-35.445388757090889, -8.432598268280667],
              [-35.445020528136858, -8.43338647510377],
              [-35.444721625954152, -8.433748222530975],
              [-35.444617231678478, -8.434101353258313],
              [-35.444410521032566, -8.434152988353663],
              [-35.444067292042959, -8.434159733268842],
              [-35.443831028587717, -8.434329888381988],
              [-35.443533248396953, -8.434671051871492],
              [-35.443360114153244, -8.435008863076229],
              [-35.443096498522131, -8.435668475503272],
              [-35.443193644151393, -8.436081953442402],
              [-35.443151097762239, -8.436652393283158],
              [-35.443168522155503, -8.437010993687084],
              [-35.443466908986196, -8.437243112804719],
              [-35.444774710835496, -8.437741170743521],
              [-35.445329350727754, -8.438075023262508],
              [-35.44561208804474, -8.438140759469896],
              [-35.446477441858242, -8.438781307560381],
              [-35.446663527661805, -8.439098353091412],
              [-35.447592558439474, -8.440050317642568],
              [-35.448441388463756, -8.440159111392763],
              [-35.448524440433154, -8.440116234084977],
              [-35.44864028832508, -8.440299906998943],
              [-35.449105190526183, -8.44063873452277],
              [-35.449599828040526, -8.441148893816973],
              [-35.449722262574916, -8.441507436899899],
              [-35.450439678535176, -8.442765897810265],
              [-35.45113947414098, -8.444475928756633],
              [-35.452022645513246, -8.44653972406282],
              [-35.452105759298789, -8.44657406971816],
              [-35.4520294002239, -8.446837046058794],
              [-35.452119397451042, -8.447739003506726],
              [-35.452292410881213, -8.448294936062835],
              [-35.453020305891755, -8.449340399989863],
              [-35.455469872173943, -8.451111326701044],
              [-35.455800811318014, -8.451159018613454],
              [-35.458100081230427, -8.451263043380456],
              [-35.460930521009978, -8.450652468211851],
              [-35.460594647333707, -8.449922682441484],
              [-35.46058842117958, -8.449296396271842],
              [-35.460313517527474, -8.448349006605067],
              [-35.459667151599788, -8.447518168889475],
              [-35.459349601090835, -8.446999499609451],
              [-35.459152443683962, -8.446537761567248],
              [-35.459081601705016, -8.44620474444481],
              [-35.459029673133188, -8.445309907719013],
              [-35.459202033306312, -8.444889720947465],
              [-35.45938837797496, -8.444690080294109],
              [-35.459140405610377, -8.443910306147448],
              [-35.457893168391955, -8.442353663966781],
              [-35.457222824653506, -8.441609225190273],
              [-35.457106862762728, -8.440709534793147],
              [-35.457573474208502, -8.438847364727662],
              [-35.459898977586469, -8.438722510288052],
              [-35.460005166831955, -8.438562410656955],
              [-35.460550125433748, -8.437510801344718],
              [-35.460626197540002, -8.437203630129432],
              [-35.460614011808801, -8.4369286403518],
              [-35.460684080652641, -8.435540499966319],
              [-35.460636725508877, -8.434667123088934],
              [-35.460595861399099, -8.434406833373757],
              [-35.460407579501954, -8.43408920214006],
              [-35.459720771956938, -8.433058586601749],
              [-35.459784078803416, -8.432989722670936],
              [-35.459565819251672, -8.432319274561729],
              [-35.45937335370261, -8.431383084230095],
              [-35.459142659206066, -8.430377753559311],
              [-35.458733826590155, -8.429191132604551],
              [-35.458568094964619, -8.428845536174979],
              [-35.458431485066384, -8.428239765389584],
              [-35.458309813912834, -8.427845332331254],
              [-35.458141086418507, -8.427093719472785],
              [-35.457706384724602, -8.425418393350569],
              [-35.45748099432825, -8.424524414086235],
              [-35.457414193730187, -8.423806402902464],
              [-35.457284111487319, -8.423263448202871],
              [-35.45714023214466, -8.423086072290829],
              [-35.457059658379535, -8.423031963879064],
              [-35.456341537254545, -8.421759461209254],
              [-35.454738682066818, -8.419277187259851],
              [-35.454452522339018, -8.419871915505812],
              [-35.454252077110219, -8.420369097710386],
              [-35.453924235664751, -8.420588587843095],
              [-35.453818792565748, -8.420757157273192],
              [-35.453724620919161, -8.421222279608717],
              [-35.453633418129385, -8.421409609582101],
              [-35.453491803301304, -8.421551443243334],
              [-35.452816267251549, -8.421993132985991],
              [-35.452640906666012, -8.422346021517169],
              [-35.450928656256458, -8.422081182235127],
              [-35.450806503020502, -8.421945895840329],
              [-35.450599909566108, -8.421783329577178],
              [-35.450326229203135, -8.421363260156973],
              [-35.449970799460566, -8.420932442535197],
              [-35.449495877102784, -8.420502001805749],
              [-35.449488992698363, -8.420399115137615],
              [-35.449218820670424, -8.420168764920062],
              [-35.447539995170835, -8.418179504773867],
              [-35.447131052675964, -8.418141337139573],
              [-35.44694630585078, -8.418295171282928],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0358000',
        uf: 'PE',
        nome_proje: 'PA SIRIGI',
        municipio: 'ALIANCA',
        area_hecta: '418.9277',
        capacidade: 41.0,
        num_famili: 36.0,
        fase: 3.0,
        data_de_cr: '26/07/2006',
        forma_obte: 'Desapropriação',
        data_obten: '24/05/2004',
        area_calc_: 403.8319,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.184924925989229, -7.569912192375073],
              [-35.183473103544877, -7.570101153285435],
              [-35.182516039487318, -7.570204270252379],
              [-35.1819257992614, -7.570414732179429],
              [-35.181822257621128, -7.570442655674083],
              [-35.181703555762077, -7.570428856192571],
              [-35.180935857240875, -7.570270480147252],
              [-35.180310400343465, -7.570266302691349],
              [-35.179958508551984, -7.570236340347126],
              [-35.179610122741273, -7.570364290131243],
              [-35.179219747242115, -7.570700863724041],
              [-35.178875377736439, -7.571091032289959],
              [-35.178631665786746, -7.57125370415591],
              [-35.178195934672175, -7.571339611033435],
              [-35.177118072268094, -7.570999907181911],
              [-35.176831784717422, -7.570973405327176],
              [-35.176395811930391, -7.570992255168832],
              [-35.174553726067003, -7.571434288979644],
              [-35.173842027664698, -7.571430185143411],
              [-35.173657917515335, -7.571443515077297],
              [-35.171494718741698, -7.571760370805467],
              [-35.170965662708056, -7.571815545316368],
              [-35.170766801390876, -7.572289536372786],
              [-35.170679041000916, -7.573187527667057],
              [-35.170755125703501, -7.573290780740082],
              [-35.170500579091147, -7.573455789779745],
              [-35.170046090087673, -7.573394308845209],
              [-35.169846621484517, -7.573367398094017],
              [-35.168942986695292, -7.572604822170929],
              [-35.168509613497662, -7.573510345844689],
              [-35.168368718866212, -7.575368589334013],
              [-35.165527089406218, -7.577167728013792],
              [-35.165561875811271, -7.578361487595683],
              [-35.16554221529006, -7.581062058239888],
              [-35.164761963709999, -7.581005268719177],
              [-35.162547829312771, -7.580898650165357],
              [-35.16180236699536, -7.5810983491033],
              [-35.161911015079653, -7.58134056696931],
              [-35.162000774051776, -7.581677869158735],
              [-35.162083477557779, -7.582382014927493],
              [-35.162183341781265, -7.582798489317587],
              [-35.162193227063867, -7.582889539992819],
              [-35.162242327182071, -7.583261279270356],
              [-35.162198121213216, -7.58351187543049],
              [-35.162077300185388, -7.583752297070752],
              [-35.161895735380483, -7.583950065699895],
              [-35.161328742708122, -7.584543267046314],
              [-35.161219135360021, -7.584681350937728],
              [-35.160172307231576, -7.58620236582602],
              [-35.160888518318188, -7.587316520119902],
              [-35.160945890852318, -7.58739812354991],
              [-35.162086064229477, -7.588629794898997],
              [-35.162150233229028, -7.588936510373157],
              [-35.162378693609242, -7.58981231984018],
              [-35.162447652236565, -7.589978800097359],
              [-35.161631516060361, -7.590341017948838],
              [-35.161036616313083, -7.590747531450051],
              [-35.161385276999418, -7.591371346953827],
              [-35.161918886860008, -7.59257145700197],
              [-35.162094300506659, -7.592978336397295],
              [-35.16116016700537, -7.593318123732208],
              [-35.160595797958528, -7.59393798442327],
              [-35.160781615594452, -7.594137166909056],
              [-35.161130752085512, -7.594543670512733],
              [-35.161844342344558, -7.595091291564381],
              [-35.162255786099649, -7.595382537657152],
              [-35.16252576803457, -7.595690424333874],
              [-35.162876349844844, -7.59484788132559],
              [-35.164365674499415, -7.592348653201151],
              [-35.164696273933522, -7.591750175789097],
              [-35.165125956889533, -7.591647949709354],
              [-35.165633180007248, -7.591744982051936],
              [-35.166086265713659, -7.591498551234413],
              [-35.166835439492075, -7.59127411566536],
              [-35.168464953433528, -7.590859328447246],
              [-35.168685596187132, -7.591224740042691],
              [-35.169371163749247, -7.591179600401978],
              [-35.170236420593248, -7.590444152385901],
              [-35.170573288338289, -7.589721141828167],
              [-35.171183690236056, -7.589611589965062],
              [-35.172453258805078, -7.589540459375077],
              [-35.172490940450011, -7.592784407206601],
              [-35.178320317150039, -7.59216830411328],
              [-35.178448953733039, -7.586677701810932],
              [-35.178167176680269, -7.580657738139215],
              [-35.178154735136694, -7.580512935294433],
              [-35.178133699685581, -7.580060673765839],
              [-35.177912573510547, -7.579901485332734],
              [-35.178029741069345, -7.579628903783698],
              [-35.178215767813185, -7.578880903770119],
              [-35.178198958400586, -7.578027579811305],
              [-35.17861440897309, -7.577768772807252],
              [-35.178878856399415, -7.577310417940923],
              [-35.181502330552149, -7.576204299830709],
              [-35.182303904969366, -7.575705167797156],
              [-35.183995687240994, -7.575320380554007],
              [-35.184688386926545, -7.575366510092697],
              [-35.185410294880676, -7.575518220030001],
              [-35.185784817088113, -7.575501278003397],
              [-35.185432946516002, -7.573532947177775],
              [-35.185313644531441, -7.572708678765651],
              [-35.185253908594014, -7.572179851511077],
              [-35.184924925989229, -7.569912192375073],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0309000',
        uf: 'PE',
        nome_proje: 'PA BOM JESUS',
        municipio: 'AMARAJI',
        area_hecta: '234.7640',
        capacidade: 25.0,
        num_famili: 22.0,
        fase: 4.0,
        data_de_cr: '02/03/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 240.224,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.435107889238097, -8.358642285625509],
              [-35.430048770837914, -8.36379735936297],
              [-35.432468571110661, -8.366763792639787],
              [-35.42964254766467, -8.36995499967513],
              [-35.429424114205268, -8.370189513164972],
              [-35.428987070581002, -8.369898388562303],
              [-35.428434225814499, -8.36937513729637],
              [-35.427874184545281, -8.368845072856759],
              [-35.42749242855151, -8.368483749092986],
              [-35.427141279005603, -8.36815173805096],
              [-35.423964227859088, -8.373522421127712],
              [-35.428308276913654, -8.376092322712784],
              [-35.431552094361358, -8.378106893544416],
              [-35.432126977849933, -8.378525496746372],
              [-35.437371568584908, -8.37493171575804],
              [-35.438254224598332, -8.375196126588403],
              [-35.438616964798634, -8.37541611886394],
              [-35.438632177114897, -8.375483535239782],
              [-35.433155410250748, -8.379274787465173],
              [-35.435270358823637, -8.380815747327341],
              [-35.438128356230223, -8.378635143386678],
              [-35.440513895151859, -8.377040909836849],
              [-35.440828224632028, -8.376817366435832],
              [-35.442364804945377, -8.375651060516228],
              [-35.44417918684475, -8.374110686811505],
              [-35.442896708084795, -8.372845781799807],
              [-35.446647580905875, -8.370273808831985],
              [-35.444902819935322, -8.368744352454556],
              [-35.441698004326973, -8.371581617350875],
              [-35.435143962417548, -8.365144594691007],
              [-35.43694569780267, -8.36281038848535],
              [-35.438079602338554, -8.361658521803163],
              [-35.435107889238097, -8.358642285625509],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0406000',
        uf: 'PE',
        nome_proje: 'PA CAVACO',
        municipio: 'MARAIAL',
        area_hecta: '530.9934',
        capacidade: 60.0,
        num_famili: 57.0,
        fase: 3.0,
        data_de_cr: '09/09/2013',
        forma_obte: 'Desapropriação',
        data_obten: '03/02/2004',
        area_calc_: 530.2591,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.70693890425045, -8.88321142678419],
              [-35.706907013892433, -8.883199351602917],
              [-35.706151623311754, -8.882913324224672],
              [-35.700838970702513, -8.880824609822056],
              [-35.698749378500601, -8.885722823204089],
              [-35.693031865644144, -8.9002663048241],
              [-35.692773543923842, -8.900972950317287],
              [-35.69325273793271, -8.901035080648871],
              [-35.693652472117662, -8.901214706008973],
              [-35.694023594167824, -8.901668496853461],
              [-35.694701970791407, -8.902241680713274],
              [-35.69534817115423, -8.902602127359634],
              [-35.696053024231546, -8.90259702017093],
              [-35.696431449760652, -8.902769838670418],
              [-35.696928831809522, -8.903094224268449],
              [-35.697755569531594, -8.903317823420414],
              [-35.698131611630082, -8.903338950084459],
              [-35.698597458576479, -8.903860355768812],
              [-35.698996624110123, -8.904283847225194],
              [-35.699267297306456, -8.905200255846049],
              [-35.699306145553585, -8.905987150467114],
              [-35.699474990385688, -8.906510618868865],
              [-35.700200054662943, -8.906374158924049],
              [-35.700361196062936, -8.905848295293042],
              [-35.701215862365615, -8.90538290305607],
              [-35.701482296716598, -8.90570895757258],
              [-35.702046610804651, -8.9061640434235],
              [-35.702575626323892, -8.906291395156988],
              [-35.703102954560549, -8.906189165814613],
              [-35.703825753869317, -8.905757525658274],
              [-35.704415032544659, -8.905097263229997],
              [-35.704972353090696, -8.904601227262097],
              [-35.705621979210669, -8.904289656878861],
              [-35.705334237960933, -8.903648693110975],
              [-35.705097934008059, -8.902193436654969],
              [-35.705058488907596, -8.901932235961921],
              [-35.705088870810911, -8.900960608040034],
              [-35.70511799030912, -8.900398116932337],
              [-35.705149347072975, -8.900424272651996],
              [-35.705582674994197, -8.900699325406187],
              [-35.705610063984516, -8.900717197265724],
              [-35.705774397975041, -8.900824428354534],
              [-35.705920561283911, -8.900931791485299],
              [-35.706093715052042, -8.901002816254572],
              [-35.706271687755255, -8.901049590743362],
              [-35.706285179968305, -8.901055727110517],
              [-35.70633955193307, -8.901073312293748],
              [-35.706458127050766, -8.901135698306456],
              [-35.706567816181177, -8.901225255400297],
              [-35.706678798390243, -8.901305315763311],
              [-35.706759672591261, -8.901368427674273],
              [-35.706878247850305, -8.90143081351145],
              [-35.707042582423909, -8.901538043830399],
              [-35.707234239720947, -8.901654110745424],
              [-35.707434916037812, -8.901761076397031],
              [-35.707690104794835, -8.901867645205668],
              [-35.707945293656401, -8.901974213794366],
              [-35.708145770757952, -8.902054073959759],
              [-35.708273365272007, -8.902107358081089],
              [-35.708300687951642, -8.902116194673434],
              [-35.708382722513903, -8.9021517394327],
              [-35.708555877058316, -8.90222276273723],
              [-35.70877452513524, -8.902302490198885],
              [-35.709029714426961, -8.90240905785671],
              [-35.709440054161739, -8.902609367872124],
              [-35.709458324756824, -8.902622788019166],
              [-35.709733016036552, -8.902909922442513],
              [-35.710062286755871, -8.903205694400036],
              [-35.710436918479637, -8.903492099939585],
              [-35.710808759241282, -8.903794246961404],
              [-35.710773611232128, -8.903561930295172],
              [-35.71079953594819, -8.903381032133021],
              [-35.710807955599982, -8.903290616153217],
              [-35.710834080042041, -8.903136822930817],
              [-35.710950858691518, -8.902955262723758],
              [-35.711167842841277, -8.90280911411871],
              [-35.711375941156618, -8.902690136568243],
              [-35.711529660210594, -8.902589626196143],
              [-35.711665208414281, -8.902489248223056],
              [-35.711827946072646, -8.902379636581705],
              [-35.711981598243334, -8.902270091127061],
              [-35.712099108651437, -8.902187915460756],
              [-35.712252760644482, -8.902078369941009],
              [-35.712424516656654, -8.901959656951396],
              [-35.71256006433682, -8.90185927879112],
              [-35.712740972090373, -8.901749534438681],
              [-35.712912727745916, -8.901630821314532],
              [-35.713066445831778, -8.901530310559203],
              [-35.713247153366318, -8.901393461170178],
              [-35.713427993990358, -8.90127468165127],
              [-35.713590663831269, -8.901156034599872],
              [-35.713789608227131, -8.901028087504343],
              [-35.713997771044397, -8.90091814398494],
              [-35.71417901096958, -8.900853573954425],
              [-35.714333328070644, -8.900834377446506],
              [-35.714451370524088, -8.900824480921537],
              [-35.714542290308735, -8.900832853107719],
              [-35.714624058132465, -8.900832256594274],
              [-35.714724063233248, -8.900840562450702],
              [-35.714815049665589, -8.900857969507115],
              [-35.714906036100068, -8.900875376538657],
              [-35.714997022545838, -8.900892783554299],
              [-35.71511539824472, -8.90092806155217],
              [-35.715270115207687, -8.900963074325242],
              [-35.715470325408859, -8.901006790455183],
              [-35.715661565340568, -8.901041536504428],
              [-35.715727877763058, -8.901064273582671],
              [-35.715807740474823, -8.901101007013638],
              [-35.715906422304556, -8.901151607980708],
              [-35.71596760397, -8.901193176143039],
              [-35.716056927965155, -8.901243845355431],
              [-35.716108230988809, -8.901264161984006],
              [-35.723565118927368, -8.902149333373357],
              [-35.723622228788386, -8.901272484302236],
              [-35.723907850365187, -8.896897274411341],
              [-35.722857310518854, -8.894899105812605],
              [-35.719819611802841, -8.894478572686007],
              [-35.719579426666741, -8.898862496941684],
              [-35.716098245990942, -8.898680103114431],
              [-35.716219323841642, -8.892923652883171],
              [-35.716364846777694, -8.885549682379359],
              [-35.713131399621808, -8.885359635172358],
              [-35.70832909266062, -8.883737813419096],
              [-35.70693890425045, -8.88321142678419],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0141000',
        uf: 'PE',
        nome_proje: 'PA OITEIRO ALTO',
        municipio: 'BARREIROS',
        area_hecta: '122.0929',
        capacidade: 11.0,
        num_famili: 11.0,
        fase: 6.0,
        data_de_cr: '10/07/1998',
        forma_obte: 'Desapropriação',
        data_obten: '04/12/1997',
        area_calc_: 121.9806,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.397253864066869, -8.823612223093415],
              [-35.397250982578051, -8.823588111046751],
              [-35.397250711758907, -8.823588383904083],
              [-35.397051660945053, -8.823789296363218],
              [-35.396645820057756, -8.8241887303861],
              [-35.395725841609426, -8.825242969644727],
              [-35.395283963715443, -8.825760935673264],
              [-35.394652464857195, -8.826336506593172],
              [-35.394389311455527, -8.826516769776742],
              [-35.394126989469889, -8.826699196567812],
              [-35.393311657247807, -8.827074941896687],
              [-35.392858240890646, -8.827213398782785],
              [-35.392532316745616, -8.827396230425018],
              [-35.391792521048863, -8.828214336662803],
              [-35.391422971417519, -8.828677613259874],
              [-35.391164897175578, -8.829519760818025],
              [-35.390912996465602, -8.829909986051632],
              [-35.390828950827888, -8.83096792818694],
              [-35.391452563150004, -8.833268554458382],
              [-35.391149757816059, -8.834219439877858],
              [-35.390936121974534, -8.834907664546483],
              [-35.390790632822622, -8.836300392059528],
              [-35.390655365261267, -8.836643149567623],
              [-35.390468779073913, -8.837115834482884],
              [-35.390225785764493, -8.837478890848647],
              [-35.389508277950519, -8.838938530880142],
              [-35.389185856166932, -8.839412714706087],
              [-35.389076221594685, -8.839573922802188],
              [-35.388415728717575, -8.840011942195739],
              [-35.391377530773525, -8.841357734394149],
              [-35.393082860355065, -8.842309623811962],
              [-35.39427708934312, -8.842911760961972],
              [-35.394770547122313, -8.843342412367052],
              [-35.395809177892559, -8.843769575464394],
              [-35.395621488576012, -8.842215762218258],
              [-35.395473165643232, -8.840988135766704],
              [-35.395418445997421, -8.840071437534101],
              [-35.395352386641491, -8.838960593018539],
              [-35.395315635651698, -8.838335423992955],
              [-35.3952505628042, -8.837236321829002],
              [-35.395197883469358, -8.83635359157838],
              [-35.395191289677271, -8.836078347462411],
              [-35.395185411580414, -8.835829488656676],
              [-35.395230996721835, -8.834446439715155],
              [-35.395591296427185, -8.833648645256263],
              [-35.395849113571572, -8.833077807914405],
              [-35.395929663378602, -8.832226671617226],
              [-35.395993709659592, -8.831549524784027],
              [-35.396972358517708, -8.831136577493606],
              [-35.397643545310757, -8.830951534470358],
              [-35.398220325230916, -8.830963300747834],
              [-35.398910516378429, -8.830977413465014],
              [-35.398990639568439, -8.830806090093956],
              [-35.401229664687371, -8.828783787532654],
              [-35.401692574929868, -8.828638752968907],
              [-35.401780145365549, -8.828565439657872],
              [-35.401843495137811, -8.828512254503824],
              [-35.401897609181631, -8.828450000502924],
              [-35.40194328202081, -8.82831893410151],
              [-35.401957373976103, -8.828221960892749],
              [-35.401950367438232, -8.828136148631883],
              [-35.401912651199282, -8.828036524732081],
              [-35.40185983115898, -8.82796176052892],
              [-35.401791017045284, -8.827900655098722],
              [-35.4016746120503, -8.827856754639086],
              [-35.401599724341821, -8.827811865336825],
              [-35.401565716965621, -8.82775180228604],
              [-35.401503857381556, -8.827726350764872],
              [-35.40140974895747, -8.827716017700867],
              [-35.401267434642492, -8.827726146997414],
              [-35.401089058929763, -8.827751961353654],
              [-35.400929206197887, -8.827789857835798],
              [-35.400829443543316, -8.827874636354181],
              [-35.400783859539537, -8.827963315796792],
              [-35.400766884357047, -8.828064103293986],
              [-35.400737698574616, -8.82815945599423],
              [-35.400724434592057, -8.828187828567334],
              [-35.400674682441363, -8.828264243554761],
              [-35.400589833742842, -8.828351005253914],
              [-35.400509152952473, -8.828393817533337],
              [-35.400473163878999, -8.828392601819056],
              [-35.400382694417033, -8.828340853021357],
              [-35.400271579409477, -8.828243867540021],
              [-35.400199094556058, -8.828163083277152],
              [-35.400152494591111, -8.828094695653641],
              [-35.400124984450947, -8.828041730556627],
              [-35.400110422029201, -8.828009830607815],
              [-35.400102051927405, -8.827951953117191],
              [-35.400110843703644, -8.82787833077559],
              [-35.400116593652115, -8.82781204835794],
              [-35.400103575514521, -8.827766039865187],
              [-35.400030300478399, -8.827605187422277],
              [-35.399982082983456, -8.827497406133094],
              [-35.399912487091704, -8.827357497347574],
              [-35.399822278843317, -8.827275832181813],
              [-35.399717248277383, -8.827234479141337],
              [-35.399594205113488, -8.827217823566698],
              [-35.399406222088849, -8.82720519832359],
              [-35.399273702541777, -8.827198454231178],
              [-35.399080467270537, -8.82718857368908],
              [-35.398915491652396, -8.827178964227375],
              [-35.398787207342941, -8.827166770315154],
              [-35.398687242011931, -8.827149966871447],
              [-35.398676233050011, -8.827147687483277],
              [-35.398639646934136, -8.827138431901838],
              [-35.398597819778267, -8.827105531269249],
              [-35.398566920611444, -8.827006134317083],
              [-35.398570959962939, -8.826885998605002],
              [-35.398641634638146, -8.826630505323568],
              [-35.398676980029329, -8.826532130874067],
              [-35.39871348169526, -8.826443961543218],
              [-35.398801242488325, -8.826343805974572],
              [-35.398881282364158, -8.826258069563258],
              [-35.398912474430304, -8.826191895574933],
              [-35.398893254875773, -8.826142311587059],
              [-35.398825113395567, -8.826100812719218],
              [-35.398698395998146, -8.82607794427121],
              [-35.398506900977516, -8.826070130801709],
              [-35.398347172050528, -8.826084979358711],
              [-35.398230043792637, -8.826111846719355],
              [-35.398131340532267, -8.826149712562891],
              [-35.398056785711297, -8.826198449938559],
              [-35.397985846114558, -8.826258280471253],
              [-35.397965774034709, -8.826287690632389],
              [-35.397943503543466, -8.826370617540718],
              [-35.397921514157467, -8.826440799616174],
              [-35.397885511263397, -8.826479620335618],
              [-35.397811292117986, -8.826510009178765],
              [-35.397717553342403, -8.826514675158327],
              [-35.397603624500967, -8.826488290340734],
              [-35.397559036358075, -8.826463992937226],
              [-35.397493468086289, -8.826384790482848],
              [-35.397451685660968, -8.826288445288601],
              [-35.397437214803347, -8.826200330571451],
              [-35.397440582389045, -8.826074595740323],
              [-35.397450855734903, -8.825991112855407],
              [-35.397473737245832, -8.82590429587075],
              [-35.397504221986857, -8.825841108871092],
              [-35.397573103035676, -8.825757974520689],
              [-35.397677855018081, -8.825657800988331],
              [-35.397803047902073, -8.825543127013761],
              [-35.39790643281998, -8.82544232954489],
              [-35.397964369988941, -8.825395144451866],
              [-35.398046807104933, -8.825342922460006],
              [-35.39818972981849, -8.825272329055084],
              [-35.398323382909872, -8.825215532023986],
              [-35.398435374202585, -8.825167097633051],
              [-35.398565310007079, -8.825097490886922],
              [-35.398642715701115, -8.825025960467675],
              [-35.398653804860508, -8.824984406935327],
              [-35.398631372741185, -8.824929782382698],
              [-35.398586648754311, -8.824884428292645],
              [-35.398502680688509, -8.824826220489223],
              [-35.398451152576314, -8.82479654494036],
              [-35.398334552829652, -8.824736376608129],
              [-35.398275307136252, -8.824707563734425],
              [-35.398187081908326, -8.824665469991942],
              [-35.398070144477508, -8.824609280264825],
              [-35.398019290366236, -8.824585474793251],
              [-35.39794571010264, -8.824545908308881],
              [-35.39784128825449, -8.824486113851394],
              [-35.397769103967306, -8.824437591154432],
              [-35.397718868189116, -8.824396971702209],
              [-35.397671754644954, -8.824347385043398],
              [-35.397647619379129, -8.824310394615935],
              [-35.39763154239327, -8.82426901465414],
              [-35.397615202979324, -8.824201065748252],
              [-35.397594943537406, -8.824102956241957],
              [-35.397567154821722, -8.82402080103707],
              [-35.397510363075227, -8.823949857005346],
              [-35.397451391124385, -8.823907124264766],
              [-35.397367767601374, -8.823845931479447],
              [-35.397343888224185, -8.823820417163068],
              [-35.397304686211257, -8.823757765714944],
              [-35.397270467755739, -8.8236789050822],
              [-35.397253864066869, -8.823612223093415],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0322000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA ALTA',
        municipio: 'BARREIROS',
        area_hecta: '396.6645',
        capacidade: 120.0,
        num_famili: 49.0,
        fase: 3.0,
        data_de_cr: '09/11/2005',
        forma_obte: 'Compra e Venda',
        data_obten: '30/04/1984',
        area_calc_: 411.4876,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.241143564074989, -8.818725314789425],
              [-35.240731623582647, -8.821729554746666],
              [-35.240752207124167, -8.821799534102656],
              [-35.240531175409075, -8.823702889787336],
              [-35.240091374115408, -8.825777667046401],
              [-35.237102127423604, -8.830535684798489],
              [-35.237108679685051, -8.830687594577981],
              [-35.237082526380888, -8.830741296184854],
              [-35.236548083732224, -8.831338986800729],
              [-35.236433149028706, -8.831447349211414],
              [-35.235846809486283, -8.832285951475578],
              [-35.235790452042899, -8.83237635435399],
              [-35.235105961772526, -8.832834802736373],
              [-35.2350490816196, -8.832885213727483],
              [-35.234921403836509, -8.833062942579412],
              [-35.232857220622762, -8.835725961451104],
              [-35.22822944588664, -8.835074248801838],
              [-35.227975899632469, -8.835024159633571],
              [-35.226870765237223, -8.834877599690605],
              [-35.227005901869269, -8.835156954137096],
              [-35.227129840051326, -8.835399023178322],
              [-35.228200874248735, -8.83750124392389],
              [-35.229540211457881, -8.839303670506428],
              [-35.230103368430115, -8.840315779405628],
              [-35.230151343110229, -8.840391032257237],
              [-35.231123176380827, -8.84217482898497],
              [-35.231232646952883, -8.842485446707066],
              [-35.231876105441508, -8.843434934007803],
              [-35.23196147944661, -8.843434425667647],
              [-35.233683273628941, -8.843065609065025],
              [-35.234657915328881, -8.842827098543639],
              [-35.234741911565045, -8.842815054034391],
              [-35.23633932553998, -8.842499974151695],
              [-35.237187635731743, -8.84243002555767],
              [-35.237421846943214, -8.842420977791262],
              [-35.239289034021631, -8.842351471266156],
              [-35.239401586872098, -8.842342460312087],
              [-35.23956863771172, -8.842318177164573],
              [-35.2401998997072, -8.84226804909148],
              [-35.240880960177634, -8.842083249233731],
              [-35.241575106692743, -8.841885687910676],
              [-35.243249012425814, -8.841412880599067],
              [-35.244986372943949, -8.840905619075585],
              [-35.247614385274268, -8.840161167475664],
              [-35.247895248494785, -8.838580337984089],
              [-35.248406172213862, -8.836130779615406],
              [-35.248461631491978, -8.835864885788336],
              [-35.248497924230982, -8.835651758308034],
              [-35.24873191310953, -8.835018787184902],
              [-35.248760787528482, -8.834939810088008],
              [-35.248993635993436, -8.834238258568558],
              [-35.249012853092168, -8.83415137189472],
              [-35.249432720213612, -8.832373644054121],
              [-35.249478637498974, -8.832096337731878],
              [-35.249921212621764, -8.829745158615992],
              [-35.249970951644563, -8.829355578235035],
              [-35.249987954402755, -8.829274795121943],
              [-35.250006922453615, -8.829184674548285],
              [-35.250003784806033, -8.827938494127734],
              [-35.250003568943818, -8.827852742711704],
              [-35.250020585866395, -8.827560240025225],
              [-35.250100502667721, -8.826331150532218],
              [-35.250116787351686, -8.826080698834691],
              [-35.250155555565001, -8.825725838185965],
              [-35.250389547679262, -8.823750443028697],
              [-35.250422230701872, -8.823434152654292],
              [-35.250540356630282, -8.822259038671996],
              [-35.250533298860944, -8.822182878037138],
              [-35.250551562448223, -8.820979049689793],
              [-35.250675830228296, -8.819954185857368],
              [-35.250739320057974, -8.818693527551845],
              [-35.250742425940537, -8.81864539785626],
              [-35.250843206855997, -8.817417082962727],
              [-35.251224162562067, -8.816731658417854],
              [-35.251054590623454, -8.816223400132921],
              [-35.250189997514994, -8.81623005609784],
              [-35.248390807107846, -8.815941402128429],
              [-35.247636669421865, -8.815927746259691],
              [-35.247415403094877, -8.816095394584726],
              [-35.246786451641242, -8.816393911114465],
              [-35.246182458448466, -8.816275118685725],
              [-35.245637974342777, -8.816001885944859],
              [-35.245262657803394, -8.816067754906527],
              [-35.244477824306124, -8.81627110559241],
              [-35.243748372730153, -8.816607094403832],
              [-35.243530703059307, -8.816780081017322],
              [-35.24352648696334, -8.817073580347325],
              [-35.243400653096373, -8.817285284772627],
              [-35.24309142822986, -8.817505258972057],
              [-35.242853643454254, -8.817734644325272],
              [-35.242758640585897, -8.817967293909918],
              [-35.242426783011346, -8.818275034136306],
              [-35.241617058189391, -8.818659303797665],
              [-35.241143564074989, -8.818725314789425],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0070000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO ARARIBA DE BAIXO',
        municipio: 'CABO DE SANTO AGOSTINHO',
        area_hecta: '881.2500',
        capacidade: 170.0,
        num_famili: 149.0,
        fase: 5.0,
        data_de_cr: '09/11/1995',
        forma_obte: 'Desapropriação',
        data_obten: '26/12/1994',
        area_calc_: 1073.4254,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.181024106060235, -8.253051637891701],
              [-35.180444761053657, -8.253141894464317],
              [-35.179660648879008, -8.253655021487065],
              [-35.178875654694821, -8.254007701283491],
              [-35.178245586856058, -8.254079893865001],
              [-35.176781664954994, -8.253986999454746],
              [-35.176551324254461, -8.253942408304566],
              [-35.176422348536953, -8.253920187712266],
              [-35.176576461059035, -8.259370106831465],
              [-35.176614934245777, -8.259672462672089],
              [-35.176606355358537, -8.260626047781024],
              [-35.176619554941546, -8.261354882741083],
              [-35.176661736109921, -8.262333405360204],
              [-35.176694777159412, -8.263323438414718],
              [-35.17673067312851, -8.264414310851407],
              [-35.176767217651793, -8.266042690924968],
              [-35.176776245928799, -8.266849481697637],
              [-35.176802764980877, -8.267908314658371],
              [-35.176921821801876, -8.271153362602446],
              [-35.173538071679495, -8.271357445883531],
              [-35.17214593997253, -8.271360430906501],
              [-35.170471172170949, -8.271436003448187],
              [-35.168865018469624, -8.271435555115461],
              [-35.168763723923114, -8.271426936184978],
              [-35.164993915079371, -8.271309846864247],
              [-35.164845981057823, -8.272873912157893],
              [-35.164926673554852, -8.274166261927952],
              [-35.164948236551581, -8.274743773275665],
              [-35.164891289113669, -8.275271282253257],
              [-35.164768265267938, -8.276340102789781],
              [-35.164926940096819, -8.276742665549476],
              [-35.166042423043272, -8.279166321000643],
              [-35.16620363284521, -8.280031887796742],
              [-35.166237627716235, -8.280357191885829],
              [-35.166915908931706, -8.281517933987919],
              [-35.167187359989555, -8.281502707193921],
              [-35.168347224378948, -8.281519329688969],
              [-35.169774200072943, -8.281568879344153],
              [-35.171845510483593, -8.281621794808204],
              [-35.171615676906292, -8.28377080378791],
              [-35.171691247507383, -8.284123385865332],
              [-35.171202838225966, -8.28569846908027],
              [-35.171620178575075, -8.287108170888411],
              [-35.172499750566402, -8.287204236507845],
              [-35.17265599852842, -8.287162126534474],
              [-35.173299700731199, -8.287048596084039],
              [-35.173786027267639, -8.287609817741089],
              [-35.174257843391047, -8.288042756561204],
              [-35.174594710454237, -8.288205955521899],
              [-35.175212966862716, -8.288486811785965],
              [-35.176120922917903, -8.28872253519293],
              [-35.176551120985032, -8.289119022879692],
              [-35.176602905214445, -8.2893296186818],
              [-35.177369065421146, -8.289724271215572],
              [-35.177607471728379, -8.289557934180204],
              [-35.178563333030858, -8.290134920351019],
              [-35.178825449634495, -8.290931157588066],
              [-35.179830474998596, -8.291241981068803],
              [-35.180025616541208, -8.290741225022307],
              [-35.181039744850438, -8.291033658358117],
              [-35.181266542224044, -8.291266216911652],
              [-35.181807655712412, -8.291744607348582],
              [-35.182159624261637, -8.292141514784886],
              [-35.18234399120734, -8.292190932796666],
              [-35.182745041870184, -8.292307928751633],
              [-35.183854176020063, -8.292301854163457],
              [-35.184225441771652, -8.292859103570002],
              [-35.184655701918921, -8.293264747453856],
              [-35.184867328203431, -8.29324983462778],
              [-35.185483772554115, -8.293200612298119],
              [-35.185631828975474, -8.293341913368769],
              [-35.186209108582709, -8.293700905990258],
              [-35.186942395221116, -8.294806279513862],
              [-35.188481139162334, -8.295086641986156],
              [-35.189606256385858, -8.293810617590895],
              [-35.189964822871637, -8.293735299203437],
              [-35.191191038773773, -8.294925054259364],
              [-35.191467985153793, -8.29507022801166],
              [-35.192381919556809, -8.295551133299851],
              [-35.192658764960647, -8.295677969490194],
              [-35.193191276108308, -8.296261824706242],
              [-35.193408013378857, -8.296338564135235],
              [-35.193841818815557, -8.296551636158775],
              [-35.194242696499948, -8.296636529631019],
              [-35.194585933954322, -8.29711598645722],
              [-35.19467640296137, -8.297246139489847],
              [-35.19507244298218, -8.298117258755047],
              [-35.195285139380537, -8.298294872716458],
              [-35.195613563498718, -8.298178456292469],
              [-35.195908039776249, -8.297750497423925],
              [-35.195820650728905, -8.292788522026385],
              [-35.195923111628929, -8.291348501118128],
              [-35.195950012073951, -8.291219993772893],
              [-35.19625383935707, -8.287087905649086],
              [-35.19645439281107, -8.283834271712697],
              [-35.196485958846779, -8.283717199423087],
              [-35.1972910504577, -8.282005132403151],
              [-35.197904811153492, -8.281061981207259],
              [-35.197885817897301, -8.280956647976373],
              [-35.197356861553352, -8.277690986666995],
              [-35.197144281847208, -8.276287082036729],
              [-35.196932327439683, -8.274995487666342],
              [-35.196618433867648, -8.273578386177679],
              [-35.196469119022133, -8.272378131413475],
              [-35.196449441493712, -8.272149026656949],
              [-35.196581398425351, -8.270216036363147],
              [-35.196716187137007, -8.268794175457746],
              [-35.196711668427014, -8.267978202260002],
              [-35.196711328543934, -8.266254522812281],
              [-35.196374364126314, -8.266070711328005],
              [-35.196122344279544, -8.265852051017006],
              [-35.196168882950872, -8.263866810472429],
              [-35.196162543597403, -8.263552823391484],
              [-35.19608682519987, -8.263383621455823],
              [-35.195760987624006, -8.262714961887234],
              [-35.195366263110714, -8.262487915181374],
              [-35.195215993724638, -8.262360380424589],
              [-35.195118075155001, -8.262129412382683],
              [-35.194686264457658, -8.26185328737547],
              [-35.194170268285241, -8.26154094932502],
              [-35.193546583914973, -8.261103133404696],
              [-35.193126835135629, -8.260719209647732],
              [-35.192544708582375, -8.260306377244397],
              [-35.19200878473233, -8.259926526577228],
              [-35.190685294133047, -8.259014626540688],
              [-35.190189360990807, -8.258792711258153],
              [-35.189789952445153, -8.258549637829738],
              [-35.189768992495601, -8.258086740116145],
              [-35.189790232338261, -8.257765724239601],
              [-35.189914356516283, -8.256907784628183],
              [-35.18869263505141, -8.256506465106977],
              [-35.18839949998317, -8.256340741257743],
              [-35.186460222957159, -8.255583468906012],
              [-35.182788185513964, -8.253962325595902],
              [-35.181718459691723, -8.253583073549848],
              [-35.181024106060235, -8.253051637891701],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0181000',
        uf: 'PE',
        nome_proje: 'PA BARRO BRANCO',
        municipio: 'BELEM DE MARIA',
        area_hecta: '389.4593',
        capacidade: 36.0,
        num_famili: 33.0,
        fase: 3.0,
        data_de_cr: '25/01/1999',
        forma_obte: 'Desapropriação',
        data_obten: '06/10/1998',
        area_calc_: 389.1932,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.819946799644228, -8.601321554501043],
              [-35.819870685033692, -8.601370607966563],
              [-35.819792410898508, -8.601436012896006],
              [-35.819714189907316, -8.60150426257926],
              [-35.819595409403419, -8.601608326031586],
              [-35.819476067264105, -8.601714631347923],
              [-35.819319634536853, -8.601856756945445],
              [-35.819163165315786, -8.60199830735908],
              [-35.819030241131337, -8.602105287591025],
              [-35.818892683866139, -8.602198203864505],
              [-35.818678428143642, -8.602335126789631],
              [-35.818461797045003, -8.60246359552916],
              [-35.818285812777468, -8.602568046036025],
              [-35.818107485790165, -8.602664106073936],
              [-35.817974256431953, -8.602729913521074],
              [-35.817841605392978, -8.602795748663391],
              [-35.817809447207274, -8.602812319967306],
              [-35.817798151256582, -8.602818061077727],
              [-35.817760324898686, -8.60283694363584],
              [-35.817722523601851, -8.602859214622558],
              [-35.817684770967801, -8.60288372300794],
              [-35.817333082492233, -8.60313497912899],
              [-35.816984223942711, -8.603386757797889],
              [-35.816921710131929, -8.603433473523525],
              [-35.816856858468419, -8.603476785788166],
              [-35.816826969496461, -8.603496185574611],
              [-35.816813424977319, -8.60350191116523],
              [-35.816667175928423, -8.603570658854949],
              [-35.816520958928024, -8.603639406261467],
              [-35.81640972640966, -8.60369152973518],
              [-35.816298514644558, -8.603746466227939],
              [-35.816260688086928, -8.60376534870014],
              [-35.816225703530336, -8.603781972575556],
              [-35.815981622844021, -8.60387339865477],
              [-35.815734653175632, -8.60395640619523],
              [-35.815514793299641, -8.604030775523519],
              [-35.815289783420084, -8.604095016597482],
              [-35.815175503140004, -8.604117271953609],
              [-35.815001660018211, -8.604129254037852],
              [-35.814827684805792, -8.604127714505795],
              [-35.814655963755051, -8.604118262259957],
              [-35.814484646977171, -8.6040896261112],
              [-35.814339877411093, -8.604050086245632],
              [-35.814201280536338, -8.604002605018582],
              [-35.814162670392562, -8.603989365082668],
              [-35.813983141188054, -8.603923002451547],
              [-35.81380301309612, -8.603853830899508],
              [-35.813623000523343, -8.603795943153154],
              [-35.813443030720045, -8.603748188897814],
              [-35.813399334529088, -8.60373779927515],
              [-35.813334118853618, -8.603727567101565],
              [-35.813266040378672, -8.603716780435613],
              [-35.813176488458296, -8.603712384640696],
              [-35.813089291566151, -8.60371807353815],
              [-35.81304566334849, -8.603721237875822],
              [-35.812961954353582, -8.603738185990487],
              [-35.812880514657259, -8.603757962645689],
              [-35.812749968527456, -8.603804600120705],
              [-35.812622868481995, -8.603856838748648],
              [-35.812440787158373, -8.603901583352512],
              [-35.812261500097101, -8.603946307454404],
              [-35.812212896919895, -8.603963030632553],
              [-35.812166573918951, -8.603979705163562],
              [-35.811952397674631, -8.604054031099345],
              [-35.811738246092908, -8.604131713427282],
              [-35.811542625134955, -8.604187266041716],
              [-35.811347487814245, -8.604234375430689],
              [-35.811143975115407, -8.604295643948646],
              [-35.810948491205536, -8.604369800803141],
              [-35.810905036270924, -8.604392112623181],
              [-35.810859296641517, -8.604413865706732],
              [-35.811463496225713, -8.604907352864348],
              [-35.811439770599904, -8.604991570748044],
              [-35.81111559152977, -8.604902000633215],
              [-35.810546126642542, -8.604740952169136],
              [-35.810533722296341, -8.604901203640361],
              [-35.810518454912575, -8.605060868642237],
              [-35.81051973263839, -8.605233999193974],
              [-35.810515378002151, -8.605409952154529],
              [-35.810494957077118, -8.605558977446446],
              [-35.810468871636779, -8.605710825396676],
              [-35.810439876922466, -8.605851473765771],
              [-35.810413723797467, -8.605989799653397],
              [-35.81038255244551, -8.606144529971234],
              [-35.810353657512302, -8.606298700178844],
              [-35.810348166669385, -8.606320702493182],
              [-35.810329852371396, -8.606372177360155],
              [-35.810305306753754, -8.606423665811667],
              [-35.810278480685149, -8.606475170927206],
              [-35.810176677190505, -8.60665183634098],
              [-35.810066345566312, -8.606826326323821],
              [-35.809980613578588, -8.606956648190399],
              [-35.809931763218039, -8.607013940727937],
              [-35.80987490603782, -8.607065697355541],
              [-35.809856312572187, -8.60707935583638],
              [-35.809802255463829, -8.607123163869888],
              [-35.809764748457631, -8.607185456430257],
              [-35.809726667545895, -8.607248328613906],
              [-35.809668081921352, -8.607370555867126],
              [-35.809628247819404, -8.607500542208619],
              [-35.809588496973873, -8.607641812737503],
              [-35.809551547588896, -8.607779674154196],
              [-35.809530222539564, -8.607880210323808],
              [-35.809506584678701, -8.607980731437635],
              [-35.809479895984708, -8.608050873053481],
              [-35.809436760911041, -8.608116595396218],
              [-35.809369375093013, -8.608195442154752],
              [-35.809283064456089, -8.608247414072231],
              [-35.80906826911486, -8.608316628914903],
              [-35.808848824322318, -8.608369541861144],
              [-35.808598743986614, -8.608414814400719],
              [-35.808348668686719, -8.608465137735768],
              [-35.808143004352672, -8.608539943812616],
              [-35.807936761761979, -8.608614754038305],
              [-35.807738195682433, -8.608659042305886],
              [-35.807537297072649, -8.608687587154385],
              [-35.807379867708384, -8.608700022018729],
              [-35.807224635558356, -8.60870115596615],
              [-35.807006909176188, -8.608677938914262],
              [-35.806791823248609, -8.608633827131413],
              [-35.806614730123655, -8.608588862320248],
              [-35.806437620527397, -8.608541659756423],
              [-35.805917492782399, -8.610037933291238],
              [-35.805317551773804, -8.611765900802519],
              [-35.804741886704534, -8.613425478217495],
              [-35.804506901880856, -8.614104408363993],
              [-35.804485539076936, -8.614733435214131],
              [-35.804193513525625, -8.615863199008327],
              [-35.803862987699759, -8.617140575608115],
              [-35.803485110696826, -8.618604929809102],
              [-35.803657071092147, -8.618719815085308],
              [-35.803829015084617, -8.618832462580244],
              [-35.804004369266565, -8.618950168030489],
              [-35.804179209614695, -8.61906787711067],
              [-35.80426634823889, -8.619132104355254],
              [-35.80434843240819, -8.619199149744636],
              [-35.80443638509724, -8.619290991645217],
              [-35.804524301467652, -8.619382258356348],
              [-35.804576212104159, -8.619430375073984],
              [-35.80463098568746, -8.619479046290531],
              [-35.804655511550159, -8.619503099101872],
              [-35.804836425516463, -8.619677699000462],
              [-35.805017307757247, -8.619852330966511],
              [-35.805058399979259, -8.619892630453849],
              [-35.805101772746816, -8.619932913275202],
              [-35.805175744741504, -8.61997578566951],
              [-35.805251861128802, -8.620004544325756],
              [-35.805320080201511, -8.620033936117128],
              [-35.805409866489235, -8.6200739806062],
              [-35.805474210037417, -8.620096622512065],
              [-35.805598914483589, -8.620197110358676],
              [-35.805688991307193, -8.620272025034707],
              [-35.805858814007458, -8.620406105484953],
              [-35.806028652417375, -8.620537947929215],
              [-35.806085628966002, -8.620580400868258],
              [-35.806145982170584, -8.620623372568653],
              [-35.806217306670533, -8.62069049602357],
              [-35.806288652199875, -8.620760464474808],
              [-35.806416837015341, -8.620854281222364],
              [-35.806545567708, -8.620948061949386],
              [-35.806695992774323, -8.621060864929069],
              [-35.806846392907971, -8.621170279369769],
              [-35.809585239247482, -8.623077602198812],
              [-35.810072168770958, -8.623415169060991],
              [-35.811040693214494, -8.623824811454382],
              [-35.813635331260471, -8.62492339697209],
              [-35.815899078036466, -8.625786451286091],
              [-35.817209993006898, -8.62507308118103],
              [-35.817743637452708, -8.624449461819189],
              [-35.818203838337212, -8.623014394648067],
              [-35.818793644325709, -8.621180278658068],
              [-35.819490389634517, -8.619018313638723],
              [-35.82072485213294, -8.615180614038692],
              [-35.82079936285146, -8.615143626327344],
              [-35.820834635211646, -8.615129837644],
              [-35.822000525867956, -8.614612337239734],
              [-35.823791270212588, -8.614049391346931],
              [-35.823955146226957, -8.614524667346567],
              [-35.825711514143521, -8.613377786495894],
              [-35.825122426563993, -8.61164425280524],
              [-35.825072113773047, -8.611504220083248],
              [-35.825213369823622, -8.611070113192362],
              [-35.825250451085388, -8.610950856271222],
              [-35.825566984730472, -8.609248447978867],
              [-35.825367534847835, -8.609168716180745],
              [-35.825168651912506, -8.609091793265536],
              [-35.82487139397184, -8.608964284995844],
              [-35.82458696020133, -8.608811875121482],
              [-35.824412192111375, -8.608699258176182],
              [-35.824248125314988, -8.608578666411908],
              [-35.824223641289151, -8.608560241047474],
              [-35.824193426234899, -8.608535656036702],
              [-35.824034974751335, -8.608409939983748],
              [-35.823881588509487, -8.608278560260386],
              [-35.823728171656448, -8.608138709191266],
              [-35.823511761588641, -8.607915884658421],
              [-35.823308897678579, -8.607678862568871],
              [-35.823078341594865, -8.607380569448182],
              [-35.822863600961405, -8.607070875247469],
              [-35.822693325035068, -8.606801485500686],
              [-35.82261582637738, -8.606663889336005],
              [-35.822543901282025, -8.60651554295052],
              [-35.822480355322654, -8.606353612594392],
              [-35.82244657437257, -8.6062292497359],
              [-35.822415754946029, -8.606045085136243],
              [-35.822408718838155, -8.605858508153071],
              [-35.822426052209245, -8.605601263014471],
              [-35.822482700776554, -8.605214003484967],
              [-35.822556303878514, -8.604826076063011],
              [-35.822601361278508, -8.604712419054604],
              [-35.822620178043621, -8.604655314105777],
              [-35.8226226033674, -8.604601174643655],
              [-35.822594992393128, -8.604542172787529],
              [-35.822558865790533, -8.60448265802215],
              [-35.822512446182778, -8.604412509553358],
              [-35.822471133647561, -8.604342355555605],
              [-35.822454367078059, -8.604299034254886],
              [-35.822443289969975, -8.60425627856362],
              [-35.822431450671679, -8.604188689413466],
              [-35.822420210596093, -8.604123940998123],
              [-35.822414861436734, -8.604091277094932],
              [-35.822406160687471, -8.604061450965093],
              [-35.822384361362239, -8.604023824913746],
              [-35.822362084115802, -8.603999724791722],
              [-35.822340353115365, -8.603975652622635],
              [-35.822299299592125, -8.603940437610678],
              [-35.822255444516898, -8.603908599774904],
              [-35.822200604299383, -8.603846409118932],
              [-35.822156448685305, -8.603774006193152],
              [-35.822095979432511, -8.603641367554765],
              [-35.822037783629639, -8.603512100809958],
              [-35.822026823609662, -8.603485136384263],
              [-35.822013057786883, -8.603460973765891],
              [-35.822002717313886, -8.603440461905461],
              [-35.821953602459516, -8.603382226224632],
              [-35.82190098769766, -8.603322814236924],
              [-35.821747284559216, -8.603139473153234],
              [-35.82159607328061, -8.602955893128476],
              [-35.821554397269004, -8.602904228097556],
              [-35.821511276692476, -8.602847887638367],
              [-35.821502476448423, -8.602836550589085],
              [-35.821458279447747, -8.602758521449918],
              [-35.821416887802066, -8.602677626569781],
              [-35.821381407777913, -8.602631629266783],
              [-35.821334664020441, -8.602591372917232],
              [-35.821290847073193, -8.602564649496466],
              [-35.821247617568027, -8.602543516150106],
              [-35.821190273057461, -8.602524788030312],
              [-35.821132900213684, -8.602506571600694],
              [-35.821034665083907, -8.602474589007645],
              [-35.820936397857189, -8.602442606619599],
              [-35.820871103326226, -8.602421667034333],
              [-35.820805713963686, -8.602392256624666],
              [-35.820731665958768, -8.602338677991478],
              [-35.820668855383836, -8.602271462514425],
              [-35.820613925096708, -8.602201376037776],
              [-35.820564663205211, -8.602129010220484],
              [-35.820517497833485, -8.602031789911198],
              [-35.820481617549454, -8.601931705604535],
              [-35.820447558326627, -8.601769558122262],
              [-35.820421972869795, -8.601602265582123],
              [-35.820412781169416, -8.601510425089263],
              [-35.820401341209056, -8.601418601081674],
              [-35.8203822435107, -8.601364043926715],
              [-35.820349006760019, -8.601312979059928],
              [-35.820327366388291, -8.601296770143584],
              [-35.820305152150191, -8.60128114085652],
              [-35.820272678418604, -8.601259385006326],
              [-35.820236878177575, -8.601243855337675],
              [-35.820193219986145, -8.601238549090496],
              [-35.820150170267304, -8.601241677922967],
              [-35.820087431778106, -8.601257930109034],
              [-35.820030380698967, -8.601283155546618],
              [-35.819946799644228, -8.601321554501043],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0356000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA SECA VEADA MORTA',
        municipio: 'CARUARU',
        area_hecta: '532.3233',
        capacidade: 27.0,
        num_famili: 26.0,
        fase: 5.0,
        data_de_cr: '27/04/2006',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/2005',
        area_calc_: 568.8287,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.001985553454539, -8.083953133336164],
              [-35.999784608815418, -8.084672386112375],
              [-35.999520042807625, -8.084847141483383],
              [-35.998740869600773, -8.085693347618607],
              [-35.998335053125274, -8.084976079503829],
              [-35.998049620942865, -8.085164600626978],
              [-35.997225319231234, -8.085708847585309],
              [-35.99713453461726, -8.086310838546762],
              [-35.996452169371942, -8.086763916554011],
              [-35.996694963268894, -8.08692200534866],
              [-35.99714833261158, -8.087441209001227],
              [-35.997639165578249, -8.087733632526493],
              [-35.997062149263833, -8.088559022412751],
              [-35.996424261786863, -8.089525156662923],
              [-35.995646260885529, -8.094358148844876],
              [-35.995314866991414, -8.096075793063189],
              [-35.995517860790059, -8.098108101136784],
              [-35.995484607501027, -8.098595102901433],
              [-35.995487118838682, -8.099001506312547],
              [-35.9955970151412, -8.099728717004922],
              [-35.995595604016181, -8.099819786093757],
              [-35.995560755574729, -8.100470371240762],
              [-35.995782110471886, -8.103884710362582],
              [-35.998718420646568, -8.106442384746947],
              [-36.007952298560454, -8.114615616740332],
              [-36.009131620357707, -8.114282352892786],
              [-36.009579913038174, -8.114170001620055],
              [-36.010263640369921, -8.114048592614655],
              [-36.010894583106413, -8.113990336830273],
              [-36.012750773468873, -8.113996089269339],
              [-36.013219116347905, -8.113965113874483],
              [-36.013676010430771, -8.113884338345665],
              [-36.014188059935584, -8.113728367975224],
              [-36.014904936145939, -8.113373007414085],
              [-36.015144495411612, -8.113211019697356],
              [-36.01534705703439, -8.113052406191729],
              [-36.015616879467025, -8.112808962446561],
              [-36.01700849256035, -8.111378866423019],
              [-36.017179550102419, -8.11118962900631],
              [-36.017372234355875, -8.110953378046176],
              [-36.017687986607079, -8.110496684970686],
              [-36.018079121852843, -8.109935334385129],
              [-36.018389838275922, -8.109536240297601],
              [-36.018868011647029, -8.108983781070563],
              [-36.018718757651492, -8.108682695582342],
              [-36.018123410734184, -8.108117929546706],
              [-36.016945801260256, -8.105442609874872],
              [-36.017283496075109, -8.105247881413742],
              [-36.017117676134696, -8.104824358587782],
              [-36.016882570558131, -8.104612466349685],
              [-36.0167197836507, -8.104567467326218],
              [-36.01636029390361, -8.103718474568764],
              [-36.010989939799749, -8.102198060651737],
              [-36.013585632860796, -8.098055705638783],
              [-36.013331082209007, -8.097695618053303],
              [-36.013865528260553, -8.097258806593356],
              [-36.014113680307041, -8.097119535348673],
              [-36.014126618514176, -8.096979681645037],
              [-36.014070834748999, -8.096390212710512],
              [-36.014028794085313, -8.096290761041105],
              [-36.013897718786183, -8.096145858328697],
              [-36.013802334843184, -8.095948191481472],
              [-36.013772141333831, -8.095712853079805],
              [-36.013782312794355, -8.095179560740414],
              [-36.013843186508659, -8.094731515854514],
              [-36.01390723834151, -8.094301041823995],
              [-36.01394065452299, -8.093829789957283],
              [-36.013935290579241, -8.093580239374564],
              [-36.013917464667813, -8.093226588130324],
              [-36.013931779309367, -8.09300602954251],
              [-36.014056885910627, -8.092534709653609],
              [-36.014078113274479, -8.092352037455942],
              [-36.014041174084952, -8.092194998621851],
              [-36.01388534546669, -8.091861921843792],
              [-36.013558718949419, -8.09132835280773],
              [-36.013395216631082, -8.09117724916298],
              [-36.013197858648958, -8.091067967539882],
              [-36.012125626323297, -8.090380448017623],
              [-36.010603896995306, -8.089310394640139],
              [-36.010337871145431, -8.089065929503516],
              [-36.009829860806846, -8.088563803713191],
              [-36.009659740786802, -8.088436930537348],
              [-36.009404795209591, -8.088302373815729],
              [-36.0089581910348, -8.088105955224608],
              [-36.008750935681135, -8.087917997729702],
              [-36.008496673998941, -8.087612289651217],
              [-36.007511063379383, -8.086442423094846],
              [-36.007312750912789, -8.086223867510727],
              [-36.007259573474528, -8.085318942867444],
              [-36.007218797756401, -8.084853064119157],
              [-36.007080345479793, -8.084245396443663],
              [-36.006970636546775, -8.084060515842001],
              [-36.006789028554024, -8.08393931060578],
              [-36.00646863993169, -8.083811338147694],
              [-36.005981532866819, -8.084481934125479],
              [-36.005466807591475, -8.084945250836185],
              [-36.004430168015318, -8.085229986293225],
              [-36.0043551247553, -8.084071937335553],
              [-36.003978021025986, -8.084001358523441],
              [-36.002474943138239, -8.083292525998939],
              [-36.001985553454539, -8.083953133336164],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0104000',
        uf: 'PE',
        nome_proje: 'PA SÍTIO DO MEIO',
        municipio: 'BELEM DE MARIA',
        area_hecta: '556.0800',
        capacidade: 60.0,
        num_famili: 56.0,
        fase: 3.0,
        data_de_cr: '19/05/1997',
        forma_obte: 'Desapropriação',
        data_obten: '17/09/1996',
        area_calc_: 446.7015,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.827238982394888, -8.621630793440433],
              [-35.82620078781428, -8.622391965196959],
              [-35.825222637783654, -8.622938503856508],
              [-35.824213512000135, -8.622894257058979],
              [-35.823757603479379, -8.622946841722001],
              [-35.82353920998041, -8.622901229141199],
              [-35.822597023178332, -8.623903782512706],
              [-35.820385230788503, -8.623697883801377],
              [-35.819127417513776, -8.623589075533914],
              [-35.818420621274377, -8.623836167970021],
              [-35.817660107085167, -8.623943686190669],
              [-35.817450294641787, -8.624238594097372],
              [-35.817250174333637, -8.624479177993958],
              [-35.815804217121695, -8.625797891186396],
              [-35.814626138864433, -8.625391499718521],
              [-35.813506353902518, -8.624955747706307],
              [-35.812380797334299, -8.626117369485142],
              [-35.811057867899919, -8.627470494211126],
              [-35.81104513461144, -8.627500700729248],
              [-35.809392559837079, -8.629106357812141],
              [-35.808891627072377, -8.629748370200099],
              [-35.807949928452018, -8.630335920276112],
              [-35.806694608137711, -8.630551591111972],
              [-35.805396185693105, -8.63074434936118],
              [-35.804991342233627, -8.630706058324479],
              [-35.804232699553971, -8.630678514755743],
              [-35.803849818722632, -8.630779775172611],
              [-35.803609279973109, -8.630870619827501],
              [-35.80322474132403, -8.631021124362999],
              [-35.802259446493366, -8.631376096395249],
              [-35.801890206357712, -8.63154758409383],
              [-35.801515592099037, -8.631674904269596],
              [-35.801230740086019, -8.631790333248322],
              [-35.801115056655554, -8.631846620142587],
              [-35.801252256723586, -8.632614566163225],
              [-35.801327002573373, -8.633206432350439],
              [-35.801294529856655, -8.63386775870773],
              [-35.802009413099718, -8.634618989742419],
              [-35.802309572315494, -8.634921561890989],
              [-35.80261869084223, -8.635183267613199],
              [-35.802961881255328, -8.635858422709967],
              [-35.804003699018786, -8.636815786124941],
              [-35.804377408936297, -8.637240567264181],
              [-35.805010011367827, -8.637733257860456],
              [-35.805146188796883, -8.637897964966589],
              [-35.805230894588689, -8.638017565667683],
              [-35.80559048675552, -8.638548834459794],
              [-35.806065179558715, -8.638802108441388],
              [-35.806432042785602, -8.639126952003302],
              [-35.807397794775426, -8.639471448253076],
              [-35.808394607203347, -8.639517892034085],
              [-35.80807953551485, -8.638552664119183],
              [-35.808430298860593, -8.6385419514032],
              [-35.808877069350437, -8.638581245130052],
              [-35.809340883844989, -8.638614195950598],
              [-35.809999233636269, -8.63862946027705],
              [-35.810160532004879, -8.638598136265495],
              [-35.810299387020578, -8.637990286573229],
              [-35.810506626345742, -8.637995908531876],
              [-35.811236173254713, -8.638012156181116],
              [-35.811190348244551, -8.637890922589378],
              [-35.81162769193741, -8.636963379726859],
              [-35.812137273661008, -8.636984526236567],
              [-35.81275894804007, -8.638042945142994],
              [-35.813036899885525, -8.63841295712821],
              [-35.814066137503616, -8.638020270877398],
              [-35.814181201931255, -8.637987973241382],
              [-35.814692530600226, -8.637871284157372],
              [-35.814833463788027, -8.637845204116866],
              [-35.815218556976852, -8.637800146619231],
              [-35.815926014699272, -8.637903452200735],
              [-35.816338111450555, -8.637789817705608],
              [-35.816483243931906, -8.638606812742033],
              [-35.816598820559662, -8.638944289391993],
              [-35.816604759529099, -8.63920025361805],
              [-35.816601362429253, -8.639286593145817],
              [-35.816480893083359, -8.639921707271885],
              [-35.81669023431229, -8.639970401687199],
              [-35.816937156529967, -8.640185596624491],
              [-35.817553545414953, -8.640546724004622],
              [-35.817950942831118, -8.641004840526454],
              [-35.818349668029484, -8.641372740567714],
              [-35.819028673775108, -8.641178862308701],
              [-35.818916765358928, -8.640384587383558],
              [-35.818764674677752, -8.639888787619546],
              [-35.818856611468924, -8.639490257733389],
              [-35.818953073764249, -8.638732794572986],
              [-35.818952416133818, -8.638646668106626],
              [-35.818939978956237, -8.637856076686635],
              [-35.818923788164788, -8.637165763785093],
              [-35.819289573390513, -8.636938023722657],
              [-35.81947175833379, -8.636876095084961],
              [-35.82006920484185, -8.636660603260324],
              [-35.820310373723487, -8.636605117289513],
              [-35.82055322366358, -8.63655744312315],
              [-35.820748516916844, -8.636549524935344],
              [-35.820830856246701, -8.636600615098239],
              [-35.82087971397366, -8.636637524227657],
              [-35.820934749137095, -8.636688489605294],
              [-35.820977095659188, -8.636734512073016],
              [-35.821023587930014, -8.636793598476116],
              [-35.821075711820583, -8.636882379040697],
              [-35.821260678484499, -8.637862084823675],
              [-35.821605061760522, -8.638201761367297],
              [-35.822375550008339, -8.638923350356489],
              [-35.822498817444909, -8.639051480130297],
              [-35.822822679749976, -8.63943087033477],
              [-35.823139642297868, -8.640201349730415],
              [-35.823292900465425, -8.64055659728719],
              [-35.823386613001503, -8.640939692561332],
              [-35.823369608749147, -8.641096547390301],
              [-35.823382935318378, -8.641149139667426],
              [-35.82329614829424, -8.641425060816237],
              [-35.823065293161477, -8.641605595338193],
              [-35.822787432041871, -8.641710120952423],
              [-35.822449942437395, -8.641670412520606],
              [-35.822088509020212, -8.641397795196186],
              [-35.821854497694069, -8.641425641485087],
              [-35.821726536664293, -8.641595370307423],
              [-35.821430847575435, -8.641746241594436],
              [-35.82118762279147, -8.641892707428482],
              [-35.821536283454449, -8.642619533988867],
              [-35.822159982026328, -8.643540244238864],
              [-35.82255464385748, -8.64364048678835],
              [-35.822949137364475, -8.643767510351562],
              [-35.823316247371061, -8.643955035187814],
              [-35.823908874078739, -8.644251091990071],
              [-35.824116295805737, -8.644312856207039],
              [-35.824803360179544, -8.644736598260897],
              [-35.825237773818863, -8.644969674514547],
              [-35.826014441139357, -8.644315557925696],
              [-35.826423411898787, -8.643937879683879],
              [-35.826685309878698, -8.643749019786705],
              [-35.827541250987416, -8.643004798165267],
              [-35.828090764237565, -8.642642738715939],
              [-35.82828388691437, -8.642496621793907],
              [-35.828430622243317, -8.642380873407784],
              [-35.828579705086177, -8.64228940373463],
              [-35.829110340561947, -8.641975280107053],
              [-35.829490927925995, -8.641837841763714],
              [-35.8296163259133, -8.641595794056951],
              [-35.830280236495696, -8.641004159605149],
              [-35.830118286228114, -8.640406573101169],
              [-35.830025672157014, -8.639900903653954],
              [-35.829872451540737, -8.639390683083445],
              [-35.829759625924417, -8.638882124194733],
              [-35.829831730724372, -8.638262714314189],
              [-35.829801655098791, -8.638020308533106],
              [-35.8297968677428, -8.637919879885205],
              [-35.829797319903577, -8.637439138541174],
              [-35.829870504154982, -8.636964393323904],
              [-35.830045086756812, -8.636549179355114],
              [-35.830451235879494, -8.636114799670498],
              [-35.830521825409953, -8.636039303506337],
              [-35.831380787340713, -8.635341743891995],
              [-35.831481126090736, -8.63471810733655],
              [-35.831603273451812, -8.63431131830427],
              [-35.831528281154704, -8.63305503095421],
              [-35.830899523403517, -8.633350025444424],
              [-35.830468860193506, -8.632457040463676],
              [-35.82980863593766, -8.632999998665039],
              [-35.829716178998609, -8.633056358773851],
              [-35.829617617090619, -8.633106315381365],
              [-35.829114009851089, -8.631881595931469],
              [-35.828548518264178, -8.632131914001876],
              [-35.828377010252041, -8.632232071736018],
              [-35.828104465896494, -8.632362939940021],
              [-35.827784928809706, -8.6317504379059],
              [-35.827682186219, -8.631249715023516],
              [-35.827287233494388, -8.629947395369937],
              [-35.826846989887734, -8.628443631411107],
              [-35.82754389034622, -8.626645819283405],
              [-35.828697282870728, -8.626313990222958],
              [-35.829616302449985, -8.626088187440985],
              [-35.829178682339972, -8.624820220971126],
              [-35.829066142465145, -8.624466646913394],
              [-35.828330133075632, -8.623238305913574],
              [-35.828159523863675, -8.623102924720715],
              [-35.827238982394888, -8.621630793440433],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0199000',
        uf: 'PE',
        nome_proje: 'PA PAU AMARELO',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '428.0000',
        capacidade: 42.0,
        num_famili: 25.0,
        fase: 5.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/1997',
        area_calc_: 444.4632,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.280199815474965, -8.86578195907286],
              [-35.278879730737842, -8.864325169945817],
              [-35.273112479262529, -8.867570817852842],
              [-35.272095156494899, -8.867390093542516],
              [-35.271662007719264, -8.867313190156844],
              [-35.269716635874339, -8.867947670211295],
              [-35.268429541462496, -8.868367368834114],
              [-35.268229569234869, -8.868553780100008],
              [-35.267799188607029, -8.868602398974636],
              [-35.267232511411159, -8.868654827433696],
              [-35.2668565729292, -8.868689557175019],
              [-35.265915249925591, -8.869506195667284],
              [-35.265511988132566, -8.869694471996716],
              [-35.265175754054134, -8.869787076506642],
              [-35.261427785092586, -8.869687500942707],
              [-35.257199023993437, -8.86956873952504],
              [-35.255785216134775, -8.869527841429003],
              [-35.251203304292396, -8.869398218906007],
              [-35.250958808435534, -8.875745263239622],
              [-35.250927995216969, -8.876370551788925],
              [-35.250828249038939, -8.879193967516569],
              [-35.25083523369284, -8.879921795762016],
              [-35.251010154317811, -8.881108489369037],
              [-35.250396121970077, -8.884143888582701],
              [-35.250038495812063, -8.885038418148524],
              [-35.249943534682892, -8.885278963465606],
              [-35.25050235645508, -8.885303881141404],
              [-35.250760551612572, -8.885198198999703],
              [-35.250910451973965, -8.885233086470922],
              [-35.251059458680444, -8.885344896863616],
              [-35.251254962318093, -8.88537634535105],
              [-35.256075656704517, -8.885596756760894],
              [-35.261762642936503, -8.883104025502194],
              [-35.261839546415302, -8.883017693952182],
              [-35.261949687366737, -8.882260237953933],
              [-35.262032546057945, -8.882152087682227],
              [-35.264427359004046, -8.88128441859034],
              [-35.269437897409986, -8.879478645184236],
              [-35.269653539909626, -8.87934220940441],
              [-35.27008484765458, -8.878910623389762],
              [-35.270252282888187, -8.878856458854314],
              [-35.270515861597772, -8.879051801213246],
              [-35.270698197118513, -8.879100312195254],
              [-35.270865737739108, -8.879078052224935],
              [-35.271899212581417, -8.878717817373936],
              [-35.272008811062513, -8.878716969200736],
              [-35.272556048011289, -8.878973760559449],
              [-35.272664240521038, -8.878847920081617],
              [-35.272669158239438, -8.878598702273608],
              [-35.272526216723975, -8.878350385085966],
              [-35.272629932697527, -8.878220775807698],
              [-35.272718142400343, -8.878200082943803],
              [-35.272915794467437, -8.878271276253685],
              [-35.273236555894684, -8.878248172300546],
              [-35.273508651205915, -8.87812937718609],
              [-35.273675192518979, -8.877797558231018],
              [-35.273757732146692, -8.877815403136228],
              [-35.274064493170066, -8.878396327209231],
              [-35.274218548178624, -8.878589712951015],
              [-35.27432428099867, -8.878595756820165],
              [-35.274403394005297, -8.878544207821234],
              [-35.274910667214719, -8.878111973941195],
              [-35.274873169559612, -8.877631361984527],
              [-35.27492054734725, -8.877473805728794],
              [-35.274998385512198, -8.877421902911268],
              [-35.27517637773267, -8.877429404103426],
              [-35.27525672309018, -8.877371430266916],
              [-35.275291211399185, -8.877275413407673],
              [-35.275282039942489, -8.876996545712929],
              [-35.275431595128261, -8.876799139972645],
              [-35.275563986098092, -8.876721687436516],
              [-35.275879405953823, -8.876702229481968],
              [-35.276087724469647, -8.876837889933265],
              [-35.276208395287036, -8.876805248519014],
              [-35.276279306484156, -8.876632273894602],
              [-35.276488878581873, -8.876558598319036],
              [-35.276556939426598, -8.876468703482855],
              [-35.276569333020568, -8.876253153676499],
              [-35.276679434473891, -8.876186682838238],
              [-35.277122610284948, -8.87620784016338],
              [-35.277057809675433, -8.872376338749749],
              [-35.277054528889842, -8.872271694714787],
              [-35.278722907141479, -8.87146198433634],
              [-35.279851255878377, -8.8702670983266],
              [-35.280289880945723, -8.869802652043594],
              [-35.282140203482811, -8.867923038879086],
              [-35.280199815474965, -8.86578195907286],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0091000',
        uf: 'PE',
        nome_proje: 'PA MACAMBIRA/BORBA',
        municipio: 'CARUARU',
        area_hecta: '836.7000',
        capacidade: 50.0,
        num_famili: 49.0,
        fase: 4.0,
        data_de_cr: '19/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 883.1165,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.015829969354478, -8.029571542542858],
              [-36.015036272466951, -8.029540778927529],
              [-36.014122390869566, -8.029766632968608],
              [-36.013403804734509, -8.030003248933843],
              [-36.012941957513625, -8.030274477936667],
              [-36.011845814664298, -8.030623435863305],
              [-36.010956932320461, -8.030934377414926],
              [-36.01000499330587, -8.030965650291058],
              [-36.008869986951666, -8.031010546379063],
              [-36.008179414843532, -8.031003404838712],
              [-36.007385441928044, -8.030936133993682],
              [-36.007066992463301, -8.030792391344248],
              [-36.006416962365307, -8.0303831373033],
              [-36.005779795418647, -8.030059065780311],
              [-36.00563737576902, -8.030607993246496],
              [-36.005240454414285, -8.031414521474726],
              [-36.004574554044844, -8.032174236617683],
              [-36.004090286868468, -8.032725663346731],
              [-36.003909370739478, -8.033019222277671],
              [-36.003801679155906, -8.033312155150456],
              [-36.00374400106832, -8.033775277031188],
              [-36.003796432807185, -8.034261893977837],
              [-36.003895741704241, -8.03448032324466],
              [-36.004167048980285, -8.034855762351068],
              [-36.004560611173893, -8.035254698512775],
              [-36.004930276252274, -8.035719120089119],
              [-36.005472896799965, -8.036457891231727],
              [-36.005683752681392, -8.036906851833656],
              [-36.005735200327855, -8.037271703028159],
              [-36.005347821780781, -8.037712847010726],
              [-36.00472879255927, -8.038204376477996],
              [-36.004169589660968, -8.038525003840578],
              [-36.004061092995755, -8.038708456046981],
              [-36.004099052403696, -8.03889083813592],
              [-36.004126748930368, -8.039328944799255],
              [-36.00394771301476, -8.039890336372229],
              [-36.003829042472852, -8.040353814304277],
              [-36.003770380266673, -8.040670780281994],
              [-36.003858624193427, -8.041047647533306],
              [-36.003986054406823, -8.041416730703618],
              [-36.004063767420114, -8.042024935378601],
              [-36.004139955271086, -8.042413905704189],
              [-36.004080845024681, -8.042682274394462],
              [-36.003948685250897, -8.04296319221277],
              [-36.003682750554454, -8.04333045665522],
              [-36.003380201575972, -8.043685703049915],
              [-36.002991648922951, -8.043980783134979],
              [-36.002566663003918, -8.04428832433268],
              [-36.004359934331319, -8.045760591060482],
              [-36.005162761976045, -8.045365093052212],
              [-36.005467284166819, -8.045277586303321],
              [-36.00562597733358, -8.045276424178466],
              [-36.005711620988251, -8.045300187259249],
              [-36.005761727477129, -8.045470283265109],
              [-36.005692538367732, -8.046018675677109],
              [-36.005611114159969, -8.046567158097099],
              [-36.005422932392307, -8.047424376492987],
              [-36.005136568929927, -8.048315197154281],
              [-36.00493441296706, -8.049047223790323],
              [-36.004840394634044, -8.04954710691948],
              [-36.004769679359903, -8.049888552062015],
              [-36.004820952974036, -8.050216908045282],
              [-36.004981624357093, -8.050483576559882],
              [-36.005081209292207, -8.050750692123799],
              [-36.005058446278774, -8.050970013489279],
              [-36.004855301215891, -8.051568079298837],
              [-36.004613296634702, -8.051862088273914],
              [-36.004248492087122, -8.052059525750479],
              [-36.00400654445842, -8.052287680323747],
              [-36.003909831844958, -8.052422356214821],
              [-36.003815093441887, -8.052824773753644],
              [-36.003719009875276, -8.053032437091122],
              [-36.003524955567585, -8.053204322286438],
              [-36.00290491924337, -8.053561987930671],
              [-36.001943467439006, -8.053970844131726],
              [-36.0015448776062, -8.054127792376541],
              [-36.001619350539741, -8.054654410555933],
              [-36.001821499461016, -8.055248512044335],
              [-36.002019623142189, -8.055542367701209],
              [-36.002469560835777, -8.056051814498494],
              [-36.002582360759142, -8.056169908146829],
              [-36.002614037023562, -8.056215398399017],
              [-36.003269796412269, -8.0569578884718],
              [-36.003655087019922, -8.057995726559946],
              [-36.003940845498917, -8.058504978467395],
              [-36.004222606873022, -8.058896369664286],
              [-36.005134865430009, -8.060018504883079],
              [-36.005433006026145, -8.060555976961256],
              [-36.005702763301031, -8.060935994092182],
              [-36.006009275684519, -8.061104207901121],
              [-36.006927731755148, -8.061487082581678],
              [-36.007537966249963, -8.061458209967142],
              [-36.008342975124854, -8.061354915746048],
              [-36.009951729741395, -8.060977869270005],
              [-36.009995132141086, -8.061083976893437],
              [-36.009999277168959, -8.06108306988757],
              [-36.010161611617924, -8.06142641386786],
              [-36.010259893480992, -8.061616497965069],
              [-36.010330768091769, -8.061736157643093],
              [-36.010589241212024, -8.062026402237581],
              [-36.010709116210307, -8.062175523060709],
              [-36.01090575378192, -8.062333776893878],
              [-36.011269541954952, -8.063002921728565],
              [-36.011401070357948, -8.06359238224692],
              [-36.011437564825236, -8.063869441915273],
              [-36.011417890056208, -8.064378695048763],
              [-36.01153778526178, -8.064819725661328],
              [-36.011645403227021, -8.065056381037925],
              [-36.011656345322272, -8.065066303091767],
              [-36.01171469483134, -8.065171626692846],
              [-36.01200624816731, -8.065425047033891],
              [-36.012471013688405, -8.065543398420578],
              [-36.01281261318681, -8.065504388938709],
              [-36.013470438908193, -8.065316889313157],
              [-36.014018054782412, -8.06506940439675],
              [-36.01515080089456, -8.064720138438755],
              [-36.016185572248901, -8.064310706177608],
              [-36.016854633960286, -8.063989243723121],
              [-36.01736544854333, -8.063729831769438],
              [-36.018125596831496, -8.063273820421605],
              [-36.018659795487906, -8.062868074555167],
              [-36.019084507375908, -8.062524111138542],
              [-36.019715771687267, -8.062044567739164],
              [-36.01947715727119, -8.061145421404976],
              [-36.019128245990117, -8.060186111781944],
              [-36.01880452998077, -8.059336281812264],
              [-36.018366480730549, -8.05794293658848],
              [-36.017930126612711, -8.056716516391388],
              [-36.0176600042319, -8.056199442415592],
              [-36.017400360002156, -8.055763050931381],
              [-36.017215389457427, -8.055520870582047],
              [-36.016638536883548, -8.055099008416144],
              [-36.015509214932166, -8.054267205135494],
              [-36.0166127723665, -8.053272900246958],
              [-36.017163617580493, -8.053475803090349],
              [-36.01748172899336, -8.053558737365424],
              [-36.017799387364924, -8.053592894349512],
              [-36.018264768960833, -8.053796424257824],
              [-36.01859673864341, -8.054110511944737],
              [-36.019295851125975, -8.054543666089412],
              [-36.019895728720215, -8.05477059304247],
              [-36.020372896354566, -8.054913237573251],
              [-36.020740128157705, -8.055044407112383],
              [-36.020850785055679, -8.0551531667015],
              [-36.020974220098431, -8.055334912728101],
              [-36.021329668867011, -8.055527053646882],
              [-36.021887401123443, -8.05570487433396],
              [-36.02236429772703, -8.05581093363625],
              [-36.022781284987822, -8.056063503883683],
              [-36.023124951710578, -8.056316614180375],
              [-36.023467896352166, -8.056460244813202],
              [-36.023871654424319, -8.056579035248276],
              [-36.024169481909183, -8.055590569974537],
              [-36.024346032072259, -8.055406520722562],
              [-36.024540708448932, -8.055319899051474],
              [-36.024833987649131, -8.055366424128977],
              [-36.025225690643389, -8.055497498647163],
              [-36.02553247106534, -8.055714383750281],
              [-36.025753697847868, -8.055907510774524],
              [-36.026032202982776, -8.056014849229598],
              [-36.026361283361076, -8.055939428532387],
              [-36.026678036635587, -8.05585181376356],
              [-36.027105720841547, -8.05590953980882],
              [-36.027644516976558, -8.056124709732682],
              [-36.028158389887146, -8.05627917731141],
              [-36.028634561408438, -8.056287853023331],
              [-36.028714953513557, -8.0556054197815],
              [-36.028833862079246, -8.055178437081757],
              [-36.028965186750376, -8.054775661517301],
              [-36.029035064939514, -8.054324740701563],
              [-36.029069233956065, -8.053995763666922],
              [-36.029129593333934, -8.053897848017796],
              [-36.029239618156566, -8.053921425088857],
              [-36.029730286015585, -8.054234327648031],
              [-36.030073772716086, -8.054463132801494],
              [-36.030428587419514, -8.054570084201741],
              [-36.03124797061318, -8.05475878475759],
              [-36.031770831545977, -8.054854734968371],
              [-36.032553420460182, -8.05503159948629],
              [-36.033055147281438, -8.05518615107499],
              [-36.033422741122322, -8.055365993906786],
              [-36.033815442231223, -8.055643120319617],
              [-36.034485922620526, -8.056246370530813],
              [-36.035309030210875, -8.056934214152545],
              [-36.036525026915925, -8.057923566330839],
              [-36.036584386190697, -8.05770397794311],
              [-36.036619638318726, -8.057521062790405],
              [-36.036643018388446, -8.057374820884293],
              [-36.036739991037223, -8.057288827284076],
              [-36.03714211393666, -8.057188468378987],
              [-36.037494932432807, -8.057027589139816],
              [-36.03783624189181, -8.056951979429797],
              [-36.038434125283885, -8.056911051741947],
              [-36.038922437890385, -8.056907430367147],
              [-36.039422348527125, -8.056830642539385],
              [-36.040082224458999, -8.056911021554853],
              [-36.040572445265909, -8.057163025737124],
              [-36.041154199653604, -8.057280477042433],
              [-36.041643329345312, -8.057386419332184],
              [-36.042082609565711, -8.057358767848502],
              [-36.042948660895185, -8.057254958582231],
              [-36.043886482616067, -8.056967961150368],
              [-36.044763946945466, -8.056754490200388],
              [-36.046036634083123, -8.056391741062317],
              [-36.047242070675487, -8.055968875597825],
              [-36.047971978820925, -8.05562253394424],
              [-36.048252016821102, -8.055523072132344],
              [-36.048650414137633, -8.053292157181385],
              [-36.049120339742004, -8.050736869197211],
              [-36.049856742226702, -8.046670604753778],
              [-36.047173665657873, -8.047019261829245],
              [-36.044136771498628, -8.047407113399753],
              [-36.042798846737028, -8.047575303403178],
              [-36.042542462834959, -8.047565009524231],
              [-36.042249645689601, -8.047591480897202],
              [-36.041860133917886, -8.047740528094518],
              [-36.041372742407333, -8.047865911208067],
              [-36.040738169744309, -8.04789491484741],
              [-36.039600254494857, -8.048110301990191],
              [-36.038576074044393, -8.048276153744233],
              [-36.037612339994354, -8.048368566011634],
              [-36.036490821857939, -8.048583824142396],
              [-36.035089533765436, -8.048947308380843],
              [-36.034153165200507, -8.049429029004552],
              [-36.033071243653772, -8.050045788571811],
              [-36.03224425785826, -8.050478007819056],
              [-36.030487664433387, -8.051242749773573],
              [-36.028918234365378, -8.051972681375668],
              [-36.028419408837628, -8.052195516101298],
              [-36.028164738129192, -8.052428741715584],
              [-36.028104921799859, -8.052587448734215],
              [-36.02807002671603, -8.052819051181761],
              [-36.02803377448717, -8.05288020457899],
              [-36.027985197148524, -8.05290486276825],
              [-36.027899099451822, -8.052820314311973],
              [-36.027776025441895, -8.052687257585143],
              [-36.027604465116518, -8.052603340188053],
              [-36.026603008673824, -8.052549849593472],
              [-36.025357847483839, -8.052559040549912],
              [-36.025039738056584, -8.052464008191251],
              [-36.02489174281699, -8.052258054037985],
              [-36.024866369540291, -8.052136561309862],
              [-36.024888578484791, -8.051831971426457],
              [-36.025154864822959, -8.050965780928866],
              [-36.025284656333646, -8.050356062105513],
              [-36.025372771059345, -8.049077004204161],
              [-36.02535782466795, -8.048699698896481],
              [-36.025195428105782, -8.048201710966861],
              [-36.024911135665732, -8.04772901127691],
              [-36.024417948544958, -8.047075196621213],
              [-36.023986749973467, -8.046554797749669],
              [-36.023555708229182, -8.045786521350557],
              [-36.023294263793197, -8.045094409303024],
              [-36.02319286468483, -8.044595969317026],
              [-36.023152820050953, -8.044133662517451],
              [-36.023041534086929, -8.043939631406433],
              [-36.022758421765381, -8.043625185681604],
              [-36.02264776887499, -8.043516425096449],
              [-36.022740733902175, -8.041239045070578],
              [-36.020375372448818, -8.039900353573852],
              [-36.019345328382293, -8.039274774484564],
              [-36.019027880751473, -8.038887857343648],
              [-36.018817010869284, -8.038438908746164],
              [-36.018704107730194, -8.038025825293229],
              [-36.018700864388776, -8.03758745460715],
              [-36.018957278042684, -8.035874541921475],
              [-36.019083032134773, -8.034716973742515],
              [-36.019209325284642, -8.033644677078481],
              [-36.020188891403457, -8.030719943441317],
              [-36.020265615712127, -8.030371411248547],
              [-36.017639378933673, -8.029862783025106],
              [-36.016771104414204, -8.029722903787368],
              [-36.015829969354478, -8.029571542542858],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0399000',
        uf: 'PE',
        nome_proje: 'PA AMAZONAS',
        municipio: 'IPOJUCA',
        area_hecta: '572.3219',
        capacidade: 57.0,
        num_famili: 57.0,
        fase: 3.0,
        data_de_cr: '10/06/2013',
        forma_obte: 'Desapropriação',
        data_obten: '19/05/2004',
        area_calc_: 571.5782,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.098147894931643, -8.347781783438128],
              [-35.097443684944452, -8.353922222373075],
              [-35.097063827924579, -8.357234435542678],
              [-35.095309739200253, -8.357977536683155],
              [-35.093672421880349, -8.358671160139698],
              [-35.092322528249873, -8.359243014784186],
              [-35.091144252440309, -8.359742162398168],
              [-35.089818933176524, -8.360303596152301],
              [-35.088471449734826, -8.36087441347531],
              [-35.087476453715219, -8.361295907350952],
              [-35.083838572153596, -8.362836937112279],
              [-35.084600790296662, -8.367464589544497],
              [-35.086907876438005, -8.367684541506458],
              [-35.087481447272303, -8.368270086383074],
              [-35.087748766881838, -8.368626396084329],
              [-35.087921407096523, -8.368916577850511],
              [-35.088121358174845, -8.368928450238021],
              [-35.088172431578087, -8.36903143492018],
              [-35.088109411454738, -8.369224829554897],
              [-35.088173725485291, -8.369291384128243],
              [-35.088546333151285, -8.369161827107275],
              [-35.088874634568107, -8.369319740129109],
              [-35.090405024473462, -8.369720741494739],
              [-35.090728626757617, -8.369764525097887],
              [-35.091217965397433, -8.369901805409848],
              [-35.092056699373046, -8.36987255006145],
              [-35.09244907329613, -8.369469300400091],
              [-35.092587584629634, -8.369354832558466],
              [-35.092828380510696, -8.369341149750131],
              [-35.092833792426724, -8.369188096870765],
              [-35.092959569046123, -8.369021999038068],
              [-35.093050554926009, -8.368951208644807],
              [-35.093204263395904, -8.368960733828532],
              [-35.093405817311513, -8.368986548606019],
              [-35.093482986392772, -8.369050244448193],
              [-35.093609756977301, -8.369070251669166],
              [-35.093757225226931, -8.369077741788526],
              [-35.093913772041709, -8.369035554313021],
              [-35.094095892489925, -8.368921888912741],
              [-35.094199507734906, -8.368883083717801],
              [-35.094309904796809, -8.368858306506274],
              [-35.094605808113918, -8.368803642522927],
              [-35.094847200229701, -8.368901621589357],
              [-35.095055370140777, -8.368999776650062],
              [-35.095297514312875, -8.369238368030858],
              [-35.095517573180707, -8.370006456508952],
              [-35.095958654352721, -8.370169547895589],
              [-35.0960012885376, -8.370376110757238],
              [-35.096326844260865, -8.37068043105277],
              [-35.096690568867686, -8.371216152010625],
              [-35.097013880585664, -8.371793444717554],
              [-35.097096060085718, -8.371849460204793],
              [-35.09724735499136, -8.372519129745241],
              [-35.097373745273408, -8.372681547217436],
              [-35.097624279300909, -8.372907222319672],
              [-35.097677417151822, -8.373091875984098],
              [-35.097753663858143, -8.373163140519553],
              [-35.097819064442938, -8.373153035151166],
              [-35.097902812902156, -8.373043977394259],
              [-35.097986984227688, -8.373013908528019],
              [-35.098106223585759, -8.373047833671219],
              [-35.098239765376469, -8.37316936823531],
              [-35.098355178774774, -8.373219304117523],
              [-35.098469431246045, -8.37324831874434],
              [-35.098667828383419, -8.373242327566111],
              [-35.098738883233999, -8.373377931839402],
              [-35.098763137964674, -8.373439298856253],
              [-35.098748891020982, -8.373557861407557],
              [-35.098749102667682, -8.3735973557674],
              [-35.098858103534312, -8.373572091756284],
              [-35.098966972095837, -8.373522143598921],
              [-35.099249699517785, -8.373515703854295],
              [-35.099392711685326, -8.373566587611112],
              [-35.099494277115532, -8.373795809416515],
              [-35.099677228382916, -8.373839921396343],
              [-35.099761540426705, -8.373680838734396],
              [-35.100053821349782, -8.373617493155633],
              [-35.100123463927218, -8.373697563043779],
              [-35.100295475863646, -8.373887349071735],
              [-35.105450207766317, -8.374473091655927],
              [-35.107142423741038, -8.372742159291869],
              [-35.109039665438665, -8.370803479300513],
              [-35.109267792220422, -8.370565909059884],
              [-35.109416404536972, -8.370418322465175],
              [-35.110411396571287, -8.369930659579731],
              [-35.114869153557073, -8.36774579428792],
              [-35.114779454893728, -8.367278346747408],
              [-35.114683183771504, -8.365918099980934],
              [-35.11439454406554, -8.361839655207358],
              [-35.114294589395421, -8.360427252375526],
              [-35.112698345419609, -8.35843323309334],
              [-35.111867712073803, -8.355360315919254],
              [-35.110985585473863, -8.352585960655739],
              [-35.109630239324318, -8.352120474480618],
              [-35.107275593925884, -8.35131176972874],
              [-35.105648425484148, -8.351213344456422],
              [-35.098147894931643, -8.347781783438128],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0287000',
        uf: 'PE',
        nome_proje: 'PA DOM HELDER CAMARA',
        municipio: 'MORENO',
        area_hecta: '125.4049',
        capacidade: 23.0,
        num_famili: 23.0,
        fase: 4.0,
        data_de_cr: '23/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '11/04/2001',
        area_calc_: 126.3996,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.196110641452378, -8.116166193514648],
              [-35.195644818307606, -8.116191394548684],
              [-35.195103186024554, -8.116220619528439],
              [-35.194090974125352, -8.116217760641353],
              [-35.193719739546296, -8.119097370575998],
              [-35.193491421442637, -8.120867108522772],
              [-35.193570516985176, -8.121167504526474],
              [-35.193876454694511, -8.122316265409681],
              [-35.194488091112142, -8.123700382195146],
              [-35.195194887457838, -8.125418430814252],
              [-35.195903726584724, -8.125626118816564],
              [-35.197184095960907, -8.125862354221363],
              [-35.198617354241748, -8.12640653428441],
              [-35.199462812062663, -8.126882299128548],
              [-35.20028825312307, -8.127346599975512],
              [-35.200879251257312, -8.127701439171505],
              [-35.20181477716558, -8.125447719620787],
              [-35.202537439861963, -8.123799325503535],
              [-35.202954412404438, -8.122899124841354],
              [-35.203452929401202, -8.121766087806925],
              [-35.204043199000871, -8.121972235062682],
              [-35.205456021791797, -8.122497249763716],
              [-35.208560812912033, -8.123652406474459],
              [-35.208979347837669, -8.122692356211955],
              [-35.209349057395414, -8.121844112979078],
              [-35.210039737424296, -8.120242349348111],
              [-35.209030946852678, -8.11957125512415],
              [-35.20854380602691, -8.119496434853252],
              [-35.20825121325646, -8.119451562132886],
              [-35.207777930280216, -8.119505833728049],
              [-35.207573423206412, -8.119446110454108],
              [-35.207433234697255, -8.119387755566313],
              [-35.20726872432266, -8.118994997074902],
              [-35.207193962158478, -8.118941529815491],
              [-35.206956926524811, -8.118921392866737],
              [-35.206353151621471, -8.118815927024945],
              [-35.205324383439859, -8.118438518248382],
              [-35.204089348527923, -8.118293714247693],
              [-35.203478605584273, -8.118324229177137],
              [-35.202182222022714, -8.118323653521436],
              [-35.202064301787004, -8.118323387697155],
              [-35.201203286224072, -8.118235214264182],
              [-35.198980634584942, -8.117340336659401],
              [-35.198262837521057, -8.116950380090952],
              [-35.196110641452378, -8.116166193514648],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0095000',
        uf: 'PE',
        nome_proje: 'PA ARARIBA DE PEDRA',
        municipio: 'CABO DE SANTO AGOSTINHO',
        area_hecta: '512.5000',
        capacidade: 62.0,
        num_famili: 60.0,
        fase: 4.0,
        data_de_cr: '30/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '17/09/1996',
        area_calc_: 626.8611,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.195338695219789, -8.220843404802606],
              [-35.195511601648711, -8.221018219313025],
              [-35.195748081431667, -8.22124617825496],
              [-35.195923536612007, -8.221535603813564],
              [-35.196540814457215, -8.221733462189347],
              [-35.196636058306368, -8.22185011414801],
              [-35.196697938584123, -8.22194402335426],
              [-35.196736843444263, -8.222044426183682],
              [-35.196705616682692, -8.22264192142247],
              [-35.196671659930203, -8.222975793660858],
              [-35.196681560408166, -8.223380748530177],
              [-35.196704956764641, -8.223450669367558],
              [-35.196910821174875, -8.223690256911059],
              [-35.197043033167155, -8.223787602325892],
              [-35.197283105192724, -8.223970963602421],
              [-35.197486824617791, -8.224169170011546],
              [-35.197624044509382, -8.224363282307511],
              [-35.197666697358649, -8.224447744252782],
              [-35.197694125586047, -8.224553304063145],
              [-35.197710434506547, -8.224728973355139],
              [-35.197729416529739, -8.224809743908922],
              [-35.197868708330311, -8.225147762857796],
              [-35.197977376053174, -8.225380239428604],
              [-35.198059303417899, -8.225631966394662],
              [-35.1980683496026, -8.225881544831159],
              [-35.197985156331811, -8.226095966776597],
              [-35.197813937646863, -8.226343984190088],
              [-35.1977840498798, -8.226488065857264],
              [-35.197791424560528, -8.226666331204118],
              [-35.197827744925135, -8.226993450781761],
              [-35.197979322574788, -8.227588671820985],
              [-35.198100473257803, -8.227766314524755],
              [-35.198260467028057, -8.228032897409605],
              [-35.198420123929836, -8.228238348658476],
              [-35.198566645990248, -8.22838019114128],
              [-35.198816162222947, -8.228652651646946],
              [-35.198867803295826, -8.22874406892894],
              [-35.198984656257387, -8.229069473567527],
              [-35.198999224344853, -8.229161093786953],
              [-35.199045976860042, -8.229293293328151],
              [-35.199061970921278, -8.229411651569395],
              [-35.199120524097523, -8.229597278116872],
              [-35.199316834339001, -8.229957908501147],
              [-35.199389056438143, -8.230072137903374],
              [-35.199481662475591, -8.230173519538246],
              [-35.199549522356577, -8.230192252004512],
              [-35.199624293870876, -8.230189295222873],
              [-35.199727445968293, -8.230116771204326],
              [-35.199857053968572, -8.229973416872493],
              [-35.200088398876865, -8.229848609322699],
              [-35.200217515684777, -8.229847901936969],
              [-35.200323867649168, -8.229891895571702],
              [-35.200403577467817, -8.229972969847555],
              [-35.200464090457018, -8.230050328456457],
              [-35.200706746687594, -8.230353391388444],
              [-35.200887499196092, -8.230558725451061],
              [-35.200987994031088, -8.230699545253833],
              [-35.201138303820876, -8.2308324502765],
              [-35.201362499827191, -8.230917826624633],
              [-35.201510912282252, -8.230938664292271],
              [-35.201857441752779, -8.231183844230426],
              [-35.202032995989953, -8.231258024380782],
              [-35.202304108164554, -8.231504891001421],
              [-35.202901363635263, -8.232240306917317],
              [-35.203094051584799, -8.232407365744995],
              [-35.203422587121239, -8.232634811990101],
              [-35.203496846455664, -8.232654782116832],
              [-35.203632933429304, -8.232758470892769],
              [-35.203712630387379, -8.232836997033852],
              [-35.203764216866723, -8.232918224678013],
              [-35.203790785029895, -8.233098930989188],
              [-35.203847471041307, -8.233177583406462],
              [-35.204073959694561, -8.234139186408141],
              [-35.204523034719031, -8.234894515791792],
              [-35.204798505585842, -8.235466125188379],
              [-35.205196258742959, -8.236880189002632],
              [-35.205233281189621, -8.237101592862102],
              [-35.2055744321516, -8.239143853765031],
              [-35.205593148359256, -8.239291489033661],
              [-35.205647397259654, -8.241237258931351],
              [-35.205689434608516, -8.242824575995652],
              [-35.205559564703357, -8.243036071962941],
              [-35.205572036927002, -8.243441009661685],
              [-35.205640350480422, -8.24377177107837],
              [-35.205733611392525, -8.243990317988089],
              [-35.20580078143665, -8.244114761662827],
              [-35.205916545813039, -8.244241485147091],
              [-35.206044720251384, -8.244300639261308],
              [-35.206237981421786, -8.24433905748239],
              [-35.206450298919812, -8.244355719441225],
              [-35.206531129314591, -8.244407492317476],
              [-35.206672653219371, -8.244568461011127],
              [-35.206757799329573, -8.244706815121328],
              [-35.20679671020816, -8.244807215606485],
              [-35.206791257667973, -8.244976634910522],
              [-35.206796957754563, -8.2450823126253],
              [-35.206833502605903, -8.245217113422216],
              [-35.206942974425687, -8.245361701529417],
              [-35.206984414049387, -8.24545699363431],
              [-35.207006797391614, -8.245574041903208],
              [-35.207151319880573, -8.248578949743251],
              [-35.206768842328366, -8.250613727390698],
              [-35.206979699873287, -8.251633358715964],
              [-35.208861880092776, -8.251793009569555],
              [-35.209291686024343, -8.251835215155538],
              [-35.21184791146009, -8.251701389165138],
              [-35.213126014817334, -8.251633196652353],
              [-35.216992411604316, -8.251223368922604],
              [-35.218063357477988, -8.250690172331488],
              [-35.220086820951494, -8.250625472561904],
              [-35.220183910783874, -8.250612198562361],
              [-35.222060237120651, -8.250527926970689],
              [-35.222152099106395, -8.250494304062263],
              [-35.222213435338517, -8.250488869462044],
              [-35.222326977228192, -8.25044557433206],
              [-35.222395042246653, -8.250385974789062],
              [-35.222470263199455, -8.250348623363449],
              [-35.222562203407406, -8.250329009442792],
              [-35.222638944839765, -8.250334951630347],
              [-35.222737554877661, -8.250364970678241],
              [-35.222832165846228, -8.250365719313548],
              [-35.222940590314558, -8.250321815633095],
              [-35.223010533673261, -8.250255200845348],
              [-35.223080626550782, -8.250215330610077],
              [-35.223153383098463, -8.250194549428475],
              [-35.223265899146845, -8.250196472203072],
              [-35.223404332769768, -8.250260656995815],
              [-35.223513944774005, -8.250314812980038],
              [-35.223713743097015, -8.250378657046964],
              [-35.223878838619299, -8.250409580319486],
              [-35.224060407680788, -8.250414303484748],
              [-35.224239529765192, -8.250210171584291],
              [-35.224420047669113, -8.250027046109805],
              [-35.224650310055537, -8.249823266960219],
              [-35.224832448085074, -8.249701264646953],
              [-35.225036404767536, -8.2495944241545],
              [-35.225129565937593, -8.249564614237796],
              [-35.22520114352551, -8.249561669524271],
              [-35.226933769308566, -8.249159778028408],
              [-35.226893508964032, -8.247449574780214],
              [-35.226713049462333, -8.246957699371862],
              [-35.226024272262329, -8.245414116770869],
              [-35.22598667809109, -8.245321353642783],
              [-35.225736257851054, -8.244892271494292],
              [-35.225661965512536, -8.244752589302758],
              [-35.2255803091332, -8.244667712253417],
              [-35.225484086434513, -8.244607114219379],
              [-35.225398240647223, -8.244573203903116],
              [-35.225311209063591, -8.244555856790747],
              [-35.224492741047875, -8.241307652870603],
              [-35.224013108020998, -8.239438136347655],
              [-35.223130860296905, -8.237583584941712],
              [-35.222233104333903, -8.235697275388249],
              [-35.219999089632964, -8.230999892107338],
              [-35.219933367734221, -8.230906009560359],
              [-35.219529523874328, -8.228978747354613],
              [-35.219121716553161, -8.227027307170038],
              [-35.218776075850322, -8.225334061831964],
              [-35.218693287549868, -8.225389283570882],
              [-35.218500178161968, -8.225376340326456],
              [-35.218298123824361, -8.225364083171932],
              [-35.218206919851546, -8.225400247367228],
              [-35.218123805565355, -8.225396885430905],
              [-35.217970183449516, -8.225358252023806],
              [-35.217464178197709, -8.225171279168796],
              [-35.217382172331028, -8.225137344645534],
              [-35.217311579609898, -8.225086790365999],
              [-35.217250112397075, -8.225068025618265],
              [-35.216753002464998, -8.225101334777026],
              [-35.216644710419324, -8.225168159221763],
              [-35.216481887919976, -8.225314247460489],
              [-35.216357408899896, -8.225458850557088],
              [-35.216245812462198, -8.22562121293285],
              [-35.216174911033136, -8.225745142923413],
              [-35.216103747692912, -8.225821951265084],
              [-35.215917772742863, -8.225942695161679],
              [-35.215854003457714, -8.225969792411803],
              [-35.215803130977349, -8.226017196082385],
              [-35.215701463816146, -8.226126012548814],
              [-35.215569002761583, -8.226214621267889],
              [-35.215508009478384, -8.226281184700214],
              [-35.21545865586539, -8.22637188228283],
              [-35.215395155362749, -8.226447374709345],
              [-35.2152786924959, -8.226654339729473],
              [-35.215221491739833, -8.226713240643067],
              [-35.215074300909606, -8.226796836106008],
              [-35.214945120278621, -8.226900709805182],
              [-35.214865371220668, -8.226927895062213],
              [-35.214803040629135, -8.226753755791957],
              [-35.214743479060637, -8.22661780916707],
              [-35.214740115889406, -8.226472637537636],
              [-35.214699489300571, -8.226293284168658],
              [-35.214595227714391, -8.226165225498459],
              [-35.214502747275588, -8.226086772174197],
              [-35.214470321474749, -8.226002893416469],
              [-35.214483224405903, -8.22590921282365],
              [-35.214471096789808, -8.22579720301774],
              [-35.214450487447849, -8.225769297451325],
              [-35.214167538766176, -8.225693167356614],
              [-35.214022681171279, -8.225621370507675],
              [-35.21395338111283, -8.225573355624906],
              [-35.213775010526007, -8.225452072968642],
              [-35.213556480753795, -8.225235491515868],
              [-35.213447389133911, -8.225158402953435],
              [-35.213382086862197, -8.225139658694953],
              [-35.21330271559615, -8.225119718318822],
              [-35.213182210707004, -8.225059249320468],
              [-35.213019760344928, -8.22504231367552],
              [-35.212747816376414, -8.22510621774509],
              [-35.212272175221337, -8.225092280013847],
              [-35.21209564870847, -8.225072874296314],
              [-35.211713405313098, -8.225072431298315],
              [-35.211397509375971, -8.225048697972412],
              [-35.210951955259951, -8.225042871468858],
              [-35.210733235749494, -8.225022423689964],
              [-35.210405552114963, -8.224947810341371],
              [-35.210192094263228, -8.224954078933932],
              [-35.210087205229591, -8.224943193447222],
              [-35.209955075423132, -8.224861136043755],
              [-35.209835842170634, -8.224799385285278],
              [-35.209725910288206, -8.224801263445961],
              [-35.209437628903444, -8.224916199314094],
              [-35.209335360070028, -8.224916761631516],
              [-35.209198469660393, -8.224898410249086],
              [-35.208984736534092, -8.224855009199857],
              [-35.208723429, -8.224762199010087],
              [-35.208546479235238, -8.224666377768362],
              [-35.20829434603737, -8.224614272132049],
              [-35.208223909289863, -8.22459173435584],
              [-35.208147221765408, -8.224594702984001],
              [-35.208046414602052, -8.224628370625574],
              [-35.207969980893346, -8.224677187561923],
              [-35.207918662046971, -8.224759616991856],
              [-35.207906068799289, -8.224794073489573],
              [-35.207875483311774, -8.224811435200891],
              [-35.20782301761367, -8.224802171442986],
              [-35.207720477263805, -8.224753701095494],
              [-35.207645972765526, -8.224689156630927],
              [-35.207380643551197, -8.224562617035282],
              [-35.207299143391431, -8.224504478932447],
              [-35.207232344400083, -8.224446260054519],
              [-35.20700562486666, -8.22425073342192],
              [-35.20693805287776, -8.224168320229138],
              [-35.206868750122986, -8.224119667041643],
              [-35.206752808389361, -8.224075090779031],
              [-35.206600436931033, -8.224031351312519],
              [-35.206477962515493, -8.223961338720851],
              [-35.206196326952181, -8.223775665002798],
              [-35.206123323099241, -8.223751230473912],
              [-35.206038873878512, -8.223737684395171],
              [-35.205947457081216, -8.223735638995464],
              [-35.205823593528706, -8.223761154199392],
              [-35.20574303572986, -8.223757775547407],
              [-35.205652089068685, -8.223725160963717],
              [-35.205472484899403, -8.22361152213557],
              [-35.205403298560718, -8.223583882486707],
              [-35.205218290420326, -8.223533316673533],
              [-35.205067984581433, -8.223516310915224],
              [-35.204927350933993, -8.223514535315019],
              [-35.204770211216598, -8.223533227918082],
              [-35.20457488960529, -8.22358269647925],
              [-35.204481512990029, -8.223573019849603],
              [-35.20432941624464, -8.223578948561252],
              [-35.204116155220468, -8.223620873672649],
              [-35.203990777326403, -8.22360373079484],
              [-35.203692504068556, -8.223530223642879],
              [-35.203112347800506, -8.223341726482635],
              [-35.202858943965744, -8.223290897594024],
              [-35.202821794133811, -8.223277091528631],
              [-35.202724287061827, -8.223213945552882],
              [-35.202577057560141, -8.223175270609824],
              [-35.202453884882615, -8.223094434691443],
              [-35.195338695219789, -8.220843404802606],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0144000',
        uf: 'PE',
        nome_proje: 'PA CAMARAGIBE',
        municipio: 'CAMARAGIBE',
        area_hecta: '293.3100',
        capacidade: 120.0,
        num_famili: 116.0,
        fase: 4.0,
        data_de_cr: '25/08/1998',
        forma_obte: 'Desapropriação',
        data_obten: '10/11/1995',
        area_calc_: 235.0565,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.972024950441117, -8.011050098221338],
              [-34.972051990477553, -8.01058694994439],
              [-34.971876308957171, -8.010238688111059],
              [-34.970082439312698, -8.008572581479676],
              [-34.970931116119097, -8.00820502862527],
              [-34.97165319365326, -8.007947918572366],
              [-34.97197497992282, -8.007417824303333],
              [-34.972534745083308, -8.006348498581367],
              [-34.972768042347639, -8.005413474372125],
              [-34.971783065901448, -8.004737387313646],
              [-34.970954069632683, -8.004092601373808],
              [-34.967611316285897, -8.002602048602634],
              [-34.96642857234243, -8.002110090754741],
              [-34.964904284495631, -8.001503942015264],
              [-34.965235164613652, -7.998998284650908],
              [-34.964194802592218, -7.998508597804642],
              [-34.963821355693042, -7.998356163380491],
              [-34.963315350463468, -7.99815340627266],
              [-34.962919314172673, -7.99757711816689],
              [-34.962852189583771, -7.997443076205682],
              [-34.96276659662913, -7.997248766369185],
              [-34.962228658525383, -7.997303709351575],
              [-34.961561619423215, -7.997720668049955],
              [-34.961404667392294, -7.997793830903563],
              [-34.960991768071572, -7.997824703278141],
              [-34.959163589867913, -7.997948263283612],
              [-34.958497028987622, -7.998350807290883],
              [-34.958311685831681, -7.998803769344585],
              [-34.957978425973828, -7.998849844011273],
              [-34.958096512022514, -7.999887899792491],
              [-34.958528199565954, -8.000880159943586],
              [-34.958443560897926, -8.001004773787601],
              [-34.958331318128749, -8.001217285492933],
              [-34.95821332421302, -8.001339091704295],
              [-34.958065166405831, -8.001453570573522],
              [-34.957735163922408, -8.001632176969116],
              [-34.957528571204932, -8.001708759202213],
              [-34.957418655942924, -8.001725469930397],
              [-34.957228898939931, -8.001780467957211],
              [-34.957031050366346, -8.001862628410604],
              [-34.956769638122658, -8.001945674931932],
              [-34.956451378597805, -8.002083698245531],
              [-34.956277819000512, -8.002177890391907],
              [-34.956187617797191, -8.00223785283135],
              [-34.956161764190355, -8.00228101969852],
              [-34.956202487445552, -8.00236286596183],
              [-34.956277539074335, -8.002457664936628],
              [-34.956288055176849, -8.002550998312593],
              [-34.956242092550781, -8.002689071225536],
              [-34.956186176304293, -8.002780800975239],
              [-34.95618437426608, -8.003089166534632],
              [-34.956183931422366, -8.003214965982007],
              [-34.956188773222479, -8.003409197984183],
              [-34.956222754038592, -8.003564874010477],
              [-34.956137211068665, -8.004123560218963],
              [-34.955832671485389, -8.004735496458782],
              [-34.955544945778037, -8.004893989568346],
              [-34.955315654873985, -8.00509037929306],
              [-34.955172292708021, -8.00511853116962],
              [-34.954843915306341, -8.005363402048657],
              [-34.954652877169664, -8.005524369955397],
              [-34.954466651272817, -8.005692530887734],
              [-34.954271924014932, -8.005932492857202],
              [-34.954306009347036, -8.006167439420217],
              [-34.954349815885259, -8.006441489299107],
              [-34.954535145215225, -8.00675416102259],
              [-34.954812892403098, -8.007268198851634],
              [-34.955246990657791, -8.008114372117873],
              [-34.955326919620681, -8.008180348933518],
              [-34.956165861504125, -8.007709091259843],
              [-34.956192997836084, -8.007874913371795],
              [-34.956540400220092, -8.008557485336718],
              [-34.956356153724272, -8.008664776271544],
              [-34.955729065328342, -8.008960329354057],
              [-34.955870938995126, -8.009163393504785],
              [-34.955869022650134, -8.00933014253328],
              [-34.956679534899372, -8.01041212661999],
              [-34.956847413415183, -8.010640272055975],
              [-34.956268364255337, -8.010777186184416],
              [-34.956621743448686, -8.011207673596742],
              [-34.956822193854414, -8.011450639368521],
              [-34.957254807158144, -8.011787386131797],
              [-34.957524623819317, -8.011856536022554],
              [-34.958189085602775, -8.012336934729335],
              [-34.958608468885586, -8.012669485395607],
              [-34.959023372165987, -8.013042386005075],
              [-34.959317129457645, -8.013239012645577],
              [-34.958795997618864, -8.013907800601277],
              [-34.958540737059899, -8.01402133031478],
              [-34.958612126038339, -8.014595807164689],
              [-34.958606922218046, -8.015008609510728],
              [-34.958569871970184, -8.0155229719049],
              [-34.958525291794182, -8.015626551608552],
              [-34.958373832504954, -8.015666281414196],
              [-34.958239222872855, -8.015900356036523],
              [-34.958055857815694, -8.016308035248608],
              [-34.957906249764228, -8.01662712366543],
              [-34.957724304068854, -8.016950321659108],
              [-34.957683599458399, -8.016993168473787],
              [-34.957451182778946, -8.017607124162767],
              [-34.957485195528456, -8.017934251715374],
              [-34.957615808341274, -8.018270309099941],
              [-34.957709442810248, -8.018252032129411],
              [-34.957753412468726, -8.018365096402388],
              [-34.95752093931673, -8.018919934031414],
              [-34.957698660813442, -8.019731096563225],
              [-34.957786241134251, -8.020424314403838],
              [-34.958085565619086, -8.020945997737476],
              [-34.958318064866731, -8.021079527185432],
              [-34.959389447193139, -8.021358677251534],
              [-34.959809547066179, -8.021491504208393],
              [-34.960052613317529, -8.021206015753636],
              [-34.960344850340093, -8.020358435090827],
              [-34.961776134682438, -8.017916676649991],
              [-34.961827569024869, -8.017857503267559],
              [-34.963718815217298, -8.017087289544699],
              [-34.965766828565194, -8.016264608059833],
              [-34.964403581999697, -8.015714952820545],
              [-34.963120371653915, -8.014900445467907],
              [-34.962905785365464, -8.013773643887102],
              [-34.962968441724101, -8.013221372180411],
              [-34.96334737307712, -8.01215067728598],
              [-34.963730553502451, -8.011560995996952],
              [-34.963923652307848, -8.010528101818096],
              [-34.963993139833022, -8.010021850799339],
              [-34.963537975528659, -8.009834202302436],
              [-34.963287536753377, -8.008944478410372],
              [-34.963381049377411, -8.008516386198856],
              [-34.964148810673422, -8.008881005720355],
              [-34.964309857297792, -8.008408217081001],
              [-34.963134549943192, -8.007656220226163],
              [-34.963527218876671, -8.007106196270666],
              [-34.963777141629421, -8.006146244580837],
              [-34.964274292679747, -8.005474000145453],
              [-34.964658352384809, -8.004916507004358],
              [-34.964790117226549, -8.004460785946129],
              [-34.965280697358146, -8.004568328233434],
              [-34.966112568202, -8.004895148794565],
              [-34.96768380763352, -8.005400637363751],
              [-34.967916583125302, -8.005818062533027],
              [-34.968251775334167, -8.005702646278486],
              [-34.968313939858469, -8.006009960121341],
              [-34.968518250136277, -8.006347382587133],
              [-34.968203173005314, -8.007153003809774],
              [-34.967002794381273, -8.007811917455438],
              [-34.966907341567953, -8.008977554649123],
              [-34.966462098823342, -8.009399255995705],
              [-34.967275111500491, -8.009879611007024],
              [-34.967986101303524, -8.010276701719784],
              [-34.968421235010943, -8.010704094108197],
              [-34.968752002974682, -8.011379736284029],
              [-34.969334951062159, -8.011586651970125],
              [-34.96967294038803, -8.011799032650686],
              [-34.969797240369594, -8.011878877533888],
              [-34.970019775943676, -8.012061191028019],
              [-34.97049579719441, -8.012425027504493],
              [-34.970786415756471, -8.012409291657562],
              [-34.970850690227756, -8.012255443599345],
              [-34.971610352544111, -8.011485009820857],
              [-34.972024950441117, -8.011050098221338],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0407000',
        uf: 'PE',
        nome_proje: 'PA CASULO BOM DESTINO',
        municipio: 'SAO BENEDITO DO SUL',
        area_hecta: '397.4273',
        capacidade: 45.0,
        num_famili: 44.0,
        fase: 3.0,
        data_de_cr: '28/11/2013',
        forma_obte: 'Desapropriação',
        data_obten: '04/05/2009',
        area_calc_: 422.6773,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.931447275699739, -8.776985228436091],
              [-35.931421926631486, -8.77694892859401],
              [-35.931419609748218, -8.777035491168231],
              [-35.931416388288405, -8.777053132274583],
              [-35.931411104586587, -8.777120385407461],
              [-35.931392103528324, -8.777198585811547],
              [-35.931385289642733, -8.777290694012546],
              [-35.931371176509451, -8.77737843233729],
              [-35.931341404275692, -8.777449579714547],
              [-35.931333732697162, -8.777536545271415],
              [-35.931316344143099, -8.777611932648631],
              [-35.931310546116826, -8.777683074353646],
              [-35.931316271962679, -8.777753042408129],
              [-35.931310277439493, -8.777822288525838],
              [-35.931314534075049, -8.777890190217597],
              [-35.931327656371714, -8.777953777058082],
              [-35.931316152447785, -8.778003733500331],
              [-35.931320096864987, -8.778020325181123],
              [-35.931314723093145, -8.778087669349599],
              [-35.931305044558485, -8.778161912745473],
              [-35.931299689975077, -8.778243259278479],
              [-35.931289619852016, -8.77832545553396],
              [-35.931290572746406, -8.778400519666855],
              [-35.93127165468438, -8.778477725722434],
              [-35.931261508643843, -8.778550256309106],
              [-35.931257422489423, -8.778619668271983],
              [-35.931242956069845, -8.778697110738326],
              [-35.931235692431898, -8.778778201110823],
              [-35.931228090419573, -8.778862455967159],
              [-35.931224216380912, -8.778924187483792],
              [-35.931217333571496, -8.778938153167864],
              [-35.931198348034776, -8.778983650837107],
              [-35.93117730094427, -8.779044160779026],
              [-35.931111597657342, -8.779107637693944],
              [-35.931026015521518, -8.779160699495886],
              [-35.930922835348603, -8.779216246888067],
              [-35.930755071820194, -8.77924664004993],
              [-35.930592141540373, -8.779256488661856],
              [-35.930428546488145, -8.779251075102763],
              [-35.930276410070555, -8.779247740545738],
              [-35.930126084811477, -8.779232196111645],
              [-35.930001124348266, -8.779231812110346],
              [-35.929865621072402, -8.779253462348247],
              [-35.929741273459214, -8.7792964361795],
              [-35.929637728241389, -8.779386495601054],
              [-35.9295116473184, -8.779474742652805],
              [-35.929398993605105, -8.779584476358814],
              [-35.929260758664519, -8.779663061195786],
              [-35.929145647580555, -8.779691147544408],
              [-35.929042982665294, -8.779719859875057],
              [-35.928944767932265, -8.779760191346968],
              [-35.928853621256479, -8.779787007389032],
              [-35.928768155670014, -8.77980872029203],
              [-35.92867857894246, -8.77982730324703],
              [-35.9285849989081, -8.779844923557121],
              [-35.928503188338226, -8.779846372080758],
              [-35.928413066777473, -8.779853395825487],
              [-35.928333209164741, -8.779872264575667],
              [-35.928248853738864, -8.779896588581996],
              [-35.928157186185082, -8.779926480049431],
              [-35.928079900062272, -8.779972159480735],
              [-35.928020562684928, -8.779979034384141],
              [-35.928002052967457, -8.779981075263615],
              [-35.925359705063379, -8.780285342838079],
              [-35.925256569864111, -8.780416321968818],
              [-35.924179141103949, -8.781397815951806],
              [-35.924142192377204, -8.781445711409923],
              [-35.924128800867202, -8.781509865876185],
              [-35.92411050220867, -8.781573606725802],
              [-35.924101599224691, -8.78164269504134],
              [-35.924080018564339, -8.781716398681258],
              [-35.924051804875802, -8.781778048299438],
              [-35.924035281113689, -8.781848099127311],
              [-35.924025104852923, -8.781905362879112],
              [-35.924018843247111, -8.781963861007588],
              [-35.924008971983831, -8.782025278003436],
              [-35.923995545770389, -8.782085006120742],
              [-35.923993404497836, -8.78214780858184],
              [-35.923977983336414, -8.782207913541853],
              [-35.923969915482246, -8.782267690430642],
              [-35.923951223118422, -8.782327549753081],
              [-35.923961350120329, -8.782391431454457],
              [-35.92392888894684, -8.782455733874734],
              [-35.923914005010893, -8.782503277494996],
              [-35.92389834628765, -8.782556247490158],
              [-35.923886485196704, -8.782607110219351],
              [-35.923881084219389, -8.782659548950637],
              [-35.923868066876963, -8.782701928745229],
              [-35.923857664926814, -8.782765156654513],
              [-35.923840470788861, -8.782830786071166],
              [-35.923834048807066, -8.782891995639277],
              [-35.923837143251887, -8.782950782556584],
              [-35.923824569702603, -8.783015021044287],
              [-35.923826201308053, -8.783072554556298],
              [-35.92382118056414, -8.783127158475356],
              [-35.923816141251422, -8.783190977156407],
              [-35.923813942481992, -8.783258026020002],
              [-35.923813887533051, -8.783332104724966],
              [-35.9238031400658, -8.783397593794028],
              [-35.923795558128312, -8.783461432203175],
              [-35.923793819782169, -8.783529290545964],
              [-35.923785529800327, -8.78359548328002],
              [-35.923788078563859, -8.783665747521766],
              [-35.923788214468253, -8.78374099915272],
              [-35.923773836643726, -8.783818350857356],
              [-35.923756377718625, -8.783896539510023],
              [-35.923736265364738, -8.78398378270038],
              [-35.923721752665521, -8.784067097843948],
              [-35.923695821494107, -8.784153482785859],
              [-35.923684568685601, -8.784235598232975],
              [-35.923675568527095, -8.784303874280068],
              [-35.923664581219299, -8.784373520830194],
              [-35.923656391250617, -8.78444088720294],
              [-35.9236331895859, -8.784504756465861],
              [-35.923597729643895, -8.784557247738611],
              [-35.923563989937328, -8.784620656732473],
              [-35.923521615634563, -8.784683138983196],
              [-35.923482472888523, -8.784740808168454],
              [-35.923444546361374, -8.784779858008232],
              [-35.923403980718092, -8.784818205610673],
              [-35.923392642961957, -8.78483157345859],
              [-35.923096482182345, -8.78587205147419],
              [-35.921870772508953, -8.792256274514413],
              [-35.921314189366633, -8.795247488820069],
              [-35.921306436651079, -8.79528955686585],
              [-35.92126757022168, -8.795498362124304],
              [-35.921187137216769, -8.795907501703647],
              [-35.92133410799233, -8.796165906313451],
              [-35.921482566587564, -8.796278220287459],
              [-35.921657344034081, -8.796330344446883],
              [-35.921793553509353, -8.796582960191039],
              [-35.921862092050347, -8.796776115861043],
              [-35.921978037542146, -8.797015608883228],
              [-35.922356044507239, -8.797319827565019],
              [-35.922431569020311, -8.797361610216022],
              [-35.922688174542685, -8.797599016633269],
              [-35.922904740251482, -8.797837727543673],
              [-35.923181053478785, -8.797982563163295],
              [-35.923396623851744, -8.79809444490906],
              [-35.923658199802958, -8.798085727389454],
              [-35.92387874521237, -8.797963772024197],
              [-35.924052931816419, -8.797928992891048],
              [-35.924437513288666, -8.798052479019303],
              [-35.924523357980156, -8.798113152156541],
              [-35.924825102742467, -8.798373874533475],
              [-35.924948611054475, -8.7987202687017],
              [-35.925111614680006, -8.798972854602198],
              [-35.925165284432325, -8.798972437321817],
              [-35.925299818664406, -8.799011411556407],
              [-35.925462913482704, -8.799263996475524],
              [-35.925464803247557, -8.799504464655108],
              [-35.925559962858543, -8.799664076677052],
              [-35.925495897130162, -8.800051949245395],
              [-35.92556584221856, -8.800412129564876],
              [-35.925783987155519, -8.800851287950458],
              [-35.92600148850665, -8.801196950051667],
              [-35.926117434085519, -8.801239501186551],
              [-35.926245526500686, -8.801279157272567],
              [-35.926705532374442, -8.801361761692734],
              [-35.926880703575954, -8.801440529356334],
              [-35.927035782136393, -8.801539598891594],
              [-35.927136597331547, -8.80155209415592],
              [-35.927402705690618, -8.801565200042976],
              [-35.92777513871652, -8.801898633190667],
              [-35.927924390892557, -8.802111303663942],
              [-35.928116696255735, -8.802670901782584],
              [-35.928307740603124, -8.8030701577981],
              [-35.928580598251074, -8.803629128015118],
              [-35.92870348230236, -8.803895393451539],
              [-35.929088824181854, -8.804253024760362],
              [-35.92926697758503, -8.804295089898424],
              [-35.929458571287, -8.804337050229332],
              [-35.929598912030258, -8.80428274723096],
              [-35.930140948417382, -8.80406785357436],
              [-35.930679928889802, -8.803880175107732],
              [-35.930675285016704, -8.805077651464405],
              [-35.931814754763678, -8.805316114937396],
              [-35.932031884244346, -8.804841498860753],
              [-35.932911008136642, -8.80530277665553],
              [-35.933041275656606, -8.805007618038468],
              [-35.933240233960774, -8.804705057825661],
              [-35.933268621601009, -8.804665629426493],
              [-35.933640440000069, -8.804148521648894],
              [-35.933582846191172, -8.803559150729278],
              [-35.9335299885783, -8.803017531875023],
              [-35.933496403510397, -8.802674146058136],
              [-35.9334945313866, -8.802655550917395],
              [-35.933665978318729, -8.80266496382348],
              [-35.93399369941983, -8.802683004372076],
              [-35.934034403119483, -8.802685216290511],
              [-35.934206486390714, -8.802694714304597],
              [-35.934397766557737, -8.802685543066755],
              [-35.935344463531855, -8.803401313877858],
              [-35.936124422068112, -8.803127281289035],
              [-35.936141539182188, -8.80312127564418],
              [-35.936103711999088, -8.803011719572412],
              [-35.936104478998537, -8.802936281073581],
              [-35.936117514871874, -8.802861921183027],
              [-35.936132124485141, -8.802791343216782],
              [-35.936163353361984, -8.802720725837712],
              [-35.936211195495865, -8.802660819348894],
              [-35.936262803384309, -8.802617776717689],
              [-35.936299858284549, -8.802583429861473],
              [-35.936350056644095, -8.802545908869959],
              [-35.93635664811606, -8.802541069475115],
              [-35.936392406402483, -8.802515043853305],
              [-35.936450994067357, -8.802493356899538],
              [-35.936544152447425, -8.802467786464101],
              [-35.936545364217388, -8.802448715625271],
              [-35.936673377690234, -8.800510230560647],
              [-35.936677916462301, -8.800440905648106],
              [-35.936733568519486, -8.799598247220088],
              [-35.937329900897751, -8.792624897735442],
              [-35.937146443724593, -8.790376995765081],
              [-35.937143037255041, -8.790336550780561],
              [-35.9371210882734, -8.790075102246385],
              [-35.937130089431186, -8.78981142487463],
              [-35.937595787217091, -8.788625083985139],
              [-35.938008818062606, -8.787992656597877],
              [-35.938675003994248, -8.786400037188709],
              [-35.938857891188874, -8.785615288204284],
              [-35.938714282349764, -8.783894110574179],
              [-35.93877401746709, -8.783511333494115],
              [-35.938923460065581, -8.783299499460751],
              [-35.939442290424694, -8.782967796720476],
              [-35.939808137673488, -8.782813536520909],
              [-35.940099808112485, -8.782603845075563],
              [-35.940787762933034, -8.782011641970984],
              [-35.941918752509558, -8.780689754013398],
              [-35.941234461297306, -8.779246523373089],
              [-35.941299239807051, -8.778271090146029],
              [-35.94121296177542, -8.778016648523952],
              [-35.940985829132622, -8.778118514584506],
              [-35.940985829132622, -8.778118514584506],
              [-35.940710420292291, -8.778397729292356],
              [-35.940290994625158, -8.778724320092744],
              [-35.939762551769554, -8.779057000252262],
              [-35.939210503292053, -8.77939049635914],
              [-35.938653555821134, -8.779724752846391],
              [-35.938094545679697, -8.780062457764084],
              [-35.937731285944842, -8.780279932320678],
              [-35.937651023482132, -8.780327985349119],
              [-35.937575099775295, -8.780373475096544],
              [-35.936996100832268, -8.780606361585598],
              [-35.936935343086631, -8.780617314178324],
              [-35.936831783052028, -8.780636007953472],
              [-35.936409743340462, -8.780712108280015],
              [-35.935776603451174, -8.780677651879461],
              [-35.935147076561762, -8.780502238199402],
              [-35.934538593743746, -8.780162062653051],
              [-35.934290214929504, -8.779930109373383],
              [-35.934258012384028, -8.779900006252433],
              [-35.934046227098165, -8.779702186900202],
              [-35.933592633679297, -8.779230174075845],
              [-35.933255389171528, -8.77887596022244],
              [-35.932714767367585, -8.778307327887655],
              [-35.932331857432935, -8.777908298437243],
              [-35.932217001526908, -8.777795364738846],
              [-35.931998453407886, -8.777580251040613],
              [-35.93184062853009, -8.777384449234498],
              [-35.93182031913404, -8.777353891949646],
              [-35.931524448749464, -8.777064036359439],
              [-35.931528878377819, -8.777061472442185],
              [-35.931447275699739, -8.776985228436091],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0317000',
        uf: 'PE',
        nome_proje: 'PA BONFIM',
        municipio: 'IPOJUCA',
        area_hecta: '202.6666',
        capacidade: 17.0,
        num_famili: 8.0,
        fase: 3.0,
        data_de_cr: '27/10/2005',
        forma_obte: 'Desapropriação',
        data_obten: '29/08/2003',
        area_calc_: 202.543,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.123935673240474, -8.341387626688194],
              [-35.111404106151184, -8.344111199880938],
              [-35.111341260903252, -8.347432383190679],
              [-35.110352013005951, -8.347109881597762],
              [-35.110120254612504, -8.346207163557109],
              [-35.109664020797048, -8.345757616855417],
              [-35.109888497771429, -8.345304445077378],
              [-35.108974821663431, -8.344179367765275],
              [-35.109648859010697, -8.342932844456751],
              [-35.108963910295593, -8.342145530610308],
              [-35.107045227146962, -8.341477769065985],
              [-35.10589647195161, -8.341687534827074],
              [-35.104491326477472, -8.343223225573366],
              [-35.103241900951062, -8.343550762926235],
              [-35.101236562810506, -8.342905398576294],
              [-35.100750832881168, -8.342946482130985],
              [-35.098264218552906, -8.347867792440427],
              [-35.105601974580495, -8.351161645744801],
              [-35.111129864330579, -8.352547889255399],
              [-35.12552929543763, -8.34385914570538],
              [-35.123935673240474, -8.341387626688194],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0248000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA',
        municipio: 'ESCADA',
        area_hecta: '220.8771',
        capacidade: 15.0,
        num_famili: 14.0,
        fase: 5.0,
        data_de_cr: '14/12/2001',
        forma_obte: 'Desapropriação',
        data_obten: '30/01/2001',
        area_calc_: 234.854,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.211235586360132, -8.421962176195425],
              [-35.211320890956387, -8.425073975274985],
              [-35.212154916300662, -8.428581705994835],
              [-35.212507241593229, -8.431872502653784],
              [-35.212661860928584, -8.432578743966118],
              [-35.213184770818614, -8.434967667528573],
              [-35.212773452092904, -8.436975129926774],
              [-35.212687459976721, -8.437395205056584],
              [-35.212527424780603, -8.437879789142041],
              [-35.212017842595884, -8.439423473175472],
              [-35.211958765303031, -8.439602460587862],
              [-35.212344473082069, -8.439719190142474],
              [-35.212687268588269, -8.439689143818471],
              [-35.213271887299335, -8.439481292267555],
              [-35.213466717224861, -8.439484169025434],
              [-35.214175220246148, -8.440003970561637],
              [-35.215183571434522, -8.44017353591493],
              [-35.215656086184573, -8.440142663249853],
              [-35.216837679112189, -8.439761319235735],
              [-35.218386697153008, -8.439896362051101],
              [-35.219744601164123, -8.439693794965857],
              [-35.221675993840108, -8.439609003017514],
              [-35.222763678402849, -8.439333387461769],
              [-35.223980546768544, -8.438773578348309],
              [-35.224399574087286, -8.438645199051944],
              [-35.225173816695076, -8.438643244635294],
              [-35.226043305843284, -8.438074284679315],
              [-35.228918994682246, -8.437204136772376],
              [-35.2270035883136, -8.432738819256469],
              [-35.225549654929971, -8.430415687194094],
              [-35.224136536420573, -8.429146920200207],
              [-35.222910661083759, -8.428046292274407],
              [-35.222420519106791, -8.427832859207181],
              [-35.220159737247172, -8.426848308130049],
              [-35.219782323814549, -8.426647610814623],
              [-35.217402211950962, -8.425381611723676],
              [-35.213889840807873, -8.423513391586958],
              [-35.213394475362314, -8.423249898402249],
              [-35.213014833230162, -8.4230233992545],
              [-35.211235586360132, -8.421962176195425],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0130000',
        uf: 'PE',
        nome_proje: 'PA CAMARAZAL',
        municipio: 'NAZARE DA MATA',
        area_hecta: '506.2463',
        capacidade: 79.0,
        num_famili: 74.0,
        fase: 6.0,
        data_de_cr: '30/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '18/08/1997',
        area_calc_: 505.7319,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.257418520491093, -7.703053400733181],
              [-35.256620865495762, -7.703003370565895],
              [-35.255643377428406, -7.703216414676573],
              [-35.25433086900469, -7.703476414997031],
              [-35.254331777437322, -7.703648149875947],
              [-35.254314324761197, -7.703774786748474],
              [-35.253898602538563, -7.705832247030672],
              [-35.253563786282641, -7.707493738253318],
              [-35.25325448947757, -7.708977751614859],
              [-35.252682895878188, -7.710553535552297],
              [-35.251589167014785, -7.71449123205553],
              [-35.250968362028587, -7.717043482372707],
              [-35.249290478935919, -7.723614593042789],
              [-35.24890901357201, -7.725162262573027],
              [-35.251222763743549, -7.725728565782681],
              [-35.254371569467352, -7.728260841591483],
              [-35.254371569947054, -7.728260931977743],
              [-35.258048194173362, -7.731206261153],
              [-35.259162240491023, -7.731100937709339],
              [-35.259704732836866, -7.730872093587317],
              [-35.26006068383608, -7.731349269771334],
              [-35.260706029045267, -7.731716445895003],
              [-35.260880509386915, -7.732149387789805],
              [-35.262048154286326, -7.731899150361206],
              [-35.263028828091684, -7.732273583423003],
              [-35.263776716776995, -7.732531382011163],
              [-35.263811214899988, -7.732543220675909],
              [-35.264862690120744, -7.732905612873569],
              [-35.265450424379509, -7.732902492826482],
              [-35.26561480144963, -7.732901620066452],
              [-35.266200405955601, -7.732991881580325],
              [-35.266861476035722, -7.733078487306136],
              [-35.266909990691651, -7.73308482791381],
              [-35.266927527527983, -7.733083135665774],
              [-35.266932890781213, -7.733081378725336],
              [-35.266942192464306, -7.733073900612279],
              [-35.266975099628148, -7.73309324967574],
              [-35.2689964092026, -7.733190971626137],
              [-35.268933007023882, -7.731501047446993],
              [-35.268834375348348, -7.730001125723583],
              [-35.268557978943107, -7.727453643510562],
              [-35.268373458560646, -7.7251406817457],
              [-35.268415002443682, -7.724435431853121],
              [-35.268347662748624, -7.722021967919466],
              [-35.268334206600017, -7.719482577691033],
              [-35.268422841693862, -7.717412213222472],
              [-35.268566472444306, -7.715459061981495],
              [-35.268488647068253, -7.713166501955393],
              [-35.268589857969069, -7.711346265947552],
              [-35.26864307603546, -7.70942974964005],
              [-35.268663174820148, -7.707657397576855],
              [-35.268663178671723, -7.707658120669369],
              [-35.268683480278717, -7.70680827161299],
              [-35.267824593462784, -7.706286309506538],
              [-35.266238086534877, -7.705320773553296],
              [-35.264699324769147, -7.704369710463618],
              [-35.263538279948655, -7.703699018159002],
              [-35.263490724302649, -7.706772215253037],
              [-35.263449395982263, -7.709379335000597],
              [-35.263423692455937, -7.711021562802678],
              [-35.263387708940265, -7.713320428552335],
              [-35.258736907865817, -7.712446907297348],
              [-35.258968850794801, -7.710050374499555],
              [-35.259055577989336, -7.707609413978703],
              [-35.259151417209729, -7.705177444322619],
              [-35.259332258050925, -7.703395827106611],
              [-35.257609169344427, -7.703087647614383],
              [-35.257418520491093, -7.703053400733181],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0318000',
        uf: 'PE',
        nome_proje: 'PA CRAUASSU',
        municipio: 'IPOJUCA',
        area_hecta: '554.1667',
        capacidade: 73.0,
        num_famili: 84.0,
        fase: 4.0,
        data_de_cr: '27/10/2005',
        forma_obte: 'Desapropriação',
        data_obten: '19/05/2004',
        area_calc_: 538.9549,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.114869153557073, -8.36774579428792],
              [-35.110411396571287, -8.369930659579731],
              [-35.109416404536972, -8.370418322465175],
              [-35.109267792220422, -8.370565909059884],
              [-35.109039665438665, -8.370803479300513],
              [-35.107142423741038, -8.372742159291869],
              [-35.105450207766317, -8.374473091655927],
              [-35.102641286582369, -8.377805500467661],
              [-35.093917720387765, -8.385057418853622],
              [-35.094750932790063, -8.3904605911851],
              [-35.094961006823617, -8.394880469020134],
              [-35.094958402106457, -8.395003327739834],
              [-35.094759166865302, -8.396424668439458],
              [-35.095019084804704, -8.396906545620585],
              [-35.095181073715104, -8.397512616945422],
              [-35.095480217074439, -8.397751438023759],
              [-35.09552809547386, -8.397876032393754],
              [-35.095730872216429, -8.398119736522336],
              [-35.09583146686181, -8.398381596344306],
              [-35.095954871400608, -8.398626213512591],
              [-35.096158832264329, -8.398832923423091],
              [-35.096160918341319, -8.398841902277692],
              [-35.096262020915816, -8.39907732594382],
              [-35.096266732950376, -8.399088298208918],
              [-35.096267122434448, -8.399095419058852],
              [-35.096279106260688, -8.399314515661448],
              [-35.096321036896228, -8.39946714887737],
              [-35.096498962241142, -8.399589909525673],
              [-35.096658700401363, -8.399579482996224],
              [-35.096824591135729, -8.399559747280534],
              [-35.096928639775328, -8.399499876645292],
              [-35.096943896720362, -8.399490382208214],
              [-35.097040598218662, -8.399380166880304],
              [-35.097130156021031, -8.399233242013405],
              [-35.097136785609408, -8.399222365775314],
              [-35.097123807671736, -8.399052292019645],
              [-35.097072653601572, -8.398717973083517],
              [-35.097040556070731, -8.398403740435963],
              [-35.097173963215639, -8.397921941008191],
              [-35.097367319999847, -8.397456679250327],
              [-35.097530311216595, -8.39728017980136],
              [-35.097536538421245, -8.397258949557047],
              [-35.0977212211425, -8.397266600807441],
              [-35.09806709212657, -8.397252404688654],
              [-35.098409242239235, -8.39713070824634],
              [-35.098574976783596, -8.397073187064027],
              [-35.098764504910044, -8.396967098243724],
              [-35.098972792270708, -8.396835440355769],
              [-35.099184630454076, -8.396654745847707],
              [-35.099414709432303, -8.396434434773038],
              [-35.099706955014391, -8.396341334998064],
              [-35.099956964157357, -8.396324018751114],
              [-35.100209452063474, -8.396318731627122],
              [-35.10042798290349, -8.396315806500299],
              [-35.100667926553875, -8.396225795755688],
              [-35.100845414511973, -8.39601589239825],
              [-35.101169412334016, -8.395916930193016],
              [-35.101521437294416, -8.395918493101133],
              [-35.101772210813195, -8.396132028473978],
              [-35.10201944372227, -8.396342955714708],
              [-35.102289485478529, -8.396344134908906],
              [-35.102543480055928, -8.396168357429373],
              [-35.102720783013659, -8.395979416182312],
              [-35.102885983586219, -8.395871865121855],
              [-35.10304352879011, -8.395770062702228],
              [-35.103321772666554, -8.395687635604922],
              [-35.103531573920833, -8.39559217712479],
              [-35.103723596485182, -8.395486401722643],
              [-35.103941682099524, -8.395358693185946],
              [-35.104151661814974, -8.39519336461766],
              [-35.104335256687577, -8.395107327805349],
              [-35.104569397596187, -8.395018009757969],
              [-35.10488171639745, -8.39493774399357],
              [-35.105096899827728, -8.394890124419044],
              [-35.105272960613043, -8.394779529760894],
              [-35.105507479771013, -8.394691938106261],
              [-35.105727133712314, -8.394631960636421],
              [-35.105961312641625, -8.394625682171595],
              [-35.106175289989345, -8.394614254853552],
              [-35.10635034183241, -8.394550776339802],
              [-35.106497729576006, -8.394542904257062],
              [-35.106604414853209, -8.394524603256496],
              [-35.106772801194666, -8.394488845110763],
              [-35.106948400534947, -8.394471283898707],
              [-35.107152758321398, -8.394424539817534],
              [-35.107254869550331, -8.394397738980505],
              [-35.10739443226845, -8.39440221465089],
              [-35.107539049166149, -8.394392883068324],
              [-35.10764531359586, -8.394338668243613],
              [-35.107754716633529, -8.394249995077001],
              [-35.107775176084601, -8.394233412309397],
              [-35.107880720452862, -8.394241755314523],
              [-35.108022446743227, -8.394252958362879],
              [-35.108203027263507, -8.394291776174057],
              [-35.108440549873883, -8.394312173974777],
              [-35.108634755627655, -8.394295347457396],
              [-35.108687077002671, -8.394290814173569],
              [-35.108758949150094, -8.394323362003252],
              [-35.10896235041492, -8.39441547362077],
              [-35.109187627873389, -8.394552744749506],
              [-35.10937100313194, -8.394639658393467],
              [-35.109570109268354, -8.394722109911195],
              [-35.109775790985474, -8.394853689892452],
              [-35.109829451610608, -8.394875871914095],
              [-35.11002208624987, -8.394990083833349],
              [-35.110167284548744, -8.395072985571463],
              [-35.110193159184732, -8.39507983542623],
              [-35.110356310754334, -8.395120425462782],
              [-35.110546281920108, -8.395118696256954],
              [-35.110641799075914, -8.395031573879418],
              [-35.110773387730902, -8.39495889340969],
              [-35.110890891944955, -8.394961704589836],
              [-35.111023765724283, -8.395044251814348],
              [-35.111164950410554, -8.395068156013192],
              [-35.111332062279651, -8.394951035239842],
              [-35.111488312435604, -8.39470862201364],
              [-35.111715632200628, -8.39444555059641],
              [-35.11191560772248, -8.394112831942241],
              [-35.112197607110318, -8.393710368209366],
              [-35.112319605287176, -8.393387609755365],
              [-35.112387285378652, -8.393111602447183],
              [-35.112543951548666, -8.392914201809136],
              [-35.112689606348418, -8.392745195163673],
              [-35.112840055282248, -8.392589046472454],
              [-35.112924176778812, -8.39246389293451],
              [-35.113002554960218, -8.392345485597096],
              [-35.113034256627074, -8.392193039826648],
              [-35.113049598280796, -8.392076925328569],
              [-35.113128055170407, -8.39194223034335],
              [-35.113240409710585, -8.391841734584675],
              [-35.11336847225877, -8.391806415308384],
              [-35.113500208639906, -8.391727699424962],
              [-35.113633435181391, -8.391623943958992],
              [-35.113815362585498, -8.391467149813321],
              [-35.114004937594352, -8.391294894140254],
              [-35.114156562608677, -8.391176265953684],
              [-35.114318114952518, -8.391086003758026],
              [-35.114532944991865, -8.390911334096973],
              [-35.114669180292026, -8.39076169289436],
              [-35.114790883273891, -8.390632588100578],
              [-35.114954464274234, -8.390453259310391],
              [-35.115166365958956, -8.390314518839771],
              [-35.11532862459147, -8.390278077280946],
              [-35.115524062648021, -8.390221424876652],
              [-35.115656063701223, -8.390140515450506],
              [-35.115747038952996, -8.389980853433295],
              [-35.115771939341592, -8.389861304778444],
              [-35.115829352411033, -8.389751112064351],
              [-35.115847817235839, -8.389665477998753],
              [-35.115872393299838, -8.38955150187639],
              [-35.115872581376024, -8.389413781603912],
              [-35.115827858524284, -8.389332288962819],
              [-35.115883039360163, -8.389252550675689],
              [-35.115904702185603, -8.389221247101284],
              [-35.115964712321805, -8.389239009208277],
              [-35.116034229205383, -8.389259585165499],
              [-35.116110710917219, -8.389200974313901],
              [-35.116238200221886, -8.389144506187105],
              [-35.116369593801807, -8.389115349825104],
              [-35.116513123211931, -8.389070986653259],
              [-35.116560752290582, -8.389056265074352],
              [-35.11660944794113, -8.38905834135273],
              [-35.116724447546602, -8.389063244650295],
              [-35.116860632177151, -8.389044336221865],
              [-35.1169681482464, -8.38901969052818],
              [-35.117082498421517, -8.389016793986714],
              [-35.117198117033382, -8.389044480440745],
              [-35.117325478579055, -8.389108031066961],
              [-35.117444617933394, -8.389086941954757],
              [-35.117552877684609, -8.389053863670116],
              [-35.117736242800206, -8.389043160823489],
              [-35.117952333975239, -8.388923292276441],
              [-35.118146183267235, -8.388757246747238],
              [-35.11825449327474, -8.388614745573198],
              [-35.118371178159826, -8.388387717642383],
              [-35.118438055082557, -8.388232657774598],
              [-35.118473591074704, -8.388021958907762],
              [-35.118501900143656, -8.387815788588732],
              [-35.118509400950984, -8.387717685944919],
              [-35.118528816825858, -8.387633231390499],
              [-35.118594873173144, -8.38753911662301],
              [-35.118607168058624, -8.387357850252968],
              [-35.118607603921809, -8.387231953073547],
              [-35.118647468683804, -8.387040361788516],
              [-35.118726903122358, -8.386800806840828],
              [-35.118768792734656, -8.38655138318196],
              [-35.118764573625185, -8.386355583368447],
              [-35.118720521514987, -8.386181620401526],
              [-35.118661238854749, -8.386017556388175],
              [-35.118660204341154, -8.386014693385599],
              [-35.118662836551302, -8.385997581015006],
              [-35.118702735958792, -8.385738189115848],
              [-35.118709635921171, -8.385465670290611],
              [-35.118749782637948, -8.385234771461278],
              [-35.118715991954879, -8.385073859363047],
              [-35.118648532811143, -8.384905330425081],
              [-35.118493421486797, -8.38471297152082],
              [-35.118356820001161, -8.384521686450421],
              [-35.118208467281626, -8.384280721090924],
              [-35.118021810374515, -8.384039439479674],
              [-35.117889807475876, -8.383781416152658],
              [-35.117747958225529, -8.383509044907921],
              [-35.11766606417217, -8.383278405112558],
              [-35.117575117291992, -8.383081011689224],
              [-35.117481605683608, -8.382893600697759],
              [-35.117422643649817, -8.382723554344299],
              [-35.117338015741986, -8.382521639718385],
              [-35.117282577023879, -8.382421343094231],
              [-35.117179473139906, -8.382248068360356],
              [-35.117131033161506, -8.382006908036443],
              [-35.117088400889564, -8.381767221336133],
              [-35.117016466090689, -8.381621934080625],
              [-35.11694663080128, -8.381713420677473],
              [-35.116924254930872, -8.381857211877103],
              [-35.116748574466378, -8.382007299546217],
              [-35.116600922991935, -8.382163310581333],
              [-35.116495331696157, -8.382232275234704],
              [-35.116216736975552, -8.382301540193309],
              [-35.116054548640825, -8.382218191896314],
              [-35.115915819233336, -8.382133850956123],
              [-35.115836207773462, -8.381969673527477],
              [-35.11578109514538, -8.381765623987629],
              [-35.115822221646908, -8.381442586530191],
              [-35.115872170722355, -8.38118430905662],
              [-35.115972259118848, -8.380807491149177],
              [-35.116016365968143, -8.380548561226556],
              [-35.116050906477689, -8.380332597227207],
              [-35.116127186641592, -8.380018304093547],
              [-35.116153139362332, -8.379823466083099],
              [-35.116152102592658, -8.379582439308329],
              [-35.116153379681784, -8.379256197806233],
              [-35.116159945471821, -8.37905011760269],
              [-35.116106027667904, -8.378923633629853],
              [-35.116070309276367, -8.378718343595896],
              [-35.116058964056485, -8.378518209882575],
              [-35.116039555060311, -8.378287413662097],
              [-35.116053353289367, -8.378088014665897],
              [-35.116110622561216, -8.377909542913399],
              [-35.116115679884295, -8.377739179705905],
              [-35.116154950023855, -8.377605219850247],
              [-35.116272988777389, -8.377518422822657],
              [-35.116349461895695, -8.377498468279589],
              [-35.11638263208684, -8.377489812999768],
              [-35.116434075127337, -8.377485293586307],
              [-35.11656890267647, -8.377473448565953],
              [-35.116746134921883, -8.377450236197193],
              [-35.116878629663582, -8.377427263685213],
              [-35.116960146819679, -8.377413154732638],
              [-35.116961873982376, -8.377274833484037],
              [-35.114869153557073, -8.36774579428792],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0116000',
        uf: 'PE',
        nome_proje: 'PA CRIMÉIA',
        municipio: 'ESCADA',
        area_hecta: '338.0000',
        capacidade: 43.0,
        num_famili: 41.0,
        fase: 5.0,
        data_de_cr: '22/10/1997',
        forma_obte: 'Desapropriação',
        data_obten: '26/09/1996',
        area_calc_: 323.0943,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.229938061897116, -8.327839804349578],
              [-35.226260877636122, -8.327878339002012],
              [-35.222823079616461, -8.328139934932612],
              [-35.214977371194166, -8.331839763779891],
              [-35.215490303570114, -8.335529120875741],
              [-35.216753410571577, -8.335878558613285],
              [-35.217278872089196, -8.335956790925511],
              [-35.218183562370385, -8.336028201520605],
              [-35.21836693907089, -8.336938478628213],
              [-35.218460170725315, -8.336959108148873],
              [-35.218571816533029, -8.336994187261991],
              [-35.218618707699761, -8.336988953538176],
              [-35.21870439009701, -8.336942285376265],
              [-35.218804702575085, -8.336834522743803],
              [-35.218795030286017, -8.336793088277592],
              [-35.218848006452433, -8.336772544736347],
              [-35.218918769637497, -8.336735722038116],
              [-35.218971662162787, -8.336716444401713],
              [-35.219034822492056, -8.336667190535737],
              [-35.219062124781971, -8.336664506873371],
              [-35.21917374451693, -8.336743243879519],
              [-35.219277901587638, -8.336705691932885],
              [-35.219422988095879, -8.336667910903689],
              [-35.219469244530998, -8.336759306506853],
              [-35.219550676441024, -8.336924443436411],
              [-35.219602544838374, -8.337013186309933],
              [-35.219661882525017, -8.337106768372443],
              [-35.219764441328238, -8.337348888776125],
              [-35.219800072858234, -8.337487346040826],
              [-35.219831671415783, -8.337586597051024],
              [-35.219837180094984, -8.337694309887841],
              [-35.21981794504859, -8.337872122317298],
              [-35.219762547923757, -8.338026545669297],
              [-35.219689957012484, -8.338125476030692],
              [-35.219552737229428, -8.338254686916054],
              [-35.219482954874678, -8.3383045203101],
              [-35.21934850399834, -8.338458030307587],
              [-35.219289988888377, -8.33859068734994],
              [-35.219208238502667, -8.338787379481852],
              [-35.219100004403764, -8.338971023042564],
              [-35.218957587920514, -8.33920872977766],
              [-35.218966488541277, -8.339322479754227],
              [-35.21898099651294, -8.339432763566847],
              [-35.218960004176047, -8.339572622507958],
              [-35.218885075612405, -8.33962727530168],
              [-35.218851680959119, -8.339660092641441],
              [-35.218862291630373, -8.339771302161635],
              [-35.218940685561748, -8.339928682657073],
              [-35.218906454277864, -8.339990158016937],
              [-35.218878238166972, -8.340023940663313],
              [-35.218886914148683, -8.340210455077624],
              [-35.218920122496826, -8.340305448858201],
              [-35.218893252572933, -8.340433047793852],
              [-35.218881960816248, -8.340635401657519],
              [-35.218846948957179, -8.340719388272531],
              [-35.21867947629832, -8.34097196875199],
              [-35.218533977595492, -8.341226505185736],
              [-35.21823084770508, -8.341708619581228],
              [-35.218069043381909, -8.341952581316626],
              [-35.217920982930224, -8.342090982065345],
              [-35.218132419306578, -8.343469407200779],
              [-35.218356775687461, -8.343454141020727],
              [-35.219948099194134, -8.344769608663348],
              [-35.221925018487731, -8.345627977589437],
              [-35.222197134710427, -8.345792947559611],
              [-35.222668445430408, -8.346227423804288],
              [-35.223683648099765, -8.347299883273458],
              [-35.225412545068131, -8.34578701072498],
              [-35.225624574588537, -8.345277835600587],
              [-35.226079406930872, -8.343075041101534],
              [-35.226169402830166, -8.342283635349022],
              [-35.226534780131942, -8.342121956645615],
              [-35.227251602503749, -8.341358032643436],
              [-35.227282976300465, -8.341513324627629],
              [-35.22733733776559, -8.341689276791861],
              [-35.227353370073757, -8.341795845163295],
              [-35.22740419344268, -8.341875914955898],
              [-35.227463374454771, -8.341941385206987],
              [-35.227622400839948, -8.341897104965236],
              [-35.227701034763086, -8.34198406869368],
              [-35.227775698521441, -8.342091120968607],
              [-35.227739630962667, -8.342260711753163],
              [-35.227724557064001, -8.342451425840396],
              [-35.227870433951665, -8.342521018486185],
              [-35.227980727382167, -8.342477283155194],
              [-35.228053467189909, -8.342453011670679],
              [-35.228201388480301, -8.342611083001861],
              [-35.228022118274232, -8.342654483069063],
              [-35.228027548359805, -8.342811909288141],
              [-35.228178406322108, -8.342831579322098],
              [-35.228393145571758, -8.342721182656252],
              [-35.228506847936515, -8.342862543575608],
              [-35.228614386111175, -8.342909121617341],
              [-35.228750038818404, -8.343000193413358],
              [-35.228598355159157, -8.343187065871087],
              [-35.228713481210335, -8.34329135940526],
              [-35.228822674385654, -8.343437716887811],
              [-35.22887137019999, -8.343638647649501],
              [-35.228914310123876, -8.343848830390959],
              [-35.229066822882274, -8.343984187839153],
              [-35.229190313013, -8.344090964966675],
              [-35.229305702521103, -8.344209628495811],
              [-35.229414395845815, -8.34420377443468],
              [-35.22960299253063, -8.344412504716189],
              [-35.229524085908245, -8.344437624745657],
              [-35.229460399776237, -8.344426051823669],
              [-35.229391097663694, -8.34446440488192],
              [-35.229495407690528, -8.344613772311474],
              [-35.229569923314649, -8.344662524293906],
              [-35.229737562768136, -8.344776554953151],
              [-35.22982747538861, -8.344867702764276],
              [-35.229967089482123, -8.345000962953211],
              [-35.23007801560076, -8.345100669688566],
              [-35.230190355963117, -8.34522567714585],
              [-35.230259613413239, -8.345371354854064],
              [-35.23053140820037, -8.345159581497095],
              [-35.230690982960098, -8.34503593574472],
              [-35.230946223867463, -8.344881832768667],
              [-35.231259625723247, -8.344677327042126],
              [-35.231463413794849, -8.344508418749015],
              [-35.231712021598518, -8.344321089918161],
              [-35.232088013875781, -8.344127168197154],
              [-35.23233698255666, -8.343939565912704],
              [-35.232583114132417, -8.343763820362883],
              [-35.232789761663703, -8.343667115710204],
              [-35.233086287497976, -8.343415883023942],
              [-35.233962287285834, -8.343419442152333],
              [-35.234025042012028, -8.343235238922599],
              [-35.234209282445029, -8.343140377377253],
              [-35.235050084042001, -8.342827593661987],
              [-35.235161452916813, -8.342781681142876],
              [-35.235201569265762, -8.342734181928909],
              [-35.235323120957872, -8.342579746271126],
              [-35.23546881000351, -8.342440630812202],
              [-35.235751061477146, -8.342217316822245],
              [-35.235980930682629, -8.342023132016752],
              [-35.236140271298247, -8.341858901921443],
              [-35.236244145355464, -8.341723999298361],
              [-35.23638972474555, -8.341565722023025],
              [-35.236595158906148, -8.341415693841675],
              [-35.2367825727151, -8.341304634118323],
              [-35.23694634216524, -8.341233026529995],
              [-35.23714228915528, -8.341186184350882],
              [-35.237333706432054, -8.341156722150149],
              [-35.237688025507723, -8.341094614105211],
              [-35.237919734711312, -8.341064562631232],
              [-35.238044017138861, -8.341071272737748],
              [-35.235771888910051, -8.340852611215041],
              [-35.235304626911891, -8.340204813911217],
              [-35.235084452542822, -8.339837271969801],
              [-35.234794695453346, -8.338931318577266],
              [-35.234379535902448, -8.337261839601688],
              [-35.234314702235082, -8.336632561137339],
              [-35.234079395507365, -8.336268537829961],
              [-35.234002926018533, -8.334820498308547],
              [-35.234044598544756, -8.334120026523033],
              [-35.233759779174015, -8.333074935226453],
              [-35.233128851622908, -8.33155417986544],
              [-35.232710380295821, -8.330448916918833],
              [-35.232107474822307, -8.329787140772021],
              [-35.231276118893078, -8.329024508561151],
              [-35.230999348049423, -8.328820882652821],
              [-35.230535328861649, -8.328468625485847],
              [-35.230246135123622, -8.328218608996755],
              [-35.230110104325234, -8.328059747247062],
              [-35.230035458210374, -8.327939226859632],
              [-35.229938061897116, -8.327839804349578],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0289000',
        uf: 'PE',
        nome_proje: 'PA DONA ISABEL',
        municipio: 'CARUARU',
        area_hecta: '882.7156',
        capacidade: 42.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '25/02/2003',
        forma_obte: 'Desapropriação',
        data_obten: '06/12/2001',
        area_calc_: 877.8629,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999327687057587, -8.046518050705707],
              [-35.998624951368946, -8.047182856533068],
              [-35.998529455636074, -8.04771319582154],
              [-35.998122376917088, -8.04864188853659],
              [-35.997707875009986, -8.049302243324993],
              [-35.997568634731088, -8.049978576326412],
              [-35.997437090597529, -8.050163146247565],
              [-35.997018776799521, -8.050504835729461],
              [-35.996137924475534, -8.05114033047335],
              [-35.995492601905831, -8.051506718265562],
              [-35.995411895585363, -8.051549117850728],
              [-35.995986788571706, -8.052376452651432],
              [-35.996412821733728, -8.05276654526449],
              [-35.997414765402645, -8.053424262839789],
              [-35.998046152095164, -8.05405144774649],
              [-35.999217414211202, -8.054816465404681],
              [-35.999608322659029, -8.055514695991116],
              [-35.998986543030604, -8.0564019326834],
              [-35.997904726207771, -8.057731619814627],
              [-35.997747385915488, -8.058025265321044],
              [-35.997514952751352, -8.058786488489837],
              [-35.997574392275105, -8.059112246276992],
              [-35.997112472938433, -8.061160182905864],
              [-35.99691348618007, -8.061174461388394],
              [-35.995399397336172, -8.061312790976128],
              [-35.99490939894639, -8.061381597306941],
              [-35.993954226072745, -8.061430310236771],
              [-35.991014305796156, -8.060990077591885],
              [-35.990394079535804, -8.063178890225744],
              [-35.990388847365637, -8.063981318993562],
              [-35.990230460217916, -8.064774472734742],
              [-35.990010954531058, -8.065355837803679],
              [-35.989680345905356, -8.065762144553347],
              [-35.990332012158703, -8.0667933039131],
              [-35.990421692928763, -8.067511791672157],
              [-35.990402475801908, -8.068369202405487],
              [-35.990602115518662, -8.069014762442862],
              [-35.991042089455156, -8.06984532044469],
              [-35.991098151059113, -8.070386514692791],
              [-35.991196500492308, -8.071576242314579],
              [-35.990961714367337, -8.072832613905719],
              [-35.990974375334787, -8.073873892654339],
              [-35.991205041194704, -8.074711128013298],
              [-35.991185262582846, -8.074916970510914],
              [-35.991018939339504, -8.07523674799091],
              [-35.990961027478924, -8.07655233438232],
              [-35.991193768380043, -8.076531194882005],
              [-35.991295315584885, -8.076777970285226],
              [-35.99113346928678, -8.077337835174582],
              [-35.990370784124465, -8.078498826764344],
              [-35.990045224379209, -8.078655921545522],
              [-35.988900460053216, -8.078460471000541],
              [-35.988763753768787, -8.078333857158043],
              [-35.98811443290429, -8.077499713604483],
              [-35.987861986634719, -8.077338611418909],
              [-35.987432351369868, -8.077369438660686],
              [-35.986702025869882, -8.077280919706784],
              [-35.986176515722164, -8.079338994688658],
              [-35.985763680386832, -8.079715075892693],
              [-35.985111956919845, -8.080168486418474],
              [-35.985190009806857, -8.082496021250364],
              [-35.985359446153439, -8.08491045469408],
              [-35.985516420382119, -8.087278357439878],
              [-35.98559865801051, -8.088480040430865],
              [-35.985672980882008, -8.090071924595147],
              [-35.985774620732634, -8.091579729160802],
              [-35.985926711548835, -8.093942980151022],
              [-35.986036451689223, -8.095242168877659],
              [-35.98813944453277, -8.096973464553562],
              [-35.990416886258181, -8.09885177715727],
              [-35.992331414057972, -8.100554113718998],
              [-35.994973864979734, -8.103095783034808],
              [-35.995788271151035, -8.103882161932638],
              [-35.99556691612365, -8.100467864748612],
              [-35.995602271472848, -8.09981724774933],
              [-35.995603671972276, -8.099726147970758],
              [-35.995493751727849, -8.098998970071207],
              [-35.995490765966906, -8.098592559398009],
              [-35.995524021378309, -8.098105552579215],
              [-35.99532100755372, -8.096073232741876],
              [-35.995652431959101, -8.09435565823321],
              [-35.996430422260737, -8.089522607896507],
              [-35.997068362651682, -8.088556463516596],
              [-35.997645312224847, -8.087731121389879],
              [-35.997154609333037, -8.087438793323724],
              [-35.996701135037242, -8.086919472643689],
              [-35.996458391017121, -8.086761378211415],
              [-35.99713384773851, -8.08631016922465],
              [-35.997231479654559, -8.085706298711886],
              [-35.998341249711807, -8.084973569984674],
              [-35.998747571537983, -8.08569078642028],
              [-35.999526675063763, -8.084844569254861],
              [-35.999791321135149, -8.084669856102986],
              [-36.001992220072317, -8.083950597357186],
              [-36.00248159305265, -8.083290009525568],
              [-36.003984220690491, -8.083998854216091],
              [-36.004361835092624, -8.084069415156259],
              [-36.0044363285553, -8.085227437301484],
              [-36.005473439665067, -8.084942722328346],
              [-36.005987693414816, -8.084479385090583],
              [-36.0064583203038, -8.083814854236147],
              [-36.006568419025228, -8.083667661286338],
              [-36.006929962105019, -8.08381361679635],
              [-36.007220512016183, -8.084096365789975],
              [-36.007326544844943, -8.084581983887608],
              [-36.009564847090402, -8.083323656592459],
              [-36.010530682457848, -8.082909097595127],
              [-36.010908462606693, -8.082697244883628],
              [-36.011207646997427, -8.082532028551181],
              [-36.011299548954952, -8.082781803830073],
              [-36.011695664798964, -8.08270739824802],
              [-36.011877617597243, -8.082720443127091],
              [-36.01289230551756, -8.08249758593958],
              [-36.012013297381358, -8.080498567455093],
              [-36.01149016385407, -8.078781080797246],
              [-36.011111593881893, -8.078679656029646],
              [-36.010444945482142, -8.078899440895414],
              [-36.010151437868053, -8.078706620741102],
              [-36.009443651411907, -8.078523651924357],
              [-36.009761091969033, -8.077221036066311],
              [-36.009788545061205, -8.074226350087157],
              [-36.009312135202336, -8.072687129696911],
              [-36.009486431518262, -8.071988188571101],
              [-36.009846833913535, -8.071432761672911],
              [-36.010460247247316, -8.071111812986739],
              [-36.011585964531442, -8.070777853710149],
              [-36.011738013213325, -8.070750945383031],
              [-36.0117147669947, -8.069630176748333],
              [-36.011708888532731, -8.069422873261113],
              [-36.011520257825666, -8.069192222621776],
              [-36.011376565529801, -8.068965637943142],
              [-36.011023006696682, -8.069008364481199],
              [-36.011031653873609, -8.068772542396767],
              [-36.011587276211706, -8.068018374414848],
              [-36.012045428525695, -8.067554308786475],
              [-36.012588757567997, -8.06715993645266],
              [-36.012792719291348, -8.067149654308837],
              [-36.012840096591383, -8.06683436396329],
              [-36.012895102450038, -8.066641086716222],
              [-36.012823567634229, -8.065604126162819],
              [-36.012709305684744, -8.065627474890666],
              [-36.012501707731282, -8.065613834464619],
              [-36.012099462484031, -8.065464315715136],
              [-36.011826332734891, -8.065299932963358],
              [-36.011690450317005, -8.065054728822467],
              [-36.011434354394034, -8.064822524479677],
              [-36.011471676330075, -8.06385784140774],
              [-36.011435169845221, -8.06358080882165],
              [-36.011303572006916, -8.062991290972905],
              [-36.010939818873773, -8.062322153479119],
              [-36.010523469778825, -8.061987072035301],
              [-36.010442917017158, -8.061892987921945],
              [-36.010195668872676, -8.061414808650152],
              [-36.010033341365379, -8.061071417630842],
              [-36.008949647887277, -8.061308696672683],
              [-36.008265485515643, -8.061456684788471],
              [-36.007331232801072, -8.061552972256889],
              [-36.007029444665129, -8.061541227025588],
              [-36.005771331564155, -8.06109294287784],
              [-36.00516889232788, -8.060006910108539],
              [-36.003827553430924, -8.058356864298945],
              [-36.003303853598844, -8.056946226710169],
              [-36.002053664094483, -8.055530719706203],
              [-36.001855525813873, -8.055236864450478],
              [-36.001653427915386, -8.054642810769577],
              [-36.001463608846294, -8.053300601304947],
              [-36.001429331414641, -8.053199495005199],
              [-36.001260735626055, -8.053020853213312],
              [-36.001095117660121, -8.052624314642816],
              [-36.001013551875268, -8.052341230820977],
              [-36.00101520337828, -8.052060401487283],
              [-36.001120387979519, -8.050916402906068],
              [-36.001081628099811, -8.050336518978876],
              [-36.001061229186348, -8.049823175790753],
              [-36.000426591495305, -8.04893710189995],
              [-35.999957752835719, -8.048073400245249],
              [-35.999327687057587, -8.046518050705707],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0085000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO SANTO ANTONIO',
        municipio: 'CAMUTANGA',
        area_hecta: '577.1900',
        capacidade: 69.0,
        num_famili: 62.0,
        fase: 5.0,
        data_de_cr: '02/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 587.9527,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.314231121186971, -7.397890580475348],
              [-35.314146607882364, -7.397901323531275],
              [-35.314078436473736, -7.397937832291193],
              [-35.313889741739267, -7.398042395487717],
              [-35.313790327695166, -7.398095426466186],
              [-35.313656905432588, -7.398207656580944],
              [-35.313525714790501, -7.398365881933276],
              [-35.31340185641055, -7.398558506545728],
              [-35.313340602375412, -7.398671446512802],
              [-35.31321878856177, -7.398943691235194],
              [-35.313146813982762, -7.399136588768748],
              [-35.313081762565488, -7.399267987354214],
              [-35.313039004277009, -7.399298760096097],
              [-35.312920888960889, -7.399342487881142],
              [-35.31280187122448, -7.399421561561005],
              [-35.312727662929419, -7.399498594837329],
              [-35.312674002763302, -7.399574527170867],
              [-35.312580550142407, -7.399659614392228],
              [-35.312366877533876, -7.399818990960124],
              [-35.312177673084562, -7.399930245054319],
              [-35.312089340616815, -7.400024886645091],
              [-35.31202359583321, -7.4001796989899],
              [-35.311654948222042, -7.400538007753213],
              [-35.311530970714593, -7.400621084010834],
              [-35.31123314745377, -7.40066990194985],
              [-35.31115434111031, -7.400663893427748],
              [-35.310812721775306, -7.400596429658731],
              [-35.310642990545098, -7.400569832631922],
              [-35.310557717763189, -7.400573980876765],
              [-35.310291737450811, -7.400686807732786],
              [-35.310166210843136, -7.400733285028604],
              [-35.310090870293251, -7.400749493528967],
              [-35.309985250032476, -7.40075853772404],
              [-35.309869724039579, -7.400743228793767],
              [-35.309756865890371, -7.400718596136668],
              [-35.309656692342294, -7.400695524630166],
              [-35.309412331146127, -7.400612912657216],
              [-35.309052908074143, -7.400482359238432],
              [-35.308934250745914, -7.400439498233244],
              [-35.308558800593985, -7.400343645640326],
              [-35.308162225506329, -7.400277277950805],
              [-35.307551038151537, -7.400190057725522],
              [-35.307298830750092, -7.400200493369064],
              [-35.307026837100089, -7.400236701313727],
              [-35.306878387457687, -7.400228160329258],
              [-35.306533317340673, -7.400193611766105],
              [-35.30602581076581, -7.400090034619457],
              [-35.305678471314089, -7.400036967716243],
              [-35.305442429600973, -7.400021919807533],
              [-35.305091958328511, -7.400028343419506],
              [-35.304739833307281, -7.400065055053029],
              [-35.304458858800146, -7.400115589123771],
              [-35.304317252350124, -7.400483384572958],
              [-35.304250012017228, -7.400542574583746],
              [-35.303977755592484, -7.40063301454636],
              [-35.303989747396692, -7.400762748704457],
              [-35.304034823679302, -7.401073628652943],
              [-35.304064011160925, -7.401341295268083],
              [-35.304096496924622, -7.4016861355578],
              [-35.304127149631015, -7.40190462385286],
              [-35.30416094156778, -7.40218709010357],
              [-35.30418034514264, -7.402331699771027],
              [-35.304234694050628, -7.402597247576715],
              [-35.30424774702616, -7.402774339131056],
              [-35.304272286560412, -7.402966375523149],
              [-35.304297414238853, -7.40323677465009],
              [-35.304320506104588, -7.403428999288663],
              [-35.304399439947346, -7.403930059395213],
              [-35.30446789495457, -7.404348650084823],
              [-35.304486756078163, -7.404580486197442],
              [-35.304487603490323, -7.404708741357046],
              [-35.304488322503516, -7.404864565294476],
              [-35.304423053784909, -7.405355615722557],
              [-35.304398800163931, -7.405584692264107],
              [-35.304365487994126, -7.405847891717012],
              [-35.304304479354585, -7.406200356434474],
              [-35.304218727725228, -7.406687716717969],
              [-35.304180139798696, -7.406911444276593],
              [-35.304131034527771, -7.407219648024597],
              [-35.304117099060811, -7.407395613842003],
              [-35.304094829658922, -7.407570899722075],
              [-35.30408696963427, -7.407818240044267],
              [-35.304050735485738, -7.408128998404423],
              [-35.304012227665815, -7.408333292310616],
              [-35.30390675116314, -7.408753777878197],
              [-35.303797712904682, -7.409116162890435],
              [-35.304135600796769, -7.409265630957314],
              [-35.304300678190842, -7.409354259348992],
              [-35.305033299535559, -7.409840995451573],
              [-35.305400379298753, -7.41004933383187],
              [-35.305636330029905, -7.410167693168503],
              [-35.30586623638608, -7.410290331861733],
              [-35.306032813672573, -7.410371268701007],
              [-35.306202042022328, -7.410439627887023],
              [-35.306379238437181, -7.410507945692531],
              [-35.306624333269859, -7.410591186722905],
              [-35.306810040842791, -7.410642015458195],
              [-35.30707382419029, -7.410714674364751],
              [-35.307424106957228, -7.410809661915593],
              [-35.307598544761831, -7.410835059576293],
              [-35.307673850242587, -7.410829155259609],
              [-35.307748092404211, -7.410810331106906],
              [-35.307801694810301, -7.410792517897992],
              [-35.307813174947299, -7.410823551479254],
              [-35.30784306261301, -7.410824842597979],
              [-35.307932909069891, -7.410829166913759],
              [-35.308023849599692, -7.410834931727946],
              [-35.308526403783198, -7.411028282672392],
              [-35.308787715164499, -7.411113065087575],
              [-35.309050157070367, -7.411188893144958],
              [-35.309331226999376, -7.411329431744455],
              [-35.30946236655619, -7.411421216643826],
              [-35.309527811498718, -7.411486588108664],
              [-35.309686003620264, -7.411661117239967],
              [-35.309990140033939, -7.412005538724351],
              [-35.310141954488252, -7.412120354972043],
              [-35.310225983386722, -7.412155078955462],
              [-35.310763791097628, -7.412336493171167],
              [-35.310956443860817, -7.412486166920399],
              [-35.311067899144625, -7.412623156463757],
              [-35.31127089447974, -7.412915497056946],
              [-35.311360839604575, -7.413077545037286],
              [-35.311485589854932, -7.413315608124663],
              [-35.311789331331035, -7.413774730481756],
              [-35.311940765405438, -7.414007321725709],
              [-35.312182936777923, -7.414397437054306],
              [-35.31236728604015, -7.414690777503914],
              [-35.312500617675376, -7.414924637560307],
              [-35.312613540709989, -7.415169088559319],
              [-35.312724401971572, -7.415625597492743],
              [-35.312813143422112, -7.415886353396301],
              [-35.312888779681273, -7.416221113771916],
              [-35.312905629244192, -7.416291889276028],
              [-35.312987748734812, -7.416342259259791],
              [-35.313142670314015, -7.416427410762448],
              [-35.313236734349566, -7.416458918091149],
              [-35.313217296653612, -7.416482067869231],
              [-35.313485552049364, -7.4166300807853],
              [-35.313607775092812, -7.416713232994945],
              [-35.313841822814041, -7.416847684920395],
              [-35.314011114080444, -7.416910164543179],
              [-35.314190427099277, -7.416949724081165],
              [-35.314347920734846, -7.416989849091205],
              [-35.314621743141863, -7.417059466940789],
              [-35.314870676107347, -7.417131293120163],
              [-35.315055343438907, -7.417190438259321],
              [-35.315324625104985, -7.417274812251582],
              [-35.315605619560834, -7.417330653271371],
              [-35.315994995535782, -7.417454623009437],
              [-35.316290366175465, -7.417541029628847],
              [-35.316601496054787, -7.417680049177906],
              [-35.316841111840333, -7.417770903777174],
              [-35.316998984211551, -7.417796835038112],
              [-35.317288386032772, -7.417850009773042],
              [-35.317630923088267, -7.417951806225505],
              [-35.317977029397326, -7.418060814649136],
              [-35.318145880840248, -7.418125644709316],
              [-35.318407490807665, -7.418214666207967],
              [-35.31866918996429, -7.418303415896641],
              [-35.318903727748413, -7.418427377050037],
              [-35.319161529876673, -7.418498159835782],
              [-35.319435071170844, -7.418548523370638],
              [-35.319964019697544, -7.418575498975589],
              [-35.320473428900122, -7.418623455199548],
              [-35.320724326402235, -7.418672580336766],
              [-35.32100386600208, -7.418744423397535],
              [-35.321354179920249, -7.418826833229246],
              [-35.321883568558917, -7.418955036597271],
              [-35.322242153142945, -7.419008749991335],
              [-35.32286652527349, -7.419101928084657],
              [-35.323398908604425, -7.419162324207179],
              [-35.323755119496887, -7.419212342864284],
              [-35.324122279628703, -7.419209247343735],
              [-35.32421075024223, -7.419210049935518],
              [-35.324449110854559, -7.419199583566367],
              [-35.324831760522791, -7.419214845158232],
              [-35.325178659444667, -7.419233185870209],
              [-35.3255492405073, -7.419226365437391],
              [-35.325818088599625, -7.419176240115912],
              [-35.326149022580445, -7.41903413831553],
              [-35.326327008530228, -7.418958909235096],
              [-35.326845046091549, -7.4187536418778],
              [-35.327360213839697, -7.418536548545115],
              [-35.32750570928215, -7.41849899940304],
              [-35.327801338509751, -7.418479463958481],
              [-35.328165889662834, -7.418445467023746],
              [-35.328440075902044, -7.41842920702766],
              [-35.328732618709481, -7.418442677948343],
              [-35.329006490243003, -7.418469894783114],
              [-35.329252714292366, -7.418491381302021],
              [-35.329577614281177, -7.418510828244452],
              [-35.329866972448812, -7.41853859602397],
              [-35.330168064150278, -7.418541988329427],
              [-35.330379985262681, -7.418531928731268],
              [-35.331149935328114, -7.418466246918109],
              [-35.331434200533678, -7.418455445737036],
              [-35.331833532827247, -7.41844087703261],
              [-35.332383828339012, -7.418412771222428],
              [-35.33263759309046, -7.418404750565004],
              [-35.332727168052116, -7.417721598824848],
              [-35.332809526567829, -7.417473329518821],
              [-35.332872527847719, -7.417331997455259],
              [-35.332995489203263, -7.41698770643118],
              [-35.333038886588774, -7.416786190192872],
              [-35.333384118140216, -7.416059125001744],
              [-35.333466529163218, -7.415803715038617],
              [-35.333476314173694, -7.415701708893172],
              [-35.333521703581084, -7.415568965566871],
              [-35.333433643901373, -7.415388114655337],
              [-35.333431013976636, -7.414923276144411],
              [-35.333483110178676, -7.414533893375252],
              [-35.333514674142556, -7.41433777206185],
              [-35.333577233315154, -7.413992261717293],
              [-35.333607189194545, -7.413834653059721],
              [-35.33363278273351, -7.413673994195521],
              [-35.333701248883763, -7.413298173758391],
              [-35.333819332969263, -7.412836045993803],
              [-35.334015518891214, -7.412160018005231],
              [-35.334083872257786, -7.411900343501256],
              [-35.33418472017096, -7.411707202896847],
              [-35.33427380794955, -7.411567722554029],
              [-35.334480151123337, -7.411204284452753],
              [-35.334613289889539, -7.410970300941722],
              [-35.334888380080564, -7.410783024582631],
              [-35.334984008381447, -7.410578884359929],
              [-35.335073173700266, -7.410144837725451],
              [-35.335160326693412, -7.409999130949181],
              [-35.335362543400954, -7.409591696868153],
              [-35.335419734060153, -7.409396073919595],
              [-35.335598316508246, -7.409005575616147],
              [-35.335856507209193, -7.408446723471831],
              [-35.336007470220558, -7.40812452074849],
              [-35.336118689907146, -7.407889477341892],
              [-35.336325179474017, -7.407451199542415],
              [-35.336384692203957, -7.407301118658673],
              [-35.336462639299988, -7.407075288513638],
              [-35.336557405836395, -7.406879649127917],
              [-35.336711465403965, -7.406475089227046],
              [-35.336711372807841, -7.406371598581856],
              [-35.336692095389147, -7.406184240761696],
              [-35.336703051160917, -7.406029534042266],
              [-35.336722770689796, -7.405836819507293],
              [-35.336710903937067, -7.405681148000685],
              [-35.336678138115872, -7.405494312898339],
              [-35.336580763641074, -7.405126142547643],
              [-35.336537370758215, -7.404915772634909],
              [-35.336486862063815, -7.40464370690289],
              [-35.336486862063815, -7.40464370690289],
              [-35.336461476390816, -7.404431163560434],
              [-35.336429630068231, -7.404264117927466],
              [-35.336405094517232, -7.404161388240406],
              [-35.336352790338161, -7.403840614180663],
              [-35.336328861413065, -7.403749902532857],
              [-35.336286539567148, -7.40357089058023],
              [-35.336242893431162, -7.403398302923574],
              [-35.33622314810512, -7.403311094328256],
              [-35.33623088501519, -7.403267216855768],
              [-35.336185568530539, -7.403190084823357],
              [-35.336107386448404, -7.403061786370117],
              [-35.336009941026958, -7.402903400255322],
              [-35.335939486319461, -7.402815373048518],
              [-35.335809937212105, -7.402698913300737],
              [-35.335713326011003, -7.402647358153499],
              [-35.335607305375042, -7.402562771305319],
              [-35.335511186095275, -7.402501451900262],
              [-35.335361680264377, -7.402326346152463],
              [-35.335195287362353, -7.402211344755145],
              [-35.335098628823786, -7.402047531026947],
              [-35.335026163188282, -7.401938635096919],
              [-35.335026163188282, -7.401938635096919],
              [-35.334934968361459, -7.401815104533981],
              [-35.334843461350808, -7.401701066039716],
              [-35.334713601659075, -7.401542668580081],
              [-35.334373152598729, -7.401318760922431],
              [-35.334253385572282, -7.401254401576211],
              [-35.33400293286271, -7.401184400130422],
              [-35.333837806146832, -7.401120639909395],
              [-35.333650556259791, -7.401085557345052],
              [-35.333510028303408, -7.401067855048525],
              [-35.333390674395993, -7.401047601362008],
              [-35.333110627529898, -7.400997458434484],
              [-35.333110627529898, -7.400997458434484],
              [-35.332922371186207, -7.400994829202788],
              [-35.332831571073434, -7.400997926019773],
              [-35.332297350568552, -7.400893345970044],
              [-35.331898481134523, -7.400613883520222],
              [-35.331290481923396, -7.400339763352451],
              [-35.33117158797679, -7.400320862357203],
              [-35.330863631373127, -7.400300962291483],
              [-35.330592576957116, -7.40025619362195],
              [-35.330458166001335, -7.400213602180679],
              [-35.330223610665399, -7.400187894433397],
              [-35.329839465628353, -7.400143084202108],
              [-35.329688628817273, -7.400145138577517],
              [-35.329399748829125, -7.400206123281553],
              [-35.32912474939171, -7.400341332334319],
              [-35.32871288193833, -7.400517930507109],
              [-35.328240551890126, -7.400560621540036],
              [-35.327862504302999, -7.400556361007375],
              [-35.327627324654067, -7.400549816961725],
              [-35.327367657735621, -7.400535627572476],
              [-35.326844176375864, -7.400510614263933],
              [-35.326594165908851, -7.40057672703289],
              [-35.326430609641392, -7.400691558045635],
              [-35.326138326082763, -7.401001752696763],
              [-35.32593683609069, -7.401065713678824],
              [-35.325637457388872, -7.401145189851295],
              [-35.325251505514196, -7.401291551589043],
              [-35.325116016708527, -7.401337090451563],
              [-35.324486611702092, -7.401539858144099],
              [-35.324251328395043, -7.401565580828453],
              [-35.324035708861174, -7.401542392288998],
              [-35.323946185289479, -7.40146087930736],
              [-35.323819415902747, -7.401321442397307],
              [-35.323506875299721, -7.401031668342473],
              [-35.323100609055643, -7.400739671249854],
              [-35.322847379444987, -7.400588872156205],
              [-35.322609466930679, -7.400596620708247],
              [-35.322401965478065, -7.400636659197562],
              [-35.322273119288866, -7.400705843767721],
              [-35.322080446007284, -7.400811063649228],
              [-35.322013874004284, -7.401014689304573],
              [-35.321914984314105, -7.401288894317778],
              [-35.321871663705736, -7.401385110331266],
              [-35.321808011783929, -7.401471941801109],
              [-35.321643119006495, -7.401660533653091],
              [-35.321555305676895, -7.401715494348895],
              [-35.321238824500227, -7.401797407125001],
              [-35.320675571894, -7.401924714317647],
              [-35.320545569501455, -7.401946090260941],
              [-35.320139072209727, -7.401990870735492],
              [-35.320010857712056, -7.401990544506783],
              [-35.319796608633204, -7.401987141376854],
              [-35.319019602312729, -7.401911558115351],
              [-35.318618335412843, -7.40191771500191],
              [-35.318489304960892, -7.401917211803888],
              [-35.318147325675355, -7.401815590105212],
              [-35.317894419166606, -7.401743964721095],
              [-35.317776025140525, -7.401734276899362],
              [-35.3176437488017, -7.401736411529308],
              [-35.317324640959605, -7.401766453570545],
              [-35.31722077773231, -7.401765095928368],
              [-35.31722077773231, -7.401765095928368],
              [-35.317089261409237, -7.401739387404623],
              [-35.316996480142024, -7.401692778785692],
              [-35.316930261080607, -7.401635004788418],
              [-35.316807242470865, -7.401554658488243],
              [-35.316707066899767, -7.401513872995747],
              [-35.31659608416814, -7.401484261241784],
              [-35.316431734059016, -7.401430432295926],
              [-35.316193900365668, -7.401332153736012],
              [-35.315893921480892, -7.401280024183597],
              [-35.315527197952001, -7.401260238303166],
              [-35.315261455786604, -7.401227453789165],
              [-35.315218084557429, -7.401175435796771],
              [-35.315174690741379, -7.40106701665781],
              [-35.315038018569318, -7.400799278820524],
              [-35.31491641827207, -7.400539416579373],
              [-35.314873399069299, -7.400433345470472],
              [-35.314539982129794, -7.39964672572069],
              [-35.314479067353325, -7.399494740683014],
              [-35.314385645544185, -7.399151485535354],
              [-35.314347261932966, -7.398788240094852],
              [-35.314271827801456, -7.398577940595764],
              [-35.314201054738348, -7.398289070813174],
              [-35.314192406473552, -7.398193305845064],
              [-35.314197046267964, -7.398110668299136],
              [-35.314231121186971, -7.397890580475348],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0222000',
        uf: 'PE',
        nome_proje: 'PA ESTRELA DO NORTE',
        municipio: 'JOAQUIM NABUCO',
        area_hecta: '847.4622',
        capacidade: 84.0,
        num_famili: 83.0,
        fase: 5.0,
        data_de_cr: '20/04/2001',
        forma_obte: 'Desapropriação',
        data_obten: '04/11/1999',
        area_calc_: 896.503,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.572206141424545, -8.551577129630124],
              [-35.569559886602093, -8.551440732378039],
              [-35.568412777962756, -8.551269515634939],
              [-35.565945768827341, -8.550976743741085],
              [-35.564405766152198, -8.566305394522404],
              [-35.56427622799189, -8.568122797111876],
              [-35.564199319872806, -8.568947357743005],
              [-35.564180449008546, -8.56907119447502],
              [-35.563748055409235, -8.57326019340022],
              [-35.560445545385598, -8.572764590795357],
              [-35.557367675311625, -8.572232225902178],
              [-35.557004937723399, -8.574236161937796],
              [-35.556694829557195, -8.575570855176739],
              [-35.555880779611428, -8.579245682774131],
              [-35.555576190142133, -8.580645766088461],
              [-35.555556269477066, -8.580735180518076],
              [-35.555047676675613, -8.582665533311925],
              [-35.554699925370443, -8.584576295437124],
              [-35.554672639810398, -8.584664132131401],
              [-35.554229301906219, -8.586686318222998],
              [-35.556458067880627, -8.587022431426581],
              [-35.558607739970569, -8.587342970573637],
              [-35.560686291882433, -8.587659089564134],
              [-35.562750585860336, -8.587934084136895],
              [-35.564670363596278, -8.588239940051134],
              [-35.566542500982266, -8.588468568831914],
              [-35.568095963814685, -8.588698318368317],
              [-35.566789388334364, -8.590213066182462],
              [-35.569719832938354, -8.590646439325672],
              [-35.572216193092281, -8.591106092362525],
              [-35.574725997372113, -8.591553076740068],
              [-35.574727200144324, -8.59103175039224],
              [-35.574888357608636, -8.591112544043769],
              [-35.575727983082217, -8.590490276035533],
              [-35.576072599840906, -8.590431854302608],
              [-35.576522907957767, -8.590186572693618],
              [-35.576617047624879, -8.590023466065139],
              [-35.576684759708975, -8.589523202476702],
              [-35.576912058549958, -8.589193926929076],
              [-35.577280709657721, -8.588419469901098],
              [-35.577315246825883, -8.588129075891844],
              [-35.577774040866508, -8.586976469817257],
              [-35.577691049335847, -8.5868273801645],
              [-35.578092352096128, -8.586129063606473],
              [-35.578616158615191, -8.585098829202252],
              [-35.578747903125787, -8.584992310742152],
              [-35.579185195184436, -8.585469042748825],
              [-35.579375633736163, -8.58532860578817],
              [-35.579103351164676, -8.584696877873593],
              [-35.578862384354494, -8.583781645057009],
              [-35.578991723849633, -8.583319103892896],
              [-35.579325455751089, -8.583048215193417],
              [-35.579642271943214, -8.582800030817799],
              [-35.579839829717578, -8.582181514819325],
              [-35.579686147535114, -8.581472905702672],
              [-35.579537215174753, -8.581171721094419],
              [-35.579504006665331, -8.580959675500367],
              [-35.579163828866314, -8.5787292080187],
              [-35.579150835909616, -8.578283884839902],
              [-35.57920298486632, -8.578100908043462],
              [-35.579505619769144, -8.577783238535934],
              [-35.579753326449811, -8.57734448553804],
              [-35.579814894670044, -8.576768176584807],
              [-35.580307905378298, -8.576436040362342],
              [-35.580533155909812, -8.576019757734473],
              [-35.580366443999594, -8.575693480018778],
              [-35.580434335831896, -8.575368072930678],
              [-35.580789115464064, -8.575136262848597],
              [-35.580908343239258, -8.574669000678579],
              [-35.581079915007528, -8.574343623057626],
              [-35.581520030004448, -8.573963314253277],
              [-35.581824406470247, -8.573595028305993],
              [-35.581920244924333, -8.573361516384232],
              [-35.581875442487487, -8.572924810840885],
              [-35.582042620520951, -8.572648079004257],
              [-35.582647159192149, -8.571986990024495],
              [-35.582945525781035, -8.571871766044014],
              [-35.583649316170849, -8.570837979707468],
              [-35.583042517751721, -8.567534321455854],
              [-35.582429208764921, -8.560086742420745],
              [-35.583521634913595, -8.557424247171902],
              [-35.572401919580308, -8.552666542532485],
              [-35.572206141424545, -8.551577129630124],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0008000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO FORTALEZA/PROGRESO',
        municipio: 'SAO BENEDITO DO SUL',
        area_hecta: '508.9387',
        capacidade: 35.0,
        num_famili: 29.0,
        fase: 7.0,
        data_de_cr: '18/12/1986',
        forma_obte: 'Desapropriação',
        data_obten: '24/06/1986',
        area_calc_: 520.6919,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.968327970141139, -8.775977153271842],
              [-35.967847087523808, -8.776234187787459],
              [-35.967130119232074, -8.776618074185242],
              [-35.966653544247613, -8.776867465434497],
              [-35.966241414576508, -8.776977224655305],
              [-35.965705752975602, -8.77714719174676],
              [-35.965092855412713, -8.777428630652292],
              [-35.964582145157088, -8.777782632137493],
              [-35.96409823783894, -8.778072294625883],
              [-35.963982234358362, -8.778186245552755],
              [-35.963816353968724, -8.778348412744512],
              [-35.963186036408644, -8.778638138413575],
              [-35.962840849594748, -8.778785410771002],
              [-35.962555007353785, -8.778907217543678],
              [-35.96228547098854, -8.779018570354349],
              [-35.961822580570015, -8.779134702764445],
              [-35.961492253584346, -8.779089473293599],
              [-35.960424887659151, -8.778415271777494],
              [-35.959901077213075, -8.779529127111239],
              [-35.959550698644762, -8.780330762527075],
              [-35.959181903983335, -8.781083631335934],
              [-35.958935262232252, -8.781533377955798],
              [-35.958743432552531, -8.781730529177885],
              [-35.958418801515705, -8.781921657797321],
              [-35.957892026867874, -8.782215894393174],
              [-35.95901024773314, -8.786376196397404],
              [-35.958271698845742, -8.786689224148651],
              [-35.958271698845742, -8.786689224148651],
              [-35.957857124584187, -8.787732117863451],
              [-35.957405496679442, -8.788374229418702],
              [-35.957053080417644, -8.788988932405758],
              [-35.956752750757964, -8.78942062388108],
              [-35.956262416331157, -8.789178285741022],
              [-35.955646637685582, -8.788893454394422],
              [-35.955208964333792, -8.788746893725783],
              [-35.954655551386232, -8.788610479256826],
              [-35.95444104897102, -8.788567054677294],
              [-35.953851628584253, -8.78843689937143],
              [-35.953641991770624, -8.788455934196271],
              [-35.953492386325749, -8.788466889698958],
              [-35.953245129290174, -8.788564477816424],
              [-35.952638492467678, -8.788812705076975],
              [-35.952387759061516, -8.788885320762537],
              [-35.951780965802669, -8.789113983564834],
              [-35.951367644196225, -8.789282978956457],
              [-35.950888376199316, -8.789610094980516],
              [-35.950395664953184, -8.7898954693307],
              [-35.949998682265026, -8.788816000473771],
              [-35.949798258535225, -8.788342583259107],
              [-35.949433533463697, -8.787921537934078],
              [-35.949112931342661, -8.78762215415253],
              [-35.948809003094595, -8.788152780492137],
              [-35.948667963457858, -8.788694087829649],
              [-35.948570187727469, -8.789386682951845],
              [-35.948529848902616, -8.789812259355484],
              [-35.94841093731678, -8.791631077516124],
              [-35.948377294226752, -8.792074264140838],
              [-35.948398707023337, -8.792294742837115],
              [-35.948437549425314, -8.79295719294759],
              [-35.948460364774618, -8.793768949373822],
              [-35.948500791040956, -8.794113732303234],
              [-35.948499939711624, -8.794558292435426],
              [-35.94889398533644, -8.79464487691223],
              [-35.949558337342573, -8.794715212655003],
              [-35.949883131797868, -8.794749622712244],
              [-35.950170747747805, -8.794780519649715],
              [-35.950670497020234, -8.794417373146677],
              [-35.951269029781805, -8.793972475847028],
              [-35.952083506075539, -8.79340795329926],
              [-35.952454080005431, -8.793014841217209],
              [-35.952613954263853, -8.792507080722643],
              [-35.952802849905638, -8.792335088007746],
              [-35.953833886139385, -8.792556340668607],
              [-35.954568693459166, -8.792762524347342],
              [-35.954975285031935, -8.792915849816117],
              [-35.955150349146308, -8.792945996333971],
              [-35.955407558207263, -8.793138536070694],
              [-35.955555997576617, -8.793255845077576],
              [-35.95592094483974, -8.793531231320252],
              [-35.956364964460263, -8.793857081255323],
              [-35.956612942179426, -8.793987737812193],
              [-35.957059929941856, -8.794274434126693],
              [-35.957433447201261, -8.794528012485769],
              [-35.958152939482204, -8.795006580709295],
              [-35.958470459575395, -8.795225816283699],
              [-35.958783225357024, -8.795396720999337],
              [-35.959456328537911, -8.795743589857112],
              [-35.959955887335603, -8.79597660894942],
              [-35.960266989333554, -8.796144264332497],
              [-35.960976329838473, -8.796582145431639],
              [-35.961291145160693, -8.796873135362274],
              [-35.961896795230878, -8.797324872894807],
              [-35.962458342098905, -8.797863909392394],
              [-35.963134500707397, -8.798387911960829],
              [-35.963496969415694, -8.798625264883594],
              [-35.964079689216085, -8.798906804106272],
              [-35.964405852650295, -8.799078683624439],
              [-35.964445553979246, -8.799124564557465],
              [-35.965061337644578, -8.799681645130786],
              [-35.965305313533079, -8.799926450947709],
              [-35.965847082963414, -8.800450690333015],
              [-35.966144232570848, -8.800752138810331],
              [-35.966499926981953, -8.801167250741802],
              [-35.966617909337494, -8.801335334736656],
              [-35.967339241331139, -8.801836280134662],
              [-35.968192756350156, -8.802881129193668],
              [-35.96816440490219, -8.802956893150649],
              [-35.968250056623802, -8.804105623152598],
              [-35.968747948193311, -8.804607104569747],
              [-35.969139048469074, -8.805487128800726],
              [-35.969105538744714, -8.80450591556983],
              [-35.969072108737677, -8.803876859825515],
              [-35.969217443035419, -8.804014836373094],
              [-35.969349144917075, -8.803813805260997],
              [-35.969856830906437, -8.803078306975559],
              [-35.969626373321226, -8.802816551959038],
              [-35.969570315776537, -8.802364297621089],
              [-35.969753395383442, -8.802166121627362],
              [-35.970189949242517, -8.801964856870569],
              [-35.970452051624605, -8.801947570036885],
              [-35.970663429600442, -8.801735583963293],
              [-35.971023537244314, -8.801539541729584],
              [-35.971375360613173, -8.801263948860237],
              [-35.971504797299396, -8.801053153404384],
              [-35.972190508732915, -8.800992846554388],
              [-35.972432231391473, -8.800886593419284],
              [-35.972675944515046, -8.800345562525933],
              [-35.97292689847125, -8.799890339927908],
              [-35.973689575665162, -8.799616933096329],
              [-35.974081448785903, -8.799431235291475],
              [-35.974626627555786, -8.799013901558485],
              [-35.974725686398578, -8.798830518587945],
              [-35.974822834460156, -8.798408032103497],
              [-35.97527540014093, -8.797887087796619],
              [-35.975398630621541, -8.797447004742796],
              [-35.975877694411622, -8.796960631652706],
              [-35.976653099611063, -8.796093672688844],
              [-35.977101174994118, -8.795968395276695],
              [-35.97737919540242, -8.796166185433902],
              [-35.978516979235224, -8.795594167686712],
              [-35.979619076283271, -8.79500069216696],
              [-35.98029136275008, -8.793742719379772],
              [-35.980639724721634, -8.793309549596616],
              [-35.980883756589911, -8.792946767326509],
              [-35.981343519941582, -8.793053989892019],
              [-35.981322055153178, -8.792556361103673],
              [-35.980632494495538, -8.791859685470573],
              [-35.980249640768804, -8.791532299301561],
              [-35.979607362030521, -8.79100915002738],
              [-35.979067316121714, -8.790426497707443],
              [-35.978720204616351, -8.789922748546481],
              [-35.978132502717543, -8.789118743209535],
              [-35.977576871179274, -8.78849925568122],
              [-35.977411321245491, -8.788291879496498],
              [-35.976930456833031, -8.788004390977592],
              [-35.976552024834618, -8.787819347799086],
              [-35.976422574316302, -8.78775406975708],
              [-35.976285727642804, -8.787720370244438],
              [-35.975928074329047, -8.787948184914521],
              [-35.975581034939054, -8.787521056731325],
              [-35.975478210255105, -8.786684953914808],
              [-35.975310553074657, -8.785665676333767],
              [-35.973640311334357, -8.784478920489914],
              [-35.97318443010704, -8.783486912986739],
              [-35.972981497820712, -8.782975494758478],
              [-35.972295703105949, -8.781924431697426],
              [-35.972151715801914, -8.781679926383136],
              [-35.972062684672814, -8.781476290075373],
              [-35.971863589048411, -8.780897451901795],
              [-35.97140932142041, -8.779900807075315],
              [-35.971236879947277, -8.779513053494492],
              [-35.970825226421937, -8.778584817901891],
              [-35.970282577184769, -8.778082594066204],
              [-35.969602169774419, -8.777431460456567],
              [-35.969398279325517, -8.776798311259624],
              [-35.969114181288866, -8.776314699720702],
              [-35.968327970141139, -8.775977153271842],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0125000',
        uf: 'PE',
        nome_proje: 'PA GAIPIÓ',
        municipio: 'IPOJUCA',
        area_hecta: '1147.0000',
        capacidade: 106.0,
        num_famili: 106.0,
        fase: 6.0,
        data_de_cr: '19/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '04/08/1997',
        area_calc_: 1297.8509,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.188724396413612, -8.390432357485542],
              [-35.182715560030843, -8.390616424401168],
              [-35.182361920189315, -8.393767295261981],
              [-35.18128748658566, -8.394200788501049],
              [-35.180863484288793, -8.395165301784299],
              [-35.17912091629227, -8.398206857205917],
              [-35.178508860688481, -8.401417228602037],
              [-35.176771345787429, -8.404498619692768],
              [-35.174744620935925, -8.406019941939585],
              [-35.17278639265156, -8.406339549426944],
              [-35.170080070438189, -8.40584365269828],
              [-35.168429657880068, -8.403806655511788],
              [-35.16356149092509, -8.403221680960396],
              [-35.16369729126064, -8.408449596704553],
              [-35.163940093198576, -8.411064854860156],
              [-35.165364688980304, -8.412843786435344],
              [-35.166840985215522, -8.41631845534797],
              [-35.167260390684014, -8.416477820128399],
              [-35.166008365496324, -8.418599300093524],
              [-35.165586703264658, -8.41964972942502],
              [-35.165451302000989, -8.420226462522567],
              [-35.165215572509965, -8.420709618203123],
              [-35.164376091639369, -8.421465551259715],
              [-35.16348314412258, -8.422210255968698],
              [-35.162321240014776, -8.422536131727872],
              [-35.160902631507476, -8.422418712131051],
              [-35.159512627129338, -8.422731379512854],
              [-35.158826383234071, -8.423230761942767],
              [-35.15804391316582, -8.423931703647225],
              [-35.15759600178972, -8.424672162246333],
              [-35.156250022078616, -8.426031905076355],
              [-35.157226073758551, -8.428952131128638],
              [-35.15924175392194, -8.429173305108758],
              [-35.161583696257004, -8.428385172200496],
              [-35.163799892613007, -8.42993104793678],
              [-35.166051934217819, -8.430474470238519],
              [-35.167662390879833, -8.43135583736928],
              [-35.169449015950406, -8.428822133980036],
              [-35.172976798921837, -8.429149383778752],
              [-35.173889342801871, -8.426190190231893],
              [-35.175040986034688, -8.425464371148385],
              [-35.175592936634345, -8.425136936449555],
              [-35.176397801993559, -8.424078708377666],
              [-35.177955508428425, -8.423455476481124],
              [-35.179731468161457, -8.427168243133837],
              [-35.183879292138485, -8.426423811771635],
              [-35.187000832992183, -8.425737584202553],
              [-35.188522476302893, -8.4254509145828],
              [-35.189623289851397, -8.425351040928351],
              [-35.190944553746029, -8.424993102385274],
              [-35.192886226057247, -8.424779255608916],
              [-35.193754547996335, -8.425346998222169],
              [-35.194251136375172, -8.425534133938154],
              [-35.200043851242285, -8.424441147528016],
              [-35.205241550133323, -8.423127246536691],
              [-35.209047000780622, -8.42246996748978],
              [-35.21109943563286, -8.422087705830679],
              [-35.208560565213638, -8.419844423530721],
              [-35.206517517295637, -8.417986096576058],
              [-35.204797311964015, -8.415920378681747],
              [-35.198531009279613, -8.407778378608118],
              [-35.19734585098135, -8.408684940368287],
              [-35.196228731295378, -8.408247583294314],
              [-35.194946470738635, -8.4064358136614],
              [-35.194098735766744, -8.402263874524664],
              [-35.194836559452753, -8.401523328187823],
              [-35.195806284435562, -8.401397937972501],
              [-35.196562019246848, -8.401188159827701],
              [-35.196505765635649, -8.39838952114094],
              [-35.191749060544502, -8.394200459594851],
              [-35.188724396413612, -8.390432357485542],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0133000',
        uf: 'PE',
        nome_proje: 'PA GIQUI/ARIMUNÃ',
        municipio: 'ESCADA',
        area_hecta: '1121.8000',
        capacidade: 110.0,
        num_famili: 92.0,
        fase: 6.0,
        data_de_cr: '30/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '30/10/1997',
        area_calc_: 1117.1951,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.158147953190031, -8.304205671727557],
              [-35.156551354657452, -8.308126523718908],
              [-35.151116496635687, -8.314296613969729],
              [-35.146747871904395, -8.317552416652154],
              [-35.145502904880153, -8.320257019091944],
              [-35.147485336315505, -8.32213146162656],
              [-35.149547224877509, -8.327154303620242],
              [-35.153381851728675, -8.329617973442138],
              [-35.152666084846786, -8.334670300086163],
              [-35.157411572687337, -8.335030858504622],
              [-35.160933216844775, -8.336473154487193],
              [-35.162161797535788, -8.338334958908741],
              [-35.162885336762002, -8.330112724097356],
              [-35.163147097956738, -8.322182985836987],
              [-35.168783504327642, -8.319453420785385],
              [-35.176824885204383, -8.315919887035394],
              [-35.179205497532301, -8.32213797868612],
              [-35.180037117275276, -8.32347328736549],
              [-35.18104662430801, -8.326595465527017],
              [-35.182568963948405, -8.329790112903719],
              [-35.187014964762369, -8.333588182546038],
              [-35.188801420494208, -8.332380742710095],
              [-35.19085052920726, -8.332301550724631],
              [-35.192585226023873, -8.333129438102054],
              [-35.196756031315317, -8.328106152836487],
              [-35.196850704846646, -8.320149828279389],
              [-35.191840544834776, -8.315081719707027],
              [-35.188800702597511, -8.311155072351914],
              [-35.188775608181032, -8.305794486388242],
              [-35.183451237615145, -8.306541164924004],
              [-35.177976321892345, -8.306819649693288],
              [-35.174074957809133, -8.306891057941607],
              [-35.168249230328499, -8.305564246299745],
              [-35.16729881470966, -8.305700852554731],
              [-35.158147953190031, -8.304205671727557],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0413000',
        uf: 'PE',
        nome_proje: 'PA GRANITO',
        municipio: 'CATENDE',
        area_hecta: '331.5952',
        capacidade: 36.0,
        num_famili: 24.0,
        fase: 3.0,
        data_de_cr: '06/11/2014',
        forma_obte: 'Desapropriação',
        data_obten: '23/12/2011',
        area_calc_: 328.8922,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.728357685921686, -8.631781316617319],
              [-35.726029640935508, -8.633859502334149],
              [-35.725563165631399, -8.633297558905591],
              [-35.724606662888661, -8.632145291123349],
              [-35.724210506825472, -8.631668051354435],
              [-35.723612137306588, -8.631880423938123],
              [-35.722587918542146, -8.632243935963901],
              [-35.72239362530518, -8.633434206316316],
              [-35.721680726514045, -8.637291111423893],
              [-35.721668718231058, -8.637356078229654],
              [-35.721474813649884, -8.638405133236001],
              [-35.720973179652027, -8.64111904986439],
              [-35.720852101001995, -8.645218062587743],
              [-35.720809896089349, -8.646646962301855],
              [-35.720806947824165, -8.646746781293199],
              [-35.720773108496253, -8.64789248978426],
              [-35.720864081273078, -8.648373123550613],
              [-35.721119296006755, -8.649721479625295],
              [-35.721342767281087, -8.650902113116928],
              [-35.721533740098174, -8.651911041209893],
              [-35.721713110663281, -8.652858664306574],
              [-35.721872125763198, -8.653698721003472],
              [-35.722036314841361, -8.654566242344062],
              [-35.7222336222503, -8.655530687810305],
              [-35.721779871698658, -8.657088332260725],
              [-35.721424246091587, -8.658309127071096],
              [-35.723465747440947, -8.659324291615654],
              [-35.723523586004212, -8.659353052361922],
              [-35.723779728302084, -8.659480421260675],
              [-35.723812824064929, -8.659496878377027],
              [-35.724008998437128, -8.659594427494369],
              [-35.724253568015847, -8.659716041234212],
              [-35.726263396130328, -8.660715430672791],
              [-35.727304839476581, -8.661233282548967],
              [-35.728210887974981, -8.661683806173031],
              [-35.729083570104727, -8.662117735250551],
              [-35.729184540526795, -8.662167941170837],
              [-35.729856844299839, -8.664449191657638],
              [-35.73273609091391, -8.662979411962262],
              [-35.73241514098342, -8.662237852848358],
              [-35.731485541129786, -8.66008997480265],
              [-35.731786709000843, -8.6577514107203],
              [-35.73179601057916, -8.657679184182385],
              [-35.730150399093006, -8.656476889782562],
              [-35.730018023099184, -8.656380174267822],
              [-35.729859353568209, -8.656264248235898],
              [-35.730296288645981, -8.652484109930795],
              [-35.730426997216426, -8.651353291556248],
              [-35.730537032312931, -8.650401330537196],
              [-35.730901098403912, -8.647251650165751],
              [-35.734804056974149, -8.644977422214376],
              [-35.733808618886634, -8.644364903501916],
              [-35.735143303138877, -8.642922219172879],
              [-35.734898514551666, -8.642364945619414],
              [-35.734776923534554, -8.642138033733735],
              [-35.733904371000484, -8.640270502080199],
              [-35.730937941281418, -8.639774930561794],
              [-35.729555133593706, -8.639543813901646],
              [-35.728361309259249, -8.639344324263426],
              [-35.728134395049985, -8.639124285765666],
              [-35.727908461465915, -8.638905197720907],
              [-35.727251124237178, -8.638267774415965],
              [-35.727005076772656, -8.638029179913273],
              [-35.725809940847029, -8.636870237594399],
              [-35.728792418519987, -8.634045216110422],
              [-35.729702073398862, -8.634412624398072],
              [-35.730604423554766, -8.633751939910658],
              [-35.730520509714509, -8.633678339442508],
              [-35.730451943645406, -8.633618200396777],
              [-35.728357685921686, -8.631781316617319],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0301000',
        uf: 'PE',
        nome_proje: 'PA PIRAJÁ',
        municipio: 'IPOJUCA',
        area_hecta: '387.5145',
        capacidade: 38.0,
        num_famili: 33.0,
        fase: 3.0,
        data_de_cr: '18/03/2005',
        forma_obte: 'Desapropriação',
        data_obten: '06/11/2003',
        area_calc_: 387.309,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.088758724081544, -8.343154681299021],
              [-35.088754580975753, -8.345193233854376],
              [-35.069080337238397, -8.354638052294678],
              [-35.081083999188152, -8.363084443574246],
              [-35.08388550212733, -8.362724283018983],
              [-35.084137085769576, -8.362069750875767],
              [-35.097119118104551, -8.357196573781721],
              [-35.098264140334216, -8.347870143146972],
              [-35.088758724081544, -8.343154681299021],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0115000',
        uf: 'PE',
        nome_proje: 'PA HEBERT DE SOUZA',
        municipio: 'MORENO',
        area_hecta: '1436.2000',
        capacidade: 147.0,
        num_famili: 136.0,
        fase: 3.0,
        data_de_cr: '22/10/1997',
        forma_obte: 'Desapropriação',
        data_obten: '29/10/1996',
        area_calc_: 1462.9121,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.170852715910875, -8.108830370563245],
              [-35.170188329962571, -8.108644750652591],
              [-35.169651105225746, -8.108496498142332],
              [-35.168936440501184, -8.108309081668938],
              [-35.168847970280375, -8.108685046360346],
              [-35.168720462283829, -8.10923124669014],
              [-35.168618803344394, -8.109583785150935],
              [-35.168584126883353, -8.109759461598733],
              [-35.168543046613138, -8.110087473606795],
              [-35.168456558429, -8.11044558396264],
              [-35.168363842402549, -8.110851460547481],
              [-35.168109901075013, -8.111925377377819],
              [-35.167975614233853, -8.11246658856162],
              [-35.167853238137177, -8.113022978177847],
              [-35.167658866286025, -8.113636991191678],
              [-35.167111967903857, -8.115914329015681],
              [-35.166554647002066, -8.118330208670494],
              [-35.165894584501423, -8.120967020934101],
              [-35.165531620851134, -8.122452392983863],
              [-35.164892488333528, -8.12503277212325],
              [-35.164856925963683, -8.125155589135163],
              [-35.164541927540441, -8.126237023310724],
              [-35.16418707920554, -8.127926453840166],
              [-35.16406042285638, -8.128448032751452],
              [-35.163920068907608, -8.128957061484304],
              [-35.163783972752746, -8.129488772962382],
              [-35.163614013212822, -8.130322448414478],
              [-35.163544097845254, -8.130544270463144],
              [-35.163399801498507, -8.131040304598299],
              [-35.163038569028799, -8.131438129470075],
              [-35.161628951261093, -8.133237633228866],
              [-35.161336779153942, -8.133603856050849],
              [-35.160993455776683, -8.134031225859204],
              [-35.160658048405814, -8.134450710884604],
              [-35.160294594080817, -8.134875559596411],
              [-35.159927226333465, -8.135353162945131],
              [-35.159612602080976, -8.135751747384608],
              [-35.15929114461882, -8.13615115524483],
              [-35.158960052747041, -8.136590332097862],
              [-35.158623526845297, -8.137003187491031],
              [-35.158280754359687, -8.137442122148745],
              [-35.157092791234788, -8.138863607945536],
              [-35.161883619543538, -8.145078410113175],
              [-35.162051627991239, -8.145260186793681],
              [-35.162707918068769, -8.146204209205179],
              [-35.163471813146636, -8.147099415795445],
              [-35.164501296667922, -8.148484986940586],
              [-35.165125606111957, -8.149303155008877],
              [-35.165437374685297, -8.149725472563215],
              [-35.165771789278189, -8.150186389092321],
              [-35.166577298894019, -8.151209786659379],
              [-35.167091966905474, -8.151947084048501],
              [-35.167370031426707, -8.152357877419599],
              [-35.167659090994235, -8.152800560760362],
              [-35.168145196456308, -8.153473538150932],
              [-35.168817084876601, -8.153891188127627],
              [-35.170393376138144, -8.154807519584949],
              [-35.171198212017678, -8.155366745529122],
              [-35.17162922780421, -8.155604461052066],
              [-35.172081966872121, -8.155982943660993],
              [-35.172555003237491, -8.156319687122462],
              [-35.172905584509422, -8.156565221437166],
              [-35.173267377608276, -8.156809606474766],
              [-35.173804548200259, -8.157121669688289],
              [-35.174891525257109, -8.157788615965574],
              [-35.175472217241705, -8.158235567932859],
              [-35.175733182187379, -8.158387608533696],
              [-35.175733182187379, -8.158387608533696],
              [-35.175733182187379, -8.158387608533696],
              [-35.176221881521158, -8.158649131010526],
              [-35.176771522199296, -8.159154406725015],
              [-35.178120669761697, -8.159973824858731],
              [-35.178776842483586, -8.160408478480248],
              [-35.179042556119157, -8.160588266296138],
              [-35.179431994650024, -8.160875251735259],
              [-35.179830839768364, -8.161135507434983],
              [-35.18050140703928, -8.161393277218975],
              [-35.180666364853749, -8.161665348257122],
              [-35.182190696991896, -8.162749498005661],
              [-35.182295043262911, -8.163055646213534],
              [-35.182338191810395, -8.163297752830147],
              [-35.182562271119266, -8.163889540400199],
              [-35.182772206323712, -8.164716646729222],
              [-35.18304274212543, -8.165771529797333],
              [-35.183240467939825, -8.166442189881325],
              [-35.18441920555999, -8.166166874671768],
              [-35.185072043503204, -8.165970239659318],
              [-35.185121060737487, -8.166202081063464],
              [-35.18601256103738, -8.165861488133208],
              [-35.186428654770317, -8.165967163861696],
              [-35.18677326488239, -8.165871229895382],
              [-35.187431645947868, -8.16573091008657],
              [-35.186992766024119, -8.165244533717653],
              [-35.186057095205136, -8.164250774705151],
              [-35.185855457466253, -8.163408100458952],
              [-35.185167674351604, -8.161339804766907],
              [-35.184559174668955, -8.159116038871209],
              [-35.18436987462394, -8.158574682362078],
              [-35.184289010736052, -8.157955282986975],
              [-35.183909157485076, -8.157061380766903],
              [-35.183768877640837, -8.15659965838999],
              [-35.183716025321843, -8.156290317627768],
              [-35.183452709894617, -8.155457358913063],
              [-35.183064698818981, -8.154677135160611],
              [-35.182735776972528, -8.153194645052709],
              [-35.182437672625952, -8.152551486553907],
              [-35.182192395537228, -8.151897151278378],
              [-35.181600343024428, -8.150795157037377],
              [-35.182453644581742, -8.149585752621181],
              [-35.183085436101358, -8.148745195115396],
              [-35.183481625383969, -8.148424678676845],
              [-35.183959182361995, -8.148124194819262],
              [-35.18436285972053, -8.147777304549486],
              [-35.184796210660458, -8.147430254375717],
              [-35.18522942025006, -8.147087147539546],
              [-35.185536737490629, -8.146848815347258],
              [-35.186084105386627, -8.146405082654784],
              [-35.18651699530885, -8.146065372220818],
              [-35.186949884452339, -8.145725661340704],
              [-35.187376483772141, -8.145405081114776],
              [-35.187809405961332, -8.145071735344199],
              [-35.188257011661065, -8.144720062865686],
              [-35.188556662580822, -8.144483012101754],
              [-35.189089451316839, -8.144072700006578],
              [-35.189688120500954, -8.143634297407081],
              [-35.189962532793558, -8.143415357932925],
              [-35.19028388830754, -8.14318689952743],
              [-35.190343334353138, -8.142463845325656],
              [-35.190364673243465, -8.141976164483747],
              [-35.190414848419174, -8.141439133765251],
              [-35.190484413035101, -8.140894759815611],
              [-35.190505090113611, -8.140362489278838],
              [-35.190517415115551, -8.139820919841682],
              [-35.190551871867591, -8.1392826804256],
              [-35.190577756666464, -8.138737484851564],
              [-35.190606910485975, -8.138217946785996],
              [-35.19064978327097, -8.137450332921391],
              [-35.190844122392072, -8.136971137099218],
              [-35.190683016930471, -8.136035120317104],
              [-35.190408096488866, -8.135909174357524],
              [-35.190467515369718, -8.135117469160804],
              [-35.190456665584925, -8.134576065161809],
              [-35.190635992969874, -8.133796355092914],
              [-35.190712088487288, -8.133510207024328],
              [-35.190759356085834, -8.132978757985562],
              [-35.190807995054293, -8.132124374089889],
              [-35.191452883258357, -8.131267827057615],
              [-35.191242031746199, -8.130866804478178],
              [-35.19148110480338, -8.130279059309192],
              [-35.191685566758579, -8.129828445390581],
              [-35.191850865395651, -8.129495261481901],
              [-35.191878085292551, -8.12916069317855],
              [-35.19184873118688, -8.128722155155792],
              [-35.191908121999369, -8.128624554523869],
              [-35.192743256162004, -8.127503791970916],
              [-35.192947677014416, -8.127077251789347],
              [-35.193147978695926, -8.126547805346064],
              [-35.193223381525421, -8.125888830346877],
              [-35.193128956095428, -8.125713165088683],
              [-35.193176590942926, -8.125264161575791],
              [-35.193385406501591, -8.124906085359509],
              [-35.193416384905262, -8.124593849214879],
              [-35.193556948994676, -8.124342582399349],
              [-35.193784043781974, -8.124076320578896],
              [-35.193793687493297, -8.123861173586578],
              [-35.193740869210032, -8.123755250752644],
              [-35.193768111943342, -8.123489077099167],
              [-35.193853437798836, -8.123337976422375],
              [-35.193613674575744, -8.123239447626483],
              [-35.193568852900363, -8.12309883449629],
              [-35.193632159429406, -8.122767448170771],
              [-35.193169529342079, -8.122378209180978],
              [-35.193204321104758, -8.122102031266593],
              [-35.193276483134085, -8.12180080175275],
              [-35.193368336461084, -8.121739857583798],
              [-35.193367012184673, -8.121577338902123],
              [-35.193453374096862, -8.121183048687927],
              [-35.193493672104054, -8.120676834679953],
              [-35.193635550539248, -8.119976587928335],
              [-35.193707989653021, -8.119448633484581],
              [-35.193770523004382, -8.118919975545699],
              [-35.193819076483045, -8.118581660320729],
              [-35.193841006763805, -8.118333775194499],
              [-35.193913257668889, -8.117799348682551],
              [-35.193981140581769, -8.117291834052544],
              [-35.194047860116143, -8.116756917938964],
              [-35.1940592539165, -8.116512411660453],
              [-35.194101272599866, -8.116204482870803],
              [-35.193473569431198, -8.116032674831006],
              [-35.191322922635493, -8.115415058910898],
              [-35.189814819752428, -8.114851507789206],
              [-35.189524357041726, -8.114769580306518],
              [-35.188690089466284, -8.114491022439621],
              [-35.187489688959488, -8.114095057429731],
              [-35.187330286747809, -8.114063145443199],
              [-35.185610710185188, -8.113526760469652],
              [-35.184272908995673, -8.113122144516803],
              [-35.182810813079975, -8.112647832879597],
              [-35.18120176064896, -8.112123366549215],
              [-35.180442336709149, -8.111887735249701],
              [-35.179864326239908, -8.111699309716599],
              [-35.178725001903388, -8.111332429595485],
              [-35.177936899229117, -8.11109088622724],
              [-35.175375148191065, -8.110329592776734],
              [-35.17460147694382, -8.110061253367514],
              [-35.172397490657623, -8.109383244806807],
              [-35.171770464689864, -8.109175845787366],
              [-35.171252343900619, -8.108969423138067],
              [-35.170852715910875, -8.108830370563245],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0232000',
        uf: 'PE',
        nome_proje: 'PA JABOATÃO',
        municipio: 'MORENO',
        area_hecta: '642.7179',
        capacidade: 72.0,
        num_famili: 72.0,
        fase: 4.0,
        data_de_cr: '15/08/2001',
        forma_obte: 'Desapropriação',
        data_obten: '27/12/2000',
        area_calc_: 692.2482,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.191352868300427, -8.130923927805663],
              [-35.191337373607482, -8.130943716696025],
              [-35.191328649095546, -8.130957412857056],
              [-35.191319257835268, -8.130981959592289],
              [-35.191307367500791, -8.131014022288177],
              [-35.19130418057015, -8.131028502104321],
              [-35.191304427757721, -8.131040613224288],
              [-35.191304929894073, -8.131049559273997],
              [-35.191312191138955, -8.131083778478937],
              [-35.191322912419096, -8.131103697167276],
              [-35.191335428207516, -8.131119990526614],
              [-35.191342134790524, -8.131135592078069],
              [-35.191344576153718, -8.131150855068274],
              [-35.191347285326387, -8.131182025508149],
              [-35.191350981872631, -8.131194569988674],
              [-35.191359611483463, -8.131213505654209],
              [-35.191370467546001, -8.131224836428624],
              [-35.191437939265406, -8.131288560082588],
              [-35.19145112624453, -8.131294816366324],
              [-35.191462120030444, -8.131298101556753],
              [-35.19147653763391, -8.131297119883875],
              [-35.191489629487627, -8.131302563150948],
              [-35.191494107546106, -8.131308685614595],
              [-35.191495268696819, -8.131322057286527],
              [-35.191491604670965, -8.131332200888918],
              [-35.191491753313052, -8.131342866282225],
              [-35.191499500516514, -8.131383048691436],
              [-35.191518391521946, -8.131420730426216],
              [-35.191526711210514, -8.131432707611561],
              [-35.19153288633072, -8.131450662207602],
              [-35.191543809297563, -8.131491008266543],
              [-35.191551771196849, -8.131520613710839],
              [-35.191550626981453, -8.131543760092153],
              [-35.191540553272446, -8.131576264958975],
              [-35.191520333547622, -8.131611265110363],
              [-35.191501485613912, -8.131631614453479],
              [-35.191466919335092, -8.131663980238635],
              [-35.191453097515918, -8.131674449803858],
              [-35.191439621340827, -8.131681753800478],
              [-35.191414885128488, -8.131686858745564],
              [-35.19140520493044, -8.131691611309101],
              [-35.19139989735887, -8.131699775165089],
              [-35.191386617930043, -8.131726602660816],
              [-35.191368334102727, -8.131784009451962],
              [-35.191360820699217, -8.131803574520363],
              [-35.191357051836498, -8.131827819761774],
              [-35.191357809031359, -8.131850323143547],
              [-35.191368572682848, -8.131894737690994],
              [-35.191367738253312, -8.13197491940479],
              [-35.19136440439658, -8.132012449835532],
              [-35.191360480026944, -8.132024764236895],
              [-35.191351921696651, -8.132035657376399],
              [-35.191345462419932, -8.132048889360794],
              [-35.191341893014481, -8.132059755591143],
              [-35.191340983601009, -8.132076030953995],
              [-35.191337047446886, -8.132086176025245],
              [-35.191325771811179, -8.132097806949458],
              [-35.19130444037097, -8.132111842278526],
              [-35.191292167433772, -8.132123568972395],
              [-35.191268770936112, -8.132158224693633],
              [-35.191265813413587, -8.132164839218047],
              [-35.191261112924209, -8.132184569906622],
              [-35.191258684435056, -8.132188379446546],
              [-35.191255530277239, -8.132192192900463],
              [-35.191252272646658, -8.132193656736534],
              [-35.191247924562781, -8.132194764880884],
              [-35.191241854992249, -8.132196243883417],
              [-35.191233065161974, -8.13219791833086],
              [-35.191219476656073, -8.132201245707778],
              [-35.191214493619839, -8.132202357285529],
              [-35.191209774350412, -8.132201930774858],
              [-35.191194254957608, -8.132200477822238],
              [-35.191194164250163, -8.132200478311466],
              [-35.191180827254762, -8.132200007888985],
              [-35.191174661575658, -8.132200493098468],
              [-35.191169320118853, -8.132202420128163],
              [-35.191163528561361, -8.132204982322648],
              [-35.191158194468699, -8.132208265187137],
              [-35.19115430974044, -8.132211178661381],
              [-35.191135284758317, -8.132232342482345],
              [-35.191104711031841, -8.132264957896579],
              [-35.191052543902167, -8.132296785857564],
              [-35.191045575590621, -8.132299806352615],
              [-35.191039691359443, -8.13230200748152],
              [-35.191031711519344, -8.132302502473367],
              [-35.191000339839668, -8.132305112222948],
              [-35.190994815983359, -8.132306859452497],
              [-35.190989667729902, -8.13231095483266],
              [-35.190983441768559, -8.132317044637057],
              [-35.19098001597532, -8.132320949945726],
              [-35.190978495048412, -8.132324844981378],
              [-35.190977164382076, -8.132330366038888],
              [-35.190974364326124, -8.13234927295054],
              [-35.190968286121574, -8.132382569790767],
              [-35.190966863265785, -8.132387820172351],
              [-35.190965874810701, -8.132389542942137],
              [-35.190964520080037, -8.132390634940871],
              [-35.190962893717817, -8.132391818804631],
              [-35.190960901089092, -8.132392371897256],
              [-35.190955557173631, -8.132393846983046],
              [-35.190951753331127, -8.132394952189889],
              [-35.190939535745095, -8.132400170387445],
              [-35.190936638495764, -8.132401180322896],
              [-35.190933463714742, -8.132401197444443],
              [-35.190928381612309, -8.132400772887795],
              [-35.190921572630849, -8.132399724915729],
              [-35.190913853619669, -8.132398139486858],
              [-35.190892790722735, -8.132394818198879],
              [-35.190881811632053, -8.132394244661194],
              [-35.190874100475384, -8.132394105464101],
              [-35.190868304003509, -8.132395763771497],
              [-35.190861788253073, -8.132398601041491],
              [-35.190821394362338, -8.132410208194374],
              [-35.190785261285654, -8.132421340402514],
              [-35.190777739397149, -8.132422646449083],
              [-35.19076187139806, -8.132423816711471],
              [-35.19075071186343, -8.132423424925555],
              [-35.190737192942208, -8.132422865080228],
              [-35.190733294954711, -8.132423338055478],
              [-35.190730223652317, -8.132425704803218],
              [-35.190727697567667, -8.132428249383942],
              [-35.19072580889911, -8.132431242482733],
              [-35.190723956063941, -8.132440833972144],
              [-35.190707925591632, -8.13249560726355],
              [-35.190697267800118, -8.132520793560134],
              [-35.190696034700402, -8.132527579575669],
              [-35.190697523327223, -8.132534441296389],
              [-35.190702580010047, -8.132546888058558],
              [-35.190719118896617, -8.132569035182451],
              [-35.190776850714848, -8.132626484024012],
              [-35.190780888491908, -8.132635049445243],
              [-35.190781480354005, -8.132643814222476],
              [-35.190779986423784, -8.132652680647992],
              [-35.190769983126032, -8.132664756658631],
              [-35.19076629600508, -8.132670651988427],
              [-35.190736188535887, -8.132789136792411],
              [-35.190718223839802, -8.13285521944554],
              [-35.190716578045532, -8.132886232600187],
              [-35.190716949712169, -8.13288785764405],
              [-35.190724847901862, -8.13292243499059],
              [-35.190736506461732, -8.132981307361542],
              [-35.19073962844076, -8.133004973100682],
              [-35.190737387841125, -8.133026679138174],
              [-35.190731154122375, -8.133048045145953],
              [-35.19070946086326, -8.133078985622969],
              [-35.190702736786612, -8.133093574909399],
              [-35.190702614770672, -8.133104512940294],
              [-35.190707639166604, -8.133127716455972],
              [-35.190724958238583, -8.133159892818396],
              [-35.190728170437872, -8.133183467670628],
              [-35.19073100655234, -8.133204603983234],
              [-35.190733823133918, -8.133238847168171],
              [-35.190724445779409, -8.1332993696406],
              [-35.190708576912421, -8.133400603280997],
              [-35.190705532708805, -8.133424663829508],
              [-35.190697292607602, -8.133444052046638],
              [-35.190682718644538, -8.133466366945717],
              [-35.190671434120553, -8.133476370865758],
              [-35.190657236619884, -8.133484492273915],
              [-35.190641621176312, -8.133498767939153],
              [-35.190636796075445, -8.133512262292777],
              [-35.190621374911473, -8.133545609524351],
              [-35.190598310625198, -8.133641458482451],
              [-35.190574251318864, -8.13378793205],
              [-35.190550847308295, -8.133971552986768],
              [-35.190482633647619, -8.134122061109954],
              [-35.190422409715246, -8.13420708270024],
              [-35.190412038637426, -8.134218256786717],
              [-35.19040454665911, -8.134225076547725],
              [-35.19039467417393, -8.134227841529826],
              [-35.190352818442804, -8.134237558348035],
              [-35.190344396311311, -8.134240134725539],
              [-35.190338532165612, -8.134246041796473],
              [-35.190332324341711, -8.134255475981902],
              [-35.190330094923098, -8.134262538544908],
              [-35.190329702735717, -8.134273839599146],
              [-35.190333838119848, -8.134283669971182],
              [-35.190349726039848, -8.134319560113061],
              [-35.190352972499674, -8.134332739766402],
              [-35.190352111555505, -8.134341241206878],
              [-35.190347912910056, -8.134486794164189],
              [-35.190334903249607, -8.134546613098106],
              [-35.190335597216652, -8.134574178761254],
              [-35.190343511045278, -8.134594926130111],
              [-35.190360441835736, -8.134622404237168],
              [-35.190371535480232, -8.134660760797335],
              [-35.190378806125011, -8.1346966974063],
              [-35.190378619527678, -8.134729148962563],
              [-35.190361722382974, -8.134824783892366],
              [-35.190359115124366, -8.134862491204338],
              [-35.190360666720174, -8.13489764513678],
              [-35.190368638982712, -8.134929148770928],
              [-35.190381475745426, -8.134971111587923],
              [-35.190398044328148, -8.135015404453721],
              [-35.190465459502889, -8.135168796804992],
              [-35.190470073976712, -8.135183324941638],
              [-35.190471722935108, -8.135186298971108],
              [-35.190472562875641, -8.135190633230136],
              [-35.19047260362705, -8.135198135505199],
              [-35.190469931461912, -8.135207189060935],
              [-35.190466267398641, -8.135217332677547],
              [-35.190461010839343, -8.135234896969262],
              [-35.190433455274253, -8.135338905412384],
              [-35.19039264640957, -8.135507976819442],
              [-35.190343727762652, -8.135620235714443],
              [-35.190341029581397, -8.135624498665937],
              [-35.190337608670688, -8.135629307861988],
              [-35.19030723474382, -8.135665357105582],
              [-35.190305703004356, -8.13566726358874],
              [-35.190304449781408, -8.135670343653691],
              [-35.190303754546356, -8.135675951677339],
              [-35.190305116887238, -8.135709660354687],
              [-35.190299983658733, -8.135766634681476],
              [-35.190299737555634, -8.135771426763032],
              [-35.190300489243882, -8.135776213453861],
              [-35.190301703311384, -8.135782624708243],
              [-35.19030399361322, -8.135786770362854],
              [-35.190331871996314, -8.135842391551776],
              [-35.190338734066508, -8.135853201522748],
              [-35.190343933918612, -8.135858596967594],
              [-35.190345575024409, -8.135860124773155],
              [-35.190347212693275, -8.135861019859725],
              [-35.190348757198819, -8.135861463484392],
              [-35.190351568183964, -8.135861267540429],
              [-35.190358640523549, -8.135860687047295],
              [-35.190360905294753, -8.135860132485135],
              [-35.190363075920601, -8.135858945684232],
              [-35.190365796206436, -8.135858750229374],
              [-35.190368609155506, -8.135858915839005],
              [-35.190371877619285, -8.13585944056495],
              [-35.190373512333082, -8.135859793311882],
              [-35.190374513079682, -8.135860330260456],
              [-35.190380696984874, -8.135863189437806],
              [-35.19038734719782, -8.135868396276736],
              [-35.190401850900805, -8.135883232644252],
              [-35.190410884090355, -8.135892946200606],
              [-35.190416650298388, -8.135902406205423],
              [-35.19047687773584, -8.136001692769513],
              [-35.190528008799632, -8.136096328032522],
              [-35.190573195833629, -8.136198859397023],
              [-35.190658728663401, -8.136414433626024],
              [-35.190706689818747, -8.136610143596288],
              [-35.190754966494701, -8.136880605583258],
              [-35.190775032017527, -8.137067607659025],
              [-35.190775063221672, -8.13706763928119],
              [-35.190777995344504, -8.137105168076609],
              [-35.190777501970274, -8.137117118048316],
              [-35.190776077793664, -8.137124846935603],
              [-35.190774227725605, -8.137131027941566],
              [-35.190768406464478, -8.137138561147758],
              [-35.190762025409256, -8.13714581953035],
              [-35.190719882658726, -8.13719343183543],
              [-35.190688316117672, -8.137248731906409],
              [-35.190658564213329, -8.137286657007998],
              [-35.190652227450975, -8.137298162843337],
              [-35.190647322108511, -8.137305541166482],
              [-35.190646422768729, -8.137314016600307],
              [-35.190641443150049, -8.137354513999549],
              [-35.190638673535162, -8.137366764221261],
              [-35.190630704418844, -8.13737904249667],
              [-35.190622709732374, -8.137386615034334],
              [-35.190590730974321, -8.137416905175201],
              [-35.190583718149838, -8.137427683792176],
              [-35.190579976888301, -8.137438610227724],
              [-35.190580508371717, -8.137449430875359],
              [-35.190581030169504, -8.137510623436123],
              [-35.190592904707408, -8.137655466213451],
              [-35.19059730675545, -8.137677616670635],
              [-35.190600182690496, -8.137686693652537],
              [-35.190603490997965, -8.13769206399483],
              [-35.190641129386847, -8.138150496767077],
              [-35.190538633289258, -8.140321981715312],
              [-35.190422221813257, -8.141902381663339],
              [-35.1903237261497, -8.143240707038199],
              [-35.190347383759253, -8.144138256954042],
              [-35.190284074154718, -8.145057337583712],
              [-35.19012116188695, -8.146176721202773],
              [-35.190054227357308, -8.147596771025734],
              [-35.191288423347444, -8.148819336615871],
              [-35.191664245778554, -8.149068051081899],
              [-35.191967458215679, -8.149492878411527],
              [-35.192547158297835, -8.150332100603675],
              [-35.193523542304597, -8.152433658134065],
              [-35.1936466835975, -8.153173386288181],
              [-35.194267236605391, -8.15441561757817],
              [-35.194860951969829, -8.155609723356799],
              [-35.195606839587612, -8.157143053890213],
              [-35.196052517409512, -8.157988596684692],
              [-35.196274629107307, -8.158327895491611],
              [-35.197022937830795, -8.159837163385422],
              [-35.19719655038913, -8.160182328565629],
              [-35.197566864913448, -8.160764967880697],
              [-35.198164938598346, -8.161673585264435],
              [-35.198699995191973, -8.162471272096612],
              [-35.199428000978322, -8.163681809007587],
              [-35.203141827252324, -8.163128668246879],
              [-35.203334433180103, -8.163081611068954],
              [-35.203547870365369, -8.162796803480695],
              [-35.203637624794666, -8.162323481588047],
              [-35.203653097520125, -8.160098793834692],
              [-35.203754680564835, -8.159764338058258],
              [-35.20362367637621, -8.159645552086847],
              [-35.203490217076236, -8.159456691489583],
              [-35.203275882074841, -8.158766312448234],
              [-35.203121850128987, -8.158241987143931],
              [-35.203132365890006, -8.1580340111797],
              [-35.203292934012893, -8.157413032923252],
              [-35.203376698796937, -8.156789673106839],
              [-35.203407529746151, -8.156663658627163],
              [-35.203412538651193, -8.156637476977236],
              [-35.203415968025176, -8.156621113717794],
              [-35.203425752297044, -8.156608986332419],
              [-35.203446750226306, -8.156587783495524],
              [-35.2034576822223, -8.156579883510057],
              [-35.203478989968751, -8.15656821163077],
              [-35.203555650733563, -8.156526559036314],
              [-35.20358275616163, -8.156516862621578],
              [-35.203593331229378, -8.15651076801378],
              [-35.2035997030491, -8.156503653212818],
              [-35.203621914682579, -8.156469463280821],
              [-35.203789826357067, -8.15612963021108],
              [-35.203872602347772, -8.155976982576576],
              [-35.203896822103026, -8.155905602545365],
              [-35.204034529607647, -8.154548270991087],
              [-35.204038295256694, -8.154532603231218],
              [-35.204049864598034, -8.154515918127629],
              [-35.204051518229946, -8.154491316796939],
              [-35.204024844127723, -8.154380600831283],
              [-35.203998170047853, -8.154269884850374],
              [-35.203957840461449, -8.154239924091225],
              [-35.203946076574653, -8.15418285084503],
              [-35.204242030767368, -8.154273439491238],
              [-35.205520561689411, -8.152816708815307],
              [-35.206315687458101, -8.150519354861295],
              [-35.206963175127441, -8.148429528047474],
              [-35.207952705848022, -8.146400299724247],
              [-35.208649799770924, -8.14538189034988],
              [-35.209261498900652, -8.144901885799577],
              [-35.210113895641783, -8.144293553035391],
              [-35.211185094780795, -8.143063597146517],
              [-35.214431802924544, -8.139113344905349],
              [-35.214802768540551, -8.134531618547655],
              [-35.209302146779521, -8.13133906772538],
              [-35.204325618013954, -8.129390968896413],
              [-35.201860366468516, -8.128251577846724],
              [-35.199600719295596, -8.127004303963549],
              [-35.199089186145159, -8.126699832264572],
              [-35.197794838141064, -8.126045711004526],
              [-35.197715813810085, -8.126009258588157],
              [-35.195204090049117, -8.125425973995492],
              [-35.193886536258006, -8.122318651596276],
              [-35.193545351478519, -8.121176950526918],
              [-35.193467650975549, -8.121283850498809],
              [-35.193439342263886, -8.121315820898012],
              [-35.193431894704517, -8.121330775624916],
              [-35.19343125592718, -8.121346778339367],
              [-35.19343784814793, -8.121374764123544],
              [-35.193437161763718, -8.121381999136537],
              [-35.193424366514492, -8.121397705826132],
              [-35.193407574832214, -8.121412439763819],
              [-35.193396260715026, -8.121416929943798],
              [-35.193375983463319, -8.121424541753388],
              [-35.193365692129241, -8.121433636383603],
              [-35.193361553059127, -8.121439805312837],
              [-35.193360344529665, -8.121451110752355],
              [-35.193361796880339, -8.12146800610177],
              [-35.193362421937117, -8.121549716531568],
              [-35.193374950107511, -8.121651881624327],
              [-35.193385151098688, -8.121709677098945],
              [-35.19338535860696, -8.121731189134518],
              [-35.193380745482088, -8.121750286580827],
              [-35.193374421714495, -8.121771743433978],
              [-35.193367239882981, -8.121785521647029],
              [-35.193352272110481, -8.121802053580252],
              [-35.19332217910803, -8.121822825071154],
              [-35.193312615867569, -8.121832367722638],
              [-35.193307873261105, -8.121844324954951],
              [-35.193303246880973, -8.121860981917305],
              [-35.193298638169338, -8.121880892867827],
              [-35.193286725765979, -8.121958964845877],
              [-35.193275714845072, -8.1220025928562],
              [-35.193276145970948, -8.122031877345567],
              [-35.193278111645448, -8.122043075270108],
              [-35.193282502806781, -8.12204992134134],
              [-35.193296679455266, -8.122071448445775],
              [-35.193301093676311, -8.122082542776123],
              [-35.193302055216044, -8.122092571041376],
              [-35.193303403616824, -8.122107026386347],
              [-35.193300745271152, -8.122118610816818],
              [-35.193292647539458, -8.122130766923124],
              [-35.193280911383646, -8.122157766828039],
              [-35.193277089568355, -8.122172250060812],
              [-35.193276439012934, -8.122186083447973],
              [-35.193291313796117, -8.122286066495755],
              [-35.193302312412889, -8.122323700396098],
              [-35.19332105634323, -8.12236780070555],
              [-35.193370636525309, -8.1224611788211],
              [-35.193378103926868, -8.122483284434074],
              [-35.193382179397901, -8.122498809771839],
              [-35.193381310821671, -8.122539309789749],
              [-35.193386618473774, -8.122564590749949],
              [-35.193394486392194, -8.122576931943634],
              [-35.193409719145336, -8.122592487503459],
              [-35.193517869673826, -8.122680849386949],
              [-35.193560705889254, -8.122701589180553],
              [-35.193579335385763, -8.122707906491192],
              [-35.193604580996499, -8.122713193826934],
              [-35.193658669015072, -8.122717964038392],
              [-35.193677197997985, -8.122722474067483],
              [-35.193695290138137, -8.122730059761373],
              [-35.193711488752783, -8.122739734658467],
              [-35.193727523622812, -8.122752664529292],
              [-35.19373674331375, -8.122763371375902],
              [-35.193741424282479, -8.122773469966118],
              [-35.19374658872556, -8.122789079812987],
              [-35.193750196443325, -8.122801986319452],
              [-35.193752291630673, -8.122820324457544],
              [-35.193757817168176, -8.122869015588018],
              [-35.193745935319164, -8.122952691669415],
              [-35.193742636650732, -8.122963285245092],
              [-35.193736514328116, -8.1229717246598],
              [-35.193726970709577, -8.122984882883406],
              [-35.193721468103185, -8.122990516827706],
              [-35.193716591604961, -8.122994520340153],
              [-35.193708894389168, -8.122996912030143],
              [-35.193671124622099, -8.12300714917806],
              [-35.19366343918842, -8.123011710204143],
              [-35.193656142104714, -8.123020969472835],
              [-35.193646948547688, -8.123031775625181],
              [-35.1936417303288, -8.123039667817251],
              [-35.193634737762345, -8.12305489128091],
              [-35.193601538700612, -8.123155133555434],
              [-35.193597790650095, -8.123199897489949],
              [-35.193598606135673, -8.123216434713376],
              [-35.193600374712375, -8.123224741176186],
              [-35.193607132589214, -8.123233111128458],
              [-35.193649411989654, -8.123268226110852],
              [-35.193658516931897, -8.123274504401856],
              [-35.193669514411376, -8.123278512693298],
              [-35.193683037910255, -8.12327997640441],
              [-35.193731602783387, -8.123286584204578],
              [-35.193750952060526, -8.123291812934918],
              [-35.193767951838105, -8.123298681374203],
              [-35.193893643459717, -8.123359921455849],
              [-35.193900470444575, -8.123364313809935],
              [-35.193903308342243, -8.123369089237492],
              [-35.193904892078606, -8.123376763957847],
              [-35.193908096860682, -8.123398982942746],
              [-35.193908054267034, -8.123407841518121],
              [-35.193907795899939, -8.123410373875513],
              [-35.193906990845797, -8.123412457218416],
              [-35.193903562684874, -8.123415910575343],
              [-35.193896611729386, -8.123422094681407],
              [-35.193888211452773, -8.123428648178407],
              [-35.193876280024512, -8.123436395796757],
              [-35.19385513638931, -8.123451514809574],
              [-35.193845396636085, -8.123461962350122],
              [-35.193839095346661, -8.123470854696649],
              [-35.19380362941736, -8.123537845198541],
              [-35.193796637836193, -8.123553249441851],
              [-35.193775084987543, -8.123660027477662],
              [-35.19377449424119, -8.123668165883258],
              [-35.19377125243448, -8.123806120555546],
              [-35.193772842064661, -8.123814879935717],
              [-35.193820086478638, -8.123929060527439],
              [-35.193825707915728, -8.123945300638995],
              [-35.193831570318991, -8.123989199205992],
              [-35.193834976516413, -8.123998400749118],
              [-35.193840196841144, -8.124007592505446],
              [-35.193880571671976, -8.124059440110891],
              [-35.193883325254916, -8.124065391082988],
              [-35.193884092638996, -8.1240730702067],
              [-35.193887350112689, -8.124121683169301],
              [-35.193886751504294, -8.124128375353303],
              [-35.19388496537664, -8.124133537298361],
              [-35.19387884746601, -8.124142790222098],
              [-35.193871900429009, -8.124149697437071],
              [-35.193865307383575, -8.124154975696765],
              [-35.19383935384419, -8.124169668712531],
              [-35.193767859191595, -8.12420006430956],
              [-35.193713612902464, -8.124232988204692],
              [-35.19365935726475, -8.124264194717714],
              [-35.193653486912162, -8.124268926731087],
              [-35.193649439026309, -8.124275185568022],
              [-35.193643235794681, -8.124285433250545],
              [-35.193637033062878, -8.124295771321284],
              [-35.193634529134641, -8.124302383402183],
              [-35.193631784515134, -8.124314781818793],
              [-35.193631842455765, -8.12432544768064],
              [-35.193632609345975, -8.124333036417591],
              [-35.193635755212291, -8.124344408758247],
              [-35.19365549513396, -8.124388142078022],
              [-35.193672935887761, -8.124476179558615],
              [-35.193674466234569, -8.12449072431378],
              [-35.193674738979681, -8.124507535626265],
              [-35.19367070644364, -8.124533318919751],
              [-35.193669476331046, -8.124540647247592],
              [-35.193667324431374, -8.124545268820491],
              [-35.193659158565971, -8.124561583310768],
              [-35.193657034164517, -8.124571266645098],
              [-35.193655981043527, -8.124577780499871],
              [-35.193657392342637, -8.124603805589729],
              [-35.193658792210236, -8.124611029347896],
              [-35.193661283991467, -8.12461887995199],
              [-35.193674949932458, -8.124646556376998],
              [-35.193677719232795, -8.124655399792868],
              [-35.193679213253205, -8.12466325577933],
              [-35.193678440106453, -8.124671214387499],
              [-35.193676877747798, -8.124700871173831],
              [-35.193675379938306, -8.124709014473241],
              [-35.193672879456471, -8.124716259272628],
              [-35.193669004631616, -8.124720980522396],
              [-35.193622036123244, -8.124791196795348],
              [-35.193558164759374, -8.124905795995359],
              [-35.193484136475881, -8.124987457151249],
              [-35.193419448923834, -8.125068706350152],
              [-35.193414513112607, -8.125078495241915],
              [-35.193396119791082, -8.125132377312886],
              [-35.193391360480099, -8.1251412613342],
              [-35.193333528885944, -8.125232235812978],
              [-35.193328963259574, -8.125243378571914],
              [-35.193326526592628, -8.125262373903659],
              [-35.193327439673965, -8.125280176074716],
              [-35.193327184251132, -8.125283250762562],
              [-35.193325480964056, -8.125286965997162],
              [-35.193323233442342, -8.125290684167535],
              [-35.193320536801764, -8.12529521827903],
              [-35.193318555505108, -8.125297850312974],
              [-35.193315843643106, -8.125299582378378],
              [-35.193307703655947, -8.125303965070785],
              [-35.193264464910023, -8.125326073007585],
              [-35.193252080880711, -8.125334003857041],
              [-35.193242573913118, -8.125337218830838],
              [-35.193235690548221, -8.125339154179285],
              [-35.193213646487756, -8.125338821122618],
              [-35.193206218887795, -8.125340759397192],
              [-35.193201623806857, -8.125346478839885],
              [-35.193197975053735, -8.125359424489961],
              [-35.193199235488464, -8.125407777073596],
              [-35.193202404819573, -8.125540364110687],
              [-35.193199350874522, -8.125562616856245],
              [-35.193172940883478, -8.125660291598901],
              [-35.193170407122757, -8.125678112364323],
              [-35.193170812914119, -8.125719419028078],
              [-35.193178266787264, -8.125755716152742],
              [-35.19318415988181, -8.125771864424744],
              [-35.193190309864129, -8.12578520917342],
              [-35.193244535204826, -8.125865274600141],
              [-35.193256613645943, -8.125901275600736],
              [-35.193259139316211, -8.125915363026083],
              [-35.19325946544803, -8.125925304315466],
              [-35.193256801212762, -8.125935804082124],
              [-35.19324924633208, -8.125964408510217],
              [-35.193242819077994, -8.125983515752267],
              [-35.193213384586002, -8.126042067356996],
              [-35.193208683207054, -8.126061617243685],
              [-35.193208313644277, -8.126077076160941],
              [-35.193208501566311, -8.126094972644397],
              [-35.193209388629214, -8.126107984209192],
              [-35.193219727099553, -8.126157643691394],
              [-35.193220631841143, -8.126173909261675],
              [-35.193218994833281, -8.126189826970871],
              [-35.19321398450856, -8.1262025991767],
              [-35.193202345390993, -8.126230773674793],
              [-35.193200065584172, -8.126245248596652],
              [-35.193189236957636, -8.126339133246063],
              [-35.193188897341528, -8.126360105867464],
              [-35.193189977112908, -8.12637519540236],
              [-35.193202832786021, -8.12642068330233],
              [-35.193205744366409, -8.126439017037828],
              [-35.193207588099341, -8.126461152980678],
              [-35.19320550680419, -8.126528867340717],
              [-35.193184924174354, -8.126697377482982],
              [-35.193157073184317, -8.126796958224102],
              [-35.193143966534961, -8.126822157723215],
              [-35.19314111008115, -8.126830669927266],
              [-35.193139882909108, -8.126838540595964],
              [-35.193140741996146, -8.126846400009674],
              [-35.19314323723107, -8.126854883333223],
              [-35.193146885128115, -8.126858479309222],
              [-35.193156863481121, -8.126875238274172],
              [-35.193161183652158, -8.126885700370945],
              [-35.193161519610285, -8.126897449435328],
              [-35.193160851407889, -8.126908028825758],
              [-35.193157307117978, -8.126923504870538],
              [-35.193150838114683, -8.126934929083388],
              [-35.193124753553199, -8.126958933115461],
              [-35.193099828526023, -8.126979315244277],
              [-35.193083513621005, -8.12698166304261],
              [-35.193074735211347, -8.126985416437998],
              [-35.193070231803937, -8.126991316171511],
              [-35.193071444376216, -8.12699745624157],
              [-35.193074382839228, -8.1270040389646],
              [-35.19307904029997, -8.127009798886396],
              [-35.193087246614127, -8.127017618663553],
              [-35.19311287005921, -8.127025615659385],
              [-35.193122237884893, -8.127030175086301],
              [-35.193124723300109, -8.127036850634751],
              [-35.19312699012432, -8.127053380029615],
              [-35.193116659530041, -8.127071965987453],
              [-35.193089408046568, -8.127098236107164],
              [-35.193030005933579, -8.127150621982574],
              [-35.192984582808236, -8.127188108252787],
              [-35.192979249283297, -8.127191481508676],
              [-35.192974208009979, -8.127198559226036],
              [-35.192970836777498, -8.127212497691735],
              [-35.192966353267373, -8.127255457771296],
              [-35.192958428804005, -8.127299521123328],
              [-35.192939674394879, -8.127337044323776],
              [-35.192907267959363, -8.127382866775736],
              [-35.192883713321152, -8.127405049336053],
              [-35.192825700087191, -8.127445948000929],
              [-35.192817022200686, -8.127451508679913],
              [-35.192812277592409, -8.127463104371607],
              [-35.192802829391155, -8.1275272428293],
              [-35.192798398147843, -8.127546429707664],
              [-35.192794496902188, -8.127562992371065],
              [-35.192779671705296, -8.127589105059277],
              [-35.192653467220779, -8.127717599231968],
              [-35.19253661896272, -8.1278819282985],
              [-35.19232332699282, -8.128092786756305],
              [-35.192281027494225, -8.128137577849552],
              [-35.192272475630787, -8.12814964603032],
              [-35.192264373862621, -8.128161079046476],
              [-35.192261163397831, -8.128171220198361],
              [-35.192249024354226, -8.128240977450192],
              [-35.192212975722974, -8.1283510878231],
              [-35.192201213878576, -8.12837338754453],
              [-35.192179566939252, -8.128396102144963],
              [-35.192171783442497, -8.128416030234249],
              [-35.192169941546901, -8.128444332685527],
              [-35.192178506142078, -8.128501413863216],
              [-35.192175474948648, -8.128628068582719],
              [-35.192178201186238, -8.128645680203631],
              [-35.192182538549687, -8.128659305921849],
              [-35.192190745384401, -8.128667216100583],
              [-35.192209792010082, -8.128683474219857],
              [-35.192221381520767, -8.128696247293069],
              [-35.192228906392067, -8.128712205983302],
              [-35.192233887542223, -8.128727455265668],
              [-35.192236479987031, -8.128753835578168],
              [-35.192233430553941, -8.128793624242791],
              [-35.192223515250973, -8.128821879849042],
              [-35.192147444541853, -8.128911777629453],
              [-35.192147470721302, -8.128911809495699],
              [-35.192078226614441, -8.12901915630882],
              [-35.192044302266972, -8.129069310961604],
              [-35.192030280812055, -8.129094813557506],
              [-35.192025401219396, -8.129120989468133],
              [-35.192022709933589, -8.12916737224424],
              [-35.192005668413493, -8.129222620955909],
              [-35.191999170055666, -8.129235745544324],
              [-35.191980342070778, -8.129265313738284],
              [-35.191973726490893, -8.12928007884153],
              [-35.191970209937978, -8.129296145628071],
              [-35.19197097927119, -8.129342871223034],
              [-35.191948273092585, -8.129400300980906],
              [-35.191922588907353, -8.129440428815309],
              [-35.191916577303608, -8.129449858116137],
              [-35.19191080538905, -8.129454973469853],
              [-35.191902896722276, -8.129459048323268],
              [-35.191897122536609, -8.129464166455069],
              [-35.191893031506147, -8.129471277922732],
              [-35.191886282229596, -8.129488852973195],
              [-35.191882524396881, -8.129500182096011],
              [-35.191883357323327, -8.129513092544324],
              [-35.191889522800473, -8.129531605103288],
              [-35.191906459482929, -8.129553232155967],
              [-35.191912737366373, -8.129567774532589],
              [-35.191914272992818, -8.129587376596865],
              [-35.19190871772647, -8.129612147906705],
              [-35.191863731894408, -8.129731883474964],
              [-35.191824097999266, -8.130035342392466],
              [-35.1918179924836, -8.130135244607626],
              [-35.19179731552827, -8.130208134472433],
              [-35.191757338810895, -8.130269288793606],
              [-35.191650185668671, -8.130365467881127],
              [-35.191591219388691, -8.130409632495031],
              [-35.191577632100383, -8.130422075859057],
              [-35.191566164469393, -8.130436638506227],
              [-35.191560165698043, -8.130454957288576],
              [-35.191522995942272, -8.130597524277665],
              [-35.191509964452486, -8.130725988141489],
              [-35.191506637586663, -8.130742220733749],
              [-35.191501101948418, -8.130758037725277],
              [-35.191484698320714, -8.130788127871455],
              [-35.191477230523532, -8.130799668787597],
              [-35.191471740917756, -8.130805698731736],
              [-35.191464237824192, -8.130810739475907],
              [-35.191456729289833, -8.130814780190105],
              [-35.19143515591108, -8.130824518954379],
              [-35.191428190352681, -8.130828934881643],
              [-35.191422215021397, -8.130837967613671],
              [-35.191406922261891, -8.130863751965958],
              [-35.191352868300427, -8.130923927805663],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0180000',
        uf: 'PE',
        nome_proje: 'PA JARDIM DE MORENO',
        municipio: 'MORENO',
        area_hecta: '510.3800',
        capacidade: 70.0,
        num_famili: 64.0,
        fase: 4.0,
        data_de_cr: '19/01/1999',
        forma_obte: 'Desapropriação',
        data_obten: '10/02/1998',
        area_calc_: 510.1931,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.116484185793503, -8.136849568115306],
              [-35.102602616284514, -8.1376178259295],
              [-35.101648096885782, -8.137670677787117],
              [-35.100371351769226, -8.137741285013027],
              [-35.09880094789817, -8.137828141047175],
              [-35.09839383611456, -8.137850674899891],
              [-35.097342726794153, -8.137908807088953],
              [-35.096044845799049, -8.137980595005118],
              [-35.09472287837422, -8.141500061357892],
              [-35.093427021171408, -8.144949607411059],
              [-35.092749169326034, -8.146817904816452],
              [-35.092182566623173, -8.148412810252767],
              [-35.091873290391177, -8.149283483080891],
              [-35.091939122045005, -8.15154686629741],
              [-35.091967531193653, -8.153416853593951],
              [-35.092032924045377, -8.157722487772553],
              [-35.092046033715718, -8.158585801916358],
              [-35.092073079781422, -8.160366392797085],
              [-35.092094041352233, -8.161744477119914],
              [-35.092109768823036, -8.162779079815062],
              [-35.092211568699774, -8.164976375477258],
              [-35.097893496133146, -8.160980718463746],
              [-35.104001144305521, -8.155146305765804],
              [-35.104053079025583, -8.154807501748616],
              [-35.104029445400577, -8.153566934468865],
              [-35.104028712028011, -8.153530779728925],
              [-35.104004226667662, -8.152248544034455],
              [-35.107638268637288, -8.151344324021167],
              [-35.108205586645795, -8.151218214813243],
              [-35.108285287747556, -8.151200497975866],
              [-35.110961986874607, -8.150605488850447],
              [-35.110998051399989, -8.150597527006259],
              [-35.114341141564239, -8.149854317671695],
              [-35.116335532990121, -8.149410916954624],
              [-35.116459466020054, -8.138937882716299],
              [-35.116484185793503, -8.136849568115306],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0386000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO PIRAUÍRA',
        municipio: 'ESCADA',
        area_hecta: '838.4027',
        capacidade: 77.0,
        num_famili: 76.0,
        fase: 3.0,
        data_de_cr: '23/09/2009',
        forma_obte: 'Desapropriação',
        data_obten: '26/12/2006',
        area_calc_: 837.8469,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.202671168772618, -8.303132945408167],
              [-35.203277121457859, -8.323400828532446],
              [-35.208564934745311, -8.322847202081531],
              [-35.213869859217894, -8.332189062247901],
              [-35.214109856542677, -8.332212580226184],
              [-35.223016461124764, -8.328217851935854],
              [-35.229310148462481, -8.328081323672507],
              [-35.229976499137443, -8.327864445264643],
              [-35.233596594951663, -8.321023489066834],
              [-35.232772232509724, -8.320097936661494],
              [-35.236406263165044, -8.318051089819848],
              [-35.236765824460711, -8.317780704291051],
              [-35.236966146297171, -8.317227215516825],
              [-35.23845959931932, -8.312826404319511],
              [-35.231795306058224, -8.308165380407482],
              [-35.202671168772618, -8.303132945408167],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0195000',
        uf: 'PE',
        nome_proje: 'PA MATO GROSSO',
        municipio: 'MORENO',
        area_hecta: '570.5200',
        capacidade: 71.0,
        num_famili: 69.0,
        fase: 4.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '11/09/1998',
        area_calc_: 563.3891,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.131390961048048, -8.136721638400893],
              [-35.127545509797649, -8.136754694684312],
              [-35.123749462458207, -8.136787290598294],
              [-35.123176722008779, -8.136792205535883],
              [-35.119777212060235, -8.136821361587975],
              [-35.11648422212614, -8.13684957696546],
              [-35.116384850098719, -8.145242731681529],
              [-35.116353117113576, -8.147923690928993],
              [-35.116335515037036, -8.149410953206448],
              [-35.11536705193415, -8.149626253095384],
              [-35.113554302394483, -8.150029240576192],
              [-35.112688271769791, -8.150221762537731],
              [-35.107638223515593, -8.151344369453659],
              [-35.109342588817199, -8.152341189219978],
              [-35.111069791155984, -8.153351351640012],
              [-35.112288866477535, -8.154064325163926],
              [-35.112945898649862, -8.154448586095345],
              [-35.113230692990591, -8.154615145563405],
              [-35.113413241468344, -8.154803253692204],
              [-35.113803580134245, -8.155205479404179],
              [-35.113833030676815, -8.155235826757758],
              [-35.113825227328263, -8.155287401009643],
              [-35.113820523691288, -8.155331799947175],
              [-35.113814352589849, -8.155413703703015],
              [-35.113803954815921, -8.155550026722716],
              [-35.11379826095343, -8.155612840606372],
              [-35.113723654951201, -8.155861763294721],
              [-35.113698969931335, -8.15595765684051],
              [-35.113682294519535, -8.156058770116189],
              [-35.11367314650748, -8.156170224881709],
              [-35.11364680878993, -8.15642761168165],
              [-35.11364351795698, -8.156436360783992],
              [-35.113637075368054, -8.156444680681371],
              [-35.113591780311452, -8.156489566281635],
              [-35.113532086847464, -8.156555868358552],
              [-35.113395970657287, -8.156710490470742],
              [-35.113327548053391, -8.156791417207556],
              [-35.113303121891576, -8.15681248771579],
              [-35.113307396282053, -8.156849601657475],
              [-35.113398424170683, -8.157699927644936],
              [-35.113500867165605, -8.15865385112116],
              [-35.113598087569585, -8.159559133918444],
              [-35.113681249891179, -8.160333505448689],
              [-35.113872493697052, -8.162114259742804],
              [-35.114045894348145, -8.162052126037914],
              [-35.1168315571935, -8.161053942924928],
              [-35.117179469237669, -8.1623185950997],
              [-35.117445983731606, -8.163290055476001],
              [-35.118428975067481, -8.166860417850961],
              [-35.118852609725643, -8.168400249272894],
              [-35.119684733991619, -8.171424809410963],
              [-35.119703734099822, -8.171493869151456],
              [-35.119715580473446, -8.171536927172172],
              [-35.11969622418605, -8.171741806724084],
              [-35.119692624770018, -8.172055926609328],
              [-35.119689863288123, -8.172122929638293],
              [-35.119684171575884, -8.172158482943159],
              [-35.119626555766743, -8.172344762400005],
              [-35.119613287035946, -8.17238761020276],
              [-35.119612558816172, -8.172397018308351],
              [-35.119614609369634, -8.172406230505604],
              [-35.11961756017876, -8.172417751661342],
              [-35.11961777323787, -8.172429640161415],
              [-35.119611661340116, -8.172453112363854],
              [-35.119598625536803, -8.172473589801539],
              [-35.11958421136336, -8.172495608350298],
              [-35.119575251213256, -8.172529978652456],
              [-35.1195349341127, -8.172819076710697],
              [-35.119519232826839, -8.173056651936577],
              [-35.119510509988963, -8.173144202062362],
              [-35.119491711114271, -8.173229224189374],
              [-35.119454333362263, -8.173360198918592],
              [-35.119418436306773, -8.173483630108299],
              [-35.119406266473739, -8.173547699821649],
              [-35.119400829137476, -8.173586194607351],
              [-35.119398304781186, -8.173617322314874],
              [-35.119398501304964, -8.173648550858294],
              [-35.11940155142085, -8.17368233890938],
              [-35.119415248677811, -8.17374818100007],
              [-35.119436404987653, -8.173809766297898],
              [-35.119452436949544, -8.173846062707463],
              [-35.11948671898832, -8.173909631593711],
              [-35.119535117466292, -8.17398510606688],
              [-35.119593591716054, -8.174066336210853],
              [-35.119648639782461, -8.174155526091745],
              [-35.119688843270644, -8.17422760101562],
              [-35.119731378202587, -8.174312224829601],
              [-35.119767385949146, -8.17439443800323],
              [-35.119781936313707, -8.174442484859572],
              [-35.119784762445022, -8.174461612413669],
              [-35.119788923393699, -8.174521167145633],
              [-35.119792398169345, -8.174580765538449],
              [-35.119793265041643, -8.174606783770686],
              [-35.119792258677144, -8.174659873531835],
              [-35.119790944394651, -8.174686913411128],
              [-35.119801908712283, -8.174674117923836],
              [-35.120991316353965, -8.173286059996638],
              [-35.121849984726531, -8.174281127497695],
              [-35.122912575316562, -8.175512498969974],
              [-35.124655725161567, -8.175420402567353],
              [-35.126060281277049, -8.176949796155151],
              [-35.126786602055411, -8.177055973031408],
              [-35.128282196961052, -8.177274601449666],
              [-35.129512388375048, -8.177454428285586],
              [-35.128900297527665, -8.17293685338263],
              [-35.128696351776362, -8.171431573947437],
              [-35.128371032566633, -8.169030416835536],
              [-35.12836610866605, -8.168994073453259],
              [-35.128215778406563, -8.167884477141445],
              [-35.128144173124454, -8.166236385082629],
              [-35.127827952035837, -8.158957483985034],
              [-35.127607689972848, -8.153886782891593],
              [-35.127539377825698, -8.152314050138729],
              [-35.124846420168261, -8.150497852827796],
              [-35.124384169309344, -8.150186095481169],
              [-35.122795133912511, -8.149114388418218],
              [-35.123263052038936, -8.148591467636894],
              [-35.123984577462849, -8.147785125597201],
              [-35.124564107360122, -8.147217101708888],
              [-35.124963192332473, -8.146972989389106],
              [-35.126638633351526, -8.145948148912009],
              [-35.127318913079556, -8.145532029629651],
              [-35.127521400215436, -8.145408170246473],
              [-35.128753731340865, -8.14264181908128],
              [-35.129944210431113, -8.139969390449878],
              [-35.131390961048048, -8.136721638400893],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0183000',
        uf: 'PE',
        nome_proje: 'PA SÃO JORGE',
        municipio: 'LAGOA DOS GATOS',
        area_hecta: '979.1500',
        capacidade: 81.0,
        num_famili: 67.0,
        fase: 5.0,
        data_de_cr: '28/01/1999',
        forma_obte: 'Desapropriação',
        data_obten: '06/10/1998',
        area_calc_: 978.7219,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.946710606437726, -8.702744078362368],
              [-35.945526887700659, -8.702248983151872],
              [-35.94437054079814, -8.703361146519839],
              [-35.943246903691204, -8.70443086738109],
              [-35.942699781136, -8.705626301753977],
              [-35.942673370962183, -8.706765489806545],
              [-35.94137658446531, -8.707871059741741],
              [-35.941134739393036, -8.70807230807252],
              [-35.94087955413633, -8.709087426954278],
              [-35.940715507860531, -8.70974021642731],
              [-35.940198689868609, -8.710376945826953],
              [-35.939828485442902, -8.710837103437576],
              [-35.93981725893088, -8.711236124564186],
              [-35.939353633641808, -8.71148823301874],
              [-35.93807451274705, -8.712127155129334],
              [-35.935466396856533, -8.713496618115977],
              [-35.935149734617248, -8.715024344470454],
              [-35.935073486204814, -8.716189761338796],
              [-35.935418207440094, -8.71826642206479],
              [-35.935401348487552, -8.719746209792715],
              [-35.935519731340754, -8.721463806736391],
              [-35.935556740503564, -8.723132075550023],
              [-35.9355776348877, -8.72407432675004],
              [-35.935790768612527, -8.724867928399016],
              [-35.934942098292559, -8.725440016132282],
              [-35.933858856689689, -8.726170293811938],
              [-35.932648525586458, -8.726986290404334],
              [-35.932049047815624, -8.727390404483428],
              [-35.931978928365155, -8.727434038302439],
              [-35.931396351325127, -8.72779339368036],
              [-35.930573540367838, -8.728295263147649],
              [-35.930033570446547, -8.727576091140334],
              [-35.929350367902636, -8.7272584974263],
              [-35.926101211896189, -8.727633466642056],
              [-35.923969231525675, -8.727960047560446],
              [-35.9224889308895, -8.72856824314157],
              [-35.921087706961984, -8.729549199429211],
              [-35.918963090937403, -8.728782677471516],
              [-35.918478800234936, -8.728612681746977],
              [-35.917961324751538, -8.730504228431391],
              [-35.91765386524925, -8.73120401988923],
              [-35.917800594532793, -8.733047994689944],
              [-35.918019278237317, -8.733799294702788],
              [-35.917990760325736, -8.735245767042935],
              [-35.919135914338412, -8.738319342756586],
              [-35.918694799432757, -8.740349337418511],
              [-35.918656594505642, -8.741156909296762],
              [-35.92020983964175, -8.74329556206513],
              [-35.920682530265303, -8.744269481641776],
              [-35.921232988450676, -8.7447356325195],
              [-35.921729878094496, -8.745400310552835],
              [-35.922592280289983, -8.745776872288824],
              [-35.923353993322365, -8.746911164197115],
              [-35.92403473166771, -8.74724530982164],
              [-35.925512697077075, -8.74743489065458],
              [-35.926099319146402, -8.747670386087689],
              [-35.927159566674533, -8.749054312521531],
              [-35.928235418382961, -8.750458529225252],
              [-35.928947129203927, -8.750061308519607],
              [-35.930183200676495, -8.749357841663185],
              [-35.930286463232108, -8.749290461963056],
              [-35.931078486170101, -8.748527918331021],
              [-35.932006034656609, -8.746020151619071],
              [-35.932342336209452, -8.745110815016078],
              [-35.933393219933166, -8.743697813453856],
              [-35.934319117757823, -8.74260512743624],
              [-35.934678946880723, -8.742393565587129],
              [-35.93519111261957, -8.742178202679009],
              [-35.936614836812659, -8.741579509942],
              [-35.936980471041444, -8.741158948683463],
              [-35.938076930195102, -8.73914619424164],
              [-35.938906911480856, -8.738087493311932],
              [-35.939397973090173, -8.737011366964499],
              [-35.939498529256866, -8.736784740910302],
              [-35.940691391396385, -8.735604970535515],
              [-35.942359206886891, -8.734758923706259],
              [-35.943422518574451, -8.734625275897713],
              [-35.943739851031197, -8.734195421657278],
              [-35.945623307794079, -8.734182953124607],
              [-35.945797763496799, -8.734209782325083],
              [-35.945976845539349, -8.734282286407177],
              [-35.946394520182835, -8.734547522771448],
              [-35.948763475386599, -8.734869305761938],
              [-35.949198109382436, -8.734785341853671],
              [-35.950179105842906, -8.734427014779282],
              [-35.950905812207445, -8.734443308013013],
              [-35.951085162150008, -8.734526738049327],
              [-35.951493958472817, -8.734898092745906],
              [-35.951927749587263, -8.735537824903536],
              [-35.952011062363852, -8.735532568568196],
              [-35.953641658288404, -8.735523021751662],
              [-35.954669849614838, -8.734240799344088],
              [-35.954262675090057, -8.732703544734498],
              [-35.954335689381061, -8.731632751156377],
              [-35.954699269707774, -8.731116802113116],
              [-35.954879600228288, -8.730437145234115],
              [-35.954824533188265, -8.729958877990036],
              [-35.954024225688613, -8.728284662380943],
              [-35.953695995182542, -8.727731284085621],
              [-35.952070092464879, -8.725943250785177],
              [-35.951854955404393, -8.725580413925085],
              [-35.95161993466624, -8.725286929972144],
              [-35.948723305982099, -8.722209704714102],
              [-35.947943444450353, -8.721549253061017],
              [-35.947635518156311, -8.720700121755966],
              [-35.947320540665416, -8.720225042788067],
              [-35.947698472186893, -8.718019221998389],
              [-35.948307210093091, -8.71708726852366],
              [-35.948934439073241, -8.716950142647535],
              [-35.949600551581362, -8.716804493063218],
              [-35.950119423193328, -8.716778328812245],
              [-35.95153318261265, -8.716348807206494],
              [-35.952368564013867, -8.714796729944011],
              [-35.953350293996856, -8.714064500473647],
              [-35.953464120937483, -8.7137949521398],
              [-35.95331345351795, -8.712495266505615],
              [-35.953632536682669, -8.711956181925425],
              [-35.953694303648007, -8.711185124574497],
              [-35.954092704409568, -8.710260135016544],
              [-35.954062009335139, -8.709184095489492],
              [-35.952976337220171, -8.708170457502749],
              [-35.952131334620361, -8.707257387767346],
              [-35.95209081686135, -8.706629405667238],
              [-35.952518911247992, -8.705935993057146],
              [-35.952358219212726, -8.705370283586481],
              [-35.95153203204039, -8.704655535208168],
              [-35.950005341610797, -8.704085701181574],
              [-35.949527812481179, -8.703731398078613],
              [-35.948825541345904, -8.703523648461195],
              [-35.948518325347024, -8.703271820645311],
              [-35.947437655736245, -8.703217685535211],
              [-35.946710606437726, -8.702744078362368],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0319000',
        uf: 'PE',
        nome_proje: 'PA LAGO AZUL',
        municipio: 'CARUARU',
        area_hecta: '247.7014',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 4.0,
        data_de_cr: '04/11/2005',
        forma_obte: 'Desapropriação',
        data_obten: '09/06/2005',
        area_calc_: 258.0111,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.021547798206818, -8.088254528766948],
              [-36.021359690300415, -8.088253403683385],
              [-36.02096700607369, -8.088320592757759],
              [-36.020616511897266, -8.088438791403739],
              [-36.02050524481907, -8.088469984236369],
              [-36.02030262900324, -8.088511630175837],
              [-36.020112474754242, -8.088574662348069],
              [-36.019892725827745, -8.088682774831492],
              [-36.019614246122721, -8.088845065098289],
              [-36.019357852814693, -8.089011581381335],
              [-36.019108353377511, -8.089144207849804],
              [-36.018822061470892, -8.089304968715288],
              [-36.01858555076668, -8.089397637590494],
              [-36.018266625080074, -8.089508597484212],
              [-36.017942271276866, -8.089610115676265],
              [-36.017688760725932, -8.089695188375364],
              [-36.017404092424187, -8.089784237317669],
              [-36.016976200463432, -8.089900807394608],
              [-36.016802249029496, -8.089962591561036],
              [-36.016632119341857, -8.090034701909865],
              [-36.016540648078141, -8.090060592458309],
              [-36.016446804160843, -8.090088633708435],
              [-36.016373808308217, -8.090121475059197],
              [-36.016257017596878, -8.090183545607697],
              [-36.016125604666641, -8.090241845930036],
              [-36.015659029078002, -8.090453797443821],
              [-36.015510059227836, -8.090514038836709],
              [-36.015311303592796, -8.090564092682207],
              [-36.015111197372711, -8.090588839794789],
              [-36.014882092422908, -8.090610674957585],
              [-36.014689593032813, -8.090636632179203],
              [-36.014416056568393, -8.090683936414477],
              [-36.014188911694646, -8.090751546264846],
              [-36.014035098150629, -8.090762074286513],
              [-36.013911061958446, -8.090803353923473],
              [-36.013808188712325, -8.090863488056831],
              [-36.013726438460161, -8.090947896644126],
              [-36.01364323126095, -8.091033197891663],
              [-36.013587135028153, -8.091097965896539],
              [-36.0135868559409, -8.091179943794739],
              [-36.013857625114227, -8.091609399132816],
              [-36.014010420894351, -8.091872403657375],
              [-36.014102196798746, -8.092051309747033],
              [-36.014191976814558, -8.092242758023671],
              [-36.014191380871594, -8.092483591525486],
              [-36.014131841510341, -8.092702945192583],
              [-36.014079535368801, -8.092875671695767],
              [-36.014054317223561, -8.093015516365087],
              [-36.014036587282554, -8.093209603313266],
              [-36.014034350078042, -8.093336094190215],
              [-36.014041903833878, -8.093488561093166],
              [-36.014056884751653, -8.09365552398422],
              [-36.014054529742886, -8.093845681540754],
              [-36.014043988046168, -8.094042545962973],
              [-36.014011381617088, -8.094313129459609],
              [-36.013989955503291, -8.094465963861698],
              [-36.01394546279699, -8.094773334787996],
              [-36.013892316885425, -8.095204784930225],
              [-36.013888132431589, -8.095637196317158],
              [-36.013898296801315, -8.095846498192627],
              [-36.013946745460807, -8.096001518056688],
              [-36.014017832857618, -8.096105020043645],
              [-36.01410193733598, -8.09619933424171],
              [-36.014155212399835, -8.096272231835629],
              [-36.01418613775013, -8.096377150521818],
              [-36.014196707212918, -8.096453550700902],
              [-36.014200985032041, -8.096553405129681],
              [-36.014203835957566, -8.096664261933537],
              [-36.014215382396024, -8.096811749843464],
              [-36.01422835351422, -8.096895708577605],
              [-36.014266579523543, -8.097027630264069],
              [-36.015153035802896, -8.095672651957322],
              [-36.015839750971892, -8.094446023287933],
              [-36.01681192790543, -8.094029362708698],
              [-36.019466218484695, -8.093726981199017],
              [-36.019536336213392, -8.094569616917275],
              [-36.019516900557889, -8.0950318206778],
              [-36.019215818390826, -8.095899838247306],
              [-36.019062968629996, -8.096493501034642],
              [-36.018612820493239, -8.098191711110653],
              [-36.019521347244229, -8.098061520780028],
              [-36.023706139942085, -8.097234211928644],
              [-36.024875514971335, -8.097269844956848],
              [-36.026285544118565, -8.097876086879932],
              [-36.027369475931089, -8.098425836435027],
              [-36.029472612849808, -8.099317170199342],
              [-36.030925077481008, -8.097872041711202],
              [-36.031409596766935, -8.098156520659355],
              [-36.033181066977242, -8.099195655606701],
              [-36.035288813319205, -8.100327750444993],
              [-36.038063920260853, -8.101579405414615],
              [-36.038068793176905, -8.10157867361508],
              [-36.03898619413922, -8.100769547554147],
              [-36.039057877426963, -8.098020337884492],
              [-36.03828346916049, -8.09744838134198],
              [-36.037350643384499, -8.097677118036515],
              [-36.036621859350873, -8.096705472028072],
              [-36.036760211576542, -8.096442327292843],
              [-36.036456184875995, -8.095969996579194],
              [-36.035956716461016, -8.096018401754982],
              [-36.03575688347717, -8.096841739470198],
              [-36.03440922013931, -8.096282053564016],
              [-36.034154797545398, -8.095423705346411],
              [-36.034479351251385, -8.095345212509248],
              [-36.034575017026903, -8.095328563072474],
              [-36.034782474131305, -8.095333775097755],
              [-36.03507379550058, -8.095341093730879],
              [-36.035326017225444, -8.095294905080694],
              [-36.035631352478013, -8.095179322211427],
              [-36.035983266805474, -8.095040951491677],
              [-36.036231298442097, -8.094945693026732],
              [-36.036450067443546, -8.094835932476597],
              [-36.036767718803937, -8.094660002014159],
              [-36.036935377153846, -8.094553780019428],
              [-36.037085429993212, -8.094475850591408],
              [-36.037188899937462, -8.094433239402758],
              [-36.037344793713636, -8.094354653420313],
              [-36.037520538219113, -8.094253524531755],
              [-36.037628387261513, -8.094194684033713],
              [-36.037824151717906, -8.09412537067487],
              [-36.038035667575819, -8.09409619150901],
              [-36.038303435574775, -8.094099614041751],
              [-36.038511982362515, -8.094102359745209],
              [-36.038720841635218, -8.094151393892886],
              [-36.039191826730224, -8.094179835553291],
              [-36.039473762882679, -8.09417736414817],
              [-36.039629757395751, -8.094174621715752],
              [-36.03972338194388, -8.094181253711064],
              [-36.040378570982966, -8.093985726492864],
              [-36.041036698557619, -8.093859417853414],
              [-36.041298736854642, -8.090634772466652],
              [-36.037212176605969, -8.092353091048219],
              [-36.033996421701239, -8.090904822942164],
              [-36.033493706776817, -8.090679479634058],
              [-36.033835481457317, -8.090192968093485],
              [-36.034233980247826, -8.089744165753947],
              [-36.035150537414033, -8.08874987425437],
              [-36.035150501270948, -8.088749840982663],
              [-36.034542757107786, -8.088872385328742],
              [-36.034370087831881, -8.088895251203928],
              [-36.034163346787643, -8.088842165789892],
              [-36.033790586673128, -8.088572055773541],
              [-36.033667072275854, -8.088506697520854],
              [-36.03360760671459, -8.088480297919793],
              [-36.033476842411474, -8.088425325635727],
              [-36.033351281115344, -8.088365422464339],
              [-36.033239324229946, -8.088316910371738],
              [-36.033123541416373, -8.088295742928777],
              [-36.032972654913678, -8.088277753170878],
              [-36.032759945499038, -8.088222817298048],
              [-36.032563895231, -8.088182998479063],
              [-36.032277634843908, -8.088157790779595],
              [-36.031909770401093, -8.088172732444979],
              [-36.031670812042101, -8.088199271411332],
              [-36.030767474265332, -8.088093421296659],
              [-36.030725450061205, -8.08808850838863],
              [-36.030373655356108, -8.087988655112239],
              [-36.030167361290793, -8.087936655381117],
              [-36.030007701818867, -8.08790369530479],
              [-36.029745580663096, -8.087813076901723],
              [-36.029219726135466, -8.087728218100889],
              [-36.028917190076086, -8.087697650425831],
              [-36.028836478497709, -8.087677821022428],
              [-36.028459938986948, -8.087707261422583],
              [-36.02833757156322, -8.087725591725368],
              [-36.028184471548101, -8.087762419628318],
              [-36.027808365441636, -8.087838457042379],
              [-36.027567048891939, -8.087902376045975],
              [-36.027475638280187, -8.087907672851557],
              [-36.027353422289387, -8.087972819992158],
              [-36.026930223962381, -8.088364243412748],
              [-36.026736060148998, -8.088401132343913],
              [-36.026403227666073, -8.088366780108336],
              [-36.026184647106291, -8.088328278974247],
              [-36.025773416514873, -8.088299085781513],
              [-36.025287674316523, -8.088344058099734],
              [-36.024806239700716, -8.088345517864543],
              [-36.02468164992991, -8.088375866466762],
              [-36.024451440967759, -8.088507256401863],
              [-36.024339135179254, -8.088518904613888],
              [-36.024178141234295, -8.08855675717289],
              [-36.024011595053636, -8.088594501578786],
              [-36.023781773568615, -8.088626860754079],
              [-36.023597217272389, -8.088626870289463],
              [-36.023300501676154, -8.088618563756713],
              [-36.023086249313813, -8.088642729467123],
              [-36.022771220077075, -8.088655174718593],
              [-36.02261438102289, -8.088645872184193],
              [-36.022414802171255, -8.088605329420062],
              [-36.022297860369257, -8.088557049460418],
              [-36.022145095626271, -8.088461803712461],
              [-36.021956006572907, -8.088372099277017],
              [-36.021774980329063, -8.088306536708794],
              [-36.021547798206818, -8.088254528766948],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0090000',
        uf: 'PE',
        nome_proje: 'PA LAGOA',
        municipio: 'NAZARE DA MATA',
        area_hecta: '285.0491',
        capacidade: 61.0,
        num_famili: 55.0,
        fase: 3.0,
        data_de_cr: '17/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 291.2086,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.281494727869905, -7.701837301494152],
              [-35.281496796366952, -7.701834398085536],
              [-35.279627778108967, -7.701800962990006],
              [-35.275272116054261, -7.703207593828088],
              [-35.268779084561579, -7.703085939769909],
              [-35.268523810453246, -7.713050670053529],
              [-35.268464942000428, -7.718698693710619],
              [-35.278658525369472, -7.717600138209799],
              [-35.286998788673358, -7.716572825023582],
              [-35.28453802171682, -7.710144370537326],
              [-35.281494727869905, -7.701837301494152],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0149000',
        uf: 'PE',
        nome_proje: 'PA NOVO MUNDO',
        municipio: 'BUENOS AIRES',
        area_hecta: '203.0000',
        capacidade: 21.0,
        num_famili: 19.0,
        fase: 6.0,
        data_de_cr: '16/10/1998',
        forma_obte: 'Desapropriação',
        data_obten: '13/05/1998',
        area_calc_: 208.6694,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.335966473142861, -7.757739368967147],
              [-35.33584913766262, -7.757696657406163],
              [-35.335775406451901, -7.757678481191754],
              [-35.335601967598826, -7.75765559588933],
              [-35.335499692532416, -7.757670925867636],
              [-35.335391461603862, -7.75771067327172],
              [-35.335295051779234, -7.757762521965743],
              [-35.335200518649216, -7.757832941479049],
              [-35.335105005618928, -7.757886554861719],
              [-35.334947420457191, -7.757944933081448],
              [-35.334727636181384, -7.757985071545558],
              [-35.334587913245791, -7.75798604228431],
              [-35.333501036767551, -7.757931319389045],
              [-35.333064666587603, -7.758663743641654],
              [-35.331230908970696, -7.761741340672889],
              [-35.332707882461435, -7.76168434102008],
              [-35.332303594277342, -7.764569895295106],
              [-35.334573175862488, -7.764617267184821],
              [-35.334889840158162, -7.76462384272486],
              [-35.334863702735603, -7.768143618202178],
              [-35.334861230619488, -7.768467024664673],
              [-35.33580489823526, -7.768652455383029],
              [-35.336898072340283, -7.76886724930349],
              [-35.337892996902958, -7.76906269653653],
              [-35.343372389536917, -7.770139060705402],
              [-35.343451406869391, -7.770154622482695],
              [-35.346159490221773, -7.770686576999042],
              [-35.346982020342466, -7.769375003980437],
              [-35.348725459898638, -7.770741986642126],
              [-35.349851049272758, -7.768703231835048],
              [-35.348187543567462, -7.767515401221467],
              [-35.3491339730299, -7.765981433688922],
              [-35.349637630900546, -7.765165022897768],
              [-35.350687243156273, -7.763463818646843],
              [-35.346587846754979, -7.760597289306296],
              [-35.345486270854096, -7.759826977152952],
              [-35.344624317052094, -7.759224265907139],
              [-35.343185245243049, -7.758217917522438],
              [-35.341141044655288, -7.756788464641851],
              [-35.341125317810466, -7.757827688528653],
              [-35.34090260975718, -7.757818407692668],
              [-35.3407201009761, -7.757821181781188],
              [-35.34057756501366, -7.757833468762584],
              [-35.340440266835685, -7.757870501640401],
              [-35.34029512183416, -7.757932352424592],
              [-35.340226935364711, -7.757973458922085],
              [-35.339871295571633, -7.758292148919276],
              [-35.339751125861497, -7.758378636948717],
              [-35.339485152416863, -7.758521910379055],
              [-35.33919119442767, -7.758670608758472],
              [-35.339059798814198, -7.758732383623541],
              [-35.338875662731787, -7.758801969440253],
              [-35.338702496458595, -7.758855700044687],
              [-35.338622126239173, -7.758874591294131],
              [-35.33852882698276, -7.758887270555107],
              [-35.338352363976099, -7.758900628285425],
              [-35.338219256017567, -7.758893396841966],
              [-35.338092201227177, -7.758857818015355],
              [-35.337915812619165, -7.758784066272955],
              [-35.337689450851677, -7.758657520172904],
              [-35.337634292211803, -7.758629066890967],
              [-35.337567606251156, -7.758601561779238],
              [-35.337416048960243, -7.758546651457603],
              [-35.337306021933053, -7.758502130611789],
              [-35.33718818887931, -7.758449689283659],
              [-35.337137029525259, -7.758422541169641],
              [-35.337033782249641, -7.758356598606893],
              [-35.336466953106836, -7.758006599261072],
              [-35.336199066758184, -7.757838145427281],
              [-35.335966473142861, -7.757739368967147],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0009000',
        uf: 'PE',
        nome_proje: 'PA PITANGA - I',
        municipio: 'IGARASSU',
        area_hecta: '956.0000',
        capacidade: 67.0,
        num_famili: 67.0,
        fase: 7.0,
        data_de_cr: '29/01/1987',
        forma_obte: 'Desapropriação',
        data_obten: '09/10/1986',
        area_calc_: 951.4249,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.984303818914377, -7.848707662032925],
              [-34.983755087830829, -7.848743102920235],
              [-34.983386676152932, -7.848769897325238],
              [-34.98243617998633, -7.8490995614412],
              [-34.981790604419153, -7.849319763873956],
              [-34.980849213130092, -7.849686132254525],
              [-34.980053385629766, -7.849897574210558],
              [-34.979755038598206, -7.849980831534872],
              [-34.978729851527262, -7.850458397852194],
              [-34.978195033425223, -7.850602903641453],
              [-34.97796190815712, -7.850663023381705],
              [-34.977275717090862, -7.850797660564625],
              [-34.976617615347998, -7.851086404310278],
              [-34.975379876915184, -7.852316673083918],
              [-34.975242605160197, -7.852454853156892],
              [-34.975129425537567, -7.852610181499414],
              [-34.975013111246682, -7.852811183981207],
              [-34.974918294476659, -7.853071108626309],
              [-34.974865867808617, -7.85332081130693],
              [-34.974783609068638, -7.853638586483322],
              [-34.97467974209161, -7.853874053482848],
              [-34.974587772890409, -7.854027611694948],
              [-34.974462638619578, -7.854193854215314],
              [-34.974343970814203, -7.854310509142084],
              [-34.974192651587437, -7.854430379954071],
              [-34.973702730148943, -7.85474227432371],
              [-34.973500343591596, -7.854926976223108],
              [-34.973265102132913, -7.855012717880034],
              [-34.972401936713119, -7.855276249411839],
              [-34.972022634737208, -7.855363780243636],
              [-34.970903152184142, -7.855980981088859],
              [-34.970179112492836, -7.856381391750354],
              [-34.969939202457979, -7.856424835188168],
              [-34.969210752726468, -7.856600865176897],
              [-34.968665734503901, -7.856657431074168],
              [-34.967414475431227, -7.856804168446065],
              [-34.966110352953173, -7.856990684261379],
              [-34.965095034881401, -7.857200345923498],
              [-34.963923392552111, -7.85706049274741],
              [-34.963637234011422, -7.857006704486754],
              [-34.963268450993006, -7.856957199879805],
              [-34.963036574783189, -7.856928214701766],
              [-34.962682406903895, -7.856899243822515],
              [-34.962374943007774, -7.856827178893625],
              [-34.962127681491189, -7.856732559523775],
              [-34.961844363641212, -7.856570175506531],
              [-34.961421612968216, -7.856324918160198],
              [-34.960119899329456, -7.855954855809062],
              [-34.959315582041157, -7.855672397662598],
              [-34.959114532716725, -7.855548605687862],
              [-34.958676879694558, -7.855340165005215],
              [-34.958890387517847, -7.856334777206997],
              [-34.959143105821951, -7.857593142288225],
              [-34.959553710406681, -7.859296231976257],
              [-34.959615228655728, -7.859315990750807],
              [-34.959629314800445, -7.859461813727624],
              [-34.959634550142702, -7.859625496453662],
              [-34.959633824660131, -7.859827906471121],
              [-34.95968900255901, -7.86010300000036],
              [-34.959682371859067, -7.860355969612443],
              [-34.959731709744176, -7.860754984359571],
              [-34.959784696166764, -7.861217460320474],
              [-34.959810172838502, -7.861410560383619],
              [-34.959807695344097, -7.861596552150847],
              [-34.959811178278443, -7.861862699174909],
              [-34.959823925091115, -7.862198963160051],
              [-34.959829051026709, -7.862339259623466],
              [-34.959834197879694, -7.862484010596662],
              [-34.959877649359271, -7.86281846075205],
              [-34.959965072885588, -7.863003476004994],
              [-34.960055892922135, -7.863079337292532],
              [-34.960154020103488, -7.863522113303764],
              [-34.96017851650172, -7.863744172816024],
              [-34.960217881957746, -7.864040777678445],
              [-34.960234993482857, -7.864473908997436],
              [-34.96024244942258, -7.864812981664925],
              [-34.960516609871007, -7.866590762343716],
              [-34.960869201298998, -7.86889632548406],
              [-34.961344698580518, -7.872042398444467],
              [-34.961572529652521, -7.87382094915129],
              [-34.961828387745214, -7.8757396881124],
              [-34.961972090635214, -7.877320957960535],
              [-34.962134902044596, -7.877860316801735],
              [-34.962279390210767, -7.877947618745416],
              [-34.962372529473875, -7.878040729331761],
              [-34.962467724144801, -7.878095966120987],
              [-34.962515869427037, -7.878121354634836],
              [-34.96259072997249, -7.8781276859523],
              [-34.962807572355509, -7.878163420842411],
              [-34.963159464604757, -7.878179590892651],
              [-34.96351683084432, -7.87817234832245],
              [-34.963716521540739, -7.878123525627571],
              [-34.963965154114163, -7.878032154768672],
              [-34.964117842421508, -7.877963506490071],
              [-34.964235939816433, -7.877901702193649],
              [-34.964470912606494, -7.877755826255225],
              [-34.964587788009723, -7.877671754616645],
              [-34.965008336818123, -7.877326221971088],
              [-34.96549809952888, -7.876855635742387],
              [-34.965612953490776, -7.87681667620315],
              [-34.966448420028101, -7.876826677818564],
              [-34.966648290749312, -7.87681627400343],
              [-34.966786816390425, -7.876823419520224],
              [-34.966997056692392, -7.876880342618449],
              [-34.967151559886993, -7.876959800169836],
              [-34.967299530299051, -7.877074925074691],
              [-34.967420817417171, -7.877215789069122],
              [-34.967694304062313, -7.877657737075847],
              [-34.968121884344413, -7.877973954112486],
              [-34.968690157596484, -7.878578501101174],
              [-34.96958819476648, -7.879538420720701],
              [-34.970341099455439, -7.87986117909442],
              [-34.971712683658644, -7.880463890496496],
              [-34.972436326260777, -7.880797918457274],
              [-34.972850753765563, -7.881047650632407],
              [-34.973234015329979, -7.881319801976076],
              [-34.973501877293764, -7.88151565510095],
              [-34.973618149799094, -7.881658210544341],
              [-34.973719123189952, -7.881754064981389],
              [-34.973925865525807, -7.882368378966518],
              [-34.974580515770931, -7.882755068352912],
              [-34.974877578374013, -7.88274921312937],
              [-34.975598496550532, -7.882625539694038],
              [-34.976430121595428, -7.882295318910739],
              [-34.977344937817151, -7.881844430770415],
              [-34.977881938074802, -7.881563485070711],
              [-34.978159541963684, -7.881458605541026],
              [-34.97843291103451, -7.881402746075179],
              [-34.978739981255536, -7.881387931955914],
              [-34.979387711579591, -7.881610940680162],
              [-34.980549911651458, -7.882217392028566],
              [-34.982496544696637, -7.883256116193847],
              [-34.990700719893418, -7.864933567267461],
              [-34.988444109504215, -7.863981526044665],
              [-34.991808405090921, -7.85657940453061],
              [-34.992033752843803, -7.856059383139465],
              [-34.994016553822391, -7.857682006184578],
              [-34.994946719822899, -7.855549997656043],
              [-34.994307578753372, -7.85477794365227],
              [-34.993838500532853, -7.853836366091858],
              [-34.99363196715391, -7.853500471997615],
              [-34.993095856592021, -7.852670017175131],
              [-34.992700271508689, -7.852150712317082],
              [-34.992586740202462, -7.852054921172805],
              [-34.992442354159216, -7.851987673868895],
              [-34.991993391773988, -7.851750370178571],
              [-34.991335057322907, -7.851166603820829],
              [-34.991154140992279, -7.851049972233874],
              [-34.990617325306289, -7.850656060207563],
              [-34.990364163695538, -7.850494667976514],
              [-34.990239917187324, -7.850435119801914],
              [-34.989823528834684, -7.850236636116101],
              [-34.989460745206564, -7.850037898308078],
              [-34.989172821766267, -7.849846601457108],
              [-34.988848934557083, -7.84960758810125],
              [-34.988282801765401, -7.849623628765804],
              [-34.988008619059912, -7.84962102727753],
              [-34.987380786342435, -7.84957889248494],
              [-34.987195541977513, -7.849608722429375],
              [-34.986666467957306, -7.849668017657855],
              [-34.986551971012126, -7.849662433533489],
              [-34.98642902430332, -7.849641298373455],
              [-34.986281821294575, -7.849568493613381],
              [-34.986185994556593, -7.849496559773711],
              [-34.98607823295972, -7.849380693601136],
              [-34.985539531647959, -7.84882363431646],
              [-34.98501468172978, -7.848831679981306],
              [-34.984596638841147, -7.848754584486178],
              [-34.984493796659685, -7.848733910624612],
              [-34.984303818914377, -7.848707662032925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0119000',
        uf: 'PE',
        nome_proje: 'PA RIACHÃO',
        municipio: 'BONITO',
        area_hecta: '835.5000',
        capacidade: 98.0,
        num_famili: 97.0,
        fase: 6.0,
        data_de_cr: '22/10/1997',
        forma_obte: 'Desapropriação',
        data_obten: '09/04/1997',
        area_calc_: 1130.4964,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.741394560741682, -8.535934101825811],
              [-35.741425447056606, -8.54047680125603],
              [-35.740958066150426, -8.542333508748978],
              [-35.742201225710339, -8.544772678018461],
              [-35.742517490436889, -8.545712619664403],
              [-35.74279924202343, -8.546720159126497],
              [-35.74416829389682, -8.548705524108726],
              [-35.745095621174784, -8.550042927052525],
              [-35.745919774483241, -8.550439652958842],
              [-35.747060836674741, -8.550995155925108],
              [-35.748042346290894, -8.551643141338724],
              [-35.748663339987033, -8.552666840226452],
              [-35.744177158049943, -8.552876759032291],
              [-35.743878348429348, -8.554159377883884],
              [-35.743599060485423, -8.555344311449817],
              [-35.743590624389832, -8.555578174629531],
              [-35.743540843135555, -8.55688070400139],
              [-35.743496865978024, -8.557907582997997],
              [-35.743415721124634, -8.558306089100009],
              [-35.743102165266357, -8.559701844618738],
              [-35.742683453215875, -8.561510212591189],
              [-35.742453758223235, -8.562452476186914],
              [-35.742227048618048, -8.563160141091155],
              [-35.740597281818864, -8.568004167889081],
              [-35.740508149087589, -8.571412761441492],
              [-35.74034879520692, -8.571534665762636],
              [-35.740200795968462, -8.571721520957196],
              [-35.740121029121148, -8.571878472648789],
              [-35.740114237012428, -8.572233097530123],
              [-35.740072069194333, -8.57242694315272],
              [-35.739980124276904, -8.572621142222829],
              [-35.739930818792487, -8.572687298028454],
              [-35.739834158267094, -8.572766177018304],
              [-35.73968978097038, -8.572915845629566],
              [-35.739603296810586, -8.573112328482699],
              [-35.739360482212874, -8.573201535283314],
              [-35.738398315009327, -8.581447249385324],
              [-35.738518688872212, -8.582993604047608],
              [-35.738753256258235, -8.586199387043578],
              [-35.739027737611814, -8.589545786105138],
              [-35.739221149363338, -8.589616409789585],
              [-35.739406421850759, -8.589636769245168],
              [-35.739861680963287, -8.589775980838708],
              [-35.740047775749275, -8.589802527573259],
              [-35.740353352382186, -8.589894803930653],
              [-35.740526535953606, -8.589964796570159],
              [-35.741145200611534, -8.590112133793351],
              [-35.741473722159945, -8.590150052944074],
              [-35.741977581681589, -8.590124788818192],
              [-35.742412382342366, -8.590014855706322],
              [-35.74268936575605, -8.589808499061583],
              [-35.74281743427953, -8.589662040702116],
              [-35.742963112473895, -8.589368362233689],
              [-35.74305617847736, -8.589113767586646],
              [-35.743110930623757, -8.588723189710603],
              [-35.743140381468955, -8.588275502518794],
              [-35.743141667365052, -8.588021561518],
              [-35.743165652799142, -8.587679202081643],
              [-35.743209151181951, -8.58745437942874],
              [-35.743348450559878, -8.587139069461376],
              [-35.743542807443681, -8.586908527486999],
              [-35.74389966582573, -8.586560700896024],
              [-35.744299069763748, -8.586288440945795],
              [-35.744556316930606, -8.586150352627683],
              [-35.744683707713278, -8.586126219730678],
              [-35.744852909908758, -8.586075466769755],
              [-35.745056590931121, -8.586060080447353],
              [-35.745275515927297, -8.586001231268598],
              [-35.745511741524929, -8.585968580862488],
              [-35.745769076910989, -8.585842878193255],
              [-35.746069500489583, -8.585651837139372],
              [-35.746311966343669, -8.585405468018948],
              [-35.746444765717698, -8.585268265748928],
              [-35.746626585921526, -8.585025425535598],
              [-35.74687916021653, -8.584670599000818],
              [-35.74698026838751, -8.58445310783992],
              [-35.747038644860829, -8.584350499948675],
              [-35.74722655293747, -8.584089035971353],
              [-35.747402283619017, -8.583864819419176],
              [-35.747733392686314, -8.58361472137765],
              [-35.747937435079884, -8.58343365695815],
              [-35.748290744764283, -8.583242238002409],
              [-35.748786876182386, -8.583009542981051],
              [-35.749283742594471, -8.582879033869972],
              [-35.749755875562087, -8.582770377659742],
              [-35.750452722633483, -8.58275921232323],
              [-35.750784988100165, -8.582670133286889],
              [-35.750930485754886, -8.582568451798748],
              [-35.751058649093949, -8.582435926884397],
              [-35.751359582192798, -8.582208493324915],
              [-35.751594261861598, -8.582069788788772],
              [-35.751826981751677, -8.581982968030955],
              [-35.752004036219795, -8.581942995186337],
              [-35.752290213836815, -8.581934758602873],
              [-35.752585880948828, -8.581948130998285],
              [-35.752944105046879, -8.582007506992905],
              [-35.753224395129187, -8.582045762331552],
              [-35.75348966552437, -8.582158445653889],
              [-35.753814493739114, -8.582332637329626],
              [-35.754048965958759, -8.582488894014608],
              [-35.754384032566954, -8.582788428344877],
              [-35.754686868616581, -8.58314857823982],
              [-35.755020042909308, -8.583616895383086],
              [-35.755426070110097, -8.584264299682319],
              [-35.755662052850987, -8.58462957118393],
              [-35.75594602384519, -8.584961983872162],
              [-35.75633585197248, -8.585304477428341],
              [-35.756481777188526, -8.585477622782705],
              [-35.756650717349835, -8.585606475456986],
              [-35.756738376869748, -8.585681717366841],
              [-35.756867958408783, -8.585745820872543],
              [-35.757384192449408, -8.585927928618117],
              [-35.757730791881272, -8.586100411265814],
              [-35.757796707329845, -8.586180453509733],
              [-35.757891238283271, -8.586345449965419],
              [-35.758137173960911, -8.586580586459743],
              [-35.758240624590726, -8.586688230090285],
              [-35.758407372216162, -8.586944061703676],
              [-35.758516429769877, -8.587181726105014],
              [-35.758608611243559, -8.587452026532588],
              [-35.758684397945906, -8.58782153841276],
              [-35.758704475068882, -8.58801648616352],
              [-35.758658368035064, -8.588524673680784],
              [-35.758591792028049, -8.588783724190682],
              [-35.758598786673033, -8.588890509937594],
              [-35.758551257816649, -8.588986847760653],
              [-35.758531841260812, -8.589098467700605],
              [-35.758536972688624, -8.589270297312966],
              [-35.758593533162227, -8.589347309502978],
              [-35.758564508910652, -8.5894210637959],
              [-35.75879345393529, -8.589780188417574],
              [-35.759055989199751, -8.589943980655876],
              [-35.759219302611569, -8.589939713962906],
              [-35.759432652078608, -8.589970700632916],
              [-35.759532746663616, -8.590044303967083],
              [-35.759657674545053, -8.590109987607891],
              [-35.759719439705954, -8.590261282738631],
              [-35.759779850948952, -8.590440457730942],
              [-35.759872839871278, -8.590607012474873],
              [-35.759974837535793, -8.590728600644972],
              [-35.76008440033457, -8.590820716004265],
              [-35.760405580016801, -8.591134275875802],
              [-35.760646064140175, -8.590615405395718],
              [-35.761639687969613, -8.588404990961285],
              [-35.763330121266122, -8.584675304063877],
              [-35.763881265177559, -8.583448163542972],
              [-35.764323610734671, -8.5824494093163],
              [-35.765522484056135, -8.579800894469537],
              [-35.765754352120325, -8.579275119188521],
              [-35.767142619372045, -8.576145262977201],
              [-35.767726722350133, -8.57474602253456],
              [-35.767804773529626, -8.574461343123511],
              [-35.76776375159136, -8.574274288887606],
              [-35.767771702835731, -8.573868568087864],
              [-35.767792291533759, -8.57370429688657],
              [-35.767807129069872, -8.57360509701522],
              [-35.767851252065789, -8.573468527029636],
              [-35.767911768076942, -8.573232746025376],
              [-35.767937202626307, -8.573093213438863],
              [-35.767914021693642, -8.572684619316727],
              [-35.767863458534123, -8.572361379999757],
              [-35.767795126279296, -8.572162134894178],
              [-35.767746679310179, -8.571916297079181],
              [-35.7677034032791, -8.571740097243641],
              [-35.767683984135559, -8.571421279452302],
              [-35.767605189693242, -8.571280945991276],
              [-35.767580792701132, -8.571134029082202],
              [-35.767547567080541, -8.571056850560618],
              [-35.767550294923829, -8.570788968974451],
              [-35.767578381288047, -8.570585935638341],
              [-35.767635898670079, -8.570365659591459],
              [-35.767716098412677, -8.570055417473801],
              [-35.767712644649812, -8.569900608705316],
              [-35.767715864651059, -8.569593241066004],
              [-35.767671067361263, -8.569421697028176],
              [-35.767628161746906, -8.569296589477853],
              [-35.76754296682126, -8.569131528368144],
              [-35.767478553421157, -8.569043735019179],
              [-35.767359675751202, -8.56895323541147],
              [-35.767122525434132, -8.568855841841691],
              [-35.766855854627522, -8.56876330467286],
              [-35.766618883650651, -8.568690682820561],
              [-35.766329876336194, -8.568520888427189],
              [-35.766254664499449, -8.568445558826163],
              [-35.766149293900234, -8.568286835065248],
              [-35.7661375769842, -8.568172342055419],
              [-35.766153914710813, -8.568065389716867],
              [-35.766264095455, -8.567813769669989],
              [-35.76643322751908, -8.567540050520247],
              [-35.766564792789538, -8.567234085476597],
              [-35.766582876259847, -8.567153442385834],
              [-35.766600657422693, -8.567030996377854],
              [-35.766695198567717, -8.566660266494546],
              [-35.76673455437161, -8.566402186444236],
              [-35.766741759886408, -8.566107950838802],
              [-35.766798427835155, -8.565985226236792],
              [-35.766898492192787, -8.565840514000477],
              [-35.766977216581786, -8.565756339773896],
              [-35.767042143237724, -8.565700134464739],
              [-35.76722907761917, -8.565307065974823],
              [-35.767215972354457, -8.565000589309323],
              [-35.766971034923266, -8.56447126476162],
              [-35.766925086830973, -8.564355468678917],
              [-35.766865989820516, -8.564142221553732],
              [-35.766748579234424, -8.563608886370844],
              [-35.76675605369779, -8.563136590082509],
              [-35.766787475997624, -8.56296449963164],
              [-35.766772984414509, -8.562896476603305],
              [-35.766860828524706, -8.562782818859858],
              [-35.766909830919914, -8.562675632657646],
              [-35.767066808690082, -8.562442257824987],
              [-35.767160219674494, -8.562237982519283],
              [-35.767202099277853, -8.562113815639153],
              [-35.767305049766698, -8.561830506864997],
              [-35.767323043650251, -8.561737477804563],
              [-35.767322226576709, -8.561624455110142],
              [-35.767303152613202, -8.561568077453776],
              [-35.767371323912982, -8.561530429105046],
              [-35.767448111654168, -8.561447430208096],
              [-35.767544560582479, -8.561286873670156],
              [-35.76773327342886, -8.561139978351799],
              [-35.766526378150367, -8.560521545003779],
              [-35.764739121648802, -8.560312924183629],
              [-35.763729671836529, -8.559786741741403],
              [-35.761028435174353, -8.558376928810279],
              [-35.759913513688211, -8.559510539026864],
              [-35.759333900735072, -8.558946434778351],
              [-35.758495777519514, -8.558125600128852],
              [-35.757645047074391, -8.557281627233776],
              [-35.757314540161396, -8.556963476615016],
              [-35.758754064383545, -8.554031474588818],
              [-35.758769814107914, -8.553842463841052],
              [-35.758696458325382, -8.553592154842846],
              [-35.75855744019352, -8.553295863741146],
              [-35.758398491521866, -8.553039971716315],
              [-35.758325916402526, -8.552898041325054],
              [-35.758092599148355, -8.552250947298102],
              [-35.758072630311986, -8.552069932922223],
              [-35.757998841113547, -8.551759241029359],
              [-35.757914233528375, -8.55102592700675],
              [-35.757887661108207, -8.55089992602497],
              [-35.757848667629403, -8.550777110288053],
              [-35.75771473778638, -8.550647229575969],
              [-35.757554326905982, -8.550512118329827],
              [-35.757368296232791, -8.550383382984901],
              [-35.757080428358478, -8.550153182207831],
              [-35.756958448581692, -8.550062698838394],
              [-35.75672745974056, -8.549846801638234],
              [-35.756196138691749, -8.549397694531597],
              [-35.75616625180335, -8.549351456910147],
              [-35.755986210000408, -8.549190162531787],
              [-35.755790057716318, -8.548951565197921],
              [-35.755686131511368, -8.548775793290895],
              [-35.755510222315401, -8.548540148216855],
              [-35.755401863802909, -8.548396923114282],
              [-35.7552016246512, -8.548238868655309],
              [-35.755109627099394, -8.548207782467296],
              [-35.755047154624918, -8.548172615151232],
              [-35.754833873672887, -8.548147811607471],
              [-35.754668504670235, -8.548080087162878],
              [-35.754257257804674, -8.547997080879126],
              [-35.754093194094871, -8.547894508886015],
              [-35.754009967534898, -8.547785168079701],
              [-35.753962204190607, -8.547632220964307],
              [-35.753937658236168, -8.547030085961165],
              [-35.753946111036768, -8.546690936295777],
              [-35.753718989225135, -8.545821603076437],
              [-35.751924661903253, -8.543643439936185],
              [-35.750136696599654, -8.543326344285362],
              [-35.747398011820167, -8.541661169620735],
              [-35.741394560741682, -8.535934101825811],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0179000',
        uf: 'PE',
        nome_proje: 'PA SANTANA',
        municipio: 'JABOATAO DOS GUARARAPES',
        area_hecta: '237.1600',
        capacidade: 32.0,
        num_famili: 22.0,
        fase: 5.0,
        data_de_cr: '19/01/1999',
        forma_obte: 'Desapropriação',
        data_obten: '13/10/1998',
        area_calc_: 231.9923,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.990139402044349, -8.112359047296856],
              [-34.990051615302562, -8.112360886538319],
              [-34.98987182943803, -8.11236393464954],
              [-34.989724304625241, -8.112341602820976],
              [-34.989597349371863, -8.112296117853655],
              [-34.989403729486902, -8.112308454372618],
              [-34.989138796225483, -8.112318788646954],
              [-34.988806892779102, -8.112306397184391],
              [-34.98851887203314, -8.112325974441026],
              [-34.988228899695279, -8.112428369632394],
              [-34.988097600632123, -8.112440401304571],
              [-34.987913188122221, -8.112425029052256],
              [-34.987710259116639, -8.112407577426836],
              [-34.987403721755129, -8.112388190436441],
              [-34.987140829870363, -8.112352498616788],
              [-34.986816001132709, -8.112376864811944],
              [-34.986615475370037, -8.11238697365923],
              [-34.986308892002491, -8.11237671670481],
              [-34.986034603550223, -8.11236639243397],
              [-34.985885103530833, -8.112422176580855],
              [-34.98572182571003, -8.112498730041494],
              [-34.985539974053481, -8.112561542415923],
              [-34.985236188530891, -8.112659302101388],
              [-34.984964652563342, -8.112747773748319],
              [-34.984815185238723, -8.112828779731119],
              [-34.984709477559427, -8.112879739555929],
              [-34.984518597885184, -8.112988520069827],
              [-34.984339296595692, -8.11307202177138],
              [-34.984159757692737, -8.113125601301784],
              [-34.983984811495041, -8.113172287789014],
              [-34.98384196872982, -8.113179854281038],
              [-34.983738212682681, -8.113184879853785],
              [-34.983620611855756, -8.113160049562744],
              [-34.983530509985599, -8.113119084024603],
              [-34.983461102995541, -8.113061925993977],
              [-34.983379973297581, -8.112945159283923],
              [-34.983291824446603, -8.112821465724055],
              [-34.983173435560168, -8.112635903156914],
              [-34.983027525251494, -8.112461865272447],
              [-34.982856091201477, -8.1122627292198],
              [-34.982705475515296, -8.112091064531194],
              [-34.982562126071166, -8.111995212257879],
              [-34.982405201566962, -8.111943000100924],
              [-34.982266884076544, -8.111929841694479],
              [-34.982153988161656, -8.111939431411763],
              [-34.982071265204596, -8.111997239726419],
              [-34.982032548976854, -8.112093887857053],
              [-34.982012349348352, -8.112211148104246],
              [-34.981884183677899, -8.112418432471991],
              [-34.981787952814912, -8.1125521543986],
              [-34.981740163383371, -8.112685550195605],
              [-34.981648738740226, -8.11285595224374],
              [-34.981536540225086, -8.113026455370388],
              [-34.981402961292432, -8.11303623599688],
              [-34.98125103363585, -8.11307819883455],
              [-34.981092054357653, -8.113088102939948],
              [-34.980884633435323, -8.113079800450244],
              [-34.980734624649322, -8.113050516370786],
              [-34.980605458270063, -8.113016610723637],
              [-34.980450801291198, -8.112964386820272],
              [-34.980291551940844, -8.112919146197262],
              [-34.980102298823937, -8.112860219754854],
              [-34.979926880050421, -8.112792004862616],
              [-34.979652264308214, -8.112715050997311],
              [-34.979430942313691, -8.112683852957096],
              [-34.979052593890152, -8.112598181719605],
              [-34.978831214829341, -8.112573854224594],
              [-34.978609779599473, -8.112538045705465],
              [-34.978404525324677, -8.112509029075182],
              [-34.978284712421846, -8.112514131232091],
              [-34.978146697402451, -8.112562895982279],
              [-34.978132964299434, -8.112592886059504],
              [-34.97807803202118, -8.112675781161345],
              [-34.978059930637102, -8.112758587773838],
              [-34.978051171983864, -8.112859700805569],
              [-34.978056775128998, -8.113077906292133],
              [-34.978027771017594, -8.113287149178626],
              [-34.978030756929783, -8.113452481655527],
              [-34.978038146119992, -8.113553606653737],
              [-34.978056898343446, -8.11362240260401],
              [-34.978098760010333, -8.11370491794197],
              [-34.978140610641773, -8.113785173251545],
              [-34.978184818959129, -8.113883768893981],
              [-34.978217585127879, -8.113998511836035],
              [-34.978234214977029, -8.114115593239459],
              [-34.97824623033128, -8.114216605337521],
              [-34.978253596145159, -8.114331471670875],
              [-34.978267767078151, -8.114409510948695],
              [-34.978305137297632, -8.114519620946973],
              [-34.978324126283809, -8.114636781273399],
              [-34.978324519500205, -8.114717147559762],
              [-34.978285554008259, -8.114781613365407],
              [-34.978196047757685, -8.114862416454431],
              [-34.978141137855204, -8.11494992206698],
              [-34.978118590482232, -8.115051102091204],
              [-34.978036169128501, -8.115189367009345],
              [-34.977963192314128, -8.115348197908101],
              [-34.97789222369618, -8.115472485126398],
              [-34.977814080729786, -8.115521049528448],
              [-34.977744932520949, -8.115516774901819],
              [-34.977641007896182, -8.11546900457046],
              [-34.977495591997709, -8.115414474714946],
              [-34.977299196150263, -8.115305046584833],
              [-34.97703576328751, -8.115140707646853],
              [-34.976788477514077, -8.114994732300493],
              [-34.976499745731047, -8.114851218037353],
              [-34.976273436164028, -8.114746454645221],
              [-34.976030934624191, -8.114632638975154],
              [-34.975843792462307, -8.114523165045604],
              [-34.975672663107311, -8.114386040374578],
              [-34.975452878243544, -8.114205486976854],
              [-34.975274921857078, -8.114082136463439],
              [-34.97513400885375, -8.114020713088518],
              [-34.974953885190494, -8.113936517447025],
              [-34.97479667980506, -8.11382689769029],
              [-34.974651039995848, -8.113726352482482],
              [-34.974475363387903, -8.113605340935433],
              [-34.97428819950904, -8.113491255618998],
              [-34.974168094703387, -8.113436691982674],
              [-34.974061767252664, -8.113379711033721],
              [-34.973967197067132, -8.11335250609946],
              [-34.973838141562844, -8.113359911898929],
              [-34.973679295860336, -8.113397385493064],
              [-34.973541447004891, -8.113499033932792],
              [-34.973398927728894, -8.113573132003856],
              [-34.973348419149048, -8.113614691028152],
              [-34.973302480321237, -8.113663188949419],
              [-34.973235802514836, -8.11368177350554],
              [-34.97319671425177, -8.113702665248672],
              [-34.973160106496231, -8.113767119310083],
              [-34.97313035961961, -8.113824669482614],
              [-34.97307068793328, -8.11388471513972],
              [-34.973006502483898, -8.113949302823183],
              [-34.972926145890789, -8.114027710141755],
              [-34.972873884168017, -8.114193401232852],
              [-34.972844697385376, -8.114365760764422],
              [-34.97280886199264, -8.114607130163179],
              [-34.972827692430428, -8.114692108345148],
              [-34.972888047224991, -8.114790626604512],
              [-34.972962270604015, -8.114886817563152],
              [-34.973066294659894, -8.114973642956743],
              [-34.973165672043784, -8.115019176574229],
              [-34.973223243314308, -8.11501202691495],
              [-34.973280826052161, -8.115007227675225],
              [-34.973352399288643, -8.115043674872105],
              [-34.973435640250209, -8.115091546702391],
              [-34.973493189453947, -8.115079876951857],
              [-34.973513739308501, -8.115033852529631],
              [-34.97354351856707, -8.115001524651841],
              [-34.973612678287729, -8.115008150451292],
              [-34.973705104101811, -8.115060588258444],
              [-34.973917287926383, -8.115096444076693],
              [-34.974042434018379, -8.115254404429299],
              [-34.974068932978874, -8.115497822009001],
              [-34.974005026038405, -8.115656608594845],
              [-34.973871692199133, -8.115716921165525],
              [-34.973684907484191, -8.115699294106349],
              [-34.97356720449492, -8.115672291754725],
              [-34.973235094732786, -8.115600132607895],
              [-34.973087432434667, -8.115568574345499],
              [-34.972944497706592, -8.115557695446466],
              [-34.972808737976308, -8.115604278166856],
              [-34.97273275000736, -8.115629868882422],
              [-34.97269151670195, -8.11567599351638],
              [-34.972643365271239, -8.11573589295903],
              [-34.972574383746228, -8.115784412120762],
              [-34.972480238310212, -8.115862976666156],
              [-34.972404688090677, -8.115959712555602],
              [-34.972363711550933, -8.116077073673056],
              [-34.972371009577003, -8.116159757268086],
              [-34.972378373914978, -8.116274624089781],
              [-34.972402144584969, -8.116423855045442],
              [-34.972432663011674, -8.116561572089079],
              [-34.972492928566261, -8.11664173893495],
              [-34.972560200660119, -8.11674483423009],
              [-34.972641251415354, -8.116827160396637],
              [-34.972727063315745, -8.11693703644265],
              [-34.972856791763, -8.117085844191644],
              [-34.972917248106853, -8.117205064190527],
              [-34.97296163545537, -8.117340453830179],
              [-34.972955311719303, -8.117475998869086],
              [-34.972909473113717, -8.117545108294632],
              [-34.972833665109967, -8.117589050088126],
              [-34.972748558259156, -8.117642258068505],
              [-34.972675130646536, -8.117709241120728],
              [-34.972588024957119, -8.117817604724571],
              [-34.97249640543734, -8.117930510356933],
              [-34.972429816188253, -8.117985979035346],
              [-34.972342328773173, -8.118016145677025],
              [-34.972273526423699, -8.118101458127502],
              [-34.972147153452887, -8.118175568337925],
              [-34.971947115420264, -8.118286648766835],
              [-34.971765694826637, -8.118439043612296],
              [-34.971584297331198, -8.118577606597094],
              [-34.97151101412441, -8.118692864164734],
              [-34.971506992621443, -8.118816916924628],
              [-34.971558061328572, -8.118908609634252],
              [-34.971645917440661, -8.118972551106612],
              [-34.971715156414753, -8.118995268470558],
              [-34.971802822746255, -8.119001714352779],
              [-34.971885604159766, -8.118973830653285],
              [-34.971933957586259, -8.118955244494083],
              [-34.972007677120992, -8.118948016622413],
              [-34.972127593464812, -8.118963617689257],
              [-34.972164581691388, -8.118995621987136],
              [-34.972178572761067, -8.119036958861024],
              [-34.972206432093309, -8.11907587803902],
              [-34.972273548763042, -8.119128438644983],
              [-34.972328930105462, -8.119155743212673],
              [-34.972368175970495, -8.119167034204978],
              [-34.972384514685992, -8.119206099606039],
              [-34.972396114311493, -8.119222135155955],
              [-34.972426073493679, -8.119226600508377],
              [-34.972458345172782, -8.119240185373943],
              [-34.972467811455139, -8.119283804240863],
              [-34.97245412234318, -8.119322924781406],
              [-34.972440522804476, -8.119380396739976],
              [-34.972397311772212, -8.119504639397789],
              [-34.972383746201693, -8.119569072240205],
              [-34.972361376481011, -8.119706955168532],
              [-34.972338725856147, -8.119805875673896],
              [-34.972311628932715, -8.119923169585052],
              [-34.972277479249321, -8.120026665990746],
              [-34.972273233908581, -8.120104794949127],
              [-34.972289359545002, -8.12010019662454],
              [-34.972314748248614, -8.120116165332389],
              [-34.972308021848626, -8.120150641562381],
              [-34.972278038949234, -8.120159917621278],
              [-34.972301283276948, -8.120201209624003],
              [-34.97232448259971, -8.120233280714427],
              [-34.972331679844231, -8.120295262431998],
              [-34.972325088038204, -8.120357310990384],
              [-34.972281629121689, -8.120449371320063],
              [-34.972279630366181, -8.120504526970835],
              [-34.972305075142678, -8.120531976609644],
              [-34.972247671188462, -8.120573569140536],
              [-34.972211029365688, -8.120631152799458],
              [-34.972204425377392, -8.120709293192769],
              [-34.972202739674408, -8.120847166273462],
              [-34.972214541467366, -8.120904605512054],
              [-34.972233181317719, -8.120950439989825],
              [-34.972214989821225, -8.12099645300821],
              [-34.972150567005116, -8.121031208921627],
              [-34.972136944916386, -8.12108407043443],
              [-34.972125715463491, -8.121143881397551],
              [-34.972110407674542, -8.121334615996178],
              [-34.972104107550905, -8.121456419675978],
              [-34.972088070749074, -8.121497811687133],
              [-34.972113684863928, -8.121541352277612],
              [-34.972113841968152, -8.121573535062694],
              [-34.972093190725744, -8.121598857670312],
              [-34.972063409831918, -8.121649537436225],
              [-34.972026836130041, -8.121702510210218],
              [-34.971999435254794, -8.121776140857245],
              [-34.971986027422737, -8.121854314238664],
              [-34.971972540344815, -8.121934838488633],
              [-34.971965936348909, -8.122012978889526],
              [-34.971982520325213, -8.12212083995003],
              [-34.972022170743571, -8.122214938300504],
              [-34.97208243690438, -8.122295014602754],
              [-34.97211240779987, -8.122301830351944],
              [-34.972140099272835, -8.12230630666593],
              [-34.972140244918982, -8.122336139021314],
              [-34.972126567215568, -8.122377610005707],
              [-34.972135988600883, -8.122412007955184],
              [-34.972156930046374, -8.122464701905038],
              [-34.972164194917703, -8.122540514980269],
              [-34.972143678261141, -8.12259340992844],
              [-34.972116402090961, -8.122674001032786],
              [-34.972100590606722, -8.122742964942113],
              [-34.972066553348732, -8.1228695136617],
              [-34.972037220822017, -8.123012040942895],
              [-34.972023935477573, -8.123133878490526],
              [-34.972017510726204, -8.123248721728549],
              [-34.971995375994887, -8.123453321128927],
              [-34.971930085993172, -8.123793644196578],
              [-34.971914867914137, -8.124002730230307],
              [-34.971895057197031, -8.124218799581303],
              [-34.971891618601127, -8.124480714387122],
              [-34.971891375123235, -8.124523747500961],
              [-34.97214264241444, -8.124536451178251],
              [-34.972654289897065, -8.124607377054554],
              [-34.973771726621301, -8.124762420706912],
              [-34.977493066408023, -8.12527862328686],
              [-34.979913680945394, -8.125614352976818],
              [-34.982717305919493, -8.126003164609841],
              [-34.983536318373787, -8.124689778722733],
              [-34.984700336111871, -8.122823163044766],
              [-34.985616638490548, -8.121353899265344],
              [-34.985629869913701, -8.121332680499245],
              [-34.986846731177643, -8.121268249136998],
              [-34.988075563118343, -8.121203213308682],
              [-34.989777897096289, -8.119341282375057],
              [-34.989839137218361, -8.118789619319269],
              [-34.989843400702775, -8.118752714335532],
              [-34.989971584518372, -8.117647009932346],
              [-34.990230602486619, -8.114647099609478],
              [-34.990708158184425, -8.114503647040154],
              [-34.990369419096027, -8.113102801968273],
              [-34.99019221195536, -8.112357939453162],
              [-34.990139400307513, -8.112359046455557],
              [-34.990139402044349, -8.112359047296856],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0227000',
        uf: 'PE',
        nome_proje: 'PA LAGOA COMPRIDA',
        municipio: 'BOM JARDIM',
        area_hecta: '233.5000',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 5.0,
        data_de_cr: '01/06/2001',
        forma_obte: 'Desapropriação',
        data_obten: '04/10/2000',
        area_calc_: 271.7439,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.485732432392354, -7.775620851187965],
              [-35.485776008031998, -7.775739266542885],
              [-35.487499121955665, -7.775912791882534],
              [-35.487431564975331, -7.780165613287929],
              [-35.487917061356377, -7.780250573212144],
              [-35.488640237573911, -7.780106376896414],
              [-35.490284580294393, -7.77972143780562],
              [-35.490950265370181, -7.778866151288602],
              [-35.490891617008529, -7.778379036232355],
              [-35.49215944844569, -7.777176123305389],
              [-35.492116743716423, -7.775299397419672],
              [-35.492363875304946, -7.7730019959248],
              [-35.492545474890335, -7.769702913251773],
              [-35.491826852832681, -7.769061137079953],
              [-35.490727799151401, -7.769057100547884],
              [-35.490670389649743, -7.769065146131907],
              [-35.487084212301198, -7.769027752849582],
              [-35.487299496840478, -7.770811294165175],
              [-35.485705901729702, -7.770858492904335],
              [-35.485838109829075, -7.771781453671618],
              [-35.485531278066176, -7.772833147654843],
              [-35.485415284116876, -7.773847583688706],
              [-35.485387791505367, -7.774658683309273],
              [-35.485732432392354, -7.775620851187965],
            ],
          ],
          [
            [
              [-35.485360080763499, -7.770866481912601],
              [-35.484072165527323, -7.771091007592753],
              [-35.484288941701386, -7.772575044117572],
              [-35.481077912614303, -7.772718359042793],
              [-35.481675008807208, -7.771322918311179],
              [-35.478033112122951, -7.771589014657713],
              [-35.477896285842185, -7.769611777307393],
              [-35.472224420513257, -7.769976781452943],
              [-35.472219619007141, -7.776772786988616],
              [-35.472197140791444, -7.778357332083259],
              [-35.473840429676308, -7.778260748241618],
              [-35.473976605115965, -7.780068794694166],
              [-35.4721543914007, -7.780202836372353],
              [-35.470264278617947, -7.780331156666102],
              [-35.470292267138028, -7.781376754383756],
              [-35.471201167528584, -7.781311295894742],
              [-35.471530839067817, -7.781972069621448],
              [-35.472372341724899, -7.781959518167124],
              [-35.473212161263341, -7.781918100006609],
              [-35.473208320640573, -7.782029618280793],
              [-35.473240196786094, -7.782716835074689],
              [-35.473319115067177, -7.785693902413454],
              [-35.47378284079273, -7.785673102915027],
              [-35.474772174875646, -7.785552993781643],
              [-35.475063484926181, -7.785552395309877],
              [-35.475520868153474, -7.785605919282766],
              [-35.475670544532989, -7.785555908137823],
              [-35.476090579990604, -7.785489839505588],
              [-35.476880627538996, -7.785516149580714],
              [-35.477306324990494, -7.785380530343504],
              [-35.478044341180222, -7.785038593714924],
              [-35.478376362090486, -7.784663079040861],
              [-35.478571333613331, -7.784521641142753],
              [-35.478947808064063, -7.784412481076563],
              [-35.479306436398311, -7.784358192229877],
              [-35.479527910823741, -7.784378270444594],
              [-35.479686010980949, -7.784411091565488],
              [-35.48099236948493, -7.783963811063713],
              [-35.481549096742881, -7.783647124189523],
              [-35.481859883548985, -7.783159514194792],
              [-35.482072483229913, -7.782473998282063],
              [-35.482271007179136, -7.782242209172036],
              [-35.482778654791765, -7.781921046212488],
              [-35.482771168948865, -7.781890082536577],
              [-35.483351507184615, -7.781382719674338],
              [-35.483203336727328, -7.781233936166369],
              [-35.483353026824965, -7.781082125111763],
              [-35.483511242058405, -7.781208617596036],
              [-35.483884811497845, -7.780663947748093],
              [-35.484144878984161, -7.779961236042723],
              [-35.484289370464779, -7.779139030429389],
              [-35.485167942530666, -7.778747135692048],
              [-35.484947006168809, -7.776534102833596],
              [-35.48537043840895, -7.776488478098029],
              [-35.485043132213207, -7.775846621914288],
              [-35.48536202949559, -7.775725156153763],
              [-35.485087685895351, -7.775043882062485],
              [-35.484904056645881, -7.774305368209666],
              [-35.48494725548229, -7.773484370028191],
              [-35.485088272885072, -7.772765664900479],
              [-35.485362900691875, -7.771792261004665],
              [-35.485360080763499, -7.770866481912601],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0069000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA GANGUNGO',
        municipio: 'JOAO ALFREDO',
        area_hecta: '200.0000',
        capacidade: 41.0,
        num_famili: 36.0,
        fase: 3.0,
        data_de_cr: '09/11/1995',
        forma_obte: 'Desapropriação',
        data_obten: '24/03/1995',
        area_calc_: 198.8839,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.681714117310307, -7.878550980577577],
              [-35.680003560247954, -7.879161864968728],
              [-35.680079602098836, -7.880428290264765],
              [-35.679764128396293, -7.880526043399906],
              [-35.678737502411337, -7.880873425816966],
              [-35.677703342755613, -7.881191478955773],
              [-35.676877650073344, -7.881231167960204],
              [-35.677395007256514, -7.879786087435647],
              [-35.676043221402132, -7.879997061594652],
              [-35.675727479617962, -7.881414804283543],
              [-35.675068978698505, -7.881614956126826],
              [-35.675757953728493, -7.883265215116081],
              [-35.677914321424289, -7.882856010724351],
              [-35.678856619194562, -7.885068550685602],
              [-35.678256467661996, -7.885329323734493],
              [-35.676881432598165, -7.885899481097506],
              [-35.680837153299613, -7.895029263820963],
              [-35.682441270790598, -7.898623831359961],
              [-35.682726061998196, -7.898524250397174],
              [-35.683895157116261, -7.901019033003234],
              [-35.685232641396816, -7.900585977162814],
              [-35.687784844415795, -7.899671632704007],
              [-35.690445670729957, -7.898724449854774],
              [-35.691325639359597, -7.897751164557221],
              [-35.690427573998399, -7.896980838450723],
              [-35.689035884959296, -7.895444880861795],
              [-35.687740482804529, -7.896439917937603],
              [-35.687382466617038, -7.894783138048254],
              [-35.687140052277563, -7.893631826924978],
              [-35.686785911408791, -7.892016505472105],
              [-35.686314545540519, -7.889886012145145],
              [-35.685967133823965, -7.888265115857758],
              [-35.685601163993276, -7.886623367143429],
              [-35.685271708520474, -7.885042196106202],
              [-35.683947826994157, -7.879245890510391],
              [-35.682133239834734, -7.879796334872722],
              [-35.681714117310307, -7.878550980577577],
            ],
          ],
          [
            [
              [-35.67471591904026, -7.878103130403897],
              [-35.673722575581436, -7.878235924387599],
              [-35.671829755915347, -7.878527792911065],
              [-35.672330779511867, -7.879495761326519],
              [-35.671158385570124, -7.880037403743358],
              [-35.671544922878773, -7.880807723963073],
              [-35.674092096352823, -7.880305145396681],
              [-35.675824717569718, -7.880020839345239],
              [-35.67471591904026, -7.878103130403897],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0405000',
        uf: 'PE',
        nome_proje: 'PA ARAGUARI',
        municipio: 'BARREIROS',
        area_hecta: '636.4645',
        capacidade: 70.0,
        num_famili: 68.0,
        fase: 3.0,
        data_de_cr: '09/09/2013',
        forma_obte: 'Desapropriação',
        data_obten: '16/09/2004',
        area_calc_: 637.4658,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.350470523752485, -8.832466028461594],
              [-35.348109315266825, -8.837131851672565],
              [-35.343561811750803, -8.844350900584855],
              [-35.341952084533851, -8.847050575206993],
              [-35.339769178968112, -8.85801933612515],
              [-35.339343979545419, -8.861539001052071],
              [-35.338667121143622, -8.86559535265647],
              [-35.338579625555973, -8.86936894853908],
              [-35.337037600433931, -8.875508877454566],
              [-35.337040815138067, -8.875508505747179],
              [-35.341400722700158, -8.87315363341037],
              [-35.341863698800616, -8.872883990433076],
              [-35.343799240831544, -8.871865049538171],
              [-35.344464054292324, -8.871491005194455],
              [-35.345019318312545, -8.871178357636213],
              [-35.346486922909079, -8.870434660733213],
              [-35.348371865534887, -8.869334790319618],
              [-35.348762821808371, -8.869074281312235],
              [-35.348938151266502, -8.868927929481035],
              [-35.350009041423952, -8.867857092667698],
              [-35.350270828118788, -8.867605938913554],
              [-35.350391767512832, -8.867476380629506],
              [-35.351301176079311, -8.866576356647251],
              [-35.355483893414345, -8.862447476269292],
              [-35.354889313026852, -8.859085568929681],
              [-35.354796410248845, -8.858558276792234],
              [-35.354224257459798, -8.856577010377052],
              [-35.353919017766835, -8.855572477181841],
              [-35.353878946871298, -8.855487339685094],
              [-35.353735156870187, -8.854560986085616],
              [-35.353700690281684, -8.854112297836956],
              [-35.35367112710211, -8.853910758542725],
              [-35.353692732383188, -8.853638416658347],
              [-35.35364634693709, -8.853379384614074],
              [-35.353546117614457, -8.852599601185611],
              [-35.353476641530207, -8.852167725562825],
              [-35.353393873452923, -8.851713598109443],
              [-35.353358176020173, -8.85150419152184],
              [-35.353341970004507, -8.851444477422744],
              [-35.353322799063662, -8.851398839047175],
              [-35.35332121706081, -8.851286693098544],
              [-35.353294113376876, -8.851007879465149],
              [-35.353225282711023, -8.850532366635012],
              [-35.353126535391205, -8.849994626772238],
              [-35.353093840305618, -8.849834871338757],
              [-35.353048567301457, -8.849506504231806],
              [-35.353036121857016, -8.84942313912941],
              [-35.353044096402044, -8.849301369706039],
              [-35.353130427182286, -8.848931826335011],
              [-35.353262480028285, -8.84851487917841],
              [-35.353425400757139, -8.84809678118101],
              [-35.35348841208716, -8.847937894147705],
              [-35.353516031545297, -8.847852357619171],
              [-35.353923318398842, -8.847298825025867],
              [-35.36717877624249, -8.840377811675575],
              [-35.359330021365352, -8.836800875894884],
              [-35.358150224245229, -8.836257339328297],
              [-35.357711113303914, -8.836050169328043],
              [-35.357145205078183, -8.835850250035655],
              [-35.356660389209381, -8.835667809778821],
              [-35.356548412255577, -8.835607929299023],
              [-35.350474745606178, -8.832455308340336],
              [-35.350470523752485, -8.832466028461594],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0117000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA SECA',
        municipio: 'CARUARU',
        area_hecta: '409.1000',
        capacidade: 25.0,
        num_famili: 25.0,
        fase: 6.0,
        data_de_cr: '22/10/1997',
        forma_obte: 'Desapropriação',
        data_obten: '26/03/1997',
        area_calc_: 409.1364,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.021290482518033, -8.107965026393183],
              [-36.020448561442322, -8.108773851203321],
              [-36.020177151131698, -8.109044609553246],
              [-36.018931854802169, -8.110328478386885],
              [-36.01763098896275, -8.111671241749953],
              [-36.01606959693067, -8.113280002660023],
              [-36.014624315273934, -8.114274034122666],
              [-36.013939600892925, -8.114514660033326],
              [-36.013376127480988, -8.114631901432418],
              [-36.012846092857998, -8.114682193919975],
              [-36.012276141986135, -8.114682725703126],
              [-36.011252614933831, -8.114676082904428],
              [-36.010394361816154, -8.114716593075997],
              [-36.010028852866007, -8.114776391634035],
              [-36.009714541323333, -8.114850243704124],
              [-36.008552100768668, -8.115180311960078],
              [-36.006240641321746, -8.115876789748347],
              [-36.005683765772829, -8.116152982121056],
              [-36.005197981954289, -8.116470215985277],
              [-36.002289198164817, -8.118693615157314],
              [-36.000564908481039, -8.120011004214181],
              [-35.99978678655674, -8.120564234655982],
              [-35.999244743875963, -8.120859383123006],
              [-35.998686463115298, -8.121089183187673],
              [-35.997094859611835, -8.121613360628539],
              [-35.996695569779142, -8.121848268064307],
              [-35.996541144678254, -8.121995532118433],
              [-35.996389725885223, -8.122205709693034],
              [-35.996240230169505, -8.122526834682253],
              [-35.996051548864358, -8.123092400230149],
              [-35.996590317639175, -8.123385899861301],
              [-35.996930209482201, -8.123553026795395],
              [-35.997088550447266, -8.123637060426043],
              [-35.997647401845427, -8.123935849779617],
              [-35.998031287173113, -8.124118824360526],
              [-35.998064820788287, -8.124154533935823],
              [-35.998294103558784, -8.124238498003422],
              [-35.998778886561489, -8.124444622675313],
              [-35.999300799577377, -8.124676047374674],
              [-35.999559560489622, -8.124790665754045],
              [-36.000272537689703, -8.125115604811706],
              [-36.00077673591597, -8.125334486507093],
              [-36.001759909895348, -8.125756300154832],
              [-36.002636959280579, -8.126121334565104],
              [-36.002834479437432, -8.126201015818232],
              [-36.003080212502759, -8.126308696858347],
              [-36.006016599288756, -8.12764836203192],
              [-36.006736788883067, -8.127992874254415],
              [-36.00697889044622, -8.12808458831581],
              [-36.007814347036884, -8.128379388046893],
              [-36.008238334896951, -8.12852608810211],
              [-36.009678779719216, -8.129028066659664],
              [-36.010215110372037, -8.12922214587187],
              [-36.011561249734569, -8.129718783990128],
              [-36.012909046541679, -8.130217414866861],
              [-36.013544387702595, -8.130450236468517],
              [-36.013646483168571, -8.130487649828229],
              [-36.013987193154335, -8.130612505053776],
              [-36.014547396343616, -8.130815282002716],
              [-36.015222483543262, -8.13106820059051],
              [-36.015789828861863, -8.131281052473442],
              [-36.017042269161593, -8.131774777838961],
              [-36.017589536659813, -8.131966651253572],
              [-36.017672874684912, -8.131976041268848],
              [-36.017640547347483, -8.131578196716646],
              [-36.017623506650395, -8.131314677181477],
              [-36.017661880095496, -8.131010267500812],
              [-36.017662829532085, -8.130912994102154],
              [-36.017661635169105, -8.130697240582629],
              [-36.017680010882408, -8.130448010200633],
              [-36.017701737735685, -8.13028657879229],
              [-36.017733359292862, -8.130147376843226],
              [-36.017812754442069, -8.129868386494033],
              [-36.01783891396834, -8.129712845925788],
              [-36.017842337717198, -8.129548613534983],
              [-36.01785276383599, -8.129416242360161],
              [-36.017875736490453, -8.12923227382127],
              [-36.017870959680074, -8.129022415481913],
              [-36.017905591125647, -8.128682365756269],
              [-36.017937732787004, -8.12831393814035],
              [-36.017944394241347, -8.127992769859304],
              [-36.017918971428976, -8.127685565516185],
              [-36.017924228050603, -8.127353232023014],
              [-36.017937875527025, -8.126640203560088],
              [-36.017948835817919, -8.126036436236358],
              [-36.017980793747711, -8.125699592544457],
              [-36.018028964461827, -8.125438042972844],
              [-36.018053988889115, -8.124935324856898],
              [-36.018173769049739, -8.124330412731418],
              [-36.018272469480358, -8.124020535160215],
              [-36.018436448125449, -8.123551908157177],
              [-36.018505242608924, -8.123149727055816],
              [-36.018594475372126, -8.122790424598064],
              [-36.018669449797081, -8.122503469383059],
              [-36.018747452277509, -8.122229917252364],
              [-36.01882295546195, -8.122030714622754],
              [-36.018905734676643, -8.121738015816366],
              [-36.018931136215087, -8.121592839383148],
              [-36.018949821209496, -8.121486052835653],
              [-36.018958136036623, -8.121438532824662],
              [-36.018996007646621, -8.121280283778072],
              [-36.019023723757613, -8.120963591500082],
              [-36.019067464658711, -8.120777115450036],
              [-36.019154238645271, -8.120543257423483],
              [-36.019258884900758, -8.120165998743651],
              [-36.019476981403635, -8.119548507309897],
              [-36.019588520726145, -8.119214675232511],
              [-36.019657193851238, -8.118966952044676],
              [-36.01987322293084, -8.118337816640491],
              [-36.020129032336094, -8.117564654520375],
              [-36.020312541575372, -8.116979077247139],
              [-36.020440856093181, -8.116608726543609],
              [-36.020562666410754, -8.11630355570114],
              [-36.020594269727034, -8.116197221737368],
              [-36.020703566117589, -8.115583002956345],
              [-36.020775178285675, -8.115208176668403],
              [-36.020783314701632, -8.115029170004901],
              [-36.020298363738036, -8.114837094349225],
              [-36.020220369192906, -8.114815006138478],
              [-36.020161513718534, -8.114696549085515],
              [-36.020010315285639, -8.114385193968699],
              [-36.019871779851741, -8.114115920372754],
              [-36.019910495236815, -8.113927007339646],
              [-36.020160471698581, -8.113697554126396],
              [-36.020287709811043, -8.113553114466823],
              [-36.020522723755114, -8.113133057338548],
              [-36.020662165963962, -8.112770475218264],
              [-36.0206405882873, -8.112465161781909],
              [-36.020656021936908, -8.112164670537032],
              [-36.020612012483724, -8.112025836771856],
              [-36.020430585788617, -8.111799406235345],
              [-36.020230418470703, -8.111553593011811],
              [-36.020119023450128, -8.111451808197351],
              [-36.020115567165085, -8.111405071028534],
              [-36.020154244883862, -8.111368302664031],
              [-36.020381749267635, -8.111378959119055],
              [-36.020720782861936, -8.111314489824052],
              [-36.020846983862057, -8.111050605161683],
              [-36.020990491858036, -8.111028489039203],
              [-36.021006491047459, -8.110670725695954],
              [-36.021024819428689, -8.110606433731874],
              [-36.0211533284265, -8.110453857074704],
              [-36.021414970315412, -8.110377073413932],
              [-36.021458851295243, -8.11026564915179],
              [-36.021560818209373, -8.109915321693913],
              [-36.021621436602047, -8.109721838385004],
              [-36.021654662743686, -8.109577906218009],
              [-36.021692840485329, -8.109365998921369],
              [-36.02159563550466, -8.109262416171942],
              [-36.021473749284183, -8.109091826074126],
              [-36.021421173635026, -8.109018241903412],
              [-36.021342546262311, -8.108908197118931],
              [-36.021308271749696, -8.108542501699135],
              [-36.021316283882115, -8.108210820008447],
              [-36.021290482518033, -8.107965026393183],
            ],
          ],
          [
            [
              [-36.024050871512202, -8.096969671164654],
              [-36.023749664458826, -8.096926080621115],
              [-36.023464669808916, -8.096936381509146],
              [-36.023009231927787, -8.096982989304262],
              [-36.022589934625884, -8.097086020334578],
              [-36.022404806167515, -8.097099177052934],
              [-36.02231934773706, -8.097095695770088],
              [-36.021973237854667, -8.09716643567573],
              [-36.021345688824887, -8.097376447934945],
              [-36.020717692535904, -8.097605360054754],
              [-36.020028244914968, -8.097856573879083],
              [-36.019964406990312, -8.097875088031929],
              [-36.019822164998139, -8.097932935163504],
              [-36.019523609347225, -8.098048024433721],
              [-36.019692248137652, -8.098585942275255],
              [-36.019935275768297, -8.099146705994603],
              [-36.020054098240273, -8.099420881119107],
              [-36.020330556061396, -8.100083985987183],
              [-36.020559744960458, -8.100673894872923],
              [-36.02057265172801, -8.100967162875962],
              [-36.020485652659197, -8.101452976243644],
              [-36.02026232813418, -8.102698402176664],
              [-36.020100252317775, -8.103126241327274],
              [-36.019989037659023, -8.103419819789263],
              [-36.019615975714856, -8.104394439228615],
              [-36.019397625078412, -8.104936807607867],
              [-36.019363856891061, -8.105020685482732],
              [-36.01925291107576, -8.104977456888687],
              [-36.019085535556997, -8.104903007068677],
              [-36.018919313498301, -8.104830087725301],
              [-36.018804745446211, -8.104794019955532],
              [-36.018596486268102, -8.10474764717827],
              [-36.018149053551895, -8.10464725930559],
              [-36.017971360593606, -8.104761919357244],
              [-36.017640195848216, -8.104983038676487],
              [-36.017540219100034, -8.105053654641154],
              [-36.017477920836583, -8.105097656456332],
              [-36.017381967054767, -8.105165430801819],
              [-36.01688637456364, -8.105448117256055],
              [-36.017187361808041, -8.105860389900823],
              [-36.017344757983089, -8.106167817893018],
              [-36.017480681816359, -8.106580925360914],
              [-36.017589231930422, -8.106911773276579],
              [-36.017656863910133, -8.107134889189139],
              [-36.01779608331163, -8.107611270802066],
              [-36.017845162388852, -8.107777348080955],
              [-36.018052991836342, -8.10812538687024],
              [-36.018174108824802, -8.108240260429342],
              [-36.018398140947163, -8.108429507483889],
              [-36.018521965298412, -8.108535681455415],
              [-36.018821129952222, -8.109034373954536],
              [-36.018940785850887, -8.109279503583087],
              [-36.019661848006947, -8.108537902006075],
              [-36.019831767922014, -8.108362961153658],
              [-36.021377882572487, -8.106884468127586],
              [-36.021453980817519, -8.106823139267641],
              [-36.022014728779666, -8.106371212759747],
              [-36.022315411583044, -8.106109086631939],
              [-36.022715342089967, -8.105709011162146],
              [-36.023092396218466, -8.105320644809778],
              [-36.023280355767128, -8.105106258472802],
              [-36.023483568147732, -8.104847470268078],
              [-36.023868407654604, -8.104352355987112],
              [-36.024637160705176, -8.103354288290479],
              [-36.025091708178294, -8.102810450600783],
              [-36.025638289672777, -8.102301787794357],
              [-36.025965551790122, -8.102056263690566],
              [-36.026230543021846, -8.101879359925295],
              [-36.02680113018662, -8.101517760974321],
              [-36.027124243202017, -8.101311853391948],
              [-36.027445231564741, -8.10109403198617],
              [-36.027644957437126, -8.100938381090037],
              [-36.027936167139153, -8.100679018269993],
              [-36.02830834400234, -8.100307067756686],
              [-36.028655917170525, -8.099961428519201],
              [-36.02898194885838, -8.099635826991092],
              [-36.029332078245957, -8.099286731107318],
              [-36.028993429853365, -8.099337105447102],
              [-36.028313807754834, -8.099019274358749],
              [-36.028168215332109, -8.098955555745331],
              [-36.027944182196684, -8.098873413184606],
              [-36.027455326719512, -8.098726544051127],
              [-36.027105996893987, -8.098619351475726],
              [-36.026777684553487, -8.098510035297092],
              [-36.026592313441363, -8.098447216003104],
              [-36.026284588454388, -8.09831947552239],
              [-36.026095583550806, -8.098205973944586],
              [-36.025951724636023, -8.098081313006499],
              [-36.025629701883211, -8.097835860924606],
              [-36.02510926144555, -8.097503040573743],
              [-36.024937725608801, -8.097392074283499],
              [-36.024876847614408, -8.097340195846421],
              [-36.024650420272351, -8.097199935365497],
              [-36.024318761711775, -8.097064422147447],
              [-36.024050871512202, -8.096969671164654],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0341000',
        uf: 'PE',
        nome_proje: 'PA VALENTES DE GIDEÕES',
        municipio: 'AGUA PRETA',
        area_hecta: '394.8559',
        capacidade: 48.0,
        num_famili: 43.0,
        fase: 3.0,
        data_de_cr: '12/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '19/04/2005',
        area_calc_: 392.9369,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.382777527119991, -8.740350638026205],
              [-35.383249700387267, -8.739827908945625],
              [-35.384288879592226, -8.739033260745199],
              [-35.38483750144924, -8.738372953553879],
              [-35.385132823698726, -8.736923890404301],
              [-35.385759112999537, -8.736484062773386],
              [-35.386525114240143, -8.735428357697964],
              [-35.387141723987938, -8.734422557365461],
              [-35.388078937010626, -8.733668671284136],
              [-35.391031256320069, -8.731749007827553],
              [-35.39107491698816, -8.731753740877245],
              [-35.399786435244152, -8.724195732403421],
              [-35.405662387270901, -8.723702765573721],
              [-35.406188022627425, -8.723673637810654],
              [-35.396847984903708, -8.719280640699326],
              [-35.393743804639172, -8.718848766481326],
              [-35.393423790090281, -8.719809978357054],
              [-35.393337657161098, -8.719977808243783],
              [-35.393123597106985, -8.720102277647015],
              [-35.392807714325095, -8.720133162219042],
              [-35.391746805304663, -8.719783854602651],
              [-35.391178026773296, -8.71950338391653],
              [-35.39051872840853, -8.719346138885131],
              [-35.390461741351679, -8.719407968922969],
              [-35.39035802810043, -8.719413200438364],
              [-35.389893648185357, -8.719494209498572],
              [-35.389459052599612, -8.71960232434871],
              [-35.388222997222684, -8.719464595507507],
              [-35.386562504136393, -8.719361253040461],
              [-35.386074095395031, -8.719492206357813],
              [-35.385729431548953, -8.719644307809931],
              [-35.38518007113548, -8.720147363746769],
              [-35.384871991617196, -8.72048215786144],
              [-35.384376472857262, -8.720767066286276],
              [-35.384091293280129, -8.720931716984241],
              [-35.383530026758443, -8.721105060349746],
              [-35.383354505976989, -8.721272908241021],
              [-35.383002988362747, -8.721533216208615],
              [-35.38239849807109, -8.722332110967423],
              [-35.382159734733051, -8.722548615042895],
              [-35.38169060960827, -8.72277766576865],
              [-35.381121993949421, -8.722811729569292],
              [-35.380646571665245, -8.722889439390771],
              [-35.380516300221295, -8.722926816036656],
              [-35.379908660465148, -8.723270719895087],
              [-35.379643422932723, -8.723501128237068],
              [-35.379303638044007, -8.723894050489442],
              [-35.37904710225002, -8.724236381605996],
              [-35.378758322676042, -8.725712337479292],
              [-35.378695177343431, -8.726398607307196],
              [-35.378579388914204, -8.72674746700295],
              [-35.378370781457313, -8.727056058123621],
              [-35.378316802388348, -8.727095710736492],
              [-35.376580883683665, -8.728350187006219],
              [-35.376433789543533, -8.728593185785334],
              [-35.376471778133535, -8.72891873712593],
              [-35.376416582956487, -8.729617464391136],
              [-35.376039717767419, -8.730125792442252],
              [-35.375576086227447, -8.730275828381508],
              [-35.374844632770575, -8.730416428431878],
              [-35.374372632785615, -8.730531181423217],
              [-35.37393634580954, -8.730719910365828],
              [-35.373364348132156, -8.731110307650603],
              [-35.372658792769805, -8.731712662589681],
              [-35.372352114656799, -8.732301133314333],
              [-35.372303721363281, -8.732276220752277],
              [-35.372258026567046, -8.732922436502728],
              [-35.372571279113309, -8.733847751582617],
              [-35.371744905783942, -8.735528527317198],
              [-35.37111897434157, -8.736398625091928],
              [-35.370846133567426, -8.736420034622226],
              [-35.371153912617288, -8.738160321786452],
              [-35.372175900447481, -8.739830159967052],
              [-35.372328989379469, -8.74005016161772],
              [-35.373052284807258, -8.73973311056392],
              [-35.373808505652342, -8.739062692200019],
              [-35.37405921135656, -8.738881416261615],
              [-35.374252184774285, -8.738641790754757],
              [-35.374444497593721, -8.738297873607104],
              [-35.374741360112914, -8.737938388844185],
              [-35.374829782769609, -8.737714330863971],
              [-35.375038596235029, -8.737623638541145],
              [-35.375183592011403, -8.737511113322251],
              [-35.37556185454423, -8.736966476682065],
              [-35.375691516467853, -8.736785179834028],
              [-35.375943877593173, -8.736470710589707],
              [-35.376135810773924, -8.736067236852888],
              [-35.37622395260663, -8.735813356048565],
              [-35.376236864204749, -8.735485475920823],
              [-35.376445204263938, -8.735320315148353],
              [-35.376593112512872, -8.735051147296966],
              [-35.376665794197528, -8.734722892432451],
              [-35.376769112478058, -8.734498741058404],
              [-35.377096418553805, -8.734213626238786],
              [-35.377262155350877, -8.734088150556367],
              [-35.377526696932357, -8.73423667812521],
              [-35.378835964065367, -8.73645726535141],
              [-35.379589978531243, -8.735819438214271],
              [-35.380300201592242, -8.734700773024128],
              [-35.380500466649274, -8.734543065567804],
              [-35.381513533468052, -8.736280479118701],
              [-35.381334495416112, -8.738570677118776],
              [-35.381052997511432, -8.738538722904334],
              [-35.38090276671614, -8.73851916434627],
              [-35.38174946622938, -8.740846204756508],
              [-35.382165718423707, -8.741510264795911],
              [-35.382777527119991, -8.740350638026205],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0065000',
        uf: 'PE',
        nome_proje: 'PA PERSEVERANÇA/CUMBE',
        municipio: 'GRAVATA',
        area_hecta: '513.0000',
        capacidade: 50.0,
        num_famili: 31.0,
        fase: 6.0,
        data_de_cr: '02/08/1995',
        forma_obte: 'Desapropriação',
        data_obten: '22/08/1994',
        area_calc_: 247.8019,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.564166912492297, -8.295814460916649],
              [-35.563904472856265, -8.296153555463418],
              [-35.563893360456284, -8.296725107747731],
              [-35.564022406794102, -8.297036377365139],
              [-35.56452309704995, -8.297524501103641],
              [-35.564633839577944, -8.297625009749535],
              [-35.564852865610511, -8.297773323152796],
              [-35.565001482216211, -8.2978377384546],
              [-35.565263355607016, -8.298063800595214],
              [-35.565407261557603, -8.298217869290269],
              [-35.565520792328407, -8.298421689941526],
              [-35.565525195669018, -8.298773828233893],
              [-35.565410390267935, -8.299188943489781],
              [-35.56533026265231, -8.299564822922131],
              [-35.565288934942316, -8.299721139050162],
              [-35.565077308380452, -8.299897531189428],
              [-35.564983202574652, -8.299913953209908],
              [-35.564825140521243, -8.299862251662473],
              [-35.564667270124147, -8.299840071782064],
              [-35.564367210878764, -8.299928464231749],
              [-35.564059530689867, -8.300065934806865],
              [-35.563906323912093, -8.300273054950015],
              [-35.563639944295481, -8.300820945307978],
              [-35.563558544835445, -8.300919527886046],
              [-35.563372095610696, -8.301060962467909],
              [-35.56309548853956, -8.301337939678042],
              [-35.562911094415654, -8.301469871381368],
              [-35.562402183214147, -8.301673481656211],
              [-35.562232040409157, -8.301717806757592],
              [-35.562028857455417, -8.30172754942328],
              [-35.561840268214553, -8.301702403079293],
              [-35.561400862083737, -8.30168730545717],
              [-35.561142219489923, -8.301796517538888],
              [-35.561069570536738, -8.301857085352355],
              [-35.56098694465458, -8.302011558456254],
              [-35.560857171157323, -8.302404628038923],
              [-35.560768707666952, -8.302801649503415],
              [-35.560680874143749, -8.303459102470889],
              [-35.560530290785202, -8.303744757743479],
              [-35.560888862277068, -8.303863706397607],
              [-35.560993848814007, -8.30389255398512],
              [-35.56125642048729, -8.303899299692812],
              [-35.561844619221588, -8.304005171400069],
              [-35.563003367905431, -8.301823553477835],
              [-35.564475511158847, -8.301120284089789],
              [-35.564579039825659, -8.305163209572079],
              [-35.564885771936225, -8.305286705478592],
              [-35.563807814020976, -8.308171087500387],
              [-35.562666047696212, -8.305957891837513],
              [-35.561261256435827, -8.307418836613445],
              [-35.561082736702424, -8.307314546318556],
              [-35.561064845809376, -8.307493908511679],
              [-35.560950074858368, -8.307751919497374],
              [-35.560900331758717, -8.307916725206294],
              [-35.560890512441709, -8.308034880590963],
              [-35.560859563223133, -8.308159498353263],
              [-35.560841648558231, -8.308661505433333],
              [-35.560861441299224, -8.308775252629955],
              [-35.560894793388123, -8.308857807894602],
              [-35.560960998166742, -8.308945950825574],
              [-35.56108913157184, -8.30903422224171],
              [-35.561255339651474, -8.309198692026213],
              [-35.561298256458386, -8.309286984808555],
              [-35.561338148186636, -8.30939849372302],
              [-35.561361349489054, -8.309548068276413],
              [-35.561395841233143, -8.309806172704047],
              [-35.561498669012579, -8.3101545149231],
              [-35.561595074930516, -8.310328923437057],
              [-35.561753137169113, -8.310543001742049],
              [-35.561919955538784, -8.310801308063912],
              [-35.561964102469354, -8.310915952585743],
              [-35.562004521833479, -8.311108646216317],
              [-35.562026740437155, -8.311269825271324],
              [-35.562059393998254, -8.311407740450413],
              [-35.562063198155315, -8.311830527681723],
              [-35.562008115556218, -8.312232606438172],
              [-35.56193792897681, -8.312591025199373],
              [-35.56327048126105, -8.311985651442848],
              [-35.563275710765517, -8.311894412788076],
              [-35.563232810450323, -8.311645852696191],
              [-35.563149565017206, -8.311542004375843],
              [-35.563066162095872, -8.311413906016581],
              [-35.563062620326889, -8.311194615189313],
              [-35.56297304428076, -8.311093970763167],
              [-35.563022597697987, -8.311063074031962],
              [-35.563058246066632, -8.311010124672176],
              [-35.563085638330463, -8.310989914676469],
              [-35.563114976468043, -8.310943332333155],
              [-35.563270936864406, -8.310833724787706],
              [-35.563524016844831, -8.310682369934101],
              [-35.563641992429261, -8.310591986180597],
              [-35.563682899184229, -8.310533730941476],
              [-35.563720082250519, -8.310391148340136],
              [-35.563726902995889, -8.310300426653789],
              [-35.563719805016987, -8.310185543665142],
              [-35.56373224732404, -8.310145396567954],
              [-35.563765935986162, -8.31011671083893],
              [-35.564431436073363, -8.311039230897098],
              [-35.566970030446072, -8.313004051816472],
              [-35.567152959368073, -8.313785227639457],
              [-35.567170052266412, -8.31771166240673],
              [-35.567330995814231, -8.317716949034784],
              [-35.567437072337079, -8.317750004172238],
              [-35.56748583513302, -8.317760233021398],
              [-35.567559866232614, -8.31774921083742],
              [-35.567644840072802, -8.317792946145138],
              [-35.567706628447937, -8.317852647068614],
              [-35.567801047029086, -8.31788366863409],
              [-35.568003295330065, -8.317891851234677],
              [-35.568095583052234, -8.317920777716262],
              [-35.568172212054421, -8.317983545799562],
              [-35.568189382679861, -8.318019284035215],
              [-35.568195005217795, -8.318069858325472],
              [-35.56819643469499, -8.318126786056363],
              [-35.568215626299555, -8.318147749788281],
              [-35.568250559060857, -8.318147524019107],
              [-35.568298647990744, -8.318216802841734],
              [-35.568426521167751, -8.318345666089076],
              [-35.56847656242082, -8.318389626931278],
              [-35.568537093208242, -8.318418758541194],
              [-35.568596414285899, -8.318424701421932],
              [-35.568652449730642, -8.318413795317651],
              [-35.568706326819715, -8.318396576838859],
              [-35.568758031791603, -8.318370937293627],
              [-35.568805557452336, -8.318353759864451],
              [-35.568868954765669, -8.318335425443815],
              [-35.568963112381027, -8.318326381632657],
              [-35.569056403811977, -8.318346866257716],
              [-35.569120158411721, -8.318383357629152],
              [-35.56916712022619, -8.318442099697426],
              [-35.569214322561471, -8.31853774376728],
              [-35.569252692168931, -8.318577562392385],
              [-35.56930364091243, -8.318598320718886],
              [-35.569375754159964, -8.318617887783191],
              [-35.569422269330055, -8.318608097525756],
              [-35.569465656903354, -8.318605708191555],
              [-35.569510068677438, -8.318598040299024],
              [-35.569569382879962, -8.318602928680006],
              [-35.569628875787203, -8.318635229958897],
              [-35.5696513256635, -8.318668825159817],
              [-35.569655924208284, -8.318724677939734],
              [-35.569620386492822, -8.318794497299054],
              [-35.569583728338898, -8.318854834421579],
              [-35.569485374097795, -8.318950892491449],
              [-35.569401193588433, -8.319110122124306],
              [-35.569380475785465, -8.319179845654002],
              [-35.569355454969887, -8.319239053140803],
              [-35.569369704357598, -8.3193138224911],
              [-35.569411648421905, -8.319414772424437],
              [-35.56948324625067, -8.319517639345932],
              [-35.569592952686747, -8.319620259776388],
              [-35.569707642700337, -8.319675400514647],
              [-35.569754336704868, -8.319693023086375],
              [-35.569824408639541, -8.319724201467281],
              [-35.56986152738537, -8.319734505250517],
              [-35.569948350855945, -8.319737106805496],
              [-35.569983194332977, -8.319723174396762],
              [-35.570016044412043, -8.319728233831309],
              [-35.570043725396737, -8.319752305669885],
              [-35.570057713698233, -8.319787009949454],
              [-35.570029345399753, -8.31981987943705],
              [-35.569979764407222, -8.31984655982458],
              [-35.56994382109874, -8.319854173036706],
              [-35.569892157327509, -8.319886138827096],
              [-35.569871185261675, -8.319916851693598],
              [-35.569880018198575, -8.319972677063333],
              [-35.569944092828386, -8.32005819511248],
              [-35.570023035625269, -8.320150997674597],
              [-35.570041381998657, -8.320204652706757],
              [-35.570039649870836, -8.320263709569206],
              [-35.570030686505007, -8.320350227257162],
              [-35.57001944098532, -8.320411454437455],
              [-35.569976596396927, -8.32049713692443],
              [-35.569933538664053, -8.320550134799033],
              [-35.569875530248552, -8.32058319604514],
              [-35.569790851232028, -8.32058479820034],
              [-35.569660447293565, -8.320555064497524],
              [-35.569591488756949, -8.32053231402338],
              [-35.56953455946644, -8.320487343602585],
              [-35.569492161528629, -8.320479182726288],
              [-35.569442951599093, -8.32048160978381],
              [-35.569409200973702, -8.320500807041187],
              [-35.569390383789425, -8.320537305092749],
              [-35.569396577148602, -8.320594201946871],
              [-35.569435699727173, -8.320668283203281],
              [-35.569477719628928, -8.320699643035766],
              [-35.569631429448521, -8.320731862008797],
              [-35.569699862170289, -8.320755143054562],
              [-35.569786005441259, -8.320815740259516],
              [-35.569897506392635, -8.320868792687769],
              [-35.570034640064883, -8.320956474005422],
              [-35.570112699703941, -8.320994981288038],
              [-35.570216653176502, -8.321026994695497],
              [-35.570339489077611, -8.321032526245801],
              [-35.570382808104029, -8.321019593315118],
              [-35.570420848014756, -8.321008803311678],
              [-35.570482045886386, -8.320977830121631],
              [-35.570573708555436, -8.320910810659278],
              [-35.570644357901706, -8.320868178035733],
              [-35.570698235123878, -8.320850959212748],
              [-35.570877048075559, -8.320836622227064],
              [-35.570976938817203, -8.320813833657642],
              [-35.5710909792666, -8.320769338583149],
              [-35.571300885468496, -8.320653051957077],
              [-35.571424339456094, -8.320591098521399],
              [-35.571528938600551, -8.320559844278284],
              [-35.571671439919221, -8.320496713100814],
              [-35.571837099025529, -8.320413398653898],
              [-35.572023359432293, -8.320404812123384],
              [-35.572158946013225, -8.3204176412926],
              [-35.572277526638203, -8.320420036681963],
              [-35.572405744500138, -8.320439239787325],
              [-35.572466062410186, -8.320435686054003],
              [-35.572523094485611, -8.320415283443305],
              [-35.572582161100428, -8.320382215038897],
              [-35.572719155867851, -8.320286433289352],
              [-35.57280326334282, -8.320197320319885],
              [-35.572863340289544, -8.32015686465432],
              [-35.572934009802722, -8.320117394763326],
              [-35.572962343391566, -8.320079253433203],
              [-35.573004520986707, -8.320053675047653],
              [-35.573064611653294, -8.320015328041531],
              [-35.572501060416144, -8.317931295665357],
              [-35.572656064388056, -8.317918693993748],
              [-35.57280939104507, -8.317892396111867],
              [-35.572973083098255, -8.317832290775755],
              [-35.573063658508964, -8.317761060570492],
              [-35.573126449750298, -8.317649943654779],
              [-35.5731606872979, -8.317543229154355],
              [-35.573149578674844, -8.317463167877449],
              [-35.573047261680458, -8.317357337516585],
              [-35.572997879730579, -8.317252218795772],
              [-35.572939609592893, -8.317082840144847],
              [-35.572922946802571, -8.316962748185905],
              [-35.572924499734015, -8.316876278599695],
              [-35.572999849196165, -8.316742938359177],
              [-35.573077023840426, -8.31664596256763],
              [-35.57316670797524, -8.31643819542008],
              [-35.573259968140107, -8.315967336258639],
              [-35.573279749466714, -8.315754222574901],
              [-35.573179010335473, -8.314997826755411],
              [-35.573290761984502, -8.313304816528506],
              [-35.573002655541451, -8.312792141364287],
              [-35.572161749995367, -8.312003102533671],
              [-35.574030853392138, -8.310485875542446],
              [-35.574325683980327, -8.310244622275921],
              [-35.574539948096472, -8.309985965868986],
              [-35.574906132953757, -8.308350355877876],
              [-35.575212933365705, -8.306942350239996],
              [-35.577089588922789, -8.306019741562908],
              [-35.577414332705018, -8.305902183616556],
              [-35.579779200850439, -8.305095020899353],
              [-35.579853358255441, -8.304779807924151],
              [-35.579928746359698, -8.304490946475104],
              [-35.580616830371497, -8.301496267017386],
              [-35.57871465559748, -8.30182912305658],
              [-35.576957455955764, -8.301831011026366],
              [-35.575668232281174, -8.301603698891634],
              [-35.572979308681617, -8.299951993082276],
              [-35.572869232147958, -8.301741996008591],
              [-35.572135159192243, -8.302073598625155],
              [-35.571390486738636, -8.301915506255868],
              [-35.570517635667883, -8.301580575665701],
              [-35.569933335267159, -8.302886516136017],
              [-35.567732499315845, -8.302713563805915],
              [-35.567326013491879, -8.302225894278685],
              [-35.56674779066104, -8.301699791586445],
              [-35.565898472798665, -8.299935471092118],
              [-35.566035039748478, -8.299774323741783],
              [-35.567123514301585, -8.299490004136395],
              [-35.567259285879416, -8.299206552468668],
              [-35.568629964721943, -8.298042104496096],
              [-35.567236028918998, -8.296115231354424],
              [-35.565723743087716, -8.296012153440326],
              [-35.564166912492297, -8.295814460916649],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0185000',
        uf: 'PE',
        nome_proje: 'PA SERRARIA',
        municipio: 'MORENO',
        area_hecta: '435.1000',
        capacidade: 48.0,
        num_famili: 40.0,
        fase: 5.0,
        data_de_cr: '03/02/1999',
        forma_obte: 'Desapropriação',
        data_obten: '13/10/1998',
        area_calc_: 476.9515,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.159417878676329, -8.08410462830528],
              [-35.159502498725047, -8.083354911352783],
              [-35.159509482021107, -8.083304278102279],
              [-35.159519900863998, -8.083257878488595],
              [-35.159536700843411, -8.083208043727678],
              [-35.159551238122155, -8.083173952551808],
              [-35.159582689606196, -8.083111497571556],
              [-35.159618197313236, -8.083049658933605],
              [-35.15969943451249, -8.082922738780017],
              [-35.159796398737939, -8.082784893443103],
              [-35.159805515539873, -8.082774428378126],
              [-35.159840499775648, -8.082734489231749],
              [-35.159851512595594, -8.082719549760526],
              [-35.159858451071706, -8.082700592608346],
              [-35.159862688153439, -8.082675059456159],
              [-35.159863154120984, -8.082662407906787],
              [-35.159861697496396, -8.082649235042958],
              [-35.159856180677892, -8.082634701806876],
              [-35.159847472726973, -8.082621779850609],
              [-35.159837444195865, -8.082611229934509],
              [-35.159829010016153, -8.082604657654402],
              [-35.159822052639548, -8.082600017449579],
              [-35.159805579897714, -8.082591069427904],
              [-35.159790019933496, -8.082583285833845],
              [-35.159756985332109, -8.082568685418275],
              [-35.159683870760915, -8.082538352545711],
              [-35.159665907695178, -8.082529943876219],
              [-35.159644731451337, -8.082519001102929],
              [-35.159598295589369, -8.082491397214982],
              [-35.159527874315764, -8.082446062509105],
              [-35.159458963165676, -8.082403908649297],
              [-35.159445704179213, -8.08239760101295],
              [-35.159435657530516, -8.082393668032257],
              [-35.159422681868314, -8.082390494588312],
              [-35.159410997065443, -8.08238938708188],
              [-35.15939854637444, -8.082389665451435],
              [-35.159373170575257, -8.082391261070597],
              [-35.159201766110492, -8.08240997098115],
              [-35.159183537365415, -8.082411768002055],
              [-35.159171115042142, -8.082412365094688],
              [-35.15916018076836, -8.082411997677669],
              [-35.159148657076891, -8.082411101899011],
              [-35.159138143775813, -8.082409669307095],
              [-35.159127414617487, -8.082407759531176],
              [-35.15911208973737, -8.082404067021201],
              [-35.159098096900038, -8.082400154878238],
              [-35.159083940979926, -8.082395658969919],
              [-35.159070423841726, -8.082390947109113],
              [-35.159059975263744, -8.08238664421061],
              [-35.159049814615258, -8.082381329991499],
              [-35.159038469743358, -8.08237394927813],
              [-35.15902663363569, -8.082364445258474],
              [-35.159014145161123, -8.082352606197144],
              [-35.159003997989792, -8.082339798110146],
              [-35.158975757386145, -8.082284036169678],
              [-35.158948977151233, -8.082222101394654],
              [-35.158941232514486, -8.082209812098119],
              [-35.158927900060888, -8.082189686481851],
              [-35.158880824773114, -8.082081939503789],
              [-35.158856040472756, -8.082014254255782],
              [-35.158833730863478, -8.081970684880254],
              [-35.158822726717013, -8.081947145530071],
              [-35.158816231418776, -8.081929109694991],
              [-35.158812915376686, -8.081907230456384],
              [-35.158812358765509, -8.081882785568936],
              [-35.158787781475056, -8.081833966562895],
              [-35.158762338705834, -8.081787862643479],
              [-35.158755192403731, -8.081777775798404],
              [-35.158738450735896, -8.08175835909501],
              [-35.158731470502353, -8.08174941404047],
              [-35.158716468834221, -8.081726267827609],
              [-35.15869246524754, -8.081685045861228],
              [-35.15867485699308, -8.081653090935433],
              [-35.158668600163104, -8.081639783962451],
              [-35.158666482026945, -8.081637589529681],
              [-35.158663858417903, -8.08163561035294],
              [-35.158661478168966, -8.081634267668589],
              [-35.158658672160236, -8.081633086668127],
              [-35.158655868968317, -8.0816324371281],
              [-35.158653442603928, -8.081632449942152],
              [-35.15865140532182, -8.081632925737798],
              [-35.158649675881904, -8.081633625791024],
              [-35.158647828143899, -8.081634645348776],
              [-35.158645134943747, -8.081637130928788],
              [-35.15864168947752, -8.081641055463248],
              [-35.158638422050423, -8.081645869275974],
              [-35.158633003098338, -8.081654746946276],
              [-35.158631283831305, -8.081657360251606],
              [-35.158629536335702, -8.08165968139275],
              [-35.158626090224274, -8.081663486349521],
              [-35.158620545413243, -8.081668750650183],
              [-35.158617968674008, -8.081670571270861],
              [-35.158614856829416, -8.081672049262092],
              [-35.158611661174184, -8.081672810200557],
              [-35.158607184265108, -8.081673312167723],
              [-35.158602090568564, -8.081673153056975],
              [-35.158596727553174, -8.081672490459619],
              [-35.158579856933351, -8.081668938944063],
              [-35.158575033605636, -8.081668226998081],
              [-35.158571785749608, -8.081667945192841],
              [-35.158566119678682, -8.08166795518361],
              [-35.158558976426598, -8.081668471231151],
              [-35.15854716765768, -8.081669118210158],
              [-35.158535626432233, -8.081669936508185],
              [-35.158529763171281, -8.081670472371284],
              [-35.158524820292186, -8.081671096379379],
              [-35.158520624126758, -8.08167174301969],
              [-35.158511700858448, -8.081673477573906],
              [-35.158504485248613, -8.081675428984429],
              [-35.158488725528578, -8.081680215760514],
              [-35.15846168016202, -8.081688755867033],
              [-35.158445283204316, -8.081694050906634],
              [-35.158408656809321, -8.081705989901637],
              [-35.1583894925054, -8.0817123624945],
              [-35.158378746483521, -8.081717308802775],
              [-35.158368647737412, -8.081723633532665],
              [-35.158359992196182, -8.0817305884012],
              [-35.158347468381955, -8.081742187530677],
              [-35.158321304335729, -8.08176843127076],
              [-35.158295807207722, -8.081827453299617],
              [-35.158274723979318, -8.081874121810346],
              [-35.158254643995392, -8.081908879985518],
              [-35.158242877349444, -8.081942531321252],
              [-35.158234100403902, -8.082137310018688],
              [-35.158241987417391, -8.08217638490717],
              [-35.158230550771265, -8.082272110758611],
              [-35.158241350090314, -8.082377498318513],
              [-35.15824218582739, -8.082454451456325],
              [-35.158257738373791, -8.082490934787639],
              [-35.158266530624033, -8.082539784037273],
              [-35.15825310045409, -8.082581522577897],
              [-35.158220007554156, -8.082616136891897],
              [-35.158177032418578, -8.082637622804432],
              [-35.15811437646466, -8.082648583146568],
              [-35.158066600059854, -8.082649685773349],
              [-35.157998321241578, -8.082646219665603],
              [-35.157953531140102, -8.082647306510829],
              [-35.157920176736482, -8.082652797361845],
              [-35.157895684762366, -8.082660154732844],
              [-35.157872709676916, -8.082671968484938],
              [-35.157848933337284, -8.082693565595941],
              [-35.157837615728965, -8.082711376605809],
              [-35.1578326323745, -8.082716930259888],
              [-35.157823649784831, -8.082722558171369],
              [-35.15781385514137, -8.082725905023276],
              [-35.157799415689801, -8.082728266598521],
              [-35.157781916541097, -8.082726870856204],
              [-35.157755872647165, -8.082723181737446],
              [-35.157745416044769, -8.082722386580063],
              [-35.157734646256657, -8.082722868613807],
              [-35.157694407571583, -8.082727439146087],
              [-35.157686362549924, -8.082728863451141],
              [-35.157663246900142, -8.082734300228111],
              [-35.157653867854904, -8.082735572140031],
              [-35.157646455410436, -8.082735611264367],
              [-35.157639035047154, -8.082734162314763],
              [-35.157630858795891, -8.082730963475052],
              [-35.157605711794162, -8.082715417719953],
              [-35.157598436733842, -8.082711204321596],
              [-35.157587533739054, -8.082706691195831],
              [-35.157545120395611, -8.082693415624792],
              [-35.157524832989075, -8.082689164625171],
              [-35.157491652890549, -8.082687320174644],
              [-35.157466509687701, -8.082692555057324],
              [-35.157295857061307, -8.08277253933173],
              [-35.157278177356794, -8.082777309625627],
              [-35.157228000293721, -8.082768220514952],
              [-35.157210724611033, -8.082768736876014],
              [-35.157191783134962, -8.082776915268127],
              [-35.157097959678744, -8.082863722026689],
              [-35.157063979511221, -8.082891963250612],
              [-35.156937024157301, -8.082925797331878],
              [-35.156896191009764, -8.082948972551261],
              [-35.156803123527077, -8.083017492527182],
              [-35.156664961188241, -8.083110060549068],
              [-35.156623987945153, -8.08310687532083],
              [-35.156565738030814, -8.083144173372183],
              [-35.156488829622504, -8.083122469822886],
              [-35.156401511042716, -8.083148866549013],
              [-35.156313896691501, -8.083159745737451],
              [-35.156240414888522, -8.083180754681765],
              [-35.156178763156547, -8.083180016996433],
              [-35.156163428680046, -8.083184562298431],
              [-35.156145580788646, -8.083197837048177],
              [-35.156133079161563, -8.083213634677975],
              [-35.15611136236376, -8.083221402490548],
              [-35.156092129620653, -8.083214913660159],
              [-35.156074566071624, -8.083201400547416],
              [-35.156051345851601, -8.083167083439026],
              [-35.156036571688738, -8.083156638162324],
              [-35.156015941712752, -8.083148137096272],
              [-35.156010296945389, -8.083149655002348],
              [-35.155999238226691, -8.083155984756383],
              [-35.155993896488098, -8.083154312215335],
              [-35.155982630056478, -8.083141616232396],
              [-35.155969482757477, -8.08311617476925],
              [-35.155963840770362, -8.083098134360039],
              [-35.155957811120338, -8.083087430360496],
              [-35.155947429545506, -8.083080682239752],
              [-35.155928725556052, -8.083073340253213],
              [-35.155921763115963, -8.083067743340159],
              [-35.155913706479204, -8.083056943739242],
              [-35.155902415212793, -8.083039570908955],
              [-35.15589555603875, -8.083033335671692],
              [-35.155888602077091, -8.083029333141807],
              [-35.155858504162637, -8.083025452692636],
              [-35.155833385687551, -8.083015274570071],
              [-35.155810069041664, -8.083002961035707],
              [-35.155804626995462, -8.082997462393157],
              [-35.155802384426913, -8.082991920297465],
              [-35.155802763493099, -8.082987985386017],
              [-35.155805938265203, -8.082973246765706],
              [-35.155821693083894, -8.082927350467694],
              [-35.155846923140444, -8.082858125560838],
              [-35.15587837750801, -8.082796095892345],
              [-35.155878748802898, -8.082785677013405],
              [-35.155870890203701, -8.082751916619264],
              [-35.155863746046364, -8.082732183416784],
              [-35.15584106426121, -8.082718909872089],
              [-35.155835256394639, -8.082709799127208],
              [-35.155831080487367, -8.082686648821527],
              [-35.155813208839668, -8.082655279734476],
              [-35.155793500952711, -8.082639652006563],
              [-35.155752899049986, -8.082626047796749],
              [-35.155675804466185, -8.082549177865783],
              [-35.15565129827084, -8.082513698252008],
              [-35.155594224226, -8.082451072543209],
              [-35.15557272636709, -8.082419722563182],
              [-35.155482004406778, -8.082327405356409],
              [-35.155457323881635, -8.082299261000502],
              [-35.155452690910657, -8.082270373156254],
              [-35.155438536965889, -8.082256204248308],
              [-35.155417996202075, -8.082244407514084],
              [-35.155341290007961, -8.082220576706467],
              [-35.155250628524065, -8.082179812250732],
              [-35.15518766885392, -8.082133586952155],
              [-35.155153324054211, -8.082093163293303],
              [-35.15510439643262, -8.082078327350331],
              [-35.155077808584501, -8.082052531510529],
              [-35.154998364412108, -8.082035305366114],
              [-35.154959016471444, -8.082016804869166],
              [-35.154927723460126, -8.081988695329912],
              [-35.154894413581609, -8.081982493228008],
              [-35.154884783138876, -8.081976591465084],
              [-35.154866970267378, -8.081956276705395],
              [-35.154832076408432, -8.081937991863922],
              [-35.154783203980763, -8.081918478605195],
              [-35.15477951553391, -8.081916823893325],
              [-35.154776543620521, -8.081914527642812],
              [-35.154773967710561, -8.081911485231867],
              [-35.154767086569798, -8.081901104580082],
              [-35.154763239211313, -8.081897138796734],
              [-35.154760953203819, -8.081895968313477],
              [-35.154758363604714, -8.081895370761742],
              [-35.154751669660349, -8.081895140312493],
              [-35.154742792416421, -8.081895505991881],
              [-35.154715875141036, -8.081898066104126],
              [-35.154700731698547, -8.081898411673835],
              [-35.154694606998021, -8.081899932088612],
              [-35.154688810716436, -8.08190304520207],
              [-35.154684675474329, -8.081907637695613],
              [-35.1546816674282, -8.081913606080962],
              [-35.154654685828703, -8.081994479514961],
              [-35.154650939377952, -8.082001993071737],
              [-35.15464670030309, -8.082007117589308],
              [-35.154642662121326, -8.082009902544945],
              [-35.154638001796883, -8.082011016641285],
              [-35.154629628898171, -8.08201095447702],
              [-35.154621569750674, -8.082009721420716],
              [-35.154614624269094, -8.082007313237566],
              [-35.154599062685087, -8.081999210541708],
              [-35.154592010551461, -8.081996802920301],
              [-35.154587794907279, -8.081996293666155],
              [-35.15458399768724, -8.081996752153975],
              [-35.154579156285081, -8.081998916860762],
              [-35.154574008103594, -8.082003567844662],
              [-35.154570406975736, -8.082008316957888],
              [-35.154569209504629, -8.082013797473261],
              [-35.154569284816063, -8.082027987484892],
              [-35.154567069621685, -8.082032623007795],
              [-35.154561221425737, -8.082036002132183],
              [-35.154554565699172, -8.082037950527974],
              [-35.154540445623347, -8.082040204009365],
              [-35.154486288538976, -8.082044847576645],
              [-35.154449201731239, -8.082050464111115],
              [-35.15443793081694, -8.082057007527256],
              [-35.154428177317754, -8.082068113639528],
              [-35.154413183623788, -8.082096679786071],
              [-35.154362969201387, -8.082160934169901],
              [-35.154357406098271, -8.082172815409161],
              [-35.154356359440847, -8.08217656783024],
              [-35.154355351435605, -8.082182578823938],
              [-35.154354039234185, -8.082201612584402],
              [-35.154352544930646, -8.082211452763465],
              [-35.154350976989264, -8.082217466708624],
              [-35.154348606321228, -8.082222953408158],
              [-35.154342736096403, -8.082232232033681],
              [-35.154335901892388, -8.082240745091719],
              [-35.154326096052202, -8.082252037499698],
              [-35.154319073083805, -8.082260152945002],
              [-35.154315551091798, -8.082264742204334],
              [-35.154312747467777, -8.082269035363137],
              [-35.154310289196957, -8.082273087538836],
              [-35.154308204777969, -8.082277244037531],
              [-35.154306600017101, -8.082281344865729],
              [-35.154304550442561, -8.082287042459894],
              [-35.154294837382409, -8.082315819934891],
              [-35.154289406020979, -8.082332430619815],
              [-35.154284917199824, -8.082350790202947],
              [-35.154282610296697, -8.0823582430288],
              [-35.154279646320823, -8.082364981820728],
              [-35.154277953463826, -8.082367555112963],
              [-35.154276470007382, -8.08236938988142],
              [-35.154275310107273, -8.082370644969295],
              [-35.154273884100412, -8.082372001100397],
              [-35.154271840099156, -8.082373725885983],
              [-35.154269957373927, -8.082375064501694],
              [-35.154268549021623, -8.082375978751207],
              [-35.154265940732941, -8.0823775138557],
              [-35.154262204171928, -8.082379353847081],
              [-35.154259444070846, -8.082380544286364],
              [-35.154255087578257, -8.082382191364573],
              [-35.15425417208305, -8.082382513624411],
              [-35.154252609055575, -8.082383054795079],
              [-35.154244451775291, -8.082385627277523],
              [-35.154234044819134, -8.082388849396914],
              [-35.154226193919349, -8.082391059856787],
              [-35.154212875689637, -8.08239462119089],
              [-35.154208376319723, -8.082395920446846],
              [-35.154203058745367, -8.082397542896011],
              [-35.154200668935282, -8.082398166695896],
              [-35.154194557486015, -8.082399673747888],
              [-35.154191669076113, -8.08240055261729],
              [-35.154188875898718, -8.082401789737913],
              [-35.154186961900812, -8.082402889355413],
              [-35.154185077115528, -8.082404467135433],
              [-35.154159630902996, -8.082430265848306],
              [-35.153847274428365, -8.083801468551696],
              [-35.152372958532574, -8.08594732286535],
              [-35.151744574512421, -8.089573168840248],
              [-35.151601030338192, -8.090401210217054],
              [-35.150969914890005, -8.094042891195322],
              [-35.150264779335778, -8.098111532777692],
              [-35.149855355288238, -8.100473877248859],
              [-35.150160068502224, -8.102496010637557],
              [-35.150211035920762, -8.102647965262461],
              [-35.150232694006355, -8.102712392320036],
              [-35.150286150340783, -8.102871836500446],
              [-35.1506311551381, -8.103871491022815],
              [-35.150911951566862, -8.104685181782585],
              [-35.15106133122864, -8.105348246229264],
              [-35.151130423668739, -8.105599357366684],
              [-35.151273985763673, -8.106072173152116],
              [-35.151348638245224, -8.106294148006038],
              [-35.151421225983071, -8.106554279933656],
              [-35.151856201911215, -8.108113356088362],
              [-35.152330945663003, -8.109815042917853],
              [-35.152441078614956, -8.110209753066016],
              [-35.152646652312669, -8.110161732250793],
              [-35.153752653916825, -8.110181397157129],
              [-35.155506382359768, -8.110372806376857],
              [-35.155827047415734, -8.110338795797068],
              [-35.156378164131297, -8.110315470168437],
              [-35.157058547185478, -8.109890089777679],
              [-35.15747223132206, -8.109377683701412],
              [-35.157766711499278, -8.108274058994541],
              [-35.157939442602633, -8.10738196815204],
              [-35.158308538499355, -8.106189511270337],
              [-35.158623382919508, -8.105704840174104],
              [-35.159190905754997, -8.10523923921272],
              [-35.159796824282772, -8.104933301640271],
              [-35.160427758846403, -8.104838119508319],
              [-35.161052447687318, -8.10485181531541],
              [-35.163205991256561, -8.105456050316075],
              [-35.164151975341142, -8.105554772037124],
              [-35.164639894299221, -8.105523269006666],
              [-35.16517866385572, -8.105426868302484],
              [-35.165798511759512, -8.105233778460066],
              [-35.166433333504798, -8.102431385476191],
              [-35.166453094991731, -8.102344322398528],
              [-35.166470694871272, -8.102243169509819],
              [-35.166488124683269, -8.102161090442644],
              [-35.167103031006654, -8.099265157170549],
              [-35.167576283951085, -8.097036083170986],
              [-35.167883381046785, -8.095589881845093],
              [-35.168088521365924, -8.094651417192759],
              [-35.168368123584273, -8.093372860439002],
              [-35.168729587997859, -8.091679507883581],
              [-35.168758433975889, -8.091544307635596],
              [-35.169329729535086, -8.087738082007402],
              [-35.169402161077706, -8.08725581279025],
              [-35.169734183258207, -8.085043853003965],
              [-35.169734183258207, -8.085043853003965],
              [-35.168806416790964, -8.084911110146516],
              [-35.168806416790964, -8.084911110146516],
              [-35.168877022102386, -8.085088267101435],
              [-35.166432092442292, -8.084836205335542],
              [-35.166432094855018, -8.084836657286026],
              [-35.166395701013087, -8.084832420977936],
              [-35.163184106551057, -8.084501873822184],
              [-35.163139182660309, -8.08449686882992],
              [-35.159417878676329, -8.08410462830528],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0228000',
        uf: 'PE',
        nome_proje: 'PA BELA VISTA',
        municipio: 'ESCADA',
        area_hecta: '235.4600',
        capacidade: 33.0,
        num_famili: 32.0,
        fase: 5.0,
        data_de_cr: '31/05/2001',
        forma_obte: 'Desapropriação',
        data_obten: '03/05/2000',
        area_calc_: 189.5282,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.24777954177025, -8.331294599878319],
              [-35.24732004987802, -8.330402367153013],
              [-35.245056799492396, -8.330757112404447],
              [-35.243926577274244, -8.330950245273163],
              [-35.243811042998452, -8.331936304081179],
              [-35.243813325123867, -8.332065997373812],
              [-35.243570961302055, -8.334922079434453],
              [-35.239522829081089, -8.340204080499191],
              [-35.240153427502655, -8.342121077014523],
              [-35.240577480370064, -8.343243193897196],
              [-35.240727353894549, -8.344300154109416],
              [-35.240747828729198, -8.344675870492972],
              [-35.240725277311014, -8.345750527311619],
              [-35.24097517751435, -8.345870323725757],
              [-35.241113334568659, -8.345954416230722],
              [-35.241146815365703, -8.34596886963593],
              [-35.241178660022612, -8.345982880348826],
              [-35.241210549035145, -8.345988755916835],
              [-35.241247400107149, -8.345989451315416],
              [-35.241269705353503, -8.345985800004826],
              [-35.241298904774467, -8.345981567363204],
              [-35.241322820898084, -8.345973929877896],
              [-35.241622302705515, -8.345939605449932],
              [-35.241749615350351, -8.345904537732226],
              [-35.241923388018677, -8.345820488121651],
              [-35.241955877222139, -8.34580430564985],
              [-35.241988275144635, -8.345788033305451],
              [-35.242020763816832, -8.345771760436438],
              [-35.242053161732898, -8.345755488088106],
              [-35.242085468377063, -8.345739125867173],
              [-35.242117865255182, -8.345722672737303],
              [-35.242150170861429, -8.345706129743878],
              [-35.242198215456526, -8.34568036851293],
              [-35.242455330760556, -8.345505911336552],
              [-35.242706574037946, -8.345256736744982],
              [-35.242801810959996, -8.345103623352079],
              [-35.242909131373999, -8.344936883416153],
              [-35.242998776986767, -8.344758221980291],
              [-35.243013089112502, -8.344737532588569],
              [-35.243024544088144, -8.344725084640849],
              [-35.243039108384181, -8.34471677691765],
              [-35.24321234132686, -8.344633724257811],
              [-35.243245289286222, -8.344618443001588],
              [-35.243294802736777, -8.344595656166868],
              [-35.243450454330443, -8.344548857995317],
              [-35.243763233683715, -8.344459501350233],
              [-35.243820292984118, -8.344439021812994],
              [-35.243851754846737, -8.34443369155213],
              [-35.243887964757576, -8.344433396104792],
              [-35.243919027558341, -8.344437649187739],
              [-35.243940771186566, -8.344446926348056],
              [-35.243955720208646, -8.34445832090144],
              [-35.243983990377409, -8.344482113501918],
              [-35.244011126698922, -8.344513957001412],
              [-35.244116758477091, -8.344607904097641],
              [-35.244139340130118, -8.344636790588705],
              [-35.244162642138896, -8.344664678748222],
              [-35.244177892853237, -8.344681223672355],
              [-35.244189009110983, -8.344688934033384],
              [-35.244221309522736, -8.344703303467467],
              [-35.244316744781564, -8.344743979611966],
              [-35.244323664201055, -8.344747827078686],
              [-35.244330780620459, -8.344754385051127],
              [-35.244336088680718, -8.344762128317514],
              [-35.244340857387506, -8.344770778500971],
              [-35.244343343333632, -8.344777001164161],
              [-35.244344299897634, -8.344785582555303],
              [-35.244342992292637, -8.344794990265315],
              [-35.244338918303328, -8.344812638907793],
              [-35.24433439674511, -8.344831374730802],
              [-35.244327045328681, -8.34484723418838],
              [-35.244303110634313, -8.34491516039982],
              [-35.244301220909755, -8.344933881323414],
              [-35.244305314700988, -8.34495148369756],
              [-35.244316588400245, -8.344986761359202],
              [-35.244331208086592, -8.345019941144093],
              [-35.244346731698172, -8.345052483097421],
              [-35.244362709076242, -8.345085022487872],
              [-35.244378595704795, -8.345117562382011],
              [-35.244387590165722, -8.345135136990987],
              [-35.244395024271448, -8.345149647269183],
              [-35.244412720806729, -8.345181273036202],
              [-35.244432317998388, -8.345211984154018],
              [-35.244455439079282, -8.345239963693448],
              [-35.244480997589108, -8.345265669726434],
              [-35.244507187757321, -8.345290739459596],
              [-35.244534821204645, -8.34531426443273],
              [-35.244595143772813, -8.345404310181502],
              [-35.244642082403139, -8.345502747384765],
              [-35.244653533008744, -8.345537210529006],
              [-35.244662989121757, -8.345572046521609],
              [-35.244670903463884, -8.345607072032575],
              [-35.244669563561956, -8.345642601905441],
              [-35.244661920902466, -8.345671026876218],
              [-35.244661238242038, -8.345678623294244],
              [-35.244661545610143, -8.345684767900471],
              [-35.244663040518347, -8.345692261592962],
              [-35.244666563999864, -8.345705347816507],
              [-35.244669693491119, -8.345713013021172],
              [-35.244676817681345, -8.345720926752097],
              [-35.244690211061076, -8.345730070396947],
              [-35.244700766837219, -8.345734981900669],
              [-35.244736369459346, -8.345739570705355],
              [-35.244773234358917, -8.345742706160932],
              [-35.244809795511031, -8.34574032969636],
              [-35.24482565460066, -8.345736262780525],
              [-35.244843847462022, -8.345727663277499],
              [-35.244875237095606, -8.345709679070426],
              [-35.244890977038452, -8.345700641521512],
              [-35.244940939696527, -8.34569294663552],
              [-35.246559386365391, -8.34561245660211],
              [-35.246517594682473, -8.34473159739418],
              [-35.247672002758414, -8.34433394436966],
              [-35.249078731466497, -8.34386110052659],
              [-35.250079039496498, -8.343449221708552],
              [-35.250373542337634, -8.343148367888045],
              [-35.250526888086405, -8.342905259499963],
              [-35.250672393361747, -8.342735228442825],
              [-35.251120019273252, -8.342580564543631],
              [-35.251518629746712, -8.342535547084005],
              [-35.253134704629275, -8.342314133984942],
              [-35.253961553026095, -8.342215881945391],
              [-35.254407073477665, -8.341362174333433],
              [-35.255166839541815, -8.340692243336793],
              [-35.256040456881827, -8.339714348414544],
              [-35.257317977112166, -8.338255465049553],
              [-35.258016680949027, -8.337431950352011],
              [-35.256726204892352, -8.336362880009014],
              [-35.255661434863754, -8.336116306025495],
              [-35.255108652884552, -8.336020656618468],
              [-35.25251722772493, -8.335424727735161],
              [-35.252427927516237, -8.335409055572885],
              [-35.25140837943065, -8.335230183084308],
              [-35.250356881919075, -8.3342253500499],
              [-35.248324250181746, -8.332313433222751],
              [-35.248268816279641, -8.3322209195364],
              [-35.248060398895085, -8.331751543914418],
              [-35.24777954177025, -8.331294599878319],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0311000',
        uf: 'PE',
        nome_proje: 'PA CANOA RACHADA',
        municipio: 'AGUA PRETA',
        area_hecta: '1496.1885',
        capacidade: 123.0,
        num_famili: 120.0,
        fase: 4.0,
        data_de_cr: '02/03/2005',
        forma_obte: 'Desapropriação',
        data_obten: '25/02/2003',
        area_calc_: 1290.7166,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.406188022627425, -8.723673637810654],
              [-35.405662387270901, -8.723702765573721],
              [-35.399786435244152, -8.724195732403421],
              [-35.39107491698816, -8.731753740877245],
              [-35.391031256320069, -8.731749007827553],
              [-35.388078937010626, -8.733668671284136],
              [-35.387141723987938, -8.734422557365461],
              [-35.386525114240143, -8.735428357697964],
              [-35.385759112999537, -8.736484062773386],
              [-35.385132823698726, -8.736923890404301],
              [-35.38483750144924, -8.738372953553879],
              [-35.384288879592226, -8.739033260745199],
              [-35.383249700387267, -8.739827908945625],
              [-35.382777527119991, -8.740350638026205],
              [-35.382165718423707, -8.741510264795911],
              [-35.381795774238491, -8.742769555160098],
              [-35.381876331666724, -8.743432262943314],
              [-35.381385811106284, -8.745251464777793],
              [-35.380734692033734, -8.745898123354435],
              [-35.380648574608848, -8.74637075450293],
              [-35.379575631360233, -8.747863183219149],
              [-35.378129834353274, -8.749843543043177],
              [-35.372065420922226, -8.75390202871186],
              [-35.37315921503032, -8.755753165547867],
              [-35.373682866421831, -8.755540909795251],
              [-35.374410418532975, -8.755117445554337],
              [-35.376394463228607, -8.754157255970286],
              [-35.376904525478878, -8.753942587948288],
              [-35.377452965167237, -8.753759753902887],
              [-35.377908665551182, -8.753644496801952],
              [-35.378600050489396, -8.753524820690927],
              [-35.379008352324632, -8.75348360173909],
              [-35.379792402169748, -8.753427010189958],
              [-35.380786579348033, -8.753367296096268],
              [-35.381112530688689, -8.753325620163526],
              [-35.381402339964751, -8.753264498469711],
              [-35.381728888073511, -8.753156113705003],
              [-35.383466810696994, -8.752378931549456],
              [-35.383933085655819, -8.752209067616999],
              [-35.38552557398247, -8.751792118019077],
              [-35.385787033155005, -8.751705750509931],
              [-35.386148399787572, -8.751540149801661],
              [-35.386436477363475, -8.751357412264364],
              [-35.387827616611503, -8.75023288900098],
              [-35.388170659491223, -8.749989692898346],
              [-35.388566687314231, -8.749751600887183],
              [-35.38893046175405, -8.74957009533599],
              [-35.390430802078541, -8.74895212051905],
              [-35.390899274619194, -8.748807105577525],
              [-35.391368121813571, -8.748690180218521],
              [-35.392048548010074, -8.748580715646812],
              [-35.392502497268737, -8.748535500435128],
              [-35.392343757309206, -8.748915123339229],
              [-35.388065570887889, -8.761420739366178],
              [-35.386444979742329, -8.767446044880197],
              [-35.385484725859186, -8.773005195597435],
              [-35.387121744132841, -8.771786769256799],
              [-35.391807618436921, -8.7695349687049],
              [-35.400269318142009, -8.76400874245196],
              [-35.41687272779172, -8.753398141571617],
              [-35.415195099966802, -8.750110590018428],
              [-35.41496354021028, -8.749720369096526],
              [-35.413442287632009, -8.747428624168181],
              [-35.413240473770074, -8.738429603285818],
              [-35.414323610133899, -8.733309074191453],
              [-35.412590740867209, -8.728271374000688],
              [-35.407567161263394, -8.724992055080127],
              [-35.406556443557427, -8.724849580389307],
              [-35.406077790805256, -8.724276930216226],
              [-35.406188022627425, -8.723673637810654],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0269000',
        uf: 'PE',
        nome_proje: 'PA TIBIRI',
        municipio: 'BARREIROS',
        area_hecta: '959.0000',
        capacidade: 84.0,
        num_famili: 77.0,
        fase: 4.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 825.4674,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.223703015018522, -8.787056166949688],
              [-35.220114679680954, -8.787950525153176],
              [-35.219503229735089, -8.78809882775656],
              [-35.219089655310469, -8.788198999082971],
              [-35.218875982395758, -8.788246336701043],
              [-35.21769132389916, -8.788132110569155],
              [-35.217297020768335, -8.788093277661625],
              [-35.216667612763956, -8.78802588211531],
              [-35.214845151390413, -8.787836824247462],
              [-35.212407243771985, -8.787585921279327],
              [-35.21214277387736, -8.787550026326992],
              [-35.211813519083726, -8.787799141733542],
              [-35.21097411858316, -8.788253122120505],
              [-35.211270601983863, -8.7901813734829],
              [-35.210629458576165, -8.79158336875412],
              [-35.209337656820729, -8.791774244953229],
              [-35.207620870972661, -8.792156880051641],
              [-35.206512770336516, -8.792615863592715],
              [-35.205303487062778, -8.79217169376447],
              [-35.204551125770763, -8.792713526626372],
              [-35.203787776962059, -8.793567342633201],
              [-35.203180223662677, -8.794658048187744],
              [-35.203355953026957, -8.79510756792145],
              [-35.204317857284309, -8.795627263519492],
              [-35.205020406404977, -8.796197637044191],
              [-35.205560278388248, -8.797318547065096],
              [-35.205922264139645, -8.798957706219314],
              [-35.206205821430736, -8.800522166502523],
              [-35.206682325463902, -8.801822267046662],
              [-35.207483255279385, -8.803135918853799],
              [-35.207839240325733, -8.803182566856524],
              [-35.208269167336326, -8.802951891362479],
              [-35.208569845500179, -8.803485370382624],
              [-35.211971241864099, -8.805752341486244],
              [-35.212046723999734, -8.80829690930473],
              [-35.212420321186222, -8.809415641434047],
              [-35.212564753200546, -8.809939065398316],
              [-35.211998108058928, -8.810385506373223],
              [-35.211714742000574, -8.810328747312717],
              [-35.211661925067837, -8.810589364862421],
              [-35.211272038106777, -8.810891012905563],
              [-35.211342826748037, -8.811825030987922],
              [-35.211745065463298, -8.81211843520782],
              [-35.212099451272287, -8.812702452210917],
              [-35.212053529065031, -8.812745737884457],
              [-35.211455757415457, -8.813331287880693],
              [-35.210644292316779, -8.813172117680384],
              [-35.209855828388726, -8.813352667416749],
              [-35.20862451800334, -8.813721113407725],
              [-35.207590428658179, -8.814769079622165],
              [-35.206724777371086, -8.815005394729877],
              [-35.206670644665657, -8.815873064105356],
              [-35.206509016207931, -8.817085751178196],
              [-35.206237303340259, -8.819257457028085],
              [-35.208571946285389, -8.819031476706565],
              [-35.208292201409172, -8.816976550685062],
              [-35.209676042261783, -8.816814970733006],
              [-35.210673199872048, -8.816486745722553],
              [-35.211955305275445, -8.815579771431048],
              [-35.212354357613982, -8.814728873992369],
              [-35.212409873953888, -8.814740243220948],
              [-35.21329593120678, -8.814841907663325],
              [-35.213395643388964, -8.816067469402938],
              [-35.212685178984948, -8.818618146646955],
              [-35.213651103937458, -8.818450028485023],
              [-35.21498709981919, -8.818206141450425],
              [-35.215886056839501, -8.818046809937957],
              [-35.216706374214141, -8.818308023731833],
              [-35.218552456183637, -8.81944464943026],
              [-35.21886918529151, -8.819765721739437],
              [-35.218861766914678, -8.820860595917297],
              [-35.221125466518366, -8.818296494610596],
              [-35.221546544513501, -8.817596534147059],
              [-35.221593703771674, -8.817501927518274],
              [-35.221730237385053, -8.817263583362067],
              [-35.221878372716283, -8.817021399693687],
              [-35.222395880390359, -8.81721717278362],
              [-35.222983464053762, -8.817018532699084],
              [-35.22324545090413, -8.816910804839281],
              [-35.223769873782643, -8.816767474615132],
              [-35.224094175453978, -8.816891580742313],
              [-35.225111681436793, -8.817494787656129],
              [-35.226659781418817, -8.81748887630023],
              [-35.226769259342419, -8.817477986478719],
              [-35.227736876635404, -8.817505754962291],
              [-35.228150485002473, -8.817516027984148],
              [-35.228545932343415, -8.817613314845472],
              [-35.229346971588093, -8.817840230466134],
              [-35.230025836119552, -8.817259824801125],
              [-35.230535892724056, -8.816871994608746],
              [-35.231290981762079, -8.816253470934521],
              [-35.232080829126588, -8.816454163194175],
              [-35.233283434077904, -8.816744611766024],
              [-35.233984192964783, -8.817977432821927],
              [-35.234669939744663, -8.817853962378569],
              [-35.236115171297939, -8.817600993223353],
              [-35.2345814644789, -8.81261250328085],
              [-35.234467905363182, -8.812027042779338],
              [-35.231275065638506, -8.801176092072106],
              [-35.231097267068364, -8.800582756765234],
              [-35.231035933359514, -8.800494840065266],
              [-35.229962431915155, -8.796718703465393],
              [-35.228329669203717, -8.794215825115216],
              [-35.227937174722911, -8.79357608663874],
              [-35.223703015018522, -8.787056166949688],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0276000',
        uf: 'PE',
        nome_proje: 'PA SERRA D ÁGUA DO UNA',
        municipio: 'BARREIROS',
        area_hecta: '532.0000',
        capacidade: 52.0,
        num_famili: 52.0,
        fase: 3.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 634.1979,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.151984489495398, -8.796383058912628],
              [-35.151700502079493, -8.796459354027064],
              [-35.149611722904261, -8.796935471387838],
              [-35.149523986402677, -8.796957078667102],
              [-35.149228971630009, -8.797031175327776],
              [-35.147524749089392, -8.797410881101353],
              [-35.145685753158617, -8.799107689401851],
              [-35.14432391274984, -8.799869441176089],
              [-35.143387611198271, -8.8005617314801],
              [-35.144182161312322, -8.802412523950006],
              [-35.147314161161752, -8.811196302144012],
              [-35.148611780454218, -8.812760498211084],
              [-35.1486112910587, -8.812839358167707],
              [-35.148364284237893, -8.813469132904334],
              [-35.147905509939207, -8.814618673865677],
              [-35.146108858509379, -8.816623557487084],
              [-35.144425010963815, -8.821406067878087],
              [-35.144281807606397, -8.822074741664784],
              [-35.144184702535327, -8.822517851953778],
              [-35.143955726301726, -8.823336956779627],
              [-35.144243353666958, -8.824406604037149],
              [-35.144198911117932, -8.825367427803354],
              [-35.144003008410273, -8.826209221998354],
              [-35.144174760481619, -8.826178565210981],
              [-35.145497599856704, -8.826509898986354],
              [-35.146927422086137, -8.826613688584471],
              [-35.147583134591876, -8.826287493355943],
              [-35.148903235062576, -8.82668875644532],
              [-35.150797538680607, -8.828170564110208],
              [-35.15162282412367, -8.828549857450788],
              [-35.152676636604568, -8.82886304519972],
              [-35.152879501787844, -8.828911849063761],
              [-35.153967888102017, -8.828824701784798],
              [-35.155437453640438, -8.828250768495018],
              [-35.157277860472647, -8.828003638518094],
              [-35.158638770083222, -8.827733976913533],
              [-35.158981101248408, -8.827599847363544],
              [-35.159946539694495, -8.82699389991827],
              [-35.160441809239181, -8.82662173177731],
              [-35.161129571499266, -8.825923510605639],
              [-35.162687054075768, -8.825795123454645],
              [-35.163764801090188, -8.825745749557795],
              [-35.165207325072835, -8.825884277119618],
              [-35.166694958002758, -8.825993008016461],
              [-35.1673412458588, -8.826381053032117],
              [-35.168421458462007, -8.82606181033654],
              [-35.169665117746085, -8.825050824936705],
              [-35.17054916067859, -8.823610522548696],
              [-35.171050777088226, -8.822826970595246],
              [-35.170760047190143, -8.822644103134802],
              [-35.16742532761544, -8.821782467136572],
              [-35.167133506667909, -8.820795384882437],
              [-35.167836796867441, -8.81947940551809],
              [-35.167857111799862, -8.819093955947542],
              [-35.167957015555572, -8.817263344569509],
              [-35.168032013291644, -8.815887827636972],
              [-35.167910025666984, -8.815638476845063],
              [-35.16754186642325, -8.8150777156452],
              [-35.167021273942126, -8.814673484585983],
              [-35.166601334648334, -8.813996724704323],
              [-35.16586294645419, -8.812610366319353],
              [-35.165475549432742, -8.811564642994185],
              [-35.165359056698776, -8.811251750836799],
              [-35.164752358813644, -8.810659547273536],
              [-35.164538554037001, -8.809892444018296],
              [-35.164471640516304, -8.808962389012345],
              [-35.164424050922882, -8.808941541047787],
              [-35.161232118466998, -8.80754320392348],
              [-35.160449843273199, -8.807195104391868],
              [-35.157357405520159, -8.805880076468842],
              [-35.157312106927343, -8.80582942676928],
              [-35.155081639866225, -8.803335447474145],
              [-35.155036341901841, -8.803284797336007],
              [-35.154973137937048, -8.80321351568946],
              [-35.154633360497705, -8.802835911047717],
              [-35.15436588305014, -8.80205213677281],
              [-35.153789672114023, -8.800345349859629],
              [-35.152693942219244, -8.799275326097669],
              [-35.152410925359241, -8.798107910138665],
              [-35.151984489495398, -8.796383058912628],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0138000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOÃO',
        municipio: 'RIO FORMOSO',
        area_hecta: '328.7000',
        capacidade: 31.0,
        num_famili: 28.0,
        fase: 6.0,
        data_de_cr: '03/06/1998',
        forma_obte: 'Desapropriação',
        data_obten: '05/11/1996',
        area_calc_: 275.7141,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.22323988713768, -8.732823834889162],
              [-35.222677166032547, -8.733104016520201],
              [-35.222359194779663, -8.733288927570733],
              [-35.222154301081147, -8.733370401733659],
              [-35.22215330910872, -8.733409327837579],
              [-35.220606792121878, -8.734065642411981],
              [-35.220444304138056, -8.734135718977736],
              [-35.220147101299894, -8.734328108372166],
              [-35.219220388202785, -8.734351160455462],
              [-35.218700694260356, -8.734099761856347],
              [-35.218530317791782, -8.734017331355966],
              [-35.217735166621729, -8.733934760104711],
              [-35.217117845594586, -8.733858468474264],
              [-35.216304167791542, -8.733638613214916],
              [-35.215552736788652, -8.733451926013148],
              [-35.215237962390624, -8.733447367257579],
              [-35.215196976518726, -8.733441829487365],
              [-35.215160846298673, -8.733430486090969],
              [-35.215040130742977, -8.733379500175339],
              [-35.215000311674096, -8.733364529515718],
              [-35.214957774424256, -8.733355047927786],
              [-35.214917423104524, -8.733353459278698],
              [-35.214766455221508, -8.733354949403671],
              [-35.214714192270058, -8.73335464659398],
              [-35.214649971040423, -8.733347724172232],
              [-35.214622473295655, -8.733349101074511],
              [-35.214596830734465, -8.733354115928407],
              [-35.214391923638196, -8.733416949121361],
              [-35.213686597520436, -8.733597212046703],
              [-35.213429063809265, -8.733666826698649],
              [-35.213319273471697, -8.733706388243125],
              [-35.213253437276009, -8.733736571138193],
              [-35.213187629753058, -8.733771618931483],
              [-35.212965532719707, -8.733901893157757],
              [-35.212666191546589, -8.734074472548832],
              [-35.212522437836832, -8.734159234023018],
              [-35.212153328525005, -8.734394276225963],
              [-35.211499645980027, -8.73477708694621],
              [-35.210390283521448, -8.735426758537317],
              [-35.208942845723662, -8.736274450079376],
              [-35.208829824241882, -8.736325051960394],
              [-35.208741353740201, -8.73635232551457],
              [-35.208377112505133, -8.736551442181169],
              [-35.207890686758674, -8.73680030878136],
              [-35.207643913824725, -8.736933407713826],
              [-35.207587831040719, -8.736959276108252],
              [-35.207512210376215, -8.736988907113595],
              [-35.207467108225721, -8.737011062718345],
              [-35.20743330666069, -8.737031936214059],
              [-35.207399265679108, -8.737064061607855],
              [-35.207357407538112, -8.737118125410513],
              [-35.207281630057381, -8.737224990490915],
              [-35.207234898146879, -8.737281819284323],
              [-35.207197534726141, -8.737320653510082],
              [-35.20716346865818, -8.737348522093658],
              [-35.20712967598098, -8.73737091587043],
              [-35.206646715285892, -8.73767034671045],
              [-35.206616112731147, -8.737689529155316],
              [-35.206582941959105, -8.737713743681278],
              [-35.206540823490847, -8.737749564878722],
              [-35.20649369455699, -8.737790888474775],
              [-35.206463883749286, -8.737814779354391],
              [-35.206434055049712, -8.737835629664875],
              [-35.206383494558928, -8.737865114584766],
              [-35.20632438397741, -8.737895865553865],
              [-35.206290569734904, -8.737914610585488],
              [-35.206257377454349, -8.737935176398459],
              [-35.206184003062646, -8.737983038209542],
              [-35.206095108407411, -8.738042240882717],
              [-35.206029335018187, -8.738083368924261],
              [-35.20597050136999, -8.738109253162461],
              [-35.205912536271164, -8.738126922493763],
              [-35.205853920586065, -8.738137906111907],
              [-35.205802307243196, -8.738144287941759],
              [-35.205732026278675, -8.738146521481649],
              [-35.205649336565997, -8.73814350605943],
              [-35.205241693745577, -8.738102244990992],
              [-35.205154836188768, -8.738092108103716],
              [-35.205098567068916, -8.73808635421052],
              [-35.204986616813997, -8.738070890079838],
              [-35.204938919243219, -8.738067518824053],
              [-35.204897966676768, -8.738067757133274],
              [-35.204811841904068, -8.738078292539534],
              [-35.204598662386374, -8.738105161481696],
              [-35.203269592435248, -8.738027565413155],
              [-35.202702071299193, -8.737877837388462],
              [-35.20108972838667, -8.738574792032541],
              [-35.197330074016762, -8.740200019785881],
              [-35.197200926326168, -8.741928458788106],
              [-35.196923951899286, -8.743515845268377],
              [-35.196777775125348, -8.744353964071163],
              [-35.196668076796421, -8.744982982956051],
              [-35.196580265341012, -8.745486505713261],
              [-35.196508450370708, -8.745898100730841],
              [-35.196671355271796, -8.745865667502821],
              [-35.1967621305186, -8.745839903007059],
              [-35.196797070119274, -8.74583042613704],
              [-35.19682667377819, -8.745823108719467],
              [-35.19685782426648, -8.745818975064266],
              [-35.196888377764481, -8.74581727741907],
              [-35.196925974882291, -8.745817971452695],
              [-35.197042920672409, -8.74582489454305],
              [-35.197076536649817, -8.745824243350187],
              [-35.197092119913151, -8.745823544777814],
              [-35.197116403651371, -8.745821123327433],
              [-35.197328483054946, -8.745789789593331],
              [-35.197617816592945, -8.745746300631801],
              [-35.197810807721766, -8.745717357967509],
              [-35.197893099340597, -8.745704261355204],
              [-35.197993858889923, -8.745687256640917],
              [-35.198112033624994, -8.745669238577857],
              [-35.198184126940767, -8.745663042678158],
              [-35.198262343432923, -8.745658635633022],
              [-35.198431557581273, -8.745653396101487],
              [-35.198513913345757, -8.745651245480678],
              [-35.198583197012219, -8.745648030502558],
              [-35.198648812739904, -8.745644760788727],
              [-35.198718318922474, -8.745640404237966],
              [-35.19874795415231, -8.745638483722583],
              [-35.198768421567067, -8.745636768493627],
              [-35.198783998557452, -8.745635005650925],
              [-35.198805060716772, -8.745630474320366],
              [-35.198831912772278, -8.745623020707407],
              [-35.198856010639794, -8.745614974948955],
              [-35.198906025853582, -8.745596440306151],
              [-35.199029197993426, -8.745544641270039],
              [-35.199126122167549, -8.745499076052518],
              [-35.199220914084286, -8.745454739474363],
              [-35.199301389857922, -8.745418695910368],
              [-35.199354592641008, -8.745396493867897],
              [-35.199398198611348, -8.745379516753211],
              [-35.199443338949578, -8.7453635949595],
              [-35.199485129019919, -8.74534966907979],
              [-35.199541264640807, -8.745332467124806],
              [-35.199591453098854, -8.745317428193623],
              [-35.19964454407399, -8.745302220369046],
              [-35.199663690697051, -8.745296787920832],
              [-35.199676960759767, -8.745292529871103],
              [-35.199693964446304, -8.745286501731989],
              [-35.19971196017827, -8.745280258787547],
              [-35.199719682207935, -8.745277819376046],
              [-35.199727367819918, -8.74527568425073],
              [-35.199732365369321, -8.745274457941248],
              [-35.19973854665551, -8.745273129737804],
              [-35.199743775762286, -8.745272301173486],
              [-35.199753817104451, -8.745271216599035],
              [-35.199761076091526, -8.745271250438043],
              [-35.199771013749704, -8.745272028885976],
              [-35.199799233813863, -8.745276349946765],
              [-35.19982822328231, -8.745281578731651],
              [-35.199881926176367, -8.745292517257583],
              [-35.19999054731575, -8.745313170932194],
              [-35.200147044000857, -8.74534130013109],
              [-35.200275314377173, -8.74536404402812],
              [-35.200299406293034, -8.745368008949415],
              [-35.200316533683569, -8.745370037893014],
              [-35.200331480159036, -8.745371509388233],
              [-35.200343823930048, -8.745372159811337],
              [-35.200368583859621, -8.745372776088093],
              [-35.200394823659664, -8.745371673366117],
              [-35.200409333838437, -8.74537041076784],
              [-35.200431166248876, -8.745366939125551],
              [-35.200469787387213, -8.745359873111164],
              [-35.200548695414319, -8.745343146796268],
              [-35.200586962902221, -8.745334410448187],
              [-35.200625571977376, -8.745325292020171],
              [-35.200636255834773, -8.745323025424899],
              [-35.200648773650698, -8.74532078616814],
              [-35.200652669006402, -8.745320535476216],
              [-35.200657865932847, -8.745320733325782],
              [-35.200664329249932, -8.74532189302735],
              [-35.200668270955148, -8.74532302937827],
              [-35.200671544215517, -8.745324188614257],
              [-35.200676369496769, -8.745326156027218],
              [-35.2006817143101, -8.745328766550298],
              [-35.200689303699072, -8.745333017413641],
              [-35.200698353058144, -8.745338666102102],
              [-35.200707863971822, -8.745344844230946],
              [-35.200721127253239, -8.745352444876261],
              [-35.200722814875526, -8.745353575320802],
              [-35.200725500904554, -8.745355574167968],
              [-35.200727959727836, -8.745357916409541],
              [-35.200730842135428, -8.745360826320628],
              [-35.200734303383406, -8.745364721088752],
              [-35.20074492013714, -8.745377050177531],
              [-35.200749529039598, -8.745381204331363],
              [-35.20075520404837, -8.745384744156786],
              [-35.200759490357669, -8.745386011536475],
              [-35.200763006358706, -8.745386219146717],
              [-35.200773690662388, -8.745384028561116],
              [-35.200886775754668, -8.745358133479156],
              [-35.200969040234462, -8.745340475372256],
              [-35.200989342719197, -8.745336708519277],
              [-35.201013624562151, -8.745333982768484],
              [-35.20105915255354, -8.745332045718209],
              [-35.20114000765836, -8.745334616338145],
              [-35.201182183609241, -8.745334371130921],
              [-35.201207692399812, -8.745332398415895],
              [-35.201237466074048, -8.745328044367906],
              [-35.201357109470138, -8.745299982549151],
              [-35.2014936648782, -8.745263308434392],
              [-35.201602884894726, -8.745229833919408],
              [-35.201701102455338, -8.745196423362049],
              [-35.201915215396774, -8.745121289517167],
              [-35.20211011689085, -8.745053564952022],
              [-35.202197047751923, -8.745023868754316],
              [-35.202250136704393, -8.74500835653634],
              [-35.202312383606973, -8.744990966631539],
              [-35.202419203105677, -8.744965107591062],
              [-35.202725069341788, -8.744900842193038],
              [-35.202770400622846, -8.744891456410425],
              [-35.202797493311387, -8.744885977588247],
              [-35.202890161612871, -8.744870463077049],
              [-35.202926032444253, -8.744863488844477],
              [-35.202958994943991, -8.744855695352239],
              [-35.20299255617595, -8.744845769891128],
              [-35.203039818563354, -8.744826946727072],
              [-35.203093452542333, -8.744800180701294],
              [-35.203592709643829, -8.744515404066645],
              [-35.203667636906722, -8.744471486234772],
              [-35.203712571282495, -8.744446595184536],
              [-35.203732834330332, -8.744436138931357],
              [-35.203751582677533, -8.744427971998435],
              [-35.20378360321935, -8.744415926976151],
              [-35.203816249299081, -8.744406310847966],
              [-35.203848294859483, -8.744398522629233],
              [-35.204071745163972, -8.744352219901558],
              [-35.204499035668917, -8.744251214370102],
              [-35.205021165804467, -8.744137204036539],
              [-35.206140703972416, -8.744870691037075],
              [-35.206635796334382, -8.745354726915592],
              [-35.206844506250597, -8.745558691497777],
              [-35.208362140623471, -8.747042329524444],
              [-35.212078332568964, -8.747367901079018],
              [-35.213799257985258, -8.747749128475778],
              [-35.214621840046547, -8.747640822560276],
              [-35.214869470338684, -8.747599580829947],
              [-35.21490701778621, -8.747591911498585],
              [-35.214934776343092, -8.74758277911714],
              [-35.214966179893196, -8.747569824574706],
              [-35.215012490401577, -8.747545437404538],
              [-35.215187094993802, -8.747430941897195],
              [-35.215209322678994, -8.747416824773314],
              [-35.215245867828351, -8.747394718152602],
              [-35.215324146493209, -8.747349258259309],
              [-35.215354269986378, -8.747326581073063],
              [-35.215388619133378, -8.747294909215782],
              [-35.21541822664431, -8.747262504935374],
              [-35.215454275123612, -8.747221017001991],
              [-35.215507722870598, -8.747162931641158],
              [-35.215570566647926, -8.747091564421652],
              [-35.215587403671996, -8.747070333294845],
              [-35.215597084656096, -8.747053552998127],
              [-35.215618542827947, -8.747012682570187],
              [-35.215633629692014, -8.746979755143755],
              [-35.215646922397717, -8.746953527676604],
              [-35.215662985459772, -8.746930628708139],
              [-35.215682697996748, -8.746904667724667],
              [-35.215710622740275, -8.746871969222019],
              [-35.215767865493156, -8.746809604719507],
              [-35.2157950225821, -8.746776302573537],
              [-35.215817976021448, -8.74674279698716],
              [-35.215835462893942, -8.746715176658146],
              [-35.215857260810928, -8.746680081489064],
              [-35.2158673957152, -8.746662538377082],
              [-35.215874039358759, -8.746648968560152],
              [-35.215878789949009, -8.74663829844579],
              [-35.215882172435322, -8.746628852599187],
              [-35.215882680300808, -8.746624288643529],
              [-35.215881741410328, -8.746620569328899],
              [-35.215880033521856, -8.746616018334949],
              [-35.215870303071142, -8.746598591512054],
              [-35.215868444172472, -8.746594345464487],
              [-35.215858585157257, -8.746555178680536],
              [-35.215857302428134, -8.746544999977955],
              [-35.21585723949871, -8.746534358041179],
              [-35.215860088044963, -8.746525067360665],
              [-35.21586630112521, -8.746516213078722],
              [-35.215910480340789, -8.74646715190646],
              [-35.21598607279347, -8.746380962840979],
              [-35.216006698557628, -8.746354388372398],
              [-35.216033327028086, -8.746309230753356],
              [-35.216048111818239, -8.746276913227405],
              [-35.216052872275576, -8.746254992617349],
              [-35.21605216931686, -8.746239489374842],
              [-35.216048084761177, -8.74622066120507],
              [-35.216039070714942, -8.74619517243679],
              [-35.216023593681527, -8.746162119858852],
              [-35.215984655745942, -8.746089676050602],
              [-35.215967332415673, -8.746054505811264],
              [-35.215956173649637, -8.746028117396573],
              [-35.215946829715257, -8.74599852566543],
              [-35.215937739270565, -8.745960114547046],
              [-35.21592538493519, -8.745886602916858],
              [-35.215907331629722, -8.745727986202025],
              [-35.215894989866356, -8.745604911752833],
              [-35.215880066544756, -8.745484589009603],
              [-35.215877214379454, -8.745467425981834],
              [-35.215872691194704, -8.74545194509651],
              [-35.215864477201741, -8.745432532955672],
              [-35.215857870402509, -8.745413567501267],
              [-35.215856230396213, -8.745407571802229],
              [-35.215855894204509, -8.745402404643862],
              [-35.215856932415335, -8.745397077418863],
              [-35.21586140973573, -8.745378959294241],
              [-35.215862134229909, -8.745372265606024],
              [-35.215860571967355, -8.745366497489796],
              [-35.21585733060337, -8.745361043281928],
              [-35.215852572816871, -8.745357574365043],
              [-35.215836792942341, -8.745350825235946],
              [-35.215831728640744, -8.745347206090507],
              [-35.215814985161515, -8.745332556884046],
              [-35.215807752759872, -8.745324085364004],
              [-35.215805279933008, -8.745319386822647],
              [-35.215804337903364, -8.74531513540652],
              [-35.215803936097011, -8.745298870232007],
              [-35.215806862396256, -8.745276884342978],
              [-35.215817106940904, -8.745226197420877],
              [-35.215836817133948, -8.745135242377261],
              [-35.215887837115773, -8.744912139494875],
              [-35.215916725306748, -8.744783958688171],
              [-35.215939359093248, -8.744683484475905],
              [-35.215960734434326, -8.744576936316701],
              [-35.215978501667784, -8.744480443456363],
              [-35.21604025647661, -8.744094659921643],
              [-35.216062931497326, -8.743976416240454],
              [-35.216083296481749, -8.743905753783714],
              [-35.21610108527392, -8.743864600783251],
              [-35.216137941053624, -8.743791713347054],
              [-35.216170602667248, -8.743733141548244],
              [-35.216227300253642, -8.743656185131869],
              [-35.216256221621734, -8.743611166155945],
              [-35.216263476911486, -8.743597668773551],
              [-35.216268528143068, -8.743586160724478],
              [-35.216280896683145, -8.743558722412214],
              [-35.216311041404275, -8.743488002717372],
              [-35.216383355398449, -8.74331793982093],
              [-35.216481184152961, -8.743110573422829],
              [-35.216552533237234, -8.742978799395988],
              [-35.216744004787422, -8.742651660999162],
              [-35.217225646935205, -8.742258367313251],
              [-35.217445084559579, -8.74207919993883],
              [-35.216960352986142, -8.74072089134625],
              [-35.217483079450709, -8.739624594423775],
              [-35.219734513204592, -8.739080835349103],
              [-35.220558085674092, -8.738790837106366],
              [-35.220935748707753, -8.738731177160842],
              [-35.220997703589418, -8.738716522929138],
              [-35.221058106985303, -8.738697924947143],
              [-35.221199024801429, -8.738650576903892],
              [-35.221285356670151, -8.738623617125485],
              [-35.221649155311191, -8.7385373220355],
              [-35.221816451683168, -8.738507086929124],
              [-35.221878460493471, -8.73850155420525],
              [-35.221945084123256, -8.738501163505678],
              [-35.222019398524054, -8.738509241507913],
              [-35.222094374579918, -8.738525829423196],
              [-35.222183153672191, -8.738550850180971],
              [-35.22235220156648, -8.738608847229742],
              [-35.222447783450818, -8.738647206854665],
              [-35.222534507976683, -8.738686530612695],
              [-35.222593367677327, -8.738716895860355],
              [-35.222666495147202, -8.738756755439351],
              [-35.222691944379868, -8.738770669131195],
              [-35.222704826077255, -8.738778347209252],
              [-35.222716561279213, -8.738785955997763],
              [-35.222725153583461, -8.738791834845518],
              [-35.222733440728881, -8.738797791507698],
              [-35.222741116646098, -8.738803751755665],
              [-35.222786554626794, -8.738838452611517],
              [-35.222821862328544, -8.738865611284099],
              [-35.222850851803784, -8.738883837108313],
              [-35.222870164000739, -8.738893605905018],
              [-35.222886859563936, -8.738900197374322],
              [-35.222910139327084, -8.738909030687518],
              [-35.222986239187591, -8.738934733747039],
              [-35.223094563407116, -8.738970129795073],
              [-35.223156114211399, -8.738990445023498],
              [-35.223284715069575, -8.739030739093696],
              [-35.223355121458866, -8.739049786052707],
              [-35.223508766398901, -8.739087196490251],
              [-35.223661778489998, -8.739120961794383],
              [-35.223716857368231, -8.739132192965263],
              [-35.223762134989812, -8.73913983288169],
              [-35.223811061933674, -8.739144410733331],
              [-35.223902760293903, -8.739146304987361],
              [-35.224015039279237, -8.739142051755969],
              [-35.224097396148352, -8.739138473020439],
              [-35.224211653041181, -8.739130656753098],
              [-35.224223645875945, -8.739130168258725],
              [-35.22425351413407, -8.739129080716136],
              [-35.22428224040415, -8.739128684013215],
              [-35.224300277883998, -8.739129642342096],
              [-35.224317403034682, -8.739131366188033],
              [-35.224342484938461, -8.739134867699743],
              [-35.224366959230281, -8.739138980908132],
              [-35.224403074770805, -8.739147738761236],
              [-35.22460080918637, -8.73919857274414],
              [-35.224727858185219, -8.739234846537858],
              [-35.224737351124304, -8.739237983465825],
              [-35.22475036673719, -8.739242467998396],
              [-35.224765380556562, -8.739248917209403],
              [-35.224785458637101, -8.739258985454429],
              [-35.224832056127802, -8.739283036957081],
              [-35.224867291818299, -8.739298033237038],
              [-35.22488719093964, -8.739303693599268],
              [-35.224922981944147, -8.73930926062709],
              [-35.225011192788941, -8.739315736058391],
              [-35.225117553322477, -8.739316327638614],
              [-35.225405976166634, -8.739301862858238],
              [-35.225490310893505, -8.739298934946401],
              [-35.225553878369467, -8.739298561530441],
              [-35.225720782692399, -8.739304270443016],
              [-35.226178623172011, -8.739321688410572],
              [-35.224356707481022, -8.735293169100554],
              [-35.224216597868363, -8.734983421049348],
              [-35.22323988713768, -8.732823834889162],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0194000',
        uf: 'PE',
        nome_proje: 'PA GULANDY/GUABIRABA',
        municipio: 'LAGOA DOS GATOS',
        area_hecta: '1067.6000',
        capacidade: 93.0,
        num_famili: 86.0,
        fase: 5.0,
        data_de_cr: '06/10/1999',
        forma_obte: 'Desapropriação',
        data_obten: '09/07/1999',
        area_calc_: 960.8081,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.821383569377353, -8.643182525158949],
              [-35.818854875694583, -8.64163316674532],
              [-35.816919460556669, -8.64070282251949],
              [-35.816465641833624, -8.640853066563658],
              [-35.816300608478549, -8.641209980583067],
              [-35.816391188656567, -8.641402117023086],
              [-35.816109704989742, -8.642276497720395],
              [-35.816513295094808, -8.643222815412569],
              [-35.816152925224188, -8.64378056491209],
              [-35.815953830914168, -8.644392149917104],
              [-35.815339254969281, -8.64485247687128],
              [-35.815100953282041, -8.645148222387574],
              [-35.815022809310058, -8.64549383657657],
              [-35.813945672693357, -8.646579159873435],
              [-35.812664110407177, -8.647914171489996],
              [-35.811625081319235, -8.648985840661407],
              [-35.810666357413595, -8.65019921749103],
              [-35.809299721218395, -8.651928589050019],
              [-35.810244620777063, -8.653549454826768],
              [-35.811301339630397, -8.654601834677047],
              [-35.811386131589529, -8.654943270027472],
              [-35.811201319244773, -8.655510842180938],
              [-35.811317806658586, -8.656330258212202],
              [-35.813022408693811, -8.65885830965694],
              [-35.813568105522329, -8.659730398534917],
              [-35.810597055055183, -8.660993379308874],
              [-35.810917708653697, -8.661674234571112],
              [-35.811237341295715, -8.662486282475161],
              [-35.811595058835636, -8.663316028630742],
              [-35.811768985681276, -8.664044401070832],
              [-35.812001421439859, -8.664887626782383],
              [-35.812135844276781, -8.665809815398081],
              [-35.813743086819109, -8.666556177008356],
              [-35.814458310175745, -8.666769186575213],
              [-35.815025748835808, -8.667278451223595],
              [-35.815311218181805, -8.668767630471729],
              [-35.815934056041606, -8.669241699853547],
              [-35.816561201706726, -8.670366692593685],
              [-35.816510770331909, -8.670860725737871],
              [-35.818628193074083, -8.673843463594366],
              [-35.82075260773729, -8.677963783720315],
              [-35.823585613407232, -8.683000551806016],
              [-35.824417301816403, -8.684430459010162],
              [-35.825983925007158, -8.687183105112977],
              [-35.826412891252964, -8.687963593403982],
              [-35.827896806055065, -8.690555385915388],
              [-35.828622738360238, -8.691668669911795],
              [-35.829287345240665, -8.69290952493432],
              [-35.829465544241451, -8.693291361567672],
              [-35.831118853931777, -8.696354923809725],
              [-35.832718478767354, -8.698964516814277],
              [-35.833170150357795, -8.697665041607962],
              [-35.833591003198684, -8.693293742696586],
              [-35.833717084450377, -8.691114639338554],
              [-35.833557168449822, -8.689154676558077],
              [-35.833524303011288, -8.686581037518312],
              [-35.834527746624481, -8.684170445987533],
              [-35.835015469339147, -8.681144366578119],
              [-35.836365873047754, -8.675004860001874],
              [-35.838165707893864, -8.671068637305391],
              [-35.838674188147372, -8.669067480399438],
              [-35.837844430939398, -8.668546750524053],
              [-35.837621312477033, -8.668275111492074],
              [-35.837262126909124, -8.666846152763561],
              [-35.835020467835399, -8.665973794600166],
              [-35.835002994857248, -8.6659077906659],
              [-35.834980522405488, -8.664908544026257],
              [-35.83539666168317, -8.663230791445601],
              [-35.835619064494665, -8.661651600018823],
              [-35.833820873853341, -8.661338249629283],
              [-35.833966138387389, -8.660416991572188],
              [-35.834259350424794, -8.658920573875971],
              [-35.833881328923241, -8.65700875110014],
              [-35.832400922913443, -8.65576083239193],
              [-35.831933198006389, -8.656055214346779],
              [-35.828817970445201, -8.658065553730811],
              [-35.828502123487191, -8.658322399101715],
              [-35.82620995401733, -8.659784009209647],
              [-35.822828342307389, -8.661779213401068],
              [-35.820965206819999, -8.660683959047002],
              [-35.820755272162508, -8.660437143386973],
              [-35.820719090368179, -8.660175131332451],
              [-35.819868801373637, -8.65746682052416],
              [-35.819638521423613, -8.657084451265165],
              [-35.819009218043341, -8.656531647349418],
              [-35.818805499190859, -8.656484994318372],
              [-35.818351654528243, -8.656414345969102],
              [-35.82045628533406, -8.655453063273445],
              [-35.822033278477996, -8.654705273501914],
              [-35.823050649880365, -8.654629188917285],
              [-35.827800401567721, -8.654481786376548],
              [-35.830261967812582, -8.654091613877515],
              [-35.831765890799623, -8.653957787408839],
              [-35.83102910174177, -8.652823878906167],
              [-35.830418251315848, -8.652128573371266],
              [-35.829866527442043, -8.651472490973884],
              [-35.829866979947163, -8.6509376335071],
              [-35.829830538688142, -8.650616990638333],
              [-35.829793223727322, -8.650057838145191],
              [-35.829549821352316, -8.647298543657215],
              [-35.829360058528067, -8.646627584254562],
              [-35.82896473616065, -8.643837698392108],
              [-35.829152485406148, -8.642086017160651],
              [-35.828445120560993, -8.642420558799945],
              [-35.826426884186702, -8.644033978173072],
              [-35.826346720842416, -8.644095102759001],
              [-35.825218524314955, -8.6450353643882],
              [-35.824434876784146, -8.644552187999379],
              [-35.822830566950728, -8.643795978748821],
              [-35.822164580226627, -8.643604925470644],
              [-35.821383569377353, -8.643182525158949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0103000',
        uf: 'PE',
        nome_proje: 'PA SÃO GREGÓRIO/ALEGRE',
        municipio: 'GAMELEIRA',
        area_hecta: '912.2900',
        capacidade: 106.0,
        num_famili: 102.0,
        fase: 5.0,
        data_de_cr: '19/05/1997',
        forma_obte: 'Desapropriação',
        data_obten: '31/05/1996',
        area_calc_: 1179.421,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.417892462148842, -8.530542685045662],
              [-35.41004221657532, -8.543388326102193],
              [-35.408481183188648, -8.553052879403557],
              [-35.415393608197292, -8.554604735757982],
              [-35.415693773405813, -8.552184576956256],
              [-35.422804742105797, -8.5499298799018],
              [-35.428678082312231, -8.547973489290737],
              [-35.436658549463772, -8.545350991100475],
              [-35.438201085829647, -8.548664085691867],
              [-35.441425748657409, -8.555623104624756],
              [-35.442620362504329, -8.556741625138551],
              [-35.445539496995572, -8.566385706552461],
              [-35.447078274861582, -8.57139262250727],
              [-35.452738958682978, -8.574476393404897],
              [-35.452719002724564, -8.575979343518894],
              [-35.459230689038471, -8.575753258486985],
              [-35.459417027107669, -8.566650583709233],
              [-35.459540198075061, -8.562137734682999],
              [-35.459792710187209, -8.554067603969226],
              [-35.45985361455471, -8.551999747520275],
              [-35.459602256458098, -8.543470467972011],
              [-35.458510414720891, -8.536381101684869],
              [-35.458108475561374, -8.534231299398519],
              [-35.451315052333854, -8.536368932878206],
              [-35.441398841737715, -8.540073170487309],
              [-35.430165040059975, -8.543281725456428],
              [-35.422293173775294, -8.538908419229628],
              [-35.423466343493224, -8.532876549340104],
              [-35.417892462148842, -8.530542685045662],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0410000',
        uf: 'PE',
        nome_proje: 'PA AGAPTO SANTOS',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '377.9200',
        capacidade: 61.0,
        num_famili: 60.0,
        fase: 3.0,
        data_de_cr: '14/07/2014',
        forma_obte: 'Desapropriação',
        data_obten: '23/12/2011',
        area_calc_: 377.7089,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.126382605016147, -8.004808434076452],
              [-35.126545459252853, -8.005609223428664],
              [-35.127179514127263, -8.007352488324264],
              [-35.127275802585572, -8.01014340792895],
              [-35.139562067835449, -8.012878462897321],
              [-35.143977455812951, -8.013733703713839],
              [-35.144519059247088, -8.011988801075914],
              [-35.143366539835434, -8.009235587986884],
              [-35.141532279156166, -8.004453560840636],
              [-35.137851692894884, -8.002705334621838],
              [-35.137888774019267, -8.002407923699808],
              [-35.137879731860075, -8.002360784333614],
              [-35.137843383770601, -8.002172589386621],
              [-35.13728939832361, -7.999302341891053],
              [-35.134366341533806, -7.997255367232062],
              [-35.134244080100743, -7.99461835596093],
              [-35.133730585346449, -7.986824865582645],
              [-35.133709418794737, -7.986503257859426],
              [-35.13369554084025, -7.986292979555044],
              [-35.133559106464922, -7.984222274424988],
              [-35.133510083935008, -7.984264109146775],
              [-35.12437408098743, -7.98870690222979],
              [-35.124781457670679, -7.996528475113277],
              [-35.124940434639242, -7.997370597843199],
              [-35.125628178328775, -8.001097509490513],
              [-35.126382605016147, -8.004808434076452],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0265000',
        uf: 'PE',
        nome_proje: 'PA VENEZA',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '573.7902',
        capacidade: 62.0,
        num_famili: 56.0,
        fase: 5.0,
        data_de_cr: '10/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '22/11/2001',
        area_calc_: 555.3146,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.217365286049713, -8.019679960292411],
              [-35.215962466341999, -8.019774647336995],
              [-35.214385504935755, -8.01988120342506],
              [-35.210711644584677, -8.020137765434558],
              [-35.210225216104483, -8.020171742054721],
              [-35.209941208907402, -8.020191525201158],
              [-35.208166005122933, -8.020315482846398],
              [-35.204000069982392, -8.020606352619282],
              [-35.203923107580962, -8.020611736049432],
              [-35.202087890093047, -8.020741322681159],
              [-35.201642161622438, -8.020772812070026],
              [-35.197198915953607, -8.021086520781294],
              [-35.195885745768472, -8.021060984905846],
              [-35.190719986667375, -8.020647207627347],
              [-35.187870516487919, -8.020454003389737],
              [-35.191783440830214, -8.028680907537213],
              [-35.195465287495409, -8.031607859979012],
              [-35.199147182728865, -8.034534584028485],
              [-35.202819163426, -8.03593379399492],
              [-35.203357263154672, -8.036345714548624],
              [-35.204266881885111, -8.037692273721872],
              [-35.204741953242504, -8.037818080378681],
              [-35.205237674688874, -8.037804573469669],
              [-35.206295659268299, -8.037991427116609],
              [-35.206515405810151, -8.038076841689058],
              [-35.207191938931722, -8.038512327162248],
              [-35.207979498841162, -8.039605802045628],
              [-35.208149656725539, -8.039811701704959],
              [-35.2092203606352, -8.040403970578375],
              [-35.209860650055646, -8.040643225728695],
              [-35.210120879479902, -8.04076837268264],
              [-35.210873337304378, -8.041389917204006],
              [-35.211492595779745, -8.041228851925892],
              [-35.211898234298978, -8.041326820550081],
              [-35.212233769563547, -8.041658825906408],
              [-35.21276134292021, -8.042367719086208],
              [-35.21294360764567, -8.043066178889484],
              [-35.213243648330469, -8.043491566668997],
              [-35.213307989199329, -8.043649764816411],
              [-35.213725488929484, -8.044362050947775],
              [-35.213764351937137, -8.044721776250315],
              [-35.214194005496815, -8.0463191893576],
              [-35.214500244177657, -8.046732790987447],
              [-35.21493253299041, -8.046844079327341],
              [-35.215108224711287, -8.046948887995528],
              [-35.215593646207317, -8.047525308681395],
              [-35.217014088863891, -8.048502711626183],
              [-35.217257500209428, -8.042981173397457],
              [-35.217233626199544, -8.042159293211062],
              [-35.217254234292, -8.040360777122574],
              [-35.217268257756039, -8.039069295564047],
              [-35.217279145343646, -8.03694976548033],
              [-35.217280006478802, -8.036607362435113],
              [-35.217337812186983, -8.034326232432344],
              [-35.217258624284113, -8.032585471198217],
              [-35.217335434936771, -8.030396346205769],
              [-35.217381364793852, -8.030069970368377],
              [-35.21737110839544, -8.029115684797528],
              [-35.217361956251686, -8.028264528560847],
              [-35.217346336986914, -8.026806164990568],
              [-35.217337536762734, -8.025986191409162],
              [-35.217337967089513, -8.025881607485816],
              [-35.217351974527453, -8.022711723975515],
              [-35.217352423707304, -8.022610574764169],
              [-35.217365286049713, -8.019679960292411],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0382000',
        uf: 'PE',
        nome_proje: 'PA CHICO MENDES III',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '411.0362',
        capacidade: 55.0,
        num_famili: 55.0,
        fase: 3.0,
        data_de_cr: '17/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '21/12/2007',
        area_calc_: 395.6849,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.097957871896241, -7.958747306113405],
              [-35.100179843297184, -7.971084848015989],
              [-35.099974410243576, -7.971390799467385],
              [-35.099808946399492, -7.971793196559529],
              [-35.099527148962451, -7.972386395050916],
              [-35.09911915712263, -7.97249817323789],
              [-35.098496285307306, -7.972368300895909],
              [-35.098293670889824, -7.972047686643811],
              [-35.098312613164261, -7.971845794963466],
              [-35.098222406239472, -7.971647175064605],
              [-35.098086428457428, -7.971492442573973],
              [-35.097907422746452, -7.971349872828507],
              [-35.097765691850988, -7.97124586296224],
              [-35.097472680663614, -7.971084770194564],
              [-35.097146026227335, -7.97092580167021],
              [-35.096775477684176, -7.970756816519372],
              [-35.09648670771837, -7.970671065804149],
              [-35.096353953111901, -7.970743677472081],
              [-35.096223406417842, -7.970886198626205],
              [-35.096183694280164, -7.971051576612183],
              [-35.095960159118484, -7.971114312630288],
              [-35.095861590973755, -7.971196870998058],
              [-35.095736173008078, -7.971119512810963],
              [-35.095585725059635, -7.971077811796657],
              [-35.095403960448699, -7.971052733020569],
              [-35.095253250176562, -7.970877670245212],
              [-35.095075019423561, -7.970669780506714],
              [-35.095001568893338, -7.97057077092921],
              [-35.094844491043133, -7.970420301749283],
              [-35.094595813198843, -7.970250725424434],
              [-35.094466422559954, -7.970103726602084],
              [-35.094331025517391, -7.969851923199028],
              [-35.09418257185191, -7.969676328678986],
              [-35.094051030450643, -7.969506857542435],
              [-35.093743067478577, -7.969164462230783],
              [-35.093517355852363, -7.968735863193036],
              [-35.093403705316057, -7.968569502316877],
              [-35.093280759804578, -7.96851243695068],
              [-35.093119705189643, -7.968520086999841],
              [-35.093028610018052, -7.968565710342784],
              [-35.092882919338116, -7.96858456106765],
              [-35.092732743725158, -7.968550548281206],
              [-35.092634113188943, -7.968446733206384],
              [-35.092534173346614, -7.968299949031124],
              [-35.092517604716811, -7.96806293881802],
              [-35.09255097736785, -7.967879870989307],
              [-35.092539764, -7.967730891431592],
              [-35.092528851513919, -7.967662575984842],
              [-35.092552488393949, -7.967488896658503],
              [-35.092576419553119, -7.967408093404156],
              [-35.092707936002853, -7.967191647992016],
              [-35.09281183636255, -7.967059740227109],
              [-35.092857270065423, -7.966989882136244],
              [-35.092891662532097, -7.966794019351986],
              [-35.092885894717142, -7.966696185426326],
              [-35.092804079074703, -7.966538439390477],
              [-35.09274032270821, -7.966451791218204],
              [-35.092670763450634, -7.966374197445437],
              [-35.092525026231016, -7.966156823212677],
              [-35.091984322519686, -7.967351854176856],
              [-35.091605346826775, -7.968246191683024],
              [-35.091178064359688, -7.969092959609305],
              [-35.090902923083512, -7.969535338895637],
              [-35.090534846351694, -7.970024767538376],
              [-35.090208710974039, -7.97046116326501],
              [-35.089739594548064, -7.970934893428672],
              [-35.087824752625686, -7.97295385302532],
              [-35.086841665831535, -7.973988205919182],
              [-35.0859458788496, -7.97492204249139],
              [-35.08556812717449, -7.975343780640133],
              [-35.08530448936687, -7.975711591132424],
              [-35.085014899644399, -7.976137274720726],
              [-35.084776584952259, -7.976501910313537],
              [-35.084463332230747, -7.97699998843143],
              [-35.08437465729056, -7.977139785238897],
              [-35.0845096580477, -7.977314976887486],
              [-35.084594806395927, -7.977467706848326],
              [-35.084652095824609, -7.977668396359586],
              [-35.084635969338038, -7.977908123653141],
              [-35.084559048071903, -7.978020571725858],
              [-35.084549908359669, -7.97818332666299],
              [-35.084609881587333, -7.978352000716457],
              [-35.084858194852536, -7.978632202994158],
              [-35.085048758702662, -7.978767575110036],
              [-35.085357851877333, -7.979042848605093],
              [-35.08557067032487, -7.979256872425665],
              [-35.085819806777153, -7.979490031803535],
              [-35.085994851878823, -7.979725162613214],
              [-35.086191572175096, -7.980032652589219],
              [-35.086390664990681, -7.980237077283973],
              [-35.086578539594761, -7.980447383907249],
              [-35.086812452595616, -7.980643902652505],
              [-35.087037558582551, -7.980887557163162],
              [-35.087280304928797, -7.980946827408068],
              [-35.087480394224215, -7.980791462296302],
              [-35.087959483128543, -7.980371245434123],
              [-35.088144738668866, -7.979938795322432],
              [-35.088639277406671, -7.978797485389208],
              [-35.088920817202222, -7.978383271492654],
              [-35.089063077225155, -7.978206017755417],
              [-35.089358018363001, -7.978084601457399],
              [-35.089713972260249, -7.978118022808682],
              [-35.090073007528311, -7.978145725469507],
              [-35.090628288414962, -7.978202551593943],
              [-35.09088285529392, -7.978148064066932],
              [-35.091301622366018, -7.977907293828922],
              [-35.091523067425044, -7.977887383438905],
              [-35.091776939522845, -7.977988918423475],
              [-35.091962177135613, -7.978108710121532],
              [-35.092002641640164, -7.978266571256922],
              [-35.091954969921446, -7.978540828777562],
              [-35.091849797896565, -7.978745335157706],
              [-35.091606887857949, -7.97920798401652],
              [-35.091487028589526, -7.979514738228316],
              [-35.091400341168161, -7.97981065071049],
              [-35.091408187472702, -7.980142429828217],
              [-35.091668123148295, -7.980612628329498],
              [-35.091842371791849, -7.981165541657075],
              [-35.092065969109996, -7.981442101019042],
              [-35.092359485174029, -7.981543201447232],
              [-35.092831857190248, -7.981661317158367],
              [-35.092931580056231, -7.981970783515984],
              [-35.092896367521995, -7.982407799010101],
              [-35.092861525573419, -7.982662759019825],
              [-35.092756048365409, -7.98301715366659],
              [-35.0926703455713, -7.983338964444738],
              [-35.092551054004964, -7.983553779803509],
              [-35.092355066285776, -7.984096805976973],
              [-35.092175854888829, -7.984372549468109],
              [-35.092086192096247, -7.984497541540918],
              [-35.091949751315539, -7.984739863274482],
              [-35.091756126541789, -7.985004485936677],
              [-35.091522511358924, -7.985241722389978],
              [-35.091386527089384, -7.985274253366585],
              [-35.09128359808124, -7.985274246132636],
              [-35.091286060478495, -7.98538953167641],
              [-35.091308898951624, -7.985560532009783],
              [-35.091581716012719, -7.985676670626764],
              [-35.092179841255245, -7.985832832452679],
              [-35.092566055493364, -7.985921231403562],
              [-35.093290888396403, -7.985972206299099],
              [-35.09380169733479, -7.986044912130283],
              [-35.09844347703298, -7.979679410966567],
              [-35.100912597631286, -7.976499540591478],
              [-35.103640404846324, -7.979610160951379],
              [-35.104020405834504, -7.974072190615304],
              [-35.104675389704134, -7.970188481705152],
              [-35.104739401674628, -7.969820660205264],
              [-35.109824749835276, -7.961540356532198],
              [-35.109558773654619, -7.956350360516963],
              [-35.110073956893231, -7.951753099897994],
              [-35.109655454339986, -7.951495514265784],
              [-35.109624410271095, -7.951879298970775],
              [-35.109569331662463, -7.952385508956085],
              [-35.109516380861869, -7.952663643703041],
              [-35.109433625435464, -7.952941517632797],
              [-35.109372877632204, -7.953093615681188],
              [-35.109278906572001, -7.953272599974621],
              [-35.109205366004552, -7.95339209193829],
              [-35.10911880134644, -7.953530253682866],
              [-35.108972526870922, -7.953705324972364],
              [-35.108829602332975, -7.953823934455293],
              [-35.108626883454384, -7.953995793843688],
              [-35.108175978430459, -7.954246194323879],
              [-35.107930175318536, -7.954311683121482],
              [-35.107776526028047, -7.954360164497002],
              [-35.107584986897884, -7.954399608524043],
              [-35.107345259252696, -7.954432337811877],
              [-35.107076579727384, -7.954432596701481],
              [-35.10689802174516, -7.954409329565578],
              [-35.106701136019723, -7.954366232449308],
              [-35.10636403626242, -7.954252746755907],
              [-35.105940017162254, -7.954063009002212],
              [-35.105387041013991, -7.953770545726402],
              [-35.105139781478265, -7.95366077239991],
              [-35.104887568469138, -7.953569914212321],
              [-35.104716885745702, -7.953529147799968],
              [-35.104580937510498, -7.95351658849303],
              [-35.104228407737935, -7.953534766569596],
              [-35.104003304045605, -7.95359379258243],
              [-35.103842250157818, -7.953654730873181],
              [-35.103506071444599, -7.953868599279918],
              [-35.102990954000873, -7.954345031484825],
              [-35.102470665451023, -7.954861521045927],
              [-35.102025231351206, -7.955250363922749],
              [-35.101641478399422, -7.955518341626794],
              [-35.101265093839515, -7.955718497648214],
              [-35.10082302753667, -7.956005677995118],
              [-35.100556816104763, -7.956174292443119],
              [-35.10026166401174, -7.956397043107419],
              [-35.100020152708858, -7.956599578111359],
              [-35.099880223426084, -7.956721215289256],
              [-35.099378696123296, -7.95726091348306],
              [-35.098968740697316, -7.957716218569904],
              [-35.098463202694923, -7.958267957473057],
              [-35.098125827996896, -7.958595420542732],
              [-35.097957871896241, -7.958747306113405],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0122000',
        uf: 'PE',
        nome_proje: 'PA CONCÓRDIA/SANTA CRUZ',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '1008.0036',
        capacidade: 96.0,
        num_famili: 85.0,
        fase: 6.0,
        data_de_cr: '04/11/1997',
        forma_obte: 'Desapropriação',
        data_obten: '08/05/1997',
        area_calc_: 1007.3937,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.173238165710629, -7.989213086143559],
              [-35.17028048690895, -7.98978092608465],
              [-35.166106740402576, -7.990857436384402],
              [-35.160819463042614, -7.992220677135238],
              [-35.154686112182127, -7.992830231428765],
              [-35.152898691840868, -7.995958767002737],
              [-35.150489491895222, -8.000758217639248],
              [-35.149007471528279, -8.004809710202764],
              [-35.148592729056816, -8.006479368051258],
              [-35.147429115469464, -8.008928868817385],
              [-35.147239229562437, -8.009328495363668],
              [-35.145419677629505, -8.01025528638225],
              [-35.144383413759662, -8.012558949877484],
              [-35.143616829100118, -8.014911921891736],
              [-35.141984869393582, -8.02048119522809],
              [-35.138088844833867, -8.033455697615318],
              [-35.138097171702753, -8.033764351433399],
              [-35.140943295003886, -8.033965491820515],
              [-35.142789735745303, -8.030951981956743],
              [-35.149132250133732, -8.02029263491322],
              [-35.152746200552329, -8.018235108959331],
              [-35.15309829078052, -8.018122807545295],
              [-35.154484014173697, -8.01768058768557],
              [-35.159115248631139, -8.015422086630492],
              [-35.159556702923702, -8.014303777246816],
              [-35.164050301670912, -8.009300630656611],
              [-35.165337409745689, -8.010077942447786],
              [-35.166128267131555, -8.010781022650182],
              [-35.167091723954002, -8.011248714113032],
              [-35.168415113776796, -8.011323289395637],
              [-35.168659576232407, -8.012740447215871],
              [-35.169845426202109, -8.015951102165801],
              [-35.169824222714432, -8.016347766681355],
              [-35.169593673509233, -8.018036341880146],
              [-35.169396813890884, -8.019733417559095],
              [-35.172011433679018, -8.01998159335921],
              [-35.173667690494462, -8.020077709500979],
              [-35.177927841586737, -8.020196754441724],
              [-35.182645850715765, -8.020179005486705],
              [-35.182492831348043, -8.018948768301602],
              [-35.182074452675224, -8.015895283584614],
              [-35.181860156504044, -8.014073030617604],
              [-35.18141769814001, -8.011585704597156],
              [-35.181119955296161, -8.009945488738554],
              [-35.181017493829529, -8.009470568358529],
              [-35.182913235405699, -8.009097883728627],
              [-35.182112959601398, -8.008349153930224],
              [-35.181996641768094, -8.008303398293188],
              [-35.181189065542, -8.007766945071486],
              [-35.180755621574654, -8.007549222871653],
              [-35.180410215454032, -8.007209276463813],
              [-35.18009720637378, -8.00683950974498],
              [-35.179787899423914, -8.006466649735184],
              [-35.179421687820053, -8.006153116104414],
              [-35.17898384053354, -8.005959549314797],
              [-35.178600579311215, -8.005662646505161],
              [-35.178172793713586, -8.005434043662],
              [-35.177846937271759, -8.005239883979339],
              [-35.176300941177303, -8.00495905817791],
              [-35.175874124831338, -8.00445149632068],
              [-35.175482162965586, -8.003830217063033],
              [-35.174602041827178, -8.002282184791628],
              [-35.175002676662757, -8.001701200127382],
              [-35.175071484568647, -8.001013292735749],
              [-35.174983896203358, -8.000314407369377],
              [-35.174889051374571, -7.999597642743034],
              [-35.175245309890911, -7.998360914208755],
              [-35.174954297558578, -7.996133820444751],
              [-35.174683135531119, -7.994057938017996],
              [-35.17446822161557, -7.993355725047929],
              [-35.174727835574089, -7.991112443208759],
              [-35.174635803054557, -7.99065147364522],
              [-35.173238165710629, -7.989213086143559],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0286000',
        uf: 'PE',
        nome_proje: 'PA SANTO ANTÔNIO',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '338.2061',
        capacidade: 36.0,
        num_famili: 36.0,
        fase: 4.0,
        data_de_cr: '23/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '06/08/2001',
        area_calc_: 340.8606,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.169388775380824, -8.019706425709089],
              [-35.168815106905129, -8.022925184424214],
              [-35.168619059202115, -8.024108740795352],
              [-35.16798142447059, -8.027578769616765],
              [-35.167675782040504, -8.029318731935721],
              [-35.16762126626864, -8.029624277096401],
              [-35.168949406682323, -8.029265737204927],
              [-35.169692038437091, -8.029176851393721],
              [-35.170121887499171, -8.029191035509818],
              [-35.170474075975427, -8.029540896765258],
              [-35.17105629194868, -8.030205557473789],
              [-35.171121682482934, -8.030581789587446],
              [-35.171319649141857, -8.03078521348211],
              [-35.171535868853383, -8.030976157002303],
              [-35.171982552231938, -8.031017819633556],
              [-35.172183659744668, -8.030958996411059],
              [-35.172549504576878, -8.031284105603175],
              [-35.172898268635691, -8.031211033424951],
              [-35.173099414474088, -8.031023038123942],
              [-35.172891858879346, -8.029903353123949],
              [-35.172733268121178, -8.029634910366093],
              [-35.17268195025833, -8.029330105314724],
              [-35.17288860033662, -8.029290506410183],
              [-35.173190426559181, -8.029566146980788],
              [-35.17328540874486, -8.029793706412361],
              [-35.173483854275446, -8.029967748865486],
              [-35.173722207051732, -8.030347133645247],
              [-35.174102501100684, -8.030763009714066],
              [-35.174337319957772, -8.030835980863632],
              [-35.174531724991944, -8.030557356725657],
              [-35.1745839809317, -8.030526889244159],
              [-35.174773231182087, -8.03084741577594],
              [-35.174817533757626, -8.031060598898772],
              [-35.17510024980934, -8.031306418411454],
              [-35.175307954220095, -8.031464772655971],
              [-35.175557271966483, -8.031399274960782],
              [-35.175665519578452, -8.032039858019179],
              [-35.175936953533508, -8.032244517241157],
              [-35.175782119973917, -8.032648036141987],
              [-35.175902011501755, -8.032886490479461],
              [-35.176446134771446, -8.033190674653415],
              [-35.176555662725256, -8.033049895809702],
              [-35.176309697236739, -8.032433996418071],
              [-35.176394380061836, -8.032276897677256],
              [-35.176946903152697, -8.032455662222013],
              [-35.177040493200245, -8.03269371295721],
              [-35.177519285309096, -8.032974287416939],
              [-35.177952338764882, -8.033231872522832],
              [-35.178190077563393, -8.033443939634967],
              [-35.178591231932636, -8.034215845714977],
              [-35.178900558798539, -8.034400143855819],
              [-35.179693192897567, -8.034571845798384],
              [-35.180223462523855, -8.034335730908767],
              [-35.180301079832105, -8.034401305764455],
              [-35.180174810358338, -8.03451460444702],
              [-35.180242051675819, -8.034777018727366],
              [-35.179983992640707, -8.035022898966213],
              [-35.179944015017156, -8.035246832359674],
              [-35.180166812862907, -8.035649614677045],
              [-35.180939140909821, -8.035839409226565],
              [-35.181139060995555, -8.036169546173774],
              [-35.181522911646759, -8.036247235081712],
              [-35.181692214859559, -8.036125753004082],
              [-35.182000965135749, -8.03608289510602],
              [-35.182093131161423, -8.03629130252415],
              [-35.182046968244983, -8.03637579328003],
              [-35.182130196472656, -8.036591750684904],
              [-35.182368079772765, -8.036711342144802],
              [-35.182615529097497, -8.036550666431477],
              [-35.182675020095935, -8.035820884498817],
              [-35.182731481033102, -8.035728836468792],
              [-35.183105716886772, -8.035704522083455],
              [-35.183490133246011, -8.036006830957506],
              [-35.183948951893861, -8.036180296588322],
              [-35.184281829257699, -8.036002624415207],
              [-35.184389530799173, -8.036029531273906],
              [-35.184321576605576, -8.036227760989009],
              [-35.18442355393524, -8.036371304210496],
              [-35.18429769480408, -8.036578067378047],
              [-35.184407648392778, -8.037008925032172],
              [-35.185187829406381, -8.037394638847514],
              [-35.185280046549728, -8.037120892978836],
              [-35.18609929392715, -8.034690682566803],
              [-35.186149287767961, -8.034576613024342],
              [-35.186241444319549, -8.03451194453322],
              [-35.187338915697389, -8.033741389830364],
              [-35.188356693943703, -8.033026577314198],
              [-35.189648340999497, -8.031244674505022],
              [-35.191782900117687, -8.028681543157925],
              [-35.187870516487919, -8.020454003389737],
              [-35.186988650221309, -8.020243283371052],
              [-35.185659264818419, -8.019933426160602],
              [-35.18299215480193, -8.020129803664432],
              [-35.182637151066267, -8.020152746788217],
              [-35.178818043861561, -8.019854788741533],
              [-35.177918759524566, -8.020170857835556],
              [-35.177067994533672, -8.020419507029239],
              [-35.175994768280795, -8.020326018626449],
              [-35.174518321593574, -8.020193075933266],
              [-35.172003865750021, -8.019955595377779],
              [-35.170713085946254, -8.019845884411909],
              [-35.169388775380824, -8.019706425709089],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0213000',
        uf: 'PE',
        nome_proje: 'PA SITIO II',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '411.5805',
        capacidade: 48.0,
        num_famili: 31.0,
        fase: 5.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '13/05/1999',
        area_calc_: 411.7843,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.155718911764808, -7.971064198059167],
              [-35.155233855123477, -7.971318378012921],
              [-35.155564334015246, -7.971779022293288],
              [-35.155494091362847, -7.972408437625831],
              [-35.15522071131609, -7.973293458832009],
              [-35.155170670105811, -7.974352048907298],
              [-35.155161885560254, -7.974838955186113],
              [-35.155496513688043, -7.975017729594837],
              [-35.156015343087546, -7.97473607353878],
              [-35.156120882564139, -7.975443939117655],
              [-35.156015554088505, -7.975849270428629],
              [-35.155968928314323, -7.976659169281551],
              [-35.154784160010891, -7.975070792268983],
              [-35.154552585299804, -7.974051813437342],
              [-35.154307921903751, -7.973771419320194],
              [-35.154083521615817, -7.974122681714947],
              [-35.154337960125886, -7.97519632076034],
              [-35.15441439012109, -7.975748409727109],
              [-35.154063063261674, -7.97561898596486],
              [-35.15381582163841, -7.975937554399379],
              [-35.153510896086303, -7.976149758149193],
              [-35.152986998989732, -7.976434150127463],
              [-35.153160661571626, -7.976107829178978],
              [-35.153164136735164, -7.975697603919156],
              [-35.153286371756138, -7.975264795357321],
              [-35.153204789575696, -7.974819487654698],
              [-35.153106184252259, -7.974344076852969],
              [-35.152685283422564, -7.973897640382206],
              [-35.15211941758195, -7.973837757724347],
              [-35.15170457811071, -7.973336328828719],
              [-35.151578624562596, -7.973162251735795],
              [-35.151203016510642, -7.972723893939541],
              [-35.150812610779575, -7.972698532100217],
              [-35.150483607642741, -7.972519723651123],
              [-35.149747591031272, -7.972364902868078],
              [-35.149568705109417, -7.972767994379536],
              [-35.14984161273545, -7.973040019848587],
              [-35.150384143427893, -7.972955215977477],
              [-35.15091114653503, -7.973143933841333],
              [-35.150983885975826, -7.973476748210022],
              [-35.150916484593509, -7.973506024344165],
              [-35.148924777945396, -7.973370106221198],
              [-35.1493983169971, -7.974312085901893],
              [-35.149905405081057, -7.974437541866194],
              [-35.150229669387592, -7.974802405885682],
              [-35.150756474747148, -7.974952797317574],
              [-35.150355781540071, -7.975110898437212],
              [-35.149840852973412, -7.975064307575744],
              [-35.149414925355167, -7.974853008066086],
              [-35.149873658513734, -7.97624061537167],
              [-35.150595980904136, -7.977434403123983],
              [-35.150872173848008, -7.977605530155329],
              [-35.151251807701598, -7.977737611536755],
              [-35.151753515961552, -7.977423144807462],
              [-35.151721749556529, -7.977833607860607],
              [-35.152220411692795, -7.978011531894931],
              [-35.152774749341852, -7.978033326658718],
              [-35.153242400757918, -7.977691736524885],
              [-35.154226099146705, -7.977610054684401],
              [-35.154838922473367, -7.977995286753854],
              [-35.15453622113575, -7.978511022098973],
              [-35.154195467213682, -7.97823664285799],
              [-35.153787785188946, -7.978151172907622],
              [-35.153437687500826, -7.978256947389986],
              [-35.153082454579916, -7.978455763578636],
              [-35.152710904265007, -7.97879414212023],
              [-35.152470489197242, -7.979326004466953],
              [-35.152268697032738, -7.979666211745693],
              [-35.15272790808109, -7.979877152656517],
              [-35.153451706911056, -7.979843376008776],
              [-35.153860221152748, -7.980087392208663],
              [-35.15439093674923, -7.979909627224917],
              [-35.154658441850259, -7.980239256290879],
              [-35.154230070815032, -7.98051492768818],
              [-35.153762819444289, -7.980933171363044],
              [-35.15421248906128, -7.981486028857669],
              [-35.154381485235909, -7.981636287234638],
              [-35.154514529060087, -7.98191662852333],
              [-35.15491685081269, -7.98205129882265],
              [-35.155106933666964, -7.981640011706893],
              [-35.155470389850407, -7.981919875389678],
              [-35.1551611769423, -7.982266067026244],
              [-35.154845505607916, -7.982470102905333],
              [-35.154683809341641, -7.982916857320896],
              [-35.155058242515807, -7.983128235929436],
              [-35.155459069554801, -7.982994895898659],
              [-35.155602791021529, -7.98342631907168],
              [-35.155837419521909, -7.983952995165084],
              [-35.156223115795733, -7.985239274506531],
              [-35.15633943324373, -7.985856779509152],
              [-35.156066453473024, -7.986195823089274],
              [-35.155848635865873, -7.985589875120513],
              [-35.155360202471719, -7.983940838472527],
              [-35.154462752292396, -7.983785336481762],
              [-35.154203227118394, -7.982193136955263],
              [-35.153547326529669, -7.982362243603037],
              [-35.153104784398565, -7.98292797167789],
              [-35.152961740933577, -7.98286788115155],
              [-35.153477010007698, -7.982025621609163],
              [-35.15348596133326, -7.981553267481091],
              [-35.153324363664495, -7.981222724904916],
              [-35.153401461251562, -7.98067263898755],
              [-35.151247859558787, -7.980397925268822],
              [-35.154459390445503, -7.990113184956078],
              [-35.154614838317478, -7.992830512726943],
              [-35.160819463042614, -7.992220677135238],
              [-35.165861911160214, -7.990858719578498],
              [-35.169361821722404, -7.990005855448173],
              [-35.17028048690895, -7.98978092608465],
              [-35.173238165710629, -7.989213086143559],
              [-35.177289572963126, -7.988366482746801],
              [-35.177327117398868, -7.985971700201443],
              [-35.177326932215685, -7.985970978037391],
              [-35.177303390853332, -7.983256166238639],
              [-35.177296818378025, -7.9832308005681],
              [-35.176934052950379, -7.9833231927111],
              [-35.176633343319082, -7.983404591724347],
              [-35.176380031069414, -7.983275397913027],
              [-35.175969239145452, -7.982877301169696],
              [-35.175478170836811, -7.981874267288555],
              [-35.175413607944257, -7.981257225858378],
              [-35.175429329201535, -7.98071524002262],
              [-35.175577613368588, -7.980291875371356],
              [-35.176814442772773, -7.979057389322967],
              [-35.176538404574977, -7.976586335903518],
              [-35.176060469684714, -7.97569857263458],
              [-35.175301360703322, -7.975121337604327],
              [-35.175054212320028, -7.974475967532462],
              [-35.17514063145132, -7.974048047155869],
              [-35.175141933061298, -7.97400266325114],
              [-35.176502113014685, -7.973033740526629],
              [-35.175078823695408, -7.972269897937796],
              [-35.173474553090706, -7.972588366167959],
              [-35.173823233785697, -7.973538913194244],
              [-35.173650209367501, -7.974445917320216],
              [-35.173797353135598, -7.974887797666597],
              [-35.174385615276371, -7.976166385680816],
              [-35.176217668087496, -7.976806410341938],
              [-35.176140441222373, -7.977086219520023],
              [-35.174704356138314, -7.977078128530322],
              [-35.17425577530814, -7.977060146554621],
              [-35.174045906102037, -7.977326370358477],
              [-35.173688467739886, -7.976693058409347],
              [-35.173319897027298, -7.976114943807825],
              [-35.173097370149371, -7.97590757599761],
              [-35.172872669811014, -7.975769369821679],
              [-35.171018961815655, -7.975870210238228],
              [-35.170699979883032, -7.976337586570372],
              [-35.168543305957918, -7.97745150210586],
              [-35.168275045949045, -7.977375531015274],
              [-35.16743603450616, -7.978653110358868],
              [-35.166839260820161, -7.980282948065011],
              [-35.166392356787881, -7.981083368247887],
              [-35.1675588768409, -7.982449876130711],
              [-35.16791692941144, -7.982116528957299],
              [-35.167540724573549, -7.981173983982914],
              [-35.167617770354603, -7.980635019002833],
              [-35.168612587749429, -7.980427416608066],
              [-35.168973200602025, -7.980992470779655],
              [-35.169545656666351, -7.981251789595546],
              [-35.170503576625904, -7.982042131779244],
              [-35.170930173438407, -7.981725055372126],
              [-35.17178181305453, -7.981861417551685],
              [-35.172257676324499, -7.981515698154608],
              [-35.172114450121086, -7.982124793075158],
              [-35.17142402680485, -7.982244391420692],
              [-35.170953013326582, -7.982804767644612],
              [-35.171688605279506, -7.983665332250809],
              [-35.1711331622882, -7.983400324022846],
              [-35.170708543017561, -7.983010338271067],
              [-35.169984308746358, -7.984077338582042],
              [-35.169708705957504, -7.984071372235409],
              [-35.169705483208901, -7.985298743133264],
              [-35.170625819063467, -7.98654214738107],
              [-35.171893086269684, -7.987079927987573],
              [-35.172812016320002, -7.988312665527813],
              [-35.171472480249676, -7.988273694179123],
              [-35.169901667204812, -7.987127626103738],
              [-35.169631369439657, -7.986770094660065],
              [-35.169114849311548, -7.986286671880121],
              [-35.168608342622569, -7.985535451839513],
              [-35.169070385938397, -7.985439110368667],
              [-35.169103840868857, -7.984201094858911],
              [-35.169010457610462, -7.983826002164033],
              [-35.169010112104694, -7.983468409723398],
              [-35.169009983089246, -7.983358040686456],
              [-35.168932729030146, -7.983152530772042],
              [-35.168419009287867, -7.983215968130628],
              [-35.167862710999941, -7.982994799847503],
              [-35.167457752902401, -7.983082795242613],
              [-35.166670874739324, -7.982296611357055],
              [-35.166113677397881, -7.982816577514511],
              [-35.165570406202448, -7.983828660711989],
              [-35.165440264969881, -7.982740918843914],
              [-35.164553215381197, -7.982682647683894],
              [-35.165144931677112, -7.982518199402792],
              [-35.165561805081872, -7.982196025816589],
              [-35.165169923848261, -7.981891734723527],
              [-35.164568130618861, -7.981468228098269],
              [-35.164228074370165, -7.981327818594508],
              [-35.164668576720814, -7.981205110492292],
              [-35.165120989538138, -7.981191805729948],
              [-35.165659935180059, -7.980425977056183],
              [-35.165369207314832, -7.979992617303089],
              [-35.16486514956393, -7.979858489787581],
              [-35.164209143809117, -7.979867435111361],
              [-35.163659882582898, -7.979741768167851],
              [-35.163463852731674, -7.97902073200494],
              [-35.163037718602432, -7.978650991197471],
              [-35.162609270860621, -7.978910308621312],
              [-35.162431475797312, -7.978449147166904],
              [-35.162445555971495, -7.977902013445621],
              [-35.16193182120282, -7.977013057380467],
              [-35.161406051345793, -7.977043101691241],
              [-35.161046362817721, -7.977479952460576],
              [-35.160299037167825, -7.978419334734057],
              [-35.160738671915958, -7.977044054717648],
              [-35.16048207950827, -7.976651549912071],
              [-35.160780370681756, -7.976368327289419],
              [-35.160992597448931, -7.975858485631673],
              [-35.160578061891442, -7.975537944663267],
              [-35.160592734503894, -7.975102985440476],
              [-35.160504129716962, -7.974392322829246],
              [-35.159949957864875, -7.97440072780393],
              [-35.159089887757354, -7.974331361624668],
              [-35.158859525978578, -7.974617031017019],
              [-35.158475291807683, -7.974679236164476],
              [-35.157971441541321, -7.974583333300512],
              [-35.157889811735281, -7.974129802235894],
              [-35.157983507413675, -7.973650680097369],
              [-35.15784708421409, -7.97326152350217],
              [-35.157247764739076, -7.972475780709662],
              [-35.156732659502403, -7.971321515068481],
              [-35.156037325640668, -7.971385336824493],
              [-35.155718911764808, -7.971064198059167],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0212000',
        uf: 'PE',
        nome_proje: 'PA VELHO I',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '399.4958',
        capacidade: 49.0,
        num_famili: 37.0,
        fase: 5.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '18/11/1999',
        area_calc_: 399.2635,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.141182400192172, -7.972641221152788],
              [-35.140506220136622, -7.972205579391277],
              [-35.141051137853218, -7.97303213290057],
              [-35.140741012218257, -7.973414025853911],
              [-35.14008504628206, -7.973395811734681],
              [-35.140326162269886, -7.973815081255734],
              [-35.140425861254066, -7.974311736067923],
              [-35.140399975170816, -7.974948518770252],
              [-35.140879068114842, -7.974712462503162],
              [-35.141039019584277, -7.974711635494285],
              [-35.140932521255294, -7.975104317612224],
              [-35.140933872017087, -7.975363923716937],
              [-35.141077778241936, -7.975573075669059],
              [-35.141443603650764, -7.975648561440744],
              [-35.141928918135321, -7.975632220689578],
              [-35.141617002925216, -7.976053626206495],
              [-35.140943766501231, -7.976167570294843],
              [-35.140379581051114, -7.976396925823817],
              [-35.140133394259536, -7.976293250448289],
              [-35.139977061902037, -7.975874266125353],
              [-35.139674170448266, -7.975834340458786],
              [-35.139610643033677, -7.975702150273944],
              [-35.139513385487703, -7.975674992113802],
              [-35.1394515812636, -7.9758741796284],
              [-35.139321058241435, -7.976024005201952],
              [-35.13909881803302, -7.976113559460536],
              [-35.138898532833196, -7.976048244570282],
              [-35.138715734989475, -7.976032646685172],
              [-35.138636275732196, -7.976132491270137],
              [-35.138609636496462, -7.976502704608737],
              [-35.138535889735309, -7.976602519692545],
              [-35.138073093028652, -7.976555193028599],
              [-35.137959974761486, -7.976773899615504],
              [-35.137760727342709, -7.97689090497174],
              [-35.137680636406657, -7.976869262245876],
              [-35.137387404769207, -7.976489672167479],
              [-35.137051399176201, -7.976673642589059],
              [-35.136786182608581, -7.977288159249791],
              [-35.136618380842947, -7.97796011614161],
              [-35.136961735412839, -7.978090862324947],
              [-35.137212942446801, -7.978078537149732],
              [-35.137360006805764, -7.977796107807811],
              [-35.137553685420144, -7.97770670165093],
              [-35.137697242326411, -7.977866139710548],
              [-35.137979858903094, -7.978383907130836],
              [-35.139023539659355, -7.978116099092889],
              [-35.139222671881015, -7.977976946986787],
              [-35.14000410704908, -7.977796186218412],
              [-35.14029479825269, -7.977686932927928],
              [-35.140495539324967, -7.977857102186711],
              [-35.140718330625219, -7.977855950019832],
              [-35.141432780586207, -7.97797663724764],
              [-35.14170756599183, -7.978539548233694],
              [-35.141557117327395, -7.978554518651695],
              [-35.140620671211742, -7.979372009851233],
              [-35.140541962145932, -7.97911072478176],
              [-35.140157846005479, -7.978814409259161],
              [-35.139686261070331, -7.979291871120436],
              [-35.138747298024825, -7.97882450307063],
              [-35.138682825354699, -7.979609190554007],
              [-35.13847341579217, -7.979969320282537],
              [-35.138954086810458, -7.980157387917564],
              [-35.138703506067316, -7.980307743842246],
              [-35.137876047020413, -7.979328887082471],
              [-35.137876047020413, -7.979328887082471],
              [-35.137635948884906, -7.979280410233111],
              [-35.137499453969475, -7.980479749065913],
              [-35.137699449199069, -7.980506286294754],
              [-35.137337964366026, -7.98128419223575],
              [-35.13776689258323, -7.981408980807787],
              [-35.137419434497254, -7.98157095535595],
              [-35.137016395267246, -7.982048061614578],
              [-35.137013453493076, -7.982581134513168],
              [-35.137294087577978, -7.982734259672883],
              [-35.137085534138848, -7.983102520480091],
              [-35.136925266171637, -7.98320016119536],
              [-35.136638987801426, -7.983426632520655],
              [-35.136321083726543, -7.983657334832687],
              [-35.136615761568315, -7.983005603372077],
              [-35.136046038281144, -7.982171580383095],
              [-35.136250800210192, -7.982015857653389],
              [-35.136751067520869, -7.9793784480693],
              [-35.136504495947463, -7.979183564435557],
              [-35.135771735160866, -7.979955160104534],
              [-35.135511998742189, -7.980525445554902],
              [-35.135197362577287, -7.980424832670304],
              [-35.13483657781709, -7.980674918455974],
              [-35.134421143808325, -7.980511729370945],
              [-35.134830639212915, -7.98019477160943],
              [-35.134177891318771, -7.979855632365032],
              [-35.135290146834791, -7.979590280760951],
              [-35.135602486929173, -7.979248874928425],
              [-35.135036027639359, -7.97904190078658],
              [-35.134886581812843, -7.97886594976608],
              [-35.135251718477278, -7.977690198697184],
              [-35.135140488633056, -7.977155002170404],
              [-35.135806602830137, -7.976751026492193],
              [-35.135187752909538, -7.975254477774926],
              [-35.134929373216018, -7.974985167849899],
              [-35.135037066701862, -7.975940086293374],
              [-35.134912306664248, -7.976117451611712],
              [-35.134301870495932, -7.976242089713678],
              [-35.13366644232255, -7.977038038229372],
              [-35.133167350401152, -7.976933944293375],
              [-35.13322309617574, -7.978765691672605],
              [-35.13322707216409, -7.982119052610561],
              [-35.133528438361715, -7.983785917640951],
              [-35.133561978143788, -7.984237809616142],
              [-35.133928515206698, -7.986439474934056],
              [-35.13437068939939, -7.990456328747269],
              [-35.134370175829218, -7.990514545680662],
              [-35.134253090157713, -7.994645923585396],
              [-35.136389863681423, -7.994457975516169],
              [-35.138207356319199, -7.994308008246464],
              [-35.140019660892506, -7.99422459054083],
              [-35.144600741973207, -7.993929921680524],
              [-35.144937920735643, -7.993899696954265],
              [-35.145016244591645, -7.993842793887876],
              [-35.149713970298052, -7.99347117208267],
              [-35.15302103704154, -7.993057302905418],
              [-35.154614838317478, -7.992830512726943],
              [-35.154459390445503, -7.990113184956078],
              [-35.15357427563719, -7.987435724818686],
              [-35.153284054572353, -7.986579309112154],
              [-35.151247859558787, -7.980397925268822],
              [-35.151204821887703, -7.98024854707085],
              [-35.150685619751172, -7.98033965118264],
              [-35.150529762038012, -7.980031133078713],
              [-35.151603992226626, -7.979053362612622],
              [-35.151046861420873, -7.978445828421503],
              [-35.150167253842177, -7.978422828753039],
              [-35.148984199792061, -7.977177828833812],
              [-35.148375704673605, -7.977672550381905],
              [-35.148457094612034, -7.977942858244336],
              [-35.146792225766958, -7.977731115452219],
              [-35.146946703450169, -7.977428488085426],
              [-35.147482555894221, -7.977226841379817],
              [-35.147974087210571, -7.977307092167036],
              [-35.148070480007, -7.977168560163986],
              [-35.147903536338518, -7.9769070122783],
              [-35.147828494411648, -7.976758251316926],
              [-35.14757691554685, -7.97669881163602],
              [-35.147370283110817, -7.976512044459863],
              [-35.147369793373997, -7.976418217865163],
              [-35.147478072354254, -7.976367939430185],
              [-35.147729333900159, -7.976366636014927],
              [-35.147871974451881, -7.976332721356287],
              [-35.147945800990819, -7.976266079436091],
              [-35.14803073929729, -7.976122002489528],
              [-35.148046276157082, -7.975815305022984],
              [-35.147851044741195, -7.975606422702391],
              [-35.147753816446091, -7.975584870902753],
              [-35.147616052143505, -7.97545849134184],
              [-35.147124782520287, -7.97542795496777],
              [-35.147004991821262, -7.975461660423338],
              [-35.146715789260995, -7.975855380394317],
              [-35.146580709576654, -7.976226154632418],
              [-35.146438500813851, -7.976342867721941],
              [-35.14618073386265, -7.97619225137994],
              [-35.14597514384063, -7.97618780304528],
              [-35.145856101911917, -7.976365140821707],
              [-35.145777250068861, -7.976581049411107],
              [-35.145709044917922, -7.976647661913002],
              [-35.145440414667043, -7.976604851361657],
              [-35.145386874495792, -7.976179823781088],
              [-35.145243513379782, -7.976075618895379],
              [-35.144992309094405, -7.976087948561512],
              [-35.14495165909161, -7.975961155179612],
              [-35.145805677235032, -7.975459561417607],
              [-35.145816238244777, -7.975293814024148],
              [-35.145832064928129, -7.975042345593449],
              [-35.146162552324789, -7.97489699632204],
              [-35.146543617159601, -7.974591296513314],
              [-35.146525125414222, -7.974331780191059],
              [-35.146387160119787, -7.974166712321014],
              [-35.146124114497816, -7.974099014445304],
              [-35.145634775171601, -7.974438449567115],
              [-35.145396547748355, -7.974765555242993],
              [-35.144831274563835, -7.974785116166373],
              [-35.144678038005708, -7.974612804751453],
              [-35.144262778952253, -7.97418630532276],
              [-35.144045949247939, -7.974217348547879],
              [-35.143650868343009, -7.97402613071863],
              [-35.143247646058228, -7.974448009380071],
              [-35.142537502256786, -7.974073293157778],
              [-35.143364056363595, -7.973765200355489],
              [-35.143492158921838, -7.973394824275943],
              [-35.143134700892709, -7.973027684217075],
              [-35.142612132677215, -7.973028760852563],
              [-35.142252492446012, -7.97306099376488],
              [-35.141449246177174, -7.972341539693868],
              [-35.141182400192172, -7.972641221152788],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0270000',
        uf: 'PE',
        nome_proje: 'PA LINDA FLOR',
        municipio: 'BARREIROS',
        area_hecta: '427.7077',
        capacidade: 43.0,
        num_famili: 41.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 435.5653,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.241064673505335, -8.818930501879992],
              [-35.23902973189773, -8.819113225424079],
              [-35.2382155005455, -8.818498311504845],
              [-35.236818941566654, -8.817472036777819],
              [-35.236115218621322, -8.817600968889968],
              [-35.234669984726381, -8.817853938475029],
              [-35.233984236821051, -8.817977409124012],
              [-35.233283476795144, -8.816744586041303],
              [-35.23208086988749, -8.816454137011187],
              [-35.231291021230753, -8.816253444424875],
              [-35.230535930972188, -8.816871969109677],
              [-35.230025873535652, -8.817259799948776],
              [-35.229347007901382, -8.817840206560376],
              [-35.228545967346065, -8.817613290576972],
              [-35.228150519368164, -8.817516003565801],
              [-35.227736910328559, -8.817505730520855],
              [-35.226769291463405, -8.817477962001499],
              [-35.226659813358189, -8.817488851842151],
              [-35.225111710859316, -8.817494763213071],
              [-35.224094203216836, -8.816891555314923],
              [-35.223769901008168, -8.816767448983049],
              [-35.223245477286056, -8.816910779447216],
              [-35.222983490000644, -8.817018507490387],
              [-35.222395905385092, -8.817217147896933],
              [-35.221878396873194, -8.817021374495637],
              [-35.22173026129903, -8.817263558563154],
              [-35.221593727469859, -8.817501903100272],
              [-35.22154656813084, -8.817596509883177],
              [-35.221125489452042, -8.818296471498686],
              [-35.21886178618449, -8.820860577021049],
              [-35.217833450699359, -8.824189646867493],
              [-35.217427746180448, -8.825513784910401],
              [-35.21707264859365, -8.826664776666343],
              [-35.216605831124205, -8.828188661300306],
              [-35.216132594580152, -8.829698682527916],
              [-35.218885365601714, -8.830697844424156],
              [-35.221388703863191, -8.831603607980542],
              [-35.221284296992877, -8.833448716398053],
              [-35.221287158918933, -8.834010083622758],
              [-35.221256840598457, -8.834419327363689],
              [-35.224304859158728, -8.834556296372606],
              [-35.22449113942772, -8.837450892737444],
              [-35.224386688415663, -8.837644831822448],
              [-35.225907228375867, -8.837019249496912],
              [-35.226246570691103, -8.837398945755313],
              [-35.227874388005318, -8.839266573792363],
              [-35.229766329781874, -8.839996199475438],
              [-35.227877048932505, -8.836591089755467],
              [-35.226911818581478, -8.834881071364984],
              [-35.229028685500083, -8.835193583731181],
              [-35.230621297407517, -8.835422224394582],
              [-35.23285501015264, -8.835749682188526],
              [-35.233155791500614, -8.835343457266891],
              [-35.234791888392159, -8.833471743807104],
              [-35.234987084378702, -8.833248628154053],
              [-35.235686001351446, -8.832585790944892],
              [-35.235813346797087, -8.832441932432662],
              [-35.236478395303259, -8.831459962471579],
              [-35.239214731303129, -8.827183467065097],
              [-35.239371192220943, -8.826949780566705],
              [-35.239997735902769, -8.825940073183411],
              [-35.240383678939949, -8.823876194729111],
              [-35.240427173542471, -8.82355002184293],
              [-35.240481054867622, -8.823107912323469],
              [-35.240601461136649, -8.822316172952004],
              [-35.240659512972755, -8.821902618590553],
              [-35.240671851893083, -8.821810532167591],
              [-35.241064673505335, -8.818930501879992],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0129000',
        uf: 'PE',
        nome_proje: 'PA DONA',
        municipio: 'GAMELEIRA',
        area_hecta: '668.5305',
        capacidade: 52.0,
        num_famili: 52.0,
        fase: 5.0,
        data_de_cr: '19/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '08/02/1996',
        area_calc_: 667.0546,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.359334323560461, -8.630881021781979],
              [-35.359732243090555, -8.632935516732275],
              [-35.35989728740099, -8.633787772044775],
              [-35.360067663369691, -8.634898479061665],
              [-35.360287091167258, -8.636609273248016],
              [-35.360584701164747, -8.638930548804739],
              [-35.361112806653793, -8.642329801106435],
              [-35.361945104829751, -8.647933056834797],
              [-35.36229787914678, -8.650312369292553],
              [-35.362518063412033, -8.651526789408123],
              [-35.362684071459768, -8.652442117705125],
              [-35.363147775891086, -8.654999330193906],
              [-35.363319432902749, -8.655945803131653],
              [-35.363503226225205, -8.656959352315265],
              [-35.363673102057042, -8.657896436142533],
              [-35.36389038227346, -8.659094240976895],
              [-35.364062236357391, -8.660042157286123],
              [-35.364289101790057, -8.661290694806363],
              [-35.365066100074216, -8.665577257193705],
              [-35.365439553974305, -8.667636667006125],
              [-35.366588426451806, -8.668352578871971],
              [-35.368198858163922, -8.669312266879247],
              [-35.377537242117072, -8.674836972200104],
              [-35.377927794402041, -8.674216714118556],
              [-35.378283613720399, -8.673648368651536],
              [-35.379768107175458, -8.67428983501031],
              [-35.379707671003082, -8.671904234481298],
              [-35.379506856230591, -8.669927564936662],
              [-35.379339739025873, -8.668282371762752],
              [-35.378475506651007, -8.666600850120568],
              [-35.37835376943503, -8.665801220302308],
              [-35.378187030596827, -8.664705520790777],
              [-35.377995853220661, -8.663449461553668],
              [-35.377833767767001, -8.662385454986405],
              [-35.377653222489563, -8.661201902305821],
              [-35.377459053589035, -8.659931219025619],
              [-35.377244007487313, -8.658517415723592],
              [-35.376983276808573, -8.656813601152779],
              [-35.376738260946013, -8.65521082117505],
              [-35.376486420942697, -8.653561989902038],
              [-35.376341255523648, -8.652615637715092],
              [-35.376275442538798, -8.652186388470135],
              [-35.376034308862351, -8.650838810208523],
              [-35.375680572665715, -8.648855576932268],
              [-35.375296330535491, -8.646713375284881],
              [-35.374890363635231, -8.644450922699326],
              [-35.374663567610085, -8.643185767056529],
              [-35.374277176379501, -8.641030740416021],
              [-35.373746472048062, -8.638075401346947],
              [-35.373445045137309, -8.63636125279897],
              [-35.373443349571772, -8.636090127890581],
              [-35.373190016917, -8.634790246553148],
              [-35.372530643634455, -8.631905469125384],
              [-35.372022899899193, -8.629682044058891],
              [-35.371173783364775, -8.630410324310231],
              [-35.370276743787414, -8.630692519400188],
              [-35.368297847812492, -8.631314984580163],
              [-35.367158525881798, -8.631673322929068],
              [-35.365317595725053, -8.632213130466718],
              [-35.364792546944138, -8.632367121666203],
              [-35.364644049622868, -8.632446181732295],
              [-35.364557420175991, -8.632366042522893],
              [-35.364491526917327, -8.632361406832231],
              [-35.364370485224846, -8.632450390019358],
              [-35.364299808209175, -8.632491162668051],
              [-35.36418318913806, -8.632476755567337],
              [-35.364091713209895, -8.632431940800204],
              [-35.364045535762443, -8.632338946761745],
              [-35.364065314383986, -8.632260672295342],
              [-35.364082387199119, -8.632154682980447],
              [-35.364041307485643, -8.632066699585419],
              [-35.363964653884274, -8.631961288190052],
              [-35.363860418211523, -8.631901425715206],
              [-35.363784361050179, -8.631891810439189],
              [-35.363670667601205, -8.631940411327159],
              [-35.363604994080362, -8.631971068793044],
              [-35.36348085415851, -8.631969313040306],
              [-35.363336119546901, -8.631914742155796],
              [-35.363259654413021, -8.631839581999326],
              [-35.363210959805187, -8.631749124343415],
              [-35.363207799088293, -8.631648301820528],
              [-35.363212457466325, -8.63158272581518],
              [-35.363254956130881, -8.631491706062606],
              [-35.363304928704828, -8.631380471851411],
              [-35.363291713863305, -8.631292316543435],
              [-35.363252351810566, -8.631073227809836],
              [-35.36324340321746, -8.630856472665036],
              [-35.363258162804705, -8.630785792291059],
              [-35.363322879219687, -8.630601356774825],
              [-35.363344657753522, -8.630437354311162],
              [-35.363344092998275, -8.630346599984895],
              [-35.363307938837664, -8.630235896608474],
              [-35.363228650814087, -8.630114114314797],
              [-35.363136979511452, -8.630037787211126],
              [-35.363060789377421, -8.630006743591846],
              [-35.362989940009172, -8.63001978542353],
              [-35.362891398658988, -8.63006072942615],
              [-35.362837304808856, -8.630120307430548],
              [-35.362775635045182, -8.630183713695757],
              [-35.362732548188248, -8.630180197596495],
              [-35.362673964541692, -8.630128877004458],
              [-35.362624199838791, -8.630069938905653],
              [-35.362604950403075, -8.630029720689802],
              [-35.362610883348623, -8.629965397324712],
              [-35.362634203239843, -8.629845503721038],
              [-35.362666600802598, -8.629759588371686],
              [-35.362664981397337, -8.629702874697172],
              [-35.36262255741773, -8.629602294023154],
              [-35.362565926834208, -8.629457682441162],
              [-35.362508974858912, -8.629261391282308],
              [-35.362477605136853, -8.629105279318955],
              [-35.362349087625006, -8.629162788861823],
              [-35.359334323560461, -8.630881021781979],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0290000',
        uf: 'PE',
        nome_proje: 'PA BOM JARDIM',
        municipio: 'BARREIROS',
        area_hecta: '789.0511',
        capacidade: 80.0,
        num_famili: 77.0,
        fase: 3.0,
        data_de_cr: '25/02/2003',
        forma_obte: 'Desapropriação',
        data_obten: '23/05/2002',
        area_calc_: 789.6615,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.287780558470622, -8.847328761430919],
              [-35.288572898243167, -8.847150174885023],
              [-35.289924217369219, -8.848206811341678],
              [-35.290446943463444, -8.848637947536298],
              [-35.290885207994968, -8.848983112421919],
              [-35.292497833722599, -8.850253092896363],
              [-35.292573329289269, -8.850308477837782],
              [-35.292937017108166, -8.850594904612068],
              [-35.293266935925551, -8.850794653078994],
              [-35.293652342865698, -8.852248326669706],
              [-35.294051118318116, -8.853217656608027],
              [-35.294072623734564, -8.854792738139022],
              [-35.294243499647358, -8.855111436260341],
              [-35.294662830726629, -8.855471623080389],
              [-35.295050150019421, -8.855803439718869],
              [-35.296549451557041, -8.856144441033026],
              [-35.297475099277349, -8.856050526432249],
              [-35.29779807591801, -8.855865377150083],
              [-35.299295683205536, -8.854425025807547],
              [-35.299883355665592, -8.854284213026961],
              [-35.299995832590966, -8.854247956107615],
              [-35.300210160985984, -8.854178405611741],
              [-35.301308305904769, -8.854610983907147],
              [-35.300821077780263, -8.852919009507751],
              [-35.300770197965932, -8.851686727412408],
              [-35.300711146300181, -8.849883560595257],
              [-35.300700765081828, -8.849485238108473],
              [-35.300750278863589, -8.848272394335092],
              [-35.301521116129067, -8.8462999022269],
              [-35.302582761008672, -8.84369090560515],
              [-35.302990369336328, -8.842618502315927],
              [-35.305718945298622, -8.841741831641986],
              [-35.307427385083152, -8.841189563635066],
              [-35.30955936535193, -8.840498870838106],
              [-35.310535460394988, -8.838321408071003],
              [-35.310842105055308, -8.837569946880015],
              [-35.311669651666627, -8.835709871272568],
              [-35.31186173773731, -8.835270833440463],
              [-35.312039152015593, -8.834867396995813],
              [-35.312193115862797, -8.834516924121214],
              [-35.312778093946989, -8.834085919911816],
              [-35.317834899592533, -8.830359674267362],
              [-35.317597503941656, -8.828815216578382],
              [-35.317242566897981, -8.826542957400415],
              [-35.315540375514303, -8.826226972498347],
              [-35.311355279857601, -8.824939648900511],
              [-35.309173717668941, -8.82805913622361],
              [-35.30743522538809, -8.830502540403717],
              [-35.306310137848911, -8.832065818022041],
              [-35.304129140187953, -8.833081236375728],
              [-35.303852979715387, -8.832924520815508],
              [-35.303499219286138, -8.832588096134273],
              [-35.301777027310223, -8.831334198898151],
              [-35.300118623649176, -8.83214070481573],
              [-35.300024666583653, -8.832181953124673],
              [-35.298682443415828, -8.83290216107515],
              [-35.297526911606248, -8.83354710793996],
              [-35.297369632000475, -8.833580374372952],
              [-35.297105025503207, -8.833619341662901],
              [-35.289814824918913, -8.831632536878001],
              [-35.289483616787116, -8.83453904867037],
              [-35.289373717584681, -8.835478047616675],
              [-35.289258086324161, -8.836445308208205],
              [-35.289177275408676, -8.837138595504815],
              [-35.289067871042697, -8.838045867224912],
              [-35.288973191189896, -8.838862440232791],
              [-35.288874215162949, -8.839695525361375],
              [-35.288412395515032, -8.842739947747713],
              [-35.288365417662675, -8.84307297036055],
              [-35.288267339629918, -8.843729252318598],
              [-35.288248394337671, -8.843845745092471],
              [-35.288227662782305, -8.843983470689672],
              [-35.28812947234465, -8.844648050540599],
              [-35.287950357468837, -8.84588016016715],
              [-35.287780558470622, -8.847328761430919],
            ],
          ],
          [
            [
              [-35.301318803737907, -8.854836296031326],
              [-35.300207884750897, -8.854464376674727],
              [-35.300007702561771, -8.854479243334373],
              [-35.29943175327746, -8.854671330432049],
              [-35.297971921251722, -8.856052945888862],
              [-35.297560754976473, -8.856314390076898],
              [-35.296532449120832, -8.856418715033044],
              [-35.294923364517956, -8.856052744871707],
              [-35.294441438769233, -8.855720330959404],
              [-35.294026904196578, -8.85528368706227],
              [-35.293800930120447, -8.854862245428617],
              [-35.293788934147564, -8.854633479490191],
              [-35.293779227372063, -8.853272748800586],
              [-35.293392972634727, -8.852333845780779],
              [-35.293039280655016, -8.850966600021724],
              [-35.29233406153017, -8.850470372648441],
              [-35.290714897106703, -8.849163004114811],
              [-35.290222559427491, -8.848808498943594],
              [-35.289579561264581, -8.848283150975924],
              [-35.288506483698335, -8.847443178282031],
              [-35.28774791495448, -8.847599133520225],
              [-35.287675656367256, -8.848314666649937],
              [-35.287373462665009, -8.850683877251333],
              [-35.287362429111496, -8.850773360519609],
              [-35.287176351008938, -8.852143410601908],
              [-35.287151037941975, -8.852455919702493],
              [-35.286807896274347, -8.855254555785379],
              [-35.286795595590547, -8.85534339607934],
              [-35.286605878018804, -8.856881825251625],
              [-35.286400688848815, -8.858526838407899],
              [-35.285841556205213, -8.863744025928497],
              [-35.285560450269173, -8.866683896938596],
              [-35.285512694808588, -8.866761936541321],
              [-35.285277969910943, -8.868390645775536],
              [-35.285170483026484, -8.869814925751884],
              [-35.285127661207234, -8.870358506993195],
              [-35.286094510794214, -8.871542670143683],
              [-35.286917422067276, -8.871896143426834],
              [-35.287549065804733, -8.872178579632251],
              [-35.290614757759265, -8.871958013113039],
              [-35.291788284699898, -8.872059731533795],
              [-35.294256313465638, -8.869469912843696],
              [-35.294431646284856, -8.869276971888821],
              [-35.296060438404453, -8.86790236033948],
              [-35.299422922613871, -8.86496798202368],
              [-35.299451736550957, -8.864738152743067],
              [-35.299691532486477, -8.862757317490438],
              [-35.299619283696899, -8.860102145048778],
              [-35.299610540776044, -8.859708928047974],
              [-35.299845062781628, -8.859521768207848],
              [-35.301267052104258, -8.858282674376035],
              [-35.301339163211743, -8.858132503663677],
              [-35.301318428295581, -8.856236931019073],
              [-35.301323491117145, -8.855394141413708],
              [-35.301318803737907, -8.854836296031326],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0030000',
        uf: 'PE',
        nome_proje: 'PA PRIMOROSO',
        municipio: 'GAMELEIRA',
        area_hecta: '1379.9368',
        capacidade: 96.0,
        num_famili: 86.0,
        fase: 5.0,
        data_de_cr: '27/12/1989',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/1988',
        area_calc_: 1385.0315,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.463127585917292, -8.615079192586803],
              [-35.460175279777275, -8.615675552809817],
              [-35.459996830505041, -8.615712240860946],
              [-35.457397041842029, -8.616034370775417],
              [-35.454820793151043, -8.615936834182154],
              [-35.454040051747917, -8.615909296681611],
              [-35.453431202718704, -8.615885689472741],
              [-35.452069497527425, -8.615875607416797],
              [-35.45146631157381, -8.615937135259758],
              [-35.450721531504712, -8.61609278819946],
              [-35.449244262192899, -8.615877204082295],
              [-35.447151784684785, -8.615736457236622],
              [-35.446341172793616, -8.615677908082313],
              [-35.445649744489749, -8.615646095590765],
              [-35.445560391030718, -8.615644652563509],
              [-35.445088834034472, -8.615706673693564],
              [-35.442431482973063, -8.615673952793433],
              [-35.44109979127682, -8.617037127933157],
              [-35.441141459846293, -8.617153559518343],
              [-35.440204907852319, -8.617663855878922],
              [-35.439706971133546, -8.618398720985878],
              [-35.438936833629732, -8.619122572547859],
              [-35.438413349826241, -8.619596708550478],
              [-35.4375690155995, -8.620077911728963],
              [-35.436567645637915, -8.620799204837061],
              [-35.435953470796008, -8.621596507692356],
              [-35.435472813924612, -8.622156215737757],
              [-35.435310661013574, -8.622289367110987],
              [-35.43488289604813, -8.622588518418693],
              [-35.434733069361016, -8.622623002608192],
              [-35.43289017934071, -8.622831197352969],
              [-35.430715000711494, -8.622592136954269],
              [-35.430176475441613, -8.622529821413325],
              [-35.429311785857223, -8.622657358813099],
              [-35.428759959437087, -8.622695153882852],
              [-35.430020812298224, -8.623384569534535],
              [-35.431192184796544, -8.623860035168267],
              [-35.433074285552934, -8.625833297723851],
              [-35.435708553681998, -8.628581425388797],
              [-35.436309898587091, -8.629451155125112],
              [-35.437405769368297, -8.631017584800547],
              [-35.439034861195481, -8.633409564466756],
              [-35.439537731866828, -8.634395601988318],
              [-35.439898155068022, -8.635078731807516],
              [-35.441224844472117, -8.636895843317086],
              [-35.441630577212834, -8.637517650410507],
              [-35.441627637294133, -8.637696737648938],
              [-35.443065873586811, -8.64009794909035],
              [-35.443463079925579, -8.640771448197116],
              [-35.444265164124339, -8.640556411141196],
              [-35.444685255731621, -8.640758953967017],
              [-35.44510276006686, -8.641194904771341],
              [-35.44626195821597, -8.642627422225102],
              [-35.445785241628244, -8.643374907259345],
              [-35.445393746606868, -8.644328413144407],
              [-35.444538841659558, -8.646718764673489],
              [-35.443628247230819, -8.648724509620328],
              [-35.447868736317083, -8.648411031332779],
              [-35.451388663823636, -8.64847702540383],
              [-35.451510050435196, -8.648473565087402],
              [-35.454277693592161, -8.648441739791686],
              [-35.455128913645602, -8.648440973841936],
              [-35.456957004381273, -8.648462773268351],
              [-35.457851224027387, -8.648466755092713],
              [-35.458672138382205, -8.648420906326542],
              [-35.460282541087899, -8.648321693146888],
              [-35.46088844544817, -8.64825173708121],
              [-35.461105429795403, -8.648265096077855],
              [-35.461207066727916, -8.648266454351704],
              [-35.462726509988499, -8.648235217369956],
              [-35.463140507281352, -8.648232552720783],
              [-35.463476026468037, -8.64822368628051],
              [-35.464640234413615, -8.648211494967494],
              [-35.467274789605149, -8.648171044196475],
              [-35.46757623639143, -8.648167088467899],
              [-35.470511846571142, -8.648168266577221],
              [-35.474660491656593, -8.648012023836108],
              [-35.475863363188694, -8.647968698535168],
              [-35.476606026108733, -8.647939238940555],
              [-35.476555513305676, -8.644685349889686],
              [-35.476546269091315, -8.644209917787798],
              [-35.476360031883637, -8.642481176710758],
              [-35.476587342885978, -8.640838957535465],
              [-35.476969600396387, -8.636917198371782],
              [-35.476988637979645, -8.636699113553165],
              [-35.477040089490664, -8.634341440912799],
              [-35.477099311791591, -8.631710762794839],
              [-35.477213498257157, -8.630709412868317],
              [-35.477215143831828, -8.630125264723207],
              [-35.47721556467728, -8.627889309574119],
              [-35.477209522682919, -8.62779948128367],
              [-35.477157733157469, -8.626969549014449],
              [-35.477394299524462, -8.62408901857432],
              [-35.47404703088494, -8.624309465021629],
              [-35.473434560368396, -8.624306707289307],
              [-35.473294052962345, -8.624304930438154],
              [-35.471671197879338, -8.624149065906117],
              [-35.467832103297319, -8.624253585560057],
              [-35.467118266683435, -8.62376524357008],
              [-35.467131244790757, -8.62366322032266],
              [-35.467221099006004, -8.622480945610167],
              [-35.467231282961102, -8.619531332077079],
              [-35.467223052612674, -8.617632085053289],
              [-35.467521551715173, -8.615915968518237],
              [-35.467271226381179, -8.61538574593586],
              [-35.466510888933563, -8.615716568991079],
              [-35.465701689728114, -8.615874674949442],
              [-35.46507294789577, -8.615674831130168],
              [-35.464266405122856, -8.614853369803857],
              [-35.463880970332042, -8.614937881410073],
              [-35.463127585917292, -8.615079192586803],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0193000',
        uf: 'PE',
        nome_proje: 'PA NOVA VIDA',
        municipio: 'OROBO',
        area_hecta: '200.1000',
        capacidade: 50.0,
        num_famili: 47.0,
        fase: 5.0,
        data_de_cr: '06/10/1999',
        forma_obte: 'Desapropriação',
        data_obten: '31/05/1999',
        area_calc_: 189.7686,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.541439413659056, -7.689386884849473],
              [-35.541453885772796, -7.691026398904919],
              [-35.541451820475451, -7.69160758657845],
              [-35.541439718348244, -7.692009357054613],
              [-35.54145580645924, -7.692383397343079],
              [-35.541456838666036, -7.692465448025839],
              [-35.541480752209353, -7.693636151924214],
              [-35.541470224254518, -7.694514981118321],
              [-35.541490565877893, -7.694736088526064],
              [-35.541456347044701, -7.695470193578778],
              [-35.541552870118942, -7.696222230200929],
              [-35.541505930085229, -7.697542646701992],
              [-35.541488854546841, -7.698371268400237],
              [-35.541520424085242, -7.699594341611455],
              [-35.541534183126871, -7.701051219495341],
              [-35.541511694804086, -7.703690996123985],
              [-35.541565017985896, -7.706236065653701],
              [-35.541594366027759, -7.70754093706088],
              [-35.541598928933475, -7.708517999710587],
              [-35.541604369702192, -7.709383991320433],
              [-35.54160284230209, -7.710387749472169],
              [-35.541616689242716, -7.711326168489694],
              [-35.541632336335773, -7.71379464546709],
              [-35.543308545200262, -7.713626802790936],
              [-35.543957575467594, -7.71364164936104],
              [-35.544896655078965, -7.713558976223545],
              [-35.545008337337293, -7.713555148680672],
              [-35.54589752111238, -7.713537023830092],
              [-35.547044822713794, -7.713460065138491],
              [-35.548197824547145, -7.713472897700637],
              [-35.548037926296459, -7.71126411315665],
              [-35.548000442929499, -7.71035981892203],
              [-35.547986186568778, -7.708825323712921],
              [-35.54795149832227, -7.70811530906556],
              [-35.547925161640698, -7.707574598828502],
              [-35.547897390457528, -7.707006063007199],
              [-35.547909705952534, -7.706005588971327],
              [-35.547601735674093, -7.702044315451136],
              [-35.547658644361782, -7.701592305333302],
              [-35.547739804333226, -7.700984081215724],
              [-35.547674508676472, -7.699941865424246],
              [-35.547641607239306, -7.699317601070619],
              [-35.547571750184737, -7.698011982190301],
              [-35.547577625690849, -7.697099928280133],
              [-35.547481966145213, -7.695629717022719],
              [-35.547421242841814, -7.69391095970375],
              [-35.547386389509079, -7.692382459112348],
              [-35.54740667570514, -7.691577046900376],
              [-35.547314082901877, -7.690953317266057],
              [-35.547403169722678, -7.69050391776315],
              [-35.54726506380068, -7.689995409614308],
              [-35.547240313867796, -7.6892939197239],
              [-35.547296267680203, -7.688544686839577],
              [-35.545301360946588, -7.688391599997813],
              [-35.543357143364474, -7.68824696888025],
              [-35.543266073076751, -7.688242538323966],
              [-35.543215940380222, -7.688236509532074],
              [-35.541427596009015, -7.688116881704627],
              [-35.541439413659056, -7.689386884849473],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0148000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO PEREIRINHA',
        municipio: 'GAMELEIRA',
        area_hecta: '767.5100',
        capacidade: 99.0,
        num_famili: 99.0,
        fase: 4.0,
        data_de_cr: '13/10/1998',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/1997',
        area_calc_: 767.0036,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.348969806461326, -8.610261834043634],
              [-35.348977529543525, -8.610309832184647],
              [-35.34896828614805, -8.61031635632442],
              [-35.348919938894376, -8.610304640473508],
              [-35.348829719904451, -8.610277473376813],
              [-35.348759870011023, -8.610241404264482],
              [-35.348620463424339, -8.610141545717894],
              [-35.348578540397668, -8.610117779209597],
              [-35.348520881377127, -8.610101500461131],
              [-35.348434270316382, -8.610057218138547],
              [-35.348399158747185, -8.610008925338361],
              [-35.348363631467421, -8.60996848863719],
              [-35.348323223182376, -8.60988927587052],
              [-35.34827314598256, -8.609860477430473],
              [-35.348206032902347, -8.609854188828979],
              [-35.34819621420116, -8.609842699445664],
              [-35.34820286040263, -8.60979137963453],
              [-35.348221244617577, -8.609761700935215],
              [-35.348192281211773, -8.6097318495337],
              [-35.348113578149629, -8.609690290646876],
              [-35.348022003866781, -8.609669137257358],
              [-35.347913001945805, -8.609606974540702],
              [-35.347790274539264, -8.609577233876895],
              [-35.347647226464098, -8.609564248371669],
              [-35.347524687067633, -8.609564996807729],
              [-35.347421904616105, -8.609608126247913],
              [-35.34725270957108, -8.609645655566149],
              [-35.347175478880693, -8.609692324713398],
              [-35.347057814640252, -8.60980622714891],
              [-35.346997259698618, -8.609922552678178],
              [-35.346910597617807, -8.610095860590629],
              [-35.346835055459493, -8.610190564808427],
              [-35.346801464711163, -8.610238353369375],
              [-35.346768571633504, -8.610324019632138],
              [-35.346759177281641, -8.610381361918794],
              [-35.346739901605901, -8.610417051713778],
              [-35.34671362641717, -8.610446778564841],
              [-35.346697184133397, -8.610490304640196],
              [-35.346625950355588, -8.610606233397451],
              [-35.346587809019752, -8.610668832955152],
              [-35.346552996574516, -8.610744347483495],
              [-35.346532496376085, -8.610807301289286],
              [-35.346513699062591, -8.610845298027176],
              [-35.346488013301325, -8.610895348198797],
              [-35.346456999573071, -8.610984698793642],
              [-35.34643134796282, -8.611040292448791],
              [-35.346412496534569, -8.611069511998585],
              [-35.346386278237411, -8.611108478002063],
              [-35.346361366991317, -8.611208878757395],
              [-35.346359936038361, -8.611277952809772],
              [-35.346339216542098, -8.611305335851196],
              [-35.346335456213872, -8.61137303819773],
              [-35.346312131101023, -8.611429541612036],
              [-35.34630954115638, -8.611498853726717],
              [-35.346313935082293, -8.611571588008388],
              [-35.346320234102073, -8.611689815238183],
              [-35.346341759508256, -8.611717864299173],
              [-35.346399652311618, -8.611772023895256],
              [-35.346462887312008, -8.611789192821956],
              [-35.346523549471051, -8.611840794613926],
              [-35.346647019536256, -8.611915573574237],
              [-35.346699140164098, -8.61193696810934],
              [-35.346743723032525, -8.611940391644982],
              [-35.346963271577835, -8.61201389072966],
              [-35.347122170711287, -8.612037866832896],
              [-35.347242894150931, -8.612043597060337],
              [-35.347386974110556, -8.612073207261417],
              [-35.347518826167907, -8.612077021514779],
              [-35.347609962557563, -8.612102335320897],
              [-35.347783043193367, -8.612167802225128],
              [-35.347933410645886, -8.612163187648596],
              [-35.347976285039849, -8.612190644153662],
              [-35.34807843607539, -8.612195563642235],
              [-35.348143533518709, -8.612213644832453],
              [-35.348285244666002, -8.612310717446032],
              [-35.348441193524124, -8.61230791643964],
              [-35.348489615177826, -8.612331643178456],
              [-35.348517659059787, -8.612362886044334],
              [-35.348641395572045, -8.612405555349575],
              [-35.348737287759697, -8.61244931876336],
              [-35.348847736807713, -8.612444947802752],
              [-35.348911974390788, -8.612474121489056],
              [-35.349035022125307, -8.612555600708355],
              [-35.349064751659085, -8.612559114751049],
              [-35.349097983532083, -8.612528421266958],
              [-35.349118421151466, -8.612530606198465],
              [-35.34919834127458, -8.612618816656013],
              [-35.349252942273807, -8.612628415267217],
              [-35.349299968629325, -8.612651688435257],
              [-35.349372706724033, -8.612704370731832],
              [-35.349416441141159, -8.612720734395035],
              [-35.349550080041006, -8.612863591218431],
              [-35.349660273914722, -8.612968247476832],
              [-35.349741695144452, -8.613074003613947],
              [-35.349814402769709, -8.613196906063209],
              [-35.349828730014224, -8.613261956790417],
              [-35.349837963620331, -8.613404188370863],
              [-35.349816942765585, -8.613457905934121],
              [-35.34976684760138, -8.61353905781818],
              [-35.349726588390375, -8.613634470735162],
              [-35.349710329006406, -8.613707562101098],
              [-35.349710967964278, -8.613811040419284],
              [-35.349696918184598, -8.613866101282355],
              [-35.349705923609157, -8.613971376328653],
              [-35.349662158438498, -8.614025233012535],
              [-35.349622093143303, -8.614152059003198],
              [-35.349607770300658, -8.614238073807099],
              [-35.349587500889442, -8.614263144389298],
              [-35.349526538590119, -8.614313410460854],
              [-35.349489610402507, -8.614346898463365],
              [-35.349461851496677, -8.614361851415312],
              [-35.349428830619345, -8.61442672976376],
              [-35.349373575225627, -8.614499135682397],
              [-35.349288903867418, -8.614618842888365],
              [-35.349276352060116, -8.614690987648203],
              [-35.34923882634429, -8.614778068312434],
              [-35.34920619902195, -8.614906696724981],
              [-35.34919421766638, -8.615071232859757],
              [-35.349145825641706, -8.615202729529631],
              [-35.349162736981022, -8.615385567948328],
              [-35.3492237695135, -8.615496992475054],
              [-35.349264030531266, -8.6155521831563],
              [-35.349274556070121, -8.615602935954659],
              [-35.34934849418628, -8.615699498313136],
              [-35.34939352819115, -8.615775910594644],
              [-35.349431898351483, -8.615825569112596],
              [-35.349450130273866, -8.615921548239408],
              [-35.349470423442945, -8.615975475099495],
              [-35.349477481904124, -8.616141280665383],
              [-35.349509024516152, -8.616287995529628],
              [-35.349491910778802, -8.616598546896792],
              [-35.349480150452891, -8.616648512061685],
              [-35.349436877744388, -8.616706985535822],
              [-35.34943871821045, -8.616779504231362],
              [-35.349414058040075, -8.616845255429139],
              [-35.349409275458186, -8.616972789584823],
              [-35.349422130545975, -8.61702491403519],
              [-35.34941013429254, -8.617111838578172],
              [-35.349404470971692, -8.617171929879113],
              [-35.349398913210884, -8.617249113582975],
              [-35.349350566845274, -8.617388001622185],
              [-35.349350591506592, -8.617542300877934],
              [-35.349315827917344, -8.617625668913917],
              [-35.349254079463087, -8.617699038608968],
              [-35.349170370210246, -8.617824283771087],
              [-35.349121532981087, -8.617883715248238],
              [-35.3490783285962, -8.617953275699115],
              [-35.349010981438497, -8.61802205987501],
              [-35.348824845550531, -8.618098038330228],
              [-35.348658745297051, -8.618111065635592],
              [-35.348602420433927, -8.618085539565765],
              [-35.348487603125633, -8.617984145428169],
              [-35.348479349900224, -8.617925525135234],
              [-35.348442414866383, -8.617882787390961],
              [-35.348390737497937, -8.61785815680485],
              [-35.348333239789191, -8.617868209893148],
              [-35.348287326470647, -8.617874958306889],
              [-35.348238798508227, -8.617834139327044],
              [-35.348202063150495, -8.617823738549754],
              [-35.348155907408803, -8.617791220587321],
              [-35.348115714635711, -8.617747116792128],
              [-35.348050197638713, -8.61773643002028],
              [-35.348014710786572, -8.617702691860716],
              [-35.348011341130871, -8.617608007660024],
              [-35.347999915782367, -8.61756188004507],
              [-35.347995546495326, -8.617455652583086],
              [-35.347957790938437, -8.61735517294969],
              [-35.347918446754811, -8.617298128626175],
              [-35.347867165236785, -8.617262408122135],
              [-35.34783144321883, -8.617190558459514],
              [-35.347788357161413, -8.617128917247943],
              [-35.347745063569349, -8.617108855031104],
              [-35.347689744242388, -8.617095795940848],
              [-35.347621011789627, -8.617015370513849],
              [-35.347592862023966, -8.616967035236437],
              [-35.347486423245449, -8.616869285216412],
              [-35.347452398912104, -8.616846856421851],
              [-35.347385118930376, -8.616776123520481],
              [-35.347339934503346, -8.616750529103113],
              [-35.347294291590124, -8.61672586141543],
              [-35.347264339355853, -8.616686314634341],
              [-35.347253802589876, -8.616633713893627],
              [-35.347201484546936, -8.616580444543811],
              [-35.347187698585252, -8.616527863656412],
              [-35.347160944292092, -8.616479981760829],
              [-35.34713296850088, -8.616459825837985],
              [-35.347099912458688, -8.616443858719313],
              [-35.34705417840356, -8.616404408359696],
              [-35.347017488782612, -8.616401398746387],
              [-35.346972794236898, -8.61637995903293],
              [-35.346914554787389, -8.616382624799199],
              [-35.346804064709843, -8.616418179074996],
              [-35.346687797913276, -8.616420275420538],
              [-35.346457303664842, -8.616529323903903],
              [-35.346365830181583, -8.61652480103287],
              [-35.346295395515952, -8.61654463429632],
              [-35.346188738221173, -8.6165619169729],
              [-35.346110107943758, -8.616607670890209],
              [-35.346049161847041, -8.616660708373299],
              [-35.345957312465785, -8.61667050893778],
              [-35.345907415317754, -8.616708695704796],
              [-35.345836991969755, -8.616730376727361],
              [-35.34568133155696, -8.616780296878083],
              [-35.345637858735948, -8.616806433022431],
              [-35.345514405572537, -8.616809958859305],
              [-35.345409610510345, -8.616828153936229],
              [-35.345362418567731, -8.616853388815972],
              [-35.345185975475466, -8.616845226780635],
              [-35.345120142496839, -8.616858564108655],
              [-35.345076942976107, -8.61685374616272],
              [-35.345044801044395, -8.616835463395889],
              [-35.344485770195703, -8.616812081879081],
              [-35.344149708000863, -8.616814133263921],
              [-35.343888699662784, -8.616792627508495],
              [-35.343836820288352, -8.616810499216141],
              [-35.343649749180727, -8.616885557028406],
              [-35.343245483381466, -8.617119011945599],
              [-35.343176170149761, -8.617170252241774],
              [-35.343123362325272, -8.617188129539851],
              [-35.343066966304349, -8.617226355681813],
              [-35.342988227236084, -8.617254554653302],
              [-35.342884644640471, -8.617318939326626],
              [-35.342840152089082, -8.617330298193968],
              [-35.342696838118393, -8.61742541566152],
              [-35.342559686809579, -8.61746598228755],
              [-35.342446445004782, -8.617469444914802],
              [-35.342320661440432, -8.617546900242342],
              [-35.342229694350991, -8.617549302985319],
              [-35.342159333044016, -8.617581146532256],
              [-35.342102315688408, -8.617669269830154],
              [-35.342012476024202, -8.617704004011589],
              [-35.341931657629324, -8.617771483512708],
              [-35.341818286575432, -8.617904762196174],
              [-35.341716630798153, -8.6180559865142],
              [-35.341693358823981, -8.618121267146876],
              [-35.341612160856521, -8.618277910424929],
              [-35.341476728054943, -8.61844689582273],
              [-35.341439894749563, -8.618571392168956],
              [-35.34135765390662, -8.618784864945898],
              [-35.341364019259174, -8.618876297534046],
              [-35.341293015032598, -8.61914306012145],
              [-35.341285831603486, -8.619333438356106],
              [-35.34125297390559, -8.619425110122464],
              [-35.341226108435322, -8.619434513488697],
              [-35.34117687137126, -8.619429270006934],
              [-35.341160360035794, -8.619461709069098],
              [-35.341160564808661, -8.619494970151479],
              [-35.341140311588788, -8.619522812260412],
              [-35.341104316100953, -8.619557218031529],
              [-35.341091515342953, -8.61958917248837],
              [-35.341089099984544, -8.619649244206945],
              [-35.341072816150813, -8.61971864003071],
              [-35.341074370204922, -8.619820265455386],
              [-35.341064306153875, -8.619844349615558],
              [-35.341066942147187, -8.619970915205323],
              [-35.341099081438898, -8.619988736313305],
              [-35.341101149759567, -8.620060792091445],
              [-35.341096402148047, -8.620119030119815],
              [-35.341109700654336, -8.620167918561474],
              [-35.341097654169054, -8.620246990060792],
              [-35.341094577846995, -8.620350491622416],
              [-35.341075804742339, -8.620467948217843],
              [-35.341081299834585, -8.620606507759284],
              [-35.341040338853006, -8.620889949345679],
              [-35.341079303867431, -8.620960856158144],
              [-35.341057965251785, -8.621038598390413],
              [-35.34104364622511, -8.621125537364422],
              [-35.341018881980496, -8.621174657930947],
              [-35.341010358377645, -8.621222755500934],
              [-35.34098438389433, -8.621263798863893],
              [-35.340970473577229, -8.621341726788859],
              [-35.340979728219331, -8.621412352804832],
              [-35.340957871351144, -8.621481320645943],
              [-35.340954994711154, -8.621541857158828],
              [-35.340929871509488, -8.621608073071865],
              [-35.340926980124188, -8.621741602008395],
              [-35.340936422529914, -8.621842717351855],
              [-35.34096753122369, -8.621843913558497],
              [-35.341004024691571, -8.621890350644414],
              [-35.341001019563109, -8.622005401198598],
              [-35.340984171983443, -8.622058631283902],
              [-35.340977530713559, -8.622110875179208],
              [-35.340957391174335, -8.622157195691038],
              [-35.340940181818119, -8.622227059150118],
              [-35.340906298866273, -8.622303030014503],
              [-35.340850468490892, -8.622357883785551],
              [-35.340834104983038, -8.622414344758313],
              [-35.340831056246081, -8.622446932724991],
              [-35.340812632607488, -8.622470374922576],
              [-35.340823841253716, -8.622481394006266],
              [-35.340841517169963, -8.62248729190239],
              [-35.340856022033847, -8.62250591349636],
              [-35.340849195839681, -8.622528130017892],
              [-35.340773773309792, -8.622529744961991],
              [-35.340738523630556, -8.622572230823302],
              [-35.340688519387008, -8.622668626987499],
              [-35.340631956844504, -8.622755361626492],
              [-35.340626719460047, -8.622809444871345],
              [-35.340603256626935, -8.622843774246977],
              [-35.340584082379834, -8.622896094579874],
              [-35.340552161833855, -8.622913844376638],
              [-35.340530643908643, -8.62296248318664],
              [-35.340528957838025, -8.622990212080719],
              [-35.34047031662039, -8.623040925192205],
              [-35.34040450444715, -8.623133259955772],
              [-35.340382439821141, -8.623168504766205],
              [-35.340148797347553, -8.62337042755823],
              [-35.339986361492159, -8.62337603779517],
              [-35.339871289410645, -8.623384131051511],
              [-35.339391055426375, -8.62228755277633],
              [-35.339317297441099, -8.624407554306307],
              [-35.339434198182758, -8.624394830198625],
              [-35.339599413971655, -8.624388279182531],
              [-35.339725888454574, -8.62442261830671],
              [-35.339809828572207, -8.624484935363375],
              [-35.339843852866672, -8.624582667039327],
              [-35.339740159765576, -8.62462949704911],
              [-35.339409659741818, -8.624631512173112],
              [-35.339279824282997, -8.624654478635113],
              [-35.339280154128438, -8.624708065993399],
              [-35.339726772230627, -8.628486163764554],
              [-35.34006403766589, -8.631389941276842],
              [-35.340201630635747, -8.632625351618595],
              [-35.340209319704698, -8.632667806548952],
              [-35.340403677904511, -8.634375934143758],
              [-35.340664954597543, -8.636697157305207],
              [-35.340953060204832, -8.639152188459896],
              [-35.341442018026839, -8.64346036300193],
              [-35.343111625880368, -8.655663860564136],
              [-35.347749750742167, -8.652204792239994],
              [-35.347781191481332, -8.65218427241213],
              [-35.349849492009042, -8.650934407760642],
              [-35.35151476110746, -8.649823756434644],
              [-35.352571779009594, -8.648995871625765],
              [-35.356491412100219, -8.645959700737464],
              [-35.356528370528103, -8.645930830983703],
              [-35.359078853841133, -8.643923104181329],
              [-35.359589832639045, -8.643529590456307],
              [-35.360995322915343, -8.642384480314702],
              [-35.360618572412697, -8.639282825162734],
              [-35.360087225974844, -8.634982382428843],
              [-35.36006853083201, -8.634887331628148],
              [-35.359334323560461, -8.630881021781979],
              [-35.359849612987631, -8.63058588537702],
              [-35.360656350485549, -8.630130036609932],
              [-35.362343335156673, -8.629171454693504],
              [-35.360794508093008, -8.628692227257744],
              [-35.359202532219911, -8.628206790311202],
              [-35.358520685719235, -8.627992933282584],
              [-35.358420239314391, -8.62699199741084],
              [-35.358020839338664, -8.625166432189141],
              [-35.357733013180869, -8.623853199241131],
              [-35.357471513505871, -8.622667769903035],
              [-35.357228951422016, -8.621579006656447],
              [-35.356554902888604, -8.618576632290067],
              [-35.356512941323594, -8.618546861644409],
              [-35.356468544893382, -8.618423787596848],
              [-35.356414407603786, -8.618152018326995],
              [-35.356382122669388, -8.618035799501063],
              [-35.356349453738957, -8.617932518238714],
              [-35.356330526809622, -8.617799586441054],
              [-35.356329336266256, -8.617607413373976],
              [-35.3563101970582, -8.61751513641177],
              [-35.356265178752452, -8.617441497111301],
              [-35.356201879777977, -8.617414167166407],
              [-35.356136792819328, -8.61739793550354],
              [-35.355974244872577, -8.617309772144424],
              [-35.355903384726943, -8.61726077583082],
              [-35.355786752599244, -8.617166325040911],
              [-35.355676676146786, -8.617081073437774],
              [-35.355498292990042, -8.616909389949157],
              [-35.355314626791532, -8.616709096451498],
              [-35.355208456387111, -8.616579933303713],
              [-35.355126852755582, -8.616445075796955],
              [-35.355072149598143, -8.61630658851929],
              [-35.355028324027238, -8.616200603575138],
              [-35.354982948692346, -8.616069219637943],
              [-35.354931243739124, -8.615965130876848],
              [-35.354805559511178, -8.615758013815153],
              [-35.354709111417542, -8.615624633054411],
              [-35.354637257650225, -8.615565017106519],
              [-35.35455623487622, -8.615523936249328],
              [-35.354411048110364, -8.615465693710048],
              [-35.354243581263084, -8.61540758765871],
              [-35.353878895097601, -8.615284166062677],
              [-35.353752046616663, -8.615188853014311],
              [-35.352461169447416, -8.613991934459129],
              [-35.351078434116104, -8.612726737371668],
              [-35.350781369117236, -8.612428271962392],
              [-35.350654385314584, -8.612310783486702],
              [-35.350531906327113, -8.612171092659167],
              [-35.35041986731229, -8.612068295829223],
              [-35.350191653973106, -8.611794355133556],
              [-35.350122318454197, -8.611691296982539],
              [-35.350010108557306, -8.611560782547588],
              [-35.349862177245271, -8.61135841852991],
              [-35.349788843412668, -8.611209187298957],
              [-35.34973633213734, -8.611124505108064],
              [-35.349300719064971, -8.610639323401088],
              [-35.348969806461326, -8.610261834043634],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0230000',
        uf: 'PE',
        nome_proje: 'PA BRIOSA',
        municipio: 'GLORIA DO GOITA',
        area_hecta: '410.6721',
        capacidade: 35.0,
        num_famili: 33.0,
        fase: 4.0,
        data_de_cr: '03/07/2001',
        forma_obte: 'Desapropriação',
        data_obten: '20/09/2000',
        area_calc_: 412.6174,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.42811398224022, -8.044149180906503],
              [-35.42747511461036, -8.044353415447794],
              [-35.426307793332207, -8.051415168830152],
              [-35.417245108926068, -8.050950898173998],
              [-35.417034457045965, -8.051225080624409],
              [-35.416643940353666, -8.051766214574998],
              [-35.415592652687621, -8.051344738234295],
              [-35.415137952110221, -8.05106290553495],
              [-35.414734633736046, -8.050607153469468],
              [-35.411660511806019, -8.052012090460611],
              [-35.41171136080051, -8.052176912534311],
              [-35.411717744199457, -8.052259571090705],
              [-35.41176985072358, -8.052713415335964],
              [-35.410259801378643, -8.052596033537048],
              [-35.40953443795356, -8.05440171725018],
              [-35.408066810236377, -8.053960795946537],
              [-35.407980715892386, -8.053938797249971],
              [-35.406800990678647, -8.053696187609862],
              [-35.406500912564994, -8.055135781210033],
              [-35.406330710569925, -8.055151873333907],
              [-35.406364895851141, -8.055427959993336],
              [-35.40637938285051, -8.055516355586885],
              [-35.406632741358784, -8.056818859283533],
              [-35.408104344953031, -8.057367445728438],
              [-35.409101341959165, -8.057749509634272],
              [-35.409593237157189, -8.058414387808654],
              [-35.410627141289282, -8.058359458702366],
              [-35.413820689821996, -8.058349393931849],
              [-35.414189533176973, -8.058367990425168],
              [-35.414283877940207, -8.05852873240819],
              [-35.414471885716765, -8.058732159435261],
              [-35.415983935645599, -8.058909761880788],
              [-35.416198379735874, -8.058895067519609],
              [-35.419859322908664, -8.05683474618411],
              [-35.419957975914421, -8.056804691660917],
              [-35.421527160523077, -8.057215500506119],
              [-35.423156820452597, -8.057676529183572],
              [-35.42329790582631, -8.057136665789947],
              [-35.424217077290244, -8.05729816712007],
              [-35.424758023911814, -8.058989642865464],
              [-35.424780499649707, -8.058997081246236],
              [-35.425306747627936, -8.059055364411256],
              [-35.425598720022812, -8.059064780896819],
              [-35.425829729896584, -8.059122785629041],
              [-35.426161119232695, -8.059175584292381],
              [-35.42645141526387, -8.059133636828479],
              [-35.427212388428295, -8.058945991822879],
              [-35.427406429814489, -8.05886193137645],
              [-35.427793143031501, -8.058601002372432],
              [-35.427951462594628, -8.058550799617946],
              [-35.428115807054851, -8.058529069800153],
              [-35.428388981865751, -8.058571998960188],
              [-35.428687514435012, -8.058620264083828],
              [-35.428910419780635, -8.058626218800864],
              [-35.42910563892859, -8.058598081374312],
              [-35.429264465543852, -8.058510154907635],
              [-35.42976106807015, -8.058145393469212],
              [-35.430257329259618, -8.057785123596837],
              [-35.430485523643767, -8.057676155599843],
              [-35.430740177891344, -8.057599922469715],
              [-35.431176224414401, -8.057456604995483],
              [-35.431535602432376, -8.057329207695696],
              [-35.431932795284027, -8.057137229194584],
              [-35.432016172733725, -8.057121741231006],
              [-35.432299376456839, -8.057132967538349],
              [-35.432509410829823, -8.057145690359443],
              [-35.432775805168198, -8.05721440823848],
              [-35.432919697496537, -8.057234000836681],
              [-35.433300778511445, -8.057182538659678],
              [-35.433477046214215, -8.057170473021131],
              [-35.433708562369063, -8.057182525002403],
              [-35.43411701113417, -8.057284145655341],
              [-35.434306732984346, -8.057361927487181],
              [-35.434687519756018, -8.057448494807383],
              [-35.434814774238959, -8.057459895431512],
              [-35.435259552636374, -8.05738999934613],
              [-35.435516958829204, -8.057386894847156],
              [-35.435825348053569, -8.057424779117206],
              [-35.436175656703767, -8.057514417079478],
              [-35.436433399384576, -8.057549604620354],
              [-35.436580738559499, -8.057588603036114],
              [-35.436683306379649, -8.057648150104281],
              [-35.436641286647927, -8.057741592695683],
              [-35.437057379558162, -8.059847984123364],
              [-35.4380831277198, -8.059723136882404],
              [-35.439599328388645, -8.060003616122801],
              [-35.438168214005096, -8.065705852621182],
              [-35.438925652834861, -8.065854801270946],
              [-35.439019655700776, -8.065894730015545],
              [-35.439530998365555, -8.065984862065406],
              [-35.439859326724751, -8.065723706454451],
              [-35.440394368516863, -8.065471531412927],
              [-35.44052324218309, -8.065459285594223],
              [-35.441237825971413, -8.065493616983307],
              [-35.441307873112812, -8.065561071987736],
              [-35.442140521777496, -8.065338571630051],
              [-35.443368697985669, -8.064403715170297],
              [-35.443905433902337, -8.063966436276008],
              [-35.443958868354528, -8.063925177062831],
              [-35.444044429918627, -8.063843056883506],
              [-35.447231983663968, -8.059035583231422],
              [-35.44709500239825, -8.058751527233003],
              [-35.447033762919183, -8.058578795857997],
              [-35.44612645669195, -8.052829972164366],
              [-35.44447768194015, -8.053314367504882],
              [-35.444381938852182, -8.053331477012433],
              [-35.443044961971317, -8.053658742802593],
              [-35.439448197367241, -8.053923899327318],
              [-35.438906908846718, -8.053937946040964],
              [-35.437402506135641, -8.053714270310195],
              [-35.436860192261179, -8.052520066398106],
              [-35.436335315425644, -8.051272073486748],
              [-35.434882763890499, -8.048280162671356],
              [-35.435337237317391, -8.048234988727204],
              [-35.434819245156646, -8.044459617677019],
              [-35.433034369168226, -8.044550246036309],
              [-35.432216876601522, -8.044595280582596],
              [-35.431781451290817, -8.044916303233784],
              [-35.430250494953782, -8.045835592663117],
              [-35.42952291053529, -8.046280524223526],
              [-35.428655594726351, -8.044865515303082],
              [-35.428193230445771, -8.044118255103401],
              [-35.42811398224022, -8.044149180906503],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0361000',
        uf: 'PE',
        nome_proje: 'PA CANAVIEIRAS',
        municipio: 'GLORIA DO GOITA',
        area_hecta: '492.9557',
        capacidade: 86.0,
        num_famili: 86.0,
        fase: 3.0,
        data_de_cr: '11/09/2006',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/2005',
        area_calc_: 492.5491,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.324069858871667, -8.010157540696872],
              [-35.321379435793283, -8.01020397152263],
              [-35.321205378355593, -8.012338638962584],
              [-35.320150883350578, -8.012301372983243],
              [-35.320082942753785, -8.013097222713208],
              [-35.321657774298167, -8.013397014037983],
              [-35.321882209051971, -8.015749429420191],
              [-35.320914482441445, -8.016559658491417],
              [-35.320573425624055, -8.016891480366066],
              [-35.320336909337207, -8.01685385732655],
              [-35.319560046352926, -8.028470463530693],
              [-35.317314010430444, -8.028198142330332],
              [-35.316797681591311, -8.029621433324179],
              [-35.31466114966333, -8.037248495328011],
              [-35.319896984673711, -8.037917091986742],
              [-35.322518190327877, -8.042788324660858],
              [-35.323276397422887, -8.045863218113407],
              [-35.322167774287109, -8.053641837302793],
              [-35.321703320692343, -8.064079148071793],
              [-35.323633481424629, -8.064091702078796],
              [-35.323887278541505, -8.062450076093658],
              [-35.325724205293135, -8.061003100877331],
              [-35.327960417174218, -8.059716542483283],
              [-35.337053746137229, -8.055173496011834],
              [-35.333882428406895, -8.053590068316293],
              [-35.334907925171521, -8.049783820124038],
              [-35.334097843692597, -8.050066989030578],
              [-35.332663004905747, -8.04988380911184],
              [-35.332014657402773, -8.048162537811558],
              [-35.332497783026419, -8.046714570406726],
              [-35.332137487859491, -8.045142419355301],
              [-35.333435080406417, -8.043672471323395],
              [-35.332951310135897, -8.043057001830517],
              [-35.331468430401046, -8.041762283201436],
              [-35.327667059315715, -8.037453136550337],
              [-35.328655438096071, -8.034504842702903],
              [-35.329136714400754, -8.033175924673884],
              [-35.328958409007392, -8.032852730587281],
              [-35.327769047660823, -8.030543848971762],
              [-35.323439363609303, -8.029104573329034],
              [-35.322033844722633, -8.028690871117307],
              [-35.322799576759046, -8.01843298819929],
              [-35.325095052716797, -8.01862394044611],
              [-35.325339610137476, -8.01714036241871],
              [-35.325542197611199, -8.014350258489063],
              [-35.323960239028239, -8.01375379507216],
              [-35.323952526337671, -8.013577138835565],
              [-35.324069858871667, -8.010157540696872],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0238000',
        uf: 'PE',
        nome_proje: 'PA SANTA HELENA',
        municipio: 'GRAVATA',
        area_hecta: '303.7885',
        capacidade: 23.0,
        num_famili: 23.0,
        fase: 4.0,
        data_de_cr: '07/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/2000',
        area_calc_: 301.8618,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.612347153469948, -8.162182762679882],
              [-35.610577325672956, -8.161972472250568],
              [-35.609905739891047, -8.161858534015675],
              [-35.609564339585674, -8.161637224448345],
              [-35.608967858287762, -8.161650750425476],
              [-35.607519418416437, -8.161738095582573],
              [-35.606136518087403, -8.161533108153801],
              [-35.6047158122282, -8.161408404096871],
              [-35.604233590770825, -8.167675805966038],
              [-35.599327601259446, -8.168751687464191],
              [-35.595729872279271, -8.170468642207716],
              [-35.596111661434513, -8.171696671316614],
              [-35.596395340092499, -8.172843374081951],
              [-35.59657260926987, -8.17406757747068],
              [-35.596795204849201, -8.174877438157083],
              [-35.59731226508562, -8.176060315237899],
              [-35.597573420839353, -8.17698924362119],
              [-35.597934316704226, -8.178036980799403],
              [-35.597960739438783, -8.178138300223479],
              [-35.598267645813685, -8.177869184890783],
              [-35.598441393101687, -8.177712228413958],
              [-35.598593963520251, -8.177597439790768],
              [-35.598778842085707, -8.177464927448701],
              [-35.598971300181169, -8.177335595569453],
              [-35.599156528964649, -8.177256524129913],
              [-35.599348346435889, -8.177195010644352],
              [-35.599552174388222, -8.177153871763936],
              [-35.599771252300449, -8.177133086625521],
              [-35.59993908343359, -8.177115626370037],
              [-35.600120936288768, -8.177090923029006],
              [-35.600386725084363, -8.177038865122659],
              [-35.600547366911485, -8.176999080158573],
              [-35.600739323427362, -8.176959093564367],
              [-35.600896472846841, -8.176923663897838],
              [-35.601091320293115, -8.176879068922375],
              [-35.601287032554445, -8.176813721380336],
              [-35.601481043734637, -8.176757575005082],
              [-35.60190817662432, -8.176681628344506],
              [-35.601980844055412, -8.177012792864378],
              [-35.602263323381557, -8.177290879498937],
              [-35.602833403018728, -8.178319745238625],
              [-35.60311131337226, -8.179830673793081],
              [-35.603133070342466, -8.180063856007093],
              [-35.603015752055647, -8.180383103588893],
              [-35.603020142898558, -8.180433767156101],
              [-35.603021222318404, -8.180461541309205],
              [-35.605516343602268, -8.179653811027087],
              [-35.605632061550857, -8.179634359030914],
              [-35.60655828592558, -8.179372831347308],
              [-35.60710740062887, -8.179206124936901],
              [-35.607868065179012, -8.180125229592019],
              [-35.608397313276583, -8.180982498927527],
              [-35.608628522057678, -8.181824423647615],
              [-35.609027617514286, -8.182744277888583],
              [-35.60993816120105, -8.183099562026648],
              [-35.61045689971106, -8.183735672652693],
              [-35.610964187023832, -8.184500137509088],
              [-35.611116343489599, -8.18492416436721],
              [-35.612026663578064, -8.183955767761132],
              [-35.612647374583062, -8.183289660783231],
              [-35.613247393175961, -8.182695445682176],
              [-35.613746657138904, -8.182193014761538],
              [-35.614367674046136, -8.181633064711729],
              [-35.614272946708738, -8.180730912282998],
              [-35.614170426071418, -8.179146523966391],
              [-35.61420546639242, -8.17823326597545],
              [-35.614330174033398, -8.177261612957567],
              [-35.614129053030361, -8.176273380395596],
              [-35.613462674061985, -8.175229212075198],
              [-35.613477491694923, -8.174433955580069],
              [-35.613602187044762, -8.173622445940309],
              [-35.613827110597811, -8.171519217624397],
              [-35.612313177693579, -8.170756088683333],
              [-35.612275571908448, -8.170578545819353],
              [-35.612316222748831, -8.166070662675677],
              [-35.612347153469948, -8.162182762679882],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0198000',
        uf: 'PE',
        nome_proje: 'PA POÇO GRANDE',
        municipio: 'PASSIRA',
        area_hecta: '1120.8600',
        capacidade: 100.0,
        num_famili: 95.0,
        fase: 3.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '24/06/1999',
        area_calc_: 1083.3834,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.467388656292698, -8.014726197310511],
              [-35.466219889508167, -8.014668848510773],
              [-35.465578549702371, -8.016676740363875],
              [-35.46405636247875, -8.021227350193342],
              [-35.464090294221435, -8.021336319525396],
              [-35.464139006026038, -8.021851795351134],
              [-35.462988565135156, -8.022402283491346],
              [-35.462256573439646, -8.024145018981656],
              [-35.461556248472959, -8.024973787085854],
              [-35.459256619274939, -8.025157622472314],
              [-35.458824121018495, -8.025126678014809],
              [-35.457534556590879, -8.025825207785767],
              [-35.457224513804086, -8.026330899898019],
              [-35.457079732014456, -8.026710977934259],
              [-35.456559008740399, -8.027177711301343],
              [-35.45619394272093, -8.027685991228505],
              [-35.455828348811607, -8.02851393076527],
              [-35.455402097776236, -8.029281952356717],
              [-35.455288146364232, -8.030024883120973],
              [-35.454932297133318, -8.029879605750375],
              [-35.454792284739547, -8.029952289728985],
              [-35.454817442416449, -8.030411968076477],
              [-35.454510293079828, -8.03091728129578],
              [-35.454251638405744, -8.031043000601166],
              [-35.453046717849851, -8.031044406531411],
              [-35.452458312591816, -8.031309643081311],
              [-35.452326875286069, -8.0338828682979],
              [-35.452179725807909, -8.036239919483151],
              [-35.454749655095782, -8.036376225233214],
              [-35.456929385551469, -8.036491892342283],
              [-35.45890810402549, -8.036624294858166],
              [-35.461089413081183, -8.036746798119973],
              [-35.460925712418295, -8.037432366735462],
              [-35.46106141870677, -8.037758258318805],
              [-35.461297038573235, -8.038007726899966],
              [-35.462757638357516, -8.038211806180872],
              [-35.464405810715824, -8.037915706986107],
              [-35.46367325503153, -8.038784170967716],
              [-35.464026078361698, -8.038877219185647],
              [-35.463942240047274, -8.039423403108788],
              [-35.463716125742835, -8.040087927314689],
              [-35.464521066199936, -8.040334881469795],
              [-35.464594478625202, -8.040374567205895],
              [-35.463516806180088, -8.04208160927336],
              [-35.463806638714814, -8.044070006539908],
              [-35.464034117844491, -8.044937049354072],
              [-35.464760325465122, -8.046962588888999],
              [-35.465702919313486, -8.046144280832662],
              [-35.466174916509473, -8.045836160662303],
              [-35.466241539856462, -8.045907517548619],
              [-35.467524459770473, -8.0465483323095],
              [-35.466823999125765, -8.046981911117021],
              [-35.465469762366091, -8.048417511669946],
              [-35.467425178932459, -8.049485548815586],
              [-35.466928389086675, -8.050641440398104],
              [-35.46728154849329, -8.050743246782162],
              [-35.467359984619328, -8.050788594729536],
              [-35.467119477441194, -8.051591027996331],
              [-35.469505927886189, -8.050735103541687],
              [-35.469446881662513, -8.050133748445472],
              [-35.471120318229524, -8.049497651329782],
              [-35.470871362077759, -8.048456415756213],
              [-35.471281456037694, -8.04837767044207],
              [-35.471377871683231, -8.04835259840678],
              [-35.471635753384227, -8.048293657675448],
              [-35.472665642635775, -8.048012354783642],
              [-35.473156704378496, -8.048799985727282],
              [-35.472452181421502, -8.049310325410977],
              [-35.47234783767211, -8.049343850292178],
              [-35.472070856864185, -8.050185635057749],
              [-35.47301675798294, -8.051035322349952],
              [-35.473334176375481, -8.051642437657941],
              [-35.472569703159188, -8.052001583444559],
              [-35.473050976212569, -8.053266716941899],
              [-35.47442421708741, -8.056008234575769],
              [-35.4767968662104, -8.054643092816823],
              [-35.476858712439331, -8.054599339903193],
              [-35.479315874778038, -8.053148726853147],
              [-35.480111947706703, -8.052679123003285],
              [-35.478818623701549, -8.051033650380122],
              [-35.478532116861587, -8.050873432396873],
              [-35.480207910383186, -8.049779094245483],
              [-35.483168149384575, -8.048562296936854],
              [-35.485564297620279, -8.047644058669881],
              [-35.485078072781036, -8.046700797629187],
              [-35.484936585983732, -8.044849729502022],
              [-35.48492939721541, -8.044756236893534],
              [-35.485900681704393, -8.044087650963377],
              [-35.487719126957771, -8.043793768679963],
              [-35.487788712787626, -8.044573355354542],
              [-35.489144360846893, -8.045243927628432],
              [-35.489934039800211, -8.045023960773461],
              [-35.489749991211625, -8.044298751344785],
              [-35.489708675468208, -8.043769056084981],
              [-35.490889145404154, -8.040935670800694],
              [-35.490834949558504, -8.039499073685596],
              [-35.49089406704919, -8.03943970183084],
              [-35.493722033221708, -8.036775894008505],
              [-35.491837515382592, -8.036413545485647],
              [-35.492642751166059, -8.034779515014867],
              [-35.493968430223333, -8.035922818513098],
              [-35.494562418362712, -8.035449909028433],
              [-35.49526685204129, -8.034993770421025],
              [-35.495889788565883, -8.036038307388258],
              [-35.496133813480931, -8.035910303887867],
              [-35.499461345566964, -8.036586305298732],
              [-35.499592169683879, -8.036539961952187],
              [-35.501183681725593, -8.035843450244085],
              [-35.501669969186111, -8.036780358851363],
              [-35.501262747929154, -8.03690935875624],
              [-35.501557198707843, -8.037612467728646],
              [-35.504846930677154, -8.037357457927678],
              [-35.505136536519267, -8.038747417175761],
              [-35.505155806979758, -8.03882917646053],
              [-35.505918834247908, -8.042069408388143],
              [-35.506309572865092, -8.04372877764613],
              [-35.507860312573406, -8.043773530763671],
              [-35.508381227356026, -8.04443909756616],
              [-35.510212621765298, -8.042411355854119],
              [-35.509725947074656, -8.040472610211323],
              [-35.509531913150326, -8.040133366603774],
              [-35.510457198022209, -8.038822207045266],
              [-35.508896430694342, -8.037511414733302],
              [-35.508700818750853, -8.036575637327271],
              [-35.509975779470082, -8.035392209372423],
              [-35.511639757456329, -8.034292074661144],
              [-35.512271081382849, -8.034439859318677],
              [-35.512700648456509, -8.034982081696581],
              [-35.513220348593791, -8.035378254406085],
              [-35.513426225348795, -8.034489550095476],
              [-35.513879494612077, -8.033520800460019],
              [-35.51388227813321, -8.032411657924184],
              [-35.51231201867607, -8.032560880448148],
              [-35.51237501500114, -8.030287838312557],
              [-35.511107668220625, -8.030656341867578],
              [-35.511369315803243, -8.031980672492059],
              [-35.509230905174498, -8.0323471721155],
              [-35.509687807214519, -8.032897546907291],
              [-35.51021354803445, -8.032890722076878],
              [-35.508609400723287, -8.03545034700846],
              [-35.507004802995709, -8.036182659924142],
              [-35.506879749842476, -8.036223547846054],
              [-35.505738075683048, -8.033864218066338],
              [-35.500953024027879, -8.035266924890639],
              [-35.500831943073933, -8.034846256853564],
              [-35.500960694146158, -8.032640576315046],
              [-35.500135508218875, -8.031601616999835],
              [-35.4999095436307, -8.031746592272002],
              [-35.499173439341959, -8.031203412012797],
              [-35.499175184081366, -8.031192195258942],
              [-35.497851730820621, -8.0317013514032],
              [-35.497696927080646, -8.031102852595213],
              [-35.495675777069714, -8.031359763105145],
              [-35.495019207230172, -8.031561121197873],
              [-35.494546865237254, -8.031419391261425],
              [-35.494780938938938, -8.031146853679251],
              [-35.494678779117351, -8.030749834639959],
              [-35.49439826932268, -8.030841908842799],
              [-35.493941854555729, -8.030338591190345],
              [-35.493537612912824, -8.030955575936098],
              [-35.492590835397493, -8.030328708038629],
              [-35.492165812045329, -8.030512029355069],
              [-35.491824099890053, -8.030708130280138],
              [-35.490916488202465, -8.030719864997739],
              [-35.490307466880815, -8.030615739086336],
              [-35.489869639117813, -8.031182858827986],
              [-35.48646819175174, -8.031286585235986],
              [-35.486120708063119, -8.031592882048702],
              [-35.484419897022626, -8.031735649729468],
              [-35.48498882612266, -8.030197043460067],
              [-35.485526354480676, -8.028743487674896],
              [-35.483910352475831, -8.028054755728231],
              [-35.482615580039969, -8.027498281596809],
              [-35.481666575601231, -8.027113775721176],
              [-35.479785654160267, -8.026365980910716],
              [-35.478653486500598, -8.025903945377186],
              [-35.477420538438814, -8.025388830812776],
              [-35.477265898477128, -8.022881441252631],
              [-35.475302203543293, -8.023433871413857],
              [-35.473400832246881, -8.023978327774163],
              [-35.471955885312255, -8.024388902675474],
              [-35.470574767945763, -8.02476998967388],
              [-35.470504552877387, -8.022873007100207],
              [-35.469751133163982, -8.020566300053636],
              [-35.46876763911979, -8.018895214496791],
              [-35.468977297619396, -8.016346489069109],
              [-35.467388656292698, -8.014726197310511],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0214000',
        uf: 'PE',
        nome_proje: 'PA SITIO I',
        municipio: 'PAUDALHO',
        area_hecta: '667.5170',
        capacidade: 71.0,
        num_famili: 68.0,
        fase: 5.0,
        data_de_cr: '23/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '13/05/1999',
        area_calc_: 667.1098,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.183578083541327, -7.949848698925917],
              [-35.180641369891283, -7.950380268219336],
              [-35.177113688360059, -7.95119359744912],
              [-35.17415520134886, -7.951367013347252],
              [-35.171215392625307, -7.951145565416383],
              [-35.167725372708738, -7.951041767396335],
              [-35.165532188865861, -7.951001595381675],
              [-35.162886063463901, -7.950989261842389],
              [-35.160515128439556, -7.950978191573017],
              [-35.159512004409834, -7.950973465847488],
              [-35.158143915596114, -7.951192098899051],
              [-35.153948363831788, -7.952063584163414],
              [-35.147309143869251, -7.953333479896393],
              [-35.147459817692692, -7.954443736113119],
              [-35.147644062375193, -7.955801679252876],
              [-35.147786160114336, -7.956848883993842],
              [-35.148229589109953, -7.959066217776762],
              [-35.148638356127265, -7.960894672814303],
              [-35.148853373260806, -7.961856708368995],
              [-35.149192239995784, -7.963372488680983],
              [-35.149288143581472, -7.963801273151362],
              [-35.149295484748038, -7.965747419863422],
              [-35.149037755367772, -7.967184304753168],
              [-35.148491811497543, -7.970228716592605],
              [-35.148596175110121, -7.970505775687139],
              [-35.149725677848188, -7.970773267943644],
              [-35.150337425943945, -7.970382483579088],
              [-35.150934624437063, -7.969810443822219],
              [-35.151520428476204, -7.968359472039741],
              [-35.151207301239054, -7.96781683555482],
              [-35.151842171992982, -7.967270995207337],
              [-35.152211834784289, -7.967544506022043],
              [-35.152722952161128, -7.968772112183488],
              [-35.153751673173872, -7.969223526627682],
              [-35.154561345576241, -7.968864792937793],
              [-35.154781090294492, -7.967849432296589],
              [-35.156015198392751, -7.965842601295146],
              [-35.155686653800593, -7.965611817320321],
              [-35.153675111313326, -7.96567994411129],
              [-35.153488927649796, -7.965310386984353],
              [-35.156745593224933, -7.964700835978411],
              [-35.157330465880435, -7.964081308401407],
              [-35.157248417164823, -7.962004941425626],
              [-35.15577729609047, -7.961445732019111],
              [-35.153708010330966, -7.959708084607557],
              [-35.152756934685122, -7.960489956830929],
              [-35.152112554084177, -7.960271835412249],
              [-35.150804479100834, -7.960704285305521],
              [-35.151900615056782, -7.959744220669122],
              [-35.15283092606419, -7.959535193966672],
              [-35.153232910006317, -7.95867219633525],
              [-35.152124363652121, -7.958000354987487],
              [-35.152423048603993, -7.957758357802191],
              [-35.152159975031395, -7.956518974946206],
              [-35.152415094987887, -7.955469805239543],
              [-35.153273904665774, -7.955461282966496],
              [-35.153116024490664, -7.956934166719195],
              [-35.154084269600745, -7.958624930347572],
              [-35.154422559840341, -7.958882784893903],
              [-35.154884437925105, -7.958758806517941],
              [-35.155010465780293, -7.957733538350606],
              [-35.155499724339052, -7.957366439034806],
              [-35.156209365283452, -7.955482110760794],
              [-35.157589813680353, -7.955387074396361],
              [-35.155947036218187, -7.957925460328978],
              [-35.155882148752845, -7.959718305229079],
              [-35.156943288209881, -7.960599370321889],
              [-35.157813684424958, -7.961053229344257],
              [-35.158336287650862, -7.962707695748002],
              [-35.158753713129798, -7.962821497877883],
              [-35.159619977128351, -7.962485604770282],
              [-35.159757926690602, -7.962647865823893],
              [-35.158620759197056, -7.963595597294726],
              [-35.158628670546321, -7.964015343791006],
              [-35.159506478572425, -7.96506846714755],
              [-35.158407154588971, -7.965331181049333],
              [-35.157408836489104, -7.966597368669892],
              [-35.1571453472855, -7.967569386850585],
              [-35.158431346776304, -7.971037148116666],
              [-35.159049832021246, -7.971362595967743],
              [-35.160119906457446, -7.970534256248421],
              [-35.161339629617487, -7.970248760031709],
              [-35.162492916731367, -7.970176483667424],
              [-35.163056983705779, -7.968842407653767],
              [-35.163650140943517, -7.969783829968412],
              [-35.164131691808038, -7.970123814893339],
              [-35.164653436451978, -7.969433468268702],
              [-35.163820720107218, -7.967457663431983],
              [-35.162720958812628, -7.966825498250369],
              [-35.16213937787213, -7.966999737495772],
              [-35.16215261584383, -7.966256815933995],
              [-35.162424601517763, -7.965852152460745],
              [-35.163047330854724, -7.963705859108096],
              [-35.162921476645408, -7.962565932815209],
              [-35.163588692466192, -7.962383021615564],
              [-35.163019122163618, -7.964844148344324],
              [-35.163246235407826, -7.965685338375669],
              [-35.164240104239411, -7.966804280840312],
              [-35.16467237835824, -7.966481669953722],
              [-35.164647036667063, -7.96599024406667],
              [-35.165371811441752, -7.965906460663069],
              [-35.164494144471391, -7.967333381607121],
              [-35.165083985008494, -7.968730581308493],
              [-35.166446126795059, -7.970388864263064],
              [-35.166817861506097, -7.97050289451004],
              [-35.167257901747256, -7.970553111134415],
              [-35.166814315108596, -7.970914472660708],
              [-35.166816582561772, -7.971345274116941],
              [-35.167722288346397, -7.971989557395123],
              [-35.169454460393858, -7.97124866779829],
              [-35.169595174793692, -7.97085029248915],
              [-35.172526631054382, -7.97119677180872],
              [-35.172684271783595, -7.970776161254076],
              [-35.171288495063969, -7.968159918316387],
              [-35.17157150285454, -7.967680709479192],
              [-35.17124695662126, -7.96678209747926],
              [-35.170839812759006, -7.966469391837118],
              [-35.169948947475412, -7.966471344847291],
              [-35.169035099316069, -7.965363210004103],
              [-35.169730974314071, -7.964114134855602],
              [-35.16960764119704, -7.962388727329423],
              [-35.168325656944248, -7.960760584440151],
              [-35.168109089461936, -7.959770285824883],
              [-35.16767925151111, -7.959485263011819],
              [-35.167752537795593, -7.959299845498442],
              [-35.168598911909697, -7.959530263097306],
              [-35.168777644931865, -7.960929516082164],
              [-35.169970752168283, -7.961934049384805],
              [-35.170844031603878, -7.960764134407395],
              [-35.170935715269955, -7.959733537514264],
              [-35.171370100496915, -7.959814244666252],
              [-35.170515931932748, -7.962425916687604],
              [-35.170435935785441, -7.963506349499776],
              [-35.170255386830746, -7.965017940431121],
              [-35.17049803554648, -7.965552428620965],
              [-35.17185700568028, -7.966608601277629],
              [-35.172359045934087, -7.966501024357853],
              [-35.172630961697962, -7.966085329226314],
              [-35.172255825249515, -7.964259101610714],
              [-35.173029340973777, -7.962587031182609],
              [-35.174440253898098, -7.961579892702295],
              [-35.173911241777255, -7.959804099415271],
              [-35.173827820175873, -7.959150093456986],
              [-35.174667039116081, -7.959107007172247],
              [-35.176324155414925, -7.958228379569936],
              [-35.17646468641307, -7.957796831535586],
              [-35.176160996429473, -7.956519731099359],
              [-35.175946327392644, -7.955874549739404],
              [-35.176223481042477, -7.955370513872507],
              [-35.176464139125756, -7.955528162099796],
              [-35.176415614714735, -7.956075201145712],
              [-35.176891136659236, -7.957439803776748],
              [-35.177122937031378, -7.9583238015108],
              [-35.175992181606468, -7.959151853803778],
              [-35.175483416231124, -7.959220147032742],
              [-35.175095256297894, -7.960098358002205],
              [-35.175994608588098, -7.960642414531612],
              [-35.175510847279078, -7.96099901986752],
              [-35.175445186180525, -7.961546149071131],
              [-35.173198732848903, -7.964377817566405],
              [-35.173225718989876, -7.965162013667229],
              [-35.173553353257049, -7.965580079648319],
              [-35.173982603440741, -7.965751834611916],
              [-35.175883161455531, -7.965534773519577],
              [-35.176452874300445, -7.966360320592565],
              [-35.174089593835014, -7.966541214911764],
              [-35.172212285682583, -7.967901525406006],
              [-35.17368979028717, -7.970848985809916],
              [-35.174267871520051, -7.971094531929945],
              [-35.175571159291785, -7.970256439561247],
              [-35.176889834215274, -7.970426139760615],
              [-35.178536489841072, -7.969980798570944],
              [-35.17947360221892, -7.969190903014969],
              [-35.180516237954158, -7.968312047372266],
              [-35.180534879269914, -7.966934822955339],
              [-35.179671816910762, -7.96471878460938],
              [-35.179390706078877, -7.964045845104267],
              [-35.179725548978972, -7.963203345757019],
              [-35.18056924284317, -7.962222944282367],
              [-35.18142329676828, -7.961230555846296],
              [-35.181899466762097, -7.960143344477731],
              [-35.181755631559284, -7.958989882570569],
              [-35.181330511548744, -7.957763596742588],
              [-35.181031768014684, -7.95625859890527],
              [-35.181107395269891, -7.955436897132305],
              [-35.181555076694295, -7.95486100540287],
              [-35.182222340911927, -7.953475400735538],
              [-35.182212618849555, -7.952802933629895],
              [-35.182251050569981, -7.951703200503309],
              [-35.182645409258186, -7.950663605972864],
              [-35.183578083541327, -7.949848698925917],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0146000',
        uf: 'PE',
        nome_proje: 'PA MINGUITO',
        municipio: 'RIO FORMOSO',
        area_hecta: '864.9000',
        capacidade: 68.0,
        num_famili: 66.0,
        fase: 6.0,
        data_de_cr: '14/10/1998',
        forma_obte: 'Desapropriação',
        data_obten: '18/12/1997',
        area_calc_: 843.7276,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.181093455504524, -8.669942173954901],
              [-35.178259889145828, -8.670838840615735],
              [-35.177061174345376, -8.671180393154106],
              [-35.171919397614523, -8.672645172354343],
              [-35.170525851041219, -8.673071246159779],
              [-35.170476866118506, -8.673051367639971],
              [-35.167764552026426, -8.673180674707297],
              [-35.164708563190139, -8.673305223389647],
              [-35.159299401065581, -8.673605682664313],
              [-35.159299491900846, -8.673605682150356],
              [-35.159592330935546, -8.674284851372482],
              [-35.159850789057835, -8.675845980208264],
              [-35.160112159259064, -8.677709178837786],
              [-35.159975764360439, -8.678289087035456],
              [-35.159766697186647, -8.678932499782514],
              [-35.158947680557617, -8.680012249097203],
              [-35.158793777318493, -8.680135871528361],
              [-35.158492871382911, -8.680430261408357],
              [-35.157987856326038, -8.680630985861578],
              [-35.15720205613421, -8.680991302172696],
              [-35.156235748375039, -8.68225727424263],
              [-35.15564529535726, -8.683882052863787],
              [-35.15925922127132, -8.685071304387632],
              [-35.161462030534658, -8.685832294915237],
              [-35.161720209446557, -8.685912905638473],
              [-35.161955185553474, -8.68600268688914],
              [-35.163225747670317, -8.686360746912182],
              [-35.165000511022434, -8.687167259241349],
              [-35.181882739215887, -8.694838618623315],
              [-35.182208509502978, -8.694903007307737],
              [-35.182259571196241, -8.694904432052926],
              [-35.183355886582234, -8.695348649022643],
              [-35.184851204601763, -8.696031460832462],
              [-35.189973297748608, -8.698351157427009],
              [-35.19312223825235, -8.699719142060335],
              [-35.194090279947616, -8.697429249200665],
              [-35.194724681740972, -8.695425463087229],
              [-35.194872857407901, -8.694709993147809],
              [-35.194896581861443, -8.6946189250065],
              [-35.195753503780544, -8.691813552399978],
              [-35.195580362038832, -8.682791285294588],
              [-35.195563298752042, -8.6819034919484],
              [-35.195481467582326, -8.681140537193542],
              [-35.194648338676835, -8.680538008699727],
              [-35.194144507652361, -8.680171216350447],
              [-35.194095714354688, -8.68009077957832],
              [-35.192509581850075, -8.678837977626712],
              [-35.192382551974248, -8.678737471859442],
              [-35.190225341046258, -8.676922011283185],
              [-35.188062368922921, -8.67526899630327],
              [-35.187972885947374, -8.67518915314454],
              [-35.183018882703166, -8.671462373332334],
              [-35.182778588990111, -8.671315508093445],
              [-35.182536115453978, -8.671152927295449],
              [-35.18183054059255, -8.670483917460166],
              [-35.181093455504524, -8.669942173954901],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0127000',
        uf: 'PE',
        nome_proje: 'PA AMARAJI',
        municipio: 'RIO FORMOSO',
        area_hecta: '1082.9700',
        capacidade: 96.0,
        num_famili: 88.0,
        fase: 6.0,
        data_de_cr: '19/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '09/07/1997',
        area_calc_: 1022.4505,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.143218554733991, -8.677534374737631],
              [-35.139850984882585, -8.67846099844118],
              [-35.136916295606902, -8.678946044744068],
              [-35.135400209836057, -8.679196606725675],
              [-35.132564630645525, -8.680103652934033],
              [-35.131555574523297, -8.680299209134388],
              [-35.132174056119901, -8.683618134690327],
              [-35.131375579400313, -8.686776039798364],
              [-35.131508359060454, -8.687075672089108],
              [-35.131291918251321, -8.687307745630303],
              [-35.131421486449781, -8.687569521339853],
              [-35.132362382001503, -8.689246102824386],
              [-35.132855674186516, -8.690067179803894],
              [-35.133475705324358, -8.692190464217013],
              [-35.134581555230916, -8.692784112572792],
              [-35.134669884143555, -8.694457686393701],
              [-35.135277916587057, -8.694956045602193],
              [-35.134586810554843, -8.69571777144823],
              [-35.133525189831559, -8.696391542444928],
              [-35.133593118823121, -8.697959924199537],
              [-35.134309528428382, -8.699242735083063],
              [-35.13411338164866, -8.699625924170336],
              [-35.132532697818796, -8.697386269746616],
              [-35.131876459916505, -8.696749694721541],
              [-35.131655042658984, -8.69623108655162],
              [-35.130784766668654, -8.696717661323026],
              [-35.130202792616686, -8.69706847851174],
              [-35.12975662413065, -8.696356872319893],
              [-35.129398165608457, -8.696215514624097],
              [-35.129453810624845, -8.69469624313902],
              [-35.129540878468063, -8.694477819207846],
              [-35.129930267129424, -8.69366228720755],
              [-35.129997949882913, -8.693566363492192],
              [-35.127253657961496, -8.692351456132453],
              [-35.126899678607039, -8.692697378223926],
              [-35.125974916503367, -8.692060118727623],
              [-35.126038540759261, -8.691985370148627],
              [-35.126038540759261, -8.691985370148627],
              [-35.128846208135982, -8.686644193299763],
              [-35.128421043356084, -8.686544514529325],
              [-35.128157606116837, -8.686529986174596],
              [-35.127779859982596, -8.686768292070512],
              [-35.127489344500681, -8.686865368948142],
              [-35.127399446525892, -8.686710846939043],
              [-35.127389681864038, -8.686364606131919],
              [-35.126926484205761, -8.686109119581008],
              [-35.126452859157681, -8.68567968399107],
              [-35.126191301011829, -8.685563270713653],
              [-35.125977029455925, -8.685745613562148],
              [-35.125376952944222, -8.685300249237972],
              [-35.125195496128882, -8.685324944002417],
              [-35.124619240602954, -8.685415385924655],
              [-35.124639529392176, -8.685243345177756],
              [-35.124348182897798, -8.685241262964359],
              [-35.124323916995309, -8.685045697127519],
              [-35.12386310634588, -8.685084693489921],
              [-35.123606927753684, -8.685197665989298],
              [-35.123326428126816, -8.684976047955407],
              [-35.123117162607258, -8.685305702425815],
              [-35.122870575598618, -8.685557374626335],
              [-35.123023596345568, -8.686242696923541],
              [-35.122781932376384, -8.686885111550737],
              [-35.122705726590546, -8.688647208878812],
              [-35.122855251310639, -8.689469586450832],
              [-35.120032349485527, -8.69019742421415],
              [-35.118462849240359, -8.6900566447859],
              [-35.117607665589333, -8.691065218863823],
              [-35.117135805590088, -8.690901608174133],
              [-35.116286730019297, -8.69746555150925],
              [-35.115670781670566, -8.696493358044677],
              [-35.115318067590408, -8.696096411306122],
              [-35.114983992524628, -8.695977320804401],
              [-35.114422803310887, -8.695858587467164],
              [-35.113931021786293, -8.695837183361622],
              [-35.113598019760033, -8.695893178058975],
              [-35.113237691440638, -8.696325181108833],
              [-35.113180724113739, -8.696664292864275],
              [-35.113245179429953, -8.697255561702823],
              [-35.112206443716481, -8.696511420562357],
              [-35.11176871103256, -8.697154108107469],
              [-35.11133814322654, -8.697065198820569],
              [-35.110675168697405, -8.697086231084336],
              [-35.109934970327338, -8.697252682017709],
              [-35.109435489864374, -8.698225919115131],
              [-35.108482604167733, -8.697318943399114],
              [-35.108916817391403, -8.696565635632568],
              [-35.108843608670178, -8.696275876682424],
              [-35.108550471667648, -8.696264302893164],
              [-35.107829384874975, -8.69716780665615],
              [-35.107292698308768, -8.696801066611629],
              [-35.106976994494516, -8.695921743503026],
              [-35.106182794200009, -8.69611397697749],
              [-35.105811902786556, -8.696234444992909],
              [-35.105607466251406, -8.696228977015647],
              [-35.10542895109424, -8.697074516581653],
              [-35.105448535974112, -8.697880723754048],
              [-35.105282883361774, -8.698231736464384],
              [-35.105165824667694, -8.698646117852897],
              [-35.105115507639944, -8.699331312469312],
              [-35.105137123264676, -8.700305731924752],
              [-35.103537343895674, -8.70204038510421],
              [-35.103338625410878, -8.703091954909436],
              [-35.102990149192514, -8.703567366512779],
              [-35.102881596855241, -8.706660172303845],
              [-35.103275383896474, -8.706733111948683],
              [-35.104084155301592, -8.70696547019347],
              [-35.104999248667717, -8.707088945757727],
              [-35.105711034074915, -8.707149907536195],
              [-35.106640017464009, -8.707271584507986],
              [-35.11026525161752, -8.707687722126646],
              [-35.11202519635254, -8.70788323393886],
              [-35.112522576421178, -8.70794419818297],
              [-35.114415886294694, -8.70807468744867],
              [-35.116621225514372, -8.708408532644661],
              [-35.118451858812314, -8.708137370645424],
              [-35.121328288940518, -8.7074860486349],
              [-35.12271439524978, -8.707152083882818],
              [-35.123548804034897, -8.706968356023534],
              [-35.124665355069908, -8.70673062217274],
              [-35.126106474009759, -8.7066642635905],
              [-35.127393871554169, -8.706436414163361],
              [-35.128436694673447, -8.706303486282406],
              [-35.129802663381938, -8.706042105777922],
              [-35.130745458463636, -8.705876919670615],
              [-35.132309195599056, -8.705695324108907],
              [-35.132454474392638, -8.705681583345946],
              [-35.133972554438316, -8.705587646853743],
              [-35.140609921482771, -8.704844110501904],
              [-35.141338536100264, -8.704703878414099],
              [-35.14334410918574, -8.704486216444954],
              [-35.144291229714568, -8.704397717343243],
              [-35.14524887423326, -8.703541458644692],
              [-35.147358136786856, -8.702742516176185],
              [-35.14927791449049, -8.702219696323327],
              [-35.149419656586275, -8.702239504798365],
              [-35.150476808699835, -8.702474062411945],
              [-35.150965375505386, -8.701280391850748],
              [-35.151254157719464, -8.700482138665972],
              [-35.151055553528053, -8.699588385263217],
              [-35.151250862589741, -8.698823201633189],
              [-35.150871965793762, -8.698094346730338],
              [-35.150688084099336, -8.696898511101258],
              [-35.150682364938447, -8.696548999216084],
              [-35.151270598203794, -8.695628382088239],
              [-35.151521767710733, -8.695132340303038],
              [-35.151222271736032, -8.694371308760207],
              [-35.151225715023052, -8.693828488059767],
              [-35.151563406448254, -8.693434823246459],
              [-35.152346948942935, -8.692721274155932],
              [-35.152635233986445, -8.6922506033898],
              [-35.152825834392004, -8.691854696121871],
              [-35.152712843710155, -8.691316872755452],
              [-35.153125562559659, -8.690476701564402],
              [-35.152968168895661, -8.689369210997789],
              [-35.152583561414197, -8.688752021785193],
              [-35.152188747795982, -8.688527098308628],
              [-35.151828670523997, -8.686524247677369],
              [-35.152488853297662, -8.6855426640997],
              [-35.152988481168592, -8.684491300883119],
              [-35.153289710451048, -8.684061143796661],
              [-35.147680342597404, -8.68661724986832],
              [-35.146444414083149, -8.683835172257306],
              [-35.146554982279106, -8.683757716598109],
              [-35.146211256639525, -8.683569467382592],
              [-35.146083303294454, -8.683385245918645],
              [-35.146004719730513, -8.683208520179535],
              [-35.144738568440687, -8.680666139808668],
              [-35.144004483540684, -8.679162619210585],
              [-35.143218554733991, -8.677534374737631],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0132000',
        uf: 'PE',
        nome_proje: 'PA SOUTO MAIOR',
        municipio: 'PAUDALHO',
        area_hecta: '841.0589',
        capacidade: 110.0,
        num_famili: 107.0,
        fase: 3.0,
        data_de_cr: '30/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '07/11/1997',
        area_calc_: 840.531,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.18381340294539, -7.949941864536195],
              [-35.183660531069307, -7.94984976431491],
              [-35.183302898229329, -7.950107526188413],
              [-35.182682658197649, -7.950697434302779],
              [-35.18263801280564, -7.950759316718421],
              [-35.182512221207141, -7.951147762231861],
              [-35.182395621562854, -7.951336934061816],
              [-35.182342105432092, -7.951521706705702],
              [-35.18234755140687, -7.951711412261469],
              [-35.182464309972922, -7.952220974730535],
              [-35.182293170612667, -7.95267573570122],
              [-35.182278713303113, -7.952839512777945],
              [-35.182356618634238, -7.953254547910913],
              [-35.182284422828928, -7.953541924052927],
              [-35.181971204748365, -7.95391164926614],
              [-35.18179634913762, -7.954263924571664],
              [-35.181734945026726, -7.954671737479036],
              [-35.181276696329007, -7.955290081556454],
              [-35.181209679536941, -7.955425299638305],
              [-35.181068564226145, -7.955831181308485],
              [-35.181019971709155, -7.956072694814907],
              [-35.180996913014489, -7.956478950447813],
              [-35.181029226529589, -7.956691203506384],
              [-35.181184433578544, -7.957269714621655],
              [-35.181362773176097, -7.957575931562694],
              [-35.181717303519484, -7.958193347891552],
              [-35.181787997582632, -7.958633188423764],
              [-35.181764235961289, -7.959009166055685],
              [-35.181792493835722, -7.959277302857759],
              [-35.181877288553643, -7.959862872481358],
              [-35.181983015260478, -7.960308765663441],
              [-35.181945588055818, -7.960672431339662],
              [-35.181624249084358, -7.961084684972051],
              [-35.181347578665836, -7.961387961201117],
              [-35.181193105194531, -7.961582756274859],
              [-35.181112220159697, -7.961684692621974],
              [-35.181080424855509, -7.961724813396681],
              [-35.180849901573666, -7.962061472904753],
              [-35.180741356819368, -7.962059783838953],
              [-35.180558331515492, -7.962119501437582],
              [-35.180334991088365, -7.962408576485347],
              [-35.1798274979922, -7.962958210840556],
              [-35.179634496384303, -7.963223352970228],
              [-35.17970985261131, -7.963276017429874],
              [-35.179575432145668, -7.963559472562588],
              [-35.179494197747402, -7.963938374086312],
              [-35.179598168430211, -7.964223739889345],
              [-35.179720590940732, -7.964466162448637],
              [-35.179825835460406, -7.965077929636263],
              [-35.179916029483927, -7.965398530417152],
              [-35.180010650560682, -7.965733209128381],
              [-35.180152826243159, -7.965989990326094],
              [-35.180537569202016, -7.966763264903654],
              [-35.180623785863766, -7.966948929901107],
              [-35.180700587182933, -7.967240132663276],
              [-35.180715987434262, -7.96768270463043],
              [-35.18075213211894, -7.968121461324858],
              [-35.180645069906447, -7.968245953058023],
              [-35.180583878310593, -7.968317052508319],
              [-35.180219614528966, -7.968740741423519],
              [-35.179997394226184, -7.968882200013782],
              [-35.179714378134669, -7.968964047933281],
              [-35.179422865580356, -7.969170953555837],
              [-35.179025855886501, -7.969421350409439],
              [-35.178781620342669, -7.969600798902571],
              [-35.178599820054579, -7.969892729247484],
              [-35.178074827655173, -7.970752865326063],
              [-35.17778844204102, -7.970900897639107],
              [-35.177596841829484, -7.971054578310194],
              [-35.177313304390701, -7.971261170008947],
              [-35.177173380277409, -7.971310355970053],
              [-35.177062773634958, -7.971536557894288],
              [-35.17695276723137, -7.97192772367894],
              [-35.176918061801082, -7.972086455199804],
              [-35.176901451884412, -7.97235772116183],
              [-35.176830479549714, -7.972517095252464],
              [-35.176621483431042, -7.97274218718802],
              [-35.176471139501984, -7.972981614522442],
              [-35.176000310226641, -7.973677221444806],
              [-35.1751024792732, -7.974114377877027],
              [-35.17510458072627, -7.974151699141145],
              [-35.175071161498934, -7.974365202123836],
              [-35.175088643913135, -7.974619114601417],
              [-35.175197035633197, -7.974968546879134],
              [-35.175517113527, -7.975446942398388],
              [-35.175612581422826, -7.975529963878603],
              [-35.175925722763111, -7.975673851013839],
              [-35.176146628104306, -7.975848594675652],
              [-35.176510279840159, -7.97643875670155],
              [-35.176595236448911, -7.976591616404851],
              [-35.17664413665176, -7.976819239795827],
              [-35.176616630725491, -7.977190447223179],
              [-35.176664221045733, -7.977496086530427],
              [-35.176662676119356, -7.977649672165056],
              [-35.176627478019519, -7.977835253060682],
              [-35.176620755446002, -7.978055666263514],
              [-35.176637593833838, -7.978187551335214],
              [-35.176753915260349, -7.978526997638978],
              [-35.17686037634725, -7.9789050934743],
              [-35.176838418740516, -7.979159484109243],
              [-35.17677519686643, -7.979258525648975],
              [-35.17661397892946, -7.979431029635749],
              [-35.176304402605943, -7.979687294486324],
              [-35.176109464719978, -7.979862145134148],
              [-35.17584305072797, -7.980101369731186],
              [-35.175699117805834, -7.980250370905022],
              [-35.175606788241858, -7.980383733939698],
              [-35.175485647837668, -7.980607460605621],
              [-35.175432442159142, -7.980748753316485],
              [-35.175452083951875, -7.981067737150742],
              [-35.175399024948739, -7.981356731172764],
              [-35.175406840722239, -7.98153141964694],
              [-35.175506532745764, -7.98196686061629],
              [-35.17572427074635, -7.982364800385268],
              [-35.175880347252509, -7.982745437636987],
              [-35.1759789049989, -7.982932122955667],
              [-35.176144948958949, -7.983225659186766],
              [-35.176264458021066, -7.983293277109526],
              [-35.176493547274383, -7.983334375676794],
              [-35.176731303677926, -7.983282685436798],
              [-35.176890076508158, -7.983213332146377],
              [-35.177271716051713, -7.983123100223011],
              [-35.177274499520387, -7.983220619432005],
              [-35.177292904420412, -7.983870627780308],
              [-35.17728721432416, -7.984713661214497],
              [-35.177274946102841, -7.985256353915825],
              [-35.17727537159665, -7.985969912596747],
              [-35.177274264438701, -7.987198447198505],
              [-35.177269338310971, -7.987466668625496],
              [-35.177270570512583, -7.987682068208808],
              [-35.177245468917903, -7.988284035749913],
              [-35.177532527986429, -7.98831235346683],
              [-35.177824071520057, -7.988331336899373],
              [-35.178166828820792, -7.988397868044602],
              [-35.17854318580649, -7.988490616495801],
              [-35.178837039036516, -7.988483463493628],
              [-35.179102190276211, -7.988518674971503],
              [-35.179671472132057, -7.988620890866759],
              [-35.180353333598454, -7.988680660338413],
              [-35.180655497161418, -7.988684580473085],
              [-35.180662534093777, -7.988797188733447],
              [-35.18082806108206, -7.988855687276581],
              [-35.181013837243128, -7.989124167474399],
              [-35.181157488855447, -7.989400372381587],
              [-35.181292873182265, -7.989639469469767],
              [-35.181762823327155, -7.990434161021297],
              [-35.182461305863598, -7.991607564367895],
              [-35.182550079857485, -7.99176085314343],
              [-35.182765199050557, -7.992055933066523],
              [-35.182931396633229, -7.992308334809779],
              [-35.183253138435404, -7.992890027811785],
              [-35.183375180360855, -7.993109217327447],
              [-35.183923367469689, -7.994017746701388],
              [-35.184382777420318, -7.994772173170558],
              [-35.184551091573368, -7.994978823964568],
              [-35.184785847461093, -7.995418877818348],
              [-35.184858758100034, -7.995538804373796],
              [-35.18506893931324, -7.995961632560791],
              [-35.185335138045694, -7.996499233526282],
              [-35.185616041472151, -7.996937234393845],
              [-35.1857027147446, -7.997087188441736],
              [-35.185890417915246, -7.997460599753016],
              [-35.186008186110477, -7.997694001922904],
              [-35.186197296973887, -7.998059179792275],
              [-35.186475309532277, -7.998515815622395],
              [-35.186542799391596, -7.99872435467716],
              [-35.186476066955173, -7.99915135942124],
              [-35.186432774586649, -7.999399534598816],
              [-35.186412464239716, -7.999519050305938],
              [-35.186408032292753, -7.999640289763342],
              [-35.186424374615378, -8.000137360504688],
              [-35.186515220721951, -8.000134257968472],
              [-35.186572724370578, -8.000714813818057],
              [-35.186582417627598, -8.000951138138907],
              [-35.18657613810592, -8.001151028606003],
              [-35.186547676786311, -8.00139153229464],
              [-35.186523786384932, -8.0016729594472],
              [-35.186514845188945, -8.001917879344033],
              [-35.186499563761231, -8.002266241540099],
              [-35.18646262801839, -8.002465028933702],
              [-35.186354645396399, -8.002808820304043],
              [-35.186213231949289, -8.003243452214743],
              [-35.18612613059549, -8.00343879932732],
              [-35.18601749490778, -8.003592137732513],
              [-35.185805296742622, -8.003761843389551],
              [-35.185621641450204, -8.003893433040391],
              [-35.185375413112311, -8.004041624659672],
              [-35.185180695084384, -8.004173363160808],
              [-35.185132083324334, -8.004206252228787],
              [-35.184967271022792, -8.004351391134801],
              [-35.184750055812117, -8.004634836557257],
              [-35.184684966117771, -8.004757753207308],
              [-35.184590259716011, -8.005023646541167],
              [-35.184530841599788, -8.005377033580697],
              [-35.184482783869292, -8.005684079611003],
              [-35.184422978031307, -8.005879643726306],
              [-35.184351397462088, -8.006043813660604],
              [-35.184261851333829, -8.0062400776223],
              [-35.184184701241584, -8.006380142301014],
              [-35.184131617031554, -8.006458703140545],
              [-35.184263544186777, -8.006540713567812],
              [-35.184251558389647, -8.006622853277376],
              [-35.184173452090931, -8.006855755969655],
              [-35.184122205536461, -8.006938826714959],
              [-35.184033141871637, -8.007089530397868],
              [-35.183844291698364, -8.007284693110858],
              [-35.184023251593622, -8.007411741093582],
              [-35.183979253806577, -8.008038212250545],
              [-35.18395303387021, -8.008291088106944],
              [-35.183926205464751, -8.008582926291098],
              [-35.183886587378758, -8.008772056106341],
              [-35.184131473690996, -8.008694739208448],
              [-35.184697452640386, -8.0084952283352],
              [-35.185084701528936, -8.008365090807901],
              [-35.185387874621945, -8.008266674232983],
              [-35.185948586132973, -8.00811699597956],
              [-35.187043912033332, -8.007685712940944],
              [-35.187823237478753, -8.0074132957969],
              [-35.188170390129883, -8.007331909138252],
              [-35.189067498624652, -8.007193097343679],
              [-35.189445244633795, -8.007135049272607],
              [-35.19027645904476, -8.007002550709531],
              [-35.19067983076576, -8.006954579819773],
              [-35.190834448817128, -8.006955385646364],
              [-35.191090032310278, -8.006962886452857],
              [-35.191857119873376, -8.006997588998106],
              [-35.192542232084385, -8.007032635455358],
              [-35.192895127653713, -8.006991167797182],
              [-35.193020301616016, -8.006963294351877],
              [-35.193048017244834, -8.00695709074288],
              [-35.194481035360234, -8.007085960612123],
              [-35.194880041194764, -8.007154343069704],
              [-35.194947513730739, -8.006105440154561],
              [-35.19495116903321, -8.005805862568129],
              [-35.195030348735493, -8.004589220767521],
              [-35.195037489094091, -8.004313849615144],
              [-35.195041331615577, -8.003812064764539],
              [-35.195059377348301, -8.003490264642855],
              [-35.194997089979331, -8.003034931307738],
              [-35.194980490344498, -8.002915431353399],
              [-35.194956715099927, -8.002827606663999],
              [-35.194916372144014, -8.002592350784381],
              [-35.194381079126458, -8.000304218160954],
              [-35.194152264232677, -7.999265024996179],
              [-35.194164194195928, -7.999071071210492],
              [-35.194298917062802, -7.997641532331198],
              [-35.194339643989942, -7.997236722292017],
              [-35.194380353827626, -7.996811754990553],
              [-35.194405413233568, -7.996393740630893],
              [-35.194450084899344, -7.996048297064077],
              [-35.194434556897917, -7.995331934299105],
              [-35.194401581559539, -7.994506832464968],
              [-35.194397911609869, -7.994414019592464],
              [-35.194375302542156, -7.993848648636601],
              [-35.194366836685234, -7.993418428665366],
              [-35.194361358365349, -7.993139960637873],
              [-35.194381017505187, -7.992491837434141],
              [-35.194321953785973, -7.991841149338691],
              [-35.19432354656697, -7.991171970352268],
              [-35.194273095398927, -7.989706083038344],
              [-35.194215705524655, -7.988519633486304],
              [-35.194203324647674, -7.988289832794148],
              [-35.194122791707422, -7.987286097375605],
              [-35.19409187299339, -7.986980917839613],
              [-35.194133953300444, -7.986557479975803],
              [-35.194139891061241, -7.986429815195451],
              [-35.19413354630732, -7.98632842921907],
              [-35.193773898173738, -7.983929529591397],
              [-35.193384090578689, -7.981093924124363],
              [-35.193284965473708, -7.980581023548417],
              [-35.193265300908209, -7.980311307931391],
              [-35.193220351473364, -7.979840152344723],
              [-35.193165816216833, -7.979441813015303],
              [-35.193042302011627, -7.978453037510477],
              [-35.192986370790983, -7.977826736976876],
              [-35.192886130527747, -7.977410380388781],
              [-35.192758265594271, -7.976999864739307],
              [-35.192713412148656, -7.976852582644232],
              [-35.192730226507933, -7.976332559067112],
              [-35.192752389286476, -7.97603342509985],
              [-35.192834640713421, -7.975371501179201],
              [-35.192871894162678, -7.974398957660528],
              [-35.192952367527845, -7.974203104124995],
              [-35.192972858210837, -7.973590138197724],
              [-35.193034759121709, -7.973193080161977],
              [-35.19298315555384, -7.972629669180216],
              [-35.192961612580035, -7.972466535367695],
              [-35.192941515271329, -7.971451359289889],
              [-35.192966728627603, -7.970839633816778],
              [-35.193008255736373, -7.970039173964712],
              [-35.193046727058423, -7.968886201651754],
              [-35.193061807639687, -7.967835857600273],
              [-35.192979714306844, -7.967557071192594],
              [-35.192774245612547, -7.966858343563727],
              [-35.192655731944491, -7.966551095387908],
              [-35.192574763518685, -7.966313159976599],
              [-35.192311968711557, -7.965507981951543],
              [-35.191695243929814, -7.963656488251506],
              [-35.191584847042336, -7.963459926649501],
              [-35.191372866019066, -7.962831285313642],
              [-35.1912774647707, -7.962589990587108],
              [-35.191131299954172, -7.962078239675392],
              [-35.191061711004423, -7.961916624668163],
              [-35.19084591752874, -7.961320905470823],
              [-35.190723472916481, -7.960989813099602],
              [-35.190395632161113, -7.960227640411049],
              [-35.190225861730028, -7.9596925112864],
              [-35.190029957090424, -7.959289944459288],
              [-35.189737696195039, -7.958551084661962],
              [-35.189628485983839, -7.958304257555717],
              [-35.189598745628167, -7.957945647838025],
              [-35.18955093258208, -7.957359525865972],
              [-35.189537590643106, -7.956947136600928],
              [-35.189584485880189, -7.956318572913658],
              [-35.18961241828147, -7.955995092598521],
              [-35.189643903433357, -7.955452663617222],
              [-35.189676280471183, -7.954839091264674],
              [-35.189694874655359, -7.954549918881646],
              [-35.189726259358281, -7.953971423953657],
              [-35.189739905752319, -7.95360417880647],
              [-35.189762198798853, -7.953226040984015],
              [-35.189799460318149, -7.952815192635931],
              [-35.189806843417117, -7.952651634185741],
              [-35.189772386511471, -7.95234366883605],
              [-35.189714763930887, -7.951958901859108],
              [-35.189667960213193, -7.951613759972299],
              [-35.189611036987039, -7.951224017642997],
              [-35.189602965069334, -7.951027726791056],
              [-35.189343718322981, -7.951014632287207],
              [-35.18904750968526, -7.951016736082642],
              [-35.188781239571675, -7.951022116771387],
              [-35.188296559507314, -7.950996106986096],
              [-35.188036829047704, -7.950969453866133],
              [-35.187764484623116, -7.950957872148718],
              [-35.187627364218798, -7.950952809356194],
              [-35.187164588196978, -7.950919632209404],
              [-35.187003974727212, -7.950912794686628],
              [-35.186642386669263, -7.950896710585821],
              [-35.186221894580328, -7.950886630967354],
              [-35.18594485961502, -7.950879682729793],
              [-35.185499814652211, -7.950787564847061],
              [-35.184899645324208, -7.950577938753402],
              [-35.184227959567558, -7.950218274537796],
              [-35.18381340294539, -7.949941864536195],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0150000',
        uf: 'PE',
        nome_proje: 'PA QUELUZ',
        municipio: 'IPOJUCA',
        area_hecta: '280.4200',
        capacidade: 29.0,
        num_famili: 27.0,
        fase: 4.0,
        data_de_cr: '14/10/1998',
        forma_obte: 'Desapropriação',
        data_obten: '10/06/1998',
        area_calc_: 257.4081,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.143615371117789, -8.422971578953561],
              [-35.14329598554685, -8.422917956997411],
              [-35.143120687919755, -8.422935699768598],
              [-35.142911212138806, -8.423027731214466],
              [-35.142676031440324, -8.423163374420501],
              [-35.142443921151092, -8.42333076477218],
              [-35.142344332595449, -8.423580918660091],
              [-35.142313620656765, -8.423793500110108],
              [-35.142090170721239, -8.423992317871051],
              [-35.141837720613381, -8.424142418416425],
              [-35.141441673425739, -8.424318738690451],
              [-35.141089241462133, -8.424609818484997],
              [-35.140828110251398, -8.42473981735982],
              [-35.14057847840516, -8.424861148000673],
              [-35.140164837129561, -8.42495999713916],
              [-35.139837586277423, -8.425095933205247],
              [-35.13963885307129, -8.425030442139716],
              [-35.139425276795556, -8.424855855325882],
              [-35.139019505006303, -8.424779506096693],
              [-35.138625583588237, -8.424786397974493],
              [-35.138206246341042, -8.424911119523877],
              [-35.137936721001324, -8.425101463626689],
              [-35.137584804323652, -8.425521791198634],
              [-35.137200654725461, -8.425795730919297],
              [-35.1364941073005, -8.425998830773327],
              [-35.136063010259342, -8.426048904645732],
              [-35.136002727063229, -8.426077805964001],
              [-35.135675230856343, -8.426144797653196],
              [-35.135459923074805, -8.426240212989015],
              [-35.135316007266958, -8.426211916509422],
              [-35.135250530566829, -8.426182777447641],
              [-35.13507673890534, -8.426083350340306],
              [-35.134851255525568, -8.425808290199226],
              [-35.134478912696217, -8.425476258198453],
              [-35.134205104811393, -8.425324855986011],
              [-35.13394038269044, -8.425294033856078],
              [-35.133698605820186, -8.425245903066148],
              [-35.133335888583915, -8.425149318762699],
              [-35.133045110339737, -8.425078358355725],
              [-35.132662894561847, -8.4251426527685],
              [-35.132280264002155, -8.425106422714723],
              [-35.132162371365801, -8.425103899317529],
              [-35.131949666934027, -8.425136113734398],
              [-35.131791425841023, -8.425119337392209],
              [-35.131662012608317, -8.425128329243574],
              [-35.131437087827081, -8.424979651334153],
              [-35.131273077589213, -8.424969546083226],
              [-35.131166397622579, -8.424900938192286],
              [-35.130973893226958, -8.424941702090473],
              [-35.130732435207229, -8.424956761605623],
              [-35.130542752775625, -8.424977406502581],
              [-35.130370029543947, -8.424931963055219],
              [-35.130323740262263, -8.42486891176379],
              [-35.130311535760612, -8.424702401033272],
              [-35.130327801241606, -8.424469748620426],
              [-35.130298346107985, -8.424306124649654],
              [-35.130122517053849, -8.424208992832142],
              [-35.129998392392892, -8.424094477959507],
              [-35.129891544886526, -8.423988538028343],
              [-35.129712534711174, -8.423811027298253],
              [-35.129729684329284, -8.423788010492169],
              [-35.129648820644988, -8.423710692697663],
              [-35.129418136872516, -8.423567748298378],
              [-35.129305888182238, -8.423536484348073],
              [-35.129202266954081, -8.42352242064784],
              [-35.12898187548781, -8.423558299770805],
              [-35.128861368558923, -8.423630463497936],
              [-35.128599353601437, -8.423545070213533],
              [-35.128564204245549, -8.423395813193091],
              [-35.128405547849091, -8.423281394449738],
              [-35.128201225217488, -8.423236039740281],
              [-35.128221963369967, -8.423379553605095],
              [-35.128147662480508, -8.423494683815305],
              [-35.128145305820389, -8.423618228386886],
              [-35.12816336326938, -8.423807739970885],
              [-35.128178339576777, -8.423948419588926],
              [-35.128173304054442, -8.424317541729518],
              [-35.128169169947157, -8.425080621070936],
              [-35.128172845122414, -8.425364246977106],
              [-35.128125364405534, -8.425697532214167],
              [-35.128123197844161, -8.425864112103719],
              [-35.128167108009848, -8.426044932246787],
              [-35.128145009960171, -8.426260387504435],
              [-35.128066105214224, -8.426642619488144],
              [-35.128049740362229, -8.426852330798932],
              [-35.128048367873781, -8.427205587082895],
              [-35.12802078066958, -8.42748136459868],
              [-35.128021695503421, -8.427696767397572],
              [-35.128022254891121, -8.42782891969026],
              [-35.128005239077197, -8.427886402809147],
              [-35.127976667856011, -8.427932468613236],
              [-35.128023657926796, -8.428268007638293],
              [-35.127986773542425, -8.428385890986119],
              [-35.12795684550526, -8.428788033270894],
              [-35.12791783935436, -8.429083967212206],
              [-35.127918224188285, -8.429175895335343],
              [-35.127968183331085, -8.429428482170433],
              [-35.127962633724046, -8.429474432175917],
              [-35.127945837182772, -8.429583619210225],
              [-35.127941088527372, -8.429819129116037],
              [-35.127947622350469, -8.43000287273429],
              [-35.128020620999244, -8.430266841647459],
              [-35.128092948856988, -8.430370022191504],
              [-35.128050505896269, -8.430533873711459],
              [-35.128047990759775, -8.430620068524789],
              [-35.128062951981221, -8.430757837462632],
              [-35.128080422186585, -8.430806627498649],
              [-35.128063807213486, -8.430958940293834],
              [-35.128053775287263, -8.431161268261624],
              [-35.128041877265019, -8.431211691029345],
              [-35.127964585742625, -8.431298083590891],
              [-35.128020135685574, -8.431510478158094],
              [-35.128009269369564, -8.431659858140286],
              [-35.128088040023137, -8.431926706291708],
              [-35.128149463925723, -8.432170634335002],
              [-35.128239981223658, -8.432492052488779],
              [-35.128223388695474, -8.432650096107222],
              [-35.128075290790463, -8.43298941431072],
              [-35.128035490534643, -8.433098699065548],
              [-35.128044432796969, -8.433173369808683],
              [-35.128038950562271, -8.433236593650275],
              [-35.127977211719234, -8.433598674400848],
              [-35.12796498271252, -8.43375946944041],
              [-35.127965784139938, -8.433949020258927],
              [-35.1279878113723, -8.434394115816486],
              [-35.128000499767751, -8.434675496142839],
              [-35.128001030407958, -8.434798970775125],
              [-35.128015636187271, -8.434853471098728],
              [-35.128116334920762, -8.434870406678476],
              [-35.128590586602492, -8.434817338640931],
              [-35.128783326566079, -8.434836883913102],
              [-35.129544925771732, -8.43472266728241],
              [-35.130537073976853, -8.434725530606562],
              [-35.130873683296997, -8.434767623611162],
              [-35.131287610583982, -8.434734839088243],
              [-35.131753685956156, -8.434773696573366],
              [-35.13215939763041, -8.434827082506109],
              [-35.132877182175562, -8.434820385590301],
              [-35.133412920880481, -8.434766001169194],
              [-35.133640013674089, -8.434750980560183],
              [-35.13402827702717, -8.434763095535329],
              [-35.136612994389687, -8.434740486732867],
              [-35.1382463298619, -8.434541464738684],
              [-35.138404574517637, -8.434558237442538],
              [-35.138720493719738, -8.434534359156482],
              [-35.138938927581997, -8.434505001276078],
              [-35.139275135007722, -8.434437957774437],
              [-35.139401627319309, -8.4344289695944],
              [-35.139588613142053, -8.434445657320884],
              [-35.139838819467634, -8.434439175771033],
              [-35.140436609992733, -8.434362767281032],
              [-35.141048671547431, -8.434251831334834],
              [-35.141390615317221, -8.4341847729149],
              [-35.14147967844201, -8.434175907135716],
              [-35.141577472023386, -8.434181366100821],
              [-35.141638105602652, -8.434235758709262],
              [-35.141710096647799, -8.434255649646675],
              [-35.142319232046717, -8.434136122440387],
              [-35.142511810503557, -8.434106831623918],
              [-35.142761769806746, -8.434045779570889],
              [-35.143008722490272, -8.433953142539014],
              [-35.143276153676126, -8.433940870014625],
              [-35.145608971471674, -8.434215317058893],
              [-35.145726956460784, -8.434240812975064],
              [-35.145775798756731, -8.434237797776245],
              [-35.145856081256213, -8.434174354893171],
              [-35.145979780930134, -8.434194088576806],
              [-35.146060464044801, -8.434231187015632],
              [-35.146474435415371, -8.434192637674993],
              [-35.146718800970689, -8.434177551286997],
              [-35.146902777002708, -8.434165525235487],
              [-35.147253405982759, -8.43410992135915],
              [-35.147417104114901, -8.434060619820201],
              [-35.147874109380403, -8.434004704059133],
              [-35.148215993587584, -8.43392902594975],
              [-35.148336720338335, -8.43390856865023],
              [-35.148474350655469, -8.433807614723968],
              [-35.14862639912905, -8.433715250393053],
              [-35.149009918553446, -8.433291956893784],
              [-35.149239139419443, -8.433095986709848],
              [-35.149402587298148, -8.432994999567011],
              [-35.149724001744673, -8.432847606916015],
              [-35.149850115944631, -8.432752455712654],
              [-35.149964324525385, -8.432559691876941],
              [-35.150127170871208, -8.432312236231482],
              [-35.150252570528949, -8.432053433728637],
              [-35.150360054943917, -8.431639589245444],
              [-35.150323004514107, -8.431048048602808],
              [-35.150483090298977, -8.43082354968511],
              [-35.150579612220085, -8.430530303392709],
              [-35.15066803630674, -8.430363460791462],
              [-35.150805308762507, -8.430184988083157],
              [-35.150988737757189, -8.430035088369442],
              [-35.151295549325468, -8.429821635253917],
              [-35.151556678259126, -8.429691629460715],
              [-35.151774936521214, -8.429624934312274],
              [-35.152027497895297, -8.429515069438544],
              [-35.152245783171701, -8.42945986276373],
              [-35.152347504785183, -8.429414779191264],
              [-35.151058081412685, -8.428443688547866],
              [-35.149361907037921, -8.427213776959542],
              [-35.148210592140416, -8.426292429042855],
              [-35.147786184213956, -8.425897359599405],
              [-35.146154038939038, -8.424845287213781],
              [-35.145027088736896, -8.424010298757867],
              [-35.144816396121776, -8.423841918585062],
              [-35.14460269647082, -8.423673102715858],
              [-35.144310524642144, -8.423469245214134],
              [-35.144096729659843, -8.423228657737564],
              [-35.143863178822244, -8.423091469617642],
              [-35.143615371117789, -8.422971578953561],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0412000',
        uf: 'PE',
        nome_proje: 'PDS ZE POJUCA',
        municipio: 'IPOJUCA',
        area_hecta: '310.0000',
        capacidade: 82.0,
        num_famili: 77.0,
        fase: 3.0,
        data_de_cr: '14/07/2014',
        forma_obte: 'Cessão Gratuita',
        data_obten: '14/07/2014',
        area_calc_: 310.1753,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.011287641065415, -8.416513258877405],
              [-35.011230408335194, -8.416381043519879],
              [-35.011144592905289, -8.416343126012986],
              [-35.011030151854612, -8.416343711695488],
              [-35.010829771026913, -8.416361443037886],
              [-35.010571973068323, -8.416399437423154],
              [-35.010266625979305, -8.416418925935188],
              [-35.010056778114624, -8.416371942738857],
              [-35.00950423043934, -8.416146617012933],
              [-35.009209592673926, -8.416043982663565],
              [-35.008647151334266, -8.415892861309571],
              [-35.008422310551531, -8.415893360303839],
              [-35.008242847517614, -8.416016172284561],
              [-35.008313846000782, -8.416297053320847],
              [-35.008290392203683, -8.416300303389107],
              [-35.007784421259927, -8.416370417177651],
              [-35.00903379958735, -8.417031432973529],
              [-35.009103300964469, -8.417916906685118],
              [-35.009148961531963, -8.418498633979418],
              [-35.006778705038322, -8.419716861457911],
              [-35.009060096324987, -8.421096515750463],
              [-35.008219435582198, -8.422829545629888],
              [-35.008628736040542, -8.424857229692545],
              [-35.009992675535436, -8.426201454541651],
              [-35.010379826848073, -8.427888672851358],
              [-35.011194424482277, -8.429416847009858],
              [-35.01041106384961, -8.430584160450092],
              [-35.008574158596218, -8.430161604388985],
              [-35.007860979402906, -8.429997544132606],
              [-35.00764852643983, -8.429988607722182],
              [-35.005448569527324, -8.429896064067149],
              [-35.006366286826946, -8.427892336441984],
              [-35.008063242258793, -8.429902659986368],
              [-35.007345527707962, -8.426808550377785],
              [-35.004914287477831, -8.425125266868013],
              [-35.006342698065083, -8.424275991316494],
              [-35.00464313837454, -8.418228917537361],
              [-35.001455651333359, -8.420422349434995],
              [-35.001474188243392, -8.421038797627284],
              [-35.002291554231391, -8.422412627718179],
              [-35.000468067455749, -8.424515351657204],
              [-35.001525401524006, -8.425152237100917],
              [-35.001506267677101, -8.42636501220421],
              [-35.002297799761564, -8.427409921535315],
              [-35.003682502493191, -8.427891644265433],
              [-35.003939283593418, -8.428645766090852],
              [-35.003645571569969, -8.430692147403645],
              [-35.00249199761943, -8.433019175474739],
              [-35.003066552917893, -8.435551723856227],
              [-35.001639634887361, -8.436831458337247],
              [-34.998618965650195, -8.435039347052406],
              [-34.998074610695845, -8.436900599961982],
              [-34.997760986150688, -8.437856940987364],
              [-34.998456413868475, -8.438897436813345],
              [-34.998890841997657, -8.440826087235473],
              [-34.99917575576076, -8.442447136116185],
              [-34.99994766811831, -8.442873425990786],
              [-35.000570173934058, -8.443564902157066],
              [-35.000781802010458, -8.443485067182428],
              [-35.001591476841334, -8.442805703377864],
              [-35.00191779593942, -8.442487705032645],
              [-35.002096244233172, -8.442440910132412],
              [-35.002579448170977, -8.442808371885318],
              [-35.002879136363482, -8.443006640385528],
              [-35.003122191608114, -8.443087005491329],
              [-35.003318894203623, -8.443006409542562],
              [-35.003388831062416, -8.442718959080134],
              [-35.003645572601314, -8.442296627584449],
              [-35.004324155305603, -8.442201448049172],
              [-35.005315571622155, -8.443521803942597],
              [-35.005953177321011, -8.444069297001862],
              [-35.006992492487491, -8.444359339976369],
              [-35.008074125938577, -8.444353796368505],
              [-35.009203710891981, -8.443806149599439],
              [-35.009697576080022, -8.442894116661041],
              [-35.009862430309724, -8.441475759323747],
              [-35.010048841620943, -8.440694642525164],
              [-35.01108278035052, -8.43820809763832],
              [-35.011413012552111, -8.437389105349716],
              [-35.01157108026689, -8.437139117408242],
              [-35.011813754835266, -8.436996120935653],
              [-35.012135774948504, -8.436900083003332],
              [-35.012275594102654, -8.436789878633901],
              [-35.012306346644721, -8.436275081377676],
              [-35.012439808956778, -8.433518660808529],
              [-35.013128188820239, -8.432595513929991],
              [-35.013326872529156, -8.432607510256782],
              [-35.014223041495669, -8.433107435447024],
              [-35.015633024562085, -8.433694686825378],
              [-35.016987076036365, -8.434492994527153],
              [-35.017196103584737, -8.434191388958503],
              [-35.017607503074096, -8.433597779820065],
              [-35.01764553216082, -8.433254129568631],
              [-35.017641638354739, -8.432503684544956],
              [-35.017717061997693, -8.432096502413444],
              [-35.018116384274862, -8.431100920093854],
              [-35.017323946752704, -8.431077256484299],
              [-35.017008338487123, -8.431282380341999],
              [-35.01657633759126, -8.431563150798555],
              [-35.016034857152007, -8.431628832275049],
              [-35.015483554444465, -8.431541810477677],
              [-35.015294899547463, -8.431731474774075],
              [-35.014997371278248, -8.43182285804424],
              [-35.014355441131677, -8.431673401765773],
              [-35.012205138818381, -8.430185672217771],
              [-35.014476223332395, -8.427405693287827],
              [-35.013002284354471, -8.42675440799645],
              [-35.013905918427184, -8.424486914333764],
              [-35.014502623228815, -8.425231902004356],
              [-35.014969290050118, -8.424713197809771],
              [-35.015732064988264, -8.42501816880517],
              [-35.015882454920593, -8.424806204591167],
              [-35.0157231789752, -8.4246574292016],
              [-35.015637299891367, -8.42447791142958],
              [-35.015589376378244, -8.424317182699761],
              [-35.015618120980946, -8.424175442051729],
              [-35.015742731437435, -8.424043225513195],
              [-35.015924929512451, -8.42393936093554],
              [-35.01605895948429, -8.423750279764572],
              [-35.016191761976479, -8.423485486101301],
              [-35.016219241737502, -8.423239801363726],
              [-35.016218169482791, -8.423041524195401],
              [-35.016131953412369, -8.422881019275122],
              [-35.015979365772736, -8.42277709272995],
              [-35.015789131933616, -8.422805243503911],
              [-35.015655619715453, -8.42289967338295],
              [-35.015493239605242, -8.422975267466096],
              [-35.015378522546825, -8.423050879029031],
              [-35.015224928330532, -8.423192709012529],
              [-35.015081055812907, -8.423381614465685],
              [-35.015023670460224, -8.423532650551996],
              [-35.014976060216092, -8.423627055268668],
              [-35.014861460364251, -8.423702566695134],
              [-35.014708514849417, -8.423711957598185],
              [-35.014535827855312, -8.423636293147274],
              [-35.014420743892721, -8.423504230970458],
              [-35.014372684486005, -8.423334652499774],
              [-35.014276655580545, -8.422986518347438],
              [-35.014228553428865, -8.422685806102125],
              [-35.014267004206125, -8.422412556288258],
              [-35.014258612849019, -8.422090893578087],
              [-35.01415411295374, -8.421779369450682],
              [-35.014008165415646, -8.421597667345216],
              [-35.012877745814215, -8.42048967082423],
              [-35.012450030948543, -8.420102113045861],
              [-35.012390793445277, -8.419967344159843],
              [-35.012247088053179, -8.41948677068193],
              [-35.011878236823549, -8.41886015489702],
              [-35.011668638907956, -8.418671984996386],
              [-35.011458747178395, -8.418662464841111],
              [-35.011344024883719, -8.418756399216395],
              [-35.011257827995465, -8.418878564081018],
              [-35.011200562399473, -8.419038991770392],
              [-35.011172476812348, -8.419219031504294],
              [-35.010991752541486, -8.419427207712054],
              [-35.010867348665528, -8.419511681507394],
              [-35.01074289341112, -8.419501805064165],
              [-35.010561148377661, -8.419378652283715],
              [-35.010447996512831, -8.419032927038945],
              [-35.0104480190926, -8.418815677612889],
              [-35.010390996430658, -8.418437491676871],
              [-35.010457600484756, -8.417994886849598],
              [-35.01055289450877, -8.417617702357241],
              [-35.0107341036694, -8.417230893585076],
              [-35.010924864655735, -8.417004432634151],
              [-35.011249482716366, -8.416664268469784],
              [-35.011287641065415, -8.416513258877405],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0271000',
        uf: 'PE',
        nome_proje: 'PA BOCA DA MATA',
        municipio: 'SAO JOSE DA COROA GRANDE',
        area_hecta: '131.2533',
        capacidade: 13.0,
        num_famili: 13.0,
        fase: 3.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/05/2001',
        area_calc_: 131.2489,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.248579532198463, -8.841647850395237],
              [-35.248414332833107, -8.842121303977313],
              [-35.248046278675524, -8.842688403132078],
              [-35.247961802880781, -8.842931260281182],
              [-35.247827277871728, -8.842935213045493],
              [-35.247287998974507, -8.843281081875102],
              [-35.246872329745273, -8.843435603926331],
              [-35.246556770435149, -8.844210997016294],
              [-35.246569205494637, -8.84451126404285],
              [-35.246817066557057, -8.844801821221765],
              [-35.247057355098093, -8.845090380871806],
              [-35.247153280567396, -8.845975292468506],
              [-35.246821523884435, -8.84639962709505],
              [-35.246508236686644, -8.84653850411677],
              [-35.246394756331554, -8.846738620376607],
              [-35.246457919251561, -8.847013510095174],
              [-35.246577787182588, -8.847803455003186],
              [-35.246777764771785, -8.848295587200077],
              [-35.246865955155343, -8.848486503097737],
              [-35.246925835614263, -8.849028998091141],
              [-35.246917284699983, -8.84913391451154],
              [-35.246874897617744, -8.849355152111478],
              [-35.246129365164904, -8.849796811958626],
              [-35.246588320390089, -8.851501310350891],
              [-35.246535521767136, -8.852357911152449],
              [-35.245636213083721, -8.854144932616615],
              [-35.245913021402139, -8.855075487695833],
              [-35.245950921721445, -8.855158541233514],
              [-35.246204422541567, -8.856065238321772],
              [-35.246295551193533, -8.856350498572565],
              [-35.246415647826971, -8.856581199904129],
              [-35.247484123640227, -8.858511438895807],
              [-35.247536390317627, -8.858594098414027],
              [-35.250559655923318, -8.859078671789051],
              [-35.251091362341974, -8.858462660177523],
              [-35.251347816615322, -8.858173818228849],
              [-35.251741140183697, -8.857711507601435],
              [-35.252038801386632, -8.857368068785862],
              [-35.252377512964863, -8.856975601846372],
              [-35.252644491042787, -8.856666214864864],
              [-35.253761935482501, -8.85537333225518],
              [-35.254791212069719, -8.854181767714584],
              [-35.254937295985634, -8.85401310577411],
              [-35.255466712749865, -8.853402446881965],
              [-35.255933858299429, -8.852860222871774],
              [-35.256255730912756, -8.852494474191646],
              [-35.255700298292467, -8.85160944884287],
              [-35.255646691542523, -8.851536569315444],
              [-35.254273744815677, -8.84948512441192],
              [-35.251545566745975, -8.845465734547007],
              [-35.251321804214257, -8.844954207744198],
              [-35.250693420509251, -8.843923328222479],
              [-35.250219690023123, -8.843169117676924],
              [-35.249644925864771, -8.842525394143314],
              [-35.249589545510418, -8.842480244905373],
              [-35.249515682947617, -8.842421892911158],
              [-35.248871642235493, -8.841894059818344],
              [-35.248579532198463, -8.841647850395237],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0012000',
        uf: 'PE',
        nome_proje: 'PA RECREIO',
        municipio: 'SALGADINHO',
        area_hecta: '364.0000',
        capacidade: 116.0,
        num_famili: 97.0,
        fase: 5.0,
        data_de_cr: '04/06/1987',
        forma_obte: 'Desapropriação',
        data_obten: '27/01/1987',
        area_calc_: 337.0671,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.623902786208781, -7.893039920926982],
              [-35.623099064561991, -7.892979539226466],
              [-35.622283950532378, -7.892966578422902],
              [-35.621650109928389, -7.892955913471895],
              [-35.621214957382875, -7.892978431170278],
              [-35.62111966428224, -7.892971889655843],
              [-35.619169864933504, -7.892973533567385],
              [-35.618643761630139, -7.89295965900987],
              [-35.61835732816256, -7.892967235907776],
              [-35.617492905203541, -7.892968129159764],
              [-35.614047545149603, -7.892944768699405],
              [-35.612497442137951, -7.892936292007986],
              [-35.612386210710049, -7.892931836053051],
              [-35.611712968939941, -7.892968843869932],
              [-35.611625496827514, -7.892995505757464],
              [-35.611575796774922, -7.892932289251195],
              [-35.61120713007719, -7.893772007949622],
              [-35.611348122927204, -7.894618664791075],
              [-35.611148052866149, -7.894971706987357],
              [-35.610950551423031, -7.895863491958286],
              [-35.61106280155856, -7.896058793497645],
              [-35.611402535002703, -7.896326050614581],
              [-35.612070191361113, -7.896884674601404],
              [-35.612478055778027, -7.897349766179726],
              [-35.612967988544781, -7.897894226888035],
              [-35.613412104067109, -7.898416381811509],
              [-35.613636950897494, -7.898818185392833],
              [-35.613855181687455, -7.899047071031512],
              [-35.614047593860725, -7.899133702412085],
              [-35.614010152722656, -7.899232886552924],
              [-35.61387226186288, -7.901285943506596],
              [-35.613840379812757, -7.901922766012814],
              [-35.613840343732107, -7.901922793348039],
              [-35.613728141672475, -7.903212980177159],
              [-35.613727890357168, -7.903389013215639],
              [-35.613619796056277, -7.905337241195467],
              [-35.613673027392807, -7.90544272567381],
              [-35.613616031654381, -7.90548029759257],
              [-35.613583460991968, -7.9059575548534],
              [-35.613535359422784, -7.906709875419589],
              [-35.613522980184044, -7.906931709201937],
              [-35.6134112810415, -7.908537295076015],
              [-35.613792240164706, -7.908699645316605],
              [-35.613759751331131, -7.909182127886385],
              [-35.613759723971398, -7.909182100948303],
              [-35.613742732595007, -7.909844231913238],
              [-35.613665995485697, -7.911232731169432],
              [-35.613643537221193, -7.91160970380905],
              [-35.61359668400523, -7.912156977919775],
              [-35.613611738472677, -7.912244718589096],
              [-35.613717544848484, -7.912207277086421],
              [-35.61371758199855, -7.912207264012819],
              [-35.613985906184951, -7.912246170448694],
              [-35.61398584135862, -7.91224616105032],
              [-35.613961913280818, -7.912690383551454],
              [-35.613926358529937, -7.913609331063457],
              [-35.613867599346854, -7.91468315632373],
              [-35.613854531916907, -7.915103255582179],
              [-35.613732930577115, -7.915122542572546],
              [-35.613658487038307, -7.9178627912988],
              [-35.61359069824254, -7.920550857598207],
              [-35.613286909188709, -7.924217703800398],
              [-35.613766879133401, -7.924454792764529],
              [-35.613949940930581, -7.922426121328201],
              [-35.614566733509882, -7.922470777516108],
              [-35.614633937003326, -7.921673335612046],
              [-35.614772793930882, -7.920496181876347],
              [-35.614837729667009, -7.91982761495621],
              [-35.61484712676048, -7.917769129429591],
              [-35.614847162785054, -7.917769093057757],
              [-35.614780818434561, -7.916596967667383],
              [-35.614783991477182, -7.915634440046759],
              [-35.614987238255047, -7.914830842781862],
              [-35.615225952892082, -7.914921120730702],
              [-35.61522596037058, -7.914921156829673],
              [-35.615520779827833, -7.915119737700198],
              [-35.615842588602796, -7.915458756984735],
              [-35.61698913830179, -7.916681701711506],
              [-35.617767785603078, -7.917442295007842],
              [-35.617939105765196, -7.917631889202434],
              [-35.618390256399167, -7.917913705323898],
              [-35.618834315922257, -7.918235054727324],
              [-35.619986556328506, -7.918907628753597],
              [-35.620041064970394, -7.918096353001129],
              [-35.620051375550339, -7.917992549485872],
              [-35.620034010679689, -7.917821800575769],
              [-35.620076188951209, -7.917444512138104],
              [-35.620094890390945, -7.917320323824027],
              [-35.620124513637762, -7.91711503140171],
              [-35.621276793064048, -7.917496732595425],
              [-35.621548718692154, -7.917111931010077],
              [-35.621581996010541, -7.917040250575899],
              [-35.621695666783062, -7.916821312495244],
              [-35.621835195463667, -7.916770566541352],
              [-35.621946954842308, -7.916737215044878],
              [-35.621989598497805, -7.916452088729049],
              [-35.621989562428411, -7.916452117872415],
              [-35.622052507922852, -7.916072371451008],
              [-35.622093081372391, -7.915766593600297],
              [-35.622300578743804, -7.914224572560658],
              [-35.622300605990041, -7.914224581425764],
              [-35.622444085705204, -7.913112005157679],
              [-35.62250543876857, -7.912666754593987],
              [-35.622530570531588, -7.912111938791916],
              [-35.622529702444837, -7.912003326020392],
              [-35.622533205313708, -7.911869293324244],
              [-35.622547516733903, -7.911065229644196],
              [-35.622519934482597, -7.91071650532398],
              [-35.622524487056786, -7.910638387249547],
              [-35.623024851778489, -7.910630196233351],
              [-35.623092053869797, -7.910606848294801],
              [-35.622684837785322, -7.90988151223894],
              [-35.622602515755972, -7.909571880271213],
              [-35.622458319102648, -7.908855110771789],
              [-35.622515845312655, -7.908664622198646],
              [-35.622659126818704, -7.90824732185312],
              [-35.622693105460506, -7.908105995009959],
              [-35.622693126982973, -7.908105958729061],
              [-35.622703781331644, -7.907970525639652],
              [-35.622692607088304, -7.907693899480074],
              [-35.622639082065696, -7.906968698455614],
              [-35.622646334878496, -7.906839973711874],
              [-35.622583237460056, -7.906822929739023],
              [-35.62266385912838, -7.906070589823497],
              [-35.622857116984804, -7.906118263027918],
              [-35.6229396937769, -7.90613428096478],
              [-35.623927278905832, -7.906099791248221],
              [-35.624179889390867, -7.906102631431658],
              [-35.625133598072921, -7.906128805631753],
              [-35.625615032107696, -7.906128037349297],
              [-35.626153573431665, -7.906186007579986],
              [-35.628091301931413, -7.906130620287803],
              [-35.628140179756336, -7.906106275734139],
              [-35.628271769179825, -7.905923182769097],
              [-35.628064664381419, -7.905719703072261],
              [-35.627658547670812, -7.905202321335175],
              [-35.627726565111068, -7.905151921860126],
              [-35.627604894860227, -7.904450837408213],
              [-35.626122128397377, -7.904381719547764],
              [-35.625948647516637, -7.904374180686172],
              [-35.625405996185982, -7.904359564991929],
              [-35.625543781282467, -7.902600659249126],
              [-35.625608689789232, -7.90181489421011],
              [-35.625706545993381, -7.901423814556079],
              [-35.625684520342539, -7.901366119777953],
              [-35.625724197687042, -7.901334423587683],
              [-35.625785052675482, -7.900222830108511],
              [-35.625715403333587, -7.900043713764719],
              [-35.625812201747408, -7.899700353278424],
              [-35.625839934533602, -7.899212843801587],
              [-35.625920596867132, -7.898223660086696],
              [-35.625935874326132, -7.897973706540414],
              [-35.62603854006867, -7.896938298831028],
              [-35.626087238886846, -7.896096971498063],
              [-35.626084366487412, -7.895972286690407],
              [-35.626088738517247, -7.895846652720805],
              [-35.626102104781403, -7.895093381548945],
              [-35.626207935935895, -7.894415345319953],
              [-35.626253353964486, -7.893169296603562],
              [-35.625678445140032, -7.893090223382205],
              [-35.624806740735117, -7.893056748741634],
              [-35.623902786208781, -7.893039920926982],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0210000',
        uf: 'PE',
        nome_proje: 'PA CONQUISTA DE BONITO',
        municipio: 'SAO JOAQUIM DO MONTE',
        area_hecta: '342.1900',
        capacidade: 41.0,
        num_famili: 32.0,
        fase: 4.0,
        data_de_cr: '13/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '06/09/1999',
        area_calc_: 327.6335,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.83405456495661, -8.503868782020499],
              [-35.831833859558877, -8.503403317258222],
              [-35.83182901463281, -8.506276217494595],
              [-35.831935494838469, -8.509341468882448],
              [-35.831986262673873, -8.511678926107184],
              [-35.831991465757461, -8.514853782859099],
              [-35.83205250963384, -8.516218123985064],
              [-35.832294907764883, -8.516278153739689],
              [-35.833261878045441, -8.5160264399762],
              [-35.833552101328998, -8.515691573874344],
              [-35.834203438697848, -8.515713654269181],
              [-35.834690121729729, -8.515584790877027],
              [-35.835322841391516, -8.515863952320979],
              [-35.835465278764779, -8.516066644972229],
              [-35.835837102472837, -8.516312474633029],
              [-35.835510348400902, -8.516702268647938],
              [-35.834515944165091, -8.517122776051494],
              [-35.834059209521598, -8.517362638604425],
              [-35.833451470612296, -8.517727830116202],
              [-35.832650996634989, -8.518072924577462],
              [-35.832063243437936, -8.51818219572397],
              [-35.832113535338721, -8.522350452020627],
              [-35.832166219918591, -8.522356572533935],
              [-35.832536011594932, -8.522399589335201],
              [-35.832477825934852, -8.52204088375553],
              [-35.83245595998909, -8.521450262730077],
              [-35.832802788573119, -8.520852433626054],
              [-35.833166792910589, -8.520171540452372],
              [-35.833648846659337, -8.519105358491981],
              [-35.834269126029341, -8.518951577089348],
              [-35.834898034779521, -8.519131473911344],
              [-35.835588730150221, -8.519419605680463],
              [-35.836194102910859, -8.519693813406731],
              [-35.836295984279708, -8.520328659883139],
              [-35.836404238516124, -8.521003031615587],
              [-35.835494305737022, -8.521507582521698],
              [-35.835292018278523, -8.521619825802684],
              [-35.835034087853984, -8.521878657011611],
              [-35.834935758938961, -8.522316655030254],
              [-35.834770916233069, -8.52257155374442],
              [-35.83659357812865, -8.522556247546992],
              [-35.840468640422692, -8.522518868588653],
              [-35.841547577616637, -8.522471940075384],
              [-35.844155140280741, -8.522376947111498],
              [-35.846716900770907, -8.522397283621569],
              [-35.848946677847799, -8.522382095080282],
              [-35.848906666571239, -8.519590064836903],
              [-35.848888578377149, -8.516693613939459],
              [-35.848872708207821, -8.514610893081038],
              [-35.848870567197714, -8.513757500167197],
              [-35.848842647451676, -8.512302410952715],
              [-35.848848829505336, -8.509973063909955],
              [-35.848827996233155, -8.507978375779054],
              [-35.847788671865523, -8.50815990785085],
              [-35.846819340239165, -8.508062206772411],
              [-35.846065618761948, -8.507716188859916],
              [-35.843440958249118, -8.506402786368788],
              [-35.840519507796408, -8.505379282677977],
              [-35.837418247825859, -8.504486350415515],
              [-35.83405456495661, -8.503868782020499],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0209000',
        uf: 'PE',
        nome_proje: 'PA SILVIO JUNGMANN',
        municipio: 'SAO JOAQUIM DO MONTE',
        area_hecta: '600.2370',
        capacidade: 58.0,
        num_famili: 54.0,
        fase: 4.0,
        data_de_cr: '13/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '06/09/1999',
        area_calc_: 599.2707,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.845103103228794, -8.474153856394576],
              [-35.844126698602061, -8.474242466820714],
              [-35.842209373107266, -8.474136456494982],
              [-35.841181851623382, -8.474209440667822],
              [-35.842941525751321, -8.476151002342176],
              [-35.841983147948817, -8.477236363077285],
              [-35.841193193321281, -8.477877526247189],
              [-35.840376054660986, -8.478746831578221],
              [-35.839894561461584, -8.477994586632617],
              [-35.839264101173669, -8.478347102316555],
              [-35.838208741951135, -8.477633814738239],
              [-35.838411255006292, -8.477530339432626],
              [-35.837996492530436, -8.476831180602353],
              [-35.837313226056075, -8.477407595813787],
              [-35.836355448279249, -8.476264533042384],
              [-35.835872754351804, -8.475384176003011],
              [-35.835520342578093, -8.47541537737651],
              [-35.835491857619189, -8.47524338267403],
              [-35.834250429038462, -8.475241018844923],
              [-35.833681729495027, -8.475304146343452],
              [-35.833590057742548, -8.475326495474366],
              [-35.833179378494144, -8.475331917208612],
              [-35.833154624402745, -8.47547087049276],
              [-35.83324261102203, -8.476345155236832],
              [-35.83441043032677, -8.476171698129574],
              [-35.834803218432057, -8.477711523005611],
              [-35.833094274453401, -8.478368567557627],
              [-35.832524123218143, -8.477057521637047],
              [-35.832163354688788, -8.476493301379469],
              [-35.831566914798543, -8.476548315908678],
              [-35.831585375961474, -8.478722122523486],
              [-35.831614675718271, -8.480739729789018],
              [-35.831628791268621, -8.481724956523507],
              [-35.831652041583489, -8.483646929418835],
              [-35.831663456597944, -8.484684306060343],
              [-35.83168644923002, -8.48617432946595],
              [-35.831703009310331, -8.4872447357233],
              [-35.831707358477985, -8.48730568862041],
              [-35.831707211467595, -8.487359898134104],
              [-35.831722671453129, -8.48969074903045],
              [-35.831729896228602, -8.490774684692269],
              [-35.831735478107483, -8.491585060307878],
              [-35.831745222275401, -8.492999739365787],
              [-35.831748883794383, -8.493535834171917],
              [-35.831752222934291, -8.494065064911316],
              [-35.831763998117296, -8.495929116908995],
              [-35.831759057052295, -8.496047236886138],
              [-35.832261546926219, -8.496195364462089],
              [-35.832723208628806, -8.4965549300662],
              [-35.83251989108777, -8.497415432102031],
              [-35.831777264878376, -8.49773632937225],
              [-35.831791258982584, -8.499110772477875],
              [-35.831810126723909, -8.500963207906263],
              [-35.831827087234061, -8.502629722170235],
              [-35.831824834042898, -8.503064219011854],
              [-35.833331715547899, -8.503288598974084],
              [-35.83436882267182, -8.503656163560278],
              [-35.836636361478583, -8.503961988980944],
              [-35.83846196677807, -8.504309423377061],
              [-35.838706334458465, -8.504391571820078],
              [-35.838802408053184, -8.504423937423828],
              [-35.839433161581169, -8.504636074230564],
              [-35.84206780944762, -8.505522009591695],
              [-35.843419298899185, -8.505976509437225],
              [-35.845377063167255, -8.506928350289618],
              [-35.846921642921409, -8.507709288022536],
              [-35.847778719271822, -8.50779570514767],
              [-35.848884813393866, -8.507623260756088],
              [-35.848811295366453, -8.505854733305833],
              [-35.848812185769852, -8.503397042654079],
              [-35.848801848411753, -8.501028063495179],
              [-35.848799305896293, -8.500438302882928],
              [-35.848786812821672, -8.497948636797616],
              [-35.848836106061299, -8.49654998946219],
              [-35.848871444537039, -8.495547250528775],
              [-35.848831002727593, -8.494125950588476],
              [-35.848792525021864, -8.492773750873825],
              [-35.848782359827119, -8.492417048442629],
              [-35.848793143219417, -8.491431293434644],
              [-35.848809069419396, -8.48997407473715],
              [-35.848770904805939, -8.488860386129161],
              [-35.848740041169812, -8.487961216085436],
              [-35.848725200797013, -8.486072367558128],
              [-35.848686935093667, -8.483762948024756],
              [-35.848733022774091, -8.482456657590145],
              [-35.848692493787581, -8.482350616321042],
              [-35.848692885626413, -8.480064674905943],
              [-35.848681154487899, -8.478534386875424],
              [-35.849302258273113, -8.478317807506636],
              [-35.848047052659737, -8.477417821219079],
              [-35.847073105904066, -8.476719416937692],
              [-35.84705281652522, -8.473963370304038],
              [-35.845103103228794, -8.474153856394576],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0294000',
        uf: 'PE',
        nome_proje: 'PA PENEDINHO',
        municipio: 'TRACUNHAEM',
        area_hecta: '366.6300',
        capacidade: 39.0,
        num_famili: 37.0,
        fase: 5.0,
        data_de_cr: '25/02/2003',
        forma_obte: 'Desapropriação',
        data_obten: '19/08/2002',
        area_calc_: 460.9737,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.136020047344644, -7.734113541921082],
              [-35.133196872128138, -7.73405114728698],
              [-35.13127160338211, -7.734020479881072],
              [-35.128126913979109, -7.733961429027213],
              [-35.125085144760092, -7.733906217265575],
              [-35.123271917463939, -7.73360546543496],
              [-35.123125413629566, -7.733598800054065],
              [-35.120854348142807, -7.73347167086345],
              [-35.118545697338469, -7.733409801436796],
              [-35.116143816588085, -7.733260769093835],
              [-35.116492939894577, -7.734355333824106],
              [-35.116902502464185, -7.73513506220071],
              [-35.117170220406528, -7.736249717799716],
              [-35.117537602524393, -7.737049984310544],
              [-35.117822046409167, -7.73797602419051],
              [-35.11917031723226, -7.740583415988864],
              [-35.119974853576082, -7.743881259368784],
              [-35.121627699106021, -7.746994079826385],
              [-35.123096655968169, -7.748997998608085],
              [-35.123051421038468, -7.749073632797804],
              [-35.123126518778555, -7.751984979822927],
              [-35.123183996952044, -7.754482850806843],
              [-35.123294885880014, -7.756555591964564],
              [-35.130514273832489, -7.75684336076641],
              [-35.130616103830597, -7.756819356878602],
              [-35.131460985013398, -7.75676375262515],
              [-35.131510600921594, -7.756743056486039],
              [-35.131588795137702, -7.756634659710191],
              [-35.132334970170966, -7.756181689238292],
              [-35.132617211987117, -7.755893448658177],
              [-35.133020620928214, -7.755666091214238],
              [-35.133381822845912, -7.75537938862225],
              [-35.13450561941869, -7.755122756646588],
              [-35.134979210621978, -7.755010836041869],
              [-35.1354314597897, -7.755008846701859],
              [-35.135913043803683, -7.75489122223571],
              [-35.1363995943289, -7.754891375357422],
              [-35.136482322648739, -7.754859097245361],
              [-35.136564600447436, -7.754757100246609],
              [-35.136834092199045, -7.754025042009138],
              [-35.137020038468414, -7.753723044763654],
              [-35.137077436012987, -7.753516752128046],
              [-35.136889535402467, -7.752115837512323],
              [-35.136573377438992, -7.751587268962582],
              [-35.136969066563999, -7.750885808818126],
              [-35.137437150940521, -7.749921740640579],
              [-35.137189971713056, -7.74969500841585],
              [-35.136784647815574, -7.749516501188453],
              [-35.136687318361155, -7.749413613635417],
              [-35.136691565490707, -7.748825556913692],
              [-35.136823793687086, -7.748268386187333],
              [-35.136808847514665, -7.747794472235817],
              [-35.136880385594417, -7.747249251874677],
              [-35.136893142703002, -7.746867938531515],
              [-35.136854207266268, -7.746646244042236],
              [-35.1368574878624, -7.746380148251259],
              [-35.137005765568063, -7.746182133598237],
              [-35.137687987379799, -7.745731054794538],
              [-35.137887706424593, -7.745419642115591],
              [-35.13807427661736, -7.745302795090841],
              [-35.138396464851482, -7.74529312087632],
              [-35.138521260851668, -7.745022662038107],
              [-35.13855290665267, -7.744879458932162],
              [-35.138541621959298, -7.744613747918193],
              [-35.138498659844721, -7.744371878365546],
              [-35.138591712059011, -7.744057486427984],
              [-35.138552815316757, -7.743938913951027],
              [-35.138378816594283, -7.743772086280053],
              [-35.138295276483625, -7.743530633818378],
              [-35.138302970122197, -7.743282653789387],
              [-35.138215825136925, -7.742776950583882],
              [-35.138182887973784, -7.742098071176995],
              [-35.138325040417584, -7.741319347924682],
              [-35.138322213804699, -7.740839879312569],
              [-35.138276392919387, -7.740570698514986],
              [-35.138185121186879, -7.740311204853517],
              [-35.138038226691393, -7.740065296708746],
              [-35.137965265416526, -7.73985402529694],
              [-35.138048749659738, -7.739550915171572],
              [-35.138384027960704, -7.739194565698636],
              [-35.138456541644004, -7.739074629789487],
              [-35.138501344712935, -7.738868138975685],
              [-35.138150614459249, -7.737987908425733],
              [-35.13789130874752, -7.737443770654783],
              [-35.13684430179454, -7.735408490221791],
              [-35.136020047344644, -7.734113541921082],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0226000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO MATO GROSSO DE BAIXO',
        municipio: 'TAMANDARE',
        area_hecta: '957.0800',
        capacidade: 90.0,
        num_famili: 90.0,
        fase: 5.0,
        data_de_cr: '18/05/2001',
        forma_obte: 'Desapropriação',
        data_obten: '21/07/2000',
        area_calc_: 911.0074,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.216674833315295, -8.681206022706407],
              [-35.21595466385795, -8.681605653844588],
              [-35.213803532422588, -8.682826911978148],
              [-35.212763589828448, -8.683488350248666],
              [-35.212171401232538, -8.683717575673807],
              [-35.210094618977131, -8.684842485679273],
              [-35.208679975939745, -8.684467613258025],
              [-35.206866128729061, -8.684035218553433],
              [-35.205148645148647, -8.683677550000592],
              [-35.204164343557721, -8.683369686389181],
              [-35.201879382876164, -8.682760288031027],
              [-35.201476314744497, -8.682649359298566],
              [-35.19848702622938, -8.68188818402497],
              [-35.196879320480207, -8.681443149119126],
              [-35.195479435905384, -8.681135575175263],
              [-35.195528150263989, -8.683638806774134],
              [-35.195585615236638, -8.686552252401425],
              [-35.19569551943664, -8.689999329652748],
              [-35.195759431458299, -8.691802312986587],
              [-35.194899267323684, -8.694611677847549],
              [-35.194874183580808, -8.694703295741014],
              [-35.194727454841811, -8.695417488670627],
              [-35.194092493500897, -8.697419741464278],
              [-35.193117771692798, -8.699716007923117],
              [-35.193446168538088, -8.700075037056383],
              [-35.193905456835225, -8.70077488976375],
              [-35.194087746479511, -8.701050156659498],
              [-35.194400217012067, -8.70162215053716],
              [-35.194591023153862, -8.702033673891721],
              [-35.194813667380252, -8.702358963400979],
              [-35.195271401808945, -8.702929927965549],
              [-35.195612527645487, -8.703601543546968],
              [-35.196169216611047, -8.704364914526321],
              [-35.196990974635732, -8.705748363292013],
              [-35.198106569332587, -8.707526011154465],
              [-35.198659638099969, -8.708367401792287],
              [-35.198763716124745, -8.708580025915557],
              [-35.199913595482457, -8.709039876025505],
              [-35.2022012897175, -8.710102790343434],
              [-35.20427905295815, -8.711026270696886],
              [-35.206851290075655, -8.712119768685238],
              [-35.207474355733304, -8.712407379129965],
              [-35.209548046762741, -8.713500234467938],
              [-35.212312922527495, -8.717761029101299],
              [-35.21969241389214, -8.712263177574654],
              [-35.223822303862661, -8.706640259089856],
              [-35.223463691863174, -8.704800352574189],
              [-35.222596263507874, -8.701511975151302],
              [-35.222410844710609, -8.700817168962621],
              [-35.221881058945087, -8.698691639804835],
              [-35.220453188621192, -8.699180110211875],
              [-35.217594511320712, -8.699211603805203],
              [-35.22099725493036, -8.695144026002113],
              [-35.220471457845122, -8.692905121701028],
              [-35.219781016736221, -8.690164802499186],
              [-35.218404299261437, -8.684161228199637],
              [-35.217440308732577, -8.680833966413404],
              [-35.217216427019146, -8.680978351504328],
              [-35.216674833315295, -8.681206022706407],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0202000',
        uf: 'PE',
        nome_proje: 'PA COCALZINHO',
        municipio: 'TAMANDARE',
        area_hecta: '223.2143',
        capacidade: 28.0,
        num_famili: 28.0,
        fase: 5.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '08/07/1999',
        area_calc_: 201.581,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.333080381475582, -8.775761084879631],
              [-35.326244317377721, -8.776009001164271],
              [-35.324919909656792, -8.775493039110643],
              [-35.32490713670682, -8.775717903219997],
              [-35.326856399680338, -8.776695009952828],
              [-35.327476502261412, -8.777005798790862],
              [-35.327379871202965, -8.777769485430843],
              [-35.327098498501996, -8.778454497060151],
              [-35.327016593864599, -8.778651218189619],
              [-35.326825787649817, -8.779115414097625],
              [-35.326340632464515, -8.780298151573659],
              [-35.32530193081152, -8.782825730260752],
              [-35.325163333464225, -8.784290938625045],
              [-35.324900088918781, -8.785844585135344],
              [-35.324732697861727, -8.786831488129826],
              [-35.324317030030919, -8.788361491912172],
              [-35.324236937894213, -8.788659880171368],
              [-35.324070471456572, -8.789269167990906],
              [-35.32392296927069, -8.790063989511104],
              [-35.323893683579357, -8.790234085572727],
              [-35.323764340200107, -8.790939490148206],
              [-35.323348214367073, -8.791872082107416],
              [-35.322908257966084, -8.79288967941527],
              [-35.322866305953546, -8.792951848886396],
              [-35.322671323653331, -8.793388776379725],
              [-35.323317670463538, -8.793444892179185],
              [-35.323522752035132, -8.793548025365824],
              [-35.324404419940649, -8.794274123682154],
              [-35.32451788228984, -8.794460420431299],
              [-35.324662613409018, -8.794619771439494],
              [-35.324833137445324, -8.794690028729073],
              [-35.325148557896469, -8.795062527105053],
              [-35.32533376730462, -8.795127360951689],
              [-35.325580992276734, -8.795373485275874],
              [-35.325735305578235, -8.795641323321075],
              [-35.326002279208993, -8.795908555553082],
              [-35.326328015706032, -8.796128516958792],
              [-35.326453192885005, -8.796153592165661],
              [-35.32659070359049, -8.796116770948412],
              [-35.326858661268773, -8.796221402041747],
              [-35.32713650078685, -8.796337088593935],
              [-35.327320929511458, -8.796567683492581],
              [-35.327427263429158, -8.796630653779626],
              [-35.327492913748273, -8.79669694851135],
              [-35.327522813565658, -8.796858092434837],
              [-35.327737983554108, -8.797411976913095],
              [-35.328128844442773, -8.797904391325964],
              [-35.328266706511364, -8.797952795758746],
              [-35.328372713054328, -8.798079576095487],
              [-35.328394531495277, -8.798255953687621],
              [-35.328440450777293, -8.798363402819515],
              [-35.328629975387202, -8.798449627880299],
              [-35.328899972967108, -8.798095564854675],
              [-35.329072961292624, -8.79794934382358],
              [-35.329224825158548, -8.797883059166388],
              [-35.329420798856113, -8.79783828308506],
              [-35.329512994274261, -8.797674395534996],
              [-35.329579933561867, -8.797598061904038],
              [-35.329741488274585, -8.7975399417095],
              [-35.32985646067857, -8.797357023698769],
              [-35.329949677884215, -8.797298422641969],
              [-35.330081312900241, -8.797280977845007],
              [-35.330090037840449, -8.797194249298837],
              [-35.330218834367372, -8.796723104829921],
              [-35.330251969753249, -8.796587962199261],
              [-35.330295276069812, -8.796481045583274],
              [-35.330429280428447, -8.796290508177618],
              [-35.330622173247129, -8.796131058452071],
              [-35.330771810280439, -8.795796177601133],
              [-35.331107022895644, -8.795584601080725],
              [-35.332006909757318, -8.795646632157506],
              [-35.332355881605309, -8.795746328558989],
              [-35.332961789145166, -8.795902546725484],
              [-35.332946035875551, -8.79541820781731],
              [-35.332943018846869, -8.795313656909187],
              [-35.332921962669502, -8.794591823272572],
              [-35.33299452302073, -8.79262731782239],
              [-35.333073105514956, -8.790492408852471],
              [-35.333134801880426, -8.788822347288045],
              [-35.333196020052839, -8.78716420980979],
              [-35.333200343392363, -8.785822212191688],
              [-35.333206021150936, -8.78402839659981],
              [-35.333184325606155, -8.782564185335788],
              [-35.333148037791489, -8.780131732927334],
              [-35.333104428267703, -8.777195727986863],
              [-35.333080381475582, -8.775761084879631],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0139000',
        uf: 'PE',
        nome_proje: 'PA BREJO',
        municipio: 'TAMANDARE',
        area_hecta: '1149.5000',
        capacidade: 110.0,
        num_famili: 102.0,
        fase: 5.0,
        data_de_cr: '03/06/1998',
        forma_obte: 'Desapropriação',
        data_obten: '08/12/1997',
        area_calc_: 1180.54,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.139392685915162, -8.74204007590378],
              [-35.136932161989805, -8.740645473460996],
              [-35.132674181420526, -8.740261260154744],
              [-35.13214002986232, -8.740211568438351],
              [-35.131523712657533, -8.740140282607612],
              [-35.128550760732246, -8.739653785509953],
              [-35.12441522996081, -8.738976990587934],
              [-35.123760698617936, -8.738799694357391],
              [-35.123252843521115, -8.73866216115473],
              [-35.122433428682555, -8.738440138066862],
              [-35.121510523479174, -8.738313423925188],
              [-35.118731609111677, -8.737739332162469],
              [-35.117623426256095, -8.73804300981219],
              [-35.116214618238558, -8.736806079553235],
              [-35.114912302081535, -8.735662610574087],
              [-35.114832787947648, -8.735547061940245],
              [-35.114763723498882, -8.735449461263526],
              [-35.114663152297972, -8.735332863989328],
              [-35.114520200579705, -8.7352314089201],
              [-35.114423922508045, -8.735180114653087],
              [-35.114379143753389, -8.735150191250579],
              [-35.114369393293195, -8.735128940000941],
              [-35.114344670356516, -8.735048130937578],
              [-35.114340343207417, -8.735039640050108],
              [-35.114267772505613, -8.734953421194447],
              [-35.114220380347263, -8.734901492592417],
              [-35.114087431643426, -8.734803181411724],
              [-35.114033606260669, -8.734749516952025],
              [-35.113928238623394, -8.734668452532503],
              [-35.113848820161571, -8.734632683965334],
              [-35.113763703068066, -8.734599785498752],
              [-35.113712189868075, -8.734575928761],
              [-35.113633007525557, -8.734561102901647],
              [-35.113583038553401, -8.734561381527865],
              [-35.113476724399952, -8.734575171704627],
              [-35.113420309520158, -8.73460857012557],
              [-35.113311509475544, -8.734777850267422],
              [-35.113225483492087, -8.734861039579775],
              [-35.113126055350953, -8.734937614506938],
              [-35.11298340914405, -8.735017774889311],
              [-35.112890359316403, -8.735031490992203],
              [-35.112652441686329, -8.735021292035],
              [-35.112580931080345, -8.735020018336934],
              [-35.112454370775524, -8.73500418175934],
              [-35.112357568502887, -8.734948496700529],
              [-35.112277299198325, -8.734876177528287],
              [-35.112203531911504, -8.734780681448074],
              [-35.112023254523372, -8.734486642642418],
              [-35.111973059846576, -8.734430688711415],
              [-35.111896228812213, -8.734388089616457],
              [-35.111819619392172, -8.734368720317844],
              [-35.111723170920698, -8.734375856337344],
              [-35.111646747179847, -8.734389479485753],
              [-35.111431171486117, -8.734523025037619],
              [-35.111102863071167, -8.734710069376476],
              [-35.110986722120586, -8.734753652989486],
              [-35.110790687031844, -8.734807715137849],
              [-35.110644416052395, -8.734825071614528],
              [-35.110511334830527, -8.734829066787771],
              [-35.110388173187097, -8.734819809268604],
              [-35.110266784727351, -8.734786587617284],
              [-35.10997333119397, -8.734659230015831],
              [-35.109736444175859, -8.734541491533987],
              [-35.109432478387859, -8.734338080751927],
              [-35.109305562913796, -8.734242879665114],
              [-35.109192042595055, -8.734170835077705],
              [-35.109018584819097, -8.734089090255548],
              [-35.108958491891151, -8.734066283883562],
              [-35.108858660802376, -8.734053551446982],
              [-35.108802059486237, -8.734053866323256],
              [-35.108722328284017, -8.734060998967248],
              [-35.108642412757391, -8.734051500239984],
              [-35.108555827893504, -8.734035439875459],
              [-35.108465825606523, -8.73400945522533],
              [-35.108265695510376, -8.733997732447465],
              [-35.10803396781197, -8.733957259383571],
              [-35.107858092697334, -8.733864047425692],
              [-35.107668233962251, -8.73378844943692],
              [-35.107636519187714, -8.733771179832301],
              [-35.107509622117753, -8.733760043784256],
              [-35.10763899038205, -8.734292013025872],
              [-35.107869810481091, -8.735270603209877],
              [-35.108826037662197, -8.737996166739888],
              [-35.110025136203397, -8.740185876104949],
              [-35.112325122426469, -8.742963421993769],
              [-35.118241840354479, -8.744869579259268],
              [-35.119876329147637, -8.747913462697896],
              [-35.120730725087306, -8.749654700564335],
              [-35.120919803400334, -8.750039987991661],
              [-35.120930974366132, -8.7501081537281],
              [-35.120888837421035, -8.750229091168473],
              [-35.120846499447701, -8.750314532553672],
              [-35.120839648720199, -8.750364277854361],
              [-35.120855654237069, -8.750418152540151],
              [-35.120855157603096, -8.750457204936291],
              [-35.120829938999798, -8.750539712043121],
              [-35.120782560142523, -8.75068163187963],
              [-35.120740527239022, -8.750756775838619],
              [-35.120732253004, -8.750808653346862],
              [-35.120736060902971, -8.750850528986499],
              [-35.120721439555915, -8.750915937689284],
              [-35.120673588700392, -8.751035488000113],
              [-35.120634296535016, -8.751154999380661],
              [-35.120591135123505, -8.751346955026051],
              [-35.120572218293539, -8.751409540439644],
              [-35.12057245149866, -8.75145072201633],
              [-35.120549065351327, -8.751481387723416],
              [-35.120532793357526, -8.751505613758097],
              [-35.120522273465618, -8.751537988086918],
              [-35.120521402367309, -8.751573490157261],
              [-35.120506507547823, -8.751590612651311],
              [-35.120483729642977, -8.751600330974227],
              [-35.120459884570103, -8.751612532035475],
              [-35.120429701450895, -8.751638264238936],
              [-35.12031591357146, -8.751773812981224],
              [-35.120307431257373, -8.751787347102084],
              [-35.120304645693878, -8.751800858335553],
              [-35.120264979446432, -8.751854330946877],
              [-35.120215009904683, -8.751914252058754],
              [-35.120197615532376, -8.751931216808698],
              [-35.120191591561699, -8.751939241278418],
              [-35.120191264346865, -8.751944033922761],
              [-35.120190603877958, -8.751952552612003],
              [-35.120186356702284, -8.751959862061614],
              [-35.120176757030272, -8.751965411732778],
              [-35.120169615708896, -8.751965451753556],
              [-35.120164471102925, -8.751969918863745],
              [-35.120162006736997, -8.751975970903567],
              [-35.120164722164539, -8.751982165661374],
              [-35.120168290802589, -8.751983393080968],
              [-35.12017009070712, -8.751985154690114],
              [-35.120169394026824, -8.75198888277173],
              [-35.120165677915047, -8.751993703447155],
              [-35.120064116462494, -8.752114268850431],
              [-35.119988249991536, -8.752202736446142],
              [-35.119966685768993, -8.752239068587723],
              [-35.119951816105896, -8.752259038299378],
              [-35.119926602811503, -8.752281542732119],
              [-35.119907851331639, -8.752309163336374],
              [-35.119893708409414, -8.752332319835812],
              [-35.119883159203148, -8.752361132856981],
              [-35.119871840085054, -8.752377530239777],
              [-35.119814744103387, -8.752441748756615],
              [-35.119803751175482, -8.752453172713809],
              [-35.119887170799288, -8.753005474501737],
              [-35.119895636488785, -8.753051581554525],
              [-35.119902136475083, -8.753065040752986],
              [-35.119910766556494, -8.753075993176395],
              [-35.119918656811009, -8.753083053821809],
              [-35.119922312876561, -8.753099719818309],
              [-35.119919862526771, -8.753108248539297],
              [-35.11990824025915, -8.753135295882164],
              [-35.119906670534341, -8.753172582613589],
              [-35.119914739668829, -8.75321123449185],
              [-35.119917415113846, -8.753242459202323],
              [-35.119940009166896, -8.753325412474336],
              [-35.119938250483415, -8.753329327293551],
              [-35.119931487583457, -8.753333622689414],
              [-35.119920885167701, -8.75335143522744],
              [-35.119914253984881, -8.753377387966848],
              [-35.119915567317662, -8.753419982668174],
              [-35.119923672650728, -8.753465025102635],
              [-35.119928090715874, -8.753489496753238],
              [-35.11993155835966, -8.753533860115549],
              [-35.119936800710107, -8.753577137858571],
              [-35.119943019690076, -8.753605151846653],
              [-35.1199470311591, -8.753620396684459],
              [-35.119952501226187, -8.753641310006309],
              [-35.11995239493325, -8.753653025490646],
              [-35.119947826515343, -8.753666185148044],
              [-35.119939308995768, -8.753673509494099],
              [-35.119933102313098, -8.753681363244825],
              [-35.119928347190488, -8.753692038150445],
              [-35.119929536872846, -8.753712794676847],
              [-35.119935575192791, -8.753739390512253],
              [-35.119945322216147, -8.753775899714656],
              [-35.119950141343196, -8.753790968279169],
              [-35.119971164716567, -8.753848448651022],
              [-35.119978622434672, -8.753873789110322],
              [-35.119981952329425, -8.753895428527439],
              [-35.119982583900267, -8.753912292257271],
              [-35.119976584037069, -8.75392297414029],
              [-35.119970385337574, -8.753932237973018],
              [-35.119967591753273, -8.753942730139423],
              [-35.119969536297418, -8.75395559115498],
              [-35.119973250557109, -8.753964880780865],
              [-35.119978145420681, -8.753978882286242],
              [-35.11998126650002, -8.753994132112654],
              [-35.119980792738673, -8.754005135554255],
              [-35.119979439686311, -8.754018105444807],
              [-35.11997109601915, -8.75405773515414],
              [-35.119973747382382, -8.754084702501403],
              [-35.119986745366745, -8.754111259325017],
              [-35.120011637872473, -8.75414440237803],
              [-35.118825175776131, -8.754126102291259],
              [-35.116165723938025, -8.754085037705369],
              [-35.113940109615527, -8.758302402038396],
              [-35.115886072391326, -8.758620003781026],
              [-35.115863946249988, -8.758953948475286],
              [-35.11583578801541, -8.759196503589777],
              [-35.115715264359125, -8.760807029976629],
              [-35.11453616246478, -8.760923275130146],
              [-35.115310114735493, -8.762799758078561],
              [-35.115722393085001, -8.762966755763136],
              [-35.115814441207021, -8.763802645408751],
              [-35.119168266216739, -8.766911272574108],
              [-35.119706686829439, -8.767560614780637],
              [-35.119890235985267, -8.767769846795908],
              [-35.120884652878146, -8.769692888035841],
              [-35.122278147251023, -8.769757010620182],
              [-35.139670368496645, -8.770555907418634],
              [-35.141528828046702, -8.770641185198077],
              [-35.141542897616354, -8.770627618998033],
              [-35.141540329639078, -8.770591757228576],
              [-35.144015963307687, -8.768155926432749],
              [-35.145742319716561, -8.766439843813782],
              [-35.146488172315649, -8.765716372973031],
              [-35.146627596277568, -8.76553077607498],
              [-35.14699983364779, -8.765052480371047],
              [-35.148239754882972, -8.763459150452128],
              [-35.151023958446814, -8.759421079240468],
              [-35.151420676256045, -8.758872499186241],
              [-35.151428658629989, -8.758863875664808],
              [-35.151431828143934, -8.758860458930299],
              [-35.151482047647313, -8.758788050255278],
              [-35.151649718700043, -8.758547786191473],
              [-35.152924646056029, -8.756687589076845],
              [-35.153620935996834, -8.755699811064034],
              [-35.153601056770562, -8.755688860447231],
              [-35.153430477422759, -8.755440615478649],
              [-35.153397255171924, -8.755371935932951],
              [-35.153349739073555, -8.755299776374418],
              [-35.15327780853886, -8.755202202345417],
              [-35.153161385539121, -8.755063699570249],
              [-35.153057941193069, -8.754946427958522],
              [-35.152758889235557, -8.754571815359627],
              [-35.152590353144518, -8.754305805361577],
              [-35.152439182978576, -8.754079766654561],
              [-35.15215051466312, -8.753652504769851],
              [-35.15201948083515, -8.753452040273327],
              [-35.151891443508212, -8.753278106444315],
              [-35.151796379047511, -8.75312953847461],
              [-35.151715759345628, -8.753010708244883],
              [-35.151669662249063, -8.75293713015089],
              [-35.151574736721273, -8.752812704694719],
              [-35.151442415308559, -8.752664755236331],
              [-35.150227564779527, -8.750941751312528],
              [-35.149588620176424, -8.750161601538963],
              [-35.149536282138428, -8.750097540463303],
              [-35.148043462527788, -8.747873520385625],
              [-35.147089319155491, -8.747199554310198],
              [-35.146489691529332, -8.746776037537417],
              [-35.14613509767819, -8.746525675829542],
              [-35.145750480875499, -8.746269789204369],
              [-35.145544086177402, -8.746132470608607],
              [-35.141646126069233, -8.743539237027209],
              [-35.139392685915162, -8.74204007590378],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0191000',
        uf: 'PE',
        nome_proje: 'PA MASCATINHO',
        municipio: 'TAMANDARE',
        area_hecta: '777.8200',
        capacidade: 62.0,
        num_famili: 49.0,
        fase: 5.0,
        data_de_cr: '13/08/1999',
        forma_obte: 'Desapropriação',
        data_obten: '24/11/1998',
        area_calc_: 789.9183,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.206688502712069, -8.745308645742265],
              [-35.206159020308583, -8.744877870320447],
              [-35.205037667355114, -8.744068282078882],
              [-35.196501637841955, -8.745865382491267],
              [-35.196084744609685, -8.748289042655315],
              [-35.195018200481201, -8.74853747078749],
              [-35.192461910915334, -8.749080257006257],
              [-35.191574727184133, -8.749332156749798],
              [-35.191019858712885, -8.749468692997734],
              [-35.190322435439477, -8.749620244709911],
              [-35.187496238368105, -8.750234424492728],
              [-35.186439236503972, -8.75046903678699],
              [-35.183218825318249, -8.751181458687237],
              [-35.178302234400221, -8.752290953604092],
              [-35.177811755804193, -8.752721951991502],
              [-35.176306460145696, -8.754007185451732],
              [-35.173316548599502, -8.756559824375838],
              [-35.173173024506738, -8.756658993186033],
              [-35.173360710610197, -8.757640178366742],
              [-35.173362555888126, -8.75772323581114],
              [-35.173793668948562, -8.757596923579172],
              [-35.174532023082882, -8.757588971674084],
              [-35.176145134592431, -8.756169976486149],
              [-35.178008478209854, -8.755301806156536],
              [-35.178282780625338, -8.75524029814793],
              [-35.178666384250263, -8.755614379132586],
              [-35.178854412637946, -8.756979900680861],
              [-35.179664849964688, -8.759286672443029],
              [-35.179909607207414, -8.7595650157041],
              [-35.182123260356384, -8.760388080080212],
              [-35.182631678182418, -8.760321872094796],
              [-35.183193789809621, -8.759802142708685],
              [-35.183849648177457, -8.759623541673564],
              [-35.185166688300917, -8.759289978712031],
              [-35.185674901057929, -8.759406905229236],
              [-35.186680362115531, -8.760111998320065],
              [-35.187671254120545, -8.759939497134031],
              [-35.188828959902274, -8.760156958786265],
              [-35.189723827399277, -8.761337141388864],
              [-35.189601079422125, -8.762190850342002],
              [-35.188740053807585, -8.76527178394397],
              [-35.189305081296936, -8.765762845300692],
              [-35.191194393549061, -8.767405105918682],
              [-35.193847311118212, -8.769710977703623],
              [-35.194675973206273, -8.770024419222358],
              [-35.196709398012416, -8.770793918526063],
              [-35.199225251105069, -8.771772274347747],
              [-35.199702743263906, -8.773026256342355],
              [-35.201481469284666, -8.772170306568043],
              [-35.21150119913824, -8.763322243376859],
              [-35.212051275955915, -8.762236634851808],
              [-35.212089944654551, -8.762169069915943],
              [-35.212723866896944, -8.761403926713168],
              [-35.215764372281789, -8.757734733624028],
              [-35.214308728286426, -8.752828699653074],
              [-35.21400596188996, -8.751807830228424],
              [-35.216068013265087, -8.746374126773613],
              [-35.212722986964771, -8.747605246819322],
              [-35.207053391973695, -8.745559604311861],
              [-35.206688502712069, -8.745308645742265],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0201000',
        uf: 'PE',
        nome_proje: 'PA SAUEZINHO',
        municipio: 'TAMANDARE',
        area_hecta: '899.8942',
        capacidade: 66.0,
        num_famili: 63.0,
        fase: 5.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/1999',
        area_calc_: 899.1395,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.358977487761898, -8.732073579866054],
              [-35.357423158704336, -8.733310590676915],
              [-35.356906822365183, -8.734498223470393],
              [-35.356843806136197, -8.734618276744747],
              [-35.355137270571667, -8.734561371871552],
              [-35.354331429280997, -8.735765977082268],
              [-35.353211884629999, -8.735590094094837],
              [-35.352179380904666, -8.735099238760069],
              [-35.352073649933367, -8.734970201082607],
              [-35.351715723045267, -8.734533632039103],
              [-35.351200802717059, -8.733905339115944],
              [-35.350000631225406, -8.732953316935403],
              [-35.350268588875963, -8.73199227223188],
              [-35.350056779644284, -8.730474040636397],
              [-35.350491205751013, -8.729143669584602],
              [-35.350469002586671, -8.727976822811433],
              [-35.349913217435308, -8.727246839645558],
              [-35.348519153784828, -8.728709232718193],
              [-35.347810511565356, -8.729130811054267],
              [-35.34681284234167, -8.731607062812349],
              [-35.346644865538529, -8.732781868244823],
              [-35.345723699638924, -8.733691724934543],
              [-35.344764221175524, -8.734491102675312],
              [-35.343267654665702, -8.735619446770825],
              [-35.343215319242532, -8.73579230493749],
              [-35.341399608684284, -8.737881907262192],
              [-35.338649084698297, -8.738237367570527],
              [-35.337612620001345, -8.738625167206978],
              [-35.336661041659767, -8.738612871219347],
              [-35.334910248932957, -8.738590225150713],
              [-35.334473898566678, -8.738614695809794],
              [-35.333206739518083, -8.738706465163242],
              [-35.332542336309629, -8.738754571644202],
              [-35.329966495505822, -8.739053584125969],
              [-35.329368886688279, -8.739122061956907],
              [-35.329154002680085, -8.739145164876795],
              [-35.328638149080007, -8.739474880707593],
              [-35.32593734052157, -8.741128907953941],
              [-35.325154810702927, -8.74161462824126],
              [-35.324477698945628, -8.742339760508003],
              [-35.323952200058706, -8.743474281143378],
              [-35.323352302616456, -8.74478713671544],
              [-35.322444695625506, -8.745495868091336],
              [-35.323960402552551, -8.746848974004546],
              [-35.325739126342157, -8.74864051773978],
              [-35.32638771184407, -8.749127026305709],
              [-35.327953757522636, -8.750301749604223],
              [-35.32839539149019, -8.750611657909429],
              [-35.330743230775667, -8.752259032563064],
              [-35.331062795450805, -8.752939163086008],
              [-35.33131441550281, -8.753474660621141],
              [-35.331631520244613, -8.754969944237978],
              [-35.331998797885376, -8.756701812843952],
              [-35.331605201990328, -8.758007170071824],
              [-35.332174542333419, -8.76046896631903],
              [-35.335964443963846, -8.758728784892417],
              [-35.337586305766706, -8.758021207345887],
              [-35.341193269709812, -8.75655678048151],
              [-35.345514288000132, -8.754459951399731],
              [-35.346305947155201, -8.753970688976137],
              [-35.347316108823293, -8.754207459503638],
              [-35.347439844480952, -8.754236878152566],
              [-35.351271045345932, -8.75514976605289],
              [-35.353036208618768, -8.755570255183162],
              [-35.357922707805479, -8.757811589496391],
              [-35.359395216924327, -8.756034228909321],
              [-35.360982925280112, -8.754562529575244],
              [-35.36300844751176, -8.754982880751236],
              [-35.361176797761615, -8.747475717772232],
              [-35.360778880318335, -8.744776409509438],
              [-35.360759463906263, -8.744650452681144],
              [-35.360737407531829, -8.744494777806317],
              [-35.360646371919856, -8.744031613697468],
              [-35.360431356200948, -8.742937016104513],
              [-35.360747874406449, -8.740935501307035],
              [-35.360773198247585, -8.7408023063954],
              [-35.361029916794969, -8.739446023910597],
              [-35.362940297717337, -8.738835165949087],
              [-35.363638295999117, -8.73806521725186],
              [-35.365780545435946, -8.734899528847649],
              [-35.363668306237315, -8.733197590225661],
              [-35.362534798060778, -8.731885674633906],
              [-35.361740718638714, -8.731171020157385],
              [-35.361091832206839, -8.731601566477444],
              [-35.359875508539965, -8.731997134285493],
              [-35.358977487761898, -8.732073579866054],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0084000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO MOROJOZINHO',
        municipio: 'VICENCIA',
        area_hecta: '267.0750',
        capacidade: 26.0,
        num_famili: 25.0,
        fase: 3.0,
        data_de_cr: '02/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 272.4048,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.260508985966723, -7.653633176169948],
              [-35.25902189242543, -7.653588819092644],
              [-35.258633070452149, -7.654389541259185],
              [-35.258194243495439, -7.65438135589018],
              [-35.257712887078561, -7.655002951126245],
              [-35.257813974992203, -7.655170544693894],
              [-35.2576607588868, -7.65518617125239],
              [-35.257560164929153, -7.656076223928348],
              [-35.257452533030616, -7.657040604139844],
              [-35.257073340014749, -7.657501043337109],
              [-35.256864468569781, -7.657754693250668],
              [-35.256743619633603, -7.657877984110774],
              [-35.256406292379893, -7.658150737197282],
              [-35.256307278347833, -7.658308442181251],
              [-35.256165850097297, -7.658498276623209],
              [-35.256146237687155, -7.65852468253057],
              [-35.255498308965358, -7.659139105314912],
              [-35.255425588028089, -7.659231502110332],
              [-35.255419857612793, -7.659278895934289],
              [-35.255408496419662, -7.6593754004512],
              [-35.255399852648004, -7.659833365220682],
              [-35.255461718297823, -7.660243497445596],
              [-35.253707509470253, -7.660289018268623],
              [-35.253487997506873, -7.660413367362558],
              [-35.252190771304662, -7.663075796164876],
              [-35.252354011196367, -7.663621886686426],
              [-35.252596064176679, -7.664027651237648],
              [-35.252993878943677, -7.664229125297536],
              [-35.253263718696886, -7.665128710951265],
              [-35.25358843144393, -7.666211055557646],
              [-35.253913156050118, -7.667293480691773],
              [-35.254075191941794, -7.667833619054712],
              [-35.25424126492382, -7.668193943317767],
              [-35.254646275399445, -7.669072661343427],
              [-35.255134600143208, -7.670132090109373],
              [-35.255263922156509, -7.670412611945022],
              [-35.255751821725681, -7.671132802744554],
              [-35.255845876430747, -7.671271517288785],
              [-35.256543645629741, -7.672235288093269],
              [-35.257859130385299, -7.674052074852178],
              [-35.258497673625683, -7.67493390620399],
              [-35.25879591764641, -7.675459937792211],
              [-35.260072218682986, -7.675773113491823],
              [-35.259977487185438, -7.674031904143961],
              [-35.259935557620807, -7.673178223147262],
              [-35.259896405286526, -7.672472846194887],
              [-35.260210248261089, -7.672368966936404],
              [-35.260496138652883, -7.672199793100244],
              [-35.260514781701993, -7.672178544152612],
              [-35.260608203071378, -7.671956962472683],
              [-35.260990882380341, -7.672207134501834],
              [-35.262918658877425, -7.67346732131106],
              [-35.263532670442864, -7.673754416391495],
              [-35.26372233059363, -7.673758750611273],
              [-35.263771445030727, -7.673759938189318],
              [-35.263938007124978, -7.673763761228597],
              [-35.265025355553405, -7.67383088638567],
              [-35.265962776298487, -7.673918051720965],
              [-35.26650155073353, -7.673941785503613],
              [-35.266775836782131, -7.673980471603481],
              [-35.266716211943837, -7.673637220373933],
              [-35.266684259405842, -7.672138019870292],
              [-35.26733584280565, -7.669940040340469],
              [-35.268534902251055, -7.665714742823574],
              [-35.268567600350153, -7.665251060059108],
              [-35.268511976928373, -7.664824721226158],
              [-35.26911249494934, -7.663865149767101],
              [-35.269279620919846, -7.66353326743444],
              [-35.269650920985725, -7.662560171162744],
              [-35.269756458361357, -7.662146902760099],
              [-35.27093319712143, -7.660844442685156],
              [-35.27233784773945, -7.660049653894753],
              [-35.271598428218873, -7.658593418572195],
              [-35.269420946673037, -7.660040631095265],
              [-35.269148957918219, -7.660348480131732],
              [-35.266791491498175, -7.659462346749111],
              [-35.266107237695131, -7.659440367883021],
              [-35.264359651389618, -7.659312802276914],
              [-35.264311341625351, -7.659309259961022],
              [-35.26420931387662, -7.658964413152568],
              [-35.264209448177837, -7.658938380579182],
              [-35.264252201778604, -7.658799138416001],
              [-35.264232357721923, -7.658747269392972],
              [-35.263862519339078, -7.658524236648191],
              [-35.263825982363592, -7.658519638099255],
              [-35.2635304541529, -7.658608506549441],
              [-35.263500617884546, -7.65863749727105],
              [-35.263439611699575, -7.658820402593176],
              [-35.263289193904008, -7.659211671304019],
              [-35.263205854900129, -7.659214549747487],
              [-35.263137310720076, -7.659068028641312],
              [-35.263065299513833, -7.659054216041499],
              [-35.262910769081408, -7.659198564951859],
              [-35.262847331025661, -7.659197180888213],
              [-35.262590533914747, -7.658934053236539],
              [-35.262521340425955, -7.658939116934303],
              [-35.262387949217427, -7.659123306380074],
              [-35.262318937840675, -7.659128549875091],
              [-35.262208264579066, -7.659016507082197],
              [-35.262182464564177, -7.659003445868805],
              [-35.261930941526323, -7.658967074882741],
              [-35.261893896142283, -7.658934819942615],
              [-35.261800597559585, -7.658612713091435],
              [-35.261757479646896, -7.658580038073284],
              [-35.261347848151289, -7.658576584845215],
              [-35.26132902388143, -7.658580570386953],
              [-35.261041944677238, -7.65870835042161],
              [-35.261011695748046, -7.658710678543168],
              [-35.260564201521696, -7.658590460450061],
              [-35.260526720366521, -7.65855801782366],
              [-35.260445423389491, -7.658487679209565],
              [-35.260422242356277, -7.658452377549729],
              [-35.260342185434588, -7.658273547922282],
              [-35.260308615405975, -7.658247420984857],
              [-35.260177754950725, -7.658224425837838],
              [-35.260114516619979, -7.658121714569778],
              [-35.260164359771146, -7.658020850439256],
              [-35.260332426830921, -7.657986434341447],
              [-35.260365597595467, -7.657902469984744],
              [-35.26038209555572, -7.657835315022619],
              [-35.26028403789887, -7.657452301402954],
              [-35.260262275519516, -7.657414542736488],
              [-35.260231096086528, -7.65736065390503],
              [-35.260276320560806, -7.657243001731856],
              [-35.260464999060488, -7.657114021374691],
              [-35.260573001517663, -7.656943072033308],
              [-35.260576029067657, -7.656778368016329],
              [-35.260411187818022, -7.656462149507732],
              [-35.260489153467397, -7.656333750095308],
              [-35.260428862057303, -7.65618845028375],
              [-35.26037933153146, -7.655729806863302],
              [-35.26050662865444, -7.655437897716311],
              [-35.26048244493505, -7.655318892325937],
              [-35.260387014399669, -7.654849643015376],
              [-35.260087088625681, -7.654877519289442],
              [-35.259797512775116, -7.654445172164425],
              [-35.260508985966723, -7.653633176169948],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0250000',
        uf: 'PE',
        nome_proje: 'PA PEDREIRAS',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '458.6848',
        capacidade: 63.0,
        num_famili: 60.0,
        fase: 4.0,
        data_de_cr: '23/04/2002',
        forma_obte: 'Desapropriação',
        data_obten: '25/04/2001',
        area_calc_: 465.3999,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.275654171811489, -8.150103631259537],
              [-35.270733724864257, -8.149303610190781],
              [-35.27045459944484, -8.149803039081128],
              [-35.270727318405342, -8.150677941851294],
              [-35.27066341011583, -8.152084050020605],
              [-35.270873103067217, -8.152784660943292],
              [-35.270545337059048, -8.154309397244743],
              [-35.270473238394679, -8.156770946328885],
              [-35.26978937257006, -8.161935699388451],
              [-35.268165149248858, -8.163085894771122],
              [-35.269511533669728, -8.164782381946232],
              [-35.274532246391665, -8.168835851434528],
              [-35.272915712424229, -8.175189233633299],
              [-35.272826839585626, -8.176989219698831],
              [-35.273471018739791, -8.176553894409203],
              [-35.274271118788718, -8.176930115954098],
              [-35.274470086184422, -8.176885601807895],
              [-35.27474540238282, -8.176673624644497],
              [-35.274866212900221, -8.174941801493132],
              [-35.275807970004081, -8.174766203943946],
              [-35.276668113727183, -8.174789560357292],
              [-35.277061602504752, -8.174109423372718],
              [-35.278477184682991, -8.173408333066204],
              [-35.279834957601992, -8.17326293043828],
              [-35.2802138141124, -8.171349590423603],
              [-35.280914327142781, -8.170486579098251],
              [-35.281835719570111, -8.167496354699008],
              [-35.282511385444238, -8.167625960998418],
              [-35.283385022311727, -8.16791823604628],
              [-35.284284418298896, -8.168530336594577],
              [-35.285035607546078, -8.168921999439295],
              [-35.286298457020543, -8.169317276224012],
              [-35.286488402094065, -8.170747967557023],
              [-35.286928299517541, -8.170956990403885],
              [-35.286950860877468, -8.171095703490398],
              [-35.287585747523607, -8.171900191654597],
              [-35.287859656247988, -8.172017950223385],
              [-35.289054996817505, -8.17164456476865],
              [-35.289849488383609, -8.171992585804926],
              [-35.290041269911143, -8.172216024982372],
              [-35.290766944640652, -8.172522133301266],
              [-35.291530910369367, -8.173244525623819],
              [-35.291022931168939, -8.171597626663452],
              [-35.290376191724043, -8.168906977645406],
              [-35.28968201685791, -8.166103783824131],
              [-35.289063436026304, -8.163956381161336],
              [-35.288838969149602, -8.163535714984228],
              [-35.288782138010198, -8.16311518525956],
              [-35.288354515145386, -8.162766167688428],
              [-35.288567363129879, -8.161911331080585],
              [-35.288049782194491, -8.160100687759241],
              [-35.28818922482931, -8.158779860750014],
              [-35.288614592385095, -8.157777390745474],
              [-35.28877961478117, -8.156360963869108],
              [-35.28846662680477, -8.155878963772714],
              [-35.289152667246562, -8.154582164817214],
              [-35.287501920908916, -8.152198702161014],
              [-35.286263955145934, -8.151766397058022],
              [-35.281459968159524, -8.150965860870476],
              [-35.275654171811489, -8.150103631259537],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0203000',
        uf: 'PE',
        nome_proje: 'PA COQUEIROS',
        municipio: 'TAMANDARE',
        area_hecta: '289.5000',
        capacidade: 44.0,
        num_famili: 44.0,
        fase: 5.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '08/07/1999',
        area_calc_: 299.1737,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.263947732544466, -8.754487740718368],
              [-35.260268907500098, -8.754902646572187],
              [-35.258226915950388, -8.755069493649138],
              [-35.258257061413339, -8.75521718291656],
              [-35.258351062145664, -8.755392690845838],
              [-35.25888380409814, -8.756231622313853],
              [-35.258898099112386, -8.756492667071372],
              [-35.258768124318351, -8.756679093527572],
              [-35.257867657333172, -8.757157816267741],
              [-35.257604170445425, -8.757290989775347],
              [-35.257515962596166, -8.757398080016603],
              [-35.257473956398563, -8.757513209770018],
              [-35.257391989192811, -8.757570460776702],
              [-35.257240730302918, -8.757496977290614],
              [-35.257149139295819, -8.757495083730973],
              [-35.257060400878622, -8.757604617463517],
              [-35.256938905265777, -8.757615465864657],
              [-35.256458739061351, -8.757916782436373],
              [-35.255963777457076, -8.758113521311655],
              [-35.255877069838753, -8.758168179206994],
              [-35.255643395234664, -8.758469470264362],
              [-35.255520497784417, -8.758534195990366],
              [-35.255186881052964, -8.758623959083943],
              [-35.254980236952804, -8.758722174732648],
              [-35.254873196962926, -8.758825219254906],
              [-35.25466273959529, -8.758819876490289],
              [-35.254552379323378, -8.758763953798791],
              [-35.254416100557933, -8.758764133867892],
              [-35.254288406914185, -8.758862239887652],
              [-35.254234588628343, -8.758992443889385],
              [-35.254221536791185, -8.75917853378736],
              [-35.253972314555014, -8.759221868156876],
              [-35.253977206469294, -8.759340152800631],
              [-35.253918251242339, -8.759432064237906],
              [-35.253684089895692, -8.759396583410082],
              [-35.253667704669795, -8.759270684548442],
              [-35.25369307730459, -8.759139023240129],
              [-35.253614756123838, -8.759092851580903],
              [-35.253520005996208, -8.759094501077719],
              [-35.253425800678002, -8.759156434043415],
              [-35.253409579725776, -8.759253875300558],
              [-35.253459584909265, -8.759327692824373],
              [-35.253490881792764, -8.759463083612154],
              [-35.253346027564596, -8.759502722308291],
              [-35.253265259246099, -8.759563129001652],
              [-35.253310468066992, -8.759738115862115],
              [-35.253133216559533, -8.759904667318583],
              [-35.253033066773511, -8.75988474693207],
              [-35.252975540277966, -8.759806635706049],
              [-35.252812211518581, -8.7597521128585],
              [-35.252683071602554, -8.759851582911736],
              [-35.252621695735023, -8.759979120344703],
              [-35.252621488704321, -8.760065439217616],
              [-35.252773194777859, -8.760409172857923],
              [-35.252871141723027, -8.760500781674439],
              [-35.253098938824486, -8.760564048968364],
              [-35.253247527356436, -8.760586211285114],
              [-35.253377014972841, -8.760710351222931],
              [-35.253408959337882, -8.76076467189964],
              [-35.25357125472631, -8.761026091558586],
              [-35.253559275699246, -8.761103758428492],
              [-35.253504503942445, -8.761196413212836],
              [-35.253394758764586, -8.761280402493849],
              [-35.25337350770647, -8.761394956506845],
              [-35.253416517458469, -8.761461223340509],
              [-35.253505346014514, -8.76145680710122],
              [-35.253588032989413, -8.7613378194139],
              [-35.253712329497645, -8.761384439859757],
              [-35.253763054833037, -8.761494768451485],
              [-35.253710475381027, -8.761611994881447],
              [-35.253718438986013, -8.761734916031287],
              [-35.253794711656141, -8.761802882498895],
              [-35.253765383621811, -8.761941527061056],
              [-35.253569772666417, -8.762031632406106],
              [-35.253154148032266, -8.762504564357657],
              [-35.253070531156595, -8.762621569117693],
              [-35.253011658896924, -8.762757506586025],
              [-35.253009084718911, -8.762873124257981],
              [-35.253078204307677, -8.762945110430778],
              [-35.253130241491235, -8.763090681389961],
              [-35.25309877491916, -8.763328310219],
              [-35.253125626987377, -8.763472314036077],
              [-35.253167751080944, -8.763601132487086],
              [-35.253213760984252, -8.76368274682684],
              [-35.253226624906098, -8.763766999230407],
              [-35.253173335566551, -8.763939861841997],
              [-35.253159380209176, -8.764128307231324],
              [-35.253090545145795, -8.764178971771711],
              [-35.253016802294674, -8.764123824828841],
              [-35.253008527343532, -8.764062322044932],
              [-35.252948998277439, -8.764028963467636],
              [-35.252861301770686, -8.764040423001651],
              [-35.252791469569971, -8.764106549263699],
              [-35.252748723673442, -8.764189687143832],
              [-35.252610936781942, -8.764166104800038],
              [-35.252573412468081, -8.764271446234474],
              [-35.252537655247977, -8.764594695246442],
              [-35.252541552521762, -8.764759000882876],
              [-35.25249705472762, -8.764928376452835],
              [-35.252435371535725, -8.765050221628044],
              [-35.252268247671843, -8.765284592072385],
              [-35.252216647336837, -8.765315630618931],
              [-35.252153578247736, -8.765305476800668],
              [-35.252111347911956, -8.765346040277491],
              [-35.252128199772962, -8.765443781535971],
              [-35.252068635090943, -8.765510117694454],
              [-35.251986786039708, -8.765557244319949],
              [-35.25191195854525, -8.765578478967621],
              [-35.251876697719219, -8.765697635817725],
              [-35.251887875587698, -8.765823267574898],
              [-35.251984560517307, -8.765760922058055],
              [-35.252085981339725, -8.765655235809582],
              [-35.25221880701335, -8.765787210330668],
              [-35.252241092876744, -8.765933338392934],
              [-35.252375570897023, -8.765983658238746],
              [-35.25236236746894, -8.766161225905448],
              [-35.252283137540836, -8.766278141304051],
              [-35.252287998978353, -8.766373251520314],
              [-35.252312344438124, -8.76662443978678],
              [-35.252437102803164, -8.766720254020431],
              [-35.252528080070263, -8.766895781303953],
              [-35.252552571833448, -8.767169691401657],
              [-35.252526131207375, -8.767519159774936],
              [-35.25248117009486, -8.767637289907423],
              [-35.252309093140497, -8.767743388728279],
              [-35.252167653705051, -8.767721518524825],
              [-35.252112333282, -8.76801578033067],
              [-35.252140239904357, -8.768146247281027],
              [-35.252148056887428, -8.768258377431705],
              [-35.252211290482713, -8.768330416723318],
              [-35.252315283779083, -8.76829572134123],
              [-35.252462257861431, -8.76828916851024],
              [-35.252478561952678, -8.768152752694506],
              [-35.252582589371471, -8.76812373322683],
              [-35.252729280877027, -8.768070317412235],
              [-35.252936644807207, -8.768135820552727],
              [-35.253080426724701, -8.768309613461128],
              [-35.253106813226132, -8.768424479847381],
              [-35.253020502684485, -8.768549951956357],
              [-35.253013926635397, -8.768643702261482],
              [-35.252934811629679, -8.769014788577492],
              [-35.253044496198463, -8.769215773789329],
              [-35.253132962020132, -8.769565261401521],
              [-35.253167461026386, -8.769842663680222],
              [-35.253122917291073, -8.770147508515077],
              [-35.253275364145061, -8.770219729109616],
              [-35.253432418309771, -8.770227315020627],
              [-35.253553931557377, -8.770257826729214],
              [-35.253639980525648, -8.770325472308841],
              [-35.253712951665541, -8.770354854674791],
              [-35.253896036634771, -8.770561330486601],
              [-35.253980020877137, -8.770875558120471],
              [-35.25408702895156, -8.771060388873435],
              [-35.254065180233177, -8.771294164121141],
              [-35.254120181444861, -8.77139000515441],
              [-35.254103094644194, -8.771509505542786],
              [-35.254146277297103, -8.77170954045253],
              [-35.254162845720735, -8.771925732393239],
              [-35.254377277327016, -8.772348989236972],
              [-35.254596829913645, -8.772476566580119],
              [-35.254789234517474, -8.772486715335859],
              [-35.255973782540082, -8.772839738917597],
              [-35.256259439142084, -8.77282528713952],
              [-35.256418811497397, -8.772872057498622],
              [-35.256533095108246, -8.772944947374615],
              [-35.25686621086885, -8.772980917165004],
              [-35.257117124645781, -8.772975620546179],
              [-35.257527781445916, -8.772913058689877],
              [-35.257775673353123, -8.772858791265685],
              [-35.257944358243797, -8.772776255252273],
              [-35.258110807830356, -8.77271623831607],
              [-35.258266480039673, -8.772571776224762],
              [-35.258607372633364, -8.772406411582169],
              [-35.258972831249125, -8.772171746475895],
              [-35.259329580866314, -8.771984413392955],
              [-35.25967341569207, -8.771734882454595],
              [-35.259924514613914, -8.77153362952023],
              [-35.260400048175121, -8.771517495772152],
              [-35.260783031487826, -8.77170184609168],
              [-35.261078724686449, -8.771919889893052],
              [-35.261394083817564, -8.772279728084596],
              [-35.261562146969275, -8.772576447288888],
              [-35.261973864557355, -8.772387878728303],
              [-35.267706545716457, -8.769754840390711],
              [-35.268236595438175, -8.769470643527942],
              [-35.268955697875811, -8.769064204651229],
              [-35.270504536379313, -8.767917232669273],
              [-35.27385036622276, -8.765439289084297],
              [-35.27304079584431, -8.764802699288259],
              [-35.272811099877018, -8.764680165227963],
              [-35.27273954472836, -8.764626546251975],
              [-35.272290028128552, -8.764212400435598],
              [-35.271452984074671, -8.763537657405232],
              [-35.27057663636451, -8.763004499279633],
              [-35.268367684917706, -8.76112809971775],
              [-35.268021154576388, -8.760855592833837],
              [-35.267891726454593, -8.760756856701915],
              [-35.265307001915659, -8.75901012840276],
              [-35.265184731311095, -8.758952924843586],
              [-35.265005865533759, -8.758806670352849],
              [-35.264978163601285, -8.758417822612564],
              [-35.264947299155601, -8.757986784359144],
              [-35.264940256296406, -8.757888931389218],
              [-35.264738537463266, -8.755542671021594],
              [-35.26469049155137, -8.754403936298518],
              [-35.264027628237145, -8.754478856697782],
              [-35.263947732544466, -8.754487740718368],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0200000',
        uf: 'PE',
        nome_proje: 'PA SAUÉ GRANDE',
        municipio: 'TAMANDARE',
        area_hecta: '635.9000',
        capacidade: 58.0,
        num_famili: 58.0,
        fase: 5.0,
        data_de_cr: '22/12/1999',
        forma_obte: 'Desapropriação',
        data_obten: '07/07/1999',
        area_calc_: 625.4962,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.291192021591648, -8.754444068716573],
              [-35.286325118537448, -8.754036692630903],
              [-35.284762600256606, -8.756152430762899],
              [-35.280951055087527, -8.760689291482528],
              [-35.280272873034583, -8.764791380572571],
              [-35.280210770811479, -8.76539398832367],
              [-35.279716776698017, -8.76766912513885],
              [-35.279615061733125, -8.768137653563052],
              [-35.278917799183631, -8.771348771773875],
              [-35.278816933493943, -8.771748152018279],
              [-35.277999704775908, -8.775295318226915],
              [-35.277790627100288, -8.776202312391648],
              [-35.277581291592064, -8.777110311468675],
              [-35.277325311010848, -8.778221042058362],
              [-35.27698001573409, -8.779718710203655],
              [-35.276299508689647, -8.782671226017627],
              [-35.276166550754176, -8.783246605444175],
              [-35.275888436458288, -8.784453186593389],
              [-35.275786023795973, -8.784897407023365],
              [-35.276492669247169, -8.785276193816518],
              [-35.277980525012921, -8.786026785962457],
              [-35.278396505029029, -8.786299398205809],
              [-35.279082618963287, -8.78689938039547],
              [-35.279684268080807, -8.78722365158675],
              [-35.280292811008394, -8.787308183602331],
              [-35.280813603370191, -8.787217540841498],
              [-35.281556456965902, -8.787393267793533],
              [-35.282029519622483, -8.787208913968078],
              [-35.28255342868453, -8.787315917809778],
              [-35.282938843503374, -8.787624952566404],
              [-35.28299633710327, -8.787948175476215],
              [-35.283432824566987, -8.788245330978077],
              [-35.284224384464963, -8.788467674437671],
              [-35.285104300811561, -8.78860631881089],
              [-35.285498151318677, -8.788795451211142],
              [-35.286011438419692, -8.788859039709253],
              [-35.286502722630587, -8.788801648027516],
              [-35.287269843459512, -8.788489419146998],
              [-35.287986097632817, -8.787959124195664],
              [-35.288677971427347, -8.787453840732569],
              [-35.289333748148735, -8.786602700580813],
              [-35.289837777289804, -8.786431707407299],
              [-35.290271508043091, -8.786235381853645],
              [-35.290538883233282, -8.786113909580004],
              [-35.291197785977644, -8.786260752800528],
              [-35.292401162166506, -8.786669103765876],
              [-35.293176738017401, -8.7869950039498],
              [-35.29422286304105, -8.787199950378493],
              [-35.294556468130359, -8.787387902807925],
              [-35.295878323294808, -8.78839901059524],
              [-35.296928521980625, -8.789529988159918],
              [-35.297440482253784, -8.789833984904799],
              [-35.298083555065141, -8.790095785902359],
              [-35.298197782737553, -8.790216743345626],
              [-35.298507490032733, -8.790315268223278],
              [-35.298558068237874, -8.789868381758216],
              [-35.298641356686112, -8.789598534975861],
              [-35.298645663934721, -8.789368757460684],
              [-35.298562875585645, -8.788865011915226],
              [-35.298473241005496, -8.78870974003997],
              [-35.29813311526329, -8.788555092278701],
              [-35.298096156306983, -8.788278296536918],
              [-35.298313844850526, -8.787982413460648],
              [-35.29881844191793, -8.787860211671658],
              [-35.299298024291033, -8.787643531933057],
              [-35.299699911091636, -8.787396957545297],
              [-35.299971794446392, -8.787506196867387],
              [-35.300219229083091, -8.787511013241602],
              [-35.300581642525636, -8.787287364925193],
              [-35.300931217517991, -8.787278723023466],
              [-35.301206554014755, -8.78713776312185],
              [-35.301929656856984, -8.786176102986991],
              [-35.302043737666757, -8.786052577178602],
              [-35.302072560475281, -8.785835213460466],
              [-35.301891591163979, -8.785676162105187],
              [-35.301671064049579, -8.78554085149797],
              [-35.301565158623895, -8.785296020966314],
              [-35.301336024388306, -8.785061514236883],
              [-35.301047853864567, -8.784638839999138],
              [-35.300791211456342, -8.784451869859147],
              [-35.300808390231104, -8.784128920625877],
              [-35.301042868700669, -8.783963265357803],
              [-35.301109226977935, -8.783836054413692],
              [-35.301012599976922, -8.783268774422964],
              [-35.300871453370995, -8.783143363267969],
              [-35.300674166075012, -8.782695188111708],
              [-35.300437873828123, -8.78265650038211],
              [-35.300335024110048, -8.782538546747288],
              [-35.300331584808504, -8.782363859325011],
              [-35.300069910339843, -8.782141398759222],
              [-35.299482812942436, -8.781642636386101],
              [-35.297466900384634, -8.780449398539581],
              [-35.2957294129044, -8.778640936920526],
              [-35.295586723116159, -8.778544734786925],
              [-35.294482194153645, -8.776106060838082],
              [-35.294360815308501, -8.775751776963519],
              [-35.293497723018845, -8.773228466997516],
              [-35.293583778330827, -8.772180592208077],
              [-35.293662053813762, -8.771226934217111],
              [-35.293797227404418, -8.769580058730014],
              [-35.293891987681512, -8.768425660719142],
              [-35.294342694178994, -8.765329656246809],
              [-35.294434131158525, -8.764373848959858],
              [-35.293217805230121, -8.763826367159925],
              [-35.292015412211718, -8.763285222206223],
              [-35.290691572026788, -8.76264339947679],
              [-35.290789657129984, -8.761035792055861],
              [-35.291192021591648, -8.754444068716573],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0192000',
        uf: 'PE',
        nome_proje: 'PA CAMPINA VERDE',
        municipio: 'VICENCIA',
        area_hecta: '326.3000',
        capacidade: 34.0,
        num_famili: 34.0,
        fase: 5.0,
        data_de_cr: '13/08/1999',
        forma_obte: 'Desapropriação',
        data_obten: '24/11/1998',
        area_calc_: 322.8459,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.27647794698602, -7.622038369198637],
              [-35.273526717729808, -7.621312332849461],
              [-35.273513273709867, -7.621786126959786],
              [-35.273427930447546, -7.625320763941345],
              [-35.273380116172227, -7.626731348056762],
              [-35.273417349189373, -7.626816840136229],
              [-35.273430198098886, -7.626985436563368],
              [-35.273433881297102, -7.627048146459122],
              [-35.273435201771207, -7.627160943716736],
              [-35.273432677271202, -7.627266168597477],
              [-35.273431441492512, -7.627358099669857],
              [-35.273395758272002, -7.627771902669013],
              [-35.273386685943137, -7.627889545130006],
              [-35.273373974683359, -7.628004323347329],
              [-35.273359911762093, -7.628137629169735],
              [-35.27334285999104, -7.628305388529232],
              [-35.273315358873511, -7.628724571810857],
              [-35.27331118098359, -7.628911244960801],
              [-35.273310087679299, -7.629047284621533],
              [-35.273268253884027, -7.629394323280957],
              [-35.27325795151215, -7.629519203258523],
              [-35.273249424844224, -7.629654378129533],
              [-35.27324639488392, -7.629801093758321],
              [-35.273235966433447, -7.629953551774512],
              [-35.273200695577437, -7.630376753014234],
              [-35.273206046772273, -7.630738005638009],
              [-35.273192647042791, -7.630911078705905],
              [-35.273180982303977, -7.631069590185469],
              [-35.273172736243318, -7.631223564287163],
              [-35.273110017976265, -7.631681618894849],
              [-35.273110728557654, -7.631970413803778],
              [-35.273111256750731, -7.632121810896735],
              [-35.273109255981566, -7.632274667498497],
              [-35.273111269525494, -7.632432835875359],
              [-35.273117479681353, -7.63266510031995],
              [-35.273121581068153, -7.632755466725669],
              [-35.273113789703856, -7.633029744840288],
              [-35.273110414357312, -7.633179625865314],
              [-35.273109938025442, -7.633329491639545],
              [-35.273102873723182, -7.633587044152187],
              [-35.273102964317033, -7.633587043675593],
              [-35.273161073558263, -7.634005424104778],
              [-35.273166985144179, -7.634061162381748],
              [-35.273185497349097, -7.634204058754223],
              [-35.273195727242573, -7.634288336906695],
              [-35.273206267032961, -7.634414101504594],
              [-35.273214220297376, -7.634598993440111],
              [-35.273231549987855, -7.634775249177967],
              [-35.273251511433671, -7.634969478266267],
              [-35.273271467189304, -7.635162622721369],
              [-35.273290861069022, -7.635335161670779],
              [-35.273313530036575, -7.635493130917077],
              [-35.273326201697977, -7.635645105814738],
              [-35.273305154977329, -7.635828252191087],
              [-35.273270034466648, -7.635954076283745],
              [-35.273255292147411, -7.636095882189883],
              [-35.273261191224563, -7.636252041537496],
              [-35.273289414787101, -7.636415314423981],
              [-35.273319840853894, -7.636583908598275],
              [-35.273332555366856, -7.636761092453844],
              [-35.273327191890431, -7.636928971112381],
              [-35.27329952565109, -7.63711097729681],
              [-35.273217446341796, -7.637625273898863],
              [-35.273217445863821, -7.637625183513406],
              [-35.273130900727431, -7.637586229905252],
              [-35.272244821898035, -7.641984400885897],
              [-35.271919221814741, -7.642671527209298],
              [-35.27151766421359, -7.644330191510978],
              [-35.271517724391295, -7.64447860834957],
              [-35.271518689104603, -7.644661006326607],
              [-35.271536922842841, -7.644802457994971],
              [-35.271640928433833, -7.645076147699784],
              [-35.27167000748176, -7.645142339404545],
              [-35.271699715481255, -7.645209250897124],
              [-35.271728168100523, -7.645278618510283],
              [-35.271755682205338, -7.645341835636464],
              [-35.271788904851746, -7.645405203475195],
              [-35.271827306808589, -7.64547143645161],
              [-35.271864732302262, -7.645541470859466],
              [-35.271894017996608, -7.645614169377295],
              [-35.271944112516174, -7.645732765801644],
              [-35.27196783700321, -7.645833152322352],
              [-35.271981584143766, -7.645914248351121],
              [-35.271992851232049, -7.645988939890859],
              [-35.272012496460363, -7.646054638892466],
              [-35.27204556864087, -7.646123792307072],
              [-35.272074048149143, -7.646198212408629],
              [-35.272102270707606, -7.646275435886388],
              [-35.272129498630314, -7.646353026149963],
              [-35.272161155756443, -7.646428694931362],
              [-35.272189042755585, -7.646510981888667],
              [-35.272214301031703, -7.646593011523528],
              [-35.272243152574582, -7.646669237395081],
              [-35.272266389050422, -7.64674585439185],
              [-35.272293940216002, -7.646816031109254],
              [-35.272319596516567, -7.646887664011727],
              [-35.272343587166169, -7.646952707362308],
              [-35.272374588217836, -7.64702422171197],
              [-35.272393323619603, -7.647106285679294],
              [-35.272411952218143, -7.647185286053896],
              [-35.272422748136769, -7.647273628613374],
              [-35.272400185256942, -7.647409871769058],
              [-35.272348822323721, -7.647530991090637],
              [-35.27230876267835, -7.647579559706555],
              [-35.272218004478361, -7.647652257839566],
              [-35.272078118700534, -7.647686438316053],
              [-35.271618661462938, -7.647819921060033],
              [-35.271344095249042, -7.647930375053923],
              [-35.27121378960819, -7.647994423284628],
              [-35.270744255187424, -7.648225215896943],
              [-35.270786048213012, -7.648298752511558],
              [-35.27089874034715, -7.648416025170716],
              [-35.271035525087562, -7.648651850421123],
              [-35.271026891217751, -7.64977687458524],
              [-35.271846101662284, -7.649795156158732],
              [-35.272389864458532, -7.649841461917553],
              [-35.272909682712729, -7.649884548820546],
              [-35.273417320472412, -7.64986876637505],
              [-35.273897492787917, -7.649729025633486],
              [-35.274341831356175, -7.649479652215248],
              [-35.274798848707434, -7.649195231515184],
              [-35.275268480423648, -7.648897818492616],
              [-35.275759704476052, -7.648621523284617],
              [-35.276224430383799, -7.64849270859994],
              [-35.276244592782859, -7.647818580838321],
              [-35.276237340405707, -7.647305126374202],
              [-35.276513220886685, -7.647152542118667],
              [-35.276513220886685, -7.647152542118667],
              [-35.276657878561444, -7.647147078458026],
              [-35.276989396034608, -7.647286694887899],
              [-35.277841750489912, -7.647643383476788],
              [-35.278173434952208, -7.647814362391149],
              [-35.278315718836517, -7.647907885086684],
              [-35.278521591531067, -7.64811866604311],
              [-35.278624049136369, -7.648133852175961],
              [-35.278726173283957, -7.648086220673014],
              [-35.278788262249833, -7.647920935281669],
              [-35.27886578910136, -7.647677111908583],
              [-35.27893588559153, -7.647535465762394],
              [-35.279045640980506, -7.647440611602724],
              [-35.279210521137749, -7.64735342024719],
              [-35.27926544383876, -7.647305947717699],
              [-35.279296380758417, -7.647211600406833],
              [-35.279366608919659, -7.647077726884481],
              [-35.279366233667567, -7.647007045804014],
              [-35.279334289169647, -7.646928667825975],
              [-35.279357643046687, -7.646873588788209],
              [-35.279388878792744, -7.646818468101052],
              [-35.279411933807815, -7.646724162447541],
              [-35.279560857242551, -7.646582000324663],
              [-35.279654612531459, -7.646459843245141],
              [-35.279685895624766, -7.646396587427208],
              [-35.279772082838846, -7.646333583779921],
              [-35.279921490005066, -7.646301339308208],
              [-35.280134452624701, -7.646363033033643],
              [-35.280276652647075, -7.646440828120519],
              [-35.280292666460383, -7.646487835354963],
              [-35.280308548400043, -7.646527069960369],
              [-35.280371603301482, -7.646526736635367],
              [-35.280434442310082, -7.646502813308659],
              [-35.280552663168294, -7.646517915742829],
              [-35.280679224438032, -7.646619303119238],
              [-35.280711127341746, -7.646689817444076],
              [-35.280750737983794, -7.646744563590626],
              [-35.280798070942026, -7.646752177030607],
              [-35.280868541639585, -7.646681121419684],
              [-35.280923498757247, -7.646657239699229],
              [-35.280994018718033, -7.646578410496716],
              [-35.281017205200499, -7.646491868449003],
              [-35.280985218729221, -7.646405627181749],
              [-35.281016287597929, -7.646319142882747],
              [-35.281155513864391, -7.646092980391487],
              [-35.281374849533485, -7.645887725700488],
              [-35.281571109730372, -7.645720013384918],
              [-35.28189247906117, -7.645484481474302],
              [-35.282277207087859, -7.645254760609008],
              [-35.282612857585228, -7.64509833215111],
              [-35.282817228560226, -7.645026658345712],
              [-35.283013014445693, -7.644805710014007],
              [-35.283153370127629, -7.64455369055897],
              [-35.283183770069321, -7.644341471993877],
              [-35.283158830714129, -7.644098190883688],
              [-35.283031602237095, -7.643871178352152],
              [-35.28288123610411, -7.643722744362528],
              [-35.282644295690183, -7.643598359344368],
              [-35.282178680034704, -7.643404591006908],
              [-35.281988814757192, -7.643256365452415],
              [-35.281750922552568, -7.642935663352088],
              [-35.281592334661063, -7.642740180301664],
              [-35.281418449271023, -7.642615451952622],
              [-35.281260278749549, -7.642498513186836],
              [-35.281181635017717, -7.642514656282559],
              [-35.280930655041168, -7.642426499172053],
              [-35.280756936475925, -7.642333143074695],
              [-35.280653896918977, -7.64220804895758],
              [-35.280621661105158, -7.642074716970555],
              [-35.280675958374495, -7.641909563288638],
              [-35.280762108599731, -7.641822697635302],
              [-35.280856349292399, -7.641775107775118],
              [-35.280950339954586, -7.641680427349009],
              [-35.281036240065767, -7.641546471121089],
              [-35.281279485118674, -7.641372364810723],
              [-35.281436362666817, -7.641245897143746],
              [-35.281522679619805, -7.641190485366469],
              [-35.281765699785133, -7.640992879353013],
              [-35.281907564265033, -7.641007856867223],
              [-35.282041463461567, -7.641007149031505],
              [-35.282182778388055, -7.640935719003005],
              [-35.282384551146848, -7.640750442736022],
              [-35.282565323402757, -7.6406709312667],
              [-35.282675118182013, -7.640583940390026],
              [-35.282910089004091, -7.640355012127846],
              [-35.283043121068559, -7.640189442101828],
              [-35.283050376622334, -7.640071538629948],
              [-35.283120429253579, -7.639922029053337],
              [-35.283159504141395, -7.639859003210294],
              [-35.283253994241747, -7.639858503507483],
              [-35.283324296979266, -7.639756084405086],
              [-35.283378550818675, -7.639582976898573],
              [-35.283433173623422, -7.639496368117933],
              [-35.283502307854668, -7.639174042835227],
              [-35.283462656142603, -7.639111433344722],
              [-35.283390159641534, -7.638817967520453],
              [-35.283413255053034, -7.638731426060778],
              [-35.283462554566917, -7.638052085348887],
              [-35.28348773369737, -7.637914382690885],
              [-35.283510495439742, -7.637765032852412],
              [-35.283533264711387, -7.637600045968913],
              [-35.283650481752289, -7.637426695980186],
              [-35.283744262078663, -7.637292698012762],
              [-35.283797066205317, -7.637170757461647],
              [-35.283765070744749, -7.637014466208075],
              [-35.283748306313605, -7.636826097298055],
              [-35.283778956682184, -7.636660969339037],
              [-35.283833412107398, -7.636542906729135],
              [-35.283919435914036, -7.636432540891898],
              [-35.28399774463837, -7.636353580240685],
              [-35.284107788826454, -7.636313770239203],
              [-35.284131308462847, -7.636290054779924],
              [-35.284020638640769, -7.63621209343771],
              [-35.28388619906638, -7.636110757036728],
              [-35.283807139467697, -7.636048355868692],
              [-35.28379075060765, -7.635930667844852],
              [-35.283845504949461, -7.635851831772276],
              [-35.283766403647071, -7.635781567104231],
              [-35.283939423861369, -7.635812197490186],
              [-35.283939785755798, -7.635812105189405],
              [-35.284018010855043, -7.63571741761761],
              [-35.283953213367269, -7.635372299932202],
              [-35.284220627584929, -7.635299299263401],
              [-35.284218510093808, -7.634883619290677],
              [-35.284296014122667, -7.634687331190212],
              [-35.284382753753178, -7.6346947362444],
              [-35.284437959360353, -7.634717945026444],
              [-35.284483967673907, -7.634698178089023],
              [-35.284554619396893, -7.634642849021159],
              [-35.284696474018737, -7.634673463250385],
              [-35.284798677863698, -7.634641558367686],
              [-35.284830370981588, -7.634672755133857],
              [-35.284870029285948, -7.634719637152866],
              [-35.284854516725552, -7.634766901343653],
              [-35.284886161046131, -7.634805962050987],
              [-35.284909847743819, -7.634813709503748],
              [-35.28494896381283, -7.6347585381314],
              [-35.2850355292695, -7.634750216587108],
              [-35.285067097536889, -7.634757913305289],
              [-35.285098616516109, -7.634773383584378],
              [-35.285145739578191, -7.634741769958754],
              [-35.28510595589465, -7.634671297567485],
              [-35.285019014893891, -7.634608938279648],
              [-35.284987071082085, -7.634530560711382],
              [-35.285041700191073, -7.634428224576255],
              [-35.285142819012073, -7.634192140596759],
              [-35.285216647423667, -7.633935050352128],
              [-35.285359233778877, -7.633421439035625],
              [-35.285538577841436, -7.63324432574573],
              [-35.285577269386813, -7.63312634654517],
              [-35.285726761811226, -7.633094101051427],
              [-35.285844436294745, -7.63300715879619],
              [-35.285906814281418, -7.632896917984366],
              [-35.285969859444677, -7.632912221457814],
              [-35.286056682221108, -7.632935353202579],
              [-35.286095415400219, -7.632825237468659],
              [-35.286134071941952, -7.632683658362494],
              [-35.286581625776932, -7.632445741477967],
              [-35.286612859940185, -7.632390620817874],
              [-35.286581124670271, -7.632351470321658],
              [-35.286564784318713, -7.632225918626557],
              [-35.286603767651123, -7.632162893286901],
              [-35.286643092357643, -7.63214704823662],
              [-35.286643624652392, -7.632127883342871],
              [-35.286674038600069, -7.632105939112565],
              [-35.286730471913174, -7.632053396734659],
              [-35.286725683850477, -7.631970717825263],
              [-35.286739278780971, -7.631954737752348],
              [-35.286752161728543, -7.63192411872812],
              [-35.286759972661351, -7.631910790487172],
              [-35.286758762416667, -7.631683111657179],
              [-35.286718269361977, -7.631479141327099],
              [-35.286709269825309, -7.631302933989126],
              [-35.283896793147377, -7.630735430346007],
              [-35.284158714239631, -7.627782891310333],
              [-35.284439547269344, -7.626073892017497],
              [-35.283378408178393, -7.625954128311629],
              [-35.283378136882966, -7.6259542201313],
              [-35.282704981803995, -7.625878232544924],
              [-35.280982022956167, -7.62580434681221],
              [-35.279320515659315, -7.625770352682133],
              [-35.279225733649383, -7.625766513557233],
              [-35.279439673491012, -7.625296174934808],
              [-35.279442656771238, -7.625140963667905],
              [-35.279410957507409, -7.624920132974311],
              [-35.27933524787673, -7.624668531212354],
              [-35.279255728094029, -7.624433038483131],
              [-35.279215010896081, -7.624237111839367],
              [-35.27919240469695, -7.624056817212629],
              [-35.279214870084004, -7.623816810059106],
              [-35.279284217822386, -7.623516891040988],
              [-35.279339890040873, -7.623269116398354],
              [-35.279410064266457, -7.622954197742607],
              [-35.279423409893944, -7.622737016346163],
              [-35.279428908190532, -7.622646147811632],
              [-35.279438533301651, -7.622479151152185],
              [-35.279518397000039, -7.622112118212482],
              [-35.277048977387139, -7.622058955950846],
              [-35.276957864157239, -7.622063502842348],
              [-35.27647794698602, -7.622038369198637],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0256000',
        uf: 'PE',
        nome_proje: 'PA CACIMBAS',
        municipio: 'VITORIA DE SANTO ANTAO',
        area_hecta: '261.1284',
        capacidade: 30.0,
        num_famili: 29.0,
        fase: 4.0,
        data_de_cr: '30/07/2002',
        forma_obte: 'Desapropriação',
        data_obten: '18/04/2001',
        area_calc_: 275.5836,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.251735182330435, -8.055165809389088],
              [-35.251450920231299, -8.056478842495574],
              [-35.251109915024259, -8.058249938247133],
              [-35.249341707743547, -8.057897551755007],
              [-35.249465768662631, -8.057289609067499],
              [-35.247752494305523, -8.056363455257705],
              [-35.247235168376044, -8.058063524930967],
              [-35.246903551622069, -8.059307653931809],
              [-35.250641285126392, -8.060714069662934],
              [-35.250970923400672, -8.060774084199842],
              [-35.25162769477641, -8.060116610896111],
              [-35.251914194933455, -8.060380595701201],
              [-35.252452109803791, -8.061462743358145],
              [-35.252580312587888, -8.061772890779796],
              [-35.251417340944151, -8.062699159180594],
              [-35.248863284678762, -8.06419275614196],
              [-35.246431761745285, -8.065263187287764],
              [-35.24683304052585, -8.066104550037064],
              [-35.247286725442265, -8.06702016724431],
              [-35.24642680083624, -8.067271464605954],
              [-35.246084556252498, -8.069378217430874],
              [-35.246070267100656, -8.069467599212794],
              [-35.246058315966209, -8.069553361683845],
              [-35.245069833086959, -8.0697974090873],
              [-35.245335490695027, -8.0707695217991],
              [-35.246107291679259, -8.07086688290031],
              [-35.248523169733652, -8.070907460951581],
              [-35.250796348708008, -8.070787584792475],
              [-35.252102051866075, -8.071132949857187],
              [-35.252743042935514, -8.071314933501576],
              [-35.252780398079551, -8.071674723688083],
              [-35.253411371290049, -8.071970080028295],
              [-35.255140869701499, -8.072667138356159],
              [-35.255367621791329, -8.072757315462894],
              [-35.255642058040159, -8.074217937024853],
              [-35.254483016921412, -8.074991255038196],
              [-35.255128633148104, -8.075872942049051],
              [-35.254795525324504, -8.076485861199391],
              [-35.25647792215581, -8.076167529801255],
              [-35.256850287994183, -8.076163531918494],
              [-35.257309951668439, -8.07614091116011],
              [-35.260865820734054, -8.076105868428037],
              [-35.261943399408608, -8.075968469501674],
              [-35.263412227533159, -8.075603288085768],
              [-35.263497469275194, -8.074732236260122],
              [-35.263768541896141, -8.074665612437219],
              [-35.263830098803545, -8.07393211614103],
              [-35.263832870490106, -8.073838694931746],
              [-35.263968789644764, -8.072620638573149],
              [-35.263877459688857, -8.072616895712617],
              [-35.2608283648579, -8.072580281536981],
              [-35.26095609966994, -8.071093963847774],
              [-35.260970848920394, -8.070993353795492],
              [-35.261137075740606, -8.069050739203849],
              [-35.261237995696348, -8.068083310667506],
              [-35.261255093822207, -8.067989213617848],
              [-35.261261242176943, -8.067896153229359],
              [-35.26131473518884, -8.067268788063094],
              [-35.262475966761237, -8.067406034089656],
              [-35.262555320240033, -8.067418819203413],
              [-35.262932710412677, -8.065419722476079],
              [-35.262998181846925, -8.065415139617919],
              [-35.264887278047965, -8.065027113992281],
              [-35.264429027090202, -8.063210258145828],
              [-35.264287784041116, -8.06232490320923],
              [-35.26400034181615, -8.060802232828291],
              [-35.262824636116086, -8.060566733973483],
              [-35.261794371959347, -8.060351154382344],
              [-35.260797005179455, -8.060190137918379],
              [-35.260438940238572, -8.060132367280627],
              [-35.258339099034629, -8.059699420009814],
              [-35.256570124978175, -8.059360024943533],
              [-35.255978755223516, -8.059212903055274],
              [-35.254481209983872, -8.058857415140373],
              [-35.252917050080143, -8.058581484860413],
              [-35.253256950204054, -8.057566063587378],
              [-35.253297341421266, -8.057480488508855],
              [-35.254171137478878, -8.055634272175798],
              [-35.252806857260126, -8.055369242597791],
              [-35.251735182330435, -8.055165809389088],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0076000',
        uf: 'PE',
        nome_proje: 'PA SOUZA, ELDORADO, MANGUEIRA E PARNAZO',
        municipio: 'AGUA PRETA',
        area_hecta: '2399.6904',
        capacidade: 292.0,
        num_famili: 291.0,
        fase: 5.0,
        data_de_cr: '11/07/1996',
        forma_obte: 'Desapropriação',
        data_obten: '04/09/1995',
        area_calc_: 2462.0317,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.56125841215291, -8.730985132798388],
              [-35.559068579553134, -8.732179920498401],
              [-35.558539699653181, -8.732931998298405],
              [-35.555094250653518, -8.734094148398409],
              [-35.553363788453687, -8.735165466998419],
              [-35.55174695885384, -8.736244821298431],
              [-35.551653330153854, -8.736359929998436],
              [-35.550639990153954, -8.737027204698425],
              [-35.550155698854006, -8.737831813298422],
              [-35.550069265354011, -8.737920455398436],
              [-35.549979384454019, -8.738152952498433],
              [-35.54999795425401, -8.738280514898431],
              [-35.550001086154012, -8.738524127198431],
              [-35.549964651254022, -8.739023380798439],
              [-35.549874223354038, -8.739392375098435],
              [-35.549782497754045, -8.739570580798445],
              [-35.549715320454048, -8.739670834698435],
              [-35.549590812454056, -8.73980082789843],
              [-35.549465378154068, -8.740011549298439],
              [-35.549186587354107, -8.740557198398436],
              [-35.549082080254117, -8.740374442798432],
              [-35.54891053445413, -8.740203879698441],
              [-35.548684497154149, -8.739829676598443],
              [-35.548623757954154, -8.73990053359843],
              [-35.548158224854198, -8.74021040959844],
              [-35.547908449054219, -8.740358857398446],
              [-35.547695232354251, -8.740460093598436],
              [-35.547434651454275, -8.740538165398442],
              [-35.547217028954293, -8.740642366398435],
              [-35.546840898754333, -8.740866514898441],
              [-35.546258058754375, -8.74106416719845],
              [-35.545892671854418, -8.741132669098446],
              [-35.544499287854556, -8.741451713798455],
              [-35.543816255054608, -8.741741421498459],
              [-35.539838998454982, -8.739873853498471],
              [-35.536386260955304, -8.73826794399849],
              [-35.533417265055569, -8.736869933598497],
              [-35.532746026955635, -8.73656520309849],
              [-35.531586978555737, -8.7360073056985],
              [-35.530892821855801, -8.735673813498506],
              [-35.528291518356042, -8.735623301298514],
              [-35.527705539456086, -8.735603956298512],
              [-35.527280386056134, -8.734718100498515],
              [-35.527287847056122, -8.733763995698517],
              [-35.526415830556211, -8.733454304798515],
              [-35.525214025856314, -8.734232849298531],
              [-35.523954451256436, -8.735058284798534],
              [-35.521538069256657, -8.736597848398539],
              [-35.520761226356726, -8.737299392898548],
              [-35.518817288256912, -8.739064575798549],
              [-35.518222401756972, -8.739600241098556],
              [-35.51504318095725, -8.738482521998568],
              [-35.513615580057376, -8.73798165459856],
              [-35.51291187265744, -8.737729894598573],
              [-35.511016114857604, -8.737081280098568],
              [-35.509497464457738, -8.736558371398583],
              [-35.507234745757934, -8.735788977998595],
              [-35.504150898258203, -8.734718912998602],
              [-35.503038913358296, -8.736101429898607],
              [-35.501539042758438, -8.738014452598614],
              [-35.500570136358533, -8.739270321098617],
              [-35.498730835658698, -8.741599138498618],
              [-35.497483248858799, -8.743196865998621],
              [-35.496508071158893, -8.744841819398628],
              [-35.495828730558962, -8.745975100998635],
              [-35.493419846759174, -8.750002128198629],
              [-35.492557994959249, -8.751414413298642],
              [-35.494316753459103, -8.75101000199863],
              [-35.495587665258988, -8.751770286498623],
              [-35.495859627258966, -8.751937560998627],
              [-35.496964584058873, -8.75252106629862],
              [-35.498969136458705, -8.753566233998621],
              [-35.499787636858635, -8.753985695398615],
              [-35.501039273758522, -8.754409832098604],
              [-35.501639427358469, -8.754654750698606],
              [-35.502693419558376, -8.755356730798601],
              [-35.506524236958057, -8.758155222998585],
              [-35.504559700258234, -8.760735057598602],
              [-35.501254082758535, -8.765101185698599],
              [-35.501072818958548, -8.765563709498608],
              [-35.500386083458608, -8.766724708098609],
              [-35.499849142758663, -8.767627027198605],
              [-35.499422097258694, -8.768346351898622],
              [-35.49908383685873, -8.768747064198621],
              [-35.498880903858748, -8.768991011098615],
              [-35.498599123058774, -8.769340440498619],
              [-35.498234123958802, -8.769897199298628],
              [-35.4972080065589, -8.77148279979861],
              [-35.496905567458924, -8.771953667398625],
              [-35.495570271959039, -8.77205866079863],
              [-35.495062829259084, -8.772086527598635],
              [-35.494646877059125, -8.77333435309863],
              [-35.494204607759166, -8.774848840598629],
              [-35.493635141159224, -8.776462216698636],
              [-35.492959634459282, -8.77770295949863],
              [-35.492829822159294, -8.777930080798628],
              [-35.492647193559314, -8.778190233798631],
              [-35.49208055385936, -8.778809917898638],
              [-35.49790899005886, -8.779291763098612],
              [-35.498842782758771, -8.779381109798619],
              [-35.499865201358681, -8.779316510998616],
              [-35.500547172558626, -8.7793270737986],
              [-35.501086614158581, -8.7806810686986],
              [-35.502253329458476, -8.779536990598594],
              [-35.50564001045818, -8.779524559398586],
              [-35.512221257757602, -8.779852845798573],
              [-35.515463932757314, -8.780210842198546],
              [-35.517447743557135, -8.780398722298553],
              [-35.52232000375669, -8.780848846398523],
              [-35.522630850356663, -8.780879448598537],
              [-35.523902653956554, -8.780722612598524],
              [-35.525391401856417, -8.780529123898525],
              [-35.526709847956305, -8.780361282298514],
              [-35.526884621856283, -8.777080002198513],
              [-35.526834791156276, -8.77624758169852],
              [-35.526714350156283, -8.774124078998511],
              [-35.526289095056313, -8.772859882198516],
              [-35.525176596956413, -8.771734780498514],
              [-35.525098204356425, -8.77097231229852],
              [-35.525007335656426, -8.770045261798531],
              [-35.524712058056458, -8.769514272998522],
              [-35.525194094956412, -8.768719145598515],
              [-35.526301898956305, -8.766910469898518],
              [-35.52691632655624, -8.765900768398518],
              [-35.527634488656176, -8.764733249098509],
              [-35.528406209456094, -8.763457270298517],
              [-35.530045823055943, -8.76112464079851],
              [-35.532010671455744, -8.758342344698502],
              [-35.533038545755652, -8.756858502898503],
              [-35.534103661255557, -8.755281394198484],
              [-35.535167565755451, -8.753713091898492],
              [-35.535332509455436, -8.753458075998491],
              [-35.535810803755382, -8.752861574398487],
              [-35.536566848755314, -8.751701339998478],
              [-35.538036824855169, -8.74950307989848],
              [-35.540079940054973, -8.746548043098466],
              [-35.54176316025481, -8.744148489398471],
              [-35.542202900454768, -8.743641489698465],
              [-35.542265559454769, -8.743571935298457],
              [-35.542507960954737, -8.743875018198462],
              [-35.542621657654735, -8.74389039899846],
              [-35.542971453154699, -8.744132418098459],
              [-35.543299584854672, -8.74444282929845],
              [-35.543621128354637, -8.744869230898445],
              [-35.543892377654608, -8.745275422598452],
              [-35.544094215454599, -8.745453122698452],
              [-35.544340726954573, -8.745692164798459],
              [-35.544463364854558, -8.745937910198442],
              [-35.544539096754562, -8.746228001098455],
              [-35.544576075754556, -8.74645964539846],
              [-35.544704575054546, -8.746699480398448],
              [-35.545057918054511, -8.747028799298448],
              [-35.54554448565446, -8.747424734698441],
              [-35.546265088654394, -8.747959991198444],
              [-35.547109707754316, -8.748509087798451],
              [-35.548138248954224, -8.749203710398444],
              [-35.54898448135414, -8.749989085398433],
              [-35.549175062054125, -8.750246111598436],
              [-35.549926609654058, -8.750558063598428],
              [-35.551524398753905, -8.751222418198431],
              [-35.552491187753823, -8.75237828879842],
              [-35.552291146853833, -8.753330687498417],
              [-35.552038799353873, -8.754400852898428],
              [-35.551716362453909, -8.755577163598419],
              [-35.55131439585395, -8.757206053198416],
              [-35.550760243554002, -8.758152036498428],
              [-35.550491138654031, -8.758928781998423],
              [-35.550456977854033, -8.75997692859843],
              [-35.550431116454035, -8.760511334798428],
              [-35.550321236954062, -8.76462522019842],
              [-35.550820197554003, -8.765211119898431],
              [-35.552199859053886, -8.766328968098426],
              [-35.554084238653701, -8.767901306698407],
              [-35.55463007695365, -8.767897613998409],
              [-35.555154338553599, -8.767759041498417],
              [-35.555695489753553, -8.767932966398412],
              [-35.555890798653529, -8.768231781398409],
              [-35.555972932253532, -8.769023763398403],
              [-35.555823583153547, -8.769620645698414],
              [-35.556168743053512, -8.770366082898406],
              [-35.556868609453453, -8.770772288198405],
              [-35.561484963253008, -8.770356463198386],
              [-35.562904823352866, -8.77023088089838],
              [-35.563709074352786, -8.770046365698381],
              [-35.564841056252675, -8.76946335609836],
              [-35.567199158852439, -8.766969933398366],
              [-35.566892710352462, -8.766170679198357],
              [-35.566403788552506, -8.76500868669836],
              [-35.565884258452549, -8.763685458798362],
              [-35.565266181552609, -8.762189715098376],
              [-35.565176098852618, -8.761961372798366],
              [-35.56508032765263, -8.761333867398362],
              [-35.564979633852637, -8.760418735198378],
              [-35.564978623052632, -8.760056231598382],
              [-35.564996779052635, -8.758616340198376],
              [-35.564981867652627, -8.757302157498374],
              [-35.564949730552627, -8.756487827698374],
              [-35.564532592852657, -8.75288022779838],
              [-35.564035045452691, -8.748514391798377],
              [-35.564019530352695, -8.748402955098378],
              [-35.563878689752705, -8.74722391439837],
              [-35.565212656752571, -8.746632215898368],
              [-35.566526057052442, -8.746053862298362],
              [-35.567878057052312, -8.745511934598367],
              [-35.567722534252326, -8.745420527598357],
              [-35.56489596015259, -8.740451133198384],
              [-35.564078865852672, -8.740905768698376],
              [-35.563326534152743, -8.741339417398377],
              [-35.563203185752755, -8.741422441098379],
              [-35.5627158932528, -8.74092086499838],
              [-35.562122797452865, -8.739615727198395],
              [-35.561438392852914, -8.737900260098385],
              [-35.560945816452964, -8.736623791298392],
              [-35.561175171552939, -8.736510698898396],
              [-35.561568418752906, -8.735771275698383],
              [-35.561675070652889, -8.735403640098379],
              [-35.562257927752825, -8.735211839198387],
              [-35.562930557452766, -8.734449245498388],
              [-35.56125841215291, -8.730985132798388],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0013000',
        uf: 'PE',
        nome_proje: 'PA PAU SANTO',
        municipio: 'JOAO ALFREDO',
        area_hecta: '550.0000',
        capacidade: 145.0,
        num_famili: 102.0,
        fase: 3.0,
        data_de_cr: '05/06/1987',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/1986',
        area_calc_: 505.3064,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.556929547441001, -7.881067956636636],
              [-35.556972436825163, -7.8805588647154],
              [-35.556486599869942, -7.880345247389985],
              [-35.556177332585172, -7.88020926540575],
              [-35.555546857707846, -7.879821087137335],
              [-35.555496700788289, -7.879698034207977],
              [-35.555421928280261, -7.87951533810052],
              [-35.555502869429645, -7.878607037644247],
              [-35.555624166175896, -7.87807622045935],
              [-35.555741190015084, -7.877514902600364],
              [-35.556254142938812, -7.876978120047654],
              [-35.556347247846098, -7.876975010012385],
              [-35.556545156351753, -7.876999591126546],
              [-35.557097382465287, -7.877172805268945],
              [-35.55726931036623, -7.877261018126403],
              [-35.557421870570003, -7.877366813502417],
              [-35.557599115528397, -7.877504545490646],
              [-35.557712320357794, -7.877632591647359],
              [-35.557812524594979, -7.877775575491669],
              [-35.558063484970035, -7.878047650879988],
              [-35.558152040084245, -7.878196527160842],
              [-35.55838001717224, -7.878214539856568],
              [-35.558758759680025, -7.878244464392443],
              [-35.559469254479957, -7.878300599795781],
              [-35.560077385900797, -7.878331063389107],
              [-35.559812896137061, -7.87790613900596],
              [-35.559761642587723, -7.877731461158315],
              [-35.559538275540881, -7.877618769116726],
              [-35.559497111926397, -7.877554961430095],
              [-35.559487690996505, -7.877042502856926],
              [-35.559483379861945, -7.876807991328794],
              [-35.559380568419321, -7.876452236817596],
              [-35.559334088938066, -7.876291405311999],
              [-35.559023830583605, -7.875815763260841],
              [-35.558854195867518, -7.875555703773555],
              [-35.558829361922704, -7.875301635156349],
              [-35.558769894906504, -7.875066072656865],
              [-35.558442217511072, -7.874839791818468],
              [-35.558328199677369, -7.874408728797109],
              [-35.55827626179299, -7.874212368650122],
              [-35.557993066177822, -7.873714670509485],
              [-35.557696787500568, -7.873231707436519],
              [-35.557696211851166, -7.87292773102098],
              [-35.557316778506333, -7.872552805760111],
              [-35.557192302754046, -7.872477160037007],
              [-35.557319273073915, -7.872134020960376],
              [-35.557500211853693, -7.872043763735364],
              [-35.557590388128773, -7.871784474955572],
              [-35.557488725358688, -7.871606249574742],
              [-35.557412312147868, -7.871401075138139],
              [-35.557408701210342, -7.871130685927514],
              [-35.557301223399115, -7.870842531428271],
              [-35.557248993969388, -7.870583010894754],
              [-35.557296147576402, -7.870427754306928],
              [-35.557314997237405, -7.87022742749294],
              [-35.557304016090825, -7.870230314000151],
              [-35.557171437685462, -7.870232838156457],
              [-35.556863248770547, -7.869988344881722],
              [-35.556761415253639, -7.869833447009068],
              [-35.556732028389376, -7.869755219281352],
              [-35.556760730512899, -7.86965441829935],
              [-35.556934418814343, -7.869425284742439],
              [-35.557410041589264, -7.86901755474557],
              [-35.55760681408772, -7.868746840698583],
              [-35.557794484069433, -7.868665106013684],
              [-35.557887967941745, -7.868497069837425],
              [-35.557981460010332, -7.868267285553248],
              [-35.557965575848584, -7.867906412373812],
              [-35.557729962145949, -7.867739280246909],
              [-35.557573289797787, -7.867687402812157],
              [-35.557423282181695, -7.867685867257456],
              [-35.557232272076519, -7.86762529824983],
              [-35.556985762895444, -7.867567005537892],
              [-35.556976577215636, -7.867443216426388],
              [-35.556836292395886, -7.867288578526773],
              [-35.556708004553961, -7.867205895856174],
              [-35.556468961692943, -7.867191640921988],
              [-35.556300314347027, -7.867058776612494],
              [-35.556081186323873, -7.866807179072541],
              [-35.555986488955241, -7.866698449664568],
              [-35.555849043935623, -7.866664912151175],
              [-35.555620113210665, -7.866647307052437],
              [-35.555463875103193, -7.866411072904643],
              [-35.555192267633721, -7.866129929746067],
              [-35.554746175331601, -7.865877311814841],
              [-35.554532559338057, -7.865772510142216],
              [-35.554446777606202, -7.865730424800992],
              [-35.554329356668774, -7.865753403348366],
              [-35.554299147293577, -7.865763343250026],
              [-35.554146768846039, -7.865810600064305],
              [-35.554088395833141, -7.865800234430283],
              [-35.554061750199928, -7.86579818523274],
              [-35.553602680066284, -7.865260130910393],
              [-35.553536446422271, -7.864949434703508],
              [-35.55328627108895, -7.864873118024293],
              [-35.553008644465791, -7.864779515424954],
              [-35.552523744073959, -7.864565210857467],
              [-35.552185905932205, -7.864342838945941],
              [-35.552077308561721, -7.864254356518602],
              [-35.552011268836161, -7.864169387199117],
              [-35.552011711117942, -7.864068719827292],
              [-35.552000764192442, -7.863840396918432],
              [-35.552092369246623, -7.863536243477702],
              [-35.55203776514152, -7.863207791033284],
              [-35.552074203971095, -7.862878932540565],
              [-35.552132620732813, -7.862541663918347],
              [-35.551894422513314, -7.86240475296803],
              [-35.551818614648255, -7.862311505811503],
              [-35.551534408213811, -7.862173910839792],
              [-35.551342955383667, -7.861954283644422],
              [-35.551157222289476, -7.861865903751147],
              [-35.550906294774805, -7.86185876587373],
              [-35.550540579494665, -7.861711462840096],
              [-35.550273333633903, -7.861888229825939],
              [-35.549847818298389, -7.862081897953125],
              [-35.549450486381353, -7.862109553688772],
              [-35.54893618579996, -7.862155602797991],
              [-35.548894363012032, -7.862131881820947],
              [-35.548524702130329, -7.861829774190248],
              [-35.54790765896405, -7.861835937903218],
              [-35.547762596762041, -7.861606845740554],
              [-35.547272395576968, -7.861398525883212],
              [-35.547041377691407, -7.861231630150519],
              [-35.546741545319748, -7.860966515668603],
              [-35.546703423916789, -7.860941525427561],
              [-35.546494457800463, -7.861000243385776],
              [-35.546256459295229, -7.860879231876399],
              [-35.545915276391447, -7.860674265549213],
              [-35.545610503406166, -7.860480243968555],
              [-35.545498520792449, -7.860016063161874],
              [-35.545483286914518, -7.859882286980964],
              [-35.54525835865482, -7.859500717626807],
              [-35.544881902922924, -7.859294402688356],
              [-35.544610647164099, -7.859405926242828],
              [-35.544553655853129, -7.859194066015259],
              [-35.544076763159161, -7.859081499827986],
              [-35.543960913865938, -7.859206534551331],
              [-35.543858762415219, -7.859302459835522],
              [-35.543589225377765, -7.859481260951973],
              [-35.543508762979776, -7.859445577669703],
              [-35.543283275333792, -7.859377668041879],
              [-35.543182356613023, -7.859268639411193],
              [-35.542763218920236, -7.859120197439618],
              [-35.542433440501732, -7.858747040275935],
              [-35.542189659374486, -7.858686394761932],
              [-35.541906468900557, -7.858629122334676],
              [-35.54156369634331, -7.858780803891885],
              [-35.541523978407099, -7.858741095434954],
              [-35.541505191554307, -7.858412097825039],
              [-35.541479344949828, -7.858358571290007],
              [-35.541418343745796, -7.858340272341176],
              [-35.541219971845486, -7.85834402864841],
              [-35.541001843139888, -7.858457947228793],
              [-35.540844692558842, -7.858433627174305],
              [-35.540643182676469, -7.858535547836973],
              [-35.540524295013157, -7.858672957105071],
              [-35.540288390972734, -7.858814438023194],
              [-35.540222470152599, -7.858913661852821],
              [-35.540101170215657, -7.858973019980801],
              [-35.539711755563317, -7.858896634597939],
              [-35.539514043617167, -7.858996242098821],
              [-35.53951031527415, -7.859035811202506],
              [-35.539500453575272, -7.859098568496076],
              [-35.539608944185808, -7.859476250257624],
              [-35.539520221280526, -7.860092435411316],
              [-35.539443251331363, -7.860316278925352],
              [-35.539423817520401, -7.860709353342474],
              [-35.539409573043066, -7.860747707445126],
              [-35.539331387546888, -7.8609582264713],
              [-35.538734210219232, -7.861335970561294],
              [-35.538493644775272, -7.861622435486492],
              [-35.537855116516958, -7.861622670654768],
              [-35.537495683273676, -7.86182055339148],
              [-35.53717795757246, -7.862059468230377],
              [-35.53691155895666, -7.862313081846795],
              [-35.536633848674555, -7.862628830550843],
              [-35.536457528376964, -7.862874451652467],
              [-35.536161539315536, -7.862939602832506],
              [-35.536038930333909, -7.862938434867858],
              [-35.536038929497259, -7.862938522194996],
              [-35.536059472339709, -7.863119858276112],
              [-35.536254373285146, -7.863407366405576],
              [-35.536569795155387, -7.863961546707249],
              [-35.536633812630214, -7.864064846800325],
              [-35.537274684737078, -7.865098968611315],
              [-35.53765590128009, -7.865707217709876],
              [-35.537796648015622, -7.865975017032335],
              [-35.537940635562649, -7.866351737244575],
              [-35.53817455169284, -7.867372487338534],
              [-35.538224911380411, -7.867579219065817],
              [-35.538320907917452, -7.867836800230108],
              [-35.538498135735281, -7.868414742095644],
              [-35.538797154241138, -7.869482873711711],
              [-35.538833946681137, -7.869588897929567],
              [-35.538924127515727, -7.870059868484788],
              [-35.538945630341885, -7.870129742048169],
              [-35.53902551616482, -7.870417291347856],
              [-35.539110041028714, -7.870805628781238],
              [-35.539271161854849, -7.871545871823351],
              [-35.539387411105224, -7.871991801216361],
              [-35.539486840544164, -7.872495978295569],
              [-35.539505332914679, -7.872773686048554],
              [-35.539579774442551, -7.873342498050013],
              [-35.53959570234025, -7.873497230849752],
              [-35.539611065811293, -7.873781970782862],
              [-35.539638440426181, -7.873804011474903],
              [-35.539670453670404, -7.87414707246378],
              [-35.53995960110543, -7.874844889811742],
              [-35.540042377473917, -7.875069802940453],
              [-35.540104703214823, -7.87542204972539],
              [-35.54014731516569, -7.875614471472271],
              [-35.540270311839031, -7.876246253781806],
              [-35.540395228540405, -7.876688739819267],
              [-35.540446445762001, -7.877329388967929],
              [-35.540506718417731, -7.877483155559189],
              [-35.540513997628658, -7.877890872415724],
              [-35.540515160734486, -7.878172475711974],
              [-35.540513543552841, -7.878254830027251],
              [-35.540523155508019, -7.878986337906383],
              [-35.540408252187966, -7.879753504811753],
              [-35.540333479209842, -7.88034937268923],
              [-35.54051056485573, -7.880617501582654],
              [-35.541020709795582, -7.880871997596404],
              [-35.541214767531478, -7.881128771058388],
              [-35.54128285935726, -7.881341553713248],
              [-35.541310863660946, -7.881455116770113],
              [-35.541541744219181, -7.881954595484217],
              [-35.541956220438678, -7.882744422351834],
              [-35.542074064032469, -7.88298363289404],
              [-35.542532186626481, -7.883479813049759],
              [-35.542820429845271, -7.883792000397787],
              [-35.543120015205766, -7.884103256205443],
              [-35.543334390077646, -7.884448268822961],
              [-35.543483488318913, -7.884531958974496],
              [-35.544022429208901, -7.88501740011521],
              [-35.544791850651663, -7.885766074549905],
              [-35.544874449902146, -7.885645000593944],
              [-35.544891588565633, -7.885555013239295],
              [-35.544909910162808, -7.885369199412431],
              [-35.54491068735198, -7.885207052519581],
              [-35.544950421965609, -7.885051718401231],
              [-35.54502728610597, -7.88492656485196],
              [-35.545359092465276, -7.884660185240729],
              [-35.545494257004087, -7.884490171670002],
              [-35.545761413673389, -7.884499121574592],
              [-35.54586291569656, -7.884609536443207],
              [-35.546082475943571, -7.88464521185121],
              [-35.546431407980151, -7.884576117740016],
              [-35.546620779657758, -7.884592590012296],
              [-35.546826264811109, -7.884640687494687],
              [-35.546879857765695, -7.884726719665213],
              [-35.54711295373243, -7.88466185462048],
              [-35.547413253990158, -7.884594818078602],
              [-35.547681531940846, -7.884642533002944],
              [-35.547865694029134, -7.88467489804241],
              [-35.548044651302199, -7.884700077957724],
              [-35.548239222274255, -7.884740091114419],
              [-35.548580140501386, -7.88481020005854],
              [-35.548752951302205, -7.884845738026329],
              [-35.549094080058538, -7.884946761715823],
              [-35.549836593022299, -7.885274648891446],
              [-35.550072390329632, -7.885399029513684],
              [-35.550589856793387, -7.885578294518433],
              [-35.551135355987455, -7.885665725584945],
              [-35.552056965727367, -7.885813436798031],
              [-35.552956584874217, -7.885800052598733],
              [-35.553956625672207, -7.885710612772597],
              [-35.554553668327124, -7.885657214433811],
              [-35.55549477282122, -7.88553691373236],
              [-35.556390094582454, -7.885580793887097],
              [-35.556595406422332, -7.885600688790084],
              [-35.556761750515399, -7.885612009524088],
              [-35.556956373804958, -7.885619632663719],
              [-35.557139837720449, -7.885603971246773],
              [-35.557143116916286, -7.885603701391344],
              [-35.557125738183451, -7.885564534376656],
              [-35.557065624583601, -7.885264376146193],
              [-35.55700580467829, -7.885012026928224],
              [-35.557000635740131, -7.88472860833255],
              [-35.557033283892203, -7.884463639433775],
              [-35.557059856255819, -7.884247981059084],
              [-35.557085558085376, -7.884164010566429],
              [-35.557099326474699, -7.884114860362553],
              [-35.557111406662415, -7.884071736672967],
              [-35.557144579796876, -7.884037480089762],
              [-35.5571913721258, -7.883990310356327],
              [-35.557052678911838, -7.883502631807269],
              [-35.556956502080624, -7.883164449924485],
              [-35.556861643921017, -7.882627410157644],
              [-35.556853392919002, -7.881971904613697],
              [-35.556929547441001, -7.881067956636636],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0241000',
        uf: 'PE',
        nome_proje: 'PA ÁGUAS CLARAS',
        municipio: 'GRAVATA',
        area_hecta: '99.1092',
        capacidade: 16.0,
        num_famili: 11.0,
        fase: 3.0,
        data_de_cr: '29/11/2001',
        forma_obte: 'Desapropriação',
        data_obten: '28/04/1987',
        area_calc_: 97.9596,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.541045667528813, -8.269789061042694],
              [-35.540867011861231, -8.26977828982003],
              [-35.539133321509524, -8.269616445360636],
              [-35.539098379677924, -8.26963014851391],
              [-35.538967629692202, -8.269626725815199],
              [-35.538653238026107, -8.269690996938071],
              [-35.53848551866519, -8.269699251802486],
              [-35.538303573983164, -8.269716385291966],
              [-35.538074530304648, -8.26972981083426],
              [-35.537908861606276, -8.269723910846331],
              [-35.537717197359633, -8.269722827606062],
              [-35.537510129237404, -8.269703115148475],
              [-35.537269799486779, -8.269658275837276],
              [-35.537033482561462, -8.269646611257739],
              [-35.536854820678862, -8.269639883196271],
              [-35.536807736309058, -8.269626062646424],
              [-35.536701286090803, -8.269667169233369],
              [-35.5365915194701, -8.269755193261267],
              [-35.536497926671046, -8.269890971692385],
              [-35.536477585689468, -8.269916830645505],
              [-35.536397148470527, -8.269940985405084],
              [-35.536298865141994, -8.269950552482015],
              [-35.536216030064402, -8.269948006639419],
              [-35.536166512734297, -8.269931755515977],
              [-35.536048768370968, -8.269920260503268],
              [-35.5359509121485, -8.269915266033799],
              [-35.535834352048404, -8.269927233776723],
              [-35.535719391048033, -8.269956597376186],
              [-35.53543066234316, -8.269964272734221],
              [-35.535085467305557, -8.269987237811444],
              [-35.534944986348336, -8.269973282770813],
              [-35.534668973517697, -8.269892794330316],
              [-35.534582090543822, -8.269881343263719],
              [-35.534500062834624, -8.269882034239224],
              [-35.534319737526673, -8.269901999839259],
              [-35.534067215870259, -8.269863613020366],
              [-35.53379271345969, -8.26986159960423],
              [-35.533477663710407, -8.26981826825851],
              [-35.533454893105812, -8.269839269579668],
              [-35.533449135221275, -8.269889419632181],
              [-35.533757206982088, -8.270823455975286],
              [-35.533783798830008, -8.270967497241418],
              [-35.533820975620706, -8.271092946614123],
              [-35.533830622782709, -8.271160916958815],
              [-35.533832912504344, -8.2712620459319],
              [-35.533842515082455, -8.271360758414987],
              [-35.533840690101464, -8.271499095711249],
              [-35.533621415439391, -8.272614803127565],
              [-35.53355756671273, -8.272960156595925],
              [-35.533514205937585, -8.273178525693078],
              [-35.533457224684028, -8.273548563741828],
              [-35.532984277870455, -8.274016701866707],
              [-35.532788217965411, -8.273964643182365],
              [-35.532582004992676, -8.273913378764423],
              [-35.53246506782159, -8.273904311205364],
              [-35.532354570293307, -8.273934894197087],
              [-35.532092860490039, -8.274069620981077],
              [-35.53188600029263, -8.27418420159422],
              [-35.531696580108118, -8.274313369415145],
              [-35.531753979663812, -8.27449466969162],
              [-35.53183251297169, -8.274663865320473],
              [-35.531880998953703, -8.274831399578789],
              [-35.531925500612907, -8.274946342654566],
              [-35.532003902525901, -8.275206146723919],
              [-35.532096167641171, -8.275427138413516],
              [-35.532191743761601, -8.275605257485504],
              [-35.532480629869269, -8.275772328747838],
              [-35.532556044315228, -8.275852528901153],
              [-35.532610325096769, -8.275944833707483],
              [-35.532668589269335, -8.276090538622862],
              [-35.532705751463766, -8.276226505087177],
              [-35.532722655479738, -8.276330891168172],
              [-35.532723332762259, -8.276423927821709],
              [-35.532685776435301, -8.276559786792539],
              [-35.532607480782765, -8.276786195832083],
              [-35.532530868037213, -8.276972157001678],
              [-35.532416846610076, -8.277192042615058],
              [-35.532274418226528, -8.277398943262128],
              [-35.532196317041951, -8.27749105758612],
              [-35.532136103315601, -8.277569444540699],
              [-35.532075099700165, -8.277632459237422],
              [-35.531986083534122, -8.277690579574427],
              [-35.53189337211905, -8.277776605299623],
              [-35.531815388741592, -8.277787414621786],
              [-35.531630211722664, -8.277789979505185],
              [-35.53161232537208, -8.277802897850492],
              [-35.531594365043205, -8.277866783501404],
              [-35.531594679744401, -8.278069642345853],
              [-35.531581203919473, -8.278121399359266],
              [-35.53156246782055, -8.278160204693357],
              [-35.531528745453542, -8.278172291229264],
              [-35.53131109285497, -8.278167123686101],
              [-35.530820699785934, -8.278060841479457],
              [-35.530774754963105, -8.278100416571109],
              [-35.530637152481638, -8.278338875219745],
              [-35.530484112862233, -8.278581356637948],
              [-35.530423899793924, -8.278658934476564],
              [-35.530294444500022, -8.278879606713343],
              [-35.530847037593702, -8.27921856844875],
              [-35.531141951862018, -8.279710869432471],
              [-35.531209728959034, -8.280017580563204],
              [-35.531254114136701, -8.280213424068645],
              [-35.532168684101194, -8.281288290379379],
              [-35.532291115551551, -8.281430851240925],
              [-35.533151828592445, -8.281560717908269],
              [-35.533233843611143, -8.281570543528748],
              [-35.533375913548184, -8.281612006393534],
              [-35.53351715147717, -8.28166722109548],
              [-35.533631991611934, -8.281724015941737],
              [-35.533830035888514, -8.281810863052314],
              [-35.533944488092075, -8.281855117649979],
              [-35.534163271350238, -8.281922173616499],
              [-35.53423381865948, -8.281999534492456],
              [-35.534355354844791, -8.282200341480266],
              [-35.534614545214971, -8.28268935074599],
              [-35.534793676108308, -8.282938780018849],
              [-35.534896586582533, -8.283102345861167],
              [-35.534978450543733, -8.283217341350063],
              [-35.535046585621309, -8.283278113929518],
              [-35.535178107303828, -8.283313088816573],
              [-35.535252828207092, -8.283312386433801],
              [-35.535315373520064, -8.283306812642392],
              [-35.535438890962581, -8.283260066613169],
              [-35.535599911573598, -8.282831119704655],
              [-35.535738717097985, -8.282318813750157],
              [-35.536016821972744, -8.281797807478767],
              [-35.536280580830123, -8.281369007169507],
              [-35.536405434332828, -8.281239744608049],
              [-35.536564055384503, -8.281066843978273],
              [-35.536609779525072, -8.280897827448836],
              [-35.536808140131484, -8.280198726832827],
              [-35.537221801835223, -8.279728071976352],
              [-35.53735421258925, -8.279425693499702],
              [-35.537445531244877, -8.279177459693878],
              [-35.537617670647485, -8.278638909016454],
              [-35.537764281949272, -8.278061894399508],
              [-35.53817823506273, -8.277386157811227],
              [-35.538470264744568, -8.27692068935411],
              [-35.538649936911817, -8.276649929361342],
              [-35.538830559683802, -8.276282494957636],
              [-35.53911355992642, -8.275741270821724],
              [-35.540113194673431, -8.273845583743459],
              [-35.540155538845838, -8.273767170814603],
              [-35.540180006469292, -8.27369520447982],
              [-35.54035236112145, -8.273286903853618],
              [-35.540556768649033, -8.272897255845724],
              [-35.540817299726136, -8.272164266670144],
              [-35.541085047468094, -8.271212048602148],
              [-35.541058518258936, -8.270172444664416],
              [-35.541045667528813, -8.269789061042694],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0261000',
        uf: 'PE',
        nome_proje: 'PA BAETÉ',
        municipio: 'BARREIROS',
        area_hecta: '358.5266',
        capacidade: 61.0,
        num_famili: 55.0,
        fase: 5.0,
        data_de_cr: '16/09/2002',
        forma_obte: 'Desapropriação',
        data_obten: '16/09/2002',
        area_calc_: 358.4316,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.185812423437604, -8.789733404144737],
              [-35.18534981352591, -8.789844030549427],
              [-35.185450852391803, -8.79002946542337],
              [-35.185934428308947, -8.790917345732241],
              [-35.186039306474186, -8.791115873490334],
              [-35.186142173351485, -8.79133398211569],
              [-35.186313153420087, -8.79157845052929],
              [-35.186632461346505, -8.792039488791817],
              [-35.186905134321442, -8.792577365978765],
              [-35.187390831189198, -8.792188864397072],
              [-35.187863788848965, -8.792812911232256],
              [-35.188214454904291, -8.793285859302649],
              [-35.188496023973734, -8.793574109771955],
              [-35.188868551308218, -8.793894994959309],
              [-35.1889714147731, -8.793934186292216],
              [-35.189736998708533, -8.794407504141354],
              [-35.190440437605837, -8.795345482071243],
              [-35.190532949617229, -8.79497472005842],
              [-35.190758337090436, -8.795143997084642],
              [-35.19098159089009, -8.795309739282471],
              [-35.19152172237974, -8.796005563597429],
              [-35.191696476591673, -8.796512142770254],
              [-35.191460441439318, -8.796666581230623],
              [-35.190724747048613, -8.795589260867983],
              [-35.19056771233803, -8.795535272335336],
              [-35.190234533360069, -8.795771905383745],
              [-35.190390306150924, -8.796047073948634],
              [-35.190542074914383, -8.796699792898851],
              [-35.190491017082635, -8.797631583482834],
              [-35.190420916914718, -8.798942422156991],
              [-35.19038024386154, -8.799639032801336],
              [-35.190478542978639, -8.799655815339268],
              [-35.190984802824786, -8.799788849326388],
              [-35.191235285721689, -8.800758203032807],
              [-35.191653610781373, -8.800436721527916],
              [-35.192106937726216, -8.800096804426154],
              [-35.192842662562761, -8.799530282139857],
              [-35.192692364871363, -8.799329102858884],
              [-35.193036954808321, -8.799031867459609],
              [-35.193202883575424, -8.799271949197824],
              [-35.193357625046495, -8.799164596655229],
              [-35.194935910935563, -8.798111117818936],
              [-35.195285703966874, -8.797881651185889],
              [-35.196288182006306, -8.797551986434193],
              [-35.198016887382742, -8.797260517134983],
              [-35.200035396705502, -8.796042782921699],
              [-35.200308433054637, -8.795875478452954],
              [-35.20074782088804, -8.795135273146974],
              [-35.200622020192249, -8.794791927553389],
              [-35.200405235308473, -8.794818969417729],
              [-35.200254681107893, -8.7948376195195],
              [-35.200207888279202, -8.794524209323958],
              [-35.199761300438141, -8.794545483648077],
              [-35.199813961048697, -8.794201392984196],
              [-35.200113452313154, -8.79413188823086],
              [-35.199937772146768, -8.793651542464231],
              [-35.200348236245624, -8.793628111073174],
              [-35.20044923743955, -8.792851550752324],
              [-35.19965199135099, -8.792524400841064],
              [-35.199215211671635, -8.792129963416887],
              [-35.19910532787592, -8.791363531323492],
              [-35.199791126137171, -8.790455706465108],
              [-35.1998862580975, -8.790193386276483],
              [-35.199319660081422, -8.789627544919956],
              [-35.199474070315368, -8.788941382971602],
              [-35.200257463010942, -8.789030880352675],
              [-35.201429511851359, -8.789964456883204],
              [-35.202193178442634, -8.790340799369838],
              [-35.203355992136686, -8.789806404622807],
              [-35.203493969473854, -8.789454372592749],
              [-35.203892218527912, -8.789243856112185],
              [-35.204242911660245, -8.789241604074814],
              [-35.205669024288262, -8.78865550206682],
              [-35.206301580293683, -8.788447356739628],
              [-35.207203003232209, -8.788011469467939],
              [-35.20801194092396, -8.788571538559792],
              [-35.208970140779464, -8.787693343965875],
              [-35.209313306994879, -8.786742838599766],
              [-35.209087990017657, -8.786530536764626],
              [-35.207784997467613, -8.785283090518169],
              [-35.207126482815859, -8.785654030523245],
              [-35.206976098437494, -8.785735925818488],
              [-35.206099893743819, -8.78591821573974],
              [-35.205114374766133, -8.785823109697274],
              [-35.204171518450899, -8.785634633618438],
              [-35.204048577517113, -8.78561566599249],
              [-35.203079670990284, -8.785517926286207],
              [-35.202042735776104, -8.785621930251002],
              [-35.201879569109423, -8.785641358812633],
              [-35.201183977142463, -8.785778934168199],
              [-35.198871029344673, -8.786258206148652],
              [-35.198201909125601, -8.786464751187827],
              [-35.197546540521046, -8.786747692743871],
              [-35.195793167692955, -8.787689113916125],
              [-35.194834662246315, -8.788228361449351],
              [-35.193760495131102, -8.788800549380289],
              [-35.192633375029423, -8.789162220378463],
              [-35.19183076067084, -8.789440940149522],
              [-35.19058389287823, -8.789452982286418],
              [-35.189971235539389, -8.789356971415664],
              [-35.188876222552928, -8.789244075290707],
              [-35.187820651536768, -8.789229868590773],
              [-35.187137592743596, -8.789333804600862],
              [-35.186828548824117, -8.789398130694513],
              [-35.186380107513862, -8.789560467876042],
              [-35.185812423437604, -8.789733404144737],
            ],
          ],
          [
            [
              [-35.184615393129498, -8.788458943846793],
              [-35.185159516601679, -8.789532812466481],
              [-35.185630531570496, -8.789381932515065],
              [-35.186274583509302, -8.789214528225695],
              [-35.186765247401894, -8.789067609584666],
              [-35.187057375201185, -8.788980306515052],
              [-35.187795447960468, -8.788867916350092],
              [-35.188900997782071, -8.788882827314627],
              [-35.19060439269245, -8.789091217111318],
              [-35.191771756929136, -8.789079908850056],
              [-35.191967617237395, -8.788996678335534],
              [-35.193148177569896, -8.788616828392545],
              [-35.19361488936255, -8.78846773614457],
              [-35.195345668504558, -8.787534399751106],
              [-35.19682174922167, -8.78671879987137],
              [-35.197385434340958, -8.786423052660767],
              [-35.197431343114673, -8.786388328895548],
              [-35.198079412794101, -8.7861235260161],
              [-35.198128579819219, -8.786094351109679],
              [-35.198484456976708, -8.785995803864237],
              [-35.198840491977542, -8.785908662419375],
              [-35.199223608183779, -8.785816056864268],
              [-35.200076073445864, -8.785637735169461],
              [-35.200636986107455, -8.785521330718915],
              [-35.201399243424184, -8.785366645264741],
              [-35.201824574052793, -8.78528347139124],
              [-35.202244024336437, -8.785235492457264],
              [-35.203082770730184, -8.785162772961499],
              [-35.203629828733277, -8.785210192610709],
              [-35.204076717333024, -8.785254220077153],
              [-35.205166683836616, -8.785464866916877],
              [-35.206079834039805, -8.785553075185613],
              [-35.20629545208196, -8.785503925596123],
              [-35.206848043652336, -8.785393201303735],
              [-35.207009908798682, -8.785301377533932],
              [-35.207531553876883, -8.78501598714624],
              [-35.207970801357035, -8.784604318852551],
              [-35.208692413337396, -8.783753880310204],
              [-35.207193803091457, -8.783347405734464],
              [-35.206192079909556, -8.783112176862591],
              [-35.204505512605643, -8.782656136528049],
              [-35.203077803058669, -8.782290454904404],
              [-35.202098846136067, -8.782035593090363],
              [-35.201376114809086, -8.781847286029407],
              [-35.200438564946332, -8.781604705434011],
              [-35.199802566478773, -8.781441694724554],
              [-35.199140062043732, -8.781262974206665],
              [-35.197498251911533, -8.780846634268977],
              [-35.194987590762665, -8.780235738702652],
              [-35.194793506294779, -8.780193617227896],
              [-35.193546608301823, -8.780984822132732],
              [-35.192030108079464, -8.781996196888363],
              [-35.191571709524098, -8.782243092106128],
              [-35.191464151399899, -8.78231676049054],
              [-35.190643023271079, -8.782838665033406],
              [-35.189780700110482, -8.783383097508333],
              [-35.18794301973719, -8.78449673014857],
              [-35.187794248190968, -8.784643888316653],
              [-35.186754533828513, -8.785576301235242],
              [-35.186694353468091, -8.785644324712678],
              [-35.185958438717812, -8.786305415336205],
              [-35.185296928238152, -8.786817284005684],
              [-35.184730640264867, -8.787313333180329],
              [-35.184145689491402, -8.787575535445947],
              [-35.184615393129498, -8.788458943846793],
            ],
          ],
          [
            [
              [-35.184287643333739, -8.788578899069075],
              [-35.184070206757809, -8.788649307254104],
              [-35.180697032054837, -8.78945483487364],
              [-35.178617650103831, -8.789962307763686],
              [-35.178268508173552, -8.79004171815944],
              [-35.178492195869055, -8.790323760000129],
              [-35.178717400874852, -8.790645583329651],
              [-35.178068599562081, -8.790874648243459],
              [-35.175828557534096, -8.791619749981935],
              [-35.17522933310353, -8.791978298013778],
              [-35.175333827181007, -8.792239463321083],
              [-35.176698094061315, -8.795902541675982],
              [-35.177055191360218, -8.795886647383083],
              [-35.177608643472048, -8.795898722599237],
              [-35.178714105866334, -8.796173619537155],
              [-35.178803572010253, -8.796123604476962],
              [-35.179357753960225, -8.795822436947985],
              [-35.179412088301191, -8.795703666971756],
              [-35.179627073325669, -8.795245910272079],
              [-35.17963393052203, -8.793886141160833],
              [-35.179818289543384, -8.793573863006257],
              [-35.180085307278461, -8.793505067588185],
              [-35.180387588875007, -8.793608440565007],
              [-35.180719073319601, -8.793992556493555],
              [-35.181286642588603, -8.793684937235193],
              [-35.181735501586431, -8.793741894124944],
              [-35.183033388948196, -8.793258853174549],
              [-35.183872969063366, -8.79295858442091],
              [-35.18590960763607, -8.791538253181834],
              [-35.185740521062698, -8.791342375785954],
              [-35.185031810381389, -8.790019374858085],
              [-35.184287643333739, -8.788578899069075],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0284000',
        uf: 'PE',
        nome_proje: 'PA BOA VISTA',
        municipio: 'SAIRE',
        area_hecta: '111.4282',
        capacidade: 16.0,
        num_famili: 10.0,
        fase: 4.0,
        data_de_cr: '10/12/2002',
        forma_obte: 'Desapropriação',
        data_obten: '10/11/2000',
        area_calc_: 111.5231,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.646384601542742, -8.327948701098093],
              [-35.64648617194274, -8.328439807898096],
              [-35.646657370842732, -8.330099509398092],
              [-35.646970065042694, -8.330930103998096],
              [-35.646986762342685, -8.331200444298094],
              [-35.647587991542622, -8.331521823998091],
              [-35.647358357442656, -8.332896668398096],
              [-35.647865644842597, -8.334478943198089],
              [-35.647417390742653, -8.335588771898093],
              [-35.647123809842689, -8.335631213698086],
              [-35.646302996942786, -8.335280487198091],
              [-35.646009629642819, -8.335732354398097],
              [-35.644545680842981, -8.334927600898096],
              [-35.644549591142976, -8.334443056198099],
              [-35.644544013542983, -8.3344231234981],
              [-35.644422637042993, -8.334358149598099],
              [-35.644274170143014, -8.334291121698111],
              [-35.644274032543009, -8.334291026898111],
              [-35.644148689443021, -8.334326956998099],
              [-35.644082012443029, -8.334341588298098],
              [-35.644012743243046, -8.334362019998107],
              [-35.64393825084305, -8.334388269698099],
              [-35.643863683643055, -8.334416869198098],
              [-35.643796519843072, -8.334440088098111],
              [-35.643733605843082, -8.33447466409811],
              [-35.643666208743085, -8.334503667698105],
              [-35.643623439443083, -8.334511723798116],
              [-35.643597071143091, -8.334516688798114],
              [-35.6435628681431, -8.3345303805981],
              [-35.643126444343146, -8.334742837998116],
              [-35.643055142343151, -8.33477132539811],
              [-35.642978899343163, -8.334793610598103],
              [-35.64290697474317, -8.334810445398116],
              [-35.642831584943181, -8.334824682798114],
              [-35.642763498543182, -8.334845648598112],
              [-35.642699496543194, -8.334866858198119],
              [-35.642646229743193, -8.334892424098104],
              [-35.642636734043208, -8.334897005498114],
              [-35.642575121843208, -8.33492280759811],
              [-35.642506722343221, -8.334951185198113],
              [-35.642433059843221, -8.334965953098104],
              [-35.642357275243235, -8.334961939998115],
              [-35.642279711043237, -8.334949625398121],
              [-35.642208633143241, -8.334916936198107],
              [-35.642144610443253, -8.334881037298125],
              [-35.642075820643264, -8.334851314798106],
              [-35.642006604243278, -8.33483912529811],
              [-35.64194915674328, -8.334836616398121],
              [-35.641895604543286, -8.334846732198109],
              [-35.641872591743287, -8.334851493898118],
              [-35.641800176343295, -8.334862819698106],
              [-35.641795722043298, -8.334875048198118],
              [-35.641789032743297, -8.334932743598122],
              [-35.641776473643297, -8.334994544298116],
              [-35.641743858643309, -8.335055575098119],
              [-35.641712208143304, -8.335111629498126],
              [-35.64167575074331, -8.335154432698108],
              [-35.641668906743313, -8.335216104998111],
              [-35.641646224743319, -8.335283665998112],
              [-35.641618369143323, -8.335350899898108],
              [-35.641596079243328, -8.335422795698106],
              [-35.641579433643329, -8.335497545398116],
              [-35.641572683643318, -8.335573223098111],
              [-35.641572452943322, -8.335646959898106],
              [-35.641576950943318, -8.335722291598119],
              [-35.641581446443325, -8.335797261898122],
              [-35.641590842843328, -8.335872470698117],
              [-35.64160375744332, -8.335944674098119],
              [-35.641627809243317, -8.336013550198118],
              [-35.64165982544332, -8.336079481798114],
              [-35.641698149843307, -8.336139045898111],
              [-35.641742317843303, -8.336190619298106],
              [-35.641867102543294, -8.336263161698119],
              [-35.641933296443291, -8.336284588198108],
              [-35.642001122543284, -8.336292359198108],
              [-35.642072311143266, -8.336287457198113],
              [-35.642140128043266, -8.336266854598117],
              [-35.642204532343257, -8.336237961498103],
              [-35.642270011943246, -8.336207073298114],
              [-35.642339648143235, -8.336187181498111],
              [-35.642410532343227, -8.336177492298116],
              [-35.642478901143221, -8.336171524598115],
              [-35.642551077743214, -8.336178633998111],
              [-35.642623511543199, -8.336197036898108],
              [-35.642694262343191, -8.336221505198104],
              [-35.642761135643184, -8.336249523398108],
              [-35.642826014343186, -8.336277735698115],
              [-35.642888315343178, -8.336313916898105],
              [-35.642946305443168, -8.336356632898116],
              [-35.643004308243164, -8.336401246398115],
              [-35.643059958243157, -8.336446779198114],
              [-35.643112064643148, -8.336491522298115],
              [-35.643156806343143, -8.336547428998113],
              [-35.643204541343145, -8.336603225398109],
              [-35.643255242943134, -8.33665493569811],
              [-35.643307030843133, -8.336706277298099],
              [-35.643362957343122, -8.336752440698108],
              [-35.64342113624312, -8.33679623969811],
              [-35.643482164843107, -8.336832067898104],
              [-35.643550324843098, -8.336862517098114],
              [-35.6436211331431, -8.336882014898112],
              [-35.643692370043084, -8.336897804998102],
              [-35.643764773043081, -8.336911599398098],
              [-35.643837695543063, -8.336921685498101],
              [-35.643905205543057, -8.336936416098096],
              [-35.643972856043057, -8.336958555298109],
              [-35.644038737043047, -8.336987393198102],
              [-35.644082720343043, -8.337038425198104],
              [-35.644111822243033, -8.337102658998107],
              [-35.644123799243033, -8.337170259898102],
              [-35.644119371543034, -8.337240409998108],
              [-35.644094971743044, -8.337308795798103],
              [-35.644086835943043, -8.337380687498099],
              [-35.644080078043039, -8.337455100098106],
              [-35.64409728354304, -8.337517696298109],
              [-35.644137794543035, -8.337565046698106],
              [-35.644141444143038, -8.337568094698105],
              [-35.644140723643034, -8.337570886898108],
              [-35.644141464143033, -8.337571076498104],
              [-35.644187781343028, -8.337591369998094],
              [-35.644259527443026, -8.337601915598102],
              [-35.644328769443021, -8.337604345298113],
              [-35.644400557742998, -8.337607661698099],
              [-35.644467771343002, -8.33761877969811],
              [-35.64453533754299, -8.33762835909811],
              [-35.644600069442987, -8.337634704398095],
              [-35.644663682042982, -8.337650002998098],
              [-35.64471967754298, -8.337665894598096],
              [-35.644776024942971, -8.337680157198104],
              [-35.644837410342959, -8.337688060998103],
              [-35.644907213042949, -8.337692926698098],
              [-35.644973300242938, -8.337698449598092],
              [-35.645041434542939, -8.337711549298101],
              [-35.645101021842926, -8.337721904798098],
              [-35.645159823642921, -8.337736873898104],
              [-35.645208794942917, -8.337760583298099],
              [-35.645232397442918, -8.337789522198092],
              [-35.645236626642912, -8.3377978072981],
              [-35.64467563904298, -8.338402634898104],
              [-35.644683669342982, -8.338611858598092],
              [-35.644146079443033, -8.338959089798109],
              [-35.644823627842975, -8.339928395898108],
              [-35.646054977242819, -8.339777135198094],
              [-35.646891362642734, -8.340148357698098],
              [-35.64778613334262, -8.339529460398095],
              [-35.648015725842598, -8.339617655498076],
              [-35.648129772442587, -8.339429213098079],
              [-35.648920800642493, -8.339532180398086],
              [-35.649523392942427, -8.339945802298081],
              [-35.650654959342305, -8.341843410098081],
              [-35.652745849442063, -8.340895989798055],
              [-35.651638043342182, -8.339216789398076],
              [-35.652452361942082, -8.338543364598072],
              [-35.652184829842113, -8.337834013398064],
              [-35.650946646642261, -8.33822696349808],
              [-35.650930916442263, -8.338204530098078],
              [-35.651001749642255, -8.337931617798077],
              [-35.651536319442194, -8.335872870798076],
              [-35.651990488842124, -8.336355614598061],
              [-35.65276977764205, -8.337100126798068],
              [-35.653131072941996, -8.336860782898066],
              [-35.653614143341947, -8.337538690798059],
              [-35.654168360741878, -8.336713148798063],
              [-35.655300281141749, -8.335639041598057],
              [-35.65573494844169, -8.33554414359805],
              [-35.655229690541745, -8.334052145998053],
              [-35.65514826704176, -8.333830855298059],
              [-35.655842161241679, -8.333793137598047],
              [-35.655903309341667, -8.332032048598059],
              [-35.65533520704173, -8.33044893479806],
              [-35.655227100541737, -8.330481555598054],
              [-35.655070716341747, -8.33055281239805],
              [-35.654835749541782, -8.330662365798055],
              [-35.65458827824181, -8.330774261798048],
              [-35.654319980141842, -8.330851779198058],
              [-35.65406280254188, -8.330856300698054],
              [-35.653846162941896, -8.330844918598068],
              [-35.653642190841929, -8.330828481698065],
              [-35.653414802741949, -8.330797291698058],
              [-35.653130690241987, -8.330763047198062],
              [-35.652821036242017, -8.330789063298072],
              [-35.65255740924205, -8.330819379898067],
              [-35.652348991042068, -8.330789417898071],
              [-35.652214202542091, -8.330686222998064],
              [-35.652090907242098, -8.330538312798071],
              [-35.651956865942111, -8.330384420198074],
              [-35.651806997242133, -8.330237862198066],
              [-35.651672842842139, -8.330134482098067],
              [-35.651478134442172, -8.330052108798078],
              [-35.65126747664219, -8.329985926198074],
              [-35.651052031242216, -8.329922847798077],
              [-35.650888026442239, -8.329873070198067],
              [-35.650883753942232, -8.329871833598075],
              [-35.650837207042237, -8.329857686798071],
              [-35.650615064142265, -8.32979700219807],
              [-35.650408347042294, -8.329736666298075],
              [-35.65021500744232, -8.329682385398071],
              [-35.650024093742331, -8.329624564198076],
              [-35.649873277742351, -8.329579758198079],
              [-35.649675419942376, -8.329501199998081],
              [-35.649505769542394, -8.329407905198075],
              [-35.649363503142411, -8.329325813098079],
              [-35.649235109942424, -8.329242543898088],
              [-35.649061456442446, -8.329147558698082],
              [-35.648915445842462, -8.329102268298078],
              [-35.648770282142479, -8.329061490298084],
              [-35.648640076842497, -8.329005522098081],
              [-35.648525069442513, -8.328956500598087],
              [-35.648399432442524, -8.328905290898083],
              [-35.648266538842535, -8.328867774098089],
              [-35.648155712642549, -8.328819899298082],
              [-35.648054523042561, -8.328774941998084],
              [-35.647934307742574, -8.328706979098083],
              [-35.647798023942592, -8.328624032998091],
              [-35.647605339942615, -8.328491764398088],
              [-35.647432822542626, -8.328309300898093],
              [-35.647312440542642, -8.328162543698094],
              [-35.647181859942663, -8.328104679698095],
              [-35.647013797042682, -8.328058451198087],
              [-35.646828131542705, -8.328065924398093],
              [-35.646666960542717, -8.328059679798097],
              [-35.646547800442733, -8.328013667198102],
              [-35.646384601542742, -8.327948701098093],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0072000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO UBU',
        municipio: 'GOIANA',
        area_hecta: '1494.1497',
        capacidade: 168.0,
        num_famili: 160.0,
        fase: 5.0,
        data_de_cr: '26/12/1995',
        forma_obte: 'Desapropriação',
        data_obten: '26/12/1995',
        area_calc_: 1546.5105,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.927860016951634, -7.676695027937317],
              [-34.922869802613043, -7.675462520490212],
              [-34.922460234953739, -7.677431628768022],
              [-34.920727855212711, -7.678748573039334],
              [-34.918306874581752, -7.680204923498537],
              [-34.917887479603543, -7.680170090259638],
              [-34.917759329250934, -7.681664114542499],
              [-34.917596229881056, -7.682781654615116],
              [-34.91713961204772, -7.683742923866223],
              [-34.917259189942982, -7.684842657334497],
              [-34.916029500278725, -7.686871753750833],
              [-34.913606822359341, -7.687174127785813],
              [-34.913128778736251, -7.68671336789124],
              [-34.9125979286896, -7.686947269346581],
              [-34.911244831046773, -7.687206982329185],
              [-34.909311074315134, -7.685824471851776],
              [-34.907717203035396, -7.684101045541434],
              [-34.90469725858356, -7.683482855713739],
              [-34.903738548744393, -7.684204772319651],
              [-34.902636713408754, -7.683884183798644],
              [-34.901592594658922, -7.684741546084473],
              [-34.900807768861583, -7.684704517960042],
              [-34.900046234406133, -7.685278912034581],
              [-34.899367440698725, -7.684976423243028],
              [-34.899478316677097, -7.68382267464921],
              [-34.898601210524703, -7.682413911020918],
              [-34.89793876346765, -7.683138213498929],
              [-34.897229428267188, -7.683692210906012],
              [-34.896879925838491, -7.683458781358556],
              [-34.896487744018238, -7.684349959418152],
              [-34.896483493407096, -7.685454598026946],
              [-34.897601219388008, -7.685781280063082],
              [-34.898755360543248, -7.68620571076418],
              [-34.898230054206891, -7.687118724977807],
              [-34.897238991436531, -7.687356091031499],
              [-34.895206877525339, -7.686615123444472],
              [-34.894886420759924, -7.686862089764601],
              [-34.895948878362056, -7.690416900284444],
              [-34.895100292830548, -7.691454298340457],
              [-34.892903201279175, -7.691794263634733],
              [-34.891626179459088, -7.692521635124933],
              [-34.890517230507591, -7.69352591057171],
              [-34.891415068458819, -7.695131963383809],
              [-34.893927857844609, -7.696897415300884],
              [-34.895886368970601, -7.69727760863288],
              [-34.897242564020132, -7.698701157882253],
              [-34.8976780241413, -7.701730104824738],
              [-34.897701709232869, -7.703100963379488],
              [-34.898245451067659, -7.702844776015751],
              [-34.89867903417062, -7.701826747289226],
              [-34.899759468228531, -7.701340349196316],
              [-34.900314069081254, -7.701182928863021],
              [-34.9010024391279, -7.702122478835569],
              [-34.901628834809848, -7.702008857634802],
              [-34.901744848488377, -7.701319065315699],
              [-34.902281452765742, -7.701597312389583],
              [-34.902345742068164, -7.702294892923058],
              [-34.902850579962823, -7.702643889239397],
              [-34.902951608639384, -7.703815500444464],
              [-34.906863896547272, -7.706116268838751],
              [-34.909709288178036, -7.70631405940579],
              [-34.912255254793664, -7.708112943158767],
              [-34.912496307565242, -7.708570417322928],
              [-34.912863408215664, -7.707993418669614],
              [-34.914061099185368, -7.70566873242878],
              [-34.914253171809683, -7.705552784202263],
              [-34.91561516186146, -7.705283967699693],
              [-34.916664599722743, -7.70526589176699],
              [-34.917133225901878, -7.704976748462391],
              [-34.917640030142138, -7.704289819173145],
              [-34.917742726997837, -7.704131145244998],
              [-34.917975958323495, -7.704038427146213],
              [-34.91838387457662, -7.702604898721298],
              [-34.919913807323724, -7.703527257832641],
              [-34.921218720568696, -7.704412286323433],
              [-34.922635913588245, -7.705413883529956],
              [-34.924473708016315, -7.706509778015968],
              [-34.925548548046322, -7.705374759696704],
              [-34.925445614721085, -7.705220575142143],
              [-34.925287935890609, -7.704984145873931],
              [-34.925141209132192, -7.704764573426839],
              [-34.924985682118212, -7.704542961079315],
              [-34.924819607575017, -7.704293641004877],
              [-34.924638477379808, -7.704021786651476],
              [-34.924490040834208, -7.703804752897848],
              [-34.924334979161877, -7.703565599030362],
              [-34.924183615081404, -7.703342430545446],
              [-34.924035070466132, -7.703121419077639],
              [-34.923874500984859, -7.70288671961241],
              [-34.923727806018114, -7.702673836309455],
              [-34.9235895637608, -7.702466248996309],
              [-34.923453261161612, -7.702266699149366],
              [-34.922860206793779, -7.701389788085566],
              [-34.922711935569204, -7.701168774581923],
              [-34.922567803921929, -7.700901001785866],
              [-34.922440061429072, -7.700710272882726],
              [-34.92229233325827, -7.70048898545863],
              [-34.922155237871429, -7.700253998726803],
              [-34.921902347074237, -7.699764220664484],
              [-34.921816394487763, -7.699555312908476],
              [-34.921714326475012, -7.699350093676582],
              [-34.921640176524377, -7.699105512376662],
              [-34.921574778108798, -7.698852122278591],
              [-34.921516752656323, -7.698585680418634],
              [-34.921473039214881, -7.698317547034216],
              [-34.921441646174138, -7.698068343967552],
              [-34.92141793662487, -7.697814676478505],
              [-34.92140822111466, -7.697549102871479],
              [-34.921415090956472, -7.697283545324747],
              [-34.92142308275043, -7.69704573784321],
              [-34.921441478253314, -7.696783654527088],
              [-34.921457913761856, -7.696529083818044],
              [-34.921480539246843, -7.696280542668295],
              [-34.921502204483851, -7.69604014249763],
              [-34.92152044502155, -7.695804006829412],
              [-34.921542410085294, -7.695549682568459],
              [-34.921564097170766, -7.695294003443719],
              [-34.92157946087616, -7.695063033900166],
              [-34.921598279099626, -7.694794168144931],
              [-34.921622934531719, -7.694533503313717],
              [-34.921644162335753, -7.694316701470504],
              [-34.921649239631819, -7.694075652391024],
              [-34.921675881567346, -7.693813351330723],
              [-34.921696946971551, -7.6935604776391],
              [-34.921716163618768, -7.693299565968524],
              [-34.921746628434107, -7.69306161458228],
              [-34.921753401748539, -7.69283434306747],
              [-34.921783675460745, -7.692593633135481],
              [-34.921800979776911, -7.692350811536866],
              [-34.921818565207865, -7.692089907185657],
              [-34.921849942467531, -7.691872879077105],
              [-34.921854918889139, -7.691649640730325],
              [-34.921873520928322, -7.691413232255203],
              [-34.921893216602193, -7.691178174994569],
              [-34.921910196748676, -7.690943853161126],
              [-34.921923334101464, -7.69072157273822],
              [-34.921939227979877, -7.690487526996175],
              [-34.92195668246088, -7.690257904230815],
              [-34.921974740408849, -7.690021407795887],
              [-34.921989295404693, -7.689812049296034],
              [-34.922009247859513, -7.689553484954685],
              [-34.922029819366948, -7.689311643225165],
              [-34.922052453310243, -7.689064910256365],
              [-34.922071757701623, -7.688823435836952],
              [-34.922086546790048, -7.688605668401447],
              [-34.922102767188115, -7.688383735812696],
              [-34.922125680373739, -7.688138628936603],
              [-34.922145669644372, -7.687908361984088],
              [-34.922161070781144, -7.687685619402538],
              [-34.922175656195421, -7.687442719609134],
              [-34.922203826929611, -7.687197860418747],
              [-34.92224040641802, -7.686968784881569],
              [-34.922277364265931, -7.686743233545494],
              [-34.922334038896523, -7.686488663525055],
              [-34.922394176076253, -7.686258397612638],
              [-34.922467557544181, -7.68603096543093],
              [-34.922550086829837, -7.685802316988322],
              [-34.922637843943164, -7.68558747747679],
              [-34.922734454294826, -7.685386521063785],
              [-34.922845843692947, -7.68516696493048],
              [-34.922972794477289, -7.684941553018981],
              [-34.923093849750835, -7.68473524347604],
              [-34.92323074627825, -7.684504724183853],
              [-34.923356570684781, -7.684290889445208],
              [-34.923483576362216, -7.684077772665991],
              [-34.923615692605793, -7.683852427982846],
              [-34.923738364347649, -7.683642946898494],
              [-34.923871678017612, -7.683421936388647],
              [-34.923992000221624, -7.683214002740392],
              [-34.924113981654067, -7.683012299760708],
              [-34.92422499959104, -7.682831258780352],
              [-34.924339249400632, -7.682643422757812],
              [-34.924464240136913, -7.682425794571534],
              [-34.924575502410789, -7.68223869518065],
              [-34.924694518811293, -7.682042791520091],
              [-34.92479430068358, -7.681882323327894],
              [-34.924905017741878, -7.681694955138141],
              [-34.92502346570484, -7.68149362956205],
              [-34.925127333420214, -7.681314790347683],
              [-34.925224734750913, -7.681148998767608],
              [-34.925315777436317, -7.681000051447635],
              [-34.92541407470334, -7.680831995658713],
              [-34.925519783939777, -7.680659657496678],
              [-34.925634333757515, -7.680458168541784],
              [-34.925735329167672, -7.68028558027597],
              [-34.925830583217625, -7.680126036395794],
              [-34.925931415570403, -7.679957517179616],
              [-34.926026944623366, -7.67979869531491],
              [-34.926128215706591, -7.679627009865547],
              [-34.926225921639514, -7.679468630267191],
              [-34.926473114452349, -7.679038720183462],
              [-34.926602948145479, -7.678810221275183],
              [-34.926720342686522, -7.67861676575183],
              [-34.92682385199133, -7.678439103350065],
              [-34.926924864501508, -7.678270492865808],
              [-34.927027391468819, -7.678096089519887],
              [-34.927148898128884, -7.677890139326857],
              [-34.927495691264419, -7.677320555266938],
              [-34.927860016951634, -7.676695027937317],
            ],
          ],
          [
            [
              [-34.931481436317547, -7.679892844426397],
              [-34.928375821362465, -7.677069171341897],
              [-34.928041080051216, -7.677643755519915],
              [-34.927693388698707, -7.678214971259534],
              [-34.927574400183168, -7.678416570719315],
              [-34.927470794910064, -7.678592967954271],
              [-34.927370321454369, -7.678760491226082],
              [-34.927265912648544, -7.678939604271651],
              [-34.927150317773879, -7.679130158799069],
              [-34.927024261220026, -7.679352131558855],
              [-34.926771313829562, -7.67979201247165],
              [-34.926671808645409, -7.679953293262747],
              [-34.926572336394592, -7.680121896889451],
              [-34.926475728192621, -7.680282531826784],
              [-34.926375435711833, -7.680450144641316],
              [-34.926281800865013, -7.680606969128978],
              [-34.926184223308226, -7.68077375609341],
              [-34.926066436525488, -7.680980864916773],
              [-34.925958749242646, -7.68115664752357],
              [-34.925860631797228, -7.681324340965687],
              [-34.925769229236487, -7.681473832427319],
              [-34.925675425380717, -7.681633460269235],
              [-34.925571558698124, -7.681812480387071],
              [-34.925451851198737, -7.682015800678374],
              [-34.925337177029348, -7.682209876850601],
              [-34.925235235020054, -7.682373609483456],
              [-34.925119638148395, -7.682564073484748],
              [-34.925012333445501, -7.682744465093809],
              [-34.924885724762738, -7.68296499367915],
              [-34.924766796900165, -7.683160444992909],
              [-34.924656139232688, -7.683340942017209],
              [-34.924538295834139, -7.683535755603285],
              [-34.924418153503602, -7.683743326923913],
              [-34.924284120800642, -7.683965606482053],
              [-34.924163428403958, -7.684171824141458],
              [-34.924030053455297, -7.684399344362008],
              [-34.923902687163398, -7.684612824502788],
              [-34.92377722307328, -7.68482611529665],
              [-34.923640507260501, -7.685056453089192],
              [-34.923523048751484, -7.685256418107899],
              [-34.923405628813924, -7.685464971651283],
              [-34.923303582512801, -7.685666223718765],
              [-34.923218105777586, -7.685843986028017],
              [-34.92314254052048, -7.68602911734218],
              [-34.92306806610658, -7.686235127887251],
              [-34.923003624612157, -7.686435217005525],
              [-34.92295079209341, -7.686637243083986],
              [-34.922900512657854, -7.686862854070074],
              [-34.922866733025927, -7.687069586395924],
              [-34.922832443411835, -7.687283824827476],
              [-34.922807940140949, -7.687497748197297],
              [-34.922794197048219, -7.687726359775574],
              [-34.922778108690416, -7.68795742286306],
              [-34.922757610207526, -7.688195286268752],
              [-34.922735041178392, -7.688436232806519],
              [-34.922719329056029, -7.688650207207369],
              [-34.922704368003146, -7.68886996432053],
              [-34.922684553603716, -7.689118854376906],
              [-34.922661746114102, -7.689367215402136],
              [-34.922641517128369, -7.689604535168369],
              [-34.922622086843944, -7.689858215104641],
              [-34.922607531353812, -7.690067392737991],
              [-34.922589301471248, -7.69030587884868],
              [-34.92257201638099, -7.690532788574965],
              [-34.922556461752762, -7.690761589104413],
              [-34.92254314999979, -7.690985316767266],
              [-34.922525660651687, -7.691227235048481],
              [-34.922505793042077, -7.691464281987985],
              [-34.922488736327075, -7.691681517102552],
              [-34.922483308501903, -7.691925189480619],
              [-34.922450001310196, -7.692156329768372],
              [-34.922433944951386, -7.692394444523442],
              [-34.922415273229745, -7.692655534492109],
              [-34.922386572536404, -7.69288321866074],
              [-34.922379814240955, -7.693111854438138],
              [-34.922347587735047, -7.693362914469287],
              [-34.922329396299737, -7.693609898733106],
              [-34.922307645360846, -7.69387145455235],
              [-34.922282910476113, -7.694114399816855],
              [-34.92227782784358, -7.694354183136496],
              [-34.922254347508549, -7.694593958521002],
              [-34.922230559652157, -7.694845940291528],
              [-34.922212426945514, -7.695105942975429],
              [-34.922196722262264, -7.695341615174178],
              [-34.922174520314897, -7.695603625068286],
              [-34.922152725491912, -7.69585541709879],
              [-34.922134491018348, -7.696092818386116],
              [-34.922112301936437, -7.696337560408405],
              [-34.922090546449972, -7.696577960931937],
              [-34.922074445327588, -7.696826201553425],
              [-34.922056731253477, -7.69707851774326],
              [-34.922049220038474, -7.697302219434954],
              [-34.922042976220851, -7.697545714673162],
              [-34.922051254673768, -7.697773865933641],
              [-34.922072300705615, -7.697999518935386],
              [-34.922101059538662, -7.698227216711687],
              [-34.922140297697752, -7.69846752452048],
              [-34.922192215716329, -7.698705967295258],
              [-34.922251341696047, -7.698934975345571],
              [-34.922306174740733, -7.699116357914585],
              [-34.922394706053232, -7.699294153820095],
              [-34.922478826681974, -7.699498730068798],
              [-34.922711785562107, -7.699949721925388],
              [-34.922830980117439, -7.700154231059773],
              [-34.922967758750325, -7.700359113256201],
              [-34.923112289468321, -7.700574719060247],
              [-34.923256239178528, -7.700842311622165],
              [-34.923386808643997, -7.701036824600932],
              [-34.923978403392184, -7.701911571582898],
              [-34.924115983003972, -7.70211292364406],
              [-34.924253495391191, -7.702319429146409],
              [-34.924398000626312, -7.702529067412082],
              [-34.924560577875916, -7.702766831474365],
              [-34.924709852478919, -7.702988924334079],
              [-34.924864136339224, -7.703216419027942],
              [-34.925018468153972, -7.703454491058715],
              [-34.925164714960481, -7.703668279777349],
              [-34.925348035029579, -7.703943378683491],
              [-34.925509912114066, -7.704186388878795],
              [-34.925665075026821, -7.704407641012488],
              [-34.92581599856441, -7.70463334206626],
              [-34.925968027433321, -7.704861437353792],
              [-34.926625623232482, -7.705837955561782],
              [-34.927298410561058, -7.70680608610934],
              [-34.927871718290454, -7.707520708049613],
              [-34.928492672476722, -7.708189096684404],
              [-34.929558346991364, -7.709194597771831],
              [-34.930526771988291, -7.710118805334339],
              [-34.931870710662963, -7.711286951267859],
              [-34.933010071675703, -7.712309465274426],
              [-34.934080877030453, -7.713262761674871],
              [-34.934805770947357, -7.713903634877696],
              [-34.93769953208789, -7.712716782865221],
              [-34.94151329413171, -7.711095857489425],
              [-34.943129608469619, -7.710337693401688],
              [-34.944141030367433, -7.709894808463455],
              [-34.945334396827931, -7.70938347059834],
              [-34.946561336961828, -7.708861851885156],
              [-34.947717796835242, -7.708367582589477],
              [-34.948993495931759, -7.707867253193447],
              [-34.950557611252961, -7.707868989374522],
              [-34.951042730959614, -7.706002781056667],
              [-34.951456639924871, -7.705409095228249],
              [-34.952255385919969, -7.703692617339223],
              [-34.954949064929934, -7.698648234991612],
              [-34.955574285873567, -7.696547146945781],
              [-34.953802807420097, -7.695979968958467],
              [-34.947854878185517, -7.693538843276156],
              [-34.943992796506031, -7.692112932074573],
              [-34.943966020791642, -7.692064233771129],
              [-34.940045115451561, -7.69063276089119],
              [-34.939865487970245, -7.689056061773239],
              [-34.939852745119289, -7.688944195532543],
              [-34.937480291295216, -7.685985130267381],
              [-34.934831820989878, -7.683025298123233],
              [-34.933327341700043, -7.681504641541239],
              [-34.931481436317547, -7.679892844426397],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0313000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOÃO DA PRATA',
        municipio: 'PALMARES',
        area_hecta: '1007.7364',
        capacidade: 106.0,
        num_famili: 106.0,
        fase: 5.0,
        data_de_cr: '06/05/2005',
        forma_obte: 'Desapropriação',
        data_obten: '16/09/2004',
        area_calc_: 942.5242,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.61365547698076, -8.6208927622051],
              [-35.612882565083851, -8.62093332264441],
              [-35.612344454781123, -8.621078176831269],
              [-35.611756449119859, -8.621188072123219],
              [-35.611444349395242, -8.621190195883591],
              [-35.611133025447316, -8.621305267146489],
              [-35.610992131614275, -8.62144741701573],
              [-35.610767864954774, -8.621844278440717],
              [-35.610485688679489, -8.622072104177988],
              [-35.610230333511836, -8.622073841150655],
              [-35.609975559984846, -8.622160288845272],
              [-35.609777338057583, -8.622218113524898],
              [-35.609436864164806, -8.622220428861656],
              [-35.609153329688617, -8.622250595061043],
              [-35.608869601285313, -8.622252524075412],
              [-35.608585872816306, -8.622254452880572],
              [-35.608302144281424, -8.622256381476523],
              [-35.608065598222652, -8.62236533351124],
              [-35.60799101158748, -8.622399687737577],
              [-35.60789361332953, -8.622474002362022],
              [-35.607849154641606, -8.622579928091346],
              [-35.60784342578792, -8.622754435243268],
              [-35.607818064862805, -8.622896928909755],
              [-35.607791196806573, -8.623053862172933],
              [-35.60777845033671, -8.62324610865817],
              [-35.607759275041204, -8.62349036130343],
              [-35.607667849378593, -8.623839577202776],
              [-35.607676752162142, -8.624067884913316],
              [-35.607740042896637, -8.624304424511145],
              [-35.607813782631446, -8.624384532969357],
              [-35.607961295093524, -8.624544785528176],
              [-35.608249639200899, -8.624854663617173],
              [-35.608452704525284, -8.625141806058442],
              [-35.60860233223098, -8.625372835079697],
              [-35.60877917803252, -8.625613497248322],
              [-35.608865460702575, -8.625782340264237],
              [-35.608979922672219, -8.625922753275724],
              [-35.609095354353506, -8.626204351116122],
              [-35.609180667683205, -8.626232009096382],
              [-35.609379667498068, -8.62628713197155],
              [-35.609550488172275, -8.626370684726249],
              [-35.609692741790781, -8.626426193464571],
              [-35.609949069972075, -8.62656564076995],
              [-35.610148457996061, -8.626677237066247],
              [-35.610319666998748, -8.626817263272589],
              [-35.610547622434858, -8.626956903129285],
              [-35.610747787061499, -8.627181446754932],
              [-35.610890623312763, -8.627321665710587],
              [-35.611005086485932, -8.627462077746472],
              [-35.611148117051044, -8.627630533474042],
              [-35.61129095362449, -8.627770752183409],
              [-35.611320491597972, -8.627939980539818],
              [-35.611407164350709, -8.628165296317242],
              [-35.611437673207767, -8.628475709244743],
              [-35.611439808917758, -8.628786315377594],
              [-35.611441750541523, -8.629068684584464],
              [-35.611471288853586, -8.629237912869092],
              [-35.611558350581504, -8.629519702318374],
              [-35.611673397441287, -8.629744824612589],
              [-35.611759682695272, -8.629913666282393],
              [-35.611988417527428, -8.630166252177688],
              [-35.61216001741991, -8.630362750673928],
              [-35.612416931922141, -8.630586905931287],
              [-35.612559964551004, -8.63075536057063],
              [-35.612817268168889, -8.631035989179038],
              [-35.613017242328318, -8.631232293655225],
              [-35.613188843263778, -8.6314287913786],
              [-35.613331682215339, -8.631569008652741],
              [-35.613447119710074, -8.631850603637162],
              [-35.6136189155824, -8.632075337879765],
              [-35.613676440125609, -8.6321878984166],
              [-35.613762921431992, -8.632384976048504],
              [-35.613878165209734, -8.632638333863222],
              [-35.613907705308826, -8.632807561526004],
              [-35.613993603624863, -8.632919928451454],
              [-35.613994381403486, -8.633032875879293],
              [-35.614023921635372, -8.633202103505411],
              [-35.61405346191561, -8.633371331120108],
              [-35.614111764745495, -8.633596838901981],
              [-35.614113126034859, -8.63379449687457],
              [-35.614085335861809, -8.633879400969452],
              [-35.61408786405481, -8.634246480061973],
              [-35.614130158633174, -8.634349650398155],
              [-35.614163578534146, -8.634431172419754],
              [-35.614174443583941, -8.634457675804349],
              [-35.614203303454964, -8.634528074374034],
              [-35.61425656658102, -8.634642597845081],
              [-35.614347311249006, -8.634837711868869],
              [-35.614365005101575, -8.634853149122655],
              [-35.614604230430565, -8.63506186432592],
              [-35.614804986218054, -8.635371114266297],
              [-35.615005547795803, -8.635652127169202],
              [-35.615177346781621, -8.635876859885823],
              [-35.615349145987977, -8.636101592462341],
              [-35.615605872000543, -8.636297506987182],
              [-35.616175877579053, -8.63666071097607],
              [-35.61640403584363, -8.63682858167852],
              [-35.616689526040155, -8.637080774963602],
              [-35.616946642817695, -8.637333161744284],
              [-35.617118638536624, -8.637586129782258],
              [-35.617376145573473, -8.637894989627847],
              [-35.617519768055494, -8.638148151190073],
              [-35.617606837901633, -8.638429937201707],
              [-35.617665728911277, -8.638740153825076],
              [-35.617752604262158, -8.638993702960061],
              [-35.617754552913574, -8.639276070587316],
              [-35.617784875564581, -8.639558244276039],
              [-35.61781461366845, -8.639755707663987],
              [-35.617873700266529, -8.64009416088301],
              [-35.617903438540623, -8.64029162423288],
              [-35.617905192692419, -8.640545755049679],
              [-35.617941191144901, -8.640735378390669],
              [-35.617964084781875, -8.640855971425994],
              [-35.618010403030226, -8.641020521752951],
              [-35.618051351089001, -8.641165993770645],
              [-35.61808050488122, -8.641278746760479],
              [-35.618110438486056, -8.641504446802438],
              [-35.618144223932916, -8.641586855141773],
              [-35.618225884342515, -8.641786038259211],
              [-35.618397493700229, -8.64198253132647],
              [-35.618511965038238, -8.642122938878234],
              [-35.618911152717814, -8.642402589347736],
              [-35.619224243253846, -8.642541637943614],
              [-35.619480975854707, -8.64273754791493],
              [-35.62000638497679, -8.642804547889893],
              [-35.620519070704034, -8.643083419400847],
              [-35.621711955274861, -8.643244680479365],
              [-35.622109974017704, -8.643354906593011],
              [-35.622281390519831, -8.643523160437656],
              [-35.622680581587922, -8.643802805685555],
              [-35.622965885842888, -8.64402675475811],
              [-35.623138475306313, -8.64436442780748],
              [-35.623539622063831, -8.64492643792763],
              [-35.623484437071212, -8.64515271987694],
              [-35.623433942577094, -8.646056680815281],
              [-35.623099313982067, -8.646906113563],
              [-35.62248094001059, -8.647757491192346],
              [-35.62208643325237, -8.648155526625523],
              [-35.620186263795681, -8.649075935724516],
              [-35.620377560099648, -8.649432829462649],
              [-35.621371066497304, -8.651287472485143],
              [-35.621932134235443, -8.652322784881582],
              [-35.622493205649072, -8.653358095491241],
              [-35.622971622068093, -8.654258429127205],
              [-35.623753500667249, -8.655707883756662],
              [-35.625057800079887, -8.658130628431774],
              [-35.625388774897019, -8.65827760220219],
              [-35.625843952831232, -8.658443902581125],
              [-35.626583666520936, -8.658721198416474],
              [-35.627437273687988, -8.659054185198931],
              [-35.627948026674026, -8.659050673322422],
              [-35.629084601887314, -8.659268758521712],
              [-35.629693935987412, -8.659728547894328],
              [-35.629754593750981, -8.659774318702368],
              [-35.629826280221998, -8.659828411457452],
              [-35.630624710968242, -8.66038767158283],
              [-35.631478715949925, -8.660777119955299],
              [-35.631805129008058, -8.660788990648664],
              [-35.631947005008804, -8.660788013381099],
              [-35.632287507343648, -8.66078566772457],
              [-35.632655598881023, -8.660670180686898],
              [-35.633024476080166, -8.660667638896031],
              [-35.633280638606536, -8.660778824498017],
              [-35.633635229873335, -8.660762261679848],
              [-35.633918981419356, -8.660760305739997],
              [-35.634145982608295, -8.660758740836815],
              [-35.63448648431185, -8.660756393229873],
              [-35.634995663539414, -8.660526980541611],
              [-35.635387691658757, -8.660524276853732],
              [-35.635506415395419, -8.660523457976208],
              [-35.635995468439923, -8.660520084424217],
              [-35.636414887925973, -8.660431948454034],
              [-35.636870906757693, -8.660332904237741],
              [-35.637442447609274, -8.660371580745098],
              [-35.637602657271835, -8.660405370795143],
              [-35.638026525289561, -8.658121681726188],
              [-35.635798157952593, -8.638013747134121],
              [-35.639729570666432, -8.637986675394489],
              [-35.644907884205828, -8.637083499788586],
              [-35.650439804889785, -8.636114563418948],
              [-35.6503320951199, -8.632392490988027],
              [-35.650202268131025, -8.628101304217584],
              [-35.644596501487563, -8.628880956423291],
              [-35.637258114972305, -8.629898355899691],
              [-35.635398302407772, -8.630118973607896],
              [-35.623585536250054, -8.62354938002594],
              [-35.622422088239894, -8.62290666405895],
              [-35.622403588891743, -8.622470893746142],
              [-35.62169620169734, -8.622475727323819],
              [-35.621271197336526, -8.622563344865219],
              [-35.620987860911505, -8.622621756329945],
              [-35.620761074838498, -8.62265154333832],
              [-35.620420603558273, -8.622653868529534],
              [-35.620165250035591, -8.622655612225239],
              [-35.619852178001146, -8.622516559481266],
              [-35.619596045974454, -8.62240535579055],
              [-35.619410278254904, -8.62236969718408],
              [-35.619311930637778, -8.622350819051237],
              [-35.618999831851333, -8.622352949236518],
              [-35.618630404092286, -8.622270756099306],
              [-35.618374077841857, -8.622131314660308],
              [-35.618090157125096, -8.62210501368093],
              [-35.617863176121041, -8.622106562165726],
              [-35.617580227661428, -8.62222144474962],
              [-35.61723936701771, -8.622167293407022],
              [-35.616585824022593, -8.622030559455133],
              [-35.616244574715324, -8.62191993349005],
              [-35.616101545372992, -8.621751479653094],
              [-35.615958710494311, -8.621611262552836],
              [-35.615815098458661, -8.621358098036355],
              [-35.615386789207321, -8.620965682766972],
              [-35.615024407212772, -8.620876377964724],
              [-35.614712211450708, -8.620864385875286],
              [-35.614371545921081, -8.620838468359992],
              [-35.614080678059238, -8.620833390008951],
              [-35.61365547698076, -8.6208927622051],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0326000',
        uf: 'PE',
        nome_proje: 'PA BELEM',
        municipio: 'PAUDALHO',
        area_hecta: '43.8005',
        capacidade: 34.0,
        num_famili: 30.0,
        fase: 3.0,
        data_de_cr: '16/11/2005',
        forma_obte: 'Compra e Venda',
        data_obten: '12/09/1984',
        area_calc_: 44.2041,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.184607626251953, -7.895775440042855],
              [-35.184761918195825, -7.896481574719279],
              [-35.184879839210858, -7.897077538523355],
              [-35.18492337400847, -7.897335523374243],
              [-35.18493451246723, -7.897373132010072],
              [-35.18503390847556, -7.897852697013069],
              [-35.185176539819942, -7.898573298344798],
              [-35.185343388956241, -7.899411380751869],
              [-35.185497784573947, -7.900353565567094],
              [-35.185619689498985, -7.901166264566161],
              [-35.18571203645331, -7.901769271689679],
              [-35.18634766820815, -7.901403314991777],
              [-35.186611585607984, -7.901850988940006],
              [-35.186808692061561, -7.902189017346918],
              [-35.186873079697307, -7.902313005657987],
              [-35.186662565949405, -7.902430804230688],
              [-35.186990067568438, -7.902967331386225],
              [-35.187574838782737, -7.903909842644506],
              [-35.187808985180062, -7.903761395984278],
              [-35.187914136522224, -7.903934551767724],
              [-35.187679902641989, -7.904075560533637],
              [-35.18738609337889, -7.90424838884901],
              [-35.18715416559305, -7.904388393783189],
              [-35.186909312654549, -7.904516627762833],
              [-35.186409795524746, -7.904774036979315],
              [-35.1861747703201, -7.90489726754342],
              [-35.186761940254719, -7.905989352789498],
              [-35.18707492196188, -7.905848933890165],
              [-35.18759002284353, -7.905617763742273],
              [-35.187834594887342, -7.905506162014833],
              [-35.188074031276855, -7.905386012476013],
              [-35.189070672245975, -7.904891688797429],
              [-35.189307256817074, -7.904774000734306],
              [-35.189373238439487, -7.904733317511003],
              [-35.189619350578297, -7.904605765842993],
              [-35.190651369632207, -7.904073762391087],
              [-35.190895229485363, -7.903948413581367],
              [-35.190716829008913, -7.903143883454428],
              [-35.190693783366235, -7.902952447478662],
              [-35.190680507333028, -7.902838397355289],
              [-35.190494289562004, -7.90081304175982],
              [-35.190420809356304, -7.900128707544177],
              [-35.190330955070976, -7.899331397781565],
              [-35.190245547659885, -7.89842877351029],
              [-35.190191937081082, -7.897690577476904],
              [-35.189863466452486, -7.897747710324044],
              [-35.188701935081085, -7.897942388112414],
              [-35.188651188990967, -7.897993025888761],
              [-35.188538197183952, -7.897856577021422],
              [-35.188188081554259, -7.897423900619218],
              [-35.188156851655435, -7.897393181327339],
              [-35.187984703727075, -7.897267984580988],
              [-35.187909667021671, -7.897238805913497],
              [-35.187834811218053, -7.897230018046177],
              [-35.187659503472908, -7.897227117971358],
              [-35.187367552240083, -7.897227084741663],
              [-35.187216422217908, -7.897233291079144],
              [-35.187179898372555, -7.897209971614148],
              [-35.187009452433962, -7.897078432149485],
              [-35.186699091441511, -7.8968349204104],
              [-35.186299389339652, -7.896544699080638],
              [-35.186166964434662, -7.896436037986498],
              [-35.185881188875243, -7.896211247548856],
              [-35.185162159381449, -7.895787889550405],
              [-35.185193630408186, -7.895724917205253],
              [-35.184965801740915, -7.895485336244621],
              [-35.184514681732871, -7.895014897814472],
              [-35.1846563808243, -7.895318617083509],
              [-35.184549998885565, -7.895378658275797],
              [-35.184607626251953, -7.895775440042855],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0019000',
        uf: 'PE',
        nome_proje: 'PA ENGENHO CAJUÍ',
        municipio: 'RIBEIRAO',
        area_hecta: '192.1757',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '30/09/1988',
        forma_obte: 'Compra e Venda',
        data_obten: '21/10/1987',
        area_calc_: 192.0127,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.475536950613126, -8.498817820769187],
              [-35.473997738452056, -8.500673906480564],
              [-35.473458421723919, -8.501312144423322],
              [-35.472511734788576, -8.502432439934939],
              [-35.471395678447742, -8.504016814732617],
              [-35.470376724123128, -8.505463317482441],
              [-35.469521384284761, -8.506836426180753],
              [-35.472446825176924, -8.508781262457109],
              [-35.474136239007223, -8.509962179247017],
              [-35.475976477557793, -8.511248499941814],
              [-35.477480920135761, -8.51230008858805],
              [-35.477726088973469, -8.512471457088411],
              [-35.478210162943178, -8.512764463347304],
              [-35.478831308306269, -8.51318991500953],
              [-35.479379152743036, -8.513594427408165],
              [-35.479452063871427, -8.513648257894712],
              [-35.481180378309631, -8.514779329143067],
              [-35.487430909190607, -8.518869790492703],
              [-35.487930978977992, -8.519125952836369],
              [-35.488727724672572, -8.519509246630763],
              [-35.489045337271321, -8.517265504334983],
              [-35.488260007020635, -8.515620118620864],
              [-35.487328429175662, -8.514485096192306],
              [-35.486891676295777, -8.513689832484785],
              [-35.48673971689697, -8.512947166458448],
              [-35.486336730095381, -8.511947049038371],
              [-35.485910587758127, -8.510680959726503],
              [-35.485758072492906, -8.509520157454167],
              [-35.483879003360308, -8.509301608929784],
              [-35.483245250669391, -8.508722119665613],
              [-35.48314970087651, -8.506196582082216],
              [-35.483068500597859, -8.504843448860958],
              [-35.479793707828158, -8.503020199021217],
              [-35.478368586777577, -8.501954869072101],
              [-35.478034272496394, -8.501590650014149],
              [-35.476350316505545, -8.499756076875308],
              [-35.475536950613126, -8.498817820769187],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0034000',
        uf: 'PE',
        nome_proje: 'PA PEDRA IMÁ',
        municipio: 'AGUA PRETA',
        area_hecta: '625.0732',
        capacidade: 49.0,
        num_famili: 48.0,
        fase: 5.0,
        data_de_cr: '31/01/1990',
        forma_obte: 'Desapropriação',
        data_obten: '02/06/1988',
        area_calc_: 626.0237,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.413071743265562, -8.712246922798892],
              [-35.411834546165657, -8.712335602198891],
              [-35.411315993165694, -8.712540495598905],
              [-35.410785568065734, -8.712871465398891],
              [-35.409643985565815, -8.712792846698907],
              [-35.40908253446586, -8.712754179398901],
              [-35.407301265565991, -8.7141478664989],
              [-35.407456849065987, -8.714815557198907],
              [-35.40826321826593, -8.716082184698905],
              [-35.407732205265972, -8.718114708698899],
              [-35.407521768465983, -8.718920180898911],
              [-35.407616254965987, -8.719814193598898],
              [-35.407560923165988, -8.72050484089891],
              [-35.407522326365992, -8.720986604698902],
              [-35.407681384365986, -8.721712273898911],
              [-35.407718170065976, -8.721867373998897],
              [-35.407590735665984, -8.722862608098911],
              [-35.407444171565999, -8.722966811298908],
              [-35.407255855166021, -8.723154197098903],
              [-35.406068495566103, -8.7238673367989],
              [-35.406046122466115, -8.724333685298904],
              [-35.40640446096608, -8.724848020098904],
              [-35.406835406766049, -8.725050094598906],
              [-35.407597576765994, -8.7250538650989],
              [-35.407852602865972, -8.725254638198903],
              [-35.410900593265751, -8.727340427598888],
              [-35.412404642665642, -8.728369652998895],
              [-35.412822003065607, -8.728625180898895],
              [-35.413098346265599, -8.729267141098894],
              [-35.413500577565564, -8.730591784598886],
              [-35.413657823565551, -8.731109629398883],
              [-35.413827007365548, -8.73131474269889],
              [-35.41392841656554, -8.73205332669888],
              [-35.4143676488655, -8.733258997298892],
              [-35.414451862865498, -8.734340543798886],
              [-35.414381332365508, -8.735171244298886],
              [-35.414219209165523, -8.736728245598888],
              [-35.413390821665587, -8.739285282398887],
              [-35.413420937265599, -8.74192554359888],
              [-35.413267251765603, -8.743621499998877],
              [-35.413119550865616, -8.744907248298883],
              [-35.41295731586564, -8.746659914798881],
              [-35.413620586165585, -8.747438839298875],
              [-35.415044001165491, -8.749983862298889],
              [-35.418702427165215, -8.749162501598876],
              [-35.419297644965162, -8.749042458098863],
              [-35.420409718565082, -8.748796880498871],
              [-35.421745092664978, -8.749019203498856],
              [-35.422653074964913, -8.749094064198852],
              [-35.42313574886488, -8.748623665398863],
              [-35.423753268164823, -8.747771545698855],
              [-35.42450913246477, -8.746561448098861],
              [-35.424656076164752, -8.745754106798859],
              [-35.425228599064702, -8.744087239898855],
              [-35.425364341764691, -8.743586441298843],
              [-35.42716003026456, -8.742688506198849],
              [-35.426606674164596, -8.739937640798843],
              [-35.42734187736454, -8.738080586898853],
              [-35.428115817964468, -8.736125683098848],
              [-35.428760309264419, -8.734497746098839],
              [-35.428901537664409, -8.733858481398835],
              [-35.429142206164379, -8.732769103298848],
              [-35.4301034093643, -8.731237540798835],
              [-35.430275628564289, -8.730963128898836],
              [-35.430899653964239, -8.729421934398834],
              [-35.430270730664297, -8.728826141098832],
              [-35.428576038764419, -8.727551799498848],
              [-35.428337315164427, -8.727303681698842],
              [-35.427493545364491, -8.726824057798844],
              [-35.426029878664608, -8.725980493098852],
              [-35.425555388264641, -8.725490481098843],
              [-35.426545290564562, -8.723295918998852],
              [-35.426912435364528, -8.721340523498842],
              [-35.42616571456459, -8.721044086698853],
              [-35.425442340964636, -8.720230524698858],
              [-35.425213880464661, -8.72000639909885],
              [-35.425021241164671, -8.719817414598861],
              [-35.424536583764706, -8.719379509598863],
              [-35.423994789664746, -8.719166357398855],
              [-35.423329534464798, -8.718996615198861],
              [-35.422239689164883, -8.718882955098872],
              [-35.42131928566495, -8.717167617198864],
              [-35.420744227464986, -8.717008177698863],
              [-35.420188476765034, -8.716854090298874],
              [-35.419299595065098, -8.716822709298862],
              [-35.418693638365149, -8.716826029698876],
              [-35.418309885265174, -8.716715612298874],
              [-35.417349817165253, -8.716481444498882],
              [-35.416900221465283, -8.716371783798879],
              [-35.416198206165333, -8.716138806998883],
              [-35.415726756565377, -8.71598234669889],
              [-35.415252000365406, -8.715570771698884],
              [-35.415178473665414, -8.715507029898887],
              [-35.41414522746549, -8.714543390098887],
              [-35.413787646265511, -8.713881248698886],
              [-35.414001462665489, -8.712722958198889],
              [-35.413699835165517, -8.712550559598894],
              [-35.413071743265562, -8.712246922798892],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0409000',
        uf: 'PE',
        nome_proje: 'PA MARIMBONDO',
        municipio: 'ALIANCA',
        area_hecta: '152.3034',
        capacidade: 20.0,
        num_famili: 17.0,
        fase: 3.0,
        data_de_cr: '25/02/2014',
        forma_obte: 'Desapropriação',
        data_obten: '24/05/2004',
        area_calc_: 153.2367,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.220445786176349, -7.556123363199409],
              [-35.213824824976719, -7.555239786399413],
              [-35.214171916776692, -7.556440932299423],
              [-35.213779941576718, -7.556908617599425],
              [-35.215037597676648, -7.557350747599411],
              [-35.215933638976594, -7.55745961489942],
              [-35.215657182876619, -7.55862955529941],
              [-35.215500752476622, -7.559022153199405],
              [-35.214545025476681, -7.560042602099418],
              [-35.214261362976693, -7.560475369999408],
              [-35.214238813676694, -7.56078136629941],
              [-35.214312272376695, -7.561171937899408],
              [-35.214543621976681, -7.562469042499419],
              [-35.214943205876665, -7.563323653599424],
              [-35.215270649676647, -7.563708638199419],
              [-35.216125094976597, -7.564774881099404],
              [-35.216311807076586, -7.564916561899421],
              [-35.216534024676577, -7.564929179599405],
              [-35.216820503076562, -7.565048013199408],
              [-35.217542806476516, -7.565192124699417],
              [-35.218273193476485, -7.565233087799411],
              [-35.218476134476468, -7.565180501999416],
              [-35.219356447576416, -7.561431856399408],
              [-35.219714640676393, -7.560187309799414],
              [-35.219394651576408, -7.558282399399399],
              [-35.219405249976404, -7.557699901199404],
              [-35.220445786176349, -7.556123363199409],
            ],
          ],
          [
            [
              [-35.209229092176962, -7.559916627799425],
              [-35.206747288677107, -7.562474566199435],
              [-35.2051019126772, -7.563343831899423],
              [-35.205200738277192, -7.564152720799435],
              [-35.2032533836773, -7.564226149099438],
              [-35.202412364677343, -7.564443486399433],
              [-35.201364986577403, -7.56438003839944],
              [-35.201191252877415, -7.564080186499436],
              [-35.201000899677418, -7.563898991599434],
              [-35.200757835577434, -7.564190637099435],
              [-35.20000266507747, -7.564867222899443],
              [-35.199684056277491, -7.564864534599434],
              [-35.19912585277752, -7.56447898019945],
              [-35.198391601577562, -7.564186249999445],
              [-35.197630436477596, -7.563509578999443],
              [-35.197037447077626, -7.562715203599451],
              [-35.196243615877677, -7.563069767899441],
              [-35.196280961977671, -7.563303289999442],
              [-35.196231133477667, -7.563666135499444],
              [-35.19592366037768, -7.564162600099439],
              [-35.195631634477706, -7.564308421299451],
              [-35.195412388277717, -7.564543235799452],
              [-35.195058661777729, -7.564747795099443],
              [-35.194693811777753, -7.564796030499447],
              [-35.194406038977775, -7.564761391499453],
              [-35.194280456077777, -7.564787800499447],
              [-35.193005216977845, -7.566663903099451],
              [-35.193026959177843, -7.566876882799449],
              [-35.19317688427784, -7.567237005099455],
              [-35.193236617177831, -7.567470415199447],
              [-35.193565572877816, -7.56781588839945],
              [-35.1937518562778, -7.567873378299446],
              [-35.194040649577794, -7.567768816799452],
              [-35.194198909877784, -7.567733650699451],
              [-35.194498504877764, -7.567721831399443],
              [-35.19455573307777, -7.568140847199452],
              [-35.194750921877763, -7.568596974199441],
              [-35.194988033077749, -7.569169744899442],
              [-35.195266841077732, -7.56981448049944],
              [-35.19543788727772, -7.570263854499447],
              [-35.195708677777702, -7.57068523029944],
              [-35.195861268277703, -7.570890676099438],
              [-35.195993454577696, -7.571147778299444],
              [-35.19512174777774, -7.57177081709944],
              [-35.195577554477715, -7.57199879299944],
              [-35.195960840877689, -7.572189327799445],
              [-35.196206235577684, -7.572356499199452],
              [-35.196361905877673, -7.572489753799437],
              [-35.19652507407767, -7.572743262399446],
              [-35.196653503877663, -7.57293851869944],
              [-35.196747804677656, -7.573195811399452],
              [-35.196886637177649, -7.573404762699443],
              [-35.197441922877616, -7.573891720699438],
              [-35.19812423077758, -7.574465677599441],
              [-35.198209380477579, -7.574446344699433],
              [-35.198181146777578, -7.574312447699447],
              [-35.199521584377507, -7.573409504999441],
              [-35.19905103937753, -7.572992578799441],
              [-35.198888061577541, -7.572776876599449],
              [-35.19867321117755, -7.572520193199446],
              [-35.198651674377544, -7.572348456599441],
              [-35.198289139877573, -7.572175004299437],
              [-35.198110561777575, -7.57227901289944],
              [-35.197903959177587, -7.572290366299435],
              [-35.197726909077602, -7.572016306999447],
              [-35.197546693677602, -7.571797253999437],
              [-35.197400398477619, -7.57147492249945],
              [-35.197357761577614, -7.571217369199434],
              [-35.197467646377618, -7.571151513499448],
              [-35.197456582377619, -7.571007219099438],
              [-35.197393471677614, -7.57078757509944],
              [-35.197310148377618, -7.570657392799449],
              [-35.197206612377634, -7.570616672099446],
              [-35.197024468177638, -7.570696639799445],
              [-35.196914252677637, -7.570697195599449],
              [-35.196750284677648, -7.570285592999451],
              [-35.19665859387765, -7.569863315099445],
              [-35.196624854777653, -7.56932217129945],
              [-35.196621166277652, -7.568933818699434],
              [-35.196771911577649, -7.568774960699437],
              [-35.196881360977649, -7.568623184699447],
              [-35.19697423307764, -7.56859865799945],
              [-35.197181713077626, -7.568760865199438],
              [-35.197254520377626, -7.568855013099435],
              [-35.197470376077611, -7.568970779499446],
              [-35.197615529577604, -7.569067999299443],
              [-35.197679221577594, -7.569062522699447],
              [-35.197901611677587, -7.568768404299449],
              [-35.198076705377574, -7.568656680699438],
              [-35.198298117277567, -7.568509494999437],
              [-35.198331897777571, -7.568378721999444],
              [-35.198321726977568, -7.567730915799442],
              [-35.198153690377573, -7.56753585959944],
              [-35.198066679777575, -7.567357579099437],
              [-35.198047822377575, -7.567034604699439],
              [-35.198453742277557, -7.566934605199441],
              [-35.198976521077526, -7.566785899199433],
              [-35.199300615577506, -7.566851283499441],
              [-35.199999433977474, -7.567117553599431],
              [-35.200543743077446, -7.567479117499427],
              [-35.200864271877428, -7.567520460099432],
              [-35.201026419577417, -7.567572913199434],
              [-35.20114767277741, -7.567711495299443],
              [-35.201516163177395, -7.56804129499944],
              [-35.201665022677382, -7.568190048099436],
              [-35.201827475877366, -7.568302644999443],
              [-35.202018934677369, -7.568361823299433],
              [-35.202304821277345, -7.568363815399429],
              [-35.202694252077329, -7.56840824509944],
              [-35.203162367277308, -7.568347451099442],
              [-35.203508614477286, -7.56836632169944],
              [-35.20458018047723, -7.568443386999439],
              [-35.204996858977211, -7.568427530999431],
              [-35.205268496577183, -7.568336797099436],
              [-35.20564325027717, -7.568204298299431],
              [-35.206938276477096, -7.568537993399421],
              [-35.207249583077086, -7.568797620099428],
              [-35.207481655877075, -7.569054211099417],
              [-35.207584981877069, -7.569053687599427],
              [-35.207599143477069, -7.569129227399425],
              [-35.207730897577058, -7.569300404199426],
              [-35.207763014777058, -7.56952020229942],
              [-35.207730164477056, -7.569833125599417],
              [-35.207803594777054, -7.570049277399434],
              [-35.207890172277047, -7.570141634599417],
              [-35.207970403777047, -7.570340567499426],
              [-35.208205607177035, -7.570535277899429],
              [-35.208434412477025, -7.570826253299423],
              [-35.208556062477015, -7.571042160399423],
              [-35.208706736477012, -7.571546618599426],
              [-35.208790517977, -7.571766154399418],
              [-35.208787791477008, -7.571907080599423],
              [-35.208888409076998, -7.572050919499418],
              [-35.208926995676997, -7.572188199199431],
              [-35.209589472276967, -7.57072759979943],
              [-35.207408824177072, -7.567940168699432],
              [-35.208027832977045, -7.567074373699429],
              [-35.208736252477003, -7.566181489499421],
              [-35.209744594976939, -7.563820394099419],
              [-35.209229092176962, -7.559916627799425],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0151000',
        uf: 'PE',
        nome_proje: 'PA DUAS BARRAS',
        municipio: 'BARREIROS',
        area_hecta: '241.2500',
        capacidade: 26.0,
        num_famili: 26.0,
        fase: 4.0,
        data_de_cr: '14/10/1998',
        forma_obte: 'Desapropriação',
        data_obten: '19/12/1997',
        area_calc_: 236.3533,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.370763310573899, -8.861449878162629],
              [-35.369466242865506, -8.864545861386432],
              [-35.369043697965999, -8.865568454674991],
              [-35.369023653823511, -8.865617024300274],
              [-35.369053173488574, -8.866791297144665],
              [-35.367730222854973, -8.870035123758075],
              [-35.367534625408652, -8.870539768919294],
              [-35.366446707078083, -8.873124877837334],
              [-35.365704794696441, -8.87474002620672],
              [-35.365358662659375, -8.875493532577591],
              [-35.364660122264766, -8.877014322611824],
              [-35.363443895563854, -8.879662244747344],
              [-35.361220771513104, -8.884501890003891],
              [-35.362229471483062, -8.883346687308475],
              [-35.362476349619612, -8.883025752394197],
              [-35.36274837554074, -8.882593199821976],
              [-35.362859415078084, -8.882431739167467],
              [-35.362966418796496, -8.882313172634445],
              [-35.363107877819658, -8.882190100898374],
              [-35.363307622632078, -8.882081663771144],
              [-35.363546325275941, -8.88199870079896],
              [-35.363767909994742, -8.881935137031975],
              [-35.364026146298023, -8.881873484247505],
              [-35.364284547327209, -8.881837551382016],
              [-35.36463086429228, -8.881815529050424],
              [-35.365052916690381, -8.881824915536518],
              [-35.365532028544891, -8.881856969521454],
              [-35.366076114400975, -8.881883678344161],
              [-35.366489274971549, -8.881905553879612],
              [-35.366916115521029, -8.881921064216709],
              [-35.36746800391974, -8.881943282225452],
              [-35.367882085233553, -8.881985665185496],
              [-35.368161948820728, -8.882019790431791],
              [-35.36831611903051, -8.882030600221432],
              [-35.368526786696641, -8.882029262323099],
              [-35.368735195927215, -8.882011863009314],
              [-35.368918185544132, -8.881979621114233],
              [-35.369072004639918, -8.881935775637221],
              [-35.369203949005545, -8.881874756844011],
              [-35.369406432649917, -8.881740743404027],
              [-35.36963916647484, -8.881567791276201],
              [-35.370115702729422, -8.881225567406931],
              [-35.370445118829089, -8.880994092577598],
              [-35.370670908963895, -8.8808421182156],
              [-35.37080730001896, -8.880769446837743],
              [-35.370922339171742, -8.880727990689117],
              [-35.371027776527299, -8.880701599466198],
              [-35.371135437897941, -8.88068591115564],
              [-35.371304528068947, -8.880670903986429],
              [-35.371542236867882, -8.880663747117481],
              [-35.371965093399737, -8.880668311389895],
              [-35.372263661499694, -8.880678737205843],
              [-35.372809616135775, -8.880700449567902],
              [-35.372914151404515, -8.88070139210115],
              [-35.372989561076359, -8.880697697228785],
              [-35.37322353945121, -8.880677907110714],
              [-35.374145435056732, -8.880591744733699],
              [-35.374616871077606, -8.880546411320744],
              [-35.37515154531183, -8.880489957619863],
              [-35.375247359715793, -8.88047541532063],
              [-35.375299812106036, -8.880462756693506],
              [-35.375333949180259, -8.880450750546796],
              [-35.375355694148695, -8.880438823317661],
              [-35.37537312364914, -8.880426119785467],
              [-35.375394549195647, -8.880406424727145],
              [-35.375410449585232, -8.880386362967817],
              [-35.37542525342851, -8.880363360998846],
              [-35.375440820594285, -8.880333388092355],
              [-35.375451451422805, -8.880310814588299],
              [-35.375467824248126, -8.880269985539524],
              [-35.375497954158824, -8.880179569519347],
              [-35.375529522309364, -8.880061480954749],
              [-35.375563567017359, -8.879909617922726],
              [-35.375626869415811, -8.879615567700478],
              [-35.375687512358994, -8.879326892948797],
              [-35.375707278926214, -8.879216917350423],
              [-35.375722814552944, -8.879119293286752],
              [-35.375748259911582, -8.878887107191682],
              [-35.375767284428726, -8.878703724552041],
              [-35.375782640656041, -8.87857823732719],
              [-35.375808467486017, -8.878398853734547],
              [-35.375814896993056, -8.878352991465931],
              [-35.375832162856398, -8.878236048196559],
              [-35.375853438801151, -8.878109451570703],
              [-35.375874362258784, -8.878011793215894],
              [-35.375899589071764, -8.877913035749469],
              [-35.375927640892975, -8.877834622690306],
              [-35.375956312557605, -8.877768798202704],
              [-35.376000526106182, -8.877690014298398],
              [-35.376037878650294, -8.877633511948483],
              [-35.376100402161043, -8.877553271804711],
              [-35.376174754697544, -8.877469205353552],
              [-35.37629943185145, -8.877334984156855],
              [-35.376418171973562, -8.877199193202465],
              [-35.376499223927233, -8.877088321188582],
              [-35.376589187481422, -8.876946759568899],
              [-35.376679032177329, -8.876800860600872],
              [-35.376993937113312, -8.876310546183875],
              [-35.377047705184552, -8.876227970612899],
              [-35.377128997163503, -8.87611609769791],
              [-35.377219184970947, -8.876009420250199],
              [-35.377444749161626, -8.875757457484584],
              [-35.377850522689911, -8.875266473571678],
              [-35.377985647185788, -8.875082146819063],
              [-35.378040543985406, -8.874988382725395],
              [-35.378120195174176, -8.874808350254112],
              [-35.378298551654211, -8.87428176056393],
              [-35.378432224767337, -8.873872222998575],
              [-35.378476854818288, -8.873746946929968],
              [-35.378505443770607, -8.873679014549634],
              [-35.378530956352549, -8.873624730992629],
              [-35.37857841030246, -8.873547266015144],
              [-35.378630771899793, -8.873478879228321],
              [-35.378736144283209, -8.873359248761377],
              [-35.378813134123767, -8.873266591719606],
              [-35.378860069916797, -8.873192345142987],
              [-35.378888248688739, -8.873133757776229],
              [-35.37892642063396, -8.873001181508956],
              [-35.378948208680178, -8.872906710589998],
              [-35.37897082338678, -8.872840659780186],
              [-35.37900370632606, -8.872739707179603],
              [-35.379063420909674, -8.872558208914159],
              [-35.379105031200474, -8.872410048804269],
              [-35.379127950112405, -8.8722877286762],
              [-35.379146337888606, -8.872131142940679],
              [-35.379151055875312, -8.87202715774491],
              [-35.379144981155726, -8.871920562064865],
              [-35.379130621170638, -8.871782403960461],
              [-35.379098967161887, -8.871573026126415],
              [-35.37905156439485, -8.871267853267877],
              [-35.379026754195543, -8.871132006543419],
              [-35.378994449217139, -8.870968241881275],
              [-35.378979888903594, -8.870882598448269],
              [-35.37896965257584, -8.870799070865081],
              [-35.378956263959005, -8.870566500782145],
              [-35.378944187139986, -8.870358763325566],
              [-35.378932099871832, -8.87023880963803],
              [-35.378918675854074, -8.870162268414807],
              [-35.378899922814391, -8.870094870614642],
              [-35.378884830537729, -8.870052098657434],
              [-35.378861764479574, -8.870003510561334],
              [-35.378815841384579, -8.869927976626698],
              [-35.378724157628248, -8.869802122936479],
              [-35.378701461871508, -8.86977328439394],
              [-35.378678391147211, -8.869750899451709],
              [-35.378632692773955, -8.869710249647094],
              [-35.378449969776781, -8.869558585573918],
              [-35.378358682518147, -8.869494276111451],
              [-35.37826749653884, -8.869445691614793],
              [-35.378210942557629, -8.86942555976213],
              [-35.378175060902073, -8.869417482562593],
              [-35.378039998875238, -8.869396800216876],
              [-35.377942889385096, -8.869383058936027],
              [-35.377812654236564, -8.869371676913639],
              [-35.377676298875592, -8.869364591881867],
              [-35.377527514753233, -8.869360749138174],
              [-35.377415281904085, -8.869362840629552],
              [-35.377296814574137, -8.869373240124025],
              [-35.377185910706032, -8.869386806548183],
              [-35.377098681666496, -8.869395399572671],
              [-35.377032951714916, -8.869395817957852],
              [-35.37696286675807, -8.869389297970976],
              [-35.37690513215, -8.86937626914141],
              [-35.376856522158761, -8.869357823704531],
              [-35.376810143329401, -8.869330254571137],
              [-35.376774660810632, -8.86930047268185],
              [-35.376754584953069, -8.869278630512028],
              [-35.376734198351855, -8.869250360089898],
              [-35.376719072515876, -8.869223261857515],
              [-35.376708549491838, -8.869199751332209],
              [-35.376694819985794, -8.869159356478919],
              [-35.376846659933982, -8.868381145003069],
              [-35.377003113421843, -8.867769198545098],
              [-35.377001810880316, -8.867675261154863],
              [-35.376996455210183, -8.867596524982348],
              [-35.37698704203909, -8.867473338890699],
              [-35.376979077271606, -8.867240293907141],
              [-35.37696977405993, -8.86688293973496],
              [-35.37696583826812, -8.866773650940804],
              [-35.376963970339112, -8.866734545614316],
              [-35.376958248322879, -8.866682604374478],
              [-35.376948679107954, -8.86661889885716],
              [-35.376935811816814, -8.866545033128649],
              [-35.376925803810892, -8.866496870117302],
              [-35.376915317363476, -8.866458087551223],
              [-35.376903599731797, -8.866416365644541],
              [-35.376890941857177, -8.86637505159856],
              [-35.37685206676899, -8.866257545426615],
              [-35.376813094072368, -8.866145800273937],
              [-35.376788714043073, -8.866082992741983],
              [-35.376739779408119, -8.865972114728883],
              [-35.376673760275615, -8.865822764024651],
              [-35.376624369819403, -8.865714300206681],
              [-35.376555848524077, -8.865573806933599],
              [-35.37652853123226, -8.865515036948022],
              [-35.376515317158052, -8.865491945390138],
              [-35.376489859497639, -8.865451392818935],
              [-35.376469264395666, -8.865420735532592],
              [-35.376439097646184, -8.865379666780244],
              [-35.37639592991242, -8.865327695753461],
              [-35.376346302184153, -8.865276569576174],
              [-35.376302955545029, -8.86523866578867],
              [-35.376280142948652, -8.865220591902563],
              [-35.376263564042553, -8.865208372743052],
              [-35.376211074789367, -8.865173072387064],
              [-35.376143488082924, -8.865135992556922],
              [-35.375983490209506, -8.8650555604813],
              [-35.375955917635473, -8.865040865912908],
              [-35.375938208512906, -8.865030931295433],
              [-35.375918470417695, -8.865019669939674],
              [-35.375891493621587, -8.865003364022597],
              [-35.375864511669278, -8.864986254349761],
              [-35.375818245380344, -8.864955134194208],
              [-35.375753020316672, -8.864908259947047],
              [-35.375720668992422, -8.864883548503665],
              [-35.375675729408393, -8.864849271748964],
              [-35.375631037682524, -8.864811644327547],
              [-35.375578735255225, -8.864763482203479],
              [-35.375531561425213, -8.864716895014427],
              [-35.37546973076735, -8.864653253715652],
              [-35.375407064443969, -8.864585330896151],
              [-35.375332493446457, -8.864498929825645],
              [-35.37512000566997, -8.864256727872327],
              [-35.374312815728693, -8.863383840224682],
              [-35.373765894295353, -8.862950881539843],
              [-35.373046257848117, -8.862381192593034],
              [-35.372274701787688, -8.86211775957343],
              [-35.370763310573899, -8.861449878162629],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0376000',
        uf: 'PE',
        nome_proje: 'PA JOSIAS BARROS',
        municipio: 'CAMUTANGA',
        area_hecta: '714.1120',
        capacidade: 77.0,
        num_famili: 74.0,
        fase: 4.0,
        data_de_cr: '07/10/2008',
        forma_obte: 'Desapropriação',
        data_obten: '13/10/2006',
        area_calc_: 704.2242,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.303797712904682, -7.409116162890435],
              [-35.302033609906864, -7.410397502668984],
              [-35.299954219684928, -7.413487321968928],
              [-35.299251391705802, -7.416966566857875],
              [-35.298136991230876, -7.42089355752725],
              [-35.30074410453927, -7.421841327607927],
              [-35.301690367914169, -7.421997342561518],
              [-35.3062911714809, -7.424504325186595],
              [-35.308789309660064, -7.425703643663268],
              [-35.308641395104402, -7.42615559100668],
              [-35.31106733729505, -7.426454321292301],
              [-35.312736246920906, -7.42647255445543],
              [-35.315001658568463, -7.427921110336887],
              [-35.31569698401676, -7.427246462628512],
              [-35.317949939696092, -7.428281459974735],
              [-35.317626776508021, -7.42891521681887],
              [-35.318751151881372, -7.429910023762555],
              [-35.31951128378531, -7.431359435586719],
              [-35.3196145771701, -7.431404205099254],
              [-35.31965771527009, -7.4314282029992],
              [-35.319706285870083, -7.4314520820992],
              [-35.319827583770078, -7.431513181599208],
              [-35.319983588570075, -7.431544633499236],
              [-35.320134270770048, -7.431562645699242],
              [-35.320244469370046, -7.431562069299244],
              [-35.320341218270045, -7.431569607599252],
              [-35.320438015470032, -7.431569101299268],
              [-35.320623347470026, -7.431546619799231],
              [-35.320757728670024, -7.431529827999231],
              [-35.320824972770005, -7.431540232099267],
              [-35.320927112370008, -7.431539697699224],
              [-35.321088239569995, -7.431512009899222],
              [-35.321179547269978, -7.431500776099199],
              [-35.321244066669976, -7.43149239419924],
              [-35.32147314726997, -7.431437777099229],
              [-35.321564531269964, -7.431423831299187],
              [-35.321604783369963, -7.431415576299207],
              [-35.321817121269952, -7.431414464899224],
              [-35.321929951569935, -7.431397785499255],
              [-35.322026517069929, -7.431370435399194],
              [-35.322176973069915, -7.431345424299204],
              [-35.322265649169907, -7.431350383199169],
              [-35.322373362669907, -7.431376663999208],
              [-35.3224622215699, -7.431416420499198],
              [-35.322537841069895, -7.431469804299235],
              [-35.322567458469898, -7.431488359199214],
              [-35.322809857569872, -7.431573046999237],
              [-35.322912998169876, -7.431866351899262],
              [-35.322916137969862, -7.431946869399233],
              [-35.322929722569874, -7.43198168729919],
              [-35.322945953469876, -7.432003114199224],
              [-35.322964886469869, -7.432021815299172],
              [-35.322975958369867, -7.432078158199269],
              [-35.322968153169867, -7.432126465199205],
              [-35.322970906469862, -7.432150674299184],
              [-35.322973876569876, -7.432198924899178],
              [-35.323022399569858, -7.432230938499213],
              [-35.323089790269862, -7.432252007099224],
              [-35.323197272269852, -7.432251444099203],
              [-35.323286067069844, -7.432261734899221],
              [-35.323329162569841, -7.432277597899231],
              [-35.323423285569838, -7.432285149199196],
              [-35.323513741869839, -7.43228422339926],
              [-35.323626444869824, -7.432243320799231],
              [-35.323733658769818, -7.432191781399224],
              [-35.323851591469804, -7.432129429899262],
              [-35.324018518269789, -7.432171488599193],
              [-35.324128970469786, -7.432219175899185],
              [-35.324201613569791, -7.432240307099258],
              [-35.324331906969775, -7.432289969299232],
              [-35.324469111669764, -7.432310762099165],
              [-35.324609018469751, -7.432328829099158],
              [-35.324727332369747, -7.432338964899223],
              [-35.324813537669741, -7.432373402099195],
              [-35.324923905269728, -7.432405000899258],
              [-35.324958814969733, -7.432396773599211],
              [-35.324999109369728, -7.432396562299193],
              [-35.325071370969724, -7.432345205899265],
              [-35.325135593469717, -7.432280424099223],
              [-35.325233747669706, -7.432210854699217],
              [-35.325349025469713, -7.432143093599234],
              [-35.325750943280738, -7.431920882221974],
              [-35.326767289469601, -7.432495662299185],
              [-35.326998606969589, -7.432659673999204],
              [-35.327023001269588, -7.432683678999188],
              [-35.327057412569587, -7.43271838729919],
              [-35.327118111669591, -7.4327583808992],
              [-35.327184342269582, -7.432817055299214],
              [-35.327246287669574, -7.432852974899249],
              [-35.327258668769574, -7.43289991049922],
              [-35.32723869576958, -7.432941683199233],
              [-35.327229653269576, -7.433012864199239],
              [-35.327216442869585, -7.433049178199225],
              [-35.327203168769579, -7.4330733808992],
              [-35.327187059469573, -7.433092265699185],
              [-35.327184518669583, -7.433108458099193],
              [-35.327192782269577, -7.433147280599216],
              [-35.327192794069582, -7.433166713499217],
              [-35.327188839769576, -7.43318960179918],
              [-35.32718497556958, -7.433212399299267],
              [-35.327190508869585, -7.433248614999216],
              [-35.327194824069579, -7.433294237099192],
              [-35.327200421169586, -7.433342564099242],
              [-35.327201878369578, -7.433361356699224],
              [-35.327203271869578, -7.433368037999234],
              [-35.327191216369577, -7.433382924499185],
              [-35.327175197569581, -7.433401808799251],
              [-35.327168539269572, -7.433409888099179],
              [-35.32725224066958, -7.433484649799212],
              [-35.327256449669576, -7.433510116499168],
              [-35.327261869669577, -7.433524820899221],
              [-35.327261961569583, -7.433542264899225],
              [-35.327259338869567, -7.433560084599184],
              [-35.327263414969572, -7.433577507699257],
              [-35.327267581569572, -7.433594930299248],
              [-35.32727162236958, -7.433605664999179],
              [-35.327269356869564, -7.433674098899254],
              [-35.327266831269576, -7.43371035679918],
              [-35.327261609269584, -7.433733251799174],
              [-35.327263357869576, -7.433824532299219],
              [-35.327235338369583, -7.433851523899256],
              [-35.327244168069576, -7.433997812099186],
              [-35.327396167969567, -7.434025214699184],
              [-35.327499673069553, -7.434026027199232],
              [-35.327552228269553, -7.4340498843992],
              [-35.327577554269546, -7.434078855599238],
              [-35.327600323169555, -7.434069335999237],
              [-35.327620558069555, -7.434077274099232],
              [-35.327683782469549, -7.434080919199217],
              [-35.327705285069555, -7.434088850699234],
              [-35.327749647169547, -7.434087261999204],
              [-35.327816764269542, -7.434073532599166],
              [-35.327885962169539, -7.43404225739924],
              [-35.327950431969526, -7.43402447449919],
              [-35.32799828286953, -7.4339288662992],
              [-35.328022533369527, -7.433805272099204],
              [-35.328051408469527, -7.433682918999231],
              [-35.328097977869518, -7.433584696399175],
              [-35.328094988681414, -7.43353481989261],
              [-35.328411673969505, -7.433314513599178],
              [-35.330070755666696, -7.432933991588965],
              [-35.332530112047515, -7.433862138487122],
              [-35.332908489461694, -7.434107484907156],
              [-35.334167257816659, -7.434315598048453],
              [-35.334020266728977, -7.436015452519324],
              [-35.334845402451123, -7.437498204163288],
              [-35.335685081397607, -7.437633334709242],
              [-35.335991071252138, -7.43820012633958],
              [-35.337468173886549, -7.439868607955335],
              [-35.336095827619467, -7.440616766733586],
              [-35.339196851174052, -7.44140601843276],
              [-35.339405237668743, -7.441211529399211],
              [-35.339973793068708, -7.440950748699226],
              [-35.340187995268693, -7.440788551399174],
              [-35.340369472268677, -7.440551326499165],
              [-35.340411401368669, -7.440347015899219],
              [-35.34029149206868, -7.440036182799156],
              [-35.340214935668691, -7.439789565199232],
              [-35.339351924768749, -7.439267900999215],
              [-35.339329155568748, -7.439020999099211],
              [-35.339327675768743, -7.438741717299223],
              [-35.339455968968736, -7.43860139519916],
              [-35.340336738668682, -7.438424922899149],
              [-35.340759821268648, -7.43812197789916],
              [-35.340973338768627, -7.437830895299198],
              [-35.341282342968618, -7.437292196199147],
              [-35.341258685768608, -7.436894989199173],
              [-35.341171103668614, -7.436583985499188],
              [-35.340954967968628, -7.4363810378992],
              [-35.341199467868613, -7.435853525899203],
              [-35.34159497156859, -7.435421837699192],
              [-35.341839412868566, -7.434883479799144],
              [-35.341752480868571, -7.434712117399176],
              [-35.34122356346861, -7.434317578599149],
              [-35.340979909177179, -7.433954048800548],
              [-35.341512469270967, -7.429976685121049],
              [-35.341382310868603, -7.429773634499204],
              [-35.340811949568632, -7.429690778399215],
              [-35.340468835268652, -7.429875166199132],
              [-35.340103351168679, -7.429887849899187],
              [-35.340070206168676, -7.429716112499176],
              [-35.340208329368672, -7.429414673199191],
              [-35.340206453368673, -7.429060283599136],
              [-35.340032480368684, -7.42869604579922],
              [-35.339816440668692, -7.428493095699208],
              [-35.339040211346237, -7.428110609363442],
              [-35.338609104619614, -7.428110083131862],
              [-35.338494670965751, -7.427863591356334],
              [-35.338032362891809, -7.427758850631688],
              [-35.337254713162608, -7.42741388190371],
              [-35.337143785598464, -7.426810422205779],
              [-35.338456879551636, -7.425531842147869],
              [-35.33979396029828, -7.424465673783197],
              [-35.340047336729256, -7.424571908967676],
              [-35.340076690126921, -7.425092238665341],
              [-35.340465838868646, -7.425477049899188],
              [-35.341020925268616, -7.425737323999135],
              [-35.341945531868546, -7.425721691099151],
              [-35.342699853268485, -7.425508198799196],
              [-35.342920478437627, -7.425501695347448],
              [-35.343956516068403, -7.426264141299165],
              [-35.347296092668152, -7.424103566599116],
              [-35.345907327332171, -7.421506704362975],
              [-35.345457338545408, -7.421831121181723],
              [-35.345092460181156, -7.421886642217954],
              [-35.344377816740447, -7.422996500403833],
              [-35.343475128957081, -7.423012014379928],
              [-35.34320432251959, -7.422701915087244],
              [-35.342397727539463, -7.422545254412217],
              [-35.340435106257054, -7.421460296490025],
              [-35.338691595051849, -7.422178836783743],
              [-35.337627393329129, -7.422130778226893],
              [-35.337034236668885, -7.421833263499164],
              [-35.334561372569063, -7.422747591499196],
              [-35.33263759309046, -7.418404750565004],
              [-35.332383828339012, -7.418412771222428],
              [-35.331833532827247, -7.41844087703261],
              [-35.331434200533678, -7.418455445737036],
              [-35.331149935328114, -7.418466246918109],
              [-35.330379985262681, -7.418531928731268],
              [-35.330168064150278, -7.418541988329427],
              [-35.329866972448812, -7.41853859602397],
              [-35.329577614281177, -7.418510828244452],
              [-35.329252714292366, -7.418491381302021],
              [-35.329006490243003, -7.418469894783114],
              [-35.328732618709481, -7.418442677948343],
              [-35.328440075902044, -7.41842920702766],
              [-35.328165889662834, -7.418445467023746],
              [-35.327801338509751, -7.418479463958462],
              [-35.32750570928215, -7.41849899940304],
              [-35.327360213839697, -7.418536548545115],
              [-35.326845046091542, -7.4187536418778],
              [-35.326327008530228, -7.418958909235096],
              [-35.326149022580445, -7.41903413831553],
              [-35.325818088599625, -7.419176240115893],
              [-35.3255492405073, -7.419226365437391],
              [-35.325178659444667, -7.419233185870209],
              [-35.324831760522791, -7.419214845158232],
              [-35.324449110854559, -7.419199583566367],
              [-35.32421075024223, -7.419210049935518],
              [-35.324122279628703, -7.419209247343717],
              [-35.323755119496887, -7.419212342864284],
              [-35.323398908604425, -7.419162324207163],
              [-35.32286652527349, -7.419101928084657],
              [-35.322242153142945, -7.419008749991335],
              [-35.321883568558917, -7.418955036597271],
              [-35.321354179920249, -7.418826833229246],
              [-35.32100386600208, -7.418744423397535],
              [-35.320724326402235, -7.418672580336766],
              [-35.320473428900122, -7.418623455199548],
              [-35.319964019697544, -7.418575498975589],
              [-35.319435071170851, -7.418548523370638],
              [-35.319161529876673, -7.418498159835782],
              [-35.318903727748413, -7.418427377050037],
              [-35.31866918996429, -7.418303415896641],
              [-35.318407490807665, -7.418214666207967],
              [-35.318145880840248, -7.418125644709316],
              [-35.317977029397326, -7.418060814649136],
              [-35.317630923088267, -7.417951806225505],
              [-35.317288386032772, -7.417850009773042],
              [-35.316998984211551, -7.417796835038112],
              [-35.316841111840333, -7.417770903777174],
              [-35.316601496054787, -7.417680049177906],
              [-35.316290366175465, -7.417541029628847],
              [-35.315994995535782, -7.417454623009437],
              [-35.315605619560834, -7.417330653271355],
              [-35.315324625104985, -7.417274812251582],
              [-35.315055343438907, -7.417190438259321],
              [-35.314870676107347, -7.417131293120163],
              [-35.314621743141863, -7.417059466940789],
              [-35.314347920734846, -7.416989849091205],
              [-35.314190427099277, -7.416949724081165],
              [-35.314011114080444, -7.416910164543179],
              [-35.313841822814041, -7.416847684920378],
              [-35.313607775092812, -7.416713232994945],
              [-35.313485552049364, -7.416630080785282],
              [-35.313217296653612, -7.416482067869231],
              [-35.313236734349566, -7.416458918091149],
              [-35.313142670314015, -7.416427410762448],
              [-35.312987748734812, -7.416342259259791],
              [-35.312905629244192, -7.416291889276028],
              [-35.312888779681273, -7.416221113771916],
              [-35.312813143422112, -7.415886353396286],
              [-35.312724401971572, -7.415625597492727],
              [-35.312613540709989, -7.415169088559301],
              [-35.312500617675376, -7.414924637560307],
              [-35.31236728604015, -7.414690777503914],
              [-35.312182936777923, -7.414397437054306],
              [-35.311940765405438, -7.41400732172569],
              [-35.311789331331035, -7.413774730481756],
              [-35.311485589854932, -7.413315608124663],
              [-35.311360839604575, -7.41307754503727],
              [-35.31127089447974, -7.412915497056946],
              [-35.311067899144625, -7.41262315646374],
              [-35.310956443860817, -7.412486166920399],
              [-35.310763791097628, -7.412336493171167],
              [-35.310225983386722, -7.412155078955462],
              [-35.310141954488252, -7.412120354972043],
              [-35.309990140033939, -7.412005538724351],
              [-35.309686003620264, -7.411661117239967],
              [-35.309527811498718, -7.411486588108664],
              [-35.30946236655619, -7.411421216643826],
              [-35.309331226999376, -7.411329431744455],
              [-35.309050157070367, -7.411188893144958],
              [-35.308787715164499, -7.411113065087575],
              [-35.308526403783198, -7.411028282672375],
              [-35.308023849599692, -7.410834931727946],
              [-35.307932909069891, -7.410829166913742],
              [-35.30784306261301, -7.410824842597979],
              [-35.307813174947299, -7.410823551479254],
              [-35.307801694810301, -7.410792517897992],
              [-35.307748092404211, -7.410810331106906],
              [-35.307673850242587, -7.410829155259609],
              [-35.307598544761831, -7.410835059576293],
              [-35.307424106957228, -7.410809661915593],
              [-35.30707382419029, -7.410714674364751],
              [-35.306810040842791, -7.410642015458195],
              [-35.306624333269859, -7.410591186722889],
              [-35.306379238437181, -7.410507945692531],
              [-35.306202042022321, -7.410439627887023],
              [-35.306032813672573, -7.410371268700989],
              [-35.30586623638608, -7.410290331861733],
              [-35.305636330029905, -7.410167693168503],
              [-35.305400379298753, -7.41004933383187],
              [-35.305033299535559, -7.409840995451573],
              [-35.304300678190842, -7.409354259348992],
              [-35.304135600796769, -7.409265630957314],
              [-35.303797712904682, -7.409116162890435],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0417000',
        uf: 'PE',
        nome_proje: 'PA LUIZA FERREIRA',
        municipio: 'SAO LOURENCO DA MATA',
        area_hecta: '509.5385',
        capacidade: 57.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '16/06/2017',
        forma_obte: 'Adjudicação',
        data_obten: '09/08/2016',
        area_calc_: 509.5165,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.159939699988207, -8.05489691187006],
              [-35.159374184964442, -8.054907573642206],
              [-35.158870745453882, -8.054960121460693],
              [-35.15861387098635, -8.054937881127826],
              [-35.158315252959518, -8.055030930926621],
              [-35.157545691712031, -8.054993580614147],
              [-35.157020105373746, -8.055060163339492],
              [-35.156905332075262, -8.055137420594589],
              [-35.156953652098714, -8.055373364148123],
              [-35.156999726966767, -8.055425097929007],
              [-35.157065580878438, -8.055444005275106],
              [-35.157395135630019, -8.055489637554958],
              [-35.157457383832678, -8.055598324330091],
              [-35.157357054632328, -8.055834145943823],
              [-35.157668374791768, -8.056049993985273],
              [-35.157818509628697, -8.056279073928991],
              [-35.157953367191723, -8.057031068730575],
              [-35.158022766214884, -8.057239692598092],
              [-35.158192276755436, -8.057547945092809],
              [-35.158377552471165, -8.057836047142752],
              [-35.158754871158258, -8.058354182704194],
              [-35.159475378308692, -8.058991184501286],
              [-35.160397997277371, -8.059704043582601],
              [-35.160389189657927, -8.059873034709248],
              [-35.160368788812946, -8.060250081466599],
              [-35.160256810273886, -8.060666028077856],
              [-35.16008471353792, -8.060999039580251],
              [-35.159450503939517, -8.061978537918865],
              [-35.15920182368064, -8.062322077031208],
              [-35.158955654232713, -8.062643185358747],
              [-35.158706726984136, -8.063128371790047],
              [-35.156618236868859, -8.072975697629548],
              [-35.156484468119849, -8.073285638040179],
              [-35.156055987153266, -8.074017098847222],
              [-35.15598425221576, -8.074223754383032],
              [-35.155972951256402, -8.074333189826],
              [-35.155949599110492, -8.07476964133066],
              [-35.155874024774477, -8.075073218739846],
              [-35.155766737549442, -8.075417007526013],
              [-35.155696159975406, -8.075585420632505],
              [-35.155515747697187, -8.076403888527311],
              [-35.155370886519549, -8.076897024365898],
              [-35.155356214463374, -8.076969416346149],
              [-35.155286123367055, -8.077571443693646],
              [-35.155126896866719, -8.078143568692349],
              [-35.155022577970556, -8.078619587414243],
              [-35.154873115343769, -8.079322550584116],
              [-35.15478249607532, -8.079730973340903],
              [-35.154524940545265, -8.080848417470969],
              [-35.154467219704891, -8.081166544730642],
              [-35.154455262113594, -8.0812890003593],
              [-35.154480855702651, -8.082026927194502],
              [-35.154555913251635, -8.082001763766641],
              [-35.154603237169624, -8.082014711730316],
              [-35.154646487995443, -8.081909627453761],
              [-35.154730344665801, -8.081901773138032],
              [-35.154750878941641, -8.081925619120563],
              [-35.154836506340224, -8.081960873067136],
              [-35.154861773734417, -8.08198785785495],
              [-35.154895448749087, -8.081992561554857],
              [-35.154953988054892, -8.08203374345606],
              [-35.155044802576455, -8.082055139821842],
              [-35.155070268180239, -8.082085287312598],
              [-35.155121573721814, -8.082096496742475],
              [-35.155187326701572, -8.082163854623793],
              [-35.155242471341971, -8.082197732511588],
              [-35.15542061746909, -8.082267842208349],
              [-35.15543316699025, -8.082308181829793],
              [-35.155724145783502, -8.082634594003531],
              [-35.155765293644059, -8.082645947300858],
              [-35.155798711553672, -8.082687623114506],
              [-35.155809433241707, -8.082725350947285],
              [-35.15583263576972, -8.082739239512726],
              [-35.155859536398509, -8.082800474642346],
              [-35.155772459963686, -8.083004770801868],
              [-35.155827037529825, -8.083034312681656],
              [-35.155863428781053, -8.083038098014915],
              [-35.155891856399343, -8.083079438561278],
              [-35.155927414123738, -8.08309705845428],
              [-35.155961046663933, -8.083162054569533],
              [-35.155983597965488, -8.083155788856613],
              [-35.156017141605304, -8.083169894027391],
              [-35.156047118807614, -8.083212672673252],
              [-35.156071871891314, -8.083228180113698],
              [-35.156091910520438, -8.083226989673065],
              [-35.156116267100458, -8.0832020030244],
              [-35.156143770810104, -8.083188931680061],
              [-35.156204545105609, -8.083189786141698],
              [-35.156281705533658, -8.083167775037944],
              [-35.156366735175894, -8.083158739026139],
              [-35.156446034409534, -8.083129575544007],
              [-35.156527783168876, -8.083151742478346],
              [-35.156543363150455, -8.083147863747904],
              [-35.156579581991906, -8.083119198754034],
              [-35.156595611141753, -8.083114504112739],
              [-35.156628103312535, -8.083118490736187],
              [-35.156896516373671, -8.082936106984793],
              [-35.157028029964394, -8.08290142503053],
              [-35.157157658581063, -8.082785127933652],
              [-35.157190440496485, -8.082775373222946],
              [-35.157241110749062, -8.082786495308252],
              [-35.157263670159267, -8.082781766176343],
              [-35.157434928942237, -8.082699327503043],
              [-35.157486342924642, -8.082696796262869],
              [-35.157566637054693, -8.08271833792392],
              [-35.157613843570076, -8.082743308402842],
              [-35.157707927006278, -8.082731241369308],
              [-35.157764459491837, -8.082736095309219],
              [-35.157795435096318, -8.082727796381938],
              [-35.157834104932199, -8.082682486027061],
              [-35.157876812215285, -8.082663006795606],
              [-35.157934721379604, -8.082653842506607],
              [-35.158068696790075, -8.082655756487487],
              [-35.158148813581164, -8.082643943896167],
              [-35.158198992571158, -8.082613849184],
              [-35.158220511842124, -8.082584086598663],
              [-35.158230857435079, -8.082551038472229],
              [-35.158218679918484, -8.082495420606614],
              [-35.158205191080654, -8.082466114066944],
              [-35.158206323986576, -8.082389183525251],
              [-35.158193763605077, -8.08227860865034],
              [-35.158204375626646, -8.082193311885259],
              [-35.158194228097912, -8.08214419160119],
              [-35.158208579020453, -8.081943262270221],
              [-35.158283358232516, -8.081780521338937],
              [-35.158347085485481, -8.081722694799788],
              [-35.158477196179732, -8.081680155715979],
              [-35.158530590453218, -8.081674811734834],
              [-35.15857860243262, -8.081680704917678],
              [-35.158614938933979, -8.081640107299428],
              [-35.158636728104369, -8.081644059918625],
              [-35.158784542564916, -8.0818776685869],
              [-35.158791740516222, -8.081952024095107],
              [-35.158982470521764, -8.082358779911988],
              [-35.15904963994393, -8.082402446522064],
              [-35.159129349919361, -8.082416488329219],
              [-35.159393167327636, -8.082393580989747],
              [-35.159623012051519, -8.082531662245815],
              [-35.159791293644673, -8.082605799218639],
              [-35.159905074498973, -8.082698935499561],
              [-35.159792223219725, -8.082814602238694],
              [-35.159693957586285, -8.082978190593444],
              [-35.15961048533773, -8.083143508629915],
              [-35.159501925186952, -8.083486129633444],
              [-35.15938519192612, -8.084116695745852],
              [-35.168844291514837, -8.085100237693776],
              [-35.168773686489374, -8.084923081578289],
              [-35.169701448708373, -8.085055823633521],
              [-35.170805539398629, -8.085037123241563],
              [-35.169363272308672, -8.076050368229769],
              [-35.175096588343578, -8.069031246638874],
              [-35.175021956964279, -8.068779177713294],
              [-35.174404402112543, -8.066692679732123],
              [-35.174313792499824, -8.066386550304935],
              [-35.173879495488848, -8.064884999463379],
              [-35.173303588670031, -8.062900780321078],
              [-35.172131004461797, -8.061562050604019],
              [-35.170184146248175, -8.05945446997592],
              [-35.169417644656129, -8.058613357450994],
              [-35.169141042244284, -8.058207964285389],
              [-35.168600625117257, -8.057294604049963],
              [-35.168546413187919, -8.057111664953513],
              [-35.168541982759891, -8.056313702180892],
              [-35.168755190325676, -8.055340040129664],
              [-35.168140755398987, -8.055173080092411],
              [-35.16789787653645, -8.055070864623477],
              [-35.167751525535962, -8.055059344926704],
              [-35.165381519282569, -8.054994038850072],
              [-35.164150060524356, -8.054974868326497],
              [-35.163619335123485, -8.054927048918524],
              [-35.161783907218243, -8.054969903342617],
              [-35.160741845075549, -8.055007485170067],
              [-35.159939699988207, -8.05489691187006],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0416000',
        uf: 'PE',
        nome_proje: 'PA CHE GUEVARA',
        municipio: 'MORENO',
        area_hecta: '404.8249',
        capacidade: 54.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '16/06/2017',
        forma_obte: 'Adjudicação',
        data_obten: '09/08/2016',
        area_calc_: 404.8059,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.175096588343578, -8.069031246638874],
              [-35.169363272308672, -8.076050368229769],
              [-35.170805539398629, -8.085037123241563],
              [-35.172381988036953, -8.085202930338662],
              [-35.17392319781645, -8.085563171386148],
              [-35.175956283293104, -8.086905246639194],
              [-35.176142997186844, -8.087929388592123],
              [-35.176221677726573, -8.088562888991451],
              [-35.17624389368293, -8.089440839183503],
              [-35.176270932190903, -8.090017306078092],
              [-35.17639971264952, -8.091112894548434],
              [-35.176434239192893, -8.091563405260841],
              [-35.177854787006645, -8.091660322847909],
              [-35.181570215411114, -8.092017328732217],
              [-35.184525033932175, -8.092394007822277],
              [-35.188818196324277, -8.090359441921814],
              [-35.188841655042737, -8.090202125637873],
              [-35.189013229283624, -8.089557710014351],
              [-35.189119380156718, -8.088639217093164],
              [-35.189285348573335, -8.087930924987365],
              [-35.189406962672685, -8.087423991358593],
              [-35.18909610165322, -8.086460006932962],
              [-35.188344661582036, -8.084409888961511],
              [-35.188401258448415, -8.083989446699672],
              [-35.188397328046776, -8.083361428411612],
              [-35.188438455509917, -8.083217576258745],
              [-35.18854653522709, -8.083026271656269],
              [-35.188604835031754, -8.0828712094068],
              [-35.188615977560431, -8.082212648562647],
              [-35.189223814969232, -8.081265707564766],
              [-35.189188790695326, -8.081246189832584],
              [-35.189865022380886, -8.079813390568463],
              [-35.190088605089308, -8.079411849829896],
              [-35.19013178853973, -8.079329814380063],
              [-35.190067873411742, -8.078308915789268],
              [-35.189829549563029, -8.076598632450606],
              [-35.189816524334127, -8.076537868827137],
              [-35.189815898297162, -8.076404906534677],
              [-35.189801083615464, -8.07633185926805],
              [-35.189230631569622, -8.075783164200564],
              [-35.189035316178511, -8.075623584059803],
              [-35.188655307456635, -8.075356793552452],
              [-35.188298612189726, -8.075141220169376],
              [-35.185975013093078, -8.074328459324187],
              [-35.185451396135655, -8.074189703639947],
              [-35.184606026002669, -8.073991108721779],
              [-35.183414242297928, -8.073531772786533],
              [-35.182832218062224, -8.073224292359793],
              [-35.181026999714355, -8.072028543794522],
              [-35.180355516889406, -8.071579349096465],
              [-35.176091142281024, -8.06946671314561],
              [-35.175096588343578, -8.069031246638874],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0419000',
        uf: 'PE',
        nome_proje: 'PA FAZENDA 21',
        municipio: 'JABOATAO DOS GUARARAPES',
        area_hecta: '565.4280',
        capacidade: 55.0,
        num_famili: 0.0,
        fase: 3.0,
        data_de_cr: '01/12/2017',
        forma_obte: 'Desapropriação',
        data_obten: '08/10/1996',
        area_calc_: 565.4117,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.035261335486709, -8.147065337134215],
              [-35.032154440370654, -8.151069902960444],
              [-35.02593478708711, -8.157463492706515],
              [-35.024223002314102, -8.159143190503263],
              [-35.021540230850498, -8.161720618367898],
              [-35.021348718891353, -8.162155858304869],
              [-35.021121745812486, -8.162774155407966],
              [-35.020518727764809, -8.163089228122962],
              [-35.020628793822972, -8.163561292433393],
              [-35.020669406332075, -8.164619227250103],
              [-35.021015960381995, -8.165011462046337],
              [-35.025074625448099, -8.174599270164592],
              [-35.029185346725143, -8.176621695906929],
              [-35.034859479675667, -8.18029295344831],
              [-35.036154853238301, -8.178957462190118],
              [-35.038289746718227, -8.176785155932331],
              [-35.040469495326811, -8.174963366853296],
              [-35.041135336585647, -8.173854692656585],
              [-35.043715974906256, -8.171514960754555],
              [-35.044082160384846, -8.171196170432781],
              [-35.044186311518096, -8.171088520962634],
              [-35.045789480302801, -8.169400708707558],
              [-35.045961649383024, -8.169252306589415],
              [-35.046107995540154, -8.169146251417551],
              [-35.046220497754462, -8.16902183560258],
              [-35.046679390427911, -8.168184588412803],
              [-35.046879790804795, -8.167701114829326],
              [-35.046884125073781, -8.167554646453066],
              [-35.046781333462427, -8.167091961765147],
              [-35.046779339259729, -8.166949864546927],
              [-35.046797270688188, -8.166818876014325],
              [-35.046836413016891, -8.166648546998712],
              [-35.0468964936438, -8.166510022930254],
              [-35.047267170265769, -8.165881049584543],
              [-35.047337271645183, -8.165697184902061],
              [-35.047506591815953, -8.164990131111951],
              [-35.047509138976281, -8.164635392475947],
              [-35.047609280103337, -8.164435646416925],
              [-35.047976310127012, -8.163999512605415],
              [-35.04798627896222, -8.163943957181237],
              [-35.043555308671529, -8.161601147064458],
              [-35.039188339590453, -8.159776484852708],
              [-35.037204734484874, -8.15598082595465],
              [-35.036187888318494, -8.154365794858006],
              [-35.037031809171019, -8.151392286964729],
              [-35.035261335486709, -8.147065337134215],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0220000',
        uf: 'PE',
        nome_proje: 'PA CACIMBA NOVA',
        municipio: 'PEDRA',
        area_hecta: '985.1800',
        capacidade: 40.0,
        num_famili: 35.0,
        fase: 4.0,
        data_de_cr: '24/03/2000',
        forma_obte: 'Desapropriação',
        data_obten: '19/11/1999',
        area_calc_: 983.9962,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.827848559870503, -8.794874682525254],
              [-36.82640636897073, -8.795955828604276],
              [-36.825718291988096, -8.796474579591324],
              [-36.827072284618637, -8.800310315713643],
              [-36.828470848716307, -8.80449982502887],
              [-36.829127986484792, -8.806452424777859],
              [-36.829910690459677, -8.80881376981665],
              [-36.832222126047256, -8.815991963259181],
              [-36.833087274069293, -8.818650174963572],
              [-36.833107660109555, -8.818722875828518],
              [-36.835227482603692, -8.82540897058959],
              [-36.835263079267691, -8.825491430990692],
              [-36.835696238750174, -8.826823750071593],
              [-36.836898255535829, -8.83058061194016],
              [-36.838605052955586, -8.830463724110743],
              [-36.839745804502165, -8.830061731254446],
              [-36.841234407468484, -8.829382339305882],
              [-36.84190716343651, -8.831382937436819],
              [-36.842377264534072, -8.831071354224832],
              [-36.842863313621237, -8.830720722826278],
              [-36.842928734677628, -8.83055053200952],
              [-36.843716266408215, -8.829988762879029],
              [-36.844048163693884, -8.829782772125876],
              [-36.844034562017022, -8.829761994485978],
              [-36.845303819146793, -8.828857335278201],
              [-36.847829992869954, -8.82697914124444],
              [-36.850276109654821, -8.826665495951556],
              [-36.850274956814054, -8.826723339610751],
              [-36.852029052429735, -8.83137126308614],
              [-36.852279581077205, -8.832065636247199],
              [-36.853932588136722, -8.834196762080087],
              [-36.857474022207036, -8.83874408906134],
              [-36.859298481581391, -8.837082996011645],
              [-36.859367206856128, -8.837032226930097],
              [-36.862940650911241, -8.833756665358266],
              [-36.863272569074027, -8.833467676384828],
              [-36.862967018981344, -8.832728971296316],
              [-36.861944593779832, -8.830039414250775],
              [-36.860869231810781, -8.827359769975935],
              [-36.860131347114553, -8.825272126530544],
              [-36.859078204004874, -8.822698457929786],
              [-36.858914290401515, -8.822315528426397],
              [-36.858322490383557, -8.82077387000124],
              [-36.857946686330671, -8.819913007057556],
              [-36.857939743833811, -8.819856201593513],
              [-36.858044778310848, -8.819890697538524],
              [-36.856241935639162, -8.815128352377368],
              [-36.855863567851756, -8.814209172490129],
              [-36.853203698355841, -8.813517045039676],
              [-36.853085536194058, -8.813364331885497],
              [-36.853730152764186, -8.812851427894984],
              [-36.854479185180317, -8.812274759840067],
              [-36.857192589281681, -8.810188728334895],
              [-36.861696330007938, -8.806743239655173],
              [-36.862630969530905, -8.80602785892164],
              [-36.862894636131379, -8.805497491701429],
              [-36.8621229977174, -8.804477192968111],
              [-36.861228112725222, -8.803351248090665],
              [-36.859485472059418, -8.804568674687813],
              [-36.858141886185408, -8.802745873210052],
              [-36.857645648064377, -8.802033926035225],
              [-36.855596890158587, -8.799175918774699],
              [-36.853032086843257, -8.801225291955443],
              [-36.852196347148833, -8.801887971572654],
              [-36.851639642149799, -8.801185074898981],
              [-36.849440569902477, -8.79871749094511],
              [-36.846405949666206, -8.80103394336701],
              [-36.846343634069591, -8.801077426315342],
              [-36.843712250179401, -8.803081776855164],
              [-36.842201066388299, -8.804212757890086],
              [-36.838147950413635, -8.807315187110797],
              [-36.833811128390622, -8.802025091155679],
              [-36.830915372413379, -8.798535170956285],
              [-36.827848559870503, -8.794874682525254],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0233000',
        uf: 'PE',
        nome_proje: 'PA CAJÁ',
        municipio: 'CARUARU',
        area_hecta: '149.0782',
        capacidade: 13.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '17/10/2001',
        forma_obte: 'Desapropriação',
        data_obten: '30/01/2001',
        area_calc_: 149.051,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.944644741475337, -8.21963555146862],
              [-35.944546714249547, -8.219659577207889],
              [-35.943725937573362, -8.219948080883009],
              [-35.943455534318517, -8.220046363165643],
              [-35.9431799092254, -8.220100597945645],
              [-35.942301626687488, -8.220043159134399],
              [-35.942256830675547, -8.220018463073284],
              [-35.940846012730795, -8.221562574143665],
              [-35.940691828834936, -8.221546267048558],
              [-35.940365895258509, -8.221467979043826],
              [-35.938638362179304, -8.221430297672004],
              [-35.938635121382546, -8.224435742744827],
              [-35.938594432270747, -8.228805410255019],
              [-35.938097676995234, -8.228853854514632],
              [-35.934509228672709, -8.229762451135773],
              [-35.93189532677917, -8.229995476537637],
              [-35.932606567884733, -8.232154469700843],
              [-35.934174991839775, -8.234811197772288],
              [-35.938277268526846, -8.233752475532416],
              [-35.939591287921992, -8.233425939370226],
              [-35.940501402449044, -8.233209231343396],
              [-35.940877555696062, -8.233197711713151],
              [-35.942208749582974, -8.233257424020652],
              [-35.942533277783546, -8.233230020283061],
              [-35.942551634549005, -8.233075766707186],
              [-35.943607325815819, -8.233146347890411],
              [-35.944988714803046, -8.233065837577305],
              [-35.946366233298775, -8.232572772569309],
              [-35.946461776817898, -8.229147678322022],
              [-35.945710487560767, -8.229036925280658],
              [-35.945741902428914, -8.22847803757948],
              [-35.945748753989747, -8.228386744784707],
              [-35.945819394554483, -8.225184788314069],
              [-35.94598697070046, -8.225074429527394],
              [-35.945880336913589, -8.224645649073398],
              [-35.945891741097917, -8.224556852403387],
              [-35.945996001507886, -8.223977284944034],
              [-35.946051938797588, -8.223092093314131],
              [-35.945982591513939, -8.222334114491893],
              [-35.94595712021524, -8.221439311422442],
              [-35.945971760693674, -8.221002956383671],
              [-35.945972156058644, -8.219890155945016],
              [-35.945641934354143, -8.219869449607534],
              [-35.944768096596491, -8.219639977669654],
              [-35.944644741475337, -8.21963555146862],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0187000',
        uf: 'PE',
        nome_proje: 'PA SANTO ANTONIO DO NORTE',
        municipio: 'ITAQUITINGA',
        area_hecta: '284.0000',
        capacidade: 38.0,
        num_famili: 35.0,
        fase: 5.0,
        data_de_cr: '13/08/1999',
        forma_obte: 'Desapropriação',
        data_obten: '24/09/1998',
        area_calc_: 314.6354,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.066993027568159, -7.695547911394157],
              [-35.065935807546474, -7.69821754043981],
              [-35.065835447039881, -7.698639196739669],
              [-35.065786509378569, -7.698844640121167],
              [-35.065362924014686, -7.700412767786754],
              [-35.065127426345697, -7.701335800088752],
              [-35.064610986829614, -7.702537442912292],
              [-35.064594146241937, -7.702578023203739],
              [-35.064574169820538, -7.702626031395379],
              [-35.064572199364534, -7.70263083208351],
              [-35.064006254016455, -7.70440774703426],
              [-35.063655212961883, -7.705500203592053],
              [-35.063654052393126, -7.705503825180053],
              [-35.063400680806005, -7.706292338770489],
              [-35.063381932168532, -7.706350646648424],
              [-35.063320240609187, -7.706542591706439],
              [-35.063320240609187, -7.706542591706439],
              [-35.068146838415487, -7.70748802580149],
              [-35.07031445145261, -7.707912640912349],
              [-35.070713510167607, -7.707939819300218],
              [-35.07228554018846, -7.708046928583394],
              [-35.072816343718422, -7.70801371314354],
              [-35.073674198731858, -7.707959928963792],
              [-35.074606411634448, -7.707915817061818],
              [-35.075416219241127, -7.707877449420593],
              [-35.075829185373223, -7.707932036875109],
              [-35.076367383514253, -7.708003192295152],
              [-35.07688972694605, -7.708038083468906],
              [-35.077052086854323, -7.708048957158713],
              [-35.077800049104653, -7.708206960670373],
              [-35.078502652768996, -7.708355329492337],
              [-35.078766024701046, -7.708452223828859],
              [-35.078852071735142, -7.708405792824945],
              [-35.078863362820904, -7.70839432677429],
              [-35.078868592782747, -7.708374952938031],
              [-35.078873301704391, -7.708326748668202],
              [-35.078886218774969, -7.708309261631326],
              [-35.078968970134127, -7.708274598492022],
              [-35.07901671426864, -7.708255107866472],
              [-35.079040110024863, -7.708237550965506],
              [-35.079056052149923, -7.708210908600932],
              [-35.079056028994216, -7.708175550327304],
              [-35.079030410892919, -7.708088530115476],
              [-35.078997859086321, -7.708010583476046],
              [-35.078998619399783, -7.707976350687183],
              [-35.079020609247131, -7.707937068987279],
              [-35.079105195765997, -7.707838117733838],
              [-35.079210903279943, -7.707748295593162],
              [-35.079279721931286, -7.707699869056055],
              [-35.07930697956278, -7.707686902746628],
              [-35.079362472691514, -7.70766511548296],
              [-35.07937665045953, -7.707662663316907],
              [-35.079407068155675, -7.70766099803245],
              [-35.079419859924812, -7.707651186472542],
              [-35.079433027204658, -7.707622459613806],
              [-35.079444898637128, -7.707563829822703],
              [-35.079474480318709, -7.707478891878123],
              [-35.079477600060656, -7.707443080434699],
              [-35.079474223638101, -7.707407749125148],
              [-35.079446070834024, -7.707265597877241],
              [-35.079441339152943, -7.707205957530557],
              [-35.079448425988737, -7.707154133862308],
              [-35.079464970873005, -7.707115356638288],
              [-35.079482295121814, -7.707093392651093],
              [-35.079521747469755, -7.707059691229758],
              [-35.079631464571378, -7.706982742886994],
              [-35.079704200549102, -7.706922038110275],
              [-35.079739196133396, -7.706885944638945],
              [-35.079773489001937, -7.70681933480336],
              [-35.0798016946543, -7.706754257512558],
              [-35.079807602241317, -7.706715933460588],
              [-35.079791047892307, -7.706592076895071],
              [-35.079778156057465, -7.70652901961634],
              [-35.079774605654194, -7.706491181119579],
              [-35.079784622012141, -7.706451215115939],
              [-35.079820205683376, -7.706383204033451],
              [-35.079897865060531, -7.706233758880955],
              [-35.079939222150394, -7.706178448006219],
              [-35.07995285401222, -7.706155567842347],
              [-35.079970937742765, -7.706072945740726],
              [-35.079994687863397, -7.706010112839341],
              [-35.080002818938219, -7.705976425571413],
              [-35.080001200098081, -7.705941792644863],
              [-35.079979332865513, -7.705843472395371],
              [-35.079972292100152, -7.70578524878861],
              [-35.079972009823109, -7.705735662008274],
              [-35.079983110656009, -7.705664173738891],
              [-35.080026461799186, -7.705528926267349],
              [-35.080044210635513, -7.705481394732472],
              [-35.08006752964787, -7.705439322198665],
              [-35.080091790039688, -7.705411547219041],
              [-35.080120579815564, -7.705388075445901],
              [-35.080168531313056, -7.705356297297361],
              [-35.080182008766876, -7.70535059770017],
              [-35.08021828128269, -7.705340747279907],
              [-35.080235094428346, -7.70532858304801],
              [-35.080252131164244, -7.705302075683274],
              [-35.080262061242422, -7.705271676971098],
              [-35.080264687522977, -7.705227463655961],
              [-35.080258215526349, -7.70514612061349],
              [-35.080248122186525, -7.705101347200048],
              [-35.080210035951914, -7.704995645643361],
              [-35.080199239880763, -7.704963907064746],
              [-35.080155047382213, -7.704821833786795],
              [-35.080130160772207, -7.704760295889047],
              [-35.08012484090586, -7.704741615844896],
              [-35.080123766358746, -7.704716413613657],
              [-35.080131496688843, -7.704683369930978],
              [-35.080152055826808, -7.704635988066127],
              [-35.080200022842781, -7.704530259484824],
              [-35.080231936458063, -7.704458960486418],
              [-35.080236045246693, -7.704441575874312],
              [-35.080235579740396, -7.704424126617869],
              [-35.080231691261417, -7.704390168074854],
              [-35.080228689110498, -7.704375978731553],
              [-35.080213476093881, -7.704336976397589],
              [-35.080211703585597, -7.704324465439433],
              [-35.08021536123178, -7.704312233101581],
              [-35.08031368276967, -7.704144336645599],
              [-35.080405105462596, -7.703992655109674],
              [-35.08042314368128, -7.703960560618239],
              [-35.080475966831209, -7.703861594012611],
              [-35.080500617481221, -7.703820904097213],
              [-35.080512535993492, -7.70379723311938],
              [-35.080521996042464, -7.703759530247697],
              [-35.080521065032379, -7.703724631740203],
              [-35.080514334180556, -7.703645806161205],
              [-35.080505054416577, -7.703561196411195],
              [-35.080504700672954, -7.703528786330835],
              [-35.080508025921752, -7.70350768928973],
              [-35.080520649626663, -7.70348372697664],
              [-35.080545611169583, -7.703455152167306],
              [-35.080577893956423, -7.70342989593667],
              [-35.080600947473833, -7.703416186519458],
              [-35.080623346848519, -7.703408110704389],
              [-35.080656398595913, -7.703400741912565],
              [-35.080692825474571, -7.703397336390358],
              [-35.080754592560574, -7.703415440114158],
              [-35.080778018965951, -7.703417623865231],
              [-35.080858502697815, -7.703401322639984],
              [-35.080899811544306, -7.703402594406001],
              [-35.080913656887432, -7.703394184357723],
              [-35.080930409160814, -7.70335995510964],
              [-35.080947758739875, -7.703288523136451],
              [-35.080986335555302, -7.703169234297196],
              [-35.081027438652043, -7.703051923642938],
              [-35.081036273003519, -7.70303715688913],
              [-35.08105597220613, -7.703021316888105],
              [-35.081081599545627, -7.70301227692585],
              [-35.081166514819046, -7.702994697363271],
              [-35.081188057539094, -7.702986436014468],
              [-35.081204323118172, -7.702975581115478],
              [-35.08124666862399, -7.702936642051442],
              [-35.081273141843198, -7.702908803532432],
              [-35.08133263872184, -7.702825980236605],
              [-35.081338465892081, -7.702809672571788],
              [-35.081341552168439, -7.702779686508027],
              [-35.081346448017122, -7.702769798824717],
              [-35.08135307731223, -7.702761697705941],
              [-35.08136351281852, -7.7027596623174],
              [-35.081385641133579, -7.702773971254373],
              [-35.081396834770153, -7.702777576082275],
              [-35.081449931857854, -7.702777452651707],
              [-35.081481467855504, -7.70277122931818],
              [-35.08151182206921, -7.702756496765034],
              [-35.081530362801786, -7.702737226842261],
              [-35.081542048622111, -7.702715924743161],
              [-35.081552912682618, -7.702682107078879],
              [-35.081561880765207, -7.702649436768427],
              [-35.081574677863657, -7.702622059182876],
              [-35.081587214949074, -7.702607062626389],
              [-35.081666280870344, -7.702533218361901],
              [-35.081786714478319, -7.702429724889339],
              [-35.081859439627209, -7.702368766629257],
              [-35.081901520452973, -7.702341246718477],
              [-35.081938202935632, -7.702330453870975],
              [-35.082046335950793, -7.702334719442197],
              [-35.082230384981017, -7.702352356593323],
              [-35.082313257099955, -7.702361264828427],
              [-35.082333171218679, -7.702358781538063],
              [-35.082359213867392, -7.702345040828005],
              [-35.082383399429034, -7.702325246736296],
              [-35.082388950191039, -7.702310454313211],
              [-35.082384084437905, -7.702231915842462],
              [-35.082365264053408, -7.702099488162957],
              [-35.082344031831681, -7.701964173907371],
              [-35.082327588508761, -7.701881629293728],
              [-35.082333970044239, -7.701860556387778],
              [-35.082351011624866, -7.701845677718228],
              [-35.082371145489518, -7.701837935722383],
              [-35.082516059492669, -7.701821321080491],
              [-35.082633345470136, -7.701807100679366],
              [-35.082648777033683, -7.701802118980992],
              [-35.082665833416918, -7.701790275271544],
              [-35.082695275700779, -7.701758707422417],
              [-35.082720642546434, -7.701721340974238],
              [-35.082734353252604, -7.701686371330974],
              [-35.082735174271427, -7.701659810723523],
              [-35.082729343485433, -7.701646176057329],
              [-35.082719952785247, -7.701638544582725],
              [-35.082673693985484, -7.701628241672715],
              [-35.082662523672766, -7.701621189237614],
              [-35.082602818887949, -7.701561435917038],
              [-35.082563221806524, -7.701495384814226],
              [-35.082552468560486, -7.701453177911096],
              [-35.082553632023924, -7.701405936586544],
              [-35.082558226370629, -7.701386189290552],
              [-35.082578692542178, -7.701323190230246],
              [-35.082583958385285, -7.701281829467669],
              [-35.082576135593719, -7.701263539430043],
              [-35.082554873421543, -7.701244791574474],
              [-35.08250693819565, -7.701215602820488],
              [-35.082446758883243, -7.701184426565495],
              [-35.082419333975864, -7.701165495694357],
              [-35.08240208411452, -7.701148033513078],
              [-35.082387916393273, -7.701128374591746],
              [-35.082380028365527, -7.701109633611589],
              [-35.082377640901669, -7.701088020528585],
              [-35.082381661208949, -7.70105461551921],
              [-35.082394609469922, -7.701019270204224],
              [-35.082417275947201, -7.700988050843861],
              [-35.08248518265404, -7.700917482801517],
              [-35.082535682270823, -7.700885145749558],
              [-35.082550755979398, -7.700869035373429],
              [-35.082556288445851, -7.700855213971426],
              [-35.082557394054497, -7.700807069273598],
              [-35.082553542188847, -7.700738251226348],
              [-35.082541553289133, -7.700602892042491],
              [-35.082541386298409, -7.700566430257001],
              [-35.082545022589301, -7.700532268353922],
              [-35.082553404370046, -7.700496376166977],
              [-35.082567784322137, -7.70046216204382],
              [-35.082584944189435, -7.700432581812823],
              [-35.082605292171287, -7.700405331971422],
              [-35.082614907900329, -7.700396489887233],
              [-35.082626286983526, -7.700390269648069],
              [-35.0826396761029, -7.700386790140525],
              [-35.08265773602534, -7.700385753909748],
              [-35.082676188768133, -7.700387276577874],
              [-35.082706458970726, -7.700393540371035],
              [-35.082712625315018, -7.700396512840666],
              [-35.082718521896368, -7.700401724372143],
              [-35.082734248404456, -7.70041933652766],
              [-35.082742577662565, -7.700426219730888],
              [-35.082750305816823, -7.700427769188241],
              [-35.082770885931886, -7.700421055334965],
              [-35.08278149443619, -7.700412799666413],
              [-35.082784720127783, -7.700404730996679],
              [-35.082783566301543, -7.700389268019613],
              [-35.082775309669962, -7.700351983827279],
              [-35.082761267561608, -7.700278648238678],
              [-35.082760833394815, -7.700270632497513],
              [-35.08276213157388, -7.700263892179718],
              [-35.082765510321622, -7.700254865478877],
              [-35.082771831344111, -7.700244781173997],
              [-35.082781287567755, -7.700234207405876],
              [-35.082792269662001, -7.700224574666858],
              [-35.082804734282739, -7.7002165137536],
              [-35.082810215179641, -7.70021471839474],
              [-35.082814852241654, -7.700215122653938],
              [-35.082820947804443, -7.700217274464445],
              [-35.082829774587189, -7.700223823285674],
              [-35.082843568979797, -7.70023518506407],
              [-35.082854155604664, -7.700241915022709],
              [-35.082860056223033, -7.700243816471437],
              [-35.082885706608607, -7.700248543069648],
              [-35.082900534852818, -7.700247712223947],
              [-35.082910622326509, -7.700243661058876],
              [-35.082926310419317, -7.700230135430574],
              [-35.082940679120419, -7.700213088320722],
              [-35.082973445035748, -7.700166739488818],
              [-35.082983960844594, -7.700156160078791],
              [-35.083003736091797, -7.700139276856846],
              [-35.083022963147414, -7.700126229263262],
              [-35.083035312244462, -7.700121861544533],
              [-35.083048143401427, -7.700120945566331],
              [-35.083061945252474, -7.700124103192935],
              [-35.083071285827806, -7.70013060468213],
              [-35.083101357982464, -7.700183341786404],
              [-35.083135972681227, -7.700219875415445],
              [-35.083144977916724, -7.70022731773994],
              [-35.083153436701032, -7.700231520929348],
              [-35.08315676958361, -7.700228994983496],
              [-35.083158935333742, -7.700190022569205],
              [-35.083161021064775, -7.700153220115301],
              [-35.083162569029589, -7.700149791229713],
              [-35.083177290604816, -7.700146589769607],
              [-35.083181728306435, -7.700143989161646],
              [-35.083215345537468, -7.70009786780022],
              [-35.083268520118615, -7.700005799272341],
              [-35.08331220821087, -7.699955415673672],
              [-35.083385579206137, -7.699893135763751],
              [-35.08347044823487, -7.699828630369038],
              [-35.08347044823487, -7.699828630369038],
              [-35.083635933914266, -7.699775213606379],
              [-35.08366169786057, -7.699758428561863],
              [-35.083675286908388, -7.699737496582068],
              [-35.083705832110958, -7.699684234834889],
              [-35.083720575047138, -7.699665952897567],
              [-35.083743540104521, -7.699653284787922],
              [-35.083802210957032, -7.699638999311411],
              [-35.083849721333955, -7.699633077565921],
              [-35.083916069999397, -7.699632015648906],
              [-35.084127756570069, -7.699649517862666],
              [-35.084240566469028, -7.699665240945854],
              [-35.084305692620788, -7.699666711314418],
              [-35.084369570020101, -7.699663555245774],
              [-35.08456944895817, -7.699650262124131],
              [-35.084581723836983, -7.699650952131497],
              [-35.084616171143637, -7.699656854626883],
              [-35.084632718138451, -7.69965753288928],
              [-35.084649930333569, -7.699652220961065],
              [-35.08468145845795, -7.699630928866698],
              [-35.084732511045004, -7.699583116175837],
              [-35.084821933706358, -7.699504757424812],
              [-35.084837675898569, -7.699495489003104],
              [-35.084859901494752, -7.69949082830808],
              [-35.084893285941305, -7.699493020034368],
              [-35.085022201663435, -7.699540756092587],
              [-35.085090329250569, -7.699559892398446],
              [-35.085121236943273, -7.699556455567404],
              [-35.085156012582623, -7.699537189379587],
              [-35.085188184532313, -7.699505544304967],
              [-35.085228920813236, -7.699450526690743],
              [-35.085309045799143, -7.699324030611578],
              [-35.085352534564748, -7.699266506213953],
              [-35.085354843382653, -7.699249979825578],
              [-35.085349825211594, -7.699234829054431],
              [-35.085329112500688, -7.699198888777956],
              [-35.085322294286478, -7.699179418639028],
              [-35.085318202384634, -7.699145449331417],
              [-35.085319229211912, -7.699122112478459],
              [-35.085324371000482, -7.699103744831526],
              [-35.085339162728339, -7.699076489912517],
              [-35.085388387389195, -7.699000288412093],
              [-35.085424679283143, -7.69894879188457],
              [-35.085433499560864, -7.698924848038663],
              [-35.085431718744722, -7.698910582852128],
              [-35.085417696510468, -7.698881865345297],
              [-35.085353111810619, -7.698791548705078],
              [-35.08531606234294, -7.698738936153533],
              [-35.085309296773744, -7.698724191491969],
              [-35.085307257976076, -7.698696127312804],
              [-35.085313506015822, -7.698660934522922],
              [-35.085320026009519, -7.698623983041266],
              [-35.085328391319564, -7.698604214585378],
              [-35.085335663013375, -7.698594125638424],
              [-35.085345498065045, -7.698587375116961],
              [-35.085398330251358, -7.698568857502284],
              [-35.085446540864709, -7.698550362371461],
              [-35.085461763990743, -7.698541347321441],
              [-35.085470085232878, -7.69853201202736],
              [-35.085471779346477, -7.698522667959073],
              [-35.08546054329917, -7.698486153520769],
              [-35.085434607454893, -7.698426483844871],
              [-35.085414409187308, -7.698384609405242],
              [-35.085409308013482, -7.698371925003701],
              [-35.085406756066028, -7.698364605870886],
              [-35.085405533035527, -7.698358238280758],
              [-35.085405536507082, -7.698349228000825],
              [-35.085411255191957, -7.698321011811849],
              [-35.085413536810393, -7.698313148001869],
              [-35.08542257228352, -7.698294324739658],
              [-35.085424350899885, -7.698288625388386],
              [-35.085424411658295, -7.698281606565919],
              [-35.085422550735743, -7.698277384682271],
              [-35.085418951510192, -7.698273286786542],
              [-35.085414752865915, -7.69827017733392],
              [-35.085407993662166, -7.698268408161693],
              [-35.085398581140431, -7.698268453961636],
              [-35.085386993006082, -7.698270786631654],
              [-35.085358131746098, -7.698279958101229],
              [-35.085345087683983, -7.69828545438687],
              [-35.0853126570277, -7.698303063651776],
              [-35.085298457194071, -7.698307639262595],
              [-35.085265146858106, -7.698308985514832],
              [-35.085235074093426, -7.698303251444498],
              [-35.085210931265784, -7.698291974154495],
              [-35.085192175818683, -7.69828012830427],
              [-35.08518224432845, -7.698271261206477],
              [-35.08517861525614, -7.698262209414766],
              [-35.085178522934058, -7.698249085590938],
              [-35.085182827799642, -7.698235027599679],
              [-35.085196593892903, -7.698211439080632],
              [-35.085224325702363, -7.698177193038576],
              [-35.085254186191577, -7.698149566551575],
              [-35.085265998467442, -7.698133138861316],
              [-35.085270308894408, -7.69812021897173],
              [-35.085267859942697, -7.698110447786545],
              [-35.085186930294221, -7.698016736491033],
              [-35.08511770522211, -7.697944549833957],
              [-35.085092271717777, -7.69791378089412],
              [-35.085071944568668, -7.697880467589755],
              [-35.085062303441084, -7.697853199139725],
              [-35.085059799773738, -7.697833574415154],
              [-35.085060559638599, -7.697827125263617],
              [-35.085063366481755, -7.697817816804827],
              [-35.08507900465095, -7.697788196532627],
              [-35.085080293841344, -7.697780772269684],
              [-35.085079772722835, -7.69775082387826],
              [-35.085077804781989, -7.697716510897991],
              [-35.085073853523511, -7.697687496251835],
              [-35.085068383154741, -7.697661503918942],
              [-35.085066864097698, -7.697658080894676],
              [-35.085064188525521, -7.697655343412301],
              [-35.085059942583406, -7.697652281607096],
              [-35.08504217013212, -7.69764402170473],
              [-35.085037636881225, -7.697640534497454],
              [-35.085035909745265, -7.697637318169405],
              [-35.085036078701421, -7.697634089462709],
              [-35.085056977725451, -7.697570919058641],
              [-35.085090740597948, -7.697489366417498],
              [-35.085117880264264, -7.697426079089998],
              [-35.085140948841548, -7.697379016547542],
              [-35.085151694869722, -7.697360945802743],
              [-35.085164353425085, -7.697344191514262],
              [-35.085178361578762, -7.697331224458696],
              [-35.085190210349182, -7.697323863759227],
              [-35.085202822056829, -7.697319142804884],
              [-35.085234458395227, -7.697311935143004],
              [-35.085267137105504, -7.69730618031298],
              [-35.085292363950401, -7.697302832864731],
              [-35.085412603962268, -7.697286219080842],
              [-35.085437346383038, -7.697281571155622],
              [-35.08560518634394, -7.697246583826344],
              [-35.085640399082884, -7.697240059407048],
              [-35.085650019002323, -7.697238684768618],
              [-35.085662684555459, -7.697237173787518],
              [-35.085666791875894, -7.697236967074513],
              [-35.085671309357146, -7.697237229632042],
              [-35.08567882602069, -7.697238354906044],
              [-35.085686013517609, -7.697240216831299],
              [-35.085704051753439, -7.697246193164785],
              [-35.085732254288622, -7.697256660817626],
              [-35.085755410096816, -7.697267265617469],
              [-35.085770893405915, -7.697275789872851],
              [-35.085783353545246, -7.697284770797764],
              [-35.08579419867452, -7.697295767453025],
              [-35.08580549692099, -7.697310063379754],
              [-35.085806543917805, -7.697312162973752],
              [-35.085806085924538, -7.697315721883782],
              [-35.085803353546588, -7.697320809383865],
              [-35.085796266923005, -7.697329854130009],
              [-35.085792726862216, -7.697335040406711],
              [-35.085791413888622, -7.697338745746666],
              [-35.085791810188915, -7.697342015969772],
              [-35.085794156770845, -7.69734456045568],
              [-35.085803212323803, -7.697350259416843],
              [-35.085811025699506, -7.697353730653342],
              [-35.085820831020627, -7.697356243751606],
              [-35.085830246302514, -7.697356767002893],
              [-35.085837891116476, -7.697355828264111],
              [-35.085855987248706, -7.69735161495881],
              [-35.085876115237944, -7.697345778890147],
              [-35.085922725885922, -7.697330922241134],
              [-35.085940077782574, -7.697326095547567],
              [-35.085952820339145, -7.69732326784265],
              [-35.085968552029399, -7.697320906484283],
              [-35.08600713398603, -7.697316877500337],
              [-35.08602409301902, -7.697313881118897],
              [-35.08603866248643, -7.697309231322486],
              [-35.08605098846688, -7.697302247656792],
              [-35.086058528981226, -7.697295362600863],
              [-35.086061008512651, -7.69729034327978],
              [-35.086060506696775, -7.697284939557088],
              [-35.086055812034488, -7.697277611927696],
              [-35.086035276135107, -7.697258363511993],
              [-35.086026669693219, -7.697248256990738],
              [-35.086023824655108, -7.697240478716558],
              [-35.086017251130819, -7.697188740237935],
              [-35.086017492289081, -7.697160285599047],
              [-35.086022347168019, -7.69714205175056],
              [-35.086032597359377, -7.697125720128781],
              [-35.0860635156073, -7.697093385660368],
              [-35.086108267392383, -7.697051948159856],
              [-35.086135195878292, -7.69702361034846],
              [-35.086143292376761, -7.697016931805387],
              [-35.086151019476446, -7.697012483911041],
              [-35.086156456122175, -7.697010491850689],
              [-35.086162750184222, -7.697010150536411],
              [-35.086169033062745, -7.697011732326915],
              [-35.086190947776743, -7.697021895010955],
              [-35.086241704815095, -7.6970423492669],
              [-35.086257432111871, -7.697045825622731],
              [-35.086274254934089, -7.697044605607175],
              [-35.086287626333231, -7.697037605829825],
              [-35.086300025973614, -7.697026175119465],
              [-35.08631277954526, -7.697009420339752],
              [-35.086340242878101, -7.696948206568853],
              [-35.086356218721569, -7.696924906467024],
              [-35.086383354280422, -7.696898298022016],
              [-35.086508722211683, -7.696792979067475],
              [-35.086539707330019, -7.696754425515936],
              [-35.08660041378382, -7.696644954143017],
              [-35.086642377733881, -7.696531545134273],
              [-35.086676444757636, -7.69640057249938],
              [-35.086687057263724, -7.696359049361315],
              [-35.086687707414256, -7.696331724325],
              [-35.086676828893339, -7.696289483025573],
              [-35.086652789564326, -7.696233178994076],
              [-35.086622628131295, -7.69616218208542],
              [-35.086587856227787, -7.69609346757797],
              [-35.086578924087235, -7.696061383443592],
              [-35.086571603663479, -7.696049974543977],
              [-35.08656362944695, -7.696044575498841],
              [-35.086552015398105, -7.696041596991885],
              [-35.086525637162723, -7.696045497541369],
              [-35.086507939842292, -7.696049873325846],
              [-35.086464183830913, -7.696065166623452],
              [-35.086442923831576, -7.696068770962726],
              [-35.086421040000459, -7.696069549781858],
              [-35.086401066277162, -7.696068034622754],
              [-35.086376104047552, -7.696062135468993],
              [-35.08629091258782, -7.696023407453813],
              [-35.0862519991204, -7.696012706754074],
              [-35.086231003227731, -7.69600299771042],
              [-35.086222247935119, -7.695994925853982],
              [-35.086216301309243, -7.695984332164585],
              [-35.086214806843863, -7.695970985007348],
              [-35.08621915916558, -7.695915911049867],
              [-35.086221245587481, -7.695860757720371],
              [-35.086218161977193, -7.69583987434807],
              [-35.086212778394412, -7.695827760395894],
              [-35.086202727613781, -7.695817275683821],
              [-35.08619160440373, -7.695814110923454],
              [-35.086180208351259, -7.695816822037838],
              [-35.086152328996896, -7.695831848349223],
              [-35.086140652727316, -7.695835838276806],
              [-35.086119668984487, -7.695841396919919],
              [-35.086110759725308, -7.695838243703729],
              [-35.086100403468464, -7.695829160831659],
              [-35.086092541427469, -7.695815731107437],
              [-35.086086925028468, -7.695794892564137],
              [-35.086087393451784, -7.695774024403409],
              [-35.08609438478419, -7.695751179078495],
              [-35.086121410041365, -7.695691912896566],
              [-35.086153706451995, -7.695627450904268],
              [-35.086168450550993, -7.695599731870393],
              [-35.086174582677948, -7.695589933010578],
              [-35.086177041418544, -7.695587217963876],
              [-35.08618107238496, -7.695585206613712],
              [-35.086186200184514, -7.695583901255219],
              [-35.086201165040464, -7.695581884114273],
              [-35.086207997941358, -7.695579290051921],
              [-35.08622476041581, -7.695565740504478],
              [-35.086235164444915, -7.695554593027119],
              [-35.086242334047093, -7.695543081912799],
              [-35.0862455016795, -7.695529788211661],
              [-35.086242467156325, -7.695511972142667],
              [-35.086238369639297, -7.695490651979639],
              [-35.086238895567433, -7.695481544305644],
              [-35.086254798184051, -7.69544770214947],
              [-35.086295942363051, -7.695364939459025],
              [-35.086341116253017, -7.695282165448745],
              [-35.086341116253017, -7.695282165448745],
              [-35.086405109484794, -7.695174098858965],
              [-35.086476147922966, -7.695079828974767],
              [-35.086488907582172, -7.695059832905707],
              [-35.086498973962073, -7.695040665682435],
              [-35.086498840380798, -7.695030098126114],
              [-35.086493461436447, -7.695018932607391],
              [-35.086485065615669, -7.695013252180065],
              [-35.086478885247629, -7.695012933461879],
              [-35.086470725014401, -7.695016292746023],
              [-35.086443764962098, -7.695036324018416],
              [-35.086402453252113, -7.695057135941649],
              [-35.086388395480242, -7.695056717923356],
              [-35.086372940421271, -7.69504598081037],
              [-35.086347075832279, -7.695018353200959],
              [-35.086319219701224, -7.69498724870349],
              [-35.086310037768989, -7.694968000584249],
              [-35.086309742799045, -7.694954668173763],
              [-35.086322941086387, -7.694931651491006],
              [-35.086342119251825, -7.694912791700989],
              [-35.086366893186536, -7.694898147062763],
              [-35.086399549929716, -7.694889641384135],
              [-35.086414666827075, -7.69488786104299],
              [-35.086447368973381, -7.694886943147889],
              [-35.086463855643316, -7.694882548563625],
              [-35.086486637327212, -7.694867783079124],
              [-35.086505359243795, -7.694846636412255],
              [-35.086528016788755, -7.694808832642487],
              [-35.08653586674798, -7.694785787229564],
              [-35.086538804804263, -7.694764432842335],
              [-35.086540838470327, -7.694713680934831],
              [-35.086544135479834, -7.694687961927706],
              [-35.08655513013084, -7.694664243679582],
              [-35.086586410028744, -7.694622502936166],
              [-35.086612063137423, -7.694599425641097],
              [-35.086625807745349, -7.694590917555703],
              [-35.086639137736427, -7.694585114578551],
              [-35.08665141599171, -7.694581241161567],
              [-35.08670510591481, -7.69457222659558],
              [-35.086748050181001, -7.694569471052984],
              [-35.086828507942876, -7.694566819515264],
              [-35.086869091608229, -7.694567587291356],
              [-35.086883694765262, -7.694566641321747],
              [-35.086888950241836, -7.694565024673267],
              [-35.086893212846412, -7.694561779200412],
              [-35.086899638979148, -7.694553780958397],
              [-35.086904334435999, -7.694543670017485],
              [-35.086904600955037, -7.694538012560239],
              [-35.086902197176194, -7.694532523259657],
              [-35.086887825756527, -7.69451492834868],
              [-35.086886093534211, -7.69451066876236],
              [-35.086885849652816, -7.694506967746261],
              [-35.086890771657309, -7.69447574308773],
              [-35.086898092880645, -7.694456359104878],
              [-35.086907333231274, -7.694440569884106],
              [-35.086920073128979, -7.694426527208513],
              [-35.086923367501505, -7.694423941422302],
              [-35.086926783248607, -7.694422502122416],
              [-35.086931245513775, -7.694421199998391],
              [-35.086938948207923, -7.694421494470732],
              [-35.086949966014366, -7.694424121752718],
              [-35.086976366971733, -7.694432409195114],
              [-35.086991136531829, -7.694439071291503],
              [-35.087008582111892, -7.694448565712963],
              [-35.087019084151365, -7.694456057646729],
              [-35.087023376732013, -7.694460349309635],
              [-35.087027020661765, -7.694466686180035],
              [-35.087033617086739, -7.694483785423298],
              [-35.087036445463205, -7.69448860874161],
              [-35.08703992456703, -7.694492658045559],
              [-35.087047644733694, -7.694499129430757],
              [-35.087054084567967, -7.694503650631904],
              [-35.087062374190154, -7.69450730924039],
              [-35.087068556617723, -7.694507800793128],
              [-35.087072161608042, -7.694506332122465],
              [-35.087086649842405, -7.694494197329186],
              [-35.087097809778768, -7.694482097719769],
              [-35.087119320117509, -7.694457617893226],
              [-35.087125705905549, -7.694451089934926],
              [-35.087132282764955, -7.694444750738942],
              [-35.087138622081191, -7.694439565652013],
              [-35.087154495507107, -7.694427807658223],
              [-35.087170769771888, -7.694416447925647],
              [-35.087184301964299, -7.694408314224989],
              [-35.087207459854113, -7.694395658450485],
              [-35.087213799302518, -7.694393659388549],
              [-35.087233955668218, -7.694393656119347],
              [-35.087243644043838, -7.694391712061721],
              [-35.087248827606331, -7.694386326825302],
              [-35.087251935479991, -7.694376306832081],
              [-35.087246450695879, -7.694345026236221],
              [-35.087248615648505, -7.694319605781865],
              [-35.087253090921529, -7.694294551495703],
              [-35.087257195530412, -7.694285419879336],
              [-35.087271265663496, -7.694265718513808],
              [-35.087276007915627, -7.694255478146393],
              [-35.08727967998842, -7.694236560185042],
              [-35.087282783417727, -7.694214133006285],
              [-35.087283642451162, -7.694191394761911],
              [-35.087282547733736, -7.694165951701498],
              [-35.087280155696618, -7.694158879955256],
              [-35.087260947748774, -7.694138961192502],
              [-35.087259464427049, -7.69413397256137],
              [-35.087261809188391, -7.694120699366234],
              [-35.087268021504606, -7.694107865078561],
              [-35.087282268246817, -7.694090289436321],
              [-35.087295173476065, -7.694080227909337],
              [-35.087309874826531, -7.69407294814431],
              [-35.087323555871606, -7.694070889805452],
              [-35.08733516661524, -7.694073204406096],
              [-35.087342020346412, -7.694078608356321],
              [-35.087356791332283, -7.694101268057002],
              [-35.087369826430461, -7.694131265778923],
              [-35.087378351209431, -7.694174383307788],
              [-35.087383903261866, -7.694193120289121],
              [-35.087398909744479, -7.694217722165838],
              [-35.087416263659364, -7.694232014949201],
              [-35.087433809245084, -7.694238418129537],
              [-35.087451794647414, -7.69424165014601],
              [-35.087476116596861, -7.694240944232949],
              [-35.087494851766763, -7.694239021993457],
              [-35.087510380743772, -7.694235721668837],
              [-35.087519750212351, -7.694231691954907],
              [-35.087535110150313, -7.694222396522368],
              [-35.087535110150313, -7.694222396522368],
              [-35.087576736856931, -7.694191910343279],
              [-35.087605195357746, -7.694172330540693],
              [-35.087620365124209, -7.694163625810385],
              [-35.087640987073819, -7.69415498611836],
              [-35.087666027952366, -7.694149461308259],
              [-35.087693594565621, -7.694148283806775],
              [-35.087728411670462, -7.694150130949337],
              [-35.087887151705047, -7.694163279434649],
              [-35.087959169012123, -7.694172598828183],
              [-35.088018366457781, -7.694181172218252],
              [-35.088036812080915, -7.694184882527296],
              [-35.088126608948684, -7.694208307080824],
              [-35.088143706280803, -7.694209922663353],
              [-35.088157292010585, -7.694207817347877],
              [-35.088165643135966, -7.694203597066672],
              [-35.088236635206165, -7.694155520913097],
              [-35.086464252625618, -7.691534723178556],
              [-35.086392209416033, -7.691608748507911],
              [-35.086321027372463, -7.691636485529178],
              [-35.086226884595028, -7.691675814846199],
              [-35.086199402184036, -7.691726390967512],
              [-35.086245648328664, -7.691788179521859],
              [-35.086243685058072, -7.691868553482482],
              [-35.086197896537612, -7.69193747917515],
              [-35.08611739323662, -7.691949260952193],
              [-35.086027615233242, -7.691917515660822],
              [-35.085997610285347, -7.691896960288663],
              [-35.085983500729874, -7.691809794183627],
              [-35.085987886758751, -7.691761590358156],
              [-35.086008224278956, -7.691658256163219],
              [-35.086042450885714, -7.691603177506241],
              [-35.086035617034568, -7.691589329554067],
              [-35.086006955270904, -7.691577172931268],
              [-35.085936639809852, -7.691585110158206],
              [-35.085883752789812, -7.691592237643253],
              [-35.085824057024638, -7.691615398799006],
              [-35.085739044964676, -7.691631722351262],
              [-35.085683779348265, -7.691634251046553],
              [-35.085635367603125, -7.691611434773894],
              [-35.085589413059786, -7.691609307849498],
              [-35.085536592193279, -7.691648526512022],
              [-35.08546745253215, -7.691612070395255],
              [-35.085206621704238, -7.691365284354707],
              [-35.084957198478442, -7.691097741317028],
              [-35.084774754042805, -7.690850935459029],
              [-35.084727518799468, -7.690809219990163],
              [-35.084499679686196, -7.690637755912497],
              [-35.084345147372872, -7.690539610504838],
              [-35.084289883891323, -7.690505437071393],
              [-35.084254392831689, -7.690464624142058],
              [-35.084227116981452, -7.690345012985531],
              [-35.08422899565101, -7.690325894415011],
              [-35.084242836981034, -7.690278784043063],
              [-35.084238290567953, -7.690257814277063],
              [-35.084205312291942, -7.690226611889576],
              [-35.084118453230928, -7.690175681562784],
              [-35.084064087230047, -7.690160716729164],
              [-35.084051623901132, -7.690148344175217],
              [-35.084041841120822, -7.690122128018222],
              [-35.084035192808763, -7.690068149864192],
              [-35.084019566048454, -7.68999969643571],
              [-35.084007253942211, -7.689969570095582],
              [-35.083993474395058, -7.689952400654716],
              [-35.083967541063465, -7.689937541106342],
              [-35.083921951258596, -7.68992632182285],
              [-35.083806383518905, -7.689917457970592],
              [-35.083765466522635, -7.68991081009471],
              [-35.083636530480902, -7.689876813555566],
              [-35.083558226794885, -7.689856492504862],
              [-35.083545545070031, -7.689850056947653],
              [-35.083535592556849, -7.689836826967944],
              [-35.083525931474014, -7.689805385326506],
              [-35.083526946472979, -7.689779582535227],
              [-35.083537163936249, -7.689755619309548],
              [-35.083569211030692, -7.689711891448413],
              [-35.083592172837321, -7.689681948328221],
              [-35.083592193516438, -7.68966772787886],
              [-35.083564435084959, -7.689643121139427],
              [-35.08354259388507, -7.689634962664536],
              [-35.083472367149241, -7.689643387436266],
              [-35.083433875319685, -7.689644975117992],
              [-35.083410249386624, -7.689634468396866],
              [-35.083390561700924, -7.689614455636415],
              [-35.083375568287032, -7.689584103856026],
              [-35.083350062025303, -7.689538305100653],
              [-35.083335176601111, -7.689526139266497],
              [-35.08331461837296, -7.6895216865226],
              [-35.0832856453261, -7.689522526889761],
              [-35.083258941013263, -7.689529354789735],
              [-35.083235602752971, -7.689539711371363],
              [-35.08321900557948, -7.689552591673617],
              [-35.083193901369498, -7.689607766457871],
              [-35.083141159551687, -7.689644634066941],
              [-35.083090683154161, -7.689681490672892],
              [-35.083068426388103, -7.689689050472289],
              [-35.083031133242351, -7.689684299588892],
              [-35.082917889549854, -7.68962935584918],
              [-35.082687488789752, -7.689526967614422],
              [-35.082631367270416, -7.68951513967249],
              [-35.082533360175248, -7.689511443948444],
              [-35.082466619821901, -7.689520898171874],
              [-35.08237713074174, -7.689585425318362],
              [-35.082331197705059, -7.689606259195874],
              [-35.082080502982947, -7.689616334923029],
              [-35.081965509239794, -7.689616802546421],
              [-35.081924963326948, -7.68963503782683],
              [-35.08189124822669, -7.689662137453877],
              [-35.081832486821966, -7.689729723471648],
              [-35.081646844130788, -7.689923083532532],
              [-35.081596422355631, -7.689989771385997],
              [-35.081585851168242, -7.690010847450957],
              [-35.081585137843852, -7.690035748792969],
              [-35.081591751695285, -7.690051033183765],
              [-35.081603656137375, -7.690060880245255],
              [-35.081615372679963, -7.690059644333116],
              [-35.081640357761124, -7.690042441586986],
              [-35.081640357761124, -7.690042441586986],
              [-35.081664672624676, -7.690030951570415],
              [-35.081674366505936, -7.690030145771726],
              [-35.08167945994893, -7.690033121569984],
              [-35.081723281830953, -7.690081091565397],
              [-35.08176947123534, -7.690131400437494],
              [-35.081780093126341, -7.690147430465935],
              [-35.081784769541109, -7.690170549996512],
              [-35.081785868871584, -7.6902048151901],
              [-35.081772250368914, -7.69025532386926],
              [-35.081775032416083, -7.690279479505596],
              [-35.081783892085085, -7.690301190028433],
              [-35.081838891759432, -7.690350872571126],
              [-35.081968492683721, -7.690411703301504],
              [-35.081993755449304, -7.690428899657455],
              [-35.082092931843881, -7.690511133329721],
              [-35.08218279895322, -7.690561230146071],
              [-35.082226622502901, -7.690590939475599],
              [-35.082248038661511, -7.69061084919983],
              [-35.082253406310969, -7.690619738592362],
              [-35.082254360682519, -7.690629857162214],
              [-35.082236232739895, -7.690703349050532],
              [-35.082212655582346, -7.690802797822793],
              [-35.08220007737399, -7.690870943288624],
              [-35.082198281001595, -7.690896574588518],
              [-35.082207286600166, -7.690974143830418],
              [-35.082205311061912, -7.691052167662674],
              [-35.082200264775331, -7.691069372809847],
              [-35.082188004999125, -7.691089918867357],
              [-35.082170413194874, -7.691108878442256],
              [-35.082134295959612, -7.691132786578641],
              [-35.082098429686567, -7.691145743214407],
              [-35.082042328584599, -7.691153753512602],
              [-35.082007777560719, -7.691156236794817],
              [-35.08198486557189, -7.691151682041098],
              [-35.081964146436739, -7.691138617672647],
              [-35.081938629916124, -7.691110503743983],
              [-35.08190861525577, -7.691069337113976],
              [-35.081876199577728, -7.691018961444028],
              [-35.081858712343795, -7.691003405944378],
              [-35.081843929387503, -7.690998416704265],
              [-35.081835498884303, -7.691000388710471],
              [-35.081824983535242, -7.691008217049547],
              [-35.081814137565111, -7.691021522608792],
              [-35.08180892677705, -7.691030014514363],
              [-35.081807285731642, -7.691044438944498],
              [-35.08181104380607, -7.691074202086034],
              [-35.081809749911955, -7.6910880775022],
              [-35.081802441252258, -7.691103835530043],
              [-35.081781662669016, -7.691132200222112],
              [-35.081773373813427, -7.69114817444083],
              [-35.081773226075519, -7.691161568272311],
              [-35.081796499276351, -7.691232599271668],
              [-35.081816146092706, -7.691301616024961],
              [-35.081826815252114, -7.691354129078879],
              [-35.081825909301863, -7.691392999384642],
              [-35.081811064655007, -7.691450570760093],
              [-35.08177915016234, -7.691540130173673],
              [-35.081765125175409, -7.691562308458511],
              [-35.081754568392661, -7.691581139036758],
              [-35.081752902379819, -7.691590441947701],
              [-35.081756412777239, -7.691597553434863],
              [-35.081764380720095, -7.691604613022834],
              [-35.081784039031128, -7.691613510363862],
              [-35.08184164804527, -7.691645503176582],
              [-35.081901556691939, -7.691665913757746],
              [-35.081935614839516, -7.691682693111258],
              [-35.081959176300671, -7.691700076072641],
              [-35.081969701309276, -7.691712214193713],
              [-35.081978445396835, -7.691731093362596],
              [-35.081980811381229, -7.691748343693328],
              [-35.081975529126709, -7.691764270318489],
              [-35.081960255326784, -7.691783346484891],
              [-35.08193883774841, -7.691803410338459],
              [-35.081886095560122, -7.691840277891281],
              [-35.081875936257887, -7.691844741180906],
              [-35.081848382618361, -7.691848573885669],
              [-35.081834421428184, -7.69185167669138],
              [-35.081824021290842, -7.691858930154136],
              [-35.081813328462729, -7.691874067677626],
              [-35.081803504380041, -7.691907121953848],
              [-35.081796896280089, -7.691982908313518],
              [-35.081778267732076, -7.692015177757243],
              [-35.08174880795351, -7.692044974567008],
              [-35.081726768149885, -7.692060763695882],
              [-35.081700530035569, -7.692068170205097],
              [-35.081679227041967, -7.692060235668113],
              [-35.081654429862077, -7.692036211929805],
              [-35.081644512294282, -7.692018861967687],
              [-35.081628946706594, -7.691976581919501],
              [-35.081598863852435, -7.691940025953329],
              [-35.081590960527329, -7.691936084911955],
              [-35.081578026417716, -7.69193534149504],
              [-35.081567773146034, -7.691938426307716],
              [-35.081562751294733, -7.69194195994686],
              [-35.081559841093998, -7.691947085650042],
              [-35.08155662945348, -7.691973383412446],
              [-35.081555576425679, -7.692038861254161],
              [-35.081548833826091, -7.692087076602665],
              [-35.08153059378418, -7.692137607722117],
              [-35.08153059378418, -7.692137607722117],
              [-35.081443459002337, -7.692220474405083],
              [-35.081386084181688, -7.692255104428833],
              [-35.081355809654681, -7.692281846031479],
              [-35.081351737749685, -7.692291973070091],
              [-35.081354178775072, -7.692298357012344],
              [-35.081374765018168, -7.692312562680371],
              [-35.0813862192147, -7.692317739629816],
              [-35.08140115059647, -7.692318615626959],
              [-35.081433176960701, -7.692315614846256],
              [-35.081445070109602, -7.692317359196115],
              [-35.081453013202953, -7.692321403642806],
              [-35.081489144107017, -7.692347780289921],
              [-35.08152453025135, -7.692380810255945],
              [-35.081686205860315, -7.692549975546235],
              [-35.081760021935523, -7.692616241259291],
              [-35.081773592974663, -7.692635677487347],
              [-35.081787849771246, -7.692673509492788],
              [-35.081793298217676, -7.692699128095488],
              [-35.081791969129632, -7.692709282981414],
              [-35.081788073765559, -7.692719431050991],
              [-35.081777900577464, -7.69272936359953],
              [-35.081748398184963, -7.69274302223505],
              [-35.08173982885392, -7.692749406470493],
              [-35.081723589626101, -7.692763866323285],
              [-35.081717819657449, -7.692769964417989],
              [-35.081713956739819, -7.692777191405543],
              [-35.081712358372947, -7.69278398179417],
              [-35.081710304339246, -7.692845915019086],
              [-35.081706095744906, -7.692949171004878],
              [-35.081700313054228, -7.692981838640553],
              [-35.081685533156744, -7.693006583724926],
              [-35.08167708512287, -7.693014862666466],
              [-35.081668841812721, -7.693020688227303],
              [-35.081635045244525, -7.693041180374776],
              [-35.08161741776977, -7.693062290327704],
              [-35.081607540790181, -7.693068795146792],
              [-35.081600901548086, -7.693067871512131],
              [-35.081582179881273, -7.693052827235],
              [-35.081562166920911, -7.693040554418683],
              [-35.081554255341985, -7.693036419633961],
              [-35.081547120084394, -7.693035505818511],
              [-35.081543228953429, -7.693036947375693],
              [-35.081540699990285, -7.693039197529823],
              [-35.081536991932445, -7.693044754956218],
              [-35.081534658532156, -7.693053681721544],
              [-35.08153491298458, -7.693066863966871],
              [-35.081537590107921, -7.693089424126093],
              [-35.081536380930395, -7.693101232152677],
              [-35.081525868080007, -7.693142262356193],
              [-35.081515874310071, -7.693170125263581],
              [-35.081497472744196, -7.693207463969085],
              [-35.081475292468994, -7.693240862283327],
              [-35.081460936345941, -7.693260470062105],
              [-35.081426686866081, -7.693296664640237],
              [-35.081381811589424, -7.693334264259569],
              [-35.081242722361068, -7.693433578294198],
              [-35.081199518364137, -7.693467265153703],
              [-35.08112403973665, -7.693545217988504],
              [-35.081103207254046, -7.693563917024388],
              [-35.081079148931074, -7.693581287319281],
              [-35.081056010710078, -7.693593729488303],
              [-35.081033599790409, -7.693599339263955],
              [-35.081018284320855, -7.693598680740013],
              [-35.080996295157, -7.693592312052875],
              [-35.080963689715439, -7.693574070310298],
              [-35.080919117072405, -7.693537057880175],
              [-35.080879645241552, -7.693496894750745],
              [-35.080847488326853, -7.693454780803012],
              [-35.080818375372807, -7.69341069321727],
              [-35.080787368815287, -7.693354006474818],
              [-35.080783046430753, -7.693340868874876],
              [-35.080782420488234, -7.693329490483778],
              [-35.080783707415932, -7.693300935831515],
              [-35.080781940573608, -7.693289562976032],
              [-35.080778946078588, -7.69327990328858],
              [-35.080773192010639, -7.693271450609288],
              [-35.080767799991207, -7.693265635741583],
              [-35.080761261684749, -7.693260403560549],
              [-35.080753777477788, -7.693256171839919],
              [-35.080745349913606, -7.693253462214575],
              [-35.0807363115876, -7.693253278560364],
              [-35.080607584134171, -7.69327324192668],
              [-35.080464268772019, -7.693305330984462],
              [-35.080366231362184, -7.693330285936892],
              [-35.08021456685286, -7.69337441315932],
              [-35.080181058495491, -7.693388556273107],
              [-35.080159073164914, -7.693403458772669],
              [-35.08013886324423, -7.69342287263673],
              [-35.080124955812813, -7.693445640712349],
              [-35.080120611988853, -7.693471143831646],
              [-35.080127744112239, -7.693539993273116],
              [-35.080133569011686, -7.693574209089967],
              [-35.080131908513358, -7.693584650119873],
              [-35.080125656384006, -7.693595056389504],
              [-35.080115085900616, -7.693605407987124],
              [-35.08009667828064, -7.693613084897279],
              [-35.080054428853408, -7.693613662481925],
              [-35.079939422429845, -7.693611779531734],
              [-35.079842754365885, -7.693616677934689],
              [-35.079812420340481, -7.693617972895312],
              [-35.079796369261992, -7.69362146517581],
              [-35.079786030558893, -7.6936265421174],
              [-35.079780770308226, -7.693635329553636],
              [-35.079779749724239, -7.693644877719614],
              [-35.079783629467116, -7.693660603211857],
              [-35.079806308908339, -7.693706349718799],
              [-35.079831814035494, -7.693770499717355],
              [-35.07983269289609, -7.693778352774328],
              [-35.079828281212123, -7.693790040136896],
              [-35.079816463361851, -7.693803755172332],
              [-35.079799408053525, -7.693815788396953],
              [-35.079779160834121, -7.693825717687916],
              [-35.079743905011512, -7.693839863750167],
              [-35.079695488695045, -7.693854894423168],
              [-35.079643638573124, -7.693867665460902],
              [-35.079477996233493, -7.693893350318211],
              [-35.079451905016512, -7.693894962261036],
              [-35.079386868847642, -7.693894329176048],
              [-35.079355889986999, -7.693897704136558],
              [-35.079333152602608, -7.693905352567619],
              [-35.079266523262632, -7.693937767308392],
              [-35.079211379536375, -7.693965425600267],
              [-35.079183315736266, -7.693974037786712],
              [-35.079151615912906, -7.693974845678863],
              [-35.079094040555375, -7.693949813100594],
              [-35.079057269563812, -7.693940504615627],
              [-35.079031933072898, -7.693943153494543],
              [-35.078969913778046, -7.693973195469998],
              [-35.078933925676552, -7.693993267538577],
              [-35.078912102689628, -7.694002478494799],
              [-35.078896385939515, -7.69400329323271],
              [-35.078873468342778, -7.69399574208546],
              [-35.078811060124849, -7.693955433783722],
              [-35.078744253939036, -7.693914354866433],
              [-35.078715362961731, -7.693899955998694],
              [-35.078693610510626, -7.693898509328234],
              [-35.078683060426584, -7.693904854848846],
              [-35.078668404700082, -7.693932983162034],
              [-35.078666714585339, -7.693945433043099],
              [-35.078669544763841, -7.693960404887437],
              [-35.078679181670026, -7.693986914863045],
              [-35.078681659685444, -7.693997904915618],
              [-35.078680467133985, -7.694006826151783],
              [-35.07867322502058, -7.694010974199159],
              [-35.078604769038769, -7.694021882727773],
              [-35.078604769038769, -7.694021882727773],
              [-35.078595054376748, -7.694018133056773],
              [-35.07853734516808, -7.693965619723719],
              [-35.078511261234993, -7.693954912341091],
              [-35.078468317209818, -7.693952123248134],
              [-35.078434838139465, -7.693958886886469],
              [-35.078394744406488, -7.693973000443496],
              [-35.078368029754614, -7.69398481894314],
              [-35.078354193083705, -7.693993991165888],
              [-35.078341979562651, -7.694005347862138],
              [-35.07833689174619, -7.694014087382975],
              [-35.078335705654446, -7.694024336433951],
              [-35.078338634133566, -7.694039971724971],
              [-35.078346767607762, -7.694058117727128],
              [-35.078353797410067, -7.694068920906479],
              [-35.078372346916446, -7.694090360900244],
              [-35.078381426273587, -7.694103872481767],
              [-35.078389219705976, -7.69411892241569],
              [-35.078390692497855, -7.694128494652014],
              [-35.07838846957889, -7.694135930021321],
              [-35.078379556281497, -7.694145525948602],
              [-35.078369897381656, -7.694153539772039],
              [-35.078354077805564, -7.694163578039079],
              [-35.078308166477953, -7.694189021910682],
              [-35.078278249701611, -7.694205167549915],
              [-35.078276125489595, -7.69420691797376],
              [-35.078274570135285, -7.69420967603123],
              [-35.078274398929828, -7.69421356552287],
              [-35.078275804309513, -7.694219059740592],
              [-35.078281172888047, -7.694228138871396],
              [-35.078292213907766, -7.694244152279164],
              [-35.078326827679518, -7.694280686545329],
              [-35.078330812258052, -7.694288504463444],
              [-35.078333256588934, -7.694302340039768],
              [-35.078332360348341, -7.6943135361352],
              [-35.078324683820917, -7.694324269300215],
              [-35.078313619468709, -7.694330888822099],
              [-35.078299757841847, -7.694334939515957],
              [-35.078283315647383, -7.694333600114348],
              [-35.078224226722014, -7.694317095285767],
              [-35.078186650232219, -7.694301886541462],
              [-35.078137128605334, -7.694276639062763],
              [-35.078064454489052, -7.694238024187995],
              [-35.078002202106966, -7.694201623833242],
              [-35.077981924885819, -7.694190987076172],
              [-35.077972213322795, -7.694188188781095],
              [-35.077963078195829, -7.694187982363958],
              [-35.077928147705968, -7.694198550778478],
              [-35.077882663202779, -7.694218113355549],
              [-35.077806621052893, -7.694253580912017],
              [-35.077781574472461, -7.694264345157005],
              [-35.077755745995383, -7.694271373829674],
              [-35.077713529685923, -7.694271009316743],
              [-35.077680330726594, -7.694260134976227],
              [-35.077604259013761, -7.694214490403931],
              [-35.077497828781674, -7.694140598699342],
              [-35.077382831064938, -7.694057003092285],
              [-35.077366519108111, -7.694041457481663],
              [-35.07732576484517, -7.693989869316217],
              [-35.077309901971823, -7.693973443063828],
              [-35.07729284147338, -7.693963243122552],
              [-35.077168447769104, -7.693913222170853],
              [-35.077011749038952, -7.693854136932156],
              [-35.07698647432057, -7.693842226118075],
              [-35.076964164339351, -7.693829530036603],
              [-35.076905684050779, -7.693790286436349],
              [-35.076895859483351, -7.69378243813925],
              [-35.076870596148581, -7.693748985209402],
              [-35.076859594148303, -7.693735001372428],
              [-35.076846995717588, -7.693725103616548],
              [-35.076834122715475, -7.693721748845217],
              [-35.07681655910433, -7.693722690194431],
              [-35.076790345504548, -7.693728413027453],
              [-35.076721537677898, -7.693751945018311],
              [-35.076683167748641, -7.693762317666052],
              [-35.076674631495649, -7.693766579625272],
              [-35.076662789289806, -7.693775315340787],
              [-35.076654559258223, -7.69378389128426],
              [-35.076648518736732, -7.693793025696574],
              [-35.076645701543924, -7.693800247598933],
              [-35.076644878409034, -7.693806938187201],
              [-35.076645210823308, -7.693811749981767],
              [-35.076653604128339, -7.693836724825515],
              [-35.07666659301595, -7.693868245457103],
              [-35.076685231065255, -7.693907895448667],
              [-35.076694058325906, -7.693924355803627],
              [-35.076712781060493, -7.693952102268535],
              [-35.076720372341647, -7.693968568617213],
              [-35.076722371363523, -7.693979114134794],
              [-35.076721120499975, -7.694005080488324],
              [-35.076708829214709, -7.694064064554071],
              [-35.076693078344917, -7.694142064933512],
              [-35.076682524507198, -7.694162521166805],
              [-35.076663404170397, -7.694180065328742],
              [-35.076633424976336, -7.694192796577073],
              [-35.076562174609073, -7.694206791905563],
              [-35.076500077148147, -7.694220833299065],
              [-35.07648565065346, -7.694227779800043],
              [-35.07647657138871, -7.694237687693291],
              [-35.076470306753087, -7.694248458819731],
              [-35.076469956944031, -7.69425588263138],
              [-35.076473127670191, -7.694262696150006],
              [-35.076485049507532, -7.694280089982954],
              [-35.076488812738333, -7.69428968163682],
              [-35.076492674022013, -7.694322923207355],
              [-35.07648900101993, -7.694346993816015],
              [-35.076450177558613, -7.694413715632253],
              [-35.076417456274626, -7.694457398942024],
              [-35.076388268334441, -7.694485069200891],
              [-35.076368768296668, -7.694494814132567],
              [-35.076329823724265, -7.694502495509801],
              [-35.076310186750959, -7.694510578281379],
              [-35.076288485186339, -7.69452621736746],
              [-35.076264342071227, -7.694549762332039],
              [-35.076254863686863, -7.694562943097917],
              [-35.076238552192287, -7.694591096342484],
              [-35.076229982192359, -7.694602631448718],
              [-35.076213449652748, -7.694620003496966],
              [-35.076194418353218, -7.694636314225899],
              [-35.076170328701629, -7.694653363058662],
              [-35.076157355883133, -7.69465954113655],
              [-35.076148904530882, -7.694661716076379],
              [-35.076143834309057, -7.694662653513926],
              [-35.076136256114076, -7.694663531968926],
              [-35.076129341142241, -7.694663932969157],
              [-35.076110350915002, -7.694664262036216],
              [-35.076084111166409, -7.694664626203788],
              [-35.076069667037117, -7.694666213662884],
              [-35.076058179491326, -7.694669683725449],
              [-35.076048695450424, -7.694674566762991],
              [-35.076039347749891, -7.694683648202139],
              [-35.075998145673459, -7.694745771159377],
              [-35.075968442061956, -7.694787137021375],
              [-35.075965350154526, -7.694789875611563],
              [-35.07596140982303, -7.69479098541024],
              [-35.07595608234503, -7.694790347285047],
              [-35.075951107253267, -7.694788853840899],
              [-35.075928378613149, -7.694778056657099],
              [-35.075923140684147, -7.694776279953543],
              [-35.075918763276505, -7.694775494957693],
              [-35.075914914552158, -7.694775892969946],
              [-35.075910854321492, -7.694778195117821],
              [-35.07590771993587, -7.694782329876285],
              [-35.075906030492824, -7.694786843218808],
              [-35.07590468557747, -7.694793773452322],
              [-35.075903932351345, -7.694805063713872],
              [-35.07590297004274, -7.694812466320295],
              [-35.075900860952039, -7.694818665199421],
              [-35.07589721264187, -7.694824093573052],
              [-35.075871922108725, -7.694850531124596],
              [-35.075859797171731, -7.69485978983825],
              [-35.075850737501241, -7.694863959485027],
              [-35.075842135770905, -7.694865582275662],
              [-35.07583272156279, -7.694864236392691],
              [-35.075821191787888, -7.694859028292768],
              [-35.075807622369801, -7.694849658305187],
              [-35.075763731727186, -7.694806207961935],
              [-35.075725632411711, -7.694779631005629],
              [-35.075703996221051, -7.694768638825841],
              [-35.075683049894415, -7.694762935719512],
              [-35.075657187102216, -7.694762321084098],
              [-35.075603031279428, -7.694770265735172],
              [-35.075584127231657, -7.69477019453621],
              [-35.075496725486722, -7.69476130649662],
              [-35.07543461779899, -7.694754646385852],
              [-35.075427349248578, -7.694755276938084],
              [-35.075420805169948, -7.694758628210219],
              [-35.075415980748033, -7.694763678375542],
              [-35.075411461865137, -7.694772900262187],
              [-35.075407640336032, -7.694788663119009],
              [-35.07540154940348, -7.694826535962622],
              [-35.075396408806057, -7.694844771187551],
              [-35.075386649907806, -7.694860374500023],
              [-35.075327007249754, -7.694913365956616],
              [-35.075285817392185, -7.694959397749973],
              [-35.075270819781146, -7.694983474156618],
              [-35.075258400220058, -7.695023713930206],
              [-35.075258532962351, -7.695069636113177],
              [-35.075247236303063, -7.69511326323595],
              [-35.075239036397775, -7.695134242703086],
              [-35.075231850306992, -7.695142434201625],
              [-35.075224375211313, -7.695145465626381],
              [-35.075206762263768, -7.695145021615012],
              [-35.075159900378381, -7.695136556965033],
              [-35.075088549505942, -7.695129941554015],
              [-35.075076128955786, -7.695126305385673],
              [-35.075063210576864, -7.695118493082591],
              [-35.07505160726862, -7.695108498259811],
              [-35.075040081724367, -7.695095643604592],
              [-35.075019275551242, -7.695065912538761],
              [-35.07501181169517, -7.695058517560306],
              [-35.075005445656871, -7.69505449876207],
              [-35.074999096459855, -7.695053931661088],
              [-35.074989987158183, -7.695057674730963],
              [-35.074980218399425, -7.695066011160146],
              [-35.074966539335144, -7.695084724336035],
              [-35.074955035046877, -7.695105185524113],
              [-35.074939060961036, -7.695137354575053],
              [-35.074920697371915, -7.695162664844551],
              [-35.074902368567962, -7.695176494236653],
              [-35.074881751870961, -7.695185724336875],
              [-35.074873046151978, -7.695195577013233],
              [-35.074867987418031, -7.695206401984915],
              [-35.074867510931547, -7.695210873938749],
              [-35.074871175012213, -7.695221384062259],
              [-35.074873385738485, -7.695226305331644],
              [-35.074872641013691, -7.69523160086451],
              [-35.074868771301531, -7.695235432832226],
              [-35.074864502802463, -7.695237492670984],
              [-35.074852067457975, -7.69524158379043],
              [-35.074847425171264, -7.695245463584532],
              [-35.074842870869446, -7.695259316714955],
              [-35.074835321776952, -7.695268458697481],
              [-35.074826807530656, -7.695273135142124],
              [-35.074816136262271, -7.695272629896895],
              [-35.074800138601056, -7.695267585651903],
              [-35.074783738492215, -7.695257990773237],
              [-35.074764588093636, -7.6952435140723],
              [-35.074712028370165, -7.695194980945978],
              [-35.0746291104618, -7.695114763451566],
              [-35.074524475400466, -7.695008010571116],
              [-35.074476548406309, -7.694956445601695],
              [-35.07444552837584, -7.694922060039395],
              [-35.074402115487551, -7.694873094018932],
              [-35.074387690050436, -7.694854608266568],
              [-35.074378930862643, -7.69483876404939],
              [-35.074377223726387, -7.694833460914989],
              [-35.074377140682749, -7.694826158209429],
              [-35.07437893679343, -7.694814246398315],
              [-35.074379560825811, -7.694805707279553],
              [-35.074378832062216, -7.694799802423754],
              [-35.074375284693609, -7.694786640288119],
              [-35.074361140744621, -7.694742415827415],
              [-35.074343996641083, -7.694698994921024],
              [-35.074328833994876, -7.694668260604217],
              [-35.074319820784709, -7.694652654694274],
              [-35.074304605074765, -7.694630301209561],
              [-35.074268070774828, -7.694588315192251],
              [-35.074244794813765, -7.694572493735744],
              [-35.074219291784587, -7.694566349604034],
              [-35.074192962352406, -7.694569424874755],
              [-35.074138820467631, -7.694584999537484],
              [-35.074072145603921, -7.694608193088286],
              [-35.074048324033427, -7.694623048369961],
              [-35.074036971173314, -7.694634674453461],
              [-35.074028577101053, -7.694649625928858],
              [-35.074026252387014, -7.694659386154068],
              [-35.074027642460322, -7.694671519671822],
              [-35.074033410453026, -7.694693175027625],
              [-35.074036580585641, -7.694701020837319],
              [-35.074049473637793, -7.694722630689989],
              [-35.074051916261652, -7.694734398006862],
              [-35.074045694469348, -7.694747119138254],
              [-35.074036610986084, -7.69475617341292],
              [-35.074024422530421, -7.694764362777641],
              [-35.074012802260981, -7.694767670044552],
              [-35.073985026960379, -7.694765101301917],
              [-35.073976133686799, -7.694766856337697],
              [-35.073951814708899, -7.694778492913931],
              [-35.073926702123657, -7.694795591739186],
              [-35.073909558961965, -7.694810660437517],
              [-35.073905647314028, -7.694816180229788],
              [-35.073900350432467, -7.694831618268489],
              [-35.073895827854116, -7.694838208252262],
              [-35.073883984133886, -7.694848532526178],
              [-35.073870613231627, -7.69485575803818],
              [-35.07386434536653, -7.69485725846495],
              [-35.073859125799828, -7.694856736840167],
              [-35.073850297924643, -7.694852441861944],
              [-35.073833742525764, -7.694836158163829],
              [-35.073832091000952, -7.694832991724652],
              [-35.073830766687777, -7.694824604288853],
              [-35.073829460804951, -7.694820010598315],
              [-35.073826766301515, -7.694817840839247],
              [-35.073815839128777, -7.694814765122991],
              [-35.073804281879269, -7.694813682875647],
              [-35.073780709524364, -7.694813453233023],
              [-35.073776087723417, -7.694812965635012],
              [-35.073759242463183, -7.694809585238028],
              [-35.073753490368944, -7.694809613058216],
              [-35.073748220690817, -7.694811084943715],
              [-35.073744594171053, -7.694813189081155],
              [-35.073741663788311, -7.694815902063195],
              [-35.073737691542824, -7.694821023522619],
              [-35.073735722152705, -7.694826628938977],
              [-35.073735301074272, -7.694832914498586],
              [-35.073736376647474, -7.694839026837411],
              [-35.073742721810383, -7.694858320944048],
              [-35.073744083800356, -7.694866333194689],
              [-35.07374640838885, -7.694883247511918],
              [-35.073746062430779, -7.694890315186854],
              [-35.073744228788144, -7.694896164278076],
              [-35.073740368878141, -7.694902388111624],
              [-35.073733032302179, -7.694908967947591],
              [-35.073721363078704, -7.694914193475069],
              [-35.073709060197736, -7.694916197305226],
              [-35.073666615477123, -7.694917777849875],
              [-35.073657685942003, -7.694919385993909],
              [-35.073650016690095, -7.694921750949842],
              [-35.073560391313499, -7.694958796140091],
              [-35.07348918539666, -7.694982011536162],
              [-35.073475550931448, -7.694985236089394],
              [-35.073466990410211, -7.694984518720805],
              [-35.073459269740468, -7.694979805824216],
              [-35.073451228669079, -7.694969324791764],
              [-35.073445130970029, -7.694956929501887],
              [-35.073435863558288, -7.694928141241929],
              [-35.073431420240837, -7.694917926537906],
              [-35.073399062211145, -7.694860679417248],
              [-35.07338327422169, -7.694842702521546],
              [-35.073366768471558, -7.694830926632375],
              [-35.073356435914938, -7.69482751472576],
              [-35.07334832951836, -7.69482648258405],
              [-35.07333366937214, -7.694828478429399],
              [-35.073304604970751, -7.694835824352349],
              [-35.073277116405094, -7.694842077707586],
              [-35.073266280739965, -7.694842746599179],
              [-35.073256327074496, -7.694840577765805],
              [-35.073245134940144, -7.694834834391197],
              [-35.073221768913719, -7.694815433040948],
              [-35.07319398989226, -7.694797143423948],
              [-35.073171047216633, -7.694783496112878],
              [-35.073160248218016, -7.694779854970441],
              [-35.073147786819341, -7.694778587391338],
              [-35.073127266883411, -7.694781357411174],
              [-35.073101247999404, -7.694788771202121],
              [-35.07307832702184, -7.694797086166106],
              [-35.073051817572853, -7.69481044530857],
              [-35.073020350973238, -7.694830894421645],
              [-35.072970131356506, -7.694876046644579],
              [-35.072959645430927, -7.694889992224715],
              [-35.072956869033099, -7.694895838659471],
              [-35.072955845539724, -7.694900443625986],
              [-35.072955374040198, -7.69490613663762],
              [-35.072956483202738, -7.694924057112743],
              [-35.07295682233871, -7.69493515241416],
              [-35.072954685822246, -7.694945500917454],
              [-35.072948495169619, -7.694954070986589],
              [-35.072939828473025, -7.694959894444051],
              [-35.072925219021897, -7.694966604278941],
              [-35.072904803967987, -7.694970282912383],
              [-35.072764569785498, -7.694980000811716],
              [-35.07272744327787, -7.694983494487372],
              [-35.072704703605751, -7.694987070175217],
              [-35.072690592027293, -7.694991544821581],
              [-35.072672761040586, -7.695000546515781],
              [-35.072631501723109, -7.695030859473708],
              [-35.072622756875361, -7.695036236812342],
              [-35.07261063627687, -7.695042487744918],
              [-35.072548392149095, -7.695067705671831],
              [-35.072491128863888, -7.695084287560862],
              [-35.07241807773508, -7.695095082060456],
              [-35.07227497740535, -7.695098801842541],
              [-35.072170514415149, -7.695099313677389],
              [-35.072115324230971, -7.69509009583509],
              [-35.072067778231713, -7.695072050610183],
              [-35.072000560624005, -7.695030518266686],
              [-35.07184646359849, -7.694919079445203],
              [-35.071714959785261, -7.694823168246852],
              [-35.071687546021671, -7.694792071097183],
              [-35.071664283420589, -7.694739828645258],
              [-35.071654630696464, -7.694678910996861],
              [-35.07165659660582, -7.694598536375049],
              [-35.071667413656122, -7.694562072600572],
              [-35.071665685448409, -7.694548049561483],
              [-35.07165796451293, -7.694534992376104],
              [-35.071636389848841, -7.694517075922325],
              [-35.071598733187756, -7.694495489347008],
              [-35.07154657365701, -7.694465060150717],
              [-35.07148115986724, -7.694431692937526],
              [-35.071356788621621, -7.694386280419633],
              [-35.071308456505456, -7.694361202037894],
              [-35.071297816952566, -7.694360508839148],
              [-35.071286423488736, -7.694363788632883],
              [-35.071276181992921, -7.694369339157043],
              [-35.071261124303177, -7.694381646992998],
              [-35.071239514386498, -7.69440275722673],
              [-35.071227067758599, -7.694416999283878],
              [-35.071215175195725, -7.69443498257744],
              [-35.071204912731226, -7.694455803375165],
              [-35.071186127726669, -7.694503316989735],
              [-35.071177860476482, -7.694523843617553],
              [-35.071164186311215, -7.694547579473291],
              [-35.071143867446004, -7.694572284307079],
              [-35.071116040641044, -7.694598254947303],
              [-35.071103145422747, -7.694605582180439],
              [-35.071085487989713, -7.69461116852264],
              [-35.071065515579932, -7.694609937150369],
              [-35.071054850492018, -7.694606574220716],
              [-35.071044734059136, -7.694600768957144],
              [-35.071020865321934, -7.694578284631591],
              [-35.071000951411285, -7.694549927084461],
              [-35.070989810266056, -7.694526838523642],
              [-35.070986835868901, -7.69450920271246],
              [-35.070981916867353, -7.69441060061402],
              [-35.070984050364082, -7.694378337855517],
              [-35.070981784604584, -7.694364678305701],
              [-35.070977664411757, -7.694355510858661],
              [-35.070969825837352, -7.694346822905065],
              [-35.070960190478388, -7.694340135392407],
              [-35.070945994241278, -7.694334038980979],
              [-35.070901103111765, -7.694321405010378],
              [-35.070827431067208, -7.694303319307918],
              [-35.070805593314212, -7.69430095194585],
              [-35.07078366169906, -7.694303440285291],
              [-35.070776525845723, -7.694306403690542],
              [-35.070770098418478, -7.694314212080696],
              [-35.070758420383683, -7.694337221157658],
              [-35.070753613395233, -7.694345875337768],
              [-35.070747104245982, -7.694351799771726],
              [-35.070732005113065, -7.694360206565759],
              [-35.070710552915557, -7.694367518438805],
              [-35.070698379502048, -7.694366818457527],
              [-35.07068961650743, -7.694363467728532],
              [-35.070660885307944, -7.694340347852076],
              [-35.070634172963402, -7.694310851747201],
              [-35.070578698107106, -7.694233104838226],
              [-35.070560538959022, -7.694214782335107],
              [-35.07054640557773, -7.694207948902394],
              [-35.070535420493272, -7.694211109790966],
              [-35.070521025090521, -7.694222750494895],
              [-35.070490684264193, -7.694259697173274],
              [-35.070473054804395, -7.694274746738042],
              [-35.070428498497812, -7.69429793576188],
              [-35.070282394759715, -7.694369502029063],
              [-35.070199802364733, -7.694417993348011],
              [-35.070175265841748, -7.694427010587096],
              [-35.0701330938154, -7.694434316131219],
              [-35.07012538129085, -7.694433511249008],
              [-35.070118617089541, -7.694430698525267],
              [-35.07010155338299, -7.694421486014368],
              [-35.070095601932273, -7.694419617829469],
              [-35.070090087054247, -7.694419549606654],
              [-35.070086004540158, -7.694420754887984],
              [-35.070082472295319, -7.694422989378396],
              [-35.070067967784176, -7.694436349266095],
              [-35.070057007523872, -7.694450534205275],
              [-35.070047779785966, -7.694469073688799],
              [-35.070041545579215, -7.694487314187973],
              [-35.07002997198208, -7.69452873911214],
              [-35.070000364930067, -7.694627507959367],
              [-35.069993036671519, -7.694659788416271],
              [-35.06998749619958, -7.69467404203796],
              [-35.069979891213748, -7.694684920117807],
              [-35.069958846877981, -7.694706902156327],
              [-35.069948225039411, -7.69471245450046],
              [-35.069938611632764, -7.694712600864668],
              [-35.069904402606163, -7.694694645336146],
              [-35.069855608950057, -7.694657857823399],
              [-35.069802464234201, -7.694612100987971],
              [-35.069781978887136, -7.694594510025476],
              [-35.069770439940378, -7.694587404868049],
              [-35.069763108731898, -7.694585306220905],
              [-35.069756398047502, -7.694587101875493],
              [-35.069746900635394, -7.694595675228898],
              [-35.069728925871935, -7.694621586321309],
              [-35.06969877296779, -7.694670361541256],
              [-35.069685479165294, -7.694688239723178],
              [-35.069675897033441, -7.694697787567385],
              [-35.069660738224357, -7.694708862863129],
              [-35.069616548174317, -7.694732145040336],
              [-35.069578246479367, -7.694755545809486],
              [-35.069566709453042, -7.694758636562054],
              [-35.069558149143148, -7.694757966543983],
              [-35.069552217551298, -7.69475297625256],
              [-35.069535180903998, -7.694727157646764],
              [-35.069494365934538, -7.694652279145441],
              [-35.069461940124818, -7.694599642289035],
              [-35.069429593544193, -7.69454465465632],
              [-35.069422420438613, -7.694531631760225],
              [-35.069416161835484, -7.69451544332135],
              [-35.06941483552923, -7.694506629054853],
              [-35.069415366656607, -7.694498517169321],
              [-35.069417919508943, -7.694491917638638],
              [-35.069424003791326, -7.694485623872379],
              [-35.069430402800933, -7.694481751730017],
              [-35.069446439149232, -7.694475177388587],
              [-35.069452793149608, -7.694471827114025],
              [-35.069456865384929, -7.694468858580553],
              [-35.06948086982316, -7.694448312104968],
              [-35.06948843542289, -7.694439929150835],
              [-35.069493569319938, -7.694431979204008],
              [-35.069494838040278, -7.694426999214233],
              [-35.069493285088541, -7.694420462348916],
              [-35.069489734148391, -7.694413650575766],
              [-35.069483514185173, -7.694405429011602],
              [-35.069472730186092, -7.694395377548693],
              [-35.069451917045249, -7.694379404047657],
              [-35.069429986991786, -7.694366610841462],
              [-35.069410931305313, -7.694358356375492],
              [-35.069385161281971, -7.69435204676984],
              [-35.069311533941885, -7.694343181333912],
              [-35.069228663025321, -7.694334270091504],
              [-35.06919669856606, -7.694334350917737],
              [-35.069138972131391, -7.694339222881163],
              [-35.069126473672483, -7.694342182614556],
              [-35.069099522652081, -7.694352745709899],
              [-35.069086116820827, -7.694353128270085],
              [-35.069076397547903, -7.694348399538398],
              [-35.06905432275677, -7.694325363665538],
              [-35.069046893022559, -7.694318875285647],
              [-35.069018930424896, -7.694300969350384],
              [-35.068996193929308, -7.694291548036071],
              [-35.068981037714018, -7.69429033975158],
              [-35.068945537467421, -7.694291702056119],
              [-35.06891856307152, -7.69428864942565],
              [-35.068907713367381, -7.694286425475946],
              [-35.068897192830207, -7.694282714554496],
              [-35.068879884928556, -7.694272901934816],
              [-35.068861189318476, -7.694260472477029],
              [-35.068824628949187, -7.694230487877597],
              [-35.068802705276042, -7.694214195521342],
              [-35.068778458550021, -7.694199032090209],
              [-35.068756548549487, -7.694189196711114],
              [-35.068739799730018, -7.694187268021839],
              [-35.068723270283435, -7.694190193140349],
              [-35.068703670880375, -7.694198491778066],
              [-35.068648540691527, -7.694229819869729],
              [-35.068593561718842, -7.694272337074484],
              [-35.068551982394304, -7.694316785160971],
              [-35.068504200954202, -7.694382784516371],
              [-35.068478383942754, -7.694432852237005],
              [-35.068458576294518, -7.694486240998575],
              [-35.068444706412684, -7.694546450426533],
              [-35.068442889225331, -7.69459642343634],
              [-35.068452284622033, -7.69466517223989],
              [-35.068457082895804, -7.694720292873606],
              [-35.068455508369048, -7.694734952018184],
              [-35.068447791197855, -7.694751302747776],
              [-35.068436541902166, -7.694763874192745],
              [-35.068409168387511, -7.694786202674119],
              [-35.068363124020841, -7.694816931354024],
              [-35.068296190609949, -7.694859589345956],
              [-35.068225420054873, -7.694900040092077],
              [-35.068101177225309, -7.694956134728863],
              [-35.067978156944704, -7.694997701539593],
              [-35.067825627519532, -7.695036844690139],
              [-35.067724774659872, -7.69505696930173],
              [-35.067625592879736, -7.695067370072997],
              [-35.067425414074933, -7.695068154564793],
              [-35.067259769991537, -7.695089293187656],
              [-35.067046681920147, -7.695455716201684],
              [-35.066993027568159, -7.695547911394157],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0134000',
        uf: 'PE',
        nome_proje: 'PA AGUA BRANCA',
        municipio: 'QUIPAPA',
        area_hecta: '1296.5000',
        capacidade: 133.0,
        num_famili: 133.0,
        fase: 3.0,
        data_de_cr: '30/12/1997',
        forma_obte: 'Desapropriação',
        data_obten: '22/10/1997',
        area_calc_: 1581.2739,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.062942937293961, -8.825587151277444],
              [-36.062769521127919, -8.82590465507251],
              [-36.062675014415241, -8.826053639795047],
              [-36.062558581622717, -8.826215179356204],
              [-36.062501621474318, -8.82627986354292],
              [-36.062410664152196, -8.826360751184207],
              [-36.062328183862917, -8.826416626286598],
              [-36.062276399955856, -8.826445417035352],
              [-36.06219445890234, -8.826480709186278],
              [-36.0620876614339, -8.826518426797108],
              [-36.061941675653273, -8.826562575023544],
              [-36.061825529993023, -8.826592733094659],
              [-36.061637230046514, -8.826632286033666],
              [-36.061488571571893, -8.826659341039591],
              [-36.061426780211782, -8.826668776199444],
              [-36.061156525816244, -8.826699598186593],
              [-36.061049544659703, -8.826712983773579],
              [-36.060873943954519, -8.826742675646482],
              [-36.060612342835803, -8.826797520929295],
              [-36.060322018557237, -8.826867861958277],
              [-36.060147783644261, -8.826915156767033],
              [-36.059932754377037, -8.826981595869665],
              [-36.059735716165378, -8.827057159320647],
              [-36.059572532822472, -8.827134485308836],
              [-36.05944701683287, -8.82720764769045],
              [-36.059377023024695, -8.827254603489216],
              [-36.059308871218335, -8.827306846955164],
              [-36.059222879159321, -8.827378414677806],
              [-36.059094311572899, -8.827503923569857],
              [-36.058940976004088, -8.827668166456592],
              [-36.058743408188434, -8.827905427216413],
              [-36.058611026287643, -8.828084806623183],
              [-36.058509565997632, -8.828256193658696],
              [-36.058444307385258, -8.828418132521508],
              [-36.058418533934073, -8.828536212456994],
              [-36.058406360630094, -8.828647661474816],
              [-36.058416468453885, -8.828796716322026],
              [-36.058457268357493, -8.829061673857227],
              [-36.05850581979653, -8.829298244619782],
              [-36.058552967685273, -8.829497294453834],
              [-36.058617696957008, -8.829711612394375],
              [-36.058696474136049, -8.829933073563863],
              [-36.059009186203127, -8.830682228131005],
              [-36.059051952098585, -8.830758596728934],
              [-36.059124828660501, -8.83086996434527],
              [-36.059185651754568, -8.830982734650842],
              [-36.059210000949754, -8.831044312592914],
              [-36.059228880118937, -8.831131300766151],
              [-36.059268223022983, -8.83136205719512],
              [-36.059026276118061, -8.831338116785968],
              [-36.058789907973491, -8.831355143017829],
              [-36.058565246612261, -8.83133901664873],
              [-36.058319515809103, -8.831299779036955],
              [-36.057828039108699, -8.831246146121714],
              [-36.057574891233166, -8.831226001438996],
              [-36.057349295131345, -8.831236155301331],
              [-36.057102118325012, -8.831264749181168],
              [-36.057000796397503, -8.831282924691187],
              [-36.056711407814376, -8.831308838130951],
              [-36.056417817937124, -8.83126904291476],
              [-36.056507175826532, -8.831694060285841],
              [-36.056755719508288, -8.833141142776716],
              [-36.056696638389376, -8.833295607852556],
              [-36.056684014084809, -8.833387111060063],
              [-36.05668858955304, -8.833577307668239],
              [-36.056712639148707, -8.833785634414005],
              [-36.056715026159075, -8.833885385890579],
              [-36.056707982352549, -8.833983256545643],
              [-36.056650347316626, -8.834184347128785],
              [-36.056475837690378, -8.834474857315167],
              [-36.056380959774067, -8.834539245486267],
              [-36.056178822200934, -8.834648863249951],
              [-36.056101340993898, -8.834729919755823],
              [-36.055837507776033, -8.835185858434961],
              [-36.055735505874701, -8.835380999136419],
              [-36.055693995860985, -8.83551861839787],
              [-36.055674222057092, -8.835583957729471],
              [-36.055601495730407, -8.835729552392152],
              [-36.05527667687808, -8.836236322685515],
              [-36.055192139327964, -8.836359510980081],
              [-36.055163898883798, -8.83648449678711],
              [-36.055152874535985, -8.83659173107511],
              [-36.055112767360086, -8.836769922701484],
              [-36.055083062054663, -8.836827776216021],
              [-36.054901675640281, -8.836954629641431],
              [-36.054252264330856, -8.837299039654074],
              [-36.053546667458107, -8.837673178931704],
              [-36.052046803725212, -8.838355621149972],
              [-36.051504710718945, -8.838521549962463],
              [-36.051305318005092, -8.838582584113119],
              [-36.051181979716091, -8.838627956161533],
              [-36.050887951881847, -8.838802521540602],
              [-36.050718104036818, -8.838962887745692],
              [-36.050620356725794, -8.839090848504496],
              [-36.050493969369128, -8.839176938272745],
              [-36.050312062033079, -8.839243439305998],
              [-36.050128428270519, -8.839344254714442],
              [-36.050052322322998, -8.839389185221197],
              [-36.049090730186116, -8.840076663532775],
              [-36.049094169816414, -8.840078136021225],
              [-36.049109907858536, -8.840097772814193],
              [-36.049129952329885, -8.840169703495643],
              [-36.049140797221085, -8.840233521702729],
              [-36.049147743920585, -8.84026221326358],
              [-36.049159458561924, -8.840285251166309],
              [-36.04918472061658, -8.840317881189209],
              [-36.049208444501851, -8.84034977640788],
              [-36.049251681302373, -8.840406306687816],
              [-36.049284625653549, -8.840443694764001],
              [-36.049346995678995, -8.840509816002704],
              [-36.049383630559454, -8.840551388658662],
              [-36.049401915627996, -8.84057623989465],
              [-36.049416561428131, -8.84060187549586],
              [-36.04943579623334, -8.84065573240216],
              [-36.049456926624778, -8.840773788700155],
              [-36.049468513422084, -8.840824288052515],
              [-36.049482139798286, -8.840858181455809],
              [-36.049500261675142, -8.840886419051078],
              [-36.049529992680213, -8.840916735520878],
              [-36.049629542236026, -8.840995977539917],
              [-36.049655000757582, -8.841026757195658],
              [-36.049670187642704, -8.841058585141706],
              [-36.049676565713654, -8.841090253358416],
              [-36.049673461284087, -8.841137926858345],
              [-36.049660692447695, -8.841161720407685],
              [-36.049626532710839, -8.841191398013331],
              [-36.049582792183777, -8.841220909789895],
              [-36.049512043593701, -8.841254544943435],
              [-36.049487326347169, -8.841267539405477],
              [-36.04946900728531, -8.84130967279458],
              [-36.049491223859192, -8.841473809552175],
              [-36.049505564030689, -8.84152071712103],
              [-36.049517825242106, -8.841554961130203],
              [-36.049020310903821, -8.841277681064811],
              [-36.04838571483868, -8.840923979152878],
              [-36.047784497892572, -8.840701437832687],
              [-36.047673291651023, -8.840660361257441],
              [-36.046029823350523, -8.840578655393271],
              [-36.045107477883931, -8.839988436458947],
              [-36.044472290613349, -8.842043732282647],
              [-36.044213979805903, -8.842340075162644],
              [-36.044177606456792, -8.842402391299245],
              [-36.044123910281478, -8.842472971985512],
              [-36.044010354475951, -8.842521489630812],
              [-36.043880506606364, -8.842551540281313],
              [-36.043738827657052, -8.842573186504374],
              [-36.043625954472539, -8.842581690303108],
              [-36.043443900314685, -8.842620813904395],
              [-36.043367192823524, -8.842649928920025],
              [-36.042958907866364, -8.842721232272917],
              [-36.04293554614312, -8.842928460404918],
              [-36.043395623350023, -8.843036522835387],
              [-36.043470694569088, -8.843064487626666],
              [-36.043771487548618, -8.843107017371713],
              [-36.043912559324617, -8.843155783776604],
              [-36.04435879681958, -8.843211340689788],
              [-36.044531568223853, -8.843242877007917],
              [-36.044680952780467, -8.843223098113459],
              [-36.044776875337419, -8.84323315994282],
              [-36.044932070976976, -8.843236372325006],
              [-36.045045296953276, -8.843240608435462],
              [-36.045133521483436, -8.843259281749528],
              [-36.045174046686384, -8.843245780071472],
              [-36.045279994750253, -8.843205964713526],
              [-36.045338305599998, -8.843217265293482],
              [-36.045364474046444, -8.843221627298256],
              [-36.045390642503172, -8.843225989301487],
              [-36.045472919792175, -8.843238743714114],
              [-36.045559896670738, -8.843254425969979],
              [-36.045755090389321, -8.843367170748033],
              [-36.045837958278149, -8.843442533116542],
              [-36.045981729180632, -8.84354583839124],
              [-36.046058249188327, -8.843642741127345],
              [-36.046182691032257, -8.843846438679716],
              [-36.046776482125694, -8.844818269109686],
              [-36.047188987319643, -8.845231833179412],
              [-36.047994212602489, -8.846276951429095],
              [-36.047969878796067, -8.846359526798132],
              [-36.047916535459109, -8.846505817179267],
              [-36.047871623070186, -8.846650387594655],
              [-36.047829932080354, -8.846795909372229],
              [-36.047785820474722, -8.846962937947096],
              [-36.047734552964258, -8.847165015404849],
              [-36.047712832002375, -8.847294643037438],
              [-36.047688518625307, -8.847387645460374],
              [-36.047645589698021, -8.847499985137231],
              [-36.047553206662705, -8.847687095514233],
              [-36.047411555596632, -8.847901835072447],
              [-36.047273360468616, -8.848083425092231],
              [-36.047008487264691, -8.848511573218437],
              [-36.046954745594881, -8.848605700435991],
              [-36.046915841449426, -8.848684326702747],
              [-36.046875633250423, -8.848797122280907],
              [-36.046837263615672, -8.848930903202861],
              [-36.046808746810228, -8.849046738553509],
              [-36.046775065546612, -8.849162978434892],
              [-36.046739877990611, -8.84926471966001],
              [-36.046716459167605, -8.849360315024052],
              [-36.046699331993196, -8.849474107668666],
              [-36.046670972502426, -8.849602389571411],
              [-36.046606118481598, -8.849871239335176],
              [-36.046498638164941, -8.850279155026847],
              [-36.046418251777546, -8.850564510918906],
              [-36.04635910641354, -8.850741223907761],
              [-36.046330821728681, -8.850820576923121],
              [-36.04629317858771, -8.850950796570247],
              [-36.046254519109119, -8.851122389812097],
              [-36.046223583061398, -8.851312036125211],
              [-36.046196471656067, -8.851442609005385],
              [-36.046169102157194, -8.851547699073203],
              [-36.046110579994419, -8.851707002174381],
              [-36.046083607222315, -8.851773337114148],
              [-36.046063380425991, -8.851842005144912],
              [-36.046019341273436, -8.852038455468938],
              [-36.045974427603099, -8.852216999699873],
              [-36.045913281947435, -8.852452357730028],
              [-36.045901694142181, -8.852503717008675],
              [-36.045882627246314, -8.852611867238227],
              [-36.045831459791934, -8.852810356474238],
              [-36.045745374148417, -8.853061647631788],
              [-36.045615003573332, -8.853459123499675],
              [-36.04560008546359, -8.853505942435612],
              [-36.045588501884041, -8.853551075623667],
              [-36.045570566866452, -8.853662954389886],
              [-36.045506274146931, -8.853919407730649],
              [-36.045410068971556, -8.854216855494682],
              [-36.045294712229641, -8.854601461341643],
              [-36.045268824205365, -8.854718520704726],
              [-36.045249436966152, -8.854848481796559],
              [-36.045214919190961, -8.855086063896218],
              [-36.045220168492364, -8.855457297687447],
              [-36.04521894854701, -8.855579839651481],
              [-36.045204261741127, -8.855693805406151],
              [-36.045191157111311, -8.855875031444397],
              [-36.045175291528587, -8.856024879426364],
              [-36.045119480688676, -8.856301970015753],
              [-36.045083704742432, -8.85656002750466],
              [-36.045055880731212, -8.856812713126688],
              [-36.045038974720939, -8.857023040741897],
              [-36.045083473533516, -8.856989877595637],
              [-36.045686586025617, -8.856375115371936],
              [-36.046032881622118, -8.856129425762976],
              [-36.046347353716612, -8.855933350160672],
              [-36.046807686993425, -8.855602017789773],
              [-36.047059304733843, -8.855485573588986],
              [-36.047317815470862, -8.855393122843829],
              [-36.047609569055894, -8.855232459054202],
              [-36.047693336283089, -8.855195450014067],
              [-36.047782114729856, -8.855110963335974],
              [-36.047980510338206, -8.854971242865046],
              [-36.048173661144546, -8.854817207601203],
              [-36.048361144012233, -8.854713264426568],
              [-36.048465814102784, -8.854651938844329],
              [-36.049379999085467, -8.854083616771415],
              [-36.04942432709592, -8.854037623654406],
              [-36.049497699659483, -8.85397144354137],
              [-36.049861333966902, -8.853578365272122],
              [-36.050173009159977, -8.853235298407462],
              [-36.050284783529037, -8.852825765030403],
              [-36.050281416990671, -8.852268268536271],
              [-36.050266810171522, -8.85194718578928],
              [-36.050289966571825, -8.851514834583661],
              [-36.050357176656547, -8.851122082180993],
              [-36.050458897832279, -8.850376474199654],
              [-36.050753346979668, -8.850002270567543],
              [-36.050806199012357, -8.849935115051418],
              [-36.050958487869025, -8.849699451598124],
              [-36.051198887790214, -8.849417145802134],
              [-36.051347048521066, -8.84910674068111],
              [-36.051418819712737, -8.848956352901116],
              [-36.051542233240205, -8.848548549768312],
              [-36.051937713747208, -8.847987600406432],
              [-36.052226573214462, -8.84755065642965],
              [-36.052532138433044, -8.847296957171499],
              [-36.052866451441005, -8.847094434141754],
              [-36.053031827813307, -8.846994287672448],
              [-36.053531912637993, -8.846778795857476],
              [-36.053644891644637, -8.846734426315454],
              [-36.05381261172667, -8.846796272689478],
              [-36.053870106415324, -8.846807565771474],
              [-36.053964476616628, -8.846807495610429],
              [-36.055301194812209, -8.846543480023387],
              [-36.055590326537363, -8.846383490604193],
              [-36.055777702547353, -8.846299494633307],
              [-36.056239850643266, -8.8460923108263],
              [-36.056346244685436, -8.846085219050558],
              [-36.056550082801088, -8.846102543134924],
              [-36.056579805790996, -8.846103525509125],
              [-36.056612981091519, -8.846096300207996],
              [-36.056667337175121, -8.846075766325951],
              [-36.056857320193693, -8.845989671823006],
              [-36.057111974409331, -8.845863660222422],
              [-36.057448364787696, -8.84568139587452],
              [-36.057637314211505, -8.845571993717016],
              [-36.057727773824666, -8.845613222066314],
              [-36.057876426044871, -8.845660804985306],
              [-36.057995032534627, -8.845700665505408],
              [-36.058098190377983, -8.845725245485646],
              [-36.058365825903039, -8.845782933523042],
              [-36.058437977482299, -8.845796227117322],
              [-36.058589519875888, -8.845811641830117],
              [-36.058990666982361, -8.845815542675838],
              [-36.059151647110745, -8.845827288226843],
              [-36.059278053732442, -8.845832773110937],
              [-36.059441806651499, -8.845874485179531],
              [-36.059554428134589, -8.845909806515991],
              [-36.059659404430342, -8.845960040201033],
              [-36.059858156787712, -8.846049190197954],
              [-36.059954256126247, -8.846079889934385],
              [-36.060183322823505, -8.846114656884714],
              [-36.060253171648768, -8.846129317740456],
              [-36.060309699193219, -8.846152303623422],
              [-36.060377139095451, -8.846190264393231],
              [-36.060449776142683, -8.846237249425574],
              [-36.060590689003575, -8.846311721054985],
              [-36.060872740749168, -8.846432218340018],
              [-36.060916209365352, -8.846455766973182],
              [-36.060951345523101, -8.846485987775733],
              [-36.060996523419675, -8.846531259862761],
              [-36.061031911119095, -8.846577203692412],
              [-36.061071494777295, -8.846616068567144],
              [-36.061136478908601, -8.846631551299229],
              [-36.061212205146568, -8.846644988631237],
              [-36.061259637839598, -8.846666954992653],
              [-36.061317281825794, -8.846706335975353],
              [-36.061467723894552, -8.846813072960593],
              [-36.06169059687776, -8.846973477962013],
              [-36.061866609682284, -8.847102178508299],
              [-36.062131665058033, -8.847277939804227],
              [-36.062457292372649, -8.847503585570673],
              [-36.062611623103251, -8.847594631748947],
              [-36.062773242737627, -8.84771568001721],
              [-36.062946205521051, -8.847830079669874],
              [-36.063074567522825, -8.847921296219971],
              [-36.063168420992739, -8.847998018070633],
              [-36.063184427547228, -8.848020228088435],
              [-36.063192869110956, -8.848047244782174],
              [-36.063190289780671, -8.848078517873434],
              [-36.063286239422403, -8.848137438054255],
              [-36.063396965053478, -8.848222152844681],
              [-36.063718105377554, -8.848515513243356],
              [-36.063748772161929, -8.848539897007297],
              [-36.063781323527266, -8.848563921244498],
              [-36.063809411004463, -8.848581359987651],
              [-36.063998909321292, -8.848675114821217],
              [-36.064060540973983, -8.848710035111598],
              [-36.064146973279605, -8.848761619711787],
              [-36.064247477578967, -8.848818367920273],
              [-36.064320181084248, -8.848857118175735],
              [-36.064390459288326, -8.848893229199112],
              [-36.064433961165058, -8.848912660418142],
              [-36.064493609226218, -8.848936710044708],
              [-36.064540151366401, -8.848952421987821],
              [-36.064582604440027, -8.848961738530919],
              [-36.064623602684925, -8.848964680350463],
              [-36.064677437424251, -8.848962482501761],
              [-36.064776782415294, -8.848951801109648],
              [-36.064928440456924, -8.848956504966843],
              [-36.065456195751892, -8.84897293946918],
              [-36.066391132782243, -8.848888216149584],
              [-36.066654246538874, -8.849772884486123],
              [-36.066723083916585, -8.850289978800376],
              [-36.066771691174615, -8.850654965446775],
              [-36.066940439486743, -8.852134765966914],
              [-36.066919947781237, -8.853298885846517],
              [-36.065834848844062, -8.85370529781523],
              [-36.065002417474744, -8.853916062882089],
              [-36.064897871628233, -8.853942580991799],
              [-36.06462076595102, -8.854054954777281],
              [-36.064245226834849, -8.854301340051698],
              [-36.063320237946321, -8.854612970449061],
              [-36.062102081654636, -8.855023382941738],
              [-36.061248392830024, -8.858015260075129],
              [-36.063478583290298, -8.860791259433547],
              [-36.063617072508215, -8.861092179707246],
              [-36.06323791691424, -8.862306956632253],
              [-36.062551315041873, -8.862890201344053],
              [-36.06154941576483, -8.863441333324301],
              [-36.061422241874027, -8.863716586214085],
              [-36.061212926909818, -8.865194493070524],
              [-36.06067157238224, -8.866004176910097],
              [-36.061719241012305, -8.867876272257742],
              [-36.06188438925264, -8.868171440974827],
              [-36.061757155295631, -8.868453920056096],
              [-36.060809231871687, -8.869388140511214],
              [-36.060893105379741, -8.869450482142339],
              [-36.060962640066307, -8.86950942202261],
              [-36.061087952705854, -8.869653395222761],
              [-36.06119233331156, -8.869779236709185],
              [-36.061243708401562, -8.869850011562717],
              [-36.061311285079121, -8.869965831239824],
              [-36.061363126931226, -8.870072543599017],
              [-36.061397764310279, -8.87016564533825],
              [-36.061432187234445, -8.870285695767079],
              [-36.061460188522474, -8.870408690135211],
              [-36.061592533151575, -8.871277010150127],
              [-36.061628334360911, -8.871602193805955],
              [-36.06163412544884, -8.871725761971705],
              [-36.061630782993802, -8.871861984804598],
              [-36.061615337920344, -8.872005598572327],
              [-36.061581691273474, -8.8721662873252],
              [-36.061543026019635, -8.872295494439225],
              [-36.061477114973485, -8.87244320256791],
              [-36.06141375988804, -8.872553499709682],
              [-36.06114910661644, -8.872892787081598],
              [-36.061085165518598, -8.872982118096791],
              [-36.061029426484374, -8.873081245760664],
              [-36.060976573007352, -8.87319611718898],
              [-36.060938151725715, -8.873294632588832],
              [-36.060910160785696, -8.873406705307108],
              [-36.060887528058657, -8.873507590907392],
              [-36.060875260098555, -8.873630268152938],
              [-36.060876768671299, -8.873818932560004],
              [-36.060918074459074, -8.874019888378577],
              [-36.061005175222462, -8.87423617733009],
              [-36.06110914526213, -8.874413670851849],
              [-36.061183723009272, -8.874501098319405],
              [-36.061330082410933, -8.874648980850429],
              [-36.061531783594809, -8.874842216493832],
              [-36.061648190499902, -8.874970398931005],
              [-36.061707063228241, -8.875044976110978],
              [-36.061837300586824, -8.875232408556217],
              [-36.061895045210022, -8.875354140020475],
              [-36.061946196123465, -8.875547687245145],
              [-36.061976557680431, -8.875705136925896],
              [-36.061982177344078, -8.875944740156118],
              [-36.06196017536795, -8.876250004563346],
              [-36.062067378195763, -8.876777878598567],
              [-36.062113187111393, -8.876886040925093],
              [-36.06223979866764, -8.877056226631142],
              [-36.062315385766468, -8.877137326369407],
              [-36.062569689643112, -8.877345782257141],
              [-36.062679155050731, -8.877412235379621],
              [-36.062854882389082, -8.877489942636615],
              [-36.062939020555135, -8.877516120696578],
              [-36.06312669365434, -8.877547525490861],
              [-36.063313419051319, -8.877554611323543],
              [-36.063420659983308, -8.877544798062255],
              [-36.063686070367417, -8.877491522377094],
              [-36.064059659538025, -8.877388386706583],
              [-36.064218102475721, -8.877343780737815],
              [-36.06437158958262, -8.877297054755795],
              [-36.064476802461314, -8.877260050574218],
              [-36.06462123221737, -8.877190610948636],
              [-36.064760717404226, -8.877107666958945],
              [-36.064940884281981, -8.876973602748],
              [-36.065120157416239, -8.876815985660983],
              [-36.065248538376643, -8.876698948897459],
              [-36.065375714423773, -8.876595433909449],
              [-36.065464301175453, -8.876532565418136],
              [-36.065564311753512, -8.876476795664921],
              [-36.065670146537201, -8.876429019555136],
              [-36.065779756334145, -8.876388181946297],
              [-36.065993004218655, -8.876335574247372],
              [-36.066234259264078, -8.876316139190093],
              [-36.066531387984298, -8.876310580946351],
              [-36.066645802529003, -8.876307036137559],
              [-36.066760417156971, -8.87629987271983],
              [-36.066930419828495, -8.87627937806873],
              [-36.067021698070221, -8.876255435336272],
              [-36.067121221849476, -8.876218963197863],
              [-36.067218870509741, -8.876173713695856],
              [-36.067397365985343, -8.876067513613993],
              [-36.067664395486176, -8.875880803458257],
              [-36.067851787995103, -8.875759541529662],
              [-36.067954886904722, -8.875706175313734],
              [-36.068065130562523, -8.875659778072864],
              [-36.068184787259163, -8.875619782635079],
              [-36.068296709623951, -8.875589169502216],
              [-36.068410421076138, -8.875566124507763],
              [-36.068527000672461, -8.875551298362309],
              [-36.068650451457501, -8.875549105964661],
              [-36.068776356028721, -8.875559186890062],
              [-36.068895855806417, -8.875577515934559],
              [-36.06901313761275, -8.875605689454201],
              [-36.069124107515613, -8.875641456958226],
              [-36.069229362987471, -8.87568449931266],
              [-36.069651553452886, -8.875906952112345],
              [-36.069734681354227, -8.875955928998085],
              [-36.070011523386476, -8.876086437070896],
              [-36.070208352034726, -8.876143930610118],
              [-36.070307248753473, -8.876159243434154],
              [-36.070408904627861, -8.876165834161766],
              [-36.070514679105067, -8.876167953089025],
              [-36.070621980716581, -8.876157705822347],
              [-36.070720628982485, -8.876141230773198],
              [-36.070825401277872, -8.876116289180272],
              [-36.070924403327268, -8.876082515419155],
              [-36.071116785156747, -8.875992212538675],
              [-36.071311573745206, -8.875878031925833],
              [-36.071733959507782, -8.87560927448618],
              [-36.071991039360221, -8.875450115623796],
              [-36.072166298696821, -8.875356628791057],
              [-36.072368626843598, -8.875271506782164],
              [-36.072479783047548, -8.875236092872312],
              [-36.072601229273502, -8.875205210931952],
              [-36.07272364388259, -8.875186890999867],
              [-36.072848023672549, -8.875179962634585],
              [-36.072973505957165, -8.875179545973824],
              [-36.073097081800221, -8.875187549904442],
              [-36.073223099806626, -8.875203999529459],
              [-36.073779437606483, -8.875304675988206],
              [-36.074235594464881, -8.875383784039807],
              [-36.074352914825674, -8.87541149286716],
              [-36.074582376031074, -8.875484978633406],
              [-36.07469200633583, -8.875533160948784],
              [-36.074799668546213, -8.875587756749121],
              [-36.074903648061635, -8.875649089926906],
              [-36.075003219155711, -8.87572206318657],
              [-36.075094591241154, -8.87580366654586],
              [-36.075226937974115, -8.875936016590042],
              [-36.075396283192333, -8.876115358603808],
              [-36.075514242675659, -8.876235719539945],
              [-36.075598985950819, -8.876310241230799],
              [-36.075687834914177, -8.876377411871198],
              [-36.075778569072561, -8.876436641909299],
              [-36.076077473196953, -8.876590060503279],
              [-36.07630124866094, -8.876624291493361],
              [-36.076482275574449, -8.876613908594551],
              [-36.076595286084903, -8.876601965260441],
              [-36.076826527848098, -8.876567974894932],
              [-36.0769325821514, -8.876537008130295],
              [-36.077035084614181, -8.876499332889722],
              [-36.077131926401883, -8.876448982565631],
              [-36.07722948193107, -8.876389759340203],
              [-36.077325217614195, -8.876323516948871],
              [-36.077458309761006, -8.876210084736886],
              [-36.077594947893445, -8.876084301358251],
              [-36.077902419437876, -8.875883146231068],
              [-36.078297968629727, -8.87558843367434],
              [-36.078430475235521, -8.875517679854442],
              [-36.078604535750401, -8.875435967652813],
              [-36.078764954745004, -8.875380348115632],
              [-36.079239644932315, -8.875235359140932],
              [-36.076331043967187, -8.873506977941304],
              [-36.074922392082136, -8.870947117369091],
              [-36.073858236070585, -8.869013114525165],
              [-36.074591305240105, -8.867105030037065],
              [-36.076428029741223, -8.865708770316717],
              [-36.076652170483712, -8.865460917985358],
              [-36.076991991357893, -8.865172414760901],
              [-36.077231849575988, -8.864912579412191],
              [-36.077323825577359, -8.864710758829284],
              [-36.077329866692395, -8.864601586123621],
              [-36.07731689180175, -8.864484947155983],
              [-36.077259499932829, -8.864264883737921],
              [-36.077098725198418, -8.863955930811086],
              [-36.077039954978936, -8.863895808514599],
              [-36.076974380072208, -8.863843104223569],
              [-36.076899197858737, -8.863796714007043],
              [-36.076811039122092, -8.86376046415932],
              [-36.076729473912827, -8.863747099155468],
              [-36.07667490454287, -8.863746672127105],
              [-36.076641484267192, -8.863737052706021],
              [-36.076605162837886, -8.863713560888593],
              [-36.076577553505558, -8.863683025246589],
              [-36.076562713104394, -8.863655958381601],
              [-36.076549312779584, -8.863613368678877],
              [-36.076545698788415, -8.86357066840109],
              [-36.076550137611001, -8.86353289724717],
              [-36.076564494530935, -8.863478359493142],
              [-36.076582881628937, -8.863437702965852],
              [-36.076616941466717, -8.863366475186966],
              [-36.076645471670069, -8.863280605810456],
              [-36.0766750683973, -8.863155067302436],
              [-36.07670139319481, -8.86296736675855],
              [-36.076702577529041, -8.862817649707246],
              [-36.07668378952679, -8.862718683326092],
              [-36.076645414124542, -8.8626218095784],
              [-36.076586674959088, -8.862530016916727],
              [-36.076513984625599, -8.862441483932292],
              [-36.076431779249887, -8.862366351847324],
              [-36.076324382907373, -8.862288028112575],
              [-36.076206410623797, -8.862214487706623],
              [-36.076073378930168, -8.862141578058669],
              [-36.075975462526195, -8.862101882960209],
              [-36.075873679121869, -8.862075258622767],
              [-36.075774041227127, -8.862062875044169],
              [-36.075682226497648, -8.862060659189819],
              [-36.075625467511529, -8.862051605695568],
              [-36.075572169618304, -8.862033221396212],
              [-36.075523252944407, -8.862008133704824],
              [-36.075480231712625, -8.861975231531225],
              [-36.075408943467025, -8.86189981046217],
              [-36.075346488741161, -8.861801999599692],
              [-36.075311948208409, -8.86169617230121],
              [-36.075257250751214, -8.861426611414116],
              [-36.075225387717019, -8.861290904047458],
              [-36.075748553651898, -8.861053380453884],
              [-36.07630645355205, -8.860800085925367],
              [-36.077475540467141, -8.860269129121301],
              [-36.07826404341759, -8.85991130160515],
              [-36.081441561566976, -8.858468610863401],
              [-36.081821467219761, -8.858647106637301],
              [-36.081984938766951, -8.859456108216257],
              [-36.082123018069986, -8.860139850959367],
              [-36.082142996743059, -8.8601613495481],
              [-36.082155552948471, -8.860191767307416],
              [-36.082183327468357, -8.860296793089885],
              [-36.082244044811567, -8.86051961181426],
              [-36.082272837919831, -8.860590956971844],
              [-36.082475820116748, -8.86079994656331],
              [-36.082520882726641, -8.860848186599689],
              [-36.082673889061567, -8.861014792512321],
              [-36.082732045773064, -8.861083481817595],
              [-36.082813098052362, -8.861188094175189],
              [-36.082889792531887, -8.861294063369657],
              [-36.082969640741858, -8.861429628298854],
              [-36.083064548235207, -8.861612287665308],
              [-36.083128150398018, -8.861755773599366],
              [-36.083173818209247, -8.861882649551909],
              [-36.083237864135647, -8.862112980712865],
              [-36.083266401098605, -8.862359878985814],
              [-36.083281940380772, -8.86258459104347],
              [-36.083277369876605, -8.86278219522279],
              [-36.083253194109588, -8.862984138149779],
              [-36.083221550520612, -8.863178536402422],
              [-36.083172417851358, -8.863395257159652],
              [-36.083117961999108, -8.863571135684037],
              [-36.082985095199462, -8.86387591872813],
              [-36.082963074925622, -8.865111862716811],
              [-36.082962190701721, -8.865166330552029],
              [-36.083026997257925, -8.865177371763416],
              [-36.083102741772564, -8.865196969232082],
              [-36.083152060769308, -8.865220481316083],
              [-36.083174565123571, -8.865234840366565],
              [-36.083206295964679, -8.865259841202596],
              [-36.083256364003333, -8.865309286567927],
              [-36.083324042881408, -8.865394643930314],
              [-36.083411512063947, -8.865527493765047],
              [-36.083502604273917, -8.86568467323101],
              [-36.083618438212753, -8.865894984900999],
              [-36.083698845884946, -8.866030625295597],
              [-36.083737910376954, -8.866087437744604],
              [-36.083781804141516, -8.866142345601418],
              [-36.083830346584449, -8.866195211954786],
              [-36.083884360888163, -8.866245320490137],
              [-36.083962607541238, -8.866309530538969],
              [-36.084159694624255, -8.866450644088303],
              [-36.084467603314316, -8.86666615925351],
              [-36.084582129826622, -8.866754502138804],
              [-36.084780019641094, -8.866914818949299],
              [-36.084951016698732, -8.867047101159843],
              [-36.085090151642881, -8.867159485731944],
              [-36.085161454359259, -8.867228881155983],
              [-36.085226069542465, -8.86730545154697],
              [-36.085285914568509, -8.86738794712406],
              [-36.085385062569543, -8.867537375005613],
              [-36.085456505052861, -8.867640874747742],
              [-36.085528058675983, -8.867731199847109],
              [-36.085583045728455, -8.867789567864248],
              [-36.085618666741716, -8.867822368189806],
              [-36.0856563939384, -8.867853016799129],
              [-36.085716427926627, -8.867894318918253],
              [-36.085860408853243, -8.867979729609665],
              [-36.08595520361883, -8.868041990789555],
              [-36.0860207007497, -8.868098919127432],
              [-36.08606410676358, -8.868146641075974],
              [-36.086114604181141, -8.868216596781823],
              [-36.086186944312288, -8.868350276965058],
              [-36.086266156007831, -8.86853405902332],
              [-36.08636853009898, -8.868758223048435],
              [-36.086471801560421, -8.868972276023722],
              [-36.086757141377944, -8.869498654215564],
              [-36.086982098741316, -8.869883992484191],
              [-36.087086401874956, -8.87004217834791],
              [-36.08724549085705, -8.870256441039272],
              [-36.087370084950997, -8.870410268229596],
              [-36.087498979893823, -8.870548590500057],
              [-36.087612310257668, -8.870644873625595],
              [-36.087776258449111, -8.870749862904576],
              [-36.08798064443431, -8.870852863934529],
              [-36.08809114791773, -8.87091601534612],
              [-36.088169703718869, -8.87097580079439],
              [-36.088237082763065, -8.871047604599591],
              [-36.088281876933372, -8.871109430347301],
              [-36.088337303095649, -8.871199525078319],
              [-36.088416854026711, -8.871346225283268],
              [-36.088545403473937, -8.871574523304316],
              [-36.088610200960147, -8.87167422201],
              [-36.088663405998858, -8.87174650244388],
              [-36.088719717096872, -8.871816548615048],
              [-36.088778682259644, -8.871884085959785],
              [-36.088957901346859, -8.872080030951947],
              [-36.089087959733845, -8.872232229293806],
              [-36.089189648703595, -8.872365009118644],
              [-36.08926679094396, -8.872477406514431],
              [-36.089370095453056, -8.872647238329264],
              [-36.089484476115253, -8.872852389138238],
              [-36.089506754534945, -8.872901256145964],
              [-36.089524849744585, -8.872950722915096],
              [-36.089544409146754, -8.873016100947027],
              [-36.089560792980961, -8.873087560180114],
              [-36.089576398622768, -8.873163966017993],
              [-36.089599893826374, -8.873329245640511],
              [-36.089618922226066, -8.873461968024479],
              [-36.089636609797481, -8.873553830626014],
              [-36.089685106237702, -8.873581733022492],
              [-36.089699979049499, -8.873584830237744],
              [-36.089722878230873, -8.873664417708952],
              [-36.089771237483916, -8.873792580825349],
              [-36.089840241501449, -8.873952162612277],
              [-36.089899478140687, -8.874094774634381],
              [-36.089958352911431, -8.874237203168001],
              [-36.090012568358688, -8.874342691451364],
              [-36.090079412398858, -8.874430616744325],
              [-36.090142379164021, -8.874491861477612],
              [-36.090313318196671, -8.874632182697187],
              [-36.090392859323202, -8.874705391214183],
              [-36.09055977395586, -8.874895322884047],
              [-36.090732472519299, -8.875123603827539],
              [-36.090820581596674, -8.875204648367776],
              [-36.090914175046542, -8.875246799075841],
              [-36.091077118924709, -8.875300963659024],
              [-36.091180776606151, -8.87534549646117],
              [-36.091293615343027, -8.875406497547203],
              [-36.091306366276704, -8.87539069708596],
              [-36.091504246902943, -8.875143984982847],
              [-36.093608712451775, -8.872520820523578],
              [-36.094503031774167, -8.871406068710444],
              [-36.096314642545693, -8.869333233759328],
              [-36.096897541066646, -8.869160723560071],
              [-36.096854207873456, -8.869138162821367],
              [-36.096795431273968, -8.869127316599966],
              [-36.096712864226056, -8.869103818383707],
              [-36.096651271297667, -8.869075695196495],
              [-36.096609566020454, -8.86904826870739],
              [-36.096555792167017, -8.868996130552258],
              [-36.096489189504389, -8.868900438822543],
              [-36.096401327340232, -8.868735922930814],
              [-36.096287695273837, -8.868521700007998],
              [-36.096179268654041, -8.868309730918286],
              [-36.096116807520296, -8.868160996327116],
              [-36.096075192557841, -8.868029678907122],
              [-36.096045092504284, -8.867895289123213],
              [-36.096013987180356, -8.8677270138247],
              [-36.09597140100346, -8.867517047430768],
              [-36.095936129692177, -8.867381849551046],
              [-36.09592169563895, -8.867311407203033],
              [-36.095914594060297, -8.867232620201555],
              [-36.095915764176318, -8.867031802307659],
              [-36.095912861629714, -8.866961946514584],
              [-36.095889878498184, -8.8667946378094],
              [-36.095884641708516, -8.866721285750117],
              [-36.095888964088743, -8.866645207467501],
              [-36.095908575433214, -8.866553935359454],
              [-36.095943678919738, -8.866467842779915],
              [-36.096053760861267, -8.866295877316148],
              [-36.096100954579626, -8.866208613952834],
              [-36.096136519216913, -8.866121621513489],
              [-36.096228945658723, -8.865849873030692],
              [-36.096244571564739, -8.865780251603775],
              [-36.096246849987217, -8.865733066376116],
              [-36.096229888667622, -8.865568108163226],
              [-36.096226237058474, -8.865483746864953],
              [-36.096230506046943, -8.865408661884118],
              [-36.096240434427713, -8.865347397827939],
              [-36.096272536121084, -8.865232914883766],
              [-36.096330131563413, -8.86509347027086],
              [-36.09642484554562, -8.864911989621165],
              [-36.096643597055532, -8.864534378038329],
              [-36.096674020325636, -8.864479701330712],
              [-36.09672565145862, -8.864391120240073],
              [-36.096807417405905, -8.864273680559389],
              [-36.096881486856077, -8.864187800250308],
              [-36.097197168450982, -8.863860239143184],
              [-36.097377824584598, -8.863665513337894],
              [-36.097603503429433, -8.863405369163821],
              [-36.097906724712843, -8.863027616314255],
              [-36.09836837338726, -8.862451621124427],
              [-36.098553354467349, -8.862226483080624],
              [-36.098721494402518, -8.862017565791],
              [-36.098801331817818, -8.861902639871632],
              [-36.098858394554462, -8.861796119456958],
              [-36.098904893202949, -8.861672986012348],
              [-36.098951998437748, -8.861501160848274],
              [-36.098982435756746, -8.861396014632151],
              [-36.099035207938215, -8.861269043818062],
              [-36.099090851667711, -8.861169694396976],
              [-36.099109707361194, -8.861145584277672],
              [-36.099160470766279, -8.861095161652271],
              [-36.099395755824574, -8.860906911003957],
              [-36.099522794749632, -8.860807618761307],
              [-36.099717634273773, -8.86064556889511],
              [-36.099777119873181, -8.860589070661987],
              [-36.099834214386654, -8.86052473936776],
              [-36.099884106720658, -8.860452176321907],
              [-36.099951706219521, -8.860328211208698],
              [-36.100032094675619, -8.860160304135562],
              [-36.10013169696682, -8.859905231655553],
              [-36.100170133616324, -8.859758725970092],
              [-36.100194016440732, -8.859620599388917],
              [-36.100222841780173, -8.859315561191847],
              [-36.100268752009697, -8.858268234076469],
              [-36.10028225061842, -8.858099039960583],
              [-36.100302549688493, -8.857937171034278],
              [-36.100336499629343, -8.857754629580347],
              [-36.100377133118656, -8.85759431864054],
              [-36.10040549368496, -8.857504830102375],
              [-36.100434385185963, -8.857428716130995],
              [-36.100466204470898, -8.857356166474656],
              [-36.10051979300394, -8.857251858678827],
              [-36.100647488168704, -8.857022299029877],
              [-36.100723640141162, -8.856867412870956],
              [-36.100757523227713, -8.856774443788648],
              [-36.100770151015418, -8.856704019085303],
              [-36.100765507387401, -8.856624991459768],
              [-36.100741276323767, -8.856494802941421],
              [-36.100698557625051, -8.856324450034549],
              [-36.100679441083749, -8.856225468810635],
              [-36.100670071982854, -8.856134468007305],
              [-36.100666971737262, -8.855973772619224],
              [-36.100669098943719, -8.855829876016845],
              [-36.100691334994259, -8.855496148346003],
              [-36.100708075988067, -8.855278240306591],
              [-36.100725393678033, -8.855073662019551],
              [-36.100727150484111, -8.854959620209675],
              [-36.100708922115068, -8.854724140733001],
              [-36.100674757303977, -8.854482258998576],
              [-36.100620416996456, -8.854253320287716],
              [-36.100547814009055, -8.854001880615485],
              [-36.100472897090647, -8.853790715070348],
              [-36.100366671288121, -8.853506401668625],
              [-36.100246721334898, -8.853130466535154],
              [-36.100174140954167, -8.852899353815619],
              [-36.100105436655895, -8.8527473646021],
              [-36.100056566605609, -8.852672318944007],
              [-36.09998212331093, -8.852586188924647],
              [-36.099819915404019, -8.852433158538988],
              [-36.099629820937203, -8.852233928379805],
              [-36.099514952637328, -8.852090479788295],
              [-36.099376774326714, -8.851884786263442],
              [-36.099113456308217, -8.85140339583435],
              [-36.09901846665462, -8.851215382670432],
              [-36.098906180207891, -8.850951213577883],
              [-36.098851425271214, -8.850781083735352],
              [-36.098800726811319, -8.850528007324117],
              [-36.098797731250016, -8.850319206488113],
              [-36.098811349271422, -8.850169436696259],
              [-36.098853208061833, -8.849904791683628],
              [-36.098896480167674, -8.849679997908341],
              [-36.098968638677228, -8.849304287874009],
              [-36.098996793370866, -8.849061308666222],
              [-36.099014407818295, -8.848691318855302],
              [-36.099022455374865, -8.848348266766331],
              [-36.099035820546966, -8.847975714431263],
              [-36.099099515446163, -8.847741050994246],
              [-36.099148226705829, -8.847610253682026],
              [-36.099133499871883, -8.847333606199065],
              [-36.099049690779673, -8.847355991127442],
              [-36.098970661204149, -8.847355378514655],
              [-36.098909036731492, -8.847342922473361],
              [-36.09884899385878, -8.847320746279367],
              [-36.098787645120311, -8.847273105988121],
              [-36.09872091044484, -8.847240396854373],
              [-36.09868107214416, -8.847233350198959],
              [-36.098547145222405, -8.84722632274555],
              [-36.098502843770426, -8.847212503653466],
              [-36.098457953932105, -8.847177717916127],
              [-36.098420796076923, -8.847116789507044],
              [-36.098380692114787, -8.847047603156346],
              [-36.098331567972245, -8.846976849574572],
              [-36.098313580367396, -8.846966977714962],
              [-36.098289107139252, -8.846968285261436],
              [-36.098260400400065, -8.846981538316301],
              [-36.098243292423902, -8.847003490717377],
              [-36.098237715759524, -8.84704275138515],
              [-36.098243783747748, -8.847108866432757],
              [-36.098240657220053, -8.847123627943981],
              [-36.098231018243901, -8.847128980892531],
              [-36.098206412588191, -8.847123175263889],
              [-36.098179069921507, -8.847130449712939],
              [-36.098134480987568, -8.84715331199998],
              [-36.098104374529868, -8.847153078556124],
              [-36.098073621022365, -8.847139364475165],
              [-36.098030283517332, -8.847098601567907],
              [-36.097942979877011, -8.847049262632712],
              [-36.097919444159288, -8.847026995084361],
              [-36.097892635887213, -8.846990103566183],
              [-36.097868673961834, -8.846974196198227],
              [-36.097842351360669, -8.846971371821077],
              [-36.097807305682856, -8.846977089225014],
              [-36.097697782515596, -8.847025650502578],
              [-36.097641938875782, -8.847043933574003],
              [-36.097607669326813, -8.847046662390788],
              [-36.097568251456437, -8.847034004046815],
              [-36.097550193462006, -8.847033115362903],
              [-36.097528304669353, -8.847040806376947],
              [-36.097429851291608, -8.84711752761744],
              [-36.097395055745736, -8.847139342784427],
              [-36.097358434290861, -8.847154031659313],
              [-36.097309455560705, -8.847160763906226],
              [-36.097274836088516, -8.847160121074294],
              [-36.097187382922506, -8.847129871278174],
              [-36.097157067442296, -8.847108299756105],
              [-36.097133906641901, -8.847086222238078],
              [-36.097101435904626, -8.847051532705587],
              [-36.097093180113049, -8.847048474092155],
              [-36.097084505440229, -8.847050839903376],
              [-36.097081043627789, -8.847060358273826],
              [-36.097086525702615, -8.847081175694658],
              [-36.097100451723144, -8.847105053197934],
              [-36.097103002397787, -8.847115741173422],
              [-36.097100275631554, -8.847127511187598],
              [-36.09709022510404, -8.847137352774691],
              [-36.09707476033612, -8.847141724689005],
              [-36.097026347577177, -8.847148274129788],
              [-36.096969054361104, -8.847159433707509],
              [-36.096933689571777, -8.847157849254227],
              [-36.096876985494561, -8.847141875012486],
              [-36.096703890835833, -8.847114329671989],
              [-36.096654332644235, -8.847098972322193],
              [-36.096625513727098, -8.847078535343876],
              [-36.096576408202871, -8.847053449111653],
              [-36.096551570409083, -8.847053256421015],
              [-36.096534738884678, -8.847087937814296],
              [-36.096504495084645, -8.847153209594518],
              [-36.09648892757847, -8.847170681968207],
              [-36.096466309603436, -8.847175372680379],
              [-36.096242923921132, -8.847057973939902],
              [-36.096207054574904, -8.847024755267494],
              [-36.096182657157662, -8.846968417637994],
              [-36.096146098655609, -8.846831132039332],
              [-36.096144140120089, -8.846792935969471],
              [-36.096151454780454, -8.846772030678121],
              [-36.09616357366739, -8.846762392327546],
              [-36.096275301934156, -8.84672058645104],
              [-36.09629573529417, -8.846706520733177],
              [-36.096312081865918, -8.846685685502381],
              [-36.096319802202565, -8.846661040129479],
              [-36.096322700709081, -8.8466273736107],
              [-36.0963186664641, -8.846589910081351],
              [-36.096308203792169, -8.846556327065668],
              [-36.096276550135549, -8.846489452123306],
              [-36.096275344240887, -8.846451261889401],
              [-36.096281629704961, -8.846417621649302],
              [-36.096298070188553, -8.846384808837024],
              [-36.096330348795668, -8.846348001345127],
              [-36.096423977276366, -8.84626263356677],
              [-36.096448860733211, -8.846161010919236],
              [-36.096435899918788, -8.846086045903334],
              [-36.096407498497655, -8.846060371661631],
              [-36.096370682818439, -8.846051850971085],
              [-36.096317214739493, -8.846055179402494],
              [-36.096269644633203, -8.846074275121603],
              [-36.096233141310577, -8.846121905186784],
              [-36.096219952772763, -8.846171962055955],
              [-36.096233259910314, -8.846202759714332],
              [-36.096227039035156, -8.846228165370459],
              [-36.09619290521448, -8.846261589553869],
              [-36.096078484642668, -8.846358774254341],
              [-36.096053558841071, -8.846369810535048],
              [-36.095986595704851, -8.846366296401346],
              [-36.095951908822656, -8.846374262354667],
              [-36.095931857682018, -8.846387582371763],
              [-36.095913667638975, -8.846403537083965],
              [-36.095899693545228, -8.846409792136676],
              [-36.095877469562907, -8.846412239952157],
              [-36.095839830760639, -8.846412696546102],
              [-36.095818318337763, -8.846420390389808],
              [-36.095793721874621, -8.846437418346731],
              [-36.095773253298141, -8.84645597563242],
              [-36.095738084374752, -8.846477413411984],
              [-36.09567433498102, -8.846496009160042],
              [-36.095595305826507, -8.846495395894157],
              [-36.095510752128057, -8.846479392534938],
              [-36.095349741940467, -8.846470656580856],
              [-36.095196164407099, -8.846473956534538],
              [-36.095082630479929, -8.846458102488123],
              [-36.094997103501207, -8.846422252413941],
              [-36.094940277411098, -8.84637389814578],
              [-36.094880558163794, -8.846310548546265],
              [-36.09482490848567, -8.846304127450356],
              [-36.094776896986673, -8.846283541409106],
              [-36.094622966384705, -8.846235930654695],
              [-36.09458463960604, -8.846228146704515],
              [-36.094536104951899, -8.846226272660328],
              [-36.094495402715665, -8.846233443135212],
              [-36.09426706002634, -8.84631626718139],
              [-36.094202249252362, -8.846326245019457],
              [-36.094047966256163, -8.846323549905501],
              [-36.093881055579139, -8.846299420344605],
              [-36.093835922601336, -8.846295701009364],
              [-36.093812161018292, -8.846302254298312],
              [-36.093775469132702, -8.846325925971403],
              [-36.093750572687135, -8.846333219082231],
              [-36.093676788796571, -8.846335640726698],
              [-36.093641825506516, -8.846330877376012],
              [-36.093576511823684, -8.846309033875858],
              [-36.093522795289793, -8.84629607697506],
              [-36.093485962117533, -8.846289801803346],
              [-36.093464126258041, -8.846290755199306],
              [-36.093447140067511, -8.846297173914003],
              [-36.093431991436816, -8.846309221722203],
              [-36.09336000431913, -8.846394382278966],
              [-36.093341064481777, -8.846409956683512],
              [-36.093283221758306, -8.846443196396674],
              [-36.093241537655167, -8.846479556152865],
              [-36.0932057925123, -8.846526443026454],
              [-36.093143275315171, -8.846627772873463],
              [-36.093109056021653, -8.846672051331431],
              [-36.09307494546907, -8.846702480744073],
              [-36.093047355916816, -8.846717239300345],
              [-36.092969335392866, -8.846731980476884],
              [-36.09293403691359, -8.846721973949778],
              [-36.092890941335, -8.846698431285979],
              [-36.092818709629718, -8.846646962536486],
              [-36.092790537898971, -8.846640005932302],
              [-36.092756277305924, -8.846641611410808],
              [-36.092649923417554, -8.846669982301334],
              [-36.092590275406046, -8.846693475496291],
              [-36.092489566513095, -8.846769803293796],
              [-36.092364072809765, -8.846839200717238],
              [-36.092170251895681, -8.846887105231431],
              [-36.09208892083597, -8.846892088129184],
              [-36.092003885288307, -8.84688955580665],
              [-36.091841381951781, -8.846927222482243],
              [-36.0917130892039, -8.846969646781739],
              [-36.091544658632237, -8.84704320204651],
              [-36.091509236701746, -8.847048915901308],
              [-36.091481435440343, -8.847042710713325],
              [-36.091329281122675, -8.847056501029657],
              [-36.091251663361113, -8.847067876041205],
              [-36.091183053962645, -8.847082315614012],
              [-36.09112306481142, -8.847101314029034],
              [-36.091061876104305, -8.847129286793495],
              [-36.091017228183155, -8.847159634042411],
              [-36.090879335666457, -8.847322140275478],
              [-36.090834611241043, -8.847362219234331],
              [-36.090765525450628, -8.84738938190057],
              [-36.09075554088848, -8.847414758080369],
              [-36.090769555901154, -8.847499089153322],
              [-36.090816934573006, -8.847648062714878],
              [-36.090941251567642, -8.847920037213127],
              [-36.090959567995291, -8.847983814096802],
              [-36.090967923376319, -8.848069972780607],
              [-36.090964153855673, -8.848166518189267],
              [-36.090953534102418, -8.848224829638223],
              [-36.090926749808744, -8.848280769503532],
              [-36.090766062370768, -8.848422510703559],
              [-36.090733065755671, -8.848454820046721],
              [-36.09062531711038, -8.848612694188732],
              [-36.090527182547312, -8.84874444059639],
              [-36.090390044377614, -8.848954490936109],
              [-36.090313248920374, -8.849052714334384],
              [-36.090217789931479, -8.849131327907994],
              [-36.09009746367208, -8.849213491251412],
              [-36.089995427470257, -8.849314887117355],
              [-36.089880481573587, -8.849382868026758],
              [-36.08983706232479, -8.849400497687196],
              [-36.089795224918305, -8.849408407316105],
              [-36.089754975234804, -8.849405848333387],
              [-36.089679083073868, -8.849389162251457],
              [-36.089562420703082, -8.84938825479208],
              [-36.08944596437204, -8.849361146491972],
              [-36.089321540395375, -8.849342211219376],
              [-36.089293039383989, -8.849329262619891],
              [-36.089278653698791, -8.849316049501194],
              [-36.089245992902939, -8.849257775816076],
              [-36.089217121690822, -8.849220119212694],
              [-36.08915417813342, -8.849160112667164],
              [-36.089052299181731, -8.849097931600449],
              [-36.08897281165379, -8.849059881200697],
              [-36.08891010590424, -8.849041425995328],
              [-36.08887280511361, -8.849046750585011],
              [-36.088819116071626, -8.849078150060574],
              [-36.088772398203318, -8.849084524324645],
              [-36.088675216846283, -8.849094997789839],
              [-36.088508549416716, -8.849135624756435],
              [-36.088467947039504, -8.849130068341292],
              [-36.088298222968945, -8.849080834681416],
              [-36.088223721510353, -8.849078757646506],
              [-36.088155929205477, -8.849084967829219],
              [-36.088046282486246, -8.849053420309975],
              [-36.087977497264532, -8.848994491127202],
              [-36.087940687669636, -8.848985220976962],
              [-36.087904536503544, -8.848987934186406],
              [-36.087859952961495, -8.84901004636985],
              [-36.087820797048096, -8.849011987539033],
              [-36.087781000384346, -8.848999699574014],
              [-36.087650292374356, -8.848870664961428],
              [-36.087619974638784, -8.848849467090496],
              [-36.087600585704941, -8.848842779495655],
              [-36.087559626848005, -8.848842259625421],
              [-36.087531071496841, -8.848836235408307],
              [-36.087472557086315, -8.848811262025349],
              [-36.087431040479323, -8.848802329516364],
              [-36.087371636479219, -8.848794755049084],
              [-36.086927727565964, -8.848771086131507],
              [-36.086848798704523, -8.848757744790097],
              [-36.08681685068624, -8.848733691012303],
              [-36.086794427310558, -8.848708472826949],
              [-36.086784478540707, -8.848681444383638],
              [-36.086787748397775, -8.84864852974561],
              [-36.08680037639634, -8.848622051394315],
              [-36.08682123353335, -8.848602000538254],
              [-36.086886736033684, -8.848551977388187],
              [-36.086902689111483, -8.848533385622778],
              [-36.086909963070404, -8.848517720842814],
              [-36.086911618610984, -8.848498643451025],
              [-36.086904283510769, -8.848474255593278],
              [-36.086892373550874, -8.848457318502037],
              [-36.086824342039804, -8.84835048220144],
              [-36.086799025666508, -8.848315473420289],
              [-36.08678704502443, -8.848307519439643],
              [-36.086772365276794, -8.848307779480127],
              [-36.086765532459673, -8.848315212665181],
              [-36.086763544772751, -8.848328672685851],
              [-36.086763365083961, -8.848351504756778],
              [-36.08676176551073, -8.848363470520734],
              [-36.086750423746359, -8.848393889234892],
              [-36.086741290234734, -8.848406732141223],
              [-36.086712869721175, -8.84843140310798],
              [-36.086698885185065, -8.848438967773648],
              [-36.08668437592744, -8.84844147504023],
              [-36.086649783180015, -8.848437462528244],
              [-36.086625045678751, -8.848424543078396],
              [-36.08661049260855, -8.848408708359534],
              [-36.086599904238696, -8.848391220084709],
              [-36.086585792674008, -8.848343197355319],
              [-36.08657703881434, -8.848331712467875],
              [-36.08656355065547, -8.848324027482715],
              [-36.086548525522076, -8.848320354468825],
              [-36.086529907593246, -8.848318899401603],
              [-36.086510198972221, -8.848312569685159],
              [-36.086488669112519, -8.848298552240745],
              [-36.086471861760671, -8.848282232080782],
              [-36.086461275610006, -8.848264463074351],
              [-36.086454256476593, -8.84824775122471],
              [-36.086450351621323, -8.848229847076208],
              [-36.08645043320557, -8.84818361929643],
              [-36.086446989927182, -8.848166841707359],
              [-36.086439581943367, -8.848151717682045],
              [-36.086425611058381, -8.84813364159143],
              [-36.086358633208178, -8.848060315024314],
              [-36.086299428641382, -8.848003519027307],
              [-36.086279657097847, -8.847981280247025],
              [-36.08626590384938, -8.84795946265961],
              [-36.086260444535682, -8.847935838045874],
              [-36.086261285574167, -8.847924615025343],
              [-36.086268894618932, -8.847914193339053],
              [-36.086284753402801, -8.847907579070538],
              [-36.086339744375962, -8.847902018128051],
              [-36.086362554916249, -8.847896768106345],
              [-36.086376143951028, -8.847891633447029],
              [-36.086383550092236, -8.847883081767877],
              [-36.08638551127887, -8.847872990423747],
              [-36.086382583136654, -8.847862486688255],
              [-36.086376223867497, -8.847857571026909],
              [-36.086363056819941, -8.847856907026282],
              [-36.08635251081737, -8.847857947876802],
              [-36.086334220625098, -8.847862671617809],
              [-36.08632387014849, -8.847862778190125],
              [-36.086309793215172, -8.847858176756516],
              [-36.086293515769846, -8.847846258964715],
              [-36.086282345257601, -8.847831012069568],
              [-36.086273704835641, -8.84780511677438],
              [-36.086252491323492, -8.847774818919845],
              [-36.086244053804442, -8.847747053605493],
              [-36.086240828892571, -8.847726440946337],
              [-36.086242854878471, -8.847708115085492],
              [-36.086263888736632, -8.847665606490139],
              [-36.086281621065574, -8.847612216919075],
              [-36.086281315442193, -8.847603230881798],
              [-36.086278354904756, -8.847596844406347],
              [-36.086271598688285, -8.847594545888091],
              [-36.086261628959761, -8.847594093947643],
              [-36.086253698106979, -8.847597588228567],
              [-36.08624817507603, -8.847605967403405],
              [-36.086243088386695, -8.847630632856182],
              [-36.086235238158395, -8.847647790410893],
              [-36.086216086326949, -8.847666357251629],
              [-36.086197003720699, -8.847676128120156],
              [-36.086177781566064, -8.847679721642942],
              [-36.086156558847236, -8.847674503086459],
              [-36.086135580259878, -8.847662174372743],
              [-36.086116561391258, -8.847639941462264],
              [-36.086106292329639, -8.84760579847325],
              [-36.086107345491335, -8.847567626128024],
              [-36.086117151399257, -8.847517169413603],
              [-36.086136306549101, -8.847474271866712],
              [-36.086159507024334, -8.847443384028308],
              [-36.086187593861936, -8.847413282868729],
              [-36.08621561881764, -8.847391041933941],
              [-36.086242805693217, -8.847379649709243],
              [-36.086292581023521, -8.847367310402284],
              [-36.086323087088012, -8.847364553359192],
              [-36.086342453197126, -8.847366575733833],
              [-36.086363675914839, -8.847371794275025],
              [-36.086394634526414, -8.847383264876317],
              [-36.086405933174767, -8.84738222988023],
              [-36.086411189418797, -8.847371883450297],
              [-36.086407470512007, -8.847354261500143],
              [-36.086392943994177, -8.847335058126472],
              [-36.086358210694115, -8.847301098986822],
              [-36.086337774517915, -8.847291581902326],
              [-36.08629142628233, -8.847274938173282],
              [-36.086262922651365, -8.847262363725855],
              [-36.08624685252844, -8.847248014486254],
              [-36.086230482683163, -8.847223930611932],
              [-36.086223832530507, -8.84720815743038],
              [-36.086218417405476, -8.847178918383564],
              [-36.08623794995205, -8.847159793020607],
              [-36.086249445963972, -8.847133680190803],
              [-36.086255279402117, -8.847109769192739],
              [-36.086253571566822, -8.847087671068822],
              [-36.086245386645558, -8.8470756291283],
              [-36.086234123359716, -8.847072172564427],
              [-36.086220937192635, -8.847073941496216],
              [-36.086189050676147, -8.847084735656383],
              [-36.086166625358238, -8.84708886572607],
              [-36.086141423256862, -8.847087172228363],
              [-36.086110270620431, -8.847076448739728],
              [-36.086087417799803, -8.847063169641187],
              [-36.086057567969249, -8.847030371479319],
              [-36.086022505548065, -8.846990420663143],
              [-36.086007197328613, -8.846950997975107],
              [-36.085998954256567, -8.84689852912466],
              [-36.085999672320177, -8.84685511371476],
              [-36.086009172568893, -8.846795670955601],
              [-36.086006460593872, -8.846781612882086],
              [-36.085991056638271, -8.846754354817792],
              [-36.085974619063933, -8.846738879750477],
              [-36.085956038042013, -8.846732745979136],
              [-36.085884758096739, -8.846727886306244],
              [-36.085824340053705, -8.846729661786638],
              [-36.085801801723193, -8.846724245827856],
              [-36.085781571436023, -8.84671248441917],
              [-36.085766659805238, -8.846694400995554],
              [-36.085758880690712, -8.846678619032526],
              [-36.085740661672133, -8.846650403244876],
              [-36.08571743846651, -8.846636372617692],
              [-36.085683264972658, -8.84662693570643],
              [-36.085667884219077, -8.846620639679442],
              [-36.085648800598655, -8.846606641275788],
              [-36.085617125190041, -8.846566716822448],
              [-36.085523328149677, -8.846434226182053],
              [-36.085471819463578, -8.846379923169327],
              [-36.085406686461539, -8.846335246348337],
              [-36.085352625204301, -8.846318355330942],
              [-36.085281737489609, -8.846335396277839],
              [-36.085243322808438, -8.846338840310505],
              [-36.085211735024203, -8.846335599758968],
              [-36.085165982419909, -8.84631503023917],
              [-36.08511139837578, -8.846268938253194],
              [-36.084974698427708, -8.846088574961525],
              [-36.084934640843585, -8.846061686356688],
              [-36.084892219234803, -8.846048254812489],
              [-36.084861616067187, -8.846063363524765],
              [-36.08480158496306, -8.846135513793428],
              [-36.084737291383554, -8.846175439434315],
              [-36.084431605399502, -8.846378184910803],
              [-36.084356733369376, -8.846423268519116],
              [-36.084278909672342, -8.846460842734565],
              [-36.084255159906512, -8.846465898174314],
              [-36.084222437276914, -8.846463397339047],
              [-36.084209814159664, -8.846465357755221],
              [-36.084195643004797, -8.846472733724886],
              [-36.084174618397427, -8.846490162894632],
              [-36.084139313567697, -8.846528816958356],
              [-36.084111946933106, -8.846563041048665],
              [-36.084104664094994, -8.846579828634836],
              [-36.084100562334982, -8.846598886913453],
              [-36.084099378329341, -8.846629758960393],
              [-36.084102150159325, -8.84666010035154],
              [-36.084115727331898, -8.846728144918391],
              [-36.084126572482823, -8.846760795120323],
              [-36.084188005411619, -8.84686907744608],
              [-36.084205741846162, -8.846910764973936],
              [-36.084220246584188, -8.84698050121243],
              [-36.084236284115192, -8.847142331723928],
              [-36.084239662136895, -8.847286844968393],
              [-36.08422726594177, -8.847331666602024],
              [-36.084196841454798, -8.847371855947186],
              [-36.084142296688519, -8.84741634917131],
              [-36.084121768700136, -8.847442391518467],
              [-36.084116317348069, -8.847465556781923],
              [-36.084119426209973, -8.847476997720293],
              [-36.084126904091029, -8.84748323223493],
              [-36.084162203600727, -8.847493052395858],
              [-36.084178510417196, -8.847501227290993],
              [-36.084209921379141, -8.847526925689685],
              [-36.084246130257405, -8.847564639645496],
              [-36.084274789018167, -8.847605289331431],
              [-36.084294777533501, -8.847647743048757],
              [-36.084311214019991, -8.847711130934677],
              [-36.084326282748172, -8.847804827954642],
              [-36.084321882704018, -8.847885646462412],
              [-36.084305711412625, -8.847931935954669],
              [-36.084273840517064, -8.847964627655056],
              [-36.084228498495747, -8.847987482098288],
              [-36.084184826869404, -8.847989387726459],
              [-36.084155573604605, -8.847976432961044],
              [-36.084135381672468, -8.847959805620325],
              [-36.084105999749895, -8.847915407102297],
              [-36.084086923110043, -8.84785274728492],
              [-36.084085610494114, -8.847780493609703],
              [-36.084081200710784, -8.847766983790581],
              [-36.084068105923471, -8.847757149477669],
              [-36.08405042152021, -8.847756637361826],
              [-36.084034149550014, -8.847767927276619],
              [-36.083985953075718, -8.847842602556167],
              [-36.083980154857443, -8.847862021927176],
              [-36.083984185367804, -8.84787590311204],
              [-36.08402118520965, -8.847908757119908],
              [-36.084034197441682, -8.84792907170757],
              [-36.084043766706131, -8.847956471519053],
              [-36.084064062652267, -8.848055449722246],
              [-36.084066390315584, -8.848094396963614],
              [-36.084053223700636, -8.848141458488096],
              [-36.084020914515719, -8.84818200741554],
              [-36.083988729156424, -8.848206835939907],
              [-36.083947979680538, -8.84821999386015],
              [-36.083854526716507, -8.84823498695841],
              [-36.083766853184457, -8.848280719178666],
              [-36.083711038822159, -8.848295256931767],
              [-36.083602597627845, -8.848301898147025],
              [-36.083554486740574, -8.848294036819457],
              [-36.083532407304951, -8.848278143370361],
              [-36.083518686677628, -8.848252208472823],
              [-36.083513715950197, -8.848214363598865],
              [-36.083516671100135, -8.848197542312965],
              [-36.083527732031136, -8.84817891260994],
              [-36.083546642744352, -8.84816708180896],
              [-36.083576846220133, -8.848154964711117],
              [-36.083613844215428, -8.848140280329325],
              [-36.083621417936072, -8.848134350260809],
              [-36.083623734865611, -8.84812688195597],
              [-36.083622300331641, -8.848117887138313],
              [-36.083614815080679, -8.848112588343469],
              [-36.083599800335598, -8.848107605185119],
              [-36.083551686523407, -8.848100118163144],
              [-36.083526910878462, -8.848092064385865],
              [-36.083458658253704, -8.848061212661849],
              [-36.083433473854299, -8.848057273195399],
              [-36.083408440751619, -8.848058013884801],
              [-36.083376343857964, -8.848071613485603],
              [-36.083270473943173, -8.848134048074785],
              [-36.083221756734922, -8.848155378795365],
              [-36.083169023793317, -8.848160956843586],
              [-36.083126140233752, -8.84815837667044],
              [-36.083095452492181, -8.848136427014277],
              [-36.083059962195399, -8.848055297669193],
              [-36.083036060154839, -8.848031903625913],
              [-36.082948870351323, -8.848016251258153],
              [-36.08291136747723, -8.847999488918381],
              [-36.082883049808281, -8.847963333645135],
              [-36.082853533593827, -8.847888239956101],
              [-36.08282883468376, -8.847870454467534],
              [-36.082793480648029, -8.847867558645342],
              [-36.08275348386254, -8.847880722292359],
              [-36.082674865809281, -8.847923530281236],
              [-36.082630946467823, -8.84795687650378],
              [-36.082606613794809, -8.847988129512169],
              [-36.082595787574846, -8.848024728263571],
              [-36.082582284503182, -8.848114459286219],
              [-36.082566877457772, -8.848159257316363],
              [-36.082532037106887, -8.848186685209843],
              [-36.082490540884358, -8.848199088491818],
              [-36.082425824508924, -8.848197086648614],
              [-36.08233635908249, -8.848183662285656],
              [-36.082271607315022, -8.848186151958695],
              [-36.082206778839485, -8.848198373270961],
              [-36.082161466243519, -8.848217484474924],
              [-36.08212426465181, -8.848257995028748],
              [-36.082048226316438, -8.848355473352539],
              [-36.081971573963934, -8.848435353922003],
              [-36.081899229362378, -8.848493931948425],
              [-36.081759565127768, -8.848594282834682],
              [-36.081689816390281, -8.848657747181411],
              [-36.081659246932333, -8.848716276619969],
              [-36.081645849807103, -8.848792532924939],
              [-36.081596342987616, -8.848866261681744],
              [-36.081512432443169, -8.848912022577233],
              [-36.081426446904047, -8.848934559584666],
              [-36.081222901237155, -8.848926982777909],
              [-36.08110925050326, -8.84892609620872],
              [-36.080946036808285, -8.848910598898838],
              [-36.080902465556079, -8.848899778097072],
              [-36.080871817811115, -8.848872775304889],
              [-36.080838252451898, -8.848833958756003],
              [-36.080824526143076, -8.848808772408491],
              [-36.080821286789622, -8.848790031273623],
              [-36.080832257863577, -8.848735092216627],
              [-36.080832827539034, -8.848710578871817],
              [-36.080825019134558, -8.848698539803879],
              [-36.080813044577845, -8.848689837084484],
              [-36.080771451642129, -8.848690635547754],
              [-36.080752870703563, -8.848684501505316],
              [-36.080719569521072, -8.848659911073149],
              [-36.080695741444259, -8.848627159566425],
              [-36.080672375311288, -8.848583556467929],
              [-36.080616206902206, -8.848452107200728],
              [-36.080601544985001, -8.848402395769261],
              [-36.080599502525203, -8.848375054681322],
              [-36.080605351106499, -8.848349272413],
              [-36.080619399149391, -8.848333660690942],
              [-36.080633749727582, -8.848327409253326],
              [-36.080650322835339, -8.848325666966799],
              [-36.080680731456702, -8.848335262130181],
              [-36.080707350251572, -8.848348196594005],
              [-36.080727260118749, -8.848352843721608],
              [-36.080738976413713, -8.848346571736428],
              [-36.080743248832064, -8.84832976078979],
              [-36.08073924947589, -8.848264224052976],
              [-36.080743992682088, -8.848235438622639],
              [-36.080754721677522, -8.848211191702964],
              [-36.080766526514331, -8.848193690880359],
              [-36.080783934917442, -8.848181474216423],
              [-36.080812571118415, -8.848177205800139],
              [-36.080844485043023, -8.848186812691226],
              [-36.08088759724523, -8.84820811077879],
              [-36.080914627794058, -8.848216556626824],
              [-36.080938351091142, -8.848214870105528],
              [-36.080976866225299, -8.848198700585002],
              [-36.0810131883295, -8.848174278965711],
              [-36.081039340771959, -8.848150900969866],
              [-36.081048126138207, -8.84813449953921],
              [-36.081052425102143, -8.848114319928602],
              [-36.081049897066592, -8.848100824786499],
              [-36.081024467738352, -8.848032500653559],
              [-36.081011420285364, -8.848016677550191],
              [-36.080990058249824, -8.848005281399219],
              [-36.080880460336964, -8.847967743360767],
              [-36.080797705555533, -8.847914693379352],
              [-36.080700886562326, -8.847831588279995],
              [-36.080601639958793, -8.847722262027229],
              [-36.080559087160054, -8.847677760623975],
              [-36.080545105582161, -8.847637225286759],
              [-36.080531374956578, -8.847469424038149],
              [-36.080539033557891, -8.847428870356902],
              [-36.080555786684386, -8.847404296116499],
              [-36.080578143354622, -8.847385006023314],
              [-36.080614412243456, -8.847367321723015],
              [-36.08064568855572, -8.847362325265617],
              [-36.08071987184055, -8.847356914875995],
              [-36.080761018191048, -8.847341140189013],
              [-36.080779614550558, -8.847321446434538],
              [-36.080789937663752, -8.847300939501068],
              [-36.080787874499244, -8.847276218470588],
              [-36.080768659770513, -8.847231150503402],
              [-36.080759135041596, -8.847198136282895],
              [-36.080766724972342, -8.847142422206208],
              [-36.080777904017388, -8.847108820840431],
              [-36.080794292588386, -8.847082746464839],
              [-36.080808675567255, -8.847072377764178],
              [-36.08082736415362, -8.847064850034643],
              [-36.080842803804032, -8.847063660355275],
              [-36.080861574307818, -8.847069608682554],
              [-36.080887815233361, -8.847082727306292],
              [-36.080907708810429, -8.847089433032096],
              [-36.080931399577807, -8.847091863718518],
              [-36.080952150574696, -8.847085287864951],
              [-36.08096807721936, -8.847070065073501],
              [-36.080972358448257, -8.847052131233177],
              [-36.080965679038435, -8.847040100974192],
              [-36.080954448310074, -8.847032527027597],
              [-36.080898887976737, -8.847014875072714],
              [-36.080871531362384, -8.847000063313009],
              [-36.080851366237439, -8.846980067204457],
              [-36.080837260676304, -8.846955252238699],
              [-36.080834089189509, -8.846927902331705],
              [-36.080840343540686, -8.846898380047342],
              [-36.080865756447594, -8.846825586374257],
              [-36.080870132115983, -8.846795675113976],
              [-36.08087335213164, -8.846673672763382],
              [-36.080896735067761, -8.846595809967644],
              [-36.08090822470232, -8.84654667684365],
              [-36.080912855686108, -8.846532114583596],
              [-36.080922370030834, -8.846518713363302],
              [-36.080936732331679, -8.846510964719165],
              [-36.080955586911045, -8.846506245645502],
              [-36.080994200002976, -8.846501493504825],
              [-36.08113729622935, -8.846490818510219],
              [-36.08115804866457, -8.846484055479902],
              [-36.081169816518489, -8.846471233300946],
              [-36.081172937774795, -8.846457220735266],
              [-36.081168486796862, -8.846448951043323],
              [-36.081148923829318, -8.846448049839697],
              [-36.08109386833894, -8.846461844544054],
              [-36.081052443147968, -8.846465264639573],
              [-36.081010685896338, -8.846463067384958],
              [-36.080985931108202, -8.846452393439831],
              [-36.080972139908525, -8.846435441591305],
              [-36.080964132250813, -8.846400941937082],
              [-36.080981386934774, -8.846312737593562],
              [-36.080982703203816, -8.846241253239821],
              [-36.080988266822494, -8.846203864881995],
              [-36.080998275508811, -8.846175494818519],
              [-36.081007797224203, -8.846161157853889],
              [-36.081018039113708, -8.846150943998905],
              [-36.081061193823174, -8.846119089250125],
              [-36.081074918534696, -8.846096737239096],
              [-36.081082740078955, -8.846059366494472],
              [-36.081089434017436, -8.845974074311567],
              [-36.08109413556511, -8.845855078026579],
              [-36.081089616483887, -8.845759966144906],
              [-36.08108039280139, -8.845736499375381],
              [-36.081061774314328, -8.845711274912295],
              [-36.08103672881262, -8.84568974350913],
              [-36.081004506613915, -8.845671524988964],
              [-36.080939623943294, -8.845642945191541],
              [-36.080865300746176, -8.845618409260236],
              [-36.080685409134254, -8.845522678316632],
              [-36.080668386754418, -8.845509818762142],
              [-36.080654207463915, -8.845494361169724],
              [-36.080642718499035, -8.845471812517992],
              [-36.080633919859231, -8.84544217283435],
              [-36.080632185793021, -8.845423443439273],
              [-36.08063650830664, -8.84540026947985],
              [-36.080648539536426, -8.845377904275219],
              [-36.080660682214379, -8.845365272185143],
              [-36.080675071014717, -8.845354154883555],
              [-36.080695448529106, -8.845347201781605],
              [-36.080718795317573, -8.845345512270773],
              [-36.080747360464834, -8.845350226860813],
              [-36.080778518572394, -8.845360202106287],
              [-36.080894430650467, -8.845408270053815],
              [-36.080918082953204, -8.845415566531384],
              [-36.0809350352053, -8.845413452836286],
              [-36.080946398669568, -8.845404183510476],
              [-36.080952937790848, -8.845386267272078],
              [-36.080956978381671, -8.845351112943961],
              [-36.080962651546763, -8.84518046843273],
              [-36.080958767077284, -8.845100334211406],
              [-36.080937359321105, -8.845047014198565],
              [-36.080897105916129, -8.844997290401059],
              [-36.080687152769769, -8.844800259440229],
              [-36.0806217580066, -8.844693443332829],
              [-36.080594804425047, -8.844675265900154],
              [-36.08034568772576, -8.844576749182924],
              [-36.080272970408586, -8.844539498953946],
              [-36.080185347789858, -8.844483416590805],
              [-36.080087526142854, -8.844432120791085],
              [-36.080018595720993, -8.844391905518844],
              [-36.079984480283429, -8.844375169463154],
              [-36.079875609610639, -8.844341006027713],
              [-36.079768365260833, -8.844291508255505],
              [-36.079715033822751, -8.84427761682697],
              [-36.079661625656904, -8.844273457031795],
              [-36.079617160672079, -8.844280596505394],
              [-36.079561614113217, -8.844308985593633],
              [-36.079468140199346, -8.844374510476849],
              [-36.079443093387319, -8.844400891583318],
              [-36.079433528897859, -8.844420655763416],
              [-36.079432975465636, -8.844443110465722],
              [-36.079440630278263, -8.844474612805676],
              [-36.079445309928097, -8.844501600135073],
              [-36.079435994823768, -8.844537461892827],
              [-36.079400360805913, -8.844570123777185],
              [-36.07933237489744, -8.844601035976414],
              [-36.079280312589532, -8.844617099732199],
              [-36.079215520276051, -8.844624829176524],
              [-36.079166663384449, -8.844616213001983],
              [-36.079130020014382, -8.844585981747624],
              [-36.079110728779042, -8.844550645443793],
              [-36.079034794793216, -8.84434867050175],
              [-36.079032497088129, -8.844305980455335],
              [-36.079039569041008, -8.844268229635752],
              [-36.079065504303529, -8.844224636926768],
              [-36.079115935865701, -8.844176743533563],
              [-36.079133547764371, -8.844138700627292],
              [-36.079122748156095, -8.844076479765219],
              [-36.079094466552561, -8.844035832894267],
              [-36.079052050071027, -8.843974113990191],
              [-36.079040356130363, -8.843929853359977],
              [-36.07904215071958, -8.843893184309723],
              [-36.079068744111488, -8.84386157486775],
              [-36.079076243254697, -8.843841233108853],
              [-36.079073516784263, -8.843829046539355],
              [-36.079063792950883, -8.843821297179995],
              [-36.079046511711326, -8.843817419176911],
              [-36.079004778456181, -8.843812227454366],
              [-36.078980373675883, -8.843804925018876],
              [-36.078950762399565, -8.843789721312927],
              [-36.07893132360784, -8.84377309970098],
              [-36.078909327320574, -8.843746725909794],
              [-36.078890394737599, -8.843713638310682],
              [-36.078868696625378, -8.843649460839927],
              [-36.078859048084098, -8.843632166988817],
              [-36.078842596245579, -8.8436185632196],
              [-36.078812979086365, -8.843604108092904],
              [-36.078749534400274, -8.843584148582311],
              [-36.078687283637805, -8.843555963417309],
              [-36.078668497135403, -8.84355207366499],
              [-36.078662458285152, -8.843554272449298],
              [-36.078659770905936, -8.843560989172678],
              [-36.078674689298374, -8.843625862377362],
              [-36.078678195660039, -8.843658455294566],
              [-36.078674593256814, -8.843685752305362],
              [-36.078666833607272, -8.843715262773246],
              [-36.078644238014739, -8.843764870585865],
              [-36.078625559145799, -8.843795044481748],
              [-36.078611105429133, -8.843814396168604],
              [-36.078598233753972, -8.84382402796145],
              [-36.078583693733172, -8.843830465042894],
              [-36.078555831230972, -8.843832119198577],
              [-36.078509101815115, -8.843816220410684],
              [-36.078491791054027, -8.843816085319004],
              [-36.07845544214338, -8.843820106310561],
              [-36.078429312939434, -8.843816720714356],
              [-36.078404167355821, -8.84380791520446],
              [-36.078383198214048, -8.84379446331924],
              [-36.078370137706997, -8.843780324524785],
              [-36.078365534091837, -8.843767561833564],
              [-36.078365465513969, -8.84375240147342],
              [-36.078370910497924, -8.84370621586266],
              [-36.078367822492488, -8.84369215489601],
              [-36.078364038284946, -8.84368286102794],
              [-36.078356759824786, -8.843675224313131],
              [-36.078347859113727, -8.843670475900616],
              [-36.078339030349255, -8.843668535411995],
              [-36.078327742200614, -8.843668260170578],
              [-36.078313234666609, -8.843670580011242],
              [-36.078301993365109, -8.843676294187812],
              [-36.078293281889863, -8.843683338220337],
              [-36.078282702094953, -8.843688683248573],
              [-36.078274605298219, -8.843689368696358],
              [-36.078262945264839, -8.843688529067132],
              [-36.078217099111328, -8.843679936310989],
              [-36.078108466485126, -8.843663367206137],
              [-36.078091570450013, -8.843658369231324],
              [-36.078069457597309, -8.84364677999479],
              [-36.078053552569564, -8.843635426364262],
              [-36.078033769990967, -8.843614684563217],
              [-36.078023413146362, -8.843591770424064],
              [-36.078019521669944, -8.843560297461572],
              [-36.078023085734358, -8.843537866294154],
              [-36.07803696104245, -8.843496425446734],
              [-36.078065968885724, -8.843444996159469],
              [-36.078092200735497, -8.843411512394319],
              [-36.078173199813158, -8.843353002505657],
              [-36.078198225922691, -8.843329241553308],
              [-36.078203677471784, -8.843306076462902],
              [-36.078199709201549, -8.843284335134266],
              [-36.07817293303652, -8.843243699983084],
              [-36.078148290784775, -8.843218802791265],
              [-36.078091391315304, -8.843180178450432],
              [-36.078039125801851, -8.843126617613642],
              [-36.077914919898298, -8.843032817780367],
              [-36.077768371109514, -8.842908898229616],
              [-36.077710128870336, -8.842849675974952],
              [-36.077646107684373, -8.842759714677053],
              [-36.077623962624131, -8.84270451732178],
              [-36.077626061454943, -8.842629296106679],
              [-36.077633918583039, -8.842587433992906],
              [-36.077673681818077, -8.84250838925834],
              [-36.077726487914802, -8.842398003718953],
              [-36.077764403300954, -8.842362365252297],
              [-36.077877818704351, -8.842297370656365],
              [-36.078094286898903, -8.842049765060455],
              [-36.078108165032788, -8.842007949899699],
              [-36.078099940743478, -8.841953235511376],
              [-36.07811120236169, -8.841909154025361],
              [-36.078160454446177, -8.841867614882673],
              [-36.078248238647404, -8.841807660541049],
              [-36.07833153650774, -8.841743927999129],
              [-36.078377961026078, -8.841679159118568],
              [-36.078415994346798, -8.84162854878088],
              [-36.078480305014047, -8.841586378424585],
              [-36.078563402081784, -8.841548097768898],
              [-36.078735251276548, -8.841517995798515],
              [-36.078805423628374, -8.841496084191315],
              [-36.078851006858841, -8.841442538153533],
              [-36.078918319093056, -8.841306063261241],
              [-36.079004984287593, -8.841101613625373],
              [-36.079061042243609, -8.840960559085131],
              [-36.079076655659286, -8.840889560651913],
              [-36.079073928408832, -8.840853604960669],
              [-36.079054678573314, -8.840813028598056],
              [-36.079046954363186, -8.840742596773389],
              [-36.079053638774475, -8.840730109293306],
              [-36.079069713437043, -8.840719940953573],
              [-36.079138395095505, -8.840696146022387],
              [-36.079162230015271, -8.840680236291684],
              [-36.079178959144187, -8.840658656381002],
              [-36.079178315687436, -8.840644801629914],
              [-36.079163724866177, -8.840633832644976],
              [-36.079141542770664, -8.840631039427228],
              [-36.079106856613798, -8.840639003875802],
              [-36.079076227325231, -8.840657480836777],
              [-36.079022053859823, -8.840702724976563],
              [-36.079000482751233, -8.840717903723593],
              [-36.078975957176283, -8.840725947408206],
              [-36.07895567142063, -8.840721297381263],
              [-36.078935474201465, -8.840705418541543],
              [-36.078916109298319, -8.840679439640514],
              [-36.07890248359648, -8.840641527355835],
              [-36.07890016809187, -8.840601083083316],
              [-36.078909500717643, -8.840562975556516],
              [-36.07893475082512, -8.840510768135434],
              [-36.078969732057111, -8.840465374316473],
              [-36.079024411637157, -8.840403664182251],
              [-36.079245833437035, -8.84019577354462],
              [-36.079260345938671, -8.840168935896525],
              [-36.079261328722019, -8.840139746833275],
              [-36.07924050869785, -8.840107393215133],
              [-36.079202624568133, -8.84009137646899],
              [-36.079158275191936, -8.840083918584048],
              [-36.079114933796234, -8.840091815547806],
              [-36.0790771692837, -8.840108365295768],
              [-36.078810704492412, -8.840301306143571],
              [-36.078765416566704, -8.840317422845922],
              [-36.078720223070313, -8.840321562147821],
              [-36.078668462444192, -8.840299448032352],
              [-36.078631831248543, -8.840267719698396],
              [-36.078612575575768, -8.840227891921144],
              [-36.07858643980272, -8.840010584347628],
              [-36.078576929929937, -8.839880247943404],
              [-36.078584975658089, -8.839814430947385],
              [-36.078617082055182, -8.839751796164069],
              [-36.078632488842565, -8.839706998320802],
              [-36.078635971534361, -8.839647134801133],
              [-36.078624720273623, -8.839546730153382],
              [-36.078580364955563, -8.839444570038317],
              [-36.078577543292759, -8.839420591761881],
              [-36.078576427553507, -8.839371173246612],
              [-36.078516042705402, -8.83929733618664],
              [-36.078507264895478, -8.839265076481944],
              [-36.078509767724491, -8.839234027715337],
              [-36.078560382802415, -8.839115015445303],
              [-36.078579012537347, -8.839043291930111],
              [-36.078586459019526, -8.838958005780313],
              [-36.078588262130623, -8.838824763077191],
              [-36.078576603622103, -8.83877601097246],
              [-36.07854686425727, -8.838729363755213],
              [-36.078494528261459, -8.838684786202148],
              [-36.078399369032645, -8.838630142425085],
              [-36.078339548069899, -8.838580266045421],
              [-36.078304504928646, -8.838538069258659],
              [-36.078288283460225, -8.838495270631796],
              [-36.078283357352781, -8.838451811478834],
              [-36.078295700435454, -8.838413727448321],
              [-36.078317780499297, -8.838381708426287],
              [-36.078377598307029, -8.838336508327927],
              [-36.078381535563764, -8.838314454350728],
              [-36.078357793678229, -8.838270848441677],
              [-36.07826983315821, -8.838162359210818],
              [-36.078225171114653, -8.83809912567205],
              [-36.078168661758006, -8.838011094835377],
              [-36.078127210920478, -8.837922432814803],
              [-36.078098894296517, -8.83788627756323],
              [-36.078078285513904, -8.837874887320282],
              [-36.078053833916314, -8.837873573658197],
              [-36.078021370518677, -8.837886047221987],
              [-36.077697592460289, -8.838093138922186],
              [-36.077655250408647, -8.838117513508269],
              [-36.077579721826304, -8.838150612786794],
              [-36.077489739779708, -8.838203063737057],
              [-36.07732316355375, -8.838328283094395],
              [-36.077267944106126, -8.838363038003791],
              [-36.077227907135374, -8.838381441449982],
              [-36.077185689013135, -8.838390095633457],
              [-36.07713679170071, -8.838386719588357],
              [-36.077068432292336, -8.838369716282974],
              [-36.076960390188304, -8.838326201188996],
              [-36.076768520113504, -8.838222889983852],
              [-36.076526572446468, -8.838075018601426],
              [-36.076431759258675, -8.837976582173731],
              [-36.076345611522825, -8.837924628558017],
              [-36.076321553990191, -8.837921071964908],
              [-36.076309479463511, -8.837925095204728],
              [-36.076300350479414, -8.83793737638959],
              [-36.076311427300503, -8.837964413595303],
              [-36.076377732097839, -8.838051023756123],
              [-36.076390990044018, -8.838087810198692],
              [-36.076383950643631, -8.838121443704814],
              [-36.076357407848619, -8.838146690052065],
              [-36.076321228519852, -8.838153145377524],
              [-36.076280598389609, -8.838151331013517],
              [-36.076217483887902, -8.838137363045611],
              [-36.076176242869373, -8.838117576726862],
              [-36.076127711815104, -8.838067788232459],
              [-36.076020408242805, -8.837930699711098],
              [-36.075988322831449, -8.837895263575058],
              [-36.075969225315177, -8.837883136404191],
              [-36.075949292494627, -8.837881483559611],
              [-36.07592741312898, -8.837888050473451],
              [-36.075918233930793, -8.837906694623202],
              [-36.075910241059226, -8.837965774153966],
              [-36.075897186500164, -8.837998612056337],
              [-36.075872948830735, -8.838017887295017],
              [-36.075832283283347, -8.838020564397015],
              [-36.075797762811703, -8.838007568195767],
              [-36.07575657499634, -8.837981044579038],
              [-36.075725259959853, -8.837943368548034],
              [-36.075702276992125, -8.83789901987228],
              [-36.075691253384768, -8.837865245400408],
              [-36.075674117673522, -8.837747576459218],
              [-36.075678134798693, -8.837715416656357],
              [-36.075691947886867, -8.837681836048558],
              [-36.075725547822422, -8.837620710519856],
              [-36.075749298623016, -8.837567743055429],
              [-36.075754909580951, -8.837524366207774],
              [-36.0757438150886, -8.837499574766024],
              [-36.075721431187311, -8.837474695200253],
              [-36.07569295539696, -8.837458751660918],
              [-36.075665905132318, -8.837452925783102],
              [-36.075626806931176, -8.837447754483536],
              [-36.075545870025366, -8.837450865852629],
              [-36.075461487226413, -8.837461436625709],
              [-36.075408826982134, -8.837458031012972],
              [-36.075363034900768, -8.837442700914748],
              [-36.075301532185755, -8.837415270053606],
              [-36.075247437593276, -8.837402869655095],
              [-36.075215015580945, -8.837410102851813],
              [-36.075147819162446, -8.837436528982588],
              [-36.075085315720735, -8.837440532796345],
              [-36.074959069448319, -8.837414842307888],
              [-36.074879509669969, -8.837386521781687],
              [-36.074746082825193, -8.837316980246479],
              [-36.074698222004621, -8.837277677728085],
              [-36.074616372611821, -8.837158006292102],
              [-36.074580465194494, -8.83713002657036],
              [-36.074536322306059, -8.837120323998528],
              [-36.074481730385273, -8.837123266560193],
              [-36.074429695828528, -8.837135961281774],
              [-36.074324735230512, -8.837178936558978],
              [-36.074281711497221, -8.837194321833],
              [-36.074173150265572, -8.837216681592682],
              [-36.074060642699095, -8.837262218054324],
              [-36.073996984850218, -8.837317119561096],
              [-36.073985565450172, -8.837333500241973],
              [-36.073983912454729, -8.837352203101014],
              [-36.074006334606686, -8.837372216933641],
              [-36.074068784799749, -8.837374950562753],
              [-36.074130559202906, -8.837367946699743],
              [-36.074176362994898, -8.837381779767808],
              [-36.074204038768805, -8.837403706195062],
              [-36.074221847688825, -8.837436036393937],
              [-36.074229825192411, -8.837474278862416],
              [-36.074227764453973, -8.837544634064121],
              [-36.074240322715866, -8.837574677376123],
              [-36.074271584363387, -8.837619090727639],
              [-36.074370097817578, -8.837725417017815],
              [-36.074452983446868, -8.837809162321875],
              [-36.074527749766091, -8.837872631188398],
              [-36.074626089810465, -8.837905590254708],
              [-36.074654568510887, -8.837921159576137],
              [-36.074677725641848, -8.837943425020699],
              [-36.074716371824032, -8.838005863181387],
              [-36.074742189750282, -8.838024780557783],
              [-36.074791285949019, -8.838050617384591],
              [-36.074821972324258, -8.838072567302332],
              [-36.074874480117863, -8.83814297432086],
              [-36.07491970502128, -8.838230168679289],
              [-36.075000102578116, -8.83834309112995],
              [-36.075043448913583, -8.838429896493386],
              [-36.07506095069742, -8.838501153127588],
              [-36.075051174689158, -8.838595404329576],
              [-36.075012822396026, -8.838686437872443],
              [-36.074967321960052, -8.838729503196358],
              [-36.074860657650525, -8.838797544340558],
              [-36.074680870111202, -8.83887998700726],
              [-36.07460688790129, -8.838907857209213],
              [-36.07452205565037, -8.83897532009467],
              [-36.074477266393195, -8.839023631291239],
              [-36.074454220618072, -8.839082593143797],
              [-36.074428438229532, -8.839202172705939],
              [-36.074410796777229, -8.839243958250064],
              [-36.074385053707431, -8.839263221582176],
              [-36.074339830914802, -8.839271103293747],
              [-36.07425806026572, -8.839236776213756],
              [-36.073694388547459, -8.838940407105817],
              [-36.073524909344655, -8.838861225497238],
              [-36.073461018714077, -8.838850245517825],
              [-36.07339928546385, -8.838852009096859],
              [-36.073346471426625, -8.838868066319582],
              [-36.073312396241569, -8.838894002138845],
              [-36.073291050682883, -8.838928272353415],
              [-36.073216477199217, -8.839031000629916],
              [-36.073189591247989, -8.839099664519825],
              [-36.073174065665313, -8.839159433607618],
              [-36.073155245748843, -8.839255111198726],
              [-36.073131642271655, -8.839289363748325],
              [-36.07310029582392, -8.839303342772004],
              [-36.072958271550824, -8.839321697328058],
              [-36.07290963238286, -8.83933329530406],
              [-36.07286810770951, -8.839349440657051],
              [-36.072829516663433, -8.839375341123761],
              [-36.072755943955229, -8.839446634605634],
              [-36.072718464101776, -8.839474789626497],
              [-36.072529611966779, -8.839561277616598],
              [-36.07240406044729, -8.839638153772436],
              [-36.072313923861529, -8.839710066292154],
              [-36.072234546573526, -8.839801527243283],
              [-36.072179590552985, -8.839850507194074],
              [-36.072094265420773, -8.839885025777356],
              [-36.072078968484689, -8.839892018173371],
              [-36.0720655235381, -8.839902768192635],
              [-36.072057518891931, -8.83991561947389],
              [-36.072054404855237, -8.839928696142916],
              [-36.072055682368386, -8.83995752836112],
              [-36.072083593817915, -8.84009249982914],
              [-36.072077514296851, -8.840147476559491],
              [-36.072055955581654, -8.840208695629357],
              [-36.072025418540029, -8.840263106835918],
              [-36.071965739570672, -8.840338251824233],
              [-36.071915281461258, -8.840389512750477],
              [-36.071850923336278, -8.840437670473463],
              [-36.071776550263948, -8.840514946413123],
              [-36.071655828864436, -8.840647258476109],
              [-36.071532928455376, -8.84081717204198],
              [-36.071504516930538, -8.840840718839351],
              [-36.071467519671963, -8.840855402108007],
              [-36.071424575329104, -8.840860681000914],
              [-36.071357635561036, -8.84085454281243],
              [-36.071326016196217, -8.840855418492204],
              [-36.071296589625213, -8.840864546229314],
              [-36.071276488089353, -8.840884227687109],
              [-36.071256232691894, -8.840923372297151],
              [-36.071243794102848, -8.840973433150598],
              [-36.071221778434975, -8.840997217092037],
              [-36.071194512100966, -8.841018714081692],
              [-36.071133806939933, -8.841033211894448],
              [-36.071069098429938, -8.841030459931392],
              [-36.070997058913228, -8.841002945863666],
              [-36.070940919358044, -8.840963578100332],
              [-36.070910357427344, -8.840925907635315],
              [-36.070898552074873, -8.840895870153842],
              [-36.070901236092901, -8.840841989895777],
              [-36.070908465478333, -8.840784402038777],
              [-36.070933763911874, -8.840726206874789],
              [-36.070980805750708, -8.840678662561869],
              [-36.071034209423317, -8.840635659763118],
              [-36.071062340799408, -8.840599945600136],
              [-36.071078788649686, -8.840566385940296],
              [-36.071076298903385, -8.840500487146501],
              [-36.071079646738333, -8.840457841495953],
              [-36.07109648273029, -8.840422787609478],
              [-36.071127677852907, -8.840380359734974],
              [-36.071191348479182, -8.840323961867327],
              [-36.071334109129573, -8.840164871673915],
              [-36.071361617978631, -8.840112682766637],
              [-36.071367128973108, -8.840082032071884],
              [-36.071362138668455, -8.840046807490527],
              [-36.071344178529372, -8.839986028119084],
              [-36.071325306003352, -8.839945454589083],
              [-36.07128950524806, -8.839904000058331],
              [-36.071187763118537, -8.839825347079495],
              [-36.071153467648649, -8.839783904315448],
              [-36.071102845128664, -8.83971313736337],
              [-36.070971648888644, -8.839551904881864],
              [-36.070891335543308, -8.83942850173198],
              [-36.070807633016685, -8.839352984301511],
              [-36.070704385889989, -8.839274319499578],
              [-36.070569433228641, -8.839210359416679],
              [-36.071482938862047, -8.837697101553815],
              [-36.071421065375226, -8.837205446111392],
              [-36.071247317533356, -8.836400434527787],
              [-36.070804792907467, -8.835287977285203],
              [-36.070916750600183, -8.834556115100648],
              [-36.069537688306355, -8.832471701375903],
              [-36.069272985264817, -8.83014332254562],
              [-36.070385882765578, -8.830174694899958],
              [-36.072941893603229, -8.82835300911437],
              [-36.072703650344103, -8.82837743812453],
              [-36.072062553418682, -8.828258335326462],
              [-36.070513185105227, -8.82824668719193],
              [-36.069980303084968, -8.828133846736598],
              [-36.069367715019801, -8.827924352829534],
              [-36.068182931396677, -8.827301874666114],
              [-36.066708638164542, -8.826422737038156],
              [-36.066618038556904, -8.826302510801536],
              [-36.066521965579341, -8.826116385057048],
              [-36.066086034630622, -8.827974668771214],
              [-36.065871338723618, -8.827904331955434],
              [-36.065161783700454, -8.8274157410711],
              [-36.064620450821586, -8.82704003375331],
              [-36.06407727584309, -8.826552924547999],
              [-36.06404584193416, -8.826485195862627],
              [-36.064067221765633, -8.826446156425776],
              [-36.063511578919886, -8.826065729034813],
              [-36.06295919426384, -8.825600833424893],
              [-36.062942937293961, -8.825587151277444],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0304000',
        uf: 'PE',
        nome_proje: 'PA MARIA PARAIBA',
        municipio: 'IBIMIRIM',
        area_hecta: '1183.7154',
        capacidade: 15.0,
        num_famili: 15.0,
        fase: 3.0,
        data_de_cr: '31/10/2012',
        forma_obte: 'Desapropriação',
        data_obten: '31/10/2012',
        area_calc_: 1183.9695,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.590860330887452, -8.655560092700325],
              [-37.649156554149933, -8.660924118325742],
              [-37.649364272791452, -8.660511834537958],
              [-37.649409722618387, -8.660155225805038],
              [-37.649347579552163, -8.659920226983571],
              [-37.647572748659464, -8.657793176607319],
              [-37.646635955948291, -8.65547574777738],
              [-37.646475351134427, -8.655272643349715],
              [-37.645646431150148, -8.654926796743341],
              [-37.645394856515964, -8.654926139830446],
              [-37.642688277622149, -8.655685969415893],
              [-37.63794050300082, -8.651492885218653],
              [-37.635205725102118, -8.649222082432509],
              [-37.63816985821132, -8.646267292668352],
              [-37.641280810713006, -8.642603617626632],
              [-37.640784738645777, -8.640960598210642],
              [-37.592132972679998, -8.635806855940844],
              [-37.590860330887452, -8.655560092700325],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0136000',
        uf: 'PE',
        nome_proje: 'PA RIACHO DA SERRA',
        municipio: 'BELEM DE SAO FRANCISCO',
        area_hecta: '160.1126',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 4.0,
        data_de_cr: '27/12/2004',
        forma_obte: 'Confisco',
        data_obten: '27/12/2004',
        area_calc_: 160.248,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.932010814280972, -8.571208439927798],
              [-38.929943338907044, -8.571455731486552],
              [-38.929206250028969, -8.572890276101084],
              [-38.928775807558047, -8.573167617058504],
              [-38.928497117334096, -8.573503870717936],
              [-38.927237317319296, -8.573865357894974],
              [-38.92712741552122, -8.574986866846917],
              [-38.926543539015185, -8.574946142699163],
              [-38.925092222788983, -8.57454189971755],
              [-38.924425870206008, -8.575682837333243],
              [-38.922252746905137, -8.576475047364353],
              [-38.923514339498368, -8.57812172882833],
              [-38.923896508165861, -8.579350520776742],
              [-38.923987089145875, -8.579473012271768],
              [-38.932377142323219, -8.588637573449615],
              [-38.937977851822325, -8.588419256707976],
              [-38.939159029038869, -8.588369876585267],
              [-38.938458127639414, -8.586887327923927],
              [-38.937935004952557, -8.586442666602036],
              [-38.937097237033512, -8.586679608673141],
              [-38.93535360149702, -8.586242702703235],
              [-38.934330254125584, -8.586491366513254],
              [-38.933344139817244, -8.585849250996123],
              [-38.933538083895051, -8.585285761061273],
              [-38.933062734253049, -8.585054661380104],
              [-38.933617656250512, -8.583935308482456],
              [-38.934460540948031, -8.582984970327033],
              [-38.935130407890995, -8.582456022723022],
              [-38.930610461284388, -8.577462489951181],
              [-38.931702423273883, -8.57606174502744],
              [-38.933052229865837, -8.574461411331622],
              [-38.93425686144387, -8.574645419325917],
              [-38.934019639497031, -8.573257916439537],
              [-38.93547281387611, -8.571833523147012],
              [-38.936256328081171, -8.571410693783649],
              [-38.942986596660297, -8.568469390981635],
              [-38.942269308465988, -8.567320708555801],
              [-38.936227212884603, -8.569983788638853],
              [-38.935390475506821, -8.570245602275214],
              [-38.933000034893112, -8.571009706061906],
              [-38.932010814280972, -8.571208439927798],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0191000',
        uf: 'PE',
        nome_proje: 'PA CACHOEIRA I',
        municipio: 'SERRA TALHADA',
        area_hecta: '2641.4570',
        capacidade: 40.0,
        num_famili: 39.0,
        fase: 3.0,
        data_de_cr: '15/10/2006',
        forma_obte: 'Desapropriação',
        data_obten: '29/07/2006',
        area_calc_: 2769.1175,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.476127805800552, -7.707560714618579],
              [-38.456683139196251, -7.717349484954013],
              [-38.456587113625297, -7.717132998964996],
              [-38.456515542425549, -7.717010434430701],
              [-38.456438418092965, -7.716833862281782],
              [-38.456279730934099, -7.716477094182578],
              [-38.456227455493803, -7.716298563615728],
              [-38.456216097249957, -7.716245814974794],
              [-38.456200838637777, -7.716193965907546],
              [-38.456177236136298, -7.716143191671607],
              [-38.456152709742902, -7.716105803330699],
              [-38.456080271164097, -7.716023579753197],
              [-38.456057989809892, -7.716003380365815],
              [-38.456039336673122, -7.715982371512025],
              [-38.456021032500693, -7.715972127029369],
              [-38.455984807105246, -7.715935809242819],
              [-38.451243001698977, -7.716493999829371],
              [-38.451242977896257, -7.716493999330323],
              [-38.451242931318852, -7.716494004934796],
              [-38.45124288477114, -7.716494010779612],
              [-38.451242838254359, -7.71649401686454],
              [-38.451242791769758, -7.716494023189472],
              [-38.451242745318588, -7.716494029754183],
              [-38.451242698902092, -7.716494036558552],
              [-38.451242652521508, -7.716494043602379],
              [-38.451242606178084, -7.716494050885481],
              [-38.451242559873037, -7.71649405840762],
              [-38.451242513607639, -7.716494066168668],
              [-38.451242467383111, -7.716494074168341],
              [-38.451242421200689, -7.716494082406466],
              [-38.451242375061618, -7.716494090882843],
              [-38.45124232896714, -7.716494099597226],
              [-38.451242282918464, -7.716494108549345],
              [-38.451242236916833, -7.716494117738996],
              [-38.451242190963484, -7.716494127165952],
              [-38.451242145059645, -7.71649413682994],
              [-38.451242099206553, -7.716494146730655],
              [-38.451242053405416, -7.716494156867918],
              [-38.451242007657477, -7.716494167241412],
              [-38.451241961963952, -7.716494177850846],
              [-38.451241916326062, -7.716494188695926],
              [-38.451241870745029, -7.716494199776429],
              [-38.451241825222091, -7.716494211092002],
              [-38.451241779758448, -7.71649422264238],
              [-38.451241734355307, -7.716494234427196],
              [-38.451241689013912, -7.716494246446197],
              [-38.45124164373545, -7.716494258699041],
              [-38.451241598521165, -7.71649427118537],
              [-38.451241553372235, -7.716494283904875],
              [-38.451241508289883, -7.716494296857221],
              [-38.451241463275316, -7.71649431004204],
              [-38.451241418329722, -7.716494323459],
              [-38.451241373454337, -7.716494337107743],
              [-38.45124132865034, -7.716494350987869],
              [-38.451241283918925, -7.716494365099059],
              [-38.451241239261307, -7.716494379440892],
              [-38.451241194678673, -7.716494394013035],
              [-38.451241150172216, -7.716494408815056],
              [-38.451241105743129, -7.716494423846542],
              [-38.451241061392601, -7.716494439107127],
              [-38.451241017121809, -7.716494454596412],
              [-38.451240972931956, -7.716494470313942],
              [-38.451240928824213, -7.716494486259309],
              [-38.451240884799759, -7.716494502432123],
              [-38.451240840859775, -7.716494518831865],
              [-38.451240797005454, -7.71649453545821],
              [-38.45124075323794, -7.716494552310633],
              [-38.451240709558419, -7.716494569388711],
              [-38.451240665968072, -7.716494586691966],
              [-38.451240622468049, -7.716494604219969],
              [-38.451240579059522, -7.716494621972222],
              [-38.451240535743651, -7.71649463994827],
              [-38.451240492521592, -7.716494658147607],
              [-38.451240449394497, -7.71649467656975],
              [-38.451240406363553, -7.716494695214241],
              [-38.451240363429861, -7.716494714080548],
              [-38.451240320594607, -7.716494733168161],
              [-38.451240277858936, -7.716494752476592],
              [-38.451240235223977, -7.7164947720053],
              [-38.451240192690868, -7.716494791753809],
              [-38.451240150260773, -7.716494811721534],
              [-38.451240107934808, -7.71649483190798],
              [-38.451240065714096, -7.716494852312591],
              [-38.451240023599794, -7.71649487293478],
              [-38.451239981593012, -7.716494893774094],
              [-38.451239939694879, -7.716494914829881],
              [-38.451239897906518, -7.71649493610163],
              [-38.451239856229051, -7.716494957588754],
              [-38.451239814663559, -7.716494979290656],
              [-38.451239773211213, -7.716495001206824],
              [-38.451239731873095, -7.716495023336586],
              [-38.451239690650297, -7.716495045679393],
              [-38.451239649543943, -7.716495068234662],
              [-38.45123960855512, -7.716495091001767],
              [-38.451239567684929, -7.716495113980081],
              [-38.451239526934465, -7.716495137169031],
              [-38.451239486304836, -7.716495160567963],
              [-38.451239445797093, -7.716495184176236],
              [-38.451239405412352, -7.71649520799328],
              [-38.451239365151665, -7.716495232018408],
              [-38.45123932501614, -7.716495256250982],
              [-38.451239285006842, -7.716495280690371],
              [-38.451239245124839, -7.716495305335966],
              [-38.451239205371188, -7.71649533018698],
              [-38.451239165746969, -7.716495355242855],
              [-38.451239126253242, -7.716495380502888],
              [-38.451239086891043, -7.716495405966412],
              [-38.45123904766146, -7.716495431632711],
              [-38.451239008565508, -7.716495457501135],
              [-38.451238969604255, -7.71649548357097],
              [-38.45123893077875, -7.716495509841521],
              [-38.451238892090004, -7.716495536312098],
              [-38.451238853539088, -7.716495562982],
              [-38.451238815127006, -7.716495589850481],
              [-38.451238776854794, -7.716495616916855],
              [-38.451238738723461, -7.716495644180349],
              [-38.451238700734081, -7.716495671640287],
              [-38.451238737667602, -7.716495622515495],
              [-38.448496726443715, -7.718488395415374],
              [-38.448496742396834, -7.71848843795837],
              [-38.448496723341947, -7.718488451768957],
              [-38.44849670425117, -7.718488465530147],
              [-38.448496685124624, -7.718488479241828],
              [-38.448496665962431, -7.718488492903894],
              [-38.448496646764738, -7.718488506516263],
              [-38.448496627531654, -7.718488520078849],
              [-38.448496608263319, -7.718488533591554],
              [-38.448496588959863, -7.71848854705429],
              [-38.448496569621412, -7.71848856046698],
              [-38.448496550248102, -7.718488573829518],
              [-38.448496530840067, -7.718488587141821],
              [-38.44849651139743, -7.718488600403769],
              [-38.448496491920324, -7.718488613615313],
              [-38.448496472408884, -7.718488626776353],
              [-38.448496452863239, -7.718488639886773],
              [-38.448496433283509, -7.718488652946534],
              [-38.448496413669844, -7.71848866595549],
              [-38.448496394022378, -7.718488678913605],
              [-38.448496374341232, -7.71848869182076],
              [-38.448496354626528, -7.718488704676876],
              [-38.448496334878442, -7.718488717481881],
              [-38.448496315097053, -7.71848873023569],
              [-38.448496295282531, -7.718488742938188],
              [-38.448496275434991, -7.718488755589291],
              [-38.448496255554588, -7.718488768188949],
              [-38.448496235641436, -7.718488780737042],
              [-38.448496215695677, -7.718488793233486],
              [-38.448496195717439, -7.718488805678215],
              [-38.448496175706865, -7.718488818071164],
              [-38.448496155664095, -7.718488830412214],
              [-38.448496135589245, -7.718488842701275],
              [-38.448496115482484, -7.718488854938287],
              [-38.448496095343899, -7.718488867123185],
              [-38.448496075173672, -7.718488879255841],
              [-38.448496054971912, -7.718488891336227],
              [-38.448496034738767, -7.718488903364179],
              [-38.448496014474365, -7.718488915339689],
              [-38.448495994178835, -7.718488927262643],
              [-38.44849597385236, -7.718488939132993],
              [-38.448495953495019, -7.718488950950639],
              [-38.448495933106983, -7.71848896271549],
              [-38.448495912688372, -7.718488974427485],
              [-38.448495892239343, -7.718488986086533],
              [-38.448495871760009, -7.718488997692575],
              [-38.448495851250541, -7.71848900924549],
              [-38.448495830711039, -7.718489020745261],
              [-38.448495810141672, -7.718489032191732],
              [-38.448495789542562, -7.718489043584897],
              [-38.448495768913865, -7.718489054924627],
              [-38.448495748255695, -7.718489066210882],
              [-38.448495727568222, -7.718489077443586],
              [-38.448495706851567, -7.718489088622642],
              [-38.448495686105858, -7.718489099747999],
              [-38.448495665331258, -7.718489110819558],
              [-38.448495644527895, -7.71848912183725],
              [-38.448495623695905, -7.718489132800991],
              [-38.44849560283545, -7.718489143710746],
              [-38.448495581946645, -7.718489154566418],
              [-38.448495561029645, -7.718489165367918],
              [-38.4484955400846, -7.718489176115168],
              [-38.448495519111638, -7.718489186808127],
              [-38.448495498110887, -7.718489197446735],
              [-38.448495477082517, -7.718489208030852],
              [-38.448495456026663, -7.718489218560481],
              [-38.448495434943446, -7.718489229035521],
              [-38.448495413833029, -7.718489239455871],
              [-38.448495392695555, -7.718489249821531],
              [-38.448495371531152, -7.718489260132348],
              [-38.448495350339975, -7.718489270388305],
              [-38.448495329122153, -7.718489280589323],
              [-38.448495307877849, -7.718489290735348],
              [-38.448495286607205, -7.718489300826295],
              [-38.448495265310349, -7.718489310862079],
              [-38.448495243987423, -7.718489320842672],
              [-38.448495222638591, -7.71848933076797],
              [-38.448495201263981, -7.718489340637955],
              [-38.448495179863748, -7.718489350452476],
              [-38.448495158438021, -7.71848936021152],
              [-38.448495136986956, -7.718489369915049],
              [-38.448495115510703, -7.718489379562959],
              [-38.448495094009395, -7.718489389155156],
              [-38.448495072483183, -7.718489398691641],
              [-38.448495050932209, -7.718489408172323],
              [-38.448495029356621, -7.718489417597142],
              [-38.448495007756563, -7.718489426966008],
              [-38.448494986132189, -7.718489436278891],
              [-38.448494964483622, -7.718489445535707],
              [-38.448494942811038, -7.71848945473642],
              [-38.448494921114552, -7.71848946388093],
              [-38.448494899394348, -7.71848947296919],
              [-38.448494877650546, -7.718489482001179],
              [-38.44849485588329, -7.718489490976777],
              [-38.448494834092735, -7.718489499895957],
              [-38.44849481227903, -7.71848950875865],
              [-38.448494790442318, -7.718489517564754],
              [-38.448494768582755, -7.718489526314285],
              [-38.44849474670049, -7.718489535007162],
              [-38.448494724795644, -7.718489543643281],
              [-38.448494702868608, -7.718489552221297],
              [-38.448494729964281, -7.718489568730688],
              [-38.444749648836883, -7.719949247508516],
              [-38.444749654075046, -7.71994923593972],
              [-38.444749629838462, -7.719949245426136],
              [-38.444749605629298, -7.71994925498193],
              [-38.444749581447745, -7.719949264607027],
              [-38.444749557293974, -7.719949274301333],
              [-38.444749533168221, -7.719949284064758],
              [-38.444749509070661, -7.719949293897258],
              [-38.444749485001502, -7.719949303798745],
              [-38.444749460960942, -7.719949313769106],
              [-38.444749436949181, -7.719949323808276],
              [-38.444749412966416, -7.719949333916173],
              [-38.444749389012863, -7.719949344092718],
              [-38.444749365088683, -7.71994935433784],
              [-38.44474934119409, -7.719949364651401],
              [-38.444749317329283, -7.719949375033388],
              [-38.444749293494461, -7.719949385483649],
              [-38.444749269689801, -7.719949396002145],
              [-38.444749245915524, -7.719949406588771],
              [-38.444749222171822, -7.719949417243448],
              [-38.444749198458886, -7.719949427966067],
              [-38.444749174776902, -7.719949438756569],
              [-38.444749151126096, -7.719949449614843],
              [-38.444749127506626, -7.719949460540803],
              [-38.44474910391871, -7.719949471534367],
              [-38.444749080362527, -7.719949482595444],
              [-38.444749056838276, -7.719949493723942],
              [-38.444749033346156, -7.719949504919757],
              [-38.444749009886358, -7.719949516182817],
              [-38.444748986459082, -7.719949527513013],
              [-38.444748963064519, -7.719949538910271],
              [-38.444748939702841, -7.719949550374458],
              [-38.444748916374287, -7.719949561905508],
              [-38.44474889307898, -7.719949573503319],
              [-38.444748869817175, -7.719949585167809],
              [-38.444748846589043, -7.719949596898877],
              [-38.444748823394768, -7.719949608696419],
              [-38.444748800234535, -7.719949620560338],
              [-38.444748777108551, -7.719949632490546],
              [-38.444748754017013, -7.719949644486928],
              [-38.444748730960114, -7.7199496565494],
              [-38.444748707937997, -7.719949668677882],
              [-38.444748684950909, -7.719949680872211],
              [-38.444748661999007, -7.719949693132346],
              [-38.444748639082484, -7.719949705458149],
              [-38.44474861620153, -7.719949717849541],
              [-38.444748593356351, -7.7199497303064],
              [-38.444748570547119, -7.719949742828643],
              [-38.444748547774019, -7.719949755416151],
              [-38.444748525037241, -7.719949768068839],
              [-38.444748502336978, -7.719949780786578],
              [-38.444748479673414, -7.719949793569277],
              [-38.444748457046735, -7.71994980641684],
              [-38.444748434457146, -7.719949819329135],
              [-38.444748411904804, -7.719949832306103],
              [-38.444748389389908, -7.719949845347581],
              [-38.444748366912648, -7.719949858453488],
              [-38.44474834447319, -7.719949871623732],
              [-38.444748322071753, -7.719949884858182],
              [-38.444748299708486, -7.719949898156726],
              [-38.444748277383596, -7.719949911519276],
              [-38.444748255097259, -7.719949924945691],
              [-38.444748232849655, -7.719949938435879],
              [-38.444748210640981, -7.719949951989753],
              [-38.444748188471401, -7.71994996560716],
              [-38.444748166341114, -7.719949979288003],
              [-38.444748144250312, -7.719949993032159],
              [-38.444748122199137, -7.719950006839548],
              [-38.444748100187809, -7.719950020710021],
              [-38.444748078216492, -7.719950034643489],
              [-38.44474805628537, -7.7199500486398],
              [-38.444748034394628, -7.71995006269887],
              [-38.444748012544444, -7.719950076820589],
              [-38.444747990735003, -7.719950091004829],
              [-38.444747968966468, -7.719950105251478],
              [-38.44474794723903, -7.719950119560385],
              [-38.444747925552875, -7.719950133931481],
              [-38.444747903908173, -7.719950148364614],
              [-38.444747882305109, -7.719950162859672],
              [-38.444747860743838, -7.719950177416543],
              [-38.444747839224583, -7.719950192035104],
              [-38.444747817747462, -7.719950206715264],
              [-38.444747796312704, -7.719950221456855],
              [-38.444747774920479, -7.71995023625978],
              [-38.444747753570915, -7.719950251123884],
              [-38.444747732264254, -7.719950266049086],
              [-38.444747711000623, -7.719950281035265],
              [-38.444747689780229, -7.719950296082237],
              [-38.44474766860322, -7.719950311189955],
              [-38.444747647469789, -7.719950326358261],
              [-38.444747626380114, -7.719950341587031],
              [-38.444747605334349, -7.719950356876121],
              [-38.444747584332674, -7.71995037222543],
              [-38.44474756337528, -7.719950387634809],
              [-38.444747542462324, -7.719950403104176],
              [-38.444747521593975, -7.719950418633376],
              [-38.444747500770404, -7.719950434222266],
              [-38.444747479991804, -7.719950449870715],
              [-38.444747459258323, -7.719950465578617],
              [-38.444747438570147, -7.719950481345831],
              [-38.444747417927431, -7.719950497172221],
              [-38.444747470863085, -7.719950511022347],
              [-38.442673611602729, -7.721545121902601],
              [-38.442673610672131, -7.721545166242147],
              [-38.442673597417503, -7.721545176453795],
              [-38.442673584181797, -7.721545186689827],
              [-38.442673570965063, -7.721545196950226],
              [-38.442673557767364, -7.721545207234949],
              [-38.442673544588722, -7.721545217544022],
              [-38.442673531429186, -7.721545227877315],
              [-38.442673518288814, -7.721545238234842],
              [-38.442673505167633, -7.721545248616573],
              [-38.442673492065708, -7.72154525902244],
              [-38.442673478983053, -7.721545269452457],
              [-38.442673465919746, -7.721545279906524],
              [-38.442673452875823, -7.721545290384659],
              [-38.442673439851312, -7.721545300886779],
              [-38.442673426846284, -7.721545311412896],
              [-38.442673413860746, -7.72154532196295],
              [-38.442673400894776, -7.721545332536897],
              [-38.442673387948396, -7.721545343134717],
              [-38.44267337502167, -7.721545353756365],
              [-38.442673362114633, -7.721545364401811],
              [-38.442673349227313, -7.721545375071042],
              [-38.442673336359796, -7.721545385763983],
              [-38.442673323512089, -7.721545396480622],
              [-38.442673310684242, -7.721545407220874],
              [-38.44267329787629, -7.721545417984795],
              [-38.442673285088318, -7.721545428772273],
              [-38.44267327232032, -7.721545439583264],
              [-38.442673259572373, -7.721545450417773],
              [-38.4426732468445, -7.721545461275773],
              [-38.442673234136755, -7.721545472157183],
              [-38.442673221449184, -7.721545483061952],
              [-38.442673208781827, -7.721545493990107],
              [-38.442673196134713, -7.721545504941581],
              [-38.4426731835079, -7.72154551591634],
              [-38.442673170901443, -7.721545526914319],
              [-38.442673158315365, -7.721545537935546],
              [-38.442673145749716, -7.72154554897991],
              [-38.442673133204529, -7.721545560047404],
              [-38.442673120679856, -7.721545571137987],
              [-38.442673108175761, -7.721545582251645],
              [-38.442673095692236, -7.72154559338833],
              [-38.442673083229366, -7.721545604547987],
              [-38.442673070787173, -7.721545615730552],
              [-38.442673058365706, -7.721545626936056],
              [-38.442673045965009, -7.721545638164416],
              [-38.442673033585137, -7.721545649415601],
              [-38.442673021226106, -7.721545660689556],
              [-38.442673008887958, -7.721545671986266],
              [-38.442672996570757, -7.721545683305682],
              [-38.442672984274552, -7.721545694647785],
              [-38.442672971999365, -7.721545706012543],
              [-38.442672959745217, -7.721545717399852],
              [-38.442672947512186, -7.721545728809754],
              [-38.442672935300315, -7.721545740242173],
              [-38.442672923109626, -7.721545751697027],
              [-38.442672910940153, -7.721545763174369],
              [-38.442672898791969, -7.721545774674092],
              [-38.4426728866651, -7.721545786196168],
              [-38.44267287455957, -7.721545797740563],
              [-38.44267286247544, -7.721545809307242],
              [-38.442672850412755, -7.72154582089617],
              [-38.442672838371529, -7.72154583250732],
              [-38.442672826351846, -7.721545844140597],
              [-38.44267281435372, -7.721545855796013],
              [-38.44267280237716, -7.721545867473508],
              [-38.442672790422286, -7.721545879173051],
              [-38.442672778489076, -7.721545890894609],
              [-38.442672766577594, -7.721545902638113],
              [-38.442672754687869, -7.721545914403551],
              [-38.442672742819958, -7.72154592619087],
              [-38.442672730973882, -7.721545938000018],
              [-38.442672719149705, -7.72154594983101],
              [-38.442672707347441, -7.721545961683732],
              [-38.442672695567154, -7.721545973558183],
              [-38.442672683808865, -7.721545985454315],
              [-38.442672672072611, -7.721545997372092],
              [-38.442672660358461, -7.721546009311478],
              [-38.442672648666431, -7.721546021272407],
              [-38.442672636996576, -7.72154603325485],
              [-38.442672625348912, -7.7215460452588],
              [-38.442672613723502, -7.721546057284177],
              [-38.442672602120368, -7.721546069330945],
              [-38.442672590539566, -7.721546081399076],
              [-38.442672578981139, -7.721546093488512],
              [-38.442672567445108, -7.721546105599224],
              [-38.442672555931516, -7.721546117731145],
              [-38.442672544440413, -7.721546129884302],
              [-38.442672532971827, -7.721546142058584],
              [-38.442672521525807, -7.721546154253974],
              [-38.442672510102383, -7.721546166470437],
              [-38.442672498701597, -7.72154617870792],
              [-38.442672487323485, -7.721546190966376],
              [-38.442672475968102, -7.72154620324577],
              [-38.442672464635471, -7.721546215546072],
              [-38.442672453325628, -7.721546227867222],
              [-38.442672442038614, -7.721546240209195],
              [-38.442672430774479, -7.721546252571922],
              [-38.442672419533245, -7.721546264955419],
              [-38.442672408314969, -7.721546277359581],
              [-38.442672397119658, -7.721546289784402],
              [-38.442672431217446, -7.721546296252226],
              [-38.440625590324721, -7.723822132151001],
              [-38.440625528257549, -7.723822162112215],
              [-38.440625522850048, -7.723822168130101],
              [-38.440625517447913, -7.723822174152795],
              [-38.440625512051184, -7.723822180180316],
              [-38.44062550665987, -7.723822186212631],
              [-38.440625501273963, -7.723822192249737],
              [-38.440625495893443, -7.723822198291636],
              [-38.440625490518343, -7.723822204338339],
              [-38.440625485148686, -7.723822210389855],
              [-38.44062547978443, -7.723822216446127],
              [-38.440625474425602, -7.723822222507173],
              [-38.440625469072202, -7.723822228573014],
              [-38.440625463724245, -7.723822234643613],
              [-38.440625458381732, -7.723822240718953],
              [-38.440625453044653, -7.723822246799049],
              [-38.440625447713025, -7.723822252883943],
              [-38.440625442386846, -7.723822258973573],
              [-38.440625437066132, -7.723822265067933],
              [-38.44062543175086, -7.723822271167042],
              [-38.440625426441073, -7.723822277270865],
              [-38.440625421136744, -7.723822283379428],
              [-38.440625415837907, -7.7238222894927],
              [-38.440625410544527, -7.723822295610733],
              [-38.44062540525664, -7.723822301733452],
              [-38.440625399974223, -7.723822307860896],
              [-38.440625394697314, -7.723822313993032],
              [-38.44062538942589, -7.723822320129875],
              [-38.440625384159965, -7.723822326271392],
              [-38.440625378899561, -7.723822332417619],
              [-38.44062537364465, -7.723822338568537],
              [-38.440625368395253, -7.723822344724145],
              [-38.440625363151362, -7.723822350884411],
              [-38.440625357912992, -7.723822357049365],
              [-38.440625352680158, -7.723822363218982],
              [-38.440625347452844, -7.723822369393234],
              [-38.440625342231058, -7.723822375572147],
              [-38.440625337014815, -7.723822381755732],
              [-38.440625331804121, -7.723822387943971],
              [-38.440625326598955, -7.723822394136823],
              [-38.440625321399352, -7.723822400334331],
              [-38.440625316205299, -7.723822406536443],
              [-38.440625311016795, -7.723822412743198],
              [-38.440625305833855, -7.723822418954561],
              [-38.440625300656485, -7.723822425170564],
              [-38.440625295484686, -7.723822431391171],
              [-38.440625290318458, -7.723822437616386],
              [-38.440625285157815, -7.723822443846207],
              [-38.440625280002742, -7.723822450080605],
              [-38.440625274853254, -7.723822456319605],
              [-38.440625269709372, -7.723822462563194],
              [-38.440625264571061, -7.723822468811379],
              [-38.440625259438363, -7.723822475064118],
              [-38.440625254311257, -7.723822481321445],
              [-38.440625249189779, -7.723822487583312],
              [-38.440625317806351, -7.723822493605996],
              [-38.439757007950988, -7.724884991966637],
              [-38.439756993580765, -7.724885039248728],
              [-38.439756944815109, -7.724885098413497],
              [-38.43975689554896, -7.724885157164298],
              [-38.439756845785823, -7.724885215496846],
              [-38.439756795529291, -7.724885273407007],
              [-38.439756744782947, -7.724885330890586],
              [-38.439756693550443, -7.724885387943483],
              [-38.439756641835444, -7.724885444561608],
              [-38.439756589641675, -7.724885500740901],
              [-38.439756536972851, -7.724885556477324],
              [-38.439756483832753, -7.724885611766891],
              [-38.439756430225216, -7.72488566660563],
              [-38.439756376154079, -7.724885720989607],
              [-38.439756321623193, -7.72488577491493],
              [-38.439756266636486, -7.724885828377714],
              [-38.439756211197903, -7.72488588137414],
              [-38.439756155311414, -7.724885933900421],
              [-38.439756098981029, -7.724885985952776],
              [-38.439756042210774, -7.724886037527464],
              [-38.439755985004737, -7.724886088620788],
              [-38.439755927367017, -7.724886139229128],
              [-38.439755869301749, -7.724886189348802],
              [-38.439755810813082, -7.724886238976244],
              [-38.439755751905224, -7.724886288107869],
              [-38.439755692582388, -7.724886336740181],
              [-38.439755632848843, -7.72488638486969],
              [-38.439755572708862, -7.72488643249294],
              [-38.439755512166762, -7.724886479606517],
              [-38.439755451226873, -7.724886526207032],
              [-38.43975538989357, -7.724886572291141],
              [-38.439755328171245, -7.724886617855589],
              [-38.439755266064367, -7.724886662897053],
              [-38.439755203577313, -7.724886707412322],
              [-38.439755140714624, -7.724886751398207],
              [-38.439755077480775, -7.724886794851558],
              [-38.439755013880337, -7.724886837769215],
              [-38.439754949917834, -7.724886880148167],
              [-38.43975488559785, -7.724886921985321],
              [-38.439754820925032, -7.724886963277722],
              [-38.439754755903991, -7.724887004022368],
              [-38.439754690539395, -7.724887044216374],
              [-38.439754624835928, -7.724887083856846],
              [-38.439754558798292, -7.724887122940925],
              [-38.439754492431248, -7.724887161465823],
              [-38.439754425739551, -7.724887199428781],
              [-38.439754358727967, -7.724887236827096],
              [-38.4397542914013, -7.724887273658042],
              [-38.439754223764382, -7.724887309918981],
              [-38.439754155822072, -7.724887345607339],
              [-38.439754087579246, -7.724887380720568],
              [-38.439754019040777, -7.724887415256123],
              [-38.439753950211589, -7.724887449211539],
              [-38.439753881096614, -7.724887482584359],
              [-38.439753811700818, -7.724887515372214],
              [-38.439753742029168, -7.724887547572758],
              [-38.43975367208666, -7.724887579183664],
              [-38.439753601878309, -7.724887610202653],
              [-38.43975353140916, -7.724887640627538],
              [-38.439753460684251, -7.724887670456121],
              [-38.43975338970867, -7.724887699686284],
              [-38.439753318487483, -7.724887728315879],
              [-38.439753247025806, -7.724887756342905],
              [-38.439753175328782, -7.7248877837653],
              [-38.439753103401522, -7.724887810581144],
              [-38.439753031249211, -7.724887836788501],
              [-38.439752958876994, -7.724887862385478],
              [-38.439752886290094, -7.724887887370268],
              [-38.439752813493698, -7.724887911741041],
              [-38.439752740493006, -7.724887935496086],
              [-38.439752667293291, -7.72488795863369],
              [-38.439752593899783, -7.724887981152198],
              [-38.439752520317725, -7.724888003049966],
              [-38.439752446552419, -7.724888024325435],
              [-38.439752372609163, -7.724888044977106],
              [-38.439752298493232, -7.724888065003499],
              [-38.439752224209961, -7.724888084403148],
              [-38.439752149764651, -7.724888103174668],
              [-38.439752075162673, -7.724888121316726],
              [-38.439752000409356, -7.724888138827995],
              [-38.439751925510052, -7.724888155707253],
              [-38.439751850470159, -7.724888171953274],
              [-38.439751775295029, -7.724888187564888],
              [-38.439751699990062, -7.724888202540975],
              [-38.439751624560678, -7.724888216880479],
              [-38.439751549012271, -7.724888230582359],
              [-38.439751473350235, -7.724888243645619],
              [-38.439751397580025, -7.724888256069332],
              [-38.439751321707071, -7.72488826785265],
              [-38.439751245736822, -7.724888278994668],
              [-38.439751169674672, -7.724888289494602],
              [-38.439751093526134, -7.724888299351706],
              [-38.439751017296658, -7.724888308565285],
              [-38.439750940991679, -7.72488831713467],
              [-38.439750864616705, -7.724888325059251],
              [-38.4397507881772, -7.724888332338431],
              [-38.43975071167862, -7.724888338971726],
              [-38.439750635126494, -7.724888344958648],
              [-38.439750558526278, -7.724888350298738],
              [-38.439750481883472, -7.72488835499167],
              [-38.439750405203455, -7.724888359037299],
              [-38.439750383826457, -7.724888330039679],
              [-38.438417466481333, -7.724953063349205],
              [-38.438417459838462, -7.724953035094619],
              [-38.438417444867916, -7.724953035808642],
              [-38.438417429896219, -7.724953036497968],
              [-38.438417414923393, -7.724953037162611],
              [-38.4384173999495, -7.724953037802568],
              [-38.438417384974535, -7.724953038417826],
              [-38.438417369998596, -7.724953039008398],
              [-38.438417355021691, -7.724953039574287],
              [-38.43841734004387, -7.724953040115476],
              [-38.438417325065174, -7.724953040631998],
              [-38.438417310085633, -7.724953041123801],
              [-38.43841729510531, -7.724953041590907],
              [-38.438417280124234, -7.72495304203333],
              [-38.438417265142441, -7.724953042451049],
              [-38.438417250159979, -7.724953042844069],
              [-38.438417235176878, -7.724953043212405],
              [-38.438417220193202, -7.724953043556027],
              [-38.438417205208964, -7.724953043874947],
              [-38.438417190224222, -7.724953044169153],
              [-38.438417175239017, -7.724953044438656],
              [-38.438417160253373, -7.72495304468346],
              [-38.438417145267373, -7.724953044903548],
              [-38.438417130280996, -7.724953045098951],
              [-38.438417115294335, -7.724953045269636],
              [-38.438417100307397, -7.724953045415609],
              [-38.438417085320253, -7.724953045536882],
              [-38.438417070332918, -7.724953045633438],
              [-38.438417055345433, -7.724953045705291],
              [-38.438417040357891, -7.724953045752431],
              [-38.438417025370256, -7.724953045774874],
              [-38.438417010382601, -7.724953045772595],
              [-38.438416995394981, -7.724953045745603],
              [-38.438416980407432, -7.724953045693911],
              [-38.438416965419975, -7.724953045617505],
              [-38.438416950432682, -7.724953045516398],
              [-38.438416935445566, -7.724953045390575],
              [-38.438416920458678, -7.724953045240054],
              [-38.438416905472067, -7.724953045064818],
              [-38.438416890485748, -7.724953044864898],
              [-38.438416875499811, -7.724953044640225],
              [-38.438416860514252, -7.724953044390878],
              [-38.438416845529126, -7.724953044116829],
              [-38.438416830544476, -7.724953043818065],
              [-38.438416815560345, -7.7249530434946],
              [-38.438416800576753, -7.724953043146421],
              [-38.438416785593773, -7.724953042773526],
              [-38.438416770611433, -7.724953042375952],
              [-38.438416755629781, -7.724953041953677],
              [-38.438416740648826, -7.724953041506701],
              [-38.438416725668652, -7.724953041035034],
              [-38.438416710689268, -7.724953040538664],
              [-38.438416695710721, -7.724953040017597],
              [-38.438416680733063, -7.724953039471831],
              [-38.438416665756336, -7.724953038901385],
              [-38.438416650780574, -7.724953038306239],
              [-38.438416635805829, -7.724953037686415],
              [-38.438416620832108, -7.724953037041886],
              [-38.438416605859494, -7.724953036372685],
              [-38.438416590887996, -7.72495303567878],
              [-38.438416575917678, -7.724953034960248],
              [-38.438416560948554, -7.724953034216999],
              [-38.438416545980701, -7.724953033449089],
              [-38.438416531014148, -7.724953032656496],
              [-38.438416516048896, -7.724953031839242],
              [-38.438416501085044, -7.724953030997303],
              [-38.438416486122598, -7.724953030130702],
              [-38.438416471161617, -7.724953029239437],
              [-38.438416456202127, -7.724953028323491],
              [-38.438416441244179, -7.7249530273829],
              [-38.438416426287816, -7.724953026417646],
              [-38.438416411333058, -7.724953025427729],
              [-38.438416396379964, -7.724953024413161],
              [-38.438416381428574, -7.724953023373917],
              [-38.438416366478933, -7.724953022310042],
              [-38.438416351531068, -7.724953021221537],
              [-38.438416336585036, -7.724953020108387],
              [-38.438416321640844, -7.724953018970607],
              [-38.438416306698578, -7.724953017808162],
              [-38.438416291758259, -7.724953016621106],
              [-38.438416276819922, -7.724953015409423],
              [-38.438416261883617, -7.724953014173105],
              [-38.438416246949373, -7.724953012912161],
              [-38.438416232017246, -7.724953011626591],
              [-38.438416217087266, -7.724953010316423],
              [-38.43841620215948, -7.72495300898164],
              [-38.438416187233926, -7.724953007622266],
              [-38.438416172310646, -7.724953006238261],
              [-38.438416157389675, -7.724953004829661],
              [-38.43841614247107, -7.724953003396466],
              [-38.438416127554845, -7.724953001938659],
              [-38.438416112641058, -7.724953000456272],
              [-38.43841609772975, -7.72495299894931],
              [-38.438416082820964, -7.724952997417754],
              [-38.43841606791473, -7.72495299586165],
              [-38.438416053011096, -7.724952994280971],
              [-38.438416038110113, -7.724952992675711],
              [-38.438416023211786, -7.724952991045877],
              [-38.438416008316203, -7.724952989391497],
              [-38.438415993423376, -7.724952987712538],
              [-38.438415978533406, -7.724952986007555],
              [-38.438416015704526, -7.724952970989762],
              [-38.436076436758988, -7.724683325489322],
              [-38.436076380429874, -7.724683361472285],
              [-38.436076372310062, -7.724683360540247],
              [-38.436076364189418, -7.724683359615456],
              [-38.436076356067943, -7.724683358698025],
              [-38.436076347945651, -7.724683357787871],
              [-38.436076339822513, -7.724683356885028],
              [-38.436076331698573, -7.724683355989495],
              [-38.436076323573836, -7.724683355101258],
              [-38.43607631544829, -7.724683354220334],
              [-38.436076307321947, -7.724683353346702],
              [-38.436076299194823, -7.724683352480362],
              [-38.436076291066939, -7.72468335162135],
              [-38.436076282938274, -7.724683350769632],
              [-38.436076274808826, -7.724683349925216],
              [-38.436076266678619, -7.724683349088103],
              [-38.436076258547665, -7.724683348258321],
              [-38.436076250415972, -7.724683347435848],
              [-38.436076242283519, -7.724683346620703],
              [-38.436076234150356, -7.724683345812854],
              [-38.436076226016446, -7.724683345012298],
              [-38.436076217881833, -7.724683344219056],
              [-38.43607620974651, -7.72468334343314],
              [-38.436076201610469, -7.724683342654553],
              [-38.436076193473717, -7.724683341883272],
              [-38.436076185336304, -7.72468334111929],
              [-38.436076177198174, -7.724683340362652],
              [-38.436076169059362, -7.724683339613325],
              [-38.436076160919903, -7.724683338871293],
              [-38.436076152779762, -7.72468333813659],
              [-38.436076144638953, -7.724683337409213],
              [-38.436076136497491, -7.724683336689148],
              [-38.436076128355396, -7.724683335976411],
              [-38.436076120212661, -7.724683335270999],
              [-38.436076112069273, -7.724683334572904],
              [-38.436076103925252, -7.724683333882102],
              [-38.436076095780621, -7.724683333198658],
              [-38.436076087635385, -7.724683332522531],
              [-38.436076079489546, -7.724683331853706],
              [-38.436076071343102, -7.724683331192253],
              [-38.436076063196055, -7.724683330538073],
              [-38.436076055048417, -7.724683329891218],
              [-38.436076046900212, -7.724683329251748],
              [-38.436076038751416, -7.724683328619554],
              [-38.436076030602059, -7.724683327994702],
              [-38.436076022452148, -7.72468332737718],
              [-38.436076014301669, -7.724683326766987],
              [-38.436076006150643, -7.72468332616412],
              [-38.436075997999097, -7.724683325568587],
              [-38.436075989846998, -7.724683324980375],
              [-38.436075981694373, -7.724683324399477],
              [-38.436075973541229, -7.724683323825944],
              [-38.436075965387566, -7.724683323259698],
              [-38.436075957233392, -7.7246833227008],
              [-38.436075949078734, -7.724683322149252],
              [-38.436075940923573, -7.724683321605027],
              [-38.436075932767906, -7.724683321068134],
              [-38.436075924611771, -7.72468332053857],
              [-38.436075916455145, -7.724683320016346],
              [-38.436075908298079, -7.724683319501453],
              [-38.43607590014053, -7.724683318993873],
              [-38.436075892673458, -7.724683324771335],
              [-38.433872342707737, -7.724547174330073],
              [-38.433872314017734, -7.724547193709308],
              [-38.433872252428436, -7.724547190114249],
              [-38.433872190816039, -7.724547186937231],
              [-38.433872129183392, -7.724547184178418],
              [-38.433872067533358, -7.724547181837941],
              [-38.433872005868778, -7.724547179915882],
              [-38.433871944192553, -7.724547178412331],
              [-38.433871882507475, -7.72454717732739],
              [-38.433871820816414, -7.724547176661088],
              [-38.433871759122262, -7.724547176413453],
              [-38.433871697427819, -7.724547176584492],
              [-38.43387163573599, -7.724547177174229],
              [-38.433871574049611, -7.724547178182595],
              [-38.433871512371532, -7.724547179609578],
              [-38.433871450704629, -7.724547181455065],
              [-38.433871389051703, -7.724547183719014],
              [-38.433871327415687, -7.724547186401303],
              [-38.433871265799368, -7.724547189501822],
              [-38.433871204205623, -7.724547193020424],
              [-38.433871142637301, -7.724547196956939],
              [-38.433871081097273, -7.724547201311155],
              [-38.433871019588352, -7.724547206082911],
              [-38.433870958113403, -7.724547211271989],
              [-38.433870896675266, -7.724547216878136],
              [-38.433870835276799, -7.724547222901075],
              [-38.433870773920823, -7.724547229340553],
              [-38.433870712610187, -7.724547236196273],
              [-38.433870651347739, -7.724547243467903],
              [-38.433870590136308, -7.72454725115512],
              [-38.433870528978716, -7.724547259257568],
              [-38.433870467877796, -7.724547267774853],
              [-38.433870406836398, -7.724547276706582],
              [-38.433870345857329, -7.724547286052365],
              [-38.433870284943417, -7.724547295811736],
              [-38.433870224097483, -7.724547305984281],
              [-38.433870163322325, -7.724547316569494],
              [-38.43387010262078, -7.724547327566905],
              [-38.433870041995647, -7.724547338976007],
              [-38.433869981449739, -7.724547350796266],
              [-38.433869920985849, -7.724547363027141],
              [-38.433869860606791, -7.72454737566806],
              [-38.43386980031535, -7.724547388718451],
              [-38.433869740114289, -7.724547402177678],
              [-38.433869680006445, -7.724547416045158],
              [-38.433869619994567, -7.724547430320201],
              [-38.433869560081462, -7.724547445002172],
              [-38.433869500269857, -7.724547460090436],
              [-38.43386944056256, -7.724547475584219],
              [-38.433869380962314, -7.724547491482816],
              [-38.433869321471875, -7.724547507785543],
              [-38.433869262094049, -7.724547524491613],
              [-38.433869202831517, -7.72454754160023],
              [-38.433869143687033, -7.724547559110632],
              [-38.433869084663378, -7.724547577022023],
              [-38.433869025763251, -7.724547595333556],
              [-38.433868966989365, -7.724547614044368],
              [-38.433868908344472, -7.724547633153608],
              [-38.433868849831278, -7.724547652660379],
              [-38.433868791452483, -7.724547672563799],
              [-38.433868733210772, -7.724547692862947],
              [-38.433868675108883, -7.724547713556842],
              [-38.433868617149464, -7.724547734644582],
              [-38.433868559335245, -7.724547756125157],
              [-38.433868501668861, -7.724547777997579],
              [-38.433868444152999, -7.724547800260859],
              [-38.433868386790323, -7.724547822913916],
              [-38.433868329583454, -7.724547845955753],
              [-38.433868272535094, -7.724547869385268],
              [-38.433868215647848, -7.724547893201391],
              [-38.433868158924362, -7.72454791740303],
              [-38.433868102367263, -7.724547941989043],
              [-38.43386804597916, -7.724547966958284],
              [-38.433867989762675, -7.724547992309619],
              [-38.433867933720393, -7.724548018041898],
              [-38.433867877854922, -7.724548044153898],
              [-38.433867822168835, -7.724548070644408],
              [-38.43386776666474, -7.724548097512187],
              [-38.433867711345172, -7.724548124756039],
              [-38.433867656212698, -7.724548152374672],
              [-38.433867601269881, -7.724548180366808],
              [-38.433867546519252, -7.724548208731169],
              [-38.433867491963362, -7.724548237466418],
              [-38.433867437604725, -7.724548266571231],
              [-38.43386738344585, -7.724548296044294],
              [-38.433867329489274, -7.724548325884182],
              [-38.433867275737448, -7.724548356089564],
              [-38.433867222192895, -7.724548386659016],
              [-38.4338671688581, -7.724548417591132],
              [-38.433867115735488, -7.724548448884468],
              [-38.433867062827559, -7.724548480537609],
              [-38.433867010136751, -7.724548512549057],
              [-38.433866957665487, -7.724548544917357],
              [-38.433866905416224, -7.724548577640969],
              [-38.433866853391365, -7.724548610718402],
              [-38.433866801593311, -7.724548644148142],
              [-38.43386675002445, -7.72454867792861],
              [-38.433866698687218, -7.724548712058278],
              [-38.43386664758394, -7.724548746535538],
              [-38.433866596717003, -7.724548781358822],
              [-38.433866546088765, -7.724548816526495],
              [-38.433866536964992, -7.724548794789684],
              [-38.432064199755594, -7.725809847852345],
              [-38.432064173195585, -7.725809885950969],
              [-38.432064123892417, -7.725809920691599],
              [-38.432064074822172, -7.725809955758818],
              [-38.432064025987032, -7.7258099911511],
              [-38.432063977389177, -7.725810026866837],
              [-38.432063929030754, -7.725810062904475],
              [-38.432063880913873, -7.725810099262414],
              [-38.432063833040715, -7.725810135939054],
              [-38.432063785413376, -7.725810172932712],
              [-38.432063738033968, -7.725810210241831],
              [-38.432063690904592, -7.725810247864705],
              [-38.43206364402733, -7.725810285799684],
              [-38.43206359740428, -7.725810324045107],
              [-38.432063551037487, -7.725810362599212],
              [-38.432063504929012, -7.725810401460358],
              [-38.432063459080901, -7.725810440626802],
              [-38.432063413495186, -7.725810480096793],
              [-38.432063368173871, -7.725810519868599],
              [-38.432063323118989, -7.725810559940465],
              [-38.432063278332528, -7.725810600310603],
              [-38.432063233816457, -7.725810640977216],
              [-38.432063189572773, -7.725810681938513],
              [-38.432063145603429, -7.725810723192672],
              [-38.432063101910344, -7.72581076473788],
              [-38.432063058495515, -7.7258108065723],
              [-38.432063015360811, -7.72581084869405],
              [-38.432062972508156, -7.725810891101278],
              [-38.432062929939477, -7.725810933792083],
              [-38.43206288765662, -7.72581097676461],
              [-38.432062845661484, -7.725811020016948],
              [-38.432062803955908, -7.725811063547192],
              [-38.432062762541776, -7.725811107353355],
              [-38.432062721420884, -7.725811151433556],
              [-38.432062680595102, -7.725811195785809],
              [-38.432062640066192, -7.725811240408175],
              [-38.432062599835966, -7.72581128529862],
              [-38.432062559906207, -7.725811330455246],
              [-38.432062520278684, -7.725811375875978],
              [-38.432062480955182, -7.725811421558828],
              [-38.432062441937376, -7.725811467501787],
              [-38.43206240322705, -7.725811513702807],
              [-38.43206236482591, -7.725811560159811],
              [-38.432062326735654, -7.72581160687079],
              [-38.432062288957958, -7.725811653833622],
              [-38.432062251494514, -7.72581170104626],
              [-38.432062214346978, -7.725811748506617],
              [-38.432062177516983, -7.725811796212581],
              [-38.432062141006185, -7.725811844162023],
              [-38.432062104816168, -7.725811892352851],
              [-38.432062068948561, -7.725811940782878],
              [-38.432062033404947, -7.725811989450015],
              [-38.432061998186896, -7.725812038352098],
              [-38.432061963295993, -7.725812087486913],
              [-38.432061928733759, -7.725812136852313],
              [-38.432061894501729, -7.725812186446106],
              [-38.432061860601401, -7.725812236266133],
              [-38.432061827034332, -7.725812286310115],
              [-38.432061793801957, -7.725812336575888],
              [-38.432061760905768, -7.72581238706119],
              [-38.432061728347207, -7.725812437763818],
              [-38.432061696127754, -7.725812488681497],
              [-38.43206166424882, -7.725812539812027],
              [-38.4320616327118, -7.725812591153077],
              [-38.4320616015181, -7.725812642702349],
              [-38.43206157066912, -7.725812694457646],
              [-38.432061540166195, -7.725812746416591],
              [-38.432061510010705, -7.725812798576946],
              [-38.432061480203977, -7.725812850936385],
              [-38.432061450747327, -7.725812903492568],
              [-38.432061421642068, -7.725812956243209],
              [-38.432061392889487, -7.72581300918588],
              [-38.432061364490856, -7.725813062318329],
              [-38.432061336447411, -7.725813115638159],
              [-38.432061308760446, -7.725813169143019],
              [-38.432061281431153, -7.725813222830517],
              [-38.432061254460749, -7.72581327669829],
              [-38.432061227850433, -7.725813330743973],
              [-38.432061201601371, -7.725813384965135],
              [-38.432061175714736, -7.725813439359386],
              [-38.432061150191686, -7.725813493924308],
              [-38.432061125033329, -7.725813548657518],
              [-38.432061100240801, -7.72581360355656],
              [-38.432061075815184, -7.725813658618997],
              [-38.432061051757579, -7.725813713842416],
              [-38.432061028069036, -7.725813769224327],
              [-38.432061004750608, -7.725813824762318],
              [-38.432060981803339, -7.725813880453922],
              [-38.432060959228224, -7.725813936296611],
              [-38.432060937026279, -7.725813992287993],
              [-38.432060915198484, -7.725814048425548],
              [-38.432060893745813, -7.725814104706799],
              [-38.432060872669204, -7.725814161129266],
              [-38.432060851969595, -7.725814217690388],
              [-38.432060831647917, -7.725814274387721],
              [-38.43206081170505, -7.725814331218744],
              [-38.43206079214189, -7.725814388180925],
              [-38.432060772959304, -7.725814445271749],
              [-38.432060754158137, -7.72581450248866],
              [-38.432060735739228, -7.725814559829137],
              [-38.432060717703393, -7.725814617290657],
              [-38.432060747469549, -7.725814637261058],
              [-38.430114020455761, -7.732090008005211],
              [-38.430113951699859, -7.732090006795227],
              [-38.430113933342781, -7.732090066701928],
              [-38.430113915402075, -7.732090126734014],
              [-38.430113897878591, -7.732090186888584],
              [-38.430113880773192, -7.73209024716273],
              [-38.430113864086671, -7.732090307553618],
              [-38.430113847819868, -7.732090368058334],
              [-38.430113831973514, -7.73209042867397],
              [-38.43011381654842, -7.732090489397605],
              [-38.430113801545282, -7.732090550226348],
              [-38.43011378696486, -7.732090611157265],
              [-38.430113772807822, -7.732090672187444],
              [-38.43011375907485, -7.732090733313949],
              [-38.430113745766612, -7.732090794533856],
              [-38.43011373288374, -7.732090855844219],
              [-38.43011372042686, -7.7320909172421],
              [-38.430113708396561, -7.732090978724571],
              [-38.430113696793427, -7.732091040288687],
              [-38.430113685618011, -7.732091101931446],
              [-38.430113674870846, -7.732091163649939],
              [-38.430113664552465, -7.732091225441181],
              [-38.43011365466333, -7.732091287302222],
              [-38.430113645203946, -7.7320913492301],
              [-38.430113636174752, -7.732091411221836],
              [-38.430113627576198, -7.732091473274464],
              [-38.430113619408658, -7.732091535384997],
              [-38.430113611672553, -7.73209159755046],
              [-38.430113604368252, -7.732091659767884],
              [-38.430113597496096, -7.732091722034261],
              [-38.430113591056433, -7.732091784346615],
              [-38.430113585049554, -7.732091846701975],
              [-38.430113579475758, -7.732091909097314],
              [-38.430113574335309, -7.732091971529666],
              [-38.430113569628439, -7.73209203399603],
              [-38.430113565355398, -7.732092096493408],
              [-38.430113561516364, -7.732092159018793],
              [-38.43011355811155, -7.732092221569205],
              [-38.430113555141105, -7.732092284141658],
              [-38.430113552605164, -7.732092346733107],
              [-38.430113550503869, -7.732092409340603],
              [-38.430113548837298, -7.732092471961092],
              [-38.430113547605551, -7.732092534591589],
              [-38.43011354680867, -7.732092597229112],
              [-38.430113546446691, -7.732092659870621],
              [-38.430113546519664, -7.732092722513117],
              [-38.430113547027553, -7.732092785153647],
              [-38.430113547970336, -7.732092847789148],
              [-38.430113549347993, -7.73209291041662],
              [-38.430113551160431, -7.732092973033099],
              [-38.430113553407558, -7.732093035635532],
              [-38.430113556089296, -7.732093098220938],
              [-38.430113559205509, -7.732093160786339],
              [-38.430113562756034, -7.732093223328698],
              [-38.430113566740687, -7.732093285845029],
              [-38.430113571159318, -7.732093348332357],
              [-38.430113576011671, -7.732093410787646],
              [-38.430113581297555, -7.732093473207918],
              [-38.430113587016692, -7.732093535590183],
              [-38.430113593168812, -7.732093597931423],
              [-38.430113599753618, -7.732093660228702],
              [-38.430113606770796, -7.732093722478994],
              [-38.430113614220012, -7.732093784679299],
              [-38.430113622100905, -7.732093846826672],
              [-38.430113630413111, -7.732093908918132],
              [-38.430113639156197, -7.732093970950655],
              [-38.430113648329787, -7.732094032921299],
              [-38.430113657933397, -7.732094094827079],
              [-38.430113667966616, -7.73209415666503],
              [-38.430113678428924, -7.732094218432204],
              [-38.430113689319832, -7.732094280125628],
              [-38.430113700638813, -7.732094341742328],
              [-38.430113712385314, -7.732094403279369],
              [-38.430113724558822, -7.7320944647338],
              [-38.430113737158685, -7.732094526102657],
              [-38.430113750184347, -7.732094587382989],
              [-38.430113763635156, -7.732094648571879],
              [-38.430113777510478, -7.732094709666384],
              [-38.43011379180966, -7.732094770663584],
              [-38.430113806532006, -7.732094831560544],
              [-38.430113821676777, -7.732094892354334],
              [-38.430113837243304, -7.73209495304206],
              [-38.4301138532308, -7.732095013620796],
              [-38.430113869638525, -7.732095074087655],
              [-38.430113886465662, -7.732095134439711],
              [-38.430113903711444, -7.732095194674072],
              [-38.430113921374996, -7.732095254787851],
              [-38.430113939455495, -7.732095314778168],
              [-38.430113957952081, -7.732095374642179],
              [-38.430113976863872, -7.732095434376959],
              [-38.430113996189945, -7.732095493979664],
              [-38.430114015929362, -7.732095553447462],
              [-38.4301140360812, -7.732095612777471],
              [-38.430114056644484, -7.732095671966842],
              [-38.430114077618228, -7.732095731012747],
              [-38.430114099001408, -7.732095789912333],
              [-38.430114120793036, -7.732095848662818],
              [-38.430114142992053, -7.732095907261341],
              [-38.430114165597359, -7.732095965705116],
              [-38.430114188607917, -7.732096023991328],
              [-38.430114212022609, -7.732096082117218],
              [-38.430114193690216, -7.732096068568992],
              [-38.433614310614622, -7.740698351363341],
              [-38.433614242757351, -7.740698350527899],
              [-38.433614309275967, -7.740698518822435],
              [-38.433614372407014, -7.740698688410177],
              [-38.433614432125225, -7.740698859223313],
              [-38.433614488406739, -7.740699031193507],
              [-38.433614541229019, -7.740699204252009],
              [-38.433614590570983, -7.740699378329543],
              [-38.433614636412841, -7.740699553356512],
              [-38.433614678736305, -7.740699729262919],
              [-38.433614717524407, -7.740699905978355],
              [-38.433614752761642, -7.740700083432178],
              [-38.433614784433942, -7.740700261553451],
              [-38.43361481252861, -7.740700440270843],
              [-38.433614837034426, -7.74070061951292],
              [-38.433614857941585, -7.740700799207981],
              [-38.433614875241695, -7.740700979284135],
              [-38.433614888927885, -7.740701159669389],
              [-38.433614898994641, -7.740701340291555],
              [-38.43361490543797, -7.7407015210784],
              [-38.433614908255258, -7.740701701957641],
              [-38.433614907445417, -7.740701882856885],
              [-38.433614903008738, -7.740702063703781],
              [-38.433614894947013, -7.740702244426056],
              [-38.433614883263452, -7.74070242495132],
              [-38.433614867962746, -7.740702605207467],
              [-38.433614849051018, -7.74070278512233],
              [-38.433614826535809, -7.74070296462399],
              [-38.433614800426142, -7.740703143640627],
              [-38.433614770732468, -7.740703322100641],
              [-38.433614737466634, -7.740703499932654],
              [-38.433614700641968, -7.740703677065543],
              [-38.433614660273207, -7.74070385342844],
              [-38.433614616376467, -7.740704028950816],
              [-38.433614568969332, -7.740704203562458],
              [-38.433614518070762, -7.740704377193542],
              [-38.433614463701097, -7.740704549774573],
              [-38.433614405882111, -7.74070472123659],
              [-38.433614344636915, -7.74070489151097],
              [-38.433614279990003, -7.740705060529579],
              [-38.433614211967225, -7.740705228224869],
              [-38.433614140595822, -7.740705394529757],
              [-38.433614065904294, -7.740705559377695],
              [-38.433613987922556, -7.740705722702751],
              [-38.433613906681785, -7.740705884439619],
              [-38.43361382221449, -7.740706044523587],
              [-38.433613734554413, -7.740706202890665],
              [-38.433613643736678, -7.740706359477434],
              [-38.433613549797542, -7.74070651422133],
              [-38.433613452774644, -7.740706667060435],
              [-38.43361335270675, -7.740706817933605],
              [-38.433613249633893, -7.740706966780527],
              [-38.433613143597327, -7.740707113541615],
              [-38.433613034639421, -7.740707258158208],
              [-38.433612922803775, -7.740707400572458],
              [-38.433612808135138, -7.740707540727375],
              [-38.433612690679354, -7.740707678566936],
              [-38.433612570483405, -7.740707814035976],
              [-38.433612447595372, -7.74070794708032],
              [-38.433612322064427, -7.740708077646782],
              [-38.433612193940739, -7.740708205683092],
              [-38.433612063275589, -7.740708331138077],
              [-38.433611930121231, -7.740708453961515],
              [-38.433611794530911, -7.740708574104316],
              [-38.433611656558888, -7.740708691518407],
              [-38.433611516260306, -7.740708806156841],
              [-38.433611373691342, -7.740708917973743],
              [-38.433611228908966, -7.740709026924412],
              [-38.433611081971115, -7.74070913296525],
              [-38.43361093293656, -7.740709236053847],
              [-38.4336107818649, -7.740709336148976],
              [-38.433610628816581, -7.740709433210577],
              [-38.433610473852809, -7.740709527199864],
              [-38.433610317035551, -7.740709618079235],
              [-38.433610158427555, -7.740709705812344],
              [-38.433609998092251, -7.740709790364061],
              [-38.43360983609378, -7.740709871700611],
              [-38.433609672496914, -7.740709949789394],
              [-38.433609507367102, -7.740710024599243],
              [-38.43360934077041, -7.740710096100234],
              [-38.433609172773451, -7.740710164263739],
              [-38.433609003443429, -7.740710229062516],
              [-38.433608832848059, -7.740710290470601],
              [-38.433608661055601, -7.740710348463498],
              [-38.433608488134745, -7.740710403017991],
              [-38.433608314154689, -7.74071045411222],
              [-38.433608139184983, -7.740710501725772],
              [-38.433607963295636, -7.740710545839619],
              [-38.433607786556983, -7.740710586436112],
              [-38.433607609039733, -7.740710623498987],
              [-38.433607430814902, -7.74071065701346],
              [-38.433607251953731, -7.740710686966096],
              [-38.433607072527792, -7.740710713344913],
              [-38.433606892608857, -7.740710736139358],
              [-38.433606712268876, -7.74071075534034],
              [-38.433606531579976, -7.740710770940141],
              [-38.433606350614454, -7.740710782932574],
              [-38.433606169444673, -7.740710791312809],
              [-38.4336059881431, -7.7407107960775],
              [-38.433605806782261, -7.740710797224725],
              [-38.433605625434708, -7.740710794754034],
              [-38.433605678943522, -7.740710822378493],
              [-38.424073150386548, -7.740485673518574],
              [-38.424073117629142, -7.740485702679688],
              [-38.42407302564267, -7.740485700972206],
              [-38.424072933643551, -7.740485700195663],
              [-38.424072841641276, -7.74048570035012],
              [-38.424072749645319, -7.740485701435557],
              [-38.424072657665128, -7.740485703451889],
              [-38.424072565710176, -7.740485706398887],
              [-38.424072473789927, -7.740485710276247],
              [-38.424072381913852, -7.740485715083597],
              [-38.424072290091409, -7.740485720820407],
              [-38.42407219833202, -7.740485727486099],
              [-38.42407210664517, -7.740485735080001],
              [-38.424072015040267, -7.740485743601311],
              [-38.424071923526768, -7.740485753049164],
              [-38.424071832114059, -7.740485763422586],
              [-38.42407174081157, -7.740485774720503],
              [-38.424071649628679, -7.740485786941777],
              [-38.424071558574816, -7.740485800085131],
              [-38.424071467659303, -7.740485814149178],
              [-38.424071376891519, -7.740485829132519],
              [-38.424071286280807, -7.740485845033619],
              [-38.424071195836497, -7.740485861850804],
              [-38.424071105567897, -7.740485879582345],
              [-38.424071015484266, -7.740485898226447],
              [-38.424070925594933, -7.740485917781172],
              [-38.424070835909106, -7.740485938244521],
              [-38.424070746436037, -7.740485959614347],
              [-38.424070657184927, -7.740485981888499],
              [-38.424070568164979, -7.740486005064656],
              [-38.424070479385328, -7.74048602914044],
              [-38.424070390855121, -7.740486054113378],
              [-38.424070302583495, -7.740486079980911],
              [-38.424070214579501, -7.740486106740332],
              [-38.424070126852214, -7.740486134388945],
              [-38.424070039410658, -7.740486162923853],
              [-38.424069952263849, -7.740486192342126],
              [-38.424069865420734, -7.740486222640781],
              [-38.424069778890271, -7.740486253816671],
              [-38.424069692681357, -7.740486285866559],
              [-38.424069606802853, -7.740486318787181],
              [-38.424069521263633, -7.740486352575164],
              [-38.424069436072465, -7.740486387226992],
              [-38.424069351238138, -7.740486422739102],
              [-38.424069266769372, -7.740486459107848],
              [-38.42406918267487, -7.740486496329506],
              [-38.424069098963294, -7.740486534400213],
              [-38.424069015643241, -7.740486573316077],
              [-38.424068932723316, -7.740486613073079],
              [-38.424068850212009, -7.740486653667138],
              [-38.424068768117856, -7.740486695094039],
              [-38.424068686449282, -7.740486737349564],
              [-38.4240686052147, -7.740486780429338],
              [-38.424068524422481, -7.740486824328929],
              [-38.42406844408093, -7.740486869043834],
              [-38.42406836419832, -7.740486914569444],
              [-38.424068284782862, -7.740486960901041],
              [-38.424068205842751, -7.740487008033893],
              [-38.4240681273861, -7.740487055963135],
              [-38.424068049420981, -7.740487104683853],
              [-38.424067971955424, -7.740487154191007],
              [-38.4240678949974, -7.74048720447953],
              [-38.424067818554839, -7.740487255544217],
              [-38.424067742635593, -7.740487307379817],
              [-38.424067667247492, -7.740487359981002],
              [-38.424067592398295, -7.740487413342358],
              [-38.42406751809569, -7.740487467458385],
              [-38.42406744434733, -7.740487522323516],
              [-38.424067371160824, -7.740487577932099],
              [-38.42406729854369, -7.740487634278438],
              [-38.424067226503389, -7.740487691356707],
              [-38.424067155047361, -7.740487749161042],
              [-38.424067084182958, -7.740487807685469],
              [-38.424067013917465, -7.740487866924],
              [-38.424066944258101, -7.740487926870514],
              [-38.42406687521207, -7.740487987518849],
              [-38.424066806786442, -7.74048804886277],
              [-38.424066738988294, -7.740488110895932],
              [-38.424066671824576, -7.740488173611992],
              [-38.424066605302215, -7.740488237004475],
              [-38.424066539428061, -7.740488301066851],
              [-38.424066474208892, -7.740488365792527],
              [-38.42406640965141, -7.740488431174856],
              [-38.42406634576227, -7.74048849720709],
              [-38.424066282548061, -7.740488563882447],
              [-38.424066220015256, -7.740488631194069],
              [-38.424066158170319, -7.740488699134998],
              [-38.424066097019598, -7.740488767698254],
              [-38.424066036569414, -7.740488836876787],
              [-38.424065976825965, -7.740488906663496],
              [-38.424065917795396, -7.740488977051142],
              [-38.424065859483797, -7.740489048032522],
              [-38.424065801897164, -7.740489119600333],
              [-38.424065745041439, -7.74048919174719],
              [-38.424065688922454, -7.7404892644657],
              [-38.424065633545986, -7.740489337748325],
              [-38.424065578917755, -7.74048941158756],
              [-38.424065525043353, -7.740489485975793],
              [-38.424065471928358, -7.740489560905369],
              [-38.424065419578213, -7.74048963636858],
              [-38.424065367998324, -7.740489712357661],
              [-38.424065436712809, -7.74048973346911],
              [-38.423088329943333, -7.741945061208454],
              [-38.423088278245721, -7.741945032171559],
              [-38.423088202697315, -7.741945146560121],
              [-38.423088128894214, -7.741945262076709],
              [-38.423088056853373, -7.741945378694795],
              [-38.423087986591298, -7.74194549638765],
              [-38.423087918124111, -7.741945615128304],
              [-38.423087851467507, -7.741945734889498],
              [-38.423087786636778, -7.741945855643777],
              [-38.423087723646773, -7.74194597736345],
              [-38.423087662511968, -7.741946100020612],
              [-38.423087603246358, -7.741946223587095],
              [-38.423087545863552, -7.741946348034602],
              [-38.423087490376695, -7.741946473334592],
              [-38.423087436798532, -7.741946599458301],
              [-38.423087385141322, -7.741946726376819],
              [-38.423087335416938, -7.741946854061052],
              [-38.423087287636761, -7.741946982481699],
              [-38.423087241811771, -7.741947111609323],
              [-38.423087197952448, -7.741947241414304],
              [-38.423087156068895, -7.741947371866882],
              [-38.423087116170677, -7.741947502937132],
              [-38.423087078266953, -7.741947634594998],
              [-38.423087042366419, -7.741947766810292],
              [-38.42308700847731, -7.741947899552674],
              [-38.423086976607408, -7.741948032791731],
              [-38.423086946764002, -7.741948166496856],
              [-38.423086918953956, -7.741948300637438],
              [-38.423086893183616, -7.741948435182691],
              [-38.423086869458935, -7.741948570101774],
              [-38.423086847785321, -7.741948705363723],
              [-38.423086828167747, -7.741948840937552],
              [-38.423086810610727, -7.741948976792124],
              [-38.42308679511828, -7.741949112896349],
              [-38.423086781693961, -7.741949249218943],
              [-38.423086770340845, -7.74194938572869],
              [-38.423086761061533, -7.741949522394258],
              [-38.423086753858158, -7.741949659184325],
              [-38.423086748732374, -7.741949796067495],
              [-38.423086745685353, -7.741949933012393],
              [-38.423086744717814, -7.741950069987616],
              [-38.423086745829927, -7.741950206961746],
              [-38.423086749021486, -7.741950343903358],
              [-38.423086754291738, -7.741950480781083],
              [-38.423086761639489, -7.741950617563479],
              [-38.423086771063026, -7.741950754219221],
              [-38.423086782560226, -7.741950890716955],
              [-38.423086796128416, -7.741951027025373],
              [-38.423086811764506, -7.74195116311325],
              [-38.423086829464907, -7.741951298949315],
              [-38.423086849225555, -7.741951434502459],
              [-38.423086871041917, -7.741951569741588],
              [-38.423086894908998, -7.741951704635685],
              [-38.423086920821312, -7.741951839153818],
              [-38.423086948772927, -7.741951973265101],
              [-38.423086978757432, -7.741952106938863],
              [-38.423087010767958, -7.741952240144362],
              [-38.42308704479715, -7.741952372851059],
              [-38.423087080837192, -7.741952505028584],
              [-38.423087118879856, -7.741952636646552],
              [-38.423087158916388, -7.741952767674824],
              [-38.423087200937623, -7.741952898083357],
              [-38.423087244933903, -7.74195302784218],
              [-38.423087290895168, -7.741953156921636],
              [-38.423087338810852, -7.741953285292024],
              [-38.423087388669977, -7.741953412923982],
              [-38.42308744046111, -7.741953539788172],
              [-38.423087494172371, -7.741953665855559],
              [-38.423087549791433, -7.741953791097191],
              [-38.423087607305547, -7.741953915484378],
              [-38.423087666701562, -7.741954038988579],
              [-38.423087727965793, -7.74195416158149],
              [-38.423087791084228, -7.741954283234917],
              [-38.423087856042372, -7.741954403921093],
              [-38.423087922825339, -7.741954523612222],
              [-38.423087991417816, -7.741954642280889],
              [-38.423088061804087, -7.741954759899945],
              [-38.423088133967973, -7.741954876442326],
              [-38.423088207892945, -7.741954991881337],
              [-38.423088283562045, -7.741955106190501],
              [-38.423088360957934, -7.74195521934363],
              [-38.423088440062855, -7.74195533131474],
              [-38.423088520858649, -7.741955442078161],
              [-38.423088603326811, -7.741955551608535],
              [-38.42308868744842, -7.741955659880707],
              [-38.423088773204185, -7.741955766869786],
              [-38.423088860574431, -7.741955872551368],
              [-38.423088949539135, -7.741955976901116],
              [-38.423089040077876, -7.741956079895108],
              [-38.423089132169913, -7.741956181509761],
              [-38.423089225794115, -7.741956281721768],
              [-38.423089320929016, -7.741956380508118],
              [-38.423089417552781, -7.741956477846167],
              [-38.423089515643269, -7.741956573713614],
              [-38.423089615177972, -7.741956668088428],
              [-38.423089716134093, -7.741956760949041],
              [-38.423089818488435, -7.741956852274082],
              [-38.423089922217571, -7.741956942042655],
              [-38.423090027297668, -7.741957030234162],
              [-38.423090133704669, -7.741957116828314],
              [-38.42309024141413, -7.741957201805352],
              [-38.423090308517786, -7.741957184530417],
              [-38.425046129564812, -7.743476444888568],
              [-38.425046139793686, -7.743476420401104],
              [-38.425046150063984, -7.743476428390207],
              [-38.425046160322765, -7.743476436394024],
              [-38.425046170570006, -7.7434764444125],
              [-38.425046180805701, -7.743476452445666],
              [-38.425046191029814, -7.743476460493495],
              [-38.425046201242338, -7.743476468555967],
              [-38.425046211443224, -7.743476476633043],
              [-38.425046221632478, -7.74347648472473],
              [-38.425046231810079, -7.743476492831007],
              [-38.425046241975991, -7.743476500951857],
              [-38.425046252130187, -7.743476509087253],
              [-38.425046262272659, -7.743476517237205],
              [-38.425046272403407, -7.743476525401662],
              [-38.425046282522366, -7.743476533580627],
              [-38.425046292629546, -7.743476541774081],
              [-38.425046302724922, -7.743476549982029],
              [-38.425046312808476, -7.743476558204378],
              [-38.425046322880156, -7.743476566441189],
              [-38.425046332939985, -7.74347657469242],
              [-38.425046342987926, -7.743476582958056],
              [-38.425046353023944, -7.743476591238048],
              [-38.425046363048033, -7.743476599532463],
              [-38.425046373060155, -7.743476607841183],
              [-38.425046383060312, -7.743476616164275],
              [-38.425046393048483, -7.743476624501657],
              [-38.425046403024631, -7.743476632853353],
              [-38.425046412988742, -7.743476641219329],
              [-38.42504642294081, -7.743476649599572],
              [-38.425046432880791, -7.743476657994049],
              [-38.425046442808657, -7.743476666402768],
              [-38.425046452724423, -7.743476674825686],
              [-38.425046462628053, -7.74347668326283],
              [-38.425046472519519, -7.74347669171414],
              [-38.425046482398805, -7.743476700179618],
              [-38.425046492265899, -7.743476708659252],
              [-38.425046502120765, -7.743476717152988],
              [-38.425046511963387, -7.743476725660805],
              [-38.42504652179376, -7.743476734182743],
              [-38.425046531611848, -7.743476742718753],
              [-38.425046541417615, -7.74347675126881],
              [-38.425046551211089, -7.743476759832925],
              [-38.425046560992207, -7.743476768411033],
              [-38.425046570760976, -7.743476777003165],
              [-38.425046580517346, -7.743476785609277],
              [-38.425046590261324, -7.743476794229357],
              [-38.425046599992889, -7.743476802863405],
              [-38.425046609711991, -7.743476811511352],
              [-38.425046619418637, -7.743476820173215],
              [-38.425046629112813, -7.743476828849011],
              [-38.425046638794477, -7.743476837538657],
              [-38.425046648463621, -7.743476846242172],
              [-38.425046658120216, -7.743476854959516],
              [-38.425046667764263, -7.743476863690692],
              [-38.425046677395713, -7.743476872435667],
              [-38.425046687014571, -7.743476881194455],
              [-38.425046696620797, -7.743476889966978],
              [-38.425046706214388, -7.743476898753261],
              [-38.425046715795325, -7.743476907553315],
              [-38.425046725363572, -7.743476916367045],
              [-38.425046734919114, -7.743476925194491],
              [-38.425046744461945, -7.743476934035597],
              [-38.425046753992028, -7.743476942890388],
              [-38.425046763509364, -7.743476951758804],
              [-38.425046773013904, -7.743476960640852],
              [-38.425046782505653, -7.74347696953653],
              [-38.425046791984585, -7.743476978445785],
              [-38.425046801450662, -7.743476987368606],
              [-38.425046810903908, -7.743476996304985],
              [-38.425046820344264, -7.74347700525488],
              [-38.425046829771723, -7.74347701421828],
              [-38.425046839186273, -7.743477023195197],
              [-38.425046848587876, -7.743477032185575],
              [-38.42504685797654, -7.743477041189445],
              [-38.425046867352208, -7.743477050206727],
              [-38.425046876714894, -7.743477059237436],
              [-38.425046886064557, -7.743477068281538],
              [-38.425046895401202, -7.743477077339034],
              [-38.425046904724788, -7.743477086409905],
              [-38.425046914035306, -7.743477095494121],
              [-38.425046923332729, -7.743477104591644],
              [-38.42504693261705, -7.743477113702499],
              [-38.42504694188824, -7.743477122826619],
              [-38.425046951146292, -7.743477131964042],
              [-38.42504696039115, -7.743477141114701],
              [-38.425046969622841, -7.743477150278586],
              [-38.425046978841323, -7.743477159455693],
              [-38.425046988046596, -7.743477168645993],
              [-38.425046997238617, -7.743477177849469],
              [-38.425047006417373, -7.743477187066134],
              [-38.425047015582841, -7.743477196295904],
              [-38.425047024735022, -7.743477205538817],
              [-38.425047033873881, -7.743477214794823],
              [-38.425047042999395, -7.743477224063931],
              [-38.425047052111573, -7.743477233346078],
              [-38.42504706121035, -7.743477242641267],
              [-38.425047070295754, -7.743477251949475],
              [-38.425047079367737, -7.743477261270692],
              [-38.425047088426297, -7.743477270604911],
              [-38.425047097471499, -7.743477279950973],
              [-38.425047125993693, -7.743477260305757],
              [-38.428043799109766, -7.746578389384563],
              [-38.428043752733558, -7.746578430791933],
              [-38.428043773168412, -7.746578451870671],
              [-38.428043793671669, -7.746578472883225],
              [-38.428043814243111, -7.746578493829335],
              [-38.428043834882502, -7.746578514708885],
              [-38.428043855589657, -7.746578535521524],
              [-38.428043876364342, -7.746578556267145],
              [-38.428043897206344, -7.746578576945464],
              [-38.428043918115442, -7.746578597556296],
              [-38.428043939091417, -7.746578618099387],
              [-38.42804396013404, -7.74657863857457],
              [-38.428043981243086, -7.746578658981644],
              [-38.428044002418368, -7.746578679320331],
              [-38.428044023659623, -7.746578699590437],
              [-38.428044044966654, -7.746578719791771],
              [-38.428044066339218, -7.7465787399241],
              [-38.428044087777096, -7.746578759987208],
              [-38.428044109280066, -7.746578779980903],
              [-38.428044130847923, -7.746578799904966],
              [-38.428044152480403, -7.746578819759195],
              [-38.428044174177302, -7.746578839543357],
              [-38.428044195938384, -7.746578859257283],
              [-38.428044217763421, -7.746578878900716],
              [-38.428044239652188, -7.74657889847349],
              [-38.42804426160442, -7.746578917975368],
              [-38.428044283619968, -7.746578937406199],
              [-38.428044305698528, -7.746578956765731],
              [-38.428044327839878, -7.746578976053758],
              [-38.428044350043827, -7.746578995270101],
              [-38.42804437231009, -7.746579014414546],
              [-38.428044394638462, -7.746579033486933],
              [-38.428044417028694, -7.74657905248701],
              [-38.428044439480566, -7.746579071414583],
              [-38.428044461993842, -7.746579090269476],
              [-38.428044484568254, -7.746579109051467],
              [-38.428044507203616, -7.746579127760349],
              [-38.428044529899644, -7.746579146395987],
              [-38.428044552656132, -7.746579164958117],
              [-38.428044575472825, -7.746579183446567],
              [-38.42804459834948, -7.746579201861169],
              [-38.428044621285856, -7.746579220201685],
              [-38.428044644281727, -7.746579238467947],
              [-38.428044667336842, -7.746579256659786],
              [-38.428044690450953, -7.746579274776951],
              [-38.428044713623827, -7.746579292819322],
              [-38.428044736855227, -7.746579310786659],
              [-38.428044760144893, -7.746579328678766],
              [-38.428044783492574, -7.746579346495509],
              [-38.428044806898058, -7.746579364236645],
              [-38.42804483036106, -7.74657938190201],
              [-38.42804485388136, -7.746579399491413],
              [-38.428044877458696, -7.746579417004692],
              [-38.428044901092825, -7.746579434441657],
              [-38.428044924783499, -7.746579451802106],
              [-38.428044948530484, -7.746579469085841],
              [-38.428044972333502, -7.74657948629275],
              [-38.428044996192327, -7.746579503422558],
              [-38.428045020106694, -7.746579520475157],
              [-38.428045044076356, -7.746579537450316],
              [-38.428045068101049, -7.746579554347921],
              [-38.428045092180554, -7.746579571167752],
              [-38.428045116314586, -7.746579587909628],
              [-38.428045140502896, -7.746579604573372],
              [-38.428045164745249, -7.746579621158847],
              [-38.428045189041377, -7.746579637665828],
              [-38.428045213391016, -7.746579654094168],
              [-38.428045237793924, -7.746579670443698],
              [-38.428045262249839, -7.746579686714231],
              [-38.428045286758504, -7.746579702905632],
              [-38.428045311319664, -7.746579719017681],
              [-38.428045335933056, -7.746579735050243],
              [-38.428045360598425, -7.746579751003116],
              [-38.428045385315514, -7.746579766876201],
              [-38.428045410084053, -7.746579782669258],
              [-38.428045434903808, -7.746579798382136],
              [-38.428045459774481, -7.746579814014654],
              [-38.428045484695843, -7.746579829566715],
              [-38.428045509667598, -7.746579845038095],
              [-38.428045534689517, -7.746579860428674],
              [-38.428045559761323, -7.746579875738261],
              [-38.42804558488276, -7.746579890966708],
              [-38.428045610053552, -7.746579906113845],
              [-38.428045635273435, -7.74657992117952],
              [-38.428045660542153, -7.746579936163551],
              [-38.428045685859431, -7.746579951065828],
              [-38.428045711225025, -7.746579965886156],
              [-38.428045736638623, -7.746579980624396],
              [-38.428045762100005, -7.7465799952804],
              [-38.428045787608895, -7.746580009853958],
              [-38.428045813164992, -7.746580024345013],
              [-38.428045838768064, -7.746580038753321],
              [-38.428045864417832, -7.746580053078797],
              [-38.428045890114007, -7.746580067321242],
              [-38.428045915856352, -7.746580081480556],
              [-38.428045941644577, -7.746580095556546],
              [-38.428045967478404, -7.746580109549082],
              [-38.428045993357571, -7.746580123458016],
              [-38.428046019281808, -7.746580137283203],
              [-38.428046045250859, -7.746580151024482],
              [-38.428046071264419, -7.74658016468173],
              [-38.428046063928186, -7.746580111024118],
              [-38.438280018476924, -7.751931702989737],
              [-38.438280007446927, -7.751931741214545],
              [-38.438280027214333, -7.751931751582033],
              [-38.438280046956073, -7.751931761998024],
              [-38.438280066672043, -7.751931772462522],
              [-38.43828008636212, -7.751931782975372],
              [-38.43828010602617, -7.751931793536595],
              [-38.438280125664093, -7.751931804146033],
              [-38.438280145275748, -7.751931814803724],
              [-38.438280164861041, -7.751931825509533],
              [-38.438280184419824, -7.751931836263427],
              [-38.438280203952019, -7.751931847065351],
              [-38.438280223457468, -7.751931857915204],
              [-38.438280242936074, -7.751931868812941],
              [-38.438280262387707, -7.751931879758474],
              [-38.438280281812261, -7.751931890751751],
              [-38.438280301209609, -7.751931901792722],
              [-38.438280320579636, -7.751931912881321],
              [-38.43828033992223, -7.751931924017462],
              [-38.438280359237268, -7.751931935201081],
              [-38.438280378524638, -7.751931946432087],
              [-38.438280397784212, -7.75193195771047],
              [-38.438280417015889, -7.75193196903609],
              [-38.438280436219543, -7.751931980408953],
              [-38.438280455395045, -7.751931991828946],
              [-38.438280474542303, -7.751932003296014],
              [-38.438280493661189, -7.751932014810036],
              [-38.438280512751582, -7.75193202637102],
              [-38.438280531813376, -7.751932037978825],
              [-38.438280550846443, -7.751932049633446],
              [-38.43828056985069, -7.751932061334799],
              [-38.438280588825982, -7.751932073082764],
              [-38.438280607772214, -7.751932084877312],
              [-38.438280626689256, -7.751932096718372],
              [-38.438280645577009, -7.751932108605862],
              [-38.438280664435354, -7.751932120539692],
              [-38.438280683264182, -7.751932132519832],
              [-38.438280702063366, -7.751932144546172],
              [-38.438280720832793, -7.751932156618678],
              [-38.438280739572356, -7.751932168737227],
              [-38.438280758281948, -7.751932180901783],
              [-38.438280776961442, -7.751932193112259],
              [-38.438280795610744, -7.751932205368592],
              [-38.438280814229714, -7.751932217670662],
              [-38.438280832818258, -7.751932230018447],
              [-38.438280851376255, -7.751932242411871],
              [-38.438280869903593, -7.751932254850843],
              [-38.438280888400165, -7.751932267335249],
              [-38.438280906865856, -7.751932279865067],
              [-38.43828092530056, -7.75193229244022],
              [-38.43828094370415, -7.75193230506065],
              [-38.438280962076519, -7.751932317726192],
              [-38.438280980417574, -7.751932330436849],
              [-38.43828099872718, -7.751932343192512],
              [-38.438281017005231, -7.751932355993138],
              [-38.438281035251627, -7.75193236883857],
              [-38.438281053466262, -7.751932381728809],
              [-38.438281071648994, -7.751932394663774],
              [-38.438281089799737, -7.751932407643342],
              [-38.438281107918378, -7.751932420667433],
              [-38.438281126004824, -7.751932433736005],
              [-38.438281144058934, -7.751932446848987],
              [-38.438281162080607, -7.751932460006264],
              [-38.438281180069744, -7.751932473207794],
              [-38.438281198026232, -7.751932486453426],
              [-38.438281215949957, -7.751932499743153],
              [-38.438281233840819, -7.751932513076847],
              [-38.438281251698697, -7.751932526454453],
              [-38.4382812695235, -7.75193253987587],
              [-38.438281287315114, -7.751932553341065],
              [-38.43828130507341, -7.751932566849903],
              [-38.438281322798311, -7.751932580402332],
              [-38.438281340489702, -7.751932593998254],
              [-38.438281358147442, -7.751932607637584],
              [-38.438281375771467, -7.751932621320266],
              [-38.438281393361663, -7.751932635046175],
              [-38.438281410917902, -7.751932648815252],
              [-38.438281428440114, -7.751932662627431],
              [-38.438281445928148, -7.751932676482579],
              [-38.438281463381919, -7.75193269038068],
              [-38.438281480801322, -7.751932704321575],
              [-38.438281498186257, -7.75193271830524],
              [-38.438281515536616, -7.751932732331552],
              [-38.438281532852272, -7.751932746400461],
              [-38.438281550133162, -7.751932760511828],
              [-38.438281567379143, -7.751932774665625],
              [-38.438281584590122, -7.751932788861751],
              [-38.438281601766008, -7.751932803100098],
              [-38.438281618906679, -7.751932817380571],
              [-38.438281636012043, -7.751932831703136],
              [-38.43828165308198, -7.751932846067644],
              [-38.438281670116417, -7.751932860474049],
              [-38.438281687115207, -7.751932874922276],
              [-38.438281704078292, -7.751932889412193],
              [-38.438281721005538, -7.751932903943739],
              [-38.438281737896865, -7.751932918516805],
              [-38.438281754752147, -7.751932933131303],
              [-38.43828177157129, -7.751932947787184],
              [-38.438281788354203, -7.751932962484362],
              [-38.438281805100779, -7.751932977222683],
              [-38.438281821811046, -7.751932992001005],
              [-38.438281830533612, -7.751932971718455],
              [-38.43939515758062, -7.75292010261351],
              [-38.447529150060255, -7.755992201839439],
              [-38.455992206642257, -7.756587247361229],
              [-38.45925871819454, -7.757926587256912],
              [-38.466467530643612, -7.756433530479902],
              [-38.472834824410093, -7.757693246517363],
              [-38.476846037534756, -7.754201181020274],
              [-38.486448461829774, -7.752546628582772],
              [-38.485515351589143, -7.734398544300534],
              [-38.495707802247189, -7.723446003637045],
              [-38.485161625411813, -7.713925040831607],
              [-38.483469395257288, -7.712105698734759],
              [-38.480191914276297, -7.705620100932063],
              [-38.476127805800552, -7.707560714618579],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0187000',
        uf: 'PE',
        nome_proje: 'PA BANDEIRA I',
        municipio: 'IBIMIRIM',
        area_hecta: '208.0424',
        capacidade: 44.0,
        num_famili: 31.0,
        fase: 3.0,
        data_de_cr: '04/10/2006',
        forma_obte: 'Doação',
        data_obten: '04/10/2006',
        area_calc_: 388.7227,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.745769546555991, -8.454392129351636],
              [-37.743665997574766, -8.45455503447991],
              [-37.743534310309485, -8.454719380487473],
              [-37.743372798752596, -8.456520014092552],
              [-37.741954443056621, -8.457803672199084],
              [-37.741703435204869, -8.457762893618382],
              [-37.739568325146635, -8.455446457793068],
              [-37.739388227876653, -8.455442169913809],
              [-37.739196126250441, -8.455610208128439],
              [-37.736529585998674, -8.459046549523192],
              [-37.736397908932688, -8.459178517580355],
              [-37.734431840620154, -8.457507839798012],
              [-37.734299596155324, -8.457535355649549],
              [-37.73320299706306, -8.458657782078635],
              [-37.731075621443232, -8.459434574714578],
              [-37.731062358784548, -8.45951836259329],
              [-37.731777620800251, -8.460299215818607],
              [-37.732311071605416, -8.462294439549298],
              [-37.732240167061782, -8.463700525479489],
              [-37.732333197269533, -8.463832949030403],
              [-37.733520154625502, -8.465000481968831],
              [-37.733538848354087, -8.4656448743411],
              [-37.733892146433142, -8.465987582657636],
              [-37.733890670819903, -8.466105592345611],
              [-37.733790448416464, -8.466144696251613],
              [-37.732643651959677, -8.465693609654197],
              [-37.732406870636801, -8.465689947975603],
              [-37.731304043578696, -8.466957217101847],
              [-37.73118607129458, -8.466980075236204],
              [-37.731184491768431, -8.46687996138831],
              [-37.731081211818321, -8.466854124107151],
              [-37.730784916137175, -8.466080326337936],
              [-37.730269099100397, -8.466272628466315],
              [-37.729913417497876, -8.466242443960216],
              [-37.730010287085669, -8.465175746698455],
              [-37.730254446537643, -8.4636205595628],
              [-37.729436367923746, -8.462145933420619],
              [-37.727907244707559, -8.461487678328488],
              [-37.727955041571697, -8.460955368052829],
              [-37.729285197932292, -8.457388606704335],
              [-37.727872144402532, -8.456045886591609],
              [-37.727588094609402, -8.452427848579113],
              [-37.72493880700933, -8.452916244016459],
              [-37.723116814989908, -8.454189468668728],
              [-37.723032392626934, -8.456106808538124],
              [-37.721784422014977, -8.456080668315956],
              [-37.72140185327396, -8.45828614395753],
              [-37.72223149100261, -8.459410937255429],
              [-37.722859861570136, -8.462216297081945],
              [-37.722399329696181, -8.463988981296895],
              [-37.725333687077317, -8.463067921603699],
              [-37.725333679150353, -8.46306794885062],
              [-37.725600090780219, -8.462984326307344],
              [-37.725567314277313, -8.462593732900407],
              [-37.725838549623035, -8.462504636763875],
              [-37.726019780923544, -8.462302749402662],
              [-37.725795035212343, -8.462009559055474],
              [-37.725615414378154, -8.461693998051057],
              [-37.725435645736667, -8.4614234717943],
              [-37.725187676297608, -8.461287648113396],
              [-37.725188268098627, -8.461107689824173],
              [-37.725279159471761, -8.46095045308668],
              [-37.725392649706613, -8.460770862766665],
              [-37.72539375929869, -8.460433373113514],
              [-37.725213473598636, -8.460320287751975],
              [-37.725236903125811, -8.460072941411449],
              [-37.725395016602498, -8.460050939091476],
              [-37.725688792080199, -8.459984433892615],
              [-37.725734894709234, -8.459692035626105],
              [-37.725736595269268, -8.459174587563583],
              [-37.725624658390458, -8.458881764842806],
              [-37.725286260318214, -8.45879068209527],
              [-37.725060982134075, -8.458632505307209],
              [-37.724699894823694, -8.45856377535307],
              [-37.724632803883537, -8.458361078780085],
              [-37.72474614535512, -8.45822652334361],
              [-37.724859635124837, -8.458046842640952],
              [-37.725175581094724, -8.458115425279779],
              [-37.725197810352334, -8.458205477842572],
              [-37.725332806471719, -8.458363360354754],
              [-37.725671425895989, -8.45838698123732],
              [-37.725807456936494, -8.458229981710598],
              [-37.726123534413475, -8.458231011117478],
              [-37.726394397018971, -8.458254410713311],
              [-37.726484235843259, -8.458389628225969],
              [-37.726303302434701, -8.45850153695274],
              [-37.725964460849504, -8.45854546880865],
              [-37.725737778048938, -8.458814670867284],
              [-37.725895447374874, -8.458927682249163],
              [-37.726211155876356, -8.459041208265544],
              [-37.726323314854461, -8.459266569101478],
              [-37.72632198461438, -8.459671520683237],
              [-37.726320728281969, -8.460053954851192],
              [-37.726094118351021, -8.460300730030452],
              [-37.725912871349976, -8.460480099893651],
              [-37.725911023044326, -8.46104249232706],
              [-37.72600073100466, -8.461245262501977],
              [-37.726271004359702, -8.46144862085424],
              [-37.726360030162802, -8.461831439486478],
              [-37.726607705140594, -8.462057151291855],
              [-37.726856192795161, -8.462035533222922],
              [-37.727399548756281, -8.461587310654537],
              [-37.727783460540813, -8.461566042213365],
              [-37.728098728841822, -8.461814581172506],
              [-37.728120163965869, -8.462174571711257],
              [-37.727780433058989, -8.46248844238014],
              [-37.727350935560807, -8.462644577792158],
              [-37.726988883328708, -8.462868304363734],
              [-37.727213647325584, -8.463184011924536],
              [-37.727280296749065, -8.46352172235685],
              [-37.727414391742691, -8.463927114865765],
              [-37.727865620531389, -8.464041081305098],
              [-37.727932713784931, -8.464243777661824],
              [-37.727977116909308, -8.464468917837596],
              [-37.727885102632904, -8.46502351128799],
              [-37.72743106982189, -8.465209861347654],
              [-37.727380644110994, -8.465408105699243],
              [-37.727404472545039, -8.46573048409188],
              [-37.727503369420873, -8.465929124177777],
              [-37.727403006950013, -8.466176672409249],
              [-37.727476283707759, -8.466598416066706],
              [-37.72782387955008, -8.466822734715175],
              [-37.728171485597109, -8.467071741173509],
              [-37.7284692266512, -8.467320675568526],
              [-37.728991115691564, -8.467520691536802],
              [-37.729239092798821, -8.467794242069733],
              [-37.72981124526121, -8.46784565925123],
              [-37.730308167176403, -8.468070461409889],
              [-37.730630401363165, -8.468468144945678],
              [-37.73092782045341, -8.468816190730506],
              [-37.731200849466063, -8.469040264712875],
              [-37.731175231255399, -8.469263278341742],
              [-37.730826077391846, -8.469485331497697],
              [-37.730725715657677, -8.469732880716409],
              [-37.730749465125434, -8.470080127936338],
              [-37.730922620436758, -8.47040299163927],
              [-37.73069782141534, -8.470650136527052],
              [-37.730647649775037, -8.470798734818445],
              [-37.730671552252112, -8.471071556646487],
              [-37.731144372871192, -8.47108548206419],
              [-37.731492624691377, -8.471111482076831],
              [-37.731840958055912, -8.471112613126097],
              [-37.731865358277048, -8.47126145372607],
              [-37.731789812512268, -8.471558640776376],
              [-37.731813309652395, -8.471955444215942],
              [-37.731638944175295, -8.472029303916392],
              [-37.731091510039732, -8.47202752577137],
              [-37.730792697645477, -8.472076111823741],
              [-37.730791234543766, -8.472522391016771],
              [-37.730565467944778, -8.473091833544098],
              [-37.730689164325533, -8.473290644303823],
              [-37.731062396722301, -8.473316635741648],
              [-37.731160890560808, -8.47363925691319],
              [-37.731334281719185, -8.473862917055758],
              [-37.731657414480402, -8.473987949615518],
              [-37.73213010744665, -8.474014263186037],
              [-37.732378496598606, -8.47416383099843],
              [-37.732626957398473, -8.474263841921605],
              [-37.732776851501917, -8.47409078850184],
              [-37.732653144055064, -8.473867199688669],
              [-37.732355557719742, -8.47356871096583],
              [-37.732356207178334, -8.473370394692862],
              [-37.732580754034558, -8.473172805131316],
              [-37.732631665518468, -8.472825890540228],
              [-37.732834851477961, -8.4723553966537],
              [-37.732910893320444, -8.471934227994048],
              [-37.733161219215347, -8.471463886718919],
              [-37.733138044922114, -8.470967970188402],
              [-37.733140315995911, -8.470273817704808],
              [-37.733290360712147, -8.470026338335803],
              [-37.733514995807603, -8.469828748142884],
              [-37.733714662289522, -8.469655855368165],
              [-37.733716607913287, -8.469060815678221],
              [-37.733568013741007, -8.468837146596348],
              [-37.73334516300536, -8.468489344218796],
              [-37.733321572838989, -8.468092630739649],
              [-37.733521157365246, -8.46794451617825],
              [-37.733745051857717, -8.467945241779786],
              [-37.734143005101558, -8.468020866707636],
              [-37.734365846551746, -8.468343890153662],
              [-37.734738264960342, -8.468617840875609],
              [-37.734961431259265, -8.468841660620104],
              [-37.735010234142322, -8.469139341775001],
              [-37.735058956134083, -8.469461801191729],
              [-37.735331988366255, -8.469685872697733],
              [-37.735629898693432, -8.469885155612348],
              [-37.736028168076395, -8.469836887315596],
              [-37.736302414956704, -8.469689012875838],
              [-37.73650232314256, -8.469441693224338],
              [-37.736653012418202, -8.468995895542241],
              [-37.736629095578607, -8.468698294879887],
              [-37.736605259566232, -8.468375915939498],
              [-37.736532750687857, -8.467743467021242],
              [-37.736209944033057, -8.467519325892686],
              [-37.735837211537003, -8.467369358643946],
              [-37.735638922945633, -8.467120750958934],
              [-37.735813942725706, -8.466873350977503],
              [-37.735865011415555, -8.466476878762602],
              [-37.735867195536279, -8.465807503658615],
              [-37.735868813594053, -8.465311576452114],
              [-37.735845382503392, -8.464865215729869],
              [-37.735596835116283, -8.464765297709723],
              [-37.73547320894837, -8.464516931612762],
              [-37.735499309428093, -8.46414515717421],
              [-37.735176435271882, -8.463970572512734],
              [-37.734878286657427, -8.463845624696505],
              [-37.734928698409689, -8.463622600204877],
              [-37.735004411404169, -8.463300543441262],
              [-37.734906158210158, -8.462903588183877],
              [-37.734608900931356, -8.462505898503668],
              [-37.734360193583257, -8.462455536481757],
              [-37.734360841152146, -8.462257219941646],
              [-37.734610276992662, -8.462084396686185],
              [-37.734636134829472, -8.46178704759035],
              [-37.734465087157602, -8.461625252756502],
              [-37.734192465057518, -8.461277290147521],
              [-37.734317223136109, -8.461178489420249],
              [-37.734616259866627, -8.461030695546565],
              [-37.734914901766231, -8.461031661758907],
              [-37.735113096473256, -8.46128026906659],
              [-37.735335864918994, -8.461652848458364],
              [-37.735633294154241, -8.462025759633619],
              [-37.736030100303935, -8.462423680027229],
              [-37.736576147940802, -8.462846951390521],
              [-37.736998812172942, -8.462947521586443],
              [-37.737296890433861, -8.463122024664523],
              [-37.737645459813372, -8.463048724170575],
              [-37.737695879687088, -8.462850568083876],
              [-37.737596573929309, -8.462775911795481],
              [-37.737522640017474, -8.462552485221016],
              [-37.737250185877826, -8.462179746262947],
              [-37.737027738428367, -8.461707964065463],
              [-37.73700390164921, -8.461385585051392],
              [-37.737153546047331, -8.461286863696126],
              [-37.737327754104207, -8.461287426234513],
              [-37.737476580681502, -8.461411889960866],
              [-37.737699997464837, -8.461586151544653],
              [-37.738097289955043, -8.46183530986281],
              [-37.738370884375193, -8.461885840250254],
              [-37.738421465232634, -8.461638036943215],
              [-37.738522212741699, -8.461241724062409],
              [-37.738796291130171, -8.461143403391581],
              [-37.73889632255154, -8.460994964143891],
              [-37.738797420289877, -8.460796326217332],
              [-37.738723727698989, -8.460498564878499],
              [-37.738923467421735, -8.460300890004012],
              [-37.739272114666143, -8.460202809318547],
              [-37.739272840414415, -8.459979623632751],
              [-37.739398160908593, -8.459707372820224],
              [-37.739647915343298, -8.459435432486154],
              [-37.739623512131054, -8.459286591954335],
              [-37.739449546774054, -8.459211605189489],
              [-37.739350886200775, -8.458938632463115],
              [-37.739601043073073, -8.458542710056225],
              [-37.739949205204859, -8.458593388865168],
              [-37.740247523561955, -8.45869355446143],
              [-37.740395625661648, -8.459041112447997],
              [-37.740493723017131, -8.459487714167732],
              [-37.740268614332599, -8.45983407025018],
              [-37.740168029862247, -8.460180917712135],
              [-37.740090871634514, -8.460949257311869],
              [-37.740338844088313, -8.461197932378347],
              [-37.740611310998894, -8.461595538585113],
              [-37.740758135645748, -8.462364599710103],
              [-37.740980980614985, -8.462687619806688],
              [-37.741328674008876, -8.462911836980862],
              [-37.741727016684088, -8.462838783369568],
              [-37.742025569969535, -8.462839744064027],
              [-37.742323811797931, -8.462964686950839],
              [-37.742621963311059, -8.463089538913382],
              [-37.742994947076639, -8.463189943105331],
              [-37.743317181747081, -8.463562929383153],
              [-37.74376513882671, -8.46353950000244],
              [-37.744387385447467, -8.463491941714409],
              [-37.744562799561862, -8.46312064491044],
              [-37.74458921244441, -8.462649664671462],
              [-37.74478999491231, -8.462129596524068],
              [-37.745065025349056, -8.461709061832046],
              [-37.745066711060481, -8.461188354392387],
              [-37.744919556568604, -8.460518497556142],
              [-37.744870986937428, -8.460146391021771],
              [-37.744996303835293, -8.459874137849894],
              [-37.74494797473271, -8.459427696208301],
              [-37.744574924036932, -8.459376851337716],
              [-37.744451453076742, -8.459079021125705],
              [-37.744454022154308, -8.45828557085764],
              [-37.745052336379423, -8.457940409060294],
              [-37.745451475808949, -8.457619386167909],
              [-37.745776847232712, -8.457050159060092],
              [-37.746449898569587, -8.456680455589737],
              [-37.746847593724766, -8.456805713215793],
              [-37.746995552309606, -8.457227604958899],
              [-37.746844549601725, -8.457747834608099],
              [-37.746599890205438, -8.458299867772583],
              [-37.746244948088815, -8.458575907460981],
              [-37.745713308443456, -8.458750547440504],
              [-37.745561218700601, -8.458876032853375],
              [-37.745737345331619, -8.459153774694681],
              [-37.74614009961978, -8.4597094201341],
              [-37.746442252259584, -8.460113538916174],
              [-37.746365270146647, -8.460466071244067],
              [-37.746186942345417, -8.460868649791506],
              [-37.745907350092281, -8.461296043825087],
              [-37.745552332338619, -8.461622453294343],
              [-37.745652119424292, -8.462026013940902],
              [-37.745879327932116, -8.462152715845509],
              [-37.746385224166154, -8.462103876497238],
              [-37.746764125910495, -8.462256295041843],
              [-37.747356442969178, -8.462447469323994],
              [-37.747671924859098, -8.462269241651523],
              [-37.747852561794204, -8.462023299723256],
              [-37.748100340568556, -8.461912046561798],
              [-37.748640043585247, -8.461913774085348],
              [-37.748752850978924, -8.461802088515755],
              [-37.749158161179743, -8.461624146893223],
              [-37.749495498145322, -8.461625225806467],
              [-37.74976506053769, -8.461715706939552],
              [-37.749786718574718, -8.461984724199915],
              [-37.749471184066252, -8.462207717960057],
              [-37.749155341254394, -8.462497992627766],
              [-37.748907002159207, -8.462810910373211],
              [-37.748793109296948, -8.4632587321321],
              [-37.74910727118062, -8.463461403144692],
              [-37.749376111789907, -8.463775885112266],
              [-37.7498255354976, -8.463889369146624],
              [-37.750049416308386, -8.464203797418607],
              [-37.750070333398575, -8.464674387665164],
              [-37.750451549646151, -8.465011746269164],
              [-37.750450682621207, -8.46528060110181],
              [-37.750225229454756, -8.465481546102911],
              [-37.749888467332546, -8.465301230817198],
              [-37.749597100010199, -8.464986586523686],
              [-37.749126108263937, -8.464604175564368],
              [-37.748520073063084, -8.464243759259011],
              [-37.747779592596324, -8.463748437651747],
              [-37.747308803426712, -8.463276316057376],
              [-37.74703924082435, -8.463185833162884],
              [-37.746139256123392, -8.463339849093847],
              [-37.745800903155185, -8.463652385290242],
              [-37.745394337991627, -8.464188885455689],
              [-37.745144893414526, -8.464785846466899],
              [-37.744791044532107, -8.46489033602781],
              [-37.744260442085952, -8.464994257454828],
              [-37.743976115820772, -8.465451296069038],
              [-37.743728697126151, -8.465450500929942],
              [-37.743305553227046, -8.465132083081993],
              [-37.742705337120817, -8.464883632985627],
              [-37.742316155323167, -8.464988006547154],
              [-37.742032603960553, -8.465233704416365],
              [-37.741749304720798, -8.465373686883813],
              [-37.74153733335244, -8.465337735678126],
              [-37.741149295025643, -8.465089966364664],
              [-37.740514068208952, -8.464770953228488],
              [-37.740054473982575, -8.464769472131293],
              [-37.739029379334532, -8.46476616669511],
              [-37.738745735887939, -8.465011771175819],
              [-37.738637698542391, -8.46561017750288],
              [-37.738283823964849, -8.4657499294721],
              [-37.737434816106884, -8.465923260260833],
              [-37.737327924036229, -8.466169524806933],
              [-37.737326661376521, -8.466556934228214],
              [-37.737467049478411, -8.466874354248018],
              [-37.737642999676446, -8.467121532510134],
              [-37.73796163394352, -8.46698166754099],
              [-37.738244844579292, -8.466841687910591],
              [-37.738774991632155, -8.466878577507815],
              [-37.739331574839987, -8.466942048466905],
              [-37.739578650400375, -8.467048470847685],
              [-37.739683822368988, -8.467330598433469],
              [-37.739453508224166, -8.467488293709843],
              [-37.739223136211393, -8.467663713447584],
              [-37.73918974143163, -8.467877479066633],
              [-37.739144517579099, -8.468153514642832],
              [-37.739061960881287, -8.468597363259233],
              [-37.739100721404441, -8.469162783181771],
              [-37.739262761927499, -8.469163306097084],
              [-37.739587418742538, -8.469043264171299],
              [-37.739952363457505, -8.468963684875655],
              [-37.740560287849775, -8.468965644970062],
              [-37.741168777903994, -8.46876566955685],
              [-37.741776833298623, -8.468727295108916],
              [-37.742263398760315, -8.468648105172779],
              [-37.742710109497089, -8.468366849855222],
              [-37.743156033203157, -8.468327951418487],
              [-37.743561053985715, -8.468410010531491],
              [-37.743682322407579, -8.468491157004157],
              [-37.74356026807456, -8.468652277623733],
              [-37.743315937389688, -8.469014851035947],
              [-37.742991412205647, -8.469094563610906],
              [-37.742301942189236, -8.469253857780114],
              [-37.741571305393762, -8.469614954459773],
              [-37.741528827045485, -8.47022044596708],
              [-37.741729763441072, -8.470746055541792],
              [-37.742093660274733, -8.470989496990422],
              [-37.742133383595686, -8.471231894370144],
              [-37.742010671543866, -8.471594948571525],
              [-37.741767255706783, -8.471674920995749],
              [-37.741363282343762, -8.471269837124529],
              [-37.740958916090399, -8.470985841144543],
              [-37.740472610285437, -8.470984273386865],
              [-37.740390144522195, -8.471428213080474],
              [-37.740510190285782, -8.471913138805725],
              [-37.740710903732868, -8.472479081073825],
              [-37.740467881420813, -8.472437873970794],
              [-37.740346875464958, -8.472275970852143],
              [-37.739861882420165, -8.471870624258017],
              [-37.739741048922802, -8.471627965151541],
              [-37.739661736654142, -8.471102837774684],
              [-37.739420161525096, -8.470617429369247],
              [-37.738933856471881, -8.470615859763875],
              [-37.738447025008327, -8.470775891043376],
              [-37.738121708345226, -8.471097865756077],
              [-37.737958087723868, -8.471581875315723],
              [-37.737835939277595, -8.471742993336914],
              [-37.737552144291278, -8.471782409117649],
              [-37.737512555417872, -8.471499679157688],
              [-37.737310702940107, -8.471256757650945],
              [-37.736864249302826, -8.471457159781739],
              [-37.737024800879603, -8.471942216941748],
              [-37.736821762744327, -8.472062740045171],
              [-37.736416609077821, -8.472021006121851],
              [-37.736012378182345, -8.47169667293803],
              [-37.735808812564478, -8.471978706372312],
              [-37.735807098637736, -8.472503571517327],
              [-37.73576500583988, -8.472987973005548],
              [-37.735521324577917, -8.473148696640649],
              [-37.73515663936093, -8.473147515650618],
              [-37.734873676094878, -8.472904330285031],
              [-37.734630126442553, -8.473024720833656],
              [-37.734750428889392, -8.473428891903991],
              [-37.735033169760854, -8.473712409592364],
              [-37.735193983340572, -8.474116711838269],
              [-37.735516971504126, -8.474481206361965],
              [-37.736084392876187, -8.474483043498678],
              [-37.736489590127363, -8.47448435489876],
              [-37.736814516023756, -8.474283470196152],
              [-37.736975767961127, -8.474526260776969],
              [-37.736934071196941, -8.474889574544246],
              [-37.736730768053356, -8.475090762377572],
              [-37.736448024898209, -8.47480724560797],
              [-37.736204870008379, -8.474806458645121],
              [-37.735232250683133, -8.474803309280489],
              [-37.734827886558904, -8.474519307118713],
              [-37.734098554878472, -8.474476610356733],
              [-37.734015288167043, -8.475162813562504],
              [-37.734175969072162, -8.475607448602588],
              [-37.734377994490728, -8.475769616046611],
              [-37.734134310857783, -8.475930338451576],
              [-37.734011325322996, -8.476374144102282],
              [-37.734451296916781, -8.476880274539168],
              [-37.734813963840701, -8.477527500106737],
              [-37.735082229054086, -8.477849766772973],
              [-37.735457849315772, -8.478147240905855],
              [-37.73597261018422, -8.478366217794941],
              [-37.736004915585461, -8.478570338273757],
              [-37.735872389207138, -8.478681864541432],
              [-37.735703713580037, -8.478764877827373],
              [-37.735470793446162, -8.478688973765452],
              [-37.735212247276237, -8.478616513399718],
              [-37.734853624563904, -8.478343149147975],
              [-37.73449546800417, -8.477955208156589],
              [-37.734078275938025, -8.477760058419758],
              [-37.733760886412561, -8.477322149545991],
              [-37.733357314028233, -8.476795970066988],
              [-37.732303933712338, -8.476711794452871],
              [-37.731272563632722, -8.476619096516705],
              [-37.734377443484277, -8.479091489506118],
              [-37.737144240380267, -8.481294600582268],
              [-37.737144224464288, -8.481294613160804],
              [-37.737155383858045, -8.481303499044371],
              [-37.737558586083694, -8.480999955609025],
              [-37.737891055745294, -8.479964311729503],
              [-37.738232582705308, -8.478741048831345],
              [-37.73780692967361, -8.477268333697287],
              [-37.739026783163567, -8.47658073768808],
              [-37.739108360129947, -8.475741605119039],
              [-37.739811423308602, -8.475763227756573],
              [-37.742052624346094, -8.476852574214174],
              [-37.743074184253075, -8.474962111504464],
              [-37.744348486765603, -8.473781450862605],
              [-37.744830082506731, -8.472799812919844],
              [-37.748675169670605, -8.468868277651143],
              [-37.750521832361926, -8.465581247305765],
              [-37.750723360569971, -8.46522260074765],
              [-37.751277150347391, -8.464236930976265],
              [-37.751464288629656, -8.462705681742477],
              [-37.751038680308916, -8.460895388465248],
              [-37.749074178867886, -8.460177762985175],
              [-37.748925195089619, -8.459453369698112],
              [-37.749993607987477, -8.458300507850202],
              [-37.749963969111114, -8.45794311190709],
              [-37.749330661104736, -8.457254881428705],
              [-37.748274309678777, -8.455965635404279],
              [-37.747646950943185, -8.455799763337367],
              [-37.746981302521981, -8.455509331888312],
              [-37.745769546555991, -8.454392129351636],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0234000',
        uf: 'PE',
        nome_proje: 'PA JOÃO PEREIRA DE ALENCAR',
        municipio: 'OURICURI',
        area_hecta: '567.7212',
        capacidade: 33.0,
        num_famili: 32.0,
        fase: 3.0,
        data_de_cr: '18/09/2007',
        forma_obte: 'Doação',
        data_obten: '22/11/2004',
        area_calc_: 568.9994,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.951864835876655, -8.128758724952046],
              [-39.964796533899019, -8.129437681583587],
              [-39.963464997152201, -8.121495964422317],
              [-39.968451700879868, -8.100410925297005],
              [-39.969289499811495, -8.095949645413206],
              [-39.966645042522778, -8.09687255614908],
              [-39.957714381544776, -8.121160336793482],
              [-39.936019468083174, -8.118979292851943],
              [-39.936600095677527, -8.131795002227621],
              [-39.933399958568749, -8.14139608935419],
              [-39.935581483092207, -8.141583601734661],
              [-39.940466981612573, -8.14200347197751],
              [-39.940757578423685, -8.141720441418741],
              [-39.941492445069066, -8.141307400951939],
              [-39.941492470377412, -8.141307379329847],
              [-39.941492554725016, -8.141307331322471],
              [-39.941492638552191, -8.141307282418337],
              [-39.941492721849336, -8.141307232623099],
              [-39.941492804606909, -8.141307181942407],
              [-39.941492886815439, -8.14130713038209],
              [-39.941492968465489, -8.141307077948056],
              [-39.941493049547752, -8.141307024646284],
              [-39.941493130052883, -8.14130697048293],
              [-39.941493209971703, -8.141306915464133],
              [-39.941493289295053, -8.141306859596243],
              [-39.941493368013823, -8.141306802885653],
              [-39.941493446119033, -8.141306745338834],
              [-39.941493523601721, -8.141306686962396],
              [-39.941493600452993, -8.141306627763012],
              [-39.941493676664066, -8.141306567747476],
              [-39.941493752226222, -8.14130650692265],
              [-39.941493827130785, -8.141306445295541],
              [-39.941493901369185, -8.14130638287314],
              [-39.941493974932918, -8.141306319662638],
              [-39.941494047813563, -8.141306255671271],
              [-39.941494120002758, -8.141306190906359],
              [-39.941494191492261, -8.141306125375348],
              [-39.941494262273864, -8.141306059085702],
              [-39.94149433233946, -8.141305992045037],
              [-39.941494401681027, -8.141305924261022],
              [-39.941494470290635, -8.141305855741443],
              [-39.941494538160406, -8.141305786494135],
              [-39.941494605282585, -8.141305716526992],
              [-39.941494671649458, -8.141305645848076],
              [-39.94149473725345, -8.141305574465509],
              [-39.941494802087036, -8.141305502387388],
              [-39.941494866142797, -8.141305429621987],
              [-39.941494929413395, -8.141305356177682],
              [-39.941494991891567, -8.141305282062856],
              [-39.941495053570179, -8.141305207285994],
              [-39.941495114442155, -8.14130513185567],
              [-39.941495174500517, -8.14130505578053],
              [-39.941495233738401, -8.141304979069279],
              [-39.941495292149035, -8.141304901730688],
              [-39.941495349725677, -8.141304823773664],
              [-39.941495406461783, -8.141304745207087],
              [-39.941495462350836, -8.141304666039961],
              [-39.941495517386436, -8.141304586281402],
              [-39.941495571562264, -8.141304505940489],
              [-39.941495624872154, -8.141304425026433],
              [-39.941495677309959, -8.14130434354853],
              [-39.941495728869704, -8.14130426151606],
              [-39.941495779545455, -8.141304178938473],
              [-39.941495829331423, -8.141304095825179],
              [-39.941495878221922, -8.141304012185744],
              [-39.941495926211317, -8.141303928029732],
              [-39.941495973294153, -8.14130384336678],
              [-39.941496019464985, -8.141303758206599],
              [-39.941496064718571, -8.141303672558868],
              [-39.941496109049716, -8.141303586433514],
              [-39.941496152453333, -8.141303499840348],
              [-39.941496194924461, -8.141303412789252],
              [-39.941496236458221, -8.141303325290234],
              [-39.941496277049865, -8.141303237353302],
              [-39.941496316694753, -8.141303148988532],
              [-39.941496355388338, -8.141303060206054],
              [-39.941496393126172, -8.141302971016026],
              [-39.94149642990395, -8.14130288142869],
              [-39.941496465717464, -8.141302791454258],
              [-39.9414965005626, -8.141302701103065],
              [-39.941496534435373, -8.141302610385472],
              [-39.941496567331889, -8.141302519311825],
              [-39.941496599248396, -8.141302427892589],
              [-39.941496630181256, -8.14130233613824],
              [-39.941496660126887, -8.141302244059256],
              [-39.94149668908188, -8.141302151666228],
              [-39.941496717042916, -8.141302058969714],
              [-39.941496744006791, -8.141301965980329],
              [-39.941496769970414, -8.141301872708691],
              [-39.941496794930821, -8.141301779165547],
              [-39.941496818885149, -8.141301685361583],
              [-39.941496841830656, -8.141301591307528],
              [-39.941496863764698, -8.141301497014192],
              [-39.941496884684788, -8.141301402492324],
              [-39.941496904588519, -8.141301307752808],
              [-39.941496923473608, -8.141301212806418],
              [-39.941496941337888, -8.141301117664113],
              [-39.941496958179336, -8.141301022336728],
              [-39.941496973995996, -8.141300926835195],
              [-39.941496988786049, -8.14130083117047],
              [-39.941497002547834, -8.141300735353502],
              [-39.941497015279751, -8.141300639395288],
              [-39.941497026980365, -8.14130054330675],
              [-39.941497037648311, -8.141300447098956],
              [-39.941497047282382, -8.141300350782918],
              [-39.941497055881456, -8.14130025436965],
              [-39.941497063444565, -8.141300157870205],
              [-39.941497069970858, -8.141300061295622],
              [-39.941497075459559, -8.141299964656987],
              [-39.941497079910043, -8.141299867965365],
              [-39.941497083321806, -8.141299771231798],
              [-39.941497085694458, -8.141299674467387],
              [-39.941497087027727, -8.141299577683229],
              [-39.941497087321466, -8.141299480890401],
              [-39.941497055462698, -8.141299431234163],
              [-39.941495425537624, -8.140601482622978],
              [-39.941495457401807, -8.140601534539824],
              [-39.941495456947926, -8.14060146272036],
              [-39.941495455921697, -8.140601390906793],
              [-39.94149545432321, -8.140601319103585],
              [-39.94149545215258, -8.140601247315296],
              [-39.941495449409928, -8.140601175546459],
              [-39.941495446095431, -8.140601103801556],
              [-39.941495442209273, -8.140601032085172],
              [-39.941495437751733, -8.140600960401796],
              [-39.941495432723087, -8.140600888755934],
              [-39.941495427123627, -8.140600817152142],
              [-39.941495420953736, -8.140600745594911],
              [-39.941495414213797, -8.140600674088747],
              [-39.94149540690421, -8.140600602638182],
              [-39.941495399025477, -8.140600531247696],
              [-39.941495390578062, -8.140600459921822],
              [-39.941495381562504, -8.140600388665041],
              [-39.941495371979386, -8.140600317481828],
              [-39.941495361829304, -8.140600246376678],
              [-39.941495351112891, -8.140600175354095],
              [-39.941495339830816, -8.140600104418571],
              [-39.941495327983823, -8.140600033574552],
              [-39.941495315572631, -8.14059996282648],
              [-39.941495302598021, -8.140599892178862],
              [-39.941495289060818, -8.140599821636133],
              [-39.941495274961902, -8.140599751202757],
              [-39.941495260302119, -8.140599680883152],
              [-39.941495245082422, -8.140599610681777],
              [-39.941495229303754, -8.140599540603038],
              [-39.941495212967119, -8.140599470651368],
              [-39.941495196073554, -8.14059940083116],
              [-39.941495178624102, -8.140599331146845],
              [-39.941495160619894, -8.140599261602787],
              [-39.941495142062031, -8.140599192203389],
              [-39.941495122951714, -8.140599122953041],
              [-39.941495103290123, -8.140599053856066],
              [-39.941495083078507, -8.140598984916871],
              [-39.941495062318168, -8.14059891613976],
              [-39.94149504101037, -8.1405988475291],
              [-39.94149501915647, -8.14059877908921],
              [-39.941494996757868, -8.140598710824422],
              [-39.941494973815971, -8.140598642738997],
              [-39.941494950332192, -8.14059857483727],
              [-39.941494926308039, -8.140598507123489],
              [-39.941494901745045, -8.140598439601963],
              [-39.941494876644725, -8.14059837227691],
              [-39.94149485100867, -8.140598305152615],
              [-39.941494824838507, -8.140598238233281],
              [-39.941494798135878, -8.140598171523148],
              [-39.941494770902473, -8.14059810502641],
              [-39.941494743140005, -8.140598038747239],
              [-39.941494714850222, -8.140597972689843],
              [-39.941494686034915, -8.140597906858398],
              [-39.941494656695895, -8.140597841257037],
              [-39.941494626835009, -8.140597775889892],
              [-39.941494596454156, -8.140597710761082],
              [-39.941494565555239, -8.140597645874729],
              [-39.941494534140219, -8.140597581234923],
              [-39.941494502211043, -8.140597516845736],
              [-39.941494469769758, -8.140597452711221],
              [-39.941494436818409, -8.140597388835452],
              [-39.941494403359044, -8.140597325222421],
              [-39.941494369393823, -8.140597261876145],
              [-39.94149433492484, -8.140597198800647],
              [-39.941494299954293, -8.140597135999876],
              [-39.941494264484369, -8.140597073477792],
              [-39.941494228517342, -8.140597011238365],
              [-39.94149419205543, -8.140596949285474],
              [-39.941494155100955, -8.140596887623081],
              [-39.941494117656283, -8.140596826255042],
              [-39.941494079723718, -8.14059676518521],
              [-39.941494041305688, -8.140596704417483],
              [-39.941494002404582, -8.140596643955641],
              [-39.941493963022907, -8.140596583803525],
              [-39.94149392316308, -8.140596523964938],
              [-39.941493882827658, -8.140596464443652],
              [-39.941493842019192, -8.140596405243395],
              [-39.941493800740218, -8.140596346367929],
              [-39.941493758993381, -8.140596287820939],
              [-39.941493716781267, -8.140596229606132],
              [-39.941493674106574, -8.140596171727164],
              [-39.941493630971983, -8.140596114187725],
              [-39.941493587380222, -8.140596056991408],
              [-39.941493543334026, -8.14059600014183],
              [-39.941493498836174, -8.140595943642564],
              [-39.941493453889471, -8.140595887497181],
              [-39.941493408496754, -8.14059583170922],
              [-39.9414933626609, -8.140595776282204],
              [-39.941493316384779, -8.140595721219654],
              [-39.941493269671312, -8.140595666524996],
              [-39.941493222523469, -8.140595612201714],
              [-39.941493174944178, -8.140595558253201],
              [-39.941493126936479, -8.14059550468289],
              [-39.941493078503377, -8.140595451494143],
              [-39.941493029647937, -8.1405953986903],
              [-39.941492980373255, -8.140595346274692],
              [-39.941492930682394, -8.140595294250655],
              [-39.941492880578529, -8.140595242621467],
              [-39.941492830064789, -8.140595191390355],
              [-39.941492779144397, -8.140595140560571],
              [-39.94149277859232, -8.140595157716245],
              [-39.940720856719544, -8.13983070942192],
              [-39.940720879076181, -8.139830706306922],
              [-39.940720833289852, -8.139830661283499],
              [-39.940720787184453, -8.139830616584568],
              [-39.940720740762295, -8.139830572212365],
              [-39.9407206940257, -8.139830528169107],
              [-39.940720646977049, -8.139830484457033],
              [-39.940720599618686, -8.139830441078301],
              [-39.940720551952978, -8.139830398035125],
              [-39.940720503982327, -8.139830355329648],
              [-39.940720455709155, -8.139830312964005],
              [-39.940720407135871, -8.139830270940346],
              [-39.940720358264933, -8.139830229260758],
              [-39.940720309098772, -8.139830187927352],
              [-39.940720259639868, -8.13983014694219],
              [-39.940720209890713, -8.139830106307357],
              [-39.940720159853797, -8.139830066024873],
              [-39.94072010953164, -8.13983002609676],
              [-39.940720058926757, -8.139829986525024],
              [-39.940720008041694, -8.139829947311677],
              [-39.940719956879029, -8.139829908458669],
              [-39.940719905441291, -8.139829869967944],
              [-39.940719853731103, -8.139829831841444],
              [-39.940719801751044, -8.139829794081102],
              [-39.940719749503728, -8.139829756688778],
              [-39.940719696991756, -8.139829719666393],
              [-39.940719644217815, -8.139829683015767],
              [-39.940719591184525, -8.139829646738763],
              [-39.940719537894545, -8.139829610837179],
              [-39.940719484350566, -8.139829575312852],
              [-39.940719430555262, -8.139829540167559],
              [-39.940719376511346, -8.139829505403052],
              [-39.940719322221547, -8.139829471021068],
              [-39.940719267688557, -8.139829437023357],
              [-39.940719212915127, -8.139829403411614],
              [-39.940719157904027, -8.139829370187535],
              [-39.940719102658015, -8.139829337352763],
              [-39.940719047179861, -8.139829304908993],
              [-39.94071899147233, -8.139829272857817],
              [-39.940718935538257, -8.139829241200886],
              [-39.940718879380434, -8.13982920993972],
              [-39.940718823001667, -8.139829179075969],
              [-39.940718766404807, -8.139829148611135],
              [-39.940718709592701, -8.139829118546748],
              [-39.940718652568194, -8.139829088884337],
              [-39.940718595334147, -8.139829059625381],
              [-39.940718537893432, -8.139829030771343],
              [-39.940718480248961, -8.139829002323712],
              [-39.940718422403584, -8.139828974283891],
              [-39.940718364360237, -8.139828946653269],
              [-39.940718306121838, -8.139828919433242],
              [-39.940718247691301, -8.139828892625184],
              [-39.940718189071561, -8.139828866230447],
              [-39.940718130265573, -8.139828840250344],
              [-39.940718071276272, -8.139828814686199],
              [-39.940718012106643, -8.139828789539267],
              [-39.940717952759648, -8.139828764810856],
              [-39.940717893238258, -8.139828740502171],
              [-39.94071783354547, -8.139828716614419],
              [-39.940717773684291, -8.139828693148838],
              [-39.940717713657719, -8.139828670106603],
              [-39.940717653468766, -8.13982864748885],
              [-39.940717593120468, -8.139828625296714],
              [-39.940717532615842, -8.139828603531361],
              [-39.940717471957932, -8.139828582193818],
              [-39.940717411149791, -8.139828561285183],
              [-39.940717350194461, -8.139828540806498],
              [-39.940717289095019, -8.139828520758829],
              [-39.94071722785452, -8.139828501143125],
              [-39.940717166476041, -8.139828481960398],
              [-39.940717104962687, -8.13982846321165],
              [-39.940717043317512, -8.139828444897772],
              [-39.940716981543652, -8.139828427019687],
              [-39.94071691964416, -8.139828409578314],
              [-39.940716857622199, -8.139828392574522],
              [-39.940716795480846, -8.139828376009151],
              [-39.940716733223233, -8.13982835988306],
              [-39.940716670852495, -8.13982834419704],
              [-39.940716608371773, -8.139828328951877],
              [-39.940716545784177, -8.13982831414833],
              [-39.940716483092864, -8.139828299787174],
              [-39.940716420300973, -8.139828285869081],
              [-39.940716357411688, -8.139828272394809],
              [-39.940716294428142, -8.139828259364986],
              [-39.94071623135352, -8.139828246780311],
              [-39.94071616819096, -8.139828234641373],
              [-39.940716104943654, -8.139828222948804],
              [-39.940716041614778, -8.139828211703195],
              [-39.940715978207507, -8.139828200905098],
              [-39.940715914725018, -8.139828190555074],
              [-39.940715851170523, -8.13982818065363],
              [-39.940715787547198, -8.139828171201254],
              [-39.940715723858261, -8.139828162198453],
              [-39.940715660106868, -8.139828153645631],
              [-39.940715596296251, -8.139828145543248],
              [-39.940715532429614, -8.139828137891699],
              [-39.94071546851017, -8.139828130691386],
              [-39.940715404541095, -8.139828123942655],
              [-39.940715340525649, -8.13982811764585],
              [-39.940715276467024, -8.139828111801281],
              [-39.940715212368431, -8.139828106409253],
              [-39.940715223230846, -8.139828099610472],
              [-39.93916866847227, -8.139703520858973],
              [-39.939168743391591, -8.139703511386848],
              [-39.939168726066441, -8.139703509974321],
              [-39.939168708744006, -8.139703508528724],
              [-39.939168691424413, -8.139703507050083],
              [-39.939168674107691, -8.139703505538382],
              [-39.939168656793889, -8.139703503993656],
              [-39.9391686394831, -8.13970350241587],
              [-39.939168622175366, -8.139703500805059],
              [-39.939168604870765, -8.1397034991612],
              [-39.939168587569363, -8.139703497484337],
              [-39.9391685702712, -8.139703495774464],
              [-39.939168552976369, -8.1397034940316],
              [-39.939168535684914, -8.139703492255705],
              [-39.939168518396897, -8.139703490446818],
              [-39.939168501112412, -8.139703488604926],
              [-39.939168483831473, -8.139703486730065],
              [-39.939168466554172, -8.139703484822252],
              [-39.939168449280587, -8.139703482881442],
              [-39.939168432010753, -8.139703480907688],
              [-39.939168414744749, -8.139703478900973],
              [-39.939168397482632, -8.139703476861321],
              [-39.939168380224451, -8.1397034747887],
              [-39.939168362970292, -8.139703472683173],
              [-39.939168345720226, -8.139703470544717],
              [-39.939168328474295, -8.139703468373334],
              [-39.939168311232557, -8.139703466169045],
              [-39.939168293995102, -8.139703463931854],
              [-39.939168276761976, -8.139703461661794],
              [-39.93916825953324, -8.139703459358831],
              [-39.939168242308973, -8.139703457023014],
              [-39.939168225089212, -8.139703454654299],
              [-39.93916820787404, -8.139703452252723],
              [-39.939168190663516, -8.139703449818324],
              [-39.939168173457702, -8.139703447351078],
              [-39.93916815625667, -8.139703444851001],
              [-39.93916813906047, -8.139703442318099],
              [-39.939168121869159, -8.139703439752388],
              [-39.939168104682814, -8.139703437153878],
              [-39.939168087501514, -8.139703434522561],
              [-39.93916807032528, -8.139703431858461],
              [-39.939168053154198, -8.139703429161587],
              [-39.939168035988331, -8.139703426431952],
              [-39.93916801882775, -8.139703423669587],
              [-39.93916800167252, -8.139703420874442],
              [-39.93916798452269, -8.139703418046571],
              [-39.939167967378324, -8.139703415185972],
              [-39.939167950239472, -8.139703412292661],
              [-39.939167933106219, -8.139703409366653],
              [-39.939167915978636, -8.139703406407953],
              [-39.939167898856766, -8.139703403416574],
              [-39.939167881740666, -8.139703400392518],
              [-39.939167864630406, -8.139703397335797],
              [-39.939167847526065, -8.139703394246432],
              [-39.939167830427692, -8.139703391124439],
              [-39.939167813335338, -8.139703387969803],
              [-39.939167796249102, -8.139703384782587],
              [-39.939167779169004, -8.139703381562725],
              [-39.939167762095131, -8.139703378310301],
              [-39.939167745027554, -8.139703375025297],
              [-39.939167727966293, -8.139703371707718],
              [-39.939167710911477, -8.139703368357591],
              [-39.939167693863105, -8.13970336497492],
              [-39.939167676821256, -8.13970336155972],
              [-39.939167659786023, -8.13970335811201],
              [-39.939167642757432, -8.139703354631786],
              [-39.939167625735593, -8.139703351119067],
              [-39.939167608720489, -8.139703347573885],
              [-39.93916759171227, -8.139703343996226],
              [-39.939167574710943, -8.139703340386141],
              [-39.939167557716587, -8.139703336743606],
              [-39.939167540729265, -8.139703333068628],
              [-39.939167523749028, -8.139703329361254],
              [-39.939167506775952, -8.139703325621467],
              [-39.939167489810089, -8.13970332184932],
              [-39.939167472851537, -8.139703318044793],
              [-39.939167455900296, -8.139703314207889],
              [-39.93916743895646, -8.139703310338653],
              [-39.93916742202012, -8.139703306437093],
              [-39.93916740509129, -8.13970330250322],
              [-39.939167388170056, -8.139703298537032],
              [-39.939167371256488, -8.139703294538586],
              [-39.939167354350609, -8.139703290507857],
              [-39.939167337452517, -8.139703286444872],
              [-39.939167320562269, -8.139703282349638],
              [-39.939167303679938, -8.139703278222175],
              [-39.939167286805549, -8.139703274062517],
              [-39.939167269939198, -8.139703269870651],
              [-39.939167253080925, -8.139703265646618],
              [-39.939167236230801, -8.139703261390412],
              [-39.939167219388906, -8.139703257102058],
              [-39.939167202555261, -8.139703252781555],
              [-39.939167185729964, -8.139703248428967],
              [-39.939167168913052, -8.139703244044259],
              [-39.939167152104616, -8.139703239627476],
              [-39.9391671353047, -8.139703235178597],
              [-39.939167118513346, -8.139703230697691],
              [-39.939167101730654, -8.139703226184738],
              [-39.939167084956651, -8.139703221639776],
              [-39.939167068191423, -8.139703217062802],
              [-39.939167051434985, -8.139703212454874],
              [-39.939167033876998, -8.139703162865146],
              [-39.937581902757742, -8.139265551459099],
              [-39.937581951484198, -8.139265589059526],
              [-39.937581724910693, -8.139265523333357],
              [-39.93758150012853, -8.139265451760011],
              [-39.937581277289617, -8.13926537438784],
              [-39.937581056544602, -8.139265291269203],
              [-39.937580838042678, -8.13926520246023],
              [-39.937580621931566, -8.13926510802097],
              [-39.937580408357334, -8.139265008015256],
              [-39.937580197464349, -8.139264902510694],
              [-39.937579989395175, -8.139264791578611],
              [-39.937579784290449, -8.139264675293971],
              [-39.937579582288819, -8.139264553735376],
              [-39.937579383526817, -8.139264426985031],
              [-39.937579188138798, -8.13926429512858],
              [-39.937578996256853, -8.139264158255141],
              [-39.937578808010677, -8.139264016457252],
              [-39.937578623527507, -8.139263869830778],
              [-39.937578442932057, -8.13926371847481],
              [-39.937578266346399, -8.139263562491655],
              [-39.937578093889897, -8.139263401986774],
              [-39.937577925679108, -8.139263237068636],
              [-39.937577761827768, -8.13926306784875],
              [-39.937577602446595, -8.139262894441472],
              [-39.937577447643363, -8.139262716964023],
              [-39.937577297522679, -8.139262535536377],
              [-39.937577152186023, -8.139262350281154],
              [-39.937577011731662, -8.139262161323593],
              [-39.937576876254511, -8.139261968791415],
              [-39.937576745846151, -8.13926177281478],
              [-39.937576620594747, -8.139261573526159],
              [-39.937576500584939, -8.139261371060238],
              [-39.937576385897856, -8.139261165553878],
              [-39.937576276611047, -8.139260957145998],
              [-39.937576172798344, -8.139260745977468],
              [-39.93757607452995, -8.139260532191066],
              [-39.937575981872286, -8.139260315931249],
              [-39.937575894887978, -8.139260097344213],
              [-39.93757581363581, -8.139259876577755],
              [-39.937575738170736, -8.139259653781075],
              [-39.937575668543744, -8.139259429104756],
              [-39.937575604801907, -8.139259202700668],
              [-39.937575546988299, -8.139258974721887],
              [-39.937575495142021, -8.139258745322477],
              [-39.937575449298109, -8.139258514657522],
              [-39.93757540948755, -8.139258282882931],
              [-39.937575375737246, -8.139258050155386],
              [-39.937575348070013, -8.13925781663219],
              [-39.937575326504572, -8.139257582471208],
              [-39.937575311055483, -8.139257347830698],
              [-39.93757530173319, -8.139257112869295],
              [-39.937575298544012, -8.139256877745821],
              [-39.937575301490071, -8.139256642619207],
              [-39.9375753105694, -8.139256407648354],
              [-39.937575325775867, -8.139256172992109],
              [-39.93757534709917, -8.139255938809109],
              [-39.937575374524911, -8.139255705257634],
              [-39.937575408034562, -8.13925547249557],
              [-39.937575447605447, -8.139255240680242],
              [-39.937575493210829, -8.139255009968334],
              [-39.937575544819879, -8.139254780515831],
              [-39.937575602397715, -8.139254552477796],
              [-39.937575665905406, -8.139254326008384],
              [-39.937575735300044, -8.139254101260704],
              [-39.937575810534717, -8.139253878386626],
              [-39.937575891558545, -8.139253657536846],
              [-39.937575978316794, -8.139253438860635],
              [-39.937576070750794, -8.139253222505822],
              [-39.937576168798081, -8.139253008618612],
              [-39.937576272392363, -8.139252797343625],
              [-39.937576381463622, -8.13925258882365],
              [-39.937576495938124, -8.139252383199659],
              [-39.937576615738521, -8.139252180610624],
              [-39.937576740783811, -8.139251981193512],
              [-39.937576870989432, -8.139251785083081],
              [-39.937577006267432, -8.139251592411917],
              [-39.937577146526337, -8.139251403310233],
              [-39.937577291671346, -8.139251217905899],
              [-39.937577441604333, -8.139251036324216],
              [-39.937577596223981, -8.139250858687928],
              [-39.93757775542575, -8.139250685117092],
              [-39.937577919102026, -8.139250515729051],
              [-39.937578087142185, -8.139250350638296],
              [-39.937578259432627, -8.139250189956414],
              [-39.937578435856892, -8.139250033792059],
              [-39.937578616295745, -8.139249882250764],
              [-39.937578800627186, -8.13924973543495],
              [-39.937578988726635, -8.139249593443859],
              [-39.93757918046694, -8.139249456373472],
              [-39.937579375718499, -8.139249324316511],
              [-39.937579574349321, -8.139249197362142],
              [-39.937579776225149, -8.139249075596222],
              [-39.937579981209517, -8.139248959101053],
              [-39.937580189163867, -8.139248847955399],
              [-39.937580399947628, -8.139248742234345],
              [-39.937580613418326, -8.139248642009413],
              [-39.937580829431667, -8.13924854734832],
              [-39.937581047841618, -8.13924845831505],
              [-39.937581268500573, -8.139248374969783],
              [-39.937581491259344, -8.13924829736886],
              [-39.937581715967362, -8.139248225564749],
              [-39.937581680854137, -8.13924818680168],
              [-39.939035873259712, -8.13880418590348],
              [-39.939035936168708, -8.138804225170302],
              [-39.939035950173547, -8.138804220906657],
              [-39.939035964185265, -8.138804216665516],
              [-39.939035978203826, -8.1388042124469],
              [-39.939035992229215, -8.138804208250857],
              [-39.939036006261368, -8.138804204077324],
              [-39.939036020300264, -8.138804199926382],
              [-39.939036034345882, -8.138804195798],
              [-39.939036048398144, -8.138804191692211],
              [-39.939036062457035, -8.138804187609013],
              [-39.939036076522534, -8.138804183548439],
              [-39.939036090594577, -8.138804179510478],
              [-39.939036104673136, -8.138804175495178],
              [-39.939036118758189, -8.138804171502485],
              [-39.939036132849687, -8.138804167532436],
              [-39.939036146947579, -8.138804163585048],
              [-39.939036161051867, -8.138804159660323],
              [-39.939036175162478, -8.138804155758271],
              [-39.939036189279392, -8.138804151878965],
              [-39.939036203402559, -8.138804148022324],
              [-39.939036217531971, -8.138804144188386],
              [-39.939036231667565, -8.138804140377196],
              [-39.939036245809312, -8.138804136588723],
              [-39.93903625995717, -8.138804132822974],
              [-39.939036274111103, -8.138804129079988],
              [-39.939036288271083, -8.138804125359798],
              [-39.939036302437067, -8.138804121662334],
              [-39.939036316609013, -8.138804117987656],
              [-39.939036330786905, -8.138804114335768],
              [-39.939036344970674, -8.138804110706692],
              [-39.939036359160319, -8.13880410710042],
              [-39.939036373355776, -8.138804103516941],
              [-39.93903638755701, -8.138804099956307],
              [-39.939036401763993, -8.138804096418506],
              [-39.939036415976695, -8.13880409290355],
              [-39.93903643019506, -8.138804089411458],
              [-39.939036444419074, -8.138804085942215],
              [-39.939036458648673, -8.138804082495836],
              [-39.939036472883842, -8.138804079072356],
              [-39.939036487124518, -8.138804075671773],
              [-39.939036501370708, -8.138804072294089],
              [-39.939036515622327, -8.138804068939269],
              [-39.939036529879367, -8.138804065607413],
              [-39.939036544141793, -8.138804062298462],
              [-39.939036558409533, -8.13880405901244],
              [-39.939036572682596, -8.138804055749381],
              [-39.939036586960924, -8.13880405250926],
              [-39.93903660124446, -8.138804049292119],
              [-39.939036615533219, -8.138804046097929],
              [-39.9390366298271, -8.138804042926704],
              [-39.939036644126112, -8.138804039778494],
              [-39.939036658430197, -8.138804036653259],
              [-39.939036672739327, -8.138804033551033],
              [-39.939036687053473, -8.138804030471793],
              [-39.939036701372572, -8.138804027415588],
              [-39.939036715696595, -8.138804024382418],
              [-39.939036730025514, -8.138804021372264],
              [-39.939036744359306, -8.138804018385146],
              [-39.939036758697902, -8.13880401542108],
              [-39.939036773041288, -8.13880401248008],
              [-39.939036787389405, -8.138804009562152],
              [-39.939036801742219, -8.138804006667254],
              [-39.939036816099723, -8.138804003795462],
              [-39.939036830461845, -8.138804000946754],
              [-39.939036844828557, -8.138803998121151],
              [-39.939036859199831, -8.138803995318616],
              [-39.939036873575631, -8.138803992539215],
              [-39.939036887955901, -8.138803989782922],
              [-39.939036902340618, -8.138803987049776],
              [-39.939036916729741, -8.13880398433974],
              [-39.93903693112324, -8.138803981652853],
              [-39.939036945521075, -8.138803978989085],
              [-39.9390369599232, -8.138803976348477],
              [-39.939036974329561, -8.138803973731033],
              [-39.93903698874017, -8.138803971136728],
              [-39.939037003154951, -8.138803968565647],
              [-39.939037017573867, -8.138803966017679],
              [-39.939037031996904, -8.138803963492958],
              [-39.939037046423991, -8.138803960991403],
              [-39.939037060855121, -8.138803958513043],
              [-39.939037075290244, -8.138803956057899],
              [-39.939037089729332, -8.138803953625944],
              [-39.939037104172328, -8.138803951217211],
              [-39.939037118619211, -8.138803948831715],
              [-39.939037133069938, -8.138803946469448],
              [-39.939037147524459, -8.13880394413038],
              [-39.939037161982753, -8.138803941814606],
              [-39.939037176444792, -8.138803939522033],
              [-39.939037190910511, -8.138803937252757],
              [-39.939037205379904, -8.138803935006713],
              [-39.939037219852892, -8.138803932783929],
              [-39.939037234329469, -8.138803930584452],
              [-39.939037248809591, -8.138803928408214],
              [-39.939037263293201, -8.138803926255267],
              [-39.939037277780301, -8.138803924125593],
              [-39.939037292270811, -8.13880392201926],
              [-39.939037306764732, -8.138803919936203],
              [-39.939037321261999, -8.138803917876418],
              [-39.939037335762578, -8.138803915839976],
              [-39.939037350266389, -8.138803913827413],
              [-39.939037324849288, -8.138803911216524],
              [-39.940449979794487, -8.138608992656188],
              [-39.940450009572594, -8.138608992607516],
              [-39.940450132841633, -8.138608974738515],
              [-39.940450255852951, -8.138608955185443],
              [-39.940450378583407, -8.138608933951991],
              [-39.94045050100987, -8.138608911042157],
              [-39.940450623109271, -8.138608886460268],
              [-39.940450744858609, -8.138608860210898],
              [-39.940450866234961, -8.138608832299033],
              [-39.940450987215456, -8.138608802729932],
              [-39.940451107777321, -8.138608771509137],
              [-39.94045122789786, -8.138608738642569],
              [-39.940451347554408, -8.138608704136411],
              [-39.940451466724461, -8.138608667997127],
              [-39.940451585385553, -8.138608630231568],
              [-39.940451703515329, -8.138608590846804],
              [-39.940451821091571, -8.1386085498503],
              [-39.940451938092096, -8.138608507249756],
              [-39.940452054494898, -8.138608463053176],
              [-39.940452170278036, -8.138608417268907],
              [-39.940452285419695, -8.138608369905576],
              [-39.940452399898199, -8.138608320972079],
              [-39.94045251369198, -8.138608270477654],
              [-39.94045262677961, -8.138608218431829],
              [-39.940452739139779, -8.138608164844364],
              [-39.940452850751328, -8.138608109725379],
              [-39.940452961593245, -8.138608053085246],
              [-39.94045307164464, -8.138607994934667],
              [-39.940453180884788, -8.138607935284531],
              [-39.94045328929311, -8.13860787414613],
              [-39.940453396849179, -8.138607811530946],
              [-39.940453503532765, -8.138607747450804],
              [-39.940453609323754, -8.13860768191776],
              [-39.940453714202206, -8.138607614944156],
              [-39.940453818148399, -8.138607546542591],
              [-39.940453921142741, -8.138607476725964],
              [-39.940454023165799, -8.138607405507408],
              [-39.940454124198418, -8.138607332900349],
              [-39.940454224221511, -8.138607258918487],
              [-39.940454323216287, -8.138607183575731],
              [-39.94045442116407, -8.138607106886274],
              [-39.940454518046401, -8.138607028864572],
              [-39.94045461384507, -8.138606949525274],
              [-39.940454708541992, -8.138606868883409],
              [-39.940454802119369, -8.138606786954078],
              [-39.940454894559558, -8.138606703752789],
              [-39.940454985845122, -8.138606619295157],
              [-39.940455075958909, -8.138606533597116],
              [-39.940455164883929, -8.138606446674807],
              [-39.940455252603414, -8.138606358544589],
              [-39.940455339100872, -8.138606269223086],
              [-39.940455424359989, -8.138606178727107],
              [-39.940455508364721, -8.138606087073672],
              [-39.94045559109923, -8.138605994280088],
              [-39.940455672547948, -8.138605900363824],
              [-39.940455752695527, -8.138605805342557],
              [-39.940455831526862, -8.138605709234174],
              [-39.940455909027129, -8.138605612056791],
              [-39.940455985181707, -8.138605513828717],
              [-39.940456059976256, -8.138605414568449],
              [-39.940456133396701, -8.138605314294667],
              [-39.9404562054292, -8.138605213026294],
              [-39.940456276060189, -8.138605110782388],
              [-39.940456345276367, -8.138605007582196],
              [-39.940456413064695, -8.138604903445147],
              [-39.940456479412397, -8.138604798390899],
              [-39.940456544306983, -8.138604692439207],
              [-39.940456607736238, -8.138604585610029],
              [-39.940456669688203, -8.138604477923495],
              [-39.940456730151219, -8.138604369399889],
              [-39.940456789113888, -8.13860426005963],
              [-39.940456846565105, -8.138604149923324],
              [-39.940456902494063, -8.138604039011737],
              [-39.940456956890202, -8.138603927345748],
              [-39.940457009743298, -8.138603814946391],
              [-39.940457061043382, -8.138603701834809],
              [-39.940457110780812, -8.138603588032343],
              [-39.940457158946188, -8.138603473560416],
              [-39.940457205530471, -8.138603358440569],
              [-39.940457250524858, -8.138603242694538],
              [-39.940457293920893, -8.138603126344067],
              [-39.940457335710391, -8.138603009411101],
              [-39.940457375885501, -8.138602891917671],
              [-39.940457414438626, -8.138602773885863],
              [-39.940457451362526, -8.138602655337968],
              [-39.940457486650239, -8.138602536296286],
              [-39.940457520295105, -8.138602416783225],
              [-39.940457552290823, -8.138602296821325],
              [-39.940457582631332, -8.138602176433189],
              [-39.940457611310919, -8.138602055641462],
              [-39.940457638324212, -8.138601934468912],
              [-39.940457663666102, -8.138601812938354],
              [-39.940457687331808, -8.138601691072664],
              [-39.940457709316888, -8.138601568894826],
              [-39.940457729617201, -8.138601446427829],
              [-39.940457748228908, -8.138601323694751],
              [-39.940457765148508, -8.138601200718705],
              [-39.940457780372824, -8.138601077522864],
              [-39.940457793898986, -8.138600954130439],
              [-39.940457805724428, -8.138600830564656],
              [-39.940457815846955, -8.138600706848772],
              [-39.94045776607306, -8.138600653822207],
              [-39.940519384062547, -8.137778650835756],
              [-39.940519383564215, -8.137778621830444],
              [-39.940519391191856, -8.137778510777743],
              [-39.940519397447339, -8.137778399639878],
              [-39.940519402329713, -8.137778288433728],
              [-39.940519405838224, -8.137778177176129],
              [-39.940519407972353, -8.137778065883907],
              [-39.94051940873176, -8.137777954573947],
              [-39.940519408116344, -8.137777843263128],
              [-39.940519406126192, -8.137777731968244],
              [-39.940519402761609, -8.137777620706224],
              [-39.940519398023106, -8.137777509493899],
              [-39.940519391911387, -8.137777398348097],
              [-39.940519384427404, -8.137777287285678],
              [-39.940519375572272, -8.137777176323455],
              [-39.940519365347328, -8.137777065478215],
              [-39.940519353754134, -8.137776954766785],
              [-39.940519340794438, -8.137776844205893],
              [-39.940519326470209, -8.137776733812315],
              [-39.940519310783607, -8.137776623602768],
              [-39.940519293737026, -8.137776513593945],
              [-39.940519275333024, -8.137776403802516],
              [-39.940519255574408, -8.137776294245104],
              [-39.940519234464162, -8.137776184938277],
              [-39.940519212005483, -8.137776075898657],
              [-39.940519188201783, -8.137775967142709],
              [-39.94051916305667, -8.137775858686924],
              [-39.940519136573919, -8.137775750547723],
              [-39.940519108757577, -8.137775642741463],
              [-39.940519079611846, -8.137775535284527],
              [-39.940519049141137, -8.137775428193157],
              [-39.940519017350077, -8.137775321483586],
              [-39.940518984243468, -8.137775215171974],
              [-39.940518949826334, -8.137775109274427],
              [-39.940518914103876, -8.137775003806979],
              [-39.940518877081523, -8.137774898785601],
              [-39.940518838764881, -8.137774794226226],
              [-39.940518799159726, -8.137774690144672],
              [-39.940518758272084, -8.13777458655672],
              [-39.940518716108137, -8.137774483478033],
              [-39.940518672674294, -8.137774380924231],
              [-39.940518627977106, -8.137774278910866],
              [-39.940518582023358, -8.137774177453393],
              [-39.940518534820008, -8.137774076567158],
              [-39.940518486374195, -8.137773976267475],
              [-39.940518436693282, -8.137773876569476],
              [-39.940518385784785, -8.137773777488327],
              [-39.940518333656378, -8.137773679039023],
              [-39.940518280316013, -8.137773581236457],
              [-39.940518225771733, -8.137773484095449],
              [-39.940518170031829, -8.1377733876307],
              [-39.940518113104716, -8.137773291856854],
              [-39.940518054999032, -8.137773196788382],
              [-39.940517995723582, -8.137773102439718],
              [-39.940517935287325, -8.137773008825141],
              [-39.940517873699434, -8.137772915958818],
              [-39.94051781096924, -8.137772823854824],
              [-39.94051774710622, -8.137772732527129],
              [-39.940517682120088, -8.137772641989542],
              [-39.940517616020621, -8.137772552255786],
              [-39.940517548817915, -8.137772463339456],
              [-39.940517480522097, -8.137772375254009],
              [-39.940517411143524, -8.137772288012817],
              [-39.940517340692715, -8.137772201629058],
              [-39.940517269180305, -8.137772116115816],
              [-39.940517196617179, -8.137772031486087],
              [-39.940517123014288, -8.137771947752652],
              [-39.940517048382794, -8.137771864928201],
              [-39.940516972734002, -8.137771783025295],
              [-39.940516896079366, -8.137771702056318],
              [-39.940516818430524, -8.137771622033561],
              [-39.9405167397992, -8.137771542969114],
              [-39.940516660197332, -8.137771464874984],
              [-39.940516579636949, -8.137771387762962],
              [-39.940516498130279, -8.137771311644775],
              [-39.940516415689665, -8.137771236531915],
              [-39.940516332327576, -8.137771162435781],
              [-39.94051624805666, -8.13777108936759],
              [-39.940516162889672, -8.137771017338384],
              [-39.940516076839515, -8.137770946359117],
              [-39.940515989919227, -8.137770876440539],
              [-39.940515902141968, -8.137770807593238],
              [-39.940515813521024, -8.137770739827605],
              [-39.940515724069847, -8.137770673153915],
              [-39.940515633801965, -8.13777060758229],
              [-39.940515542731056, -8.137770543122661],
              [-39.940515450870912, -8.137770479784779],
              [-39.940515358235452, -8.137770417578249],
              [-39.940515264838702, -8.137770356512499],
              [-39.940515170694823, -8.137770296596745],
              [-39.940515075818055, -8.137770237840121],
              [-39.940514980222794, -8.13777018025146],
              [-39.940514883923491, -8.13777012383953],
              [-39.940514786934756, -8.137770068612854],
              [-39.940514689271261, -8.137770014579809],
              [-39.940514590947821, -8.137769961748562],
              [-39.940514491979314, -8.137769910127144],
              [-39.940514392380742, -8.137769859723358],
              [-39.94051429216718, -8.137769810544865],
              [-39.940514191353813, -8.137769762599062],
              [-39.940514089955911, -8.137769715893238],
              [-39.940514098895683, -8.137769709164608],
              [-39.938975749245401, -8.137072511563876],
              [-39.938975736109512, -8.137072533021477],
              [-39.938975610592642, -8.137072474982141],
              [-39.938975485978297, -8.137072415042839],
              [-39.938975362295444, -8.137072353217453],
              [-39.938975239572834, -8.137072289520393],
              [-39.938975117838957, -8.137072223966433],
              [-39.938974997122152, -8.137072156570817],
              [-39.938974877450441, -8.137072087349219],
              [-39.938974758851671, -8.13707201631771],
              [-39.938974641353369, -8.137071943492822],
              [-39.938974524982889, -8.137071868891457],
              [-39.938974409767219, -8.137071792530953],
              [-39.938974295733196, -8.137071714429089],
              [-39.938974182907302, -8.137071634603998],
              [-39.938974071315734, -8.137071553074216],
              [-39.938973960984477, -8.137071469858725],
              [-39.938973851939132, -8.137071384976849],
              [-39.938973744205065, -8.13707129844831],
              [-39.938973637807301, -8.137071210293232],
              [-39.938973532770596, -8.137071120532093],
              [-39.938973429119329, -8.137071029185762],
              [-39.938973326877637, -8.137070936275476],
              [-39.93897322606923, -8.137070841822812],
              [-39.938973126717556, -8.137070745849744],
              [-39.938973028845723, -8.137070648378566],
              [-39.938972932476467, -8.137070549431945],
              [-39.938972837632178, -8.137070449032846],
              [-39.938972744334926, -8.137070347204611],
              [-39.93897265260636, -8.137070243970951],
              [-39.938972562467818, -8.137070139355817],
              [-39.938972473940254, -8.137070033383521],
              [-39.938972387044238, -8.137069926078736],
              [-39.938972301799964, -8.137069817466372],
              [-39.938972218227271, -8.137069707571658],
              [-39.938972136345527, -8.13706959642017],
              [-39.938972056173824, -8.137069484037708],
              [-39.938971977730738, -8.13706937045041],
              [-39.938971901034563, -8.137069255684679],
              [-39.938971826103078, -8.137069139767178],
              [-39.938971752953705, -8.137069022724868],
              [-39.938971681603462, -8.137068904584954],
              [-39.938971612068933, -8.137068785374874],
              [-39.938971544366247, -8.137068665122317],
              [-39.938971478511178, -8.137068543855278],
              [-39.938971414519003, -8.13706842160191],
              [-39.938971352404629, -8.137068298390647],
              [-39.938971292182465, -8.137068174250111],
              [-39.938971233866518, -8.137068049209178],
              [-39.938971177470322, -8.137067923296858],
              [-39.938971123007015, -8.137067796542471],
              [-39.938971070489252, -8.137067668975451],
              [-39.93897101992922, -8.13706754062545],
              [-39.938970971338662, -8.137067411522315],
              [-39.938970924728913, -8.137067281696014],
              [-39.938970880110773, -8.137067151176772],
              [-39.938970837494622, -8.137067019994898],
              [-39.938970796890359, -8.137066888180865],
              [-39.938970758307434, -8.137066755765307],
              [-39.938970721754814, -8.137066622779054],
              [-39.938970687240968, -8.137066489252957],
              [-39.938970654773961, -8.137066355218096],
              [-39.938970624361311, -8.137066220705584],
              [-39.938970596010073, -8.137066085746735],
              [-39.938970569726862, -8.137065950372859],
              [-39.938970545517783, -8.137065814615447],
              [-39.938970523388463, -8.13706567850603],
              [-39.938970503344031, -8.137065542076279],
              [-39.938970485389149, -8.137065405357896],
              [-39.938970469528016, -8.13706526838264],
              [-39.938970455764299, -8.137065131182352],
              [-39.938970444101173, -8.137064993788901],
              [-39.938970434541403, -8.13706485623427],
              [-39.93897042708717, -8.137064718550393],
              [-39.938970421740215, -8.137064580769263],
              [-39.938970418501782, -8.137064442922913],
              [-39.938970417372623, -8.137064305043403],
              [-39.938970418353009, -8.137064167162771],
              [-39.938970421442704, -8.137064029313066],
              [-39.938970426640978, -8.137063891526275],
              [-39.938970433946658, -8.137063753834491],
              [-39.938970443358023, -8.1370636162697],
              [-39.938970454872873, -8.137063478863839],
              [-39.938970468488549, -8.137063341648863],
              [-39.938970484201889, -8.137063204656698],
              [-39.938970502009234, -8.137063067919144],
              [-39.938970521906441, -8.137062931467984],
              [-39.938970543888892, -8.137062795334964],
              [-39.938970567951465, -8.137062659551683],
              [-39.938970594088609, -8.137062524149727],
              [-39.938970622294192, -8.137062389160574],
              [-39.938970652561693, -8.137062254615561],
              [-39.938970684884076, -8.137062120545972],
              [-39.938970719253824, -8.137061986982983],
              [-39.938970755662929, -8.137061853957634],
              [-39.93897079410295, -8.137061721500855],
              [-39.938970834564962, -8.137061589643395],
              [-39.938970877039552, -8.137061458415936],
              [-39.938970921516841, -8.137061327848942],
              [-39.938970967986485, -8.137061197972807],
              [-39.938971016437485, -8.137061068817854],
              [-39.938971002806973, -8.137061036202299],
              [-39.939288474281476, -8.136234103214671],
              [-39.939288475488858, -8.136234100305934],
              [-39.939288485131378, -8.136234075041006],
              [-39.93928849469809, -8.136234049747477],
              [-39.939288504188944, -8.13623402442559],
              [-39.939288513603842, -8.136233999075557],
              [-39.939288522942711, -8.136233973697639],
              [-39.939288532205438, -8.136233948292011],
              [-39.939288541391967, -8.136233922858933],
              [-39.939288550502205, -8.136233897398602],
              [-39.939288559536088, -8.136233871911278],
              [-39.939288568493502, -8.136233846397175],
              [-39.939288577374391, -8.136233820856518],
              [-39.939288586178677, -8.136233795289536],
              [-39.939288594906273, -8.13623376969645],
              [-39.939288603557102, -8.136233744077513],
              [-39.939288612131094, -8.136233718432946],
              [-39.939288620628169, -8.136233692762969],
              [-39.939288629048221, -8.136233667067797],
              [-39.939288637391236, -8.136233641347687],
              [-39.939288645657093, -8.136233615602837],
              [-39.939288653845743, -8.136233589833523],
              [-39.939288661957086, -8.136233564039943],
              [-39.939288669991072, -8.136233538222315],
              [-39.93928867794763, -8.136233512380894],
              [-39.939288685826668, -8.136233486515918],
              [-39.939288693628143, -8.136233460627599],
              [-39.939288701351948, -8.136233434716146],
              [-39.939288708998056, -8.13623340878183],
              [-39.939288716566388, -8.136233382824866],
              [-39.939288724056851, -8.136233356845469],
              [-39.939288731469404, -8.13623333084392],
              [-39.939288738803974, -8.136233304820433],
              [-39.939288746060505, -8.136233278775224],
              [-39.939288753238905, -8.136233252708529],
              [-39.939288760339124, -8.136233226620556],
              [-39.939288767361113, -8.136233200511583],
              [-39.939288774304792, -8.136233174381804],
              [-39.939288781170099, -8.136233148231486],
              [-39.93928878795699, -8.136233122060844],
              [-39.939288794665387, -8.136233095870121],
              [-39.939288801295248, -8.136233069659532],
              [-39.939288807846488, -8.136233043429332],
              [-39.939288814319063, -8.13623301717975],
              [-39.93928882071291, -8.136232990910996],
              [-39.939288827027987, -8.136232964623337],
              [-39.939288833264222, -8.136232938317026],
              [-39.939288839421565, -8.136232911992265],
              [-39.939288845499959, -8.136232885649274],
              [-39.939288851499356, -8.136232859288324],
              [-39.93928885741969, -8.136232832909625],
              [-39.939288863260927, -8.13623280651341],
              [-39.939288869022995, -8.136232780099945],
              [-39.939288874705852, -8.136232753669441],
              [-39.939288880309455, -8.136232727222122],
              [-39.939288885833747, -8.13623270075826],
              [-39.939288891278657, -8.136232674278055],
              [-39.939288896644165, -8.136232647781778],
              [-39.939288901930226, -8.136232621269645],
              [-39.93928890713677, -8.136232594741877],
              [-39.939288912263763, -8.136232568198745],
              [-39.939288917311174, -8.13623254164045],
              [-39.939288922278934, -8.136232515067222],
              [-39.939288927167013, -8.136232488479362],
              [-39.939288931975334, -8.136232461877043],
              [-39.939288936703896, -8.136232435260538],
              [-39.93928894135265, -8.13623240863007],
              [-39.939288945921547, -8.1362323819859],
              [-39.93928895041055, -8.136232355328259],
              [-39.939288954819595, -8.136232328657355],
              [-39.939288959148683, -8.136232301973461],
              [-39.93928896339775, -8.136232275276782],
              [-39.939288967566746, -8.136232248567586],
              [-39.939288971655671, -8.136232221846093],
              [-39.939288975664468, -8.136232195112555],
              [-39.939288979593094, -8.136232168367208],
              [-39.939288983441521, -8.136232141610266],
              [-39.939288987209714, -8.136232114841993],
              [-39.939288990897644, -8.1362320880626],
              [-39.939288994505269, -8.136232061272372],
              [-39.93928899803258, -8.136232034471519],
              [-39.939289001479509, -8.136232007660286],
              [-39.939289004846053, -8.136231980838911],
              [-39.939289008132171, -8.13623195400765],
              [-39.939289011337834, -8.136231927166719],
              [-39.939289014463021, -8.136231900316355],
              [-39.93928901750769, -8.136231873456827],
              [-39.939289020471826, -8.136231846588322],
              [-39.9392890233554, -8.13623181971114],
              [-39.939289026158391, -8.13623179282547],
              [-39.939289028880765, -8.136231765931598],
              [-39.939289031522499, -8.136231739029721],
              [-39.939289034083558, -8.136231712120134],
              [-39.939289036563949, -8.136231685203018],
              [-39.939289038963629, -8.136231658278655],
              [-39.939289041282585, -8.13623163134724],
              [-39.939289043520787, -8.136231604409069],
              [-39.939289045678223, -8.136231577464327],
              [-39.939289047754848, -8.136231550513285],
              [-39.939289049750691, -8.136231523556221],
              [-39.939289051665703, -8.136231496593323],
              [-39.93928901396113, -8.136231476197541],
              [-39.9393512778909, -8.135334946078709],
              [-39.939351349399018, -8.135334939482799],
              [-39.939351352041065, -8.135334900220704],
              [-39.939351354511643, -8.135334860947511],
              [-39.939351356810725, -8.135334821663969],
              [-39.939351358938261, -8.135334782370853],
              [-39.939351360894236, -8.13533474306886],
              [-39.939351362678565, -8.135334703758799],
              [-39.939351364291248, -8.135334664441309],
              [-39.939351365732257, -8.135334625117267],
              [-39.939351367001549, -8.135334585787316],
              [-39.939351368099103, -8.135334546452222],
              [-39.939351369024912, -8.135334507112759],
              [-39.939351369778933, -8.135334467769662],
              [-39.939351370361187, -8.135334428423665],
              [-39.939351370771632, -8.135334389075519],
              [-39.939351371010268, -8.135334349725952],
              [-39.939351371077095, -8.135334310375736],
              [-39.939351370972112, -8.1353342710256],
              [-39.93935137069532, -8.135334231676296],
              [-39.939351370246726, -8.135334192328552],
              [-39.93935136962633, -8.135334152983148],
              [-39.939351368834153, -8.135334113640782],
              [-39.939351367870209, -8.13533407430223],
              [-39.939351366734513, -8.135334034968222],
              [-39.939351365427079, -8.135333995639517],
              [-39.939351363947949, -8.135333956316861],
              [-39.939351362297131, -8.13533391700096],
              [-39.939351360474689, -8.135333877692615],
              [-39.939351358480614, -8.135333838392496],
              [-39.93935135631498, -8.135333799101454],
              [-39.939351353977806, -8.135333759820139],
              [-39.939351351469149, -8.135333720549347],
              [-39.939351348789039, -8.135333681289781],
              [-39.939351345937553, -8.135333642042237],
              [-39.939351342914733, -8.135333602807409],
              [-39.939351339720623, -8.135333563586073],
              [-39.939351336355308, -8.135333524378938],
              [-39.939351332818816, -8.135333485186782],
              [-39.939351329111261, -8.135333446010323],
              [-39.939351325232685, -8.135333406850311],
              [-39.939351321183146, -8.135333367707476],
              [-39.93935131696275, -8.135333328582586],
              [-39.939351312571574, -8.135333289476341],
              [-39.939351308009691, -8.135333250389522],
              [-39.939351303277178, -8.135333211322825],
              [-39.939351298374142, -8.13533317227704],
              [-39.939351293300689, -8.135333133252848],
              [-39.939351288056884, -8.135333094251061],
              [-39.939351282642825, -8.135333055272346],
              [-39.939351277058648, -8.135333016317494],
              [-39.939351271304432, -8.135332977387208],
              [-39.939351265380289, -8.135332938482261],
              [-39.93935125928634, -8.135332899603336],
              [-39.939351253022693, -8.135332860751245],
              [-39.939351246589482, -8.135332821926639],
              [-39.939351239986792, -8.135332783130306],
              [-39.939351233214786, -8.135332744362969],
              [-39.939351226273573, -8.135332705625352],
              [-39.939351219163299, -8.135332666918197],
              [-39.939351211884073, -8.13533262824221],
              [-39.939351204436065, -8.135332589598166],
              [-39.939351196819381, -8.135332550986792],
              [-39.939351189034205, -8.135332512408777],
              [-39.939351181080646, -8.135332473864894],
              [-39.939351172958879, -8.135332435355842],
              [-39.939351164669048, -8.135332396882415],
              [-39.939351156211316, -8.13533235844527],
              [-39.939351147585839, -8.135332320045123],
              [-39.939351138792794, -8.135332281682777],
              [-39.939351129832296, -8.13533224335891],
              [-39.939351120704579, -8.13533220507421],
              [-39.939351111409799, -8.135332166829485],
              [-39.939351101948112, -8.135332128625425],
              [-39.939351092319697, -8.135332090462718],
              [-39.93935108252478, -8.135332052342147],
              [-39.939351072563468, -8.135332014264394],
              [-39.939351062436032, -8.135331976230168],
              [-39.939351052142612, -8.13533193824024],
              [-39.939351041683409, -8.135331900295277],
              [-39.939351031058642, -8.135331862396045],
              [-39.939351020268504, -8.135331824543204],
              [-39.939351009313178, -8.13533178673751],
              [-39.939350998192907, -8.13533174897967],
              [-39.939350986907868, -8.135331711270425],
              [-39.939350975458304, -8.13533167361045],
              [-39.939350963844397, -8.135331636000448],
              [-39.939350952066398, -8.13533159844113],
              [-39.939350940124534, -8.135331560933286],
              [-39.939350928018982, -8.135331523477543],
              [-39.93935091575004, -8.135331486074662],
              [-39.939350903317873, -8.135331448725298],
              [-39.939350890722778, -8.135331411430217],
              [-39.939350877964948, -8.135331374190061],
              [-39.939350865044652, -8.135331337005589],
              [-39.939350851962111, -8.135331299877471],
              [-39.939350838717587, -8.135331262806448],
              [-39.93935082531133, -8.135331225793179],
              [-39.939350811743601, -8.135331188838403],
              [-39.93935079801463, -8.135331151942777],
              [-39.939350784124699, -8.135331115107043],
              [-39.939350724468085, -8.135331148728472],
              [-39.938963421371142, -8.134310664659093],
              [-39.938963457598632, -8.134310678378574],
              [-39.938963423111623, -8.134310586001973],
              [-39.938963389636882, -8.13431049325637],
              [-39.938963357178409, -8.134310400152756],
              [-39.93896332574004, -8.134310306702211],
              [-39.9389632953255, -8.13431021291581],
              [-39.938963265938419, -8.134310118804709],
              [-39.938963237582279, -8.134310024380078],
              [-39.938963210260461, -8.13430992965314],
              [-39.938963183976178, -8.134309834635125],
              [-39.938963158732591, -8.134309739337317],
              [-39.938963134532671, -8.134309643771084],
              [-39.938963111379309, -8.134309547947757],
              [-39.938963089275234, -8.134309451878636],
              [-39.938963068223067, -8.134309355575217],
              [-39.938963048225354, -8.134309259048889],
              [-39.938963029284423, -8.134309162311162],
              [-39.938963011402549, -8.134309065373495],
              [-39.938962994581821, -8.134308968247389],
              [-39.9389629788243, -8.134308870944418],
              [-39.938962964131797, -8.134308773476118],
              [-39.938962950506088, -8.134308675854017],
              [-39.938962937948773, -8.134308578089792],
              [-39.938962926461357, -8.134308480194978],
              [-39.938962916045206, -8.134308382181265],
              [-39.938962906701541, -8.134308284060223],
              [-39.938962898431484, -8.134308185843555],
              [-39.938962891236017, -8.134308087542935],
              [-39.938962885115984, -8.134307989169999],
              [-39.938962880072125, -8.13430789073646],
              [-39.938962876105037, -8.134307792253987],
              [-39.938962873215196, -8.134307693734284],
              [-39.938962871402921, -8.13430759518905],
              [-39.938962870668455, -8.134307496629981],
              [-39.938962871011874, -8.134307398068797],
              [-39.93896287243313, -8.134307299517186],
              [-39.938962874932059, -8.134307200986866],
              [-39.938962878508377, -8.134307102489556],
              [-39.938962883161636, -8.134307004036906],
              [-39.938962888891297, -8.134306905640621],
              [-39.938962895696683, -8.134306807312429],
              [-39.938962903576979, -8.134306709063948],
              [-39.938962912531267, -8.134306610906886],
              [-39.938962922558446, -8.134306512852874],
              [-39.938962933657351, -8.134306414913583],
              [-39.938962945826695, -8.134306317100627],
              [-39.938962959064952, -8.134306219425662],
              [-39.938962973370614, -8.13430612190019],
              [-39.93896298874197, -8.134306024535894],
              [-39.938963005177193, -8.134305927344299],
              [-39.938963022674301, -8.134305830336919],
              [-39.938963041231254, -8.134305733525322],
              [-39.938963060845836, -8.134305636920971],
              [-39.938963081515723, -8.134305540535353],
              [-39.938963103238457, -8.134305444379919],
              [-39.938963126011458, -8.134305348466095],
              [-39.938963149831984, -8.134305252805239],
              [-39.938963174697292, -8.134305157408731],
              [-39.938963200604348, -8.134305062287934],
              [-39.938963227550126, -8.13430496745409],
              [-39.938963255531391, -8.13430487291849],
              [-39.938963284544833, -8.134304778692352],
              [-39.938963314587014, -8.13430468478688],
              [-39.938963345654365, -8.134304591213187],
              [-39.938963377743185, -8.134304497982434],
              [-39.938963410849674, -8.134304405105663],
              [-39.9389634449699, -8.134304312593917],
              [-39.938963480099794, -8.134304220458171],
              [-39.938963516235219, -8.134304128709394],
              [-39.938963553371842, -8.134304037358451],
              [-39.938963591505292, -8.134303946416187],
              [-39.938963630631008, -8.134303855893432],
              [-39.938963670744371, -8.134303765800926],
              [-39.938963711840572, -8.13430367614937],
              [-39.938963753914784, -8.134303586949386],
              [-39.938963796961993, -8.134303498211588],
              [-39.938963840977074, -8.134303409946517],
              [-39.938963885954806, -8.134303322164634],
              [-39.938963931889852, -8.134303234876379],
              [-39.938963978776748, -8.134303148092156],
              [-39.938964026609945, -8.134303061822207],
              [-39.938964075383737, -8.134302976076819],
              [-39.938964125092348, -8.134302890866165],
              [-39.938964175729865, -8.134302806200347],
              [-39.938964227290278, -8.134302722089421],
              [-39.938964279767475, -8.134302638543424],
              [-39.938964333155205, -8.134302555572212],
              [-39.938964387447143, -8.134302473185674],
              [-39.938964442636838, -8.1343023913936],
              [-39.938964498717738, -8.134302310205685],
              [-39.93896455568315, -8.134302229631579],
              [-39.938964613526373, -8.134302149680849],
              [-39.938964672240502, -8.134302070362978],
              [-39.938964731818537, -8.134301991687403],
              [-39.938964792253451, -8.134301913663466],
              [-39.938964853538039, -8.134301836300436],
              [-39.938964915665025, -8.134301759607478],
              [-39.938964978627027, -8.134301683593741],
              [-39.938965042416584, -8.134301608268199],
              [-39.93896510702649, -8.134301533639215],
              [-39.938965033844546, -8.134301526131608],
              [-39.939408051090837, -8.133795374860233],
              [-39.939408127924771, -8.133795416056273],
              [-39.939408204952478, -8.133795329187963],
              [-39.939408283091176, -8.133795243310585],
              [-39.939408362327981, -8.133795158438213],
              [-39.939408442649928, -8.133795074584796],
              [-39.939408524043792, -8.133794991764107],
              [-39.939408606496201, -8.13379490998973],
              [-39.939408689993627, -8.133794829275113],
              [-39.939408774522349, -8.133794749633536],
              [-39.939408860068475, -8.133794671078061],
              [-39.939408946617945, -8.133794593621587],
              [-39.939409034156554, -8.133794517276854],
              [-39.939409122669936, -8.133794442056388],
              [-39.939409212143509, -8.133794367972563],
              [-39.939409302562602, -8.133794295037546],
              [-39.939409393912371, -8.133794223263317],
              [-39.939409486177794, -8.133794152661668],
              [-39.93940957934371, -8.133794083244208],
              [-39.93940967339482, -8.133794015022273],
              [-39.939409768315677, -8.133793948007138],
              [-39.939409864090692, -8.133793882209806],
              [-39.939409960704118, -8.133793817641065],
              [-39.939410058140098, -8.133793754311556],
              [-39.939410156382614, -8.13379369223165],
              [-39.939410255415531, -8.133793631411539],
              [-39.939410355222599, -8.133793571861236],
              [-39.939410455787367, -8.133793513590533],
              [-39.939410557093375, -8.133793456608954],
              [-39.939410659123965, -8.133793400925915],
              [-39.939410761862355, -8.133793346550524],
              [-39.939410865291684, -8.133793293491745],
              [-39.939410969394963, -8.133793241758278],
              [-39.939411074155096, -8.133793191358601],
              [-39.93941117955486, -8.133793142301027],
              [-39.939411285576945, -8.133793094593599],
              [-39.93941139220393, -8.133793048244176],
              [-39.939411499418291, -8.133793003260321],
              [-39.939411607202452, -8.133792959649492],
              [-39.939411715538668, -8.133792917418843],
              [-39.939411824409156, -8.133792876575228],
              [-39.939411933796038, -8.133792837125434],
              [-39.939412043681344, -8.133792799075922],
              [-39.939412154046998, -8.133792762432927],
              [-39.939412264874903, -8.133792727202511],
              [-39.939412376146826, -8.133792693390406],
              [-39.939412487844514, -8.133792661002214],
              [-39.939412599949598, -8.133792630043256],
              [-39.939412712443669, -8.133792600518555],
              [-39.939412825308239, -8.133792572433023],
              [-39.939412938524782, -8.133792545791273],
              [-39.939413052074698, -8.133792520597638],
              [-39.939413165939321, -8.133792496856309],
              [-39.939413280099942, -8.133792474571132],
              [-39.939413394537837, -8.133792453745814],
              [-39.939413509234178, -8.133792434383757],
              [-39.939413624170129, -8.133792416488113],
              [-39.939413739326817, -8.133792400061873],
              [-39.939413854685327, -8.133792385107736],
              [-39.939413970226688, -8.133792371628109],
              [-39.939414085931944, -8.133792359625247],
              [-39.939414201782071, -8.133792349101089],
              [-39.939414317758057, -8.133792340057401],
              [-39.939414433840824, -8.133792332495647],
              [-39.939414550011321, -8.133792326417076],
              [-39.93941466625045, -8.133792321822696],
              [-39.939414782539124, -8.133792318713221],
              [-39.939414898858253, -8.133792317089227],
              [-39.939415015188715, -8.13379231695092],
              [-39.939415131511396, -8.133792318298347],
              [-39.93941524780719, -8.133792321131274],
              [-39.939415364056991, -8.133792325449267],
              [-39.939415480241713, -8.133792331251591],
              [-39.939415596342265, -8.133792338537299],
              [-39.939415712339567, -8.133792347305201],
              [-39.939415828214564, -8.133792357553862],
              [-39.939415943948227, -8.13379236928157],
              [-39.939416059521555, -8.133792382486421],
              [-39.939416174915529, -8.133792397166228],
              [-39.939416290111225, -8.133792413318616],
              [-39.939416405089702, -8.133792430940893],
              [-39.939416519832086, -8.133792450030159],
              [-39.939416634319514, -8.133792470583321],
              [-39.939416748533191, -8.133792492596955],
              [-39.939416862454337, -8.133792516067496],
              [-39.939416976064244, -8.133792540991037],
              [-39.939417089344253, -8.133792567363534],
              [-39.939417202275777, -8.133792595180617],
              [-39.939417314840234, -8.133792624437719],
              [-39.939417427019144, -8.133792655130064],
              [-39.939417538794061, -8.133792687252567],
              [-39.939417650146638, -8.13379272079999],
              [-39.939417761058579, -8.133792755766789],
              [-39.939417871511679, -8.133792792147235],
              [-39.939417981487779, -8.133792829935352],
              [-39.939418090968807, -8.133792869124921],
              [-39.939418199936789, -8.133792909709539],
              [-39.939418308373824, -8.133792951682533],
              [-39.939418416262107, -8.13379299503695],
              [-39.939418523583903, -8.13379303976572],
              [-39.939418630321754, -8.133793085861276],
              [-39.93941857430007, -8.133793089444476],
              [-39.940574719978549, -8.134301149798889],
              [-39.940574723301573, -8.134301148130145],
              [-39.940574756446331, -8.134301162774927],
              [-39.940574789532114, -8.134301177551485],
              [-39.940574822558411, -8.134301192459631],
              [-39.940574855524659, -8.134301207499147],
              [-39.940574888430376, -8.134301222669784],
              [-39.940574921275001, -8.134301237971277],
              [-39.940574954058015, -8.134301253403368],
              [-39.94057498677892, -8.134301268965825],
              [-39.940575019437176, -8.134301284658433],
              [-39.940575052032258, -8.134301300480901],
              [-39.940575084563655, -8.134301316432998],
              [-39.940575117030839, -8.134301332514458],
              [-39.940575149433293, -8.134301348725037],
              [-39.940575181770512, -8.134301365064461],
              [-39.940575214041964, -8.13430138153249],
              [-39.940575246247136, -8.134301398128805],
              [-39.940575278385538, -8.134301414853205],
              [-39.94057531045663, -8.134301431705429],
              [-39.940575342459908, -8.134301448685145],
              [-39.940575374394868, -8.134301465792126],
              [-39.940575406260997, -8.134301483026084],
              [-39.94057543805777, -8.134301500386773],
              [-39.940575469784704, -8.134301517873885],
              [-39.940575501441288, -8.134301535487138],
              [-39.940575533027001, -8.134301553226296],
              [-39.940575564541348, -8.134301571091024],
              [-39.94057559598383, -8.134301589081016],
              [-39.940575627353951, -8.134301607196059],
              [-39.940575658651206, -8.134301625435819],
              [-39.940575689875068, -8.134301643800038],
              [-39.940575721025077, -8.134301662288369],
              [-39.940575752100727, -8.134301680900572],
              [-39.940575783101515, -8.134301699636334],
              [-39.940575814026943, -8.134301718495331],
              [-39.940575844876534, -8.134301737477264],
              [-39.940575875649756, -8.134301756581879],
              [-39.940575906346183, -8.134301775808826],
              [-39.94057593696526, -8.134301795157802],
              [-39.940575967506533, -8.134301814628486],
              [-39.940575997969518, -8.134301834220645],
              [-39.940576028353703, -8.134301853933843],
              [-39.940576058658635, -8.134301873767843],
              [-39.940576088883809, -8.134301893722364],
              [-39.940576119028748, -8.134301913797017],
              [-39.940576149092983, -8.134301933991479],
              [-39.940576179076004, -8.134301954305471],
              [-39.940576208977355, -8.134301974738653],
              [-39.940576238796559, -8.134301995290693],
              [-39.940576268533142, -8.134302015961266],
              [-39.940576298186613, -8.13430203675008],
              [-39.94057632775651, -8.134302057656738],
              [-39.940576357242364, -8.134302078680928],
              [-39.940576386643706, -8.134302099822326],
              [-39.940576415960059, -8.134302121080585],
              [-39.940576445190949, -8.134302142455379],
              [-39.940576474335934, -8.134302163946344],
              [-39.940576503394531, -8.134302185553159],
              [-39.940576532366265, -8.134302207275486],
              [-39.940576561250708, -8.134302229112958],
              [-39.940576590047357, -8.134302251065243],
              [-39.940576618755792, -8.134302273131981],
              [-39.940576647375536, -8.134302295312811],
              [-39.940576675906129, -8.134302317607384],
              [-39.940576704347116, -8.134302340015324],
              [-39.940576732698041, -8.134302362536312],
              [-39.940576760958464, -8.13430238516999],
              [-39.940576789127931, -8.134302407915957],
              [-39.940576817206001, -8.134302430773896],
              [-39.940576845192197, -8.134302453743436],
              [-39.940576873086087, -8.134302476824155],
              [-39.940576900887251, -8.134302500015718],
              [-39.940576928595199, -8.134302523317777],
              [-39.940576956209526, -8.134302546729954],
              [-39.940576983729763, -8.134302570251888],
              [-39.940577011155497, -8.134302593883149],
              [-39.940577038486275, -8.13430261762341],
              [-39.940577065721676, -8.134302641472248],
              [-39.940577092861247, -8.134302665429342],
              [-39.940577119904567, -8.134302689494257],
              [-39.940577146851204, -8.134302713666623],
              [-39.94057717370071, -8.134302737946072],
              [-39.94057720045268, -8.13430276233218],
              [-39.940577227106672, -8.134302786824614],
              [-39.940577253662269, -8.134302811422915],
              [-39.940577280119044, -8.134302836126791],
              [-39.940577306476577, -8.134302860935744],
              [-39.940577332734456, -8.134302885849413],
              [-39.940577358892241, -8.134302910867405],
              [-39.94057738494952, -8.13430293598932],
              [-39.940577410905888, -8.13430296121477],
              [-39.940577436760918, -8.134302986543316],
              [-39.94057746251422, -8.134303011974579],
              [-39.940577488165353, -8.134303037508181],
              [-39.940577513713933, -8.134303063143669],
              [-39.940577539159541, -8.13430308888066],
              [-39.940577564501773, -8.134303114718749],
              [-39.940577589740215, -8.134303140657472],
              [-39.940577614874478, -8.134303166696498],
              [-39.940577639904248, -8.134303192834857],
              [-39.940577629236756, -8.134303223266206],
              [-39.941676011140018, -8.13545482897212],
              [-39.941676034333533, -8.13545485299319],
              [-39.941676058070357, -8.135454877975988],
              [-39.941676081711655, -8.135454903048577],
              [-39.941676105257073, -8.135454928210612],
              [-39.941676128706256, -8.135454953461711],
              [-39.941676152058889, -8.1354549788015],
              [-39.941676175314619, -8.135455004229616],
              [-39.941676198473118, -8.135455029745714],
              [-39.941676221534038, -8.135455055349416],
              [-39.94167624449706, -8.135455081040305],
              [-39.941676267361814, -8.135455106818085],
              [-39.941676290128044, -8.135455132682342],
              [-39.941676312795359, -8.135455158632695],
              [-39.941676335363447, -8.135455184668791],
              [-39.94167635783198, -8.13545521079026],
              [-39.941676380200647, -8.135455236996696],
              [-39.941676402469099, -8.13545526328773],
              [-39.941676424637031, -8.135455289662945],
              [-39.941676446704122, -8.135455316122052],
              [-39.94167646867006, -8.135455342664578],
              [-39.941676490534498, -8.135455369290174],
              [-39.941676512297157, -8.13545539599847],
              [-39.941676533957697, -8.135455422789045],
              [-39.941676555515805, -8.135455449661544],
              [-39.941676576971176, -8.135455476615562],
              [-39.941676598323497, -8.13545550365072],
              [-39.941676619572476, -8.135455530766592],
              [-39.941676640717773, -8.135455557962837],
              [-39.941676661759118, -8.135455585239027],
              [-39.941676682696176, -8.135455612594757],
              [-39.941676703528657, -8.135455640029683],
              [-39.941676724256261, -8.135455667543358],
              [-39.941676744878691, -8.135455695135413],
              [-39.941676765395641, -8.135455722805441],
              [-39.941676785806806, -8.135455750553003],
              [-39.9416768061119, -8.135455778377731],
              [-39.941676826310641, -8.135455806279236],
              [-39.941676846402729, -8.135455834257105],
              [-39.941676866387859, -8.135455862310927],
              [-39.941676886265753, -8.135455890440303],
              [-39.941676906036129, -8.135455918644826],
              [-39.941676925698708, -8.135455946924091],
              [-39.941676945253178, -8.135455975277679],
              [-39.941676964699269, -8.135456003705194],
              [-39.941676984036704, -8.135456032206189],
              [-39.941677003265212, -8.135456060780314],
              [-39.941677022384503, -8.135456089427063],
              [-39.941677041394286, -8.135456118146115],
              [-39.94167706029431, -8.135456146937004],
              [-39.941677079084279, -8.135456175799325],
              [-39.941677097763957, -8.135456204732654],
              [-39.94167711633304, -8.13545623373661],
              [-39.941677134791277, -8.135456262810679],
              [-39.941677153138386, -8.135456291954553],
              [-39.94167717137411, -8.135456321167736],
              [-39.941677189498193, -8.135456350449861],
              [-39.941677207510352, -8.13545637980042],
              [-39.941677225410352, -8.135456409219083],
              [-39.941677243197923, -8.13545643870539],
              [-39.941677260872794, -8.135456468258891],
              [-39.941677278434739, -8.135456497879193],
              [-39.941677295883466, -8.135456527565847],
              [-39.941677313218747, -8.135456557318379],
              [-39.941677330440342, -8.135456587136426],
              [-39.941677347547966, -8.135456617019537],
              [-39.941677364541405, -8.135456646967288],
              [-39.941677381420391, -8.135456676979253],
              [-39.941677398184687, -8.135456707054962],
              [-39.941677414834068, -8.135456737193989],
              [-39.941677431368269, -8.135456767395899],
              [-39.941677447787058, -8.135456797660298],
              [-39.941677464090205, -8.135456827986685],
              [-39.941677480277455, -8.135456858374681],
              [-39.941677496348589, -8.135456888823775],
              [-39.941677512303386, -8.135456919333603],
              [-39.941677528141597, -8.135456949903707],
              [-39.94167754386298, -8.135456980533565],
              [-39.941677559467344, -8.135457011222792],
              [-39.941677574954426, -8.135457041970968],
              [-39.941677590324041, -8.135457072777617],
              [-39.941677605575926, -8.135457103642308],
              [-39.941677620709889, -8.135457134564581],
              [-39.941677635725696, -8.135457165543986],
              [-39.941677650623141, -8.135457196580102],
              [-39.941677665402004, -8.135457227672456],
              [-39.941677680062064, -8.13545725882061],
              [-39.941677694603129, -8.135457290024091],
              [-39.941677709024951, -8.135457321282489],
              [-39.941677723327359, -8.135457352595333],
              [-39.941677737510126, -8.135457383962128],
              [-39.941677751573046, -8.135457415382458],
              [-39.941677765515941, -8.135457446855856],
              [-39.941677779338576, -8.135457478381911],
              [-39.941677793040768, -8.135457509960116],
              [-39.941677806622316, -8.135457541590064],
              [-39.941677820083022, -8.13545757327122],
              [-39.941677833422702, -8.135457605003181],
              [-39.941677846641142, -8.135457636785468],
              [-39.941677859738178, -8.135457668617637],
              [-39.941677872713598, -8.13545770049936],
              [-39.941677833155779, -8.135457718941387],
              [-39.942061617252584, -8.136405943285894],
              [-39.942061680606948, -8.13640591733891],
              [-39.942061751317326, -8.136406087041914],
              [-39.942061825472898, -8.136406255278418],
              [-39.942061903043083, -8.136406421979043],
              [-39.942061983995849, -8.136406587074941],
              [-39.942062068297787, -8.136406750497958],
              [-39.942062155914108, -8.136406912180664],
              [-39.942062246808646, -8.136407072056304],
              [-39.942062340943863, -8.136407230058904],
              [-39.942062438280928, -8.13640738612323],
              [-39.942062538779645, -8.136407540184896],
              [-39.942062642398547, -8.136407692180262],
              [-39.942062749094859, -8.136407842046626],
              [-39.942062858824542, -8.136407989722134],
              [-39.942062971542299, -8.136408135145802],
              [-39.94206308720161, -8.136408278257612],
              [-39.942063205754728, -8.13640841899851],
              [-39.942063327152717, -8.136408557310411],
              [-39.942063451345476, -8.136408693136165],
              [-39.942063578281747, -8.136408826419794],
              [-39.942063707909142, -8.136408957106216],
              [-39.942063840174121, -8.136409085141524],
              [-39.942063975022137, -8.136409210472848],
              [-39.942064112397482, -8.136409333048467],
              [-39.942064252243497, -8.136409452817807],
              [-39.942064394502417, -8.136409569731413],
              [-39.942064539115563, -8.136409683740998],
              [-39.942064686023215, -8.136409794799544],
              [-39.94206483516475, -8.136409902861201],
              [-39.942064986478613, -8.136410007881331],
              [-39.942065139902319, -8.136410109816651],
              [-39.942065295372565, -8.136410208625032],
              [-39.942065452825183, -8.136410304265723],
              [-39.942065612195172, -8.136410396699226],
              [-39.942065773416729, -8.136410485887398],
              [-39.942065936423354, -8.136410571793425],
              [-39.942066101147731, -8.136410654381836],
              [-39.942066267521874, -8.136410733618568],
              [-39.94206643547713, -8.136410809470906],
              [-39.942066604944138, -8.136410881907528],
              [-39.942066775852972, -8.136410950898524],
              [-39.942066948133089, -8.136411016415423],
              [-39.942067121713357, -8.136411078431196],
              [-39.942067296522154, -8.136411136920252],
              [-39.942067472487324, -8.136411191858452],
              [-39.942067649536206, -8.136411243223096],
              [-39.942067827595757, -8.136411290992999],
              [-39.942068006592464, -8.136411335148436],
              [-39.942068186452438, -8.136411375671178],
              [-39.942068367101442, -8.136411412544506],
              [-39.942068548464931, -8.136411445753208],
              [-39.942068730468016, -8.136411475283541],
              [-39.942068913035591, -8.136411501123353],
              [-39.942069096092297, -8.136411523261966],
              [-39.942069279562567, -8.136411541690256],
              [-39.942069463370686, -8.136411556400601],
              [-39.942069647440775, -8.136411567386922],
              [-39.942069831696855, -8.136411574644685],
              [-39.942070016062878, -8.136411578170913],
              [-39.942070200462737, -8.136411577964132],
              [-39.942070384820333, -8.136411574024434],
              [-39.942070569059545, -8.136411566353461],
              [-39.942070753104353, -8.136411554954352],
              [-39.942070936878771, -8.136411539831833],
              [-39.94207112030697, -8.13641152099213],
              [-39.942071303313206, -8.136411498443033],
              [-39.942071485821963, -8.136411472193847],
              [-39.94207166775788, -8.13641144225539],
              [-39.942071849045902, -8.136411408640054],
              [-39.942072029611175, -8.136411371361694],
              [-39.94207220937917, -8.13641133043569],
              [-39.94207238827569, -8.136411285878927],
              [-39.942072566226898, -8.136411237709856],
              [-39.942072743159322, -8.136411185948271],
              [-39.942072918999969, -8.136411130615588],
              [-39.942073093676207, -8.136411071734647],
              [-39.942073267115987, -8.136411009329738],
              [-39.942073439247693, -8.136410943426624],
              [-39.942073610000271, -8.136410874052521],
              [-39.942073779303264, -8.136410801236041],
              [-39.942073947086747, -8.136410725007236],
              [-39.942074113281514, -8.136410645397602],
              [-39.942074277818939, -8.136410562439979],
              [-39.942074440631124, -8.136410476168621],
              [-39.942074601650845, -8.136410386619094],
              [-39.942074760811657, -8.136410293828412],
              [-39.942074918047865, -8.13641019783487],
              [-39.942075073294546, -8.136410098678054],
              [-39.942075226487653, -8.136409996398928],
              [-39.942075377563931, -8.136409891039696],
              [-39.942075526461025, -8.13640978264384],
              [-39.942075673117472, -8.136409671256114],
              [-39.942075817472741, -8.13640955692248],
              [-39.942075959467253, -8.136409439690151],
              [-39.942076099042403, -8.136409319607502],
              [-39.942076236140558, -8.136409196724095],
              [-39.942076370705159, -8.13640907109067],
              [-39.942076502680635, -8.136408942759079],
              [-39.942076632012522, -8.136408811782269],
              [-39.942076758647445, -8.13640867821433],
              [-39.942076690494659, -8.136408712079916],
              [-39.942903645500621, -8.135518548195654],
              [-39.942903703705589, -8.135518513864817],
              [-39.942903723097658, -8.135518492924115],
              [-39.942903742423411, -8.13551847192263],
              [-39.942903761682679, -8.135518450860605],
              [-39.942903780875255, -8.135518429738219],
              [-39.942903800000956, -8.135518408555669],
              [-39.94290381905958, -8.135518387313178],
              [-39.942903838050974, -8.135518366010956],
              [-39.942903856974901, -8.135518344649256],
              [-39.94290387583122, -8.13551832322821],
              [-39.94290389461969, -8.135518301748089],
              [-39.942903913340167, -8.135518280209093],
              [-39.942903931992454, -8.135518258611434],
              [-39.942903950576373, -8.13551823695534],
              [-39.942903969091702, -8.135518215240982],
              [-39.942903987538308, -8.135518193468631],
              [-39.942904005915977, -8.135518171638454],
              [-39.942904024224553, -8.135518149750704],
              [-39.94290404246383, -8.135518127805584],
              [-39.942904060633623, -8.1355181058033],
              [-39.942904078733768, -8.135518083744083],
              [-39.942904096764089, -8.135518061628174],
              [-39.942904114724385, -8.135518039455754],
              [-39.942904132614487, -8.135518017227058],
              [-39.942904150434231, -8.135517994942299],
              [-39.942904168183425, -8.135517972601741],
              [-39.942904185861892, -8.13551795020553],
              [-39.942904203469475, -8.135517927753959],
              [-39.942904221005982, -8.135517905247214],
              [-39.942904238471243, -8.135517882685532],
              [-39.942904255865088, -8.135517860069111],
              [-39.942904273187331, -8.135517837398224],
              [-39.942904290437824, -8.135517814673037],
              [-39.942904307616367, -8.135517791893825],
              [-39.942904324722825, -8.135517769060804],
              [-39.942904341757, -8.135517746174179],
              [-39.942904358718721, -8.1355177232342],
              [-39.942904375607846, -8.135517700241088],
              [-39.942904392424168, -8.135517677195057],
              [-39.942904409167568, -8.135517654096354],
              [-39.942904425837838, -8.135517630945209],
              [-39.942904442434838, -8.135517607741843],
              [-39.942904458958388, -8.13551758448647],
              [-39.942904475408341, -8.135517561179368],
              [-39.942904491784518, -8.135517537820716],
              [-39.942904508086755, -8.135517514410774],
              [-39.942904524314912, -8.13551749094977],
              [-39.942904540468788, -8.13551746743793],
              [-39.942904556548285, -8.135517443875523],
              [-39.942904572553175, -8.135517420262696],
              [-39.942904588483344, -8.135517396599759],
              [-39.942904604338622, -8.135517372886945],
              [-39.942904620118846, -8.135517349124454],
              [-39.942904635823865, -8.135517325312545],
              [-39.942904651453503, -8.135517301451465],
              [-39.942904667007646, -8.135517277541423],
              [-39.942904682486109, -8.135517253582666],
              [-39.942904697888743, -8.135517229575452],
              [-39.942904713215412, -8.135517205519994],
              [-39.942904728465926, -8.135517181416551],
              [-39.942904743640163, -8.135517157265342],
              [-39.942904758737981, -8.135517133066619],
              [-39.942904773759217, -8.135517108820617],
              [-39.942904788703707, -8.135517084527553],
              [-39.942904803571324, -8.13551706018772],
              [-39.94290481836191, -8.135517035801314],
              [-39.942904833075325, -8.135517011368611],
              [-39.942904847711411, -8.135516986889842],
              [-39.942904862270048, -8.135516962365228],
              [-39.942904876751065, -8.13551693779506],
              [-39.942904891154335, -8.135516913179535],
              [-39.942904905479693, -8.135516888518906],
              [-39.942904919727027, -8.135516863813429],
              [-39.942904933896166, -8.135516839063339],
              [-39.942904947986982, -8.135516814268907],
              [-39.942904961999361, -8.135516789430367],
              [-39.942904975933111, -8.135516764547939],
              [-39.94290498978814, -8.135516739621893],
              [-39.942905003564277, -8.135516714652502],
              [-39.942905017261417, -8.135516689639946],
              [-39.942905030879409, -8.135516664584568],
              [-39.942905044418104, -8.135516639486495],
              [-39.942905057877397, -8.13551661434609],
              [-39.94290507125713, -8.135516589163535],
              [-39.942905084557175, -8.135516563939097],
              [-39.942905097777405, -8.13551653867305],
              [-39.942905110917692, -8.13551651336561],
              [-39.942905123977887, -8.135516488017052],
              [-39.942905136957883, -8.13551646262759],
              [-39.942905149857538, -8.135516437197531],
              [-39.942905162676738, -8.135516411727108],
              [-39.942905175415348, -8.13551638621654],
              [-39.942905188073226, -8.135516360666118],
              [-39.942905200650266, -8.135516335076092],
              [-39.942905213146339, -8.1355163094467],
              [-39.942905225561319, -8.135516283778227],
              [-39.942905237895069, -8.135516258070876],
              [-39.94290525014749, -8.135516232324949],
              [-39.942905262318448, -8.135516206540684],
              [-39.942905274407842, -8.135516180718334],
              [-39.942905273738525, -8.13551619288655],
              [-39.94335321380224, -8.134555276567157],
              [-39.943353271877648, -8.134555328943726],
              [-39.943353297521853, -8.134555273452957],
              [-39.943353322789328, -8.134555217790878],
              [-39.943353347678908, -8.134555161959993],
              [-39.94335337218947, -8.13455510596286],
              [-39.9433533963199, -8.134555049802005],
              [-39.943353420069087, -8.134554993480025],
              [-39.943353443435946, -8.13455493699948],
              [-39.943353466419417, -8.134554880362934],
              [-39.943353489018449, -8.134554823573001],
              [-39.943353511232004, -8.134554766632231],
              [-39.943353533059081, -8.134554709543234],
              [-39.943353554498678, -8.13455465230864],
              [-39.943353575549814, -8.134554594931032],
              [-39.943353596211523, -8.134554537413051],
              [-39.943353616482888, -8.134554479757314],
              [-39.943353636362964, -8.134554421966456],
              [-39.943353655850842, -8.13455436404308],
              [-39.943353674945627, -8.134554305989859],
              [-39.943353693646479, -8.134554247809469],
              [-39.943353711952533, -8.13455418950449],
              [-39.94335372986292, -8.134554131077651],
              [-39.943353747376861, -8.134554072531589],
              [-39.943353764493537, -8.134554013868975],
              [-39.943353781212181, -8.134553955092498],
              [-39.943353797532012, -8.134553896204819],
              [-39.943353813452305, -8.134553837208664],
              [-39.94335382897232, -8.13455377810665],
              [-39.943353844091362, -8.134553718901541],
              [-39.943353858808727, -8.134553659596035],
              [-39.943353873123748, -8.134553600192794],
              [-39.943353887035784, -8.134553540694579],
              [-39.943353900544182, -8.134553481104078],
              [-39.943353913648323, -8.134553421423998],
              [-39.943353926347633, -8.134553361657071],
              [-39.943353938641515, -8.134553301806044],
              [-39.943353950529421, -8.134553241873618],
              [-39.943353962010804, -8.13455318186257],
              [-39.943353973085117, -8.134553121775594],
              [-39.943353983751877, -8.13455306161546],
              [-39.943353994010607, -8.134553001384916],
              [-39.943354003860804, -8.134552941086662],
              [-39.943354013302056, -8.134552880723495],
              [-39.943354022333914, -8.134552820298158],
              [-39.943354030955973, -8.134552759813404],
              [-39.943354039167836, -8.134552699272009],
              [-39.94335404696912, -8.134552638676714],
              [-39.943354054359482, -8.134552578030304],
              [-39.943354061338567, -8.134552517335505],
              [-39.943354067906078, -8.134552456595113],
              [-39.943354074061709, -8.134552395811912],
              [-39.943354079805182, -8.134552334988681],
              [-39.943354085136221, -8.134552274128167],
              [-39.943354090054591, -8.134552213233166],
              [-39.943354094560071, -8.134552152306457],
              [-39.943354098652449, -8.134552091350804],
              [-39.94335410233154, -8.134552030368988],
              [-39.943354105597187, -8.134551969363809],
              [-39.943354108449221, -8.134551908338045],
              [-39.943354110887519, -8.134551847294453],
              [-39.943354112911983, -8.134551786235855],
              [-39.943354114522513, -8.134551725165039],
              [-39.943354115719018, -8.134551664084762],
              [-39.943354116501482, -8.134551602997814],
              [-39.943354116869834, -8.134551541907012],
              [-39.943354116824054, -8.134551480815109],
              [-39.943354116364183, -8.134551419724927],
              [-39.943354115490209, -8.134551358639232],
              [-39.943354114202187, -8.134551297560797],
              [-39.94335411250016, -8.13455123649242],
              [-39.943354110384213, -8.134551175436899],
              [-39.943354107854461, -8.134551114397023],
              [-39.943354104910995, -8.134551053375548],
              [-39.943354101553965, -8.134550992375299],
              [-39.943354097783512, -8.134550931399025],
              [-39.943354093599801, -8.134550870449512],
              [-39.943354089003044, -8.134550809529584],
              [-39.943354083993448, -8.134550748641949],
              [-39.943354078571225, -8.134550687789435],
              [-39.943354072736639, -8.134550626974807],
              [-39.943354066489952, -8.13455056620084],
              [-39.943354059831442, -8.134550505470301],
              [-39.943354052761421, -8.134550444785967],
              [-39.943354045280209, -8.134550384150595],
              [-39.943354037388147, -8.134550323566994],
              [-39.943354029085604, -8.13455026303788],
              [-39.943354020372915, -8.134550202566016],
              [-39.943354011250555, -8.134550142154186],
              [-39.943354001718859, -8.134550081805152],
              [-39.943353991778331, -8.134550021521617],
              [-39.943353981429375, -8.134549961306384],
              [-39.943353970672504, -8.134549901162178],
              [-39.943353959508173, -8.134549841091745],
              [-39.943353947936899, -8.134549781097846],
              [-39.943353935959216, -8.134549721183195],
              [-39.943353923575671, -8.134549661350517],
              [-39.943353910786833, -8.134549601602567],
              [-39.943353897593276, -8.134549541942052],
              [-39.94335388399562, -8.134549482371707],
              [-39.943353869994461, -8.134549422894231],
              [-39.943353835396692, -8.134549396298581],
              [-39.94309480505202, -8.133465489847442],
              [-39.943094841638562, -8.133465534108254],
              [-39.943094820147593, -8.133465442048262],
              [-39.943094799623232, -8.133465349769514],
              [-39.943094780067774, -8.133465257282067],
              [-39.943094761483302, -8.133465164596023],
              [-39.943094743871896, -8.133465071721536],
              [-39.943094727235447, -8.133464978668693],
              [-39.94309471157576, -8.133464885447729],
              [-39.943094696894612, -8.133464792068748],
              [-39.943094683193522, -8.133464698541978],
              [-39.943094670474032, -8.133464604877682],
              [-39.943094658737536, -8.133464511086006],
              [-39.943094647985305, -8.13346441717724],
              [-39.94309463821849, -8.13346432316162],
              [-39.9430946294382, -8.133464229049423],
              [-39.943094621645358, -8.133464134850936],
              [-39.943094614840838, -8.133464040576472],
              [-39.943094609025387, -8.133463946236267],
              [-39.943094604199629, -8.133463851840666],
              [-39.943094600364063, -8.133463757399962],
              [-39.943094597519156, -8.133463662924443],
              [-39.943094595665201, -8.133463568424487],
              [-39.943094594802389, -8.133463473910407],
              [-39.943094594930834, -8.133463379392477],
              [-39.943094596050521, -8.133463284881058],
              [-39.943094598161281, -8.133463190386438],
              [-39.943094601262942, -8.133463095918989],
              [-39.943094605355157, -8.133463001488979],
              [-39.943094610437441, -8.13346290710674],
              [-39.943094616509285, -8.133462812782577],
              [-39.943094623569998, -8.133462718526792],
              [-39.943094631618798, -8.133462624349695],
              [-39.943094640654849, -8.133462530261566],
              [-39.943094650677118, -8.133462436272643],
              [-39.94309466168454, -8.133462342393226],
              [-39.943094673675901, -8.133462248633597],
              [-39.943094686649893, -8.133462155003931],
              [-39.943094700605094, -8.133462061514509],
              [-39.943094715540006, -8.133461968175489],
              [-39.943094731452966, -8.133461874997117],
              [-39.943094748342247, -8.133461781989496],
              [-39.943094766206002, -8.133461689162885],
              [-39.943094785042312, -8.133461596527344],
              [-39.943094804849061, -8.133461504093004],
              [-39.943094825624122, -8.133461411869977],
              [-39.943094847365224, -8.133461319868363],
              [-39.94309487006997, -8.133461228098152],
              [-39.943094893735932, -8.133461136569377],
              [-39.943094918360472, -8.133461045292064],
              [-39.943094943940935, -8.133460954276146],
              [-39.943094970474498, -8.133460863531633],
              [-39.943094997958283, -8.133460773068325],
              [-39.943095026389287, -8.133460682896194],
              [-39.94309505576441, -8.133460593025038],
              [-39.943095086080426, -8.133460503464706],
              [-39.943095117334032, -8.13346041422494],
              [-39.943095149521831, -8.133460325315536],
              [-39.943095182640278, -8.133460236746187],
              [-39.943095216685784, -8.133460148526524],
              [-39.943095251654611, -8.133460060666222],
              [-39.943095287542938, -8.133459973174894],
              [-39.943095324346857, -8.133459886062052],
              [-39.943095362062358, -8.133459799337238],
              [-39.943095400685294, -8.133459713009932],
              [-39.943095440211465, -8.133459627089524],
              [-39.943095480636572, -8.133459541585408],
              [-39.943095521956167, -8.133459456506911],
              [-39.943095564165745, -8.133459371863379],
              [-39.943095607260709, -8.133459287664014],
              [-39.943095651236355, -8.133459203918015],
              [-39.943095696087845, -8.133459120634537],
              [-39.943095741810325, -8.133459037822691],
              [-39.943095788398772, -8.133458955491481],
              [-39.943095835848112, -8.133458873649932],
              [-39.943095884153145, -8.133458792306975],
              [-39.943095933308641, -8.133458711471473],
              [-39.943095983309192, -8.133458631152287],
              [-39.943096034149328, -8.13345855135816],
              [-39.943096085823512, -8.133458472097825],
              [-39.943096138326126, -8.133458393379945],
              [-39.943096191651385, -8.133458315213083],
              [-39.943096245793505, -8.133458237605813],
              [-39.943096300746568, -8.133458160566571],
              [-39.943096356504554, -8.1334580841038],
              [-39.943096413061397, -8.133458008225864],
              [-39.943096470410879, -8.133457932941033],
              [-39.943096528546789, -8.133457858257508],
              [-39.943096587462755, -8.133457784183481],
              [-39.943096647152331, -8.133457710727029],
              [-39.94309670760903, -8.133457637896189],
              [-39.943096768826209, -8.133457565698857],
              [-39.943096830797188, -8.133457494142968],
              [-39.943096893515239, -8.133457423236344],
              [-39.943096956973463, -8.133457352986705],
              [-39.943097021164959, -8.133457283401707],
              [-39.943097086082723, -8.133457214488994],
              [-39.943097151719641, -8.133457146256067],
              [-39.943097218068544, -8.13345707871038],
              [-39.943097285122199, -8.133457011859338],
              [-39.943097352873728, -8.133456945709407],
              [-39.943097326322295, -8.133456891865828],
              [-39.945026032900536, -8.131593346589938],
              [-39.945026087192474, -8.131593387288271],
              [-39.945026108538819, -8.131593366730488],
              [-39.945026129952531, -8.131593346242425],
              [-39.945026151433353, -8.13159332582425],
              [-39.945026172981095, -8.131593305476192],
              [-39.945026194595528, -8.131593285198473],
              [-39.945026216276368, -8.131593264991324],
              [-39.945026238023431, -8.131593244854926],
              [-39.945026259836474, -8.131593224789521],
              [-39.945026281715265, -8.131593204795308],
              [-39.945026303659553, -8.131593184872532],
              [-39.945026325669126, -8.131593165021373],
              [-39.945026347743742, -8.131593145242064],
              [-39.945026369883166, -8.131593125534794],
              [-39.945026392087136, -8.131593105899812],
              [-39.945026414355461, -8.131593086337286],
              [-39.945026436687854, -8.131593066847438],
              [-39.945026459084112, -8.131593047430487],
              [-39.945026481543977, -8.131593028086622],
              [-39.945026504067222, -8.131593008816088],
              [-39.945026526653585, -8.131592989619048],
              [-39.945026549302852, -8.131592970495747],
              [-39.945026572014761, -8.131592951446359],
              [-39.945026594789063, -8.131592932471101],
              [-39.945026617625551, -8.131592913570175],
              [-39.945026640523942, -8.131592894743763],
              [-39.945026663484001, -8.1315928759921],
              [-39.945026686505493, -8.13159285731537],
              [-39.945026709588156, -8.131592838713754],
              [-39.945026732731755, -8.131592820187485],
              [-39.945026755936034, -8.131592801736748],
              [-39.945026779200759, -8.131592783361711],
              [-39.945026802525675, -8.131592765062631],
              [-39.94502682591051, -8.131592746839674],
              [-39.94502684935506, -8.131592728693027],
              [-39.945026872859032, -8.13159271062286],
              [-39.945026896422199, -8.131592692629422],
              [-39.945026920044285, -8.131592674712874],
              [-39.945026943725061, -8.131592656873408],
              [-39.945026967464266, -8.131592639111219],
              [-39.945026991261649, -8.131592621426503],
              [-39.945027015116942, -8.131592603819428],
              [-39.945027039029895, -8.131592586290227],
              [-39.945027063000275, -8.131592568839038],
              [-39.945027087027796, -8.131592551466067],
              [-39.945027111112218, -8.131592534171485],
              [-39.945027135253262, -8.131592516955509],
              [-39.945027159450696, -8.131592499818314],
              [-39.945027183704248, -8.131592482760082],
              [-39.945027208013656, -8.131592465780999],
              [-39.945027232378671, -8.131592448881234],
              [-39.945027256799044, -8.131592432060955],
              [-39.945027281274463, -8.131592415320348],
              [-39.945027305804715, -8.131592398659597],
              [-39.945027330389522, -8.131592382078917],
              [-39.945027355028607, -8.131592365578459],
              [-39.945027379721743, -8.131592349158371],
              [-39.945027404468611, -8.131592332818855],
              [-39.945027429269011, -8.13159231656009],
              [-39.945027454122624, -8.131592300382247],
              [-39.945027479029221, -8.131592284285501],
              [-39.945027503988499, -8.131592268270001],
              [-39.945027529000235, -8.131592252335944],
              [-39.945027554064119, -8.13159223648349],
              [-39.945027579179907, -8.131592220712808],
              [-39.945027604347324, -8.131592205024059],
              [-39.945027629566098, -8.131592189417441],
              [-39.945027654835975, -8.131592173893091],
              [-39.945027680156663, -8.131592158451195],
              [-39.945027705527892, -8.131592143091893],
              [-39.94502773094942, -8.13159212781539],
              [-39.945027756420949, -8.131592112621794],
              [-39.945027781942201, -8.131592097511319],
              [-39.945027807512922, -8.131592082484081],
              [-39.945027833132826, -8.131592067540291],
              [-39.94502785880163, -8.131592052680096],
              [-39.945027884519099, -8.13159203790361],
              [-39.945027910284921, -8.131592023211047],
              [-39.945027936098825, -8.131592008602519],
              [-39.945027961960548, -8.13159199407821],
              [-39.945027987869807, -8.131591979638278],
              [-39.94502801382631, -8.131591965282833],
              [-39.945028039829815, -8.131591951012107],
              [-39.945028065879995, -8.131591936826192],
              [-39.945028091976617, -8.131591922725258],
              [-39.945028118119374, -8.131591908709444],
              [-39.945028144308004, -8.131591894778932],
              [-39.945028170542216, -8.131591880933851],
              [-39.945028196821731, -8.131591867174354],
              [-39.945028223146281, -8.131591853500566],
              [-39.945028249515545, -8.131591839912646],
              [-39.945028275929275, -8.131591826410736],
              [-39.945028302387186, -8.131591812995016],
              [-39.945028328888988, -8.131591799665578],
              [-39.94502835543441, -8.131591786422597],
              [-39.945028382023139, -8.131591773266207],
              [-39.945028408654935, -8.131591760196558],
              [-39.945028435329462, -8.131591747213774],
              [-39.945028462046466, -8.131591734317999],
              [-39.945028488805562, -8.131591721510134],
              [-39.945028479664465, -8.131591712919541],
              [-39.946380872916627, -8.130947051403671],
              [-39.946380872257102, -8.130947093037708],
              [-39.946380896563987, -8.130947081495627],
              [-39.946380920905106, -8.130947070025433],
              [-39.946380945280289, -8.130947058627264],
              [-39.94638096968928, -8.130947047301188],
              [-39.946380994131886, -8.130947036047335],
              [-39.946381018607902, -8.130947024865774],
              [-39.946381043117071, -8.130947013756627],
              [-39.946381067659225, -8.130947002719996],
              [-39.94638109223412, -8.130946991755948],
              [-39.946381116841565, -8.130946980864616],
              [-39.946381141481297, -8.130946970046066],
              [-39.946381166153152, -8.13094695930039],
              [-39.946381190856883, -8.130946948627717],
              [-39.946381215592261, -8.130946938028101],
              [-39.946381240359095, -8.130946927501657],
              [-39.946381265157171, -8.130946917048476],
              [-39.946381289986242, -8.130946906668637],
              [-39.946381314846121, -8.130946896362261],
              [-39.946381339736561, -8.130946886129415],
              [-39.946381364657363, -8.130946875970187],
              [-39.946381389608298, -8.13094686588469],
              [-39.946381414589148, -8.130946855872995],
              [-39.946381439599676, -8.130946845935185],
              [-39.946381464639707, -8.130946836071331],
              [-39.946381489708983, -8.130946826281582],
              [-39.9463815148073, -8.13094681656594],
              [-39.946381539934421, -8.130946806924577],
              [-39.946381565090135, -8.130946797357538],
              [-39.946381590274228, -8.130946787864893],
              [-39.946381615486459, -8.130946778446727],
              [-39.946381640726621, -8.130946769103144],
              [-39.946381665994494, -8.130946759834208],
              [-39.946381691289851, -8.130946750640007],
              [-39.94638171661245, -8.130946741520656],
              [-39.946381741962107, -8.130946732476161],
              [-39.946381767338558, -8.130946723506671],
              [-39.946381792741619, -8.13094671461225],
              [-39.946381818171041, -8.13094670579294],
              [-39.946381843626611, -8.130946697048856],
              [-39.946381869108087, -8.13094668838006],
              [-39.946381894615278, -8.130946679786629],
              [-39.946381920147935, -8.130946671268642],
              [-39.946381945705824, -8.130946662826169],
              [-39.946381971288744, -8.130946654459295],
              [-39.946381996896463, -8.130946646168072],
              [-39.946382022528745, -8.130946637952583],
              [-39.946382048185392, -8.130946629812897],
              [-39.946382073866133, -8.130946621749096],
              [-39.946382099570776, -8.130946613761266],
              [-39.946382125299102, -8.130946605849461],
              [-39.946382151050848, -8.130946598013731],
              [-39.946382176825814, -8.130946590254174],
              [-39.946382202623774, -8.130946582570862],
              [-39.946382228444477, -8.130946574963829],
              [-39.946382254287734, -8.130946567433158],
              [-39.946382280153287, -8.130946559978931],
              [-39.94638230604091, -8.130946552601186],
              [-39.946382331950389, -8.130946545300009],
              [-39.946382357881497, -8.130946538075433],
              [-39.946382383834006, -8.130946530927559],
              [-39.946382409807669, -8.130946523856455],
              [-39.946382435802263, -8.130946516862153],
              [-39.946382461817571, -8.130946509944726],
              [-39.946382487853363, -8.130946503104255],
              [-39.946382513909406, -8.130946496340741],
              [-39.94638253998545, -8.130946489654303],
              [-39.946382566081304, -8.130946483044978],
              [-39.946382592196713, -8.130946476512825],
              [-39.946382618331462, -8.130946470057888],
              [-39.946382644485297, -8.130946463680264],
              [-39.946382670658032, -8.13094645737999],
              [-39.946382696849369, -8.130946451157095],
              [-39.946382723059138, -8.13094644501165],
              [-39.946382749287089, -8.130946438943727],
              [-39.946382775532982, -8.130946432953357],
              [-39.946382801796609, -8.130946427040586],
              [-39.946382828077709, -8.130946421205506],
              [-39.946382854376068, -8.130946415448131],
              [-39.946382880691431, -8.130946409768528],
              [-39.946382907023612, -8.130946404166734],
              [-39.946382933372355, -8.130946398642816],
              [-39.94638295973742, -8.13094639319679],
              [-39.946382986118586, -8.130946387828757],
              [-39.946383012515604, -8.130946382538726],
              [-39.946383038928275, -8.130946377326755],
              [-39.946383065356329, -8.130946372192895],
              [-39.946383091799554, -8.13094636713717],
              [-39.946383118257721, -8.130946362159641],
              [-39.946383144730589, -8.130946357260353],
              [-39.946383171217917, -8.130946352439347],
              [-39.946383197719477, -8.130946347696655],
              [-39.946383224235056, -8.130946343032319],
              [-39.946383250764399, -8.130946338446405],
              [-39.94638327730727, -8.130946333938949],
              [-39.946383303863449, -8.13094632950996],
              [-39.946383330432703, -8.130946325159499],
              [-39.946383357014774, -8.130946320887597],
              [-39.946383383609465, -8.130946316694285],
              [-39.946383410216455, -8.130946312579956],
              [-39.946383412552819, -8.130946321943991],
              [-39.948508029804437, -8.130620929352929],
              [-39.948508027865657, -8.13062095959609],
              [-39.948508118910084, -8.130620945181773],
              [-39.948508209802903, -8.130620929846717],
              [-39.948508300534726, -8.130620913592512],
              [-39.948508391096198, -8.130620896420833],
              [-39.948508481477965, -8.130620878333476],
              [-39.948508571670722, -8.130620859332272],
              [-39.948508661665151, -8.130620839419221],
              [-39.948508751451975, -8.130620818596343],
              [-39.948508841021919, -8.130620796865788],
              [-39.948508930365762, -8.130620774229818],
              [-39.948509019474272, -8.130620750690742],
              [-39.948509108338278, -8.130620726251024],
              [-39.948509196948571, -8.130620700913166],
              [-39.948509285296048, -8.130620674679749],
              [-39.948509373371579, -8.130620647553537],
              [-39.948509461166076, -8.130620619537281],
              [-39.948509548670486, -8.130620590633898],
              [-39.94850963587578, -8.130620560846365],
              [-39.948509722772961, -8.130620530177763],
              [-39.948509809353069, -8.130620498631249],
              [-39.948509895607167, -8.130620466210059],
              [-39.948509981526364, -8.130620432917565],
              [-39.9485100671018, -8.130620398757175],
              [-39.948510152324637, -8.130620363732437],
              [-39.948510237186063, -8.130620327846966],
              [-39.948510321677354, -8.130620291104425],
              [-39.948510405789797, -8.130620253508647],
              [-39.948510489514682, -8.130620215063489],
              [-39.948510572843404, -8.130620175772926],
              [-39.948510655767365, -8.13062013564101],
              [-39.948510738277982, -8.13062009467189],
              [-39.948510820366756, -8.130620052869773],
              [-39.948510902025234, -8.130620010238982],
              [-39.948510983244987, -8.130619966783941],
              [-39.948511064017609, -8.130619922509077],
              [-39.948511144334809, -8.130619877419004],
              [-39.948511224188273, -8.13061983151834],
              [-39.948511303569759, -8.130619784811874],
              [-39.948511382471111, -8.130619737304359],
              [-39.948511460884156, -8.130619689000724],
              [-39.948511538800815, -8.130619639905953],
              [-39.948511616213054, -8.130619590025116],
              [-39.948511693112877, -8.130619539363346],
              [-39.94851176949237, -8.130619487925872],
              [-39.948511845343624, -8.130619435718023],
              [-39.948511920658838, -8.130619382745147],
              [-39.948511995430245, -8.130619329012763],
              [-39.948512069650114, -8.130619274526346],
              [-39.948512143310793, -8.130619219291557],
              [-39.948512216404673, -8.130619163314083],
              [-39.948512288924256, -8.130619106599729],
              [-39.948512360862004, -8.130619049154321],
              [-39.948512432210535, -8.130618990983761],
              [-39.948512502962465, -8.130618932094073],
              [-39.948512573110527, -8.130618872491359],
              [-39.948512642647451, -8.130618812181716],
              [-39.948512711566067, -8.130618751171397],
              [-39.948512779859279, -8.130618689466717],
              [-39.948512847520043, -8.130618627073991],
              [-39.948512914541368, -8.130618563999706],
              [-39.948512980916334, -8.130618500250298],
              [-39.948513046638112, -8.130618435832439],
              [-39.948513111699903, -8.130618370752689],
              [-39.948513176095027, -8.130618305017828],
              [-39.948513239816805, -8.130618238634606],
              [-39.948513302858686, -8.130618171609846],
              [-39.948513365214147, -8.130618103950503],
              [-39.948513426876772, -8.130618035663552],
              [-39.948513487840188, -8.130617966756013],
              [-39.94851354809812, -8.130617897235043],
              [-39.94851360764433, -8.130617827107748],
              [-39.948513666472699, -8.13061775638139],
              [-39.948513724577147, -8.13061768506331],
              [-39.948513781951668, -8.130617613160801],
              [-39.948513838590372, -8.1306175406813],
              [-39.948513894487391, -8.130617467632291],
              [-39.948513949636968, -8.130617394021312],
              [-39.948514004033413, -8.130617319855963],
              [-39.948514057671098, -8.130617245143899],
              [-39.948514110544536, -8.130617169892819],
              [-39.948514162648223, -8.13061709411045],
              [-39.948514213976786, -8.130617017804633],
              [-39.94851426452496, -8.130616940983261],
              [-39.948514314287536, -8.130616863654305],
              [-39.948514363259335, -8.130616785825623],
              [-39.948514411435333, -8.130616707505292],
              [-39.948514458810557, -8.130616628701434],
              [-39.948514505380125, -8.130616549422147],
              [-39.948514551139226, -8.130616469675632],
              [-39.948514596083143, -8.130616389470088],
              [-39.948514640207243, -8.130616308813796],
              [-39.948514683506964, -8.130616227715073],
              [-39.94851472597783, -8.130616146182284],
              [-39.948514767615492, -8.130616064223862],
              [-39.948514808415624, -8.130615981848225],
              [-39.948514848374032, -8.130615899063921],
              [-39.948514887486581, -8.130615815879437],
              [-39.948514925749272, -8.130615732303406],
              [-39.948514963158104, -8.130615648344413],
              [-39.94851491537149, -8.130615667572847],
              [-39.949217468452872, -8.129017069688421],
              [-39.949217498193505, -8.129017059061285],
              [-39.949217538514404, -8.129016965893625],
              [-39.949217577783919, -8.129016872281113],
              [-39.949217615997107, -8.129016778235528],
              [-39.949217653149148, -8.129016683768665],
              [-39.949217689235375, -8.129016588892434],
              [-39.949217724251248, -8.129016493618769],
              [-39.949217758192354, -8.129016397959658],
              [-39.949217791054416, -8.129016301927162],
              [-39.949217822833319, -8.129016205533327],
              [-39.949217853525042, -8.129016108790328],
              [-39.949217883125726, -8.129016011710325],
              [-39.949217911631663, -8.129015914305535],
              [-39.949217939039244, -8.129015816588169],
              [-39.949217965345042, -8.129015718570576],
              [-39.949217990545719, -8.1290156202651],
              [-39.94921801463812, -8.129015521684096],
              [-39.949218037619218, -8.129015422839936],
              [-39.949218059486121, -8.129015323745156],
              [-39.949218080236051, -8.129015224412155],
              [-39.949218099866435, -8.129015124853415],
              [-39.949218118374766, -8.129015025081504],
              [-39.949218135758741, -8.129014925108974],
              [-39.949218152016165, -8.129014824948387],
              [-39.949218167144977, -8.129014724612372],
              [-39.949218181143294, -8.12901462411358],
              [-39.94921819400934, -8.129014523464585],
              [-39.949218205741509, -8.129014422678107],
              [-39.949218216338323, -8.129014321766858],
              [-39.949218225798447, -8.129014220743471],
              [-39.949218234120664, -8.129014119620669],
              [-39.949218241303953, -8.129014018411223],
              [-39.94921824734741, -8.12901391712785],
              [-39.949218252250276, -8.129013815783264],
              [-39.949218256011932, -8.129013714390284],
              [-39.949218258631888, -8.129013612961606],
              [-39.949218260109838, -8.129013511510022],
              [-39.949218260445583, -8.129013410048318],
              [-39.949218259639082, -8.129013308589231],
              [-39.949218257690426, -8.129013207145563],
              [-39.94921825459987, -8.129013105730035],
              [-39.949218250367821, -8.129013004355459],
              [-39.949218244994775, -8.129012903034578],
              [-39.94921823848145, -8.129012801780117],
              [-39.949218230828635, -8.129012700604838],
              [-39.949218222037302, -8.129012599521465],
              [-39.949218212108562, -8.129012498542753],
              [-39.949218201043657, -8.12901239768137],
              [-39.949218188843986, -8.129012296950041],
              [-39.949218175511071, -8.129012196361415],
              [-39.949218161046616, -8.129012095928184],
              [-39.949218145452413, -8.129011995662951],
              [-39.949218128730436, -8.129011895578362],
              [-39.949218110882804, -8.129011795687006],
              [-39.949218091911732, -8.129011696001434],
              [-39.949218071819629, -8.1290115965342],
              [-39.949218050609026, -8.12901149729786],
              [-39.94921802828258, -8.129011398304876],
              [-39.949218004843118, -8.12901129956769],
              [-39.949217980293561, -8.129011201098747],
              [-39.949217954637028, -8.129011102910436],
              [-39.949217927876731, -8.129011005015116],
              [-39.949217900016045, -8.129010907425092],
              [-39.949217871058487, -8.129010810152655],
              [-39.949217841007673, -8.129010713210061],
              [-39.949217809867406, -8.129010616609499],
              [-39.9492177776416, -8.129010520363117],
              [-39.949217744334305, -8.129010424483061],
              [-39.949217709949721, -8.129010328981376],
              [-39.949217674492182, -8.129010233870048],
              [-39.949217637966129, -8.129010139161096],
              [-39.94921760037618, -8.129010044866403],
              [-39.949217561727046, -8.129009950997849],
              [-39.949217522023616, -8.129009857567272],
              [-39.949217481270857, -8.129009764586392],
              [-39.949217439473927, -8.12900967206695],
              [-39.949217396638055, -8.129009580020565],
              [-39.94921735276867, -8.12900948845882],
              [-39.949217307871244, -8.129009397393254],
              [-39.949217261951482, -8.129009306835336],
              [-39.949217215015132, -8.12900921679643],
              [-39.949217167068092, -8.12900912728788],
              [-39.949217118116422, -8.129009038320945],
              [-39.949217068166263, -8.129008949906828],
              [-39.949217017223923, -8.129008862056651],
              [-39.949216965295768, -8.129008774781497],
              [-39.949216912388408, -8.129008688092304],
              [-39.949216858508422, -8.129008602000024],
              [-39.949216803662644, -8.129008516515489],
              [-39.94921674785796, -8.129008431649433],
              [-39.949216691101391, -8.129008347412547],
              [-39.949216633400084, -8.129008263815436],
              [-39.949216574761287, -8.129008180868606],
              [-39.949216515192383, -8.129008098582501],
              [-39.949216454700895, -8.129008016967509],
              [-39.9492163932944, -8.129007936033872],
              [-39.949216330980661, -8.129007855791791],
              [-39.949216267767497, -8.129007776251338],
              [-39.949216203662871, -8.12900769742253],
              [-39.949216138674849, -8.129007619315312],
              [-39.949216084706457, -8.129007576367581],
              [-39.948574968516205, -8.128245821003615],
              [-39.94857497488244, -8.128245808504765],
              [-39.948574918945326, -8.128245741382589],
              [-39.948574863660625, -8.128245673725836],
              [-39.948574809033445, -8.128245605540769],
              [-39.94857475506889, -8.128245536833756],
              [-39.948574701771975, -8.128245467611181],
              [-39.948574649147666, -8.128245397879482],
              [-39.94857459720086, -8.128245327645139],
              [-39.948574545936381, -8.128245256914711],
              [-39.948574495359026, -8.128245185694801],
              [-39.948574445473469, -8.128245113991992],
              [-39.948574396284371, -8.128245041812979],
              [-39.94857434779631, -8.128244969164459],
              [-39.948574300013782, -8.128244896053211],
              [-39.948574252941256, -8.128244822486042],
              [-39.94857420658311, -8.128244748469804],
              [-39.948574160943622, -8.128244674011354],
              [-39.948574116027103, -8.128244599117668],
              [-39.948574071837683, -8.128244523795663],
              [-39.948574028379483, -8.128244448052381],
              [-39.948573985656573, -8.128244371894867],
              [-39.948573943672891, -8.128244295330186],
              [-39.948573902432379, -8.128244218365495],
              [-39.948573861938826, -8.128244141007938],
              [-39.948573822196067, -8.128244063264736],
              [-39.948573783207749, -8.128243985143108],
              [-39.94857374497753, -8.12824390665032],
              [-39.948573707508949, -8.128243827793662],
              [-39.948573670805501, -8.128243748580505],
              [-39.948573634870606, -8.128243669018197],
              [-39.948573599707593, -8.128243589114165],
              [-39.948573565319755, -8.128243508875812],
              [-39.948573531710267, -8.128243428310643],
              [-39.94857349888229, -8.128243347426118],
              [-39.948573466838837, -8.128243266229804],
              [-39.948573435582929, -8.128243184729213],
              [-39.948573405117457, -8.128243102931965],
              [-39.94857337544525, -8.128243020845671],
              [-39.948573346569077, -8.128242938477955],
              [-39.94857331849164, -8.128242855836495],
              [-39.948573291215524, -8.12824277292896],
              [-39.948573264743303, -8.12824268976307],
              [-39.948573239077383, -8.128242606346594],
              [-39.948573214220204, -8.128242522687263],
              [-39.948573190174059, -8.128242438792888],
              [-39.948573166941209, -8.128242354671258],
              [-39.948573144523778, -8.128242270330208],
              [-39.948573122923889, -8.128242185777566],
              [-39.948573102143513, -8.128242101021227],
              [-39.948573082184616, -8.128242016069089],
              [-39.948573063049047, -8.128241930929025],
              [-39.948573044738581, -8.128241845608976],
              [-39.94857302725493, -8.128241760116881],
              [-39.948573010599709, -8.128241674460709],
              [-39.94857299477448, -8.128241588648404],
              [-39.948572979780714, -8.128241502687969],
              [-39.948572965619796, -8.128241416587384],
              [-39.948572952293055, -8.128241330354715],
              [-39.948572939801728, -8.128241243997929],
              [-39.948572928146994, -8.128241157525089],
              [-39.948572917329898, -8.128241070944227],
              [-39.948572907351462, -8.128240984263405],
              [-39.948572898212639, -8.128240897490731],
              [-39.94857288991426, -8.128240810634216],
              [-39.948572882457107, -8.128240723701959],
              [-39.948572875841862, -8.128240636702083],
              [-39.948572870069135, -8.128240549642644],
              [-39.948572865139489, -8.128240462531783],
              [-39.948572861053357, -8.128240375377574],
              [-39.948572857811129, -8.128240288188131],
              [-39.948572855413111, -8.128240200971595],
              [-39.948572853859524, -8.128240113736059],
              [-39.948572853150509, -8.12824002648966],
              [-39.948572853286137, -8.128239939240494],
              [-39.948572854266388, -8.128239851996709],
              [-39.948572856091175, -8.128239764766398],
              [-39.948572858760336, -8.128239677557684],
              [-39.948572862273608, -8.128239590378692],
              [-39.94857286663067, -8.12823950323752],
              [-39.948572871831118, -8.128239416142293],
              [-39.948572877874462, -8.128239329101115],
              [-39.948572884760154, -8.128239242122071],
              [-39.948572892487554, -8.128239155213262],
              [-39.948572901055911, -8.12823906838279],
              [-39.948572910464463, -8.128238981638692],
              [-39.948572920712323, -8.128238894989103],
              [-39.948572931798545, -8.128238808442052],
              [-39.948572943722056, -8.128238722005598],
              [-39.948572956481804, -8.128238635687786],
              [-39.948572970076555, -8.128238549496661],
              [-39.948572984505098, -8.128238463440244],
              [-39.948572999766029, -8.128238377526499],
              [-39.94857301585796, -8.128238291763495],
              [-39.9485730327794, -8.128238206159132],
              [-39.948573050528765, -8.128238120721434],
              [-39.948573069104377, -8.12823803545834],
              [-39.948573088504553, -8.128237950377779],
              [-39.948573108727466, -8.128237865487645],
              [-39.948573129771233, -8.128237780795887],
              [-39.948573151633788, -8.12823769631045],
              [-39.948573134170303, -8.128237685379839],
              [-39.948650951004829, -8.127943110703765],
              [-39.948650951011331, -8.127943085542269],
              [-39.948650957033415, -8.127943062604187],
              [-39.948650962995139, -8.127943039650475],
              [-39.94865096889643, -8.127943016681256],
              [-39.948650974737305, -8.127942993696697],
              [-39.948650980517684, -8.127942970696962],
              [-39.948650986237539, -8.127942947682206],
              [-39.948650991896827, -8.127942924652567],
              [-39.948650997495498, -8.127942901608261],
              [-39.948651003033532, -8.127942878549387],
              [-39.948651008510886, -8.127942855476139],
              [-39.948651013927524, -8.127942832388626],
              [-39.948651019283396, -8.127942809287084],
              [-39.948651024578488, -8.127942786171603],
              [-39.94865102981273, -8.127942763042366],
              [-39.948651034986128, -8.127942739899558],
              [-39.948651040098611, -8.127942716743297],
              [-39.948651045150172, -8.127942693573768],
              [-39.948651050140747, -8.127942670391105],
              [-39.948651055070322, -8.127942647195514],
              [-39.948651059938861, -8.12794262398711],
              [-39.948651064746315, -8.127942600766064],
              [-39.948651069492684, -8.127942577532538],
              [-39.948651074177903, -8.127942554286692],
              [-39.948651078801959, -8.12794253102869],
              [-39.948651083364801, -8.1279425077587],
              [-39.948651087866409, -8.12794248447686],
              [-39.948651092306775, -8.127942461183352],
              [-39.948651096685808, -8.127942437878312],
              [-39.948651101003556, -8.127942414561915],
              [-39.94865110525992, -8.127942391234315],
              [-39.948651109454921, -8.127942367895679],
              [-39.94865111358849, -8.127942344546167],
              [-39.948651117660624, -8.127942321185914],
              [-39.948651121671283, -8.127942297815125],
              [-39.948651125620451, -8.127942274433934],
              [-39.948651129508107, -8.127942251042507],
              [-39.948651133334202, -8.127942227641016],
              [-39.948651137098729, -8.127942204229594],
              [-39.948651140801637, -8.127942180808427],
              [-39.94865114444292, -8.127942157377687],
              [-39.948651148022556, -8.127942133937484],
              [-39.948651151540503, -8.127942110488009],
              [-39.94865115499676, -8.127942087029448],
              [-39.948651158391293, -8.127942063561935],
              [-39.948651161724072, -8.127942040085633],
              [-39.94865116499507, -8.127942016600681],
              [-39.948651168204279, -8.127941993107267],
              [-39.948651171351678, -8.127941969605576],
              [-39.948651174437231, -8.127941946095756],
              [-39.948651177460945, -8.127941922577929],
              [-39.948651180422743, -8.127941899052272],
              [-39.948651183322667, -8.12794187551898],
              [-39.94865118616066, -8.127941851978186],
              [-39.948651188936729, -8.127941828430053],
              [-39.948651191650825, -8.127941804874773],
              [-39.948651194302926, -8.127941781312462],
              [-39.94865119689306, -8.127941757743299],
              [-39.948651199421157, -8.12794173416748],
              [-39.948651201887245, -8.127941710585116],
              [-39.948651204291281, -8.127941686996394],
              [-39.948651206633222, -8.127941663401467],
              [-39.948651208913127, -8.127941639800516],
              [-39.948651211130908, -8.127941616193667],
              [-39.948651213286581, -8.127941592581111],
              [-39.948651215380131, -8.127941568963028],
              [-39.948651217411538, -8.127941545339548],
              [-39.948651219380793, -8.127941521710833],
              [-39.948651221287875, -8.12794149807706],
              [-39.948651223132785, -8.1279414744384],
              [-39.948651224915494, -8.127941450794992],
              [-39.948651226635995, -8.127941427147016],
              [-39.948651228294281, -8.127941403494603],
              [-39.948651229890338, -8.127941379837937],
              [-39.948651231424151, -8.127941356177189],
              [-39.948651232895706, -8.127941332512544],
              [-39.948651234305018, -8.127941308844118],
              [-39.948651235652051, -8.12794128517208],
              [-39.948651236936804, -8.127941261496616],
              [-39.948651238159258, -8.127941237817861],
              [-39.948651239319432, -8.127941214136001],
              [-39.948651240417284, -8.1279411904512],
              [-39.948651241452829, -8.127941166763616],
              [-39.948651242426067, -8.127941143073416],
              [-39.948651243336961, -8.127941119380733],
              [-39.948651244185513, -8.127941095685749],
              [-39.948651244971757, -8.127941071988641],
              [-39.948651245695636, -8.127941048289568],
              [-39.948651246357166, -8.127941024588674],
              [-39.948651246956345, -8.127941000886151],
              [-39.948651247493167, -8.127940977182126],
              [-39.948651247967632, -8.127940953476781],
              [-39.948651248379726, -8.127940929770267],
              [-39.948651248729448, -8.127940906062769],
              [-39.948651249016805, -8.127940882354459],
              [-39.948651249241799, -8.127940858645465],
              [-39.948651249404406, -8.127940834935965],
              [-39.948651249504643, -8.127940811226114],
              [-39.948651249542507, -8.127940787516094],
              [-39.948651249518001, -8.127940763806054],
              [-39.948651217763945, -8.127940758554033],
              [-39.948648403354127, -8.126742661848146],
              [-39.948648435164678, -8.126742691200681],
              [-39.94864843515937, -8.126742651256921],
              [-39.948648435331094, -8.126742611313537],
              [-39.948648435679836, -8.126742571371292],
              [-39.948648436205616, -8.126742531430958],
              [-39.948648436908385, -8.126742491493344],
              [-39.948648437788165, -8.126742451559192],
              [-39.948648438844899, -8.126742411629312],
              [-39.948648440078621, -8.126742371704477],
              [-39.948648441489262, -8.126742331785476],
              [-39.948648443076799, -8.126742291873059],
              [-39.948648444841226, -8.126742251968027],
              [-39.948648446782492, -8.126742212071139],
              [-39.948648448900549, -8.126742172183173],
              [-39.948648451195375, -8.126742132304935],
              [-39.94864845366692, -8.126742092437169],
              [-39.948648456315134, -8.126742052580665],
              [-39.948648459139953, -8.126742012736218],
              [-39.948648462141342, -8.126741972904592],
              [-39.948648465319238, -8.12674193308656],
              [-39.948648468673575, -8.126741893282926],
              [-39.948648472204283, -8.126741853494424],
              [-39.948648475911298, -8.126741813721857],
              [-39.948648479794542, -8.126741773965975],
              [-39.948648483853965, -8.126741734227592],
              [-39.948648488089447, -8.126741694507443],
              [-39.948648492500936, -8.126741654806326],
              [-39.948648497088328, -8.126741615125033],
              [-39.948648501851558, -8.126741575464283],
              [-39.948648506790505, -8.126741535824909],
              [-39.948648511905077, -8.126741496207645],
              [-39.948648517195195, -8.126741456613273],
              [-39.94864852266074, -8.126741417042551],
              [-39.948648528301597, -8.126741377496264],
              [-39.948648534117673, -8.12674133797521],
              [-39.948648540108842, -8.126741298480146],
              [-39.94864854627501, -8.126741259011814],
              [-39.948648552616035, -8.126741219571002],
              [-39.94864855913179, -8.126741180158509],
              [-39.948648565822161, -8.126741140775071],
              [-39.948648572687013, -8.12674110142145],
              [-39.948648579726218, -8.126741062098432],
              [-39.948648586939626, -8.126741022806771],
              [-39.948648594327096, -8.126740983547245],
              [-39.948648601888507, -8.1267409443206],
              [-39.948648609623682, -8.126740905127633],
              [-39.948648617532491, -8.126740865969085],
              [-39.948648625614773, -8.126740826845724],
              [-39.948648633870363, -8.126740787758298],
              [-39.948648642299105, -8.126740748707597],
              [-39.948648650900843, -8.126740709694378],
              [-39.948648659675392, -8.126740670719387],
              [-39.94864866862261, -8.126740631783399],
              [-39.948648677742298, -8.126740592887185],
              [-39.948648687034272, -8.126740554031455],
              [-39.948648696498367, -8.126740515217014],
              [-39.948648706134399, -8.126740476444597],
              [-39.94864871594217, -8.126740437714954],
              [-39.948648725921501, -8.126740399028863],
              [-39.94864873607218, -8.126740360387068],
              [-39.948648746394035, -8.126740321790322],
              [-39.948648756886833, -8.126740283239389],
              [-39.948648767550395, -8.126740244735007],
              [-39.948648778384495, -8.126740206277935],
              [-39.948648789388926, -8.126740167868901],
              [-39.948648800563475, -8.126740129508674],
              [-39.948648811907951, -8.126740091198005],
              [-39.94864882342209, -8.126740052937627],
              [-39.948648835105686, -8.126740014728322],
              [-39.948648846958534, -8.126739976570795],
              [-39.948648858980341, -8.126739938465807],
              [-39.948648871170931, -8.126739900414078],
              [-39.948648883530041, -8.126739862416388],
              [-39.948648896057435, -8.126739824473457],
              [-39.948648908752865, -8.126739786586032],
              [-39.948648921616105, -8.126739748754854],
              [-39.948648934646869, -8.126739710980662],
              [-39.948648947844916, -8.126739673264167],
              [-39.948648961210012, -8.126739635606128],
              [-39.948648974741872, -8.126739598007283],
              [-39.948648988440212, -8.126739560468359],
              [-39.948649002304819, -8.12673952299007],
              [-39.948649016335374, -8.126739485573161],
              [-39.948649030531634, -8.126739448218389],
              [-39.948649044893308, -8.126739410926435],
              [-39.94864905942012, -8.126739373698062],
              [-39.948649074111785, -8.126739336533976],
              [-39.948649088968018, -8.126739299434941],
              [-39.948649103988522, -8.126739262401609],
              [-39.948649119173012, -8.126739225434758],
              [-39.948649134521197, -8.126739188535094],
              [-39.948649150032757, -8.126739151703346],
              [-39.948649165707408, -8.12673911494022],
              [-39.948649181544845, -8.126739078246439],
              [-39.948649197544746, -8.126739041622697],
              [-39.948649213706823, -8.126739005069739],
              [-39.948649230030711, -8.126738968588267],
              [-39.948649246516155, -8.126738932179009],
              [-39.948649263162793, -8.126738895842642],
              [-39.948649279969914, -8.126738859581488],
              [-39.948649211279466, -8.126738861342179],
              [-39.949202246208102, -8.125552672610819],
              [-39.949202262413728, -8.125552666882227],
              [-39.949202346001499, -8.125552492480606],
              [-39.949202433309942, -8.125552319898695],
              [-39.94920252429916, -8.125552149215267],
              [-39.949202618927629, -8.125551980508286],
              [-39.949202717152119, -8.125551813854777],
              [-39.94920281892778, -8.125551649330903],
              [-39.94920292420813, -8.125551487011695],
              [-39.9492030329451, -8.125551326971342],
              [-39.949203145089044, -8.125551169282907],
              [-39.949203260588732, -8.125551014018406],
              [-39.949203379391427, -8.125550861248756],
              [-39.949203501442895, -8.125550711043681],
              [-39.949203626687357, -8.125550563471819],
              [-39.949203755067664, -8.125550418600513],
              [-39.949203886525183, -8.125550276495929],
              [-39.949204020999858, -8.125550137222989],
              [-39.949204158430312, -8.125550000845239],
              [-39.94920429875377, -8.125549867425011],
              [-39.949204441906147, -8.125549737023212],
              [-39.949204587822095, -8.125549609699403],
              [-39.949204736434965, -8.125549485511698],
              [-39.949204887676878, -8.125549364516838],
              [-39.949205041478805, -8.125549246770055],
              [-39.949205197770489, -8.125549132325135],
              [-39.949205356480554, -8.12554902123432],
              [-39.949205517536548, -8.125548913548361],
              [-39.949205680864885, -8.125548809316397],
              [-39.949205846391024, -8.125548708586077],
              [-39.949206014039348, -8.12554861140338],
              [-39.949206183733331, -8.125548517812646],
              [-39.949206355395454, -8.125548427856677],
              [-39.949206528947315, -8.125548341576481],
              [-39.949206704309681, -8.125548259011527],
              [-39.949206881402482, -8.12554818019948],
              [-39.949207060144836, -8.125548105176332],
              [-39.949207240455117, -8.125548033976335],
              [-39.949207422250993, -8.125547966632039],
              [-39.949207605449445, -8.125547903174175],
              [-39.94920778996682, -8.125547843631713],
              [-39.949207975718835, -8.125547788031849],
              [-39.949208162620693, -8.125547736399978],
              [-39.949208350587028, -8.125547688759685],
              [-39.949208539532002, -8.125547645132714],
              [-39.949208729369346, -8.125547605538976],
              [-39.949208920012367, -8.125547569996549],
              [-39.949209111374003, -8.125547538521689],
              [-39.949209303366885, -8.12554751112877],
              [-39.949209495903304, -8.12554748783028],
              [-39.949209688895365, -8.12554746863688],
              [-39.949209882254934, -8.125547453557317],
              [-39.949210075893689, -8.125547442598471],
              [-39.949210269723245, -8.12554743576537],
              [-39.949210463655071, -8.125547433061124],
              [-39.949210657600602, -8.125547434486965],
              [-39.949210851471278, -8.125547440042242],
              [-39.949211045178565, -8.12554744972439],
              [-39.949211238633993, -8.125547463529035],
              [-39.949211431749241, -8.125547481449862],
              [-39.949211624436117, -8.125547503478684],
              [-39.949211816606628, -8.125547529605438],
              [-39.949212008173021, -8.125547559818154],
              [-39.9492121990478, -8.125547594103114],
              [-39.949212389143824, -8.125547632444617],
              [-39.949212578374272, -8.125547674825137],
              [-39.949212766652742, -8.125547721225372],
              [-39.949212953893252, -8.125547771624097],
              [-39.949213140010286, -8.125547825998291],
              [-39.949213324918887, -8.125547884323144],
              [-39.949213508534548, -8.125547946572024],
              [-39.949213690773504, -8.125548012716497],
              [-39.94921387155248, -8.125548082726338],
              [-39.949214050788946, -8.125548156569595],
              [-39.949214228401033, -8.125548234212536],
              [-39.949214404307654, -8.125548315619758],
              [-39.949214578428474, -8.125548400754033],
              [-39.949214750683979, -8.125548489576495],
              [-39.949214920995516, -8.125548582046578],
              [-39.949215089285289, -8.125548678122046],
              [-39.949215255476481, -8.125548777759089],
              [-39.949215419493171, -8.125548880912111],
              [-39.949215581260468, -8.125548987534073],
              [-39.949215740704496, -8.125549097576254],
              [-39.949215897752467, -8.125549210988444],
              [-39.949216052332659, -8.125549327718796],
              [-39.949216204374459, -8.125549447714059],
              [-39.94921635380846, -8.125549570919395],
              [-39.949216500566422, -8.125549697278572],
              [-39.949216644581291, -8.125549826733854],
              [-39.949216785787357, -8.125549959226156],
              [-39.949216924120101, -8.125550094694965],
              [-39.949217059516357, -8.125550233078425],
              [-39.949217191914315, -8.125550374313361],
              [-39.949217321253506, -8.125550518335215],
              [-39.949217447474858, -8.12555066507829],
              [-39.949217570520737, -8.125550814475512],
              [-39.949217690334955, -8.125550966458706],
              [-39.949217806862791, -8.125551120958463],
              [-39.949217920051055, -8.125551277904211],
              [-39.949218029847827, -8.125551437224427],
              [-39.949218035787759, -8.125551460002004],
              [-39.950015251810463, -8.126735220598658],
              [-39.950015298376627, -8.12673521099838],
              [-39.950015328321946, -8.126735255747612],
              [-39.950015357999675, -8.126735300673515],
              [-39.95001538740879, -8.126735345774506],
              [-39.950015416548197, -8.126735391048967],
              [-39.950015445416931, -8.126735436495339],
              [-39.950015474013924, -8.126735482111956],
              [-39.950015502338175, -8.126735527897234],
              [-39.950015530388683, -8.126735573849558],
              [-39.950015558164473, -8.126735619967297],
              [-39.950015585664538, -8.126735666248821],
              [-39.95001561288791, -8.126735712692497],
              [-39.950015639833659, -8.126735759296675],
              [-39.950015666500775, -8.126735806059729],
              [-39.95001569288835, -8.126735852979987],
              [-39.950015718995466, -8.126735900055781],
              [-39.950015744821158, -8.126735947285452],
              [-39.950015770364551, -8.126735994667351],
              [-39.950015795624715, -8.126736042199774],
              [-39.950015820600768, -8.126736089881042],
              [-39.95001584529183, -8.12673613770947],
              [-39.950015869697012, -8.126736185683393],
              [-39.950015893815475, -8.126736233801081],
              [-39.95001591764634, -8.126736282060865],
              [-39.950015941188802, -8.126736330461007],
              [-39.950015964441974, -8.126736378999787],
              [-39.950015987405074, -8.126736427675528],
              [-39.950016010077285, -8.126736476486517],
              [-39.950016032457789, -8.126736525430987],
              [-39.950016054545813, -8.126736574507202],
              [-39.950016076340546, -8.126736623713452],
              [-39.950016097841278, -8.126736673047981],
              [-39.950016119047177, -8.126736722509058],
              [-39.950016139957548, -8.126736772094933],
              [-39.9500161605716, -8.126736821803835],
              [-39.950016180888646, -8.126736871634053],
              [-39.950016200907982, -8.126736921583765],
              [-39.950016220628846, -8.126736971651226],
              [-39.950016240050566, -8.12673702183468],
              [-39.950016259172465, -8.12673707213234],
              [-39.950016277993839, -8.126737122542449],
              [-39.950016296514072, -8.126737173063184],
              [-39.950016314732459, -8.126737223692793],
              [-39.950016332648367, -8.126737274429459],
              [-39.950016350261187, -8.126737325271378],
              [-39.950016367570257, -8.126737376216811],
              [-39.950016384575015, -8.12673742726391],
              [-39.950016401274816, -8.126737478410861],
              [-39.950016417669083, -8.126737529655884],
              [-39.950016433757234, -8.126737580997135],
              [-39.950016449538715, -8.126737632432821],
              [-39.950016465012958, -8.126737683961121],
              [-39.950016480179407, -8.126737735580219],
              [-39.950016495037545, -8.126737787288294],
              [-39.950016509586838, -8.126737839083496],
              [-39.95001652382674, -8.126737890964003],
              [-39.950016537756802, -8.126737942927972],
              [-39.950016551376493, -8.126737994973572],
              [-39.950016564685349, -8.126738047098979],
              [-39.950016577682881, -8.126738099302337],
              [-39.950016590368662, -8.126738151581792],
              [-39.950016602742203, -8.126738203935474],
              [-39.950016614803111, -8.126738256361572],
              [-39.950016626550912, -8.126738308858231],
              [-39.950016637985222, -8.126738361423573],
              [-39.950016649105635, -8.126738414055744],
              [-39.950016659911746, -8.126738466752881],
              [-39.950016670403173, -8.126738519513118],
              [-39.950016680579559, -8.126738572334601],
              [-39.950016690440528, -8.126738625215447],
              [-39.950016699985738, -8.126738678153821],
              [-39.950016709214864, -8.1267387311478],
              [-39.95001671812755, -8.126738784195535],
              [-39.950016726723504, -8.126738837295173],
              [-39.950016735002428, -8.126738890444791],
              [-39.950016742964017, -8.126738943642524],
              [-39.950016750607965, -8.126738996886498],
              [-39.950016757934051, -8.126739050174832],
              [-39.950016764941985, -8.126739103505638],
              [-39.950016771631518, -8.126739156877031],
              [-39.950016778002428, -8.126739210287118],
              [-39.950016784054469, -8.12673926373402],
              [-39.950016789787462, -8.126739317215847],
              [-39.950016795201179, -8.126739370730698],
              [-39.950016800295423, -8.126739424276726],
              [-39.950016805070021, -8.126739477851947],
              [-39.950016809524818, -8.126739531454536],
              [-39.950016813659637, -8.126739585082568],
              [-39.950016817474335, -8.126739638734154],
              [-39.950016820968798, -8.126739692407414],
              [-39.950016824142871, -8.126739746100426],
              [-39.95001682699646, -8.126739799811304],
              [-39.95001682952946, -8.126739853538121],
              [-39.950016831741785, -8.126739907279015],
              [-39.950016833633349, -8.126739961032081],
              [-39.950016835204096, -8.126740014795415],
              [-39.950016836453955, -8.126740068567061],
              [-39.950016837382904, -8.126740122345186],
              [-39.950016837990887, -8.126740176127884],
              [-39.950016838278003, -8.12674022991305],
              [-39.950016806445717, -8.126740191350686],
              [-39.950018304029328, -8.127376818471731],
              [-39.950018335843914, -8.127376849528149],
              [-39.950018335946723, -8.12737687631148],
              [-39.950018336129119, -8.127376903094353],
              [-39.950018336391125, -8.127376929876583],
              [-39.950018336732697, -8.127376956657903],
              [-39.950018337153864, -8.127376983438115],
              [-39.950018337654619, -8.127377010216959],
              [-39.950018338234948, -8.127377036994213],
              [-39.950018338894857, -8.127377063769622],
              [-39.950018339634333, -8.127377090542987],
              [-39.950018340453354, -8.12737711731403],
              [-39.950018341351949, -8.12737714408256],
              [-39.950018342330083, -8.127377170848311],
              [-39.950018343387754, -8.127377197611063],
              [-39.950018344524949, -8.127377224370564],
              [-39.950018345741675, -8.127377251126608],
              [-39.950018347037897, -8.127377277878928],
              [-39.950018348413614, -8.127377304627309],
              [-39.95001834986882, -8.12737733137153],
              [-39.9500183514035, -8.127377358111334],
              [-39.950018353017633, -8.127377384846486],
              [-39.950018354711212, -8.127377411576756],
              [-39.950018356484222, -8.127377438301934],
              [-39.950018358336635, -8.127377465021763],
              [-39.950018360268473, -8.12737749173599],
              [-39.950018362279657, -8.127377518444407],
              [-39.950018364370237, -8.127377545146762],
              [-39.950018366540135, -8.127377571842834],
              [-39.950018368789365, -8.127377598532396],
              [-39.950018371117899, -8.127377625215175],
              [-39.950018373525729, -8.127377651890981],
              [-39.950018376012807, -8.127377678559574],
              [-39.950018378579145, -8.127377705220692],
              [-39.950018381224687, -8.127377731874121],
              [-39.950018383949434, -8.127377758519634],
              [-39.950018386753356, -8.127377785156982],
              [-39.950018389636412, -8.127377811785951],
              [-39.950018392598615, -8.127377838406284],
              [-39.950018395639887, -8.127377865017774],
              [-39.950018398760257, -8.127377891620162],
              [-39.950018401959646, -8.127377918213238],
              [-39.950018405238055, -8.127377944796745],
              [-39.950018408595454, -8.127377971370485],
              [-39.950018412031817, -8.127377997934165],
              [-39.950018415547099, -8.127378024487586],
              [-39.950018419141273, -8.127378051030533],
              [-39.950018422814317, -8.127378077562746],
              [-39.950018426566196, -8.12737810408405],
              [-39.950018430396867, -8.12737813059411],
              [-39.950018434306301, -8.127378157092791],
              [-39.950018438294457, -8.127378183579797],
              [-39.950018442361326, -8.127378210054905],
              [-39.950018446506853, -8.127378236517925],
              [-39.950018450731022, -8.127378262968596],
              [-39.950018455033735, -8.127378289406652],
              [-39.950018459415027, -8.127378315831907],
              [-39.950018463874819, -8.127378342244125],
              [-39.950018468413099, -8.127378368643054],
              [-39.9500184730298, -8.127378395028488],
              [-39.950018477724882, -8.127378421400181],
              [-39.950018482498336, -8.127378447757925],
              [-39.950018487350071, -8.127378474101436],
              [-39.950018492280094, -8.12737850043051],
              [-39.950018497288333, -8.127378526744936],
              [-39.950018502374739, -8.127378553044455],
              [-39.950018507539305, -8.127378579328843],
              [-39.950018512781938, -8.127378605597908],
              [-39.950018518102624, -8.127378631851379],
              [-39.950018523501299, -8.127378658089031],
              [-39.950018528977928, -8.12737868431061],
              [-39.950018534532461, -8.127378710515925],
              [-39.950018540164841, -8.127378736704749],
              [-39.950018545875025, -8.127378762876832],
              [-39.950018551662964, -8.127378789031956],
              [-39.950018557528601, -8.127378815169868],
              [-39.950018563471886, -8.12737884129036],
              [-39.950018569492762, -8.127378867393221],
              [-39.950018575591201, -8.127378893478179],
              [-39.950018581767118, -8.127378919545011],
              [-39.950018588020491, -8.127378945593522],
              [-39.950018594351235, -8.127378971623454],
              [-39.9500186007593, -8.127378997634608],
              [-39.950018607244651, -8.127379023626732],
              [-39.950018613807202, -8.127379049599583],
              [-39.950018620446926, -8.12737907555297],
              [-39.950018627163743, -8.127379101486671],
              [-39.95001863395759, -8.127379127400424],
              [-39.950018640828418, -8.127379153294006],
              [-39.950018647776176, -8.12737917916721],
              [-39.950018654800786, -8.127379205019782],
              [-39.950018661902206, -8.127379230851517],
              [-39.95001866908035, -8.127379256662186],
              [-39.950018676335162, -8.127379282451551],
              [-39.950018683666585, -8.12737930821941],
              [-39.950018691074554, -8.127379333965511],
              [-39.950018698559006, -8.12737935968965],
              [-39.950018706119863, -8.127379385391578],
              [-39.950018713757082, -8.12737941107107],
              [-39.95001872147057, -8.127379436727928],
              [-39.950018729260272, -8.127379462361901],
              [-39.950018673243406, -8.127379440465868],
              [-39.950224562977667, -8.128053304883018],
              [-39.950832700510517, -8.128877449890755],
              [-39.950832687526493, -8.128877467949401],
              [-39.950832718685632, -8.12887751043796],
              [-39.950832749596167, -8.128877553106479],
              [-39.950832780257016, -8.128877595953481],
              [-39.950832810667166, -8.128877638977569],
              [-39.950832840825576, -8.128877682177208],
              [-39.950832870731247, -8.128877725550987],
              [-39.950832900383148, -8.128877769097452],
              [-39.950832929780269, -8.128877812815089],
              [-39.950832958921637, -8.128877856702434],
              [-39.950832987806244, -8.128877900758017],
              [-39.950833016433123, -8.128877944980323],
              [-39.950833044801307, -8.128877989367835],
              [-39.950833072909823, -8.128878033919115],
              [-39.950833100757734, -8.128878078632594],
              [-39.950833128344087, -8.12887812350681],
              [-39.950833155667944, -8.128878168540181],
              [-39.950833182728395, -8.128878213731221],
              [-39.950833209524518, -8.128878259078409],
              [-39.950833236055395, -8.128878304580192],
              [-39.95083326232016, -8.128878350235022],
              [-39.950833288317881, -8.128878396041387],
              [-39.950833314047706, -8.128878441997703],
              [-39.95083333950874, -8.128878488102437],
              [-39.950833364700152, -8.128878534354032],
              [-39.950833389621089, -8.12887858075085],
              [-39.950833414270662, -8.12887862729141],
              [-39.950833438648068, -8.128878673974105],
              [-39.950833462752492, -8.128878720797356],
              [-39.950833486583107, -8.128878767759526],
              [-39.950833510139105, -8.128878814859108],
              [-39.95083353341969, -8.128878862094497],
              [-39.950833556424058, -8.128878909464056],
              [-39.95083357915145, -8.128878956966188],
              [-39.950833601601104, -8.128879004599321],
              [-39.950833623772233, -8.128879052361782],
              [-39.950833645664119, -8.128879100251986],
              [-39.950833667275987, -8.128879148268329],
              [-39.950833688607133, -8.128879196409146],
              [-39.950833709656798, -8.128879244672865],
              [-39.950833730424314, -8.128879293057741],
              [-39.950833750908956, -8.128879341562266],
              [-39.950833771110034, -8.128879390184752],
              [-39.950833791026852, -8.128879438923507],
              [-39.950833810658757, -8.128879487776924],
              [-39.950833830005067, -8.128879536743357],
              [-39.950833849065141, -8.128879585821155],
              [-39.95083386783832, -8.128879635008589],
              [-39.950833886323963, -8.128879684304055],
              [-39.950833904521474, -8.128879733705871],
              [-39.95083392243022, -8.128879783212374],
              [-39.950833940049584, -8.128879832821879],
              [-39.950833957378983, -8.128879882532699],
              [-39.950833974417826, -8.12887993234312],
              [-39.950833991165538, -8.128879982251528],
              [-39.950834007621552, -8.128880032256186],
              [-39.950834023785305, -8.128880082355414],
              [-39.950834039656243, -8.128880132547488],
              [-39.950834055233862, -8.128880182830734],
              [-39.950834070517587, -8.12888023320348],
              [-39.950834085506955, -8.128880283663973],
              [-39.950834100201405, -8.128880334210537],
              [-39.950834114600475, -8.128880384841466],
              [-39.950834128703661, -8.128880435554979],
              [-39.950834142510494, -8.128880486349439],
              [-39.950834156020498, -8.128880537223095],
              [-39.950834169233232, -8.128880588174182],
              [-39.950834182148228, -8.128880639201034],
              [-39.950834194765065, -8.128880690301914],
              [-39.950834207083297, -8.128880741475072],
              [-39.950834219102532, -8.128880792718777],
              [-39.950834230822345, -8.128880844031338],
              [-39.950834242242351, -8.128880895410944],
              [-39.950834253362153, -8.128880946855908],
              [-39.950834264181388, -8.128880998364494],
              [-39.950834274699666, -8.128881049934938],
              [-39.950834284916652, -8.128881101565449],
              [-39.950834294831992, -8.128881153254371],
              [-39.950834304445337, -8.128881204999887],
              [-39.950834313756403, -8.128881256800277],
              [-39.950834322764827, -8.12888130865378],
              [-39.95083433147034, -8.1288813605586],
              [-39.950834339872614, -8.128881412513067],
              [-39.950834347971401, -8.128881464515365],
              [-39.950834355766396, -8.12888151656372],
              [-39.950834363257364, -8.128881568656404],
              [-39.950834370444021, -8.128881620791642],
              [-39.950834377326125, -8.128881672967657],
              [-39.950834383903477, -8.128881725182707],
              [-39.950834390175821, -8.128881777434977],
              [-39.950834396142966, -8.128881829722781],
              [-39.950834401804691, -8.128881882044251],
              [-39.950834407160833, -8.128881934397688],
              [-39.950834412211179, -8.128881986781286],
              [-39.950834416955558, -8.128882039193261],
              [-39.950834421393829, -8.128882091631882],
              [-39.950834425525841, -8.12888214409532],
              [-39.95083442935146, -8.128882196581865],
              [-39.950834432870529, -8.128882249089683],
              [-39.95083443608263, -8.12888230161801],
              [-39.950834436476377, -8.128882329769747],
              [-39.950901406107562, -8.130033064152911],
              [-39.950901441884426, -8.130033062380518],
              [-39.950901445265849, -8.130033124099388],
              [-39.950901448223924, -8.130033185839832],
              [-39.950901450758487, -8.130033247598991],
              [-39.950901452869431, -8.13003330937396],
              [-39.950901454556664, -8.13003337116187],
              [-39.950901455820102, -8.130033432959831],
              [-39.950901456659679, -8.13003349476495],
              [-39.950901457075368, -8.130033556574329],
              [-39.950901457067125, -8.130033618385122],
              [-39.950901456634966, -8.130033680194378],
              [-39.950901455778933, -8.130033741999288],
              [-39.950901454499039, -8.130033803796914],
              [-39.950901452795328, -8.130033865584362],
              [-39.950901450667928, -8.130033927358788],
              [-39.950901448116909, -8.130033989117258],
              [-39.950901445142392, -8.130034050856922],
              [-39.950901441744506, -8.130034112574883],
              [-39.950901437923434, -8.13003417426828],
              [-39.950901433679334, -8.130034235934197],
              [-39.950901429012418, -8.130034297569782],
              [-39.950901423922893, -8.130034359172129],
              [-39.950901418411007, -8.130034420738379],
              [-39.950901412477002, -8.130034482265678],
              [-39.950901406121183, -8.130034543751105],
              [-39.950901399343799, -8.130034605191815],
              [-39.950901392145212, -8.130034666584944],
              [-39.95090138452575, -8.130034727927606],
              [-39.95090137648576, -8.13003478921696],
              [-39.950901368025619, -8.130034850450103],
              [-39.950901359145696, -8.130034911624216],
              [-39.950901349846468, -8.130034972736423],
              [-39.950901340128326, -8.13003503378388],
              [-39.950901329991716, -8.130035094763732],
              [-39.950901319437143, -8.130035155673133],
              [-39.95090130846507, -8.130035216509222],
              [-39.95090129707603, -8.13003527726916],
              [-39.950901285270554, -8.130035337950138],
              [-39.950901273049162, -8.130035398549287],
              [-39.950901260412486, -8.130035459063814],
              [-39.95090124736106, -8.13003551949085],
              [-39.950901233895515, -8.130035579827597],
              [-39.950901220016483, -8.130035640071224],
              [-39.950901205724605, -8.130035700218938],
              [-39.950901191020549, -8.130035760267898],
              [-39.95090117590501, -8.13003582021533],
              [-39.950901160378685, -8.130035880058433],
              [-39.950901144442305, -8.130035939794411],
              [-39.950901128096611, -8.130035999420443],
              [-39.950901111342368, -8.130036058933756],
              [-39.95090109418036, -8.130036118331594],
              [-39.95090107661138, -8.130036177611183],
              [-39.950901058636255, -8.130036236769719],
              [-39.950901040255836, -8.130036295804459],
              [-39.950901021470969, -8.130036354712647],
              [-39.950901002282521, -8.130036413491526],
              [-39.950900982691408, -8.130036472138363],
              [-39.950900962698526, -8.130036530650409],
              [-39.950900942304834, -8.130036589024931],
              [-39.950900921511263, -8.130036647259219],
              [-39.950900900318793, -8.130036705350534],
              [-39.950900878728412, -8.130036763296154],
              [-39.950900856741136, -8.130036821093393],
              [-39.950900834357967, -8.130036878739551],
              [-39.950900811579977, -8.130036936231919],
              [-39.950900788408227, -8.130036993567844],
              [-39.950900764843787, -8.130037050744615],
              [-39.950900740887754, -8.130037107759566],
              [-39.950900716541277, -8.130037164610052],
              [-39.950900691805458, -8.130037221293389],
              [-39.95090066668147, -8.130037277806965],
              [-39.950900641170499, -8.130037334148115],
              [-39.950900615273703, -8.13003739031422],
              [-39.950900588992312, -8.130037446302641],
              [-39.95090056232754, -8.130037502110778],
              [-39.950900535280674, -8.130037557736014],
              [-39.950900507852921, -8.130037613175768],
              [-39.950900480045604, -8.130037668427425],
              [-39.950900451859987, -8.130037723488432],
              [-39.950900423297419, -8.130037778356195],
              [-39.950900394359216, -8.130037833028176],
              [-39.950900365046742, -8.13003788750181],
              [-39.950900335361354, -8.130037941774527],
              [-39.95090030530443, -8.130037995843821],
              [-39.950900274877398, -8.130038049707153],
              [-39.950900244081673, -8.13003810336202],
              [-39.950900212918675, -8.130038156805902],
              [-39.950900181389883, -8.130038210036339],
              [-39.950900149496761, -8.13003826305078],
              [-39.950900117240792, -8.130038315846802],
              [-39.950900084623484, -8.13003836842192],
              [-39.95090005164635, -8.130038420773669],
              [-39.950900018310961, -8.130038472899624],
              [-39.950899984618857, -8.130038524797357],
              [-39.95089995057161, -8.130038576464417],
              [-39.950899916170798, -8.130038627898408],
              [-39.950899881418046, -8.130038679096902],
              [-39.950899846314989, -8.130038730057541],
              [-39.950899810863241, -8.130038780777923],
              [-39.950899775064471, -8.130038831255789],
              [-39.950899786033659, -8.130038856313172],
              [-39.949936278710489, -8.131387593871537],
              [-39.949936253256546, -8.131387628927582],
              [-39.949936246289397, -8.131387638580984],
              [-39.949936239309437, -8.131387648225202],
              [-39.949936232316681, -8.131387657860213],
              [-39.949936225311141, -8.13138766748599],
              [-39.949936218292848, -8.131387677102516],
              [-39.949936211261786, -8.131387686709807],
              [-39.949936204217977, -8.131387696307794],
              [-39.949936197161428, -8.131387705896527],
              [-39.949936190092174, -8.131387715475926],
              [-39.949936183010195, -8.131387725046],
              [-39.949936175915532, -8.131387734606742],
              [-39.949936168808186, -8.131387744158113],
              [-39.949936161688157, -8.13138775370011],
              [-39.949936154555481, -8.131387763232725],
              [-39.949936147410149, -8.131387772755916],
              [-39.949936140252191, -8.1313877822697],
              [-39.949936133081614, -8.13138779177403],
              [-39.949936125898411, -8.131387801268907],
              [-39.949936118702638, -8.131387810754312],
              [-39.94993611149426, -8.131387820230209],
              [-39.949936104273313, -8.131387829696603],
              [-39.94993609703981, -8.131387839153474],
              [-39.949936089793759, -8.131387848600806],
              [-39.949936082535196, -8.131387858038581],
              [-39.949936075264105, -8.131387867466763],
              [-39.949936067980495, -8.131387876885377],
              [-39.949936060684387, -8.131387886294382],
              [-39.949936053375808, -8.131387895693759],
              [-39.949936046054745, -8.131387905083505],
              [-39.949936038721241, -8.131387914463568],
              [-39.94993603137528, -8.131387923833978],
              [-39.9499360240169, -8.131387933194679],
              [-39.949936016646106, -8.131387942545704],
              [-39.949936009262892, -8.131387951886985],
              [-39.949936001867293, -8.131387961218532],
              [-39.949935994459317, -8.131387970540329],
              [-39.949935987038963, -8.13138797985234],
              [-39.949935979606266, -8.131387989154588],
              [-39.949935972161228, -8.131387998447011],
              [-39.949935964703869, -8.131388007729605],
              [-39.949935957234189, -8.131388017002374],
              [-39.949935949752202, -8.131388026265306],
              [-39.949935942257937, -8.131388035518354],
              [-39.949935934751387, -8.131388044761511],
              [-39.949935927232588, -8.131388053994772],
              [-39.949935919701531, -8.131388063218107],
              [-39.949935912158239, -8.131388072431514],
              [-39.949935904602725, -8.131388081634977],
              [-39.949935897035004, -8.131388090828461],
              [-39.949935889455091, -8.131388100011964],
              [-39.949935881862991, -8.131388109185478],
              [-39.949935874258721, -8.13138811834896],
              [-39.949935866642292, -8.131388127502433],
              [-39.949935859013728, -8.131388136645873],
              [-39.949935851373041, -8.131388145779233],
              [-39.949935843720212, -8.131388154902519],
              [-39.949935836055303, -8.131388164015704],
              [-39.949935828378294, -8.131388173118776],
              [-39.949935820689213, -8.131388182211719],
              [-39.949935812988073, -8.131388191294548],
              [-39.949935805274876, -8.1313882003672],
              [-39.949935797549649, -8.131388209429684],
              [-39.949935789812393, -8.131388218481971],
              [-39.949935782063143, -8.131388227524061],
              [-39.949935774301899, -8.131388236555917],
              [-39.949935766528661, -8.131388245577547],
              [-39.949935758743464, -8.131388254588909],
              [-39.949935750946302, -8.131388263590003],
              [-39.949935743137196, -8.131388272580836],
              [-39.949935735316181, -8.131388281561353],
              [-39.949935727483243, -8.131388290531554],
              [-39.949935719638418, -8.13138829949142],
              [-39.949935711781698, -8.131388308440956],
              [-39.949935703913106, -8.131388317380109],
              [-39.949935696032647, -8.131388326308889],
              [-39.949935688140364, -8.131388335227275],
              [-39.949935680236237, -8.131388344135278],
              [-39.949935672320301, -8.131388353032827],
              [-39.949935664392555, -8.131388361919944],
              [-39.949935656453029, -8.131388370796591],
              [-39.949935648501722, -8.131388379662774],
              [-39.949935640538648, -8.131388388518452],
              [-39.949935632563843, -8.131388397363647],
              [-39.949935624577286, -8.131388406198326],
              [-39.949935616579019, -8.131388415022485],
              [-39.949935608569056, -8.131388423836057],
              [-39.949935600547377, -8.131388432639078],
              [-39.949935592514045, -8.131388441431529],
              [-39.949935584469031, -8.131388450213375],
              [-39.949935576412379, -8.13138845898464],
              [-39.949935568344088, -8.13138846774525],
              [-39.949935560264187, -8.131388476495221],
              [-39.949935552172668, -8.131388485234524],
              [-39.949935544069561, -8.13138849396317],
              [-39.949935535954879, -8.131388502681135],
              [-39.949935527828629, -8.131388511388392],
              [-39.949935519690825, -8.131388520084927],
              [-39.949935511541483, -8.131388528770726],
              [-39.949935503380665, -8.13138853744486],
              [-39.949935438528122, -8.131388535264726],
              [-39.948907307842418, -8.132480417095739],
              [-39.948907361041698, -8.132480436684263],
              [-39.948907313815035, -8.132480487224662],
              [-39.948907266977002, -8.132480538122918],
              [-39.948907220530351, -8.132480589376067],
              [-39.948907174477775, -8.132480640981127],
              [-39.948907128821951, -8.13248069293506],
              [-39.948907083565558, -8.13248074523487],
              [-39.948907038711219, -8.132480797877477],
              [-39.948906994261584, -8.132480850859839],
              [-39.94890695021919, -8.132480904178852],
              [-39.948906906586643, -8.132480957831422],
              [-39.948906863366496, -8.132481011814397],
              [-39.948906820561213, -8.132481066124654],
              [-39.948906778173352, -8.132481120759021],
              [-39.948906736205345, -8.13248117571429],
              [-39.948906694659669, -8.132481230987302],
              [-39.948906653538735, -8.132481286574793],
              [-39.948906612844915, -8.132481342473543],
              [-39.948906572580604, -8.132481398680287],
              [-39.948906532748168, -8.132481455191755],
              [-39.948906493349888, -8.132481512004611],
              [-39.948906454388087, -8.132481569115591],
              [-39.948906415865018, -8.132481626521368],
              [-39.948906377782976, -8.132481684218584],
              [-39.94890634014412, -8.132481742203861],
              [-39.948906302950675, -8.132481800473833],
              [-39.948906266204801, -8.132481859025088],
              [-39.948906229908637, -8.13248191785423],
              [-39.948906194064314, -8.132481976957793],
              [-39.948906158673921, -8.132482036332378],
              [-39.948906123739498, -8.132482095974497],
              [-39.948906089263097, -8.132482155880689],
              [-39.948906055246745, -8.13248221604745],
              [-39.948906021692395, -8.132482276471285],
              [-39.948905988602029, -8.13248233714863],
              [-39.948905955977544, -8.13248239807602],
              [-39.948905923820881, -8.132482459249825],
              [-39.948905892133887, -8.132482520666532],
              [-39.948905860918423, -8.132482582322536],
              [-39.948905830176301, -8.132482644214262],
              [-39.948905799909319, -8.132482706338079],
              [-39.948905770119239, -8.132482768690382],
              [-39.948905740807795, -8.132482831267529],
              [-39.948905711976685, -8.132482894065873],
              [-39.948905683627629, -8.132482957081743],
              [-39.948905655762246, -8.132483020311472],
              [-39.948905628382164, -8.132483083751373],
              [-39.948905601488988, -8.132483147397751],
              [-39.948905575084282, -8.132483211246917],
              [-39.948905549169588, -8.132483275295101],
              [-39.948905523746411, -8.132483339538608],
              [-39.948905498816245, -8.132483403973643],
              [-39.948905474380531, -8.132483468596492],
              [-39.948905450440691, -8.132483533403407],
              [-39.948905426998124, -8.132483598390547],
              [-39.948905404054209, -8.132483663554179],
              [-39.948905381610274, -8.132483728890486],
              [-39.948905359667627, -8.132483794395636],
              [-39.948905338227561, -8.132483860065854],
              [-39.948905317291292, -8.132483925897287],
              [-39.948905296860076, -8.132483991886104],
              [-39.948905276935093, -8.132484058028437],
              [-39.948905257517495, -8.132484124320442],
              [-39.948905238608425, -8.132484190758246],
              [-39.948905220208985, -8.132484257338005],
              [-39.948905202320226, -8.132484324055802],
              [-39.948905184943222, -8.132484390907766],
              [-39.94890516807898, -8.132484457890023],
              [-39.948905151728475, -8.132484524998617],
              [-39.948905135892659, -8.132484592229664],
              [-39.948905120572469, -8.13248465957922],
              [-39.948905105768766, -8.132484727043385],
              [-39.948905091482452, -8.13248479461822],
              [-39.948905077714329, -8.13248486229978],
              [-39.948905064465215, -8.132484930084134],
              [-39.948905051735885, -8.13248499796733],
              [-39.948905039527084, -8.132485065945398],
              [-39.948905027839501, -8.132485134014365],
              [-39.948905016673848, -8.132485202170287],
              [-39.948905006030749, -8.132485270409152],
              [-39.948904995910844, -8.132485338727021],
              [-39.948904986314709, -8.132485407119892],
              [-39.948904977242911, -8.132485475583783],
              [-39.948904968695977, -8.132485544114704],
              [-39.948904960674419, -8.132485612708651],
              [-39.948904953178676, -8.132485681361647],
              [-39.948904946209204, -8.132485750069682],
              [-39.9489049397664, -8.132485818828727],
              [-39.948904933850677, -8.132485887634806],
              [-39.948904928462319, -8.132485956483874],
              [-39.94890492360166, -8.132486025371939],
              [-39.948904919269019, -8.132486094294977],
              [-39.94890491546461, -8.132486163248942],
              [-39.948904912188667, -8.132486232229869],
              [-39.948904909441382, -8.132486301233703],
              [-39.948904907222918, -8.132486370256439],
              [-39.948904905533404, -8.132486439294038],
              [-39.948904904372931, -8.132486508342488],
              [-39.948904903741564, -8.132486577397749],
              [-39.94890490363936, -8.132486646455787],
              [-39.948904871937081, -8.132486663373086],
              [-39.948907266954727, -8.133505230621729],
              [-39.948907298782537, -8.133505267064018],
              [-39.948907298394353, -8.133505374547209],
              [-39.948907296724357, -8.133505482018201],
              [-39.948907293772791, -8.133505589461841],
              [-39.948907289540067, -8.133505696862938],
              [-39.948907284026781, -8.133505804206331],
              [-39.948907277233708, -8.133505911476869],
              [-39.948907269161829, -8.13350601865935],
              [-39.948907259812266, -8.133506125738721],
              [-39.948907249186348, -8.133506232699798],
              [-39.948907237285546, -8.133506339527459],
              [-39.948907224111586, -8.133506446206685],
              [-39.948907209666302, -8.133506552722316],
              [-39.948907193951754, -8.13350665905941],
              [-39.948907176970124, -8.133506765202881],
              [-39.948907158723856, -8.13350687113777],
              [-39.9489071392155, -8.1335069768491],
              [-39.948907118447814, -8.133507082321954],
              [-39.948907096423731, -8.133507187541422],
              [-39.948907073146351, -8.13350729249264],
              [-39.948907048618999, -8.133507397160811],
              [-39.948907022845091, -8.133507501531122],
              [-39.948906995828324, -8.133507605588818],
              [-39.948906967572448, -8.133507709319268],
              [-39.948906938081493, -8.13350781270778],
              [-39.948906907359635, -8.133507915739747],
              [-39.948906875411168, -8.133508018400658],
              [-39.948906842240653, -8.133508120675947],
              [-39.948906807852737, -8.133508222551196],
              [-39.948906772252307, -8.133508324012048],
              [-39.948906735444361, -8.133508425044148],
              [-39.948906697434126, -8.133508525633252],
              [-39.948906658226946, -8.133508625765096],
              [-39.948906617828378, -8.1335087254256],
              [-39.94890657624412, -8.133508824600648],
              [-39.948906533480027, -8.13350892327623],
              [-39.948906489542182, -8.133509021438465],
              [-39.948906444436744, -8.133509119073423],
              [-39.948906398170109, -8.133509216167345],
              [-39.948906350748821, -8.133509312706519],
              [-39.948906302179545, -8.133509408677316],
              [-39.948906252469172, -8.133509504066197],
              [-39.948906201624688, -8.133509598859627],
              [-39.948906149653311, -8.133509693044273],
              [-39.948906096562375, -8.133509786606821],
              [-39.948906042359354, -8.133509879534044],
              [-39.948905987051916, -8.133509971812837],
              [-39.948905930647882, -8.133510063430158],
              [-39.948905873155191, -8.133510154373056],
              [-39.948905814581998, -8.133510244628667],
              [-39.948905754936568, -8.133510334184328],
              [-39.948905694227292, -8.133510423027328],
              [-39.94890563246279, -8.133510511145149],
              [-39.948905569651757, -8.133510598525307],
              [-39.948905505803069, -8.13351068515548],
              [-39.948905440925735, -8.133510771023417],
              [-39.948905375028936, -8.133510856117034],
              [-39.948905308121965, -8.133510940424284],
              [-39.948905240214287, -8.133511023933231],
              [-39.948905171315481, -8.133511106632133],
              [-39.948905101435265, -8.133511188509285],
              [-39.948905030583546, -8.133511269553129],
              [-39.948904958770299, -8.133511349752238],
              [-39.948904886005657, -8.133511429095245],
              [-39.948904812299936, -8.13351150757093],
              [-39.948904737663518, -8.133511585168288],
              [-39.948904662106962, -8.133511661876293],
              [-39.948904585640904, -8.133511737684122],
              [-39.948904508276172, -8.133511812581114],
              [-39.948904430023703, -8.133511886556631],
              [-39.948904350894516, -8.133511959600266],
              [-39.948904270899824, -8.133512031701654],
              [-39.948904190050889, -8.133512102850677],
              [-39.948904108359159, -8.13351217303725],
              [-39.948904025836136, -8.133512242251458],
              [-39.948903942493509, -8.13351231048355],
              [-39.948903858343037, -8.133512377723836],
              [-39.948903773396609, -8.133512443962895],
              [-39.94890368766621, -8.133512509191311],
              [-39.948903601163956, -8.133512573399896],
              [-39.948903513902074, -8.133512636579596],
              [-39.94890342589288, -8.133512698721461],
              [-39.948903337148778, -8.133512759816711],
              [-39.948903247682338, -8.133512819856744],
              [-39.948903157506194, -8.133512878833052],
              [-39.948903066633051, -8.13351293673734],
              [-39.948902975075775, -8.133512993561412],
              [-39.948902882847285, -8.133513049297221],
              [-39.948902789960613, -8.133513103936917],
              [-39.948902696428867, -8.133513157472793],
              [-39.94890260226525, -8.133513209897282],
              [-39.948902507483076, -8.133513261202951],
              [-39.948902412095734, -8.133513311382593],
              [-39.948902316116687, -8.133513360429102],
              [-39.948902219559493, -8.133513408335554],
              [-39.948902122437779, -8.133513455095166],
              [-39.948902024765296, -8.133513500701387],
              [-39.948901926555784, -8.133513545147721],
              [-39.948901827823171, -8.133513588427904],
              [-39.948901728581291, -8.13351363053599],
              [-39.948901659465697, -8.133513654945059],
              [-39.948134285535545, -8.133833897058189],
              [-39.948134358092148, -8.133833868021974],
              [-39.948134330069607, -8.133833879771256],
              [-39.948134302086721, -8.133833891613991],
              [-39.948134274143804, -8.13383390355007],
              [-39.948134246241167, -8.133833915579361],
              [-39.948134218379117, -8.133833927701719],
              [-39.94813419055798, -8.133833939917006],
              [-39.948134162778054, -8.133833952225107],
              [-39.948134135039652, -8.133833964625813],
              [-39.948134107343101, -8.13383397711908],
              [-39.9481340796887, -8.133833989704716],
              [-39.94813405207676, -8.133834002382587],
              [-39.948134024507588, -8.133834015152541],
              [-39.94813399698149, -8.133834028014446],
              [-39.948133969498798, -8.133834040968159],
              [-39.948133942059783, -8.133834054013533],
              [-39.94813391466478, -8.133834067150417],
              [-39.9481338873141, -8.133834080378669],
              [-39.948133860008035, -8.13383409369813],
              [-39.948133832746898, -8.133834107108667],
              [-39.948133805530979, -8.133834120610128],
              [-39.948133778360607, -8.133834134202347],
              [-39.948133751236078, -8.133834147885176],
              [-39.948133724157699, -8.133834161658466],
              [-39.948133697125762, -8.133834175522058],
              [-39.948133670140578, -8.133834189475806],
              [-39.948133643202468, -8.133834203519569],
              [-39.948133616311694, -8.133834217653135],
              [-39.948133589468583, -8.133834231876378],
              [-39.948133562673448, -8.133834246189149],
              [-39.948133535926551, -8.133834260591277],
              [-39.948133509228242, -8.133834275082595],
              [-39.948133482578783, -8.13383428966295],
              [-39.948133455978486, -8.133834304332169],
              [-39.948133429427642, -8.133834319090111],
              [-39.948133402926558, -8.133834333936576],
              [-39.948133376475532, -8.133834348871421],
              [-39.948133350074855, -8.13383436389449],
              [-39.948133323724832, -8.133834379005561],
              [-39.948133297425755, -8.133834394204522],
              [-39.948133271177909, -8.133834409491197],
              [-39.948133244981584, -8.133834424865336],
              [-39.9481332188371, -8.133834440326876],
              [-39.94813319274472, -8.13383445587557],
              [-39.948133166704757, -8.133834471511269],
              [-39.948133140717523, -8.133834487233811],
              [-39.948133114783268, -8.133834503042985],
              [-39.948133088902289, -8.133834518938663],
              [-39.948133063074913, -8.133834534920599],
              [-39.948133037301382, -8.133834550988677],
              [-39.948133011582016, -8.133834567142703],
              [-39.948132985917098, -8.133834583382463],
              [-39.948132960306914, -8.133834599707811],
              [-39.948132934751747, -8.133834616118561],
              [-39.948132909251882, -8.133834632614491],
              [-39.948132883807624, -8.133834649195444],
              [-39.948132858419235, -8.133834665861245],
              [-39.948132833087016, -8.133834682611699],
              [-39.94813280781122, -8.133834699446576],
              [-39.948132782592197, -8.133834716365756],
              [-39.948132757430159, -8.133834733369016],
              [-39.948132732325419, -8.133834750456186],
              [-39.948132707278276, -8.133834767627016],
              [-39.948132682288971, -8.133834784881371],
              [-39.948132657357831, -8.133834802219031],
              [-39.948132632485077, -8.133834819639825],
              [-39.948132607671049, -8.133834837143533],
              [-39.948132582915996, -8.133834854729974],
              [-39.948132558220188, -8.133834872398939],
              [-39.948132533583916, -8.133834890150229],
              [-39.948132509007444, -8.133834907983656],
              [-39.948132484491069, -8.133834925899036],
              [-39.948132460035048, -8.133834943896131],
              [-39.948132435639664, -8.133834961974737],
              [-39.948132411305181, -8.133834980134671],
              [-39.948132387031883, -8.133834998375727],
              [-39.948132362820033, -8.133835016697722],
              [-39.948132338669907, -8.133835035100402],
              [-39.948132314581791, -8.133835053583617],
              [-39.948132290555932, -8.133835072147143],
              [-39.948132266592587, -8.133835090790726],
              [-39.948132242692076, -8.133835109514187],
              [-39.94813221885461, -8.133835128317333],
              [-39.948132195080511, -8.133835147199944],
              [-39.948132171369998, -8.133835166161804],
              [-39.948132147723378, -8.133835185202683],
              [-39.948132124140869, -8.133835204322363],
              [-39.948132100622786, -8.133835223520686],
              [-39.948132077169369, -8.13383524279738],
              [-39.94813205378086, -8.13383526215229],
              [-39.948132030457543, -8.133835281585098],
              [-39.948132007199703, -8.133835301095683],
              [-39.948131984007546, -8.133835320683774],
              [-39.948131960881398, -8.133835340349192],
              [-39.948131937821465, -8.133835360091677],
              [-39.948131914828032, -8.133835379911012],
              [-39.948131891901348, -8.133835399807007],
              [-39.948131869041667, -8.133835419779402],
              [-39.948131846249254, -8.133835439827964],
              [-39.948131823524349, -8.133835459952536],
              [-39.948131747798833, -8.133835440516117],
              [-39.946975931722385, -8.134862497283191],
              [-39.946975944034691, -8.134862528839966],
              [-39.946975883207614, -8.134862583380739],
              [-39.946975822876468, -8.13486263846575],
              [-39.946975763046133, -8.134862694090518],
              [-39.946975703721506, -8.134862750250544],
              [-39.946975644907361, -8.1348628069412],
              [-39.946975586608509, -8.134862864157931],
              [-39.946975528829682, -8.134862921896072],
              [-39.946975471575584, -8.134862980150928],
              [-39.946975414850861, -8.134863038917757],
              [-39.946975358660119, -8.134863098191802],
              [-39.946975303007918, -8.134863157968221],
              [-39.946975247898813, -8.134863218242183],
              [-39.946975193337252, -8.134863279008787],
              [-39.946975139327684, -8.134863340263067],
              [-39.946975085874485, -8.134863402000073],
              [-39.946975032982024, -8.13486346421476],
              [-39.946974980654588, -8.134863526902073],
              [-39.946974928896417, -8.134863590056929],
              [-39.946974877711746, -8.134863653674211],
              [-39.946974827104697, -8.13486371774872],
              [-39.946974777079419, -8.134863782275263],
              [-39.946974727639954, -8.134863847248615],
              [-39.946974678790347, -8.134863912663452],
              [-39.946974630534555, -8.134863978514485],
              [-39.946974582876472, -8.134864044796338],
              [-39.946974535820026, -8.134864111503653],
              [-39.946974489368984, -8.134864178631004],
              [-39.946974443527154, -8.134864246172901],
              [-39.946974398298266, -8.134864314123886],
              [-39.946974353686002, -8.134864382478431],
              [-39.946974309693942, -8.134864451230994],
              [-39.946974266325718, -8.134864520375952],
              [-39.946974223584824, -8.134864589907703],
              [-39.946974181474737, -8.134864659820602],
              [-39.946974139998879, -8.134864730108973],
              [-39.946974099160641, -8.134864800767087],
              [-39.94697405896332, -8.134864871789199],
              [-39.946974019410185, -8.134864943169536],
              [-39.946973980504453, -8.134865014902298],
              [-39.946973942249301, -8.134865086981678],
              [-39.94697390464782, -8.134865159401784],
              [-39.946973867703072, -8.134865232156763],
              [-39.946973831418063, -8.13486530524068],
              [-39.946973795795735, -8.134865378647595],
              [-39.946973760838993, -8.134865452371539],
              [-39.946973726550659, -8.134865526406541],
              [-39.946973692933547, -8.134865600746553],
              [-39.946973659990384, -8.134865675385562],
              [-39.946973627723828, -8.134865750317472],
              [-39.946973596136516, -8.134865825536219],
              [-39.946973565231005, -8.134865901035674],
              [-39.946973535009832, -8.134865976809694],
              [-39.94697350547542, -8.134866052852127],
              [-39.946973476630191, -8.134866129156819],
              [-39.946973448476498, -8.134866205717532],
              [-39.946973421016594, -8.134866282528042],
              [-39.946973394252744, -8.134866359582139],
              [-39.946973368187109, -8.134866436873528],
              [-39.946973342821813, -8.134866514395945],
              [-39.946973318158918, -8.134866592143094],
              [-39.946973294200426, -8.134866670108611],
              [-39.946973270948277, -8.134866748286228],
              [-39.946973248404376, -8.13486682666956],
              [-39.946973226570542, -8.134866905252226],
              [-39.946973205448543, -8.134866984027845],
              [-39.946973185040129, -8.134867062989986],
              [-39.946973165346925, -8.134867142132302],
              [-39.946973146370553, -8.134867221448301],
              [-39.946973128112532, -8.134867300931543],
              [-39.946973110574376, -8.134867380575574],
              [-39.946973093757478, -8.13486746037394],
              [-39.946973077663237, -8.134867540320146],
              [-39.946973062292955, -8.134867620407656],
              [-39.946973047647852, -8.13486770063002],
              [-39.946973033729137, -8.13486778098069],
              [-39.946973020537961, -8.13486786145315],
              [-39.946973008075375, -8.134867942040824],
              [-39.946972996342375, -8.134868022737189],
              [-39.946972985339961, -8.134868103535679],
              [-39.946972975068981, -8.134868184429731],
              [-39.9469729655303, -8.134868265412745],
              [-39.946972956724679, -8.134868346478166],
              [-39.946972948652835, -8.134868427619386],
              [-39.946972941315423, -8.134868508829852],
              [-39.946972934713067, -8.134868590102906],
              [-39.946972928846279, -8.134868671431992],
              [-39.946972923715499, -8.134868752810478],
              [-39.946972919321212, -8.134868834231749],
              [-39.946972915663736, -8.134868915689163],
              [-39.946972912743384, -8.134868997176161],
              [-39.946972910560376, -8.134869078686084],
              [-39.946972909114905, -8.134869160212277],
              [-39.946972908407083, -8.134869241748158],
              [-39.946972908436969, -8.13486932328709],
              [-39.946972909204561, -8.13486940482243],
              [-39.946972910709796, -8.134869486347542],
              [-39.946972912952546, -8.134869567855842],
              [-39.946972915932648, -8.134869649340674],
              [-39.946972919649824, -8.134869730795421],
              [-39.946972862771545, -8.134869739905747],
              [-39.947040277756649, -8.136213571279251],
              [-39.947040340623445, -8.136213598591569],
              [-39.947040341915717, -8.136213623652216],
              [-39.947040343277742, -8.136213648709154],
              [-39.947040344709542, -8.136213673762242],
              [-39.947040346211075, -8.136213698811275],
              [-39.947040347782377, -8.136213723856073],
              [-39.947040349423389, -8.136213748896427],
              [-39.94704035113412, -8.136213773932138],
              [-39.947040352914541, -8.136213798963032],
              [-39.947040354764653, -8.136213823988903],
              [-39.947040356684447, -8.136213849009568],
              [-39.947040358673895, -8.136213874024834],
              [-39.947040360732991, -8.136213899034498],
              [-39.947040362861699, -8.136213924038371],
              [-39.947040365060033, -8.136213949036252],
              [-39.947040367327965, -8.136213974027992],
              [-39.947040369665459, -8.136213999013318],
              [-39.947040372072522, -8.136214023992133],
              [-39.947040374549132, -8.136214048964183],
              [-39.947040377095242, -8.13621407392926],
              [-39.94704037971087, -8.136214098887223],
              [-39.94704038239599, -8.136214123837863],
              [-39.947040385150544, -8.136214148780976],
              [-39.947040387974539, -8.136214173716365],
              [-39.947040390867976, -8.136214198643858],
              [-39.947040393830797, -8.136214223563275],
              [-39.947040396863002, -8.13621424847439],
              [-39.947040399964528, -8.136214273377027],
              [-39.947040403135389, -8.136214298270991],
              [-39.947040406375571, -8.13621432315608],
              [-39.947040409685016, -8.136214348032135],
              [-39.947040413063718, -8.136214372898948],
              [-39.947040416511626, -8.1362143977563],
              [-39.947040420028756, -8.13621442260404],
              [-39.947040423615043, -8.136214447441967],
              [-39.947040427270473, -8.136214472269875],
              [-39.947040430995017, -8.136214497087582],
              [-39.947040434788661, -8.136214521894901],
              [-39.947040438651356, -8.136214546691633],
              [-39.947040442583067, -8.136214571477607],
              [-39.947040446583792, -8.136214596252607],
              [-39.947040450653475, -8.136214621016444],
              [-39.947040454792095, -8.136214645768934],
              [-39.947040458999624, -8.136214670509894],
              [-39.947040463276018, -8.136214695239099],
              [-39.947040467621257, -8.136214719956403],
              [-39.947040472035283, -8.136214744661604],
              [-39.947040476518097, -8.136214769354497],
              [-39.947040481069642, -8.136214794034901],
              [-39.947040485689904, -8.136214818702625],
              [-39.947040490378804, -8.136214843357472],
              [-39.947040495136342, -8.136214867999271],
              [-39.947040499962483, -8.136214892627837],
              [-39.947040504857171, -8.136214917242953],
              [-39.947040509820383, -8.136214941844447],
              [-39.947040514852077, -8.136214966432101],
              [-39.947040519952211, -8.136214991005767],
              [-39.947040525120741, -8.136215015565242],
              [-39.947040530357633, -8.136215040110319],
              [-39.947040535662843, -8.136215064640835],
              [-39.947040541036337, -8.1362150891566],
              [-39.947040546478085, -8.136215113657403],
              [-39.947040551988025, -8.13621513814307],
              [-39.947040557566105, -8.136215162613397],
              [-39.947040563212305, -8.136215187068217],
              [-39.947040568926582, -8.136215211507313],
              [-39.947040574708872, -8.136215235930537],
              [-39.947040580559147, -8.13621526033768],
              [-39.947040586477364, -8.136215284728541],
              [-39.947040592463459, -8.136215309102957],
              [-39.947040598517418, -8.136215333460717],
              [-39.947040604639156, -8.136215357801662],
              [-39.947040610828651, -8.136215382125584],
              [-39.94704061708584, -8.136215406432301],
              [-39.947040623410693, -8.136215430721627],
              [-39.947040629803141, -8.136215454993375],
              [-39.947040636263139, -8.13621547924736],
              [-39.947040642790668, -8.136215503483383],
              [-39.947040649385649, -8.136215527701275],
              [-39.947040656048038, -8.136215551900831],
              [-39.947040662777766, -8.136215576081865],
              [-39.947040669574811, -8.136215600244199],
              [-39.947040676439094, -8.136215624387658],
              [-39.947040683370574, -8.136215648512042],
              [-39.947040690369199, -8.13621567261718],
              [-39.947040697434915, -8.136215696702861],
              [-39.947040704567669, -8.136215720768925],
              [-39.947040711767407, -8.136215744815171],
              [-39.947040719034064, -8.136215768841403],
              [-39.94704072636759, -8.136215792847464],
              [-39.947040733767935, -8.13621581683317],
              [-39.947040741235035, -8.136215840798322],
              [-39.947040748768828, -8.136215864742731],
              [-39.947040756369269, -8.136215888666209],
              [-39.947040764036288, -8.136215912568598],
              [-39.947040771769828, -8.136215936449686],
              [-39.947040779569825, -8.136215960309292],
              [-39.947040787436244, -8.136215984147267],
              [-39.947040795368999, -8.136216007963402],
              [-39.947040803368033, -8.136216031757503],
              [-39.947040737304803, -8.136216012178911],
              [-39.947429457630328, -8.137366811525135],
              [-39.947429450795852, -8.137366803589682],
              [-39.947429475468248, -8.137366877700222],
              [-39.947429499497623, -8.137366952020265],
              [-39.947429522882231, -8.137367026544236],
              [-39.947429545620295, -8.137367101266602],
              [-39.947429567710124, -8.137367176181801],
              [-39.947429589150104, -8.137367251284248],
              [-39.947429609938595, -8.137367326568329],
              [-39.947429630074069, -8.137367402028437],
              [-39.947429649555033, -8.137367477658968],
              [-39.947429668380003, -8.137367553454254],
              [-39.947429686547636, -8.137367629408686],
              [-39.947429704056482, -8.137367705516558],
              [-39.947429720905355, -8.13736778177223],
              [-39.947429737092897, -8.137367858170013],
              [-39.947429752617936, -8.137367934704171],
              [-39.947429767479342, -8.137368011369064],
              [-39.947429781675979, -8.137368088158937],
              [-39.947429795206808, -8.13736816506808],
              [-39.947429808070787, -8.137368242090742],
              [-39.94742982026699, -8.137368319221233],
              [-39.947429831794494, -8.137368396453752],
              [-39.94742984265244, -8.137368473782544],
              [-39.947429852840038, -8.137368551201881],
              [-39.947429862356486, -8.137368628705985],
              [-39.94742987120113, -8.137368706289051],
              [-39.947429879373253, -8.137368783945332],
              [-39.947429886872278, -8.137368861669017],
              [-39.947429893697652, -8.137368939454319],
              [-39.947429899848849, -8.137369017295434],
              [-39.947429905325421, -8.137369095186539],
              [-39.947429910126964, -8.137369173121876],
              [-39.947429914253092, -8.13736925109561],
              [-39.947429917703538, -8.137369329101938],
              [-39.947429920478015, -8.137369407135042],
              [-39.94742992257634, -8.137369485189117],
              [-39.947429923998335, -8.137369563258313],
              [-39.947429924743901, -8.137369641336852],
              [-39.947429924812987, -8.137369719418887],
              [-39.947429924205572, -8.137369797498597],
              [-39.947429922921728, -8.137369875570176],
              [-39.947429920961525, -8.137369953627818],
              [-39.947429918325135, -8.137370031665679],
              [-39.947429915012719, -8.137370109677944],
              [-39.947429911024557, -8.137370187658815],
              [-39.947429906360931, -8.137370265602462],
              [-39.947429901022197, -8.137370343503095],
              [-39.947429895008746, -8.137370421354879],
              [-39.947429888321032, -8.137370499152039],
              [-39.947429880959525, -8.137370576888777],
              [-39.947429872924829, -8.137370654559273],
              [-39.947429864217497, -8.137370732157775],
              [-39.947429854838191, -8.137370809678462],
              [-39.947429844787621, -8.137370887115573],
              [-39.947429834066519, -8.137370964463349],
              [-39.947429822675694, -8.137371041716003],
              [-39.947429810615979, -8.137371118867776],
              [-39.947429797888304, -8.137371195912952],
              [-39.9474297844936, -8.137371272845755],
              [-39.947429770432869, -8.13737134966045],
              [-39.947429755707127, -8.137371426351327],
              [-39.947429740317531, -8.137371502912671],
              [-39.947429724265184, -8.137371579338753],
              [-39.947429707551322, -8.137371655623923],
              [-39.947429690177131, -8.137371731762439],
              [-39.947429672143947, -8.137371807748648],
              [-39.947429653453106, -8.137371883576924],
              [-39.947429634106008, -8.137371959241557],
              [-39.947429614104102, -8.137372034736927],
              [-39.947429593448838, -8.137372110057424],
              [-39.947429572141793, -8.137372185197414],
              [-39.947429550184552, -8.137372260151306],
              [-39.947429527578741, -8.137372334913515],
              [-39.947429504326045, -8.137372409478466],
              [-39.947429480428205, -8.137372483840574],
              [-39.947429455886997, -8.137372557994361],
              [-39.947429430704254, -8.137372631934268],
              [-39.947429404881838, -8.137372705654775],
              [-39.947429378421702, -8.137372779150368],
              [-39.947429351325788, -8.137372852415613],
              [-39.947429323596133, -8.137372925445002],
              [-39.947429295234791, -8.137372998233138],
              [-39.947429266243908, -8.137373070774585],
              [-39.947429236625617, -8.137373143063924],
              [-39.947429206382118, -8.13737321509576],
              [-39.947429175515687, -8.137373286864745],
              [-39.947429144028604, -8.137373358365538],
              [-39.947429111923228, -8.13737342959277],
              [-39.94742907920196, -8.137373500541154],
              [-39.947429045867224, -8.137373571205405],
              [-39.947429011921514, -8.137373641580265],
              [-39.947428977367359, -8.137373711660464],
              [-39.947428942207324, -8.137373781440784],
              [-39.947428906444046, -8.137373850916044],
              [-39.947428870080167, -8.137373920081046],
              [-39.94742883311843, -8.13737398893065],
              [-39.947428795561549, -8.137374057459708],
              [-39.947428757412375, -8.137374125663142],
              [-39.947428718673699, -8.137374193535827],
              [-39.947428679348, -8.137374261073644],
              [-39.947428658181451, -8.137374229744745],
              [-39.946789238439649, -8.138461583705972],
              [-39.946789262009098, -8.138461548916004],
              [-39.946789225539334, -8.138461610372913],
              [-39.946789188583836, -8.138461671541011],
              [-39.94678915114492, -8.138461732416522],
              [-39.946789113224881, -8.138461792995614],
              [-39.946789074826128, -8.138461853274544],
              [-39.946789035951035, -8.138461913249527],
              [-39.94678899660201, -8.13846197291684],
              [-39.946788956781532, -8.138462032272773],
              [-39.946788916492075, -8.138462091313629],
              [-39.946788875736139, -8.138462150035723],
              [-39.946788834516262, -8.138462208435385],
              [-39.946788792835022, -8.138462266508963],
              [-39.946788750695013, -8.138462324252888],
              [-39.946788708098872, -8.138462381663492],
              [-39.94678866504924, -8.13846243873725],
              [-39.946788621548791, -8.138462495470582],
              [-39.946788577600259, -8.13846255186],
              [-39.94678853320638, -8.138462607901905],
              [-39.946788488369911, -8.138462663592888],
              [-39.946788443093645, -8.138462718929421],
              [-39.946788397380395, -8.138462773908062],
              [-39.946788351233032, -8.138462828525437],
              [-39.946788304654433, -8.138462882778049],
              [-39.946788257647484, -8.138462936662604],
              [-39.946788210215104, -8.138462990175752],
              [-39.946788162360271, -8.138463043314056],
              [-39.946788114085969, -8.138463096074315],
              [-39.946788065395189, -8.138463148453148],
              [-39.946788016290981, -8.138463200447367],
              [-39.946787966776398, -8.138463252053695],
              [-39.946787916854525, -8.138463303268946],
              [-39.946787866528474, -8.138463354089852],
              [-39.946787815801365, -8.138463404513315],
              [-39.946787764676401, -8.138463454536167],
              [-39.946787713156723, -8.138463504155297],
              [-39.946787661245573, -8.138463553367622],
              [-39.946787608946167, -8.13846360217009],
              [-39.946787556261789, -8.13846365055961],
              [-39.946787503195686, -8.138463698533206],
              [-39.946787449751191, -8.138463746087872],
              [-39.946787395931615, -8.138463793220657],
              [-39.946787341740347, -8.138463839928603],
              [-39.946787287180726, -8.138463886208818],
              [-39.946787232256177, -8.138463932058448],
              [-39.946787176970105, -8.13846397747456],
              [-39.946787121325968, -8.138464022454357],
              [-39.946787065327221, -8.138464066995059],
              [-39.946787008977374, -8.138464111093858],
              [-39.946786952279915, -8.138464154748048],
              [-39.946786895238397, -8.138464197954843],
              [-39.946786837856351, -8.138464240711611],
              [-39.946786780137387, -8.138464283015677],
              [-39.946786722085072, -8.138464324864406],
              [-39.946786663703044, -8.138464366255144],
              [-39.946786604994941, -8.13846440718536],
              [-39.946786545964386, -8.138464447652476],
              [-39.946786486615096, -8.138464487653982],
              [-39.946786426950766, -8.138464527187404],
              [-39.946786366975104, -8.138464566250276],
              [-39.946786306691855, -8.138464604840113],
              [-39.94678624610475, -8.138464642954547],
              [-39.946786185217611, -8.13846468059123],
              [-39.94678612403419, -8.138464717747759],
              [-39.946786062558324, -8.138464754421866],
              [-39.946786000793836, -8.138464790611222],
              [-39.946785938744569, -8.138464826313591],
              [-39.94678587641441, -8.138464861526769],
              [-39.946785813807224, -8.13846489624855],
              [-39.946785750926921, -8.138464930476765],
              [-39.946785687777407, -8.13846496420928],
              [-39.946785624362626, -8.138464997443998],
              [-39.946785560686543, -8.138465030178835],
              [-39.946785496753101, -8.13846506241175],
              [-39.94678543256633, -8.138465094140759],
              [-39.946785368130158, -8.138465125363846],
              [-39.946785303448657, -8.138465156079135],
              [-39.946785238525848, -8.138465186284645],
              [-39.946785173365754, -8.138465215978526],
              [-39.946785107972474, -8.138465245158887],
              [-39.946785042350029, -8.138465273823964],
              [-39.946784976502585, -8.138465301971966],
              [-39.946784910434161, -8.138465329601106],
              [-39.946784844148922, -8.138465356709661],
              [-39.946784777651011, -8.138465383295941],
              [-39.946784710944527, -8.138465409358293],
              [-39.946784644033663, -8.138465434895149],
              [-39.946784576922582, -8.13846545990488],
              [-39.946784509615476, -8.138465484385874],
              [-39.946784442116488, -8.138465508336688],
              [-39.946784374429889, -8.138465531755779],
              [-39.946784306559856, -8.13846555464173],
              [-39.946784238510645, -8.138465576993021],
              [-39.946784170286485, -8.138465598808386],
              [-39.946784101891623, -8.138465620086379],
              [-39.94678403333031, -8.138465640825677],
              [-39.946783964606851, -8.138465661025025],
              [-39.946783895725517, -8.138465680683135],
              [-39.946783826690584, -8.138465699798795],
              [-39.946783757506374, -8.138465718370869],
              [-39.946783711417673, -8.138465666541679],
              [-39.945561150883499, -8.138788774721979],
              [-39.945560334646721, -8.138789047952551],
              [-39.944335260533414, -8.139241311717267],
              [-39.944335323575068, -8.139241281826026],
              [-39.944335306516166, -8.139241288143364],
              [-39.944335289469997, -8.139241294494736],
              [-39.944335272436668, -8.139241300880173],
              [-39.944335255416199, -8.139241307299608],
              [-39.944335238408698, -8.139241313753049],
              [-39.9443352214142, -8.139241320240485],
              [-39.944335204432797, -8.13924132676182],
              [-39.944335187464553, -8.139241333317102],
              [-39.94433517050954, -8.139241339906249],
              [-39.944335153567806, -8.139241346529294],
              [-39.944335136639438, -8.139241353186131],
              [-39.944335119724485, -8.139241359876802],
              [-39.944335102823054, -8.139241366601237],
              [-39.944335085935172, -8.139241373359432],
              [-39.944335069060934, -8.139241380151372],
              [-39.944335052200387, -8.139241386977005],
              [-39.944335035353625, -8.139241393836301],
              [-39.944335018520675, -8.139241400729258],
              [-39.944335001701639, -8.139241407655826],
              [-39.944334984896578, -8.139241414616],
              [-39.944334968105551, -8.13924142160972],
              [-39.944334951328642, -8.139241428636977],
              [-39.944334934565887, -8.139241435697746],
              [-39.944334917817386, -8.13924144279197],
              [-39.944334901083188, -8.13924144991967],
              [-39.944334884363364, -8.139241457080773],
              [-39.944334867658, -8.139241464275287],
              [-39.944334850967131, -8.139241471503155],
              [-39.944334834290842, -8.139241478764346],
              [-39.944334817629205, -8.139241486058856],
              [-39.944334800982261, -8.139241493386638],
              [-39.944334784350112, -8.139241500747653],
              [-39.944334767732805, -8.139241508141895],
              [-39.944334751130391, -8.139241515569321],
              [-39.944334734542984, -8.139241523029916],
              [-39.944334717970605, -8.139241530523613],
              [-39.944334701413354, -8.139241538050415],
              [-39.944334684871265, -8.139241545610263],
              [-39.944334668344432, -8.139241553203162],
              [-39.944334651832911, -8.139241560829078],
              [-39.944334635336759, -8.139241568487947],
              [-39.944334618856061, -8.139241576179778],
              [-39.94433460239086, -8.139241583904527],
              [-39.944334585941242, -8.139241591662156],
              [-39.944334569507269, -8.139241599452614],
              [-39.944334553089007, -8.139241607275922],
              [-39.944334536686505, -8.139241615132011],
              [-39.944334520299854, -8.139241623020846],
              [-39.94433450392912, -8.139241630942406],
              [-39.944334487574345, -8.139241638896648],
              [-39.944334471235628, -8.139241646883567],
              [-39.944334454912998, -8.139241654903113],
              [-39.944334438606539, -8.139241662955254],
              [-39.944334422316309, -8.139241671039956],
              [-39.944334406042394, -8.139241679157164],
              [-39.944334389784835, -8.139241687306919],
              [-39.944334373543711, -8.139241695489096],
              [-39.944334357319086, -8.13924170370373],
              [-39.944334341111031, -8.139241711950758],
              [-39.944334324919581, -8.139241720230155],
              [-39.944334308744835, -8.139241728541897],
              [-39.944334292586845, -8.139241736885927],
              [-39.94433427644568, -8.139241745262227],
              [-39.944334260321398, -8.139241753670763],
              [-39.944334244214069, -8.139241762111478],
              [-39.944334228123765, -8.139241770584372],
              [-39.944334212050535, -8.139241779089408],
              [-39.944334195994436, -8.139241787626528],
              [-39.944334179955575, -8.139241796195719],
              [-39.944334163933988, -8.139241804796926],
              [-39.944334147929716, -8.139241813430134],
              [-39.94433413194286, -8.139241822095295],
              [-39.944334115973476, -8.139241830792388],
              [-39.944334100021621, -8.139241839521365],
              [-39.944334084087366, -8.139241848282193],
              [-39.944334068170768, -8.139241857074834],
              [-39.944334052271891, -8.139241865899272],
              [-39.944334036390806, -8.139241874755443],
              [-39.944334020527577, -8.139241883643324],
              [-39.944334004682254, -8.139241892562891],
              [-39.944333988854922, -8.139241901514119],
              [-39.944333973045623, -8.13924191049694],
              [-39.94433395725445, -8.139241919511326],
              [-39.944333941481425, -8.139241928557254],
              [-39.944333925726639, -8.139241937634694],
              [-39.944333909990149, -8.139241946743576],
              [-39.94433389427202, -8.139241955883868],
              [-39.94433387857233, -8.139241965055552],
              [-39.944333862891128, -8.13924197425858],
              [-39.944333847228449, -8.139241983492914],
              [-39.944333831584387, -8.139241992758523],
              [-39.94433381595902, -8.13924200205537],
              [-39.944333800352375, -8.139242011383443],
              [-39.944333784764545, -8.139242020742689],
              [-39.944333769195573, -8.139242030133037],
              [-39.94433375364553, -8.139242039554473],
              [-39.944333738114466, -8.139242049006947],
              [-39.944333722602458, -8.139242058490444],
              [-39.944333707109557, -8.13924206800491],
              [-39.944333719243296, -8.139242038848661],
              [-39.943499435333663, -8.139755526084414],
              [-39.943499424052391, -8.139755565611894],
              [-39.943499357981956, -8.139755606669022],
              [-39.943499292267418, -8.139755648289455],
              [-39.94349922691363, -8.139755690470082],
              [-39.943499161925381, -8.139755733207862],
              [-39.943499097307452, -8.13975577649961],
              [-39.943499033064619, -8.139755820342128],
              [-39.943498969201599, -8.139755864732242],
              [-39.943498905723082, -8.139755909666627],
              [-39.943498842633765, -8.13975595514202],
              [-39.943498779938274, -8.139756001155046],
              [-39.943498717641219, -8.139756047702297],
              [-39.943498655747199, -8.139756094780404],
              [-39.943498594260753, -8.139756142385863],
              [-39.943498533186414, -8.139756190515183],
              [-39.943498472528688, -8.139756239164823],
              [-39.943498412292023, -8.139756288331196],
              [-39.943498352480859, -8.139756338010688],
              [-39.943498293099601, -8.139756388199645],
              [-39.943498234152607, -8.139756438894365],
              [-39.94349817564423, -8.139756490091132],
              [-39.943498117578756, -8.139756541786175],
              [-39.94349805996049, -8.139756593975671],
              [-39.943498002793646, -8.139756646655789],
              [-39.943497946082452, -8.139756699822652],
              [-39.94349788983105, -8.139756753472373],
              [-39.94349783404359, -8.139756807600971],
              [-39.943497778724215, -8.139756862204456],
              [-39.943497723876966, -8.139756917278843],
              [-39.943497669505867, -8.139756972820066],
              [-39.943497615614945, -8.139757028824057],
              [-39.943497562208144, -8.139757085286622],
              [-39.943497509289415, -8.139757142203662],
              [-39.943497456862652, -8.139757199570994],
              [-39.943497404931684, -8.139757257384355],
              [-39.943497353500369, -8.13975731563955],
              [-39.943497302572482, -8.139757374332234],
              [-39.943497252151758, -8.139757433458124],
              [-39.943497202241922, -8.139757493012846],
              [-39.943497152846632, -8.13975755299203],
              [-39.943497103969534, -8.13975761339125],
              [-39.943497055614216, -8.139757674206091],
              [-39.943497007784281, -8.139757735432038],
              [-39.943496960483181, -8.139757797064592],
              [-39.943496913714434, -8.139757859099275],
              [-39.943496867481485, -8.139757921531436],
              [-39.943496821787726, -8.139757984356528],
              [-39.943496776636508, -8.139758047569913],
              [-39.943496732031193, -8.139758111166936],
              [-39.943496687975021, -8.139758175142926],
              [-39.943496644471253, -8.139758239493183],
              [-39.943496601523101, -8.139758304212938],
              [-39.943496559133713, -8.13975836929745],
              [-39.943496517306208, -8.139758434741957],
              [-39.943496476043663, -8.139758500541605],
              [-39.943496435349111, -8.139758566691565],
              [-39.94349639522558, -8.139758633186965],
              [-39.943496355675968, -8.139758700022911],
              [-39.943496316703246, -8.139758767194476],
              [-39.943496278310249, -8.139758834696721],
              [-39.943496240499783, -8.139758902524681],
              [-39.94349620327467, -8.139758970673382],
              [-39.943496166637622, -8.139759039137752],
              [-39.943496130591356, -8.139759107912843],
              [-39.943496095138514, -8.139759176993509],
              [-39.943496060281696, -8.13975924637473],
              [-39.943496026023496, -8.139759316051341],
              [-39.943495992366401, -8.139759386018268],
              [-39.943495959312919, -8.139759456270314],
              [-39.943495926865452, -8.139759526802301],
              [-39.943495895026416, -8.139759597609119],
              [-39.943495863798134, -8.13975966868548],
              [-39.943495833182908, -8.139759740026182],
              [-39.943495803183012, -8.139759811625966],
              [-39.943495773800628, -8.13975988347957],
              [-39.943495745037907, -8.139759955581699],
              [-39.943495716897026, -8.139760027927048],
              [-39.943495689379994, -8.139760100510312],
              [-39.943495662488857, -8.139760173326103],
              [-39.943495636225606, -8.13976024636912],
              [-39.943495610592159, -8.13976031963394],
              [-39.943495585590398, -8.1397603931152],
              [-39.943495561222186, -8.13976046680747],
              [-39.943495537489298, -8.13976054070536],
              [-39.94349551439349, -8.1397606148034],
              [-39.943495491936446, -8.139760689096148],
              [-39.943495470119835, -8.139760763578144],
              [-39.943495448945264, -8.13976083824387],
              [-39.943495428414266, -8.139760913087873],
              [-39.943495408528385, -8.13976098810465],
              [-39.943495389289069, -8.139761063288638],
              [-39.943495370697747, -8.139761138634329],
              [-39.943495352755761, -8.139761214136147],
              [-39.943495335464455, -8.139761289788588],
              [-39.943495318825079, -8.139761365586045],
              [-39.943495302838883, -8.139761441522969],
              [-39.943495287507027, -8.139761517593751],
              [-39.943495272830638, -8.139761593792814],
              [-39.94349525881082, -8.139761670114504],
              [-39.943495245448574, -8.139761746553255],
              [-39.943495184031171, -8.139761776559421],
              [-39.943357837798466, -8.140568037174862],
              [-39.944273360878576, -8.141877248214106],
              [-39.94427338786366, -8.141877271961704],
              [-39.944273395300208, -8.141877282612496],
              [-39.944273402721407, -8.141877293273888],
              [-39.944273410127259, -8.141877303945893],
              [-39.944273417517742, -8.141877314628463],
              [-39.944273424892828, -8.1418773253216],
              [-39.944273432252523, -8.141877336025265],
              [-39.944273439596785, -8.141877346739429],
              [-39.944273446925621, -8.141877357464089],
              [-39.94427345423901, -8.141877368199216],
              [-39.944273461536937, -8.141877378944773],
              [-39.944273468819389, -8.141877389700758],
              [-39.944273476086352, -8.141877400467139],
              [-39.944273483337795, -8.141877411243902],
              [-39.944273490573714, -8.141877422031024],
              [-39.944273497794107, -8.141877432828478],
              [-39.944273504998947, -8.141877443636229],
              [-39.944273512188211, -8.141877454454292],
              [-39.944273519361886, -8.14187746528258],
              [-39.944273526519986, -8.141877476121167],
              [-39.944273533662461, -8.14187748696993],
              [-39.944273540789304, -8.141877497828906],
              [-39.944273547900529, -8.141877508698059],
              [-39.944273554996073, -8.141877519577376],
              [-39.944273562075956, -8.141877530466816],
              [-39.944273569140158, -8.141877541366386],
              [-39.944273576188657, -8.141877552276032],
              [-39.944273583221445, -8.141877563195722],
              [-39.94427359023851, -8.14187757412547],
              [-39.944273597239814, -8.141877585065229],
              [-39.944273604225373, -8.141877596014996],
              [-39.944273611195158, -8.141877606974743],
              [-39.944273618149161, -8.141877617944411],
              [-39.944273625087369, -8.141877628924023],
              [-39.944273632009747, -8.141877639913561],
              [-39.944273638916307, -8.141877650912976],
              [-39.944273645807023, -8.14187766192223],
              [-39.944273652681872, -8.141877672941364],
              [-39.944273659540855, -8.1418776839703],
              [-39.944273666383964, -8.141877695009013],
              [-39.944273673211157, -8.141877706057512],
              [-39.944273680022448, -8.14187771711576],
              [-39.944273686817809, -8.141877728183726],
              [-39.944273693597225, -8.141877739261382],
              [-39.944273700360682, -8.141877750348732],
              [-39.944273707108188, -8.141877761445732],
              [-39.944273713839692, -8.141877772552393],
              [-39.944273720555202, -8.141877783668642],
              [-39.944273727254703, -8.14187779479448],
              [-39.944273733938182, -8.141877805929889],
              [-39.944273740605624, -8.141877817074821],
              [-39.944273747257, -8.141877828229292],
              [-39.944273753892332, -8.14187783939327],
              [-39.944273760511564, -8.141877850566717],
              [-39.944273767114709, -8.141877861749601],
              [-39.944273773701745, -8.141877872941922],
              [-39.944273780272681, -8.141877884143668],
              [-39.944273786827473, -8.141877895354767],
              [-39.944273793366101, -8.14187790657523],
              [-39.944273799888578, -8.141877917805019],
              [-39.944273806394882, -8.141877929044128],
              [-39.944273812885001, -8.141877940292522],
              [-39.944273819358912, -8.141877951550185],
              [-39.944273825816616, -8.1418779628171],
              [-39.944273832258091, -8.141877974093234],
              [-39.94427383868333, -8.141877985378573],
              [-39.944273845092297, -8.141877996673076],
              [-39.944273851485015, -8.141878007976734],
              [-39.94427385786144, -8.141878019289509],
              [-39.944273864221572, -8.141878030611403],
              [-39.944273870565411, -8.141878041942361],
              [-39.944273876892915, -8.141878053282376],
              [-39.94427388320409, -8.141878064631458],
              [-39.94427388949893, -8.141878075989499],
              [-39.9442738957774, -8.141878087356547],
              [-39.944273902039505, -8.141878098732576],
              [-39.944273908285226, -8.141878110117537],
              [-39.944273914514547, -8.141878121511398],
              [-39.944273920727454, -8.141878132914163],
              [-39.944273926923941, -8.141878144325803],
              [-39.944273933103993, -8.14187815574628],
              [-39.944273939267596, -8.141878167175591],
              [-39.944273945414743, -8.141878178613695],
              [-39.944273951545398, -8.141878190060545],
              [-39.944273957659583, -8.141878201516166],
              [-39.944273963757269, -8.141878212980533],
              [-39.944273969838441, -8.141878224453599],
              [-39.944273975903087, -8.141878235935344],
              [-39.944273981951206, -8.141878247425753],
              [-39.944273987982761, -8.141878258924789],
              [-39.944273993997761, -8.141878270432425],
              [-39.944273999996192, -8.141878281948671],
              [-39.944274005978023, -8.141878293473468],
              [-39.944274011943278, -8.141878305006808],
              [-39.944274017891907, -8.141878316548663],
              [-39.944274023823915, -8.14187832809899],
              [-39.94427402973929, -8.141878339657804],
              [-39.944274035638017, -8.141878351225055],
              [-39.944274041520075, -8.141878362800743],
              [-39.944274047385527, -8.141878374383809],
              [-39.944273998771642, -8.141878332051396],
              [-39.944843192874551, -8.143004475143197],
              [-39.94484323141031, -8.143004507342178],
              [-39.944843286775004, -8.143004615080363],
              [-39.944843343581709, -8.143004722070602],
              [-39.944843401820251, -8.143004828293726],
              [-39.944843461480211, -8.143004933730682],
              [-39.944843522550904, -8.143005038362617],
              [-39.944843585021381, -8.143005142170791],
              [-39.94484364888045, -8.143005245136571],
              [-39.944843714116693, -8.14300534724158],
              [-39.944843780718408, -8.143005448467454],
              [-39.944843848673671, -8.143005548796127],
              [-39.944843917970303, -8.143005648209598],
              [-39.944843988595899, -8.14300574669006],
              [-39.944844060537825, -8.143005844219868],
              [-39.944844133783171, -8.143005940781567],
              [-39.944844208318841, -8.143006036357869],
              [-39.944844284131449, -8.143006130931656],
              [-39.944844361207444, -8.14300622448596],
              [-39.944844439533021, -8.143006317004037],
              [-39.944844519094154, -8.143006408469338],
              [-39.944844599876568, -8.143006498865478],
              [-39.944844681865817, -8.143006588176227],
              [-39.944844765047208, -8.143006676385621],
              [-39.944844849405854, -8.143006763477855],
              [-39.944844934926643, -8.143006849437315],
              [-39.944845021594233, -8.143006934248639],
              [-39.944845109393114, -8.14300701789662],
              [-39.944845198307576, -8.143007100366281],
              [-39.94484528832168, -8.143007181642833],
              [-39.944845379419313, -8.143007261711732],
              [-39.944845471584145, -8.14300734055864],
              [-39.944845564799678, -8.143007418169429],
              [-39.944845659049214, -8.143007494530201],
              [-39.944845754315857, -8.143007569627278],
              [-39.944845850582574, -8.143007643447193],
              [-39.9448459478321, -8.143007715976749],
              [-39.944846046047026, -8.143007787202919],
              [-39.944846145209759, -8.143007857112988],
              [-39.944846245302529, -8.143007925694409],
              [-39.944846346307422, -8.143007992934912],
              [-39.944846448206356, -8.143008058822462],
              [-39.944846550981048, -8.143008123345224],
              [-39.944846654613116, -8.143008186491667],
              [-39.944846759083987, -8.143008248250496],
              [-39.944846864374966, -8.143008308610618],
              [-39.944846970467168, -8.143008367561231],
              [-39.944847077341606, -8.143008425091764],
              [-39.944847184979132, -8.143008481191957],
              [-39.944847293360482, -8.143008535851703],
              [-39.944847402466209, -8.143008589061266],
              [-39.944847512276816, -8.143008640811072],
              [-39.944847622772613, -8.143008691091884],
              [-39.944847733933798, -8.143008739894702],
              [-39.944847845740483, -8.143008787210741],
              [-39.944847958172623, -8.143008833031548],
              [-39.944848071210103, -8.143008877348908],
              [-39.944848184832658, -8.143008920154902],
              [-39.944848299019938, -8.143008961441883],
              [-39.944848413751501, -8.143009001202413],
              [-39.944848529006791, -8.143009039429396],
              [-39.944848644765152, -8.143009076115975],
              [-39.944848761005886, -8.143009111255566],
              [-39.944848877708154, -8.143009144841894],
              [-39.944848994851043, -8.143009176868945],
              [-39.944849112413571, -8.143009207330971],
              [-39.944849230374693, -8.143009236222536],
              [-39.944849348713284, -8.143009263538424],
              [-39.94484946740814, -8.143009289273788],
              [-39.944849586438011, -8.143009313423986],
              [-39.944849705781571, -8.143009335984733],
              [-39.94484982541745, -8.143009356951943],
              [-39.944849945324194, -8.143009376321862],
              [-39.944850065480367, -8.143009394091051],
              [-39.944850185864404, -8.143009410256328],
              [-39.944850306454796, -8.143009424814778],
              [-39.944850427229902, -8.143009437763801],
              [-39.944850548168105, -8.143009449101063],
              [-39.944850669247735, -8.143009458824574],
              [-39.944850790447127, -8.143009466932527],
              [-39.944850911744545, -8.143009473423525],
              [-39.944851033118297, -8.143009478296396],
              [-39.944851154546598, -8.143009481550257],
              [-39.944851276007746, -8.143009483184519],
              [-39.944851397479958, -8.143009483198897],
              [-39.944851518941491, -8.14300948159339],
              [-39.944851640370572, -8.143009478368285],
              [-39.944851761745475, -8.14300947352414],
              [-39.944851883044436, -8.143009467061876],
              [-39.944852004245753, -8.143009458982577],
              [-39.944852125327714, -8.143009449287778],
              [-39.944852246268603, -8.143009437979105],
              [-39.944852367046792, -8.143009425058683],
              [-39.944852487640652, -8.143009410528776],
              [-39.944852608028548, -8.143009394392003],
              [-39.944852728188941, -8.143009376651248],
              [-39.944852848100318, -8.143009357309717],
              [-39.944852967741177, -8.143009336370799],
              [-39.944853087090124, -8.143009313838313],
              [-39.944853206125735, -8.143009289716296],
              [-39.944853324826731, -8.143009264009027],
              [-39.944853278985519, -8.143009245166313],
              [-39.946127406243257, -8.142724365486124],
              [-39.946127465160842, -8.142724409092827],
              [-39.946127484700462, -8.142724404746923],
              [-39.946127504249645, -8.142724400444006],
              [-39.946127523808308, -8.142724396184057],
              [-39.946127543376363, -8.142724391967098],
              [-39.946127562953698, -8.142724387793157],
              [-39.946127582540214, -8.142724383662273],
              [-39.946127602135846, -8.142724379574441],
              [-39.94612762174048, -8.142724375529715],
              [-39.946127641354003, -8.142724371528063],
              [-39.946127660976337, -8.142724367569532],
              [-39.946127680607404, -8.142724363654127],
              [-39.946127700247075, -8.142724359781878],
              [-39.946127719895273, -8.142724355952817],
              [-39.946127739551898, -8.142724352166965],
              [-39.946127759216864, -8.142724348424307],
              [-39.946127778890066, -8.142724344724872],
              [-39.946127798571396, -8.142724341068696],
              [-39.946127818260777, -8.142724337455801],
              [-39.946127837958116, -8.142724333886179],
              [-39.946127857663292, -8.142724330359849],
              [-39.946127877376242, -8.142724326876863],
              [-39.946127897096844, -8.142724323437191],
              [-39.946127916825013, -8.142724320040879],
              [-39.946127936560657, -8.142724316687945],
              [-39.946127956303677, -8.142724313378393],
              [-39.94612797605398, -8.142724310112234],
              [-39.946127995811452, -8.142724306889523],
              [-39.946128015576022, -8.142724303710205],
              [-39.946128035347563, -8.142724300574338],
              [-39.946128055126017, -8.142724297481951],
              [-39.94612807491125, -8.14272429443303],
              [-39.946128094703198, -8.142724291427625],
              [-39.94612811450174, -8.142724288465699],
              [-39.946128134306797, -8.142724285547308],
              [-39.94612815411827, -8.142724282672464],
              [-39.94612817393606, -8.142724279841174],
              [-39.946128193760053, -8.142724277053444],
              [-39.946128213590171, -8.142724274309288],
              [-39.946128233426336, -8.142724271608742],
              [-39.946128253268419, -8.14272426895177],
              [-39.946128273116329, -8.142724266338451],
              [-39.946128292969981, -8.142724263768731],
              [-39.946128312829273, -8.142724261242666],
              [-39.946128332694101, -8.142724258760268],
              [-39.946128352564386, -8.142724256321523],
              [-39.946128372440015, -8.142724253926465],
              [-39.946128392320908, -8.142724251575096],
              [-39.946128412206953, -8.142724249267427],
              [-39.946128432098043, -8.142724247003466],
              [-39.946128451994113, -8.142724244783246],
              [-39.946128471895044, -8.142724242606759],
              [-39.946128491800735, -8.142724240473996],
              [-39.946128511711116, -8.142724238385023],
              [-39.946128531626066, -8.142724236339804],
              [-39.946128551545492, -8.142724234338344],
              [-39.946128571469302, -8.142724232380672],
              [-39.94612859139739, -8.142724230466827],
              [-39.946128611329684, -8.142724228596755],
              [-39.946128631266049, -8.142724226770506],
              [-39.946128651206415, -8.142724224988079],
              [-39.946128671150682, -8.142724223249502],
              [-39.946128691098743, -8.142724221554746],
              [-39.946128711050513, -8.142724219903847],
              [-39.946128731005878, -8.142724218296792],
              [-39.946128750964768, -8.142724216733614],
              [-39.946128770927061, -8.142724215214296],
              [-39.946128790892665, -8.142724213738871],
              [-39.946128810861488, -8.142724212307323],
              [-39.946128830833445, -8.14272421091969],
              [-39.946128850808414, -8.142724209575945],
              [-39.946128870786303, -8.1427242082761],
              [-39.946128890767028, -8.14272420702016],
              [-39.946128910750481, -8.142724205808159],
              [-39.946128930736556, -8.142724204640063],
              [-39.946128950725203, -8.142724203515915],
              [-39.946128970716266, -8.142724202435698],
              [-39.946128990709674, -8.142724201399405],
              [-39.946129010705327, -8.142724200407084],
              [-39.946129030703126, -8.142724199458705],
              [-39.946129050702986, -8.142724198554289],
              [-39.946129070704778, -8.142724197693807],
              [-39.946129090708446, -8.142724196877319],
              [-39.946129110713869, -8.142724196104799],
              [-39.946129130720962, -8.142724195376246],
              [-39.946129150729597, -8.142724194691672],
              [-39.946129170739709, -8.142724194051084],
              [-39.946129190751201, -8.142724193454457],
              [-39.946129210763957, -8.142724192901829],
              [-39.946129230777878, -8.142724192393187],
              [-39.946129250792886, -8.142724191928531],
              [-39.946129270808868, -8.142724191507869],
              [-39.946129290825745, -8.14272419113121],
              [-39.946129310843389, -8.142724190798521],
              [-39.946129330861737, -8.142724190509847],
              [-39.946129350880668, -8.142724190265174],
              [-39.946129370900096, -8.142724190064506],
              [-39.9461293909199, -8.142724189907836],
              [-39.946129410940024, -8.142724189795167],
              [-39.946129430960312, -8.142724189726485],
              [-39.946129402638384, -8.142724179946395],
              [-39.946910522121243, -8.142722358629635],
              [-39.946910568155381, -8.14272236836838],
              [-39.946910602305685, -8.142722368352734],
              [-39.946910636455826, -8.142722368465137],
              [-39.946910670605291, -8.142722368705567],
              [-39.946910704753613, -8.142722369074004],
              [-39.9469107389003, -8.14272236957046],
              [-39.946910773044856, -8.142722370194985],
              [-39.946910807186818, -8.142722370947482],
              [-39.946910841325703, -8.142722371827981],
              [-39.946910875461029, -8.142722372836442],
              [-39.94691090959229, -8.142722373972903],
              [-39.946910943719026, -8.142722375237346],
              [-39.946910977840751, -8.142722376629662],
              [-39.946911011956963, -8.142722378149955],
              [-39.946911046067207, -8.142722379798091],
              [-39.946911080170977, -8.142722381574142],
              [-39.946911114267799, -8.142722383478036],
              [-39.946911148357195, -8.142722385509776],
              [-39.946911182438676, -8.14272238766925],
              [-39.946911216511758, -8.142722389956523],
              [-39.94691125057598, -8.142722392371525],
              [-39.946911284630829, -8.142722394914214],
              [-39.946911318675838, -8.142722397584553],
              [-39.946911352710529, -8.142722400382544],
              [-39.946911386734392, -8.142722403308101],
              [-39.946911420747, -8.14272240636118],
              [-39.946911454747813, -8.142722409541763],
              [-39.94691148873639, -8.14272241284983],
              [-39.946911522712227, -8.14272241628527],
              [-39.946911556674856, -8.142722419848081],
              [-39.946911590623799, -8.142722423538169],
              [-39.94691162455856, -8.142722427355524],
              [-39.946911658478662, -8.142722431300092],
              [-39.946911692383637, -8.142722435371812],
              [-39.946911726272987, -8.142722439570594],
              [-39.946911760146257, -8.142722443896414],
              [-39.946911794002951, -8.142722448349211],
              [-39.946911827842591, -8.14272245292892],
              [-39.946911861664695, -8.142722457635436],
              [-39.946911895468794, -8.142722462468745],
              [-39.946911929254405, -8.142722467428749],
              [-39.946911963021044, -8.1427224725154],
              [-39.946911996768229, -8.142722477728576],
              [-39.946912030495504, -8.14272248306828],
              [-39.94691206420238, -8.142722488534361],
              [-39.946912097888365, -8.14272249412679],
              [-39.946912131552992, -8.142722499845462],
              [-39.946912165195812, -8.142722505690333],
              [-39.9469121988163, -8.142722511661297],
              [-39.946912232414022, -8.142722517758225],
              [-39.946912265988473, -8.142722523981101],
              [-39.946912299539186, -8.142722530329841],
              [-39.946912333065704, -8.142722536804277],
              [-39.946912366567531, -8.142722543404375],
              [-39.946912400044198, -8.142722550130038],
              [-39.946912433495235, -8.142722556981131],
              [-39.946912466920168, -8.142722563957637],
              [-39.946912500318525, -8.142722571059409],
              [-39.946912533689812, -8.14272257828631],
              [-39.946912567033593, -8.142722585638309],
              [-39.946912600349386, -8.142722593115236],
              [-39.946912633636686, -8.142722600717031],
              [-39.946912666895059, -8.142722608443568],
              [-39.946912700124017, -8.142722616294774],
              [-39.94691273332311, -8.142722624270458],
              [-39.946912766491842, -8.142722632370571],
              [-39.94691279962975, -8.142722640594998],
              [-39.946912832736366, -8.142722648943604],
              [-39.946912865811242, -8.142722657416257],
              [-39.946912898853888, -8.142722666012876],
              [-39.946912931863828, -8.142722674733307],
              [-39.946912964840614, -8.142722683577428],
              [-39.946912997783762, -8.142722692545117],
              [-39.946913030692826, -8.142722701636245],
              [-39.946913063567322, -8.142722710850725],
              [-39.94691309640681, -8.142722720188344],
              [-39.946913129210799, -8.142722729649043],
              [-39.946913161978827, -8.142722739232669],
              [-39.946913194710447, -8.142722748939054],
              [-39.946913227405169, -8.142722758768095],
              [-39.946913260062558, -8.142722768719668],
              [-39.94691329268214, -8.142722778793598],
              [-39.946913325263452, -8.142722788989774],
              [-39.946913357806032, -8.142722799307998],
              [-39.946913390309426, -8.142722809748154],
              [-39.946913422773157, -8.142722820310118],
              [-39.9469134551968, -8.142722830993682],
              [-39.946913487579856, -8.142722841798756],
              [-39.946913519921878, -8.142722852725168],
              [-39.946913552222426, -8.142722863772766],
              [-39.946913584481024, -8.142722874941368],
              [-39.946913616697238, -8.142722886230843],
              [-39.946913648870577, -8.142722897641022],
              [-39.946913681000623, -8.142722909171749],
              [-39.946913713086886, -8.142722920822846],
              [-39.946913745128946, -8.142722932594159],
              [-39.946913777126319, -8.142722944485488],
              [-39.946913809078566, -8.142722956496735],
              [-39.946913840985239, -8.142722968627696],
              [-39.946913872845791, -8.142722980878933],
              [-39.946913881895526, -8.142722983895903],
              [-39.94701454098508, -8.142761911047986],
              [-39.946639563429464, -8.144218742659868],
              [-39.946536127901553, -8.14462091412555],
              [-39.946065922212441, -8.144990205388115],
              [-39.946065979285848, -8.144990212544108],
              [-39.94606591431598, -8.144990264056393],
              [-39.946065849821707, -8.144990316158708],
              [-39.946065785808429, -8.144990368846711],
              [-39.946065722281503, -8.144990422115988],
              [-39.946065659246251, -8.144990475962066],
              [-39.946065596707925, -8.14499053038047],
              [-39.946065534671796, -8.144990585366617],
              [-39.946065473143015, -8.144990640915916],
              [-39.946065412126764, -8.144990697023724],
              [-39.946065351628135, -8.144990753685327],
              [-39.946065291652175, -8.144990810896015],
              [-39.946065232203907, -8.144990868650988],
              [-39.946065173288339, -8.144990926945402],
              [-39.946065114910361, -8.144990985774395],
              [-39.946065057074883, -8.144991045133015],
              [-39.946064999786728, -8.144991105016338],
              [-39.946064943050693, -8.144991165419329],
              [-39.94606488687154, -8.144991226336922],
              [-39.946064831253949, -8.144991287764057],
              [-39.946064776202597, -8.144991349695559],
              [-39.946064721722067, -8.14499141212627],
              [-39.946064667816927, -8.14499147505094],
              [-39.946064614491696, -8.144991538464318],
              [-39.946064561750816, -8.144991602361101],
              [-39.946064509598713, -8.14499166673596],
              [-39.946064458039771, -8.144991731583472],
              [-39.946064407078268, -8.144991796898225],
              [-39.946064356718495, -8.144991862674763],
              [-39.946064306964644, -8.144991928907571],
              [-39.946064257820886, -8.144991995591075],
              [-39.946064209291336, -8.14499206271976],
              [-39.946064161380072, -8.144992130287978],
              [-39.946064114091072, -8.144992198290067],
              [-39.946064067428324, -8.14499226672036],
              [-39.946064021395699, -8.144992335573106],
              [-39.946063975997063, -8.144992404842549],
              [-39.946063931236232, -8.144992474522924],
              [-39.946063887116928, -8.144992544608336],
              [-39.946063843642854, -8.144992615092985],
              [-39.946063800817647, -8.144992685970948],
              [-39.94606375864489, -8.144992757236286],
              [-39.946063717128105, -8.14499282888303],
              [-39.946063676270761, -8.144992900905205],
              [-39.946063636076303, -8.144992973296791],
              [-39.946063596548072, -8.144993046051699],
              [-39.946063557689392, -8.144993119163871],
              [-39.946063519503504, -8.144993192627174],
              [-39.94606348199359, -8.144993266435478],
              [-39.946063445162828, -8.144993340582591],
              [-39.946063409014251, -8.144993415062302],
              [-39.946063373550913, -8.144993489868373],
              [-39.94606333877578, -8.144993564994573],
              [-39.946063304691755, -8.144993640434608],
              [-39.946063271301689, -8.144993716182157],
              [-39.946063238608382, -8.144993792230892],
              [-39.946063206614568, -8.14499386857444],
              [-39.946063175322934, -8.144993945206416],
              [-39.946063144736087, -8.144994022120406],
              [-39.946063114856564, -8.144994099309967],
              [-39.946063085686909, -8.144994176768643],
              [-39.946063057229537, -8.144994254489946],
              [-39.946063029486837, -8.144994332467416],
              [-39.946063002461116, -8.14499441069446],
              [-39.946062976154664, -8.144994489164608],
              [-39.946062950569662, -8.14499456787124],
              [-39.946062925708247, -8.144994646807778],
              [-39.94606290157251, -8.144994725967619],
              [-39.946062878164469, -8.144994805344142],
              [-39.94606285548609, -8.144994884930709],
              [-39.946062833539237, -8.144994964720656],
              [-39.946062812325778, -8.144995044707311],
              [-39.946062791847503, -8.144995124883978],
              [-39.946062772106089, -8.144995205243953],
              [-39.946062753103206, -8.144995285780499],
              [-39.946062734840432, -8.14499536648689],
              [-39.946062717319315, -8.144995447356361],
              [-39.946062700541297, -8.144995528382148],
              [-39.946062684507808, -8.144995609557482],
              [-39.946062669220169, -8.144995690875565],
              [-39.946062654679665, -8.144995772329594],
              [-39.946062640887519, -8.144995853912739],
              [-39.946062627844881, -8.144995935618192],
              [-39.946062615552847, -8.144996017439096],
              [-39.946062604012432, -8.144996099368628],
              [-39.946062593224603, -8.14499618139992],
              [-39.946062583190269, -8.144996263526124],
              [-39.946062573910297, -8.144996345740322],
              [-39.946062565385404, -8.144996428035682],
              [-39.946062557616344, -8.144996510405322],
              [-39.94606255060377, -8.14499659284232],
              [-39.946062544348258, -8.144996675339783],
              [-39.946062538850335, -8.144996757890805],
              [-39.946062534110446, -8.14499684048849],
              [-39.946062530129005, -8.144996923125934],
              [-39.946062526906339, -8.14499700579621],
              [-39.946062524442716, -8.144997088492394],
              [-39.946062522738345, -8.144997171207587],
              [-39.946062521793365, -8.144997253934834],
              [-39.946062521607864, -8.144997336667254],
              [-39.946062489915853, -8.144997358414415],
              [-39.946064469062982, -8.145840285850856],
              [-39.946064500821358, -8.14584029233791],
              [-39.946064500740512, -8.145840357146037],
              [-39.946064500193614, -8.145840421951901],
              [-39.946064499180707, -8.14584048675221],
              [-39.946064497701855, -8.145840551543632],
              [-39.946064495757099, -8.145840616322815],
              [-39.946064493346569, -8.145840681086458],
              [-39.94606449047037, -8.145840745831222],
              [-39.946064487128659, -8.145840810553807],
              [-39.946064483321607, -8.145840875250865],
              [-39.946064479049404, -8.145840939919065],
              [-39.94606447431228, -8.145841004555114],
              [-39.946064469110482, -8.145841069155686],
              [-39.946064463444237, -8.14584113371744],
              [-39.946064457313881, -8.145841198237095],
              [-39.946064450719732, -8.145841262711301],
              [-39.946064443662088, -8.14584132713677],
              [-39.946064436141334, -8.14584139151019],
              [-39.946064428157868, -8.145841455828274],
              [-39.946064419712066, -8.145841520087709],
              [-39.946064410804411, -8.145841584285167],
              [-39.946064401435315, -8.145841648417376],
              [-39.946064391605283, -8.145841712481069],
              [-39.94606438131482, -8.145841776472906],
              [-39.946064370564436, -8.145841840389638],
              [-39.946064359354686, -8.145841904227979],
              [-39.946064347686175, -8.145841967984621],
              [-39.946064335559477, -8.14584203165631],
              [-39.946064322975218, -8.145842095239804],
              [-39.946064309934044, -8.145842158731789],
              [-39.946064296436624, -8.145842222129017],
              [-39.946064282483654, -8.145842285428266],
              [-39.946064268075858, -8.145842348626275],
              [-39.946064253213954, -8.145842411719755],
              [-39.946064237898725, -8.145842474705509],
              [-39.94606422213095, -8.1458425375803],
              [-39.946064205911441, -8.145842600340886],
              [-39.946064189241028, -8.145842662984059],
              [-39.946064172120572, -8.145842725506581],
              [-39.946064154550932, -8.14584278790527],
              [-39.946064136533039, -8.145842850176907],
              [-39.94606411806781, -8.145842912318285],
              [-39.946064099156175, -8.145842974326236],
              [-39.946064079799136, -8.145843036197558],
              [-39.946064059997667, -8.145843097929081],
              [-39.94606403975277, -8.145843159517618],
              [-39.946064019065517, -8.145843220960035],
              [-39.946063997936953, -8.145843282253173],
              [-39.946063976368151, -8.145843343393857],
              [-39.946063954360255, -8.145843404378994],
              [-39.946063931914374, -8.145843465205397],
              [-39.946063909031629, -8.145843525870001],
              [-39.946063885713258, -8.145843586369633],
              [-39.946063861960397, -8.145843646701241],
              [-39.946063837774311, -8.145843706861671],
              [-39.946063813156222, -8.145843766847893],
              [-39.946063788107395, -8.145843826656801],
              [-39.946063762629123, -8.145843886285306],
              [-39.946063736722692, -8.145843945730379],
              [-39.946063710389474, -8.145844004988927],
              [-39.946063683630769, -8.145844064057941],
              [-39.946063656447997, -8.14584412293439],
              [-39.946063628842545, -8.145844181615232],
              [-39.946063600815798, -8.14584424009746],
              [-39.946063572369233, -8.145844298378057],
              [-39.946063543504287, -8.145844356454054],
              [-39.946063514222438, -8.145844414322466],
              [-39.946063484525233, -8.145844471980322],
              [-39.946063454414137, -8.145844529424641],
              [-39.946063423890749, -8.145844586652514],
              [-39.946063392956596, -8.145844643660968],
              [-39.946063361613284, -8.145844700447073],
              [-39.946063329862426, -8.14584475700792],
              [-39.946063297705635, -8.145844813340618],
              [-39.946063265144581, -8.145844869442254],
              [-39.946063232180933, -8.145844925309987],
              [-39.946063198816375, -8.145844980940906],
              [-39.946063165052628, -8.145845036332153],
              [-39.946063130891424, -8.145845091480915],
              [-39.946063096334505, -8.145845146384342],
              [-39.946063061383668, -8.145845201039631],
              [-39.946063026040697, -8.145845255443948],
              [-39.946062990307404, -8.145845309594526],
              [-39.946062954185614, -8.145845363488549],
              [-39.946062917677203, -8.145845417123297],
              [-39.946062880784027, -8.145845470496004],
              [-39.946062843507995, -8.145845523603887],
              [-39.946062805851007, -8.14584557644428],
              [-39.946062767815015, -8.145845629014417],
              [-39.946062729401952, -8.145845681311645],
              [-39.9460626906138, -8.145845733333246],
              [-39.94606265145255, -8.145845785076562],
              [-39.946062611920212, -8.145845836538935],
              [-39.946062572018825, -8.145845887717718],
              [-39.946062531750421, -8.145845938610279],
              [-39.946062491117075, -8.14584598921402],
              [-39.946062450120884, -8.145846039526326],
              [-39.946062408763943, -8.145846089544623],
              [-39.94606236704837, -8.145846139266354],
              [-39.946062324976374, -8.145846188688646],
              [-39.946062304501076, -8.145846169715176],
              [-39.945213516275537, -8.146835970198623],
              [-39.945769568410704, -8.146883061633059],
              [-39.945860443340983, -8.146890808809898],
              [-39.947334187025234, -8.142440905375535],
              [-39.951864835876655, -8.128758724952046],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0398000',
        uf: 'PE',
        nome_proje: 'PA JERAMATAIA',
        municipio: 'CUSTODIA',
        area_hecta: '2269.6825',
        capacidade: 53.0,
        num_famili: 51.0,
        fase: 3.0,
        data_de_cr: '05/11/2012',
        forma_obte: 'Desapropriação',
        data_obten: '13/10/2009',
        area_calc_: 2270.1981,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.619551428144696, -8.240596591653011],
              [-37.619603088876438, -8.241928793754877],
              [-37.619810099752598, -8.242700868451699],
              [-37.619653811792148, -8.243007790426436],
              [-37.61969003899479, -8.244078598549535],
              [-37.619641452343288, -8.249710369963383],
              [-37.619455587928762, -8.24990686566448],
              [-37.619432457953486, -8.250032482726374],
              [-37.619433143376781, -8.250358030595727],
              [-37.61964068291919, -8.250978186306973],
              [-37.619385766484235, -8.251453870534004],
              [-37.619339341485137, -8.254366438094628],
              [-37.619400567885187, -8.257907859785101],
              [-37.619442185804139, -8.258731813968906],
              [-37.619345104638626, -8.25897835108848],
              [-37.618156294296938, -8.267999084081172],
              [-37.687287500974882, -8.27771283386471],
              [-37.68683438622886, -8.27719498014017],
              [-37.68675923075741, -8.277135952548425],
              [-37.686716838207076, -8.277052616116883],
              [-37.686449318206805, -8.276416002490773],
              [-37.686274451784172, -8.274667384824628],
              [-37.69165841229151, -8.258273481579987],
              [-37.692696015863689, -8.258001957885993],
              [-37.693058075636031, -8.257772538497335],
              [-37.695605461489443, -8.258020489938],
              [-37.69556905839157, -8.257771683362378],
              [-37.695245626770934, -8.257572583526052],
              [-37.695007919919988, -8.257254392654396],
              [-37.694863329733188, -8.257054066756092],
              [-37.694600882637801, -8.256529610216269],
              [-37.694505825632845, -8.255897180922664],
              [-37.694445731638197, -8.255675426719968],
              [-37.694418179590407, -8.255219559808785],
              [-37.694340218114952, -8.254910028361261],
              [-37.69423390447831, -8.254664611181054],
              [-37.694068001015339, -8.254320428885787],
              [-37.664006166816527, -8.250121156381995],
              [-37.664702093120361, -8.248058032888926],
              [-37.664705444711309, -8.247870851982011],
              [-37.664667048513088, -8.247680819084401],
              [-37.664453366974648, -8.24724603871482],
              [-37.663730167820319, -8.246618751148786],
              [-37.662301987496591, -8.246427700987638],
              [-37.66166506332349, -8.246330623045308],
              [-37.660995390501483, -8.246254233862896],
              [-37.660846792829474, -8.246172349837376],
              [-37.660820562330912, -8.246144228673314],
              [-37.660561161040185, -8.246079156312275],
              [-37.660399361810143, -8.246143725950853],
              [-37.660299432854131, -8.246165999771611],
              [-37.659905678177061, -8.246102286678038],
              [-37.619551428144696, -8.240596591653011],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0299000',
        uf: 'PE',
        nome_proje: 'PA CAIÇARA',
        municipio: 'AGUAS BELAS',
        area_hecta: '829.2298',
        capacidade: 36.0,
        num_famili: 36.0,
        fase: 4.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '19/05/2004',
        area_calc_: 818.09,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.011052436616573, -9.215128067809916],
              [-37.007413261442082, -9.216955133273238],
              [-37.003872253004864, -9.218793455916744],
              [-37.003835570193864, -9.218812597536244],
              [-37.001452894627676, -9.22005933359325],
              [-36.99342782545763, -9.224082538745709],
              [-36.992433382779261, -9.224579144791042],
              [-36.991188843984276, -9.225200544724544],
              [-36.989795556485809, -9.225899753205764],
              [-36.988392738754804, -9.22661011100703],
              [-36.986849858536729, -9.227390368145485],
              [-36.98569925673943, -9.227937512815487],
              [-36.984548286538022, -9.228484922167123],
              [-36.98346112444522, -9.228994899266514],
              [-36.982399322140992, -9.229492811228448],
              [-36.98132658526233, -9.229961188732975],
              [-36.98036585300256, -9.230380835411449],
              [-36.979759842368608, -9.230645454723746],
              [-36.977525360458891, -9.23163203696291],
              [-36.978473955495247, -9.233135332711864],
              [-36.976281490396772, -9.234233417251659],
              [-36.976214789571237, -9.234266759289538],
              [-36.970335437112155, -9.23719975047953],
              [-36.971784798339655, -9.239497849595802],
              [-36.972445288102826, -9.239908452474912],
              [-36.973082703739671, -9.241155991417287],
              [-36.97311706674617, -9.241892913074496],
              [-36.973152473267987, -9.242876802565384],
              [-36.973470410706419, -9.243890129544933],
              [-36.973758626695613, -9.244246019070918],
              [-36.974926205577638, -9.245404436872679],
              [-36.975110774003909, -9.245460439311943],
              [-36.976063229037621, -9.245749295332908],
              [-36.976905879979704, -9.245843991734407],
              [-36.977631618374353, -9.246033033750882],
              [-36.978182281825582, -9.246176612967286],
              [-36.979162821578413, -9.246025480190514],
              [-36.981993248322965, -9.245827984576874],
              [-36.982951647095611, -9.246161422564763],
              [-36.983478067806956, -9.246344632322456],
              [-36.984772754053353, -9.246888139411727],
              [-36.986111632433349, -9.248253415844191],
              [-36.986715858227804, -9.248564066010086],
              [-36.987694880283286, -9.248857473305547],
              [-36.988589012947777, -9.248782125590678],
              [-36.988946926490996, -9.248661283130778],
              [-36.989078657407916, -9.248585184336262],
              [-36.989302105984478, -9.248589239238976],
              [-36.990443836250954, -9.248607660130288],
              [-36.990700343128118, -9.248783020755978],
              [-36.991113319947779, -9.248932228923048],
              [-36.992257051324295, -9.249416471281478],
              [-36.99256561813506, -9.249718135740109],
              [-36.993188989240302, -9.250168456860859],
              [-36.993834057143381, -9.250531574999714],
              [-36.993852104401952, -9.250734798211765],
              [-36.995323808200936, -9.251422633798692],
              [-36.996592866163134, -9.251888039922155],
              [-36.998211313239203, -9.247578527332577],
              [-36.99823227091354, -9.247508857535133],
              [-36.998360987085654, -9.247080459561014],
              [-36.998475504584619, -9.246781521658445],
              [-36.998778318936893, -9.245992685903339],
              [-36.999118156106874, -9.245108686782004],
              [-36.999487993221543, -9.244148468371506],
              [-36.999531218705215, -9.244034718185285],
              [-36.999935327492679, -9.242982846017677],
              [-37.000401115506733, -9.241771222519038],
              [-37.000989716301653, -9.240240183970075],
              [-37.001574721578891, -9.23868408315982],
              [-37.002247089292212, -9.236849860823282],
              [-37.00307338799135, -9.234597498307526],
              [-37.004142725033596, -9.231548179346301],
              [-37.00549796004389, -9.228296773164201],
              [-37.008432259710226, -9.223284836161865],
              [-37.008459464849473, -9.223252262739489],
              [-37.011115808322387, -9.220059998157636],
              [-37.011441355703276, -9.2196687465169],
              [-37.012624334686684, -9.218171418154681],
              [-37.011052436616573, -9.215128067809916],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0017000',
        uf: 'PE',
        nome_proje: 'PA SÃO JOSÉ',
        municipio: 'SAO BENEDITO DO SUL',
        area_hecta: '229.0014',
        capacidade: 19.0,
        num_famili: 18.0,
        fase: 6.0,
        data_de_cr: '17/09/1987',
        forma_obte: 'Desapropriação',
        data_obten: '13/03/1987',
        area_calc_: 228.8001,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.94715629204515, -8.755857130401655],
              [-35.945945892012709, -8.756451370492686],
              [-35.945212246383889, -8.757374942683066],
              [-35.944610059301333, -8.758570050307421],
              [-35.944230518113812, -8.758943303084909],
              [-35.943551052197869, -8.760065935038897],
              [-35.94326275394134, -8.7613020404927],
              [-35.942328514797602, -8.761626407330242],
              [-35.941661058969025, -8.761355335747869],
              [-35.941646210358599, -8.761825379588215],
              [-35.941595985435178, -8.76311959951032],
              [-35.941569616756546, -8.763311970588086],
              [-35.941499828948018, -8.763826044070463],
              [-35.941332661601493, -8.765624718865347],
              [-35.94132720962984, -8.765784822666646],
              [-35.941350651903157, -8.766204800964797],
              [-35.941366490179533, -8.766511461745067],
              [-35.943290019519274, -8.766098437441839],
              [-35.945368153720914, -8.768664975440318],
              [-35.945947757754553, -8.769895897051878],
              [-35.945488212308533, -8.771213798227864],
              [-35.945488030934669, -8.771214318378483],
              [-35.946539313597384, -8.771632738699642],
              [-35.947178257178798, -8.771316713822868],
              [-35.947648500721868, -8.771057078733062],
              [-35.948484781284051, -8.770420067336548],
              [-35.948737907560385, -8.770104349351229],
              [-35.949399058480623, -8.769241283917109],
              [-35.949807525099779, -8.768904490058983],
              [-35.950212453639637, -8.76879222541123],
              [-35.950534354329001, -8.768756363456669],
              [-35.950809325359458, -8.768727537309042],
              [-35.951406776132934, -8.768736204541247],
              [-35.952851328896976, -8.768918312104582],
              [-35.953337494447879, -8.768960481693279],
              [-35.953845306747631, -8.768821192351894],
              [-35.95404164450818, -8.768670119056432],
              [-35.95425497890421, -8.768319525002212],
              [-35.954407782972766, -8.767596372725746],
              [-35.954498477253885, -8.767182175917304],
              [-35.954579712948082, -8.766813651024226],
              [-35.954614818849393, -8.766515592724751],
              [-35.954379124770171, -8.764664203826355],
              [-35.9545346882432, -8.76459037987156],
              [-35.95612689093295, -8.763924343992542],
              [-35.958158915665223, -8.76306422094857],
              [-35.959582828809488, -8.762459913608524],
              [-35.960343381443671, -8.762033899321848],
              [-35.961307338767234, -8.761711478878333],
              [-35.961442721200925, -8.761655206355774],
              [-35.961275575409992, -8.761412016523892],
              [-35.96032335387563, -8.75988331321274],
              [-35.959836719163256, -8.758756303624192],
              [-35.959715757243245, -8.758272162402623],
              [-35.960540520786893, -8.756336248197496],
              [-35.960368894406344, -8.756377020150726],
              [-35.959123747090601, -8.756158338556043],
              [-35.957561279038934, -8.756417181206114],
              [-35.956488251701053, -8.756402032597222],
              [-35.955895063677957, -8.756364325101158],
              [-35.955471465460917, -8.75628944007037],
              [-35.955057409050674, -8.75621624141961],
              [-35.954904854801157, -8.756226355334617],
              [-35.954286956685742, -8.756426592432836],
              [-35.953467842023286, -8.756354600518733],
              [-35.953045607673147, -8.756367658823054],
              [-35.952465324128447, -8.756266629860436],
              [-35.951626273098057, -8.756497131201382],
              [-35.951019118428668, -8.756482415008499],
              [-35.949781650276684, -8.75675393425106],
              [-35.948700747824368, -8.756762368603374],
              [-35.94822248485373, -8.756574585827146],
              [-35.947616833546988, -8.756081464962895],
              [-35.94715629204515, -8.755857130401655],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0323000',
        uf: 'PE',
        nome_proje: 'PA SÃO PEDRO',
        municipio: 'JABOATAO DOS GUARARAPES',
        area_hecta: '174.2000',
        capacidade: 15.0,
        num_famili: 15.0,
        fase: 3.0,
        data_de_cr: '22/12/2004',
        forma_obte: 'Desapropriação',
        data_obten: '03/02/2004',
        area_calc_: 170.4678,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.033930703455276, -8.191230653629749],
              [-35.018385222312304, -8.199794367867867],
              [-35.018484200699952, -8.200097347437584],
              [-35.017725786126398, -8.200231864421063],
              [-35.01725242512515, -8.201708580325617],
              [-35.016445545853422, -8.203065376660136],
              [-35.016968925035194, -8.205118651645456],
              [-35.015871670570576, -8.207102928400836],
              [-35.016228990607722, -8.208271558927621],
              [-35.01703791780406, -8.209368860077463],
              [-35.019016069505291, -8.209957849047196],
              [-35.032055302392813, -8.197871125176746],
              [-35.032737918144221, -8.197553996031179],
              [-35.033256726175949, -8.196230455981754],
              [-35.034055304153192, -8.195468606039341],
              [-35.034809031977737, -8.194719727885841],
              [-35.035069592397548, -8.194143470125899],
              [-35.03506338034957, -8.193456824186937],
              [-35.034838522113816, -8.192916915379156],
              [-35.034279982036445, -8.191811881698065],
              [-35.033930703455276, -8.191230653629749],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0016000',
        uf: 'PE',
        nome_proje: 'PA LAJES',
        municipio: 'FLORESTA',
        area_hecta: '391.0000',
        capacidade: 20.0,
        num_famili: 20.0,
        fase: 5.0,
        data_de_cr: '07/05/1996',
        forma_obte: 'Adjudicação',
        data_obten: '18/02/1992',
        area_calc_: 502.9946,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.13011849977557, -8.525368372414782],
              [-38.128859350824285, -8.530136260871783],
              [-38.138988439172465, -8.527068317765295],
              [-38.139179094978736, -8.527009680890023],
              [-38.139509372884824, -8.52692078348189],
              [-38.139520915757871, -8.526918909752061],
              [-38.139756985733321, -8.526866342532196],
              [-38.13993591784569, -8.526849735612032],
              [-38.140219230970153, -8.526838605557014],
              [-38.140265477130121, -8.526838346212397],
              [-38.141109900571053, -8.526874584175616],
              [-38.142070589718472, -8.522319970168374],
              [-38.143694795702366, -8.515254644839967],
              [-38.143784517878167, -8.514864215455757],
              [-38.142359417412543, -8.513631127997572],
              [-38.140659029404723, -8.512037732774688],
              [-38.139175504666099, -8.51175226665177],
              [-38.13867423546322, -8.50876081431803],
              [-38.138326699252886, -8.508773972929031],
              [-38.133800280470332, -8.508944806692551],
              [-38.133116428660841, -8.511980199702965],
              [-38.132612193643205, -8.514242788446799],
              [-38.132493430507139, -8.514775598042513],
              [-38.132135945389535, -8.516379450278249],
              [-38.131138092662532, -8.520814110228764],
              [-38.13011849977557, -8.525368372414782],
            ],
          ],
          [
            [
              [-38.127565604583339, -8.535879857017772],
              [-38.127325387759804, -8.536955231023933],
              [-38.126714420665991, -8.539690038509729],
              [-38.125738025563663, -8.544092698715957],
              [-38.125294471709687, -8.546156156281471],
              [-38.128975085846157, -8.546972019878382],
              [-38.132637339007914, -8.547759228725013],
              [-38.136369610919559, -8.548570075853254],
              [-38.137339325488156, -8.544142944389431],
              [-38.138300821360879, -8.539722120119508],
              [-38.138988797804167, -8.536563005273495],
              [-38.139204808607921, -8.535571227465907],
              [-38.139255656741447, -8.535342246527822],
              [-38.139550834250286, -8.534011751558648],
              [-38.139671071616512, -8.533469356185046],
              [-38.140179824762413, -8.531175384919678],
              [-38.141024253792011, -8.527227215706064],
              [-38.140159025542459, -8.527190117403151],
              [-38.140112779341372, -8.527190376730861],
              [-38.139829465961455, -8.52720150668199],
              [-38.139650533684232, -8.527218113536048],
              [-38.139583364649035, -8.52722999366271],
              [-38.139414463479966, -8.527270680667378],
              [-38.139402920595984, -8.527272554392928],
              [-38.13907264236412, -8.527361451676407],
              [-38.138881986367089, -8.527420088479408],
              [-38.137940189102665, -8.527697378419575],
              [-38.128775987020127, -8.53047849260283],
              [-38.127698241860443, -8.535286032717877],
              [-38.127565604583339, -8.535879857017772],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0249000',
        uf: 'PE',
        nome_proje: 'PA BARRA DO EXÚ',
        municipio: 'SERRA TALHADA',
        area_hecta: '1105.7279',
        capacidade: 34.0,
        num_famili: 29.0,
        fase: 3.0,
        data_de_cr: '27/11/2007',
        forma_obte: 'Desapropriação',
        data_obten: '01/11/2006',
        area_calc_: 1107.3144,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.44823806299943, -8.024047189213857],
              [-38.448126076777086, -8.024351539730139],
              [-38.443446014064286, -8.023148746214913],
              [-38.443650054202294, -8.022850259979698],
              [-38.44026420358189, -8.022006281257047],
              [-38.437324631991039, -8.024215174367958],
              [-38.429999058414289, -8.030461898520107],
              [-38.42697717578335, -8.033108831822897],
              [-38.433437222363246, -8.045696412373321],
              [-38.422800585387918, -8.054733963509912],
              [-38.427042750788658, -8.058403096387513],
              [-38.429108047064744, -8.060141646817213],
              [-38.4416507341938, -8.049742341793099],
              [-38.447031180581277, -8.052664698913153],
              [-38.435111997747299, -8.062746098438321],
              [-38.449966008742877, -8.070689678788661],
              [-38.462684605814943, -8.061327801566184],
              [-38.459723678311619, -8.058012832955704],
              [-38.457557555921902, -8.055561445027568],
              [-38.456781060266245, -8.054682673908994],
              [-38.456096889763572, -8.053784486318552],
              [-38.454078549689513, -8.051134736700169],
              [-38.452225129805669, -8.048756553690039],
              [-38.452941892604564, -8.048482988404144],
              [-38.453193682694483, -8.048179134689713],
              [-38.453421405476995, -8.048023951382895],
              [-38.453527003818543, -8.047848163337292],
              [-38.453449535910835, -8.047754985411917],
              [-38.453131115754843, -8.047666642397187],
              [-38.450129883904452, -8.045364438227139],
              [-38.448083152586619, -8.042158709421409],
              [-38.446036771184033, -8.043934506119053],
              [-38.445590931530276, -8.044321399430141],
              [-38.444750584742735, -8.045050570709957],
              [-38.442177581418839, -8.04726387143651],
              [-38.440705524508985, -8.046316724938597],
              [-38.438899091883364, -8.045154507731818],
              [-38.438815402936008, -8.045100574749991],
              [-38.438151327178829, -8.044346839039138],
              [-38.441631498188798, -8.04158304259518],
              [-38.445067125303154, -8.038774650803724],
              [-38.445646497397753, -8.038487705842243],
              [-38.445915654408964, -8.038291607728997],
              [-38.446019574490862, -8.038215949296442],
              [-38.446624144263168, -8.037883630069921],
              [-38.446630349283488, -8.037858492859547],
              [-38.446760014978466, -8.037333776347909],
              [-38.448290918201998, -8.035950927619906],
              [-38.450676418719389, -8.034480843384445],
              [-38.451432106950406, -8.034868985488393],
              [-38.451507901616182, -8.034448938204166],
              [-38.452153398685063, -8.034245015745897],
              [-38.45232673774985, -8.034102875520432],
              [-38.452591522319906, -8.034117881195696],
              [-38.452707489971353, -8.034056980577439],
              [-38.452446425291626, -8.033162155586659],
              [-38.452622786948069, -8.033066511426496],
              [-38.452332444442121, -8.032959572792338],
              [-38.452401579882562, -8.032564300140326],
              [-38.45248510687766, -8.032467808822748],
              [-38.452712523811819, -8.032398825368597],
              [-38.452696868713232, -8.032365699191443],
              [-38.454584963780199, -8.03083545378494],
              [-38.454662333898625, -8.030772739385718],
              [-38.454741006688792, -8.030708984665729],
              [-38.455769769482615, -8.029858109482452],
              [-38.457166371934662, -8.028769195198146],
              [-38.457797299841793, -8.028114974437125],
              [-38.458462525389841, -8.027462878072118],
              [-38.459605838302551, -8.02664814264951],
              [-38.459326737114019, -8.026213346167628],
              [-38.44823806299943, -8.024047189213857],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0252000',
        uf: 'PE',
        nome_proje: 'PA TIMBURANA',
        municipio: 'SERRA TALHADA',
        area_hecta: '2230.5086',
        capacidade: 46.0,
        num_famili: 43.0,
        fase: 3.0,
        data_de_cr: '03/12/2007',
        forma_obte: 'Desapropriação',
        data_obten: '01/12/2006',
        area_calc_: 2232.6878,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.180523307837333, -8.24031097094861],
              [-38.168787713056489, -8.24165425601598],
              [-38.172944034549921, -8.250208692606426],
              [-38.171700192112411, -8.256084932337776],
              [-38.174145082330341, -8.2663285081185],
              [-38.1788089510055, -8.271494011712383],
              [-38.1790548998936, -8.274328622441866],
              [-38.178498523672346, -8.279611069693887],
              [-38.181169958181364, -8.280063988744223],
              [-38.183447905822035, -8.280053692922463],
              [-38.187410529325376, -8.278538003123384],
              [-38.192732692315296, -8.279266489468775],
              [-38.205639489509821, -8.289412972324541],
              [-38.207133797641958, -8.288580857930778],
              [-38.208470021482725, -8.287972539745502],
              [-38.209912329445991, -8.287337192559848],
              [-38.212003329131342, -8.28438524929831],
              [-38.213111926170626, -8.283259208549342],
              [-38.213293111204266, -8.2825380132216],
              [-38.213662637823838, -8.281838054575468],
              [-38.21463130116566, -8.280954406925309],
              [-38.215945167524048, -8.279973525496588],
              [-38.216714580680332, -8.279278909257757],
              [-38.21833776359005, -8.278383063135438],
              [-38.218798987657138, -8.278234251614043],
              [-38.220018546380125, -8.277464956627041],
              [-38.208795224849986, -8.236071123921581],
              [-38.205100888092701, -8.236325549847503],
              [-38.201453035308084, -8.235206728654726],
              [-38.186801732041538, -8.237292520306969],
              [-38.180523307837333, -8.24031097094861],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0155000',
        uf: 'PE',
        nome_proje: 'PA PANELAS',
        municipio: 'LAGOA GRANDE',
        area_hecta: '2405.5098',
        capacidade: 50.0,
        num_famili: 47.0,
        fase: 3.0,
        data_de_cr: '06/12/2005',
        forma_obte: 'Desapropriação',
        data_obten: '06/12/2005',
        area_calc_: 2411.1051,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.190052197909509, -8.74303672188293],
              [-40.193210362313081, -8.739754367264572],
              [-40.196754684680073, -8.733135026562527],
              [-40.19738933326898, -8.731945978611808],
              [-40.198483245476964, -8.731384216151632],
              [-40.209822642138953, -8.729716040933365],
              [-40.211922279891617, -8.724869864869095],
              [-40.213091730846394, -8.721829704307114],
              [-40.213162413026105, -8.721667712337494],
              [-40.213736036088399, -8.720272111436143],
              [-40.200140597826362, -8.709004581827084],
              [-40.197646493457157, -8.706923923823338],
              [-40.193278092538748, -8.703294984542268],
              [-40.188517349906718, -8.699343835890062],
              [-40.174869702447538, -8.707610565977866],
              [-40.174714076632171, -8.707705459859859],
              [-40.169702692038747, -8.71080270485276],
              [-40.163952502653579, -8.716995292468837],
              [-40.153898988144135, -8.739810831425213],
              [-40.150396057153841, -8.747927761910313],
              [-40.157841374869797, -8.761097604255561],
              [-40.190052197909509, -8.74303672188293],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0368000',
        uf: 'PE',
        nome_proje: 'PA POÇO DA CABRA',
        municipio: 'AGUAS BELAS',
        area_hecta: '161.9806',
        capacidade: 5.0,
        num_famili: 5.0,
        fase: 4.0,
        data_de_cr: '07/08/2007',
        forma_obte: 'Desapropriação',
        data_obten: '15/08/2006',
        area_calc_: 161.8999,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.978459184475504, -9.212959226334211],
              [-36.976178695794673, -9.213970070031646],
              [-36.967283246082175, -9.217912638646752],
              [-36.966765615966565, -9.218142031496331],
              [-36.964086973733139, -9.219329148412578],
              [-36.964605478605307, -9.219836670623003],
              [-36.96539649294256, -9.220420400504681],
              [-36.966167898572749, -9.221108244609843],
              [-36.966328031257284, -9.221282167400194],
              [-36.967047599723415, -9.222030282457109],
              [-36.967882928143929, -9.222948452527586],
              [-36.96843621960415, -9.224390409096808],
              [-36.968873170590832, -9.224460855032017],
              [-36.970129966547717, -9.224467951474375],
              [-36.970250032108872, -9.224457600883454],
              [-36.971138823131994, -9.224381621634759],
              [-36.97184911008619, -9.224320814307555],
              [-36.972001407956888, -9.22449731272501],
              [-36.972020323388918, -9.224562594946869],
              [-36.972341900374353, -9.225669952146232],
              [-36.973165666776062, -9.227150945706381],
              [-36.975202244639739, -9.226082910357693],
              [-36.976989955701754, -9.22514552981305],
              [-36.978640046032787, -9.224280133925658],
              [-36.978810146521788, -9.224190964786782],
              [-36.980497337950986, -9.223295755330032],
              [-36.981705507517489, -9.222654755572551],
              [-36.98028303981198, -9.218406361149226],
              [-36.979306470871073, -9.215489795845849],
              [-36.978459184475504, -9.212959226334211],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0065000',
        uf: 'PE',
        nome_proje: 'PA CACIMBA NOVA',
        municipio: 'FLORESTA',
        area_hecta: '3340.9959',
        capacidade: 55.0,
        num_famili: 54.0,
        fase: 5.0,
        data_de_cr: '12/06/2002',
        forma_obte: 'Desapropriação',
        data_obten: '12/06/2002',
        area_calc_: 3345.0264,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.300622991246662, -8.308030241842003],
              [-38.292763606584174, -8.310893323323512],
              [-38.244593509882428, -8.335025304583509],
              [-38.24202371547846, -8.347965957607618],
              [-38.24178485856357, -8.350136583510279],
              [-38.23457809715233, -8.359694681795636],
              [-38.229632955197452, -8.36624959494957],
              [-38.228445550930587, -8.369260771487188],
              [-38.223244156732044, -8.38245075694754],
              [-38.219915560087806, -8.391740784015898],
              [-38.24403920355978, -8.401102597819204],
              [-38.273313827090284, -8.362036763752583],
              [-38.266113665235167, -8.357739262249352],
              [-38.29767135473211, -8.317937599091406],
              [-38.297994303924774, -8.317819953903683],
              [-38.300622991246662, -8.308030241842003],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0053000',
        uf: 'PE',
        nome_proje: 'PA DEMÉTRIUS',
        municipio: 'OROCO',
        area_hecta: '1667.0000',
        capacidade: 30.0,
        num_famili: 27.0,
        fase: 4.0,
        data_de_cr: '15/01/2001',
        forma_obte: 'Desapropriação',
        data_obten: '15/01/2001',
        area_calc_: 1511.7125,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.624493116133621, -8.380497281544386],
              [-39.579964486541549, -8.380049739106687],
              [-39.565055109361047, -8.380325705718999],
              [-39.560718648746899, -8.380507181017443],
              [-39.55706033798274, -8.381674643830534],
              [-39.552576658921609, -8.383555254042141],
              [-39.519170967454983, -8.395065318812694],
              [-39.521779401824304, -8.402573336272605],
              [-39.548679475496897, -8.393248719273732],
              [-39.555553627916488, -8.393460874454778],
              [-39.572857182227686, -8.393994362891124],
              [-39.572858090526765, -8.393994361571002],
              [-39.619612012207554, -8.39533862104089],
              [-39.620199532945733, -8.393528716173245],
              [-39.620696722813406, -8.392035524508865],
              [-39.621374703476377, -8.389999353311449],
              [-39.621645750185344, -8.389094435783997],
              [-39.622730501735994, -8.385836559533777],
              [-39.623227674086415, -8.384343365517994],
              [-39.623950827763466, -8.38217144612654],
              [-39.624041221464431, -8.381899956134054],
              [-39.624493116133621, -8.380497281544386],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0221000',
        uf: 'PE',
        nome_proje: 'PA SANTO ANTONIO DA MANDASSAIA',
        municipio: 'OROCO',
        area_hecta: '904.4533',
        capacidade: 17.0,
        num_famili: 16.0,
        fase: 3.0,
        data_de_cr: '27/02/2007',
        forma_obte: 'Confisco',
        data_obten: '27/02/2007',
        area_calc_: 905.1023,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.545355056660469, -8.34877485045345],
              [-39.607247443203008, -8.347503830334155],
              [-39.544572520811265, -8.327424462698449],
              [-39.544554670338556, -8.327650613438966],
              [-39.543464890297578, -8.327652106017961],
              [-39.543237441241942, -8.32735393042134],
              [-39.541966006970974, -8.327337577120014],
              [-39.541647968092747, -8.327202335105492],
              [-39.541193831282406, -8.327157728826839],
              [-39.540286798133529, -8.32797301869274],
              [-39.540287294277732, -8.328334820281587],
              [-39.540651113699276, -8.328741352708256],
              [-39.54024263086459, -8.328877584424177],
              [-39.539880299171372, -8.32955645639993],
              [-39.539653446273995, -8.329692440598746],
              [-39.539971858608766, -8.330099035289933],
              [-39.540471034279882, -8.32987222979134],
              [-39.540244863752598, -8.330505691459537],
              [-39.539609525444874, -8.330777907078538],
              [-39.540064534602109, -8.331455667614204],
              [-39.540157769892105, -8.33321932648022],
              [-39.539386512408321, -8.333717853309528],
              [-39.540067946086737, -8.333943053058817],
              [-39.539750708298818, -8.33439573732606],
              [-39.540024460969512, -8.335345095602175],
              [-39.539525117105804, -8.335454315448589],
              [-39.539525588206615, -8.335798026871878],
              [-39.540161430943286, -8.335887612393874],
              [-39.540254606907517, -8.337606045572965],
              [-39.540617876319835, -8.337605550728345],
              [-39.540527742276801, -8.338103151392303],
              [-39.541028357585894, -8.338916523561116],
              [-39.540665957020941, -8.339550171246138],
              [-39.541684486179768, -8.340543737009778],
              [-39.54139424197232, -8.340815484802633],
              [-39.54153090489465, -8.341131874932913],
              [-39.541213850281494, -8.341720235951971],
              [-39.541850762050281, -8.342578645738085],
              [-39.541597279939147, -8.343166920177952],
              [-39.541897853841164, -8.343799662792099],
              [-39.541825946614097, -8.344342463904482],
              [-39.542171931407765, -8.344975144120692],
              [-39.542309095650921, -8.345653334935891],
              [-39.542218650707689, -8.345924810047718],
              [-39.541628200960673, -8.345835166885355],
              [-39.542128330383136, -8.346286735430233],
              [-39.542990989041698, -8.346195104063186],
              [-39.544218677316913, -8.347369275746917],
              [-39.545355056660469, -8.34877485045345],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0220000',
        uf: 'PE',
        nome_proje: 'PA VÁRZEA GRANDE',
        municipio: 'OROCO',
        area_hecta: '165.8090',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '27/02/2007',
        forma_obte: 'Confisco',
        data_obten: '27/02/2007',
        area_calc_: 165.9242,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.520113844900891, -8.501987729594557],
              [-39.519842769418524, -8.503028267341008],
              [-39.519998474166606, -8.503887334854031],
              [-39.519404741745397, -8.504708419214079],
              [-39.526037168533612, -8.512880346312178],
              [-39.526417913677498, -8.512383080033986],
              [-39.542673653461527, -8.52978024969166],
              [-39.543382442454437, -8.529824482652293],
              [-39.543808855216369, -8.529371637351943],
              [-39.544171343644976, -8.52869275728723],
              [-39.544987792401542, -8.527832340621496],
              [-39.54553193632983, -8.527107979576371],
              [-39.546348533682398, -8.526292694500926],
              [-39.520113844900891, -8.501987729594557],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0293000',
        uf: 'PE',
        nome_proje: 'PA JUA',
        municipio: 'CABROBO',
        area_hecta: '227.4014',
        capacidade: 4.0,
        num_famili: 4.0,
        fase: 3.0,
        data_de_cr: '27/12/2010',
        forma_obte: 'Confisco',
        data_obten: '20/12/2010',
        area_calc_: 227.5644,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.396984368620821, -8.310713643925871],
              [-39.395221810513341, -8.311564022973277],
              [-39.395485827894547, -8.315031164274341],
              [-39.394671777768814, -8.315038937612723],
              [-39.395257928803453, -8.323050539659951],
              [-39.395258469263354, -8.326668634472764],
              [-39.399693783839233, -8.358844938362081],
              [-39.404260580886493, -8.358380712582395],
              [-39.39904171125994, -8.32460172475553],
              [-39.398446889884177, -8.321032438402263],
              [-39.396984368620821, -8.310713643925871],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0202000',
        uf: 'PE',
        nome_proje: 'PA SAMUEL BARBOSA I',
        municipio: 'SANTA MARIA DA BOA VISTA',
        area_hecta: '311.3733',
        capacidade: 10.0,
        num_famili: 9.0,
        fase: 3.0,
        data_de_cr: '27/11/2006',
        forma_obte: 'Confisco',
        data_obten: '27/11/2006',
        area_calc_: 311.4086,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.86729983310989, -8.498505559134971],
              [-39.8671187755332, -8.498084547365258],
              [-39.86632274417704, -8.498604470955307],
              [-39.865857980674292, -8.498896733374846],
              [-39.864694558479499, -8.499662935029148],
              [-39.864107609714132, -8.500040664931671],
              [-39.86430549598694, -8.500468052006843],
              [-39.8721408661957, -8.517389629155879],
              [-39.872716771635304, -8.519668596890684],
              [-39.89529436265348, -8.571650657095017],
              [-39.895307522900445, -8.571638993868744],
              [-39.897305040479004, -8.569981699376523],
              [-39.897824218128321, -8.569467321417896],
              [-39.86729983310989, -8.498505559134971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0276000',
        uf: 'PE',
        nome_proje: 'PA BOA VISTA',
        municipio: 'SERRA TALHADA',
        area_hecta: '205.1590',
        capacidade: 8.0,
        num_famili: 7.0,
        fase: 3.0,
        data_de_cr: '05/11/2008',
        forma_obte: 'Adjudicação',
        data_obten: '06/11/2008',
        area_calc_: 219.5919,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.579334465122997, -7.995380992518333],
              [-38.588508277117569, -8.008774293114575],
              [-38.594880171101842, -8.017245886311912],
              [-38.59567153244852, -8.016552341132941],
              [-38.597005813231611, -8.015411941513971],
              [-38.596822611983981, -8.013644207010787],
              [-38.598722721039486, -8.012368854200451],
              [-38.587095150606217, -7.993763005492025],
              [-38.585105842922665, -7.990331463277438],
              [-38.579334465122997, -7.995380992518333],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0014000',
        uf: 'PE',
        nome_proje: 'PA SERRA NEGRA',
        municipio: 'FLORESTA',
        area_hecta: '2427.0000',
        capacidade: 103.0,
        num_famili: 64.0,
        fase: 5.0,
        data_de_cr: '04/10/1991',
        forma_obte: 'Adjudicação',
        data_obten: '04/09/1991',
        area_calc_: 2444.1554,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.046295235914521, -8.554318246326989],
              [-38.042145377876089, -8.554468529361326],
              [-38.042226972848205, -8.554103710484089],
              [-38.042295152639703, -8.553798730470726],
              [-38.046830994193712, -8.533515239582623],
              [-38.045762593427526, -8.533090612879045],
              [-38.04533346817864, -8.532951272032072],
              [-38.040879216773924, -8.530111370131776],
              [-38.040090865763936, -8.529460233017316],
              [-38.037584306755072, -8.528294658246647],
              [-38.03674921684263, -8.527736011203279],
              [-38.032569955768444, -8.526473549721382],
              [-38.031924403016824, -8.526309688980096],
              [-38.025364934508914, -8.55461872333052],
              [-38.025342702235129, -8.554714805908485],
              [-38.027657017152684, -8.554631092087535],
              [-38.02757240968041, -8.554996079676076],
              [-38.02484226917818, -8.566771650672781],
              [-38.010039206635327, -8.631452930651781],
              [-38.010512891882676, -8.631510406532588],
              [-38.010351235940597, -8.631993029896734],
              [-38.010074876142795, -8.633388349445049],
              [-38.010212840874864, -8.634290392855165],
              [-38.010196625892995, -8.636989805426582],
              [-38.00994539136488, -8.637564897906822],
              [-38.015179289643285, -8.64015800461876],
              [-38.01765786715476, -8.639519798677444],
              [-38.019707527883305, -8.639921356112913],
              [-38.026978928662423, -8.640411081742176],
              [-38.036777524302003, -8.596655791724626],
              [-38.046295235914521, -8.554318246326989],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'MF0280000',
        uf: 'PE',
        nome_proje: 'PA SAMUEL MATIAS',
        municipio: 'IBIMIRIM',
        area_hecta: '431.3721',
        capacidade: 12.0,
        num_famili: 12.0,
        fase: 3.0,
        data_de_cr: '09/03/2009',
        forma_obte: 'Desapropriação',
        data_obten: '25/06/2008',
        area_calc_: 429.6038,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.752153722470702, -8.673098110461279],
              [-37.680866161519695, -8.665302866792294],
              [-37.679834072331474, -8.666115160123002],
              [-37.679589058511752, -8.669008790064888],
              [-37.679367429528206, -8.669940445194483],
              [-37.747737103823702, -8.677857739531769],
              [-37.748245485830566, -8.67731057527558],
              [-37.749366539427527, -8.676117472223066],
              [-37.750474423049376, -8.674919890537289],
              [-37.751634020821633, -8.673664054493049],
              [-37.752153722470702, -8.673098110461279],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_sipra: 'PE0089000',
        uf: 'PE',
        nome_proje: 'PA RINOCERONTE',
        municipio: 'AMARAJI',
        area_hecta: '847.8000',
        capacidade: 77.0,
        num_famili: 60.0,
        fase: 5.0,
        data_de_cr: '02/12/1996',
        forma_obte: 'Desapropriação',
        data_obten: '09/05/1996',
        area_calc_: 783.4287,
        sr: null,
        descricao_: null,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.510705172127238, -8.333382697322],
              [-35.497909355276754, -8.334543163658692],
              [-35.48953122844857, -8.335350624693596],
              [-35.490519084532664, -8.337475757236231],
              [-35.493495826892136, -8.343874737577414],
              [-35.499330911986625, -8.356133893051167],
              [-35.499528480535602, -8.35623910242006],
              [-35.501161911574151, -8.358064265311759],
              [-35.50379947750011, -8.361011366446343],
              [-35.506672797627523, -8.364135103049561],
              [-35.508302848665814, -8.363270916749991],
              [-35.509835823991722, -8.362458188194319],
              [-35.510017000471109, -8.362218963664024],
              [-35.510596742094449, -8.361453475344568],
              [-35.512162462878401, -8.359385893740534],
              [-35.512395963557942, -8.35916314547036],
              [-35.512937235833498, -8.359037383887882],
              [-35.514512454923469, -8.358671386982971],
              [-35.514891292301805, -8.358848517149202],
              [-35.51528028356406, -8.359030394488299],
              [-35.515568034277614, -8.35919233616948],
              [-35.517692969241075, -8.360050394363572],
              [-35.518759947510581, -8.360244415869346],
              [-35.521809115313822, -8.353772836463714],
              [-35.522129382739614, -8.353053458238103],
              [-35.523452989659823, -8.345686649478063],
              [-35.520358010539617, -8.342968152330728],
              [-35.515855451510902, -8.339104861550126],
              [-35.514467320524481, -8.337893016870337],
              [-35.510705172127238, -8.333382697322],
            ],
          ],
        ],
      },
    },
  ],
};
